import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string
}

export function DragIcon({className}: Props) {
	return <div className={join(className)}>
		<svg data-cy='drag-icon' xmlns='http://www.w3.org/2000/svg' width='10' height='16'
			 viewBox='0 0 10 16'
			 fill='none'>
			<path
				d='M3.855 14C3.855 15.1 2.98763 16 1.9275 16C0.867375 16 0 15.1 0 14C0 12.9
				0.867375 12 1.9275 12C2.98763 12 3.855 12.9 3.855 14ZM1.9275 6C0.867375
				6 0 6.9 0 8C0 9.1 0.867375 10 1.9275 10C2.98763 10 3.855 9.1 3.855 8C3.855
				6.9 2.98763 6 1.9275 6ZM1.9275 0C0.867375 0 0 0.9 0 2C0 3.1 0.867375 4 1.9275
				4C2.98763 4 3.855 3.1 3.855 2C3.855 0.9 2.98763 0 1.9275 0ZM7.71 4C8.77013 4
				9.6375 3.1 9.6375 2C9.6375 0.9 8.77013 0 7.71 0C6.64988 0 5.7825 0.9 5.7825
				2C5.7825 3.1 6.64988 4 7.71 4ZM7.71 6C6.64988 6 5.7825 6.9 5.7825 8C5.7825
				9.1 6.64988 10 7.71 10C8.77013 10 9.6375 9.1 9.6375 8C9.6375 6.9 8.77013
				6 7.71 6ZM7.71 12C6.64988 12 5.7825 12.9 5.7825 14C5.7825 15.1 6.64988 16 7.71
				16C8.77013 16 9.6375 15.1 9.6375 14C9.6375 12.9 8.77013 12 7.71 12Z'
				fill='#0088CC'/>
		</svg>
	</div>;
}
