import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Wrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	variants: {
		emptyMode: {
			true: {
				gridTemplateColumns: 'repeat(3, 1fr)',
			},
			false: {
				gap: 70,
			},
		},
		isTablet: {
			true: {
				gridAutoFlow: 'row',
				gap: 16,
			},
		},
	},
});