import React from 'react';
import {TestHistoryService} from '../../../../services/test-history-service';
import styles from '../../styles.module.less';
import {Specialist} from './components/specialist';
import {Classes} from './components/classes';
import {Groups} from './components/groups';
import {SpecialistGroups} from './components/specialist-groups';
import {Students} from './components/students';
import {Subject} from './components/subject';
import {Test} from './components/test';

interface Props {
	service: TestHistoryService;
}

export function ReportSettings({service}: Props) {
	return <div className={styles.reportSettings}>
		<div>
			<Specialist service={service} />
			<Classes service={service} />
			<Groups service={service} />
			<SpecialistGroups service={service} />
			<Students service={service} />
			<Subject service={service} />
			<Test service={service} />
		</div>
	</div>;
}
