import {environment} from '@esgi/core/environments';
import {userStorage} from '@esgi/core/authentication';

export class ImageUrlBuilder {
	constructor(
		private defaultUrl: (entityId: string, testId: string) => string,
		private fallbackUrl: (entityId: string) => string) {

	}

	public large (entityId, testId, pregenerated, modifyTicks) {
		const {awsPreviewsBucket} = userStorage.get();

		if (pregenerated && awsPreviewsBucket) {
			return this.defaultUrl(entityId, testId) + '/large.png?ticks=' + modifyTicks;
		}

		return this.fallbackUrl(entityId) + '&ticks=' + modifyTicks;
	}

	public white (entityId, testId, pregenerated, modifyTicks) {
		const {awsPreviewsBucket} = userStorage.get();

		if (pregenerated && awsPreviewsBucket) {
			return this.defaultUrl(entityId, testId) + '/white.png?ticks=' + modifyTicks;
		}

		return this.fallbackUrl(entityId) + '&width=400&height=253&ticks=' + modifyTicks;
	}

	public medium (entityId, testId, pregenerated, modifyTicks) {
		const {awsPreviewsBucket} = userStorage.get();

		if (pregenerated && awsPreviewsBucket) {
			return this.defaultUrl(entityId, testId) + '/medium.png?ticks=' + modifyTicks;
		}

		return this.fallbackUrl(entityId) + '&width=221&height=100&ticks=' + modifyTicks;
	}

	public small (entityId, testId, pregenerated, modifyTicks) {
		const {awsPreviewsBucket} = userStorage.get();

		if (pregenerated && awsPreviewsBucket) {
			return this.defaultUrl(entityId, testId) + '/small.png?ticks=' + modifyTicks;
		}

		return this.fallbackUrl(entityId) + '&width=80&height=37&ticks=' + modifyTicks;
	}
}

export function createSelfAssessImageUrlBuilder() {
	const {awsPreviewsBucket} = userStorage.get();

	return (thumbnailUrl: string, questionId: number): string => {
		if (thumbnailUrl == null) {
			return `${environment.esgiApiURL}/assets/questions/self-assess-image?id=${questionId}`;
		}

		return `https://${awsPreviewsBucket}.s3.amazonaws.com/${thumbnailUrl}`;
	};
}

export function createQuestionImageUrlBuilder() {
	const {awsPreviewsBucket} = userStorage.get();

	const defaultUrl = (questionId: string, testId: string): string => {
		return `https://${awsPreviewsBucket}.s3.amazonaws.com/question-cards/${testId}/${questionId}`;
	};

	const fallbackUrl = (questionId: string): string => {
		return `${environment.esgiApiURL}/assets/questions/image?questionID=${questionId}`;
	};

	return new ImageUrlBuilder(defaultUrl, fallbackUrl);
}

export function createShareScreenImageUrlBuilder (authCode: string) {
	const {awsPreviewsBucket} = userStorage.get();

	const defaultUrl = (questionId: string, testId: string): string => {
		return `https://${awsPreviewsBucket}.s3.amazonaws.com/question-cards/${testId}/${questionId}`;
	};

	const fallbackUrl = (questionId: string): string => {
		return `${environment.esgiApiURL}/assets/questions/share-screen-image?questionID=${questionId}&AuthenticationCode=${authCode}`;
	};

	return new ImageUrlBuilder(defaultUrl, fallbackUrl);
}

export function createTestIntroImageUrlBuilder () {
	const {awsPreviewsBucket} = userStorage.get();

	const defaultUrl = (testIntroPageId: string, testId: string): string => {
		return `https://${awsPreviewsBucket}.s3.amazonaws.com/test-intro-pages/${testId}/${testIntroPageId}`;
	};

	const fallbackUrl = (testIntroPageId: string): string => {
		return `${environment.esgiApiURL}/assets/tests/intro-page/image?testIntroPageID=${testIntroPageId}`;
	};

	return new ImageUrlBuilder(defaultUrl, fallbackUrl);
}
