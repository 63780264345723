import {ChangeEvent, useCallback} from 'react';
import {InputHidden} from './index.styled';

type Props = {
	inputFileRef: React.MutableRefObject<HTMLInputElement | null>;
	setUploadedImage: (data: string) => void;
};

export function FileInputHidden({inputFileRef, setUploadedImage}: Props) {
	const handleFileChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0];

			const type = file?.type;

			const reader = new FileReader();

			reader.addEventListener(
				'load',
				() => {
					const imageData = reader.result;

					if (typeof imageData === 'string') {
						setUploadedImage(imageData);
					}

					event.target.value = '';
				},
				false,
			);

			if (file && type?.toLocaleLowerCase().includes('image')) {
				reader.readAsDataURL(file);
			}
		},
		[setUploadedImage],
	);

	return <InputHidden type='file' ref={inputFileRef} onChange={handleFileChange} accept='image/*' hidden />;
}
