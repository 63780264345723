import {SVGProps} from 'react';
import * as React from 'react';

export function SightWords(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            fill='none'
            viewBox='0 0 32 32'
            {...props}
        >
            <path fillRule='evenodd' clipRule='evenodd' d='M15.752 15.504C16.648 15.504 17.176 16 17.176 16.992V17.216H15.8C13.288 17.216 12.328 18.384 12.328 19.68V19.888C12.328 21.216 13.4 22.192 15 22.192C15.976 22.192 16.728 21.808 17.176 21.152C17.208 21.68 17.624 22.08 18.152 22.08C18.696 22.08 19.128 21.648 19.128 21.104V16.896C19.128 15.104 17.96 13.808 15.912 13.808C14.712 13.808 13.576 14.208 12.888 14.912C12.712 15.096 12.648 15.296 12.648 15.52C12.648 16 13.032 16.368 13.512 16.368C13.72 16.368 13.928 16.288 14.136 16.112C14.584 15.728 15.096 15.504 15.752 15.504ZM15.432 20.544C14.632 20.544 14.28 20.176 14.28 19.712V19.616C14.28 19.072 14.76 18.752 15.464 18.752H17.176V19.648C16.888 20.192 16.168 20.544 15.432 20.544Z' fill='#333333'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M16 6C15.448 6 15 6.448 15 7C15 7.552 15.448 8 16 8C16.552 8 17 7.552 17 7C17 6.448 16.552 6 16 6ZM10 9C9.4477 9 9 9.448 9 10C9 10.552 9.4477 11 10 11C10.5523 11 11 10.552 11 10C11 9.448 10.5523 9 10 9ZM22 9C21.448 9 21 9.448 21 10C21 10.552 21.448 11 22 11C22.552 11 23 10.552 23 10C23 9.448 22.552 9 22 9ZM8 15C7.4477 15 7 15.448 7 16C7 16.552 7.4477 17 8 17C8.5523 17 9 16.552 9 16C9 15.448 8.5523 15 8 15ZM24 15C23.448 15 23 15.448 23 16C23 16.552 23.448 17 24 17C24.552 17 25 16.552 25 16C25 15.448 24.552 15 24 15Z' fill='#333333'/>
        </svg>
	);
}
