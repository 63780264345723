import {StudentsPanelTabID} from '../../../types';
import {TabSectionProps} from '../../types';
import {MultiTabPanel} from '../../multi-tab-panel';
import {StudentsContext, StudentsContextValue} from './context';
import {useSortStudents} from '../../hooks/use-sort-students-data';
import {useStudentsPanelContext} from '../../../context';
import {useEffect, useMemo, useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {StudentSort, useUser} from '@esgi/core/authentication';
import {ChangeSortBy} from '@esgi/main/features/teacher/events';

export function StudentsTabContent({
	sectionTitle = 'Students',
	description = '',
	children,
	...restTabProps
}: TabSectionProps<StudentsPanelTabID.Students>) {
	const {
		entities: {students: allStudents},
	} = useStudentsPanelContext();
	const [sortByData, setSortByData] = useState<StudentSort | undefined>();
	const sortedStudents = useSortStudents(allStudents, sortByData);
	const user = useUser();
	const contextValue = useMemo<StudentsContextValue>(
		() => ({
			students: sortedStudents,
		}), 
		[sortedStudents],
	);
	useEventEffect(ChangeSortBy, ({sortBy}) => {
		return setSortByData(sortBy);
	});

	useEffect(()=>{
		const sortBy = user?.studentSort;
		setSortByData(sortBy);
	}, [user]);
	
	return (
		<MultiTabPanel.TabContent
			name={sectionTitle}
			id={StudentsPanelTabID.Students}
			description={description}
			{...restTabProps}
		>
			<StudentsContext.Provider value={contextValue}>{children}</StudentsContext.Provider>
		</MultiTabPanel.TabContent>
	);
}
