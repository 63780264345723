import React, {Dispatch, PropsWithChildren, useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Drawer} from '@esgi/ui/layout';
import {useSpring, config} from 'react-spring';
import {DrawerContentWrapper, DrawerWrapper, PanelBox, drawerWrapperWidth} from './index.styled';
import {DrawerContext, DrawerContextValue} from '../../context/drawer-context';
import {PanelAnimatedBox} from '../panel-animated-box';
import {PanelTrigger} from '../drawer-panel/components/panel-trigger';
import {withDrawerManager} from '../../with-drawer-manager';
import {Property} from '@stitches/react/types/css';
import {CSS} from '@stitches/react';

export {drawerPaddingInline} from './index.styled';

type Props = {
	open: boolean;
	width?: Property.Width;
	onClickOutside?: VoidFunction;
	onStateChanged: (open: boolean) => void;
	onPanelCollapse?: Dispatch<void>;
	css?: CSS;
} & PropsWithChildren;

function DrawerRootImpl(props: Props) {
	const [withPanel, setWithPanel] = useState(false);
	const [isPanelOpen, setIsPanelOpen] = useState(false);
	const [panelWidth, setPanelWidth] = useState(0);

	const drawerWrapperRef = useRef<HTMLDivElement>(null);
	const panelRef = useRef<HTMLDivElement>(null);

	const togglePanelOpen = useCallback(() => {
		setIsPanelOpen((currentValue) => !currentValue);
		props.onPanelCollapse?.();
	}, [props]);

	useLayoutEffect(() => {
		if (drawerWrapperRef.current) {
			const contentWrapper = drawerWrapperRef.current.parentElement;
			const root = contentWrapper?.parentElement;

			if (root) {
				if (withPanel) {
					root.style.width = 'max-content';
				}
			}
		}
	}, [drawerWrapperRef, withPanel]);

	const drawerContextValue = useMemo<DrawerContextValue>(
		() => ({
			withPanel,
			setWithPanel,
			togglePanelOpen,
			isPanelOpen,
			panelRef,
		}),
		[withPanel, togglePanelOpen, isPanelOpen],
	);

	const DrawerContentElement = withPanel ? DrawerContentWrapper : React.Fragment;

	const panelAnimationStyles = useSpring({
		left: isPanelOpen ? -(panelWidth - drawerWrapperWidth) : 0,
		config: {
			...config.gentle,
			clamp: true,
		},
	});

	return (
		<>
			<Drawer
				width={props.width}
				show={props.open}
				afterChangeState={props.onStateChanged}
				onClickOutside={props.onClickOutside}
			>
				<DrawerContext.Provider value={drawerContextValue}>
					<DrawerContentElement {...(withPanel ? {'data-cy': 'drawer-content'} : {})}>
						{withPanel && (
							<PanelBox onClick={togglePanelOpen} style={panelAnimationStyles}>
								<PanelTrigger tooltipTitle='View All' dataCy='view-all-trigger' />
							</PanelBox>
						)}
						<DrawerWrapper css={props.css} ref={drawerWrapperRef} dataCy='drawer-wrapper' data-drawer-wrapper=''>
							{props.children}
						</DrawerWrapper>
					</DrawerContentElement>
				</DrawerContext.Provider>
			</Drawer>

			{/* next div uses as container for rendering panel */}
			{withPanel && (
				<PanelAnimatedBox
					isPanelOpen={isPanelOpen}
					panelRef={panelRef}
					panelWidth={panelWidth}
					setPanelWidth={setPanelWidth}
				/>
			)}
		</>
	);
}

export const DrawerRoot = withDrawerManager(DrawerRootImpl);
