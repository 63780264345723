import {Observable, Subject} from 'rxjs';
import {clearDebounce, computed, debounce, observable, observableArray} from '../decorators';

export class Item<T> {
	value: T;
	height: number;
	element: HTMLElement;
}

interface IDisplayBoundaries {
	from: number,
	to: number,
}

export class Scroll<T> {

	/**
     * Items to display.
     */
	@observableArray()
	public displayItems: any[];

	@observable()
	public isReady: boolean = false;
    
	private isReadyPromise: JQueryPromise<any>;

	@observable()
	protected totalHeight: number = 0;

	protected readonly smoothPadding = 1000;

	protected readonly items: KnockoutObservableArray<Item<T>> = ko.observableArray<Item<T>>([]);

	protected readonly _displayItems: KnockoutObservable<IDisplayBoundaries> = ko.observable<IDisplayBoundaries>(null);

	protected $container: JQuery;
	protected preRenderItemHeight: number = 0;

	protected lastScrollOffset: number = 0;
	protected alreadyScrolledByAction: number = 0;

	/**
     * Virtual scroll allow to render items partially in depends of the current scroll position.
     * For initialize the VS call init method with all items that need to render.
     * The structure of the HTML code should be like:
     * <div class="selector"> - parent container with binded styles from containerStyles() method
     *      <div class="placeholder"></div> - required top placeholder for fill empty space. Bind styles from topPlaceholderHeight() to this element.
     *      <ko data-bind="foreach: displayItems"> - foreach to render items that should be displayed now.
     *          <div data-bind="virtualScrollItem">...</div> - display item. virtualScrollItem bind is required.
     *      </ko>
     * </div>
     * @param scrollContainerSelector - selector of the scroll container
     */
	constructor(private scrollContainerSelector: string) {
	}

	/**
     * Styles for scroll container.
     */
	@computed()
	public get containerStyles() {
    	let h;
    	if (!this.isReady) {
    		h = 'auto';
    	} else {
    		h = this.totalHeight + 'px';
    	}
    	return {height: h};
	}

	/**
     * Height for the top placeholder.
     */
	@computed()
	public get topPlaceholderHeight() {
    	const currItems = this._displayItems();
    	if (currItems) {
    		const items = this.items();
    		return items.slice(0, currItems.from).map(i => i.height).reduce((a, b) => a + b, 0);
    	}
    	return 0;
	}

	/**
     * Set preliminary height of each item.
     * @param height
     */
	public updateItemsHeight(height: number) {
    	this.preRenderItemHeight = height;
    	this.items().forEach(i => i.height = height);
    	this.recalculateTotalHeight();
	}

	/**
     * Will initialize the virtual scroll. Call it each time when items changed.
     * @param items - all items to display
     * @param preRenderItemHeight - preliminary height of the each item.
     */
	public init(items: T[], preRenderItemHeight: number = 0) {
    	if (!this.isReady) {
    		const scrollItems = this.buildItems(items);
    		this.items(scrollItems);
    		this.updateItemsHeight(preRenderItemHeight);
    		this.initListener().subscribe((container) => {
    			this.$container = container;
    			this.calculateFirstHeight();
    		});
    	} else {
    		this.reInit(items, preRenderItemHeight);
    	}
	}

	// Use for handling touch scroll and scrolling after user stop scroll gesture
	protected touchDeviceScrollHandler() {
    	const scrollOffset = this.$container.scrollTop();
    	this.alreadyScrolledByAction += Math.abs((this.$container.scrollTop() - this.lastScrollOffset));
    	this.lastScrollOffset = scrollOffset;
    	if(this.alreadyScrolledByAction >= this.smoothPadding) {
    		clearDebounce(this, 'smoothlyUpdateScope');
    		this.immediatelyUpdateScope();
    	} else {
    		this.smoothlyUpdateScope();
    	}
	}

	protected getItemsToShow(boundaries: IDisplayBoundaries) {
    	if (boundaries) {
    		return Array.from(this.items().slice(boundaries.from, boundaries.to + 1));
    	} else {
    		return Array.from(this.items());
    	}
	}

	protected getCurrentItemsIndexesToShow(): number[] {
    	const scrollPos = this.$container.scrollTop();
    	const rootH = this.$container.height();
    	const smoothPadding = this.smoothPadding;
    	const res = [];
    	const items = this.items();
    	let totalHeight = 0;
    	for (let i = 0; i < items.length; i++) {
    		const itemH = items[i].height;
    		totalHeight += itemH;
    		if ((totalHeight > (scrollPos - smoothPadding)) && (totalHeight < (scrollPos + rootH + smoothPadding + itemH))) {
    			res.push(i);
    		}
    	}
    	return res.sort((a, b) => a - b);
	}

	private reInit(items: T[], preRenderItemHeight: number = 0) {
    	this.isReady = false;
    	this.items([]);
    	this.displayItems = [];
    	this._displayItems(null);
    	this.totalHeight = 0;
    	this.init(items, preRenderItemHeight);
	}

	private initListener(subject?: Subject<JQuery>): Observable<JQuery> {
    	const container = $(this.scrollContainerSelector);

    	// Wait container in DOM
    	if (!container[0]) {
    		let behaviorSubject = subject;
    		if(!behaviorSubject) {
    			behaviorSubject = new Subject<JQuery>();
    		}
    		setTimeout(() => this.initListener(behaviorSubject));
    		return behaviorSubject;
    	}

    	if (this.isTouchDevice()) {
    		container.scroll(() => this.touchDeviceScrollHandler());
    	} else {
    		container.scroll(() => this.smoothlyUpdateScope());
    	}

    	$(window).on('resize', () => {
            
    	});
    	if(subject) {
    		subject.next(container); 
    		subject.complete();
    		return subject.asObservable();
    	} else {
    		return Observable.create((obs) => {
    			obs.next(container);
    			obs.complete();
    		});
    	}
	}

	@debounce(40)
	private smoothlyUpdateScope() {
    	this.updateScope();
	}

	private immediatelyUpdateScope() {
    	this.updateScope();
	}

	private updateScope() {
    	this.alreadyScrolledByAction = 0;
    	const prev = this._displayItems();
    	const itemsToShow = this.getCurrentItemsIndexesToShow();
    	const firstItemIndex = itemsToShow[0];
    	const lastItemIndex = itemsToShow[itemsToShow.length - 1];
    	const newVal = {from: firstItemIndex, to: lastItemIndex} as IDisplayBoundaries;

    	if (!prev) {
    		this._displayItems(newVal);
    		this.setItemsToShow(this.getItemsToShow(newVal));
    	} else {
    		this._displayItems(newVal);
    		this.setItemsToShow(this.getItemsToShow(newVal));
    	}
	}

	private setItemsToShow(items: Item<T>[]) {
    	this.displayItems = [];
    	items.filter(i => !!i).forEach(i => {
    		this.displayItems.push(i.value);
    	});
	}

	private buildItems(items: T[]): Item<T>[] {
    	return Array.from(items).map(i => {
    		const item = new Item<T>();
    		item.value = i;
    		item.height = 0;
    		return item;
    	});
	}

	private calculateFirstHeight() {
    	this.setItemsToShow([this.items()[0]]);
    	setTimeout(() => {
    		this.updateScope();
    		this.isReady = true;
    	});
	}

	private recalculateTotalHeight() {
    	this.totalHeight = this.items().map(i => i.height).reduce((a, b) => a + b, 0);
	}

	private isTouchDevice() {
    	// Taken from no-react.js
    	return document.body.parentElement.classList.contains('touch');
	}

	/**
     * This method use only for internal calling. Don't use it manually.
     * @param element
     * @param itemModel
     */
	setItemHeight = (element: HTMLElement, itemModel: T) => {
    	if (itemModel) {
    		const item = this.items().find(i => i.value === itemModel);
    		if (item) {
    			setTimeout(() => {
    				item.element = element;
    				item.height = element.clientHeight;
    				this.recalculateTotalHeight();
    			});
    		}
    	}
	}
}

ko.bindingHandlers.virtualScrollItem = {
	init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
		const callFun = bindingContext.$parent.setItemHeight;
		if (callFun) {
			callFun(element, viewModel);
		}
	},
};