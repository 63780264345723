import './styles.less';
import './print-styles.less';
import React from 'react';
import {UsageReportApi} from './api';
import {Comparator} from './comparator';
import {IRow, SortCell, SortModel, TeacherActivityRequest} from './models';

const sortRows = [
  new SortCell('schoolName', 'School', 'info-cell', {minWidth: '90px'}),
  new SortCell('teacher', 'Teacher', 'info-cell', {minWidth: '90px'}),
  new SortCell('expDate', 'Expiration Date', '', {minWidth: '155px'}),
  new SortCell('lastLoginDate', 'Last Login Date', '', {minWidth: '155px'}),
  new SortCell('loginCount', 'Login Count', '', {minWidth: '150px'}),
  new SortCell('testsAuthored', 'Total Tests Authored', '', {minWidth: '220px'}),
  new SortCell('testsAdministered', 'Tests Administered', '', {minWidth: '180px'}),
  new SortCell('testsCompleted', 'Tests Completed', '', {minWidth: '155px'}),
  new SortCell('questionsAsked', 'Questions Asked', '', {minWidth: '155px'}),
  new SortCell('studentsEnrolled', 'Students Enrolled', '', {minWidth: '165px'}),
  new SortCell('studentsTested', 'Students Tested', '', {minWidth: '155px'})];

export class OptionsRef {
  current: TeacherActivityRequest = null;
}

class State {
  sortRows: SortCell[] = sortRows;
  rows: IRow[] = [];
  districtName: string;
  date: string;
  loaded: boolean;
  sortModel: SortModel;
  sortRules: SortModel[] = [{
    fieldName: 'schoolName',
    direction: 'Asc',
    relatedSorts: [{fieldName: 'lastName', direction: 'Asc'}, {fieldName: 'firstName', direction: 'Asc'}],
  }];
  cacheKey: string;
}

class Props {
  onLoaded: () => void;
  districtId: number;
  globalSchoolYearId: number;
  teachersGroupID: number;
  schoolsGroupID: number;
  optionsToExportRef?: OptionsRef;
}

export class TeacherActivityReport extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = new State();
  }

  public componentDidMount() {
    this.server.init().subscribe(response => {
      let date = new Date(Date.parse(response.report.reportDateTime));
      let dateStr = (date.getMonth() + 1) + '/' + (date.getUTCDate()) + '/' + date.getFullYear().toString().substring(2);
      this.setState({
        loaded: true,
        rows: response.report.rows,
        districtName: response.report.districtName,
        date: dateStr,
        sortModel: response.sortModel,
        cacheKey: response.cacheKey,
      }, () => {
        this.updateOptionsRef();
        if (this.state.sortModel) {
          this.applySort(this.state.sortModel);
        }
        setTimeout(() => {
          $('#table-usage-report').floatThead({
            position: 'absolute',
            scrollContainer: () => {
              return $('.report-holder');
            },
          });
        });
        this.props.onLoaded();
      });
    });
  }

  updateOptionsRef() {
    if (this.props.optionsToExportRef) {
      const options = new TeacherActivityRequest();
      options.sortModel = this.state.sortModel;
      options.cacheKey = this.state.cacheKey;
      options.globalSchoolYearId = this.props.globalSchoolYearId;
      options.districtId = this.props.districtId;
      this.props.optionsToExportRef.current = options;
    }
  }

  applySort(sortModel: SortModel) {
    let sortRule = null;
    for (let i = 0; i < this.state.sortRules.length; i++) {
      if (this.state.sortRules[i].fieldName === sortModel.fieldName) {
        sortRule = this.state.sortRules[i];
        break;
      }
    }

    let additionalSortModels = [];
    if (sortRule != null) {
      additionalSortModels = sortRule.relatedSorts;
    }

    let comparator = new Comparator(sortModel, additionalSortModels);

    let rows = this.state.rows;
    rows.sort((left, right) => {
      return comparator.comparer(left, right);
    });

    const cells = this.getCellDirection(sortModel);

    this.setState({rows: rows, sortModel: sortModel, sortRows: cells}, () => this.updateOptionsRef());
  }

  sortClicked(data: SortCell) {
    let sort = this.state.sortModel;
    if (sort == null) {
      sort = new SortModel();
    }
    if (sort.fieldName === data.name) {
      sort.direction = this.getSortDirection(this.state.sortModel.direction);
    } else {
      sort.fieldName = data.name;
      sort.direction = this.getSortDirection('None');
    }
    this.applySort(sort);
  }

  getCellDirection(sortModel: SortModel): SortCell[] {
    const sortRows = this.state.sortRows;
    for (let i = 0; i < sortRows.length; i++) {
      let cell = sortRows[i];
      if (cell.name === sortModel.fieldName) {
        cell.direction(sortModel.direction);
      } else {
        cell.direction('None');
      }
    }
    return sortRows;
  }


  getSortDirection(oldDirection) {
    if (oldDirection === 'Asc') {
      return 'Desc';
    }
    if (oldDirection === 'Desc') {
      return 'Asc';
    }
    if (oldDirection === 'None') {
      return 'Asc';
    }
    return 'None';
  }

  private server = {
    init: () => {
      const request = {
          districtId: this.props.districtId,
          globalSchoolYearId: this.props.globalSchoolYearId,
          schoolsGroupID: this.props.schoolsGroupID,
          teachersGroupID: this.props.teachersGroupID,
        }
      return UsageReportApi.init(request);
    },
    removeCache: (guid) => {
      return UsageReportApi.removeCache(guid);
    },
  };

  public render() {
    return <div className='report teacher-activity-report'>
      <div className='usage-report-body' style={{height: '100%'}}>
        <div className='report-holder'
             style={{overflow: 'hidden', position: 'relative', height: '100%'}}>
          <table id='table-usage-report' className='table-report'>
            <thead>
            <tr>
              {this.state.sortRows.map((item, index) => {
                return <th key={index} onClick={() => this.sortClicked(item)}
                           className={item.cellStyle()} style={item.cssInline}>
                  <span/>{item.displayName} <i className={item.directionCss()}
                                               aria-hidden='true'/>
                </th>;
              })}
            </tr>
            </thead>
            <tbody>
            {this.state.rows.map((row, index) => {
              return <tr key={index}>
                <td className='info-cell'>
                  <div>{row.schoolName}</div>
                </td>
                <td className='info-cell'>
                  <span>{row.firstName}</span>
                  <span style={{marginLeft: '4px'}}>{row.lastName}</span>
                </td>
                <td>{row.expDateStr}</td>
                <td>{row.lastLoginDateStr}</td>
                <td>{row.loginCount}</td>
                <td>{row.testsAuthored}</td>
                <td>{row.testsAdministered}</td>
                <td>{row.testsCompleted}</td>
                <td>{row.questionsAsked}</td>
                <td>{row.studentsEnrolled}</td>
                <td>{row.studentsTested}</td>
              </tr>;
            })}
            </tbody>
          </table>
        </div>
      </div>
      <div id='report-print' style={{display: 'none'}}>
        <table>
          <thead>
          <tr>
            <th colSpan={this.state.sortRows.length}>
              <span>{this.state.districtName}</span>
              (<span>{this.state.date}</span>)
            </th>
          </tr>
          <tr>
            {this.state.sortRows.map((item, index) => {
              return <th key={index}>{item.displayName}</th>;
            })}
          </tr>
          </thead>
          <tbody>
          {this.state.rows.map((row, index) => {
            return <tr key={index}>
              <td>{row.schoolName}</td>
              <td>
                <span>{row.firstName}</span>
                <span style={{marginLeft: '4px'}}>{row.lastName}</span>
              </td>
              <td>{row.expDateStr}</td>
              <td>{row.lastLoginDateStr}</td>
              <td>{row.loginCount}</td>
              <td>{row.testsAuthored}</td>
              <td>{row.testsAdministered}</td>
              <td>{row.testsCompleted}</td>
              <td>{row.questionsAsked}</td>
              <td>{row.studentsEnrolled}</td>
              <td>{row.studentsTested}</td>
            </tr>;
          })}
          </tbody>
        </table>
      </div>
    </div>;
  }
}
