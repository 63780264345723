import {Textarea} from '@esgi/ui/controls';
import {RubricCriterion, TestInfo, TestSession} from '../../../../types';
import {SessionTimeInfo} from '../../../session-time-info';
import {noop} from 'underscore';
import {AlertBody} from '../../../components.styled';
import {QuestionRowViewMode} from '../../../question-row-view-mode';
import {GridBox} from '@esgi/ui/layout';
import {ProgressDashedLine, QuestionsContainer} from '../../../../../kit';
import {Text} from '@esgi/ui/typography';
import {TestNameWithBar} from './test-name-with-bar';
import {GridProgressBarItem} from '@esgi/ui';
import {AlertFooter} from '../../../alert-footer';
import {useMemo} from 'react';
import {AlertHeader} from '../../../alert-header';
import {Student} from '@esgi/main/libs/store';
import {AlertHeaderDeletedSession} from '../../../alert-header/deleted-session';
import {AlertFooterDeletedSession} from '../../../alert-footer-deleted-session';

type Props = {
	canEdit: boolean;
	sessionInfo: TestSession;
	testInfo: TestInfo;
	questionTitlesById: Record<RubricCriterion['id'], RubricCriterion['name']>;
	onCloseAlert: VoidFunction;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	student: Student;
	onDeleteSession: VoidFunction;
	onRestoreSession: VoidFunction;
	onDownloadSession: VoidFunction;
};

export function ViewModeContent({
	canEdit,
	sessionInfo,
	testInfo,
	questionTitlesById,
	onCloseAlert,
	setIsEditMode,
	student,
	onDeleteSession,
	onRestoreSession,
	onDownloadSession,
}: Props) {
	const maxCriteriaValue = testInfo.totalPossible / sessionInfo.rubricAnswers.length;

	const progressBarItems = useMemo(
		() =>
			sessionInfo.rubricAnswers.map<GridProgressBarItem>(({score}) => ({
				value: score,
				maxValue: maxCriteriaValue,
			})),
		[maxCriteriaValue, sessionInfo],
	);

	return (
		<>
			{sessionInfo.isDeleted ? (
				<AlertHeaderDeletedSession onCloseAlert={onCloseAlert} student={student} />
			) : (
				<AlertHeader
					canEdit={canEdit}
					isEditMode={false}
					onCloseAlert={onCloseAlert}
					onDeleteSession={onDeleteSession}
					onEditModeClick={() => setIsEditMode(true)}
					student={student}
				/>
			)}
			<AlertBody>
				<TestNameWithBar
					testInfo={testInfo}
					correctAnswers={sessionInfo.correctAnswers}
					progressBarItems={progressBarItems}
				/>

				<SessionTimeInfo duration={sessionInfo.duration} testDate={sessionInfo.testDate} />

				<QuestionsContainer>
					{sessionInfo.rubricAnswers.map(({score, criteriaID, notes}, index) => (
						<QuestionRowViewMode
							questionNumber={index + 1}
							questionTitle={questionTitlesById[criteriaID] ?? ''}
							comment={notes}
							withQuestionUnderline={index !== sessionInfo.rubricAnswers.length - 1}
							key={criteriaID}
						>
							<GridBox
								gap='4'
								align='center'
								justify='start'
								css={{
									gridTemplateColumns: `auto ${maxCriteriaValue * 12 + (maxCriteriaValue - 1) * 4}px`,
									paddingTop: 2,
									paddingBottom: 2,
								}}
							>
								<Text size='xSmall' font='mono' bold color='primary'>
									{score}
								</Text>
								<ProgressDashedLine value={score} maxValue={maxCriteriaValue} progressLineHeight={12} />
							</GridBox>
						</QuestionRowViewMode>
					))}
				</QuestionsContainer>

				<Textarea placeholder='Summary Note' value={sessionInfo.notes ?? ''} disabled onChange={noop} />
			</AlertBody>

			{sessionInfo.isDeleted ? (
				canEdit && <AlertFooterDeletedSession onRestoreSession={onRestoreSession} />
			) : (
				<AlertFooter isEditMode={false} onDownload={onDownloadSession} />
			)}
		</>
	);
}
