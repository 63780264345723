import {CardViewType} from '../../../../../types';
import {SingleStudentRearrangeModeContent} from '../types';
import {TestCard} from '../../../../test-cards';
import {TestSingleStudentModel} from '../../../../../../../types';
import {Ref, useImperativeHandle, useState} from 'react';
import {GridCardsRearrangeContainer} from './grid-cards-rearrange-container';

export type SingleStudentCardsManager = {
	getItems: () => TestSingleStudentModel[];
};

type Props = {
	cardViewType: CardViewType;
	cardsRef: Ref<SingleStudentCardsManager | undefined>;
	showSelfAssessOption: boolean;
} & Pick<SingleStudentRearrangeModeContent, 'allTests'>;

export function SingleStudentCards({cardViewType, allTests, cardsRef, showSelfAssessOption}: Props) {
	const [controlledItems, setControlledItems] = useState(allTests);

	useImperativeHandle(cardsRef, () => ({
		getItems: () => controlledItems,
	}));

	if (cardViewType === CardViewType.List) {
		return (
			<TestCard.ListCard.CardGroupDroppable droppableItems={controlledItems} onDragEnd={setControlledItems}>
				{controlledItems.map(({testInfo: {id, name, type}}, index) => (
					<TestCard.ListCard.DraggableCard
						draggableId={String(id)}
						index={index}
						testID={id}
						name={name}
						type={type}
						key={id}
					/>
				))}
			</TestCard.ListCard.CardGroupDroppable>
		);
	}

	if (cardViewType === CardViewType.Grid) {
		return (
			<GridCardsRearrangeContainer
				items={controlledItems}
				onDragEnd={setControlledItems}
				renderItem={({testInfo, studentResult}) => (
					<TestCard.GridCard.SingleStudentCard.Draggable
						showSelfAssessOption={showSelfAssessOption}
						studentResult={studentResult}
						testInfo={testInfo}
					/>
				)}
			/>
		);
	}

	return null;
}
