import {ImageMimeType, UploadFileErrorMessage, UploadFileErrorType} from '@esgi/ui';

export const defaultZoomValueRange = {min: 1, max: 3};
export const maxFileSize = 10240000;
export const fileTypes = [ImageMimeType.PNG, ImageMimeType.JPEG];
export const errorMessages: UploadFileErrorMessage = {
	[UploadFileErrorType.FileSize]: 'The file exceeds the 10MB limit. Please upload a smaller photo.',
	[UploadFileErrorType.FileType]: 'Invalid file format. Please upload a PNG or JPEG (JPG) file.',
};

export const tooltipMessage = 'Ensure your photo is less than 10MB and in either PNG or JPEG (JPG) format.';
