export enum SortFieldTypes {
	Id = 'id',
	Name = 'name',
}

export enum SortDirectionTypes {
	Ascending = 'asc',
	Descending = 'desc',
}

export interface SortType {
	field: SortFieldTypes;
	direction: SortDirectionTypes;
}

export const SortFieldMap = {
	[SortFieldTypes.Id]: 'Test ID',
	[SortFieldTypes.Name]: 'Test Name',
};

export const initialSortValue: SortType = {
	field: SortFieldTypes.Name,
	direction: SortDirectionTypes.Ascending,
};