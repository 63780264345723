import {DisplayResults, ChangeFunction} from '../../../service/types';
import {SettingsPanel, ToggleGroup, Option} from '@esgi/main/kits/reports';
import {useEffect} from 'react';

interface Props {
	value?: DisplayResults;
	disabled?: boolean;
	onChange: ChangeFunction<DisplayResults>;
}

const periods: Option[] = [
	{id: DisplayResults.Score, label: 'Score'},
	{id: DisplayResults.Percent, label: 'Percent'},
];

export function DisplayResultsControl({value, disabled, onChange}: Props) {
	useEffect(() => {
		if (typeof value === 'undefined') {
			onChange(DisplayResults.Score);
		}
	}, [value, onChange]);

	return (
		<SettingsPanel title='Display Results As'>
			<ToggleGroup
				value={value}
				options={periods}
				disabled={disabled}
				onChange={(value) => onChange(value as DisplayResults)}
				dataCy='display-results-as-toggle-group'
			/>
		</SettingsPanel>
	);
}
