import React from 'react';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {ArrowRightIcon} from '@esgillc/ui-kit/icons';

interface Props {
	onClick: () => void;
}

export function ViewAll({onClick}: Props) {
	return (
		<div className={styles.container}>
			<Buttons.Link className={styles.button} onClick={onClick}>View all students</Buttons.Link>
			<ArrowRightIcon onClick={onClick}/>
		</div>
	);
}
