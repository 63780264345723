import {Button} from '@esgi/ui';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	alignItems: 'center',
	gap: '$3',
});

export const PageInfoContainer = styled(GridBox, {
	columnGap: '$3',
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
});

export const PageInfoBox = styled(GridBox, {
	columnGap: 6,
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	alignItems: 'center',
});

export const Dot = styled(Box, {
	width: 1,
	height: 1,
	backgroundColor: '$muted',
	borderRadius: '50%',
});

export const ArrowButton = styled(Button, {
	minWidth: 'unset',
	width: 32,
	height: 32,
	alignItems: 'center',
	justifyContent: 'center',
	padding: 0,

	'& > svg:last-child': {
		margin: 0,
	},

	variants: {
		buttonType: {
			previous: {
				'& > svg:last-child': {
					transform: 'rotate(-180deg)',
				},
			},
		},
	},
});
