import {TestModel} from '../../../../../types';
import {tabItems} from '../constants';
import {TabItem} from '../../tabs/types';
import {TestSectionTabId} from '../../../types';
import {TestType} from '@esgi/main/libs/core';
import {useMemo} from 'react';

function mapTestSectionTabToTestType(tabId: TestSectionTabId) {
	switch (tabId) {
		case TestSectionTabId.YesNo:
			return TestType.YN;
		case TestSectionTabId.Rubric:
			return TestType.Rubric;
		case TestSectionTabId.SingleScore:
			return TestType.Score;
		case TestSectionTabId.All:
		default:
			return null;
	}
}

export function useTabItems(tests: TestModel[]) {
	return useMemo(
		() =>
			tabItems.map<TabItem<TestSectionTabId> | null>((t) => {
				if (!tests.length) {
					return null;
				}

				if (t.value === TestSectionTabId.All) {
					return t;
				}
				return {
					value: t.value,
					label: t.label,
					disabled: !tests.filter((item) => item.testInfo.type === mapTestSectionTabToTestType(t.value)).length,
					dataCy: t.labelDataCy,
				} as TabItem<TestSectionTabId>;
			}),
		[tests],
	);
}
