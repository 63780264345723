import IRetryOptions = JQuery.jqXHR.IRetryOptions;
import jqXHR = JQuery.jqXHR;
import $ from 'jquery';

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    jqXHR.retry = function (opts) {
        opts = opts || {} as IRetryOptions;
        if (opts.timeout) {
            this.timeout = opts.timeout;
        }
        if (opts.statusCodes) {
            this.statusCodes = opts.statusCodes;
        }
        return this.pipe(null, pipeFailRetry(this, opts));
    };
});

// generates a fail pipe function that will retry `jqXHR` `times` more times
function pipeFailRetry(jqXHR:jqXHR, opts:IRetryOptions) {
    let timeout = jqXHR.timeout || 3000;
    const statusCodes = jqXHR.statusCodes || [0];

    // takes failure data as input, returns a new deferred
    return function (input:any, status:any, msg:any) {
        const ajaxOptions = this;
        const output = $.Deferred();
        const retryAfter = jqXHR.getResponseHeader('Retry-After');

        // whenever we do make this request, pipe its output to our deferred
        function nextRequest() {
            $.ajax(ajaxOptions)
                .retry({timeout: opts.timeout, statusCodes: opts.statusCodes})
                .pipe(output.resolve, output.reject);
        }

        if (!statusCodes || $.inArray(input.status, statusCodes) > -1) {
            // implement Retry-After rfc
            // http://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.37
            if (retryAfter) {
                // it must be a date
                if (Number.isNaN(Number.parseInt(retryAfter))) {
                    timeout = new Date(retryAfter).getTime() - $.now();
                    // its a number in seconds
                } else {
                    timeout = parseInt(retryAfter, 10) * 1000;
                }
                // ensure timeout is a positive number
                if (isNaN(timeout) || timeout < 0) {
                    timeout = jqXHR.timeout;
                }
            }

            if (timeout !== undefined) {
                setTimeout(nextRequest, timeout);
            } else {
                nextRequest();
            }
        } else {
            // no times left, reject our deferred with the current arguments
            output.rejectWith(this, arguments);
        }

        return output;
    };
}
