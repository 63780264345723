import {EmptyList} from './components/empty-list';
import {TestList} from './components/test-list';
import {SkeletonList} from './index.styled';
import {ReportTest, Test, TestSession} from './types';
import {useTestsControl} from './hooks';
import {Skeletonable} from '@esgi/ui';
import {ExpandableTestList} from './components/expandable-test-list';

interface Props extends Skeletonable {
	tests: ReportTest[] | Test[] | null;
	disabled?: boolean;
	onChange: (value: Test['id'][]) => void;
	withExpandableListItem?: boolean;
	onChangeTestsData?: (testID: number, session: TestSession) => void;
	hasSelectedStudents?: boolean,
	defaultSelectedTestIDs?: number[],
	emptyListMessage?: string;
}

export function TestsControl({
	skeleton,
	tests,
	onChange,
	withExpandableListItem,
	onChangeTestsData,
	hasSelectedStudents,
	defaultSelectedTestIDs,
	emptyListMessage = 'There\'s no test data for the student',
}: Props) {
	const {items, selected, toggleSelected, ...props} = useTestsControl({tests, onChange, defaultSelectedTestIDs});

	const commonProps = {
		tests: items,
		selectedTests: selected,
		toggleTests: toggleSelected,
		...props,
	};

	if (skeleton) {
		return <SkeletonList/>;
	}

	if (!tests?.length) {
		return <EmptyList content={emptyListMessage} hasSelectedStudents={hasSelectedStudents}/>;
	}

	if (withExpandableListItem) {
		return <ExpandableTestList hasSelectedStudents={hasSelectedStudents} onChangeTestsData={onChangeTestsData} {...commonProps}/>;
	}

	return <TestList {...commonProps}/>;
}
