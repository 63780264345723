import React from 'react';
import {EventBusManager} from '@esgillc/events';
import {FilterType} from '../../../enums';
import {TestExplorerBackEvent} from '../../../events';
import {DataService, INotedSeriesModel} from '../../../services/data-service';
import {RemoveFilterEvent} from '../../header/search-tags/search-tags';
import {AuthorClicked} from '../../noted-author/events';
import {BoxItem} from '../box-item/box-item';
import {Box} from '../box/box';
import {Loading} from '../enums';
import {ClearAllEvent} from '../events';

class State {
	notedSeries: INotedSeriesModel[] = [];
	selected: { id: number, name: string } = null;
	loading: Loading = Loading.NotLoaded;
}

class Props {
	dataService: DataService;
	hasChanged: (notedSeries: INotedSeriesModel | null) => any;
}

export class NotedSeries extends React.Component<Props, State> {
	private readonly eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	componentWillUnmount() {
		this.eventBus.destroy();
	}

	render() {
		return <Box title='Noted Series'
		            collapsible={true}
		            onOpened={() => this.onBoxOpened()}
		            cleared={() => this.changed(null)}
		            canBeCleared={!!this.state.selected}
		            filterType={FilterType.NotedSeries}
		            loading={this.state.loading === Loading.Loading}>
			{this.state.notedSeries.map(g => {
				return <BoxItem key={g.name} selected={this.state.selected && this.state.selected.id === g.id}
				                title={g.name}
				                onSelect={() => this.changed(g)}/>;
			})}
		</Box>;
	}

	changed(ns) {
		this.setState({selected: ns ? {id: ns.id, name: ns.name} : null}, () => {
			this.props.hasChanged(ns);
		});
	}

	onBoxOpened() {
		if (this.state.loading === Loading.NotLoaded) {
			this.setState({loading: Loading.Loading}, () =>
				this.props.dataService.notedSeries.subscribe(r =>
					this.setState({loading: Loading.Loaded, notedSeries: r}),
				));
		}
	}

	componentDidMount(): void {
		this.eventBus.subscribe(AuthorClicked, () => {
			this.changed(null);
		});

		this.eventBus.subscribe(ClearAllEvent, () => {
			this.changed(null);
		});

		this.eventBus.subscribe(RemoveFilterEvent, (args) => {
			if (args.type === FilterType.NotedSeries) {
				this.changed(null);
			}
		});

		this.eventBus.subscribe(TestExplorerBackEvent, () => {
			this.setState({selected: null});
		});
	}
}
