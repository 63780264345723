import {CheckAll, CorrectAnswer, InCorrectAnswer, Sort} from '@esgi/ui/icons';
import {TestListProps} from '../../../../types';
import {Text} from '@esgi/ui/typography';
import {Wrapper, ButtonIcon, ButtonText, TagList, Tag, SortTable, Container, OpenListButton} from './index.styled';
import {useMemo} from 'react';
import {useUser} from '@esgi/core/authentication';
import {Tooltip} from '@esgi/ui';
import {TagListModel} from './types';

interface Props extends Pick<TestListProps, 'sort' | 'onSort' | 'onSelectAll'> {
	setListOpen: (v: boolean) => void;
	isListOpen: boolean;
	isListOpenDisabled: boolean;
}

export function ActionPanel({sort, onSort, onSelectAll, setListOpen, isListOpen, isListOpenDisabled}: Props) {
	const currentUser = useUser();

	const tagItems = useMemo<TagListModel[]>(
		() => [
			{
				tagType: 'green',
				content: <CorrectAnswer />,
				tooltipText: currentUser?.testResultsCorrectVerbiage ?? 'Correct',
			},
			{
				tagType: 'red',
				content: <InCorrectAnswer />,
				tooltipText: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect',
			},
			{
				content: (
					<Text size='xxSmall' font='mono' color='lowContrast'>
						NT
					</Text>
				),
				tooltipText: 'Not Tested',
			},
		],
		[currentUser],
	);

	return (
		<Wrapper>
			<ButtonIcon data-cy='check-all-tests-button' onClick={onSelectAll}>
				<CheckAll />
			</ButtonIcon>
			<ButtonText data-cy='sort-tests-button-root' onClick={onSort}>
				<Text data-cy='sort-tests-label' size='small'>Test Name</Text>
				<SortTable data-cy='sort-tests-direction' field={sort.field} direction={sort.direction} />
			</ButtonText>

			<Container>
				<TagList data-cy='tag-list'>
					{tagItems.map(({content, tooltipText, tagType}) => (
						<Tooltip>
							<Tooltip.Trigger>
								<Tag type={tagType}>{content}</Tag>
							</Tooltip.Trigger>
							<Tooltip.Content variant='secondary'>
								<Text size='medium'>{tooltipText}</Text>
							</Tooltip.Content>
						</Tooltip>
					))}
				</TagList>

				<OpenListButton onClick={() => setListOpen(!isListOpen)} disabled={isListOpenDisabled}>
					<Sort width={24} height={24} transform={isListOpen ? 'rotate(0)' : 'rotate(180)'} />
				</OpenListButton>
			</Container>
		</Wrapper>
	);
}
