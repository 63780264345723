import {useMemo} from 'react';
import {Select, OptionItem} from '@esgi/main/features/teacher/home';
import {useValue} from '../../../../hooks';
import {ChangeFunction} from '../../../../types';
import {ClassType} from '../../../types';
import {AllOptionItem} from '../types';

type OptionType = ClassType['id'] | AllOptionItem.Value;

interface Props {
	classes: ClassType[];
	onChange: ChangeFunction<OptionType>;
}

export function ClassSelect({classes, onChange}: Props) {
	const [classId, setClassId] = useValue<OptionType>(AllOptionItem.Value, onChange);

	const items = useMemo<OptionItem[]>(
		() => [
			{
				value: AllOptionItem.Value,
				label: AllOptionItem.Label,
			},
			...classes.map<OptionItem>(({id, name}) => ({value: String(id), label: name})),
		],
		[classes],
	);

	return (
		<Select
			placeholder='Class'
			items={items}
			selectedValue={String(classId)}
			onValueChange={(value) => setClassId(value === AllOptionItem.Value ? value : Number(value))}
		/>
	);
}
