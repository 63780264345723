import {useServiceFactory} from '@esgi/core/service';
import {FilterSpecialistService} from '../../../../services/filter-specialist-service';
import {StudentCredentialsSpecialistService} from '../../../../services/student-credentials-specialist-service';
import {FiltersSpecialist} from '../../../filters/filters-specialist';
import {StudentCredentialsContent} from '../student-credentials-content';
import {useEffect} from 'react';
import {StudentCardsService} from 'pages/assignment-center/services/service';

interface Props {
	setInitialized: (value: boolean) => void;
	onGoBack: VoidFunction;
	setCountStudentsWithoutCredentials: (value: number) => void;
	setShowMissingStudentsAlerts: (value: boolean) => void;
	credentialsService: StudentCardsService;
}

export function StudentCredentialsSpecialist({setInitialized, ...props}: Props) {
	const service = useServiceFactory(() => {
		const filterTeacherService = new FilterSpecialistService();

		return new StudentCredentialsSpecialistService(filterTeacherService);
	});

	useEffect(() => {
		service.init().subscribe(() => setInitialized(true));
	}, []);

	return (
		<>
			<FiltersSpecialist service={service} />
			<StudentCredentialsContent service={service} {...props} />
		</>
	);
}
