import {Fragment} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Buttons} from '@esgillc/ui-kit/button';
import {CameraIcon} from '@esgillc/ui-kit/icons';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {BreadcrumbModel, Library} from '../../models';
import {Search, State as SearchState} from '../search/search';
import {Sort, State as SortState} from '../sort/sort';
import styles from './styles.module.less';

export class State {
	sort: SortState = new SortState();
	search: SearchState = new SearchState();
	breadcrumb: BreadcrumbModel[] = [];
}

export class Props extends SharedProps<State> {
	library: Library;
	back: () => any;
	manageModeClicked: () => any;
	searchClicked: () => any;
	sortChanged: (m) => any;
	uploadImagesClicked: () => any;
	navigateToClicked: (id) => any;
	loading: boolean;
}

export class Header extends SharedComponent<State, Props> {
	public get editingEnable() {
		return this.props.library === Library.Private;
	}

	public render(): JSX.Element | false | null {
		return <>
			<div className='header'>
				<div className='row'>
					<div className='col-xs-3'>
						<div className='back'>
							{!!this.state.breadcrumb.length &&
							<a href='#' onClick={() => this.props.back()}>
								<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12'
								     viewBox='0 0 8 12'
								     fill='none'>
									<path d='M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z'
									      fill='#0088CC'/>
								</svg>
								Back</a>}
						</div>
					</div>
					<div className='col-xs-6'>
						<h3 className='text-center'>
							Image Gallery
						</h3>
					</div>
					<div className='col-xs-3'/>
				</div>
				<div className='row'>
					<div className='col-xs-3'/>
					<div className='col-xs-6'>
						<Search search={() => !this.props.loading && this.props.searchClicked()}
						        state={this.state.search}
						        onChange={(ch, cb) => this.setState({search: ch}, cb)}/>
					</div>
					<div className='col-xs-3 right-center'>
						{this.editingEnable &&
						<OnHoverTooltip message={this.props.loading ? 'Wait for loading' : ''}>
							<Buttons.Link
								className={styles.addImage}
								onClick={() => !this.props.loading && this.props.uploadImagesClicked()}
							>
								<CameraIcon/>
								Add Image
							</Buttons.Link>
						</OnHoverTooltip>
						}
					</div>
				</div>
			</div>
			<div className='sub-header'>
				<div className='actions'>
					<div className='breadcrumbs'>
						{this.state.breadcrumb.map((b, i, a) => {
							const isLastItem = i === (a.length - 1);
							return <Fragment key={i}><a className={'clickable ' + (isLastItem ? 'last' : 'item')}
							            onClick={() => !isLastItem && this.props.navigateToClicked(b.id)}>{b.name}</a>{!isLastItem ? ':' : ''}</Fragment>;
						})}
					</div>
				</div>
				<div className='actions'>
					{this.editingEnable &&
					<div className='manage-button' onClick={() => this.props.manageModeClicked()}>
						<div className='icon'>
							<svg width='16' height='16' viewBox='0 0 16 16' fill='none'
							     xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M13.95 8.78C13.98 8.53 14 8.27 14 8C14 7.73 13.98 7.47 13.94 7.22L15.63 5.9C15.78 5.78 15.82 5.56 15.73 5.39L14.13 2.62C14.03 2.44 13.82 2.38 13.64 2.44L11.65 3.24C11.23 2.92 10.79 2.66 10.3 2.46L10 0.34C9.97001 0.14 9.80001 0 9.60001 0H6.40001C6.20001 0 6.04001 0.14 6.01001 0.34L5.71001 2.46C5.22001 2.66 4.77001 2.93 4.36001 3.24L2.37001 2.44C2.19001 2.37 1.98001 2.44 1.88001 2.62L0.280007 5.39C0.180007 5.57 0.220008 5.78 0.380008 5.9L2.07001 7.22C2.03001 7.47 2.00001 7.74 2.00001 8C2.00001 8.26 2.02001 8.53 2.06001 8.78L0.370007 10.1C0.220007 10.22 0.180007 10.44 0.270007 10.61L1.87001 13.38C1.97001 13.56 2.18001 13.62 2.36001 13.56L4.35001 12.76C4.77001 13.08 5.21001 13.34 5.70001 13.54L6.00001 15.66C6.04001 15.86 6.20001 16 6.40001 16H9.60001C9.80001 16 9.97001 15.86 9.99001 15.66L10.29 13.54C10.78 13.34 11.23 13.07 11.64 12.76L13.63 13.56C13.81 13.63 14.02 13.56 14.12 13.38L15.72 10.61C15.82 10.43 15.78 10.22 15.62 10.1L13.95 8.78ZM8.00001 11C6.35001 11 5.00001 9.65 5.00001 8C5.00001 6.35 6.35001 5 8.00001 5C9.65001 5 11 6.35 11 8C11 9.65 9.65001 11 8.00001 11Z'
									fill='#0088CC'/>
							</svg>
						</div>
						<span className='type clickable'>
                                    Manage Images
                                </span>
					</div>}
					<Sort state={this.state.sort}
					      sortChanged={(m) => this.props.sortChanged && this.props.sortChanged(m)}
					      onChange={(ch) => this.setState({sort: ch})}/>
				</div>
			</div>
		</>;
	}
}
