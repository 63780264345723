import React from 'react';
import {isIpad, join} from '@esgillc/ui-kit/utils';
import AudioButton from '../audio-button/audio-button';
import styles from './option.module.less';
import {getTextStyles, getUrl} from '../utils';

interface BaseOptionProps {
	type: 'Vertical' | 'Horizontal'
	selected?: boolean;
	clicked: () => any;
	audioPath?: string;
	height?: number;
	multiselect?: boolean;
	text?: string;
	picUrl?: string;
	className?: string;
	disabled?: boolean;
	id: number;
	disableAudio?: boolean;
	bucket: string;
	customize?: { optionClassName?: string; actionClassName?: string; audiBtnClassname?: string };
	fontSize?: number;
	lineHeight?: number;
}

export class BaseOption extends React.Component<BaseOptionProps> {
	private get type() {
		return this.props.type === 'Horizontal' ? styles.horizontal : styles.vertical;
	}

	private get selected() {
		return this.props.selected ? styles.selected : '';
	}

	private get disabled() {
		return this.props.disabled ? styles.disabled : '';
	}

	private optionClass() {
		let customize = '';
		if (this.props.customize && this.props.customize.optionClassName) {
			customize = this.props.customize.optionClassName;
		}

		return join(styles.option, this.selected, this.disabled, customize);

	}

	private actionClass() {
		let customize = '';
		if (this.props.customize && this.props.customize.actionClassName) {
			customize = this.props.customize.actionClassName;
		}

		return join(styles.action, customize);
	}

	render() {
		return (
			<div className={join(styles.optionBox, this.type, this.props.className)}>
				{this.renderAudioButton()}
				<div className={this.optionClass()}
				     onClick={this.props.clicked}>
					<div className={this.actionClass()}>{this.renderCheckBox()}</div>
					{this.renderText()}
					{this.renderImg()}
				</div>
			</div>
		);
	}

	private renderImg() {
		if (!this.props.picUrl) {
			return null;
		}
		const url = getUrl(this.props.bucket, this.props.picUrl);
		return (
			<div className={styles.imgContainer}>
				<img
					style={
						this.props.height
							? {
									height: this.props.height + 'px',
									maxHeight: this.props.height + 'px',
							  }
							: undefined
					}
					src={url}
				/>
			</div>
		);
	}

	private renderText() {
		if (!this.props.text) {
			return null;
		}
		const {fontSize: defaultFontSize, lineHeight: defaultLineHeight} = getTextStyles(this.props.text);
		const fontSize = this.props.fontSize ||defaultFontSize
		const lineHeight = this.props.lineHeight || defaultLineHeight
		return <span className={styles.textContainer} style={isIpad() ? {fontSize, lineHeight: lineHeight + 'px'} : undefined}
		             dangerouslySetInnerHTML={{__html: this.props.text}}/>;
	}

	private renderCheckBox() {
		if (!this.props.multiselect) {
			return <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='27' cy='27' r='24' stroke='#828282' strokeWidth='6'/>
			</svg>;
		}
		if (this.props.selected) {
			return <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 0H44V44H0V0Z' fill='white'/>
				<path
					d='M34.8333 5.5H9.16667C7.13167 5.5 5.5 7.15 5.5 9.16667V34.8333C5.5 36.85 7.13167 38.5 9.16667 38.5H34.8333C36.8683 38.5 38.5 36.85 38.5 34.8333V9.16667C38.5 7.15 36.8683 5.5 34.8333 5.5ZM18.3333 31.1667L9.16667 22L11.7517 19.415L18.3333 25.9783L32.2483 12.0633L34.8333 14.6667L18.3333 31.1667Z'
					fill='#0088CC'/>
			</svg>;
		}

		return <svg className={styles.checkBox} width='44' height='44' viewBox='0 0 44 44' fill='none'
		            xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M39.1111 4.88889V39.1111H4.88889V4.88889H39.1111ZM39.1111 0H4.88889C2.2 0 0 2.2 0 4.88889V39.1111C0 41.8 2.2 44 4.88889 44H39.1111C41.8 44 44 41.8 44 39.1111V4.88889C44 2.2 41.8 0 39.1111 0Z'
				fill='#828282'/>
		</svg>;
	}

	private renderAudioButton() {
		if (!this.props.audioPath) {
			return null;
		}
		const url = getUrl(this.props.bucket, this.props.audioPath);
		return <AudioButton disabled={this.props.disableAudio} className={join(styles.audioBtn, this.props.customize?.audiBtnClassname)} id={this.props.id}
		                    audioUrl={url}/>;
	}
}

export default BaseOption;
