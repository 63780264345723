import React from 'react';
import {NamingWritingPrintSettings, StudentStepState, TemplateType} from '../../shared/types';
import StudentsStep from '../students/students-step';
import NameWritingSettingsStep from '../name-writing-settings/name-writing-settings-step';
import NameWritingPreviewStep from '../name-writing-preview/name-writing-preview-step';
import FinalStep from '../final/final-step';
import {NameWritingSteps} from './types';
import {NameWritingReport} from '../../shared/templates/name-writing/types';
import {HierarchySnapshot} from 'modules/hierarchy/models';

interface Props {
	hierarchy: HierarchySnapshot;
	goToBack: () => void;
	close: () => void;
}

class State {
	currentStep: NameWritingSteps = NameWritingSteps.Students;
	studentState: StudentStepState;
	printSettingsState: NamingWritingPrintSettings;
	report: NameWritingReport;
}

export class NameWriting extends React.PureComponent<Props, State> {
	private readonly totalStepsCount: number = 2;
	public override readonly state = new State();

	render() {
		return <>
			{
				this.state.currentStep === NameWritingSteps.Students &&
				<StudentsStep
					currentStep={1}
					totalStepsCount={this.totalStepsCount}
					hierarchy={this.props.hierarchy}
					selectedClassId={this.state.studentState?.classId}
					selectedGroupId={this.state.studentState?.groupId}
					selectedStudentIds={this.state.studentState?.students?.map(x => x.studentId)}
					goToNext={(data) => this.studentNext(data)}
					goToBack={this.props.goToBack}/>
			}
			{
				this.state.currentStep === NameWritingSteps.PrintSettings &&
				<NameWritingSettingsStep
					currentStep={2}
					totalStepsCount={this.totalStepsCount}
					unitData={this.state.studentState}
					printData={this.state.printSettingsState}
					goToBack={(data) => this.printSettingsBack(data)}
					goToPreview={(data, report) => this.printSettingsPreview(data, report)}
					pdfDownloaded={(report) => this.pdfDownloaded(report)}/>
			}
			{
				this.state.currentStep === NameWritingSteps.Preview &&
				<NameWritingPreviewStep
					totalStepsCount={this.totalStepsCount}
					currentStep={2}
					report={this.state.report}
					goToBack={() => this.previewBack()}
					pdfDownloaded={() => this.pdfDownloaded(this.state.report)}/>
			}
			{
				this.state.currentStep === NameWritingSteps.Final &&
				<FinalStep
					goToBack={() => this.finalBack()}
					closeForm={this.props.close}
					templateType={TemplateType.NameWriting}
					nwReport={this.state.report}
					swReport={null}/>
			}
		</>;
	}

	private studentNext(data: StudentStepState) {
		this.setState({
			currentStep: NameWritingSteps.PrintSettings,
			studentState: data,
		});
	}

	private printSettingsBack(data: NamingWritingPrintSettings) {
		this.setState({
			currentStep: NameWritingSteps.Students,
			printSettingsState: data,
		});
	}

	private printSettingsPreview(data: NamingWritingPrintSettings, report: NameWritingReport) {
		this.setState({
			currentStep: NameWritingSteps.Preview,
			printSettingsState: data,
			report: report,
		});
	}

	private pdfDownloaded(report: NameWritingReport) {
		this.setState({
			currentStep: NameWritingSteps.Final,
			report: report,
		});
	}

	private previewBack() {
		this.setState({currentStep: NameWritingSteps.PrintSettings});
	}

	private finalBack() {
		this.setState({currentStep: NameWritingSteps.PrintSettings});
	}
}
