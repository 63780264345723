import React from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onClose: () => void
}

export function IsTruncatedDialog({onClose}: Props): JSX.Element {
	const modalManager = useModal();
	const close = useCloseModal(modalManager, onClose);

	return <Alert modalManagerRef={modalManager}>
		<Alert.Body>
			Due to web limitations, we have only shown the first 20 tests in your subject tab.
			The score for Average Growth does include the results from all tests in your subject tab, even if they are not showing.
			Exporting to Excel will display all tests.
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={close}>
				Ok
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
