import {ContentBox, UserNameBox} from '../../../components/content-box.styled';
import {DisabledInput} from '../../../components/disabled-input';
import {nameTitleOptions} from './constants';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {NameTitle, SavePersonalDataBody, UserCountry, UserPhoto, UserState} from '../../../../../types';
import {GridBox} from '@esgi/ui/layout';
import {ObservableBuilder} from '@esgi/api';
import {PanelContent} from '../../../components/panels.styled';
import {Drawer} from '@esgi/main/kits/common';
import {PanelHeaderTitle} from '../../../components/panel-header-title';
import {ExpirationDate} from '../../../../expiration-date';
import {Logout} from '../../../../logout';
import {CroppedImage, generateRandomString, isUndefined, useStreamEffect} from '@esgi/ui';
import {isNull} from 'underscore';
import {NoAvatar} from '../user-avatar-variant/no-avatar';
import {WithAvatar} from '../user-avatar-variant/with-avatar';
import {getPhotoData} from './helpers/get-photo-data';
import {isPhotoAndControlledAvatarEqual} from './helpers/is-photo-and-controlled-avatar-equal';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ErrorTooltip, Form, FormControl, FormElement, FormGroup, Validators, useForm} from '@esgi/ui/form';
import {Input} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {FormElementSelect} from '../../../../form-element-select';
import {FormElementSelectOption} from '../../../../form-element-select/types';
import {getAdaptedStates} from './helpers/get-adapted-states';

type Props = {
	firstName: string;
	lastName: string;
	email: string;
	nameTitle: NameTitle;
	countryId: UserCountry['countryID'];
	countries: UserCountry[];
	stateId: UserState['stateID'];
	states: UserState[];
	getStatesByCountryID: (countryID: number) => ObservableBuilder<UserState[]>;
	savePersonalData: (body: SavePersonalDataBody) => void;
	photo: UserPhoto | null;
	withRenewButton: boolean;
	expirationDate: Date;
	isUserDataLoading: boolean;
	canAllowRenewByCC: boolean;
};

export function PersonalInformationContent({
	firstName: initialFirstName,
	lastName: initialLastName,
	email,
	nameTitle: initialNameTitle,
	countryId: initialCountryID,
	stateId: initialStateID,
	countries,
	states,
	getStatesByCountryID,
	savePersonalData,
	photo,
	expirationDate,
	withRenewButton,
	isUserDataLoading,
	canAllowRenewByCC,
}: Props) {
	const [isFormValid, setIsFormValid] = useState(true);
	const [isFormTouched, setIsFormTouched] = useState(false);

	const [controlledUserAvatar, setControlledUserAvatar] = useState<CroppedImage | null>(null);

	const [selectStatesKey, setSelectStatesKey] = useState(generateRandomString());

	const updateOptionStatesKey = useCallback(() => {
		setSelectStatesKey(generateRandomString());
	}, []);

	const [statesByCountryID, setStatesByCountryID] = useState<Record<string, FormElementSelectOption[]>>({
		[String(initialCountryID)]: getAdaptedStates(states),
	});

	const form = useForm(
		() =>
			new FormGroup({
				nameTitle: new FormControl([initialNameTitle]),
				firstName: new FormControl(initialFirstName, {
					validators: [Validators.required()],
				}),
				lastName: new FormControl(initialLastName, {
					validators: [Validators.required()],
				}),
				countryID: new FormControl([String(initialCountryID)]),
				stateID: new FormControl([String(initialStateID)]),
			}),
	);

	useEffect(() => {
		setControlledUserAvatar(
			isNull(photo)
				? null
				: {
						image: photo.imageUrl,
						crop: photo.crop,
						croppedImage: photo.imageCropUrl,
				  },
		);
	}, [photo]);

	const adaptedCountryList = useMemo(
		() =>
			countries.map<FormElementSelectOption>(({countryID, name}) => ({
				label: name,
				value: String(countryID),
			})),
		[countries],
	);

	useStreamEffect(form.controls.countryID.onChanged, ({currState: {value: currentCountryIDValue, status}, reason}) => {
		const currentCountryID = currentCountryIDValue[0];

		if (isUndefined(currentCountryID) || reason !== 'value') {
			return;
		}

		const stateFromCache = statesByCountryID[currentCountryID];

		if (!isUndefined(stateFromCache)) {
			const firstState = stateFromCache[0];

			form.controls.stateID.value = firstState ? [firstState.value] : [];
			updateOptionStatesKey();

			return;
		}

		getStatesByCountryID(Number(currentCountryID)).subscribe({
			next: (states) => {
				setStatesByCountryID((currentState) => ({
					...currentState,
					[currentCountryID]: getAdaptedStates(states),
				}));

				const firstState = states[0];

				form.controls.stateID.value = firstState ? [String(firstState.stateID)] : [];
				updateOptionStatesKey();
			},
		});
	});

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {nameTitle, firstName, lastName, countryID, stateID},
			},
		}) => {
			setIsFormTouched(
				nameTitle[0] !== initialNameTitle ||
					firstName !== initialFirstName ||
					lastName !== initialLastName ||
					Number(countryID[0]) !== initialCountryID ||
					Number(stateID[0]) !== initialStateID,
			);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		},
	);

	const handleSave = () => {
		const {newPhoto} = getPhotoData({
			currentPhoto: photo,
			newPhoto: controlledUserAvatar,
		});

		form.validate().subscribe(({valid}) => {
			const {firstName, lastName, stateID, nameTitle, countryID} = form.value;

			if (valid && stateID[0] && nameTitle[0] && countryID[0]) {
				savePersonalData({
					firstName,
					lastName,
					stateID: Number(stateID[0]),
					title: nameTitle[0],
					countryID: Number(countryID[0]),
					newPhoto,
				});

				setIsFormTouched(false);
			}
		});
	};

	return (
		<>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={
					!isFormValid ||
					(!isFormTouched && isPhotoAndControlledAvatarEqual({photo, controlledUserAvatar})) ||
					isUserDataLoading
				}
			>
				<PanelHeaderTitle title='Personal Information' />
			</Drawer.PanelHeader>
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						<Drawer.ContentBlock title='Profile Picture'>
							<GridBox dataCy='profile-picture-box' justify='center'>
								{isNull(controlledUserAvatar) ? (
									<NoAvatar setUserAvatar={setControlledUserAvatar} />
								) : (
									<WithAvatar setUserAvatar={setControlledUserAvatar} userAvatar={controlledUserAvatar} />
								)}
							</GridBox>
						</Drawer.ContentBlock>

						<Form controller={form}>
							<Drawer.ContentBlock title='Name'>
								<UserNameBox>
									<FormElementSelect
										controlElement={form.controls.nameTitle}
										items={nameTitleOptions}
										placeholder='Title'
										dataCy='user-title-select'
									/>

									<FormElement control={form.controls.firstName}>
										<Input.Base placeholder='First Name' dataCy='first-name-input' />

										<ErrorTooltip showOnError='required'>
											<Text size='xSmall' font='mono' color='negativeVivid'>
												First Name is required
											</Text>
										</ErrorTooltip>
									</FormElement>

									<FormElement control={form.controls.lastName}>
										<Input.Base placeholder='Last Name' dataCy='last-name-input' />

										<ErrorTooltip showOnError='required'>
											<Text size='xSmall' font='mono' color='negativeVivid'>
												Last Name is required
											</Text>
										</ErrorTooltip>
									</FormElement>
								</UserNameBox>
							</Drawer.ContentBlock>

							<Drawer.ContentBlock title='Location'>
								<GridBox dataCy='location-box' columns='2' gap='3'>
									<FormElementSelect
										controlElement={form.controls.countryID}
										items={adaptedCountryList}
										placeholder='Country'
										dataCy='location-country-select'
									/>

									<FormElementSelect
										controlElement={form.controls.stateID}
										items={form.value.countryID[0] ? statesByCountryID[form.value.countryID[0]] ?? [] : []}
										placeholder='State'
										dataCy='location-state-select'
										key={selectStatesKey}
									/>
								</GridBox>
							</Drawer.ContentBlock>
						</Form>

						<Drawer.ContentBlock title='Email'>
							<DisabledInput
								value={email}
								placeholder='Email'
								inputTranscript='To change your email, please contact our Customer Support team or your system administrator.'
								dataCy='email-disabled-input'
							/>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Expiration Date'>
							<ExpirationDate
								date={expirationDate}
								withRenewButton={withRenewButton}
								canAllowRenewByCC={canAllowRenewByCC}
							/>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Terminate Current Session'>
							<Logout />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
