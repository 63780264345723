import {AllTabContent} from './components/all-tab-content';
import {DefaultModeContent} from './components/mode-content-variants/default-mode-content';
import {SubjectSectionRoot} from './components/subject-section-root';

export {useSubjectSectionContext} from './context/subject-section-context';

export const SubjectSection = {
	Root: SubjectSectionRoot,
	AllTabContent,
	DefaultModeContent,
};