import {HttpClient} from '@esgi/api';

interface IResponse {
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	questions: IQuestionResponse[];
}

export function Load(testID: number, testSessionID: number) {
	return HttpClient.default.ESGIApi.get<IResponse>('TestSubset', 'Init', {
		TestID: testID,
		TestSessionID: testSessionID,
	});
}

export interface IQuestionResponse {
	questionID: number;
	name: string;
	answerState: string;
}
