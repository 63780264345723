import {join} from '@esgillc/ui-kit/utils';
import {useEffect, useMemo, useState} from 'react';
import {QuestionModel} from '../../kit/models';
import {PreviewQuestionSlider} from './slider';
import {TestIntro} from './test-intro';
import {TestPreviewModel} from '../models';
import styles from '../styles.module.less';

enum Screen {
	None,
	Intro,
	Slider,
}

type Props = {
	testModel: TestPreviewModel;
	onClose: () => void;
}

export function Preview({testModel, onClose}: Props) {
	const [screen, setScreen] = useState<Screen>(Screen.None);

	const questions = useMemo<QuestionModel[]>(() => {
		return (testModel?.questions || []).map(q => {
			const model = new QuestionModel();
			model.questionID = q.questionID;
			model.direction = q.direction;
			model.directUrl = q.directUrl;
			model.fallbackUrl = q.fallbackUrl;
			return model;
		});
	}, [testModel]);

	useEffect(() => {
		if(testModel.testIntroPage) {
			setScreen(Screen.Intro);
		} else {
			setScreen(Screen.Slider);
		}
	}, [testModel]);

	let content;

	switch (screen) {
		case Screen.Intro: {
			const {testName, testID, isWhiteBackground, testIntroPage} = testModel;
			content = <TestIntro testID={testID}
			                     testName={testName}
			                     isWhiteBackground={isWhiteBackground}
			                     bucketName={testIntroPage.bucketName}
			                     pregenerated={testIntroPage.pregenerated}
			                     modifyTicks={testIntroPage.modifyTicks}
			                     testIntroID={testIntroPage.questionID}
			                     onClose={onClose}
			                     onNext={() => setScreen(Screen.Slider)}
			/>;
			break;
		}
		case Screen.Slider:
			content = <PreviewQuestionSlider questions={questions}
			                                 closeClicked={onClose}
			                                 testName={testModel.testName}
			                                 backClicked={() => setScreen(Screen.Intro)}
			                                 hasTestIntroPage={!!testModel.testIntroPage}
			                                 isWhiteBackground={testModel.isWhiteBackground}/>;
			break;
	}

	return <div className={join(styles.testPreview, testModel?.isWhiteBackground && styles.white)}>
		{content}
	</div>;
}
