import {useState} from 'react';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Checkbox, Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {Buttons} from '@esgillc/ui-kit/button';
import {ElementStatus, Form, FormElement} from '@esgillc/ui-kit/form';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {State as ColorPickerState} from '@esgi/deprecated/elements';
import {AutoTestCreatorFormType} from '../../form';
import {Dictionary, PreviewState, TileState} from '../../types';
import {QuestionContainer} from '../question-container';
import {SaveTestAlert} from '../save-test-alert/index';
import {ContentArea} from '../content-area';
import {Grade} from '../grade/index';
import {Preview} from '../preview';
import {Topic} from '../topic';
import {Name} from '../name';
import {PropertiesTextarea} from '../properties-textarea';
import {maxLengths} from 'shared/modules/auto-test-creator/constants';
import styles from './styles.module.less';

interface Props {
	form: AutoTestCreatorFormType
	dictionary: Dictionary
	isTopicSelected: boolean
	previewState: PreviewState,
	colorPicker: ColorPickerState
	preview: () => void,
	onChangeTopic: (id: number) => void
	onChangePreviewState: (p: TileState[]) => void
	onChangeColor: (c: ColorPickerState) => void
	onClose: () => void;
	onSave: () => void
}

export function TestCreatorModal({
	onChangeColor,
	onChangePreviewState,
	onChangeTopic,
	preview,
	isTopicSelected,
	dictionary,
	onClose,
	form,
	previewState,
	colorPicker,
	onSave,
}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	const [isThumbnailViewButtonVisible, setThumbnailViewButtonVisible] = useState(false);
	const [isThumbnailViewModalOpen, setThumbnailViewModalOpen] = useState(false);
	const [isSaveTestAlertOpen, setSaveTestAlertOpen] = useState(false);


	useStreamEffect(form.controls.questions.onChanged, value => {
		setThumbnailViewButtonVisible(Boolean(value.currState.value.length));
	});

	const onCancel = () => {
		const hasQuestions = Boolean(form.controls.questions.value.length);
		setSaveTestAlertOpen(hasQuestions);
		if (!hasQuestions) {
			handleClose();
		}
	};

	const onSubmit = () => {
		form.validate().subscribe((v) => {
			if (!v.length && !form.controls.invalidQuestionIDs.value.length) {
				if (form.controls.questions.value.length) {
					onSave();
				} else {
					form.controls.questionAdder.status = ElementStatus.invalid;
				}
			}
		});
	};

	const onThumbnailView = () => {
		form.validate().subscribe((v) => {
			if (!v.length && !form.controls.invalidQuestionIDs.value.length) {
				preview();
				setThumbnailViewModalOpen(true);
			}
		});
	};

	return <div data-cy='test-creator-modal'>
		<Modal modalManagerRef={modalRef}>
			<Modal.Header>
				<Title>Auto Test Creator</Title>
				<CloseIcon onClick={onCancel}/>
			</Modal.Header>

			<Modal.Body className={styles.body}>
				<Form controller={form}>
					<div className={styles.testHeader}>
						<Topic control={form.controls.topic} topics={dictionary.topics}
							   onChange={id => onChangeTopic(id)}/>
						<Name control={form.controls.testName}/>
					</div>

					<div className={styles.testBody}>
						<div className={styles.leftColumn}>
							<PropertiesTextarea
								control={form.controls.description}
								title={'Description:'}
								isRequired
								isTopicSelected={isTopicSelected}
								maxLength={maxLengths.description}
								placeholder={`Enter test description (max ${maxLengths.description} characters)`}
								dataCy={'description'}
							/>

							<PropertiesTextarea
								control={form.controls.directions}
								title={'Directions:'}
								isTopicSelected={isTopicSelected}
								maxLength={maxLengths.directions}
								placeholder={`Enter test directions (max ${maxLengths.directions} characters)`}
								message={'Directions will display on every test question during assessment'}
								dataCy={'directions'}
							/>

							<ContentArea control={form.controls.contentArea}
										 colorPicker={colorPicker}
										 contentAreas={dictionary.contentAreas}
										 defaultColors={dictionary.defaultColors}
										 onChangeColor={onChangeColor}
							/>

							<Grade control={form.controls.grade} grades={dictionary.gradeLevels}/>

							<div data-cy='font' className={styles.row}>
								<div className={styles.titleContainer}>
									<label className={styles.title}>Font:</label>
								</div>
								<FormElement className={styles.fontDropdown} control={form.controls.font}>
									<Dropdown optionName='displayName'>
										{dictionary.fonts.map(f => <Option key={f.systemName} className={styles.test}
																		   value={f}>
											<div style={{fontFamily: f.systemName}}>{f.displayName}</div>
										</Option>)}
									</Dropdown>
								</FormElement>
							</div>

							<div data-cy='share-test' className={styles.row}>
								<div className={styles.titleContainer}>
									<label className={styles.title}>Share Test:</label>
								</div>
								<FormElement className={styles.shareTest} control={form.controls.shareTest}>
									<Checkbox>Share with District</Checkbox>
								</FormElement>
							</div>
						</div>
						<QuestionContainer form={form}/>
					</div>
				</Form>

				{isThumbnailViewModalOpen && <Preview
					preview={previewState}
					onChange={onChangePreviewState}
					onSave={onSave}
					onClose={() => setThumbnailViewModalOpen(false)}
				/>}

				{isSaveTestAlertOpen && <SaveTestAlert
					onSave={onSave}
					onClose={handleClose}
					onCancel={() => setSaveTestAlertOpen(false)}
				/>}

			</Modal.Body>
			<Modal.Footer className={styles.footer}>
				<Buttons.Gray onClick={onCancel}> CANCEL </Buttons.Gray>
				<div>{isThumbnailViewButtonVisible &&
					<Buttons.Outlined onClick={onThumbnailView}>Thumbnail View</Buttons.Outlined>}
					<Buttons.Contained onClick={onSubmit}> SAVE AND CLOSE </Buttons.Contained>
				</div>
			</Modal.Footer>
		</Modal>
	</div>;
}
