import {useMemo} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useUser, UserType} from '@esgi/core/authentication';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../hooks';
import {TestSessionRestoreEvent, TestSessionNeedUpdateEvent} from '../../events';
import {dispatchAppEvent} from '@esgillc/events';

interface Props {
	onCancel: () => void;
}

export function FooterMain({onCancel}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const answersLoaded = useBehaviorSubject(service.answersLoaded$);
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);
	const validation = useBehaviorSubject(service.validation$);
	const isSaving = useBehaviorSubject(service.isSaving$);
	const currentUser = useUser();

	const allowRestore = useMemo<boolean>(() => {
		const {userType, userID} = currentUser;
		if (!((userID === currentTestSession.userID) || (userType === UserType.T && currentTestSession.userID === null))) {
			return false;
		}

		return currentTestSession ? currentTestSession.deleted : false;
	}, [currentTestSession]);

	const allowEdit = useMemo<boolean>(() => {
		if (!testSessionDetails) {
			return false;
		}

		const {canEdit} = testSessionDetails;

		if (!canEdit) {
			return false;
		}

		if (allowRestore) {
			// deleted. don't show edit button.
			return false;
		}

		if (editMode) {
			// in edit mode. don't show edit button.
			return false;
		}

		if (!currentTestSession) {
			// null or test sessions is not present.
			return false;
		}

		const {userType, userID} = currentUser;
		if (userType === UserType.T) {
			return userID === currentTestSession.userID || currentTestSession.userID === null;
		}

		return userID === currentTestSession.userID;
	}, [testSessionDetails, allowRestore, currentTestSession, editMode]);

	const saveDisabled = useMemo<boolean>(() => {
		return !validation.valid || isSaving;
	}, [validation, isSaving]);

	const allowSave = useMemo<boolean>(() => {
		if (!testSessionDetails) {
			return false;
		}

		return !allowRestore && testSessionDetails.canEdit && editMode;
	}, [testSessionDetails, allowRestore, editMode]);

	const onEdit = () => {
		service.setEditMode(true);
	};

	const onRestore = () => {
		dispatchAppEvent(TestSessionRestoreEvent, new TestSessionRestoreEvent());
	};

	const onSave = () => {
		dispatchAppEvent(TestSessionNeedUpdateEvent, new TestSessionNeedUpdateEvent());
		service.setSaving(true);
	};

	return (
		<>
			<Buttons.Gray onClick={onCancel}>
				{editMode ? 'Cancel' : 'Close'}
			</Buttons.Gray>
			{allowEdit &&
				<Buttons.Contained disabled={!answersLoaded} onClick={onEdit}>
					Edit
				</Buttons.Contained>
			}
			{allowRestore &&
				<Buttons.Contained onClick={onRestore}>
					Restore
				</Buttons.Contained>
			}
			{allowSave &&
				<Buttons.Contained
					disabled={saveDisabled}
					onClick={onSave}
				>
					Save
				</Buttons.Contained>
			}
		</>
	);
}
