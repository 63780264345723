import {ListGroup, ListGroupProps} from './components/list-group';
import {ListGroupDroppable, ListGroupDroppableProps} from './components/list-group-droppable/list-group-droppable';
import {ListItem, ListItemProps} from './components/list-item';
import {ListItemDraggable, ListItemDraggableProps} from './components/list-item-draggable';

export const List = Object.assign(ListGroup, {
	Droppable: ListGroupDroppable,
	Item: ListItem,
	ItemDraggable: ListItemDraggable,
});

export {type ListGroupProps, type ListItemProps, type ListGroupDroppableProps, type ListItemDraggableProps};
