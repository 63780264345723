import {TrackType} from '../../../../../types';
import {TabItem} from '../../../types';

export const markingPeriodItems: TabItem<TrackType>[] = [
	{
		label: '2',
		value: TrackType.Semester,
	},
	{
		label: '3',
		value: TrackType.Trimester,
	},
	{
		label: '4',
		value: TrackType.Quarter,
	},
	{
		label: '5',
		value: TrackType.Periods5,
	},
	{
		label: '6',
		value: TrackType.Periods6,
	},
	{
		label: '7',
		value: TrackType.Periods7,
	},
	{
		label: '8',
		value: TrackType.Periods8,
	},
	{
		label: '9',
		value: TrackType.Periods9,
	},
];
