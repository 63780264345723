import {Student} from '@esgi/main/libs/store';

export enum ListHeaderCellID {
	Student = 'Student',
	Assignment = 'Assignment',
}

export type StudentModel = Student & {
	joinedAssignmentName: string;
};

export type AssignmentModel = {
	id: number;
	studentsIDs: Student['id'][];
	name: string;
};
