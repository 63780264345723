export function generateRandomString(wordLength?: number): string {
	if (window.isSecureContext && !wordLength) {
		return window.crypto.randomUUID();
	}

	/**
	 *  Math.random().toString(36) return a random string, with always the same the first two letters ("0.")
	 *  example: Math.random().toString(36) => '0.2wveznajmk7'
	 */
	const randomWord = Math.random().toString(36).substring(2);

	if (wordLength && wordLength > randomWord.length) {
		return randomWord + generateRandomString(wordLength - randomWord.length);
	}

	return randomWord.slice(0, wordLength);
}
