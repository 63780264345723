import {TooltipTriggerProps as RadixTooltipTriggerProps, Trigger} from '@radix-ui/react-tooltip';
import {CSS} from '@stitches/react';
import {forwardRef} from 'react';

type TooltipTriggerProps = Omit<RadixTooltipTriggerProps, 'ref'> & {
	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const TooltipTrigger = forwardRef<HTMLButtonElement, TooltipTriggerProps>(
	({asChild = true, children, ...props}, forwardedRef) => {

		return (
			<Trigger
				asChild={asChild}
				ref={forwardedRef}
				{...props}>
				{children}
			</Trigger>
		);
	},
);
