import {styled} from '../theme';
import {generateTextColors} from './generate-text-colors';

export const Text = styled('span', {
	color: '$neutral6',
	font: 'Roboto, "Open Sans", serif',
	variants: {
		color: generateTextColors(),
		size: {
			xxLarge: {
				fontWeight: 700,
				fontSize: 22,
				lineHeight: '36px',
				letterSpacing: -0.1,
				textTransform: 'capitalize',
			},
			xLarge: {
				fontWeight: 700,
				fontSize: 18,
				lineHeight: '20px',
				letterSpacing: -0.1,
				textTransform: 'capitalize',
			},
			large: {
				fontWeight: 500,
				fontSize: 14,
				lineHeight: '20px',
			},
			medium: {
				fontWeight: 500,
				fontSize: 13,
				lineHeight: '16px',
			},
			small: {
				fontWeight: 600,
				fontSize: 11,
				lineHeight: '12px',
				fontVariantNumeric: 'lining-nums proportional-nums stacked-fractions',
			},
			xSmall: {
				fontWeight: 500,
				fontSize: 10,
				lineHeight: '12px',
				letterSpacing: -0.4,
				fontVariantNumeric: 'lining-nums proportional-nums stacked-fractions',
			},
			xxSmall: {
				fontWeight: 500,
				fontSize: 9,
				lineHeight: '10px',
				letterSpacing: -0.2,
				fontVariantNumeric: 'lining-nums proportional-nums stacked-fractions',
			},
		},
		bold: {
			true: {},
		},
		font: {
			mono: {
				fontFamily: 'Roboto Mono, Roboto, serif',
			},
		},
	},
	compoundVariants: [
		{
			size: 'small',
			font: 'mono',
			css: {
				fontWeight: 500,
			},
		},
		{
			size: 'medium',
			font: 'mono',
			css: {
				letterSpacing: 0.2,
				fontVariantNumeric: 'lining-nums proportional-nums stacked-fractions',
			},
		},
		{
			size: 'small',
			bold: true,
			css: {
				fontWeight: 800,
			},
		},
		{
			size: 'medium',
			bold: true,
			css: {
				fontWeight: 600,
			},
		},
		{
			size: 'small',
			font: 'mono',
			bold: true,
			css: {
				fontWeight: 700,
			},
		},
		{
			size: 'medium',
			font: 'mono',
			bold: true,
			css: {
				fontWeight: 700,
				letterSpacing: 0.2,
				fontVariantNumeric: 'lining-nums proportional-nums stacked-fractions',
			},
		},
		{
			size: 'xxSmall',
			font: 'mono',
			bold: true,
			css: {
				fontWeight: 700,
			},
		},
	],
});
