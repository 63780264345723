import React, {useEffect} from 'react';
import {environment} from '@esgi/core/environments';
import {userStorage} from '@esgi/core/authentication';
import {createQuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import styles from './question-tile.module.less';

export interface QuestionTileProps {
	testID: number;
	pregenerated: boolean;
	questionID: number;
	name: string;
}


export default function QuestionTile ({testID, pregenerated, questionID, name}: QuestionTileProps) {
	const urlBuilder = createQuestionImageUrlBuilder();
	const modifyTicks = (new Date()).getTime();
	let img;
	let url;

	const onError = () => {
		if (img.src.includes('s3.amazonaws.com')) {
			return urlBuilder.medium(questionID, testID, false, modifyTicks);
		} else {
			return '/ESGI/Images/150x100.gif';
		}
	};

	useEffect(() => {
		if (!url) {
			url = urlBuilder.medium(questionID, testID, pregenerated, modifyTicks);
		}
	}, []);

	return (
		<div data-cy='question-tile' className={styles.questionTileBox}>
			<img
				ref={ref => img = ref}
				onError={(e) => img.src = onError() }
				src={urlBuilder.medium(questionID, testID, pregenerated, modifyTicks)}
			/>
			<div className={styles.questionShortName}>
				{name}
			</div>
		</div>
	);
}
