import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {createElement, PropsWithChildren} from 'react';

type TextTag = keyof Pick<
	JSX.IntrinsicElements,
	'p' | 'span' | 'h1' | 'h2'
>;

type TextProps<T extends TextTag> = PropsWithChildren<{
	color: 'black' | 'blue' | 'gray';
	size: '12px' | '14px' | '16px' | '20px';
	weight?: '400' | '500';
	as: T;
	className?: string;
	uppercase?: boolean;
}>;

export const Text = <T extends TextTag = TextTag>({
	size,
	color,
	children,
	as,
	weight,
	className,
	uppercase,
}: TextProps<T>) =>
		createElement(
			as,
			{
				className: join(
					styles[`color-${color}`],
					styles[`size-${size}`],
					styles.text,
					weight && styles[`weight-${weight}`],
					uppercase && styles.uppercase,
					className,
				),
			},
			children,
		);
