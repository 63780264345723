import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const AlertHeader = styled(Alert.Header, {
	'& > div': {
		gridTemplateColumns: '1fr auto',
	},
});

export const AllToggleVisiblePasswordsButton = styled(Button.Icon, {
	variants: {
		isAllPasswordsOpened: {
			true: {
				color: '$primary',
			},

			false: {
				color: '$base',
			},
		},
	},
});

export const HeaderDivider = styled(Box, {
	width: 1,
	height: '100%',
	borderRadius: 1,
	backgroundColor: '$border',
});
