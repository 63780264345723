import './checkbox-list.less';
import React from 'react';
import {Checkbox} from './checkbox';

class Props {
    items: InModel[];
    onClick: (id: number, value: any) => any;
    hasError: boolean;
}

export class InModel {
    checked: boolean;
    label: string;
    id: any
}

class State {
    showScroll: boolean;
}

/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class CheckboxList extends React.PureComponent<Props, State> {
    ref: HTMLDivElement = null;

    constructor(props) {
    	super(props);
    	this.state = new State();
    }
    //TODO:Remove that shit
    private setRef(c: HTMLDivElement) {
    	this.ref = c;
    }

    getInnerBoxHeight() {
    	let sum = 0;
    	let checkboxes = $(this.ref).children('.checkbox-list-checkbox');
    	for (let i = 0; i < checkboxes.length; i++) {
    		sum = sum + checkboxes.height();
    	}
    	return sum;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    	if (this.props.items.length != prevProps.items.length) {
    		this.setState({showScroll: $(this.ref)[0].scrollHeight >= 320});
    	}
    	if (prevProps.items.length > 0 && this.props.items.length > prevProps.items.length) {
    		$(this.ref).scrollTop(1000);
    	}
    }

    render() {
    	const mainClass = this.state.showScroll ? 'checkbox-list no-right-border' : 'checkbox-list';
    	const resultClass = this.props.hasError ? mainClass + ' has-error' : mainClass;
    	return <div className={resultClass} ref={(n) => this.setRef(n)}>
            {
                this.props.items.map((item: InModel, index) => {
                    return <Checkbox
	                    key={index}
                        checked={item.checked}
                        label={item.label}
                        id={item.id}
                        className='checkbox-list-checkbox'
                        onClick={(e) => this.props.onClick(item.id, e)}
                    />;
                })
            }
        </div>;
    }
}
