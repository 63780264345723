import {ConflictLevel} from 'shared/modules/student-roster-upload-tool/types';
import {
	RenderConflictIcon,
} from 'shared/modules/student-roster-upload-tool/components/steps/conflict-resolution/components/render-conflict-icon';
import styles from './styles.module.less';

interface Props {
	title: string;
	message: string;
	conflictLevel: ConflictLevel;
}

export function ConflictMessage({title, message, conflictLevel}: Props) {
	return <div className={styles.conflictMessage} onClick={(e) => e.stopPropagation()}>
		<div className={styles.messageBlockLeft}>
			<RenderConflictIcon conflictLevel={conflictLevel}/>
		</div>
		<div data-cy='message-with-title' className={styles.messageBlockRight}>
			<span data-cy={'title'} className={styles.messageTitle}>{title}</span>
			<span data-cy='message'>{message}</span>
		</div>
	</div>;
}