import {ComponentProps, forwardRef} from 'react';
import {Divider} from './index.styled';

type Props = ComponentProps<typeof Divider> & {
	height?: number;
};

export const ChartsDivider = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-dashboard-statistics-charts-divider', css = {}, height = 60, ...props}, forwardedRef) => (
		<Divider
			dataCy={dataCy}
			css={{
				height,
				...css,
			}}
			ref={forwardedRef}
			{...props}
		/>
	),
);
