import React from 'react';
import {isTouchDevice} from '@esgillc/ui-kit/utils';
import {SubjectEntity} from 'api/entities';
import {SubjectType} from '@esgi/core/enums';
import {SubjectModel} from '../../../services/subjects-service/models';
import {Subject} from '../subject/subject';
import {EventBusManager} from '@esgillc/events';
import {SubjectRemovedEvent} from 'modules/manage-subjects-and-tests/events';
import styles from '../subject/subject.module.less';

class State {
	dragging: boolean = false;
	selectAfterRemove: number = 0;
}

class Props {
	selectedSubject: SubjectModel;
	onSelectSubject?: (s: SubjectModel) => any;
	onDragStarted?: () => any;
	subjectType: SubjectType;
	canDrag: boolean;
	subjects: SubjectModel[] = [];
}

export default class BaseTray extends React.Component<Props, State> {
	private rootElement: HTMLDivElement;
	private eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		this.state = new State();
		this.eventBus.subscribe(
			SubjectRemovedEvent,
			({id: selectAfterRemove, type}) => {
				if (type === this.props.subjectType) {
					this.setState({selectAfterRemove});
				}
			},
		);
	}

	public render() {
		if (!this.props.subjects.length) {
			return null;
		}

		return (
			<div className='tray' ref={r => this.rootElement = r}>
			{this.props.subjects.map(s => (
				<Subject key={s.id}
					initModel={s}
					selected={!!this.props.selectedSubject && this.props.selectedSubject.id === s.id}
					subjectClicked={() => this.props.onSelectSubject(s)}
				/>
			))}
			</div>
		);
	}

	public switchDrag() {
		const canDrag = this.props.canDrag && !isTouchDevice();
		if (canDrag) {
			this.turnDragOn();
		} else {
			this.turnDragOff();
		}
	}

	public turnDragOff() {
		if (this.state.dragging) {
			$(this.rootElement).sortable('destroy');
			this.setState({dragging: false});
		}
	}

	public turnDragOn() {
		const hasSubjects = this.props.subjects.length > 1;
		const subjectClass = `.${styles.subjectTab}`;
		if (!this.state.dragging && hasSubjects) {
			$(this.rootElement).sortable({
				items: subjectClass,
				opacity: 0.8,
				revert: true,
				axis: 'x',
				forceHelperSize: true,
				placeholder: 'subject-tab placeholder',
				containment: 'parent',
				tolerance: 'pointer',
				forcePlaceholderSize: true,
				scroll: true,
				distance: 10,
				start: () => {
					this.props.onDragStarted && this.props.onDragStarted();
				},
				update: async (event, ui) => {
					const ids = $(this.rootElement).find(subjectClass).toArray().map((e) => parseInt(e.getAttribute('data-id')))
						.filter(e => !isNaN(e));
					// let subjects = this.props.subjects.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
					await SubjectEntity.reorder(this.props.subjectType, ids);
					// this.setState({subjects: subjects});
				},
			});

			this.setState({dragging: true});
		}
	}

	public componentDidMount(): void {
		this.switchDrag();
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		this.switchDrag();

		if (
			this.state.selectAfterRemove
			&& this.props.selectedSubject
			&& this.props.selectedSubject.id !== this.state.selectAfterRemove
		) {
			this.props.onSelectSubject(this.props.selectedSubject);
			this.setState({selectAfterRemove: 0});
		}
	}

}
