import {Observable, of, map, BehaviorSubject, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2PagesAssignmentsController, V2TeachersPagesAssignmentsController} from '@esgi/contracts/esgi';
import {AssignmentInfo} from '../../../assignment/types';
import {Student} from '@esgi/main/libs/store';
import {AssignmentState} from '@esgi/main/kits/assignments';

export class EditAssignmentService extends BaseService {
	public initialAssignmentData$ = new BehaviorSubject<AssignmentInfo | null>(null);

	private assignmentsController = new V2PagesAssignmentsController();
	private teachersPagesAssignmentsController = new V2TeachersPagesAssignmentsController();

	public fetchAssignmentInfo({assignmentID}: {assignmentID: AssignmentInfo['id']}) {
		return this.teachersPagesAssignmentsController.info({assignmentID}).pipe(
			tap(({assignment: {state, ...assignmentData}}) => {
				this.initialAssignmentData$.next({
					...assignmentData,
					state: state as unknown as AssignmentState,
				});
			}),
		);
	}

	public saveAssignment(params: {
		assignmentID: AssignmentInfo['id'];
		name: string;
		description: string;
		testIDs: number[];
		studentIDs: Student['id'][];
	}) {
		return this.assignmentsController.update(params);
	}

	public deleteAssignment(assignmentID: number) {
		return this.assignmentsController.makeDelete({assignmentID}).subscribe();
	}

	public validateAssignmentName({
		value,
		initialValue,
	}: {
		value: string;
		initialValue: string;
	}): Observable<string | null> {
		if (!value) {
			return of('Assignment name is required');
		}
		if(value.length > 50){
			return of('Assignment name cannot exceed 50 characters');
		}
		if (initialValue === value) {
			return of(null);
		}

		return this.assignmentsController
			.validateName({name: value})
			.pipe(map(({valid}) => (!valid ? 'Assignment with this name already exist.' : null)));
	}

	public validateAssignmentDescription({
		value,
	}: {
		value: string;
	}): Observable<string | null> {

		if(value && value.length > 150){
			return of('Assignment name cannot exceed 150 characters');
		}

		return of(null);
	}
	public override dispose() {
		this.assignmentsController.dispose();
		this.teachersPagesAssignmentsController.dispose();
	}
}
