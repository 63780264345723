import {CrossIcon} from '@esgillc/ui-kit/icons';
import React, {useMemo} from 'react';
import {CreateAssignmentService} from '../../../services/create-assignment-service';
import {TestItem} from '../../../types/types';
import styles from '../hooks/styles.module.less';
import {
	Table,
	TableHeader,
	TableHeaderColumn,
	TableBody,
	TableColumn,
	TableRow,
	useSortableColumns,
} from '@esgillc/ui-kit/table';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import tableStyles from './table.module.less';
import {CROSS_ICON_SIZE} from '../../../../../constants';

type Props<Service extends CreateAssignmentService> = {
	selectedTests: TestItem[];
	service: Service;
};

export function SelectedTestsTable<Service extends CreateAssignmentService>(props: Props<Service>) {
	const tests = useMemo(
		() =>
			props.selectedTests.map((t) => ({
				id: t.id,
				name: t.name,
				subjects: t.subjects.map((s) => s.name).join(', '),
			})),
		[props.selectedTests],
	);

	const {data, sortableColumns} = useSortableColumns({
		data: tests,
		columns: {
			name: true,
			subjects: true,
		},
		activeSortedKey: 'name',
	});

	const isSubjectTabSortableDisable = useMemo(() => {
		const uniqSubjects = new Set();

		data.forEach(({subjects}) => {
			uniqSubjects.add(subjects);
		});

		return uniqSubjects.size <= 1;
	}, [data]);

	return <Table>
		<TableHeader>
			<TableHeaderColumn.Sortable {...sortableColumns.name} style={{width: `65%`}} disableSorting={data.length <= 1}>Tests in my subject
				tabs</TableHeaderColumn.Sortable>
			<TableHeaderColumn.Sortable {...sortableColumns.subjects} style={{width: `25%`}} disableSorting={isSubjectTabSortableDisable}>Subject
				tab</TableHeaderColumn.Sortable>
			<TableHeaderColumn style={{marginLeft: 'auto', marginRight: 'auto'}}>Remove</TableHeaderColumn>
		</TableHeader>
		<OverlayScrollbarsComponent className={tableStyles.body}>
			<TableBody>
				{data.map(item => (
					<TableRow style={{height: 32}} key={item.id}>
						<TableColumn style={{width: `65%`}}>
							{item.name}
						</TableColumn>
						<TableColumn style={{width: `25%`}}>
							{item.subjects}
						</TableColumn>
						<TableColumn style={{marginLeft: 'auto', marginRight: 'auto'}}>
							<div className={styles.iconContainer}>
								<CrossIcon className={styles.icon}
								           width={CROSS_ICON_SIZE}
								           height={CROSS_ICON_SIZE}
								           color='#0077B8'
								           onClick={() => props.service.deselectTest(item.id)}/>
							</div>
						</TableColumn>
					</TableRow>
				))}
			</TableBody>
		</OverlayScrollbarsComponent>
	</Table>;
}
