import {Container, Wrapper, TextChip, TestTagWrapper} from './index.styled';
import React from 'react';
import {OneLinedText, TestColorIndicator} from '@esgi/main/kits/common';
import {Test} from '@esgi/main/kits/reports';

type Props = {
	test: Test,
}

export function TestRow({test}: Props) {
	return (
		<Container>
			<Wrapper>
				<TestColorIndicator dataCy='color-test-indicator' testID={test.id}/>
				<OneLinedText dataCy='test-name' size='medium' bold>
					{test.name}
				</OneLinedText>
			</Wrapper>
			<TestTagWrapper>
				<TextChip data-cy='test-type' size='small' color={'red98'}>Rubric</TextChip>
			</TestTagWrapper>
		</Container>
	);
}