import {Single as SelectableListSingle} from './variants/selectable-list/single';
import {Multiple as SelectableListMultiple} from './variants/selectable-list/multiple';
import {Editable as SelectableListEditable} from './variants/selectable-list/editable';
import {ExpandableSingle} from './variants/selectable-list-group/expandable/expandable-single';
import {Rearrange as SelectableListGroupRearrange} from './variants/selectable-list-group/rearrange';
import {ExpandableEditable} from './variants/selectable-list-group/expandable/expandable-editable';
import {ExpandableMultiple} from './variants/selectable-list-group/expandable/expandable-multiple';

export const Lists = {
	SelectableList: {
		Single: SelectableListSingle,
		Multiple: SelectableListMultiple,
		Editable: SelectableListEditable,
	},
	SelectableListGroup: {
		Rearrange: SelectableListGroupRearrange,

		Expandable: {
			Single: ExpandableSingle,
			Multiple: ExpandableMultiple,
			Editable: ExpandableEditable,
		},
	},
};

export {type RearrangeItem} from './variants/selectable-list-group/rearrange';
