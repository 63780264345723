import {Drawer} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {PanelContent} from '../../components/panels.styled';
import {ContentBox} from '../../components/content-box.styled';
import {TrackModel} from '../../../../types';
import {DisabledInput} from '../../components/disabled-input';
import {trackTypeTranscript} from '../../../../constants';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {DisabledTrackDates} from '../../components/disabled-track-dates';

type Props = {
	currentUserTrack: TrackModel;
};

export function MarkingPeriodsContent({currentUserTrack}: Props) {
	return (
		<>
			<Drawer.PanelHeader>
				<PanelHeaderTitle title='Marking Periods' additionalText='Main Account' />
			</Drawer.PanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						<Drawer.ContentBlock title='Current Marking Period'>
							<GridBox flow='column' gap='3'>
								<DisabledInput value={currentUserTrack.name} placeholder='Marking Period Name' />
								<DisabledInput value={trackTypeTranscript[currentUserTrack.schoolYearType]} placeholder='Type' />
							</GridBox>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Marking Period Dates'>
							<DisabledTrackDates rowGap='20' track={currentUserTrack} />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
