import * as React from 'react';
import type {SVGProps} from 'react';

export function TaskAdd(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M13.914 15.086a.914.914 0 1 0 0 1.828h1.672v1.672a.914.914 0 1 0 1.828 0v-1.672h1.672a.914.914 0 1 0 0-1.828h-1.672v-1.672a.914.914 0 1 0-1.828 0v1.672h-1.672Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M8.889 10.333A2.833 2.833 0 0 1 11.722 7.5h9.445A2.833 2.833 0 0 1 24 10.333v11.334a2.833 2.833 0 0 1-2.833 2.833h-9.445a2.833 2.833 0 0 1-2.833-2.833V10.333Zm2.833-.944a.944.944 0 0 0-.944.944v11.334c0 .521.423.944.944.944h9.445a.944.944 0 0 0 .944-.944V10.333a.944.944 0 0 0-.944-.944h-9.445ZM7.944 19.896h.945v-1.89h-.945a.944.944 0 0 0 0 1.89Zm.945-5.903v-1.889h-.945a.944.944 0 1 0 0 1.89h.945Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
