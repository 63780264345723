import React, {ReactNode} from 'react';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {join} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Description} from '../../../../types';
import DetailsDialog from '../../../dialogs/details-dialog/details-dialog';
import styles from './description.module.less';

interface Props {
	selected: boolean;
	description: Description;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	onClicked: () => void;
	criteriaName: string;
	levelName: string;
}

class State {
	showDetailsDialog: boolean = false;
}

export default class DescriptionView extends React.PureComponent<Props, State> {
	public state = new State();

	public render() {
		return <>
			{this.renderDescription()}
			{this.renderDetailsDialog()}
		</>;
	}

	private renderDescription(): ReactNode {
		return <div className={styles.box}>
			<div className={join(styles.innerBox, this.props.selected && styles.selected, this.noTouch && styles.noTouch)}
			     onMouseEnter={() => this.noTouch && this.props.onMouseEnter()}
			     onMouseLeave={() => this.noTouch && this.props.onMouseLeave()}
			     onClick={() => this.props.onClicked()}>
				<div className={styles.header}>
					<div>
						<span className={styles.title}>Description</span>
					</div>
					<div className={styles.selectIcon}>
						<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM6.4 12L2.4 8L3.528 6.872L6.4 9.736L12.472 3.664L13.6 4.8L6.4 12Z'
								fill='#07D540'/>
						</svg>
					</div>
				</div>
				<div className={styles.description}>
					{this.props.description.description}
				</div>
				<div className={styles.footer}>
					{this.props.description.details &&
						<OnHoverTooltip message={this.props.description.details} placement={'bottom'}>
							<div style={{display: 'flex'}}>
								<AnchorLikeButton
									onClick={(e) => {
										e.stopPropagation();
										this.setState({showDetailsDialog: true});
									}}>Details</AnchorLikeButton>
							</div>
						</OnHoverTooltip>}
				</div>
			</div>
		</div>;
	}

	private renderDetailsDialog(): ReactNode {
		if (this.state.showDetailsDialog) {
			return <DetailsDialog details={this.props.description.details}
			                      criteriaName={this.props.criteriaName}
			                      levelName={this.props.levelName}
			                      onClose={() => this.setState({showDetailsDialog: false})}/>;
		}
	}

	private get noTouch(): boolean {
		return !('ontouchstart' in document.documentElement);
	}
}
