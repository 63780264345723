import {BaseService} from '@esgi/core/service';
import {correctDates, dateServer} from 'modules/track-confirm/utils';
import moment, {Moment} from 'moment';
import {BehaviorSubject} from 'rxjs';
import {InitResponse, SchoolYearTypeModel, TrackDateRow, UpdateRequest} from 'modules/track-confirm/types';

export default class TrackConfirmService extends BaseService {
	private controllerName = 'profiles/teacher';
	private plainDates: Date[];
	private validationArray: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);

	public trackID: number = 0;
	public isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public selectedSchoolYearType: BehaviorSubject<SchoolYearTypeModel> = new BehaviorSubject<SchoolYearTypeModel>(null);
	public trackDates: BehaviorSubject<TrackDateRow[]> = new BehaviorSubject<TrackDateRow[]>([]);

	constructor() {
		super();
		this.validationArray.subscribe((value) => {
			this.isValid.next(value.every(x => x === true));
		})
		this.trackDates.subscribe((value) => {
			this.setDates(value);
		})
	}

	public init(userID: number, globalSchoolYearID: number) {
		return this.httpClient.ESGIApi.get<InitResponse>(this.controllerName, 'init/track-confirm',
			{
				globalSchoolYearID: globalSchoolYearID,
				userID: userID,
			})
	}

	public update(result: UpdateRequest) {
		return this.httpClient.ESGIApi.post(this.controllerName, 'update-track-confirm', result)
	}

	public updateSelectedSchoolYearType(schoolYearType: SchoolYearTypeModel) {
		this.selectedSchoolYearType.next(schoolYearType)
		this.setTrackDates(schoolYearType.defaultTrackDates);
	}

	public submit() {
		const data: UpdateRequest = {
			trackID: this.trackID,
			schoolYearTypeID: this.selectedSchoolYearType.getValue().id,
			trackDates: this.trackDates.getValue().map(r => {
				return {dateFrom: dateServer(r.from), dateTo: dateServer(r.to)};
			}),
		};
		return this.update(data);
	}

	public setTrackDates(trackDates: { dateFrom: string, dateTo: string }[]): void {
		const result = trackDates.map((trackDate) => {
			return {
				from: moment(trackDate.dateFrom).toDate(),
				to: moment(trackDate.dateTo).toDate(),
			}
		})
		this.trackDates.next(result);
	}

	public updateTrackDates(trackDates: { dateFrom: string, dateTo: string }[], index: number, isFrom: boolean): void {
		let result = trackDates.map((trackDate) => {
			return {
				from: moment(trackDate.dateFrom).toDate(),
				to: moment(trackDate.dateTo).toDate(),
			}
		})
		result = correctDates(result, index, isFrom);
		this.trackDates.next(result);
	}



	public validate(date: Moment, index: number): string {
		const currentType = this.selectedSchoolYearType.getValue();
		const maxDate = moment(currentType?.maxDate).valueOf()
		const minDate = moment(currentType?.minDate).valueOf()
		let message = '';
		if (!this.plainDates) {
			return '';
		}
		const currentDate = moment(this.plainDates[index]).valueOf();
		if (!date.valueOf()) {
			message += 'Please enter a date';
		}
		if (index == 0) {
			if (minDate > currentDate) {
				console.debug(minDate, currentDate)
				message += `This date must be ${moment(minDate).format('L')} or greater`;
			}
		}
		if (index + 1 == this.plainDates.length) {
			if (maxDate < currentDate) {
				message += `This date must be prior to ${moment(maxDate).format('L')}`;
			}
		}
		if (index + 1 != this.plainDates.length) {
			const nextDate = this.plainDates[index + 1];
			if (nextDate === null) {
				message += '';
			} else if (currentDate >= nextDate.getTime()) {
				message += 'This date must be less than next date.';
			}
		}
		if (index - 1 > 0) {
			let prevDate = this.plainDates[index - 1];
			if (prevDate === null) {
				message += '';
			} else if (currentDate <= prevDate.getTime()) {
				message += 'This date must be greater than previous date.';
			}
		}
		const validationArray = this.validationArray.getValue();
		validationArray[index] = message === '';
		this.validationArray.next(validationArray);
		return message;
	}

	private setDates(trackDates: TrackDateRow[]): void {
		const simpleDates = new Array<Date>();
		const validationArray = [];
		for (let i = 0; i < trackDates.length; i++) {
			simpleDates.push(trackDates[i].from);
			simpleDates.push(trackDates[i].to);
			validationArray.push(true)
			validationArray.push(true)
		}
		this.validationArray.next(validationArray);
		this.plainDates = simpleDates;
	}
}
