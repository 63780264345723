import {TestInfo, TestSession} from '../../../../../../../types';
import {useCallback, useEffect, useState} from 'react';
import {useDiagramLegendItems} from './hooks/use-diagram-legend-items';
import {SynchronizedStateProps, TabId} from './types';
import {noop} from 'underscore';
import {FullScreenDrawer} from '../../../../full-screen-drawer';
import {FullScreenContent} from './components/full-screen-content';
import {QuickViewContent} from './components/quick-view-content';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	emptySessionsCount: number;
	isTablet: boolean;
};

export function YesNoContent({testInfo, testSessions, emptySessionsCount, isTablet}: Props) {
	const [isOpenFullScreenDiagram, setIsOpenFullScreenDiagram] = useState(false);
	const [diagramPageIndex, setDiagramPageIndex] = useState(0);

	const [activeTab, setActiveTab] = useState(TabId.QuickView);

	useEffect(() => {
		setDiagramPageIndex(0);
	}, [testSessions, activeTab]);

	const isDetailedView = activeTab === TabId.DetailedView;

	const diagramLegendItems = useDiagramLegendItems({isDetailedView});

	const onFullScreenButtonClick = useCallback(() => {
		setIsOpenFullScreenDiagram(true);
	}, []);

	const closeFullScreenDiagram = useCallback(() => {
		setIsOpenFullScreenDiagram(false);
	}, []);

	const synchronizedStateProps: SynchronizedStateProps = {
		testInfo,
		diagramLegendItems,
		activeTab,
		setActiveTab,
		emptySessionsCount,
		isDetailedView,
		testSessions,
		diagramPageIndex,
		setDiagramPageIndex,
		isTablet,
	};

	return (
		<>
			<QuickViewContent
				onFullScreenButtonClick={onFullScreenButtonClick}
				isTablet={isTablet}
				{...synchronizedStateProps}
			/>

			{isOpenFullScreenDiagram && (
				<FullScreenDrawer
					onClose={closeFullScreenDiagram}
					onDownloadClick={noop}
					title={testInfo.name}
					subTitle='Item Analysis'
				>
					<FullScreenContent {...synchronizedStateProps} />
				</FullScreenDrawer>
			)}
		</>
	);
}
