import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, map, Observable} from 'rxjs';
import {MarkingPeriod, NotTested} from './types';
import {V2TeachersPagesReportsRubricResultsController} from '@esgi/contracts/esgi';
import {deepCopy} from 'shared/utils';
import {StudentResult} from '../types/table-level-report-service-types';
import {isNumber} from 'underscore';
import {PeriodResult} from '../types/filters-service-types';

export type Filter = {
	showBaseline: boolean;
	displayZeroIfNotTested: NotTested;
	showCurrentMarkingPeriod: MarkingPeriod;
	carryScoresForward: boolean;
	includeNotTested: boolean;
}

export class SettingsService extends BaseService {
	public markingPeriod$ = new BehaviorSubject<MarkingPeriod>(MarkingPeriod.Current);
	public showBaseline$ = new BehaviorSubject<boolean>(false);
	public showCarryForward$ = new BehaviorSubject<boolean>(false);
	public notTested$ = new BehaviorSubject<NotTested>(NotTested.NT);
	public includeNotTested$ = new BehaviorSubject<boolean>(true);

	constructor(private controller: V2TeachersPagesReportsRubricResultsController) {
		super();
	}

	public setFilters(filter: Filter) {
		this.showBaseline$.next(filter.showBaseline);
		this.showCarryForward$.next(filter.carryScoresForward);
		this.markingPeriod$.next(filter.showCurrentMarkingPeriod);
		this.notTested$.next(filter.displayZeroIfNotTested);
	}

	public filter$: Observable<Filter> = this.completeOnDestroy(
		combineLatest([this.showBaseline$, this.showCarryForward$, this.markingPeriod$, this.notTested$, this.includeNotTested$]))
		.pipe(map(([baseLine, carryForward, markingPeriod, notTested, includeNotTested]) => {
			return {
				showBaseline: baseLine,
				carryScoresForward: carryForward,
				showCurrentMarkingPeriod: markingPeriod,
				displayZeroIfNotTested: notTested,
				includeNotTested: includeNotTested,
			};
		}));

	public applyFilters(results: StudentResult[], filter: Filter, currentPeriod: number) {
		let rows = deepCopy<StudentResult[]>(results);
		let cp = rows[0].criteriaResults[0].periodResults.length - 1;
		if (!filter.includeNotTested) {
			rows = rows.filter(x => x.hasResult);
		}
		if (!filter.showBaseline) {
			rows = this.applyShowBaselineFilter(rows);
		}
		if (filter.carryScoresForward) {
			rows = this.applyCarryForwardFilter(rows);
		}
		if (filter.displayZeroIfNotTested === NotTested.NT) {
			rows = this.applyDisplayNotTestedFilter(rows);
		}
		if (filter.showCurrentMarkingPeriod === MarkingPeriod.Current) {
			rows = this.applyMarkingPeriodFilter(rows, filter.showBaseline, currentPeriod);
		}
		if (filter.showCurrentMarkingPeriod === MarkingPeriod.Current && !filter.includeNotTested) {
			rows = rows.filter(x => {
				const countNotTested = x.criteriaResults[0].periodResults.reduce((acc, current) => {
					if (current.periodName === cp.toString() && (current.value === 'NT' || current.value == '0')) {
						return ++acc;
					}
					return acc;
				}, 0);
				return countNotTested === 0;
			});
		}
		return rows;
	}

	public setShowBaseLine(value: boolean) {
		this.showBaseline$.next(value);
		this.controller.updateShowBaseLine({value: value ? 'True' : 'False'}).subscribe();
	}

	public setCarryForward(value: boolean) {
		this.showCarryForward$.next(value);
		this.controller.updateReportSettings({name: 'carryScoresForward', value}).subscribe();
	}

	public setMarkingPeriod(value: MarkingPeriod) {
		this.markingPeriod$.next(value);
		this.controller.updateReportSettings({name: 'currentScoreOnly', value: value === MarkingPeriod.Current}).subscribe();
	}

	public setNotTested(value: NotTested) {
		this.notTested$.next(value);
		this.controller.updateReportSettings({name: 'displayZeroIfNotTested', value: value === NotTested.Zero}).subscribe();
	}

	public setIncludeNotTested(value: boolean) {
		this.includeNotTested$.next(value);
	}

	private applyCarryForwardFilter(rows: StudentResult[]) {
		return rows.map((row) => {
			return {...row, criteriaResults: row.criteriaResults.map((criteriaResult) => {
				const lastTestedPeriod = [...criteriaResult.periodResults]
					.reverse()
					.find(({testSessionID}) => testSessionID !== 0);

				if (!lastTestedPeriod) {
					return criteriaResult;
				}

				const testedPeriodIndex = criteriaResult.periodResults.findIndex(
					({periodName}) => periodName === lastTestedPeriod.periodName,
				);

				for (let i = testedPeriodIndex + 1; i < criteriaResult.periodResults.length; i++) {
					if (criteriaResult.periodResults[i]?.value) {
							criteriaResult.periodResults[i]!.value = lastTestedPeriod.value;
					}
				}

				return criteriaResult;
			})};
		});
	}

	private applyShowBaselineFilter(rows: StudentResult[]) {
		return rows.map((row) => {
			return {...row, criteriaResults: row.criteriaResults.map((criteriaResult) => {
				return {...criteriaResult, periodResults: criteriaResult.periodResults = criteriaResult.periodResults.filter(({periodName}) => periodName !== 'B')};
			})};
		});
	}

	private applyDisplayNotTestedFilter(rows: StudentResult[]) {
		return rows.map((row) => {
			return {...row,
				criteriaResult: row.criteriaResults.map((criteriaResult) => {
					criteriaResult.periodResults = criteriaResult.periodResults.map((periodResult) => {
						if (parseInt(periodResult.value) === 0 && periodResult.testSessionID === 0) {
							periodResult.value = 'NT';
						}

						return periodResult;
					});

					return criteriaResult;
				})};
		});
	}

	private applyMarkingPeriodFilter(rows: StudentResult[], showBaseLine: boolean, currentPeriod?: number) {
		if (!rows[0]?.criteriaResults[0]?.periodResults.length) {
			return rows;
		}

		let cp = isNumber(currentPeriod) ? currentPeriod : rows[0].criteriaResults[0].periodResults.length - 1;

		if (isNumber(currentPeriod) && showBaseLine) {
			cp++;
		}

		return rows.map((row) => {
			return {...row, criteriaResults: row.criteriaResults.map((criteriaResult) => {
				const periodResults: PeriodResult[] = [];

				if (showBaseLine) {
					const filteredPeriodResults = criteriaResult.periodResults.filter(({periodName}) => periodName === 'B');

					if (filteredPeriodResults.length) {
						periodResults.push(filteredPeriodResults[0]!);
					}
				}

				const periodResult = criteriaResult.periodResults[cp];

				if (periodResult) {
					periodResults.push(periodResult);
				}

				criteriaResult.periodResults = periodResults;

				return criteriaResult;
			})};
		});
	}

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}