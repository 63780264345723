import {BaseComponentProps, ProgressBar} from '@esgi/ui';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {PropsWithChildren, useMemo} from 'react';

type Props = BaseComponentProps &
	PropsWithChildren & {
		progressBarSize?: number;
		progressBarCircleStrokeWidth?: number;
		value: number;
		maxValue: number;
	};

export function ProgressWithPointer({
	dataCy = 'assignments-progress-with-pointer',
	css = {},
	progressBarSize = 48,
	progressBarCircleStrokeWidth = 6,
	value,
	maxValue,
	children,
}: Props) {
	const {isProgressSuccess, valueTranscript, hidePointer, valueTranscriptColor} = useMemo(() => {
		const result = value / maxValue;

		const isProgressSuccess = result > 0.5;
		const valueTranscript = `${Math.round(result * 100)}%`;

		let valueTranscriptColor: VariantProps<typeof Text>['color'] = 'base';

		if (result <= 0) {
			valueTranscriptColor = 'lowContrast';
		}

		if (isProgressSuccess) {
			valueTranscriptColor = 'green';
		}

		return {
			isProgressSuccess,
			valueTranscript,
			hidePointer: result >= 1 || result <= 0,
			valueTranscriptColor,
		};
	}, [value, maxValue]);

	return (
		<GridBox dataCy={dataCy} css={css} gap='3' flow='column' align='center' display='inlineGrid'>
			<ProgressBar value={Math.max(0, value)} maxValue={maxValue}>
				<ProgressBar.RingWithPointer
					size={progressBarSize}
					pointerFillColor={isProgressSuccess ? 'green' : 'neutral40'}
					progressValueColor={isProgressSuccess ? 'positiveMuted' : 'lowContrast'}
					circleColor='border'
					circleStrokeWidth={progressBarCircleStrokeWidth}
					hidePointer={hidePointer}
				>
					<FlexBox align='center'>
						<Text size='medium' bold font='mono' color={valueTranscriptColor}>
							{valueTranscript}
						</Text>
					</FlexBox>
				</ProgressBar.RingWithPointer>
			</ProgressBar>
			{children}
		</GridBox>
	);
}
