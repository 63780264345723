import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AlertBodyGrid} from './alert-grid-body';

type Props = {
	onAlertClose: VoidFunction;
	validationData: string;
};

export function DuplicateIDInFile({onAlertClose, validationData}: Props) {
	return (
		<>
			<Alert.Header>
				<Text size='large'>Your file contains duplicate student IDs.</Text>
			</Alert.Header>
			<AlertBodyGrid>
				<Text size='medium'>All IDs must be unique. Please fix the IDs and try again.</Text>
				{validationData && <Text size='medium'>Errors: {validationData}</Text>}
			</AlertBodyGrid>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
