import {useUser} from '@esgi/core/authentication';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onClose: () => void;
}

export function NoIncorrectItemsAlert(props: Props) {
	const currentUser = useUser();

	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);
	return (
		<Alert modalManagerRef={modal}>
			<Alert.Body>
				There are no {currentUser?.testResultsIncorrectVerbiage ?? 'incorrect'} or untested questions for this test.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={close}>OK</Buttons.Text>
			</Alert.Footer>
		</Alert>
	);
}
