import {useEffect} from 'react';
import {useServiceFactory} from '@esgi/core/service';
import {Modal, useModal, useCloseModal} from '@esgillc/ui-kit/modal';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Header} from './components/header';
import {Body} from './components/body';
import {Footer} from './components/footer';
import {AllStudentsService} from './services';
import {Subject} from '../test-session-details-form/types';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';

interface Props {
	classId: number;
	specialistGroupId: number;
	studentId: number;
	testId: number;
	subject: Subject;
	hierarchy: HierarchySnapshot;
	onClose: () => void;
}

export const TestSessionDetailsAllStudents = (props: Props) => {
	const modal = useModal();
	const onClose = useCloseModal(modal, props.onClose);
	const service = useServiceFactory(
		() => new AllStudentsService(
			props.hierarchy,
			props.testId,
			props.classId,
			props.specialistGroupId,
			props.subject,
		),
	);
	const reportData = useBehaviorSubject(service.reportData$);

	useEffect(() => {
		service.init();
	}, []);

	return (
		<>
			<ServiceLoader fullscreen trackingService={service}/>
			{reportData &&
				<Modal
					modalManagerRef={modal}
					onCatchError={onClose}
				>
					<Header service={service}/>
					<Body service={service}/>
					<Footer onClose={onClose}/>
				</Modal>
			}
		</>
	);
};

export default TestSessionDetailsAllStudents;
