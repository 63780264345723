import React, {ReactElement} from 'react';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {TextInput, CustomDropdown, Dropdown} from '@esgi/deprecated/elements';

class Props {
	message?: string;
	errorClassName?: string;
	showValidation: boolean;
	withRedBorder?: boolean;
	withTooltip?: boolean;
	validationPlacement?: 'right' | 'top' | 'bottom' | 'left';
	validationMessageOffsetX?: number;
	validationMessageOffsetY?: number;
	wrapperDivClass?: string;
	children?: any;
}

export default class ValidationWrapper extends React.PureComponent<Props> {
	private ref: HTMLElement;

	get tooltip() {
		return <ValidationTooltip
			element={this.ref}
			placement={this.props.validationPlacement || 'right'}
			offsetX={this.props.validationMessageOffsetX}
			offsetY={this.props.validationMessageOffsetY}
			notHide={true}
			container={this.ref.parentElement}
		>
			{this.props.message}
		</ValidationTooltip>;
	}

	get withTooltip() {
		return this.props.withTooltip === undefined ? true : this.props.withTooltip;
	}

	get withRedBorder() {
		return this.props.withRedBorder === undefined ? true : this.props.withRedBorder;
	}

	get showTooltip() {
		return this.ref && this.props.showValidation && this.withTooltip;
	}

	get showRedBorder() {
		return this.props.showValidation && this.withRedBorder;
	}

	renderRedBorderControl() {
		let element = this.props.children as any;
		
		const errorClassName = this.props.errorClassName === undefined ? 'has-error' : this.props.errorClassName;

		let redBorderClass = element.props.className;
		
		if (this.showRedBorder) {
			redBorderClass = element.props.className === undefined
				? errorClassName
				: element.props.className + ` ${errorClassName}`;
		}
		
		if (element.type === TextInput || element.type === Dropdown || element.type === CustomDropdown) {
			return <div ref={(r) => this.ref = r} className={this.props.wrapperDivClass}>
				{React.cloneElement(this.props.children as any,
					{
						className: redBorderClass,
					})}{this.showTooltip && this.tooltip}</div>;
		} else {
			return <div ref={(r) => this.ref = r} className={this.props.wrapperDivClass + ' ' + redBorderClass}>
				{React.cloneElement(this.props.children as any)}
				{this.showTooltip && this.tooltip}
			</div>;	
		}
	}

	renderControl() {
		return <>{React.cloneElement(
			this.props.children as any,
			{
				ref: el => this.ref = el,
			})}{this.showTooltip && this.tooltip}</>;
	}

	render() {
		{
			return this.withRedBorder ? this.renderRedBorderControl() : this.renderControl();
		}
	}
}
