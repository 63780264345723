import React, {Suspense, Fragment} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {SubjectType} from '@esgi/core/enums';

const RubricResultsReport = lazyComponent(() => import('modules/reports/rubric-report/'));

class State {
	opened: boolean = false;
}

interface Props {
	subjectID: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
}

export default class RubricReportButton extends React.Component<Props, State> {
	public state = new State();

	render() {
		return <>
			<NoStudentProtectedButton title='Rubric Results'
			                          icon={this.icon()}
			                          hoverTitle='Rubric Results'
			                          noStudents={this.props.hierarchy.students.length == 0}
			                          onClicked={() => this.setState({opened: true})}/>
			{this.renderReport()}
		</>;
	}

	icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='15'
		            viewBox='0 0 15 15'
		            fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M1.5625 0C0.699558 0 0 0.699555 0 1.5625V2.8125H15V1.5625C15 0.699555 14.3004 0 13.4375 0H1.5625ZM4.375 3.75H0V6.875H4.375V3.75ZM9.6875 3.75H5.3125V6.875H9.6875V3.75ZM10.625 3.75H15V6.875H10.625V3.75ZM0 7.8125H4.375V11H0V7.8125ZM9.6875 7.8125H5.3125V11H9.6875V7.8125ZM10.625 7.8125H15V11H10.625V7.8125ZM4.375 12H0V12.9688C0 14.0906 0.909424 15 2.03125 15H4.375V12ZM5.3125 12H9.6875V15H5.3125V12ZM15 12H10.625V15H12.9688C14.0906 15 15 14.0906 15 12.9688V12Z'
			      fill='#0088CC'/>
		</svg>;
	};

	renderReport() {
		if (this.state.opened) {
			return <Suspense fallback={<Fragment/>}>
				<RubricResultsReport
					hierarchy={this.props.hierarchy.snapshot}
					subjectID={this.props.subjectID}
					subjectType={this.props.subjectType}
					onClosed={() => this.setState({opened: false})}
				/>
			</Suspense>;
		}
	}
}
