import {Text, SortArrows, SortBy} from '../../../../kit';
import styles from './styles.module.less';

export type TableHeaderSortableProps = {
	sortBy: SortBy;
	headerText: string;
	isSortByActive: boolean;
};

export const TableHeaderSortable = ({
	sortBy,
	headerText,
	isSortByActive,
}: TableHeaderSortableProps) => (
	<div className={styles.headerCellStudents}>
		<Text color='black' size='12px' as='span'>
			{headerText}
		</Text>
		<SortArrows sortBy={sortBy} isSortByActive={isSortByActive} />
	</div>
);
