import {useAutoControlledState} from '@esgi/ui/utils';
import {intersection} from 'underscore';
import {CSS} from '@stitches/react';
import React, {
	ComponentPropsWithoutRef,
	MouseEvent,
	ReactNode,
	forwardRef,
	useCallback,
	useEffect,
	useLayoutEffect,
} from 'react';
import {useSelectableListContext} from '../../context';
import {Expand} from '@esgi/ui/icons';
import {useSelectableListGroupContext} from '../selectable-list-group-root/context';
import {IconComponent} from '@esgi/ui';
import {ArrowTop, Div} from './index.styled';

type TriggerExpandableProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	/** Data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** When true, prevents the user from interacting with the item. */
	disabled?: boolean;

	/** When true, selectable list is open */
	openList?: boolean;

	/** Add icon before all content. If this value is not passed. Icon <Expand /> is displayed on hover. */
	IconBefore?: IconComponent;

	/** Hovered state. */
	hovered?: boolean | undefined;

	/** Selected state. */
	selected?: boolean;

	/** Item Content. */
	children?: ReactNode | ((args: {hovered?: boolean; selected?: boolean; isListOpenInner?: boolean}) => React.JSX.Element);
};

export const SelectableListTrigger = forwardRef<HTMLDivElement, TriggerExpandableProps>(
	(
		{
			dataCy = 'ui-kit-selectable-list-trigger',
			children,
			disabled,
			onClick,
			openList: controlledOpenList,
			IconBefore,
			hovered: controlledHovered,
			onMouseOver,
			onMouseOut,
			selected: controlledSelected,
			...props
		},
		forwaredRef,
	) => {
		const [hovered, setHovered] = useAutoControlledState({
			initialState: false,
			controlledState: controlledHovered,
		});

		const [selected, setSelected] = useAutoControlledState({
			initialState: false,
			controlledState: controlledSelected,
		});

		const [isListOpenInner, setIsListOpenInner] = useAutoControlledState({
			initialState: false,
			controlledState: controlledOpenList,
		});

		const {setIsExpandableList, setIsListOpen, triggerWithStyledButton} = useSelectableListContext();
		const {value, valuesVocabulary} = useSelectableListGroupContext();

		useLayoutEffect(() => {
			setIsExpandableList(true);

			return () => {
				setIsExpandableList(false);
				setIsListOpen(true);
			};
		}, []);

		useLayoutEffect(() => {
			setIsListOpen(isListOpenInner);
		}, [isListOpenInner]);

		useEffect(() => {
			const allSelected = value.length && valuesVocabulary.length && intersection(value, valuesVocabulary).length === valuesVocabulary.length;

			if (allSelected && !selected) {
				setSelected(true);
			}

			if (!allSelected && selected) {
				setSelected(false);
			}
		});

		const handleClick = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				if (disabled) {
					return;
				}

				setIsListOpenInner(!isListOpenInner);
				onClick?.(event);
			},
			[onClick, disabled, isListOpenInner, setIsListOpenInner],
		);

		const handleMouseOver = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(true);
				onMouseOver?.(event);
			},
			[onMouseOver, setHovered],
		);

		const handleMouseOut = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(false);
				onMouseOut?.(event);
			},
			[onMouseOut, setHovered],
		);

		const getIconBefore = useCallback(() => {
			const contentHovered = hovered && !triggerWithStyledButton.hovered;

			if (!IconBefore) {
				return null;
			}

			if (contentHovered && !isListOpenInner) {
				return <Expand />;
			}

			if (contentHovered && isListOpenInner) {
				return <ArrowTop />;
			}

			return <IconBefore />;
		}, [hovered, isListOpenInner, IconBefore, triggerWithStyledButton.hovered]);

		return (
			<Div
				align='center'
				dataCy={dataCy}
				onClick={handleClick}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
				isListOpen={isListOpenInner}
				hovered={hovered}
				withStyledButton={triggerWithStyledButton.exist}
				styledButtonHovered={triggerWithStyledButton.hovered}
				selected={selected}
				ref={forwaredRef}
				disabled={Boolean(disabled)}
				{...props}
			>
				{getIconBefore()}
				{typeof children === 'function'
					? children({
							hovered,
							selected,
							isListOpenInner,
					  })
					: children}
			</Div>
		);
	},
);
