import {Dispatch} from 'react';
import {SettingsPanel} from '@esgi/main/kits/reports';
import {Wrapper, Checkbox, TagList, Tag} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Tooltip} from '@esgi/ui/tooltip';
import {Grade} from '../../types';

interface Props {
	grades: Grade[];
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function GradesControl({grades, value, onChange}: Props) {
	const isDisabled = !grades.length;

	return (
		<SettingsPanel data-cy='grades-panel' title='Grades'>
			<Wrapper border>
				<Tooltip open={!isDisabled ? false : undefined}>
					<Tooltip.Trigger>
						<Checkbox data-cy='include-grades-checkbox' disabled={isDisabled} label='Include Grades' checked={value} onCheckedChange={onChange} />
					</Tooltip.Trigger>

					<Tooltip.Content>
						<Text size='small'>You need to configure Grade Scales to use this option.</Text>
					</Tooltip.Content>
				</Tooltip>
			</Wrapper>

			{Boolean(grades.length) && (
				<Wrapper data-cy='grades-map'>
					<TagList>
						{grades.map(({color, description, id, name}) => (
							<Tag key={id} title={description} disabled={!value} css={{backgroundColor: value ? color : undefined}}>
								<Text size='small' bold color='currentColor'>
									{name}
								</Text>
							</Tag>
						))}
					</TagList>
				</Wrapper>
			)}
		</SettingsPanel>
	);
}
