import React from 'react';
import {SuggestedGradeLevel} from 'api/entities/suggested-grade-level';
import {Dropdown, OnErrorTooltip, Option} from '@esgillc/ui-kit/form-control';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Field} from '../type';

import styles from '../confirm.module.less';

export const GRADES: Field = SuggestedGradeLevel.list.map(gl => {
	return {id: gl.id, name: gl.name};
});

interface Props {
	control: FormControl<Field>;
	initialValue: number[];
}

export default class GradeField extends React.PureComponent<Props> {
	public componentDidMount() {
		this.props.control.value = GRADES.filter(g => this.props.initialValue.findIndex(gl => gl === g.id) > -1);
	}

	public render() {
		return <div className={styles.inlineField}>
			<div className={styles.label}>Add/Edit Grade:</div>
			<FormElement control={this.props.control} className={styles.element}>
				<Dropdown placeholder='Select Grade' optionName='name' multiselect
				          className={styles.dropdown}>
					{GRADES.map(s => <Option key={s.id} value={s}>{s.name}</Option>)}
				</Dropdown>
				<OnErrorTooltip showOnError='required' placement='right'>
					Pick at least one grade
				</OnErrorTooltip>
			</FormElement>
		</div>;
	}
}
