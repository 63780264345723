import {useEffect, useState} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {Checkbox} from '@esgillc/ui-kit/control';
import {useService} from '@esgi/core/service';
import {ParentLetterEditorService} from './service';
import ImageUploader from './image-uploader';
import {LogoModel} from './types';
import ParentLetterEditorModal from 'modules/reports/parent-letter/parent-letter-editor-modal';
import styles from './styles.module.less';

interface Props {
  onExit: () => void;
}

export function ParentLetterEditor({onExit}: Props): JSX.Element {
	const service = useService(ParentLetterEditorService);

	const logo = useBehaviorSubject(service.logo);
	const template = useBehaviorSubject(service.letterTemplate);
	const published = useBehaviorSubject(service.published);

	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		const sub = service.init().subscribe();
		return () => sub.unsubscribe();
	}, []);

	const setLogo = (logo: LogoModel) => {
		if (editMode) {
			service.logo.next(logo);
		} else {
			service.setLogo(logo);
		}
	};

	const revertLogo = () => {
		service.revertLogo();
	};

	if (!template) {
		return;
	}

	return (
		<ParentLetterEditorModal
			defaultMessage={template.defaultBody}
			title='Parent Message'
			message={template.body}
			showSnackbar={true}
			afterTextAreaRenderer={() => (
				<Checkbox
					className={styles.checkbox}
					checked={published}
					onClick={() => service.setPublished(!published)}
				>
					Publish message to District
				</Checkbox>
			)}
			onCloseClicked={onExit}
			onCancelClicked={revertLogo}
			onSaveClicked={(message: string) => service.save(message)}
			onToggleEditMode={(editMode) => setEditMode(editMode)}
		>
			<ServiceLoader trackingService={service} fullscreen/>
			<ImageUploader
				logo={logo}
				onChanged={(logo) => setLogo(logo)}
			/>
		</ParentLetterEditorModal>
	);
}

export default ParentLetterEditor;
