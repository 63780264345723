/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RightPanelBox} from 'shared/right-panel/right-panel';
import './manage-subject-tabs-and-tests-panel.less';
import {userStorage, UserType} from '@esgi/core/authentication';
import {ShareScreenLauncher} from 'modules/share-screen-launcher';
import rightPanelStyle from 'pages/home/components/right-panel/right-panel.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {SubjectModel} from '../../services/subjects-service/models';

const AddTestModal = lazyComponent(() => import('shared/modules/home-add-test/modal/modal'));
const ManageSubjectsAndTestsModal = lazyComponent(() => import('modules/manage-subjects-and-tests'));

export class State {
	addTest: boolean = false;
	openManageSubjects: boolean = false;
	studentScreen: boolean = false;
}

export class Props {
	subject: SubjectModel;
	openManageSubjectsInitially: boolean;
	hierarchy: HierarchyInstance;
}

export class ManageSubjectsAndTestsPanel extends React.Component<Props, State> {
	constructor(props?: Props) {
		super(props);
		this.state = new State();
	}

	private get user() {
		return userStorage.get();
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.openManageSubjectsInitially !== this.props.openManageSubjectsInitially) {
			this.setState({openManageSubjects: this.props.openManageSubjectsInitially});
		}
	}

	public render(): JSX.Element | false | null {
		return (
			<RightPanelBox
				title='Subject Tabs & Testing'
				className='manage-controls'
				titleClassName={rightPanelStyle.boxTitle}
			>
				{this.props.subject && this.props.subject.canEdit && (
					<div className='manage-controls-item'>
						<OnHoverTooltip message='Find tests to add to your subject tabs'>
							<a className='home-add-test-link' href='#' onClick={() => this.openHomeAddTest()}>
								<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
									<path
										d='M9.83332 4.83332H8.16666V8.16666H4.83332V9.83332H8.16666V13.1667H9.83332V9.83332H13.1667V8.16666H9.83332V4.83332ZM8.99999 0.666656C4.39999 0.666656 0.666656 4.39999 0.666656 8.99999C0.666656 13.6 4.39999 17.3333 8.99999 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99999C17.3333 4.39999 13.6 0.666656 8.99999 0.666656ZM8.99999 15.6667C5.32499 15.6667 2.33332 12.675 2.33332 8.99999C2.33332 5.32499 5.32499 2.33332 8.99999 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 12.675 12.675 15.6667 8.99999 15.6667Z'
										fill='#0088CC'
									/>
								</svg>
								<span className={rightPanelStyle.reportTitle}>Add Test</span>
							</a>
						</OnHoverTooltip>
					</div>
				)}
				<div className='manage-controls-item'>
					<OnHoverTooltip message='Add, edit and organize subject tabs and tests'>
						<a className='home-manage-subjects-link' href='#' onClick={() => this.openManageSubjects()}>
							<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 16 16' fill='none'>
								<path
									d='M13.95 8.78C13.98 8.53 14 8.27 14 8C14 7.73 13.98 7.47 13.94 7.22L15.63 5.9C15.78 5.78 15.82 5.56 15.73 5.39L14.13 2.62C14.03 2.44 13.82 2.38 13.64 2.44L11.65 3.24C11.23 2.92 10.79 2.66 10.3 2.46L10 0.34C9.97001 0.14 9.80001 0 9.60001 0H6.40001C6.20001 0 6.04001 0.14 6.01001 0.34L5.71001 2.46C5.22001 2.66 4.77001 2.93 4.36001 3.24L2.37001 2.44C2.19001 2.37 1.98001 2.44 1.88001 2.62L0.280007 5.39C0.180007 5.57 0.220008 5.78 0.380008 5.9L2.07001 7.22C2.03001 7.47 2.00001 7.74 2.00001 8C2.00001 8.26 2.02001 8.53 2.06001 8.78L0.370007 10.1C0.220007 10.22 0.180007 10.44 0.270007 10.61L1.87001 13.38C1.97001 13.56 2.18001 13.62 2.36001 13.56L4.35001 12.76C4.77001 13.08 5.21001 13.34 5.70001 13.54L6.00001 15.66C6.04001 15.86 6.20001 16 6.40001 16H9.60001C9.80001 16 9.97001 15.86 9.99001 15.66L10.29 13.54C10.78 13.34 11.23 13.07 11.64 12.76L13.63 13.56C13.81 13.63 14.02 13.56 14.12 13.38L15.72 10.61C15.82 10.43 15.78 10.22 15.62 10.1L13.95 8.78ZM8.00001 11C6.35001 11 5.00001 9.65 5.00001 8C5.00001 6.35 6.35001 5 8.00001 5C9.65001 5 11 6.35 11 8C11 9.65 9.65001 11 8.00001 11Z'
									fill='#0088CC'
								/>
							</svg>
							<span className={rightPanelStyle.reportTitle}>Manage Tabs & Tests</span>
						</a>
					</OnHoverTooltip>
				</div>
				{this.renderStudentScreenLink()}
				{this.renderAddTest()}
				{this.renderManageSubjects()}
				{this.renderStudentScreen()}
			</RightPanelBox>
		);
	}

	private renderStudentScreenLink() {
		if ([UserType.T, UserType.ISS, UserType.ISD, UserType.PA].indexOf(this.user.userType) !== -1) {
			return (
				<div className='manage-controls-item'>
					<OnHoverTooltip message='Test on a student device without displaying teacher notes'>
						<a className='home-manage-subjects-link' href='#' onClick={() => this.openStudentScreen()}>
							<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M15 11.0251C15.825 11.0251 16.4925 10.3529 16.4925 9.53138L16.5 2.06276C16.5 1.24121 15.825 0.569031 15 0.569031H3C2.175 0.569031 1.5 1.24121 1.5 2.06276V9.53138C1.5 10.3529 2.175 11.0251 3 11.0251H0V12.5188H18V11.0251H15ZM3 2.06276H15V9.53138H3V2.06276Z'
									fill='#0077B8'
								/>
							</svg>
							<span className={rightPanelStyle.reportTitle}>Launch Student Screen</span>
						</a>
					</OnHoverTooltip>
				</div>
			);
		}

		return null;
	}

	private openHomeAddTest() {
		this.setState({addTest: true});
	}

	private openManageSubjects() {
		this.setState({openManageSubjects: true});
	}

	private openStudentScreen() {
		this.setState({studentScreen: true});
	}

	private renderAddTest() {
		if (this.state.addTest) {
			return (
				<Suspense fallback={<div />}>
					<AddTestModal
						subjectId={this.props.subject.id}
						hierarchy={this.props.hierarchy}
						closed={() => this.setState({addTest: false})}
						limitToSelectedSubjectId={false}
					/>
				</Suspense>
			);
		}

		return null;
	}

	private renderManageSubjects() {
		if (this.state.openManageSubjects) {
			return (
				<Suspense fallback={<div />}>
					<ManageSubjectsAndTestsModal
						hierarchy={this.props.hierarchy.snapshot}
						closed={() => {
							this.setState({openManageSubjects: false});
						}}
					/>
				</Suspense>
			);
		}

		return null;
	}

	private renderStudentScreen() {
		if (this.state.studentScreen) {
			return <ShareScreenLauncher userID={this.user.userID} closed={() => this.setState({studentScreen: false})} />;
		}

		return null;
	}
}
