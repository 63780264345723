import {PropsWithChildren} from 'react';
import {OverlayScrollbarsComponent, OverlayScrollbarsComponentProps} from 'overlayscrollbars-react';

interface Props extends OverlayScrollbarsComponentProps, PropsWithChildren {}

export function ExpandablePanel({children, ...props}: Props) {
	return (
		<OverlayScrollbarsComponent
			defer
			style={{height: 'calc(100vh - 170px)'}}
			options={{
				scrollbars: {autoHide: 'leave'},
				paddingAbsolute: true,
			}}
			{...props}
		>
			{children}
		</OverlayScrollbarsComponent>
	);
}
