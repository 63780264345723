import React, {ReactNode} from 'react';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isTouchDevice, join} from '@esgillc/ui-kit/utils';
import {EventBusManager} from '@esgillc/events';
import {AudioEndPlayEvent, AudioStartPlayEvent} from '../../events';
import styles from './audio-button.module.less';

interface AudioButtonProps {
	audioUrl: string;
	disabled?: boolean;
	className?: string;
	id: number;
	icon?: ReactNode;
	onAudioEnd?: () => void;
}

class AudioButtonState {
	played: boolean = false;
}

export class AudioButton extends React.Component<AudioButtonProps, AudioButtonState> {
	state = new AudioButtonState();
	private audio = new Audio(this.props.audioUrl);
	private destroy$: Subject<void> = new Subject();
	private readonly eventBus = new EventBusManager();

	componentDidMount(): void {
		fromEvent(this.audio, 'ended')
			.pipe(takeUntil(this.destroy$))
			.subscribe(_ => {
				this.props.onAudioEnd?.();
				this.setState({played: false}, () => this.eventBus.dispatch(AudioEndPlayEvent, {}));
			});
	}

	componentDidUpdate(prevProps: Readonly<AudioButtonProps>, prevState: Readonly<AudioButtonState>, snapshot?: any): void {
		if (prevProps.id !== this.props.id || prevProps.audioUrl !== this.props.audioUrl) {
			if (this.state.played) {
				this.setState({played: false}, () => this.audio?.pause());
			}

			this.audio.src = this.props.audioUrl;
		}
	}

	componentWillUnmount() {
		this.destroy$.next();
		this.eventBus.destroy();
		this.audio.pause();
		this.props.onAudioEnd?.();
	}

	private play() {
		if (this.state.played) {
			return;
		}

		this.setState({played: true}, () => {
			this.audio?.play();
			this.eventBus.dispatch(AudioStartPlayEvent, {});
		});
	}

	private get audioButtonClass() {
		return join(styles.audioButton, this.state.played && styles.playing, this.props.className);
	}

	private get disabled(): boolean {
		if (this.state.played && this.props.disabled) {
			return false;
		}

		return this.state.played || this.props.disabled;
	}

	render() {
		return (
                <button onTouchStart={() => isTouchDevice() && this.play()} type='button' disabled={this.disabled} className={this.audioButtonClass} onClick={() => this.play()}>
					{this.props.icon || <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle cx='27' cy='27' r='27' fill='#0088CC'/>
						<circle cx='27' cy='27' r='25.5' stroke='white' strokeOpacity='0.32' strokeWidth='3'/>
						<path d='M12 21.5783C10.8954 21.5783 10 22.4738 10 23.5783V30.5783C10 31.6829 10.8954 32.5783 12 32.5783H16.5049C17.0353 32.5783 17.544 32.789 17.9191 33.1641L23.0858 38.3308C24.3457 39.5907 26.5 38.6984 26.5 36.9166V17.2401C26.5 15.4583 24.3457 14.566 23.0858 15.8259L17.9191 20.9925C17.544 21.3676 17.0353 21.5783 16.5049 21.5783H12ZM34.75 27.0783C34.75 24.9965 33.9804 23.1034 32.7122 21.6554C31.7326 20.537 30.1667 21.4393 30.1667 22.9261V31.2318C30.1667 32.7138 31.7246 33.617 32.7045 32.5051C33.9773 31.0609 34.75 29.1643 34.75 27.0783ZM32.4298 11.6884C31.2981 11.2528 30.1667 12.1544 30.1667 13.3671C30.1667 14.2033 30.7227 14.9271 31.4929 15.2528C36.0983 17.2001 39.3333 21.7636 39.3333 27.0783C39.3333 32.3931 36.0983 36.9565 31.4929 38.9039C30.7227 39.2296 30.1667 39.9533 30.1667 40.7896C30.1667 42.0022 31.2981 42.9039 32.4298 42.4683C38.6154 40.0872 43 34.096 43 27.0783C43 20.0606 38.6154 14.0694 32.4298 11.6884Z' fill='white'/>
					</svg>}
                </button>
		);
	}
}

export default AudioButton;
