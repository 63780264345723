import React from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {IFormControlValidatorResult, TextInput} from '@esgi/deprecated/elements';

export class State {
	value: number = 1;
	valid: boolean = true;
	validator: IFormControlValidatorResult = {valid: false, message: ''};
	touched: boolean = false;
	dirty: boolean = true;
}

export class Props extends SharedProps<State> {
	totalPossible: number;
	canEdit: boolean;
	touched: boolean;
}

export class TotalPossible extends SharedComponent<State, Props> {
	private maxQuestionsCount: number = 1000;
	private minQuestionsCount: number = 1;

	private timerId: any;

	constructor(props?: Props) {
		super(props);
	}

	componentDidMount(): void {
		if (this.state.value !== 1) {
			return;
		}
		if (!this.props.totalPossible) {
			return;
		}
		const value = this.props.totalPossible;
		this.setState({
			value: value,
			valid: this.isValid(value),
			touched: this.props.touched,
			dirty: false,
		});
	}

	private isValid(value: number): boolean {
		return value >= this.minQuestionsCount && value <= this.maxQuestionsCount;
	}

	private isDirty(value: number): boolean {
		return value !== this.props.totalPossible;
	}

	private onTotalPossibleChanged(value: string, validator: IFormControlValidatorResult) {
		const parsed = parseInt(value);
		this.setState({
			value: parsed,
			validator: validator,
			touched: !this.isValid(parsed),
			dirty: !!parsed && this.isDirty(parsed),
			valid: validator.valid,
		});
		console.log('Total possible was changed. New value: ' + value);
	}


	private totalPossibleValidator(value): IFormControlValidatorResult {
		let message = '';
		let valid = true;
		if (!value) {
			message = 'Required';
			valid = false;
		} else if (!this.isValid(value)) {
			message = `Total Possible must be between ${this.minQuestionsCount}-${this.maxQuestionsCount}`;
			valid = false;
		}

		if (value) {
			if (value > this.maxQuestionsCount || value < this.minQuestionsCount) {
				this.timerId = setTimeout(() => {
					if (!this.timerId) {
						return;
					}
					this.setState({
						value: value > this.maxQuestionsCount && this.maxQuestionsCount || this.minQuestionsCount,
						valid: true,
					});
				}, 1500);
			} else {
				if (this.timerId) {
					clearTimeout(this.timerId);
					this.timerId = null;
				}
			}
		}

		return {
			valid: valid,
			message: message,
		};
	}

	render() {
		const touched = this.props.touched || this.state.touched;
		return <>
			<span>Total Possible: </span>
			{this.props.canEdit
				?
				<div className='total-possible-input-field'>
					<TextInput
						value={!isNaN(this.state.value) && this.state.value.toString() || ''}
						validator={(value) => this.totalPossibleValidator(value)}
						onEdit={(value, validation) => this.onTotalPossibleChanged(value, validation)}
						type='number'
						touched={touched}
						inputClassName='total-possible-input'
						onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
							const value = event.target.value;
							if (!value) {
								const newValue = 1;
								this.setState({
									value: 1,
									valid: true,
									dirty: this.isDirty(newValue),
								});
							}
						}}
					/>
					<div className='control-buttons'>
						<div>
							<i className='fa fa-caret-up'
							   onClick={() => {
								   const {value} = this.state;
								   const updValue = (value || 0) + 1;
								   this.setState({
									   value: updValue > this.maxQuestionsCount + 1 && value || updValue,
									   dirty: true,
									   valid: updValue && updValue <= this.maxQuestionsCount,
									   touched: true,
								   });
							   }}></i>
						</div>
						<div>
							<i className={`fa fa-caret-down ${(!this.state.value || this.state.value <= this.minQuestionsCount) && 'inactive'}`}
							   onClick={() => {
								   const {value} = this.state;
								   if (!value) {
									   return;
								   }

								   const updValue = value - 1;
								   if (!updValue) {
									   return;
								   }
								   this.setState({
									   value: updValue,
									   dirty: true,
									   valid: updValue && updValue >= this.minQuestionsCount,
									   touched: true,
								   });
							   }}></i>
						</div>
					</div>
				</div>
				: <span>{this.props.totalPossible.toString()}</span>
			}
		</>;
	}
}