import {PropsWithChildren} from 'react';
import styles from './styles.module.less';
import {Label} from '@esgillc/ui-kit/form-control';

type ReportHeaderFieldProps = PropsWithChildren<{
	title?: string;
	value?: string | undefined;
}>;

export function ReportHeaderField({children, title, value}: ReportHeaderFieldProps) {
	return (
		<div className={styles.reportHeaderField}>
			{children ?? (
				<>
					<Label className={styles.title}>{title}:</Label>
					<div className={styles.value}>{value}</div>
				</>
			)}
		</div>
	);
}
