import {Dispatch, useCallback, useMemo} from 'react';
import {StudentSectionMode, StudentSectionModePermissions} from '../types';
import {DragDropMode, Edit} from '@esgi/ui/icons';
import {StudentSectionModeButtonsPermissions} from '../../../types';
import {SectionModeButtonItem} from '@esgi/main/features/teacher/home';
import {StudentsPanelTabID} from '@esgi/main/features/teacher/students-panel';

export function useSectionModeButtons({
	sectionMode,
	setSectionMode,
	activeTab,
	sectionModePermissions,
}: {
	sectionMode: StudentSectionMode;
	setSectionMode: Dispatch<StudentSectionMode>;
	activeTab: StudentsPanelTabID;
	sectionModePermissions: StudentSectionModePermissions;
}) {
	const modeButtonsPermissions = useMemo<StudentSectionModeButtonsPermissions>(
		() => ({
			[StudentSectionMode.Edit]: sectionModePermissions[StudentSectionMode.Edit][activeTab],
			[StudentSectionMode.Rearrange]: sectionModePermissions[StudentSectionMode.Rearrange][activeTab],
		}),
		[activeTab, sectionModePermissions],
	);

	const handleSetSectionMode = useCallback(
		(activateMode: StudentSectionMode) => {
			setSectionMode(activateMode === sectionMode ? StudentSectionMode.View : activateMode);
		},
		[sectionMode, setSectionMode],
	);

	return useMemo(
		() =>
			[
				{
					dataCy: 'students-panel-action-button-edit',
					activateMode: StudentSectionMode.Edit,
					Icon: Edit,
					tooltip: 'Edit',
				},
				{
					dataCy: 'students-panel-action-button-drag-and-drop',
					activateMode: StudentSectionMode.Rearrange,
					Icon: DragDropMode,
					tooltip: 'Reorder',
				},
			].map<SectionModeButtonItem>(({Icon, activateMode, dataCy, tooltip,}) => {
				return {
					dataCy,
					Icon,
					tooltip,
					onClick: () => handleSetSectionMode(activateMode),
					isActive: sectionMode === activateMode,
					permitted:
						(modeButtonsPermissions[activateMode] && sectionMode === StudentSectionMode.View) ||
						sectionMode === activateMode,
				};
			}),
		[sectionMode, modeButtonsPermissions, handleSetSectionMode],
	);
}
