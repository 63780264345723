import {createContext, useContext} from 'react';
import {useServiceFactory} from '@esgi/core/service';
import {AiAnalysisService, StudentService} from '../services';

export const useAiAnalysis = () => {
	const service = useServiceFactory(
		() => new AiAnalysisService(new StudentService())
	);

	return {service};
};

export const AiAnalysisContext = createContext(null);
export const useAiAnalysisService = () =>
	useContext<AiAnalysisService>(AiAnalysisContext);
