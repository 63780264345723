import {styled} from '@esgi/ui/theme';
import {Range} from '@radix-ui/react-slider';

export const SliderRange = styled(Range, {
	position: 'absolute',
	backgroundColor: '$primaryMuted',

	'&[data-orientation=horizontal]': {
		height: '100%',
	},

	'&[data-orientation=vertical]': {
		width: '100%',
	},

	'&[data-disabled]': {
		backgroundColor: '$mild',
	},
});
