import {styled} from '@esgi/ui/theme';
import {FlexBox, Box} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const SkeletonContainer = styled(FlexBox, {
	padding: '$4',
	flexDirection: 'column',
	gap: '$2',
});

export const SkeletonRow = styled(Box, {
	...SkeletonStyles,
	height: 18,
	borderRadius: 4,
});
