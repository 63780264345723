import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	className?: string;
	children?: any;
}

export function QuestionFooter(props: Props) {
	return <div {...props} className={join(styles.footer, props.className)} data-cy='question-footer'/>;
}
