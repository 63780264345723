import React, {createRef} from 'react';
import {OverlayScrollbars} from 'overlayscrollbars';
import {Button, PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import {
	RubricEditForm,
	CriteriaActiveView, DescriptionActiveView, LevelActiveView,
	validateCriteria,
	validateDescriptions,
	validateLevels,
} from '../../../common/rubric-edit-form';
import RubricService from '../../../common/rubric-service';
import RubricDetailsService from '../../rubric-details-service';
import styles from './editor.module.less';

interface Props {
	detailsService: RubricDetailsService;
	onCancelClicked: () => void;
	onSaveClicked: () => void;
}

export default class RubricEditorModal extends React.PureComponent<Props> {
	private readonly modalManagerRef: ModalManagerRef = createRef();
	private readonly criteriaToRef: CriteriaActiveView[] = [];
	private readonly levelsToRef: LevelActiveView[] = [];
	private readonly descriptionsToRef: DescriptionActiveView[] = [];
	private readonly editService = new RubricService();
	private osInstance: OverlayScrollbars;

	constructor(props: Props) {
		super(props);
		this.editService.initFromModel(props.detailsService.serialize());
	}

	private onCriteriaRendered = (id: number, ref: CriteriaActiveView) => {
		this.criteriaToRef[id] = ref;
	};

	private onLevelRendered = (id: number, ref: LevelActiveView) => {
		this.levelsToRef[id] = ref;
	};

	private onDescriptionsRendered = (id: number, ref: DescriptionActiveView) => {
		this.descriptionsToRef[id] = ref;
	};

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader className={styles.modalHeader}>
				<Title className={styles.title}>
					Edit Test
				</Title>
			</ModalHeader>
			<ModalBody className={styles.body}>
				<div className={styles.subHeader}>
					<div className={styles.rubricName}>
						{this.editService.testInfo$.value.name}
					</div>
					<div className={styles.rubricName}>
						:
					</div>
					<div className={styles.rubricDescription}>
						{this.editService.testInfo$.value.description}
					</div>
				</div>
				<RubricEditForm overlayScrollRef={(ref) => this.osInstance = ref}
				                options={{
					                criteriaOptions: {editMode: true},
					                levelsOptions: {editMode: true},
					                descriptionsOptions: {editMode: true},
				                }}
				                prohibitModifyScore={!this.props.detailsService.canModifyScore}
				                rubricService={this.editService}
				                onCriteriaRendered={this.onCriteriaRendered}
				                onLevelsRendered={this.onLevelRendered}
				                onDescriptionsRendered={this.onDescriptionsRendered}
				/>
			</ModalBody>
			<ModalFooter className={styles.footer}>
				<div>
					<Button onClick={() => this.onCancelClicked()}>CANCEL</Button>
				</div>
				<div className={styles.footerLeftActions}>
					<PrimaryButton onClick={() => this.onSaveClicked()}>SAVE</PrimaryButton>
				</div>
			</ModalFooter>
		</Modal>;
	}

	private validate(): boolean {
		const criteriaValid = validateCriteria({
			criteria: this.editService.criteria$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.criteriaToRef,
			},
		});

		if (!criteriaValid) {
			return false;
		}

		const levelsValid = validateLevels({
			levels: this.editService.levels$.value,
			displayMode: this.editService.levelDisplayMode$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.levelsToRef,
			},
		});

		if (!levelsValid) {
			return false;
		}

		return validateDescriptions({
			criteria: this.editService.criteria$.value,
			levels: this.editService.levels$.value,
			descriptions: this.editService.descriptions$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.descriptionsToRef,
			},
		});
	}

	private onCancelClicked(): void {
		this.modalManagerRef.current.close(this.props.onCancelClicked);
	}

	private onSaveClicked(): void {
		const isValid = this.validate();

		if (isValid) {
			const {levels$, criteria$, descriptions$} = this.props.detailsService;
			levels$.next(this.editService.levels$.value);
			criteria$.next(this.editService.criteria$.value);
			descriptions$.next(this.editService.descriptions$.value);

			this.modalManagerRef.current.close(this.props.onSaveClicked);
		}
	}

	public componentWillUnmount() {
		this.editService.destroy();
	}
}
