import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {isIOS as isDeviceIOS, isVerticalOrientation, join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import styles from '../../styles.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TestsRow} from '../tests-row';
import FormService from '../../../../../services/form-service';
import {SessionDropdown} from '../../../../../session-dropdown';
import {Alerts} from '../alerts';

interface Props {
	service: FormService
}

export function TableWrapper({service}: Props) {
	const tests = useBehaviorSubject(service.tests);
	const includeSessionOption = useBehaviorSubject(service.settingsService.includeSessionOption);
	const model = useBehaviorSubject(service.model);
	const {
		testResultsCorrectVerbiage,
		testResultsIncorrectVerbiage,
	} = model;
	const selectedDate = useBehaviorSubject(service.selectedDate);
	const tableRef = useRef<HTMLTableElement>(null);
	const [incorrectVerbiageColumnHeader, setIncorrectVerbiageColumnHeader] = useState('');
	const [isVisible, setIsVisible] = useState(false);
	const isIOS = isDeviceIOS();
	const tracks = useMemo(() => model.tracks.map(track => {
		return {id: track.trackID, dateTo: track.dateTo?.format('MM-DD-YY'), dateFrom: track.dateFrom?.format('MM-DD-YY')};
	}), [model.tracks]);

	const setVerbiageColumnHeader = useCallback(() => {
		const newVal = ((isIOS && isVerticalOrientation() && includeSessionOption) ? '' : 'Number of ') + testResultsIncorrectVerbiage + ' items to print';
		setIncorrectVerbiageColumnHeader(newVal);
	}, [isIOS, includeSessionOption, testResultsIncorrectVerbiage]);

	useEffect(() => {
		setVerbiageColumnHeader();
	}, [setVerbiageColumnHeader]);

	useEffect(() => {
		if (isIOS) {
			window.addEventListener('orientationchange', setVerbiageColumnHeader);
			return () => {
				window.removeEventListener('orientationchange ', setVerbiageColumnHeader);
			};
		}
	}, [setVerbiageColumnHeader]);

	function openSessionDropdown() {
		setIsVisible(true);
	}

	return <div className={join(styles.overlayWrapper, styles.showAlert)}>
		<div className={styles.tableWrapper} ref={tableRef}>
			<table className='table table-striped table-bonnie'>
				<thead>
				<tr>
					<th className={join('test-name-cell', styles.alertContainer)}>
						<strong>Test Name</strong><br/>
						<Alerts service={service} tableRef={tableRef}/>
					</th>
					<th className={join(styles.resultsCell, 'table-block', 'text-center')}>
						{testResultsCorrectVerbiage}
					</th>
					<th className={join(styles.resultsCell, 'text-center')}>{testResultsIncorrectVerbiage}</th>
					<th className={join(styles.resultsCell, 'text-center')}>Skipped</th>
					<th className={join(styles.maxItemsCell, 'table-block')}>
						<span>{incorrectVerbiageColumnHeader}</span>
						<OnHoverTooltip message='Help'><a
							href='https://support.esgisoftware.com/hc/en-us/articles/209159806#number'
							target='_blank' rel='noreferrer'>
							<sup className='fa fa-question-circle' aria-hidden='true'/>
						</a></OnHoverTooltip>
					</th>
					{includeSessionOption && <>
						<th className={styles.textLeft}>
							<div className={styles.chooseSessionHeader}>
								<span className={styles.chooseSessionTitle}>View session as of</span>
								<i className='fa fa-caret-down' aria-hidden='true'
								   onClick={() => openSessionDropdown()}/>
							</div>
							<SessionDropdown tracks={tracks} date={selectedDate} onHide={() => setIsVisible(false)}
							                 isVisible={isVisible} onChange={(newDate) => service.updateDate(newDate)}/>
						</th>
					</>}
				</tr>
				</thead>
				<tbody>
				{tests.map(test => {
					return <TestsRow key={test.id} model={test} includeSessionOption={includeSessionOption}
					                 service={service}/>;
				})}
				</tbody>
			</table>
		</div>
	</div>;
}
