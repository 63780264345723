import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type DrawerContextValue = {
	closeDrawer: VoidFunction;
	closeDrawerWithCbBefore: VoidFunction;
	withPanel: boolean;
	setWithPanel: (value: boolean) => void;
	togglePanelOpen: VoidFunction;
	isPanelOpen: boolean;
	panelRef: React.RefObject<HTMLDivElement> | null;
};

export const DrawerContext = createContext<DrawerContextValue>({
	closeDrawer: noop,
	closeDrawerWithCbBefore: noop,
	withPanel: false,
	setWithPanel: noop,
	togglePanelOpen: noop,
	isPanelOpen: false,
	panelRef: null,
});

export function useDrawerContext() {
	return useContext(DrawerContext);
}
