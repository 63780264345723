import {TestList} from './index.styled';
import {TestRow} from '../row';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import React, {useCallback, useState} from 'react';
import {Test, TestType} from '../../../../service/types';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {Skeleton} from '../skeleton';
import {lazyComponent} from '@esgi/core/react';
import {isNull} from 'underscore';
import {TestDetailsState} from './types';
import {UserInfo} from '@esgi/core/authentication';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

type Props = {
	tests: Test[];
	selectedTests: number[];
	loaded: boolean;
	osRef: React.MutableRefObject<OverlayScrollbarsComponentRef<'div'>>;
	onTestSelect: (id: number) => void;
	user: UserInfo
};

export function Body({tests, selectedTests, loaded, onTestSelect, osRef, user}: Props) {

	const [testDetailsState, setTestDetailsState] = useState<TestDetailsState | null>(null);

	const closeTestDetails = useCallback(() => {
		setTestDetailsState(null);
	}, []);

	return (
		<>
			<OverlayScrollbarsComponent ref={osRef} defer options={{scrollbars: {autoHide: 'leave'}}}>
				<TestList offset={Boolean(selectedTests.length)}>
					{tests.map((test) => (
						<TestRow
							key={test.id + test.created}
							testInfo={test}
							onTestSelect={onTestSelect}
							selected={selectedTests?.includes(test.id) || test.disabled}
							onTestNameClick={setTestDetailsState}
						/>
					))}
					{!loaded && <Skeleton />}
				</TestList>
			</OverlayScrollbarsComponent>

			{!isNull(testDetailsState) &&
				(testDetailsState.testType === TestType.Score || testDetailsState.testType === TestType.YN) && (
					<TestDetails
						testID={testDetailsState.testId}
						firstName={user.firstName}
						lastName={user.lastName}
						userID={user.userID}
						close={closeTestDetails}
						disableCopyTest
					/>
			)}

			{!isNull(testDetailsState) && testDetailsState.testType === TestType.Rubric && (
				<RubricDetails testID={testDetailsState.testId} disableCopy disableAddToSubject close={closeTestDetails} />
			)}
		</>
	);
}
