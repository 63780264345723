import {Group, Student} from '@esgi/main/libs/store';

export type GroupItem = Omit<Group, 'studentIDs'> & {
	students: Student[];
};

export type GroupBucket = GroupItem & {
	isListOpen: boolean;
};

export enum AllOptionItem {
	Value = 'all',
	Label = 'All Classes',
}
