import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {useCallback} from 'react';
import {Entity} from '../../types/table-level-report-service-types';
import styles from './styles.module.less';
import {Label} from '@esgillc/ui-kit/form-control';

type ReportDropdownProps = {
	title: string;
	entities: Entity[];
	onSelected: (entity: Entity) => void;
	selectedValue: Entity;
};

export function ReportDropdown({title, entities, onSelected, selectedValue}: ReportDropdownProps) {
	const setValue = useCallback(
		(value: Entity[]) => {
			value[0] && onSelected(value[0]);
		},
		[onSelected],
	);

	return (
		<div data-cy='rubric-report-dropdown' className={styles.dropdownContainer}>
			<Label className={styles.dropdownTitle}>{title}:</Label>
			<Dropdown setValue={setValue} optionName='value' className={styles.dropdown} value={[selectedValue]}>
				{entities.map((entity) => (
					<Option key={entity.id} value={entity}>
						{entity.value}
					</Option>
				))}
			</Dropdown>
		</div>
	);
}
