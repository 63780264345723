import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {Schools} from 'modules/forms/students-form/types';

export type DistrictSpecialistFormType = FormGroup<{
	school: FormControl<Schools[]>,
	specialistGroupIDs: FormControl<number[]>
},
	ControlValue<{
		school: FormControl<Schools[]>,
		specialistGroupIDs: FormControl<number[]>
	}>>

export const createDistrictSpecialistForm = ():
	DistrictSpecialistFormType => new FormGroup({
	school: new FormControl([], {validators: [Validators.required()]}),
	specialistGroupIDs: new FormControl([]),
});
