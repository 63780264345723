import React, {ReactNode} from 'react';
import styles from './box.module.less';


export class Box extends React.PureComponent<{ children?: ReactNode }> {
	render(): JSX.Element | false | null {
		return <div className={styles.box}>
			{this.props.children}
		</div>;
	}
}

