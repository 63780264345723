import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const Panel = styled(Box, {
	overflow: 'hidden',
	height: '100%',
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',

	'> div': {
		width: '100%',
	},
});