import {ToggleGroupContent} from './components/toggle-group-content';
import {ToggleGroupItem} from './components/toggle-group-item';
import {ToggleGroupRoot} from './components/toggle-group-root';
import {ToggleGroupTitle} from './components/toggle-group-title';

export const ToggleGroup = Object.assign(
	{},
	{
		Root: ToggleGroupRoot,
		Content: ToggleGroupContent,
		Item: ToggleGroupItem,
		Title: ToggleGroupTitle,
	},
);

export {type ToggleGroupOrientation} from './types';
