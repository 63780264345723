import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, takeUntil} from 'rxjs/operators';
import {EventBusDispatcher} from '@esgillc/events';
import {validateCriteria, validateLevels} from '../../common/rubric-edit-form';
import RubricService from '../../common/rubric-service';
import {CompleteStepInfo, SubjectModel} from '../../common/types';
import {RubricCreatedEvent} from '../events';
import {initializeCriteria, initializeLevels} from '../../common/utils/initializing';

export default class RubricCreatingService extends RubricService {
	public readonly selectedSubjects$: BehaviorSubject<SubjectModel[]> = new BehaviorSubject<SubjectModel[]>([]);

	public get completeStepInfo$(): Observable<CompleteStepInfo> {
		if (!this.completeStepInfo) {
			this.completeStepInfo = this.httpClient.ESGIApi
				.get<CompleteStepInfo>(this.controller, 'init/complete-step')
				.pipe(shareReplay(1))
				.asObservable();
		}
		return this.completeStepInfo;
	}

	protected readonly controller = 'assets/rubric';
	protected completeStepInfo: Observable<CompleteStepInfo>;

	constructor() {
		super();
		let criteria = [], levels = [], descriptions = [];

		const initializeCriteriaResult = initializeCriteria(levels);
		criteria = initializeCriteriaResult.criteria;

		const initializeLevelsResult = initializeLevels(criteria);
		levels = initializeLevelsResult.levels;
		descriptions = initializeLevelsResult.descriptions;

		this.updateEntities({criteria, levels, descriptions});
	}

	public get criteriaValid$(): Observable<boolean> {
		return this.criteria$.pipe(
			takeUntil(this.destroy$),
			map(c => validateCriteria({criteria: c})),
			distinctUntilChanged(),
		);
	}

	public get levelsValid$(): Observable<boolean> {
		return combineLatest(this.levelDisplayMode$, this.levels$).pipe(
			takeUntil(this.destroy$),
			map(p => validateLevels({levels: p[1], displayMode: p[0]})),
			distinctUntilChanged(),
		);
	}

	public save(): Observable<number> {
		const model = this.serialize();
		return this.httpClient.ESGIApi.post<{ rubricID: number, createDate: string }>(this.controller, 'create', {
			...model,
			subjects: this.selectedSubjects$.value,
		}).pipe(map((r) => {
			EventBusDispatcher.dispatch(RubricCreatedEvent, new RubricCreatedEvent(r.rubricID, r.createDate, model));
			return r.rubricID;
		})).asObservable();
	}
}
