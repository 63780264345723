import {LeftPanel} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const LeftPanelRoot = styled(LeftPanel.Root, {
	maxWidth: 'unset',
	width: 272,
	paddingLeft: 20,
	paddingRight: 20,
});

export const LeftPanelHeader = styled(LeftPanel.Header, {
	display: 'grid',
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	alignItems: 'center',
	gap: '$2',
});

export const LeftPanelBody = styled(LeftPanel.Body, {
	display: 'grid',
	gridTemplateRows: 'auto auto 1fr',
	gap: 20,
	overflow: 'hidden',
});

export const AssignmentsSkeletonBox = styled(GridBox, {
	gap: 6,
	gridAutoRows: 'max-content',
});
