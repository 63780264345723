import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {Checkbox as BaseCheckbox} from '../../../checkbox/index';
import {useCheckboxGroupContext} from '../../context';
import {CheckedState} from '@radix-ui/react-checkbox';

export type CheckboxProps = ComponentPropsWithoutRef<'button'> & {
	/** The value of the checkbox as string or number(not related to checked state). Value should be unique in the
	 *  group scope. */
	value: string | number;

	/** The label of the checkbox. */
	label: string;
} & Skeletonable & BaseComponentProps;

export const GroupCheckboxItem = forwardRef<HTMLButtonElement, CheckboxProps>(({value, dataCy='ui-kit-checkbox-group-item', ...props}: CheckboxProps, ref) => {
	const {checked, onChange, disabled} = useCheckboxGroupContext();

	const onChangeChecked = (item: string | number, isChecked: CheckedState) => {
		onChange(isChecked ? [...checked, item] : checked.filter(el => el !== item));
	};

	return <BaseCheckbox
		ref={ref}
		checked={checked.includes(value)}
		onCheckedChange={(isChecked) => onChangeChecked(value, isChecked)}
		disabled={disabled}
		dataCy={dataCy}
		{...props}
	/>;
});
