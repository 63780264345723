import {RefObject, useLayoutEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {StudentCredentialsSearchData, Student} from '../../../types/types';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react';

type UseScrollToStudentParameters = {
	tableRef: RefObject<OverlayScrollbarsComponentRef<'div'>>;
	tableBodyRef: RefObject<HTMLDivElement>;
	data: Student[];
};

export function useScrollToStudent({tableRef, tableBodyRef, data}: UseScrollToStudentParameters) {
	const [searchParams, updateSearchParams] = useSearchParams();
	const [activeStudentRowIndex, setActiveStudentRowIndex] = useState<number>();

	useLayoutEffect(() => {
		const studentId = searchParams.get(StudentCredentialsSearchData.StudentId);

		if (!studentId) {
			if (activeStudentRowIndex) {
				setActiveStudentRowIndex(undefined);
			}

			return;
		}

		if (data && tableRef.current && tableBodyRef.current) {
			const overlayScrollInstance = tableRef.current.osInstance();

			const studentRowIndex = data.findIndex(({id}) => String(id) === studentId);
			const studentRow = tableBodyRef.current.querySelector<HTMLDivElement>(`div:nth-child(${studentRowIndex + 1})`);

			if (overlayScrollInstance && studentRow) {
				const content = overlayScrollInstance.elements().content;

				content.scrollTop = studentRow.offsetTop;

				setActiveStudentRowIndex(studentRowIndex);

				searchParams.delete(StudentCredentialsSearchData.StudentId);
				updateSearchParams(searchParams, {replace: true});
			}
		}
	}, [data]);

	return {
		activeStudentRowIndex,
	};
}
