import {useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Field} from 'modules/subject-details/types';
import SearchableList from '../searchable-list';
import styles from './styles.module.less';

class Props {
	title: string;
	description: string;
	source: Field[] = [];
	dropdownLabel: string;
	searchPlaceholder: string;
	initiallySelected: Field[];
	onConfirm: (values: Field[]) => void;
	onCancel: () => void;
}

export default function BaseSelectorModal({
	title,
	dropdownLabel,
	description,
	initiallySelected,
	searchPlaceholder,
	onCancel,
	onConfirm,
	source,
}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	const [selectedValues, setSelectedValues] = useState<Field[]>(initiallySelected || []);

	const confirm = () => {
		onConfirm(selectedValues);
		handleClose();
	};

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title>{title}</Title>
		</Modal.Header>
		<Modal.Body className={styles.modalBody}>
			<div data-cy={'selector-modal-body'}>
			<div data-cy={'body'} className={styles.body}>
				<div data-cy={'description'} className={styles.description}>
					{description}
				</div>
				<SearchableList
					values={source}
					selectedValues={selectedValues}
					dropdownLabel={dropdownLabel}
					searchPlaceholder={searchPlaceholder}
					onSelect={v => setSelectedValues(v)}
				/>
			</div>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Gray onClick={handleClose}>CANCEL</Buttons.Gray>
			<Buttons.Contained onClick={confirm} disabled={!selectedValues.length}>
				CONFIRM
			</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
