import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {CSS} from '@stitches/react';

const FiltersPanelWidth = 54;

export const Layout = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	background: '$border',
	height: '100%',
	width: '100%',
	variants: {
		isSmallScreen: {
			true: {
				gridTemplateColumns: 'auto',
			},
		},
	},
});

export const ContentWrapper = styled(GridBox, {
	gridTemplateRows: 'repeat(2, max-content) 1fr',
	overflow: 'hidden',
	gap: '$4',
	variants: {
		isSmallScreen: {
			true: {
				width: `calc(100% - ${FiltersPanelWidth}px)`,
				justifySelf: 'end',
			},
		},
	},
});

export const FiltersTabsContainer = styled(GridBox, {
	gap: '$3',
	gridTemplateColumns: 'repeat(3, max-content)',
	alignItems: 'center',
});

export const FiltersContainer = styled(GridBox, {
	height: '100%',
	gap: '$3',
	gridTemplateColumns: 'max-content 200px',
	padding: '0px 20px',
	justifyContent: 'space-between',
	alignItems: 'center',

	variants: {
		isSmallScreen: {
			true: {
				gridTemplateColumns: 'max-content 170px',
			},
		},
	},
});

export const cardViewItemCSS: CSS = {
	padding: 0,
	width: 34,
	height: 34,
};
