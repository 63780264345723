import './styles.less';
import React from 'react';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {TextInput, Validators} from '@esgi/deprecated/elements';
import {dispatchAppEvent, EventBusDispatcher, EventBusManager} from '@esgillc/events';
import {
	RemoveClickedEvent,
	SaveAndAddClickedEvent,
	SaveClickedEvent,
	SpecialistGroupCreatedEvent, SpecialistGroupRemoveEvent, SpecialistGroupChangedEvent,
} from './events';
import {HttpClient} from '@esgi/api';
import {Loader} from '@esgillc/ui-kit/loader';

class Props{
	groupID: number;
	groupName: string;
	userID: number;
	numberOfGroups: number;
	onSaved: () => void;
}

class State {
	groupName: string = '';
	groupNameValid: boolean;
	touched: boolean = false;
	saving: boolean;
}

export class SpecialistGroupForm extends React.Component<Props, State> {
	private _eventBus = new EventBusManager();
	labelRef: Node = null;

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	state: State;

	componentDidMount() {
		if (this.props.groupID) {
			this.setState({
				groupName: this.props.groupName,
				groupNameValid: true,
			});
		}

		this._eventBus.subscribe(SaveClickedEvent, () => {
			this.handleSaveOnClick();
		});
		this._eventBus.subscribe(SaveAndAddClickedEvent, () => {
			this.handleSaveOnClick();
		});
		this._eventBus.subscribe(RemoveClickedEvent, () => {
			this.server.remove();
		});

	}

	componentWillUnmount() {
		this._eventBus.destroy();
		$(this.labelRef).bstooltip('dispose');
	}

	server = {
		create: () => {
			if (this.valid()) {
				this.setState({saving: true})
				return HttpClient.default.ESGIApi.post<{ groupID: number }>('modules/forms/specialist-group', 'create', {
					Name: this.state.groupName,
					UserID: this.props.userID,
				}).subscribe((response) => {
					dispatchAppEvent(SpecialistGroupCreatedEvent, new SpecialistGroupCreatedEvent(response.groupID, this.state.groupName, this.props.userID));
					this.props.onSaved();
				}, () => {
					this.setState({saving: false})
				});
			}
		},
		update: () => {
			if (this.valid()) {
				this.setState({saving: true})
				return HttpClient.default.ESGIApi.post<{ groupID: number }>('modules/forms/specialist-group', 'update', {
					specialistGroupID: this.props.groupID,
					specialistGroup: this.state.groupName,
				}).subscribe(() => {
					EventBusDispatcher.dispatch(SpecialistGroupChangedEvent, new SpecialistGroupChangedEvent(this.props.groupID, this.state.groupName));
					this.props.onSaved();
				}, () => {
					this.setState({saving: false})
				});
			}
		},
		remove: () => {
			if(this.props.numberOfGroups === 1) {
				return OldAlerts.bsalert('Sorry. You must have at least one group in each school year.');
			}

			OldAlerts.bsconfirm('Are you sure you want to delete this group? This will NOT delete students and their data.' +
				' The students still will be available, and can be added to another group.', (result) => {
				if (result) {
					this.setState({saving: true})
					return HttpClient.default.ESGIApi.post('modules/forms/specialist-group', 'remove', {groupID: this.props.groupID})
						.subscribe(() => {
							EventBusDispatcher.dispatch(SpecialistGroupRemoveEvent, new SpecialistGroupRemoveEvent(this.props.groupID));
							this.props.onSaved();
						}, () => {
							this.setState({saving: false})
						});
				}
			});
		},
	};


	valid() {
		return this.state.groupNameValid;
	}

	handleSaveOnClick() {
		this.setState({touched: true});
		if (this.props.groupID) {
			return this.server.update();
		} else {
			return this.server.create();
		}
	}

	showTooltip() {
		$(this.labelRef).bstooltip('destroy');
		setTimeout(() => {
			if ($(this.labelRef).width() >= 225) {
				$(this.labelRef).bstooltip({
					placement: 'bottom',
					title: this.state.groupName,
				});
			}
		}, 10);
	}

	updateGroupName(value, valid) {
		this.setState({groupName: value, groupNameValid: valid});
		this.showTooltip();
	}

	render() {
		return <div id='specialist-group-edit-form' className='container'>
			<Loader show={this.state.saving} fullscreen/>
			<div className='row'>
				<div className='col-md-12 col-xs-12'>
					<TextInput
						label={'Group Name'}
						value={this.state.groupName}
						touched={this.state.touched}
						onEdit={(value, validation) => this.updateGroupName(value, validation.valid)}
						validator={(value: string) => Validators.requiredValidator(value)}
						inputClassName='edit-group-name-input'
						placeholder={'i.e. ELL Learners'}
					/>
				</div>
			</div>
		</div>;

	}
}
