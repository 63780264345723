import {HierarchySnapshot} from 'modules/hierarchy/models';
import {GoogleAnalyticsTracker} from '@esgi/core/tracker';
import {ChangesCollector} from '../utils/changes-collector';
import {ModalHeader} from './total-report-header';
import {ModalWindow} from '@esgi/deprecated/knockout';
import {TotalReport} from './total-report';
import {ReportType, SubjectType} from '@esgi/core/enums';

export default class TotalReportModal {

	events = {
		onClosed: (callback) => {
			$(this).on('onClosed', callback);
		},
	};

	constructor(
		public options: IOptions,
		private changesCollector: ChangesCollector,
		private hierarchy: HierarchySnapshot,
	) {}

	public load() {
		GoogleAnalyticsTracker.trackEvent('TotalsReport');

		let subjectChanged = null;
		let classSelected = null;
		let groupSelected = null;

		const header = new ModalHeader();

		header.events.excel(() => {
			ctr.view.downloadExcel();
		});
		header.events.pdf(() => {
			ctr.view.downloadPdf();
		});
		header.events.closed(() => {
			this.changesCollector.applyChanges({
				subjectTabID: subjectChanged?.id,
				subjectTabType: subjectChanged?.type ? SubjectType[subjectChanged.type as string] : null,
				classID: classSelected,
				groupID: groupSelected,
			});

			ctr.view.closeTotalReport();
			$(this).trigger('onClosed');
			modal.close();
		});

		const ctr = new TotalReport({
			globalSchoolYearID: this.options.globalSchoolYearId,
			reportID: this.options.reportId,
			subjectType: this.options.subjectType,
			reportType: this.options.reportType,
			subjectID: this.options.subjectId,
			sourceID: null,
			hierarchy: this.hierarchy,
		});

		const modal = new ModalWindow(ctr, {
			className: 'total-report-dialog blue-header',
			fullscreen: false,
			buttons: [],
			allowClose: false,
			showHeader: false,
			showFooter: false,
			responsive: true,
			header,
			animate: true,
		});

		modal.load();

		ctr.events.onSubjectChanged((e, s) => {
			subjectChanged = s;
		});
		ctr.events.onClassChanged((event, classId) => {
			classSelected = classId;
		});
		ctr.events.onGroupChanged((event, groupId) => {
			groupSelected = groupId;
		});
		ctr.events.aborted(() => {
			modal.close();
		});
		ctr.events.loaded(() => {
			switch (this.options.reportType) {
				case ReportType.Class:
					header.reportName = 'Class Total Report';
					break;
				case ReportType.District:
					header.reportName = 'District Total Report';
					break;
				case ReportType.Group:
				case ReportType.SpecialistGroup:
					header.reportName = 'Group Total Report';
					break;
				case ReportType.School:
					header.reportName = 'School Total Report';
					break;
				case ReportType.SchoolSpecialist:
					header.reportName = 'School Specialist Total Report';
					break;
				case ReportType.StudentsDistrict:
					header.reportName = 'District Students Total Report';
					break;
				case ReportType.StudentsSchool:
					header.reportName = 'School Students Total Report';
					break;
				case ReportType.DistrictSpecialist:
					header.reportName = 'District Specialist Totals Report';
					break;
				case ReportType.DistrictPreassess:
					header.reportName = 'District Pre-Assess Totals Report';
					break;
				case ReportType.DistrictSpecialistStudents:
					header.reportName = 'District Specialist Students Report';
					break;
				case ReportType.DistrictPreassessStudents:
					header.reportName = 'District Pre-Assess Students Report';
					break;
				case ReportType.SchoolsGroup:
					header.reportName = 'School Group Total Report';
					break;
				case ReportType.TeachersGroup:
					header.reportName = 'Teacher Group Total Report';
					break;
				case ReportType.StudentsSchoolsGroup:
					header.reportName = 'School Group Students Total Report';
					break;
				case ReportType.StudentsTeachersGroup:
					header.reportName = 'Teacher Group Students Total Report';
					break;
				case ReportType.GroupOfSpecialists:
					header.reportName = 'Specialists Group Total Report';
					break;
				case ReportType.GroupOfSpecialistsStudents:
					header.reportName = 'Specialists Group Students Total Report';
					break;
			}
		});
		ctr.events.emptyReport((event, emptyReport) => {
			header.showDownload = !emptyReport;
		});
	}
}

export interface IOptions {
	subjectId: number;
	subjectType: SubjectType;
	reportType: ReportType;
	reportId: number;
	globalSchoolYearId: number;
}
