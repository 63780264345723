export interface IHtmlGenerator {
	getBody(): string;
	getStyle(): string;
}

export class Printer {

	private readonly windowProvider: any;
	constructor(private readonly testMode: boolean) {
    	this.windowProvider = window;
	}

	print(htmlGenerator: IHtmlGenerator) {
    	if (this.windowProvider.reportPrint == undefined) {
			let frame = document.createElement('iframe');
			frame.setAttribute('name', 'reportPrint')
			frame.setAttribute('frameborder', '0')
			frame.style.display = 'none';
		    document.body.appendChild(frame);
    	}

		let frameBody = this.windowProvider.reportPrint.document.getElementsByTagName('body')[0];
		while (frameBody.lastChild) {
			frameBody.removeChild(frameBody.lastChild);
		}
		let frameHead = this.windowProvider.reportPrint.document.getElementsByTagName('head')[0];
		while (frameHead.lastChild) {
			frameHead.removeChild(frameHead.lastChild);
		}
    	let body = htmlGenerator.getBody();
    	let style = htmlGenerator.getStyle();


    	if (this.testMode) {
    		let w = this.windowProvider.open('', '_blank');
			w.document.getElementsByTagName('head')[0].innerHTML += `<style>${style}</style>`
			w.document.getElementsByTagName('body')[0].innerHTML += body
    	}

		frameHead.innerHTML += `<style>${style}</style>`
		frameBody.innerHTML += body
    	this.windowProvider.reportPrint.focus();
    	try {
    		if (navigator.userAgent.toLowerCase().indexOf('firefox') != -1) {
    			this.windowProvider.reportPrint.this.windowProvider.print();
    		} else {
    			this.windowProvider.reportPrint.document.execCommand('print', false, null);
    		}
    	} catch (e) {
    		this.windowProvider.reportPrint.print();
    	}

	}
}

export class Table {
	headers: Array<Row>;
	rows: Array<Row>;
	constructor() {
    	this.headers = new Array<Row>();
    	this.rows = new Array<Row>();
	}
}

export class Row {
	cells: Array<Cell>;
	constructor() {
    	this.cells = new Array<Cell>();
	}
}

export class Cell {
	value: any;
	colspan: number;
	rowspan: number;
	height: number;
	width: number;
	class: string;
}

export class TableParser {
	parse(thead: HTMLElement, tbody: HTMLElement): Table {
		let table = new Table();
		table.headers = this.parseThead(thead);
		table.rows = this.parseBody(tbody);
		return table;
	}

	parseBody(tbody: HTMLElement) {
		let rawRows = tbody.getElementsByTagName('tr');
		let rows = new Array<Row>();
		for (let i = 0; i < rawRows.length; i++) {
			let cells = rawRows[i].cells;
			let row = new Row();
			for (let j = 0; j < cells.length; j++) {
				row.cells.push(this.extractCell(cells[j]));
			}
			rows.push(row);
		}
		return rows;
	}

	parseThead(thead: HTMLElement): Array<Row> {
		let rawRows = thead.getElementsByTagName('tr');
		let rows = new Array<Row>();
		for (let i = 0; i < rawRows.length; i++) {
			let cells = rawRows[i].cells;
			let row = new Row();
			for (let j = 0; j < cells.length; j++) {
				let cell = this.extractCell(cells[j]);
				cell.height = +cells[j].style.height;
				cell.width = +cells[j].style.width;
				row.cells.push(cell);
			}
			rows.push(row);
		}
		return rows;
	}

	extractCell(rawCell): Cell {
		let cell = new Cell();
		cell.colspan = rawCell.colSpan == undefined ? 1 : rawCell.colSpan;
		cell.rowspan = rawCell.rowSpan == undefined ? 1 : rawCell.rowSpan;
		cell.value = rawCell.innerHTML;
		cell.class = ''
		return cell;
	}
}
