import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@stitches/react';

export const IconWrapper = styled(FlexBox, {
	'& > svg': {
		width: '24px',
		height: '24px',
		fill: '$mediumContrast',

		'& > path': {
			fill: '$mediumContrast',
		},
	},
});