import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {ToggleGroup} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';

export const Label = styled(Text, {
	textAlign: 'center',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	flex: '1',
});

export const CheckboxContainer = styled(Box, {
	padding: '4px',
	backgroundColor: '$vivid',
	borderRadius: '6px',
});
