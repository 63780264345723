import React from 'react';
import {Radio} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Cards, Difficulty} from 'shared/modules/bingo/types/models';

import styles from 'shared/modules/bingo/steps/cards/cards.module.less';

export interface DifficultyPanelProps {
	difficulty: string;
	onChange: (field: keyof Cards, value: boolean | string) => void,
}

export default function DifficultyPanel ({difficulty, onChange}: DifficultyPanelProps) {
	return (
		<div className={styles.panel}>
			<div className={styles.header}>
				<span>Difficulty</span>
				<OnHoverTooltip
					message={<>Easy: 75% from correct answers / 25% from incorrect answers<br/>Medium: 50%
						from correct answers / 50% from incorrect answers<br/>Hard: 25% from correct answers
						/ 75% from incorrect answers</>}>
					<i className='glyphicon glyphicon-question-sign tip' data-cy='difficulty-tip'/>
				</OnHoverTooltip>
			</div>
			<div className={styles.subheader}>Questions will be customized based on student test results.</div>
			<div className={styles.radioBtn}>
				<Radio
					checked={difficulty === Difficulty.Easy}
					value={Difficulty.Easy}
					id='easy'
					onChange={() => onChange('difficulty', Difficulty.Easy)}
				>
					<div className={styles.label}>
						<span className={styles.title}>Easy - </span>
						<span className={styles.difficulty}>Best for creating a fun engaging game for all!</span>
					</div>
				</Radio>
				<Radio
					checked={difficulty === Difficulty.Medium}
					value={Difficulty.Medium}
					id='medium'
					onChange={() => onChange('difficulty', Difficulty.Medium)}
				>
					<div className={styles.label}>
						<span className={styles.title}>Medium - </span>
						<span className={styles.difficulty}>Challenge students to increase their knowledge</span>
					</div>
				</Radio>
				<Radio
					checked={difficulty === Difficulty.Hard}
					value={Difficulty.Hard}
					id='hard'
					onChange={() => onChange('difficulty', Difficulty.Hard)}
				>
					<div className={styles.label}>
						<span className={styles.title}>Hard - </span>
						<span className={styles.difficulty}>Test for mastery</span>
					</div>
				</Radio>
			</div>
		</div>
	);
}
