import {ElementStatus, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, OnErrorTooltip, Option} from '@esgillc/ui-kit/form-control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import {CommonData} from 'modules/subject-details/types';
import Section from '../index';
import styles from './styles.module.less';

interface Props {
	form: DistrictFormType | SchoolFormType;
	commonData: CommonData;
}

export default function GradeLevelSection({form, commonData}: Props) {
	return <Section
		data-cy={'grade-level-section'}
		title='Grade Level'
		className={styles.center}
		required

	>
		<FormElement className={styles.glForm} control={form.controls.gradeLevels}>
			<OnHoverTooltip
				message={form.controls.gradeLevels.status === ElementStatus.disabled ? 'Grade Level does not apply for specialist accounts.' : ''}>
				<div data-cy={'grade-level-dropdown'}>
				<Dropdown placeholder='Select Grade Level' multiselect optionName='shortName'>
					{commonData.gradeLevels?.map(v => <Option key={v.gradeLevelID} value={v}>{v.name}</Option>)}
				</Dropdown>
				</div>
			</OnHoverTooltip>
			<OnErrorTooltip placement='right' showOnError='required'>
				Please select one or more grade levels.
			</OnErrorTooltip>
		</FormElement>
	</Section>;
}
