import {useEffect, useState, DragEvent, RefObject} from 'react';
import {useEventEffect} from '@esgillc/events';
import {setRef, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {useService} from '@esgi/core/service';
import {useUser} from '@esgi/core/authentication';
import {
	GroupOfSpecialistsCreatedEvent,
	SchoolsGroupCreatedEvent,
	TeachersGroupCreatedEvent,
} from 'modules/forms/admin-group-forms/events';
import {ClassCreatedEvent} from 'modules/forms/class-form/events';
import {SpecialistGroupCreatedEvent} from 'shared/modules/forms/specialist-group-form/events';
import {InitialData, UserProfile} from '@esgi/main/features/user-profile';
import {HierarchyInstance, HierarchyMode} from './core/models';
import {SchAdminEditEvent} from './core/events';
import {ClassicHierarchy} from './classic/hierarchy';
import {SpecialistHierarchy} from './specialist/hierarchy';
import {ModeSwitcher} from './components/mode-switcher';
import {HierarchyDataService} from './services/hierarchy-data-service';
import './hierarchy.less';
import {PreAssessHierarchy} from 'modules/hierarchy/pre-assess/hierarchy';
import {GroupCreatedEvent} from 'modules/forms/group-form/events';

type Props = {
	onHierarchyChanged: (hierarchy: HierarchyInstance) => void;
	onStudentDragStart?: (event: DragEvent<HTMLAnchorElement>, id?: number) => void;
	onStudentDragEnd?: (event: DragEvent<HTMLAnchorElement>, id?: number) => void;
	scheduledStudentIDs?: Array<number>;
	isClassicForce?: boolean;
	onReady?: () => void;
	hierarchyServiceRef?: RefObject<HierarchyDataService>;
}

export function Hierarchy(props: Props) {
	const service = useService(HierarchyDataService);
	const hierarchy = useBehaviorSubject(service.hierarchyState$);
	const currentUser = useUser();

	const [profile, setProfile] = useState<InitialData>();
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		setRef(service, props.hierarchyServiceRef);
		return () => setRef(null, props.hierarchyServiceRef);
	}, [props.hierarchyServiceRef]);

	useEffect(() => {
		const sub = service.hierarchyChanged$.subscribe(props.onHierarchyChanged);
		return () => sub.unsubscribe();
	}, [props.onHierarchyChanged]);

	useEffect(() => {
		if (currentUser?.globalSchoolYearID) {
			service.init(props.isClassicForce, true).subscribe(() => {
				setLoaded(true);
				props.onReady && props.onReady();
			});
		}
	}, []);

	useEventEffect(SchAdminEditEvent, (args) => {
		setProfile({selectedUserID: args.id, selectedUserType: 'C'});
	});

	useEventEffect(ClassCreatedEvent, (args) => {
		const pos = $('#all-box-item_All-Classes a.edit.icon').offset();
		showSnackbarNotification(`You've created ${args.name}`, {
			vPos: 'top',
			hPos: 'left',
			offsetX: pos.left,
			offsetY: pos.top + 37,
		});
	});

	useEventEffect(GroupCreatedEvent, (args) => {
		const pos = $('#all-box-item_All-Groups a.edit.icon').offset();
		showSnackbarNotification(`You've created ${args.name}`, {
			vPos: 'top',
			hPos: 'left',
			offsetX: pos.left,
			offsetY: pos.top + 37,
		});
	});

	useEventEffect(SchoolsGroupCreatedEvent, (args) => {
		const pos = $('#all-box-item_School-Groups a.edit.icon').offset();
		showSnackbarNotification(`You've created ${args.name}`, {
			vPos: 'top',
			hPos: 'left',
			offsetX: pos.left,
			offsetY: pos.top + 37,
		});
	});

	useEventEffect(TeachersGroupCreatedEvent, (args) => {
		const pos = $('#all-box-item_Teacher-Groups a.edit.icon').offset();
		showSnackbarNotification(`You've created ${args.name}`, {
			vPos: 'top',
			hPos: 'left',
			offsetX: pos.left,
			offsetY: pos.top + 37,
		});
	});

	useEventEffect(GroupOfSpecialistsCreatedEvent, (args) => {
		const pos = $('#all-box-item_Specialist-Groups a.edit.icon').offset();
		showSnackbarNotification(`You've created ${args.name}`, {
			vPos: 'top',
			hPos: 'left',
			offsetX: pos.left,
			offsetY: pos.top + 37,
		});
	});

	useEventEffect(SpecialistGroupCreatedEvent, (args) => {
		const pos = $('#all-box-item_Specialist-Groups > a.edit.icon').offset();
		showSnackbarNotification(`You've created ${args.name}`, {
			vPos: 'top',
			hPos: 'left',
			offsetX: pos.left,
			offsetY: pos.top + 37,
		});
	});

	if (!loaded || !hierarchy) {
		return null;
	}

	return <div className='hierarchy'>
		<ModeSwitcher hierarchy={hierarchy} userType={currentUser?.userType} service={service}/>
		{hierarchy.mode === HierarchyMode.Classic && <ClassicHierarchy
			boxes={hierarchy.boxes}
			schAdmins={hierarchy.schAdmins}
			onDragStart={props.onStudentDragStart}
			onDragEnd={props.onStudentDragEnd}
			scheduledIDs={props.scheduledStudentIDs}
			schools={hierarchy.schools}
			teachers={hierarchy.teachers}
			classes={hierarchy.classes}
			groups={hierarchy.groups}
      schoolsGroups={hierarchy.schoolsGroups}
      teachersGroups={hierarchy.teachersGroups}
			students={hierarchy.students}
			snapshot={hierarchy.classic}
			onGroupsChanged={service.updateGroups}
      onAdminGroupsChanged={service.updateAdminGroups}
			onOpenCloseChanged={service.toggleBox}
			onChanged={(classic, cb) => {
				service.updateHierarchy({classic}, true);
				cb && cb();
			}
			}
		/>}
		{hierarchy.mode === HierarchyMode.Specialist && <SpecialistHierarchy
			boxes={hierarchy.boxes}
      groupsOfSpecialists={hierarchy.groupsOfSpecialists}
			students={hierarchy.students}
			teachers={hierarchy.teachers}
			schools={hierarchy.schools}
			schoolSpecialists={hierarchy.schoolSpecialists}
			districtSpecialists={hierarchy.districtSpecialists}
			specialistGroups={hierarchy.specialistGroups}
			snapshot={hierarchy.specialist}
			onOpenCloseChanged={service.toggleBox}
			onChanged={(specialist, cb) => {
				service.updateHierarchy({specialist}, true);
				cb && cb();
			}
			}
		/>}
    {hierarchy.mode === HierarchyMode.PreAssess && <PreAssessHierarchy
      boxes={hierarchy.boxes}
      onDragStart={props.onStudentDragStart}
      onDragEnd={props.onStudentDragEnd}
      users={hierarchy.preAssesses}
      groups={hierarchy.specialistGroups}
      students={hierarchy.students}
      snapshot={hierarchy.preAssess}
      onOpenCloseChanged={service.toggleBox}
      onChanged={(preAssess, cb) => {
        service.updateHierarchy({preAssess}, true);
        cb && cb();
      }
      }
    />}
		{profile && <UserProfile initialData={profile} close={() => setProfile(null)}/>}
	</div>;
}
