import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const BodyContainer = styled(GridBox, {
	paddingTop: '20px',
	display: 'grid',
	gridTemplateRows: 'repeat(4,auto) 1fr',
	gap: '20px',

	'& > div': {
		paddingBottom: '20px',
	},
});