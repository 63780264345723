import {useMemo} from 'react';
import styles from './styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {TestsRow} from './tests-row';
import {NotesRow} from './notes-row';
import {ReportService} from '../../../../services/report-service';
import {groupTestByTwo} from '../../../../utils';

interface Props {
	service: ReportService;
}

export function DetailReportMain({service}: Props) {
	const report = useBehaviorSubject(service.dataService.report);
	const students = useBehaviorSubject(service.settingsService.students);
	const groupedTests = useMemo(() => report.students.map(student =>
		<div
			key={student.fullName}
			className={join(styles.studentDetailContainer, 'item')}
		>
			<div className={styles.studentName}>{student.fullName}</div>
			{groupTestByTwo(student.tests).map((tests, index) =>
				<div
					key={index}
					className={join(styles.detailsContainer, report.trackDates.length >= 7 && styles.largePeriods, student.tests.length === 1 && styles.oneTest)}
				>
					<div className={styles.detailsGroup}>
						<TestsRow tests={tests} service={service}/>
						<NotesRow tests={tests} service={service} student={student}/>
					</div>
				</div>,
			)}
		</div>,
	), [report.students]);

	return <>
		{report?.students.length > 0 &&
			<div className={styles.mainContainer} id='itemsUL'>
				<div>{groupedTests}</div>
			</div>
		}
		{students.length === 1 &&
			<div className={styles.emptyTextContainer}>
				<span className={styles.emptyReportText}>There are no students in this group or class</span>
			</div>
		}
		{(students.length !== 1 && report.students.length === 0) &&
			<div className={styles.emptyTextContainer}>
				<span className={styles.emptyReportText}>There's no test data for the student</span>
			</div>
		}
	</>;
}
