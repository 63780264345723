import {useLayoutEffect, useRef} from 'react';
import {Observable} from 'rxjs';

export function useObservableCallbackRef<F extends (...rest: never[]) => Observable<unknown>>(callback: F) {
	const callbackRef = useRef(callback);

	useLayoutEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	return callbackRef;
}
