import {ControlValue, FormControl, FormGroup, ValidatorArgs, Validators} from '@esgillc/ui-kit/form';
import {Observable, switchMap} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {HttpClient} from '@esgi/api';

export type LoginInfoFormType = FormGroup<{ password: FormControl<string>, login: FormControl<string> },
	ControlValue<{ password: FormControl<string>, login: FormControl<string> }>>;

export const createLoginInfoForm = (): LoginInfoFormType => {
	return new FormGroup({
		login: new FormControl('', {validators: [Validators.required()]}),
		password: new FormControl('', {validators: [Validators.required(), Validators.length.min(8)]}),
	});
};

export const loginAvailableCustomValidator = (initialLogin: string, httpClient: HttpClient) => {
	const checkUserName = (userName: string) => {
		return httpClient.ESGIApi.get<boolean>('profiles/common', 'check-user-name', {userName: userName});
	};
	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(debounceTime(800), switchMap((validatorArgs) =>
			checkUserName(validatorArgs.field.currentValue).pipe(map((isUsed) => {
				if (isUsed && validatorArgs.field.currentValue !== initialLogin) {
					validatorArgs.errors.push('login-in-use');
				}
				return validatorArgs;
			}))));
	};
};
