import {Observable, tap} from 'rxjs';
import {StudentCredentialsTeacherInitResponse} from '../types/teacher-types';
import {uniq} from 'underscore';
import {deepCopy} from 'shared/utils';
import {SortBy} from '../kit';
import {StudentCredentialsService} from './student-credentials-service';
import {FilterTeacherService} from './filter-teacher-service';

export class StudentCredentialsTeacherService extends StudentCredentialsService<FilterTeacherService> {
	private readonly studentCredentialsTeacherController = 'assignments/student-credentials/teacher';

	public init(): Observable<StudentCredentialsTeacherInitResponse> {
		return this.httpClient.ESGIApi.get<StudentCredentialsTeacherInitResponse>(
			this.studentCredentialsTeacherController,
			'init',
		)
			.pipe(
				tap((response) => {
					const groups = response.classes.map(({groups}) => groups).flat();
					const students = uniq(response.classes.map(({students}) => students).flat(), ({id}) => id);
					const studentsIds = students.map(({id}) => id);

					this.filterService.tableData$.next(deepCopy(students));
					this.filterService.allTableData$.next(deepCopy(students));

					this.filterService.allStudentsIds$.next(studentsIds);
					this.filterService.classes$.next(response.classes);
					this.filterService.groups$.next(groups);

					this.filterService.setSortByValue('name', SortBy.Asc);
				}),
			)
			.asObservable();
	}
}
