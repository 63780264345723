import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {Content as ContentBase} from '../layout/index.styled';

export const SetupText = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	flex: '1 1 auto',

	[`& ${Text}`]: {
		borderRadius: '6px',
		padding: '12px 20px',
		border: '1px solid $border',
		background: '$vivid',
		color: '$neutral40',
	},
});

export const Content = styled(ContentBase, {
	display: 'grid',
	gap: '$4',
});
