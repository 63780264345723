import {ChangeEvent} from 'react';
import {useValue} from '../hooks';
import {ChangeFunction} from '../types';
import {Input} from '@esgi/ui/controls';
import {Section, Title} from './index.styled';

interface Props {
	disabled?: boolean;
	onChange: ChangeFunction<string>;
}

export function CopiesControl({disabled, onChange}: Props) {
	const [value, setValue] = useValue<string>('1', onChange);

	const onInput = ({target, nativeEvent}: ChangeEvent<HTMLInputElement>) => {
		if (target.value === '') {
			setValue(target.value);
			(nativeEvent.target as HTMLInputElement).value = ''; // FF hack. It allows to enter letters
			return;
		}
		let parsedValue = parseInt(target.value);
		if (isNaN(parsedValue)) {
			parsedValue = 1;
		}
		if (parsedValue < 1) {
			parsedValue = 1;
		}
		if (parsedValue > 99) {
			parsedValue = 99;
		}
		setValue(parsedValue.toString());
	};

	return (
		<Section>
			<Title size='small' font='mono' color='lowContrast' data-cy='copies-title'>Copies</Title>
			<Input
				type='number'
				value={value}
				min='1'
				max='20'
				placeholder='Copies per student'
				disabled={disabled}
				onInput={onInput}
				dataCy='copies-input'
			/>
		</Section>
	);
}
