import {Close} from '@esgi/ui/icons';
import {Button} from 'libs/ui/src/buttons';
import {ComponentProps} from 'react';

type SnackbarCloseIconProps = Omit<ComponentProps<typeof Button.Icon>, 'children' | 'skeleton'> & {
	/**
	 * Attribute defines a string value that labels an interactive element.
	 */
	ariaLabel?: string;
};

export function SnackbarCloseIcon({
	dataCy = 'ui-kit-snackbar-close-icon',
	ariaLabel = 'Close',
	...props
}: SnackbarCloseIconProps) {
	return (
		<Button.Icon dataCy={dataCy} aria-label={ariaLabel} {...props}>
			<Close />
		</Button.Icon>
	);
}
