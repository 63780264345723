import {BaseApi} from '@esgi/contracts';
import {useMemo} from 'react';
import {v2Domain} from './v2';

export function useEsgiApiGateway() {
	return useMemo(() => new Api(), []);
}

export class Api extends BaseApi {
	protected apiName = 'esgi-apigateway';
	public v2 = new v2Domain(this.clientFactory);
}
