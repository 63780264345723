import {createContext, ReactNode, useContext, useEffect} from 'react';
import {useService} from '../../../../../../libs/core/src/service';
import {UserFeatureService} from './service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useUser} from '@esgi/core/authentication';

export const UserFeatureContext = createContext({});

export function UserFeaturesProvider({children}: { children: ReactNode }): JSX.Element {
	const user = useUser();
	const service = useService(UserFeatureService);
	const userFeatures = useBehaviorSubject(service.userFeatures$);
	useEffect(()=>{
		if(user) {
			service.init();
		}
	}, []);
	return <UserFeatureContext.Provider value={userFeatures}>{children}</UserFeatureContext.Provider>;
}

export function useUserFeatures(): Readonly<Record<string, boolean>>{
	return useContext(UserFeatureContext);
}
