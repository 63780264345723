import React, {useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment/moment';
import {useClickOutsideEffect} from '@esgillc/ui-kit/core';
import {MultiLevelItem} from '../results-form-modal/components/multi-level-menu/multilevel-item/menu-item';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

type Track = { id: number, dateFrom?: string, dateTo: string };

interface Props {
	tracks: Track[]
	date: string;
	isVisible?: boolean
	onHide?: () => void;
	onChange
}

export function SessionDropdown({tracks, date, isVisible: outerIsVisible, onHide, onChange}: Props) {
	const tracksSorted = useMemo(() => tracks.sort(sortFunc), [tracks]);
	const [isVisible, setIsVisible] = useState(outerIsVisible);
	const innerDate = useMemo(() => date || tracksSorted[0].dateTo, [date, tracksSorted]);
	const hiddenInputRef = useRef<HTMLInputElement>(null);
	const sessionWrapperRef = useRef<HTMLDivElement>(null);
	const sessionDatepickerRef = useRef<HTMLInputElement>(null);
	const datePickerWrapper = useRef<HTMLDivElement>(null);


	useEffect(() => {
		setIsVisible(outerIsVisible);
	}, [outerIsVisible]);

	function hideDropdown() {
		setIsVisible(false);
		onHide();
	}

	function sortFunc(a, b) {
		const aDate = moment(a.dateTo.replace(/-/g, '/'));
		const bDate = moment(b.dateTo.replace(/-/g, '/'));
		if (aDate.isAfter(bDate)) {
			return 1;
		}
		if (aDate.isBefore(bDate)) {
			return -1;
		}
		return 0;
	}

	function raiseDateClicked(date: string) {
		hideDropdown();
		onChange(date);
	}

	function correctHeight(element: HTMLDivElement) {
		const top = sessionWrapperRef?.current?.offsetTop;
		const {height} = {height: $(element).height()};
		if (document.body.clientHeight - (top + height) < 0) {
			$(element).css({top: document.body.clientHeight - (top + height) + 'px'});
		}
	}

	const initDatePicker = (target: EventTarget) => {
		($(sessionDatepickerRef.current) as any).datepicker({
			format: 'mm/dd/yyyy',
			autoclose: true,
			todayHighlight: true,
			endDate: moment().toDate(),
			beforeShowDay: (date: Date) => {
				if (moment(date).isAfter(moment())) {
					return {
						tooltip: 'This date is in the future. Please select a valid date.',
					};
				}
			},
		});

		if (target !== sessionDatepickerRef.current) {
			$(sessionDatepickerRef.current).datepicker('update', moment(innerDate).toDate());
		}

		$(hiddenInputRef.current).on('change', (e) => {
			raiseDateClicked($('#hidden_input').val() as string);
		});

		correctHeight(datePickerWrapper.current);
	};

	const onMPClick = (date: string) => {
		raiseDateClicked(date);
	};

	useClickOutsideEffect(hideDropdown, sessionWrapperRef);

	return isVisible && <div className={join(styles.sessionWrapper, styles.resetTableStyles)} ref={sessionWrapperRef}>
		<ul className={styles.sessionDropdown}>
			<MultiLevelItem title='Date' className={styles.multilevelMenu} onHover={initDatePicker}>
				<div className={styles.datePickerWrapper} ref={datePickerWrapper}>
					<div className='input-group date react-datepicker' id='session-datepicker'
					     ref={sessionDatepickerRef}>
						<input type='text' id='hidden_input' className={styles.hidden} ref={hiddenInputRef}/>
					</div>
				</div>
			</MultiLevelItem>
			<MultiLevelItem title='Marking Period' className={styles.multilevelMenu}>
				<div className={styles.markingPeriodWrapper}>
					<div className={styles.title}>End date:</div>
					<ul className={styles.markingPeriodsItems}>
						{tracksSorted.map((track, pos) => {
							const disabled = moment(track.dateFrom?.replace(/[-]/g, '/')).isAfter(moment());
							return <OnHoverTooltip
								message={disabled && 'This marking period has not yet begun. Please select a valid marking period.'}>
								<li className={disabled && styles.disabled} key={track.dateTo}>
									<div onClick={() => !disabled && onMPClick(track.dateTo)}><span className={styles.position}>{pos + 1}.</span> <span>{track.dateTo}</span></div>
								</li>
							</OnHoverTooltip>;
						})}
					</ul>
				</div>
			</MultiLevelItem>
		</ul>
	</div>;
}
