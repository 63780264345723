import React from 'react';
import {NavLink} from 'react-router-dom';
import {Route} from 'pages/assignment-center/hooks/use-navigation';
import styles from './styles.module.less';

type CredentialColumnProps = {
	hasCredentials: boolean;
	onStudentCredentialClicked?: VoidFunction | undefined;
};

export function CredentialColumn({
	hasCredentials,
	onStudentCredentialClicked,
}: CredentialColumnProps): React.JSX.Element {
	if (hasCredentials) {
		return <>Yes</>;
	}

	return (
		<NavLink to={Route.StudentCredentials} className={styles.navLink} onClick={onStudentCredentialClicked}>
			Add
		</NavLink>
	);
}
