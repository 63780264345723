import {Container, CheckBox, Wrapper} from './index.styled';
import {noop} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {Button, GradeScale} from '@esgi/ui';
import React from 'react';
import {TestColorIndicator} from '@esgi/main/kits/common';
import {Test, TestTypeLabel} from '@esgi/main/kits/reports';
import {TestType} from '@esgi/main/libs/core';

type Props = {
	name: Test['name'],
	checked: boolean,
	testID: Test['id'],
	testType: TestType,
}

export function TestRow({name, checked, testID, testType}: Props) {
	return (
		<Container>
			<Wrapper>
				<CheckBox checked={checked} active={checked} onCheckedChange={noop}/>
				<TestColorIndicator testID={testID}/>
				<Text size='medium' bold>
					{name}
				</Text>
				<TestTypeLabel testType={testType} />
			</Wrapper>
			{/*TODO Opens Grade Scale Range Set Modal*/}
			<Button.Icon onClick={e => e.stopPropagation()}>
				<GradeScale/>
			</Button.Icon>
		</Container>
	);
}