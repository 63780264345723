import {StudentLevelTableModel} from '../../../../types/student-level-report-service-types';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';

type TableProps = {
	table?: StudentLevelTableModel | undefined;
};

export function Table({table}: TableProps) {
	if (!table) {
		return null;
	}

	const {studentName, rubricName, totalScores, totalPossible} = table;

	return (
		<>
			<div className={styles.studentName}>{studentName}</div>
			<div className={styles.container}>
				<h3 className={styles.rubricName}>{rubricName}</h3>
				<table className={styles.table}>
					<thead>
						<tr className={styles.tr}>
							<th className={join(styles.th, styles.descriptionColumn)}>Marking Period</th>
							{totalScores.map(({periodName, testSessionID}, index) => (
								<th
									className={join(styles.th, periodName === 'B' && styles.baseLine)}
									key={`${testSessionID}-${index}`}
								>
									{periodName}
								</th>
							))}
							<th className={join(styles.th, styles.descriptionColumn)}>Total Possible</th>
						</tr>
					</thead>
					<tbody>
						<tr className={styles.tr}>
							<td className={join(styles.td, styles.descriptionColumn)}>Total Score</td>
							{totalScores.map(({value, testSessionID}, index) => (
								<td className={styles.td} key={`${testSessionID}-${index}`}>
									{value}
								</td>
							))}
							<td className={join(styles.td, styles.descriptionColumn)}>{totalPossible}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
}
