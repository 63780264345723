import {useEffect, useState} from 'react';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {useService} from '@esgi/core/service';
import {Form} from '@esgillc/ui-kit/form';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {Buttons} from '@esgillc/ui-kit/button';
import {PersonalDetailsService} from './service';
import {SaveResult} from '../../../types';
import SubjectTabNameSection from '../../sections/subject-tab-name';

class Props {
	subjectID?: number;
	subjectName?: string;
	editMode: boolean;
	onClosed: () => void;
	onSaved: (response: SaveResult) => void;
}

export default function PersonalTabDetails({
	subjectID,
	subjectName,
	editMode,
	onClosed,
	onSaved,
}: Props) {

	const modalRef = useModal();
	const handelClose = useCloseModal(modalRef, onClosed);

	const service = useService(PersonalDetailsService);
	const form = service.form;

	const [isLoading, setLoading] = useState(false);
	const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);

	useEffect(() => {
		service.init(subjectID, subjectName);
	}, []);

	useStreamEffect(service.form.onChanged, () => {
		service.form.validate(true).subscribe((v) => {
			setSaveButtonDisabled(!v.valid);
		});
	});

	const onSubmit = () => {
		setLoading(true);
		service.save(subjectID).subscribe({next: (r) => {
			onSaved(r);
			handelClose();
		},
		complete: () => setLoading(false),
		});
	};

	return <>
		<Loader show={isLoading} fullscreen/>
		<Modal modalManagerRef={modalRef} onCatchError={handelClose}>
			<Modal.Header>
				<Title>{editMode ? 'Edit Subject Tab' : 'Create New Subject Tab'}</Title>
			</Modal.Header>
			<Modal.Body>
				<div data-cy={'personal-details'}>
					<Form controller={form}>
						<SubjectTabNameSection form={form}/>
					</Form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={handelClose}>Cancel</Buttons.Gray>
				<Buttons.Contained disabled={isSaveButtonDisabled} onClick={onSubmit}>
					Save
				</Buttons.Contained>
			</Modal.Footer>
		</Modal></>;
}
