import {Observable} from 'rxjs';
import {WidgetService} from '../service';
import {StudentBundle, ClassBundle} from '../types';
import {ClassResponse, StudentResponse} from './types';

export class TimePerQuestionService extends WidgetService<StudentResponse, ClassResponse> {
	protected requestStudentData(request: StudentBundle): Observable<StudentResponse> {
		return this.studentsController.timePerQuestion({testIDs: request.testIDs, studentID: request.studentID});
	}

	protected requestClassData(request: ClassBundle): Observable<ClassResponse> {
		return this.classController.timePerQuestion({testIDs: request.testIDs, studentIDs: request.studentIDs});
	}
}