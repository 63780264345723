import {Renderable, RenderableFunction} from '@esgillc/ui-kit/core';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {join} from '@esgillc/ui-kit/utils';
import styles from './settings.module.less';
import {ReactNode} from 'react';

interface Props {
	title: string;
	className?: string;
	children?: ReactNode;
}

export default function SettingsFooter(props: Props): JSX.Element {
	const className = join(styles.item, props.className);

	return <div className={styles.footer}>
		<FlexBox direction='row' className={className}>
			<div className={styles.title}>
				{props.title}:
			</div>
			<div>
				{props.children}
			</div>
		</FlexBox>
	</div>;
}
