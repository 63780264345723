import {AssignmentInfo} from '../../../services/types';
import {AssignmentStatus} from '../../../typings/assignment';
import {filterByStatus} from '../utils';

export function countByStatus(assignments: AssignmentInfo[]) {
	return {
		[AssignmentStatus.Published]: filterByStatus(AssignmentStatus.Published, assignments).length,
		[AssignmentStatus.NotStarted]: filterByStatus(AssignmentStatus.NotStarted, assignments).length,
		[AssignmentStatus.Completed]: filterByStatus(AssignmentStatus.Completed, assignments).length,
		[AssignmentStatus.Draft]: filterByStatus(AssignmentStatus.Draft, assignments).length,
	};
}
