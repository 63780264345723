import {StudentRace} from '@esgi/contracts/esgi/types/esgi.students/enums/student-race';
import {StudentLunchStatus} from '@esgi/contracts/esgi/types/esgi.students/enums/student-lunch-status';
import {StudentSpecialProgram} from '@esgi/contracts/esgi/types/esgi.students/enums/student-special-program';

export {StudentRace, StudentLunchStatus, StudentSpecialProgram};

export enum StudentProfileMode {
	view,
	add,
	edit,
}
export enum StudentGenders {
	Female = 'F',
	Male = 'M',
	Other = 'U',
}

export enum StudentProfileTab {
	Profile = 'profile',
	Location = 'location',
	Information = 'information',
}

export class Profile {
	constructor(
		public firstName: string,
		public lastName: string,
		public gender: string,
		public birthDate: string,
		public studentIDN: string,
		public exportIDN: string,
		public gradeLevelID: number,
		public languageID: number,
		public credential: Credential,
		public photo: Photo | null,
	) {
	}
}

export class Location {
	constructor(
		public districtID: number,
		public schoolID: number,
		public globalSchoolYearID: number,
		public teacherID: number | null,
		public specialistID: number | null,
		public classIDs: number[],
		public groupIDs: number[],
		public specialistGroupIDs: number[],
	) {
	}
}

export class Information {
	constructor(
		public race: StudentRace,
		public lunchStatus: StudentLunchStatus,
		public studentSpecialPrograms: StudentSpecialProgram[],
		public comments: string,
	) {
	}
}

export interface PhotoBase64 {
	image: string | null;
	imageCrop: string | null;
	crop?: CropParams;
}

export interface Photo {
	imageUrl: string;
	imageCropUrl: string;
	crop: CropParams;
}

export interface NewPhoto {
	imageUrl: string;
	imageCropUrl: string;
	crop: CropParams;
	remove?: boolean;
}

export class Credential {
	constructor(
		public userName: string,
		public password: string,
	) {
	}
}

export interface Dictionary {
	genders: DictionaryItem[];
	gradeLevels: DictionaryItem[];
	languages: DictionaryItem[];
	lunchStatuses: DictionaryItem<StudentLunchStatus>[];
	races: DictionaryItem<StudentRace>[];
	specialPrograms: DictionaryItem<StudentSpecialProgram>[];
}

export interface SaveStudentProfileResponse {
	value: { studentID: number },
	errors: { type: string, description: string }[],
	isSuccess: boolean
}

export interface DictionaryItem<IDType = string> {
	id: IDType,
	name: string,
	valueName?: string
}

export interface Schools {
	schoolID: number;
	name: string;
}

export interface ClassItem {
	id: number;
	name: string;
	studentIDs: number[];
}

export interface GroupItem {
	id: number;
	name: string;
	studentIDs: number[];
	classID: number;
	disabled?: boolean;
}

export interface ImageData {
	image: string,
	croppedImage: string,
	cropParameters: CropParams
}

export interface CropParams {
	x: number,
	y: number,
	zoom: number
}

export interface CropPoints {
	x: number,
	y: number,
}

export interface CropAreaProperties {
	width: number,
	height: number,
	x: number
	y: number
}

export interface ImageCropperParams {
	width: number;
	height: number;
	x: number;
	y: number;
}

export class StudentModel {
	constructor(
		public id: number,
		public firstName: string,
		public lastName: string,
		public photoUrl: string,
		public gradeLevelID: number,
		public hasCredentials: boolean,
		public studentIDN: string,
		public languageID: number,
	) {
	}
}

export class StudentAddOrEditModel {
	constructor(
		public classIds: number[],
		public groupIds: number[],
		public student: StudentModel,
	) {
	}
}
export class Student {
	id: number;
	firstName: string;
	lastName: string;
	gradeLevelID: number;
	photoUrl: string;
	hasCredentials: boolean;
}
