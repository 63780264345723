import React from 'react';
import './component.less';
import ColorButton
	from 'shared/modules/grade-scale/grade-scale/info/entry/name/grade-entry-color-picker/color-button/component';

export class Props {
	color: string;
	onChange: (color: string) => void;
}

export class State {
	opened: boolean;
	color: string;
}

export default class GradeScaleEntryColorPicker extends React.PureComponent<Props, State> {
	colors: string[];
	ref: HTMLDivElement;

	constructor(props) {
		super(props);
		this.state = new State();

		this.colors = ['#cdcdcd', '#90caf9', '#80cbc4', '#ce93d8', '#fff59d', '#ffcc80', '#9fa8da', '#a5d6a7', '#fff9c4', '#ef9a9a'];
	}

	componentDidMount() {
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	handleClickOutside(event: any) {
		if (this.ref && !this.ref.contains(event.target) && this.state.opened) {
			this.onToggle();
		}
	}

	onToggle() {
		this.setState((prevState) => {
			return {
				opened: !prevState.opened,
			};
		});
	}

	onSelect(color: string) {
		this.setState(() => {
			return {
				opened: false,
			};
		}, () => this.props.onChange(color));
	}

	render() {
		return <div className='grade-scale-entry-color-picker-container'>
			<div className='grade-scale-entry-color-picker'
				 onClick={() => this.onToggle()}
				 style={{backgroundColor: this.props.color}}>
			</div>
			{this.state.opened && <div className='picker-panel' ref={(r) => this.ref = r}>
				{this.colors.map((color) => {
					return <ColorButton
						key={color}
						selected={this.props.color === color}
						onClick={(color: string) => this.onSelect(color)}
						color={color}
					/>;
				})}
			</div>}
		</div>;
	}
}