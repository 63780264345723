export function validateNumberValue(value: string) {
	if (value === '') {
		return value;
	}

	let parsedValue = parseInt(value);

	if (isNaN(parsedValue)) {
		parsedValue = 1;
	}

	if (parsedValue < 1) {
		parsedValue = 1;
	}

	if (parsedValue > 99) {
		parsedValue = 99;
	}

	return parsedValue.toString();
}