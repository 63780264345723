import './сolor-picker.less';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {IFormControlValidatorResult} from './form-controls';
import React, {createRef, RefObject} from 'react';
import {join} from '@esgillc/ui-kit/utils';

export class ColorPicker extends SharedComponent<State, Props> {
	private containerRef: HTMLElement = null;
	private readonly maxLength: number = 6;
	private paste: boolean;
	private readonly colorExp: RegExp = /^([0-9A-F]{3}){2}$/;
	private inputRef = createRef<HTMLInputElement>();
	private button: RefObject<HTMLButtonElement> = createRef();

    constructor(props: Props) {
    	super(props);
    	this.state = new State();
    	this.bindScope();
    }


    public static colors: { color: string, title: string }[] = [
    	{color: 'CDCDCD', title: 'PaleGray'},
    	{color: '80CBC4', title: 'Teal'},
    	{color: 'CE93D8', title: 'Magenta'},
    	{color: '9FA8DA', title: 'Purple'},
    	{color: '90CAF9', title: 'Blue'},
    	{color: 'A5D6A7', title: 'Green'},
    	{color: 'FFF59D', title: 'Yellow'},
    	{color: 'FFCC80', title: 'Orange'},
    	{color: 'EF9A9A', title: 'Pink'},
    ];


    private bindScope() {
    	this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
    	document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
    	document.removeEventListener('mousedown', this.handleClickOutside);
    }

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if (prevState.show !== this.state.show) {
			const buttonBounds = this.button.current?.getBoundingClientRect();
			this.setState({
				x: buttonBounds?.x + buttonBounds.width,
				y: buttonBounds?.y,
			});
		}
	}

	handleClickOutside(event) {
    	if (this.containerRef && !this.containerRef.contains(event.target) && this.state.show) {
    		this.setState({show: false});
    	}
    }

    private cleanHex(hex: string): string {
    	return hex.replace('#', '');
    }

    private setColor(color: string, isCustom: boolean ) {
    	color = this.cleanHex(color);
    	let selectedColor = this.state.selectedColor;
    	let selectedColorTitle = this.state.selectedColorTitle;
    	let hexValue = this.state.selectedColor;

    	if (color.length === this.maxLength) {
    		const colorExists = GetColorFromPallete(color);
    		if (isCustom) {
    			if (!colorExists && this.colorExp.test(color.toUpperCase())) {
    				hexValue = `#${color}`;
    			}
    			selectedColor = (!colorExists) ? color : colorExists.color;
    			selectedColorTitle = (!colorExists) ? color : colorExists.title;
    		} else {
    			selectedColor = colorExists.color;
    			selectedColorTitle = colorExists.title;
    		}
    	}

    	const stateObj = {
    		show: false,
    		selectedColor: selectedColor,
    		selectedColorTitle: selectedColorTitle,
    		touched: false,
    		hexValue: hexValue,
    	};

    	this.setState(stateObj);
    }

    private colorInputValidation(hex: string, callback?: () => void): void {
    	let message: string = '';
    	let valid: boolean = true;
    	hex = this.cleanHex(hex).toUpperCase();
    	const setInvalid = () => {
    		message = 'Please enter a color in the format \'#RRGGBB\'';
    		valid = false;
    	};
    	if (hex.length) {
    		if (hex.length === this.maxLength) {
    			if (!this.colorExp.test(hex)) {
    				setInvalid();
    			}
    		} else if (hex.length > this.maxLength) {
    			setInvalid();
    		}
    	}

    	this.setState({
    		validator: {message, valid},
    		touched: !valid,
    	}, callback);
    }

    private colorInputChanged(value: string) {
    	const colorPicker = $('.color-picker-input input');

    	if (this.paste) {
    		this.colorInputValidation(colorPicker.val().toString(), () => {
    			this.paste = false;
    			this.setState({hexValue: value});
    		});
    		return;
    	}

    	const updValue = this.cleanHex(value);// # symbol is not counted
    	const currValue = this.cleanHex(this.state.hexValue);
    	if (currValue.length > this.maxLength) {
    		if (currValue.length >= updValue.length) {
    			this.setState({hexValue: value}, () => this.colorInputValidation(this.state.hexValue));
    		} else {
    			colorPicker.val(this.state.hexValue);
    		}
    	} else {
    		if (updValue.length > this.maxLength) {
    			colorPicker.val(this.state.hexValue);
    		} else {
    			this.setState({hexValue: value}, () => this.colorInputValidation(this.state.hexValue));
    		}

    	}
	}

	private renderDialog() {
		const {show, x, y} = this.state;

		if(show) {
			let style = {
				position: 'absolute',
				top: 0,
				left: 40,
			};

			if(x && y) {
				style.position = 'fixed';
				style.top = y;
				style.left = x;
			}

			return <div className={join('color-dialog', this.props.align === 'up' && 'align-up', this.props.className)} style={style as any}>
				<div className='colors-container'>
					{ColorPicker.colors.map((color, index) => {
						return <div className='color-item' style={{backgroundColor: `#${color.color}`}}
						            key={'color-' + index}
						            onClick={() => this.setColor(color.color, false)}>
						</div>;
					})}
				</div>
				<div className={'form-group  color-picker-input '+ (this.state?.validator?.valid === false && 'has-error')}>
					<input
						ref={this.inputRef}
						onKeyDown={(eventObj) => {
							if (eventObj.key === 'Enter') {
								this.setColor(this.inputRef.current.value, true);
								this.setState({show: false});
							}
						}
						}
						className='form-control large '
						placeholder='Custom Hex#'
						onChange={(e) => this.colorInputChanged(e.target.value)}
						value={this.state.hexValue}
						onBlur={() => this.setColor(this.state.hexValue, true)}
						onPaste={() => this.paste = true}
					/>
					{this.inputRef && this.state.touched && !this.state.validator.valid &&
						<ValidationTooltip element={this.inputRef.current} placement={'right'} container='body'>
							{this.state.validator.message}
						</ValidationTooltip>}
				</div>
			</div>;
		}
	}

	render() {
    	return <div className='react-color-picker' ref={(r) => this.containerRef = r}>
            <button style={{backgroundColor: `#${this.state.selectedColor}`}}
                    ref={this.button}
                    onClick={() => {
                        const selectedColor = this.state.selectedColor;
                        const colorExists = GetColorFromPallete(selectedColor);
                        const hexValue = !colorExists && `#${this.state.selectedColor}` || '';
                        this.setState({show: !this.state.show, hexValue: hexValue});
                    }} disabled={this.props.disabled}/>
            {!this.props.hideTitle && <span>{this.state.selectedColorTitle}</span>}
		    {this.renderDialog()}
        </div>;
    }
}

class Props extends SharedProps<State> {
	disabled?: boolean;
	hideTitle?: boolean;
	align?: string;
	className?: string;
}

export class State {
	constructor(color?: string) {
		this.selectedColor = color || ColorPicker.colors[0].color;
		const colorExists = GetColorFromPallete(this.selectedColor);
		this.hexValue = !colorExists && `#${color}` || '';
	}

	x: number = 0;
	y: number = 0;

	show: boolean = false;
	selectedColor: string;
	selectedColorTitle: string;
	hexValue: string = '';
	touched: boolean = false;
	validator: IFormControlValidatorResult = {message: '', valid: true};
}

export function GetColorFromPallete (selectedColor: string): { color: string, title: string} {
	return ColorPicker.colors.find(c => c.color === selectedColor.toUpperCase() || c.title.toLowerCase() === selectedColor.toLowerCase());
}
