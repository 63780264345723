import {Modal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {AllStudentsService} from '../../services';
import styles from '../../styles.module.less';

interface Props {
	service: AllStudentsService;
}

export const Body = ({service}: Props) => {
	const reportData = useBehaviorSubject(service.reportData$);

	return (
		<Modal.Body className={styles.body}>
			{service.hasClass &&
				<p>
					<b>Class:</b>
					{reportData.className}
				</p>
			}
			{service.hasGroup &&
				<p>
					<b>Group:</b>
					{reportData.groupName}
				</p>
			}
			<p className={styles.spacer}>
				<b>Test Name:</b>
				{reportData.name}
			</p>
			<p>
				<b>Number of students:</b>
				{reportData.totalStudents}
			</p>
			<p className={styles.spacer}>
				<b>Students tested:</b>
				{reportData.studentsTested}/{reportData.totalStudents}
			</p>
			<p>
				<b>Correct answers:</b>
				{reportData.correctAnswers}/{reportData.totalAnswers}
			</p>
			<p className={styles.spacer}>
				<b>Average score:</b>
				{reportData.percentage}%
			</p>
			<div className={styles.info}>
				To export session data, please use the "Download" button located at the top of this page.
				Once clicked, a menu will appear, allowing you to select specific export options.
			</div>
		</Modal.Body>
	);
};
