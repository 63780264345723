import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const Chart = styled(Box, {
	overflow: 'hidden',
	position: 'relative',
	opacity: 0,
	height: 0,
	width: 0,
});
