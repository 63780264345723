export interface DateObject {
	day: number;
	month: number;
	year: number;
}

export enum DateOptions {
	Day = 'day',
	Month = 'month',
	Year = 'year'
}
