import {SelectableList} from '@esgi/ui/layout';
import {Student} from '@esgi/main/libs/store';
import {Edit} from '@esgi/ui/icons';
import {IconComponent} from '@esgi/ui';
import {SelectableListTriggerContent} from './selectable-list-trigger-content';
import {SelectableLisItemContent, SelectableListRoot, SelectableListTrigger} from './lists.styled';
import {noop} from 'underscore';
import {StudentFullName, SelectableEditableItem, getListValue} from '../../../../kit';

type Props = {
	IconBefore: IconComponent;
	triggerLabel: string;
	isListOpen: boolean;
	onTriggerButtonClick: VoidFunction;
	onStyledButtonClick: VoidFunction;
	isListSelected: boolean;
	students: Student[];
	selectedStudentId: Student['id'] | null;
	onStudentItemClick: (id: number) => void;
};

export function ExpandableEditableList({
	IconBefore,
	triggerLabel,
	isListOpen,
	onTriggerButtonClick,
	onStyledButtonClick,
	isListSelected,
	students,
	selectedStudentId,
	onStudentItemClick,
}: Props) {
	return (
		<SelectableListRoot isListOpen={isListOpen}>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedStudentId)}>
				<SelectableListTrigger
					openList={isListOpen}
					onClick={students.length ? onTriggerButtonClick : noop}
					IconBefore={IconBefore}
					selected={isListSelected}
					hovered={students.length ? undefined : false}
					defaultCursor={Boolean(!students.length)}
				>
					<SelectableListTriggerContent itemsCount={students.length} triggerLabel={triggerLabel} />
					<SelectableList.StyledButton onClick={onStyledButtonClick} dataCy='editable-list-icon'>
						<Edit />
					</SelectableList.StyledButton>
				</SelectableListTrigger>
				<SelectableList.Group>
					{students.map(({firstName, lastName, id}) => (
						<SelectableEditableItem key={id} onItemClick={() => onStudentItemClick(id)} value={String(id)}>
							<SelectableLisItemContent>
								<StudentFullName firstName={firstName} lastName={lastName} />
							</SelectableLisItemContent>
						</SelectableEditableItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
