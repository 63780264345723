import {CentralPanel} from '@esgi/main/kits/common';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CentralPanelRoot = styled(CentralPanel.Root, {
	paddingLeft: 0,
	paddingRight: 0,
});

export const CentralPanelBody = styled(CentralPanel.Body, {
	display: 'grid',
	gridAutoRows: 'auto 1fr',
	overflow: 'hidden',
	gap: 20,
});

export const CentralPanelTablesContainer = styled(GridBox, {
	height: '100%',
	gap: 20,
	gridTemplateRows: 'auto auto 1fr',
	paddingLeft: 20,
	paddingRight: 20,
	overflow: 'hidden',
});

export const CentralPanelDivider = styled(Box, {
	width: '100%',
	height: 1,
	borderRadius: 1,
	backgroundColor: '$mild',
});
