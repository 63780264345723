import React from 'react';
import {ValidationStatus} from '@esgi/core/enums';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {ValidationResult, Validator, SharedProps, SharedComponent} from '@esgi/deprecated/react';

export class Description extends SharedComponent<State, Props> {
	private textarea: HTMLTextAreaElement;
	private validator: Validator;
	private maxDescriptionLength: number = 140;

	render(): JSX.Element | false | null {
		return <div
			className={'form-group item-value ' + (this.state.validation.valid == ValidationStatus.Invalid ? 'has-error' : '')}>
                    <textarea className='form-control description' defaultValue={this.state.value}
							  ref={r => this.textarea = r}
							  placeholder={`max ${this.maxDescriptionLength} characters`}
							  maxLength={this.maxDescriptionLength}
							  onChange={(e) => {
								  this.handleInput(e);
							  }}/>
			{this.state.validation.valid === ValidationStatus.Invalid &&
			<ValidationTooltip element={this.textarea} placement='right' container='body' notHide={true}>
				{this.state.validation.message}
			</ValidationTooltip>}
		</div>;
	}

	handleInput(e) {
		this.setState({value: e.target.value}, async () => {
			await this.validate();
			this.props.onTexAreaChanged(this.state.value);
		});
	}

	componentDidMount(): void {
		this.validator = Validator.validate(() => {
			if (!this.state.value) {
				return 'Please enter a test description';
			}
			return null;
		});
		if (!this.props.readOnly) {
			this.initTextAreaAutosize();
		}

		this.setState({value: this.props.description}, async () => {
			if (!this.props.readOnly && !this.props.isNew) {
				await this.validate();
			}
		});
	}

	componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevProps.readOnly && !this.props.readOnly) {
			this.initTextAreaAutosize();
		}
		if(prevState.value != this.state.value) {
			this.textarea.value = this.state.value;
		}
	}

	async validate(): Promise<boolean> {
		const result = await this.validator.validate();
		this.setState({validation: result});

		return result.valid === ValidationStatus.Valid;
	}

	private initTextAreaAutosize = () => {
		setTimeout(() => {
			const maxHeight = parseInt($('.item-value .description').css('max-height'));
			const getOverflowValue = (scrollHeight, maxHeight) => {
				return scrollHeight > maxHeight ? 'scroll' : 'hidden';
			};
			const {description} = this.props;
			$('.item-value .description').each((index, element) => {
				if (!element.innerHTML && description) {
					element.innerHTML = description;
				}

				const overflow = getOverflowValue(element.scrollHeight, maxHeight);
				const overflowStyle = `overflow-y:${overflow};`;
				const heightStyle = `height:${(element.scrollHeight || 52)}px;`;
				element.setAttribute('style', heightStyle + ' ' + overflowStyle);
			}).on('input', (event) => {
				const element = event.target;
				$(element).css('height', 'auto');
				$(element).css('height', element.scrollHeight + 'px');

				const overflow = getOverflowValue(element.scrollHeight, maxHeight);
				$(element).css('overflow-y', overflow);
			});
		}, 10);

	}

	observe = {
		state: {
			value: () => {
				if (this.state.validation.valid != ValidationStatus.Unknown) {
					this.validate();
				}
			},
		},
	}
}

export class State {
	value: string = '';
	validation = new ValidationResult();
}

export class Props extends SharedProps<State> {
	onTexAreaChanged: (text: string) => void;
	readOnly: boolean;
	isNew: boolean;
	description: string;
}
