import {Outlet, useNavigate} from 'react-router-dom';
import {IconBox, LayoutContainer, Overlay, PanelWrapper} from './index.styled';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {Users} from '@esgi/ui/icons';
import {useMemo} from 'react';
import {useParseURL} from './hooks/use-parse-url';
import {isNull} from 'underscore';
import {TestDashboardContext} from './outlet-context';
import {useTestDashboardState} from './hooks/use-test-dashboard-state';
import {ClassesContent} from './components/classes-content';
import {GroupsContent} from './components/groups-content';
import {StudentsContent} from './components/students-content';
import {Text} from '@esgi/ui/typography';
import {Panel, ScreenSize, useRootPageContext} from '@esgi/main/kits/common';
import {useEventEffect} from '@esgillc/events';
import {SchoolYearChangedEvent} from 'modules/school-year';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {useUser} from '@esgi/core/authentication';
import {routes} from '@esgi/main/libs/core';

export default function TestDashboardLayout() {
	const currentUser = useUser();
	const urlParams = useParseURL();
	const {screenSize, rootPageContentContainerRef} = useRootPageContext();
	const isTablet = screenSize === ScreenSize.PortraitTablet || screenSize === ScreenSize.Tablet;

	const navigate = useNavigate();
	useEventEffect(SchoolYearChangedEvent, () => navigate(routes.teacher.home.root));

	const {
		activeStudentTab,
		setActiveStudentTab,
		isPanelLoaded,
		entities,
		selected,
		onSetSelected,
		isStudentPanelOpened,
		toggleStudentPanelOpened,
		initVisibleSection,
	} = useTestDashboardState({urlParams});

	const outletContext = useMemo<TestDashboardContext | null>(() => {
		if (isNull(urlParams)) {
			return null;
		}

		const {testID, subjectID, subjectType} = urlParams;

		const contextValue: TestDashboardContext = {
			testId: testID,
			subjectID,
			subjectType,
			classesList: entities.classes,
			groupsList: entities.groups,
			studentsList: entities.students,
			isTablet,
			testResultsCorrectVerbiage: currentUser?.testResultsCorrectVerbiage ?? 'Correct',
			testResultsIncorrectVerbiage: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect',
			initVisibleSection,
			rootPageContentContainerRef,
			...selected,
		};

		return contextValue;
	}, [isTablet, urlParams, selected, entities, currentUser, initVisibleSection, rootPageContentContainerRef]);

	return (
		<LayoutContainer isTablet={isTablet}>
			<PanelWrapper isTablet={isTablet} isStudentPanelOpened={isStudentPanelOpened}>
				{isTablet && <Overlay onClick={toggleStudentPanelOpened} isStudentPanelOpened={isStudentPanelOpened} />}
				<Panel
					icon={Users}
					isTablet={isTablet}
					isOpen={isStudentPanelOpened}
					toggleOpen={toggleStudentPanelOpened}
					loaded
					dataCy='students-panel'
				>
					<StudentsPanel.Root
						entities={entities}
						skeleton={!isPanelLoaded}
						activeTab={activeStudentTab}
						onActiveTabChanged={setActiveStudentTab}
					>
						<StudentsPanel.Selection.Single selected={selected} onSelectedChange={onSetSelected}>
							<StudentsPanel.Header.Title align='center'>
								<IconBox>
									<Users />
								</IconBox>
								<Text size='small' color='neutral56'>
									Students
								</Text>
							</StudentsPanel.Header.Title>

							<StudentsPanel.Header.Tabs dataCy='students-tabs'/>

							<StudentsPanel.Tabs.Root>
								<StudentsPanel.Tabs.Classes sectionTitle='Classes' description='Classes'>
									<ClassesContent />
								</StudentsPanel.Tabs.Classes>
								<StudentsPanel.Tabs.Groups sectionTitle='Groups' description='Students Arranged in Groups'>
									<GroupsContent />
								</StudentsPanel.Tabs.Groups>
								<StudentsPanel.Tabs.Students sectionTitle='Students' description='All Students List'>
									<StudentsContent />
								</StudentsPanel.Tabs.Students>
							</StudentsPanel.Tabs.Root>
						</StudentsPanel.Selection.Single>
					</StudentsPanel.Root>
				</Panel>
			</PanelWrapper>

			<Outlet context={outletContext} />
			<BackgroundDownloadManager />
		</LayoutContainer>
	);
}
