import {useEffect, useState} from 'react';
import {WindowTool} from '@esgi/deprecated/utils';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

const messages = {
	'class-link-account-linked': 'Your ClassLink account has been successfully linked.',
	'class-link-account-linking-failed': 'Your ClassLink account was not linked. Please, try again or contact us.',
};

const messageIDName: string = 'esgi-messageID';

export function ClassLinkMessagesLayer() {
	const [alert, setAlert] = useState<string>();

	useEffect(() => {
		const messageID = WindowTool.getUrlQueryParameter(messageIDName);
		if (!messageID || !messages[messageID]) {
			return;
		}

		WindowTool.clearUrlQueryParameters();
		setAlert(messages[messageID]);
	}, []);

	if (alert) {
		return <Alert>
			<Alert.Header/>
			<Alert.Body>{alert}</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={() => setAlert(undefined)}>Close</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}
}