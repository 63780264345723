import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const Layout = styled(FlexBox, {
	justifyContent: 'space-between',
	width: '100%',
	marginBottom: 16,
});

export const AddButton = styled(Button, {
	width: '32px',
	height: '32px',
	minWidth: `unset`,
	padding: 0,
	margin: 0,
	border: `none`,
	marginLeft: 16,
	display: 'flex',
	alignItems: 'center',

	'> svg': {
		margin: `0 !important`,
	},
});

export const InfoButton = styled(Button.Icon, {
	marginRight: 16,
});