import {GridBox, ExpandablePanel} from '@esgi/ui/layout';
import {styled} from '@stitches/react';
import {Checkbox as CheckboxUi} from '@esgi/ui/controls';

export const ExpandablePanelStyled = styled(ExpandablePanel, {
	width: 'fit-content',
	overflow: 'hidden',

	'&[data-expandable-panel-open=true]': {
		backgroundColor: '$background',
		backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',
	},

	'&[data-expandable-panel-open=false]': {
		cursor: 'pointer',
	},

	'[class*="open-true"]': {
		paddingTop: '$3',
		padding: 'unset',
	},

	variants: {
		isSmallScreen: {
			true: {
				position: 'absolute',
				top: '0',
				left: '0',
				width: 'fit-content',
				height: '100%',
				zIndex: 10,
			},
		},
	},
});

export const ExpandablePanelContent = styled(ExpandablePanel.Content, {
	display: 'grid',
	alignContent: 'start',
	gap: '$5',
	overflow: 'hidden',
	minWidth: '400px',
	paddingBottom: '20px',
	height: '100%',
});



export const Checkbox = styled(CheckboxUi, {
	'& div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
	},

	'& > span': {
		color: '$base',
	},
});

export const DisabledCheckbox = styled(CheckboxUi, {
	'& div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$mild !important',
		'& svg path': {
			fill: '$mild',
		},
	},
});

export const CheckboxesBox = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'start',
	width: '100%',
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',
	gap: 4,
	padding: 6,
});


export const FiltersPanelItems = styled(GridBox, {
	gap: '$3',
	paddingLeft: 20,
	paddingRight: 20,
});

