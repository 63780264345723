import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {IndicatorColorVariant} from './types';

const cardIndicatorPaddingInline = 5;
const cardIndicatorLineWidth = 2;

export const testColorIndicatorWidth = 2 * cardIndicatorPaddingInline + cardIndicatorLineWidth;

export const TestColorIndicatorWrapper = styled(Box, {
	height: '100%',
	display: 'inline-flex',
	alignItems: 'center',
	paddingTop: 6,
	paddingBottom: 6,
	paddingRight: cardIndicatorPaddingInline,
	paddingLeft: cardIndicatorPaddingInline,
	borderRadius: 24,
});

export const TestColorIndicatorLine = styled(Box, {
	width: cardIndicatorLineWidth,
	height: '100%',
	borderRadius: 24,

	variants: {
		color: {
			[IndicatorColorVariant.LanguageArts]: {
				backgroundColor: '$blue',
			},

			[IndicatorColorVariant.Science]: {
				backgroundColor: '$purple',
			},

			[IndicatorColorVariant.SocialStudies]: {
				backgroundColor: '$yellow',
			},

			[IndicatorColorVariant.Math]: {
				backgroundColor: '$positiveVivid',
			},

			[IndicatorColorVariant.Spanish]: {
				backgroundColor: '$tertiaryVivid',
			},

			[IndicatorColorVariant.SEL]: {
				backgroundColor: '$indigo',
			},

			[IndicatorColorVariant.Other]: {
				backgroundColor: '$lowContrast',
			},
		},
	},
});
