/* eslint-disable @typescript-eslint/naming-convention */
import {AnswerState, TestType, TestScreenType, SubjectType} from '@esgi/core/enums';
import moment from 'moment';

export class TestSession {
	id: number;
	testDate: moment.Moment = moment();
	number: number;
	originalTestDate: moment.Moment;
	deleted: boolean;
	answers: Answer[] = [];
	durationString: string;
	userID: number;
	userName: string;
	testScreenType: TestScreenType;

	constructor(session?: TestSession) {
		if (session) {
			for (const key in session) {
				this[key] = session[key];
			}
		}
	}

	public get isEmpty(): boolean {
		return this.id === undefined || this.id <= 0;
	}

	public get testDateIso() {
		if (this.id && this.testDate) {
			return this.testDate.toISOString(true);
		}

		return null;
	}

	public get testDateString() {
		if (this.id && this.testDate) {
			const dateStr = this.testDate.format('M-DD-YY h:mm A');
			if (this.deleted) {
				return dateStr + ' (deleted)';
			}
			return dateStr;
		}

		return 'None';
	}

	public static FromResponse(response: TestSessionResponse): TestSession {
		const testSesstion = new TestSession();
		testSesstion.testDate = moment(response.testDate);
		testSesstion.number = response.number;
		testSesstion.originalTestDate = response.originalTestDate
			? moment(response.originalTestDate)
			: null;
		testSesstion.deleted = response.isDeleted;
		testSesstion.id = response.sessionId;
		testSesstion.durationString = response.durationString;
		testSesstion.userID = response.userID;
		testSesstion.userName = response.userName;
		testSesstion.testScreenType = response.testScreenType;

		return testSesstion;
	}

	public static FromResponseArray(responses: TestSessionResponse[]): TestSession[] {
		const result = responses.map(
			(session) => TestSession.FromResponse(session),
		);

		result.sort((a, b) => b.number - a.number);

		return result;
	}

	public static Empty(): TestSession {
		const testSesstion = new TestSession();
		testSesstion.id = -1;
		testSesstion.testDate = moment();
		return testSesstion;
	}
}

export class Answer {
	answerState: number;
	comment: string;
	text: string;
	inherited: boolean;
	questionId: number;
	orderNumber: number;
	id: number;
	sessionId: number;
	testDate: moment.Moment;
	userID: number;

	public static FromResponse(
		response: AnswerResponse,
		questions: Question[],
		sessions: TestSession[],
	): Answer {
		const question = questions.find(({questionId}) => questionId === response.questionID);
		const session = sessions.find(({id}) => id === (response.parentTestSessionID || response.testSessionID));
		return Answer.From(response, question, session);
	}

	public static From(
		response: AnswerResponse,
		question: Question,
		session: TestSession,
	): Answer {
		const answer = new Answer();
		answer.answerState = AnswerState.NotTested;

		if (response) {
			answer.answerState = AnswerState[response.answerState];
			answer.id = response.testSessionAnswerID;
			answer.comment = response.comment || '';
			answer.inherited = !!response.parentTestSessionID;
		}

		answer.text = question.name;
		answer.questionId = question.questionId;
		answer.orderNumber = question.orderNumber;
		answer.sessionId = response
			? response.testSessionID
			: session ? session.id : 0;
		answer.testDate = session ? moment(session.testDate) : moment();
		answer.userID = session ? session.userID : 0;

		return answer;
	}

	public static FromResponseArray(
		responses: AnswerResponse[],
		questions: Question[],
		sessions: TestSession[],
	): Answer[] {
		const answers = responses
			.filter((a) => questions.some((q) => q.questionId === a.questionID))
			.map((answer) => Answer.FromResponse(answer, questions, sessions));

		if (answers.length < questions.length) {
			const missedQuestions = questions.filter((q) =>
				answers.filter((a) => a.questionId === q.questionId).length === 0,
			);

			missedQuestions.forEach((quesiton) => {
				answers.push(Answer.From(null, quesiton, sessions[0]));
			});
		}

		return answers;
	}

	public static Empty(quesiton: Question) {
		return Answer.From({answerState: 'NotTested'}, quesiton, null);
	}
}

export class Question {
	name: string;
	questionId: number;
	orderNumber: number;

	public static FromResponse(response: QuestionResponse): Question {
		const question = new Question();
		question.name = response.name;
		question.orderNumber = response.orderNumber;
		question.questionId = response.questionId;

		return question;
	}

	public static FromResponseArray(responses: QuestionResponse[]): Question[] {
		return responses.map((question) => Question.FromResponse(question));
	}
}

export interface InitArguments {
	groupID: number;
	teacherID: number;
	classID: number;
	groupName: string;
}

// Requests
interface TestSessionRequest {
	testSessionID: number;
}

interface TestSessionRequest2 {
	testSessionId: number;
}

export interface InitRequest {
	studentID?: number;
	testID: number;
}

export interface ResultRequest extends TestSessionRequest {}

export interface RestoreSessionRequest extends TestSessionRequest2 {}

export interface DeleteSessionRequest extends TestSessionRequest2 {}

export interface UpdateYNRequest extends TestSessionRequest {
	testID: number;
	listTsa: TestSessionAnswer[];
	testDate: string;
	testDateTouched: boolean;
	studentID: number;
	testType: TestType;
	notes?: string;
}

export interface SaveYNNoteRequest extends TestSessionRequest {
	questionID: number;
	comment: string;
}

export interface DeleteYNNoteRequest extends TestSessionRequest {
	questionID: number;
}

export interface UpdateSSRequest extends TestSessionRequest {
	testID: number;
	testDate: string;
	testDateTouched: boolean;
	studentID: number;
	score: number;
	testType: TestType;
	notes?: string;
}

export interface DownloadRequest {
	classID: number,
	specialistGroupID: number,
	studentID: number,
	testID: number,
	sessionID: number,
	subject: string,
}

// Responses
export interface InitResponse {
	testSessionsInfo: TestSessionResponse[];
	studentFirstName: string;
	studentLastName: string;
	testName: string;
	canEdit: boolean;
	canDelete: boolean;
	canTest: boolean;
	questions: QuestionResponse[];
	testType: string;
	totalPossible: number;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	nowProfileTZ: string;
	isUserLinkedDistrict: boolean;
	iepGoal?: IEPGoal;
	iepStatuses?: IEPStatus[];
}

export interface TestSessionResponse {
	testDate: string;
	originalTestDate?: string;
	isDeleted: boolean;
	typeSession: string;
	sessionId: number;
	duration: number;
	number: number;
	isArtificial: boolean;
	durationString: string;
	testDateString: string;
	originalTestDateString: string;
	userID: number;
	userName: string;
	testScreenType: TestScreenType;
}

export interface QuestionResponse {
	name: string;
	questionId: number;
	orderNumber: number;
}

export interface ResultYnResponse {
	summaryNote: string;
	answers: AnswerResponse[];
}

export interface ResultScoreResponse {
	score: number;
	note: string;
}

export interface AnswerResponse {
	testSessionID?: number;
	questionID?: number;
	answerState?: string;
	testSessionAnswerID?: number;
	comment?: any;
	parentTestSessionID?: number;
}

export interface UpdateResponse {
	testSessionID: number;
}

export interface CreateSessionResponse {
	nowProfileTZ: string;
}

export interface TestSessionAnswer {
	answerState: number;
	questionID: number;
}

export type TestSessionDetails = Partial<Omit<InitResponse, 'nowProfileTZ' | 'testType'>> & {nowProfileTZ: moment.Moment, testType: TestType} & Record<string, any> | null;

export type TestSessions = {[key: number]: TestSession};

export interface Subject {
	id: number;
	name?: string;
	type: SubjectType;
}

export interface IEPGoal {
	benchmark: string;
	goal: string;
	id: number;
	isCompleted: boolean;
	statusID: number;
}

export interface IEPStatus {
	id: number;
	name: string;
	isDeleted: boolean;
}
