import React from 'react';
import styles from './action-button.module.less';
import {join, isTouchDevice} from '@esgillc/ui-kit/utils';


interface StoryButtonProps {
	disabled?: boolean;
	clicked: () => any;
	className?: string;
}

export function StoryButton({clicked, className, disabled}: StoryButtonProps) {
	return (
		<button onTouchStart={clicked} onClick={isTouchDevice() ? undefined : clicked}
		        className={join(styles.actionBtn, styles.storyBtn, className)} disabled={disabled} type='button'>
			<svg width='127' height='126' viewBox='0 0 127 126' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='64.1894' cy='63' r='61' fill='#0088CC' stroke='#09A9EE' strokeWidth='3'/>
				<g clipPath='url(#clip0_112_130)'>
					<path
						d='M63.6894 0C81.0811 0 96.8311 6.98969 108.237 18.3055C119.644 29.6213 126.689 45.2463 126.689 62.5C126.689 79.7537 119.644 95.3787 108.237 106.694C96.8311 118.01 81.0811 125 63.6894 125C46.2977 125 30.5477 118.01 19.1413 106.694C7.735 95.3956 0.689392 79.7706 0.689392 62.5C0.689392 45.2294 7.735 29.6213 19.1413 18.3055C30.5477 6.98969 46.2977 0 63.6894 0ZM106.613 19.9172C95.634 9.02552 80.4483 2.29031 63.6894 2.29031C46.9304 2.29031 31.7619 9.02552 20.7659 19.9172C9.78711 30.8089 2.99802 45.8741 2.99802 62.5C2.99802 79.1259 9.78711 94.1741 20.783 105.083C31.7619 115.974 46.9475 122.727 63.7065 122.727C80.4655 122.727 95.634 115.991 106.63 105.083C117.609 94.1911 124.415 79.1259 124.415 62.5C124.415 45.8741 117.626 30.8089 106.63 19.9172H106.613Z'
						fill='white'/>
					<path fillRule='evenodd' clipRule='evenodd'
					      d='M47.8026 85.5219C42.5355 83.6557 37.0974 82.519 31.4711 82.519C25.8449 82.519 30.4793 82.0779 30.4793 81.535V44.449H26.1185V91.1883H60.064C56.0965 88.9658 52.0094 87.0148 47.8026 85.5219Z'
					      fill='white'/>
					<path fillRule='evenodd' clipRule='evenodd'
					      d='M32.463 80.568C37.9866 80.6868 43.305 81.8404 48.4695 83.6727C53.3604 85.4201 58.0974 87.7782 62.6976 90.4418V44.0079C57.6699 41.005 52.6593 38.4942 47.5461 36.6959C42.6381 34.9824 37.6446 33.9305 32.4459 33.8118V80.551L32.463 80.568Z'
					      fill='white'/>
					<path fillRule='evenodd' clipRule='evenodd'
					      d='M64.6813 44.0079V90.4418C69.2814 87.7613 74.0355 85.4031 78.9093 83.6727C84.0738 81.8404 89.4093 80.7037 94.9158 80.568V33.8287C89.7342 33.9475 84.7236 34.9824 79.8156 36.7128C74.7195 38.5111 69.6918 41.022 64.6642 44.0249L64.6813 44.0079Z'
					      fill='white'/>
					<path fillRule='evenodd' clipRule='evenodd'
					      d='M95.9077 82.519C90.2814 82.519 84.8433 83.6557 79.5762 85.5219C75.3865 87.0148 71.2993 88.9658 67.3148 91.1883H101.243V44.449H96.8824V81.535C96.8824 82.0779 96.4378 82.519 95.8906 82.519H95.9077Z'
					      fill='white'/>
				</g>
				<defs>
					<clipPath id='clip0_112_130'>
						<rect width='126' height='125' fill='white' transform='translate(0.689392)'/>
					</clipPath>
				</defs>
			</svg>

		</button>
	);
}
