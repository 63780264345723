import * as React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

export class Props {
    id: number;
    text: string;
    onClick: () => any;
    deleteClicked: () => any;
    editable: boolean = false;
}

export class Chip extends React.PureComponent<Props> {
	render(): JSX.Element | false | null {
		return <OnHoverTooltip
            message={this.props.id ? `Show all images tagged with '${this.props.text}'` : ''}>
            <div className='tag' onClick={() => this.props.onClick()}>
                <span className='tag-name clickable'>{this.props.text}</span>
                {this.props.editable && <div className='delete-icon' onClick={(e) => {
                    e.stopPropagation();
                    this.props.deleteClicked();
                }}>
                    <i className='fa fa-times'/>
                </div>}
            </div>
        </OnHoverTooltip>;
	}
}