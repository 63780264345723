import {Button} from '@esgi/ui';
import {CheckAll} from '@esgi/ui/icons';
import {useTestsRootContext} from '../../tests-root/context';

export function SelectAll() {
	const {handleSelectAll} = useTestsRootContext();

	return (
		<Button.Icon onClick={handleSelectAll}>
			<CheckAll/>
		</Button.Icon>
	);
}
