jQuery.param = function (a, traditional) {
    var prefix,
		s = [],
		add = function (key, value) {
		    // If value is a function, invoke it and return its value
		    value = jQuery.isFunction(value) ? value() : (value == null ? "" : value);
		    s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
		};

    // Set traditional to true for jQuery <= 1.3.2 behavior.
    if (traditional === undefined) {
        traditional = jQuery.ajaxSettings && jQuery.ajaxSettings.traditional;
    }

    // If an array was passed in, assume that it is an array of form elements.
    if (jQuery.isArray(a) || (a.jquery && !jQuery.isPlainObject(a))) {
        // Serialize the form elements
        jQuery.each(a, function () {
            add(this.name, this.value);
        });

    } else {
        // If traditional, encode the "old" way (the way 1.3.2 or older
        // did it), otherwise encode params recursively.
        for (prefix in a) {
            buildParams(prefix, a[prefix], traditional, add);
        }
    }

    // Return the resulting serialization
    return s.join("&").replace(/%20/g, "+");
};

export function buildParams(prefix, obj, traditional, add) {
    var name;

    if (jQuery.isArray(obj)) {
        // Serialize array item.
        jQuery.each(obj, function (i, v) {
            if (traditional || /\[\]$/.test(prefix)) {
                // Treat each array item as a scalar.
                add(prefix, v);

            } else {
                // Item is non-scalar (array or object), encode its numeric index.
                buildParams(prefix + "[" + i + "]", v, traditional, add);
            }
        });

    } else if (!traditional && jQuery.type(obj) === "object") {
        // Serialize object item.
        for (name in obj) {
            buildParams(prefix + "." + name, obj[name], traditional, add);
        }

    } else if (typeof (obj) === "function") {
        return;
    }
    else {
        // Serialize scalar item.
        add(prefix, obj);
    }
}

jQuery.whenAll = function (deferreds) {
    return $.Deferred(function (def) {
        $.when.apply(jQuery, deferreds).then(
            function () {
                def.resolveWith(this, [Array.prototype.slice.call(arguments)]);
            },
            function () {
                def.rejectWith(this, [Array.prototype.slice.call(arguments)]);
            });
    });
}