import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {Entity, StudentResult} from '../types/table-level-report-service-types';
import {
	ChartCriteriaResult,
	CriteriaNoteModel,
	NotesModel,
	NotesResponse,
	StudentLevelReportModel,
	StudentLevelTableModel,
	StudentLevelChartModel,
} from '../types/student-level-report-service-types';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {FiltersService} from '../services/filters-service';
import {buildChart, ChartSeria} from '../utils/build-chart';
import {isEmpty, isNull} from 'underscore';
import moment from 'moment';

export class StudentLevelReportService extends BaseService {
	private readonly controller = 'reports/rubric-results/student-level';
	private readonly studentLevelReport = new BehaviorSubject<StudentLevelReportModel | null>(null);
	private readonly selectedStudentID = new BehaviorSubject<number | null>(null);
	private notesCache: NotesModel[] = [];

	public filter$ = this.completeOnDestroy(this.filterService.filter$);

	private readonly loading = new Subject<boolean>();
	public loading$ = this.completeOnDestroy(this.loading);

	private readonly showNotes = new BehaviorSubject<boolean>(false);
	public showNotes$ = this.completeOnDestroy(this.showNotes);

	private readonly showSummary = new BehaviorSubject<boolean>(false);
	public showSummary$ = this.completeOnDestroy(this.showSummary);

	private readonly showInColor = new BehaviorSubject<boolean>(true);
	public showInColor$ = this.completeOnDestroy(this.showInColor);

	constructor(private readonly filterService: FiltersService) {
		super();
	}

	public students$ = this.completeOnDestroy(this.studentLevelReport).pipe(
		map((report) => {
			if (isNull(report)) {
				return [];
			}

			const allOption: Entity = {
				id: 0,
				value: 'All',
			};

			const reportResultsOptions: Entity[] = report.results.map(({studentID, firstName, lastName}) => ({
				id: studentID,
				value: `${firstName} ${lastName}`,
			}));

			const options: Entity[] = [allOption, ...reportResultsOptions];

			return options;
		}),
	);

	public selectedStudent$ = this.completeOnDestroy(combineLatest([this.selectedStudentID, this.students$])).pipe(
		map(([selectedStudentID, students]) => {
			if (isNull(selectedStudentID) || !students.length) {
				return null;
			}

			return students.find(({id}) => id === selectedStudentID);
		}),
	);

	public tables$ = this.completeOnDestroy(
		combineLatest([this.selectedStudentID, this.studentLevelReport, this.filter$, this.students$]),
	).pipe(
		map(([selectedStudentID, report, filter, students]) => {
			if (isNull(report) || isNull(selectedStudentID) || isNull(filter) || !students.length) {
				return [];
			}

			const studentResults =
				selectedStudentID === 0
					? report.results
					: report.results.filter(({studentID}) => studentID === selectedStudentID);

			const copyStudentResults: StudentResult[] = JSON.parse(JSON.stringify(studentResults));

			this.filterService.applyFilters(copyStudentResults, report.currentPeriod);

			return copyStudentResults.map(({criteriaResults, studentID}) => {
				const totalPossible =
					criteriaResults.filter(({criteriaName}) => criteriaName !== 'Score').length * report.maxLevel;

				const totalScores = criteriaResults.find(({criteriaName}) => criteriaName === 'Score');

				const student = students.find(({id}) => id === studentID);

				if (!student) {
					throw new Error('student is undefined');
				}

				if (!totalScores) {
					throw new Error('totalScores is undefined');
				}

				const studentLevelTableModel: StudentLevelTableModel = {
					rubricName: report.downloadData.rubricName,
					studentID,
					studentName: student.value,
					totalScores: totalScores.periodResults,
					totalPossible,
				};

				return studentLevelTableModel;
			});
		}),
	);

	public charts$: Observable<StudentLevelChartModel[] | null> = this.completeOnDestroy(
		combineLatest([this.selectedStudentID, this.studentLevelReport, this.filter$]),
	).pipe(
		map(([selectedStudentID, report, filter]) => {
			if (isNull(report) || isNull(selectedStudentID)) {
				return null;
			}

			const studentResults =
				selectedStudentID === 0
					? report.results
					: report.results.filter(({studentID}) => studentID === selectedStudentID);

			return studentResults.map(({criteriaResults, studentID}) => {
				const chartCriteriaResults = criteriaResults
					.filter(({criteriaName}) => criteriaName !== 'Score')
					.map(({periodResults, criteriaName}) => {
						const lastResult = [...periodResults].reverse().find(({testSessionID}) => testSessionID !== 0);

						const chartCriteriaResult: ChartCriteriaResult = {
							name: criteriaName,
							score: parseInt(lastResult?.value ?? '0'),
						};

						return chartCriteriaResult;
					});

				const series: ChartSeria[] = chartCriteriaResults.map(({name, score}) => {
					return {
						name,
						y: score,
						color: filter.showInColor ? '#51C3F2' : '#BDBDBD',
					};
				});

				const maxScore = report.maxLevel * chartCriteriaResults.length;
				const score = chartCriteriaResults.reduce((sum, {score}) => sum + score, 0);

				const studentLevelChartModel: StudentLevelChartModel = {
					studentID,
					chart: buildChart(series, score, maxScore, Math.max(...chartCriteriaResults.map((r) => r.score))),
				};

				return studentLevelChartModel;
			});
		}),
		filter((chartModel) => !isNull(chartModel)),
	);

	public notes$: Observable<NotesModel[]> = this.completeOnDestroy(
		combineLatest([this.selectedStudentID, this.studentLevelReport, this.filter$]),
	).pipe(
		mergeMap(([selectedStudentID, report, filter]) => {
			if (isNull(report) || isNull(selectedStudentID) || !(filter.showNotes || filter.showSummary)) {
				return [];
			}

			const studentResults =
				selectedStudentID === 0
					? report.results
					: report.results.filter(({studentID}) => studentID === selectedStudentID);

			const lastTestSessionIDs = this.getLastTestSessionIDs(studentResults);

			if (!lastTestSessionIDs.length) {
				return [];
			}

			if (
				studentResults.every((studentResult) =>
					this.notesCache.some(({studentID}) => studentID === studentResult.studentID),
				)
			) {
				return of(
					selectedStudentID === 0
						? this.notesCache
						: this.notesCache.filter(({studentID}) => studentID === selectedStudentID),
				);
			}

			this.setLoading(true);

			return this.httpClient.ESGIApi.get<{notes: NotesResponse[]}>(
				this.controller,
				'notes',
				{testSessionIDs: lastTestSessionIDs, sessionGuid: report.sessionGuid},
			)
				.withCustomErrorHandler(() => this.setLoading(false))
				.pipe(
					map((response) => {
						this.notesCache = studentResults
							.map((studentResult) => {
								const studentNotes = response.notes.find(({studentID}) => studentID === studentResult.studentID);

								if (!studentNotes) {
									const notesModel: NotesModel = {
										studentID: studentResult.studentID,
										criteriaNotes: [],
										summaryNotes: '',
									};

									return notesModel;
								}

								const {criteriaNotes, summaryNotes} = studentNotes;

								const result = criteriaNotes
									.map((criteriaNote) => {
										const criteria = studentResult.criteriaResults.find(
											(criteriaResult) => criteriaResult.criteriaID === criteriaNote.criteriaID,
										);

										if (!criteria) {
											throw new Error('criteria is undefined');
										}

										const criteriaNoteModel: CriteriaNoteModel = {
											notes: criteriaNote.notes,
											criteriaName: criteria.criteriaName,
										};

										return criteriaNoteModel;
									})
									.filter(({notes}) => !isEmpty(notes));

								const notesModel: NotesModel = {
									studentID: studentResult.studentID,
									criteriaNotes: result,
									summaryNotes,
								};

								return notesModel;
							})
							.filter((noteModel) => !isNull(noteModel));

						return this.notesCache;
					}),
					tap(() => this.setLoading(false)),
				);
		}),
		filter((noteModel) => !isNull(noteModel)),
	);

	public init(report: StudentLevelReportModel) {
		this.selectedStudentID.next(report.selectedStudentID);
		this.studentLevelReport.next(report);
		this.filterService.setFilter(report.filter);
	}

	public downloadPDF() {
		this.downloadFile(false);
	}

	public downloadZip() {
		this.downloadFile(true);
	}

	private downloadFile(splitByStudent: boolean) {
		this.setLoading(true);

		const date = moment().format('YYYY_M_D');
		const extension = splitByStudent ? 'zip' : 'pdf';

		const filename = `Rubric_Results_Student_${date}.${extension}`;

		const studentLevelReport = this.studentLevelReport.value;

		if (isNull(studentLevelReport)) {
			throw new Error('studentLevelReport is undefined');
		}

		const {
			results,
			downloadData,
			rubricID,
			teacherClassID,
			teacherGroupID,
			specialistGroupID,
			trackID,
			subjectID,
			subjectType,
			sortBy,
			sessionGuid,
		} = studentLevelReport;

		const studentResults =
			this.selectedStudentID.value === 0
				? results
				: results.filter(({studentID}) => studentID === this.selectedStudentID.value);

		const lastTestSessionIDs = this.getLastTestSessionIDs(studentResults);

		this.httpClient.ESGIApi.file(this.controller, 'export/pdf', filename, {
			...downloadData,
			studentID: this.selectedStudentID.value,
			splitByStudent,
			filename,
			testSessionIDs: lastTestSessionIDs,
			rubricID,
			teacherClassID,
			teacherGroupID,
			specialistGroupID,
			trackID,
			subjectID,
			subjectType,
			filter: this.filterService.getFilter(),
			sortBy,
			sessionGuid,
		}).subscribe(() => this.setLoading(false));
	}

	public setShowBaseLineFilter(showBaseline: boolean) {
		this.filterService.setShowBaseLineFilter(showBaseline);
	}

	public setShowNotesFilter(showNotes: boolean) {
		this.filterService.setShowNotesFilter(showNotes);
		this.showNotes.next(showNotes);
	}

	public setShowSummaryFilter(showSummary: boolean) {
		this.filterService.setShowSummaryFilter(showSummary);
		this.showSummary.next(showSummary);
	}

	public setShowInColorFilter(showInColor: boolean) {
		this.filterService.setShowInColorFilter(showInColor);
	}

	public setCarryForwardFilter(carryScoresForward: boolean) {
		this.filterService.setCarryForwardFilter(carryScoresForward);
	}

	public setMarkingPeriodFilter(showCurrentMarkingPeriod: boolean) {
		this.filterService.setMarkingPeriodFilter(showCurrentMarkingPeriod);
	}

	private setLoading(loading: boolean) {
		this.loading.next(loading);
	}

	private getLastTestSessionIDs(studentResults: StudentResult[]) {
		return studentResults
			.map(({criteriaResults}) => criteriaResults[0])
			.map((criteriaResult) => {
				if (!criteriaResult) {
					return 0;
				}

				const lastResult = [...criteriaResult.periodResults].reverse().find(({testSessionID}) => testSessionID !== 0);

				return lastResult?.testSessionID ?? 0;
			})
			.filter((x) => x > 0);
	}

	public selectStudent(studentID: number) {
		this.selectedStudentID.next(studentID);
	}

	public override destroy() {
		this.filterService.destroy();
		super.destroy();
	}
}
