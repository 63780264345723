import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {RootBox} from './index.styled';

type CentralPanelRootProps = ComponentPropsWithoutRef<'div'> & BaseComponentProps;

export const CentralPanelRoot = forwardRef<HTMLDivElement, CentralPanelRootProps>(
	({dataCy = 'features-central-panel-root', css = {}, children, ...props}, forwardedRef) => {
		return (
			<RootBox dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</RootBox>
		);
	},
);
