import {deepCopy} from 'shared/utils';
import {
	CommonInfo,
	CriteriaInfo,
	DescriptionsInfo,
	getNextTempID, LevelsInfo,
} from './common';
import {recalculateLevelsOrders} from './ordering';
import {sortLevels} from './sorting';
import {Criteria, Description, Level, LevelDisplayMode} from '../models';
import {getLevelName} from '../level-score-type';

export function createCriteria(criteria: Criteria[], levels: Level[], descriptions: Description[]): CommonInfo<Criteria> & CriteriaInfo & DescriptionsInfo {
	const safeCriteria = deepCopy(criteria);
	const newCriteria = {id: getNextTempID(safeCriteria), name: '', order: safeCriteria.length + 1} as Criteria;
	safeCriteria.push(newCriteria);

	const safeDescriptions = deepCopy(descriptions);
	for (let level of levels) {
		safeDescriptions.push({
			id: getNextTempID(safeDescriptions),
			description: '',
			details: '',
			levelID: level.id,
			criteriaID: newCriteria.id,
		} as Description);
	}

	return {
		entity: newCriteria,
		criteria: safeCriteria,
		descriptions: safeDescriptions,
	};
}

export function createLevel(criteria: Criteria[], levels: Level[],
	 descriptions: Description[], isZero: boolean = false): CommonInfo<Level> & LevelsInfo & DescriptionsInfo {
	const safeLevels = deepCopy(levels);
	const newLevel = {
		id: getNextTempID(safeLevels),
		name: '',
	 	score: isZero? safeLevels.length : safeLevels.length + 1,
	} as Level;
	safeLevels.push(newLevel);

	let safeDescriptions = deepCopy(descriptions);
	for (let item of criteria) {
		safeDescriptions.push({
			id: getNextTempID(safeDescriptions),
			description: '',
			details: '',
			levelID: newLevel.id,
			criteriaID: item.id,
		} as Description);
	}
	const reorderResult = recalculateLevelsOrders(safeLevels, isZero);
	const outLevels = sortLevels(reorderResult.levels);
	safeDescriptions = safeDescriptions.sort((d1, d2) => {
		return safeLevels.findIndex(l => l.id === d1.levelID) - safeLevels.findIndex(l => l.id === d2.levelID);
	});

	return {
		entity: outLevels.find(l => l.id === newLevel.id),
		levels: outLevels,
		descriptions: safeDescriptions,
	};
}


export function createZeroLevel(criteria: Criteria[], levels: Level[], descriptions: Description[], displayMode: LevelDisplayMode = LevelDisplayMode.Number): CommonInfo<Level> & LevelsInfo & DescriptionsInfo {
	const safeLevels = deepCopy(levels);
	const newLevel = {
		id: getNextTempID(safeLevels),
		name: displayMode === LevelDisplayMode.Number? '': getLevelName(0, ''),
	 	score: 0,
		metaInfo: {defaultField: true},
	} as Level;
	
	let safeDescriptions = deepCopy(descriptions);
	for (let item of criteria) {
		safeDescriptions.push({
			id: getNextTempID(safeDescriptions),
			description: '',
			details: '',
			levelID: newLevel.id,
			criteriaID: item.id,
		} as Description);
	}

	const outLevels = sortLevels([...safeLevels, newLevel]);
	safeDescriptions = safeDescriptions.sort((d1, d2) => {
		return safeLevels.findIndex(l => l.id === d1.levelID) - safeLevels.findIndex(l => l.id === d2.levelID);
	});

	return {
		entity: outLevels.find(l => l.id === newLevel.id),
		levels: outLevels,
		descriptions: safeDescriptions,
	};
}
