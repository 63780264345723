import {BaseService} from '@esgi/core/service';
import {
	SettingsResponse,
	Row,
	TestRequest,
	TestResponse,
	FilterStatus,
	DateRange,
	InitRequest,
	InitResponse,
	TestModel, TestChartModel,
} from '../types';
import {BehaviorSubject, tap, takeUntil} from 'rxjs';
import {getSelectedSchoolYearID} from '@esgi/core/authentication';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {omit} from 'underscore';
import {getDateWithoutTimeZone, getImageFromSVG} from '../utils';

export class IEPProgressReportService extends BaseService {
	public readonly settings$ = new BehaviorSubject(null);
	public readonly rows$ = new BehaviorSubject<Row[]>([]);
	public readonly selected$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
	public readonly reportData$ = new BehaviorSubject<TestModel[]>([]);
	public readonly dateRange$ = new BehaviorSubject<DateRange>(null);
	public readonly testResultsExist$ = new BehaviorSubject<boolean>(null);
	private readonly fileName = 'IEP_Progress_Report';
	private readonly reportRows$ = new BehaviorSubject<Row[]>([]);
	private readonly controller = 'reports/iep-progress';

	constructor(private hierarchy: HierarchySnapshot) {
		super();
	}

	public settings() {
		const globalSchoolYearID = getSelectedSchoolYearID();
		return this.httpClient.ESGIApi.post<SettingsResponse>(
			this.controller,
			'settings',
			{globalSchoolYearID, hierarchy: this.hierarchy},
		).pipe(tap((response) => {
			this.settings$.next(response);
		}));
	}

	public tests(params: TestRequest) {
		return this.httpClient.ESGIApi.get<TestResponse>(
			this.controller,
			'tests',
			params,
		).pipe(tap((response) => {
			this.reportRows$.next(response.tests.map((test) => {
				test.iepGoal = test.iepGoalCompleted || test.iepGoalInProgress;
				return test;
			}));
			this.setFilter({status: FilterStatus.All});
		}));
	}

	public exportPdf(params, callback) {
		const {specialist, subject, student} = params;
		const promises: Promise<TestChartModel>[] = params.testIDs.map(x => {
			const svg = (document.querySelector(`#export-chart-${x} .highcharts-root`) as any);
			return getImageFromSVG(x, svg.outerHTML)
		});
		Promise.all(promises).then((tests) => {
			const data = {
				...omit(params, 'specialist', 'subject', 'student'),
				fromDate: getDateWithoutTimeZone(params.fromDate),
				toDate: getDateWithoutTimeZone(params.toDate),
				specialistTitle: specialist.title,
				specialistLastName: specialist.lastName,
				subjectName: subject.name,
				studentFirstName: student.firstName,
				studentLastName: student.lastName,
				studentID: student.id,
				tests: tests,
			};
			return this.httpClient.ESGIApi.file(
				this.controller,
				'download-pdf',
				this.fileName,
				data,
			).pipe(
				tap(() => {
					if (typeof callback === 'function') {
						callback();
					}
				}),
				takeUntil(this.destroy$),
			).subscribe();
		})
	}

	public setShowBaseline(showBaseline: boolean) {
		return this.httpClient.ESGIApi.post(
			this.controller,
			'update-show-base-line',
			{value: showBaseline ? 'True' : 'False'},
		).subscribe();
	}

	public initReport(request: InitRequest, callback): void {
		this.httpClient.ESGIApi.get<InitResponse>(
			this.controller,
			'report',
			request,
		).pipe(
			tap(() => {
				if (typeof callback === 'function') {
					callback();
				}
			}),
			takeUntil(this.destroy$),
		).subscribe((response) => {
			this.reportData$.next(response.tests);
			this.dateRange$.next(response.dateRange);
			this.testResultsExist$.next(response.testResultsExist);
		});
	}

	public setFilter({status}) {
		if (status === FilterStatus.Complete) {
			this.rows$.next([...this.reportRows$.value.filter(
				({iepGoalCompleted}) => iepGoalCompleted),
			]);
			return;
		}
		if (status === FilterStatus.Progress) {
			this.rows$.next([...this.reportRows$.value.filter(
				({iepGoalInProgress}) => iepGoalInProgress),
			]);
			return;
		}
		this.rows$.next([...this.reportRows$.value]);
	}

	public select(id, value) {
		if (value) {
			this.selected$.next([...this.selected$.value, id]);
		} else {
			this.selected$.next(this.selected$.value.filter((v) => v !== id));
		}
	}

	public selectAll(value) {
		const ids = this.rows$.value
			.filter(({iepGoal}) => iepGoal)
			.map(({id}) => id);
		this.selected$.next(value ? ids : []);
	}
}
