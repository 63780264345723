import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import React from 'react';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	SharedComponent,
	SharedProps,
} from '@esgi/deprecated/react';
import {Api} from '../../../api';
import {Events, ItemType} from '../../../models';

export class State {
	constructor(public ids: number[], public type: ItemType, public name = '', public parentFolderName?: string) {
	}
}

export class Props extends SharedProps<State> {
	close: (ids?: number[]) => any;
}

export class RemoveModal extends SharedComponent<State, Props> {
	title = () => {
		switch (this.state.type) {
			case ItemType.Folder:
				return `Remove Folder`;
			case ItemType.Image:
				return this.state.ids.length > 1 ? `Remove ${this.state.ids.length} Images` : `Remove 1 Image`;
		}
	};
	innerText = () => {
		switch (this.state.type) {
			case ItemType.Folder:
				return ` "${this.state.name}" folder`;
			case ItemType.Image:
				if (this.state.ids.length > 1) {
					return `these images`;
				} else {
					return ` "${this.state.name}" image`;
				}
		}
	};

	render(): JSX.Element | false | null {
		return <Modal className='white-header remove-modal ig-inner-modal' animate={true}
		              onCatchError={() => this.props.close()}>
			<ModalHeader title={this.title()}>
			</ModalHeader>
			<ModalBody>
				<span>Are you sure you want to remove {this.innerText()}? You cannot undo this action.</span>
			</ModalBody>
			<ModalFooter>
				<Transparent
					onClick={() => this.props.close()}
					title='CANCEL'
				/>
				<Transparent
					onClick={() => this.removeItems()}
					title='REMOVE'
				/>
			</ModalFooter>
		</Modal>;
	}

	private removeItems() {
		Api.DeleteItems(this.state.ids)
			.subscribe(() => {
				this.dispatch(Events.ItemsDeleted, {ids: this.state.ids});
				if (this.state.type === ItemType.Image) {
					showSnackbarNotification(`You've deleted ${this.state.ids.length} image(s) from ${this.state.parentFolderName}`);
				}
				this.props.close(this.state.ids);
			}, () => this.props.close(this.state.ids));
	}
}
