import {
	StudentDetail as StudentDetailUI,
	StudentDetailContext,
	useStudentDetail,
} from '@esgi/main/features/teacher/student-detail';
import {Activities} from '@esgi/main/kits/activities';
import {ExpandablePanel, Reports} from '@esgi/main/kits/reports';
import {useBehaviorSubject, useStream} from '@esgi/ui';
import {Class, Group} from '@esgi/main/libs/store';
import {useMemo, useState} from 'react';
import {StudentTabId} from '../../../home/types';
import {LeftSidebar, RightSidebar, Page, Header, Divider, Content, Wrapper} from '../../layout/index.styled';
import {withNewOldReports} from '../../old-reports';
import {OldStudentsDetail} from '../../old-reports/students/students-detail';

function StudentDetail() {
	const {service} = useStudentDetail();

	const isPageLoading = useBehaviorSubject(service.isPageLoading$);
	const canRunReportPreview = useStream(service.canRunReportPreview$, false);

	const loadedStudents = useBehaviorSubject(service.studentService.loaded$);

	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const studentList = useBehaviorSubject(service.studentService.students$);

	const loadedSubjects = useBehaviorSubject(service.subjectService.loaded$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);

	const defaultSelectedTestsIDs = useBehaviorSubject(service.testService.defaultSelectedTestsIDs$);

	const grades = useBehaviorSubject(service.grades$);
	const selectedSubjectID = useBehaviorSubject(service.selectedSubjectID$);
	const selectedStudents = useBehaviorSubject(service.selectedStudents$);
	const loadingTests = useBehaviorSubject(service.testService.loading$);
	const isReportAlertOpen = useBehaviorSubject(service.isReportAlertOpen$);

	const reportSettings = useBehaviorSubject(service.reportSettings$);
	const tests = useBehaviorSubject(service.testService.tests$);

	const [activeTab, setActiveTab] = useState(StudentTabId.Classes);

	const {emptyTestsMessage, filteredTests} = useMemo(() => {
		let emptyTestsMessage = 'There is no test data for the student.';
		let filteredTests = tests;

		const hasTestedTests = filteredTests.some((x) => x.isTested);
		if (!hasTestedTests) {
			filteredTests = [];
			if (selectedStudents.length > 1) {
				emptyTestsMessage = 'There is no test data for any student selected.';
			}

			const studentIDs = selectedStudents.map(x => x.studentId);
			if(activeTab === StudentTabId.Classes) {
				const fullySelectedNumber = getFullySelectedUnitNumber(classes, studentIDs);
				if (fullySelectedNumber > 0){
					emptyTestsMessage = 'There is no test data for any student in ' + (fullySelectedNumber === 1 ? 'this class' : 'these classes') + '.' ;
				}
			}
			if(activeTab === StudentTabId.Groups) {
				const fullySelectedNumber = getFullySelectedUnitNumber(groups, studentIDs);
				if (fullySelectedNumber > 0){
					emptyTestsMessage = 'There is no test data for any student in ' + (fullySelectedNumber === 1 ? 'this group' : 'these groups') + '.' ;
				}
			}
		}

		return {emptyTestsMessage, filteredTests};
	}, [activeTab, classes, groups, selectedStudents, tests]);

	function getFullySelectedUnitNumber(units: Class[] | Group[], studentIDs: number[]){
		let fullySelectedNumber = 0;
		let allClassStudents: number[] = [];
		for(const unit of units){
			const fullySelected = unit.studentIDs.length > 0 && unit.studentIDs.filter(x => studentIDs.includes(x)).length === unit.studentIDs.length;
			if (fullySelected){
				fullySelectedNumber++;
				allClassStudents = allClassStudents.concat(unit.studentIDs);
			}
		}
		if (studentIDs.every(x => allClassStudents.includes(x))){
			return fullySelectedNumber;
		}
		return 0;
	}

	return (
		<StudentDetailContext.Provider value={service}>
			<Page>
				<LeftSidebar>
					<Activities.StudentsControl
						skeleton={!loadedStudents}
						classes={classes.filter(x => x.studentIDs.length !== 0)}
						groups={groups.filter(x => x.studentIDs.length !== 0)}
						students={studentList}
						onChange={(value) => service.setStudents(value)}
						onActiveTabChange={setActiveTab}
					/>
				</LeftSidebar>
				<Content>
					<Header>
						<Reports.StudentPanel
							studentCounter={selectedStudents.length}
							student={service.studentService.get(selectedStudents.length && selectedStudents[0].studentId)}
						/>
						<Divider />
						<Reports.SubjectControl
							skeleton={!loadedSubjects}
							subjects={subjects}
							value={selectedSubjectID.toString()}
							onChange={(value) => service.setSubject(+value)}
						/>
					</Header>
					{selectedStudents.length ? (
						<Reports.TestsControl
							skeleton={loadingTests}
							tests={filteredTests}
							onChange={(value) => service.setTestsIDs(value)}
							defaultSelectedTestIDs={defaultSelectedTestsIDs}
							emptyListMessage={emptyTestsMessage}
							hasSelectedStudents={selectedStudents.length > 0}
						/>
					) : (
						<Reports.NoStudentPanel />
					)}
				</Content>
				<RightSidebar>
					<Reports.ReportPanel
						reportTitle='Run Report'
						disabled={isPageLoading || !canRunReportPreview}
						onReport={service.openReportAlert.bind(service)}
					/>
					<ExpandablePanel>
						<Wrapper>
							<StudentDetailUI.SettingsControl
								value={reportSettings}
								onSettingValueChange={service.reportSettingValueChange.bind(service)}
							/>
							<StudentDetailUI.GradesControl
								grades={grades}
								value={reportSettings.includeGradeScore}
								onChange={(newValue) =>
									service.reportSettingValueChange({
										key: 'includeGradeScore',
										newValue,
									})
								}
							/>
							<StudentDetailUI.MarkingPeriodControl
								value={reportSettings.markingPeriodAll}
								onChange={(newValue) =>
									service.reportSettingValueChange({
										key: 'markingPeriodAll',
										newValue,
									})
								}
							/>
							<StudentDetailUI.NotTestedControl
								value={reportSettings.displayZeroIfNotTested}
								onChange={(newValue) =>
									service.reportSettingValueChange({
										key: 'displayZeroIfNotTested',
										newValue,
									})
								}
							/>
							<StudentDetailUI.OutputControl
								value={reportSettings.printInColor}
								onChange={(value) => service.reportSettingValueChange({key: 'printInColor', newValue: value})}
							/>
						</Wrapper>
					</ExpandablePanel>
				</RightSidebar>
			</Page>
			{isReportAlertOpen && (
				<StudentDetailUI.PreviewDialog
					onClose={service.closeReportAlert.bind(service)}
					onDownload={service.onDownload.bind(service)}
				/>
			)}
		</StudentDetailContext.Provider>
	);
}

export default withNewOldReports(OldStudentsDetail, StudentDetail);
