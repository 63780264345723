import {Container, StyledText} from './index.styled';

export function NoCredentialsNotification() {
	return (
		<Container dataCy='no-credentials-notification'>
			<StyledText size='xSmall' font='mono' bold color='negativeVivid'>
				All students must have credentials set up before an assignment can be created.
			</StyledText>
		</Container>
	);
}
