import React from 'react';
import styles from '../../styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../../services/report-service';
import {Radio} from '@esgillc/ui-kit/control';

interface Props {
	reportService: ReportService;
}

export function DetailReportSettingsDisplayZero({reportService}: Props) {
	const settingsService = reportService.settingsService;
	const currentPeriod = useBehaviorSubject(settingsService.currentPeriod);
	return <div className={join(styles.settingsCell, styles.flexColumn)}>
		<span className={styles.title}>Marking Periods:</span>
		<div className={styles.radioGroup}>
			<Radio
				checked={currentPeriod === 'All'}
				value='All'
				onChange={() => settingsService.periodChanged('All')}
			>Show All</Radio>
			<Radio
				checked={currentPeriod === 'Current'}
				value='Current'
				onChange={() => settingsService.periodChanged('Current')}
			>Current</Radio>
		</div>
	</div>;
}
