import {SubjectType} from '@esgi/core/enums';
import {SortBy} from 'shared/modules/bingo/steps/preview-questions/preview-questions';

export enum Difficulty {
	Easy = 'easy',
	Medium = 'medium',
	Hard = 'hard',
}

export class InitModel{
	TeacherID: number;
	ClassID: number;
	GroupID: number;
	SpecialistID: number;
	SpecialistGroupID: number;
}

export class EntityModel {
	id: number;
	name: string;

	constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
	}
}

export class QuestionResponse {
	questionID: number;
	name: string;
	orderNum: number;
	pregenerated: boolean;
}

export class Question {
	questionID: number;
	name: string;
	orderNum: number;
	pregenerated: boolean;

	constructor(q: QuestionResponse) {
		this.questionID = q.questionID;
		this.name = q.name;
		this.orderNum = q.orderNum;
		this.pregenerated = q.pregenerated;
	}
}

class Entity {
	id: number;
	selected: boolean;

	constructor(id: number, selected: boolean) {
		this.id = id;
		this.selected = selected;
	}
}

export class Group extends Entity {
	classID: number;
	name: string;

	constructor(r: GroupResponse) {
		super(r.id, r.selected);
		this.classID = r.classID;
		this.name = r.name;
	}
}

export class Class extends Entity {
	name: string;

	constructor(r: ClassResponse) {
		super(r.id, r.selected);
		this.name = r.name;
	}
}

export class SpecialistGroup extends Entity {
	name: string;

	constructor(r: SpecialistGroupResponse) {
		super(r.id, r.selected);
		this.name = r.name;
	}
}

export class Student extends Entity {
	classes: number[];
	groups: number[];
	specialistGroups: number[];
	name: string;

	constructor(r: StudentResponse) {
		super(r.id, false);
		this.classes = r.classes;
		this.groups = r.groups;
		this.specialistGroups = r.specialistGroups;
		this.name = r.firstName + ' ' + r.lastName;
	}
}

export class StudentResponse {
	id: number;
	firstName: string;
	lastName: string;
	classes: number[];
	groups: number[];
	specialistGroups: number[];
}

export class GroupResponse {
	id: number;
	name: string;
	classID: number;
	selected: boolean;
}

export class ClassResponse {
	id: number;
	name: string;
	selected: boolean;
}

export class SpecialistGroupResponse {
	id: number;
	name: string;
	selected: boolean;
}

export class ResultModel {
	test: EntityModel;
	teacher: string;
	className: string;
	specialistGroupName: string;
	subject: string;
	difficulty: string;
	x3cardSize: boolean;
	questions: EntityModel[];
	oneCardPerPage: boolean;
	inClassroom: boolean;
	students: EntityModel[];
	sheetsCount: number;
	cardsCount: number;
	teacherID: number;
	specialistID: number;
}

export interface PlayersResponse {
	students: StudentResponse[];
	classes: ClassResponse[];
	groups: GroupResponse[];
	specialistGroups: SpecialistGroupResponse[];
	testResultsVerbiagesEnabled: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
}

export interface Players {
	classes: Class[],
	groups: Group[],
	specialistGroups: SpecialistGroup[],
	students: Student[],
	loaded: boolean,
	testResultsVerbiagesEnabled?: boolean;
	testResultsCorrectVerbiage?: string;
	testResultsIncorrectVerbiage?: string;
}

export interface Subject {
	id: number;
	subjectType: SubjectType;
	name: string;
	published: boolean;
	level: string;
}

export interface Subjects {
	subjects: Subject[],
	selectedSubject: Subject,
	loaded: boolean,
}

export interface Test {
	id: number;
	name: string;
	color: string;
	questionCount: number;
}

export interface Tests {
	tests: Test[];
	selectedTest: Test;
	loaded: boolean;
}

export interface Cards {
	x3cardSize: boolean,
	twoCardsPerPage: boolean,
	difficulty: string,
}

export interface Questions {
	questions: Question[],
	selectedQuestionsIDs: number[],
	selectedSortBy: SortBy,
	warning: string,
	valid: boolean
}

export interface Print{
	gamePlayedAtStudentHome: boolean;
	numberOfPlayersAtHome: number;
	cardsPerStudent: number;
	cardsPerPerson: number;
	sheetsPerClass: number;
	sheetsPerStudent: number;
}
