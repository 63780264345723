import React from 'react';

interface Props {
	className?: string;
	tooltip?: string;
	color?: string;
	onClick?: () => any;
}

export const ListIcon = (props: Props) => {
	const color = props.color ? props.color : '#000000';

	return (
		<button
			className={props.className}
			onClick={props.onClick}
			title={props.tooltip}
		>
			<svg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M4.41701 14.3501C4.41701 15.4501 3.54964 16.3501 2.48951 16.3501C1.42939 16.3501 0.562012 15.4501 0.562012 14.3501C0.562012 13.2501 1.42939 12.3501 2.48951 12.3501C3.54964 12.3501 4.41701 13.2501 4.41701 14.3501ZM2.48951 6.35007C1.42939 6.35007 0.562012 7.25007 0.562012 8.35007C0.562012 9.45007 1.42939 10.3501 2.48951 10.3501C3.54964 10.3501 4.41701 9.45007 4.41701 8.35007C4.41701 7.25007 3.54964 6.35007 2.48951 6.35007ZM2.48951 0.350067C1.42939 0.350067 0.562012 1.25007 0.562012 2.35007C0.562012 3.45007 1.42939 4.35007 2.48951 4.35007C3.54964 4.35007 4.41701 3.45007 4.41701 2.35007C4.41701 1.25007 3.54964 0.350067 2.48951 0.350067ZM8.27201 4.35007C9.33214 4.35007 10.1995 3.45007 10.1995 2.35007C10.1995 1.25007 9.33214 0.350067 8.27201 0.350067C7.21189 0.350067 6.34451 1.25007 6.34451 2.35007C6.34451 3.45007 7.21189 4.35007 8.27201 4.35007ZM8.27201 6.35007C7.21189 6.35007 6.34451 7.25007 6.34451 8.35007C6.34451 9.45007 7.21189 10.3501 8.27201 10.3501C9.33214 10.3501 10.1995 9.45007 10.1995 8.35007C10.1995 7.25007 9.33214 6.35007 8.27201 6.35007ZM8.27201 12.3501C7.21189 12.3501 6.34451 13.2501 6.34451 14.3501C6.34451 15.4501 7.21189 16.3501 8.27201 16.3501C9.33214 16.3501 10.1995 15.4501 10.1995 14.3501C10.1995 13.2501 9.33214 12.3501 8.27201 12.3501Z'
					fill={color}
				/>
			</svg>
		</button>
	);
};
