import React, {ChangeEvent} from 'react';
import styles from '../../../../styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {Checkbox} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Buttons} from '@esgillc/ui-kit/button';
import {User} from '../../../../../../../models';

interface Props {
	includeQuestionNotesOption: boolean
	includeSummaryNotesOption: boolean
	printInColorOption: boolean
	includeMessageOption: boolean
	hasGradeScales: boolean
	includeGradeOption: boolean
	teacher: User
	onIncludeSummaryChange: (e: ChangeEvent<HTMLInputElement>) => void
	onPrintInColorChange: (e: ChangeEvent<HTMLInputElement>) => void
	onIncludeMessageChange: (e: ChangeEvent<HTMLInputElement>) => void
	onIncludeQuestionChange: (e: ChangeEvent<HTMLInputElement>) => void
	viewMessageClicked: () => void;
}

export function ShowBlock(props: Props) {
	const {
		includeQuestionNotesOption,
		includeSummaryNotesOption,
		printInColorOption,
		includeMessageOption,
		hasGradeScales,
		includeGradeOption,
		teacher,
		onIncludeSummaryChange,
		onIncludeQuestionChange,
		onIncludeMessageChange,
		onPrintInColorChange,
		viewMessageClicked,
	} = props;
	return <>
		<div className={styles.filtersContainer}>
			<div className={styles.formGroup}>
				<strong>Show: </strong>
			</div>
		</div>
		<div className={join(styles.filtersContainer, styles.separator)}>
			{teacher.includeQuestionNotesOption && <>
				<div className={styles.formGroup}>
					<Checkbox
						checked={includeQuestionNotesOption}
						onChange={onIncludeQuestionChange}>Notes</Checkbox>
				</div>
			</>}
			{teacher.includeSummaryNotesOption && <>
				<div className={styles.formGroup}>
					<Checkbox
						checked={includeSummaryNotesOption}
						onChange={onIncludeSummaryChange}>Summary</Checkbox>
				</div>
			</>}
			{teacher.printInColorOption && <>
				<div className={styles.formGroup}>
					<Checkbox
						disabled={!hasGradeScales || !includeGradeOption}
						checked={printInColorOption}
						onChange={onPrintInColorChange}>In color</Checkbox>
				</div>
			</>}
			{teacher.includeMessageOption && <>
				<div className={styles.formGroup}>
					<Checkbox
						checked={includeMessageOption}
						onChange={onIncludeMessageChange}
					>
						<Buttons.Link
							onClick={viewMessageClicked}
							className={styles.link}
						>
							Parent Message
						</Buttons.Link>&nbsp;
						<OnHoverTooltip message='Help'>
							<a href='https://support.esgisoftware.com/hc/en-us/articles/209159806-Parent-Letter#View'
							   target='_blank' rel='noreferrer'>
								<sup className='fa fa-question-circle' aria-hidden='true'/>
							</a>
						</OnHoverTooltip>
					</Checkbox>
				</div>
			</>}
		</div>
	</>;
}
