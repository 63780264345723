import React, {ReactNode} from 'react';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {OnErrorTooltip, TextArea} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';

interface Props {
	readonly: boolean;
	control: FormControl<string>;
}

export class DescriptionField extends React.PureComponent<Props> {
	public render() {
		return <div className={styles.descriptionField}>
			<div className={join(styles.requiredField, this.props.readonly && styles.readonly)}>
				Description:
			</div>
			<div className={styles.fieldContent}>
				<FormElement control={this.props.control}>
					{this.props.readonly ? this.renderText() : this.renderTextInput()}
					<OnErrorTooltip showOnError='required' placement='right'>
						Please enter a test description
					</OnErrorTooltip>
				</FormElement>
			</div>
		</div>;
	}

	private renderText(): ReactNode {
		return <span className={styles.description}>{this.props.control.value}</span>;
	}

	private renderTextInput(): ReactNode {
		return <TextArea maxLength={140}
		                 maxHeight={130}
		                 autoResizeToFit
		                 className={styles.description}
		                 placeholder='max 140 characters'/>;
	}
}
