import {useService} from '@esgi/core/service';
import {Layer} from '@esgillc/ui-kit/core';
import {useBehaviorSubject, useStream, useStreamEffect} from '@esgillc/ui-kit/utils';
import {useEffect, useState} from 'react';
import {SessionEndedAlert} from './components/session-ended-alert';
import {StudentScreenSlider} from './components/slider';
import {ConnectScreen} from './components/screens/connect-screen';
import {DisconnectScreen} from './components/screens/disconnect';
import {StudentSummary} from './components/screens/summary';
import {WaitingScreen} from './components/screens/waiting';
import {SecondScreenService} from './service';

enum ScreenType {
	Code,
	Waiting,
	Disconnect,
	Slider,
	Summary,
	Ended,
}

export default function SecondScreenPage() {
	const [screenType, setScreenType] = useState(ScreenType.Code);
	const service = useService(SecondScreenService);
	const connected = useBehaviorSubject(service.connectStatus);

	const testingModel = useStream(service.testingModel);
	const summary = useStream(service.summaryModel);

	const questionIndex = useBehaviorSubject(service.questionIndex);

	const [minIndex, maxIndex] = useBehaviorSubject(service.questionsLoadRange);

	useStreamEffect(service.clientScreenSession.onDisconnected, () => setScreenType(ScreenType.Disconnect));
	useStreamEffect(service.clientScreenSession.onSessionEnded, () => setScreenType(ScreenType.Ended));
	useStreamEffect(service.clientScreenSession.onConnected, () => {
		if(screenType !== ScreenType.Disconnect) {
			return;
		}

		if(!testingModel && !summary) {
			setScreenType(ScreenType.Code);
		}
	});

	useEffect(() => {
		if (connected) {
			if (testingModel) {
				service.syncCurrentQuestion();
				setScreenType(ScreenType.Slider);
			} else {
				setScreenType(ScreenType.Waiting);
			}
		}
	}, [testingModel]);

	useEffect(() => {
		if (summary) {
			setScreenType(ScreenType.Summary);
		}
	}, [summary]);

	const endSession = () => {
		setScreenType(ScreenType.Code);
		service.clearService();
	};

	const screen = () => {
		switch (screenType) {
			case ScreenType.Code:
				return <ConnectScreen service={service}/>;
			case ScreenType.Waiting:
				return <WaitingScreen/>;
			case ScreenType.Disconnect:
				return <DisconnectScreen/>;
			case ScreenType.Slider:
				return testingModel && <StudentScreenSlider minIndex={minIndex} maxIndex={maxIndex} questions={testingModel.questions} index={questionIndex}/>;
			case ScreenType.Summary:
				return <StudentSummary summary={summary}
				                       testType={testingModel?.testType}
				                       studentName={testingModel?.studentName}
				                       testName={testingModel?.testName}
				                       onClose={() => service.clearData()}/>;
			case ScreenType.Ended:
				return <SessionEndedAlert onClose={endSession}/>;
		}
	};

	return <Layer>
		{screen()}
	</Layer>;
}
