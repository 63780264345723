import React, {ChangeEvent, useCallback} from 'react';
import {BingoService} from 'shared/modules/bingo/service/service';
import {CheckboxOptions} from 'modules/forms/class-form/components/checkbox-list-box/components/checkbox-options';
import {Question} from 'shared/modules/bingo/types/models';
import {SelectDropdownVariants} from 'modules/forms/group-form/components/checkbox-list-box/types';
import {Checkbox, Dropdown, Option} from '@esgillc/ui-kit/control';
import QuestionTile from 'shared/modules/bingo/components/question-tile';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {CheckedMode} from '@esgi/deprecated/elements';

import styles from './preview-questions.module.less';

interface PreviewQuestionsProps {
	service: BingoService;
	entityName: string;
	subjectName: string;
	testName: string;
	testID: number;
	questions: Question[];
	selectedQuestions: number[];
	x3cardSize: boolean;
	selectedSortBy: SortBy;
	questionSelected: (questions: number[]) => void,
	warning: string,
	valid: boolean,
}

export enum SortBy {
	TestOrder = 0,
	Alphabetical = 1
}

export default function PreviewQuestions ({
	service,
	questions,
	selectedQuestions,
	entityName,
	subjectName,
	testName,
	testID,
	x3cardSize,
	selectedSortBy,
	questionSelected,
	warning,
}: PreviewQuestionsProps) {
	const limit = x3cardSize ? 8 : 24;

	const onOptionSelect = (value: SelectDropdownVariants) => {
		service.sortQuestions(SelectDropdownVariants.All === value);
	};

	const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		service.sortQuestions(e.target.checked);
	};

	const sortBy = (sortBy: SortBy) => {
		service.setQuestionsSortedBy(sortBy);
	};

	const isCheckBoxDisabled = useCallback((questionID: number) => {
		const limitReached = selectedQuestions.length === limit;
		const selected = selectedQuestions.some(x => x === questionID);
		return selected && limitReached;
	}, [selectedQuestions.length]);

	const selectQuestion = (questionID: number) => {
		let questions = [...selectedQuestions];
		if (questions.some(x => x === questionID)) {
			questions = questions.filter(x => x !== questionID);
		} else {
			questions.push(questionID);
		}

		questionSelected(questions);
	};

	const renderWarningPopup = (text: string) => {
		return (
			<div className={styles.warningPopup}>
				<div className={styles.content}>{text}</div>
				<div className={styles.pointer}/>
			</div>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.topHeaderSub}>
				{entityName + ' | ' + subjectName + ' | ' + testName}
			</div>
			<div className={styles.topHeaderMain}>
				Questions that will be displayed on the bingo card.
			</div>
			<div className={styles.small}>
				Each bingo card will be randomized.
			</div>
			<div className={styles.panel}>
				<div className={styles.header}>
					<div className={styles.left}>
						<span className={styles.sub}>
							Uncheck each question you wish to exclude from the game.
							<OnHoverTooltip
								message='Excluding a question will remove it from all Bingo cards and replace it with another question from the test.'>
								<i className='glyphicon glyphicon-question-sign tip'/>
							</OnHoverTooltip>
						</span>
						<div className={styles.checkboxOptions}>
							<CheckboxOptions
								onSelect={onOptionSelect}
								isCheckboxChecked={selectedQuestions.length ? CheckedMode.All : CheckedMode.None}
								isCheckboxDisabled={false}
								onCheck={onCheckboxChange}
							/>
							<span>Questions: <b>{selectedQuestions.length}</b> of <b>{questions.length}</b> (minimum: {limit})</span>
							{warning && renderWarningPopup(warning)}
						</div>
					</div>
					<div className={styles.sort}>
						<span className={styles.label}>View questions by:</span>
						<Dropdown
							value={[selectedSortBy]}
							displayLabel={(selected) => selected[0] === SortBy.TestOrder ? 'Test order' : 'Alphabetical'}
							className={styles.dropdown}
							setValue={(value) => sortBy(value[0])}
							autoWidth
						>
							<Option key={0} value={SortBy.TestOrder} className={styles.dropdown}>Test order</Option>
							<Option key={1} value={SortBy.Alphabetical} className={styles.dropdown}>Alphabetical</Option>
						</Dropdown>
					</div>
				</div>
				<div className={styles.questiosBox}>
					{questions.map((x, index) => {
						return (
							<div
								key={x.questionID}
								className={styles.questionTile}
							>
								<Checkbox
									checked={selectedQuestions.some(c => c === x.questionID)}
									onClick={() => selectQuestion(x.questionID)}
									disabled={isCheckBoxDisabled(x.questionID)}
									id={x.questionID + ''}
								/>
								<QuestionTile
									name={x.name}
									questionID={x.questionID}
									testID={testID}
									pregenerated={x.pregenerated}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
