import {Text} from '@esgi/ui/typography';
import {EmptyBox} from './index.styled';

export function NoStudentPanel() {
	return (
		<EmptyBox>
			<Text size='small' font='mono' color='neutral40'>There are no tests to display. Please select a student.</Text>
		</EmptyBox>
	);
}
