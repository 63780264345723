import styles from './styles.module.less';
import {fields} from './fields';

export function FieldsList() {
	return <div data-cy={'field-list'}>
		<div className={styles.requiredFields}>
			<h4 className={styles.fieldTitle}>Required fields</h4>
			<ul className={styles.list}>
				{fields.required.map((fl) => <li key={fl}>{fl}</li>)}
			</ul>
		</div>
		<div>
			<h4 className={styles.fieldTitle}>Optional fields</h4>
			<div className={styles.fieldsList}>
				<ul className={styles.leftList}>
					{fields.optionalLeft.map((fl) => <li key={fl}>{fl}</li>)}
				</ul>
				<ul className={styles.rightList}>
					{fields.optionalRight.map((fl) => <li key={fl}>{fl}</li>)}
				</ul>
			</div>
		</div>
	</div>;
}
