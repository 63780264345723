import * as React from 'react';
import type {SVGProps} from 'react';

export function LinkDelete(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M20 11a1 1 0 0 1 1-1h2c3.263 0 5 2.084 5 5s-1.737 5-5 5h-1a1 1 0 0 1 0-2h1c2.07 0 3-1.116 3-3s-.93-3-3-3h-2a1 1 0 0 1-1-1ZM4 17c0-3.263 2.084-5 5-5h1a1 1 0 0 1 0 2H9c-1.884 0-3 .93-3 3s1.116 3 3 3h2a1 1 0 1 1 0 2H9c-2.916 0-5-1.737-5-5ZM18.95 13.293a1 1 0 0 1 0 1.414l-1.415 1.414 1.415 1.415a1 1 0 1 1-1.415 1.414l-1.414-1.415-1.414 1.415a1 1 0 1 1-1.414-1.415l1.413-1.414-1.413-1.414a1 1 0 0 1 1.414-1.414l1.414 1.413 1.415-1.413a1 1 0 0 1 1.414 0Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
