import {GridBox} from '@esgi/ui/layout';
import {StyledSkeletonList} from './index.styled';

export function TestsListLoading({skeletonCount = 5}: {skeletonCount?: number}) {
	return (
		<GridBox gap='2'>
			{Array.from({length: skeletonCount}).map((_, index) => (
				<StyledSkeletonList key={index} />
			))}
		</GridBox>
	);
}