import {EventBusDispatcher} from '@esgillc/events';
import {HierarchyMode, HierarchySnapshot} from 'modules/hierarchy/core/models';
import {HomepageOutsideChangeEvent} from 'pages/home/components/report-button/events';
import {SubjectType} from '@esgi/core/enums';

export interface ChangesCollectorFields {
	subjectTabID: number,
	subjectTabType: SubjectType,
	classID: number,
	groupID: number,
	studentID: number,
	schoolID: number,
	teacherID: number,
}

export class ChangesCollector {
	public readonly subjectTabID: HierarchyField<number>;
	public readonly subjectTabType: HierarchyField<SubjectType>;
	public readonly classID: HierarchyField<number>;
	public readonly groupID: HierarchyField<number>;
	public readonly studentID: HierarchyField<number>;
	public readonly schoolID: HierarchyField<number>;
	public readonly teacherID: HierarchyField<number>;

	constructor(subjectTab: { id: number, type: SubjectType }, hierarchy: HierarchySnapshot) {
		this.subjectTabID = new HierarchyField<number>(subjectTab.id);
		this.subjectTabType = new HierarchyField<SubjectType>(subjectTab.type);

		switch (hierarchy.mode) {
			case HierarchyMode.Classic: {
				const {classID, groupID, studentID, schoolID, teacherID} = hierarchy.classic;
				this.classID = new HierarchyField<number>(classID);
				this.groupID = new HierarchyField<number>(groupID);
				this.studentID = new HierarchyField<number>(studentID);
				this.schoolID = new HierarchyField<number>(schoolID);
				this.teacherID = new HierarchyField<number>(teacherID);
				break;
			}
			case HierarchyMode.Specialist: {
				const {groupID, studentID} = hierarchy.specialist;
				this.classID = new HierarchyField<number>(undefined);
				this.groupID = new HierarchyField<number>(groupID);
				this.studentID = new HierarchyField<number>(studentID);
			}
			case HierarchyMode.PreAssess: {
				const {groupID, studentID} = hierarchy.preAssess;
				this.classID = new HierarchyField<number>(undefined);
				this.groupID = new HierarchyField<number>(groupID);
				this.studentID = new HierarchyField<number>(studentID);
			}
			case HierarchyMode.PreAssess: {
				const {groupID, studentID} = hierarchy.preAssess;
				this.classID = new HierarchyField<number>(undefined);
				this.groupID = new HierarchyField<number>(groupID);
				this.studentID = new HierarchyField<number>(studentID);
			}
		}
	}

	public applyChanges(changes: Partial<ChangesCollectorFields>): void {
		for (const key in changes) {
			if(key in this) {
				this[key].value = changes[key];
			}
		}
	}

	public get isTouched(): boolean {
		return [this.subjectTabID.touched, this.subjectTabType.touched, this.classID.touched, this.groupID.touched, this.studentID.touched, this.teacherID?.touched, this.schoolID?.touched].some((t) => t);
	}
}

export class HierarchyField<T> {
	constructor(readonly initialValue?: T) {
	}

	public value: T;

	public get touched(): boolean {
		return this.value !== this.initialValue && this.value !== undefined && this.value !== null;
	}
}

export function notifyHierarchyChanges(changes: ChangesCollector) {
	if (changes.isTouched) {
		const event = new HomepageOutsideChangeEvent();
		if(changes.subjectTabID.touched || changes.subjectTabType.touched) {
			event.subject = {id: changes.subjectTabID.value, type: changes.subjectTabType.value};
		}
		if (changes.studentID?.touched) {
			event.hierarchy.studentID = changes.studentID.value;
		}

		EventBusDispatcher.dispatch(HomepageOutsideChangeEvent, event);
	}
}
