import StudentProgressReportService
	from 'modules/reports/student-progress-report/services/student-progress-report-service';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {GradeValue, MarkingPeriod, Report, SubjectModel} from 'modules/reports/student-progress-report/models';
import styles from './table.module.less';

interface Props {
	service: StudentProgressReportService
}

export default function Table(props: Props) {
	const {service} = props;
	const showGradeColors = useBehaviorSubject<boolean>(service.showGradeColors);
	const report = useBehaviorSubject<Report>(service.report);
	const subjects = useBehaviorSubject<SubjectModel[]>(service.subjects);
	const showBaseline = useBehaviorSubject<boolean>(service.showBaseline);
	const gradeValues = useBehaviorSubject<GradeValue[]>(service.gradeValues);
	const reportDisplayResultsMode = useBehaviorSubject<string>(service.reportDisplayResultsMode);
	const displayZeroIfNotTested = useBehaviorSubject<boolean>(service.displayZeroIfNotTested);
	const markingPeriods = useBehaviorSubject<MarkingPeriod[]>(service.markingPeriods);
	const testResultsCorrectVerbiage = service.testResultsCorrectVerbiage;

	return <table className={styles.dataContainer}>
		{
			report.students.map((student, index) => <tbody>
			<tr>
				<td className={styles.space20}/>
			</tr>
			<tr>
				<td className={styles.studentName} colSpan={markingPeriods.length + 2}>
					{student.firstName} {student.lastName}
				</td>
			</tr>
			{
				student.subjects?.map((subject, index) => <>
					{
						reportDisplayResultsMode === '2' && gradeValues && report?.gradeScales?.length !== 1 && <>
							<tr/>
							<tr>
								<td colSpan={markingPeriods.length + 2} style={{paddingTop: index === 0 ? '10px' : '30px'}}>
									<div className={styles.gradeDescriptions}>
										{
											gradeValues.map((item) => <div className={styles.gradeContainer}>
												<div className={styles.gradeName} style={{backgroundColor: showGradeColors ? item.color : 'white'}}>
													{item.name}
												</div>
												<div className={styles.gradeDescription}>{item.description}</div>
											</div>)
										}
									</div>
								</td>
							</tr>
						</>
					}
					<tr>
						<td className={index === 0 || (reportDisplayResultsMode === '2' && gradeValues !== null) ? styles.space20 : styles.space40} />
					</tr>
					{
						reportDisplayResultsMode === '1' && <tr>
							<td/>
							<td className={styles.percentageCorrectLabel} colSpan={ markingPeriods.length - (showBaseline ? 0 : 1)}>
								<span>Percentage {testResultsCorrectVerbiage}</span>
							</td>
						</tr>
					}
					<tr className={styles.dataHeader}>
						<td className={join(styles.subjectInfo, styles.bgGray)}>
							<table>
								<tr>
									<td className={styles.subjectName}>{subjects.find(x => x.id === subject.subjectID)?.name}</td>
									<td>:</td>
								</tr>
							</table>
						</td>
						{
							markingPeriods?.map((item, mpIndex) => ((showBaseline || mpIndex !== 0)) &&
								<td className={`${styles.cell} ${mpIndex === 0 && styles.b} ${styles.markingPeriod} ${styles.bgGray}`}>
									{item.title}
								</td>)
						}
						<td>_</td>
					</tr>
					{
						subject?.tests?.map((test, testIndex) => <tr className={`${testIndex % 2 !== 0 ? styles.bgLightgray : ''} ${styles.dataBody} ${testIndex === subject.tests.length - 1 ? ' last' : ''}`}>
							<td className={styles.testInfo} title={test.testName}>
								<td className={styles.testName}>{test.testName}</td>
							</td>
							{
								test.scores?.map((score, scoreIndex) => <>
									{
										(showBaseline || scoreIndex !== 0) && <>
											{
												reportDisplayResultsMode === '0' && <>
													<td className={styles.cell}>
														{score.testDate == null && !displayZeroIfNotTested ? 'NT' : score.score}
													</td>
												</>
											}
											{
												reportDisplayResultsMode === '1' && <>
													<td className={styles.cell}>
														{score.testDate == null && !displayZeroIfNotTested ? 'NT' : score.percent}
													</td>
												</>
											}
											{
												reportDisplayResultsMode === '2' && <>
													<td className={styles.cell} style={{backgroundColor: showGradeColors ? score.testDate != null ? score.color : 'rgba(228, 228, 228, 0.5)' : null}}>
														{score.testDate == null ? !displayZeroIfNotTested ? 'NT' : 0 : score.grade}
													</td>
												</>
											}
										</>
									}
								</>)
							}
							<td className={styles.bgWhite}/>
						</tr>)
					}
				</>)
			}
			<tr>
			</tr>
			{
				report.students.length - 1 !== index && <tr>
					<td className={styles.pageBreak} colSpan={markingPeriods.length + 2}><span>page break</span></td>
				</tr>
			}
			</tbody>)
		}
	</table>;
}
