import {Dropdown, Option} from '@esgillc/ui-kit/control';
import React from 'react';
import {ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters';
import styles from './elements.module.less';

type Entity = {id: number, name: string};
interface Props {
	title: string;
	entities: Entity[];
	onSelected: (entity: Entity) => void;
	selectedValue: Entity;
}

export default function ReportDropdown(props: Props) {
	return <>{props.entities.length !== 0 && <ReportFiltersContainer className={styles.filterContainer}>
		<div className={styles.dropdownContainer}>
			<label className={styles.dropdownTitle}>{props.title}:</label>
			{
				props.entities.length > 1 && <Dropdown setValue={(v) => props.onSelected(v[0])}
				                                        optionName='name'
				                                        className={styles.dropdown}
																							  autoWidth
				                                        value={[props.selectedValue]}>
					{props.entities.map(v => <Option key={v.id} value={v}>{v.name}</Option>)}
				</Dropdown>
			}
			{
				props.entities?.length === 1 && props.selectedValue && <span>{props.selectedValue.name}</span>
			}
		</div>
	</ReportFiltersContainer>}</>;
}
