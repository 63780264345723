import {PropsWithChildren, useLayoutEffect, useRef, useState} from 'react';
import {SelectableEditableItemContent, SelectableEditableItem as SelectableEditableItemStyled} from './index.styled';
import {ButtonEditable} from '../../../../components/button-editable';

type Props = PropsWithChildren<{
	value: string;
	onItemClick: VoidFunction;
}>;

export function SelectableEditableItem({value, onItemClick, children}: Props) {
	const [contentHeight, setContentHeight] = useState(0);
	const contentRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		setContentHeight(contentRef.current?.clientHeight ?? 0);
	}, []);

	return (
		<SelectableEditableItemStyled
			value={value}
			forceSelect={false}
			withActiveBackground={false}
			updapdateVerticalPadding={contentHeight <= 24} // 24px - height of Editable Button
		>
			<SelectableEditableItemContent onClick={onItemClick} ref={contentRef}>
				{children}
			</SelectableEditableItemContent>

			<ButtonEditable onClick={onItemClick} />
		</SelectableEditableItemStyled>
	);
}
