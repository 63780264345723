import {Button} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

type Props = {
	onConfirm: VoidFunction;
	onCancel: VoidFunction;
};

export function GeneratePreviewDialog({onCancel, onConfirm}: Props) {
	const modal = Alert.useRef();

	return (
		<Alert modalManagerRef={modal}>
			<Alert.Header withBacklight={false} onCloseIconClick={onCancel}>
				<Text size='small'>Generate New Report</Text>
			</Alert.Header>
			<Alert.Body>
				<Box>
					<Text size='medium' css={{maxWidth: '468'}}>
						Are you sure you wish to generate a new report? Generating a new report will
						remove this version of the report.
					</Text>
				</Box>
			</Alert.Body>
			<Alert.Footer>
				<Button css={{marginRight: 12}} color='tertiary' onClick={onConfirm}>
					<Text size='medium' color='neutral40'>
						Generate Anyway
					</Text>
				</Button>
				<Button color='secondary' onClick={onCancel}>
					<Text size='medium' color='neutral40'>
						Cancel
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
