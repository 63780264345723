import {EmptyContentTemplate} from '../../../empty-content-template';
import {ExpandablePanel} from '../../../expandable-panel';
import {List} from '../list';
import {StudentType, ToggleStudentFunction} from '../../types';
import {StudentWithUnit} from '../../../types';

export interface Props {
	students: StudentType[];
	selectedStudents: StudentWithUnit[] | null;
	toggleStudent: ToggleStudentFunction;
	showEmptyTemplate?: boolean;
}

export function StudentList(props: Props) {
	const {students, selectedStudents, toggleStudent, showEmptyTemplate = true} = props;
	const selectedStudentIds = (selectedStudents || []).map(({studentId}) => studentId);

	return (
		<ExpandablePanel>
			{students.length &&
				<List.SelectableListMultiple
					students={students}
					selectedStudentId={selectedStudentIds}
					setSelectedStudentId={(studentId) => toggleStudent(studentId)}
				/>
			}
			{(!students.length && showEmptyTemplate) &&
				<EmptyContentTemplate
					size='full'
					templateText='No students to display'
				/>
			}
		</ExpandablePanel>
	);
}
