import Cropper from 'react-easy-crop';
import styles from './styles.module.less';
import {CropParams} from 'modules/forms/students-form/types';
import {CameraIcon, TrashIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

interface Props {
	image: string,
	croppedImage: string,
	crop: CropParams,
	isEditing: boolean;
	setEditing: (isEditing: boolean) => void;
	onRemove: () => void
	setCrop: (value: CropParams) => void
	setZoom: (value: number) => void
	onCropComplete: (croppedAreaPixels: {
		width: number,
		height: number,
		x: number
		y: number
	}) => void
}

const squareCropSize = {width: 96, height: 96};

export function ImageCropper({
	                             image,
	                             croppedImage,
	                             crop,
	                             onRemove,
	                             setCrop,
	                             setZoom,
	                             onCropComplete,
	                             isEditing,
	                             setEditing,
}: Props) {
	const {x, y, zoom} = crop ? {...crop, zoom: crop.zoom || 1} : {x: 0, y: 0, zoom: 1};

	if (!isEditing) {
		return <div className={styles.uploadPhotoContainer}>
			<OnHoverTooltip message='Delete image'>
				<TrashIcon
					className={styles.deleteButtonBeforeEdit}
					onClick={onRemove}
				/>
			</OnHoverTooltip>
			<div className={styles.imageContainer} onClick={() => setEditing(true)}>
				<img src={croppedImage} className={styles.croppedImage}/>
				<div className={styles.editImageLayer}>
					<CameraIcon/>
					<label>Edit Photo</label>
				</div>
			</div>
		</div>;
	}

	return <div data-cy='image-cropper' className={styles.uploadPhotoContainer}>
		<OnHoverTooltip message='Delete image'>
			<TrashIcon
				className={styles.deleteButton}
				onClick={onRemove}
			/>
		</OnHoverTooltip>

		<div className={styles.easyCrop}>
			<Cropper
				image={image}
				crop={{x, y}}
				zoom={zoom}
				aspect={1}
				cropShape='round'
				cropSize={squareCropSize}
				onCropChange={setCrop}
				onZoomChange={setZoom}
				onCropComplete={(croppedArea, croppedAreaPixels) => {
					onCropComplete(croppedAreaPixels);
				}}
				classes={{
					containerClassName: styles.cropContainer,
				}}
			/>
		</div>
	</div>;
}
