import {createContext, useContext} from 'react';
import {useService} from '@esgi/core/service';
import {PieChartService} from './services';

export const usePieChart = () => {
	const service = useService(PieChartService);

	return {service};
};

export const PieChartContext = createContext(null);
export const usePieChartService = () =>
	useContext<PieChartService>(PieChartContext);
