import React, {PropsWithChildren} from 'react';
import {TabContentRoot} from './styled';
import {BottomSectionButton} from './button';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

export type ExpandablePanelModeVariantProps = PropsWithChildren<
	| {
			withBottomSectionButton?: false;
	  }
	| {
			withBottomSectionButton: true;
			onBottomButtonClick: VoidFunction;
			buttonText: string;
			isBottomSectionButtonDisabled?: boolean;
			bottomSectionButtonWithIcon?: boolean;
	  }
>;

export function TabContent({children, ...restProps}: ExpandablePanelModeVariantProps) {
	return (
		<TabContentRoot>
			<OverlayScrollbarsComponent
				defer
				style={{height: 'calc(100% + 0px)'}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				{children}
			</OverlayScrollbarsComponent>

			{restProps.withBottomSectionButton && (
				<BottomSectionButton
					onClick={restProps.onBottomButtonClick}
					buttonText={restProps.buttonText}
					disabled={Boolean(restProps.isBottomSectionButtonDisabled)}
					withIcon={Boolean(restProps.bottomSectionButtonWithIcon)}
				/>
			)}
		</TabContentRoot>
	);
}
