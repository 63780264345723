import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert} from '@esgi/ui/alert';

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	paddingLeft: 0,
	paddingRight: 0,
});

export const Page = styled(FlexBox, {
	flexDirection: 'column',
	marginTop: 30,
	paddingLeft: 20,
	paddingRight: 20,
	width: 'min-content',
	overflow: 'hidden',
});

export const Student = styled(Box, {
	textAlign: 'left',
	marginBottom: '20px',

	[`& ${Text}`]: {
		fontWeight: 'bold',
		fontSize: '22px',
	},
});
