import {resolvedPromise} from '@esgi/deprecated/utils';
import {FormField, Validators, observable} from '@esgi/deprecated/knockout';
import {FieldContainer} from '../../../kit/component';
import {SchoolModel, SelectionType} from '../../models';
import {Dictionaries} from '../../server';

export class FederalSchoolSelectComponent extends FieldContainer<any> {
	@observable()
	public selectedSchool: SchoolModel;
	@observable()
	public selectionType: SelectionType;
	@observable()
	private stateID: number;

	public declare schoolUpdated: KnockoutObservable<SchoolInfo>;
	public declare inputText: KnockoutObservable<string>;
	private declare $element: JQuery;

	buildField(initValue?): FormField<any> {
		this.inputText = ko.observable<string>();
		this.schoolUpdated = ko.observable<SchoolInfo>();
		const validator = Validators.Custom((f) => {
			if (this.selectionType === SelectionType.New && this.inputText()) {
				return resolvedPromise(true);
			}
			if (this.selectionType === SelectionType.Existing && this.selectedSchool) {
				return resolvedPromise(true);
			}
			return resolvedPromise(false);
		}, (r) => '<div>Please enter a school or select</div><div>\'My school does not belong to a District.\'</div>');

		const f = this.createField(null, validator);

		f.validation.validationMessageTitleUseHtml = true;

		f.disabled = ko.computed({
			read: () => !this.stateID || !!this.selectedSchool,
			deferEvaluation: true,
		});

		this.inputText.subscribe((v) => {
			if (!v) {
				this.clearValue();
			}
			this.triggerSchoolUpdated();
		});

		f.validation.showValidation(true);
		f.validation.errorValidation(true);

		return f;
	}

	private triggerSchoolUpdated() {
		const eventModel = {
			selectionType: this.selectionType,
			name: this.inputText(),
		} as SchoolInfo;
		this.schoolUpdated(eventModel);
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		this.$element = $('input#school', rootElement);
		this.typeahead();
		return super.afterRender(rootElement);
	}

	clearValue() {
		this.inputText('');
		this.selectionType = SelectionType.Existing;
		this.selectedSchool = null;
		this.typeahead();
		this.triggerSchoolUpdated();
	}

	setState(stateID: number) {
		this.stateID = stateID;
		this.clearValue();
	}

	addSchool() {
		this.selectionType = SelectionType.New;
		this.selectedSchool = null;
		this.$element.typeahead('destroy');
		this.triggerSchoolUpdated();
	}

	typeahead() {
		this.$element.typeahead({
			minLength: 2,
			delay: 300,
			autoSelect: false,
			showEmpty: true,
			disableEsc: true,
			source: (query, process) => {
				if (!query.trim()) {
					return;
				}
				query = query.replace(/[^a-zA-Z0-9\s]/g, '');

				Dictionaries.searchSchools(query.trim(), this.stateID)
					.done((results) => {
						const array = results.items.map(r => SchoolModel.FromResponse(r));
						process(array);
					});

			},
			afterSelect: (a: SchoolModel) => {
				this.field.validation.validate();

			},
			afterShow: (typeahead) => {
				typeahead.$menu.removeClass('required');
				typeahead.$menu.bstooltip('destroy');
				const addNew = $('<li><hr><a class="dropdown-item non-option" onmousedown="return false" href="#" role="option">My school isn\'t listed</a></li>');
				addNew.prop('not-option', true);
				addNew.prop('add-new', true);
				$('a', addNew).click(() => {
					if (this.inputText() && this.inputText().trim()) {
						typeahead.hide();
						typeahead.$menu.bstooltip('destroy');
						this.addSchool();
					}
					return false;
				});
				typeahead.$menu.append(addNew);
			},
			//Custom method that has been added to tooltip. Allows handle selecting an option using the Enter key.
			notOptionSelected: (typeahead, element) => {
				if (element.prop('add-new')) {
					typeahead.hide();
					typeahead.$menu.bstooltip('destroy');
					this.addSchool();
				}
			},
			updater: (school: SchoolModel) => {
				if (school.districtID || school.schoolID) {
					this.selectionType = SelectionType.Existing;
					this.selectedSchool = school;
					this.inputText(school.name);
					return school;
				}
			},
			blur: (typeahead: any) => {
				this.field.validation.validate().then(r => {
					if (r) {
						typeahead.innerblur();
					}
				});
			},
			afterHide: (typeahead: any) => {
				typeahead.$menu.bstooltip('destroy');
			},
		});
	}

	template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group school has-feedback'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<input spellCheck={false} autoComplete='off' id='school' name='school' type='text'
					       className='form-control input-md'
					       data-bind='textInput: root.inputText, disable: disabled'/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
					<ko data-bind='if: root.selectionType === 1 || !!root.selectedSchool'>
						<ko data-bind='with:root'>
							<i data-bind='click: clearValue'
							   className='fa fa-times-circle clear-button form-control-feedback'/>
						</ko>
					</ko>
				</div>
			</div>
		</div>;
	};
}

export class SchoolInfo {
	selectionType: SelectionType;
	name: string;
}
