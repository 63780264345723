import {SchoolYearChangedEvent} from 'modules/school-year';
import {RefObject} from 'react';
import {HierarchyChangedItselfEvent, HierarchyInitializedEvent} from 'modules/hierarchy/events';
import {HierarchyDataService} from 'modules/hierarchy/services/hierarchy-data-service';
import {EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {HomepageOutsideChangeEvent} from '../../components/report-button/events';
import PieChartsService from '../pie-charts-service';
import {SubjectChangedItselfEvent} from '../subjects-service/events';
import SubjectsService from '../subjects-service/subjects-service';
import {applyChangesToHierarchy} from './utils';

export default class EventsService extends BaseService {
	private readonly eventBusManager: EventBusManager = new EventBusManager();

	constructor(
		private subjectsService: SubjectsService,
		private pieChartsService: PieChartsService,
		private hierarchyServiceRef: RefObject<HierarchyDataService>,
	) {
		super();
		this.eventBusManager.subscribe(SchoolYearChangedEvent, this.schoolYearChangedEventHandler);
		this.eventBusManager.subscribe(HomepageOutsideChangeEvent, this.homepageOutsideChangeEventHandler);
		this.eventBusManager.subscribe(HierarchyInitializedEvent, this.hierarchyInitializedEventHandler);
		this.eventBusManager.subscribe(HierarchyChangedItselfEvent, this.hierarchyChangedItselfEventHandler);
		this.eventBusManager.subscribe(SubjectChangedItselfEvent, this.subjectChangedItselfEventHandler);
	}

	public destroy() {
		super.destroy();
		this.eventBusManager.destroy();
	}

	private schoolYearChangedEventHandler = async (event: SchoolYearChangedEvent) => {
		const hierarchy = await this.hierarchyServiceRef.current.updateGlobalSchoolYear(event.schoolYearID);
		const subject = await this.subjectsService.updateGlobalSchoolYear(event.schoolYearID, hierarchy);
		await this.pieChartsService.reloadPieCharts(hierarchy, subject, true);
	};

	private homepageOutsideChangeEventHandler = async (event: HomepageOutsideChangeEvent) => {
		const hierarchyInstance = applyChangesToHierarchy(event.hierarchy, this.hierarchyServiceRef.current.hierarchyInstance.snapshot);
		const hierarchy = this.hierarchyServiceRef.current.updateOutside(hierarchyInstance);
		const subject = await this.subjectsService.updateSubjectsOutside(hierarchy, event.subject);
		await this.pieChartsService.reloadPieCharts(hierarchy, subject);
	};

	private hierarchyInitializedEventHandler = async (event: HierarchyInitializedEvent) => {
		const subject = await this.subjectsService.initialize(event.hierarchy);
		await this.pieChartsService.reloadPieCharts(event.hierarchy, subject);
	};

	private hierarchyChangedItselfEventHandler = async (event: HierarchyChangedItselfEvent) => {
		const subject = await this.subjectsService.updateSubjectsOutside(event.hierarchy);
		await this.pieChartsService.reloadPieCharts(event.hierarchy, subject);
	};

	private subjectChangedItselfEventHandler = async (event: SubjectChangedItselfEvent) => {
		const hierarchy = this.hierarchyServiceRef.current.hierarchyInstance;
		await this.pieChartsService.reloadPieCharts(hierarchy, event.subject);
	};
}
