import {StudentsControl} from './students-control';
import {FontControl} from './font-control';
import {CopiesControl} from './copies-control';
import {FileTypeControl} from './file-type-control';
import {CardSizeControl} from './card-size-control';
import {DownloadPanel} from './download-panel';
import {CopiesPanel} from './copies-panel';
import {Header} from './header';
import {StudentAvatar} from './student-avatar';
import {StudentCounter} from './student-counter';
import {StudentPanel} from './student-panel';
import {SubjectControl} from './subject-control';
import {ToggleGroup} from './toggle-group';

export const Activities = {
	StudentsControl,
	FontControl,
	CopiesControl,
	FileTypeControl,
	CardSizeControl,
	DownloadPanel,
	CopiesPanel,
	Header,
	StudentAvatar,
	StudentCounter,
	StudentPanel,
	SubjectControl,
	ToggleGroup,
};

export {ExpandablePanel} from './expandable-panel';
export {AllTestButton} from './all-test-button';
export {EmptyContentTemplate} from './empty-content-template';
export {Dialog} from './dialog';
export {useValue} from './hooks';
export * from './types';
