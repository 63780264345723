import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import React from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	SharedComponent,
	SharedProps,
} from '@esgi/deprecated/react';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Subject as SubjectEntity} from 'api/entities/subject';
import './component.less';
import SubjectDetails from '../../../modules/subject-details';
import {Subject} from './add-test-to-subject';

//TODO:Replace all usages with modules/add-test-to-subject/add-test-to-subject.tsx
/**
 * @deprecated Use modules/add-test-to-subject/add-test-to-subject.tsx instead.
 */

export class AddTestToSubject extends SharedComponent<State, Props> {
	render() {
		return <Modal className='white-header add-test-to-subject-modal new-design-modal'
		              onCatchError={() => this.props.close()}>
			<ModalHeader>
				<div className='modal-title'>
					{this.props.title()}
				</div>
			</ModalHeader>
			<ModalBody>
				<div className='add-test-to-subject'>
					<select className='form-control streched' value={this.state.subjectId || ''}
					        onChange={e => this.setState({subjectId: parseInt(e.target.value) || null})}>
						<option value=''>Select a subject tab</option>
						{this.props.mySubjects.map(s => {
							return <option value={s.id} key={s.id}>{s.name}</option>;
						})}
					</select>
					<a href='#' className='create-subject-link' onClick={() => this.openCreateNewSubject()}>Create
						new subject</a>
				</div>
			</ModalBody>
			<ModalFooter>
				<footer>
					<Transparent
						onClick={() => this.props.close()}
						title='Add Later'
					/>
					<Button
						onClick={() => this.save()}
						title='Confirm Subject Tab'
						disabled={!this.state.subjectId || this.state.isSaving}
						className={this.state.subjectId ? ' btn-primary' : ' btn-secondary'}
					/>
				</footer>
			</ModalFooter>
			{this.state.subjectDetailsOpened && <SubjectDetails
				onClosed={() => this.setState({subjectDetailsOpened: false})}
				onSaved={(subjectId) => this.subjectCreated(subjectId)}/>}
		</Modal>;
	}

	private async save() {
		if (this.state.subjectId) {
			const s = this.props.mySubjects.filter(s => s.id == this.state.subjectId)[0];
			if (s) {
				this.setState({isSaving: true});
				await SubjectEntity.addTest(s.id, s.subjectType, this.props.testId).finally(() => this.setState({isSaving: false}));
				showSnackbarNotification(`You've added ${this.props.testName} to ${s.name}`);

				this.props.saved();
			}
		}
	}

	private openCreateNewSubject() {
		this.setState({subjectDetailsOpened: true});
	}

	private subjectCreated(subjectId: number) {
		this.setState({subjectId: subjectId});
	}
}

export class State {
	subjectId: number;
	subjectDetailsOpened: boolean = false;
	isSaving: boolean = false;
}

export class Props extends SharedProps<State> {
	testId: number;
	testName: string;
	close: () => any;
	saved: () => any;
	mySubjects: Subject[];
	title: () => React.ReactNode;
}

