import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {rightPanelPaddingInline} from './right-panel-root/index.styled';

export const Divider = styled(Box, {
	width: `calc(100% + 2 * ${rightPanelPaddingInline}px)`,
	height: 1,
	backgroundColor: '$mild',
	marginLeft: -rightPanelPaddingInline,
	marginRight: -rightPanelPaddingInline,
});
