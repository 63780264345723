import * as React from 'react';
import type {SVGProps} from 'react';

export function DraggableItem(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='8' height='24' viewBox='0 0 8 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<rect x='1' y='7.75' width='6' height='1.5' rx='0.75' fill='currentColor'/>
			<rect x='1' y='11.25' width='6' height='1.5' rx='0.75' fill='currentColor'/>
			<rect x='1' y='14.75' width='6' height='1.5' rx='0.75' fill='currentColor'/>
		</svg>

	);
}
