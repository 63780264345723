import {createContext, useContext} from 'react';
import {TeacherPageContextValue} from './types';

export const TeacherPageContext = createContext<TeacherPageContextValue | null>(null);

export function useTeacherPageContext() {
	const context = useContext(TeacherPageContext);

	if (!context) {
		throw new Error('TeacherPageContext is null');
	}

	return context;
}
