import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Checkbox} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {TestSelectorService} from '../service';
import {Row} from './row';

import styles from '../styles.module.less';

type Props = {
	service: TestSelectorService,
};

export function Table(props: Props): JSX.Element {
	const tests = useBehaviorSubject(props.service.tests);
	const selectedTestIDs = useBehaviorSubject(props.service.selectedTestsIDs);

	const selectedAll = tests.every(t => selectedTestIDs.includes(t.testID));

	return <div className={styles.tableContainer}>
		<OverlayScrollbarsComponent style={{maxHeight: 295, maxWidth: 600, minHeight: 295, minWidth: 600}}>
			<table>
				<thead>
				<tr>
					<th className={styles.checkedColumnHead}>
						<div className={styles.checkedColumn}>
							<Checkbox checked={selectedAll} onClick={() => props.service.toggleAllTests()}/>
						</div>
					</th>
					<th className={styles.testNameHead}>
						<span>Tests to Include</span>
					</th>
				</tr>
				</thead>
				<tbody>
				{tests.map(t => <Row key={t.testID}
				                     test={t}
				                     clicked={() => props.service.selectTest(t)}
				                     checked={selectedTestIDs.includes(t.testID)}/>)}
				</tbody>
			</table>
		</OverlayScrollbarsComponent>
	</div>;
}
