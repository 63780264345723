import * as React from 'react';
import type {SVGProps} from 'react';

export function Text(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M21.752 15.504c.896 0 1.424.496 1.424 1.488v.224H21.8c-2.512 0-3.472 1.168-3.472 2.464v.208c0 1.328 1.072 2.304 2.672 2.304.976 0 1.728-.384 2.176-1.04a.97.97 0 0 0 .976.928.97.97 0 0 0 .976-.976v-4.208c0-1.792-1.168-3.088-3.216-3.088-1.2 0-2.336.4-3.024 1.104a.831.831 0 0 0-.24.608c0 .48.384.848.864.848.208 0 .416-.08.624-.256.448-.384.96-.608 1.616-.608Zm-.32 5.04c-.8 0-1.152-.368-1.152-.832v-.096c0-.544.48-.864 1.184-.864h1.712v.896c-.288.544-1.008.896-1.744.896ZM11.372 10.72c-.64 0-1.136.4-1.344.976l-3.184 8.896a1.256 1.256 0 0 0-.096.448c0 .576.464 1.04 1.04 1.04.488 0 .864-.336 1.008-.752l.544-1.632h4.064l.544 1.632c.136.416.512.752 1.008.752.576 0 1.04-.464 1.04-1.04 0-.144-.032-.272-.096-.448l-3.184-8.896c-.208-.576-.704-.976-1.344-.976Zm0 2.864 1.44 4.32h-2.88l1.44-4.32Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
