import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {SkeletonStylesWithBorders} from '../../../../skeleton';

const inputPaddingInline = 12;

export const InputDiv = styled(Box, {
	position: 'relative',
	borderRadius: 6,
	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});

export const Placeholder = styled(Text, {
	pointerEvents: 'none',
	position: 'absolute',
	left: inputPaddingInline,
	transition: 'color .3s, top .3s, transform .3s',

	'&[data-state=none-valued]': {
		color: '$primary72',
		top: '50%',
		transform: 'translateY(-50%)',

		'&[data-error=true]': {
			'&:not([data-disabled=true])': {
				color: '$negative',
			},
		},
	},

	'&[data-hovered=true]': {
		color: '$secondary',
	},

	'&[data-state=valued]': {
		'&[data-hovered=true]': {
			color: '$secondary80',
		},

		color: '$lowContrast',
		top: 8,
		transform: 'none',
	},

	'&[data-disabled=true]': {
		color: '$muted',
	},
});

export const InputStyled = styled('input', {
	all: 'unset',
	width: '100%',
	height: '100%',
	paddingLeft: inputPaddingInline,
	paddingRight: inputPaddingInline,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$primaryMuted',
	backgroundColor: '$vivid',
	borderRadius: 6,
	boxSizing: 'border-box',
	transition: 'background-color .3s, border-color .3s, color .3s, box-shadow .3s, padding-top .3s, padding-bottom .3s',
	outline: 'none',
	cursor: 'text',

	'&[data-hovered=true]': {
		'&:not([data-focused=true])': {
			backgroundColor: '$secondaryBackground',
			borderColor: '$secondary80',
		},
	},

	'&[data-focused=true]': {
		color: '$base',
		boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',
	},

	'&[data-state=valued]': {
		'&:not([data-focused=true])': {
			color: '$primary',
		},

		'&[data-hovered=true]': {
			'&:not([data-focused=true])': {
				color: '$secondary',
			},
		},

		'&[data-disabled=true]': {
			color: '$muted',
		},

		'&[data-error=true]': {
			'&:not([data-disabled=true])': {
				color: '$negative',
			},
		},
	},

	'&[data-disabled=true]': {
		borderColor: '$border',
		cursor: 'default',
	},

	'&[data-error=true]': {
		'&:not([data-disabled=true])': {
			borderColor: '$negativeHighlight',
		},
	},

	variants: {
		variant: {
			default: {
				paddingTop: 12,
				paddingBottom: 12,
				fontSize: 13,
				fontWeight: '600',
				lineHeight: '16px',

				'&[data-state=valued]': {
					'&:not([data-focused=true])': {
						paddingTop: 18,
						paddingBottom: 6,
					},
				},
			},

			mini: {
				paddingTop: 9,
				paddingBottom: 9,
				fontSize: 11,
				fontWeight: '800',
				lineHeight: '12px',
			},
		},
	},
});

export const ErrorDiv = styled(Box, {
	paddingLeft: inputPaddingInline,
	paddingRight: inputPaddingInline,
});
