import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {map} from 'rxjs/operators';

export class HistoryService extends BaseService {
	private history: WizardStep[] = [WizardStep.None];
	private stepNumber: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	private prevStepNumber: number;
		
	next(step: WizardStep) {
		this.prevStepNumber = this.stepNumber.value;
		
		const stepIndex = this.history.findIndex(s => s === step);
		
		if (stepIndex === -1) {
			this.history.push(step);
			this.stepNumber.next(this.stepNumber.value + 1);
		} else {
			this.stepNumber.next(stepIndex);	
		}
	}

	prev() {
		this.prevStepNumber = this.stepNumber.value;
		
		if (this.stepNumber.value > 1) {
			this.stepNumber.next(this.stepNumber.value - 1);
		} else {
			this.none();
		}
	}

	get prevStep(){
		return this.history[this.prevStepNumber];
	}
	
	none() {
		this.stepNumber.next(0);
	}
	
	get step(): Observable<WizardStep> {
		return this.completeOnDestroy(this.stepNumber).pipe(map(stepNumber =>{
			return this.history[stepNumber];
		}));
	}
}
