import {Text} from '@esgi/ui/typography';
import {SaveButton} from './styled';
import {Block} from '@esgi/ui/icons';

type Props = {
	onClick: VoidFunction;
	buttonText: string;
	disabled?: boolean;
	withIcon?: boolean;
};

export function BottomSectionButton({onClick, disabled, buttonText, withIcon}: Props) {
	return (
		<SaveButton color='secondary' onClick={onClick} disabled={disabled}>
			{withIcon && <Block />}
			<Text size='medium' bold>
				{buttonText}
			</Text>
		</SaveButton>
	);
}
