import React, {useEffect, useState} from 'react';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {Periods} from './components/periods';
import {useService} from '@esgi/core/service';
import {TrackAddEditService} from './service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {SchoolYearTypeModel, TrackModel} from '../../types';
import {AddEditForm} from './components/form';
import styles from './styles.module.less';


interface Props {
	onClose: () => void;
	selectedTrack: TrackModel;
	schoolYearTypes: SchoolYearTypeModel[];
	tracks: TrackModel[];
	districtID: number;
}


export function ProfileTrackAddEdit({
	                                    onClose,
	                                    tracks,
	                                    schoolYearTypes,
	                                    districtID,
	                                    selectedTrack,
}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const service = useService(TrackAddEditService);
	const periods = useBehaviorSubject(service.periods);
	const [isEditingTrack, setEditingTrack] = useState(true);

	useEffect(() => {
		service.init({districtID, selectedTrack, schoolYearTypes});
	}, []);

	const onSubmit = () => {
		service.form.validate().subscribe((result) => {
			if (result.valid) {
				(isEditingTrack ? service.update() : service.create()).subscribe(handleClose);
			}
		});

	};


	return <div>
		<Modal modalManagerRef={modalRef}>
			<ServiceLoader className={styles.loader} trackingService={service}/>
			<Modal.Header>
				<Title>
					Add / Edit Track
				</Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<AddEditForm
						form={service.form}
						isEditing={isEditingTrack}
						setEditing={setEditingTrack}
						selectedTrack={selectedTrack}
						schoolYearTypes={schoolYearTypes}
						tracks={tracks}
					/>
					<Periods
						periods={periods}
						fromValidator={service.dateFromValidator}
						toValidator={service.dateToValidator}
						onFromChange={service.changeFromDate}
						onToChange={service.changeToDate}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={handleClose}>
					Cancel
				</Buttons.Gray>
				<Buttons.Contained disabled={!service.isPeriodsValid()} onClick={onSubmit}>
					Save
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	</div>;
}
