import {DropdownInModel} from './types';


export const Titles: DropdownInModel[] = [
	{id: 1, value: 'Ms.'},
	{id: 2, value: 'Mr.'},
	{id: 3, value: 'Mrs.'},
	{id: 4, value: 'Miss'},
	{id: 5, value: 'Dr.'},
];

export const GradeLevels: DropdownInModel[] = [
	{ id: 100, value: "Other" },
	{ id: 1, value: "PK(3)" },
	{ id: 2, value: "PK(4)" },
	{ id: 3, value: "PK" },
	{ id: 4, value: "K" },
	{ id: 5, value: "1" },
	{ id: 6, value: "2" },
	{ id: 7, value: "3" },
	{ id: 8, value: "4" },
	{ id: 9, value: "5" },
	{ id: 10, value: "6" },
	{ id: 11, value: "7" },
	{ id: 12, value: "8" },
	{ id: 13, value: "9" },
	{ id: 14, value: "10" },
	{ id: 15, value: "11" },
	{ id: 16, value: "12" },
	{ id: 17, value: "TK" },
];