import {TestColorIndicator} from '@esgi/main/kits/common';
import {TestType} from '@esgi/main/libs/core';
import {Container} from './index.styled';
import {OneLinedText, TestTypeLabel} from '../../../kit';

type Props = {
	name: string;
	testID: number;
	type: TestType;
};

export function TestName({name, testID, type}: Props) {
	return (
		<Container>
			<TestColorIndicator testID={testID} />
			<OneLinedText size='xLarge' color='base' data-cy='test-name'>
				{name}
			</OneLinedText>
			<TestTypeLabel testType={type} />
		</Container>
	);
}
