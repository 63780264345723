import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type CheckboxGroupContextValue = {
	checked: (string | number)[]
	onChange: (v: (string | number)[]) => void
	disabled: boolean
};

export const CheckboxGroupContext = createContext<CheckboxGroupContextValue>({
	checked: [],
	onChange: noop,
	disabled: false,
});

export function useCheckboxGroupContext() {
	return useContext(CheckboxGroupContext);
}
