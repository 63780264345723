import {interval, Subscription} from 'rxjs';
import {ValidationTooltip, TooltipProps, TooltipState} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';
import styles from './tooltip.module.less';

export default class RubricTooltip extends ValidationTooltip {
	private sub: Subscription;
	private oldPosition: DOMRect;

	get className() {
		return join(super.className,
			styles.tooltip,
			!this.state.shown && styles.tooltipHidden);
	}

	get isInitiallyHidden() {
		return true;
	}

	public componentDidUpdate(prevProps: Readonly<TooltipProps>, prevState: Readonly<TooltipState>, prevContext: any) {
		super.componentDidUpdate(prevProps, prevState, prevContext);
		if (prevState.shown !== this.state.shown) {
			if (this.state.shown) {
				this.sub?.unsubscribe();
				this.sub = interval(100).subscribe(() => {
					if (this.element) {
						const currPos = this.element.getBoundingClientRect();
						const prevPos = this.oldPosition;
						if (prevPos && (currPos.x !== prevPos.x || currPos.y !== prevPos.y)) {
							this.hideTooltip();
						}
						this.oldPosition = currPos;
					}
				});
			} else {
				this.oldPosition = null;
				this.sub?.unsubscribe();
			}
		}
	}

	public componentWillUnmount() {
		super.componentWillUnmount();
		this.sub?.unsubscribe();
	}
}
