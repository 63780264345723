import {PropsWithChildren} from 'react';
import {Container, Divider, TitleContainer} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Box} from '@esgi/ui/layout';

type Props = NonNullable<PropsWithChildren> & {
	title: string;
	dataCy?: string;
};

export function SectionBlock({children, title, dataCy = 'assignment-section-block'}: Props) {
	return (
		<Container dataCy={dataCy}>
			<TitleContainer dataCy={`${dataCy}-header`}>
				<Text size='small' font='mono' color='lowContrast'>
					{title}
				</Text>

				<Divider />
			</TitleContainer>

			<Box>{children}</Box>
		</Container>
	);
}
