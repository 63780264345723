import './loader.less';
import '../mask';
import Selectors from '@esgi/testing/selectors';

export interface ILoaderOptions {
	longLoadingText?: string;
	whiteBackground?: boolean;
	delay?: number;
}

export class LoaderOptions implements ILoaderOptions {
	longLoadingText: string = null;
	whiteBackground: boolean = false;
	delay = 1000;
}

/**
 * @deprecated Use a UI-Kit/loader component in new code.
 */
export class Loader {
	private options: LoaderOptions;
	private static disabled: boolean = false;
	private static elements: any[] = [];

	constructor(element: any, options?: ILoaderOptions) {
		this.element = element;
		this.options = $.extend(true, new LoaderOptions(), options);

		Loader.elements.push(this.element);
	}

	element: any;
	text = ko.observable<string>();
	timeoutId?: number = null;

	mask() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		this.timeoutId = window.setTimeout(() => {
			if (Loader.disabled) {
				return;
			}

			let filename = 'loading' + (this.options.whiteBackground ? '_white' : '') + '.gif';
			let src = 'https://esgiwebfiles.s3.amazonaws.com/loader/' + filename;

			let text = '<div data-cy="' + Selectors.UIKit.Loader + '">' +
				'<img src="' + src + '"/>';
			if (this.options.longLoadingText) {
				const style = this.options.delay === 0 ? ' style="opacity:1;"' : '';
				text += `<div class="long_load_text"${style}>${this.options.longLoadingText}</div>`;
			}
			text += '</div>';

			$(this.element).mask(text, null, this.options.whiteBackground ? 'white-background' : null);
		}, this.options.delay);
	}

	unmask() {
		try {
			if (this.timeoutId) {
				clearTimeout(this.timeoutId);
				this.timeoutId = null;
			}
			$(this.element).unmask();
		} catch (err) {
		}
	}

	public static UnmaskAll() {
		Loader.disabled = true;

		for (let i = 0; i < Loader.elements.length; i++) {
			$(Loader.elements[i]).unmask();
		}
	}
}
