import React, {createRef, ReactNode} from 'react';
import {CloseIcon, Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import {TemplateModel} from '../../../common/types';
import TemplateCard from './components/template-card';
import OwnPathCard from './components/own-path-card';
import styles from './overview.module.less';

class Props {
	ownPathClicked: () => void;
	templateClicked: (templateID: number) => void;
	onCloseClicked: () => void;
	hidden: boolean;
	templates: TemplateModel[];
}

export default class OverviewDialog extends React.PureComponent<Props> {
	private modalManagerRef: ModalManagerRef = createRef();

	public render() {
		if (this.props.hidden) {
			return <></>;
		}

		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader className={styles.modalHeader}>
				<Title className={styles.header}>
					Observe Behavior and Measure Learning with Rubrics!
				</Title>
				<CloseIcon className={styles.closeButtonContainer}
				           onClick={() => this.modalManagerRef.current.close(this.props.onCloseClicked)}/>
			</ModalHeader>
			<ModalBody>
				<div>
					<div className={styles.body}>
						<div className={styles.templateSection}>
							<div className={styles.sectionTitle}>Select a Pre-Existing Template</div>
							<div className={styles.sectionBody}>
								{this.renderTemplates()}
							</div>
						</div>
						<div className={styles.divider}>
							<span>OR</span>
							<hr/>
						</div>
						<div className={styles.ownPathSection}>
							<div className={styles.sectionTitle}>Create Your Own Rubric</div>
							<div className={styles.sectionBody}>
								<div className={styles.ownPathContainer}>
									<OwnPathCard onClick={() => this.modalManagerRef.current.close(this.props.ownPathClicked)}/>
									<span className={styles.description}>Quickly set your own rubric criteria and scoring levels.</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter/>
		</Modal>;
	}

	private renderTemplates(): ReactNode {
		return this.props.templates
			.sort((a, b) => a.order - b.order)
			.map((t) => <TemplateCard key={t.order}
			                          text={t.name}
			                          onClicked={() => this.modalManagerRef.current.close(() => this.props.templateClicked(t.rubricID))}/>);
	}
}
