import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Button, Modal, ModalFooter} from '@esgi/deprecated/knockout';
import {SubjectType} from '@esgi/core/enums';
import {userStorage} from '@esgi/core/authentication';
import {GradeScaleReportModal} from '../modal';
import {ReportConfigurationForm} from './configuration-form';
import {GradeScaleHierarchyLevel} from 'shared/modules/reports/grade-scale-report/models';
import {HomepageStateChanges} from './models';
import {HttpClient} from '@esgi/api';

export default class GradeScaleReportLauncher {

	constructor(public level: GradeScaleHierarchyLevel, private subjectID: number, private subjectType: SubjectType, private hierarchy: HierarchySnapshot) {
	}

	private selectedClassId: number;
	private selectedGroupId: number;
	private selectedTeacherId: number;
	private selectedSchoolId: number;
	private selectedSubjectType: SubjectType;
	private selectedSubjectId: number;

	private form: ReportConfigurationForm;
	private gsr: GradeScaleReportModal;
	private currentUser = userStorage.get();

	load() {
		let form = new ReportConfigurationForm(this.level, this.subjectID, this.subjectType, this.currentUser, this.hierarchy);
		form.events.subjectChanged((_, s) => {
			this.selectedSubjectId = s.id;
			this.selectedSubjectType = s.type;
		});

		let dismissButton = new Button();
		dismissButton.title('Dismiss All !\'s');
		dismissButton.className('btn btn-link btn-dismiss');
		dismissButton.events.click(() => {
			form.dismissAllExclamationClicked();
		});
		dismissButton.visible(false);

		form.allowDismiss.subscribe(allow => {
			dismissButton.visible(allow);
		});

		let prefix = this.level.toString();
		if (this.level === GradeScaleHierarchyLevel.StudentsDistrict) {
			prefix = 'District';
		}
		if (this.level === GradeScaleHierarchyLevel.SchoolsGroup) {
			prefix = 'School Group';
		}
		if (this.level === GradeScaleHierarchyLevel.TeachersGroup) {
			prefix = 'Teacher Group';
		}
		if (this.level === GradeScaleHierarchyLevel.GroupOfSpecialists) {
			prefix = 'Specialist Group';
		}
		if (this.level === GradeScaleHierarchyLevel.StudentsSchool || this.level === GradeScaleHierarchyLevel.SchoolSpecialistStudents) {
			prefix = 'School';
		}

		const modal = new Modal(form,
			{
				showHeader: true,
				title: prefix + ' Grade Report',
				showFooter: true,
				allowClose: true,
				buttons: [
					{
						title: 'Cancel',
						className: 'btn cancel-button',
						closeModal: false,
						onClick: () => {
							modal.close();
							$(this).trigger('close');
						},
						cancel: true,
					},
					{
						title: 'Run Report',
						className: 'btn btn-primary run-report-button',
						closeModal: false,
						disabled: ko.computed(() => {
							return form.checked.length === 0 || form.hasNotConfiguredSet() || form.hasInvalidTest();
						}, this, {deferEvaluation: true}),
						onClick: () => {
							this.initReportModal().done(gsr => {
								this.gsr = gsr;
								this.gsr.events.closed(() => {
									modal.close();
									$(this).trigger('close');
								});
								this.gsr.load();
							});
						},
					},
				],
				className: 'grade-scale-configuration-modal blue-header',
				loadingAnimationDelay: 0,
			});

		modal.events.onClose(() => {
			// if we opened the report we don't want to fire events right now,
			// because subject may change within report
			// in this case events will be published upon report closing

			if (!this.gsr) {
				this.publishDelayedEvents();
			} else {
				setTimeout(() => this.publishDelayedEvents());
			}
			$(this).trigger('close');
		});

		this.form = form;

		return modal.load().done(() => {
			let footer = modal.footer as ModalFooter;
			footer.leftButtons = [dismissButton];
		});
	}

	initReportModal(): JQueryPromise<GradeScaleReportModal> {
		let selectedTestIds = this.form.checked.map(s => s.id);
		const deferred = $.Deferred<GradeScaleReportModal>();
		HttpClient.default.ESGIApi.post<GradeScaleReportModal>('reports/grade-report-result', 'remember-selected-test-ids', {
			subjectId: this.form.selectedSubject.subjectID,
			selectedTestIds: selectedTestIds,
		}).subscribe(() => {
			let gsr = new GradeScaleReportModal({
				testIds: selectedTestIds,
				subjectId: this.form.selectedSubject.subjectID,
				subjectLevel: this.form.selectedSubject.level,
				subjectType: this.form.selectedSubject.subjectType,
				userType: this.form.user.userType,
				level: this.level,
			}, this.hierarchy);

			gsr.events.classChanged((e, id) => {
				this.selectedClassId = id;
			});

			gsr.events.groupChanged((e, id) => {
				this.selectedGroupId = id;
			});

			gsr.events.schoolChanged((e, id) => {
				this.selectedSchoolId = id;
			});
			gsr.events.teacherChanged((e, id) => this.selectedTeacherId = id);

			gsr.events.subjectChanged((e, s) => {
				this.selectedSubjectId = s.SubjectID;
				this.selectedSubjectType = s.SubjectType;
			});

			gsr.events.teacherClassChanged((e, info) => {
				this.selectedTeacherId = info?.teacherID;
				this.selectedClassId = info?.classID;
			});

			gsr.events.closed(() => {
				$(this).trigger('close');
			});

			deferred.resolve(gsr);
		}, error => {
			deferred.reject();
		});

		return deferred.promise();
	}


	publishDelayedEvents() {
		$(this).trigger('homepageStateChanged', {
			subjectTabID: this.selectedSubjectId,
			subjectTabType: this.selectedSubjectType,
			classID: this.selectedClassId,
			groupID: this.selectedGroupId,
			schoolID: this.selectedSchoolId,
			teacherID: this.selectedTeacherId,
		} as HomepageStateChanges);
	}

	events = {
		homepageStateChanged: (callback: (_: Event, state: HomepageStateChanges) => any) => {
			// @ts-ignore
			$(this).on('homepageStateChanged', callback);
		},
		onClose: (callback: VoidFunction) => {
			// @ts-ignore
			$(this).on('close', callback);
		},
	};
}
