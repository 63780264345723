import * as React from 'react';
import type {SVGProps} from 'react';

export function MultipleSelectionCenter(
	props: SVGProps<SVGSVGElement>
): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M12.839 10.355a2.484 2.484 0 0 0-2.484 2.484v6.322a2.484 2.484 0 0 0 2.484 2.484h6.322a2.484 2.484 0 0 0 2.484-2.484v-2.258a.677.677 0 0 1 1.355 0v2.258A3.839 3.839 0 0 1 19.161 23H12.84A3.839 3.839 0 0 1 9 19.161V12.84A3.839 3.839 0 0 1 12.839 9h2.71a.677.677 0 0 1 0 1.355h-2.71Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16.68 17.716a.954.954 0 0 1-1.36 0l-2.039-2.06a.977.977 0 0 1 0-1.372.954.954 0 0 1 1.36 0L16 15.657l4.36-4.373a.954.954 0 0 1 1.358 0 .977.977 0 0 1 0 1.373l-5.038 5.059Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
