import {ButtonTemplate} from '@esgi/deprecated/knockout';

export function JoinToDistrictTemplate() {
	return <div data-bind='afterRender: true'>
		<div data-bind='render: joinToDistrictForm'></div>
		<div data-bind='with: confirmButton' style={{padding: '20px 40px', textAlign: 'right'}}>
			{ButtonTemplate.render()}
		</div>
	</div>
}



