import {
	TestModel,
	TestResponse,
} from './types';
import moment from 'moment/moment';
import {TestType} from '@esgi/core/enums';

export function mapToTestModel(sourceModel: TestResponse): TestModel {
	return {
		date: moment(sourceModel.createDate).format('MM/DD/YYYY'),
		id: sourceModel.testID,
		type: TestType[sourceModel.type],
		name: sourceModel.name,
		questions: sourceModel.numberOfQuestions,
		color: '#' + sourceModel.color,
	};
}