import React from 'react';
import styles from '../../../../styles.module.less';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {GroupItem} from '../../../../../../../models';

interface Props {
	groupItems: GroupItem[]
	selectedGroupItem: GroupItem
	groupType: string
	onChange: (group: GroupItem[]) => void
}
export function Group({groupType, groupItems, selectedGroupItem, onChange}: Props) {
	return groupItems && groupItems.length > 0 && <>
		<div className={styles.formGroup}>
			<label>{(groupType === 'SpecialistGroup' ? 'Group' : groupType) + ': '}</label>
			{groupItems.length > 1 && <>
				<Dropdown value={[selectedGroupItem]} setValue={onChange} optionName='name' className={styles.formControl} autoWidth={true}>
					{groupItems.map(group => <Option key={group.id} value={group}>{group.name}</Option>)}
				</Dropdown>
			</>}
			{groupItems.length === 1 && <span>{groupItems[0].name}</span>}
		</div>
	</>;
}
