import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

type Props = {
	className?: string;
	isWhiteBackground: boolean;
	disabled?: boolean;
	tooltip?: string;
	onClick: () => void;
}

export function CloseButton(props: Props) {
	return <OnHoverTooltip message={props.tooltip} placement='left'>
		<button className={join(styles.closeButton, props.className)}
		        data-cy='close-question-layout-button'
		        disabled={props.disabled}
		        onClick={props.onClick}>
			<svg width='24' height='24' viewBox='0 0 48 48'>
				<g fill='none' fillRule='evenodd'>
					<rect width='48' height='48'/>
					<path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'
					      transform='matrix(-1 0 0 1 48 0)'/>
					<path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'/>
				</g>
			</svg>
		</button>
	</OnHoverTooltip>;
}
