import React from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {
	IBoxInfo,
	IDistrictSpecialist,
	ISchoolBox,
	ISchoolSpecialist,
	ISpecialistGroupBox,
	IStudentBox,
} from '../../core/api';
import {BoxType} from '../../core/models';

export class State {

}

export class Props {
	boxes: IBoxInfo[];
	schools: ISchoolBox;
	specialGroups: ISpecialistGroupBox;
	schoolSpecialists: ISchoolSpecialist[];
	districtSpecialists: IDistrictSpecialist[];
	students: IStudentBox;
	userType: UserType;
	userID: number;
	selectedID: number;
	studentID: number;
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class School extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();

	get items(): ItemProps[] {
		let schoolIDs = [];
		if (this.props.userType === UserType.ISS && this.props.userID) {
			const user = this.props.schoolSpecialists.find(r => r.userID === this.props.userID);
			if (user) {
				schoolIDs.push(user.schoolID);
			}
		}

		if (this.props.userType === UserType.ISD) {
			let userIDs: number[] = [];
			userIDs = this.props.districtSpecialists.map(r => r.userID);

			if (this.props.userID) {
				userIDs = userIDs.filter(r => r === this.props.userID);
			}

			const groupIDs = this.props.specialGroups.items.filter(t => userIDs.indexOf(t.userID) >= 0)
				.map(r => r.groupID);

			schoolIDs = this.props.students.items
				.filter(s => s.specialistGroups.some(r => groupIDs.indexOf(r) >= 0))
				.map(r => r.schoolID);

		}

		return this.props.schools.items.filter(s => schoolIDs.indexOf(s.schoolID) >= 0)
			.map(r => {
				return {
					title: r.name,
					id: r.schoolID,
				};
			});
	}

	render(): any {
		if (UserType.V === this.props.userType) {
			return null;
		}

		const current = this.currentUser.userType;
		if (this.props.userType === UserType.ISS && current === UserType.ISS) {
			return null;
		}

		if (this.props.userType === UserType.ISS && !this.props.userID) {
			return null;
		}

		const items = this.items;
		const title = items.length === 1 ? 'School' : 'All Schools';

		const options: BoxOptions = {
			boxType: BoxType.SpecialistSchool,
			canDrag: false,
			title: title,
			canCreateTooltip: null,
			canEdit: false,
			canAdd: false,
		};

		let preselectedID = null;
		if (!this.props.selectedID) {
			const student = this.props.students.items.find(t => t.studentID === this.props.studentID);
			if (student) {
				preselectedID = student.schoolID;
			}
		}

		return <Box
			open={this.props.boxes.filter(t => t.boxType === BoxType.SpecialistSchool && t.open).length > 0}
			selectedID={this.props.selectedID}
			preselectedID={preselectedID}
			scheduledIDs={[]}
			onDragStart={null}
			onAddClicked={() => {
			}}
			onEditClicked={() => {
			}}
			options={options}
			empty={{message: 'The list is empty'}}
			onDragEnd={null}
			items={items}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
			itemSelected={this.props.onSelected}
		/>;
	}
}
