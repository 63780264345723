import {
  SchoolsGroupChangedEvent,
  SchoolsGroupCreatedEvent,
  SchoolsGroupRemovedEvent,
} from 'modules/forms/admin-group-forms/events';
import {GroupModelForAdmin} from '../core/api';
import {HierarchyState} from './hierarchy-data-service';

export function applySchoolsGroupChanged(args: SchoolsGroupChangedEvent, hierarchy: HierarchyState): HierarchyState {
  const {schools, schoolsGroups} = hierarchy;

  const schoolsInGroup = schools.items.filter(x => x.groups.some(c => c === args.groupId));
  const forRemove = schoolsInGroup.filter(x => args.schools.every(c => c !== x.schoolID));
  const forAddIds = args.schools.filter(x => schoolsInGroup.every(c => c.schoolID !== x));

  schools.items = schools.items.map(x => {
    if (forRemove.some(c => c.schoolID === x.schoolID)) {
      const newSchool = {...x};
      newSchool.groups = x.groups.filter(x => x !== args.groupId);
      return newSchool;
    }
    if (forAddIds.some(c => c === x.schoolID)) {
      const newSchool = {...x};
      newSchool.groups.push(args.groupId);
      return newSchool;
    }
    return x;
  });

  const item = schoolsGroups.items.filter(s => s.groupID === args.groupId)[0];
  const newItem: GroupModelForAdmin = {...item, name: args.name};

  schoolsGroups.items = schoolsGroups.items.map(s => s.groupID === newItem.groupID ? newItem : s);

  return hierarchy;
}

export function applySchoolsGroupCreated(args: SchoolsGroupCreatedEvent, hierarchy: HierarchyState): HierarchyState {
  const {schools, schoolsGroups} = hierarchy;
  schools.items = schools.items.map(x => {
    if (args.schools.some(c => c === x.schoolID)) {
      const newState = {...x};
      newState.groups.push(args.groupId);
      return newState;
    }
    return x;
  });

  const group: GroupModelForAdmin = {
    groupID: args.groupId,
    userID: args.userID,
    name: args.name,
    orderNum: 0,
  };

  schoolsGroups.items = schoolsGroups.items.concat(group);

  return hierarchy;
}

export function applySchoolsGroupRemoved(args: SchoolsGroupRemovedEvent, hierarchy: HierarchyState): HierarchyState {
	const {schoolsGroups, classic} = hierarchy;
  schoolsGroups.items = schoolsGroups.items.filter(s => s.groupID !== args.id);

	if (classic.schoolsGroupID === args.id) {
    classic.schoolsGroupID = 0;
	}
	return hierarchy;
}
