import Selectors from '@esgi/testing/selectors';
import React, {ReactNode} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {CloseIcon} from '@esgi/deprecated/ui-kit/modal';

export class ModalHeaderProps {
	title?: {
		text: string,
		click?: () => any
	} | string;
	help?: {
		url?: string;
		click?: () => any;
		tooltip?: string;
		bootstrap?: boolean;
	};
	close?: {
		white?: boolean;
		tooltip?: string;
		click?: () => any;
	}
	children?: ReactNode;
	className?: string;
}

/**
 * @deprecated Use UI-Kit/Modal implementation.
 */
export class ModalHeader extends React.Component<ModalHeaderProps> {
	ref: HTMLDivElement = null;

	helpClicked = () => {
		if (this.props.help) {
			if (this.props.help.url) {
				window.open(this.props.help.url, '_blank');
			} else if (this.props.help.click) {
				this.props.help.click();
			}
		}
	}

	get title() {
		if (this.props.title) {
			if (typeof this.props.title === 'string') {
				return this.props.title;
			} else {
				return this.props.title.text;
			}
		}
		return null;
	}

	get headerIsLink() {
		if (typeof this.props.title == 'object') {
			return !!this.props.title.click;
		}

		return false;
	}

	private get className() {
		let className = 'modal-header';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}

	componentDidMount(): void {
		if (this.props.help && this.props.help.tooltip && this.props.help.bootstrap) {
			$('.help', this.ref).bstooltip({
				placement: 'bottom',
				title: this.props.help.tooltip,
				trigger: 'hover',
				container: $(this.ref).parents('.modal-header'),
			});
		}
	}

	render() {
		return <div className={this.className} ref={(r) => this.ref = r} data-cy={Selectors.UIKit.Modal.Header}>
			<div className='standard-header'>
				<div className='title-container'>
					{this.title &&
					<h3 data-cy={Selectors.UIKit.Modal.Title}>
						{this.headerIsLink && <a href='#' onClick={() => this.headerClicked()}>{this.title}</a>}
						{!this.headerIsLink && this.title}
					</h3>
					}
					{this.props.children}
					{this.props.help &&
					<OnHoverTooltip message='Help'>
						<a href='#' className='help' onClick={this.helpClicked}
						   title={this.props.help.tooltip}>
							<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM8.25 12.75H6.75V11.25H8.25V12.75ZM9.8025 6.9375L9.1275 7.6275C8.5875 8.175 8.25 8.625 8.25 9.75H6.75V9.375C6.75 8.55 7.0875 7.8 7.6275 7.2525L8.5575 6.3075C8.835 6.0375 9 5.6625 9 5.25C9 4.425 8.325 3.75 7.5 3.75C6.675 3.75 6 4.425 6 5.25H4.5C4.5 3.5925 5.8425 2.25 7.5 2.25C9.1575 2.25 10.5 3.5925 10.5 5.25C10.5 5.91 10.23 6.51 9.8025 6.9375Z'
									fill='#828282'/>
							</svg>
						</a>
					</OnHoverTooltip>}
				</div>
				{this.props.close && <CloseIcon tooltip={this.props.close.tooltip} color={this.props.close.white ? '#FFFFFF' : undefined} onClick={this.props.close.click}/>}
			</div>
		</div>;
	}

	private headerClicked() {
		if (typeof this.props.title == 'object') {
			this.props.title.click();
		}
	}
}
