import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {RightSidebar} from '../../layout/index.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

export const Divider = styled(Box, {
	height: '20px',
	borderRadius: '1px',
	borderRight: '1.5px solid $muted',
	margin: '0 16px',
});

export const RightSidebarStyled = styled(RightSidebar, {
	'@media (max-width: 1024px)': {
		maxWidth: '300px',
		height: '100%',
	},
});

export const OverlayScrollbarsComponentStyled = styled(OverlayScrollbarsComponent, {
	height: '80vh',
});
