/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {join} from '@esgillc/ui-kit/utils';
import {CopyTestButton, HideButton, StarButton} from 'modules/assets/tests/kits/test-details';
import React, {ReactNode} from 'react';
import {SharedComponent, SharedProps, ValidationResult, Validator} from '@esgi/deprecated/react';
import {TestType, ValidationStatus} from '@esgi/core/enums';
import {ValidationTooltip, OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TextInput} from '@esgi/deprecated/elements';
import {ResetRequested} from 'shared/modules/test-details/events';
import './component.less';

import styles from './test-name.module.less';

export class State {
	name: string;
	dirty: boolean = false;
	totalPossible: number;
	eyeHovered: boolean = false;
	validation = new ValidationResult();
}

export class Props extends SharedProps<State> {
	name: string;
	starred: boolean;
	hide: boolean;
	canEdit: boolean;
	canCopy: boolean;
	starClickHandler: () => void;
	hideClickHandler: () => void;
	copyTestClickHandler: () => void;
	testType: TestType;
	isNew: boolean;
	draft: boolean;
	owner: string;
	createDate: string;
	testId: number;
	hasSelfAssessVersion: boolean;
}

export class TestName extends SharedComponent<State, Props> {
	private readonly maxNameLength: number = 65;
	private textInput: TextInput;
	private validator: Validator;

	constructor(props?: Props) {
		super(props);
		this.state = new State();
		this.setState({name: props.name || ''});
	}

	componentDidMount() {
		this.subscribe(ResetRequested, async (arg) => {
			const oldName = arg.test.name ?? '';
			await this.nameChanged(oldName);
		});
		if (this.props.canEdit) {
			this.setTestNameWidth(this.props.name);
		}
		const {name} = this.props;
		this.setState({name: name || ''});

		this.validator = Validator.validate(() => {
			if (!this.state.name) {
				return 'Enter test name.';
			} else if (this.state.name.length > this.maxNameLength) {
				return `Test name length cannot be greater than ${this.maxNameLength.toString()} symbols.`;
			}
			return null;
		});
	}


	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if (this.props.testId !== prevProps.testId) {
			this.setTestNameWidth(this.props.name);
		}
	}

	private async nameChanged(newName: string) {
		this.setState(
			{
				name: newName,
				dirty: newName !== this.props.name,
			}, async () => {
				await this.validate();
				this.onInput(this.state.name);
			},
		);
	}

	public async validate(): Promise<boolean> {
		const result = await this.validator.validate();
		this.setState({validation: result});

		return result.valid === ValidationStatus.Valid;
	}

	private renderIcons() {
		let message = 'Save a copy of the test to customize your questions.';
		if(this.props.hasSelfAssessVersion) {
			message = 'Save a copy of the teacher version to customize your questions.';
		}

		return <>
			{!this.props.isNew &&
			<div className={styles.actionsContent}>
				<StarButton isStarred={this.props.starred} onClick={this.props.starClickHandler}/>
				<HideButton isHidden={this.props.hide} onClick={this.props.hideClickHandler}/>
				{(!this.props.isNew && this.props.canCopy && !this.props.draft) && <CopyTestButton onClick={this.props.copyTestClickHandler}
				                                       tooltipMessage={message}/>}
			</div>
			}
		</>;
	}

	private onInput = (value: string) => {
		this.setTestNameWidth(value);
	};

	private setTestNameWidth(name: string) {
		let buffer = document.getElementsByClassName('buffer')[0];
		buffer.innerHTML = name;
		const testNameWidth = buffer.clientWidth + 45;
		$('.test-name-input').width(testNameWidth);
	}


	private renderEditPanel() {
		return <>
			<div className='buffer'></div>
			<div
				className={'form-group test-name-group' + (this.state.validation.valid === ValidationStatus.Invalid ? ' has-error' : '')}>
				<TextInput
					ref={(r) => this.textInput = r}
					className='test-name-input'
					value={this.state.name}
					onEdit={async (value) => await this.nameChanged(value)}
					placeholder='Enter Test Name'
					maxLength={this.maxNameLength}
					initialFocus={this.props.isNew}
					autocomplete={false}
				/>
				{this.state.validation.valid === ValidationStatus.Invalid &&
				<ValidationTooltip element={this.textInput.ref} placement='right'
								   container={this.textInput.containerRef} notHide={true}>
					{this.state.validation.message}
				</ValidationTooltip>}
			</div>
		</>;
	}


	get testNameTitle(): JSX.Element {
		const title = <span>{this.props.canEdit && this.asterisk} Test Name:</span>;
		return title;
	}

	get asterisk() {
		const className = 'asterisk ' + (this.props.isNew && 'red' || '');
		return <span className={className}>*</span>;
	}

	private renderSelfAssessIcon(): ReactNode {
		if (this.props.hasSelfAssessVersion) {
			return <div className={styles.selfAssessIconContainer}>
				<OnHoverTooltip message='Student self-assess'>
					<svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle cx='14.0386' cy='14.0385' r='12' fill='white'/>
						<path
							d='M21.5259 21.5385H6.55124C6.78062 17.0267 10.0947 13.5385 14.0386 13.5385C17.9825 13.5385 21.2965 17.0267 21.5259 21.5385Z'
							fill='#BA5ACB' stroke='white'/>
						<circle cx='14.0386' cy='10.0385' r='5' fill='#FFA53D' stroke='white' strokeWidth='2'/>
						<circle cx='14' cy='14' r='13' stroke='#059BE0' strokeWidth='2'/>
					</svg>
				</OnHoverTooltip>
			</div>;
		}
	}

	render() {
		return <>
			<div className='test-data-item test-name-item'>
				<span className='control-label'>
					{this.testNameTitle}
				</span>
				<div className={join('item-value', !this.props.canEdit && styles.readonlyField)}>
					<div className={`test-name ${!this.props.canEdit && 'non-edit'}`}>
						<div className='test-name-value-and-icons'>
							{!this.props.canEdit
							? <div className='title'><span>{this.state.name}</span></div>
							: this.renderEditPanel()}
							<div className='icons'> 
								{this.props.draft &&
								<svg width='45' height='24' viewBox='0 0 45 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<rect x='0.5' y='0.5' width='44' height='23' rx='3.5' stroke='#00BFA5'/>
									<path
										d='M6.69824 16V8.17969H9.10449C9.79199 8.17969 10.4061 8.33545 10.9468 8.64697C11.491 8.95492 11.9154 9.39535 12.2197 9.96826C12.5241 10.5376 12.6763 11.1857 12.6763 11.9126V12.2725C12.6763 12.9993 12.5259 13.6457 12.2251 14.2114C11.9279 14.7772 11.5072 15.2158 10.9629 15.5273C10.4186 15.8389 9.80452 15.9964 9.12061 16H6.69824ZM8.30957 9.48486V14.7056H9.08838C9.71859 14.7056 10.2002 14.4997 10.5332 14.0879C10.8662 13.6761 11.0363 13.0871 11.0435 12.3208V11.9072C11.0435 11.1123 10.8787 10.5107 10.5493 10.1025C10.2199 9.69076 9.73828 9.48486 9.10449 9.48486H8.30957ZM16.7476 13.1372H15.4639V16H13.8525V8.17969H16.7583C17.6821 8.17969 18.3947 8.38558 18.896 8.79736C19.3973 9.20915 19.6479 9.79102 19.6479 10.543C19.6479 11.0765 19.5316 11.5223 19.2988 11.8804C19.0697 12.2349 18.7205 12.5177 18.2515 12.729L19.9434 15.9248V16H18.2139L16.7476 13.1372ZM15.4639 11.832H16.7637C17.1683 11.832 17.4816 11.73 17.7036 11.5259C17.9256 11.3182 18.0366 11.0335 18.0366 10.6719C18.0366 10.3031 17.931 10.013 17.7197 9.80176C17.512 9.59049 17.1916 9.48486 16.7583 9.48486H15.4639V11.832ZM25.293 14.3887H22.4678L21.9307 16H20.2173L23.1284 8.17969H24.6216L27.5488 16H25.8354L25.293 14.3887ZM22.9028 13.0835H24.8579L23.875 10.1562L22.9028 13.0835ZM32.9844 12.8042H29.8906V16H28.2793V8.17969H33.3711V9.48486H29.8906V11.5044H32.9844V12.8042ZM40.2998 9.48486H37.9043V16H36.293V9.48486H33.9297V8.17969H40.2998V9.48486Z'
										fill='#00BFA5'/>
								</svg>}
								{this.renderIcons()}
								{this.renderSelfAssessIcon()}
							</div>
						</div>
						<div className='test-info'>
							<div
								className='test-owner-info'>{this.props.owner} {this.props.createDate && (' on ' + this.props.createDate) || ''}</div>
							{this.props.canEdit && <div
								className='limit-counter'>{this.maxNameLength - (this.state.name && this.state.name.length || 0)} characters
								left ({this.maxNameLength} max)</div> || null}
						</div>
					</div>
				</div>
			</div>
		</>;
	}
}
