import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gap: 6,
	alignItems: 'center',
	gridAutoFlow: 'column',
});

export const IndicatorBox = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 2,
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,

	variants: {
		variant: {
			success: {
				backgroundColor: '$positive',
			},

			error: {
				backgroundColor: '$negativeVivid',
			},

			neutral: {
				backgroundColor: '$mediumContrast',
			},

			lightZeroValue: {
				backgroundColor: '$lowContrast',
			},
		},
	},
});
