import {StudentSectionMode} from '@esgi/main/features/teacher/home';

export enum StudentTabId {
	Classes = 'classes',
	Groups = 'groups',
	Students = 'students',
}

export type updateStudentSectionModePermissions = (args: {
	sectionMode: StudentSectionMode;
	tabId: StudentTabId;
	newPermissions: boolean;
}) => void;

export type getStudentSectionModePermissionValue = (args: {
	sectionMode: StudentSectionMode;
	tabId: StudentTabId;
}) => boolean;