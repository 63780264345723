import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';
import {FormElement} from '@esgi/ui/form';

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	flexDirection: 'column',
	justifyItems: 'center',
});

export const FormElementWrapper = styled(FormElement, {
	marginBottom: '0',

	'& > div': {
		width: '100%',
	},
});
