import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
}

export function SelectIcon({className}: Props) {
	return <svg data-cy='select-icon' className={join(className)}
				xmlns='http://www.w3.org/2000/svg'
				width='20' height='20'
				viewBox='0 0 25 25' fill='none'>
		<path
			d='M12.3595 0.654785C5.90199 0.654785 0.661133 5.89564 0.661133 12.3531C0.661133
			18.8106 5.90199 24.0515 12.3595 24.0515C18.817 24.0515 24.0578 18.8106 24.0578
			12.3531C24.0578 5.89564 18.817 0.654785 12.3595 0.654785ZM10.0198 18.2023L4.17063
			12.3531L5.8201 10.7037L10.0198 14.8917L18.8988 6.01262L20.5483 7.67379L10.0198 18.2023Z'
			fill='#00BFA5'/>
	</svg>;
}
