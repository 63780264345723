import {RightPanel} from '@esgi/main/kits/common';
import {styled} from '@esgi/ui/theme';

export const RightPanelRoot = styled(RightPanel.Root, {
	maxWidth: 'unset',
	height: '100%',
	overflow: 'hidden',
	width: 400,
	paddingLeft: 20,
	paddingRight: 20,
});

export const RightPanelBody = styled(RightPanel.Body, {
	display: 'grid',
	gap: 38,
	overflow: 'hidden',
});
