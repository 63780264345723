import React, {ReactNode} from 'react';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {Description} from '../../../models';
import {DetailsViewer} from './components/details-viewer/details-viewer';
import styles from './description.module.less';

class State {
	detailsEditDialogOpened: boolean = false;
}

interface Props {
	levelName: string;
	criteriaName: string;
	description: Description;
	showHeader: boolean;
}

export default class DescriptionView extends React.PureComponent<Props, State> {
	public state = new State();

	render() {
		return <div className={styles.box}>
			<div className={styles.innerBox}>
				<div className={styles.header}>
					{this.props.showHeader && 'Description'}
				</div>
				<div className={styles.descriptionContainer}>
					<div className={styles.description}>
						<span>
							{this.props.description.description}
						</span>
					</div>
					{this.props.description.details?.trim() && <div className={styles.details}>
						<AnchorLikeButton onClick={() => this.setState({detailsEditDialogOpened: true})}>
							Details
						</AnchorLikeButton>
					</div>}
				</div>
			</div>
			{this.renderDetailsDialog()}
		</div>;
	}

	private renderDetailsDialog(): ReactNode {
		if (this.state.detailsEditDialogOpened) {
			return <DetailsViewer criteriaName={this.props.criteriaName}
			                      levelName={this.props.levelName}
			                      details={this.props.description.details}
			                      onClose={() => this.setState({detailsEditDialogOpened: false})}/>;
		}
	}
}
