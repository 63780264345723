import {
	ComponentPropsWithoutRef,
	PropsWithChildren,
	useCallback,
	forwardRef,
	useEffect,
	useState,
	useMemo,
	useRef,
} from 'react';
import {Point, Area} from 'react-easy-crop';
import {Size} from 'react-easy-crop/types';
import {BaseComponentProps} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import {ImageCropperContext, ImageCropperContextValue} from '../../context';
import {convertBase64ToFile, convertFileToBase64, cropImage} from './utils';
import {CroppedImage} from '../../types';

export type ImageCropperProps = PropsWithChildren & {
	/** Object holding information about the crop operation. */
	imageData: CroppedImage | null

	/** Minimum zoom level for the image cropper.
	 * @default 1*/
	minZoom?: number;

	/** Maximum zoom level for the image cropper.
	 * @default 3 */
	maxZoom?: number;

	/** Increment step for the zoom slider.
	 * @default 0.1 */
	step?: number;

	/** Defines the size of the cropping area. */
	croppedAreaSize?: Size

	/** Callback function that is called when the crop operation is completed. */
	onCropChange: (croppedImg: CroppedImage) => void;

} & ComponentPropsWithoutRef<'div'> & BaseComponentProps;

export const ImageCropperRoot = forwardRef<HTMLDivElement, ImageCropperProps>(({
	minZoom = 1,
	maxZoom = 3,
	step = 0.1,
	onCropChange,
	croppedAreaSize = {width: 160, height: 160},
	dataCy = 'ui-kit-image-cropper-root',
	children,
	imageData,
	...props
}, ref) => {

	const actualCrop = useMemo(() => imageData?.crop ? {x: imageData.crop.x, y: imageData.crop.y} : {
		x: 0,
		y: 0,
	}, [imageData]);
	const [crop, setCrop] = useState<Point>(actualCrop);
	const [zoom, setZoom] = useState<number>(minZoom);
	const [imageBase64, setImageBase64] = useState(imageData?.image || null);
	const [imageFile, setImageFile] = useState<File>();
	const [errorMessage, setErrorMessage] = useState<string>('');

	const cropTimeout = useRef<ReturnType<typeof setTimeout>>();
	const cropRef = useRef<Point>(actualCrop);

	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
		x: 0,
		y: 0,
		width: croppedAreaSize.width,
		height: croppedAreaSize.height,
	});

	const onZoomChange = useCallback((zoomValue?: number) => {
		setZoom(zoomValue || 1);
	}, [setZoom]);

	const handleCropImage = useCallback((updatedImageBase64?: string | null, updatedCroppedAreaPixels?: Area) => {
		clearTimeout(cropTimeout.current);
		cropTimeout.current = setTimeout(() => {
			if (!(updatedImageBase64) || !updatedCroppedAreaPixels) {
				return null;
			}
			cropImage(updatedImageBase64, updatedCroppedAreaPixels, imageFile?.type || 'image/jpeg').then((croppedImage) => {
				if (!croppedImage) {
					return null;
				}
				onCropChange({
					croppedImage,
					croppedImageFile: convertBase64ToFile(croppedImage, imageFile?.name || 'image', imageFile?.type || 'image/jpeg'),
					crop: {...cropRef.current, zoom},
					image: imageData?.image || imageBase64,
				});
			});
		}, 500);
	}, [imageData?.image, imageFile?.name, imageFile?.type, onCropChange, zoom]);

	const onChangeImageFile = useCallback((newFile?: File) => {
		if (newFile) {
			setImageFile(newFile);
			convertFileToBase64(newFile).subscribe(base64 => {
				setImageBase64(base64);
				handleCropImage(base64, croppedAreaPixels);
			});
		}
	}, [setImageBase64, handleCropImage, zoom, croppedAreaPixels]);


	const cropperChangeHandler = useCallback((point: Point) => {
		setCrop(point);
		cropRef.current = point;
	}, [setCrop, cropRef]);

	const cropCompleteHandler = useCallback((area: Area) => {
		setCroppedAreaPixels(area);
		if (imageBase64) {
			handleCropImage(imageBase64, area);
		}
	}, [imageBase64, zoom, handleCropImage]);

	useEffect(() => {
		if (imageData?.image) {
			setCrop(actualCrop);
			onZoomChange(imageData.crop?.zoom);
			handleCropImage(imageData.image, croppedAreaPixels);
			setImageBase64(imageData.image);
		}
	}, [imageData?.image]);

	useEffect(() => {
		setCrop(actualCrop);
	}, [imageData?.crop?.x, imageData?.crop?.y]);

	useEffect(() => {
		onZoomChange(imageData?.crop?.zoom);
	}, [imageData?.crop?.zoom]);

	const contextValue = useMemo<ImageCropperContextValue>(() => {
		return {
			crop,
			zoom,
			step,
			minZoom,
			maxZoom,
			imageBase64,
			croppedAreaSize,
			onCropChange: cropperChangeHandler,
			onChangeImageBase64: setImageBase64,
			onChangeImageFile,
			onChangeCroppedAreaPixels: cropCompleteHandler,
			onZoomChange,
			errorMessage,
			onChangeErrorMessage: setErrorMessage,
		};
	}, [
		crop,
		zoom,
		step,
		minZoom,
		maxZoom,
		imageBase64,
		croppedAreaSize,
		setImageBase64,
		onChangeImageFile,
		cropCompleteHandler,
		onZoomChange,
		cropperChangeHandler,
		errorMessage,
		setErrorMessage,
	]);

	return <ImageCropperContext.Provider value={contextValue}>
		<Box dataCy={dataCy} ref={ref} {...props}>
			{children}
		</Box>
	</ImageCropperContext.Provider>;
});
