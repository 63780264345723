import {Fragment} from 'react';
import styles from './styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../../../services/report-service';
import {Answer, Test} from '../../../../../../../models/models';

interface Props {
	test: Test;
	service: ReportService;
}

export function DetailReportAnswers({test, service}: Props) {
	const teacher = useBehaviorSubject(service.settingsService.teacher);
	const includeQuestionNotes = useBehaviorSubject(service.settingsService.includeQuestionNotes);

	function getCorrectAnswerNoteIndex(index: number, questions: Answer[]) {
		return questions.slice(0, index + 1).filter(i => !!i.note).length;
	}

	function getIncorrectAnswerNoteIndex(index: number, questions: Answer[], correctNotesCount: number) {
		return questions.slice(0, index + 1).filter(i => !!i.note).length + correctNotesCount;
	}

	return <div className={styles.answersContainer}>
		<div className={join(styles.correctAnswersContainer, test.type !== 'YN' && styles.score)}>
			{test.type === 'YN' &&
				<>
					<div className={styles.answersContainerHeader}>{teacher?.testResultsCorrectVerbiage}</div>
					<div className={styles.answersContainerBody}>
						{test.answers.filter(a => a.correct).map((answer, index) =>
							<Fragment key={answer.text}>
								<span>{answer.text}</span>
								{answer.note && includeQuestionNotes &&
									<sup>
										<b>{getCorrectAnswerNoteIndex(index, test.answers.filter(a => a.correct))}</b>
									</sup>
								}
								{(index !== test.answers.filter(a => a.correct).length - 1) && <>, </>}
							</Fragment>,
						)}
					</div>
				</>
			}
			{test.type !== 'YN' &&
				<>
					<div className={styles.answersContainerHeader}>Score</div>
					<div className={styles.answersContainerBody}>{test.totalCorrect}</div>
				</>
			}
		</div>
		<div className={join(styles.incorrectAnswersContainer, test.type !== 'YN' && styles.score)}>
			{test.type === 'YN' &&
				<>
					<div className={styles.answersContainerHeader}>{teacher.testResultsIncorrectVerbiage}</div>
					<div className={styles.answersContainerBody}>
						{test.answers.filter(a => !a.correct).map((answer, index) =>
							<Fragment key={index}>
								<span>{answer.text}</span>
								{answer.note && includeQuestionNotes &&
									<sup>
										<b>{getIncorrectAnswerNoteIndex(index, test.answers.filter(a => !a.correct), test.answers.filter(a => a.correct && !!a.note).length)}</b>
									</sup>
								}
								{index !== test.answers.filter(a => !a.correct).length - 1 && <>, </>}
							</Fragment>,
						)}
					</div>
				</>
			}
			{test.type !== 'YN' &&
				<>
					<div className={styles.answersContainerHeader}>Total Possible</div>
					<div className={styles.answersContainerBody}>{test.questionCount}</div>
				</>
			}
		</div>
	</div>;
}
