import {Step1} from './steps/step1';
import {Step2} from './steps/step2';
import {Step3} from './steps/step3';

import './styles.less';

export class AutoQuoter {
	constructor() {
		this.step1 = new Step1();
		this.step1.events.next(() => {
			this.currentStep(this.step2);
		});

		this.step2 = new Step2();
		this.step2.events.next(() => {
			this.currentStep(this.step3);
		});
		this.step2.events.back(() => {
			this.currentStep(this.step1);
		});

		this.step3 = new Step3();
		this.step3.events.back(() => {
			this.currentStep(this.step2);
		});

		this.currentStep(this.step1);
	}

	step1: Step1;
	step2: Step2;
	step3: Step3;

	currentStep = ko.observable<any>();
}
