import {GroupChangedEvent, GroupCreatedEvent, GroupRemovedEvent} from 'modules/forms/group-form/events';
import {IGroupModel} from '../core/api';
import {HierarchyState} from './hierarchy-data-service';


export function applyGroupChanged(args: GroupChangedEvent, hierarchy: HierarchyState): HierarchyState {
	const {students, groups} = hierarchy;

	const studentsInGroup = students.items.filter(x => x.groups.some(c => c === args.groupId));
	const forRemove = studentsInGroup.filter(x => args.students.every(c => c !== x.studentID));
	const forAddIds = args.students.filter(x => studentsInGroup.every(c => c.studentID !== x));

	students.items = students.items.map(x => {
		if (forRemove.some(c => c.studentID === x.studentID)) {
			const newStudent = {...x};
			newStudent.groups = x.groups.filter(x => x !== args.groupId);
			return newStudent;
		}
		if (forAddIds.some(c => c === x.studentID)) {
			const newStudent = {...x};
			newStudent.groups.push(args.groupId);
			return newStudent;
		}
		return x;
	});

	const item = groups.items.filter(s => s.groupID === args.groupId)[0];
	const newItem: IGroupModel = {...item, name: args.name};

	groups.items = groups.items.map(s => s.groupID === newItem.groupID ? newItem : s);

	return hierarchy;
}

export function applyGroupCreated(args: GroupCreatedEvent, hierarchy: HierarchyState): HierarchyState {
	const {students, classic, groups} = hierarchy;
	students.items = students.items.map(x => {
		if (args.students.some(c => c === x.studentID)) {
			const newStudent = {...x};
			newStudent.groups.push(args.groupId);
			return newStudent;
		}
		return x;
	});

	const group: IGroupModel = {
		groupID: args.groupId,
		classID: classic.classID,
		name: args.name,
		orderNum: 0,
	};

	groups.items = groups.items.concat(group);

	return hierarchy;
}

export function applyGroupRemoved(args: GroupRemovedEvent, hierarchy: HierarchyState): HierarchyState {
	const {groups, classic} = hierarchy;
	groups.items = groups.items.filter(s => s.groupID !== args.id);

	if (classic.groupID === args.id) {
		if (groups.items.length) {
			const first = groups.items.find(t => t.classID === classic.classID);
			if (first) {
				classic.groupID = first.groupID;
			} else {
				classic.groupID = 0;
			}
		} else {
			classic.groupID = 0;
		}
	}
	return hierarchy;
}
