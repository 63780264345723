import React, {ReactNode} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {SubItemProps} from './box';
import {OsChecker} from '@esgillc/ui-kit/utils';

class Props {
	selected?: boolean;
	preselected: boolean;
	title: string;
	canEdit: boolean;
	canDrag: boolean;
	canView?: boolean;
	onSelect: () => any;
	onEdit: (id: number) => void;
	className?: string;
	id: number;
	onDragStart?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	onDragEnd?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	scheduled?: boolean;
	subItems?: SubItemProps[];
	editTooltip?: string;
	viewTooltip?: string;
	editIcon?: ReactNode;
	viewIcon?: ReactNode;
}

class State {
	mouseDown: boolean = false;
}

export class BoxItem<P extends Props = Props> extends React.Component<P, State> {
	constructor(props: P) {
		super(props);
		this.state = new State();
	}

	protected select(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();
		if (this.props.onSelect) {
			this.props.onSelect();
		}
	}

	render() {
		return <>
			<div
				className={'item ' + (this.props.className ? this.props.className : '') + (this.props.selected ? ' selected' : '') + (this.props.preselected ? 'preselected' : '')}
				data-name={this.props.title} data-id={this.props.id}>
				<OnHoverTooltip message={this.props.title}>
					<a href='#' className='title'
					   onClick={(e) => this.select(e)}
					   onDragStart={this.props.onDragStart != null ? (event) => this.props.onDragStart(event, this.props.id) : null}
					   onDragEnd={this.props.onDragEnd != null ? (event) => this.props.onDragEnd(event, this.props.id) : null}
					   draggable={this.props.onDragStart != null}>{this.props.title}</a>
				</OnHoverTooltip>
				{this.renderDrag()}
				{this.renderEdit()}
				{this.renderView()}
				{this.renderScheduled()}
			</div>
			{this.renderSubItems()}
		</>;
	}

	private renderScheduled() {
		if (this.props.scheduled) {
			return <OnHoverTooltip message='Scheduled'>
				<a href='#' className='icon scheduled'>
					<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M8.99999 0.666687C4.39999 0.666687 0.666656 4.40002 0.666656 9.00002C0.666656 13.6 4.39999 17.3334 8.99999 17.3334C13.6 17.3334 17.3333 13.6 17.3333 9.00002C17.3333 4.40002 13.6 0.666687 8.99999 0.666687ZM7.33332 13.1667L3.16666 9.00002L4.34166 7.82502L7.33332 10.8084L13.6583 4.48335L14.8333 5.66669L7.33332 13.1667Z'
							fill='#0088CC'/>
					</svg>
				</a>
			</OnHoverTooltip>;
		}
	}

	private renderSubItems() {
		if (this.props.selected) {
			return this.props.subItems.map(x => {
				return <BoxItem
					preselected={null}
					id={x.id}
					key={x.id}
					title={x.title}
					canDrag={false}
					subItems={[]}
					canEdit={x.canEdit}
					className='sub-hierarchy'
					onSelect={() => {
					}}
					onEdit={(c) => x.onEdit(c)}
				/>;
			});
		}
	}

	private mouseDown = () => {
		setTimeout(() => this.setState({mouseDown: true}), 500);
	};
	private mouseUpOrLeave = () => this.setState({mouseDown: false});

	private renderDrag() {
		if (this.props.canDrag) {
			return <a href='#'
			          className={`icon drag ${OsChecker.isMac() && 'mac' || ''} ${this.state.mouseDown && 'mouseDown' || ''}`}
			          title='Drag'
			          onMouseDown={OsChecker.isMac() && this.mouseDown || null}
			          onMouseUp={OsChecker.isMac() && this.mouseUpOrLeave || null}
			          onMouseLeave={OsChecker.isMac() && this.mouseUpOrLeave || null}
			>
				<svg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16' fill='none'>
					<path
						d='M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z'
						fill='#0088CC'/>
				</svg>
			</a>;
		}

		return null;
	}

	protected renderEditIcon() {
		return <a href='#' className='icon edit' onClick={() => this.props.onEdit(this.props.id)}>
			{this.props.editIcon ? this.props.editIcon :
				<svg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14' fill='none'>
					<path
						d='M8.9034 2.33642L11.816 5.19826L4.44334 12.4424L1.53235 9.58059L8.9034 2.33642ZM14.0262 1.64621L12.7272 0.369929C12.2253 -0.12331 11.4101 -0.12331 10.9064 0.369929L9.66219 1.59248L12.5748 4.45434L14.0262 3.0283C14.4155 2.64572 14.4155 2.02877 14.0262 1.64621ZM0.0081052 13.6031C-0.0449012 13.8375 0.170481 14.0476 0.409064 13.9906L3.65472 13.2173L0.743724 10.3555L0.0081052 13.6031Z'
						fill='#0088CC'/>
				</svg>}
		</a>;
	}

	protected renderViewIcon() {
		if(!this.props.viewIcon) {
			return this.renderEditIcon();
		}
		return <a href='#' className='icon edit' onClick={() => this.props.onEdit(this.props.id)}>
			{this.props.viewIcon}
		</a>;
	}

	renderEdit() {
		if (this.props.canEdit) {
			const icon = this.renderEditIcon();
			if (this.props.editTooltip) {
				return <OnHoverTooltip message={this.props.editTooltip}>
					{icon}
				</OnHoverTooltip>;
			} else {
				return icon;
			}
		}
		return null;
	}

	renderView() {
		if (!this.props.canEdit && this.props.canView) {
			const icon = this.renderViewIcon();
			if (this.props.viewTooltip) {
				return <OnHoverTooltip message={this.props.viewTooltip ?? this.props.editTooltip}>
					{icon}
				</OnHoverTooltip>;
			} else {
				return icon;
			}
		}
		return null;
	}
}

