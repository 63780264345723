import {Button} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Property} from '@stitches/react/types/css';

const testButtonWidth: Property.Width = 74;

export const TestButton = styled(Button, {
	width: testButtonWidth,
});

export const TestButtonStub = styled(Box, {
	width: testButtonWidth,
});
