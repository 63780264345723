import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';
import {Box, GridBox, SelectableList} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Wrapper = styled(GridBox, {
	overflow: 'hidden',
	gap: '20px',
	alignContent: 'start',
});

export const SelectableListItem = styled(SelectableList.Item, {
	padding: '8px 12px',

	[`& ${Text}`]: {
		'&:not(hover)': {
			color: '$neutral16',
		},
	},
});

export const SelectButton = styled(Button, {
	width: 'fit-content',
	minWidth: 'fit-content',
	padding: '4px 3px',
});

export const Divider = styled(Box, {
	background: '$mild',
	width: '100%',
	height: '1px',
});

export const StyledImage = styled('img', {
	width: '80px',
	height: '80px',
	borderRadius: '6px',
});

export const StyledText = styled(Text, {
	color: '$mediumContrast !important',
	maxHeight: 48,

	variants: {
		showFullDescription: {
			true: {
				maxHeight: 'none',
			},
			false: {
				overflow: 'hidden',
			},
		},
	},
});

export const Content = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	alignItems: 'center',
	gap: '$3',
});