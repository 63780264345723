import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {TestColorIndicatorLine, TestColorIndicatorWrapper} from './index.styled';
import {TestContentArea} from '../types';
import {BaseComponentProps} from '@esgi/ui';
import {isNull} from 'underscore';
import {indicatorWrapperColor, indicatorLineColor} from './constants';
import {TestColor} from './types';
import {legacyTestColorByContentArea} from '../constants';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'color'> &
	BaseComponentProps & {
		contentArea: TestContentArea;

		/**
		 * e.g. 90CAF9
		 * if color null, color set by contentArea
		 */
		color: string | null;
	};

export const TestColorLineIndicator = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-color-line-indicator', css = {}, contentArea, color, ...props}, forwardedRef) => {
		const testColor = useMemo<TestColor>(() => {
			const legacyColor = legacyTestColorByContentArea[contentArea];

			if (isNull(color) || color.toLocaleLowerCase() === legacyColor.toLocaleLowerCase()) {
				return {
					defaultColors: {
						indicatorWrapperColor: `$${indicatorWrapperColor[contentArea]}`,
						indicatorLineColor: indicatorLineColor[contentArea],
					},
					customColor: null,
				};
			}

			return {
				defaultColors: null,
				customColor: `#${color}`,
			};
		}, [contentArea, color]);

		return (
			<TestColorIndicatorWrapper
				css={{
					...css,
					backgroundColor: testColor.defaultColors?.indicatorWrapperColor ?? testColor.customColor ?? 'unset',
				}}
				ref={forwardedRef}
				{...props}
			>
				{!isNull(testColor.defaultColors) && (
					<TestColorIndicatorLine color={testColor.defaultColors.indicatorLineColor} dataCy={`${dataCy}-line`} />
				)}
			</TestColorIndicatorWrapper>
		);
	},
);
