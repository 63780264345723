import moment from 'moment';
import {LogoModel} from '../../parent-letter-editor/models';
import {
	ResponseResultsGradeScaleEntry,
	ResponseResultsGroupItem,
	ResponseResultsInit,
	ResponseResultsLetterTemplate,
	ResponseResultsStudent,
	ResponseResultsTestResult,
	ResponseResultsUser,
} from '../../api-models';

export class Init {
	groupItem: GroupItem;
	students: Student[];
	user: User;
	letterTemplates: { [languageId: number]: LetterTemplate };
	gradeScaleEntries: GradeScale[];
	reportDate: string;
	subject: string;
	reportGuid: string;
	showSessionDate: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	translations: { [languageId: string]: { [name: string]: string } };
	logo: LogoModel;

	get hasScales(): boolean {
		return this.gradeScaleEntries && this.gradeScaleEntries.length > 0;
	}

	public static FromResponse(response: ResponseResultsInit): Init {
		const result = new Init();

		result.groupItem = GroupItem.FromResponse(response.groupItem);
		result.user = User.FromResponse(response.user);
		result.letterTemplates = (response.letterTemplates)
			? LetterTemplate.FromResponseArray(response.letterTemplates)
			: [];

		if (response.gradeScaleEntries) {
			result.gradeScaleEntries = GradeScale.FromResponseArray(response.gradeScaleEntries);
		}

		result.reportDate = response.reportDate;
		result.subject = response.subject;
		result.students = [];
		result.reportGuid = response.reportGuid;
		result.showSessionDate = response.showSessionDate;
		result.translations = response.translations;
		result.logo = response.logo;

		for (let i = 0; i < response.students.length; i++) {
			const student = Student.FromResponse(response.students[i]);

			if (result.letterTemplates[student.languageID]) {
				const letterTemplate = result.letterTemplates[student.languageID];

				student.letter = new LetterTemplate();
				student.letter.letterText = '<p>' + letterTemplate.letterText.replace(new RegExp('<Teacher_Title>', 'gi'), result.user.title)
					.replace(new RegExp('<Teacher_First_Name>', 'gi'), result.user.firstName)
					.replace(new RegExp('<Teacher_Last_Name>', 'gi'), result.user.lastName)
					.replace(new RegExp('<Student_First_Name>', 'gi'), student.firstName)
					.replace(new RegExp('<Student_Last_Name>', 'gi'), student.lastName)
					.replace(new RegExp('<Student_ID>', 'gi'), student.studentIDN)
					.replace(/[\n\r]+/g, '<p>');
				student.letter.languageID = student.languageID;
			} else {
				student.letter = null;
			}
			result.students.push(student);
		}

		return result;
	}
}

export class GroupItem {
	name: string;
	itemType: string;

	public static FromResponse(response: ResponseResultsGroupItem): GroupItem {
		const result = new GroupItem();

		result.name = response.name;
		result.itemType = response.itemType;

		return result;
	}
}

export class GradeScale {
	private static _DEFAULT_COLOR: string = '#BDBDBD';
	name: string;
	description: string;
	color: string;

	public static FromResponse(response: ResponseResultsGradeScaleEntry): GradeScale {
		const result = new GradeScale();

		result.name = response.name;
		result.description = response.description;
		result.color = response.color
			? response.color
			: GradeScale._DEFAULT_COLOR;

		return result;
	}

	public static FromResponseArray(response: ResponseResultsGradeScaleEntry[]): GradeScale[] {
		return response.map(item => {
			return GradeScale.FromResponse(item);
		});
	}
}

export class LetterTemplate {
	letterText: string;
	languageID: number;

	public static FromResponse(response: ResponseResultsLetterTemplate): LetterTemplate {
		const result = new LetterTemplate();

		result.letterText = response.letterText;
		result.languageID = response.languageID;

		return result;
	}

	public static FromResponseArray(response: ResponseResultsLetterTemplate[]): { [languageId: number]: LetterTemplate } {

		const results: { [languageId: number]: LetterTemplate; } = {};

		for (let i = 0; i < response.length; i++) {
			results[response[i].languageID] = LetterTemplate.FromResponse(response[i]);
		}

		return results;
	}
}

export class Student {
	firstName: string;
	lastName: string;
	studentIDN: string;
	studentID: number;
	languageID: number;
	testResults: TestResult[];
	letter: LetterTemplate;

	get fullName(): string {
		return this.firstName + ' ' + this.lastName;
	}

	public static FromResponse(response: ResponseResultsStudent): Student {
		const result = new Student();

		result.firstName = response.firstName;
		result.lastName = response.lastName;
		result.studentIDN = response.studentIDN;
		result.studentID = response.studentID;
		result.languageID = response.languageID;
		result.testResults = TestResult.FromResponseArray(response.testResults);

		return result;
	}

	public static FromResponseArray(response: ResponseResultsStudent[]): Student[] {
		return response.map(item => {
			return Student.FromResponse(item);
		});
	}
}

export class User {
	firstName: string;
	lastName: string;
	title: string;
	userID: number;

	get fullName(): string {
		return this.title + ' ' + this.lastName;
	}

	public static FromResponse(response: ResponseResultsUser): User {
		const result = new User();

		result.firstName = response.firstName;
		result.lastName = response.lastName;
		result.title = response.title;
		result.userID = response.userID;

		return result;
	}

	public static FromResponseArray(response: ResponseResultsUser[]): User[] {
		return response.map(item => {
			return User.FromResponse(item);
		});
	}
}

export class TestResult {
	testId: number;
	testType: string;
	testName: string;
	questionsTotal: number;
	correctAnswers: string[];
	incorrectAnswers: string[];
	gradeScale: GradeScale;
	fullwidth: boolean;
	isSkip: boolean;
	notesText: string;
	summaryNotes: string;
	testDate: Date;

	get resultsTitle(): string {
		return ' (' + this.correctAnswers.length.toString() + ' out of ' + this.questionsTotal.toString() + ')';
	}

	get testDateString(): string {
		return moment(this.testDate).format('MM/DD/YYYY');
	}

	get hasGradeRange(): boolean {
		return this.gradeScale !== undefined &&
			this.gradeScale != null;
	}

	get correctAnswersString(): string {
		const result = '';

		if (this.correctAnswers && this.correctAnswers.length > 0) {
			return this.correctAnswers.join(', ');
		}

		return result;
	}

	get incorrectAnswersString(): string {
		const result = '';

		if (this.incorrectAnswers && this.incorrectAnswers.length > 0) {
			return this.incorrectAnswers.join(', ');
		}

		return result;
	}

	public static FromResponse(response: ResponseResultsTestResult): TestResult {
		const result = new TestResult();

		result.testName = response.testName;
		result.testType = response.testType;
		result.questionsTotal = response.questionsTotal;

		if (response.gradeScale) {
			result.gradeScale = GradeScale.FromResponse(response.gradeScale);
		}

		result.fullwidth = response.fullwidth;
		result.isSkip = response.isSkip;
		result.testDate = response.testDate;
		result.correctAnswers = [];
		result.incorrectAnswers = [];
		result.notesText = '';

		if (result.testType == 'YN') {

			let noteIndx = 0;
			let answerItem = '';

			response.answers.forEach((item, indx) => {

				answerItem = item.questionName;

				if (item.questionNote) {
					result.notesText = ((result.notesText) ? result.notesText + ' ,' : '') + '<sup>' + (++noteIndx).toString() + '</sup>' + item.questionNote;
					answerItem += '<sup>' + noteIndx.toString() + '</sup>';
				}

				if (item.score > 0) {
					result.correctAnswers.push(answerItem);
				} else {
					result.incorrectAnswers.push(answerItem);
				}
			});

			result.summaryNotes = response.summaryNotes;
		}

		if (result.testType == 'Score') {
			if (response.answers[0].questionNote){
				result.notesText = response.answers[0].questionNote;
			}

			if (response.summaryNotes){
				result.summaryNotes = response.summaryNotes;
			}

			result.correctAnswers.push(response.answers[0].score.toString());
			result.incorrectAnswers.push(response.questionsTotal.toString());
		}

		if (result.testType == 'Rubric') {
			if (response.answers && response.answers.length > 0) {
				result.notesText = response.answers
					.filter(a => !!a.questionNote)
					.map(a => '<b>' + a.questionName + ': </b>' + a.questionNote)
					.reduce((sum, n, i) => i === 0 ? n : sum + ', ' + n, '');
			}

			const score = response.answers.reduce((sum, a) => sum + a.score, 0);
			result.correctAnswers.push(score.toString());

			const incorrectAnswersCount = response.answers.length;
			if (incorrectAnswersCount > 0) {
				result.incorrectAnswers.push(response.questionsTotal.toString());
			}

			result.summaryNotes = response.summaryNotes;
		}

		return result;
	}

	public static FromResponseArray(response: ResponseResultsTestResult[]): TestResult[] {
		return response.map(item => {
			return TestResult.FromResponse(item);
		});
	}
}
