import React from 'react';
import {Title, TitleProps} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import styles from './report-modal.module.less';

export class ReportModalTitle extends React.PureComponent<TitleProps> {
	public render() {
		return <Title className={join(styles.title, this.props.className)}>{this.props.children}</Title>;
	}
}
