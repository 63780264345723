import {EventBusManager} from '@esgillc/events';
import {BehaviorSubject, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {FileType, PrintSettings, UnitDataSettings, DownloadRequest, BackgroundGenerationModel, ReportData} from '../types';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {SsoTracker} from '@esgi/core/tracker';
import {V2TeachersPagesReportsPieChartsController} from '@esgi/contracts/esgi';
import {ReportRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/teachers/pages/reports/pie-charts/report-request';

export class DownloadService extends BaseService {
	public readonly reportData$ = new BehaviorSubject<ReportData | null>(null);
	public readonly busyController = new BehaviorSubject(false);
	private readonly controller = new V2TeachersPagesReportsPieChartsController();
	private readonly eventBus = new EventBusManager();
	private readonly downloadParameters = {
		[FileType.PDF]: {
			method: 'download-pdf',
			extension: '.pdf',
		},
		[FileType.ZIP]: {
			method: 'download-pdf',
			extension: '.zip',
		},
	};
	private readonly currentUser = getUser();

	public override dispose() {
		this.controller.dispose();
	}

	public getExportOptions(
		unitDataSettings: UnitDataSettings,
		printSettings: PrintSettings,
	): DownloadRequest {
		const {name: subjectName = ''} = unitDataSettings.selectedSubject || {};
		const {id: studentID = null} = unitDataSettings.selectedStudent || {};
		const {userID, globalSchoolYearID, timeZone, firstName, lastName} = this.currentUser;
		const {showEachStudent, className, groupName} = printSettings;

		return {
			userID,
			studentID,
			studentIDs: unitDataSettings.studentIDs,
			testIDs: unitDataSettings.selectedTests.map(({id}) => id),
			globalSchoolYearID,
			timeZone,
			showEachStudent,
			zip: false,
			districtName: '',
			schoolName: '',
			teacherName: `${firstName} ${lastName}`,
			className,
			groupName,
			subjectName,
		};
	}

	public preview(request: ReportRequest) {
		this.busyController.next(true);
		return this.controller.getReport(request).pipe(
			tap(({value}) => {
				this.reportData$.next(value);
				this.busyController.next(false);
			}),
		);
	}

	public download(fileType: FileType, request: DownloadRequest) {
		const {className, groupName, zip} = request;
		const {tests, students} = this.reportData$.value;
		const pagesNum = Math.ceil(tests.length / 20) * students.filter(({testResults}) => (testResults || []).length).length;
		const backgroundLoading = zip && pagesNum > 60;

		if (!backgroundLoading) {
			this.downloadFile(fileType, request);
		} else {
			return this.httpClient.ESGIApi.post<BackgroundGenerationModel>(
				'/v2/teachers/pages/reports/pie-charts',
				'start-background-generation',
				request,
			).subscribe(({reportGuid}: BackgroundGenerationModel) => {
				this.eventBus.dispatch(BackgroundDownloadManagerEvents.Start, {
					reportGuid,
					fileType: 'ZIP',
				});
			});
		}

		if (className || groupName) {
			const eventType = zip ? 'PDF Bulk' : 'PDF Standard';
			SsoTracker.trackEvent({
				trackingEvent: eventType,
				data: {reportType: 'PieCharts'},
			});
		}
	}

	private downloadFile(type: FileType, request: DownloadRequest) {
		const {method, extension} = this.downloadParameters[type];
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = `Pie_Charts_${year}-${month}-${day}${extension}`;

		this.httpClient.ESGIApi.file(
			'/v2/teachers/pages/reports/pie-charts',
			method,
			filename,
			request,
		).subscribe();
	}
}
