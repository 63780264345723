import {useEffect} from 'react';
import {usePieChartService} from '../../hooks';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {EmptyList} from './components/empty-list';
import {TestList} from './components/test-list';
import {SkeletonList} from './index.styled';
import {Test} from './types';
import {useTestsControl} from './hooks';
import {Skeletonable} from '@esgi/ui';

interface Props extends Skeletonable {
	tests: Test[] | null;
	disabled?: boolean;
	onChange: (value: Test['id'][]) => void;
}

export function TestsControl({skeleton, tests, onChange}: Props) {
	const service = usePieChartService();
	const selectAll = useBehaviorSubject(service.selectAll$);
	const {items, selected, toggleSelected, ...props} = useTestsControl(tests, onChange);

	const commonProps = {
		tests: items,
		selectedTests: selected,
		toggleTests: toggleSelected,
		...props,
	};

	useEffect(() => {
		const tests = items.map(({id}) => id);
		props.setSelected(tests);
	}, [selectAll]);

	if (skeleton) {
		return <SkeletonList/>;
	}

	if (!tests?.length) {
		const selectedData = service.entity$.value;
		let text = 'There is no test data for ';
		if (!selectedData.studentId){
			text += 'any student in this ' + (selectedData.groupId ? 'group' : 'class');
		} else {
			text += 'the student';
		}
		return <EmptyList content={text} />;
	}


	return <TestList {...commonProps}/>;
}
