import {TestType} from '@esgi/main/libs/core';
import {TestSession, TestInfo} from '../../../../../types';
import {useCallback, useState} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {CheckboxesBox, Checkbox} from '../../../../../kit';
import {defaultRowsLimit, rowsLimit} from '../../../constants';
import {Button} from '@esgi/ui';
import {Download} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {YesNoTable} from './components/yes-no-table';
import {RubricTable} from './components/rubric-table';
import {ScoreTable} from './components/score-table';
import {useSessionsData} from './use-sessions-data';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {Class, Student, SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {dispatchAppEvent} from '@esgillc/events';
import {ReportErrorEvent} from '@esgi/core/react';
import {isNull} from 'underscore';
import {AddSessionDetails, TestSessionDetailsAlert} from '@esgi/main/features/teacher/test-session-details';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	isTablet: boolean;
	studentID: Student['id'];
	subjectID: SubjectTab['id'];
	studentFirstName: string;
	studentLastName: string;
	classID: Class['id'];
	testID: number;
	subjectType: SubjectType;
};

export function StudentEntity({
	testSessions,
	testInfo,
	studentFirstName,
	studentLastName,
	classID,
	studentID,
	testID,
	subjectID,
	subjectType,
	isTablet,
}: Props) {
	const [openedTestSessionDetailsID, setOpenedTestSessionDetailsID] = useState<number | null>(null);
	const [openAddSessionDetails, setOpenAddSessionDetails] = useState(false);

	const [showDeleted, setShowDeleted] = useState(true);
	const [rowsCount, setRowsCount] = useState(defaultRowsLimit);

	const service = useService(Service);

	const toggleshowDeleted = useCallback(() => {
		setShowDeleted((currentState) => !currentState);
	}, []);

	const handleLoadMoreClick = useCallback(() => {
		setRowsCount((prev) => prev + rowsLimit);
	}, []);

	const onOpenTestSessionDetailsAlert = useCallback((sessionID: number) => {
		setOpenedTestSessionDetailsID(sessionID);
	}, []);

	const onCloseTestSessionDetailsAlert = useCallback(() => {
		setOpenedTestSessionDetailsID(null);
	}, []);

	const handleOpenAddSessionDetails = useCallback(() => {
		setOpenAddSessionDetails(true);
	}, []);

	const handleCloseAddSessionDetails = useCallback(() => {
		setOpenAddSessionDetails(false);
	}, []);

	const {visibleSessions, notVisibleSessionCount, hasDeletedSessions} = useSessionsData({
		testSessions,
		rowsCount,
		showDeleted,
	});

	const handleDownloadAllSession = () => {
		if (testInfo.testType === TestType.YN || testInfo.testType === TestType.Score) {
			service
				.downloadYNScoreSessionDetails({
					testName: testInfo.name,
					studentFirstName,
					studentLastName,
					classID,
					studentID,
					testID,
					subjectName: testInfo.subjectName,
				})
				.subscribe({
					error: () => {
						dispatchAppEvent(ReportErrorEvent, new ReportErrorEvent('Unable to load pdf file.'));
					},
				});
		}

		if (testInfo.testType === TestType.Rubric) {
			service
				.downloadRubricSessionDetails({
					classID,
					testSessionIDs: testSessions.map(({id}) => id),
					studentID,
					subjectID,
					subjectType,
					testID,
				})
				.subscribe();
		}
	};

	const getTableByTestType = () => {
		switch (testInfo.testType) {
			case TestType.YN:
				return (
					<YesNoTable
						testInfo={testInfo}
						testSessions={visibleSessions}
						notVisibleSessionCount={notVisibleSessionCount}
						onLoadMoreClick={handleLoadMoreClick}
						isTablet={isTablet}
						onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
					/>
				);
			case TestType.Rubric:
				return (
					<RubricTable
						testInfo={testInfo}
						testSessions={visibleSessions}
						notVisibleSessionCount={notVisibleSessionCount}
						onLoadMoreClick={handleLoadMoreClick}
						isTablet={isTablet}
						onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
					/>
				);
			case TestType.Score:
				return (
					<ScoreTable
						testInfo={testInfo}
						testSessions={visibleSessions}
						notVisibleSessionCount={notVisibleSessionCount}
						onLoadMoreClick={handleLoadMoreClick}
						isTablet={isTablet}
						onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<GridBox gapY='4'>
				<GridBox justify={hasDeletedSessions ? 'between' : 'end'} align='center' flow='column'>
					{hasDeletedSessions && (
						<CheckboxesBox>
							<Checkbox
								checked={showDeleted}
								onCheckedChange={toggleshowDeleted}
								label='Show Deleted Sessions'
								selected={showDeleted}
								dataCy='show-deleted-sessions'
							/>
						</CheckboxesBox>
					)}
					<GridBox gapX='4' flow='column'>
						{testSessions.filter(x => !x.isDeleted).length > 0 &&
							<Button dataCy='export-all-menu-trigger' color='tertiary' onClick={handleDownloadAllSession}>
								<Download />
								<Text size='medium' bold>
									Export All
								</Text>
							</Button>
						}
						<Button dataCy='add-session-button' color='secondary' onClick={handleOpenAddSessionDetails}>
							<Text size='medium' bold>
								Add Session
							</Text>
						</Button>
					</GridBox>
				</GridBox>

				{getTableByTestType()}
			</GridBox>

			{!isNull(openedTestSessionDetailsID) && (
				<TestSessionDetailsAlert
					testSessionID={openedTestSessionDetailsID}
					onAlertClose={onCloseTestSessionDetailsAlert}
					classID={classID}
					subjectID={subjectID}
				/>
			)}

			{openAddSessionDetails && (
				<AddSessionDetails
					studentID={studentID}
					subjectID={subjectID}
					onAlertClose={handleCloseAddSessionDetails}
					testID={testID}
				/>
			)}
		</>
	);
}
