import {Modal, Title, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';

interface Props {
	onSaveClicked: () => void;
	onDeleteClicked: () => void;
	studentNames: string[];
}

export function SaveDuplicateName({
	onSaveClicked, onDeleteClicked, studentNames,
}: Props) {

	const getName = () => studentNames.join(', ');
	const modalRef = useModal();

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>
				Save Duplicate Name?
			</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
				<span data-cy={'save-duplicate-name'} className={styles.text}>
					{getName()} matches a student that already exists in ESGI. It is recommended that you delete duplicate students to avoid data inconsistencies.
				</span>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Contained onClick={onSaveClicked}>SAVE ANYWAY</Buttons.Contained>
			<Buttons.Contained onClick={onDeleteClicked}>DELETE DUPLICATE</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}