import {useTeacherPageContext} from '../../../../../context/teacher-page-context';
import {useCallback, useState} from 'react';
import {Class} from '@esgi/main/libs/store';
import {ClassBucket, StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {DrawerMode} from '@esgi/main/features/teacher/home';
import {TeacherDrawerName} from '@esgi/main/features/teacher/drawers';
import {useNavigate} from 'react-router-dom';
import {SelectAllClassesButton} from './select-all-classes-button';
import {useUser} from '@esgi/core/authentication';

type Props = {
	setViewMode: VoidFunction;
	classBuckets: ClassBucket[]
};

export function EditableContent({setViewMode, classBuckets}: Props) {
	const {openEditClassForm} = useTeacherPageContext();

	const user = useUser();

	const navigate = useNavigate();

	const [openedClassesId, setOpenedClassesId] = useState<Class['id']>();

	const handleStudentClicked = useCallback(
		(studentID: number) => {
			const studentProfileDrawerUrl = new URLSearchParams({
				drawerMode: DrawerMode.Edit,
				drawerName: TeacherDrawerName.TeacherStudentViaProfile,
				studentId: studentID.toString(),
			});
			navigate(`/home?${studentProfileDrawerUrl.toString()}`);
		},
		[navigate],
	);

	return (
		<StudentsPanel.Tabs.TabContent withBottomSectionButton buttonText='Done' onBottomButtonClick={setViewMode}>
			<SelectAllClassesButton disabled />

			{classBuckets.map(({id, name, students}) => {
				const isOpen = openedClassesId === id;

				return (
					<StudentsPanel.Lists.SelectableListGroup.Expandable.Editable
						groupName={name}
						isListOpen={isOpen}
						items={students}
						withCounter
						onStyledButtonClick={() => openEditClassForm({classId: String(id), schoolYear: String(user?.globalSchoolYearID)})}
						onItemClick={handleStudentClicked}
						onTriggerButtonClick={() =>
							students.length ? setOpenedClassesId(id) : openEditClassForm({classId: String(id), schoolYear: String(user?.globalSchoolYearID)})
						}
						key={id}
					>
						{({item: {firstName, lastName}}) => (
							<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
						)}
					</StudentsPanel.Lists.SelectableListGroup.Expandable.Editable>
				);
			})}
		</StudentsPanel.Tabs.TabContent>
	);
}
