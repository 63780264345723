import {LableBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {testLabelByTestType} from './constants';
import {BaseComponentProps} from '@esgi/ui';
import {TestType} from '../types';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {isUndefined} from 'underscore';

type Props = BaseComponentProps &
	ComponentPropsWithoutRef<'div'> & {
		testType: TestType;
	};

export const TestTypeLabel = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-type-label', css = {}, testType, children, ...props}, forwardedRef) => {
		return (
			<LableBox dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{isUndefined(children) ? (
					<Text data-cy={`${dataCy}-label`} size='small' color='highContrast'>
						{testLabelByTestType[testType]}
					</Text>
				) : (
					children
				)}
			</LableBox>
		);
	},
);
