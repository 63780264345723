import './styles.less';
import React, {ReactNode} from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';

class Props {
	pointToRef: React.RefObject<HTMLElement>;

	top: number;
	left: number;

	arrowTop: number;
	arrowLeft: number;
	children?: ReactNode;
}

class State {
	modalStyle: Style;
	arrowStyle: Style;

	loaded: boolean = false;
}

class Style {
	top: number;
	left: number;
}

export class HintPopup extends React.Component<Props, State> {
	constructor(props?: Props) {
		super(props);
		this.state = {
			loaded: false,
			modalStyle: {
				top: 0,
				left: 0,
			},
			arrowStyle: {
				top: 0,
				left: 0,
			},
		};
	}

	setPosition() {
		if (!this.props.pointToRef) {
			return;
		}

		const pointTo = this.props.pointToRef.current.getBoundingClientRect();
		this.setState({
			loaded: true,
			modalStyle: {
				top: pointTo.top + this.props.top,
				left: pointTo.left + this.props.left,
			},
			arrowStyle: {
				top: this.props.arrowTop,
				left: this.props.arrowLeft,
			},

		});
	}

	componentDidMount() {
		setTimeout(() => this.setPosition(), 1000);
	}

	renderPopup() {
		return <div className='hint'>
			{this.props.children}
		</div>;
	}

	render() {
		if (!this.state.loaded) {
			return <></>;
		}

		const userType = userStorage.get().userType;

		return <div className='hint-popup' style={this.state.modalStyle}>
			{this.renderPopup()}
			{userType === UserType.T &&
			<div className='hint-arrow-left' style={this.state.arrowStyle}>
			</div>
			}

			{(userType === UserType.ISD || userType === UserType.ISS) &&
			<div className='hint-arrow-upper' style={this.state.arrowStyle}>
			</div>
			}

		</div>;
	}
}
