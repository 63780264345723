import {userStorage} from '@esgi/core/authentication';
import {StudentAssessInstructionsLocalStorage} from '../types';
import {BaseService} from '@esgi/core/service';

export class LocalStorageService extends BaseService {
	private readonly studentAssessInstructions = 'studentAssessInstructions';
	private readonly currentUserId = userStorage.get().userID;

	public get isBannedModal() {
		return this.getValueByKey('isBannedModal') ?? false;
	}

	public setBanModal(value: boolean) {
		this.setValueByKey('isBannedModal', value);
	}

	private getValue(): StudentAssessInstructionsLocalStorage | null {
		const item = localStorage.getItem(this.studentAssessInstructions);

		if (item) {
			return JSON.parse(item) as StudentAssessInstructionsLocalStorage;
		}

		return null;
	}

	private getValueByKey<K extends keyof StudentAssessInstructionsLocalStorage>(
		key: K,
	): StudentAssessInstructionsLocalStorage[K] | null {
		const item = this.getValue();
		const userInfo = item?.[this.currentUserId];

		if (userInfo) {
			return userInfo[key];
		}

		return null;
	}

	private setValueByKey<K extends keyof StudentAssessInstructionsLocalStorage>(
		key: K,
		value: StudentAssessInstructionsLocalStorage[K],
	) {
		const currentValue = this.getValue();
		const currentUserValue = currentValue?.[this.currentUserId];

		localStorage.setItem(
			this.studentAssessInstructions,
			JSON.stringify({
				...currentValue,
				[this.currentUserId]: {
					...currentUserValue,
					[key]: value,
				},
			}),
		);
	}
}
