import {useCallback, useEffect, useState} from 'react';
import {ClassState} from './types';
import {ClassType, StudentType} from '../../types';

interface Props {
	classes: ClassType[];
	students: StudentType[];
	selected: ClassType['id'][];
}

export function useClassList({classes, students, selected}: Props) {
	const [state, setState] = useState<ClassState[]>([]);

	useEffect(() => {
		setState((currentState) =>
			classes.map<ClassState>(({studentIDs, ...classData}, i) => {
				const classStudents = students.filter(({id}) => studentIDs.includes(id));
				const current = currentState.find(({id}) => classData.id === id);
				return {...classData, students: classStudents, isListOpen: !!current?.isListOpen || !i};
			}),
		);
	}, [classes, students]);

	const onToggle = useCallback((isListOpen: boolean, classId: ClassType['id']) => {
		setState((currentState) => {
			const copied = [...currentState];
			const copiedClass = copied.find(({id}) => id === classId);

			if (copiedClass) {
				copiedClass.isListOpen = isListOpen;
			}

			return copied;
		});
	}, []);

	const onSelectAll = (
		classId: ClassType['id'],
		selectedStudentIds: StudentType['id'][],
	): StudentType['id'][] => {
		const {students} = state.find(({id}) => classId === id);
		const studentIds = students.map(({id}) => id);
		const isSelected = studentIds.length && studentIds.every((id) => selectedStudentIds.includes(id));
		const notSelected = studentIds.filter((id) => !selectedStudentIds.includes(id));
		return isSelected ? studentIds : notSelected;
	};

	return {
		state,
		onToggle,
		onSelectAll,
	};
}
