import {useUser} from '@esgi/core/authentication';
import {BaseSelectedValuesStorage, SelectedValues} from './base';
import {useCallback, useMemo, useState} from 'react';

export const useSelectedState = <T extends object>(selectedValuesStorage: BaseSelectedValuesStorage<T>): [
	SelectedValues<T>,
	(values: SelectedValues<T>['values']) => void
] => {
	const user = useUser();
	const [selectedValues, setSelectedValues] = useState(() => selectedValuesStorage.getSelectedValues(user?.globalSchoolYearID, user?.userID));

	const userInfo = useMemo(() => {
		return {
			globalSchoolYearId: user?.globalSchoolYearID,
			userId: user?.userID,
		};
	}, [user]);

	const updateSelectedValues = useCallback((values: SelectedValues<T>['values']) => {
		selectedValuesStorage.setSelectedValues({values, userInfo});
		setSelectedValues({values, userInfo});
	}, [selectedValuesStorage, userInfo]);

	return [selectedValues, updateSelectedValues];
};
