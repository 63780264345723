import {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {AssignmentStateTab} from './types';
import {TeacherAssignmentsContext} from './outlet-context';
import {useService} from '@esgi/core/service';
import {AssignmentsListService} from './assignments-list-service';
import {useBehaviorSubject} from '@esgi/ui';
import {sortAssignmentStateKey} from './constants';
import {Snackbar} from '@esgi/ui/snackbar';
import {useEventEffect} from '@esgillc/events';
import {AddAssignmentToList, RemoveAssignmentFromList, UpdateAssignmentInList} from './events';
import {routes} from '@esgi/main/libs/core';

export default function TeacherAssignmentsLayout() {
	const [isAssignmentListLoaded, setIsAssignmentListLoaded] = useState(false);
	const [selectedAssignmentState, setSelectedAssignmentState] = useState(AssignmentStateTab.AllAssignments);

	const snackbarRef = Snackbar.useRef();

	const navigate = useNavigate();
	const {pathname} = useLocation();

	const dataService = useService(AssignmentsListService);

	const assignmentsList = useBehaviorSubject(dataService.assignmentsList$);

	useLayoutEffect(() => {
		if (pathname === routes.teacher.assignments.root) {
			navigate(routes.teacher.assignments.assignmentsList, {
				replace: true,
			});
		}
	}, [pathname]);

	useEffect(() => {
		dataService.init().subscribe(() => {
			setIsAssignmentListLoaded(true);
		});
	}, []);

	const filteredAssignmentList = useMemo(
		() =>
			selectedAssignmentState === AssignmentStateTab.AllAssignments
				? assignmentsList
				: assignmentsList.filter(({state}) => state === sortAssignmentStateKey[selectedAssignmentState]),
		[selectedAssignmentState, assignmentsList],
	);

	useEventEffect(UpdateAssignmentInList, ({assignmentId, newAssignmentData}) => {
		dataService.updateAssignmentInList({assignmentId, newAssignmentData});
	});

	useEventEffect(RemoveAssignmentFromList, ({assignmentId}) => {
		dataService.removeAssignmentFromList(assignmentId);
	});

	useEventEffect(AddAssignmentToList, ({assignment}) => {
		dataService.addAssignmentToList(assignment);
	});

	const outletContext = useMemo<TeacherAssignmentsContext>(
		() => ({
			selectedAssignmentState,
			setSelectedAssignmentState,
			visibleAssignmentsList: filteredAssignmentList,
			assignmentsList,
			isAssignmentListLoaded,
			snackbarRef,
		}),
		[assignmentsList, filteredAssignmentList, isAssignmentListLoaded, selectedAssignmentState, snackbarRef],
	);

	return (
		<>
			<Outlet context={outletContext} />
			<Snackbar snackbarRef={snackbarRef} />
		</>
	);
}
