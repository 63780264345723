import {useCallback, useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {FilterType} from '../../../enums';
import {TestExplorerBackEvent} from '../../../events';
import {DataService} from '../../../services/data-service';
import {ISubjectInfo} from '../../../services/subjects-service';
import {RemoveFilterEvent} from '../../header/search-tags/search-tags';
import {AuthorClicked} from '../../noted-author/events';
import {BoxItem} from '../box-item/box-item';
import {Box} from '../box/box';
import {Loading} from '../enums';
import {ClearAllEvent} from '../events';

type Props = {
	dataService: DataService;
	hasChanged: (subjectId: number | null) => any;
}

export function SubjectTabs(props: Props): JSX.Element {
	const [loading, setLoading] = useState(Loading.NotLoaded);
	const [subjectID, setSubjectID] = useState<number>(null);
	const [subjects, setSubjects] = useState<ISubjectInfo[]>([]);

	const changed = (subjectID: number) => {
		setSubjectID(subjectID);
		props.hasChanged(subjectID);
	};

	const clear = () => changed(null);

	const onBoxOpened = () => {
		if (loading === Loading.NotLoaded) {
			setLoading(Loading.Loading);
			props.dataService.subjects.subjectInfos$.subscribe(r => {
				setSubjects(r.filter(x => !x.hidden));
				setLoading(Loading.Loaded);
			});
		}
	};

	useEventEffect(AuthorClicked, clear);
	useEventEffect(ClearAllEvent, clear);
	useEventEffect(TestExplorerBackEvent, clear);
	useEventEffect(RemoveFilterEvent, (args) => args.type === FilterType.SubjectTab && changed(null));

	const boxes = subjects.map(g => <BoxItem key={g.id}
	                                         title={g.name}
	                                         selected={subjectID === g.id}
	                                         onSelect={() => g.id === subjectID ? clear() : changed(g.id)}/>);

	return <Box collapsible
	            cleared={clear}
	            children={boxes}
	            title='Subject Tab'
	            onOpened={onBoxOpened}
	            canBeCleared={!!subjectID}
	            filterType={FilterType.SubjectTab}
	            loading={loading === Loading.Loading}/>;
}
