import {Select as SelectUI} from '@esgi/ui/controls';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {SelectProps} from '@radix-ui/react-select';
import {SelectField, SelectOption} from './index.styled';
import {SubjectLevel} from '@esgi/main/libs/store';
import {SubjectName} from '@esgi/main/kits/common';

interface Props extends SelectProps {
	options: {id: number; name: string; level: SubjectLevel}[];
}

export function SelectSubjects(props: Props) {
	const {options, value, onValueChange} = props;

	return (
		<Box dataCy='subject-dropdown'>
			<Text data-cy='label'>Subject Tab</Text>
			{options.length > 1 && (
				<SelectUI.Root value={value} onValueChange={onValueChange}>
					<SelectField />
					<SelectUI.Content>
						{options?.map(({id, name, level}) => (
							<SelectOption value={String(id)} key={id}>
								<SubjectName subjectLevel={level}>{name}</SubjectName>
								<Text size='medium' bold data-placeholder-value=''>
									{name}
								</Text>
							</SelectOption>
						))}
					</SelectUI.Content>
				</SelectUI.Root>
			)}
			{options.length === 1 && <SubjectName subjectLevel={options[0]!.level}>{options[0]!.name}</SubjectName>}
		</Box>
	);
}
