import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Skeletonable} from '../../../../types';
import {CSS} from '@stitches/react';
import {Ul} from './index.styled';

export type ListGroupProps = ComponentPropsWithoutRef<'ul'> &
	Skeletonable & {
		/** Data attribute used by test automation team. */
		dataCy?: string;

		/** Returns a Style interface from a configuration, leveraging the given media and style map. */
		css?: CSS;
	};

export const ListGroup = forwardRef<HTMLUListElement, ListGroupProps>(
	({dataCy = 'ui-kit-list-group', skeleton, ...props}, forwaredRef) => (
		<Ul data-cy={dataCy} ref={forwaredRef} {...props} />
	),
);
