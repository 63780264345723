import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupSingleProps} from '@radix-ui/react-toggle-group';
import {ToggleGroupContent, ToggleGroupItem, ToggleGroupTitle} from './index.styled';
import {useTabsContext} from '../../context';

type Props = {
	dataCy?: string;
	onActive?: ToggleGroupSingleProps['onValueChange'];
};

export function TabsBar<T extends string>({dataCy, onActive}: Props) {
	const {active, setActive, availableTabs} = useTabsContext<T>();

	if (!availableTabs.length) {
		return null;
	}

	return (
		<ToggleGroup.Root
			css={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}
			onValueChange={(value) => {
				setActive(value as T);
				onActive && onActive(value);
			}}
			value={active}
			dataCy={dataCy}
		>
			<ToggleGroupContent dataCy={`${dataCy}-content`}>
				{availableTabs.map(({id, name, description, disabled, dataCy, applyDisabledStyles = false}) => (
					<ToggleGroupItem
						value={id}
						valueDescription={description}
						key={id}
						disabled={active === id || disabled}
						dataCy={dataCy}
						applyDisabledStyles={applyDisabledStyles}
					>
						<Text size='medium' bold data-cy={dataCy}>
							{name}
						</Text>
					</ToggleGroupItem>
				))}
			</ToggleGroupContent>
			<ToggleGroupTitle dataCy={`${dataCy}-title`}>
				{({description}) => (
					<Text size='small' font='mono' data-cy={`${dataCy}-description`}>
						{description}
					</Text>
				)}
			</ToggleGroupTitle>
		</ToggleGroup.Root>
	);
}
