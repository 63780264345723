import {NavigationBar, NavigationBarOrientation} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const NavigationBarItem = styled(NavigationBar.Item, {
	color: '$base',
	margin: '12px 16px',
	whiteSpace: 'nowrap',
	gap: '6px',
	variants: {
		active: {
			true: {
				'& svg': {
					fill: '$primary',
					'& path': {
						fill: '$primary',
					},
				},

				'span': {
					color: '$primary',
				},
			},
		},

		expanded: {
			true: {
				width: 120,
				'& span': {
					marginTop: '0',
					width: '0',
				},
			},
		},

		orientation: {
			[NavigationBarOrientation.Vertical]: {
				display: 'flex',
				flexDirection: 'column',
			},
		},
	},
});
