import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CheckboxesBox = styled(GridBox, {
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',
	gap: 4,
	padding: 6,
	width: 280,
	variants: {
		isTablet: {
			true: {
				gridAutoFlow: 'column',
				justifyContent: 'start',
				width: '100%',
			},
		},
	},
});
