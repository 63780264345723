import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const ParentLettersContainer = styled(FlexBox, {
	width: '100%',
	borderBottom: '2px solid $neutral92',
	marginBottom: '10px',
	justifyContent: 'space-between',
	alignItems: 'flex-end',
	padding: '30px 0 5px 0',
});

export const ParentLetterLogo = styled(FlexBox, {
	width: '240px',
	height: '49px',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
});

export const Container = styled(FlexBox, {
	width: '50%',
});

export const ReportInfoWrapper = styled(GridBox, {
	width: '100%',
	paddingBottom: '5px',
	justifyContent: 'end',
});

export const ReportDate = styled(Text, {
	textAlign: 'end',
});

export const Label = styled(Text, {
	marginRight: '5px',
});

export const ContentWrapper = styled(GridBox, {
	gap: '5px',
});
