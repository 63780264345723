import {BaseComponentProps, GridProgressBar as GridProgressBarUI, GridProgressBarItem} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {PropsWithChildren} from 'react';

type Props = BaseComponentProps &
	PropsWithChildren & {
		progressBarTitle?: string;
		items: GridProgressBarItem[];
		boxSize?: number;
	};

export function GridProgressBar({
	dataCy = 'test-dashboard-grid-progress-bar',
	css = {},
	boxSize = 60,
	progressBarTitle,
	items,
	children,
}: Props) {
	return (
		<GridBox dataCy={dataCy} css={css} gap={4} display='inlineGrid'>
			<GridBox gap='3' flow='column'>
				<GridProgressBarUI items={items} boxSize={boxSize} activeItemColor='positive' inactiveItemColor='positiveMuted' />
				{children}
			</GridBox>

			{progressBarTitle && (
				<Text size='small' font='mono' color='neutral56' data-cy='progress-bar-label'>
					{progressBarTitle}
				</Text>
			)}
		</GridBox>
	);
}
