import {Button, ContextMenu} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {ButtonIcon, ContextMenuItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {MouseEvent, useCallback, useEffect, useState} from 'react';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	onDeleteAssignment: VoidFunction;
	disabledOpenMenu?: boolean;
};

export function AssignmentContextMenu({onDeleteAssignment, disabledOpenMenu}: Props) {
	const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
	const [isOpenDeleteAssignmentAlert, setIsOpenDeleteAssignmentAlert] = useState(false);

	const deleteAssignmentAlert = Alert.useRef();

	useEffect(() => {
		const body = document.body;

		if (!isOpenContextMenu && !isOpenDeleteAssignmentAlert && body.style.pointerEvents) {
			body.style.pointerEvents = '';
		}
	}, []);

	const handleOpenDeleteAlert = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		setIsOpenDeleteAssignmentAlert(true);
	}, []);

	const closeDeleteAssignmentAlert = Alert.useClose(deleteAssignmentAlert, () => {
		setIsOpenDeleteAssignmentAlert(false);
		setIsOpenContextMenu(false);
	});

	const onCloseDeleteAssignmentAlert = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();

			closeDeleteAssignmentAlert();
		},
		[closeDeleteAssignmentAlert],
	);

	const onConfirmDeleteAssignmentAlert = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();

			onDeleteAssignment();
			closeDeleteAssignmentAlert();
		},
		[onDeleteAssignment, closeDeleteAssignmentAlert],
	);

	return (
		<>
			<ContextMenu.Root
				open={isOpenDeleteAssignmentAlert ? true : isOpenContextMenu}
				onOpenChange={setIsOpenContextMenu}
			>
				<ContextMenu.Trigger>
					<ButtonIcon withBackgroundHover disabled={Boolean(disabledOpenMenu)}>
						<More />
					</ButtonIcon>
				</ContextMenu.Trigger>
				<ContextMenu.Content
					css={{
						display: isOpenDeleteAssignmentAlert ? 'none' : undefined,
					}}
				>
					<ContextMenu.Group>
						<ContextMenuItem onClick={handleOpenDeleteAlert}>
							<Delete />
							<Text size='small'>Delete...</Text>
						</ContextMenuItem>
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>

			{isOpenDeleteAssignmentAlert && (
				<Alert modalManagerRef={deleteAssignmentAlert} colorConfig={alertColorsConfig.negative} zIndex={999}>
					<Alert.Header onCloseIconClick={onCloseDeleteAssignmentAlert}>
						<Text size='large' color='negative'>
							Delete assignment?
						</Text>
					</Alert.Header>
					<Alert.Body>
						<GridBox>
							<Text size='medium' color='neutral40'>
								Are you sure you want to delete this assignment?
							</Text>
							<Text size='medium' color='neutral40'>
								This action cannot be undone.
							</Text>
						</GridBox>
					</Alert.Body>
					<Alert.Footer>
						<GridBox gap='3' flow='column'>
							<Button color='tertiary' onClick={onConfirmDeleteAssignmentAlert}>
								<Text size='medium' bold color='base'>
									Confirm
								</Text>
							</Button>
							<Button color='secondary' onClick={onCloseDeleteAssignmentAlert}>
								<Text size='medium' bold color='base'>
									Cancel
								</Text>
							</Button>
						</GridBox>
					</Alert.Footer>
				</Alert>
			)}
		</>
	);
}
