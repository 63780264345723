import React, {useEffect} from 'react';
import {SubjectType} from '@esgi/core/enums';
import BingoTile from 'shared/modules/bingo/components/tile/tile';
import {join, useStream} from '@esgillc/ui-kit/utils';
import {BingoService} from 'shared/modules/bingo/service/service';

import styles from './tests.module.less';

interface BingoTestsProps {
	service: BingoService;
	subjectType: SubjectType;
	subjectID: number;
}

export default function BingoTests ({service, subjectType, subjectID}: BingoTestsProps) {
	const {tests, selectedTest, loaded} = useStream(service.tests$, {
		tests: [],
		selectedTest: undefined,
		loaded: false,
	});

	useEffect(() => {
		service.getTests(subjectType, subjectID).subscribe();
	}, [loaded, subjectID, subjectType]);

	return (
		<div>
			{tests?.length > 0 && <div className={styles.testsPanel}>
				{tests?.map((x, key) => {
					return (
						<BingoTile
							key={key}
							color={x.color}
							value={x.name}
							className={join(styles.testTile, x.id === selectedTest.id && styles.selected)}
							onClick={() => service.setSelectedTest(x)}
						/>
					);
				})}
			</div>}
		</div>
	);
}
