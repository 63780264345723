import React from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {Level} from '../../../../types';
import styles from './level.module.less';

interface Props {
	level: Level;
	highest: boolean;
	hovered: boolean;
}

export default class LevelView extends React.PureComponent<Props> {
	public render() {
		return <div className={styles.box}>
			<div className={styles.header}>
				<span>Level {this.props.level.score} {this.props.highest && '(highest)' || ''}</span>
			</div>
			<div className={join(styles.level, this.props.hovered && styles.hovered)}>
				<span>{this.props.level.name}</span>
			</div>
		</div>;
	}
}
