import React, {useMemo} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {IconButton} from '../../../../icons/icon-button';

export function DeleteButton(props: {onClick: () => void, canDelete: boolean, subjectType: SubjectType}) {
	const tooltipText = useMemo(() => {
		if (!props.canDelete) {
			if (props.subjectType === SubjectType.Stock) {
				return 'This is a stock tab, Admins cannot delete it';
			} else {
				return 'You are not allowed to delete this tab';
			}
		}

		return 'Delete subject tab';
	}, [props.canDelete, props.subjectType]);

	return <OnHoverTooltip message={tooltipText}>
		<IconButton
			data-cy='remove-subject-button'
			disabled={!props.canDelete}
			onClick={(e) => {
				e.stopPropagation();
				props.onClick();
			}}>
			<svg xmlns='http://www.w3.org/2000/svg'
			     width='11'
			     height='13'
			     viewBox='0 0 12 15'
			     fill='none'>
				<path
					d='M1.16671 13.3333C1.16671 14.25 1.91671 15 2.83337 15H9.50004C10.4167 15 11.1667 14.25 11.1667 13.3333V3.33333H1.16671V13.3333ZM12 0.833333H9.08337L8.25004 0H4.08337L3.25004 0.833333H0.333374V2.5H12V0.833333Z'
					fill='#0088CC'/>
			</svg>
		</IconButton>
	</OnHoverTooltip>;
}
