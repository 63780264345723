import {BehaviorSubject, tap, of, catchError, EMPTY} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectType} from '@esgi/core/enums';
import {V2TeachersPagesReportsPieChartsController} from '@esgi/contracts/esgi';
import {Entity, Test} from '../types';

export class TestService extends BaseService {
	public loading$ = new BehaviorSubject(false);
	public tests$ = new BehaviorSubject<Test[]>([]);
	private readonly controller = new V2TeachersPagesReportsPieChartsController();

	public override dispose() {
		this.controller.dispose();
	}

	public getTests(subjects: SubjectTab[] | undefined, entity: Entity) {
		const {classId, groupId, studentId} = entity;
		if (!subjects || !(classId || groupId || studentId)) {
			return of();
		}
		this.loading$.next(true);
		const subjectsRequest = subjects.map(x => ({subjectID: x.id, subjectType: SubjectType[x.type]}));
		return this.controller.availableTests({
			subjects: subjectsRequest,
			classID: classId === -1 ? null : classId,
			groupID: groupId,
			studentIDs: studentId ? [studentId] : [],
		}).pipe(
			tap(({value: {subjectTests}}) => {
				const value = subjectTests
					.flatMap(({tests}) => tests)
					.filter(test => test.isTested)
					.map(({testID: id, name, type}) => ({id, name, type}));
				const filteredTests = value.filter((value, index, self) =>
					index === self.findIndex((t) => (
						t.id === value.id
					))
				);
				this.tests$.next(filteredTests);
				this.loading$.next(false);
			}),
			catchError(() => {
				this.tests$.next([]);
				this.loading$.next(false);
				return EMPTY;
			}),
		);
	}

	public get(testId: Test['id']) {
		return this.tests$.value.find(({id}) => id === testId);
	}
}
