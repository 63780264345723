import {Drawer} from '@esgi/main/kits/common';
import {
	PanelSidebar,
	PanelSidebarHeader,
	ToggleGroupContent,
	ToggleGroupItem,
	ToggleGroupTitle,
} from '../components/panels.styled';
import {UserCard} from '@esgi/ui/icons';
import {ToggleGroup} from '@esgi/ui/controls';
import {ItemId} from './types';
import {useMemo, useState} from 'react';
import {toggleGroupList} from './constants';
import {isNull} from 'underscore';
import {SchoolAndDistrictContent} from './components/school-and-district-content';
import {PersonalInformationMainAccountContent} from './components/personal-information-main-account-content';
import {SecondaryProfile, TrackModel, UserCountry, UserModel, UserState} from '../../../types';
import {MarkingPeriodsContent} from './components/marking-periods-content';
import {LoginContent} from '../components/login-content';
import {PersonalInformationContent} from './components/personal-information-content';
import {UserAccountService} from '../../../user-account-service';
import {Text} from '@esgi/ui/typography';
import {Tooltip} from '@esgi/ui';
import {IconWrapper} from './index.styled';

type Props = {
	dataService: UserAccountService;
	user: UserModel;
	countries: UserCountry[] | null;
	states: UserState[] | null;
	secondaryProfile: SecondaryProfile;
	activeUserTrackId: TrackModel['trackID'] | null;
	tracks: TrackModel[] | null;
};

export function SecondaryProfilePanel({
	dataService,
	user,
	countries,
	states,
	secondaryProfile,
	activeUserTrackId,
	tracks,
}: Props) {
	const visibleTrack = useMemo(() => {
		const activeTrack = tracks?.find(({trackID}) => trackID === activeUserTrackId) ?? tracks?.[0];

		return activeTrack ?? null;
	}, [activeUserTrackId, tracks]);

	const [activeTabId, setActiveTabId] = useState(ItemId.PersonalInformation);

	const getTabContent = () => {
		if (isNull(countries) || isNull(states)) {
			return null;
		}

		if (activeTabId === ItemId.SchoolAndDistrict) {
			return (
				<SchoolAndDistrictContent
					districtName={user.districtName}
					isLinkedToDistrict={user.isLinked}
					schoolName={user.schoolName}
				/>
			);
		}

		if (activeTabId === ItemId.PersonalInformationMainAcoount) {
			return (
				<PersonalInformationMainAccountContent
					firstName={user.firstName}
					lastName={user.lastName}
					email={user.email}
					nameTitle={user.title}
					countries={countries}
					states={states}
					countryId={user.countryID}
					stateId={user.stateID}
					expirationDate={new Date(user.expirationDate)}
				/>
			);
		}

		if (activeTabId === ItemId.MarkingPeriods && visibleTrack) {
			return <MarkingPeriodsContent currentUserTrack={visibleTrack} />;
		}

		if (activeTabId === ItemId.Login) {
			return <LoginContent userName={secondaryProfile.userName} email={secondaryProfile.email} />;
		}

		if (activeTabId === ItemId.PersonalInformation) {
			return (
				<PersonalInformationContent
					firstName={secondaryProfile.firstName}
					lastName={secondaryProfile.lastName}
					email={secondaryProfile.email}
					saveSecondaryProfileData={dataService.saveSecondaryProfileData.bind(dataService)}
				/>
			);
		}

		return null;
	};

	return (
		<Drawer.Panel>
			<PanelSidebar>
				<PanelSidebarHeader>
					<Tooltip delayDuration={400}>
						<Tooltip.Trigger>
							<IconWrapper>
								<UserCard />
							</IconWrapper>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small'>My Account</Text>
						</Tooltip.Content>
					</Tooltip>
					<Text size='small' color='neutral56'>
						My Account
					</Text>
				</PanelSidebarHeader>

				{toggleGroupList.map(({title, tabItems}, index) => (
					<ToggleGroup.Root
						orientation='vertical'
						onValueChange={(tabId) => setActiveTabId(tabId as ItemId)}
						value={activeTabId}
						key={index}
					>
						<ToggleGroupTitle>
							<Text size='small' font='mono' color='neutral56'>
								{title}
							</Text>
						</ToggleGroupTitle>
						<ToggleGroupContent>
							{tabItems.map(({label, value}) => (
								<ToggleGroupItem value={value} key={value} disabled={activeTabId === value} applyDisabledStyles={false}>
									<Text size='medium' bold color='base'>
										{label}
									</Text>
								</ToggleGroupItem>
							))}
						</ToggleGroupContent>
					</ToggleGroup.Root>
				))}
			</PanelSidebar>

			{getTabContent()}
		</Drawer.Panel>
	);
}
