import {PossibleTabID, useTabsContext} from '../features';
import {PropsWithChildren, useEffect, useState} from 'react';
import {Box} from '@esgi/ui/layout';
import {animated, useSpring, useTransition} from 'react-spring';

type Props<T extends PossibleTabID> = {
	tab: T,
	name: string,
	description: string,
	disabled: boolean,
} & PropsWithChildren;

export function TabContent<Tab extends PossibleTabID>(props: Props<Tab>) {
	const context = useTabsContext();

	const show = context.active === props.tab;

	const [hide, setHide] = useState(!show);

	useEffect(() => {
		context.add({
			id: props.tab,
			name: props.name,
			description: props.description,
			disabled: props.disabled,
		});

		return () => context.remove(props.tab);
	}, []);

	const styles = useSpring({
		opacity: show ? 1 : 0,
		// transform: show ? 'scale(1)' : 'scale(0.98)',
		delay: show ? 150 : 0,
		config: {
			duration: 200,
		},
		onStart: () => {
			if (show) {
				setHide(false);
			}
		},
		onRest: () => {
			if (!show) {
				setHide(true);
			}
		},
	});

	if (!hide) {
		return <Box style={{position: 'absolute', width: '100%', height: '100%', overflow: 'hidden', top: 0, left: 0}}>
			<animated.div style={{...styles, height: '100%'}}>{props.children}</animated.div>
		</Box>;
	}

	return <></>;
}