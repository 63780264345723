import {SectionBlock} from '../section-block';
import {EmptyBoxMessage} from '../empty-box-message';
import {GridBox} from '@esgi/ui/layout';
import {Dispatch} from 'react';
import {Test} from '../../types';
import {SelectedTestRow} from './components/selected-test-row';

type Props = {
	selectedTestsIDs: Test['id'][];
	allSelfAsessTests: Test[];
	onTestSwap: (idx1: number, idx2: number) => void;
	onRemoveTestByID: Dispatch<Test['id']>;
};

export function AssignmentSelectedTest({selectedTestsIDs, allSelfAsessTests, onTestSwap, onRemoveTestByID}: Props) {
	const getSectionContent = () => {
		if (!selectedTestsIDs.length) {
			return <EmptyBoxMessage message='Please select a test' />;
		}

		return (
			<GridBox gap='1'>
				{selectedTestsIDs.map((testID, index) => {
					const test = allSelfAsessTests.find(({id}) => testID === id);

					if (!test) {
						return null;
					}

					const {id, contentArea, name} = test;

					return (
						<SelectedTestRow
							key={id}
							id={id}
							contentArea={contentArea}
							name={name}
							moveUpDisabled={index === 0}
							onMoveUpClick={() => onTestSwap(index, index - 1)}
							moveDownDisabled={index === selectedTestsIDs.length - 1}
							onMoveDownClick={() => onTestSwap(index, index + 1)}
							onClearButtonClick={onRemoveTestByID}
						/>
					);
				})}
			</GridBox>
		);
	};

	return <SectionBlock dataCy='selected-tests-section' title='Selected Tests'>{getSectionContent()}</SectionBlock>;
}
