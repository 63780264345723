import {Observable, map, of, tap, BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {NotificationController} from '@esgi/contracts/esgi';
import {NotificationActiveResponse, NotificationType, SchoolYearModel} from './types';
import {getUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';
import {EventBusManager} from '@esgillc/events';
import {CurrentSchoolYearEvent} from 'modules/school-year';
/**
 * work with session storage should be the same with the legacy
 * @see /ESGI/Frontends/ESGI/packages/app/src/shared/alert/modal-alert/api/api.ts
 */

export class Service extends BaseService {
	// keep the name with the legacy version
	public schoolYear = new BehaviorSubject<SchoolYearModel | null>(null);

	private readonly notificationsSessionStorageKey = 'notifications';
	private readonly eventBusManager: EventBusManager = new EventBusManager();
	private controller = new NotificationController();
	private readonly currentUser = getUser();

	public init(): Observable<NotificationActiveResponse> {
		const existedNotificationsData = this.getNotificationsStoredData();

		this.eventBusManager.subscribe(CurrentSchoolYearEvent, (res) => this.schoolYear.next(res.schoolYear));

		if (!isNull(existedNotificationsData)) {
			return of(existedNotificationsData);
		}

		return this.controller.active().pipe(
			map(({notification}) => {
				const adaptedResponse: NotificationActiveResponse = {
					notification: {
						...notification,
						type: notification.type as NotificationType,
					},
				};

				return adaptedResponse;
			}),
			tap((response) => {
				this.setNotificationsStoredData(response);
			}),
		);
	}

	public dismiss(userNotificationID: number) {
		if (!isNull(this.currentUser)) {
			const notifications = this.getNotificationsStoredData();

			if (!isNull(notifications)) {
				this.setNotificationsStoredData({
					notification: null,
				});
			}

			return this.controller.dismiss({userID: this.currentUser.userID, userNotificationID});
		}

		return new Observable<void>();
	}

	public override dispose() {
		this.controller.dispose();
	}

	private getNotificationsStoredData() {
		const notifications = sessionStorage.getItem(this.notificationsSessionStorageKey);

		if (!isNull(notifications)) {
			return JSON.parse(notifications) as NotificationActiveResponse;
		}

		return null;
	}

	private setNotificationsStoredData(data: NotificationActiveResponse) {
		sessionStorage.setItem(this.notificationsSessionStorageKey, JSON.stringify(data));
	}
}
