import {CSS} from '@stitches/react';
import {styled} from '@esgi/ui/theme';
import {Skeletonable} from '../../../../types';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {useToggleGroupContext} from '../../context';
import {GridBox} from '@esgi/ui/layout';

type ToggleGroupContentProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
} & Skeletonable;

export const ToggleGroupContent = forwardRef<HTMLDivElement, ToggleGroupContentProps>(
	({dataCy, skeleton, ...props}, forwaredRef) => {
		const context = useToggleGroupContext();

		return (
			<Content
				data-cy={dataCy ?? 'ui-kit-toggle-group-content'}
				data-orientation={context.orientation}
				display='inlineGrid'
				flow='column'
				ref={forwaredRef}
				{...props}
			/>
		);
	},
);

const Content = styled(GridBox, {
	justifySelf: 'start',
	padding: 2,
	borderRadius: 6,
	backgroundColor: '$vivid',
	gap: 1,
	width: '100%',

	'&[data-orientation=vertical]': {
		gridAutoFlow: 'row',
	},
});
