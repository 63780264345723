import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EditableContentContainer = styled(GridBox, {
	gap: 20,
});

export const ProgressBarContainer = styled(GridBox, {
	justifySelf: 'center',
});
