import {ContextMenu} from '@esgi/ui';
import {Delete, Edit, Eye, More, Skip} from '@esgi/ui/icons';
import {ButtonIconable} from '../../../header/components/button-iconable.styled';
import {Text} from '@esgi/ui/typography';
import {ContextMenuItem} from './index.styled';
import {Dispatch, MouseEvent, useCallback, useState} from 'react';
import {MoveToAlert} from './components/move-to-alert';
import {SubjectTab} from '@esgi/main/libs/store';
import {Box} from '@esgi/ui/layout';
import {RemoveAlert} from './components/remove-alert';
import {TestGroupStudentsModel} from '@esgi/main/features/teacher/home';

type Props = {
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	openTestDetails: VoidFunction;
	testInfo: TestGroupStudentsModel['testInfo'];
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	subjectID: SubjectTab['id'];
};

export function CardContextMenu({
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	testInfo,
	onTestMoveTo,
	onRemoveTest,
	subjectID,
}: Props) {
	const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
	const [isMoveToAlertOpened, setIsMoveToAlertOpened] = useState(false);
	const [isRemoveAlertOpened, setIsRemoveAlertOpened] = useState(false);

	const openMoveToAlert = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		setIsContextMenuOpened(false);
		setIsMoveToAlertOpened(true);
	}, []);

	const closeMoveToAlert = useCallback(() => {
		setIsMoveToAlertOpened(false);
	}, []);

	const openRemoveAlert = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		setIsContextMenuOpened(false);
		setIsRemoveAlertOpened(true);
	}, []);

	const closeRemoveAlert = useCallback(() => {
		setIsRemoveAlertOpened(false);
	}, []);

	return (
		<>
			<ContextMenu.Root open={isContextMenuOpened} onOpenChange={setIsContextMenuOpened}>
				<ContextMenu.Trigger dataCy='card-menu-trigger'>
					<ButtonIconable withBackgroundHover>
						<More />
					</ButtonIconable>
				</ContextMenu.Trigger>
				<ContextMenu.Content>
					<ContextMenu.Group>
						{userCanRemoveTest && <>
							<ContextMenuItem onClick={openMoveToAlert}>
							<Skip />
							<Text size='medium'>Move to...</Text>
						</ContextMenuItem>
						<ContextMenuItem onClick={openRemoveAlert}>
							<Delete />
							<Text size='medium'>Remove...</Text>
						</ContextMenuItem>
						</>}
						<ContextMenuItem
							onClick={(e) => {
								e.stopPropagation();
								openTestDetails();
							}}
						>
							{userCanEditTest ? <Edit /> : <Eye />}
							<Text size='medium'>{userCanEditTest ? 'Edit' : 'View'} Test</Text>
						</ContextMenuItem>
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>

			{(isMoveToAlertOpened || isRemoveAlertOpened) && (
				<Box
					onClick={(event) => event.stopPropagation()}
					css={{
						width: 0,
						height: 0,
						position: 'absolute',
					}}
				>
					{isMoveToAlertOpened && (
						<MoveToAlert
							onClose={closeMoveToAlert}
							subjectID={subjectID}
							testInfo={testInfo}
							onSave={onTestMoveTo}
						/>
					)}
					{isRemoveAlertOpened && (
						<RemoveAlert
							onClose={closeRemoveAlert}
							subjectID={subjectID}
							testInfo={testInfo}
							onConfirm={onRemoveTest}
						/>
					)}
				</Box>
			)}
		</>
	);
}
