import * as React from 'react';
import type {SVGProps} from 'react';

export function ExpandFullAlt(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.5 8.25C16.5 7.83579 16.8358 7.5 17.25 7.5C17.6642 7.5 18 7.83579 18 8.25V15.75C18 16.1642 17.6642 16.5 17.25 16.5C16.8358 16.5 16.5 16.1642 16.5 15.75V8.25Z'
				fill='#333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.21447 12.5147C5.92851 12.2304 5.92851 11.7696 6.21447 11.4853L10 7.7132C10.286 7.42893 10.7496 7.42893 11.0355 7.7132C11.3215 7.99747 11.3215 8.45837 11.0355 8.74264L7.76777 12L11.0355 15.2574C11.3215 15.5416 11.3215 16.0025 11.0355 16.2868C10.7496 16.5711 10.286 16.5711 10 16.2868L6.21447 12.5147Z'
				fill='#333'
			/>
		</svg>
	);
}
