import {OutModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/student-explorer/get-data/models/out-model';

export type {SpecialistModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/student-explorer/get-data/models/specialist-model';
export type {StudentModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/student-explorer/get-data/models/student-model';
export type {TeacherModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/student-explorer/get-data/models/teacher-model';
export type {ClassModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/student-explorer/get-data/models/class-model';
export type {GroupModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/student-explorer/get-data/models/group-model';
export type {SchoolModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/student-explorer/get-data/models/school-model';
export {UnassignType} from '@esgi/contracts/esgi/types/esgi.students/commands/esgi.apigateway/student-explorer/unassign/unassign-type';

export type InitResponse = OutModel;

export enum StudentSort {
	FirstName = 'FirstName',
	LastName = 'LastName',
}

export type GradeLevelModel = {
	gradeLevelID: number,
	name: string,
}

export type Filter = {
	keyword: string,
	teacherID: number,
	classID: number,
	groupID: number,
	gradeLevelID: number,
}

export type StudentExportModel = {
	name: string,
	primaryTeacher: string,
	classes: string,
	groups: string,
	grade: string,
	id: string,
	created: string,
}

export type UpdateFilter = (value: Partial<Filter>) => void;