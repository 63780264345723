import {Loader} from '@esgillc/ui-kit/loader';
import React, {useState} from 'react';
import {finalize} from 'rxjs/operators';
import styles from './styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import {ReportService} from '../../../../services/report-service';
import {MenuForAllStudents} from './menu-for-all-students';
import {MenuForOneStudent} from './menu-for-one-student';

interface Props {
	service: ReportService
	onGoBack: () => void;
	onClose: () => void;
}

export function SettingsModalHeader({onGoBack, onClose, service}: Props) {
	const report = useBehaviorSubject(service.dataService.report);
	const displayDownloadLink = report != null && report.students.length > 0;
	const [showMenu, setShowMenu] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const selectedStudent = useBehaviorSubject(service.settingsService.selectedStudent)[0];

	const download = (zip: boolean) => {
		setShowLoader(true);
		setShowMenu(false);
		try {
			service.settingsService.downloadPdf(zip).pipe(finalize(() => setShowLoader(false))).subscribe();
		} catch (e) {
			setShowLoader(false);
		}
	};

	const closeMenu = () => setShowMenu(false);

	return <div className={styles.sdrReportHeader}>
		<Loader show={showLoader} fullscreen/>
		<span className={styles.btnBack} onClick={onGoBack}>
			<i className={join(styles.fa, styles.faAngleLeft, 'fa', 'fa-angle-left')}/>
			<span>Back</span>
		</span>
		<div className={styles.reportName}>Student Detail Report</div>
		<div className={styles.printDownloadButtons}>
			{displayDownloadLink && <div className={join(styles.download, 'btn-group')}>
				<div onClick={() => setShowMenu(!showMenu)}>
					<i className={join(styles.fa, 'fa', 'fa-download')}/> Download
				</div>
				{selectedStudent.studentID === 0 &&
					<MenuForAllStudents showMenu={showMenu} download={download} closeMenu={closeMenu} />}
				{selectedStudent.studentID !== 0 &&
					<MenuForOneStudent showMenu={showMenu} download={download} closeMenu={closeMenu} />}
			</div>}
			<CloseIcon onClick={onClose} color='white'/>
		</div>
	</div>;
}
