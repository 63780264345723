import {AssignmentState} from '@esgi/main/kits/assignments';
import {Assignment} from '../../../types';
import {noTestsMessage} from './constants';
import {TableTests, TableTestsRowData, TableTestsHeader} from '../table-tests';
import {EmptyContent} from '../empty-content';
import {CommonAssignmentProps} from './types';
import {AssignmentHeader} from './components/assignment-header';
import {AssignmentBody} from './components/assignment-body';
import {useCallback} from 'react';

type Props = CommonAssignmentProps & {
	onAssignmentComplete: (args: {assignmentId: Assignment['id']; assignmentName: string}) => void;
};

export function AssignmentPublished({
	assignment,
	onAssignmentComplete,
	studentsWithoutAccess,
	students,
	sortStudentsByKey,
	onDeleteAssignment,
	onOpenExportCredentialsAlert,
	onOpenStudentCredentials,
	onOpenCredentialsForStudentsInAssignment,
	disabledOpenExportStudentsCredentials,
}: Props) {
	const testsCount = assignment.tests.length;

	const getTableTests = useCallback(() => {
		if (!testsCount) {
			return <EmptyContent messageText={noTestsMessage} />;
		}

		return (
			<TableTests>
				<TableTestsHeader studentsCount={students.length} />

				{assignment.tests.map((test) => (
					<TableTestsRowData {...test} studentsCount={students.length} key={test.id} />
				))}
			</TableTests>
		);
	}, [testsCount, students, assignment]);

	return (
		<>
			<AssignmentHeader
				studentsWithoutAccessCount={studentsWithoutAccess.length}
				hasTestsInAssignment={Boolean(assignment.tests.length)}
				onDeleteAssignment={() => onDeleteAssignment({assignmentId: assignment.id, assignmentName: assignment.name})}
				disableEditDashboardButton
				withActionButton
				actionButtonLabel='Complete'
				onActionButtonClick={() =>
					onAssignmentComplete({
						assignmentId: assignment.id,
						assignmentName: assignment.name,
					})
				}
				onOpenExportCredentialsAlert={onOpenExportCredentialsAlert}
				disabledOpenExportStudentsCredentials={disabledOpenExportStudentsCredentials}
			/>
			<AssignmentBody
				name={assignment.name}
				state={AssignmentState.Published}
				description={assignment.description}
				testsCount={testsCount}
				displayedTime={assignment.posted}
				displayedTimeType='Posted'
				sortStudentsByKey={sortStudentsByKey}
				students={students}
				onOpenStudentCredentials={onOpenStudentCredentials}
				onOpenCredentialsForStudentsInAssignment={onOpenCredentialsForStudentsInAssignment}
			>
				{getTableTests()}
			</AssignmentBody>
		</>
	);
}
