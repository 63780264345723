import {useMemo} from 'react';
import {DeltaIndicator} from './delta-indicator';
import {ProgressWithPointer} from './progress-with-pointer';
import {AnswersInfo} from '../../answers-info';
import {ContentWrapper, ProgressBarInfo, ProgressBarTranscriptWrapper} from './progress-bars.styled';
import {Text} from '@esgi/ui/typography';
import {TestType} from '@esgi/main/libs/core';
import {GridProgressBar} from '@esgi/ui';
import {TestSingleStudentStudentResult, RubricCriteria} from '../../../../../types';
import {isNull} from 'underscore';
import {useTestSectionContext} from '../../../context/test-section-context';

type Props = {
	testType: TestType;
	value: number | null;
	maxValue: number;
	deltaIndicatorValue: number | null;
	result: number | null;
	dataCy?: string;
	rubricAnswers: TestSingleStudentStudentResult['lastTestSessionRubricAnswers'];
	rubricCriteria: RubricCriteria[];
	isSmallScreen: boolean;
};

export function LastSessionInfo({
	testType,
	value,
	maxValue,
	deltaIndicatorValue,
	rubricAnswers = [],
	rubricCriteria,
	isSmallScreen,
	result,
}: Props) {
	const {testResultsCorrectVerbiage} = useTestSectionContext();

	const progress = useMemo(() => {
		if (testType === TestType.Rubric) {
			const gridProgressItems = rubricAnswers.length
				? rubricAnswers?.map((answer) => ({value: answer.score, maxValue: Math.round(maxValue / rubricAnswers.length)}))
				: rubricCriteria.map((item) => ({value: 0, maxValue: Math.round(maxValue / rubricCriteria.length)}));
			return <GridProgressBar boxSize={48} items={gridProgressItems} />;
		}

		return <ProgressWithPointer value={value} maxValue={maxValue} isPercentageType result={result} />;
	}, [testType, value, maxValue, result, rubricAnswers, rubricCriteria]);

	const answersInfoCaption = useMemo(() => {
		switch (testType) {
			case TestType.Rubric:
				return 'Last Score';
			case TestType.YN:
				return `${testResultsCorrectVerbiage} answers`;
			case TestType.Score:
				return 'Last Score';

			default:
				return null;
		}
	}, [testType, testResultsCorrectVerbiage]);

	return (
		<ContentWrapper dataCy='last-session-info'>
			<ProgressBarInfo>
				{progress}
				{!isSmallScreen && (
					<ProgressBarTranscriptWrapper>
						{!isNull(answersInfoCaption) && (
							<AnswersInfo
								value={value}
								maxValue={maxValue}
								caption={answersInfoCaption}
							/>
						)}
						<DeltaIndicator
							value={deltaIndicatorValue}
							caption='Since Last Test'
							withPersantageSign={testType !== TestType.Rubric}
							dataCy='since-last-test-delta'
						/>
					</ProgressBarTranscriptWrapper>
				)}
			</ProgressBarInfo>
			<Text size='small' font='mono' color='neutral56' data-cy='last-session-info-label'>
				{`Last Session${!isSmallScreen ? ' Result' : ''}`}
			</Text>
		</ContentWrapper>
	);
}
