import React, {useState} from 'react';
import {ArrowIcon as KitArrow} from '@esgillc/ui-kit/icons';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Arrow, ArrowContainer} from './index.styled';
import {SortOptions} from '../../../types';

type Props = {
	onClick: VoidFunction;
	cellValue: string;
	sorted: boolean;
	sortOptions: SortOptions;
	previousSortedColumnIndex: number;
}

export const ArrowIcon = ({onClick, cellValue, sorted, sortOptions, previousSortedColumnIndex}: Props) => {

	const [rotated, setRotate] = useState(false);

	const handleClick = () => {
		if (previousSortedColumnIndex === sortOptions.columnIndex) {
			if (sortOptions.direction === 'asc') {
				setRotate(true);
			} else {
				setRotate(false);
			}
		}
		onClick?.();
	};

	return (
		<ArrowContainer>
			<GridBox flow='column' align='center' justify='center'>
				<Text size='large'>{cellValue}</Text>
					<Arrow
						rotated={rotated}
						onClick={handleClick}
						visible={sorted}
					>
						<KitArrow fill='black'/>
					</Arrow>
			</GridBox>
		</ArrowContainer>
	);
};