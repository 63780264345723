/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import UseReCaptcha from './use-recaptcha';

export function withRecaptcha<Props>(Component: React.ComponentType<Props>) {
	return (props: Props) => {
		const {reCaptchaLoaded, generateReCaptchaToken} = UseReCaptcha();

		return <Component reCaptchaLoaded={reCaptchaLoaded} generateReCaptchaToken={generateReCaptchaToken} {...props} />;
	};
}
