export function PromoCodeTemplate() {
	return <div>
		<ko data-bind='with: promoCodeForm'>
			<div className='activation-code'>
				<ko data-bind='with: promoCode '>
					<div className='form-group promo'
					     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : $parent.hasError}, afterRender: true">
						<div>
							<input id='promo-code' name='promo-code' type='text' className='form-control input-md'
							       data-bind='value: value' placeholder='Promo Code:'/>
							<ko data-bind='if: showSuccessValidation() && valid() '>
								<i className='fa fa-check form-control-feedback'></i>
							</ko>
							<ko data-bind='if: $parent.hasError'>
								<i className='fa fa-close form-control-feedback'></i>
							</ko>
							<ko data-bind='if: showValidationInProgress() '>
								<i className='fa form-control-feedback'>
									<img src='img/ajax-loader.gif'/>
								</i>
							</ko>
						</div>
					</div>
				</ko>
			</div>
		</ko>
		<div className='clearfix' style={{marginTop: '25px'}}>
			<ko data-bind='render: cancelButton'>
			</ko>

			<ko data-bind='render: submitButton'>
			</ko>
		</div>
	</div>
}
