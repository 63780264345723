import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const LanguageAndTemplatesWrapper = styled(FlexBox, {
	height: '100vh',
});

export const SubSideBarWrapper = styled(Box, {
	'> div': {
		width: '100%',
		height: '100vh',
	},
});

export const DrawerPanelContent = styled(FlexBox, {
	width: '100%',
});

export const FormElementWrapper = styled(Box, {
	'> div': {
		margin: '0px',
	},
});
