import {useEffect, useRef, useState} from 'react';
import {HierarchySnapshot} from '../../hierarchy/core/models';
import {SubjectType, TestType} from '@esgi/core/enums';
import {Modal, useModal} from '@esgillc/ui-kit/modal';
import {ModalHeader} from './components/modal-header';
import {TestHistoryRootService} from './services/test-history-root-service';
import {View} from './components/view';
import {SsoTracker} from '@esgi/core/tracker';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {NoGraphAlert} from './components/no-graph-alert';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useServiceFactory} from '@esgi/core/service';
import styles from './styles.module.less';

interface Props {
	hierarchy: HierarchySnapshot,
	subjectID: number,
	subjectType: SubjectType,
	testId: number,
	studentId?: number
	onClose: () => void;
	testType: TestType,
	withoutSettings?: boolean,
}

export default function TestHistory({onClose, testId, studentId, subjectID, subjectType, hierarchy, testType, withoutSettings = false}: Props) {
	const service = useServiceFactory(() => new TestHistoryRootService(
		hierarchy,
		subjectID,
		subjectType,
		testId,
		testType,
		studentId));
	const modalManagerRef = useModal();
	const showNoGraphAlert = useBehaviorSubject(service.headerService.showNoGraphAlert);
	const onModalClose = () => {
		modalManagerRef.current.close(onClose);
	};
	const chartRef = useRef<HTMLDivElement>();
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: 'HistoryReport',
		});
		service.init().subscribe({next: () => setInitialized(true), error: () => onClose()});

		return () => {
			service.testHistoryService.destroy();
			service.headerService.destroy();
			service.destroy();
		};
	}, []);
	return <>
		<NoGraphAlert onClosed={() => service.headerService.showNoGraphAlert.next(false)} show={showNoGraphAlert} />
		{initialized && <Modal modalManagerRef={modalManagerRef}>
			<ModalHeader onClose={onModalClose} service={service.headerService} chartRef={chartRef}/>
			<Modal.Body className={styles.modalBody}>
				<View testId={testId} subjectID={subjectID} subjectType={subjectType} hierarchy={hierarchy}
				      testType={testType} service={service} chartRef={chartRef} withoutSettings={withoutSettings}/>
			</Modal.Body>
		</Modal>}
		<ServiceLoader trackingService={service} fullscreen/>
		<ServiceLoader trackingService={service.headerService} fullscreen/>
	</>;
}
