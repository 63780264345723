import {GroupResponse} from './types';
import {EntityModel, StudentResponse} from '../../shared/types';

export function studentsByGroupSpecification(item: StudentResponse, isSpecialist: boolean, selectedGroupId: number): boolean {
	return (isSpecialist || selectedGroupId === 0)
		? true
		: item.groups.includes(selectedGroupId);
}

export function studentsBySpecialistGroupSpecification(item: StudentResponse, isSpecialist: boolean, selectedGroupId: number): boolean {
	return (!isSpecialist || selectedGroupId === 0)
		? true
		: item.specialistGroups.includes(selectedGroupId);
}

export function studentsByClassSpecification(item: StudentResponse, isSpecialist: boolean, classId: number): boolean {
	return isSpecialist
		? true
		: item.classes.includes(classId);
}

export function studentsSpecification(item: StudentResponse, isSpecialist: boolean, classId: number, groupId?: number): boolean {
	if (groupId === null) {
		return studentsByClassSpecification(item, isSpecialist, classId);
	}
	return studentsByClassSpecification(item, isSpecialist, classId) &&
		   studentsByGroupSpecification(item, isSpecialist, groupId) &&
		   studentsBySpecialistGroupSpecification(item, isSpecialist, groupId);
}

export function getFullName(item: StudentResponse) {
	return `${item.firstName} ${item.lastName}`;
}

export function filterGroups(groups: GroupResponse[], classId: number) {
	return groups.filter(x => x.classID === classId);
}

export function filterStudents(students: StudentResponse[], isSpecialist: boolean, classId: number, groupId: number) {
	return students.filter(student => studentsSpecification(student, isSpecialist, classId, groupId));
}

export function firstOrDefault(array: EntityModel[], id: number): EntityModel {
	return array?.find(x => x.id === id) ?? array?.[0];
}

export function normalizeGroupId(array: EntityModel[], id: number): number {
	return id !== 0 || array.length == 1 ? firstOrDefault(array, id)?.id : 0;
}

export function normalizeStudentIds(students: StudentResponse[], studentIds: number[], isSpecialist: boolean, classId: number, groupId: number) {
	return students
		.filter(student =>
			studentIds?.includes(student.id) &&
			studentsSpecification(student, isSpecialist, classId, groupId))
		.map(x => x.id);
}
