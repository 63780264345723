import React from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import './answer.less';
import {userStorage, UserType} from '@esgi/core/authentication';
import {NoteEditor, State as NoteEditorState} from './note-editor';
import {Checkbox} from '@esgi/deprecated/elements/checkbox';
import {Models} from '../models';

export class State {
    selected: boolean;
    note: NoteEditorState = new NoteEditorState();
}

export class Props extends SharedProps<State> {
    answer: Models.Answer;
    notTested: boolean;
    editMode: boolean;
    selected: boolean;
    saveNoteHandler: (comment: string) => void;
    deleteNoteHandler: () => void;
    studentName: string;
    testName: string;
    readOnly: boolean;
}

export class Answer extends SharedComponent<State, Props> {
    private allowedToAddNote: boolean;

    constructor(props?: Props) {
    	super(props);
	    const currentUser = userStorage.get();

	    this.allowedToAddNote = currentUser.userType === UserType.T &&
            !this.props.notTested &&
            !this.props.answer.comment;

    	this.state = new State();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    get css() {
    	let css = 'answer answer-' + this.props.answer.id + '-row';
    	if (this.props.answer.inherited) {
    		css += ' inherited';
    	}

    	if (this.state && this.props.selected) {
    		css += ' selected';
    	}

    	if (this.props.answer.comment) {
    		css += ' exist-comment';
    	}

    	return css;
    }

    private selectChanged(selected: boolean) {
    	this.setState({selected: selected});
    }

    render() {
    	return <div className={this.css}>
            <div>
                {this.props.editMode &&
                    <Checkbox
                        id={this.props.answer.questionId.toString()}
                        checked={this.props.selected}
                        onClick={(value: boolean) => this.selectChanged(value)}
                    />
                }

                <span className='question-text' title={this.props.answer.text}>{this.props.answer.text}</span>
            </div>

            {this.props.answer.sessionId && (!this.props.readOnly && !this.props.notTested && !this.props.editMode) &&
                <div className='right'>
                    <NoteEditor
                        state={this.state.note}
                        onChange={(ch, cb) => this.setState({note: ch}, cb)}
                        studentName={this.props.studentName}
                        testName={this.props.testName}
                        questionText={this.props.answer.text}
                        date={this.props.answer.testDate}
                        note={this.props.answer.comment}
                        readOnly={false}
                        saveHandler={(noteText: string) => this.props.saveNoteHandler(noteText)}
                        deleteHandler={this.props.deleteNoteHandler}
                    />

                </div>
            }
        </div>;
    }
}
