import {useMemo, useCallback, useState} from 'react';
import {
	Table,
	TableBody,
	TableColumn,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	useSortableColumns,
} from '@esgillc/ui-kit/table';
import {AssignmentModel} from '../../../types/types';
import {AssignmentStatus} from '../../../../../typings/assignment';
import {join} from '@esgillc/ui-kit/utils';
import {DragIcon} from '@esgillc/ui-kit/icons';
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';
import styles from '../styles.module.less';
import {ProgressBodyCell} from './progress-body-cell';
import {getUniqValuesInData} from 'pages/assignment-center/utils/get-uniq-values-in-data';

type Props = {
	assignment: AssignmentModel;
	onOrder: (testIds: number[]) => void;
	studentsCount: number;
};

export function TestsTable({assignment, onOrder, studentsCount}: Props) {
	const {tests, state} = assignment;
	const [rows, setRows] = useState(tests);
	const {data, sortableColumns} = useSortableColumns({
		data: rows,
		columns: {
			name: true,
			subjectName: true,
			progress: true,
		},
		activeSortedKey: false,
	});
	const isSortable = useMemo(
		() => [AssignmentStatus.Draft, AssignmentStatus.NotStarted].includes(state) && data.length > 1,
		[state, data],
	);

	const {isSubjectTabSortableDisable, isProgressSortableDisable} = useMemo(() => {
		const {subjectName, progress} = getUniqValuesInData({
			data,
			keys: ['subjectName', 'progress'],
		});

		return {
			isSubjectTabSortableDisable: subjectName.size <= 1,
			isProgressSortableDisable: progress.size <= 1,
		};
	}, [data]);

	const onDragEnd = useCallback(
		({source, destination}: DropResult) => {
			if (!destination || source.index === destination.index) {
				return;
			}
			const copy = Array.from(rows);
			const [item] = copy.splice(source.index, 1);

			if (item) {
				copy.splice(destination.index, 0, item);
			}

			onOrder(copy.map(({testID: id}) => id));
			setRows(copy);
		},
		[data],
	);

	if (!sortableColumns.name || !sortableColumns.subjectName || !sortableColumns.progress) {
		return null;
	}

	return (
		<Table>
			<TableHeader bordered className={styles.tableHeader}>
				<TableHeaderColumn.Sortable
					className={join(
						styles.headerCell,
						styles.nameColumn,
						styles.nameColumnTest,
						isSortable && state !== AssignmentStatus.Published && styles.nameColumnSortable,
					)}
					{...sortableColumns.name}
					disableSorting={data.length <= 1 || isSortable || state === AssignmentStatus.Published}
				>
					Test Name
				</TableHeaderColumn.Sortable>
				<TableHeaderColumn.Sortable
					className={join(styles.headerCell, styles.xxl)}
					{...sortableColumns.subjectName}
					disableSorting={isSubjectTabSortableDisable || isSortable || state === AssignmentStatus.Published}
				>
					Subject Tab
				</TableHeaderColumn.Sortable>
				<TableHeaderColumn.Sortable
					className={join(styles.headerCell, styles.progressColumn)}
					{...sortableColumns.progress}
					disableSorting={isProgressSortableDisable || isSortable || state === AssignmentStatus.Published}
				>
					Progress
				</TableHeaderColumn.Sortable>
			</TableHeader>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='tests'>
					{(provided) => (
						<TableBody ref={provided.innerRef} {...provided.droppableProps}>
							{data.map((item, index) => (
								<Draggable
									draggableId={item.testID.toString()}
									isDragDisabled={!isSortable}
									index={index}
									key={item.testID}
								>
									{(provided) => (
										<TableRow
											ref={provided.innerRef}
											{...provided.draggableProps}
											className={styles.row}
											data-id={item.testID}
										>
											{isSortable && (
												<TableColumn className={styles.dragColumn}>
													<span className={styles.dragButton} {...provided.dragHandleProps}>
														<DragIcon fill='#424242' />
													</span>
												</TableColumn>
											)}
											<TableColumn className={join(styles.name, styles.nameColumn, styles.nameColumnTest)}>
												<span>{item.name}</span>
											</TableColumn>
											<TableColumn className={join(styles.headerCell, styles.xxl)}>{item.subjectName}</TableColumn>
											<TableColumn className={join(styles.headerCell, styles.progressColumn)}>
												<ProgressBodyCell testCompletion={item.progress} testsCount={studentsCount} />
											</TableColumn>
										</TableRow>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</TableBody>
					)}
				</Droppable>
			</DragDropContext>
		</Table>
	);
}
