import React from 'react';
import SubjectDetails from 'modules/subject-details';
import {SsoTracker} from '@esgi/core/tracker';
import styles from './create-subject.module.less';

class State {
	subjectCreatorOpened: boolean = false;
}

export default class CreateSubject extends React.Component<{}, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

	render() {
		return <div className={styles.tab}>
			<a href='#' onClick={() => this.openSubjectEditor()} className={`${styles.text} subject-create-link`}>
				Add Subject Tab
			</a>
			{this.renderSubjectEditor()}
		</div>;
	}

	private openSubjectEditor() {
		this.setState({subjectCreatorOpened: true});
	}

	private renderSubjectEditor() {
		if (!this.state.subjectCreatorOpened) {
			return null;
		}

		return <SubjectDetails selectAfterSave
		                       onClosed={() => this.setState({subjectCreatorOpened: false})}
		                       onSaved={(id: number) => {
			                       this.setState({subjectCreatorOpened: false}, () => SsoTracker.trackEvent({
				                       trackingEvent: 'AddSubjectHomepage',
				                       data: {subjectId: id},
			                       }));
		                       }}/>;
	}
}
