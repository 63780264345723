import {useCallback} from 'react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {GroupModel} from '../../../types';
import {SelectField} from './index.styled';

type Props = {
	selected: number,
	groups: GroupModel[],
	skeleton?: boolean,
	disabled: boolean,
	onChange: (value: number) => void,
}

export function GroupSelect(props: Props) {
	const onChange = useCallback((value: string) => props.onChange(Number(value)), [props.onChange]);

	return <Select.Root value={props.selected.toString()} onValueChange={onChange} dataCy='group-select'>
		<SelectField placeholder='Group' skeleton={props.skeleton} disabled={props.disabled}/>
		<Select.Content>
			<Select.Option value='0'><Text bold size='medium'>All</Text></Select.Option>
			{props.groups.map((i, index) => <Select.Option value={i.groupID.toString()} key={i.groupID}>
				<Text bold size='medium'>{i.name}</Text>
			</Select.Option>)}
		</Select.Content>
	</Select.Root>;
}
