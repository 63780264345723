import React, {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type SelectContextValue = {
	/** When true, prevents the user from interacting with select. */
	disabled: boolean | undefined;

	/** Select error message. */
	errorMessage: string | undefined;

	/** Additional select item. */
	showAdditionalItem: boolean | undefined;

	/** Additional item click handling. */
	onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const SelectContext = createContext<SelectContextValue>({
	disabled: undefined,
	showAdditionalItem: false,
	errorMessage: undefined,
	onClick: noop,
});

export function useSelectContext() {
	return useContext(SelectContext);
}
