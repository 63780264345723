import {GridBox} from '@esgi/ui/layout';
import {Divider, SelectableListItem} from '../index.styled';
import {Text} from '@esgi/ui/typography';
import {OptionItem, ReadMoreButton} from '@esgi/main/features/standards-drawer';

type Props = Pick<OptionItem, 'value' | 'description' | 'label'>;

export function SeriesSelectItem({
	value,
	label,
	description,
}: Props) {
	return (
		<SelectableListItem
			value={String(value)}
			key={value}
			variant='outlined'
		>
			<GridBox gap={2}>
				<Text data-cy='series-name' size='large' bold>{label}</Text>
				<Divider />
				<ReadMoreButton dataCy='series-description' description={description} />
			</GridBox>
		</SelectableListItem>
	);
}