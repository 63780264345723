import React from 'react';
import {EventBusManager} from '@esgillc/events';
import {FilterType} from '../../../enums';
import {TestExplorerBackEvent} from '../../../events';
import {DataService} from '../../../services/data-service';
import {SearchHintEvents} from '../../header/search-hint/search-hint';
import {AuthorClicked} from '../../noted-author/events';
import {EmptyResultsEvents} from '../../search-results/empty-results';
import {SearchEvents} from '../../search/events';
import {BoxItem} from '../box-item/box-item';
import {Box} from '../box/box';
import {userStorage} from '@esgi/core/authentication';
import {UserSettingsChangedEvent} from 'shared/modules/user-settings/events';
import {ClearAllEvent, ScopeChangedEvent} from '../events';

class State {
	value: string = '';
	showSelfAssessBox: boolean;
}

class Props {
	dataService: DataService;
}

export class Scope extends React.Component<Props, State> {
	private readonly eventBus = new EventBusManager();
	private readonly currentUser = userStorage.get();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	componentWillUnmount() {
		this.eventBus.destroy();
	}

	render() {
		return <Box initiallyOpened={true}
		            className='scope'
		            canBeCleared={false}
		            loading={false} collapsible={false}
		            filterType={FilterType.Scope}>
			<BoxItem
				selected={this.state.value === 'AllTests'}
				title='All Tests'
				onSelect={() => this.changed('AllTests')}
			/>
			<BoxItem
				selected={this.state.value === 'MyTests'}
				title='My Tests'
				onSelect={() => this.changed('MyTests')}
			/>
			<BoxItem
				selected={this.state.value === 'DistrictTests'}
				title='District Tests'
				onSelect={() => this.changed('DistrictTests')}
			/>
			<BoxItem
				selected={this.state.value === 'DraftTests'}
				title='My Drafts'
				onSelect={() => this.changed('DraftTests')}
			/>
			{this.state.showSelfAssessBox && <BoxItem
				selected={this.state.value === 'SelfAssessTests'}
				title='Self-Assess'
				onSelect={() => this.changed('SelfAssessTests')}
			/>}
		</Box>;
	}

	changed(value: string) {
		if (this.state.value !== value) {
			this.setState({value: value}, () => {
				this.props.dataService.filter.update({scope: value});
			});
		}
	}

	componentDidMount(): void {
		this.eventBus.subscribe(AuthorClicked, () => {
			this.changed('AllTests');
		});

		this.eventBus.subscribe(ClearAllEvent, () => {
			this.changed('AllTests');
		});

		this.eventBus.subscribe(SearchEvents.KeywordChanged, () => {
			if (!this.state.value) {
				this.setState({value: 'AllTests'});
			}
		});

		this.eventBus.subscribe(TestExplorerBackEvent, () => {
			this.setState({value: null});
		});

		this.eventBus.subscribe(SearchHintEvents.Clicked, () => {
			this.changed('AllTests');
		});

		this.eventBus.subscribe(EmptyResultsEvents.Clicked, () => {
			this.changed('AllTests');
		});

		this.eventBus.subscribe(ScopeChangedEvent, () => {
			if (!this.state.value) {
				this.setState({value: 'AllTests'});
			}
		});

		this.eventBus.subscribe(UserSettingsChangedEvent, (args: UserSettingsChangedEvent) => {
			if (args.showSelfAssessOption !== this.state.showSelfAssessBox) {
				this.setState({showSelfAssessBox: args.showSelfAssessOption});
			}
		});

		this.setState({showSelfAssessBox: this.currentUser.showSelfAssessOption});
	}

}
