import {PreAssessHierarchyLevel} from '../enums';
import {SelectedModel} from '../selected-model';
import {PreAssessHierarchySnapshot} from '../snapshot/preassess-hierarchy-snapshot';

export class PreAssessHierarchyInstance {
  public districtID: number;
  public userID: number;
  public groupID: number;
  public studentID: number;
  public get selected(): SelectedModel<PreAssessHierarchyLevel> {
    if (this.studentID !== 0) {
      return {level: PreAssessHierarchyLevel.Student, levelID: this.studentID};
    }
    if (this.groupID !== 0) {
      return {level: PreAssessHierarchyLevel.Group, levelID: this.groupID};
    }
    if (this.userID !== 0) {
      return {level: PreAssessHierarchyLevel.User, levelID: this.userID};
    }
    if (this.districtID !== 0) {
      return {level: PreAssessHierarchyLevel.District, levelID: this.districtID};
    }

    return {level: PreAssessHierarchyLevel.None, levelID: 0};
  }

  public static build(model: PreAssessHierarchySnapshot): PreAssessHierarchyInstance {
    const m = new PreAssessHierarchyInstance();
    m.districtID = model.districtID;
    m.userID = model.userID;
    m.groupID = model.groupID;
    m.studentID = model.studentID;
    return m;
  }

  public copy(): PreAssessHierarchyInstance {
    const m = new PreAssessHierarchyInstance();
    m.districtID = this.districtID;
    m.userID = this.userID;
    m.groupID = this.groupID;
    m.studentID = this.studentID;
    return m;
  }

  public equal(outer: PreAssessHierarchyInstance): boolean {
    if (this.districtID !== outer.districtID) {
      return false;
    }
    if (this.userID !== outer.userID) {
      return false;
    }
    if (this.groupID !== outer.groupID) {
      return false;
    }
    if (this.studentID !== outer.studentID) {
      return false;
    }

    return true;
  }

  public extract(): PreAssessHierarchySnapshot {
    return {
      studentID: this.studentID,
      groupID: this.groupID,
      userID: this.userID,
      districtID: this.districtID,
      selected: this.selected,
    } as PreAssessHierarchySnapshot;
  }
}
