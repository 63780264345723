import {GridBox} from '@esgi/ui/layout';
import {StudentInfo} from '../../components/student-info';
import {CredentialsRow} from '../../components/credentials-row';
import {noop} from 'underscore';
import {of} from 'rxjs';

export function StudentCredentialsSkeleton() {
	return (
		<>
			{new Array(3).fill(null).map((_, index) => (
				<GridBox gap='3' key={index}>
					<StudentInfo skeleton firstName={''} lastName={''} photoUrl={undefined} studentNameColor={'currentColor'} />
					<CredentialsRow
						studentId={0}
						userName=''
						initialUserName=''
						onUserNameChanged={noop}
						onValidateUsername={of}
						password=''
						initialPassword=''
						onPasswordChanged={noop}
						onValidatePassword={of}
						isPasswordHidden
						togglePasswordHidden={noop}
						onCredentialsValidValueChanged={noop}
						skeleton
					/>
				</GridBox>
			))}
		</>
	);
}
