/* The code is defining an enumeration called `UserType` with several possible values. Each value
represents a different type of user. The values are `V`, `T`, `C`, `D`, `S`, `M`, `A`, `ISS`, `ISD`,
and `PA`. This enumeration can be exported and used in other parts of the code. */
export enum UserType {
	V,
	T,
	C,
	D,
	S,
	M,
	A,
	ISS,
	ISD,
	PA
}
