import {TestContentArea} from '@esgi/main/kits/common';
import {TestType} from '@esgi/main/libs/core';

export interface Test {
	id: number;
	name: string;
	contentArea: TestContentArea;
	isTested: boolean;
	correct: number;
	incorrect: number;
	untested: number;
	maxScore?: number;
	type: TestType
}

export interface ReportTest extends Test {
	sessions?: TestSession[],
	testDate?: string,
	sessionID?: number
}

export interface Sort {
	field: 'default' | 'name' | 'id';
	direction: 'asc' | 'desc';
}

export interface TestListProps {
	tests: Test[];
	selectedTests: Test['id'][] | null;
	toggleTests: (value: Test['id'][]) => void;
	sort: Sort;
	onSort: VoidFunction;
	onSelectAll: VoidFunction;
	onChangeTestsData?: (testID: number, session: TestSession) => void;
}

export type TestSession = {
	correct: number;
	incorrect: number;
	untested: number;
	sessionID?: number;
	testDate: string;
};

export enum TestStatus {
	Default = 'default',
	Active = 'active',
	Saved = 'saved',
	InProgress = 'inProgress',
}
