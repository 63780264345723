import {BaseGroupService} from 'modules/forms/admin-group-forms/services/base-group-service';
import {Observable, tap} from 'rxjs';
import {PreModel} from 'modules/forms/admin-group-forms/models/types';
import {InModel} from '@esgi/deprecated/elements/checkbox-list';
import {Item} from '@esgi/deprecated/elements/custom-dropdown/dropdown';
import {InitCreateUserGroupModel, InitEditModel, ItemModel, UserModel} from '../models/group-models';

export class UsersGroupService extends BaseGroupService {
	private availableItems: UserModel[];
	private schoolItems: ItemModel[];
	private gradeItems: ItemModel[];
	private gradeLevels: ItemModel[];
	public name: string;

	public initCreate(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.get<InitCreateUserGroupModel>('users-group', 'init-create', {
			districtID: model.districtID,
			userTypes: model.userTypes,
			schoolID: model.schoolID,
		}).pipe(tap(resp => {
			this.availableItems = resp.items;
			this.schoolItems = resp.schools;
			this.gradeLevels = resp.gradeLevels;
			this.gradeItems = this.filterGradeItems(this.availableItems);
			const users = this.mapUsers(resp.items);
			this.name = resp.name;
			this.availableItems$.next(users);
		})).asObservable();
	}

	public initEdit(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.get<InitEditModel>('users-group', 'init-edit', {
			groupID: model.groupID,
			districtID: model.districtID,
			userTypes: model.userTypes,
			schoolID: model.schoolID,
		}).pipe(tap(resp => {
			const availableUsers = resp.availableItems.map(t => {
				const u: InModel = {
					label: t.name,
					id: t.id,
					checked: false,
				};
				return u;
			});
			const usersInGroup = resp.itemsInGroup.map(t => {
				const u: InModel = {
					label: t.name,
					id: t.id,
					checked: false,
				};
				return u;
			});
			this.name = resp.name;
			this.availableItems$.next(availableUsers);
			this.itemsInGroup$.next(usersInGroup);
		})).asObservable();
	}

	public create(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.post<any>('users-group', 'create', {
			userID: model.userID,
			name: model.groupName,
			groupType: model.groupType,
			userIDs: this.itemsInGroup$.value.map(x => x.id),
		}).asObservable();
	}

	public update(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.post<any>('users-group', 'update', {
			groupID: model.groupID,
			districtID: model.districtID,
			groupName: model.groupName,
			groupType: model.groupType,
			userIDs: this.itemsInGroup$.value.map(x => x.id),
		}).asObservable();
	}

	public remove(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.post<number>('users-group', 'remove', {groupID: model.groupID}).asObservable();
	}

	private filterGradeItems(source: UserModel[]) {
		return source.map(s => s.gradeIDs)
			.reduce((a, b) => a.concat(b), [])
			.filter((value, index, self) => self.indexOf(value) === index)
			.map(gradeID => this.gradeLevels.find(s => s.id === gradeID))
			.sort((a, b) => a.id - b.id);
	}

	public updateList(schoolID: number, gradeID: number, userType: string = null) {
		if (!schoolID && !gradeID && !userType) {
			const availableUsers = this.mapUsers(this.availableItems);
			this.gradeItems = this.filterGradeItems(this.availableItems);
			this.availableItems$.next(availableUsers);
		} else {
			let items = this.availableItems;

			if (schoolID) {
				items = items.filter(x => x.schoolID === schoolID);
			}

			if (userType) {
				items = items.filter(x => x.userType === userType);
			}

			this.gradeItems = this.filterGradeItems(items);

			if (gradeID) {
				items = items.filter(x => x.gradeIDs.includes(gradeID));
			}
			const itemsIDs = items.map(x => x.id);
			const itemsInGroupIDs = this.itemsInGroup$.value.map(x => x.id);
			const availableUsersIDs = itemsIDs.filter(x => !itemsInGroupIDs.includes(x));
			const availableUsers = items.filter(x => availableUsersIDs.some(y => y === x.id));

			this.availableItems$.next(this.mapUsers(availableUsers));
		}
	}

	public get schools(): Item[] {
		return this.mapItems(this.schoolItems);
	}

	public get grades(): Item[] {
		return this.mapItems(this.gradeItems);
	}

	private mapItems(items: ItemModel[]): Item[] {
		if (!items) {
			return [];
		}
		const temp = [{value: 0, key: 0, title: 'All'}];
		const res = items.map(x => {
			const i: Item = {
				value: x.id,
				key: x.id,
				title: x.name,
			};
			return i;
		});
		return [...temp, ...res];
	}

	private mapUsers(users: UserModel[]): InModel[] {
		if (!users) {
			return [];
		}
		return users.map(t => {
			const u: InModel = {
				label: t.name,
				id: t.id,
				checked: false,
			};
			return u;
		});
	}
}
