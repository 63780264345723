import React from 'react';
import styles from './styles.module.less';
import {Init, Student} from '../../../../../services/results-service/models';

interface Props {
	model: Init
	student: Student
}

export function ParentLetterHeader({model, student}: Props) {
	const translation = model.translations[student.languageID];
	const teacherTitle = model.groupItem.itemType === 'SpecialistGroup' ? 'Specialist' : model.user.title === 'Mr.'
		? translation.mrTeacher : translation.msTeacher;
	const classTitle = model.groupItem.itemType === 'SpecialistGroup' || model.groupItem.itemType === 'Group'
		? translation.group : translation.class;
	return <div className={styles.parentLetterHeader}>
		<div className={styles.parentLetterLogo}>
			{model.logo && <>
				<div
					style={{backgroundImage: 'url(\'data:' + model.logo.contentType + ';base64,' + model.logo.base64 + '\')'}}
					className={styles.parentLetterLogo}/>
			</>}
		</div>
		<div className={styles.parentLetterInfoBlock}>
			<div>
				<span>{model.reportDate}</span>
			</div>
			<div>
				<strong>{teacherTitle + ': '}</strong>
				<span className={styles.teacherName}>{model.user.fullName}</span>

				<strong>{classTitle + ': '}</strong>
				<span>{model.groupItem.name}</span>
			</div>
			<div>
				<strong>{translation.subjectTab + ': '}</strong>
				<span>{model.subject}</span>
			</div>
		</div>
	</div>;
}
