import {UserInfo} from './components/user-info';
import React, {useMemo} from 'react';
import {SubjectName} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {HeaderBox} from '../../../index.styled';
import {ActionButtons} from '../../../kit';
import {useNavigate} from 'react-router-dom';
import {Divider} from './index.styled';
import {isNull} from 'underscore';
import {routes} from '@esgi/main/libs/core';
import {useFlashcardUrlWithParams} from '@esgi/main/features/teacher/utils';
import {Student, SubjectTab, useSubjects} from '@esgi/main/libs/store';

interface Props {
	userInfo?: Student;
	onStartTestHandler?: VoidFunction;
	onDownload: VoidFunction;
	subjectID: SubjectTab['id'];
	testID: number;
}

export function Header({userInfo, onStartTestHandler, onDownload, subjectID, testID}: Props) {
	const navigate = useNavigate();
	const flashcardUrl = useFlashcardUrlWithParams();

	const [{data: subjectsList}] = useSubjects();

	const subject = useMemo(() => subjectsList.find(({id}) => id === subjectID) ?? null, [subjectID, subjectsList]);

	return (
		<HeaderBox>
			<GridBox flow='column' gap='4' align='center'>
				{userInfo && (
					<UserInfo
						studentID={userInfo.id}
						avatarUrl={userInfo.photoUrl}
						firstName={userInfo.firstName}
						lastName={userInfo.lastName}
					/>
				)}
				{!isNull(subject) && (
					<>
						<Divider />
						<SubjectName subjectLevel={subject.level} size='large' noWrap>
							{subject.name}
						</SubjectName>
					</>
				)}
			</GridBox>
			<GridBox flow='column' gap='4' align='center'>
				<ActionButtons
					withStartTestButton
					onStartTestHandler={onStartTestHandler}
					onDownloadClicked={onDownload}
					onLetterFileClicked={() => navigate(`${routes.teacher.reports.parentLetter}?student-id=${userInfo?.id}`)}
					onBingoGameClicked={() => navigate(routes.teacher.activities.bingo)}
					onFleshCardsClicked={() => flashcardUrl({
						studentId: userInfo?.id.toString(),
						testId: testID.toString(),
						subjectId: subjectID.toString(),
					})}
				/>
			</GridBox>
		</HeaderBox>
	);
}
