import {ControlValue, ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';

export type PersonalFormType = FormGroup<{
	subjectName: FormControl<string>,
},
	ControlValue <{
		subjectName: FormControl<string>,
	}>>

export const createPersonalForm = ():
	PersonalFormType => new FormGroup({
	subjectName: new FormControl('', {validators: [Validators.required()], initialValidateStatus: ElementStatus.untouched}),
});