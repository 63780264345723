import React, {useState} from 'react';
import FormService from '../../../../../services/form-service';
import styles from '../../styles.module.less';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Buttons} from '@esgillc/ui-kit/button';

interface Props {
	service: FormService
}

export function TableControls({service}: Props) {
	const sortBy = useBehaviorSubject(service.sortBy);
	const isInit = useBehaviorSubject(service.settingsService.isInit);
	const [sortingOptionsOpened, setSortingOptionsOpened] = useState(false);

	function toggleSortingOptions() {
		setSortingOptionsOpened(!sortingOptionsOpened);
	}

	return isInit && <>
		<div className={styles.tableControls}>
			<div className={styles.selectAllLinks}>
				<Buttons.Link onClick={() => service.selectAllToggle(true)}>Select
					All</Buttons.Link>
				&nbsp;|&nbsp;
				<Buttons.Link onClick={() => service.selectAllToggle(false)}>Deselect
					All</Buttons.Link>
			</div>
			<div className={styles.selectAllLinks}>
				<span>Sort by:</span>
				{sortBy === 0 && <>
					<a href='#' onClick={toggleSortingOptions}>Test
						Order
						<svg width='10' height='5' viewBox='0 0 10 5' fill='none'
						     xmlns='http://www.w3.org/2000/svg'>
							<path d='M0 0L5 5L10 0H0Z' fill='#0088CC'/>
						</svg>
					</a>
				</>}
				{sortBy === 1 && <>
					<a href='#' onClick={toggleSortingOptions}
					>Alphabetical
						<svg width='10' height='5' viewBox='0 0 10 5' fill='none'
						     xmlns='http://www.w3.org/2000/svg'>
							<path d='M0 0L5 5L10 0H0Z' fill='#0088CC'/>
						</svg>
					</a>
				</>}
				{sortingOptionsOpened && <>
					<ul className={styles.sortingOptionsMenu} id='sortingOptionsMenu'>
						<li onClick={() => {
							service.setSortBy('TestOrder');
							toggleSortingOptions();
						}}>Test Order</li>
						<li onClick={() => {
							service.setSortBy('Alphabetical');
							toggleSortingOptions();
						}}>Alphabetical</li>
					</ul>
				</>}
			</div>
		</div>
	</>;
}
