import {environment} from '@esgi/core/environments';
import {fromEvent, merge, Observable, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {io, Socket} from 'socket.io-client';
import {ShareTestingModel} from './models';

export type OnJoinedData = {
	code: string;
	secretCode: string;
	testInfo: {
		test: ShareTestingModel;
		summary: any; //TODO
	}
}

export class StudentScreenClient {
	public readonly host = environment.url.replace('https://', 'wss://');
	public socket: Socket = io(`${this.host}/student`, {
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 3000,
		path: '/share-screen/api',
		reconnectionAttempts: Infinity,
		transports: ['websocket'],
	});
	public onBadCode: Observable<void> = fromEvent(this.socket, 'badCode');
	public onApplied: Observable<void> = fromEvent(this.socket, 'applyed');
	public onJoined: Observable<OnJoinedData> = fromEvent(this.socket, 'joined');
	public onTestStarted: Observable<ShareTestingModel> = fromEvent(this.socket, 'testStarted');
	public onTestEnded: Observable<void> = fromEvent(this.socket, 'testEnded');
	public onShowQuestion: Observable<number> = fromEvent(this.socket, 'showQuestion').pipe(map(e => e.index));
	public onSummary: Observable<any> = fromEvent(this.socket, 'summary');
	public onSessionEnded: Observable<void> = fromEvent(this.socket, 'sessionEnded');
	public onReconnected: Observable<any> = fromEvent(this.socket, 'reconnect');
	public onDisconnected: Observable<any> = fromEvent(this.socket, 'disconnect');
	public onConnected: Observable<any> = fromEvent(this.socket, 'connect');
	public onConnectError: Observable<void> = merge(
		fromEvent(this.socket, 'reconnect_error'),
		fromEvent(this.socket, 'connect_timeout'),
	);

	constructor() {
		this.socket.on('reconnect_attempt', () => {
			this.socket.io.opts.transports = ['websocket'];
		});

		this.onJoined.pipe(
			switchMap(data => fromEvent(this.socket, 'reconnect').pipe(map(() => data.secretCode))),
		).subscribe((secretCode) => {
			this.socket.emit('reconnect_client', secretCode);
		});
	}

	public joinWithSecret(code): void {
		this.socket.emit('joinWithSecret', code);
	}

	public join(code: string): void {
		this.socket.emit('join', code);
	}

	public synchronizeCurrentQuestion(): void {
		this.socket.emit('synchronizeCurrentQuestion');
	}
}
