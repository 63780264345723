import * as Highcharts from './highstock';
import React from 'react';

export class HighchartsReact extends React.PureComponent<any> {
	constructor(props) {
		super(props);
		this.container = (React as any).createRef();
	}

	chart: any;
	container: any;

	createChart() {
		const props = this.props;
		const H = props.highcharts || Highcharts;
		const constructorType = props.constructorType || 'chart';

		if (!H) {
			console.warn('The "highcharts" property was not passed.');
		} else if (!H[constructorType]) {
			console.warn('The "constructorType" property is incorrect or some ' +
				'required module is not imported.');
		} else if (!props.options) {
			console.warn('The "options" property was not passed.');
		} else {
			// Create a chart
			this.chart = H[constructorType](
				this.container.current,
				props.options,
				props.callback ? props.callback : undefined,
			);
		}
	}

	componentDidMount() {
		if (this.props.async) {
			setTimeout(() => {
				this.createChart();
				this.props.chartRendered && this.props.chartRendered();
			}, 1);
		} else {
			this.createChart();
			this.props.chartRendered && this.props.chartRendered();

		}
	}


	componentDidUpdate() {
		const props = this.props;
		if (props.allowChartUpdate !== false) {
			if (!props.immutable && this.chart) {
				this.chart.update(
					props.options,
					...(props.updateArgs || [true, true]),
				);
			} else {
				this.createChart();
			}
		}
	}

	componentWillUnmount() {
		// Destroy chart
		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}
	}

	render() {
		// Create container for the chart
		return React.createElement(
			'div',
			{
				ref: this.container,
				style: this.props.isFluid ? {width: '100%', height: '100%'} : this.props.containerStyle,
			},
		);
	}
}

