import * as React from 'react';
import type {SVGProps} from 'react';

export function Info(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			data-cy='info-icon'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 6C10.477 6 6 10.477 6 16s4.477 10 10 10 10-4.477 10-10S21.523 6 16 6Zm0 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16.001 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-1 3a1 1 0 0 0-1 1c0 .482.359.842.812.938l-.593 2.874c-.232 1.161.598 2.188 1.781 2.188h1a1 1 0 0 0 0-2h-.781l.75-3.812A.986.986 0 0 0 16.001 14h-1Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
