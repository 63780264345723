/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class RepAutoQuoterFormTemplate {
	static render () {
		return <div className='form-content fixed'>
			<div className='form-container'>
				<div className='container'>
					<div className='header'>
						<div className='title-container'>
							<div className='title'>
								<b> Request a Quote </b>
								<br/>
								<b> Riverside Insights Rep </b>
							</div>
						</div>
					</div>
					<form>
						<label className='groups-label'>TEACHER LICENSES</label>
						<div className='row'>
							<div className='col-xs-2'>
								<ko data-bind='with: license'>
									<input name='licence' id='license' type='number' min='0' autoComplete='off' defaultValue='0' className='form-control has-feedback has-error'
										placeholder='Qty'
										data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible, value: value" />
								</ko>
							</div>
						</div>
						<ko>
							<div className='form-group form-check'>
								<ko>
									<input id='hasSpecialistsCheckbox' data-bind='checked: hasSpecialist' type='checkbox' className='specialistCheckbox form-check-input' />
								</ko>
								<label className='form-check-label' htmlFor='hasSpecialistsCheckbox'>Add Specialist Licenses</label>
								<div className='container specialists specialists-container'>
									<div className='col-xs-6'>
										<div className='row'>
											<div className='col-xs-4'>
												<ko data-bind='with: specialistsLicense50'>
													<input name='specialistLicence50' id='specialistLicence50' min='0' type='number' autoComplete='off' className='form-control has-feedback has-error'
														placeholder='Qty'
														data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible, value: value" />
												</ko>
											</div>
											<label className='form-check-label'>0-50 students</label>
										</div>
										<div className='row'>
											<div className='col-xs-4'>
												<ko data-bind='with: specialistsLicense100'>
													<input name='specialistLicence100' id='specialistLicence100' min='0' type='number' autoComplete='off' className='form-control has-feedback has-error'
														placeholder='Qty'
														data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible, value: value" />
												</ko>
											</div>
											<label className='form-check-label'>51-100 students</label>
										</div>
										<div className='row mb-3'>
											<div className='col-xs-4'>
												<ko data-bind='with: specialistsLicense150'>
													<input name='specialistLicence150' min='0' id='specialistLicence150' type='number' autoComplete='off' className='form-control has-feedback has-error'
														placeholder='Qty'
														data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible, value: value" />
												</ko>
											</div>
											<label className='form-check-label'>101-150 students</label>
										</div>
										<div className='row mb-3'>
											<div className='col-xs-4'>
												<ko data-bind='with: specialistsLicense200'>
													<input name='specialistLicence200' min='0' id='specialistLicence200' type='number' autoComplete='off' className='form-control has-feedback has-error'
														placeholder='Qty'
														data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible, value: value" />
												</ko>
											</div>
											<label className='form-check-label'>151-200 students</label>
										</div>
									</div>
									<div className='col-xs-6'>
										<div className='row'>
											<p className='specialist-information'>
												ESGI Specialist Licenses are designed for
												specialists that share a student with a primary
												teacher. <a target='_blank' href='https://support.esgisoftware.com/hc/en-us/articles/360056906592-Specialist-Accounts' rel='noreferrer'>CLICK HERE</a> for more information.
											</p>
										</div>
									</div>
								</div>
							</div>
						</ko>
						<div className='row'>
							<div className='col-xs-6'>
								<label className='groups-label'>CUSTOMER CONTACT INFORMATION</label>
							</div>
						</div>
						<div className='row'>
							<div className='col-xs-6'>
								<label className='field-label'>First name</label>
								<ko data-bind='with: firstName'>
									<div className='form-group has-feedback has-error'
										data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible">
										<input placeholder='First Name' name='firstName' type='text'
											className='form-control' data-bind='trimedValue: value' />
									</div>
								</ko>
							</div>
							<div className='col-xs-6'>
								<label className='field-label'>Last name</label>
								<ko data-bind='with: lastName'>
									<div className='form-group has-feedback has-error'
										data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible">
										<input placeholder='Last Name' name='lastName' type='text'
											className='form-control' data-bind='trimedValue: value' />
									</div>
								</ko>
							</div>
						</div>
						<div className='row'>
							<div className='col-xs-6'>
								<label className='field-label'>Phone number</label>
								<ko data-bind='with: phoneNumber'>
									<div className='form-group has-feedback has-error'
										data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible">
										<input placeholder='Phone Number' name='phoneNumber' type='text'
											className='form-control'
											data-bind='trimedValue: value' />
									</div>
								</ko>
							</div>
							<div className='col-xs-6'>
								<label className='field-label'>Email</label>
								<ko data-bind='with: email '>
									<div className='form-group has-feedback has-error'
										data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible">
										<input placeholder='Email' name='email' type='text'
											className='form-control'
											data-bind='trimedValue: value'
											data-original-title='' title='' />
									</div>
								</ko>
							</div>
						</div>
						<div className='row'>
							<div className='col-xs-12 inline-checkbox'>
								<ko>
									<input id='sendCopyToCustomerCheckbox' data-bind='checked: sendCopyToCustomer.value' type='checkbox' className='sendCopyToCustomerCheckbox form-check-input' />
								</ko>
								<label htmlFor='sendCopyToCustomerCheckbox' className='form-check-label'>Send a copy of the quote to customer. Quote will be sent directly to the customer with a copy to you</label>
							</div>
						</div>
						<div className='row'>
							<div className='col-xs-6'>
								<label className='groups-label'>DISTRICT INFORMATION</label>
							</div>
						</div>
						<ko data-bind='if: $data.tabModule !== undefined '>
							<ko data-bind='with: tabModule '>
								<div data-bind='afterRender: true'>
									<div className='form-group'>
										<div className='row'>
											<div className='col-xs-6'
												data-bind="css: {'validation-fail':!validate.stateName.validationStatus()}">
												<label className='field-label custom-mt'>State</label>
												<div className='states-container'>
													<select
														className='state-select form-control state'
														autoComplete='off'
														data-bind="options: states,optionsText: 'name', optionsValue:'id' ,value: model.stateId, optionsCaption: 'State', chosen: { disable_search_threshold: 10, width:'100%', search_contains: true }"
														style={{width: '100%'}}>
													</select>
												</div>
											</div>
											<div className='col-xs-6'
												data-bind="css: {'validation-fail':!validate.countryName.validationStatus()}">
												<label className='field-label custom-mt'>Country</label>
												<div className='country-container'>
													<select
														className='country-select form-control country'
														autoComplete='off'
														data-bind="options: countries,optionsText: 'name', optionsValue: 'countryID', value: model.countryId, optionsCaption: 'Country', chosen: { disable_search_threshold: 10, width:'100%', search_contains: true }">
													</select>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-xs-6 custom-mt'
												data-bind="css: {'validation-fail':!validate.districtName.validationStatus()}">
												<label className='field-label custom-mt'>District</label>
												<div className='federal-districts-container'>
													<select
														className='federal-select form-control district'
														autoComplete='off'
														data-bind="options: federalDistricts,optionsText: 'name', optionsValue:'id', value: model.federalDistrictId, optionsCaption: 'District', chosen: districtChosenOption">
													</select>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-xs-6 custom-mt'
												data-bind="css: {'validation-fail':!validate.schoolName.validationStatus()}">
												<label className='field-label custom-mt'>School</label>
												<div className='federal-schools-container'>
													<select
														className='federal-select form-control school'
														autoComplete='off'
														data-bind="options: federalSchools,optionsText: 'schoolName', optionsValue:'federalSchoolId', value: model.federalSchoolId, optionsCaption: 'School', chosen: schoolChosenOption">
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ko>
						</ko>
						<div className='row'>
							<div className='col-xs-6'>
								<label className='groups-label'>RIVERSIDE INSIGHTS REP INFO</label>
							</div>
						</div>
						<div className='row'>
							<div className='col-xs-6'>
								<label className='field-label'>Full name</label>
								<ko data-bind='with: representativeName'>
									<div className='form-group has-feedback has-error'
										data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible">
										<input placeholder='Representative Full Name' name='representativeName' type='text'
											className='form-control' data-bind='trimedValue: value'/>
									</div>
								</ko>
							</div>
							<div className='col-xs-6'>
								<label className='field-label'>Email</label>
								<ko data-bind='with: representativeEmail '>
									<div className='form-group has-feedback has-error'
										data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true, visible: visible">
										<input placeholder='Representative Email' name='representativeEmail' type='text'
											className='form-control' data-bind='trimedValue: value'/>
									</div>
								</ko>
							</div>
						</div>
					</form>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 bg-light text-right'>
							<button type='button' className='btn action-button next-button' data-bind='click: view.getquote'>Get Quote</button>
							<a id='donwloader' hidden></a>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}
