import {ToggleGroup} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	padding: 3,
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	paddingLeft: 13,
	paddingRight: 13,
	paddingTop: 8,
	paddingBottom: 8,

	'&[data-disabled]': {
		'& svg path': {
			fill: '$muted',
		},
	},

	variants: {
		isSmallScreen: {
			true: {
				paddingLeft: 8,
				paddingRight: 8,
			},
		},
	},
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	marginTop: 6,
});
