import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';

interface Props {
	onCancelClicked: () => void;
	onUploadFileClicked: () => void;
}

export function ContinueFileUpload({onCancelClicked, onUploadFileClicked}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancelClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title>Continue File Upload?</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<div data-cy={'continue-file-upload'}>
				<span data-cy={'message'}>Thank you for purchasing additional student slots.
					<p>Do you wish to continue with your file upload?</p>
				</span>
			</div>
		</Modal.Body>
		<Modal.Footer className={styles.footer}>
			<Buttons.Outlined onClick={handleClose}>
				CANCEL
			</Buttons.Outlined>
			<Buttons.Contained onClick={onUploadFileClicked}>
				UPLOAD FILE
			</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}