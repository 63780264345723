import React from 'react';
import {FlexBox} from '@esgillc/ui-kit/layout';
import styles from './name-writing-template.module.less'
import {join} from '@esgillc/ui-kit/utils';
import * as modelUtils from '../../utils/utils';
import {NameWritingReportDetails, NameWritingReportStudentData} from './types';
import {UnitType} from '../../types';

interface Props {
  details: NameWritingReportDetails;
  student: NameWritingReportStudentData;
  className?: string;
}

export class NameWritingTemplate extends React.PureComponent<Props> {
  public render() {
    return <FlexBox className={join(styles.page, this.props.className)} direction='column'>
      <FlexBox className={styles.header} align='start'>
        <FlexBox className={styles.headerItem}>
          <FlexBox className={styles.headerItemKey}>Writing Practice:</FlexBox>
          <FlexBox>{new Date().toLocaleDateString('en-US')}</FlexBox>
        </FlexBox>
        <FlexBox className={styles.headerItem}>
          <FlexBox className={styles.headerItemKey}>
            {
              this.props.details.unitType === UnitType.Class ? 'Teacher:' : 'Specialist:'
            }
          </FlexBox>
          <FlexBox>{modelUtils.getLecturerName(this.props.details.lecturer)}</FlexBox>
        </FlexBox>
        <FlexBox className={styles.headerItem}>
          <FlexBox className={styles.headerItemKey}>
            {
              this.props.details.unitType === UnitType.Class ? 'Class:' : 'Group:'
            }
          </FlexBox>
          <FlexBox>
            {
              this.props.student.unitName
            }
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox className={styles.studentNameBlock} direction='column' justify='stretch' align='stretch'>
        <FlexBox className={styles.studentNameBlockFullName}>{this.props.student.fullName}</FlexBox>
        <FlexBox className={styles.studentNameBlockMyNameIs}>My Name Is</FlexBox>
        <FlexBox className={styles.studentName} style={{fontSize: this.props.student.titleNameFontSize}}>
          {this.props.student.bodyName}
        </FlexBox>
      </FlexBox>
      <FlexBox direction='column' justify='space-around' align='center' className={styles.pageBody} style={{fontSize: this.props.student.bodyFontSize}}>
        {
          [...Array(this.props.student.linesCount)].map((x, i) => {
            return <p className={styles.lineItem} key={`template-item-${i}`}>
              {this.props.student.bodyName}
            </p>
          })
        }
      </FlexBox>
    </FlexBox>;
  }
}
