import {
	BaseHttpClient,
	BuilderOptions,
	ObservableBuilder,
	RequestBuilder,
} from '@esgi/api';
import {AxiosRequestConfig} from 'axios';
import {Observable} from 'rxjs';

export class ContractHttpClient extends BaseHttpClient {
	constructor(protected config?: BuilderOptions) {
		super();
	}

	public request<T>(
		method: string,
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
	): ObservableBuilder<T> {
		if (this.config) {
			const request = this.innerRequest(this.config.baseUrl, this.config);
			switch (method.toLowerCase()) {
				case 'get':
					return request.get('', url, data, config);
				case 'post':
					return request.post('', url, data, config);
				case 'put':
					return request.put('', url, data, config);
				case 'delete':
					return request.delete('', url, data, config);
			}

			throw new Error(`unsupported method '${method}' on request ${url}`);
		} else {
			throw new Error(
				`Client not initialized. Use registerClientConfig from @esgi/contracts`,
			);
		}
	}

	public file(
		url: string,
		filename: string,
		data?: object,
		config?: AxiosRequestConfig,
		json?: boolean,
	): Observable<Blob> {
		if (this.config) {
			const request = this.innerRequest(this.config.baseUrl, this.config);
			return request.file('', url, filename, data, config, json);
		} else {
			throw new Error(
				`Client not initialized. Use registerClientConfig from @esgi/contracts`,
			);
		}
	}

	protected override innerRequest(
		baseUrl: string,
		options?: Partial<Omit<BuilderOptions, 'baseUrl'>>,
	): RequestBuilder {
		return super.innerRequest(baseUrl, {
			defaultErrorHandlers: this.config?.defaultErrorHandlers,
			responseMapper: (data) => {
				if (data instanceof Blob) {
					return data;
				}
				if (data) {
					return (data as any).object;
				}
				return undefined;
			},
			...options,
		});
	}
}
