import {useState, useCallback} from 'react';

export function useItems() {
	const [selectedItems, setSelectedItems] = useState({
		classes: [],
		groups: [],
	});

	const setSelectedClass = useCallback((classId: number) => {
		const {classes} = selectedItems;
		const index = classes.findIndex((id) => id === classId);
		if (index !== -1) {
			classes.splice(index, 1);
		} else {
			classes.push(classId);
		}
		setSelectedItems({
			...selectedItems,
			classes: [...classes],
		});
	}, [selectedItems]);

	const setSelectedGroup = useCallback((groupId: number) => {
		const {groups} = selectedItems;
		const index = groups.findIndex((id) => id === groupId);
		if (index !== -1) {
			groups.splice(index, 1);
		} else {
			groups.push(groupId);
		}
		setSelectedItems({
			...selectedItems,
			groups: [...groups],
		});
	}, [selectedItems]);

	return {selectedItems, setSelectedClass, setSelectedGroup};
}
