import {useItemAnalysisService} from '../../../../hooks';
import {Sort} from '../../../../types';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {Tooltip} from '@esgi/ui/tooltip';
import {Checkbox} from '@esgi/ui/controls';
import {Select} from '../select';
import {Text} from '@esgi/ui/typography';
import {Box} from '@esgi/ui/layout';
import {ReportSettings, Row, RadioboxRoot, RadioboxGroup, Radiobox, Link} from './index.styled';
import {Info} from '@esgi/ui/icons';
import {SelectSubjects} from '../select-subjects';

const sorts = [
	{id: Sort.LeastKnown, name: 'Least Known'},
	{id: Sort.MostKnown, name: 'Most Known'},
	{id: Sort.QuestionOrder, name: 'Question Order'},
	{id: Sort.Alphabetical, name: 'Alphabetical'},
];

export function Settings() {
	const service = useItemAnalysisService();
	const entity = useBehaviorSubject(service.entity$);
	const notTested = useBehaviorSubject(service.notTested$);
	const sort = useBehaviorSubject(service.sort$);
	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const tests = useBehaviorSubject(service.testService.tests$);
	const testResultsIncorrectVerbiage = useBehaviorSubject(service.reportService.testResultsIncorrectVerbiage$);
	const unitDataSettings = useBehaviorSubject(service.unitDataSettings$);
	const {selectedClass, selectedGroup, selectedTest, selectedSubject} = unitDataSettings;

	return (
		<ReportSettings dataCy='report-settings'>
			<Row>
				{(entity.classId && classes.length > 0) &&
					<Select
						label='Class'
						options={classes?.filter(({studentIDs}) => studentIDs.length !== 0)?.map(({id, name}) => ({id, name}))}
						hasAll
						value={entity.classId === -1 ? '-1' : (selectedClass ? selectedClass.id.toString() : '-1')}
						onValueChange={(value) => service.setEntity({classId: +value, groupId: null})}
						dataCy='class-dropdown'
					/>
				}
				{(entity.groupId && groups.length > 0) &&
					<Select
						label='Group'
						options={groups?.filter(({studentIDs}) => studentIDs.length !== 0)?.map(({id, name}) => ({id, name}))}
						value={selectedGroup.id.toString()}
						onValueChange={(value) => service.setEntity({classId: null, groupId: +value})}
						dataCy='group-dropdown'
					/>
				}
				<SelectSubjects
					options={(subjects ?? []).map(({id, name, level}) => ({id, name, level}))}
					value={selectedSubject.subjectID.toString()}
					onValueChange={(value) => service.setSubject(+value)}
				/>
				{(selectedSubject && tests.length > 0) &&
					<Select
						label='Test'
						options={tests.map(({id, name}) => ({id, name}))}
						value={selectedTest?.id.toString()}
						onValueChange={(value) => service.setTest(+value)}
						dataCy='test-dropdown'
					/>
				}
			</Row>
			<Row>
				<Box>
					<Text data-cy='sort-options-title'>Sort Options</Text>
					<Tooltip>
						<Tooltip.Trigger>
							<Link
								href='https://support.esgisoftware.com/hc/en-us/articles/209159686'
								target='_blank'
								rel='noreferrer'
								data-cy='help-link'
							>
								<Info/>
							</Link>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<Text size='small'>Help</Text>
						</Tooltip.Content>
					</Tooltip>
					<RadioboxRoot
						value={sort.toString()}
						onValueChange={(value) => service.setSort(Number(value) as Sort)}
					>
						<RadioboxGroup orientation='horizontal'>
							{sorts.map(({id, name}) =>
								<Radiobox
									key={id}
									value={id.toString()}
									dataCy={`radio-box-${id}`}
								>
									<Text data-cy='radio-box-name' size='small' font='mono' color='currentColor'>
										{name}
									</Text>
								</Radiobox>
							)}
						</RadioboxGroup>
					</RadioboxRoot>
				</Box>
				<Box>
					<Checkbox
						label={`Display Not Tested as ${testResultsIncorrectVerbiage}`}
						checked={notTested}
						onCheckedChange={(value) => service.setNotTested(!!value)}
						dataCy='display-not-tested-checkbox'
					/>
				</Box>
			</Row>
		</ReportSettings>
	);
}
