import React, {RefObject, useEffect, useRef, useState} from 'react';
import {Fade} from '@esgillc/ui-kit/transition';
import {Buttons} from '@esgillc/ui-kit/button';
import {join} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import NoteDialog from '../../../../../common/note-dialog/note-dialog';
import NoteReadonlyDialog from '../../../../../common/note-readonly-dialog/note-readonly-dialog';
import {Criteria} from '../../../../../common/types';
import styles from './criteria.module.less';

interface Props {
	criteria: Criteria;
	invalid: boolean;
	note: string;
	levelName: string;
	onNoteChanged: (value: string) => void;
	editMode: boolean;
	criteriaToRef: Map<number, RefObject<HTMLDivElement>>
}

export function CriteriaView ({criteria, invalid, note, levelName, onNoteChanged, editMode, criteriaToRef}: Props) {
	const boxRef: RefObject<HTMLDivElement> = useRef();
	const [showNotesDialog, setShowNotesDialog] = useState(false);
	const hasNotes = !!note?.trim();
	const tooltip = (hasNotes || !editMode ? 'View' : 'Add') + ' Notes';

	useEffect(() => {
		criteriaToRef.set(criteria.id, {current: boxRef.current});
	}, [criteriaToRef, criteria]);

	return (
		<>
			<div className={styles.box} ref={boxRef}>
				<div className={styles.innerBox}>
					<div className={join(styles.criteria, invalid && styles.invalid)}>
						<div className={styles.body}>
							<div className={styles.textContainer}>
								<div className={styles.name}>
									<span>{criteria.name}</span>
								</div>
							</div>
						</div>
						<div className={styles.footer}>
							<Fade in={editMode || hasNotes} duration={100}>
								<Buttons.Link
									onClick={() => setShowNotesDialog(true)}
									className={join(styles.notesButton, editMode && styles.notesEditMode)}
								>
									<OnHoverTooltip message={tooltip}>
										<div>
											Notes
											<Fade in={hasNotes && editMode} duration={100}>
												<svg width='13' height='10' viewBox='0 0 13 10' fill='none'
												     xmlns='http://www.w3.org/2000/svg'>
													<path d='M11.59 0L5 6.59L1.41 3.01L0 4.42L5 9.42L13 1.42L11.59 0Z' fill='#0077B8'/>
												</svg>
											</Fade>
										</div>
									</OnHoverTooltip>
								</Buttons.Link>
							</Fade>
						</div>
					</div>
				</div>
			</div>
			{showNotesDialog && (
				editMode ? <NoteDialog
						initValue={note}
						criteriaName={criteria.name}
						levelName={levelName || ''}
						onClose={() => setShowNotesDialog(false)}
						onSave={(value) => {
							setShowNotesDialog(false);
							onNoteChanged(value);
						}}/>
					: <NoteReadonlyDialog
						criteriaName={criteria.name}
						levelName={levelName || ''}
						onClose={() => setShowNotesDialog(false)}
						notes={note}/>
			)}
		</>
	);
}
