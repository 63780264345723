import {BaseService} from '@esgi/core/service';
import {CreateResponseModel, InitEditModel, InModel, StudentModel} from 'modules/forms/class-form/types';
import {SsoTracker} from '@esgi/core/tracker';
import {
	ClassChangedEvent,
	ClassCreatedEvent,
	ClassRemovedEvent,
} from 'modules/forms/class-form/events';
import {dispatchAppEvent} from '@esgillc/events';
import {BehaviorSubject, tap} from 'rxjs';
import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {ObservableBuilder} from '@esgi/api';

export class ClassFormService extends BaseService {
	availableStudents = new BehaviorSubject<InModel[]>([]);
	studentsInClass = new BehaviorSubject<InModel[]>([]);
	className = new BehaviorSubject<string>('');
	classID: number;
	teacherID: number;
	numberOfClasses: number;
	form: FormGroup<{ className: FormControl<string> }, ControlValue<{ className: FormControl<string> }>> =
		new FormGroup({className: new FormControl('', {validators: [Validators.required()]})});

	public init = ({
		               teacherID,
		               classID,
		               className,
		               numberOfClasses,
	               }): ObservableBuilder<InitEditModel | StudentModel[]> => {
		this.teacherID = teacherID;
		this.classID = classID;
		this.numberOfClasses = numberOfClasses;
		if (className) {
			this.form.controls.className.value = className;
			this.className.next(className);
		}
		this.form.controls.className.onChanged.subscribe((field) => {
			this.className.next(field.currState.value);
		});

		if (this.classID) {
			return this.initEdit();
		}
		return this.initCreate();

	};

	public selectAvailableStudent = (selectedItems: number[]) => {
		const newStudentsInGroup = this.studentsInClass.value.map((student) => ({...student, checked: false}));

		const newAvailableStudents = this.availableStudents.value.map(student => {
			const isStudentChecked = selectedItems.indexOf(student.id) !== -1;
			return {...student, checked: isStudentChecked};
		},
		);

		this.studentsInClass.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);
	};

	public selectStudentsInClass = (selectedItems: number[]) => {
		const newAvailableStudents = this.availableStudents.value.map((student) => ({...student, checked: false}));
		const newStudentsInGroup = this.studentsInClass.value.map(student => {
			const isStudentChecked = selectedItems.indexOf(student.id) !== -1;
			return {...student, checked: isStudentChecked};
		},
		);
		this.studentsInClass.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);
	};

	public selectByFilterInClassStudents = (checked: boolean) => {
		let newStudentsInGroup: InModel[];
		let newAvailableStudents = this.availableStudents.value;
		if (checked) {
			newStudentsInGroup = this.studentsInClass.value.map((student) => ({...student, checked: true}));
			if (newStudentsInGroup.length > 0) {
				newAvailableStudents = this.availableStudents.value.map((student) => ({...student, checked: false}));
			}
		} else {
			newStudentsInGroup = this.studentsInClass.value.map((student) => ({...student, checked: false}));
		}
		this.studentsInClass.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);
	};

	public selectByFilterAvailableStudents = (checked: boolean) => {
		let newStudentsInGroup = this.studentsInClass.value;
		let newAvailableStudents: InModel[];

		if (checked) {
			newAvailableStudents = this.availableStudents.value.map((student) => ({...student, checked: true}));

			if (newAvailableStudents.length > 0) {
				newStudentsInGroup = this.studentsInClass.value.map((student) => ({...student, checked: false}));
			}
		} else {
			newAvailableStudents = this.availableStudents.value.map((student) => ({...student, checked: false}));
		}

		this.studentsInClass.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);
	};

	public move = (): void => {
		if (this.availableStudents.value.filter(student => student.checked).length) {
			this.moveToClass();
			return;
		}
		this.moveToAvailable();
	};

	public create() {
		return this.httpClient.ESGIApi.post<CreateResponseModel>('class', 'create', {
			name: this.form.controls.className.value,
			teacherID: this.teacherID,
			studentIDs: this.studentsInClass.value.map(student => student.id),
		}).pipe(tap((response) => {
			SsoTracker.trackEvent({
				trackingEvent: 'ClassCreated',
				data: {classId: response.classID},
			});

			const event = new ClassCreatedEvent(response.classID,
				this.form.controls.className.value, this.teacherID, this.studentsInClass.value.map(x => x.id));
			dispatchAppEvent(ClassCreatedEvent, event);
		}));
	}

	public save() {
		return this.classID ? this.update() : this.create();
	}

	public update() {
		return this.httpClient.ESGIApi.post('class', 'update', {
			classID: this.classID,
			className: this.className.value,
			studentIDs: this.studentsInClass.value.map(x => x.id),
		}).pipe(tap(() => {
			const event = new ClassChangedEvent(this.classID, this.className.value, this.studentsInClass.value.map(x => x.id));
			dispatchAppEvent(ClassChangedEvent, event);
		}));
	}


	public remove() {
		return this.httpClient.ESGIApi.post<{ canRemove: boolean }>('class', 'remove', {classID: this.classID})
			.pipe(tap((response) => {
				if (response.canRemove) {
					SsoTracker.trackEvent({
						trackingEvent: 'ClassDeleted',
						data: {classId: this.classID},
					});
					const event = new ClassRemovedEvent(this.classID);
					dispatchAppEvent(ClassRemovedEvent, event);
				}
			}));
	}

	private initCreate() {
		return this.httpClient.ESGIApi.get<StudentModel[]>('class', 'create/init', {teacherID: this.teacherID})
			.pipe(tap(response => {
				const students = this.handleLoadedStudents(response);
				this.availableStudents.next(students);
			}));
	}

	private initEdit() {
		return this.httpClient.ESGIApi.get<InitEditModel>('class', 'edit/init', {
			teacherID: this.teacherID,
			classID: this.classID,
		}).pipe(tap(response => {
			const availableStudents = this.handleLoadedStudents(response.availableStudents);
			const studentsInClass = this.handleLoadedStudents(response.studentsInClass);
			this.availableStudents.next(availableStudents);
			this.studentsInClass.next(studentsInClass);
		}));
	}

	private handleLoadedStudents(students: StudentModel[]) {
		return students.map(student => new InModel(false, student.fullName, student.id));
	}

	private moveToAvailable() {
		const checkedStudents = this.studentsInClass.value.filter(student => student.checked);
		const studentsInGroup = this.studentsInClass.value.filter(student => !student.checked);
		const availableStudents = this.availableStudents.value.concat(checkedStudents).map(student => student.checked ? {
			...student,
			checked: false,
		} : student);

		this.studentsInClass.next(studentsInGroup);
		this.availableStudents.next(availableStudents);
	}

	private moveToClass(): void {
		const checkedStudents = this.availableStudents.value.filter(student => student.checked);
		const availableStudents = this.availableStudents.value.filter(student => !student.checked);
		const studentsInGroup = this.studentsInClass.value.concat(checkedStudents).map(student => student.checked ? {
			...student,
			checked: false,
		} : student);
		this.studentsInClass.next(studentsInGroup);
		this.availableStudents.next(availableStudents);
	}
}
