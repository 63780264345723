import {Class} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/classes/class';

export class AddNewClass {
	constructor(public newClass: Class) {}
}

export class EditClass {
	constructor(public classInfo: Class) {}
}

export class RemoveClass {
	constructor(public classInfo: Class) {}
}