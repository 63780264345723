import {PropsWithChildren} from 'react';
import {Container} from './index.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

export function QuestionsContainer({children}: PropsWithChildren) {
	return (
		<Container>
			<OverlayScrollbarsComponent
				defer
				style={{
					height: 'calc(100% + 0px)',
					paddingLeft: 12,
					paddingRight: 12,
				}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				{children}
			</OverlayScrollbarsComponent>
		</Container>
	);
}
