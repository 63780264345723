import React from 'react';
import {Button, ButtonProps} from './default';

/**
 * @deprecated Use buttons from @esgi/ui or @esgillc/ui-kit
 **/
export class Primary extends React.PureComponent<ButtonProps> {
	render() {
		let props = {...this.props};
		props.className = props.className ? `btn-primary ${props.className}` : 'btn-primary';

		return <Button
            {...props}
        />;
	}
}