import {styled} from '@esgi/ui/theme';
import {FlexBox, Box} from '@esgi/ui/layout';

export const Layout = styled(FlexBox, {
	flexDirection: 'row',
	justifyContent: 'space-between',
	padding: '20px',
	paddingTop: '15px',
});

export const VerticalDivider = styled(Box, {
	height: '100%',
	width: 1,
	backgroundColor: '$neutral88',
});
