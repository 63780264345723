import React from 'react';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import styles from './dialogs.module.less';

interface Props {
	criteriaName: string;
	onConfirm: () => void;
	onCancel: () => void;
}

export default class RemoveDialog extends React.PureComponent<Props> {
	public render() {
		return <Modal className='white-header'>
			<ModalHeader title={`Remove Criteria ${this.props.criteriaName}?`}/>
			<ModalBody className={styles.modalBody}>
				<div className={styles.body}>
					Removing this criteria will also remove the descriptions associated with that criteria.
				</div>
			</ModalBody>
			<ModalFooter>
				<LinkButton onClick={() => this.props.onCancel()}>CANCEL</LinkButton>
				<LinkButton onClick={() => this.props.onConfirm()}>REMOVE</LinkButton>
			</ModalFooter>
		</Modal>;
	}
}
