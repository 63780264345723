import React from 'react';
import {IBoxInfo, IPreAssessModel} from '../../core/api';
import {BoxType} from '../../core/models';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {userStorage, UserType} from '@esgi/core/authentication';

export class State {

}
export class Props {
	boxes: IBoxInfo[];
	selectedID: number;
	preAssesses: IPreAssessModel[];
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;

}

export class User extends React.Component<Props, State> {
	private currentUser = userStorage.get();

	get items(): ItemProps[] {
		return this.props.preAssesses
			.map(r => {
				return {
					title: `${r.firstName} ${r.lastName}`,
					id: r.userID,
				};
			});
	}


	render(): any {
		if (this.currentUser.userType !== UserType.D) {
			return null;
		}

		const options: BoxOptions = {
			canAdd: false,
			canEdit: false,
			canCreateTooltip: null,
			title: 'Pre-Assesses',
			canDrag: false,
			boxType: BoxType.PreAssessUser,
		};

		return <Box
      open={this.props.boxes.filter(t => t.boxType == BoxType.PreAssessUser && t.open).length > 0}
      selectedID={this.props.selectedID}
      scheduledIDs={[]}
      onDragStart={null}
      onAddClicked={() => {}}
      onEditClicked={() => {}}
      options={options}
      empty={{message: 'The list is empty'}}
      onDragEnd={null}
      items={this.items}
      onOpenCloseChanged={this.props.onOpenCloseChanged}
      itemSelected={this.props.onSelected}
    />;
	}
}
