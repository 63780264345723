import {PropsWithChildren} from 'react';
import {SortTable} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {HeaderCell as StyledHeaderCell} from '../../styled';
import {Sorting} from '../../types';
import {styled} from '@esgi/ui/theme';

type Props = {
	name: string,
	sorting: Sorting,
	onClick: VoidFunction,
} & PropsWithChildren;

export function HeaderCell(props: Props) {
	const {field, direction} = props.sorting;
	const active = field === props.name;

	return <StyledHeaderCell data-cy='header-cell'>
		{props.children}
		<Text css={{cursor: 'pointer', userSelect: 'none'}} size='small' color={active ? 'primary' : 'lowContrast'} onClick={props.onClick}>
			{props.name}
		</Text>
		<SortIndicator disabled={!active}
		               direction={!active ? 'none' : direction}
		               width='12px'
		               height='12px'
		               onClick={props.onClick}/>
	</StyledHeaderCell>;
}

export const SortIndicator = styled(SortTable, {
	cursor: 'pointer',
	variants: {
		disabled: {
			true: {
				'& path': {
					fill: '$muted',
				},
			},
		},
		direction: {
			none: {

			},
			asc: {
				'& path:first-child': {
					fill: '$muted',
				},
				'& path:last-child': {
					fill: '$primary',
				},
			},
			desc: {
				'& path:first-child': {
					fill: '$primary',
				},
				'& path': {
					fill: '$muted',
				},
			},
		},
	},
});