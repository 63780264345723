import {environment} from '@esgi/core/environments';
import {Renderable, OldAlerts, observable} from '@esgi/deprecated/knockout';
import {ServerClient} from 'pages/landing/kit/server-client';
import {ReceiptUtil, StripeUtil} from 'pages/landing/kit/stripe';
import {IsPaymentAvailableResponse, MakePaymentResponse} from './models';
import {PaymentIsUnavailable} from './payment-is-unavailable';
import {PurchaseStep} from './step-1/purchase-step';
import {PurchaseCompleteStep} from './step-2/purchase-complete-step';
import {Step} from './steps';
import {PaymentTemplate} from './templates';

import './quotePayment.less';

export class Page {
	quotePayment: QuotePayment;

	constructor() {
		this.quotePayment = new QuotePayment();
		this.quotePayment.load().done(() => {
			ko.applyBindings(this, document.body);
		});
	}

	public destroy() {
		ko.cleanNode(document.body);
	}
}

export class PurchaseRequest {
	public quoteId: number;
	public stripePaymentMethodId: string;
}

export class PurchaseRequestBuilder {
	public static GetPurchaseRequest(
		quoteId: number,
		stripePaymentMethodId: string,
	) {
		const request = new PurchaseRequest();

		request.quoteId = quoteId;
		request.stripePaymentMethodId = stripePaymentMethodId;

		return request;
	}
}

export class QuotePayment extends Renderable {
	private resultFileId: number;

	createPurchaseCompleteStep(resp: MakePaymentResponse): PurchaseCompleteStep {
		const purchaseCompleteStep = new PurchaseCompleteStep(this.quoteId);

		purchaseCompleteStep.transactionId = resp.transactionID;
		purchaseCompleteStep.orderId = resp.orderId;
		purchaseCompleteStep.email = resp.email;
		this.resultFileId = resp.fileId;
		purchaseCompleteStep.subscriptions = this.purchaseStep.orderSummary.items;

		purchaseCompleteStep.events.downloadReceiptClicked(() => {
			this.server.downloadReceipt();
		});

		purchaseCompleteStep.events.continueClicked(() => {
			let correctLink = document.location.host.replace('app', '');
			if (correctLink.startsWith('.')) {
				correctLink = `www${correctLink}`;
			}
			window.location.href = `https://${correctLink}`;
		});

		return purchaseCompleteStep;
	}

	createPurchaseStep(): PurchaseStep {
		const purchaseStep = new PurchaseStep(this.quoteId);
		purchaseStep.events.purchaseClicked(() => {
			const thisComponent = this;
			purchaseStep.validate().then((valid) => {
				if (!valid) {
					return;
				}

				purchaseStep.purchaseButton.disabled(true);
				purchaseStep.purchaseButton.title('Purchasing...');
				purchaseStep.purchaseButton.icon('fa fa-circle-o-notch fa-spin');

				purchaseStep.purchaseForm.stripeUtil.StripeObject.createPaymentMethod({
					type: StripeUtil.ElementNameCard,
					card: purchaseStep.purchaseForm.stripeUtil.GetCardElement(),
				}).then(
					function (result) {
						if (result.error) {
							OldAlerts.bsalert(
								`Credit card declined. The response from payment system was:<em>${result.error.message}</em>Please check your payment information and try again.`,
							);
							purchaseStep.purchaseButton.title('Try Again');
							purchaseStep.purchaseButton.icon('');
							purchaseStep.purchaseButton.disabled(false);
						} else {
							thisComponent.stripePaymentMethodId = result.paymentMethod.id;

							return thisComponent.server
								.makePayment()
								.then((r: MakePaymentResponse) => {
									purchaseStep.view.purchaseSuccess(r);
								})
								.fail(() => {
									purchaseStep.purchaseButton.title('Try Again');
									purchaseStep.purchaseButton.disabled(false);
								})
								.always(() => {
									purchaseStep.purchaseButton.disabled(false);
									purchaseStep.purchaseButton.icon('');
								});
						}
					},
					(resp) => {
					},
				);
			});
		});

		purchaseStep.events.purchaseSuccess((e, data) => {
			this.currentStep = this.createPurchaseCompleteStep(data);
		});

		return purchaseStep;
	}

	server = {
		isPaymentAvailable: () => {
			return ServerClient.SSOAPI.Get<IsPaymentAvailableResponse>(
				'Quotes',
				'IsPaymentAvailable',
				{data: {guid: this.token}},
			);
		},
		makePayment: () => {
			return ServerClient.SSOAPI.Post<MakePaymentResponse>(
				'Quotes',
				'MakePayment',
				{
					data: PurchaseRequestBuilder.GetPurchaseRequest(
						this.quoteId,
						this.stripePaymentMethodId,
					),
				},
			);
		},
		downloadReceipt: () => {
			const ssoUrl = `${environment.ssoApiURL}/quotes/PaymentReceiptAttachment?id=`;
			this.downloadFile(
				ssoUrl + this.resultFileId + '&guid=' + this.token,
				'pdf',
				'application/pdf',
			);
		},
	};

	private downloadFile = (url: string, extension: string, mimeType: string) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'arraybuffer';
		xhr.onload = (e: any) => {
			if (e.target.status == 200) {
				const blob = new Blob([e.target.response], {type: mimeType});
				const filenameProvider = new ReceiptUtil();
				const filename = filenameProvider.getReceiptFileName(extension);

				const link = document.createElement('a');
				link.setAttribute('type', 'hidden');
				link.href = window.URL.createObjectURL(blob);
				link.download = filename;
				document.body.appendChild(link);
				link.click();
				link.remove();
			} else {
				OldAlerts.bsalert(
					'Uh oh. Something went wrong on our end. Please contact Customer Support (support@esgisoftware.com) for assistance.',
				);
			}
		};
		xhr.send();
	};
	private quoteId: number;
	private token: string;
	private purchaseStep: PurchaseStep;

	private stripePaymentMethodId: string;

	private getParameterByName(name) {
		const url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) {
			return null;
		}
		if (!results[2]) {
			return '';
		}
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	constructor() {
		super();
		this.token = this.getParameterByName('token');
	}

	template = () => PaymentTemplate.render();

	load(): JQueryPromise<any> {
		return this.server
			.isPaymentAvailable()
			.done((res: IsPaymentAvailableResponse) => {
				if (res.isPaymentAvailable) {
					this.quoteId = res.quoteId;
					this.purchaseStep = this.createPurchaseStep();
					this.currentStep = this.purchaseStep;
				} else {
					this.currentStep = new PaymentIsUnavailable(res.wasPaymentProcessed);
				}
			});
	}

	@observable()
	currentStep: Step;
}
