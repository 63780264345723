import {CSS} from '@stitches/react';
import {Close, Justify} from '@esgi/ui/icons';
import {styled} from '@esgi/ui/theme';
import {ButtonIcon} from '../buttons/components/icon';

interface Props {
	expanded: boolean;
	setExpanded: (v: boolean) => void;
	css?: CSS
}

export const BurgerButtonContainer = styled(ButtonIcon, {
	width: '36px',
	height: '36px',
	borderRadius: '8px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',

	'& svg': {
		width: '24px',
		height: '24px',
	},

	'&:hover': {
		backgroundColor: '$secondaryBackground',
	},
});

export function BurgerButton({expanded, setExpanded, css}: Props) {
	return <BurgerButtonContainer onClick={() => setExpanded(!expanded)} css={css} data-cy='burger-button'>
		{expanded
			? <Close width={24} height={24}/>
			: <Justify width={24} height={24}/>
		}
	</BurgerButtonContainer>;
}
