import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ReactNode} from 'react';
import {styled} from '@esgi/ui/theme';
import {SkeletonWithBordersVariant} from '@esgi/ui/skeleton';

type Props = {
	text: string | number,
	label?: string,
	skeleton?: boolean,
	children?: ReactNode,
}

const SkeletonBox = styled(FlexBox, {
	variants: {
		...SkeletonWithBordersVariant,
	},
});

export function NumberRow(props: Props) {
	return <FlexBox direction='column' align='start' dataCy='number-row'>
		<SkeletonBox align='center' skeleton={props.skeleton}>
			<Text size='xLarge' color='base' css={{paddingRight: 12}} data-cy='number-row-text'>
				{props.text}
			</Text>
			{props.children}
		</SkeletonBox>
		<Text size='xSmall' font='mono' color='neutral56' css={{paddingTop: 4}} data-cy='number-row-label'>{props.label}</Text>
	</FlexBox>;
}