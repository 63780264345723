import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ClassicHierarchyLevel, HierarchyInstance, HierarchyMode} from 'modules/hierarchy/core/models';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType, UserInfo} from '@esgi/core/authentication';
import {isEqual} from 'underscore';
import {ILoadResponse, PieChartModel} from '../components/pie-charts/models';
import {SubjectModel} from './subjects-service/models';

interface PieChartsInfo {
	pieCharts: PieChartModel[];
	noStudents: boolean;
	noTests: boolean;
	canTest: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	hierarchy: HierarchyInstance;
	currentSubject: SubjectModel;
}

export default class PieChartsService extends BaseService {
	public readonly pieChartsInfo$: Subject<PieChartsInfo> = new Subject<PieChartsInfo>();
	public readonly busy$: Subject<boolean> = new Subject<boolean>();

	private readonly currentUser: UserInfo = userStorage.get();
	private lastHierarchy: HierarchyInstance;
	private lastSubject: SubjectModel;

	public loadPieCharts(subject: SubjectModel, hierarchy: HierarchyInstance): Observable<void> {
		if (!subject) {
			this.pieChartsInfo$.next({
				pieCharts: [],
				noStudents: false,
				noTests: true,
				canTest: false,
				testResultsCorrectVerbiage: '',
				testResultsIncorrectVerbiage: '',
				hierarchy: hierarchy,
				currentSubject: null,
			});
			return of();
		}

		this.busy$.next(true);

		return this.httpClient.ESGIApi.get<ILoadResponse>(
			'pages/home/test-results',
			'pie-charts',
			{
				subjectId: subject.id,
				subjectType: subject.type,
				hierarchy: hierarchy.snapshot,
			},
		).pipe(map(({
			pieCharts,
			totalStudentsWithoutFilters,
			canTest,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
		}) => {
			let noStudents = false;
			const noTests = pieCharts.length === 0;

			if (!noTests) {
				noStudents = pieCharts[0]?.totalStudents === 0;
			}
			noStudents = noStudents && [
				UserType.T,
				UserType.ISD,
				UserType.ISS,
				UserType.PA,
			].indexOf(this.currentUser.userType) !== -1 && totalStudentsWithoutFilters === 0;

			this.pieChartsInfo$.next({
				pieCharts,
				noStudents,
				noTests,
				canTest,
				testResultsCorrectVerbiage,
				testResultsIncorrectVerbiage,
				hierarchy,
				currentSubject: subject,
			});
			this.busy$.next(false);
		})).asObservable();
	}

	public async reloadPieCharts(
		hierarchy: HierarchyInstance,
		subject: SubjectModel,
		forceUpdate = false,
	) {
		let shouldUpdate = !isEqual(subject, this.lastSubject);
		this.lastSubject = subject;
		shouldUpdate = shouldUpdate || this.shouldUpdateByHierarchy(hierarchy);
		this.lastHierarchy = hierarchy;

		if (forceUpdate || shouldUpdate) {
			await this.loadPieCharts(subject, hierarchy).toPromise();
		}
	}

	private shouldUpdateByHierarchy(current: HierarchyInstance): boolean {
		const prev = this.lastHierarchy;
		if (!prev || !current) {
			return false;
		}

		if (current.equal(prev)) {
			return false;
		}

		if (current.mode !== prev.mode) {
			return true;
		}

		if (current.mode === HierarchyMode.Classic) {
			const selected = current.classic.selected;

			if ([ClassicHierarchyLevel.District, ClassicHierarchyLevel.School].some(r => r === selected.level)) {
				return true;
			}

			if (selected.level === ClassicHierarchyLevel.Teacher) {
				return true;
			}

			if (selected.level === ClassicHierarchyLevel.Student || prev.classic.selected.level === ClassicHierarchyLevel.Student) {
				return true;
			}

			if (current.classic.classID !== prev.classic.classID && (selected.level === ClassicHierarchyLevel.Class || selected.level === ClassicHierarchyLevel.TeachersGroup)) {
				return true;
			}

			if (selected.level === ClassicHierarchyLevel.SchoolsGroup) {
				if (current.classic.schoolsGroupID !== prev.classic.schoolsGroupID) {
					return true;
				}

				if (current.classic.schoolID !== prev.classic.schoolID) {
					return true;
				}
			}

			if (current.classic.teachersGroupID !== prev.classic.teachersGroupID && selected.level === ClassicHierarchyLevel.TeachersGroup) {
				return true;
			}

			return selected.level === ClassicHierarchyLevel.Group || prev.classic.selected.level === ClassicHierarchyLevel.Group;
		}

		return !current.specialist.equal(prev.specialist) || !current.preAssess.equal(prev.preAssess);
	}
}
