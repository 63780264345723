import {Entities} from 'api/entities/group';
import React from 'react';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, IClassBox, IGroupBox, IGroupModel} from '../../core/api';
import {HierarchyEvents} from '../../core/events';
import {BoxType} from '../../core/models';
import {OsChecker} from '@esgillc/ui-kit/utils';
import {EventBusDispatcher} from '@esgillc/events';

export class Props {
	boxes: IBoxInfo[];
	groups: IGroupBox;
	classes: IClassBox; // needed only for publish className. best way - remove it.
	classID: number;
	teacherID: number;
	selectedID: number;
	onSelected: (id: number, callback: () => void) => void;
	onOrderChanged: (items: IGroupModel[]) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class State {
}

export class Group extends React.Component<Props, State> {

	cmp: Box;
	private draggingTurnedOn: boolean = false;

	get items(): ItemProps[] {
		return Group.Filter(this.props.groups.items, this.props.classID);
	}

	static Filter(items: IGroupModel[], classID: number): ItemProps[] {
		return items
			.filter(s => s.classID === classID)
			.map(r => {
				return {id: r.groupID, title: r.name};
			});
	}

	render() {
		if (!this.props.classID) {
			return null;
		}

		const options: BoxOptions = {
			canDrag: this.props.groups.canEdit,
			boxType: BoxType.ClassicGroup,
			title: 'All Groups',
			canCreateTooltip: null,
			canAdd: this.props.groups.canCreate,
			canEdit: this.props.groups.canEdit,
			tooltipPostfix: 'group',
		};


		return <Box
			options={options}
			selectedID={this.props.selectedID}
			items={this.items}
			onDragEnd={null}
			onDragStart={null}
			empty={{message: 'You have 0 groups'}}
			scheduledIDs={null}
			onEditClicked={this.publish.edit}
			onAddClicked={this.publish.add}
			itemSelected={this.props.onSelected}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
			open={this.props.boxes.filter(t => t.boxType === BoxType.ClassicGroup && t.open).length > 0}
			ref={(r) => {
				this.cmp = r;
			}}
		/>;
	}

	publish = {
		add: () => {
			const cl = this.props.classes.items.find(t => t.classID === this.props.classID);
			const args: HierarchyEvents.Group.AddArgs = {
				teacherID: this.props.teacherID,
				classID: this.props.classID,
				className: cl ? cl.name : '',
			};

			EventBusDispatcher.dispatch(HierarchyEvents.Group.Add, args);
		},
		edit: (id: number) => {
			const item = this.props.groups.items.find(t => t.groupID === id);
			const cl = this.props.classes.items.find(t => t.classID === this.props.classID);
			const args: HierarchyEvents.Group.EditArgs = {
				teacherID: this.props.teacherID,
				classID: item.classID,
				id: item.groupID,
				name: item.name,
				className: cl ? cl.name : '',
			};

			EventBusDispatcher.dispatch(HierarchyEvents.Group.Edit, args);
		},
	}

	componentDidMount(): void {
		this.toggleDrag();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		this.toggleDrag();
	}

	private toggleDrag() {
		if (this.props.groups.canEdit) {
			this.turnDragOn();
		} else {
			this.turnDragOff();
		}
	}

	private turnDragOff() {
		if (!this.draggingTurnedOn) {
			return;
		}

		$(this.cmp.rootElement).find('.box-body').sortable('destroy');
		this.draggingTurnedOn = false;
	}

	private turnDragOn() {
		if (this.draggingTurnedOn) {
			return;
		}

		if (!this.cmp) {
			return;
		}

		$(this.cmp.rootElement).find('.box-body').sortable({
			items: '.item:not(.empty)',
			opacity: 0.8,
			revert: true,
			handle: '.drag',
			forceHelperSize: true,
			placeholder: 'item placeholder',
			containment: 'parent',
			forcePlaceholderSize: true,
			tolerance: 'pointer',
			scroll: true,

			update: () => {
				const ids = $(this.cmp.rootElement).find('.item').toArray().map((e) => parseInt(e.getAttribute('data-id')))
					.filter(e => !isNaN(e));

				const groups = [...this.props.groups.items].sort((a, b) => ids.indexOf(a.groupID) - ids.indexOf(b.groupID));

				Entities.Group.reorder(ids);
				this.props.onOrderChanged(groups);

				return;
			},
			start: (event, ui) => {
				if (OsChecker.isMac()) {
					$(ui.item).children('.drag').css({cursor: 'grabbing'});
				}
			},
			stop: (event, ui) => {
				if (OsChecker.isMac()) {
					$(ui.item).children('.drag').removeClass('mousedown').css({cursor: 'grab'});
				}
			},
		});

		this.draggingTurnedOn = true;
	}
}
