import {BaseComponentProps, ThemeColorKeys, getThemeColor} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
		gradeValue: string;
		indicatorColor: ThemeColorKeys;
		indicatortextColor: NonNullable<VariantProps<typeof Text>['color']>;
	};

export const GradeIndicator = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'test-dashboard-grade-indicator', css = {}, gradeValue, indicatorColor, indicatortextColor, ...props},
		forwardedRef,
	) => (
		<Container
			dataCy={dataCy}
			css={{backgroundColor: getThemeColor(indicatorColor), ...css}}
			ref={forwardedRef}
			{...props}
		>
			<Text size='small' bold color={indicatortextColor}>
				{gradeValue}
			</Text>
		</Container>
	),
);
