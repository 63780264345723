/* eslint-disable camelcase */
import {UserInfo} from '@esgi/core/authentication';
import {Module} from '@esgi/deprecated/knockout';
import {Api, StateModel} from './server';
import {TabTemplate} from './template';

export class SchoolInfoTabModel {
	CountryID: number;
	countryName: string;
	StateID: number;
	StateName: string;
	DistrictID: number;
	federalDistrictId: number;
	federalDistrictName: string;
	schoolId: number;
	federalSchoolId: number;
	schoolName: string;
	TeacherID: string;
}

export interface IValidationSetting {
	cssIcon: string;
	message: string;
	validationStatus: KnockoutObservable<boolean>;
	validate: () => JQueryPromise<boolean>;
}

export class ValidationSetting implements IValidationSetting {
	cssIcon = 'ace-icon fa fa-times';
	message = '';

	validate() {
		const promise = this.validateFunc();
		const result = $.Deferred<boolean>();
		promise.done(() => {
			this.validationStatus(true);
			$(this).trigger('onValidateSuccess');
			result.resolve();
		}).fail((object) => {
			this.validationStatus(false);
			let message = this.message;
			if (typeof object == 'string') {
				message = object;
			}
			$(this).trigger('onValidateFail', message);
			result.reject(message);
		});
		return result.promise();
	}

	validationStatus = ko.observable<boolean>(true);

	private validateFunc: () => JQueryPromise<boolean | string>;

	constructor(validateFunc: () => JQueryPromise<boolean | string>, validateSuccessCallback?: () => void, validateFailCallback?: (eventType, message) => void) {
		this.validateFunc = validateFunc;
		if (typeof validateSuccessCallback === 'function') {
			this.events.onValidateSuccess(validateSuccessCallback);
		}

		if (typeof validateFailCallback === 'function') {
			this.events.onValidateFail(validateFailCallback);
		}
	}

	events = {
		onValidateSuccess: (callback) => {
			$(this).on('onValidateSuccess', callback);
		},
		onValidateFail: (callback) => {
			$(this).on('onValidateFail', callback);
		},
	};

}

export class AutoQuoterTabModule extends Module {

	renewApi = new Api();
	server = this.renewApi.api;

	load(): JQueryPromise<any> {
		return this.initModelApi().then((model) => {
			this.rawModel = model;
			return this.server.getCountries().done((countries) => {
				this.countries(countries);
				if (this.rawModel.CountryID) {
					this.model.countryId(this.rawModel.CountryID);
				}
			}).then(() => {
				if (!this.rawModel.CountryID) {
					return $.Deferred().resolve();
				}
				return this.server.getStatesByCountryId(this.rawModel.CountryID).done((states) => {
					this.states(states);
					if (this.rawModel.StateID) {
						this.model.stateId(this.rawModel.StateID);
					}
				});
			}).then(() => {
				if (!this.rawModel.StateID) {
					return $.Deferred().resolve();
				}
				return this.server.getDistrictByState(this.rawModel.StateID).done(({federalDistricts}) => {
					this.federalDistricts(federalDistricts);
				});
			}).then(() => {
				const action = this.rawModel.federalDistrictId > 0
					? this.server.getSchoolByFederalDistrictId(this.model.federalDistrictId())
					: $.Deferred().resolve({federalSchools: []});

				return action.done(({federalSchools}) => {
					this.federalSchools.push({
						federalSchoolId: 0,
						schoolName: 'Multiple Schools',
					});
					for (let i = 0; i < federalSchools.length; i++) {
						this.federalSchools.push(federalSchools[i]);
					}
					this.initModule(model);
				});
			});
		});
	}

	afterRender(rootElement) {
		function isNumber(n) {
			n = +n;
			return !isNaN(parseFloat(n)) && isFinite(n);
		}

		this.rootElement = rootElement;
		this.model.countryId.subscribe((value) => {
			this.states([]);
			this.federalDistricts([]);
			this.federalSchools([]);

			if (!isNumber(value)) {
				return;
			}

			this.server.getStatesByCountryId(this.model.countryId()).done((states) => {
				this.states(states);
			});
			this.validate.countryName.validate();
		});

		this.model.stateId.subscribe((value) => {
			this.federalDistricts([]);
			this.federalSchools([]);

			if (!isNumber(value)) {
				return;
			}

			this.server.getDistrictByState(this.model.stateId()).done(({federalDistricts}) => {
				this.federalDistricts(federalDistricts);
				this.validate.stateName.validate();
			});
		});

		this.model.federalDistrictId.subscribe((value) => {
			this.federalSchools([]);

			if (!isNumber(value)) {
				return;
			}

			const action = this.model.federalDistrictId() > 0
				? this.server.getSchoolByFederalDistrictId(this.model.federalDistrictId())
				: $.Deferred().resolve({federalSchools: []});

			action.done(({federalSchools}) => {
				this.federalSchools.push({
					federalSchoolId: 0,
					schoolName: 'Multiple Schools',
				});

				for (let i = 0; i < federalSchools.length; i++) {
					this.federalSchools.push(federalSchools[i]);
				}
			});
		});

		this.model.federalSchoolId.subscribe((value) => {
			if (!isNumber(value)) {
				return;
			}
			this.validate.schoolName.validate();
		});

		return $.Deferred<any>().resolve(rootElement).promise();
	}

	inArray(value, type, array) {
		let found = false;
		for (let i = 0, max = array.length; i < max; ++i) {
			if (array[i][type] === value) {
				found = true;
				break;
			}
		}
		return found;
	}

	schoolTimeout: number;
	districtTimeout: number;
	stateTimeout: number;
	countryTimeout: number;

	validate = {
		schoolName: new ValidationSetting(() => {
			const promise = $.Deferred<string>();

			if (this.model.federalSchoolId() === undefined) {
				return promise.reject('Required');
			}
			const isFound = this.inArray(this.model.federalSchoolId(), 'federalSchoolId', this.federalSchools());

			if (!isFound) {
				return promise.reject('Required');
			}
			return promise.resolve();

		},
		() => {
			const element = $('.federal-schools-container', this.rootElement);
			window.clearTimeout(this.schoolTimeout);
			element.bstooltip('destroy');
		},
		(eventType, message) => {
			const element = $('.federal-schools-container', this.rootElement);
			window.clearTimeout(this.schoolTimeout);
			element.bstooltip('destroy');

			const tooltip = element.bstooltip({
				trigger: 'hover',
				title: message,
				placement: 'right',
			});

			tooltip.bstooltip('show');

			this.schoolTimeout = window.setTimeout(() => {
				element.bstooltip('destroy');
			},
			2000);
		}),

		districtName: new ValidationSetting(() => {
			const promise = $.Deferred<string>();

			if (!this.model.federalDistrictId()) {
				return promise.reject('Required');
			}
			const isFound = this.inArray(this.model.federalDistrictId(),
				'id',
				this.federalDistricts());

			if (!isFound) {
				return promise.reject('Required');
			}
			return promise.resolve();

		},
		() => {
			const element = $('.federal-districts-container', this.rootElement);
			window.clearTimeout(this.districtTimeout);
			element.bstooltip('destroy');
		},
		(eventType, message) => {
			const element = $('.federal-districts-container', this.rootElement);
			window.clearTimeout(this.districtTimeout);
			element.bstooltip('destroy');

			const tooltip = element.bstooltip({
				trigger: 'hover',
				title: message,
				placement: 'right',
			});

			tooltip.bstooltip('show');

			this.districtTimeout = window.setTimeout(() => {
				element.bstooltip('destroy');
			},
			2000);
		}),

		stateName: new ValidationSetting(() => {
			const promise = $.Deferred<string>();

			if (!this.model.stateId()) {
				return promise.reject('Required');
			}
			const isFound = this.inArray(this.model.stateId(), 'id', this.states());

			if (!isFound) {
				return promise.reject('Required');
			}
			return promise.resolve();

		},
		() => {
			const element = $('.states-container', this.rootElement);
			window.clearTimeout(this.stateTimeout);
			element.bstooltip('destroy');
		},
		(eventType, message) => {
			const element = $('.states-container', this.rootElement);
			window.clearTimeout(this.stateTimeout);
			element.bstooltip('destroy');

			const tooltip = element.bstooltip({
				trigger: 'hover',
				title: message,
				placement: 'right',
			});

			tooltip.bstooltip('show');

			this.stateTimeout = window.setTimeout(() => {
				element.bstooltip('destroy');
			},
			2000);
		}),

		countryName: new ValidationSetting(() => {
			const promise = $.Deferred<string>();
			if (!this.model.countryId()) {
				return promise.reject();
			}

			const isFound = this.inArray(this.model.countryId(), 'countryID', this.countries());

			if (!isFound) {
				return promise.reject('Required');
			}
			return promise.resolve();

		},
		() => {
			const element = $('.countries-container', this.rootElement);
			window.clearTimeout(this.countryTimeout);
			element.bstooltip('destroy');
		},
		(eventType, message) => {
			const element = $('.countries-container', this.rootElement);
			window.clearTimeout(this.countryTimeout);
			element.bstooltip('destroy');

			const tooltip = element.bstooltip({
				trigger: 'hover',
				title: message,
				placement: 'right',
			});

			tooltip.bstooltip('show');

			this.countryTimeout = window.setTimeout(() => {
				element.bstooltip('destroy');
			},
			2000);
		}),
	};


	private userContext: UserInfo;

	protected initModelApi: () => JQueryPromise<SchoolInfoTabModel>;

	private getById<T>(array: Array<T>, idField: string, searchId: any): T {
		for (let i = 0; i < array.length; i++) {
			if (array[i][idField] == searchId) {
				return array[i];
			}
		}
		return null;
	}


	districtChosenOption = {
		disable_search_threshold: 10,
		width: '100%',
		search_contains: true,
		skip_no_results: true,
		persistent_create_option: true,
		show_crate_option_if_empty_list: true,
		set_create_option_as_default_action: true,
		use_simple_create_option: true,
		hide_list_if_empty_text: false,
		create_option_text: 'My District is not listed',
		placeholder_text_single: 'Please type the name of your district',
		create_option: (districtName) => {
			if (districtName != null && districtName.trim().length > 0) {
				let fd = this.getById(this.federalDistricts(), 'id', -1);
				if (fd != null) {
					this.federalDistricts.remove(fd);
				}
				fd = {
					name: districtName,
					id: -1,
				};
				this.federalDistricts.unshift(fd);
				this.model.federalDistrictId(-1);
				this.model.districtName(districtName);
			}
		},
	};

	schoolChosenOption = {
		disable_search_threshold: 10,
		width: '100%',
		search_contains: true,
		skip_no_results: true,
		persistent_create_option: true,
		show_crate_option_if_empty_list: true,
		set_create_option_as_default_action: true,
		use_simple_create_option: true,
		hide_list_if_empty_text: false,
		create_option_text: 'My school is not listed',
		placeholder_text_single: 'Please type the name of your school',
		create_option: (schoolName) => {
			if (schoolName != null && schoolName.trim().length > 0) {
				let fs = this.getById(this.federalSchools(), 'FederalSchoolId', -1);
				if (fs != null) {
					this.federalSchools.remove(fs);
				}
				fs = {
					schoolName: schoolName,
					federalSchoolId: -1,
				};
				this.federalSchools.unshift(fs);
				this.model.federalSchoolId(-1);
				this.model.schoolName(schoolName);
			}
		},
	};


	constructor(userContext: UserInfo, initModelApi: () => JQueryPromise<SchoolInfoTabModel>) {
		super();
		this.userContext = userContext;
		this.initModelApi = initModelApi;
	}

	protected rawModel: SchoolInfoTabModel;
	countries = ko.observableArray([]);
	states = ko.observableArray<StateModel>([]);
	federalDistricts = ko.observableArray<{id: number, name: string}>([]);
	federalSchools = ko.observableArray([]);

	protected model = {
		countryId: ko.observable(0),
		stateId: ko.observable(0),
		districtName: ko.observable(''),
		federalDistrictId: ko.observable(0),
		schoolName: ko.observable(''),
		federalSchoolId: ko.observable(0),
	};


	protected initModule(rawModel: SchoolInfoTabModel) {
		this.rawModel = rawModel;
		this.model.countryId(rawModel.CountryID);
		this.model.stateId(rawModel.StateID);

		this.model.districtName(rawModel.federalDistrictName);
		this.model.federalDistrictId(rawModel.federalDistrictId);
		if (rawModel.federalDistrictId <= 0) {
			this.model.federalDistrictId(-1);
		}

		this.model.schoolName(rawModel.schoolName);
		this.model.federalSchoolId(rawModel.federalSchoolId);
		if (rawModel.federalSchoolId <= 0) {
			this.model.federalSchoolId(-1);
		}
	}

	extractModel(): JQueryPromise<SchoolInfoTabModel> {
		return $.when(this.validate.schoolName.validate(), this.validate.districtName.validate()).then(() => {
			const model = new SchoolInfoTabModel();
			model.TeacherID = this.rawModel.TeacherID;

			const state = this.getById(this.states(), 'id', this.model.stateId());
			if (state != null) {
				model.StateName = state.name;
				model.StateID = state.id;
			}

			const country = this.getById(this.countries(), 'countryID', this.model.countryId());
			if (country != null) {
				model.countryName = country.name;
				model.CountryID = country.countryID;
			}

			model.federalDistrictId = this.model.federalDistrictId();
			model.federalSchoolId = this.model.federalSchoolId();

			const federalDistrict = this.getById(this.federalDistricts(), 'id', model.federalDistrictId);
			model.federalDistrictName = federalDistrict.name;

			const federalSchool = this.getById(this.federalSchools(), 'federalSchoolId', model.federalSchoolId);
			model.schoolName = federalSchool.schoolName;

			if (model.federalDistrictId == -1) {
				model.federalDistrictId = null;
			}
			if (model.federalSchoolId == -1) {
				model.federalSchoolId = null;
			}

			model.schoolId = this.rawModel.schoolId;
			model.DistrictID = this.rawModel.DistrictID;
			return model;

		}, () => {
			return null;
		});
	}

	events = {
		onClose: (callback) => {
			$(this).on('onClose', callback);
		},
	};

	template = () => TabTemplate.render();
}
