import {DotsIcon} from '@esgillc/ui-kit/icons';
import {join} from '@esgillc/ui-kit/utils';
import {useRef, useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {Alert} from '@esgillc/ui-kit/modal';
import {Portal} from '@esgillc/ui-kit/core';
import {SubjectEntity} from 'api/entities';
import {SubjectType} from '@esgi/core/enums';
import SubjectDetails from 'modules/subject-details';
import {SubjectModel} from '../../../services/subjects-service/models';
import {ContextMenu} from './components/context-menu/context-menu';
import {Lock} from './components/lock';
import styles from './subject.module.less';

type Props = {
	selected: boolean;
	initModel: SubjectModel;
	subjectClicked: () => any;
}

export function Subject(props: Props): JSX.Element {
	const subject = props.initModel;
	const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
	const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
	const [showSubjectHideAlert, setShowSubjectHideAlert] = useState<boolean>(false);

	const ref = useRef<HTMLDivElement>();

	let subjectDetailsDialog;
	if (showDetailsDialog) {
		subjectDetailsDialog = <SubjectDetails subjectID={subject.id}
		                                       subjectName={subject.name}
		                                       onSaved={() => setShowDetailsDialog(false)}
		                                       onClosed={() => setShowDetailsDialog(false)}
		/>;
	}

	let subjectHideAlert;
	if (showSubjectHideAlert) {
		subjectHideAlert = <Alert>
			<Alert.Body>
				This subject tab is currently published and cannot be hidden. Please un-publish the subject tab first.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={() => setShowSubjectHideAlert(false)}>OK</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}

	const className = join(
		styles.subjectTab,
		props.selected && styles.selected,
		subject.level === 'School' && styles.school,
		subject.level === 'District' && styles.district,
		!subject.published && styles.notPublished,
	);

	return <div ref={ref}
	            data-id={subject.id}
	            className={className}
	            data-name={subject.name}>
		{!subject.published && <Lock subject={subject} selected={props.selected}/>}
		<a href='#' onClick={props.subjectClicked} className={styles.text}>{subject.name}</a>
		{subject.canEdit &&
			<a className={styles.dots} href='#' onClick={() => setShowContextMenu(true)}>
				<DotsIcon/>
			</a>
		}
		<Portal owner={document.getElementById('subject-context-menu-holder')}>
			<ContextMenu show={showContextMenu}
			             relatedElementRef={ref}
			             renameClicked={() => {
				             setShowContextMenu(false);
				             setShowDetailsDialog(true);
			             }}
			             hideClicked={() => {
				             if (subject.type === SubjectType.Personal || !subject.published) {
					             setShowContextMenu(false);
					             SubjectEntity.hide(subject.id, subject.type);
				             } else {
					             setShowSubjectHideAlert(true);
				             }
			             }}
			             close={() => setShowContextMenu(false)}/>
		</Portal>
		{subjectHideAlert}
		{subjectDetailsDialog}
	</div>;
}
