import {useMemo} from 'react';
import {Class} from '@esgi/main/libs/store';
import {NewFolder, UserCard, UserList, Users} from '@esgi/ui/icons';
import {StudentSectionModePermissions, StudentTabId} from '../../../types';
import {useTeacherPageContext} from '../../../context/teacher-page-context';
import {AddModeMenuListItem} from '@esgi/main/features/teacher/home';
import {StudentSectionMode} from '../types';

export function useAddSectionModeItems({
	sectionModePermissions,
	selectedClassId,
	selectedStudentId,
}: {
	sectionModePermissions: StudentSectionModePermissions;
	selectedClassId: Class['id'] | null;
	selectedStudentId: number | null;
}) {
	const {openAddClassForm, openAddGroupForm, openAddStudentViaFileForm, openAddStudentViaProfile} =
		useTeacherPageContext();

	return useMemo<AddModeMenuListItem[]>(
		() => [
			{
				itemText: 'Add a Student...',
				Icon: UserCard,
				permitted: sectionModePermissions[StudentSectionMode.Add][StudentTabId.Students],
				dataCy: 'students-panel-menu-item-add-student',
				onClick: () =>
					openAddStudentViaProfile({
						classId: selectedClassId ? String(selectedClassId) : null,
						studentId: String(selectedStudentId),
					}),
			},
			{
				itemText: 'Add Students via File...',
				Icon: UserList,
				permitted:
					sectionModePermissions[StudentSectionMode.Add][StudentTabId.Students] &&
					Boolean(selectedClassId) &&
					selectedClassId !== -1,
				dataCy: 'students-panel-menu-item-add-student-via-file',
				onClick: () => openAddStudentViaFileForm({classId: selectedClassId ? String(selectedClassId) : null}),
				notPermittedTooltipText: 'Please select a class first',
			},
			{
				itemText: 'Add a Class...',
				Icon: Users,
				permitted: sectionModePermissions[StudentSectionMode.Add][StudentTabId.Classes],
				notPermittedTooltipText: 'Managing classes is disabled for your district.\nPlease contact your administrator to make any changes\nto your roster sharing services.',
				dataCy: 'students-panel-menu-item-add-class',
				onClick: openAddClassForm,
			},
			{
				itemText: 'Add a Group...',
				Icon: NewFolder,
				permitted: sectionModePermissions[StudentSectionMode.Add][StudentTabId.Groups],
				dataCy: 'students-panel-menu-item-add-group',
				onClick: () => openAddGroupForm({classId: selectedClassId}),
			},
		],
		[
			sectionModePermissions,
			selectedClassId,
			openAddClassForm,
			openAddGroupForm,
			openAddStudentViaProfile,
			selectedStudentId,
			openAddStudentViaFileForm,
		],
	);
}
