import {Drawer} from '@esgi/main/kits/common';
import {UserCard} from '@esgi/ui/icons';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {DrawerBody} from '../../drawer-body.styled';
import {ExpirationDate} from '../../expiration-date';
import {TrackModel, UserModel} from '../../../types';
import {DisabledTrackDates} from '../../panels/components/disabled-track-dates';
import {Logout} from '../../logout';
import {GridBox} from '@esgi/ui/layout';
import {DisabledInput} from '../../panels/components/disabled-input';
import {trackTypeTranscript} from '../../../constants';

type Props = {
	onClose: VoidFunction;
	user: UserModel;
	visibleTrack: TrackModel;
};

export function ViewMode({onClose, user, visibleTrack}: Props) {
	return (
		<>
			<Drawer.Header Icon={UserCard} tooltipText='My Account' sectionName='My Account' closeDrawer={onClose} />
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<DrawerBody>
					<Drawer.ContentBlock title='Expiration Date'>
						<ExpirationDate
							date={user?.expirationDate ? new Date(user.expirationDate) : null}
							withRenewButton={user ? !user.notRenewable : false}
							canAllowRenewByCC={user?.canAllowRenewByCC}
						/>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Current Marking Period'>
						<GridBox flow='column' gap='3'>
							<DisabledInput dataCy='current-marking-period-input' value={visibleTrack.name} placeholder='Marking Period Name' />
							<DisabledInput dataCy='current-marking-period-select' value={trackTypeTranscript[visibleTrack.schoolYearType]} placeholder='Type' />
						</GridBox>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Marking Period Dates'>
						<DisabledTrackDates rowGap='16' track={visibleTrack} counterSize='small' />
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Terminate Current Session'>
						<Logout />
					</Drawer.ContentBlock>
				</DrawerBody>
			</OverlayScrollbarsComponent>
		</>
	);
}
