import {useCallback, useEffect} from 'react';
import {isNull, noop} from 'underscore';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {useService} from '@esgi/core/service';
import {SubjectTab} from '@esgi/main/libs/store';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {TestSection, TestSectionContentCommonProps, TestSectionContentType} from '@esgi/main/features/teacher/home';
import {DataTestsService} from '../../services/data-tests-service';
import {TestSavedEvent} from '@esgi/main/features/assessments';
import {UpdateWidgetsEvent} from '@esgi/main/features/teacher/widgets';
import {
	TestSessionRubricCreatedEvent,
	TestSessionScoreCreatedEvent,
	TestSessionUpdated,
	TestSessionYNCreatedEvent,
} from '@esgi/main/features/teacher/test-session-details';
import {useUser} from '@esgi/core/authentication';
import {TestSectionContentGroupStudents, TestSectionContentSingleStudent} from './types';
import {TestChanged} from 'shared/modules/test-details/events';
import {RubricChangedEvent} from 'modules/assets/tests/rubric/details/events';
import {TestType} from '@esgi/core/enums';

type Props = Pick<
	TestSectionContentCommonProps,
	'subject' | 'hasSubjects' | 'hasStudents' | 'widgets' | 'onAddTestClick'
> & {
	showAddTestDrawer: boolean;
	singleStudentData: TestSectionContentSingleStudent | null;
	groupStudentsData: TestSectionContentGroupStudents | null;
};

export function TestSectionContent({showAddTestDrawer, singleStudentData, groupStudentsData, ...props}: Props) {
	const currentUser = useUser();
	const dataService = useService(DataTestsService);

	const isLoadedData = useBehaviorSubject(dataService.isLoadedData$);

	const allSingleStudentTests = useBehaviorSubject(dataService.allSingleStudentTests$);
	const allGroupStudentsTests = useBehaviorSubject(dataService.allGroupStudentsTests$);

	const fetchTestCards = useCallback(
		({withCheckingShowingTestDrawer}: {withCheckingShowingTestDrawer: boolean}) => {
			if (isNull(props.subject) || (withCheckingShowingTestDrawer && showAddTestDrawer)) {
				return;
			}

			if (!isNull(singleStudentData)) {
				dataService.fetchTestCardsByStudent({
					subjectID: props.subject.id,
					subjectType: props.subject.type,
					studentID: singleStudentData.student.id,
					studentsIDsForStatistic: singleStudentData.studentsIDsForStatistic,
				});

				return;
			}

			if (!isNull(groupStudentsData)) {
				dataService.fetchTestCardsByStudents({
					subjectID: props.subject.id,
					subjectType: props.subject.type,
					studentIDs: groupStudentsData.studentsIDs,
				});

				return;
			}
		},
		[dataService, groupStudentsData, props.subject, showAddTestDrawer, singleStudentData],
	);

	useEventEffect(
		TestSavedEvent,
		(args) => {
			if (allSingleStudentTests.some((t) => t.testInfo.id === args.data.testID)) {
				fetchTestCards({withCheckingShowingTestDrawer: false});

				dispatchAppEvent(UpdateWidgetsEvent);
			}
		},
		[fetchTestCards],
	);

	useEventEffect(TestSessionUpdated, () => {
		fetchTestCards({withCheckingShowingTestDrawer: true});
	});

	useEventEffect(TestSessionYNCreatedEvent, () => {
		fetchTestCards({withCheckingShowingTestDrawer: true});
	});

	useEventEffect(TestSessionScoreCreatedEvent, () => {
		fetchTestCards({withCheckingShowingTestDrawer: true});
	});

	useEventEffect(TestSessionRubricCreatedEvent, () => {
		fetchTestCards({withCheckingShowingTestDrawer: true});
	});

	useEventEffect(TestChanged, ({id, color, contentArea, isWhiteBackground, newName, testType}) => {
		if (!isNull(props.subject) && (testType === TestType.YN || testType === TestType.Score)) {
			dataService.updateTestInStorage({
				subjectID: props.subject.id,
				testID: id,
				newTestValue: {
					color,
					contentArea,
					isWhiteBackground,
					name: newName,
				},
			});
		}
	});

	useEventEffect(RubricChangedEvent, (args) => {
		if (isNull(props.subject)) {
			return;
		}

		const rubricModel = args.rubricModel;

		dataService.updateTestInStorage({
			subjectID: props.subject.id,
			testID: rubricModel.id,
			newTestValue: {
				color: rubricModel.color,
				name: rubricModel.name,
				contentArea: args.contentAreaName,
			},
		});
	});

	useEffect(() => {
		fetchTestCards({withCheckingShowingTestDrawer: true});
	}, [props.subject, groupStudentsData, singleStudentData, showAddTestDrawer]);

	const onMoveTest = useCallback(
		({previousSubject, newSubject, testID}: {previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}) => {
			dataService.onMoveToTestRequest({previousSubject, newSubject, testID}).subscribe(() => {
				fetchTestCards({withCheckingShowingTestDrawer: true});
			});
		},
		[dataService, fetchTestCards],
	);

	const onTestRemove = useCallback(
		({subjectID, testID}: {subjectID: SubjectTab['id']; testID: number}) => {
			dataService.onRemoveTestRequest({subjectID, testID}).subscribe(() => {
				fetchTestCards({withCheckingShowingTestDrawer: true});
			});
		},
		[dataService, fetchTestCards],
	);

	if (!isNull(singleStudentData)) {
		return (
			<TestSection.Content
				contentType={TestSectionContentType.SingleStudent}
				isLoadedData={isLoadedData}
				onTestMoveTo={onMoveTest}
				onRemoveTest={onTestRemove}
				showSelfAssessOption={Boolean(currentUser?.showSelfAssessOption)}
				allTests={allSingleStudentTests}
				updateTestsOrder={(tests) => {
					if (!isNull(props.subject)) {
						dataService.reorderAllSingleStudentTests({tests, subjectInfo: props.subject});
					}
				}}
				{...props}
				{...singleStudentData}
			/>
		);
	}

	if (!isNull(groupStudentsData)) {
		return (
			<TestSection.Content
				contentType={TestSectionContentType.GroupStudents}
				isLoadedData={isLoadedData}
				onTestMoveTo={onMoveTest}
				onRemoveTest={onTestRemove}
				allTests={allGroupStudentsTests}
				updateTestsOrder={(tests) => {
					if (!isNull(props.subject)) {
						dataService.reorderAllGroupStudentsTests({tests, subjectInfo: props.subject});
					}
				}}
				{...props}
				{...groupStudentsData}
			/>
		);
	}

	return (
		<TestSection.Content
			contentType={TestSectionContentType.Default}
			isLoadedData={isLoadedData}
			onTestMoveTo={noop}
			onRemoveTest={noop}
			{...props}
		/>
	);
}
