import {PropsWithChildren} from 'react';
import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

interface Props extends PropsWithChildren {
	onClose: VoidFunction;
	onDownload: VoidFunction;
}

export function Dialog({children, ...props}: Props) {
	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, props.onClose);

	return (
		<Alert
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.neutral}
		>
			<Alert.Header onCloseIconClick={onClose}>
				<Text size='large'>Preview</Text>
			</Alert.Header>
			{children}
			<Alert.Footer>
				<Button
					color='primary'
					onClick={() => {
						props.onDownload();
						onClose();
					}}
				>
					<Text size='medium' bold>
						Download
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
