import {SkeletonShape} from '@esgi/main/kits/common';

export function HeaderTitleSkeleton() {
	return (
		<>
			<SkeletonShape width={90} height={12} />
			<SkeletonShape width={72} height={12} />
		</>
	);
}
