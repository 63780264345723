import {BaseService} from '@esgi/core/service';
import {concat, filter, Observable, OperatorFunction, publish, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TestType} from '@esgi/core/enums';
import {QuestionModel} from '../models';
import {convertToShareQuestion} from '../utils';
import {InitOptions} from './models';
import {TeacherScreenClient} from './teacher-client';

export function delayUntil<T>(
	notifier: Observable<any>,
): OperatorFunction<T, T> {
	return source =>
		source.pipe(
			publish(published => {
				const delayed = new Observable<T>(subscriber => {
					let buffering = true;
					const buffer: T[] = [];
					const subscription = new Subscription();
					subscription.add(
						notifier.subscribe(
							() => {
								buffer.forEach(value => subscriber.next(value));
								subscriber.complete();
							},
							error => subscriber.error(error),
							() => {
								buffering = false;
								buffer.length = 0;
							},
						),
					);
					subscription.add(
						published.subscribe(
							value => buffering && buffer.push(value),
							error => subscriber.error(error),
						),
					);
					subscription.add(() => {
						buffer.length = 0;
					});
					return subscription;
				});
				return concat(delayed, published);
			}),
		);
}

type Action = (manager: TeacherScreenClient) => any;

export class ShareSyncService extends BaseService {
	protected shareManager: TeacherScreenClient;
	private enabled: Subject<boolean> = new Subject();
	private queue: Subject<Action> = new Subject<Action>();
	private options: InitOptions;

	constructor(options: InitOptions) {
		super();
		this.options = options;
		const clearShareScreen = () => {
			delete localStorage.shareScreenSessionCreated;
			this.shareManager?.dispose();
			this.enabled.next(false);
		};

		if (localStorage.shareScreenSessionCreated) {
			const shareScreen = new TeacherScreenClient();

			shareScreen.connectionFail.pipe(takeUntil(this.destroy$)).subscribe(() => clearShareScreen());

			shareScreen.shared.pipe(takeUntil(this.destroy$)).subscribe((data) => {
				if (!data) {
					clearShareScreen();
				} else {
					this.enabled.next(true);
				}
			});

			shareScreen.isShared(this.options.userID);
			this.shareManager = shareScreen;
		} else {
			clearShareScreen();
		}

		this.queue.pipe(delayUntil(this.enabled.pipe(filter(Boolean))), takeUntil(this.destroy$)).subscribe((action) => {
			if (this.shareManager) {
				action(this.shareManager);
			}
		});
	}

	public start(startIndex: number, questions: QuestionModel[], testType: TestType) {
		const {isWhiteBackground, testName, userID, studentName} = this.options;

		this.addAction((manager) => manager.startTest(userID, {
			currentQuestionIndex: startIndex,
			questions,
			isWhiteBackground,
			testName,
			studentName,
			testType,
		}));
	}

	public syncQuestionIndex(index: number): void {
		this.addAction((manager) => manager.showQuestion(index));
	}

	public syncSummary(total: number, correct: number, showTestSessionResults: boolean, testResultsCorrectVerbiage?: string, testResultsIncorrectVerbiage?: string): void {
		this.addAction((manager) => manager.summary(total, correct, testResultsCorrectVerbiage, testResultsIncorrectVerbiage, showTestSessionResults));
	}

	public endTest() {
		this.shareManager?.endTest();
	}

	private addAction(action: Action) {
		this.queue.next(action);
	}

	public destroy() {
		super.destroy();
		this.shareManager?.endTest();
		setTimeout(() => this.shareManager?.dispose());
	}
}
