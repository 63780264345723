import {createContext} from '@esgi/ui';
import {Student} from '@esgi/main/libs/store';

export interface StudentsRootContextValue {
	selectedStudentIDs: number[];
	getStudentById: (studentId: number) => Student | undefined;
}

export const {
	Context: StudentsRootContext,
	useHook: useStudentsRootContext,
} = createContext<StudentsRootContextValue>('Students Root Context');