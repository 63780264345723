import React, {createRef, Suspense} from 'react';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {lazyComponent} from '@esgi/core/react';

const ImageGallery = lazyComponent(() => import('./image-gallery'));

class Props {
	selectMode?: boolean = false;
	onImageSelected?: (id: number) => void;
	onClosed: () => void;
}

export default class ImageGalleryModal extends React.PureComponent<Props> {
	private modalManagerRef: ModalManagerRefObject = createRef();

	public render() {
		return <Suspense fallback={<></>}>
			<Modal className='image-gallery-modal' modalManagerRef={this.modalManagerRef}>
				<Modal.Body className='modalBody'>
					<ImageGallery onImageSelected={(id) => this.props.onImageSelected && this.props.onImageSelected(id)}
					              selectMode={this.props.selectMode}
					              onClosed={() => this.modalManagerRef.current.close(this.props.onClosed)}/>
				</Modal.Body>
			</Modal>
		</Suspense>;
	}
}
