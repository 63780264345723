import {SubjectType} from '@esgi/core/enums';

export class TestHistorySelectedChangedEvent {
	constructor(public classID: TestHistorySelectedChangedArgs['classID'], public groupID: TestHistorySelectedChangedArgs['groupID'], public studentID: TestHistorySelectedChangedArgs['studentID']) {
	}
}


export interface TestHistorySelectedChangedArgs {
	classID: number;
	groupID: number;
	studentID: number;
}

export class TestHistorySubjectChangedEvent {
	constructor(public type: SubjectType, public id: number) {
	}
}

