import {StudentSort, useUser} from '@esgi/core/authentication';
import {ListHeaderCellID, StudentModel} from '../../types';
import {ComponentProps, Dispatch, useCallback, useMemo, useRef} from 'react';
import {ListHeader} from '../list-header';
import {SortDirection, getUniqValuesInData} from '@esgi/main/kits/assignments';

export function useStudentsState({
	students,
	setStudents,
}: {
	students: StudentModel[];
	setStudents: Dispatch<StudentModel[]>;
}) {
	const user = useUser();

	const previousDisabledCells = useRef<ComponentProps<typeof ListHeader>['disableSortingCells']>([]);

	const disableSortingCells = useMemo<ComponentProps<typeof ListHeader>['disableSortingCells']>(() => {
		const {firstName, lastName, joinedAssignmentName} = getUniqValuesInData({
			data: students,
			keys: ['firstName', 'lastName', 'joinedAssignmentName'],
		});

		const disabledCells = [
			user?.studentSort === StudentSort.FirstName && firstName.size <= 1 && ListHeaderCellID.Student,
			user?.studentSort === StudentSort.LastName && lastName.size <= 1 && ListHeaderCellID.Student,
			joinedAssignmentName.size <= 1 && ListHeaderCellID.Assignment,
		].filter<ListHeaderCellID>((value): value is ListHeaderCellID => !!value);

		if (JSON.stringify(previousDisabledCells.current) === JSON.stringify(disabledCells)) {
			return previousDisabledCells.current;
		}

		previousDisabledCells.current = disabledCells;

		return previousDisabledCells.current;
	}, [students, user]);

	const sortStudentsByKey = useCallback(
		({key, sortDirection}: {key: keyof StudentModel; sortDirection: SortDirection}) => {
			const copiedStudents = [...students];

			const stringKeys: (keyof StudentModel)[] = ['firstName', 'lastName', 'joinedAssignmentName'];

			if (stringKeys.includes(key)) {
				copiedStudents.sort((a, b) => {
					const aValue = a[key];
					const bValue = b[key];

					if (typeof aValue === 'string' && typeof bValue === 'string') {
						return sortDirection === SortDirection.Asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
					}

					return 0;
				});
			}

			setStudents(copiedStudents);
		},
		[setStudents, students],
	);

	const sortStudentsData = useCallback(
		({sortDirection, sortableCellId}: {sortableCellId: ListHeaderCellID; sortDirection: SortDirection}) => {
			const studentKeyByHeaderCellId: Record<ListHeaderCellID, keyof StudentModel> = {
				[ListHeaderCellID.Student]: user?.studentSort === StudentSort.FirstName ? 'firstName' : 'lastName',
				[ListHeaderCellID.Assignment]: 'joinedAssignmentName',
			};

			sortStudentsByKey({
				key: studentKeyByHeaderCellId[sortableCellId],
				sortDirection,
			});
		},
		[sortStudentsByKey, user],
	);

	return {
		disableSortingCells,
		sortStudentsData,
	};
}
