import {BaseService} from '@esgi/core/service';
import {FormState, IGradeRange, IGradeScale} from 'shared/modules/grade-scale/models';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {IUpdateModel} from 'shared/modules/grade-scale/grade-range/forms/default/models';
import {Api} from 'shared/modules/grade-scale/grade-range/forms/default/api';
import {
	GradeRangeEditorService,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/service';

export class DefaultGradeRangeEditorService extends BaseService {
	public gradeRangeEditorService: GradeRangeEditorService;
	private gradeRanges: IGradeRange[];
	private gradeScaleID: number;
	private testID?: number;
	private readonly formState: BehaviorSubject<FormState> = new BehaviorSubject<FormState>(FormState.None);
	private readonly gradeScale: Subject<IGradeScale> = new Subject<IGradeScale>();
	
	constructor() {
		super();
		this.gradeRangeEditorService = new GradeRangeEditorService(100);
		
		this.gradeRangeEditorService.gradeRangesResult$.subscribe(gradeRanges => {
			this.gradeRanges = gradeRanges;
		});
		
		this.formState$.subscribe(fs => {
			if (fs === FormState.Saving) {
				if (this.gradeRangeEditorService.formValid) {
					const updateModel = {
						testID: this.testID,
						gradeScaleID: this.gradeScaleID,
						gradeRanges: this.gradeRanges,
					} as IUpdateModel;

					Api.update(updateModel).withCustomErrorHandler(() => this.setFormState(FormState.None)).subscribe(() => {
						this.setFormState(FormState.Saved);
					});
				} else {
					this.gradeRangeEditorService.showValidation();
					this.setFormState(FormState.None);
				}
			}
		});
	}
	
	public init(gradeScaleID: number, testID?: number) {
		this.testID = testID;
		
		if (this.gradeScaleID !== gradeScaleID) {
			this.gradeScaleID = gradeScaleID;
			
			Api.init(gradeScaleID).subscribe(response => {
				this.gradeRangeEditorService.setEditor(response.gradeRanges, response.gradeScale);
				this.gradeScale.next(response.gradeScale);
			});	
		} else {
			this.gradeRangeEditorService.hideValidation();
		}
	}

	public save() {
		if (this.formState.value === FormState.None) {
			this.formState.next(FormState.Saving);
		}
	}
	
	public setFormState(formState: FormState) {
		this.formState.next(formState);
	}

	public formState$: Observable<FormState> = this.completeOnDestroy(this.formState);
	public gradeScale$: Observable<IGradeScale> = this.completeOnDestroy(this.gradeScale);
	
	destroy() {
		this.gradeRangeEditorService.destroy();
		super.destroy();
	}
}
