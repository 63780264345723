import React from 'react';
import {ArrowIcon} from './arrow-icon';
import {MatrixContext} from './matrix-context';
import styles from './matrix.module.less';
import {scrollYDown} from './scrolling';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
	rowHeight: number;
}

export class NextRowButton extends React.PureComponent<Props> {
	public render() {
		return <MatrixContext.Consumer>
			{(context) => <div className={join(
				styles.nextRow,
				this.props.className,
				!context.scrollActionState?.canNextRow && styles.hidden,
			)}>
				<ArrowIcon className={styles.arrowIconDown} onClick={() => scrollYDown(context.osInstance, this.props.rowHeight)}/>
			</div>}
		</MatrixContext.Consumer>;
	}
}
