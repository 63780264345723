import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Layout = styled(FlexBox, {
	flexDirection: 'row',
	background: '$neutral96',
	width: '100%',
	padding: '18px 20px',
	borderBottom: `1px solid $neutral88`,
});
