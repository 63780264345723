import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const ProfileHeaderWrapper = styled(FlexBox, {
	padding: '18px 20px',
	alignItems: 'center',
	height: 'max-content',
	justifyContent: 'space-between',
	borderBottom: '1px solid $mild',
	marginBottom: '20px',
	flex: 0,

	'& svg': {
		'& path': {
			fill: '$neutral56',
		},
	},
});

export const Title = styled(Text, {
	marginTop: '1px',
});

export const ButtonsContainer = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',
	padding: '0 6px',
	gap: '20px',
});

export const IconContainer = styled(FlexBox, {
	width: '24px',
	height: '24px',
	alignItems: 'center',
	justifyContent: 'center',
	marginRight: '4px',
});
