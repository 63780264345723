import {TestType} from '@esgi/core/enums';
import {getUser, UserType} from '@esgi/core/authentication';

export const IEP_NAME = 'iep';

export function getSummaryPieChartOptions(testType: TestType, testResultsCorrectVerbiage: string, correctPercent: number, testResultsIncorrectVerbiage: string, incorrectPercent: number) {
	const seriesData = [
		{
			name: testResultsCorrectVerbiage,
			y: correctPercent,
			selected: true,
			color: '#00BF96',
		},
	];

	if (correctPercent !== 100) { //ESGI-23039
		seriesData.push({
			name: testResultsIncorrectVerbiage,
			y: incorrectPercent,
			color: '#FFF',
			selected: false,
		});
	}

	return {
		chart: {
			backgroundColor: 'transparent',
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			width: 270,
			height: 270,
		},
		title: {
			enabled: false,
			text: '',
		},
		legend: {
			enabled: false,
		},

		credits: {
			enabled: false,
		},
		tooltip: {
			enabled: true,
			headerFormat: '',
			pointFormat: testType === TestType.YN ? '<span><span style="font-family: Roboto; font-weight: bold">{point.y}%</span> {point.name}</span>' : '<span><span style="font-weight: bold">{point.y}%</span></span>',
		},
		plotOptions: {
			pie: {
				size: 150,
				allowPointSelect: false,
				cursor: 'pointer',
				slicedOffset: 0,
				dataLabels: {
					enabled: false,
				},
			},
			series: {
				animation: false,
			},
		},
		series: [{
			name: '',
			colorByPoint: true,
			borderColor: '#999999',
			data: seriesData,
			states: {
				hover: {
					enabled: false,
				},
				inactive: {
					opacity: 1,
				},
			},
		}],
	};
}

export function nl2br(text = '', isXhtml = false) {
	if (!(text || '')) {
		return '';
	}
	const breakTag = isXhtml ? '<br />' : '<br>';
	return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const hasIEPLogic = () => {
	const {userType} = getUser();
	return [UserType.ISD, UserType.ISS].includes(userType);
};
