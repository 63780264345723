import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gap: '12px',
});

export const TestButtonLabelTag = styled(FlexBox, {
	borderRadius: '6px',
	padding: '8px',
	width: '120px',
	height: '40px',
	border: '1px solid $mild',
	alignItems: 'center',
	justifyContent: 'center',
});
