import {Dispatch} from 'react';
import {createContext} from '@esgi/ui';
import {Class, Group, Student} from '@esgi/main/libs/store';

export type SingleSelectState = {
	classId: Class['id'] | null;
	groupId: Group['id'] | null;
	studentId: Student['id'] | null;
	// TODO: convert studentFromClassId and studentFromGroupId to entity type
	studentFromClassId: Class['id'] | null;
	studentFromGroupId: Class['id'] | null;

};

export type SingleSelectContextValue = {
	selected: SingleSelectState;
	selectClass: Dispatch<Class['id']>;
	selectGroup: Dispatch<Group['id']>;
	selectStudent: Dispatch<Student['id']>;
	selectStudentFromClass: (args: {studentId: Student['id']; classId: Class['id']}) => void;
	selectStudentFromGroup: (args: {studentId: Student['id']; groupId: Group['id']}) => void;
};

export const {Context: SingleSelectContext, useHook: useSingleSelectContext} =
	createContext<SingleSelectContextValue>('SingleSelectContext');
