import React from 'react';
import {ReactiveElement} from '@esgillc/ui-kit/form';
import {HierarchyMode} from 'modules/hierarchy/core/models';
import FilterService from '../../services/filter-service';
import {Radio} from '@esgillc/ui-kit/form-control';

class Props {
	filterService: FilterService;
	specialistTitle: string;
}

export default class ModeSwitcher extends React.PureComponent<Props> {
	public render() {
		return <div className='toggle-container'>
			<div className='title'>Filter Students by:</div>
			<ReactiveElement subject={this.props.filterService.mode$} className='mode-field'>
				<Radio value={HierarchyMode.Classic}>
					Standard
				</Radio>
				<Radio value={HierarchyMode.Specialist}>
					{this.props.specialistTitle}
				</Radio>
			</ReactiveElement>
		</div>;
	}
}
