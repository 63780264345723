export enum FileType {
	Zip = 'zip',
	Pdf = 'pdf'
}

export type OptionItem = {
	label: string;
};

export enum UnitType {
	Class,
	Group,
	None,
}

export interface UnitData {
	id: number;
	type: UnitType;
	name?: string;
}

export interface StudentWithUnit {
	studentId: number;
	unitData: UnitData,
}

export interface ReportSettings {
	showBaseline: boolean,
	carryForward: boolean,
}
