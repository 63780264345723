import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@stitches/react';

export const EmptyBarBox = styled(FlexBox, {
	height: 6,
	borderRadius: 3,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$vivid',
});
