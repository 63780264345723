import {useMemo} from 'react';
import {ChartItem} from './types';
import {TestSession, TestInfo} from '../../../../../../types';

export function useDiagramData({session, testInfo}: {session: TestSession; testInfo: TestInfo}) {
	return useMemo(() => {
		const updatedQuestions: Record<number, ChartItem> = {};
		if(session?.rubricAnswers?.length){
			for (const {criteriaID, notes, score} of session.rubricAnswers) {
				updatedQuestions[criteriaID] = {
					score,
					criteriaName: testInfo.rubricCriteria.find(item => item.id === criteriaID).name,
					notes,
				};
			}
		}

		return {
			data: Object.values(updatedQuestions).map<ChartItem>(item => item),
			maxScore: testInfo.totalPossible / testInfo.rubricCriteria.length,
		};
	}, [session, testInfo.rubricCriteria]);
}
