import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useMemo, useState} from 'react';
import ItemAnalysisReportService from '../../services/item-analysis-report-service';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import styles from './zoom.module.less';

interface Props {
	itemAnalysisReportService: ItemAnalysisReportService;
}

export default function Zoom({itemAnalysisReportService}: Props) {
	const zoomOptions = ['Zoom In', 'Zoom Out', 'Zoom To Fit', '50%', '100%', '200%'];
	const zoom = useBehaviorSubject<number>(itemAnalysisReportService.zoom);
	const [show, setShow] = useState<boolean>(false);

	const selectZoom = (zoom) => {
		itemAnalysisReportService.changeZoom(zoom.getAttribute('data-value'));
		setShow(false);
	};

	const closeMenu = () => setShow(false);

	const zoomPercent = useMemo(() => `${Math.round(zoom * 100)}%`, [zoom]);

	return (
		<div className={styles.zoom}>
			<div className={styles.zoomLine}>
				Zoom level:
				<span
					className={styles.zoomLabel}
					onClick={() => setShow(true)}
				>
					{zoomPercent}
					<div className={styles.zoomArrow}></div>
				</span>
			</div>
			<Menu
				className={styles.menu}
				show={show}
				keepFocusInside
				onClickOutside={closeMenu}
				onEscPressed={closeMenu}
			>
				{zoomOptions.map((item, index) => (
					<MenuItem key={`${item}_${index}`}>
						<span data-value={item} onClick={(e) => selectZoom(e.target)}>{item}</span>
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
