import {join} from '@esgi/ui/utils';
import {Radio} from '@esgillc/ui-kit/control';
import styles from '../../styles.module.less';
import {Dispatch} from 'react';

interface Props {
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function DetailReportSettingsMarkingPeriods({value, onChange}: Props) {
	return (
		<div className={join(styles.settingsCell, styles.flexColumn)}>
			<span className={styles.title}>Marking Periods:</span>
			<div className={styles.radioGroup}>
				<Radio
					data-cy='show-all-option'
					checked={value}
					value='All'
					onChange={() => onChange(true)}
				>
					Show All
				</Radio>
				<Radio
					data-cy='current-option'
					checked={!value}
					value='Current'
					onChange={() => onChange(false)}
				>
					Current
				</Radio>
			</div>
		</div>
	);
}
