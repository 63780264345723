import {Upload} from '@esgi/ui/icons';
import {styled} from '@esgi/ui/theme';

export const UploadSecondaryIcon = styled(Upload, {
	fill: '$secondary',

	'& path': {
		fill: '$secondary',
	},
});
