import {ComponentPropsWithoutRef} from 'react';
import {EmptyContent} from './index.styled';
import {VariantProps} from '@stitches/react';
import {Text} from '@esgi/ui/typography';

type Props = {
	templateText: string;
	additionalText?: string;
	size?: Extract<VariantProps<typeof EmptyContent>['size'], string>;
	position?: Extract<VariantProps<typeof EmptyContent>['position'], string>
	dataCy?: string
} & ComponentPropsWithoutRef<'div'>;

export function EmptyContentTemplate({templateText, additionalText, dataCy ='empty-content-template', ...props}: Props) {
	return (
		<EmptyContent {...props} dataCy={dataCy}>
			<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-text`}>
				{templateText}
			</Text>
			{additionalText && (
				<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-additional-text`}>
					{additionalText}
				</Text>
			)}
		</EmptyContent>
	);
}
