import * as React from 'react';
import type {SVGProps} from 'react';

export function AccessAll(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			data-cy='access-all-icon'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.75 12.95C10.3415 12.95 9.19995 14.0915 9.19995 15.5V21.45C9.19995 22.8584 10.3415 24 11.75 24H20.25C21.6584 24 22.8 22.8584 22.8 21.45V15.5C22.8 14.0915 21.6584 12.95 20.25 12.95C19.4 12.95 12.6 12.95 11.75 12.95ZM11.75 14.65H20.25C20.7192 14.65 21.1 15.0308 21.1 15.5V21.45C21.1 21.9192 20.7192 22.3 20.25 22.3H11.75C11.2808 22.3 10.9 21.9192 10.9 21.45V15.5C10.9 15.0308 11.2808 14.65 11.75 14.65Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.45 11.25C13.45 9.84168 14.5916 8.7 16 8.7C17.4083 8.7 18.55 9.84168 18.55 11.25V12.95H20.25V11.25C20.25 8.90279 18.3471 7 16 7C13.6527 7 11.75 8.90279 11.75 11.25V12.95H13.45V11.25Z'
				fill='#333333'
			/>
			<path
				d='M12.5 17C12.5 16.5858 12.8358 16.25 13.25 16.25C13.6642 16.25 14 16.5858 14 17C14 17.4142 13.6642 17.75 13.25 17.75C12.8358 17.75 12.5 17.4142 12.5 17Z'
				fill='#333333'
			/>
			<path
				d='M12.5 20C12.5 19.5858 12.8358 19.25 13.25 19.25C13.6642 19.25 14 19.5858 14 20C14 20.4142 13.6642 20.75 13.25 20.75C12.8358 20.75 12.5 20.4142 12.5 20Z'
				fill='#333333'
			/>
			<path
				d='M15.25 17C15.25 16.5858 15.5858 16.25 16 16.25C16.4142 16.25 16.75 16.5858 16.75 17C16.75 17.4142 16.4142 17.75 16 17.75C15.5858 17.75 15.25 17.4142 15.25 17Z'
				fill='#333333'
			/>
			<path
				d='M15.25 20C15.25 19.5858 15.5858 19.25 16 19.25C16.4142 19.25 16.75 19.5858 16.75 20C16.75 20.4142 16.4142 20.75 16 20.75C15.5858 20.75 15.25 20.4142 15.25 20Z'
				fill='#333333'
			/>
			<path
				d='M18 17C18 16.5858 18.3358 16.25 18.75 16.25C19.1642 16.25 19.5 16.5858 19.5 17C19.5 17.4142 19.1642 17.75 18.75 17.75C18.3358 17.75 18 17.4142 18 17Z'
				fill='#333333'
			/>
			<path
				d='M18 20C18 19.5858 18.3358 19.25 18.75 19.25C19.1642 19.25 19.5 19.5858 19.5 20C19.5 20.4142 19.1642 20.75 18.75 20.75C18.3358 20.75 18 20.4142 18 20Z'
				fill='#333333'
			/>
		</svg>
	);
}
