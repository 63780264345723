import {Form, FormElement, FormField, Validators} from '@esgi/deprecated/knockout';
import {FieldTool} from '../../common/component';

export class FullNameFormComponent extends Form {
	private resizeTimer;
	title: FormField;
	firstName: FormField;
	lastName: FormField;

	titleOptions = ['Ms.', 'Mr.', 'Mrs.', 'Miss', 'Dr.'];

	constructor(options?: {firstName: string, lastName: string}) {
		super();
		this.title = this.createTitleField();
		this.firstName = this.createFirstNameField(options && options.firstName);
		this.lastName = this.createLastNameField(options && options.lastName);
		this.resolveValidationDisplaying();
		$(window).resize(() => {
			clearTimeout(this.resizeTimer);
			this.resizeTimer = setTimeout(() => {
				this.resolveValidationDisplaying();
			}, 300);
		});
	}


	private resolveValidationDisplaying() {
		const width = $(window).width();
		const show = width > 1024;
		this.innerElements.forEach((f) => {
			FieldTool.showTooltipAfterValidation(f as FormElement, show);
		});
	}

	createFirstNameField(initValue?) {
		const f = this.createField(initValue, Validators.Required('Please enter your first name.'));
		f.validation.showValidation(true);
		f.validation.successValidation(true);
		f.validation.errorPosition = 'left';
		return f;
	}

	createLastNameField(initValue?) {
		const f = this.createField(initValue, Validators.Required('<div>Please enter your last name.</div>'));
		f.validation.showValidation(true);
		f.validation.successValidation(true);
		f.validation.validationMessageTitleUseHtml = true;

		return f;
	}

	createTitleField() {
		const f = this.createField(this.titleOptions[0]);
		f.validation.showValidation(true);
		f.validation.successValidation(true);
		return f;
	}

	validate(): JQueryPromise<boolean> {
		return $.whenAll([this.firstName.validation.validate(), this.lastName.validation.validate()]).then((r: Array<boolean>) => {
			return r.reduce((a, b) => a && b);
		});
	}

	template = () => {
		return <div className='user-info-row row'>
			<div data-bind='var : {root: $data}, afterRender: true' className='col col-xs-4 col-sm-2'>
				<ko data-bind='with: title'>
					<label>Title</label>
					<div className='user-title form-group' data-bind='afterRender: true'>
						<div>
							<select id='title' name='title' className='form-control'
							        data-bind='value: value, options: root.titleOptions'/>
						</div>
					</div>
				</ko>
			</div>
			<div data-bind='with: firstName' className='col col-xs-8 col-sm-5'>
				<div className='form-group'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
					<div>
						<label>First Name</label>
						<input type='text' className='form-control input-md' id='firstname'
						       data-bind='value: value'/>
						<div className='error-message visible-xs visible-sm hidden-md'>
							<span data-bind='text:validation.validationResults'/>
						</div>
						<ko data-bind='if: showSuccessValidation'>
							<i className='fa fa-check form-control-feedback'/>
						</ko>
						<ko data-bind='if: showErrorValidation '>
							<i className='fa fa-close form-control-feedback'/>
						</ko>
					</div>
				</div>
			</div>
			<div data-bind='with: lastName' className='col col-xs-12 col-sm-5'>
				<div className='form-group'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
					<div>
						<label>Last Name</label>
						<input type='text' className='form-control input-md' id='lastname'
						       data-bind='value: value'/>
						<div className='error-message visible-xs visible-sm hidden-md'>
							<span data-bind='text:validation.validationResults'/>
						</div>
						<ko data-bind='if: showSuccessValidation'>
							<i className='fa fa-check form-control-feedback'/>
						</ko>
						<ko data-bind='if: showErrorValidation '>
							<i className='fa fa-close form-control-feedback'/>
						</ko>
					</div>
				</div>
			</div>
		</div>;
	};
}
