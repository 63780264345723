export class PrintStudentReport {
	printTestMode = false;

	constructor(private document) {
	}

	reportPrint: any;
	data: any = {};

	maxWidth = 650;

	classStyle = {
		nameHeader: {
			name: 'nameHeader',
			style: 'border-top: 1.5pt solid windowtext;border-right: 1.5pt solid windowtext;border-bottom: 1.5pt solid windowtext;border-left: 1.5pt solid windowtext;white-space: normal;' +
				'vertical-align: top; text-align: center;',
		},
		testTitle: {
			name: 'testTitle',
			style: 'font-size: 11.0pt;font-weight: 700; font-family: \'Times New Roman\', serif; text-align: center; vertical-align: middle; border-top: 1.5pt solid windowtext; border-left: none;  ' +
				'border-right: 2.0pt double black; border-bottom:none; white-space: normal; padding: 3px;',
		},
		ltHeader: {
			name: 'ltHeader',
			style: 'font-size: 12.0pt; font-weight: 700; text-decoration: underline; text-underline-style: single; font-family: \'Times New Roman\', serif;',
		},
		teacherName: {
			name: 'teacherName',
			style: 'font-weight: 700; font-family: Arial, sans-serif;white-space: normal;',
		},
		fname: {
			name: 'fname',
			style: 'padding:1px;font-size: 12.0pt;font-weight: 700;font-family: \'Times New Roman\', serif;border-top: 1.0pt solid windowtext;border-right: 1.0pt solid windowtext;' +
				'border-bottom: 1.0pt solid windowtext; border-left: 1.5pt solid windowtext;',
		},
		col: {
			name: 'col',
			style: 'padding-right:1px;padding-left:1px;white-space:nowrap;font-weight:700; font-family:Arial, sans-serif; text-align:center; border-top:none;border-bottom:1.0pt solid windowtext;',
		},
	};

	style =
		{
			rightBorder: 'border-right: 2pt solid black;',
			nowrap: 'white-space:nowrap;',
		};
	const =
		{
			openTable: '<table width="100%" border="1" cellpadding="0" cellspacing="0" style="border-bottom:2px; solid windowtext; border-collapse:collapse;" align="center">',
			closeTable: '</table>',
			pageBreak: '<DIV style="page-break-after:always"></DIV>',
			openHead: '<thead style="display: table-header-group;">',
			closeHead: '</thead>',

		};
	factory = {
		cell: (text, style, classList, colspan?, rowspan?) => {
			if (style === undefined) {
				style = '';
			}
			if (colspan === undefined) {
				colspan = 1;
			}
			if (rowspan === undefined) {
				rowspan = 1;
			}
			if (classList === undefined) {
				classList = '';
			}
			return '<td colspan="' + colspan + '" rowspan="' + rowspan + '" style="' + style + '" class="' + classList + '" >' + text + '</td>';
		},
		span: (text, style, classList?) => {
			if (classList === undefined) {
				classList = '';
			}
			return '<span style="' + style + '" class="' + classList + '">' + text + '</span>';
		},
	};
	preparedRow =
		{
			subTest: (startPos, endPos) => {
				return '<tr>';
			},
			sorting: (infoColumns, startPos, endPos) => {
				let row = '<tr>';
				let i;
				let rightBorder;

				for (i = 0; i < infoColumns.length; i++) {
					rightBorder = i + 1 === infoColumns.length ? this.style.rightBorder : '';
					row += this.factory.cell(infoColumns[i].text, rightBorder, this.classStyle.fname.name);
				}

				for (i = startPos; i < endPos; i++) {
					if (i < this.data.testColumns.length) {
						let j = 0 + this.data.periodsInTest * i;
						let last = j + this.data.periodsInTest;
						for (j; j < last; j++) {
							rightBorder = j + 1 === last ? this.style.rightBorder : '';
							row += this.factory.cell($('span', this.data.subTrackColumns[j]).text().trim(),
								rightBorder,
								this.classStyle.col.name,
								this.data.subTrackColumns[j].colSpan,
								this.data.subTrackColumns[j].rowSpan);
						}
					}

					if (i >= this.data.testColumns.length) {
						let j = 0 + this.data.periodsInTest * (i - this.data.testColumns.length);
						let last = j + this.data.periodsInTest;
						for (j; j < last; j++) {
							rightBorder = j + 1 === last ? this.style.rightBorder : '';
							row += this.factory.cell($('span', this.data.subTotalColumns[j]).text().trim(),
								rightBorder,
								this.classStyle.col.name,
								this.data.subTotalColumns[j].colSpan,
								this.data.subTotalColumns[j].rowSpan);

						}
					}
				}

				row += '</tr>';
				return row;
			},
			info: (infoColumn, countColumns) => {
				let row = '<tr>';
				row += this.factory.cell($(this.data.reportDate[0]).text().trim(), 'font-size: 12.0pt; font-weight: 700;border-top:2pt solid windowtext;border-left:2pt solid windowtext; border-right:none; text-align:left;', '', 2);
				infoColumn -= 2;
				row += this.factory.cell($(this.data.reportTitle[0]).text().trim(), 'font-size: 12.0pt; font-weight: 700;border-top:2pt solid windowtext;border-left:none; border-right:none;text-align:center;', '', (infoColumn + countColumns) - 2);
				let column = (infoColumn + countColumns) - 2;
				let colspan = countColumns * this.data.periodsInTest + column;
				row += this.factory.cell($(this.data.reportSubjectName[0]).text().trim(), 'font-size: 12.0pt; font-weight: 700;border-top:2pt solid windowtext;border-left:none; border-right:2pt solid windowtext; text-align:right;', '', colspan);
				row += '</tr>';
				return row;
			},
			otherInfo: (infoColumn, countColumns) => {
				let row = '<tr>';
				row += this.factory.cell($(this.data.reportDate[0]).text().trim(), 'font-size: 12.0pt; font-weight: 700;border-top:2pt solid windowtext;border-left:2pt solid windowtext; border-right:none; text-align:left;', '', 2);
				infoColumn -= 2;
				let colspan = infoColumn + (countColumns * this.data.periodsInTest);
				row += this.factory.cell($('.teacherName', this.data.infoColumns[0]).text().trim(), 'font-size: 12.0pt; font-weight: 700;border-top:2pt solid windowtext;border-left:none; border-right:2pt solid windowtext;text-align:center;', '', colspan);
				row += '</tr>';
				return row;
			},
		};

	print(selector, allRows, reportType = '') {
		this.prepareReport();
		this.parseTable(selector, allRows, reportType);
		this.fillReport();

		this.reportPrint.window.focus();
		this.reportPrint.window.print();
	}

	renderHeaderRowsFirstPage() {
		let rows = '';
		let row = '<tr>';
		let countColumn = 0;
		let currentWidth = 0;
		let i;

		for (i = 0; i < this.data.infoColumns.length; i++) {
			let text = this.factory.span($('.header-title', this.data.infoColumns[i]).text().trim(), '', this.classStyle.ltHeader.name);
			text += '<br/>';
			text += this.factory.span($('.sub-header', this.data.infoColumns[i]).text().trim(), '', this.classStyle.teacherName.name);

			row += this.factory.cell(text,
				'',
				this.classStyle.nameHeader.name,
				this.data.infoColumns[i].colSpan,
				this.data.infoColumns[i].rowSpan);
			currentWidth += this.data.infoColumns[i].clientWidth;
		}

		for (i = 0; i < this.data.testColumns.length; i++) {
			row += this.factory.cell($(this.data.testColumns[i]).text().trim(),
				'border-right:2pt solid black; border-bottom:2pt solid windowtext;',
				this.classStyle.testTitle.name,
				this.data.testColumns[i].colSpan,
				this.data.testColumns[i].rowSpan);
			countColumn++;
			currentWidth += this.data.testColumns[i].clientWidth;
			if (currentWidth > this.maxWidth) {
				break;
			}
		}

		if (currentWidth < this.maxWidth) {
			for (i = 0; i < this.data.totalColumns.length; i++) {
				row += this.factory.cell($('span', this.data.totalColumns[i]).text().trim(),
					'border-bottom: 2pt solid black;',
					this.classStyle.testTitle.name,
					this.data.totalColumns[i].colSpan,
					this.data.totalColumns[i].rowSpan);
				countColumn++;
				currentWidth += this.data.totalColumns[i].clientWidth;
				if (currentWidth > this.maxWidth) {
					break;
				}
			}
		}

		rows += this.preparedRow.info(this.data.subInfoColumns.length, countColumn);

		row += '</tr>';
		rows += row;

		rows += this.preparedRow.subTest(0, countColumn);
		let infoColumns = [];
		for (i = 0; i < this.data.subInfoColumns.length; i++) {
			infoColumns.push({text: $('span', this.data.subInfoColumns[i]).text().trim()});
		}

		rows += this.preparedRow.sorting(infoColumns, 0, countColumn);
		return {width: currentWidth, countColumn: countColumn, rows: rows};
	}

	renderFirstPage() {
		let header = this.renderHeaderRowsFirstPage();
		let table = this.const.openTable;
		table += this.const.openHead + header.rows + this.const.closeHead;
		for (let i = 0; i < this.data.rows.length; i++) {
			let tds = this.data.rows[i].EntityInfo.concat(this.data.rows[i].TestResults);

			let row = '<tr>';
			let j;
			let rightBorder;

			for (j = 0; j < this.data.subInfoColumns.length; j++) {
				rightBorder = j + 1 === this.data.subInfoColumns.length ? this.style.rightBorder : '';
				row += this.factory.cell(tds[j].Value, rightBorder, this.classStyle.fname.name);
			}

			for (j = this.data.subInfoColumns.length; j < this.data.subInfoColumns.length + header.countColumn * this.data.periodsInTest; j++) {
				rightBorder = (j + 1 - this.data.subInfoColumns.length) % this.data.periodsInTest === 0 ? this.style.rightBorder : '';
				row += this.factory.cell(tds[j].Value, rightBorder, this.classStyle.col.name);
			}

			row += '</tr>';
			table += row;
		}
		table += this.const.closeTable;

		return {header: header, pages: table};
	}

	renderHeaderRow(currentIndex) {
		let rows = '';
		let row = '<tr>';
		let span = this.factory.span('Student', this.classStyle.fname.name + 'border:none;');
		row += this.factory.cell(span, 'vertical-align:middle;width:200px;', this.classStyle.nameHeader.name, 1, 3);

		let currentWidth = 0;
		let countColumn = 1;
		let i;
		for (i = currentIndex; i < this.data.testColumns.length; i++) {
			row += this.factory.cell($(this.data.testColumns[i]).text().trim(),
				'border-right: 2pt solid black; border-bottom:2pt solid windowtext;',
				this.classStyle.testTitle.name,
				this.data.testColumns[i].colSpan,
				this.data.testColumns[i].rowSpan);

			currentWidth += this.data.testColumns[i].clientWidth;
			countColumn++;
			if (currentWidth > this.maxWidth) {
				break;
			}
		}

		if (currentWidth < this.maxWidth) {
			for (i = 0; i < this.data.totalColumns.length; i++) {
				row += this.factory.cell($('span', this.data.totalColumns[i]).text().trim(),
					'border-bottom:2pt solid black;border-right:2pt solid black;',
					this.classStyle.testTitle.name,
					this.data.totalColumns[i].colSpan,
					this.data.totalColumns[i].rowSpan);

				countColumn++;
				currentWidth += this.data.totalColumns[i].clientWidth;
				if (currentWidth > this.maxWidth) {
					break;
				}
			}
		}

		rows += this.preparedRow.otherInfo(1, countColumn - 1);

		row += '</tr>';
		rows += row;

		rows += this.preparedRow.subTest(currentIndex, currentIndex + countColumn - 1);
		rows += this.preparedRow.sorting([], currentIndex, currentIndex + countColumn - 1);
		return {width: currentWidth + 200, currentIndex: currentIndex, countColumn: countColumn, rows: rows};
	}

	renderOtherPage(first) {
		let header = this.renderHeaderRow(first.header.countColumn);
		let table = this.const.openTable;
		table += this.const.openHead + header.rows + this.const.closeHead;

		let posStudent = 0;
		let i;
		for (i = 0; i < this.data.subInfoColumns.length; i++) {
			if ('Student' === $.trim($('span', this.data.subInfoColumns[i]).text().trim())) {
				posStudent = i;
				break;
			}
		}
		let render = true;
		while (render) {


			for (i = 0; i < this.data.rows.length; i++) {
				let tds = this.data.rows[i].EntityInfo.concat(this.data.rows[i].TestResults);
				let row = '<tr>';
				row += this.factory.cell(tds[posStudent].Value, this.style.rightBorder + this.style.nowrap, this.classStyle.fname.name);

				let startPos = this.data.subInfoColumns.length + (header.currentIndex * this.data.periodsInTest);
				let endPos = startPos + (header.countColumn - 1) * this.data.periodsInTest;
				let rightBorder;
				for (let j = startPos; j < endPos; j++) {
					rightBorder = (j + 1 - startPos) % this.data.periodsInTest === 0 ? this.style.rightBorder : '';
					row += this.factory.cell(tds[j].Value, rightBorder + this.style.nowrap, this.classStyle.col.name);
				}

				row += '</tr>';
				table += row;
			}

			if ((header.currentIndex + header.countColumn - 1) < (this.data.testColumns.length + this.data.totalColumns.length)) {
				header = this.renderHeaderRow(header.currentIndex + header.countColumn - 1);
				table += this.const.closeTable + '<br/>' + this.const.pageBreak + this.const.openTable + this.const.openHead;
				table += header.rows + this.const.closeHead;
			} else {
				render = false;
				break;
			}
		}

		table += this.const.closeTable;
		return {pages: table};

	}

	prepandClass(classObject) {
		return '.' + classObject.name + ' {' + classObject.style + ' }';
	}

	renderStyle() {
		return '<style>' +
			'table { page-break-inside:auto } tr { page-break-inside:avoid; page-break-after:auto }' +
			this.prepandClass(this.classStyle.nameHeader) +
			this.prepandClass(this.classStyle.testTitle) +
			this.prepandClass(this.classStyle.ltHeader) +
			this.prepandClass(this.classStyle.teacherName) +
			this.prepandClass(this.classStyle.fname) +
			this.prepandClass(this.classStyle.col) +
			'</style>';
	}

	fillReport() {
		let first = this.renderFirstPage();
		let other = this.renderOtherPage(first);
		let style = this.renderStyle();
		$(this.reportPrint.window.document).find('head').append(style);
		$(this.reportPrint.window.document).find('body').append(first.pages + '<br/>' + this.const.pageBreak + other.pages);
		if (this.printTestMode === true) {
			let w = window.open('', '_blank');
			$(w.document).find('head').append(style);
			$(w.document).find('body').append(first.pages + '<br/>' + this.const.pageBreak + other.pages);
		}
	}

	prepareReport() {
		if (this.reportPrint === undefined) {
			let frame = $('<iframe>', {
				name: 'reportPrint',
				frameborder: 0,
			}).hide();
			$('body').append(frame);
		}
		$(this.reportPrint.window.document).find('body').empty();
		$(this.reportPrint.window.document).find('head').empty();
	}

	parseTable(selector, AllRows, reportType) {

		let valueToSkip = 0;
		switch (reportType) {
			case 'Class':
			case 'Group':
				valueToSkip = 2;
				break;
			case 'StudentsSchool':
				valueToSkip = 4;
				break;
			case 'StudentsDistrict':
				valueToSkip = 5;
				break;
			default:
		}

		let table = $(selector, this.document)[1];
		this.data.helpText = $('#report-help-text', table);

		let titleRow = $('.grade-report .grade-report-header');

		this.data.reportDate = titleRow.find('.grade-report-date');
		this.data.reportTitle = titleRow.find('.grade-report-name');
		this.data.reportSubjectName = $('div.header-second-row select').first().find(':selected').text();

		this.data.infoColumns = $('.entity-info-header', table);
		this.data.testColumns = $('.test-name', table);

		this.data.subTestColumns = $('.header-sub-test', table);
		this.data.totalColumns = $('.total-score', table);

		this.data.subInfoColumns = $('td[class=\'sort-period\']', $('.gr-table-subheader', table)).slice(0, valueToSkip);
		this.data.subTrackColumns = $('td[class=\'sort-period\']', $('.gr-table-subheader', table)).slice(valueToSkip).filter(function () {
			return $(this).css('display') !== 'none';
		});

		this.data.subTotalColumns = $('td[class=\'cell-right color-total-weight\']', $('.gr-table-subheader', table));

		this.data.rows = AllRows;
		this.data.periodsInTest = this.data.subTrackColumns.length / this.data.testColumns.length;
	}
}
