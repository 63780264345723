import {EdgeStyle, IGraphStyle, ShapeType} from 'shared/modules/question-editor/models';
import {ColorPicker} from 'shared/modules/question-editor/mx/editor/color-picker/color-picker';
import React from 'react';
import {StrokeSelector} from 'shared/modules/question-editor/mx/editor/toolbars/stroke-selector/stroke-selector';
import './edge-toolbar.less';

export class Props {
	shapeStyle: EdgeStyle;
	shapeType: ShapeType;
	mostRecentColors: string[];
	styleChanged: (type: ShapeType, style: IGraphStyle) => void;
	mostRecentColorsChanged: (colors: string[]) => void;
}

export class EdgeToolbar extends React.PureComponent<Props> {
	changeStyle(field: string, value: any) {
		let newStyle = {...this.props.shapeStyle};
		newStyle[field] = value;
		this.props.styleChanged(this.props.shapeType, newStyle);
	}

	render() {
		return <div className='mx-question-editor-edge-toolbar'>
			<div className='text-toolbar-header'>STYLES</div>
			<div className='text-toolbar-line'></div>
			<div className='vertex-toolbar-tools-row'>
				<ColorPicker
					title='Color'
					mostRecentColorsChanged={(colors) => this.props.mostRecentColorsChanged(colors)}
					mostRecentColors={this.props.mostRecentColors}
					color={this.props.shapeStyle.strokeColor}
					onSelect={(value: string) => this.changeStyle('strokeColor', value)}/>
				<StrokeSelector
					strokeWidth={this.props.shapeStyle.strokeWidth}
					onSelect={(strokeWidth: number) => this.changeStyle('strokeWidth', strokeWidth)}/>
			</div>
		</div>;
	}
}

export namespace Events {
	export function StyleChanged(args: StyleChanged.Args): StyleChanged.Args {
		return args;
	}

	export module StyleChanged {
		export interface Args {
			type: ShapeType,
			style: EdgeStyle;
		}
	}
}
