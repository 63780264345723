import {Box, FlexBox} from '@esgi/ui/layout';
import {styled} from '@stitches/react';

export const TableRoot = styled(FlexBox, {
	overflow: 'hidden',
	padding: '20px',
	height: '60vh',
	marginBottom: '20px',
});

export const TableWrapper = styled(Box, {
	overflow: 'auto',
});

export const TableStyled = styled('table', {
	maxWidth: 'initial',
});

export const TableRowLightBodyStyled = styled('tr', {
	borderBottom: '1px solid #BDBDBD',
	backgroundColor: '#FFFFFF',
});

export const TableRowDarkBodyStyled = styled('tr', {
	borderBottom: '1px solid #BDBDBD',
	backgroundColor: '#F2F2F2',
});

export const TableHeadRowStyled = styled('th', {
	overflow: 'hidden',
	position: 'sticky',
	top: 0,
	zIndex: 2,

	variants: {
		isLeftFixed: {
			true: {
				left: 0,
				zIndex: 3,
			},
		},
		isRightFixed: {
			true: {
				right: 0,
				zIndex: 3,
			},
		},
	},
});

export const TableCellStyled = styled('td', {
	position: 'sticky',
	zIndex: 1,

	variants: {
		isLeftFixed: {
			true: {
				left: 0,
				zIndex: 2,
			},
		},
		isRightFixed: {
			true: {
				right: 0,
				zIndex: 2,
			},
		},
		dark: {
			true: {
				backgroundColor: '#F2F2F2',
			},
			false: {
				backgroundColor: '#FFFFFF',
			},
		},
	},
});

export const TableColumnWrapperStyled = styled('div', {
	display: 'flex',
	flexDirection: 'row',
	borderRight: '2px solid #BDBDBD',

	'&:last-child': {
		borderRight: 'none',
	},

	variants: {
		isRightFixed: {
			true: {
				borderLeft: '2px solid #BDBDBD',
			},
		},
		header: {
			true: {
				height: '65px !important',
			},
		},
		lastsBeforeRight: {
			true: {
				borderRight: 'none !important',
			},
		},
		light: {
			true: {
				fontWeight: 700,
				backgroundColor: '#E0E0E0',
			},
			false: {
				fontWeight: 700,
				backgroundColor: '#D2D2D2',
			},
		},
	},
});

export const TableColumnStyled = styled('div', {
	width: '100%',
	borderRight: '1px solid #BDBDBD',
	overflow: 'hidden',
	wordBreak: 'break-word',
});