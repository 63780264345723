import {combineLatest, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {TestScreenType} from '@esgi/core/enums';
import {HttpClient} from '@esgi/api';

export class LandingService extends BaseService {
	private readonly notedAuthorsPrefixUrl: string = '//esgi-cdn.s3.amazonaws.com/';
	private featuredSeriesData$: Observable<ISeriesResponse[]>;
	private gradeLevelData$: Observable<IGradeLevelsInfo>;
	private notedAuthorsData$: Observable<INotedAuthor[]>;

	constructor(private controller: string) {
		super();
	}

	public get featuredSeries$(): Observable<ISeriesResponse[]> {
		if (!this.featuredSeriesData$) {
			this.featuredSeriesData$ = HttpClient.default.ESGIApi.get<IFeaturedSeriesResponse>(this.controller, 'featured-series')
				.pipe(map(r => r.series), shareReplay())
				.asObservable();
		}
		return this.featuredSeriesData$;
	}

	public get gradeLevels$(): Observable<IGradeLevelsInfo> {
		if (!this.gradeLevelData$) {
			const request = HttpClient.default.ESGIApi.get<IGradeLevelResponse>(this.controller, 'grade-level-tests');
			this.gradeLevelData$ = combineLatest([request, this.notedAuthors$]).pipe(map(r => {
				const response = r[0];
				const notedAuthors = r[1];
				const tests = response.tests.map(t => {
					const newTestInfo = new TestInfo();

					let author = notedAuthors.find(a => a.name === t.creatorName);
					if (author) {
						author = {name: t.creatorName, searchName: t.creatorName} as INotedAuthor;
					}

					newTestInfo.title = t.name;
					newTestInfo.testId = t.id;
					newTestInfo.starred = t.starred;
					newTestInfo.gradeLevels = t.gradeLevels;
					newTestInfo.description = t.description;
					newTestInfo.contentArea = t.contentArea;
					newTestInfo.color = '#' + t.color;
					newTestInfo.authorName = t.creatorName;
					newTestInfo.author = author;
					newTestInfo.selfAssessmentTestAllowed = t.selfAssessmentTestAllowed;
					return newTestInfo;
				});
				return {gradeLevel: response.gradeLevel, tests: tests} as IGradeLevelsInfo;
			}), shareReplay());
		}
		return this.gradeLevelData$;
	}

	public get notedAuthors$(): Observable<INotedAuthor[]> {
		if (!this.notedAuthorsData$) {
			this.notedAuthorsData$ = HttpClient.default.ESGIApi.get<INotedAuthorsOutModel>(this.controller, 'available-noted-authors')
				.pipe(map(r => {
					return r.notedAuthors.map(a => {
						return {
							id: a.id,
							name: a.name,
							searchName: a.searchName,
							visible: a.visible,
							tips: a.dataSets.map(t => {
								return {header: t.header, body: t.body};
							}),
							image30url: this.buildNotedAuthorImagePath('thumbnail', a.imageSet, a.lastUpdated),
							image100url: this.buildNotedAuthorImagePath('default', a.imageSet, a.lastUpdated),
							logoUrl: this.buildNotedAuthorImagePath('logo', a.imageSet, a.lastUpdated),
						} as INotedAuthor;
					});
				}), shareReplay())
				.asObservable();
		}
		return this.notedAuthorsData$;
	}

	private buildNotedAuthorImagePath(type: 'thumbnail' | 'default' | 'logo', imageSet: INotedAuthorImageSet, lastUpdated: number) {
		let path = this.notedAuthorsPrefixUrl;
		if (imageSet && imageSet[type]) {
			path += imageSet[type] + '?i=' + lastUpdated;
		}
		return path;
	}
}

interface IFeaturedSeriesResponse {
	series: ISeriesResponse[];
}

interface ISeriesResponse {
	id: number;
	name: string;
	description: string;
	gradeLevels: number[];
	contentArea: string;
	color: string;
	testScreenTypes: TestScreenType[];
}

interface IGradeLevelResponse {
	tests: ITestResponse[];
	gradeLevel: string;
}

interface ITestResponse {
	id: number;
	name: string;
	description: string;
	creatorName: string;
	gradeLevels: number[];
	contentArea: string;
	starred: boolean;
	color: string;
	selfAssessmentTestAllowed: boolean;
}

export interface INotedAuthor {
	id: number;
	name: string;
	searchName: string;
	visible: boolean;
	tips: INotedAuthorTip[];
	image30url: string;
	image100url: string;
	logoUrl: string;
}

interface INotedAuthorTip {
	header: string;
	body: string;
}

interface INotedAuthorsOutModel {
	notedAuthors: INotedAuthorResponseModel[]
}

interface INotedAuthorResponseModel {
	id: number,
	name: string,
	searchName: string,
	visible: boolean,
	dataSets: INotedAuthorDataSet[],
	imageSet: INotedAuthorImageSet,
	lastUpdated: number
}

interface INotedAuthorImageSet {
	logo: string,
	default: string,
	thumbnail: string,
}

interface INotedAuthorDataSet {
	header: string,
	body: string
}

export class TestInfo {
	testId: number;
	title: string;
	color: string;
	authorName: string;
	description: string;
	contentArea: string;
	gradeLevels: number[] = [];
	starred: boolean = false;
	author: INotedAuthor;
	selfAssessmentTestAllowed: boolean;
}

interface IGradeLevelsInfo {
	tests: TestInfo[],
	gradeLevel: string,
}
