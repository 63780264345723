export const tooltipTexts = {
	averageClassText:
		'View your class\'s average performance and number of sessions for a tab.',
	questionsClassText:
		'View the number of questions your students have answered this week and last within the selected tab.',
	timeClassText: 'View the all-student average time per question across this week and last within the selected tab.',
	untestedClassText: 'View the number of students who have not been tested within a tab. Easily navigate to the Untested Students report via the Details button.',
	averageStudentText: 'View a student\'s average performance and compare it to the selected class\'s performance for a tab.',
	questionsStudentText: 'View the number of questions a student has answered this week and last within a tab.',
	timeStudentText: 'View a student\'s average time per question across this week and last within a tab.',
	testsTakenText: 'View the percentage of tests taken within the selected tab for both the student and class.',
};
