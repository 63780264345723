import {Eye} from '@esgi/ui/icons';
import {Container, ViewCredentialsButton} from './index.styled';
import {getInputIndicatorByAsyncStatus} from './get-input-indicator-by-async-status';
import {FocusEvent, useCallback, useEffect, useState} from 'react';
import {Observable} from 'rxjs';
import {Student} from '@esgi/main/libs/store';
import {debounce} from 'underscore';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {Input} from '@esgi/ui/controls';

type MultipleForm = {
	multipleForm: true;
	isAllCredentialsValid?: boolean;
	isCredentialsInit?: boolean;
};

type NotMultipleForm = {
	multipleForm?: false;
};

type Props = {
	studentId: Student['id'];
	userName: string;
	initialUserName: string;
	onUserNameChanged: (value: string) => void;
	onValidateUsername: (args: {value: string; studentID: number; initialValue: string}) => Observable<string | null>;
	password: string;
	initialPassword: string;
	onPasswordChanged: (value: string) => void;
	onValidatePassword: (value: string) => Observable<string | null>;
	isPasswordHidden: boolean;
	togglePasswordHidden: VoidFunction;
	onCredentialsValidValueChanged: (value: boolean) => void;
	skeleton?: boolean;
} & (NotMultipleForm | MultipleForm);

export function CredentialsRow({
	studentId,
	userName,
	initialUserName,
	onUserNameChanged,
	onValidateUsername,
	password,
	initialPassword,
	onPasswordChanged,
	onValidatePassword,
	isPasswordHidden,
	togglePasswordHidden,
	onCredentialsValidValueChanged,
	skeleton,
	...props
}: Props) {
	const [passwordFocused, setPasswordFocused] = useState(false);

	const [userNameError, setUserNameError] = useState<string | null>(null);
	const [passwordError, setPasswordError] = useState<string | null>(null);

	const [validateUsernameData, validateUsername] = useCancelableRequest(onValidateUsername);
	const [validatePasswordData, validatePassword] = useCancelableRequest(onValidatePassword);

	const handleValidateUserName = useCallback(
		debounce((...args: Parameters<typeof validateUsername>) => validateUsername(...args), 300),
		[validateUsername],
	);

	const handleValidatePassword = useCallback(
		debounce((value: string) => validatePassword(value), 300),
		[validatePassword],
	);

	useEffect(() => {
		const isUserNameDataPending = isAsyncPending(validateUsernameData);
		const isPasswordDataPending = isAsyncPending(validatePasswordData);

		const userNameError = isAsyncSucceed(validateUsernameData) ? validateUsernameData.data : null;
		const passwordError = isAsyncSucceed(validatePasswordData) ? validatePasswordData.data : null;

		onCredentialsValidValueChanged(
			!userNameError && !passwordError && !isUserNameDataPending && !isPasswordDataPending,
		);

		setUserNameError(userNameError);
		setPasswordError(passwordError);
	}, [validatePasswordData, validateUsernameData]);

	useEffect(() => {
		if (props.multipleForm) {
			const {isAllCredentialsValid} = props;

			if (isAllCredentialsValid && userNameError) {
				setUserNameError(null);
			}
		}
	}, [props, userNameError]);

	useEffect(() => {
		if (props.multipleForm) {
			const {isCredentialsInit} = props;

			if (isCredentialsInit) {
				setUserNameError(null);
				setPasswordError(null);
			}
		}
	}, [props]);

	const updateUserName = useCallback(
		(value: string) => {
			handleValidateUserName({
				value,
				studentID: studentId,
				initialValue: initialUserName,
			});
			onUserNameChanged(value);
		},
		[initialUserName, onUserNameChanged, studentId, handleValidateUserName],
	);

	const updatePassword = useCallback(
		(value: string) => {
			handleValidatePassword(value);
			onPasswordChanged(value);
		},
		[onPasswordChanged, handleValidatePassword],
	);

	const onUserNameValueChange = useCallback(
		(event: FocusEvent<HTMLInputElement>) => {
			const value = event.target.value;

			updateUserName(value);
		},
		[updateUserName],
	);

	const onPasswordValueChange = useCallback(
		(event: FocusEvent<HTMLInputElement>) => {
			const value = event.target.value;

			updatePassword(value);
		},
		[updatePassword],
	);

	const togglePasswordFocused = useCallback(() => {
		setPasswordFocused((value) => !value);
	}, []);

	const onClearUserName = useCallback(() => {
		updateUserName('');
	}, [updateUserName]);

	const onClearPassword = useCallback(() => {
		updatePassword('');
	}, [updatePassword]);

	return (
		<Container>
			<Input.CleanableIndicator
				placeholder='Username'
				status={getInputIndicatorByAsyncStatus({
					asyncStatus: validateUsernameData.asyncStatus,
					hasError: Boolean(userNameError),
					isInitialValue:
						(props.multipleForm && props.isCredentialsInit) || (!props.multipleForm && initialUserName === userName),
				})}
				onChange={onUserNameValueChange}
				error={userNameError ?? undefined}
				value={userName}
				onClearClick={onClearUserName}
				skeleton={skeleton}
				disabled={skeleton}
				dataCy='username-input'
			/>
			<Input.CleanableIndicator
				placeholder='Password'
				status={getInputIndicatorByAsyncStatus({
					asyncStatus: validatePasswordData.asyncStatus,
					hasError: Boolean(passwordError),
					isInitialValue:
						(props.multipleForm && props.isCredentialsInit) || (!props.multipleForm && initialUserName === userName),
				})}
				value={isPasswordHidden && !passwordFocused && !passwordError && password ? '*'.repeat(4) : password}
				onChange={onPasswordValueChange}
				error={passwordError ?? undefined}
				onClearClick={onClearPassword}
				onFocus={togglePasswordFocused}
				onBlur={togglePasswordFocused}
				skeleton={skeleton}
				disabled={skeleton}
				dataCy='password-input'
			/>
			<ViewCredentialsButton
				color='secondary'
				onClick={togglePasswordHidden}
				isShownCredentials={!isPasswordHidden}
				skeleton={skeleton}
				disabled={skeleton}
				dataCy='show-password-button'
			>
				<Eye />
			</ViewCredentialsButton>
		</Container>
	);
}
