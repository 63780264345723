import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DictionariesContentAreaController, V2TestsController} from '@esgi/contracts/esgi';
import {
	InitRequestModel,
	Scope,
	Test,
	TestStoreModel,
	TestType,
	SortBy,
	SortDirection,
} from './types';
import {OptionItem} from '@esgi/main/features/teacher/home';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {subjectsStore} from '@esgi/main/libs/store';
import {isUndefined} from '@esgi/ui';
import {mappedTestTypeForStore} from './constants';
import {isNull} from 'underscore';
import {legacyTestColorByContentArea} from '@esgi/main/kits/common';


export class DataService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public initialized$ = new BehaviorSubject(false);

	public contentAreas$ = new BehaviorSubject<OptionItem[]>([]);
	public tests$ = new BehaviorSubject<Test[]>([]);
	public totalCount$ = new BehaviorSubject<number>(0);
	public subjectTestIDs$ = new BehaviorSubject<number[]>([]);

	private contentAreaController = new DictionariesContentAreaController();
	private testController = new V2TestsController();

	private subjectStorage = subjectsStore();

	public getContentAreas() {
		return this.contentAreaController.all()
			.pipe(
				tap(({contentAreas}) => {
					this.contentAreas$.next([{value: '0', label: 'All Content Areas'}, ...contentAreas.map(({id, name}) =>
						({
							value: String(id),
							label: name,
							labelDataCy: `select-option-item-${name}`,
						}
						))]);
					this.initialized$.next(true);
				})
			).subscribe();
	}

	public getSubjectTestIDs(subjectID: number, subjectType: SubjectType) {
		this.loaded$.next(false);

		return this.testController.addToSubjectInit({subjectType, subjectID})
			.pipe(tap(({testIDs}) => {
				this.subjectTestIDs$.next(testIDs);
			})).subscribe();
	}

	public addSelectedTabs(subjectID: number, subjectType: SubjectType, testIDs: number[]) {
		this.loaded$.next(false);

		return this.testController
			.addToSubjectSave({
				subjectID,
				subjectType,
				testIDs,
			})
			.pipe(
				tap(() => {
					const selectedTests = [...this.subjectTestIDs$.value, ...testIDs];

					const updatedTests = this.tests$.value.map((item) => ({...item, disabled: selectedTests.includes(item.id)}));

					this.tests$.next(updatedTests);

					this.subjectStorage
						.update((iteratedSubject) => {
							if (iteratedSubject.id !== subjectID) {
								return iteratedSubject;
							}

							const newTestsForStoreSubject = testIDs
								.map<TestStoreModel | null>((testID) => {
									const testFromStore = iteratedSubject.tests.find(({id}) => id === testID);

									if (!isUndefined(testFromStore)) {
										return testFromStore;
									}

									const testData = updatedTests.find(({id}) => id === testID);

									if (!isUndefined(testData)) {
										const testModel: TestStoreModel = {
											id: testID,
											contentArea: testData.contentArea,
											name: testData.name,
											maxScore: testData.questions,
											type: mappedTestTypeForStore[testData.testType],
											creatorID: testData.authorID,
											isWhiteBackground: testData.isWhiteBackground,
											color: legacyTestColorByContentArea[testData.contentArea],
											testScreenTypes: testData.testScreenTypes,
										};

										return testModel;
									}

									return null;
								})
								.filter((item): item is TestStoreModel => !isNull(item));

							return {
								...iteratedSubject,
								tests: [...iteratedSubject.tests, ...newTestsForStoreSubject],
							};
						});

					this.loaded$.next(true);
				}),
			);
	}

	public fetchData({pageIndex: page, itemsPerPage, sorting, keyword, onlySelfAssess, contentAreaID, testType, scope}: InitRequestModel): Subscription {
		this.loaded$.next(false);
		const pageIndex = page ? page : page + 1;
		return this.testController.search({
			keyword,
			onlySelfAssess,
			pageIndex,
			sorting,
			itemsPerPage,
			contentAreaID: contentAreaID !== '0' ? +contentAreaID : null,
			testType: TestType[testType],
			scope: Scope[scope],
		})
			.pipe(tap(({count, tests}) => {
				const newTests: Test[] = tests.map((item) => ({
					...item,
					testType: TestType[item.testType],
					disabled: this.subjectTestIDs$.value.includes(item.id),
				}));

				this.tests$.next(newTests);
				this.totalCount$.next(count);
				this.loaded$.next(true);
			})).subscribe();
	}

	public override dispose() {
		this.contentAreaController.dispose();
		this.testController.dispose();
	}
}
