import {ImageCropperRoot} from './components/image-cropper-root';
import {ImageCropperSlider} from './components/image-cropper-slider';
import {ImageCropperCropper} from './components/image-cropper-cropper';
import {ImageCropperUploader} from './components/image-cropper-uploader';
import {ImageCropperErrorMessage} from './components/image-cropper-error-message';

export const ImageCropper = Object.assign(ImageCropperRoot, {
	Slider: ImageCropperSlider,
	Uploader: ImageCropperUploader,
	Cropper: ImageCropperCropper,
	ErrorMessage: ImageCropperErrorMessage,
});

export * from './types';
