import {Users} from '@esgi/ui/icons';
import {IconWrapper, Counter} from './index.styled';
import {CounterBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import { Tooltip } from '@esgi/ui';

interface Props {
	count: number;
	title: string;
}

export function StudentCounter({count, title}: Props) {
	return (
		<Tooltip>
			<Tooltip.Trigger>
				<Counter align='center'>
					<IconWrapper data-cy='icon-wrapper'>
						<Users/>
					</IconWrapper>
					<CounterBox>
						<Text data-cy='student-counter' font='mono' size='small'>{count}</Text>
					</CounterBox>
					<Text size='small'>Students</Text>
				</Counter>
			</Tooltip.Trigger>
			<Tooltip.Content variant='secondary'>
				<Text size='small' >{`${count} students will receive ${title} for the selected tests`}</Text>
			</Tooltip.Content>
		</Tooltip>
		
	);
}
