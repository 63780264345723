import {DataRow, SortModel} from 'modules/reports/growth-report/types';

export class Comparator {
	sortModel: SortModel;
	additionalSortModels: SortModel[];

	constructor(sortModel: SortModel, additionalSortModels: SortModel[]) {
		this.sortModel = sortModel;
		this.additionalSortModels = additionalSortModels;
	}

	private stringCompare(left, right) {
		let l = left;
		let r = right;
		if (this.sortModel.direction === 'Desc') {
			r = left;
			l = right;
		}
		r = r != null ? r.trim() : r;
		l = l != null ? l.trim() : l;

		if (l > r) {
			return 1;
		}
		if (l < r) {
			return -1;
		}
		return 0;

	}

	private intCompare(left, right) {
		if (left === right) {
			return 0;
		}
		if (this.sortModel.direction === 'Asc') {
			return left > right ? 1 : -1;
		}
		if (this.sortModel.direction === 'Desc') {
			return right > left ? 1 : -1;
		}
		return 0;
	}

	comparer(left: DataRow, right: DataRow) {
		if (this.sortModel.direction === 'None') {
			return 0;
		}
		let leftValue;
		let rightValue;
		if (this.sortModel.fieldName.indexOf('.') > 0) {
			let parts = this.sortModel.fieldName.split('.');
			let results = parts[0];
			let testID = parts[1];
			let type = parts[2];
			let leftResults = left[results][testID];
			let rightResults = right[results][testID];
			switch(type) {
				case 'growthPercent':
					leftValue = leftResults.growthPercent
					rightValue = rightResults.growthPercent
					break;
				case 'firstPercent':
					leftValue = leftResults.firstPercent
					rightValue = rightResults.firstPercent
					break;
				case 'numberOfTestedStudents':
					leftValue = leftResults.numberOfTestedStudents
					rightValue = rightResults.numberOfTestedStudents
					break;
				default:
					leftValue = leftResults.periodsPercent[+type]
					rightValue = rightResults.periodsPercent[+type]
					break;
			}

			if (leftValue == null) {
				leftValue = -1;
			}
			if (rightValue == null) {
				rightValue = -1;
			}
		} else {
			if (this.sortModel.fieldName === 'fullName'){
				leftValue = [left.firstName, left.lastName].join(' ');
				rightValue = [right.firstName, right.lastName].join(' ');
			} else {
				leftValue = left[this.sortModel.fieldName];
				rightValue = right[this.sortModel.fieldName];
			}
		}
		let value;
		if (Number.isInteger(leftValue) && Number.isInteger(rightValue)) {
			value = this.intCompare(leftValue, rightValue);
		} else {
			value = this.stringCompare(leftValue, rightValue);
		}

		let i: number;
		if (this.additionalSortModels != null && this.additionalSortModels.length && value === 0) {
			for (i = 0; i < this.additionalSortModels.length; i++) {
				let comparator = new Comparator(this.additionalSortModels[i], []);

				value = comparator.comparer(left, right);
				if (value !== 0) {
					break;
				}
			}
		}
		return value;
	}
}
