import {useState} from 'react';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {SettingsResponse, Student as StudentType} from '../../../../types';
import {getGroupAndStudent} from '../../../../utils';
import styles from '../../styles.module.less';

interface Props {
	value: StudentType;
	settings: SettingsResponse;
	onChange: (value: {student: StudentType, group: string}) => void;
}

export const Student = (props: Props): JSX.Element => {
	const {
		specialistTitle,
		specialistLastName,
		groups,
	} = props.settings;
	const result = getGroupAndStudent(props.value.id, groups);
	const [group, setGroup] = useState(result ? result.group : groups[0]);
	const [student, setStudent] = useState(props.value);

	return (
		<>
			<div className={styles.field}>
					<label className={styles.title}>Specialist:</label>
					<span className={styles.muted}>{specialistTitle} {specialistLastName}</span>
				</div>
				<div className={styles.field}>
					<label className={styles.title}>Group:</label>
					<Dropdown
						optionName='name'
						autoWidth={true}
						value={[group]}
						setValue={(group) => {
							setGroup(group[0]);
							setStudent(group[0].students[0]);
							props.onChange({student: group[0].students[0], group: group[0].name});
						}}
					>
						{groups.map((group, index) => (
							<Option
								key={index}
								value={group}
							>
								{group.name}
							</Option>
						))}
					</Dropdown>
				</div>
				<div className={styles.field}>
					<label className={styles.title}>Student:</label>
					<Dropdown
						displayLabel={(value: StudentType[]) => `${value[0].firstName} ${value[0].lastName}`}
						autoWidth={true}
						value={[student]}
						setValue={(student) => {
							setStudent(student[0]);
							props.onChange({student: student[0], group: group.name});
						}}
					>
						{group.students.map((student) => (
							<Option
								key={student.id}
								value={student}
							>
								{`${student.firstName} ${student.lastName}`}
							</Option>
						))}
					</Dropdown>
				</div>
		</>
	);
};
