export function useFormState(key: string) {
	const getState = (clear = false) => {
		const item = sessionStorage.getItem(key);
		if (item === null) {
			return item;
		}
		const data = JSON.parse(item);
		if (clear) {
			sessionStorage.removeItem(key);
		}
		return data;
	};
	const setState = (data: Record<string, any>) => {
		sessionStorage.setItem(key, JSON.stringify(data));
	};

	return {getState, setState};
}
