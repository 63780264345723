export const SessionSelectorSelectors = {
	resumeYesButton: 'resume-yes-button',
	resumeNoButton: 'resume-no-button',
	sessionSelector: 'session-selector',
	shuffleQuestions: 'shuffle-questions',
	startTest: 'start-test-button',
	retestAll: 'retest-all-button',
	retestIncorrect: 'retest-incorrect-button',
	close: 'session-selector-close-button',
	noIncorrectItemsClose: 'no-incorrect-items-close',
	noIncorrectItemsAlert: 'no-incorrect-items-alert',
};
