import React from 'react';
import './component.less';
import {TextInput} from '@esgi/deprecated/elements';
import GradeScaleEntryColorPicker
	from 'shared/modules/grade-scale/grade-scale/info/entry/name/grade-entry-color-picker/component';
import ValidationWrapper from '@esgi/deprecated/elements/validation-wrapper';
import {IGradeScaleEntryForm} from 'shared/modules/grade-scale/grade-scale/models';
import {ValidationStatus} from '@esgi/core/enums';

export class Props {
	entry: IGradeScaleEntryForm;
	onChange: (field: string, value: any) => void;
	showValidation: boolean;
	withErrorTooltip: boolean;
}

export default class GradeScaleEntryName extends React.PureComponent<Props> {
	onGradeNameChange(value: string) {
		this.props.onChange('gradeName', value.length < 4 ? value : this.props.entry.gradeName.value);
	}

	get placeholder(){
		if (this.props.entry.id === 0){
			return 'e.g. O';
		}

		if (this.props.entry.id === 1){
			return 'e.g. S';
		}
	}
	
	render() {
		return <div className='grade-scale-entry-name'>
			<div className='text-input-wrapper'>
				<ValidationWrapper
					message={this.props.entry.gradeName.validation.message}
					showValidation={this.props.entry.gradeName.validation.status === ValidationStatus.Invalid && this.props.showValidation}
					withTooltip={this.props.withErrorTooltip}
					validationPlacement='left'
					validationMessageOffsetX={-13}
				>
					<TextInput
						value={this.props.entry.gradeName.value}
						className='grade-scale-entry-name-text'
						onEdit={(value) => this.onGradeNameChange(value)}
						placeholder={this.placeholder}
					/>
				</ValidationWrapper>
				<ValidationWrapper
					message={this.props.entry.color.validation.message}
					showValidation={this.props.entry.color.validation.status === ValidationStatus.Invalid && this.props.showValidation}
					validationPlacement='left'
					withTooltip={this.props.withErrorTooltip}
					validationMessageOffsetX={-13}
				>
					<GradeScaleEntryColorPicker
						color={this.props.entry.color.value}
						onChange={(color) => this.props.onChange('color', color)}
					/>
				</ValidationWrapper>
			</div>
		</div>;
	}
}