import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(Box, {
	display: 'inline-grid',
	paddingTop: 4,
	paddingBottom: 4,
	paddingLeft: 8,
	paddingRight: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$red92',
	backgroundColor: '$negativeBackground',
	borderRadius: 3,
});
