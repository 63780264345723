import {ControlValue, FormControl, FormGroup, Validator, ValidatorArgs, Validators} from '@esgillc/ui-kit/form';
import {DictionaryItem} from 'modules/forms/students-form/types';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export type AdditionalFormType = FormGroup<{
	race: FormControl<DictionaryItem[]>,
	lunchStatus: FormControl<DictionaryItem[]>,
	programs: FormControl<DictionaryItem[]>,
	comments: FormControl<string>,
	birthDate: FormControl<string>
},
	ControlValue<{
		race: FormControl<DictionaryItem[]>,
		lunchStatus: FormControl<DictionaryItem[]>,
		programs: FormControl<DictionaryItem[]>,
		comments: FormControl<string>,
		birthDate: FormControl<string>
	}>>


const dateValidator = (): Validator<string> => {
	return function (validationChain: Observable<ValidatorArgs<string>>) {
		return validationChain.pipe(tap(args => {
			const value = args.field.currentValue as string | number;
			if(!value && value!==0) {
				return;
			}
			if(value === 0) {
				args.errors.push('invalidDate');
				return;
			}
			const norLaterThanToday = new Date(value) <= new Date();

			if (!norLaterThanToday) {
				args.errors.push('laterThanToday');
			}
		}));
	};
};

export const createAdditionalForm = ():
	AdditionalFormType => new FormGroup({
	race: new FormControl([]),
	lunchStatus: new FormControl([]),
	programs: new FormControl([]),
	comments: new FormControl(''),
	birthDate: new FormControl('', {validators: [dateValidator()]}),
});
