import {useCallback, useEffect, useMemo, useState} from 'react';
import {RubricCriterion, TestInfo, TestSession} from '../../../../../types';
import {useEditableCommonState} from '../../../hooks/use-editable-common-state';
import {GridProgressBarItem} from '@esgi/ui';

export function useEditModeState({
	sessionInfo: defaultSessionInfo,
	testInfo,
	onUpdateTestSession,
}: {
	sessionInfo: TestSession;
	testInfo: TestInfo;
	onUpdateTestSession: (args: {summaryNotes: string; testDate: string; answers: TestSession['rubricAnswers']}) => void;
}) {
	const maxCriteriaValue = testInfo.totalPossible / defaultSessionInfo.rubricAnswers.length;

	const [editableSessionInfo, setEditableSessionInfo] = useState(defaultSessionInfo);

	useEffect(() => {
		setEditableSessionInfo(defaultSessionInfo);
	}, [defaultSessionInfo]);

	const {
		dateTimeRef,
		setIsSessionTimeInfoValid,
		setSessionTimeInfoTouched,
		sessionNote,
		onUpdateSessionNote,
		isCommonDataTouched,
		isCommonDataValid,
	} = useEditableCommonState({
		sessionInfo: editableSessionInfo,
	});

	const {correctAnswers, progressBarItems} = useMemo(() => {
		let correctAnswers = 0;

		const progressBarItems = editableSessionInfo.rubricAnswers.map<GridProgressBarItem>(({score}) => {
			correctAnswers += score;

			return {
				value: score,
				maxValue: maxCriteriaValue,
			};
		});

		return {
			correctAnswers,
			progressBarItems,
		};
	}, [editableSessionInfo, maxCriteriaValue]);

	const onSetScore = useCallback(({criteriaID, newScore}: {criteriaID: RubricCriterion['id']; newScore: number}) => {
		setEditableSessionInfo((currentSessionInfo) => ({
			...currentSessionInfo,
			rubricAnswers: currentSessionInfo.rubricAnswers.map((iteratedAnswer) =>
				iteratedAnswer.criteriaID === criteriaID
					? {
							...iteratedAnswer,
							score: newScore,
					  }
					: iteratedAnswer,
			),
		}));
	}, []);

	const onAddCommentToEditableSession = useCallback(
		({criteriaID, comment}: {criteriaID: RubricCriterion['id']; comment: string}) => {
			setEditableSessionInfo((currentSessionInfo) => ({
				...currentSessionInfo,
				rubricAnswers: currentSessionInfo.rubricAnswers.map((iteratedAnswer) =>
					iteratedAnswer.criteriaID === criteriaID
						? {
								...iteratedAnswer,
								notes: comment,
						  }
						: iteratedAnswer,
				),
			}));
		},
		[],
	);

	const onDeleteCommentFromEditableSession = useCallback(({criteriaID}: {criteriaID: RubricCriterion['id']}) => {
		setEditableSessionInfo((currentSessionInfo) => ({
			...currentSessionInfo,
			rubricAnswers: currentSessionInfo.rubricAnswers.map((iteratedAnswer) =>
				iteratedAnswer.criteriaID === criteriaID
					? {
							...iteratedAnswer,
							notes: '',
					  }
					: iteratedAnswer,
			),
		}));
	}, []);

	const handleSave = useCallback(() => {
		const dateTime = dateTimeRef.current?.getFullDate();

		onUpdateTestSession({
			answers: editableSessionInfo.rubricAnswers,
			summaryNotes: sessionNote,
			testDate: dateTime ?? editableSessionInfo.testDate,
		});
	}, [dateTimeRef, editableSessionInfo, onUpdateTestSession, sessionNote]);

	const isDataTouched =
		isCommonDataTouched ||
		defaultSessionInfo.rubricAnswers.some((defaultRubricAnswer) => {
			const editableRubricAnswer = editableSessionInfo.rubricAnswers.find(
				({criteriaID}) => criteriaID === defaultRubricAnswer.criteriaID,
			);

			return (
				editableRubricAnswer?.notes !== defaultRubricAnswer.notes ||
				editableRubricAnswer.score !== defaultRubricAnswer.score
			);
		});

	const isDataValid = isCommonDataValid;

	return {
		isDataTouched,
		progressBarItems,
		correctAnswers,
		setSessionTimeInfoTouched,
		setIsSessionTimeInfoValid,
		dateTimeRef,
		maxCriteriaValue,
		onSetScore,
		sessionNote,
		onUpdateSessionNote,
		isDataValid,
		handleSave,
		editableSessionInfo,
		onAddCommentToEditableSession,
		onDeleteCommentFromEditableSession,
	};
}
