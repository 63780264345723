import {BehaviorSubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {getUser} from '@esgi/core/authentication';
import {BaseService} from '@esgi/core/service';
import {ElementStatus} from '@esgillc/ui-kit/form';
import Enumerable from 'linq';
import {SsoTracker} from '@esgi/core/tracker';
import {Fonts} from 'shared/modules/assets/fonts';
import {State as ColorPickerState} from '@esgi/deprecated/elements';
import {createAutoTestCreatorForm} from './form';
import {
	AutoTestCreatorInitResponse,
	Dictionary,
	PreviewState,
	QuestionAreaSize,
	Test,
	TileState,
} from './types';
import {defaultValues, dictionary} from './constants';
import {getContentAreaId, serializeQuestions} from './utils';

export class AutoTestCreatorService extends BaseService {

	public form = createAutoTestCreatorForm();

	public dictionary = new BehaviorSubject<Dictionary>(null);
	public previewState = new BehaviorSubject<PreviewState>(null);
	public colorPicker = new BehaviorSubject<ColorPickerState>(new ColorPickerState());

	private controller = 'assets/quick-test-creator';
	private questionAreaSize: QuestionAreaSize = {width: 0, height: 0};
	private currentUser = getUser();

	public init() {
		return this.httpClient.ESGIApi.get<AutoTestCreatorInitResponse>(this.controller, 'init')
			.pipe(tap((response) => {

				const {contentAreas, questionAreaHeight, questionAreaWidth} = response;
				const {defaultColors, topicTemplates, topics, gradeLevels} = dictionary;
				const {font, contentAreaID} = defaultValues;

				this.questionAreaSize = {height: questionAreaHeight, width: questionAreaWidth};

				this.dictionary.next({
					contentAreas,
					topics,
					topicTemplates,
					defaultColors,
					gradeLevels,
					fonts: Enumerable.from(Fonts).where(x => x.systemName !== 'AbcMath').toArray(),
				});

				this.form.controls.font.value = [this.dictionary.value.fonts.find(v => v.systemName === font)];
				this.form.controls.contentArea.value = [contentAreas.find(c => c.id === contentAreaID)];
			}));
	}

	public save = () => {
		if (!this.previewState.value) {
			this.preview();
		}

		const {testName, description, shareTest, contentArea, grade} = this.form.value;
		const {userID, firstName, lastName} = this.currentUser;

		const test = new Test(
			testName,
			description,
			this.previewState?.value.tiles.map(t => t.question),
			this.colorPicker.value.selectedColor,
			contentArea[0].id,
			grade.map(v => v.id),
			`${firstName} ${lastName}`,
			userID,
			shareTest,
		);

		return this.httpClient.ESGIApi.post<{ testID: number }>(this.controller, 'create', test)
			.pipe(tap(r => {
				SsoTracker.trackEvent({
					trackingEvent: 'ATC',
					data: {userId: this.currentUser.userID, testId: r.testID},
				});
			}));
	};

	public onChangeTopic = (id: number) => {
		const {
			testName,
			description,
			directions,
			contentAreaName,
		} = dictionary.topicTemplates.find(t => t.topicID === id);

		this.form.value = {
			...this.form.value,
			testName,
			description,
			directions,
			contentArea: [getContentAreaId(contentAreaName, this.dictionary.value.contentAreas)],
		};

		this.form.controls.testName.status = ElementStatus.valid;
		this.form.controls.description.status = ElementStatus.valid;
	};

	public preview = () => {
		const {questions, font, directions} = this.form.value;

		const tiles = serializeQuestions({
			questions,
			fontFamily: font[0].systemName,
			directions,
			questionAreaSize: this.questionAreaSize,
		}).map(q => {
			const tile = new TileState();
			tile.question = q;

			return tile;
		});

		const previewState = new PreviewState(
			this.form.controls.testName.value,
			tiles,
			this.currentUser.firstName + ' ' + this.currentUser.lastName,
			tiles[0].id,
		);
		this.previewState.next(previewState);
	};
}
