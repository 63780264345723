import React, {ChangeEvent} from 'react';
import styles from '../../../../../../styles.module.less';
import {Checkbox} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

interface Props {
	hasGradeScales: boolean
	includeGradeOption: boolean
	onIncludeGradeOptionChange: (v: ChangeEvent<HTMLInputElement>) => void;
}

export function IncludeGradeOption({hasGradeScales, includeGradeOption, onIncludeGradeOptionChange}: Props) {
	return <OnHoverTooltip message={!hasGradeScales && 'You need to configure Grade Scales to use this option.'}>
		<div className={styles.formGroup}>
			<Checkbox
				disabled={!hasGradeScales}
				checked={includeGradeOption}
				onChange={onIncludeGradeOptionChange}>Grades</Checkbox>
		</div>
	</OnHoverTooltip>;
}
