import React from 'react';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {CenterPanel} from 'shared/page/layout/center-panel';
import Layout from 'shared/page/layout/layout';
import {SidePanel} from 'shared/page/layout/side-panel/side-panel';
import {ErrorBoundary} from '@esgi/core/react';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {userStorage} from '@esgi/core/authentication';
import AppHistory from 'shared/tools/history/history';
import {InlineManual} from 'shared/tools/inline-manual/inline-manual';
import {RoutesMap} from '@esgi/main/libs/core';
import {Filter} from './components/filter/filter';
import Landing from './components/landing/landing';
import Search from './components/search/search';
import SearchHeader from './components/header/header';
import SearchResults from './components/search-results/search-results';
import {RightPanel} from './components/side-panels/right-panel';
import {DataService} from './services/data-service';
import {MainScreen} from './enums';
import {TestExplorerBackEvent} from './events';
import './styles.less';
import {Footer} from 'shared/page/footer/footer';
import {imageQueueService} from 'pages/test-explorer/services/image-queue-service';

class State {
	loaded: boolean[] = [false, false, false];
	currentScreen: MainScreen = MainScreen.Landing;
}

export default class TestExplorer extends React.Component<{excludeLayout?: boolean}, State> {
	public readonly state = new State();
	private readonly eventBus = new EventBusManager();
	private readonly dataService = new DataService();
	private onDestroy$: Subject<void> = new Subject();

	public componentDidMount(): void {
		this.dataService.filter.onChanged$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((v) => {
				if (this.state.currentScreen === MainScreen.Landing && v.touched) {
					this.setState({currentScreen: MainScreen.Results});
				}
			});
	}

	public componentWillUnmount(): void {
		this.dataService.destroy();
		this.onDestroy$.next();
		this.eventBus.destroy();
		imageQueueService.clear();
		InlineManual.destroy();
	}

	private back(): void {
		dispatchAppEvent(TestExplorerBackEvent);
		if (this.state.currentScreen === MainScreen.Results) {
			this.setState({currentScreen: MainScreen.Landing});
		}
		if (this.dataService.filter.currentValue.keyword) {
			this.dataService.filter.clear();
		} else {
			if (this.dataService.filter.currentValue.touched) {
				this.dataService.filter.clear();
			} else {
				AppHistory.navigate(RoutesMap.home);
			}
		}
	}

	private landingLoadingHandler(): void {
		const status = this.state.loaded;
		const newStatus = status.sort();
		newStatus.shift();
		newStatus.push(true);
		this.setState({loaded: newStatus});
	}

	render(): JSX.Element | false | null {
		return <>
			<Layout
				redesign={this.props.excludeLayout}
				loaded={this.state.loaded}
				preloaderLifted={() => this.pageShown()}>
				<SidePanel collapsable>
					<Filter dataService={this.dataService}/>
				</SidePanel>
				<CenterPanel>
					<div className='te-content'>
						<div className='te-top-panel'>
							<div className='te-top-panel-center'>
								{this.state.currentScreen !== MainScreen.Landing && <div className='back'>
									<a href='#' onClick={() => this.back()}>
										<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12'
										     viewBox='0 0 8 12'
										     fill='none'>
											<path d='M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z'
											      fill='#0088CC'/>
										</svg>
										Back
									</a>
								</div>}
								<h3 className='page-title'>Test Explorer</h3>
								<Search dataService={this.dataService}
								        currentScreen={this.state.currentScreen}
								/>
							</div>
							<div className='te-top-panel-right'/>
						</div>
						{this.state.currentScreen === MainScreen.Results &&
							<div className='search-header-outer'>
								<SearchHeader dataService={this.dataService}/>
							</div>}
						<div className='te-main-panel'>
							<div className='te-main-center'>
								<ErrorBoundary fillSpace={true}>
									{this.renderMainCenter()}
								</ErrorBoundary>
							</div>
							<RightPanel subjectsService={this.dataService.subjects}/>

						</div>
					</div>
				</CenterPanel>
			</Layout>
			<BackgroundDownloadManager/>
		</>;
	}

	renderMainCenter() {
		if (this.state.currentScreen === MainScreen.Landing) {
			return this.renderLanding();
		}
		return this.renderResultsScreen();
	}

	renderLanding() {
		return <>
			<Landing landingService={this.dataService.landing}
			         onCardsLoaded={() => this.landingLoadingHandler()}
			         subjectsService={this.dataService.subjects}/>
			{!this.props.excludeLayout && <Footer/>}
		</>;
	}

	renderResultsScreen() {
		return <>
			<div className='search-main-panel'>
				<SearchResults dataService={this.dataService}/>
			</div>
			{!this.props.excludeLayout && <Footer/>}
		</>;
	}

	private pageShown() {
		if(!this.props.excludeLayout){
			InlineManual.load(userStorage.get(), '5b0427080af09051077710e09e679ac1', true);
		}
	}
}
