export interface RegistrationResult {
    userID: number;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    token: string;
    cleverUrl: string;
    errorMessage: string;
}

export enum ErrorTypes {
    RequestTimeout = 'request_timeout',
    TokenIsRequired = 'token_is_required',
    ReqistrationGuidIsRequired = 'guid_is_required',
    CacheIsNotExists = 'cache_is_not_exists',
    UserNotFound = 'user_not_found',
    CleverEmailIsEmpty = 'clever_email_is_empty',
    IsUnauthorized = 'is_unauthorized',
    RegistrationHasError = 'registration_has_error',
}