import {IndicatorType, Input as BaseInput} from '../../controls/input';
import {InputProps} from '../../controls/input/components/input';
import {InputCleanable, InputCleanableProps} from '../../controls/input/components/input-cleanable';
import {InputWithIconProps} from '../../controls/input/components/input-with-icon';
import {ElementStatus, useFormElementContext} from '../../form/index';
import {ChangeEvent, ComponentPropsWithoutRef, forwardRef, useCallback, useMemo} from 'react';
import {useComposedRefs} from '../../utils/hooks';

type BaseInputProps = (InputProps | InputCleanableProps | InputWithIconProps) & {
	variant?: 'iconable' | 'cleanable' | 'cleanableIndicator' | '';
};

export const BaseFormInput = forwardRef<HTMLInputElement, BaseInputProps>(
	({children, variant, ...props}, forwardedRef) => {
		const {status, setValue, validateOnBlur, value, setRef} = useFormElementContext(
			{
				string: true,
				number: true,
				object: true,
			},
			Input,
		);

		const onBlur = useCallback(() => validateOnBlur(), [validateOnBlur]);
		const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), [setValue]);
		const onClearClick = useCallback(() => setValue(''), [setValue]);

		const inputRef = useComposedRefs(forwardedRef, setRef);

		const commonProps = {
			error: status === ElementStatus.invalid,
			disabled: status === ElementStatus.disabled,
			onChange,
			onBlur,
			value,
			ref: inputRef,
			...props,
		};

		const indicatorStatus = useMemo(() => {
			if (status === ElementStatus.invalid) {
				return IndicatorType.Failed;
			} else if (status === ElementStatus.valid) {
				return IndicatorType.Succeeded;
			} else if (status === ElementStatus.pending) {
				return IndicatorType.Pending;
			}
			return IndicatorType.None;
		}, [status]);

		if (variant === 'iconable') {
			return <BaseInput.Iconable {...commonProps}>{children as InputWithIconProps['children']}</BaseInput.Iconable>;
		}

		if (variant === 'cleanable') {
			return <BaseInput.Cleanable onClearClick={onClearClick} {...commonProps} />;
		}

		if (variant === 'cleanableIndicator') {
			return (
				<BaseInput.CleanableIndicator
					onClearClick={onClearClick}
					status={indicatorStatus}
					{...(commonProps as Omit<ComponentPropsWithoutRef<typeof InputCleanable>, 'error'>)}
				/>
			);
		}

		return <BaseInput {...commonProps} />;
	},
);

export const Input = {
	Base: forwardRef<HTMLInputElement, InputProps>((props, ref) => <BaseFormInput ref={ref} {...props} />),
	Cleanable: forwardRef<HTMLInputElement, InputCleanableProps>((props, ref) => (
		<BaseFormInput variant='cleanable' ref={ref} {...props} />
	)),
	Iconable: forwardRef<HTMLInputElement, InputWithIconProps>((props, ref) => (
		<BaseFormInput variant='iconable' ref={ref} {...props} />
	)),
	CleanableIndicator: forwardRef<HTMLInputElement, InputCleanableProps>((props, ref) => (
		<BaseFormInput variant='cleanableIndicator' ref={ref} {...props} />
	)),
};
