import React from 'react';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {SecondaryProfileFormType} from '../../forms/secondary-profile-form';
import styles from './styles.module.less';

export interface Props {
	form: SecondaryProfileFormType;
}

export function SecondaryProfileInfo({form}: Props) {
	return <Form controller={form}>
		<div className={styles.inputGroup}>
			<FormElement control={form.controls.firstName}>
				<div data-cy={'firstname-input'}>
					<label className={styles.label}>First name</label>
					<Input/>
					<OnErrorTooltip showOnError='required' placement='top'>
						Please enter first name.
					</OnErrorTooltip>
				</div>
			</FormElement>
			<FormElement control={form.controls.lastName}>
				<div data-cy={'lastname-input'}>
					<label className={styles.label}>Last name</label>
					<Input/>
					<OnErrorTooltip showOnError='required' placement='top'>
						Please enter last name.
					</OnErrorTooltip>
				</div>
			</FormElement>
		</div>
		<div className={styles.input}>
			<div data-cy={'email-input'}>
				<FormElement control={form.controls.email}>
					<label className={styles.label}>Email</label>
					<Input/>
					<OnErrorTooltip showOnError='email' placement='top'>
						Invalid email.
					</OnErrorTooltip>
				</FormElement>
			</div>
		</div>
	</Form>;
}
