export type TabItem<TabId extends string = string> = {
	value: TabId;
	label: string;
	disabled?: boolean;
	dataCy?: string
	valueDescription: string,
	applyDisabledStyles: false,
	permitted: false,
	contentType: 'text',
};

export enum TestTypeTabId {
	All = 'All',
	YN = 'YN',
	Rubric = 'Rubric',
	Score = 'Score',
}

export enum TestScopeTabId {
	All = 'All',
	District = 'District',
	MyTests = 'MyTests',
}
