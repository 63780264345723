import {BehaviorSubject, forkJoin, from, tap} from 'rxjs';
import {getUser, UserType} from '@esgi/core/authentication';
import {createDistrictAdminForm} from 'modules/forms/students-form/components/forms/location/district-admin';
import {RefObject} from 'react';
import {BaseTabService} from '../../../base-tab-service';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {switchMap} from 'rxjs/operators';
import {
	ClassesResponse, ClassesResponseItem,
	ClassItem,
	GroupsItem,
	PreSelected, ProfileInitData,
	Schools,
	StudentProfileMode,
	StudentProfileTab,
	TabsApi,
	Teacher,
	UserRawResponse,
} from 'modules/forms/students-form/types';
import {ObservableBuilder} from '@esgi/api';
import {StudentChangedEvent, StudentSaveAndAddEvent} from 'modules/forms/students-form/events';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';

interface InitArgs {
	initData: ProfileInitData,
	mode: StudentProfileMode,
	studentID: number,
	preSelected: PreSelected | null,
	tabsApi: RefObject<TabsApi>
}

export class DistrictAdminService extends BaseTabService {
	public preSelected = new BehaviorSubject<PreSelected>(null);
	public teachers = new BehaviorSubject<Teacher[]>(null);
	public schools = new BehaviorSubject<Schools[]>(null);
	public classes = new BehaviorSubject<ClassItem[]>(null);
	public groups = new BehaviorSubject<(GroupsItem & { isDisabled?: boolean })[]>(null);
	public selectedClasses = new BehaviorSubject<ClassItem[]>([]);

	public form = createDistrictAdminForm();

	protected tab = StudentProfileTab.Location;
	private currentUser = getUser();

	public init({initData, mode, studentID, preSelected, tabsApi}: InitArgs) {
		this.studentID = studentID;
		this.preSelected.next(preSelected);
		this.initData.next(initData);

		const schoolID = this.initData.value?.location?.schoolID || this.currentUser?.schoolID;

		return forkJoin([this.getTeachers(schoolID), this.getSchools(), this.setClassesAndGroups()]).pipe(tap(([teachersResponse, schoolsResponse]) => {
			const selectedTeacherID = initData?.location?.teacherID;
			const teacher = teachersResponse.users.find(t => t.userID === selectedTeacherID);
			const school = schoolsResponse.schools.find(s => s.schoolID === schoolID);

			this.form.value = {
				classIDs: initData?.location?.classIDs || [],
				groupIDs: initData?.location?.groupIDs || [],
				teacher: teacher ? [{...teacher, fullName: `${teacher.firstName} ${teacher.lastName}`}] : [],
				school: school ? [school] : [],
			};

			this.selectedClasses.next(this.form.controls.classIDs.value?.map(id => this.classes.value?.find(cl => id === cl.id)));

			if (mode !== StudentProfileMode.view) {
				this.disableGroups();
				this.form.controls.classIDs.onChanged.subscribe((ch) => {
					if (ch.reason === 'value') {
						this.disableGroups();
					}

					if (ch.currState.value) {
						this.selectedClasses.next(ch.currState.value?.map(id => this.classes.value?.find(cl => id === cl.id)));
					} else {
						this.selectedClasses.next([]);
					}
				});
				this.form.controls.teacher.onChanged.subscribe((ch) => {
					if (ch.reason === 'value') {
						this.form.controls.classIDs.value = [];
						this.form.controls.groupIDs.value = [];
						this.setClassesAndGroups(ch.currState.value[0]?.userID).subscribe();
					}
				});

				this.form.controls.school.onChanged.subscribe((ch) => {
					if (ch.reason === 'value') {
						this.getTeachers(ch.currState.value[0].schoolID).subscribe(({users}) => {
							this.form.controls.teacher.value = users.length ? [{
								...users[0],
								fullName: `${users[0].firstName} ${users[0].lastName}`,
							}] : [];
							this.form.controls.teacher.status = ElementStatus.untouched;
						});
					}
				});
				this.initTabsApi(tabsApi);
			} else {
				this.form.status = ElementStatus.disabled;
			}
		}));
	}

	public getSchools() {
		return this.httpClient.ESGIApi.get<{ schools: Schools[] }>(this.controller, 'location/schools')
			.pipe(tap((s) => {
				this.schools.next(s.schools);
			}));
	}

	public save = (isSaveAndAdd?: boolean) => {
		const {
			groupIDs,
			classIDs,
			teacher,
			school,
		} = this.form.value;
		return this.form.validate().pipe(switchMap(v => {
			if (v.valid) {
				const model = {
					studentID: this.studentID,
					groupIDs: groupIDs.filter(group => !this.groups.value.find(item => Number(item.id) === group)?.isDisabled),
					classIDs,
					globalSchoolYearID: this.currentUser.globalSchoolYearID,
					teacherID: teacher[0]?.userID,
					schoolID: school[0]?.schoolID,
				};
				return this.httpClient.ESGIApi.post(this.controller, 'profile/location/save', model)
					.pipe(tap(() => {
						const {
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
						} = this.initData.value.general;
						if(!model.classIDs.length && this.initData.value.location.classIDs.length) {
							showSnackbarNotification('You have removed this student from all classes.');
						}
						this.initData.next({...this.initData.value, location: {
							...this.initData.value.location,
							classIDs, groupIDs, teacherID: model.teacherID, schoolID: model.schoolID,
						}});
						const event = new StudentChangedEvent(
							this.studentID,
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
							this.form.controls.school.value[0].schoolID,
							this.form.controls.teacher.value[0].userID,
							model.classIDs,
							model.groupIDs,
							null,
						);
						this.eventBus.dispatch(StudentChangedEvent, event);
						this.eventBus.dispatch(StudentSaveAndAddEvent, new StudentSaveAndAddEvent(isSaveAndAdd));
					}));
			}
			return from([]);
		}));
	};

	private getTeachers(schoolID: number) {
		const globalSchoolYearID = this.currentUser.globalSchoolYearID;
		return this.httpClient.ESGIApi
			.get<{ users: UserRawResponse[] }>(this.controller, 'location/users', {
				userType: UserType.T,
				schoolID,
				globalSchoolYearID,
			})
			.pipe(tap(res => {
				this.teachers.next(res.users.map(user => ({...user, fullName: `${user.firstName} ${user.lastName}`})));
			}));
	}

	private setClassesAndGroups = (selectedTeacherID?: number): ObservableBuilder<ClassesResponse> => {

		const userID = selectedTeacherID || this.initData?.value?.location?.teacherID;
		const queryParams = new URLSearchParams({
			...(userID ? {userID: userID.toString()} : {}),
			globalSchoolYearId: this.currentUser.globalSchoolYearID?.toString(),
			studentID: this.studentID?.toString(),
		});
		return this.httpClient.ESGIApi
			.get<ClassesResponse>(this.controller, `location/classes?${queryParams}`).pipe(tap(res => {
				this.classes.next(res.classes.map(item => ({id: item.classID, name: item.name, teacherID: userID})));
				this.groups.next(res.classes.reduce((acc: GroupsItem[], item: ClassesResponseItem) => {
					return [...acc, ...item.groups.map(item => ({
						id: item.groupID,
						name: item.name,
						classID: item.classID,
					}))];
				}, []));
				this.disableGroups();
			}));
	};

	private disableGroups = () => {
		this.groups.next(this.groups.value.map(group => ({
			...group,
			isDisabled: !this.form.controls.classIDs.value.includes(Number(group.classID)),
		})));
	};
}
