import {environment} from '@esgi/core/environments';
import {fromEvent} from 'rxjs';
import {io, Socket} from 'socket.io-client';

export class TeacherScreenClient {
	public readonly host = environment.url.replace('https://', 'wss://');
	public readonly socket: Socket = io(`${this.host}/teacher`, {
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 3000,
		path: '/share-screen/api',
		reconnectionAttempts: Infinity,
		withCredentials: true,
		transports: ['websocket', 'polling'],
	});

	public shared = fromEvent(this.socket, 'shared');
	public studentJoined = fromEvent(this.socket, 'studentJoined');

	constructor() {
		this.socket.on('reconnect_attempt', () => {
			this.socket.io.opts.transports = ['polling', 'websocket'];
		});

		this.socket.on('connect', () => {
		});
	}

	public share = (teacherID: number) => this.socket.emit('share', teacherID);

	public isExpired = (teacherID: number) => this.socket.emit('isExpired', teacherID);

	public dispose() {
		this.socket.close();
	}
}
