import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gridTemplateRows: 'auto auto 1fr',
	gap: 20,
	overflow: 'hidden',
});

export const LegendBox = styled(GridBox, {
	gap: 20,
	gridAutoFlow: 'column',
});

export const Divider = styled(Box, {
	width: '100%',
	height: 1,
	borderRadius: 1,
	backgroundColor: '$neutral92',
});

export const DiagramInfoBox = styled(GridBox, {
	gap: 26,
	gridTemplateColumns: '280px 1fr',
	overflow: 'hidden',
});

export const DiagramDescriptionBox = styled(GridBox, {
	height: 'max-content',
});

export const DiagramBox = styled(GridBox, {
	overflow: 'hidden',
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 6,
	paddingRight: 6,

	variants: {
		justifyCentered: {
			true: {
				justifyContent: 'center',
			},
		},
	},
});
