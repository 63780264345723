import {DeltaIndicator} from './components/delta-indicator';
import {ProgressWithPointer} from './components/progress-with-pointer';
import {ContentWrapper, ProgressBarInfo, ProgressBarTranscriptWrapper} from './components/progress-bars.styled';
import {TestType} from '@esgi/main/libs/core';
import {AnswersInfo} from './components/answer-info';
import {isNull} from 'underscore';

type YNProps = {
	testType: TestType.YN;
	testResultsCorrectVerbiage: string;
};

type ScoreProps = {
	testType: TestType.Score;
};

type Props = {
	value: number | null;
	maxValue: number;
	deltaIndicatorValue: number | null;
	result: number | null;
} & (YNProps | ScoreProps);

export function SessionInfo({value, maxValue, deltaIndicatorValue, result, ...props}: Props) {
	return (
		<ContentWrapper>
			<ProgressBarInfo>
				<ProgressWithPointer value={value} maxValue={maxValue} isPercentageType result={result} />
				<ProgressBarTranscriptWrapper>
					{props.testType === TestType.YN && (
						<AnswersInfo value={value} maxValue={maxValue} caption={`${props.testResultsCorrectVerbiage} answers`} />
					)}
					{!isNull(deltaIndicatorValue) && (
						<DeltaIndicator value={deltaIndicatorValue} caption='Since Last Test' withPersantageSign />
					)}
				</ProgressBarTranscriptWrapper>
			</ProgressBarInfo>
		</ContentWrapper>
	);
}
