import {ProgressBar} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useService} from '@esgi/core/service';

import {Container, Delta, Divider, LegendContainer, LegendItem, LegendText, PercentContainer} from '../kit';
import {CardBox} from '../../card/styled';
import {TestsTakenService} from './service';
import {
	ClassPerformanceRingContainer,
	RingsContainer,
	StudentPerformanceRingContainer,
} from '../average-performance/styled';
import {useStudentsData} from '../hooks';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	studentID: number,
	transparent?: boolean,
};

export function TestsTaken(props: Props) {
	const service = useService(TestsTakenService);

	const {loaded, data} = useStudentsData(service, props.studentID, props.testIDs, props.studentIDs);

	const {classPercent, studentPercent, delta} = data;

	const classPercentRound = Math.round(classPercent * 10) / 10;
	const studentPercentRound = Math.round(studentPercent * 10) / 10;
	const deltaRound = Math.round(delta * 10) / 10;

	return <CardBox transparent={props.transparent} dataCy='widget-card'>
		<Container direction='column' justify='between' noPadding={props.transparent}>
			<Text color='neutral40' size='medium' data-cy='widget-name'>Tests Taken</Text>
			<FlexBox justify='start' align='center'>
				<RingsContainer>
					<ClassPerformanceRingContainer>
						<ProgressBar value={loaded ? classPercentRound : 0} maxValue={100}>
							<ProgressBar.RingWithPointer progressValueColor='lowContrast'
							                             skeleton={!loaded}
							                             size={80}
							                             circleStrokeWidth={8}
							                             pointerFillColor='neutral40'/>
						</ProgressBar>
					</ClassPerformanceRingContainer>
					<StudentPerformanceRingContainer>
						<ProgressBar value={loaded ? studentPercentRound : 0} maxValue={100}>
							<ProgressBar.RingWithPointer progressValueColor='primaryVivid'
							                             skeleton={!loaded}
							                             size={60}
							                             circleStrokeWidth={8}
							                             pointerFillColor='primary48'>

							</ProgressBar.RingWithPointer>
						</ProgressBar>
					</StudentPerformanceRingContainer>
					{loaded && <StudentPerformanceRingContainer>
						<PercentContainer>
							<Text font='mono' bold color='primary48' size='medium' data-cy='student-percent'>
								{studentPercentRound}%
							</Text>
							<Divider/>
							<Text font='mono' bold color='neutral40' size='small' data-cy='class-percent'>
								{classPercentRound}%
							</Text>
						</PercentContainer>
					</StudentPerformanceRingContainer>}
				</RingsContainer>
				<LegendContainer>
					<LegendItem skeleton={!loaded} dataCy='legend-student'>
						<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<rect x='1.25' y='1.25' width='7.5' height='7.5' rx='3.75' fill='#059DF0' stroke='#DCEFF9'
							      strokeWidth='1.5'/>
						</svg>
						<LegendText font='mono' color='primary48' size='xSmall'>Student %</LegendText>
					</LegendItem>
					<LegendItem skeleton={!loaded} css={{marginTop: 6}} dataCy='legend-class'>
						<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<rect x='1.25' y='1.25' width='7.5' height='7.5' rx='3.75' fill='#666666' stroke='#E0E0E0'
							      strokeWidth='1.5'/>
						</svg>
						<LegendText font='mono' color='neutral40' size='xSmall'>Class %</LegendText>
					</LegendItem>
					<Delta skeleton={!loaded} css={{marginTop: 14}} value={deltaRound}>
						Compared to Class
					</Delta>
				</LegendContainer>
			</FlexBox>
		</Container>
	</CardBox>;
}
