import {ComponentProps} from 'react';
import {Cheap} from '@esgi/ui';
import {SubjectLevel} from '@esgi/main/libs/store';

export const cheapContent: Partial<
	Record<
		SubjectLevel,
		{
			cheapTitle: string;
			cheapColor: NonNullable<ComponentProps<typeof Cheap>['color']>;
		}
	>
> = {
	[SubjectLevel.District]: {
		cheapTitle: 'D',
		cheapColor: 'secondary',
	},
	[SubjectLevel.School]: {
		cheapTitle: 'S',
		cheapColor: 'primary',
	},
	[SubjectLevel.Teacher]: {
		cheapTitle: 'T',
		cheapColor: 'neutral',
	},
};
