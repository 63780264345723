import {useMemo} from 'react';
import {StudentSort, StudentInfo} from '../../../../../class-group/types';

import {deepClone} from './utils/deep-clone';

export function useStudentsData({
	sortStudentsBy,
	studentsInfo,
	visibleStudentsCount,
	showOnlyUntestedStudents,
	showDeletedSessions,
}: {
	studentsInfo: StudentInfo[];
	sortStudentsBy: StudentSort;
	visibleStudentsCount: number;
	showOnlyUntestedStudents: boolean;
	showDeletedSessions: boolean;
}) {
	const hasDeletedSessions = useMemo(() => {
		const hasDeletedSessions = studentsInfo.some((student) => {
			if (!student.testSessionsInfo.testSessions.length) {
				return false;
			}

			const hasNotTestedSession = student.testSessionsInfo.testSessions.some((session) => session.isDeleted);

			return hasNotTestedSession;
		});

		return hasDeletedSessions;
	}, [studentsInfo]);

	const sortedStudents = useMemo(() => {
		const sortedStudents = deepClone(studentsInfo).sort((a, b) => {
			if (sortStudentsBy === StudentSort.LastName) {
				return a.lastName.localeCompare(b.lastName);
			}

			if (sortStudentsBy === StudentSort.FirstName) {
				return a.firstName.localeCompare(b.firstName);
			}

			return 0;
		});

		sortedStudents.forEach((student) => {
			student.testSessionsInfo.testSessions = deepClone(student.testSessionsInfo.testSessions).sort(
				(a, b) => new Date(b.testDate).getTime() - new Date(a.testDate).getTime(),
			);
		});

		return sortedStudents;
	}, [studentsInfo, sortStudentsBy]);

	const filteredStudents = useMemo(() => {
		if (showOnlyUntestedStudents) {
			return sortedStudents.filter((student) => !student.testSessionsInfo.testSessions.length);
		}

		if (showDeletedSessions) {
			return sortedStudents;
		}

		const students = deepClone(sortedStudents);

		students.forEach((student) => {
			student.testSessionsInfo.testSessions = [...student.testSessionsInfo.testSessions].filter(
				(session) => !session.isDeleted,
			);
		});

		return students;
	}, [showOnlyUntestedStudents, sortedStudents, showDeletedSessions]);

	const {visibleStudents, notVisibleStudentsCount} = useMemo(() => {
		const visibleStudents = filteredStudents.slice(0, visibleStudentsCount);

		return {
			visibleStudents,
			notVisibleStudentsCount: filteredStudents.length - visibleStudents.length,
		};
	}, [filteredStudents, visibleStudentsCount]);

	return {
		hasDeletedSessions,
		visibleStudents,
		notVisibleStudentsCount,
	};
}
