import {PropsWithChildren} from 'react';
import {
	ReportModal,
	ReportModalHeader,
	ReportModalBody,
	ReportModalCloseIcon,
	ReportModalTitle,
} from '@esgi/deprecated/ui-kit/report/modal';
import {StudentLevelReportService} from '../../../../services/student-level-report-service';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {ReportModalPDFZipDownloadButton} from '@esgi/deprecated/ui-kit/report/modal/download-button/report-modal-pdf-zip-download-button';
import {ReportModalDownloadButton} from '@esgi/deprecated/ui-kit/report/modal/download-button/report-modal-download-button';
import {useStream} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

type ModalProps = PropsWithChildren<{
	onClosed: () => void;
	loading: boolean;
	service: StudentLevelReportService;
}>;

export function Modal({loading, onClosed, service, children}: ModalProps) {
	const modalRef = useModal();
	const onModalClose = useCloseModal(modalRef, onClosed);

	const selectedStudent = useStream(service.selectedStudent$);

	const allStudentsSelected = selectedStudent?.id === 0;

	return (
		<ReportModal modalManagerRef={modalRef}>
			<Loader show={loading} fullscreen />
			<ReportModalHeader className={styles.header}>
				<ReportModalTitle>Rubric Results</ReportModalTitle>
				{allStudentsSelected ? (
					<ReportModalPDFZipDownloadButton
						onZipClicked={service.downloadZip.bind(service)}
						onPDFClicked={service.downloadPDF.bind(service)}
					/>
				) : (
					<ReportModalDownloadButton onClick={service.downloadPDF.bind(service)} />
				)}
				<ReportModalCloseIcon
					className={styles.close}
					onClick={onModalClose}
				/>
			</ReportModalHeader>
			<ReportModalBody className={styles.body}>{children}</ReportModalBody>
		</ReportModal>
	);
}
