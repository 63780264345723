import {ReportSettingValueChangeParameters, ReportSettingsValue} from '../../types';
import {Section, Title, CheckboxControl} from './index.styled';

interface Props {
	disabled: boolean;
	value: ReportSettingsValue;
	onSettingValueChange(args: ReportSettingValueChangeParameters): void;
}

export function SettingsControl({disabled, value, onSettingValueChange}: Props) {
	return (
		<Section>
			<Title data-cy='title' size='small' font='mono' color='lowContrast'>
				Settings
			</Title>
			<CheckboxControl
				data-cy='show-baseline-checkbox'
				checked={value.showBaseline}
				onCheckedChange={(value) => onSettingValueChange({key: 'showBaseline', newValue: Boolean(value)})}
				label='Show Baseline'
				disabled={disabled}
			/>
			<CheckboxControl
				data-cy='carry-forward-checkbox'
				checked={value.carryScoresForward}
				onCheckedChange={(value) => onSettingValueChange({key: 'carryScoresForward', newValue: Boolean(value)})}
				label='Carry Forward'
				disabled={disabled}
			/>
		</Section>
	);
}
