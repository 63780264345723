import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Checkbox} from '@esgillc/ui-kit/control';
import {IconArrowDown, IconArrowTop} from '../icon';

export const SortableColumnStyled = styled(Box, {
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',

	'&:hover': {
		'& > svg': {
			display: 'block',
		},
	},
});

export const IconArrowTopStyled = styled(IconArrowTop, {
	display: 'block !important',
});

export const IconArrowDownStyled = styled(IconArrowDown, {
	display: 'none',
	variants: {
		visible: {
			true: {
				display: 'block',
			},
		},
	},
});

