import * as React from 'react';
import type {SVGProps} from 'react';

export function Block(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M14.004 8.001a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Zm0 14a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Zm-6-4a1 1 0 0 0 2 0v-4a1 1 0 0 0-2 0v4Zm14 0a1 1 0 0 0 2 0v-4a1 1 0 0 0-2 0v4Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				d='M25 9a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM25 23a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM9 21a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM11 9a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z'
			/>
		</svg>
	);
}
