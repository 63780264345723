import {BaseResponse, HttpClient} from '@esgi/api';

export class Api {
	cacheTracks: ITrackModel[];
	controller = 'home/school-year-selector';

	constructor() {
		this.cacheTracks = [];
	}

	private putTrack(track: ITrackModel) {
		const exist = this.cacheTracks.findIndex(t => t.trackID == track.trackID);
		if (exist >= 0) {
			this.cacheTracks[exist] = track;
			return;
		}
		this.cacheTracks.push(track);

	}

	refreshTrack(track: ITrackModel) {
		this.putTrack(track);
	}

	init() {
		return HttpClient.default.ESGIApi.get<IInitModel>(this.controller, 'init').toPromise()
			.then((resp) => {
				if (resp.track) {
					this.putTrack(resp.track);
				}
				return resp;
			});
	}


	switch(globalSchoolYearID: number) {
		return HttpClient.default.ESGIApi.post<BaseResponse<ISwitchResponse>>(
			this.controller,
			'switch',
			{globalSchoolYearID: globalSchoolYearID})
			.toPromise().then(r => {
				if (!r.isSuccess) {
					return {track: null, error: r?.errors?.at(0)?.description};
				}

				let track: ITrackModel = null;
				const obj = r.value;
				if (obj && obj.trackID) {
					track = {
						trackID: obj.trackID,
						autoTrack: obj.autoTrack,
						trackName: obj.trackName,
					};
					this.putTrack(track);
				}
				return {track: track, error: null};
			});
	}

	trackInfo(trackID: number): Promise<ITrackModel> {
		const exist = this.cacheTracks.find(t => t.trackID === trackID);
		if (exist) {
			return Promise.resolve(exist);
		}
		return HttpClient.default.ESGIApi.get<ITrackModel>(this.controller, 'track-info', {trackID: trackID})
			.toPromise().then(track => {
				this.putTrack(track);
				return track;
			});
	}
}

interface ISwitchResponse {
	trackID: number;
	trackName: string;
	autoTrack: boolean;
}

export interface IInitModel {
	schoolYears: IYearModel[];
	track: ITrackModel;
	currentGlobalSchoolYearID: number;
}

export interface IYearModel {
	globalSchoolYearID: number;
	name: string;
}

export interface ITrackModel {
	trackID: number;
	trackName: string;
	autoTrack: boolean;
}
