import React from 'react';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {Modal, ModalBody, ModalHeader} from '@esgi/deprecated/react';
import {userStorage} from '@esgi/core/authentication';
import ExportService from './export-service';
import {OptionsRef, TeacherActivityReport} from './report';

class State {
	reportLoaded: boolean = false;
}

class Props {
  schoolsGroupID: number;
  teachersGroupID: number;
	onCloseClicked: () => void;
}

export default class TeacherActivityReportModal extends React.Component<Props, State> {
	private optionsRef = new OptionsRef();
	private exportService: ExportService = new ExportService();
	globalSchoolYearId: number;
	districtId: number;

	constructor(props) {
		super(props);
		this.state = new State();
		const {globalSchoolYearID, districtID} = userStorage.get();
		this.globalSchoolYearId = globalSchoolYearID;
		this.districtId = districtID;
	}

	downloadPdfClicked() {
		this.exportService.exportPdf('Teacher_Activity_Report', this.optionsRef.current).subscribe();
	}

	downloadExcelClicked() {
		this.exportService.exportExcel('teacher_activity', this.optionsRef.current).subscribe();
	}

	render() {
		return <Modal className='blue-header teacher-activity-report-modal' loading={!this.state.reportLoaded}>
			<ServiceLoader fullscreen
			               trackingService={this.exportService}
			               longLoadingText='Please wait. This report may take a few minutes to load.'/>
			<ModalHeader title='Teacher Activity' close={{click: () => this.props.onCloseClicked(), white: true}}>
				<div id='downloadDropdown' className='btn-group'>
					<span className='btn-report' data-toggle='dropdown'>
						<i className='fa fa-download' aria-hidden='true'/>
						Download
					</span>
					<ul className='dropdown-menu dropdown-default'>
						<li>
							<a href='#' onClick={() => this.downloadPdfClicked()}>
								PDF
							</a>
						</li>
						<li>
							<a href='#' onClick={() => this.downloadExcelClicked()}>
								Excel
							</a>
						</li>
					</ul>
				</div>
			</ModalHeader>
			<ModalBody>
				<TeacherActivityReport
					onLoaded={() => this.setState({reportLoaded: true})}
					districtId={this.districtId}
          schoolsGroupID={this.props.schoolsGroupID}
          teachersGroupID={this.props.teachersGroupID}
					globalSchoolYearId={this.globalSchoolYearId}
					optionsToExportRef={this.optionsRef}/>
			</ModalBody>
		</Modal>;
	}

	public componentWillUnmount() {
		this.exportService.destroy();
	}
}
