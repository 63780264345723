import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';

export const SelectContent = styled(Select.Content, {
	minWidth: '232px',
});

export const SelectField = styled(Select.Field, {
	minWidth: 100,
	maxHeight: 40,

	'& [data-placeholder-content]': {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},

	'& [data-value-content]': {
		display: 'grid',
		minHeight: 20,
		alignItems: 'center',
		justifyContent: 'start',

		'> *:not([data-placeholder-value])': {
			display: 'none',
		},

		[`& > ${Text}`]: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
});

export const SelectOption = styled(Select.Option, {
	'& [data-placeholder-value]': {
		display: 'none',
	},
});
