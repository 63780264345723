import SpecialistsSubSection from './subsections/specialists';
import TeachersSubSection from './subsections/teachers';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import {SchoolCommonData} from 'modules/subject-details/types';
import Section from '../../index';

class Props {
	commonData: SchoolCommonData;
	form: SchoolFormType;
}

export default function SchoolAdministratorPublishTo({
	commonData,
	form,
}: Props) {

	if (!commonData.schoolSpecialists?.length) {
		return <></>;
	}

	return <Section data-cy={'publish-to'} title='Publish to'>
		<TeachersSubSection form={form} schoolSubjectData={commonData}/>
		<SpecialistsSubSection form={form} schoolSubjectData={commonData}/>
	</Section>;
}

