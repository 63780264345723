import {Student} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/students/student';
import {Class} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/classes/class';
import {Group} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/groups/group';

export class RemoveStudentEvent {
	constructor(public student: Student) {}
}

interface StudentData {
	id: number;
	firstName: string;
	lastName: string;
	gradeLevelID: number;
	photoUrl: string;
	hasCredentials: boolean;
	studentIDN: string;
	languageID: number;
}

export class AddStudent {
	constructor(
		public studentsData: {
			student: Student;
			classIds: Class['id'][];
			groupIds: Group['id'][];
		}
	) {}
}

export class EditStudent {
	constructor(
		public studentsData: {
			student: StudentData;
			classIds: Class['id'][];
			groupIds: Group['id'][];
		}
	) {}
}

export class RemoveStudent {
	constructor(public studentID: number) {}
}
