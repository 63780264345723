import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const EsgiAgreementPage = lazyComponent(() => import('pages/esgi-agreement'));

export default function EsgiAgreement() {
	return (
		<RouteWrapper title='ESGI, LLC Terms of Use'>
			<Suspense fallback={<DefaultRouteFallback />}>
				<EsgiAgreementPage />
			</Suspense>
		</RouteWrapper>
	);
}
