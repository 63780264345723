import React, {ReactNode} from 'react';
import {distinctUntilChanged} from 'rxjs/operators';
import AlreadySavedDialog from './components/dialogs/already-saved-dialog';
import ContinueDialog from './components/dialogs/continue-dialog';
import UserMovedDialog from './components/dialogs/user-moved-dialog';
import TestScreen from './components/test-screen/test-screen';
import TestingService from './testing-service';
import {ResultModel, SessionStatus} from './types';
import ShareScreenStartedDialog from './components/dialogs/share-screen-started-dialog';
import {TestLauncherData, SubjectModel, StudentModel} from '../types';
import {SessionResults} from './components/sesssion-results';
import {dispatchAppEvent} from '@esgillc/events';
import {TestSavedEvent} from '../../../../assessments/src/kit/events';
import {Class} from '@esgi/main/libs/store';

class State {
	loading: boolean = false;
	initialized: boolean = false;
	status: SessionStatus = SessionStatus.None;
	showContinueDialog: boolean = false;
	showResultsDialog: boolean = false;
	showAlreadySavedDialog: boolean = false;
	showShareScreenStartedDialog: boolean = false;
	showUserMovedDialog: boolean = false;
}

interface Props {
    userID: number;
	launcherData: TestLauncherData,
	subject: Pick<SubjectModel, 'id' | 'type' | 'name'>;
	student: StudentModel;
	studentClass: Class;
    onClosed: () => void;
    onFinished: () => void;
    onTestHistoryClicked?: () => void;
    onTestSessionDetailsClicked?: () => void;
}

export class RubricTesting extends React.PureComponent<Props, State> {
	public state = new State();
	private service: TestingService = new TestingService();

	public componentDidMount() {
		this.service
			.init(this.props.launcherData.testID, this.props.student.id, this.props.userID)
			.subscribe(shareScreenStarted => {
				if (shareScreenStarted) {
					this.setState({
						initialized: true,
						showShareScreenStartedDialog: true,
					});
				} else {
					this.runTest();
				}
			});

		this.service.sessionStatus.pipe(distinctUntilChanged()).subscribe(s => {
			if (this.state.status !== s) {
				switch (s) {
					case SessionStatus.Started:
						this.setState({showContinueDialog: true});
						break;
					case SessionStatus.Saved:
						this.setState({showAlreadySavedDialog: true});
						break;
					case SessionStatus.UserMoved:
						this.setState({showUserMovedDialog: true});
						break;
					case SessionStatus.Normal:
						break;
				}
			}
		});
	}

	public componentWillUnmount() {
		this.service.destroy();
	}

	public runTest() {
		this.service
			.runTest()
			.subscribe(r => {
				if (r.sessionStatus === SessionStatus.Normal) {
					this.service.startTesting();
				}
				this.setState({initialized: true});
			});
	}

	public render() {
		return (
			<>
				{this.renderModals()}
			</>
		);
	}

	public renderModals() {
		if (!this.state.initialized) {
			return;
		}

		if (this.state.showContinueDialog) {
			return this.renderContinueDialog();
		}

		if (this.state.showAlreadySavedDialog) {
			return this.renderAlreadySavedDialog();
		}

		if (this.state.showUserMovedDialog) {
			return this.renderUserMovedDialog();
		}

		if (this.state.showShareScreenStartedDialog) {
			return this.renderShareScreenStartedDialog();
		}

		if(this.state.showResultsDialog) {
			return this.renderShowResultsDialog();
		}

		return this.renderTestScreen();
	}

	private renderContinueDialog() {
		return <ContinueDialog service={this.service}
                               onClosed={() => this.setState({showContinueDialog: false})}/>;
	}

	private renderUserMovedDialog(): ReactNode {
		return <UserMovedDialog onClosed={() => this.props.onClosed()}/>;
	}

	private renderShareScreenStartedDialog(): ReactNode {
		return <ShareScreenStartedDialog
            service={this.service}
            onDisconnected={() => this.setState({
                showShareScreenStartedDialog: false,
                initialized: false,
            }, () => this.runTest())}
            onClosed={() => this.props.onClosed()}
        />;
	}

	private renderAlreadySavedDialog(): ReactNode {
		return <AlreadySavedDialog onClosed={() => this.props.onClosed()}/>;
	}

	private onDoneHandler = (testSessionId, summaryText) => {
		if (summaryText !== null && summaryText.trim() !== '') {
			this.service.updateSummaryScore(testSessionId, summaryText);
		}
		this.props.onClosed();
	};

	private renderTestScreen(): ReactNode {
		return (
			<TestScreen
				testingService={this.service}
				afterTestEnded={() => {
					dispatchAppEvent(
						TestSavedEvent,
						new TestSavedEvent({
							studentID: this.props.student.id,
							testID: this.props.launcherData.testID,
							testSessionID: this.service.testSessionID,
						}),
					);
					this.setState({showResultsDialog: true});
				}}
				onCancel={() => this.props.onClosed()}
			/>
		);
	}

	private renderShowResultsDialog(): ReactNode {
		const resultModel = {
			studentID: this.props.student.id,
			testModel: this.service.testModel,
			studentName: this.service.studentName,
			answers: this.service.answers.value,
			testSessionID: this.service.testSessionID,
			summaryNotes: this.service.summaryNotes.value,
		} as ResultModel;

		return <SessionResults launcherData={this.props.launcherData}
							   result={resultModel}
							   studentClass={this.props.studentClass}
							   student={this.props.student}
							   onDoneClicked={this.onDoneHandler}
							   subject={this.props.subject}
							   onExportClicked={() => this.export()}
		 />;
	}
	private export(): void {
		const {id: subjectId, type: subjectType} = this.props.subject;
		this.setState({loading: true});
		const hideLoader = () => this.setState({loading: false});
		this.service.exportSession(subjectId, subjectType as unknown as any, this.props.studentClass.id).subscribe({
			next: hideLoader,
			error: hideLoader,
		});
	}
}
