import {LayerManager, LayerRegisterInfo} from '@esgillc/ui-kit/core';
import {observable, observableArray, rendered} from '../decorators';
import {deregisterModal, registerModal} from '@esgillc/ui-kit/modal';
import {
	ButtonTemplate,
	ModalCloserTemplate,
	ModalFooterTemplate,
	ModalHeaderTemplate,
	ModalTemplate,
} from './modal-template';
import './modal.less';
import './modal.gs-grade-range.less';
import {Renderable} from '../renderable';
import {Loader} from '@esgi/deprecated/jquery';

export interface BsTooltipOptions {
	trigger?: string,
	container?: string | JQuery | HTMLElement,
	title?: string,
	placement?: string,
	html?: boolean,
	delay?: any,
	viewport?: any,
}

export interface IButtonOption {
	title: string;
	closeModal: boolean;
	align?: string;
	style?: string;
	className?: string;
	onClick?: () => void;
	size?: string;
	disabled?: any;
	icon?: string;
	submit?: boolean;
	ok?: boolean;
	cancel?: boolean;
}

export interface IModalOptions {
	allowClose?: boolean;
	showHeader?: boolean;
	showFooter?: boolean;
	title?: string;
	footerTextStyle?: string;
	footerText?: string;
	buttons?: IButtonOption[];
	className?: string;
	horizontalAlignment?: string;
	verticalAlignment?: string;
	responsive?: boolean;
	fullscreen?: boolean;
	container?: string | HTMLElement | JQuery;
	animate?: boolean;
	header?: Renderable;
	footer?: Renderable;
	help?: IModalHelp | string;
	close?: IModalClose;
	disableFocus?: boolean;
	longLoadingText?: string;
	loadingAnimationDelay?: number;
}

export interface IModalHelp {
	url?: string;
	tooltip?: string;
	click?: () => any;
}

export interface IModalClose {
	tooltip?: string;
	click?: () => any;
	bootstrap?: BsTooltipOptions;
}

export interface IModalHeader {
	title: string;
	help?: IModalHelp | string;
	close?: IModalClose;
}

export class ModalOptions implements IModalOptions {
	allowClose = true;
	showHeader = true;
	showFooter = true;
	responsive = false;
	title = '';
	footerTextStyle = '';
	footerText = '';
	buttons = new Array<IButtonOption>();
	className = '';
	horizontalAlignment = 'center';
	verticalAlignment = 'middle';
	animate: boolean = true;
	disableFocus = false;
}

export class Modal extends Renderable implements ModalBase{
	private modalRegisterInfo: LayerRegisterInfo;

	@observable()
	innerControl: Renderable;
	settings: IModalOptions;
	renderPromise: JQueryDeferred<any>;

	constructor(innerControl: Renderable, options?: IModalOptions) {
    	super();
    	this.innerControl = innerControl;
    	this.settings = $.extend(true, new ModalOptions(), options);

    	if (this.settings.header) {
    		this.header = this.settings.header;
    	} else if (this.settings.showHeader) {
    		let close = this.settings.close;
    		if (!close) {
    			if (this.settings.allowClose) {
    				close = {};
    			}
    		}

    		let header = new ModalHeader({
    			close: close,
    			help: this.settings.help,
    			title: this.settings.title,
    		});

    		header.events.close(() => {
    			this.close();
    		});

    		this.header = header;
    	}

    	if (this.settings.footer) {
    		this.footer = this.settings.footer;
    	} else if (this.settings.showFooter) {
    		let footer = new ModalFooter(this.settings.buttons);
    		footer.footerText = this.settings.footerText;
    		footer.footerTextStyle = this.settings.footerTextStyle;
    		footer.events.closeRequested(() => this.close());

    		this.submitHandler = footer.submit.bind(footer);
    		this.cancelHandler = footer.cancel.bind(footer);

    		this.footer = footer;
    	}


    	this.className = ko.observable('modal modal-form ' + (this.settings.responsive ? 'responsive ' : '') + this.settings.className);
    	this.horizontalAlignment = ko.observable(this.settings.horizontalAlignment);
    	this.verticalAlignment = ko.observable(this.settings.verticalAlignment);

    	this.fullscreen = !!this.settings.fullscreen;
    	this.responsive = !!this.settings.responsive;
    	this.disableFocus = !!this.settings.disableFocus;
	}

	template = () => ModalTemplate.render();

	title: KnockoutObservable<string>;
	className: KnockoutObservable<string>;
	horizontalAlignment: KnockoutObservable<string>;
	verticalAlignment: KnockoutObservable<string>;


	zIndexValue(): number {
    	return this.zIndex();
	}

	submitHandler = () => {
    	return false;
	};
	cancelHandler = () => {
    	return false;
	};

	showHeader = ko.observable<boolean>();
	showFooter = ko.observable<boolean>();
	zIndex = ko.observable(1050);

	@observable()
	header: Renderable = null;
	footer: Renderable = null;

	@observable()
	fullscreen = false;
	responsive = false;
	disableFocus = false;

	preserveModal = false;
	loader: Loader = null;


	private closeAnimation(callback: any) {
    	if (this.settings.animate) {
    		$(this.rootElement).find('.modal-dialog').animate({top: -2000}, 250, callback);
    	} else {
    		callback();
    	}
	}

	close(force?: boolean) {
    	let deferred = $.Deferred<any>();


    	let closeAction = () => {
    		this.closeAnimation(() => {
    			$(document).off('keydown', this.openCloseHandler);
    			deregisterModal(this.modalRegisterInfo);

    			if (typeof this.innerControl.dispose === 'function') {
    				this.innerControl.dispose();
    			}

    			$(this.rootElement).remove();
    			if (LayerManager.hasRegisterModals) {
    				$('body').removeClass('no-scroll');
    				$(window).off('resize', () => this.ieResizeHandler());
    			}

    			deferred.resolve();
    		});
    	};

    	if (force) {
    		closeAction();
    	} else {
    		let args = {cancel: false};
    		$(this).trigger('onclose', args);

    		if (!args.cancel) {
    			closeAction();
    		}
    	}


    	return deferred.promise();
	}

	events = {
    	onClose: (callback: (event: Event, args: { cancel: boolean }) => void) => {
    		if (callback) {
    			$(this).on('onclose', callback as any);
    		}
    	},
    	controlRendered: (callback: () => void) => {
    		if (callback) {
    			$(this).on('controlRendered', callback);
    		}
    	},
    	onLoad: (callback: () => void) => {
    		if (callback) {
    			$(this).on('onload', callback);
    		}
    	},
	};

	render() {
    	return ko.render(this);
	}


	open() {
    	let deferred = $.Deferred();

		this.modalRegisterInfo = registerModal();
		this.zIndex(this.modalRegisterInfo.priority);

		if (LayerManager.hasRegisterModals && bowser.msie) {
			$(window).on('resize', () => this.ieResizeHandler());
		}

    	$(this.rootElement).appendTo(this.settings.container || 'body');
    	return deferred.resolve().promise();
	}

	private isWindowCurrent() {
    	return LayerManager.currentActive === this.modalRegisterInfo;
	}

	private openCloseHandler = (e: JQuery.Event) => {
    	let triggered;
    	if (!this.isWindowCurrent()) {
    		return true;
    	}

    	if (e.keyCode === 13) {
    		triggered = this.submitHandler();
    		if (triggered) {
    			$(document.activeElement).blur();
    			return false;
    		}
    	}

    	if (e.keyCode === 27) {
    		triggered = this.cancelHandler();
    		return true;
    	}

    	return true;
	};

	public static makeIeRedraw(delay: number = 100) {
    	let deferred = $.Deferred();

    	if (bowser.msie || bowser.msedge) {
    		setTimeout(() => {

    			$('.modal-body-inner').css('overflow', 'hidden');
    			$('.modal-header').css('overflow', 'hidden');

    			setTimeout(() => {
    				$('.modal-body-inner').css('overflow', '');
    				$('.modal-header').css('overflow', '');
    				deferred.resolve();
    			}, 200);
    		}, delay);
    	} else {
    		deferred.resolve();
    	}

    	return deferred.promise();
	}

	ieResizeHandlerTimeoutId = 0;

	public ieResizeHandler() {
    	clearTimeout(this.ieResizeHandlerTimeoutId);
    	this.ieResizeHandlerTimeoutId = window.setTimeout(() => {
    		Modal.makeIeRedraw();
    	}, 250);
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
    	let promise = super.afterRender(rootElement);
    	$('body').addClass('no-scroll');

    	$(document).on('keydown', rootElement, this.openCloseHandler);

    	setTimeout(() => {
    		$(':focus').blur();
    		if (!this.disableFocus) {
    			$('input:first', rootElement).focus();
    		}
    	}, 300);

    	Modal.makeIeRedraw();

    	return promise;
	}

	renderControl(innerControl: Renderable) {
    	this.loader.element = $(this.rootElement).find('.modal-content');
    	let timeoutId = setTimeout(() => {
    		if (this.loader) {
    			this.loader.mask();
    		}
    	}, 250);

    	this.renderPromise = $.Deferred();
    	this.innerControl = innerControl;
    	return this.renderPromise.promise().done(() => {

    		$(this).trigger('controlRendered');

    		clearTimeout(timeoutId);
    		if (this.loader) {
    			this.loader.unmask();
    		}

    		Modal.makeIeRedraw();

    	});
	}

	controlLoaded() {
    	this.renderPromise.resolve();

    	if (bowser.msie) {
    		this.rootElement.find('.modal-dialog').css('top', '-2000px').animate({top: 0, opacity: 1}, 150);
    	} else {
    		this.rootElement.find('.modal-dialog').css('opacity', 1);
    	}

    	Modal.makeIeRedraw();
	}

	controlLoadFailed() {
    	this.close(true);
	}

	load() {
    	let timeoutId = 0;

    	this.renderPromise = $.Deferred();
    	this.render().done(() => {
    		this.loader = new Loader(this.rootElement, {longLoadingText: this.settings.longLoadingText, delay: this.settings.loadingAnimationDelay});
    		if (this.renderPromise.state() !== 'resolved') {
    			timeoutId = window.setTimeout(() => {
    				this.loader.mask();
    			}, 250);
    		}
    		this.open();
    	});

    	return this.renderPromise.promise().done(() => {
    		clearTimeout(timeoutId);

    		if (this.loader) {
    			this.loader.unmask();
    		}

    		Modal.makeIeRedraw();

    		this.showHeader(this.settings.showHeader);
    		this.showFooter(this.settings.showFooter);

    		$(this).trigger('onload');
    	});
	}

}

export class ModalHeader extends Renderable {
	constructor(options: IModalHeader) {
		super();

		if (options.help) {
			if (typeof options.help === 'string') {
				this.help = {
					url: options.help,
				};
			} else {
				this.help = options.help;
			}
		}

		this.title = options.title;

		if (options.close) {
			this.close = new ModalCloser(options.close);
			this.close.events.clicked(() => {
				return $(this).trigger('close');
			});
		}
	}

	help: IModalHelp = null;
	close: ModalCloser = null;

	@observable()
	title = null;

	helpClicked() {
    	if (this.help) {
    		if (this.help.url) {
    			window.open(this.help.url, '_blank');
    		} else if (this.help.click) {
    			this.help.click();
    		}
    	}
	}

	template = () => {
    	return ModalHeaderTemplate.render();
	};

	afterRender(rootElement: JQuery): JQueryPromise<any> {
    	if (this.help && this.help.tooltip) {
    		$('.help', rootElement).bstooltip({
    			placement: 'bottom',
    			title: this.help.tooltip,
    			trigger: 'hover',
    			container: rootElement.parents('.modal-header'),
    		});
    	}
    	return super.afterRender(rootElement);
	}

	events = {
    	close: (callback) => {
    		$(this).on('close', callback);
    	},
	}
}

export class ModalCloser extends Renderable {
	constructor(private options: IModalClose) {
		super();
		this.tooltip = options.tooltip;
		if (this.options.click) {
			this.clicked = this.options.click;
		}
	}

	template = () => ModalCloserTemplate.render();

	tooltip: string;

	@rendered()
	private makeBootstrapTooltip(rootElement: JQuery) {
    	if (this.options.bootstrap) {
    		this.options.bootstrap.title = this.tooltip;

    		$(rootElement).bstooltip(this.options.bootstrap);
    	}
	}

	private clicked = (): any => {
    	return $(this).trigger('closeClicked');
	};

	events = {
    	clicked: (callback) => {
    		$(this).on('closeClicked', callback);
    	},
	}
}

export class ModalFooter extends Renderable {

	constructor(buttons: IButtonOption[]) {
		super();
		this.createButtons(buttons);
	}

	template = () => {
    	return ModalFooterTemplate.render();
	};

	public createButtons(buttons: IButtonOption[]) {
    	if (buttons && buttons.length > 0) {

    		let leftButtonArray = [];
    		let rightButtonArray = [];

    		for (let i = 0; i < buttons.length; i++) {
    			let optionButton = buttons[i];
    			let button = new Button();
    			button.title(optionButton.title);
    			button.className(optionButton.className);
    			button.style(optionButton.style);
    			button.events.click(optionButton.onClick);
    			button.icon(optionButton.icon);
    			button.iconplacement('left');

    			if (optionButton.closeModal) {
    				button.events.click(() => $(this).trigger('closeRequested'));
    			}

    			if (optionButton.disabled) {
    				if (typeof optionButton.disabled === 'function') {
    					button.disabled = optionButton.disabled;
    				} else {
    					button.disabled(optionButton.disabled);
    				}
    			}

    			if (optionButton.align === 'left') {
    				leftButtonArray.push(button);
    			} else {
    				rightButtonArray.push(button);
    			}

    			if (optionButton.submit) {
    				this.submitButton = button;
    				this.okButton = button;
    			}

    			if (optionButton.ok) {
    				this.okButton = button;
    			}

    			if (optionButton.cancel) {
    				this.cancelButton = button;
    			}
    		}

    		this.leftButtons = leftButtonArray;
    		this.rightButtons = rightButtonArray;
    	}
	}

	submit() {
    	let handler = this.okButton || this.submitButton;
    	if (handler) {
    		if (handler.rootElement) {
    			handler.rootElement.trigger('click');
    		} else {
    			$(handler).trigger('click');
    		}
    		return true;
    	}

    	return false;
	}

	cancel() {
    	let handler = this.cancelButton;
    	if (handler) {
    		$(handler).trigger('click');
    		return true;
    	}

    	return false;
	}


	@observableArray()
	leftButtons: Button[] = [];

	@observableArray()
	rightButtons: Button[] = [];

	@observable()
	footerText: string;

	@observable()
	footerTextStyle: string;

	submitButton: Button;
	cancelButton: Button;
	okButton: Button;

	events = {
    	closeRequested: (callback) => {
    		$(this).on('closeRequested', callback);
    	},
	}
}

export abstract class ModalBase {
	public static readonly zIndexDefault = LayerManager.defaultPriority;
	abstract zIndexValue: () => number;
	public static GetMaxZIndex(){
    	return LayerManager.currentMaxPriority;
	}
}

export class ModalWindow extends Modal {

}

export class CustomModal extends Modal {
	constructor(innerControl: Renderable, className?: string) {
		super(innerControl,
			{
				allowClose: false,
				buttons: [],
				showFooter: false,
				showHeader: false,
				className: className,
			});
	}
}

export class Button extends Renderable {

	style = ko.observable<string>();
	title = ko.observable<string>('Ok');
	className = ko.observable<string>('btn');
	private cssClass = ko.computed<string>(() => this.className());
	disabled = ko.observable(false);
	icon = ko.observable('');
	iconplacement = ko.observable('left');
	visible = ko.observable<boolean>(true);

	@observable()
	type = 'text';

	template = () => {
		return ButtonTemplate.render();
	};

	onClick = (a, b, c): any => {
		return $(this).triggerHandler('click', [a, b, c]);
	};

	events = {
		click: (callback) => {
			$(this).on('click', callback);
		},
	};
}

