import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Radio} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import SelectorBox from '../../../shared/components/selector-box/selector-box';
import SelectorBoxHeader from '../../../shared/components/selector-box/header';
import SelectorBoxBody from '../../../shared/components/selector-box/body';
import styles from './group-selector.module.less';
import {GroupResponse} from '../types';
import DataService from '../data-service';

interface Props {
	className?: string;
	groups: GroupResponse[];
	control: IFormControl;
	dataService: DataService;
}

class State {
	columns: GroupResponse[][] = [];
}

export default class GroupSelector extends React.Component<Props, State> {
	public override readonly state = new State();

	componentDidMount() {
		this.props.dataService.groupsColumns$.subscribe(columns => this.setState({columns}))
	}

	public render() {
		const isDataLoaded = this.props.groups != null;
		const hasData = this.props.control && this.props.groups?.length > 0

		return <div className={this.props.className}>
			<SelectorBox>
				<SelectorBoxHeader>
					Groups
				</SelectorBoxHeader>
				<SelectorBoxBody className={join(styles.body, !hasData && styles.emptyBody)}>
					{isDataLoaded ? !hasData && <div className={styles.emptyBodyContent}>No groups have been created</div> : <span/>}
					{hasData &&
						<FormElement control={this.props.control} className={styles.formGroup}>
							<div className={join('row', styles.listColumns)}>
								{this.renderColumn(this.state.columns[0], styles.column1)}
								{this.renderColumn(this.state.columns[1], styles.column2)}
							</div>
						</FormElement>
					}
				</SelectorBoxBody>
			</SelectorBox>
		</div>;
	}

	private renderColumn(groups: GroupResponse[], className: string) {
		return <div className={join('col-xs-12 col-sm-6', className)}>
			{groups.map(x => <Radio key={x.id} value={x.id}>{x.name}</Radio>)}
		</div>;
	}
}
