import {AssignmentState} from '@esgi/main/kits/assignments';
import {TableTestsHeader, TableTests, TableTestsRowData} from '../table-tests';
import {EmptyContent} from '../empty-content';
import {noTestsMessage} from './constants';
import {CommonAssignmentProps} from './types';
import {AssignmentHeader} from './components/assignment-header';
import {AssignmentBody} from './components/assignment-body';
import {useCallback} from 'react';

export function AssignmentCompleted({
	assignment,
	students,
	sortStudentsByKey,
	onDeleteAssignment,
	studentsWithoutAccess,
	onOpenExportCredentialsAlert,
	onOpenStudentCredentials,
	onOpenCredentialsForStudentsInAssignment,
	disabledOpenExportStudentsCredentials,
}: CommonAssignmentProps) {
	const testsCount = assignment.tests.length;

	const getTableTests = useCallback(() => {
		if (!testsCount) {
			return <EmptyContent messageText={noTestsMessage} />;
		}

		return (
			<TableTests>
				<TableTestsHeader studentsCount={students.length} />

				{assignment.tests.map((test) => (
					<TableTestsRowData {...test} studentsCount={students.length} key={test.id} />
				))}
			</TableTests>
		);
	}, [assignment, students, testsCount]);

	return (
		<>
			<AssignmentHeader
				studentsWithoutAccessCount={studentsWithoutAccess.length}
				hasTestsInAssignment={Boolean(testsCount)}
				onDeleteAssignment={() => onDeleteAssignment({assignmentId: assignment.id, assignmentName: assignment.name})}
				disableEditDashboardButton
				onOpenExportCredentialsAlert={onOpenExportCredentialsAlert}
				disabledOpenExportStudentsCredentials={disabledOpenExportStudentsCredentials}
			/>
			<AssignmentBody
				name={assignment.name}
				state={AssignmentState.Completed}
				description={assignment.description}
				testsCount={testsCount}
				displayedTime={assignment.completed}
				displayedTimeType='Completed'
				sortStudentsByKey={sortStudentsByKey}
				students={students}
				onOpenStudentCredentials={onOpenStudentCredentials}
				onOpenCredentialsForStudentsInAssignment={onOpenCredentialsForStudentsInAssignment}
			>
				{getTableTests()}
			</AssignmentBody>
		</>
	);
}
