import {FolderResponse} from './api';

export class BaseItem {
	id: number;
	name: string;
	canDelete: boolean;
}

export class FolderItem extends BaseItem {
	subItemsCount: number;
}

export class ImageItem extends BaseItem {
	normalUrl: string;
	thumbUrl: string;
	width: number;
	height: number;
}

export class BreadcrumbModel {
	name: string;
	id: number;
	library: Library;
	searchKey?: string | number;
	searchType?: SearchType

	static from(m: BreadcrumbModelResponse, library: Library): BreadcrumbModel {
		return {
			id: m.id,
			name: m.name,
			library: library,
		};
	}
}

export enum SearchType {
	ByTag,
	ByKeyword
}

export interface BreadcrumbModelResponse {
	name: string;
	id: number;
}

export interface UploadImagesResponse {
  errorType: 'SizeLimit' | 'InvalidFormat' | 'EncodingIncorrect' | null;
}


export class FolderModel {
	id: number;
	parentID: number;
	name: string;

	static from(m: FolderResponse): FolderModel {
		return {
			id: m.id,
			name: m.name,
			parentID: m.parentID,
		};
	}
}

export enum ItemType {
	Folder,
	Image
}

export module Events {
	export function ItemsDeleted(ids: number[]): ContentChanged.Args {
		return {
			ids: ids,
		};
	}

	export function ItemsMoved(ids: number[]): ContentChanged.Args {
		return {
			ids: ids,
		};
	}

	export function ItemPropertyChanged(id: number): ContentChanged.Args {
		return {
			ids: [id],
		};
	}

	export function ItemCreated(): ContentChanged.Args {
		return {ids: []};
	}

	export module ContentChanged {
		export interface Args {
			ids: number[];
		}
	}
}

export enum Page {
	Main,
	Home
}

export enum Library {
	Search = 0,
	Stock = 1,
	Private = 2
}
