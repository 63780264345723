import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {Page} from '../../layout/index.styled';

export const ContentBox = styled(GridBox, {
	gap: '12px',
	gridTemplateRows: 'auto auto auto',
});

export const EmptyBox = styled(Box, {
	margin: '20px auto',
	textAlign: 'center',
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $border',
	background: '$surface',
});

export const Panel = styled(Box, {
	overflow: 'hidden',
	height: '100%',
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',

	'> div': {
		width: '100%',
	},
});

export const PageUntested = styled(Page, {
	gridTemplateColumns: '275px auto 1fr',
});
