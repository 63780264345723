import {useMemo} from 'react';
import {TestSession} from '../../../../../../../../types';

export function useSelectedSessions({
	testSessions,
	selectedSessionIds,
}: {
	testSessions: TestSession[];
	selectedSessionIds: TestSession['id'][];
}) {
	return useMemo(
		() =>
			selectedSessionIds.reduce<TestSession[]>((prevValue, sessionId) => {
				const sessionItem = testSessions.find(({id}) => sessionId === id);

				return sessionItem ? [...prevValue, sessionItem] : prevValue;
			}, []),
		[testSessions, selectedSessionIds],
	);
}
