import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {Checkbox} from '@esgi/ui/controls';

export const Wrapper = styled(Box, {
	padding: '8px',
	border: '1px solid $border',
	backgroundColor: '$vivid',
	borderRadius: '8px',
	marginTop: '8px',
});

export const CheckboxGroup = styled(GridBox, {
	gap: '$1',

	'& svg': {
		width: '24px',
		height: '24px',
	},

	variants: {
		column: {
			true: {
				gridAutoFlow: 'column',
				alignItems: 'center',
			},
		},
	},
});

export const CheckboxGroupItem = styled(Checkbox, {
	justifyContent: 'flex-start',

	'& > span': {
		display: 'inline-flex',
		color: '$highContrast',
	},
});
