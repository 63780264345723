import styles from './styles.module.less';
import {Radio} from '@esgillc/ui-kit/control';
import {ReportFiltersLabelContainer, ReportFiltersContent} from '../report-filter';
import {ReportFiltersLabel, ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters';

type MarkingPeriodFilterProps = {
	onChanged: (showCurrentMarkingPeriod: boolean) => void;
	showCurrentMarkingPeriod: boolean;
};

export function MarkingPeriodFilter({showCurrentMarkingPeriod, onChanged}: MarkingPeriodFilterProps) {
	return (
		<ReportFiltersContainer dataCy='rubric-report-marking-period-filter' className={styles.container}>
			<ReportFiltersLabelContainer>
				<ReportFiltersLabel>Marking Period:</ReportFiltersLabel>
			</ReportFiltersLabelContainer>
			<ReportFiltersContent className={styles.radioRow}>
				<Radio
					className={styles.radioLabel}
					value='Current'
					checked={!!showCurrentMarkingPeriod}
					onChange={() => onChanged(true)}
				>
					Current
				</Radio>
				<Radio
					className={styles.radioLabel}
					value='All'
					checked={!showCurrentMarkingPeriod}
					onChange={() => onChanged(false)}
				>
					All
				</Radio>
			</ReportFiltersContent>
		</ReportFiltersContainer>
	);
}
