export enum Step {
	None = 0,
	AddStudent = 1,
	UploadRoster = 2,
	ContinueFileUpload = 3,
	ConflictResolution = 4,
	SchoolSelection = 5,
}

export enum Notification {
	None = 0,
	IncorrectFileType = 1,
	MissingRequiredInformation = 2,
	MaximumStudents = 3,
	PurchasingStudents = 4,
	ConflictExists = 5,
	FileUploadIsComplete = 6,
	DeleteFileUpload = 7,
	SaveDuplicateName = 8,
	InvalidField = 9,
	DuplicateIDInFile = 10,
	UnsupportedFieldName = 11
}

export enum ConflictType {
	None = 0,
	DuplicateStudentName = 1,
	DuplicateStudentIDN = 2,
	UnknownLanguage = 3,
}

export enum ConflictLevel {
	Warning = 0,
	RequireChange = 1,
	Notification = 2,
}

export enum ValidationType {
	None = 0,
	Standard = 1,
	Conflict = 2,
	Full = 3,
}

export class Validation {
	type: ValidationType;
	message: string;
	conflictType: ConflictType;

	constructor(
		type: ValidationType = ValidationType.None,
		conflictType: ConflictType = ConflictType.None,
		message: string = '',
	) {
		this.type = type;
		this.message = message;
		this.conflictType = conflictType;
	}
}

export enum ValidationStatus {
	None = 0,
	IncorrectFileType = 1,
	MissingRequiredInformation = 2,
	InvalidField = 3,
	MaximumStudents = 4,
	PurchasingStudents = 5,
	ConflictExists = 6,
	Complete = 7,
	DuplicateIDInFile = 8,
	CompleteWithStudentsExistInClass = 9,
	UnsupportedFieldName = 10,
}

export class ConflictStudentField {
	value: string;
	validation: Validation;
	editable: boolean;

	constructor(value: string, validation: Validation = new Validation(), editable = false) {
		this.value = value;
		this.validation = validation;
		this.editable = editable;
	}
}

export class SaveConflictStudentModel {
	id: number;
	firstName: string;
	lastName: string;
	studentIDN: string;
	gradeLevel: string;
	language: string;
	createDate: string;
	gender: string;
}

export class ConflictStudentModel {
	constructor(
		public id: number,
		public schoolID: number,
		public teacherID: number,
		public gradeLevel: string,
		public createDate: string,
		public gender: string,
		public removed: boolean,
		public existingStudents: ConflictExistingStudentModel[] = [],
		public studentIDN: ConflictStudentField,
		public name: ConflictStudentField,
		public language: ConflictStudentField,
	) {
	}
}

export class ConflictExistingStudentModel {
	id: number;
	teacherID: number;
	schoolID: number;
	name: string;
	studentIDN: string;
	gradeLevel: string;
	language: string;
	createDate: string;
	gender: string;
}

export interface ServerExistingStudentField {
	value: string;
	validationMessage: string;
	validationType: string;
	conflictType: string;
}

export interface ServerConflictStudentModel {
	id: number;
	schoolID: number;
	teacherID: number;
	name: ServerExistingStudentField;
	studentIDN: ServerExistingStudentField;
	gradeLevel: string;
	language: ServerExistingStudentField;
	createDate: string;
	gender: string;
	existingStudents: ConflictExistingStudentModel[];
}

export interface ValidStudentModel {
	id: number;
	firstName: string;
	lastName: string;
	gradeLevelID: number;
	studentIDN: string;
}

export interface UploadRosterResponse {
	className?: string;
	specialistGroupName?: string;
	uploadedStudentsCount: number;
	conflictStudents: ServerConflictStudentModel[];
	createdStudents: ValidStudentModel[];
	validation: ServerValidation;
}

export interface SaveConflictStudentsResponse {
	createdStudents: ValidStudentModel[];
	validation: ServerValidation;
}

export interface CheckStudentIDNsResponse {
	studentsIDNs: string[];
}

export interface ServerValidation {
	status: string;
	data: string;
}
