import React from 'react';

export function ArrowIcon(props: {open: boolean}) {
	if (props.open) {
		return <svg xmlns='http://www.w3.org/2000/svg' width='12' height='8'
		            viewBox='0 0 12 8'
		            fill='none'>
			<path
				d='M10.59 7.41L6 2.83L1.41 7.41L0 6L6 0L12 6L10.59 7.41Z'
				fill='#0088CC'/>
		</svg>;
	} else {
		return <svg xmlns='http://www.w3.org/2000/svg' width='12' height='8'
		            viewBox='0 0 12 8'
		            fill='none'>
			<path
				d='M10.59 0.589996L6 5.17L1.41 0.589996L0 2L6 8L12 2L10.59 0.589996Z'
				fill='#0088CC'/>
		</svg>;
	}
}
