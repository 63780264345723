export const languagesMock = [
	{
		id: 0,
		name: 'Other',
	},
	{
		id: 1,
		name: 'English',
	},
	{
		id: 2,
		name: 'Spanish',
	},
	{
		id: 3,
		name: 'Mandarin',
	},
	{
		id: 4,
		name: 'Japanese',
	},
	{
		id: 5,
		name: 'Hindi',
	},
];

export const letterTypesMock = [
	{
		id: 2,
		name: 'Parent Conferencer - Invitation',
	},
	{
		id: 3,
		name: 'Reminder Letter',
	},
];
export const defaultBodyText = 'The parent/teacher conference is recognized by parents and teachers to be a highly effective means of improving the education of children.  I hope you will make every effort Student_Last_Name to attend this Student_Last_Names conference.Student_ID'
export const languagesAndTemplatesMock = [
	{
		id: 0,
		name: 'Other',
		template: {
			name: 'template 1',
			letterTemplateID: 1035309,
			body: defaultBodyText,
			isDefault: false,
		},
	},
	{
		id: 1,
		name: 'English',
		template: {
			name: 'template 2',
			letterTemplateID: 1035309,
			body: defaultBodyText,
			isDefault: false,
		},
	},
	{
		id: 2,
		name: 'Spanish',
		template: {
			name: 'template 3',
			letterTemplateID: 1035309,
			body: defaultBodyText,
			isDefault: false,
		},
	},
	{
		id: 3,
		name: 'Mandarin',
		template: {
			name: 'template 4',
			letterTemplateID: 1035309,
			body: defaultBodyText,
			isDefault: false,
		},
	},
	{
		id: 4,
		name: 'Japanese',
		template: {
			name: 'template 5',
			letterTemplateID: 1035309,
			body: defaultBodyText,
			isDefault: false,
		},
	},
	{
		id: 5,
		name: 'Hindi',
		template: {
			name: 'template 6',
			letterTemplateID: 1035309,
			body: defaultBodyText,
			isDefault: false,
		},
	},
];
