import {LayerManager, LayerRegisterInfo} from '@esgillc/ui-kit/core';
import React, {createRef, PureComponent, RefObject} from 'react';
import ReactDOM from 'react-dom';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {userStorage} from '@esgi/core/authentication';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {Pin} from '@esgi/deprecated/ui-kit/pin';

import styles from './pin-popup.module.less';

class State {
	pin: string[] = [];
	showWarn: boolean = false;
}

interface Props {
	onCancel: () => void;
	onSuccess: () => void;
}

export class PinPopup extends PureComponent<Props, State> {
	public readonly state = new State();
	private readonly popupRef: RefObject<HTMLDivElement> = createRef();
	private readonly onDestroy$: Subject<void> = new Subject();
	private readonly pinContainerRef: RefObject<HTMLDivElement> = createRef();

	private registerInfo: LayerRegisterInfo;

	public componentDidMount() {
		fromEvent(document.body, 'click').pipe(takeUntil(this.onDestroy$)).subscribe(e => {
			if (!(this.popupRef.current.contains(e.target as any) || e.target === this.popupRef.current)) {
				this.props.onCancel();
			}
		});
		this.registerInfo = LayerManager.register({active: true});
	}

	public render() {
		return ReactDOM.createPortal(this.renderPopup(), document.body);
	}

	public renderPopup() {
		return <div className={styles.popup} ref={this.popupRef}>
			<div className={styles.title}>
				Enter PIN Number
			</div>
			<div className={styles.subTitle}>
				to exit the student screen.
			</div>
			<div ref={this.pinContainerRef}>
				<Pin onChange={(v) => this.setState({pin: [...v]}, () => this.checkWarn())}
				     autofocus
				     tooltipPlacement='left'
				     onUserCompleteEnter={(pin) => this.validateAndClose(pin, true)}/>
			</div>
			{this.state.showWarn &&
				<ValidationTooltip element={null} show={1} notHide elementRef={this.pinContainerRef} placement='left'>
					PIN does not match. Please try again.
				</ValidationTooltip>}
			<div className={styles.footer}>
				<LinkButton onClick={() => this.props.onCancel()}>
					CANCEL
				</LinkButton>
				<LinkButton onClick={() => this.okClicked()}>
					OK
				</LinkButton>
			</div>
		</div>;
	}

	public componentWillUnmount() {
		if(this.registerInfo) {
			LayerManager.deregister(this.registerInfo);
		}
		this.onDestroy$.next();
	}

	private okClicked(): void {
		this.validateAndClose(this.state.pin.join(''));
	}

	private checkWarn(): void {
		if (this.state.showWarn) {
			this.setState({showWarn: validatePin(this.state.pin)});
		}
	}

	private validateAndClose(input: string, silence: boolean = false): void {
		const pin = userStorage.get().screenLockPIN;
		if (input !== pin) {
			if (!silence) {
				this.setState({showWarn: true});
			}
		} else {
			this.props.onSuccess();
		}
	}
}

function validatePin(pin: string[]): boolean {
	if (pin.length === 4) {
		for (let char of pin) {
			if (!/^\d+$/.test(char)) {
				return false;
			}
		}

		return true;
	}
	return false;
}
