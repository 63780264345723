import {Observable} from 'rxjs';

const maxFileSize = 5120000;
export const fileTypes = ['.jpg', '.jpeg', '.png', '.bmp', '.gif', '.tif', '.tiff'];

export const convertImageToBase64 = (newFile: FileList): Observable<string> => {
	return new Observable(function(subscriber) {
		const file = newFile[0];
		const type = `.${file.type.split('/')[1]}`;
		if (file.size > maxFileSize) {
			subscriber.error('File size is too big.');
			subscriber.complete();
			return;
		}
		if (!fileTypes.includes(type)) {
			subscriber.error('Unsupported file type.');
			subscriber.complete();
			return;
		}

		const reader = new FileReader();

		reader.onload = () => {
			const readerResult = reader.result as string;
			const img = new Image;

			img.onload = () => {
				const base64Result = (readerResult).split(',')[1];
				const base64URL = `data:${file.type};base64,${base64Result}`;
				subscriber.next(base64URL);
				subscriber.complete();
			};
			img.src = readerResult;
		};
		reader.readAsDataURL(file);
	});
};




