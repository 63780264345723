import {useState} from 'react';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {UntestedStudentsReportService} from '../service';
import styles from './modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService;
	closed: () => void;
}

export function ModalHearder({service, closed}: Props) {
	const [showMenu, setShowMenu] = useState(false);
	const emptyReport = useBehaviorSubject(service.emptyReport);
	const toggle = () => setShowMenu(!showMenu);

	return <div className={styles.reportHeader}>
		<div className={styles.reportName}>Untested Students</div>
		<div className={styles.printDownloadButtons}>
			{!emptyReport &&
				<div id='downloadDropdown' className={join(
					styles.downloadDropdown,
					'btn-group',
				)}>
					<span onClick={toggle}>
							<i className={join(
								'fa fa-download',
								styles.fa,
							)}/> Download
                        </span>
					<Menu show={showMenu} keepFocusInside onClickOutside={toggle} onEscPressed={toggle}
					      className={styles.menuContainer}>
						<MenuItem onSelect={() => service.downloadFile('pdf')}>PDF</MenuItem>
						<MenuItem onSelect={() => service.downloadFile('xlsx')}>Excel</MenuItem>
					</Menu>
				</div>
			}
			<CloseIcon onClick={closed} color={'#FFFFFF'}/>
		</div>
	</div>
		;
}
