import {YesNoContent} from './components/yes-no-content';
import {RubricContent} from './components/rubric-content';
import {TestType} from '@esgi/main/libs/core';
import {TestSession, TestInfo} from '../../../../../types';
import {useMemo} from 'react';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	isTablet: boolean;
};

export function StudentEntity({testSessions, testInfo, isTablet}: Props) {
	const sortedTestSessions = useMemo(
		() => [...testSessions].sort((a, b) => new Date(b.testDate).getTime() - new Date(a.testDate).getTime()),
		[testSessions],
	);

	if (testInfo.testType === TestType.YN) {
		return <YesNoContent testSessions={sortedTestSessions} testInfo={testInfo} isTablet={isTablet} />;
	}

	if (testInfo.testType === TestType.Rubric) {
		return <RubricContent testSessions={sortedTestSessions} testInfo={testInfo} isTablet={isTablet} />;
	}

	return null;
}
