import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import React from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import BaseProps from './base-props';

class Props extends BaseProps {
	specialistTitle: string;
}

export default class SpecialistUnassignDialog extends React.PureComponent<Props> {
	public render() {
		let text = 'Are you sure you want to unassign the selected student(s) from their ' + this.props.specialistTitle + ' Group(s)?';
		return <Modal className='student_operation_dialog remove_student_dialog'>
			<ModalHeader title='Unassign student(s) from'>
			</ModalHeader>
			<ModalBody>
				{text}
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => this.props.closeClicked()}
						title='Cancel'
					/>
					<Primary
						onClick={() => this.props.processClicked()}
						title='Confirm'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
