import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const RequestAQuoteForm = lazyComponent(() => import('pages/landing/kit/iframed-form/index'));

export default function RequestAQuoteIframedForm() {
	return (
		<RouteWrapper title='Request a Quote Form'>
			<Suspense fallback={<DefaultRouteFallback />}>
				<RequestAQuoteForm
					pageContext={{
						formUrl:
							'https://forms.office.com/Pages/ResponsePage.aspx?id=nusOTCzlh0uyb73dsHS5_5Y6wGhzO1ZCiAcyGtSCr5xUNDNSNFZUVUNCVkNTUkVRRU0wWFMzRTVEQS4u',
					}}
				/>
			</Suspense>
		</RouteWrapper>
	);
}
