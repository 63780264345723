import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Label = styled(Text, {
	textAlign: 'center',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	display: 'grid',
	width: '100%',
	padding: '11px',
	gap: '8px',
	justifyItems: 'center',
});

export const IconContainer = styled(FlexBox, {
	width: '24px',
	height: '24px',
	alignItems: 'center',
	justifyContent: 'center',
	'& svg': {
		'& path': {
			fill: '$neutral56',
		},
	},
});
