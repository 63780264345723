import {Moment} from 'moment/moment';
import {ValidDate} from 'modules/subject-details/types';
import {Validators} from '@esgi/deprecated/elements';

export const validate = (v: Moment, field: string, valid: ValidDate, setValid: (valid: ValidDate) => void) => {
	const validRes = Validators.requriedValidator(v.isValid() ? v.format() : '', field, 'Please select a date.');
	setTimeout(() => {
		if (field === 'to' && valid.toValid !== validRes.valid) {
			setValid({...valid, toValid: validRes.valid});
		}
		if (field === 'from' && valid.fromValid !== validRes.valid) {
			setValid({...valid, fromValid: validRes.valid});
		}
	});
	return validRes;
};
