import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {useModal} from '@esgillc/ui-kit/modal';

interface Props {
	onClose: () => void,
	isOpen: boolean,
}

export function InformationAlert({onClose, isOpen}: Props) {
	const modalRef = useModal();
	const closeAlert = () => {
		modalRef.current.close(() => {
			onClose();
		});
	};

	if (!isOpen) {
		return null;
	}

	return <Alert modalManagerRef={modalRef}>
		<Alert.Body>
			Sorry. You must have at least one class in each school year.
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={closeAlert}>
				OK
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
