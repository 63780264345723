import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Container = styled(GridBox, {
	gap: '$4',
	paddingBottom: 16,
});

export const Row = styled(GridBox, {
	gridAutoFlow: 'column',
	gridTemplateColumns: 'auto max-content',
	gap: '$4',
});

export const BorderedText = styled(Text, {
	paddingBottom: '$1',
	borderBottom: '1px solid $border',
});

