import {HttpClient} from '@esgi/api';

export class Api {
	public init(cleverGuid: string, registrationType: string) {
		return HttpClient.default.SSO.get<{ exist: boolean, redirectUrl: string, registrationAllow: boolean, isAlreadyHaveAccount: boolean }>('Integrations/Clever', 'Init', {
			cleverGuid: cleverGuid,
			registrationType: registrationType,
		});
	}

	public link(cleverGuid: string) {
		const data = new FormData();
		data.set('cleverGuid', cleverGuid);
		return HttpClient.default.SSO.post('Integrations/Clever', 'Link', data);
	}

	public register(cleverGuid: string, registrationType: string, promoCode: string) {
		return HttpClient.default.SSO.post<{ token: string }>('Registration/Create', 'Clever', {
			cleverGuid: cleverGuid,
			registrationType: registrationType,
			promoCode: promoCode,
		});
	}
}