import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const Panel = styled(Box, {
	padding: '0 20px',
});

export const Title = styled(Box, {
	padding: '20px 0 12px',
	margin: '0 12px 12px',
	borderBottom: '1px solid $mild',
	textAlign: 'center',
});
