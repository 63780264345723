import * as React from 'react';
import type {SVGProps} from 'react';

export function Requiered(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 16 16'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='m8.013 9.72-1.36 2.32a3.412 3.412 0 0 1-.493.667c-.187.195-.431.293-.733.293-.303 0-.57-.107-.8-.32a1.043 1.043 0 0 1-.347-.8c0-.249.058-.467.173-.653.116-.187.245-.36.387-.52L6.76 8.76l-2.453-.427c-.41-.07-.73-.195-.96-.373C3.116 7.782 3 7.516 3 7.16c0-.32.098-.596.293-.827.196-.23.471-.346.827-.346.16 0 .316.022.467.066.15.045.289.094.413.147l2.213 1.093-.346-2.613a1.632 1.632 0 0 1-.027-.253V4.2c0-.356.102-.644.307-.867C7.35 3.111 7.64 3 8.013 3c.356 0 .636.111.84.333.205.223.307.511.307.867v.227c0 .062-.009.146-.027.253l-.346 2.613 2.186-1.066a2.87 2.87 0 0 1 .427-.174c.142-.044.284-.066.427-.066.355 0 .64.106.853.32.213.213.32.497.32.853s-.12.622-.36.8c-.24.178-.547.302-.92.373l-2.48.427 1.92 1.947c.16.16.298.333.413.52.116.186.174.404.174.653 0 .338-.111.609-.334.813a1.123 1.123 0 0 1-.786.307c-.303 0-.556-.098-.76-.293a2.927 2.927 0 0 1-.52-.667L8.013 9.72Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
