import {PropsWithChildren} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './styles.module.less';

interface Props extends PropsWithChildren {
	title: string;
	className?: string;
	tooltipText?: string;
	required?: boolean;
	'data-cy'?: string;
}

export default function Section({
	title,
	className,
	tooltipText,
	required,
	children,
	...props
}: Props) {

	return <div data-cy={props['data-cy']} className={join(className, styles.section)}>
		<div className={styles.labelSection} data-cy={'section-header'}>
			{required && <span data-cy={'required-star'} className={styles.requiredStar}>*</span>}
			<label data-cy={'section-title'} className={styles.title}>{title}</label>
			{tooltipText && <div data-cy={'tooltip-text'}> <OnHoverTooltip message={tooltipText}>
				<QuestionMarkIcon
					className={styles.questionMarkIcon}
					fill={'#08c'}
					width={'12'}
					height={'12'}
				/>
			</OnHoverTooltip> </div>}
		</div>
		<div data-cy={'section-body'} className={styles.fieldSection}>
			{children}
		</div>
	</div>;
}

