import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Label, FieldError, TextArea, Hint} from '@esgillc/ui-kit/form-control';
import {ErrorText} from 'modules/forms/students-form/components/error-text';
import styles from '../styles.module.less';

const MAX_LENGTH = 300;

type Props = {
	control: IFormControl;
}

export const IEPGoalField = ({control}: Props) => (
	<FormElement
		className={styles.formElement}
		control={control}
	>
		<Label className={styles.label}>
			<span className={styles.required}>*</span>IEP Goal
		</Label>
		<div className={styles.field}>
			<TextArea
				rows={4}
				maxLength={MAX_LENGTH}
				autoResizeToFit
				placeholder='Enter goal for this test'
			/>
			<Hint className={styles.limitCounter}>
				{(props) => `${MAX_LENGTH - (props.value?.length | 0)} characters left (${MAX_LENGTH} max)`}
			</Hint>
			<ErrorText showOnError='required'>
				Required
			</ErrorText>
		</div>
	</FormElement>
);
