import {Button} from '@esgi/ui';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AlertBody, CustomLink} from './index.styled';

interface Props {
	onClose: VoidFunction;
	limitStudents: number;
}

export function LimitAlert(props: Props) {
	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);

	return (
		<Alert modalManagerRef={modal} colorConfig={alertColorsConfig.negative}>
			<Alert.Header withCloseIcon={false}>
				<Text>You Reached the Maximum Number of Students</Text>
			</Alert.Header>
			<AlertBody>
				<Text size='large'>
					You have reached a maximum number of {props.limitStudents} students in
					your classes. Please remove students or increase the maximum number by
					purchasing additional student slots. Please contact us with any
					questions.
				</Text>
				<Text size='large'>
					Links for purchasing additional slots:
					<CustomLink to='/renewal/'>Renewal</CustomLink>
				</Text>
				<Text size='large'>
					contact us:
					<CustomLink
						to='https://support.esgisoftware.com/hc/en-us/requests/new'
						target='_blank'
					>
						Support
					</CustomLink>
				</Text>
			</AlertBody>
			<Alert.Footer>
				<Button onClick={close}>
					<Text size='large'>Close</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
