import {InputStyles} from '@esgillc/ui-kit/form-control';
import {useRef, useState} from 'react';
import {Observable} from 'rxjs';
import {Buttons} from '@esgillc/ui-kit/button';
import {Input, TextArea} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {join, useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {ScoreService} from '../../../service';
import {finalize} from 'rxjs/operators';
import {CloseButton} from '../../../../../kit/buttons/close-button';
import {QuestionModel} from '../../../../../kit/models';
import {QuestionSlider} from '../../../../../kit/slider';
import {QuestionFooter, QuestionHeader, QuestionLayout, QuestionTitle} from '../../../../../kit/question-layout';
import {IncorrectScoreAlert} from './components/incorrect-score-alert';
import {LostConnectionAlert} from '../../../../../kit/lost-connection-alert';
import styles from './styles.module.less';

type Props = {
	service: ScoreService;
	whiteBackground: boolean;
	question: QuestionModel;
	onClose: () => void;
	onSave: (score: number, comment: string, duration: number) => Observable<any>;
}

export function ScoreSlider({service, question, ...props}: Props) {
	const testingModel = useBehaviorSubject(service.testingModel);
	const {studentName, testName} = testingModel;
	const {direction} = question;

	const [score, setScore] = useState<number>();
	const [comment, setComment] = useState(question.comment || '');

	const [valid, setValid] = useState(false);
	const [showValidations, setShowValidations] = useState(false);
	const [showScoreAlert, setShowScoreAlert] = useState(false);
	const [showConnectionLostAlert, setShowConnectionLostAlert] = useState(false);

	const [startTime] = useState(() => Date.now());

	const [saving, setSaving] = useState(false);

	const validate = (value) => {
		if (value !== undefined) {
			return value >= 0 && value <= testingModel.totalPossible;
		}
		return false;
	};

	const updateScore = (value: number) => {
		if (value >= 0) {
			setScore(+value);
		}
		const valid = validate(value);
		setValid(valid);
		setShowValidations(!valid);
	};

	const save = () => {
		if (valid) {
			setSaving(true);
			props.onSave(score, comment, Date.now() - startTime)
				.pipe(finalize(() => setSaving(false)))
				.subscribe();
		} else if (score > testingModel.totalPossible) {
			setShowScoreAlert(true);
		}
	};

	const inputRef = useRef<HTMLInputElement>();

	useStreamEffect(service.onLostConnection, () => setShowConnectionLostAlert(true));

	return <>
		<QuestionLayout whiteBackground={props.whiteBackground}>
			<QuestionHeader>
				<QuestionTitle>
					<div className={styles.studentName}>
						{studentName}
					</div>
					<div className={join(styles.testName, props.whiteBackground && styles.isWhiteBackground)}>
						{testName}
					</div>
				</QuestionTitle>
				<CloseButton isWhiteBackground={props.whiteBackground} onClick={props.onClose}/>
			</QuestionHeader>
			<QuestionSlider questions={[question]} currentIndex={0}/>
			<QuestionFooter className={styles.footer}>
				<div>
					{direction && <div className={styles.direction}>
						Directions: <span className={join(styles.directionText, props.whiteBackground && styles.isWhiteBackground)}>
						{direction}
					</span>
					</div>}
					<div className={styles.btnHolder}>
						<div className={styles.left}/>
						<div className={styles.center}>
							<div className={join(styles.valueHolder, props.whiteBackground && styles.isWhiteBackground)}>
								<label>Enter test score:</label>
								<OnHoverTooltip
									message={showValidations && `Value must be less than or equal to ${testingModel.totalPossible}`}
									followForCursor
									placement='top'>
									<Input type='number'
									       tabIndex={1}
									       disabled={saving}
									       ref={inputRef}
									       className={join(showValidations && InputStyles.error)}
									       value={score === undefined ? '' : score}
									       min={0}
									       onBlur={e => updateScore(parseInt(e.target.value, 10))}
									       onChange={(e) => updateScore(parseInt(e.target.value, 10))}/>
								</OnHoverTooltip>
							</div>
						</div>
						<div className={styles.right}>
							<div className={styles.commentHolder}>
								<TextArea className={styles.comment}
								          tabIndex={2}
								          disabled={saving}
								          maxLength={140}
								          placeholder='Notes:'
								          value={comment}
								          onChange={e => setComment(e.target.value)}/>
							</div>
							<Buttons.Contained tabIndex={3} onClick={save} disabled={saving}>
								SAVE
							</Buttons.Contained>
						</div>
					</div>
				</div>
			</QuestionFooter>
		</QuestionLayout>
		{showScoreAlert &&
			<IncorrectScoreAlert
				totalPossible={testingModel.totalPossible}
				onClose={() => setShowScoreAlert(false)}
			/>
		}
		{showConnectionLostAlert &&
			<LostConnectionAlert
				onAbort={props.onClose}
				onRetry={() => {
					save();
					setShowConnectionLostAlert(false);
				}}
			/>
		}
	</>;
}
