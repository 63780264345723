import React, {CSSProperties, ReactNode} from 'react';
import {isIOS} from '@esgillc/ui-kit/utils';

class State {
	width: number = 0;
	height: number = 0;
	initialized: boolean = false;
}

class Props {
	fixInitially?: boolean;
	fixWidth?: boolean;
	fixHeight?: boolean;
	styles?: CSSProperties;
	children?: ReactNode;
}

export class AutoDimension extends React.PureComponent<Props, State> {
	public state = new State();
	private _ref = React.createRef<HTMLDivElement>();

	public componentDidMount() {
		if (!isIOS()) {
			const obs = new MutationObserver((ags) => {
				this.checkAndUpdate();
			});
			obs.observe(this._ref.current, {childList: true, characterData: true, subtree: true});
			setTimeout(() => this.checkAndUpdate());
		}
	}

	private checkAndUpdate(): void {
		const child = this._ref.current?.children[0];
		if (child) {
			this.setState({
				height: child.getBoundingClientRect().height,
				width: child.getBoundingClientRect().width,
				initialized: true,
			});
		}
	}

	public render() {
		const ios = isIOS();
		if(ios) {
			return <>{this.props.children}</>;
		}
		return <div ref={this._ref}
		            style={{
			            ...this.props.styles || {},
			            overflow: 'hidden',
			            maxHeight: this.props.fixHeight ? 'auto' : this.state.height,
			            minHeight: this.props.fixHeight ? 'auto' : this.state.height,
			            maxWidth: this.props.fixWidth ? 'auto' : this.state.width,
			            minWidth: this.props.fixWidth ? 'auto' : this.state.width,
			            transition: this.props.fixInitially ? this.state.initialized ? 'none' : 'all 0.2s' : 'all 0.2s',
		            }}>
			{this.props.children}
		</div>;
	}
}
