import {SortDirection} from '@esgi/contracts/esgi/types/esgi.enums/sort-direction';
import {FilterModel, OptionItem} from './types';

export const defaultFilters: FilterModel = {
	gradeLevels: [],
	contentAreaIDs: [],
	stateStandardIDs: [],
	searchText: '',
};

export const defaultSortBy = {field: 'CreatedDate', direction: SortDirection.Desc};

export const sortFields: OptionItem[] = [
	{value: 'CreatedDate', label: 'Create Date'},
	{value: 'ShortTitle', label: 'Name'},
	{value: 'ContentArea', label: 'Content Area'},
	{value: 'GradeLevel', label: 'Grade level'},
];

export const maxGradeLevelToRender = 3;
export const pageSize = 25;

export const tileCardHeight = 380;
export const tileCardWidth = 424;

export const listCardHeight = 198;