import React from 'react';
import {UserType} from '@esgi/core/authentication';
import {HierarchyMode} from '../core/models';
import {HierarchyDataService, HierarchyState} from '../services/hierarchy-data-service';

type Props = {
	hierarchy: HierarchyState,
	userType: UserType,
	service: HierarchyDataService,
};

export function ModeSwitcher({userType, hierarchy, service}: Props): JSX.Element {
	if ([UserType.T, UserType.ISD, UserType.ISS, UserType.PA].some(c => c === userType)) {
		return null;
	}

	if (hierarchy.schoolSpecialists.length === 0 && hierarchy.districtSpecialists.length === 0 && hierarchy.preAssesses.length === 0) {
		return null;
	}

	if (UserType.C === userType && hierarchy.schoolSpecialists.length === 0) {
		return null;
	}

	return <div className='toggle-container'>
		<input
			id='toggle-classic'
			className='toggle toggle-left'
			name='toggle' value='false'
			type='radio'
			checked={hierarchy.mode === HierarchyMode.Classic}
			onChange={() => service.updateHierarchy({mode: HierarchyMode.Classic}, true)}
		/>
		<label htmlFor='toggle-classic' className='toggle'>Teachers</label>
		<input
			id='toggle-specialist'
			className={`toggle ${hierarchy.preAssesses.length ? '' :'toggle-right'}`}
			name='toggle'
			value='true'
			type='radio'
			checked={hierarchy.mode === HierarchyMode.Specialist}
			onChange={() => service.updateHierarchy({mode: HierarchyMode.Specialist}, true)}
		/>
		<label htmlFor='toggle-specialist' className='toggle'>Specialist</label>
		{!!hierarchy.preAssesses.length && 
			<>
				<input
					id='toggle-preassess'
					className='toggle toggle-right'
					name='toggle'
					type='radio'
					checked={hierarchy.mode === HierarchyMode.PreAssess}
					onChange={() => service.updateHierarchy({mode: HierarchyMode.PreAssess}, true)}
				/>
				<label htmlFor='toggle-preassess' className='toggle'>Pre-Assess</label>
			</>
		}
	</div>;
}
