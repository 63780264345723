import {useState} from 'react';
import {SectionWrapper} from '../../../components/section-wrapper';
import {SectionRow} from '../../../components/section-row';
import {StudentScreenModal} from './components/student-screen-modal';
import {TestButtonLabelsModal} from './components/test-button-labels-modal';
import {KeyboardShortcutsModal} from './components/keyboard-shortcuts-modal/index';

interface Props {
	enableShortcuts: boolean;
	onSaveKeyboardShortcuts: (enableShortcuts: boolean) => void;
	createShareSession: () => void;
	code: string;
	lockScreen: boolean;
	codeExpireTime: string;
	correctLabel: string;
	incorrectLabel: string;
	testButtonsLabelsEnabled: boolean;
	onSaveTestButtonLabels: (testButtonsLabelsEnabled: boolean, correctLabel: string, incorrectLabel: string) => void;
	showSelfAssessOption: boolean;
	onSaveSelfAssessOption: (showSelfAssessOption: boolean, lockScreen: boolean) => void;
}

export function TestingSection({
	                               enableShortcuts,
	                               onSaveKeyboardShortcuts,
	                               createShareSession,
	                               code,
	                               lockScreen,
	                               codeExpireTime,
	                               correctLabel,
	                               incorrectLabel,
	                               testButtonsLabelsEnabled,
	                               onSaveTestButtonLabels,
	                               showSelfAssessOption,
	                               onSaveSelfAssessOption,
}: Props,
) {

	const [showKeyboardShortcutsModal, setShowKeyboardShortcutsModal] = useState<boolean>(false);
	const [showTestButtonLabelsModal, setShowTestButtonLabelsModal] = useState<boolean>(false);
	const [showStudentScreenModal, setShowStudentScreenModal] = useState<boolean>(false);

	return <div data-cy='testing-section'><SectionWrapper title={'TESTING'}>

		<div data-cy='keyboard-shortcuts'>
			<SectionRow title={'Keyboard Shortcuts'} onClick={() => setShowKeyboardShortcutsModal(true)}/>
		</div>

		<div data-cy='test-button-labels'>
			<SectionRow title={'Test Button Labels'} onClick={() => setShowTestButtonLabelsModal(true)}/>
		</div>

		<div data-cy='student-testing-options'>
			<SectionRow title={'Student-Testing Options'} onClick={() => setShowStudentScreenModal(true)}/>
		</div>

		{showKeyboardShortcutsModal &&
			<div data-cy='keyboard-shortcuts-modal'>
				<KeyboardShortcutsModal
					onClose={() => setShowKeyboardShortcutsModal(false)}
					isShortcutsEnabled={enableShortcuts}
					onSave={(enableShortcuts) => onSaveKeyboardShortcuts(enableShortcuts)}
				/>
			</div>
		}

		{showStudentScreenModal &&
			<div data-cy='student-screen-modal'>
				<StudentScreenModal
					lockScreen={lockScreen}
					onClose={() => setShowStudentScreenModal(false)}
					createShareSession={createShareSession}
					code={code}
					codeExpireTime={codeExpireTime}
					showSelfAssessOption={showSelfAssessOption}
					onSave={onSaveSelfAssessOption}
				/>
			</div>
		}

		{showTestButtonLabelsModal &&
			<div data-cy='test-button-labels-modal'>
				<TestButtonLabelsModal
					onClose={() => setShowTestButtonLabelsModal(false)}
					correctLabel={correctLabel}
					incorrectLabel={incorrectLabel}
					testButtonsLabelsEnabled={testButtonsLabelsEnabled}
					onSave={(testButtonsLabelsEnabled, correctLabel, incorrectLabel) =>
						onSaveTestButtonLabels(testButtonsLabelsEnabled, correctLabel, incorrectLabel)
					}
				/>
			</div>
		}
	</SectionWrapper>
	</div>;
}
