import {BehaviorSubject, combineLatest, debounce, filter, switchMap, timer, withLatestFrom} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2TeachersPagesReportsUntestedStudentsController} from '@esgi/contracts/esgi';
import {
	InitRequest,
} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/teachers/pages/reports/untested-students/init-request';

import {
	StudentItem,
	StudentListDataItem,
	TestItem,
	TestListDataItem,
} from '@esgi/main/features/untested-students-report';
import {Student, SubjectTab} from '@esgi/main/libs/store';
import {
	TestData,
} from '@esgi/contracts/esgi/types/esgi.assessments/endpoints/esgi.apigateway/teachers/pages/reports/untested-students/models/test-data';
import {
	StudentData,
} from '@esgi/contracts/esgi/types/esgi.assessments/endpoints/esgi.apigateway/teachers/pages/reports/untested-students/models/student-data';
import {DataSubjectsService} from './data-subjects-service';
import {DataStudentsService} from './data-students-service';
import {TestContentArea} from '@esgi/main/kits/common';

export class DataTestsService extends BaseService {
	public isLoadedData$ = new BehaviorSubject(false);
	public tests = new BehaviorSubject<TestListDataItem[]>([]);
	public students = new BehaviorSubject<StudentListDataItem[]>([]);
	private readonly untestedStudentsController = new V2TeachersPagesReportsUntestedStudentsController();
	private subjectService = new DataSubjectsService();
	private studentsService = new DataStudentsService();
	private forceFetchTests$ = new BehaviorSubject({});

	constructor(
		public selectedLastWeek$: BehaviorSubject<number>,
		public showAllStudents$: BehaviorSubject<boolean>,
		public selectedClassId$: BehaviorSubject<number>,
		public selectedGroupId$: BehaviorSubject<number>,
		public selectedSubjectId$: BehaviorSubject<number>,
	) {
		super();

		this.completeOnDestroy(
			combineLatest([
				this.selectedLastWeek$, this.selectedClassId$,
				this.selectedGroupId$, this.selectedSubjectId$,
				this.subjectService.allSubjects$, this.studentsService.classes$,
				this.studentsService.groups$, this.studentsService.students$,
				this.showAllStudents$,
				this.forceFetchTests$,
			])
		).pipe(
			debounce(() => timer(10)),
			filter(([lastWeek, classId, groupId, subjectId,
				allSubjects, classes, groups, students, showAllStudents, forceFetchTests]) => {
				return Boolean(students.length > 0 && subjectId && (classId || groupId));
			}),
			switchMap(([lastWeek, classId, groupId, subjectId,
				allSubjects, classes, groups, students, showAllStudents, forceFetchTests]) => {
				this.isLoadedData$.next(false);
				const subject = allSubjects.find(x => x.id === subjectId);
				const testIDs = subject?.tests.map(x => x.id) || [];
				let studentIDs: number[] = [];
				if (classId) {
					studentIDs = classes.find(c => c.id === classId).studentIDs;
				} else if (groupId) {
					studentIDs = groups.find(c => c.id === groupId).studentIDs;
				}
				const requestParams: InitRequest = {
					testIDs,
					studentIDs,
					lastUntestedWeeks: lastWeek,
				};
				return this.untestedStudentsController.init(requestParams);
			}),
			withLatestFrom(this.subjectService.allSubjects$, this.selectedSubjectId$, this.studentsService.students$, this.showAllStudents$),
		).subscribe({
			next: ([response, allSubjects, subjectId, students, showAllStudents]) => {
				const subject = allSubjects.find(x => x.id === subjectId);
				const parsedTests = this.parseTests(response.testsData, subject, students, showAllStudents);
				const parsedStudents = this.parseStudents(response.studentsData, subject, students, showAllStudents);
				this.tests.next(parsedTests);
				this.students.next(parsedStudents);
				this.isLoadedData$.next(true);
			},
		});
	}


	public override dispose() {
		super.dispose();
		this.untestedStudentsController.dispose();
	}

	public fetchTests(testIDs: number[], studentIDs: number[], lastUntestedWeeks: number, subject: SubjectTab, students: Student[], showAllStudents: boolean) {
		const requestParams: InitRequest = {
			testIDs,
			studentIDs,
			lastUntestedWeeks,
		};
		this.isLoadedData$.next(false);
		this.untestedStudentsController.init(requestParams).subscribe({
			next: (response) => {
				const parsedTests = this.parseTests(response.testsData, subject, students, showAllStudents);
				const parsedStudents = this.parseStudents(response.studentsData, subject, students, showAllStudents);
				this.tests.next(parsedTests);
				this.students.next(parsedStudents);
			},
			complete: () => {
				this.isLoadedData$.next(true);
			},
		});
	}

	public forceFetchTests() {
		this.forceFetchTests$.next({});
	}

	private parseTests(data: TestData[], subject: SubjectTab, students: Student[], showAllStudents): TestListDataItem[] {
		return data.map(t => {
			const subjectTest = subject.tests.find(x => x.id === t.testID);
			const testStudents: StudentItem[] = t.students.map((s) => {
				const storeStudent = students.find(x => x.id === s.studentID);
				const student: StudentItem = {
					...storeStudent,
					correct: s.correctAnswers,
					incorrect: s.incorrectAnswers,
					untested: s.notTestedAnswers,
					maxScore: subjectTest.maxScore,
				};
				return student;
			});
			const test: TestListDataItem = {
				id: t.testID,
				name: subjectTest.name,
				contentArea: subjectTest.contentArea as TestContentArea,
				type: subjectTest.type,
				students: testStudents.filter(x => showAllStudents ? true : (x.correct + x.incorrect) === 0),
				notTested: t.notTested,
				maxScore: subjectTest.maxScore,
			};
			return test;
		});
	}

	private parseStudents(data: StudentData[], subject: SubjectTab, students: Student[], showAllStudents: boolean): StudentListDataItem[] {
		return data.map(t => {
			const studentTests: TestItem[] = t.tests.map((ti) => {
				const storeTest = subject.tests.find(x => x.id === ti.testID);
				const testItem: TestItem = {
					...storeTest,
					correct: ti.correctAnswers,
					incorrect: ti.incorrectAnswers,
					untested: ti.notTestedAnswers,
					maxScore: storeTest.maxScore,
				};
				return testItem;
			});
			const storeStudent = students.find(x => x.id === t.studentID);
			const student: StudentListDataItem = {
				...storeStudent,
				tests: studentTests.filter(x => showAllStudents ? true : (x.correct + x.incorrect) === 0),
				notTested: t.notTested,
			};
			return student;
		});
	}
}
