import React from 'react';
import {MoveDirection} from '../models';
import './question-mover.less';

export class Props {
    direction: MoveDirection;
    title: string;
    editMode: boolean;
    click: (e: any) => void;
}

export class QuestionMover extends React.Component<Props> {

	constructor(props?: Props) {
		super(props);

		this.bindScope();
	}

	private bindScope() {
		this.clickHandler = this.clickHandler.bind(this);
	}

	private clickHandler(event: any) {
		this.props.click(event);
	}

	render() {
		return <div className={(this.props.editMode) ? 'question-mover' : 'hide'}>
            <button className='arrow'
                title={this.props.title}
                onClick={this.clickHandler}>
                {this.props.direction == MoveDirection.Left
                    ? <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M7.41016 10.59L2.83016 6L7.41016 1.41L6.00016 0L0.000156403 6L6.00016 12L7.41016 10.59Z' fill='#0088CC' />
                    </svg>
                    : <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z' fill='#0088CC' />
                    </svg>}
            </button>
        </div>;
	}
}