import {useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Title} from '@esgi/deprecated/ui-kit/modal';
import {Text} from '../../kit';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCallback, useMemo, useState} from 'react';
import {LocalStorageService} from '../../services/local-storage-service';
import {Checkbox} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';
import {useUser} from '@esgi/core/authentication';
import styles from './styles.module.less';

type Props = {
	absentStudentsNumber: number;
	onClose: VoidFunction;
};

export function ModalDone({
	absentStudentsNumber,
	onClose,
}: Props) {
	const modal = useModal();
	const [isChecked, setIsChecked] = useState(false);
	const currentUser = useUser();

	const service = useMemo(() => new LocalStorageService(), []);

	const onModalClose = useCallback(() => {
		if (isChecked && currentUser?.userID) {
			service.setBanModal(true);
		}

		modal.current.close(onClose);
	}, [isChecked, currentUser]);

	const onCheckboxClick = useCallback(() => {
		setIsChecked((isChecked) => !isChecked);
	}, []);

	return (
		<Alert modalManagerRef={modal}>
			<Alert.Header>
				<Title>({absentStudentsNumber}) Missing Student Credentials</Title>
			</Alert.Header>
			<Alert.Body className={styles.body}>
				<div className={styles.mainInformationContainer}>
					<div className={styles.modalTextContainer}>
						<Text
							as='p'
							color='gray'
							size='16px'
							weight='400'
							className={styles.description}
						>
							{absentStudentsNumber === 1 ? 'Student' : 'Student(s)'} without
							credentials will not be able to <br/> access assignments.
						</Text>
					</div>
					<br/>
					<div className={styles.checkboxContainer}>
						<Checkbox
							checked={isChecked}
							className={join(
								styles.checkbox,
								isChecked && styles.checkboxIsChecked,
							)}
							onClick={onCheckboxClick}
						/>
						<Text as='p' color='gray' size='16px' weight='400'>
							Don’t show this again.
						</Text>
					</div>
				</div>
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={onModalClose}>
					Got It
				</Buttons.Text>
			</Alert.Footer>
		</Alert>
	);
};
