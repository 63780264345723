export class ModalTemplate {
	static render(): React.ReactElement {
		return <div id='modal-form' aria-hidden='true'
                data-bind="afterRender: true, style: {'z-index': zIndex}, attr: {class: className}, css: {fullscreen: fullscreen, top: verticalAlignment() == 'top'}">
      <div className='modal-dialog' data-bind="css: {'animate': settings.animate}">
        <div className='modal-content'>

          <div className='modal-header' data-bind="render: header, visible: header, css: !header ? 'hidden' : ''"/>

          <div className='modal-body' id='modal-inner-control'>
            <div className='modal-body-inner'
                 data-bind='module: {data: innerControl, loaded: controlLoaded, failed: controlLoadFailed}'></div>
          </div>

          <div className='modal-footer' data-bind='render: footer, visible: footer'/>
        </div>
      </div>
    </div>;
	}
}

export class ModalFooterTemplate {
	static render() {
		return <div>
      <div className='footer-text pull-left'
           data-bind='text:footerText, attr:{style: footerTextStyle}'></div>
      <div className='control-group pull-left'>
        <div className='controls' data-bind='foreach: leftButtons'>
          <ko data-bind='render: $data'/>
        </div>
      </div>
      <div className='control-group pull-right'>
        <div className='controls' data-bind='foreach: rightButtons'>
          <ko data-bind='render: $data'/>
        </ div>
      </ div>
    </div>;
	}
}

export class ModalHeaderTemplate {
	static render() {
		return <div className='standard-header' data-bind='afterRender: true'>
      <h3 data-bind='text: title'/>
      <a href='#' className='help' data-bind='visible: help, click: helpClicked'>
        <i className='fa fa-question-circle'/>
      </a>
      <ko data-bind='render: close'/>
    </div>;
	}
}

export class ButtonTemplate {
	static render() {
		return <button className='btn' type='button'
                   data-bind="click: onClick, attr: { 'class': cssClass, 'style': style, type: type }, disable: disabled, visible: visible">
      <ko data-bind="if: icon && (!iconplacement() || iconplacement() == 'left')">
        <i data-bind="attr: {'class': icon}"></i>
      </ko>
      <span data-bind='text: title'></span>

      <ko data-bind="if: icon && (iconplacement() == 'right')">
        <i data-bind="attr: {'class': icon}"></i>
      </ko>
    </button>;
	}
}

export class ModalCloserTemplate {
	static render() {
		return <a href='#' className='close' data-bind="click: clicked, attr: {'title': tooltip}" title='close'>
      <svg width='24' height='24' viewBox='0 0 48 48'>
        <g fill='none' fillRule='evenodd'>
          <rect width='48' height='48' />
          <path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'
                transform='matrix(-1 0 0 1 48 0)' />
          <path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round' />
        </g>
      </svg>
    </a>;
	}
}
