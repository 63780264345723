import {NameTitle} from '../../../../../types';
import {FormElementSelectOption} from '../../../../form-element-select/types';
import {userNameTitleTranscript} from '../../../constants';

const nameTitleList: NameTitle[] = [NameTitle.DR, NameTitle.MISS, NameTitle.MR, NameTitle.MRS, NameTitle.MS];

export const nameTitleOptions = nameTitleList.map<FormElementSelectOption<NameTitle>>((value) => ({
	value,
	label: userNameTitleTranscript[value],
}));
