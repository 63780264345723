import {CardRoot, RearrangeButton} from './index.styled';
import {Draggable, DraggableId} from 'react-beautiful-dnd';
import {DragDrop} from '@esgi/ui/icons';
import {CardHeaderBox} from '../card-header/index.styled';
import {OneLinedText, TestColorIndicator} from '@esgi/main/kits/common';
import {TestTypeLabel} from '../../../../../../../../kit';
import {TestType} from '@esgi/main/libs/core';

type Props = {
	index: number;
	draggableId: DraggableId;
	type: TestType;
	name: string;
	dataCy?: string;
	testID: number;
};

export function DraggableCard({index, draggableId, type, name, dataCy = 'draggable-card', testID}: Props) {
	return (
		<Draggable draggableId={draggableId} index={index} key={draggableId} data-cy={dataCy}>
			{(provided) => (
				<CardRoot ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
					<CardHeaderBox>
						<TestColorIndicator testID={testID} />
						<OneLinedText size='xxLarge' color='neutral16'>
							{name}
						</OneLinedText>
						<TestTypeLabel testType={type} />

						<RearrangeButton>
							<DragDrop />
						</RearrangeButton>
					</CardHeaderBox>
				</CardRoot>
			)}
		</Draggable>
	);
}
