import {Observable} from 'rxjs';
import {environment} from '@esgi/core/environments';

import {registerClientConfig} from '@esgi/contracts';
import {ApiKey as ESGIApiGatewayKey} from '@esgi/contracts/esgi';
import {ApiKey as SSOApiGatewayKey} from '@esgi/contracts/sso';

import NotAuthorizedHandler from './handler/not-authorized-handler';
import {DefaultErrorHandler} from './handler/default-handler';
import MoveUserHandler from './handler/move-user-handler';

export function registerContracts() {
	registerClientConfig(ESGIApiGatewayKey, {
		baseUrl: environment.esgiApiURL,
		defaultErrorHandlers: [new MoveUserHandler(), new NotAuthorizedHandler(), new DefaultErrorHandler()],
		cancel$: new Observable<void>(),
	});

	registerClientConfig(SSOApiGatewayKey, {
		baseUrl: environment.ssoApiURL,
		defaultErrorHandlers: [new MoveUserHandler(), new NotAuthorizedHandler(), new DefaultErrorHandler()],
		cancel$: new Observable<void>(),
	});
}