import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
	className: string;
	uploadedStudentsCount: number;
	conflictStudentsCount: number;
	onViewStudentsClicked: VoidFunction;
	onAlertClose: VoidFunction;
};

export function ConflictExists({
	className,
	uploadedStudentsCount,
	conflictStudentsCount,
	onViewStudentsClicked,
	onAlertClose,
}: Props) {
	return (
		<>
			<Alert.Header>
				<Text size='large'>
					You are uploading {uploadedStudentsCount} students to {className}
				</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='medium'>{conflictStudentsCount} Students Need Attention.</Text>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={onViewStudentsClicked}>
						<Text size='medium' bold>
							View Students
						</Text>
					</Button>
					<Button color='secondary' onClick={onAlertClose}>
						<Text size='medium' bold>
							Cancel
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</>
	);
}
