export enum StudentCredentialsSearchData {
	StudentId = 'studentId',
}

export interface Student {
	id: number;
	name: string;
	userName: string;
	password: string;
}

export type GroupItem<P = unknown> = P & {id: number; name: string};

export type StudentCredentialsPageLocalStorageUserInfo = {
	isBannedModal: boolean;
	isHiddenPasswords: boolean;
};

export type StudentCredentialsPageLocalStorage = Record<string, StudentCredentialsPageLocalStorageUserInfo>;

export type CheckAnotherLinksResponse = {
	links: string[];
};
