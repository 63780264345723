import React from 'react';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SubjectType} from '@esgi/core/enums';
import {useState} from '@esgi/deprecated/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {SubjectModel} from '../../../services/subjects-service/models';
import SubjectsService from '../../../services/subjects-service/subjects-service';
import BaseTray from './base-tray';

class Props {
	subjectsService: SubjectsService;
	dragStarted: () => void;
}

class State {
	subjects: SubjectModel[] = [];
	selectedSubject: SubjectModel = null;
}

@useState(State)
export default class DistrictTray extends React.Component<Props, State> {
	private canDrag: boolean = userStorage.get().userType == UserType.D;
	private onDestroy$: Subject<void> = new Subject();

	public componentDidMount() {
		this.props.subjectsService.districtSubjects$()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(subjects => this.setState({subjects}));

		this.props.subjectsService.selectedSubject$()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(selectedSubject => this.setState({selectedSubject}));
	}

	public render() {
		return <BaseTray selectedSubject={this.state.selectedSubject}
		                 subjects={this.state.subjects}
		                 onSelectSubject={s => this.props.subjectsService.selectSubject(s)}
		                 subjectType={SubjectType.Deployed}
		                 onDragStarted={() => this.props.dragStarted()}
		                 canDrag={this.canDrag}/>;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
