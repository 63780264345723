import {useCallback, useState} from 'react';
import {Drawer, DrawerMode, useDrawerMode, useDrawerParameters} from '@esgi/main/features/teacher/home';
import {EditGroupFormParams, TeacherDrawerName, AddGroupFormParams} from '../types';
import {AddGroup} from './components/add-group';
import {EditGroup} from './components/edit-group';
import {NoChangesConfirmation} from './components/no-changes-confirmation';
import {isNull} from 'underscore';

export * from './events';

interface Props {
	onAddStudentByRef?: VoidFunction;
}

export function DrawerWithGroupForm(props: Props) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isUnsavedChangesConfirmation, setIsUnsavedChangesConfirmation] = useState(false);

	const drawerMode = useDrawerMode({drawerName: TeacherDrawerName.TeacherGroup});

	const {classId, groupId, schoolYear} = useDrawerParameters<EditGroupFormParams>(['classId', 'groupId', 'schoolYear']);
	const {classId: addModeDefaultClassID} = useDrawerParameters<AddGroupFormParams>(['classId']);

	const onBeforeCloseDrawerCb = useCallback(() => {
		if (isFormTouched) {
			setIsUnsavedChangesConfirmation(true);
		}

		return !isFormTouched;
	}, [isFormTouched]);

	const onCloseUnsavedChangesConfirmation = useCallback(() => {
		setIsUnsavedChangesConfirmation(false);
	}, []);

	return (
		<Drawer
			drawerName={TeacherDrawerName.TeacherGroup}
			skeleton={!isLoaded}
			beforeCloseDrawerCb={onBeforeCloseDrawerCb}
			dataCy='drawer-with-group'
		>
			{drawerMode === DrawerMode.Create && (
				<AddGroup
					onLoaded={setIsLoaded}
					onFormTouched={setIsFormTouched}
					initialClassID={!isNull(addModeDefaultClassID) ? Number(addModeDefaultClassID) : null}
					onAddStudentByRef={props.onAddStudentByRef}
				/>
			)}
			{drawerMode === DrawerMode.Edit && classId && groupId && (
				<EditGroup
					onLoaded={setIsLoaded}
					initialClassID={Number(classId)}
					groupID={Number(groupId)}
					schoolYear={Number(schoolYear)}
					onFormTouched={setIsFormTouched}
					onAddStudentByRef={props.onAddStudentByRef}
				/>
			)}

			<NoChangesConfirmation
				isUnsavedChangesConfirmation={isUnsavedChangesConfirmation}
				onClose={onCloseUnsavedChangesConfirmation}
			/>
		</Drawer>
	);
}
