import {resolvedPromise} from '@esgi/deprecated/utils';
import {AlertTemplate} from './template';
import {IModalOptions, Modal} from '../modal/modal';
import {Renderable} from '../renderable';
import './alert.less';

export interface Window {
	bsalert: (a: string | IAlertOptions, b?: () => void) => JQueryPromise<any>;
	bsconfirm: (a: string | IConfirmOptions, b?: (result: boolean) => void) => JQueryPromise<any>;
	bsprompt: (a: string | IPromptOptions, b: (result: string) => void) => JQueryPromise<any>;
	bsmobile: (a: string | IAlertOptions, b?: () => void) => JQueryPromise<any>;
}

enum AlertType {
	Alert,
	Confirm,
	Prompt
}

interface IDialogOptions {
	type: AlertType;
	content?: any;
	value?: any;
	className?: string;
}

export class Alert extends Renderable {
	constructor(templates: any, options: IDialogOptions) {
		super();

		this.type = ko.observable(AlertType[options.type]);
		this.value = ko.observable(options.value);
		this.content = ko.observable(options.content);
		this.className = ko.observable('alert ' + options.className);
	}

	template = () => AlertTemplate.render();

	className: KnockoutObservable<string>;
	type: KnockoutObservable<string>;
	value: KnockoutObservable<any>;
	content: KnockoutObservable<any>;

	load(): JQueryPromise<any> {
    	return resolvedPromise();
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
    	setTimeout(() => {
    		$('div.form-group .form-control', rootElement).focus();
    	},
    	200);
    	return resolvedPromise();
	}

}

interface IAlertOptions {
	message: string;
	modal: IModalOptions;
	className?: string;
}

(window as any).alert = (window as any).bsalert = (a: string | IAlertOptions, b: () => void): JQueryPromise<any> => {
	let alertOptions = {content: '', type: AlertType.Alert};

	let modalOptions = {
		title: '',
		showHeader: false,
		verticalAlignment: 'top',
		className: 'alert-modal',
		buttons: [
			{
				title: 'OK',
				className: 'btn btn-sm btn-link',
				style: 'min-width: 65px;',
				onClick: () => {
					if (b) {
						b();
					}
				},
				closeModal: true,
				ok: true,
			},
		],
	};

	if (typeof a == 'string') {
		alertOptions.content = a;
	} else {
		alertOptions.content = (a as IAlertOptions).message;
		modalOptions = $.extend(true, modalOptions, (a as IAlertOptions).modal);
		modalOptions.className = (a as IAlertOptions).className + ' ' + 'alert-modal';
		if (modalOptions.title) {
			modalOptions.showHeader = true;
		}
	}

	let alert = new Alert(null, alertOptions);
	let modal = new Modal(alert, modalOptions);

	return modal.load();
};

interface IConfirmOptions {
	message: string;
	modal: IModalOptions;
	title?: string;
	className?: string;
}

(window as any).confirm = (window as any).bsconfirm = (a: string | IConfirmOptions, b: (result: boolean) => void) => {
	let alertOptions = {content: '', type: AlertType.Confirm};
	let modalOptions = {
		showHeader: false,
		verticalAlignment: 'top',
		className: 'confirm-modal',
		title: '',
		buttons: [
			{
				title: 'Cancel',
				className: 'btn btn-sm btn-link',
				onClick: () => {
					if (b) {
						b(false);
					}
				},
				cancel: true,
				closeModal: true,
			},
			{
				title: 'OK',
				className: 'btn btn-sm btn-link',
				onClick: () => {
					if (b) {
						b(true);
					}

					modal.close(true);
				},
				ok: true,
				closeModal: true,
			},
		],
	};

	if (typeof a == 'string') {
		alertOptions.content = a;
	} else {
		alertOptions.content = a.message;
		modalOptions = $.extend(true, modalOptions, a.modal);
		modalOptions.className = a.className + ' ' + 'confirm-modal';

		if (a.title) {
			modalOptions.showHeader = true;
			modalOptions.title = a.title;
		}
	}

	let alert = new Alert(null, alertOptions);
	const modal = new Modal(alert, modalOptions);

	return modal.load();
};

interface IPromptOptions {
	defaultValue: string;
	message: string;
	modal: IModalOptions;
	className?: string;
}

(window as any).prompt = (window as any).bsprompt = (a: string | IPromptOptions, b: (result: string) => void): JQueryPromise<any> => {
	let alert: Alert;
	let alertOptions = {content: '', type: AlertType.Prompt, value: ''};
	let modalOptions = {
		showHeader: false,
		verticalAlignment: 'top',
		className: 'alert-modal',
		buttons: [
			{
				title: 'Cancel',
				className: 'btn btn-link  btn-sm',
				closeModal: true,
				style: 'min-width: 65px',
				cancel: true,
				onClick: () => {
					b(null);
				},
			},
			{
				title: 'OK',
				className: 'btn btn-link btn-sm',
				style: 'width: 60px',
				onClick: () => {
					b(alert.value());
				},
				closeModal: true,
				ok: true,
			},
		],
	};

	if (typeof a == 'string') {
		alertOptions.content = a;
	} else {
		alertOptions.content = (a as IPromptOptions).message;
		alertOptions.value = (a as IPromptOptions).defaultValue;
		modalOptions = $.extend(true, modalOptions, (a as IPromptOptions).modal);
		modalOptions.className = (a as IPromptOptions).className + ' ' + 'alert-modal';
	}

	alert = new Alert(null, alertOptions);
	let modal = new Modal(alert, modalOptions);

	return modal.load();
};

(window as any).bsmobile = (a: string | IAlertOptions, b: () => void): JQueryPromise<any> => {
	let alertOptions = {content: '', type: AlertType.Alert};

	let modalOptions = {
		title: '',
		showHeader: false,
		horizontalAlignment: 'middle',
		verticalAlignment: 'center',
		className: 'mobile-modal',
		buttons: [
			{
				title: 'OK',
				className: 'btn btn-primary btn-sm',
				style: 'min-width: 65px;',
				onClick: () => {
					if (b) {
						b();
					}
				},
				closeModal: true,
				ok: true,
			},
		],
	};

	if (typeof a == 'string') {
		alertOptions.content = a;
	} else {
		alertOptions.content = (a as IAlertOptions).message;
		modalOptions = $.extend(true, modalOptions, (a as IAlertOptions).modal);
		modalOptions.className = (a as IAlertOptions).className + ' ' + 'mobile-modal';
		if (modalOptions.title) {
			modalOptions.showHeader = true;
		}
	}

	let alert = new Alert(null, alertOptions);
	let modal = new Modal(alert, modalOptions);

	return modal.load();
};

export class OldAlerts {
	static alert(a: string | IAlertOptions, b?: () => void) {
		(window as any).alert(a, b);
	}

	static bsalert(a: string | IAlertOptions, b?: () => void) {
		(window as any).bsalert(a, b);
	}

	static confirm(a: string | IConfirmOptions, b?: (result: boolean) => void) {
		(window as any).confirm(a, b);
	}

	static bsconfirm(a: string | IConfirmOptions, b?: (result: boolean) => void) {
		(window as any).bsconfirm(a, b);
	}
	static prompt(a: string | IPromptOptions, b?: (result: string) => void) {
		(window as any).prompt(a, b);
	}
	static bsprompt(a: string | IPromptOptions, b?: (result: string) => void) {
		(window as any).bsprompt(a, b);
	}
}
