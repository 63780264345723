import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const StyledText = styled(Text, {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	display: '-webkit-box',
	'-webkit-line-clamp': 2,
	'-webkit-box-orient': 'vertical',
});

export const WrappedText = styled(Text, {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	wordWrap: 'break-word',
});