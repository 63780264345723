import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Section = styled(Box, {
	marginBottom: '12px',
	width: '100%',
	flex: '0',
});

export const Header = styled(FlexBox, {
	height: '44px',
	borderBottom: '1px solid $mild',
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Roboto Mono',
});

export const Title = styled(Text, {
	padding: '20px 12px 12px 12px',
});

export const Body = styled(GridBox, {
	width: '100%',
	paddingTop: '12px',
	gap: '12px',
});
