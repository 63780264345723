import {AssignmentState} from '@esgi/main/kits/assignments';
import {AssignmentStateTab} from './types';

export const sortAssignmentStateKey: Record<
	Exclude<AssignmentStateTab, AssignmentStateTab.AllAssignments>,
	AssignmentState
> = {
	[AssignmentStateTab.Completed]: AssignmentState.Completed,
	[AssignmentStateTab.InProgress]: AssignmentState.Published,
	[AssignmentStateTab.NotStarted]: AssignmentState.NotStarted,
	[AssignmentStateTab.Draft]: AssignmentState.Draft,
};
