import {CheckAll, Folder} from '@esgi/ui';
import {StudentNameWithCheckbox} from './student-name-with-checkbox';
import {Dispatch, useCallback, useState} from 'react';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';

export function GroupsList() {
	const {
		selected: {studentIDs: selectedStudentsIDs, groupIDs: selectedGroupsIDs},
		toggleStudentId: selectStudent,
		toggleGroupId: selectGroup,
	} = StudentsPanel.Selection.MultiSelect.useMultiSelectContext();

	const [openedGroupsIDs, setOpenedGroupsIDs] = useState<number[]>([]);

	const openGroup = useCallback<Dispatch<number>>(
		(id) =>
			setOpenedGroupsIDs((values) => {
				if (values.includes(id)) {
					return values.filter((v) => v !== id);
				} else {
					return [...values, id];
				}
			}),
		[],
	);

	const {groupsWithStudents} = StudentsPanel.Tabs.Groups.useGroupsContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			{groupsWithStudents.length ? (
				groupsWithStudents.map(({id, name: classLabel, students}) => {
					const isGroupOpened = openedGroupsIDs.includes(id);

					return (
						<StudentsPanel.Lists.SelectableListGroup.Expandable.Multiple
							withCounter
							isListOpen={isGroupOpened}
							key={id}
							hideArrowIcon
							onTriggerButtonClick={() => openGroup(id)}
							groupName={classLabel}
							value={selectedStudentsIDs}
							isListSelected={selectedGroupsIDs.includes(id)}
							items={students}
							onItemClick={(studentId) => selectStudent(studentId)}
							icon={[
								{
									Component: Folder,
								},
								{
									Component: CheckAll,
									variant: 'styled-button',
									onClick: () => selectGroup(id),
								},
							]}
						>
							{({item: {firstName, lastName, id}}) => (
								<StudentNameWithCheckbox
									checked={selectedStudentsIDs.includes(id)}
									firstName={firstName}
									lastName={lastName}
								/>
							)}
						</StudentsPanel.Lists.SelectableListGroup.Expandable.Multiple>
					);
				})
			) : (
				<StudentsPanel.Tabs.EmptyTabContent size='full' templateText='No groups to display' />
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
