import {PropsWithChildren, useCallback, useMemo} from 'react';
import {useAutoControlledState} from '@esgi/ui/utils';
import {SelectableListGroupContext, SelectableListGroupContextValue} from '../../context';

export type GroupMultipleProviderType = {
	/** The controlled value of the pressed item when type is "multiple". */
	value?: string[] | undefined;

	/** The values of the items to show as pressed when initially rendered and type is "multiple". */
	defaultValue?: string[] | undefined;

	/** Event handler called when the value changes. */
	onValueChange?: ((value: string[]) => void) | undefined;
};

type GroupMultipleProviderInternalProps = {
	/** Contain all passed items value. */
	valuesVocabulary: string[];

	/** Add a value to vocabuldary state. */
	addValueToVocabulary: (value: string) => void;

	/** Remove a value from vocabuldary state. */
	removeValueFromVocabulary: (value: string) => void;
};

type GroupMultipleProviderProps = PropsWithChildren<GroupMultipleProviderType & GroupMultipleProviderInternalProps>;

export function GroupMultipleProvider({
	value: controlledValue,
	defaultValue,
	children,
	valuesVocabulary,
	addValueToVocabulary,
	removeValueFromVocabulary,
	onValueChange,
}: GroupMultipleProviderProps) {
	const [value, setValue] = useAutoControlledState({
		initialState: defaultValue ?? [],
		controlledState: controlledValue,
		onChange: onValueChange,
	});

	const handleButtonActivate = useCallback(
		(itemValue: string) => {
			setValue([...value, itemValue]);
		},
		[setValue, value],
	);

	const handleButtonDeactivate = useCallback(
		(itemValue: string) => {
			const filteredValue = value.filter((value) => value !== itemValue);

			setValue(filteredValue);
		},
		[setValue, value],
	);

	const handleActivateAllItems = useCallback(() => {
		setValue([...valuesVocabulary]);
	}, [valuesVocabulary, setValue]);

	const handleDeactivateAllItems = useCallback(() => {
		setValue([]);
	}, [setValue]);

	const contextValue = useMemo<SelectableListGroupContextValue>(
		() => ({
			value,
			type: 'multiple',
			onItemActivate: handleButtonActivate,
			onItemDeactivate: handleButtonDeactivate,
			addValueToVocabulary,
			removeValueFromVocabulary,
			onActivateAllItems: handleActivateAllItems,
			onDeactivateAllItems: handleDeactivateAllItems,
			valuesVocabulary,
		}),
		[
			value,
			handleButtonActivate,
			handleButtonDeactivate,
			addValueToVocabulary,
			removeValueFromVocabulary,
			handleActivateAllItems,
			handleDeactivateAllItems,
			valuesVocabulary,
		],
	);

	return <SelectableListGroupContext.Provider value={contextValue}>{children}</SelectableListGroupContext.Provider>;
}
