import {createContext} from '@esgi/ui';
import {Entities} from './types';
import {Dispatch} from 'react';

export type StudentsPanelContextValue = {
	entities: Entities;
	setEntities: Dispatch<Partial<Entities>>;
};

export const {Context: StudentsPanelContext, useHook: useStudentsPanelContext} =
	createContext<StudentsPanelContextValue>('');
