import React from 'react';
import {Tooltip} from '@esgillc/ui-kit/tooltip';
import {INotedAuthor} from '../../services/landing-service';

class Props {
	author: INotedAuthor;
	element: HTMLElement;
}

export class AuthorTooltip extends React.PureComponent<Props> {
	render() {
		return <Tooltip className='author-tip' element={this.props.element} offsetY={5} placement='bottom'>
			{this.props.author.tips.map(t => {
				return <div key={t.header + t.body}>
					<strong>{t.header}</strong>: {t.body}
				</div>;
			})}
		</Tooltip>;
	}
}
