import {HttpClient} from '@esgi/api';

export function CardDetail(testId: number) {
	return HttpClient.default.ESGIApi.get<Response>('pages/test-explorer', 'detail-card', {testId: testId});
}

export interface Response {
	name: string;
	contentArea: string;
	author: string;
	gradeLevels: number[];
	stateStandard: string;
	createDate: string;
	type: string;
	description: string;
	imageQuestionID: number;
	isWhiteBackground: boolean;
	questions: QuestionResponse[];
}

export interface QuestionResponse {
	questionID: number;
	ticks: number;
	pregenerated: boolean;
}
