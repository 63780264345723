import {BehaviorSubject, zip} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {Class, Group, Student, classesStore, groupsStore, studentsStore} from '@esgi/main/libs/store';

export class StudentService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public classes$ = new BehaviorSubject<Class[]>([]);
	public groups$ = new BehaviorSubject<Group[]>([]);
	public students$ = new BehaviorSubject<Student[]>([]);
	private storageClasses = classesStore();
	private storageGroups = groupsStore();
	private storageStudents = studentsStore();

	constructor() {
		super();

		zip(
			this.storageClasses.get(),
			this.storageGroups.get(),
			this.storageStudents.get()
		).subscribe(([classes, groups, students]) => {
			this.classes$.next(classes.data);
			this.groups$.next(groups.data);
			this.students$.next(students.data);
			this.loaded$.next(classes.loaded && groups.loaded && students.loaded);
		});
	}

	public override dispose() {
		this.storageClasses.dispose();
		this.storageGroups.dispose();
		this.storageStudents.dispose();
	}

	public get(studentId: Student['id']) {
		return this.students$.value.find(({id}) => id === studentId);
	}

	public getClass(classId: Class['id']) {
		return this.classes$.value.find(({id}) => id === classId);
	}

	public getGroup(groupId: Group['id']) {
		return this.groups$.value.find(({id}) => id === groupId);
	}

	public getStudentIdsByClass(classId: Class['id']) {
		if (classId === -1) {
			return [...new Set(this.classes$.value.flatMap(({studentIDs}) => studentIDs))];
		}
		return this.getClass(classId)?.studentIDs || [];
	}

	public getStudentIdsByGroup(groupId: Group['id']) {
		return this.getGroup(groupId)?.studentIDs || [];
	}
}
