import {
	EdgePosition,
	ImageModel,
	Position,
	ShapeDefinitions, ShapeSize,
	ShapeType, StyleDefinition,
	TextAlign, TextStyle, VertexPosition, VertexSize,
} from 'shared/modules/question-editor/models';
import {mxGraphModel} from 'mxgraph';
import mx from '../../assets/mx-graph/mx-graph-factory';
import Enumerable from 'linq';
import {ShapeDefinitionService} from 'shared/modules/question-editor/utils/shape-definition-builder';

export class XmlCodec {
	public static decode(xml: string, images: ImageModel[]) {
		let strResult = xml;
		for (let i = 0; i < images.length; i++) {
			let image = images[i];
			let regExp = new RegExp(`image=${image.url};`);
			if (regExp.test(strResult)) {
				strResult = strResult.replace(regExp, '');
			}
		}

		return strResult;
	}

	public static getModel(xml: string): mxGraphModel {
		let xmlDocument = mx.mxUtils.parseXml(xml);
		let node = xmlDocument.documentElement;
		let decoder = new mx.mxCodec(xmlDocument);
		return decoder.decode(node, null);
	}

	public static setDefinitionsFromXml(xml: string, shapeDefinition: ShapeDefinitions, isEdit: boolean, isSecondQuestion: boolean) {
		let model = this.getModel(xml);
		let cells = model.getChildCells(model.cells[1]);
		let shapeDefinitionService = new ShapeDefinitionService();
		let definitions = new ShapeDefinitions();

		for (let i = cells.length - 1; i >= 0; i--) {
			let cell = cells[i];

			if (definitions.styles.filter(x => x.type === cell.type).length > 0) {
				continue;
			}

			let shapeStyle = shapeDefinitionService.shapeStyleFactory(cell.type);
			let style = shapeDefinitionService.convertStrStyleToGraphStyle(shapeStyle, cell.style);

			if (ShapeType.Text && !isEdit && isSecondQuestion) {
				let textStyle = style as TextStyle;
				if (textStyle.align === TextAlign.Center) {
					textStyle.align = TextAlign.Left;
				}
			}

			definitions.styles.push(new StyleDefinition(cell.type, style));

			let geo = cell.geometry;

			if (cell.type >= ShapeType.Line) {
				let sPosition = new Position(geo.sourcePoint.x, geo.sourcePoint.y);
				let tPosition = new Position(geo.targetPoint.x, geo.targetPoint.y);

				definitions.edgePositions.push(new EdgePosition(cell.type, sPosition, tPosition));
			} else {
				if (cell.type === ShapeType.Text) {
					let size = shapeDefinitionService.defaultSizes.text;

					definitions.vertexSizes.push(new VertexSize(ShapeType.Text, size));

					if (isEdit) {
						let center = shapeDefinitionService.getVertexCenterCoordinates(size.width, size.height);
						definitions.vertexPositions.push(new VertexPosition(ShapeType.Text, center));
					} else {
						definitions.vertexPositions.push(new VertexPosition(cell.type, new Position(geo.x, geo.y)));
					}
				} else {
					definitions.vertexPositions.push(new VertexPosition(cell.type, new Position(geo.x, geo.y)));
					definitions.vertexSizes.push(new VertexSize(cell.type, new ShapeSize(geo.width, geo.height)));
				}
			}
		}

		definitions.styles.forEach(s => {
			let def = Enumerable.from(shapeDefinition.styles).where(x => x.type === s.type).firstOrDefault();
			def.style = s.style;
		});

		definitions.vertexPositions.forEach(s => {
			let def = Enumerable.from(shapeDefinition.vertexPositions).where(x => x.type === s.type).firstOrDefault();
			def.position = s.position;
		});

		definitions.vertexSizes.forEach(s => {
			let def = Enumerable.from(shapeDefinition.vertexSizes).where(x => x.type === s.type).firstOrDefault();
			def.size = s.size;
		});

		definitions.edgePositions.forEach(s => {
			let def = Enumerable.from(shapeDefinition.edgePositions).where(x => x.type === s.type).firstOrDefault();
			def.source = s.source;
			def.target = s.target;
		});
	}
}