import React from 'react';
import {Question, TemplateType} from '../models';
import Practice from './practice/practice';
import WithFourColumns from './with-four-columns/with-four-columns';
import WithOneColumn from './with-one-column/with-one-column';
import WithThreeColumns from './with-three-columns/with-three-columns';
import WithTwoColumns from './with-two-columns/with-two-columns';
import Template5 from './template5/template5';
import Story from './story';


export interface TemplateProps {
	question: Question,
	questionIndex?: number;
	onSelectOption: (id: number) => any;
	selectedOptionID: number[];
	maxOptionsSelected: boolean;
	audioPlaying?: boolean;
	awsBucket: string;
	isPreviewMode?: boolean
}

export class QuestionTemplate extends React.PureComponent<TemplateProps> {
	render() {
		switch(this.props.question.templateType) {
			case TemplateType.With1Column:
				return <WithOneColumn {...this.props}/>;
			case TemplateType.With2Columns:
				return <WithTwoColumns {...this.props} />;
			case TemplateType.With3Columns:
				return <WithThreeColumns {...this.props} />;
			case TemplateType.With4Columns:
				return <WithFourColumns {...this.props} />;
			case TemplateType.Template5:
				return <Template5 {...this.props} />;
			case TemplateType.Practice:
				return <Practice {...this.props} />;
			case TemplateType.Story:{
				return <Story {...this.props}/>;
			}

			default:
				return <></>;
		}
	}
}

export default QuestionTemplate;
