import {PropsWithChildren} from 'react';
import {Text} from '@esgi/ui/typography';
import {CSS} from '@stitches/react';
import {NumericValue, StringValue} from './types';
import {isStringValue, resolveColor, resolveValueType} from './utils';
import {DeltaContainer, DeltaIndicator, DeltaText} from './styled';

type Props = {
	skeleton?: boolean,
	css?: CSS,
	unit?: string,
} & (NumericValue | StringValue) & PropsWithChildren;

export function Delta({unit = '%', ...props}: Props) {
	if(isStringValue(props)) {
		const color = resolveColor(props.type);
		return <DeltaContainer skeleton={props.skeleton} css={props.css} dataCy='delta-container'>
			<DeltaIndicator color={props.type} dataCy='delta-indicator'>
				<Text font='mono' size='xSmall' color='vivid'>{props.value}{unit}</Text>
			</DeltaIndicator>
			<DeltaText font='mono' size='xSmall' color={color} data-cy='delta-text'>{props.children}</DeltaText>
		</DeltaContainer>;
	} else {
		const sign = props.value > 0 ? '+' : '';
		const type = resolveValueType(props.value);
		const color = resolveColor(type);

		return <DeltaContainer skeleton={props.skeleton} css={props.css} dataCy='delta-container'>
			<DeltaIndicator color={type} dataCy='delta-indicator'>
				<Text font='mono' size='xSmall' color='vivid'>{props.value !== 0 ? sign : ''}{props.value}{unit}</Text>
			</DeltaIndicator>
			<DeltaText font='mono' size='xSmall' color={color} data-cy='delta-text'>{props.children}</DeltaText>
		</DeltaContainer>;
	}
}