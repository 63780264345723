import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {ReportFiltersLabelContainer} from '../report-filter';
import {
	CheckboxStyled,
	ReportFilterContainerStyled,
	ReportFilterLabelStyled,
	ReportFiltersContentStyled,
} from './index.styled';

type ShowResultsFilterProps = {
	carryScoresForward: boolean;
	showBaseline: boolean;
	onCarryForwardClicked: (carryScoresForward: boolean) => void;
	onShowBaseLineClicked: (showBaseLine: boolean) => void;
};

export function ShowResultsFilter({
	carryScoresForward,
	showBaseline,
	onCarryForwardClicked,
	onShowBaseLineClicked,
}: ShowResultsFilterProps) {
	return (
		<ReportFilterContainerStyled>
			<ReportFiltersLabelContainer>
				<ReportFilterLabelStyled dataCy='results-label'>Results:</ReportFilterLabelStyled>
			</ReportFiltersLabelContainer>
			<ReportFiltersContentStyled>
				<OnHoverTooltip message='Carry forward'>
					<div data-cy='carry-forward-checkbox'>
						<CheckboxStyled
							onChange={() => onCarryForwardClicked(!carryScoresForward)}
							checked={!!carryScoresForward}
							data-cy='carry-forward-label'
						>
							Carry forward
						</CheckboxStyled>
					</div>
				</OnHoverTooltip>
				<OnHoverTooltip message='Show Baseline'>
					<div data-cy='show-baseline-checkbox'>
						<CheckboxStyled
							onChange={() => onShowBaseLineClicked(!showBaseline)}
							checked={!!showBaseline}
							data-cy='show-baseline-label'
						>
							Show Baseline
						</CheckboxStyled>
					</div>
				</OnHoverTooltip>
			</ReportFiltersContentStyled>
		</ReportFilterContainerStyled>
	);
}
