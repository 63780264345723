import * as React from 'react';
import type {SVGProps} from 'react';

export function SortTable(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M6.30025 9.38106C6.13443 9.53965 5.86557 9.53965 5.69975 9.38106L4.37437 7.69765C4.20854 7.53907 4.20854 7.28196 4.37437 7.12338C4.54019 6.9648 4.90625 7.00439 5.125 7.00439L7.09375 7.00446C7.3125 7.00447 7.45981 6.9648 7.62563 7.12338C7.79146 7.28196 7.79146 7.53907 7.62563 7.69765L6.30025 9.38106Z'
			      fill='#333333'/>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M5.69975 2.61894C5.86557 2.46035 6.13443 2.46035 6.30025 2.61894L7.62563 4.30235C7.79146 4.46093 7.79146 4.71804 7.62563 4.87662C7.45981 5.0352 7.09375 4.99561 6.875 4.99561L4.90625 4.99554C4.6875 4.99553 4.54019 5.0352 4.37437 4.87662C4.20854 4.71804 4.20854 4.46093 4.37437 4.30235L5.69975 2.61894Z'
			      fill='#333333'/>
		</svg>
	);
}
