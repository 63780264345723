export interface StudentModel {
	id: number;
	fullName: string;
}

export interface InitEditModel {
	availableStudents: StudentModel[];
	studentsInClass: StudentModel[];
}

export interface CreateResponseModel {
	classID: number;
}

export class InModel {
	constructor(public checked: boolean, public label: string, public id: number) {
	}
}

export enum ArrowDirection {
	None,
	Left,
	Right
}

