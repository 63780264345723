import {useNavigationBarContext, NavigationBar} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {LogoBeta} from '@esgi/ui/icons';
import {BurgerButton, isUndefined} from '@esgi/ui';
import {SelectedItemIndicator} from '../../../selected-item-indicator';
import {useEffect, useState} from 'react';
import {menuLabelByRoute, menuIconByPath} from '../../../../constants';
import {useLocation} from 'react-router-dom';
import {routes} from '@esgi/main/libs/core';

export function HorizontalPanelHead() {
	const {expanded, setExpanded} = useNavigationBarContext();

	const {pathname} = useLocation();

	const [activeRouteKey, setActiveRouteKey] = useState<string>();

	useEffect(() => {
		const currentRoute = Object.entries(routes.teacher).find(([_, route]) => {
			if(typeof route === 'string') {
				return pathname.startsWith(route);
			}

			return Object.entries(route).find(([_, nestedRoute]) => pathname.startsWith(nestedRoute));
		});

		setActiveRouteKey(currentRoute?.[0]);
	}, [pathname]);

	const Icon = !isUndefined(activeRouteKey) ? menuIconByPath[activeRouteKey] : null;

	return (
		<NavigationBar.Head
			css={{
				paddingTop: '14px',
				paddingBottom: '14px',
			}}
		>
			<BurgerButton expanded={expanded} setExpanded={setExpanded} />

			<NavigationBar.Item withHoverEffects={false} css={{paddingBottom: 0, paddingTop: 0, marginTop: '-8px'}}>
				<LogoBeta width={80} height={32} />
			</NavigationBar.Item>

			{!isUndefined(activeRouteKey) && (
				<SelectedItemIndicator css={{marginLeft: 12}}>
					<Icon />
					<Text size='small' css={{marginTop: '1px'}}>
						{menuLabelByRoute[activeRouteKey]}
					</Text>
				</SelectedItemIndicator>
			)}
		</NavigationBar.Head>
	);
}
