export class UrlSearchParamsBuilder {
	static BuildParameters<T>(obj: T): URLSearchParams {
		const params = new URLSearchParams();

		if (obj == null) {
			return params;
		}

		return UrlSearchParamsBuilder.PopulateParams(params, '', obj);

	}

	private static PopulateArray<T>(params: URLSearchParams, prefix: string, val: Array<T>): URLSearchParams {
		for (let index = 0; index < val.length; index++) {
			const key = prefix + '[' + index + ']';
			const value = val[index];
			params = UrlSearchParamsBuilder.PopulateParams(params, key, value);
		}
		return params;
	}

	private static PopulateObject<T>(params: URLSearchParams, prefix: string, val: T): URLSearchParams {
		const objectKeys = Object.keys(val) as Array<keyof T>;

		if (prefix) {
			prefix = prefix + '.';
		}

		for (const objKey of objectKeys) {

			const value = val[objKey];
			const key = prefix + objKey.toString();

			params = UrlSearchParamsBuilder.PopulateParams(params, key, value);
		}
		return params;
	}

	private static PopulateParams<T>(params: URLSearchParams, key: string, value: any): URLSearchParams {
		if (value instanceof Array) {
			return UrlSearchParamsBuilder.PopulateArray(params, key, value);
		}

		if (value instanceof Date) {
			params.append(key, value.toISOString());
			return params;
		}

		if (value instanceof Object) {
			return UrlSearchParamsBuilder.PopulateObject(params, key, value);
		}

		if (value === null || value === undefined) {
			return params;
		}

		params.append(key, value);
		return params;
	}

}
