import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';

export const ConflictedStudentInfoRow = styled(FlexBox, {
	justifyContent: 'space-between',
	padding: '12px 14px',
	backgroundColor: '$background',
	borderRadius: '6px',
	border: '1px solid transparent',
	marginTop: '8px',

	variants: {
		withRedColor: {
			true: {
				backgroundColor: '$red99',
				borderColor: '$red92',
			},
		},
	},
});

export const LineNumber = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	gap: '10px',
});

