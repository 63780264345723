import {styled} from '@stitches/react';
import {Button} from '@esgi/ui';

export const OutlinedButtonIcon = styled(Button.Icon, {
	width: '32px',
	height: '32px',
	border: '1px solid $border',
	display: 'flex',
	alignItems: 'center',
	alignContent: ' center',
	justifyContent: 'center',
	borderRadius: '6px',

	'& svg': {
		width: '24px',
		height: '24px',
		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		large: {
			true: {
				width: '36px',
				height: '36px',
				padding: '6px',
			},
		},
	},
});
