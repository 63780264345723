import {useState, useRef} from 'react';
import {Card} from '../../../../types';
import {getS3Source} from '../../../../utils';
import {environment} from '@esgi/core/environments';
import {CardBody, LoadText, Image} from './body.styled';

class Props {
	card: Card;
}

export function Body({card}: Props) {
	const [loaded, setLoaded] = useState(false);
	const img = useRef<HTMLImageElement>(null);

	const getAssetsSource = () => {
		if (loaded) {
			return;
		}

		const url = `/reports/flash-cards/QuestionImage?questionId=${card.questionID}&width=400&height=253`;
		img.current.src = environment.esgiApiURL + url;
		setLoaded(true);
	};

	return (
		<CardBody>
			<LoadText loaded={loaded}>Loading</LoadText>
			<Image
				ref={img}
				src={getS3Source(card.questionID, card.testID, card.ticks)}
				alt={card.questionID.toString()}
				loaded={loaded}
				onLoad={() => setLoaded(true)}
				onError={getAssetsSource}
			/>
		</CardBody>
	);
}
