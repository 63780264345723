import * as modelUtils from '../utils/utils';
import {NameWritingReport} from '../templates/name-writing/types';
import {BasePdfDownloadingService} from './base-pdf-downloading-service';
import {SsoTracker} from '@esgi/core/tracker';

export class NameWritingPdfDownloadingService extends BasePdfDownloadingService<NameWritingReport> {
	protected override executePdfDownloadingRequest(report: NameWritingReport) {
		const date = new Date().toLocaleDateString('en-US');
		const request = {
			documentDate: date,
			lecturerName: modelUtils.getLecturerName(report.details.lecturer),
			unitType: report.details.unitType,
			copies: report.details.copiesCount,
			students: report.students.sort((first, second) => first.unitId - second.unitId),
		};
		SsoTracker.trackEvent({trackingEvent: 'WritingPractice-NameWriting'});
		return this.httpClient.ESGIApi.file(this.controller, 'name-writing/pdf', `Name_Writing_${date}.pdf`, request);
	}
}