import {useFlashcardsService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody as Body, Header, Page, ReportWrapper, HeaderText} from './index.styled';
import {Card} from '../card';

const columns = (amount: number) => {
	if (amount < 1) {
		return 1;
	}
	if (amount > 4) {
		return 4;
	}
	return amount as 1 | 2 | 3 | 4;
};

export function AlertBody() {
	const service = useFlashcardsService();
	const results = useBehaviorSubject(service.results$);

	return (
		<Body>
			<ReportWrapper>
				{results.students.map(({studentID, firstName, lastName, cards}) =>
					<Page key={studentID}>
						<Header>
							<HeaderText data-cy='header-student-name' type='studentName'>{`${firstName || ''} ${lastName || ''}`}</HeaderText>
							<HeaderText data-cy='header-subject-name' type='subjectName'>{results.subjectName}</HeaderText>
						</Header>
						<GridBox columns={columns(results.pageSize.colCount)}>
							{cards.map((card) =>
									<Card
										key={card.questionID}
										card={card}
										studentName={`${firstName || ''} ${lastName || ''}`}
									/>
							)}
						</GridBox>
					</Page>
				)}
			</ReportWrapper>
		</Body>
	);
}
