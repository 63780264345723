import {useCallback} from 'react';
import {useSubjectSectionContext} from '../../context/subject-section-context';
import {SubjectTab} from '@esgi/main/libs/store';
import {useAutoControlledState} from '@esgi/ui';
import {ExpandablePanelModeVariant} from '../../../../kit';
import {SelectableListRearrangeable} from '../lists/selectable-list-rearrangeable';

type Props = {
	selectedSubjectId: SubjectTab['id'] | null;
	subjects: SubjectTab[];
	updateSubjectsOrder: (subjects: SubjectTab[]) => void;
};

export function RearrangeModeContent({updateSubjectsOrder, selectedSubjectId, subjects}: Props) {
	const [controlledSubjects, setControlledSubjects] = useAutoControlledState({initialState: subjects});

	const {setSectionViewMode} = useSubjectSectionContext();

	const handleSaveClick = useCallback(() => {
		updateSubjectsOrder(controlledSubjects);
		setSectionViewMode();
	}, [setSectionViewMode, updateSubjectsOrder, controlledSubjects]);

	return (
		<ExpandablePanelModeVariant
			withBottomSectionButton
			bottomSectionButtonWithIcon
			buttonText='Save'
			onBottomButtonClick={handleSaveClick}
		>
			<SelectableListRearrangeable
				onDragEnd={setControlledSubjects}
				subjects={controlledSubjects}
				selectedSubjectId={selectedSubjectId}
			/>
		</ExpandablePanelModeVariant>
	);
}
