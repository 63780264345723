import {ReportFiltersLabelContainer, ReportFiltersContent} from '../report-filter';
import {RadioStyled, ReportFilterContainerStyled, ReportFilterLabelStyled} from './index.styled';
import {CheckboxStyled} from '../show-results-filter/index.styled';

type DisplayNotTestedFilterProps = {
	displayZeroIfNotTested: boolean;
	onChanged: (displayZeroIfNotTested: boolean) => void;
	includeNotTested: boolean;
	onIncludeTestedChanged: (value: boolean) => void;
};

export function DisplayNotTestedFilter({includeNotTested, onIncludeTestedChanged, displayZeroIfNotTested, onChanged}: DisplayNotTestedFilterProps) {
	return (
		<ReportFilterContainerStyled>
			<ReportFiltersLabelContainer>
				<ReportFilterLabelStyled dataCy='display-not-tested-label'>Display not tested as:</ReportFilterLabelStyled>
			</ReportFiltersLabelContainer>
			<ReportFiltersContent>
				<RadioStyled
					value='Zero'
					onChange={() => onChanged(true)}
					checked={!!displayZeroIfNotTested}
					data-cy='zero-radio'
				>
					Zero
				</RadioStyled>
				<RadioStyled
					value='NT'
					onChange={() => onChanged(false)}
					checked={!displayZeroIfNotTested}
					data-cy='nt-radio'
				>
					NT
				</RadioStyled>
			</ReportFiltersContent>
			<CheckboxStyled
				onChange={(value) => onIncludeTestedChanged(value.target.checked)}
				checked={includeNotTested}
				data-cy='include-not-tested-checkbox'
			>
				Include not tested
			</CheckboxStyled>
		</ReportFilterContainerStyled>
	);
}
