import React from 'react';
import {range} from 'underscore';
import AddCriteria from '../parts/criteria/components/add-criteria';
import {DescriptionEmptyView} from '../parts/description/description-empty-view';
import styles from './row.animation.module.less';
import rowAnimationStyles from '../../row.animation.module.less';
import {ClassNameAnimation} from '@esgillc/ui-kit/transition';

interface Props {
	className: string;
	onClicked: () => void;
	show: boolean;
	columnsCount: number;
}

export default class AddCriteriaRow extends React.PureComponent<Props> {
	public render() {
		return <ClassNameAnimation in={this.props.show} duration={200} classNames={{
			enter: rowAnimationStyles.appearAnimationRow,
			exit: styles.rowRemove,
			initial: styles.rowHidden,
		}}>
			<tr key='add-criteria-row' className={styles.initial}>
				<th>
					<AddCriteria className={this.props.className} onClick={this.props.onClicked}/>
				</th>
				{range(this.props.columnsCount).map(i => <td key={i}><DescriptionEmptyView/></td>)}
			</tr>
		</ClassNameAnimation>;
	}
}
