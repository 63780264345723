import {PropsWithChildren, useState} from 'react';
import styles from '@esgi/deprecated/ui-kit/report/modal/download-button/report-modal-download-button.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Menu, MenuItem, MenuItemProps} from '@esgillc/ui-kit/menu';

interface DownloadButtonProps extends PropsWithChildren {
	name?: string;
	title?: string;
}

const Button = ({name, title, children}: DownloadButtonProps) => {

	const [isDownloadMenuOpen, setDownloadMenuOpen] = useState(false);

	return <div className={styles.downloadWrapper}>
		<OnHoverTooltip message={title ? title : 'Download'}>
			<button className={styles.downloadButton} onClick={() => setDownloadMenuOpen(prev => !prev)}>
				<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z' fill='white'/>
				</svg>
				<span className={styles.name}>{name ? name : 'Download'}</span>
			</button>
		</OnHoverTooltip>
		<Menu className={styles.menu} show={isDownloadMenuOpen}
		      onClickOutside={() => setDownloadMenuOpen(false)}>
			{children}
		</Menu>
	</div>;
};

const ButtonItem = (props: MenuItemProps) => <MenuItem className={styles.pdfExcelMenuItem} {...props}/>;

export const ReportDownload = {
	Button,
	ButtonItem,
};
