export function getUniqValuesInData<T extends Partial<Record<keyof T, unknown>>>({
	keys,
	data,
}: {
	keys: (keyof T)[];
	data: T[];
}) {
	const state = {} as Record<keyof T, Set<unknown>>;

	keys.forEach((key) => {
		state[key] = new Set();
	});

	data.forEach((item) => {
		keys.forEach((key) => {
			state[key].add(item[key]);
		});
	});

	return state;
}
