import {Wrapper, ButtonIcon, ButtonText, SortTable} from './index.styled';
import {CheckAll} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {TestListProps} from '../../../../types';

interface Props extends Pick<TestListProps, 'sort' | 'onSort' | 'onSelectAll'> {}

export function Action({sort, onSort, onSelectAll}: Props) {
	return (
		<Wrapper>
			<ButtonIcon data-cy='select-all-tests-button' onClick={onSelectAll}>
				<CheckAll/>
			</ButtonIcon>
			<ButtonText data-cy='sort-tests-button' onClick={onSort}>
				<Text data-cy='test-name-button' size='small'>Test Name</Text>
				<SortTable field={sort.field} direction={sort.direction}/>
			</ButtonText>
		</Wrapper>
	);
}
