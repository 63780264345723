import {Button} from '@esgi/ui';
import {Edit} from '@esgi/ui/icons';
import {MouseEvent, useCallback} from 'react';

type Props = {
	onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export function ButtonEditable({onClick}: Props) {
	const handleClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();

			onClick?.(event);
		},
		[onClick],
	);

	return (
		<Button.Icon withBackgroundHover onClick={handleClick}>
			<Edit />
		</Button.Icon>
	);
}
