import 'overlayscrollbars/overlayscrollbars.css';
import {useEffect, useMemo, useRef} from 'react';
import {useBehaviorSubject, useMatchMedia} from '@esgi/ui';
import {Outlet, useNavigate} from 'react-router-dom';
import {TooltipProvider} from '@esgi/ui/tooltip';
import {ThemeProvider} from '@esgi/ui/theme';
import {UserType, useUser} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';
import {useService} from '@esgi/core/service';
import {ContactUs, useContactUs} from '@esgi/features/support';
import {PageContainer, ContentContainer} from './index.styled';
import {Service} from './service';
import {ScreenSize, RootPageContext, NotificationAlert} from '@esgi/main/kits/common';
import {VersionToggleBanner} from './components/version-toggle-banner';
import {NavigationPanelRoot} from '../navigator/teacher';
import {UserFeaturesProvider} from '../user-features-context';

import {Provider} from 'react-redux';
import {reduxStore} from '@esgi/main/libs/store';

const TeacherDrawers = lazyComponent(() => import('../navigator/teacher/drawers'));

export default function () {
	const navigate = useNavigate();
	const isPortraitTablet = useMatchMedia('portrait-tablet');
	const isLandscapeTablet = useMatchMedia('landscape-tablet');
	const isSmallLaptop = useMatchMedia('small-laptop');

	const screenSize = isPortraitTablet
		? ScreenSize.PortraitTablet
		: isPortraitTablet || isLandscapeTablet
			? ScreenSize.Tablet
			: isPortraitTablet || isLandscapeTablet || isSmallLaptop
				? ScreenSize.Small
				: null;

	const {isVisible, handleClose} = useContactUs();

	const rootPageContentContainerRef = useRef<HTMLDivElement>(null);

	const schoolYearsService = useService(Service);

	const user = useUser();

	useEffect(() => {
		if (!user?.userID) {
			navigate('/login', {
				replace: true,
			});

			return;
		}

		schoolYearsService.init().subscribe();
	}, [schoolYearsService]);

	const selectedGlobalSchoolYearID = useBehaviorSubject(schoolYearsService.selectedGlobalSchoolYearID$);
	const schoolYears = useBehaviorSubject(schoolYearsService.schoolYears$);

	const navigationPanel = useMemo(() => {
		switch (user?.userType) {
			case UserType.T:
				return (
					<NavigationPanelRoot
						currentGlobalSchoolYearID={selectedGlobalSchoolYearID}
						schoolYears={schoolYears}
						onChangeSchoolYears={(id) => schoolYearsService.switchSchoolYear(id)}
					/>
				);
		}
	}, [user, selectedGlobalSchoolYearID, schoolYears]);

	const drawers = useMemo(() => {
		switch (user?.userType) {
			case UserType.T:
				return <TeacherDrawers screenSize={screenSize} />;
		}
	}, [screenSize, user?.userType]);

	const outletContext = useMemo<RootPageContext>(
		() => ({screenSize, rootPageContentContainerRef}),
		[screenSize, rootPageContentContainerRef],
	);

	const isTablet = screenSize === ScreenSize.Tablet || screenSize === ScreenSize.PortraitTablet;

	return (
		<ThemeProvider>
			<TooltipProvider>
				<UserFeaturesProvider>
					<Provider store={reduxStore}>
						{isVisible && <ContactUs ticketSubmitted={handleClose} onClose={handleClose}/>}
						<VersionToggleBanner/>
						<PageContainer direction={isTablet ? 'column' : 'row'} withVersionBannerOffset>
							{navigationPanel}
							<ContentContainer key={selectedGlobalSchoolYearID} ref={rootPageContentContainerRef}>
								<Outlet context={outletContext}/>
								{drawers}
							</ContentContainer>
						</PageContainer>

						<NotificationAlert/>
					</Provider>
				</UserFeaturesProvider>
			</TooltipProvider>
		</ThemeProvider>
	);
}
