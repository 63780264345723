export function SaveAndCloseClicked() {
}

export function SaveAndNextClicked() {
}

export function CloseClicked() {
}

export function ShowValidation(formValid: boolean, canvasValid: boolean): ShowValidation.Args {
	return {
		formValid: formValid,
		canvasValid: canvasValid,
	};
}

export namespace ShowValidation {
	export interface Args {
		formValid: boolean,
		canvasValid: boolean,
	}
}