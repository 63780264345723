import {CounterBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {GroupTypeMultiple, GroupTypeSingle, SessionItem} from './types';
import {Timestamp, DateFormatText, AnswersInfo, TestSessionDelta} from '../../../../../../../kit';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {AnswersInfoBox, Container, LoadMoreButton, SessionDateInfo} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {paginationSize} from './constants';
import {useSessionListData} from './use-session-list-data';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props = {
	sessionItems: SessionItem[];
	indicatorPercentageType?: boolean;
	visibleSessionItemsCount: number;
	setVisibleSessionItemsCount: Dispatch<number>;
} & (GroupTypeSingle | GroupTypeMultiple);

export function SessionList({
	sessionItems,
	indicatorPercentageType = false,
	visibleSessionItemsCount,
	setVisibleSessionItemsCount,
	...props
}: Props) {
	const [visibleSessionItems, setVisibleSessionItems] = useState<SessionItem[]>([]);

	const {groupRootProps, isSessionItemSelected, toggleValueInState} = useSessionListData(props);

	useEffect(() => {
		setVisibleSessionItems(sessionItems.slice(0, visibleSessionItemsCount));
	}, [sessionItems]);

	const handleLoadMoreButtonClick = useCallback(() => {
		setVisibleSessionItems((currentVisibleItems) => {
			const nextNotVisibleIndex = currentVisibleItems.length;

			const newItems = [
				...currentVisibleItems,
				...sessionItems.slice(nextNotVisibleIndex, nextNotVisibleIndex + paginationSize),
			];
			setVisibleSessionItemsCount(newItems.length);

			return newItems;
		});
	}, [sessionItems, setVisibleSessionItemsCount]);

	const notShownItemsCount = Math.min(sessionItems.length - visibleSessionItems.length, paginationSize);

	return (
		<Container dataCy='session-list'>
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<SelectableList>
					<SelectableList.GroupRoot {...groupRootProps}>
						<SelectableList.Group>
							{visibleSessionItems.map(({date, delta, sessionId, sessionResult, totalPossible}) => {
								const {forceSelect, isItemSelected} = isSessionItemSelected(sessionId);

								return (
									<SelectableList.Item
										value={String(sessionId)}
										setTextCurrentColor={false}
										onClick={() => toggleValueInState(sessionId)}
										forceSelect={forceSelect}
										key={sessionId}
										dataCy='session-item'
									>
										<GridBox align='center' justify='between' flow='column' gap='2'>
											<SessionDateInfo>
												<DateFormatText
													date={date}
													dateFormat='MM/DD/YYYY'
													color={isItemSelected ? 'primary' : 'base'}
													size='medium'
													font='mono'
													bold
												/>
												<Timestamp
													date={date}
													backgroundColor={isItemSelected ? 'primaryVivid' : 'lowContrast'}
													timeTextColor={isItemSelected ? 'primary' : 'highContrast'}
												/>
											</SessionDateInfo>

											<GridBox align='center' flow='column' gap='2'>
												<TestSessionDelta value={delta} percentageType={indicatorPercentageType} />
												<AnswersInfoBox dataCy='answers-info-box'>
													<AnswersInfo value={sessionResult} maxValue={totalPossible} />
												</AnswersInfoBox>
											</GridBox>
										</GridBox>
									</SelectableList.Item>
								);
							})}
						</SelectableList.Group>
					</SelectableList.GroupRoot>
				</SelectableList>
			</OverlayScrollbarsComponent>

			{notShownItemsCount > 0 && (
				<LoadMoreButton dataCy='load-more-button' color='tertiary' onClick={handleLoadMoreButtonClick}>
					<Text size='medium' bold>
						Load More...
					</Text>

					<CounterBox>
						<Text data-cy='conter' size='small' font='mono'>
							{notShownItemsCount}
						</Text>
					</CounterBox>
				</LoadMoreButton>
			)}
		</Container>
	);
}
