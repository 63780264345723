import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';

interface Props {
	goBack: () => void;
	onCancel: () => void;
}

export function SaveDialog({goBack, onCancel}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	return <Alert modalManagerRef={modalRef}>
		<Alert.Header>
			<Title>Your Changes Will Not Be Saved</Title>
		</Alert.Header>
		<Alert.Body>
			<span data-cy='save-dialog-alert'>
				You have made changes to one or more questions. If you go back, you will lose the changes. Are you sure you want to back?
			</span>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={handleClose}>CANCEL</Buttons.Text>
			<Buttons.Text onClick={goBack}>GO BACK</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}