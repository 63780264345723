import {SortModel} from './report-type';

export class Comparator {
	constructor(
		public sortModel: SortModel,
		public additionalSortModels: SortModel[],
	) {}

	public compare(left: any, right: any): number {
		if (this.sortModel.direction === 'None') {
			return 0;
		}
		const leftValue = this.sortModel.fieldValue
			? this.sortModel.fieldValue(left)
			: left[this.sortModel.fieldName];
		const rightValue = this.sortModel.fieldValue
			? this.sortModel.fieldValue(right)
			: right[this.sortModel.fieldName];
		if (!leftValue) {
			return this.sortModel.direction === 'Asc' ? -1 : 1;
		}
		if (!rightValue) {
			return this.sortModel.direction === 'Asc' ? 1 : -1;
		}
		let value;
		if (this.isNumeric(leftValue) && this.isNumeric(rightValue)) {
			value = this.intCompare(leftValue, rightValue);
		} else if (this.isDate(leftValue) && this.isDate(rightValue)) {
			value = this.dateCompare(new Date(leftValue), new Date(rightValue));
		} else {
			value = this.stringCompare(leftValue, rightValue);
		}

		if (this.additionalSortModels != null && this.additionalSortModels.length && value === 0) {
			for (let i = 0; i < this.additionalSortModels.length; i++) {
				// We have 'specialist' field, but what is coming from the backend is 'FirstName' and 'LastName',
				// its a temporary fix that made sorting with specialist 2 times, what is wrong
				const additionalModel = this.additionalSortModels[i].fieldName === 'FirstName' || this.additionalSortModels[i].fieldName === 'LastName'
					? {fieldName: 'specialist', direction: this.additionalSortModels[i].direction} : this.additionalSortModels[i];
				const comparator = new Comparator(additionalModel, []);
				value = comparator.compare(left, right);
				if (value !== 0) {
					break;
				}
			}
		}
		return value;
	}

	private isNumeric(value: string) {
		return /^-?\d+$/.test(value);
	}

	private isDate(val: any) {
		const d = new Date(val);
		return !isNaN(d.valueOf());
	}

	private dateCompare(left: Date, right: Date) {
		if (this.sortModel.direction === 'Asc') {
			return left.getTime() - right.getTime();
		}
		if (this.sortModel.direction === 'Desc') {
			return right.getTime() - left.getTime();
		}
		return 0;
	}

	private intCompare(left: any, right: any) {
		if (this.sortModel.direction === 'Asc') {
			return left - right;
		}
		if (this.sortModel.direction === 'Desc') {
			return right - left;
		}
		return 0;
	}

	private stringCompare(left: string, right: string) {
		let l = left;
		let r = right;
		if (this.sortModel.direction === 'Desc') {
			r = left;
			l = right;
		}
		r = r != null ? r.toLowerCase().trim() : r;
		l = l != null ? l.toLowerCase().trim() : l;

		if (l > r) {
			return 1;
		}
		if (l < r) {
			return -1;
		}
		return 0;
	}
}
