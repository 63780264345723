import {ComponentProps} from 'react';
import {ElementStatus, useFormElementContext} from '../../../../form';
import {SelectableList as BaseSelectableList} from '../../../../layout/selectable-list';

type SelectableListItemDraggableProps = ComponentProps<typeof BaseSelectableList.ItemDraggable>

export function SelectableListItemDraggable({
	children,
	value,
	...props
}: SelectableListItemDraggableProps) {

	const {status} = useFormElementContext();

	const commonProps = {
		disabled: status === ElementStatus.disabled,
		value,
		...props,
	};

	return <BaseSelectableList.ItemDraggable {...commonProps}>
		{children}
	</BaseSelectableList.ItemDraggable>;
}
