import {GridBox} from '@esgi/ui/layout';
import {TestName} from '../../../test-name';
import {AnswersInfo, GridProgressBar} from '../../../../../kit';
import {TestType} from '@esgi/main/libs/core';
import {TestInfo} from '../../../../types';
import {GridProgressBarItem} from '@esgi/ui';

type Props = {
	testInfo: TestInfo;
	progressBarItems: GridProgressBarItem[];
	correctAnswers: number;
};

export function TestNameWithBar({testInfo, progressBarItems, correctAnswers}: Props) {
	return (
		<TestName name={testInfo.name} testID={testInfo.id} type={TestType.Rubric}>
			<GridBox flow='column' align='center' justify='start' gap='3'>
				<AnswersInfo value={correctAnswers} maxValue={testInfo.totalPossible} />
				<GridProgressBar boxSize={40} items={progressBarItems} />
			</GridBox>
		</TestName>
	);
}
