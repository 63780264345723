import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupContent, ToggleGroupItem} from './index.styled';
import {tabItems} from './constants';
import {AssignmentStateTab} from '../../../layout/types';

type Props = {
	activeTab: AssignmentStateTab;
	setActiveTab: (tabId: AssignmentStateTab) => void;
	disabled: boolean;
};

export function Tabs({activeTab, setActiveTab, disabled}: Props) {
	return (
		<ToggleGroup.Root disabled={disabled} onValueChange={setActiveTab} value={activeTab} dataCy='assignments-list-tabs'>
			<ToggleGroupContent dataCy='assignments-list-tabs-content'>
				{tabItems.map(({value, label, labelDataCy}) => {
					return (
						<ToggleGroupItem key={value} value={value} dataCy={labelDataCy}>
							<Text size='medium' bold data-cy={`${labelDataCy}-label`}>
								{label}
							</Text>
						</ToggleGroupItem>
					);
				})}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
