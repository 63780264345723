import {Alert} from '@esgillc/ui-kit/modal';
import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {SpecialistGroupItem} from 'modules/forms/students-form/types';

interface Props {
	studentFullName: string,
	selectedSpecialistGroups: SpecialistGroupItem[],
	onCancel: () => void,
	onRemove: () => void,
}

export function RemoveFromSpecialistGroupsAlert({
	studentFullName,
	selectedSpecialistGroups,
	onRemove,
	onCancel,
}: Props) {

	const modalRef = useModal();
	const handelClose = useCloseModal(modalRef, onCancel);

	const onRemoveClicked = () => {
		onRemove();
		handelClose();
	};

	return <Alert modalManagerRef={modalRef}>
		<Alert.Header>
			<Title>Remove Student From All Specialist Groups?</Title>
		</Alert.Header>
		<Alert.Body className={styles.body}>
			<span data-cy='remove-from-specialist-groups-alert'>{studentFullName} student will be removed from:</span>
			<div data-cy='specialist-groups-list' className={styles.classesList}>
				{selectedSpecialistGroups.map(cl => <span key={cl.id} className={styles.classRow}>{cl.name}</span>)}
			</div>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={handelClose}>CANCEL</Buttons.Text>
			<Buttons.Text onClick={onRemoveClicked}>REMOVE</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}

