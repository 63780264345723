import {BehaviorSubject, tap, catchError, EMPTY} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectType, TestType} from '@esgi/core/enums';
import {mapToEnum} from 'shared/utils';
import {V2TeachersPagesReportsItemAnalysisController} from '@esgi/contracts/esgi';
import {Test} from '../types';
import {TestContentArea} from '@esgi/main/kits/common';

export class TestService extends BaseService {
	public loading$ = new BehaviorSubject(false);
	public tests$ = new BehaviorSubject<Test[]>([]);
	private readonly controller = new V2TeachersPagesReportsItemAnalysisController();

	public override dispose() {
		this.controller.dispose();
	}

	public getTests(subject: SubjectTab) {
		if (!subject) {
			return;
		}
		this.loading$.next(true);
		const {id: subjectID, type: subjectType} = subject;
		return this.controller.init({
			subjectID,
			subjectType: SubjectType[subjectType],
		}).pipe(
			tap(({value: {reportFilter}}) => {
				const value = reportFilter.tests
					.filter(({type}) => mapToEnum(type, TestType) === TestType.YN)
					.map(({id, name, contentAreaName, type}) => ({
						id,
						name,
						contentArea: contentAreaName as TestContentArea,
						type,
					}));
				this.tests$.next(value);
				this.loading$.next(false);
			}),
			catchError(() => {
				this.tests$.next([]);
				this.loading$.next(false);
				return EMPTY;
			}),
		);
	}

	public get(testId: Test['id']) {
		return this.tests$.value.find(({id}) => id === testId);
	}
}
