import {useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {Checkbox} from '@esgillc/ui-kit/control';
import {HelpLink, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {keyboardShortcutsHelpLink} from './constants';
import styles from './styles.module.less';

interface Props {
	onClose: () => void;
	onSave: (enableShortcuts: boolean) => void;
	isShortcutsEnabled: boolean;
}

export function KeyboardShortcutsModal({onClose, onSave, isShortcutsEnabled: isEnabled}: Props) {

	const [isShortcutsEnabled, setShortcutsEnabled] = useState<boolean>(isEnabled);
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	const onSubmit = () => {
		onSave(isShortcutsEnabled);
		handleClose();
	};

	return <Modal modalManagerRef={modalRef} onCatchError={handleClose}>
		<Modal.Header className={styles.header}>
			<Title className={styles.title}>Keyboard Shortcuts
				<HelpLink url={keyboardShortcutsHelpLink} title={'Help'}/>
			</Title>
		</Modal.Header>

		<Modal.Body className={styles.body}>
			<p className={styles.description}>Use the keyboard instead of the mouse to select correct or incorrect answers on a test screen.</p>
			<div data-cy={'checkbox'}>
				<Checkbox checked={isShortcutsEnabled}
				          onClick={() => setShortcutsEnabled(prev => !prev)}
				          className={styles.enableShortcuts}>
					Enable shortcuts.
				</Checkbox>
			</div>
			<p>Select “Y” or the Left Arrow if the student answered correctly.</p>
			<p>Select “N” or the Right Arrow if the student answered incorrectly.</p>
		</Modal.Body>

		<Modal.Footer>
			<Buttons.Gray onClick={handleClose}>Cancel</Buttons.Gray>
			<Buttons.Contained onClick={onSubmit}>Save</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
