import {merge, Observable, Subject} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';
import {BroadcastEventManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {SchoolYearChangedEvent} from 'modules/school-year';
import {hierarchyAggregationPattern} from '../../core/hierarchy-events';
import {DataModel} from '../models/models';

export default class DataService extends BaseService {
	public data: Readonly<DataModel> = null;
	public data$: Observable<DataModel>;

	private readonly broadcastBus = new BroadcastEventManager();
	private readonly controller: string = 'student-explorer';

	private initialData$: Observable<DataModel>;
	private dataStorage$: Subject<DataModel> = new Subject<DataModel>();

	constructor() {
		super();
		this.initialData$ = this.getData$().pipe(tap(data => {
			this.data = data;
			this.dataStorage$.next(data);
		}), shareReplay());

		this.data$ = merge(this.initialData$, this.dataStorage$);

		this.broadcastBus.aggregate({
			SchoolYearChangedEvent,
			...hierarchyAggregationPattern,
		}).subscribe((aggregation) => {
			this.refreshData().subscribe();
		});
	}

	public refreshData(): Observable<DataModel> {
		return this.getData$().pipe(tap(r => {
			this.data = r;
			this.dataStorage$.next(r);
		}));
	}

	private getData$(): Observable<DataModel> {
		return this.httpClient.ESGIApi.get<DataModel>(this.controller, 'get-data').asObservable();
	}

	public destroy() {
		super.destroy();
		this.broadcastBus.destroy();
	}
}
