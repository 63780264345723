import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const DeltaWrapper = styled(GridBox, {
	gap: 6,
	gridAutoFlow: 'column',
	alignItems: 'center',
});

export const DeltaIndicatorBox = styled(FlexBox, {
	all: 'unset',
	alignItems: 'center',
	paddingTop: 1,
	paddingBottom: 1,
	paddingLeft: 4,
	paddingRight: 4,
	borderRadius: 2,

	[`& > ${Text}`]: {
		color: '$vivid',
	},

	variants: {
		variant: {
			success: {
				backgroundColor: '$positive',

				[`& ~ ${Text}`]: {
					color: '$positive',
				},
			},

			error: {
				backgroundColor: '$negativeVivid',

				[`& ~ ${Text}`]: {
					color: '$negativeVivid',
				},
			},

			neutral: {
				backgroundColor: '$muted',

				[`& ~ ${Text}`]: {
					color: '$lowContrast',
				},
			},
		},
	},
});
