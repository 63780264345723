import {styled} from '@esgi/ui/theme';
import {animated} from 'react-spring';

export const PanelAnimatedDiv = styled(animated.div, {
	backgroundColor: '$background',
	height: '100%',
	position: 'absolute',
	width: '100%',
	top: 0,
	zIndex: 501, // drawer zIndex is 500

	variants: {
		hidden: {
			true: {
				visibility: 'hidden',
			},
		},
	},
});
