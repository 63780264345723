import {districtAdminRoutes} from './district-admin-routes';
import {teacherRoutes} from './teacher-routes';

export * from './enums';
export * from './routes-map';
export * from './use-design-version';
export * from './selected-values-storage';

export const routes = {
	teacher: teacherRoutes,
	districtAdmin: districtAdminRoutes,
} as const;
