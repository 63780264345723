import {useCallback, useEffect, useState} from 'react';
import {Drawer} from '@esgi/ui/layout';
import {useService, useServiceFactory} from '@esgi/core/service';
import {Test} from '../../types';
import {QuestionBankContext} from '../../hooks';
import {QuestionBankService} from '../../services';
import {QuestionBankFilters} from '../question-bank-filters';
import {QuestionBankContent} from '../question-bank-content';
import {StyledSectionContainer} from './index.styled';
import {
	AddStandardsDrawer,
	StandardsService,
	useStandardsDrawerState,
	OptionItem,
	mappedGradeLevels,
} from '@esgi/main/features/standards-drawer';
import {ScreenSize} from '@esgi/main/kits/common';
import {DictionariesContentAreaController} from '@esgi/contracts/esgi';

interface Props {
	test?: Test;
	onClose: () => void;
	screenSize: ScreenSize;
}

export function QuestionBankDrawer({test, onClose, screenSize}: Props) {
	const contentAreaController = new DictionariesContentAreaController();
	const [contentAreas, setContentAreas] = useState<OptionItem[]>([]);

	const [gradeLevels, setGradeLevels] = useState<OptionItem[]>(mappedGradeLevels);

	const standardsService = useService(StandardsService);
	const service = useServiceFactory<QuestionBankService>(
		() => new QuestionBankService(standardsService)
	);

	const {
		showAddStandardsDrawer,
		showAddStandardsDrawerToggle,
		selectedStandardsFilter,
		selectedStandardsIDs,
		selectedGradeLevelIDs,
		selectedContentAreaIDs,
		selectedStandards,
		selectedStandardType,
		isContentAreasLoading,
	} = useStandardsDrawerState({service: standardsService, contentAreas, setGradeLevels});

	const [showFiltersPanel, setShowFiltersPanel] = useState(false);

	const showFiltersPanelToggle = useCallback(
		() => setShowFiltersPanel((prev) => !prev),
		[]
	);

	const isSmallScreen = screenSize === ScreenSize.Small;
	const isPortraitTablet = screenSize === ScreenSize.PortraitTablet;
	const isTablet = screenSize === ScreenSize.Tablet;

	const anySmallScreen = isTablet || isPortraitTablet || isSmallScreen;

	useEffect(() => {
		if (!test) {
			onClose();
			return;
		}

		service.loadTest(test);
	}, [service, test, onClose]);

	const getContentAreas = () => {
		return contentAreaController.all().subscribe(({contentAreas: recievedContentAreas}) => {
			setContentAreas(
				recievedContentAreas.map(({id, name}) => ({
					value: id,
					label: name,
				}))
			);
		});
	};

	useEffect(() => {
		getContentAreas();

		return () => {
			if (service.isBeingClosedNaturally) {
				return;
			}

			service.forceClose();
		};
	}, []);

	return (
		<Drawer show width='100%'>
			<QuestionBankContext.Provider value={service}>
				<StyledSectionContainer>
					<QuestionBankFilters
						service={service}
						contentAreas={contentAreas}
						selectedStandardsFilter={selectedStandardsFilter}
						selectedStandardsIDs={selectedStandardsIDs}
						selectedGradeLevelIDs={selectedGradeLevelIDs}
						selectedContentAreaIDs={selectedContentAreaIDs}
						selectedStandards={selectedStandards}
						showAddStandardsDrawerToggle={showAddStandardsDrawerToggle}
						isPanelOpened={showFiltersPanel}
						togglePanelOpened={showFiltersPanelToggle}
						isSmallScreen={anySmallScreen}
						isAnyFilterSelected={service.isAnyFilterSelected}
						onFiltersReset={service.resetFilters}
						isContentAreasLoading={isContentAreasLoading}
					/>
					<QuestionBankContent isSmallScreen={anySmallScreen} selectedStandardType={selectedStandardType} />
				</StyledSectionContainer>
			</QuestionBankContext.Provider>
			{showAddStandardsDrawer && (
				<AddStandardsDrawer
					service={standardsService}
					onClose={showAddStandardsDrawerToggle}
					isSmallScreen={anySmallScreen}
					contentAreas={contentAreas}
					gradeLevels={gradeLevels}
					setGradeLevels={setGradeLevels}
				/>
			)}
		</Drawer>
	);
}