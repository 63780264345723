import React, {createRef, ReactNode, RefObject} from 'react';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {isIOS, isSafari, join} from '@esgillc/ui-kit/utils';
import {Loader} from '@esgi/deprecated/ui-kit/loader';
import {NextColumnButton, scrollToElement} from '@esgi/deprecated/ui-kit/matrix';
import Matrix from '@esgi/deprecated/ui-kit/matrix/matrix';
import {PrevColumnButton} from '@esgi/deprecated/ui-kit/matrix/prev-column-button';
import {PrevRowButton} from '@esgi/deprecated/ui-kit/matrix/prev-row-button';
import {RowBodyRenderer, RowData} from '@esgi/deprecated/ui-kit/matrix/types';
import {Modal, ModalBody, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import {Criteria, Description, Level, RubricAnswer} from '../../types';
import TestingService from '../../testing-service';
import {getInfo, isDescriptionSelected, validateCriteria} from '../../utils';
import BottomPanel from './components/bottom-panel/bottom-panel';
import CriteriaView from './components/criteria/criteria';
import DescriptionView from './components/description/description';
import LevelView from './components/level/level';
import {ColumnWidth, RowHeight} from './utils';
import {CloseButton} from '@esgi/deprecated/react';
import styles from './test.screen.module.less';
import {LostConnectionAlert} from '../../../../kit/lost-connection-alert';

interface Props {
	testingService: TestingService;
	afterTestEnded: () => void;
	onCancel: () => void;
}

class State {
	hoveredLevel: number;
	hoveredCriteria: number;
	answers: RubricAnswer[] = [];
	showValidation: boolean = false;
	showLoader: boolean = false;
	showConnectionLostAlert = false;
}

export default class RubricBody extends React.PureComponent<Props, State> {
	public state = new State();
	private readonly isIOSDevice = isIOS() || isSafari();
	private modalManagerRef: ModalManagerRef = createRef();
	private bodyRef: RefObject<HTMLDivElement> = createRef();
	private tableHeaderRef: RefObject<HTMLTableSectionElement> = createRef();
	private matrixRef: RefObject<Matrix<Level, Criteria, Description>> = createRef();
	private criteriaToRef: Map<number, CriteriaView> = new Map<number, CriteriaView>();
	private onDestroy$: Subject<void> = new Subject();

	private get testModel() {
		return this.props.testingService.testModel;
	}

	public componentDidMount() {
		this.props.testingService.answers
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((answers) => this.setState({answers}));

		this.props.testingService.onLostConnection
			.pipe(
				takeUntil(this.onDestroy$),
				tap(() => this.setState({showConnectionLostAlert: true})),
			).subscribe();
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}

	public render() {
		const testModel = this.props.testingService.testModel;
		return (
			<>
				<Modal
					className={styles.modal}
					containerClassName={styles.modalContainer}
					modalManagerRef={this.modalManagerRef}
				>
					<Loader show={this.state.showLoader}/>
					<ModalHeader className={styles.modalHeader}>
						<Title className={styles.modalTitle}>{this.props.testingService.studentName}</Title>
						<CloseButton className={styles.closeIcon} onClicked={() => this.cancelTest()}/>
					</ModalHeader>
					<ModalBody className={styles.modalBody}>
						<div className={styles.body}>
							<div className={styles.header}>
								<span className={styles.testName}>{this.testModel.rubricName}</span>:
								<span className={styles.description}>{this.testModel.description}</span>
							</div>
							<div className={styles.matrixContainer} ref={this.bodyRef}>
								<Matrix
									ref={this.matrixRef}
									maxWidth={this.calcWidth}
									maxHeight={this.calcHeight}
									className={{
										body: styles.matrix,
										tableContainer: styles.tableContainer,
									}}
									renderHeader={this.renderHeader}
									renderRow={this.renderRow}
									columnHeaderOptions={{
										cells: testModel.levelModels,
										cellRenderer: this.renderLevel,
									}}
									rowHeaderOptions={{
										cells: testModel.criteriaModels,
										cellRenderer: this.renderCriteria,
									}}
									cellsOptions={{
										cells: testModel.descriptionModels,
										cellRenderer: this.renderDescription,
										cellGetter: (source, col, row) => source.find(d => d.levelID === col.id && d.criteriaID === row.id),
									}}
								>
									<BottomPanel
										testingService={this.props.testingService}
										maxWidth={this.calcWidth}
										submitted={this.endTest}
										canceled={this.cancelTest}
										onShowValidation={() => {
											const incorrectAnswer = this.state.answers.find(a => a.score === undefined);
											if (!incorrectAnswer) {
												return;
											}
											const ref = this.criteriaToRef.get(incorrectAnswer.criteriaID);
											if (ref && ref.boxRef.current && this.matrixRef.current?.osInstance) {
												scrollToElement(ref.boxRef?.current, this.matrixRef.current.osInstance, 92, 195);
											}
											this.setState({showValidation: true});
										}}
									/>
								</Matrix>
							</div>
						</div>
					</ModalBody>
				</Modal>
				{this.renderLostConnectionAlert()}
			</>
		);
	}

	private renderHeader = (_, headerRenderer) => {
		return <thead ref={this.tableHeaderRef}>
		<tr key={0}>
			<th className={styles.prevActions}>
				<div>
					<div className={styles.prevColumn}>
						<PrevColumnButton columnWidth={ColumnWidth}/>
					</div>
					<div className={styles.prevRow}>
						<PrevRowButton rowHeight={RowHeight}/>
					</div>
				</div>
			</th>
			{headerRenderer()}
			<th className={styles.scoreHeader}>
				<NextColumnButton className={styles.nextColumnButton} columnWidth={ColumnWidth}/>
			</th>
		</tr>
		</thead>;
	};

	private renderRow = (data: RowData<Criteria, Description>, rowRenderer: RowBodyRenderer) => {
		let score = 0;
		const answer = this.state.answers.find(a => a.criteriaID === data.row.id);
		if (answer) {
			score = answer.score;
		}
		const isFirst = data.rowIndex === 0;
		const isLast = data.rowIndex === (this.testModel.criteriaModels.length - 1);

		return <tr key={data.row?.id}>
			{rowRenderer()}
			<th className={styles.scoreCell}>
				<div className={join(isFirst && styles.first, isLast && styles.last)}>
					{isFirst && <div className={styles.scoreColumnTitle}>
						Scores
					</div>}
					<div className={styles.score}>
						{score}
					</div>
				</div>
			</th>
		</tr>;
	};

	private renderLevel = (level: Level, index: number): ReactNode => {
		return <th key={level.id}>
			<LevelView level={level} highest={index === 0} hovered={this.state.hoveredLevel === level.id}/>
		</th>;
	};

	private renderCriteria = (criteria: Criteria) => {
		const isInvalid = this.state.showValidation && !validateCriteria(criteria.id, this.testModel, this.state.answers);
		const selectedLevel = getInfo(this.testModel, this.state.answers).byCriteria(criteria.id).level;

		return <th key={criteria.id}>
			<CriteriaView ref={(r) => this.criteriaToRef.set(criteria.id, r)}
			              criteria={criteria}
			              invalid={isInvalid}
			              levelName={selectedLevel?.name}
			              hovered={this.state.hoveredCriteria === criteria.id}
			              note={this.state.answers.find(a => a.criteriaID === criteria.id)?.notes}
			              onNoteChanged={(value) => this.props.testingService.updateNote(criteria.id, value)}/>
		</th>;
	};

	private renderDescription = (description: Description) => {
		const {level, criteria} = getInfo(this.testModel, this.state.answers).byDescription(description.id);
		return <td>
			<DescriptionView description={description}
			                 onMouseEnter={() => this.setState({
				                 hoveredLevel: description.levelID,
				                 hoveredCriteria: description.criteriaID,
			                 })}
			                 levelName={level.name}
			                 criteriaName={criteria.name}
			                 selected={isDescriptionSelected(this.state.answers, this.testModel, description.id)}
			                 onClicked={() => this.props.testingService.selectCard(description.id)}
			                 onMouseLeave={() => this.setState({
				                 hoveredLevel: 0,
				                 hoveredCriteria: 0,
			                 })}/>
		</td>;
	};

	private renderLostConnectionAlert = () => {
		if (!this.state.showConnectionLostAlert) {
			return;
		}
		return (
			<LostConnectionAlert
				onAbort={() => this.cancelTest()}
				onRetry={() => {
					this.endTest();
					this.setState({showConnectionLostAlert: false});
				}}
			/>
		);
	};

	private calcWidth = (): number => {
		const {width} = document.body.getBoundingClientRect();
		const colWidth = 190;
		const widthWithPadding = width - 195 - 96 - 8;
		let maxAvailableColumns = Math.floor(widthWithPadding / colWidth);
		const testColumnsCount = this.testModel.levelModels.length;
		if (testColumnsCount <= maxAvailableColumns) {
			maxAvailableColumns = testColumnsCount;
		}
		return maxAvailableColumns * colWidth + 195 + 96 + 8;
	};

	private calcHeight = (): number => {
		if (!this.bodyRef.current) {
			return 1;
		}
		const body = this.bodyRef.current;
		let {height} = body.getBoundingClientRect();
		height -= 100 + 10; // Bottom Panel's height + padding;
		const rowHeight = 150;
		const paddingBottom = 10;
		const tableHeaderHeight = this.tableHeaderRef.current?.clientHeight || 92;

		if (this.isIOSDevice) {
			return (this.state.answers.length * rowHeight) + tableHeaderHeight + paddingBottom;
		}

		const heightWithNoHeader = height - tableHeaderHeight - 35;
		let maxAvailableRows = Math.floor(heightWithNoHeader / rowHeight);
		const testRowCount = this.testModel.criteriaModels.length;
		if (testRowCount <= maxAvailableRows) {
			maxAvailableRows = testRowCount;
		}
		return maxAvailableRows * (rowHeight) + tableHeaderHeight + paddingBottom;
	};

	private endTest = () => {
		this.setState({showLoader: true}, () => this.props.testingService.endTest().pipe(
			takeUntil(this.onDestroy$),
			tap(() => this.setState({showLoader: false})),
		).subscribe(() => this.modalManagerRef.current.close(this.props.afterTestEnded)));
	};

	private cancelTest = () => {
		this.setState({showLoader: true}, () => {
			this.props.testingService.cancelTesting()
				.pipe(
					takeUntil(this.onDestroy$),
					tap(() => this.setState({showLoader: false})),
				).subscribe(() => this.modalManagerRef.current.close(this.props.onCancel));
		});
	};
}
