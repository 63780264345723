import React from 'react';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {IFormControlValidatorResult, Validators} from '@esgi/deprecated/elements';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';

export class State {
	value: string = '';
	valid: boolean = true;
	touched: boolean = false;
	editMode: boolean = false;
	validatorRes: string = '';
}

class Props extends SharedProps<State> {
	editable?: boolean = false;
}

export class NameField extends SharedComponent<State, Props> {
	private readonly maxLength = 35;
	private inputRef: HTMLTextAreaElement;

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if (prevState.editMode !== this.state.editMode) {
			if (this.state.editMode) {
				this.inputRef.focus();
			}
		}
	}

	validate(value): IFormControlValidatorResult {
		const rqResult = Validators.requriedValidator(value);
		if (!rqResult.valid) {
			return rqResult;
		}

		const lvResult = Validators.maxLengthValidator(value, this.maxLength);
		if (!lvResult.valid) {
			return lvResult;
		}

		return {valid: true, message: ''};
	}
	get value() {
		let val = this.state.value;
		if(val.length > 22) {
			return this.state.value.slice(0, 22) + '\n' + this.state.value.slice(22);
		} else {
			return val;
		}
	}

	private handleInput(e): void {
		let value: string = e.target.value;
		const validationResult = this.validate(value);
		value = value.split('\n').reduce((a, b) => a+b);
		let caret = e.target.selectionStart;
		if(caret === 23) {
			caret+=1;
		}
		setTimeout(() => this.inputRef.setSelectionRange(caret, caret), 10);
		this.setState({
			value: value,
			valid: validationResult.valid,
			validatorRes: validationResult.message,
		});
	}

	private static handleKey(e): void {
		if(e.charCode === 13) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	render(): JSX.Element {
		return <div className='name-field-container'>
                                <textarea className={`name-field ${this.state.valid ? '' : 'invalid'}`}
                                          value={this.value}
                                          cols={22}
                                          ref={r => this.inputRef = r}
                                          onInput={e => this.handleInput(e)}
                                          rows={this.state.value.length > 22 ? 2 : 1}
                                          onKeyPressCapture={e =>NameField.handleKey(e)}
                                          maxLength={34}/>
			{this.renderValidation()}
		</div>;
	}

	renderValidation(): JSX.Element | null {
		if (!this.state.valid && this.state.validatorRes) {
			return <ValidationTooltip element={this.inputRef}
			                          placement={'right'}
			                          container='body'>
				{this.state.validatorRes}
			</ValidationTooltip>;
		}
	}
}