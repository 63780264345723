import {styled} from '@esgi/ui/theme';
import {Item, ToggleGroupItemProps as ToggleGroupItemRadixProps} from '@radix-ui/react-toggle-group';
import {ComponentPropsWithoutRef, MouseEvent, forwardRef, useCallback, useEffect} from 'react';
import {CSS} from '@stitches/react';
import {Cheap} from '../../../../cheap';
import {Text} from '@esgi/ui/typography';
import {useToggleGroupContext} from '../../context';
import {Skeletonable} from '@esgi/ui';
import {SkeletonStylesWithBorders} from '../../../../skeleton';

type ToggleGroupItemProps = Omit<ComponentPropsWithoutRef<'button'>, 'color'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** This value is shown if item is active or hovered. */
	valueDescription?: string;

	/** Prop for applying disabled styles. Use in pair with prop "disabled". Default value is true. */
	applyDisabledStyles?: boolean;
} & ToggleGroupItemRadixProps & Skeletonable

export const ToggleGroupItem = forwardRef<HTMLButtonElement, ToggleGroupItemProps>(
	(
		{dataCy, valueDescription, onClick, onMouseEnter, onMouseLeave, applyDisabledStyles = true, ...props},
		forwaredRef,
	) => {
		const {setValueDescriptionOnHover, setValueDescriptionSelected, selectedValue, setSelectedValue, setHoveredValue} =
			useToggleGroupContext();

		useEffect(() => {
			if (selectedValue === props.value) {
				setValueDescriptionSelected?.(valueDescription);
			}
		}, []);

		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				const dataState = event.currentTarget.getAttribute('data-state');
				const isItemSelected = dataState === 'on';

				setValueDescriptionSelected(isItemSelected ? undefined : valueDescription);
				setSelectedValue(isItemSelected ? undefined : props.value);

				onClick?.(event);
			},
			[valueDescription, onClick, props.value, setSelectedValue, setValueDescriptionSelected],
		);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				setValueDescriptionOnHover(valueDescription ?? '');
				setHoveredValue(props.value);

				onMouseEnter?.(event);
			},
			[onMouseEnter, valueDescription, setHoveredValue, props.value, setValueDescriptionOnHover],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				setHoveredValue(undefined);
				setValueDescriptionOnHover(undefined);

				onMouseLeave?.(event);
			},
			[onMouseLeave, setHoveredValue, setValueDescriptionOnHover],
		);

		return (
			<ToggleItem
				data-cy={dataCy ?? 'ui-kit-toggle-group-item'}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				applyDisabledStyles={applyDisabledStyles}
				ref={forwaredRef}
				{...props}
			/>
		);
	},
);

const ToggleItem = styled(Item, {
	all: 'unset',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 13,
	paddingRight: 13,
	borderWidth: 1,
	borderStyle: 'solid',
	borderRadius: 5,
	position: 'relative',
	boxSizing: 'border-box',
	userSelect: 'none',
	transition: 'background-color .3s, border-color .3s',
	cursor: 'pointer',
	outline: 'none',
	appearance: 'none',
	margin: 0,
	overflow: 'visible',
	backgroundColor: 'transparent',
	borderColor: 'transparent',

	'&:hover:not([data-disabled])': {
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',

		[`& > ${Text}`]: {
			color: '$secondary',
		},
	},

	'&[data-state=on]': {
		backgroundColor: '$primaryBackground',
		borderColor: '$primaryMuted',

		[`& > ${Text}`]: {
			color: '$primary',
		},
	},

	'&[data-state=off] + [data-state=off]': {
		'&::before': {
			content: '',
			display: 'block',
			background: '$border',
			position: 'absolute',
			width: 1,
			height: 12,
			left: -2,
		},

		'&[data-orientation=vertical]::before': {
			width: 12,
			height: 1,
			top: -2,
			left: 'unset',
		},
	},

	variants: {
		applyDisabledStyles: {
			true: {
				'&[data-disabled]': {
					cursor: 'default',

					[`& ${Text}`]: {
						color: '$mild',
					},

					[`& ${Cheap}`]: {
						backgroundColor: '$background',
					},
				},

				'&[data-state=on]': {
					'&[data-disabled]': {
						backgroundColor: '$background',
						borderColor: '$mild',
						[`& ${Text}`]: {
							color: '$mild',
						},
					},
				},
			},
		},

		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});
