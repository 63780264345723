import {TestHistoryService} from 'modules/reports/test-history/services/test-history-service';
import {ReportAllStudents} from 'modules/reports/test-history/models';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import { TestType } from '@esgi/core/enums';

interface Props {
	service: TestHistoryService;
	reportData: ReportAllStudents;
}

export function AllStudentsReportView({service, reportData}: Props) {
	const selectedTest = useBehaviorSubject(service.selectedTest);
	const selectedClass = useBehaviorSubject(service.selectedClass);
	const selectedGroup = useBehaviorSubject(service.selectedGroup);
	const students = useBehaviorSubject(service.students);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.allStudentsReport}>
				<h4>{selectedTest.length > 0 ? selectedTest[0].name : ''}</h4>
				<div>
					{selectedClass.length > 0 &&
						<p><b>Class:</b> {selectedClass[0].name || ''}</p>
					}
					{selectedGroup.length > 0 &&
						<p><b>Group:</b> {selectedGroup[0].name || ''}</p>
					}
					<p><b>Test Name:</b> {selectedTest.length > 0 ? selectedTest[0].name : ''}</p>
					<div className={styles.spacer}></div>
					<p><b>Number of students:</b> {students.length - 2}</p>
					<p><b>Students tested:</b> {reportData.studentsTested}/{students.length - 2}</p>
					<div className={styles.spacer}></div>
					<p><b>Correct answers:</b> {reportData.correctAnswers}/{reportData.totalAnswers}</p>
					<p><b>Average score</b> {Math.round(reportData.averageScore)}%</p>
					<div className={styles.spacer}></div>
					<p><b>Total number of Test Session:</b> {reportData.testSessionsNumber}</p>
					<div className={styles.info}>To export session data, please use the "Download" button located at the top of this page. Once clicked, a menu will appear, allowing you to select specific export options.</div>
				</div>
			</div>
		</div>
	);
}
