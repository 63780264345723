import {PropsWithChildren, useState} from 'react';
import {join, tryCall} from '@esgillc/ui-kit/utils';
import {CollapseButton} from './collapse-button';
import {ErrorBoundary} from '@esgi/core/react';

type Props = {
	collapsable?: boolean;
	toggled?: (open: boolean) => void;
	className?: string;
} & PropsWithChildren;

export function SidePanel(props: Props) {
	const {collapsable, toggled, className, children} = props;
	const [open, setOpen] = useState(true);

	const clickHandler = () => {
		const value = !open;
		setOpen(value);
		tryCall(toggled, value);
	};

	return (
		<ErrorBoundary>
			<div className={join('left', !open && 'collapsed', className)}>
				{collapsable &&
					<CollapseButton
						open={open}
						clicked={clickHandler}
					/>
				}
				{children}
			</div>
		</ErrorBoundary>
	);
}
