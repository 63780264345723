import {
	ElementStatus,
	ErrorTooltip,
	Form,
	FormControl,
	FormElement,
	FormGroup,
	Validators,
	useForm,
} from '@esgi/ui/form';
import {Input} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {useStreamEffect} from '@esgi/ui';
import {useEffect} from 'react';

type Props = {
	initialGroupName?: string;
	restoredGroupName?: string;
	setIsFormValid: (value: boolean) => void;
	onFormTouched: (value: boolean) => void;
	setGroupName: (value: string) => void;
};

export function GroupNameForm({initialGroupName = '', restoredGroupName = '', setGroupName, setIsFormValid, onFormTouched}: Props) {
	const form = useForm(
		() =>
			new FormGroup({
				groupName: new FormControl(initialGroupName, {
					validators: [Validators.required()],
				}),
			}),
	);

	useEffect(() => {
		form.controls.groupName.value = initialGroupName;
	}, [initialGroupName]);

	useEffect(() => {
		form.controls.groupName.value = restoredGroupName;
		form.controls.groupName.status= ElementStatus.valid;
	}, [restoredGroupName]);

	useStreamEffect(form.controls.groupName.onChanged, ({currState}) => {
		setIsFormValid(currState.status === ElementStatus.valid);
		onFormTouched(currState.value !== initialGroupName);
		setGroupName(currState.value);
	});

	return (
		<Form controller={form}>
			<FormElement control={form.controls.groupName}>
				<Input.Base placeholder='Group Name' />

				<ErrorTooltip showOnError='required'>
					<Text size='xSmall' font='mono' color='negativeVivid'>
						Group name is empty
					</Text>
				</ErrorTooltip>
			</FormElement>
		</Form>
	);
}
