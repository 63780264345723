import {Observable} from 'rxjs';

export enum AsyncStatus {
	Idle = 'Idle',
	Pending = 'Pending',
	Succeeded = 'Succeeded',
	Failed = 'Failed',
}

export type IdleAsyncResponse = {
	asyncStatus: AsyncStatus.Idle;
	value: null;
	errors: null;
};

export type PendingAsyncResponse = {
	asyncStatus: AsyncStatus.Pending;
	value: null;
	errors: null;
};

export type SuccessAsyncResponse<T> = {
	asyncStatus: AsyncStatus.Succeeded;
	data: T;
};

export type FailedAsyncResponse = {
	asyncStatus: AsyncStatus.Failed;
	error: unknown;
	data: undefined;
};

export type AsyncResponse<T> = IdleAsyncResponse | PendingAsyncResponse | SuccessAsyncResponse<T> | FailedAsyncResponse;

export type CancellableRequestReturnType<F extends (...args: any[]) => Observable<unknown>> =
	ReturnType<F> extends Observable<infer U> ? U : any;
