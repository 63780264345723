import {Wrapper, Column, SelectWrapper, DomainsWrapper} from './index.styled';
import {Select} from '../select';
import {GridBox} from '@esgi/ui/layout';
import {Skeleton} from '../skeleton';
import {SelectableList} from '../selectable-list-simple';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {SkeletonRow} from '../skeleton/index.styled';
import {OptionItem} from '../../types';

type Props = {
	standardTypeOptions: OptionItem[],
	onStandardTypeChange: (id: string) => void,
	selectedStandardType: OptionItem['value'],
	onContentAreaSelect: (id: number) => void,
	selectedContentArea: OptionItem['value'],
	gradeLevels: OptionItem[],
	onGradeLevelSelect: (id: number) => void,
	selectedGradeLevel: OptionItem['value'],
	isContentAreasLoading: boolean
	isDomainsLoading: boolean
	contentAreas: OptionItem[],
	domainsList: OptionItem[],
	selectedDomain: OptionItem['value'],
	onDomainSelect: (id: number) => void,
}

export function SmallScreenContent({
	standardTypeOptions,
	onStandardTypeChange,
	selectedStandardType,
	contentAreas,
	onContentAreaSelect,
	selectedContentArea,
	gradeLevels,
	onGradeLevelSelect,
	selectedGradeLevel,
	isContentAreasLoading,
	isDomainsLoading,
	domainsList,
	selectedDomain,
	onDomainSelect,
}: Props) {
	return (
		<Wrapper>
			<Column>
				<SelectWrapper gap={3}>
					<Select
						placeholder='Type'
						items={standardTypeOptions}
						disabled={false}
						onValueChange={onStandardTypeChange}
						selectedValue={selectedStandardType}
						dataCy='Type-select'
					/>
					{isContentAreasLoading
						? <SkeletonRow variant='select'/>
						: <Select
							placeholder='Content Area'
							items={contentAreas}
							disabled={false}
							onValueChange={area => onContentAreaSelect(Number(area))}
							selectedValue={selectedContentArea}
							dataCy='content-area-select'
						/>
					}
					{isContentAreasLoading
						? <SkeletonRow variant='select'/>
						: <Select
							placeholder='Grade Level'
							items={gradeLevels}
							disabled={false}
							onValueChange={grade => onGradeLevelSelect(Number(grade))}
							selectedValue={selectedGradeLevel}
							dataCy='grade-level-select'
						/>
					}
				</SelectWrapper>
				<DomainsWrapper>
					<OverlayScrollbarsComponent style={{height: 'calc(100% + 0px)'}} defer options={{scrollbars: {autoHide: 'leave'}}}>
						{isDomainsLoading
							? <GridBox gap={2}><Skeleton variant='listItem'/></GridBox>
							: (
								<SelectableList
									items={domainsList}
									selected={selectedDomain}
									onClick={onDomainSelect}
								/>
							)}
					</OverlayScrollbarsComponent>
				</DomainsWrapper>
			</Column>
		</Wrapper>
	);
}