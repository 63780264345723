import {ExportApi, Helper, HistoryModel} from 'shared/modules/export/export';
import {Module, OldAlerts} from '@esgi/deprecated/knockout';
import React from 'react';
import AppHistory from '../../../tools/history/history';
import './export-history.less';

export class ExportHistoryOptions {
	showDeliveryStatus: boolean;
	historyContainer: ExportTrackingContainer;
}

export class ExportTrackingOptions {
	context;
	count: number;
	interval: number;
}

export class ExportTrackingContainer {
	rows: KnockoutObservableArray<HistoryModel>;
	trackingRows: KnockoutObservableArray<HistoryModel>;

	private readonly helpers: Helper;
	private readonly context;
	private server: ExportApi;

	private timerId = 0;
	private offset = 0;
	private count = 20;
	private interval = 5000;

	pendingRequest = ko.observable<boolean>();

	private progress = $.Deferred().resolve().promise();

	constructor(options: ExportTrackingOptions) {
		this.count = options.count;
		this.interval = options.interval;
		this.context = options.context;

		this.trackingRows = ko.observableArray([]);
		this.rows = ko.observableArray([]);
		this.helpers = new Helper();
		this.server = new ExportApi();

		this.trackingRows.subscribe((newVal) => {
			if (newVal.length === 0) {
				window.clearInterval(this.timerId);
				this.timerId = 0;
				return;
			}
			if (newVal.length !== 0 && this.timerId <= 0) {
				this.timerId = window.setInterval(() => {
					let listExportIds = this.trackingRows().map((item: HistoryModel) => {
						return item.id;
					});

					this.progress.then(() => {
						this.server.progress(listExportIds).then((updateRows) => {
							for (let i = 0; i < this.trackingRows().length; i++) {

								let row = this.helpers.getById<HistoryModel>(updateRows, 'id', this.trackingRows()[i].id);
								if (row != null) {
									if (row.status !== 'Processing' && row.status !== 'Idle') {
										this.trackingRows.remove(this.trackingRows()[i]);
										i--;
										let defRow = this.helpers.getById<HistoryModel>(this.rows(), 'id', row.id);
										let index = this.rows.indexOf(defRow);
										this.rows.splice(index, 1, row);
									}
								}
							}
						}).catch((err) => {
							console.error(err);
						});
					});


				}, this.interval);
			}
		});
	}

	loadingFull = false;

	addRow(row: HistoryModel) {
		this.rows.push(row);
		if (row.status === 'Processing' || row.status === 'Idle') {
			this.trackingRows.push(row);
		}
	}

	addTopRow(row: HistoryModel) {
		this.rows.unshift(row);
		if (row.status === 'Processing' || row.status === 'Idle') {
			this.trackingRows.push(row);
		}
	}

	removeAll() {
		this.loadingFull = false;
		this.trackingRows.removeAll();
		this.rows.removeAll();
		this.offset = 0;
	}

	loadFromServer(): JQueryPromise<any> {
		this.pendingRequest(true);
		const deferred = $.Deferred();
		this.server.history(this.context.userID, this.offset, this.count).then((rows) => {
			if (rows.length === 0) {
				this.loadingFull = true;
			} else {
				this.offset = this.offset + this.count;
				for (let i = 0; i < rows.length; i++) {
					this.addRow(rows[i]);
				}
			}
			deferred.resolve(rows);
		}).then(() => this.pendingRequest(false));
		return deferred.promise();
	}
}

export class ExportHistory extends Module {
	private context;
	private helpers: Helper;
	private server: ExportApi;

	constructor(context, options: ExportHistoryOptions = null) {
		super();
		this.context = context;
		this.helpers = new Helper();
		this.options = options;
		this.historyContainer = options.historyContainer;
		this.server = new ExportApi();
	}

	private options: ExportHistoryOptions;
	private historyContainer: ExportTrackingContainer;


	refresh() {
		this.historyContainer.removeAll();
		this.historyContainer.loadFromServer();
	}

	load(): JQueryPromise<any> {
		return this.historyContainer.loadFromServer();
	}

	view = {
		donwloadClicked: (row: HistoryModel) => {
			this.server.downloadLink(row.id).then((url) => {
				AppHistory.navigateOutside(url);
			}).catch((obj) => {
				OldAlerts.bsalert(obj.Message);
			});
		},
		showError: (row, event) => {
			let element = $(event.target);
			$(element).qtip({
				content: {
					text: row.ErrorMessage,
				},
				hide: {
					fixed: true,
					delay: 300,
				},
				position: {
					at: 'center right',
					my: 'center left',
				},
				show: {
					event: 'click',
					ready: true,
				},
				events: {
					hide: (event, api) => {
						api.destroy();
					},
				},
				style: {
					classes: 'qtip-red',
				},
			});
		},
	};

	addRow(row: HistoryModel) {
		this.historyContainer.addTopRow(row);
	}

	scrolled(data, event) {
		if (this.historyContainer.loadingFull) {
			return;
		}
		if (this.historyContainer.pendingRequest()) {
			return;
		}

		const elem = event.target;
		if (elem.scrollTop > (elem.scrollHeight - elem.offsetHeight - 100)) {
			this.historyContainer.loadFromServer();
		}
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		this.rootElement = rootElement;
		return $.Deferred().resolve(rootElement).promise();

	}

	events =
		{};

	template = () => <div className='export-history' data-bind='event: {scroll: scrolled },afterRender:true'>
		<table className='table table-striped table-hover table-bordered'>
			<thead>
			<tr>
				<th>Action</th>
				<th>Create Date</th>
				<th>File Name</th>
				<th data-bind='visible: options.showDeliveryStatus'>Delivery Status</th>
			</tr>
			</thead>
			<tbody data-bind='foreach: historyContainer.rows'>
			<tr>
				<td>
					<ko data-bind="if: status == 'Processing' || status == 'Idle'">
						Processing...
					</ko>
					<ko data-bind="if: status == 'Done'">
						<button className='btn btn-primary btn-xs'
						        data-bind='click:$parent.view.donwloadClicked'>Download
						</button>
					</ko>
					<ko data-bind="if: status == 'Error'">
						<span className='error' data-bind='click:$parent.view.showError'>Error</span>
					</ko>
				</td>
				<td data-bind="text:$parent.helpers.formatDate(createDate,'M/DD/YY h:mm A')"/>
				<td data-bind='text:fileName'/>
				<td style={{textAlign: 'left'}} data-bind='visible: $parent.options.showDeliveryStatus'>
					<ko data-bind="foreach: { data: deliveryStatuses, as: 'deliveryStatus' }">
						<div>
							<ko data-bind="foreach: {data: messages, as: 'message' }">
								<div data-bind='text: message'/>
							</ko>
						</div>
					</ko>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
}

