import React, {ReactNode} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Hint, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import styles from '../styles.module.less';

interface Props {
	author: string;
	lastEditDate: string;
	readonly: boolean;
	nameControl: FormControl;
	children?: ReactNode;
}

class State {
	testName: string = '';
}

export class TestNameField extends React.PureComponent<Props, State> {
	public render() {
		return <div className={styles.header}>
			<div className={join(styles.nameTitle, styles.requiredField, this.props.readonly && styles.readonly)}>
				Test Name:
			</div>
			<div className={join(!this.props.readonly && styles.nameContainer)}>
				{this.renderTestName()}
			</div>
			{this.props.children}
		</div>;
	}

	private renderTestName(): ReactNode {
		return <FormElement control={this.props.nameControl}>
			{!this.props.readonly ? <Input maxLength={65}/> :
				<span className={styles.nameReadonly}>{this.props.nameControl.value}</span>}
			<Hint className={styles.hint}>
				{(field) => <div className={styles.nameHint}>
					<span>{this.props.author} on {this.props.lastEditDate}</span>
					{!this.props.readonly && <span>
						{65 - field.value.length} characters left (65 max)
					</span>}
				</div>}
			</Hint>
			<OnErrorTooltip placement='right' showOnError='required'>
				Enter test name.
			</OnErrorTooltip>
		</FormElement>;
	}
}
