import {useMemo} from 'react';
import {VariantProps} from '@stitches/react';
import {PercentageCheap} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {AnswersStatistics} from '../../hooks/use-item-statistics';
import {useUser} from '@esgi/core/authentication';

type AnswerInfoItem = {
	title: string;
	value: number;
	valuePercentage: number;
	cheapType: NonNullable<VariantProps<typeof PercentageCheap>['colorType']>;
	cheapTextColor: NonNullable<VariantProps<typeof Text>['color']>;
};

export function useTooltipData({
	notTested,
	notTestedPercentage,
	incorrectPercentage,
	correctPercentage,
	correct,
	incorrect,
}: AnswersStatistics) {
	const currentUser = useUser();

	return useMemo(() => {
		const answerInfoItems: AnswerInfoItem[] = [
			{
				title: 'Not Tested:',
				value: notTested,
				valuePercentage: notTestedPercentage,
				cheapType: 'notTested',
				cheapTextColor: 'lowContrast',
			},
			{
				title: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect:',
				value: incorrect,
				valuePercentage: incorrectPercentage,
				cheapType: 'incorrect',
				cheapTextColor: 'vivid',
			},
			{
				title: currentUser?.testResultsCorrectVerbiage ?? 'Correct:',
				value: correct,
				valuePercentage: correctPercentage,
				cheapType: 'correct',
				cheapTextColor: 'vivid',
			},
		];

		return answerInfoItems;
	}, [notTested, notTestedPercentage, incorrect, incorrectPercentage, correct, correctPercentage, currentUser]);
}
