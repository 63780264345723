import {PropsWithChildren} from 'react';
import {useUser} from '@esgi/core/authentication';
import {ChartBlockModel} from '../../../../types';
import {TestModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/pie-charts/models/test-model';
import {PieChart} from './pie-chart';
import {Text} from '@esgi/ui/typography';
import {Result, ResultTitle, ResultData, Legend, LegendItem, LegendBox, LegendTitle, Data} from './card.styled';

export interface Props extends PropsWithChildren {
	testName: TestModel['name'];
	chartBlockModel: ChartBlockModel;
	isNotTested: boolean;
	withLegend: boolean;
	printInColor: boolean;
}

export function BaseCard({
	testName,
	chartBlockModel,
	isNotTested,
	withLegend,
	printInColor,
	children,
}: Props) {
	const currentUser = useUser();
	const {testResultsIncorrectVerbiage, testResultsCorrectVerbiage} = currentUser;

	return (
		<Result dataCy='result'>
			<ResultTitle data-cy='test-title'>
				<Text size='large'>{testName}</Text>
			</ResultTitle>
			<ResultData>
				<Data type='chart'>
					<PieChart
						data-cy='high-chart'
						chartBlockModel={chartBlockModel}
						printInColor={printInColor}
						isNotTested={isNotTested}
					/>
					{withLegend &&
						<Legend data-cy='chart-legend'>
							<LegendItem type='correct'>
								<LegendBox color={printInColor}>
									<Text data-cy='correct-percent' size='medium' bold color='currentColor'>{chartBlockModel.correctPercent}%</Text>
								</LegendBox>
								<LegendTitle>
									<Text data-cy='correcrt-title' size='xxSmall' font='mono'>{testResultsCorrectVerbiage.toLowerCase()}</Text>
								</LegendTitle>
							</LegendItem>
							<LegendItem type='incorrect'>
								<LegendBox>
									<Text data-cy='incorrect-percent' size='medium' bold color='currentColor'>{100 - chartBlockModel.correctPercent}%</Text>
								</LegendBox>
								<LegendTitle>
									<Text data-cy='incorrect-title' size='xxSmall' font='mono'>{testResultsIncorrectVerbiage.toLowerCase()}</Text>
								</LegendTitle>
							</LegendItem>
						</Legend>
					}
				</Data>
				<Data type='info'>
					{children}
					{isNotTested && <Text>Not tested yet</Text>}
				</Data>
			</ResultData>
		</Result>
	);
}
