import {ControlValue, FormControl, FormGroup} from '@esgillc/ui-kit/form';

export type TeacherFormType = FormGroup<{
	groupIDs: FormControl<number[]>,
	classIDs: FormControl<number[]>,
},
	ControlValue<{
		groupIDs: FormControl<number[]>,
		classIDs: FormControl<number[]>,
	}>>

export const createTeacherForm = ():
	TeacherFormType => new FormGroup({
	groupIDs: new FormControl([]),
	classIDs: new FormControl([]),
});
