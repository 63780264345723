import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {AddTests as AddTestsIcon} from '@esgi/ui';

export const AddTests = styled(AddTestsIcon, {
	marginRight: 6,
});

export const FiltersContainer = styled(GridBox, {
	width: '100%',
	height: 40,
	gap: 12,
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	'& > div:first-child': {
		minWidth: 232,
	},
});

export const TestsContainer = styled(GridBox, {
	gap: '$2',
	overflow: 'auto',
	gridAutoRows: 'min-content',
});
