import * as React from 'react';
import type {SVGProps} from 'react';

export function Setup(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fillRule='evenodd'
				d='M8.998 9a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2h-10Zm4 12a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2h-10Zm-4-6a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2h-3Zm11 0a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2h-3ZM21.998 11a1 1 0 0 0 2 0V9a1 1 0 0 0-2 0v2Zm-14 12a1 1 0 0 0 2 0v-2a1 1 0 0 0-2 0v2Zm7-6a1 1 0 0 0 2 0v-2a1 1 0 0 0-2 0v2Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
