import React, {Dispatch} from 'react';
import {FiltersContentBlock, RadioStyled, ReportFilterLabel} from '../../index.styled';
import {ReportFilterContainerStyled, TitleStyled} from './index.styled';
import {LabelStyled} from '../show-filter/index.styled';

interface Props {
	displayZeroIfNotTested: boolean;
	onClicked: Dispatch<boolean>
}

export function DisplayNotTestedFilter({displayZeroIfNotTested, onClicked}: Props) {
	return <ReportFilterContainerStyled data-cy='display-not-tested-container'>
			<TitleStyled>
				<ReportFilterLabel>Display not tested as:</ReportFilterLabel>
			</TitleStyled>
			<FiltersContentBlock>
				<RadioStyled
					data-cy='zero-option'
					value='Zero'
					onClick={() => onClicked(true)}
					checked={displayZeroIfNotTested}
				>
					<LabelStyled selected={displayZeroIfNotTested}>
						Zero
					</LabelStyled>
				</RadioStyled>
				<RadioStyled
					data-cy='nt-option'
					value='NT'
					onClick={() => onClicked(false)}
					checked={!displayZeroIfNotTested}
				>
					<LabelStyled selected={!displayZeroIfNotTested}>
						NT
					</LabelStyled>
				</RadioStyled>
			</FiltersContentBlock>
		</ReportFilterContainerStyled>;
}
