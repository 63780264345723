import {Fade} from '@esgillc/ui-kit/transition';
import {useState} from 'react';
import {useService} from '@esgi/core/service';
import {Text} from './kit';
import {Divider} from '../kit/divider';
import {Header, ModalDone, StudentCredentialsSpecialist, StudentCredentialsTeacher} from './components';
import {environment} from '@esgi/core/environments';
import {StudentCardsService} from 'pages/assignment-center/services/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import styles from './styles.module.less';
import {UserInfo, UserType} from '@esgi/core/authentication';

interface Props {
	onCreateClick: VoidFunction;
	onGoBack: VoidFunction;
	currentUser: Readonly<UserInfo> | null;
}

export function StudentCredentials({onCreateClick, onGoBack, currentUser}: Props) {
	const [initialized, setInitialized] = useState(false);
	const [countStudentsWithoutCredentials, setCountStudentsWithoutCredentials] = useState<number>(0);
	const [showMissingStudentsAlerts, setShowMissingStudentsAlerts] = useState(false);

	const credentialsService = useService(StudentCardsService);

	if (!currentUser) {
		return null;
	}

	const isTeacher = currentUser.userType === UserType.T;
	const isSpecialist = currentUser.userType === UserType.ISD || currentUser.userType === UserType.ISS;

	const getStudentCredentials = () => {
		if (isTeacher) {
			return (
				<StudentCredentialsTeacher
					setInitialized={setInitialized}
					onGoBack={onGoBack}
					setCountStudentsWithoutCredentials={setCountStudentsWithoutCredentials}
					setShowMissingStudentsAlerts={setShowMissingStudentsAlerts}
					credentialsService={credentialsService}
				/>
			);
		}

		if (isSpecialist) {
			return (
				<StudentCredentialsSpecialist
					setInitialized={setInitialized}
					onGoBack={onGoBack}
					setCountStudentsWithoutCredentials={setCountStudentsWithoutCredentials}
					setShowMissingStudentsAlerts={setShowMissingStudentsAlerts}
					credentialsService={credentialsService}
				/>
			);
		}

		throw new Error('<StudentCredentials />: Current user is not teacher or specialist');
	};

	return (
		<Fade
      in={initialized}
      duration='common'
    >
      <div className={styles.container}>
        <ServiceLoader
          trackingService={credentialsService}
          fullscreen
        />
        <Header
          onCreateClick={onCreateClick}
          goToPreviousPage={onGoBack}
        />
        <Divider className={styles.divider} />
        <div className={styles.content}>
          <div className={styles.instructions}>
            <Text
              size='14px'
              color='gray'
              as='p'
            >
              Students need credentials in order to access their assignments. Please enter credentials for all students
              that will be self assessing.
            </Text>
            <br />
            <Text
              size='14px'
              color='gray'
              as='p'
            >
              Students can access the assignments at{' '}
              <a
                href={environment.studentAppUrl || `${location.origin}/student`}
                target='_blank'
                rel='noopener noreferrer'
              >
                www.esgiselfassess.com
              </a>{' '}
              on their own device.
            </Text>
          </div>
					{getStudentCredentials()}
				</div>
			</div>
			{showMissingStudentsAlerts && (
        <ModalDone
          absentStudentsNumber={countStudentsWithoutCredentials}
          onClose={onGoBack}
        />
      )}
		</Fade>
	);
}
