import * as React from 'react';
import type {SVGProps} from 'react';

export function NotTestedAnswer(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect width='16' height='16' rx='4' transform='matrix(0 -1 -1 0 16 16)' fill='#none' stroke='#E0E0E0'/>
			<path d='M7.61946 10.5H6.57251L5.05817 7.27541V10.5H4V5.5H5.05817L6.56129 8.71085L6.56503 5.5H7.61946V10.5Z' fill='#333'/>
			<path d='M12 6.34332H10.6734V10.5H9.61894V6.34332H8.29052V5.5H12V6.34332Z' fill='#333'/>
		</svg>

	);
}
