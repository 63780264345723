import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {ComponentProps} from '@stitches/react';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {StyledAvatarFilterLayer} from './styled';

type AvatarFilterLayerProps = ComponentPropsWithoutRef<'div'> & {
	/** Avatar image front layer color */
	filterColor?: ComponentProps<typeof StyledAvatarFilterLayer>['filterColor'];
} & BaseComponentProps & Skeletonable

export const AvatarFilterLayer = forwardRef<HTMLDivElement, AvatarFilterLayerProps>(({
	dataCy = 'ui-kit-avatar-filter-layer',
	filterColor = 'neutral',
	...props
}: AvatarFilterLayerProps, ref) => {
	return <StyledAvatarFilterLayer data-cy={dataCy} filterColor={filterColor} ref={ref} {...props}/>;
});
