import {resolvedPromise} from '@esgi/deprecated/utils';
import {FormField, Validators} from '@esgi/deprecated/knockout';
import {FieldContainer} from '../../../kit/component';

export class GradeScaleForm extends FieldContainer<Array<string>> {
	buildField(initValue?): FormField<Array<string>> {
		const selectedValidator = Validators.Custom(field => {
			const value = field.value() as any;
			if (!value.length) {
				return resolvedPromise(false);
			}

			return resolvedPromise(true);
		}, _ => '<div>Please select at least one grade level.</div>');

		const f = this.createField<Array<string>>([], selectedValidator);
		f.value = ko.observableArray() as any;
		f.value.subscribe((v) => {
			f.validation.removeError();
			f.validation.validate();
		});
		f.validation.errorPosition = 'left';
		f.validation.validationMessageTitleUseHtml = true;

		f.validation.showValidation(true);
		f.validation.errorValidation(true);

		return f;
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		return super.afterRender(rootElement);
	}

	template = () => {
		return <div data-bind='var : {root: $data}'>
			<div data-bind='with: field'>
				<div className='form-group grade-container' data-bind='afterRender: true'>
					<div className='col col-sm-8 col-xs-12'>
						<div className='col col-xs-3'>
							<label className='lbl-chk'>
								<input type='checkbox' data-bind="checked: root.field.value, value: '17'"/>
								<text>TK</text>
							</label>
						</div>
						<div className='col col-xs-3'>
							<label className='lbl-chk'>
								<input type='checkbox' data-bind="checked: root.field.value, value: '3'"/>
								<text>Pre-K</text>
							</label>
						</div>
						<div className='col col-xs-6'>
							<label className='lbl-chk'>
								<input type='checkbox' data-bind="checked: root.field.value, value: '4'"/>
								<text>Kindergarten</text>
							</label>
						</div>
						<div className='col col-xs-3'>
							<label className='lbl-chk'>
								<input type='checkbox' data-bind="checked: root.field.value, value: '5'"/>
								<text>1st</text>
							</label>
						</div>
						<div className='col col-xs-3'>
							<label className='lbl-chk'>
								<input type='checkbox' data-bind="checked: root.field.value, value: '6'"/>
								<text>2nd</text>
							</label>
						</div>
						<div className='col col-xs-3'>
							<label className='lbl-chk'>
								<input type='checkbox' data-bind="checked: root.field.value, value: '7'"/>
								<text>3rd+</text>
							</label>
						</div>
						<div className='col col-xs-3'>
							<label className='lbl-chk'>
								<input type='checkbox' data-bind="checked: root.field.value, value: '100'"/>
								<text>Other</text>
							</label>
						</div>
					</div>
				</div>
				<div className='error-message visible-xs visible-sm hidden-md'>
					<span data-bind='text:validation.validationResults'/>
				</div>
			</div>
		</div>;
	};
}

export interface GradeModel {
	display: string,
	value: string
}
