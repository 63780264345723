import {SessionType, SubjectType} from '@esgi/core/enums';

export interface TestRequest {
	studentId: number;
	subjectId: number;
	subjectType: SubjectType;
	testID?: number;
}

export interface TestResponse {
	tests: Row[];
}

export interface SettingsResponse {
	groups: any[];
	markingPeriods: any[];
	showBaseline: boolean;
	specialistLastName: string;
	specialistTitle: string;
	subjects: any[];
}

export interface Row {
	id: number;
	iepGoalCompleted: boolean;
	iepGoalInProgress: boolean;
	name: string;
	iepGoal?: boolean;
}

export type SubjectTab = {
	id: number;
	name: string;
	subjectType: SubjectType;
	hidden: boolean;
	level: string;
	gradeLevels: number[];
}

export interface Student {
	id: number;
	firstName: string;
	lastName: string;
}
export interface Subject {
	id: number;
	name: string;
	subjectType: string;
}

export enum FilterStatus {
	All = 0,
	Progress = 1,
	Complete = 2,
}

export enum FilterSession {
	Date = 0,
	Period = 1,
	Range = 2,
}

export enum Step {
	Selector = 0,
	Goal = 1,
	Http = 2,
}

export interface ReportModel {
	latestSessionOnly: boolean;
	fromDate: Date;
	toDate: Date;
	selectedMarkingPeriod: number,
	specialist: any,
	subject: any,
	student: Student,
	groupName: string,
	testIDs: number[],
	showBaseline: boolean,
	districtId: number,
	selectedTests: any[],
	testId: number;
}

export interface InitRequest {
	latestSessionOnly: boolean;
	fromDate: string;
	toDate: string;
	studentId: number,
	testIDs: number[],
	showBaseline: boolean,
}

export interface InitResponse {
	tests: TestModel[];
	testResultsExist: boolean;
	dateRange: DateRange;
}

export interface TestModel {
	testID: number;
	name: string,
	totalPossible: number;
	iepGoal: IEPGoalModel;
	dateRange: DateRange;
	testResults: TestResults[];
}

export interface DateRange {
	from: string;
	to: string;
}

export interface IEPGoalModel {
	goal: string;
	status: string;
	benchmark: string;
}

export interface TestResults {
	isBaseline: boolean;
	date: string;
	score: number;
	sessionType: SessionType;
}

export interface TestChartModel {
	id: number;
	chart: string;
}