import {useEffect, useMemo, useState} from 'react';
import {uniqueId} from 'underscore';
import {Step} from 'shared/modules/student-roster-upload-tool/types';
import {ImageResponse, PreSelected, ProfileInitData} from '../types';
import {getUser, UserType} from 'libs/core/src/authentication';
import {EventBusManager} from '@esgillc/events';
import {StudentSaveAndAddEvent} from '../events';
import {StudentProfileService} from '../service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ISchoolBox} from 'modules/hierarchy/core/api';
import {useService} from '@esgi/core/service';
import {useLocation} from 'react-router-dom';

export interface StudentsFormProps {
	onClose: () => void;
	studentID?: number;
	preSelected?: PreSelected | null;
	hasStudentsInClass?: boolean
	hasStudentsInSpecialistsGroup?: boolean
	schools?: ISchoolBox;
}


export const useStudentsForm = (props: StudentsFormProps) => {
	const {studentID: id, preSelected, hasStudentsInClass, onClose, schools, hasStudentsInSpecialistsGroup} = props;
	const service = useService(StudentProfileService);
	const [isLimitAlertOpen, setLimitAlertOpen] = useState(false);
	const [isInitialized, setInitialized] = useState(false);
	const [profileKey, setProfileKey] = useState(uniqueId());
	const [selectedStep, setSelectedStep] = useState(Step.AddStudent);
	const [isStudentRosterUploadToolOpen, setStudentRosterUploadTool] = useState(false);
	const [isStudentProfileModalOpen, setStudentProfileModalOpen] = useState(false);
	const {pathname} = useLocation();
	const isStudentManager = pathname.includes('student-manager');
	const mode = useBehaviorSubject(service.mode);
	const studentID = useBehaviorSubject(service.studentID);
	const initData = useBehaviorSubject(service.initData);
	const uploadedFile = useBehaviorSubject(service.uploadedFile);
	const selectedSchoolId = useBehaviorSubject(service.selectedSchoolID);
	const user = getUser();
	const isPA = useMemo(() => {
		const userType = preSelected?.userType;
		return user.userType === UserType.PA || (user.userType === UserType.D && userType === UserType.PA);
	}, [preSelected]);
	const isTeacher = user.userType === UserType.T;
	const hasAccessToRosterUpload = useMemo(() => {
		return isPA || isTeacher;
	}, [isPA, preSelected]);

	const handleRoasterUpload = () => {
		onUploadRosterOpen(isPA ? Step.SchoolSelection : Step.UploadRoster);
	};

	const handleChangeSchoolId = (schoolId: number) => {
		service.setSelectedSchoolId(schoolId);
	};

	const onViewStudentProfile = (studentId) => {
		setStudentRosterUploadTool(false);

		service.init(studentId).subscribe({
			complete: () => {
				setStudentProfileModalOpen(true);
			},
		});
	};

	const onCloseStudentProfile = () => {
		if (!uploadedFile) {
			onClose();
		}
		setStudentRosterUploadTool(Boolean(uploadedFile));
		setStudentProfileModalOpen(false);
	};

	const onImageChanged = (data: ImageResponse) => {
		service.imageChanged(data);
	};

	const onUploadRosterOpen = (step: Step) => {
		setStudentRosterUploadTool(true);

		if (uploadedFile) {
			setSelectedStep(Step.ConflictResolution);
		} else {
			setSelectedStep(step);
		}
	};

	const openModalLogic = () => {
		if (hasAccessToRosterUpload) {
			// BL for PA
			if (isPA) {
				// if no students in group, show roster upload tool
				if (!hasStudentsInSpecialistsGroup) {
					if (isStudentManager) {
						setStudentProfileModalOpen(true);
					} else {
						setSelectedStep(Step.AddStudent);
						setStudentRosterUploadTool(true);
					}
					return;
				}
				// 	BL for T
			} else if (isTeacher) {
				// if no students in class, show roster upload tool
				if (!hasStudentsInClass && preSelected?.classID) {
					setStudentRosterUploadTool(true);
					setSelectedStep(Step.AddStudent);
					return;
				}
				setStudentProfileModalOpen(true);
			}
			// if student in class or in group, show Profile modal
			setStudentProfileModalOpen(true);
		} else {
			setStudentProfileModalOpen(true);
		}
	};
	const openStudentProfileModal = () => {
		setStudentRosterUploadTool(false);
		setStudentProfileModalOpen(true);
	};

	const init = (profileInitData: ProfileInitData) => {
		if (!id) {
			if (profileInitData?.limit?.limitExceed) {
				setLimitAlertOpen(true);
			} else {
				openModalLogic();
			}
		} else {
			setStudentProfileModalOpen(true);
		}
	};

	const initService = (cb: (data: ProfileInitData) => void) => {
		service.init(id, preSelected).subscribe({
			next: (data) => cb(data),
			complete: () => setInitialized(true),
		});
	};

	const onAddIndividually = () => {
		if (!isInitialized) {
			initService(() => openStudentProfileModal());
		} else {
			openStudentProfileModal();
		}

	};


	useEffect(() => {
		const eventBus = new EventBusManager();
		eventBus.subscribe(StudentSaveAndAddEvent, (student) => {
			if (student.isSaveAndAddOther) {
				if(student.studentLimitExceeded){
					setLimitAlertOpen(true);
				}else {
					setProfileKey(uniqueId());
				}
			}
		});
		return () => eventBus.destroy();
	}, []);

	useEffect(() => {
		initService(init);
	}, [profileKey]);

	return {
		...props,
		isInitialized,
		isStudentRosterUploadToolOpen,
		isStudentProfileModalOpen,
		isLimitAlertOpen,
		selectedSchoolId,
		selectedStep,
		profileKey,
		mode,
		initData,
		onAddIndividually,
		onUploadRosterOpen,
		onImageChanged,
		onViewStudentProfile,
		onCloseStudentProfile,
		setStudentRosterUploadTool,
		preSelected,
		service,
		handleRoasterUpload,
		handleChangeSchoolId,
		isPA,
		schools,
		studentID,
		uploadedFile,
	};
};
