export class TotalReportTemplate {
	public static render() {
		return (
			<div className='total-report-content' data-bind='afterRender: true'>
				<div className='total-report-settings'>
					<div>
						<ko data-bind="if: report.type == 'Class' || report.type == 'Group' || report.type == 'SpecialistGroup' || report.type == 'SchoolsGroup' || report.type == 'StudentsSchoolsGroup' || report.type == 'StudentsTeachersGroup' || report.type == 'TeachersGroup' || report.type == 'GroupOfSpecialists' || report.type == 'GroupOfSpecialistsStudents'">
							<ko data-bind="if: report.type == 'Class' || report.type == 'Group' || report.type == 'SpecialistGroup'">
								<div>
									<span data-bind="text: userTitleText() + ': '"></span>
									<span className='name' data-bind='text: report.args[0]'></span>
								</div>
							</ko>
							<ko data-bind="if: report.type == 'Class' ">
								<div>
									<span>Class: </span>
									<ko data-bind='if: classes() '>
										<ko data-bind='if: classes().length > 1 '>
											<select className='form-control class-dropdown' data-bind="options: classes, optionsText: 'name', value: selectedClass"></select>
										</ko>
										<ko data-bind='if: classes().length == 1 '>
											<span className='name' data-bind='text: selectedClass().name'></span>
										</ko>
									</ko>
								</div>
							</ko>
							<ko data-bind="if: report.type == 'Group' ">
								<div>
									<span>Group: </span>
									<ko data-bind='if: groups() '>
										<ko data-bind='if: groups().length > 1 '>
											<select className='form-control group-dropdown' data-bind="options: groups, optionsText: 'name', value: selectedGroup"></select>
										</ko>
										<ko data-bind='if: groups().length == 1 '>
											<span className='name' data-bind='text: selectedGroup().name'></span>
										</ko>
									</ko>
								</div>
							</ko>
							<ko data-bind="if: report.type == 'SchoolsGroup' || report.type == 'StudentsSchoolsGroup'">
								<div>
									<span>School Group: </span>
									<ko data-bind='if: schoolsGroup() '>
										<ko data-bind='if: schoolsGroup().length > 1 '>
											<select className='form-control group-dropdown'
															data-bind="options: schoolsGroup, optionsText: 'name', value: selectedSchoolsGroup"></select>
										</ko>
										<ko data-bind='if: schoolsGroup().length == 1 '>
											<span className='name' data-bind='text: selectedSchoolsGroup().name'></span>
										</ko>
									</ko>
								</div>
							</ko>
							<ko data-bind="if: report.type == 'TeachersGroup' || report.type == 'StudentsTeachersGroup'">
								<div>
									<span>Teacher Group: </span>
									<ko data-bind='if: teachersGroup() '>
										<ko data-bind='if: teachersGroup().length > 1 '>
											<select className='form-control group-dropdown' data-bind="options: teachersGroup, optionsText: 'name', value: selectedTeachersGroup"></select>
										</ko>
										<ko data-bind='if: teachersGroup().length == 1 '>
											<span className='name' data-bind='text: selectedTeachersGroup().name'></span>
										</ko>
									</ko>
								</div>
							</ko>
							<ko data-bind="if: report.type == 'GroupOfSpecialists' || report.type == 'GroupOfSpecialistsStudents'">
								<div>
									<span>Specialist Group: </span>
									<ko data-bind='if: groupOfSpecialists() '>
										<ko data-bind='if: groupOfSpecialists().length > 1 '>
											<select className='form-control group-dropdown' data-bind="options: groupOfSpecialists, optionsText: 'name', value: selectedGroupOfSpecialists"></select>
										</ko>
										<ko data-bind='if: groupOfSpecialists().length == 1 '>
											<span className='name' data-bind='text: selectedGroupOfSpecialists().name'></span>
										</ko>
									</ko>
								</div>
							</ko>
							<ko data-bind="if: report.type == 'SpecialistGroup' ">
								<div>
									<span>Group: </span>
									<ko data-bind='if: specialistGroups() '>
										<ko data-bind='if: specialistGroups().length > 1 '>
											<select className='form-control group-dropdown' data-bind="options: specialistGroups, optionsText: 'name', value: selectedSpecialistGroup"></select>
										</ko>
										<ko data-bind='if: specialistGroups().length == 1 '>
											<span className='name' data-bind='text: selectedSpecialistGroup().name'></span>
										</ko>
									</ko>
								</div>
							</ko>
						</ko>
						<div>
							<div>Subject Tab: </div>
							<select className='form-control subject-dropdown' data-bind="options: subjects, optionsText: 'name', value: selectedSubject"></select>
						</div>
						<ko data-bind='if: gradeLevels() && isGradeEnabled'>
							<div>
								<span>Grade: </span>
								<select id='selectedGradeLevel' className='form-control' data-bind="options: gradeLevels, optionsText: 'name', value: selectedGradeLevel"></select>
							</div>
						</ko>
						<ko data-bind="if: report.type == 'School' || report.type == 'SchoolSpecialist' || report.type == 'District' || report.type == 'DistrictSpecialist'">
							<ko data-bind='if: tracks().length == 1 '>
								<div>
									<span>Track: </span>
									<span className='name' data-bind='text: selectedTrack().name'></span>
								</div>
							</ko>
							<ko data-bind='if: tracks().length > 1 '>
								<div>
									<span>Track: </span>
									<select id='selectedTrack' className='form-control' data-bind="options: tracks, optionsText: 'name', value: selectedTrack"></select>
								</div>
							</ko>
						</ko>
						<div>
							<ko data-bind='foreach: report.table.infoRows '>
								<ko data-bind='foreach: cells '>
									<div className='report-help-text' data-bind='text: value'></div>
								</ko>
							</ko>
						</div>
					</div>
					<div data-bind='visible: rows().length > 0'>
						<div>
							Settings:
						</div>
						<div className='settings-cell'>
							<div>Display results as:</div>
							<div>
								<input type='radio' id='displayResultsAsScore' name='displayResultsOption' value='Score' className='theme' data-bind='checked: displayResultsOption'/>
								<label htmlFor='displayResultsAsScore'>Score</label>
								<input type='radio' id='displayResultsAsPercent' name='displayResultsOption' value='Percent' className='theme' data-bind='checked: displayResultsOption'/>
								<label htmlFor='displayResultsAsPercent'>Percent</label>
							</div>
						</div>
						<div className='settings-cell'>
							<div>Display not tested as:</div>
							<div>
								<input id='zeroDisplayZeroIfNotTestedOption' type='radio' className='theme' name='displayZeroIfNotTestedOptions' value='Zero' data-bind='checked: displayZeroIfNotTestedOption'/>
								<label htmlFor='zeroDisplayZeroIfNotTestedOption'>Zero</label>
								<input id='ntDisplayZeroIfNotTestedOption' type='radio' name='displayZeroIfNotTestedOptions' className='theme' value='NT' data-bind='checked: displayZeroIfNotTestedOption'/>
								<label htmlFor='ntDisplayZeroIfNotTestedOption'>NT</label>
							</div>
						</div>
						<div className='settings-cell scores'>
							<div>Results:</div>
								<div>
									<input id='carryScoresForwardOption' type='checkbox' data-bind='checked: carryScoresForward' className='theme'/>
									<label htmlFor='carryScoresForwardOption'><span>Carry forward</span>
										<a
											className='inline-tooltip'
											href='https://support.esgisoftware.com/hc/en-us/articles/209159526-Class-Totals-Report'
											target='_blank' data-bind="bsTooltip: {placement:'right'}" title='Help' rel='noreferrer'
										>
											<sup className='fa fa-question-circle' aria-hidden='true'></sup>
										</a>
									</label>
								</div>
							</div>
							<div className='settings-cell'>
								<div>Marking Period:</div>
								<div>
									<input id='currentMarkingPeriodOption' type='radio' name='markingPeriodOptions' className='theme' value='Current' data-bind='checked: markingPeriod'/>
									<label htmlFor='currentMarkingPeriodOption'>Current</label>
									<input id='allMarkingPeriodOption' type='radio' name='markingPeriodOptions' value='All' className='theme' data-bind='checked: markingPeriod'/>
									<label htmlFor='allMarkingPeriodOption'>All</label>
								</div>
							</div>
					</div>
				</div>
				<div className='total-report-holder'>
					<div className='total-report-body' data-bind='visible: rows().length > 0'>
						<div className='left-panel'>
							<div className='left-top-panel'>
								<table>
									<tr className='first-header'>
										<ko data-bind="if: report.type == 'Class' || report.type == 'Group' || report.type == 'SpecialistGroup' ">
											<th className='entity-info-header' data-bind='attr: { colSpan: report.valueToSkip }'>
													<span className='header-title'>Students</span>
											</th>
										</ko>
										<ko data-bind="if: report.type == 'School' || report.type == 'SchoolSpecialist'">
											<th className='entity-info-header' data-bind='attr: { colSpan: report.valueToSkip }'></th>
										</ko>
										<ko data-bind="if: report.type == 'District' || report.type == 'DistrictSpecialist' || report.type == 'DistrictPreassess' || report.type == 'GroupOfSpecialists' || report.type == 'SchoolsGroup' || report.type == 'TeachersGroup'">
											<th className='entity-info-header'></th>
										</ko>
										<ko data-bind="if:report.type == 'TeachersGroup'">
											<th className='entity-info-header' colSpan={2}></th>
										</ko>
										<ko data-bind="if: report.type == 'StudentsSchool' || report.type == 'SchoolSpecialistStudents'">
											<th className='entity-info-header' colSpan={2}>
												<span className='sub-header' data-bind='text: report.args[0]'></span>
											</th>
											<th className='entity-info-header' data-bind='attr: { colSpan: report.valueToSkip - 2 }'>
												<span className='sub-header' data-bind='text: report.args[1]'></span>
											</th>
										</ko>
										<ko data-bind="if: report.type == 'StudentsDistrict' || report.type == 'DistrictSpecialistStudents' || report.type == 'DistrictPreassessStudents' || report.type == 'GroupOfSpecialistsStudents' || report.type == 'StudentsTeachersGroup' || report.type == 'StudentsSchoolsGroup'">
											<th className='entity-info-header' data-bind='attr: { colSpan: report.valueToSkip }'>
												<span className='sub-header' data-bind='text: report.args[0]'></span>
											</th>
										</ko>
									</tr>
									<tr className='total-table-subheader'>
										<ko data-bind='foreach: report.table.sortRow().cells '>
											<ko data-bind='if: $index() < $parent.report.valueToSkip '>
												<td className='entity-info-subheader subheader-title' data-bind='click: $parent.view.sort.bind($data, $index() - $parent.report.valueToSkip)'>
													<div>
														<span data-bind='text: value'></span>
														<i data-bind='attr: {class: $parent.getCellSortClass($index() - $parent.report.valueToSkip)}'></i>
													</div>
												</td>
											</ko>
										</ko>
									</tr>
								</table>
							</div>
							<div className='left-bottom-panel'>
								<div>
									<table className='table table-hover table-striped'>
										<tbody>
											<ko data-bind='foreach: rows '>
												<tr className='data-row-left'>
													<ko data-bind='foreach: cells '>
														<ko data-bind='if: $index() < $parents[1].report.valueToSkip '>
															<td className='entity-info-cell' data-bind='text:value'></td>
														</ko>
													</ko>
												</tr>
											</ko>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className='right-panel'>
							<div className='right-top-panel'>
								<table data-bind="style: {'width': totalWidth() + 'px'}">
									<tr className='test-names'>
										<ko data-bind='foreach: tests '>
											<td data-bind="attr: {class: 'test-name' + ($index() == $root.tests().length - 1 ? ' last' : '')}, style: {'width': $parent.testWidth() + 'px'}">
												<ko data-bind='if: id != 0 '>
													<div>
														<span data-bind='text:name'></span>
														<ko data-bind='if: $parent.view.showOutOf($data)'>
															<span className='test-questionscount' data-bind="text:'(out of ' + questionsCount + ')'"></span>
														</ko>
													</div>
												</ko>
												<ko data-bind='if: id == 0 '>
													<div className='avg-column'>
														<div className='avg-title'>Avg</div>
														<div className='avg-text'>
															<div data-bind="style: {'max-width': ($parent.testWidth() - 30) + 'px'}, text: $root.testResultsCorrectVerbiage" className='avg-correct-text'>Correct</div>
															<div className='avg-percent-text'>(%)</div>
														</div>
													</div>
													<a
														id='avgTooltip'
														data-bind="bsTooltip: {placement:'left',className:'avg-tooltip'}"
														title='Help'
														href='https://support.esgisoftware.com/hc/en-us/articles/209159646-Average-Correct'
														target='_blank'
														rel='noreferrer'
													>
														<sup className='fa fa-question-circle' aria-hidden='true'></sup>
													</a>
												</ko>
											</td>
										</ko>
										<td className='cell4scroll' />
									</tr>
									<tr className='total-table-subheader'>
										<ko data-bind='foreach: tests '>
											<td data-bind="attr: {class: 'test-sort' + ($index() == $root.tests().length - 1 ? ' last' : '')}, style: {'width': ($parent.testWidth() + ($index() == 0 ? 2 : 0)) + 'px'}">
												<table>
													<tr>
														<ko data-bind='if: $parent.isCellShown($index() * ($parent.report.maxMarkingPeriods + 1)) '>
															<td data-bind="click: $parent.view.sort.bind($data, $index() * ($parent.report.maxMarkingPeriods + 1)), attr: {class: 'sort-period b-column' + ($parent.sortModel().columnIndex == $index() * ($parent.report.maxMarkingPeriods + 1) + $parent.report.valueToSkip ? ' sorted' : '')}, style: {width: $parent.cellWidth($index() * ($parent.report.maxMarkingPeriods + 1), id) + 'px'}">
																<div style={{position: 'relative'}}>
																	<span>B</span>
																	<i data-bind='attr: {class: $parent.getCellSortClass($index() * ($parent.report.maxMarkingPeriods + 1))}'></i>
																</div>
															</td>
														</ko>
														<ko data-bind='foreach: Array.from(Array($parent.report.maxMarkingPeriods))'>
															<ko data-bind='if: $parents[1].isCellShown($parentContext.$index() * ($parents[1].report.maxMarkingPeriods + 1) + $index() + 1)'>
																<td data-bind="click: $parents[1].view.sort.bind($data, $parentContext.$index() * ($parents[1].report.maxMarkingPeriods + 1) + $index() + 1), attr: {class: 'sort-period' + ($parents[1].sortModel().columnIndex == $parentContext.$index() * ($parents[1].report.maxMarkingPeriods + 1) + $index() + 1 + $parents[1].report.valueToSkip ? ' sorted' : '')}, style: {width: $parents[1].cellWidth($parentContext.$index() * ($parents[1].report.maxMarkingPeriods + 1) + $index() + 1, $parentContext.$data.id) + 'px'}">
																	<div style={{position: 'relative'}}>
																		<span data-bind='text: ($index() + 1)'></span>
																		<i data-bind='attr: {class: $parents[1].getCellSortClass($parentContext.$index() * ($parents[1].report.maxMarkingPeriods + 1) + $index() + 1)}'></i>
																	</div>
																</td>
															</ko>
														</ko>
													</tr>
												</table>
											</td>
										</ko>
										<td className='cell4scroll' />
									</tr>
								</table>
							</div>
							<div className='right-bottom-panel' data-bind='event: { scroll: scrolled }'>
								<table className='table table-hover table-striped' data-bind="style: {'width': totalWidth() + 'px'}">
									<tbody>
										<ko data-bind='foreach: rows '>
											<tr className='data-row-right'>
												<ko data-bind='foreach: cells '>
													<ko data-bind='if: $index() >= $parents[1].report.valueToSkip '>
														<td data-bind="text: $parents[1].cellRender($data),attr: { class: 'result-info-cell' + (($index() - $parents[1].report.valueToSkip) >= ($parents[1].tests().length - 1) * ($parents[1].report.maxMarkingPeriods + 1) ? ' average' : '') }, css: { 'left-border': ($index() - $parents[1].report.valueToSkip) == 0, 'right-border': $parents[1].isLeftBorder($index(), $parent)}"></td>
													</ko>
												</ko>
											</tr>
										</ko>
									</tbody>
								</table>
						</div>
					</div>
					</div>
					<ko data-bind='if: rows().length === 0'>
						<div className='empty-text-container'>
							<span className='emptyReportText'>There are no students in this group or class</span>
						</div>
					</ko>
				</div>
			</div>
		);
	}
}
