import {Text} from '@esgi/ui/typography';
import {CheapBox} from './index.styled';

type Props = {
	outerDataCy: string;
	innerDataCy: string;
};

export function NoTestedCheap({outerDataCy, innerDataCy}: Props) {
	return (
		<CheapBox dataCy={outerDataCy}>
			<Text data-cy={innerDataCy} size='small' font='mono' bold color='lowContrast'>
				NT
			</Text>
		</CheapBox>
	);
}
