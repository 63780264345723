import {File, Upload, UserList} from '@esgi/ui/icons';
import {Drawer} from '@esgi/main/features/teacher/home';
import {
	DrawerBody,
	TemplateTranscriptText,
	InfoBlock,
	InfoBlockItem,
	InfoBlockItemsWrapper,
	InfoBlocsWrapper,
	InputHidden,
} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {AddStudentsViaFileService} from '../../add-students-via-file-service';
import {ChangeEvent, useCallback, useState} from 'react';
import {fileAdditionalInfo} from './constants';

type Props = {
	dataService: AddStudentsViaFileService;
	classId: string;
};

export function UploadRosterStep({dataService, classId}: Props) {
	const [fileExplorer, setFileExplorer] = useState<HTMLInputElement | null>(null);

	const handleUploadFile = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			dataService.onFileUpload(event, classId);
		},
		[dataService, classId],
	);

	return (
		<>
			<Drawer.Header Icon={UserList} sectionName='Add Students' />
			<DrawerBody>
				<Drawer.ContentBlock title='New Import'>
					<Button data-cy='upload-a-file-with-students-button' color='secondary' onClick={() => fileExplorer?.click()}>
						<Upload />
						<Text size='medium' bold>
							Upload a file
						</Text>
					</Button>
					<InputHidden
						type='file'
						ref={setFileExplorer}
						onChange={handleUploadFile}
						onClick={(event: React.MouseEvent<HTMLInputElement>) => ((event.target as HTMLInputElement).value = null)}
						hidden
					/>
				</Drawer.ContentBlock>

				<InfoBlocsWrapper>
					{fileAdditionalInfo.map(({items, sectionTitle}, index) => (
						<InfoBlock key={index} dataCy='upload-student-list-info-block'>
							<Text size='small' data-cy='upload-student-list-info-block-title'>{sectionTitle}</Text>

							<InfoBlockItemsWrapper align='center'>
								{items.map((itemName, index) => (
									<InfoBlockItem key={index} dataCy='upload-student-list-info-block-item'>
										<Text size='xSmall' font='mono' bold color='neutral40'>
											{itemName}
										</Text>
									</InfoBlockItem>
								))}
							</InfoBlockItemsWrapper>
						</InfoBlock>
					))}
				</InfoBlocsWrapper>
				<Drawer.ContentBlock title='Use Our Template'>
					<Button data-cy='get-template-button' color='secondary' onClick={dataService.downloadTemplate.bind(dataService)}>
						<File />
						<Text size='medium' bold>
							Get the template
						</Text>
					</Button>
					<TemplateTranscriptText size='small' color='neutral40' data-cy='template-transcript-text'>
						Fill out this template to ensure your student information is in the correct format.
					</TemplateTranscriptText>
				</Drawer.ContentBlock>
			</DrawerBody>
		</>
	);
}
