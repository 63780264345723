import moment from 'moment';
import {DateCorrector} from './date-corrector';
import {TrackDateRow} from './types';

export function dateServer (uiDate: Date): string {
	return moment.utc(uiDate).format('YYYY-MM-DD');
}

export function setIndex (index: number, isStart: boolean): number {
	let correctedIndex = index;
	if (index === 0) {
		if (!isStart) {
			correctedIndex+=1;
		}
	} else {
		if (isStart) {
			correctedIndex*=2;
		} else {
			correctedIndex*=2;
			correctedIndex++;
		}
	}
	return correctedIndex;
}

export function correctDates (dates: TrackDateRow[], index: number, isFrom: boolean): TrackDateRow[] {
	const corrector = new DateCorrector();
	return corrector.correctDates(dates, index, isFrom);
}
