import {Buttons} from '@esgillc/ui-kit/button';
import {useState} from 'react';
import IepDatesFormModal from '../../components/iep-dates';
import {IEPGoalFormService} from '../../services';
import styles from '../styles.module.less';
import {join} from '@esgillc/ui-kit/utils';

type Props = {
	service: IEPGoalFormService;
}

export function IEPDatesButton ({service}: Props) {
	const [isShowed, setIsShowed] = useState(false);

	return (
		<>
			<Buttons.Link
				className={join(styles.link, styles.datesLink)}
				onClick={() => setIsShowed(true)}
			>
				IEP Dates
			</Buttons.Link>
			{isShowed &&
				<IepDatesFormModal
					service={service}
					onClose={() => setIsShowed(false)}
				/>
			}
		</>
	);
}
