import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import React, {Suspense} from 'react';
import {TestType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {userStorage} from '@esgi/core/authentication';
import {SubjectModel} from '../../../../../services/subjects-service/models';
import MoveTestToSubject from '../../../move-test/move-test';
import styles from './context-menu.module.less';
import {TestInfo} from 'modules/assets/tests/rubric/common/models';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

class Props {
	testId: number;
	testName: string;
	testType: TestType;
	hierarchy: HierarchySnapshot;
	subject: SubjectModel;
	clickedOutside: () => any;
	canEdit: boolean;
	canRemove: boolean;
	testClosed: (totalPossible: number) => void;
	onTestInfoChanged: (testInfo: TestInfo) => void;
	removeTestFromSubject: () => void;
}

class State {
	testDetailsOpened: boolean;
	moveToSubjectOpened: boolean;
}

export default class ContextMenu extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();

	constructor(props: Props) {
		super(props);

		this.state = new State();
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	wrapperRef: any;

	private openTestDetails() {
		this.setState({testDetailsOpened: true});
	}

	render() {
		return <ul className={'dropdown-menu ' + styles.dropdownMenu} ref={this.setWrapperRef}>
			<li>
				<a href='#' onClick={() => this.openTestDetails()}>
					{this.props.canEdit ? 'Edit Test' : 'View Test'}
				</a>
				{this.renderTestDetails()}
			</li>
			<li>
				{this.props.canRemove &&
					<a href='#' onClick={() => this.props.removeTestFromSubject()}>Remove Test from Subject</a>
				}
			</li>
			<li>
				{this.props.canRemove &&
					<a href='#' onClick={() => this.setState({moveToSubjectOpened: true})}>Move
						Test to Subject</a>
				}
				{this.renderMoveToSubject()}
			</li>
		</ul>;
	}

	private renderMoveToSubject() {
		if (this.state.moveToSubjectOpened) {
			return <MoveTestToSubject hierarchy={this.props.hierarchy}
			                          testId={this.props.testId}
			                          testName={this.props.testName}
			                          currentSubjectID={this.props.subject.id}
			                          currentSubjectName={this.props.subject.name}
			                          currentSubjectType={this.props.subject.type}
			                          close={() => this.setState({moveToSubjectOpened: false}, this.props.clickedOutside)}/>;
		}
	}

	private renderTestDetails() {
		if (this.state.testDetailsOpened) {
			if (this.props.testType !== TestType.Rubric) {
				return <Suspense fallback={<></>}>
					<TestDetails
						testID={this.props.testId}
						firstName={this.currentUser.firstName}
						lastName={this.currentUser.lastName}
						userID={this.currentUser.userID}
						close={(totalPossible: number) => {
							this.setState({testDetailsOpened: false}, () => this.props.testClosed(totalPossible));
						}}
						disableCopyTest={true}
					/>
				</Suspense>;
			} else {
				return <Suspense fallback={<></>}>
					<RubricDetails
						testID={this.props.testId}
						close={(testInfo: TestInfo) => {
							this.setState({testDetailsOpened: false}, () => this.props.clickedOutside());
							this.props.onTestInfoChanged(testInfo);
						}}
						disableCopy
					/>
				</Suspense>;
			}
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		if (!this.state.testDetailsOpened && !this.state.moveToSubjectOpened && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.props.clickedOutside();
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}
}
