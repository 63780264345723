import {Text} from '@esgi/ui/typography';
import {AssignmentState} from '../types';
import {StatusBox} from './index.styled';
import {statusText} from './constants';
import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';

type Props = BaseComponentProps &
	ComponentPropsWithoutRef<typeof StatusBox> & {
		state: AssignmentState;
		likeAbbreviation?: boolean;
	};

export const AssignmentStatus = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'assignments-assignment-status', css = {}, state, likeAbbreviation = false, ...props}, forwardedRef) => {
		if (state === AssignmentState.None) {
			return null;
		}

		const {full, abbreviation} = statusText[state];

		return (
			<StatusBox
				dataCy={dataCy}
				css={css}
				likeAbbreviation={likeAbbreviation}
				assignmentState={state}
				ref={forwardedRef}
				{...props}
			>
				<Text size='small' color='currentColor'>
					{likeAbbreviation ? abbreviation : full}
				</Text>
			</StatusBox>
		);
	},
);
