import {ClassTotalsService} from '../service';
import {useGroups, useClasses} from '@esgi/main/libs/store';
import {useCallback, useState} from 'react';
import {SelectableListItem} from '../service/types';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {isUndefined, useBehaviorSubject} from '@esgi/ui';
import {isNull} from 'underscore';
import {TotalReportType} from '@esgi/contracts/esgi/types/esgi.reports/enums/total-report-type';

export default function useReportState(service: ClassTotalsService) {
	const [{data: classes}] = useClasses();
	const [{data: groups}] = useGroups();
	const [selectedEntity, setSelectedEntity] = useState<SelectableListItem | null>(null);

	const [selected, setSelected] = useState<SingleSelectState>({
		classId: null,
		groupId: null,
		studentId: null,
		studentFromClassId: null,
		studentFromGroupId: null,
	});

	const onEntitySelect = useCallback(
		(state: SingleSelectState) => {
			setSelected(state);

			const {classId, groupId} = state;

			if (!isNull(classId)) {
				const selectedClass = classes.find(({id}) => id === classId);

				if (isUndefined(selectedClass)) {
					setSelectedEntity(null);
					return;
				}

				setSelectedEntity({
					value: String(selectedClass.id),
					label: selectedClass.name,
				});

				service.setReportId(selectedClass.id);
				service.setReportType(TotalReportType.Class);
			}

			if (!isNull(groupId)) {
				const selectedGroup = groups.find(({id}) => id === groupId);

				if (isUndefined(selectedGroup)) {
					setSelectedEntity(null);
					return;
				}

				setSelectedEntity({
					value: String(selectedGroup.id),
					label: selectedGroup.name,
				});

				service.setReportId(selectedGroup.id);
				service.setReportType(TotalReportType.Group);
			}
		},
		[classes, groups, service],
	);

	useCallback((value: boolean) => {
		service.setCarryForward(value);
	}, [service]);

	const isSubjectsLoaded = useBehaviorSubject(service.subjectService.loaded$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const selectedSubject = useBehaviorSubject(service.subject$);

	const selectedTests = useBehaviorSubject(service.tests$);

	return {
		selected,
		selectedEntity,
		subjects,
		isSubjectsLoaded,
		selectedSubject,
		selectedTests,
		onEntitySelect,
	};
}
