import {styled} from '@esgi/ui/theme';
import {HeaderRow as BaseHeaderRow} from '../../index.styled';
import {FlexBox} from '@esgi/ui/layout';

export const HeaderRow = styled(BaseHeaderRow, {
	padding: '12px 12px',
});

export const StudentIconBox = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: '24px',
	height: '24px',
	marginTop: '-2px',
	marginRight: '6px',
});
