export namespace TeacherFormEvents {
	export namespace Changed {
		export class Args {
			teacherID: number;
			firstName: string;
			lastName: string;
		}
	}
	export namespace Created {
		export class Args {
			id: number;
			firstName: string;
			lastName: string;
			schoolID: number;
			districtID: number;
			userID: number;
			title: string;
      groups: number[];
			defaultClass: ClassArgs;
		}

		export class ClassArgs {
			name: string;
			classID: number;
		}
	}
	export namespace Removed {
		export class Args {
			teacherID: number;
		}
	}
}

export function RemoveClickedEvent() {

}

export function SaveClickedEvent() {

}
