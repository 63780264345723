import React, {useState} from 'react';
import {SectionRow} from 'shared/modules/user-settings/components/section-row';
import {SectionWrapper} from 'shared/modules/user-settings/components/section-wrapper';
import TestResultVerbiageModal
	from './components/test-result-verbiage-modal';
import TestSessionResultsModal
	from './components/test-session-results-modal';

interface Props {
	correctVerbiage: string;
	incorrectVerbiage: string;
	onSaveTestResultVerbiage: (testResultsVerbiagesEnabled: boolean, correctVerbiage: string, incorrectVerbiage: string) => void;
	testResultsVerbiagesEnabled: boolean;
	showPieChartsResults: boolean;
	onSaveTestSessionResults: (showPieChartsResults: boolean) => void;
}

export default function TestSessionResultsSection(props: Props) {
	const [showTestResultVerbiageModal, setShowTestResultVerbiageModal] = useState<boolean>(false);
	const [showTestSessionResultsModal, setShowTestSessionResultsModal] = useState<boolean>(false);

	return (
		<div data-cy='test-session-results-section'>
			<SectionWrapper title={'TEST SESSION RESULTS'}>

				<div data-cy='test-result-verbiage'>
					<SectionRow title={'Test Result Verbiage'} onClick={() => setShowTestResultVerbiageModal(true)}/>
				</div>
				<div data-cy='test-session-results'>
					<SectionRow title={'Test Session Results'} onClick={() => setShowTestSessionResultsModal(true)}/>
				</div>
				{showTestResultVerbiageModal &&
					<div data-cy='test-result-verbiage-modal'>
						<TestResultVerbiageModal
							correctVerbiage={props.correctVerbiage}
							incorrectVerbiage={props.incorrectVerbiage}
							testResultsVerbiagesEnabled={props.testResultsVerbiagesEnabled}
							onClose={() => setShowTestResultVerbiageModal(false)}
							onSave={(testResultsVerbiagesEnabled, correctVerbiage, incorrectVerbiage) =>
								props.onSaveTestResultVerbiage(testResultsVerbiagesEnabled, correctVerbiage, incorrectVerbiage)}
						/>
					</div>
				}

				{showTestSessionResultsModal &&
					<div data-cy='test-session-results-modal'>
						<TestSessionResultsModal
							onClose={() => setShowTestSessionResultsModal(false)}
							showPieChartsResults={props.showPieChartsResults}
							onSave={(showPieChartsResults) => props.onSaveTestSessionResults(showPieChartsResults)}
						/>
					</div>
				}
			</SectionWrapper>
		</div>
	);
}