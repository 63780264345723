export function ActivationCodeFormTemplate() {
	return <div className='activation-code'>
		<ko data-bind='with: activationCode '>
			<div className='form-group'
			     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
				<div>
					<input id='activation-code' name='activation-code' type='text' className='form-control input-md'
					       data-bind='value: value' placeholder='Activation Code:' data-cy='activation-code-input'/>
					<ko data-bind='if: showSuccessValidation() && valid() '>
						<i className='fa fa-check form-control-feedback' data-cy='activation-code-validation-success'></i>
					</ko>
					<ko data-bind='if: showErrorValidation() && !valid() '>
						<i className='fa fa-close form-control-feedback' data-cy='activation-code-validation-error'></i>
					</ko>
					<ko data-bind='if: showValidationInProgress() '>
						<i className='fa form-control-feedback'>
							<img src='img/ajax-loader.gif'/>
						</i>
					</ko>
				</div>
			</div>
		</ko>
	</div>
}
