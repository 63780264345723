import {GridBox} from '@esgi/ui/layout';
import {CardFooter as CardFooterContainer} from '../../../components/components.styled';
import {PropsWithChildren} from 'react';
import {TwoSplitButtonsBox} from '../../../../../components/two-split-buttons-box';
import {selfAssessButtonWidth} from '../../constants';
import {StartTestButton} from '../../../../../components/start-test-button';
import {SelfAsses} from '@esgi/ui/icons';
import {SelfAssessButton} from '../../../../../components/self-assess-button';

type Props = PropsWithChildren<{
	showSelfAssessOption: boolean;
	selfAssessmentTestAllowed: boolean;
	onStartSelfAssessTestHandler?: VoidFunction;
	onStartTestHandler?: VoidFunction;
	disableActionButtons?: boolean;
}>;

export function CardFooter({
	children,
	showSelfAssessOption,
	selfAssessmentTestAllowed,
	onStartSelfAssessTestHandler,
	onStartTestHandler,
	disableActionButtons,
}: Props) {
	const withSelfAssess = showSelfAssessOption && selfAssessmentTestAllowed;

	return (
		<CardFooterContainer>
			<GridBox flow='column' gap='3' align='center'>
				{children}
			</GridBox>

			<TwoSplitButtonsBox>
				{withSelfAssess && (
					<SelfAssessButton
						onClick={onStartSelfAssessTestHandler}
						width={selfAssessButtonWidth}
						disabled={Boolean(disableActionButtons)}
					>
						<SelfAsses />
					</SelfAssessButton>
				)}
				<StartTestButton onClick={onStartTestHandler} disabled={Boolean(disableActionButtons)} />
			</TwoSplitButtonsBox>
		</CardFooterContainer>
	);
}
