import {RubricAnswer, RubricModel} from '../common/types';

export interface InitModel {
	answers: RubricAnswer[];
	studentName: string;
	rubric: RubricModel;
	session: string;
	cacheAvailable: boolean;
	sessionStatus: SessionStatus;
	summaryNotes: string;
}

export enum SessionStatus {
	None = -1,
	Normal = 0,
	Started = 1,
	Saved = 2,
	UserMoved = 3,
}

export interface ResultModel {
  testSessionID: number;
  summaryNotes: string;
	studentID: number;
	studentName: string;
	testModel: RubricModel;
	answers: RubricAnswer[];
}
