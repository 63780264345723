import * as React from 'react';
import type {SVGProps} from 'react';

export function BoxArrowUpRight(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M20.778 18.556A2.444 2.444 0 0 1 18.333 21h-4.889A2.444 2.444 0 0 1 11 18.556v-4.89a2.444 2.444 0 0 1 2.444-2.444h1.834a.611.611 0 0 1 0 1.222h-1.834c-.675 0-1.222.548-1.222 1.223v4.889c0 .675.547 1.222 1.222 1.222h4.89c.674 0 1.222-.547 1.222-1.222v-1.834a.611.611 0 0 1 1.222 0v1.834Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M15.457 15.679a.611.611 0 1 0 .864.864l4.457-4.457v2.192H22V10.61a.611.611 0 0 0-.611-.611h-3.667v1.222h2.191l-4.456 4.457Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
