import {DatePicker as DatePickerUI} from '@esgi/ui/controls';
import {inputWithTooltipError} from './hocs';
import {ComponentProps, forwardRef} from 'react';

type DatePickerInputProps = Omit<ComponentProps<typeof DatePickerUI.Input>, 'error'> & {
	error?: string | undefined;
};

const DataPickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(({error, ...props}, forwardedRef) => (
	<DatePickerUI.Input {...props} ref={forwardedRef} />
));

export const DatePicker = {
	...DatePickerUI,
	Input: inputWithTooltipError(DataPickerInput),
};
