import React from 'react';
import './component.less';

export class Props {
	color: string;
	selected: boolean;
	onClick: (color: string) => void;
}

export default class ColorButton extends React.PureComponent<Props> {
	colors: string[];

	render() {
		return <div className='grade-scale-entry-color-button'
					style={{backgroundColor: this.props.color}}
					onClick={() => this.props.onClick(this.props.color)}>
		</div>;
	}
}