import {Box} from '@esgi/ui/layout';
import {styled, keyframes} from '@esgi/ui/theme';

const shineLines = keyframes({
	to: {
		backgroundPositionX: '-20%',
	},
});

export const SkeletonBox = styled(Box, {
	backgroundColor: '$border',
	background: 'linear-gradient(100deg, $mild 40%, rgba(245, 245, 245, .5) 50%, $mild 60%) $border',
	backgroundSize: '200% 100%',
	backgroundPositionX: '180%',
	animation: `1.5s ${shineLines} ease infinite`,

	variants: {
		shape: {
			round: {
				borderRadius: '50%',
			},

			square: {
				borderRadius: 6,
			},
		},
	},
});
