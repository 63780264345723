import {
	EdgePosition,
	ImageModel,
	StyleDefinition,
	VertexPosition,
	VertexSize,
} from 'shared/modules/question-editor/models';
import {
	State as AutoTestCreatorEditorState,
} from 'shared/modules/question-editor/editors/auto-test-creator/auto-test-creator';
import {SubjectType} from '@esgi/core/enums';

export class Test {
	constructor(
		public name: string,
		public description: string,
		public questions: Question[],
		public color: string,
		public contentAreaID: number,
		public gradeLevels: number[],
		public creatorName: string,
		public creatorID: number,
		public isShared?: boolean,
		public testID?: number,
		public orderNum?: number,
		public copyToTeacher?: boolean,
		public testIDN?: string,
		public questionCount?: number,
		public is?: number,
		public isTemp?: boolean,
		public isDeleted?: boolean,
		public isStock?: boolean,
		public isPublic?: boolean,
		public stockSubjectID?: number,
		public imageQuestionID?: number,
		public copiedFromTestID?: number,
		public stateStandardID?: number,
	) {
	}
}

export class Question {
	constructor(
		public xml: string,
		public name: string,
		public directions: string,
		public isDefaultImage: boolean,
		public orderNum: number,
		public contentAreaID?: number,
		public testName?: string,
		public testType?: string,
		public questionID?: number,
		public testID?: number,
		public questionsCount?: number,
		public stateStandard?: string,
		public stateStandardID?: number,
		public totalPossible?: number,
		public pregenerated?: boolean,
		public images?: ImageModel[],
	) {
	}
}

export class PreviewState {
	constructor(
		public name: string,
		public tiles: TileState[],
		public author: string = '',
		public defaultImageId: string = '',
		public changed: boolean = false,
		public questionEditor: AutoTestCreatorEditorState = null,
		public shapeDefinitions: ShapeDefinitions = new ShapeDefinitions(),
	) {
	}
}

export class TileState {
	constructor(
		public question: Question = null,
		public id: string = Math.random().toString(36).substring(2, 9),
		public contextMenuOpened: boolean = false,
		public changed: boolean = false,
	) {
	}
}

export class ShapeDefinitions {
	public styles: StyleDefinition[] = [];
	public vertexSizes: VertexSize[] = [];
	public tempVertexSizes: VertexSize[] = [];
	public edgePositions: EdgePosition[] = [];
	public tempEdgePositions: EdgePosition[] = [];
	public vertexPositions: VertexPosition[] = [];
	public tempVertexPositions: VertexPosition[] = [];
}

export interface TopicTemplate {
	topicName: string;
	testName: string;
	description: string;
	directions: string;
	contentAreaName: string;
}

export interface GradeLevel {
	id: number,
	name: string,
	shortName: string,
}

export interface Dictionary {
	gradeLevels: GradeLevel[];
	contentAreas: ContentAreaItem[];
	topicTemplates: TopicTemplate[];
	topics: TopicItem[];
	fonts: FontItem[];
	defaultColors: { [key: number]: string };
}

export interface AutoTestCreatorInitResponse {
	contentAreas: ContentAreaItem[];
	questionAreaWidth: number;
	questionAreaHeight: number;
	colorHex: string;
}

export interface TopicItem {
	id: number,
	name: string,
}

export interface FontItem {
	displayName: string,
	systemName: string,
}

export interface QuestionItem {
	id: string;
	name: string;
}

export interface ContentAreaItem {
	id: number,
	name: string
}

export interface UpdateResult {
	tileID: string;
	name: string;
	xml: string;
	directions: string;
	stateStandardID: number;
	definitions: ShapeDefinitions;
	images: ImageModel[];
}

export interface EditQuestion {
	testName: string,
	tileID: string,
	orderNum: number,
	state: TileState[],
}

export interface GetBestSize {
	elements: string[],
	fontFamily: string,
	canvasHeight: number,
	canvasWidth: number
}

export interface GetXML {
	text: string,
	size: number,
	fontFamily: string,
	areaWidth: number,
	areaHeight: number
}

export interface DefaultColors {
	[key: number]: string;
}

export interface QuestionAreaSize {
	width: number,
	height: number
}

export interface SubjectTest {
	testID: number,
	addedAt: string
}

export interface Subject {
	id: number;
	name: string;
	subjectType: SubjectType;
	tests?: SubjectTest[];
}

export interface SerializeQuestionsProps {
	questions: QuestionItem[],
	directions: string,
	fontFamily: string,
	questionAreaSize: QuestionAreaSize
}



