export class PaymentTemplate {
	public static render() {
		return (
			<div className='quotepayment clearfix' data-bind='var: {root: $data}'>
				<div className='path'>
					<h1 className='title'>
						<span>Payment</span>
					</h1>
					<main>
						<div className='steps'>
							<div className='step'>
								<header className='active'>
									<span
										className='caption'
										data-bind='text: root.currentStep.title'
									></span>
								</header>
								<form>
									<fieldset>
										<div data-bind='module: {data: root.currentStep}'></div>
									</fieldset>
								</form>
							</div>
						</div>
					</main>
				</div>
			</div>
		);
	}
}
