import {useCallback, useRef, useState} from 'react';

import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {UnsavedChangesAlert} from './components/student-profile/components/unsaved-changes-alert';
import {ModalOwnerContainer} from './index.styled';
import {AddStudentProfile} from './components/student-profile/student-profile-only-add';

interface Props {
	onClose: VoidFunction,
}

export function StudentProfileDrawerRef(props: Props) {
	const drawerRef = useDrawerRef();
	const studentDrawerModalsOwner = useRef();

	const [isUnsavedChangesAlert, setUnsavedChangesAlert] = useState(false);

	const formTouchedRef = useRef<{
		updateTouchedStatus: () => boolean;
		isInitialized: boolean;
	}>();

	const onBeforeCloseDrawerCb = useCallback(() => {
		const isFormTouched = formTouchedRef.current.updateTouchedStatus();
		const isInitialized = formTouchedRef.current.isInitialized;

		if (isFormTouched && isInitialized) {
			setUnsavedChangesAlert(true);
		}

		return !(isFormTouched && isInitialized);
	}, [formTouchedRef]);

	const close = useDrawerClose(drawerRef, () => {
		onBeforeCloseDrawerCb();
		props.onClose();
	});

	const onAlertClose = useDrawerClose(drawerRef, () => {
		setUnsavedChangesAlert(false);
		props.onClose();
	});

	return (<>
		<Drawer drawerRef={drawerRef} onClickOutside={close}>
			<AddStudentProfile modalsOwner={studentDrawerModalsOwner} formTouchedRef={formTouchedRef} onDrawerClose={close}/>
			{isUnsavedChangesAlert && <UnsavedChangesAlert onClose={onAlertClose} isUnsavedChangesConfirmation={isUnsavedChangesAlert}/>}
		</Drawer>
		<ModalOwnerContainer ref={studentDrawerModalsOwner} />
	</>);
}
