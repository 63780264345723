import React from 'react';
import ReactDOM from 'react-dom';
import {ContextMenu as BaseComponent, ContextMenuProps, ContextMenuState} from '../context-menu/context-menu';
import {SortBy} from './models';

export class Props extends ContextMenuProps<ContextMenuState> {
	sortChanged: (e: SortBy) => any;
}

export class ContextMenu extends BaseComponent<ContextMenuState, Props> {
	render(): JSX.Element | false | null {
		return ReactDOM.createPortal(<ul className='ig dropdown-menu card-context-menu'
		                                 style={{
			                                 left: this.state.x || this.props.x,
			                                 top: this.state.y || this.props.y,
		                                 }}
		                                 ref={r => this.rootElement = r}>
			<li>
				<a href='#' onClick={() => this.props.sortChanged(SortBy.CreateDate)}>Create
					Date</a>
			</li>
			<li>
				<a href='#' onClick={() => this.props.sortChanged(SortBy.Name)}>Image Name</a>
			</li>
		</ul>, document.body);
	}
}
