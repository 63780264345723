import {useState} from 'react';
import {FormControl, FormElement} from '@esgi/ui/form';
import {DroppableTextEditor, Input} from '@esgi/ui/form-controls';
import {Drawer} from '@esgi/main/kits/common';

import {FormElementWrapper} from '../../index.styled';
import {DraggableButtonsContainer, DraggableContainer} from './index.styled';
import {DraggableButtonType} from '../../types';

interface Props {
	templateNameControl: FormControl;
	messageControl: FormControl;
	draggableButtons: DraggableButtonType[];
}

export function ParentLetterEditor(props: Props) {

	return (
		<Drawer.ContentBlock title='Parent Letter Template'>
			<FormElementWrapper>
				<FormElement control={props.templateNameControl}>
					<Input.Base placeholder='Template Name' />
				</FormElement>
			</FormElementWrapper>
			<FormElementWrapper >
				<FormElement control={props.messageControl}>
					<DroppableTextEditor tags={props.draggableButtons} placeholder='Message'>
						<DraggableContainer>
							<DroppableTextEditor.TextBox />
						</DraggableContainer>
						<DraggableButtonsContainer>
							{props.draggableButtons.map(tag=>
								<DroppableTextEditor.DraggableTag tagData={tag} key={tag.value}/>
							)}
						</DraggableButtonsContainer>
					</DroppableTextEditor>
				</FormElement>
			</FormElementWrapper>
		</Drawer.ContentBlock>
	);
}
