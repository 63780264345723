import {BaseService} from '@esgi/core/service';

export default class ExportService extends BaseService {
	private readonly controllerName = 'reports/usage';

	public exportPdf = (fileName: string, optionsRef) => {
		return this.httpClient.ESGIApi.file(this.controllerName, 'download-pdf', fileName, optionsRef);
	};

	public exportExcel = (fileName: string, optionsRef) => {
		return this.httpClient.ESGIApi.file(this.controllerName, 'download-excel', fileName, optionsRef);
	};
}
