import {ComponentPropsWithoutRef, MouseEvent, forwardRef, useCallback} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {Edit} from '@esgi/ui/icons';
import {AssignmentState} from '../../../types';
import {ActionButtonsDivider, ButtonIcon, CardHeader} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {AssignmentStatus} from '../../../assignment-status';
import {AssignmentContextMenu} from '../../../assignment-context-menu';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	BaseComponentProps & {
		assignmentName: string;
		assignmentState: AssignmentState;
		onDeleteAssignmentClick: VoidFunction;
		onEditAssignmentClick: VoidFunction;
		editAssignmentButtonDisabled?: boolean;
	};

export const AssignmentCardHeader = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'assignments-assignment-card-header',
			css = {},
			assignmentName,
			assignmentState,
			onDeleteAssignmentClick,
			onEditAssignmentClick,
			editAssignmentButtonDisabled,
			...props
		},
		forwardedRef,
	) => {
		const handleEditAssignmentClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation();

				onEditAssignmentClick();
			},
			[onEditAssignmentClick],
		);

		return (
			<CardHeader dataCy={dataCy} css={css} {...props} ref={forwardedRef}>
				<GridBox display='inlineGrid' flow='column' align='center' gap='3'>
					<Text
						size='xLarge'
						color={assignmentState === AssignmentState.Draft ? 'lowContrast' : 'neutral16'}
						data-assignment-name=''
					>
						{assignmentName}
					</Text>
					<AssignmentStatus state={assignmentState} />
				</GridBox>

				<GridBox display='inlineGrid' flow='column' align='center' gap='3'>
					<AssignmentContextMenu onDeleteAssignment={onDeleteAssignmentClick} />
					<ActionButtonsDivider />
					<ButtonIcon
						withBackgroundHover
						disabled={Boolean(editAssignmentButtonDisabled)}
						onClick={handleEditAssignmentClick}
						dataCy='edit-icon'
					>
						<Edit />
					</ButtonIcon>
				</GridBox>
			</CardHeader>
		);
	},
);
