export class PurchaseFormTemplate {
	static render() {
		return <div className='purchase-form' data-bind='afterRender: true, var: {root: $data}'>
            <div>
                <div data-bind='with: promoCode'>
                    <div className='form-group promo'
                         data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true, visible: $parent.allowUsePromoCode">
                        <label className='control-label' htmlFor='promo-code'>Have a promo code? (Optional):</label>
                        <div>
                            <input id='promo-code' name='promo-code' type='text' className='form-control input-md'
                                   data-bind='value: value, disable: disabled'/>
                            <div data-bind='if: showValidation '>
                                <div data-bind='if: showSuccessValidation'>
                                    <i className='fa fa-check form-control-feedback'></i>
                                </div>
                                <div data-bind='if: showErrorValidation '>
                                    <i className='fa fa-close form-control-feedback'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-bind='if: showOrderOverview '>
                    <div className='form-block'>
                        <label className='control-label'>Order Overview</label>
                        <div className='price-box'>
                            <div className='row'>
                                <div className='col-xs-3 months'>
                                    <div className='form-group'>
                                        <label className='control-label'>Months</label>
                                        <div>
                                            <div data-bind='with: month '>
                                                <select name='months' className='form-control'
                                                        data-bind='options: $parent.months, value: value'></select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xs-3 price'>
                                    <div className='form-group'>
                                        <label className='control-label'>Price</label>
                                        <div>
                                            <div data-bind='with: price '>
                                                <input className='form-control' data-bind='value: value' disabled/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-bind='with: startDate '>
                                    <div className='col-xs-3 start-date'>
                                        <label className='control-label'>Start Date</label>
                                        <div>
                                            <input className='form-control' disabled data-bind='value: value'/>
                                        </div>
                                    </div>
                                </div>
                                <div data-bind='with: endDate '>
                                    <div className='col-xs-3 end-date'>
                                        <label className='control-label'>Expiration Date</label>
                                        <div>
                                            <input className='form-control' disabled data-bind='value: value'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-private className='form-block'>
                    <div className='method-section'>
                        <label className='control-label'>Payment Method</label>
                        <div className='payment-methods'>
                            <img
                                src='//esgiwebfiles.s3.amazonaws.com/Images/VISACard.png'
                                alt='Visa' />
                            <img
                                src='//esgiwebfiles.s3.amazonaws.com/Images/MCCard.png'
                                alt='MasterCard' />
                            <img
                                src='//esgiwebfiles.s3.amazonaws.com/Images/DiscoverCard.png'
                                alt='Discover' />
                            <img
                                src='//esgiwebfiles.s3.amazonaws.com/Images/AmexCard.png'
                                alt='American Express' />
                        </div>
                        <div data-bind='if: allowPayPal '>
                            <div className='paypal-section'>
                                <div>
                                    <label className='control-label'>OR</label>
                                </div>
                                <div className='paypal-button-container'>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-private data-bind='with: cardNumber'>
                    <div className='form-group col-xs-12'
                         data-bind="css: {'has-success' : showSuccessValidation,  'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
                        <div className='row'>
                            <label className='control-label'>Card Number</label>
                            <div data-private id='stripe-card-number' className='form-control input-md validation-error-element'></div>
                            <div className='error-message visible-xs visible-sm hidden-md'>
                                <span data-bind='text:validation.validationResults' />
                            </div>
                            <div data-bind='if: showValidation '>
                                <div data-bind='if: showSuccessValidation'>
                                    <i className='fa fa-check form-control-feedback' />
                                </div>
                                <div data-bind='if: showErrorValidation '>
                                    <i className='fa fa-close form-control-feedback' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div data-private data-bind='with: cardExpiry'>
                        <div className='form-group col-xs-6'
                             data-bind="css: {'has-success' : showSuccessValidation,  'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
                            <label className='control-label'>Expiration Date</label>
                            <div data-private id='stripe-card-expiry' className='form-control input-md validation-error-element'></div>
                            <div className='error-message visible-xs visible-sm hidden-md'>
                                <span data-bind='text:validation.validationResults' />
                            </div>
                            <div data-bind='if: showValidation '>
                                <div data-bind='if: showSuccessValidation'>
                                    <i className='fa fa-check form-control-feedback form-control-feedback-row-element' />
                                </div>
                                <div data-bind='if: showErrorValidation '>
                                    <i className='fa fa-close form-control-feedback form-control-feedback-row-element' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-private data-bind='with: cardCvc'>
                        <div className='form-group col-xs-6'
                             data-bind="css: {'has-success' : showSuccessValidation,  'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
                            <label className='control-label'>Security Number</label>
                            <div data-private id='stripe-card-cvc' className='form-control input-md validation-error-element'></div>
                            <div className='error-message visible-xs visible-sm hidden-md'>
                                <span data-bind='text:validation.validationResults' />
                            </div>
                            <div data-bind='if: showValidation '>
                                <div data-bind='if: showSuccessValidation'>
                                    <i className='fa fa-check form-control-feedback form-control-feedback-row-element' />
                                </div>
                                <div data-bind='if: showErrorValidation '>
                                    <i className='fa fa-close form-control-feedback form-control-feedback-row-element' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
								<div data-private data-bind='with: address'>
									<div className='form-group col-xs-12' data-bind='afterRender: true'>
										<div className='row'>
											<div data-private id='stripe-address'></div>
										</div>
									</div>
								</div>
            </div>
        </div>;
	}
}
