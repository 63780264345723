import {Dispatch, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {SingleSelectState, SingleSelectContext, SingleSelectContextValue} from './context';
import {Class, Group, Student} from '@esgi/main/libs/store';

type Props = {
	selected: SingleSelectState | undefined;
	onSelectedChange: Dispatch<SingleSelectState>;
} & PropsWithChildren;

export function SingleSelect({
	children,
	selected: externalSelectedState = {
		classId: null,
		groupId: null,
		studentId: null,
		studentFromClassId: null,
		studentFromGroupId: null,
	},
	onSelectedChange,
}: Props) {
	const [selected, setSelected] = useState(externalSelectedState);

	useEffect(() => {
		setSelected(externalSelectedState);
	}, [externalSelectedState]);

	const updateSelectedValue = useCallback(
		(data: Partial<SingleSelectState>) => {
			const value: SingleSelectState = {
				classId: null,
				groupId: null,
				studentId: null,
				studentFromClassId: null,
				studentFromGroupId: null,
				...data,
			};

			setSelected(value);
			onSelectedChange(value);
		},
		[onSelectedChange],
	);

	const handleSelectClass = useCallback(
		(classId: Class['id']) => {
			updateSelectedValue({classId});
		},
		[updateSelectedValue],
	);

	const handleSelectGroup = useCallback(
		(groupId: Group['id']) => {
			updateSelectedValue({groupId});
		},
		[updateSelectedValue],
	);

	const handleSelectStudent = useCallback(
		(studentId: Student['id']) => {
			updateSelectedValue({studentId});
		},
		[updateSelectedValue],
	);

	const handleSelectStudentFromClass = useCallback(
		({studentId, classId}: {studentId: Student['id']; classId: Class['id']}) => {
			updateSelectedValue({studentId, studentFromClassId: classId});
		},
		[updateSelectedValue],
	);

	const handleSelectStudentFromGroup = useCallback(
		({studentId, groupId}: {studentId: Student['id']; groupId: Group['id']}) => {
			updateSelectedValue({studentId, studentFromGroupId: groupId});
		},
		[updateSelectedValue],
	);

	const singleSelectContext = useMemo<SingleSelectContextValue>(
		() => ({
			selected,
			selectClass: handleSelectClass,
			selectGroup: handleSelectGroup,
			selectStudent: handleSelectStudent,
			selectStudentFromClass: handleSelectStudentFromClass,
			selectStudentFromGroup: handleSelectStudentFromGroup,
		}),
		[
			handleSelectClass,
			handleSelectGroup,
			handleSelectStudent,
			handleSelectStudentFromClass,
			handleSelectStudentFromGroup,
			selected,
		],
	);

	return <SingleSelectContext.Provider value={singleSelectContext}>{children}</SingleSelectContext.Provider>;
}
