import {setRef, tryCall, isIOS} from '@esgillc/ui-kit/utils';
import {selectedUserID} from 'modules/hierarchy/utils';
import {createRef} from 'react';
import {Modal, Renderable} from '@esgi/deprecated/knockout';
import {Loader} from '@esgi/deprecated/jquery';
import {openModal} from '@esgi/deprecated/react';
import {TotalReportTemplate} from './total-report-template';
import './total-report.less';
import {InvalidTrackAlert} from 'shared/alert/invalid-track-alert/invalid-track-alert';
import {Api} from './api';
import {
	DownloadRequest,
	Entity,
	GetRequest,
	GetResponse,
	GradeLevel,
	Report,
	SortColumnType,
	SortDirection,
	SortModel,
	Subject,
	TestModel,
} from './models';
import {ComparerTotalReport} from './comparer-total-report';
import {PrintStudentReport} from 'shared/modules/reports/grade-scale-report/print-report';
import {userStorage, UserType} from '@esgi/core/authentication';
import {HierarchyMode} from 'modules/hierarchy/core/models';
import {StudentSort} from '@esgi/core/enums';

const sortModel = {
	columnIndex: 0,
	direction: SortDirection.Asc,
	type: SortColumnType.String,
};

export class TotalReport extends Renderable {
	api: Api;

	reportFirstLoaded = false;

	isEdgeOrIE = ko.observable(false);
	isiOS = ko.observable(false);

	subjects = ko.observableArray<Subject>([]);
	selectedSubject = ko.observable<Subject>(null);

	selectedClass = ko.observable<Entity>();
	classes = ko.observableArray<Entity>([]);

	selectedGroup = ko.observable<Entity>();
	groups = ko.observableArray<Entity>([]);

	selectedSchoolsGroup = ko.observable<Entity>();
	schoolsGroup = ko.observableArray<Entity>([]);

	selectedTeachersGroup = ko.observable<Entity>();
	teachersGroup = ko.observableArray<Entity>([]);

	selectedGroupOfSpecialists = ko.observable<Entity>();
	groupOfSpecialists = ko.observableArray<Entity>([]);

	selectedTrack = ko.observable<Entity>();
	tracks = ko.observableArray<Entity>([]);

	selectedGradeLevel = ko.observable<GradeLevel>(null);
	gradeLevels = ko.observableArray<GradeLevel>(null);

	selectedSpecialistGroup = ko.observable<Entity>();
	specialistGroups = ko.observableArray<Entity>([]);

	sortModel = ko.observable<SortModel>(sortModel);
	defaultSortModel = ko.observable<SortModel>(sortModel);
	report: Report;
	userTitleText = ko.observable('');

	loader = null;
	loading = ko.observable(false);
	rows = ko.observableArray([]);
	tests = ko.observableArray<TestModel>([]);
	displayResultsOption = ko.observable('Score');
	displayZeroIfNotTestedOption = ko.observable('NT');
	carryScoresForward = ko.observable(false);
	markingPeriod = ko.observable('All');
	testResultsCorrectVerbiage = '';
	selectedSchool = ko.observable();
	selectedTeacher = ko.observable();
	isGradeEnabled: boolean = userStorage.get().userType === UserType.D || userStorage.get().userType === UserType.C;
	rootElement = null;
	intervalId = null;
	defaultRows = null;
	rowsBlockSize = 100;
	scrollProcessing = false;

	view = {
		showOutOf: (test: TestModel) => {
			let show = test.id > 0 && this.displayResultsOption() === 'Score';
			if (test.type === 'Score') {
				show = show && (this.report.type !== 'District' && this.report.type !== 'School');
			}

			return show;
		},
		print: () => {
			if (this.report.type === 'StudentsSchool' || this.report.type === 'StudentsDistrict') {
				const p = new PrintStudentReport($('body')[0]);
				p.print('table', this.report.table.rows);
			} else {
				window.print();
			}
		},
		downloadExcel: () => {
			const options: DownloadRequest = {
				...this.request,
				currentScoreOnly: this.markingPeriod() === 'Current',
				displayPercents: this.displayResultsOption() === 'Percent',
				displayZero: this.displayZeroIfNotTestedOption() === 'Zero',
				gradeLevelID: this.extractGradeLevel(),
			};

			this.loader.mask();
			this.api.downloadFile('xlsx', this.report.type, options).subscribe({
				error: (err) => {
					console.log(err);
					this.loader.unmask();
				},
				complete: () => this.loader.unmask(),
			});
		},
		downloadPdf: () => {
			const options: DownloadRequest = {
				...this.request,
				currentScoreOnly: this.markingPeriod() === 'Current',
				displayPercents: this.displayResultsOption() === 'Percent',
				displayZero: this.displayZeroIfNotTestedOption() === 'Zero',
				gradeLevelID: this.extractGradeLevel(),
			};

			this.loader.mask();
			this.api.downloadFile('pdf', this.report.type, options).subscribe({
				error: (err) => {
					console.log(err);
					this.loader.unmask();
				},
				complete: () => this.loader.unmask(),
			});
		},
		sort: (index) => {
			index += this.report.valueToSkip;

			const sortRow = this.report.table.sortRow();
			const sortModel = this.sortModel();

			if (index !== this.sortModel().columnIndex) {
				sortModel.columnIndex = index;
				sortModel.direction = SortDirection.Asc;
				sortModel.type = sortRow.cells[index].type;
			} else {
				if (this.sortModel().direction === 'None') {
					sortModel.direction = SortDirection.Asc;
				} else if (this.sortModel().direction === 'Desc') {
					sortModel.direction = SortDirection.Asc;
				} else if (this.sortModel().direction === 'Asc') {
					sortModel.direction = SortDirection.Desc;
				}
			}

			this.sortModel(sortModel);
			this.rowsFactory.sort();
		},
		closeTotalReport: () => {
			this.closeTotalReport();
		},
	};

	events = {
		onLoaded: (callback) => {
			if (typeof callback === 'function') {
				$(self).on('onLoaded', callback);
			}
		},
		onLongLoading: (callback) => {
			if (typeof callback === 'function') {
				$(self).on('onLongLoading', callback);
			}
		},
		onSubjectChanged: (callback) => {
			$(self).on('onSubjectChanged', callback);
		},
		onClassChanged: (callback) => {
			if (typeof callback === 'function') {
				$(self).on('onClassChanged', callback);
			}
		},
		onGroupChanged: (callback) => {
			if (typeof callback === 'function') {
				$(self).on('onGroupChanged', callback);
			}
		},
		aborted: (callback) => {
			$(self).on('aborted', callback);
		},
		loaded: (callback) => {
			$(self).on('loaded', callback);
		},
		emptyReport: (callback) => {
			if (typeof callback === 'function') {
				$(self).on('emptyReport', callback);
			}
		},
	};

	modelFactory = {
		init: (rawModel: GetResponse) => {
			this.report = rawModel.report;
			this.displayZeroIfNotTestedOption(rawModel.report.displayZero ? 'Zero' : 'NT');
			this.carryScoresForward(rawModel.report.carryScoresForward);
			this.markingPeriod(rawModel.report.markingPeriodAll ? 'All' : 'Current');
			this.testResultsCorrectVerbiage = rawModel.testResultsCorrectVerbiage;

			this.carryScoresForward.pureSubscribe((value) => {
				this.loader.mask();
				this.loading(true);

				this.api.carryScoresForward({...this.request, value: value, gradeLevelID: this.extractGradeLevel(), hierarchy: this.request.hierarchy})
					.then((response) => {
						this.applyResponse(response.report);
						this.rowsFactory.sort();
						window.setTimeout(() => {
							this.synchronizeTable();
						}, 1);
					});
			});
			this.markingPeriod.pureSubscribe((value) => {
				this.api.markingPeriodAll(value !== 'Current');
				this.updateResultCellsDisplay();
			});
			this.displayResultsOption.pureSubscribe(() => {
				setTimeout(() => {
					this.fixTestNames();
					this.updateResultCellsDisplay();
				}, 20);
			});
			this.displayZeroIfNotTestedOption.pureSubscribe((value) => {
				this.api.displayZeroIfNotTested(value === 'Zero')
					.then(() => {
						setTimeout(() => {
							this.synchronizeTable();
							this.rowsFactory.sort();
						}, 1);
					});
				return true;
			});
			this.selectedSubject.pureSubscribe(() => {
				if (!this.reportFirstLoaded) {
					return;
				}

				const subject = this.selectedSubject();
				this.request.subjectID = subject.subjectID;
				this.request.subjectType = subject.subjectType;

				this.loader.mask();
				this.loading(true);
				this.api.SubjectChanged(this.request)
					.then((response) => {
						this.applyResponse(response.report);
						const gradeLevels = response.gradeLevels || [];
						gradeLevels.unshift({gradeLevelID: 0, name: 'All'});
						this.fill(this.gradeLevels, this.selectedGradeLevel, gradeLevels, (gl) => gl.gradeLevelID === 0);

						$(self).trigger('onSubjectChanged', {id: subject.subjectID, type: subject.subjectType});
					});
			});
			this.selectedClass.pureSubscribe((value) => {
				if (this.reportFirstLoaded) {
					this.request.reportID = value.id;

					this.loader.mask();
					this.loading(true);
					this.api.IdChanged(this.request)
						.then((response) => {
							this.applyResponse(response.report);
							$(self).trigger('onClassChanged', [value.id]);
						});
				}
			});
			this.selectedGroup.pureSubscribe((value) => {
				if (this.reportFirstLoaded) {
					this.request.reportID = value.id;

					this.loader.mask();
					this.loading(true);
					this.api.IdChanged(this.request)
						.then((response) => {
							$(self).trigger('onGroupChanged', [value.id]);
							this.applyResponse(response.report);
						});
				}
			});
			this.selectedSchoolsGroup.pureSubscribe((value) => {
				if (this.reportFirstLoaded) {
					this.request.reportID = value.id;

					this.loader.mask();
					this.loading(true);
					this.api.IdChanged(this.request)
						.then((response) => {
							this.applyResponse(response.report);
						});
				}
			});
			this.selectedTeachersGroup.pureSubscribe((value) => {
				if (this.reportFirstLoaded) {
					this.request.reportID = value.id;

					this.loader.mask();
					this.loading(true);
					this.api.IdChanged(this.request)
						.then((response) => {
							this.applyResponse(response.report);
						});
				}
			});
			this.selectedGroupOfSpecialists.pureSubscribe((value) => {
				if (this.reportFirstLoaded) {
					this.request.reportID = value.id;

					this.loader.mask();
					this.loading(true);
					this.api.IdChanged(this.request)
						.then((response) => {
							this.applyResponse(response.report);
						});
				}
			});
			this.selectedSpecialistGroup.pureSubscribe((value) => {
				if (this.reportFirstLoaded) {
					this.request.reportID = value.id;

					this.loader.mask();
					this.loading(true);
					this.api.IdChanged(this.request)
						.then((response) => {
							this.applyResponse(response.report);
							$(self).trigger('onGroupChanged', [value.id]);
						});
				}
			});
			this.selectedTrack.pureSubscribe((track) => {
				if (this.reportFirstLoaded) {
					this.loader.mask();
					this.loading(true);
					this.api.changeTrack({...this.request, trackID: track.id, gradeLevelID: this.extractGradeLevel(), userID: selectedUserID(this.request.hierarchy)})
						.then((response) => {
							this.applyResponse(response.report);
						});
				}
			});

			if (this.gradeLevels()) {
				this.selectedGradeLevel.pureSubscribe((ent) => {
					if (this.reportFirstLoaded) {
						this.loader.mask();
						this.loading(true);

						this.api.gradeLevel({...this.request, gradeLevelID: ent.gradeLevelID === 0 ? null : ent.gradeLevelID, hierarchy: this.request.hierarchy})
							.then((response) => {
								this.applyResponse(response.report);
								setTimeout(() => this.syncScrolls(), 1);
							});
					}
				});
			}

			const table = rawModel.report.table;
			for (let i = 0; i < table.rows.length; i++) {
				const row = table.rows[i];
				for (let j = rawModel.report.valueToSkip; j < row.cells.length; j++) {
					const cell = row.cells[j];
					const testIndex = Math.floor((j - rawModel.report.valueToSkip) / (this.report.maxMarkingPeriods + 1));
					cell.percent = (cell.value !== 'NT' && rawModel.report.tests[testIndex].questionsCount) ? Math.floor((cell.value * 100) / rawModel.report.tests[testIndex].questionsCount) : cell.value;
					cell.zeroIsMinimumLevel = cell.value !== 'NT' ? rawModel.report.tests[testIndex].zeroIsMinimumLevel : false;
					let avgPercent = null;
					if (rawModel.report.tests[testIndex].id === 0) {
						const colIndex = j - rawModel.report.valueToSkip - testIndex * (rawModel.report.maxMarkingPeriods + 1);
						let percentsSum = 0;
						const testsCount = rawModel.report.tests.length - 1;
						for (let k = 0; k < testsCount; k++) {
							const c = colIndex + rawModel.report.valueToSkip + k * (rawModel.report.maxMarkingPeriods + 1);
							const percent = row.cells[c].percent;
							const value = row.cells[c].value;
							if (percent !== 'NT' && value !== '---' && value !== '*') {
								percentsSum += parseInt(percent);
							}
						}
						avgPercent = Math.floor(percentsSum / testsCount);
					}
					cell.avgPercent = avgPercent;
				}
			}

			this.tests(rawModel.report.tests);

			this.defaultRows = rawModel.report.table.rows;

			let len = this.rowsBlockSize;
			if (len > this.defaultRows.length) {
				len = this.defaultRows.length;
			}

			this.rows(this.defaultRows.slice(0, len));

			for (let i = 0; i < rawModel.report.table.sortRow.cells.length; i++) {
				rawModel.report.table.sortRow.cells[i].sortDirection = ko.observable(rawModel.report.table.sortRow.cells[i].sortDirection);
			}

			this.report.table.sortRow = ko.observable(this.report.table.sortRow);
			this.report.table.headerRows = ko.observableArray(this.report.table.headerRows);
			this.report.table.titleRow = ko.observable(this.report.table.titleRow);
			this.report.table.infoRows = ko.observable(this.report.table.infoRows);

			const {studentSort} = this.request.hierarchy;
			const {cells} = rawModel.report.table.sortRow();
			const cellHeader = cells[1] && cells[1].value
				? cells[1].value.toLowerCase()
				: '';
			if (studentSort === StudentSort.LastName && cellHeader === 'last name') {
				const sortModel = this.sortModel();
				this.sortModel({...sortModel, columnIndex: 1});
			}

			this.rowsFactory.sort();
		},
	};

	rowsFactory = {
		currentCountOfRows: 0,
		numberOfAddingRows: 25,
		firstInitialRows: 150,
		add: (sourceArray) => {
			const currentIndex = this.rowsFactory.currentCountOfRows;
			if (currentIndex >= sourceArray.length) {
				return false;
			}
			let lastIndex = currentIndex + this.rowsFactory.numberOfAddingRows;
			if (this.rowsFactory.currentCountOfRows === 0) {
				lastIndex = currentIndex + this.rowsFactory.firstInitialRows;
			}

			if (lastIndex > sourceArray.length) {
				lastIndex = sourceArray.length;
			}

			this.rowsFactory.pushAll(this.report.table.rows, currentIndex, lastIndex);
			this.rowsFactory.currentCountOfRows = lastIndex;
			return true;
		},
		pushAll: (sourceArray, startIndex, endIndex) => {
			const addedRows = sourceArray.slice(startIndex, endIndex);
			for (let i = 0; i < addedRows.length; i++) {
				this.rows.push(addedRows[i]);
			}

			this.rows.valueHasMutated();
		},
		sort: () => {
			const rows = this.defaultRows;
			const sortRow = this.report.table.sortRow();

			for (let i = 0; i < sortRow.cells.length; i++) {
				sortRow.cells[i].sortDirection('None');
			}

			sortRow.cells[this.sortModel().columnIndex].sortDirection(this.sortModel().direction);
			const comparator = new ComparerTotalReport(this.sortModel(), this.defaultSortModel(), sortRow, this.displayZeroIfNotTestedOption() === 'Zero');
			rows.sort(comparator.comparer);
			this.rows(rows.slice(0, this.rows().length));
			this.api.reportService.setData(rows);

			setTimeout(() => {
				this.fixHeadWidth();
				this.updateResultCellsDisplay();
			}, 100);
		},
	};

	constructor(private request: GetRequest) {
		super();
		this.api = new Api();
		this.userTitleText(request.hierarchy.mode === HierarchyMode.Specialist ? 'Specialist' : 'Teacher');
	}

	template = () => TotalReportTemplate.render();

	public load(): JQueryPromise<any> {
		this.isEdgeOrIE(window.navigator.userAgent.indexOf('Edge') > -1 || window.navigator.userAgent.indexOf('Trident') > -1);
		this.isiOS(isIOS());
		const deferred = $.Deferred();
		this.api.get(this.request)
			.then((response) => {

				if (response.invalidTrack) {
					const closeRef = createRef<Function>();
					const modal = openModal(<InvalidTrackAlert close={() => tryCall(closeRef.current)} schoolYearName={response.schoolYearName}/>);
					setRef(() => {
						tryCall(modal.close);
						$(self).trigger('aborted');
					}, closeRef);
					return;
				}

				this.request.sourceID = response.report.sourceID;

				this.modelFactory.init(response);

				this.fill(this.subjects, this.selectedSubject, response.subjects, (subj) => {
					return subj.subjectID === this.request.subjectID;
				});

				this.fill(this.classes, this.selectedClass, response.classes, (cls) => cls.id === this.request.reportID);
				this.fill(this.groups, this.selectedGroup, response.groups, (grp) => grp.id === this.request.reportID);
				this.fill(this.schoolsGroup, this.selectedSchoolsGroup, response.schoolsGroups, (grp) => grp.id === this.request.reportID);
				this.fill(this.teachersGroup, this.selectedTeachersGroup, response.teachersGroups, (grp) => grp.id === this.request.reportID);
				this.fill(this.tracks, this.selectedTrack, response.tracks, (trc) => trc.id === response.selectedTrackID);
				this.fill(this.groupOfSpecialists, this.selectedGroupOfSpecialists, response.groupsOfSpecialists, (grp) => grp.id === this.request.reportID);
				let gradeLevels;
				if (response.gradeLevels) {
					gradeLevels = response.gradeLevels;
					gradeLevels.unshift({gradeLevelID: 0, name: 'All'});
				} else {
					gradeLevels = [{gradeLevelID: 0, name: 'All'}];
				}
				this.fill(this.gradeLevels, this.selectedGradeLevel, gradeLevels, (gl) => gl.gradeLevelID === 0);

				this.fill(this.specialistGroups, this.selectedSpecialistGroup, response.specialistGroups, (sg) => sg.id === this.request.reportID);

				$(self).trigger('onLoaded');
				$(self).trigger('loaded');

				setTimeout(() => {
					this.reportFirstLoaded = true;
				}, 100);
				deferred.resolve(response);
			});
		return deferred.promise();
	}

	scrolled = (data, event) => {
		if (this.scrollProcessing) {
			return;
		}

		const elem = event.target;
		if (elem.scrollTop > (elem.scrollHeight - elem.offsetHeight - 50)) {

			const scrollTop = elem.scrollTop;
			if (this.rows().length < this.defaultRows.length) {
				this.scrollProcessing = true;

				let len = this.rows().length + this.rowsBlockSize;
				if (len > this.defaultRows.length) {
					len = this.defaultRows.length;
				}
				this.rows(this.defaultRows.slice(0, len));

				setTimeout(() => {
					this.updateResultCellsDisplay();
					this.synchronizeTable();

					setTimeout(() => {
						this.scrollProcessing = false;
					}, 500);
				}, 200);
			}
		}
	};

	public extractGradeLevel(): number {
		const gl = this.selectedGradeLevel();
		if (!gl) {
			return null;
		}

		return gl.gradeLevelID === 0 ? null : gl.gradeLevelID;
	}

	public cellRender(cell) {
		if (this.displayZeroIfNotTestedOption() === 'Zero' && cell.value === 'NT') {
			return '0';
		} else if (cell.avgPercent != null) {
			if(this.displayZeroIfNotTestedOption() === 'NT' && this.carryScoresForward()) {
				return cell.value === 'NT' ? 'NT' : cell.percent;
			} else {
				return cell.value === 'NT' ? 'NT' : cell.avgPercent;
			}
		} else {
			if (this.displayResultsOption() === 'Score') {
				return cell.value;
			} else {
				return cell.percent;
			}
		}
	}

	public isLeftBorder(index: number, row: any) {
		return (index !== row.cells.length - 1) && (index - this.report.valueToSkip) % (this.report.maxMarkingPeriods + 1) === this.report.maxMarkingPeriods;
	}

	fixTestNames = () => {
		$('.first-header').css('width', 'auto');
		$('.first-header').css('height', 'auto');
		$('.test-names').css('height', 'auto');
		let maxHeight = $('.test-names').outerHeight();
		if ($('.first-header').outerHeight() > maxHeight) {
			maxHeight = $('.first-header').outerHeight() + 1;
		}
		$('.first-header').outerHeight(maxHeight - 1);
		$('.test-names').outerHeight(maxHeight);
	};

	syncScrolls = () => {
		$('.left-bottom-panel').scrollTop($('.right-bottom-panel').scrollTop());
		$('.right-top-panel').scrollLeft($('.right-bottom-panel').scrollLeft());
	};

	synchronizeScrolls = () => {
		$('.right-bottom-panel', this.rootElement).on('scroll', () => {
			this.syncScrolls();
		});

		$('.left-bottom-panel', this.rootElement).on('mousewheel', (event, delta, deltaX, deltaY) => {
			if (deltaY) {

				const scrollTop = $('.left-bottom-panel').scrollTop();
				const y = scrollTop - (deltaY * 171);

				$('.right-bottom-panel').scrollTop(y);
			}
		});

		$('.left-bottom-panel', this.rootElement)[0].addEventListener('touchstart', touchStart, false);
		$('.left-bottom-panel', this.rootElement)[0].addEventListener('touchmove', touchMove, false);

		const start = {x: 0, y: 0, scrollTop: 0};

		function touchStart(event) {

			start.x = event.touches[0].pageX;
			start.y = event.touches[0].pageY;

			start.scrollTop = $('.left-bottom-panel').scrollTop();
		}

		function touchMove(event) {

			const offset = {x: 0, y: 0};

			offset.x = start.x - event.touches[0].pageX;
			offset.y = start.y - event.touches[0].pageY;

			if (Math.abs(offset.x) < Math.abs(offset.y)) {
				event.preventDefault();
			}

			if (offset.y) {
				const y = start.scrollTop + (offset.y);
				$('.left-bottom-panel, .right-bottom-panel').scrollTop(y);
			}
		}

		$('.right-top-panel', this.rootElement)[0].addEventListener('touchstart', touchStartTop, false);
		$('.right-top-panel', this.rootElement)[0].addEventListener('touchmove', touchMoveTop, false);

		const startTop = {x: 0, y: 0, scrollLeft: 0};

		function touchStartTop(event) {

			startTop.x = event.touches[0].pageX;
			startTop.y = event.touches[0].pageY;

			startTop.scrollLeft = $('.right-top-panel').scrollLeft();
		}

		function touchMoveTop(event) {

			const offset = {x: 0, y: 0};

			offset.x = startTop.x - event.touches[0].pageX;
			offset.y = startTop.y - event.touches[0].pageY;

			if (Math.abs(offset.y) < Math.abs(offset.x)) {
				event.preventDefault();
			}

			if (offset.x) {
				const x = startTop.scrollLeft + (offset.x);
				$('.right-top-panel, .right-bottom-panel').scrollLeft(x);
			}
		}
	};

	synchronizeTable = () => {
		this.fixHeadWidth();
		this.fixLeftPanelWidth();

		const report = $('.total-report-content');

		if (this.isEdgeOrIE()) {
			$('#downloadDropdown li').each(function () {
				let display = $(this).css('display');
				display = display !== 'block' ? 'block' : '';
				$(this).css('display', display);
			});

			// ie has a bug, so scrollbars do not show up
			// this hack makes it show them
			Modal.makeIeRedraw();
		}

		const dataWidth = report.find('.right-bottom-panel > table').outerWidth() + (!this.isiOS() && report.find('.left-bottom-panel > div').height() > report.find('.left-bottom-panel').height() ? 17 : 0);
		report.find('.right-top-panel > div > table').outerWidth(dataWidth);

		this.fixTestNames();

	};

	fixHeadWidth = () => {
		const head = $($('.total-table-subheader')[0]);
		const length = head.find('td').length;

		for (let i = 0; i < length - 1; i++) {
			head.find('td:eq(' + i + ')').outerWidth($('.left-bottom-panel table tr:eq(0) td:eq(' + i + ')').outerWidth());
		}
	};

	fixLeftPanelWidth = () => {
		$('.left-panel', this.rootElement).on('scroll', () => {
			const left = $('.left-panel').scrollLeft();
			const dif = $('.left-bottom-panel > div').width() - $('.left-bottom-panel').innerWidth();

			if (left > dif) {
				return;
			}

			const leftPx = left + 'px';

			$('.left-bottom-panel').css('left', leftPx);
			$('.left-bottom-panel table').css('left', '-' + leftPx);


			$('.right-bottom-panel').scrollTop($('.left-bottom-panel').scrollTop());
		});

	};

	updateResultCellsDisplay = () => {
		const show = this.markingPeriod() === 'All';

		const periods = this.report.maxMarkingPeriods;
		if (periods !== 0) {
			$('.right-bottom-panel table tr').each(function () {
				const tds = $(this).find('td').filter((index) => {
					return index % (periods + 1) !== periods;
				});

				const lastTd = $(this).find('td:eq(' + periods + ')');

				if (show) {
					lastTd.removeClass('left-border');
					tds.show();
				} else {
					tds.hide();
					lastTd.addClass('left-border');
				}
			});
		}

		$('.right-bottom-panel tr td').css('width', '');

		if (this.rows().length > 0) {
			const row = this.rows()[0];
			const results = row.cells;
			for (let i = 0; i < results.length - this.report.valueToSkip; i++) {
				const result = results[i];
				const width = this.cellWidth(i, result.testID) + 'px';
				$('.right-bottom-panel tr:eq(0) td:eq(' + i + ')').css('width', width);
			}
		}
		$(self).trigger('emptyReport', [this.rows().length === 0]);

		this.addHoverSupport('.data-row-right', '.left-bottom-panel');
		this.addHoverSupport('.data-row-left', '.right-bottom-panel');
	};

	addHoverSupport = (hoveredTrClass, tableToHoverClass) => {
		$(hoveredTrClass).hover(
			function () {
				$(tableToHoverClass + ' table tr:eq(' + $(this).index() + ')').addClass('hovered');
			},
			function () {
				$(tableToHoverClass + ' table tr:eq(' + $(this).index() + ')').removeClass('hovered');
			},
		);
	};


	getCellSortClass = (index) => {
		index += this.report.valueToSkip;

		let _class = 'fa sort-icon ';

		const sortRow = this.report.table.sortRow();

		if (index === this.sortModel().columnIndex) {
			_class += 'visible ';
			if (this.sortModel().direction === 'Asc') {
				_class += 'fa-arrow-down ';
			} else {
				_class += 'fa-arrow-up ';
			}
		} else {
			_class += index < this.report.valueToSkip ? 'fa-arrow-down' : 'fa-arrow-up';
		}

		return _class;
	};

	testWidth = () => {
		let width = 130;
		width = Math.max(width, (this.report.maxMarkingPeriods + 1) * 42);
		return width;
	};

	totalWidth = () => {
		return this.tests().length * this.testWidth();
	};

	isCellShown = (index) => {
		if (isNaN(index)) {
			return false;
		}

		return this.markingPeriod() === 'All' || (index + 1) % (this.report.maxMarkingPeriods + 1) === 0;
	};

	cellWidth = (index, testId) => {
		const isCellShown = this.isCellShown(index);
		if (isCellShown) {
			const n = this.report.maxMarkingPeriods + 1;
			const firstTest = Math.floor(index / n) === 0;
			const testWidth = this.testWidth() - (firstTest ? 2 : 0);
			let resultsWidth = 0;

			if (this.markingPeriod() === 'All') {
				resultsWidth = Math.floor(testWidth / n);
				if (index % n < testWidth % resultsWidth) {
					resultsWidth += 1;
				}
			} else {
				resultsWidth = testWidth;
			}

			return Math.max(resultsWidth, firstTest ? 41 : 42);
		}

		return 0;
	};

	cssTitleClass = (index) => {
		if (this.report.type === 'District') {
			switch (index()) {
				case 0:
					return 'title-cell align-left report-date-title';
				case 1:
					return 'title-cell report-name align-center';
				case 2:
					return 'title-cell align-center';
			}
		} else {
			switch (index()) {
				case 0:
					return 'title-cell report-date-title';
				case 1:
					return 'title-cell report-date';
				case 2:
					return 'title-cell report-name align-center';
				case 3:
					return 'title-cell align-center';
			}
		}

		return '';
	};

	cssClass = (item, index) => {
		let style = '';
		if (item === undefined) {
			return style;
		}
		if (item.Style === undefined) {
			return 'cell';
		} else {
			switch (item.Style) {
				case 'HeaderInfo':
					style = 'header-info';
					break;
				case 'HeaderTest':
					style = 'header-test';
					break;
				case 'HeaderTotal':
					style = 'total-score';
					break;
				case 'HeaderSubTest':
					style = 'header-sub-test';
					break;
				case 'CellFirst':
					style = 'cell-right';
					break;
				case 'Cell':
					style = 'cell';
					break;
				default:
					style = '';
					break;
			}
		}

		if (item.Style === 'CellFirst' || item.Style === 'Cell') {
			if (index() < this.report.table.realCountInfoColumn) {
				style += ' align-left';
			}
			if (index() >= this.report.table.realCountInfoColumn + this.report.table.countResultColumn) {
				style += ' color-total-weight';
			}
		}
		return style;
	};

	sortDirection = (item) => {
		if (item.SortDirection() === 'None') {
			return '../images/ar_none.gif';
		}
		if (item.SortDirection() === 'Asc') {
			return '../images/ar_down.gif';
		}
		if (item.SortDirection() === 'Desc') {
			return '../images/ar_up.gif';
		}
		return '';
	};

	applyResponse = (report: Report) => {
		this.report = report;
		this.defaultRows = this.report.table.rows;

		for (let i = 0; i < this.report.table.rows.length; i++) {
			const row = this.report.table.rows[i];
			for (let j = this.report.valueToSkip; j < row.cells.length; j++) {
				const cell = row.cells[j];
				const testIndex = Math.floor((j - this.report.valueToSkip) / (this.report.maxMarkingPeriods + 1));
				cell.percent = (cell.value !== 'NT' && this.report.tests[testIndex].questionsCount) ? Math.floor((cell.value * 100) / this.report.tests[testIndex].questionsCount) : cell.value;
				cell.zeroIsMinimumLevel = cell.value !== 'NT' ? this.report.tests[testIndex].zeroIsMinimumLevel : false;
				let avgPercent = null;
				if (this.report.tests[testIndex].id === 0) {
					const colIndex = j - this.report.valueToSkip - testIndex * (this.report.maxMarkingPeriods + 1);
					let percentsSum = 0;
					const testsCount = this.report.tests.length - 1;
					for (let k = 0; k < testsCount; k++) {
						const c = colIndex + this.report.valueToSkip + k * (this.report.maxMarkingPeriods + 1);
						const percent = row.cells[c].percent;
						const value = row.cells[c].value;
						if (percent !== 'NT' && value !== '---' && value !== '*') {
							percentsSum += parseInt(percent);
						}
					}
					avgPercent = Math.floor(percentsSum / testsCount);
				}
				cell.avgPercent = avgPercent;
			}
		}

		this.tests(this.report.tests);

		let len = this.rowsBlockSize;
		if (len > this.defaultRows.length) {
			len = this.defaultRows.length;
		}

		this.rows(this.defaultRows.slice(0, len));

		for (let i = 0; i < this.report.table.sortRow.cells.length; i++) {
			this.report.table.sortRow.cells[i].sortDirection = ko.observable(this.report.table.sortRow.cells[i].sortDirection);
		}
		this.report.table.sortRow = ko.observable(this.report.table.sortRow);
		this.report.table.headerRows = ko.observableArray(this.report.table.headerRows);
		this.report.table.titleRow = ko.observable(this.report.table.titleRow);
		this.report.table.infoRows = ko.observable(this.report.table.infoRows);

		const sortModel = this.sortModel();

		if (sortModel.columnIndex > this.report.valueToSkip + this.tests().length * (this.report.maxMarkingPeriods + 1)) {
			sortModel.columnIndex = this.report.valueToSkip - 1;
			sortModel.direction = SortDirection.Asc;
			sortModel.type = SortColumnType.String;

			this.sortModel(sortModel);
			this.rowsFactory.sort();
		}

		this.loading(false);
		setTimeout(() => {
			this.updateResultCellsDisplay();
			this.loader.unmask();
			this.synchronizeTable();
		}, 1);
	};

	public dispose() {
		clearInterval(this.intervalId);
	}

	public closeTotalReport() {
		if (this.request.sourceID) {
			return this.api.close(this.request);
		}

		return null;
	}

	public afterRender(rootElement: JQuery) {
		const isMounted = document.contains(rootElement[0]);
		const afterRenderClb = () => {
			this.loader = new Loader($(rootElement).parents('.modal-content'));

			this.rootElement = rootElement;
			this.synchronizeScrolls();
			this.synchronizeTable();

			this.updateResultCellsDisplay();
		};
		if (isMounted) {
			afterRenderClb();
		} else {
			setTimeout(() => {
				afterRenderClb();
			});
		}
		return $.Deferred().resolve(rootElement).promise();
	}

	private fill<T>(obs: KnockoutObservableArray<T>, select: KnockoutObservable<T>, arr: T[], comparer: (item: T) => boolean) {
		if (!arr) {
			return;
		}

		obs(arr);
		let selected = obs().find(c => comparer(c));
		if (selected == null) {
			selected = obs()[0];
		}
		select(selected);
	}
}
