import {StudentsPanelTabID} from '@esgi/main/features/teacher/students-panel';

export type PermittedData<T = unknown> = T & {permitted: boolean};

export enum StudentSectionMode {
	View = 'view',
	Edit = 'edit',
	Rearrange = 'rearrange',
	Add = 'add',
}

export type TabsPermissions<TabId extends string> = Record<TabId, boolean>;

export type StudentSectionModePermissions = Record<StudentSectionMode, TabsPermissions<StudentsPanelTabID>>;

export type updateStudentSectionModePermissions = (args: {
	sectionMode: StudentSectionMode;
	tabId: StudentsPanelTabID;
	newPermissions: boolean;
}) => void;

export type getStudentSectionModePermissionValue = (args: {
	sectionMode: StudentSectionMode;
	tabId: StudentsPanelTabID;
}) => boolean;
