import {createContext, createRef, RefObject, useContext} from 'react';
import {noop} from 'underscore';

export type DrawerContextValue = {
	withPanel: boolean;
	setWithPanel: (value: boolean) => void;
	togglePanelOpen: VoidFunction;
	isPanelOpen: boolean;
	panelRef: RefObject<HTMLDivElement>;
};

export const DrawerContext = createContext<DrawerContextValue>({
	withPanel: false,
	setWithPanel: noop,
	togglePanelOpen: noop,
	isPanelOpen: false,
	panelRef: createRef(),
});

export function useDrawerContext() {
	return useContext(DrawerContext);
}
