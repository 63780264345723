import {Test, Track} from '../models';
import moment from 'moment/moment';

export default class TestRow {
	private static readonly defaultFormat: string = 'MM-DD-YY';
	public correct: number = 0;
	public incorrect: number = 0;
	public untested: number = 0;
	public currentDate: string = '';
	public printAllItems: boolean = true;
	public readonly noData: boolean = false;
	public max: number = 0;
	public selected: boolean = false;
	public printAmount: number = 0;
	public id: number;
	public orderNum: number;
	public name: string = '';
	public type: string = '';
	public questionCount: number;

	constructor(public model: Test, private allStudentsSelected: boolean, public tracks: Track[], selectedDate?: string) {
		this.id = model.id;
		this.name = model.name;
		this.type = model.type;
		this.correct = model.correct;
		this.incorrect = model.incorrect;
		this.untested = model.untested;
		this.questionCount = model.questionsCount;
		this.orderNum = model.orderNum;
		this.currentDate = selectedDate || moment().format(TestRow.defaultFormat);
		this.noData = !model.DataExists;
	}

	public get printItems() {
		if (this.printAllItems) {
			return null;
		}

		return this.printAmount;
	}

	setValidationRange(includeUntested: boolean, reset?: boolean) {
		const incorrectCount = includeUntested ? this.incorrect + this.untested : this.incorrect;
		if (this.allStudentsSelected) {
			this.max = this.model.questionsCount;
		} else {
			this.max = incorrectCount;
		}


		const fieldValue = this.printAmount;

		if (fieldValue === 0 || fieldValue > this.max) {
			this.printAmount = this.max;
		}

		if (this.model.type === 'Score' && this.model.DataExists) {
			this.selected = true;
			return;
		}

		this.selected = !!(this.correct + incorrectCount);
	}
}
