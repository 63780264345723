import * as React from 'react';
import type {SVGProps} from 'react';

export function Image(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='m17.741 14.706 1.645 3.7A1 1 0 0 0 20.3 19H25a1 1 0 0 1 1 1v3a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3v-3a1 1 0 0 1 1-1h2.7a1 1 0 0 0 .914-.594l1.645-3.7a3 3 0 0 1 5.482 0Zm-3.038.263-.11.042a1 1 0 0 0-.507.507l-1.644 3.7-.086.177A3 3 0 0 1 9.7 21H8v2a1 1 0 0 0 1 1h14l.117-.007A1 1 0 0 0 24 23v-2h-3.7a3 3 0 0 1-2.742-1.782l-1.644-3.7a1 1 0 0 0-1.21-.549ZM20.5 10a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M23 6H9a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3ZM9 8h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
