import {HttpClient} from '@esgi/api';
import {
	IInitResponse,
	ISharedGradeRangeSetsResponse,
	ITestUpdateModel,
} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/models';
import {SubjectType} from '@esgi/core/enums';
import {SubjectLevel} from 'shared/modules/grade-scale/models';
import {map} from 'rxjs/operators';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class Api {
	private static controllerName = 'reports/grade-scales/grade-range-shared';

	static init(gradeScaleID: number, hierarchy: HierarchySnapshot) {
		return HttpClient.default.ESGIApi.get<IInitResponse>(this.controllerName, 'init', 
			{gradeScaleID: gradeScaleID, hierarchy: hierarchy});
	}

	static getTests(subjectID: number, subjectType: SubjectType, subjectLevel: SubjectLevel, hierarchy: HierarchySnapshot) {
		return HttpClient.default.ESGIApi.get<ISharedGradeRangeSetsResponse>(this.controllerName, 'get-grade-range-sets',
			{
				subjectID: subjectID,
				subjectType: subjectType,
				subjectLevel: subjectLevel, 
				hierarchy: hierarchy})
			.pipe(map(response => {
				return response.tests.map(t => {
					t.readonly = response.readOnly;
					return t;
				});
			}));
	}

	static save(tests: ITestUpdateModel[]) {
		return HttpClient.default.ESGIApi.post(this.controllerName, 'save', {tests: tests});
	}
}
