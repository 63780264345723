import {Box} from '../../../box';
import {CSS} from '@stitches/react';
import {PropsWithChildren, forwardRef, useEffect} from 'react';
import {useInfoBlockContextContext} from '../../context';

type InfoBlockGroupProps = PropsWithChildren<{
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
}>;

export const InfoBlockGroup = forwardRef<HTMLDivElement, InfoBlockGroupProps>(
	({children, css = {}, dataCy = 'ui-kit-info-block-group'}, forwardedRef) => {
		const {setInBlock} = useInfoBlockContextContext();

		useEffect(() => {
			setInBlock(true);

			return () => setInBlock(false);
		});

		return (
			<Box dataCy={dataCy} css={css} ref={forwardedRef}>
				{children}
			</Box>
		);
	},
);
