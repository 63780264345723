import React from 'react';
import styles from './styles.module.less';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportSettingsService} from '../../../../../../services/report-settings-service';

interface Props {
	service: ReportSettingsService;
}

export function DetailReportSettingsTeacher({service}: Props) {
	const selectedSpecialistGroup = useBehaviorSubject(service.selectedSpecialistGroup);
	const teacher = useBehaviorSubject(service.teacher);
	return <div>
		<strong>{selectedSpecialistGroup[0] ? 'Specialist: ' : 'Teacher: '}</strong>
		<span className={styles.name}>{teacher?.fullName}</span>
	</div>;
}
