import {ProgressBar} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {FlexBox, GridBox} from '@esgi/ui/layout';

import {useClassData} from '../../hooks';
import {AveragePerformanceService} from '../service';
import {Delta, LegendContainer, PercentContainer} from '../../kit';
import {ClassPerformanceRingContainer, RingsContainer, StudentPerformanceRingContainer} from '../styled';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	service: AveragePerformanceService,
};

export function ClassView(props: Props) {
	const {loaded, data} = useClassData(props.service, props.testIDs, props.studentIDs);

	const {percent, delta, sessions} = data;

	const percentRounded = Math.round(percent);
	const deltaRounded = Math.round(delta);

	return <>
		<RingsContainer>
			<ClassPerformanceRingContainer>
				<ProgressBar value={loaded ? percent : 0} maxValue={100}>
					<ProgressBar.RingWithPointer progressValueColor='primaryMuted'
					                             skeleton={!loaded}
					                             size={80}
					                             circleStrokeWidth={8}
					                             pointerFillColor='primary48'/>
				</ProgressBar>
			</ClassPerformanceRingContainer>
			{loaded && <StudentPerformanceRingContainer>
				<PercentContainer>
					<Text color='primary48' size='xLarge' data-cy='class-percent'>
						{percentRounded}%
					</Text>
				</PercentContainer>
			</StudentPerformanceRingContainer>}
		</RingsContainer>
		<LegendContainer>
			<GridBox flow='row' gapY='4'>
				<FlexBox direction='column' justify='start' dataCy='sessions-number'>
					<Text css={{paddingBottom: 2}} font='mono' color='base' size='medium'>{sessions}</Text>
					<Text font='mono' color='mediumContrast' size='xSmall'>Sessions</Text>
				</FlexBox>
				<Delta skeleton={!loaded} value={deltaRounded}>
					Since Last Week
				</Delta>
			</GridBox>
		</LegendContainer>
	</>;
}