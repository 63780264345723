import {styled} from '@esgi/ui/theme';
import {SelectableList} from '@esgi/ui/form-controls';
import {GridBox} from '@esgi/ui/layout';

export const SelectableListRoot = styled(SelectableList.Root, {
	display: 'grid',
	gridTemplateRows: '1fr auto',
	gap: '8px',
});


export const ListLabelContainer = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',

	'& svg': {
		fill: '$primary',

		'& path': {
			fill: '$primary',
		},
	},
});
