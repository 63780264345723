export interface KnockoutArray<T> extends Array<T> {

	removeAll(): void;
}

export interface ObservableOptions {
	subscribe?: string[];
	pureSubscribe?: string[];
	immediate?: boolean;
	warm?: boolean;
}

export function computed(options?: ObservableOptions) {
	return function (target: any, key: string, property: any) {
		const sourceGetFunction = Object.getOwnPropertyDescriptor(target, key).get;

		const createObservables = function () {
			if (!this.observables[key]) {
				const observable = ko.computed({
					read: () => {
						return sourceGetFunction.call(this);
					},
					deferEvaluation: !options || !options.warm,
				});

				if (options) {

					if (options.subscribe) {
						for (let i = 0; i < options.subscribe.length; i++) {
							observable.subscribe(this[options.subscribe[i]].bind(this));
						}
					}

					if (options.pureSubscribe) {
						for (let i = 0; i < options.pureSubscribe.length; i++) {
							observable.pureSubscribe(this[options.pureSubscribe[i]].bind(this));
						}
					}
				}

				this.observables[key] = observable;
			}
		};

		// property getter
		property.get = function () {
			if (!this.observables) {
				this.observables = {};
			}

			if (!this.observables[key]) {
				createObservables.call(this);
			}

			return this.observables[key]();
		};

	};
}

export function computedArray(options?: ObservableOptions) {
	return function (target: any, key: string, property: any) {
		const sourceGetFunction = property.get;

		const createObservables = function () {
			const array = KnockoutArray(ko.observableArray());

			if (!this.observables[key]) {
				const observable = ko.computed({
					read: () => {
						const result = sourceGetFunction.call(this);
						array.removeAll();

						if (result.length) {
							for (let i = 0; i < result.length; i++) {
								array.push(result[i]);
							}
						}

						return array;
					},
					deferEvaluation: true,
				});

				if (options) {
					if (options.subscribe) {
						for (let i = 0; i < options.subscribe.length; i++) {
							observable.subscribe(this[options.subscribe[i]].bind(this));
						}
					}

					if (options.pureSubscribe) {
						for (let i = 0; i < options.pureSubscribe.length; i++) {
							observable.pureSubscribe(this[options.pureSubscribe[i]].bind(this));
						}
					}
				}

				this.observables[key] = observable;
			}
		};

		// property getter
		property.get = function () {
			if (!this.observables) {
				this.observables = {};
			}

			if (!this.observables[key]) {
				createObservables.call(this);
			}

			return this.observables[key]();
		};
	};
}

export function observableArray(options?: ObservableOptions) {
	return function (target: any, key: string) {
		const createObservables = function (newVal: any) {
			let array = this.observables[key];
			if (!array) {
				array = KnockoutArray(ko.observableArray());

				if (options) {
					if (options.subscribe) {
						for (let i = 0; i < options.subscribe.length; i++) {
							array.array.subscribe(target[options.subscribe[i]].bind(this));
						}
					}

					if (options.pureSubscribe) {
						for (let i = 0; i < options.pureSubscribe.length; i++) {
							array.array.pureSubscribe(target[options.pureSubscribe[i]].bind(this));
						}
					}
				}

				this.observables[key] = array;
			}

			if (array === newVal) {
				newVal = newVal.splice(0);
			}

			array.removeAll();

			if (newVal) {
				for (var i = 0; i < newVal.length; i++) {
					array.push(newVal[i]);
				}
			}

		};

		// property getter
		const getter = function () {
			if (!this.observables) {
				this.observables = {};
			}

			if (!this.observables[key]) {
				createObservables.call(this);
			}

			return this.observables[key].array();
		};

		// property setter
		const setter = function (newVal: any) {
			if (!this.observables) {
				this.observables = {};
			}

			createObservables.call(this, newVal);
		};

		// Create new property with getter and setter
		Object.defineProperty(target, key, {
			get: getter,
			set: setter,
			enumerable: true,
			configurable: true,
		});
	};
}

export function observable(options?: ObservableOptions) {
	return function (target: any, key: string) {

		const createObservables = function (newVal: any) {
			if (!this.observables[key]) {
				const observable = ko.observable();

				if (options) {
					if (options.subscribe) {
						for (let i = 0; i < options.subscribe.length; i++) {
							observable.subscribe(this[options.subscribe[i]].bind(this));
						}
					}

					if (options.pureSubscribe) {
						for (let i = 0; i < options.pureSubscribe.length; i++) {
							observable.pureSubscribe(this[options.pureSubscribe[i]].bind(this));
						}
					}
				}

				this.observables[key] = observable;
			}
		};

		// property getter
		const getter = function () {
			if (!this.observables) {
				this.observables = {};
			}

			if (!this.observables[key]) {
				createObservables.call(this);
			}

			return this.observables[key]();
		};

		// property setter
		const setter = function (newVal: any) {
			if (!this.observables) {
				this.observables = {};
			}

			if (!this.observables[key]) {
				createObservables.call(this, newVal);
			}

			this.observables[key](newVal);

		};

		// Create new property with getter and setter
		Object.defineProperty(target, key, {
			get: getter,
			set: setter,
			enumerable: true,
			configurable: true,
		});
	};
}

export function ui(property: string) {
	var accessor = function (target: any) {
		if (target[property]) {
			ko.unwrap(target[property]);
		}
	};

	return function (target: any, key: string) {
		var ui = target['_ui'];
		if (!ui) {
			target['_ui'] = ui = {};
		}

		var uiUpdater = ui[key];
		if (!uiUpdater) {
			uiUpdater = ui[key] = {computed: ko.observable(), accessors: []};
		}

		uiUpdater.accessors.push(accessor);
	};
}

export function rendered() {
	return function (target: any, key: string) {
		var rendered = target['_rendered'];
		if (!rendered) {
			rendered = target['_rendered'] = [];
		}

		rendered.push(key);
	};
}

export function init() {
	return function (target: any, key: string) {
		var rendered = target['_init'];
		if (!rendered) {
			rendered = target['_init'] = [];
		}

		rendered.push(key);
	};
}

export function KnockoutArray(observable: any): KnockoutArray<any> {
	const arr: KnockoutArray<any> = [] as any;
	(<any>arr).array = observable;
	observable(arr);

	arr.push = function (element: any) {
		const result = Array.prototype.push.apply(this, arguments);
		this.array(arr);
		this.array.valueHasMutated();

		return result;
	};
	// @ts-ignore
	arr.remove = function (element) {
		const index = this.indexOf(element);
		let result = undefined;
		if (index > -1) {
			result = this.splice(index, 1);
		}
		this.array(arr);
		this.array.valueHasMutated();

		return result;
	};
	arr.removeAll = function () {
		const result = this.splice(0, this.length);
		this.array(arr);
		this.array.valueHasMutated();

		return result;
	};
	arr.unshift = function () {
		const result = Array.prototype.unshift.apply(this, arguments);
		this.array(arr);
		this.array.valueHasMutated();

		return result;
	};

	arr.splice = function () {
		const result = Array.prototype.splice.apply(this, arguments);
		this.array(arr);
		this.array.valueHasMutated();

		return result;
	};

	return arr;
}

export class KnockoutUnobtrusive {
	static observable<T>(object: any, key: string) {
		return object.observables[key] as KnockoutObservable<T>;
	}

	static observableArray<T>(object: any, key: string) {
		return object.observables[key].array as KnockoutObservableArray<T>;
	}
}

export const debounceTimeoutsKey = 'debounceTimeouts';

/**
 * @deprecated Use shared/tools/helpers
 */
export function debounce(timeout: number = 0) {

	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		const originalMethod = descriptor.value;

		descriptor.value = function () {

			if (!this[debounceTimeoutsKey]) {
				this[debounceTimeoutsKey] = {};
			}

			clearTimeout(this[debounceTimeoutsKey][propertyKey]);
			const args = arguments;
			this[debounceTimeoutsKey][propertyKey] = setTimeout(() => {
				originalMethod.apply(this, args);
			}, timeout);
		};
	};
}

export function clearDebounce(obj: any, key: string) {
	if (obj[debounceTimeoutsKey]) {
		clearTimeout(obj[debounceTimeoutsKey][key]);
	}
}
