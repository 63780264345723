import {useCallback, useEffect, useState} from 'react';
import {NotificationModel, NotificationType} from './types';
import {isNull} from 'underscore';
import {RolloverNotofication} from './variant/rollover-notofication';
import {NewSchoolYear} from './variant/new-school-year';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {useBehaviorSubject} from '@esgi/ui';

export function NotificationAlert() {
	const [isLoaded, setIsLoaded] = useState(false);

	const [notificationState, setNotificationState] = useState<NotificationModel | null>(null);

	const service = useService(Service);
	const schoolYear = useBehaviorSubject(service.schoolYear);

	useEffect(() => {
		service.init().subscribe(({notification}) => {
			setNotificationState(notification);
			setIsLoaded(true);
		});
	}, []);

	const onCloseNotification = useCallback(() => {
		if (!isNull(notificationState)) {
			service.dismiss(notificationState.notificationID).subscribe();
			setNotificationState(null);
		}
	}, [notificationState, service]);

	if (!isLoaded || isNull(notificationState)) {
		return null;
	}

	return (
		<>
			{notificationState.type === NotificationType.RolloverNotification && (
				<RolloverNotofication onClose={onCloseNotification} />
			)}
			{notificationState.type === NotificationType.NewSchoolYear && schoolYear && (
				<NewSchoolYear onClose={onCloseNotification} schoolYear={schoolYear}/>
			)}
		</>
	);
}
