import {StudentModel} from '../../../../../../models/models';
import React from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';

class Props {
	students: StudentModel[];
	close: Function;
	process: Function;
}

export default class DeletePopup extends React.PureComponent<Props> {
	render() {
		let description = 'Are you sure you want to delete ' + this.props.students.length + ' selected students from the system?';
		let names = '';
		if (this.props.students.length <= 10) {
			description = 'Are you sure you wish to delete the following students from the system?';
			names = this.props.students.map(x => x.firstName + ' ' + x.lastName).join(', ');
		}

		return <Modal className='student_operation_dialog delete_student_dialog'>
			<ModalHeader title='Delete'>
			</ModalHeader>
			<ModalBody>
				<div>
					<div className='students_list'>
						<div className='description'>{description}&nbsp;<strong>Doing so will also remove all associated data which is not recoverable.</strong></div>
						{names &&
						<div className='names'>{names}</div>
						}
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => {
							this.props.close();
						}}
						title='Cancel'
					/>
					<Primary
						onClick={() => {
							this.props.process(this.props.students);
						}}
						title='Confirm'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
