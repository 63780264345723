import {ReactNode, useMemo} from 'react';
import {Moment} from 'moment';
import {TestScreenType} from '@esgi/core/enums';
import {DateTimePicker} from '@esgi/deprecated/elements/date-time-picker';
import {useBehaviorSubject, join} from '@esgillc/ui-kit/utils';
import {dispatchAppEvent} from '@esgillc/events';
import {Fade} from '@esgillc/ui-kit/transition';
import {BaseCheckbox} from '@esgi/deprecated/ui-kit/checkbox';
import {BaseDropdown, Option} from '@esgi/deprecated/ui-kit/dropdown';
import {useTestSessionDetailsService} from '../../../../hooks';
import {TestSession, TestSessions} from '../../../../types';
import {TestedByChangedEvent} from '../../../../events';
import styles from './styles.module.less';

interface Props {
	setTestSession: (testSession?: TestSession) => void;
	visibleSessions: TestSession[],
	setDate: (date: Moment) => void;
	showDeletedSessions: boolean;
	setShowDeletedSessions: (value: boolean) => void;
}

export function SessionPicker(props: Props): JSX.Element {
	const {
		setTestSession,
		visibleSessions,
		setDate,
		showDeletedSessions,
		setShowDeletedSessions,
	} = props;
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const validator = useBehaviorSubject(service.validator$);
	const editMode = useBehaviorSubject(service.editMode$);

	const sessions = useMemo<TestSessions>(
		() => visibleSessions.reduce((result, item) => {
			result[item.id] = item;
			return result;
		}, {}),
		[visibleSessions],
	);

	const existsDeletedSessions = useMemo<boolean>(
		() => testSessionDetails.testSessions.some(({deleted}) => deleted),
		[testSessionDetails.testSessions],
	);

	const showDateTimePicker = useMemo(
		() => editMode && currentTestSession?.id && !currentTestSession.deleted,
		[editMode, currentTestSession],
	);

	const setShowDeleted = (checked: boolean) => {
		setShowDeletedSessions(checked);

		if (!checked) {
			const firstNotDeleted = testSessionDetails.testSessions.find(
				({deleted}) => !deleted,
			);
			setTestSession(firstNotDeleted);
			return;
		}
		if (testSessionDetails.testSessions.every(({deleted}) => deleted)) {
			setTestSession(testSessionDetails.testSessions[0]);
		}
	};

	const testSessionChanged = (testSessionId: number) => {
		setTestSession(sessions[testSessionId]);
		dispatchAppEvent(
			TestedByChangedEvent,
			new TestedByChangedEvent(currentTestSession.userName),
		);
	};

	const validateTestDate = (date: Moment) => {
		const testDateValidation = !date.isValid()
			? {message: 'Please enter a test session date.', valid: false}
			: {message: '', valid: true};
		if (validator.testDateValidation.valid !== testDateValidation.valid) {
			service.validate({testDateValidation});
		}
		service.validateAll();
		return testDateValidation;
	};

	const renderDateTimePicker = (): ReactNode => showDateTimePicker && (
		<DateTimePicker
			id={'session_time_' + currentTestSession.id.toString()}
			date={currentTestSession.testDate}
			endDate={testSessionDetails.nowProfileTZ}
			validator={(date) => validateTestDate(date)}
			onSelect={(date) => setDate(date)}
		/>
	);

	const renderSelectDropdown = (): ReactNode => {
		if (showDateTimePicker) {
			return;
		}

		const testSessionId = currentTestSession
			? currentTestSession.id
			: undefined;
		const session = visibleSessions.find(({id}) => testSessionId === id);
		const value = session ? [session] : [];

		return (
			<BaseDropdown
				value={value}
				optionName='testDateString'
				setValue={(value) => testSessionChanged(value[0]?.id)}
				className={styles.sessionSelector}
				disabled={(visibleSessions.length < 2 || editMode)}
				placeholder='None'
			>
				{visibleSessions.map((v) => (
					<Option
						key={v.id || 0}
						value={v}
					>
						{v.testScreenType === TestScreenType.SelfAssessment
							? `${v.testDateString} (SA)`
							: v.testDateString
						}
					</Option>
					))
				}
			</BaseDropdown>
		);
	};

	const renderShowDeletedSessions = (): ReactNode => {
		const show = !editMode && existsDeletedSessions;
		return (
			<Fade in={show} duration={100}>
				<div className={join(styles.showDeletedSessions, !show && styles.hidden)}>
					<BaseCheckbox
						id='deleted_session'
						checked={showDeletedSessions}
						onChange={(event) => setShowDeleted(event.target.checked)}
					/>
					<label htmlFor='deleted_session'>
						Show deleted sessions
					</label>
				</div>
			</Fade>
		);
	};

	return (
		<div className={styles.sessionSelector}>
			<div className={styles.selectorHolder}>
				{renderDateTimePicker()}
				{renderSelectDropdown()}
			</div>
			{renderShowDeletedSessions()}
		</div>
	);
}
