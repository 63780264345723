import {Text} from '@esgi/ui/typography';
import {DeltaWrapper, DeltaIndicatorBox} from './styled';
import {useMemo} from 'react';
import {isNull} from 'underscore';
import {VariantProps} from '@stitches/react';

type Props = {
    caption?: string;
    value: number | null;
    withPersantageSign?: boolean;
};

export function DeltaIndicator({value, caption, withPersantageSign}: Props) {
	const {deltaValue, variant} = useMemo<{
        deltaValue: string;
        variant: NonNullable<VariantProps<typeof DeltaIndicatorBox>['variant']>;
    }>(() => {
    	if (isNull(value)) {
    		return {
    			deltaValue: '-',
    			variant: 'neutral',
    		};
    	}

    	if (value === 0) {
    		return {
    			deltaValue: '0',
    			variant: 'neutral',
    		};
    	}

    	return {
    		deltaValue: (value > 0 ? '+' : '-') + Math.abs(value).toFixed(1) + (withPersantageSign ? '%' : ''),
    		variant: value < 0 ? 'error' : 'success',
    	};
    }, [value, withPersantageSign]);

	return (
        <DeltaWrapper>
            <DeltaIndicatorBox variant={variant}>
                <Text size='small' font='mono'>
                    {deltaValue}
                </Text>
            </DeltaIndicatorBox>

            {caption && (
                <Text size='xSmall' font='mono'>
                    {caption}
                </Text>
            )}
        </DeltaWrapper>
	);
}
