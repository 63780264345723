import {GridBox} from '@esgi/ui/layout';
import {TooltipContainer} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {ChartItem} from '../../types';
import {ComponentProps} from 'react';
import {Tooltip} from 'recharts';

type Props = Pick<ComponentProps<typeof Tooltip>, 'active'> & {
	payload?: {
		payload: ChartItem;
	}[];
};

export function BarItemTooltip({payload, active}: Props) {
	if (payload?.length && active) {
		const {payload: {criteriaName}} = payload[0];

		return (
			<TooltipContainer>
				<GridBox gap='2'>
					<Text data-cy='criteria-title' size='small' font='mono' color='neutral40'>
						Criteria:
					</Text>
					<Text data-cy='criteria-name' size='medium' bold color='base'>
						{criteriaName}
					</Text>
				</GridBox>
			</TooltipContainer>
		);
	}

	return null;
}
