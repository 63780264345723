import {ChartOptions, IdNameModel, QuestionData, ReportData} from './models';
import {Observable} from 'rxjs';
import {dispatchAppEvent} from '@esgillc/events';
import {ChartRenderedEvent} from './events';

export function extractNumbersFromString(s: string) {
	const numbers = new Array<number>();
	let number = '';
	let i = 0;
	while (i < s.length) {
		const c = s[i];
		if ('0123456789'.indexOf(c) !== -1) {
			number += c;
		} else {
			if (number != '') {
				numbers.push(parseInt(number));
				number = '';
			}
		}
		i++;
	}

	if (number != '') {
		numbers.push(parseInt(number));
	}

	return numbers;
}

export function comparePartsLists(a: number[], b: number[]) {
	let result = 0;
	const length = Math.min(a.length, b.length);
	let i = 0;
	while (i < length && result == 0) {
		if (a[i] > b[i]) {
			result = 1;
		}
		if (a[i] < b[i]) {
			result = -1;
		}
		i++;
	}
	if (result == 0) {
		if (a.length > length) {
			result = -1;
		}
		if (b.length > length) {
			result = 1;
		}
	}

	return result;
}

export function getReportDate() {
	let date = new Date().toISOString();
	date = date.substr(0, date.indexOf('T'));
	const dateParts = date.split('-');
	date = dateParts[1] + '-' + dateParts[2] + '-' + dateParts[0].substr(2);
	return date;
}

export function calculateMetrics(data: ReportData, gradeLevels: IdNameModel[], selectedGradeLevel: IdNameModel) {
	let students = data.students;
	if (gradeLevels && selectedGradeLevel?.id > 0) {
		students = students.filter(x => x.gradeLevelID === selectedGradeLevel.id);
	}
	const studentIDs = students.map(x => x.studentID);

	data.maxScore = data.showPercentage ? 100 : students.length;
	for (let i = 0; i < data.questions.length; i++) {
		const questionData = data.questions[i];
		const answers = questionData.answers.filter(x => studentIDs.indexOf(x.studentID) != -1);
		questionData.correct = answers.filter(x => x.answerState == 1).length;
		questionData.incorrect = answers.filter(x => x.answerState == 0).length;

		if (data.showPercentage) {
			questionData.correct = Math.round((100 * questionData.correct) / students.length);
			questionData.incorrect = Math.round((100 * questionData.incorrect) / students.length);
		}
	}
}

export function sortQuestionData(data: QuestionData[], sortOptionMode: string) {
	return data.sort((a, b) => {
		let result = 0;
		if (sortOptionMode === '0') {
			result = a.correct > b.correct ? 1 : -1;
		}
		if (sortOptionMode === '1') {
			result = a.correct < b.correct ? 1 : -1;
		}
		if (result != 0) {
			return a.correct == b.correct ? a.incorrect == b.incorrect ? a.order == b.order ? 0 : a.order > b.order ? 1 : -1 : a.incorrect > b.incorrect ? 1 : -1 : result;
		}

		if (sortOptionMode === '2') {
			if (a.order != b.order) {
				return a.order > b.order ? 1 : -1;
			} else {
				return a.questionID > b.questionID ? 1 : -1;
			}
		}

		const aNumbers = extractNumbersFromString(a.label);
		const bNumbers = extractNumbersFromString(b.label);

		if (aNumbers.length > 0 && bNumbers.length > 0) {
			return comparePartsLists(aNumbers, bNumbers);
		}

		return a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;
	});
}

export function toDetailsPDF(qdBody: string, qdClassName: string, qdHeaderName: string, qdDetailsClass: string) {
	return `
		<html>
			<head>
				<style type='text/css'>
					body {
						margin: 40px;
					}
					body > div {
						margin: 15px 0;
					}
					.${qdClassName} > div:first-child {
						margin-top: 0;
						font-size: 20px;
					}
					.${qdClassName} > div:last-child {
						margin-bottom: 0;
					}
					.${qdClassName} {
						font-size: 18px;
					}
					.${qdClassName} > div:not(:first-child) {
						margin-top: 15px;
					}
					.${qdClassName} > div > div {
						font-weight: normal;
					}
					.${qdHeaderName} {
						font-weight: bold;
					}
					.${qdDetailsClass} > div {
						margin-bottom: 15px;
					}
				</style>
			</head>
			<body>${qdBody}</body>
		</html>
	`.replace(/\t|\n/g, '');
}

export function getChartOptions(options: ChartOptions) {
	let questionsData = sortQuestionData(
		options.reportData.questions.slice(),
		options.sortOptionMode,
	);
	if (options.clone) {
		const {min, max} = options.chartSize;
		questionsData = questionsData.slice(min, max);
	}
	const notTestedAnswersData: number[] = questionsData.map(
		x => options.reportData.maxScore - x.correct - (!options.stackIncorrect ? x.incorrect : 0),
	);
	const incorrectAnswersData: number[] = questionsData.map(x => x.incorrect);
	const correctAnswersData: number[] = questionsData.map(x => x.correct);
	const chart = {
		chart: {
			animation: true,
			type: 'column',
			spacingLeft: 10,
			spacingRight: 10,
			width: options.chartSize.width,
			height: options.chartSize.height,
			style: {
				fontFamily: 'Roboto',
			},
			events: {
				render: () => {
					options.updateScrollbarDisplay();
					options.updateTitleSize();
					options.clone && dispatchAppEvent(ChartRenderedEvent);
				},
				load: (event) => {
					options.normalizeChart(event);
				},
			},
		},
		title: {
			text: options.testName,
			style: {
				fontSize: '22px',
				fontWeight: 'bold',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				top: '11px',
				maxWidth: 'var(--width, "none")',
			},
			useHTML: true,
		},
		credits: {
			enabled: false,
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		xAxis: {
			categories: questionsData.map(x => x.label),
			max: options.chartSize.max,
			min: 0,
			events: {
				afterSetExtremes: (event) => {
					if (event.min !== 0 || event.max !== questionsData.length - 1) {
						event.min = 0;
						event.max = questionsData.length - 1;
					}
				},
			},
		},
		yAxis: {
			min: 0,
			zIndex: 10,
			tickPositioner: () => {
				let step = Math.ceil(options.reportData.maxScore / 10);
				if (step > 25) {
					step = 50;
				} else if (step > 15) {
					step = 25;
				} else if (step > 5) {
					step = 10;
				} else if (step > 3) {
					step = 5;
				}
				const positions = [0];
				let i = 0;
				while (i + step < options.reportData.maxScore) {
					i += step;
					positions.push(i);
				}
				if (options.reportData.maxScore - i < step) {
					positions.pop();
				}
				positions.push(options.reportData.maxScore);
				return positions;
			},
			title: {
				text: options.userLevel !== 'user' && options.userLevel !== 'group'
					? `${options.testResultsCorrectVerbiage} Answers %`
					: 'Number of Students',
				style: {
					fontSize: '14px',
				},
			},
			stackLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					color: 'gray',
				},
			},
		},
		legend: {
			align: 'right',
			verticalAlign: 'top',
			enabled: true,
		},
		tooltip: {
			enabled: true,
			formatter: (event) => {
				const point = event.chart.hoverPoint;
				const x = point.category;
				const index = point.index;
				const seriesIndex = point.series.index;

				let str = '<span style="font-weight: normal">' + x + '</span><br />' +
					'<span' + ((seriesIndex !== 2 && !options.stackIncorrect) || (seriesIndex !== 1 && options.stackIncorrect) ? ' style="font-weight: normal"' : '') + '>' + options.testResultsCorrectVerbiage + ': ' + correctAnswersData[index] + '</span><br />' +
					'<span' + ((seriesIndex !== 1 && !options.stackIncorrect) || (seriesIndex !== 0 && options.stackIncorrect) ? ' style="font-weight: normal"' : '') + '>' + options.testResultsIncorrectVerbiage + ': ' + incorrectAnswersData[index] + '</span><br />' +
					'<span' + ((seriesIndex !== 0 && !options.stackIncorrect) || (seriesIndex !== 0 && options.stackIncorrect) ? ' style="font-weight: normal"' : '') + '>Not Tested: ' + (notTestedAnswersData[index] - (options.stackIncorrect ? incorrectAnswersData[index] : 0)) + '</span><br />';

				str += 'Total: ' + options.reportData.maxScore;
				return str;
			},
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				maxPointWidth: 30,
			},
			series: {
				animation: true,
				groupPadding: 0,
				pointPadding: 0.1,
				borderWidth: 0,
				cursor: 'pointer',
				clip: true,
				events: {
					legendItemClick: (e) => {
						e.preventDefault();
					},
				},
				point: {
					events: {
						click: (event) => {
							options.selectPoint(event);
						},
					},
				},
				states: {
					inactive: {
						opacity: 1,
					},
				},
			},
		},
		series: [{
			name: (options.stackIncorrect ? options.testResultsIncorrectVerbiage + ' / ' : '') + 'Not Tested',
			color: '#F2F2F2',
			states: {
				hover: {
					brightness: 0,
					color: '#E0E0E0',
				},
			},
			data: notTestedAnswersData.slice(),
		}],
	};

	if (!options.stackIncorrect) {
		chart.series.push({
			name: options.testResultsIncorrectVerbiage,
			color: '#B3E5FA',
			states: {
				hover: {
					brightness: 0,
					color: '#A8D7EB',
				},
			},
			data: incorrectAnswersData.slice(),
		});
	}

	if (options.clone) {
		chart.chart.animation = false;
		chart.plotOptions.series.animation = false;
		chart.title.text = null;
		chart.tooltip.enabled = false;
		chart.legend.enabled = false;
	}

	chart.series.push({
		name: options.testResultsCorrectVerbiage,
		color: '#00BF96',
		states: {
			hover: {
				brightness: 0,
				color: '#00B08A',
			},
		},
		data: correctAnswersData.slice(),
	});
	chart.xAxis.max = questionsData.length - 1;
	return chart;
}

export function resizeObservable(elem) {
	return new Observable((subscriber) => {
		const ro = new ResizeObserver((entries) => {
			subscriber.next(entries);
		});
		ro.observe(elem);

		return function unsubscribe() {
			ro.unobserve(elem);
		};
	});
}

export function getTemplatePdf(titles, caption, content, legend) {
	let pages = content;
	if (typeof content === 'string') {
		pages = [content];
	}
	const body = pages.map((page) => `
		<div class='page'>
			<div class='chart-header'>
				<table>
					${titles.map(({left, right}) => `<tr><td>${left}</td><td>${right}</td></tr>`).join('')}
				</table>
				<div class='test-name'>Test: ${caption}</div>
			</div>
			${page}
			<div class='chart-legend'>
				<svg>${legend}</svg>
			</div>
		</div>
	`).join('');
	const pageWidth = 1224;
	const totalWidth = pageWidth * pages.length;

	return `
		<html>
			<head>
				<meta charset="UTF-8" />

				<style type='text/css'>
					* {
						box-sizing: border-box;
					}
					body {
						margin: 0;
						padding: 0;
					}
					body > div {
						transform: rotate(90deg);
						-webkit-transform: rotate(90deg);
						transform-origin: bottom left;
						-webkit-transform-origin: bottom left;
						position: absolute;
						top: -650px;
						left: 150px;
						width: ${totalWidth}px;
					}
					.page {
						display: inline-block;
						width: ${pageWidth}px;
					}
					.page > svg {
						padding-left: 15px;
					}
					.chart-zoom-dropdown,
					.highcharts-scrollbar,
					.highcharts-title,
					.highcharts-legend {
						display: none !important;
					}
					.chart-header {
						font-size: 18px;
						padding-left: 15px;
					}
					.chart-header .test-name {
						font-size: 28px;
						margin-top: 40px;
					}
					.chart-legend {
						height: 25px;
						padding-left: 45px;
					}
					.chart-legend > svg {
						overflow: visible !important;
					}
					.chart-header table tr td {
						margin: 0;
						padding: 0;
					}
				</style>
			</head>
			<body>
				<div>
					${body}
				</div>
			</body>
		</html>
	`.replace(/\t|\n/g, '');
}
