import TestRow from './services/test-row';
import {RequestSettingsFetchSessionHierarchy} from './api-models';
import moment from 'moment/moment';
import {TestResult} from './services/results-service/models';
import {GroupItem, SortBy, Student, Subject, Test, User} from './models';

export function selectedTestsFilter(tests: TestRow[], includeSessionOption: boolean) {
	return tests.filter(item => item.selected && !item.noData)
		.map(item => {
			return {
				testID: item.id,
				max: (item.printAllItems) ? null : item.printItems,
				testDate: (includeSessionOption && item.currentDate) || null,
				noData: item.noData,
				answersCount: item.correct + item.incorrect + item.untested,
			};
		});
}

export function makeSelectedEntityName(selectedStudent: Student, selectedGroupItem: GroupItem, teacher: User, groupType: string) {
	return (selectedStudent && selectedStudent?.studentId !== 0)
		? selectedStudent?.fullName
		: (selectedGroupItem)
			? (teacher ? teacher.fullName + '\'s | ' : '') +
			selectedGroupItem.name + ' ' + (groupType === 'SpecialistGroup' ? 'Group' : groupType)
			: '';
}

export function prepareTestSessionRequest(test: { id: number, date: string, questionsCount: number }, selectedStudent: Student,
	selectedGroupItem: GroupItem): RequestSettingsFetchSessionHierarchy {
	const result: RequestSettingsFetchSessionHierarchy = {studentIDs: [], tests: []};
	result.tests = [{testID: test.id, testDate: test.date, questionsCount: test.questionsCount}];

	if (selectedStudent.studentId === 0) {
		selectedGroupItem.students.forEach((student) => {
			result.studentIDs.push(student.studentId);
		});
	} else {
		result.studentIDs.push(selectedStudent.studentId);
	}
	return result;
}

export function makeCacheKeyBase(selectedGroupItem: GroupItem,
	selectedStudent: Student,
	selectedSubject: Subject, classId?: number, studentId?: number, subjectId?: number) {

	let key = '';
	if (classId !== undefined) {
		key += classId.toString() + '_';
	} else if (selectedGroupItem) {
		key += selectedGroupItem?.id.toString() + '_';
	}

	if (studentId !== undefined) {
		key += studentId.toString() + '_';
	} else if (selectedStudent) {
		key += selectedStudent?.studentId.toString() + '_';
	}

	if (subjectId !== undefined) {
		key += subjectId.toString();
	} else if (selectedSubject) {
		key += selectedSubject?.id.toString();
	}

	return key;
}

export function makeSessionHierarchy(tests: TestRow[], selectedStudent: Student, selectedGroupItem: GroupItem): RequestSettingsFetchSessionHierarchy {
	const result: RequestSettingsFetchSessionHierarchy = {
		studentIDs: [],
		tests: [],
	};
	tests.forEach(t => {
		result.tests.push({
			testDate: moment(t.currentDate, 'MM-DD-YYYY HH:mm').format(),
			testID: t.id,
			questionsCount: t.questionCount,
		});
	});

	result.studentIDs = [];

	if (selectedStudent.studentId === 0) {
		selectedGroupItem.students.forEach((student) => {
			result.studentIDs.push(student.studentId);
		});
	} else {
		result.studentIDs.push(selectedStudent.studentId);
	}
	return result;
}

function sortAlphabeticalFunc (a, b) {
	return a.name.localeCompare(b.name, undefined, {
		numeric: true,
		sensitivity: 'base',
	});
}
function sortFn(a: TestRow, b: TestRow, newSorting: SortBy) {
	if (newSorting === SortBy.Alphabetical) {
		return sortAlphabeticalFunc(a, b);
	}

	if (newSorting === SortBy.TestOrder) {
		if (a.orderNum === b.orderNum || (!a.orderNum && !b.orderNum)) {
			return sortAlphabeticalFunc(a, b);
		}
		return a.orderNum > b.orderNum ? 1 : -1;
	}
}

export function sortTestsBy(newSorting: SortBy, tests: TestRow[]) {
	return tests.sort((a, b) => sortFn(a, b, newSorting));
}

export function testResultsCorrectVerbiage(testResult: TestResult, testResultsCorrectVerbiage: string, translation): string {
	return testResult.testType === 'YN'
		? (testResultsCorrectVerbiage === 'Correct'
			? translation.correct
			: testResultsCorrectVerbiage)
		: translation.score;
}

export function testResultsIncorrectVerbiage(testResult: TestResult, testResultsIncorrectVerbiage: string, translation): string {
	return testResult.testType === 'YN'
		? (testResultsIncorrectVerbiage === 'Incorrect'
			? translation.incorrect
			: testResultsIncorrectVerbiage)
		: translation.totalPossible;
}

export function makeShowNoTestsAlert(showNoStudentsAlert: boolean, isInit: boolean, cache: Test[]) {
	if (showNoStudentsAlert) {
		return false;
	}

	if (!cache) {
		return false;
	}
	return isInit && cache.length === 0;
}
