import {PieChartArcProgress} from './components/pie-chart-arc-progress';
import {PieChartProgressLine} from './components/pie-chart-progress-line';
import {PieChartPointer} from './components/pie-chart-pointer';
import {PieChartRing} from './components/pie-chart-ring';
import {PieChartRoot} from './components/pie-chart-root';

export const PieChart = Object.assign(PieChartRoot, {
	Ring: PieChartRing,
	ArcProgress: PieChartArcProgress,
	Pointer: PieChartPointer,
	ProgressLine: PieChartProgressLine,
});
