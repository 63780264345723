import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {SortTable as SortTableIcon} from '@esgi/ui/icons';

export const Wrapper = styled(FlexBox, {
	alignItems: 'center',
	padding: '8px 12px 8px 8px',
	marginBottom: '20px',
});

export const ButtonText = styled(Button.Text, {
	marginRight: '8px',
	display: 'flex',
	alignItems: 'center',
	color: '$lowContrast',

	'& svg': {
		marginLeft: '2px',
	},
});

export const SortTable = styled(SortTableIcon, {
	variants: {
		direction: {
			asc: {
				'& path:last-child': {
					fill: '$primary',
				},
			},
			desc: {
				'& path:first-child': {
					fill: '$primary',
				},
			},
		},
		field: {
			default: {
				'& path:first-child, & path:last-child,': {
					fill: 'currentColor',
				},
			},
			name: {},
		},
	},
});
