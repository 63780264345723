import {useEffect, useState} from 'react';
import {Drawer} from '@esgi/main/kits/common';
import {
	BoxArrowUpRight,
	useBehaviorSubject,
	useStreamEffect,
	ClassLink,
	UnassignLink,
	Clever,
} from '@esgi/ui';
import {Select} from '@esgi/ui/form-controls';
import {useService} from '@esgi/core/service';
import {Form} from '@esgi/ui/form';
import {SystemSettingsService} from './service';
import {FormElementWrapper} from '../../../../../index.styled';
import {
	ContentWrapper,
	PanelContent,
	StyledButton,
} from '../../../index.styled';
import {Text} from '@esgi/ui/typography';
import AppHistory from 'shared/tools/history/history';
import {environment} from '@esgi/core/environments';
import {SsoLinksService} from 'shared/modules/user-settings/components/sso-links/service';
import {dispatchAppEvent} from '@esgillc/events';
import {StudentSort, userStorage} from '@esgi/core/authentication';
import {OutModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/teacher/init/out-model';
import {ChangeSortBy} from '@esgi/main/features/teacher/events';
import {SettingsTab} from '../../../../../types';
import {LogoLinkContainer} from './index.styled';

interface Props {
	onChangeDesignVersion: VoidFunction;
	isOneClickAccountLinked: boolean;
	isCleverAccountLinked: boolean;
	onUpdateInitData: (props: Partial<OutModel>) => void;
	initData: OutModel;
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData: (tab: SettingsTab, isTouched: boolean) => void;
}

export function SystemSettings({
	onChangeDesignVersion,
	initData,
	isOneClickAccountLinked,
	isCleverAccountLinked,
	onUpdateInitData,
	onUpdateTabTouchedData,
	onCloseDrawer,
}: Props) {
	const ssoLinksService = useService(SsoLinksService);
	const service = useService(SystemSettingsService);
	const data = useBehaviorSubject(service.initData);
	const [isDisableButton, setIsDisableButton] = useState(true);
	const [isCleverActive, setCleverActive] = useState(false);
	const [isClassLinkActive, setClassLinkActive] = useState(false);
	const ssoIsActive = environment.country !== 'ca';
	const onCleverLinkClick = () => {
		AppHistory.navigateOutside(
			`${environment.ssoApiURL}/integrations/clever/authesgi`
		);
	};
	const onClassLinkClick = () => {
		ssoLinksService.getClassLink().subscribe((response) => {
			AppHistory.navigateOutside(response.url);
		});
	};
	const onCleverUnlink = () => {
		service.unlinkCleverAccount().subscribe(() => {
			setCleverActive(false);
		});
	};
	const onClassUnlink = () => {
		service.unlinkClassLinkAccount().subscribe(() => {
			setClassLinkActive(false);
		});
	};
	useEffect(() => {
		service.init({
			selectedTimeZone: initData.selectedTimeZone,
			sortByVariants: initData.sortByVariants,
			sortBy: initData.sortBy,
			timeZones: initData.timeZones,
		});
		setClassLinkActive(isOneClickAccountLinked);
		setCleverActive(isCleverAccountLinked);
	}, []);

	useStreamEffect(service.form.onChanged, () => {
		const isTouched = service.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.SystemSettings, isTouched);
	});

	const onSave = () => {
		setIsDisableButton(true);
		service.save().subscribe({
			error: () => setIsDisableButton(false),
			next: () => {
				dispatchAppEvent(
					ChangeSortBy,
					new ChangeSortBy(StudentSort[service.form.controls.sortBy.value[0]])
				);
				const changedData: Partial<OutModel> = {
					sortBy: service.form.controls.sortBy.value[0],
					selectedTimeZone: data?.timeZones.find(el => el.id === service.form.controls.timeZone.value[0]),
				};

				onUpdateInitData(changedData);
				userStorage.update({
					studentSort: service.form.controls.sortBy.value[0],
				});
				service.initData.next({...data, ...changedData});
				onUpdateTabTouchedData(SettingsTab.SystemSettings, false);
			},
		});
	};

	return (
		<Form data-cy='system-settings' controller={service.form}>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={onSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
			>
				<Text data-cy='system-settings-title' size='large' color='neutral24' bold>
					System Settings
				</Text>
			</Drawer.PanelHeader>

			<PanelContent>
				<ContentWrapper dataCy='sort-students-block'>
					<Drawer.ContentBlock title='Sort Students' withDivider>
						<FormElementWrapper control={service.form.controls.sortBy}>
							<Select.Root dataCy='sort-students-dropdown'>
								<Select.Field placeholder='Sort Students' />
								<Select.Content>
									{data?.sortByVariants.map((s) => (
										<Select.Option key={s.value} value={s.value.toString()}>
											<Text size='medium' bold>
												{s.name}
											</Text>
										</Select.Option>
									))}
								</Select.Content>
							</Select.Root>
						</FormElementWrapper>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Time Zone' withDivider>
						<FormElementWrapper control={service.form.controls.timeZone}>
							<Select.Root dataCy='time-zone-dropdown'>
								<Select.Field placeholder='Time Zone' />
								<Select.Content>
									{data?.timeZones.map((t) => (
										<Select.Option key={t.id} value={t.id}>
											<Text size='medium' bold>
												{t.name}
											</Text>
										</Select.Option>
									))}
								</Select.Content>
							</Select.Root>
						</FormElementWrapper>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Legacy Version Switch' withDivider>
						<StyledButton dataCy='go-back-to-old-button' color='secondary' onClick={onChangeDesignVersion}>
							<Text size='medium' color='base'>
								Go back to old ESGI
							</Text>
							<BoxArrowUpRight data-cy='go-back-to-old-arrow'/>
						</StyledButton>
					</Drawer.ContentBlock>
					{ssoIsActive &&
						<Drawer.ContentBlock title='Single Sign On' withDivider>
						{isCleverActive ? (
							<LogoLinkContainer onClick={onCleverUnlink} dataCy='link-clever-desable-button'>
								<Clever />
								<UnassignLink />
							</LogoLinkContainer>
						) : (
							<StyledButton color='secondary' onClick={onCleverLinkClick} dataCy='link-clever-button'>
								<Text size='medium' color='base'>
									Link to Clever account
								</Text>
								<BoxArrowUpRight />
							</StyledButton>
						)}

						{isClassLinkActive ? (
							<LogoLinkContainer onClick={onClassUnlink} dataCy='link-classic-disable-button'>
								<ClassLink />
								{<UnassignLink />}
							</LogoLinkContainer>
						) : (
							<StyledButton color='secondary' onClick={onClassLinkClick} dataCy='link-classic-button'>
								<Text size='medium' color='base'>
									Link to ClassicLink account
								</Text>
								<BoxArrowUpRight />
							</StyledButton>
						)}
					</Drawer.ContentBlock>}
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}
