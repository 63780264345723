import {UserCard} from '@esgi/ui/icons';
import {ToggleGroup} from '@esgi/ui/controls';
import {useCallback, useRef, useState} from 'react';
import {LoginContent} from '../components/login-content';
import {ItemId} from './types';
import {tabItems} from './constants';
import {PersonalInformationContent} from './components/personal-information-content';
import {SchoolAndDistrictContent} from './components/school-and-district-content';
import {UserAccountService} from '../../../user-account-service';
import {SchoolYearModel, TrackModel, UserCountry, UserModel, UserState} from '../../../types';
import {isNull} from 'underscore';
import {MarkingPeriods} from './components/marking-periods';
import {
	PanelSidebar,
	PanelSidebarHeader,
	ToggleGroupContent,
	ToggleGroupItem,
	ToggleGroupTitle,
} from '../components/panels.styled';
import {Text} from '@esgi/ui/typography';
import {DrawerPanel, IconWrapper} from './index.styled';
import {Tooltip} from '@esgi/ui';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = {
	dataService: UserAccountService;
	user: UserModel;
	countries: UserCountry[] | null;
	states: UserState[] | null;
	tracks: TrackModel[] | null;
	schoolYearTypes: SchoolYearModel[] | null;
	isUserDataLoading: boolean;
	activeUserTrackId: TrackModel['trackID'] | null;
};

export function PrimaryAccountPanel({
	dataService,
	user,
	countries,
	states,
	tracks,
	schoolYearTypes,
	isUserDataLoading,
	activeUserTrackId,
}: Props) {
	const [activeTabId, setActiveTabId] = useState(ItemId.PersonalInformation);

	const [isOpenNoChangesConfirmation, setIsOpenNoChangesConfirmation] = useState(false);
	const closeAnywayNoChangesConfirmation = useRef<VoidFunction | null>(null);

	const onCloseNoChangesConfirmation = useCallback(() => {
		closeAnywayNoChangesConfirmation.current = null;
		setIsOpenNoChangesConfirmation(false);
	}, []);

	const onOpenNoChangesConfirmation = (onCloseAlert: VoidFunction) => {
		setIsOpenNoChangesConfirmation(true);
		closeAnywayNoChangesConfirmation.current = onCloseAlert;
	};

	const getTabContent = () => {
		if (isNull(countries) || isNull(states)) {
			return null;
		}

		if (activeTabId === ItemId.SchoolAndDistrict) {
			return (
				<SchoolAndDistrictContent
					onSchoolNameSave={dataService.saveSchoolName.bind(dataService)}
					defaultCountries={countries}
					defaultStates={states}
					user={user}
				/>
			);
		}

		if (activeTabId === ItemId.Login) {
			return <LoginContent userName={user.userName} email={user.email} />;
		}

		if (activeTabId === ItemId.PersonalInformation) {
			return (
				<PersonalInformationContent
					firstName={user.firstName}
					lastName={user.lastName}
					email={user.email}
					nameTitle={user.title}
					countries={countries}
					states={states}
					countryId={user.countryID}
					stateId={user.stateID}
					getStatesByCountryID={dataService.getStatesByCountryID.bind(dataService)}
					savePersonalData={dataService.savePersonalData.bind(dataService)}
					photo={user.photo}
					expirationDate={new Date(user.expirationDate)}
					withRenewButton={user ? !user.notRenewable : false}
					isUserDataLoading={isUserDataLoading}
					canAllowRenewByCC={user?.canAllowRenewByCC}
				/>
			);
		}

		if (activeTabId === ItemId.MarkingPeriods && activeUserTrackId) {
			return (
				<MarkingPeriods
					tracks={tracks ?? []}
					schoolYearTypes={schoolYearTypes}
					updateTrackRequest={dataService.updateTrackRequest.bind(dataService)}
					activeUserTrackId={activeUserTrackId}
					isLinkedToDistrict={user.isLinked}
					onOpenNoChangesConfirmation={onOpenNoChangesConfirmation}
					createTrackRequest={dataService.createNewTrackRequest.bind(dataService)}
					setActiveTrackId={dataService.setActiveTrackId.bind(dataService)}
				/>
			);
		}

		return null;
	};

	return (
		<>
			<DrawerPanel layout={activeTabId}>
				<PanelSidebar>
					<PanelSidebarHeader>
						<Tooltip delayDuration={400}>
							<Tooltip.Trigger>
								<IconWrapper>
									<UserCard />
								</IconWrapper>
							</Tooltip.Trigger>
							<Tooltip.Content variant='secondary'>
								<Text size='small'>My Account</Text>
							</Tooltip.Content>
						</Tooltip>
						<Text size='small' color='neutral56'>
							My Account
						</Text>
					</PanelSidebarHeader>

					<ToggleGroup.Root
						orientation='vertical'
						onValueChange={(tabId) => setActiveTabId(tabId as ItemId)}
						value={activeTabId}
					>
						<ToggleGroupTitle>
							<Text size='small' font='mono' color='lowContrast'>
								Setup
							</Text>
						</ToggleGroupTitle>
						<ToggleGroupContent>
							{tabItems.map(({label, value}) => (
								<ToggleGroupItem value={value} key={value} disabled={activeTabId === value} applyDisabledStyles={false}>
									<Text size='medium' bold color='base'>
										{label}
									</Text>
								</ToggleGroupItem>
							))}
						</ToggleGroupContent>
					</ToggleGroup.Root>
				</PanelSidebar>

				{getTabContent()}
			</DrawerPanel>
			{isOpenNoChangesConfirmation && (
				<UnsavedChangesConfirmation
					onCloseAnyway={() => closeAnywayNoChangesConfirmation.current?.()}
					onClose={onCloseNoChangesConfirmation}
				/>
			)}
		</>
	);
}
