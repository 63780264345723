import {GridBox} from '@esgi/ui/layout';
import {CredentialsRow} from '../../components/credentials-row';
import {useCallback} from 'react';
import {Observable} from 'rxjs';
import {StudentInfo} from '../../components/student-info';
import {UpdateStudentInState} from '../types';
import {Student} from '@esgi/main/libs/store';
import {StudentCredentialsStateModel} from '../../types';

type Props = {
	studentState: StudentCredentialsStateModel;
	studentId: Student['id'];
	onValidateUsername: (args: {value: string; studentID: number; initialValue: string}) => Observable<string | null>;
	onValidatePassword: (value: string) => Observable<string | null>;
	updateStudentInState: UpdateStudentInState;
	isAllCredentialsValid: boolean;
	isStudentCredentialsTouched: boolean;
};

export function StudentCredentials({
	studentState,
	studentId,
	onValidatePassword,
	onValidateUsername,
	updateStudentInState,
	isAllCredentialsValid,
	isStudentCredentialsTouched,
}: Props) {
	const onCredentialsValidValueChanged = useCallback(
		(value: boolean) => {
			updateStudentInState({
				studentId,
				newValue: {
					isCredentialsValid: value,
				},
			});
		},
		[updateStudentInState, studentId],
	);

	const setIsCredentialsTouched = useCallback(
		(value: boolean) => {
			updateStudentInState({
				studentId,
				newValue: {
					isCredentialsTouched: value,
				},
			});
		},
		[studentId, updateStudentInState],
	);

	const onUserNameChanged = useCallback(
		(value: string) => {
			updateStudentInState({
				studentId,
				newValue: {
					userName: value,
				},
			});

			setIsCredentialsTouched(
				studentState.initialUserName !== value || studentState.initialPassword !== studentState.password,
			);
		},
		[
			setIsCredentialsTouched,
			studentId,
			studentState.initialPassword,
			studentState.initialUserName,
			studentState.password,
			updateStudentInState,
		],
	);

	const onPasswordChanged = useCallback(
		(value: string) => {
			updateStudentInState({
				studentId,
				newValue: {
					password: value,
				},
			});

			setIsCredentialsTouched(
				studentState.initialUserName !== studentState.userName || studentState.initialPassword !== value,
			);
		},
		[
			setIsCredentialsTouched,
			studentId,
			studentState.initialPassword,
			studentState.initialUserName,
			studentState.userName,
			updateStudentInState,
		],
	);

	const togglePasswordHidden = useCallback(() => {
		updateStudentInState({
			studentId,
			newValue: {
				isPasswordHidden: !studentState.isPasswordHidden,
			},
		});
	}, [studentId, studentState.isPasswordHidden, updateStudentInState]);

	return (
		<GridBox gap='3' dataCy='student-credentials'>
			<StudentInfo
				firstName={studentState.firstName}
				lastName={studentState.lastName}
				photoUrl={studentState.photoUrl}
				studentNameColor='neutral40'
			/>

			<CredentialsRow
				studentId={studentId}
				userName={studentState.userName}
				initialUserName={studentState.initialUserName}
				onUserNameChanged={onUserNameChanged}
				onValidateUsername={onValidateUsername}
				password={studentState.password}
				initialPassword={studentState.initialPassword}
				onPasswordChanged={onPasswordChanged}
				onValidatePassword={onValidatePassword}
				isPasswordHidden={studentState.isPasswordHidden}
				togglePasswordHidden={togglePasswordHidden}
				onCredentialsValidValueChanged={onCredentialsValidValueChanged}
				multipleForm
				isAllCredentialsValid={isAllCredentialsValid}
				isCredentialsInit={!isStudentCredentialsTouched}
			/>
		</GridBox>
	);
}
