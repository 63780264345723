import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const Result = styled(FlexBox, {
	flexDirection: 'column',
	width: 240,
	margin: 10,
	border: '1px solid #BCBABA',
	borderRadius: 12,
});

export const ResultTitle = styled(Box, {
	backgroundColor: '#EEEEEE',
	borderRadius: '13px 13px 0 0',
	padding: 10,
	flexGrow: 1,
	textAlign: 'center',
});

export const ResultData = styled(FlexBox, {
	height: 120,
	padding: '0 10px',
});

export const Legend = styled(FlexBox, {
	lineHeight: '10px',
	color: '#424242',
});

export const LegendItem = styled(Box, {
	flex: 1,

	variants: {
		type: {
			correct: {
				paddingRight: 3,
				borderRight: '1px solid #BDBDBD',
				textAlign: 'right',
			},
			incorrect: {
				textAlign: 'left',
				paddingLeft: 3,
			},
			info: {
				flex: 'none',
				margin: '5px 0',
			},
		},
	},
});

export const LegendBox = styled(Box, {
	variants: {
		color: {
			true: {
				color: '#22B78C',
			},
		},
	},
});

export const LegendTitle = styled(Box, {
	whiteSpace: 'nowrap',
});

export const Data = styled(FlexBox, {
	flexDirection: 'column',

	variants: {
		type: {
			chart: {
				alignItems: 'center',
				justifyContent: 'space-evenly',
			},
			info: {
				justifyContent: 'center',
				paddingLeft: 20,
				width: '100%',
				color: '#666',
			},
		},
	},
});
