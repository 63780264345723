import {StudentLevelTableModel} from '../../../../../../types/student-level-report-service-types';
import {
	CellDescriptionStyled, CellHeadBaselineStyled,
	CellHeadDescriptionStyled,
	CellStyled,
	ContainerStyled,
	RowStyled,
	RubricNameStyled,
	StudentNameStyled,
	TableStyled,
} from './index.styled';

type TableProps = {
	table?: StudentLevelTableModel | undefined;
};

export function Table({table}: TableProps) {
	if (!table) {
		return null;
	}

	const {studentName, rubricName, totalScores, totalPossible} = table;

	return (
		<>
			<StudentNameStyled>{studentName}</StudentNameStyled>
			<ContainerStyled dataCy='total-table'>
				<RubricNameStyled data-cy='rubric-name'>{rubricName}</RubricNameStyled>
				<TableStyled>
					<thead>
						<RowStyled>
							<CellHeadDescriptionStyled data-cy='marking-period-cell'>Marking Period</CellHeadDescriptionStyled>
							{totalScores.map(({periodName, testSessionID}, index) => (
								<CellHeadBaselineStyled
									baseline={periodName === 'B'}
									key={`${testSessionID}-${index}`}
								>
									{periodName}
								</CellHeadBaselineStyled>
							))}
							<CellHeadDescriptionStyled>Total Possible</CellHeadDescriptionStyled>
						</RowStyled>
					</thead>
					<tbody>
						<RowStyled>
							<CellDescriptionStyled>Total Score</CellDescriptionStyled>
							{totalScores.map(({value, testSessionID}, index) => (
								<CellStyled key={`${testSessionID}-${index}`}>
									{value}
								</CellStyled>
							))}
							<CellDescriptionStyled>{totalPossible}</CellDescriptionStyled>
						</RowStyled>
					</tbody>
				</TableStyled>
			</ContainerStyled>
		</>
	);
}
