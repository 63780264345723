import {Observable, of} from 'rxjs';

/**
 * Creates a new Observable, that will emit single value and then completes.
 * @method lift
 * @param value The value that will emit.
 * @return A new observable with the single value.
 */
export function single<T>(value?: T): Observable<T> {
	return new Observable<T>(observer => {
		observer.next(value);
		observer.complete();
	});
}
