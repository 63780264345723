import {TooltipContentProps} from '@radix-ui/react-tooltip';
import {useMemo} from 'react';

export function useGetTransformValue({
	isShownContent,
	side,
}: {
	isShownContent: boolean;
	side: NonNullable<TooltipContentProps['side']>;
}) {
	return useMemo(() => {
		const startValue = 5;

		const axisBySideType: Record<typeof side, {translateAxis: 'X' | 'Y'; isPositiveStartValue: boolean}> = {
			bottom: {
				translateAxis: 'Y',
				isPositiveStartValue: true,
			},
			top: {
				translateAxis: 'Y',
				isPositiveStartValue: false,
			},
			left: {
				translateAxis: 'X',
				isPositiveStartValue: false,
			},
			right: {
				translateAxis: 'X',
				isPositiveStartValue: true,
			},
		};

		const {translateAxis, isPositiveStartValue} = axisBySideType[side];

		if (isShownContent) {
			return `translate${translateAxis}(0)`;
		}

		return `translate${translateAxis}(${isPositiveStartValue ? startValue : -1 * startValue}px)`;
	}, [isShownContent, side]);
}
