import {TextArea} from '@esgillc/ui-kit/control';
import {TextInput} from '@esgi/deprecated/elements/text-input';
import {IFormControlValidatorResult, Validators} from '@esgi/deprecated/elements';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Sessions} from '../sessions';
import {useTestSessionDetailsService} from '../../hooks';
import {useSingleScore} from './hooks';
import {SingleScoreEditButtons} from './components/edit-buttons';
import styles from './styles.module.less';

interface Props {}

export function SingleScore({}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);
	const {
		state,
		setState,
		getAnswers,
		setAnswers,
	} = useSingleScore(service);

	const setScore = (score: number, scoreValidation: IFormControlValidatorResult) => {
		setState({
			...state,
			score,
			scoreTouched: true,
		});
		service.setDirty(score !== state.score);
		service.validate({scoreValidation});
		service.validateAll();
	};

	const setNote = (note) => {
		setState({
			...state,
			note: note.replace(/[\r\n\v]+/g, ''),
		});
	};

	return (
		<div className={styles.testSessionDetails}>
			<Sessions
				getAnswers={getAnswers}
				setAnswers={setAnswers}
			>
				<SingleScoreEditButtons/>
			</Sessions>
			{currentTestSession &&
				<div className={styles.scoreContainer}>
					Score:&nbsp;
					{editMode
						? <TextInput
								value={state.score.toString()}
								onEdit={(value, validation) => setScore(value, validation)}
								validator={(value: number) => Validators.integerValidator(value, 0, testSessionDetails.totalPossible, 'The score')}
								touched={state.scoreTouched}
								validationPlacement={'top'}
							/>
						: state.score.toString()
					}/{testSessionDetails.totalPossible.toString()}
				</div>
			}
			{testSessionDetails.canEdit &&
				<div className={styles.noteContainer}>
					<TextArea
						value={state.note}
						maxLength={255}
						disabled={!editMode || !testSessionDetails.canEdit}
						rows={3}
						placeholder='Summary: Enter session summary (optional)'
						onChange={(e) => setNote(e.target.value)}
					/>
				</div>
			}
		</div>
	);
}
