import {Page, Content, RightSidebar, Header, Divider} from '../../layout/index.styled';
import {PieChart as PieChartUI, usePieChart, PieChartContext} from '@esgi/main/features/teacher/pie-chart';
import {Reports} from '@esgi/main/kits/reports';
import {useReportState} from './hooks';
import {withNewOldReports} from '../../old-reports';
import {OldPieChart} from '../../old-reports/class/pie-chart';

function PieChart() {
	const {service} = usePieChart();
	const {
		selectedEntity,
		preview,
		setPreview,
		valid,
		busy,
		subjects,
		loadedSubjects,
		subject,
		tests,
		loadingTests,
		showEachStudent,
		entity,
		onEntitySelect,
	} = useReportState(service);

	return (
		<PieChartContext.Provider value={service}>
			<Page data-cy='pie-chart-page'>
				<Reports.StudentLevel.SingleStudentPanel
					hideEmptyClassAndGroup
					onActive={(value) => service.setTab(value)}
					onChange={(value) => {
						onEntitySelect(value);
						service.setEntity({
							classId: value.classId,
							groupId: value.groupId,
							studentId: value.studentId,
							fromClassId: value.studentFromClassId,
							fromGroupId: value.studentFromGroupId,
						});
					}}
				/>
				<Content dataCy='pie-chart-content'>
					<Header data-cy='header'>
						<Reports.UnitNamePanel
							skeleton={!selectedEntity?.label}
							title={selectedEntity?.label ?? ''}
						/>
						<Divider/>
						<Reports.SubjectControl
							skeleton={!loadedSubjects}
							subjects={subjects}
							value={subject.toString()}
							onChange={(value) => service.setSubject(+value)}
						/>
					</Header>
					<PieChartUI.TestsControl
						skeleton={loadingTests}
						tests={tests}
						onChange={(value) => service.setTests(value)}
					/>
				</Content>
				<RightSidebar dataCy='right-sidebar'>
					<Reports.ReportPanel
						reportTitle='Run Report'
						disabled={busy || !valid}
						onReport={() => service.onPreview(() => setPreview(true))}
					/>
					<PieChartUI.SettingsControl
						disabled={entity.studentId !== null}
						value={showEachStudent}
						onChange={(value) => service.setShowEachStudent(value)}
					/>
				</RightSidebar>
			</Page>
			{preview &&
				<PieChartUI.PreviewDialog
					onClose={() => setPreview(false)}
					onDownload={(fileType) => service.onDownload(fileType)}
				/>
			}
		</PieChartContext.Provider>
	);
}

export default withNewOldReports(OldPieChart, PieChart);
