import {styled} from '@esgi/ui/theme';
import {Box} from '../../../layout/box';
import {GridBox} from '../../../layout/grid-box';
import {Text} from '@esgi/ui/typography';
import {Viewport} from '@radix-ui/react-toast';
import {SnackbarCloseIcon} from '../snackbar-close-icon';
import {animated} from 'react-spring';

export const SnackbarViewport = styled(Viewport, {
	all: 'unset',
	position: 'fixed',
	left: 0,
	bottom: 0,
	width: '100vw',
	margin: 0,
	listStyle: 'none',
	outline: 'none',
});

export const Progress = styled(Box, {
	width: '100%',
	height: '100%',
	background: 'rgba(51, 51, 51, 0.88)',
	gridColumnStart: 1,
	gridColumnEnd: -1,
	gridRowStart: 1,
	gridRowEnd: 1,
	zIndex: 1,
});

export const WrapperBox = styled(animated.li, {
	display: 'grid',
	alignItems: 'center',
	background: 'repeating-linear-gradient(45deg, $vivid, $vivid 2px, $neutral24 0, $neutral24 13px)',
});

export const Content = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	gap: 12,
	alignItems: 'center',
	borderTopStyle: 'solid',
	borderTopColor: '$neutral24',
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 20,
	paddingRight: 20,
	borderTopWidth: 1,
	gridColumnStart: 1,
	gridColumnEnd: -1,
	gridRowStart: 1,
	gridRowEnd: 1,
	zIndex: 2,

	[`& > ${Text}`]: {
		color: '$mild',
	},
});

export const SnackbarCloseButton = styled(SnackbarCloseIcon, {
	color: '$mild',
	alignSelf: 'baseline',
});
