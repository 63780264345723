import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Header = styled(GridBox, {
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: '$8',
	minHeight: '40px',
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
});
