export class PaymentIsUnavailableTemplate {
	static render() {
		return <div>
			<div className='title text-center'>
				Link Invalid
			</div>
			<section>
				<ko data-bind='if: !wasPaymentProcessed'>
					This payment request is no longer valid. Please contact Customer Support at support@esgisoftware.com
					to inquire about the status of your payment.
				</ko>
				<ko data-bind='if: wasPaymentProcessed'>
					The order tied to this payment form has already been processed. If you need to discuss payment or
					inquire about additional purchases please contact support@esgisoftware.com. Thank you.
				</ko>
			</section>
		</div>;
	}
}
