import {
	AsyncResponse,
	AsyncStatus,
	FailedAsyncResponse,
	IdleAsyncResponse,
	PendingAsyncResponse,
	SuccessAsyncResponse,
} from './types';

export const isAsyncSucceed = <T>(response: AsyncResponse<T>): response is SuccessAsyncResponse<T> =>
	response.asyncStatus === AsyncStatus.Succeeded;

export const isAsyncFailed = (response: AsyncResponse<unknown>): response is FailedAsyncResponse =>
	response.asyncStatus === AsyncStatus.Failed;

export const isAsyncPending = (response: AsyncResponse<unknown>): response is PendingAsyncResponse =>
	response.asyncStatus === AsyncStatus.Pending;

export const isAsyncIdle = (response: AsyncResponse<unknown>): response is IdleAsyncResponse =>
	response.asyncStatus === AsyncStatus.Idle;
