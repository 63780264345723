export function isNotWindowPlatform() {
    return navigator.platform.toLowerCase().indexOf("win") === -1;
}

export function activatePerfectScrollBarOnNotWindowPlatform(destinations?:any, afterActivate?:any, params?:any) {
    if (!isNotWindowPlatform())
        return;

    params = params || {};
    if (params.minScrollbarLength === undefined) {
        params.minScrollbarLength = 30;
    }

    for (let i = 0; i < destinations.length; i++) {
        const $container = $(destinations[i]);
        if ($container.length === 0) {
            continue;
        }
        if ($container.css("position") !== "relative") {
            $container.css("position", "relative");
        }
        if ($container.hasClass("ps-container")) {
            $container.perfectScrollbar("update");
        } else {
            $container.perfectScrollbar(params);
        }
    }
    if (afterActivate !== undefined && afterActivate !== null && typeof afterActivate === "function") {
        afterActivate();
    }
}

export function activatePerfectScrollBarInIframe(scrollbox, iframe, parentTag) {
    activatePerfectScrollBarOnNotWindowPlatform(
        [scrollbox],
        function () {
            parentTag = parentTag || "body";
            const parentDocument = window.parent.document;
            const $scrollbox = $(scrollbox);
            const $parentIframe = $(iframe, parentDocument);
            $parentIframe.attr("scrolling", "no");
            const $parentTag = $(parentTag, parentDocument);
            $scrollbox.height($parentTag.height() - 100);
            $scrollbox.css("padding-bottom", 5);

            const browser = navigator.userAgent.toLowerCase();
            if (browser.indexOf("firefox") > -1) {
                $scrollbox.bind("wheel mousewheel",
                    function (e) {
                        const scrollTopDelta = (e.originalEvent as any).deltaY > 0 ? 40 : -40;
                        $scrollbox.scrollTop($scrollbox.scrollTop() + scrollTopDelta);
                        $scrollbox.perfectScrollbar("update");
                        return false;
                    });
            }
            $(window.parent).on("resize",
                function () {
                    $scrollbox.height($parentTag.height() - 100);
                    $scrollbox.perfectScrollbar("update");
                });
            $scrollbox.perfectScrollbar("update");
        });
}