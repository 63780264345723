import {Dispatch, createContext, useContext} from 'react';
import {TabInfo} from './types';

export type TabsContextValue<TabId extends string> = {
	active: TabId;
	setActive: Dispatch<TabId>;
	add: Dispatch<TabInfo<TabId>>;
	remove: Dispatch<TabId>;
	updateTabInfo: (args: {id: TabId; data: Partial<Omit<TabInfo<TabId>, 'id'>>}) => void;
	hasTabInList: (tabId: TabId) => boolean;
	availableTabs: Readonly<Readonly<TabInfo<TabId>[]>>;
};

export const TabsContext = createContext<TabsContextValue<any> | null>(null);

export function useTabsContext<T extends string>() {
	const context = useContext(TabsContext);

	if (!context) {
		throw new Error('TabsContext is null');
	}

	return context as TabsContextValue<T>;
}
