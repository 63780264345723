import {Fragment, Suspense, useState} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from './no-students-protected-button';
import {IepProgressReportIcon} from './icons/iep-progress-report-icon';
import {HierarchyInstance} from 'modules/hierarchy/core/models';

const IepProgressReport = lazyComponent(() => import('modules/reports/iep-progress'));

interface Props {
	hierarchy: HierarchyInstance;
	noStudents: boolean;
	subjectId?: number;
}

export const IepProgressButton = (props: Props): JSX.Element => {
	const [opened, setOpened] = useState(false);

	const onClick = () => {
		SsoTracker.trackEvent({
			trackingEvent: 'IepProgressReport',
		});
		setOpened(true);
	};

	return (
		<>
			<NoStudentProtectedButton
				title='IEP Progress'
				icon={<IepProgressReportIcon/>}
				hoverTitle='Details IEP Progress'
				onClicked={onClick}
				noStudents={props.noStudents}
				linkClassName='iep-progress-report-link'
			/>
			{opened &&
				<Suspense fallback={<Fragment/>}>
					<IepProgressReport studentId={props.hierarchy.specialist.studentID}
					                   hierarchy={props.hierarchy}
					                   subjectId={props.subjectId}
					                   onClose={() => setOpened(false)}
					/>
				</Suspense>
			}
		</>
	);
};
