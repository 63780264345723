import {useCallback, useMemo, useRef} from 'react';
import {Observable, of} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {AnswerState} from '@esgi/core/enums';
import {QuestionModel} from '../../../../../../kit/models';
import {Props} from '../question-slider';
import {TestScreenService} from '../services/testing-service';
import {useKeyboardShortcuts} from './use-keyboard-shortcuts';

type Out = {
	submitCorrect: () => void,
	submitIncorrect: () => void,
	skip: () => void,
	backClicked: () => void,
	closeClicked: () => void,
	finishTest: () => Observable<any>;
};

export function useSliderActions(service: TestScreenService, index: number, questions: QuestionModel[], serviceBusy: boolean, props: Props): Out {
	const createFinishRequest = useCallback(() => service.finish().pipe(tap(props.onEndTest), catchError(() => of())), []);
	const clickCoordsRef = useRef<{x: number, y: number, time: number}>({x: 0, y: 0, time: 0});

	const submitAnswer = useCallback((state: AnswerState, e?: MouseEvent) => {
		const time = Date.now();
		const lastClick = clickCoordsRef.current;
		clickCoordsRef.current = {x: e?.clientX, y: e?.clientY, time};

		if(e && e.clientX === lastClick.x && e.clientY === lastClick.y && time - lastClick.time < 500) {
			return;
		}

		const request = service.submitAnswer(state);
		if (index === questions.length - 1) {
			request.pipe(switchMap(() => createFinishRequest())).subscribe();
		} else {
			request.subscribe(() => service.setCurrentQuestionIndex(index + 1));
		}
	}, [index, questions, createFinishRequest]);

	const yesClicked = useCallback((e?: MouseEvent) => !serviceBusy && submitAnswer(AnswerState.Correct, e), [submitAnswer, serviceBusy]);
	const noClicked = useCallback((e?: MouseEvent) => !serviceBusy && submitAnswer(AnswerState.Incorrect, e), [submitAnswer, serviceBusy]);
	const skipClicked = useCallback((e?: MouseEvent) => !serviceBusy && submitAnswer(AnswerState.NotTested, e), [submitAnswer, serviceBusy]);

	const closeClicked = useCallback(() => {
		if (index > 0) {
			createFinishRequest().subscribe();
		} else {
			props.closeClicked();
		}
	}, [index, props.closeClicked]);

	const backClicked = useCallback(() => {
		if (serviceBusy) {
			return;
		}

		if (index === 0 && props.testingModel.testIntro) {
			props.toIntroClicked();
		} else if (index > 0) {
			service.setCurrentQuestionIndex(index - 1);
		}
	}, [index, props.toIntroClicked, serviceBusy]);

	useKeyboardShortcuts((event) => {
		if (props.testingModel.enableShortcuts) {
			switch (event) {
				case AnswerState.Correct:
					return yesClicked();
				case AnswerState.Incorrect:
					return noClicked();
			}
		}
	});

	return {
		submitCorrect: yesClicked,
		submitIncorrect: noClicked,
		skip: skipClicked,
		closeClicked,
		backClicked,
		finishTest: createFinishRequest,
	};
}
