import {ClassicHierarchyLevel} from '../enums';
import {SelectedModel} from '../selected-model';
import {ClassicHierarchySnapshot} from '../snapshot/classic-hierarchy-snapshot';

export class ClassicHierarchyInstance {
	public districtID: number;
	public schoolID: number;
	public teacherID: number;
	public classID: number;
	public groupID: number;
  public schoolsGroupID: number;
  public teachersGroupID: number;
	public studentID: number;
	public get selected(): SelectedModel<ClassicHierarchyLevel> {
		if (this.studentID !== 0) {
			return {level: ClassicHierarchyLevel.Student, levelID: this.studentID};
		}
		if (this.groupID !== 0) {
			return {level: ClassicHierarchyLevel.Group, levelID: this.groupID};
		}
    if (this.schoolsGroupID !== 0) {
      return {level: ClassicHierarchyLevel.SchoolsGroup, levelID: this.schoolsGroupID};
    }
    if (this.teachersGroupID !== 0) {
      return {level: ClassicHierarchyLevel.TeachersGroup, levelID: this.teachersGroupID};
    }
		if (this.classID !== 0) {
			return {level: ClassicHierarchyLevel.Class, levelID: this.classID};
		}
		if (this.teacherID !== 0) {
			return {level: ClassicHierarchyLevel.Teacher, levelID: this.teacherID};
		}
		if (this.schoolID !== 0) {
			return {level: ClassicHierarchyLevel.School, levelID: this.schoolID};
		}
		if (this.districtID !== 0) {
			return {level: ClassicHierarchyLevel.District, levelID: this.districtID};
		}

		return {level: ClassicHierarchyLevel.None, levelID: 0};
	}

	public static build(model: ClassicHierarchySnapshot): ClassicHierarchyInstance {
		const m = new ClassicHierarchyInstance();
		m.districtID = model.districtID;
		m.schoolID = model.schoolID;
		m.teacherID = model.teacherID;
    m.schoolsGroupID = model.schoolsGroupID;
    m.teachersGroupID = model.teachersGroupID;
		m.classID = model.classID;
		m.groupID = model.groupID;
		m.studentID = model.studentID;
		return m;
	}

	public copy(): ClassicHierarchyInstance {
		const m = new ClassicHierarchyInstance();
		m.districtID = this.districtID;
		m.schoolID = this.schoolID;
		m.teacherID = this.teacherID;
		m.classID = this.classID;
		m.groupID = this.groupID;
    m.schoolsGroupID = this.schoolsGroupID;
    m.teachersGroupID = this.teachersGroupID;
		m.studentID = this.studentID;
		return m;
	}

	public equal(outer: ClassicHierarchyInstance): boolean {
		if (this.districtID !== outer.districtID) {
			return false;
		}
		if (this.schoolID !== outer.schoolID) {
			return false;
		}
		if (this.teacherID !== outer.teacherID) {
			return false;
		}
		if (this.classID !== outer.classID) {
			return false;
		}
		if (this.groupID !== outer.groupID) {
			return false;
		}
    if (this.schoolsGroupID !== outer.schoolsGroupID) {
      return false;
    }
    if (this.teachersGroupID !== outer.teachersGroupID) {
      return false;
    }
		if (this.studentID !== outer.studentID) {
			return false;
		}

		return true;
	}

	public extract(): ClassicHierarchySnapshot {
		return {
			studentID: this.studentID,
			classID: this.classID,
			groupID: this.groupID,
      schoolsGroupID: this.schoolsGroupID,
      teachersGroupID: this.teachersGroupID,
			schoolID: this.schoolID,
			teacherID: this.teacherID,
			districtID: this.districtID,
			selected: this.selected,
		} as ClassicHierarchySnapshot;
	}
}
