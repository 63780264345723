import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {DictionaryItem, Teacher, UserRawResponse} from 'modules/forms/students-form/types';

export type SchoolAdminFormType = FormGroup<{
	groupIDs: FormControl<number[]>,
	classIDs: FormControl<number[]>,
	teacher: FormControl<(UserRawResponse & { fullName: string })[]>,
},
	ControlValue<{
		groupIDs: FormControl<number[]>,
		classIDs: FormControl<number[]>,
		teacher: FormControl<(UserRawResponse & { fullName: string })[]>,
	}>>

export const createSchoolAdminForm = ():
	SchoolAdminFormType => new FormGroup({
	groupIDs: new FormControl([]),
	classIDs: new FormControl([]),
	teacher: new FormControl([], {validators: [Validators.required()]}),
});
