import * as React from 'react';
import type {SVGProps} from 'react';

export function Upload(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M10.667 18a.655.655 0 0 0-.667.643v1.286c0 1.42 1.194 2.571 2.667 2.571h6.666c1.473 0 2.667-1.151 2.667-2.571v-1.286a.655.655 0 0 0-.667-.643.655.655 0 0 0-.666.643v1.286c0 .71-.597 1.285-1.334 1.285h-6.666c-.737 0-1.334-.575-1.334-1.285v-1.286a.655.655 0 0 0-.666-.643Z'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M15.49 9.213a.716.716 0 0 1 1.02 0l2.279 2.294a.733.733 0 0 1 0 1.03.716.716 0 0 1-1.02 0l-1.019-.912v5.625a.75.75 0 0 1-1.5 0v-5.625l-1.02.912a.716.716 0 0 1-1.019 0 .733.733 0 0 1 0-1.03l2.28-2.294Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
