import {createContext, Dispatch, useContext, useEffect, useMemo, useState} from 'react';
import {PanelDebugName} from '../../constants';

export type PossibleTabID = string;

export type TabsContextValue<T extends PossibleTabID> = {
	add: Dispatch<TabInfo<T>>,
	disable: Dispatch<T>,
	remove: Dispatch<T>,
	active: T,
	setActive: Dispatch<T>,
	availableTabs: Readonly<Readonly<TabInfo<T>[]>>,
}

export const TabsContext = createContext<TabsContextValue<any> | null>(null);

export function useTabsContext<T extends PossibleTabID>() {
	const context = useContext(TabsContext);

	if (!context) {
		throw new Error('FeaturesContext is null');
	}

	return context as TabsContextValue<T>;
}

export function useTabActivator<T extends PossibleTabID>(tabID: T, name: string, description: string, disabled?: boolean) {
	const context = useTabsContext();

	useEffect(() => {
		context.add({id: tabID, name, description, disabled: disabled || false});
		return () => context.remove(tabID);
	}, [tabID, name, description, disabled]);
}

export type TabInfo<T> = {
	id: T,
	name: string,
	description: string,
	disabled: boolean,
}

function checkSetTab<T>(tab: TabInfo<T> | undefined) {
	if (tab) {
		if (!tab.disabled) {
			return true;
		}

		console.warn(`${PanelDebugName}: You try to activate tab that disabled at moment. Reject setActive method. Tab: ${tab.name}`);
		return false;
	}

	console.warn(`${PanelDebugName}: You try to activate tab that not exist. Register tab with add method of TabsContext. Reject setActive method.`);
	return false;
}

export function useTabsFeature<Tab extends PossibleTabID>(initialActiveTab?: Tab) {
	const [tab, setTab] = useState<Tab | undefined>(initialActiveTab);
	const [tabInfos, setTabInfos] = useState<TabInfo<Tab>[]>([]);

	const context = useMemo(() => ({
		add: (tabInfo) => setTabInfos((values) => [...values, tabInfo]),
		disable: (disabledTab) => setTabInfos((values) => values.map(tab => {
			if (tab.id === disabledTab) {
				tab.disabled = true;
			}
			return tab;
		})),
		remove: (tab) => setTabInfos((values) => values.filter(t => t.id !== tab)),
		availableTabs: tabInfos,
		active: tab,
		setActive: (tab) => {
			const tabInfo = tabInfos.find(t => t.id === tab);
			const valid = checkSetTab<Tab>(tabInfo);
			if(valid) {
				setTab(tab);
			}
		},
	} as TabsContextValue<Tab>), [tab, tabInfos]);

	return {
		tab,
		setTab,
		tabInfos,
		setTabInfos,
		context,
	};
}