import React from 'react';
import {GridBox} from '@esgi/ui/layout';
import {LastTestSession} from '../types';
import {AnswersInfo, ProgressWithPointer, Statistics, TestSessionDelta} from '../../../../kit';
import {useTestDashboardContext} from '../../../../layout/outlet-context';

interface Props {
	lastSession: LastTestSession;
	totalPossible: number;
	classCorrectAnswersAvg: number;
}

export function DefaultDiagrams({lastSession, totalPossible, classCorrectAnswersAvg}: Props) {
	const {testResultsCorrectVerbiage} = useTestDashboardContext();

	return (
		<>
			<ProgressWithPointer
				percentageType
				value={Math.round((classCorrectAnswersAvg / totalPossible) * 100) || 0}
				progressBarTitle='Class Average'
				dataCy='class-average'
			/>
			<Statistics.ChartsDivider />
			<ProgressWithPointer dataCy='last-session-result' percentageType value={lastSession?.progress || 0} progressBarTitle='Last Session Result'>
				<GridBox>
					<AnswersInfo
						value={lastSession?.correctAnswersCount || 0}
						maxValue={totalPossible}
						transcriptText={`${testResultsCorrectVerbiage} answers`}
					/>
					<TestSessionDelta value={lastSession?.delta} deltaTranscript='Since Last Test' percentageType lightZeroValue />
				</GridBox>
			</ProgressWithPointer>
		</>
	);
}
