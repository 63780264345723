import React from 'react';
import ReactDOM from 'react-dom';


export interface Item {
    key: any,
    value: any,
    title: string,
    style?: any,
    disabled?: boolean,
}

export interface Position {
	top: number;
	left: number;
}

export interface Size {
	width: string;
	height: string;
}

class Props {
	onSelect: (item: Item) => any;
	items: Item[];
	coords: Position;
	size: Size;
}

/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class Dropdown extends React.PureComponent<Props> {

	private safeGetText(item: Item) {
		if (item) {
			return item.title;
		}
		return '';
	}

	private onSelect(item: Item) {
		this.props.onSelect(item);
	}

	private renderItem(item: Item) {
		if (item && !item.disabled) {
			return <div data-cy='dropdown-item' className='option' style={item.style} key={item.key} onClick={() => this.onSelect(item)}>
				{this.safeGetText(item)}
			</div>;
		}
	}

	private renderDropdown() {
		return <div data-cy='dropdown' className='dropdown-wrapper' style={{maxHeight: this.props.size.height, top: this.props.coords.top, left: this.props.coords.left, width: this.props.size.width}}>
				{this.props.items.map(i => this.renderItem(i))}
			</div>;
	}

	render() {
		return ReactDOM.createPortal(this.renderDropdown(), document.body);
	}
}
