import React from 'react';
import styles from '../../styles.module.less';
import {TestHistoryService} from '../../../../../../services/test-history-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';

interface Props {
	service: TestHistoryService
}

export function Test({service}: Props) {
	const selectedSubject = useBehaviorSubject(service.selectedSubject);
	const selectedTest = useBehaviorSubject(service.selectedTest);
	return selectedSubject[0] && selectedSubject[0].tests.length > 0 && <>
		<div>
			<span className={styles.settingsTitle}>Test: </span>
			<Dropdown value={selectedTest} setValue={(v) => service.selectedTest.next(v)}
			          optionName='name'
			          className={styles.fluid} autoWidth={true}>
				{selectedSubject[0].tests.map(v => <Option key={v.testID} value={v}>{v.name}</Option>)}
			</Dropdown>
		</div>
	</>;
}
