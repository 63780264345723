import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

type Props = {
	onConfirm: () => void;
	onCancel: () => void;
}

export function ConfirmAbortAlert(props: Props) {
	const modal = useModal();
	const onConfirm = useCloseModal(modal, props.onConfirm);
	const onCancel = useCloseModal(modal, props.onCancel);

	return (
		<Alert
			modalManagerRef={modal}
			data-cy='confirm-abort-alert'
		>
			<Alert.Body>
				Are you sure you would like to abort? The current test session will be lost.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Contained
					data-cy='confirm-abort-button'
					onClick={onConfirm}
				>
					YES, ABORT TEST SESSION
				</Buttons.Contained>
				<Buttons.Text
					data-cy='cancel-abort-button'
					onClick={onCancel}
				>
					CANCEL
				</Buttons.Text>
			</Alert.Footer>
		</Alert>
	);
}
