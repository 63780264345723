import {Text} from '@esgi/ui/typography';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import * as Slider from '@radix-ui/react-slider';
import {CSS} from '@stitches/react';

const thumbHeight = 24;

const hatchStyles: CSS = {
	display: 'block',
	position: 'absolute',
	width: 1,
	height: 5,
	backgroundColor: '$neutral72',
	borderRadius: 2,
};

export const MonthsLine = styled(FlexBox, {
	width: '100%',
	borderRadius: 2,
	marginTop: 32,
});

export const MonthLine = styled(FlexBox, {
	height: 1,
	borderRadius: 2,
	backgroundColor: '$muted',
	justifyContent: 'center',
	position: 'relative',
	alignItems: 'flex-end',

	'&::after': {
		content: '',
		...hatchStyles,
		right: 0,
		top: 0,
		bottom: 0,
		margin: 'auto',
	},

	[`& ${Text}`]: {
		marginBottom: 12,
	},
});

export const SliderRoot = styled(Slider.Root, {
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	userSelect: 'none',
	touchAction: 'none',
	width: '100%',
	height: thumbHeight,

	'&::before, &::after': {
		content: '',
		...hatchStyles,
	},

	'&::before': {
		left: 0,
	},

	'&::after': {
		right: 0,
	},
});

export const SliderTrack = styled(Slider.Track, {
	backgroundColor: '$muted',
	position: 'relative',
	flexGrow: 1,
	height: 1,
});

export const SliderRange = styled(Slider.Range, {
	position: 'absolute',
	backgroundColor: '$primaryBackground',
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$primary80',
	height: 4,
});

export const ThumbDotStyled = styled(Box, {
	borderRadius: '50%',
	width: 2,
	height: 2,
});

export const DotsContainer = styled(GridBox, {
	width: '100%',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	justifyContent: 'center',
	gap: 3,
});

export const ThumbDot = Object.assign(ThumbDotStyled, {
	toString: () => `.${ThumbDotStyled.className}`,
});

export const SliderThumb = styled(Slider.Thumb, {
	display: 'flex',
	alignItems: 'center',
	width: 8,
	height: thumbHeight,
	borderRadius: 4,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$muted',
	backgroundColor: '$vivid',
	boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(230, 232, 239, 0.24)',
	color: '$base',
	cursor: 'grab',

	[`& ${ThumbDot}`]: {
		backgroundColor: 'currentColor',
	},

	'&:hover': {
		borderColor: '$secondaryMuted',
		color: '$secondary',
	},

	'&:focus': {
		outline: 'none',
		borderColor: '$primaryMuted',
		color: '$primary',
	},
});

export const ContentBoxWrapper = styled(GridBox, {
	gap: 28,
	height: '100%',
	overflow: 'hidden',

	variants: {
		withDateSlider: {
			true: {
				gridAutoRows: 'auto auto 1fr',
			},
		},
	},
});

export const TrackDatesRow = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr 1fr',
	gap: '$3',
});

export const TrackDatesWrapper = styled(GridBox, {
	gridAutoRows: 'max-content',

	variants: {
		rowGap: {
			16: {
				rowGap: 16,
			},

			20: {
				rowGap: 20,
			},
		},
	},
});

export const Counter = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$background',
	height: 40,

	variants: {
		size: {
			small: {
				width: 40,
			},

			medium: {
				width: 80,
			},
		},
	},
});
