import {useCallback, useMemo} from 'react';
import {SortByOption, TestedOption, TestSectionTabId} from '../types';
import {TestModel} from '../../../types';
import {isTestGroupStudentsModel} from '../utils';
import {TestType} from '@esgi/main/libs/core';

export function useFilteredTests<TestModelType extends TestModel>({
	tests,
	activeTab,
	selectedTestedOption,
	selectedSortByOption,
}: {
	tests: TestModelType[];
	activeTab: TestSectionTabId;
	selectedTestedOption: TestedOption;
	selectedSortByOption: SortByOption;
}) {
	const sortTests = useCallback(
		(tests: TestModelType[]) => {
			if (selectedSortByOption === SortByOption.Default) {
				return;
			}

			if (selectedSortByOption === SortByOption.TestName) {
				tests.sort((a, b) => a.testInfo.name.localeCompare(b.testInfo.name));
				return;
			}

			if (selectedSortByOption === SortByOption.LastTested) {
				tests.sort((a, b) => {
					const aValue = a.analyticsData?.lastSessionDate;
					const bValue = b.analyticsData?.lastSessionDate;

					if (aValue && bValue) {
						return new Date(bValue).getTime() - new Date(aValue).getTime();
					}

					if (!aValue && bValue) {
						return 1;
					}

					if (aValue && !bValue) {
						return -1;
					}

					return 0;
				});

				return;
			}

			if (selectedSortByOption === SortByOption.ContentArea) {
				tests.sort((a, b) => a.testInfo.contentArea.localeCompare(b.testInfo.contentArea));
				return;
			}

			if (selectedSortByOption === SortByOption.NumberOfQuestions) {
				tests.sort((a, b) => {
					const aValue = a.testInfo.type === TestType.Score ? 1 : a.testInfo.totalPossible;
					const bValue = b.testInfo.type === TestType.Score ? 1 : b.testInfo.totalPossible;

					return aValue - bValue;
				});
				return;
			}

			if (selectedSortByOption === SortByOption.StudentsTestedPercentage) {
				tests.sort((a, b) => {
					if (isTestGroupStudentsModel(a) && isTestGroupStudentsModel(b)) {
						const aValue = a.studentsResult.testedStudents / a.studentsResult.totalStudents;
						const bValue = b.studentsResult.testedStudents / b.studentsResult.totalStudents;

						return aValue - bValue;
					}

					return 0;
				});
			}
		},
		[selectedSortByOption],
	);

	const filteredTests = useMemo(() => {
		const filteredTests = tests
			.filter(
				({testInfo}) =>
					activeTab === TestSectionTabId.All ||
					(activeTab === TestSectionTabId.Rubric && testInfo?.type === TestType.Rubric) ||
					(activeTab === TestSectionTabId.SingleScore && testInfo?.type === TestType.Score) ||
					(activeTab === TestSectionTabId.YesNo && testInfo?.type === TestType.YN),
			)
			.filter(
				({analyticsData}) =>
					selectedTestedOption === TestedOption.Any ||
					(selectedTestedOption === TestedOption.Yes && Boolean(analyticsData?.sessionCount)) ||
					(selectedTestedOption === TestedOption.No && !analyticsData?.sessionCount),
			);

		sortTests(filteredTests);

		return filteredTests;
	}, [tests, selectedTestedOption, activeTab, sortTests]);

	return {testItems: filteredTests};
}
