import {HttpClient, ObservableBuilder} from '@esgi/api';
import {HierarchySnapshot} from 'modules/hierarchy/models';

interface IResponse {
	correctAnswers: number,
	lastTestDate: string
}

export default function ReloadPieChart(testId: number, fullHierarchy: HierarchySnapshot): ObservableBuilder<IResponse> {
	return HttpClient.default.ESGIApi.get<IResponse>('pages/home/test-results', 'pie-chart', {testId: testId, hierarchy: fullHierarchy});
}
