import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	height: '100%',
	width: '100%',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: 'auto 1fr',
	justifyContent: 'space-between',

	variants: {
		columnGap: {
			20: {
				columnGap: 20,
			},

			48: {
				columnGap: '$8',
			},

			60: {
				columnGap: 60,
			},
		},
		isTablet: {
			true: {
				gridTemplateColumns: '1fr',
				gap: 12,
			},
		},
	},

	defaultVariants: {
		columnGap: '48',
	},
});

export const Sidebar = styled(GridBox, {
	overflow: 'hidden',
	gridTemplateRows: 'auto 1fr',
	gridTemplateColumns: 'minmax(280px, max-content)',

	variants: {
		gap: {
			16: {
				gap: '$4',
			},

			20: {
				gap: 20,
			},
		},
		isTablet: {
			true: {
				gridTemplateColumns: '1fr',
			},
		},
	},

	defaultVariants: {
		gap: '16',
	},
});
