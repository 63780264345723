import {useTransition} from 'react-spring';
import {AssignmentInfo} from '../../services/types';

export function useListAnimation(data: AssignmentInfo[]) {

	let height = 0;
	const itemHeight = 80;
	const animationDuration = 250;

	return useTransition(
		data.map(data => ({...data, y: (height += itemHeight) - itemHeight})),
		{
			key: (item: AssignmentInfo) => item.id,
			from: {opacity: 0, scale: 0.7, config: {duration: animationDuration}},
			enter: ({y}) => ({y, opacity: 1, scale: 1, config: {duration: animationDuration}}),
			leave: {opacity: 0, scale: 0.7, config: {duration: animationDuration}},
			update: ({y}) => ({y}),
		},
	);
}
