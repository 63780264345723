import {Typo, convertEnumToFontFamily, getNameByFormat, calculateMaxPossibleFontSize, getLecturerName} from '../utils';
import {from, map, Observable, toArray} from 'rxjs';
import {DownloadPDFRequest, FormatType, PrintSettings, UnitDataSettings} from '../types';

const linesCountByFontSize = [
	{
		fontSize: Typo.convertToPixels('30pt'),
		linesCount: 10,
	},
	{
		fontSize: Typo.convertToPixels('40pt'),
		linesCount: 7,
	},
	{
		fontSize: Typo.convertToPixels('62pt'),
		linesCount: 5,
	},
];

export class GenerateService {
	private readonly pageWidth = Typo.convertToPixels('180mm');

	public generateReport(
		unitDataSettings: UnitDataSettings,
		printSettings: PrintSettings,
	): Observable<Omit<DownloadPDFRequest, 'documentDate'>> {
		const lecturerName = getLecturerName(unitDataSettings.lecturer);
		const initialBodyFontSizeInPixel = Typo.convertToPixels(printSettings.selectedFontSize);
		const fontFamily = convertEnumToFontFamily(printSettings.fontFamily);
		const fontSizeCalculation = ({firstName, lastName, unitData}) => {
			const {type: unitType, name: unitName} = unitData;
			const bodyName = getNameByFormat(firstName, lastName, printSettings.nameFormat);
			const fullName = getNameByFormat(firstName, lastName, FormatType.FirstAndLastName);
			const minBodyFontSize = calculateMaxPossibleFontSize(bodyName, this.pageWidth, initialBodyFontSizeInPixel, fontFamily);
			const indexOfLastCheckedValue = linesCountByFontSize.findIndex(
				({fontSize}) => fontSize > minBodyFontSize.fontSize,
			);
			let linesCount = 0;
			if (indexOfLastCheckedValue === 0) {
				linesCount = linesCountByFontSize[0].linesCount;
			} else if (indexOfLastCheckedValue === -1) {
				linesCount = linesCountByFontSize[linesCountByFontSize.length - 1].linesCount;
			} else {
				linesCount = linesCountByFontSize[indexOfLastCheckedValue - 1].linesCount;
			}

			return {
				fullName,
				bodyName,
				bodyFontSize: minBodyFontSize.fontSize,
				titleNameFontSize: 60,
				linesCount,
				unitType,
				unitName,
			};
		};

		return from(unitDataSettings.students).pipe(
			map(fontSizeCalculation),
			toArray(),
			map((students) => ({
				lecturerName,
				copies: printSettings.copiesCount,
				students,
			})),
		);
	}

	public destroy() {}
}
