import {createContext, useContext} from 'react';

export type TimestampContextValue = {
	time: string;
	meridiem: string;
};

export const TimestampContext = createContext<TimestampContextValue>({
	time: '',
	meridiem: '',
});

export function useTimestampContext() {
	return useContext(TimestampContext);
}
