import 'array-flat-polyfill';
import {Navigate, Route} from 'react-router-dom';
import AdminHome from './routes/admin-home';
import Home from './routes/home';
import ParentConferencer from './routes/parent-conferencer';
import StudentManager from './routes/student-manager';
import StudentsScreen from './routes/students-screen';
import TestExplorer from './routes/test-explorer';
import AssignmentCenter from 'root/routes/assignment-center';
import {RoutesMap} from '@esgi/main/libs/core';
import './styles.less';
import {withErrorHandling} from '@esgillc/ui-kit/core';

const WrappedHome = withErrorHandling(Home);
const WrappedAdminHome = withErrorHandling(AdminHome);
const WrappedTestExplorer = withErrorHandling(TestExplorer);
const WrappedStudentManager = withErrorHandling(StudentManager);
const WrappedParentConferencer = withErrorHandling(ParentConferencer);
const WrappedAssignmentCenter = withErrorHandling(AssignmentCenter);
const WrappedStudentsScreen = withErrorHandling(StudentsScreen);

export function OldRoutes() {
	return [
		<Route key='home' path={RoutesMap.home} element={<WrappedHome />} />,
		<Route key='adminHome' path={RoutesMap.adminHome} element={<WrappedAdminHome />} />,
		<Route key='testExplorer' path={RoutesMap.testExplorer} element={<WrappedTestExplorer />} />,
		<Route key='studentManager' path={RoutesMap.studentManager} element={<WrappedStudentManager />} />,
		<Route key='parentConferencer' path={RoutesMap.parentConferencer} element={<WrappedParentConferencer />} />,
		<Route key='assignmentCenter' path={RoutesMap.assignmentCenter + '/*'} element={<WrappedAssignmentCenter />} />,
		<Route key='studentsScreen' path={RoutesMap.studentsScreen} element={<WrappedStudentsScreen />} />,
	];
}

export function NotFoundRoute() {
	return <Navigate to={RoutesMap.home} replace />;
}
