import {Button} from '@esgi/ui';
import {Box, Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardHeader = styled(Card.Header, {
	display: 'inline-grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
});

export const ActionButtonsDivider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
	borderRadius: 1,
});

export const ButtonIcon = styled(Button.Icon, {
	variants: {
		applyButtonSizes: {
			true: {
				width: 24,
				height: 24,
				color: '$base',
			},
		},
	},

	defaultVariants: {
		applyButtonSizes: true,
	},
});
