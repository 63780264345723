import {Text} from '@esgi/ui/typography';
import {LineBox, SessionTimeInfoBox, TooltipContainer} from './index.styled';
import {Button} from '@esgi/ui';
import {ExpandChart} from '@esgi/ui/icons';
import {GridBox} from '@esgi/ui/layout';
import {AnswersInfo, DateFormatText, TestSessionDelta, Timestamp} from '../../../../kit';
import {TestType} from '../../../../types';
import {useUser} from '@esgi/core/authentication';
import {LineChartData} from '../chart/types';

type Props = {
	payload: LineChartData;
	onOpenTestSessionDetailsAlert: (sessionID: number) => void;
	testType: TestType;
};

export const ChartTooltip = ({payload, onOpenTestSessionDetailsAlert, testType}: Props) => {
	const currentUser = useUser();

	const incorrectItems = payload.answers.filter((el) => el.answerState === 'Incorrect');
	const total = payload.totalPossible || payload.answers.length;
	const difference = payload.correctAnswers - payload.presentScore;

	return (
		<TooltipContainer>
			<GridBox align='center' justify='between' flow='column' gap='3' dataCy='session-date-container'>
				<Text size='small' font='mono' color='neutral40'>
					Session:
				</Text>
				<SessionTimeInfoBox>
					<DateFormatText
						date={payload.testDate}
						dateFormat='MM/DD/YY'
						size='small'
						font='mono'
						bold
						color='base'
					/>
					<Timestamp date={payload.testDate} backgroundColor='lowContrast' dataCy='session-time' />
				</SessionTimeInfoBox>
			</GridBox>

			<GridBox gap='2'>
				<LineBox />

				<GridBox flow='column' align='center' justify='between' dataCy='test-history-score'>
					<Text size='small' font='mono' color='neutral40'>
						Score:
					</Text>
					<AnswersInfo value={payload.correctAnswers} maxValue={total} />
				</GridBox>

				<GridBox flow='column' align='center' justify='between' dataCy='test-history-baseline-difference'>
					<Text size='small' font='mono' color='neutral40'>
						Baseline Difference:
					</Text>
					<TestSessionDelta value={difference} decimals={0} />
				</GridBox>

				{testType === TestType.YN && (
					<>
						<GridBox flow='column' align='center' justify='between' dataCy='test-history-correct'>
							<Text size='small' font='mono' color='neutral40'>
								{currentUser?.testResultsCorrectVerbiage ?? 'Correct'}:
							</Text>
							<Text size='small' font='mono' bold color='base'>
								{payload.correctAnswers}
							</Text>
						</GridBox>

						<GridBox flow='column' align='center' justify='between' dataCy='test-history-incorrect'>
							<Text size='small' font='mono' color='neutral40'>
								{currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect'}:
							</Text>
							<Text size='small' font='mono' bold color='base'>
								{incorrectItems.length}
							</Text>
						</GridBox>
					</>
				)}
			</GridBox>

			<Button color='secondary' onClick={() => onOpenTestSessionDetailsAlert(payload.id)} dataCy='test-history-view-details'>
				<ExpandChart height={24} width={24} />
				<Text size='medium' bold color='base'>
					View Details
				</Text>
			</Button>
		</TooltipContainer>
	);
};
