import {styled} from '@esgi/ui/theme';
import {Checkbox} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {FlexBox, GridBox} from '@esgi/ui/layout';

export const NameCheckbox = styled(Checkbox, {
	padding: 0,
	'& > div': {
		background: '$border',
		borderColor: '$vivid',
	},

	variants: {
		active: {
			true: {
				'& > div': {
					background: '$primary92',
					borderColor: '$vivid',
				},
			},
		},
		disabled: {
			true: {
				'& > div': {
					background: '$mild',
				},
				'& svg': {
					'& path': {
						fill: '$lowContrast',
					},
				},
			},
		},
	},
});

export const Cell = styled(FlexBox, {
	alignItems: 'center',
	width: '100%',
	height: '100%',
});

export const StyledText = styled(Text, {
	color: '$base',
	variants: {
		selected: {
			true: {
				color: '$primary',
			},
		},
		disabled: {
			true: {
				color: '$muted',
			},
		},
	},
});

export const Row = styled(GridBox, {
	height: 48,
	alignItems: 'center',
	justifyContent: 'start',
	gap: 12,
	gridAutoFlow: 'column',
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 10,
	paddingRight: 10,
	background: '$vivid',
	borderRadius: 6,
	variants: {
		selected: {
			true: {
				border: '1px solid $primaryMuted',
				background: '$primary98',
			},
		},
	},
});
