import {dispatchAppEvent} from '@esgillc/events';
import {LoadScriptFailedEvent} from './events';

/**
 * Build asynchronous class loader.
 * @param factory import statement wrapped with arrow function.
 * @example
 * const lazy = lazyClass(() => import('./a.ts'));
 * await lazy.load();
 * @return LazyModule
 * @tutorial
 */
export function lazyClass<T>(factory: () => Promise<{ default: T }>): LazyModule<T> {
	return new LazyModule<T>(factory);
}

/* The LazyModule class is a TypeScript class that allows for lazy loading of modules by asynchronously
loading them using a factory function. */
class LazyModule<T> {
	constructor(private factory: () => Promise<{ default: T }>) {
	}

	public async load(): Promise<T> {
		try {
			let res = await this.factory();
			if (res) {
				return res.default;
			}
		} catch (err) {
			console.error(err);
			dispatchAppEvent(LoadScriptFailedEvent);
			throw err;
		}
	}
}
