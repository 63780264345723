import {styled} from '@stitches/react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {MutableRefObject} from 'react';
import {SelectOption} from '../../types';

interface Props {
	value?: string;
	contentRef: MutableRefObject<HTMLDivElement>;
	fieldRef: MutableRefObject<HTMLButtonElement>;
	options: SelectOption[];
	onChange: (value: SelectOption['value']) => void;
}

const SelectField = styled(Select.Field, {
	width: 80,
	height: 40,
	paddingLeft: '12px',
	paddingRight: '6px',
	'& span:first-child': {
		marginTop: 0,
		marginBottom: '2px',
	},
});

export function SchoolYearSelect({value, contentRef, options, onChange}: Props) {
	return <Select.Root onValueChange={onChange} dataCy='school-years' value={value}>
		<SelectField placeholder='Year'/>
		<Select.Content ref={contentRef}>
			{options?.map(({name, value}) => <Select.Option value={value} key={value}>
				<Text data-cy='year' size='medium' bold>
					{name}
				</Text>
			</Select.Option>)}
		</Select.Content>
	</Select.Root>;
}
