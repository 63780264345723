import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const WrapperBox = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',

	variants: {
		withClassesSelect: {
			true: {
				gridTemplateRows: 'auto 1fr',
				gap: '$5',
			},
		},
	},
});
