import {
	FormElement,
	FormField,
	FormFieldValidator,
	Renderable,
} from '@esgi/deprecated/knockout';

export abstract class FieldContainer<T = any> extends Renderable {
	protected field: FormField<T>;
	private resizeTimer;

	constructor(initValue?) {
		super();
		this.field = this.buildField(initValue);
	}

	protected createField<T>(
		value?: T | KnockoutObservable<T>,
		...validators: FormFieldValidator[]
	) {
		const f = new FormField<T>(value);
		f.validation.errorValidation(true);
		f.validation.validators = f.validation.validators.concat(validators);
		return f;
	}

	abstract buildField(initValue?): FormField<T>;

	setErrorPosition(position: string) {
		FieldTool.setErrorPosition(this.field, position);
	}

	get validation() {
		return this.field.validation;
	}

	validate(): JQueryPromise<boolean> {
		return this.field.validation.validate();
	}

	removeTooltip() {
		this.field.validation.removeError();
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		if (
			this.field &&
			this.field.value() &&
			!(this.field.value() instanceof Array)
		) {
			this.field.validation.validate();
		}
		const helper = $('div.error-message', rootElement);
		if (helper[0]) {
			this.resolveValidationDisplaying();
			$(window).resize(() => {
				clearTimeout(this.resizeTimer);
				this.resizeTimer = setTimeout(() => {
					this.resolveValidationDisplaying();
				}, 300);
			});
		}
		return super.afterRender(rootElement);
	}

	private resolveValidationDisplaying() {
		const width = $(window).width();
		if (width <= 1024) {
			FieldTool.showTooltipAfterValidation(this.field, false);
		} else {
			FieldTool.showTooltipAfterValidation(this.field, true);
		}
	}

	serialize(): T {
		return this.field.serialize();
	}
}

export class FieldTool {
	public static setErrorPosition(field: FormElement, position: string): void {
		if (field.validation.errorPosition !== position) {
			field.validation.errorPosition = position;
			const lastResult = field.validation.validationResults();
			if (lastResult.length) {
				field.validation.removeError();
				field.validation.showError(lastResult[0], true);
			}
		}
	}

	public static scrollToField(
		field: FormElement | FieldContainer,
		duration = 150,
		easing = 'linear',
	) {
		const $modal = $('.registration-modal');
		const modalOffset = $modal[0] ? $modal.offset().top : 170;
		const scrollPos = $(window).scrollTop() + modalOffset;
		if (field.rootElement.offset().top <= scrollPos) {
			if (field.rootElement) {
				$('html, body').animate(
					{
						scrollTop: $(field.rootElement).offset().top - modalOffset,
					},
					duration,
					easing,
				);
			}
		}
	}

	public static showTooltipAfterValidation(field: FormElement, show: boolean) {
		field.validation.showTooltip(show);
		if (!show) {
			field.validation.removeError();
		} else {
			const lastMsg = field.validation.validationResults()[0];
			if (lastMsg) {
				field.validation.showError(lastMsg, true);
			}
		}
	}
}

export abstract class AsyncFieldContainer<T> extends FieldContainer<T> {
	public inProcess = ko.observable<boolean>();
}
