import {Button} from '@esgi/ui';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	overflow: 'hidden',
	flexDirection: 'column',
	height: 'fit-content',
	maxHeight: '100%',
});

export const SessionDateInfo = styled(GridBox, {
	alignItems: 'center',
	gridAutoFlow: 'column',
	gap: '$2',
	gridTemplateColumns: 'minmax(75px, auto) auto',
});

export const AnswersInfoBox = styled(GridBox, {
	justifyContent: 'end',
	minWidth: 40,
});

export const LoadMoreButton = styled(Button, {
	display: 'inline-grid',
	justifyContent: 'start',
	columnGap: '$3',
	gridAutoFlow: 'column',
	marginTop: '$2',
});
