import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const Wrapper = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr auto',
	alignItems: 'center',
	width: '100%',
	height: '100%',
	borderRadius: '6px',
	minHeight: '104px',
	objectFit: 'cover',
	backgroundColor: '$vivid',
});

export const ControlButton = styled(Button.Icon, {
	variants: {
		prev: {
			true: {
				'& > svg': {
					transform: 'rotate(90deg)',
				},
			},
		},
		next: {
			true: {
				'& svg': {
					transform: 'rotate(-90deg)',
				},
			},
		},
	},
});

export const ImageWrapper = styled(FlexBox, {
	backgroundColor: 'black',
	objectFit: 'cover',
	borderRadius: '6px',
	overflow: 'hidden',
	width: '168px',
	height: '74px',
	variants: {
		isListView: {
			true: {
				width: '227px',
				height: '100px',
			},
		},
	},
});