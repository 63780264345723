import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';

export const Section = styled(Box, {
	padding: '0 20px',
});

export const Title = styled(Text, {
	padding: '20px 0 12px',
	margin: '0 12px 12px',
	borderBottom: '1px solid $mild',
	textAlign: 'center',
	display: 'block',
});

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
	position: 'relative',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr 1fr',
	gap: '2px',
	backgroundColor: '$vivid',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	padding: '12px 20px',

	'&[data-state=off] + [data-state=off]:before': {
		display: 'none',
	},
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	position: 'absolute',
	width: '100%',
	marginTop: 6,
});

export const Label = styled(Text, {
	textAlign: 'center',
});
