import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(Box, {
	display: 'inline-grid',
	gap: 4,
	gridAutoFlow: 'column',
	alignItems: 'center',

	'& > svg': {
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});
