import {SnackbarManager} from '@esgi/ui/snackbar';
import {RefObject, useCallback, useMemo, useState} from 'react';
import {Avatar} from '@esgi/ui/avatar';
import {Text} from '@esgi/ui/typography';
import {Button, LinkDelete} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';

import {ClassModel, StudentModel} from '../../types';
import {Container, Item, Title} from './styled';
import {StudentManagerService} from '../../service';

type Props = {
	student: StudentModel,
	classes: ClassModel[],
	snackbarRef: RefObject<SnackbarManager>,
	service: StudentManagerService,
	onSuccess: VoidFunction,
	onClose: VoidFunction,
}

export function UnassignDialog({student, ...props}: Props) {
	const alertRef = Alert.useRef();
	const close = Alert.useClose(alertRef, props.onClose);
	const [loading, setLoading] = useState(false);

	const confirm = useCallback(() => {
		setLoading(true);
		props.service.unassign([student.studentID]).subscribe({
			next: () => {
				close();
				props.onSuccess();
				props.snackbarRef.current.showSnackbar(<Text>{student.firstName} {student.lastName} has been unassigned from all classes and groups</Text>);
			},
			complete: () => setLoading(false),
		});
	}, [props.service, props.onSuccess, close, props.snackbarRef, student]);

	const classes = useMemo(() => {
		if (!student) {
			return [];
		}
		return props.classes.filter(c => student.classIDs.includes(c.classID));
	}, [props.classes, student]);
	const groups = useMemo(() => {
		if (!student) {
			return [];
		}

		return classes.map(c => c.groups).flat().filter(g => student.groupIDs.includes(g.groupID));
	}, [classes, student]);

	const hasGroups = !!groups.length;

	if (!student) {
		return null;
	}

	return <Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.neutral} dataCy='unassign-dialog'>
		<Alert.Header withBacklight={false} onCloseIconClick={close} disableClose={loading}>
			<Avatar.Root size='md'>
				<Avatar.Fallback>
					{student.firstName[0]} {student.lastName[0]}
				</Avatar.Fallback>
			</Avatar.Root>
			<Text color='primary' size='small' css={{paddingLeft: 12}}>{student.firstName} {student.lastName}</Text>
		</Alert.Header>
		<Alert.Body>
			<Container>
				<Text color='neutral40' size='medium' data-cy='unassign-info'>
					This student will be unassigned from the following classes{hasGroups && ' and groups'}:
				</Text>
				{hasGroups && <Title dataCy='title-classes'>
					<Text size='small' color='lowContrast'>Classes</Text>
				</Title>}
				{classes.map(c => <Item key={c.classID} dataCy='class-name'>
					<LinkDelete width={24} height={24}/>
					<Text key={c.classID} color='negative' size='small'>
						{c.name}
					</Text>
				</Item>)}
				{hasGroups && <>
					<Title dataCy='title-groups'>
						<Text size='small' color='lowContrast'>Groups</Text>
					</Title>
					{groups.map(g => <Item key={g.groupID} dataCy='group-name'>
						<LinkDelete width={24} height={24}/>
						<Text key={g.groupID} color='negative' size='small'>
							{g.name}
						</Text>
					</Item>)}
				</>}
			</Container>
		</Alert.Body>
		<Alert.Footer>
			<Button css={{marginRight: 12}} color='tertiary' onClick={close} disabled={loading}>
				<Text size='medium' color='base'>
					Cancel
				</Text>
			</Button>
			<Button color='secondary' onClick={confirm} disabled={loading}>
				<Text size='medium' color='base'>
					Unassign
				</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}