import React from 'react';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {Modal} from '@esgillc/ui-kit/modal';
import NavigationBar from '../../shared/components/navigation-bar/navigation-bar';
import {Radio} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import DataService from './data-service';
import styles from './sight-words-test-selector-step.module.less';
import {SightWordsTest, TestSelectorState} from './types';
import SelectorBox from '../../shared/components/selector-box/selector-box';
import SelectorBoxHeader from '../../shared/components/selector-box/header';
import SelectorBoxBody from '../../shared/components/selector-box/body';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {StudentStepState} from '../../shared/types';

interface Props {
  hierarchy: HierarchySnapshot;
  unitData: StudentStepState;
  goToBack: () => void;
  currentStep: number;
  totalStepsCount: number;
  goToNext: (data: TestSelectorState) => void;
  testSelectorState: TestSelectorState;
}

class State {
  isAscOrder: boolean = true;
  items: SightWordsTest[] = [];
  selectedTest?: number;
}

export default class SightWordsTestSelectorStep extends React.PureComponent<Props, State> {
  private readonly service = new DataService();
  public override readonly state = new State();

  componentDidMount() {
    this.service.init(this.props.hierarchy).subscribe(result => {
      const items = this.service.sortItems(result, this.state.isAscOrder);
      this.setState({items});
      if (items.length === 1){
        this.setState({selectedTest: items[0].id});
      }
    });
    if (this.props.testSelectorState != null){
      this.setState({selectedTest: this.props.testSelectorState.selectedTestId});
    }
  }

  componentWillUnmount() {
    this.service.destroy();
  }

  render() {
    return <>
      <ServiceLoader trackingService={this.service}/>
      <Modal.Body>
        <FlexBox align='stretch' justify='stretch' direction='column' className={styles.body}>
          <div className={styles.unitName}>
            {this.props.unitData.students[0]?.units?.[0]?.name}
          </div>
          <div className={styles.bodyTitle}>
            Select a Sight Words test to automatically generate a template.
          </div>
          <div>
            <SelectorBox className={styles.table}>
              <SelectorBoxHeader>
                <FlexBox direction='row'>
                  <FlexBox className={styles.header}>
                    Sight Words Tests
                    {
                      this.state.items.length > 1 &&
                        <span onClick={e => this.onChangeSortDirection()}
                              className={join(
                              !this.state.isAscOrder && 'fa fa-caret-down',
                              this.state.isAscOrder && 'fa fa-caret-up',
                              styles.order)}>
                        </span>
                    }
                  </FlexBox>
                  <FlexBox className={styles.header}>
                    Subject Tab
                  </FlexBox>
                </FlexBox>
              </SelectorBoxHeader>
              <SelectorBoxBody className={styles.tableBody}>
                {this.state.items?.map(item => this.renderItem(item))}
              </SelectorBoxBody>
            </SelectorBox>
          </div>
        </FlexBox>
      </Modal.Body>
      <Modal.Footer>
        <NavigationBar prevStepEnabled={true}
                       nextStepEnabled={this.state.selectedTest != null}
                       currentStep={this.props.currentStep}
                       totalSteps={this.props.totalStepsCount}
                       nextClicked={() => this.onGoToNext()}
                       previousClicked={() => this.props.goToBack()}/>
      </Modal.Footer>
    </>;
  }

  private renderItem(item: SightWordsTest) {
    return <FlexBox direction='row' key={item.id}>
      <FlexBox align='center' className={join(styles.item, styles.itemSelector)}>
        <Radio value={item.id}
               checked={this.state.selectedTest === item.id}
               className={styles.nameFormatRadio}
               onChange={() => this.onSelectTest(item)}>
          {item.name}
        </Radio>
      </FlexBox>
      <FlexBox align='center' className={styles.item}>
        <div className={styles.subjectTabs}>{item.subjectTabs?.join(', ')}</div>
      </FlexBox>
    </FlexBox>
  }

  private onChangeSortDirection() {
    const newOrder = !this.state.isAscOrder;
    const sortedItems = this.service.sortItems(this.state.items, newOrder)
    this.setState({
      isAscOrder: newOrder,
      items: sortedItems,
    });
  }

  private onGoToNext() {
    this.props.goToNext(new TestSelectorState(this.state.selectedTest))
  }

  private onSelectTest(item: SightWordsTest) {
    this.setState({
      selectedTest: item.id,
    });
  }
}
