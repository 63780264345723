import {forwardRef, useEffect, useState} from 'react';
import {TabID} from '../../types';
import {useDatePickerRootContext} from '../../context';
import {DateRangeToggle} from '../date-range-toggle';
import {DayButton} from '../day-button';
import {parseDateRangeToString} from '../../utils';
import {Enter, Prev} from '../../../../icons';
import {styled} from '../../../../theme';
import {Text} from '../../../../typography';
import {Box} from '../../../../layout/box';
import {Button} from '../../../../buttons';
import {BaseComponentProps} from '../../../../types';

export const DatePickerCalendarPanel = forwardRef<HTMLDivElement, BaseComponentProps>(({
	dataCy = 'ui-kit-date-picker-calendar-panel',
	...props
}, ref) => {
	const {
		setExpanded,
		setCalendarRendered,
		rangeMode,
		setDateValue,
		calendars,
		weekDays,
		addOffset,
		subtractOffset,
		dayButton,
		setApprovedDate,
		dateObjects,
		format = 'mM/dD/YYYY',
	} = useDatePickerRootContext();

	useEffect(() => {
		setCalendarRendered(true);
	}, [setCalendarRendered]);

	const {days, month, year} = calendars[0] ?? {};

	const [selectedTabID, setSelectedTabID] = useState<TabID>('start');
	const selectedDateString = parseDateRangeToString(dateObjects, format);

	const onConfirmButtonClicked = () => {
		setDateValue(parseDateRangeToString(dateObjects, format));
		setApprovedDate(dateObjects);
		setExpanded(false);
	};

	const onStartEndChange = (v: 'start' | 'end') => {
		if (selectedTabID !== v) {
			const elementSelector = v === 'start' ? '.range-start' : '.range-end';
			setSelectedTabID(v);
			(document.querySelector(elementSelector) as HTMLButtonElement)?.click?.();
		}
	};

	useEffect(() => {
		setSelectedTabID((dateObjects.length === 2 || !dateObjects.length) ? 'start' : 'end');
	}, [dateObjects]);

	return <CalendarPanel data-cy={dataCy} ref={ref} {...props}>
		{rangeMode && <DateRangeToggle selectedTabID={selectedTabID} onChange={onStartEndChange}/>}

		<Controller dataCy='calendar-panel-controller'>
			<Button.Icon dataCy='prev-button' {...subtractOffset?.({months: 1})}>
				<Prev/>
			</Button.Icon>
			<Text data-cy='current-date' size='small' font='mono' color='mediumContrast'>{month} {year}</Text>
			<Button.Icon dataCy='next-button' {...addOffset?.({months: 1})}>
				<Enter/>
			</Button.Icon>
		</Controller>

		<WeekDays>
			{weekDays.map((d) => (
				<p key={d}>{d.slice(0, 2).toUpperCase()}</p>
			))}
		</WeekDays>

		<DaysTable>
			{days?.map((d, index) => {
				return <DayButton key={index} dayButton={dayButton} dayData={d}/>;
			})}
		</DaysTable>

		<SelectedDate dataCy={rangeMode ? 'selected-range-date': 'selected-single-date'}>
			{selectedDateString}
		</SelectedDate>

		<ConfirmButton dataCy='confirm-button'
					   onClick={onConfirmButtonClicked}
					   disabled={rangeMode ? dateObjects.length < 2 : !dateObjects.length}
						 color='secondary'>
				<Text size='medium' bold>Confirm</Text>
		</ConfirmButton>
	</CalendarPanel>;
});

const CalendarPanel = styled(Box, {
	width: '232px',
	padding: '8px',
	border: '1px 1px 0 1px',
	borderRadius: '0 0 8px 8px',
	backgroundColor: '$vivid',
	marginTop: '6px',
});

const Controller = styled(Box, {
	padding: '8px 6px 8px 6px',
	width: '100%',
	display: 'grid',
	gridTemplateColumns: '1fr 8fr 1fr',
	justifyItems: 'center',
	height: '40px',
	alignItems: 'center',
});

const WeekDays = styled(Box, {
	height: '40px',
	padding: '14px 8px 14px 8px',
	display: 'grid',
	gridTemplateColumns: 'repeat(7, 24px)',
	gridGap: '8px',
	justifyContent: 'center',
	justifyItems: 'center',

	'& p': {
		width: '24px',
		height: '12px',
		fontWeight: '500',
		fontSize: '10px',
		lineHeight: '12px',
		color: '$natural64',
		marginBottom: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

const DaysTable = styled(Box, {
	padding: '16px 8px 16px 8px',
	backgroundColor: '$natural99',
	display: 'grid',
	gridTemplateColumns: 'repeat(7, 24px)',
	gridTemplateRows: 'repeat(5, 24px)',
	gridGap: '8px',
	justifyContent: 'center',
	justifyItems: 'center',
});

const SelectedDate = styled(Box, {
	color: '$secondary',
	height: '40px',
	fontSize: '11px',
	lineHeight: '12px',
	fontWeight: '700',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const ConfirmButton = styled(Button, {
	width: '100%',
});


