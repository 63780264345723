import {Typo, convertEnumToFontFamily, calculateMaxPossibleFontSize, getNameByFormat, getLecturerName} from '../utils';
import {DownloadPDFRequest, PrintSettings, UnitDataSettings, FontSizeCalculationResult, FontFamily, FormatType} from '../types';
import {groupBy} from 'underscore';

export class GenerateService {
	private readonly pageWidth = Typo.convertToPixels('180mm');
	private readonly columnSpacing = 20;

	public generateReport(
		unitDataSettings: UnitDataSettings,
		printSettings: PrintSettings,
	): Omit<DownloadPDFRequest, 'documentDate'> {
		const totalColumnSpacing = this.columnSpacing * (printSettings.columnsStyle.length - 1);
		const columnWidth = this.pageWidth / printSettings.columnsStyle.length - totalColumnSpacing;
		const fontSize = Typo.convertToPixels(printSettings.selectedFontSize);
		// The font size should be such that the longest word would fit in the column
		const wordWithMaxLength = unitDataSettings.questions
			.map(({name}) => name)
			.sort((left, right) => left.length - right.length)
			.at(0);
		const maxFontSize = printSettings.columnsStyle
			.map((fontStyle) => this.calculateMaxPossibleFontSize(
				wordWithMaxLength,
				columnWidth,
				fontSize,
				fontStyle
			))
			.sort((left, right) => left.fontSize - right.fontSize)
			.at(0);

		const columns = printSettings.columnsStyle.map(
			(fontFamily) => ({fontFamily})
		);
		const groupedTests = groupBy(unitDataSettings.questions, 'testName');
		const students = unitDataSettings.students.map(({firstName, lastName, unitData}) => {
			const fullName = getNameByFormat(firstName, lastName, FormatType.FirstAndLastName);
			const {type: unitType, name: unitName} = unitData;
			return {fullName, unitType, unitName};
		});

		const tests = Object.keys(groupedTests).map((testName) => ({
			testName,
			blankLine: '',
			columns,
			words: groupedTests[testName].map(({name: word}) => ({word})),
		}));

		return {
			lecturerName: getLecturerName(unitDataSettings.lecturer),
			fontSize: maxFontSize.fontSize,
			copies: printSettings.copiesCount,
			students,
			tests,
		};
	}

	public destroy() {}

	private calculateMaxPossibleFontSize(
		text: string | null,
		columnSize: number,
		initialFontSize: number,
		fontStyle: FontFamily
	): FontSizeCalculationResult {
		if (text === null) {
			return {fontSize: 0} as FontSizeCalculationResult;
		}
		const fontFamily = convertEnumToFontFamily(fontStyle);
		return calculateMaxPossibleFontSize(text, columnSize, initialFontSize, fontFamily);
	}
}
