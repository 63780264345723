import {
	SelectableListItem,
	SelectableListRoot,
	SelectableListTriggerContent,
	SelectableListTrigger,
	Counter,
} from './index.styled';
import {getThemeColor} from '@esgi/ui';
import {SelectableList as UISelectableList} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Student} from '@esgi/main/libs/store';
import {Folder, AccessError, CheckAll} from '@esgi/ui/icons';
import {Dispatch, useCallback, useLayoutEffect, useState} from 'react';
import {StudentFullName} from '@esgi/main/features/teacher/home';

type Props = {
	triggerLabel?: string;
	students: Student[];
	onStudentClick: (value: Student['id']) => void;
	selectedStudentsIDs: Student['id'][];
	initialOpened?: boolean;
	toggleEntityStudents: Dispatch<{studentsIDs: Student['id'][]; method: 'add' | 'remove'}>;
};

export function ExpandableList({
	students,
	triggerLabel,
	onStudentClick,
	selectedStudentsIDs,
	initialOpened,
	toggleEntityStudents,
}: Props) {
	const [_, setForceUpdate] = useState({});
	const [isListOpened, setIsListOpened] = useState<boolean>(initialOpened ?? false);

	useLayoutEffect(() => {
		if (isListOpened) {
			setForceUpdate({});
		}
	}, [students]);

	const handleTriggerClick = useCallback(() => {
		if (students.length) {
			setIsListOpened((opened) => !opened);
		}
	}, [students]);

	const handleStyledButtonClick = useCallback(() => {
		const studentsIDsInList = students.map(({id}) => id);

		if (studentsIDsInList.every((id) => selectedStudentsIDs.includes(id))) {
			toggleEntityStudents({studentsIDs: studentsIDsInList, method: 'remove'});

			return;
		}

		toggleEntityStudents({
			studentsIDs: studentsIDsInList.filter((id) => !selectedStudentsIDs.includes(id)),
			method: 'add',
		});
	}, [selectedStudentsIDs, students, toggleEntityStudents]);

	return (
		<SelectableListRoot isListOpen={isListOpened}>
			<UISelectableList.GroupRoot type='multiple' value={selectedStudentsIDs.map(String)}>
				<SelectableListTrigger
					IconBefore={Folder}
					openList={isListOpened}
					hovered={!students.length ? false : undefined}
					defaultCursor={!students.length}
					onClick={handleTriggerClick}
				>
					<SelectableListTriggerContent>
						<Text size='large' color='currentColor'>
							{triggerLabel}
						</Text>
						<Counter>
							<Text size='small' font='mono'>
								{students.length}
							</Text>
						</Counter>

						{Boolean(students.length) && (
							<UISelectableList.StyledButton onClick={handleStyledButtonClick}>
								<CheckAll />
							</UISelectableList.StyledButton>
						)}
					</SelectableListTriggerContent>
				</SelectableListTrigger>
				<UISelectableList.Group>
					{students.map(({firstName, lastName, id, hasCredentials}) => (
						<SelectableListItem value={String(id)} key={id} onClick={() => onStudentClick(id)}>
							<StudentFullName firstName={firstName} lastName={lastName} />
							{!hasCredentials && <AccessError width={24} height={24} color={getThemeColor('negative')} />}
						</SelectableListItem>
					))}
				</UISelectableList.Group>
			</UISelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
