import type {SVGProps} from 'react';

export function ListOrdered(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
			<path d='M9 7c0-.6.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1h-9c-.6 0-1-.4-1-1zM9 12c0-.6.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1h-9c-.6 0-1-.4-1-1zM9 17c0-.6.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1h-9c-.6 0-1-.4-1-1z' />
			<path d='M3.9 6.7c.5-.2.9-.4 1.2-.7H6v4.5H5V7c-.2.2-.6.4-1.1.6v-.9zM4 17.1 5.1 16l.7-.7c.3-.3.3-.4.3-.7 0-.3-.2-.6-.5-.6-.5 0-.6.5-.6.7H4c0-.2 0-.5.3-.9.3-.3.7-.5 1.3-.5 1 0 1.5.5 1.5 1.2 0 .5-.2 1.1-2.1 2.7h2.1v.8H4v-.9z' />
		</svg>
	);
}
