import {from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {SaveResult} from 'modules/subject-details/types';
import {subjectNameValidator} from 'modules/subject-details/forms/subject-name-validator';
import {createPersonalForm} from 'modules/subject-details/forms/personal';

export class PersonalDetailsService extends BaseService {

	public form = createPersonalForm();

	private personalController = 'subjects/personal';
	private subjectID: number;

	public init = (subjectID?: number, subjectName?: string) => {
		this.subjectID = subjectID;

		if(subjectID){
			this.form.controls.subjectName.value = subjectName;
		}
		this.form.controls.subjectName.validators
			.push((validationChain) => subjectNameValidator(this.httpClient, subjectID, validationChain));
	};

	public save = (subjectID) => {
		return this.form.validate()
			.pipe(switchMap(response => {
				if (response.valid) {
					const {subjectName} = this.form.value;

					if (subjectID) {
						return this.updatePersonalTab(subjectID, subjectName);
					} else {
						return this.createPersonalTab(subjectName);
					}
				}else {
					return from([]);
				}
			}));
	};

	private createPersonalTab(subjectName: string) {
		return this.httpClient.ESGIApi.post<SaveResult>(this.personalController, 'create', {name: subjectName});
	}

	private updatePersonalTab(id: number, name: string) {
		return this.httpClient.ESGIApi.post<SaveResult>(this.personalController, 'update', {id, name});
	}
}
