import React, {Fragment, Suspense} from 'react';
import {userStorage} from '@esgi/core/authentication';
import {TestType} from '@esgi/core/enums';
import {TestModel} from '../types';
import {lazyComponent} from '@esgi/core/react';

const TestDetails = lazyComponent(() => import('../../../test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

class State {
	testDetailsOpened: boolean = false;
}

interface Props {
	onSelected: (checked: boolean) => any;
	selected: boolean;
	model: TestModel;
}

export class Row extends React.Component<Props, State> {
	public state = new State();
	private readonly user = userStorage.get();

	render() {
		const {name, id, author, date, questions} = this.props.model;
		return <tr data-name={name}>
			<td>
				<label>
					<input type='checkbox' className='ace' checked={this.props.selected}
					       onChange={(e) => this.checkedChanged(e.target.checked)}/>
					<span className='lbl'/>
				</label>
			</td>
			<td className='name'>
				<a href='#' onClick={() => this.openTestDetails()}>
					{name}
				</a>
				{this.state.testDetailsOpened && this.props.model.type !== TestType.Rubric &&
					<Suspense fallback={<Fragment/>}>
						<TestDetails
							testID={id}
							firstName={this.user.firstName}
							lastName={this.user.lastName}
							userID={this.user.userID}
							close={() => this.setState({testDetailsOpened: false})}
							disableCopyTest={true}
						/>
					</Suspense>
				}
				{this.state.testDetailsOpened && this.props.model.type === TestType.Rubric &&
					<Suspense fallback={<Fragment/>}>
						<RubricDetails testID={id}
						               disableCopy
						               disableAddToSubject
						               close={() => this.setState({testDetailsOpened: false})}/>
					</Suspense>
				}
			</td>
			<td className='author'>{author}</td>
			<td className='create-date'>{date}</td>
			<td className='questions'>{questions}</td>
		</tr>;
	}

	checkedChanged(checked: boolean) {
		this.props.onSelected(checked);
	}

	private openTestDetails() {
		this.setState({testDetailsOpened: true});
	}
}

export class LoadingRow extends React.Component {
	render() {
		return <tr>
			<td colSpan={5}>
				Loading <i className='fa fa-spin fa-refresh'/>
			</td>
		</tr>;
	}
}
