import React from 'react';
import {IGraphStyle, ShapeType} from 'shared/modules/question-editor/models';
import {ValidationTooltip, OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {ShapeSelector} from 'shared/modules/question-editor/mx/editor/toolbar-menu/shape-selector/shape-selector';
import {EventBusManager} from '@esgillc/events';
import {ShowValidation} from 'shared/modules/question-editor/form-events';
import './toolbar-menu.less';
import {isSafari} from '@esgillc/ui-kit/utils';

export class Props {
	shapeStyle: IGraphStyle;
	shapeType: ShapeType;
	added: (type: ShapeType) => void;
}

export class State {
	showValidation: boolean = false;
}

export class ToolbarMenu extends React.PureComponent<Props> {
	validationRef: HTMLDivElement = null;
	state: State;
	isSafari: boolean;
	private eventBus = new EventBusManager();
	
	constructor(props, context) {
		super(props, context);
		this.state = new State();
		this.isSafari = isSafari();
	}

	renderImageIcon() {
		return <svg onClick={() => {
			this.setState({showValidation: false});
			this.addShape(ShapeType.Image);
		}} className='image-item' width='31' height='31' viewBox='0 0 31 31' fill='none'
					xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M31 27.5556V3.44444C31 1.55 29.45 0 27.5556 0H3.44444C1.55 0 0 1.55 0 3.44444V27.5556C0 29.45 1.55 31 3.44444 31H27.5556C29.45 31 31 29.45 31 27.5556ZM9.47222 18.0833L13.7778 23.2672L19.8056 15.5L27.5556 25.8333H3.44444L9.47222 18.0833Z'
				fill={this.props.shapeType === ShapeType.Image ? '#0088CC' : '#828282'}/>
		</svg>;
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, prevContext: any): void {
		if (!prevProps.shapeType && !!this.props.shapeType) {
			this.setState({showValidation: false});
		}
	}

	componentDidMount() {
		this.eventBus.subscribe(ShowValidation, (args: ShowValidation.Args) => {
			this.setState({showValidation: !args.canvasValid});
		});
	}

	componentWillUnmount() {
		this.eventBus.destroy();
	}
	
	addShape(shapeType: ShapeType) {
		this.props.added(shapeType);
	}

	render() {
		return <div ref={(ref) => this.validationRef = ref} className='mx-question-editor-toolbar-menu'>
			{(this.validationRef && this.state.showValidation) &&
			<div className='mx-question-editor-toolbar-menu-validation'></div>}
			<OnHoverTooltip message='Image'>
				{this.renderImageIcon()}
			</OnHoverTooltip>
			<OnHoverTooltip message='Text'>
				<div onClick={() => this.addShape(ShapeType.Text)}
					 className={(this.props.shapeType === ShapeType.Text) ? 'toolbar-item selected' : 'toolbar-item'}>
					<svg width='15' height='17' viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M14.7305 4.88281H13.9219C13.8516 4.60156 13.7227 4.26953 13.5352 3.88672C13.3555 3.49609 13.1523 3.12891 12.9258 2.78516C12.6914 2.42578 12.4336 2.11328 12.1523 1.84766C11.8789 1.57422 11.6133 1.41797 11.3555 1.37891C11.1133 1.34766 10.8086 1.32422 10.4414 1.30859C10.0742 1.29297 9.73438 1.28516 9.42188 1.28516H8.74219V14.5859C8.74219 14.8438 8.79688 15.082 8.90625 15.3008C9.01562 15.5117 9.20703 15.6758 9.48047 15.793C9.62109 15.8477 9.91406 15.9219 10.3594 16.0156C10.8125 16.1094 11.1641 16.1602 11.4141 16.168V17H3.5625V16.168C3.78125 16.1523 4.11328 16.1211 4.55859 16.0742C5.01172 16.0273 5.32422 15.9688 5.49609 15.8984C5.75391 15.7969 5.94141 15.6523 6.05859 15.4648C6.18359 15.2695 6.24609 15.0117 6.24609 14.6914V1.28516H5.56641C5.32422 1.28516 5.02344 1.29297 4.66406 1.30859C4.30469 1.31641 3.96094 1.33984 3.63281 1.37891C3.375 1.41016 3.10547 1.56641 2.82422 1.84766C2.55078 2.12109 2.29688 2.43359 2.0625 2.78516C1.82812 3.13672 1.62109 3.51172 1.44141 3.91016C1.26172 4.30078 1.13672 4.625 1.06641 4.88281H0.246094V0.371094H14.7305V4.88281Z'
							fill='white'/>
					</svg>
				</div>
			</OnHoverTooltip>
			<ShapeSelector
				opened={() => this.setState({showValidation: false})}
				added={(type) => this.props.added(type)}
				//changed={(type) => this.props.changed(type)}
				shapeType={this.props.shapeType}
				shapeStyle={this.props.shapeStyle}
			/>
			{(this.validationRef && this.state.showValidation) && <ValidationTooltip
				className={this.isSafari ? 'toolbar-menu-validation-tooltip safari' : 'toolbar-menu-validation-tooltip'}
				notHide={true} element={this.validationRef} placement={'bottom'}>
				Please add text, an image, or shape.
			</ValidationTooltip>}
		</div>;
	}
}

export namespace Events {
	export function Added(args: ShapeType): ShapeType {
		return args;
	}
}

