import {SubjectTab} from '@esgi/main/libs/store';
import {OptionItem} from '@esgi/main/features/teacher/home';
import {IconComponent, GridSix, GridNine} from '@esgi/ui';
import {StudentWithUnit} from '@esgi/main/kits/activities';
import {BingoPlace, BingoCardSize, Difficulty, TestModel, QuestionModel} from './types';

export const minQuestionsCountForMediumCardSize = 25;

export interface BingoToggleOption<T extends string | number> extends OptionItem<T> {
	content?: string;
	icon?: IconComponent;
	disabled?: boolean;
}

export interface BingoStudent {
	id: number;
	name: string;
}

export interface BingoTestSettings {
	difficulty: Difficulty;
	cardSize: BingoCardSize;
	place: BingoPlace;
	cardsCount: string;
	sheetsCount: string;
	peopleAtHomeCount: string;
}

export interface BingoGenerateParams {
	subject: SubjectTab;
	tests: TestModel[];
	students: StudentWithUnit[];
	settings: BingoTestSettings;
	questions: QuestionModel[];
}

export const initialTestSettingsValue = {
	difficulty: Difficulty.Easy,
	cardSize: BingoCardSize.Small,
	place: BingoPlace.Classroom,
	cardsCount: '1',
	sheetsCount: '1',
	peopleAtHomeCount: '1',
};

export const difficultyItemList: BingoToggleOption<Difficulty>[] = [
	{
		value: Difficulty.Easy,
		label: 'Easy',
		content: '😏',
	},
	{
		value: Difficulty.Medium,
		label: 'Medium',
		content: '😔',
	},
	{
		value: Difficulty.Hard,
		label: 'Hard',
		content: '😖',
	},
];

export const testLocationItemList: BingoToggleOption<BingoPlace>[] = [
	{
		value: BingoPlace.Classroom,
		label: 'Classroom',
		content: '👩🏼‍🏫',
	},
	{
		value: BingoPlace.Home,
		label: 'Home',
		content: '🏠',
	},
];

export const getCardSizeItemList: (
	isMediumCardSizeAvailable?: boolean
) => BingoToggleOption<BingoCardSize>[] = (
	isMediumCardSizeAvailable = true
) => ([
	{
		value: BingoCardSize.Small,
		label: '3',
		icon: GridSix,
		disabled: false,
	},
	{
		value: BingoCardSize.Medium,
		label: '5',
		icon: GridNine,
		disabled: !isMediumCardSizeAvailable,
	},
]);