import React, {useEffect, useState} from 'react';
import {
	StudentsReportValidator,
	StudentsReportValidatorRequest,
} from '../../../../shared/modules/reports/students-report-validator/students-report-validator';
import {userStorage} from '@esgi/core/authentication';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {LauncherOptions} from '../models/models';


export function useReportValidator(options: LauncherOptions, hierarchy: HierarchySnapshot, onClose: () => void) {
	const [isValid, setIsValid] = useState(false);

	function load() {
		const request = new StudentsReportValidatorRequest();
		request.byValidate = ['HasTrack'];
		request.subjectId = options.subjectId;
		request.subjectType = options.subjectType;
		request.globalSchoolYearId = userStorage.get().globalSchoolYearID;
		request.userId = options.userId;
		request.schoolId = hierarchy.classic.schoolID;
		request.districtId = hierarchy.classic.districtID;
		return new StudentsReportValidator().run(request);
	}

	useEffect(() => {
		load().then(() => setIsValid(true)).catch(() => {
			setIsValid(false);
			onClose();
		});
	});

	return isValid;
}
