import {ReportFiltersBody, ReportFiltersContainer, ReportFiltersRow} from '@esgi/deprecated/ui-kit/report/filters';
import {ReportDropdown} from '../../../report-dropdown';
import {StudentLevelReportService} from '../../../../services/student-level-report-service';
import {ShowFilter} from '../show-filter';
import {ShowResultsFilter} from '../../../show-results-filter';
import {MarkingPeriodFilter} from '../../../marking-period-filter';
import styles from './styles.module.less';
import {useStream} from '@esgillc/ui-kit/utils';

type HeaderProps = {
	service: StudentLevelReportService;
};

export function Header({service}: HeaderProps) {
	const students = useStream(service.students$);
	const selectedStudent = useStream(service.selectedStudent$);
	const filter = useStream(service.filter$);

	return (
		<ReportFiltersBody>
			<ReportFiltersRow className={styles.reportHeaderRow}>
				<ReportFiltersContainer>
					{selectedStudent && (
						<ReportDropdown
							title='Student'
							onSelected={({id}) => service.selectStudent(id)}
							entities={students}
							selectedValue={selectedStudent}
							data-cy='student-level-report-header-'
						/>
					)}
				</ReportFiltersContainer>
				<ShowFilter
					showNotes={filter?.showNotes ?? false}
					showSummary={filter?.showSummary ?? false}
					showInColor={filter?.showInColor ?? false}
					onShowInColorChange={service.setShowInColorFilter.bind(service)}
					onShowNotesChange={service.setShowNotesFilter.bind(service)}
					onShowSummaryChange={service.setShowSummaryFilter.bind(service)}
				/>
				<ShowResultsFilter
					onCarryForwardClicked={service.setCarryForwardFilter.bind(service)}
					onShowBaseLineClicked={service.setShowBaseLineFilter.bind(service)}
					carryScoresForward={filter?.carryScoresForward ?? false}
					showBaseline={filter?.showBaseline ?? false}
				/>
				<MarkingPeriodFilter
					onChanged={service.setMarkingPeriodFilter.bind(service)}
					showCurrentMarkingPeriod={filter?.showCurrentMarkingPeriod ?? false}
				/>
			</ReportFiltersRow>
		</ReportFiltersBody>
	);
}
