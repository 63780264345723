import React from 'react';
import './card.less';

export class Props {
	title: string;
	imagePath: string;
	color: string;
	className: string;
	descriptions: string[];
	onClicked: () => void;
}

export default class Card extends React.PureComponent<Props> {
	render(): JSX.Element | false | null {
		return <div className={`admin-card-link ${this.props.className}`} onClick={() => this.props.onClicked()}>
			<div className={`admin-card`}>
				<div className={`card-header ${this.props.className}-header`}>{this.props.title}
					<span className='arrow'>
                    <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0.0216827 12.355L5.36502 7L0.0216827 1.645L1.66668 0L8.66668 7L1.66668 14L0.0216827 12.355Z' fill='white'/>
                    </svg>
                    </span>
				</div>
				<div className={`card-central ${this.props.className}-central`}>
					<img src={this.props.imagePath}/>
				</div>
				<div className={`card-bottom ${this.props.className}-bottom`}>
					<div className={`card-descriptions ${this.props.className}-desc`}>
						{this.props.descriptions.map((desc, index) => <div key={index} className='card-description-item'>
                            <span className='tick'>
                                <svg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path fillRule='evenodd' clipRule='evenodd'
									  d='M5.61991 8.95986C5.98119 9.77282 6.29263 10.6042 6.59473 11.4417C7.21138 10.6809 7.83739 9.92007 8.49142 9.18381C11.4346 5.85528 17.4859 -0.252642 22.3164 0.00811824C23.7553 0.0848124 23.7148 0.523503 22.5126 1.08797C17.3115 3.53298 12.97 10.1532 9.97078 14.8653C9.21708 16.0525 8.13949 18.1202 6.90929 18.7644C6.13068 19.1725 5.07177 19.0651 4.34299 18.5957C2.95084 17.703 1.28773 13.2762 0.319143 11.5981C-0.418978 10.1563 0.166538 8.39846 1.63032 7.66833C3.09411 6.94127 4.87868 7.51801 5.61991 8.95986Z'
									  fill='#2FB454'/>
                            </svg>
                            </span>
							{desc}
						</div>)}
					</div>
				</div>
			</div>
		</div>;
	}
}
