import {useCallback, useEffect, useRef, useState} from 'react';
import {StudentInstructionsAlertState, StudentModel} from '../types';
import {Subscription} from 'rxjs';
import {useStudents, useGroups, useClasses} from '@esgi/main/libs/store';
import {useService} from '@esgi/core/service';
import {AssignmentService} from '../services/assignment-service';
import {useBehaviorSubject, useStreamEffect} from '@esgi/ui';
import {LocalStorageService} from '../services/local-storage-service';

export function useAssignmentState({selectedAssignmentId}: {selectedAssignmentId: number | null}) {
	const [isAssignmentDataLoaded, setIsAssignmentDataLoaded] = useState(false);

	const [isOpenExportStudentsCredentialsAlert, setIsOpenExportStudentsCredentialsAlert] = useState(false);
	const [studentInstructionsAlertState, setStudentInstructionsAlertState] =
		useState<StudentInstructionsAlertState | null>(null);

	const [studentsInAssignment, setStudentsInAssignment] = useState<StudentModel[]>([]);
	const [studentsWithoutAccess, setStudentsWithoutAccess] = useState<StudentModel[]>([]);

	const initRequestSubscription = useRef<Subscription | null>(null);

	const [studentsIdsForCredentialsAlert, setStudentsIdsForCredentialsAlert] = useState<
		StudentModel['studentID'][] | null
	>(null);

	const [{loaded: isStudentListLoaded}] = useStudents();
	const [{loaded: isGroupListLoaded}] = useGroups();
	const [{loaded: isClassListLoaded}] = useClasses();

	const assignmentService = useService(AssignmentService);
	const localStorageService = useService(LocalStorageService);

	const assignment = useBehaviorSubject(assignmentService.assignment$);

	const disabledOpenExportStudentsCredentials = !assignment?.students.length;

	useStreamEffect(assignmentService.studentsInAssignment$, (students) => {
		setStudentsInAssignment(students);
		setStudentsWithoutAccess(students.filter(({hasCredentials}) => !hasCredentials));
	});

	useEffect(() => {
		setIsAssignmentDataLoaded(false);

		if (selectedAssignmentId && isStudentListLoaded && isClassListLoaded && isGroupListLoaded) {
			initRequestSubscription.current?.unsubscribe();

			initRequestSubscription.current = assignmentService.init({assignmentId: selectedAssignmentId}).subscribe(() => {
				setIsAssignmentDataLoaded(true);
			});
		}

		return () => {
			initRequestSubscription.current?.unsubscribe();
		};
	}, [selectedAssignmentId, isStudentListLoaded, isGroupListLoaded, isClassListLoaded]);

	const onCloseStudentCredentialsAlert = useCallback(() => {
		setStudentsIdsForCredentialsAlert(null);
	}, []);

	const onOpenStudentCredentials = useCallback((studentId: StudentModel['studentID']) => {
		setStudentsIdsForCredentialsAlert([studentId]);
	}, []);

	const onOpenCredentialsForStudentsInAssignment = useCallback(() => {
		const studentsIds = studentsInAssignment.map(({studentID}) => studentID);

		setStudentsIdsForCredentialsAlert(studentsIds);
	}, [studentsInAssignment]);

	const onOpenExportStudentsCredentialsAlert = useCallback(() => {
		if (!disabledOpenExportStudentsCredentials) {
			setIsOpenExportStudentsCredentialsAlert(true);
		}
	}, [disabledOpenExportStudentsCredentials]);

	const closeExportStudentsCredentialsAlert = useCallback(() => {
		setIsOpenExportStudentsCredentialsAlert(false);
	}, []);

	const onCloseStudentInstructionsAlertOpened = useCallback(() => {
		setStudentInstructionsAlertState(null);
	}, []);

	return {
		assignmentService,
		studentsIdsForCredentialsAlert,
		isAssignmentDataLoaded,
		assignment,
		onOpenStudentCredentials,
		onOpenCredentialsForStudentsInAssignment,
		studentsInAssignment,
		onCloseStudentCredentialsAlert,
		disabledOpenExportStudentsCredentials,
		isOpenExportStudentsCredentialsAlert,
		onOpenExportStudentsCredentialsAlert,
		closeExportStudentsCredentialsAlert,
		studentInstructionsAlertState,
		setStudentInstructionsAlertState,
		onCloseStudentInstructionsAlertOpened,
		localStorageService,
		studentsWithoutAccess,
	};
}
