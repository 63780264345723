import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const SidebarHeader = styled(FlexBox, {
	alignItems: 'center',
	paddingBottom: '24px',
	justifyContent: 'space-between',

	'& > svg': {
		marginRight: '2px',
		fill: '$neutral56',
		width: '24px',
		height: '24px',

		'& > path': {
			fill: '$neutral56',
		},
	},
});

export const PanelContent = styled(FlexBox, {
	padding: '40px 20px',
	flexDirection: 'column',
	alignItems: 'center',
});

export const ContentWrapper = styled(GridBox, {
	gridTemplateRows: 'repeat(4,auto) 1fr',
	gap: '20px',
	width: '560px',
	padding: '0 20px',

	'& > div': {
		paddingBottom: '20px',
	},
});

export const StyledButton = styled(Button, {
	padding: '3px 11px',
	alignItems: 'center',

	'& > svg': {
		width: '32px',
		height: '32px',
	},
});
