import {getUser, UserType} from '@esgi/core/authentication';
import {LocationFormProps} from '../location/types';
import {TeacherLocation} from './variants/teacher';
import {DistrictAdminLocation} from './variants/district-admin';
import {SchoolAdminLocation} from './variants/school-admin';
import {DistrictLevelLocation} from './variants/district-specialist';
import {
	SchoolSpecialistLocation,
} from 'modules/forms/students-form/components/profile-modal/components/tabs/location/variants/school-specialist';

export function Location(props: LocationFormProps) {
	const {userType} = getUser();
	const unitPrefix = userType === UserType.PA ? 'Pre-Assess' : 'Specialist';

	switch (userType) {
		case UserType.T:
			return <TeacherLocation {...props}/>;

		case UserType.C:
			return <SchoolAdminLocation {...props}/>;

		case UserType.D:
			return <DistrictAdminLocation {...props}/>;

		case UserType.ISS:
			return <SchoolSpecialistLocation {...props}/>;

		case UserType.ISD:
			return <DistrictLevelLocation {...props} unitPrefix={unitPrefix}/>;
		case UserType.PA:
			return <DistrictLevelLocation {...props} unitPrefix={unitPrefix}/>;
	}
}

