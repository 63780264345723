import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {EmptyContent as EmptyContentBox} from '@esgi/main/kits/assignments';

export const EmptyDataContainer = styled(Box, {
	display: 'inline-grid',
	alignItems: 'center',
	justifyContent: 'center',

	variants: {
		withoutDefaultHeight: {
			false: {
				height: 100,
			},
		},
	},
});

export const EmptyContent = styled(EmptyContentBox, {
	width: 'unset',
	height: 'unset',
});
