import {TrackType} from './types';

export const trackTypeTranscript: Record<TrackType, string> = {
	[TrackType.Semester]: 'Semester (2 periods)',
	[TrackType.Trimester]: 'Trimester (3 periods)',
	[TrackType.Quarter]: 'Quarter',
	[TrackType.Periods5]: '(5) Periods',
	[TrackType.Periods6]: '(6) Periods',
	[TrackType.Periods7]: '(7) Periods',
	[TrackType.Periods8]: '(8) Periods',
	[TrackType.Periods9]: '(9) Periods',
};
