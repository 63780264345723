import {omit, values} from 'underscore';
import {CSS} from '@stitches/react';

const CellsSize = {
	name: '280px',
	gradeLevel: '64px',
	primaryTeacher: '280px',
	classes: '280px',
	groups: '280px',
	specialist: '280px',
	createDate: '120px',
	idn: '280px',
};

function getTemplateColumns(agreementLevel: string) {
	if(agreementLevel === 'T') {
		const cellsSize = omit(CellsSize, 'primaryTeacher');
		return values(cellsSize).join(' ');
	}
	return values(CellsSize).join(' ');
}

export function useTableRowStyles(agreementLevel: string): CSS {
	return {
		gridTemplateColumns: getTemplateColumns(agreementLevel),
	};
}