import {ComponentPropsWithoutRef, forwardRef, useId, useMemo} from 'react';
import {keyframes, styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {useProgressBarContext} from '../../context';
import {Box} from '@esgi/ui/layout';
import {animated, config, useSpring} from 'react-spring';
import {ThemeColorKeys} from '../../../types';
import {getThemeColor} from '../../../utils';
import {buildSkeletonAnimationStyles} from '@esgi/ui/skeleton';

type RingWithPointerProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: Omit<CSS, 'width' | 'height'>;

	/** Set width and height for svg. */
	size?: number;

	/** Set circle color. */
	circleColor?: ThemeColorKeys;

	/** Set progress value color. */
	progressValueColor?: ThemeColorKeys;

	/** Set width of circle. */
	circleStrokeWidth?: number;

	/** Set filled color for pointer. */
	pointerFillColor?: ThemeColorKeys;

	/** Hide pointer. */
	hidePointer?: boolean;

	skeleton?: boolean;
};

export const RingWithPointer = forwardRef<HTMLDivElement, RingWithPointerProps>(
	(
		{
			dataCy,
			color,
			size = 48,
			circleStrokeWidth = 6,
			circleColor = 'border',
			progressValueColor = 'positiveVivid',
			pointerFillColor = 'green',
			hidePointer,
			css,
			children,
			skeleton,
			...props
		},
		forwaredRef,
	) => {
		const {progress} = useProgressBarContext();

		const progressCircleColorId = useId();

		const centerOfSvg = useMemo(
			() => ({
				x: size / 2,
				y: size / 2,
			}),
			[size],
		);

		const circleValues = useMemo(() => {
			const radius = (size - circleStrokeWidth) / 2;
			const perimeter = 2 * Math.PI * radius;
			const progressValue = perimeter - perimeter * progress;

			return {
				radius,
				perimeter,
				progressValue,
			};
		}, [size, circleStrokeWidth, progress]);

		const pointerValues = useMemo(
			() => ({
				strokeWidth: 1,
				radius: circleStrokeWidth / 2,
			}),
			[circleStrokeWidth],
		);

		const pointerAnimateProps = useSpring({
			from: {
				transform: `rotate(0deg) translate(${circleStrokeWidth / 2}px)`,
			},
			to: {
				transform: `rotate(${360 * progress}deg) translate(${circleStrokeWidth / 2}px)`,
			},
			config: config.molasses,
		});

		const circleProgressAnimateProps = useSpring({
			from: {
				strokeDashoffset: circleValues.perimeter,
			},
			to: {
				strokeDashoffset: circleValues.progressValue,
			},
			config: config.molasses,
		});

		return (
			<RingBox
				data-cy={dataCy ?? 'ui-kit-progress-bar-ring-with-pointer'}
				css={{
					width: size,
					height: size,
					...css,
				}}
				ref={forwaredRef}
				{...props}
			>
				<SVG
					xmlns='http://www.w3.org/2000/svg'
					viewBox={`0 0 ${size} ${size}`}
					css={{
						width: size,
						height: size,
					}}
				>
					{Boolean(circleValues.progressValue) && (
						<MainCircle
							skeleton={skeleton}
							cx={centerOfSvg.x}
							cy={centerOfSvg.y}
							r={circleValues.radius}
							strokeWidth={circleStrokeWidth}
							strokeDasharray={circleValues.perimeter}
							fill='none'
							strokeLinecap='round'
							stroke={getThemeColor(circleColor)}
							data-progress-bar-ring-progress=''
						/>
					)}

					<animated.circle
						cx={centerOfSvg.x}
						cy={centerOfSvg.y}
						r={circleValues.radius}
						strokeWidth={circleStrokeWidth}
						strokeDasharray={circleValues.perimeter}
						fill='none'
						strokeLinecap='round'
						stroke={`url(#${progressCircleColorId})`}
						style={circleProgressAnimateProps}
					/>

					{!hidePointer && progress > 0 && (
						<animated.circle
							style={{
								transformOrigin: '50% 50%',
								...pointerAnimateProps,
							}}
							cx={2 * circleValues.radius}
							cy='50%'
							r={pointerValues.radius}
							fill={getThemeColor(pointerFillColor)}
							stroke={getThemeColor('vivid')}
							strokeWidth={pointerValues.strokeWidth}
							data-progress-bar-ring-pointer=''
						/>
					)}

					<defs>
						<linearGradient id={progressCircleColorId}>
							<stop offset='0%' stopColor={getThemeColor(progressValueColor)} stopOpacity='0.32' />
							<stop offset='100%' stopColor={getThemeColor(progressValueColor)} />
						</linearGradient>
					</defs>
				</SVG>

				<Content>{children}</Content>
			</RingBox>
		);
	},
);



const RingBox = styled(Box, {
	position: 'relative',
});

const Content = styled(Box, {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	userSelect: 'none',
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: 0,
	marginTop: 0,
	marginBottom: 0,
	marginLeft: 'auto',
	marginRight: 'auto',
});

const SVG = styled('svg', {
	transform: 'rotate(-90deg)',
	fill: 'none',
});

const MainCircle = styled(animated.circle, {
	variants: {
		skeleton: {
			true: buildSkeletonAnimationStyles('stroke'),
		},
	},
});
