import {styled} from '@esgi/ui/theme';
import {CollapseSide} from '@esgi/ui';
import {FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {TestColorIndicator} from '../../../../tests';

export const StyledSelectableListTrigger = styled(SelectableList.Trigger, {
	color: '$base',
	height: '48px',
	padding: '8px 12px',
	pointerEvents: 'unset !important'
});

export const StyledTestContainer = styled(GridBox, {
	gap: '$3',
	gridAutoFlow: 'column',
	alignItems: 'center',
	height: '100%',
});

export const StyledIconBefore = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: '16px',
	height: '16px',
	borderRadius: '4px',
	backgroundColor: '$border',

	variants: {
		checked: {
			true: {
				backgroundColor: '$primary92',
				'& > svg': {
					stroke: '$primary',
				},
			},
		},
	},
});

export const StyledTestColorIndicator = styled(TestColorIndicator, {
	padding: '6px 4px',
});

export const StyledCollapse = styled(CollapseSide, {
	transition: 'transform .2s ease-in-out',

	'& rect, & path': {
		fill: 'currentColor',
	},

	variants: {
		rotate: {
			true: {
				transform: 'rotate(90deg)',
			},
			false: {
				transform: 'rotate(-90deg)',
			},
		},
	},
});