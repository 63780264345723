import React from 'react';
import {Modal, ModalBody, ModalFooter} from '@esgi/deprecated/react';
import './styles.less';
import ResultFormHeader from 'shared/modules/reports/flash-cards/results-form/result-form-header';
import Results from 'shared/modules/reports/flash-cards/results-form/result-form';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import {ResultsModel} from './services/results-service';

class State {
	results: ResultsModel;
	fullHeight: boolean;
}

class Props {
	dataService: DataService;
	settingsClose: () => void;
	onClosed: () => void;
	onLoading: (status: boolean) => void
	onBack: () => void
}

export default class ResultsFormModal extends React.Component<Props, State> {
	constructor(props?: Props) {
		super(props);
		this.state = new State();
	}

	componentDidMount(): void {
		this.props.dataService.resultsService.results$.subscribe((results) => {
			let increasedBody = false;
			const cards = results.model.students.reduce((acc, x) => acc.concat(x.cardRows), []); // equivavalent to .flatMap(a => a.cardRows);
			if(cards.length > 1){
				increasedBody = true;
			}
			this.setState({fullHeight: increasedBody, results: results});
		});
	}

	private get modalClassName() {
		let baseClass = 'flash-cards-results-modal blue-header ';
		if (this.state.fullHeight) {
			baseClass += ' full-height';
		}
		return baseClass;
	}

	render() {
		if (!this.state.results?.model) {
			return null;
		}

		return <Modal className={this.modalClassName}
					  animate={true}
		>
			<ResultFormHeader
				allStudentsSelected={this.state.results.allStudentsSelected}
				reportGuid={this.state.results.model.reportGuid}
				onLoading={(m) => this.props.onLoading(m)}
				settingsClose={this.props.settingsClose}
				close={() => {
					this.props.onClosed();
					this.props.onBack();
				}}
			/>
			<ModalBody>
				<Results
					students={this.state.results.model.students}
					subjectName={this.state.results.model.subjectName}
					fullHeight={this.state.fullHeight}
				/>
			</ModalBody>
			<ModalFooter>
			</ModalFooter>
		</Modal>;
	}
}