import {DropdownInModel} from '@esgi/deprecated/elements';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Input, OnErrorTooltip, Option} from '@esgillc/ui-kit/form-control';
import {UserInfoProfileFormType} from '../../forms/user-info';
import {GradeLevels, Titles} from '../../constants';
import styles from './styles.module.less';
import {getUser, UserType} from '@esgi/core/authentication';

interface Props {
	states: DropdownInModel[];
	countries: DropdownInModel[];
	form: UserInfoProfileFormType;
}

export function ProfileUserInfo({countries, states, form}: Props) {
	const currentUser = getUser();

	return <div data-cy={'user-info-form'}><Form controller={form} className={styles.userInfoForm}>
		<div className={styles.firstRow}>
			<FormElement control={form.controls.title}>
				<div data-cy={'title-dropdown'}>
					<label className={styles.label}>
						Title
					</label>
					<Dropdown optionName={'value'}>
						{Titles.map((title) => <Option key={title.id} value={title}>{title.value}</Option>)}
					</Dropdown>
				</div>
			</FormElement>
			<FormElement control={form.controls.firstName}>
				<div data-cy={'firstname-input'}>
					<label className={styles.label}>
						First Name
					</label>
					<Input/>
					<OnErrorTooltip showOnError={'required'} placement={'top'}>
						Please enter your First Name.
					</OnErrorTooltip>
				</div>
			</FormElement>
			<FormElement control={form.controls.lastName}>
				<div data-cy={'lastname-input'}>
					<label className={styles.label}>
						Last Name
					</label>
					<Input/>
					<OnErrorTooltip showOnError={'required'} placement={'top'}>
						Please enter your Last Name.
					</OnErrorTooltip>
				</div>
			</FormElement>
		</div>
		<div className={styles.secondRow}>
			<FormElement control={form.controls.state}>
				<div data-cy={'state-dropdown'}>
					<label className={styles.label}>
						State
					</label>
					<Dropdown optionName={'value'}>
						{states.map((title) => <Option key={title.id} value={title}>{title.value}</Option>)}
					</Dropdown>
				</div>
			</FormElement>
			<FormElement control={form.controls.country}>
				<div data-cy={'country-dropdown'}>
					<label className={styles.label}>
						Country
					</label>
					<Dropdown optionName={'value'}>
						{countries.map((title) => <Option key={title.id} value={title}>{title.value}</Option>)}
					</Dropdown>
				</div>
			</FormElement>
		</div>
		<div className={styles.secondRow}>
			<FormElement control={form.controls.email}>
				<div data-cy={'email-input'}>
					<label className={styles.label}>
						Email
					</label>
					<Input/>
					<OnErrorTooltip showOnError={'email'} placement={'top'}>
						Email is invalid.
					</OnErrorTooltip>
				</div>
			</FormElement>
			{currentUser.userType === UserType.T ? (
				<FormElement
					control={form.controls.gradeLevels}
					className={styles.fixedWidth}
				>
					<div data-cy={'grade-levels-dropdown'}>
						<label className={styles.label}>
							Grade
						</label>
						<Dropdown optionName='value' multiselect={true}>
							{GradeLevels.map((grade) =>
								<Option key={grade.id} value={grade}>{grade.value}</Option>
							)}
						</Dropdown>
					</div>
				</FormElement>
			) : (
				<div></div>
			)}
		</div>
	</Form>
	</div>;
}
