import React from 'react';
import {IModalRef, openModal} from '@esgi/deprecated/react';
import {ContactUs} from '@esgi/features/support';

/**
 * @deprecated Don't use this component at the new components. Use support/contact-us/component instead.
 */
export class ZenboxCompact {
	promise: JQueryDeferred<{}>;
	modalRef: IModalRef;

	constructor(exceptionToken) {
		this.exceptionToken = exceptionToken;
	}

	load(): JQueryPromise<any> {
		this.promise = $.Deferred();
		this.modalRef = openModal(<ContactUs ticketSubmitted={() => this.closeModal()}
			           onClose={() => this.closeModal()}/>);
		return this.promise.promise();
	}

	private closeModal() {
		if (this.modalRef) {
			this.modalRef.close();
			this.promise.resolve();
		}
	}

	private exceptionToken: string;
}

