import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Wrapper = styled(GridBox, {
	gap: '$3',
	alignItems: 'start',
	variants: {
		isTablet: {
			true: {
				gridAutoFlow: 'row',
			},
			false: {
				gridAutoFlow: 'column',
				gridTemplateColumns: 'auto 1fr',
			},
		},
	},
});