import {CheckAllIcon, Row, SkeletonCell} from '../../styled';
import {useTableRowStyles} from '../../hooks/use-table-row-styles';
import {HeaderCell} from './header-cell';
import {FieldType, Sorting} from '../../types';

type Props = {
	sorting: Sorting,
	skeleton: boolean,
	agreementLevel: string,
	allSelected: boolean,
	onSelectAllClicked: VoidFunction,
	onCellClicked: (field: FieldType) => void,
	showSpecialistColumns: boolean,
}

export function Header(props: Props) {
	const styles = useTableRowStyles(props.agreementLevel);

	if(props.skeleton) {
		return <Row css={{...styles, marginBottom: 12, overflow: 'hidden'}}>
			<SkeletonCell resetMargin />
			<SkeletonCell resetMargin />
			{props.agreementLevel !== 'T' &&<SkeletonCell resetMargin />}
			<SkeletonCell resetMargin />
			<SkeletonCell resetMargin />
			<SkeletonCell resetMargin />
			<SkeletonCell resetMargin />
			<SkeletonCell resetMargin />
		</Row>;
	}

	return <Row
		css={{
			...styles,
			height: 24,
			marginBottom: 12,
			position: 'sticky',
			top: 0,
			zIndex: 1,
			backgroundColor: '$background',
		}}
	>
		<HeaderCell name={FieldType.Name} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.Name)}>
			<CheckAllIcon allChecked={props.allSelected} width={24} height={24} onClick={props.onSelectAllClicked}/>
		</HeaderCell>
		<HeaderCell name={FieldType.Grade} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.Grade)}/>
		{props.agreementLevel !== 'T' && <HeaderCell name={FieldType.Teacher} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.Teacher)}/>}
		<HeaderCell name={FieldType.Class} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.Class)}/>
		<HeaderCell name={FieldType.Groups} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.Groups)}/>
		{props.showSpecialistColumns && <HeaderCell name={FieldType.Specialist} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.Specialist)}/>}
		<HeaderCell name={FieldType.Created} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.Created)}/>
		<HeaderCell name={FieldType.ID} sorting={props.sorting} onClick={() => props.onCellClicked(FieldType.ID)}/>
	</Row>;
}
