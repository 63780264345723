import {useUser} from '@esgi/core/authentication';
import {Buttons} from '@esgillc/ui-kit/button';
import {Checkbox} from '@esgillc/ui-kit/control';
import React, {Suspense, useState} from 'react';
import {RowModel} from '../model';
import {lazyComponent} from '@esgi/core/react';
import {ViewMode} from 'shared/modules/test-details/models';
import styles from '../styles.module.less';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));

interface Props {
	onSelected: (checked: boolean) => void;
	selected: boolean;
	row: RowModel;
}

export function Row(props: Props) {
	const user = useUser();
	const [testDetailsOpened, setTestDetailsOpened] = useState(false);

	return <tr data-name={props.row.name} className={styles.row}>
		<td>
			<label>
				<Checkbox
					className={styles.testItem}
					key={props.row.id}
					checked={props.selected}
					onChange={(e) => props.onSelected(e.target.checked)}/>
			</label>
		</td>
		<td className={styles.name}>
			<Buttons.Link onClick={() => setTestDetailsOpened(true)} className={styles.nameButton}>
				{props.row.name}
			</Buttons.Link>
			{testDetailsOpened &&
				<Suspense fallback={<React.Fragment/>}>
					<TestDetails
						testID={props.row.id}
						firstName={user.firstName}
						lastName={user.lastName}
						userID={user.userID}
						close={() => setTestDetailsOpened(false)}
						disableCopyTest={true}
						viewMode={ViewMode.SelfAssess}
					/>
				</Suspense>
			}
		</td>
		<td className={styles.author}>{props.row.author}</td>
		<td className={styles.createDate}>{props.row.date}</td>
		<td className={styles.questions}>{props.row.questions}</td>
	</tr>;
}

