import {Panel, Button, ButtonIcon} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Skeletonable} from '@esgi/ui';
import {Preview} from '@esgi/ui/icons';
import {Title} from './title';

interface Props extends Skeletonable {
	reportTitle?: string;
	disabled?: boolean;
	onPreview?: VoidFunction;
	onReport: VoidFunction;
}

export function ReportPanel(props: Props) {
	const {
		skeleton,
		reportTitle = 'Run Report',
		disabled = false,
		onPreview,
		onReport,
	} = props;

	return (
		<Panel dataCy='panel'>
			<Title/>
			{onPreview &&
				<ButtonIcon
					disabled={disabled}
					onClick={onPreview}
				>
					<Preview/>
				</ButtonIcon>
			}
			<Button
				skeleton={skeleton}
				color='primary'
				disabled={disabled}
				onClick={onReport}
			>
				<Text data-cy='run-report-button' size='large' bold>{reportTitle}</Text>
			</Button>
		</Panel>
	);
}
