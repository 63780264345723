type SeriesData = {name: string, y: number}
export function getChartOptions(answers: SeriesData[], max: number) {
	max = max === 0 ? 1 : max;
	answers.forEach(element => {
		if (element.y === 0) {
			element.y = 0.03;
		}
	});
	return {
		chart: {
			type: 'column',
		},
		title: {
			text: '',
		},
		accessibility: {
			announceNewData: {
				enabled: true,
			},
		},
		xAxis: {
			categories: answers.map(({name}) => name),
		},
		yAxis: {
			max: max,
			allowDecimals: false,
			title: {
				text: '',
			},
		},
		legend: {
			enabled: false,
		},
		plotOptions: {
			column: {
				stacking: 'normal',
			},
			series: {
				borderWidth: 0,
				color: '#51C3F2',
				dataLabels: {
					enabled: false,
				},
			},
		},
		series: [
			{
				name: 'Answer',
				colorByPoint: false,
				data: answers,
			},
		],
		credits: {
			enabled: false,
		},
		tooltip: {
			formatter: function () {
				return Math.floor(this.y);
			},
		},
	};
}
