import {Buttons} from '@esgillc/ui-kit/button';
import {Alert} from '@esgillc/ui-kit/modal';
import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

interface Props {
	onClose: () => void,
	onSave: () => void,
	onCancel: () => void
}

export function CloseActionConfirmModal({onClose, onSave, onCancel}: Props){
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	return <Alert data-cy='close-action-confirm-modal' modalManagerRef={modalRef} className={styles.confirmSaveModal}>
		<Alert.Header className={styles.header}>
			<Title className={styles.title}>Save My Changes?</Title>
			<p className={styles.text}>You have made edits to this profile. Do you wish to save your changes?</p>
		</Alert.Header>
		<Alert.Footer className={styles.footer}>
			<div data-cy='footer-container'>
			<Buttons.Text onClick={() => {
				onCancel();
				handleClose();
			}
			}>Cancel</Buttons.Text>
			<Buttons.Contained onClick={() => {
				onSave();
				handleClose();
			}}>SAVE</Buttons.Contained>
			</div>
		</Alert.Footer>
	</Alert>;
}
