import {useStudentDetailService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {DetailReportSettingsGradeScale} from './components/grade-scale';
import {DetailReportSettingsShowBlock} from './components/show-block';
import {DetailReportSettingsResultsBlock} from './components/results-block';
import {DetailReportSettingsDisplayZero} from './components/display-zero';
import {DetailReportSettingsMarkingPeriods} from './components/marking-periods';
import styles from './styles.module.less';

/**
 * This is a temporary solution, so no styled components are used.
 * Just the old version with minimal adaptation.
 */
export function Settings() {
	const service = useStudentDetailService();
	const report = useBehaviorSubject(service.report$);
	const reportSettings = useBehaviorSubject(service.reportSettings$);
	const grades = useBehaviorSubject(service.grades$);

	return report?.students.length !== 0 && (
		<div className={styles.reportSettings}>
			<DetailReportSettingsGradeScale grades={grades}/>
			<DetailReportSettingsShowBlock/>
			<DetailReportSettingsResultsBlock/>
			<DetailReportSettingsDisplayZero
				value={reportSettings.displayZeroIfNotTested}
				onChange={(value) => service.reportSettingValueChange({key: 'displayZeroIfNotTested', newValue: value})}
			/>
			<DetailReportSettingsMarkingPeriods
				value={reportSettings.markingPeriodAll}
				onChange={(value) => service.reportSettingValueChange({key: 'markingPeriodAll', newValue: value})}
			/>
		</div>
	);
}
