import {Test, TestType, MarkingPeriodItem, Report, Score} from '../../types';
import {AxisLabelsFormatterContextObject, TooltipFormatterContextObject} from './types';
import moment from 'moment';
import {Highcharts} from '@esgi/deprecated/highcharts';

export function convertDate(value: string) {
	return moment.utc(value).toDate().getTime();
}

export function getDateOrDefault(value: string, minValue: number) {
	const numValue = convertDate(value);
	if (numValue < minValue) {
		return minValue - 2629746000;
	}
	return numValue;
}

export function positions(maxValue: number, dep = 1) {
	if (maxValue <= 10 && dep === 1) {
		return [...Array(maxValue + 1).keys()];
	}

	const midValue = Math.floor(maxValue / 2);

	let divider = 1;
	for (let i = midValue; i > 1; i--) {
		if (maxValue % i === 0) {
			divider = i;
			break;
		}
	}

	let array = [0];
	if (divider !== 1) {
		for (let i = divider; i <= maxValue; i += divider) {
			array.push(i);
		}
	} else {
		array = positions(Math.floor((maxValue * 2) / 3), dep + 1);
		array.push(maxValue);
	}

	return array;
}

export function xPositions(test: Test, report: Report) {
	const dates = report.trackDates;
	const array: number[] = [];

	const startMp = convertDate(dates[0].from);

	if (test.scores != null && test.scores.length !== 0) {
		const firstTs = convertDate(test.scores[0].testDate);
		if (firstTs < startMp) {
			array.push(startMp - 2629746000); // -1 month;
		}
	}

	for (let i = 0; i < dates.length; i++) {
		array.push(convertDate(dates[i].from));

		if (i === dates.length - 1) {
			array.push(convertDate(dates[i].to));
		}
	}

	return array;
}

export function formate(
	markingPeriod: MarkingPeriodItem,
	test: Test,
	rowType: keyof Score,
) {
	const {index} = markingPeriod;
	if (test.scores.length <= index) {
		return 'NT';
	}

	const score = test.scores[index];
	if (!score.testDate) {
		return 'NT';
	}

	return score[rowType];
}

export function styleCell(
	markingPeriod: MarkingPeriodItem,
	test: Test,
	gradeScaleAllow: boolean,
	includeGradeScore: boolean,
	printInColor: boolean,
) {
	if (!gradeScaleAllow || !includeGradeScore || !printInColor) {
		return 'white';
	}

	const {index} = markingPeriod;
	if (test.scores.length <= index) {
		return '#E9EAEA';
	}

	const score = test.scores[index];
	if (!score.testDate) {
		return 'white';
	}

	return score.color;
}

export function groupTestByTwo(tests: Test[]) {
	const res = [];
	let i;
	for (i = 0; i < tests.length; i++) {
		if (i % 2 !== 0) {
			res.push([tests[i - 1], tests[i]]);
		}
	}
	i--;
	if (i % 2 === 0) {
		res.push([tests[i]]);
	}
	return res;
}

export function getAnswersWithNote({answers}: Test) {
	return answers.filter(({note}) => !!note);
}

export function getTestType(type: TestType | keyof typeof TestType) {
	if (typeof type === 'string') {
		return TestType[type];
	}
	return type;
}

export function historyOptions(sModel: Test, showBaseline: boolean, report: Report, printInColor: boolean, teacher: Report['teacher']) {
	const startMp = convertDate(report.trackDates[0].from);

	const model = {...sModel};
	if (!showBaseline) {
		model.scores = model.scores.filter(x => convertDate(x.testDate) > startMp);
		model.testSessions = model.testSessions.filter(x => convertDate(x.date) > startMp);
	}

	return {
		title: {text: ''},
		plotOptions: {
			series: {
				marker: {
					enabledThreshold: 0,
					enabled: true,
				},
			},
		},
		chart: {backgroundColor: null},
		xAxis: {
			title: {text: 'Test Session Date'},
			tickmarkPlacement: 'on',
			type: 'datetime',
			softMin: startMp,
			min: convertDate(report.trackDates[0].from),
			softMax: convertDate(report.trackDates[report.trackDates.length - 1].to),
			max: convertDate(report.trackDates[report.trackDates.length - 1].to),
			startOnTick: true,
			endOnTick: true,
			minPadding: 10,
			maxPadding: 10,
			showFirstLabel: true,
			showLastLabel: true,
			gridLineWidth: 1,
			tickPositions: xPositions(model, report),
			labels: {
				formatter: function (this: AxisLabelsFormatterContextObject) {
					if (startMp > Number(this.value)) {
						return 'B';
					}
					let str = Highcharts.dateFormat('%m/%e', this.value);
					if (str[0] === '0') {
						str = str.substr(1);
					}
					return str;
				},
			},
		},
		yAxis: {
			title: {text: 'Score'},
			softMin: 0,
			minTickInterval: 1,
			max: model.questionCount,
			softMax: model.questionCount,
			showLastLabel: true,
			endOnTick: true,
			startOnTick: true,
			showFirstLabel: true,
			maxPadding: 0,
			minPadding: 0,
			tickmarkPlacement: 'on',
			tickInterval: 1,
			tickPositions: positions(model.questionCount),
			ordinal: false,
		},
		tooltip: {
			enabled: true,
			formatter: function (this: TooltipFormatterContextObject) {
				if (getTestType(model.type) === TestType.YN) {
					return '<span style="font-size: 10px">' + Highcharts.dateFormat('%m-%d-%Y', this.x) + '</span><br/>' + teacher.testResultsCorrectVerbiage + ': <b>' + this.y + '</b><br/>';
				}
				return '<span style="font-size: 10px">' + Highcharts.dateFormat('%m-%d-%Y', this.x) + '</span><br/><b>' + this.y + ' / ' + model.questionCount + '</b><br/>';
			},
		},
		legend: {enabled: false},
		credits: {enabled: false},
		series: [
			{
				stickyTracking: false,
				name: model.testID,
				color: (printInColor) ? '#A3A3A3' : '#7B7B7B',
				data: model.testSessions.map((value, index) => ({
					x: getDateOrDefault(value.date, startMp),
					y: value.correctAnswers,
					marker: {
						symbol: index === 0 ? 'diamond' : 'circle',
						radius: index === 0 ? 6 : 4,
					},
					rawDate: convertDate(value.date),
				})),
			},
		],
	};
}

export function pieChartOptions(
	test: Test,
	printInColor: boolean,
	teacher: Report['teacher'],
) {
	const {questionCount, testSessions, type} = test;
	const correctScore = testSessions[testSessions.length - 1].correctAnswers;
	const correctPercent = Math.round(100 * correctScore / (questionCount));

	const defOptions = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			backgroundColor: null,
			type: 'pie',
			width: 200,
			height: 150,
		},
		title: {text: ''},
		legend: {enabled: false},
		credits: {enabled: false},
		tooltip: {
			enabled: true,
			headerFormat: '',
			pointFormat: getTestType(type) === TestType.YN ? '<span><span style="font-weight:' +
				' bold">{point.y}%</span> {point.name}</span>' : '<span style="font-weight: bold">{point.y}%</span>',
		},
		plotOptions: {
			pie: {
				borderColor: 'grey',
				size: 90,
				colors: (printInColor) ? ['#00BF96', '#FFFFFF'] : ['#CCCCCC', '#7B7B7B'],
				allowPointSelect: false,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					color: '#000000',
					format: '{point.y}%',
					connectorWidth: 0,
					connectorPadding: 0,
					distance: 7,
					x: 0,
					y: 0,
					style: {
						fontSize: '12px',
						textOutline: false,
					},
				},
			},
			series: {animation: false},
		},
		series: [{
			name: 'Student Name',
			colorByPoint: true,
			borderColor: '#999999',
			data: [{
				name: teacher?.testResultsCorrectVerbiage,
				y: correctPercent,
				selected: true,
				color: (printInColor) ? '#00BF96' : '',
			}, {
				name: teacher?.testResultsIncorrectVerbiage,
				y: 100 - correctPercent,
				color: '#FFF',
			}],
			states: {
				hover: {enabled: false},
				inactive: {opacity: 1},
			},
		}],
	};

	let x = 0;
	let y = 0;

	if (correctPercent === 0) {
		defOptions.series[0].data.splice(0, 1);
		defOptions.plotOptions.pie.dataLabels.format = '0%';
		defOptions.tooltip.pointFormat = '<span><span style="font-weight: bold">0%</span> ' + teacher?.testResultsCorrectVerbiage + '</span>';

		x = -14;
	}

	if (correctPercent > 0 && correctPercent < 7) {
		y = -4;
		x = -10;
	}

	if (correctPercent >= 7 && correctPercent < 12) {
		y = -6;
		x = -10;
	}

	if (correctPercent >= 12 && correctPercent < 16) {
		y = -6;
		x = -8;
	}

	if (correctPercent >= 16 && correctPercent < 20) {
		y = -6;
		x = -6;
	}

	if (correctPercent >= 20 && correctPercent < 26) {
		y = -6;
		x = -4;
	}

	if (correctPercent >= 26 && correctPercent < 35) {
		y = -6;
		x = -2;
	}

	if (correctPercent === 35) {
		y = -6;
		x = -1;
	}

	if (correctPercent >= 36 && correctPercent < 40) {
		y = -6;
		x = -2;
	}

	if (correctPercent >= 40 && correctPercent < 61) {
		y = -6;
		x = 3;
	}

	if (correctPercent >= 61 && correctPercent < 78) {
		y = -6;
		x = 2;
	}

	if (correctPercent >= 78 && correctPercent < 82) {
		y = -4;
		x = 2;
	}

	if (correctPercent >= 82 && correctPercent < 87) {
		y = -6;
		x = 4;
	}

	if (correctPercent >= 87 && correctPercent < 90) {
		y = -6;
		x = 6;
	}

	if (correctPercent >= 90 && correctPercent < 92) {
		y = -6;
		x = 8;
	}

	if (correctPercent >= 92 && correctPercent < 97) {
		y = -6;
		x = 12;
	}

	if (correctPercent >= 97 && correctPercent < 100) {
		y = -5;
		x = 12;
	}

	if (correctPercent === 100) {
		defOptions.series[0].data.splice(1, 1);
		defOptions.plotOptions.pie.dataLabels.format = '100%';
		x = -23;
	}

	defOptions.plotOptions.pie.dataLabels.y = y;
	defOptions.plotOptions.pie.dataLabels.x = x;

	return defOptions;
}
