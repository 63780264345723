import React from 'react';
import './component.less';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import {DefaultGradeRangeEditorService} from 'shared/modules/grade-scale/grade-range/forms/default/service';
import {FormState} from 'shared/modules/grade-scale/models';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import GradeRangeEditor from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/component';
import {Subscription} from 'rxjs';

export class Props {
	gradeScaleID: number;
	subjectID?: number;
	testID?: number;
	sourceStep: WizardStep;
	service: DefaultGradeRangeEditorService;
	onClose: () => void;
	onCancel: () => void;
	onToCustomRangeClicked: (subjectID?: number, testID?: number) => void;
	onSaved: () => void;
}

export class State {
	formChanged: boolean;
	gradeScaleName: string;
}

export default class DefaultGradeRange extends React.PureComponent<Props, State> {
	private supportPageUri: string = 'https://support.esgisoftware.com/hc/en-us/articles/115005886826-Class-Grade-Report-Grading-Scales';
	subscribers: Subscription[] = [];
	
	constructor(props) {
		super(props);
		this.state = new State();
	}
	
	componentDidMount() {
		this.props.service.init(this.props.gradeScaleID, this.props.testID);

		this.subscribers.push(this.props.service.formState$.subscribe((fs) => {
			if (fs === FormState.Saved) {
				this.props.onSaved();
			}

			if (fs === FormState.Closed) {
				this.props.onClose();
			}
		}));

		this.subscribers.push(this.props.service.gradeRangeEditorService.changedGradeRange$.subscribe((grf) => {
			this.setState({formChanged: true});
		}));

		this.subscribers.push(this.props.service.gradeScale$.subscribe((gradeScale) => {
			if (gradeScale) {
				this.setState({gradeScaleName: gradeScale.name});	
			}
		}));
		
		this.setState({
			formChanged: !!this.props.testID && this.props.sourceStep === WizardStep.CustomGradeRange,
		});
	}

	componentWillUnmount() {
		this.subscribers.map(s => s.unsubscribe());
	}
	
	renderSaveButton(){
		const saveAction = this.state.formChanged ? () => this.props.service.save() : () => this.props.service.setFormState(FormState.Saved);
		
		return <Primary
			onClick={() => saveAction()}
			title={this.state.formChanged ? 'SAVE AND CONTINUE' : 'OK'}
		/>;
	}
	
	render() {
		return <>
			<ModalHeader
				title='Grade Range'
				close={{click: () => this.props.onClose()}}
			/>
			<ModalBody>
				<div className='grade-range-form-component default-grade-range-content'>
					<div className='grade-scale-header default'>
						Default Percentage
						<a target='_blank' href={this.supportPageUri} rel='noreferrer'>
							<i className='glyphicon glyphicon-question-sign tip'></i>
						</a>
					</div>
					<div className='red-header'>
						The default percentage values will apply to all subject tabs using the [{this.state.gradeScaleName}] grade scale.
						<a target='_blank' href={this.supportPageUri} rel='noreferrer'>
							<span>Learn more</span>
						</a>
					</div>
					<div className='grade-range-editor-header'>
						Range as Percentage %
					</div>
					<GradeRangeEditor service={this.props.service.gradeRangeEditorService}/>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='left-footer-container'>
					<Button
						onClick={() => this.props.onCancel()}
						title='Cancel'
						className='cancel-button'
					/>
				</div>
				<div className='right-footer-container'>
					<Transparent
						className='set-custom-range-button'
						onClick={() => this.props.onToCustomRangeClicked(this.props.subjectID, this.props.testID)}
						title='SET CUSTOM RANGE'
					/>
					{this.renderSaveButton()}
				</div>
			</ModalFooter>
		</>
	}
}