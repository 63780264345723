import {PropsWithChildren, useCallback, useEffect, useMemo} from 'react';
import {useService} from '@esgi/core/service';
import {SubjectTab} from '@esgi/main/libs/store';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {SubjectRootContext} from './context';
import {SubjectRootService} from './service';

type Props = PropsWithChildren<{
	initialSelectedSubjectID: number | null;
	onSubjectChange: (subject: SubjectTab) => void;
}>

export function SubjectRoot({initialSelectedSubjectID, onSubjectChange, children}: Props) {
	const service = useService(SubjectRootService);

	const isLoaded = useBehaviorSubject(service.loaded$);
	const subjects = useBehaviorSubject(service.subjects$);
	const selectedSubject = useBehaviorSubject(service.selectedSubject$);

	const selectedSubjectID = useMemo(() => {
		if (!selectedSubject) {
			return undefined;
		}

		return String(selectedSubject.id);
	}, [selectedSubject]);

	const setSelectedSubject = useCallback((subjectId: string) => {
		service.subjectSelect(Number(subjectId));
	}, [service]);

	const contextValue = useMemo(() => ({
		isLoaded,
		subjects,
		selectedSubjectID,
		setSelectedSubject,
	}), [isLoaded, subjects, selectedSubjectID, setSelectedSubject]);

	useEffect(() => {
		if (!selectedSubject) {
			return;
		}

		onSubjectChange(selectedSubject);
	}, [selectedSubject]);

	useEffect(() => {
		service.init(initialSelectedSubjectID);
	}, [service, initialSelectedSubjectID]);

	return (
		<SubjectRootContext.Provider value={contextValue}>
			{children}
		</SubjectRootContext.Provider>
	);
}