import {Container, CheckBox, Wrapper, TestNameStyled, TestTagWrapper} from './index.styled';
import {noop} from 'underscore';
import React from 'react';
import {TestColorIndicator} from '@esgi/main/kits/common';
import {Test} from '../../../../service/types';
import {TestTypeLabel} from '@esgi/main/kits/reports';

type Props = {
	test: Test,
	checked: boolean,
}

export function TestRow({test, checked}: Props) {
	return (
		<Container>
			<Wrapper>
				<CheckBox data-cy='test-checkbox' checked={checked} active={checked} onCheckedChange={noop}/>
				<TestColorIndicator testID={test.id}/>
				<TestNameStyled data-cy='test-name' size='large' bold>
					{test.name}
				</TestNameStyled>
				<TestTagWrapper>
					<TestTypeLabel testType={test.type} />
				</TestTagWrapper>
			</Wrapper>
		</Container>
	);
}