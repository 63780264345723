import {CloseIcon, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {ErrorText} from '../error-text';
import {DictionaryItem} from '../../types';
import {ElementStatus, Form, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {UploadRosterService} from 'shared/modules/student-roster-upload-tool/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useEffect} from 'react';

interface Props {
	onClose: () => void;
	onNext: () => void;
	onSchoolSelected: (id: number) => void;
	service: UploadRosterService;
	schools: DictionaryItem<number>[];
}

export function SchoolSelectModal({onClose, onNext, onSchoolSelected, service, schools}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const isValid = useBehaviorSubject(service.schoolFormStatus) === ElementStatus.valid;

	useEffect(() => {
		service.schoolForm.onChanged.subscribe((value => {
			onSchoolSelected(value.currState.value.school[0].id);
		}));
	}, []);

	return <div data-cy={'add-student'}><Alert modalManagerRef={modalRef} className={styles.selectSchoolDialog}>
		<Alert.Header className={styles.header}>
			<Title className={styles.title}>Select School</Title>
			<CloseIcon className={styles.closeIcon} onClick={handleClose}/>
			<p className={styles.text}>Please select your school.</p>
		</Alert.Header>
		<Alert.Body className={styles.alertBody}>
			<Form controller={service.schoolForm} className={styles.fluid}>
				<FormElement control={service.schoolForm.controls.school}>
					<Dropdown className={styles.fluid} optionName='name' placeholder={'Select School'}>
						{schools?.map((school, i) =>
							<Option key={school.id} value={school}>{school.name}</Option>)}
					</Dropdown>
					<ErrorText showOnError={'required'} className={styles.errorText}>
						Please select a school.
					</ErrorText>
				</FormElement>
			</Form>
		</Alert.Body>
		<Alert.Footer className={styles.footer}>
			<Buttons.Contained disabled={!isValid} onClick={() => {
				onNext();
				handleClose();
			}}>Next</Buttons.Contained>
		</Alert.Footer>
	</Alert></div>;
}
