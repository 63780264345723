import {styled} from '@esgi/ui/theme';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';

export const CheckBox = styled(CheckboxUI, {
	padding: 0,
	'& > div': {
		background: '$border',
		borderColor: '$vivid',
	},

	variants: {
		active: {
			true: {
				'& > div': {
					background: '$primary92',
				},
			},
		},
		disabled: {
			true: {
				'& > div': {
					background: '$mild',
				},
				'& svg': {
					'& path': {
						fill: '$lowContrast',
					},
				},
			},
		},
	},
});

export const Container = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 20,
	paddingTop: 1,
	paddingBottom: 1,
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',
});

export const Wrapper = styled(GridBox, {
	height: 32,
	gridAutoFlow: 'column',
	gap: 12,
	justifyContent: 'start',
	alignItems: 'center',
});