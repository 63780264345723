import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';

interface Props {
	onClear: () => void;
	onCancel: () => void;
}

export function ClearAll({onClear, onCancel}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	const onClearClicked = () => {
		onClear();
		handleClose();
	};

	return <Alert modalManagerRef={modalRef}>
		<Alert.Header>
			<Title className={styles.title}>Clear All Questions?</Title>
		</Alert.Header>
		<Alert.Body className={styles.body}>
			<span data-cy='clear-all-message'
						className={styles.text}>Are you sure you want to clear all of the questions?</span>
		</Alert.Body>
		<Alert.Footer>
			<div data-cy='clear-all-footer'>
				<Buttons.Text onClick={handleClose}>CANCEL</Buttons.Text>
				<Buttons.Text onClick={onClearClicked}>YES,CLEAR ALL</Buttons.Text>
			</div>
		</Alert.Footer>
	</Alert>;
}