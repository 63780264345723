import {useCallback, useMemo} from 'react';
import {StudentFullName, getListValue, StudentSection} from '@esgi/main/features/teacher/home';
import {Student} from '@esgi/main/libs/store';
import {CheckAll} from '@esgi/ui/icons';
import {IconComponent, Tooltip} from '@esgi/ui';
import {noop} from 'underscore';
import {SelectableList, SelectableListRoot, SelectableListItem, SelectableListTrigger, SelectableLisItemContent} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	IconBefore: IconComponent;
	triggerLabel: string;
	isListOpen: boolean;
	onTriggerButtonClick: VoidFunction;
	onStyledButtonClick: VoidFunction;
	isListSelected: boolean;
	students: Student[];
	selectedStudentId: Student['id'][] | null;
	setSelectedStudentId: (value: Student['id']) => void;
};

export function ExpandableSelectableListMultiple({
	isListOpen,
	isListSelected,
	onTriggerButtonClick,
	onStyledButtonClick,
	IconBefore,
	triggerLabel,
	students,
	selectedStudentId,
	setSelectedStudentId,
}: Props) {
	const value = (selectedStudentId || []).map(getListValue);
	const checked = useCallback((id: string) => value.includes(id), [value]);
	const isAllSelected = useMemo(
		() => students?.every((student) => value?.includes(student.id.toString())),
		[students, value],
	);

	return (
		<SelectableListRoot isListOpen={isListOpen}>
			<SelectableList.GroupRoot
				type='multiple'
				value={value}
			>
				<SelectableListTrigger
					openList={isListOpen}
					onClick={students.length ? onTriggerButtonClick : noop}
					IconBefore={IconBefore}
					selected={isListSelected}
					hovered={students.length ? undefined : false}
					defaultCursor={Boolean(!students.length)}
				>
					<StudentSection.SelectableListTriggerContent itemsCount={students.length} triggerLabel={triggerLabel} />
					<Tooltip>
						<Tooltip.Trigger>
							<SelectableList.StyledButton onClick={onStyledButtonClick}>
								<CheckAll />
							</SelectableList.StyledButton>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<Text size='small'>{!isAllSelected ? 'Select All Students' : 'Deselect All'}</Text>
						</Tooltip.Content>
					</Tooltip>
				</SelectableListTrigger>
				<SelectableList.Group>
					{students.map(({firstName, lastName, id}) => (
						<SelectableListItem
							key={id}
							value={String(id)}
							checked={checked(String(id))}
							onClick={() => setSelectedStudentId(id)}
						>
							<SelectableLisItemContent>
								<StudentFullName firstName={firstName} lastName={lastName} />
							</SelectableLisItemContent>
						</SelectableListItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
