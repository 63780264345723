import React from 'react';
import './component.less';
import GradeScaleCheckbox
	from 'shared/modules/grade-scale/grade-scale/selector/item/grade-scale-checkbox/component';
import {IWizardGradeScale} from 'shared/modules/grade-scale/grade-scale/models';

export class Props {
	selected: boolean;
	onSelect: () => void;
	gradeScale: IWizardGradeScale;
	children?: any;
}

export default class GradeScaleItem extends React.PureComponent<Props> {
	render() {
		return <div className='grade-scale-item' onClick={() => this.props.onSelect()}>
			<GradeScaleCheckbox selected={this.props.selected} />
			<div className='info-block'>
				<div className='info-first-row'>
					{this.props.gradeScale.name}
				</div>
				{this.props.gradeScale.owner && <div className='info-second-row'>
					User: {this.props.gradeScale.owner}
				</div>}
			</div>
		</div>;
	}
}
