import React, {useRef} from 'react';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';

import styles from './input-with-label.module.less';

export interface InputWithLabelProps {
	value: number;
	onClick: (value: number) => any;
	label: string;
	showTooltip?: boolean;
	isBig?: boolean;
}

export default function InputWithLabel({value, onClick, label, showTooltip, isBig}: InputWithLabelProps) {
	const ref: React.RefObject<HTMLInputElement> = useRef();

	const validateNumber = (value: string) => {
		const parsedValue = parseInt(value);
		if (isNaN(parsedValue)) {
			return NaN;
		}
		if (parsedValue < 1) {
			return 1;
		}
		if (parsedValue > 99) {
			return 99;
		}

		return parsedValue;
	};
	return (
		<div className={styles.labelNumber}>
			<div>
				<span className={isBig ? styles.boldText : styles.label}>{label}</span>
				{showTooltip && <i className='glyphicon glyphicon-question-sign tip no-top-position'/>}
			</div>
			<div>
				<input
					type='number'
					ref={ref}
					className={join(styles.counterInput, !value && styles.hasError)}
					value={value}
					onChange={(e) => {
						const value = validateNumber(e.target.value);
						onClick(value);
					}}
				/>
				{!value &&
					<ValidationTooltip element={null} elementRef={ref} placement='right' container='body'>
						Incorrect value
					</ValidationTooltip>
				}
			</div>
		</div>
	);
}
