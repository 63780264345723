import React from 'react';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import {Primary} from '@esgi/deprecated/elements/buttons';
import {join} from '@esgillc/ui-kit/utils';
import BingoLogo from 'shared/modules/bingo/assets/bingo-logo';

import styles from './finish.module.less';

interface FinishProps {
	onClose: () => void,
	prevStep: () => void
}

export default function Finish ({onClose, prevStep}: FinishProps) {
	return (
		<div>
			<div>
				<div className={styles.header}>
					<BingoLogo/>
					<CloseIcon
						className={styles.closeIcon}
						color={'#fff'}
						onClick={onClose}
					/>
				</div>
				<div className={styles.subHeader}>
					<div className={styles.balls}/>
					<div className={styles.title}>
						Your Bingo cards have been downloaded. <br/> Thanks for playing!
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.sightWords}/>
				<div className={styles.letterSounds}/>
				<div className={styles.numbers}/>
			</div>
			<div>
				<div className={styles.footer}>
					<div className={styles.counter}>
							<i
								onClick={prevStep}
								className={join(
									'glyphicon glyphicon-chevron-left',
									styles.arrowIcon)}
							/>
						<div className={styles.pages}>5/5</div>
					</div>
					<Primary
						onClick={onClose}
						title='FINISH'
						className={styles.btn}
					/>
				</div>
			</div>
		</div>
	);
}
