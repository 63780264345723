import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {
	DistrictsResponse,
	ManageAccountInitArgs,
	ManageAccountRequestModel,
	ManageAccountSavedEvent, SchoolSearchResponseModel,
} from '../../types';
import {
	DefaultProfileControllers,
	DistrictModel,
	DropdownInModel, SchoolModel,
} from '../../../../types';
import {BehaviorSubject, map, tap, debounceTime} from 'rxjs';


const normalizeRegex = /[^a-zA-Z0-9\s]/g;

export class ManageAccountService extends BaseService {
	public countries = new BehaviorSubject<DropdownInModel[]>([]);
	public states = new BehaviorSubject<DropdownInModel[]>([]);

	public availableSchoolsByQuery = new BehaviorSubject<SchoolModel[]>([]);
	public defaultSelectedSchool = new BehaviorSubject<SchoolModel>(null);
	public selectedSchool = new BehaviorSubject<SchoolModel>(null);
	public isSchoolAvailableInList = new BehaviorSubject<boolean>(true);

	public loadedDistricts = new BehaviorSubject<DistrictModel[]>([]);
	public availableDistrictsByQuery = new BehaviorSubject<DistrictModel[]>([]);
	public isDistrictAvailableInList = new BehaviorSubject(true);
	public hasDistrict = new BehaviorSubject(true);
	public selectedDistrict = new BehaviorSubject(null);

	public currentUser = userStorage.get();

	public form = new FormGroup({
		state: new FormControl<DropdownInModel[]>([]),
		country: new FormControl<DropdownInModel[]>([]),
		school: new FormControl<string>('', {validators: [Validators.required()]}),
		district: new FormControl<string>('', {validators: [Validators.required()]}),
	});

	public init(
		{
			defaultStates,
			defaultCountries,
			schoolName,
			districtName,
			selectedCountry,
			selectedState,
		}: ManageAccountInitArgs) {

		this.states.next(defaultStates);
		this.countries.next(defaultCountries);

		this.form.controls.school.value = `${schoolName} (${districtName})`;
		this.form.controls.state.value = [selectedState];
		this.form.controls.country.value = [selectedCountry];
		this.form.controls.district.status = ElementStatus.disabled;
		this.form.controls.state.onChanged.subscribe((v) => {
			if (v.prevState.value[0] && v.currState.value[0].id !== v.prevState.value[0].id) {
				this.resetSchool();
			}
		});
		this.form.controls.school.onChanged.pipe(
			debounceTime(800))
			.subscribe((v) => {
				if (v.currState.value.length > 1 && this.isSchoolAvailableInList.value) {
					this.loadSchools(v.currState.value);
				}
			});
		this.form.controls.district.onChanged.subscribe((v) => {
			if (this.isDistrictAvailableInList.value && this.hasDistrict.value) {
				const query = v.currState.value.replace(normalizeRegex, '');
				const substrRegex = new RegExp(query, 'i');
				this.availableDistrictsByQuery.next(this.loadedDistricts.value.filter(d => substrRegex.test(d.name)));
			}
		});

		this.defaultSelectedSchool.next({
			federalSchoolName: schoolName,
			federalDistrictName: districtName,
			federalSchoolID: 0,
			federalDistrictID: 0,
		});
	}

	public setSchoolAvailableInList = (isAvailable: boolean) => {
		this.isSchoolAvailableInList.next(isAvailable);
		if (!isAvailable) {
			this.form.controls.district.status = null;
			this.loadDistricts();
		} else {
			this.form.controls.district.status = ElementStatus.disabled;
		}
	};

	public setDistrictAvailableInList = (isAvailable: boolean) => {
		this.isDistrictAvailableInList.next(isAvailable);
	};

	public setDontHaveDistrict = () => {
		this.hasDistrict.next(false);
		this.form.controls.district.value = this.form.controls.school.value;
		this.form.controls.district.status = ElementStatus.disabled;
	};

	public resetSchool = () => {
		this.form.controls.school.value = '';
		this.form.controls.district.value = '';
		this.selectedSchool.next(null);
		this.selectedDistrict.next(null);
		this.isDistrictAvailableInList.next(true);
		this.isSchoolAvailableInList.next(true);
		this.availableSchoolsByQuery.next([]);
	};

	public selectSchool = (school: SchoolModel) => {
		this.form.controls.school.value = `${school.federalSchoolName} (${school.federalDistrictName})`;
		this.selectedSchool.next(school);
	};

	public selectDistrict = (district) => {
		this.form.controls.district.value = district.name;
		this.selectedDistrict.next(district);
	};

	public save = () => {
		const model = new ManageAccountRequestModel(
			this.form.controls.country.value[0].id,
			this.isSchoolAvailableInList.value
				? this.selectedSchool.value.federalDistrictName
				: this.hasDistrict.value && this.isDistrictAvailableInList.value ? this.selectedDistrict.value.name
					: this.form.controls.district.value,
			this.currentUser.schoolID,
			this.isSchoolAvailableInList.value ? this.selectedSchool.value.federalSchoolName : this.form.controls.school.value,
			this.form.controls.state.value[0].id,
		);
		return this.httpClient.ESGIApi.post<boolean>(DefaultProfileControllers.Teacher, 'update-location', model)
			.pipe(map(() => new ManageAccountSavedEvent(
				this.states.value,
				this.countries.value,
				this.form.controls.state.value[0],
				this.form.controls.country.value[0],
				model.schoolName,
				model.districtName,
			)));
	};

	public getStatesByCountryID(countryID: number) {
		return this.httpClient.ESGIApi.get<{ id: number, name: string }[]>(DefaultProfileControllers.Common, 'get-states-by-country-id', {countryID})
			.pipe(tap(response => {
				const states = response.map(x => new DropdownInModel(x.id, x.name));
				this.states.next(states);
				this.form.controls.state.value = [states[0]];
			}));
	}

	private loadSchools(query: string) {
		query = query.replace(normalizeRegex, '');
		return this.httpClient.ESGIApi.get<SchoolSearchResponseModel>(DefaultProfileControllers.Common, 'federal-schools', {
			Query: query,
			StateID: this.form.controls.state.value[0].id,
		}).subscribe((response) => {
			this.availableSchoolsByQuery.next(response.items);
		});
	}

	private loadDistricts() {
		this.httpClient.ESGIApi.get<DistrictsResponse>(DefaultProfileControllers.Common, 'federal-districts', {
			StateID: this.form.controls.state.value[0].id,
		}).subscribe((response) => {
			this.loadedDistricts.next(response.federalDistricts);
		});
	}

}
