import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const Panel = styled(Box, {
	minWidth: 272,
	width: 'max-content',
	overflow: 'hidden',
	height: '100%',
	padding: 20,
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',
});
