import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {classesStore, groupsStore, studentsStore, Class, Group, Student} from '@esgi/main/libs/store';

export class StudentService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public readonly classes$ = new BehaviorSubject<Class[]>([]);
	public readonly groups$ = new BehaviorSubject<Group[]>([]);
	public readonly students$ = new BehaviorSubject<Student[]>([]);

	private readonly storageClasses = classesStore();
	private readonly storageGroups = groupsStore();
	private readonly storageStudents = studentsStore();

	constructor() {
		super();

		this.storageClasses.get().subscribe((value) => this.classes$.next(value.data));
		this.storageGroups.get().subscribe((value) => this.groups$.next(value.data));
		this.storageStudents.get().subscribe((value) => this.students$.next(value.data));
	}

	public override dispose() {
		this.storageClasses.dispose();
		this.storageGroups.dispose();
		this.storageStudents.dispose();
	}

	public get(studentId: number) {
		return this.students$.value.find(({id}) => id === studentId);
	}
}
