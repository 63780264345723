import {LableBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {BaseComponentProps} from '@esgi/ui';
import {TestType} from '@esgi/main/libs/core';
import {testLabelByTestType} from './constants';

type Props = BaseComponentProps & {
	testType: TestType;
};

export function TestTypeLabel({dataCy = 'test-type', testType, css = {}}: Props) {
	return (
		<LableBox dataCy={dataCy} css={css}>
			<Text data-cy={`${dataCy}-label`} size='small' color='neutral40'>
				{testLabelByTestType[testType]}
			</Text>
		</LableBox>
	);
}
