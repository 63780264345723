import {PropsWithChildren} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {ThemeProvider} from '../../../../theme';
import {Property} from '@stitches/react/types/css';
import {DropdownMenuContent} from './index.styled';

type DropdownBodyProps = PropsWithChildren<{
	/**
	 * CSS property sets the z-order of a positioned element.
	 * @default 700
	 */
	zIndex?: Property.ZIndex;
}>;

export function DropdownBody({children, zIndex = 700}: DropdownBodyProps) {
	return (
		<DropdownMenu.Portal>
			<ThemeProvider>
				<DropdownMenuContent align='end' css={{zIndex}}>
					{children}
				</DropdownMenuContent>
			</ThemeProvider>
		</DropdownMenu.Portal>
	);
}
