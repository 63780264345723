import React, {useCallback} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {
	ReportDisplayResultsMode,
} from '../../../../types';
import {
	StudentProgressReportService,
} from '../../../../services';
import {DisplayNotTestedFilter} from './components/display-not-tested-filter';
import {ShowResultsFilter} from './components/show-results-filter';
import {ShowFilter} from './components/show-filter';
import {GradeScaleLegend} from './components/grade-scales-legend';
import {ReportFilterBody, ReportFilterRow} from './index.styled';

interface Props {
	service: StudentProgressReportService;
}

export default function ReportSettings({service}: Props) {
	const report = useBehaviorSubject(service.report$);
	const gradeValues = useBehaviorSubject(service.gradeScales$);
	const reportDisplayResultsMode = useBehaviorSubject(service.displayType$);
	const reportSettings = useBehaviorSubject(service.reportSettings$);

	const changeDisplayResultsMode = useCallback((value: ReportDisplayResultsMode) => {
		service.setDisplayType(value);
	}, [service]);

	const changeShowGradeColors = useCallback((value: boolean) => {
		service.reportSettingValueChange({
			key: 'showGradeColors',
			newValue: value,
		});
	}, [service]);

	const changeCarryScoresForward = useCallback((value: boolean) => {
		service.reportSettingValueChange({
			key: 'carryScoresForward',
			newValue: value,
		});
	}, [service]);

	const changeShowBaseline = useCallback((value: boolean) => {
		service.reportSettingValueChange({
			key: 'showBaseline',
			newValue: value,
		});
	}, [service]);

	const changeDisplayZeroIfNotTested = useCallback((value: boolean) => {
		service.reportSettingValueChange({
			key: 'displayZeroIfNotTested',
			newValue: value,
		});
	}, [service]);

	return <ReportFilterBody>
		{
      report !== null && <ReportFilterRow>
				{
					report.gradeScales.length === 1 && <GradeScaleLegend gradeValues={gradeValues[0]?.gradeValues ?? []} />
				}
				<ShowFilter reportDisplayResultsMode={reportDisplayResultsMode}
				            changeDisplayResultsMode={changeDisplayResultsMode}
				            hasGradeScales={report?.subjectGradeScales?.length > 0}
				            showGradeColors={reportSettings.showGradeColors}
				            changeShowGradeColors={changeShowGradeColors}/>
				<ShowResultsFilter
					onCarryForwardClicked={changeCarryScoresForward}
					onShowBaseLineClicked={changeShowBaseline}
					carryScoresForward={reportSettings.carryScoresForward}
					showBaseline={reportSettings.showBaseline}
				/>
				<DisplayNotTestedFilter
					onClicked={changeDisplayZeroIfNotTested}
					displayZeroIfNotTested={reportSettings.displayZeroIfNotTested}
				/>
			</ReportFilterRow>
		}
	</ReportFilterBody>;
}

