import {UserType} from './enum';

/**
 * The function `userTypeTransform` takes a value and returns the corresponding `UserType` enum value
 * if it is a valid string representation, otherwise it returns `UserType.V`.
 * @param {any} value - The `value` parameter is the input value that needs to be transformed into a
 * `UserType` enum value.
 * @returns a value of type `UserType`.
 */
export function userTypeTransform(value: any): UserType {
	if (typeof value == 'string') {
		// @ts-ignore
		const out = UserType[value];
		if(out) {
			return out;
		}
	}

	return UserType.V;
}
