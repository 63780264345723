import React from 'react';
import {EventBusManager} from '@esgillc/events';
import {FilterType} from '../../../enums';
import {TestExplorerBackEvent} from '../../../events';
import {RemoveFilterEvent} from '../../header/search-tags/search-tags';
import {FeaturedSeriesModel} from '../../landing/featured-series/featured-series';
import {FeaturedSeriesEvents} from './events';

export class State {
	selected: FeaturedSeriesModel = new FeaturedSeriesModel();
}

export class Props {
	hasChanged: (featuredSeries: FeaturedSeriesModel | null) => any;
}

export class FeaturedSeries extends React.Component<Props, State> {
	private readonly eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	componentWillUnmount() {
		this.eventBus.destroy();
	}

	render() {
		return <></>;
	}

	componentDidMount(): void {
		this.eventBus.subscribe(RemoveFilterEvent, (args) => {
			if (args.type === FilterType.FeaturedSeries) {
				this.setSeries(new FeaturedSeriesModel());
			}
		});

		this.eventBus.subscribe(FeaturedSeriesEvents.SeriesClicked, (args: FeaturedSeriesEvents.SeriesClicked.Args) => {
			this.setSeries(args.featuredSeries);
		});

		this.eventBus.subscribe(TestExplorerBackEvent, () => {
			this.setState({selected: new FeaturedSeriesModel()});
		});
	}

	setSeries(featuredSeries: FeaturedSeriesModel) {
		this.setState({selected: featuredSeries}, () => {
			this.props.hasChanged(featuredSeries);
		});
	}
}
