import {Page, LeftSidebar, Content, Header, Divider} from '../../layout/index.styled';
import {useBehaviorSubject, useStream} from '@esgillc/ui-kit/utils';
import {StudentProgressReport as StudentProgressReportUI, useStudentProgressReport, StudentProgressReportContext} from '@esgi/main/features/teacher/student-progress-report';
import {Reports} from '@esgi/main/kits/reports';
import {OverlayScrollbarsComponentStyled, RightSidebarStyled} from './index.styled';
import {useMemo, useState} from 'react';
import {StudentTabId} from '../../../home/types';
import {Class, Group} from '@esgi/main/libs/store';

function StudentProgressReport() {
	const {service} = useStudentProgressReport();

	const isPageLoading = useBehaviorSubject(service.isPageLoading$);
	const canRunReportPreview = useStream(service.canRunReportPreview$, false);

	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const students = useBehaviorSubject(service.studentService.students$);

	const selectedStudentsIDs = useBehaviorSubject(service.selectedStudentsIDs$);
	const selectedSubjectID = useBehaviorSubject(service.selectedSubjectID$);

	const tests = useBehaviorSubject(service.testService.tests$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const studentsLoaded = useBehaviorSubject(service.studentService.loaded$);

	const displayType = useBehaviorSubject(service.displayType$);
	const gradeScales = useBehaviorSubject(service.gradeScales$);

	const testsLoaded = useBehaviorSubject<boolean>(service.testService.loaded$);
	const reportSettings = useBehaviorSubject(service.reportSettings$);

	const isReportAlertOpen = useBehaviorSubject(service.isReportAlertOpen$);

	const isDisabled = isPageLoading || !canRunReportPreview;

	const [activeTab, setActiveTab] = useState(StudentTabId.Classes);

	const {emptyTestsMessage, filteredTests} = useMemo(() => {
		let emptyTestsMessage = 'There is no test data for the student.';
		let filteredTests = tests;

		const hasTestedTests = filteredTests.some((x) => x.isTested);
		if (!hasTestedTests) {
			filteredTests = [];
			if (selectedStudentsIDs.length > 1) {
				emptyTestsMessage = 'There is no test data for any student selected.';
			}

			if(activeTab === StudentTabId.Classes) {
				const fullySelectedNumber = getFullySelectedUnitNumber(classes, selectedStudentsIDs);
				if (fullySelectedNumber > 0){
					emptyTestsMessage = 'There is no test data for any student in ' + (fullySelectedNumber === 1 ? 'this class' : 'these classes') + '.' ;
				}
			}
			if(activeTab === StudentTabId.Groups) {
				const fullySelectedNumber = getFullySelectedUnitNumber(groups, selectedStudentsIDs);
				if (fullySelectedNumber > 0){
					emptyTestsMessage = 'There is no test data for any student in ' + (fullySelectedNumber === 1 ? 'this group' : 'these groups') + '.' ;
				}
			}
		}

		return {emptyTestsMessage, filteredTests};
	}, [activeTab, classes, groups, selectedStudentsIDs, tests]);

	function getFullySelectedUnitNumber(units: Class[] | Group[], studentIDs: number[]){
		let fullySelectedNumber = 0;
		let allClassStudents: number[] = [];
		for(const unit of units){
			const fullySelected = unit.studentIDs.length > 0 && unit.studentIDs.filter(x => studentIDs.includes(x)).length === unit.studentIDs.length;
			if (fullySelected){
				fullySelectedNumber++;
				allClassStudents = allClassStudents.concat(unit.studentIDs);
			}
		}
		if (studentIDs.every(x => allClassStudents.includes(x))){
			return fullySelectedNumber;
		}
		return 0;
	}

	return (
		<StudentProgressReportContext.Provider value={service}>
			<Page>
				<LeftSidebar>
					{studentsLoaded && (
						<StudentProgressReportUI.StudentsControl
							classes={classes.filter(x => x.studentIDs.length !== 0)}
							groups={groups.filter(x => x.studentIDs.length !== 0)}
							students={students}
							onChange={(value) => service.setStudents(value)}
							onActiveTabChange={setActiveTab}
						/>
					)}
				</LeftSidebar>
				<Content>
					<Header>
						<Reports.StudentPanel
							studentCounter={selectedStudentsIDs.length}
							student={service.studentService.get(selectedStudentsIDs.length && selectedStudentsIDs[0])}
						/>
						<Divider />
						<StudentProgressReportUI.SubjectTabControl
							subjects={subjects}
							value={selectedSubjectID}
							onChange={service.setSubject.bind(service)}
						/>
					</Header>
					{selectedStudentsIDs.length ? (
						<StudentProgressReportUI.TestsControl
							skeleton={!testsLoaded}
							tests={filteredTests}
							onChange={(value) => service.setTestsIDs(value)}
							allSubjectsMode={selectedSubjectID === -1}
							emptyTestsMessage={emptyTestsMessage}
						/>
					) : (
						<Reports.NoStudentPanel />
					)}
				</Content>
				<OverlayScrollbarsComponentStyled>
					<RightSidebarStyled>
						<StudentProgressReportUI.DownloadPanel
							downloadTitle='Run Report'
							disabled={isDisabled}
							onClick={service.openReportAlert.bind(service)}
						/>
						<StudentProgressReportUI.DisplayTypeControl
							value={displayType}
							onChange={(value) => service.setDisplayType(value)}
							disabled={isDisabled}
							gradeDisabled={gradeScales.length === 0}
						/>
						<StudentProgressReportUI.SettingsControl
							disabled={isDisabled}
							value={reportSettings}
							onSettingValueChange={service.reportSettingValueChange.bind(service)}
						/>
						<StudentProgressReportUI.DisplayNotTestedControl
							disabled={isDisabled}
							value={reportSettings.displayZeroIfNotTested}
							onChange={(value) => service.reportSettingValueChange({key: 'displayZeroIfNotTested', newValue: value})}
						/>
						<StudentProgressReportUI.ColorSelectorControl
							disabled={isDisabled}
							value={reportSettings.showGradeColors}
							onChange={(value) => service.reportSettingValueChange({key: 'showGradeColors', newValue: value})}
						/>
					</RightSidebarStyled>
				</OverlayScrollbarsComponentStyled>
			</Page>
			{isReportAlertOpen && <StudentProgressReportUI.PreviewDialog service={service} onClose={() => service.isReportAlertOpen$.next(false)} />}
		</StudentProgressReportContext.Provider>
	);
}

export default StudentProgressReport;
