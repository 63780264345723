import React, {PureComponent} from 'react';
import {tryCall} from '@esgillc/ui-kit/utils';

interface Props {
	onClick?: () => void;
}

export class ArrowIcon extends PureComponent<Props & { className?: string }> {
	render() {
		return <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'
		            className={this.props.className} onClick={() => tryCall(this.props.onClick)}>
			<circle r='15' transform='matrix(-1 0 0 1 15 15)' fill='#EEEEEE'/>
			<path d='M10.802 8.696L16.7822 15L10.802 21.304L12.4109 23L19.9999 15L12.4109 7L10.802 8.696Z'
			      fill='#424242'/>
		</svg>;
	}
}
