import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {SpecialistActivityReportService} from './service';
import {DownloadIcon} from './components/icons/download-icon';
import {ReportTable} from './components/table';
import {sortRows} from 'modules/reports/specialist-activity/constants';
import {ReportModalHeader} from '@esgi/deprecated/ui-kit/report/modal';
import styles from './styles.module.less';

interface Props {
	groupOfSpecialistsID: number;
	onCloseClicked: () => void;
}

export default function SpecialistActivityReport({onCloseClicked, groupOfSpecialistsID}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCloseClicked);
	const service = useService(SpecialistActivityReportService);
	const {reportService} = service;
	const sortModel = useBehaviorSubject(reportService.sortModel);
	const rows = useBehaviorSubject(reportService.reportRows);
	const [isInitLoading, setInitLoading] = useState(false);
	const [isDownloadMenuOpen, setDownloadMenuOpen] = useState(false);

	const downloadPdfClicked = () => {
		service.exportPdf();
		setDownloadMenuOpen(false);
	};

	const downloadExcelClicked = () => {
		service.exportExcel();
		setDownloadMenuOpen(false);
	};

	useEffect(() => {
		setInitLoading(true);
		service.init(groupOfSpecialistsID).subscribe({
			complete: () => setInitLoading(false),
		});
	}, []);

	return <>
		<ServiceLoader trackingService={service} fullscreen/>
		{!isInitLoading && <Modal modalManagerRef={modalRef} className={styles.modal}>
			<ReportModalHeader className={styles.modalHeader}>
				<div>
					<Title className={styles.title}>Specialist Activity</Title>
					<div className={styles.downloadWrapper}>
						<button className={styles.downloadButton} onClick={() => {
							setDownloadMenuOpen(!isDownloadMenuOpen);
						}}>
							<DownloadIcon/>
							<span>Download</span>
						</button>

						<Menu className={styles.menu} show={isDownloadMenuOpen}
						      onClickOutside={() => setDownloadMenuOpen(false)}>
							<MenuItem onSelect={downloadPdfClicked}>PDF</MenuItem>
							<MenuItem onSelect={downloadExcelClicked}>Excel</MenuItem>
						</Menu>

					</div>
				</div>
				<CloseIcon onClick={handleClose} color='#fff'/>
			</ReportModalHeader>

			<Modal.Body className={styles.body}>
				<ReportTable
					rows={rows}
					onSortClicked={(name) => reportService.sort(name)}
					sortRows={sortRows}
					sortModel={sortModel}
				/>
			</Modal.Body>
		</Modal>}
	</>;
}
