import {getUser, UserType} from '@esgi/core/authentication';
import {
	ClassicHierarchyLevel,
	HierarchyMode,
	HierarchySnapshot, PreAssessHierarchyLevel,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {SubjectType, TestType} from '@esgi/core/enums';
import {SubjectModel} from '../../../services/subjects-service/models';

export function canRemoveTestFromSubject(subject: SubjectModel) {
	const currentUser = getUser();

	if (!subject) {
		return;
	}

	if (subject.type === SubjectType.Personal && [UserType.T, UserType.ISS, UserType.ISD].includes(currentUser.userType)) {
		return true;
	}

	if (subject.type === SubjectType.Deployed && subject.level === 'School' && currentUser.userType === UserType.C) {
		return true;
	}

	if (subject.type === SubjectType.Deployed && subject.level === 'District' && currentUser.userType === UserType.D) {
		return true;
	}

	return false;
}

export function isUserLevel(hierarchy: HierarchySnapshot) {
	const {mode, classic, specialist, preAssess} = hierarchy;
	const isClassic = mode === HierarchyMode.Classic && [
		ClassicHierarchyLevel.Student,
		ClassicHierarchyLevel.Group,
		ClassicHierarchyLevel.Class,
		ClassicHierarchyLevel.Teacher,
	].includes(classic.selected.level);
	const isSpecialist = mode === HierarchyMode.Specialist && [
		SpecialistHierarchyLevel.Student,
		SpecialistHierarchyLevel.Group,
		SpecialistHierarchyLevel.User,
	].includes(specialist.selected.level);
	const isPreAssess = mode === HierarchyMode.PreAssess && [
		PreAssessHierarchyLevel.Student,
		PreAssessHierarchyLevel.Group,
		PreAssessHierarchyLevel.User,
	].includes(preAssess.selected.level);

	return isClassic || isSpecialist || isPreAssess;
}

export function pieChartOptions(studentsTested: number, correctPercent: number, testType: string, testResultsCorrectVerbiage: string, testResultsIncorrectVerbiage: string) {
	const tested = studentsTested > 0;
	const incorrectPercent = 100 - correctPercent;

	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			backgroundColor: null,
			type: 'pie',
			width: 80,
			height: 80,
		},
		title: {
			enabled: false,
			text: '',
		},
		legend: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			enabled: true,
			headerFormat: '',
			pointFormat: testType === TestType[TestType.YN] ? '<span><span style="font-weight: bold; text-overflow: unset">{point.y}%</span><br />{point.name}</span>' : '<span style="font-weight: bold">{point.y}%</span>',
		},
		plotOptions: {
			pie: {
				borderColor: '#D8D8D8',
				size: 73,
				colors: ['#00BF96', '#FFFFFF'],
				allowPointSelect: false,
				cursor: 'pointer',
				slicedOffset: 0,
				dataLabels: {
					enabled: false,
				},
			},
			series: {
				animation: false,
			},
		},
		series: [{
			name: 'Student Name',
			colorByPoint: true,
			borderColor: '#D8D8D8',
			data: [{
				name: testResultsCorrectVerbiage.replace(/ /g, '\u00a0'),
				y: correctPercent,
				selected: true,
				color: '#00BF96',
			}, {
				name: testResultsIncorrectVerbiage.replace(/ /g, '\u00a0'),
				y: incorrectPercent,
				color: '#FFF',
			}],
		}],
	};

	if (correctPercent === 0) {
		if (tested) {
			options.series[0].data.splice(0, 1);
			options.tooltip.pointFormat = '<span><span style="font-weight: bold">0%</span> ' + testResultsCorrectVerbiage + '</span>';
		} else {
			options.series[0].data = [{
				name: '',
				color: '#EEE',
				selected: true,
				y: 100,
			}];
			options.tooltip.enabled = false;
		}
	}

	if (correctPercent === 100) {
		options.series[0].data.splice(1, 1);
	}

	return options;
}

export function calcPercentage(correct: number, total: number) {
	let correctPercent = Math.round(correct * 100 / (total || 1));
	if (isNaN(correctPercent)) {
		correctPercent = 0;
	}

	return {
		correctPercent,
		incorrectPercent: 100 - correctPercent,
	};
}

export function getStudentID(h: HierarchySnapshot) {
	if (h.mode === HierarchyMode.Classic) {
		return h.classic.studentID;
	}

	if (h.mode === HierarchyMode.Specialist) {
		return h.specialist.studentID;
	}

	if (h.mode === HierarchyMode.PreAssess) {
		return h.preAssess.studentID;
	}

	return 0;
}

export function isStudentLevel(h: HierarchySnapshot) {
	return h && (
		(h.mode === HierarchyMode.Classic && h.classic.selected.level === ClassicHierarchyLevel.Student)
		|| (h.mode === HierarchyMode.Specialist && h.specialist.selected.level === SpecialistHierarchyLevel.Student)
		|| (h.mode === HierarchyMode.PreAssess && h.preAssess.selected.level === PreAssessHierarchyLevel.Student)
	);
}
