import {FormField, Validators} from '@esgi/deprecated/knockout';
import {FieldContainer} from 'pages/landing/invitation/common/component';

export class PasswordInputComponent extends FieldContainer<string> {
	public template = () => {
		return <div data-bind='with: field'>
			<div className='form-group'
			     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
				<label className='control-label'>Create Password</label>
				<div>
					<input id='password' name='password' type='password' className='form-control input-md'
					       data-bind='value: value'/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
					<ko data-bind='if: showSuccessValidation'>
						<i className='fa fa-check form-control-feedback'/>
					</ko>
					<ko data-bind='if: showErrorValidation '>
						<i className='fa fa-close form-control-feedback'/>
					</ko>
				</div>
			</div>
		</div>;
	};

	buildField(initValue?): FormField<string> {
		const f = this.createField(initValue || '',
			Validators.Required('<div>Please enter a password. Passwords must</div><div>contain a minimum of 8 characters.</div>'),
			Validators.Length(8, null, '<div>Please enter a valid password. Passwords</div><div>must contain a minimum of 8 characters.</div>'),
		);
		f.validation.validationMessageTitleUseHtml = true;
		f.validation.showValidation(true);
		f.validation.successValidation(true);
		return f;
	}
}
