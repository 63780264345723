import {Buttons} from '@esgillc/ui-kit/button';
import {useState} from 'react';
import IepStatusFormModal from '../../components/iep-status';
import {IEPGoalFormService} from '../../services';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';

type Props = {
	service: IEPGoalFormService;
	className?: string;
}

export function AddStatusButton({service, className}: Props) {
	const [isShowed, setIsShowed] = useState(false);

	return (
		<>
			<Buttons.Link
				className={join(styles.link, className)}
				onClick={() => setIsShowed(true)}
			>
				Add status
			</Buttons.Link>
			{isShowed &&
				<IepStatusFormModal
					service={service}
					onClose={() => setIsShowed(false)}
				/>
			}
		</>
	);
}
