import {SettingsModel, StudentResultModel} from 'shared/modules/reports/pie-chart/service/models';
import {StudentSort, TestType, SubjectType} from '@esgi/core/enums';

export function formattedDate (testResult) {
	if (testResult.lastTestDate) {
		const date = new Date(testResult.lastTestDate);
		const month = (date.getMonth() + 1);
		const monthStr = (month < 10 ? '0' : '') + month;
		const day = date.getDate();
		const dayStr = (day < 10 ? '0' : '') + day;
		return monthStr + '-' + dayStr + '-' + (date.getFullYear() % 1000);
	}
}

export function getUnities (settings: SettingsModel) {
	let specialistGroups = settings?.specialistGroups;
	let unities = [];

	if (settings.specialistGroups) {
		if (settings.specialistGroupID > 0) {
			specialistGroups = specialistGroups.filter(x => x.unityID === settings.specialistGroupID);
		}
		unities = unities.concat(specialistGroups);
	} else {
		let groups = settings.groups;

		if (groups) {
			if (settings?.groupID > 0) {
				groups = groups.filter(x => x.unityID === settings.groupID);
			}
			unities = unities.concat(groups);
		} else {
			if (settings?.schools?.length > 0) {
				let schools = settings.schools;
				if (settings.schoolID != null) {
					schools = schools.filter(x => x.schoolID === settings.schoolID);
				}
				const school = schools[0];
				if (settings.teacherID > 0) {
					let classes = school.teachers.filter(x => x.teacherID === settings.teacherID)[0].classes;
					if (classes) {
						if (settings.classID > 0) {
							classes = classes.filter(x => x.unityID === settings.classID);
						}
						unities = unities.concat(classes);
					}
				}
			}
		}
	}
	return unities;
}

export function getStudents (settings, unities) {
	const students = [];

	for (let i = 0; i < unities.length; i++) {
		const unityStudents = unities[i].students;
		for (let j = 0; j < unityStudents.length; j++) {
			const st = unityStudents[j];
			if (students.filter(x => x.studentID === st.studentID).length === 0) {
				students.push(st);
			}
		}
	}

	return students.sort((a, b) => {
		let result = 0;
		const aFirst = settings.sortBy === StudentSort.LastName ? a.lastName.toLowerCase() : a.firstName.toLowerCase();
		const aSecond = settings.sortBy === StudentSort.LastName ? a.firstName.toLowerCase() : a.lastName.toLowerCase();
		const bFirst = settings.sortBy === StudentSort.LastName ? b.lastName.toLowerCase() : b.firstName.toLowerCase();
		const bSecond = settings.sortBy === StudentSort.LastName ? b.firstName.toLowerCase() : b.lastName.toLowerCase();

		if (aFirst > bFirst) {
			result = 1;
		} else if (aFirst < bFirst) {
			result = -1;
		} else if (aSecond > bSecond) {
			result = 1;
		} else if (aSecond < bSecond) {
			result = -1;
		}

		return result;
	});
}

export function getPieChartOptions (
	testType: TestType,
	correctPercent: number,
	testResultsCorrectVerbiage,
	testResultsIncorrectVerbiage,
	printInColor,
) {
	const tested = correctPercent != null;
	const correctColor = printInColor ? '#00BF96' : '#7B7B7B';

	if (correctPercent == null) {
		correctPercent = 0;
	}

	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			backgroundColor: null,
			type: 'pie',
			width: 80,
			height: 80,
			events: {
				load: (e) => {
					if (e.target.container.parentNode.style.overflow === 'hidden') {
						e.target.container.parentNode.style.overflow = 'initial';
					}
				},
			},
		},
		title: {
			enabled: false,
			text: '',
		},
		legend: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			enabled: true,
			headerFormat: '',
			pointFormat: testType.toString() === TestType[TestType.YN]
				? '<span><span style="font-weight: bold text-overflow: unset">{point.y}%</span> {point.name}</span>'
				: '<span style="font-weight: bold">{point.y}%</span>',
		},
		plotOptions: {
			pie: {
				borderColor: '#D8D8D8',
				size: 73,
				colors: ['#00BF96', '#FFFFFF'],
				allowPointSelect: false,
				cursor: 'pointer',
				slicedOffset: 0,
				dataLabels: {
					enabled: false,
				},
			},
			series: {
				animation: false,
			},
		},
		series: [{
			name: 'Student Name',
			colorByPoint: true,
			borderColor: '#D8D8D8',
			data: [{
				name: testResultsCorrectVerbiage,
				y: correctPercent,
				selected: true,
				color: correctColor,
			}, {
				name: testResultsIncorrectVerbiage,
				y: 100 - correctPercent,
				color: '#FFF',
			}],
		}],
	};

	if (correctPercent === 0) {
		if (tested) {
			options.series[0].data.splice(0, 1);
			options.tooltip.pointFormat = '<span><span style="font-weight: bold">0%</span> ' + testResultsCorrectVerbiage + '</span>';
		} else {
			options.series[0].data = [{
				name: '',
				color: '#EEE',
				selected: true,
				y: 100,
			}];
			options.tooltip.enabled = false;
		}
	}

	if (correctPercent === 100) {
		options.series[0].data.splice(1, 1);
	}

	return options;
}

export function getSortedStudents (students, sortBy): StudentResultModel[] {
	return students.sort((a, b) => {
		let result = 0;
		const aFirst = sortBy === StudentSort.LastName ? a.lastName.toLowerCase() : a.firstName.toLowerCase();
		const aSecond = sortBy === StudentSort.LastName ? a.firstName.toLowerCase() : a.lastName.toLowerCase();
		const bFirst = sortBy === StudentSort.LastName ? b.lastName.toLowerCase() : b.firstName.toLowerCase();
		const bSecond = sortBy === StudentSort.LastName ? b.firstName.toLowerCase() : b.lastName.toLowerCase();

		if (aFirst > bFirst) {
			result = 1;
		} else if (aFirst < bFirst) {
			result = -1;
		} else if (aSecond > bSecond) {
			result = 1;
		} else if (aSecond < bSecond) {
			result = -1;
		}

		return result;
	});
}

export function getSubjectType (type) {
	if (typeof type === 'string') {
		return SubjectType[type];
	}
	return type;
}
