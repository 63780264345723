import {Observable} from 'rxjs';
import {StudentResponse} from './types';
import {WidgetService} from '../service';
import {StudentBundle, ClassBundle} from '../types';

export class TestsTakenService extends WidgetService<StudentResponse, any> {
	protected requestStudentData(request: StudentBundle): Observable<StudentResponse> {
		return this.studentsController.testsTaken({testIDs: request.testIDs, studentID: request.studentID, classStudentsIDs: request.classStudentIDs});
	}

	protected requestClassData(request: ClassBundle): Observable<any> {
		throw new Error('Method not implemented.');
	}
}