import {FunctionComponent} from 'react';
import {PreModel, ViewType} from 'modules/forms/admin-group-forms/models/types';
import {SchoolsGroupContainer} from 'modules/forms/admin-group-forms/containers/schools-group/schools-group-container';
import {TeachersGroupContainer} from 'modules/forms/admin-group-forms/containers/teachers-group/teachers-group-container';
import {SpecialistsGroupContainer} from 'modules/forms/admin-group-forms/containers/specialists-group/specialists-group-container';

type Props = {
	viewType: ViewType;
	preModel: PreModel;
	onClose: () => void;
}

export function GroupFormModal(props: Props): JSX.Element {
	if (props.viewType === ViewType.SchoolsGroup) {
		return <SchoolsGroupContainer
			onClose={props.onClose}
			preModel={props.preModel}/>;
	}

	if (props.viewType === ViewType.TeachersGroup) {
		return <TeachersGroupContainer
			onClose={props.onClose}
			preModel={props.preModel}/>;
	}

	if (props.viewType === ViewType.GroupOfSpecialists) {
		return <SpecialistsGroupContainer
			onClose={props.onClose}
			preModel={props.preModel}/>;
	}
}

export default GroupFormModal as FunctionComponent<Props>;
