import {createContext, useContext} from 'react';
import {Point, Area} from 'react-easy-crop';
import {Size} from 'react-easy-crop/types';
import {noop} from 'underscore';

export type ImageCropperContextValue = {
	imageBase64: string | null;
	onChangeImageBase64: (url: string) => void;
	onChangeImageFile: (imgFile: File) => void;
	crop: Point;
	onCropChange: (point: Point) => void;
	zoom: number;
	minZoom?: number;
	maxZoom?: number;
	step: number;
	croppedAreaSize: Size;
	onChangeCroppedAreaPixels: (area: Area, zoom: number) => void;
	onZoomChange: (value?: number) => void;
	errorMessage: string
	onChangeErrorMessage: (value: string) => void;
};

export const ImageCropperContext = createContext<ImageCropperContextValue>({
	imageBase64: '',
	onChangeImageBase64: noop,
	onChangeImageFile: noop,
	crop: {x: 0, y: 0},
	onCropChange: noop,
	zoom: 1,
	minZoom: 1,
	maxZoom: 3,
	step: 0.1,
	croppedAreaSize: {width: 0, height: 0},
	onChangeCroppedAreaPixels: noop,
	onZoomChange: noop,
	errorMessage: '',
	onChangeErrorMessage: noop,
});

export function useImageCropperContext() {
	return useContext(ImageCropperContext);
}
