import * as React from 'react';
import type {SVGProps} from 'react';

export function AccessExport(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M16 8.7C14.5916 8.7 13.45 9.84168 13.45 11.25V12.95H11.75V11.25C11.75 8.90279 13.6527 7 16 7C18.058 7 19.7743 8.46282 20.166 10.4054C20.2588 10.8656 19.8694 11.25 19.4 11.25C18.9305 11.25 18.5638 10.859 18.4102 10.4154C18.0646 9.41695 17.116 8.7 16 8.7Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.1431 21.5176L25.1431 18.0299C25.1431 17.708 24.9687 17.4268 24.7092 17.2758C24.5664 17.1763 24.3931 17.1174 24.206 17.1174L20.7183 17.1174C20.2367 17.1174 19.8463 17.5077 19.8463 17.9893C19.8463 18.4709 20.2367 18.8612 20.7183 18.8612L22.2426 18.8616L18.5001 22.5C18.1596 22.8405 18.1596 23.3926 18.5001 23.7331C18.8406 24.0736 19.3927 24.0736 19.7332 23.7331L23.3993 20.1712V21.5176C23.3993 21.9992 23.7897 22.3895 24.2712 22.3895C24.7528 22.3895 25.1431 21.9992 25.1431 21.5176Z'
				fill='#333333'
			/>
			<path
				d='M9.19995 15.5C9.19995 14.0916 10.3415 12.95 11.75 12.95H13.45L21.15 12.95C21.6194 12.95 22 13.3306 22 13.8C22 14.2695 21.6194 14.65 21.15 14.65H11.75C11.2808 14.65 10.9 15.0308 10.9 15.5V21.45C10.9 21.9192 11.2808 22.3 11.75 22.3H15.4C15.8694 22.3 16.25 22.6806 16.25 23.15C16.25 23.6195 15.8694 24 15.4 24H11.75C10.3415 24 9.19995 22.8585 9.19995 21.45V15.5Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.45 11.25C13.45 9.84168 14.5916 8.7 16 8.7C17.4083 8.7 18.55 9.84168 18.55 11.25V12.95H20.25V11.25C20.25 8.90279 18.3471 7 16 7C13.6527 7 11.75 8.90279 11.75 11.25V12.95H13.45V11.25Z'
				fill='#333333'
			/>
			<path
				d='M12.5 17C12.5 16.5858 12.8358 16.25 13.25 16.25C13.6642 16.25 14 16.5858 14 17C14 17.4142 13.6642 17.75 13.25 17.75C12.8358 17.75 12.5 17.4142 12.5 17Z'
				fill='#333333'
			/>
			<path
				d='M12.5 20C12.5 19.5858 12.8358 19.25 13.25 19.25C13.6642 19.25 14 19.5858 14 20C14 20.4142 13.6642 20.75 13.25 20.75C12.8358 20.75 12.5 20.4142 12.5 20Z'
				fill='#333333'
			/>
			<path
				d='M15.25 17C15.25 16.5858 15.5858 16.25 16 16.25C16.4142 16.25 16.75 16.5858 16.75 17C16.75 17.4142 16.4142 17.75 16 17.75C15.5858 17.75 15.25 17.4142 15.25 17Z'
				fill='#333333'
			/>
			<path
				d='M15.25 20C15.25 19.5858 15.5858 19.25 16 19.25C16.4142 19.25 16.75 19.5858 16.75 20C16.75 20.4142 16.4142 20.75 16 20.75C15.5858 20.75 15.25 20.4142 15.25 20Z'
				fill='#333333'
			/>
		</svg>
	);
}
