import {useEffect, useState} from 'react';
import {Checkbox} from '@esgi/ui/form-controls';
import {FormControl, FormElement} from '@esgi/ui/form';
import {useStreamEffect} from '@esgi/ui/utils';
import {GradeRange} from '../../types';
import {Section, Title, SectionItem} from '../index.styled';
import {GradeSectionItem, GradeIndicatorBox} from './index.styled';

interface Props {
	includeGradesControl: FormControl;
	gradeRanges: GradeRange[]
}

export function GradesControl({includeGradesControl, gradeRanges}: Props) {

	const [isDisabled, setDisabled] = useState(false);

	useEffect(() => {
		setDisabled(!includeGradesControl.value);
	}, []);

	useStreamEffect(includeGradesControl.onChanged, (v) => {
		setDisabled(!v.currState.value);
	});

	return (
		<Section>
			<Title size='small' font='mono' color='lowContrast'>Grades</Title>
			<SectionItem>
				<FormElement control={includeGradesControl}>
					<Checkbox label='Include Grades'/>
				</FormElement>
			</SectionItem>

			<GradeSectionItem>
				{gradeRanges.map(grade => (
					<GradeIndicatorBox
						key={grade.label}
						gradeValue={grade.label}
						indicatorColor={isDisabled ? 'muted' : grade.color}
						indicatortextColor={isDisabled ? 'vivid' : grade.labelColor}
					/>
				))}
			</GradeSectionItem>
		</Section>
	);
}
