import './state-standards-library.less';
import React from 'react';
import {Renderable} from '@esgi/deprecated/knockout';
import {Api} from './api';
import {ContentAreaSection, StateStandard, StateStandardResponse} from 'shared/modules/state-standards-library/models';

export class Options {
	stateId: number;
	selectedStateStandardId?: number;
	selectedStateStandardText?: string;
	selectedContentAreaId: number;
}

export class StateStandardsLibrary extends Renderable {

	constructor(public options: Options) {
		super();
		this.selectedContentAreaId = options.selectedContentAreaId;
		this.selectedStateStandard = ko.observable({
			id: options.selectedStateStandardId,
			name: options.selectedStateStandardText,
			text: options.selectedStateStandardText,
		});
	}

	selectedContentAreaId: number;
	selectedStateStandard: KnockoutObservable<any>;
	model = null;

	treeNodeTemplate() {
    	const items =$($('#tree-node', this.rootElement).children()[0].outerHTML)[0];
    	return !!items && [items] || [];
	}

	initModel(model: StateStandardResponse) {
    	this.model = {
    		stateID: model.stateID,
    		stateName: model.stateName,
    		sections: [],
    	};

    	for (let i = 0; i < model.sections.length; i++) {

    		this.model.sections.push(model.sections[i]);
    		this.model.sections[i].selected = ko.observable(this.selectedContentAreaId === model.sections[i].contentAreaID);

    		this.model.sections[i].tree = this.getGrade(this.model.sections[i]);
    		if (this.model.sections[i].contentAreaID === this.selectedContentAreaId) {
    			this.prepareSelected(this.model.sections[i].tree);
    		}
    	}
	}

	prepareSelected(roots) {
    	for (let i = 0; i < roots().length; i++) {
    		const root = roots()[i];
    		if (root.id === this.selectedStateStandard().id) {
    			this.selectedStateStandard(root);
    			root.selected(true);
    			return true;
    		} else {
    			root.expanded(this.prepareSelectedChild(root));
    			if (root.expanded()) {
    				return true;
    			}
    		}
    	}

    	return false;
	}

	prepareSelectedChild(root) {
    	for (let i = 0; i < root.children().length; i++) {
    		const children = root.children()[i];
    		if (children.id === this.selectedStateStandard().id) {
    			this.selectedStateStandard(children);
    			children.selected(true);
    			return true;
    		} else {
    			children.expanded(this.prepareSelectedChild(children));
    			if (children.expanded()) {
    				return true;
    			}
    		}
    	}
    	return false;
	}

	getGrade(sections: ContentAreaSection) {
    	const treeData = ko.observableArray();
    	for (let i = 0; i < sections.stateStandards.length; ++i) {

    		const element = {
    			id: sections.stateStandards[i].gradeLevelId,
    			name: sections.stateStandards[i].name,
    			selected: false,
    			text: sections.stateStandards[i].name,
    			CanBeSelected: false,
    			children: [],
    		};

    		element.children = this.getTree(sections.stateStandards[i].standardsForGrade);
    		treeData.push(new TreeNode(element));
    	}
    	return treeData;
	}

	getTree(stateStandards: StateStandard[]) {
    	const treeData = [];

    	for (let i = 0; i < stateStandards.length; i++) {

    		const standard = stateStandards[i];
    		const element = {
    			id: standard.stateStandardID,
    			name: standard.displayText,
    			text: standard.name,
    			selected: false,
    			CanBeSelected: true,
    			children: [],
    		};

    		if (standard.childrenCount !== 0) {
    			element.children = this.getChildren(standard);
    		}

    		treeData.push(element);
    	}
    	return treeData;
	}

	getChildren(element: StateStandard) {
    	const childrens = [];
    	for (let i = 0; i < element.childrenStateStandards.length; i++) {
    		const children = element.childrenStateStandards[i];
    		const sub = {
    			id: children.stateStandardID,
    			name: children.displayText,
    			text: children.name,
    			selected: false,
    			CanBeSelected: true,
    			children: [],
    		};
    		if (children.childrenStateStandards.length !== 0) {
    			sub.children = this.getChildren(children);
    		}
    		childrens.push(sub);
    	}
    	return childrens;
	}

	load() {
	    const deferred = $.Deferred();
    	this.server.get.stateStandards().subscribe(model => {
    		this.initModel(model);
    		$(this).trigger('onLoaded');
		    deferred.resolve();
    	});
		return deferred.promise();
	}

	view =
		{
        	okClicked: () => {
        		$(this).trigger('onClosing');
        		$(this).trigger('onUpdate', [
        			{
        				contentAreaId: this.selectedContentAreaId,
        				stateStandardId: this.selectedStateStandard().id,
        				stateStandard: this.selectedStateStandard().name,
        			},
        		]);
        		$(this).trigger('onClosed');
        	},

        	tabClicked: (tab) => {
        		for (let i = 0; i < this.model.sections.length; i++) {
        			this.model.sections[i].selected(false);
        		}
        		tab.selected(true);
        	},

        	StandardClicked: (item) => {
        		if (item.selected() === true) {
        			item.selected(false);
        			this.selectedContentAreaId = null;
        			this.selectedStateStandard({id: null, name: null, text: null});
        			return;
        		}
        		if (this.selectedStateStandard() != null && this.selectedStateStandard().selected !== undefined) {
        			this.selectedStateStandard().selected(false);
        		}
        		for (let i = 0; i < this.model.sections.length; i++) {
        			if (this.model.sections[i].selected() === true) {
        				this.selectedContentAreaId = this.model.sections[i].contentAreaID;
        				break;
        			}

        		}
        		item.selected(true);
        		this.selectedStateStandard(item);

        	},

		};
	private server =
		{
        	get:
                {
                	stateStandards: () => {
                		return Api.getStateStandardLibrary(this.options.stateId);
                	},
                },
		};
	events =
		{
        	onLoaded: (callback) => {
        		if (callback) {
        			$(this).on('onLoaded', callback);
        		}
        	},
        	onClosing: (callback) => {
        		if (callback) {
        			$(this).on('onClosing', callback);
        		}
        	},
        	onClosed: (callback) => {
        		if (callback) {
        			$(this).on('onClosed', callback);
        		}
        	},
        	onUpdate: (callback) => {
        		if (callback) {
        			$(this).on('onUpdate', callback);
        		}
        	},
		};

	template = () => <div data-cy='state-standards-library' id='stateStandard' className='state-standard'>
        <div className='tabbable'>
            <ul className='nav nav-tabs'>
                <ko data-bind='foreach: model.sections'>
                    <li data-bind="css: {'active' : $data.selected }, click : $parent.view.tabClicked">
                        <a data-bind="attr :{ href: '#' + $data.contentAreaID },text: $data.contentName+' ('+$data.countStateStandards+')'"
                           data-toggle='tab'/>
                    </li>
                </ko>

            </ul>
            <div className='tab-content'>

                <ko data-bind='foreach: model.sections'>

                    <div className='tab-pane'
                         data-bind="css: {'in active' : $data.selected }, attr : {id : $data.contentAreaID}">
                        <ko data-bind='if: tree().length !=0'>
                            <ul className='standard-tree root-tree'
                                data-bind='template: {nodes: $root.treeNodeTemplate(), foreach: tree}'>

                            </ul>
                        </ko>
                        <ko data-bind='if: tree().length == 0'>
                            <div className='empty-standard-outer'>
                                <div className='empty-standard-inner'>
                                    State Standards are not available for current state (
                                    <ko data-bind='text: $parent.model.stateName'/>
                                    ) and content area (
                                    <ko data-bind='text: contentName'/>
                                    ).
                                </div>
                            </div>
                        </ko>
                    </div>
                </ko>
            </div>
        </div>
        <div className='current-state-standard-row'>
            <div className='state-standard-label'>Selected Standard:</div>
            <div className='state-standard-value' data-bind='text: selectedStateStandard().name'/>
        </div>

        <script type='text/html' id='tree-node'>
            <li className='tree-node' data-bind="css: {'tree-node-child': !hasChildren()}">

                <i className='ace-icon fa fa-lg tree-icon'
                   data-bind="css: {'fa-minus-square-o ': expanded, 'fa-plus-square-o ': collapsed }, visible: hasChildren , click: toggle"/>
                <i className='ace-icon fa fa-lg radio-icon'
                   data-bind="css: {'fa-dot-circle-o': selected, 'fa-circle-o': !selected()}, visible: isCanBeSelected, click: $root.view.StandardClicked"/>

                <div className='node-label-container'>
                    <a href='#' className='node-label'
                       data-bind="text: name, click: !$parent.isCanBeSelected ? null : $root.view.StandardClicked , css: {'selected': selected}"/>
                </div>

                <div data-bind='if: expanded' className='node-childs'>
                    <ul className='sub-tree'
                        data-bind='template: {nodes: $root.treeNodeTemplate(), foreach: children}'/>
                </div>
            </li>
        </script>

    </div>;
}

class TreeNode {

	constructor(values) {
		this.name = values.name;
		this.id = values.id;
		this.selected(values.selected);
		this.text = values.text;
		this.CanBeSelected = values.CanBeSelected;

		for (let i = 0; i < values.children.length; i++) {
			const child = new TreeNode(values.children[i]);
			if (!this.childIsSelected) {
				this.childIsSelected = child.selected() || child.expanded();
			}
			this.children.push(child);
		}

		this.expanded = ko.observable(this.childIsSelected);
		this.isCanBeSelected = ko.observable(this.CanBeSelected);
	}


	children = ko.observableArray<TreeNode>();
	name = ko.observable<string>();
	id = ko.observable<number>();
	selected = ko.observable<boolean>();
	text = '';

	private childIsSelected = false;
	private CanBeSelected = false;


	expanded: KnockoutObservable<boolean>;
	isCanBeSelected: KnockoutObservable<boolean>;

	collapsed = ko.computed({
    	read: () => {
    		return !this.expanded();
    	},
    	deferEvaluation: true,
	});

	hasChildren = ko.computed(() => {
    	return this.children().length !== 0;
	});

	toggle() {
    	this.expanded(!this.expanded());
	}
}
