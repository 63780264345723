import {useMemo} from 'react';
import {Collapse} from '@esgillc/ui-kit/layout';
import styles from './styles.module.less';
import {
	Table,
	TableBody,
	TableColumn,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	useSortableColumns,
} from '@esgillc/ui-kit/table';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {CreateAssignmentSpecialistService} from '../../services/create-assignment-specialist-service';
import {CredentialColumn, RemoveColumn} from './components/table-columns';
import {DropdownsSectionSpecialist} from './components/dropdowns-section-specialist';
import {ThirdStepContainer} from './components/third-step-container';
import {getUniqValuesInData} from 'pages/assignment-center/utils/get-uniq-values-in-data';

interface Props {
	service: CreateAssignmentSpecialistService;
	disabled: boolean;
	onStudentCredentialClicked?: VoidFunction;
}

export function ThirdStepSpecialist({service, disabled, onStudentCredentialClicked}: Props) {
	const selectedStudents = useBehaviorSubject(service.selectedStudents);

	const {data, sortableColumns} = useSortableColumns({
		data: selectedStudents,
		columns: {
			name: true,
			group: true,
			hasCredentials: true,
		},
		activeSortedKey: 'name',
	});

	const {isCredentialsTabSortableDisable, isGroupsTabSortableDisable} = useMemo(() => {
		const {group, hasCredentials} = getUniqValuesInData({data, keys: ['group', 'hasCredentials']});

		return {
			isGroupsTabSortableDisable: group.size <= 1,
			isCredentialsTabSortableDisable: hasCredentials.size <= 1,
		};
	}, [data]);

	if (!sortableColumns.name || !sortableColumns.group || !sortableColumns.hasCredentials) {
		return null;
	}

	return (
		<ThirdStepContainer instructionTitle='Add groups or specific students to your assignment.' disabled={disabled}>
			<DropdownsSectionSpecialist service={service} />
			<Collapse title='Selected Students' className={styles.collapse} contentClassName={styles.content}>
				<Table>
					<TableHeader className={styles.tableHeader}>
						<TableHeaderColumn.Sortable
							className={join(styles.tableColumn, styles.column30)}
							{...sortableColumns.name}
							disableSorting={data.length <= 1}
						>
							Students
						</TableHeaderColumn.Sortable>
						<TableHeaderColumn.Sortable
							className={join(styles.tableColumn, styles.column50)}
							{...sortableColumns.group}
							disableSorting={isGroupsTabSortableDisable}
						>
							Group
						</TableHeaderColumn.Sortable>
						<TableHeaderColumn.Sortable
							className={join(styles.tableColumn, styles.contentCenter, styles.column10)}
							{...sortableColumns.hasCredentials}
							disableSorting={isCredentialsTabSortableDisable}
						>
							Credentials
						</TableHeaderColumn.Sortable>
						<TableHeaderColumn className={join(styles.tableColumn, styles.contentCenter, styles.column10)}>
							Remove
						</TableHeaderColumn>
					</TableHeader>
					<OverlayScrollbarsComponent className={styles.tableBody}>
						<TableBody>
							{data.map((item) => (
								<TableRow key={item.id}>
									<TableColumn className={join(styles.tableColumn, styles.column30)}>{item.name}</TableColumn>
									<TableColumn className={join(styles.tableColumn, styles.column50)}>{item.group}</TableColumn>
									<TableColumn className={join(styles.tableColumn, styles.contentCenter, styles.column10)}>
										<CredentialColumn
											hasCredentials={item.hasCredentials}
											onStudentCredentialClicked={onStudentCredentialClicked}
										/>
									</TableColumn>
									<TableColumn
										className={join(styles.tableColumn, styles.contentCenter, styles.column10, styles.removeColumn)}
									>
										<RemoveColumn removeSelectedStudent={() => service.removeSelectedStudent(item.id)} />
									</TableColumn>
								</TableRow>
							))}
						</TableBody>
					</OverlayScrollbarsComponent>
				</Table>
			</Collapse>
		</ThirdStepContainer>
	);
}
