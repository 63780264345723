import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {FormElement} from '@esgi/ui/form';

export const SectionDescription = styled(Text, {
	padding: '8px',
});

export const SectionContent = styled(GridBox, {
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'start',
	borderRadius: '6px',
	width: '100%',
	padding: '12px',
	gap: '4px',

	'& > div': {
		marginBottom: 0,
	},
});

export const CheckboxFormElementWrapper = styled(FormElement, {
	width: '100%',
	padding: '4px',
});
