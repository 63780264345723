import {styled} from '@esgi/ui/theme';
import {Root, SliderProps} from '@radix-ui/react-slider';

export type SliderRootProps = SliderProps;

export const SliderRoot = styled(Root, {
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	userSelect: 'none',
	touchAction: 'none',

	'&::before, &::after': {
		content: '',
		display: 'block',
		width: 1,
		height: 4,
		borderRadius: 2,
		backgroundColor: '$primary72',
	},

	'&[data-orientation=horizontal]': {
		width: 144,
		height: 12,

		'&::before, &::after': {
			width: 1,
			height: 4,
		},
	},

	'&[data-orientation=vertical]': {
		width: 12,
		height: 144,
		flexDirection: 'column',

		'&::before, &::after': {
			width: 4,
			height: 1,
		},
	},

	'&[data-disabled]': {
		'&::before, &::after': {
			backgroundColor: '$muted',
		},
	},
});
