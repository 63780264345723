import {Button, ContextMenu} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';
import {DeleteGroupConfirmation} from './delete-group-confirmation';

type Props = {
	groupName: string;
	onDeleteGroup: VoidFunction;
};

export function GroupContextMenu({groupName, onDeleteGroup}: Props) {
	const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
	const [isOpenedDeleteGroupConfirmation, setIsOpenedDeleteGroupConfirmation] = useState(false);

	const closeDeleteConfirmation = useCallback(() => {
		setIsOpenedDeleteGroupConfirmation(false);
	}, []);

	const handleRemoveItemClick = useCallback(() => {
		setIsContextMenuOpened(false);

		setIsOpenedDeleteGroupConfirmation(true);
	}, []);

	return (
		<>
			<ContextMenu.Root open={isContextMenuOpened} onOpenChange={setIsContextMenuOpened}>
				<ContextMenu.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</ContextMenu.Trigger>
				<ContextMenu.Content>
					<ContextMenu.Group>
						<ContextMenu.Item onClick={handleRemoveItemClick}>
							<Delete />
							<Text size='medium'>Delete Group</Text>
						</ContextMenu.Item>
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>

			{isOpenedDeleteGroupConfirmation && (
				<DeleteGroupConfirmation
					onCloseAlert={closeDeleteConfirmation}
					groupName={groupName}
					onDeleteGroup={onDeleteGroup}
				/>
			)}
		</>
	);
}
