import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {RainbowRing} from '@esgi/ui';
import React from 'react';
import {Text} from '@esgi/ui/typography';

const Container = styled(FlexBox, {
	position: 'relative',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: '12px',
});

const EmojiBox = styled(Box, {
	position: 'absolute',
});

const Label = styled(Text, {
	textAlign: 'center',
});

const ProgressBarContainer = styled(FlexBox, {
	alignItems: 'center',
});

const ProgressBarLabel = styled(Text, {
	marginTop: '2px',
});

export function NoScorePlaceholder() {
	return <ProgressBarContainer>
		<Container>
			<RainbowRing/>
			<EmojiBox>😋</EmojiBox>
		</Container>

		<FlexBox direction='column' align='start'>
			<Label size='medium' font='mono' bold color='base'>Success</Label>
			<ProgressBarLabel size='xSmall' color='mediumContrast'>You Finished!</ProgressBarLabel>
		</FlexBox>
	</ProgressBarContainer>;
}