import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CheapBox = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: 20,
	height: 20,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	borderRadius: 4.5,
	backgroundColor: '$vivid',
});
