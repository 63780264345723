import {CSS, styled} from '@stitches/react';
import {Alert} from '../alert';
import {Button} from '../buttons';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		width: 520,
		overflow: 'hidden',
	},
};

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$3',
});

export const StyledButton = styled(Button, {
	color: '$negative',
});