import {Text} from '@esgi/ui/typography';
import {SelectField} from './index.styled';
import {Select as SelectUI} from '@esgi/ui/controls';

export type SelectOptionItem<T extends string = string> = {
	value: T;
	label: string;
};

type Props<T extends string = string> = {
	selectedValue: T | undefined;
	items: SelectOptionItem<T>[];
	onValueChange: (value: T) => void;
	placeholder: string;
	skeleton?: boolean;
	dataCy?: string;
};

export function Select<T extends string = string>({
	selectedValue,
	onValueChange,
	items,
	placeholder,
	skeleton,
	dataCy = 'select',
}: Props<T>) {
	return (
		<SelectUI.Root value={selectedValue} onValueChange={onValueChange} dataCy={dataCy}>
			<SelectField placeholder={placeholder} skeleton={Boolean(skeleton)} dataCy={`${dataCy}-field`} />
			<SelectUI.Content dataCy={`${dataCy}-content`}>
				{items.map(({label, value}) => (
					<SelectUI.Option value={value} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</SelectUI.Option>
				))}
			</SelectUI.Content>
		</SelectUI.Root>
	);
}
