import {createContext} from '@esgi/ui';
import {Class, Student} from '@esgi/main/libs/store';
import {AllOptionItem, GroupBucket} from './types';
import {Dispatch} from 'react';

export type GroupsContextValue = {
	selectedClassID: Class['id'] | AllOptionItem.Value;
	setSelectedClassID: Dispatch<Class['id'] | AllOptionItem.Value>;
	groupsWithStudents: GroupBucket[];
	studentsWithoutGroup: Student[];
};

export const {Context, useHook: useGroupsContext} = createContext<GroupsContextValue>('GroupsContext');
