import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AlertBodyGrid} from './alert-grid-body';

type Props = {
	onAlertClose: VoidFunction;
	studentsCount: number;
	className: string;
};

export function FileUploadIsComplete({onAlertClose, studentsCount, className}: Props) {

	return (
		<>
			<Alert.Header>
				<Text size='large'>File Upload is Complete</Text>
			</Alert.Header>
			<AlertBodyGrid>
				<Text size='medium'>
					{studentsCount} students have been added to {className}.
				</Text>
			</AlertBodyGrid>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
