import {styled} from '@esgi/ui/theme';
import {NavigationBar} from '@esgi/ui/layout';

export const CustomNavItem = styled(NavigationBar.Item, {
	'&:hover': {
		'& svg': {
			fill: '$tertiary',
			'& path': {
				fill: '$tertiary',
			},
		},
	},
});
