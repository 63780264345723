import {observable, Form} from '@esgi/deprecated/knockout';

export abstract class Step extends Form {

	@observable()
	title: string = '';

	@observable()
	icon: string = '';

	@observable()
	enabled: boolean = false;
}
