import {UserInfo} from './types';
import {Observable, Subject} from 'rxjs';

export const userStorageKey = 'currentUser';

interface Storage<T> {
	set(value: T): void;

	get(): T;

	clear(): void;

	update(partialValue: Partial<T>): void;

	onChanged$: Observable<T>
}

/**
 * The `createStorage` function creates a storage object that allows for setting, getting, clearing,
 * and updating values in local storage, and emits an event whenever the value changes.
 * @param {string} key - The `key` parameter is a string that represents the key under which the value
 * will be stored in the localStorage.
 * @returns The `createStorage` function returns an object with the following properties and methods:
 */
function createStorage<T>(key: string): Storage<T> {
	const storage = window.localStorage;
	const onChangedEmitter = new Subject<T | null>();
	return {
		set(value: T) {
			storage.setItem(key, JSON.stringify(value));
			onChangedEmitter.next({...value});
		},

		get(): T {
			const value = storage.getItem(key);
			try {
				return value ? JSON.parse(value) : {};
			} catch (error) {
				return {} as T;
			}
		},

		clear() {
			storage.removeItem(key);
			onChangedEmitter.next(null);
		},

		update(partialValue: Partial<T>) {
			const currentValue = this.get() || {} as T;
			const updatedValue = {...currentValue, ...partialValue};
			this.set(updatedValue);
		},
		onChanged$: onChangedEmitter.asObservable(),
	};
}

export const userStorage = createStorage<UserInfo>(userStorageKey);
