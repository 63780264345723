import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const SectionBox = styled(Box, {
	width: 24,
	height: 24,
	color: '$neutral56',

	'& svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const ActionButtonsDivider = styled(Box, {
	borderRadius: 1,
	width: 1.5,
	backgroundColor: '$background',
	marginLeft: 4,
	marginRight: 4,
});

export const ButtonIconable = styled(Button.Icon, {
	width: 24,
	height: 24,
	color: '$base',

	'& svg': {
		width: '100%',
		height: '100%',
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		active: {
			true: {
				color: '$primary',
			},
		},
	},
});
