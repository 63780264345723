import {CentralPanel} from '@esgi/main/kits/common';
import {styled} from '@esgi/ui/theme';

export const CentralPanelRoot = styled(CentralPanel.Root, {
	maxWidth: 'unset',
	height: '100%',
	overflow: 'hidden',
	paddingLeft: 20,
	paddingRight: 20,
	paddingTop: 20,
	paddingBottom: 20,
	gap: '$5',
});

export const CentralPanelBody = styled(CentralPanel.Body, {
	display: 'grid',
	gridTemplateRows: 'auto auto 1fr',
	gap: 20,
	overflow: 'hidden',
});
