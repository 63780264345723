import { Button } from '@esgi/ui';
import { Box } from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const PanelHeaderWrapper = styled(Box, {
    display: 'block'
});

export const PanelActionButton = styled(Button, {
    margin:'0 10px'
});