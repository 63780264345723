import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

type Props = {
	onAlertClose: VoidFunction;
};

export function IncorrectFileType({onAlertClose}: Props) {
	return (
		<>
			<Alert.Header>
				<Text size='large'>Incorrect File Type</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='medium'>Accepted formats include csv and xlsx. Please try again.</Text>
			</Alert.Body>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
