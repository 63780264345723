import React, {useState} from 'react';
import {CloseIcon, Modal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {join} from '@esgillc/ui-kit/utils';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {ResultsService} from '../../../services/results-service/results-service';
import {MultiLevelMenu} from '../multi-level-menu';
import {MultiLevelItem} from '../multi-level-menu/multilevel-item/menu-item';


interface Props {
	onClose: () => void
	onGoBack: () => void;
	service: ResultsService
	allStudentsSelected: boolean
}

export function ResultsHeader({onClose, onGoBack, service, allStudentsSelected}: Props) {
	const [showMenu, setShowMenu] = useState<boolean>(false);

	function closeMenu() {
		setShowMenu(false);
	}

	return <Modal.Header className={styles.blueHeader}>
		<div className={styles.plReportHeader}>
			<div className={styles.btnBack} onClick={onGoBack}>
				<i className={join('fa fa-angle-left', styles.fa, styles.faAngleLeft)}/>
				<span>Back</span>
			</div>
			<div className={styles.reportName}>Parent Letter Report</div>
			{!allStudentsSelected && <>
				<div className={styles.printDownloadButtons} onClick={() => setShowMenu(true)}>
					<span><i className={join(styles.fa, 'fa fa-download')}/> Download</span></div>
				<Menu show={showMenu}
				      keepFocusInside
				      onEscPressed={closeMenu}
				      onClickOutside={closeMenu}
				      className={styles.menu}>
					<MenuItem onSelect={() => service.pdf(false)}>
						PDF
					</MenuItem>
					<MenuItem onSelect={() => service.word(false)}>
						Word
					</MenuItem>
				</Menu>
			</>}
			{allStudentsSelected &&
				<>
					<MultiLevelMenu menuHolder={(<div className={styles.flex}><i
						className={join(styles.fa, 'fa fa-download')}/> Download</div>)}>
						<MultiLevelItem title='PDF'>
							<Menu show={true}
							      keepFocusInside
							      onEscPressed={closeMenu}
							      onClickOutside={closeMenu}
							      className={styles.relativeMenu}>
								<MenuItem onSelect={() => service.pdf(false)} className={styles.menuItem}>
									<div className={styles.bold}>One PDF file</div>
									<div className={styles.wrap}>per student or class</div>
								</MenuItem>
								<MenuItem onSelect={() => service.pdf(true)} className={styles.menuItem}>
									<div className={styles.bold}>ZIP</div>
									<div className={styles.wrap}>Individual PDF files for each student in the class
									</div>
								</MenuItem>
							</Menu>
						</MultiLevelItem>
						<MultiLevelItem title='Word'>
							<Menu show={true}
							      keepFocusInside
							      onEscPressed={closeMenu}
							      onClickOutside={closeMenu}
							      className={styles.relativeMenu}>
								<MenuItem onSelect={() => service.word(false)} className={styles.menuItem}>
									<div className={styles.bold}>One Word file</div>
									<div className={styles.wrap}>per student or class</div>
								</MenuItem>
								<MenuItem onSelect={() => service.word(true)} className={styles.menuItem}>
									<div className={styles.bold}>ZIP</div>
									<div className={styles.wrap}>Individual Word files for each student in the class
									</div>
								</MenuItem>
							</Menu>
						</MultiLevelItem>
					</MultiLevelMenu>
				</>
			}
			<CloseIcon color='white' onClick={onClose}/>
		</div>
	</Modal.Header>;
}
