import {TestsControl} from './components/tests-control';
import {PreviewDialog} from './components/preview-dialog';
import {IncludeControl} from './components/include-control';

export * from './services';
export * from './hooks';
export * from './types';

export const Flashcards = {
	TestsControl,
	PreviewDialog,
	IncludeControl,
};
