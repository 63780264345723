export const draggableButtons = [
	{
		name: 'Student First Name',
		value: 'val1',
	},
	{
		name: 'Student Last Name',
		value: 'val2',
	},
	{
		name: 'Teacher Title',
		value: 'val3',
	},
	{
		name: 'Teacher First Name',
		value: 'val4',
	},
	{
		name: 'Teacher Last Name',
		value: 'val5',
	},
	{
		name: 'Class',
		value: 'val6',
	},
	{
		name: 'Subject Tab',
		value: 'val7',
	},
];
