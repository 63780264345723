import mx from 'shared/modules/assets/mx-graph/mx-graph-factory';
import {FontSizeCalculationResult, Rectangle} from '../types';

export class Typo {
	public static A4: Rectangle = new Rectangle(
		Typo.convertToPixels('210mm'),
		Typo.convertToPixels('297mm'),
	);

	public static getMaxPossibleFontSize(str: string, maxWidth: number, fontSize: number, minFontSize: number, fontFamily: string): FontSizeCalculationResult {
		let actualRectangle = this.getStringArea(str, fontSize, fontFamily);
		if (actualRectangle.width > maxWidth) {
			do {
				fontSize = Math.floor(fontSize - 1);
				actualRectangle = this.getStringArea(str, fontSize, fontFamily);
			} while (fontSize > minFontSize && actualRectangle.width > maxWidth);
		}
		return {
			fontSize,
			width: actualRectangle.width,
			height: actualRectangle.height,
		} as FontSizeCalculationResult;
	}

	public static getStringArea(str: string, fontSize: number, fontFamily: string): Rectangle {
		return mx.mxUtils.getSizeForString(str, fontSize, fontFamily, null, '0');
	}

	public static convertToPixels(arg: string): number {
		if (arg.length < 3) {
			throw new Error('The value must contain the unit of measurement (mm, sm, pt)');
		}

		const value = +arg.substring(0, arg.length - 2);
		const unit = arg.substring(arg.length - 2);
		switch (unit.toLowerCase()) {
			case 'mm': return 3.7795275591 * value;
			case 'sm': return 3.7795275591 * value * 10;
			case 'pt': return value * 1.3333333333;
			default: return 0;
		}
	}
}
