import {useMemo, Fragment, Suspense} from 'react';
import {Loader, ServiceLoader} from '@esgillc/ui-kit/loader';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import {useBehaviorSubject, tryCall} from '@esgillc/ui-kit/utils';
import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {IEPProgressReportService} from './services';
import {Table} from './components/table';
import {Filter} from './components/filter';
import {Header} from './components/header';
import {Report} from './components/report';
import {IepGoalFormModal} from 'modules/forms/iep-goal-form';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {TestRequest, Step, Row, FilterSession} from './types';

import {useIEPReport} from './hooks';
import styles from './styles.module.less';
import {SsoTracker} from '@esgi/core/tracker';

interface Props {
	hierarchy: HierarchySnapshot;
	studentId?: number;
	subjectId?: number;
	selected?: number[];
	onRunReport?: () => void;
	onClose: () => void;
}

export const IepProgressReport = (props: Props): JSX.Element => {
	const modal = useModal();
	const service = useMemo(
		() => new IEPProgressReportService(props.hierarchy),
		[props.hierarchy],
	);
	const settings = useBehaviorSubject(service.settings$);
	const selected = useBehaviorSubject(service.selected$);
	const tests = useBehaviorSubject(service.rows$);
	const {
		state,
		setState,
		filter,
		setFilter,
		step,
		setStep, initialyLoaded,
	} = useIEPReport({service, ...props});
	const reportData = useMemo(() => {
		if (!filter || !settings) {
			return {};
		}
		const {
			session,
			period,
			from: fromDate,
			to: toDate,
			subject,
			showBaseline,
			student,
			group,
		} = filter;
		const latestSessionOnly = session === FilterSession.Date;
		const selectedMarkingPeriod = session === FilterSession.Period ? period : null;
		const specialist = {
			title: settings.specialistTitle,
			lastName: settings.specialistLastName,
		};

		return {
			latestSessionOnly,
			fromDate,
			toDate,
			selectedMarkingPeriod,
			specialist,
			subject,
			student,
			groupName: group,
			testIDs: selected,
			showBaseline,
			districtId: props.hierarchy.districtID,
			selectedTests: tests.filter(({id}) => selected.includes(id)),
		};
	}, [filter, settings, selected]);

	const onClose = useCloseModal(modal, props.onClose);
	const onReport = () => {
		tryCall(props.onRunReport, reportData);
		setStep(Step.Http);
		SsoTracker.trackEvent({
			trackingEvent: 'IEP Run Report',
		});
	};
	const onFilter = (value) => {
		const {student, subject, showBaseline, status} = value;
		const {id: studentId} = student;
		const {id: subjectId, subjectType} = subject;
		const newState = {} as TestRequest;
		if (state.studentId !== studentId) {
			newState.studentId = studentId;
		}
		if (state.subjectId !== subjectId) {
			newState.subjectId = subjectId;
		}
		if (state.subjectType !== subjectType) {
			newState.subjectType = subjectType;
		}
		if (Object.keys(newState).length) {
			setState({...state, ...newState});
			service.selectAll(false);
		}
		if (filter.showBaseline !== showBaseline) {
			service.setShowBaseline(showBaseline);
		}
		if (filter.status !== status) {
			service.setFilter({status});
		}
		setFilter({...filter, ...value});
	};
	const onView = (row: Row) => {
		setState({...state, testID: row.id});
		setStep(Step.Goal);
	};


	return initialyLoaded && (
		<Switch test={step}>
			<Case value={Step.Selector}>
				<Suspense fallback={<Fragment/>}>
					<ServiceLoader trackingService={service} fullscreen/>
					<Modal
						modalManagerRef={modal}
						className={styles.modal}
					>
						<Header
							service={service}
							reportData={reportData}
							onClose={onClose}
						/>
						<Modal.Body className={styles.body}>
							{settings &&
								<Filter
									data={filter}
									settings={settings}
									onChange={onFilter}
								/>
							}
							<Table
								service={service}
								onClick={onView}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Buttons.Contained
								disabled={selected.length === 0}
								onClick={onReport}
							>
								RUN REPORT
							</Buttons.Contained>
						</Modal.Footer>
					</Modal>
				</Suspense>
			</Case>
			<Case value={Step.Goal}>
				<Suspense fallback={<Fragment/>}>
					<IepGoalFormModal
						testID={state.testID}
						hierarchy={props.hierarchy}
						subject={{id: state.subjectId, type: state.subjectType}}
						onClose={() => setStep(Step.Selector)}
					/>
				</Suspense>
			</Case>
			<Case value={Step.Http}>
				<Suspense fallback={<Fragment/>}>
					<Report
						service={service}
						reportData={reportData}
						onBack={() => setStep(Step.Selector)}
						onClose={props.onClose}
					/>
				</Suspense>
			</Case>
		</Switch>
	);
};

export default IepProgressReport; // for lazy loading
