import {FormField, observableArray, Validators} from '@esgi/deprecated/knockout';
import {Dictionaries} from '../../server';
import {FieldContainer} from '../../common/component';

export class PositionSelectComponent extends FieldContainer<string> {
	@observableArray()
	private positions: Array<string>;

	buildField(initValue?): FormField<string> {
		this.positions = [];
		const f = this.createField<string>(undefined, Validators.Required());
		f.afterRender(this.rootElement).done(() => {
			this.loadPositions(initValue);
		});
		return f;
	}

	loadPositions(initValue?) {
		return Dictionaries.loadPositions().done((r) => {
			this.positions = r.positions;
			if (initValue) {
				const tz = r.positions.find((e) => e === initValue);
				this.field.value(tz);
			}
		});
	}

	public template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group state'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<select id='position' name='position' className='form-control'
					        data-bind="options: root.positions, valueAllowUnset: true, value: value"/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
				</div>
			</div>
		</div>;
	};
}
