import {BaseComponentProps} from 'libs/ui/src/types';
import {ComponentPropsWithoutRef, forwardRef, useEffect, useId} from 'react';
import {BodyWrapper} from './index.styled';
import {useAlertContext} from '../../context';
import {useAutoControlledState} from '@esgi/ui/utils';

type AlertBodyProps = BaseComponentProps & ComponentPropsWithoutRef<'div'>;

export const AlertBody = forwardRef<HTMLDivElement, AlertBodyProps>(
	({dataCy = 'ui-kit-alert-body', id: extendedId, ...props}, forwardedRef) => {
		const innerId = useId();

		const {setDescribedId} = useAlertContext();

		const [id] = useAutoControlledState({initialState: innerId, controlledState: extendedId});

		useEffect(() => setDescribedId(id), [id]);

		return <BodyWrapper dataCy={dataCy} id={id} {...props} ref={forwardedRef} />;
	},
);
