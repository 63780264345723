import {BaseService} from '@esgi/core/service';
import {getUser, StudentSort} from '@esgi/core/authentication';
import {Test} from '@esgi/main/kits/reports';
import {BehaviorSubject, combineLatest, debounceTime, Observable, of, switchMap, tap} from 'rxjs';
import {ChartBlockModel, Entity, FileType, PrintSettings, ReportData, UnitDataSettings} from '../types';
import {DownloadService} from './download-service';
import {StudentService} from './student-service';
import {SubjectService} from './subject-service';
import {TestService} from './test-service';
import {TestType} from '@esgi/core/enums';
import {SubjectTab} from '@esgi/main/libs/store';
import {V2TeachersPagesReportsPieChartsController} from '@esgi/contracts/esgi';
import {getPieChartOptions} from '../utils';
import {isNull} from 'underscore';

export class PieChartService extends BaseService {
	public readonly tab$ = new BehaviorSubject('');
	public readonly entity$ = new BehaviorSubject<Entity>({classId: null, groupId: null, studentId: null});
	public readonly subject$ = new BehaviorSubject(0);
	public readonly tests$ = new BehaviorSubject<Test['id'][]>([]);
	public readonly showEachStudent$ = new BehaviorSubject<boolean>(false);
	public readonly printInColor$ = new BehaviorSubject<boolean>(true);
	public readonly unitDataSettings$ = new BehaviorSubject<UnitDataSettings| null>(null);
	public readonly printSettings$ = new BehaviorSubject<PrintSettings | null>(null);
	public readonly chartBlockModels$ = new BehaviorSubject<ChartBlockModel[]>([]);
	public readonly reportData$ = new BehaviorSubject<ReportData | null>(null);
	public readonly selectAll$ = new BehaviorSubject(0);
	public readonly preview$ = new BehaviorSubject(false);
	public readonly valid$: Observable<boolean> = this.completeOnDestroy(
		combineLatest([this.entity$, this.tests$])
	).pipe(
		debounceTime(100),
		switchMap(([entity, tests]) => {
			const {classId, groupId, studentId} = entity;
			return of(Boolean(classId || groupId || studentId) && Boolean(tests.length));
		}),
	);
	public readonly busy$ = new BehaviorSubject(false);
	public studentService = new StudentService();
	public subjectService = new SubjectService();
	public testService = new TestService();
	protected downloadService = new DownloadService();
	private readonly controller = new V2TeachersPagesReportsPieChartsController();

	constructor() {
		super();

		this.completeOnDestroy(
			combineLatest([this.subject$, this.entity$])
		).pipe(
			debounceTime(100),
			tap(([subjectId, entity]) => {
				const {classId, groupId, studentId} = entity;
				if (!subjectId || !(classId || groupId || studentId)) {
					return;
				}
				let subjects: SubjectTab[] = [];
				if (subjectId === -1) {
					subjects = this.subjectService.getAll();
				} else {
					subjects = [this.subjectService.get(subjectId)];
				}
				this.testService.getTests(subjects, entity)
					.pipe(tap(() => {
						this.selectAll$.next(this.selectAll$.value + 1);
						if (this.preview$.value) {
							const s = this.unitDataSettings$
								.pipe(debounceTime(200))
								.subscribe(() => {
									s.unsubscribe();
									this.onPreview(() => {});
								});
						}
					}))
					.subscribe();
			}),
		).subscribe();

		this.completeOnDestroy(
			this.subjectService.loaded$
		).pipe(tap((value) => {
			if (value) {
				this.subject$.next(this.subjectService.getByIndex(0)?.id || 0);
			}
		})).subscribe();

		this.completeOnDestroy(
			combineLatest([this.entity$, this.tests$, this.subject$])
		).pipe(
			debounceTime(100),
			tap(([entity, tests, subjectId]) => {
				if (!subjectId) {
					return;
				}

				const studentIDs = this.getStudentIdsByEntity(entity);
				const selectedSubject = this.subjectService.get(subjectId);
				const selectedTests = tests.map((id) => this.testService.get(id));
				const selectedGroup = entity.groupId
					? this.studentService.getGroup(entity.groupId)
					: null;
				const selectedClass = entity.classId
					? this.studentService.getClass(entity.classId)
					: null;
				const selectedStudent = entity.studentId
					? this.studentService.get(entity.studentId)
					: null;

				const {name: className = ''} =this.studentService.getClass(entity.fromClassId || entity.classId) || {};
				const {name: groupName = ''} =this.studentService.getGroup(entity.fromGroupId || entity.groupId) || {};

				this.unitDataSettings$.next({
					selectedSubject,
					selectedTests,
					selectedGroup,
					selectedClass,
					selectedStudent,
					studentIDs,
				});
				this.printSettings$.next({
					...this.printSettings$.value,
					className,
					groupName,
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([
				this.showEachStudent$,
				this.printInColor$,
			]),
		).pipe(
			debounceTime(100),
			tap(([showEachStudent, printInColor]) => {
				const {firstName, lastName} = getUser();
				this.printSettings$.next({
					...this.printSettings$.value,
					showEachStudent,
					printInColor,
					gradeLevelID: 0,
					teacherName: `${firstName} ${lastName}`,
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.downloadService.busy, this.downloadService.busyController])
		).pipe(
			debounceTime(100),
			tap(([busy, busyController]) => this.busy$.next(busy || busyController)),
		).subscribe();
	}

	public destroy() {
		super.destroy();
		this.subjectService.destroy();
		this.testService.destroy();
		this.downloadService.destroy();
	}

	public override dispose() {
		this.controller.dispose();
	}

	public setEntity(value: Entity) {
		this.entity$.next(value);
	}

	public setSubject(value: number) {
		this.subject$.next(value);
	}

	public setTests(value: Test['id'][]) {
		this.tests$.next(value);
	}

	public setShowEachStudent(value: boolean) {
		this.showEachStudent$.next(value);
	}

	public setPrintInColor(value: boolean) {
		this.httpClient.ESGIApi.post(
			'reports/user-settings',
			'update',
			{name: 'printInColor', value}
		).subscribe(() => {
			this.printInColor$.next(value);
		});
	}

	public setPreview(value: boolean) {
		this.preview$.next(value);
	}

	public setTab(value: string) {
		this.tab$.next(value);
	}

	public onPreview(callback: VoidFunction) {
		const studentIDs = this.getStudentIdsByEntity(this.entity$.value);
		const testIDs = this.tests$.value;
		if (!testIDs.length) {
			let students: ReportData['students'] = [];
			if (this.unitDataSettings$.value?.selectedStudent) {
				const {id, firstName, lastName} = this.unitDataSettings$.value.selectedStudent;
				students = [{id, firstName, lastName, gradeLevelID: 0, testResults: null}];
			}

			this.reportData$.next({students, tests: []});
			return;
		}
		this.downloadService.preview({studentIDs, testIDs}).subscribe(
			({value}) => {
				value.students.sort((a, b) => {
					const currentUser = getUser();
					if (isNull(currentUser) || currentUser.studentSort === StudentSort.FirstName) {
						return a.firstName.localeCompare(b.firstName);
					}
					if (currentUser.studentSort === StudentSort.LastName) {
						return a.lastName.localeCompare(b.lastName);
					}
					return 0;
				});
				this.reportData$.next(value);
				this.chartBlockModels$.next(this.getChartBlockModels(value, 0, true));
				callback();
			},
		);
	}

	public onDownload(fileType: FileType) {
		const payload = this.downloadService.getExportOptions(
			this.unitDataSettings$.value,
			this.printSettings$.value
		);
		if (fileType === FileType.ZIP) {
			payload.zip = true;
		}
		this.downloadService.download(fileType, payload);
	}

	public getDataCombined({tests, students}: ReportData) {
		const data: ChartBlockModel['combinedTestResult'][] = [];
		for (const test of tests) {
			let studentsTested = 0;
			let correctAnswers = 0;

			for (const student of students) {
				const testResult = (student.testResults ?? []).find(({testID}) => testID === test.id);
				if (testResult) {
					studentsTested++;
					correctAnswers += testResult.correctAnswers;
				}
			}

			if (test.type.toString() !== TestType[TestType.YN]) {
				correctAnswers = Math.floor(correctAnswers / studentsTested);
			}

			data.push({
				testID: test.id,
				studentsTested,
				correctAnswers,
			});
		}
		return data;
	}

	public getChartBlockModels(data: ReportData, gradeLevelID, reportLoaded: boolean = false) {
		const filteredStudents = gradeLevelID > 0
			? data.students.filter(x => x.gradeLevelID === gradeLevelID)
			: data.students;

		const {userID: teacherID, testResultsCorrectVerbiage, testResultsIncorrectVerbiage} = getUser();
		const printInColor = this.printInColor$.value;

		if (!reportLoaded) {
			data.students = filteredStudents;
		}

		const chartBlockModels: ChartBlockModel[] = [];

		data.tests.forEach((test) => {
			data.students.forEach((student) => {
				const testResult = (student.testResults || []).find(({testID}) => testID === test.id) || null;
				const correctAnswers = testResult != null ? testResult.correctAnswers : null;
				const correctPercent = testResult != null ? Math.round((correctAnswers * 100) / test.maxScore) : null;

				chartBlockModels.push({
					testResult: testResult,
					correctPercent: correctPercent,
					studentID: student.id,
					testID: test.id,
					pieChartOptions: getPieChartOptions({
						testType: test.type,
						correctPercent,
						testResultsCorrectVerbiage,
						testResultsIncorrectVerbiage,
						printInColor,
					}),
				});
			});

			const combinedTestResult = this.getDataCombined(data).find(({testID}) => testID === test.id);
			const studentsNumber = teacherID > 0 ? data.students.length : combinedTestResult.studentsTested;
			const combinedMaxScore = test.type.toString() !== TestType[TestType.YN] ? test.maxScore : test.maxScore * studentsNumber;
			const correctPercent = combinedTestResult.studentsTested > 0 ? Math.round((combinedTestResult.correctAnswers * 100) / combinedMaxScore) : null;

			chartBlockModels.push({
				correctPercent: correctPercent,
				combinedTestResult: combinedTestResult,
				combinedMaxScore: combinedMaxScore,
				testID: test.id,
				studentID: 0,
				pieChartOptions: getPieChartOptions({
					testType: test.type,
					correctPercent,
					testResultsCorrectVerbiage,
					testResultsIncorrectVerbiage,
					printInColor,
				}),
			});
		});

		return chartBlockModels;
	}

	private getStudentIdsByEntity(entity: Entity) {
		const {classId, groupId, studentId} = entity;
		if (classId) {
			return this.studentService.getStudentIdsByClass(classId);
		}
		if (groupId) {
			return this.studentService.getStudentIdsByGroup(groupId);
		}
		if (studentId) {
			return [studentId];
		}
		return [];
	}
}
