import {join} from '@esgillc/ui-kit/utils';
import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from '../styles.module.less';
import {getUser, UserType} from '../../../../../../../../../libs/core/src/authentication';

interface Props {
	onCancelClicked: () => void;
	onViewStudentsClicked: () => void;
	uploadedStudentsCount: number;
	conflictStudentsCount: number;
	className: string;
	specialistGroupName?: string;
}

export function ConflictExists({
	                               onCancelClicked,
	                               onViewStudentsClicked,
	                               uploadedStudentsCount,
	                               conflictStudentsCount,
	                               specialistGroupName,
	                               className,
}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancelClicked);
	const isPA = getUser().userType === UserType.PA;
	const title = `You are uploading ${uploadedStudentsCount} students to ${isPA ? specialistGroupName : className}`;

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>{title}</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
				<span data-cy={'conflict-exists'} className={join(styles.text, styles.redRow)}>
					{conflictStudentsCount} Students Need Attention.
				</span>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Contained onClick={onViewStudentsClicked}>VIEW STUDENTS</Buttons.Contained>
			<Buttons.Contained onClick={handleClose}>CANCEL</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
