import React, {ReactNode} from 'react';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {LevelDisplayMode} from '../../../../../models';
import RubricService from '../../../../../rubric-service';
import ResetLevelsModal from './components/reset-levels-modal/reset-levels-modal';
import styles from './level.actions.module.less';

class State {
	tempDisplayMode: LevelDisplayMode;
	askToResetLevels: boolean = false;
}

interface Props {
	levelDisplayMode: LevelDisplayMode;
	rubricService: RubricService;
	canChangeLevelMode: boolean;
	onUpdate: (levelMode: LevelDisplayMode) => void;
}

export default class LevelModeSelector extends React.PureComponent<Props, State> {
	public readonly state = new State();

	private readonly displayModes = [
		{id: 1, value: 'Numerical'},
		{id: 2, value: 'Text'},
	];

	public render() {
		return <>
			{this.renderLevelModeSelector()}
			{this.renderResetLevelsDialog()}
		</>;
	}

	private renderLevelModeSelector(): ReactNode {
		const selectedDisplayMode = this.displayModes.find(m => m.id === this.props.levelDisplayMode);
		let mode;
		if (!this.props.canChangeLevelMode) {
			mode = <span className={styles.mode}>{selectedDisplayMode.value}</span>;
		} else {
			mode = <Dropdown value={[selectedDisplayMode]}
			                     optionName='value'
			                     className={styles.displayModeSelector}
			                     setValue={(v) => this.updateLevelMode(v[0].id)}>
				{this.displayModes.map(d => <Option key={d.id} value={d}>{d.value}</Option>)}
			</Dropdown>;
		}
		return mode;
	}

	private updateLevelMode(mode: LevelDisplayMode): void {
		if (this.props.rubricService.levels$.value.some(l => !l.metaInfo?.defaultField)) {
			this.setState({askToResetLevels: true, tempDisplayMode: mode});
		} else {
			this.props.onUpdate(mode);
		}
	}

	private renderResetLevelsDialog() {
		if (this.state.askToResetLevels) {
			return <ResetLevelsModal
				onConfirm={() => this.setState({askToResetLevels: false}, () => this.props.onUpdate(this.state.tempDisplayMode))}
				onCancel={() => this.setState({askToResetLevels: false})}/>;
		}
	}
}
