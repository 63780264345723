import {styled} from '@esgi/ui/theme';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {CSS} from '@stitches/react';
import {Skeletonable} from '../../types';

export type BoxProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
} & Skeletonable;

export const Box = forwardRef<HTMLDivElement, BoxProps>(({dataCy, skeleton, ...props}, forwaredRef) => (
	<StyledBox data-cy={dataCy ?? 'ui-kit-box'} ref={forwaredRef} {...props} />
));

const StyledBox = styled('div', {
	boxSizing: 'border-box',
});
