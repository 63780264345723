import {HelpLink, Modal, Title} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {AllStudentsService} from '../../services';
import {Download} from './components/download';
import styles from '../../styles.module.less';

interface Props {
	service: AllStudentsService;
}

export const Header = ({service}: Props) => {
	const reportData = useBehaviorSubject(service.reportData$);

	return (
		<Modal.Header className={styles.header}>
			<Title className={styles.title}>
				Test Session Details
				<HelpLink
					url='https://support.esgisoftware.com/hc/en-us/articles/360024519772-Details-and-History-Buttons'
					className={styles.hint}
				/>
			</Title>
			<Download
				students={reportData?.studentsTested}
				onDonwload={(format, type) => service.startReportBackgroundGeneration(format, type)}
			/>
		</Modal.Header>
	);
};
