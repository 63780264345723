import {UserType} from '@esgi/core/authentication';
import {SpecialistHierarchySnapshot} from '../models';
import {StudentModel} from '../api';

export function withGroupID(h: SpecialistHierarchySnapshot, groupID: number) {
	const n = {...h};
	n.groupID = groupID;
	n.filter = h.filter;
	n.studentID = 0;

	return n;
}

export function withGroupOfSpecialistsID(h: SpecialistHierarchySnapshot, groupOfSpecialistsID: number) {
  const n = {...h};
  n.groupOfSpecialistsID = groupOfSpecialistsID;
  n.type = 0;
  n.groupID = 0;
  n.filter = {
    teacherID: 0,
    schoolID: 0,
  };
  n.studentID = 0;

  return n;
}

export function withUserType(h: SpecialistHierarchySnapshot, type: UserType) {
	const n = {...h};
	n.type = type;
	n.groupID = 0;
	n.userID = 0;
	n.studentID = 0;
	n.filter = {
		schoolID: 0,
		teacherID: 0,
	};

	return n;
}

export function withUserID(h: SpecialistHierarchySnapshot, userID: number) {
	const n = {...h};
	n.userID = userID;
	n.filter = {
		teacherID: 0,
		schoolID: 0,
	};
	n.studentID = 0;
	n.groupID = 0;

	return n;
}

export function withStudent(h: SpecialistHierarchySnapshot, student: StudentModel) {
	const n = {...h};
	if (!student) {
		n.studentID = 0;
		return n;
	}

	n.studentID = student.studentID;
	if (n.filter.schoolID !== 0 && n.filter.schoolID !== student.schoolID) {
		n.filter.schoolID = student.schoolID;
	}
	if (n.filter.teacherID !== 0 && n.filter.teacherID !== student.teacherID) {
		n.filter.teacherID = student.teacherID;
	}

	if (!student.specialistGroups.some(t => t === n.groupID)) {
		n.groupID = 0;
	}

	return n;
}

export function withSchoolID(h: SpecialistHierarchySnapshot, schoolID: number) {
	const n = {...h};
	n.filter = {
		schoolID: schoolID,
		teacherID: 0,
	};

	return n;
}

export function withTeacherID(h: SpecialistHierarchySnapshot, teacherID: number) {
	const n = {...h};
	n.filter = {
		schoolID: n.filter.schoolID,
		teacherID: teacherID,
	};

	return n;
}
