import React from 'react';
import {TemplateProps} from '../template';
import styles from './template5.module.less';
import {getUrl, isMultiple} from '../../utils';
import {BaseOption} from '../../option/option';
import {QuestionCard} from '../../models';

class Template5 extends React.Component<TemplateProps> {
	private selected(id: number): boolean {
		return this.props.selectedOptionID.includes(id);
	}

	render() {
		const q = this.props.question;
		return (
			<div className={styles.template}>
				<div className={styles.cardsContainer}>
					{q.cards.map((c, i) => this.renderCard(c, i))}
				</div>
				<div className={styles.optionsContainer}>
					{q.options.map(item =>
						<BaseOption
							bucket={this.props.awsBucket}
							type={'Vertical'}
							id={item.id}
							disabled={this.props.maxOptionsSelected && !this.props.selectedOptionID.includes(item.optionID)}
							multiselect={isMultiple(q)}
							key={item.optionID}
							text={item.text}
							clicked={() => this.props.onSelectOption(item.optionID)}
							selected={this.selected(item.optionID)}
							audioPath={item.audioUrl}
							picUrl={item.picUrl}
							className={styles.optionBox}
							disableAudio={this.props.audioPlaying}
							customize={{
								optionClassName: styles.customizeOption,
								actionClassName: styles.customizeAction,
							}}
						/>)}
				</div>
			</div>
		);
	}

	private renderCard(q: QuestionCard, i: number) {
		return <div key={i} className={styles.card}>
			{this.renderPic(q.picUrl)}
			{this.renderText(q.text)}
		</div>;
	}

	private renderText(text: string) {
		if (!text) {
			return null;
		}

		return <span className={styles.cardText} dangerouslySetInnerHTML={{__html: text}}/>;
	}

	private renderPic(picUrl: string) {
		if (!picUrl) {
			return null;
		}
		const url = getUrl(this.props.awsBucket, picUrl);
		return <div className={styles.cardImg}><img src={url}/></div>;
	}
}

export default Template5;
