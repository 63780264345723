import React from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {ReportSettingsService} from '../../../../../../services/report-settings-service';

interface Props {
	service: ReportSettingsService;
}

export function DetailReportSettingsSpecialistGroups({service}: Props) {
	const specialistGroups = useBehaviorSubject(service.specialistGroups);
	const selectedSpecialistGroup = useBehaviorSubject(service.selectedSpecialistGroup);
	return specialistGroups && specialistGroups.length > 0 && selectedSpecialistGroup && <>
		<div>
			<strong>Group: </strong>
			{specialistGroups.length > 1 && <>
				<Dropdown autoWidth={true}
				          setValue={(v) => service.changeHierarchy('specialistGroupChanged', v)}
				          optionName={'itemName'}
				          value={selectedSpecialistGroup}>
					{specialistGroups.map(group => <Option key={group.itemID}
					                                       value={group.itemID}>{group.itemName}</Option>)}
				</Dropdown>
			</>}
			{specialistGroups.length === 1 && <>
				<span>{specialistGroups[0].itemName}</span>
			</>}
		</div>
	</>;

}
