import {styled} from '@esgi/ui/theme';
import {Checkbox} from '@esgi/ui/controls';
import {Box} from '@esgi/ui/layout';

export const StyledCheckbox = styled(Checkbox, {
	padding: '0',
	background: '$border',
	width: '16px',
	height: '16px',
	overflow: 'hidden',
	variants: {
		selectedStyles: {
			true: {
				background: '$primary92',
				'& > div': {
					borderColor: '$primary92',
				},
			},
		},
	},
	'& > div': {
		borderColor: '$border',
	},
});

export const CheckboxContainer = styled(Box, {
	padding: '0px 10px 0 0',
});