import {RefObject, useEffect, useState} from 'react';

export const useProgressRelatedContent = (containerRef: RefObject<HTMLElement>, itemRef: RefObject<HTMLElement | SVGElement>, progress: number) => {
	const [needToTransform, setNeedToTransform] = useState<boolean>();
	const [itemWidth, setItemWidth] = useState<number>(0);
	const [containerFillWidth, setContainerFillWidth] = useState(0);

	useEffect(() => {
		if(containerRef.current && itemRef.current){
			const containerWidth = containerRef.current.getBoundingClientRect().width;
			const containerFillWidth = containerWidth * progress;
			const currentItemWidth = itemRef.current.getBoundingClientRect().width;
			setNeedToTransform(containerFillWidth >= currentItemWidth);
			setContainerFillWidth(containerFillWidth);
			setItemWidth(currentItemWidth);
		}
	}, [progress]);

	return {needToTransform, containerFillWidth, itemWidth};
};
