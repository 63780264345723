import {IHtmlGenerator, Row, Table, TableParser} from '../print-module/print-module';

export class Options {
    thead: HTMLElement;
    tbody: HTMLElement;
    width: number;
}
export class GrowthReportPrintService implements IHtmlGenerator {

    options: Options;

    pageBreak = '<div style="page-break-after:always"></div>';

    getStyle() {
    	let style = 'td, th { border: 1px solid black; text-align:center;} ';
    	style += 'table { page-break-inside:auto; border-collapse:collapse; border-spacing:0px; width:100%; } tr { page-break-inside:avoid; page-break-after:auto }';
    	style += 'tbody td {overflow: hidden;white-space: nowrap;}';
    	return style;

    }

    constructor(options: Options) {
    	this.options = options;
    }

    getBody() {
    	let parser = new TableParser();
    	let table = parser.parse(this.options.thead, this.options.tbody);
    	let columnForFirstPage = this.getCountColumnForFirstPage(table.headers[0]);

    	let body = this.generateFirstPage(columnForFirstPage, table);

    	let currentColumn = columnForFirstPage;
    	while (currentColumn < table.headers[0].cells.length) {
    		body += this.pageBreak;
    		let columnForOtherPage = this.getCountColumnForOtherPage(currentColumn, table.headers);
    		body += this.generateOtherPage(currentColumn, columnForOtherPage, table);
    		currentColumn += columnForOtherPage;
    	}
    	return body;
    }

    private getCountColumnForFirstPage(headerRow: Row): number {
    	let currentWidth = headerRow.cells[0].width;
    	let count = 1;
    	for (let i = 1; i < headerRow.cells.length; i++) {
    		if (currentWidth < this.options.width) {
    			currentWidth += headerRow.cells[i].width;
    			count++;
    		} else {
    			break;
    		}
    	}
    	return count;
    }

    private getCountColumnForOtherPage(skipColumn: number, headers: Row[]): number {

    	let width = headers[2].cells[1].width;
    	let countColumn = 0;
    	for (let i = skipColumn; i < headers[0].cells.length; i++) {
    		if (width < this.options.width || countColumn == 0) {
    			width += headers[0].cells[i].width;
    			countColumn++;
    		} else {
    			break;
    		}
    	}

    	return countColumn;
    }

    private generateFirstPage(columnForFirstPage: number, table: Table): string {
    	let html = '<table>';
    	html += '<thead>';

    	let countSubHeaderColumn = 0;
    	let mainCells = table.headers[0].cells;
    	html += '<tr>';
    	let i: number;
    	for (i = 0; i < columnForFirstPage; i++) {
    		html += '<th colspan="' + mainCells[i].colspan + '" rowspan="' + mainCells[i].rowspan + '">' + mainCells[i].value + '</th>';
    		countSubHeaderColumn += mainCells[i].colspan;
    	}
    	html += '</tr>';

    	html += '<tr>';
    	let questionCells = table.headers[1].cells;
    	for (i = 0; i < columnForFirstPage - 1; i++) {
    		html += '<th colspan="' + questionCells[i].colspan + '" rowspan="' + questionCells[i].rowspan + '">' + questionCells[i].value + '</th>';
    	}
    	html += '</tr>';


    	html += '<tr>';
    	let subCells = table.headers[2].cells;
    	for (i = 0; i < countSubHeaderColumn; i++) {
    		html += '<th colspan="' + subCells[i].colspan + '" rowspan="' + subCells[i].rowspan + '">' + subCells[i].value + '</th>';
    	}
    	html += '</tr>';

    	html += '</thead>';

    	html += '<tbody>';

    	for (i = 0; i < table.rows.length; i++) {
    		html += '<tr>';

    		let cells = table.rows[i].cells;
    		for (let j = 0; j < countSubHeaderColumn; j ++) {
    			html += '<td>' + cells[j].value + '</td>';
    		}

    		html += '</tr>';
    	}

    	html += '</tbody>';

    	html += '</table>';

    	return html;
    }

    private generateOtherPage(offsetColumn: number, columnForPage: number, table: Table): string {
    	let html = '<table>';
    	html += '<thead>';

    	let countSubHeaderColumn = 0;
    	let mainCells = table.headers[0].cells;
    	html += '<tr>';
    	html += '<th rowspan="3">Teacher</th>';
    	let i: number;
    	for (i = offsetColumn; i < offsetColumn+columnForPage; i++) {
    		html += '<th colspan="' + mainCells[i].colspan + '" rowspan="' + mainCells[i].rowspan + '">' + mainCells[i].value + '</th>';
    		countSubHeaderColumn += mainCells[i].colspan;
    	}
    	html += '</tr>';

    	html += '<tr>';
    	let questionCells = table.headers[1].cells;
    	for (i = offsetColumn-1; i < offsetColumn+columnForPage-1; i++) {
    		html += '<th colspan="' + questionCells[i].colspan + '" rowspan="' + questionCells[i].rowspan + '">' + questionCells[i].value + '</th>';
    	}
    	html += '</tr>';


    	let startSubHeader = 0;
    	for (i = 0; i < offsetColumn-1; i++) {
    		startSubHeader += table.headers[0].cells[i].colspan;
    	}

    	html += '<tr>';
    	let subCells = table.headers[2].cells;
    	for (i = startSubHeader; i < startSubHeader+countSubHeaderColumn; i++) {
    		html += '<th colspan="' + subCells[i].colspan + '" rowspan="' + subCells[i].rowspan + '">' + subCells[i].value + '</th>';
    	}
    	html += '</tr>';

    	html += '</thead>';

    	html += '<tbody>';

    	for (i = 0; i < table.rows.length; i++) {
    		html += '<tr>';

    		let cells = table.rows[i].cells;
    		html += '<td>' + cells[1].value + '</td>';
    		for (let j = startSubHeader; j < startSubHeader+countSubHeaderColumn; j++) {
    			html += '<td>' + cells[j].value + '</td>';
    		}

    		html += '</tr>';
    	}

    	html += '</tbody>';

    	html += '</table>';

    	return html;
    }

}
