import {UserType} from '@esgi/core/authentication';
import {ObservableBuilder} from '@esgi/api';
import {Observable} from 'rxjs';
import {FormGroup} from '@esgillc/ui-kit/form';

export type UsersResponse = { users: UserRawResponse[] };

export type UserRawResponse = {
	userID: number;
	schoolID: number;
	userType: string;
	firstName: string;
	lastName: string;
}

export type Teacher = {
	userID: number;
	schoolID: number;
	userType: string;
	firstName: string;
	lastName: string;
	fullName: string
}

export type ProfileInitResponse = {
	value: ProfileInitData
}

export type ProfileInitData = {
	studentID?: number;
	general?: GeneralResponse;
	location?: LocationResponse;
	dictionary: Dictionary,
	additional?: AdditionalResponse;
	photo?: ImageResponse;
	limit?: StudentLimit;
	isOpenedWithAdd?: boolean;
}

export interface StudentLimit {
	max: number,
	studentsCount: number,
	limitExceed: boolean,
	allowUseCC: boolean,
	isLimit: boolean
}

export interface Dictionary {
	genders: DictionaryItem[];
	gradeLevels: DictionaryItem<number>[];
	languages: DictionaryItem<number>[];
	lunchStatuses: DictionaryItem[];
	races: DictionaryItem[];
	specialPrograms: DictionaryItem[];
}

export interface SpecialistGroups {
	id: string,
	name: string,
	specialistID: string,
	schoolID: string
}

export interface DictionaryItem<IDType = string> {
	id: IDType,
	name: string,
}

export interface GroupsItem {
	id: number,
	name: string,
	classID: number
}

export interface SpecialistGroupItem {
	id: number,
	name: string,
	userID: number
}

export interface ClassItem {
	id: number,
	name: string,
	teacherID: number
}

export interface ClassesResponseItem {
	classID: number;
	name: string;
	groups: GroupsResponseItem[]
}

export interface GroupsResponseItem {
	groupID: number,
	name: string,
	classID: number
}

export interface ClassesResponse {
	classes: ClassesResponseItem[]
}

export interface PreSelected {
	schoolID: number;
	userType: UserType;
	userID: number;
	classID: number;
	groupID: number;
	specialistGroupID: number;
}


export interface Schools {
	schoolID: number;
	name: string;
}

export interface ImageData {
	image: File,
	croppedImage: File,
	cropParameters: CropParams
}

export interface CropParams {
	x: number,
	y: number,
	zoom: number
}

export class GeneralResponse {
	constructor(
		public firstName: string,
		public lastName: string,
		public gender: string,
		public studentIDN: string,
		public exportIDN: string,
		public gradeLevelID: number,
		public languageID: number,
		public credentialModel?: CredentialModel,
	) {
	}
}

export class AdditionalResponse {
	constructor(
		public birthDate: string,
		public race: string,
		public lunchStatus: string,
		public studentSpecialPrograms: string[],
		public comments: string,
		public studentID?: number,
	) {
	}
}

export class LocationResponse {
	constructor(
		public classIDs: number[],
		public groupIDs: number[],
		public specialistGroupIDs: number[],
		public teacherID: number,
		public schoolID: number,
		public specialistID?: number,
	) {
	}
}

export class CredentialModel {
	constructor(
		public userName?: string,
		public password?: string,
	) {
	}
}

export enum StudentProfileMode {
	view,
	add,
	edit,
}

export enum StudentProfileTab {
	General,
	Location,
	Additional,
}

export interface ImageResponse {
	imageUrl: string,
	imageCropUrl: string,
	crop: {
		x: number,
		y: number,
		zoom: number
	}
}

export interface ImageCropperParams {
	width: number;
	height: number;
	x: number;
	y: number;
}

export type TabsApi = Record<StudentProfileTab, {
	isTouched: boolean,
	save: (...props) => (Observable<any> | ObservableBuilder<any>),
	form: FormGroup<any, any>,
	isSaved?: boolean,
	haveClass?: boolean
}>

export interface SpecialistGroup {
	groupID: number,
	name: string,
	userID: number,
	type: UserType
}

export interface SaveStudentResponse {
	value: { studentID: number },
	errors?: {type: string, description: string}[],
	isSuccess?: boolean
}
