import {getThemeColor} from '../../../utils';
import {ThemeColorKeys} from '../../../types';
import {SVGProps, useCallback, useEffect, useMemo} from 'react';
import {animated} from 'react-spring';
import {useArcAnimation} from './utils/use-arc-animation';
import {useOpacityAnimation} from './utils/use-opacity-animation';
import {useInformationScaleContext} from '../../context';

type InformationScaleProgressProps = {
	/**
	 * The minimum value of the information progress scale.
	 * Percentage progress values are based on the provided minValue and maxValue.
	 */
	minValue: number;

	/**
	 * The maximum value of the information progress scale.
	 * Percentage progress values are based on the provided minValue and maxValue.
	 */
	maxValue: number;

	/**
	 * The current value of the information progress scale.
	 * It is a midpoint fo the pointer.
	 */
	value: number;

	/**
	 * Set stroke color for the progress arc.
	 * @default 'primaryVivid'
	 */
	strokeColor?: ThemeColorKeys;

	/**
	 * Set fill color for pointer.
	 * @default 'vivid'
	 */
	pointerFillColor?: ThemeColorKeys;
};

export function Progress({
	minValue,
	maxValue,
	value,
	strokeColor = 'primaryVivid',
	pointerFillColor = 'vivid',
}: InformationScaleProgressProps) {
	const {
		scaleRadius,
		setScaleRadius,
		setStartAngleForRenderDots,
		getCoordinatesByAngle,
		dotElipseWideRadius,
		svgSize,
		dotCircleRadius,
	} = useInformationScaleContext();

	const progressData = useMemo(() => {
		const progressStrokeWidth = dotElipseWideRadius / 2;
		const pointerProgressRadius = progressStrokeWidth * 3;

		const progressValue = (value - minValue) / (maxValue - minValue);

		return {progressValue, progressStrokeWidth, pointerProgressRadius};
	}, [dotElipseWideRadius, maxValue, minValue, value]);

	useEffect(() => {
		const scaleRadius = (svgSize - progressData.pointerProgressRadius * 2) / 2 - progressData.progressStrokeWidth;
		const startAngleProgress = progressData.progressValue * 360;

		setScaleRadius(scaleRadius);
		setStartAngleForRenderDots(startAngleProgress);
	}, [progressData.pointerProgressRadius, progressData.progressStrokeWidth, progressData.progressValue, svgSize]);

	const getArcValueD = useCallback(
		({startValueProgress, endValueProgress}: {startValueProgress: number; endValueProgress: number}) => {
			const startArcAngle = startValueProgress * 360;
			const endArcAngle = endValueProgress * 360;

			const arcAngle = endArcAngle - startArcAngle;

			const isCircle = arcAngle === 360;

			const {x: x1, y: y1} = getCoordinatesByAngle(startArcAngle);
			const {x: x2, y: y2} = getCoordinatesByAngle(isCircle ? endArcAngle - 1 : endArcAngle);

			const largeArcFlag = arcAngle <= 180 ? 0 : 1;

			const startPosition = `M ${x1} ${y1}`;
			const arc = `A ${scaleRadius} ${scaleRadius}, 0, ${largeArcFlag}, 1, ${x2} ${y2}`;

			return `${startPosition} ${arc}`;
		},
		[getCoordinatesByAngle, scaleRadius],
	);

	const getPointerCenter = useCallback(
		(value: number) => {
			const angle = value * 360;

			const {x, y} = getCoordinatesByAngle(angle);

			const center: Pick<SVGProps<SVGCircleElement>, 'cx' | 'cy'> = {
				cx: x,
				cy: y,
			};

			return center;
		},
		[getCoordinatesByAngle],
	);

	const elipseValue = useMemo(() => {
		const {x, y} = getCoordinatesByAngle(0);

		return {
			x,
			y,
			rx: dotCircleRadius,
			ry: dotElipseWideRadius,
		};
	}, [dotCircleRadius, dotElipseWideRadius, getCoordinatesByAngle]);

	const activeAnimationArcProps = useArcAnimation({
		progressFrom: 0,
		progressTo: progressData.progressValue,
	});

	const pointerAnimationProps = useOpacityAnimation();

	return (
		<>
			<animated.path
				d={activeAnimationArcProps.progress.to((progress) =>
					getArcValueD({
						startValueProgress: 0,
						endValueProgress: progress,
					}),
				)}
				fill='none'
				stroke={getThemeColor(strokeColor)}
				strokeWidth={progressData.progressStrokeWidth}
			/>

			<ellipse
				cx={elipseValue.x}
				cy={elipseValue.y}
				rx={elipseValue.rx}
				ry={elipseValue.ry}
				fill={getThemeColor(strokeColor)}
			/>

			<animated.circle
				style={pointerAnimationProps}
				r={progressData.pointerProgressRadius}
				strokeWidth={progressData.progressStrokeWidth}
				fill={getThemeColor(pointerFillColor)}
				stroke={getThemeColor(strokeColor)}
				{...getPointerCenter(progressData.progressValue)}
			/>
		</>
	);
}
