import {useService} from '@esgi/core/service';
import {ElementStatus, Form, FormElement} from '@esgillc/ui-kit/form';
import {Hint, Input, Label, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import {useEffect, useState} from 'react';
import {Subject, switchMap, takeUntil, takeWhile, timer} from 'rxjs';
import {CleverRegistrationService} from './service';
import {BaseResponse, Error} from '@esgi/api';
import {ErrorTypes, RegistrationResult} from './models';
import {Loader} from '@esgillc/ui-kit/loader';
import styles from './styles.module.less';

export default function CleverRegistrationPage() {

	const requestTime = 5*60*1000;
    const service = useService(CleverRegistrationService);
	const requestStopTimer$ = new Subject<boolean>();
	const [requestTimeoutID, setRequestTimeoutID] = useState<NodeJS.Timeout>(null);
	const [loaded, setLoaded] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [cleverUrl, setCleverUrl] = useState<string>(null);
	const [error, setError] = useState<Error>(null);

	useEffect(() => {
		service.init();
		startPolling(1000, 2000).subscribe();
		const timeoutID = startRequestTimer(requestTime);
		setRequestTimeoutID(timeoutID);
	}, []);

	useEffect(() => {
		if (loaded) {
			service.form.validate().subscribe(({valid}) => setFormValid(valid));
			clearTimeout(requestTimeoutID);
		}
	}, [loaded, requestTimeoutID]);

	const startRequestTimer = (timeout: number) => {
		return setTimeout(() => {
			processRequestTimeout();
		  }, timeout)
	}

	const startPolling = (startDue: number, interval: number) => {		
		return timer(startDue, interval)
			.pipe(
				switchMap(() => service.checkStatus()),
				takeWhile(res => {
					setCleverUrl(res.value?.cleverUrl);
					let needStop = false;

					if (res.errors !== null && res.errors.length) {
						needStop = true;
					}

					if (res.value && res.value.userID > 0) {
						needStop = true;						
					}

					if (needStop) {
						processResult(res);
						setLoaded(true);
						return false;
					}

					return true;
				}),
				takeUntil(requestStopTimer$)
			);
	}

	const processResult = (res: BaseResponse<RegistrationResult>) => {
		if (res.isSuccess) {
			const user = res.value;
			if (user?.userID > 0) {
				service.form.controls.firstName.value = user.firstName;
				service.form.controls.lastName.value = user.lastName;
				service.form.controls.userName.value = user.userName;
				service.form.controls.email.value = user.email;
			}
		} else if (res.errors !== null && res.errors.length) {
			setError(res.errors[0]);
		}

		if (res.value?.errorMessage) {
			console.error(`Clever registration error: ${res.value.errorMessage}`);
		}
	}

	const processRequestTimeout = () => {
		requestStopTimer$.next(true);
		const error = {
			type: ErrorTypes.RequestTimeout,
			description: 'Uh-oh. Something is wrong with the connection to Clever. Please reload the page and try again. If the error persists, please email support@esgisoftware.com'
		} as Error;
		setError(error);
		setLoaded(true);
	}

	return <div>
		<Form controller={service.form} className={join('form-horizontal', styles.form)}>

			<Loader show={!loaded} whiteBackground />
			
			<div className={styles.title}>
				<h3 style={{textAlign: "center"}}>Please wait</h3>
				<p>Data is being received from the Clever account and a new user is being registered.</p>
				<p>This process may take up to several minutes. When the process is complete, you can continue registration.</p>
			</div>

			{error && <div className={styles.error}>
				<span>{error.description}</span>
			</div>}
			
			<div className={styles.formRow}>
				<FormElement control={service.form.controls.firstName} className={join('row', styles.formControl)} disableValidateOnChange>
					<Label className={styles.label}>First Name</Label>
					<div>
						<Input readOnly type='text'/>
						<Hint className={styles.hint}>{(state) => {
							if (state.status === ElementStatus.valid) {
								return <i className='fa fa-check form-control-feedback'/>;
							}
							if (state.status === ElementStatus.invalid) {
								return <i className='fa fa-close form-control-feedback'/>;
							}
						}}</Hint>
						<OnErrorTooltip showOnError='required' placement='right'>
							Required
						</OnErrorTooltip>
					</div>
				</FormElement>
				<FormElement control={service.form.controls.lastName} className={join('row', styles.formControl)} disableValidateOnChange>
					<Label className={styles.label}>Last Name</Label>
					<div>
						<Input readOnly type='text'/>
						<Hint className={styles.hint}>{(state) => {
							if (state.status === ElementStatus.valid) {
								return <i className='fa fa-check form-control-feedback'/>;
							}
							if (state.status === ElementStatus.invalid) {
								return <i className='fa fa-close form-control-feedback'/>;
							}
						}}</Hint>
						<OnErrorTooltip showOnError='required' placement='right'>
							Required
						</OnErrorTooltip>
					</div>
				</FormElement>
			</div>
			
			<div className={styles.formRow}>
				<FormElement control={service.form.controls.userName} className={join('row', styles.formControl)} disableValidateOnChange>
					<Label className={styles.label}>User Name</Label>
					<div>
						<Input readOnly type='text'/>
						<Hint className={styles.hint}>{(state) => {
							if (state.status === ElementStatus.valid) {
								return <i className='fa fa-check form-control-feedback'/>;
							}
							if (state.status === ElementStatus.invalid) {
								return <i className='fa fa-close form-control-feedback'/>;
							}
						}}</Hint>
						<OnErrorTooltip showOnError='required' placement='right'>
							Required
						</OnErrorTooltip>
					</div>
				</FormElement>
				<FormElement control={service.form.controls.email} className={join('row', styles.formControl)} disableValidateOnChange>
					<Label className={styles.label}>Email</Label>
					<div>
						<Input readOnly type='email'/>
						<Hint className={styles.hint}>{(state) => {
							if (state.status === ElementStatus.valid) {
								return <i className='fa fa-check form-control-feedback'/>;
							}
							if (state.status === ElementStatus.invalid) {
								return <i className='fa fa-close form-control-feedback'/>;
							}
						}}</Hint>
						<OnErrorTooltip showOnError='required' placement='right'>
							Required
						</OnErrorTooltip>
						<OnErrorTooltip showOnError='email' placement='right'>
							Invalid email
						</OnErrorTooltip>
					</div>
				</FormElement>
			</div>
			
			{loaded && <div className={styles.buttonsRow}>
				{!error && formValid 
					&& <button className='btn btn-success' onClick={() => service.continue()}>Continue</button>}
				{error && cleverUrl
					&& <button className='btn btn-secondary' onClick={() => service.reLoginToClever(cleverUrl)}>Re-login to clever</button>}
				{error?.type === ErrorTypes.RequestTimeout
					&& <button className='btn btn-primary' onClick={() => document.location.reload()}>Reload Page</button>}
			</div>}
		</Form>
	</div>;
}