import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './styles.module.less';
import {historyOptions} from '../../../../../../utils';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {DateRange, TestModel} from 'modules/reports/iep-progress/types';

interface Props {
	test: TestModel;
	dateRange: DateRange;
}

export function DetailReportCharts({test, dateRange}: Props) {
	const [itemHeight, setItemHeight] = useState(0);
	const itemRef = useRef<HTMLDivElement>(null);

	const historyOptionsMemo = useMemo(() => historyOptions(test, dateRange), [test]);
	const containerStyle = useMemo(() => ({
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
	}), []);

	useEffect(() => {
		setItemHeight(itemRef.current?.clientHeight);
		const visibleEl = (itemRef.current.children[0] as HTMLElement);
		visibleEl.style.width = itemRef.current.clientWidth + 'px';
		visibleEl.style.display = 'flex';

	}, [itemRef.current]);

	return <div className={styles.flexRow} ref={itemRef}>
			<div className={styles.graphContainer} id={`export-chart-${test.testID}`}>
				<HighchartsReact
					options={historyOptionsMemo}
					containerStyle={containerStyle}/>
			</div>
	</div>;
}
