import {useMemo} from 'react';
import {
	QuestionModel,
	QuestionSlider,
	QuestionFooter,
	QuestionHeader,
	QuestionLayout,
} from 'modules/assessments';

type Props = {
	index: number;
	questions: QuestionModel[];
	minIndex: number;
	maxIndex: number;
}

export function StudentScreenSlider(props: Props): JSX.Element {
	const {minIndex, maxIndex, questions} = props;

	return <QuestionLayout>
		<QuestionHeader/>
		<QuestionSlider minIndex={minIndex} maxIndex={maxIndex} questions={questions} currentIndex={props.index}/>
		<QuestionFooter/>
	</QuestionLayout>;
}
