import {useMemo} from 'react';
import {ChartItem} from '../types';
import {TestSession, TestInfo, Question, AnswerState} from '../../../../../types';

export function useDiagramData({
	sessions,
	testInfo,
	emptySessionsCount,
}: {
	sessions: TestSession[];
	testInfo: TestInfo;
	emptySessionsCount: number;
}) {
	const questionTitlesById = useMemo(() => {
		const questionTitlesById: Record<Question['id'], Question['label']> = {};

		testInfo.questions.forEach(({id, label}) => (questionTitlesById[id] = label));

		return questionTitlesById;
	}, [testInfo]);

	return useMemo(() => {
		const updatedQuestions: Record<number, ChartItem> = {};

		for (const {answers, duration} of sessions) {
			for (const {answerState, questionID} of answers) {
				const question = updatedQuestions[questionID];
				updatedQuestions[questionID] = {
					questionID,
					correct: (question?.correct ?? 0) + (answerState === AnswerState.Correct ? 1 : 0),
					incorrect: (question?.incorrect ?? 0) + (answerState === AnswerState.Incorrect ? 1 : 0),
					sessionTimeInSec: (question?.sessionTimeInSec ?? 0) + duration,
					questionTitle: questionTitlesById[questionID] ?? '',
				};
			}
		}

		return {
			data: Object.values(updatedQuestions).map<ChartItem>(({sessionTimeInSec, ...value}) => ({
				...value,
				sessionTimeInSec: sessionTimeInSec / sessions.length,
			})),
			sessionsCount: sessions.length + emptySessionsCount,
		};
	}, [questionTitlesById, sessions, emptySessionsCount]);
}
