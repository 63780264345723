import {useEffect, useState} from 'react';
import {Loader} from '@esgillc/ui-kit/loader';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Label, Option} from '@esgillc/ui-kit/form-control';
import {SchoolAdminService} from './service';
import {TabFooter} from '../../../../tab-footer';
import {LocationFormProps} from '../../types';
import {StudentProfileMode, StudentProfileTab} from 'modules/forms/students-form/types';
import {ManagedCheckboxesList} from '../../components/managed-checkboxes-list';
import {RemoveFromClassesAlert} from '../../components/remove-from-classes-alert';
import {RemoveFromGroupsCheckbox} from '../../components/remove-from-groups-checkbox';
import styles from '../../styles.module.less';

export function SchoolAdminLocation({
	studentID,
	mode,
	initData,
	tabsApi,
	preSelected,
	onClose,
}: LocationFormProps) {

	const service = useService(SchoolAdminService);
	const teachers = useBehaviorSubject(service.teachers);
	const classes = useBehaviorSubject(service.classes);
	const groups = useBehaviorSubject(service.groups);
	const serviceLoading = useBehaviorSubject(service.serviceLoading);
	const tabs = useBehaviorSubject(service.tabsStatus);
	const selectedClasses = useBehaviorSubject(service.selectedClasses);

	const [isShowCheckbox, setShowCheckbox] = useState(!!selectedClasses.length);
	const [isShowRemoveFromClassesAlert, setShowRemoveFromClassesAlert] = useState(false);
	const [isInitialized, setInitialized] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const onSave = (isSaveAndAdd?: boolean) => {
		if(tabs[StudentProfileTab.Location]?.isTouched) {
			service.save(isSaveAndAdd).subscribe(() => {
				if (!isSaveAndAdd) {
					onClose();
				}
			});
		}else {
			if (!isSaveAndAdd) {
				onClose();
			}
		}
	};

	const handelCheckboxClicked = () => {
		service.form.controls.classIDs.value = [];
		service.form.controls.groupIDs.value = [];
		setShowCheckbox(false);
	};

	useEffect(() => {
		setLoading(true);
		service.init({initData, mode, studentID, preSelected, tabsApi}).subscribe({complete: () => {
			setInitialized(true);
			setLoading(false);
		}});
	}, []);

	useEffect(() => {
		setShowCheckbox(Boolean(selectedClasses.length));
	}, [selectedClasses.length]);

	return <div data-cy='school-admin-location-form'>
		<Loader show={isLoading || serviceLoading} fullscreen/>
		{isInitialized && <div className={styles.formWrapper}>
			<Form controller={service.form} className={styles.form}>
				<div data-cy='teacher' className={styles.row}>
					<FormElement control={service.form.controls.teacher} className={styles.column}>
						<Label className={styles.topLabel}>Teacher</Label>
						<Dropdown optionName='fullName' tabIndex={3} placeholder='Select Teacher'>
							{teachers.map(v => <Option key={v.userID} value={v}>{v.fullName}</Option>)}
						</Dropdown>
					</FormElement>
				</div>

				{mode !== StudentProfileMode.view && <RemoveFromGroupsCheckbox
					label='Remove student from all Classes / Groups'
					isChecked={isShowCheckbox}
					onClick={() => setShowRemoveFromClassesAlert(true)}
				/>}

				<div className={styles.row}>
					<ManagedCheckboxesList
						data-cy='classes-checkbox-list'
						listName='Classes'
						list={classes}
						control={service.form.controls.classIDs}
					/>
					<ManagedCheckboxesList
						data-cy='groups-checkbox-list'
						listName='Groups'
						list={groups}
						control={service.form.controls.groupIDs}
					/>
				</div>
			</Form>
			{isShowRemoveFromClassesAlert && <RemoveFromClassesAlert
				teacherFullName={service.form.controls.teacher.value[0].fullName}
				studentFullName={initData.general.firstName + ' ' + initData.general.lastName}
				selectedClasses={selectedClasses}
				onRemove={handelCheckboxClicked}
				onCancel={() => setShowRemoveFromClassesAlert(false)}
			/>}

			<TabFooter
				tabsApi={tabs}
				canSaveAndAdd={initData.isOpenedWithAdd}
				currentTab={StudentProfileTab.Location}
				mode={mode}
				onSave={() => onSave(false)}
				onSaveAndAdd={() => onSave(true)}
			/>
		</div>}
	</div>;
}

