import {AnswerState} from '@esgi/core/enums';
import {TextArea} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {AnswerPanel} from './components/answer-panel';
import {QuestionMover} from './components/question-mover';
import {MoveDirection} from './components/question-mover/types';
import {YesNoEditButtons} from './components/edit-buttons';
import {Sessions} from '../sessions';
import {useYesNo} from './hooks';
import {YesNoService} from '../../services';
import {useTestSessionDetailsService} from '../../hooks';
import styles from './styles.module.less';
import {useMemo} from 'react';
import {UserType, useUser} from '../../../../../../../../libs/core/src/authentication';

interface Props {}

export function YesNo({}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const serviceData = service.serviceData as YesNoService;
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);
	const currentUser = useUser();
	const {
		state,
		setState,
		getAnswers,
		setAnswers,
		getAnswersByState,
		correctAnswersHeader,
		setSummaryNote,
		setActiveAnswerPanel,
	} = useYesNo(service);
	const notTestedVerbiage = 'Not Tested';

	const renderMover = (position = 'first') => {
		if (
			state.activeAnswerPanel === undefined
			|| !testSessionDetails
		) {
			return null;
		}

		const {
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
		} = testSessionDetails;

		if (position === 'first') {
			return (state.activeAnswerPanel === AnswerState.Correct)
				? <QuestionMover
						direction={MoveDirection.Right}
						title={`Move to ${testResultsIncorrectVerbiage}`}
						onClick={() => onMoverClick(AnswerState.Incorrect)}
					/>
				: <QuestionMover
						direction={MoveDirection.Left}
						title={`Move to ${testResultsCorrectVerbiage}`}
						onClick={() => onMoverClick(AnswerState.Correct)}
					/>;
		}
		if (position === 'second') {
			return (state.activeAnswerPanel === AnswerState.NotTested)
				? <QuestionMover
						direction={MoveDirection.Left}
						title={`Move to ${testResultsIncorrectVerbiage}`}
						onClick={() => onMoverClick(AnswerState.Incorrect)}
					/>
				: <QuestionMover
						direction={MoveDirection.Right}
						title={`Move to ${notTestedVerbiage}`}
						onClick={() => onMoverClick(AnswerState.NotTested)}
					/>;
		}
		return null;
	};

	const onMoverClick = (targetMove: AnswerState) => {
		const answers = [...state.answers];
		let states = state.notTestedPanel.selectedAnswers;
		if (state.activeAnswerPanel === AnswerState.Correct) {
			states = state.correctPanel.selectedAnswers;
		}
		if (state.activeAnswerPanel === AnswerState.Incorrect) {
			states = state.incorrectPanel.selectedAnswers;
		}
		let dirty = false;

		answers.forEach((answer) => {
			if (!states.includes(answer.questionId)) {
				return;
			}

			answer.answerState = targetMove;
			dirty = true;
		});

		if (dirty) {
			setAnswers(answers);
			service.setDirty(true);

			setActiveAnswerPanel();
		}
	};

	const onNoteSave = (testSessionID: number, questionID: number, comment: string) => {
		const answers = [...state.answers];

		serviceData.saveNote({testSessionID, questionID, comment}).subscribe();

		const answer = answers.find((a) => a.questionId === questionID);
		answer.comment = comment;

		setAnswers(answers);
	};

	const onNoteDelete = (testSessionID: number, questionID: number) => {
		const answers = [...state.answers];

		serviceData.deleteNote({testSessionID, questionID}).subscribe();

		const answer = answers.find((a) => a.questionId === questionID);
		answer.comment = null;

		setAnswers(answers);
	};

	const canEdit = useMemo<boolean>(() => {
		const {userType, userID} = currentUser;
		return (userID === currentTestSession.userID) || (userType === UserType.T && currentTestSession.userID === null);
	}, [currentTestSession]);

	return (
		<div className={styles.testSessionDetails}>
			<Sessions
				getAnswers={getAnswers}
				setAnswers={setAnswers}
			>
				<YesNoEditButtons/>
			</Sessions>
			{(currentTestSession && !currentTestSession.isEmpty) &&
				<div className={styles.correctAnswersHeader}>
					{correctAnswersHeader}
				</div>
			}
			<div className={styles.answers}>
				<div className={testSessionDetails.canEdit ? styles.questionContainer : `${styles.questionContainer} full`}>
					<AnswerPanel
						statePanel={state.correctPanel}
						answerState={AnswerState.Correct}
						answers={getAnswersByState(AnswerState.Correct)}
						title={testSessionDetails.testResultsCorrectVerbiage}
						setActive={(panelState?: AnswerState) => setActiveAnswerPanel(panelState)}
						onChange={(changedState) => setState({...state, correctPanel: changedState})}
						onNoteSave={onNoteSave}
						onNoteDelete={onNoteDelete}
						canEdit={canEdit}
					/>

					{renderMover('first')}

					<AnswerPanel
						statePanel={state.incorrectPanel}
						answerState={AnswerState.Incorrect}
						answers={getAnswersByState(AnswerState.Incorrect)}
						title={testSessionDetails.testResultsIncorrectVerbiage}
						setActive={(panelState?: AnswerState) => setActiveAnswerPanel(panelState)}
						onChange={(changedState) => setState({...state, incorrectPanel: changedState})}
						onNoteSave={onNoteSave}
						onNoteDelete={onNoteDelete}
						canEdit={canEdit}
					/>

					{renderMover('second')}

					<AnswerPanel
						statePanel={state.notTestedPanel}
						answerState={AnswerState.NotTested}
						answers={getAnswersByState(AnswerState.NotTested)}
						title={notTestedVerbiage}
						setActive={(panelState?: AnswerState) => setActiveAnswerPanel(panelState)}
						onChange={(changedState) => setState({...state, notTestedPanel: changedState})}
						onNoteSave={onNoteSave}
						onNoteDelete={onNoteDelete}
						canEdit={canEdit}
					/>
				</div>
				{testSessionDetails.canEdit &&
					<div className='summary-notes-container'>
						<TextArea
							value={state.summaryNote}
							maxLength={255}
							disabled={!editMode || !testSessionDetails.canEdit}
							rows={3}
							placeholder='Summary: Enter session summary (optional)'
							onChange={(e) => setSummaryNote(e.target.value)}
						/>
					</div>
				}
			</div>
		</div>
	);
}
