import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, switchMap, tap} from 'rxjs';
import {ElementStatus} from '@esgi/ui/form';
import {createQuickSettingsSetupForm, equalPinsValidator} from './form';
import {V2TeachersUserSettingsController} from '@esgi/contracts/esgi';
import {OutModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/teacher/init/out-model';
import {userStorage} from '@esgi/core/authentication';
import {isEqual} from 'underscore';

export class QuickSetupSettingsService extends BaseService {
	public initData = new BehaviorSubject<OutModel | null>(null);
	public isUnlockHomepage = new BehaviorSubject<boolean>(false);
	public isKeyboardShortcutsEnabled = new BehaviorSubject<boolean>(false);
	public form = createQuickSettingsSetupForm();
	public pin = new BehaviorSubject<string>('');
	public confirmPin = new BehaviorSubject<string>('');
	public isShowTestSessionResult = new BehaviorSubject<boolean>(false);
	private controller = new V2TeachersUserSettingsController();
	private initialPin: string = '';

	public init = () => {
		return this.controller.init().pipe(tap((response) => {
			this.initData.next(response);
			this.setupQuickSettings();
		}));
	};

	public save() {
		const model = {
			showNewUIVersion: true,
			lockScreen: this.form.controls.lockScreen.value,
			pin: this.form.controls.screenLockPIN.value,
			keyboardShortcuts: this.form.controls.enableShortcuts.value,
			showTestSessionResults: this.isShowTestSessionResult.value,
		};

		if (this.isUnlockHomepage.value) {
			const {screenLockPIN, confirmPin} = this.form.value;

			if (this.form.controls.screenLockPIN.validators.length > 2) {
				this.form.controls.screenLockPIN.validators.pop();
			}
			if (this.form.controls.confirmPin.validators.length > 2) {
				this.form.controls.confirmPin.validators.pop();
			}

			this.form.controls.screenLockPIN.validators.push(equalPinsValidator(confirmPin));
			this.form.controls.confirmPin.validators.push(equalPinsValidator(screenLockPIN));

			model.pin = screenLockPIN;
		}

		return this.form.validate().pipe(switchMap((v) => {
			if (v.valid) {
				return this.controller.quickSetupSave(model);
			}
		})).pipe(tap(() => {
			this.updateInitDataState({
				screenLockPIN: model.pin,
				showTestSessionResults: model.showTestSessionResults,
				enableShortcuts: model.keyboardShortcuts,
				lockScreen: model.lockScreen,
			});
			userStorage.update({
				screenLockPIN: model.pin,
				lockScreen: model.lockScreen,
				enableShortcuts: model.keyboardShortcuts,
			});
		}));
	}

	public updateInitDataState = (props: Partial<OutModel>) => {
		this.initData.next({...this.initData.value, ...props});

		this.setupQuickSettings();
	};

	public compareQuickSetupStatesForEquality = () => {

		const initialState = {
			screenLockPIN: this.initData.value?.screenLockPIN,
			lockScreen: this.initData.value?.lockScreen,
			enableShortcuts: this.initData.value?.enableShortcuts,
			showTestSessionResults: this.initData.value?.showTestSessionResults,
			confirmPin: this.initData.value?.screenLockPIN,
		};

		const currentState = {
			screenLockPIN: this.form.controls.screenLockPIN.value,
			lockScreen: this.form.controls.lockScreen.value,
			enableShortcuts: this.form.controls.enableShortcuts.value,
			showTestSessionResults: this.isShowTestSessionResult.value,
			confirmPin: this.form.controls.confirmPin.value,
		};

		return !isEqual(initialState, currentState);
	};

	public setupQuickSettings = () => {
		const {
			enableShortcuts,
			lockScreen,
			screenLockPIN,
		} = this.initData.value;

		this.form.value = {
			enableShortcuts,
			lockScreen,
			screenLockPIN,
			confirmPin: screenLockPIN,
			pinsEqual: '',
		};

		this.isUnlockHomepage.next(this.form.value.lockScreen);
		this.isKeyboardShortcutsEnabled.next(this.form.value.enableShortcuts);
		this.isShowTestSessionResult.next(this.initData.value.showTestSessionResults);
		this.pin.next(screenLockPIN);
		this.confirmPin.next(screenLockPIN);
		this.initialPin = screenLockPIN;

		if (!this.form.value.lockScreen) {
			this.form.controls.screenLockPIN.status = ElementStatus.disabled;
			this.form.controls.confirmPin.status = ElementStatus.disabled;
			this.isUnlockHomepage.next(false);
		}

		this.form.controls.lockScreen.onChanged.subscribe(lock => {
			const isUnlocked = lock.currState.value;

			this.form.controls.screenLockPIN.status = isUnlocked ? ElementStatus.valid : ElementStatus.disabled;
			this.form.controls.confirmPin.status = isUnlocked ? ElementStatus.valid : ElementStatus.disabled;
			this.isUnlockHomepage.next(isUnlocked);
		});

		this.form.controls.enableShortcuts.onChanged.subscribe(v => {
			this.isKeyboardShortcutsEnabled.next(v.currState.value);
		});
	};
}
