import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {useEffect} from 'react';

export function ClassesContent() {
	const {classBuckets} = StudentsPanel.Tabs.Classes.useClassesContext();

	const {
		selectStudentFromClass,
		selected: {
			studentFromClassId,
			studentId: selectedStudentId,
			classId: selectedClassId,
		},
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	useEffect(() => {
		if (classBuckets?.length) {
			if(classBuckets[0].students?.length) {				
				selectStudentFromClass({
					studentId: classBuckets[0].students[0].id,
					classId: classBuckets[0].id,
				});
			}
		}
	}, [classBuckets]);

	return (
		<StudentsPanel.Tabs.TabContent>
			{classBuckets.length ? (
				classBuckets.map(({id, name, students}) => {
					const isOpen = selectedClassId === id || studentFromClassId === id;
					return (
						<StudentsPanel.Lists.SelectableListGroup.Expandable.Single
							withCounter
							isListOpen={isOpen && Boolean(students.length)}
							key={id}
							onTriggerButtonClick={() =>
								selectStudentFromClass({
									studentId: students[0].id,
									classId: id,
								})
							}
							groupName={name}
							onItemClick={(studentId) =>
								selectStudentFromClass({studentId, classId: id})
							}
							value={selectedStudentId}
							isListSelected={false}
							items={students}
							allowSelectEmpty
						>
							{({item: {firstName, lastName}}) => (
								<StudentsPanel.StudentFullName
									firstName={firstName}
									lastName={lastName}
								/>
							)}
						</StudentsPanel.Lists.SelectableListGroup.Expandable.Single>
					);
				})
			) : (
				<StudentsPanel.Tabs.EmptyTabContent
					size='full'
					templateText='No classes to display'
				/>
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
