import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {createKeyboardShortcutsForm} from './form';
import {V2TeachersUserSettingsController} from '@esgi/contracts/esgi';


export class KeyboardShortcutsService extends BaseService {
	public isKeyboardShortcutsEnabled = new BehaviorSubject<boolean>(false);
	public form = createKeyboardShortcutsForm();
	private controller = new V2TeachersUserSettingsController();
	public initialIsKeyboardShortcutsEnabled = new BehaviorSubject<boolean>(false);

	constructor() {
		super();
		this.form.controls.isKeyboardShortcuts.onChanged.subscribe(v => {
			this.isKeyboardShortcutsEnabled.next(v.currState.value);
		});
	}
	public init = (isKeyboardShortcuts: boolean) => {
		this.isKeyboardShortcutsEnabled.next(isKeyboardShortcuts);
		this.initialIsKeyboardShortcutsEnabled.next(isKeyboardShortcuts);
		this.form.controls.isKeyboardShortcuts.value = isKeyboardShortcuts;
	};

	public compareStatesForEquality = () => {
		return !(this.form.controls.isKeyboardShortcuts.value === this.initialIsKeyboardShortcutsEnabled.value);
	};

	public save = () => {
		return this.controller.tabsKeyboardShortcutsSave({value: this.isKeyboardShortcutsEnabled.value});
	};

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}
