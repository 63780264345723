import {
	ConflictLevel, ConflictStudentModel,
	ConflictType,
	Validation,
	ValidationType,
} from 'shared/modules/student-roster-upload-tool/types';
import {
	parseStudentName,
} from 'shared/modules/student-roster-upload-tool/components/steps/conflict-resolution/utils';
import {TextInputConflict} from '../text-input';


interface Props {
	existingStudentNames: string[];
	uploadedStudentName: string;
	onEdit: (value: string, validation: Validation) => void;
	onClick: (validation: Validation) => void;
	validation: Validation;
	tableHeight: number;
	tableOffset: number;
	student: ConflictStudentModel
}

export function Name({
											 onEdit,
											 existingStudentNames,
											 uploadedStudentName,
											 onClick,
											 validation,
											 tableOffset,
											 tableHeight,
											 student,
										 }: Props) {

	if(!student.name.editable) {
		return <>{student.name.value}</>;
	}

	const onHandleEdit = (value: string) => {
		if (!value.trim()) {
			return onEdit(value, new Validation(ValidationType.Standard, ConflictType.None, 'Please enter a student name.'));
		}

		const names = parseStudentName(value);
		if (names.length < 2 || (names[0].length === 0 || names[1].length === 0)) {
			return onEdit(value, new Validation(ValidationType.Standard, ConflictType.None, 'First and last name required.'));
		}

		const fullName = names[0] + ' ' + names[1];
		if (existingStudentNames.map(s => s.toLowerCase()).some(s => s === fullName.toLowerCase())) {
			return onEdit(value, new Validation(ValidationType.Conflict, ConflictType.DuplicateStudentName));
		}
		return onEdit(value, new Validation());
	};

	return <div data-cy={'name'}>
		<TextInputConflict
			value={uploadedStudentName}
			validationType={validation.type}
			conflictLevel={ConflictLevel.Warning}
			validation={{
				valid: validation.type === ValidationType.None,
				message: validation.message,
			}}
			onEdit={(value) => onHandleEdit(value)}
			tableHeight={tableHeight}
			tableOffset={tableOffset}
			onClick={() => onClick(validation)}
		/>
	</div>;
}
