import {createContext, useContext} from 'react';
import {TestSectionContextValue} from './types';

export const TestSectionContext = createContext<TestSectionContextValue | null>(null);

export function useTestSectionContext() {
	const context = useContext(TestSectionContext);

	if (!context) {
		throw new Error('TestSectionContext is null');
	}

	return context;
}
