import {SubjectTab, SubjectType} from '@esgi/main/libs/store';

export class SubjectChangedEvent {
	constructor(public id: SubjectTab['id'], public name: string, public type: SubjectType, public hidden: boolean) {}
}

export class SubjectRemovedEvent {
	constructor(public id: SubjectTab['id']) {}
}

export class SubjectCreatedEvent {
	constructor(public newSubject: SubjectTab) {}
}
