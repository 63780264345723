import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
}

export function DeleteIcon({className}: Props) {
	return <svg data-cy='delete-icon' className={join(className)} xmlns='http://www.w3.org/2000/svg' width='13'
				height='14' viewBox='0 0 12 14' fill='none'>
		<path
			d='M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75
			10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM11.25 1H8.625L7.875
			0.25H4.125L3.375 1H0.75V2.5H11.25V1Z'
			fill='#828282'/>
	</svg>;
}
