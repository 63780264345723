import React, {CSSProperties} from 'react';
import {Draggable, DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd';
import {parse, stringify} from './utils';

type RefSetter = (element: any) => void;

interface Props {
	canDrag: boolean;
	draggableId: string;
	index: number;
	scrollContainer: HTMLElement;
	children: (provided?: DraggableProvided, snapshot?: DraggableStateSnapshot, refSetter?: RefSetter) => React.ReactElement<HTMLElement>;
}

export default class DragNDropRow extends React.PureComponent<Props> {
	private rowRef: HTMLTableRowElement;
	private isDragging: boolean = false;
	private initialTopPosition: number = undefined;
	private initialScrollTopPosition: number = undefined;
	private initialScrollHeight: number = undefined;

	public componentDidMount() {
		if (this.props.scrollContainer) {
			this.initScrollListener(this.props.scrollContainer);
		}
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
		if (prevProps.scrollContainer !== this.props.scrollContainer) {
			this.initScrollListener(this.props.scrollContainer);
		}
	}

	public render() {
		return <Draggable isDragDisabled={!this.props.canDrag} draggableId={this.props.draggableId} index={this.props.index}>
			{(provided, snapshot) => this.renderElement(provided, snapshot)}
		</Draggable>;
	}

	private renderElement(provided?: DraggableProvided, snapshot?: DraggableStateSnapshot): React.ReactElement<HTMLElement> {
		const providedProps = provided || {} as DraggableProvided;
		let {style} = providedProps.draggableProps || {};
		if (this.isDragging !== snapshot?.isDragging) {
			if (snapshot?.isDragging) {
				this.initDragging(style);
			}
		}
		this.isDragging = !!snapshot?.isDragging;

		let stylesToApply = style as CSSProperties;

		if (snapshot?.isDragging) {
			stylesToApply = {
				...style,
				position: 'absolute',
				left: undefined,
				top: undefined,
			} as CSSProperties;

			if (stylesToApply.transform) {
				const transform = parse(stylesToApply.transform);
				if (transform.translate) {
					transform.translate = [0, transform.translate[1]];
				}
				stylesToApply.transform = stringify(transform);
			}

			this.calcPosition();
		}
		providedProps.draggableProps.style = stylesToApply as any;

		return this.props.children(providedProps, snapshot, (element) => this.setRef(element, providedProps));
	}

	private setRef(element, providedProps: DraggableProvided) {
		this.rowRef = element;
		providedProps?.innerRef(element);
	}

	private initScrollListener(ref: HTMLElement) {
		ref?.addEventListener('scroll', () => {
			if (this.isDragging) {
				this.calcPosition();
			}
		});
	}

	private initDragging(libStyles: CSSProperties) {
		if (this.props.scrollContainer && this.rowRef && libStyles) {
			const parentBounds = this.rowRef.parentElement.getBoundingClientRect();
			const rowBounds = this.rowRef.getBoundingClientRect();
			this.initialTopPosition = rowBounds.top - parentBounds.top;
			this.initialScrollTopPosition = this.props.scrollContainer.scrollTop;
			this.initialScrollHeight = this.rowRef.parentElement.offsetHeight;
		}
	}

	private calcPosition(): void {
		if (this.rowRef && this.props.scrollContainer) {
			const scrollDelta = this.initialScrollTopPosition - this.props.scrollContainer.scrollTop;
			const {scrollLeft} = this.props.scrollContainer;
			this.rowRef.style.top = (this.initialTopPosition - scrollDelta) + 'px';
			this.rowRef.style.left = scrollLeft + 'px';
		}
	}
}
