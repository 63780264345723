import {Box} from '@esgi/ui/layout';
import {styled} from '@stitches/react';

export const Container = styled(Box, {
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 18,
	height: 18,
	borderRadius: 4,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
});
