import {useAssignmentId} from '../hooks/use-assignment-id';
import {isNull} from 'underscore';
import {Student} from '@esgi/main/libs/store';
import {AddAssignment} from './assignment-variant/add-assignment';
import {useCallback, useState} from 'react';
import {Layout} from './index.styled';
import {EditAssignment} from './assignment-variant/edit-assignment';
import {MultipleStudentsCredentialsAlert} from '../student-credentials/multiple-students-credentials-alert';
import {SingleStudentCredentialsAlert} from '../student-credentials/single-student-credentials-alert';
import {useTeacherAssignmentsContext} from '../layout/outlet-context';
import {CommonAssignmentVariantProps} from './assignment-variant/types';

export default function TeacherAddEditAssignment() {
	const {snackbarRef} = useTeacherAssignmentsContext();

	const assignmentID = useAssignmentId();

	const [studentsIdsForCredentialsAlert, setStudentsIdsForCredentialsAlert] = useState<Student['id'][] | null>(null);

	const onOpenStudentCredentials = useCallback((studentId: Student['id']) => {
		setStudentsIdsForCredentialsAlert([studentId]);
	}, []);

	const onOpenCredentialsForGroupStudents = useCallback((studentsIDs: Student['id'][]) => {
		setStudentsIdsForCredentialsAlert(studentsIDs);
	}, []);

	const onCloseStudentCredentialsAlert = useCallback(() => {
		setStudentsIdsForCredentialsAlert(null);
	}, []);

	const getAssignmentContent = () => {
		const commonProps: CommonAssignmentVariantProps = {
			snackbarRef,
			onOpenCredentialsForGroupStudents,
			onOpenStudentCredentials,
		};

		if (!isNull(assignmentID)) {
			return <EditAssignment assignmentID={assignmentID} {...commonProps} />;
		}

		return <AddAssignment {...commonProps} />;
	};

	return (
		<>
			<Layout dataCy='assignment-edit'>{getAssignmentContent()}</Layout>

			{studentsIdsForCredentialsAlert?.length === 1 && (
				<SingleStudentCredentialsAlert
					snackbarRef={snackbarRef}
					studentId={studentsIdsForCredentialsAlert[0]!}
					onAlertClose={onCloseStudentCredentialsAlert}
				/>
			)}

			{studentsIdsForCredentialsAlert && studentsIdsForCredentialsAlert.length >= 2 && (
				<MultipleStudentsCredentialsAlert
					snackbarRef={snackbarRef}
					studentIds={studentsIdsForCredentialsAlert}
					onAlertClose={onCloseStudentCredentialsAlert}
				/>
			)}
		</>
	);
}
