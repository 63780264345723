import {Text} from '@esgi/ui/typography';
import {SortFieldMap} from '../../tests-root/models';
import {useTestsRootContext} from '../../tests-root/context';
import {ButtonText, SortArrows} from './index.styled';

export function Sort() {
	const {sort, handleSortDirectionChange} = useTestsRootContext();

	return (
		<ButtonText onClick={handleSortDirectionChange}>
			<Text size='small'>{SortFieldMap[sort.field]}</Text>
			<SortArrows direction={sort.direction} />
		</ButtonText>
	);
}
