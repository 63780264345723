import axios, {AxiosRequestConfig, AxiosResponse, CancelTokenSource} from 'axios';
import {Subscriber, Observer} from 'rxjs';

export default class RequestSubscriber<T> extends Subscriber<T> {
	private inProcessing: boolean = false;
	private cancelToken: CancelTokenSource = axios.CancelToken.source();

	constructor(
		private observer: Subscriber<AxiosResponse<T>> | Observer<AxiosResponse<T>>,
		private options: AxiosRequestConfig,
		private retryAttempts: number,
	) {
		super(observer);
		this.process();
		this.inProcessing = true;
	}

	/**
	 * Makes a request and retries a certain number of times if it receives a 503
	 * error, then notifies an observer with the response or throws an error.
	 */
	private async process(): Promise<void> {
		const delay = 100;
		for (let i = 0; i < this.retryAttempts + 1; i++) {
			let response: AxiosResponse<T>;
			try {
				response = await this.makeRequest();
				this.observer.next(response);
				break;
			} catch (err: any) {
				console.error(err);
				if (i < this.retryAttempts && err.response && err.response.status === 503) {
					console.info(`${err.response.config && err.response.config.url} .Attempt ${i + 1}: retrying in ${(i + 1) * delay}ms`);
					await this.sleep((i + 1) * delay);
					continue;
				}
				this.observer.error(err);
			}
		}
		this.endRequest();
	}

	/**
	 * Sends an asynchronous HTTP request using Axios with a specified
	 * configuration.
	 * @returns a Promise that resolves to an AxiosResponse object.
	 */
	private async makeRequest(): Promise<AxiosResponse<T>> {
		const config = {...this.options};
		config.cancelToken = this.cancelToken.token;
		return await axios.request(config);
	}

	private endRequest() {
		this.inProcessing = false;
		this.observer.complete();
	}

	private sleep(delay: number): Promise<void> {
		return new Promise<void>(resolve => setTimeout(resolve, delay));
	}

	/**
	 * The function unsubscribes from a subscription and cancels any ongoing processing.
	 */
	override unsubscribe() {
		super.unsubscribe();
		if (this.inProcessing) {
			this.cancelToken.cancel();
		}
		this.observer.complete();
	}
}
