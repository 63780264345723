import {useState} from 'react';
import {ExpandableSelectableListMultiple} from './components/list';
import {ActionPanel} from './components/action-panel';
import {ReportTest, TestListProps} from '../../../tests-control/types';
import {EmptyList} from '../empty-list';

type Props = Omit<TestListProps, 'tests'> & {
	hasSelectedStudents?: boolean
	tests: ReportTest[]
};
export function ExpandableTestList(props: Props) {
	const {
		tests,
		selectedTests,
		toggleTests,
		sort,
		onSort,
		onSelectAll,
		onChangeTestsData,
	} = props;

	const [isListOpen, setListOpen] = useState(false);

	return	props.hasSelectedStudents ? (
		<>
				<ActionPanel
					sort={sort}
					onSort={onSort}
					onSelectAll={onSelectAll}
					isListOpen={isListOpen}
					setListOpen={setListOpen}
					isListOpenDisabled={!selectedTests?.length}
				/>

				<ExpandableSelectableListMultiple
					isListOpen={isListOpen}
					items={tests}
					selected={selectedTests}
					setSelected={(id) => toggleTests([id])}
					onChangeTestsData={onChangeTestsData}
				/>
		</>
	) : (
			<EmptyList content='No selected student' />
	);
}
