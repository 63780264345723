import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const gridCardsContainerCSS: CSS = {
	gridTemplateColumns: 'repeat(auto-fill, minmax(362px, 1fr))',
	gridAutoRows: 'max-content',
	gap: 12,
};

export const GridCardsContainer = styled(GridBox, gridCardsContainerCSS);
