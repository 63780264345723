export function CartTemplate() {
	return <div className='cart' data-bind='var: {step: $data}'>
		<section>
			<table className='table table-hover cart-table'>
				<tbody data-cy='cart-table'>
				<ko data-bind='if: cart.expiration && cart.expiration.products.length && isAnyProductSelected'>
					<tr data-cy='subscription-headers-row'>
						<th></th>
						<th></th>
						<th data-cy='new-expiration-date-column-header'>New Expiration Date</th>
						<th data-cy='renewal-cost-column-header'>Cost</th>
					</tr>
					<ko data-bind='foreach: cart.expiration.products'>
						<ko data-bind='if: selected'>
							<tr data-cy='subscription-renewal-row'>
								<td data-cy='subscription-label'><i className='fa fa-clock-o'></i> Subscription</td>
								<td data-cy='service-name'>
									<label>
										<span className='lbl'></span>
									</label>
									<span data-bind='text: name'/>
								</td>
								<td data-cy='new-expiration-date' style={{position: 'relative'}} className='expiration-cell'>
									<span data-bind="text: newExpirationDate().format('MM/DD/YY')"></span>
									<a className='edit' href='#' data-bind='click: step.view.editExpiration'><i
										className='fa fa-pencil'></i></a>
								</td>
								<td data-cy='renewal-cost'>$<span data-bind='text: price.toFixed(2)'></span></td>
							</tr>
						</ko>
					</ko>
				</ko>

				<ko>

					<tr data-cy='students-headers-row'>
						<th></th>
						<th data-cy='current-limit-column-header'>Current Limit</th>
						<th data-cy='new-limit-column-header'>New Limit</th>
						<th data-cy='students-cost-column-header'>Cost</th>
					</tr>

					<tr data-cy='students-limit-row'>
						<td data-cy='add-students-label'><i className='fa fa-user'></i> Add Students</td>
						<td data-cy='current-students-limit'><span data-bind='text: cart.student.currentStudentLimit'></span></td>
						<td data-cy='new-students-limit' style={{position: 'relative'}}><span
							data-bind='text: cart.student.newStudentLimit'></span>
							<a className='edit' href='#' data-bind='click: step.view.editStudents'><i
								className='fa fa-pencil'></i></a></td>
						<td data-cy='students-cost'>$<span data-bind='text: cart.studentsPrice'></span></td>
					</tr>
				</ko>
				</tbody>
			</table>


		</section>
		<div data-cy='total-cost' className='clearfix'>
			<h3 className='pull-right'>Total: $<span data-bind='text: cart.price'></span></h3>
		</div>
		<div className='clearfix button-container' style={{marginTop: '15px'}}>
			<button className='btn btn-cancel pull-left' data-cy='cancel-button'>Cancel</button>
			<ko data-bind='if: !cart.student.newStudentLimit'>
				<button className='btn btn-link btn-students' data-bind='click: view.addStudents' data-cy='adjust-students-button'>Need to adjust
					your student limit?
				</button>
			</ko>
			<ko data-bind='if: !isAnyProductSelected'>
				<button className='btn btn-link btn-time' data-bind='click: view.addTime' data-cy='renew-subscription-button'>Need to renew your
					subscription?
				</button>
			</ko>
			<button className='btn btn-warning pull-right' data-cy='check-out-button'
			        data-bind="click: view.checkOut, css: {'disabled' : !isAbleToPurchase }">Check Out
			</button>
		</div>
	</div>;
}
