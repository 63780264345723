import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Dropdown, OnErrorTooltip, Option, Label} from '@esgillc/ui-kit/form-control';
import {IEPStatusModel} from '../../models';
import {join} from '@esgillc/ui-kit/utils';

import styles from '../styles.module.less';

type Props = {
	control: IFormControl;
	statuses: IEPStatusModel[];
	editMode: boolean;
}

export const StatusDropdown = ({control, statuses, editMode}: Props) => {
	let value = 'Status is not set';
	if (!editMode) {
		const status = statuses.find(({id}) => id === control.value[0]?.id);
		value = status?.name || 'Status is not set';
	}

	return (
		<FormElement
			className={styles.textAreaFormElement}
			control={control}
		>
			<Label className={styles.label}>Status</Label>
			{editMode &&
				<>
					<Dropdown
						placeholder='Select a status...'
						className={join(styles.field, styles.dropDownField)}
						optionName='name'
						tabIndex={0}>
						{statuses.map((v, i) => <Option key={i} value={v}>{v.name}</Option>)}
					</Dropdown>
					<OnErrorTooltip
						showOnError='required'
						placement='left'
					>
						Please select a status...
					</OnErrorTooltip>
				</>
			}
			{!editMode &&
				<div className={styles.dropDownField}>{value}</div>
			}
		</FormElement>
	);
};
