import {DateFormat} from './types';

export function isValidDate(year: number, month: number, day: number): boolean {
	const date = new Date(year, month, day);
	return (
		date.getFullYear() === year &&
		date.getMonth() === month &&
		date.getDate() === day
	);
}

function addLeadingZeroIfNeeded(number: number): string {
	return number < 10 ? `0${number}` : number.toString();
}

export function parseDateStringToDate(dateStr: string, format: DateFormat): Date | '' {
	const parts = dateStr.split('/');
	if (parts.length === 3) {
		let day: number;
		let month: number;
		let year: number;

		switch (format) {
			case 'dD/mM/YYYY':
				day = parseInt(parts[0], 10);
				month = parseInt(parts[1], 10) - 1;
				year = parseInt(parts[2], 10);
				break;
			case 'mM/dD/YYYY':
				month = parseInt(parts[0], 10) - 1;
				day = parseInt(parts[1], 10);
				year = parseInt(parts[2], 10);
				break;
			case 'mM/dD/YY':
				month = parseInt(parts[0], 10) - 1;
				day = parseInt(parts[1], 10);
				year = parseInt(`20${parts[2]}`, 10);
				break;
			case 'YYYY/mM/dD':
				year = parseInt(parts[0], 10);
				month = parseInt(parts[1], 10) - 1;
				day = parseInt(parts[2], 10);
				break;
			case 'YYYY/dD/mM':
				year = parseInt(parts[0], 10);
				day = parseInt(parts[1], 10);
				month = parseInt(parts[2], 10) - 1;
				break;
			default:
				return '';
		}

		if (isValidDate(year, month, day)) {
			return new Date(year, month, day);
		}
	}
	return '';
}

export function parseDateOrRangeStringToDate(dateStr: string, format: DateFormat ): Date[] {
	return dateStr.split('-').map(item => {
		const parsedDate = parseDateStringToDate(item, format);
		return parsedDate instanceof Date ? parsedDate : '';
	}).filter(item => !!item).sort((a, b) => a > b ? 1 : -1) as Date[];
}

export function parseDateRangeToString(dateArray: Date[], format: DateFormat): string {
	const [startDate, endDate] = dateArray;
	const startDateString = startDate ? formatDateString(startDate, format) : '';
	const endDateString = endDate ? formatDateString(endDate, format) : '';

	return `${startDateString}${endDateString ? '-' : ''}${endDateString}`;
}

function formatDateString(date: Date, format: string = 'mM/dD/YYYY'): string {
	let formattedDate = format;
	formattedDate = formattedDate.replace('dD', addLeadingZeroIfNeeded(date.getDate()));
	formattedDate = formattedDate.replace('mM', addLeadingZeroIfNeeded(date.getMonth() + 1));

	if ([...formattedDate.matchAll(/Y/g)].length === 2) {
		formattedDate = formattedDate.replace('YY', date.getFullYear().toString().substring(2));
	} else {
		formattedDate = formattedDate.replace('YYYY', date.getFullYear().toString());
	}

	return formattedDate;
}

export function monthFormat(date: string) {
	if (date.startsWith('1')) {
		return '[0-2]';
	} else if (date.startsWith('0')) {
		return '[1-9]';
	}
	return '[0-9]';
}

export function dayFormat(date: string) {
	if (date.startsWith('3')) {
		return '[0-1]';
	} else if (date.startsWith('0')) {
		return '[1-9]';
	}
	return '[0-9]';
}

export function getStringDateParts(dateString: string, format: DateFormat) {
	const [firstPart, secondPart, thirdPart] = dateString.split('/');

	switch (format) {
		case 'dD/mM/YYYY':
			return {day: firstPart, month: secondPart, year: thirdPart};
		case 'mM/dD/YYYY':
			return {day: secondPart, month: firstPart, year: thirdPart};
		case 'YYYY/mM/dD':
			return {day: thirdPart, month: secondPart, year: thirdPart};
		case 'YYYY/dD/mM':
			return {day: secondPart, month: thirdPart, year: firstPart};
		default:
			return {day: '', month: '', year: ''};
	}
}