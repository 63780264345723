import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportFiltersLabelContainer, ReportFiltersContent} from '../../../../../report-filter';
import {RubricResultReportService} from '../../../../../../../../service';
import {Item, ReportFilterContainerStyled, ReportFilterLabelStyled, SmallLabel} from './index.styled';

interface Props {
	service: RubricResultReportService;
}

export function LevelsLegend({service}: Props) {
	const reportData = useBehaviorSubject(service.reportData$);

	const levelItems = reportData?.levels?.map(({name, score}, index) => {
		const spacesOrNumbersRegex = /^(\s*|\d+)$/;

		const isNumber = spacesOrNumbersRegex.test(name);
		const key = `${name}-${score}-${index}`;

		return isNumber ? (
			<Item dataCy='level-item' key={key}>
				{score}
			</Item>
		) : (
			<OnHoverTooltip key={key} message={name}>
				<Item>
					{score}
				</Item>
			</OnHoverTooltip>
		);
	});

	return (
		<ReportFilterContainerStyled>
			<ReportFiltersLabelContainer>
				<ReportFilterLabelStyled dataCy='score-results-label'>Results:</ReportFilterLabelStyled>
				<SmallLabel dataCy='highest-lowest-label'>Highest to Lowest</SmallLabel>
			</ReportFiltersLabelContainer>
			<ReportFiltersContent>{levelItems}</ReportFiltersContent>
		</ReportFilterContainerStyled>
	);
}
