import {ComponentProps, useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {Tooltip} from '@esgi/ui/tooltip';
import {TooltipContent, TriggerText} from './styled';
import {Text} from '@esgi/ui/typography';
import {BaseComponentProps} from '@esgi/ui';

type Props = BaseComponentProps & ComponentProps<typeof Text>;

export function OneLinedText({dataCy, css = {}, ...textProps}: Props) {
	const [isTextTruncated, setIsTextTruncated] = useState(false);

	const textRef = useRef<HTMLElement>(null);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight}}) => {
		setIsTextTruncated(clientWidth < scrollWidth || clientHeight < scrollHeight);
	});

	return (
		<Tooltip open={!isTextTruncated ? false : undefined}>
			<Tooltip.Trigger>
				<TriggerText ref={textRef} {...textProps} data-cy={dataCy} css={css} />
			</Tooltip.Trigger>
			<TooltipContent>
				<Text size='large' data-cy={dataCy}>{textProps.children}</Text>
			</TooltipContent>
		</Tooltip>
	);
}
