import {Select as SelectUI} from '@esgi/ui/controls';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {SelectProps} from '@radix-ui/react-select';
import {SelectField} from './index.styled';

interface Props extends SelectProps {
	label: string;
	options: {id: number, name: string}[];
	hasAll?: boolean;
	dataCy: string;
}

export function Select(props: Props) {
	const {label, options, hasAll = false, value, onValueChange, dataCy} = props;

	return (
		<Box dataCy={dataCy}>
			<Text data-cy='label'>{label}</Text>
			{options.length > 1 &&
				<SelectUI.Root
					value={value}
					onValueChange={onValueChange}
				>
					<SelectField/>
					<SelectUI.Content>
						{hasAll &&
							<SelectUI.Option value='-1'>
								<Text size='medium' bold>All</Text>
							</SelectUI.Option>
						}
						{options?.map(({id, name}) =>
							<SelectUI.Option
								key={id}
								value={id.toString()}
							>
								<Text size='medium' bold>{name}</Text>
							</SelectUI.Option>
						)}
					</SelectUI.Content>
				</SelectUI.Root>
			}
			{options.length === 1 &&
				<Text size='medium' bold>{options[0].name}</Text>
			}
		</Box>
	);
}
