import React, {useState} from 'react';
import {FormElement, ElementStatus} from '@esgillc/ui-kit/form';
import {Radio} from '@esgillc/ui-kit/form-control';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {DistrictTab} from '../../../../../../types';
import Selector from 'modules/subject-details/components/sections/publish-to/district/selector';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import PublishToTeachers = DistrictTab.PublishToTeachers;
import {DistrictCommonData} from 'modules/subject-details/types';
import styles from '../styles.module.less';

interface Props {
	form: DistrictFormType;
	districtSubjectData: DistrictCommonData;
}

export default function TeachersSubSection({districtSubjectData, form}: Props) {

	const [prevSelected, setPrevSelected] = useState<PublishToTeachers>(PublishToTeachers.All);
	const [selectedSchoolsCount, setSelectedSchoolsCount] = useState<number>(0);
	const [isBySchoolSelectorOpened, setBySchoolSelectorOpened] = useState<boolean>(false);

	useStreamEffect(form.controls.publishToTeacher.onChanged, v => v.reason === 'value' && setPrevSelected(v.prevState.value));

	const teacherBySchoolLabel = (value: PublishToTeachers): string => {
		if (!selectedSchoolsCount || value !== PublishToTeachers.Schools) {
			return 'Teachers By School';
		}
		return `Teachers By School [${selectedSchoolsCount}]`;
	};

	const openTeachersBySchoolSelector = (event: React.MouseEvent<HTMLInputElement>) => {
		const nameStatus = form.status;
		if (nameStatus !== ElementStatus.valid || form.status !== ElementStatus.valid) {

			form.validate().subscribe(r => {
				if (!r.length) {
					setBySchoolSelectorOpened(true);
					form.controls.publishToTeacher.value = PublishToTeachers.Schools;
				}
			});

			event.stopPropagation();
			event.preventDefault();
		} else {
			setBySchoolSelectorOpened(true);
		}
	};

	return <div data-cy={'teachers-subsection'}>
		<strong className={styles.title}>Teachers</strong>
		<FormElement control={form.controls.publishToTeacher}>
			<Radio
				value={PublishToTeachers.All}
				id='publish-to-teachers-all-district-and-school-teachers'
				className={styles.radio}>
				All District and School Teachers [{districtSubjectData.teachersCount}]
			</Radio>
			<Radio
				value={PublishToTeachers.Schools}
				onClick={(e) => openTeachersBySchoolSelector(e)}
				id='publish-to-teachers-teachers-by-school'
				className={styles.radio}
			>{teacherBySchoolLabel(form.controls.publishToTeacher.value)}</Radio>
			<Radio
				value={PublishToTeachers.None}
				id='publish-to-teachers-none'
				className={styles.radio}
			>None</Radio>
		</FormElement>
		{isBySchoolSelectorOpened && <div data-cy={'school-selector'}> <Selector
			isShowSchoolSelector
			districtSubjectData={districtSubjectData}
			subjectTabName={form.controls.subjectName.value}
			initiallySelected={form.controls.schools.value}
			selectedGradeLevels={form.controls.gradeLevels.value.map(v => v.shortName)}
			onCancel={() => {
				setBySchoolSelectorOpened(false);
				form.controls.publishToTeacher.value = prevSelected;
			}}
			onConfirm={(v) => {
				setBySchoolSelectorOpened(false);
				setSelectedSchoolsCount(v.length);
				form.controls.schools.value = v.map(v => v.id);
			}}
		/></div>}
	</div>;
}
