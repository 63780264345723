import {reportTypeList} from '../../model';
import {useAiAnalysisContext} from '../../layout/outlet-context';
import {Drawer} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {CardRoot, CardHeader, CardText} from './index.styled';
import {DrawerContentContainer} from '../../index.styled';

export function ReportSelection() {
	const {
		selectedReportType,
		setSelectedReportType,
		targetReportType,
		setTargetReportType,
	} = useAiAnalysisContext();

	return (
		<DrawerContentContainer>
			<Drawer.ContentBlock title='Report Type' withDivider>
				{reportTypeList.map((reportType) => (
					<CardRoot
						onClick={() => {
							setTargetReportType(reportType);
							if (!selectedReportType) {
								setSelectedReportType(reportType);
							}
						}}
						key={reportType.value}
						selected={
							targetReportType && reportType.value == targetReportType.value
						}
					>
						<CardHeader>
							<Text color='currentColor' size='medium' bold>
								{reportType.label}
							</Text>
							{reportType.icon && <reportType.icon height={18} width={18} />}
						</CardHeader>
						<CardText size='medium' color='neutral40'>
							{reportType.content}
						</CardText>
					</CardRoot>
				))}
			</Drawer.ContentBlock>
		</DrawerContentContainer>
	);
}
