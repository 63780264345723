import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Layout = styled(FlexBox, {
	flexDirection: 'column',
	gap: '20px',
	background: '$neutral96',
	height: '100%',
	width: '100%',
});
