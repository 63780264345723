import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Label, ButtonLabel} from '../../index.styled';
import {ComponentProps} from 'react';
import {FlexBox} from '@esgi/ui/layout';

interface Props {
	onDelete: VoidFunction;
	onCancel: VoidFunction;
	studentFullName: string;
	portalProps?: ComponentProps<typeof Alert>['portalProps']
}

export function DeleteStudentAlert({studentFullName, onDelete, onCancel, portalProps}: Props) {

	const alertRef = Alert.useRef();
	const cancelClicked = Alert.useClose(alertRef, onCancel);

	return <Alert portalProps={portalProps} colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
		<Alert.Header withBacklight withCloseIcon={false} onCloseIconClick={onCancel}>
			<Label size='large'>Delete Student?</Label>
		</Alert.Header>

		<Alert.Body>
			<FlexBox direction='column'>
				<Label size='medium' color='neutral24'>
					{studentFullName} will be removed from your list.
				</Label>
				<Label size='medium' color='neutral24'>If you'd like to add this student back to your
					list, please visit Student Manager to make the appropriate changes.</Label>
			</FlexBox>
		</Alert.Body>

		<Alert.Footer align='center' gapX={3}>
			<Button color='tertiary' onClick={cancelClicked}>
				<ButtonLabel>Cancel</ButtonLabel>
			</Button>
			<Button onClick={onDelete} color='secondary'>
				<ButtonLabel>Delete</ButtonLabel>
			</Button>
		</Alert.Footer>
	</Alert>;
}
