import React from 'react';
import styles from './styles.module.less';
import {DetailReportScores} from './components/scores';
import {DateRange, TestModel} from 'modules/reports/iep-progress/types';
import {DetailReportCharts} from './components/charts';

interface Props {
	test: TestModel;
	dateRange: DateRange;
	markingPeriod?: number;
}

export function TestResults({test, dateRange, markingPeriod}: Props) {
	return <div className={styles.testsRow}>
		<div className={styles.detailContainer} key={test.testID}>
			<div className={styles.detailContainerInner}>
				<div id={`${test.testID}-iep-report`} className={styles.testName}>{test.name}</div>
				<div className={styles.flexRow}>
					<DetailReportScores test={test} markingPeriod={markingPeriod}/>
				</div>
				<DetailReportCharts test={test} dateRange={dateRange}/>
			</div>
		</div>
	</div>;
}
