import {join} from '@esgillc/ui-kit/utils';
import React from 'react';

import styles from './report-filters.module.less';

class Props {
	className?: string;
	children?: any;
}

export function ReportFiltersBody(props: Props) {
	return <div className={join(styles.reportFiltersBody, props.className)}>
		{props.children}
	</div>;
}
