import {SubjectType} from '@esgi/core/enums';

export interface IGradeScale {
	id: number;
	name: string;
	entries: IGradeScaleEntry[];
}

export interface IReportGradeScale {
	id: number;
	name: string;
	ownerID: number;
	type?: GradeScaleType;
	entries: IGradeScaleEntry[];
}

export interface IGradeScaleEntry {
	id: number;
	color: string;
	description: string;
	gradeName: string;
	orderNumber: number;
	parentID?: number;
}

export enum GradeScaleWizardType {
	Personal = 0,
	Stock = 1,
	District = 2,
	Default = 3,
}

export enum GradeScaleType
{
	Teacher = 1,
	School = 2,
	District = 3,
	Stock = 4,
	Default = 5,
}

export enum GradeRangeType {
	Default = 0,
	Custom = 1,
	Shared = 2,
}

export interface IGradeRange {
	id?: number;
	from?: number;
	to?: number;
	gradeScaleEntryID: number;
	markingPeriod?: number;
}

export enum GradeRangeSelectionType {
	SameAllYear = 0,
	DifferentPerPeriod = 1,
}

export interface ITrack {
	trackID: number;
	trackDates: ITrackDate[];
}

export interface ITrackDate {
	from: string;
	to: string;
}

export interface ISubject {
	id: number;
	name: string;
	subjectType: SubjectType;
	subjectLevel: SubjectLevel;
}

export enum FormState {
	None = 0,
	Saving = 1,
	Valid = 2,
	Saved = 3,
	Loading = 4,
	Changing = 5,
	Closed = 6,
	Canceled = 7,
}

export enum SubjectLevel {
	Teacher = 0,
	School,
	District
}