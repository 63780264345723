import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TooltipContainer = styled(GridBox, {
	gap: '$3',
	width: 212,
	padding: 12,
	borderRadius: 8,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$tertiaryMuted',
	backgroundColor: 'rgba(255, 251, 250, 0.80)',
	boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
});

export const Divider = styled(Box, {
	width: '100%',
	height: 1,
	borderRadius: 1,
	background: '$tertiaryMuted',
});

export const AnswersInfoContainer = styled(GridBox, {
	gap: '$2',
	gridTemplateColumns: '1fr auto',
});

export const PercentageCheap = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 2,
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,

	variants: {
		colorType: {
			correct: {
				backgroundColor: '$positive',
			},

			incorrect: {
				backgroundColor: '$lowContrast',
			},

			notTested: {
				backgroundColor: '$vivid',
				borderStyle: 'solid',
				borderWidth: 1,
				borderColor: '$mild',
			},
		},
	},
});
