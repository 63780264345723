import {useCallback} from 'react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {ClassModel} from '../../../types';
import {SelectField} from './index.styled';

type Props = {
	selected: number,
	classes: ClassModel[],
	disabled: boolean,
	skeleton?: boolean,
	onChange: (value: number) => void,
}

export function ClassSelect(props: Props) {
	const onChange = useCallback((value: string) => props.onChange(Number(value)), [props.onChange]);

	return <Select.Root value={props.selected.toString()} onValueChange={onChange} dataCy='class-select'>
		<SelectField placeholder='Class' skeleton={props.skeleton} disabled={props.disabled}/>
		<Select.Content>
			<Select.Option value='0'><Text bold size='medium'>All</Text></Select.Option>
			{props.classes.map((i, index) => <Select.Option value={i.classID.toString()} key={i.classID}>
				<Text bold size='medium'>{i.name}</Text>
			</Select.Option>)}
		</Select.Content>
	</Select.Root>;
}
