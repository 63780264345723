import {lazyComponent} from '@esgi/core/react';

export * from 'root/old-routes';


export const TestExplorer = lazyComponent(() => import('./root/routes/test-explorer'));
export const StudentManager = lazyComponent(() => import('./root/routes/student-manager'));
export const ParentConferencer = lazyComponent(() => import('./root/routes/parent-conferencer'));
export const StudentScreen = lazyComponent(() => import('./root/routes/students-screen'));

export const Renewal = lazyComponent(() => import('./root/routes/renewal'));
export const Login = lazyComponent(() => import('./root/routes/login/login'));
export const ResetPassword = lazyComponent(() => import('./root/routes/reset-password'));
export const SignUp = lazyComponent(() => import('./root/routes/sign-up'));
export const Autoquoter = lazyComponent(() => import('./root/routes/autoquoter'));
export const RepAutoquoter = lazyComponent(() => import('./root/routes/rep-autoquoter'));
export const RequestAQuoteForm = lazyComponent(() => import('./root/routes/request-a-quote-form'));
export const Invitation = lazyComponent(() => import('./root/routes/invitation'));
export const CompleteImport = lazyComponent(() => import('root/routes/import-registration-finish'));
export const CompleteRegistration = lazyComponent(() => import('root/routes/complete-registration'));
export const QuotePayment = lazyComponent(() => import('root/routes/quote-payment'));
export const Agreement = lazyComponent(() => import('root/routes/esgi-agreement'));
export const PrivacyPolicy = lazyComponent(() => import('root/routes/privacy-policy'));
export const CleverRegistration = lazyComponent(() => import('root/routes/clever-registration'));
