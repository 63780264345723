import * as React from 'react';
import type {SVGProps} from 'react';

export function Bold(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M12.5 8A1.5 1.5 0 0 0 11 9.5v13a1.5 1.5 0 0 0 1.5 1.5h4c3.038 0 5.5-2.318 5.5-5.176 0-1.614-.793-3.044-1.97-3.954.636-.731.97-1.617.97-2.635C21 9.896 18.985 8 16.5 8h-4Zm1.5 2.824v2.823h2.5c.828 0 1.5-.632 1.5-1.412 0-.779-.672-1.411-1.5-1.411H14Zm0 5.647v4.705h2.5c1.38 0 2.5-1.053 2.5-2.352 0-1.3-1.12-2.353-2.5-2.353H14Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
