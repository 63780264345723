import React, {ReactNode} from 'react';
import {ModalWindow} from '@esgi/deprecated/knockout';
import {StateStandardsLibrary} from 'shared/modules/state-standards-library';
import {userStorage} from '@esgi/core/authentication';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {DependElement, FormControl} from '@esgillc/ui-kit/form';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {join} from '@esgillc/ui-kit/utils';
import {Item} from '../models';
import styles from '../styles.module.less';

interface Props {
	readonly: boolean;
	stateStandardNames: string;
	control: FormControl<Item>;
}

export class StateStandardField extends React.PureComponent<Props> {

	public render() {
		if (this.props.readonly && !this.props.stateStandardNames) {
			return <></>;
		}

		return <div className={styles.stateStandardField}>
			<div className={join(styles.fieldTitle, styles.hideTablet)}>
				State Standard:
			</div>
			<div className={join(styles.fieldTitle, styles.dTablet)}>
				Standard:
			</div>
			<DependElement control={this.props.control}>
				{state => this.renderContent(state.value)}
			</DependElement>
		</div>;
	}

	private renderContent(fieldValue: Item): ReactNode {
		return <div className={join(styles.fieldContent, styles.stateStandardContainer)}>
			{this.props.readonly ? this.renderText(fieldValue) : this.renderStateStandardButton(fieldValue)}
		</div>;
	}

	private renderText(fieldValue: Item): ReactNode {
		return <span>{fieldValue.name}</span>;
	}

	private renderStateStandardButton(fieldValue: Item): ReactNode {
		return <>
			<OnHoverTooltip message={fieldValue?.name}>
							<span className={styles.stateStandardText}>
							{fieldValue?.name || 'Add State Standard'}
						</span>
			</OnHoverTooltip>
		</>;
	}
}
