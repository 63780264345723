import {TestInfo, TestSession} from '../../../../../../../types';
import {useSessionItems} from './hooks/use-session-items';
import {useSelectedSessions} from './hooks/use-selected-sessions';
import {useSelectionState} from './hooks/use-selection-state';
import {useCallback, useEffect, useState} from 'react';
import {useDiagramLegendItems} from './hooks/use-diagram-legend-items';
import {FullScreenDrawer} from '../../../../full-screen-drawer';
import {noop} from 'underscore';
import {FullScreenContent} from './components/full-screen-content';
import {QuickViewContent} from './components/quick-view-content';
import {SynchronizedStateProps} from './types';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	isTablet: boolean;
};

export function YesNoContent({testSessions, testInfo, isTablet}: Props) {
	const [isOpenFullScreenDiagram, setIsOpenFullScreenDiagram] = useState(false);
	const [diagramPageIndex, setDiagramPageIndex] = useState(0);

	const {
		isAllSessionsSelected,
		selectedSessionIds,
		setSelectedSessionIds,
		isAllSessionsChecked,
		isDetailedViewChecked,
		toggleAllSessionsCheckbox,
		toggleDetailedViewCheckbox,
	} = useSelectionState({
		testSessions,
		initialSelectedTestSessionIDs: testSessions[0] ? [testSessions[0].id] : [],
	});

	useEffect(() => {
		setDiagramPageIndex(0);
	}, [testSessions, isDetailedViewChecked]);

	const selectedSessions = useSelectedSessions({testSessions, selectedSessionIds});
	const sessionItems = useSessionItems({testSessions, testInfo});

	const diagramLegendItems = useDiagramLegendItems({isDetailedViewChecked});

	const onFullScreenButtonClick = useCallback(() => {
		setIsOpenFullScreenDiagram(true);
	}, []);

	const closeFullScreenDiagram = useCallback(() => {
		setIsOpenFullScreenDiagram(false);
	}, []);

	const synchronizedStateProps: SynchronizedStateProps = {
		testInfo,
		diagramLegendItems,
		isAllSessionsChecked,
		isAllSessionsSelected,
		isDetailedViewChecked,
		selectedSessionIds,
		selectedSessions,
		sessionItems,
		setSelectedSessionIds,
		toggleAllSessionsCheckbox,
		toggleDetailedViewCheckbox,
		diagramPageIndex,
		setDiagramPageIndex,
		isTablet,
	};

	return (
		<>
			<QuickViewContent
				isTablet={isTablet}
				onFullScreenButtonClick={onFullScreenButtonClick}
				{...synchronizedStateProps}
			/>

			{isOpenFullScreenDiagram && (
				<FullScreenDrawer
					onClose={closeFullScreenDiagram}
					onDownloadClick={noop}
					title={testInfo.name}
					subTitle='Item Analysis'
				>
					<FullScreenContent {...synchronizedStateProps} />
				</FullScreenDrawer>
			)}
		</>
	);
}
