import {join} from '@esgillc/ui-kit/utils';
import {Row, SortCell, SortModel} from '../../types';
import {ArrowIcon} from '../icons/arrow-icon';
import styles from './styles.module.less';

interface Props {
	rows: Row[],
	sortRows: SortCell[]
	onSortClicked: (item: string) => void
	sortModel: SortModel
}

export function ReportTable({rows, sortRows, onSortClicked, sortModel}: Props) {

	return <table className={styles.table}>
		<thead>
		<tr>
			{sortRows.map((item) => {
				return <th key={item.name} onClick={() => onSortClicked(item.name)}>
					<div className={styles.columnHeader}>
						<div>{item.displayName}</div>
						<ArrowIcon className={join(
							styles.arrow,
							sortModel.fieldName === item.name ? styles.arrowSorted: styles.arrowInactive,
							sortModel.fieldName === item.name && sortModel.direction === 'Desc'
								? styles.arrowUp : styles.arrowDown)}/>
					</div>
				</th>;
			})}
		</tr>
		</thead>
		<tbody>
		{rows.map((row) => {
			return <tr key={row.id}>
				<td>
					<div>{row.schoolName}</div>
				</td>
				<td>
					<span>{row.firstName} {row.lastName}</span>
				</td>
				<td>{row.expDateStr}</td>
				<td>{row.lastLoginDateStr}</td>
				<td>{row.loginCount}</td>
				<td>{row.testsAuthored}</td>
				<td>{row.testsAdministered}</td>
				<td>{row.testsCompleted}</td>
				<td>{row.questionsAsked}</td>
				<td>{row.studentsEnrolled}</td>
				<td>{row.studentsTested}</td>
			</tr>;
		})}
		</tbody>
	</table>;
}
