import {FormField, Validators} from '@esgi/deprecated/knockout';
import {FieldContainer} from '../../../kit/component';
import {InfoModel} from '../../models';

export class TrackSelect extends FieldContainer<InfoModel> {
	private declare tracks: Array<InfoModel>;

	buildField(initValue): FormField<InfoModel> {
		this.tracks = initValue;
		return this.createField<InfoModel>(initValue[0], Validators.Required());
	}

	template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<select id='tracks' name='tracks' className='form-control'
					        data-bind="options: root.tracks, optionsText: 'name', value: value, valueAllowUnset: true"/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
				</div>
			</div>
		</div>;
	};
}
