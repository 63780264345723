export enum SpecialProgram
{
    GiftedTalented = 'GiftedTalented',
    SPED = 'SPED',
    RTI = 'RTI',
    ELLESL = 'ELLESL',
    FiveOuFour = 'FiveOuFour',
}

export const getName = (specialProgram: SpecialProgram) => {
	switch(specialProgram){
		case SpecialProgram.GiftedTalented: return 'Gifted/Talented';
		case SpecialProgram.SPED: return 'SPED';
		case SpecialProgram.RTI: return 'RTI';
		case SpecialProgram.ELLESL: return 'ELL/ESL';
		case SpecialProgram.FiveOuFour: return '504';
	}
};

export const specialProgramNames = () => {
	return [
		{id: SpecialProgram.GiftedTalented, name: getName(SpecialProgram.GiftedTalented)},
		{id: SpecialProgram.SPED, name: getName(SpecialProgram.SPED)},
		{id: SpecialProgram.RTI, name: getName(SpecialProgram.RTI)},
		{id: SpecialProgram.ELLESL, name: getName(SpecialProgram.ELLESL)},
		{id: SpecialProgram.FiveOuFour, name: getName(SpecialProgram.FiveOuFour)},
	];
};
