import {SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ExpandableRearrangeableItem = styled(SelectableList.ItemDraggable, {
	minHeight: 40,
	padding: 6,
	borderRadius: 6,
	color: '$neutral40',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	paddingLeft: 12,

	'& > svg': {
		marginTop: 6,
	},

	'& > button': {
		marginTop: 2,
	},

	variants: {
		selected: {
			true: {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
		},
	},
});
