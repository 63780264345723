import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const FilterContainer = styled(GridBox, {
	gridTemplateColumns: 'repeat(3, 1fr)',
	gap: 6,
	alignItems: 'center',
	paddingTop: 8,
	paddingBottom: 8,
});
