import {dispatchAppEvent} from '@esgillc/events';
import React, {RefObject} from 'react';
import {RoutesMap} from '@esgi/main/libs/core';
import {HttpClient} from '@esgi/api';
import {EsgiLetters} from '../../esgi-letters/component';
import {GetStarted} from '../../modules/get-started/get-started';
import {userStorage, UserType} from '@esgi/core/authentication';
import {NavLink} from 'react-router-dom';
import {HeaderModels} from './models';
import {join} from '@esgillc/ui-kit/utils';
import './header.less';

import styles from './header.module.less';
import {ArrowRightIcon} from '@esgillc/ui-kit/icons';
import {
	ArrowLeftIcon,
} from 'pages/assignment-center/components/student-credentials/kit/icons/components/arrow-left-icon';
import {fromEvent} from 'rxjs';
import {debounce} from 'underscore';

export class State {
	bgColor: string = '#FFF';
	canScrollRight: boolean = false;
	canScrollLeft: boolean = false;
}

export class Props {
	hamburgerRef?: RefObject<HTMLButtonElement>;
}

export class Header extends React.PureComponent<Props, State> {
	private container: HTMLElement;
	private readonly user = userStorage.get();
	private refStudentManager: React.RefObject<HTMLAnchorElement> = React.createRef();

	constructor(props, context) {
		super(props, context);
		this.state = new State();
	}

	private init = () => {
		if (window.location.hostname === 'esgisoftware.com') {
			return;
		}

		HttpClient.default.ESGIApi.get<HeaderModels.BackgroundResponseModel>('pages/settings', 'get-header-bg-color')
			.subscribe((r) => {
				this.setState({bgColor: r.backgroundColor});
			});
	};

	componentDidMount() {
		this.init();
		this.recalculateScrollPosition();
		fromEvent(window, 'resize').subscribe(this.windowResized);
		this.container?.addEventListener('scroll', debounce(this.recalculateScrollPosition.bind(this), 100));
	}

	private windowResized = () => {
		this.recalculateScrollPosition();
	};

	private getNextHiddenItem() {
		const trays = this.container?.children;
		for (let i = 0; i < trays.length; i++) {
			const tray = trays[i] as HTMLElement;
			if (tray.offsetLeft + tray.offsetWidth > this.container?.offsetWidth) {
				return tray;
			}
		}
	}


	private scrollForward() {
		this.scrollTo(this.getNextHiddenItem().offsetLeft);
	}

	private getPreviousHiddenItem() {
		const trays = this.container?.children;
		for (let i = trays.length - 1; i >= 0; i--) {
			const tray = trays[i] as HTMLElement;
			if (tray.offsetLeft < this.container?.scrollLeft + this.container?.offsetLeft) {
				return tray;
			}
		}
	}

	private scrollBackward() {
		const offsetLeft = this.getPreviousHiddenItem()?.offsetLeft;
		if (offsetLeft && offsetLeft >= this.container?.offsetLeft) {
			this.scrollTo(this.container?.offsetLeft - offsetLeft);
		}
	}

	private recalculateScrollPosition() {
		const scrollWidth = this.container?.scrollWidth;
		const offsetWidth = this.container?.offsetWidth; // minus padding
		const canScrollLeft = this.container?.scrollLeft > 0;
		const canScrollRight = scrollWidth > offsetWidth && Math.round(this.container?.scrollLeft) < Math.round(scrollWidth - offsetWidth);

		if (canScrollLeft !== this.state.canScrollLeft) {
			this.setState({canScrollLeft: canScrollLeft});
		}

		if (canScrollRight !== this.state.canScrollRight) {
			this.setState({canScrollRight: canScrollRight});
		}
	}


	private scrollTo(scrollLeft: number) {
		if (scrollLeft < 0) {
			scrollLeft = 0;
		}

		$(this.container).animate({scrollLeft: scrollLeft}, 400);
	}


	private get homeTitle() {
		return (this.user.userType === UserType.C || this.user.userType === UserType.D) && 'Data' || 'Home';
	}

	private next() {
		this.scrollForward();
	}

	private back() {
		this.scrollBackward();
	}

	private renderMenu(): JSX.Element {
		return <>
			{this.state.canScrollLeft && <ArrowLeftIcon onClick={() => this.back()} className={styles.arrowIconLeft}/>}
			<div className={join('menu', styles.menu)} ref={(r) => this.container = r}>
				{(this.user.userType === UserType.C || this.user.userType === UserType.D) && this.renderAdminHome() || null}
				{this.renderHome()}
				{this.renderTestExplorer()}
				{this.user.canExploreStudents && this.renderStudentManager() || null}
				{(this.user.userType === UserType.ISD || this.user.userType === UserType.ISS
						|| this.user.userType === UserType.PA
					) &&
					<GetStarted
						pointToRef={this.refStudentManager}
						left={10}
						arrowLeft={25}
						arrowTop={-7}
						top={40}
					/>
				}
				{this.user.userType === UserType.T && this.renderParentConferencer() || null}
				{[UserType.T, UserType.ISD, UserType.ISS].includes(this.user.userType) && this.renderAssignmentCenter()}
			</div>
			{this.state.canScrollRight &&
				<ArrowRightIcon onClick={() => this.next()} className={styles.arrowIconRight}/>}
		</>;
	}

	private renderAdminHome = (): JSX.Element => {
		return <NavLink to={RoutesMap.adminHome} data-cy-route-trigger='adminHome'
		                className={({isActive}) => join('menu-item menu-item-admin-home-link', isActive && 'selected')}>
			Home
		</NavLink>;
	};

	private renderHome = (): JSX.Element => {
		return (
			<NavLink
				to={RoutesMap.home}
				end
				data-cy-route-trigger='home'
				className={({isActive}) => join('menu-item menu-item-home-link', isActive && 'selected')}
				onClick={() => dispatchAppEvent(HomePageClickedEvent)}
			>
				{this.homeTitle}
			</NavLink>
		);
	};

	private renderStudentManager = (): JSX.Element => {
		return <NavLink to={RoutesMap.studentManager} data-cy-route-trigger='studentManager'
		                className={({isActive}) => join('menu-item menu-item-student-manager-link', isActive && 'selected')}>
			Student Manager
		</NavLink>;
	};

	private renderParentConferencer = (): JSX.Element => {
		return <NavLink to={RoutesMap.parentConferencer} data-cy-route-trigger='parentConferencer'
		                className={({isActive}) => join('menu-item menu-item-parent-conferencer-link', isActive && 'selected')}>
			Parent Conferencer
		</NavLink>;

	};

	private renderTestExplorer = (): JSX.Element => {
		return <NavLink to={RoutesMap.testExplorer} data-cy-route-trigger='testExplorer'
		                className={({isActive}) => join('menu-item menu-item-test-explorer-link', isActive && 'selected')}>
			Test Explorer
		</NavLink>;
	};

	private renderAssignmentCenter = (): JSX.Element => {
		return <NavLink to={RoutesMap.assignmentCenter} data-cy-route-trigger='assignmentCenter'
		                className={({isActive}) => join('menu-item menu-item-admin-home-link', isActive && 'selected')}>
			Assignments
		</NavLink>;
	};

	render() {
		return <div className='navigation' style={{backgroundColor: this.state.bgColor}}>
			<div className='navigation-inner'>
				<div className='left'>
					<button className={join('hamburger', styles.hamburgerButton)} ref={this.props.hamburgerRef}
					        onClick={(e) => {
						        dispatchAppEvent(HamburgerClickedEvent);
					        }}>
						<i className='fa fa-reorder'/>
					</button>
					<NavLink to={RoutesMap.home} className='esgi-logo top-navigation-home-link'>
						<svg width='100' height='45' viewBox='0 0 76 34' fill='none'
						     xmlns='http://www.w3.org/2000/svg'>
							{EsgiLetters.Letters.map(l => l.path)}
						</svg>
					</NavLink>
				</div>
				{this.renderMenu()}
			</div>
		</div>;
	}
}

export class HamburgerClickedEvent {}

export class HomePageClickedEvent {}
