import {Class, Student} from '@esgi/main/libs/store';
import {TestSession} from '../types';
import {TestLauncherData} from '@esgi/main/features/assessments';

export type StudentModel = {
	id: number;
	firstName: string;
	lastName: string;
};

export enum StudentSpecialProgram {
	GiftedTalented = 'GiftedTalented',
	SPED = 'SPED',
	RTI = 'RTI',
	ELLESL = 'ELLESL',
	FiveOuFour = 'FiveOuFour',
}

export type StudentInfo = StudentModel & {
	studentSpecialPrograms: StudentSpecialProgram[];
	testSessionsInfo: {
		testSessions: TestSession[];
	};
};

export enum StudentSort {
	FirstName = 'FirstName',
	LastName = 'LastName',
}

export type SectionData = {
	selectedStudentsIDs: Student['id'][];
	sectionName: string;
};

export type TestLauncherState = {
	studentClass: Class;
	student: Student;
	data: TestLauncherData;
};

export type OpenTestingStudentParams = {
	studentID: Student['id'];
	lastTestSession: TestSession | null;
	studentSessionsCount: number;
	penultimateTestSession: TestSession | null;
	averageStudentSessionsDuration: number | null;
};
