import {PropsWithChildren} from 'react';
import {LabelStyled, ReportHeaderStyled, TextStyled} from './index.styled';

type ReportHeaderFieldProps = PropsWithChildren<{
	title?: string;
	value?: string | undefined;
}>;

export function ReportHeaderField({children, title, value}: ReportHeaderFieldProps) {
	return (
		<ReportHeaderStyled dataCy='header-field'>
			{children ?? (
				<>
					<LabelStyled data-cy='label'>{title}:</LabelStyled>
					<TextStyled data-cy='field-value'>{value}</TextStyled>
				</>
			)}
		</ReportHeaderStyled>
	);
}
