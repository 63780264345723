import {CorrectAnswer} from '@esgi/ui/icons';
import {IconBox} from './index.styled';

export function CorrectAnswerIcon() {
	return (
		<IconBox dataCy='correct-answer-icon'>
			<CorrectAnswer />
		</IconBox>
	);
}
