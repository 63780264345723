import React from 'react';
import {Modal} from '@esgillc/ui-kit/modal';
import {ResultsService} from '../../../services/results-service/results-service';
import {ResultsForm} from './components/results-form';
import styles from './styles.module.less';

interface Props {
	service: ResultsService
}

export function ResultsBody({service}: Props) {
	return <Modal.Body className={styles.modalBody}>
		<ResultsForm service={service} />
	</Modal.Body>;
}
