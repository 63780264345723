import {Users} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {CounterBox} from '@esgi/ui/layout';
import {useStudentsRootContext} from '../students-root/context';
import {StyledIconWrapper, StyledCounter} from './index.styled';
import { Tooltip } from '@esgi/ui';

export function StudentsCounter({title}:{title: string}) {
	const {selectedStudentIDs} = useStudentsRootContext();

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<StyledCounter align='center'>
					<StyledIconWrapper>
						<Users/>
					</StyledIconWrapper>
					<CounterBox>
						<Text data-cy='student-counter' font='mono' size='small'>{selectedStudentIDs.length}</Text>
					</CounterBox>
					<Text size='small'>Students</Text>
				</StyledCounter>
			</Tooltip.Trigger>
			<Tooltip.Content variant='secondary'>
				<Text size='small'>
					{`${selectedStudentIDs.length} students will receive ${title} for the selected tests`}
				</Text>
			</Tooltip.Content>
		</Tooltip>
		
	);
}
