import {useEffect, useState} from 'react';
import {Drawer} from '@esgi/main/kits/common';
import {Box, FlexBox} from '@esgi/ui/layout';
import {useService} from '@esgi/core/service';
import {Form} from '@esgi/ui/form';
import {Enter, Prev, useBehaviorSubject, useStreamEffect} from '@esgi/ui';
import {KeyboardShortcutsService} from './service';
import {SingleCheckboxSection} from '../../../../single-checkbox-section';
import {Text} from '@esgi/ui/typography';
import {ContentWrapper, PanelContent} from '../../../index.styled';
import {KeyboardButton, TestShortcutsInfo} from './index.styled';
import {OutModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/teacher/init/out-model';
import {userStorage} from '@esgi/core/authentication';
import {useKeyboardShortcutsDescription} from '../../../../../use-keyboard-shortcuts-description';
import {SettingsTab} from '../../../../../types';

interface Props {
	isKeyboardShortcuts: boolean;
	onUpdateInitData: (props: Partial<OutModel>) => void;
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData: (tab: SettingsTab, isTouched: boolean) => void;
}

export function TestingKeyboardShortcuts({isKeyboardShortcuts, onUpdateInitData, onCloseDrawer, onUpdateTabTouchedData}: Props) {

	const service = useService(KeyboardShortcutsService);
	const isKeyboardShortcutsEnabled = useBehaviorSubject(service.isKeyboardShortcutsEnabled);
	const [isDisableButton, setIsDisableButton] = useState(true);

	useEffect(() => {
		service.init(isKeyboardShortcuts);
	}, []);

	useStreamEffect(service.form.onChanged, () => {
		const isTouched = service.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.KeyboardShortcuts, isTouched);
	});

	const onSave = () => {
		setIsDisableButton(true);
		service.save().subscribe({error: () => setIsDisableButton(false), next: ()=>{
			const changedData = {enableShortcuts: service.isKeyboardShortcutsEnabled.getValue()};

			userStorage.update(changedData);
			onUpdateInitData(changedData);
			service.initialIsKeyboardShortcutsEnabled.next(changedData.enableShortcuts);
			onUpdateTabTouchedData(SettingsTab.KeyboardShortcuts, false);
		}});
	};

	const keyboardShortcutsDescription = useKeyboardShortcutsDescription();

	return <Form data-cy='keyboard-shortcuts-root' controller={service.form}>
		<Drawer.PanelHeader
			data-cy='keyboard-shortcuts-header'
			withActionButton
			actionButtonText='Save Changes'
			onActionButtonClick={onSave}
			actionButtonDisabled={isDisableButton}
			onClose={onCloseDrawer}
		>
			<Text data-cy='keyboard-shortcuts-label' size='large' color='neutral24' bold>Keyboard Shortcuts</Text>
		</Drawer.PanelHeader>

		<PanelContent data-cy='keyboard-shortcuts-content'>
			<ContentWrapper>
				<Drawer.ContentBlock title='Keyboard Shortcuts' withDivider>
					<SingleCheckboxSection
						checkboxControl={service.form.controls.isKeyboardShortcuts}
						isDisabled={!isKeyboardShortcutsEnabled}
						description={keyboardShortcutsDescription}
						dataCy='keyboard-shortcuts-checkbox'
					/>

					<FlexBox direction='column'>
						<TestShortcutsInfo data-cy='keyboard-shortcuts-correct-row' size='small' color='neutral40'>
							Press
							<KeyboardButton>
								<Text size='large' font='mono' bold>Y</Text>
							</KeyboardButton>
							or
							<KeyboardButton>
								<Enter/>
							</KeyboardButton>
							<Box>
								<Text size='small' color='neutral40'>if the student answers </Text>
								<Text size='small' color='positive'>correctly</Text>
							</Box>
						</TestShortcutsInfo>

						<TestShortcutsInfo data-cy='keyboard-shortcuts-incorrect-row' size='small' color='neutral40'>
							Press
							<KeyboardButton>
							<Text size='large' font='mono' bold>N</Text>
							</KeyboardButton>
							or
							<KeyboardButton>
								<Prev/>
							</KeyboardButton>
							<Box>
								<Text size='small' color='neutral40'>if the student answers </Text>
								<Text size='small' color='negative'>incorrectly</Text>
							</Box>
						</TestShortcutsInfo>
					</FlexBox>

				</Drawer.ContentBlock>
			</ContentWrapper>
		</PanelContent>
	</Form>;
}



