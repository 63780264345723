import {deepEqual} from 'shared/utils';
import {Observable, Subject} from 'rxjs';
import {SubjectType} from '@esgi/core/enums';
import {BaseService} from '@esgi/core/service';
import {TestsSortBy} from '../enums';
import {FilterModel} from '../models/filter-model';

export class FilterData extends FilterModel {
	touched: boolean = false;
	subjectType: SubjectType = null;
}

export default class FilterService extends BaseService{
	private readonly filterDataSubject: Subject<FilterData> = new Subject<FilterData>();
	private _currentValue: FilterData;

	constructor() {
		super();
		this.clear();
	}

	public get currentValue(): Readonly<FilterData> {
		return this._currentValue;
	}

	public update<K extends keyof FilterData>(value?: (Pick<FilterData, K> | FilterData)): void {
		const filterModel = {...this._currentValue, ...value};
		const newValue = {
			...filterModel,
			scope: filterModel.scope || 'AllTests',
			notedSeries: filterModel.notedSeries ? {
				id: filterModel.notedSeries.id,
				name: filterModel.notedSeries.name,
			} : null,
			sortBy: filterModel.keyword ? TestsSortBy.None : filterModel.sortBy,
			source: 'ES',
		  };

		if(!deepEqual(newValue, this.currentValue)) {
			newValue.touched = true;
			this._currentValue = newValue;
			this.filterDataSubject.next(newValue);
		}
	}

	public get onChanged$(): Observable<FilterData> {
		return this.filterDataSubject;
	}

	public clear() {
		this._currentValue = new FilterData();
		this.filterDataSubject.next(this._currentValue);
	}

	public asObservable(): Observable<FilterData> {
		return this.filterDataSubject.asObservable();
	}
}
