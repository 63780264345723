import {HttpClient} from '@esgi/api';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {SubjectCreatedEvent} from 'api/entities/events/subject';
import React, {createRef} from 'react';
import {SubjectEntity} from 'api/entities';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import {SubjectType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import SubjectDetails from 'modules/subject-details';
import {EventBusManager} from '@esgillc/events';
import './move-test.less';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {finalize} from 'rxjs/operators';

class State {
	selectedSubjectID: number;
	subjectCreatorOpened: boolean = false;
	availableSubjects: SubjectResponseModel[];
	loading: boolean = false;
	isSaving: boolean = false;
}

class Props {
	testId: number;
	testName: string;
	close: () => any;
	currentSubjectID: number;
	currentSubjectName: string;
	currentSubjectType: SubjectType;
	hierarchy: HierarchySnapshot;
}

interface SubjectResponseModel {
	name: string,
	id: number,
	available: boolean,
  isHidden: boolean,
}

export default class MoveTestToSubject extends React.Component<Props, State> {
	private modalManagerRef: ModalManagerRefObject = createRef();
	private eventBus: EventBusManager = new EventBusManager();
	public state = new State();

	render() {
		return <Modal className='white-header move-test-to-subject-modal' modalManagerRef={this.modalManagerRef}>
			<Modal.Header>
				<div className='modal-title'>
					<span>Move <span className='test-name'>{this.props.testName}</span> to a Subject Tab</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='move-test-to-subject'>
					<select className='form-control' value={this.state.selectedSubjectID || ''}
					        disabled={this.state.loading}
					        onChange={e => this.setState({selectedSubjectID: parseInt(e.target.value) || null})}>
						{(this.props.currentSubjectID && this.props.currentSubjectID)
							? <option value={this.props.currentSubjectID}>{this.props.currentSubjectName}</option>
							: <option value=''>Select a subject tab</option>
						}
						{this.state.availableSubjects && this.state.availableSubjects.map(s => {
							return <option value={s.id} key={s.id}>{s.name}</option>;
						})}
					</select>
					<a href='#' className='create-subject-link' onClick={() => this.openCreateNewSubject()}>
						Create new subject tab
					</a>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => this.closeModal()}
					title='Cancel'
				/>
				<Primary
					onClick={() => this.save()}
					title='Save'
					disabled={!this.state.selectedSubjectID || this.state.loading || this.state.isSaving}
				/>
			</Modal.Footer>
			{this.state.subjectCreatorOpened &&
			<SubjectDetails selectAfterSave={false}
							onClosed={() => this.setState({subjectCreatorOpened: false})}
							onSaved={(subjectId) => this.subjectCreated(subjectId)}
			/>}
		</Modal>;
	}

	componentDidMount() {
		this.setState({selectedSubjectID: this.props.currentSubjectID, loading: true});
		this.eventBus.subscribe(SubjectCreatedEvent, (args: SubjectCreatedEvent) => {
			const newSubject: SubjectResponseModel = {
				available: args.published,
				id: args.id,
				name: args.properties.name,
				isHidden: false,
			};
			const availableSubjects = this.state.availableSubjects;
			this.setState({
				availableSubjects: availableSubjects.concat([newSubject]),
				selectedSubjectID: newSubject.id,
			});
		});
		this.init();
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	init() {
		return HttpClient.default.ESGIApi.get<{ subjects: SubjectResponseModel[] }>('assets/subjects/personal', 'test/move/init', {
			testID: this.props.testId,
			fullHierarchy: this.props.hierarchy,
		}).toPromise().then((r) => {
			this.setState({availableSubjects: r.subjects.filter(x => !x.isHidden), loading: false});
		});
	}

	private save() {
		if (this.state.selectedSubjectID) {

			if (this.state.selectedSubjectID === this.props.currentSubjectID) {
				this.closeModal();
			} else {
				this.setState({isSaving: true});
				SubjectEntity.moveTestToSubject(this.props.testId, this.props.currentSubjectID, this.state.selectedSubjectID, this.props.currentSubjectType)
					.pipe(finalize(() => {
						this.setState({isSaving: false}, () => this.closeModal());
					}))
					.subscribe({
						next: () => {
							showSnackbarNotification(`You've moved ${this.props.testName} to ${this.state.availableSubjects.find(i => i.id === this.state.selectedSubjectID).name}`);
						},
					});
			}
		}
	}
	private closeModal() {
		this.modalManagerRef.current.close(this.props.close);
	}

	private openCreateNewSubject() {
		this.setState({subjectCreatorOpened: true});
	}

	private subjectCreated(subjectId: number) {
		this.setState({selectedSubjectID: subjectId, subjectCreatorOpened: false});
	}
}
