import {BasicPrintSetting} from './components/base-print-settings/types';

export enum NameFormat {
	FirstName,
	FirstAndLastName
}

export enum TemplateType {
	NameWriting = 1,
	SightWords = 2,
}

export interface EntityModel {
	id: number;
	name: string;
}

export class OptionModel<T> {
	key: T;
	value: string;

	constructor(key: T, value: string) {
		this.key = key;
		this.value = value;
	}
}

export interface LecturerModel {
	firstName: string;
	lastName: string;
	title: string;
}

export interface SightWordsTestQuestion {
	questionID: number;
	name: string;
	orderNum: number;
	pregenerated: boolean;
}

export enum FontFamily {
	Normal,
	Lined,
	DottedLined,
	Blank
}

export class PdfDownloadResult {
	public isSuccess: boolean;
	public data?: Blob;
	public error?: any;

	private constructor(isSuccess: boolean, error?: string, data?: Blob) {
		this.isSuccess = isSuccess;
		this.data = data;
		this.error = error;
	}

	public static success(data: Blob): PdfDownloadResult {
		return new PdfDownloadResult(true, null, data);
	}

	public static error(error: any): PdfDownloadResult {
		return new PdfDownloadResult(false, error, null);
	}
}

export class Rectangle {
	width: number;
	height: number;

	constructor(width: number, height: number) {
		this.width = width;
		this.height = height;
	}
}

export interface FontSizeCalculationResult {
	fontSize: number;
	width: number;
	height: number;
}

export interface StudentResponse extends EntityModel {
	id: number;
	firstName: string;
	lastName: string;
	classes: number[];
	groups: number[];
	specialistGroups: number[];
}

export class StudentWithUnits {
	public studentId: number;
	public firstName: string;
	public lastName: string;
	public units: EntityModel[];

	constructor(student: StudentResponse, units: EntityModel[]) {
		this.studentId = student.id;
		this.firstName = student.firstName;
		this.lastName = student.lastName;
		this.units = units;
	}
}

export enum UnitType {
	Class,
	Group,
}

export interface StudentStepState {
	classId?: number;
	groupId?: number;
	unitType: UnitType;
	students?: StudentWithUnits[];
	lecturer: LecturerModel;
}

export class NamingWritingPrintSettings implements BasicPrintSetting {
	constructor(public nameFormat: NameFormat = NameFormat.FirstName,
				public selectedFontSize: OptionModel<string> = new OptionModel('30pt', '30 pts'),
				public copiesCount: number = 1,
				public fontFamily: FontFamily = FontFamily.DottedLined,
				public wordsPerPage: number = 14) {
	}
}

export class SightWordsPrintSettings implements BasicPrintSetting {
	constructor(
		public selectedFontSize: OptionModel<string> = new OptionModel('30pt', '30 pts'),
		public copiesCount: number = 1,
		public columnsStyle: FontFamily[] = [FontFamily.Lined, FontFamily.DottedLined, FontFamily.Blank],
		public allQuestions: SightWordsTestQuestion[] = [],
		public selectedQuestions: SightWordsTestQuestion[] = null,
		public wordsPerPage: number = 14,
	) {
	}
}