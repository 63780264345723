import {AppEvent} from '@esgillc/events';

export function RemoveClickedEvent() {

}

export function SaveClickedEvent() {

}

export function SaveAndAddClickedEvent() {

}

@AppEvent({broadcast: true})
export class SpecialistGroupCreatedEvent {
	constructor(public groupId: number,
	            public name: string,
	            public userID: number) {
	}
}

@AppEvent({broadcast: true})
export class SpecialistGroupChangedEvent {
	constructor(public groupId: number,
	            public name: string) {
	}
}

@AppEvent({broadcast: true})
export class SpecialistGroupRemoveEvent {
	constructor(public id: number) {
	}
}
