import {styled} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';

export const SelectField = styled(Select.Field, {
	textAlign: 'left',
	'& > :first-child': {
		width: '150px',
		overflow: 'hidden',

		'span:last-child': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
});
