import {FileType, RequestModels, ResponseModels} from 'shared/modules/reports/flash-cards/settings-form/api/models';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {HttpClient} from '@esgi/api';

export class Api {
	private controllerName = 'reports/flash-cards';

	downloadFile(reportGuid: string, type: FileType, zip: boolean, callBack: () => void) {
		let url = '/' + this.controllerName;

		let date = new Date();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		let extension = zip ? ".zip" : this.downloadParameters[type].Extension;
		
		let filename = 'Flash_Cards_' + year + '-' + month + '-' + day + extension;

		HttpClient.default.ESGIApi.file(url, this.downloadParameters[type].Method, filename, {
			reportGuid: reportGuid,
			zip: zip,
		})
			.subscribe(
				() => callBack && callBack(),
				() => OldAlerts.bsalert('Uh oh. Something went wrong on our end. Please contact Customer Support (support@esgisoftware.com) for assistance.'),
			);
	}

	initResultsForm(requestModel: RequestModels.Results.Init) {
		return HttpClient.default.ESGIApi.post<ResponseModels.Results.Init>(this.controllerName, 'Results', requestModel);
	}

	private downloadParameters = {
		[FileType.PDF]: {
			Method: 'DownloadPdf',
			Extension: '.pdf',
			ContentType: 'application/pdf',
		},
		[FileType.Word]: {
			Method: 'DownloadWord',
			Extension: '.docx',
			ContentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		}
	}
}
