import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo, useState} from 'react';
import {BaseComponentProps, Skeletonable, ThemeColorKeys} from '../../../types';
import {CSS} from '@stitches/react';
import {SkeletonCircle, Svg} from './index.styled';
import {InformationScaleDot, InformationScaleDotCircle, InformationScaleDotEllipse} from './types';
import {config, useTransition, animated} from 'react-spring';
import {getThemeColor} from '../../../utils';
import {InformationScaleContext, InformationScaleContextValue} from '../../context';
import {Point} from '../../types';

type InformationScaleProps = Omit<BaseComponentProps, 'css'> &
	Skeletonable &
	ComponentPropsWithoutRef<'svg'> & {
		/**
		 * Returns a Style interface from a configuration, leveraging the given media and style map.
		 * @default {}
		 */
		css?: Omit<CSS, 'width' | 'height'>;

		/**
		 * Set width and height for the main circle in svg.
		 * @default 92
		 */
		size?: number;

		/**
		 * Set color for the dot scales.
		 * @default 'muted'
		 */
		nonActiveDotColor?: ThemeColorKeys;

		/**
		 * Set color for the elipse dot scales.
		 * @default 'lowContrast'
		 */
		nonActiveElipseColor?: ThemeColorKeys;
	};

export const InformationScaleRoot = forwardRef<SVGSVGElement, InformationScaleProps>(
	(
		{
			dataCy = 'ui-kit-information-scale-root',
			css = {},
			size = 92,
			skeleton,
			nonActiveDotColor = 'muted',
			nonActiveElipseColor = 'lowContrast',
			children,
			...svgProps
		},
		forwardedRef,
	) => {
		const [startAngleForRenderDots, setStartAngleForRenderDots] = useState(0);
		const [scaleRadius, setScaleRadius] = useState(0);

		const svgValues = useMemo(() => {
			const dotCircleRadius = size / 92;
			const dotElipseWideRadius = dotCircleRadius * 2;

			const svgCenter = {
				x: size / 2,
				y: size / 2,
			};

			const scaleRadius = (size - dotElipseWideRadius * 2) / 2;
			setScaleRadius(scaleRadius);

			return {
				size,
				center: svgCenter,
				scale: {
					center: svgCenter,
					dotCircleRadius,
					dotElipseWideRadius,
				},
			};
		}, [size]);

		const getCoordinatesByAngle = useCallback(
			(angle: number): Point => {
				const angleRadian = ((angle - 90) * Math.PI) / 180;

				return {
					x: svgValues.scale.center.x + scaleRadius * Math.cos(angleRadian),
					y: svgValues.scale.center.y + scaleRadius * Math.sin(angleRadian),
				};
			},
			[svgValues, scaleRadius],
		);

		const informationScaleDots = useMemo(() => {
			const dots: InformationScaleDot[] = [];

			const degreeBetweenDots = 18;

			const startAngle = Math.ceil(startAngleForRenderDots / 18) * 18;

			for (let currentAngle = startAngle; currentAngle < 360; currentAngle += degreeBetweenDots) {
				const {x, y} = getCoordinatesByAngle(currentAngle);

				if (currentAngle % 90 === 0) {
					const isHorizontal = currentAngle === 0 || currentAngle === 180;

					const ellipseDot: InformationScaleDotEllipse = {
						type: 'ellipse',
						cx: x,
						cy: y,
						rx: isHorizontal ? svgValues.scale.dotCircleRadius : svgValues.scale.dotElipseWideRadius,
						ry: isHorizontal ? svgValues.scale.dotElipseWideRadius : svgValues.scale.dotCircleRadius,
						color: nonActiveElipseColor,
					};

					dots.push(ellipseDot);

					continue;
				}

				const circleDot: InformationScaleDotCircle = {
					type: 'circle',
					x,
					y,
					radius: svgValues.scale.dotCircleRadius,
					color: nonActiveDotColor,
				};

				dots.push(circleDot);
			}

			return dots;
		}, [nonActiveDotColor, nonActiveElipseColor, svgValues, getCoordinatesByAngle, startAngleForRenderDots]);

		const informationScaleAnimatedDots = useTransition(informationScaleDots, {
			from: {
				opacity: 0,
			},
			enter: {
				opacity: 1,
			},
			trail: 300 / informationScaleDots.length,
			config: config.molasses,
		});

		const contextValue = useMemo<InformationScaleContextValue>(
			() => ({
				setStartAngleForRenderDots,
				scaleRadius,
				setScaleRadius,
				getCoordinatesByAngle,
				dotElipseWideRadius: svgValues.scale.dotElipseWideRadius,
				svgSize: svgValues.size,
				dotCircleRadius: svgValues.scale.dotCircleRadius,
			}),
			[
				getCoordinatesByAngle,
				scaleRadius,
				svgValues.scale.dotCircleRadius,
				svgValues.scale.dotElipseWideRadius,
				svgValues.size,
			],
		);

		return (
			<InformationScaleContext.Provider value={contextValue}>
				<Svg
					data-cy={dataCy}
					xmlns='http://www.w3.org/2000/svg'
					viewBox={`0 0 ${svgValues.size} ${svgValues.size}`}
					css={{
						width: svgValues.size,
						height: svgValues.size,
						...css,
					}}
					ref={forwardedRef}
					{...svgProps}
				>
					{skeleton ? (
						<SkeletonCircle
							cx={svgValues.center.x}
							cy={svgValues.center.y}
							r={(svgValues.size - svgValues.scale.dotCircleRadius) / 2}
							strokeWidth={svgValues.scale.dotCircleRadius}
						/>
					) : (
						<>
							{informationScaleAnimatedDots((style, item) => {
								if (item.type === 'circle') {
									const {x, y, radius, color} = item;

									return <animated.circle style={style} cx={x} cy={y} r={radius} fill={getThemeColor(color)} />;
								}

								const {cx, cy, rx, ry, color} = item;

								return <animated.ellipse style={style} cx={cx} cy={cy} rx={rx} ry={ry} fill={getThemeColor(color)} />;
							})}

							{children}
						</>
					)}
				</Svg>
			</InformationScaleContext.Provider>
		);
	},
);
