import {useService} from '@esgi/core/service';
import {DragDropRoot} from '@esgillc/ui-kit/drag-n-drop';
import {Loader, ObservableLoader} from '@esgillc/ui-kit/loader';
import {useEffect, useMemo, useState} from 'react';
import {
	Modal,
	CloseIcon,
	Title,
	useModal,
	useCloseModal,
} from '@esgillc/ui-kit/modal';
import {debounceTime} from 'rxjs/operators';
import {HierarchySnapshot} from '../hierarchy/models';
import {Footer} from './components/footer';
import {RolloverChecker} from 'shared/modules/rollover-checker/rollover-checker';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {Body} from './components/body';
import {ManageSubjectsAndTestsService} from './service';

export type Props = {
	closed: () => any;
	hierarchy: HierarchySnapshot;
}

export default function ManageSubjectsAndTests(props: Props): JSX.Element {
	const modal = useModal();
	const close = useCloseModal(modal, props.closed);
	const [loaded, setLoaded] = useState(false);
	const [showRolloverChecker, setShowRolloverChecker] = useState(true);

	const service = useService(ManageSubjectsAndTestsService);
	const isLoading$ = useMemo(() => service.busy.pipe(debounceTime(1000)), []);

	useEffect(() => {
		if (!showRolloverChecker) {
			service.init().subscribe(r => setLoaded(true), props.closed);
		}
	}, [showRolloverChecker]);

	return <>
		{showRolloverChecker && <RolloverChecker callback={() => setShowRolloverChecker(false)}/>}
		<Loader show={!loaded && !showRolloverChecker} fullscreen/>
		{loaded && <Modal onCatchError={close} modalManagerRef={modal}>
			<ObservableLoader observable={isLoading$} fullscreen/>
			<Modal.Header>
				<Title>Manage Subject Tabs & Tests</Title>
				<CloseIcon onClick={close}/>
			</Modal.Header>
			<Modal.Body>
				<DragDropRoot>
					<Body hierarchy={props.hierarchy} service={service}/>
				</DragDropRoot>
			</Modal.Body>
			<Footer doneClicked={close}/>
			<SnackbarLayout/>
		</Modal>}
	</>;
}
