import React, {Suspense, useMemo, useState} from 'react';
import {SelectedSnapshot} from 'modules/hierarchy/models';
import {SubjectType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import TrackConfirmModal from 'modules/track-confirm';
import {ITrackModel} from 'pages/home/components/school-year-selector/api';

const StudentProgressReportLauncher = lazyComponent(() => import('modules/reports/student-progress-report/launcher'));

class Props {
	subjectId: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	autoTrack: boolean;
	noStudents: boolean;
	trackChanged: (track: ITrackModel) => void;
}

export default function StudentProgressButton(props: Props) {
	const [showTrackConfirmModal, setShowTrackConfirmModal] = useState<boolean>(false);
	const [showLauncher, setShowLauncher] = useState<boolean>(false);
	const [changesCollector, setChangesCollector] = useState<ChangesCollector>();
	const options = new SelectedSnapshot(props.hierarchy.snapshot);

	const clickHandler = async () => {
		if (!props.autoTrack) {
			runReport();
		} else {
			setShowTrackConfirmModal(true);
		}
	};

	const onConfirm = () => {
		setShowTrackConfirmModal(false);
		runReport();
	};

	const runReport = () => {
		setChangesCollector(new ChangesCollector({
			id: props.subjectId,
			type: props.subjectType,
		}, props.hierarchy.snapshot));
		setShowLauncher(true);
	};

	const icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='15'
								viewBox='0 0 15 15'
								fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
						d='M2 0C0.895431 0 0 0.8781 0 1.96129V12.7484C0 13.8316 0.89543 14.7097 2 14.7097H13C14.1046 14.7097 15 13.8316 15 12.7484V1.96129C15 0.8781 14.1046 0 13 0H2ZM10.7138 4.4129H13.5V7.15882L12.3955 6.07029L9.09207 9.32595L8.74379 9.66919L8.39552 9.32595L5.78857 6.7567L2.19655 10.2968L1.5 9.6103L5.4403 5.72699L5.78857 5.38375L6.13685 5.72699L8.74379 8.29623L11.6989 5.38381L10.7138 4.4129Z'
						fill='#0088CC'/>
		</svg>;
	};

	return <>
		<NoStudentProtectedButton title='Student Progress'
															icon={icon()}
															hoverTitle='View student progress as raw scores, percentages, or grades'
															onClicked={() => clickHandler()}
															noStudents={props.noStudents}
															linkClassName='student-progress-report-link'/>
		{showTrackConfirmModal && <TrackConfirmModal
			confirmed={() => onConfirm()}
			canceled={() => setShowTrackConfirmModal(false)}
			trackChanged={props.trackChanged}
		/>}
		{showLauncher && <Suspense fallback={<></>}>
			<StudentProgressReportLauncher subjectId={props.subjectId}
																		 subjectType={props.subjectType}
																		 classId={options.classID}
																		 groupId={options.groupID}
																		 specialistGroupID={options.specialistGroupID}
																		 studentId={options.studentID}
																		 teacherId={options.teacherID}
																		 districtID={options.districtID}
																		 schoolID={options.schoolID}
																		 changesCollector={changesCollector}
																		 hierarchy={props.hierarchy.snapshot}
																		 onClose={() => {
																			 props.applyHomepageChanges(changesCollector);
																			 setShowLauncher(false);
																		 }}
			/></Suspense>}
	</>;
}
