import {CubeModel} from '../types';
import {getCenterBetweenTwoPoints} from './get-center-between-two-points';
import {getCubeCoordinates} from '../../utils/get-cube-coordinates';

export function getCubeModel({
	diagonalX1,
	diagonalY1,
	diagonalX2,
	diagonalY2,
}: {
	diagonalX1: number;
	diagonalY1: number;
	diagonalX2: number;
	diagonalY2: number;
}): CubeModel {
	const center = getCenterBetweenTwoPoints({
		x1: diagonalX1,
		y1: diagonalY1,
		x2: diagonalX2,
		y2: diagonalY2,
	});

	const coordinates = getCubeCoordinates({
		diagonalX1,
		diagonalY1,
		diagonalX2,
		diagonalY2,
	});

	return {
		...coordinates,
		center,
	};
}
