import {useEffect} from 'react';
import {SubjectType} from '../../types/table-level-report-service-types';
import {TableLevelReportService} from '../../services/table-level-report-service';
import {ReportModal} from './components/report-modal';
import {ReportFilters} from './components/filter';
import {Table} from './components/report-table';
import {useStream} from '@esgillc/ui-kit/utils';
import {ReportError} from './components/report-error';

type TableLevelReportProps = {
	subjectID: number;
	subjectType: SubjectType;
	service: TableLevelReportService;
	onClosed: () => void;
};

export function TableLevelReport({service, onClosed, subjectID, subjectType}: TableLevelReportProps) {
	const reportErrors = useStream(service.reportErrors$);

	useEffect(() => {
		service.init(subjectID, subjectType);
	}, []);

	return (
		<ReportModal service={service} onClosed={onClosed}>
			<ReportFilters service={service} />
			{reportErrors?.noStudents && <ReportError errorText='There are no students in this group or class' />}
			{reportErrors?.noRubrics && <ReportError errorText='There are no rubrics in this subject' />}
			<Table service={service} />
		</ReportModal>
	);
}
