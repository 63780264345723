import React, {createRef} from 'react';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {HttpClient} from '@esgi/api';
import {Loader} from '@esgi/deprecated/ui-kit/loader';
import {CloseIcon, Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import RubricService from '../common/rubric-service';
import {RubricModel} from '../common/types';
import RubricPreviewForm from '../common/preview-form/rubric-preview-form';
import styles from './preview.module.less';

interface Props {
	testID: number;
	onClose: () => void;
}

class State {
	initialized: boolean = false;
}

export default class RubricPreview extends React.PureComponent<Props, State> {
	public state = new State();
	private onDestroy$: Subject<void> = new Subject();

	private modalManagerRef: ModalManagerRef = createRef();
	private testDataService: RubricService = new RubricService();

	public componentDidMount() {
		HttpClient.default.ESGIApi.get<RubricModel>('assets/rubric', 'init/preview', {rubricID: this.props.testID})
			.pipe(tap(r => {
				this.testDataService.initFromModel(r);
				this.setState({initialized: true});
			}), takeUntil(this.onDestroy$))
			.subscribe();
	}

	public render() {
		const {name, description} = this.testDataService.testInfo$.value;
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Loader show={!this.state.initialized}/>
			{this.state.initialized && <>
				<ModalHeader className={styles.header}>
					<Title className={styles.title}>
						Preview
					</Title>
					<CloseIcon onClick={() => this.modalManagerRef.current.close(() => this.props.onClose())} color='#FFFFFF'/>
				</ModalHeader>
				<ModalBody className={styles.body}>
					<div className={styles.innerBody}>
						<div className={styles.infoRow}>
							<div className={styles.name}>
							<span>
								{name}
							</span>
							</div>
							{name?.trim() && description?.trim() && <div className={styles.divider}>
								:
							</div>}
							<div className={styles.description}>
							<span>
								{description}
							</span>
							</div>
						</div>
						<div className={styles.innerBody}>
							<RubricPreviewForm rubricService={this.testDataService} showCellHeader maxWidth={945}/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter/>
			</>}
		</Modal>;
	}

	public componentWillUnmount() {
		this.testDataService.destroy();
		this.onDestroy$.next();
	}
}
