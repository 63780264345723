import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Alert} from '@esgi/ui/alert';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
	flexDirection: 'column',
	overflow: 'auto',
	maxHeight: '65vh',
	maxWidth: '98vw',
	padding: 0,

	'@media (min-width: 617px)': {
		minWidth: 617,
	},

	'@media (min-width: 857px)': {
		minWidth: 857,
	},

	'@media (min-width: 1097px)': {
		minWidth: 1097,
	},

	'@media (min-width: 1337px)': {
		minWidth: 1337,
	},
});

export const ReportWrapper = styled(FlexBox, {
	flexDirection: 'column',
	paddingTop: 20,
});

export const Student = styled(Box, {
	padding: '10px 20px',
});

export const Results = styled(FlexBox, {
	flexWrap: 'wrap',
	paddingLeft: 10,
	paddingRight: 10,
});
