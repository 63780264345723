import {useEffect} from 'react';
import {RepAutoQuoter} from './quoter';

export default function RepAutoQuoterRoot() {
	useEffect(() => {
		try {
			const autoQuoter = new RepAutoQuoter();
			ko.applyBindings(autoQuoter, document.body);

			return () => {
				ko?.cleanNode(document.body);
			};
		} catch (e) {
			console.error(e);
		}
	}, []);

	return (
		<div className='auto-quoter-root'>
			<div className='auto-quoter'>
				<div data-bind='module: {data: form}'></div>
			</div>
		</div>
	);
}
