import {GridBox} from '@esgi/ui/layout';
import {Content, Divider, SelectableListItem, StyledImage} from '../index.styled';
import {Text} from '@esgi/ui/typography';
import {Logo} from '@esgi/ui';
import {AdditionSelectItem, OptionItem, ReadMoreButton} from '@esgi/main/features/standards-drawer';

type Props = Pick<OptionItem, 'value' | 'description' | 'title' | 'label' | 'imageSet'>;

export function AuthorSelectItem({
	value,
	label,
	title,
	description,
	imageSet,
}: Props) {
	return (
		<SelectableListItem
			value={String(value)}
			key={value}
			variant='outlined'
		>
			<Content>
				{label === AdditionSelectItem.System
					? <Logo />
					: <StyledImage data-cy='logo' alt={label} src={`https://esgi-cdn.s3.amazonaws.com/${imageSet?.default}`}/>
				}
				<GridBox gap={2}>
					<Text data-cy='author-label' size='large' bold>{label}</Text>
					<Text data-cy='author-title' size='small' bold>{title}</Text>
					<Divider />
					<ReadMoreButton dataCy='author-description' description={description} />
				</GridBox>
			</Content>
		</SelectableListItem>
	);
}