import {Buttons} from '@esgillc/ui-kit/button';
import {AutoSize} from '@esgillc/ui-kit/core';
import {Fade} from '@esgillc/ui-kit/transition';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {useMemo, useState} from 'react';
import {animated} from 'react-spring';
import {Route} from '../../hooks/use-navigation';
import {AssignmentInfo} from '../../services/types';
import {routesToDisplayName} from '../kit/routes-to-display-name';
import {Divider} from '../kit/divider';
import {SortDirection, SortField, SortingModel} from './sorting-model';
import {AssignmentTile} from './components/assignment-tile';
import {Sorting} from './components/sorting';
import {District} from '../kit/district';
import {useListAnimation} from './use-list-animation';
import {sortByModel} from './utils';
import {filterByStatus, mapStatusFromRoute} from '../kit/utils';
import 'overlayscrollbars/overlayscrollbars.css';
import styles from './styles.module.less';

type Props = {
	assignments: AssignmentInfo[];
	route: Route;
	onCreateClick: VoidFunction;
	onAssignmentClick: (id: number) => void;
};

export default function AssignmentCenterList(props: Props) {
	const [sorting, setSorting] = useState<SortingModel>({
		direction: SortDirection.Desc,
		field: SortField.PostedAt,
	});

	const status = mapStatusFromRoute(props.route);

	const assignments = useMemo(
		() => sortByModel(sorting, filterByStatus(status, props.assignments)),
		[sorting, status, props.assignments],
	);

	const transitions = useListAnimation(assignments);

	return (
		<Fade in duration='common' animateOnMount>
			<div className={styles.container}>
				<div className={styles.header}>
					{routesToDisplayName[props.route]}
					<District/>
					<Buttons.Contained onClick={props.onCreateClick}>
						Create Assignment
					</Buttons.Contained>
				</div>
				<Divider className={styles.divider}/>
				<div className={styles.body}>
					<div className={styles.listContainer}>
						{assignments.length > 1 &&
							<Sorting
								sorting={sorting}
								sortingChange={setSorting}
							/>
						}
						<AutoSize>
							{bounds =>
								<OverlayScrollbarsComponent style={{height: bounds.height}}>
									{transitions((style, assignment, t, index) => (
										<animated.div
											style={{
												zIndex: assignments.length - index,
												position: 'absolute',
												width: '100%',
												paddingRight: '30px',
												...style,
											}}
										>
											<AssignmentTile
												assignment={assignment}
												onClick={() => props.onAssignmentClick(assignment.id)}
											/>
										</animated.div>
									))}
								</OverlayScrollbarsComponent>
							}
						</AutoSize>
					</div>
				</div>
			</div>
		</Fade>
	);
}
