import {
	ExpandablePanel,
	StudentSectionMode,
	SubjectSectionMode,
	Tabs,
	StudentSectionContext,
	StudentSectionContextValue,
	SubjectSectionContext,
	SubjectSectionContextValue,
} from '@esgi/main/features/teacher/home';
import {SectionTabId, SubjectTabId} from '../../types';
import {Task, Users} from '@esgi/ui';
import {ScreenSize} from '@esgi/main/kits/common';
import {SubjectSectionContent} from '../subject-section-content';
import React, {Dispatch, useCallback, useMemo, useState} from 'react';
import {useSectionTabItems} from '../../hooks/use-section-tab-items';
import {ContentWrapper, Divider, TabsWrapper} from './index.styled';
import {DataStateService} from '../../services/data-state-service';
import {Class, Student, SubjectTab} from '@esgi/main/libs/store';
import {StudentPanel} from '../student-panel';
import {StudentsPanelTabID} from '@esgi/main/features/teacher/students-panel';
import {DataSubjectsService} from '../../services/data-subjects-service';

type Props = {
	stateService: DataStateService,
	dataService: DataSubjectsService;
	districtSubjects: SubjectTab[];
	schoolSubjects: SubjectTab[];
	personalSubjects: SubjectTab[];
	isUpdatingState: boolean,
	screenSize: ScreenSize,
	setStudentFromClassID: Dispatch<Class['id'] | null>;
	setStudentFromGroupID: Dispatch<Class['id'] | null>;
	isFirstEntry: boolean,
	selectedStudentId: number | null,
	selectedStudent: Student | null;
	selectedClassId: number | null,
	selectedGroupId: number | null,
	activeStudentTab: StudentsPanelTabID,
	setHasStudents: React.Dispatch<React.SetStateAction<boolean>>,
	selectedSubjectId: number | null,
	activeSubjectTab: SubjectTabId,
	setSelectedSubject: React.Dispatch<React.SetStateAction<SubjectTab>>,
}

export function TabletViewSections({
	stateService,
	isUpdatingState,
	screenSize,
	isFirstEntry,
	selectedStudentId,
	selectedStudent,
	selectedClassId,
	selectedGroupId,
	activeStudentTab,
	setHasStudents,
	selectedSubjectId,
	activeSubjectTab,
	setStudentFromClassID,
	setStudentFromGroupID,
	dataService,
	districtSubjects,
	schoolSubjects,
	personalSubjects,
}: Props) {
	const [activeSectionTab, setActiveSectionTab] = useState(SectionTabId.Students);
	const {tabItems} = useSectionTabItems({activeSectionTab});

	const [studentsLoaded, setStudentsLoaded] = useState(false);
	const [subjectsLoaded, setSubjectsLoaded] = useState(false);
	const [studentsSectionMode, setStudentsSectionMode] = useState(StudentSectionMode.View);
	const [subjectsSectionMode, setSubjectsSectionMode] = useState(SubjectSectionMode.View);

	const [isPanelOpened, setPanelOpened] = useState(true);

	const togglePanelOpened = () => setPanelOpened(prev => !prev);

	const setStudentsSectionViewMode = useCallback(() => {
		setStudentsSectionMode(StudentSectionMode.View);
	}, []);

	const setSubjectsSectionViewMode = useCallback(() => {
		setSubjectsSectionMode(SubjectSectionMode.View);
	}, []);

	const studentContextValue = useMemo<StudentSectionContextValue>(
		() => ({
			setSectionViewMode: setStudentsSectionViewMode,
			sectionMode: studentsSectionMode,
			setSectionMode: setStudentsSectionMode,
			loaded: studentsLoaded,
			setLoaded: setStudentsLoaded,
		}),
		[setStudentsSectionViewMode, studentsLoaded, studentsSectionMode],
	);

	const subjectsContextValue = useMemo<SubjectSectionContextValue>(
		() => ({
			setSectionViewMode: setSubjectsSectionViewMode,
			sectionMode: subjectsSectionMode,
			setSectionMode: setSubjectsSectionMode,
			loaded: subjectsLoaded,
			setLoaded: setSubjectsLoaded,
		}),
		[setSubjectsSectionViewMode, subjectsLoaded, subjectsSectionMode],
	);

	return (
		<StudentSectionContext.Provider value={studentContextValue}>
			<SubjectSectionContext.Provider value={subjectsContextValue}>
				<ExpandablePanel
					PanelIcon={activeSectionTab === SectionTabId.Students ? Users : Task}
					loaded={activeSectionTab === SectionTabId.Students ? studentsLoaded : subjectsLoaded}
					disablePanelToggling={isUpdatingState}
					togglePanelOpened={togglePanelOpened}
					onClick={!isPanelOpened ? togglePanelOpened : undefined}
					isPanelOpened={isPanelOpened}
					dataCy='students-subjects-expandable-panel'
					isTablet={screenSize === ScreenSize.PortraitTablet}
				>
					<ContentWrapper>
						<TabsWrapper>
							<Tabs<SectionTabId>
								activeTab={activeSectionTab}
								setActiveTab={setActiveSectionTab}
								tabItems={tabItems}
								showTitle={false}
								dataCy='section-tabs'
							/>
						</TabsWrapper>
						<Divider/>
						{activeSectionTab === SectionTabId.Students
							? <StudentPanel
								isFirstEntry={isFirstEntry}
								setHasStudents={setHasStudents}
								activeTab={activeStudentTab}
								setActiveTab={stateService.setActiveStudentTab.bind(stateService)}
								selectedClassId={selectedClassId}
								setSelectedClassId={stateService.setSelectedClassId.bind(stateService)}
								selectedGroupId={selectedGroupId}
								setSelectedGroupId={stateService.setSelectedGroupId.bind(stateService)}
								selectedStudentId={selectedStudentId}
								setSelectedStudentId={stateService.setSelectedStudentId.bind(stateService)}
								setStudentFromClassID={setStudentFromClassID}
								setStudentFromGroupID={setStudentFromGroupID}
							/>
							: <SubjectSectionContent
								selectedSubjectId={selectedSubjectId}
								setSelectedSubjectId={stateService.setSelectedSubjectId.bind(stateService)}
								activeTab={activeSubjectTab}
								setActiveTab={stateService.setActiveSubjectTab.bind(stateService)}
								dataService={dataService}
								districtSubjects={districtSubjects}
								schoolSubjects={schoolSubjects}
								personalSubjects={personalSubjects}
								selectedStudent={selectedStudent}
							/>
						}
					</ContentWrapper>
				</ExpandablePanel>
			</SubjectSectionContext.Provider>
		</StudentSectionContext.Provider>
	);
}