import {Filters} from '../filters';
import {Chart} from '../chart';
import {GridBox} from '@esgi/ui/layout';
import {EntityType, SelectField, TestInfo, TrackDate} from '../../../../types';
import {useCallback, useEffect, useState} from 'react';
import {FiltersState} from '../../types';
import {StudentInfo} from '../../../../class-group/types';
import {useSessionsGroupedByDate} from '../../hooks/use-sessions-grouped-by-date';
import {usePreparedTestSessions} from '../../hooks/use-prepared-test-sessions';
import {Wrapper} from './index.styled';
import {EmptyContent} from '../../../../kit';
import {ChartExpandedView} from '../chart-expanded-view';
import {useFilteredSession} from './hooks';

type Props = {
	entityType: EntityType,
	trackDates: TrackDate[],
	students: StudentInfo[],
	testInfo: TestInfo | null,
	isTablet: boolean,
}

export function ClassEntity({entityType, students, trackDates, isTablet, testInfo: {testType, totalPossible}}: Props) {
	const [showOnlyTestedStudents, setShowOnlyTestedStudents] = useState(false);
	const [isExpandedViewOpen, setExpandedViewOpen] = useState(false);
	const [fieldValue, setFieldValue]= useState<SelectField>(SelectField.Period);
	const preparedStudents = useSessionsGroupedByDate(students);
	const testSessions = usePreparedTestSessions(preparedStudents, showOnlyTestedStudents, students, totalPossible);
	const [sessions, setSessions] = useState(testSessions);

	const [filtersState, setFiltersState] = useState<FiltersState>(() => {
		return {
			period: trackDates.find(item => new Date(item.dateTo) > new Date())?.dateFrom || trackDates.reverse()[0]?.dateFrom,
		};
	});

	const {filteredSessionsData, onFilterValueChange} = useFilteredSession({
		setFieldValue,
		trackDates,
		testSessions,
		setFiltersState,
		setSessions,
	});

	const toggleShowOnlyTestedStudent = useCallback(() => {
		setShowOnlyTestedStudents(prev => !prev);
	}, []);

	useEffect(() => {
		const {filteredSessions} = filteredSessionsData(fieldValue, filtersState[fieldValue]);
		setSessions(filteredSessions);
	}, [showOnlyTestedStudents]);

	return (
		<Wrapper isTablet={isTablet} emptyMode={!sessions.length}>
			<Filters
				filtersState={filtersState}
				onFilterValueChange={onFilterValueChange}
				trackDates={trackDates}
				isTablet={isTablet}
			/>
			{!sessions.length
				? <GridBox justify='center' align='center'><EmptyContent templateText='No data to display' /></GridBox>
				: <Chart
					toggleShowOnlyTestedStudent={toggleShowOnlyTestedStudent}
					showOnlyTestedStudents={showOnlyTestedStudents}
					entityType={entityType}
					testSessions={sessions.filter(el=>!el.isDeleted)}
					showExpandedView={() => setExpandedViewOpen(true)}
					testType={testType}
					isTablet={isTablet}
				/>}
			{isExpandedViewOpen && <ChartExpandedView
        testType={testType}
        entityType={entityType}
        onCancel={() => setExpandedViewOpen(false)}
      />}
		</Wrapper>
	);
}
