import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const HomePage = lazyComponent(() => import('pages/home/root'));

export default function Home() {
	return <RouteWrapper authorize>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<HomePage/>
		</Suspense>
	</RouteWrapper>;
}
