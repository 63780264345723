import React, {ReactNode} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {ErrorBoundary} from '@esgi/core/react';

export class CenterPanel extends React.Component<{ children?: ReactNode, className?: string }> {
	render() {
		return <div className={join('center', this.props.className)}>
			<ErrorBoundary>{this.props.children}</ErrorBoundary>
		</div>;
	}
}
