import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const CardRoot = styled(Card, {
	display: 'inline-grid',
	gap: '$4',
	cursor: 'pointer',

	[`& ${Text}[data-assignment-name]`]: {
		transition: 'color .3s',
	},

	'&:hover': {
		[`& ${Text}[data-assignment-name]`]: {
			color: '$secondary',
		},
	},
});
