import React from 'react';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import {Modal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

interface Props {
	isDirty: boolean
	readOnly: boolean
	onSave: () => void;
	closeModal: () => void
}

export function Footer({isDirty, onSave, readOnly, closeModal}: Props) {
	return <Modal.Footer className={styles.modalFooter}>
		{!readOnly && <><Button
			onClick={closeModal}
			title='CANCEL'
		/>
			<Primary
				onClick={() => onSave()}
				title='SAVE'
				disabled={!isDirty}
			/>
		</>}
	</Modal.Footer>;
}
