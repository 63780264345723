import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {OneLinedText} from '@esgi/main/kits/common';

export const HeaderBox = styled(FlexBox, {
	alignItems: 'center',
	flexWrap: 'wrap',

	'& > :last-child': {
		padding: '4px 0',
	},
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
	margin: '0 10px',
});

export const Text = styled(OneLinedText, {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});

export const ClassName = styled(Text, {
	marginLeft: '5px',
	textOverflow: 'ellipsis',
	maxWidth: '200px',
});

export const IconWrapper = styled(FlexBox, {
	width: 40,
	height: 40,
	borderRadius: 12,
	borderWidth: 1.5,
	borderStyle: 'solid',
	borderColor: '$border',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	color: '$primary',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});