!(function (e, t) {
    var i = "multiple" in document.createElement("INPUT"), n = "FileList" in window, a = "FileReader" in window,
        s = "File" in window, r = function (t, i) {
            var n = this;
            this.settings = e.extend({}, e.fn.ace_file_input.defaults, i), this.$element = e(t), this.element = t, this.disabled = !1, this.can_reset = !0, this.$element.off("change.ace_inner_call").on("change.ace_inner_call", function (e, t) {
                return t !== !0 ? l.call(n) : void 0
            });
            var a = this.$element.closest("label").css({display: "block"}), s = 0 == a.length ? "label" : "span";
            this.$element.wrap("<" + s + ' class="ace-file-input" />'), this.apply_settings(), this.reset_input_field()
        };
    r.error = {
        FILE_LOAD_FAILED: 1,
        IMAGE_LOAD_FAILED: 2,
        THUMBNAIL_FAILED: 3
    }, r.prototype.apply_settings = function () {
        var t = this;
        this.multi = this.$element.attr("multiple") && i, this.well_style = "well" == this.settings.style, this.well_style ? this.$element.parent().addClass("ace-file-multiple") : this.$element.parent().removeClass("ace-file-multiple"), this.$element.parent().find(":not(input[type=file])").remove(), this.$element.after('<span class="ace-file-container" data-title="' + this.settings.btn_choose + '"><span class="ace-file-name" data-title="' + this.settings.no_file + '">' + (this.settings.no_icon ? '<i class="fa ' + this.settings.no_icon + '"></i>' : "") + "</span></span>"), this.$label = this.$element.next(), this.$container = this.$element.closest(".ace-file-input");
        var a = !!this.settings.icon_remove;
        if (a) {
            var s = e('<a class="remove" href="#"><i class="fa ' + this.settings.icon_remove + '"></i></a>').appendTo(this.$element.parent());
            s.on("click", function (e) {
                if (e.preventDefault(), !t.can_reset) return !1;
                var i = !0;
                if (t.settings.before_remove && (i = t.settings.before_remove.call(t.element)), !i) return !1;
                t.reset_input();
                return !1
            })
        }
        this.settings.droppable && n && o.call(this)
    }, r.prototype.show_file_list = function (t) {
        var i = "undefined" == typeof t ? this.$element.data("ace_input_files") : t;
        if (i && 0 != i.length) {
            this.well_style && (this.$label.find(".ace-file-name").remove(), this.settings.btn_change || this.$label.addClass("hide-placeholder")), this.$label.attr("data-title", this.settings.btn_change).addClass("selected");
            for (var n = 0; n < i.length; n++) {
                var s = "string" == typeof i[n] ? i[n] : e.trim(i[n].name), r = s.lastIndexOf("\\") + 1;
                0 == r && (r = s.lastIndexOf("/") + 1), s = s.substr(r);
                var o = "fa fa-file", l = "file";
                if (/\.(jpe?g|png|gif|svg|bmp|tiff?)$/i.test(s) ? (o = "fa fa-picture-o file-image", l = "image") : /\.(mpe?g|flv|mov|avi|swf|mp4|mkv|webm|wmv|3gp)$/i.test(s) ? (o = "fa fa-film file-video", l = "video") : /\.(mp3|ogg|wav|wma|amr|aac)$/i.test(s) && (o = "fa fa-music file-audio", l = "audio"), this.well_style) {
                    this.$label.append('<span class="ace-file-name" data-title="' + s + '"><i class=" fa' + o + '"></i></span>');
                    var d = e.trim(i[n].type),
                        h = a && this.settings.thumbnail && (d.length > 0 && d.match("image") || 0 == d.length && "image" == l);
                    if (h) {
                        var u = this;
                        e.when(c.call(this, i[n])).fail(function (e) {
                            u.settings.preview_error && u.settings.preview_error.call(u, s, e.code)
                        })
                    }
                } else this.$label.find(".ace-file-name").attr({"data-title": s}).find('.fa').attr("class", 'fa ' + o)
            }
            return !0
        }
    }, r.prototype.reset_input = function () {
        this.reset_input_ui(), this.reset_input_field()
    }, r.prototype.reset_input_ui = function () {
        this.$label.attr({
            "data-title": this.settings.btn_choose,
            "class": "ace-file-container"
        }).find(".ace-file-name:first").attr({
            "data-title": this.settings.no_file,
            "class": "ace-file-name"
        }).find('.fa').attr("class", 'fa ' + this.settings.no_icon).prev("img").remove(), this.settings.no_icon || this.$label.find('.fa').remove(), this.$label.find(".ace-file-name").not(":first").remove(), this.reset_input_data()
    }, r.prototype.reset_input_field = function () {
        this.$element.wrap("<form>").parent().get(0).reset(), this.$element.unwrap()
    }, r.prototype.reset_input_data = function () {
        this.$element.data("ace_input_files") && (this.$element.removeData("ace_input_files"), this.$element.removeData("ace_input_method"))
    }, r.prototype.enable_reset = function (e) {
        this.can_reset = e
    }, r.prototype.disable = function () {
        this.disabled = !0, this.$element.attr("disabled", "disabled").addClass("disabled")
    }, r.prototype.enable = function () {
        this.disabled = !1, this.$element.removeAttr("disabled").removeClass("disabled")
    }, r.prototype.files = function () {
        return e(this).data("ace_input_files") || null
    }, r.prototype.method = function () {
        return e(this).data("ace_input_method") || ""
    }, r.prototype.update_settings = function (t) {
        this.settings = e.extend({}, this.settings, t), this.apply_settings()
    }, r.prototype.loading = function (t) {
        if (t === !1) this.$container.find(".ace-file-overlay").remove(), this.element.removeAttribute("readonly"); else {
            var i = "string" == typeof t ? t : '<i class="overlay-content fa fa-spin fa-spinner orange2 fa-2x"></i>',
                n = this.$container.find(".ace-file-overlay");
            0 == n.length && (n = e('<div class="ace-file-overlay"></div>').appendTo(this.$container), n.on("click tap", function (e) {
                return e.stopImmediatePropagation(), e.preventDefault(), !1
            }), this.element.setAttribute("readonly", "true")), n.empty().append(i)
        }
    };
    var o = function () {
        var e = this, t = this.$element.parent();
        t.off("dragenter").on("dragenter", function (e) {
            e.preventDefault(), e.stopPropagation()
        }).off("dragover").on("dragover", function (e) {
            e.preventDefault(), e.stopPropagation()
        }).off("drop").on("drop", function (t) {
            t.preventDefault(), t.stopPropagation();
            var i = t.originalEvent.dataTransfer, n = i.files;
            if (!e.multi && n.length > 1) {
                var a = [];
                a.push(n[0]), n = a
            }
            return n = h.call(e, n, !0), n === !1 ? !1 : (e.$element.data("ace_input_method", "drop"), e.$element.data("ace_input_files", n), e.show_file_list(n), e.$element.triggerHandler("change", [!0]), !0)
        })
    }, l = function () {
        var e = this.element.files || [this.element.value];
        return e = h.call(this, e, !1), e === !1 ? !1 : (this.$element.data("ace_input_method", "select"), this.$element.data("ace_input_files", e), this.show_file_list(e), !0)
    }, c = function (t) {
        var i = this, n = i.$label.find(".ace-file-name:last"), a = new e.Deferred, s = new FileReader;
        return s.onload = function (s) {
            n.prepend("<img class='middle' style='display:none;' />");
            var o = n.find("img:last").get(0);
            e(o).one("load", function () {
                var s = 50;
                "large" == i.settings.thumbnail ? s = 150 : "fit" == i.settings.thumbnail && (s = n.width()), n.addClass(s > 50 ? "large" : "");
                var l = d(o, s, t.type);
                if (null == l) return e(this).remove(), void a.reject({code: r.error.THUMBNAIL_FAILED});
                var c = l.w, h = l.h;
                "small" == i.settings.thumbnail && (c = h = s), e(o).css({
                    "background-image": "url(" + l.src + ")",
                    width: c,
                    height: h
                }).data("thumb", l.src).attr({src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg=="}).show(), a.resolve()
            }).one("error", function () {
                n.find("img").remove(), a.reject({code: r.error.IMAGE_LOAD_FAILED})
            }), o.src = s.target.result
        }, s.onerror = function () {
            a.reject({code: r.error.FILE_LOAD_FAILED})
        }, s.readAsDataURL(t), a.promise()
    }, d = function (t, i) {
        var n = t.width, a = t.height;
        n = n > 0 ? n : e(t).width(), a = a > 0 ? a : e(t).height(), (n > i || a > i) && (n > a ? (a = parseInt(i / n * a), n = i) : (n = parseInt(i / a * n), a = i));
        var s;
        try {
            var r = document.createElement("canvas");
            r.width = n, r.height = a;
            var o = r.getContext("2d");
            o.drawImage(t, 0, 0, t.width, t.height, 0, 0, n, a), s = r.toDataURL()
        } catch (l) {
            s = null
        }
        return s ? (/^data\:image\/(png|jpe?g|gif);base64,[0-9A-Za-z\+\/\=]+$/.test(s) || (s = null), s ? {
            src: s,
            w: n,
            h: a
        } : null) : null
    }, h = function (e, t) {
        var i = p.call(this, e, t);
        return -1 === i ? (this.reset_input(), !1) : i && 0 != i.length ? ((i instanceof Array || n && i instanceof FileList) && (e = i), i = !0, this.settings.before_change && (i = this.settings.before_change.call(this.element, e, t)), -1 === i ? (this.reset_input(), !1) : i && 0 != i.length ? ((i instanceof Array || n && i instanceof FileList) && (e = i), e) : (this.$element.data("ace_input_files") || this.reset_input(), !1)) : (this.$element.data("ace_input_files") || this.reset_input(), !1)
    }, u = function (e) {
        return e ? ("string" == typeof e && (e = [e]), 0 == e.length ? null : new RegExp(".(?:" + e.join("|") + ")$", "i")) : null
    }, f = function (e) {
        return e ? ("string" == typeof e && (e = [e]), 0 == e.length ? null : new RegExp("^(?:" + e.join("|").replace(/\//g, "\\/") + ")$", "i")) : null
    }, p = function (t, i) {
        var n = u(this.settings.allowExt), a = u(this.settings.denyExt), r = f(this.settings.allowMime),
            o = f(this.settings.denyMime), l = this.settings.maxSize || !1;
        if (!(n || a || r || o || l)) return !0;
        for (var c = [], d = {}, h = 0; h < t.length; h++) {
            var p = t[h], v = s ? p.name : p;
            if (!n || n.test(v)) if (a && a.test(v)) "ext" in d || (d.ext = []), d.ext.push(v); else {
                var g;
                if (s) {
                    if ((g = e.trim(p.type)).length > 0) {
                        if (r && !r.test(g)) {
                            "mime" in d || (d.mime = []), d.mime.push(v);
                            continue
                        }
                        if (o && o.test(g)) {
                            "mime" in d || (d.mime = []), d.mime.push(v);
                            continue
                        }
                    }
                    l && p.size > l ? ("size" in d || (d.size = []), d.size.push(v)) : c.push(p)
                } else c.push(p)
            } else "ext" in d || (d.ext = []), d.ext.push(v)
        }
        if (c.length == t.length) return t;
        var m = {ext: 0, mime: 0, size: 0};
        "ext" in d && (m.ext = d.ext.length), "mime" in d && (m.mime = d.mime.length), "size" in d && (m.size = d.size.length);
        var b;
        return this.$element.trigger(b = new e.Event("file.error.ace"), {
            file_count: t.length,
            invalid_count: t.length - c.length,
            error_list: d,
            error_count: m,
            dropped: i
        }), b.isDefaultPrevented() ? -1 : c
    };
    e.fn.ace_file_input = function (i, n) {
        var a, s = this.each(function () {
            var t = e(this), s = t.data("ace_file_input"), o = "object" == typeof i && i;
            s || t.data("ace_file_input", s = new r(this, o)), "string" == typeof i && (a = s[i](n))
        });
        return a === t ? s : a
    }, e.fn.ace_file_input.defaults = {
        style: !1,
        no_file: "No File ...",
        no_icon: "fa fa-upload",
        btn_choose: "Choose",
        btn_change: "Change",
        icon_remove: "fa fa-times",
        droppable: !1,
        thumbnail: !1,
        allowExt: null,
        denyExt: null,
        allowMime: null,
        denyMime: null,
        maxSize: !1,
        before_change: null,
        before_remove: null,
        preview_error: null
    }
}(window.jQuery));
