import {ScrollArea, SectionBlock} from '../../kit';
import {TestSession, TestInfo, TestDashboardSectionID} from '../../types';
import {sectionTitle} from './constants';
import {ClassEntity} from './components/selected-entity/class-entity';
import {isNull} from 'underscore';
import {StudentEntity} from './components/selected-entity/student-entity';
import {StudentSort, StudentInfo, OpenTestingStudentParams} from '../../class-group/types';
import {Class, Student} from '@esgi/main/libs/store';
import {useTestDashboardContext} from '../../layout/outlet-context';
import {Dispatch, useEffect, useRef} from 'react';

type Props = {
	testInfo: TestInfo | null;
	isTablet: boolean;
} & (
	| {
			entityType: 'student';
			testSessions: TestSession[];
			studentID: Student['id'];
			studentFirstName: string;
			studentLastName: string;
			studentClassID: Class['id'];
	  }
	| {
			entityType: 'class-group';
			studentsInfo: StudentInfo[];
			sortStudentsBy: StudentSort;
			onOpenTestingStudent: Dispatch<OpenTestingStudentParams>;
	  }
);

export function SessionsSection({testInfo, isTablet, ...props}: Props) {
	const {subjectID, subjectType, testId} = useTestDashboardContext();

	const {setSectionRef} = ScrollArea.useScrollAreaContext();

	const sectionRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (sectionRef.current) {
			setSectionRef({
				sectionID: TestDashboardSectionID.Sessions,
				ref: sectionRef,
			});
		}
	}, []);

	if (isNull(testInfo)) {
		return null;
	}

	if (props.entityType === 'class-group') {
		const {studentsInfo, sortStudentsBy, onOpenTestingStudent} = props;

		return (
			<SectionBlock isTablet={isTablet} title={sectionTitle} dataCy={sectionTitle} ref={sectionRef}>
				<ClassEntity
					testInfo={testInfo}
					studentsInfo={studentsInfo}
					sortStudentsBy={sortStudentsBy}
					onOpenTestingStudent={onOpenTestingStudent}
					isTablet={isTablet}
				/>
			</SectionBlock>
		);
	}

	if (props.entityType === 'student') {
		const {testSessions, studentID, studentFirstName, studentLastName, studentClassID} = props;

		return (
			<SectionBlock isTablet={isTablet} title={sectionTitle} ref={sectionRef}>
				<StudentEntity
					testInfo={testInfo}
					testSessions={testSessions}
					classID={studentClassID}
					testID={testId}
					studentFirstName={studentFirstName}
					studentLastName={studentLastName}
					studentID={studentID}
					subjectID={subjectID}
					subjectType={subjectType}
					isTablet={isTablet}
				/>
			</SectionBlock>
		);
	}

	return null;
}
