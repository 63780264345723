import {CSS} from '@stitches/react';
import React, {ComponentPropsWithoutRef, ReactNode, forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {useSelectContext} from '../../context';
import {Box} from '@esgi/ui/layout';

type ErrorTextProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Item Content. */
	children?: ReactNode | ((value: string) => React.JSX.Element);
};

export const ErrorText = forwardRef<HTMLDivElement, ErrorTextProps>(({dataCy, children, ...props}, forwaredRef) => {
	const {errorMessage} = useSelectContext();

	if (!errorMessage) {
		return null;
	}

	return (
		<ErrorTextWrapper data-cy={dataCy ?? 'ui-kit-select-error'} ref={forwaredRef} {...props}>
			{typeof children === 'function' ? children(errorMessage) : children}
		</ErrorTextWrapper>
	);
});

const ErrorTextWrapper = styled(Box, {
	paddingLeft: 12,
	paddingRight: 5,

	[`& ${Text}`]: {
		color: '$negativeVivid',
	},
});
