import {useService} from '@esgi/core/service';
import {ClassTotalsService} from './service';
import useReportState from './hooks/use-report-state';
import {TestSelectionPanel} from './components/test-selection-panel';
import {SettingsPanel} from './components/settings-panel';
import {Reports} from '@esgi/main/kits/reports';
import {useState} from 'react';
import {PreviewDialog} from './components/preview-dialog';
import {Entities} from '@esgi/main/features/teacher/students-panel';
import {Page} from '../../layout/index.styled';
import {Panel} from './index.styled';

export default function TotalReport() {
	const service = useService(ClassTotalsService);

	const {
		selectedEntity,
		subjects,
		isSubjectsLoaded,
		selectedSubject,
		selectedTests,
		onEntitySelect,
		selected,
	} = useReportState(service);

	const [preview, setPreview] = useState(false);
	const [entities, setEntities] = useState<Entities>();

	return (
		<Page>
			<Panel>
				<Reports.ClassLevel.StudentPanel
					hideEmptyClassAndGroup
					selected={selected}
					onSelect={onEntitySelect}
					onEntityChanged={setEntities}/>
			</Panel>
			<TestSelectionPanel
				selectedEntity={selectedEntity}
				subjects={subjects}
				isSubjectsLoaded={isSubjectsLoaded}
				selectedSubject={selectedSubject}
				onSubjectSelect={value => service.setSubject(value)}
				onTestSelect={value => service.setTests(value)}
			/>
			<SettingsPanel
				service={service}
				disabled={!selectedTests.length}
				getReport={() => {
					service.getReport();
					setPreview(true);
				}}
			/>
			{preview &&
				<PreviewDialog
					service={service}
					onClose={() => setPreview(false)}
					subjects={subjects}
					isSubjectsLoaded={isSubjectsLoaded}
					selectedSubject={selectedSubject.toString()}
					onSubjectSelect={value => service.subjectChanged(value)}
					selectedEntity={selected}
					classes={entities?.classes ?? []}
					groups={entities?.groups ?? []}
					setSelected={onEntitySelect}
				/>
			}
		</Page>
	);
}