import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert} from '@esgi/ui/alert';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
	overflow: 'auto',
	maxHeight: '75vh',
	padding: 0,
});

export const ReportWrapper = styled(FlexBox, {
	flexDirection: 'column',
	width: '100%',
	padding: '0 20px',
});

export const Page = styled(FlexBox, {
	flexDirection: 'column',

	'&:not(:last-child)': {
		marginTop: 20,
	},
});

export const Header = styled(FlexBox, {
	alignItems: 'baseline',
	borderBottom: '1px solid $neutral40',
});

export const HeaderText = styled(Text, {
	marginRight: 10,
});

export const Student = styled(FlexBox, {
	flexDirection: 'column',
	marginTop: 5,
	borderBottom: '1px solid $neutral40',
});

export const FullNameText = styled(Text, {
	fontSize: 21,
	fontWeight: 700,
});

export const TitleText = styled(Text, {
	color: '$neutral40',
	fontSize: 26,
	padding: '20px 0 20px',
	textAlign: 'center',
});

export const Content = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-around',
	marginTop: 10,
});

export const Row = styled(FlexBox, {
	justifyContent: 'space-between',
	width: '100%',
	marginTop: 10,
});

export const Cell = styled('p', {
	margin: 0,
	width: '100%',
	padding: '0 5px',
	lineHeight: 1,

	variants: {
		type: {
			normal: {
				fontFamily: 'AbcPrint',
			},
			dotted: {
				fontFamily: 'AbcPrintDottedLined',
			},
			lined: {
				fontFamily: 'AbcPrintLined',
				color: '#828282',
			},
			blank: {
				fontFamily: 'AbcPrintLined',
				height: '1em',
				position: 'relative',

				'&:before, &:after': {
					content: '',
					display: 'block',
					position: 'absolute',
					left: 0,
					right: 0,
					borderColor: '$neutral6',
				},
				'&:before': {
					marginTop: '-1px',
					borderTop: '1px solid',
					borderBottom: '1px dashed',
					top: 0,
					height: '0.38em',
				},
				'&:after': {
					borderBottom: '1px solid',
					bottom: '0.2em',
					height: '0.4em',
				},
			},
		},
	},
});
