import React from 'react';
import {Body, IApi as BodyApi, Props as BodyProps, State as BodyState} from './body';
import {
	ResultRequest,
	ResultScoreResponse,
	UpdateResponse,
	UpdateSSRequest,
} from '../../api/models';
import {IFormControlValidatorResult, Validators} from '@esgi/deprecated/elements/form-controls';
import {TestType} from '@esgi/core/enums';
import {Models} from '../../models';
import {TextInput} from '@esgi/deprecated/elements/text-input';
import {Observable} from 'rxjs';
import {TestSessionDetailsEvents} from 'shared/modules/test/test-session-details/events';
import {TextArea} from '@esgillc/ui-kit/control';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export interface IApi extends BodyApi {
	scoreLoad: (request: ResultRequest) => Observable<ResultScoreResponse>;
  updateSSTestSession: (request: UpdateSSRequest) => Observable<UpdateResponse>;
}

export class Props extends BodyProps<State, IApi> {
	totalPossible: number;
	hierarchy: HierarchySnapshot;
}

export class State extends BodyState {
	score: number = 0;
	note: string;
	scoreIncorrect: boolean = false;
	scoreTouched: boolean = false;
}

export class SS extends Body<IApi, State, Props> {
	constructor(props?: Props) {
		super(props);
	}

	private readonly maxChars: number = 140;

	componentDidMount(): void {
		super.componentDidMount();

		this.subscribe(TestSessionDetailsEvents.TestSessionUpdate, () => {
			this.updateTestSession();
		});
	}

	protected getAnswers(request: ResultRequest) {
		this.loader.mask();

		this.props.api.scoreLoad(request)
			.subscribe({
				next: (resp) => {
					this.setState({
						score: resp.score,
						note: resp.note || '',
					});
				},
				error: () => {
					this.loader.unmask();
				},
				complete: () => {
					this.loader.unmask();
				},
			});
	}

	protected setAnswers(answers?: Models.Answer[]) {
		this.setState({
			score: 0,
			note: null,
		});
	}

	protected renderEditButtons() {
		if (!this.props.editMode) {
			return <>
				{(this.props.canChangeSession)
					? <a href='#' onClick={() => this.addTestSessionClicked()}>
						<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM22.5 16.5H16.5V22.5H13.5V16.5H7.5V13.5H13.5V7.5H16.5V13.5H22.5V16.5Z' fill='#059BE0' />
						</svg>
						<span>Add Test Session</span>
					</a>
					: (this.currentTestSession && !this.currentTestSession.deleted && !this.currentTestSession.isEmpty)
						? <a href='#' onClick={() => this.deleteTestSession()}>Delete test session</a>
						: null}
			</>;
		} else {
			return (this.currentTestSession && !this.currentTestSession.deleted && !this.currentTestSession.isEmpty)
				? <a href='#' onClick={() => this.deleteTestSession()}>Delete test session</a>
				: null;
		}
	}

	private changeScore(score: number, validation: IFormControlValidatorResult) {
		this.setState({
			dirty: this.state.dirty || score != this.state.score,
			scoreIncorrect: score > this.props.totalPossible,
			scoreValidation: validation,
			score: score,
			scoreTouched: true,
		});

		this.validateAll();
	}

	private noteChanged(note: string) {
		this.setState({
			dirty: (this.state.dirty || note != this.state.note),
			note: note,
		});
	}

	get testType(): TestType {
		return TestType.Score;
	}

	protected renderCorrectAnswersHeader() {
	}

  private summaryNotesOnChange(event) {
    const value = event.target.value.replace(/[\r\n\v]+/g, '');
    this.setState({note: value});
  }

	protected renderAnswersBlock() {
		return <>
			<div className='score-container'>
				{this.currentTestSession &&
					<>
						Score: {this.props.editMode
							? <TextInput
								value={this.state.score.toString()}
								onEdit={(value, validation) => this.changeScore(value, validation)}
								validator={(value: number) => Validators.integerValidator(value, 0, this.props.totalPossible, 'The score')}
								touched={this.state.scoreTouched}
								validationPlacement={'top'}
							/>
							: this.state.score.toString()}/{this.props.totalPossible.toString()}
					</>
				}
			</div>
      {!this.props.readOnly &&
      <div className='summary-notes-container'>
        <TextArea value={this.state.note}
                  maxLength={255}
                  disabled={!this.props.editMode || !this.props.canChangeSession}
                  rows={3}
                  placeholder='Summary: Enter session summary (optional)'
                  onChange={(e) => this.summaryNotesOnChange(e)}
        />
      </div>}
		</>;
	}

	protected updateAction(): Observable<UpdateResponse> {
		const request: UpdateSSRequest = {
			testSessionID: (this.currentTestSession.id > 0) ? this.currentTestSession.id : 0,
			testDate: this.currentTestSession.testDateIso,
			testDateTouched: this.testDateTouched,
			testID: this.props.testId,
			notes: this.state.note || null,
			testType: TestType.Score,
			score: this.state.score,
			studentID: this.props.studentID,
		};

		return this.props.api.updateSSTestSession(request);
	}
}

