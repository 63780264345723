import {ProgressDashedLine} from '@esgi/main/kits/assignments';
import {BorderedText, Container, Row} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	criteria: { id: number, value: number, maxValue: number, name: string }[],
	maxValue: number
}

const progressBarItemSize = 11;
const linesGap = 2;

export function CriteriaList({criteria, maxValue}: Props) {
	const resultColumnWidth = maxValue * (progressBarItemSize + linesGap) - linesGap;
	return (
		<Container>
			<Row>
				<BorderedText color='lowContrast' size='small' font='mono'>
					Criteria
				</BorderedText>
				<BorderedText css={{width: resultColumnWidth}} color='lowContrast' size='small' font='mono'>
					Result
				</BorderedText>
			</Row>
			<GridBox gap={3}>
				{criteria.map(({id, name, value}) => (
					<Row key={id}>
						<Text size='small' font='mono'>{name}</Text>
						<ProgressDashedLine
							activeLineColor='positive'
							lastActiveLineBorderColor='positive'
							notActiveLineColor = 'positiveMuted'
							linesGap={linesGap}
							value={value}
							maxValue={maxValue}
							progressLineHeight={progressBarItemSize}
							css={{width: resultColumnWidth}}
						/>
					</Row>
				))}
			</GridBox>
		</Container>
	);
}