import {BaseComponentProps} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {ComponentProps, ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';

type Props = BaseComponentProps & ComponentPropsWithoutRef<'div'> & ComponentProps<typeof GridBox>;

export const Root = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-dashboard-statistics-root', css = {}, ...props}, forwardedRef) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef as any} {...props} />
	),
);
