import React from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {IBoxInfo, IDistrictSpecialist, ISchoolSpecialist} from '../../core/api';
import {BoxType} from '../../core/models';
import {Box, BoxOptions, ItemProps} from '../../components/box';

export class State {
}
export class Props {
    boxes: IBoxInfo[];
    selectedID: UserType;
    districtSpecialists: IDistrictSpecialist[];
    schoolSpecialists: ISchoolSpecialist[];
    groupOFSpecialistsID: number;
    onSelected: (id: UserType, callback: () => void) => void;
    onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}


export class Type extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();
    title = 'Specialists';

    get items(): ItemProps[] {
    	const d: ItemProps = {title: 'District Specialists', id: UserType.ISD};
    	const s: ItemProps = {title: 'School Specialists', id: UserType.ISS};

    	return [d, s];
    }

    render(): any {
    	if (this.currentUser.userType !== UserType.D) {
    		return null;
    	}
      if(this.props.groupOFSpecialistsID){
        return null;
      }
    	if (this.props.districtSpecialists.length == 0 && this.props.schoolSpecialists.length == 0) {
    		return null;
    	}

    	const options: BoxOptions = {
    		canAdd: false,
    		canEdit: false,
    		canCreateTooltip: null,
    		title: 'Specialists',
    		canDrag: false,
    		boxType: BoxType.SpecialistType,
    	};

    	return <Box
            open={this.props.boxes.filter(t => t.boxType == BoxType.SpecialistType && t.open).length > 0}
            selectedID={this.props.selectedID}
            scheduledIDs={[]}
            onDragStart={null}
            onAddClicked={() => {}}
            onEditClicked={() => {}}
            options={options}
            empty={{message: 'The list is empty'}}
            onDragEnd={null}
            items={this.items}
            onOpenCloseChanged={this.props.onOpenCloseChanged}
            itemSelected={this.props.onSelected}
        />;
    }

}
