export type ChartSeria = {name: string; y: number; color: '#51C3F2' | '#BDBDBD'};

export function buildChart(series: ChartSeria[], score: number, maxScore: number, max: number) {
	max = max === 0 ? 1 : max;

	series.forEach((element) => {
		if (element.y === 0) {
			element.y = 0.03;
		}
	});

	return {
		chart: {
			type: 'column',
			marginLeft: 50,
			marginRight: 50,
			height: 400,
			style: {
				fontFamily: 'Roboto',
				color: '#333333',
			},
		},
		title: {
			align: 'center',
			style: {
				fontWeight: '500',
				fontSize: '18px',
				color: '#333333',
			},
			text: `Most Recent Score: ${score}/${maxScore}`,
		},
		credits: {
			enabled: false,
		},
		xAxis: {
			title: {
				text: 'Criteria',
				style: {
					fontSize: '18px',
					fontWeight: '500',
					color: '#4F4F4F',
				},
			},
			labels: {
				style: {
					fontSize: '16px',
					fontWeight: '500',
					color: '#4F4F4F',
					textAlign: 'center',
				},
			},
			categories: series.map(({name}) => name),
		},
		yAxis: {
			max: max,
			min: 0,
			tickInterval: 1,
			title: {
				text: 'Score',
				style: {
					fontSize: '18px',
					fontWeight: '500',
					color: '#4F4F4F',
				},
			},
			labels: {
				style: {
					fontSize: '16px',
					fontWeight: '500',
					color: '#4F4F4F',
				},
			},
		},
		legend: {
			enabled: false,
		},
		series: [
			{
				name: 'Score',
				data: series,
			},
		],
		tooltip: {
			formatter: function () {
				return String(Math.floor(this.y));
			},
		},
	};
}
