import {Link} from 'react-router-dom';
import styles from './styles.module.less';
import {RoutesMap} from '@esgi/main/libs/core';

export default function PrivacyPolicyPage() {
	return (
		<div className={styles.root}>
			<div className={styles.container}>
				<h2>
					<b>ESGI, LLC Privacy Policy</b>
				</h2>
				<p>Last updated: December 5, 2023.</p>
				<p>
					ESGI, LLC (“ESGI,” “we,” “our,” or “us”) values the privacy of individuals who use the ESGI website,
					mobile application, and related services (collectively, the “Service”). This Privacy Policy (the
					“Policy”) describes the information we gather from visitors, users, and others who access or use the
					Service (“Users” or “you”), how we use and disclose such information, and the steps we take to protect
					such information. By using the Service, you consent to the privacy practices described in this
					Policy.
				</p>
				<p>This Policy is incorporated into and subject to the ESGI <Link to={RoutesMap.esgiAgreement}>Terms of Use</Link>.
					Capitalized terms used but not defined in this Policy have the meaning given to them in the Terms of
					Use.</p>
				<p><b>1. Information We Collect</b></p>
				<p>
					<i>Personal Information</i>
					<br/>
					WHILE USING THE SERVICE, WE ASK USERS TO PROVIDE US WITH CERTAIN PERSONALLY IDENTIFIABLE INFORMATION 
					THAT CAN BE USED TO CONTACT OR IDENTIFY THEM (“PERSONAL INFORMATION”). PERSONAL INFORMATION MAY INCLUDE 
					YOUR NAME, EMAIL ADDRESS, MAILING ADDRESS, TELEPHONE NUMBER, OTHER CONTACT INFORMATION, INFORMATION 
					ABOUT A CUSTOMER TO THE SERVICE (SUCH AS A SCHOOL, DISTRICT, OR EDUCATIONAL ORGANIZATION, EACH, A “CUSTOMER”), 
					PAYMENT CARD INFORMATION (IF YOU PURCHASE SERVICES THROUGH THE ESGI WEBSITE), AND STUDENT/PARENT INFORMATION. 
					YOU MAY PROVIDE US WITH PERSONAL INFORMATION WHEN YOU REGISTER FOR AN ACCOUNT, USE THE SERVICE, MAKE A PURCHASE 
					ON THE SERVICE, CONTACT CUSTOMER SUPPORT, OR OTHERWISE COMMUNICATE WITH US VIA CHATS, COMMENTS, OR REQUESTS.
				</p>
				<p>
					<i>Usage Information</i>
					<br/>
					WE COLLECT INFORMATION ABOUT HOW YOU ACCESS AND INTERACT WITH THE SERVICE, SUCH AS DEVICE TYPE, IP ADDRESS, 
					OPERATING SYSTEM, BROWSER TYPE, ADDRESS OF A REFERRING WEBSITE, ACTIVITY ON OUR WEBSITE, DEVICE ID, ACCESS DATES 
					AND TIMES, AND OTHER SYSTEM ACTIVITY. WE MAY COLLECT THIS INFORMATION BY AUTOMATED MEANS USING TECHNOLOGIES SUCH 
					AS COOKIES, WEB BEACONS, PIXELS, BROWSER ANALYSIS TOOLS, SERVER LOGS, AND MOBILE IDENTIFIERS. WE MAY USE SUCH 
					USAGE INFORMATION TO, FOR EXAMPLE, BETTER UNDERSTAND WEBSITE TRAFFIC PATTERNS AND TO OPTIMIZE OUR USERS’ EXPERIENCE. 
					WE TRACK USERS’ USE OF THE SERVICE, BUT WE DO NOT TRACK USERS ACROSS THIRD-PARTY WEBSITES. WE DO NOT PROCESS OR 
					RESPOND TO WEB BROWSERS’ “DO NOT TRACK” SIGNALS OR OTHER SIMILAR TRANSMISSIONS THAT INDICATE A REQUEST TO DISABLE 
					ONLINE TRACKING OF USERS WHO VISIT OR USE THE SERVICE.
				</p>
				<p>
					<i>Third-Party Cookies</i>
					<br/>
					THIRD PARTIES MAY ALSO USE COOKIES, WEB BEACONS, OR OTHER DEVICES TO COLLECT INFORMATION WHEN YOU USE THE SERVICE. 
					WE DO NOT CONTROL ANY COOKIES OR OTHER DATA COLLECTION TOOLS THAT MAY BE PLACED ON THE SERVICE BY A THIRD PARTY, 
					AND THIS POLICY DOES NOT COVER THE USE OF SUCH COOKIES AND/OR OTHER DATA COLLECTION TOOLS BY ANY THIRD PARTY. 
					WE ENCOURAGE YOU TO READ THE PRIVACY POLICIES OF SUCH THIRD PARTIES TO FIND OUT MORE ABOUT THE INFORMATION THAT 
					MAY BE COLLECTED BY THEM AND THE CHOICES YOU HAVE REGARDING THE COLLECTION, USE, AND SHARING OF SUCH INFORMATION. 
					YOU MAY BE ABLE TO “OPT OUT” OF THE COLLECTION OF INFORMATION THROUGH COOKIES OR OTHER TRACKING TECHNOLOGY BY ACTIVELY 
					MANAGING THE SETTINGS ON YOUR BROWSER OR MOBILE DEVICE. HOWEVER, PLEASE NOTE THAT DOING SO MAY NEGATIVELY IMPACT 
					YOUR EXPERIENCE USING THE SERVICE, AS SOME FEATURES MAY NOT WORK PROPERLY. DEPENDING ON YOUR MOBILE DEVICE AND 
					OPERATING SYSTEM, YOU MAY NOT BE ABLE TO DELETE OR BLOCK ALL COOKIES.
				</p>
				<p>
					<i>INFORMATION ABOUT OTHERS</i>
					<br/>
					YOU MAY ALSO PROVIDE US WITH A THIRD PARTY’S PERSONAL INFORMATION IN CONNECTION WITH AN ASSESSMENT OR OTHER CLASSROOM 
					ACTIVITIES. SUCH THIRD-PARTY PERSONAL INFORMATION MAY INCLUDE BUT IS NOT LIMITED TO THE INDIVIDUAL’S NAME, STUDENT ID, 
					GRADE, PREFERRED LANGUAGE, AND GENDER. WE COLLECT AND HANDLE ANY SUCH PERSONAL INFORMATION ABOUT OTHERS CONSISTENT 
					WITH THIS PRIVACY POLICY. YOU REPRESENT AND WARRANT THAT YOU HAVE OBTAINED ANY REQUIRED CONSENT, PROVIDED ANY REQUIRED NOTICE, 
					AND OTHERWISE FULLY COMPLIED WITH ALL APPLICABLE LAWS, INCLUDING ANY DATA PROTECTION LAWS, WITH REGARD TO ANY INFORMATION 
					THAT YOU PROVIDE TO US ABOUT OTHERS.
				</p>
				<p>
					<i>INFORMATION FROM OTHER SOURCES</i>
					<br/>
					WE MAY OBTAIN BOTH PERSONAL AND NON-PERSONAL INFORMATION ABOUT YOU FROM BUSINESS PARTNERS, CONTRACTORS, SUPPLIERS, 
					AND OTHER THIRD PARTIES AND ADD IT TO YOUR ACCOUNT INFORMATION OR OTHER INFORMATION WE HAVE COLLECTED. WE MAY ALSO 
					OBTAIN YOUR INFORMATION WHEN IT IS SUBMITTED BY A PARENT OR GUARDIAN, OR A REPRESENTATIVE OF THE INSTITUTION 
					(SUCH AS A SCHOOL, SCHOOL DISTRICT, STATE DEPARTMENT OF EDUCATION, OR OTHER EDUCATIONAL ORGANIZATION) FUNDING YOUR 
					USE OF ESGI SERVICES. WE, AND THE THIRD PARTIES WE ENGAGE, MAY COMBINE INFORMATION WE COLLECT FROM YOU OVER TIME, 
					AND ACROSS THE ESGI WEBSITE, WITH INFORMATION OBTAINED FROM OTHER SOURCES. THIS HELPS US IMPROVE THE INFORMATION’S 
					OVERALL ACCURACY AND COMPLETENESS, AND ALSO HELPS US BETTER TAILOR OUR INTERACTIONS WITH YOU.
				</p>
				<p id='how-to-use'><b>2. How We Use Your Information </b></p>
				<p>
					<i>Provide, Maintain, and Improve the Service</i>
					<br/>
						We may use your information to:
						<ul>
							<li>PROVIDE CUSTOMER SERVICE</li>
							<li>SEND YOU CONFIRMATIONS, UPDATES, SECURITY ALERTS, AND SUPPORT AND ADMINISTRATIVE MESSAGES
							</li>
							<li>DETECT AND PREVENT ILLEGAL ACTIVITY, FRAUD, AND/OR ABUSE</li>
							<li>OPERATE, PROTECT, AND OPTIMIZE THE SERVICE AND YOUR EXPERIENCE, SUCH AS BY PERFORMING ANALYTICS AND CONDUCTING PRODUCT RESEARCH
							</li>
							<li>TROUBLESHOOT AND PROTECT AGAINST ERRORS</li>
							<li>PERSONALIZE AND IMPROVE THE SERVICE</li>
							<li>MONITOR AND ANALYZE USAGE AND TRENDS AND OTHERWISE MEASURE THE EFFECTIVENESS OF THE SERVICE; AND
							</li>
							<li>DEVELOP NEW FEATURES</li>
						</ul>
				</p>
				<p>
					<i>Sharing Your Information</i>
					<br/>
					WE ONLY SHARE PERSONAL INFORMATION IN A FEW LIMITED CIRCUMSTANCES AS SET FORTH BELOW. WE DO NOT RENT OR SELL 
					INFORMATION FOR MARKETING PURPOSES. WE NEVER SHARE OR SELL PERSONAL INFORMATION OF STUDENTS (“STUDENT DATA”) 
					WITH THIRD PARTIES FOR MARKETING PURPOSES AND WILL NOT SELL STUDENT DATA UNDER ANY CIRCUMSTANCES. IN ADDITION, 
					WE DO NOT USE ANY BEHAVIORAL INFORMATION TO PROVIDE TARGETED ADVERTISING TO STUDENTS. IF YOU USE THE SERVICE, 
					YOU ARE AUTHORIZING US TO SHARE INFORMATION IN THE FOLLOWING WAYS:
						<ul>
							<li>WITH A THIRD PARTY, AS DIRECTED BY YOU OR A CUSTOMER</li>
							<li>WITH OUR BUSINESS PARTNERS, VENDORS, AND CONSULTANTS WHO PERFORM SERVICES ON OUR BEHALF OR 
								WHO HELP US PROVIDE OUR SERVICE, SUCH AS ACCOUNTING, MANAGERIAL, TECHNICAL, MARKETING, OR ANALYTIC SERVICES, 
								PROVIDED THAT THEY ARE SUBJECT TO CONFIDENTIALITY AND SECURITY OBLIGATIONS
							</li>
							<li>IF REQUIRED TO DO SO BY LAW OR IN THE GOOD-FAITH BELIEF THAT SUCH ACTION IS NECESSARY TO COMPLY WITH LOCAL, 
								STATE, OR FEDERAL LAWS, OR TO RESPOND TO A COURT ORDER, JUDICIAL, OR OTHER GOVERNMENTAL SUBPOENA OR WARRANT, 
								OR IN THE EVENT OF BANKRUPTCY PROCEEDINGS
							</li>
							<li>IF WE BELIEVE IN GOOD FAITH THAT DOING SO IS REASONABLY NECESSARY OR APPROPRIATE TO PROTECT THE RIGHTS, 
								PROPERTY, OR SAFETY OF ESGI, OUR USERS, OUR EMPLOYEES, COPYRIGHT OWNERS, THIRD PARTIES, OR THE PUBLIC, 
								INCLUDING TO PROTECT ESGI OR OUR USERS FROM FRAUDULENT, ABUSIVE, INAPPROPRIATE, OR UNLAWFUL USE OF OUR SERVICE
							</li>
							<li>TO ENFORCE OR APPLY THIS POLICY, OUR TERMS OF USE, OR OUR OTHER POLICIES OR AGREEMENTS</li>
							<li>IN CONNECTION WITH ANY MERGER, SALE OF COMPANY ASSETS, FINANCING OR ACQUISITION, OR IN ANY OTHER SITUATION 
								WHERE INFORMATION MAY BE DISCLOSED OR TRANSFERRED AS ONE OF THE BUSINESS ASSETS OF ESGI; PROVIDED, 
								IF THE RIGHTS AND OBLIGATIONS WITH RESPECT TO YOUR PERSONAL INFORMATION ARE ASSIGNED TO A THIRD-PARTY 
								SUCCESSOR-IN-INTEREST, THE SUCCESSOR WILL BE BOUND, WITH RESPECT TO YOUR PERSONAL INFORMATION, BY THE TERMS OF 
								THE THEN-APPLICABLE PRIVACY POLICY OR WILL ADOPT A PRIVACY POLICY THAT IS SUBSTANTIALLY SIMILAR IN ALL MATERIAL 
								RESPECTS WITH SUCH PRIVACY POLICY; PROVIDED, FURTHER, IF THE SUCCESSOR DOES NOT AGREE TO BE BOUND BY THE TERMS 
								OF THE THEN-APPLICABLE PRIVACY POLICY AND DOES NOT ADOPT A PRIVACY POLICY THAT IS SUBSTANTIALLY SIMILAR IN 
								ALL MATERIAL RESPECTS WITH SUCH PRIVACY POLICY, YOU WILL HAVE RECOURSE TO ANY REMEDIES AVAILABLE TO YOU AT 
								LAW OR IN EQUITY, WHICH MAY INCLUDE THE RIGHT TO TERMINATE YOUR COMMERCIAL RELATIONSHIP WITH THE SUCCESSOR 
								OR TO HAVE THE SUCCESSOR RETURN YOUR INFORMATION TO YOU; AND/OR
							</li>
							<li>IN AN AGGREGATED AND/OR ANONYMOUS FORM THAT DOES NOT REASONABLY IDENTIFY A USER OR CUSTOMER
							</li>
						</ul>
				</p>
				<p>
					<i>Links to Third-Party Sites and Services</i>
					<br/>
					THE SERVICE MAY CONTAIN LINKS TO OTHER WEBSITES. WE EXERCISE COMMERCIALLY REASONABLE EFFORTS TO ONLY LINK TO THE WEBSITES 
					AND SERVICES OF REPUTABLE THIRD PARTIES WHO POST A PRIVACY POLICY GOVERNING THEIR PROCESSING OF PERSONAL INFORMATION 
					THAT ALIGNS WITH THIS POLICY. HOWEVER, WE ARE NOT RESPONSIBLE FOR THE PRACTICES EMPLOYED BY WEBSITES OR SERVICES LINKED 
					TO OR FROM THE SERVICE, NOR THE INFORMATION OR CONTENT PROVIDED TO OR CONTAINED THEREIN. PLEASE REMEMBER THAT WHEN YOU 
					USE A LINK TO GO FROM THE SERVICE TO ANOTHER WEBSITE, OUR PRIVACY POLICY DOES NOT APPLY TO THOSE THIRD-PARTY WEBSITES 
					OR SERVICES. YOUR BROWSING AND INTERACTION ON ANY THIRD-PARTY WEBSITE OR SERVICE, INCLUDING THOSE THAT HAVE A LINK ON 
					OUR SERVICE, ARE SUBJECT TO THAT THIRD PARTY’S RULES AND POLICIES. PLEASE READ THOSE RULES AND POLICIES BEFORE PROCEEDING.
				</p>
				<p>
					<i>DATA RETENTION</i>
					<br/>
					WE RETAIN PERSONAL INFORMATION OF USERS OF OUR SERVICES: (1) FOR AS LONG AS REASONABLY NECESSARY FOR THEM TO USE 
					OF OUR SERVICES AND (2) AS REQUIRED BY LAW, CONTRACTUAL COMMITMENT, AND OUR BACKUP RETENTION AND DISASTER RECOVERY 
					PRACTICES. UPON RECEIVING A WRITTEN REQUEST FROM YOU, WE WILL RETURN OR DELETE PERSONAL INFORMATION UNDER YOUR 
					CONTROL FROM OUR SYSTEMS, SUBJECT TO APPLICABLE LAW AND OUR BACKUP RETENTION PRACTICES. WE CAN PROVIDE A CERTIFICATION 
					OF SUCH DELETION OR RETURN IF REQUESTED. THIS DELETION OR RETURN PROCEDURE APPLIES TO PERSONAL INFORMATION AND 
					DOES NOT APPLY TO DE-IDENTIFIED INFORMATION. WE RETAIN DE-IDENTIFIED INFORMATION IN ACCORDANCE WITH OUR STANDARD 
					PRACTICES FOR SIMILAR INFORMATION.
				</p>
				<p><b>3. How We Protect Your Information</b></p>
				<p>
					WE TAKE CERTAIN PHYSICAL, ADMINISTRATIVE, AND TECHNICAL MEASURES DESIGNED TO PROTECT YOUR INFORMATION IN AN EFFORT 
					TO PREVENT LOSS, MISUSE, UNAUTHORIZED ACCESS, DISCLOSURE, ALTERATION, AND DESTRUCTION. FOR EXAMPLE, WE USE 
					INDUSTRY-STANDARD ENCRYPTION FOR DATA IN TRANSIT AND AT REST WHEN COLLECTING, TRANSFERRING, STORING, AND PROCESSING 
					YOUR INFORMATION. OTHER SAFEGUARDS INCLUDE BUT ARE NOT LIMITED TO FIREWALLS, CYBERSECURITY TRAINING FOR PERSONNEL, 
					AND PHYSICAL-ACCESS CONTROLS.
				</p>
				<p>
					IF WE SHARE YOUR INFORMATION WITH THIRD PARTIES AS PERMITTED UNDER THESE TERMS, WE ENSURE THAT THOSE PARTIES 
					HAVE POLICIES AND PRACTICES THAT ARE CONSISTENT WITH OUR OWN.
				</p>
				<p>
					PLEASE BE AWARE, HOWEVER, THAT DESPITE OUR EFFORTS, NO SECURITY MEASURE IS PERFECT OR IMPENETRABLE, AND NO METHOD 
					OF DATA TRANSMISSION CAN BE GUARANTEED AGAINST ANY INTERCEPTION OR OTHER TYPES OF MISUSE. TO PROTECT THE CONFIDENTIALITY 
					OF INFORMATION MAINTAINED IN YOUR ACCOUNT, YOU MUST KEEP YOUR PASSWORD CONFIDENTIAL AND NOT DISCLOSE IT TO ANY OTHER PERSON. 
					YOU ARE RESPONSIBLE FOR ALL USES OF THE SERVICE BY ANY PERSON USING YOUR PASSWORD. PLEASE ADVISE US IMMEDIATELY 
					IF YOU BELIEVE YOUR PASSWORD HAS BEEN MISUSED. IF THERE IS ANY DISCLOSURE OR ACCESS TO ANY STUDENT DATA BY AN 
					UNAUTHORIZED PARTY, WE WILL PROMPTLY NOTIFY THE AFFECTED CUSTOMER AND WILL USE REASONABLE EFFORTS TO COOPERATE WITH 
					THEIR INVESTIGATION OF THE INCIDENT.
				</p>
				<p><b>4. Legal Compliance and Privacy Rights</b></p>
				<p>
					<i>Legal Compliance</i>
					<br/>
					WE COMPLY WITH THE FOLLOWING LAWS CONCERNING THE PROTECTION OF STUDENT DATA, INCLUDING EDUCATIONAL RECORDS: 
					FAMILY EDUCATIONAL RIGHTS AND PRIVACY ACT (“FERPA”), CHILDREN’S ONLINE PRIVACY PROTECTION ACT (“COPPA”), 
					PROTECTION OF PUPIL RIGHTS AMENDMENT, AND STUDENT ONLINE PERSONAL INFORMATION PROTECTION ACT. WE WILL BE CONSIDERED 
					A SCHOOL OFFICIAL AS THAT TERM IS USED IN FERPA. AS TO COPPA OR ANY STATE LAW REQUIRING CONSENT OR AUTHORIZATION 
					FROM A PARENT OR GUARDIAN FOR STUDENT DATA COLLECTION OR USE, THE PARENT, GUARDIAN, TEACHER, OR CUSTOMER IS 
					RESPONSIBLE FOR FULFILLING ANY APPLICABLE CONSENT REQUIREMENT.
				</p>
				<p>
					<i>CCPA</i>
					<br/>
					CALIFORNIA LAW REQUIRES US TO DISCLOSE CERTAIN INFORMATION REGARDING THE CATEGORIES OF PERSONAL INFORMATION WE COLLECT. 
					FOR PURPOSES OF THIS SECTION, “PERSONAL INFORMATION” HAS THE MEANING PROVIDED BY THE CALIFORNIA CONSUMER PRIVACY ACT, 
					AS AMENDED BY THE CALIFORNIA PRIVACY RIGHTS ACT (COLLECTIVELY REFERRED TO HEREIN AS THE “CCPA”) AND DOES NOT INCLUDE 
					INFORMATION THAT IS PUBLICLY AVAILABLE, DEIDENTIFIED, OR AGGREGATED, OR THAT IS EXCLUDED FROM THE CCPA’S SCOPE, 
					SUCH AS PERSONAL INFORMATION COVERED BY CERTAIN SECTOR-SPECIFIC PRIVACY LAWS. UNDER THE CCPA, WE COLLECT, RETAIN, 
					USE, AND DISCLOSE STUDENT DATA AS A SERVICE PROVIDER TO OUR CUSTOMERS. FOR INFORMATION ABOUT OUR PRACTICES WITH REGARD 
					TO STUDENT DATA OR ANY OTHER PERSONAL INFORMATION WE PROCESS AS A SERVICE PROVIDER, 
					PLEASE SEE THE SECTION  “<a href='#how-to-use'>How We Use Your Information</a>.” IF YOU HAVE 
					A QUESTION OR WOULD LIKE TO EXERCISE YOUR CALIFORNIA CONSUMER RIGHTS TO KNOWLEDGE, ACCESS, OR DELETION, PLEASE 
					CONTACT YOUR CUSTOMER DIRECTLY.
				</p>
				<p>
					WE COLLECT PERSONAL INFORMATION FROM AND ABOUT YOU FOR A VARIETY OF PURPOSES. FOR EXAMPLE, WE USE PERSONAL 
					INFORMATION TO RESPOND TO YOUR REQUESTS, ENGAGE WITH YOU AS A CUSTOMER, AND PROVIDE OUR SERVICE TO YOU; 
					TO MONITOR, IMPROVE, AND DEVELOP THE SERVICE; TO PROTECT THE SECURITY AND INTEGRITY OF OUR BUSINESS; COMPLY 
					WITH LEGAL REQUIREMENTS AND OBLIGATIONS; FOR OUR BUSINESS AND OPERATIONAL PURPOSES; AND AS OTHERWISE PERMITTED 
					OR REQUIRED BY LAW. THE TYPE OF PERSONAL INFORMATION WE COLLECT WILL DEPEND ON HOW YOU ENGAGE WITH US 
					(E.G., AS A CUSTOMER OR POTENTIAL CUSTOMER OR AS A WEBSITE VISITOR). WE COLLECT INFORMATION DIRECTLY FROM YOU, 
					FROM OUR BUSINESS PARTNERS AND AFFILIATES, FROM YOUR BROWSER OR DEVICE WHEN YOU VISIT OUR WEBSITE, OR FROM THIRD 
					PARTIES THAT YOU PERMIT TO SHARE INFORMATION WITH US. WE COLLECT THE FOLLOWING CATEGORIES OF PERSONAL INFORMATION:
					<ul>
						<li>IDENTIFIERS, SUCH AS YOUR NAME, ADDRESS, PHONE NUMBER, EMAIL ADDRESS, OR OTHER SIMILAR IDENTIFIERS
						</li>
						<li>CALIFORNIA CUSTOMER RECORDS, SUCH AS PAYMENT INFORMATION</li>
						<li>COMMERCIAL INFORMATION, SUCH AS A HISTORY OF PURCHASES, OBTAINED OR CONSIDERED</li>
						<li>INTERNET/NETWORK INFORMATION, SUCH AS DEVICE INFORMATION, LOGS, AND ANALYTICS DATA</li>
						<li>GEOLOCATION DATA, SUCH AS PRECISE LOCATION DATA FROM YOUR DEVICE OR GENERATED BASED ON YOUR IP ADDRESS; AND
						</li>
						<li>INFERENCES ABOUT YOUR INTERESTS AND PREFERENCES</li>
					</ul>
				</p>
				<p>
					WE SHARE PERSONAL INFORMATION WITH THIRD PARTIES FOR BUSINESS PURPOSES. THE CATEGORIES OF THIRD PARTIES TO 
					WHOM WE DISCLOSE YOUR PERSONAL INFORMATION MAY INCLUDE OUR SERVICE PROVIDERS AND ADVISORS, VENDORS WHO ASSIST 
					US WITH MARKETING AND ADVERTISING OUR SERVICES, ANALYTICS PROVIDERS, AND THIRD PARTIES WITH YOUR CONSENT OR 
					DIRECTION OR WITH WHOM YOU INTERACT THROUGH THE SERVICE. WHEN WE DISCLOSE YOUR PERSONAL INFORMATION TO OUR 
					SERVICE PROVIDERS, VENDORS, OR OTHER THIRD PARTIES THAT PROCESS THE INFORMATION ON OUR BEHALF, WE HAVE ENTERED 
					INTO A WRITTEN CONTRACT WITH THEM TO PROTECT YOUR PERSONAL INFORMATION, AND WE WILL REMAIN LIABLE FOR ANY BREACH 
					OF THIS POLICY THAT IS CAUSED BY THE ACTS OR OMISSIONS OF OUR SERVICE PROVIDERS. WE DO NOT SELL YOUR PERSONAL 
					INFORMATION FOR MONETARY OR OTHER VALUABLE CONSIDERATION.
				</p>
				<p>
					AS A CALIFORNIA RESIDENT, YOU MAY BE ABLE TO EXERCISE THE FOLLOWING RIGHTS UPON VERIFICATION OF YOUR IDENTITY:
					<ul>
						<li>THE RIGHT TO KNOW WHAT PERSONAL INFORMATION WE HAVE ABOUT YOU, WHERE THAT PERSONAL INFORMATION CAME FROM, 
							AND HOW IT IS USED
						</li>
						<li>THE RIGHT TO CORRECT INACCURATE PERSONAL INFORMATION WE HAVE ABOUT YOU</li>
						<li>THE RIGHT TO KNOW WHETHER YOUR PERSONAL INFORMATION IS SOLD OR DISCLOSED AND TO WHOM</li>
						<li>THE RIGHT TO OPT-OUT OF THE SALE OR DISCLOSURE OF YOUR PERSONAL INFORMATION</li>
						<li>THE RIGHT TO HAVE YOUR PERSONAL INFORMATION DELETED</li>
						<li>THE RIGHT TO LIMIT THE USE OR DISCLOSURE OF YOUR SENSITIVE PERSONAL INFORMATION</li>
						<li>THE RIGHT TO REQUEST THAT WE NOT REACH DECISIONS AFFECTING YOU USING AUTOMATED PROCESSING OR PROFILING; AND</li>
						<li>THE RIGHT TO BE FREE OF DISCRIMINATION FOR EXERCISING THESE RIGHTS</li>
					</ul>
				</p>
				<p>
					HOWEVER, PLEASE NOTE THAT IF THE EXERCISE OF THESE RIGHTS LIMITS OUR ABILITY TO PROCESS PERSONAL INFORMATION 
					(SUCH AS IN THE CASE OF A DELETION REQUEST), WE MAY NO LONGER BE ABLE TO PROVIDE YOU THE SERVICE OR ENGAGE WITH YOU 
					IN THE SAME MANNER. IF YOU WISH TO EXERCISE ANY OF THE ABOVE RIGHTS UNDER THE CCPA, PLEASE CONTACT 
					US AT <a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a>.
				</p>
				<p>
					<i>NY Parents’ Bill of Rights</i>
					<br/>
					THE NEW YORK PARENTS’ BILL OF RIGHTS FOR DATA PRIVACY AND SECURITY (THE “PRIVACY BILL OF RIGHTS”) ADDRESSES 
					THE RELATIONSHIP BETWEEN SCHOOLS AND THEIR THIRD-PARTY CONTRACTORS, IN ADDITION TO THE SCHOOLS’ RELATIONSHIPS WITH PARENTS. 
					THE ONLY ELEMENTS OF THE PRIVACY BILL OF RIGHTS THAT ARE INCORPORATED HEREIN ARE THOSE PROVISIONS DIRECTED TO 
					THIRD-PARTY CONTRACTORS (“CONTRACTOR PRIVACY PROVISIONS”). WE AGREE TO COMPLY WITH THE CONTRACTOR PRIVACY PROVISIONS 
					FOR SCHOOLS IN THE STATE OF NEW YORK. IN THE EVENT OF A DIRECT CONFLICT BETWEEN THIS POLICY AND THE CONTRACTOR 
					PRIVACY PROVISIONS, THE CONTRACTOR PRIVACY PROVISIONS WILL CONTROL. THE FULL EXTENT OF THE PRIVACY BILL OF RIGHTS 
					IS AVAILABLE AT <a href='http://www.nysed.gov/data-privacy-security/bill-rights-data-privacy-and-security-parents-bill-rights'>http://www.nysed.gov/data-privacy-security/bill-rights-data-privacy-and-security-parents-bill-rights</a>.
				</p>
				<p>
					<i>International Privacy Rights</i>
					<br/>
					ANY INFORMATION COLLECTED THROUGH THE SERVICE IS STORED AND PROCESSED IN THE UNITED STATES. IF YOU USE OUR SERVICE 
					OUTSIDE OF THE UNITED STATES, YOU CONSENT TO HAVE YOUR DATA TRANSFERRED TO THE UNITED STATES. WE ARE COMMITTED TO 
					PROTECTING THE PRIVACY AND CONFIDENTIALITY OF PERSONALLY IDENTIFIABLE INFORMATION WHEN IT IS TRANSFERRED. IF YOU 
					RESIDE OR ARE LOCATED WITHIN THE EU, SWITZERLAND, OR THE UK AND SUCH TRANSFERS OCCUR, WE TAKE APPROPRIATE STEPS 
					IN AN EFFORT TO PROVIDE THE SAME LEVEL OF PROTECTION FOR THE PROCESSING CARRIED OUT IN ANY SUCH COUNTRIES AS YOU 
					WOULD HAVE WITHIN THE EU, SWITZERLAND, OR THE UK TO THE EXTENT FEASIBLE UNDER APPLICABLE LAW. YOU ARE ENTITLED TO 
					THE RIGHTS UNDER CHAPTER III OF THE EU GENERAL DATA PROTECTION REGULATION AND THE UK GDPR, OR SECTION 2 OF THE SWISS 
					FEDERAL ACT ON DATA PROTECTION CONCERNING THE PROCESSING OF YOUR INFORMATION, WHICH INCLUDES RIGHTS TO ACCESS, 
					CORRECT, DELETE, RESTRICT OR OBJECT TO OUR USE OF, OR RECEIVE A PORTABLE COPY OF YOUR PERSONAL INFORMATION.
				</p>
				<p>
					<i>FAMILY EDUCATIONAL RIGHTS AND PRIVACY ACT (FERPA)</i>
					<br/>
					THE FAMILY EDUCATIONAL RIGHTS AND PRIVACY ACT (“FERPA”) PROVIDES STUDENTS WITH PRIVACY RIGHTS AND PROTECTION 
					FOR CERTAIN EDUCATION-RELATED PERSONAL INFORMATION THAT IS COLLECTED AND PROCESSED ON BEHALF OF EDUCATIONAL 
					INSTITUTIONS RECEIVING GOVERNMENT FUNDING FROM THE U.S. DEPARTMENT OF EDUCATION. FERPA PROVIDES STUDENTS WITH 
					RIGHTS TO OBTAIN FROM THESE EDUCATIONAL INSTITUTIONS ACCESS TO THEIR EDUCATIONAL RECORDS, AS WELL AS CORRECTION 
					AND AMENDMENT OF THEIR EDUCATIONAL RECORDS. FOR ANY FERPA REQUESTS OR QUESTIONS, PLEASE CONTACT 
					US AT <a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a>.
				</p>
				<p><b>5. Children’s Privacy</b></p>
				<p>
					THE CHILDREN'S ONLINE PRIVACY PROTECTION ACT OF 1998 AND ITS RULES (COLLECTIVELY, “COPPA”) REQUIRE US TO INFORM 
					PARENTS AND LEGAL GUARDIANS ABOUT OUR PRACTICES FOR COLLECTING, USING, AND DISCLOSING PERSONAL INFORMATION FROM 
					CHILDREN UNDER THE AGE OF 13 (“CHILD” OR “CHILDREN”). 
				</p>
				<p>
					IF A CHILD IS REGISTERED FOR, OR OTHERWISE USES, CERTAIN ESGI PRODUCTS OR SERVICES, WE MAY COLLECT AND USE 
					PERSONAL INFORMATION FROM SUCH CHILD, THEIR PARENT OR GUARDIAN, OR THE APPLICABLE SPONSORING ORGANIZATION. 
					AS A PART OF OUR SERVICES, WE MAY ALSO PROVIDE THIS PERSONAL INFORMATION TO THE CHILD’S PARENT OR GUARDIAN, 
					OR THE APPLICABLE SPONSORING ORGANIZATION. WE DO NOT USE SUCH PERSONAL INFORMATION FOR ANY OTHER COMMERCIAL PURPOSE. 
					A TEACHER, PARENT OR GUARDIAN MAY REVIEW OR TERMINATE THEIR CHILD’S REGISTRATION, AND REFUSE TO PERMIT 
					THE FURTHER COLLECTION OR USE OF THEIR CHILD’S PERSONAL INFORMATION, AT ANY TIME BY CONTACTING US 
					AT <a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a>.  
					IN ANY EVENT, WE WILL DELETE A CHILD’S PERSONAL INFORMATION ONCE THE INFORMATION IS NO LONGER NEEDED FOR ITS EDUCATIONAL PURPOSE. 
				</p>
				<p>
					TEACHERS OF CHILDREN MUST OBTAIN CORRECT PARENTAL CONSENT AS REQUIRED BY THEIR SCHOOL POLICIES AND MUST COMPLY 
					WITH THE PRIVACY LAWS OF THEIR JURISDICTION (INCLUDING FOR COPPA AND FERPA) BEFORE ADDING CHILDREN TO THE SITES, 
					PRODUCTS, OR SERVICES.
				</p>
				<p><b>6. Your Choices and Opting Out</b></p>
				<p>
					STUDENT DATA IS PROVIDED AND CONTROLLED BY CUSTOMER. IF YOU HAVE ANY QUESTIONS ABOUT REVIEWING, MODIFYING, 
					OR DELETING STUDENT DATA, PLEASE CONTACT CUSTOMER DIRECTLY. FOLLOWING TERMINATION OR DEACTIVATION OF YOUR ACCOUNT, 
					WE MAY RETAIN PROFILE INFORMATION AND CONTENT FOR A COMMERCIALLY REASONABLE TIME FOR BACKUP, ARCHIVAL, OR AUDIT 
					PURPOSES, BUT ANY AND ALL STUDENT DATA ASSOCIATED WITH THE ACCOUNT WILL BE PROMPTLY DELETED. YOU MAY “OPT OUT” OF 
					RECEIVING MARKETING OR PROMOTIONAL EMAILS FROM US BY FOLLOWING THE INSTRUCTIONS IN THOSE EMAILS. IF YOU OPT OUT, 
					WE MAY STILL SEND YOU NON-PROMOTIONAL EMAILS, SUCH AS EMAILS ABOUT YOUR ACCOUNT OR OUR ONGOING BUSINESS RELATIONS.
				</p>
				<p><b>7. Changes and Updates to the Policy</b></p>
				<p>
					WE RESERVE THE RIGHT TO CHANGE THE PROVISIONS OF THIS POLICY AT ANY TIME, IN WHICH CASE WE WILL NOTIFY YOU 
					THROUGH THE SERVICE AND ASK FOR YOUR CONSENT TO ANY MATERIAL CHANGES. CONSENT TO THESE MATERIAL CHANGES WOULD BE 
					ESTABLISHED BY HAVING YOU AGREE TO THE PROVISIONS OF THE REVISED POLICY BY THE SAME METHOD YOU AGREED TO THIS POLICY, 
					WHICH MAY INCLUDE CLICKWRAP CONSENT OR THE EXECUTION OF A SEPARATE CONTRACTUAL DOCUMENT, OR BY SOME OTHER MUTUALLY 
					AGREED UPON MEANS. WE RECOMMEND THAT YOU REVISIT THIS PAGE PERIODICALLY IF YOU HAVE QUESTIONS ABOUT HOW WE PROCESS 
					YOUR DATA. YOUR ACCEPTANCE OF THE NEW POLICY AND CONTINUED USE OF THE SERVICE INDICATE THAT YOU HAVE READ, UNDERSTOOD, 
					AND AGREED TO THE UPDATED VERSION OF THIS POLICY.
				</p>
				<p><b>8. Contact</b></p>
				<p>
					PLEASE CONTACT US WITH ANY QUESTIONS REGARDING THIS POLICY AT <a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a>.
				</p>
			</div>
		</div>
	);
}
