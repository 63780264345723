import * as React from 'react';
import type {SVGProps} from 'react';

export function CommentAdd(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M5.11035 9.28577C5.11035 7.76593 6.34345 6.53369 7.86434 6.53369H10.9383C11.3183 6.53369 11.6263 6.84173 11.6263 7.22171C11.6263 7.60169 11.3183 7.90973 10.9383 7.90973H7.86434C7.10355 7.90973 6.48734 8.52551 6.48734 9.28577V16.1659L8.14387 14.9193C8.26298 14.8298 8.40412 14.7899 8.55283 14.7899H16.1263C16.8871 14.7899 17.5033 14.1741 17.5033 13.4139V12.9383C17.5033 12.5581 17.8115 12.2498 18.1918 12.2498C18.572 12.2498 18.8803 12.5581 18.8803 12.9383V13.4139C18.8803 14.9337 17.6472 16.1659 16.1263 16.1659H8.78967L6.20781 18.1007C5.7534 18.4412 5.11035 18.1089 5.11035 17.542V9.28577Z' fill='#666666'/>
			<path d='M13.5 7.19252C13.5 6.81227 13.8083 6.50402 14.1885 6.50402H17.6965C18.0767 6.50402 18.385 6.81227 18.385 7.19252C18.385 7.57276 18.0767 7.88101 17.6965 7.88101H14.1885C13.8083 7.88101 13.5 7.57276 13.5 7.19252Z' fill='#666666'/>
			<path d='M15.9425 9.63498C15.5623 9.63498 15.254 9.32673 15.254 8.94648V5.4385C15.254 5.05825 15.5623 4.75 15.9425 4.75C16.3228 4.75 16.631 5.05825 16.631 5.4385V8.94648C16.631 9.32673 16.3228 9.63498 15.9425 9.63498Z' fill='#666666'/>
		</svg>
	);
}
