import {useCallback} from 'react';
import {FiltersState} from '../../types';
import {filterSessions} from '../../utils/filter-sessions';
import {SelectField, TestSession, TrackDate} from '../../../../types';

interface Props{
	setFieldValue: (val: SelectField) => void,
	trackDates: TrackDate[],
	testSessions: TestSession[],
	setFiltersState: (val: FiltersState) => void,
	setSessions: (val: TestSession[]) => void,
} 
export const useFilteredSession = ({
	setFieldValue,
	trackDates,
	testSessions,
	setFiltersState,
	setSessions,
}: Props) => {
	const filteredSessionsData = (
		field: SelectField,
		value: string | (string | Date)[]
	) => {
		setFieldValue(field);
		const filteredSessionsValue = !value.length
			? trackDates.find((item) => new Date(item.dateTo) > new Date())?.dateFrom
			: value;
		const filteredSessionsField = !value.length ? 'period' : field;
		const filteredSessions = filterSessions(
			filteredSessionsValue,
			testSessions,
			trackDates
		);
		return {
			filteredSessionsValue,
			filteredSessionsField,
			filteredSessions,
		};
	};

	const onFilterValueChange = useCallback(
		(field: SelectField) => (value: string | (string | Date)[]) => {
			const {filteredSessionsValue, filteredSessionsField, filteredSessions} =
				filteredSessionsData(field, value);
			setFiltersState({[filteredSessionsField]: filteredSessionsValue});
			setSessions(filteredSessions);
		},
		[filteredSessionsData, setFiltersState, setSessions]
	);

	return {filteredSessionsData, onFilterValueChange};
};
