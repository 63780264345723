import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {StudentProgressReportService} from '../../../../services';
import {
	Cell,
	Description,
	GradeContainer,
	GradeDescriptions, GradeName,
	HeaderRow,
	MainTable,
	Row,
	Table,
	TableBody,
} from './index.styled';
import {Report, ReportDisplayResultsMode} from '../../../../types';
import React from 'react';

interface Props {
	service: StudentProgressReportService
	report: Report
}

export default function StudentsTable({service, report}: Props) {
	const markingPeriods = useBehaviorSubject(service.markingPeriods$);
	const testResultsCorrectVerbiage = useBehaviorSubject(service.testResultsCorrectVerbiage$);
	const {showBaseline, showGradeColors, displayZeroIfNotTested} = useBehaviorSubject(service.reportSettings$);
	const displayType = useBehaviorSubject(service.displayType$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);

	return <MainTable data-cy='progress-report-table'>
		{
			report.students.map((student, index) => <TableBody data-cy='one-student-table'>
			<Row>
				<Cell space20/>
			</Row>
			<Row>
				<Cell data-cy='student-name' studentName colSpan={markingPeriods.length + 2}>
					{student.firstName} {student.lastName}
				</Cell>
			</Row>
			{
				student.subjects?.map((subject, index) => <>
					{
						displayType === ReportDisplayResultsMode.Grade && report.gradeScales[0]?.gradeValues && report?.gradeScales?.length !== 1 && <>
							<Row/>
							<Row>
								<Cell colSpan={markingPeriods.length + 2} style={{paddingTop: index === 0 ? '10px' : '30px'}}>
									<GradeDescriptions>
										{
											report.gradeScales[0].gradeValues.map((item) => <GradeContainer>
												<GradeName style={{backgroundColor: showGradeColors ? item.color : 'white'}}>
													{item.name}
												</GradeName>
												<Description>{item.description}</Description>
											</GradeContainer>)
										}
									</GradeDescriptions>
								</Cell>
							</Row>
						</>
					}
					<Row>
						<Cell spaceDynamic={index === 0 || (displayType === ReportDisplayResultsMode.Grade && report.gradeScales[0]?.gradeValues !== null)} />
					</Row>
					{
						displayType === ReportDisplayResultsMode.Percent && <Row>
							<Cell/>
							<Cell percentageCorrectLabel colSpan={ markingPeriods.length - (showBaseline ? 0 : 1)}>
								<span>Percentage {testResultsCorrectVerbiage}</span>
							</Cell>
						</Row>
					}
					<HeaderRow>
						<Cell subjectInfo bgGray>
							<Table>
								<Row>
									<Cell data-cy='subject-name' subjectName>{subjects.find(x => x.id === subject.subjectID)?.name}</Cell>
									<Cell>:</Cell>
								</Row>
							</Table>
						</Cell>
						{
							markingPeriods?.map((item, mpIndex) => ((showBaseline || mpIndex !== 0)) &&
								<Cell data-cy='marking-period-header' simple bgGray bold={mpIndex === 0} markingPeriod>
									{item.title}
								</Cell>)
						}
						<Cell>_</Cell>
					</HeaderRow>
					{
						subject?.tests?.map((test, testIndex) => <Row data-cy='test-row' bgLightgray={testIndex % 2 !== 0} dataBody>
							<Cell testInfo title={test.testName}>
								<Cell data-cy='test-name' testName>{test.testName}</Cell>
							</Cell>
							{
								test.scores?.map((score, scoreIndex) => <>
									{
										(showBaseline || scoreIndex !== 0) && <>
											{
												displayType === ReportDisplayResultsMode.Score && <Cell simple>
														{score.testDate == null && !displayZeroIfNotTested ? 'NT' : score.score}
													</Cell>
											}
											{
												displayType === ReportDisplayResultsMode.Percent && <Cell simple>
														{score.testDate == null && !displayZeroIfNotTested ? 'NT' : score.percent}
													</Cell>
											}
											{
												displayType === ReportDisplayResultsMode.Grade && <Cell simple style={{backgroundColor: showGradeColors ? score.testDate != null ? score.color : 'rgba(228, 228, 228, 0.5)' : null}}>
														{score.testDate == null ? !displayZeroIfNotTested ? 'NT' : 0 : score.grade}
													</Cell>
											}
										</>
									}
								</>)
							}
							<Cell bgWhite/>
						</Row>)
					}
				</>)
			}
			<Row>
			</Row>
			{
				report.students.length - 1 !== index && <Row>
					<Cell pageBreak colSpan={markingPeriods.length + 2}>
						<span>page break</span>
					</Cell>
				</Row>
			}
			</TableBody>)
		}
	</MainTable>;
}