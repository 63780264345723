import {styled} from '@esgi/ui/theme';
import {Thumb} from '@radix-ui/react-slider';

export const SliderThumb = styled(Thumb, {
	display: 'block',
	width: 12,
	height: 12,
	borderRadius: '50%',
	borderStyle: 'solid',
	borderWidth: 1.5,
	borderColor: '$blue',
	backgroundColor: '$background',
	boxSizing: 'border-box',
	outline: 'none',
	appearance: 'none',

	'&:hover': {
		borderColor: '$secondary',
	},

	'&:focus, &:focus-visible': {
		borderColor: '$primary',
	},

	'&[data-disabled]': {
		borderColor: '$muted',
	},
});
