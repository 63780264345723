import React from 'react';
import {ProgressBar} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {RainbowRing} from '@esgi/ui/icons';
import {TestSessionProgress, TestSessionResult} from '../../types';
import {ToggleGroupItem, ToggleGroupRoot, ToggleGroupContent} from '../../index.styled';
import {
	EmojiBox,
	Label,
	Container,
	CorrectAnswersCount,
	ProgressBarContainer,
	ProgressBarLabel,
	ProgressBarRoot,
} from './index.styled';
import {useUser} from '@esgi/core/authentication';

interface Props {
	isDisabled?: boolean;
	testSessionResult: TestSessionResult;
	onChange: (v: TestSessionResult) => void;
	testSessionProgress: TestSessionProgress;
}

export function TestSessionResultsView({isDisabled, testSessionResult, onChange, testSessionProgress}: Props) {
	const currentUser = useUser();

	return <ToggleGroupRoot value={testSessionResult}>
			<ToggleGroupContent>
				<ToggleGroupItem disabled={isDisabled} value={TestSessionResult.InProgress} onClick={() => onChange(TestSessionResult.InProgress)}>
					<ProgressBarContainer>
						<ProgressBarRoot value={testSessionProgress?.correctAnswers}
									 maxValue={testSessionProgress?.questions}>
							<ProgressBar.RingWithPointer>
								<Text size='medium' bold font='mono'>
									{`${Math.floor((testSessionProgress?.correctAnswers / testSessionProgress?.questions) * 100)}%`}
								</Text>
							</ProgressBar.RingWithPointer>
						</ProgressBarRoot>

						<FlexBox direction='column'>
							<FlexBox align='end'>
								<CorrectAnswersCount size='medium' font='mono' bold color='base'>{testSessionProgress?.correctAnswers}</CorrectAnswersCount>
								<Text size='xSmall' font='mono' color='lowContrast'>/{testSessionProgress?.questions}</Text>
							</FlexBox>
							<ProgressBarLabel size='xSmall' color='mediumContrast'>{currentUser?.testResultsCorrectVerbiage ?? 'Correct'} Answers</ProgressBarLabel>
						</FlexBox>
					</ProgressBarContainer>

					<Label size='medium' bold color='base'>With Scores</Label>
				</ToggleGroupItem>

				<ToggleGroupItem value={TestSessionResult.Success} onClick={() => onChange(TestSessionResult.Success)}>
					<ProgressBarContainer>
						<Container>
							<RainbowRing/>
							<EmojiBox>😋</EmojiBox>
						</Container>

						<FlexBox direction='column' align='start'>
							<Label size='medium' font='mono' bold color='base'>Success</Label>
							<ProgressBarLabel size='xSmall' color='mediumContrast'>You Finished!</ProgressBarLabel>
						</FlexBox>
					</ProgressBarContainer>

					<Label size='medium' bold color='base'>Without Scores</Label>
				</ToggleGroupItem>
			</ToggleGroupContent>
		</ToggleGroupRoot>;
}
