import {Box} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const SkeletonBox = styled(Box, SkeletonStyles, {
	variants: {
		shape: {
			round: {
				borderRadius: '50%',
			},

			square: {
				borderRadius: 6,
			},
		},
	},
});
