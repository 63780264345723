import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type TooltipContextValue = {
	/** Duration time for openning content. */
	delayDuration: number | undefined;

	/** Set duration time for openning content. */
	setDelayDuration: (duration: number) => void;

	/** Value for monitoring content showing. If true, content is showed. */
	isShownContent: boolean;
};

export const TooltipContext = createContext<TooltipContextValue>({
	delayDuration: undefined,
	setDelayDuration: noop,
	isShownContent: false,
});

export function useTooltipContext() {
	return useContext(TooltipContext);
}
