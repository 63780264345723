import {UserType, useUser} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';
import {withNewOldReports} from '../../old-reports';

const NewClassGrades = lazyComponent(() => import('./teacher'));
const OldClassGrades = lazyComponent(() => import('../../old-reports/class/grade'));

const ClassGrades = withNewOldReports(OldClassGrades, OldClassGrades);

export default function ClassGradesReport() {
	const user = useUser();

	switch (user?.userType) {
		case UserType.T:
			return <ClassGrades/>;
	}
}