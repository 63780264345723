import React, {ReactNode} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

export class ButtonProps {
	title?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
	styleName?: string;
	disabled?: boolean;
	onHoverMessage?: string;
	hoverPlacement?: 'right' | 'top' | 'bottom' | 'left';
	style?: React.CSSProperties;
	type?: 'submit' | 'reset' | 'button';
	dataToggle?: string;
	tabIndex?: number;
	value?: string | string[] | number;
	contentOnRight?: boolean;
	children?: ReactNode;
}

/**
 * @deprecated Use buttons from @esgi/ui or @esgillc/ui-kit
 **/

export class Button extends React.PureComponent<ButtonProps> {
	private get className(): string {
		let res = 'btn ';
		if (this.props.className) {
			res += this.props.className + ' ';
		}
		if (this.props.styleName) {
			res += this.props.styleName + ' ';
		}
		return res;
	}

	renderButtonContent(){
		if (this.props.contentOnRight) {
			return <>{this.renderTitle()}{this.renderChildren()}</>;
		} else{
			return <>{this.renderChildren()}{this.renderTitle()}</>;
		}
	}
	
	renderChildren(){
		if (this.props.children) {
			return this.props.children;
		}
	}

	renderTitle(){
		if (this.props.title) {
			return this.props.title;
		}
	}
	
	renderButton() {
		return <button
			className={this.className}
			disabled={this.props.disabled}
			style={this.props.style}
			data-toggle={this.props.dataToggle}
			tabIndex={this.props.tabIndex}
			value={this.props.value}
			type={this.props.type}
			onClick={(e) => !!this.props.onClick && this.props.onClick(e)}>
			{this.renderButtonContent()}
		</button>;
	}


	renderButtonOnHover() {
		return <OnHoverTooltip message={this.props.onHoverMessage} placement={this.props.hoverPlacement}>
			{this.renderButton()}
		</OnHoverTooltip>;
	}

	render() {
		{
			return this.props.onHoverMessage ? this.renderButtonOnHover() : this.renderButton();
		}
	}
}
