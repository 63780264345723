import {Header, Root, ButtonIcon, ButtonText, SortTable, EmptyBox, StyledButton} from './index.styled';
import {CheckAll} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import React, {useCallback} from 'react';
import {useTestsControl, ExpandablePanel, Test} from '@esgi/main/kits/reports';
import {TestRow} from '../test-row';
import {TestListSkeleton} from '../test-list-skeleton';

type Props = {
	setShowAddTestsDrawer: VoidFunction,
	isTestsLoading: boolean,
	tests: Test[],
	onTestSelect: (value: number[]) => void,
}

export function TestList({isTestsLoading, tests, onTestSelect, setShowAddTestsDrawer}: Props) {
	const {items, selected, toggleSelected, sort, onSort, onSelectAll} = useTestsControl({tests, onChange: onTestSelect});
	const checked = useCallback((id: number) => selected.includes(id), [selected]);

	if (isTestsLoading) {
		return <TestListSkeleton/>;
	}

	if (!tests.length) {
		return (
			<FlexBox align='center' direction='column' justify='center'>
				<EmptyBox>
					<Text font='mono' size='small' color='neutral40'>No tests to display</Text>
				</EmptyBox>
				<StyledButton color='secondary' onClick={setShowAddTestsDrawer}>
					<Text size='small' bold>
						Browse All Tests
					</Text>
				</StyledButton>
			</FlexBox>
		);
	}

	return (
		<Root>
			<Header>
				<ButtonIcon onClick={onSelectAll}>
					<CheckAll/>
				</ButtonIcon>
				<ButtonText onClick={onSort}>
					<Text size='small'>Test Name</Text>
					<SortTable direction={sort?.direction}/>
				</ButtonText>
			</Header>
			<ExpandablePanel style={{height: 'calc(100% - 60px)'}}>
				<SelectableList>
					<SelectableList.GroupRoot type='multiple' value={selected.map(item => item.toString())}>
						<SelectableList.Group>
							<GridBox gap='2'>
								{items?.map(item => (
									<SelectableList.Item
										css={{borderColor: checked(item.id) && '$primaryMuted'}}
										value={item.id.toString()}
										key={item.id}
										variant='outlined'
										withActiveBackground
										onClick={() => toggleSelected([item.id])}
									>
										<TestRow checked={checked(item.id)} name={item.name} testID={item.id} testType={item.type}/>
									</SelectableList.Item>
								))}
							</GridBox>
						</SelectableList.Group>
					</SelectableList.GroupRoot>
				</SelectableList>
			</ExpandablePanel>
		</Root>
	);
}