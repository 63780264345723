import React from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {
	ConflictStudentModel,
	ConflictType,
	Validation,
	ValidationType,
} from 'shared/modules/student-roster-upload-tool/types';
import {BellIcon} from 'shared/modules/student-roster-upload-tool/icons/bell-icon';
import {CustomDropdown} from '@esgi/deprecated/elements';
import styles from './styles.module.less';

interface Props {
	uploadedStudentLanguage: string;
	onEdit: (value: string, validation: Validation) => void;
	onClick: (validation: Validation) => void;
	validation: Validation;
	student: ConflictStudentModel;
}

const languages = [
	{
		key: 'English',
		value: 'English',
		title: 'English',
	},
	{
		key: 'Spanish',
		value: 'Spanish',
		title: 'Spanish',
	},
	{
		key: 'Other',
		value: 'Other',
		title: 'Other',
	}];

export function Language({
													 uploadedStudentLanguage,
													 onClick,
													 onEdit,
													 validation,
													 student,
												 }: Props) {

	if (!student.language.editable) {
		return <>{student.language.value}</>;
	}

	const onSelect = (value: string) => {
		const validation = value === 'Other' ? new Validation(ValidationType.Conflict, ConflictType.UnknownLanguage) : new Validation();
		onEdit(value, validation);
	};

	const handleOnClick = (e?: React.MouseEvent<HTMLElement>) => {
		if (e) {
			e.stopPropagation();
		}
		onClick(validation);
	};

	return <div data-cy={'language'} className={styles.dropdownConflict}>
		<CustomDropdown
			items={languages}
			value={uploadedStudentLanguage}
			onSelect={(t) => onSelect(t.value)}
			onClick={() => handleOnClick()}
		/>
		<div data-cy={'conflict-icon-container'} onClick={(e) => handleOnClick(e)}
				 className={join(validation.type > ValidationType.None ? styles.conflictIcon : styles.hide)}>
			<BellIcon width={'14'} height={'16'}/>
		</div>
	</div>;
}
