import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const ContainerStyled = styled(Box, {
	borderTop: '1px solid #BDBDBD',
});

export const NotesContainerStyled = styled(Box, {
	textAlign: 'left',
	overflow: 'auto',
	maxHeight: '200px',
});

export const Heading4 = styled('h4', {
	fontWeight: 700,
	fontSize: '20px',
	color: '#333333',
	textAlign: 'center',
	marginBottom: '0px',
});

export const TextMarginedStyled = styled('p', {
	margin: '5px 0px',
});
export const SummaryStyled = styled('p', {
	paddingTop: '10px',
});

export const TitleStyled = styled('span', {
	fontWeight: 500,
});