import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo, useState} from 'react';
import {BaseComponentProps, Skeletonable} from '../../../types';
import {PieChartContext, PieChartContextValue} from '../../context';
import {SkeletonableCircle, Svg} from './index.styled';
import {CSS} from '@stitches/react';
import {ShapeOffsetState, SvgValue, UpdateShapeOffset} from '../../types';

type PieChartRootProps = ComponentPropsWithoutRef<'svg'> &
	Pick<BaseComponentProps, 'dataCy'> &
	Skeletonable & {
		/**
		 * Returns a Style interface from a configuration, leveraging the given media and style map.
		 * @default {}
		 */
		css?: Omit<CSS, 'width' | 'height'>;

		/**
		 * Set width and height for the svg.
		 * @default 64
		 */
		size?: number;
		/**
		 * The minimum value of the data range.
		 */
		minValue: number;

		/**
		 * The maximum value of the data range.
		 */
		maxValue: number;
	};

export const PieChartRoot = forwardRef<SVGSVGElement, PieChartRootProps>(
	(
		{dataCy = 'ui-kit-pie-chart-root', skeleton, css = {}, size = 64, minValue, maxValue, children, ...svgProps},
		forwardedRef,
	) => {
		const [shapeSizes, setShapeSizes] = useState<ShapeOffsetState>({
			ring: 0,
			arcProgress: 0,
			pointer: 0,
			progressLine: 0,
		});

		const updateShapeOffset = useCallback<UpdateShapeOffset>(({shape, offset}) => {
			setShapeSizes((currentState) => ({
				...currentState,
				[shape]: offset,
			}));
		}, []);

		const circleValues = useMemo<SvgValue>(() => {
			return {
				size,
				radius: size / 2,
				center: {
					x: size / 2,
					y: size / 2,
				},
			};
		}, [size]);

		const getValueProgress = useCallback(
			(value: number) => {
				return (value - minValue) / (maxValue - minValue);
			},
			[maxValue, minValue],
		);

		const contextValue = useMemo<PieChartContextValue>(
			() => ({
				getValueProgress,
				svgValue: circleValues,
				shapeSizes,
				updateShapeOffset,
				maxShapeOffset: Math.max(...Object.values(shapeSizes)),
			}),
			[circleValues, getValueProgress, shapeSizes, updateShapeOffset],
		);

		return (
			<PieChartContext.Provider value={contextValue}>
				<Svg
					data-cy={dataCy}
					xmlns='http://www.w3.org/2000/svg'
					viewBox={`0 0 ${size} ${size}`}
					css={{
						width: size,
						height: size,
						...css,
					}}
					strokeLinecap='round'
					ref={forwardedRef}
					{...svgProps}
				>
					{skeleton ? (
						<SkeletonableCircle cx={circleValues.center.x} cy={circleValues.center.y} r={circleValues.radius} />
					) : (
						children
					)}
				</Svg>
			</PieChartContext.Provider>
		);
	},
);
