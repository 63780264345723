import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	className?: string;
	children?: any;
}

export function QuestionBody(props: Props) {
	return <div {...props} className={join(styles.body, props.className)}/>;
}
