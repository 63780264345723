import {Users} from '@esgi/ui/icons';
import {Dispatch, useCallback, useEffect, useMemo} from 'react';
import {StudentSectionMode} from './types';
import {sectionTitleByMode} from './constants/section-title-by-mode';
import {useSectionModeButtons} from './hooks/use-section-mode-buttons';
import {usePanelState} from './hooks/use-panel-state';
import {TitleWithActionButtons} from '@esgi/main/features/teacher/home';
import {useAddSectionModeItems} from './hooks/use-add-section-mode-items';
import {ClassesContent} from './components/classes-content';
import {StudentsContent} from './components/students-content';
import {Entities, StudentsPanelTabID, StudentsPanel, SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {GroupsContent} from './components/groups-content';
import {Class, Group, Student} from '@esgi/main/libs/store';
import {useService} from '@esgi/core/service';
import {useTeacherPageContext} from '../../context/teacher-page-context';
import {DataStudentsService} from '../../services/data-students-service';
import {useBehaviorSubject} from '@esgi/ui';
import {useHandleEvents} from './hooks/use-handle-events';
import {isNull} from 'underscore';

type Props = {
	isFirstEntry: boolean;
	setHasStudents: Dispatch<boolean>;
	activeTab: StudentsPanelTabID;
	setActiveTab: Dispatch<StudentsPanelTabID>;
	selectedClassId: Class['id'] | null;
	setSelectedClassId: Dispatch<Class['id']>;
	selectedGroupId: Group['id'] | null;
	setSelectedGroupId: Dispatch<Group['id']>;
	selectedStudentId: Student['id'] | null;
	setSelectedStudentId: Dispatch<Student['id']>;
	setStudentFromClassID: Dispatch<Class['id'] | null>;
	setStudentFromGroupID: Dispatch<Class['id'] | null>;
};

export function StudentPanel({
	isFirstEntry,
	activeTab,
	setActiveTab,
	selectedClassId,
	setSelectedClassId,
	selectedGroupId,
	setSelectedGroupId,
	setHasStudents,
	selectedStudentId,
	setSelectedStudentId,
	setStudentFromClassID,
	setStudentFromGroupID,
}: Props) {
	const {teacherSnackbarRef} = useTeacherPageContext();

	const dataService = useService(DataStudentsService);

	const classList = useBehaviorSubject(dataService.classes$);
	const groupList = useBehaviorSubject(dataService.groups$);
	const studentList = useBehaviorSubject(dataService.studentBox$);
	const loadedInfo = useBehaviorSubject(dataService.isLoadedData$);

	const entities = useMemo<Entities>(
		() => ({
			classes: classList,
			groups: groupList,
			students: studentList,
		}),
		[classList, groupList, studentList],
	);

	const {
		selected,
		sectionMode,
		sectionModePermissions,
		setSectionMode,
		setSelected,
		setSectionViewMode,
		getSectionModePermissionValue,
		updateSectionModePermissions,
	} = usePanelState({
		classList,
		studentList,
		groupList,
		selectedClassId,
		selectedGroupId,
		selectedStudentId,
	});

	useHandleEvents({
		dataService,
		teacherSnackbarRef,
		classList,
		groupList,
		selectedClassId,
		selectedGroupId,
		setSelected,
	});

	const sectionModeButtons = useSectionModeButtons({
		sectionMode,
		setSectionMode,
		activeTab,
		sectionModePermissions,
	});

	const addSectionModeItems = useAddSectionModeItems({
		sectionModePermissions,
		selectedClassId: selected.classId,
		selectedStudentId: selected.studentId,
	});

	const onAddSectionModeOpenChanged = useCallback(
		(open: boolean) => setSectionMode(open ? StudentSectionMode.Add : StudentSectionMode.View),
		[setSectionMode],
	);

	const onSetSelected = useCallback(
		({classId, groupId, studentFromClassId, studentFromGroupId, studentId}: SingleSelectState) => {
			if (!isNull(classId)) {
				setSelectedClassId(classId);

				setStudentFromClassID(null);
				setStudentFromGroupID(null);
			}

			if (!isNull(groupId)) {
				setSelectedGroupId(groupId);

				setStudentFromClassID(null);
				setStudentFromGroupID(null);
			}

			if (!isNull(studentId)) {
				setSelectedStudentId(studentId);
				setStudentFromClassID(studentFromClassId);
				setStudentFromGroupID(studentFromGroupId);
			}

			setSelected({classId, groupId, studentFromClassId, studentFromGroupId, studentId});
		},
		[
			setSelected,
			setSelectedClassId,
			setSelectedGroupId,
			setSelectedStudentId,
			setStudentFromClassID,
			setStudentFromGroupID,
		],
	);

	useEffect(() => {
		if (isFirstEntry && loadedInfo.classes && classList.length) {
			onSetSelected({
				classId: classList[0]!.id,
				groupId: null,
				studentId: null,
				studentFromClassId: null,
				studentFromGroupId: null,
			});
		}
	}, [classList, isFirstEntry, loadedInfo.classes]);

	useEffect(() => {
		setHasStudents(loadedInfo.students && Boolean(studentList.length));

		if (loadedInfo.students && !studentList.length && selectedStudentId) {
			onSetSelected({
				classId: null,
				groupId: null,
				studentId: null,
				studentFromClassId: null,
				studentFromGroupId: null,
			});
		}
	}, [loadedInfo.students, studentList.length, selectedStudentId]);

	const isPanelLoaded = loadedInfo.classes && loadedInfo.groups && loadedInfo.students;

	return (
		<StudentsPanel.Root
			entities={entities}
			skeleton={!isPanelLoaded}
			activeTab={activeTab}
			onActiveTabChanged={setActiveTab}
		>
			<StudentsPanel.Selection.Single selected={selected} onSelectedChange={onSetSelected}>
				<StudentsPanel.Header.Title align='center' justify='between' dataCy='students-section-panel-header'>
					<TitleWithActionButtons
						sectionTitle={sectionTitleByMode[sectionMode]}
						SectionIcon={Users}
						isDisplaySectionIcon
						isViewMode={sectionMode === StudentSectionMode.View}
						sectionModeButtons={sectionModeButtons}
						addSectionModeItems={addSectionModeItems}
						isAddSectionModeActive={sectionMode === StudentSectionMode.Add}
						isAddSectionModeDisabled={sectionMode !== StudentSectionMode.View && sectionMode !== StudentSectionMode.Add}
						onAddSectionModeOpenChanged={onAddSectionModeOpenChanged}
						dataCy='students-section-panel-header'
						addActionTooltipText='Add Class, Group, or Student'
					/>
				</StudentsPanel.Header.Title>

				<StudentsPanel.Header.Tabs dataCy='students-tabs' />

				<StudentsPanel.Tabs.Root>
					<StudentsPanel.Tabs.Classes
						sectionTitle='Classes'
						description='Classes'
						dataCy='students-panel-toggle-item-classes'
						disabled={sectionMode !== StudentSectionMode.View}
						applyDisabledStyles={sectionMode !== StudentSectionMode.View}
					>
						<ClassesContent
							canAddClass={sectionModePermissions[StudentSectionMode.Add][StudentsPanelTabID.Classes]}
							sectionMode={sectionMode}
							setViewMode={setSectionViewMode}
							updateClassesOrder={dataService.updateClassesOrder.bind(dataService)}
						/>
					</StudentsPanel.Tabs.Classes>
					<StudentsPanel.Tabs.Groups
						sectionTitle='Groups'
						description='Students Arranged in Groups'
						dataCy='students-panel-toggle-item-groups'
						disabled={sectionMode !== StudentSectionMode.View}
						applyDisabledStyles={sectionMode !== StudentSectionMode.View}
					>
						<GroupsContent
							canAddGroup={sectionModePermissions[StudentSectionMode.Add][StudentsPanelTabID.Groups]}
							sectionMode={sectionMode}
							setViewMode={setSectionViewMode}
							getSectionModePermissionValue={getSectionModePermissionValue}
							updateSectionModePermissions={updateSectionModePermissions}
							updateGroupsOrderInClass={dataService.updateGroupsOrderInClass.bind(dataService)}
						/>
					</StudentsPanel.Tabs.Groups>
					<StudentsPanel.Tabs.Students
						sectionTitle='Students'
						description='All Students List'
						dataCy='students-panel-toggle-item-students'
						disabled={sectionMode !== StudentSectionMode.View}
						applyDisabledStyles={sectionMode !== StudentSectionMode.View}
					>
						<StudentsContent
							canAddStudent={sectionModePermissions[StudentSectionMode.Add][StudentsPanelTabID.Students]}
							sectionMode={sectionMode}
							setViewMode={setSectionViewMode}
							selectedClassId={selected.classId}
						/>
					</StudentsPanel.Tabs.Students>
				</StudentsPanel.Tabs.Root>
			</StudentsPanel.Selection.Single>
		</StudentsPanel.Root>
	);
}
