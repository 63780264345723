import {DatePicker as BaseDatePicker} from '../../controls/date-picker';
import {ElementStatus, useFormElementContext} from '../../form';
import React, {ComponentProps, useCallback} from 'react';
import {BaseComponentProps} from '@esgi/ui';

type Props = Omit<ComponentProps<typeof BaseDatePicker.Root>, 'onChange' | 'value'> & BaseComponentProps;

function DatePickerRootWithForm({children, rangeMode, dataCy, ...props}: Props) {
	const {status, setValue, value, setRef} = useFormElementContext(
		{
			array: true,
		},
		DatePickerRootWithForm,
	);

	const onChange = useCallback((values: Date[]) => setValue(values), [setValue]);

	const commonProps = {
		error: status === ElementStatus.invalid,
		disabled: status === ElementStatus.disabled,
		value,
		onChange,
		rangeMode,
		dataCy,
		ref: setRef,
		...props,
	};

	return <BaseDatePicker.Root {...commonProps}>{children}</BaseDatePicker.Root>;
}

export const DatePicker = {
	Root: DatePickerRootWithForm,
	Input: BaseDatePicker.Input,
	CalendarPanel: BaseDatePicker.CalendarPanel,
	DropdownTrigger: BaseDatePicker.DropdownTrigger,
	DropdownBody: BaseDatePicker.DropdownBody,
};
