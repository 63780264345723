import {Observable} from 'rxjs';
import {WidgetService} from '../service';
import {StudentBundle, ClassBundle} from '../types';
import {ClassResponse, StudentResponse} from './types';

export class AveragePerformanceService extends WidgetService<StudentResponse, ClassResponse> {
	protected requestStudentData(request: StudentBundle): Observable<StudentResponse> {
		return this.studentsController.averagePerformance({testIDs: request.testIDs, studentID: request.studentID, classStudentsIDs: request.classStudentIDs});
	}

	protected requestClassData(request: ClassBundle): Observable<ClassResponse> {
		return this.classController.averagePerformance({testIDs: request.testIDs, studentIDs: request.studentIDs});
	}
}