import {useMemo} from 'react';
import {Modal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {SelfAssessIcon, IEPIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TestScreenType} from '@esgi/core/enums';
import {Download} from './components/download';
import {Subheader} from './components/subheader';
import {useTestSessionDetailsService} from '../../hooks';
import styles from './header.module.less';

interface Props {
	onDownload: (current: boolean) => void;
}

export function Header({onDownload}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);
	const activeTab = useBehaviorSubject(service.activeTab$);
	const iepGoal = useBehaviorSubject(service.iepGoal$);
	const testSessionsNumber = useMemo<number>(() => {
		if (testSessionDetails.testSessions) {
			return testSessionDetails.testSessions.filter(({deleted}) => !deleted).length;
		}
		return 0;
	}, [testSessionDetails]);
	const isSelfAssess = useMemo(() => {
		if (currentTestSession) {
			return currentTestSession.testScreenType === TestScreenType.SelfAssessment;
		}
		return false;
	}, [currentTestSession]);

	return (
		<Modal.Header className={styles.modalHeader} data-cy='header-link-block'>
			<div className={styles.title}>
				<div>
					Test Session Details
					<sup className={styles.helpContainer}>
						<OnHoverTooltip message='Help'>
							<a href='https://support.esgisoftware.com/hc/en-us/articles/360024519772-Details-and-History-Buttons' target='_blank' rel='noreferrer'>
								<i className='fa fa-question-circle' />
							</a>
						</OnHoverTooltip>
					</sup>
				</div>
				<div className={styles.iconsContainer}>
					{isSelfAssess &&
						<OnHoverTooltip message='Student self-assess'>
							<SelfAssessIcon/>
						</OnHoverTooltip>
					}
					{iepGoal &&
						<IEPIcon/>
					}
					{(!editMode && activeTab === 'details') &&
						<Download
							testSessionsNumber={testSessionsNumber}
							deletedCurrentSession={currentTestSession ? currentTestSession.deleted : true}
							onDownload={onDownload}
						/>
					}
				</div>
			</div>
			<Subheader isSelfAssess={isSelfAssess}/>
		</Modal.Header>
	);
}
