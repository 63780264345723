import {useService} from '@esgi/core/service';
import {ElementStatus, Form, FormElement} from '@esgillc/ui-kit/form';
import {Hint, Input, Label, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import {useCallback, useEffect, useState} from 'react';
import {of, switchMap} from 'rxjs';
import {PasswordReminder} from 'modules/password-reminder/password-reminder';
import {ResetPasswordService} from './service';
import './styles.less';

import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {useNavigate} from 'react-router-dom';
import {RoutesMap} from '@esgi/main/libs/core';
import {UserType} from '@esgi/core/authentication';

export default function ResetPasswordPage() {
	const [showRemindPassword, setShowRemindPassword] = useState(false);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showForgotDialog, setShowForgotDialog] = useState(false);
	const [userType, setUserType] = useState<UserType>(UserType.V);

	const service = useService(ResetPasswordService);

	useEffect(() => {
		service.init().subscribe(r => {
			setShowRemindPassword(r.allowRemindPassword);
			setShowResetPassword(r.allowResetPassword);
		});
	}, []);

	const remindPasswordClicked = () => {
		service.form.validate().pipe(switchMap((res) => {
			if (res.valid) {
				return service.save();
			} else {
				return of();
			}
		})).subscribe(r => {
			if(r) {
				setShowResetPassword(r.allowResetPassword);
				setShowRemindPassword(r.allowRemindPassword);
				setShowSuccessMessage(r.showSuccessMessage);
				setUserType(r.userType as UserType);
			}
		});
	};

	const forgotPasswordClicked = () => {
		setShowForgotDialog(true);
	};

	const navigate = useNavigate();

	const goToLoginPage = useCallback(() => {
		const crmAdminTypes = [UserType.M, UserType.A];
		if (crmAdminTypes.includes(userType)) {
			document.location.href = RoutesMap.loginCRM;
			return;
		}

		navigate(RoutesMap.login, {
			replace: true,
		});

	}, [navigate, userType]);

	return <div id='reset-password-form'>
		{showForgotDialog && <PasswordReminder onClose={() => setShowForgotDialog(false)}/>}
		{showResetPassword && <Form controller={service.form} className={join('form-horizontal', styles.form)}>
			<legend>Enter your new password</legend>
			<FormElement control={service.form.controls.password} className='row' disableValidateOnChange>
				<Label className='col-md-3 control-label'>Password</Label>
				<div className='col-md-7'>
					<Input type='password'/>
					<Hint className={styles.hint}>{(state) => {
						if (state.status === ElementStatus.valid) {
							return <i className='fa fa-check form-control-feedback'/>;
						}
						if (state.status === ElementStatus.invalid) {
							return <i className='fa fa-close form-control-feedback'/>;
						}
					}}</Hint>
					<OnErrorTooltip showOnError='required' placement='right'>
						Required
					</OnErrorTooltip>
					<OnErrorTooltip showOnError='length-min' placement='right'>
						Password must be at least 8 characters length
					</OnErrorTooltip>
				</div>
			</FormElement>
			<FormElement control={service.form.controls.confirm} className='row' disableValidateOnChange>
				<Label className='col-md-3 control-label'>Confirm Password</Label>
				<div className='col-md-7'>
					<Input type='password'/>
					<Hint className={styles.hint}>{(state) => {
						if (state.status === ElementStatus.valid) {
							return <i className='fa fa-check form-control-feedback'/>;
						}
						if (state.status === ElementStatus.invalid) {
							return <i className='fa fa-close form-control-feedback'/>;
						}
					}}</Hint>
					<OnErrorTooltip showOnError='required' placement='right'>
						Required
					</OnErrorTooltip>
					<OnErrorTooltip showOnError='length-min' placement='right'>
						Password must be at least 8 characters length
					</OnErrorTooltip>
					<OnErrorTooltip showOnError='consistent' placement='right'>
						Passwords do not match
					</OnErrorTooltip>
				</div>
			</FormElement>
			<div className='form-group'>
				<div className='col-md-10'>
					<button style={{width: '100px'}} className='btn btn-primary pull-right' onClick={remindPasswordClicked}>
						Save
					</button>
				</div>
			</div>
		</Form>}
		{showRemindPassword &&
			<div>That link has expired (links expire after 1 hour). Please click here to request a new password:
				<a href='#' onClick={forgotPasswordClicked}>Forgot Password?</a>
			</div>}
		{!showResetPassword && !showRemindPassword && !showSuccessMessage && <div className='wait'>Please wait</div>}
		{showSuccessMessage && <div className='alert alert-success' style={{marginBottom: '0px', textAlign: 'center'}}>
			<h4>Success!</h4>
			<p>Your password has been changed</p>
			<Buttons.Contained onClick={goToLoginPage}>Login</Buttons.Contained>
		</div>}
	</div>;
}
