import {AnswerState} from '@esgi/core/enums';

export class QuestionModel {
	comment: string;
	oldAnswerState: AnswerState;
	duration: number;
	questionID: number;
	id: number;
	modifyTicks: number;
	answerState: AnswerState = AnswerState.NotTested;
	direction: string;
	directUrl: string;
	fallbackUrl: string;
	url: string;
}

export class TestIntroModel {
	pregenerated: boolean;
	modifyTicks: number;
	id: number;
}

export interface QuestionResponseModel {
	questionID: number;
	directions: string;
	modifyTicks: number;
	pregenerated: boolean;
	oldAnswerState: AnswerState;
	cacheAnswerState: string;
	comment: string;
	duration: number;
}
