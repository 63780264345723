import {Box} from '@esgillc/ui-kit/layout';
import {styled} from '../../theme/stitches.config';
import {Text} from '@esgi/ui/typography';
import {CSS} from '@stitches/react';

const inputPaddingInline = 12;

export const TextareaStyled = styled('textarea', {
	width: '100%',
	paddingTop: 18,
	paddingLeft: inputPaddingInline,
	paddingBottom: 6,
	paddingRight: inputPaddingInline,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$primaryMuted',
	borderRadius: 6,
	boxSizing: 'border-box',
	fontSize: 13,
	fontWeight: '600',
	lineHeight: '16px',
	outline: 'none',

	variants: {
		resize: {
			false: {
				resize: 'none',
			},
		},
	},
});

export const Placeholder = styled(Text, {
	position: 'absolute',
	width: `calc(100% - 2 * ${inputPaddingInline}px)`,
	left: inputPaddingInline,
	boxSizing: 'border-box',
	top: 1,
	paddingTop: 4,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	pointerEvents: 'none',
	appearance: 'none',
	outline: 'none',
	fontWeight: 500,
	fontSize: 13,
	lineHeight: '16px',
});

export const ErrorWrapper = styled(Box, {
	paddingLeft: inputPaddingInline,
	paddingRight: inputPaddingInline,
	color: '$negativeVivid',

	[`& ${Text}`]: {
		color: 'currentColor',
	},
});

const baseErrorStyles: CSS = {
	[`& > ${Placeholder}`]: {
		color: '$negative',
	},

	[`& > ${TextareaStyled}`]: {
		color: '$negative',
		borderColor: '$negativeHighlight',
	},
};

const valuedFocusedPlaceholderStyled: CSS = {
	top: 3,
	fontWeight: 500,
	fontSize: 10,
	lineHeight: '12px',
	letterSpacing: -0.4,
	fontVariantNumeric: 'lining-nums proportional-nums stacked-fractions',
};

export const Wrapper = styled(Box, {
	position: 'relative',
	display: 'inline-block',

	[`& > ${Placeholder}`]: {
		backgroundColor: '$vivid',
		transition: 'background-color .3s, color .3s, font-size .3s, font-weight .3s, lint-height .3s',
	},

	[`& > ${TextareaStyled}`]: {
		backgroundColor: '$vivid',
		transition: 'background-color .3s, border-color .3s, color .3s',
	},

	[`&:has(> ${TextareaStyled}:hover:not([disabled]))`]: {
		[`& > ${Placeholder}`]: {
			color: '$secondary',
			backgroundColor: '$secondaryBackground',
		},

		[`& > ${TextareaStyled}`]: {
			backgroundColor: '$secondaryBackground',
			borderColor: '$secondary80',
			color: '$secondary',
		},
	},

	[`&:has(> ${TextareaStyled}:focus)`]: {
		[`& > ${Placeholder}`]: valuedFocusedPlaceholderStyled,
	},

	[`&:has(> ${TextareaStyled}:disabled)`]: {
		[`& > ${Placeholder}`]: {
			color: '$muted',
		},

		[`& > ${TextareaStyled}`]: {
			borderColor: '$mild',
			cursor: 'default',
			resize: 'none',
		},
	},

	variants: {
		valued: {
			true: {
				[`& > ${Placeholder}`]: valuedFocusedPlaceholderStyled,

				[`&:has(> ${TextareaStyled}:not([disabled]):not(:hover))`]: {
					[`& > ${Placeholder}`]: {
						color: '$lowContrast',
					},
				},

				[`&:has(> ${TextareaStyled}:not([disabled]):not(:hover):not(:focus))`]: {
					[`& > ${TextareaStyled}`]: {
						color: '$primary',
					},
				},
			},

			false: {
				[`&:has(> ${TextareaStyled}:not([disabled]))`]: {
					[`& > ${Placeholder}`]: {
						color: '$primary72',
					},
				},
			},
		},

		withError: {
			true: {
				[`&:has(> ${TextareaStyled}:not([disabled]))`]: baseErrorStyles,
				[`&:has(> ${TextareaStyled}:hover:not([disabled]))`]: baseErrorStyles,
				[`&:has(> ${TextareaStyled}:not(:hover):not([disabled]))`]: baseErrorStyles,
				[`&:has(> ${TextareaStyled}:not([disabled]):not(:hover):not(:focus))`]: baseErrorStyles,
			},
		},
	},
});
