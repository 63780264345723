import './styles.less';
import React from 'react';
import {Button} from '@esgi/deprecated/elements/buttons';
import {SortDirection} from '@esgi/core/enums';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TestsSortBy} from '../../../enums';

export class SortByInfo {
	value: TestsSortBy = TestsSortBy.CreateDate;
	direction: SortDirection = SortDirection.Desc;
}

class State {
	value: TestsSortBy = TestsSortBy.CreateDate;
	direction: SortDirection = SortDirection.Desc;
}

class Props {
	sortChanged: (state: SortByInfo) => void;
	keywordUsed: boolean;
}

export class SortBy extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = new State();
	}
	render() {
		return <div className='sort-by-button'>
			<label>Sort by:</label>
			<OnHoverTooltip
				message={this.props.keywordUsed ? 'When searching by keyword, you cannot change the way that results are sorted. Remove the keyword if you wish to sort via another method' : ''}>
				<div className='btn-group'>
					<Button
						dataToggle='dropdown'
						className='btn-link'
						disabled={this.props.keywordUsed}
						title={this.sortAsText()}
					/>
					<ul className='dropdown-menu manual'>
						<li><a href='#' onClick={() => this.changeSort(TestsSortBy.CreateDate)}>Create Date</a></li>
						<li><a href='#' onClick={() => this.changeSort(TestsSortBy.TestName)}>Test Name</a></li>
						<li><a href='#' onClick={() => this.changeSort(TestsSortBy.ContentArea)}>Content Area</a>
						</li>
						<li><a href='#' onClick={() => this.changeSort(TestsSortBy.StateStandard)}>State
							Standard</a>
						</li>
						<li><a href='#' onClick={() => this.changeSort(TestsSortBy.TestOwner)}>Author</a></li>
						<li><a href='#' onClick={() => this.changeSort(TestsSortBy.NumberOfQuestions)}># of
							Questions</a></li>
					</ul>
				</div>
			</OnHoverTooltip>
			{!this.props.keywordUsed &&
			<a href='#' onClick={() => this.changeSortDirection()}>
				<div className={'arrow up ' + (this.state.direction === SortDirection.Asc ? 'active' : '')}/>
				<div className={'arrow down ' + (this.state.direction === SortDirection.Desc ? 'active' : '')}/>
			</a>
			}
		</div>;
	}

	private sortAsText() {

		if (this.props.keywordUsed) {
			return 'Keyword Search';
		}

		switch (this.state.value) {
			case TestsSortBy.ContentArea:
				return 'Content Area';

			case TestsSortBy.NumberOfQuestions:
				return '# of Questions';

			case TestsSortBy.TestOwner:
				return 'Author';

			case TestsSortBy.StateStandard:
				return 'State Standard';

			case TestsSortBy.CreateDate:
				return 'Create Date';

			case TestsSortBy.TestName:
				return 'Test Name';

			default:
				return 'N/A';
		}
	}

	private changeSort(value: TestsSortBy) {
		this.setState({value: value, direction: SortDirection.Asc}, () => {
			this.props.sortChanged({...this.state as SortByInfo});
		});
	}

	private changeSortDirection() {
		let direction = SortDirection.None;
		if (this.state.direction === SortDirection.Asc) {
			direction = SortDirection.Desc;
		} else {
			direction = SortDirection.Asc;
		}

		this.setState({direction: direction}, () => {
			this.props.sortChanged({...this.state as SortByInfo});
		});
	}
}
