import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const MeridiemCheapBox = styled(GridBox, {
	minWidth: 20,
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	backgroundColor: '$primary80',
	borderRadius: 2,
	paddingTop: 1,
	paddingBottom: 1,
	paddingLeft: 2,
	paddingRight: 2,

	variants: {
		inputVariantType: {
			default: {
				top: 21,
				left: 50,
			},

			mini: {
				top: 0,
				bottom: 0,
				margin: 'auto',
				left: 45,
				height: 'max-content',
			},
		},

		disabled: {
			true: {
				backgroundColor: '$mild',
			},
		},

		hovered: {
			true: {
				backgroundColor: '$secondary80',
			},
		},

		errored: {
			true: {
				backgroundColor: '$neutral72',
			},
		},
	},
});
