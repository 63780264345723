import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import React from 'react';
import {ReportFiltersLabel} from '@esgi/deprecated/ui-kit/report/filters/report-filters-label';
import {FlexBox} from '@esgi/ui/layout';
import {CheckboxStyled, FiltersContentBlockStyled, IconStyled, ReportsFiltersContainer} from './index.styled';

interface Props {
	carryScoresForward: boolean;
	showBaseline: boolean;
	onCarryForwardClicked: (carryScoresForward: boolean) => void;
	onShowBaseLineClicked: (showBaseLine: boolean) => void;
}

export function ShowResultsFilter(props: Props) {
	return <ReportsFiltersContainer data-cy='show-results-container'>
			<FlexBox>
				<ReportFiltersLabel>Results:</ReportFiltersLabel>
			</FlexBox>
			<FiltersContentBlockStyled>
					<CheckboxStyled
						data-cy='carry-forward-checkbox'
						selected={props.carryScoresForward}
						onClick={() => props.onCarryForwardClicked(!props.carryScoresForward)}
						checked={props.carryScoresForward}>
						Carry forward
						<OnHoverTooltip message='Help'>
							<a
								href='https://support.esgisoftware.com/hc/en-us/articles/360024914451-Student-Progress-Report'
								target='_blank' rel='noreferrer'>
								<IconStyled className='fa fa-question-circle' aria-hidden='true'/>
							</a>
						</OnHoverTooltip>
					</CheckboxStyled>
				<OnHoverTooltip message='Show Baseline'>
					<CheckboxStyled
						data-cy='show-baseline-checkbox'
						selected={props.showBaseline}
						onClick={() => props.onShowBaseLineClicked(!props.showBaseline)}
						checked={props.showBaseline}>
						Show Baseline
					</CheckboxStyled>
				</OnHoverTooltip>
			</FiltersContentBlockStyled>
		</ReportsFiltersContainer>;
}
