import {SelectableList} from '@esgi/ui/layout';
import {Student} from '@esgi/main/libs/store';
import {StudentFullName, SelectableDraggableItem, getListValue} from '../../../../kit';

type Props = {
	selectedStudentId: Student['id'] | null;
	onDragEnd: (groupState: Student[]) => void;
	students: Student[];
};

export function SelectableListRearrangeable({onDragEnd, students, selectedStudentId}: Props) {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedStudentId)}>
				<SelectableList.GroupDroppable onDragEnd={onDragEnd} droppableItems={students}>
					{students.map(({firstName, lastName, id}, index) => (
						<SelectableDraggableItem value={String(id)} index={index} draggableId={String(id)} key={id}>
							<StudentFullName firstName={firstName} lastName={lastName} />
						</SelectableDraggableItem>
					))}
				</SelectableList.GroupDroppable>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
} 
