import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const PageContainer = styled(FlexBox, {
	width: '100vw',
	height: '100vh',
	overflow: 'hidden',
	variants: {
		withVersionBannerOffset: {
			true: {
				height: 'calc(100vh - 44px)',
			},
		},
	},
});

export const ContentContainer = styled(Box, {
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	position: 'relative',
});