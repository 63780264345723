import {HttpClient} from '@esgi/api';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import FilterService from './filter-service';
import {LandingService} from './landing-service';
import SearchService from './search-service';
import {SubjectsService} from './subjects-service';


export class DataService extends BaseService {
	private readonly controller = 'pages/test-explorer';

	private readonly filterService: FilterService;
	private readonly searchService: SearchService;
	private readonly subjectsService: SubjectsService;
	private readonly landingService: LandingService;

	private contentAreasData: Observable<IContentAreaModel[]>;
	private notedSeriesData: Observable<INotedSeriesModel[]>;

	constructor() {
		super();
		this.filterService = new FilterService();
		this.landingService = new LandingService(this.controller);
		this.subjectsService = new SubjectsService(this.controller);
		this.searchService = new SearchService(
			this.controller,
			this.filterService,
			this.subjectsService,
			this.landingService);
	}

	public get filter(): FilterService {
		return this.filterService;
	}

	public get search(): SearchService {
		return this.searchService;
	}

	public get landing(): LandingService {
		return this.landingService;
	}

	public get subjects(): SubjectsService {
		return this.subjectsService;
	}

	public get contentAreas(): Observable<IContentAreaModel[]> {
		if (!this.contentAreasData) {
			this.contentAreasData = this.httpClient.ESGIApi.get<{contentAreas: IContentAreaModel[]}>('dictionaries/contentarea', 'all')
				.pipe(map(x => {
					return x.contentAreas;
				}), shareReplay())
				.asObservable();
		}
		return this.contentAreasData;
	}

	public get notedSeries(): Observable<INotedSeriesModel[]> {
		if (!this.notedSeriesData) {
			this.notedSeriesData = (HttpClient.default.ESGIApi.get<INotedSeriesApiResponseModel>(this.controller, 'noted-series')
				.pipe(map(r => r.notedSeries.sort((a, b) => {
					if (a.name > b.name) {
						return 1;
					}
					if (a.name < b.name) {
						return -1;
					}
					return 0;
				})), shareReplay()).asObservable());
		}
		return this.notedSeriesData;
	}
}

export interface IContentAreaModel {
  id: number;
	name: string;
}
export interface INotedSeriesModel {
	id: number,
	name: string
}

interface INotedSeriesApiResponseModel {
	notedSeries: INotedSeriesModel[]
}
