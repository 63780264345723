import React, {ReactNode} from 'react';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {HierarchyMode} from 'modules/hierarchy/core/models';
import {EventBusManager} from '@esgillc/events';
import {ReactiveComponent} from '@esgi/core/service';
import {userStorage, UserType} from '@esgi/core/authentication';
import {FilterModel} from '../../models/models';
import DataService from '../../services/data-service';
import FilterService from '../../services/filter-service';
import FilterHeader from './header';
import ModeSwitcher from './mode-switcher';
import {StudentViewEvent} from 'modules/forms/students-form/events';
import {Form, FormControl, FormControlChangedArgs, FormElement, FormGroup} from '@esgillc/ui-kit/form';
import {Dropdown, Label, Option} from '@esgillc/ui-kit/form-control';

class Props {
	showSpecialistFilter: boolean;
	dataService: DataService;
	filterService: FilterService;
}

export default class Filter extends React.PureComponent<Props> {
	private readonly onDestroy$: Subject<void> = new Subject<void>();
	private readonly eventBusManager: EventBusManager = new EventBusManager();

	private filters = new FormGroup({
		name: new FormControl(''),
		schools: new FormControl([{id: 0, value: 'All'}]),
		teachers: new FormControl([{id: 0, value: 'All'}]),
		classes: new FormControl([{id: 0, value: 'All'}]),
		groups: new FormControl([{id: 0, value: 'All'}]),
		gradeLevels: new FormControl([{id: 0, value: 'All'}]),
		specialistTypes: new FormControl([{id: 0, value: 'All'}]),
		specialists: new FormControl([{id: 0, value: 'All'}]),
		specialistGroups: new FormControl([{id: 0, value: 'All'}]),
	});

	private wrapObservable<T>(observable: Observable<FormControlChangedArgs<T>>): Observable<FormControlChangedArgs<T>> {
		return observable.pipe(takeUntil(this.onDestroy$), filter(ch => ch.reason === 'value'));
	}

	private updateFilter(filter: Partial<FilterModel>, trigger: keyof FilterModel): void {
		const defaultValue = [{id: 0, value: 'All'}];
		if ('name' in filter && trigger !== 'name') {
			this.filters.controls.name.value = filter.name;
		}
		if ('schoolID' in filter && trigger !== 'schoolID') {
			this.filters.controls.schools.value = defaultValue;
		}
		if ('teacherID' in filter && trigger !== 'teacherID') {
			this.filters.controls.teachers.value = defaultValue;
		}
		if ('classID' in filter && trigger !== 'classID') {
			this.filters.controls.classes.value = defaultValue;
		}
		if ('groupID' in filter && trigger !== 'groupID') {
			this.filters.controls.groups.value = defaultValue;
		}
		if ('gradeLevelID' in filter && trigger !== 'gradeLevelID') {
			this.filters.controls.gradeLevels.value = defaultValue;
		}
		if ('specialistType' in filter && trigger !== 'specialistType') {
			this.filters.controls.specialistTypes.value = defaultValue;
		}
		if ('specialistUserID' in filter && trigger !== 'specialistUserID') {
			this.filters.controls.specialists.value = defaultValue;
		}
		if ('specialistGroupID' in filter && trigger !== 'specialistGroupID') {
			this.filters.controls.specialistGroups.value = defaultValue;
		}
		this.props.filterService.updateFilter(filter);
	}

	public componentDidMount() {
		const {
			name,
			teachers,
			schools,
			classes,
			groups,
			gradeLevels,
			specialistTypes,
			specialists,
			specialistGroups,
		} = this.filters.controls;

		this.wrapObservable(name.onChanged)
			.subscribe(ch => this.updateFilter({
				name: ch.currState.value,
			}, 'name'));
		this.wrapObservable(schools.onChanged)
			.subscribe(ch => this.updateFilter({
				schoolID: ch.currState.value[0]?.id,
				teacherID: 0,
				classID: 0,
				groupID: 0,
				specialistUserID: 0,
				specialistGroupID: 0,
			}, 'schoolID'));
		this.wrapObservable(teachers.onChanged)
			.subscribe(ch => this.updateFilter({
				teacherID: ch.currState.value[0]?.id,
				classID: 0,
				groupID: 0,
			}, 'teacherID'));
		this.wrapObservable(classes.onChanged)
			.subscribe(ch => this.updateFilter({
				classID: ch.currState.value[0]?.id,
				groupID: 0,
			}, 'classID'));
		this.wrapObservable(groups.onChanged)
			.subscribe(ch => this.updateFilter({
				groupID: ch.currState.value[0]?.id,
			}, 'groupID'));
		this.wrapObservable(gradeLevels.onChanged)
			.subscribe(ch => this.updateFilter({
				gradeLevelID: ch.currState.value[0]?.id,
			}, 'gradeLevelID'));
		this.wrapObservable(specialistTypes.onChanged)
			.subscribe(ch => this.updateFilter({
				specialistType: ch.currState.value[0]?.id,
				specialistUserID: 0,
				specialistGroupID: 0,
			}, 'specialistType'));
		this.wrapObservable(specialists.onChanged)
			.subscribe(ch => this.updateFilter({
				specialistUserID: ch.currState.value[0]?.id,
				specialistGroupID: 0,
			}, 'specialistUserID'));
		this.wrapObservable(specialistGroups.onChanged)
			.subscribe(ch => this.updateFilter({
				specialistGroupID: ch.currState.value[0]?.id,
			}, 'specialistGroupID'));

		this.props.filterService.mode$.pipe(takeUntil(this.onDestroy$)).subscribe(mode => {
			this.props.filterService.updateFilter({
				schoolID: 0,
				teacherID: 0,
				classID: 0,
				groupID: 0,
				specialistGroupID: 0,
				specialistType: 0,
				specialistUserID: 0,
				gradeLevelID: 0,
			});
		});

		this.eventBusManager.subscribe(StudentViewEvent, (e: StudentViewEvent) => {
			this.filters.controls.name.value = `${e.firstName} ${e.lastName}`;
		});

		const urlParams = new URLSearchParams(window.location.search);
		const firstName = urlParams.get('firstname');
		const lastName = urlParams.get('lastname');

		if (firstName && lastName) {
			this.filters.controls.name.value = `${firstName} ${lastName}`;
		}
	}

	render() {
		const hasStudents = !!this.props.dataService.data.students.length;
		const currentUser = userStorage.get();
		const specialistTitle = currentUser.userType === UserType.PA ? 'Pre-Assess' : 'Specialist';
		return <Form controller={this.filters}>
			<FilterHeader filterService={this.props.filterService}
			              dataService={this.props.dataService}
			              nameField={this.filters.controls.name}
			              hasStudents={hasStudents}/>
			{this.props.showSpecialistFilter && <ModeSwitcher filterService={this.props.filterService} specialistTitle={specialistTitle}/>}
			{hasStudents &&
				<ReactiveComponent stream={this.props.filterService.mode$}>
					{value => <div className='filters'>
						{this.renderClassicFilters(value)}
						{this.renderSpecialistFilters(value, specialistTitle)}
					</div>}
				</ReactiveComponent>
			}
		</Form>;
	}

	private renderClassicFilters(mode: HierarchyMode): ReactNode {
		if (mode !== HierarchyMode.Classic) {
			return;
		}

		return <div className='filters_container'>
			{this.renderSchools()}
			{this.renderTeachers()}
			{this.renderClasses()}
			{this.renderGroups()}
			{this.renderGradeScales()}
		</div>;
	}

	private renderSpecialistFilters(mode: HierarchyMode, specialistTitle: string): ReactNode {
		if (mode === HierarchyMode.Specialist || mode === HierarchyMode.PreAssess) {
			return <div className='filters_container'>
				{this.renderSpecialistTypes()}
				{this.renderSchools()}
				{this.renderSpecialists(specialistTitle)}
				{this.renderSpecialistGroups()}
				{this.renderGradeScales()}
			</div>;
		}
	}

	private renderSchools(): ReactNode {
		const value = this.props.filterService.schools;
		if (value && value.length > 1) {
			return <FormElement control={this.filters.controls.schools}>
				<Label>School</Label>
				<Dropdown placeholder='School' optionName='value' autoWidth>
					{this.props.filterService.schools.map(s => <Option key={s.id} value={s}>{s.value}</Option>)}
				</Dropdown>
			</FormElement>;
		}
	}

	private renderGradeScales(): ReactNode {
		return <ReactiveComponent stream={this.props.filterService.gradeLevels$}>
			{value => value && value.length && <FormElement control={this.filters.controls.gradeLevels}>
				<Label>Grade</Label>
				<Dropdown optionName='value' autoWidth>
					{value?.map(gl => <Option key={gl.id} value={gl}>{gl.value}</Option>)}
				</Dropdown>
			</FormElement>}
		</ReactiveComponent>;
	}

	private renderTeachers(): ReactNode {
		return <ReactiveComponent stream={this.props.filterService.teachers$}>
			{value => value && <FormElement control={this.filters.controls.teachers}>
				<Label>Primary Teacher</Label>
				<Dropdown optionName='value' autoWidth>
					{value?.map(t => <Option key={t.id} value={t}>{t.value}</Option>)}
				</Dropdown>
			</FormElement>}
		</ReactiveComponent>;
	}

	private renderClasses(): ReactNode {
		return <ReactiveComponent stream={this.props.filterService.classes$}>
			{value => value && <FormElement control={this.filters.controls.classes}>
				<Label>Class</Label>
				<Dropdown optionName='value' autoWidth>
					{value?.map(t => <Option key={t.id} value={t}>{t.value}</Option>)}
				</Dropdown>
			</FormElement>}
		</ReactiveComponent>;
	}

	private renderGroups(): ReactNode {
		return <ReactiveComponent stream={this.props.filterService.groups$}>
			{value => value && <FormElement control={this.filters.controls.groups}>
				<Label>Group</Label>
				<Dropdown optionName='value' autoWidth>
					{value?.map(t => <Option key={t.id} value={t}>{t.value}</Option>)}
				</Dropdown>
			</FormElement>}
		</ReactiveComponent>;
	}

	private renderSpecialistTypes(): ReactNode {
		const currentUser = userStorage.get();
		return <ReactiveComponent stream={this.props.filterService.specialistTypes$}>
			{value => value && value.length && currentUser.userType === UserType.D &&
				<FormElement control={this.filters.controls.specialistTypes}>
					<Label>Specialist Type</Label>
					<Dropdown optionName='value' autoWidth>
						{value.map(v => <Option key={v.id} value={v}>{v.value}</Option>)}
					</Dropdown>
				</FormElement>}
		</ReactiveComponent>;
	}

	private renderSpecialists(specialistTitle: string): ReactNode {
		return <ReactiveComponent stream={this.props.filterService.specialists$}>
			{value => <FormElement control={this.filters.controls.specialists}>
				<Label>{specialistTitle}</Label>
				<Dropdown optionName='value' autoWidth>
					{value?.map(v => <Option key={v.id} value={v}>{v.value}</Option>)}
				</Dropdown>
			</FormElement>}
		</ReactiveComponent>;
	}

	private renderSpecialistGroups(): ReactNode {
		return <ReactiveComponent stream={this.props.filterService.specialistGroups$}>
			{value => value && <FormElement control={this.filters.controls.specialistGroups}>
				<Label>Group</Label>
				<Dropdown optionName='value' autoWidth>
					{value?.map(v => <Option key={v.id} value={v}>{v.value}</Option>)}
				</Dropdown>
			</FormElement>}
		</ReactiveComponent>;
	}

	public componentWillUnmount() {
		this.eventBusManager.destroy();
		this.onDestroy$.next();
	}
}
