import {ServerClient} from 'pages/landing/kit/server-client';
import {Form, FormField, NumberField, Validators} from '@esgi/deprecated/knockout';
import {PCInputComponent} from '../pc-input/pc-input';

import './pricing-form.less';

export class PricingFormModel {
	promocode: string;
	month: number;
}

export interface PriceResponse {
    price: number;
}

export class PricingForm extends Form {
	private readonly startDate: string;
	private readonly promocode: PCInputComponent;
	private month: FormField<number>;
	private price: FormField<string>;
	private endDate: FormField<string>;
	constructor(promocode?: string) {
		super();
		this.month = this.createMonth();
		this.price = this.createPrice();
		this.promocode = this.createPromocode(promocode);
		this.startDate = this.getStartDate();
		this.endDate = this.createEndDate();
		this.promocode.events.acFlowClicked(() => this.toAcFlow());
	}

	get promoCodeField() {
		return this.promocode;
	}

	get monthField (){
		return this.month;
	}

	toAcFlow() {
		$(this).trigger('acFlowClicked');
	}

	events = {
		acFlowClicked: (callback) => {
			$(this).on('acFlowClicked', callback);
		},
	};

	private createEndDate() {
		const endDate = new FormField();
		endDate.value = ko.computed(() => {
			const endDate = new Date(this.startDate);
			const month = this.month.value();

			if (!isNaN(month)) {
				endDate.setMonth(endDate.getMonth() + month);
			}
			return this.formatDate(endDate.getMonth() + 1, endDate.getDate(), endDate.getFullYear());
		});

		return endDate;
	}

	private getStartDate() {
		const date = new Date();
		return this.formatDate(date.getMonth() + 1, date.getDate(), date.getFullYear());
	}

	private formatDate(month, day, year) {
		return `${(month / 10 >= 1 ? month : '0' + month)}/${(day / 10 >= 1 ? day : '0' + day)}/${year}`;
	}

	private createMonth() {
		const month = new NumberField(12);
		month.validation.validators.push(Validators.Range(12, 36, '<div>Please enter a value between 12 and 36 months</div>'));
		month.validation.errorPosition = 'bottom';
		month.validation.validationMessageTitleUseHtml = true;
		month.value.subscribe((value) => {
			if (!value) {
				this.month.value(0);
			}
			if (value >= 12 && value <= 36) {
				this.calculatePrice();
				this.validation.removeError();
			}
		});
		return month;
	}

	private createPrice() {
		return new FormField();
	}

	private createPromocode(initValue?: string) {
		const f = new PCInputComponent(initValue);
		f.promoCodeInfo.subscribe((v) => this.calculatePrice());
		return f;
	}

	removeTooltip() {
		this.promocode.removeTooltip();
	}

	calculatePrice() {
		if (!this.month.value()) {
			this.price.value('');
			return;
		}

		ServerClient.SSOAPI.Get<PriceResponse>('Registration/Pricing', 'Get', {
			data: {
				months: this.month.value(),
				promoCode: this.promocode.serialize(),
			},
		}).done((price) => {
			this.price.value('$' + price.price);
		}).fail(() => {
			this.price.value('');
		});
	}

	serialize(): PricingFormModel {
		return {promocode: this.promocode.serialize(), month: this.month.value()};
	}
	afterRender(rootElement: JQuery): JQueryPromise<any> {
		this.month.rootElement = $('#month');
		return super.afterRender(rootElement);
	}

	template = () => {
		return <div className='pricing-form' data-bind='afterRender:true'>
            <div className='promocode'>
                            <span className='pc-label'>
                                <b className=''>Promo Code?</b>
                                <a href='#' data-bind='click:toAcFlow'>Have an activation code?</a>
                            </span>
                <ko data-bind='render:promocode'/>
            </div>
            <div className='pricing'>
                <div className='item price'>
                    <b>Total <span data-bind='text:price.value'/></b>
                </div>
                <div className='item'>
                    <div className='month' data-bind='with:month'>
                        <div data-bind="css: {'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
                            <input data-bind='value:value' type='number' id='month' min='12' max='36'
                                   className='form-control'/>
                        </div>
                        <span style={{alignSelf: 'flex-end'}}>Months</span>
                    </div>
                </div>
                <div className='item date'>
                    <span>Start Date:</span>
                    <b data-bind='text:startDate'/>
                </div>
                <div className='item date'>
                    <span>Expiration</span>
                    <b data-bind='text:endDate.value'/>
                </div>
            </div>
        </div>;
	};
}
