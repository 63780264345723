import {Student, Group} from '@esgi/main/libs/store';
import {ListsContentWrapper, WrapperBox} from './index.styled';
import {Dispatch, useMemo} from 'react';
import {Box} from '@esgi/ui/layout';
import {SelectableList} from '../list-variant/selectable-list';
import {ExpandableList} from '../list-variant/expandable-list';
import {EmptyContentTemplate} from '@esgi/main/features/teacher/home';

type Props = {
	groups: Group[];
	visibleStudents: Student[];
	onStudentClick: (studentId: Student['id']) => void;
	selectedStudentsIDs: Student['id'][];
	toggleEntityStudents: Dispatch<{studentsIDs: Student['id'][]; method: 'add' | 'remove'}>;
};

export function GroupsContent({
	groups,
	visibleStudents,
	onStudentClick,
	selectedStudentsIDs,
	toggleEntityStudents,
}: Props) {
	const studentsWithoutGroup = useMemo(() => {
		const studentIdsInGroups = new Set(groups.map(({studentIDs}) => studentIDs).flat());
		const studentsWithoutGroup: Student[] = [];

		visibleStudents.forEach((student) => {
			if (!studentIdsInGroups.has(student.id)) {
				studentsWithoutGroup.push(student);
			}
		});

		return studentsWithoutGroup;
	}, [groups, visibleStudents]);

	const groupsWithStudents = useMemo(
		() =>
			groups.map(({studentIDs, ...classModel}) => ({
				...classModel,
				students: visibleStudents.filter(({id}) => studentIDs.includes(id)),
			})),
		[groups, visibleStudents],
	);

	return (
		<WrapperBox>
			<ListsContentWrapper>
				<Box>
					{groupsWithStudents.length ? (
						groupsWithStudents.map(({id: groupId, name: groupName, students}) => (
							<ExpandableList
								key={groupId}
								triggerLabel={groupName}
								students={students}
								onStudentClick={onStudentClick}
								selectedStudentsIDs={selectedStudentsIDs}
								toggleEntityStudents={toggleEntityStudents}
							/>
						))
					) : (
						<EmptyContentTemplate size='full' templateText='No groups to display' />
					)}
				</Box>

				<SelectableList
					students={studentsWithoutGroup}
					onStudentClick={onStudentClick}
					selectedStudentsIDs={selectedStudentsIDs}
				/>
			</ListsContentWrapper>
		</WrapperBox>
	);
}
