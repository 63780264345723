import {useCallback, useMemo, useState} from 'react';
import {Question} from '@esgi/selfassess';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useQuestionBankService} from '../../hooks';
import {BankQuestionModel, ViewMode} from '../../types';
import {Header} from './components/header';
import {QuestionList} from './components/question-list';
import {SelectionPanel} from './components/selection-panel';
import {QuestionPreview} from './components/question-preview';
import {StyledContentContainer, SelectionPanelWrapper, StyledQuestionListContainer} from './index.styled';

type Props = {
	isSmallScreen: boolean;
	selectedStandardType: number;
}

export function QuestionBankContent({selectedStandardType, isSmallScreen}: Props) {
	const service = useQuestionBankService();

	const [previewQuestion, setPreviewQuestion] = useState<Question>(null);

	const selectedView = useBehaviorSubject(service.selectedView);
	const isLoading = useBehaviorSubject(service.isLoading);
	const selectedQuestions = useBehaviorSubject(service.selectedQuestions);
	const searchText = useBehaviorSubject(service.searchText$);
	const hasChanges = useBehaviorSubject(service.hasChanges$);
	const isShowOnlySelected = useBehaviorSubject(service.isShowOnlySelected);
	const isSaving = useBehaviorSubject(service.isSaving);

	const selectedQuestionsCount = useMemo(() => (
		Object.keys(selectedQuestions).length
	), [selectedQuestions]);

	const handleViewChange = useCallback((value: ViewMode) => {
		service.selectedView.next(value);
		service.savePageState(value);
	}, [service]);

	const handlePreviewOpen = useCallback((question: BankQuestionModel) => {
		const template = JSON.parse(question.templateJson);

		setPreviewQuestion({
			...template,
			id: question.id,
			thumbnailUrl: question.thumbnailUrl,
			templateType: question.templateType,
		});
	}, []);

	const handlePreviewClose = useCallback(() => {
		setPreviewQuestion(null);
	}, []);

	const handleClearSelection = useCallback(() => {
		service.clearSelection();
	}, [service]);

	const handleShowOnlySelectedClick = useCallback(()=>{
		service.toggleShowOnlySelected();
	}, [service]);

	const handleAddSelection = useCallback(() => {
		service.saveTestAndClose();
	}, [service]);

	return (
		<StyledContentContainer isSmallScreen={isSmallScreen}>
			<Header isSmallScreen={isSmallScreen} searchText={searchText} selectedView={selectedView} onViewChanged={handleViewChange} hasChanges={hasChanges}/>
			<StyledQuestionListContainer>
				<QuestionList selectedView={selectedView} onQuestionPreview={handlePreviewOpen} selectedStandardType={selectedStandardType} />
			</StyledQuestionListContainer>
			{previewQuestion && (
				<QuestionPreview question={previewQuestion} onClose={handlePreviewClose} />
			)}
			{(selectedQuestionsCount > 0) && (
				<SelectionPanelWrapper>
					<SelectionPanel
						selectedCount={selectedQuestionsCount}
						isLoading={isLoading}
						onClear={handleClearSelection}
						showOnlySelected={isShowOnlySelected}
						onShowOnlySelectedClick={handleShowOnlySelectedClick}
						onAddSelection={handleAddSelection}
						isSaving={isSaving}
					/>
				</SelectionPanelWrapper>
			)}
		</StyledContentContainer>
	);
}