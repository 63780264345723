import {useMemo} from 'react';
import {HeaderCellId} from '../../types';
import {TableHeaderCellSortableItem} from '@esgi/main/kits/assignments';

export function useSortableCells({withTestsTakenBar}: {withTestsTakenBar: boolean}) {
	return useMemo<(TableHeaderCellSortableItem<HeaderCellId> & {permitted: boolean})[]>(
		() => [
			{
				cellKey: HeaderCellId.Student,
				cellName: 'Student Name',
				permitted: true,
			},
			{
				cellKey: HeaderCellId.Class,
				cellName: 'Class',
				permitted: true,
			},
			{
				cellKey: HeaderCellId.Group,
				cellName: 'Group',
				permitted: true,
			},
			{
				cellKey: HeaderCellId.TestsTaken,
				cellName: 'Tests Taken',
				justifyContent: 'end',
				permitted: withTestsTakenBar,
			},
		],
		[withTestsTakenBar],
	);
}
