import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {
	ClassTeacherItem,
	GroupTeacherItem,
	StudentInfoTeacher,
} from '../../../../types/teacher-types';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {uniq} from 'underscore';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	classes: ClassTeacherItem[];
	selectedStudents: StudentInfoTeacher[];
	students: StudentInfoTeacher[];
	onChange: (value: StudentInfoTeacher[]) => void;
}

function mapGroupToStatus(
	groups: GroupTeacherItem[],
	selectedStudentsIDs: number[],
): Record<number, boolean> {
	const selectedGroups = uniq(
		selectedStudentsIDs.map((id) => groups.filter(
			({studentIDs}) => studentIDs.includes(id),
		)).flat(),
		({id}) => id,
	);
	const fullSelected = selectedGroups
		.filter(({studentIDs}) => studentIDs.every(
			(studentID) => selectedStudentsIDs.includes(studentID),
		))
		.map(({id}) => id);
	const partialSelected = selectedGroups
		.filter(({id}) => !fullSelected.includes(id))
		.map(({id}) => id);
	const notSelected = groups.
		filter((g) => !selectedGroups.includes(g))
		.map(({id}) => id);

	const result = {};
	notSelected.forEach((groupID) => result[groupID] = false);
	fullSelected.forEach((groupID) => result[groupID] = true);
	partialSelected.forEach((groupID) => result[groupID] = undefined);

	return result;
}

function mapClassToStatus(
	classes: ClassTeacherItem[],
	selectedStudentsIDs: number[],
): Record<number, boolean> {
	const classesToStudentIDs = classes.map(({id: classID, students}) => ({
		classID,
		studentIDs: students.map(({id}) => id),
	}));

	const fullSelected = classesToStudentIDs
		.filter(({studentIDs}) => studentIDs.every((id) => selectedStudentsIDs.includes(id)))
		.map(({classID}) => classID);

	const partialSelected = classesToStudentIDs
		.filter(({studentIDs}) => studentIDs.some((id) => selectedStudentsIDs.includes(id)))
		.filter(({classID}) => !fullSelected.includes(classID))
		.map(({classID}) => classID);

	const notSelected = classesToStudentIDs
		.filter(({studentIDs}) => studentIDs.every((id) => !selectedStudentsIDs.includes(id)))
		.map(({classID}) => classID);

	const result = {};
	notSelected.forEach((classID) => result[classID] = false);
	fullSelected.forEach((classID) => result[classID] = true);
	partialSelected.forEach((classID) => result[classID] = undefined);

	return result;
}

export function ClassGroupDropdown(props: Props): JSX.Element {
	const {classes, students, selectedStudents, onChange} = props;
	const ref = useRef<Dropdown<any>>(null);
	const placeholder = 'Select classes and groups';
	const initialSelectedStudentsIDs = useMemo(
		() => selectedStudents.map(({id}) => id),
		[selectedStudents],
	);
	const [selectedClasses, setSelectedClasses] = useState({});
	const [selectedGroups, setSelectedGroups] = useState({});

	const onClassSelect = useCallback(({id, groupIds}: ClassTeacherItem) => {
		const value = Boolean(selectedClasses[id]);
		setSelectedClasses({
			...selectedClasses,
			[id]: !value,
		});
		if (groupIds.length) {
			const groups = {};
			groupIds.forEach((id) => groups[id] = !value);
			setSelectedGroups({
				...selectedGroups,
				...groups,
			});
		}
	}, [selectedClasses]);

	const onGroupSelect = useCallback(({id}: GroupTeacherItem) => {
		const {id: classID, groupIds = []} = classes.find(
			({groupIds}) => groupIds.includes(id),
		);
		const value = Boolean(selectedGroups[id]);
		const selected = {
			...selectedGroups,
			[id]: !value,
		};
		setSelectedGroups(selected);
		if (groupIds.length) {
			const selectedGroupIds = Object.keys(selected)
				.filter((id) => selected[id])
				.map((id) => +id);
			const value = groupIds.every((id) => selectedGroupIds.includes(id));
			setSelectedClasses({
				...selectedClasses,
				[classID]: value,
			});
		}
	}, [selectedGroups]);

	const onCancel = () => {
		ref.current.closeDropdown();
	};

	const onSave = () => {
		let result = [];
		const groups = classes.map(({groups}) => groups).flat();
		classes.forEach(({id, students}) => {
			if (selectedClasses[id]) {
				result = [
					...result,
					...students.map(({id}) => id),
				];
			}
		});
		groups.forEach(({id, studentIDs}) => {
			if (selectedGroups[id]) {
				result = [
					...result,
					...studentIDs,
				];
			}
		});
		result = [...(new Set(result))];
		onChange(students.filter(({id}) => result.includes(id)));
		onCancel();
	};

	useEffect(() => {
		const groups = classes.map(({groups}) => groups).flat();
		setSelectedClasses(mapClassToStatus(classes, initialSelectedStudentsIDs));
		setSelectedGroups(mapGroupToStatus(groups, initialSelectedStudentsIDs));
	}, [classes, initialSelectedStudentsIDs]);

	return (
		<Dropdown
			ref={ref}
			multiselect={true}
			optionName='name'
			value={[]}
			setValue={() => {}}
			placeholder={placeholder}
			displayLabel={() => placeholder}
		>
			<div className={styles.container}>
				{classes.map((c) => {
					const isEmptyClass = !c.students.length;

					return (
						<>
							<OnHoverTooltip
								message={isEmptyClass ? 'This class does not have any students.' : ''}
								className={styles.tooltip}
								key={c.id}
							>
								<div>
									<Option
										className={join(styles.class, isEmptyClass && styles.notActiveClass)}
										value={c}
										checked={isEmptyClass ? false : selectedClasses[c.id]}
										disabled={isEmptyClass}
										onClick={onClassSelect}
									>
										{c.name}
									</Option>
								</div>
							</OnHoverTooltip>

							{c.groups.map((g) => {
								const isEmptyGroup = !g.studentIDs.length;

								return (
									<OnHoverTooltip
										message={isEmptyGroup ? 'This group does not have any students.' : ''}
										className={styles.tooltip}
										key={g.id}
									>
										<div>
											<Option
												className={join(styles.group, isEmptyGroup && styles.notActiveGroup)}
												value={g}
												checked={isEmptyGroup ? false : selectedGroups[g.id]}
												disabled={isEmptyGroup}
												onClick={onGroupSelect}
											>
												{g.name}
											</Option>
										</div>
									</OnHoverTooltip>
								);
							})}
						</>
					);
				})}
			</div>
			<div className={styles.footer}>
				<Buttons.Gray onClick={onCancel}>Cancel</Buttons.Gray>
				<Buttons.Contained onClick={onSave}>Save</Buttons.Contained>
			</div>
		</Dropdown>
	);
}
