/* eslint-disable @typescript-eslint/member-ordering */
import {isNull} from 'underscore';
import {
	PageSectionsState,
	SetSelectedSubjectIdParams,
	StudentSectionState,
	SubjectBelongsTo,
	SubjectTabId,
	UpdateStudentPanelStateArgs,
} from '../types';
import {BehaviorSubject, debounceTime, of, Subscription, switchMap, tap} from 'rxjs';
import {defaultPageSectionState} from '../constants';
import {BaseService} from '@esgi/core/service';
import {Class, Group, Student, subjectsStore, SubjectTab} from '@esgi/main/libs/store';
import {V2TeachersPagesHomeCommonController, V2UserStateController} from '@esgi/contracts/esgi';
import {EventBusManager} from '@esgillc/events';
import {UpdateSelectedWidgets, WidgetType} from '@esgi/main/features/teacher/widgets';
import {mapToEnum} from 'shared/utils';
import {StudentsPanelTabID} from '@esgi/main/features/teacher/students-panel';
import {getUser} from '@esgi/core/authentication';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {CardViewType} from '@esgi/main/features/teacher/home';
import {SubjectLevelToSubjectBelongsTo} from '../utils';

export class DataStateService extends BaseService {
	private readonly currentUser = getUser();

	public isFirstEntry$ = new BehaviorSubject(false);

	public isLoadedData$ = new BehaviorSubject(false);
	public isInitedSubjectData$ = new BehaviorSubject(false);
	public isUpdatingState$ = new BehaviorSubject(false);

	public sectionsState$ = new BehaviorSubject<PageSectionsState>(defaultPageSectionState);

	public selectedSubjectId$ = new BehaviorSubject<SubjectTab['id'] | null>(null);
	public activeSubjectTab$ = new BehaviorSubject(SubjectTabId.All);

	public selectedStudentId$ = new BehaviorSubject<Student['id'] | null>(null);
	public selectedClassId$ = new BehaviorSubject<Student['id'] | null>(null);
	public selectedGroupId$ = new BehaviorSubject<Student['id'] | null>(null);
	public activeStudentTab$ = new BehaviorSubject(StudentsPanelTabID.Classes);

	public testSectionCardViewType$ = new BehaviorSubject(CardViewType.List);

	private eventBus = new EventBusManager();
	private updateStateRequestSubscription: Subscription | null = null;
	private stateController = new V2TeachersPagesHomeCommonController();
	private subjectController = new V2UserStateController();

	private readonly subjects$ = new BehaviorSubject<SubjectTab[]>([]);
	private readonly storageSubjects = subjectsStore();

	constructor() {
		super();

		this.stateController.init().subscribe({
			next: ({state}) => {
				const teacherState: PageSectionsState = state || {} as PageSectionsState;

				teacherState.widgets = {
					studentLevel: (teacherState?.widgets?.studentLevel || []).map((w) => mapToEnum(w, WidgetType)),
					classGroupLevel: (teacherState?.widgets?.classGroupLevel || []).map((w) => mapToEnum(w, WidgetType)),
				};

				if (isNull(teacherState.studentSection) || isNull(teacherState.subjectSection)) {
					this.updateTeacherState(defaultPageSectionState);
					this.isFirstEntry$.next(true);
				} else {
					this.sectionsState$.next(teacherState);
				}

				this.iniSubjectSectionState();
				this.iniStudentSectionState();

				this.testSectionCardViewType$.next(
					teacherState.testSection?.pieChartMode ? CardViewType.Grid : CardViewType.List,
				);
			},
			complete: () => {
				this.isLoadedData$.next(true);
			},
		});

		this.eventBus.subscribe(UpdateSelectedWidgets, (args) =>
			this.updateTeacherState({
				widgets: {
					studentLevel: args.studentLevel,
					classGroupLevel: args.classLevel,
				},
			}),
		);

		this.storageSubjects.get().subscribe(({data: value}) => this.subjects$.next(value));
	}

	private getSectionsState() {
		return this.sectionsState$.value;
	}

	public updateTeacherState(state: Partial<PageSectionsState>) {
		const currentSectionsState = this.getSectionsState();

		this.isUpdatingState$.next(true);

		const newTeacherState = {...currentSectionsState, ...state};

		this.sectionsState$.next(newTeacherState);

		if (!isNull(this.updateStateRequestSubscription)) {
			this.updateStateRequestSubscription.unsubscribe();
		}

		this.updateStateRequestSubscription = this.sectionsState$
			.pipe(
				debounceTime(300),
				switchMap((state) => {
					if (!isNull(state)) {
						return this.stateController.stateUpdate({state});
					}

					return of();
				}),
				tap(() => this.isUpdatingState$.next(false)),
			)
			.subscribe();
	}

	public toggleOpenPanel(panel: keyof Omit<PageSectionsState, 'widgets' | 'testSection'>) {
		const currentSectionsState = this.getSectionsState();

		const panelState = currentSectionsState[panel];

		const newPanelState = {
			...panelState,
			opened: !panelState.opened,
		};

		this.updateTeacherState({[panel]: newPanelState});
	}

	private get subjectStateKeysWithCorrespondTabIds(): [SubjectBelongsTo, SubjectTabId][] {
		return [
			['district', SubjectTabId.District],
			['school', SubjectTabId.School],
			['personal', SubjectTabId.Personal],
		];
	}

	private get studentStateKeysWithCorrespondTabIds(): [
		keyof Pick<StudentSectionState, 'classes' | 'groups' | 'students'>,
		StudentsPanelTabID,
	][] {
		return [
			['classes', StudentsPanelTabID.Classes],
			['groups', StudentsPanelTabID.Groups],
			['students', StudentsPanelTabID.Students],
		];
	}

	private iniSubjectSectionState() {
		const {subjectSection: subjectSectionState} = this.getSectionsState();

		const selectedSubjectIds: SubjectTab['id'][] = [];

		let activeSubjectTabId: SubjectTabId | null = null;

		this.subjectStateKeysWithCorrespondTabIds.forEach(([key, subjectId]) => {
			const {selectedIDs, opened} = subjectSectionState[key];

			selectedSubjectIds.push(...selectedIDs);

			if (opened) {
				activeSubjectTabId = subjectId;
			}
		});

		this.currentUser?.agreementLevelCode === 'T' && activeSubjectTabId !== SubjectTabId.Personal
			? this.setActiveSubjectTab(SubjectTabId.Personal)
			: this.activeSubjectTab$.next(activeSubjectTabId ?? SubjectTabId.All);

		return this.subjectController.selectedSubjectInit().subscribe((response) => {
			this.isInitedSubjectData$.next(true);
			if (response?.subjectID) {
				const exist = this.subjects$.value.find(({id}) => id === response.subjectID);
				if (exist) {
					this.selectedSubjectId$.next(response.subjectID);
				} else if (this.subjects$.value.length) {
					const first = this.subjects$.value.find(({hidden}) => !hidden);
					first && this.setSelectedSubjectId({
						subjectId: first.id,
						belongsTo: SubjectLevelToSubjectBelongsTo[first.level],
					});
				}
			}
		});
	}

	public setSelectedSubjectId(args: SetSelectedSubjectIdParams) {
		const {subjectSection: subjectSectionStateValue} = this.getSectionsState();

		const subjectSectionState = {
			...subjectSectionStateValue,
		};

		this.subjectStateKeysWithCorrespondTabIds.forEach(([key]) => {
			if (args.subjectId !== null && 'belongsTo' in args) {
				const {subjectId, belongsTo} = args;

				subjectSectionState[key].selectedIDs = key === belongsTo ? [subjectId] : [];

				return;
			}

			subjectSectionState[key].selectedIDs = [];
		});

		if(args.subjectId){
			this.selectedSubjectId$.next(args.subjectId);
			this.updateTeacherState({
				subjectSection: subjectSectionState,
			});

			this.subjectController
				.selectedSubjectUpdate({
					subjectID: args.subjectId,
					subjectType: SubjectType.Personal,
				})
				.subscribe();
		}
	}

	public setActiveSubjectTab(activeSubjectTab: SubjectTabId) {
		const {subjectSection: subjectSectionStateValue} = this.getSectionsState();

		const subjectSectionState = structuredClone(subjectSectionStateValue);

		this.activeSubjectTab$.next(activeSubjectTab);

		this.subjectStateKeysWithCorrespondTabIds.forEach(([key, subjectId]) => {
			subjectSectionState[key].opened = activeSubjectTab === subjectId;
		});

		this.updateTeacherState({
			subjectSection: subjectSectionState,
		});
	}

	private iniStudentSectionState() {
		const {studentSection: studentSectionState} = this.getSectionsState();

		let activeStudentTabId: StudentsPanelTabID | null = null;

		this.studentStateKeysWithCorrespondTabIds.forEach(([key, studentTabId]) => {
			const {selectedIDs, opened} = studentSectionState[key];

			if (selectedIDs.length) {
				switch (key) {
					case 'classes':
						this.selectedClassId$.next(selectedIDs[0]!);
						break;
					case 'groups':
						this.selectedGroupId$.next(selectedIDs[0]!);
						break;
					case 'students':
						this.selectedStudentId$.next(selectedIDs[0]!);
						break;
				}
			}

			if (opened) {
				activeStudentTabId = studentTabId;
			}

			this.activeStudentTab$.next(activeStudentTabId ?? StudentsPanelTabID.Classes);
		});
	}

	private updateStudentPanelState({classes, groups, students}: UpdateStudentPanelStateArgs) {
		const {studentSection: studentSectionStateValue} = this.getSectionsState();

		const studentSectionState = structuredClone(studentSectionStateValue);

		studentSectionState.classes.selectedIDs = classes.requestData;
		studentSectionState.groups.selectedIDs = groups.requestData;
		studentSectionState.students.selectedIDs = students.requestData;

		this.selectedClassId$.next(classes.stateData);
		this.selectedGroupId$.next(groups.stateData);
		this.selectedStudentId$.next(students.stateData);

		this.updateTeacherState({
			studentSection: studentSectionState,
		});
	}

	public setSelectedStudentId(studentId: Student['id'] | null) {
		this.updateStudentPanelState({
			classes: {
				requestData: [],
				stateData: null,
			},
			groups: {
				requestData: [],
				stateData: null,
			},
			students: {
				requestData: isNull(studentId) ? [] : [studentId],
				stateData: studentId,
			},
		});
	}

	public setSelectedClassId(classId: Class['id']) {
		this.updateStudentPanelState({
			classes: {
				requestData: [classId],
				stateData: classId,
			},
			groups: {
				requestData: [],
				stateData: null,
			},
			students: {
				requestData: [],
				stateData: null,
			},
		});
	}

	public setSelectedGroupId(groupId: Group['id']) {
		this.updateStudentPanelState({
			classes: {
				requestData: [],
				stateData: null,
			},
			groups: {
				requestData: [groupId],
				stateData: groupId,
			},
			students: {
				requestData: [],
				stateData: null,
			},
		});
	}

	public setActiveStudentTab(activeSubjectTab: StudentsPanelTabID) {
		const {studentSection: studentSectionStateValue} = this.getSectionsState();

		const studentSectionState = structuredClone(studentSectionStateValue);

		this.activeStudentTab$.next(activeSubjectTab);

		this.studentStateKeysWithCorrespondTabIds.forEach(([key, subjectId]) => {
			studentSectionState[key].opened = activeSubjectTab === subjectId;
		});

		this.updateTeacherState({
			studentSection: studentSectionState,
		});
	}

	public setTestSectionCardViewType(newType: CardViewType) {
		this.testSectionCardViewType$.next(newType);

		this.updateTeacherState({
			testSection: {
				pieChartMode: newType === CardViewType.Grid,
			},
		});
	}

	public override dispose() {
		super.dispose();
		this.stateController.dispose();
		this.subjectController.dispose();
		this.eventBus.destroy();
	}
}
