import {unified} from 'unified';
import stringify from 'remark-stringify';
import {slateToRemark} from 'remark-slate-transformer';
import type {Node} from 'slate';

const processor = unified().use(stringify);

export function serializeMarkdown(nodes: Node[]) {
	const ast = processor.runSync(
		slateToRemark(nodes, {
			overrides: {
				'numbered-list': (node: any, next: any) => ({
					type: 'list',
					ordered: true,
					depth: node.depth,
					children: next(node.children),
				}),
				'bulleted-list': (node: any, next: any) => ({
					type: 'list',
					ordered: false,
					depth: node.depth,
					children: next(node.children),
				}),
				'list-item': (node: any, next: any) => ({
					type: 'listItem',
					children: next(node.children),
				}),
			},
		})
	);
	// @ts-ignore: Argument of type 'Node' is not assignable to parameter of type 'Root'. Problem with Slate package.
	const text = processor.stringify(ast);
	// Bug with extra line breaks within listItem. Might have to do with: https://github.com/remarkjs/remark/issues/563
	return text.replace(/\n([1-9]\.)/g, '$1').replace(/\n\n\s+:/g, ':');
}
