import React, {useCallback, useEffect, useState} from 'react';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Button, PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import DataService from '../../data-service';
import {SessionModel} from '../../types';
import {useStreamEffect} from '@esgillc/ui-kit/utils';

interface Props {
	canEdit: boolean;
	editMode: boolean;
	onCancelClicked: () => void;
	onEditClicked: () => void;
	onSaveClicked: () => void;
	onRestoreClicked: () => void;
	service: DataService;
}

export function Footer ({canEdit, editMode, onRestoreClicked, onSaveClicked, onEditClicked, onCancelClicked, service}: Props) {
	const [currentSession, setCurrentSession] = useState<SessionModel>();

	const getConfirmHandler = useCallback(() => {
		if(currentSession?.isDeleted) {
			onRestoreClicked();
		}
		if (editMode) {
			onSaveClicked();
		} else {
			onEditClicked();
		}
	}, [editMode, currentSession?.isDeleted]);

	useStreamEffect(service.currentSession, currentSession => setCurrentSession(currentSession));

	return (
		<>
			<Button onClick={onCancelClicked}>CANCEL</Button>
			{canEdit &&
				<PrimaryButton onClick={getConfirmHandler}>
					{currentSession?.isDeleted ? 'RESTORE' : editMode ? 'SAVE' : 'EDIT'}
				</PrimaryButton>}
		</>
	);
}
