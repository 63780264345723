import {Header, Root, ButtonIcon, ButtonText, SortTable, EmptyBox} from './index.styled';
import {CheckAll} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import React, {useCallback} from 'react';
import {ExpandablePanel} from '@esgi/main/kits/reports';
import {TestRow} from '../test-row';
import {Test} from '../../../../service/types';
import {useTestsControl} from '../../../../hooks/use-tests-control';
import {TestListSkeleton} from '../test-list-skeleton';

type Props = {
	isTestsLoading: boolean,
	tests: Test[],
	onTestSelect: (value: number[]) => void,
}

export function TestList({isTestsLoading, tests, onTestSelect}: Props) {

	const {items, selected, toggleSelected, sort, onSort, onSelectAll} = useTestsControl(tests, onTestSelect);
	const checked = useCallback((id: number) => selected.includes(id), [selected]);

	if (isTestsLoading) {
		return <TestListSkeleton/>;
	}

	if (!tests.length) {
		return (
			<FlexBox align='center' direction='column' justify='center'>
				<EmptyBox>
					<Text font='mono' size='small' color='neutral40'>No tests to display</Text>
				</EmptyBox>
			</FlexBox>
		);
	}

	return (
		<Root>
			<Header>
				<ButtonIcon data-cy='check-all-button' onClick={onSelectAll}>
					<CheckAll/>
				</ButtonIcon>
				<ButtonText data-cy='sort-button' onClick={onSort}>
					<Text size='small'>Test Name</Text>
					<SortTable field={sort.field} direction={sort?.direction}/>
				</ButtonText>
			</Header>
			<ExpandablePanel style={{height: 'calc(100% - 60px)'}}>
				<SelectableList>
					<SelectableList.GroupRoot type='multiple' value={selected.map(item => item.toString())}>
						<SelectableList.Group>
							<GridBox gap='2'>
								{items?.map(item => (
									<SelectableList.Item
										data-cy='test-item-root'
										css={{borderColor: checked(item.id) && '$primaryHighlight'}}
										value={item.id.toString()}
										key={item.id}
										variant='outlined'
										withActiveBackground
										onClick={() => toggleSelected([item.id])}
									>
										<TestRow checked={checked(item.id)} test={item}/>
									</SelectableList.Item>
								))}
							</GridBox>
						</SelectableList.Group>
					</SelectableList.GroupRoot>
				</SelectableList>
			</ExpandablePanel>
		</Root>
	);
}
