import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const ArrowContainer = styled(GridBox, {
	'&:hover': {
		'& div > div': {
			cursor: 'pointer',
			display: 'grid',
		},
	},
});

export const Arrow = styled(Box, {
	variants: {
		visible: {
			true: {
				display: 'grid',
			},
			false: {
				display: 'none',
			},
		},
		rotated: {
			true: {
				transform: 'rotateX(180deg)',
			},
		},
	},
});