import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {GroupItem, Init, Student, Subject, User} from '../models';
import FormService from './form-service';
import {Flag, RequestResultsInit, RequestSettingsSubjects, ResponseSubject} from '../api-models';
import {SubjectType} from '@esgi/core/enums';
import {
	ReportUserSettingsActions,
	ReportUserSettingsApi,
	SettingsFormApiActions,
	SettingsFormApiHost,
} from '../constants';
import {makeSelectedEntityName, selectedTestsFilter} from '../utils';
import {ChangeEvent} from 'react';
import TestRow from './test-row';
import {takeUntil} from 'rxjs/operators';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {HierarchySnapshot} from '../../../hierarchy/core/models';

export default class SettingsService extends BaseService {
	public readOnly = new BehaviorSubject<boolean>(false);
	public includeQuestionNotesOption = new BehaviorSubject<boolean>(false);
	public includeSummaryNotesOption = new BehaviorSubject<boolean>(false);
	public includeGradeOption = new BehaviorSubject<boolean>(false);
	public includeUntestedQuestionsOption = new BehaviorSubject<boolean>(false);
	public includeMessageOption = new BehaviorSubject<boolean>(false);
	public isInit = new BehaviorSubject<boolean>(false);
	public printInColorOption = new BehaviorSubject<boolean>(false);
	public testResultsCorrectVerbiage = new BehaviorSubject<string>('');
	public testResultsIncorrectVerbiage = new BehaviorSubject<string>('');
	public showSessionDateOption = new BehaviorSubject<boolean>(false);
	public hasGradeScales = new BehaviorSubject<boolean>(false);
	public includeSessionOption = new BehaviorSubject<boolean>(false);
	public teacher = new BehaviorSubject<User>(null);
	public subjects = new BehaviorSubject<Subject[]>([]);
	public selectedSubject = new BehaviorSubject<Subject>(null);
	public groupItems = new BehaviorSubject<GroupItem[]>([]);
	public groupType = new BehaviorSubject<string>('');
	public students = new BehaviorSubject<Student[]>([]);
	public selectedStudent = new BehaviorSubject<Student>(null);
	public selectedGroupItem = new BehaviorSubject<GroupItem>(null);
	private subjectsCache: { [key: number]: Subject[] } = {};

	constructor(public changesCollector: ChangesCollector, private hierarchy: HierarchySnapshot, private reloadTests: (subjectChanged?: boolean) => void) {
		super();
		this.selectedSubject.pipe(takeUntil(this.destroy$)).subscribe(s => this.selectedSubjectChanged(s));
	}

	public changeHierarchy(cacheKey: string) {
		if (!this.isInit.value) {
			return;
		}
		switch (cacheKey) {
			case 'groupItemsSelect':
				this.groupItemChange(this.selectedGroupItem.value.id);
				break;
			case 'studentsSelect':
				this.studentChange(this.selectedStudent.value.studentId);
				this.loadSubjects();
				this.reloadTests();
				break;
			case 'subjectsSelect':
				this.hasGradeScales.next(this.selectedSubject.value.hasScales);
				this.reloadTests(true);
				break;
		}
	}

	public selectedGroupItemChanged(c: GroupItem) {
		if (!c || !this.isInit.value) {
			return;
		}
		this.selectedGroupItem.next(c);
		this.students.next(c.students);
		this.selectedStudent.next(c.selectedStudent);
	}

	public initModel(model: Init) {
		let result = false;
		this.readOnly.next(model.readOnly);
		this.includeQuestionNotesOption.next(model.user.includeQuestionNotesOption.value);
		this.includeSummaryNotesOption.next(model.user.includeSummaryNotesOption.value);
		this.includeGradeOption.next(model.user.includeGradeOption.value);
		this.includeUntestedQuestionsOption.next(model.user.includeUntestedQuestionsOption.value);
		this.includeMessageOption.next(model.user.includeMessageOption.value);
		this.printInColorOption.next(model.user.printInColorOption.value);
		this.showSessionDateOption.next(model.user.showSessionDateOption.value);
		this.teacher.next(model.user);
		this.testResultsCorrectVerbiage.next(model.testResultsCorrectVerbiage);
		this.testResultsIncorrectVerbiage.next(model.testResultsIncorrectVerbiage);

		if (model.groupItems && model.selectedGroupItem) {
			this.groupItems.next(model.groupItems.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			}));

			this.groupType.next(model.groupType);
			this.selectedGroupItem.next(model.selectedGroupItem);
			this.students.next(this.selectedGroupItem.value.students);
			this.selectedStudent.next(this.selectedGroupItem.value.selectedStudent);
			const subjectsCacheKey = model.selectedGroupItem.selectedStudent.studentId;
			this.subjectsCache[subjectsCacheKey] = model.subjects;
			this.setSubject(model.selectedSubjectId);
			const studentId = model.selectedGroupItem.selectedStudent.studentId;
			const groupItemId = model.selectedGroupItem.id;
			const subjectId = model.selectedSubjectId;
			this.changesCollector.applyChanges({studentID: studentId});
			this.changesCollector.applyChanges({subjectTabID: subjectId});

			if (this.groupType.value === 'Group') {
				this.changesCollector.applyChanges({groupID: groupItemId});
			} else {
				this.changesCollector.applyChanges({classID: groupItemId});
			}
			this.isInit.next(true);

			result = true;
		}
		return result;
	}

	public loadSubjects() {
		const subjectCacheKey = this.selectedStudent.value.studentId;

		if (!this.subjectsCache[subjectCacheKey]) {
			const requestData = new RequestSettingsSubjects();
			requestData.hierarchy = this.hierarchy;

			this.httpClient.ESGIApi.get<{subjects: ResponseSubject[]}>(SettingsFormApiHost, SettingsFormApiActions.subjects, requestData).subscribe(r => {
				this.subjectsCache[subjectCacheKey] = Subject.FromResponseArray(r.subjects);
				this.setSubject();
			});
		} else {
			this.setSubject();
		}
	}

	public makeResultModel(tests: TestRow[], model: Init): RequestResultsInit {
		if (tests.some(item => {
			return item.printAmount < 0 || item.printAmount > item.max;
		})) {
			return;
		}

		const studentIds = [];
		let allStudentsSelected = true;

		if (this.selectedStudent && this.selectedStudent.value.studentId) {
			studentIds.push(this.selectedStudent.value.studentId);
			allStudentsSelected = false;
		} else {
			this.selectedGroupItem.value.students.forEach((student) => {
				if (student.studentId !== 0) {
					studentIds.push(student.studentId);
				}
			});
		}

		const resultsModel = new RequestResultsInit();
		resultsModel.teacherID = model.user.userId;
		resultsModel.subject = {
			name: this.selectedSubject.value.name,
			subjectID: this.selectedSubject.value.id,
			subjectType: this.selectedSubject.value.subjectType,
			subjectLevel: this.selectedSubject.value.level,
		};
		resultsModel.includeTestNotes = this.includeQuestionNotesOption.value;
		resultsModel.includeSummaryNotes = this.includeSummaryNotesOption.value;
		resultsModel.includeLetter = this.includeMessageOption.value;
		resultsModel.includeGradeScale = this.includeGradeOption.value;
		resultsModel.printInColor = this.printInColorOption.value;
		resultsModel.showSessionDate = this.showSessionDateOption.value;
		resultsModel.includeUntestedQuestions = this.includeUntestedQuestionsOption.value;
		resultsModel.tests = selectedTestsFilter(tests, this.includeSessionOption.value);
		resultsModel.studentIDs = studentIds;
		resultsModel.allStudentsSelected = allStudentsSelected;
		resultsModel.groupItemID = this.selectedGroupItem.value.id;
		resultsModel.groupItemLevel = this.groupType.value;
		resultsModel.testResultsCorrectVerbiage = this.testResultsCorrectVerbiage.value;
		resultsModel.testResultsIncorrectVerbiage = this.testResultsIncorrectVerbiage.value;
		resultsModel.hierarchy = this.hierarchy;
		return resultsModel;
	}

	public selectedEntityName() {
		return makeSelectedEntityName(this.selectedStudent.value, this.selectedGroupItem.value, this.teacher.value, this.groupType.value);
	}

	public optionChanged(event: ChangeEvent<HTMLInputElement>, id: string) {
		this.updateOption(id, event.target.checked);
	}

	public updateOption(optionName: string, optionValue: boolean) {
		const requestData = new Flag();
		requestData.name = optionName;
		requestData.value = optionValue;

		return this.httpClient.ESGIApi.post(
			ReportUserSettingsApi,
			ReportUserSettingsActions.update,
			requestData).subscribe();
	}

	public onStudentChanged(studentId) {
		this.changesCollector.applyChanges({
			studentID: studentId,
		});
	}

	private setSubject(selectedSubjectId?: number) {
		const subjects = this.subjectsCache[this.selectedStudent.value.studentId];

		if (!selectedSubjectId || selectedSubjectId === 0) {
			selectedSubjectId = this.selectedSubject.value.id;
		}

		let selectedSubject = subjects.find(s => s.id === selectedSubjectId);

		if (!selectedSubject) {
			selectedSubject = subjects[0];
		}
		this.subjects.next(subjects);
		this.selectedSubject.next(selectedSubject);
		this.hasGradeScales.next(selectedSubject.hasScales);
		if (!selectedSubjectId) {
			this.reloadTests();
		}
	}


	private selectedSubjectChanged(subject: Subject) {
		if (!subject) {
			return;
		}
		this.subjectChange(subject.id, SubjectType[subject.subjectType]);
	}

	private onClassChanged(classId: number) {
		this.changesCollector.applyChanges({
			classID: classId,
		});
	}

	private onGroupChanged(groupId: number) {
		this.changesCollector.applyChanges({
			groupID: groupId,
		});
	}

	private onSubjectChanged(s: {id: number, type: SubjectType}) {
		this.changesCollector.applyChanges({
			subjectTabID: s.id,
			subjectTabType: s.type,
		});
	}

	private studentChange(studentId: number) {
		this.onStudentChanged(studentId);
	}

	private subjectChange(subjectId: number, type: SubjectType) {
		this.onSubjectChanged({id: subjectId, type: type});
	}

	private groupItemChange(itemId: number) {
		switch (this.groupType.value) {
			case 'Class':
				this.onClassChanged(itemId);
				break;

			case 'SpecialistGroup':
			case 'Group':
				this.onGroupChanged(itemId);
				break;
			default:
				break;
		}
	}
}
