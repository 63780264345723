import {UserType} from '@esgi/core/authentication';
import {ServerClient} from 'pages/landing/kit/server-client';
import moment from 'moment';
import {Loader} from '@esgi/deprecated/jquery';
import {computed, observable, Button, Renderable, OldAlerts} from '@esgi/deprecated/knockout';
import {StripeUtil} from '../kit/stripe';
import {CookieReader, WindowTool} from '@esgi/deprecated/utils';
import {RenewalTemplate} from './html/renewal-template';
import {CartModel, ExpirationProductModel, OrderSummary} from './models';
import {
	CalculateRenewalCartPriceResponce, DownloadRenewRequest, isSameProduct,
	PayPalCaptureTransactionResponce,
	PayPalInitPaymentResponce, RenewalApi, RenewalType, RenewRequestBuilder, RenewResponce,
} from './server';
import {ActivationCodeStep, JoinToDistrictStep} from './steps/activation-code-step';
import {AddStudentsStep} from './steps/add-students-step';
import {AddTimeStep} from './steps/add-time-step';
import {CartStep} from './steps/cart-step';
import {MainStep} from './steps/main-step';
import {PromoCodeStep} from './steps/promo-code-step';
import {OrderConfirmationStep, OrderConfirmationStepProductPaymentModel} from './steps/purchase-complete-step';
import {PurchaseStep} from './steps/purchase-step';
import {Step} from './steps/steps';
import * as store from 'store';

import {FreeExtraStudentsStep} from './steps/free-students-step';
import {StudentLimitDecreasedStep} from './steps/student-limit-decreased-step';
import './renewal.less';

export enum PageType {
	Students = 'purchase_students',
	Expiration = 'renewexpiration',
	ActivationCode = 'activationcode',
	PayPalSuccess = 'paypalsuccess',
	PayPalCancel = 'paypalcancel',
}

export enum SourceScreen {
	Profile = 'profile',
	AddStudent = 'addstudent',
	Login = 'login'
}

export class Renewal extends Renderable {

	template = RenewalTemplate;

	cart = new CartModel();

	promoCode = ko.observable<string>();

	@observable()
	activationCode: string;

	@observable()
	currentStep: Step;

	@computed()
	get isAnyProductSelected() {
		return this.cart.expiration.products.some(x => x.selected());
	}

	@computed()
	get isNeedToPayForStudents() {
		return this.cart.student.newStudentLimit > this.cart.student.currentStudentLimit
			|| (this.orderSummary && this.orderSummary.Students.some(x => parseFloat(x.Price) > 0));
	}

	@computed()
	get isStudentsFree() {
		return this.cart.student.isAbleToPurchase && parseFloat(this.cart.studentsPrice) === 0
			&& !this.cart.expiration.products.some(x => x.isPurchasable() && x.selected());
	}

	products: ExpirationProductModel[] = [];
	userID: number;
	rateScheduleID?: number;
	userName: string;
	students: number;
	ratePerStudent: number;
	renewalType: RenewalType;
	userType: UserType;

	@observable()
	orderSummary: OrderSummary;

	allowUseAc: boolean;
	allowUseCc: boolean;
	allowUsePromoCode: boolean;

	token: string;
	continueButtonText: string;

	stripePaymentMethodId: string;

	showOrderSummary = ko.computed({
		read: () => {
			const isPurchaseStep = this.currentStep instanceof PurchaseStep;
			const hasSummary = this.orderSummary != null;
			return isPurchaseStep && hasSummary;
		},
		deferEvaluation: true,
	});

	showExit = ko.computed({
		read: () => {
			const isConfirmationStep = this.currentStep instanceof OrderConfirmationStep;
			return !isConfirmationStep;
		},
		deferEvaluation: true,
	});

	renewApi = new RenewalApi();
	server = this.renewApi.api;
	paymentLoader: Loader;

	load(): JQueryPromise<any> {
		let page = WindowTool.getUrlQueryParameter('page');

		const activationCode = WindowTool.getUrlQueryParameter('ActivationCode');

		if (activationCode) {
			this.activationCode = activationCode;
			page = PageType.ActivationCode;
		}
		store.remove('source_screen');
		const source = WindowTool.getUrlQueryParameter('source_screen');
		if (source != '') {
			store.set('source_screen', source);
		}

		store.remove('back_url');
		const url = WindowTool.getUrlQueryParameter('back_url');
		if (url != '') {
			store.set('back_url', url);
		}

		const uri = WindowTool.parseUri();
		WindowTool.clearUrlQueryParameters();

		this.paymentLoader = new Loader('body', {
			delay: 0,
			longLoadingText: 'We are processing your payment, please wait',
		});

		return this.init(page);
	}

	tryIdentityTrackingSession(userName, email, userID) {
		try {
			// @ts-ignore
			if (window.LogRocket) {
				// @ts-ignore
				window.LogRocket.identify(userName, {
					Email: email,
					UserID: userID,
					Session: 'Renewal',
				});
			}
		} catch (e) {

		}
	}

	init(page: string) {
		return this.server.init().done(r => {
			if (page != PageType.PayPalSuccess) {
				if (!r.allowUseAc && !r.allowUseCc) {
					const msg = 'Sorry. Your account is not permitted to make this purchase. Please contact Customer Support with error code ESGI-11499-CC/AC';
					OldAlerts.bsalert(msg, () => {
						$(this).trigger('exit');
					});
					return;
				}

				if (!r.allowUseCc) {
					page = PageType.ActivationCode;
				} else if (!r.allowUseAc) {
					page = PageType.Expiration;
				}
			}

			this.tryIdentityTrackingSession(r.userName, r.email, r.userID);

			this.renewalType = RenewalType.None;

			this.allowUseAc = r.allowUseAc;
			this.allowUseCc = r.allowUseCc;
			this.allowUsePromoCode = r.allowUsePc;
			this.userName = r.userName;
			this.userID = r.userID;
			this.userType = r.userType;
			this.rateScheduleID = r.rateScheduleID;
			this.ratePerStudent = r.students.ratePerYear;
			this.cart.student.currentStudentLimit = r.students.limit;
			this.cart.student.newStudentLimit = r.students.limit;
			this.cart.student.studentYearPrice = r.studentYearPrice;

			this.cart.expiration.esgiExpDaysAffectTimeDropdown = r.esgiExpireDaysThresholdApplyDiscount;
			this.products = r.products.map(p => ExpirationProductModel.FromResponse(p));
			this.students = r.students.limit;

			if (this.allowUsePromoCode) {
				this.promoCode(r.promoCode);
			}

			for (const p of r.products) {
				this.cart.expiration.addProduct(p);
			}

			let step;
			switch (page) {
				case PageType.Students:
					step = this.createAddStudentsStep();
					break;
				case PageType.Expiration:
					step = this.createAddTimeStep();
					break;
				case PageType.ActivationCode:
					step = this.createActivationCodeStep(this.activationCode);
					break;
				case PageType.PayPalSuccess:
					// do will be handled by PayPalSuccess Handler
					break;
				default:
					step = this.createMainStep();
					break;
			}

			this.currentStep = step;
		});
	}

	recalculateProductPrices = () => {
		const request = this.buildCalculateCartPriceRequest();

		this.server.renewalCartPrice(request)
			.always((r) => {
				const responce = r as CalculateRenewalCartPriceResponce;
				if (!responce.products) {
					return;
				}

				const cartQuery = this.cart.expiration.products;

				responce.products.forEach(p => {
					const cartProduct = cartQuery.find(pr => isSameProduct(pr.name, p.product));

					if (cartProduct.selected() == false) {
						cartProduct.price = 0;
						cartProduct.newExpirationDate(cartProduct.expirationDate);
					} else {
						cartProduct.price = p.price;
					}
				});
			});
	};

	createMainStep() {
		const main = new MainStep(this.cart.productsMaxExpirationDate, this.students, this.renewApi, this.userType);
		main.products = this.products;
		main.acEnabled = this.allowUseAc;
		main.allowUsePromoCode = this.allowUsePromoCode;

		if (this.promoCode) {
			main.promoCode.value(this.promoCode());
			main.promoCode.validate();
		}

		main.events.addStudentsClicked(() => {
			this.promoCode(main.promoCode.value());
			this.currentStep = this.createAddStudentsStep();
		});
		main.events.addTimeClicked(() => {
			this.promoCode(main.promoCode.value());
			this.currentStep = this.createAddTimeStep();
		});
		main.events.activationCodeClicked(() => {
			this.promoCode(main.promoCode.value());
			this.currentStep = this.createActivationCodeStep();
		});

		main.events.modifyPromoCodeClicked(() => {
			this.currentStep = this.createPromoCodeStep(() => main.promoCode.valid());
		});

		this.recalculateProductPrices();

		return main;
	}

	createPromoCodeStep(isPromoCodeValid: () => boolean) {

		const promoCodeStep = new PromoCodeStep(this.promoCode(), this.renewApi);
		promoCodeStep.events.cancelClicked(() => {
			this.currentStep = this.createMainStep();
		});
		promoCodeStep.events.acFlowClicked((e) => {
			this.currentStep = this.createActivationCodeStep(e);
		});
		promoCodeStep.events.submitClicked((e, b) => {

			const async = $.Deferred();

			const m = promoCodeStep.promoCodeForm.serialize();
			const orig = this.promoCode();

			if (!orig && !m) {
				this.currentStep = this.createMainStep();

				async.resolve();
				return async;
			}

			promoCodeStep.validate().done(valid => {
				if (valid) {


					return this.server.changePromoCode({promoCode: m.promoCode})
						.done((r) => {
							if (r.success) {
								promoCodeStep.submitButton.disabled(true);
								promoCodeStep.submitButton.title('Success!');
								setTimeout(() => location.reload(), 500);
							} else {
								promoCodeStep.submitButton.title('Try again');
								promoCodeStep.submitButton.disabled(false);
							}
						})
						.always(() => async.resolve());
				}
			}).fail(() => async.resolve());

			return async;
		});

		promoCodeStep.load();

		return promoCodeStep;
	}


	createActivationCodeStep(initValue?: string) {
		this.renewalType = RenewalType.ActivationCode;
		const actCode = new ActivationCodeStep(initValue);
		actCode.events.cancelClicked(() => {
			this.currentStep = this.createMainStep();
		});

		actCode.backEnabled = this.allowUseCc;
		actCode.events.nextClicked((e, b) => {

			const async = $.Deferred();

			actCode.validate().done(valid => {
				if (valid) {
					const m = actCode.serialize();

					this.cart.activationCode = {
						code: m.activationCode,
						districtID: m.joinToDistrictId,
						schoolID: null,
						schoolType: 'Federal',
						movePrevStudents: false,
						currentDistrictID: m.currentDistrictId,
						agreement: m.agreement,
						moveStudents: false,
						trackID: null,
						moveIsForbidden: false,
					};

					if (m.joinToDistrictId && (m.currentDistrictId === m.joinToDistrictId || m.agreement == 'T')) {
						async.resolve();
						this.currentStep = this.createJoinToDistrictStep(m.joinToDistrictId);
					} else {
						return this.useActivationCode(b).always(() => async.resolve());
					}
				} else {
					async.resolve();
				}
			}).fail(() => async.resolve());

			return async;
		});

		actCode.load();

		return actCode;
	}

	createAddTimeStep() {
		const addTime = new AddTimeStep(this.cart.expiration);

		addTime.events.cancel(() => {
			this.currentStep = this.createMainStep();
		});
		addTime.events.next(() => {
			this.currentStep = this.createCartStep();
		});
		addTime.events.addTimeCartDataChanged(() => {
			this.recalculateProductPrices();
		});

		return addTime;
	}

	createAddStudentsStep() {
		const addStudents = new AddStudentsStep(this.cart.student, this.ratePerStudent, this.cart.productsMaxExpirationDate, this.cart.productsMaxCurrExpirationDate);
		addStudents.events.cancel(() => {
			this.currentStep = this.createMainStep();
		});
		addStudents.events.continue(() => {
			this.currentStep = this.createCartStep();
		});
		return addStudents;
	}

	createFreeExtraStudentsStep() {
		const freeExtraStudents = new FreeExtraStudentsStep(this.cart);

		freeExtraStudents.events.cancel(() => {
			this.currentStep = this.createCartStep();
		});
		freeExtraStudents.events.continue(() => {
			return this.performAddStudents()
				.then(() => {
					$(this).trigger('addStudentsCompleted');
				});
		});

		return freeExtraStudents;
	}

	createStudentLimitDecreasedStep() {
		const studentLimitDecreasedStep = new StudentLimitDecreasedStep(this.cart);

		studentLimitDecreasedStep.events.cancel(() => {
			this.currentStep = this.createCartStep();
		});

		return studentLimitDecreasedStep;
	}

	createCartStep() {
		const step = new CartStep(this.cart);
		step.events.addStudents(() => {
			this.currentStep = this.createAddStudentsStep();
		});
		step.events.editStudents(() => {
			this.currentStep = this.createAddStudentsStep();
		});
		step.events.addTime(() => {
			this.currentStep = this.createAddTimeStep();
		});
		step.events.editExpiration(() => {
			this.currentStep = this.createAddTimeStep();
		});
		step.events.checkOutClicked(() => {
			const product = this.cart.expiration.products[0];
			const productNotRenewed = product.expirationDate.isSame(product.newExpirationDate());
			if (this.cart.student.studentLimitDecreased && productNotRenewed) {
				this.currentStep = this.createStudentLimitDecreasedStep();
			} else if (this.isStudentsFree){
				this.currentStep = this.createFreeExtraStudentsStep();
			} else {
				return this.createPurchaseStep().done(s => {
					this.currentStep = s;
				});
			}
		});
		step.events.cancelClicked(() => {
			//todo
		});
		return step;
	}

	createPurchaseStep() {
		let isPaypalInited = false;

		const deferred = $.Deferred<PurchaseStep>();
		this.renewalType = RenewalType.CreditCard;

		const updateCartPrice = () =>
			this.server.renewalCartPrice(this.buildCalculateCartPriceRequest())
				.always((r) => {
					const responce = r as CalculateRenewalCartPriceResponce;
					if (!this.orderSummary) {
						this.orderSummary = new OrderSummary(responce);
					} else {
						this.orderSummary.Update(responce);
					}
				});

		const task1 = updateCartPrice();
		const task2 = this.server.renewAllowedValidation().done((r) => {
			if (!r.isAllowedToRenew) {
				OldAlerts.bsalert(r.reason);
			}
		});

		const promocode = this.promoCode();
		const purchase = new PurchaseStep(this.renewApi);
		purchase.purchaseForm.allowUsePromoCode = this.allowUsePromoCode && promocode && promocode.length > 0;

		purchase.events.purchaseClicked(() => {
			purchase.validate().then((valid) => {
				if (!valid) {
					return;
				}

				purchase.purchaseButton.disabled(true);
				purchase.purchaseButton.title('Purchasing...');
				purchase.purchaseButton.icon('fa fa-circle-o-notch fa-spin');

				const thisComponent = this;
				purchase.purchaseForm.stripeUtil.GetAddressElement().getValue()
					.then(function(result) {
						if (result.complete) {
							purchase.purchaseForm.stripeUtil.StripeObject.createPaymentMethod({
								type: StripeUtil.ElementNameCard,
								card: purchase.purchaseForm.stripeUtil.GetCardElement(),
								// eslint-disable-next-line camelcase
								billing_details: result.value,
							}).then(function (result) {
								if (result.error) {
									OldAlerts.bsalert(`Credit card declined. The response from payment system was:<em>${result.error.message}</em>Please check your payment information and try again.`);
									purchase.purchaseButton.title('Try Again');
									purchase.purchaseButton.icon('');
									purchase.purchaseButton.disabled(false);
								} else {
									thisComponent.cart.creditCard = purchase.serialize();
									thisComponent.stripePaymentMethodId = result.paymentMethod.id;
									return thisComponent.finish().then((r) => {
										if (r.error){
											purchase.purchaseButton.title('Try Again');
											OldAlerts.bsalert(r.error);
										} else {
											purchase.view.purchaseSuccess(r);
										}
									}).fail(() => {
										purchase.purchaseButton.disabled(false);
										purchase.purchaseButton.title('Try Again');
									}).always(() => {
										purchase.purchaseButton.disabled(false);
										purchase.purchaseButton.icon('');
									});
								}
							}, (resp) => {
							});
						}
					});
			});

		});

		purchase.purchaseForm.events.paypalButtonInit((containerSelector: string) => {
			// Fix ESGI-30257. Check Task for more info.
			if (isPaypalInited) {
				return;
			}

			isPaypalInited = true;

			const request = RenewRequestBuilder.GetRenewRequest(this, this.cart.creditCard, '');
			this.server.paypal.initPayment(request).then((result: PayPalInitPaymentResponce) => {
				const script = document.createElement('script');
				script.src = 'https://www.paypal.com/sdk/js?client-id=' + result.clientId;
				script.async = true;
				document.body.appendChild(script);
				const that = this;
				script.onload = () => {
					const paypal = (window as any).paypal;
					paypal.Buttons({
						fundingSource: paypal.FUNDING.PAYPAL,
						createOrder: (data, actions) => {
							return actions.order.create({
								// eslint-disable-next-line camelcase
								purchase_units: [{
									amount: {
										value: result.orderInfo.price,
									},
								}],
							});
						},
						onApprove: (data, actions) => {
							return actions.order.capture().then(function (details) {
								that.paymentLoader.mask();

								ServerClient.SSOAPI.Post<PayPalCaptureTransactionResponce>('Renewal/PayPal', 'CaptureTransaction',
									{
										data: {
											...result.orderInfo,
											orderId: details.id,
										},
									}).then((result) => {
									if (result.renewalModel.token) {
										CookieReader.write('esgi-token', result.renewalModel.token);
									}

									that.init(PageType.PayPalSuccess).done(() => {
										that.currentStep = that.createOrderConfirmationStep(result.renewalModel);
									});
								}).always(() => {
									that.paymentLoader.unmask();
								});
							});
						},
					}).render(containerSelector);

				};
			});
		});

		const task3 = purchase.load().always(() => {
			purchase.purchaseForm.promoCode.value(this.promoCode());

			purchase.events.backClicked(() => {
				this.promoCode(purchase.purchaseForm.promoCode.value());
				const step = this.createCartStep();
				this.orderSummary = null;
				this.currentStep = step;
			});

			purchase.events.purchaseSuccess((e, data) => {
				this.currentStep = this.createOrderConfirmationStep(data);
			});
		});

		const tasks = [task1, task2, task3];
		$.whenAll<any>(tasks).always(() => deferred.resolve(purchase));

		return deferred;
	}

	createJoinToDistrictStep(districtId: number) {
		const step = new JoinToDistrictStep(districtId);
		step.events.nextClicked((e, b) => {

			return step.validate().done(valid => {
				if (valid) {
					const model = step.serialize();

					this.cart.activationCode.schoolID = model.schoolId;
					this.cart.activationCode.schoolType = model.schoolType == 'NonFederal' ? 'NonFederal' : 'Federal';
					this.cart.activationCode.districtID = model.districtId;
					this.cart.activationCode.trackID = model.trackId;
					this.cart.activationCode.moveStudents = model.moveStudents;
					this.cart.activationCode.movePrevStudents = model.movePrevStudents;

					return this.useActivationCode(b);
				}
			});
		});

		step.load();

		return step;
	}

	buildCalculateCartPriceRequest() {
		return RenewRequestBuilder.GetCalculateRenewalCartPriceRequest(this);
	}

	useActivationCode(b: Button) {
		const async = $.Deferred();

		const title = b.title();

		b.disabled(true);
		b.title('Activating...');

		// if user is in D district and tries to join to another D district, it should be forbidden
		const joinToDistrictForbidden = this.cart.activationCode.districtID && this.cart.activationCode.agreement === 'D' && this.cart.activationCode.currentDistrictID != this.cart.activationCode.districtID;
		if (joinToDistrictForbidden) {
			this.cart.activationCode.moveIsForbidden = true;
			this.cart.activationCode.districtID = null;
			this.cart.activationCode.moveStudents = false;
			this.cart.activationCode.movePrevStudents = false;
			this.cart.activationCode.schoolID = null;
			this.cart.activationCode.schoolType = null;
			this.cart.activationCode.trackID = null;
		}

		this.performRenew().done((r) => {
			b.title('Activated!');
			b.icon('fa fa-check');

			let message = 'Your account has been renewed';

			if (r.acExpirationDate) {
				message = 'Payment Successful! New ESGI expiration date is ' + moment(r.acExpirationDate).format('MM/DD/YY');
			} else {
				const esgi = r.payments.filter(p => p.title === 'ESGI')[0];
				if (esgi) {
					message = `The Activation Code has been applied successfully and your account has been extended for ${esgi.durationMonths} months.`;
				}
			}

			if (joinToDistrictForbidden) {
				message += ' However, the district in your account does not match the district linked to this code. Customer Support has been notified and will contact you soon to resolve the issue. <br />' +
					'Feel free to let us know if you have any questions by clicking Contact Us or emailing us at support@esgisoftware.com';

				OldAlerts.bsconfirm({
					message: message,
					modal: {
						buttons: [
							{
								title: 'Back to Home',
								className: 'btn btn-primary btn-sm',
								style: 'min-width: 60px',
								onClick: () => {
									location.href = '/login/byrenew?esgi-token=' + r.token;
								},
								closeModal: true,
								ok: true,
							},
							{
								title: 'Contact Us',
								closeModal: true,
								className: 'btn btn-sm',
								style: 'min-width: 65px',
								onClick: () => {
									window.open('/esgi/api/Zendesk/SendMessage?windowHelper', 'contactUsPopup', 'width=700,height=900,scrollbars=yes');
									location.href = '/login/byrenew?esgi-token=' + r.token;
								},
								cancel: true,
							},
						],
					},
				}, () => {
				});

				return;

			}

			OldAlerts.bsalert(message, () => {
				location.href = '/login/byrenew?esgi-token=' + r.token;
			});
		}).fail(() => {
			b.disabled(false);
			b.icon('');
			b.title(title);
		}).always(() => {
			async.resolve();
		});

		return async;
	}

	performAddStudents(){
		const request = RenewRequestBuilder.GetAddStudentsRequest(this.cart.student.newStudentLimit);
		return this.server.addStudents(request);
	}

	performRenew() {
		const request = RenewRequestBuilder.GetRenewRequest(this, this.cart.creditCard, this.stripePaymentMethodId);
		return this.server.renew(request);
	}

	createOrderConfirmationStep(resp: RenewResponce) {
		const confirmation = new OrderConfirmationStep();
		this.token = resp.token;

		if (resp.payments) {
			const p = resp.payments
				.filter(p => p.title != 'Students')
				.map(s => new OrderConfirmationStepProductPaymentModel(s));
			confirmation.Payments = p;
		}

		confirmation.continueText = this.continueButtonText;

		confirmation.orderId = resp.paymentInfoID;
		confirmation.transactionId = resp.transactionID;
		confirmation.paymentID = resp.paymentID;
		confirmation.newStudentLimit = resp.newMaxStudentsLimit;

		confirmation.events.downloadReceiptClicked(() => {
			const r = new DownloadRenewRequest();
			r.paymentID = confirmation.paymentID;
			this.server.downloadRenewPdf(r);
		});

		confirmation.events.continueClicked(() => {
			$(this).trigger('renewalCompleted');
		});

		return confirmation;
	}

	finish() {
		return this.performRenew();
	}

	events = {
		renewalCompleted: (callback) => {
			$(this).on('renewalCompleted', callback);
		},
		addStudentsCompleted: (callback) => {
			$(this).on('addStudentsCompleted', callback);
		},
		exit: (callback) => {
			$(this).on('exit', callback);
		},
	};

	view = {
		exitClicked: () => {
			if (this.currentStep instanceof MainStep) {
				$(this).trigger('exit');
			} else {
				OldAlerts.bsconfirm({
					className: 'confirm-modal',
					message: 'Are you sure you want to exit?',
					modal: {
						buttons: [
							{
								title: 'CANCEL',
								className: 'btn btn-close',
								closeModal: true,
							},
							{
								title: 'OK',
								className: 'btn btn-primary',
								closeModal: true,
							},
						],
					},
				}, (result) => {
					if (result) {
						$(this).trigger('exit');
					}
				});
			}
		},
	};

}


export class EsgiComponent extends Renewal {
	constructor() {
		super();
		this.continueButtonText = 'Continue to ESGI';

		this.events.renewalCompleted(() => {
			let url = '/login/byrenew?esgi-token=' + this.token;

			const backUrl = store.get('back_url');
			if (backUrl) {
				url = url + '&back_url=' + backUrl;
			}

			const sourceScreen = store.get('source_screen');
			if (sourceScreen) {
				url = url + '&source_screen=' + sourceScreen;
			}

			window.location.href = url;
		});

		this.events.addStudentsCompleted(() => {
			window.location.href = '/esgi';
		});

		this.events.exit(() => {
			let redirectLink;
			switch (store.get('source_screen')) {
				case SourceScreen.Login:
					redirectLink = '/';
					break;
				default:
					redirectLink = '/esgi';
					break;
			}
			window.location.href = redirectLink;
		});
	}
}
