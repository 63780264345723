import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const MainText = styled(Text, {
	justifySelf: 'end',
});

export const TranscriptionText = styled(Text, {
	justifySelf: 'end',
});
