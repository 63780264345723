import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './close-button.module.less';
import {join} from '@esgillc/ui-kit/utils';


interface CloseButtonProps {
	closed: () => any;
	disabled?: boolean;
	className?: string;
}

export default class CloseButton extends React.Component<CloseButtonProps> {
	render() {
		return (
			<OnHoverTooltip message='Close'>
				<button disabled={this.props.disabled ?? false} type='button'
				        className={join(styles.closeButton, this.props.className)} onClick={this.props.closed}>
					<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle cx='15' cy='15' r='14' stroke='#828282' strokeWidth='2'/>
						<path
							d='M21.5999 9.72942L20.2705 8.39999L14.9999 13.6706L9.72933 8.39999L8.3999 9.72942L13.6705 15L8.3999 20.2706L9.72933 21.6L14.9999 16.3294L20.2705 21.6L21.5999 20.2706L16.3293 15L21.5999 9.72942Z'
							fill='#828282'/>
					</svg>
				</button>
			</OnHoverTooltip>
		);
	}
}
