import {useState, useEffect, useMemo, useCallback} from 'react';
import {useValue, ChangeFunction} from '@esgi/main/kits/activities';
import {Test, Question, TestState, Sort} from './types';

const sortOrder: Sort[] = [
	{field: 'name', direction: 'desc'},
	{field: 'name', direction: 'asc'},
	{field: 'default', direction: 'desc'},
];

export function useTestsControl(
	tests: Test[] | null,
	onChange: ChangeFunction<Question['questionID'][]>,
) {
	const [state, setState] = useState<TestState[]>([]);
	const [selected, setSelected] = useValue<Question['questionID'][]>([], onChange);
	const [sortIndex, setSortIndex] = useState(2);
	const sort = useMemo<Sort>(() => sortOrder[sortIndex], [sortIndex]);
	const isSelectedAll = useMemo(() => {
		if (!state.length) {
			return false;
		}
		return state
			.flatMap(({questions}) => questions)
			.every(({questionID: id}) => selected.includes(id));
	}, [selected, state]);
	const items = useMemo(() => {
		if (!state.length) {
			return [];
		}
		
		if (sort.field === 'name') {
			return state.sort((left, right) => {
				if (sort.direction === 'asc') {
					return left.name.localeCompare(right.name);
				}
				return right.name.localeCompare(left.name);
			});
		}
		return state;
	}, [sort, state]);

	useEffect(() => {
		setSelected([]);
		setState((currentState) =>
			(tests || []).map<TestState>((test) => {
				const current = currentState.find(({testID: id}) => id === test.testID);

				return {...test, isListOpen: current?.isListOpen ?? false};
			}),
		);
	}, [tests]);

	const onToggle = useCallback((isListOpen: boolean, testId: Test['testID']) => {
		setState((currentState) => {
			const copied = [...currentState];
			const copiedTest = copied.find(({testID: id}) => id === testId);

			if (copiedTest) {
				copiedTest.isListOpen = isListOpen;
			}

			return copied;
		});
	}, []);

	const onSelectAll = useCallback(() => {
		if (isSelectedAll) {
			setSelected([]);
		} else {
			const sessions = state.map(({testID: id}) => onSelectTestAll(id, [])).flat();
			setSelected(sessions);
		}
	}, [isSelectedAll, state]);

	const onSelectTestAll = useCallback((
		testId: Test['testID'],
		selectedSessionIds: Question['questionID'][],
	): Question['questionID'][] => {
		const {questions = []} = state.find(({testID: id}) => testId === id) || {};
		const sessionIds = questions.map(({questionID: id}) => id);
		const isSelected = sessionIds.length && sessionIds.every((id) => selectedSessionIds.includes(id));
		const notSelected = sessionIds.filter((id) => !selectedSessionIds.includes(id));
		return isSelected ? sessionIds : notSelected;
	}, [state]);

	const onSort = useCallback(() => {
		let next = sortIndex + 1;
		if (next > 2) {
			next = 0;
		}
		setSortIndex(next);
	}, [sortIndex]);

	const toggleQuestions = useCallback((questionIds: Question['questionID'][]) => {
		const questions = [...selected];
		questionIds.forEach((questionId) => {
			const index = questions.findIndex((id) => id === questionId);
			if (index !== -1) {
				questions.splice(index, 1);
			} else {
				questions.push(questionId);
			}
		});
		setSelected(questions);
	}, [selected]);

	return {
		items,
		selected,
		setSelected,
		toggleQuestions,
		sort,
		onSort,
		onToggle,
		onSelectAll,
		onSelectTestAll,
	};
}
