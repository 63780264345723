import {ReportErrorEvent} from '@esgi/core/react';
import {ContactUs} from '@esgi/features/support';
import {useCallback, useState} from 'react';
import {ErrorAlert} from './error-alert';

type Props = {
    error: ReportErrorEvent,
    next: VoidFunction,
}

export function ReportLayerHandler({error, ...props}: Props) {
	const [contactUsFormOpened, setContactUsFormOpened] = useState(false);
	const closeContactUs = useCallback(() => {
		setContactUsFormOpened(false);
		props.next();
	}, [props.next]);

	if (contactUsFormOpened) {
		return <ContactUs token={error?.token}
                          ticketSubmitted={closeContactUs}
                          onClose={closeContactUs}/>;
	}

	if (error) {
		return <ErrorAlert key={error.message} onClose={props.next} message={error.message} onContactUs={() => setContactUsFormOpened(true)}/>;
	}
	return <></>;
}