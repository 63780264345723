import {useMemo} from 'react';
import {Column, FixedColumnPosition} from '@esgi/deprecated/ui-kit/report/table/types';
import {StudentResult} from '../../../../../types/table-level-report-service-types';
import styles from '../styles/result-column.module.less';
import cellStyles from '../styles/cell.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TableLevelReportService} from '../../../../../services/table-level-report-service';

export function useResultColumn({
	service,
	rows,
}: {
	service: TableLevelReportService;
	rows: StudentResult[] | undefined;
}) {
	return useMemo<Column<StudentResult>>(() => {
		const topHeaderCell = {
			renderer: () => <div className={cellStyles.cell}>Results</div>,
		};

		const bottomHeaderCell = {
			renderer: () => {
				const resultsExists = rows?.some(({hasResult}) => hasResult);

				return (
					<div className={cellStyles.cell}>
						{resultsExists ? (
							<a href='#' onClick={() => service.openStudentLevelReport(0)}>
								View all
							</a>
						) : (
							<OnHoverTooltip message='This rubric does not have any results.'>
								<div className={styles.viewChartDisabled}>View all</div>
							</OnHoverTooltip>
						)}
					</div>
				);
			},
		};

		const cells = [
			{
				renderer: ({hasResult, studentID}: StudentResult) => {
					return (
						<div className={cellStyles.cell}>
							{hasResult ? (
								<a href='#' onClick={() => service.openStudentLevelReport(studentID)}>
									View chart
								</a>
							) : (
								<OnHoverTooltip message='This student does not have any results for this rubric.'>
									<div className={styles.viewChartDisabled}>View chart</div>
								</OnHoverTooltip>
							)}
						</div>
					);
				},
			},
		];

		const studentResultColumn: Column<StudentResult> = {
			fixedColumnPosition: FixedColumnPosition.Right,
			className: styles.resultsColumn,
			headers: [[topHeaderCell], [bottomHeaderCell]],
			cells,
		};

		return studentResultColumn;
	}, [service, rows]);
}
