import React from 'react';
import {UserType, userStorage} from '@esgi/core/authentication';
import {EventBusDispatcher} from '@esgillc/events';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, ITeacherBox} from '../../core/api';
import {HierarchyEvents} from '../../core/events';
import {BoxType} from '../../core/models';

export class Props {
    boxes: IBoxInfo[];
    teachers: ITeacherBox;
    selectedID: number;
    schoolID: number;
    teachersGroupID: number;
    onSelected: (id: number, callback: () => void) => void;
    onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class State {
}

export class Teacher extends React.Component<Props, State> {
	private currentUser = userStorage.get();

	get items(): ItemProps[] {
    let teachers = this.props.teachers.items;
    if(this.props.teachersGroupID){
      teachers = this.props.teachers.items.filter(s => s.groups.some(g => g === this.props.teachersGroupID));
    } else if(this.props.schoolID){
      teachers = this.props.teachers.items.filter(s => s.schoolID === this.props.schoolID);
    }
    return teachers.map(r => {
      return {
        title: `${r.firstName} ${r.lastName}`,
        id: r.userID,
      };
    });
	}



	render() {
		if (this.currentUser.userType <= UserType.T) {
			return null;
		}

		if (!this.props.schoolID && !this.props.teachersGroupID) {
			return null;
		}

		const options: BoxOptions = {
			canEdit: this.props.teachers.canEdit,
			canAdd: this.props.teachers.canCreate,
			canCreateTooltip: null,
			title: 'All Teachers',
			boxType: BoxType.ClassicTeacher,
			canDrag: false,
			tooltipPostfix: 'teacher',
		};

		return <Box
            selectedID={this.props.selectedID}
            items={this.items}
            empty={{message: 'You have 0 teachers'}}
            scheduledIDs={null}
            itemSelected={this.props.onSelected}
            onDragStart={null}
            onDragEnd={null}
            onAddClicked={this.publish.add}
            onEditClicked={this.publish.edit}
            options={options}
            open={this.props.boxes.filter(t => t.boxType === BoxType.ClassicTeacher && t.open).length > 0}
            onOpenCloseChanged={this.props.onOpenCloseChanged}
        />;
	}

    publish = {
    	add: () => {
    		const args: HierarchyEvents.Teacher.AddArgs = {
    		};
    		EventBusDispatcher.dispatch(HierarchyEvents.Teacher.Add, args);
    	},
    	edit: (id: number) => {
    		const args: HierarchyEvents.Teacher.EditArgs = {
    			id: id,
    		};
    		EventBusDispatcher.dispatch(HierarchyEvents.Teacher.Edit, args);
    	},
    }
}
