import {ServerClient} from 'pages/landing/kit/server-client';
import {observable, observableArray, Form, FormField, SelectFormField, Validators} from '@esgi/deprecated/knockout';
import {JoinToDistrictFormTemplate} from './template';

import './styles.less';

export class JoinToDistrictFormComponentModel {
	districtId: number;
	schoolId: number;
	trackId: number;
	moveStudents: boolean;
	movePrevStudents: boolean;
	sameDistrict: boolean;
	schoolType: string;
}

export class JoinToDistrictForm extends Form {

	district: FormField<string>;
	school: SelectFormField<InitSchoolModel>;
	track: SelectFormField<TrackModel>;
	sameDistrict: FormField<string>;
	moveStudents: FormField<string>;
	movePrevStudents: FormField<string>;

	@observable()
	schoolID?: number = null;

	@observable()
	currentDistrictName: string = '';

	@observable()
	currentSchoolName: string = '';

	@observable()
	joinType: string = '';

	@observable()
	title = '';

	@observableArray()
	classes: InitClassModel[] = [];

	constructor(public districtId: number) {
		super();

		this.school = this.createSchool();
		this.district = this.createDistrict();
		this.track = this.createTrack();
		this.sameDistrict = this.createField<string>('true', Validators.Required());
		this.moveStudents = this.createField<string>('false', Validators.Required());
		this.movePrevStudents = this.createField<string>('false', Validators.Required());
	}

	template = JoinToDistrictFormTemplate;

	createDistrict() {
		let f = this.createField<string>('', Validators.Required());
		f.disabled(true);
		return f;
	}

	createTrack() {
		let f = new SelectFormField(null);
		this.addElement(f, Validators.Required());
		return f;
	}

	createSchool() {
		let f = new SelectFormField<InitSchoolModel>();
		this.addElement(f, Validators.Required());
		return f;
	}

	serialize() {
		const m = new JoinToDistrictFormComponentModel();
		m.districtId = this.districtId;
		m.schoolId = this.school.value.with(s => s.type == 'NonFederal' ? s.schoolID : s.federalSchoolID);
		m.sameDistrict = this.sameDistrict.value() == 'true';
		m.moveStudents = m.sameDistrict || this.moveStudents.value() == 'true';
		m.movePrevStudents = m.sameDistrict || (this.moveStudents.value() == 'true' && this.movePrevStudents.value() == 'true');

		m.schoolType = this.school.value.with(s => s.type);
		m.trackId = this.track.value.with(t => t.markingPeriodID);

		return m;
	}


	load(): JQueryPromise<any> {

		return ServerClient.SSOAPI.Get<InitModel>('Renewal', 'InitJoinToDistrict', {data: {districtId: this.districtId}})
			.done((model) => {
				this.district.value(model.districtName);
				this.school.source = model.availableSchools;
				this.track.source = model.tracks;
				this.joinType = model.joinType;
				this.currentSchoolName = model.currentSchoolName;
				this.currentDistrictName = model.currentDistrictName;
				this.classes = model.classes;
				this.schoolID = model.currentSchoolID;

				const selectedSchool = model.availableSchools.filter(s => s.schoolID == model.currentSchoolID)[0];
				if (selectedSchool) {
					this.school.value(selectedSchool);
				} else {
					this.school.value(this.school.source[0]);
				}

				this.track.value(this.track.source[0]);

			});
	}
}

class InitModel {
	districtName: string;
	districtID: number;
	currentSchoolID: number;
	currentSchoolName: string;
	currentDistrictName: string;
	joinType: string;
	availableSchools: InitSchoolModel[];
	tracks: TrackModel[];
	classes: InitClassModel[];
}

interface TrackModel {
	markingPeriodID: number;
	name: string;
}

interface InitSchoolModel {
	schoolID: number;
	name: string;
	type: string;
	federalSchoolID: number;
}

interface InitClassModel {
	name: string;
	students: string[];
	studentCount: number;
	totalCount: number;
}
