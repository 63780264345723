import {useState, useEffect} from 'react';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {GrowthReportService} from 'modules/reports/growth-report/services/growth-report-service';
import styles from './sort-row.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

interface SortRowProps {
	growthReportService: GrowthReportService;
}

export default function SortRow(props: SortRowProps) {
	const sortModel = useBehaviorSubject(props.growthReportService.sortModel);
	const showNumberOfStudents = useBehaviorSubject(props.growthReportService.showNumberOfStudents);
	const tests = useBehaviorSubject(props.growthReportService.tests);
	const numberPeriods = useBehaviorSubject(props.growthReportService.numberPeriods);
	const showMarkingPeriods = useBehaviorSubject(props.growthReportService.showMarkingPeriods);
	const isPreAssessMode = props.growthReportService.isPreAssessMode;
	const [sortField, setSortField] = useState<string>('schoolName');
	const [direction, setDirection] = useState<string>('Asc');

	useEffect(() => {
		if (sortModel) {
			setSortField(sortModel.fieldName);
			setDirection(sortModel.direction);
		}
	}, [sortModel])

	const sortClicked = (name: string) => {
		props.growthReportService.sortClicked({fieldName: name, direction});
	}

	const getDirection = (name: string) => {
		if (sortField === name) {
			switch(direction) {
				case 'Asc':
					return 'fa fa-arrow-down fa-fw';
				case 'Desc':
					return 'fa fa-arrow-up fa-fw';
			}
		}
		return 'fa fa-fw fa-arrow-down fa-lightgray';
	}

	const isSortActive = (name: string) => {
		return sortField !== name ? 'show-icon-hover' : '';
	}

	return (
		<tr>
			{!isPreAssessMode &&
				<th className={join(styles.sortCell, styles.leftAlign, styles.biggerText, isSortActive('schoolName'))}
						onClick={() => sortClicked('schoolName')}>
					<span>
                        <span>School</span>
                        <i className={getDirection('schoolName')} aria-hidden='true'/>
					</span>
				</th>
			}
			<th className={join(styles.sortCell, styles.leftAlign, styles.biggerText, isSortActive('fullName'))}
			    onClick={() => sortClicked('fullName')}>
					<span>
                        <span>Teacher</span>
                        <i className={getDirection('fullName')} aria-hidden='true'/>
					</span>
			</th>
			{
				showNumberOfStudents && <th className={join(styles.sortCell, styles.biggerText, styles.numberOfStudentsCell)}
				                            onClick={() => sortClicked('numberOfStudents')}>
					<span>
                        <span>Students</span>
                        <i className={getDirection('numberOfStudents')} aria-hidden='true'/>
					</span>
				</th>
			}
			<th className={join(styles.sortCell, styles.biggerText, styles.highlighterCell, styles.avgCell, isSortActive('averageOfGrowthScores'))}
			    onClick={() => sortClicked('averageOfGrowthScores')}>
				<OnHoverTooltip message={<div>
					<span className={styles.textAlignJustify}>Average growth across the entire subject tab for students tested at least twice on a test (students tested twice coverage percent)</span>
				</div>}>
							<span>
                        <span>Average Growth</span>
                        <i className={getDirection('averageOfGrowthScores')} aria-hidden='true'/>
					</span>
				</OnHoverTooltip>
			</th>
			{
				tests?.map((test, index) => <>
					{
						showNumberOfStudents && <th key={`sort-cell-show-students-${index}`}
						                            className={join(styles.sortCell, styles.leftDarkerBorder, styles.highlighterCell, isSortActive(`results.${index}.numberOfTestedStudents`))}
						                            onClick={() => sortClicked(`results.${index}.numberOfTestedStudents`)}>
							<OnHoverTooltip message={<div>
								<span className={styles.textAlignJustify}>Number of students who have at least two test sessions</span>
							</div>}>
								<span>
			                        <span>S2</span>
			                        <i className={getDirection(`results.${index}.numberOfTestedStudents`)} aria-hidden='true'/>
								</span>
							</OnHoverTooltip>
						</th>
					}
					<th key={`sort-cell-first-percent-${index}`}
					    className={join(styles.sortCell, styles.grayCell, !showNumberOfStudents && styles.leftDarkerBorder, isSortActive(`results.${index}.firstPercent`))}
					    onClick={() => sortClicked(`results.${index}.firstPercent`)}>
							<span>
		                        <span>B</span>
		                        <i className={getDirection(`results.${index}.firstPercent`)} aria-hidden='true'/>
							</span>
					</th>
					{
						showMarkingPeriods && numberPeriods.map((period: (number | null), periodIndex) => <>
							{
								<th key={`sort-cell-period-percent-${index}-${periodIndex}`}
								                       className={join(styles.sortCell, isSortActive(`results.${index}.${periodIndex}`))}
								                       onClick={() => sortClicked(`results.${index}.${periodIndex}`)}>
									<span>
				                        <span>{periodIndex + 1}</span>
				                        <i className={getDirection(`results.${index}.${periodIndex}`)} aria-hidden='true'/>
									</span>
								</th>
							}
						</>)
					}
					<th key={`sort-cell-growth-percent-${index}`}
					    className={join(styles.sortCell, styles.grayCell, styles.growthCell, isSortActive(`results.${index}.growthPercent`))}
					    onClick={() => sortClicked(`results.${index}.growthPercent`)}>
							<span>
		                        <span>Growth</span>
		                        <i className={getDirection(`results.${index}.growthPercent`)} aria-hidden='true'/>
							</span>
					</th>
				</>)
			}
		</tr>
	)
}
