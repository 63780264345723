import {HideButton, StarButton} from 'modules/assets/tests/kits/test-details';
import moment from 'moment';
import React from 'react';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {ContentAreaIcon} from '../../../icons/content-area';
import {GradeLevelIcon} from '../../../icons/grade-level';

interface Props {
	name: string;
	hidden: boolean;
	starred: boolean;
	createDate: string;
	school: boolean;
	district: boolean;
	contentArea: string;
	gradeLevels: number[];
	onStarClicked: () => void;
	onHideClicked: () => void;
	openTestDetails: () => void;
}

export default class Header extends React.PureComponent<Props> {
	public render() {
		return <header>
			<div className='title'>
				<div className='top-row'>
					<div className='star-switch'>
						<StarButton isStarred={this.props.starred} onClick={this.props.onStarClicked}/>
					</div>
					<div className='hide-switch'>
						<HideButton isHidden={this.props.hidden} onClick={this.props.onHideClicked}/>
					</div>
					<AnchorLikeButton className='name' onClick={() => this.props.openTestDetails()}>
						{this.props.name}
					</AnchorLikeButton>
				</div>
				<div>
					<div className='create-date'>
						{moment(this.props.createDate).format('MM-DD-YYYY')}
					</div>
				</div>
			</div>
			<div className='icons'>
				{this.props.school && <div className='school'>School</div>}
				{this.props.district && <div className='district'>District</div>}
				<ContentAreaIcon contentArea={this.props.contentArea}/>
				<GradeLevelIcon gradeLevels={this.props.gradeLevels}/>
			</div>
		</header>;
	}
}
