import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';

interface Props {
	onClicked: () => void;
	errors: string;
}

export function InvalidField({errors, onClicked}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Body className={styles.body}>
			<span data-cy={'invalid-field'} className={styles.text}>The file contains an invalid field.</span>
			<p className={styles.paragraph}>Errors:</p>
			<p data-cy={'errors'} className={styles.paragraph}>{errors}</p>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}