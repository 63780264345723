import {styled} from '@esgi/ui/theme';
import {SortTable} from '@esgi/ui';
import {SortDirection} from '../../../../service/types';

export const SortIndicator = styled(SortTable, {
	cursor: 'pointer',
	width: '12px',
	height: '12px',
	variants: {
		disabled: {
			true: {
				cursor: 'auto',
				'& path': {
					fill: '$muted',
				},
			},
		},
		direction: {
			[SortDirection.None]: {},
			[SortDirection.Asc]: {
				'& path:first-child': {
					fill: '$muted',
				},
				'& path:last-child': {
					fill: '$primary',
				},
			},
			[SortDirection.Desc]: {
				'& path:first-child': {
					fill: '$primary',
				},
				'& path': {
					fill: '$muted',
				},
			},
		},
	},
});