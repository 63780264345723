import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from 'root/route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const AdminHomePage = lazyComponent(() => import('pages/admin-home/root'));

export default function AdminHome() {
	return <RouteWrapper className='admin-home' authorize>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<AdminHomePage/>
		</Suspense>
	</RouteWrapper>;
}
