import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import React, {useEffect, useState} from 'react';
import {GroupForm} from 'modules/forms/group-form/components/form';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {useService} from '@esgi/core/service';
import GroupFormService from 'modules/forms/group-form/service';
import {RemoveDialog} from 'modules/forms/group-form/components/remove-dialog';
import {ServiceLoader} from '@esgillc/ui-kit/loader';

interface Props {
	onClose: () => void;
	groupID: number;
	teacherID: number;
	classID: number;
	groupName: string;
	className: string;
}

export default function GroupFormModal({groupID, teacherID, classID, groupName, className, onClose}: Props) {
	const modalManagerRef = useModal();
	const handleClose = useCloseModal(modalManagerRef, onClose);
	const service = useService(GroupFormService);

	const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
	const [isLoaded, setLoaded] = useState(false);

	useEffect(() => {
		service.init({teacherID, groupID, groupName, classID}).subscribe(()=>{
			setLoaded(true);
		});
	}, []);

	const handleRemove = () => {
		service.remove().subscribe(() => {
			setRemoveDialogOpen(false);
			handleClose();
		});
	};

	const handleSave = () => {
		service.form.validate().subscribe((result)=>{
			if(result.valid){
				service.save().subscribe(() => {
					handleClose();
				});
			}

		});
	};

	return <Modal modalManagerRef={modalManagerRef}>
		<ServiceLoader trackingService={service} fullscreen/>
		<RemoveDialog onClose={() => setRemoveDialogOpen(false)} isOpen={isRemoveDialogOpen} onApprove={handleRemove}/>
		<Modal.Header>
			<div className={styles.headerLinkBlock} data-cy='header-link-block'>
				<Title>{groupID ? 'Edit Group' : 'Add Group'}</Title>
				{!!groupID &&
					<div className={styles.removeLink} data-cy='header-remove-button'
					     onClick={() => setRemoveDialogOpen(true)}>
						Remove group
					</div>}
			</div>
		</Modal.Header>
		<Modal.Body>
			<GroupForm groupID={groupID}
			           className={className}
			           service={service}/>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Gray className={styles.cancelButton} onClick={handleClose}>Cancel</Buttons.Gray>
			<Buttons.Contained disabled={!isLoaded}
			                   onClick={handleSave}>Save</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
