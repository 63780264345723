import {testLabelByTestType} from '../../../../kit';
import {TestSectionTabId, TestedOption} from '../../types';
import {OptionItem} from '../select/types';
import {TabItem} from '../tabs/types';
import {TestType} from '@esgi/main/libs/core';

export const tabItems: TabItem<TestSectionTabId>[] = [
	{
		value: TestSectionTabId.All,
		label: 'All',
		labelDataCy: 'test-type-tab-item-all',
	},
	{
		value: TestSectionTabId.YesNo,
		label: testLabelByTestType[TestType.YN],
		labelDataCy: 'test-type-tab-item-yes-no',
	},
	{
		value: TestSectionTabId.Rubric,
		label: testLabelByTestType[TestType.Rubric],
		labelDataCy: 'test-type-tab-item-rubric',
	},
	{
		value: TestSectionTabId.SingleScore,
		label: testLabelByTestType[TestType.Score],
		labelDataCy: 'test-type-tab-item-single-score',
	},
];

export const testedOptionItems: OptionItem<TestedOption>[] = [
	{
		label: 'Any',
		value: TestedOption.Any,
		permitted: true,
		labelDataCy: 'tested-options-item-any',
	},
	{
		label: 'Yes',
		value: TestedOption.Yes,
		permitted: true,
		labelDataCy: 'tested-options-item-yes',
	},
	{
		label: 'No',
		value: TestedOption.No,
		permitted: true,
		labelDataCy: 'tested-options-item-no',
	},
];
