import {Textarea} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {TestInfo, TestSession} from '../../../../../types';
import {EditableContentContainer, ProgressBarContainer} from './index.styled';
import {AlertBody} from '../../../../components.styled';
import {TestName} from '../../../../test-name';
import {TestType} from '@esgi/main/libs/core';
import {AlertFooter} from '../../../../alert-footer';
import {EditableSessionTimeInfo} from '../../../../editable-session-time-info';
import {AlertHeader} from '../../../../alert-header';
import {useNoChangesConfirmationState} from '../../../hooks/use-no-changes-confirmation-state';
import {Input, ProgressWithPointer} from '../../../../../../kit';
import {useEditModeState} from './use-edit-mode-state';
import {Student} from '@esgi/main/libs/store';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = {
	canEdit: boolean;
	sessionInfo: TestSession;
	testInfo: TestInfo;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	onCloseAlert: VoidFunction;
	student: Student;
	onUpdateTestSession: (args: {score: number; notes: string; testDateTouched: boolean; testDate: string}) => void;
};

export function EditModeContent({
	canEdit,
	sessionInfo,
	testInfo,
	setIsEditMode,
	onCloseAlert,
	student,
	onUpdateTestSession,
}: Props) {
	const {
		isDataTouched,
		setSessionTimeInfoTouched,
		setIsSessionTimeInfoValid,
		dateTimeRef,
		scoreError,
		scoreValue,
		handleUpdateScoreValue,
		sessionNote,
		onUpdateSessionNote,
		isDataValid,
		handleSave,
	} = useEditModeState({sessionInfo, testInfo, onUpdateTestSession});

	const {handleCloseAlert, setViewMode, isOpenNoChangesConfirmation, onCloseNoChangesConfirmation, onCloseAnyway} =
		useNoChangesConfirmationState({isDataTouched, onCloseAlert, setIsEditMode});

	return (
		<>
			<AlertHeader
				canEdit={canEdit}
				isEditMode
				onCloseAlert={handleCloseAlert}
				onEditModeClick={setViewMode}
				student={student}
			/>
			<AlertBody>
				<TestName name={testInfo.name} testID={testInfo.id} type={TestType.Score} />

				<EditableContentContainer>
					<EditableSessionTimeInfo
						sessionInfo={sessionInfo}
						setTouched={setSessionTimeInfoTouched}
						setisValid={setIsSessionTimeInfoValid}
						editableSessionTimeRef={dateTimeRef}
						data-cy='date-time'
					/>

					<GridBox flow='column' gap='3' columns='3' align='center'>
						<ProgressBarContainer>
							<ProgressWithPointer
								value={(!scoreError ? Number(scoreValue) / testInfo.totalPossible : 0) * 100}
								percentageType
							/>
						</ProgressBarContainer>
						<Input
							placeholder='Score'
							value={scoreValue}
							onChange={handleUpdateScoreValue}
							error={scoreError}
							dataCy='score'
						/>
						<Input placeholder='Max score' value={testInfo.totalPossible} disabled dataCy='max-score' />
					</GridBox>

					<Textarea
						placeholder='Summary Note'
						value={sessionNote}
						onChange={onUpdateSessionNote}
						dataCy='summary-note'
					/>
				</EditableContentContainer>
			</AlertBody>

			<AlertFooter isEditMode isSaveDisabled={!isDataTouched || !isDataValid} onSave={handleSave} />

			{isOpenNoChangesConfirmation && (
				<UnsavedChangesConfirmation onCloseAnyway={() => onCloseAnyway?.()} onClose={onCloseNoChangesConfirmation} />
			)}
		</>
	);
}
