import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';

interface Props {
	onClicked: () => void;
	errors: string;
}

const message = 'Your file contains duplicate student IDs. All IDs must be unique. Please fix the IDs and try again.';

export function DuplicateIdInFile({onClicked, errors}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header/>
		<Modal.Body className={styles.body}>
			<span data-cy={'duplicate-id-in-file'} className={styles.text}>{message}</span>
			<p className={styles.paragraph}>Errors:</p>
			<p data-cy={'errors'} className={styles.paragraph}>{errors}</p>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}