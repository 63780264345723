import {ElementStatus, useFormElementContext} from '../../form';
import React, {ComponentProps, useCallback} from 'react';
import {DroppableTextEditorRoot} from '../../controls/droppable-text-editor/components/root';
import {DroppableTextBox} from '../../controls/droppable-text-editor/components/droppable-text-box';
import {DraggableTag} from '../../controls/droppable-text-editor/components/draggable-tag';

type Props = Omit<ComponentProps<typeof DroppableTextEditorRoot>, 'onChange' | 'value'>

function DroppableTextEditorRootWithForm({children, dataCy, ...props}: Props) {

	const {status, setValue, value} = useFormElementContext({
		string: true,
	}, DroppableTextEditorRootWithForm);

	const onChange = useCallback((value: string) => setValue(value), [setValue]);

	const commonProps = {
		error: status === ElementStatus.invalid,
		disabled: status === ElementStatus.disabled,
		value,
		onChange,
		dataCy,
		...props,
	};

	return <DroppableTextEditorRoot {...commonProps}>
		{children}
	</DroppableTextEditorRoot>;
}
export const DroppableTextEditor = Object.assign(DroppableTextEditorRootWithForm, {
	TextBox: DroppableTextBox,
	DraggableTag: DraggableTag,
});

