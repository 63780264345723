export const SettingsFormApiHost = 'reports/parent-letter';

export enum SettingsFormApiActions {
	settings = 'settings',
	settingsFetchHierarchy = 'settings-fetch-hierarchy',
	settingsFetchSessionHierarchy = 'settings-fetch-session-hierarchy',
	results = 'results',
	subjects = 'subjects',
	fetchSubjectSessionHierarchy = 'settings-fetch-subject-session-hierarchy'
}

export const ReportUserSettingsApi = 'reports/user-settings';

export enum ReportUserSettingsActions {
	update = 'update'
}

export enum SettingsFormAlerts {
	noValidDataAlert = 'The current selection has no valid results to display. Use the checkboxes on the left to' +
		' select tests that contain data for the report that you\'re trying to run.',
	noDataByDateAlert = 'The date selected has no valid results to display. Please select a new date that contains test data for the report you’re trying to run.'
}

export const ParentLetterTemplateApiHost = 'reports/parent-letter/template';

export const ParentLetterTemplateActions = {
	init: 'init',
	fetchLanguage: 'fetch-language',
};
