import {SelectableList} from '@esgi/ui/layout';
import {Student} from '@esgi/main/libs/store';
import {StudentFullName, SelectableEditableItem, getListValue} from '../../../../kit';
import {SelectableLisItemContent} from './lists.styled';

type Props = {
	selectedStudentId: Student['id'] | null;
	onStudentEdit: (studentId: Student['id']) => void;
	students: Student[];

};

export function SelectableListEditable({selectedStudentId, onStudentEdit, students}: Props) {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedStudentId)}>
				<SelectableList.Group>
					{students.map(({firstName, lastName, id}) => {
						const handleStudentClick = () => onStudentEdit(id);

						return (
							<SelectableEditableItem key={id} onItemClick={handleStudentClick} value={String(id)}>
								<SelectableLisItemContent>
									<StudentFullName firstName={firstName} lastName={lastName} />
								</SelectableLisItemContent>
							</SelectableEditableItem>
						);
					})}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
