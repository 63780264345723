import {UserType} from '@esgi/core/authentication';
import {SpecialistHierarchyLevel} from '../enums';
import {SelectedModel} from '../selected-model';
import {SpecialistHierarchyFilter} from '../specialist-hierarchy-filter';
import {SpecialistHierarchySnapshot} from '../snapshot/specialist-hierarchy-snapshot';

export class SpecialistHierarchyInstance {
	public districtID: number;
	public type: UserType;
	public userID: number;
	public groupID: number;
	public studentID: number;
  public groupOfSpecialistsID: number;
	public filter: SpecialistHierarchyFilter;

	public get selected(): SelectedModel<SpecialistHierarchyLevel> {
		if (this.studentID !== 0) {
			return {level: SpecialistHierarchyLevel.Student, levelID: this.studentID};
		}
    if (this.groupOfSpecialistsID !== 0) {
      return {level: SpecialistHierarchyLevel.GroupOfSpecialists, levelID: this.groupOfSpecialistsID};
    }
		if (this.groupID !== 0) {
			return {level: SpecialistHierarchyLevel.Group, levelID: this.groupID};
		}
		if (this.userID !== 0) {
			return {level: SpecialistHierarchyLevel.User, levelID: this.userID};
		}
		if (this.type !== UserType.V) {
			return {level: SpecialistHierarchyLevel.Type, levelID: this.type};
		}
		if (this.districtID !== 0) {
			return {level: SpecialistHierarchyLevel.District, levelID: this.districtID};
		}

		return {level: SpecialistHierarchyLevel.None, levelID: 0};
	}

	public static build(model: SpecialistHierarchySnapshot): SpecialistHierarchyInstance {
		const m = new SpecialistHierarchyInstance();
		m.districtID = model.districtID;
		m.type = model.type;
		m.userID = model.userID;
		m.groupID = model.groupID;
		m.studentID = model.studentID;
    m.groupOfSpecialistsID = model.groupOfSpecialistsID;
		m.filter = {
			teacherID: model.filter.teacherID,
			schoolID: model.filter.schoolID,
		};
		return m;
	}

	public copy(): SpecialistHierarchyInstance {
		const m = new SpecialistHierarchyInstance();
		m.districtID = this.districtID;
		m.type = this.type;
		m.userID = this.userID;
		m.groupID = this.groupID;
    m.groupOfSpecialistsID = this.groupOfSpecialistsID;
		m.studentID = this.studentID;
		m.filter = {
			schoolID: this.filter.schoolID,
			teacherID: this.filter.teacherID,
		};
		return m;
	}

	public equal(outer: SpecialistHierarchyInstance): boolean {
		if (this.districtID !== outer.districtID) {
			return false;
		}
		if (this.type !== outer.type) {
			return false;
		}
		if (this.userID !== outer.userID) {
			return false;
		}
		if (this.groupID !== outer.groupID) {
			return false;
		}
		if (this.studentID !== outer.studentID) {
			return false;
		}
		if (this.filter.schoolID !== outer.filter.schoolID) {
			return false;
		}
		if (this.filter.teacherID !== outer.filter.teacherID) {
			return false;
		}
    if (this.groupOfSpecialistsID !== outer.groupOfSpecialistsID) {
      return false;
    }

		return true;
	}

	public extract(): SpecialistHierarchyInstance {
		return {
			studentID: this.studentID,
			filter: {
				schoolID: this.filter.schoolID,
				teacherID: this.filter.teacherID,
			},
			type: this.type,
			groupID: this.groupID,
			userID: this.userID,
			districtID: this.districtID,
      groupOfSpecialistsID: this.groupOfSpecialistsID,
			selected: this.selected,
		} as SpecialistHierarchyInstance;
	}
}
