import React from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {ReportModalDownloadButton} from './report-modal-download-button';
import styles from './report-modal-download-button.module.less';

interface Props {
	name?: string;
	title?: string;
	onPDFClicked: () => void;
	onZipClicked: () => void;
}

export class ReportModalPDFZipDownloadButton extends React.PureComponent<Props> {
	get options() {
		return [
			() => <div className={styles.pdfZipMenuItem} onClick={() => this.props.onPDFClicked()}>
				<p className={join(styles.p, styles.pdfZipMenuItemTop)}>One PDF file</p>
				<p className={styles.p}>per student or class</p>
			</div>,
			() => <div className={styles.pdfZipMenuItem} onClick={() => this.props.onZipClicked()}>
				<p className={join(styles.p, styles.pdfZipMenuItemTop)}>ZIP</p>
				<p className={styles.p}>Individual PDF files for each student in the class</p>
			</div>,
		];
	}

	public render() {
		return <ReportModalDownloadButton {...this.props} options={this.options}/>;
	}
}
