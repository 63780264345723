import {PropsWithChildren, useCallback} from 'react';
import {HeaderCell as StyledHeaderCell, Text} from '../../index.styled';
import {SortBy, Sorting, SortDirection} from '../../../../service/types';
import {SortIndicator} from './index.styled';
import {FieldType} from '../../types';

type Props = {
	name: FieldType,
	sorting: Sorting,
	onClick: VoidFunction,
	disabled?: boolean,
} & PropsWithChildren;

export function HeaderCell(props: Props) {
	const {by, direction} = props.sorting;
	const active = by === SortBy[props.name] && !props.disabled;

	const onClick = useCallback(() => {
		if (!props.disabled) {
			props.onClick();
		}
	}, [props.disabled, props.onClick]);

	return (
		<StyledHeaderCell
			dataCy='header-cell'
			disabled={props.disabled}
		>
			{props.children}
			<Text
				size='small'
				color={active ? 'primary' : 'lowContrast'}
				disabled={props.disabled}
				onClick={onClick}
			>
				{props.name}
			</Text>
			<SortIndicator
				disabled={!active}
				direction={!active ? SortDirection.None : direction}
				onClick={onClick}
			/>
		</StyledHeaderCell>
	);
}
