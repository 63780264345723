import {FlexBox} from '@esgillc/ui-kit/layout';
import React from 'react';
import styles from './name-writing-settings-step.module.less';
import {NameFormat, NamingWritingPrintSettings, OptionModel, StudentStepState} from '../../shared/types';
import {BasePrintSettings} from '../../shared/components/base-print-settings/base-print-settings';
import SettingsItem from '../../shared/components/settings/settings-item';
import {Radio} from '@esgillc/ui-kit/control';
import {NameWritingTemplate} from '../../shared/templates/name-writing/name-writing-template';
import TemplatePreview from '../../shared/components/template-preview/template-preview';
import {Modal} from '@esgillc/ui-kit/modal';
import NavigationBar from '../../shared/components/navigation-bar/navigation-bar';
import {Buttons} from '@esgillc/ui-kit/button';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {NameWritingReport} from '../../shared/templates/name-writing/types';
import {BasicPrintSetting} from '../../shared/components/base-print-settings/types';
import {NameWritingPdfDownloadingService} from '../../shared/services/name-writing-pdf-downloading-service';
import {ReportGeneratorService} from '../../shared/templates/name-writing/report-generator-service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

interface Props {
	unitData: StudentStepState;
	currentStep: number;
	totalStepsCount: number;
	printData: NamingWritingPrintSettings;
	goToBack: (data: NamingWritingPrintSettings) => void;
	goToPreview: (data: NamingWritingPrintSettings, report: NameWritingReport) => void;
	pdfDownloaded: (report: NameWritingReport) => void;
}

class State {
	report: NameWritingReport;
	printData: NamingWritingPrintSettings;

	constructor(data: NamingWritingPrintSettings) {
		this.printData = data;
	}
}

export default class NameWritingSettingsStep extends React.PureComponent<Props, State> {
	private readonly fontSizeOptions: OptionModel<string>[] = [
		new OptionModel('30pt', '30 pts'),
		new OptionModel('40pt', '40 pts'),
		new OptionModel('62pt', '62 pts'),
	];
	private readonly downloadPdfService: NameWritingPdfDownloadingService;
	private readonly reportGenerator: ReportGeneratorService;
	private readonly destroy$: Subject<void> = new Subject();

	constructor(props) {
		super(props);
		const normalizedPrintData = this.props.printData == null
			? new NamingWritingPrintSettings(NameFormat.FirstName, this.fontSizeOptions[0], 1)
			: this.props.printData;
		this.state = new State(normalizedPrintData);
		this.reportGenerator = new ReportGeneratorService();
		this.downloadPdfService = new NameWritingPdfDownloadingService();
	}

	componentDidMount() {
		this.generateReport(this.state.printData);
	}

	componentWillUnmount() {
		this.downloadPdfService.destroy();
		this.destroy$.next();
		this.destroy$.complete();
	}

	render() {
		return <>
			<ServiceLoader trackingService={this.downloadPdfService}/>
			<Modal.Body>
				<FlexBox align='stretch' justify='stretch' direction='column' className={styles.body}>
					<div className={styles.unitName}>
						{this.props.unitData.students[0]?.units?.[0].name}
					</div>
					<div className={styles.bodyTitle}>
						Select the settings for your print-out.
					</div>
					<FlexBox align='stretch' justify='space-around' direction='row' wrap='wrap'>
						{this.renderSettings()}
						{this.renderPreview()}
					</FlexBox>
				</FlexBox>
			</Modal.Body>
			<Modal.Footer>
				<FlexBox align='center' justify='stretch' className={styles.footer}>
					<FlexBox justify='end' className={styles.navigation}>
						<NavigationBar
							prevStepEnabled={true}
							nextStepEnabled={false}
							currentStep={this.props.currentStep}
							totalSteps={this.props.totalStepsCount}
							previousClicked={() => this.props.goToBack(this.state.printData)}/>
					</FlexBox>
					<FlexBox justify='end' className={styles.buttons}>
						<Buttons.Outlined disabled={this.downloadDisabled()} onClick={e => this.props.goToPreview(this.state.printData, this.state.report)}>Preview</Buttons.Outlined>
						<Buttons.Contained disabled={this.downloadDisabled()} onClick={e => this.onDownloadPdf()}>Download</Buttons.Contained>
					</FlexBox>
				</FlexBox>
			</Modal.Footer>
		</>;
	}

	private renderSettings() {
		return <FlexBox align='stretch' justify='stretch' direction='column' className={styles.settingsContainer}>
			<BasePrintSettings
					fontSizeWarningMessage='The selected font size is too large to fit some student names within the practice sheet. The font size will be reduced for those specific students to fit on the sheet.'
					isFontSizeOptimized={this.state.report?.details.isFontSizeOptimized ?? false}
					fontSizeOptions={this.fontSizeOptions}
					studentsCount={this.props.unitData.students.length}
					selectedFontSize={this.state.printData.selectedFontSize}
					copiesCount={this.state.printData.copiesCount}
					wordsCount={7}
					settingsChanged={(settings) => this.onBasicSettingsChanged(settings)}>
				<SettingsItem title='Name Format'>
					<FlexBox>
						<Radio value={NameFormat.FirstName}
							   checked={this.state.printData.nameFormat === NameFormat.FirstName}
							   className={styles.nameFormatRadio}
							   onChange={() => this.onNameFormatChanged(NameFormat.FirstName)}>
							First Name
						</Radio>
						<Radio value={NameFormat.FirstAndLastName}
							   checked={this.state.printData.nameFormat === NameFormat.FirstAndLastName}
							   className={styles.nameFormatRadio}
							   onChange={() => this.onNameFormatChanged(NameFormat.FirstAndLastName)}>
							First and Last Name
						</Radio>
					</FlexBox>
				</SettingsItem>
			</BasePrintSettings>
		</FlexBox>;
	}

	protected renderPreview() {
		return <TemplatePreview className={styles.preview}>
			{
				this.state.report &&
				<NameWritingTemplate className={styles.template}
									 details={this.state.report.details}
									 student={this.state.report.students[0]}/>
			}
		</TemplatePreview>;
	}

	private onNameFormatChanged(newValue: NameFormat) {
		this.onChangePrintSettings({nameFormat: newValue} as NamingWritingPrintSettings)
	}

	private onBasicSettingsChanged(settings: BasicPrintSetting) {
		this.onChangePrintSettings(settings as NamingWritingPrintSettings)
	}

	private onChangePrintSettings(mutatedData: NamingWritingPrintSettings) {
		const newSettings = {...this.state.printData, ...mutatedData};
		this.setState({printData: newSettings});
		this.generateReport(newSettings);
	}

	private downloadDisabled(){
		return isNaN(this.state.printData.copiesCount);
	}

	private generateReport(settings) {
		this.reportGenerator
			.generateReport(this.props.unitData, settings)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				this.setState({report: result});
			});
	}

	private onDownloadPdf() {
		const report = this.state.report;
		this.downloadPdfService
			.downloadPdf(report)
			.subscribe(result => {
				if (result.isSuccess) {
					this.props.pdfDownloaded(report);
				}
			});
	}
}
