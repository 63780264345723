import {CroppedImage} from '@esgi/ui';
import {NewPhoto, UserPhoto} from '../../../../../../types';
import {isNull} from 'underscore';
import {parseDataUrlBased64Text} from './parse-combined-based64-text';
import {isPhotoAndControlledAvatarEqual} from './is-photo-and-controlled-avatar-equal';

type Return = {
	newPhoto: NewPhoto | null;
};

export function getPhotoData({
	currentPhoto,
	newPhoto,
}: {
	currentPhoto: UserPhoto | null;
	newPhoto: CroppedImage | null;
}): Return {
	if (!isNull(newPhoto) && !isPhotoAndControlledAvatarEqual({photo: currentPhoto, controlledUserAvatar: newPhoto})) {
		const parsedImage = parseDataUrlBased64Text(newPhoto.image ?? '');
		const parsedCropImage = parseDataUrlBased64Text(newPhoto.croppedImage ?? '');

		const cropX = newPhoto.crop?.x ?? 0;
		const cropY = newPhoto.crop?.y ?? 0;
		const cropZoom = newPhoto.crop?.zoom ?? 0;

		return {
			newPhoto: {
				remove: false,
				crop: {
					x: cropX,
					y: cropY,
					zoom: cropZoom,
				},
				imageBase64: parsedCropImage.base64String,
				contentType: parsedImage.contentType,
				imageCropBase64: parsedCropImage.base64String,
			},
		};
	}

	if (!isNull(currentPhoto) && isNull(newPhoto)) {
		return {
			newPhoto: {
				remove: true,
			},
		};
	}

	return {
		newPhoto: null,
	};
}
