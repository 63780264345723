import {useEffect, useRef} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {maxLengths} from 'shared/modules/auto-test-creator/constants';
import {AdvancedEditIcon} from 'shared/modules/auto-test-creator/icons/advanced-edit-icon';
import {DeleteIcon} from 'shared/modules/auto-test-creator/icons/delete-icon';
import {ThumbnailIcon} from 'shared/modules/auto-test-creator/icons/thumbnail-icon';
import styles from './styles.module.less';

interface Props {
	id: string;
	orderNumber: number;
	onClose: () => void;
	onEdit: (id, order) => void;
	onDelete: (id: string) => void;
	onChangeStateStandard: () => void;
	onChangeSelectedThumbnailID: (id: string) => void;
	stateStandardID?: number;
	stateStandard?: string;
}

export function ContextMenu({
	orderNumber,
	id,
	onClose,
	onDelete,
	stateStandard,
	onChangeStateStandard,
	stateStandardID,
	onChangeSelectedThumbnailID,
	onEdit,
}: Props) {

	const ref = useRef<HTMLUListElement>();

	useEffect(() => {
		const onOutsideClick = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};
		document.addEventListener('mousedown', onOutsideClick);
		return () => document.removeEventListener('mousedown', onOutsideClick);
	}, [onClose]);


	return <ul data-cy='context-menu' ref={ref} className={styles.menu}>
		<li data-cy='advanced-edit' onClick={() => onEdit(id, orderNumber)} className={styles.menuButton}>
			<div className={styles.iconContainer}>
				<AdvancedEditIcon/>
			</div>
			<div className={styles.labelContainer}>Advanced edit</div>
		</li>

		<li data-cy='delete' onClick={() => onDelete(id)} className={styles.menuButton}>
			<div className={styles.iconContainer}>
				<DeleteIcon/>
			</div>
			<div className={styles.labelContainer}>Delete</div>
		</li>

		<li data-cy='set-as-thumbnail' onClick={() => onChangeSelectedThumbnailID(id)} className={styles.menuButton}>
			<div className={styles.iconContainer}>
				<ThumbnailIcon/>
			</div>
			<div className={styles.labelContainer}>Set as thumbnail</div>
		</li>

		<li data-cy='standard' onClick={onChangeStateStandard} className={styles.menuButton}>
			{stateStandardID ? <div data-cy='state-standard'>
				<OnHoverTooltip message={stateStandard}>
					<div>
						{stateStandard.length >= maxLengths.standard ? `${stateStandard.slice(0, maxLengths.standard)} ...` : stateStandard}
					</div>
				</OnHoverTooltip>
			</div> : <div className={styles.addStateStandards} data-cy='add-state-standard'>
				<div data-cy='standard-icon' className={styles.standardIcon}>S</div>
				<div className={styles.labelContainer}>Add State Standard</div>
			</div>
			}
		</li>
	</ul>;
}
