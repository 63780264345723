import {useEffect, useMemo, useRef, useState} from 'react';
import {historyOptions, pieChartOptions} from '../../../../utils';
import {CommonProps} from '../../../../types';
import {Container, Chart, Graph} from './index.styled';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import RenderIfVisible from 'react-render-if-visible';

export function Charts({test, report, reportSettings: {printInColor, showBaseline}}: CommonProps) {
	const {teacher} = report;
	const [itemHeight, setItemHeight] = useState(0);
	const itemRef = useRef<HTMLDivElement>(null);

	const pieChartOptionsMemo = useMemo(
		() => pieChartOptions(test, printInColor, teacher),
		[test, printInColor, teacher],
	);
	const historyOptionsMemo = useMemo(
		() => historyOptions(test, showBaseline, report, printInColor, teacher),
		[test, printInColor, teacher, showBaseline, report],
	);
	const containerStyle = {
		width: '100%',
		height: '100%',
	};

	useEffect(() => {
		setItemHeight(itemRef.current?.clientHeight);
		const visibleEl = (itemRef.current?.children[0] as HTMLElement);
		visibleEl.style.width = itemRef.current?.clientWidth + 'px';
		visibleEl.style.display = 'flex';
	}, [itemRef.current]);

	return (
		<Container ref={itemRef}>
			<RenderIfVisible defaultHeight={itemHeight}>
				<Chart data-cy='pie-chart'>
					<HighchartsReact
						options={pieChartOptionsMemo}
						containerStyle={containerStyle}
					/>
				</Chart>
				<Graph data-cy='graph'>
					<HighchartsReact
						options={historyOptionsMemo}
						containerStyle={containerStyle}
					/>
				</Graph>
			</RenderIfVisible>
		</Container>
	);
}
