/* eslint-disable @typescript-eslint/member-ordering */
import {BaseService} from '@esgi/core/service';
import {AssignmentStatus} from 'pages/assignment-center/typings/assignment';
import {BehaviorSubject} from 'rxjs';
import {
	AssignmentDeletedEvent,
	AssignmentReorderEvent,
} from 'pages/assignment-center/components/assignment-card/events';
import {dispatchAppEvent} from '@esgillc/events';
import {LocalStorageService} from './local-storage-service';

export abstract class AssignmentCardService extends BaseService {
	public status: BehaviorSubject<AssignmentStatus> = new BehaviorSubject<AssignmentStatus>(AssignmentStatus.None);
	public localStorage: LocalStorageService;
	public isTeacherInstructionsBanned$ = new BehaviorSubject<boolean | null>(null);
	private readonly controllerView = 'assignments/view';
	private readonly controllerEdit = 'assignments/edit';
	protected assignmentID: number | undefined;

	constructor() {
		super();

		this.localStorage = new LocalStorageService();
	}

	public complete = () => {
		this.setState(AssignmentStatus.Completed);
	};

	public post = () => {
		this.setState(AssignmentStatus.Published);
		this.isTeacherInstructionsBanned$.next(this.localStorage.isBannedModal);
	};

	public delete = () => {
		return this.httpClient.ESGIApi.post(this.controllerEdit, 'delete', {assignmentID: this.assignmentID}).subscribe(
			() => {
				if (!this.assignmentID) {
					throw new Error('assignmentID is undefined');
				}

				return dispatchAppEvent(AssignmentDeletedEvent, new AssignmentDeletedEvent(this.assignmentID));
			},
		);
	};

	public reorder = (testIDs: number[]) => {
		return this.httpClient.ESGIApi.post(this.controllerEdit, 'tests-reorder', {
			assignmentID: this.assignmentID,
			testIDs,
		}).subscribe(() => {
			if (!this.assignmentID) {
				throw new Error('assignmentID is undefined');
			}

			return dispatchAppEvent(AssignmentReorderEvent, new AssignmentReorderEvent(this.assignmentID));
		});
	};

	private setState(state: AssignmentStatus) {
		return this.httpClient.ESGIApi.post(this.controllerView, 'set-state', {
			assignmentID: this.assignmentID,
			assignmentState: state,
		}).subscribe(() => {
			this.status.next(state);
		});
	}
}
