import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

const titleContainerPaddingInline = 12;

export const Container = styled(GridBox, {
	paddingTop: 20,
	paddingBottom: 20,
	paddingLeft: 20,
	paddingRight: 32,
	width: '100%',
	gap: '$4',
	gridTemplateRows: 'auto 1fr',
	variants: {
		isTablet: {
			true: {
				paddingLeft: 16,
				paddingRight: 16,
			},
		},
	},
});

export const TitleContainer = styled(GridBox, {
	paddingTop: 20,
	gap: '$3',
	paddingLeft: titleContainerPaddingInline,
	paddingRight: titleContainerPaddingInline,
	textAlign: 'center',
});

export const Divider = styled(Box, {
	width: `calc(100% + ${titleContainerPaddingInline}px)`,
	height: 1,
	backgroundColor: '$mild',
	marginRight: -titleContainerPaddingInline,
	justifySelf: 'center',
});
