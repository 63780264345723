import {Card, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardHeaderBox = styled(Card.Header, {
	display: 'grid',
	gridTemplateColumns: 'auto auto 1fr',
	alignItems: 'center',
	gap: 12,
	height: 32,
});

export const TestLabelsBox = styled(FlexBox, {
	justifySelf: 'end',
	gap: 12,
	width: 'max-content',
});
