import {Drawer} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {ContentWrapper, PanelContent} from '../../../index.styled';

export function GradeScales() {

	const onSave = () => {
		console.log('save Grade Scales tab');
	};

	return <>
		<Drawer.PanelHeader
			withActionButton
			actionButtonText='Save Changes'
			onActionButtonClick={onSave}
		>
			<Text size='large' color='neutral24' bold>Grade Scales</Text>
		</Drawer.PanelHeader>

		<PanelContent>
			<ContentWrapper>
				Grade Scales tab
			</ContentWrapper>
		</PanelContent>
	</>;
}
