import {Renderable, FormElement} from '@esgi/deprecated/knockout';
import {FieldContainer, FieldTool} from '../../../kit/component';
import {
	EmailInputComponent,
	PasswordInputComponent,
	PricingForm,
	StripeForm,
	UsernameInputComponent,
} from '../../components';
import {resizeToWindow} from '../../utils';
import {AcUserInfoModel, FlowResultModel, PurchaseUserInfoModel, TrialUserInfoModel} from '../../types';
import {PurchaseContinueTemplate} from './templates';

import './purchase.less';
import {StripeUtil} from '@esgi/deprecated/utils';

export class PurchaseFlow extends Renderable {
	private email: EmailInputComponent;
	private username: UsernameInputComponent;
	private password: PasswordInputComponent;
	private pricingForm: PricingForm;
	private fields: Array<FieldContainer | FormElement>;
	stripeForm: StripeForm;
	userCreating: KnockoutObservable<boolean> = ko.observable(false);

	constructor(opts?: TrialUserInfoModel) {
		super();
		this.userCreating(false);
		this.createFields(opts);
	}

	template = () => {
		resizeToWindow();
		return PurchaseContinueTemplate.Render();
	};

	private createFields(opts?: TrialUserInfoModel) {
		this.email = new EmailInputComponent(opts && opts.email);
		this.username = new UsernameInputComponent(opts && opts.userName);
		this.password = new PasswordInputComponent(opts && opts.password);
		this.pricingForm = new PricingForm(opts && opts.promoCode);
		this.stripeForm = new StripeForm();

		this.email.validationResult.subscribe((v) => {
			if (!this.username.serialize() && !v.isExist && v.isValid) {
				this.username.trySetUserName(v.value);
			}
		});
		this.pricingForm.events.acFlowClicked(() => {
			this.eventTriggers.toACFlow();
		});
		this.email.setErrorPosition('left');
		this.username.setErrorPosition('left');
		this.password.setErrorPosition('left');
		this.stripeForm.cardNumber.validation.errorPosition = 'left';
		this.fields = new Array<FieldContainer | FormElement>();
		this.fields.push(...[this.email, this.username, this.password, this.pricingForm.promoCodeField, this.pricingForm.monthField, this.stripeForm]);
	}

	public isValid(): JQueryPromise<boolean> {
		return $.whenAll([...this.fields.map((f) => f.validate())]).pipe((r: Array<boolean>) => {
			const indexOfFirstFailed = r.indexOf(false);
			if (indexOfFirstFailed !== -1) {
				FieldTool.scrollToField(this.fields[indexOfFirstFailed]);
			}
			return r.reduce((a, b) => a && b);
		});
	}

	serialize(): FlowResultModel<PurchaseUserInfoModel> {
		const model = <PurchaseUserInfoModel>{
			email: this.email.serialize().trim(),
			userName: this.username.serialize().trim(),
			password: this.password.serialize(),
			promoCode: this.pricingForm.serialize().promocode,
			months: this.pricingForm.serialize().month,
			cardNumber: StripeUtil.StripeCardNumberId,
		};
		return <FlowResultModel<PurchaseUserInfoModel>>{
			model: model,
		};
	}

	clearValidationResult(): void {
		this.email.removeTooltip();
		this.username.removeTooltip();
		this.password.removeTooltip();
		this.pricingForm.removeTooltip();
	}

	events = {
		back: (callback) => {
			$(this).on('back', callback);
		},
		done: (callback) => {
			$(this).on('next', callback);
		},
		toACFlow: (callback) => {
			$(this).on('acFlowClicked', callback);
		},
	};

	private eventTriggers = {
		next: () => {
			this.userCreating(true);
			this.isValid().done((r) => {
				if (r) {
					$(this).trigger('next', {data: this.serialize()});
				} else {
					this.userCreating(false);
				}
			});
		},
		back: () => {
			$(this).trigger('back');
		},
		toACFlow: () => {
			const pcInfo = this.pricingForm.promoCodeField.promoCodeInfo();
			let model = <AcUserInfoModel>{
				email: this.email.validation.valid() ? this.email.serialize() : '',
				password: this.password.validation.valid() ? this.password.serialize() : '',
				userName: this.username.validation.valid() ? this.username.serialize() : '',
			};
			if (pcInfo && pcInfo.isActivationCode) {
				model.activationCode = pcInfo.value;
			}
			$(this).trigger('acFlowClicked', {data: model});
		},
	};
}
