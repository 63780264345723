import {Dispatch, SetStateAction} from 'react';
import {LegendWithTranscriptItem} from '../../../../legend-with-transcript';
import {TestInfo, TestSession} from '../../../../../../../types';

export enum TabId {
	QuickView = 'QuickView',
	DetailedView = 'DetailedView',
}

export type SynchronizedStateProps = {
	activeTab: TabId;
	setActiveTab: Dispatch<SetStateAction<TabId>>;
	isDetailedView: boolean;
	emptySessionsCount: number;
	diagramLegendItems: LegendWithTranscriptItem[];
	testSessions: TestSession[];
	testInfo: TestInfo;
	diagramPageIndex: number;
	setDiagramPageIndex: Dispatch<number>;
	isTablet: boolean;
};
