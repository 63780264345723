import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AssignmentInfo} from './types';
import {EventBusManager} from '@esgillc/events';
import {AssignmentDeletedEvent} from 'pages/assignment-center/components/assignment-card/events';
import {CreateAssignmentExtractedSpecialistData} from '../components/create-assignment/types/specialist-types';
import {CreateAssignmentExtractedTeacherData} from '../components/create-assignment/types/teacher-types';

type InitResponse = {assignments: AssignmentInfo[]};

export class AssignmentService extends BaseService {

	public readonly assignments: BehaviorSubject<AssignmentInfo[]> = new BehaviorSubject<AssignmentInfo[]>([]);
	public readonly eventBus: EventBusManager = new EventBusManager();
	public createAssignmentExtractedTeacherData = new BehaviorSubject<CreateAssignmentExtractedTeacherData | null>(null);
	public createAssignmentExtractedSpecialistData = new BehaviorSubject<CreateAssignmentExtractedSpecialistData | null>(null);

	constructor() {
		super();

		this.eventBus.fromEvent(AssignmentDeletedEvent).subscribe(e => {
			this.assignments.next(this.assignments.value.filter(a => a.id !== e.args.assignmentID));
		});
	}

	public init(): Observable<InitResponse> {
		return this.httpClient.ESGIApi
			.get<InitResponse>('assignments/view', 'list')
			.pipe(tap(res => this.assignments.next(res.assignments)))
			.asObservable();
	}

	public extractTeacherData(data: CreateAssignmentExtractedTeacherData | null) {
		this.createAssignmentExtractedTeacherData.next(data);
	}

	public extractSpecialistData(data: CreateAssignmentExtractedSpecialistData | null) {
		this.createAssignmentExtractedSpecialistData.next(data);
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}
}
