import {LableBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {TestType} from '@esgi/main/libs/core';
import {testLabelByTestType} from './constants';

type Props = {
	testType: TestType;
	dataCy?: string;
};

export function TestTypeLabel({testType, dataCy = 'test-type'}: Props) {
	return (
		<LableBox data-cy={dataCy}>
			<Text data-cy={`${dataCy}-label`} size='small' color='neutral40'>
				{testLabelByTestType[testType]}
			</Text>
		</LableBox>
	);
}
