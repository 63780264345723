import {NavigationBarOrientation} from '@esgi/ui/layout';
import {ThemeProvider} from '@esgi/ui/theme';
import {useMatchMedia} from '@esgi/ui/utils';
import {NavigationPanelBaseProps, SchoolYear} from './types';
import {VerticalPanel} from './components/variants/vertical-panel';
import {HorizontalPanel} from './components/variants/horizontal-panel';
import {transformToShortName} from './utils';

type Props = Omit<NavigationPanelBaseProps, 'schoolYears' | 'onChangeSchoolYears'> & {
	schoolYears: SchoolYear[];
	onChangeSchoolYears?: (globalSchoolYearID: number) => void;
};

export function NavigationPanel({schoolYears, onChangeSchoolYears, ...passedProps}: Props) {
	const schoolYearsTransformed = transformToShortName(schoolYears);
	const isPortrait = useMatchMedia('portrait-tablet');
	const isLandscape = useMatchMedia('landscape-tablet');
	const orientation =
		isLandscape || isPortrait ? NavigationBarOrientation.Horizontal : NavigationBarOrientation.Vertical;

	const commonProps: NavigationPanelBaseProps = {
		schoolYears: schoolYearsTransformed,
		onChangeSchoolYears: (v: string) => onChangeSchoolYears?.(Number(v)),
		...passedProps,
	};

	return (
		<ThemeProvider>
			{orientation === NavigationBarOrientation.Horizontal ? (
				<HorizontalPanel {...commonProps} />
			) : (
				<VerticalPanel {...commonProps} />
			)}
		</ThemeProvider>
	);
}
