import {FontStyle} from 'shared/modules/question-editor/models';
import mx from '../../assets/mx-graph/mx-graph-factory';

export class FontStyleInfo {
	style: FontStyle = FontStyle.Normal;
	disabledStyles: FontStyle[] = [];
}

export class ContentEditableService {
	static contentSelected(contentArea: Node) {
		return window.getSelection().type === 'Range' && !this.contentAllSelected(contentArea);
	}

	static contentAllSelected(contentArea: Node) {
		const range = window.getSelection().getRangeAt(0);

		if (contentArea.firstChild.isEqualNode(range.startContainer)) {
			let isTrue = false;

			const lastChild = contentArea.childNodes[contentArea.childNodes.length === 1 ? 0 : contentArea.childNodes.length - 2];

			if (lastChild.isEqualNode(range.endContainer)) {
				isTrue = true;
			}

			if (lastChild.isEqualNode(range.endContainer.parentNode)) {
				isTrue = true;
			}

			if (isTrue) {
				if (range.startOffset === 0 && range.endOffset === range.endContainer.textContent.length){
					return true;
				}
			}
		}

		return false;
	}

	private static getSelectedElement() {
		let node = null;

		const sel = window.getSelection();

		if (sel.getRangeAt && sel.rangeCount) {
			const range = sel.getRangeAt(0);
			node = range.commonAncestorContainer;
		}

		return node;
	}

	static getEditingElement(textarea: Element) {
		let node = this.getSelectedElement();

		while (node != null && node.nodeType != mx.mxConstants.NODETYPE_ELEMENT) {
			node = node.parentNode;
		}

		if (node != null) {
			if (node == textarea && textarea.children.length == 1 &&
				textarea.firstChild.nodeType == mx.mxConstants.NODETYPE_ELEMENT) {
				node = textarea.firstChild;
			}
		}

		return node;
	}

	static sendCommandToContent(value: string, command: string, param?: string) {
		const contentEditable = document.createElement('div');
		contentEditable.setAttribute('contentEditable', 'true');
		$(contentEditable).css({position: 'absolute'});
		$(contentEditable).offset({top: -10000, left: -10000});
		contentEditable.innerHTML = value;
		$('body').append(contentEditable);

		const range = document.createRange();
		range.selectNodeContents(contentEditable);
		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);

		document.execCommand(command, false, param);

		const result = contentEditable.children.length > 0 ? contentEditable.children[0].innerHTML : contentEditable.innerHTML;
		$(contentEditable).remove();

		return result;
	}

	static getFontStyle(node: any, fontStyleInfo?: FontStyleInfo) {
		if (!fontStyleInfo) {
			fontStyleInfo = new FontStyleInfo();
		}

		if (node.className.indexOf('mx-graph-canvas') !== -1) {
			return fontStyleInfo.style;
		}

		const tagName = node.tagName;

		const boldDisabled = fontStyleInfo.disabledStyles.indexOf(FontStyle.Bold) !== -1;
		const italicDisabled = fontStyleInfo.disabledStyles.indexOf(FontStyle.Italic) !== -1;
		const underlineDisabled = fontStyleInfo.disabledStyles.indexOf(FontStyle.Underline) !== -1;

		if (tagName === 'B' && !boldDisabled) {
			fontStyleInfo.style += FontStyle.Bold;
		}
		if (tagName === 'I' && !italicDisabled) {
			fontStyleInfo.style += FontStyle.Italic;
		}
		if (tagName === 'U' && !underlineDisabled) {
			fontStyleInfo.style += FontStyle.Underline;
		}

		//disabled styles
		if (tagName === 'SPAN') {
			if (node.style.fontStyle === 'normal') {
				fontStyleInfo.style -= FontStyle.Italic;
				fontStyleInfo.disabledStyles.push(FontStyle.Italic);
			}
			if (node.style.fontWeight === 'normal') {
				fontStyleInfo.style -= FontStyle.Bold;
				fontStyleInfo.disabledStyles.push(FontStyle.Bold);
			}
			if (node.style.textDecoration === 'normal') {
				fontStyleInfo.style -= FontStyle.Underline;
				fontStyleInfo.disabledStyles.push(FontStyle.Underline);
			}

			if (fontStyleInfo.style < 0 || isNaN(fontStyleInfo.style)){
				fontStyleInfo.style = FontStyle.Normal;
			}
		}

		if (tagName === 'DIV') {
			if (node.style.fontWeight === 'bold' && !boldDisabled) {
				fontStyleInfo.style += FontStyle.Bold;
			}
			if (node.style.fontStyle === 'italic' && !italicDisabled) {
				fontStyleInfo.style += FontStyle.Italic;
			}
			if (node.style.textDecoration === 'underline' && !underlineDisabled) {
				fontStyleInfo.style += FontStyle.Underline;
			}
		}

		return this.getFontStyle(node.parentNode, fontStyleInfo);
	}

	private static componentToHex(c) {
		const hex = c.toString(16);
		return hex.length == 1 ? '0' + hex : hex;
	}

	static RGBToHex(rgb) {
		let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
		rgb = rgb.substr(4).split(')')[0].split(sep);

		let r = (+rgb[0]).toString(16),
			g = (+rgb[1]).toString(16),
			b = (+rgb[2]).toString(16);

		if (r.length == 1) {
			r = '0' + r;
		}
		if (g.length == 1) {
			g = '0' + g;
		}
		if (b.length == 1) {
			b = '0' + b;
		}

		return ('#' + r + g + b).toUpperCase();
	}
}