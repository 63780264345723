export enum ValidationType {
	None = 'None',
	InvalidKey = 'InvalidKey',
	AlreadyRegistered = 'AlreadyRegistered',
	NotImportCreator = 'NotImportCreator',
}

export interface InitResponse {
	title: string;
	firstName: string;
	lastName: string;
	stateID: number;
	stateName: string;
	email: string;
	userName: string;
	countryID: number;
	countryName: string;
	tracks: {trackID: number, name: string}[];
	validationFlag: ValidationType;
}
