import {ElementStatus} from '@esgillc/ui-kit/form';
import {Input} from '@esgi/ui/form-controls';
import {useMemo} from 'react';
import {InputContainer} from './index.styled';
import {IndicatorFailed} from './indicator-variant/indicator-failed';
import {IndicatorSucceeded} from './indicator-variant/indicator-succeeded';

type Props = {
    placeholder: string;
    dataCy: string;
    inpuType: React.HTMLInputTypeAttribute;
    controlStatus: ElementStatus;
};

export function InputWithIndicator({placeholder, dataCy, inpuType, controlStatus}: Props){    

	const statusComponent = useMemo(() => {
		if (controlStatus === ElementStatus.valid) {
			return <IndicatorSucceeded />;
		}

		if (controlStatus === ElementStatus.invalid) {
			return <IndicatorFailed />;
		}

		return null;
	}, [controlStatus]);

	return (							
            <InputContainer>
                <Input.Base type={inpuType} placeholder={placeholder} dataCy={dataCy}/>
                {statusComponent}
            </InputContainer>

	);
}