import {BaseService} from '@esgi/core/service';
import {Subject} from 'api/entities/subject';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {map, shareReplay, tap} from 'rxjs/operators';
import {SubjectType} from '@esgi/core/enums';
import {SsoTracker} from '@esgi/core/tracker';
import {AvailableTest, ContentAreaModel, GetTestsResponse} from '../../../../../../../../models';

export class AddSelfAssessService extends BaseService {
	public contentAreas$: Observable<ContentAreaModel[]> = this.httpClient.ESGIApi
		.get<{contentAreas: ContentAreaModel[]}>('dictionaries/contentarea', 'all')
		.pipe(
			map(x => x.contentAreas),
			shareReplay(1),
		).asObservable();

	public tests$: Observable<AvailableTest[]> = this.httpClient.ESGIApi
		.get<GetTestsResponse>('assessments/self', 'get-tests')
		.pipe(
			map(x => x.tests),
			shareReplay(1),
		).asObservable();

	public addTestToSubject(testID: number, subjectID: number, subjectType: SubjectType): Observable<void> {
		return fromPromise(Subject.addTest(subjectID, subjectType, testID))
			.pipe(tap(() => SsoTracker.trackEvent({
				trackingEvent: 'AddTestHomepage',
				data: {subjectId: subjectID, subjectType: subjectType, testId: testID},
			})));
	}
}
