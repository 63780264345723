import {WrapperBox} from './index.styled';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';

export function GroupsContent() {
	const {
		entities: {classes, groups},
	} = StudentsPanel.useStudentsPanelContext();

	const {groupsWithStudents} = StudentsPanel.Tabs.Groups.useGroupsContext();

	const {
		selected: {
			studentId: selectedStudentId,
			groupId: selectedGroupId,
			studentFromGroupId,
		},
		selectStudentFromGroup,
		selectGroup,
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	const isDisplayClassesSelect = Boolean(classes.length || groups.length);

	return (
		<WrapperBox withClassesSelect={isDisplayClassesSelect}>
			{isDisplayClassesSelect && <StudentsPanel.Tabs.Groups.ClassSelect />}

			<StudentsPanel.Tabs.TabContent>
				{groupsWithStudents.length ? (
					groupsWithStudents.map(({id, name, students}) => {
						const isOpen = selectedGroupId === id || studentFromGroupId === id;

						return (
							<StudentsPanel.Lists.SelectableListGroup.Expandable.Single
								withCounter
								isListOpen={isOpen && Boolean(students.length)}
								key={id}
								hideArrowIcon={isOpen}
								onTriggerButtonClick={() => selectGroup(id)}
								groupName={name}
								onItemClick={(studentId) =>
									selectStudentFromGroup({studentId, groupId: id})
								}
								value={selectedStudentId}
								isListSelected={selectedGroupId === id}
								items={students}
								allowSelectEmpty
							>
								{({item: {firstName, lastName}}) => (
									<StudentsPanel.StudentFullName
										firstName={firstName}
										lastName={lastName}
									/>
								)}
							</StudentsPanel.Lists.SelectableListGroup.Expandable.Single>
						);
					})
				) : (
					<StudentsPanel.Tabs.EmptyTabContent
						size='full'
						templateText='No groups to display'
					/>
				)}
			</StudentsPanel.Tabs.TabContent>
		</WrapperBox>
	);
}
