import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TagWrapper = styled(FlexBox, {
	borderRadius: 12,
	backgroundColor: '$background',
	border: '1px solid $mild',
	padding: '6px 12px',
	'& > span': {
		fontSize: 11,
		fontWeight: 600,
		color: '$highContrast',
	},
	variants: {
		variant: {
			first: {
				borderBottomRightRadius: 0,
				borderTopRightRadius: 0,
			},
			middle: {
				borderRadius: 0,
			},
			last: {
				borderBottomLeftRadius: 0,
				borderTopLeftRadius: 0,
			},
		},
		color: {
			'default': {
			},
			'orange': {
				backgroundColor: '$tertiarySurface',
				borderColor: '$tertiaryHighlight',
				'& > span': {
					color: '$tertiary',
				},
			},
		},
	},
});