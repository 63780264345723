import {TestType} from '@esgi/main/libs/core';
import {TestContentArea} from '@esgi/main/kits/common';
import {Student} from '@esgi/main/libs/store';

export type AnalyticsData = {
	total: number;
	averageSessionDuration: number;
	lastSessionDate: Date;
	sessionCount: number;
};

export type StudentResultDiagramData = {
	correctAnswers: number;
	result: number;
	delta: number;
	lastTestSessionRubricAnswers?: {criteriaID: number, score: number}[]
};
export enum Session {
	Resume = 'resume',
	NewSession = 'newSession'
}

export enum IncludeType {
	AllQuestions = 'allQuestions',
	IncorrectAndNotTested = 'incorrectAndNotTested'
}

export type CheckboxCheckedState = boolean | 'indeterminate'

export type StartData = {
	session: Session;
	include?: IncludeData;
}

export interface IncludeData {
	includeType: IncludeType;
	isShuffleQuestionOrder?: CheckboxCheckedState;
}

export interface TestLauncherData {
	studentResult: StudentResultDiagramData | null,
	testID: number,
	testType: TestType,
	contentArea: TestContentArea,
	analyticsData: AnalyticsData | null
	isIncorrectDisabled?: boolean,
}

export interface TestLauncherState {
	isOpen: boolean;
	data: TestLauncherData;
}

export type StudentModel = Student;


export enum SubjectType {
	Personal = 'Personal',
	Stock = 'Stock',
	Deployed = 'Deployed',
}

export enum SubjectLevel {
	Teacher = 'Teacher',
	School = 'School',
	District = 'District',
}

export type SubjectModel = {
	id: number;
	name: string;
	type: SubjectType;
	hidden: boolean;
	level: SubjectLevel;
	testsCount: number;
	gradeLevels: number[];
	canEdit: boolean;
};
