export class SchoolSaveResponse {
	constructor(
		public id: number | undefined,
		public gradeLevels: number[],
		public forceTestType: number | null,
		public publishType: SubjectPublishType,
		public publishTrackID: number,
		public publishFromDate: string | null,
		public publishToDate: string | null,
		public shuffleQuestions: boolean,
		public name: string,
		public publishToTeachers: PublishToTeachers,
		public publishToSpecialists: PublishToSpecialists,
		public schoolSpecialists: number[],
	) {
	}
}

export class DistrictSaveResponse {
	constructor(
		public id: number | undefined,
		public gradeLevels: number[],
		public forceTestType: number | null,
		public publishType: SubjectPublishType,
		public publishTrackID: number,
		public publishFromDate: string | null,
		public publishToDate: string | null,
		public shuffleQuestions: boolean,
		public name: string,
		public publishToTeachers: PublishToTeachers,
		public publishToSpecialists: PublishToSpecialists,
		public districtSpecialists: number[],
		public schoolSpecialists: number[],
		public schools: number[],
	) {
	}
}

export module SchoolTab {
	export enum PublishToTeachers {
		None = 1,
		All = 2,
	}

	export enum PublishToSpecialists {
		None = 1,
		All = 2,
		IndividualISS = 3,
	}
}

export module DistrictTab {
	export enum PublishToTeachers {
		None = 1,
		All = 2,
		Schools = 3,
	}

	export enum PublishToSpecialists {
		None = 1,
		All = 2,
		IndividualISS = 3,
		IndividualISD = 4,
	}
}

export type DistrictTabFields = CommonDSubjectFields & {
	selectedSchools: number[];
	selectedSchoolSpecialists: number[];
	selectedDistrictSpecialists: number[];
	publishToTeachers: string;
	publishToSpecialists: string;
}

export type SchoolTabFields = CommonDSubjectFields & {
	publishToSpecialists: string;
	publishToTeachers: string;
	selectedSchoolSpecialists: number[];
}

export type DistrictCommonData = CommonData & {
	districtSpecialists: Field[];
	schoolSpecialistsSchools: Field[];
	teachersCount: number;
	specialistsCount: number;
}
export type SchoolCommonData = CommonData & {
	schoolName: string;
	teachersCount: number;
	schoolSpecialists: Field[];
}

export type CommonData = {
	tracks: Field[];
	schools: Field[];
	trackDates: TrackDateResponse[];
	gradeLevels: GradeLevel[];
}

export type CommonDSubjectFields = {
	name: string;
	forceTestType?: string;
	publishFromDate?: string;
	publishToDate?: string;
	publishType: string;
	publishTrackID?: number;
	selectedGradeLevels: number[];
	selectedTrackDates: number[];
	shuffleQuestions: boolean;
}

export type Field = {
	id: number,
	name: string,
}

export type CustomDateRange = {
	from: string;
	to: string;
}

export type SaveResult = {
	id: number;
	subjectName: string;
	published: boolean;
	subjectType: string;
}

export interface ValidDate {
	fromValid: boolean,
		toValid: boolean,
}

export interface GradeLevel {
	gradeLevelID: number;
	name: string;
	shortName: string;
}

export interface TrackDateResponse {
	trackID: number;
	trackDateID: number;
	dateFrom: string;
	dateTo: string;
}

export interface InitResponse {
	districtSpecialists: Field[],
	gradeLevels: GradeLevel[],
	schools: Field[],
}

export enum PublishToTeachers {
	None = 1,
	All = 2,
	Schools = 3,
}

export enum PublishToSpecialists {
	None = 1,
	All = 2,
	IndividualISS = 3,
	IndividualISD = 4,
}

export enum SubjectPublishType {
	Off = 0,
	Indefinitely = 1,
	SchoolYear = 2,
	MarkingPeriod = 3,
	CustomDateRange = 4
}



