import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Table = styled(GridBox, {
	gridTemplateColumns: '280px 1fr 1fr', // 280px is width of CheckboxesBox component
	rowGap: '$2',
	columnGap: 40,
	alignItems: 'center',
	paddingLeft: 12,
	variants: {
		isTablet: {
			true: {
				gridTemplateColumns: 'auto',
				columnGap: 12,
			},
		},
	},
});

export const ResultHeaderRow = styled(GridBox, {
	gridColumnStart: 2,
	gridColumnEnd: 4,
});

export const ResultBox = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'baseline',
	gridTemplateColumns: 'auto 220px',
	gap: '$3',
	justifyContent: 'flex-start',

	variants: {
		withOpacity: {
			true: {
				opacity: 0.4,
			},
		},
	},
});

export const SessionDetailsBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	columnGap: '$4',
	justifyContent: 'end',
	alignItems: 'center',

	'& > svg': {
		fill: '$muted',

		'& path': {
			fill: '$muted',
		},
	},
});
