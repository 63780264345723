import {
	GetRequest,
	GetResponse,
	GradeLevel,
	RebuildCarryScoresForwardRequest,
	RebuildGradeLevel,
	RebuildResponse,
	RebuildTrackRequest,
	Report,
	Row,
} from './models';
import {BaseService} from '@esgi/core/service';
import {ReportService} from 'modules/reports/utils/report-service';
import {tap} from 'rxjs';

export class Api extends BaseService {
	public reportService: ReportService<Row> = new ReportService();
	private controller = 'reports/total';

	public get(request: GetRequest): Promise<GetResponse> {
		return this.httpClient.ESGIApi.get<GetResponse>(
			this.controller,
			'get-total-report',
			request,
		).pipe(tap(({report}) => {
			this.reportService.setSortModel(null);
			if (report){
				this.reportService.setData(report.table.rows);
			}
		})).toPromise();
	}

	public IdChanged(request: GetRequest): Promise<{report: Report}> {
		return this.httpClient.ESGIApi.post<{report: Report}>(
			this.controller,
			'id-changed',
			request,
		).pipe(tap(({report}) => {
			this.reportService.setData(report.table.rows);
		})).toPromise();
	}

	public SubjectChanged(request: GetRequest): Promise<{report: Report, gradeLevels: GradeLevel[]}> {
		return this.httpClient.ESGIApi.post<{report: Report, gradeLevels: GradeLevel[]}>(
			this.controller,
			'subject-changed',
			request,
		).pipe(tap(({report}) => {
			this.reportService.setData(report.table.rows);
		})).toPromise();
	}

	public close({reportType, sourceID}: GetRequest) {
		return this.httpClient.ESGIApi.post(
			this.controller,
			'close-total-report',
			{type: reportType, reportGuids: [sourceID]},
		).subscribe();
	}

	public carryScoresForward(request: RebuildCarryScoresForwardRequest) {
		return this.httpClient.ESGIApi.post<RebuildResponse>(
			this.controller,
			'carry-scores-forward',
			request,
		).pipe(tap(({report}) => {
			this.reportService.setData(report.table.rows);
		})).toPromise();
	}

	public gradeLevel(request: RebuildGradeLevel) {
		return this.httpClient.ESGIApi.post<RebuildResponse>(
			this.controller,
			'grade-level',
			request,
		).pipe(tap(({report}) => {
			this.reportService.setData(report.table.rows);
		})).toPromise();
	}

	public displayZeroIfNotTested(value: boolean) {
		return this.httpClient.ESGIApi.post(
			'reports/user-settings',
			'update',
			{name: 'displayZeroIfNotTested', value},
		).toPromise();
	}

	public markingPeriodAll(value){
		return this.httpClient.ESGIApi.post(
			this.controller,
			'marking-period-all',
			{value},
		).toPromise();
	}

	public changeTrack(request: RebuildTrackRequest) {
		return this.httpClient.ESGIApi.post<RebuildResponse>(
			this.controller,
			'change-track',
			request,
		).pipe(tap(({report}) => {
			this.reportService.setData(report.table.rows);
		})).toPromise();
	}

	public downloadFile(extension, reportType, options) {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = `${reportType}_Total_Report_${year}-${month}-${day}.${extension}`;
		const actions = {
			pdf: 'download-pdf',
			xlsx: 'download-excel',
		};
		const {rows} = this.reportService.optionsToExport.value;

		return this.httpClient.ESGIApi.file(
			this.controller,
			actions[extension],
			filename,
			{...options, rows},
		);
	}
}
