import {SubjectBelongsTo} from './types';
import {SubjectLevel} from '@esgi/main/libs/store';

type ArrayMember = string | number;
export const convertPrimitiveArrayToDictionary = (array: ArrayMember[]): Record<ArrayMember, boolean> => {
	return array.reduce<Record<ArrayMember, boolean>>((acc, item) => {
		acc[item] = true;
		return acc;
	}, {});
};

export const SubjectLevelToSubjectBelongsTo: Record<SubjectLevel, SubjectBelongsTo> = {
	[SubjectLevel.Teacher]: 'personal',
	[SubjectLevel.School]: 'school',
	[SubjectLevel.District]: 'district',
};
