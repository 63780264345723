import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EmptyDataContainer = styled(GridBox, {
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
});

export const Container = styled(Box, {
	maxWidth: 1040,
});
