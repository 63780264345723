import {SkeletonStylesWithBorders} from '../../../skeleton';
import {Root} from '@radix-ui/react-avatar';
import {styled} from '@esgi/ui/theme';

export const StyledAvatarRoot = styled(Root, {
	position: 'relative',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	verticalAlign: 'middle',
	overflow: 'hidden',
	userSelect: 'none',
	cursor: 'pointer',
	border: '1px solid',
	transition: '.2s ease',

	'&:hover': {
		transition: '.5s ease',
		border: '1px solid $secondaryMuted',
	},

	variants: {
		size: {
			xl: {
				width: '64px',
				height: '64px',
				borderRadius: '16px',
			},
			lg: {
				width: '56px',
				height: '56px',
				borderRadius: '16px',
			},
			md: {
				width: '48px',
				height: '48px',
				borderRadius: '12px',
			},
			sm: {
				width: '40px',
				height: '40px',
				borderRadius: '10px',
			},
			xs: {
				width: '24px',
				height: '24px',
				borderRadius: '8px',
			},
		},

		borderType: {
			dashed: {
				borderStyle: 'dashed',
			},
			solid: {
				borderStyle: 'solid',
			},
		},

		borderColor: {
			primary: {
				borderColor: '$primaryHighlight',
			},
			secondary: {
				borderColor: '$secondaryMuted',
			},
			neutral: {
				borderColor: '$border',
			},
			transparent: {
				borderColor: 'transparent',
			},
		},

		isCircle: {
			true: {
				borderRadius: '50%',
			},
		},

		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
})
;
