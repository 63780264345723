import {useVirtualizer} from '@tanstack/react-virtual';
import {useCallback, useEffect, useRef, useState} from 'react';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {Text} from '@esgi/ui/typography';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useQuestionBankService} from '../../../../hooks';
import {listCardHeight, tileCardHeight} from '../../../../constants';
import {BankQuestionModel, ColumnsCountType, ViewMode} from '../../../../types';
import {QuestionRow} from '../question-row';
import {useViewModeListener, useVirtualListConfig} from './hooks';
import {NoDataWrapper, StyledOverlayScrollbars, StyledRowContainer} from './index.styled';

interface Props {
	selectedView: ViewMode;
	onQuestionPreview: (question: BankQuestionModel) => void;
	selectedStandardType: number
}

export function QuestionList({selectedView, onQuestionPreview, selectedStandardType}: Props) {
	const service = useQuestionBankService();

	const [viewColumnsCount, setViewColumnsCount] = useState<ColumnsCountType>(1);

	const response = useBehaviorSubject(service.pageResponse$);
	const loadedQuestionsCount = useBehaviorSubject(service.loadedQuestionsCount);
	const isLoading = useBehaviorSubject(service.isLoading);
	const osRef = useRef<OverlayScrollbarsComponentRef>();
	const questions = useBehaviorSubject(service.questions);
	const selectedQuestions = useBehaviorSubject(service.selectedQuestions);

	const scrollElement = osRef.current?.osInstance()?.elements()?.viewport;

	const {totalRows, totalLoadedRows, hasMoreRows, targetPageSize} = useVirtualListConfig({
		total: response?.total ?? 0,
		loadedQuestionsCount,
		viewColumnsCount,
	});

	const virtualizer = useVirtualizer({
		paddingEnd: 8,
		count: hasMoreRows ? totalLoadedRows + 1 : totalRows,
		estimateSize: ()=> selectedView === ViewMode.List ? listCardHeight : tileCardHeight,
		getScrollElement: () => scrollElement,
	});

	useViewModeListener({
		virtualizer,
		containerWidth: scrollElement?.clientWidth ?? 0,
		setViewColumnsCount,
		selectedView,
	});

	const handleQuestionSelectedChanged = useCallback((question: BankQuestionModel) => {
		service.toggleQuestion(question);
	}, [service]);

	useEffect(() => {
		if (!response) {
			return;
		}

		if (virtualizer.getVirtualItems().length) {
			virtualizer?.scrollToIndex(0);
		}

	}, [response, selectedView, virtualizer]);

	useEffect(() => {
		if (!response) {
			return;
		}

		const virtualRows = [...virtualizer.getVirtualItems()];
		const lastRow = virtualRows[virtualRows.length - 1];
		if (!lastRow) {
			return;
		}

		const needMore =
			response.total
			&& loadedQuestionsCount > 0
			&& loadedQuestionsCount < response.total
			&& lastRow.index >= totalLoadedRows;

		if (!needMore || isLoading) {
			return;
		}

		service.getPage(loadedQuestionsCount, targetPageSize);
	}, [response, isLoading, service, viewColumnsCount, targetPageSize, loadedQuestionsCount, totalLoadedRows, virtualizer.getVirtualItems()]);

	const noData = !response?.total;

	return (
		<StyledOverlayScrollbars ref={osRef as never}>
			{noData && <NoDataWrapper><Text size='medium' bold>No questions found</Text></NoDataWrapper>}
			<StyledRowContainer style={{height: `${virtualizer.getTotalSize()}px`}}>
				{virtualizer.getVirtualItems().map((virtualItem) => (
					<QuestionRow
						key={virtualItem.key}
						questions={questions}
						selectedQuestions={selectedQuestions}
						selectedView={selectedView}
						columnsCount={viewColumnsCount}
						allRowsLoaded={loadedQuestionsCount === response?.total}
						virtualItem={virtualItem}
						onSelectionChanged={handleQuestionSelectedChanged}
						onPreviewClick={onQuestionPreview}
						selectedStandardType={selectedStandardType}
					/>
				))}
			</StyledRowContainer>
		</StyledOverlayScrollbars>
	);
}