import {useMemo} from 'react';
import {LegendWithTranscriptItem} from '../../../../../legend-with-transcript';
import {useUser} from '@esgi/core/authentication';

export function useDiagramLegendItems({isDetailedViewChecked}: {isDetailedViewChecked: boolean}) {
	const currentUser = useUser();

	return useMemo<LegendWithTranscriptItem[]>(
		() => [
			{
				transcriptText: currentUser?.testResultsCorrectVerbiage ?? 'Correct',
				transcriptTextColor: 'green',
				backgroundColor: 'positiveMuted',
				borderType: isDetailedViewChecked ? 'right' : 'top',
				borderColor: 'positive',
			},
			{
				transcriptText: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect',
				transcriptTextColor: 'neutral40',
				backgroundColor: 'mild',
				borderType: isDetailedViewChecked ? 'right' : 'top',
				borderColor: 'mediumContrast',
			},
			{
				transcriptText: 'Not Tested',
				transcriptTextColor: 'neutral40',
				backgroundColor: 'vivid',
				borderType: 'full',
				borderColor: 'muted',
			},
		],
		[isDetailedViewChecked, currentUser],
	);
}
