import React from 'react';
import {Dropdown, Label, Option} from '@esgillc/ui-kit/form-control';
import {Form, FormControl, FormElement, FormGroup} from '@esgillc/ui-kit/form';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {useState} from '@esgi/deprecated/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {SchoolModel} from '../../../../../../../models/models';
import styles from '../assign.module.less';
import {Field, MoveStudentModel} from '../models';

class State {
	schools: Field[] = null;
	teachers: Field[] = null;
	classes: Field[] = null;
	groups: Field[] = null;
}

class Props {
	hidden: boolean;
	schools: SchoolModel[] = null;
	onValueChange: (model: Partial<MoveStudentModel>) => void;
}

@useState(State)
export default class TeacherAssign extends React.PureComponent<Props, State> {
	private readonly currentUser = userStorage.get();
	private readonly onDestroy$: Subject<void> = new Subject();

	private form = new FormGroup({
		school: new FormControl<Field[]>([]),
		teacher: new FormControl<Field[]>([]),
		class: new FormControl<Field[]>([]),
		group: new FormControl<Field[]>([]),
	});

	public componentDidMount() {
		const {school, teacher} = this.form.controls;

		this.wrapWithOnDestroy(school.onChanged).subscribe((event) => {
			this.refreshTeachers();
			this.props.onValueChange({schoolID: event.currState.value[0]?.id});
		});

		this.wrapWithOnDestroy(teacher.onChanged).subscribe((event) => {
			this.refreshClasses();
			this.props.onValueChange({teacherID: event.currState.value[0]?.id});
		});

		this.wrapWithOnDestroy(this.form.controls.class.onChanged).subscribe((event) => {
			this.refreshGroups();
			this.props.onValueChange({classID: event.currState.value[0]?.id});
		});

		this.wrapWithOnDestroy(this.form.controls.group.onChanged).subscribe((event) => {
			this.props.onValueChange({groupID: event.currState.value[0]?.id});
		});

		this.initSchools();
	}

	private wrapWithOnDestroy<T>(observable: Observable<T>): Observable<T> {
		return observable.pipe(takeUntil(this.onDestroy$));
	}

	private initSchools(): void {
		let school: Field;
		const schools = this.schools;

		if (schools.length > 0) {
			school = schools[0];
		} else {
			school = {id: this.currentUser.schoolID, value: ''};
		}

		this.setState({schools: schools}, () => this.form.controls.school.value = [school]);
	}

	private get schools() {
		if (this.currentUser.userType !== UserType.D) {
			return [];
		}

		let schools = this.props.schools.map(x => {
			return {id: x.schoolID, value: x.name};
		});
		schools.unshift({id: 0, value: 'Select a school'});
		return schools;
	}

	private refreshTeachers(): void {
		let teacher: Field;
		const teachers = this.getTeachers(this.form.value.school[0]?.id);

		if (this.currentUser.userType === UserType.T) {
			teacher = {id: this.currentUser.userID, value: ''};
		} else {
			if (teachers.length === 2) {
				teacher = teachers[1];
			} else {
				teacher = teachers[0];
			}
		}
		this.setState({teachers: teachers}, () => this.form.controls.teacher.value = [teacher]);
	}

	private getTeachers(schoolID: number): Field[] {
		const school = this.props.schools.find(x => x.schoolID === schoolID);
		if (this.currentUser.userType === UserType.T ||
			!school ||
			this.currentUser.userType === UserType.ISD ||
			this.currentUser.userType === UserType.ISS) {
			return [];
		}

		const teachers = school.teachers
			.filter(x => !x.isExpired)
			.map(x => {
				return {id: x.teacherID, value: x.firstName + ' ' + x.lastName};
			});

		if (this.currentUser.userType === UserType.D) {
			teachers.unshift({id: -1, value: 'Unassigned'});
		}

		teachers.unshift({id: 0, value: 'Select a teacher'});
		return teachers;
	}

	private refreshClasses(): void {
		const teacherID = this.form.value.teacher[0]?.id || 0;
		const schoolID = this.form.value.school[0]?.id || 0;
		const classes = this.getClasses(teacherID, schoolID);
		this.setState({classes: classes}, () => this.form.controls.class.value = [classes[0]]);
	}

	private getClasses(teacherID: number, schoolID: number): Field[] {
		if (teacherID === 0 || teacherID === -1) {
			return [];
		}

		let school = this.props.schools.find(x => x.schoolID === schoolID);
		let teacher = school.teachers.find(x => x.teacherID === teacherID);
		return teacher.classes.map(x => {
			return {id: x.classID, value: x.name};
		});
	}

	private refreshGroups() {
		const teacherID = this.form.value.teacher[0]?.id || 0;
		const schoolID = this.form.value.school[0]?.id || 0;
		const classID = this.form.value.class[0]?.id || 0;
		let group: Field;
		let groups = this.getGroups(teacherID, schoolID, classID);
		if (groups.length === 2) {
			group = groups[1];
		} else {
			group = groups[0];
		}

		this.setState({groups: groups}, () => this.form.controls.group.value = [group]);
	}

	private getGroups(teacherID: number, schoolID: number, classID: number): Field[] {
		if (classID === 0) {
			return [];
		}

		let school = this.props.schools.find(x => x.schoolID === schoolID);
		let teacher = school.teachers.find(x => x.teacherID === teacherID);
		let selectedClass = teacher.classes.find(x => x.classID === classID);
		let groups = selectedClass.groups.map(x => {
			return {id: x.groupID, value: x.name};
		});
		groups.unshift({id: 0, value: 'Select a group'});
		return groups;
	}

	public render() {
		if (this.props.hidden) {
			return <></>;
		}
		return <div>
			<Form controller={this.form}>
				{this.state.schools && this.state.schools.length > 0 &&
					<FormElement control={this.form.controls.school}>
						<Label className={styles.dropdownLabel}>School</Label>
						<Dropdown optionName='value'>
							{this.state.schools.map(s => <Option value={s} key={s.id}>{s.value}</Option>)}
						</Dropdown>
					</FormElement>
				}
				{this.state.teachers && this.state.teachers.length > 0 &&
					<FormElement control={this.form.controls.teacher}>
						<Label className={styles.dropdownLabel}>Teacher</Label>
						<Dropdown optionName='value'>
							{this.state.teachers.map(s => <Option value={s} key={s.id}>{s.value}</Option>)}
						</Dropdown>
					</FormElement>
				}
				{this.state.classes && this.state.classes.length > 0 &&
					<FormElement control={this.form.controls.class}>
						<Label className={styles.dropdownLabel}>Class</Label>
						<Dropdown optionName='value'>
							{this.state.classes.map(s => <Option value={s} key={s.id}>{s.value}</Option>)}
						</Dropdown>
					</FormElement>
				}
				{this.state.groups && this.state.groups.length > 1 &&
					<FormElement control={this.form.controls.group}>
						<Label className={styles.dropdownLabel}>Group</Label>
						<Dropdown optionName='value'>
							{this.state.groups.map(s => <Option value={s} key={s.id}>{s.value}</Option>)}
						</Dropdown>
					</FormElement>
				}
			</Form>
		</div>;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
