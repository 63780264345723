import React, {ReactElement, ReactNode, RefObject} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, shareReplay, takeUntil} from 'rxjs/operators';
import ScrollContext, {
	InitEventArgs,
	OnHeightChangedEventArgs,
	OnScrollEventArgs,
	ScrollContextState,
} from './scroll-context';
import styles from './virtual.module.less';

class Props {
	className?: string;
	children?: ReactNode;
}

export default class VirtualScrollContainer extends React.PureComponent<Props> {
	private readonly onDestroy$: Subject<void> = new Subject();
	private readonly onInitEmitter: Subject<InitEventArgs> = new Subject<InitEventArgs>();
	private readonly onScrollEmitter: Subject<OnScrollEventArgs> = new Subject<OnScrollEventArgs>();
	private readonly onHeightChangedEmitter: Subject<OnHeightChangedEventArgs> = new Subject<OnHeightChangedEventArgs>();

	private readonly elementReference: RefObject<HTMLDivElement> = React.createRef();
	private readonly heightContainerRef: RefObject<HTMLDivElement> = React.createRef();
	private readonly expanderRef: RefObject<HTMLDivElement> = React.createRef();
	private animationRequest: number;

	private eventListener = (e: Event) => {
		if (this.animationRequest) {
			cancelAnimationFrame(this.animationRequest);
		}
		this.animationRequest = requestAnimationFrame(() => this.onScrollEmitter.next({
			e: e,
			pos: this.elementReference.current?.scrollTop,
			ref: this.elementReference.current,
		}));
	};

	private scrollContext: ScrollContextState = {
		onInit: this.onInitEmitter.pipe(takeUntil(this.onDestroy$), shareReplay(1)),
		onScroll: this.onScrollEmitter.pipe(takeUntil(this.onDestroy$)),
		onHeightChanged: this.onHeightChangedEmitter.pipe(takeUntil(this.onDestroy$)),
		expanderRef: this.expanderRef,
	};

	private initListener() {
		this.elementReference.current?.addEventListener('scroll', this.eventListener, {passive: true});
	}

	private removeListener() {
		this.elementReference.current?.removeEventListener('scroll', this.eventListener);
	}

	public componentDidMount() {
		this.onInitEmitter.next({ref: this.elementReference.current});
		this.onHeightChangedEmitter.next({height: this.heightContainerRef.current?.clientHeight});
		this.removeListener();
		this.initListener();
		this.initResizeObserving();
	}

	private initResizeObserving(): void {
		fromEvent(window, 'resize')
			.pipe(debounceTime(100))
			.subscribe(r => this.onHeightChanged(this.heightContainerRef.current?.clientHeight));
	}

	private onHeightChanged(height: number): void {
		this.onHeightChangedEmitter.next({height});
	}

	public render() {
		if (this.validateChild(this.props.children)) {
			return <ScrollContext.Provider value={this.scrollContext}>
				<div className={join(styles.viewPort)} ref={this.heightContainerRef}>
					<div ref={this.elementReference}
					     className={join(styles.virtualScrollMain, this.props.className)}>
						<div ref={this.expanderRef} className={styles.containerExpander}/>
						{this.props.children}
					</div>
				</div>
			</ScrollContext.Provider>;
		}
	}

	private validateChild(children: React.ReactNode): children is ReactElement {
		const childCount = React.Children.count(children);
		if (childCount === 1) {
			if (children instanceof Object && 'type' in children && typeof children.type === 'function') {
				throw `[UI-Kit]: ${VirtualScrollContainer.name} should have a HTML in child instead provided ${children.type}`;
			}
			if (!(children instanceof Object)) {
				throw `[UI-Kit]: ${VirtualScrollContainer.name} should have a HTML in child instead provided ${typeof children}`;
			}
		} else {
			throw `[UI-Kit]: ${VirtualScrollContainer.name} should have one children instead provided ${childCount}.`;
		}
		return true;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
