import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	paddingTop: 28,
	paddingBottom: 28,
	paddingLeft: 40,
	paddingRight: 40,
	justifyContent: 'space-between',
	gridAutoFlow: 'column',
	alignItems: 'center',
	backgroundColor: '$background',
});
