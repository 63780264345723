import {Class, Group, Student} from '@esgi/main/libs/store';
import {AllOptionItem, GroupItem} from '../types';
import {studentsComparator} from '../../../hooks/use-sort-students-data';
import {StudentSort} from '@esgi/core/authentication';

export function getGroupsContentData({
	groups,
	allStudents,
	selectedClassId,
	sortBy,
}: {
	groups: Group[];
	allStudents: Student[];
	selectedClassId: Class['id'] | AllOptionItem.Value;
	sortBy?: StudentSort;
}) {
	const studentIdsInGroups = new Set(groups.map(({studentIDs}) => studentIDs).flat());
	const studentsWithoutGroup: Student[] = [];

	allStudents.forEach((student) => {
		if (!studentIdsInGroups.has(student.id)) {
			studentsWithoutGroup.push(student);
		}
	});

	const groupsWithStudents: GroupItem[] = [];
	
	groups.forEach(({studentIDs, ...groupData}) => {
		if (groupData.classID === selectedClassId || selectedClassId === AllOptionItem.Value) {
			const studentList: Student[] = allStudents.filter(({id}) => studentIDs.includes(id));
			const students: Student[] = sortBy? studentList.concat([]).sort(studentsComparator(sortBy)): studentList;
			const groupItem: GroupItem = {
				...groupData,
				students,
			};

			groupsWithStudents.push(groupItem);
		}
	});

	return {
		groupsWithStudents,
		studentsWithoutGroup,
	};
}
