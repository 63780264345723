import {MouseEvent} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {join} from '@esgillc/ui-kit/utils';
import {ArrowIcon} from '@esgillc/ui-kit/icons';
import styles from './styles.module.less';

interface Props {
	className?: string;
	name: string;
	withIcon?: boolean;
	collapsed?: boolean;
	selected: boolean;
	onClick: () => void;
	onToggle?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const SideBarItem = (props: Props): JSX.Element => (
	<div
		className={join(props.selected ? styles.selectedButton : styles.button, props.className)}
		onClick={props.onClick}
	>
		<Buttons.Link className={join(props.selected ? styles.selectedLinkButton : styles.linkButton)}>
			{props.name}
		</Buttons.Link>
		{props.withIcon &&
			<Buttons.Icon
				className={styles.toggleButton}
				onClick={props.onToggle}
			>
				<ArrowIcon direction={props.collapsed ? 'up' : 'down'}/>
			</Buttons.Icon>
		}
	</div>
);
