import {AppEvent} from '@esgillc/events';

@AppEvent({broadcast: true})
export class TestSavedEvent {
	constructor(public testID: number, public studentID: number, public correct?: number) {
	}
}

export class TestSessionStatusUserMovedEvent {
}
