import React, {useEffect} from 'react';
import {Cards} from 'shared/modules/bingo/types/models';
import {BingoService} from 'shared/modules/bingo/service/service';
import CardSizePanel from 'shared/modules/bingo/components/card-size-panel';
import DifficultyPanel from 'shared/modules/bingo/components/difficulty-panel';

import styles from './cards.module.less';

interface CardsProps {
	service: BingoService;
	entityName: string;
	subjectName: string;
	testName: string;
	questionCount: number;
	cards: Cards;
	selectedTestId: number;
}

export default function Cards ({
	service,
	entityName,
	subjectName,
	testName,
	questionCount,
	selectedTestId,
	cards: {
		x3cardSize,
		twoCardsPerPage,
		difficulty,
	},
}: CardsProps) {

	const updateCards = (field, value) => service.updateCards(field, value);

	useEffect(() => {
		service.getQuestions(selectedTestId).subscribe();
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.topHeaderSub}>
				{entityName + ' | ' + subjectName + ' | ' + testName}
			</div>
			<div className={styles.topHeaderMain}>
				Adjust these settings to fit your class needs
			</div>
			<div className={styles.content}>
				<div className={styles.left}>
					<div className={styles.cardInfo}>The student's Bingo card will be populated with questions based on the
						selected difficulty.
					</div>
					<div className={styles.cardInfo}>Questions will be randomized on the card with the center space reserved
						as 'Free'.
					</div>
					<div className={styles[`${x3cardSize ? 'cardX3' : 'cardX5'}`]}/>
					<div className={styles.cardLegend}>Bingo Card Example</div>
				</div>
				<div className={styles.right}>
					<CardSizePanel
						x3cardSize={x3cardSize}
						onChange={updateCards}
						questionCount={questionCount}
						twoCardsPerPage={twoCardsPerPage}
					/>
					<DifficultyPanel
						difficulty={difficulty}
						onChange={updateCards}
					/>
				</div>
			</div>
		</div>
	);
}
