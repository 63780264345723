import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button as ButtonUI} from '@esgi/ui';

export const ButtonIcon = styled(ButtonUI.Icon, {
	marginRight: '20px',
});

export const Button = ButtonUI;

export const Panel = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: '18px 20px',
	borderBottom: '1px solid $mild',
	color: '$neutral56',

	'& > svg': {
		flexShrink: 0,
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	[`& > ${Text}`]: {
		marginLeft: '6px',
		lineHeight: 1,
		color: 'currentColor',
	},

	[`& > button:not(${ButtonIcon})`]: {
		marginRight: '6px',
	},

	'& > :nth-child(2)': {
		marginRight: 'auto',
	},
});
