import {ToggleGroup} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/kits/common';

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	gridAutoColumns: 'minmax(0, 1fr)',
	width: '100%',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	height: 40,
});

export const DrawerPanelHeader = styled(Drawer.PanelHeader, {
	'& [data-children-box]': {
		overflow: 'hidden',

		[`& > ${Text}`]: {
			wordBreak: 'break-all',
		},
	},
});
