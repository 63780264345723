import React from 'react';
import './component.less';
import {Draggable} from 'react-beautiful-dnd';
import {TextInput} from '@esgi/deprecated/elements';
import GradeScaleEntryName from 'shared/modules/grade-scale/grade-scale/info/entry/name/component';
import {IGradeScaleEntryForm} from 'shared/modules/grade-scale/grade-scale/models';
import ValidationWrapper from '@esgi/deprecated/elements/validation-wrapper';
import {GradeScaleService} from 'shared/modules/grade-scale/grade-scale/service';
import {ValidationStatus} from '@esgi/core/enums';

export class State {
	withErrorTooltip: boolean = true;
}

export class Props {
	service: GradeScaleService;
	canRemove: boolean;
	showValidation: boolean;
	entry: IGradeScaleEntryForm;
	containerHeight: number;
	containerTop: number;
}

export default class GradeScaleEntry extends React.PureComponent<Props, State> {
	ref: HTMLDivElement;

	constructor(props) {
		super(props);
		this.state = new State();
	}
	
	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (this.isVisible()) {
			this.setState({withErrorTooltip: true});
		} else {
			this.setState({withErrorTooltip: false});
		}
	}

	onChange(field: string, value: any) {
		let newForm = {...this.props.entry};
		newForm[field].value = value;
		this.props.service.updateEntry(newForm);
	}

	isVisible() {
		if (this.ref) {
			const tableTopSide = this.props.containerTop;
			const tableBottomSide = tableTopSide + this.props.containerHeight;

			const position = this.ref.getBoundingClientRect();
			return tableTopSide < position.top && (position.bottom < tableBottomSide + 20);
		}

		return false;
	}

	get placeholder(){
		if (this.props.entry.id === 0){
			return 'e.g. Outstanding';
		}

		if (this.props.entry.id === 1){
			return 'e.g. Satisfactory';
		}
	}
	
	render() {
		return <Draggable
			key={this.props.entry.id}
			draggableId={'entry-' + this.props.entry.id}
			index={this.props.entry.orderNumber}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<div className='grade-scale-entry-row' ref={r => this.ref = r}>
						<div className='dnd-control' {...provided.dragHandleProps}>
							<svg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16'
								 fill='none'>
								<path
									d='M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z'
									fill='#0088CC'></path>
							</svg>
						</div>
						<GradeScaleEntryName
							entry={this.props.entry}
							onChange={(field, value) => this.onChange(field, value)}
							showValidation={this.props.showValidation}
							withErrorTooltip={this.state.withErrorTooltip}
						/>
						<ValidationWrapper
							message={this.props.entry.description.validation.message}
							showValidation={this.props.entry.description.validation.status === ValidationStatus.Invalid && this.props.showValidation}
							withTooltip={this.state.withErrorTooltip}
							wrapperDivClass='grade-scale-entry-wrapper description'
						>
							<TextInput value={this.props.entry.description.value}
									   placeholder={this.placeholder}
									   className='grade-scale-entry-description'
									   onEdit={(value) => this.onChange('description', value)}
							>
								{this.props.canRemove && <div className='clear-button' onClick={() => this.props.service.removeEntry(this.props.entry)}>
									<svg width='14' height='14' viewBox='0 0 14 14' fill='none'
										 xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
											fill='#828282'/>
									</svg>
								</div>}
							</TextInput>
						</ValidationWrapper>
					</div>
				</div>
			)}
		</Draggable>;
	}
}