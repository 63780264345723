import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {UntestedStudentsReportService} from '../../../service';
import styles from '../../modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService;
}

export function ReportSpecialistGroups({service}: Props) {
	const specialistGroups = useBehaviorSubject(service.specialistGroups);
	const selectedSpecialistGroup = useBehaviorSubject(service.selectedSpecialistGroup);

	return specialistGroups.length > 0 &&
		<div>
			<span className={styles.bold}>Group:</span>
			{specialistGroups.length > 1 &&
				<Dropdown value={selectedSpecialistGroup} setValue={(v) => service.onSelectedSpecialistGroupChanged(v)}
				          optionName='name' className={styles.fluid} autoWidth={true}>
					{specialistGroups.map(group => <Option key={group.specialistGroupID}
					                                       value={group}>{group.name}</Option>)}
				</Dropdown>
			}
			{specialistGroups.length == 1 &&
				<span className={styles.name}>{selectedSpecialistGroup[0].name}</span>
			}
		</div>;
}
