export enum ReportType {
	None = 0,
	Current = 1,
	All = 2,
}

export enum ReportFormat {
	None = 0,
	SingleFile = 1,
	Zip = 2,
}

export interface BackgroundGenerationModel {
	reportGuid: string;
}

export interface BackgroundGenerationRequest {
	format: number;
	classID: number;
	specialistGroupID: number;
	studentsID: BackgroundGenerationStudentData[];
	testID: number;
	subject: string;
}

export interface BackgroundGenerationStudentData {
	studentID: number;
	sessionsID: number;
}
