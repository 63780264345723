import React, {ReactNode} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './icon.module.less';

interface Props {
	isHidden: boolean;
	onClick: () => void;
	scale?: number;
}

class State {
	hovered: boolean;
}

export class HideButton extends React.PureComponent<Props, State> {
	public state = new State();

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (this.props.isHidden !== prevProps.isHidden) {
			this.setState({hovered: false});
		}
	}


	public render() {
		return <OnHoverTooltip
			message={((this.props.isHidden) ? 'Unhide from search results' : 'Hide from search results')}>
			<button
				className={styles.button}
				onClick={this.props.onClick}
				onMouseOver={() => this.setState({hovered: true})}
				onMouseLeave={() => this.setState({hovered: false})}>
				{this.renderIcon()}
			</button>
		</OnHoverTooltip>;
	}

	private renderIcon() {
		if (this.props.isHidden) {
			return this.state.hovered ? this.renderEyeOpen() : this.renderEyeClose();
		} else {
			return this.state.hovered ? this.renderEyeClose() : this.renderEyeOpen();
		}
	}

	private renderEyeOpen(): ReactNode {
		return <svg viewBox='0 0 20 20' width={this.scaledBounds} height={this.scaledBounds}>
			<defs>
				<clipPath id='_clipPath_Zhrspac756iaElTj84N8TQKcoThxwGTJ'>
					<rect width='20' height='20'/>
				</clipPath>
			</defs>
			<g clipPath='url(#_clipPath_Zhrspac756iaElTj84N8TQKcoThxwGTJ)'>
				<g>
					<path
						d=' M 10 3.421 C 5.614 3.421 1.868 6.149 0.35 9.999 C 1.868 13.851 5.614 16.579 10 16.579 C 14.386 16.579 18.132 13.851 19.65 9.999 C 18.132 6.149 14.386 3.421 10 3.421 Z  M 10 14.386 C 7.579 14.386 5.614 12.421 5.614 9.999 C 5.614 7.579 7.579 5.614 10 5.614 C 12.421 5.614 14.386 7.579 14.386 9.999 C 14.386 12.421 12.421 14.386 10 14.386 Z  M 10 7.368 C 8.544 7.368 7.368 8.544 7.368 9.999 C 7.368 11.456 8.544 12.631 10 12.631 C 11.456 12.631 12.632 11.456 12.632 9.999 C 12.632 8.544 11.456 7.368 10 7.368 Z '
						fill='rgb(66,66,66)'/>
				</g>
			</g>
		</svg>;
	}

	private renderEyeClose(): ReactNode {
		return <svg viewBox='0 0 20 20' width={this.scaledBounds} height={this.scaledBounds}>
			<defs>
				<clipPath id='_clipPath_j0lzZ7p26croY9cF1QGzuyWQm0VstCyU'>
					<rect width='20' height='20'/>
				</clipPath>
			</defs>
			<g clipPath='url(#_clipPath_j0lzZ7p26croY9cF1QGzuyWQm0VstCyU)'>
				<g>
					<path
						d=' M 6.679 3.965 C 7.721 3.612 8.838 3.421 10 3.421 L 10 3.421 C 14.386 3.421 18.132 6.148 19.65 9.999 C 19.027 11.581 18.028 12.973 16.764 14.064 L 14.144 11.44 C 14.301 10.989 14.386 10.504 14.386 9.999 C 14.386 7.578 12.421 5.614 10 5.614 C 9.498 5.614 9.015 5.698 8.565 5.854 L 6.679 3.965 Z  M 5.07 4.668 C 2.934 5.827 1.25 7.716 0.35 9.999 C 1.868 13.851 5.614 16.58 10 16.58 C 11.991 16.58 13.851 16.017 15.429 15.043 L 13.29 12.9 C 12.486 13.811 11.31 14.386 10 14.386 L 10 14.386 C 7.579 14.386 5.614 12.422 5.614 9.999 C 5.614 8.688 6.191 7.51 7.104 6.706 L 5.07 4.668 Z  M 12.047 11.656 L 12.047 11.656 C 11.565 12.252 10.828 12.632 10 12.632 C 8.544 12.632 7.368 11.457 7.368 9.999 C 7.368 9.17 7.75 8.432 8.347 7.95 L 12.047 11.656 Z  M 12.631 9.925 C 12.593 8.53 11.474 7.411 10.079 7.37 L 12.631 9.925 Z '
						fillRule='evenodd' fill='rgb(130,130,130)'/>
					<rect x='8.402' y='-0.813' width='1.636' height='21.627'
					      transform='matrix(0.708,-0.707,0.707,0.708,-4.37,9.438)' fill='rgb(130,130,130)'/>
				</g>
			</g>
		</svg>;
	}

	private get scaledBounds(): string {
		return ((this.props.scale || 1) * 100) + '%';
	}
}
