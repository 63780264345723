import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const LabelBox = styled(FlexBox, {
	paddingTop: 6,
	paddingBottom: 6,
	paddingRight: 12,
	paddingLeft: 12,
	borderRadius: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$background',
	alignItems: 'center',
	textWrap: 'nowrap',
	overflow: 'hidden',
});
