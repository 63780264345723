import {useEffect, useRef, useState} from 'react';
import {useService} from '@esgi/core/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {Modal, useCloseModal, useModal, Title} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {PinSettingService} from './service';
import {Pin} from '@esgi/deprecated/ui-kit/pin';
import styles from './styles.module.less';

interface Props {
	onClose: () => void;
	onSave: () => void;
}

export function PinModal({onClose, onSave}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const service = useService(PinSettingService);

	const confirmRef = useRef<Pin>();
	const confirmContainer = useRef<HTMLDivElement>();

	const [isLoaded, setLoaded] = useState(false);
	const [isEditMode, setEditMode] = useState(false);
	const [isShowConfirmWarn, setShowConfirmWarn] = useState(false);

	const pinValid = useBehaviorSubject(service.pinValid);
	const newPin = useBehaviorSubject(service.newPin);
	const pinsEqual = useBehaviorSubject(service.pinsEqual);
	const confirmPinValid = useBehaviorSubject(service.confirmPinValid);

	const validate = () => {
		if (confirmPinValid) {
			setShowConfirmWarn(!pinsEqual);
		}
	};

	useEffect(() => {
		service.init().subscribe(pin => {
			const pinExist = pin.length === 4;
			setEditMode(!pinExist);
			service.newPin.next(!pinExist);
			setLoaded(true);
		});
	}, []);

	const onSubmit = () => {
		if (pinValid && confirmPinValid) {
			if (pinsEqual) {
				service.save().subscribe(() => {
					onSave();
					handleClose();
				});
			} else {
				setShowConfirmWarn(true);
			}
		}
	};

	return <>
		<ServiceLoader trackingService={service} fullscreen/>
		{isLoaded && <Modal modalManagerRef={modalRef} onCatchError={handleClose}>
			<Modal.Header>
				<Title className={styles.title}> {!newPin && isEditMode ? 'Edit PIN' : 'PIN Number'} </Title>
			</Modal.Header>

			<Modal.Body className={styles.body}>
				{isEditMode
					? <div>
						<div className={styles.rowTitle}>
							<span>Enter four digits for PIN:</span>
						</div>
						<div className={styles.pinContainer}>
							<Pin canShowPin
							     initialValue={service.pin.value}
							     onBlur={() => validate()}
							     onChange={(v) => service.pin.next(v)}
							     onUserCompleteEnter={() => confirmRef.current?.focus()}
							/>
						</div>
						<div className={styles.confirmTitle}>
							<span>Confirm PIN:</span>
						</div>
						<div className={styles.pinContainer} ref={confirmContainer}>
							<Pin ref={confirmRef}
							     canShowPin
							     initialValue={service.confirmPin.value}
							     onBlur={() => validate()}
							     onChange={(v) => service.confirmPin.next(v)}
							     onUserCompleteEnter={() => validate()}
							/>
							{isShowConfirmWarn &&
								<ValidationTooltip element={null}
								                   elementRef={confirmContainer}
								                   placement='right'>
									PIN does not match. Please try again.
								</ValidationTooltip>
							}
						</div>
					</div>
					: <div data-cy='column' className={styles.column}>
						<div className={styles.pinContainer}>
							<Pin initialValue={service.pin.value}
							     canShowPin
							     readonly
							/>
						</div>
						<div className={styles.editButton}>
							<Buttons.Link onClick={() => setEditMode(true)}>Edit PIN</Buttons.Link>
						</div>
					</div>
				}
			</Modal.Body>

			<Modal.Footer className={styles.footer}> {
				isEditMode
					? <>
						<div data-cy='cancel-button'><Buttons.Text onClick={handleClose}>CANCEL</Buttons.Text></div>
						<div data-cy='save-button'><Buttons.Text onClick={onSubmit}>SAVE</Buttons.Text></div>
					</>
					: <div data-cy='ok-button'><Buttons.Text onClick={onSave}>OK</Buttons.Text></div>}
			</Modal.Footer>
		</Modal>
		} </>;
}
