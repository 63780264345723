import './component.less';
import React from 'react';
import ReactDOM from 'react-dom';
import {UserProfile} from '@esgi/main/features/user-profile';
import {Api} from './api/api';
import {Button} from '@esgi/deprecated/elements/buttons/default';

export class State {
	notificationID: number;
	notificationType: string;
	loaded: boolean = false;

	modalStyle: Style;
	arrowStyle: Style;

	userProfileOpened: boolean = false;
	// to webpack
}

export class Props {
	pointToRef: React.RefObject<HTMLElement>;

	top: number;
	left: number;

	arrowTop: number;
	arrowLeft: number;
}

export class Style {
	top: number;
	left: number;
}


export class ModalAlertContainer extends React.Component<Props, State> {
	readonly Api: Api = new Api();
	templates: { [type: string]: JSX.Element; } = {};

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			notificationID: 0,
			modalStyle: {
				top: 0,
				left: 0,
			},
			arrowStyle: {
				top: 0,
				left: 0,
			},
			userProfileOpened: false,
			notificationType: '',
		};

		this.templates.NEW_SCHOOL_YEAR = this.renderDefault();
		this.templates.ROLLOVER_NOTIFICATION = this.renderRollOverNotification();
	}


	componentDidMount(): void {
    	this.Api.init()
			.subscribe(r => {
				if (r.notification && this.props.pointToRef.current) {
					const pointTo = this.props.pointToRef.current.getBoundingClientRect();

					this.setState({
						notificationID: r.notification.notificationID,
						notificationType: r.notification.type,
						loaded: true,
						modalStyle: {
							top: pointTo.top + this.props.top,
							left: pointTo.left + this.props.left,
						},
						arrowStyle: {
							top: this.props.arrowTop,
							left: this.props.arrowLeft,
						},

					});
				}
			});
	}

	dismiss() {
		this.Api.dismiss(this.state.notificationID).subscribe();

		this.setState({notificationID: null});
	}

	render() {
		if (!this.state.loaded || !this.state.notificationID) {
			return <></>;
		}


		return ReactDOM.createPortal(<>
				<div className='rollover-alert'>
					<div className='rollover-modal' style={this.state.modalStyle}>
						<div className='rollover-modal-inner'>

							<div className='warning-icon'>
								<svg width='40' height='40' viewBox='0 0 40 40' fill='none'
									 xmlns='http://www.w3.org/2000/svg'>
									<path d='M0 14.25H16.5L8.25 0L0 14.25ZM9 12H7.5V10.5H9V12ZM9 9H7.5V6H9V9Z'
										  fill='#FEC702'/>
								</svg>
							</div>
							{this.templates[this.state.notificationType]}
						</div>
						<div className='rollover-modal-footer'>
							<Button
								onClick={() => this.dismiss()}
								title='GOT IT'
								className='btn btn-dismiss alert-button-dismiss'
							/>
						</div>

						<div className='rollover-model-arrow' style={this.state.arrowStyle}>
						</div>
					</div>
					<div className='rollover-modal-background'>
					</div>
				</div>
				{this.renderProfile()}
		</>
		, document.body);
	}

	private renderDefault() {
		return <div className='alert-text-container'>
			<span className='modal-alert-header'>You are Viewing the 2024-25 School Year.</span><br/>
			To view a different year, select it from the dropdown. <a
			href='https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Years'
			className='external-link' target='_blank' rel='noreferrer'> Learn more</a>.
		</div>;
	}

	private renderRollOverNotification() {
		return <div className='alert-text-container'>
			<span className='modal-alert-header'>The new 2024-2025 school year is now available!</span><br/><br/>
			<ul>
				<li>All subject tabs and tests have been copied from the previous school year to the new school year.
				</li>
				<li>Continue your end-of-year testing in the current school year, and make any necessary updates to subject tabs in the new school year.
				</li>
				<li><span className='bold italic'>Please ensure you are in the correct school year when making changes to your subject tabs.</span>
				</li>
			</ul>
			Interested in <span className='bold'>Pre-Assessing?</span>
			<a href='https://support.esgisoftware.com/hc/en-us/articles/209158786-Pre-Assess-Account-FAQ'
			   className='external-link' target='_blank' rel='noreferrer'> Learn more</a>.
		</div>;
	}


	renderProfile() {
		if (!this.state.userProfileOpened) {
			return null;
		}

		return <UserProfile
			close={() => this.setState({userProfileOpened: false})}
		/>;
	}

	private openProfile() {
		this.setState({userProfileOpened: true});
	}

}
