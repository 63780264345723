import {BuilderOptions} from '@esgi/api';

const configMap: Map<string, BuilderOptions> = new Map<
	string,
	BuilderOptions
>();

export function registerClientConfig(
	clientName: string,
	config: BuilderOptions,
) {
	configMap.set(clientName, config);
}

export function getClientConfig(
	clientName: string,
): BuilderOptions | undefined {
	return configMap.get(clientName);
}
