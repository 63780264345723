import React, {useMemo} from 'react';
import styles from './styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Checkbox} from '@esgillc/ui-kit/control';
import {DatePicker} from '@esgi/deprecated/elements';
import moment from 'moment';
import {TestHistoryRootService} from '../../../../services/test-history-root-service';

interface Props {
	service: TestHistoryRootService
}

export function ChartFilter({service}: Props) {
	const selectedStudent = useBehaviorSubject(service.testHistoryService.selectedStudent);
	const selectedTest = useBehaviorSubject(service.testHistoryService.selectedTest);
	const includeOnlyTestedStudents = useBehaviorSubject(service.testHistoryService.includeOnlyTestedStudents);
	const reportData = useBehaviorSubject(service.testHistoryService.reportData);
	const questionsCount = reportData?.questionsCount;
	const dateFrom = useBehaviorSubject(service.testHistoryService.dateFrom);
	const dateTo = useBehaviorSubject(service.testHistoryService.dateTo);
	const zoom = useBehaviorSubject(service.testHistoryService.zoom);
	const studentDisplayName = service.testHistoryService.getStudentDisplayName();
	const dateFromMemoized = useMemo(() => moment(dateFrom), [dateFrom]);
	const dateToMemoized = useMemo(() => moment(dateTo), [dateTo]);

	return <div className={styles.chartFilterContainer}>
		<div>
			<div className={styles.chartHeader}>
				<div className={styles.studentName}>{studentDisplayName}</div>
				<div className={join(styles.studentName, styles.right)}>{studentDisplayName}</div>
				<div className={styles.testName}>{selectedTest[0] && selectedTest[0].name}</div>
				<div className={styles.questionMark}>
					<OnHoverTooltip message='Help'><a target='_blank'
					                                  href='https://support.esgisoftware.com/hc/en-us/articles/209158346'
					                                  title='Help'
					                                  rel='noreferrer'><i
						className='fa fa-question-circle'></i></a></OnHoverTooltip>
				</div>
			</div>
			<div className={styles.chartFilter}>
				{selectedStudent[0] && selectedStudent[0].studentID === 0 && <>
					<div className={styles.unityFilters}>
						<Checkbox checked={includeOnlyTestedStudents} className={styles.checkbox}
						          onChange={(e) => service.testHistoryService.includeOnlyTestedStudents.next(e.target.checked)}
						          id='includeOnlyTestedStudents'>
							Include only students who have been tested
						</Checkbox>
					</div>
				</>}
				<div className={styles.commonFilters}>
					<div className={styles.totalPossible}>
						<div className={styles.totalPossibleTitle}>Total Possible</div>
						<div className={styles.totalPossibleValue}>{questionsCount}</div>
					</div>
					<div className={styles.chartZoom}>
						<div className={styles.chartZoomTitle}>Zoom:</div>
						<div className={join('1m-zoom-option', zoom === 0 && styles.selected)}
						     onClick={() => service.testHistoryService.zoom.next(0)}>1w
						</div>
						<div className={join('1m-zoom-option', zoom === 1 && styles.selected)}
						     onClick={() => service.testHistoryService.zoom.next(1)}>1m
						</div>
						<div className={join('cmp-zoom-option', zoom === 2 && styles.selected)}
						     onClick={() => service.testHistoryService.zoom.next(2)}>Current
							Marking Period
						</div>
						<div className={join('sytd-zoom-option', zoom === 3 && styles.selected)}
						     onClick={() => service.testHistoryService.zoom.next(3)}>School
							YTD
						</div>
					</div>
					<div className={styles.chartDatesContainer}>
						<div className={styles.chartDatepickerTitle}>Test Sessions from:</div>
						<DatePicker onSelect={(v) => service.testHistoryService.dateFrom.next(v.toDate())}
						            id='dateFrom' date={dateFromMemoized}/>
						<div className={styles.chartDatepickerTitle}>to:</div>
						<DatePicker onSelect={(v) => service.testHistoryService.dateTo.next(v.toDate())}
						            id='dateTo' date={dateToMemoized}/>
					</div>
				</div>
			</div>
		</div>
	</div>;
}
