import {SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	minHeight: 40,
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 12,
	paddingRight: 8,
	borderRadius: 6,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',

	'&:not(last-child)': {
		marginBottom: 6,
	},

	variants: {
		selected: {
			true: {},
		},

		outlined: {
			true: {},
		},
	},

	compoundVariants: [
		{
			selected: true,
			outlined: true,
			css: {
				borderColor: '$primaryMuted',

				'&:hover': {
					borderColor: '$primaryMuted',
				},
			},
		},
	],
});
