import {noop} from 'underscore';
import React, {useMemo} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {userStorage} from '@esgi/core/authentication';
import {CloseIcon, Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {AudioButton, getTextStyles, getUrl, Question, QuestionTemplate, TextSizeBreakpoints} from '@esgi/selfassess';
import {StyledDirectionsText, StyledQuestionHeaderContainer, StyledQuestionsWrapper} from './index.styled';
import styles from './styles.module.less';

interface Props {
	question: Question;
	onClose: () => void;
}

export function QuestionPreview({question, onClose}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	const {
		fontSize,
		lineHeight,
	} = getTextStyles(question.directionsText, TextSizeBreakpoints.xs);

	const bucket = useMemo(() => {
		return userStorage.get().awsEnvBucket;
	}, []);

	const audioUrl = useMemo(() => {
		if (question.audioDirectionUrl) {
			return getUrl(bucket, question.audioDirectionUrl);
		}

		return '';
	}, [bucket, question.audioDirectionUrl]);

	return (
		<Modal containerClassName={styles.modalWrapper} className={styles.modal} modalManagerRef={modalRef}>
			<Modal.Header className={join(styles.modalSection, styles.modalHeader)}>
				<StyledQuestionHeaderContainer>
					<AudioButton id={question.id} audioUrl={audioUrl} />
					{question.directionsText && (
						<StyledDirectionsText
							style={{fontSize, lineHeight: lineHeight + 'px'}}
							dangerouslySetInnerHTML={{__html: question.directionsText}}
						/>
					)}
				</StyledQuestionHeaderContainer>
				<CloseIcon color='#FFFFFF' className={styles.modalClose} onClick={handleClose} />
			</Modal.Header>
			<Modal.Body className={join(styles.modalSection, styles.modalBody)}>
				<StyledQuestionsWrapper>
					<QuestionTemplate
						isPreviewMode
						awsBucket={bucket}
						question={question}
						selectedOptionID={[]}
						onSelectOption={noop}
						maxOptionsSelected={true}
						questionIndex={0}
					/>
				</StyledQuestionsWrapper>
			</Modal.Body>
		</Modal>
	);
}