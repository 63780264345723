import {Text} from '@esgi/ui/typography';
import {Container, TextContainer} from './styled';

export function NoStudents() {
	return <Container>
		<TextContainer>
			<Text size='small' color='neutral40'>
				No students
			</Text>
		</TextContainer>
	</Container>;
}