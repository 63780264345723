import React, {createRef} from 'react';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {map, Observable, Subscription} from 'rxjs';
import {ZendeskApi, ZendeskFormResponse} from './api';
import {ContactUsErrorAlert} from './components/contact-us-error-alert';
import {createForm, ContactUsFormControls, filterDynamicFields} from './form-generator';
import {Form, FormControl, FormGroup} from '@esgillc/ui-kit/form';
import {CloseIcon, Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {Field, FieldType} from './field';

import styles from './contact-us.module.less';

class State {
	loading: boolean = true;
	initialized: boolean = false;
	sending: boolean = false;
	response: ZendeskFormResponse;
	alertMessage: string = '';
	hasError: boolean = false;
}

class Props {
	title?: string;
	token?: string;
	onLoaded?: () => void;
	ticketSubmitted: () => void;
	onClose: () => any;
}

export class ContactUs extends React.Component<Props, State> {
	public state = new State();
	private readonly modalManagerRef: ModalManagerRefObject = createRef();
	private api: ZendeskApi;
	private loaderRef: React.RefObject<HTMLDivElement> = createRef();

	private form: FormGroup<ContactUsFormControls, any>;

	constructor(props) {
		super(props);
		this.api = new ZendeskApi();
	}

	componentDidMount() {
		//TODO Refactor init parameter.
		return this.api.init(true).subscribe(
			(formModel: ZendeskFormResponse) => {
				this.props.onLoaded && this.props.onLoaded();
				this.form = createForm(formModel);
				this.setState({loading: false, initialized: true, response: formModel});
			},
			() => {
				this.props.onLoaded && this.props.onLoaded();
				this.setState({loading: false, initialized: true});
			});
	}

	public render() {
		return <>
			<Loader show={this.state.loading || !this.state.initialized} fullscreen/>
			{this.renderModal()}
			{this.renderAlert()}
			{this.renderErrorAlert()}
		</>;
	}

	renderModal() {
		if (!this.state.initialized) {
			return null;
		}
		return <Modal modalManagerRef={this.modalManagerRef}
		              className='white-header'
		              onCatchError={() => this.props.onClose()}>
			<Modal.Header>
				<Title>
					{this.props.title || 'Contact Us'}
				</Title>
				<CloseIcon onClick={() => this.modalManagerRef.current.close(() => this.props.onClose())}/>
			</Modal.Header>
			<Modal.Body>
				<div className={styles.contactUsForm} ref={this.loaderRef}>
					<div id='dropbox_content'>
						<Form controller={this.form}>
							<fieldset className='body'>
								{this.renderCommonField(this.form?.controls.questionType, 'Question type', FieldType.select, true)}
								{this.renderCommonField(this.form?.controls.topic, 'Topic', FieldType.input, true)}
								{this.renderCommonField(this.form?.controls.message, 'Message', FieldType.textarea, true)}
								{this.renderCommonField(this.form?.controls.name, 'Name', FieldType.input, true)}
								{this.renderCommonField(this.form?.controls.email, 'Email', FieldType.input, true)}
								{this.renderCommonField(this.form?.controls.phone, 'Phone', FieldType.input, false)}
								{this.renderDynamicFields()}
							</fieldset>
						</Form>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className='btn' onClick={() => this.props.onClose()}>Cancel</button>
				<button className='btn btn-primary' disabled={this.state.sending} onClick={() => this.sendForm()}>
					{this.state.sending ? <i className='fa fa-circle-o-notch fa-spin'/> : ''} Submit
				</button>
			</Modal.Footer>
		</Modal>;
	}

	private renderAlert() {
		if (this.state.alertMessage) {
			return <Alert>
				<Alert.Body>
					{this.state.alertMessage}
				</Alert.Body>
				<Alert.Footer>
					<Buttons.Text onClick={() => this.props.onClose()}>
						OK
					</Buttons.Text>
				</Alert.Footer>
			</Alert>;
		}
	}

	private renderErrorAlert() {
		if (this.state.hasError) {
			return <ContactUsErrorAlert onClose={this.props.onClose}/>
		}
	}

	private renderDynamicFields() {
		const fields = filterDynamicFields(this.state.response.fields);
		return fields.map(f => {
			const control = this.form.controls[f.id] as FormControl;
			if (control) {
				return <Field key={f.title}
				              control={control}
				              options={f.options}
				              title={f.title}
				              type={f.type.toLowerCase() === 'dropdown' ? FieldType.select : FieldType.input}
				              isRequired={f.isRequired}/>;
			}
		});
	}

	private renderCommonField(control: FormControl, title: string, type: FieldType, isRequired: boolean) {
		const field = this.state.response.fields.find(x => x.title.toLowerCase() === title.toLowerCase());
		return <Field key={title}
		              control={control}
		              options={field?.options ?? []}
		              title={title}
		              type={type}
		              isRequired={isRequired}/>;
	}

	private sendForm(): Subscription {
		return this.validate().subscribe(res => {
			if (res) {
				const requestFields = this.state.response.fields.map(f => {
					let value;

					if (f.title.toLowerCase() === 'phone') {
						value = this.form.controls.phone.value || f.value;
					} else if (f.title.toLowerCase() === 'question type') {
						value = this.form.controls.questionType.value[0] || f.value;
					} else {
						value = this.form.controls[f.id]?.value || f.value;
					}

					return {
						...f,
						value,
					};
				});

				const name = this.form.controls.name.value;
				const topic = this.form.controls.topic.value;
				const message = this.form.controls.message.value;
				const email = this.form.controls.email.value;

				return this.api.send(requestFields, topic, message, name, email?.trim())
					.subscribe((msg) => {
						this.setState({alertMessage: msg});
					}, () => {
						this.setState({hasError: true});
					});
			}
		});
	}

	public validate(): Observable<boolean> {
		return this.form.validate().pipe(map(v => v.valid));
	}
}
