import {TestType} from '@esgi/core/enums';
import {TestResultModel} from './types';

export function formattedDate(testResult: TestResultModel | undefined) {
	if (testResult?.lastTestDate) {
		const date = new Date(testResult.lastTestDate);
		const month = (date.getMonth() + 1);
		const monthStr = (month < 10 ? '0' : '') + month;
		const day = date.getDate();
		const dayStr = (day < 10 ? '0' : '') + day;
		return monthStr + '-' + dayStr + '-' + (date.getFullYear() % 1000);
	}
}

export function getPieChartOptions({
	testType,
	correctPercent,
	testResultsCorrectVerbiage,
	testResultsIncorrectVerbiage,
	printInColor,
}: {
	testType: TestType;
	correctPercent: number;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	printInColor: boolean;
}) {
	const tested = correctPercent != null;
	const correctColor = printInColor ? '#00BF96' : '#7B7B7B';

	if (correctPercent == null) {
		correctPercent = 0;
	}

	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			backgroundColor: null,
			type: 'pie',
			width: 80,
			height: 80,
			events: {
				load: (e) => {
					if (e.target.container.parentNode.style.overflow === 'hidden') {
						e.target.container.parentNode.style.overflow = 'initial';
					}
				},
			},
		},
		title: {
			enabled: false,
			text: '',
		},
		legend: {
			enabled: false,
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			enabled: true,
			headerFormat: '',
			pointFormat: testType.toString() === TestType[TestType.YN]
				? '<span><span style="font-weight: bold text-overflow: unset">{point.y}%</span> {point.name}</span>'
				: '<span style="font-weight: bold">{point.y}%</span>',
		},
		plotOptions: {
			pie: {
				borderColor: '#D8D8D8',
				size: 73,
				colors: ['#00BF96', '#FFFFFF'],
				allowPointSelect: false,
				cursor: 'pointer',
				slicedOffset: 0,
				dataLabels: {
					enabled: false,
				},
			},
			series: {
				animation: false,
			},
		},
		series: [{
			name: 'Student Name',
			colorByPoint: true,
			borderColor: '#D8D8D8',
			data: [{
				name: testResultsCorrectVerbiage,
				y: correctPercent,
				selected: true,
				color: correctColor,
			}, {
				name: testResultsIncorrectVerbiage,
				y: 100 - correctPercent,
				color: '#FFF',
			}],
		}],
	};

	if (correctPercent === 0) {
		if (tested) {
			options.series[0].data.splice(0, 1);
			options.tooltip.pointFormat = `<span><span style="font-weight: bold">0%</span>${testResultsCorrectVerbiage}</span>`;
		} else {
			options.series[0].data = [{
				name: '',
				color: '#EEE',
				selected: true,
				y: 100,
			}];
			options.tooltip.enabled = false;
		}
	}

	if (correctPercent === 100) {
		options.series[0].data.splice(1, 1);
	}

	return options;
}
