import {Drawer} from '@esgi/main/features/teacher/home';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = {
	isUnsavedChangesConfirmation: boolean;
	onClose: VoidFunction;
};

export function UnsavedChangesAlert({isUnsavedChangesConfirmation, onClose}: Props) {
	const forceDrawerClose = Drawer.useForceDrawerClose();

	if (isUnsavedChangesConfirmation) {
		return <UnsavedChangesConfirmation onCloseAnyway={forceDrawerClose} onClose={onClose} />;
	}

	return null;
}
