import {BaseButton} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	paddingTop: 5,
	paddingBottom: 5,
	paddingLeft: 6,
	paddingRight: 6,
	gap: 2,
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$vivid',
	gridAutoFlow: 'column',
	height: 'max-content',
});

export const ScoreButton = styled(BaseButton, {
	width: 12,
	height: 12,
	borderRadius: 2,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',

	variants: {
		active: {
			true: {
				borderColor: '$primary72',
				backgroundColor: '$primary92',
			},
		},

		hovered: {
			true: {
				borderColor: '$secondaryMuted',
				backgroundColor: '$secondaryBackground',
			},
		},
	},
});
