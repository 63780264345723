import {TestsControl} from './components/tests-control';
import {PreviewDialog} from './components/preview-dialog';

export * from './services';
export * from './hooks';
export * from './types';

export const SightWords = {
	TestsControl,
	PreviewDialog,
};
