import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Hint, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {maxLengths} from '../../constants';
import styles from './styles.module.less';

interface Props {
	control: FormControl;
}

export function Name({control}: Props) {

	return <div data-cy='name' className={styles.row}>
		<div data-cy='name-title' className={styles.requiredTitleContainer}>
			<span data-cy='required-star' className={styles.requiredStar}>*</span>
			<label className={styles.requiredTitle}>Test Name:</label>
		</div>

		<FormElement className={styles.nameContainer} control={control}>
			<Input className={styles.nameInput} placeholder={'Enter Test Name*'} maxLength={maxLengths.name}/>
			<OnErrorTooltip showOnError='required' placement='right'>Name is required</OnErrorTooltip>
			<Hint className={styles.limitCounter}>
				{props => <span
					data-cy='limit-counter'>{maxLengths.name - (props.value.length | 0)} characters left ({maxLengths.name} max)</span>}
			</Hint>
		</FormElement>
	</div>;
}

