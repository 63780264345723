import {Renderable} from '@esgi/deprecated/knockout';
import './continueDialog.less';

export class ContinueDialog extends Renderable {
	public template = () => {
		return <div className='dialog-modal'>
			<div className='inner-modal'>
				<div className='title-container'>
					<div className='title'><b>Thanks for creating your free trial!</b></div>
				</div>
				<div>
					Continue finishing setting up your account, or come back later and login.
				</div>
				<div className='dialog-actions'>
					<a href='#' data-bind='click:finishLater'>FINISH LATER</a>
					<a href='#' data-bind='click:continueClicked'>CONTINUE</a>
				</div>
			</div>
		</div>;
	};

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		window.scrollTo(0, 0);
		return super.afterRender(rootElement);
	}

	events = {
		finishLater: (callback) => {
			$(this).on('finishLater', callback);
		},
		continue: (callback) => {
			$(this).on('continue', callback);
		},
	};
	finishLater = () => {
		$(this).trigger('finishLater');
	};
	continueClicked = () => {
		$(this).trigger('continue');
	};
}
