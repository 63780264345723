import {useState} from 'react';
import {WritingPractice as WritingPracticeUI, useWritingPractice, WritingPracticeContext} from '@esgi/main/features/teacher/writing-practice';
import {Activities, ExpandablePanel} from '@esgi/main/kits/activities';
import {Text} from '@esgi/ui/typography';
import {Page, LeftSidebar, Content, RightSidebar} from '../layout/index.styled';
import {SetupText} from './index.styled';
import {useStream, useBehaviorSubject} from '@esgi/ui';

export default function () {
	const {service} = useWritingPractice();
	const valid = useStream(service.valid$, false);
	const busy = useStream(service.busy$, false);
	const printSettings = useBehaviorSubject(service.printSettings$);
	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const studentList = useBehaviorSubject(service.studentService.students$);
	const loadedStudents = useBehaviorSubject(service.studentService.loaded$);
	const totalPages = useStream(service.totalPages$, 0);
	const students = useBehaviorSubject(service.students$);
	const [preview, setPreview] = useState(false);

	return (
		<WritingPracticeContext.Provider value={service}>
			<Page>
				<LeftSidebar dataCy='left-sidebar'>
					<Activities.StudentsControl
						skeleton={!loadedStudents}
						classes={classes}
						groups={groups}
						students={studentList}
						onChange={(value) => service.setStudents(value)}
					/>
				</LeftSidebar>
				<Content>
					<Activities.Header
						counterTitle='writing practice'
						studentCounter={students.length}
						student={service.studentService.get(students.length && students[0].studentId)}
					/>
					<SetupText>
						<Text size='small'>No additional setup required</Text>
					</SetupText>
				</Content>
				<RightSidebar>
					<Activities.DownloadPanel
						disabled={!valid || !printSettings || busy}
						disabledPreviewTooltip='Select a test/student to preview templates'
						activePreviewTooltip='Click to preview templates'
						disabledDownloadTooltip='Please select a test/student to generate templates'
						onPreview={() => {
							service.onPreview();
							setPreview(true);
						}}
						onDownload={() => service.onDownload()}
					/>
					<ExpandablePanel>
						<WritingPracticeUI.NameFormatControl onChange={(value) => service.setFormat(value)}/>
						<Activities.FontControl onChange={(value) => service.setFont(value)}/>
						<Activities.CopiesControl onChange={(value) => service.setCopies(value)}/>
						<Activities.FileTypeControl
							visibleToggler={false}
							onChange={(value) => service.setFileType(value)}
						>
							<Activities.CopiesPanel>{totalPages}</Activities.CopiesPanel>
						</Activities.FileTypeControl>
					</ExpandablePanel>
				</RightSidebar>
			</Page>
			{preview &&
				<WritingPracticeUI.PreviewDialog
					onClose={() => setPreview(false)}
					onDownload={() => service.onDownload()}
				/>
			}
		</WritingPracticeContext.Provider>
	);
}