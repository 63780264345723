import React from 'react';
import {SelectField as BaseSelectField, SelectFieldProps} from '../../controls/select/components/select-field';
import {ElementStatus, useFormElementContext} from '@esgi/ui/form';

export function SelectField({
	children,
	dataCy = 'ui-kit-form-select-field',
	...props
}: Omit<SelectFieldProps, 'error' | 'disabled'>) {

	const {status} = useFormElementContext({array: true}, BaseSelectField);
	const commonProps = {
		disabled: status === ElementStatus.disabled,
		error: status === ElementStatus.invalid,
		dataCy,
		...props,
	};

	return <BaseSelectField {...commonProps}>
		{children}
	</BaseSelectField>;
}
