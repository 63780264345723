import * as SelectRadix from '@radix-ui/react-select';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps, Skeletonable} from '../../../../types';
import {ThemeProvider, styled} from '@esgi/ui/theme';
import {SelectContentProps as SelectContentRadixProps} from '@radix-ui/react-select';
import {Property} from '@stitches/react/types/css';

type SelectContentProps = ComponentPropsWithoutRef<'div'> &
	BaseComponentProps & {
		/** If true, children will not be wrapped by the Viewport component. */
		withoutViewport?: boolean;

		/**
		 * CSS property sets the z-order of a positioned element.
		 * @default 700
		 */
		zIndex?: Property.ZIndex;
	} & Skeletonable &
	Omit<SelectContentRadixProps, 'position' | 'avoidCollisions'>;

export const SelectContent = forwardRef<HTMLDivElement, SelectContentProps>(
	({dataCy, children, skeleton, withoutViewport = false, css = {}, zIndex = 700, ...props}, forwaredRef) => (
		<SelectRadix.Portal>
			<ThemeProvider>
				<Content
					data-cy={dataCy ?? 'ui-kit-select-content'}
					position='popper'
					avoidCollisions
					css={{...css, zIndex}}
					ref={forwaredRef}
					{...props}
				>
					{withoutViewport ? (
						children
					) : (
						<SelectRadix.Viewport data-cy={dataCy ? `${dataCy}-viewport` : 'ui-kit-select-content-viewport'}>
							{children}
						</SelectRadix.Viewport>
					)}
				</Content>
			</ThemeProvider>
		</SelectRadix.Portal>
	),
);

const Content = styled(SelectRadix.Content, {
	width: 'var(--radix-select-trigger-width)',
	maxHeight: 'var(--radix-select-content-available-height)',
	borderBottomLeftRadius: 6,
	borderBottomRightRadius: 6,
	boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',
});
