import {Renderable} from '@esgi/deprecated/knockout';
import './styles.less';

export class Step1 extends Renderable {
	goTo = null;

	template = () => (
		<div className='step1-content'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-6'>
						<h1>
							Get A <strong>Quote</strong>
						</h1>
					</div>
					<div className='col-md-3'></div>
				</div>
			</div>
			<div className='step1'>
				<div className='container'>
					<div className='center'>
						<div className='row'>
							<h2>Are you a new Customer or Returning Customer?</h2>
						</div>
						<div className='row'>
							<div className='col-md-6'>
								<a
									className='btn btn-extra-lg btn-primary-action'
									data-bind='click: view.new'
								>
									New
								</a>
							</div>
							<div className='col-md-6'>
								<a
									className='btn btn-extra-lg btn-primary-action'
									data-bind='click: view.returning'
								>
									Returning
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	view = {
		new: () => {
			$(this).trigger('next');
		},
		returning: () => {
			window.location.href = '/forms/request-a-quote';
		},
	};

	events = {
		next: (callback: any) => {
			$(this).on('next', callback);
		},
	};
}
