import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {AsyncHighchartsReact} from '@esgi/deprecated/highcharts';
import {TestType} from '@esgi/core/enums';
import {ChartBlockModel, OnRenderedParams, TestModel} from 'shared/modules/reports/pie-chart/service/models';
import NotTested from 'shared/modules/reports/pie-chart/components/cards/not-tested';

import styles from '../cards.module.less';

export interface CombineCardProps {
	totalStudentCount: number;
	printInColor: boolean;
	uniqueID: string;
	chartBlockModel: ChartBlockModel;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	test: TestModel;
	onCardRendered?: (params: OnRenderedParams) => void;
	onChartRendered?: () => void;
}

export default function CombineCard ({
	test,
	onCardRendered,
	onChartRendered,
	chartBlockModel,
	testResultsIncorrectVerbiage,
	testResultsCorrectVerbiage,
	printInColor,
	totalStudentCount,
}: CombineCardProps) {
	const cardRef = useRef(null);

	const isNotTested = chartBlockModel.combinedTestResult.studentsTested === 0;

	const renderChart = useCallback(() => {
		if (!chartBlockModel.correctPercent) {
			chartBlockModel.pieChartOptions.series[0].data[0].color = '#FFF';
		} else {
			chartBlockModel.pieChartOptions.series[0].data[0].color = printInColor ? '#00BF96' : '#7B7B7B';
		}

		if(isNotTested) {
			return <NotTested/>;
		} else {
			return (
				<AsyncHighchartsReact
					chartRendered={onChartRendered}
					options={chartBlockModel.pieChartOptions}
					className={styles.chartContainer}
					showLoader
				/>
			);
		}
	}, [chartBlockModel.pieChartOptions, isNotTested, printInColor]);

	const scoreTitle= useMemo(() => {
		if (test.type.toString() === TestType[TestType.YN]){
			return testResultsCorrectVerbiage + ' Answers';
		}

		if (test.type.toString() === TestType[TestType.Score]){
			return 'Score';
		}

		if (test.type.toString() === TestType[TestType.Rubric]){
			return 'Avg. Score';
		}
	}, [test.type]);

	const renderLegend = useCallback(() => {
		return (
			<div className={styles.legend}>
				<div className={styles.correct}>
					<div
						className={styles.percents + (printInColor ? ` ${styles.colored}` : '')}>{chartBlockModel.correctPercent}%
					</div>
					<div className={styles.title}>{testResultsCorrectVerbiage.toLowerCase()}</div>
				</div>
				<div className={styles.incorrect}>
					<div className={styles.percents}>{100 - chartBlockModel.correctPercent}%</div>
					<div className={styles.title}>{testResultsIncorrectVerbiage.toLowerCase()}</div>
				</div>
			</div>
		);
	}, [printInColor, testResultsCorrectVerbiage, testResultsIncorrectVerbiage, chartBlockModel.correctPercent]);

	useEffect(() => {
		const resultDataHeight = 120;
		const margin = 20;
		const bounds = cardRef.current.getBoundingClientRect();

		onCardRendered({
			width: bounds.width + margin,
			height: bounds.height + resultDataHeight + margin,
		});
	}, [onCardRendered]);

	const withLegend = chartBlockModel.combinedTestResult.studentsTested > 0;

	return (
		<div className={styles.result}>
			<div data-cy='test-title' className={styles.testName} ref={cardRef}>{test?.name}</div>
			<div className={styles.resultData}>
				<div className={styles.chart}>
					<div data-cy='high-chart'>{renderChart()}</div>
					{withLegend && renderLegend()}
				</div>
				<div className={styles.info}>
					{withLegend &&
						<>
							<div>
								<div className={styles.infoTitle}>Students Tested</div>
								<div data-cy='students-tested'>{chartBlockModel.combinedTestResult.studentsTested}/{totalStudentCount}</div>
							</div>
							<div>
								<div className={styles.infoTitle}>{scoreTitle}</div>
								<div data-cy='correct-answers' className={styles.correctAnswersResult}>
									{chartBlockModel.combinedTestResult.correctAnswers}/{chartBlockModel.combinedMaxScore}
								</div>
							</div>
						</>
					}
					{chartBlockModel.combinedTestResult.studentsTested === 0 &&
						<div>
							Not tested yet
						</div>
					}
				</div>
			</div>
		</div>
	);
}
