import {useEffect, useState} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {IEPProgressReportService} from './services';
import {TestRequest, Step} from './types';
import {getGroupAndStudent} from './utils';

interface Props {
	service: IEPProgressReportService;
	studentId?: number;
	subjectId?: number;
	selected?: number[];
}

export const useIEPReport = ({service, ...props}: Props) => {
	const tests = useBehaviorSubject(service.rows$);
	const [state, setState] = useState<TestRequest>({
		testID: 0,
		studentId: 0,
		subjectId: 0,
		subjectType: null,
	});
	const [filter, setFilter] = useState(null);
	const [step, setStep] = useState(Step.Selector);
	const [initialyLoaded, setInitialyLoaded] = useState(false);
	useEffect(() => {
		service.settings().subscribe({
			next: ({groups, subjects, showBaseline}) => {
				const {studentId, subjectId} = props;
				let student = groups[0].students[0];
				let subject = subjects[0];
				if (studentId) {
					const result = getGroupAndStudent(studentId, groups);
					if (result) {
						student = result.student;
					}
				}
				if (subjectId) {
					const exist = subjects.find(({id}) => id === subjectId);
					if (exist) {
						subject = exist;
					}
				}
				setFilter({student, subject, showBaseline});
				setState({
					studentId: student.id,
					subjectId: subject.id,
					subjectType: subject.subjectType,
				});
				setInitialyLoaded(true);
			},
		});
		if (props.selected) {
			props.selected.forEach((id) => service.select(id, true));
		}
		return () => service.destroy();
	}, []);

	useEffect(() => {
		if (!state.subjectId || !state.studentId) {
			return;
		}
		service.tests(state).subscribe();
	}, [state]);

	useEffect(() => {
		if (!props.selected) {
			tests.forEach(({id, iepGoal}) => {
				iepGoal && service.select(id, true);
			});
		}
	}, [tests]);

	return {
		state,
		setState,
		filter,
		setFilter,
		step,
		setStep, initialyLoaded,
	};
};
