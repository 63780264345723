import React from 'react';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';

export class Props {
	manageMode: boolean;
	multiplyDeleteClicked: () => any;
	multiplyMoveClicked: () => any;
	close: () => any;
}

export class BottomBar extends React.PureComponent<Props> {
	render(): JSX.Element | false | null {
		return <div className={'bottom-bar' + (this.props.manageMode ? '' : ' hidden-bar')}>
			<div className='manage-panel'>
				<Transparent
					onClick={() => this.props.multiplyMoveClicked()}
					title='MOVE TO'
				/>
				<Transparent
					onClick={() => this.props.multiplyDeleteClicked()}
					title='REMOVE'
				/>
			</div>
			<a className='close-btn' onClick={() => this.props.close()}>
				<i className='fa fa-times fa-lg'/>
			</a>
		</div>;
	}
}