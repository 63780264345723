export function AddStudentsTemplate() {
	return <div className='add-students' data-bind='var: {step: $data}'>
		<table className='table students'>
			<tbody data-cy='add-students-table'>
			<tr data-cy='headers-row'>
				<th></th>
				<th data-cy='current-limit-column-header'>Current Limit</th>
				<th data-cy='new-limit-column-header'>
					<div>
						New Limit*
						<div>
							<small>(max 50)</small>
						</div>
					</div>

				</th>
				<th data-cy='cost-column-header'>Cost</th>
			</tr>

			<tr data-cy='add-students-row'>
				<td data-cy='student-limit-label'>Student Limit</td>
				<td data-bind='text: currentStudentLimit' data-cy='current-student-limit'></td>
				<td style={{width: '160px'}} data-cy='new-student-limit'>
					<ko data-bind='with: students'>
						<div className='form-group promo' data-bind='afterRender: true, visible: visible'>
							<input id='students' name='students' type='number' min='0' max='50' className='form-control'
							       data-bind='textInput: value, enable: step.allowAddExtraStudents, event: { keypress: step.studentsKeyDown }'
							       style={{width: '100px', display: 'inline-block'}}/>
						</div>
					</ko>
				</td>
				<td className='expiration-cell' data-cy='limit-change-cost'>$<span data-bind='text: price'></span></td>
			</tr>
			</tbody>
		</table>
		<div className='clearfix comment' data-bind='if: allowAddExtraStudents' data-cy='comment-can-add-students'>
			* Licenses include up to 35 students. Additional students are $<span
			data-bind='text: ratePerStudentFullYear'></span> per student per full year<br/>
			* A maximum of 15 additional students may be added<br/><br/>
			* New extra students <span data-bind='text: сurrentNewExtraStudents'></span><br/>
			<span style={{marginLeft: '10px'}}>period: </span><span data-bind='text: datesForNewStudents'></span> (<span data-bind='text: monthsForNewStudents'></span> months)<br/>
			<span style={{marginLeft: '10px'}}>price: </span>$<span data-bind='text: ratePerNewStudent'></span> per student<br/><br/>
			* Current extra students <span data-bind='text: сurrentExtraStudents'></span><br />
			<span style={{marginLeft: '10px'}}>period: </span><span data-bind='text: datesForCurrentStudents'></span> (<span data-bind='text: monthsForCurrentStudents'></span> months)<br/>
			<span style={{marginLeft: '10px'}}>price: </span>$<span data-bind='text: ratePerCurrentStudent'></span> per student<br/><br />
			* Have questions? Contact <a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a><br />
		</div>
		<div className='clearfix comment' data-bind='ifnot: allowAddExtraStudents' data-cy='comment-cannot-add-students'>
			* Sorry, your account type does not allow the addition of extra students.<br/>
			Please contact Customer Support <a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a> for
			purchasing options.<br/>
		</div>
		<div className='clearfix' data-cy='total-cost'>
			<h3 className='pull-right'>Total: $<span data-bind='text: price'></span></h3>
		</div>
		<div className='clearfix' style={{marginTop: '15px'}}>
			<button className='btn btn-cancel pull-left' data-bind='click: view.cancel' data-cy='cancel-button'>Cancel</button>
			<button className='btn btn-warning pull-right'
			        data-bind="click: view.continue, css: {'disabled': !nextButtonEnabled }" data-cy='add-students-button'>Add Students
			</button>
		</div>
	</div>;
}
