import {ConflictExistingStudentModel, ConflictStudentModel} from 'shared/modules/student-roster-upload-tool/types';

export const parseStudentName = (name: string) => {
	const names = name.split(' ');
	const firstName = names[0];
	let lastName = '';
	for (let i = 1; i < names.length; i++) {
		const value = names[i];
		if (value !== '') {
			lastName += ' ' + value;
		}
	}
	return [firstName.trim(), lastName.trim()];
};

export function getUploadedStudent(students: ConflictStudentModel[], studentID: number): ConflictStudentModel {
	return students.filter(x => x.existingStudents.some(s => s.id === studentID))[0];
}

export function getUpdatedStudent(student: ConflictStudentModel, studentID: number): ConflictExistingStudentModel {
	return student.existingStudents.filter(x => x.id === studentID)[0];
}
