import React, {createRef} from 'react';
import {
  CloseIcon,
  Modal,
  ModalBody,
  ModalFooter, ModalHeader,
  ModalManagerRef,
  Title,
} from '@esgi/deprecated/ui-kit/modal';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';

interface Props {
  onSaveClicked: () => void;
	onCloseClicked: () => void;
}

export default class DeletedTestSessionsExistModal extends React.PureComponent<Props> {
	private readonly modalManagerRef: ModalManagerRef = createRef();

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
      <ModalHeader>
        <Title>
          Warning: Your rubric has been modified.
        </Title>
        <CloseIcon onClick={() => this.modalManagerRef.current.close(() => this.props.onCloseClicked())}/>
      </ModalHeader>
      <ModalBody>
        This rubric has deleted test sessions. Saving the rubric will permanently delete these sessions due to your modifications.
      </ModalBody>
      <ModalFooter>
        <LinkButton onClick={() => this.props.onCloseClicked()}>
          Cancel
        </LinkButton>
        <LinkButton onClick={() => this.props.onSaveClicked()}>
          Confirm
        </LinkButton>
      </ModalFooter>
    </Modal>;
	}
}
