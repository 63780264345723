import {GroupingType} from '../../types';
import {Section, Title, Label, ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot} from '../../index.styled';

interface Props {
	disabled?: boolean;
	onChange: (value: GroupingType) => void;
	value: GroupingType;
}

export function GroupingControl(props: Props) {
	const {disabled, onChange, value} = props;

	return (
		<Section>
			<ToggleGroupRoot
				value={value}
				disabled={disabled}
			>
				<ToggleGroupContent>
					<ToggleGroupItem
						value={GroupingType.Tests}
						onClick={() => onChange(GroupingType.Tests)}
					>
						<Label size='medium' bold>Tests</Label>
					</ToggleGroupItem>
					<ToggleGroupItem
						value={GroupingType.Students}
						onClick={() => onChange(GroupingType.Students)}
					>
						<Label size='medium' bold>Students</Label>
					</ToggleGroupItem>
				</ToggleGroupContent>
			</ToggleGroupRoot>
		</Section>
	);
}
