import {EventBusDispatcher} from '@esgillc/events';
import {Reordered} from './events/group';
import {HttpClient} from '@esgi/api';

export namespace Entities {
	export class Group {
		private static controller = 'group';
		static reorder(groupIds: number[]) {
			return HttpClient.default.ESGIApi.post(this.controller, 'reorder', {
				groupIds: groupIds,
			}).subscribe(r => EventBusDispatcher.dispatch(Reordered, Reordered(groupIds)));
		}
	}

  export class SchoolsGroup {
    private static controller = 'schools-group';
    static reorder(groupIds: number[]) {
      return HttpClient.default.ESGIApi.post(this.controller, 'reorder', {
        groupIds: groupIds,
      }).subscribe(r => EventBusDispatcher.dispatch(Reordered, Reordered(groupIds)));
    }
  }

  export class UsersGroup {
    private static controller = 'users-group';
    static reorder(groupIds: number[]) {
      return HttpClient.default.ESGIApi.post(this.controller, 'reorder', {
        groupIds: groupIds,
      }).subscribe(r => EventBusDispatcher.dispatch(Reordered, Reordered(groupIds)));
    }
  }
}
