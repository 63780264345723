import React from 'react';
import {ModalBody, ModalHeader} from '@esgi/deprecated/react';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {GradeScaleService} from './service';
import {IReportGradeScale, SubjectLevel} from '../models';
import Info from './info/component';
import Selector from './selector/component';
import {MoreLevelsConfirmationModal} from './more-levels-confirmation-modal';
import {Subscription} from 'rxjs';
import './component.less';
import '../styles.less';

export class Props {
	hasScales: boolean;
	gradeScaleID?: number;
	prevGradeScaleID?: number;
	subjectLevel?: SubjectLevel;
	needDeleteCreatedScale: boolean;
	service: GradeScaleService;
	onClose: () => void;
	onSaved: (gradeScale: IReportGradeScale, isNew: boolean) => void;
}

export class State {
	formChanged: boolean;
	moreLevelsConfirmation: boolean;
}

export default class GradeScale extends React.PureComponent<Props, State> {
	subscribers: Subscription[] = [];

	constructor(props) {
		super(props);
		this.state = new State();
	}

	public componentDidMount() {
		this.subscribers.push(this.props.service.savedGradeScale$.subscribe((resp) => {
			this.props.onSaved(resp.reportGradeScale, resp.isNew);
		}));

		this.subscribers.push(this.props.service.formChanged$.subscribe((changed) => {
			this.setState({formChanged: changed});
		}));

		this.subscribers.push(this.props.service.moreLevelsConfirmation$.subscribe(
			(moreLevelsConfirmation) => this.setState({moreLevelsConfirmation}),
		));

		if (this.props.needDeleteCreatedScale) {
			this.subscribers.push(this.props.service.deleteGradeScale(this.props.gradeScaleID).subscribe(r => {
				this.props.service.init(this.props.hasScales, this.props.prevGradeScaleID, this.props.subjectLevel);
			}));
		} else {
			this.props.service.init(this.props.hasScales, this.props.gradeScaleID, this.props.subjectLevel);
		}
	}

	public componentWillUnmount() {
		this.subscribers.map(s => s.unsubscribe());
	}

	public renderSaveButton() {
		if (this.state.formChanged) {
			return <Primary
				title='SAVE AND CONTINUE'
				onClick={() => this.props.service.save()}
			/>;
		}

		return <Primary
			title='CLOSE'
			onClick={() => this.props.onClose()}
		/>;
	}

	public render() {
		return <>
			{this.state.moreLevelsConfirmation &&
				<MoreLevelsConfirmationModal
					onBackClicked={() => this.props.service.setMoreLevelsConfirmation(false)}
					onOKClicked={() => {
						this.props.service.save();
						this.props.service.setMoreLevelsConfirmation(false);
					}}
				/>
			}
			<ModalHeader
				title='Grade Scale'
				close={{click: () => this.props.onClose()}}
			/>
			<ModalBody>
				<Selector service={this.props.service}/>
				<div className='grade-scale-content'>
					<Info service={this.props.service}/>
					<div className='grade-scale-footer'>
						{this.renderSaveButton()}
					</div>
				</div>
			</ModalBody>
		</>;
	}
}
