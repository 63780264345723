import {useMemo, Dispatch} from 'react';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {usePieChartService} from '../../hooks';
import {AlertBody} from './components/body';
import {FileType} from '../../types';
import {Reports} from '@esgi/main/kits/reports';
import {Settings} from './components/settings';
import {menuItems} from './constants';

type Props = {
	onClose: VoidFunction;
	onDownload: Dispatch<FileType>;
};

export function PreviewDialog(props: Props) {
	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, props.onClose);

	const service = usePieChartService();
	const report = useBehaviorSubject(service.reportData$);
	const printSettings = useBehaviorSubject(service.printSettings$);
	const unitName = printSettings?.className ? 'class' : 'group';

	const filteredStudents = useMemo(() => {
		if (report?.students) {
			if (printSettings?.gradeLevelID > 0) {
				return report?.students.filter(({gradeLevelID}) => printSettings?.gradeLevelID === gradeLevelID);
			}

			return report?.students;
		}
		return [];
	}, [report?.students, printSettings?.gradeLevelID]);

	let error = '';
	if (report?.students.length === 0) {
		error = 'There are no students in this ' + unitName + '.';
	}
	if (!(filteredStudents.length > 0 && report?.tests.length > 0)) {
		if (filteredStudents.length === 1) {
			error = 'There is no test data for this student.';
		} else {
			error = 'There is no test data for any student in this ' + unitName + '.';
		}
	}

	return (
		<Alert
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.neutral}
		>
			<Alert.Header
				withBacklight={false}
				onCloseIconClick={onClose}
				dataCy='header'
			>
				<Text data-cy='title' size='large'>Pie Charts</Text>
			</Alert.Header>
			<Settings/>
			<AlertBody error={error}/>
			{error.length === 0 &&
				<Alert.Footer css={{paddingTop: 0}}>
					<Reports.DownloadButton
						menuItems={menuItems}
						onClick={(value) => props.onDownload(value as FileType)}
					/>
				</Alert.Footer>
			}
		</Alert>
	);
}
