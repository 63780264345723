import './styles.less';
import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

export class Props {
    contentArea: string;
}

export class ContentAreaIcon extends React.PureComponent<Props> {
	render() {
		if (!this.props.contentArea) {
			return null;
		}

		let icon = this.renderIcon(this.props.contentArea.toLowerCase());
		if (!icon) {
			return null;
		}

		return <div className='ca-icon'>
            {icon}
        </div>;
	}

	renderIcon(contentArea: string) {
		switch (contentArea) {
			case 'mathematics':
				return <Math />;
			case 'language arts':
				return <LanguageArts />;
			case 'social studies':
				return <SocialStudies />;
			case 'science':
				return <Science />;
			case 'sel':
				return <Sel />;
			case 'spanish':
				return <Spanish />;
			case 'multiple':
				return <Multiple />;

			default:
				return <Other />;
		}
	}
}

class Spanish extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='Spanish'>
            <svg xmlns='http://www.w3.org/2000/svg' width='32' height='29' viewBox='0 0 32 29' fill='none'>
                <g clipPath='url(#clip0)'>
                    <path
                        d='M14.3534 6.2002V1.93615C14.3542 1.72186 14.4396 1.51656 14.5912 1.36503C14.7427 1.2135 14.948 1.12805 15.1623 1.12731H29.3269C29.541 1.12805 29.746 1.2136 29.897 1.3652C30.0481 1.51681 30.1329 1.72212 30.1329 1.93615V16.0951C30.1329 16.3092 30.0481 16.5145 29.897 16.6661C29.746 16.8177 29.541 16.9032 29.3269 16.904H27.9319V19.6603L23.4226 16.904H19.1614V18.0313H23.107L29.0648 21.6697V18.0341H29.3326C29.8456 18.0334 30.3373 17.829 30.6998 17.466C31.0623 17.103 31.2659 16.611 31.2659 16.098V1.93615C31.2659 1.42314 31.0623 0.931093 30.6998 0.568075C30.3373 0.205057 29.8456 0.000746742 29.3326 0H15.1623C14.649 0.000745492 14.157 0.204965 13.794 0.567903C13.4311 0.93084 13.2269 1.42288 13.2261 1.93615V6.2002H14.3534Z'
                        fill='black' />
                    <path
                        d='M1.93615 25.3616H2.20389V29L8.15889 25.3616H16.098C16.6112 25.3609 17.1033 25.1566 17.4662 24.7937C17.8291 24.4308 18.0334 23.9387 18.0341 23.4255V9.26365C18.0334 8.75038 17.8291 8.25834 17.4662 7.8954C17.1033 7.53246 16.6112 7.32824 16.098 7.3275H1.93615C1.42288 7.32824 0.93084 7.53246 0.567903 7.8954C0.204965 8.25834 0.000745492 8.75038 0 9.26365V23.4226C-5.4269e-07 23.9364 0.203896 24.4292 0.566914 24.7927C0.929932 25.1562 1.42239 25.3609 1.93615 25.3616V25.3616ZM1.12731 9.26365C1.12805 9.04936 1.2135 8.84406 1.36503 8.69253C1.51656 8.541 1.72186 8.45555 1.93615 8.45481H16.098C16.3122 8.45555 16.5175 8.541 16.6691 8.69253C16.8206 8.84406 16.9061 9.04936 16.9068 9.26365V23.4226C16.9061 23.6369 16.8206 23.8422 16.6691 23.9938C16.5175 24.1453 16.3122 24.2307 16.098 24.2315H7.84043L3.3312 26.9877V24.2315H1.93615C1.72186 24.2307 1.51656 24.1453 1.36503 23.9938C1.2135 23.8422 1.12805 23.6369 1.12731 23.4226V9.26365Z'
                        fill='black' />
                    <path
                        d='M7.98415 18.2877H10.0556L10.7122 20.8608L11.8029 20.579L9.56521 11.8255H8.47454L6.23683 20.579L7.3275 20.8608L7.98415 18.2877ZM9.01846 14.2435L9.77376 17.1604H8.28289L9.01846 14.2435Z'
                        fill='black' />
                    <path
                        d='M23.7344 13C23.6719 12.875 23.6211 12.6523 23.582 12.332C23.0781 12.8555 22.4766 13.1172 21.7773 13.1172C21.1523 13.1172 20.6387 12.9414 20.2363 12.5898C19.8379 12.2344 19.6387 11.7852 19.6387 11.2422C19.6387 10.582 19.8887 10.0703 20.3887 9.70703C20.8926 9.33984 21.5996 9.15625 22.5098 9.15625H23.5645V8.6582C23.5645 8.2793 23.4512 7.97852 23.2246 7.75586C22.998 7.5293 22.6641 7.41602 22.2227 7.41602C21.8359 7.41602 21.5117 7.51367 21.25 7.70898C20.9883 7.9043 20.8574 8.14062 20.8574 8.41797H19.7676C19.7676 8.10156 19.8789 7.79688 20.1016 7.50391C20.3281 7.20703 20.6328 6.97266 21.0156 6.80078C21.4023 6.62891 21.8262 6.54297 22.2871 6.54297C23.0176 6.54297 23.5898 6.72656 24.0039 7.09375C24.418 7.45703 24.6328 7.95898 24.6484 8.59961V11.5176C24.6484 12.0996 24.7227 12.5625 24.8711 12.9062V13H23.7344ZM21.9355 12.1738C22.2754 12.1738 22.5977 12.0859 22.9023 11.9102C23.207 11.7344 23.4277 11.5059 23.5645 11.2246V9.92383H22.7148C21.3867 9.92383 20.7227 10.3125 20.7227 11.0898C20.7227 11.4297 20.8359 11.6953 21.0625 11.8867C21.2891 12.0781 21.5801 12.1738 21.9355 12.1738ZM22.9375 4H24.25L22.6797 5.72266H21.8066L22.9375 4Z'
                        fill='black' />
                </g>
                <defs>
                    <clipPath id='clip0'>
                        <rect width='31.2659' height='29' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </OnHoverTooltip>;
	}
}

class Multiple extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='Multiple content areas'>
            <svg width='38' height='29' viewBox='0 0 38 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M35.0549 0H2.54945C1.14407 0 0 1.13163 0 2.52174V21.8414C0 23.2315 1.14407 24.3631 2.54945 24.3631H35.0549C36.3902 24.3631 37.2857 23.3513 37.2857 21.8414V2.52174C37.2857 1.01185 36.3902 0 35.0549 0ZM36.011 21.8414C36.011 22.2197 35.9186 23.1023 35.0549 23.1023H2.54945C1.84516 23.1023 1.27472 22.538 1.27472 21.8414V20.2054H36.011V21.8414ZM1.27472 2.52174C1.27472 1.82511 1.84516 1.26087 2.54945 1.26087H35.0549C35.9186 1.26087 36.011 2.14348 36.011 2.52174V18.9446H1.27472V2.52174Z' fill='black' />
                <path d='M12.4286 25.624V27.7391H10.3826V29H26.9031V28.3696V27.7391H24.8571V25.624H23.5824V27.7391H13.7033V25.624H12.4286Z' fill='black' />
                <path d='M5.97846 3.27827H4.70374V4.53914H5.97846V3.27827Z' fill='black' />
                <path d='M5.97846 6.43041H4.70374V7.69128H5.97846V6.43041Z' fill='black' />
                <path d='M5.97846 9.58263H4.70374V10.8435H5.97846V9.58263Z' fill='black' />
                <path d='M5.97846 12.7348H4.70374V13.9956H5.97846V12.7348Z' fill='black' />
                <path d='M5.97846 15.887H4.70374V17.1478H5.97846V15.887Z' fill='black' />
                <path d='M15.5389 3.27827H9.16528V4.53914H15.5389V3.27827Z' fill='black' />
                <path d='M19.4395 6.43041H11.7147V7.69128H19.4395V6.43041Z' fill='black' />
                <path d='M30.1982 6.43041H20.7143V7.69128H30.1982V6.43041Z' fill='black' />
                <path d='M23.8246 9.58263H17.8461V10.8435H23.8246V9.58263Z' fill='black' />
                <path d='M16.5714 9.58263H11.7147V10.8435H16.5714V9.58263Z' fill='black' />
                <path d='M32.7477 12.7348H28.2033V13.9956H32.7477V12.7348Z' fill='black' />
                <path d='M26.9285 12.7348H14.8187V13.9956H26.9285V12.7348Z' fill='black' />
                <path d='M13.544 12.7348H9.16528V13.9956H13.544V12.7348Z' fill='black' />
                <path d='M14.2642 15.887H9.16528V17.1478H14.2642V15.887Z' fill='black' />
            </svg>
        </OnHoverTooltip>;
	}
}

class Other extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='Other'>
            <svg width='32' height='29' viewBox='0 0 32 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0)'>
                    <path d='M25.2 23.3C25.0939 23.3 24.9922 23.2578 24.9171 23.1828C24.8421 23.1078 24.8 23.0061 24.8 22.9V21.8C24.8 21.6939 24.8421 21.5922 24.9171 21.5172C24.9922 21.4422 25.0939 21.4 25.2 21.4C25.3061 21.4 25.4078 21.4422 25.4828 21.5172C25.5578 21.5922 25.6 21.6939 25.6 21.8V22.9C25.6 23.0061 25.5578 23.1078 25.4828 23.1828C25.4078 23.2578 25.3061 23.3 25.2 23.3Z' fill='black' />
                    <path d='M6.00002 23.3C5.89394 23.3 5.7922 23.2578 5.71719 23.1828C5.64217 23.1078 5.6 23.0061 5.6 22.9V21.8C5.6 21.6939 5.64217 21.5922 5.71719 21.5172C5.7922 21.4422 5.89394 21.4 6.00002 21.4C6.10611 21.4 6.20785 21.4422 6.28286 21.5172C6.35787 21.5922 6.39999 21.6939 6.39999 21.8V22.9C6.39999 23.0061 6.35787 23.1078 6.28286 23.1828C6.20785 23.2578 6.10611 23.3 6.00002 23.3Z' fill='black' />
                    <path d='M27.4 23.3C27.2 23.3 27.1 23.2 27.1 22.9V21.8C27.1 21.6 27.2 21.4 27.4 21.4C27.5061 21.4 27.6078 21.4422 27.6828 21.5172C27.7578 21.5922 27.8 21.6939 27.8 21.8V22.9C27.8 23.2 27.7 23.3 27.4 23.3Z' fill='black' />
                    <path d='M3.80001 23.3C3.50001 23.3 3.39999 23.2 3.39999 22.9V21.8C3.39999 21.6939 3.44216 21.5922 3.51717 21.5172C3.59219 21.4422 3.69392 21.4 3.80001 21.4C4.00001 21.4 4.1 21.6 4.1 21.8V22.9C4.1 23.2 4.00001 23.3 3.80001 23.3Z' fill='black' />
                    <path d='M25.2 26.7C25.0939 26.7 24.9922 26.6579 24.9171 26.5828C24.8421 26.5078 24.8 26.4061 24.8 26.3V25.2C24.8 25.0939 24.8421 24.9922 24.9171 24.9172C24.9922 24.8422 25.0939 24.8 25.2 24.8C25.3061 24.8 25.4078 24.8422 25.4828 24.9172C25.5578 24.9922 25.6 25.0939 25.6 25.2V26.3C25.6 26.4061 25.5578 26.5078 25.4828 26.5828C25.4078 26.6579 25.3061 26.7 25.2 26.7Z' fill='black' />
                    <path d='M6.00002 26.7C5.89394 26.7 5.7922 26.6579 5.71719 26.5828C5.64217 26.5078 5.6 26.4061 5.6 26.3V25.2C5.6 25.0939 5.64217 24.9922 5.71719 24.9172C5.7922 24.8422 5.89394 24.8 6.00002 24.8C6.10611 24.8 6.20785 24.8422 6.28286 24.9172C6.35787 24.9922 6.39999 25.0939 6.39999 25.2V26.3C6.39999 26.4061 6.35787 26.5078 6.28286 26.5828C6.20785 26.6579 6.10611 26.7 6.00002 26.7Z' fill='black' />
                    <path d='M27.4 26.7C27.2 26.7 27.1 26.5 27.1 26.3V25.2C27.1 25 27.2 24.8 27.4 24.8C27.5061 24.8 27.6078 24.8422 27.6828 24.9172C27.7578 24.9922 27.8 25.0939 27.8 25.2V26.3C27.8 26.4061 27.7578 26.5078 27.6828 26.5828C27.6078 26.6579 27.5061 26.7 27.4 26.7Z' fill='black' />
                    <path d='M3.80001 26.7C3.69392 26.7 3.59219 26.6579 3.51717 26.5828C3.44216 26.5078 3.39999 26.4061 3.39999 26.3V25.2C3.39999 25.0939 3.44216 24.9922 3.51717 24.9172C3.59219 24.8422 3.69392 24.8 3.80001 24.8C4.00001 24.8 4.1 25 4.1 25.2V26.3C4.1 26.5 4.00001 26.7 3.80001 26.7Z' fill='black' />
                    <path d='M15.6 5.3C15.4939 5.3 15.3922 5.25786 15.3172 5.18285C15.2421 5.10783 15.2 5.0061 15.2 4.90001V0.400009C15.2 0.293923 15.2421 0.192187 15.3172 0.117172C15.3922 0.0421577 15.4939 1.52588e-05 15.6 1.52588e-05H19.5C19.6061 1.52588e-05 19.7078 0.0421577 19.7829 0.117172C19.8579 0.192187 19.9 0.293923 19.9 0.400009V2.59999C19.9 2.70608 19.8579 2.80784 19.7829 2.88286C19.7078 2.95787 19.6061 3.00002 19.5 3.00002H16V4.90001C16 5.0061 15.9578 5.10783 15.8828 5.18285C15.8078 5.25786 15.7061 5.3 15.6 5.3ZM16 2.2H19.2V0.800003H16V2.2Z' fill='black' />
                    <path d='M22.9 29H20.7C20.6475 29 20.5954 28.9897 20.5469 28.9696C20.4984 28.9495 20.4543 28.92 20.4171 28.8828C20.38 28.8457 20.3506 28.8016 20.3305 28.7531C20.3104 28.7045 20.3 28.6525 20.3 28.6C20.3 28.5475 20.3104 28.4954 20.3305 28.4469C20.3506 28.3984 20.38 28.3543 20.4171 28.3172C20.4543 28.28 20.4984 28.2505 20.5469 28.2304C20.5954 28.2103 20.6475 28.2 20.7 28.2H22.5V10.8C22.5 10.6939 22.5421 10.5922 22.6172 10.5172C22.6922 10.4422 22.7939 10.4 22.9 10.4C23.2 10.4 23.3 10.5 23.3 10.8V28.6C23.3 28.7061 23.2578 28.8078 23.1828 28.8828C23.1078 28.9578 23.0061 29 22.9 29Z' fill='black' />
                    <path d='M10.5 29H8.30001C8.19392 29 8.09219 28.9578 8.01717 28.8828C7.94216 28.8078 7.89999 28.7061 7.89999 28.6V10.8C7.89999 10.5 8.00001 10.4 8.30001 10.4C8.4061 10.4 8.50783 10.4422 8.58285 10.5172C8.65786 10.5922 8.69997 10.6939 8.69997 10.8V28.2H10.5C10.6061 28.2 10.7078 28.2421 10.7829 28.3172C10.8579 28.3922 10.9 28.4939 10.9 28.6C10.9 28.7061 10.8579 28.8078 10.7829 28.8828C10.7078 28.9578 10.6061 29 10.5 29Z' fill='black' />
                    <path d='M24.1 12.1H23.8L15.6 5.50002L7.39998 12.1C7.30715 12.1663 7.19182 12.193 7.0793 12.1743C6.96678 12.1555 6.86631 12.0928 6.8 12C6.7337 11.9072 6.70697 11.7918 6.72572 11.6793C6.74448 11.5668 6.80715 11.4663 6.89998 11.4L15.4 4.7C15.455 4.6508 15.5262 4.62361 15.6 4.62361C15.6738 4.62361 15.745 4.6508 15.8 4.7L24.3 11.4C24.3803 11.4751 24.4337 11.5746 24.4517 11.6831C24.4698 11.7915 24.4516 11.9029 24.4 12L24.1 12.1Z' fill='black' />
                    <path d='M15.6 14.3C15.0618 14.2996 14.536 14.1384 14.0901 13.8371C13.6442 13.5357 13.2985 13.108 13.0974 12.6088C12.8963 12.1096 12.849 11.5617 12.9616 11.0354C13.0741 10.5091 13.3414 10.0284 13.729 9.65513C14.1167 9.28182 14.6071 9.0329 15.1372 8.94029C15.6674 8.84768 16.2132 8.91561 16.7044 9.13539C17.1957 9.35516 17.6101 9.71672 17.8944 10.1737C18.1787 10.6307 18.32 11.1622 18.3 11.7C18.2741 12.3986 17.9783 13.06 17.4747 13.5449C16.9711 14.0298 16.2991 14.3005 15.6 14.3ZM15.6 9.8C15.2242 9.8 14.8569 9.91144 14.5444 10.1202C14.232 10.329 13.9885 10.6257 13.8446 10.9729C13.7008 11.3201 13.6632 11.7021 13.7366 12.0707C13.8099 12.4392 13.9908 12.7778 14.2565 13.0435C14.5222 13.3092 14.8608 13.4902 15.2293 13.5635C15.5979 13.6368 15.9799 13.5992 16.3271 13.4554C16.6743 13.3116 16.971 13.068 17.1798 12.7556C17.3886 12.4431 17.5 12.0758 17.5 11.7C17.5 11.1961 17.2998 10.7128 16.9435 10.3565C16.5872 10.0002 16.1039 9.8 15.6 9.8Z' fill='black' />
                    <path d='M20.7 19.9H10.5C10.3 19.9 10.1 19.8 10.1 19.6V16.2C10.1 16.0939 10.1422 15.9922 10.2172 15.9172C10.2922 15.8422 10.3939 15.8 10.5 15.8H20.7C20.8061 15.8 20.9078 15.8422 20.9828 15.9172C21.0578 15.9922 21.1 16.0939 21.1 16.2V19.6C21.1 19.8 20.9 19.9 20.7 19.9ZM10.9 19.2H20.3V16.6H10.9V19.2Z' fill='black' />
                    <path d='M17.3 19.9C17.1 19.9 16.9 19.8 16.9 19.6V16.2C16.9 16.0939 16.9422 15.9922 17.0172 15.9172C17.0922 15.8422 17.1939 15.8 17.3 15.8C17.4061 15.8 17.5078 15.8422 17.5828 15.9172C17.6579 15.9922 17.7 16.0939 17.7 16.2V19.6C17.7 19.8 17.5 19.9 17.3 19.9Z' fill='black' />
                    <path d='M13.9 19.9C13.7 19.9 13.5 19.8 13.5 19.6V16.2C13.5 16.0939 13.5421 15.9922 13.6171 15.9172C13.6922 15.8422 13.7939 15.8 13.9 15.8C14.0061 15.8 14.1078 15.8422 14.1828 15.9172C14.2578 15.9922 14.3 16.0939 14.3 16.2V19.6C14.3 19.8 14.1 19.9 13.9 19.9Z' fill='black' />
                    <path d='M20.7 29H10.5C10.3939 29 10.2922 28.9578 10.2172 28.8828C10.1422 28.8078 10.1 28.7061 10.1 28.6V22.9C10.1 22.7939 10.1422 22.6922 10.2172 22.6171C10.2922 22.5421 10.3939 22.5 10.5 22.5H20.7C20.8061 22.5 20.9078 22.5421 20.9828 22.6171C21.0578 22.6922 21.1 22.7939 21.1 22.9V28.6C21.1 28.7061 21.0578 28.8078 20.9828 28.8828C20.9078 28.9578 20.8061 29 20.7 29ZM10.9 28.2H20.3V23.3H10.9V28.2Z' fill='black' />
                    <path d='M17.3 29C17.1939 29 17.0922 28.9578 17.0172 28.8828C16.9422 28.8078 16.9 28.7061 16.9 28.6V22.9C16.9 22.7939 16.9422 22.6922 17.0172 22.6171C17.0922 22.5421 17.1939 22.5 17.3 22.5C17.4061 22.5 17.5078 22.5421 17.5828 22.6171C17.6579 22.6922 17.7 22.7939 17.7 22.9V28.6C17.7 28.7061 17.6579 28.8078 17.5828 28.8828C17.5078 28.9578 17.4061 29 17.3 29Z' fill='black' />
                    <path d='M13.9 29C13.7939 29 13.6922 28.9578 13.6171 28.8828C13.5421 28.8078 13.5 28.7061 13.5 28.6V22.9C13.5 22.7939 13.5421 22.6922 13.6171 22.6171C13.6922 22.5421 13.7939 22.5 13.9 22.5C14.0061 22.5 14.1078 22.5421 14.1828 22.6171C14.2578 22.6922 14.3 22.7939 14.3 22.9V28.6C14.3 28.7061 14.2578 28.8078 14.1828 28.8828C14.1078 28.9578 14.0061 29 13.9 29Z' fill='black' />
                    <path d='M30.8 19.9H25.2C25 19.9 24.8 19.8 24.8 19.6C24.8 19.4939 24.8421 19.3922 24.9171 19.3172C24.9922 19.2421 25.0939 19.2 25.2 19.2H30.1L28.4 16.6H25.2C25.0939 16.6 24.9922 16.5579 24.9171 16.4829C24.8421 16.4078 24.8 16.3061 24.8 16.2C24.8 16.0939 24.8421 15.9922 24.9171 15.9172C24.9922 15.8422 25.0939 15.8 25.2 15.8H28.6C28.7 15.8 28.8 15.8 28.9 16L31.2 19.3C31.2 19.5 31.3 19.6 31.2 19.7C31.1 19.8 31 19.9 30.8 19.9Z' fill='black' />
                    <path d='M6.00003 19.9H0.399997C0.199997 19.9 0.100034 19.9 3.374e-05 19.7C-0.0999663 19.5 3.374e-05 19.5 3.374e-05 19.3L2.30002 16C2.40002 15.8 2.50001 15.8 2.60001 15.8H6.00003C6.10612 15.8 6.20786 15.8422 6.28287 15.9172C6.35789 15.9922 6.4 16.0939 6.4 16.2C6.4 16.3061 6.35789 16.4078 6.28287 16.4829C6.20786 16.5579 6.10612 16.6 6.00003 16.6H2.80002L1.10001 19.2H6.00003C6.10612 19.2 6.20786 19.2421 6.28287 19.3172C6.35789 19.3922 6.4 19.4939 6.4 19.6C6.4 19.8 6.20003 19.9 6.00003 19.9Z' fill='black' />
                    <path d='M29.7 29H25.2C25.1475 29 25.0954 28.9897 25.0469 28.9696C24.9984 28.9495 24.9543 28.92 24.9171 28.8828C24.88 28.8457 24.8506 28.8016 24.8305 28.7531C24.8104 28.7045 24.8 28.6525 24.8 28.6C24.8 28.5475 24.8104 28.4954 24.8305 28.4469C24.8506 28.3984 24.88 28.3543 24.9171 28.3172C24.9543 28.28 24.9984 28.2505 25.0469 28.2304C25.0954 28.2103 25.1475 28.2 25.2 28.2H29.3V19.6C29.3 19.4939 29.3421 19.3922 29.4171 19.3172C29.4922 19.2421 29.5939 19.2 29.7 19.2C29.8061 19.2 29.9078 19.2421 29.9828 19.3172C30.0578 19.3922 30.1 19.4939 30.1 19.6V28.6C30.1 28.7061 30.0578 28.8078 29.9828 28.8828C29.9078 28.9578 29.8061 29 29.7 29Z' fill='black' />
                    <path d='M6.00002 29H1.50002C1.39394 29 1.2922 28.9578 1.21719 28.8828C1.14217 28.8078 1.1 28.7061 1.1 28.6V19.6C1.1 19.4939 1.14217 19.3922 1.21719 19.3172C1.2922 19.2421 1.39394 19.2 1.50002 19.2C1.60611 19.2 1.70785 19.2421 1.78286 19.3172C1.85787 19.3922 1.89999 19.4939 1.89999 19.6V28.2H6.00002C6.10611 28.2 6.20785 28.2421 6.28286 28.3172C6.35787 28.3922 6.39999 28.4939 6.39999 28.6C6.39999 28.7061 6.35787 28.8078 6.28286 28.8828C6.20785 28.9578 6.10611 29 6.00002 29Z' fill='black' />
                </g>
                <defs>
                    <clipPath id='clip0'>
                        <rect width='31.3' height='29' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </OnHoverTooltip>;
	}
}

class LanguageArts extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='Language Arts'>
            <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'
                 fill='none'>
                <path
                    d='M24.686 5.61034L24.4089 5.8874H28.8835V26.858H20.447C18.78 26.8629 17.133 27.2218 15.6151 27.9109V14.6813L14.5068 15.7895V27.9607C12.9552 27.2342 11.2635 26.856 9.55023 26.8525H1.10824V5.87908H10.2678C10.7868 5.91962 11.3012 6.0049 11.8055 6.13397L11.8332 6.1035L12.7004 5.2363C11.9309 4.98865 11.1348 4.83242 10.3288 4.77084H0V27.958H9.55023C11.2346 27.962 12.8942 28.3645 14.3932 29.1327L14.5124 29.1937H15.4849L15.604 29.1327C17.1032 28.3648 18.7626 27.9623 20.447 27.958H30V4.77084H25.1875C25.0932 5.08823 24.9208 5.37689 24.686 5.61034Z'
                    fill='black' fillOpacity='0.6' />
                <path d='M11.933 21.8765H3.35519V22.9847H11.933V21.8765Z' fill='black' fillOpacity='0.6' />
                <path
                    d='M3.35519 14.6231H6.22829C6.46656 14.2537 6.71591 13.8843 6.97635 13.5149H3.35519V14.6231Z'
                    fill='black' fillOpacity='0.6' />
                <path d='M3.35519 10.4423H9.61674L10.725 9.33405H3.35519V10.4423Z' fill='black'
                      fillOpacity='0.6' />
                <path d='M26.6448 21.8765H18.0671V22.9847H26.6448V21.8765Z' fill='black' fillOpacity='0.6' />
                <path d='M26.6448 17.6957H18.0671V18.8039H26.6448V17.6957Z' fill='black' fillOpacity='0.6' />
                <path d='M26.6448 13.5149H18.0671V14.6231H26.6448V13.5149Z' fill='black' fillOpacity='0.6' />
                <path d='M26.642 9.33405H20.9623L19.8541 10.4423H26.642V9.33405Z' fill='black'
                      fillOpacity='0.6' />
                <path
                    d='M12.6145 6.88758C12.3879 7.11547 12.2608 7.42374 12.2608 7.74507C12.2608 8.0664 12.3879 8.37469 12.6145 8.60258L13.3986 7.8185C13.3801 7.79852 13.3698 7.7723 13.3698 7.74507C13.3698 7.71785 13.3801 7.69164 13.3986 7.67166L18.1806 2.90346C18.4795 2.60255 18.8688 2.4079 19.2889 2.34934L10.1459 11.4701C8.98881 12.6243 7.96639 13.9061 7.09824 15.2908L5.58826 17.7095L6.12853 18.2498C6.12853 18.2498 5.16714 19.5603 5.38324 19.7764C5.4913 19.8844 6.91262 19.0339 6.91262 19.0339L7.46674 19.5741L9.88269 18.0614C11.2674 17.1932 12.5491 16.1708 13.7033 15.0137L23.8991 4.81794C24.042 4.67382 24.1306 4.48468 24.1498 4.28264C24.169 4.0806 24.1176 3.87814 24.0044 3.7097L24.8744 2.84252C25.3897 2.32442 25.229 1.4406 24.481 0.692535C23.7329 -0.0555255 22.8491 -0.216229 22.3337 0.301872L21.4638 1.16907C21.2935 1.06126 21.0923 1.0131 20.8916 1.03216C20.691 1.05122 20.5024 1.13641 20.3555 1.27435L20.3001 1.33254C19.7906 1.19054 19.2522 1.18814 18.7414 1.32559C18.2305 1.46305 17.766 1.7353 17.3965 2.11385L12.6145 6.88758ZM23.6969 1.4766C23.8774 1.63585 24.0066 1.8451 24.0681 2.07783L23.237 2.90901L22.2645 1.93653L23.0957 1.10535C23.3297 1.16559 23.5402 1.29496 23.6996 1.4766H23.6969ZM12.9165 14.238C12.609 14.5455 12.2903 14.8392 11.9662 15.1246L10.0434 13.2045C10.3204 12.8776 10.6252 12.559 10.93 12.2542L20.9651 2.21914L22.9516 4.20287L12.9165 14.238ZM7.61911 18.1667L7.00403 17.5488L8.04578 15.8865C8.44229 15.2577 8.8714 14.6501 9.33134 14.0662L11.1101 15.8477C10.5277 16.3089 9.91999 16.7372 9.28978 17.1305L7.61911 18.1667Z'
                    fill='black' fillOpacity='0.6' />
            </svg>
        </OnHoverTooltip>;
	}
}

class SocialStudies extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='Social Studies'>
            <svg xmlns='http://www.w3.org/2000/svg' width='22' height='29' viewBox='0 0 22 29'
                 fill='none'>
                <g clipPath='url(#clip0)'>
                    <path
                        d='M9.18615 25.2746V27.9897H7.01153V29H12.3483V27.9897H10.1863V25.2746C12.9271 25.1677 15.5521 24.1415 17.6388 22.3614C19.7255 20.5813 21.1525 18.1507 21.6899 15.461C22.2274 12.7713 21.8441 9.97906 20.6018 7.53369C19.3594 5.08832 17.3304 3.13216 14.8412 1.98014L15.5029 0.386431L14.5684 0L12.9418 3.92493C12.2364 3.67286 11.5041 3.50335 10.7596 3.41979C10.4066 3.37946 10.0516 3.35922 9.69634 3.35917C7.49574 3.35969 5.36072 4.10858 3.64197 5.48283C1.92322 6.85708 0.722925 8.77499 0.238209 10.9216C-0.246507 13.0681 0.0131798 15.3157 0.974626 17.2952C1.93607 19.2747 3.54211 20.8683 5.52896 21.8144L3.90494 25.7393L4.83692 26.1258L5.49612 24.5346C6.68011 24.9718 7.92512 25.2215 9.18615 25.2746ZM1.05343 12.1183C1.23935 10.4071 1.92878 8.78955 3.03442 7.47037C4.14007 6.15119 5.61222 5.18969 7.26457 4.70757C8.91691 4.22545 10.6751 4.24438 12.3167 4.76198C13.9583 5.27957 15.4094 6.27255 16.4864 7.61524C17.5634 8.95792 18.2178 10.5899 18.3668 12.3047C18.5158 14.0195 18.1528 15.74 17.3236 17.2483C16.4944 18.7567 15.2363 19.9851 13.7086 20.7781C12.181 21.5711 10.4524 21.8931 8.74162 21.7033C7.60667 21.5807 6.50709 21.2352 5.50593 20.6867C4.50477 20.1383 3.62173 19.3975 2.90746 18.507C2.19319 17.6165 1.66174 16.5938 1.34358 15.4975C1.02543 14.4011 0.926821 13.2528 1.05343 12.1183V12.1183ZM8.63051 22.7085C8.98366 22.7467 9.3386 22.7661 9.69381 22.7666C11.8942 22.7655 14.0288 22.0162 15.7471 20.6417C17.4655 19.2673 18.6653 17.3494 19.1497 15.203C19.6341 13.0566 19.3742 10.8092 18.4127 8.83001C17.4513 6.8508 15.8453 5.25736 13.8587 4.31136L14.4371 2.91465C16.7472 3.99517 18.6198 5.83117 19.7457 8.11953C20.8715 10.4079 21.1834 13.0118 20.6297 15.5013C20.0761 17.9908 18.69 20.2171 16.7004 21.8126C14.7108 23.4081 12.2366 24.2774 9.68623 24.277C8.38368 24.2793 7.09093 24.0519 5.86739 23.6051L6.44578 22.2059C7.15297 22.4575 7.88697 22.6261 8.63304 22.7085H8.63051Z'
                        fill='black' />
                    <path
                        d='M4.07922 16.7453L4.33179 16.96C4.90007 19.2963 5.81941 19.804 6.7135 20.1222L7.26918 20.3217L7.50405 19.0841L7.92332 18.579L8.77448 18.3618L9.10283 17.296L9.71908 16.71L9.58774 15.5861L9.31498 15.379C8.34336 14.6722 7.44935 13.8645 6.64786 12.9694L6.57714 12.8886L6.48116 12.8457C6.48116 12.8457 5.9356 12.5931 5.77901 12.53L5.38502 13.4594L5.96591 13.712C6.77136 14.6048 7.6659 15.413 8.63558 16.124L8.65578 16.3185L8.21631 16.7378L7.96374 17.5157L7.34998 17.6723L6.54936 18.6144L6.49377 18.9073C6.05177 18.6295 5.60978 18.0638 5.26629 16.5584L5.23094 16.3993L4.88745 16.0988C4.67698 15.6408 4.49312 15.1711 4.33683 14.692C4.42776 14.5 4.56668 14.1995 4.64498 14.0454L4.69802 13.9418V13.8256C4.69802 13.6994 4.69802 13.5529 4.71318 13.4064V13.1311L4.48839 12.9745L4.30402 12.8457C4.03552 12.1587 3.94853 11.4141 4.05145 10.6837C4.27118 8.25649 7.37272 7.23864 8.48655 6.94819L7.7642 7.84733L8.01677 9.23899L7.49144 9.26172L5.90782 9.74413L4.56163 10.9514L5.23598 11.7091L6.41802 10.6509L7.66317 10.2695L9.20889 10.2038L8.84773 8.13021L9.85801 6.86736L9.00685 5.80405L8.70881 5.85708C8.49412 5.8975 3.40487 6.86736 3.05632 10.5877C2.9315 11.4985 3.0482 12.4261 3.39476 13.2776L3.48317 13.4923L3.60187 13.5781L3.68775 13.6387C3.68775 13.6539 3.68775 13.6716 3.68775 13.6867C3.55136 13.9696 3.34172 14.4217 3.33161 14.4444L3.24826 14.6238L3.34424 14.9167C3.52687 15.4888 3.7471 16.0482 4.00346 16.5913L4.07922 16.7453Z'
                        fill='black' />
                    <path
                        d='M11.6285 8.96369L12.3256 9.17838L12.6211 8.21356L12.3913 8.14284L11.9568 7.02902C13.2893 7.56248 14.3893 8.55145 15.0609 9.8199L15.9576 9.3577C15.0951 7.72965 13.6375 6.49734 11.8887 5.91771L11.7826 5.88235L10.4768 6.00611L11.6285 8.96369Z'
                        fill='black' />
                    <path
                        d='M15.066 10.3326L14.0178 10.4185L13.0075 9.99922L11.285 10.4488L10.0853 12.2294L9.99187 13.8938L11.4239 15.5962L11.7169 15.5355L12.7272 15.3284C12.8319 15.5675 12.9238 15.812 13.0025 16.0609C12.8534 16.5054 12.7019 16.8666 12.6994 16.8716L12.6691 16.9398L12.3635 19.804L13.1793 19.41C15.1771 18.4527 16.7152 15.6214 16.8415 12.6765V12.4643L15.7883 11.353L15.066 10.3326ZM13.6011 17.9299L13.6819 17.1949C13.745 17.0409 13.8991 16.6519 14.038 16.2124L14.086 16.0609L14.038 15.9093C13.8951 15.4367 13.7144 14.9764 13.4975 14.5328L13.3232 14.1641L12.9267 14.2575C12.7297 14.303 12.169 14.4167 11.823 14.4874L11.035 13.5503L11.0905 12.5628L11.9215 11.3303L12.9317 11.0625L13.841 11.4439L14.5734 11.3858L15.0104 11.9768L15.8287 12.8457C15.705 14.8763 14.8058 16.8565 13.6011 17.9299V17.9299Z'
                        fill='black' />
                </g>
                <defs>
                    <clipPath id='clip0'>
                        <rect width='21.9268' height='29' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </OnHoverTooltip>;
	}
}

class Math extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='Math'>
            <svg width='30' height='30' xmlns='http://www.w3.org/2000/svg'>
                <g>
                    <rect fill='none' id='canvas_background' height='3.874995' width='3.874995' y='-1'
                          x='-1' />
                </g>
                <g>
                    <path id='svg_1' fill='#010101'
                          d='m12.065764,25.928801l15.9476,0l0,-21.87776l-15.9476,0l0,21.87776zm14.8559,-20.78606l0,6.17466l-13.773,0l0,-6.17466l13.773,0zm-13.773,16.27946l3.8603,0l0,3.4149l-3.8603,0l0,-3.4149zm8.821,-5.5982l-3.8603,0l0,-3.4149l3.8603,0l0,3.4149zm0,4.5065l-3.8603,0l0,-3.4148l3.8603,0l0,3.4148zm1.0917,-4.5065l0,-3.4149l3.8603,0l0,3.4149l-3.8603,0zm-6.0437,-3.4149l0,3.4149l-3.8602,0l0,-3.4149l3.8602,0zm0,4.5066l0,3.4148l-3.8602,0l0,-3.4148l3.8602,0zm1.0917,4.5065l3.8603,0l0,3.4149l-3.8603,0l0,-3.4149zm4.952,-4.5065l3.8603,0l0,7.9214l-3.8603,0l0,-7.9214z' />
                    <path id='svg_2' fill='#010101'
                          d='m8.851764,-0.062499l-5.73799,0c-0.716157,0 -1.30131,0.585153 -1.30131,1.30131l0,27.39739c0,0.7161 0.585153,1.3013 1.30131,1.3013l5.73799,0c0.71616,0 1.30131,-0.5852 1.30131,-1.3013l0,-27.39739c0,-0.716157 -0.58515,-1.30131 -1.30131,-1.30131zm-5.9476,4.06114l0,-0.64629l0,0.64629zm0,3.45851l0,-0.63755l2.27075,0l0,-0.55022l0,-0.54149l-2.27075,0l0,-0.64629l0,2.37555zm0,1.73799l0,-0.64629l0,0.64629zm0,3.46726l0,-0.6463l2.27075,0l0,-0.5415l0,-0.5415l-2.27075,0l0,-0.6463l0,2.3756zm0,1.7292l0,-0.6462l0,0.6462zm0,3.4673l0,-0.6463l2.27075,0l0,-0.5415l0,-0.5415l-2.27075,0l0,-0.6462l0,2.3755zm0,1.7293l0,-0.6463l0,0.6463zm0,3.4672l0,-0.6463l2.27075,0l0,-0.5415l0,-0.5415l-2.27075,0l0,-0.6462l0,2.3755zm0,1.738l0,-0.6463l0,0.6463zm0,-22.53276l0,-1.02183c0,-0.11354 0.09607,-0.20961 0.20961,-0.20961l5.73799,0c0.11354,0 0.20961,0.09607 0.20961,0.20961l0,27.39739c0,0.1135 -0.09607,0.2096 -0.20961,0.2096l-5.73799,0c-0.11354,0 -0.20961,-0.0961 -0.20961,-0.2096l0,-1.0218l2.27075,0l0,-0.5415l0,-0.5415l-2.27075,0l0,-0.655' />
                    <path id='svg_3' fill='#010101'
                          d='m4.17467,2.21834c-0.40174,0 -0.73362,0.33188 -0.73362,0.73362c0,0.40175 0.33188,0.73363 0.73362,0.73363c0.40175,0 0.73363,-0.33188 0.73363,-0.73363c-0.00874,-0.40174 -0.33188,-0.73362 -0.73363,-0.73362z' />
                    <path id='svg_4' fill='#010101'
                          d='m26.083164,6.11354l-3.0218,0l0,4.29696l3.0218,0l0,-4.29696zm-1.0917,3.20524l-0.8384,0l0,-2.11354l0.8384,0l0,2.11354z' />
                </g>
            </svg>
        </OnHoverTooltip>;
	}
}

class Science extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='Science'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='29' viewBox='0 0 20 29'
                 fill='none'>
                <g clipPath='url(#clip0)'>
                    <path d='M11.5113 24.0696H7.89768V25.1147H11.5113V24.0696Z' fill='black' />
                    <path d='M11.5113 20.5422H7.89768V21.5874H11.5113V20.5422Z' fill='black' />
                    <path
                        d='M9.60125 29H9.79721C11.9707 28.9804 14.0746 28.2309 15.7709 26.8718C17.4672 25.5127 18.6575 23.6229 19.1506 21.506C19.6438 19.389 19.4111 17.1678 18.49 15.199C17.569 13.2302 16.0129 11.6281 14.0718 10.65V1.04514H15.1379V0H4.28671V1.04514H5.33185V10.65C3.39168 11.6275 1.83614 13.2285 0.914825 15.196C-0.00648879 17.1635 -0.240168 19.3835 0.251295 21.4997C0.742759 23.6159 1.93087 25.5057 3.62498 26.8658C5.31909 28.2259 7.42099 28.9775 9.5934 29H9.60125ZM6.08174 11.4521L6.38484 11.3136V1.04514H13.0267V11.3136L13.3298 11.4521C14.954 12.2075 16.3048 13.4469 17.1968 15.0004H13.1547C12.0036 15.0013 10.8713 15.2924 9.86253 15.8469L9.04471 16.2989C8.1902 16.7677 7.23144 17.0139 6.25679 17.0149H1.37078C1.70668 15.802 2.30185 14.6764 3.1152 13.716C3.92856 12.7556 4.94071 11.9832 6.08174 11.4521ZM1.1513 18.06H6.26202C7.41314 18.0591 8.54544 17.7679 9.5542 17.2134L10.372 16.764C11.2259 16.2933 12.1849 16.0461 13.1599 16.0455H17.7115C18.25 17.3513 18.4594 18.7693 18.3214 20.175C18.1834 21.5807 17.7022 22.931 16.9201 24.1071C16.138 25.2832 15.0789 26.2492 13.836 26.9202C12.5931 27.5911 11.2044 27.9464 9.79198 27.9549H9.62999C7.35222 27.9268 5.17684 27.0041 3.57344 25.386C1.97004 23.768 1.06722 21.5843 1.05985 19.3063C1.06003 18.8892 1.09059 18.4727 1.1513 18.06Z'
                        fill='black' />
                </g>
                <defs>
                    <clipPath id='clip0'>
                        <rect width='19.4034' height='29' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </OnHoverTooltip>;
	}
}

class Sel extends React.PureComponent {
	render() {
		return <OnHoverTooltip message='SEL'>
            <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29'
                 fill='none'>
                <g clipPath='url(#clip0)'>
                    <path
                        d='M25.6993 11.7864C25.5666 10.5978 25.2967 9.42849 24.8952 8.30188C24.1798 6.41647 23.0313 4.7256 21.5423 3.36572C20.0532 2.00583 18.2654 1.015 16.323 0.47315C14.3806 -0.0686943 12.3381 -0.146368 10.3601 0.246386C8.38219 0.639139 6.52427 1.49132 4.93626 2.73419C3.34825 3.97706 2.07462 5.5758 1.21813 7.40143C0.361645 9.22706 -0.0537125 11.2284 0.00555966 13.2441C0.0648319 15.2598 0.597078 17.2333 1.55936 19.0055C2.52164 20.7776 3.88701 22.2988 5.54531 23.4462V29H6.61747V22.894L6.16984 22.567C4.65737 21.5135 3.41321 20.1198 2.5374 18.498C1.6616 16.8761 1.17858 15.0714 1.1272 13.2289C1.07581 11.3864 1.45749 9.55752 2.24154 7.88938C3.0256 6.22124 4.19015 4.76036 5.64156 3.6242C7.09297 2.48804 8.79073 1.70831 10.5983 1.3477C12.4059 0.9871 14.2729 1.05569 16.0492 1.54795C17.8254 2.04021 19.4614 2.94242 20.8255 4.18201C22.1897 5.4216 23.2439 6.964 23.9034 8.68518C24.2793 9.75493 24.5285 10.865 24.6459 11.9928V12.0652L24.7076 12.2072C25.836 14.2149 27.2566 18.2221 27.4737 19.3398C27.5104 19.5092 27.5329 19.6813 27.5407 19.8544H24.6807L24.6539 20.361C24.5521 22.1462 24.083 23.9528 23.6649 25.5691C23.6347 25.6834 23.5677 25.7845 23.4741 25.8567C23.3806 25.9289 23.2657 25.9682 23.1476 25.9685H18.0816V29H19.1537V27.0513H23.1476C23.5072 27.0517 23.8565 26.9314 24.1398 26.7099C24.4231 26.4883 24.6239 26.1782 24.7102 25.8291C25.1669 24.2266 25.4932 22.5898 25.6859 20.9346H27.463C27.9455 20.9346 28.2403 20.8355 28.4172 20.6184C28.6397 20.3503 28.6853 19.8786 28.5325 19.1388C28.2993 17.9567 26.8867 13.9388 25.6993 11.7864Z'
                        fill='black' />
                    <path
                        d='M5.50242 12.0946C5.50455 10.0806 6.30555 8.14972 7.72967 6.7256C9.15379 5.30148 11.0847 4.50049 13.0987 4.49836C14.7786 4.50049 16.3891 5.16875 17.5769 6.35662C18.7648 7.54448 19.4331 9.15495 19.4352 10.8348C19.4338 12.2334 18.8776 13.5743 17.8886 14.5633C16.8997 15.5522 15.5587 16.1085 14.1601 16.1099C13.0004 16.1085 11.8886 15.6471 11.0685 14.8271C10.2485 14.007 9.78714 12.8952 9.78572 11.7355C9.78643 10.7767 10.1676 9.85739 10.8456 9.17944C11.5235 8.50149 12.4428 8.12029 13.4016 8.11958C15.1948 8.11958 16.0123 9.2936 16.0123 10.3872C16.0187 10.9147 15.8164 11.4235 15.4494 11.8025C15.2126 12.0236 14.9337 12.1948 14.6293 12.3058C14.3249 12.4167 14.0012 12.4653 13.6777 12.4484V13.5206C15.9131 13.5206 17.0845 11.9445 17.0845 10.3872C17.0845 8.72804 15.8193 7.04741 13.4016 7.04741C12.1587 7.04883 10.9671 7.54322 10.0882 8.42209C9.20935 9.30096 8.71498 10.4926 8.71356 11.7355C8.71568 13.1793 9.2902 14.5635 10.3112 15.5844C11.3322 16.6054 12.7163 17.1799 14.1601 17.182C15.8431 17.1806 17.4567 16.5115 18.6467 15.3214C19.8368 14.1314 20.5059 12.5178 20.5074 10.8348C20.5052 8.87059 19.724 6.98741 18.3351 5.59848C16.9461 4.20955 15.063 3.42832 13.0987 3.42619C10.8003 3.42832 8.59672 4.34227 6.97154 5.96746C5.34635 7.59265 4.43239 9.79627 4.43026 12.0946C4.43026 17.7235 8.68139 21.9827 14.3183 21.9827V20.9105C9.28181 20.9105 5.50242 17.1204 5.50242 12.0946Z'
                        fill='black' />
                </g>
                <defs>
                    <clipPath id='clip0'>
                        <rect width='28.622' height='29' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </OnHoverTooltip>;
	}
}