import React from 'react';
import {MoveButton} from 'modules/forms/class-form/components/move-button';
import {CheckboxListBox} from 'modules/forms/class-form/components/checkbox-list-box';
import {ClassFormService} from '../../service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ClassFormHeader} from 'modules/forms/class-form/components/header';
import styles from './styles.module.less';
import {checkboxOptionsMode, getArrowDirection} from 'modules/forms/class-form/components/form/utils';


interface Props {
	classID,
	service: ClassFormService
}

export function ClassForm({classID, service}: Props) {
	const availableStudents = useBehaviorSubject(service.availableStudents);
	const studentsInClass = useBehaviorSubject(service.studentsInClass);
	const className = useBehaviorSubject(service.className);

	const isMoveDisabled = (): boolean => {
		const students = availableStudents.concat(studentsInClass);
		const checked = students.filter(student => student.checked);
		return !checked.length;
	};

	return <div className={styles.classForm} data-cy='class-form'>
		<ClassFormHeader classID={classID} className={className} formController={service.form}/>
		<div className={styles.classFormBody}>
			<CheckboxListBox
				items={availableStudents}
				onCheckboxClick={service.selectByFilterAvailableStudents}
				onItemCheckboxClick={service.selectAvailableStudent}
				isChecked={checkboxOptionsMode(availableStudents)}
				label={'Available Students'}/>

			<MoveButton moveDisabled={isMoveDisabled()} onClick={service.move}
			            arrowDirection={getArrowDirection(availableStudents, studentsInClass)}/>
			<CheckboxListBox
				items={studentsInClass}
				onCheckboxClick={service.selectByFilterInClassStudents}
				onItemCheckboxClick={service.selectStudentsInClass}
				isChecked={checkboxOptionsMode(studentsInClass)}
				withLabelTooltip
				label={className || 'New class'}/>
		</div>
	</div>;
}
