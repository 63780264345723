import {useMatch, useSearchParams} from 'react-router-dom';
import {isNull} from 'underscore';
import {CommonURLParams, URLParams, DasboardEntityType, StudentDashboardSearchKey} from '../types';
import {useMemo} from 'react';
import {SubjectType} from '@esgi/main/libs/store';

const URLPattern = '/home/test-dashboard/:testID/subject/:subjectType/:subjectID/:entityType/:entityID';

export function useParseURL() {
	const match = useMatch(URLPattern);
	const [searchParams] = useSearchParams();

	return useMemo<URLParams | null>(() => {
		if (isNull(match)) {
			return null;
		}

		const {testID, subjectType, subjectID, entityType, entityID} = match.params;

		const commonURLParams: CommonURLParams = {
			subjectID: Number(subjectID),
			subjectType: subjectType as SubjectType,
			testID: Number(testID),
		};

		if (entityType === 'class') {
			return {
				entityType: DasboardEntityType.Class,
				classID: Number(entityID),
				...commonURLParams,
			};
		}

		if (entityType === 'group') {
			return {
				entityType: DasboardEntityType.Group,
				groupID: Number(entityID),
				...commonURLParams,
			};
		}

		if (entityType === 'student') {
			const studentFromClassID = searchParams.get(StudentDashboardSearchKey.StudentFromClassID);
			const studentFromGroupID = searchParams.get(StudentDashboardSearchKey.StudentFromGroupID);

			return {
				entityType: DasboardEntityType.Student,
				studentID: Number(entityID),
				studentFromClassID: studentFromClassID ? Number(studentFromClassID) : null,
				studentFromGroupID: studentFromGroupID ? Number(studentFromGroupID) : null,
				...commonURLParams,
			};
		}

		throw new Error('entityType is not class, group or student');
	}, [match, searchParams]);
}
