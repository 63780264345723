import {BaseService} from '@esgi/core/service';
import {FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {SightWordsPreviewFormControls} from './types';
import {filter, Subject} from 'rxjs';
import {ReportGeneratorService} from '../../shared/templates/sight-words/report-generator-service';
import {SightWordsReport} from '../../shared/templates/sight-words/types';
import {SightWordsPdfDownloadingService} from '../../shared/services/sight-words-pdf-downloading-service';
import {SightWordsPrintSettings, StudentStepState} from '../../shared/types';

export default class DataService extends BaseService {
	private readonly reportGenerator: ReportGeneratorService;
	public readonly form: FormGroup<SightWordsPreviewFormControls, any>;
	public readonly downloadPdfService: SightWordsPdfDownloadingService;

	private settings: SightWordsPrintSettings;
	private unitData: StudentStepState;
	private report: SightWordsReport;

	private readonly selectedCount = new Subject<number>();
	public readonly selectionChanged$ = this.completeOnDestroy(this.selectedCount);

	constructor() {
		super();
		this.form = this.createForm();
		this.reportGenerator = new ReportGeneratorService();
		this.downloadPdfService = new SightWordsPdfDownloadingService();
	}

	public init(unitData: StudentStepState, settings: SightWordsPrintSettings) {
		this.unitData = unitData;
		this.settings = settings;
		this.form.controls.selectedQuestionIds.value = settings.selectedQuestions.map(x => x.questionID);
	}

	public destroy() {
		super.destroy();
		this.form.destroy();
		this.downloadPdfService.destroy();
	}

	private generateReport() {
		const action = this.reportGenerator.generateReport(this.unitData, this.settings);
		this.completeOnDestroy(action).subscribe(result => {
			this.report = result;
		});
	}

	public selectAllQuestions() {
		this.form.controls.selectedQuestionIds.value = this.settings.allQuestions.map(x => x.questionID);
	}

	public removeQuestionSelection() {
		this.form.controls.selectedQuestionIds.value = [];
	}

	private createForm() {
		const form = new FormGroup({
			selectedQuestionIds: new FormControl([], {validators: [Validators.required()]}),
		});
		this.completeOnDestroy(form.controls.selectedQuestionIds.onChanged
			.pipe(filter(v => v.reason === 'value')))
			.subscribe(change => {
				const selectedQuestionIds = change.currState.value;
				this.settings.selectedQuestions = this.settings.allQuestions.filter(x => selectedQuestionIds.includes(x.questionID));
				this.selectedCount.next(selectedQuestionIds.length);
				this.generateReport();
			});
		return form;
	}

	public downloadPdf() {
		return this.downloadPdfService.downloadPdf(this.report);
	}

	public getCurrentSettings() {
		return {
			...this.settings,
			selectedQuestions: this.settings.allQuestions.filter(x => this.form.controls.selectedQuestionIds.value.includes(x.questionID)),
		}
	}
}
