import {FormField, Validators} from '@esgi/deprecated/knockout';
import {AsyncFieldContainer} from '../../../kit/component';
import {ValidationServer} from '../../server';
import {resolvedPromise} from '@esgi/deprecated/utils';

import './email-input.less';

export class EmailInputComponent extends AsyncFieldContainer<string> {
	public iconClass = ko.computed(() => {
		if (this.field.showSuccessValidation()) {
			return {'fa-check': true};
		}
		if (this.field.showErrorValidation()) {
			return {'fa-close': true};
		}
		return false;
	});
	public validationResult: KnockoutObservable<EmailValidationResult> = ko.observable<EmailValidationResult>({isExist: false, value: '', isValid: true});
	private readonly autofocus: boolean = false;
	private isEmailExist: boolean;
	private notAllowedEmails: KnockoutObservableArray<string> = ko.observableArray<string>([]);
	private environmentError = ko.observable<boolean>(false);

	constructor(initValue?, autofocus = false, withoutExistValidator = false) {
		super({initValue, withoutExistValidator});
		this.autofocus = autofocus;
	}

	public setNotAllowedEmails(emails: string[]) {
		//https://github.com/babel/babel/issues/8280. Babel behavior. It will override properties initialized by super call.
		this.notAllowedEmails(emails);
	}

	public buildField({initValue, withoutExistValidator}): FormField<string> {
		const checkEmailNotAllowed = Validators.Custom((v) => {
			return resolvedPromise(this.notAllowedEmails.indexOf(v.value().trim().toLowerCase()) === -1);
		}, _ => '<div>Sorry. This email address has already been used when applying this activation code.</div><div>Please contact support@esgisoftware.com if you have any questions</div>');
		const checkEmailExistenceValidator = Validators.Ajax((v) => {
			this.inProcess(true);
			return ValidationServer.checkEmailExistence(v.value()).done((r) => {
				this.isEmailExist = r.exist;
				this.environmentError(r.enviromentError);
			}).always(v => {
				this.inProcess(false);
			});
		}, _ => _.enviromentError && '<div>Please go to <a href=\'https://www.esgisoftware.com/\'>www.esgisoftware.com</a></div> to create a new account.' || '');
		const f = this.createField(initValue || '',
			Validators.Required('<div>Please enter your email.</div>'),
			Validators.Email('<div>Email format is invalid. Please try again.</div>'),
			checkEmailNotAllowed,
		);

		if (initValue) {
			f.disabled(true);
		}

		if (!withoutExistValidator) {
			f.validation.validators.push(checkEmailExistenceValidator);
		}

		f.validation.successValidation(true);
		f.validation.showValidation(true);
		f.validation.validationMessageTitleUseHtml = true;
		f.validation.errorPosition = 'bottom';
		f.value.subscribe(() => {
			this.isEmailExist = false;
			this.setNotAllowedEmails([]);
		});
		f.validation.validationResults.subscribe(v => {
			this.validationResult({
				isExist: this.isEmailExist,
				value: this.field.value(),
				isValid: !v.length,
			});
		});
		return f;
	}

	public afterRender(rootElement: JQuery): JQueryPromise<any> {
		if (this.autofocus) {
			$('#email', rootElement).focus();
		}
		return super.afterRender(rootElement);
	}

	public template = () =>
    <div data-bind='var : {root: $data}, afterRender: true'>
      <div data-bind='with: field'>
        <div className='form-group email-field'
             data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
          <label className='control-label'>Email</label>
          <input id='email' name='email' type='email' className='form-control input-md' data-bind='value: value, disable: disabled'/>
          <div className='error-message visible-xs visible-sm hidden-md'>
            <span data-bind='text:validation.validationResults'/>
          </div>
          <ko data-bind='if: !root.inProcess()'>
            <i data-bind="css: {'fa-check': valid() && showSuccessValidation, 'fa-close': !valid(), 'fa form-control-feedback': root.iconClass()}"/>
          </ko>
          <ko data-bind='if: root.inProcess()'>
            <i className='fa fa-spinner fa-spin form-control-feedback'/>
          </ko>
        </div>
      </div>
    </div>;
}

export interface EmailValidationResult {
  isExist: boolean;
  isValid: boolean;
  value: string;
}
