import './styles.less';
import {GradeScalesApi} from 'api/grade-scales-api';
import {computed, computedArray, observable, observableArray, Renderable} from '@esgi/deprecated/knockout';
import {HierarchyMode} from 'modules/hierarchy/core/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import React from 'react';
import {SubjectType} from '@esgi/core/enums';
import {ResizableTable} from './resizable-table/resizable-table';
import {UserInfo, UserType} from '@esgi/core/authentication';
import {isMobile} from '@esgillc/ui-kit/utils';
import {
	GradeScaleHierarchyLevel,
	IConfigurationInitResponse,
	IGradeRangeInfo,
	IReportResponse,
	SubjectModel,
	TooltipModel,
} from 'shared/modules/reports/grade-scale-report/models';
import {openModal} from '@esgi/deprecated/react';
import {GradeRangeType, GradeScaleType, IReportGradeScale, SubjectLevel} from 'shared/modules/grade-scale/models';
import Wizard from 'shared/modules/grade-scale/wizard/component';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {HttpClient} from '@esgi/api';
import {tap} from 'rxjs/operators';

export class ReportConfigurationForm extends Renderable {
	constructor(
		public level: GradeScaleHierarchyLevel,
		private subjectID: number,
		private subjectType: SubjectType,
		public user: UserInfo,
		public hierarchy: HierarchySnapshot,
	) {
		super();
		this.api = new GradeScalesApi();
	}

	@observable()
	isResizableTableLoaded = false;

	@observableArray()
	checked: TestModel[] = [];

	@observableArray()
	subjects: SubjectModel[] = [];

	@observable({subscribe: ['selectedSubjectChanged']})
	selectedSubject: SubjectModel = null;

	selectedSubjectChanged(s: SubjectModel) {
		if (!s) {
			return;
		}

		let tests = this.testsMap[s.subjectID];
		if (tests) {
			this.model = tests;
			this.checkTestsHavingResults();

		} else {
			this.reloadTests(s).subscribe(() => {
				this.checkTestsHavingResults();
			});
		}

		$(this).trigger('subjectChanged', {id: s.subjectID, type: SubjectType[s.subjectType]});
	}

	@observable()
	model: ReportConfigurationModel = null;

	isMobile = isMobile();

	shownTooltip: TooltipModel = null;

	@computed()
	get readonly() {
		if (!this.model) {
			return false;
		}

		return this.model.readonly;
	}

	@computed()
	get canEditGradeRange() {
		const r = this.readonly;
		if (r) {
			return false;
		}

		if ([UserType.T, UserType.ISD, UserType.ISS].indexOf(this.user.userType) != -1) {
			return this.gradeScale.type === GradeScaleType.Teacher && this.gradeScale.ownerID === this.user.userID;
		} else if (this.user.userType === UserType.C) {
			return this.gradeScale.type === GradeScaleType.School;
		} else if (this.user.userType === UserType.D) {
			return this.gradeScale.type === GradeScaleType.District;
		}

		return true;
	}

	@computed()
	get managedByLabelText() {
		const type = this.gradeScale.type;

		const userType = this.user.userType;

		switch (type) {
			case GradeScaleType.District: return userType !== UserType.D ? '(Managed by district)' : '';
			case GradeScaleType.School: return userType !== UserType.C ? '(Managed by school)' : '';
			case GradeScaleType.Teacher:
				if (userType !== UserType.T && this.hierarchy.mode == HierarchyMode.Classic ) {
					return '(Managed by teacher)';
				} else if (userType !== UserType.ISD && userType !== UserType.ISS && this.hierarchy.mode == HierarchyMode.Specialist ) {
					return '(Managed by specialist)';
				} else {
					return '';
				}
			default: return '';
		}
	}

	allValidChecked = ko.computed({
		read: () => {
			let tests = this.tests;
			return !tests.filter(s => !s.invalid()).filter(s => this.checked.indexOf(s) === -1).length;
		},
		write: (val) => {
			this.toggleCheckAll(val);
		},
		pure: true,
		deferEvaluation: true,
	});

	@computed()
	get gradeScale() {
		if (this.model) {
			return this.model.gradeScale;
		}

		return null;
	}

	@computedArray({subscribe: ['testsChanged']})
	get tests() {
		if (this.model) {
			return this.model.tests;
		}

		return [];
	}

	testsChanged() {
		if (this.resizableTable) {
			this.resizableTable.sync();
		}
	}

	@computed()
	get showDismissButton() {
		if (this.tests && this.tests.length) {
			return !!this.tests.find(t => t.invalid());
		}

		return false;
	}

	allowDismiss = ko.pureComputed({
		read: () => {
			if (this.tests && this.tests.length) {
				return !!this.tests.find(t => t.invalid());
			}

			return false;
		},
		deferEvaluation: true,
	});

	testsMap: { [id: number]: ReportConfigurationModel; } = {};

	api: GradeScalesApi;

	resizableTable: ResizableTable;

	private reloadTests = (selectedSubject?) => {
		if (!selectedSubject) {
			selectedSubject = this.selectedSubject;
		}
		return this.server.loadTests(selectedSubject)
			.pipe(tap(model => {
				let m = ReportConfigurationModel.FromResponse(model);
				this.testsMap[selectedSubject.id] = m;

				let checked = this.checked.map(s => s.id);
				let tests = m.tests;
				this.checked = [];

				for (let i = 0; i < tests.length; i++) {
					if (checked.indexOf(tests[i].id) > -1) {
						this.checked.push(tests[i]);
					}
				}

				this.model = m;
			}));
	}

	load(): JQueryPromise<any> {
		const dif = $.Deferred();
		this.server.init(this.subjectID, this.subjectType).subscribe(() => dif.resolve());
		return dif.promise();
	}

	private server =
		{
			init: (subjectID: number, subjectType: SubjectType) => {
				return HttpClient.default.ESGIApi.get<IConfigurationInitResponse>('reports/grade-report-setting', 'init-report-configuration-form', {
						subjectID: subjectID,
						subjectType: subjectType,
						fullHierarchy: this.hierarchy,
					})
					.pipe(tap(r => {
						this.subjects = r.subjects;
						let selectedSubject = this.subjects.filter(s => s.subjectID === r.selectedSubject.subjectID)[0];
						let report = ReportConfigurationModel.FromResponse(r.report);

						if (selectedSubject) {
							this.testsMap[selectedSubject.subjectID] = report;
						}
						this.selectedSubject = selectedSubject;
					}));
			},
			loadTests: (subject: SubjectModel) => {
				return HttpClient.default.ESGIApi.get<IReportResponse>('reports/grade-report-setting',
					'get-tests-for-report-configuration',
					{
						subjectId: subject.subjectID,
						subjectType: subject.subjectType,
						subjectLevel: subject.level,
						subjectHasGradeScales: subject.hasGradeScales,
						checkInvalid: true,
						fullHierarchy: this.hierarchy,
					});
			},
			dismiss: (gradeScaleId: number, testId: number) => {
				return HttpClient.default.ESGIApi.post('reports/grade-report-setting',
					'dismiss',
					{data: {gradeScaleId: gradeScaleId, testId: testId}});
			},
			dimsissExclamationMarks: () => {
				return this.api.dismissAllWarnings(this.gradeScale.id, this.selectedSubject.subjectID, this.selectedSubject.subjectType, this.selectedSubject.level, this.hierarchy)
					.subscribe((fixedIds) => {
						for (let i = 0; i < this.tests.length; i++) {
							this.tests[i].invalid(false);
							if (this.tests[i].hasResults) {
								this.checked.push(this.tests[i]);
							}
						}
					});
			},
			updateGradeReport: () => {
				let subject = this.selectedSubject;
				return HttpClient.default.ESGIApi.post('reports/grade-report-result',
					'update-grade-report',
					{
						gradeScaleId: this.model.gradeScale.id,
						subjectId: subject.subjectID,
						subjectType: subject.subjectType,
						level: subject.level,
					});
			},
		}



	formatGradeRangeName(test: TestModel) {
		if (!test.gradeRangeInfo()){
			return 'Not configured yet';
		}

		if (test.gradeRangeInfo().gradeRangeType === GradeRangeType.Default) {
			return 'Default Percentage';
		}

		if (test.gradeRangeInfo().gradeRangeType === GradeRangeType.Custom) {
			return 'Custom Score';
		}

		if (test.gradeRangeInfo().gradeRangeType === GradeRangeType.Shared) {
			return 'Shared Scale';
		}
	}

	dismissTest(test: TestModel) {
		if (this.shownTooltip != null) {
			this.shownTooltip.tooltip.bstooltip('destroy');
			this.shownTooltip = null;
		}

		this.server.dismiss(this.gradeScale.id, test.id);
		test.invalid(false);
		test.invalidMessage('');
		if (test.hasResults) {
			this.checked.push(test);
		}
	}

	openRangeEditor(test: TestModel, onSaved: () => void){
		let wizardStep = WizardStep.None;
		switch (test.gradeRangeInfo().gradeRangeType) {
			case GradeRangeType.Default: {
				wizardStep = WizardStep.DefaultGradeRange;
				break;
			}
			case GradeRangeType.Custom: {
				wizardStep = WizardStep.CustomGradeRange;
				break;
			}
			case GradeRangeType.Shared: {
				wizardStep = WizardStep.SharedGradeRange;
				break;
			}
		}
		const component = openModal(
			<Wizard
				hierarchy={this.hierarchy}
				subjectID={this.selectedSubject.subjectID}
				testID={test.id}
				initialStep={wizardStep}
				gradeScaleID={this.gradeScale.id}
				onClosed={() => {
					component.close();
				}}
				onGradeRangeSaved={() => onSaved()}
			/>,
		);
	}

	editRangeClicked(test: TestModel) {
		let onSaved = () => {};

		if (!this.canEditGradeRange) {
			onSaved = () => {
				setTimeout(() => {
					this.dismissTest(test);
				}, 1000);
			};
		} else {
			onSaved = () => {
				this.reloadTests().subscribe(() => {
					if (test.invalidLevel() === 'Error') {
						this.dismissTest(test);
					}
				});
			};

			if (test.invalidLevel() === 'Warning') {
				this.dismissTest(test);
			}
		}

		this.openRangeEditor(test, onSaved);
	}

	editScaleClicked() {
		const component = openModal(
			<Wizard
				hierarchy={this.hierarchy}
				initialStep={WizardStep.GradeScale}
				subjectID={this.subjectID}
				subjectLevel={SubjectLevel[this.selectedSubject.level]}
				gradeScaleID={this.model.gradeScale.id}
				onClosed={() => component.close()}
				onGradeRangeSaved={() => this.reloadTests().subscribe()}
				onGradeScaleSaved={(gradeScale) => {
					this.model.gradeScale = gradeScale;
					for (let id in this.testsMap) {
						let tests = this.testsMap[id];
						if (tests.gradeScale.id == gradeScale.id) {
							tests.gradeScale = gradeScale;
						}
					}

          const selectedSubject = this.subjects.filter(x => x.subjectID == this.selectedSubject.subjectID)[0];
          selectedSubject.hasGradeScales = true;
          this.selectedSubject = selectedSubject;

					this.server.updateGradeReport().subscribe(() => {
						this.reloadTests().subscribe();
					});
				}}
			/>,
		);
	}

	exclamationClicked(test: TestModel, event: Event) {
		if (this.isMobile) {
			let create = this.shownTooltip == null || this.shownTooltip.index != test.id;

			if (this.shownTooltip != null) {
				this.shownTooltip.tooltip.bstooltip('destroy');
				this.shownTooltip = null;
			}

			if (create) {
				let element = $(event.target);
				this.shownTooltip = new TooltipModel();

				this.shownTooltip.index = test.id;
				this.shownTooltip.tooltip = element.bstooltip({
					trigger: 'manual',
					container: $(element).parents('#modal-form'),
					title: $(element).attr('title'),
					placement: 'top',
				});
				this.shownTooltip.tooltip.bstooltip('show');

				let _that = this;
				$(element).parents('#modal-form').bind('click', function (event) {
					if (!$(event.target).hasClass('fa-exclamation-triangle')) {
						if (_that.shownTooltip != null) {
							_that.shownTooltip.tooltip.bstooltip('destroy');
							_that.shownTooltip = null;
						}
						$('#modal-form').unbind('click');
					}
				});
			}

			return;
		}

		if (!this.readonly) {
			if (test.invalidLevel() === 'Warning') {
				this.server.dismiss(this.gradeScale.id, test.id);
				test.invalid(false);
				test.invalidMessage('');
				if (test.hasResults) {
					this.checked.push(test);
				}
			}

			this.openRangeEditor(test, () =>{
				this.reloadTests().subscribe(() =>{
					if (test.invalidLevel() === 'Error') {
						this.server.dismiss(this.gradeScale.id, test.id);
						test.invalid(false);
						test.invalidMessage('');
					}

					if (test.hasResults) {
						this.checked.push(test);
					}
				});
			});
		}
	}

	dismissAllExclamationClicked() {
		return this.server.dimsissExclamationMarks();
	}

	hasNotConfiguredSet() {
		return !!this.tests.filter(t => t.gradeRangeInfo() == null).length;
	}

	hasInvalidTest() {
		return !!this.tests.filter(t => t.invalid()).length;
	}

	hasNoOwnSubjects() {
		if ([UserType.T, UserType.ISD, UserType.ISS].indexOf(this.user.userType) != -1){
			return !this.subjects.filter(t => t.subjectType == 'Personal').length;
		}

		return false;
	}

	viewingPersonalSubjectByAdmin() {
		return ([UserType.T, UserType.ISD, UserType.ISS].indexOf(this.user.userType) == -1 && this.selectedSubject.subjectType == 'Personal') || (this.user.userType == UserType.D && this.selectedSubject.subjectType == 'Deployed' && this.selectedSubject.level == 'School');
	}

	editGSTooltipText() {
		if (this.viewingPersonalSubjectByAdmin()) {
			return 'Sorry, you can only edit Grade Scales and Grade Ranges in ' + (this.user.userType === UserType.D ? 'Purple' : 'Blue') + ' tabs';
		}
		return null;
	}

	afterRender(rootElement): JQueryPromise<any> {
		super.afterRender(rootElement);

		this.resizableTable = new ResizableTable(rootElement, 250);
		this.resizableTable.load();
		this.isResizableTableLoaded = true;


		return $.Deferred().resolve().promise();
	}

	checkTestsHavingResults() {
		this.checked = [];
		let tests = this.tests;
		for (let i = 0; i < tests.length; i++) {
			if (!tests[i].invalid() && tests[i].hasResults) {
				this.checked.push(tests[i]);
			}
		}
	}

	toggleCheckAll(val: boolean) {
		if (!val) {
			this.checked = [];
			return;
		}

		this.checked = [];
		let tests = this.tests;
		for (let i = 0; i < tests.length; i++) {
			if (!tests[i].invalid()) {
				this.checked.push(tests[i]);
			}
		}

		return false;
	}

	template = () => {
		return <div className='gs-report-configuration' data-bind='afterRender: true'>
			<div className='report-header'>
				<div className='col-xs-6 col-sm-6'>
					<h5>Subject Tab:</h5>
					<select id='gs-select-subject' className='form-control'
					        data-bind="options: subjects, optionsText: 'name', value: selectedSubject "/>
				</div>
				<ko data-bind='with: gradeScale'>
					<div className='col-xs-6 col-sm-6 grading-scale'>
            <ko data-bind='if: $root.selectedSubject.subjectType == "Deployed" || $root.selectedSubject.hasGradeScales'>
              <div className='top'>
                <h5 className='pull-left'>Grade Scale: <span className='managed-by-level' data-bind='text: $parent.managedByLabelText'/></h5>
                <button className='btn btn-primary pull-right edit-gs-button'
                        data-bind='click: function() { if (!$parent.viewingPersonalSubjectByAdmin()) $parent.editScaleClicked(); }, visible: !$parent.readonly, attr: { title: $parent.editGSTooltipText() }, css: { nonclickable: $parent.viewingPersonalSubjectByAdmin() }' data-toggle='tooltip'>
                  <i className='ace-icon fa fa-pencil bigger-110'/>
                  <span className='bolder'> EDIT</span>
                </button>
              </div>
              <hr/>
              <div className='bottom' style={{justifyContent: 'space-around'}}>
                <ko data-bind='if: !$parent.hasNotConfiguredSet()'>
                  <ko data-bind='foreach: entries'>
                    <div data-bind='text: gradeName, attr: { title: description }'
                         data-toggle='tooltip'/>
                  </ko>
                </ko>
              </div>
            </ko>
            <ko data-bind='if: $root.selectedSubject.subjectType == "Personal" && !$root.selectedSubject.hasGradeScales'>
              <button className='btn btn-primary pull-right edit-gs-button'
                      data-bind='click: function() { $parent.editScaleClicked(); }, attr: { title: $parent.editGSTooltipText() }' data-toggle='tooltip'>
                <i className='ace-icon fa fa-pencil bigger-110'/>
                <span className='bolder'> + GRADE SCALE</span>
              </button>
            </ko>
					</div>
				</ko>
			</div>

			<div className='report-body'>
				<table className='table table-bordered table-striped table-hover grid-header'>
					<thead>
					<tr>
						<th data-resizable-column-id='gs-report-tests-checkbox'>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: allValidChecked'/>
								<span className='lbl'/>
							</label>
						</th>
						<th data-resizable-column-id='gs-report-tests-include'>
							<span className='gs-table-header-text'>Tests to Include</span>
						</th>
						<th className='row' data-resizable-column-id='gs-report-grade-range'>
							<span className='gs-table-header-text'>Grade Ranges</span>
						</th>
					</tr>
					</thead>
				</table>

				<div className='grid-body-wrapper'>
					<table className='table table-bordered table-striped table-hover grid-body'
					       style={{display: 'none'}} data-bind='visible: isResizableTableLoaded'>
						<tbody data-bind='foreach: tests'>
						<tr data-bind="attr: { 'data-name': name }">
							<td>
								<ko data-bind='if: invalid'>
									<i className='fa fa-exclamation-triangle' aria-hidden='true'
									   data-bind="click: function (data, event) { $parent.exclamationClicked(data, event) }, attr: { title: invalidMessage }, bsTooltip:{placement:'right',container: 'body'}"/>
								</ko>
								<ko data-bind='ifnot: invalid'>
									<label>
										<input type='checkbox'
										       className='ace test-checkbox'
										       data-bind='checked: $parent.checked, value: $data'/>
										<span className='lbl'/>
									</label>
								</ko>
							</td>
							<td className='gs-test-name-cell'>
								<span className='gs-test-name' data-bind='text: name'/>
							</td>
							<td>
								<div className='pull-left gs-range-name'>
									<span data-bind='text: $parent.formatGradeRangeName($data)'/>
									<ko data-bind='if: gradeRangeInfo()'>
										<ko data-bind="if: gradeRangeInfo().gradeScaleType === 'District'">
											<span style={{color: 'orange'}}>District <i className='fa fa-flag'/></span>
										</ko>
										<ko data-bind="if: gradeRangeInfo().gradeScaleType === 'School'">
											<span style={{color: 'orange'}}>School <i className='fa fa-flag'/></span>
										</ko>
									</ko>
								</div>
								<ko data-bind='if: gradeRangeInfo()'>
									<ko data-bind='if: $parent.canEditGradeRange'>
										<i className='ace-icon fa fa-pencil bigger-150 pull-right icon-warning test-edit-gr-link' data-bind='click: !$parent.viewingPersonalSubjectByAdmin() ? $parent.editRangeClicked.bind($parent, $data) : null,
                                                        attr: {
                                                            title: $parent.editGSTooltipText()
                                                        },
                                                        css: { nonclickable: $parent.viewingPersonalSubjectByAdmin() }' data-toggle='tooltip' />
									</ko>
									<ko data-bind='ifnot: $parent.canEditGradeRange'>
										<a href='#' className='pull-right test-view-gr-link' style={{marginRight: '5px'}}
										   data-bind='click: $parent.editRangeClicked.bind($parent, $data)'>View</a>
									</ko>
								</ko>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
        <ko data-bind='if: $root.selectedSubject.subjectType == "Personal" && !$root.selectedSubject.hasGradeScales'>
          <div className='overflow-modal'>
            <div className='notification'>Please, <a href='#' data-bind='click: function() { editScaleClicked(); }'>add a grade scale</a> to run a report for this subject tab.</div>
          </div>
        </ko>
			</div>

			<span data-bind='if: !hasNotConfiguredSet() && readonly'
			      className='not-configured-set-info'>
        <span data-bind='if: hasNoOwnSubjects()'>
          Sorry, you do not have any self-managed subject tabs to configure grade scales against.
        </span>
        You're currently only able to run this report against District or School managed subjects.
        <span data-bind='if: hasNoOwnSubjects()'>
          You can create your own subject tabs within Test Explorer, then return to this page to configure your scales.
        </span>
    </span>

			<span data-bind='if: hasNotConfiguredSet()' className='not-configured-set-info'>
        <ko data-bind="if: selectedSubject.level == 'District'">
                    Sorry. Your District Administrator has not yet configured Grade Scales which prevents you from running the Grade Report on this tab. Please contact your District Administrator and request that they create Grade Scales for your purple tabs. If you do not know who your District administrator is, please contact support@esgisoftware.com. Please note that you can continue to use Grade Scales on your gray tabs.
                    </ko>
                    <ko data-bind="if: selectedSubject.level == 'School'">
                    Sorry. Your School Administrator has not yet configured Grade Scales which prevents you from running the Grade Report on this tab. Please contact your School Administrator and request that they create Grade Scales for your blue tabs. If you do not know who your School administrator is, please contact support@esgisoftware.com. Please note that you can continue to use Grade Scales on your gray tabs.
                    </ko>
                </span>
		</div>;
	}

	events = {
		subjectChanged: (callback: (_, subject: {id: number, type: SubjectType}) => any) => {
			$(this).on('subjectChanged', callback);
		},
	}
}



class TestModel {
	id: number;
	name = ko.observable<string>();
	invalid = ko.observable<boolean>();
	invalidMessage = ko.observable<string>();
	invalidType = ko.observable<string>();
	invalidLevel = ko.observable<string>();
	gradeRangeInfo = ko.observable<IGradeRangeInfo>();
	hasResults: boolean;
}

class ReportConfigurationModel {
	@observable()
	gradeScale: IReportGradeScale = null;

	@observableArray()
	tests: TestModel[] = [];

	@observable()
	readonly = false;

	public static FromResponse(response: IReportResponse): ReportConfigurationModel {
		let model = new ReportConfigurationModel();
		model.readonly = response.readonly;
		model.gradeScale = response.gradeScale;
		for (let i = 0; i < response.tests.length; i++) {
			let t = response.tests[i];
			let test = new TestModel();
			test.id = t.id;
			test.invalid(t.invalid);
			test.invalidMessage(t.invalidMessage);
			test.invalidLevel(t.invalidLevel);
			test.gradeRangeInfo(t.gradeRangeInfo);
			test.invalidType(t.invalidType);
			test.name(t.name);
			test.hasResults = t.hasResults;
			model.tests.push(test);
		}
		return model;
	}
}
