import {BaseButton} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const SelectableTrackButton = styled(BaseButton, {
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 12,
	paddingRight: 12,
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',
	minHeight: 40,
	width: 232,
	alignItems: 'start',
	justifyContent: 'space-between',
	gap: '$3',
	transition: 'background .3s',
	color: '$neutral40',

	[`& > ${Text}`]: {
		color: 'currentColor',
		wordBreak: 'break-all',
		alignSelf: 'center',
	},

	'&:not(:last-child)': {
		marginBottom: 6,
	},

	variants: {
		selected: {
			true: {
				cursor: 'default',
				borderColor: '$primaryMuted',
				background: 'linear-gradient(272deg, #F3F9FC 48.17%, #F7FBFD 100%)',
				color: '$primary',
			},

			false: {
				'&:hover': {
					borderColor: '$secondaryMuted',
					backgroundColor: '$secondaryBackground',
					color: '$secondary',
				},
			},
		},
	},
});

export const ActiveLabel = styled(GridBox, {
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 8,
	paddingRight: 8,
	borderRadius: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$background',
	marginTop: 3,
});
