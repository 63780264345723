import {VariantProps} from '@stitches/react';
import {Counter, TrackDatesRow, TrackDatesWrapper} from '../../track-dates-with-slider/index.styled';
import moment from 'moment';
import {Text} from '@esgi/ui/typography';
import {DisabledInput} from './disabled-input';
import {TrackModel} from '../../../types';
import {dateFormat} from '../../track-dates-with-slider/constants';

type Props = {
	rowGap: NonNullable<VariantProps<typeof TrackDatesWrapper>['rowGap']>;
	track: TrackModel;
	counterSize?: VariantProps<typeof Counter>['size'];
};

export function DisabledTrackDates({rowGap, track, counterSize = 'medium'}: Props) {
	return (
		<TrackDatesWrapper rowGap={rowGap}>
			{track.trackDates.map(({dateFrom, dateTo, trackDateID}, index) => {
				const dateFromValue = moment(dateFrom).format(dateFormat);
				const dateToValue = moment(dateTo).format(dateFormat);

				return (
					<TrackDatesRow key={trackDateID} dataCy='track-dates-row'>
						<Counter size={counterSize}>
							<Text data-cy='item-counter' size='large' color='neutral40'>
								{`${index + 1}`.padStart(2, '0')}
							</Text>
						</Counter>

						<DisabledInput dataCy='disabled-input-date-from' value={dateFromValue} placeholder='From' />
						<DisabledInput dataCy='disabled-input-date-to' value={dateToValue} placeholder='To' />
					</TrackDatesRow>
				);
			})}
		</TrackDatesWrapper>
	);
}
