import React, {useState} from 'react';
import {CreateAssignmentTeacherService} from '../../../services/create-assignment-teacher-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {AddSpecificModalTeacher} from './add-specific-modal/add-specific-modal-teacher';
import {ClassGroupDropdown} from './class-group-dropdown';
import {StudentsDropdown} from './students-dropdown';
import {StudentInfoTeacher} from '../../../types/teacher-types';
import styles from './styles.module.less';

interface Props {
	service: CreateAssignmentTeacherService;
}

export function DropdownsSectionTeacher({service}: Props) {
	const [showAddModal, setShowAddModal] = useState(false);
	const classes = useBehaviorSubject(service.classes);
	const students = useBehaviorSubject(service.students);
	const selectedStudents = useBehaviorSubject(service.selectedStudents);
	const setSelectedStudents = (value: StudentInfoTeacher[]) => service.setStudents(value);
	const appendSelectedStudents = (students: StudentInfoTeacher[]) => {
		setSelectedStudents([...selectedStudents, ...students]);
	};

	const appendSelectedStudentsByClass = (students: StudentInfoTeacher[]) => {
		setSelectedStudents([...students]);
	};

	const [prevSelectedStudents, setPrevSelectedStudents] = useState<StudentInfoTeacher[]>([]);

	return (
		<div className={styles.dropdownsSection}>
			<div>
				<div className={styles.label}>Classes and Groups</div>
				<ClassGroupDropdown
					classes={classes}
					students={students}
					selectedStudents={selectedStudents}
					onChange={appendSelectedStudentsByClass}
				/>
			</div>
			<div>
				<div className={styles.label}>Students</div>
				<StudentsDropdown
					students={students}
					selectedStudents={selectedStudents}
					setSelectedStudents={setSelectedStudents}
					showAddSpecific={() => setShowAddModal(true)}
					setPrevSelectedStudents={setPrevSelectedStudents}
				/>
			</div>
			{showAddModal && (
				<AddSpecificModalTeacher
					service={service}
					onClose={() => setShowAddModal(false)}
					onSelectedStudentsChange={appendSelectedStudents}
					prevSelectedStudents={prevSelectedStudents}
				/>
			)}
		</div>
	);
}
