import {styled} from '@esgi/ui/theme';

export const UploaderContainer = styled('label', {
	display: 'flex',
	borderRadius: '48px',
	border: '1px solid $blue',
	width: '162px',
	height: '162px',
	cursor: 'pointer',
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '0',
	transition: 'background-color .2s ease, border-color .2s ease, color .2s ease',

	'&:hover': {
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',
		color: '$secondary',
	},

	variants: {
		error: {
			true: {
				borderColor: '$negativeVivid',
				color: '$negativeVivid',
			},
		},
	},

	'& > input': {
		display: 'none',
	},
});

