import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {SelectAllClassesButton} from './compoents/select-all-classes-button';
import {useEffect} from 'react';

export function ClassesContent(props: {withoutAllClass?: boolean}) {
	const {classBuckets} = StudentsPanel.Tabs.Classes.useClassesContext();

	const {
		selectClass,
		selectStudentFromClass,
		selected: {studentFromClassId, studentId: selectedStudentId, classId: selectedClassId},
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	useEffect(()=>{
		if(classBuckets?.length){
			selectClass(classBuckets[0].id);
		}
	}, [classBuckets]);

	return (
		<StudentsPanel.Tabs.TabContent>
			{!props.withoutAllClass && <SelectAllClassesButton/>}

			{classBuckets.length ? (
				classBuckets.map(({id, name, students}) => {
					const isOpen = selectedClassId === id || studentFromClassId === id ;
					return (
						<StudentsPanel.Lists.SelectableListGroup.Expandable.Single
							withCounter
							isListOpen={isOpen && Boolean(students.length)}
							key={id}
							hideArrowIcon={isOpen}
							onTriggerButtonClick={() => selectClass(id)}
							groupName={name}
							onItemClick={(studentId) => selectStudentFromClass({studentId, classId: id})}
							value={selectedStudentId}
							isListSelected={selectedClassId === id}
							items={students}
							allowSelectEmpty
							dataCy='class-list'
						>
							{({item: {firstName, lastName}}) => (
								<StudentsPanel.StudentFullName dataCy='student-fullname' firstName={firstName} lastName={lastName} />
							)}
						</StudentsPanel.Lists.SelectableListGroup.Expandable.Single>
					);
				})
			) : (
				<StudentsPanel.Tabs.EmptyTabContent size='full' templateText='No classes to display' />
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
