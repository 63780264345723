import {CSS} from '@stitches/react';
import {BaseComponentProps, Skeletonable, ThemeColorKeys} from '../../../types';
import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo, useState} from 'react';
import {SkeletonableCircle, Svg} from './index.styled';
import {useBoxplotChartContext} from '../../context';
import {getThemeColor} from '@esgi/ui/utils';
import {getCubeCoordinates} from '../utils/get-cube-coordinates';
import {ArcProgressModel} from './types';
import {animated} from 'react-spring';
import {useAnimationData} from './hooks/use-animation-data';

export type BoxplotChartRoundProps = Omit<BaseComponentProps, 'css'> &
	Skeletonable &
	Omit<ComponentPropsWithoutRef<'svg'>, 'children'> & {
		/**
		 * Returns a Style interface from a configuration, leveraging the given media and style map.
		 * @default {}
		 */
		css?: Omit<CSS, 'width' | 'height'>;

		/**
		 * Set width and height for the main circle in svg.
		 * @default 64
		 */
		size?: number;

		/**
		 * Set color for the active quartile.
		 * @default 'highContrast'
		 */
		activeQuartileColor?: ThemeColorKeys;

		/**
		 * Set color for the non active quartile.
		 * @default 'lowContrast'
		 */
		nonActiveQuartileColor?: ThemeColorKeys;

		/**
		 * Set stroke width for the main circle.
		 * @default 1
		 */
		circleStrokeWidth?: number;

		/**
		 * Set stroke color for the main circle.
		 * @default 'border'
		 */
		circleStrokeColor?: ThemeColorKeys;

		/**
		 * Set fill color for pointer.
		 * @default 'surface'
		 */
		pointerFillColor?: ThemeColorKeys;
	};

export const BoxplotChartRound = forwardRef<SVGSVGElement, BoxplotChartRoundProps>(
	(
		{
			dataCy = 'ui-kit-boxplot-chart-round',
			skeleton,
			css = {},
			size = 64,
			activeQuartileColor = 'highContrast',
			nonActiveQuartileColor = 'lowContrast',
			circleStrokeWidth = 1,
			circleStrokeColor = 'border',
			pointerFillColor = 'surface',
			...svgProps
		},
		forwardedRef,
	) => {
		const {chartMinValueProgress, chartMaxValueProgress, quartile1Progress, quartile2Progress, quartile3Progress} =
			useBoxplotChartContext();

		const [circleCutValue, setCircleCutValue] = useState(0);

		const svgValues = useMemo(() => {
			const adaptedSize = size;

			return {
				size: adaptedSize,
			};
		}, [size]);

		const circleValues = useMemo(() => {
			const circleSize = size - circleCutValue;

			return {
				radius: circleSize / 2,
				center: {
					x: svgValues.size / 2,
					y: svgValues.size / 2,
				},
			};
		}, [size, circleCutValue, svgValues]);

		const cubeInfo = useMemo(() => {
			const diagonalWidth = 10 + circleStrokeWidth;
			const sideWidth = diagonalWidth / Math.sqrt(2);

			const diagonalProgress = diagonalWidth / 360;

			return {
				sideWidth,
				diagonalWidth,
				diagonalProgress,
			};
		}, [circleStrokeWidth]);

		const getCoordinatesByAngle = useCallback(
			(angle: number) => {
				const angleRadian = ((angle - 90) * Math.PI) / 180;

				return {
					x: circleValues.center.x + circleValues.radius * Math.cos(angleRadian),
					y: circleValues.center.y + circleValues.radius * Math.sin(angleRadian),
				};
			},
			[circleValues],
		);

		const {startCube, endCube} = useMemo(() => {
			const startCubeDiagonalProgress = (chartMinValueProgress + cubeInfo.diagonalProgress) * 360;
			const endCubeDiagonalProgress = (chartMaxValueProgress - cubeInfo.diagonalProgress) * 360;

			const {x: startDX1, y: startDY1} = getCoordinatesByAngle(chartMinValueProgress * 360);
			const {x: startDX2, y: startDY2} = getCoordinatesByAngle(startCubeDiagonalProgress);

			const {x: endDX1, y: endDY1} = getCoordinatesByAngle(chartMaxValueProgress * 360);
			const {x: endDX2, y: endDY2} = getCoordinatesByAngle(endCubeDiagonalProgress);

			const startCube = getCubeCoordinates({
				diagonalX1: startDX1,
				diagonalY1: startDY1,
				diagonalX2: startDX2,
				diagonalY2: startDY2,
			});

			const endCube = getCubeCoordinates({
				diagonalX1: endDX1,
				diagonalY1: endDY1,
				diagonalX2: endDX2,
				diagonalY2: endDY2,
			});

			return {startCube, endCube};
		}, [cubeInfo, getCoordinatesByAngle, chartMinValueProgress, chartMaxValueProgress]);

		const nonActiveArcModel = useMemo<ArcProgressModel>(
			() => ({
				startValueProgress: chartMinValueProgress + cubeInfo.diagonalProgress / 2,
				endValueProgress: chartMaxValueProgress - cubeInfo.diagonalProgress / 2,
			}),
			[chartMaxValueProgress, chartMinValueProgress, cubeInfo],
		);

		const activeArcModel = useMemo(() => {
			const progressModel: ArcProgressModel = {
				startValueProgress: quartile1Progress,
				endValueProgress: quartile3Progress,
			};

			return {
				progressModel,
				strokeWidth: circleStrokeWidth + 3,
			};
		}, [circleStrokeWidth, quartile1Progress, quartile3Progress]);

		const getArcValueD = useCallback(
			({startValueProgress, endValueProgress}: {startValueProgress: number; endValueProgress: number}) => {
				const startArcAngle = startValueProgress * 360;
				const endArcAngle = endValueProgress * 360;

				const arcAngle = endArcAngle - startArcAngle;

				const isCircle = arcAngle === 360;

				const {x: x1, y: y1} = getCoordinatesByAngle(startArcAngle);
				const {x: x2, y: y2} = getCoordinatesByAngle(isCircle ? endArcAngle - 1 : endArcAngle);

				const largeArcFlag = arcAngle <= 180 ? 0 : 1;

				const startPosition = `M ${x1} ${y1}`;
				const arc = `A ${circleValues.radius} ${circleValues.radius}, 0, ${largeArcFlag}, 1, ${x2} ${y2}`;

				return `${startPosition} ${arc}`;
			},
			[circleValues, getCoordinatesByAngle],
		);

		const pointerInfo = useMemo(() => {
			const angle = quartile2Progress * 360;

			const {x, y} = getCoordinatesByAngle(angle);

			const strokeWidth = activeArcModel.strokeWidth * 0.375;
			const radius = activeArcModel.strokeWidth;

			setCircleCutValue(2 * (strokeWidth + radius));

			return {
				center: {
					x,
					y,
				},
				radius,
				strokeWidth,
				progress: quartile2Progress,
			};
		}, [activeArcModel, getCoordinatesByAngle, quartile2Progress]);

		const {nonActiveAnimationArcProps, activeAnimationArcProps, cubeAnimationProps, pointerAnimationProps} =
			useAnimationData({
				nonActiveArcStartValueProgress: nonActiveArcModel.startValueProgress,
				nonActiveArcEndValueProgress: nonActiveArcModel.endValueProgress,
				activeArcStartValueProgress: activeArcModel.progressModel.startValueProgress,
				activeArcEndValueProgress: activeArcModel.progressModel.endValueProgress,
			});

		return (
			<Svg
				data-cy={dataCy}
				xmlns='http://www.w3.org/2000/svg'
				viewBox={`0 0 ${svgValues.size} ${svgValues.size}`}
				css={{
					width: svgValues.size,
					height: svgValues.size,
					...css,
				}}
				ref={forwardedRef}
				{...svgProps}
			>
				{skeleton ? (
					<SkeletonableCircle
						cx={circleValues.center.x}
						cy={circleValues.center.y}
						r={circleValues.radius}
						strokeWidth={circleStrokeWidth}
					/>
				) : (
					<>
						<circle
							cx={circleValues.center.x}
							cy={circleValues.center.y}
							r={circleValues.radius}
							strokeWidth={circleStrokeWidth}
							fill='none'
							stroke={getThemeColor(circleStrokeColor)}
						/>

						<animated.path
							d={nonActiveAnimationArcProps.progress.to((progress) =>
								getArcValueD({
									startValueProgress: nonActiveArcModel.startValueProgress,
									endValueProgress: progress,
								}),
							)}
							fill='none'
							stroke={getThemeColor(nonActiveQuartileColor)}
							strokeWidth={circleStrokeWidth}
						/>

						{[startCube, endCube].map(({x1, y1, x2, y2, x3, y3, x4, y4}, index) => (
							<animated.path
								style={cubeAnimationProps}
								fill={getThemeColor(nonActiveQuartileColor)}
								d={`M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3} ${x4} ${y4} Z`}
								key={index}
							/>
						))}

						<animated.path
							d={activeAnimationArcProps.progress.to((progress) =>
								getArcValueD({
									startValueProgress: activeArcModel.progressModel.startValueProgress,
									endValueProgress: progress,
								}),
							)}
							fill='none'
							stroke={getThemeColor(activeQuartileColor)}
							strokeWidth={activeArcModel.strokeWidth}
							strokeLinecap='round'
						/>

						<animated.circle
							style={pointerAnimationProps}
							cx={pointerInfo.center.x}
							cy={pointerInfo.center.y}
							r={pointerInfo.radius}
							strokeWidth={pointerInfo.strokeWidth}
							fill={getThemeColor(pointerFillColor)}
							stroke={getThemeColor(activeQuartileColor)}
						/>
					</>
				)}
			</Svg>
		);
	},
);
