import * as React from 'react';
import type {SVGProps} from 'react';


export function AddWidget(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				d='M6.5 12.625C6.5 11.0716 7.7151 9.8125 9.21429 9.8125L10.1199 9.81342C10.6193 9.81392 11.0238 10.2335 11.0238 10.7509C11.0238 11.269 10.6182 11.6889 10.1182 11.6884L9.21429 11.6875C8.71486 11.6875 8.30952 12.1075 8.30952 12.625V19.1875C8.30952 19.705 8.71486 20.125 9.21429 20.125H22.7857C23.2851 20.125 23.6905 19.705 23.6905 19.1875V12.625C23.6905 12.1075 23.2851 11.6875 22.7857 11.6875L21.8818 11.6884C21.3818 11.6889 20.9762 11.269 20.9762 10.7509C20.9762 10.2335 21.3807 9.81392 21.8801 9.81342L22.7857 9.8125C24.2849 9.8125 25.5 11.0716 25.5 12.625V19.1875C25.5 20.7409 24.2849 22 22.7857 22H9.21429C7.7151 22 6.5 20.7409 6.5 19.1875V12.625Z'
				fill='#333333'/>
			<path
				d='M16.0009 14.5C15.5309 14.5 15.1494 14.105 15.1494 13.6176L15.1487 11.6377L13.2325 11.6384C12.7622 11.6384 12.381 11.2431 12.381 10.7561C12.381 10.269 12.7622 9.8737 13.2325 9.8737L15.1482 9.87303L15.1475 7.88235C15.1475 7.39529 15.529 7 15.9991 7C16.4691 7 16.8506 7.39529 16.8506 7.88235L16.8513 9.87243L18.7675 9.87176C19.2376 9.87176 19.619 10.2671 19.619 10.7541C19.619 11.2412 19.2376 11.6365 18.7675 11.6365L16.8518 11.6371L16.8525 13.6176C16.8525 14.105 16.471 14.5 16.0009 14.5Z'
				fill='#333333'/>
		</svg>
	);
}
