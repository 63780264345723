import {RightPanelBody} from './components/right-panel-body';
import {RightPanelContentBlock} from './components/right-panel-content-block';
import {RightPanelHeader} from './components/right-panel-header';
import {RightPanelRoot} from './components/right-panel-root';

export const RightPanel = {
	Root: RightPanelRoot,
	Header: RightPanelHeader,
	Body: RightPanelBody,
	ContentBlock: RightPanelContentBlock,
};
