import {Text} from '@esgi/ui/typography';
import {TableTestsRow} from './components.styled';
import {Box, FlexBox} from '@esgi/ui/layout';

type Props = {
	studentsCount: number;
};

export function TableTestsHeader({studentsCount}: Props) {
	const withStudentBar = studentsCount >= 1;

	return (
		<TableTestsRow dataCy='table-tests-header'>
			<Text size='small' color='lowContrast'>
				Test
			</Text>
			<Text size='small' color='lowContrast'>
				Subject Tab
			</Text>
			{withStudentBar ? (
				<FlexBox align='center' justify='end'>
					<Text size='small' color='lowContrast'>
						Students Tested
					</Text>
				</FlexBox>
			) : (
				<Box />
			)}
		</TableTestsRow>
	);
}
