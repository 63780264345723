import {DPDay, DPOffsetValue, DPPropGetter, DPPropsGetterConfig} from '@rehookify/datepicker';

export type TabID = 'start' | 'end'

export enum DayButtonState {
	Selected = 'selected',
	InRange = 'inRange',
	Neutral = 'neutral',
	Special = 'special',
	Disabled = 'disabled',

	SelectionProgress = 'selectionProgress',
}

export type DayButtonType = (day: DPDay, config?: DPPropsGetterConfig) => DPPropGetter;

export type SubtractOffset = (
	offsetValue: DPOffsetValue,
	config?: DPPropsGetterConfig,
) => DPPropGetter;

export type AddOffset = (
	offsetValue: DPOffsetValue,
	config?: DPPropsGetterConfig,
) => DPPropGetter;

export type DateFormat = 'dD/mM/YYYY' | 'mM/dD/YYYY' | 'YYYY/mM/dD' | 'YYYY/dD/mM' | 'mM/dD/YY'