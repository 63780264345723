import {MutableRefObject} from 'react';
import {Box, FlexBox, NavigationBar, useNavigationBarContext} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {SchoolYearSelect} from '../../../school-year-select';
import {SelectOption, UserInfo} from '../../../../types';
import {AdditionalElementType, useNavigateItems} from '../../use-navigate-items';
import {NavLink} from 'react-router-dom';
import {NavigationBarItem} from '../../../nav-item';

interface Props {
	schoolYears: SelectOption[];
	onChangeSchoolYears?: (id: SelectOption['value']) => void;
	selectContentRef: MutableRefObject<HTMLDivElement>;
	selectFieldRef: MutableRefObject<HTMLButtonElement>;
	currentSchoolYearID: number;
	userInfo: UserInfo;
}

export function HorizontalPanelContent({
	onChangeSchoolYears,
	schoolYears,
	currentSchoolYearID,
	selectContentRef,
	selectFieldRef,
	userInfo,
}: Props) {
	const {expanded} = useNavigationBarContext();

	const navigateItems = useNavigateItems({canExploreStudents: userInfo.canExploreStudents});

	return (
		<NavigationBar.Content css={{marginTop: '0'}}>
			{expanded && (
				<>
					<Box>
						<SchoolYearSelect
							value={currentSchoolYearID?.toString()}
							contentRef={selectContentRef}
							fieldRef={selectFieldRef}
							options={schoolYears}
							onChange={(v) => onChangeSchoolYears?.(v)}
						/>
					</Box>
					<FlexBox css={{justifyContent: 'space-between', alignItems: 'center', overflowY: 'auto'}}>
						{navigateItems.map((item, index) => {
							if (item === AdditionalElementType.Divider) {
								return <NavigationBar.Divider css={{marginBottom: 0}} key={`${item}-${index}`} />;
							}

							const {dataCy, iconDataCy, to, label, permitted = true, Icon} = item;

							if (!permitted) {
								return null;
							}

							return (
								<NavLink to={to} style={{all: 'unset'}} key={dataCy}>
									{({isActive}) => (
										<NavigationBarItem dataCy={dataCy} active={isActive}>
											<Icon data-cy={iconDataCy} width={24} height={24} />
											<Text data-cy='label' size='small'>
												{label}
											</Text>
										</NavigationBarItem>
									)}
								</NavLink>
							);
						})}
					</FlexBox>
				</>
			)}
		</NavigationBar.Content>
	);
}
