import {useService} from '@esgi/core/service';
import {DataStateService} from '../services/data-state-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

export function useStateService() {
	const stateService = useService(DataStateService);

	const isFirstEntry = useBehaviorSubject(stateService.isFirstEntry$);
	const isUpdatingState = useBehaviorSubject(stateService.isUpdatingState$);
	const sectionsState = useBehaviorSubject(stateService.sectionsState$);
	const selectedSubjectId = useBehaviorSubject(stateService.selectedSubjectId$);
	const activeSubjectTab = useBehaviorSubject(stateService.activeSubjectTab$);
	const selectedStudentId = useBehaviorSubject(stateService.selectedStudentId$);
	const selectedClassId = useBehaviorSubject(stateService.selectedClassId$);
	const selectedGroupId = useBehaviorSubject(stateService.selectedGroupId$);
	const activeStudentTab = useBehaviorSubject(stateService.activeStudentTab$);
	const isStateLoaded = useBehaviorSubject(stateService.isLoadedData$);
	const isSubjectStateLoaded = useBehaviorSubject(stateService.isInitedSubjectData$);
	const testSectionCardViewType = useBehaviorSubject(stateService.testSectionCardViewType$);

	return {
		isFirstEntry,
		isUpdatingState,
		sectionsState,
		selectedSubjectId,
		activeSubjectTab,
		selectedStudentId,
		selectedClassId,
		selectedGroupId,
		activeStudentTab,
		stateService,
		isStateLoaded,
		isSubjectStateLoaded,
		testSectionCardViewType,
	};
}
