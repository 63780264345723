import {Root, Divider, Header, IconWrapper, ClassLabel} from './index.styled';
import {Users} from '@esgi/ui';
import {SelectableListItem} from '../../service/types';
import {TestList} from './components/test-list';
import {SubjectTab} from '@esgi/main/libs/store';
import {Reports, Test} from '@esgi/main/kits/reports';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	selectedEntity: SelectableListItem,
	subjects: SubjectTab[],
	isSubjectsLoaded: boolean,
	onSubjectSelect: (value: number) => void;
	selectedSubject: SubjectTab;
	tests: Test[];
	selectedTest?: number | null;
	isTestsLoading?: boolean;
	onTestSelect: (value: number) => void;
	setShowAddTestsDrawer: VoidFunction;
}

export function TestSelectionPanel({
	selectedEntity,
	subjects,
	isSubjectsLoaded,
	selectedSubject,
	onSubjectSelect,
	tests,
	selectedTest,
	isTestsLoading,
	onTestSelect,
	setShowAddTestsDrawer,
}: Props) {
	return(
		<Root dataCy='rubric-report-content'>
			<Header>
				<ClassLabel gap='3' align='center' flow='column' skeleton={!selectedEntity}>
					{selectedEntity && <>
							<IconWrapper dataCy='user-icon'>
								<Users />
							</IconWrapper>
							<OneLinedText dataCy='class-name' color='neutral56' size='small'>{selectedEntity?.label}</OneLinedText>
						</>}
				</ClassLabel>
				<Divider/>
				<Reports.SubjectControl
					skeleton={!isSubjectsLoaded}
					subjects={subjects}
					value={selectedSubject?.id.toString()}
					onChange={value => onSubjectSelect(+value)}
				/>
			</Header>
			<TestList
				isTestsLoading={!!isTestsLoading}
				tests={tests}
				onTestSelect={onTestSelect}
				setShowAddTestsDrawer={setShowAddTestsDrawer}
				selectedTest={selectedTest}
			/>
		</Root>
	);
}