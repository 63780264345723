import {BehaviorSubject, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {dispatchAppEvent} from '@esgillc/events';
import {CurrentSchoolYearEvent, SchoolYearChangedEvent} from 'modules/school-year';
import {SchoolYearModel} from './types';
import {getUser, userStorage} from '@esgi/core/authentication';
import {V2SchoolYearsController} from '@esgi/contracts/esgi';
import {ClearStorageEvent} from '@esgi/main/libs/store';

export class Service extends BaseService {
	public selectedGlobalSchoolYearID$: BehaviorSubject<number>;
	public schoolYears$ = new BehaviorSubject<SchoolYearModel[]>([]);
	private controller = new V2SchoolYearsController();
	private user = getUser();

	constructor() {
		super();
		this.selectedGlobalSchoolYearID$ = new BehaviorSubject<number>(this.user.globalSchoolYearID);
	}

	public init() {
		return this.controller.init().pipe(
			tap(({schoolYears}) => {
				this.schoolYears$.next(schoolYears);

				const schoolYear = schoolYears.find(y => y.globalSchoolYearID === this.user?.globalSchoolYearID);
				dispatchAppEvent(CurrentSchoolYearEvent, {schoolYear});
			})
		);
	}

	public switchSchoolYear(id: number) {
		this.controller.makeSwitch({globalSchoolYearID: id}).subscribe(() => {
			this.selectedGlobalSchoolYearID$.next(id);
			userStorage.update({globalSchoolYearID: id});
			dispatchAppEvent(SchoolYearChangedEvent, {schoolYearID: id});
			dispatchAppEvent(ClearStorageEvent);
		});
	}

	public override dispose() {
		this.controller.dispose();
	}
}
