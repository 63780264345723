import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import moment from 'moment/moment';
import {BaseService} from '@esgi/core/service';
import {SessionType, SubjectPublishType} from '@esgi/core/enums';
import {
	SaveResult,
	SchoolTab,
	SchoolCommonData,
	SchoolTabFields,
	CustomDateRange, SchoolSaveResponse,
} from 'modules/subject-details/types';
import {createSchoolForm} from 'modules/subject-details/forms/school';
import PublishToSpecialists = SchoolTab.PublishToSpecialists;
import PublishToTeachers = SchoolTab.PublishToTeachers;
import {subjectNameValidator} from 'modules/subject-details/forms/subject-name-validator';
import {ElementStatus} from '@esgillc/ui-kit/form';

export class SchoolDetailsService extends BaseService {

	public form = createSchoolForm();
	public commonData = new BehaviorSubject<SchoolCommonData>(null);
	public gradeLevelsIDs = new BehaviorSubject<number[]>([]);

	private subjectID: number;
	private controller: string = 'subjects/school';

	public init(subjectID: number) {
		this.subjectID = subjectID;
		this.form.controls.subjectName.validators
			.push((validationChain) => subjectNameValidator(this.httpClient, subjectID, validationChain));
		if (!this.subjectID) {
			return this.initCreateSchoolSubject();
		} else {
			return this.initEditSchoolSubject(this.subjectID);
		}
	}

	public save(): Observable<SaveResult> {
		return this.form.validate().pipe(switchMap(res => {

			if (!res.length) {
				const {
					shuffleQuestions,
					subjectName,
					gradeLevels,
					publishType,
					trackDates,
					customDateRange,
					forceTest,
					track,
					publishToSpecialists,
					publishToTeacher,
					sessionType,
					iss,
				} = this.form.value;

				let publishFromDate = null;
				let publishToDate = null;

				if (publishType === SubjectPublishType.MarkingPeriod) {
					let selectedTrackDates = this.commonData.value.trackDates.filter(td => trackDates.indexOf(td.trackDateID) > -1);
					selectedTrackDates = selectedTrackDates.sort((a, b) => moment(a.dateTo).unix() - moment(b.dateFrom).unix());
					publishFromDate = moment.utc(selectedTrackDates[0].dateFrom).format();
					publishToDate = moment.utc(selectedTrackDates[selectedTrackDates.length - 1].dateTo).endOf('day').format();
				}

				if (publishType === SubjectPublishType.CustomDateRange) {
					publishFromDate = moment.utc(customDateRange.from).format();
					publishToDate = moment.utc(customDateRange.to).endOf('day').format();
				}

				const model = new SchoolSaveResponse(
					this.subjectID,
					gradeLevels.map(gl => gl.gradeLevelID),
					forceTest ? sessionType : null,
					publishType,
					track[0],
					publishFromDate ? publishFromDate : null,
					publishToDate ? publishToDate : null,
					shuffleQuestions,
					subjectName,
					publishToTeacher,
					publishToSpecialists,
					iss,
				);

				if (!this.subjectID) {
					return this.createSchoolTab(model);
				} else {
					return this.updateSchoolTab(model);
				}
			}
		}));
	}

	private initCreateSchoolSubject() {
		return this.httpClient.ESGIApi.get<SchoolCommonData>(this.controller, 'init').asObservable().pipe(map(r => {
			this.gradeLevelsIDs.next(r.gradeLevels.map(v => v.gradeLevelID));
			this.commonData.next(r);
		}));
	}

	private initEditSchoolSubject(subjectID: number) {
		return this.httpClient.ESGIApi.get<{ commonModel: SchoolCommonData, editModel: SchoolTabFields }>(this.controller, 'initEdit', {subjectID: subjectID})
			.pipe(tap(response => {

				if(response) {
					this.form.controls.subjectName.status = ElementStatus.valid;
				} else {
					this.form.controls.subjectName.status = ElementStatus.untouched;
				}

				const {commonModel, editModel} = response;
				const {name} = editModel;

				this.gradeLevelsIDs.next(commonModel.gradeLevels.map(v => v.gradeLevelID));
				this.commonData.next(commonModel);

				const gradeLevels = commonModel ? commonModel.gradeLevels.filter(gl => editModel.selectedGradeLevels.indexOf(gl.gradeLevelID) > -1) : [];
				const publishToTeacher: PublishToTeachers = response ? PublishToTeachers[editModel.publishToTeachers] : PublishToTeachers.All;
				const publishToSpecialists: PublishToSpecialists = editModel ? PublishToSpecialists[editModel.publishToSpecialists] : PublishToSpecialists.All;
				const publishType: SubjectPublishType = editModel ? SubjectPublishType[editModel.publishType] : SubjectPublishType.Indefinitely;
				const sessionType: SessionType = editModel && editModel.forceTestType ? SessionType[editModel.forceTestType] : SessionType.TestIncorrect;
				const track = editModel && editModel.publishTrackID ? [editModel.publishTrackID] : [];
				const customDateRange: CustomDateRange = editModel ? {
					from: moment(editModel.publishFromDate).format('L'),
					to: moment(editModel.publishToDate).format('L'),
				} : {
					from: moment(new Date()).format('L'),
					to: moment(new Date()).add(1, 'days').format('L'),
				};

				this.form.value = {
					subjectName: name ? name : '',
					gradeLevels,
					publishToTeacher,
					publishToSpecialists,
					iss: editModel.selectedSchoolSpecialists || [],
					publishType,
					customDateRange,
					track,
					trackDates: response ? editModel.selectedTrackDates : [],
					forceTest: response ? !!editModel.forceTestType : false,
					sessionType,
					shuffleQuestions: response ? editModel.shuffleQuestions : false,
				};
				return response;
			}));
	}

	private createSchoolTab(model: SchoolSaveResponse) {
		return this.httpClient.ESGIApi.post<SaveResult>(this.controller, 'create', model);
	}

	private updateSchoolTab(model: SchoolSaveResponse) {
		return this.httpClient.ESGIApi.post<SaveResult>(this.controller, 'update', model);
	}
}
