import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const PanelWithTabs = styled(GridBox, {
	height: '100%',
	gap: '$5',
	gridAutoFlow: 'row',

	variants: {
		withTabs: {
			true: {
				gridTemplateRows: 'auto auto 1fr',
			},

			false: {
				gridTemplateRows: 'auto 1fr',
			},
		},
	},
});