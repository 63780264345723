import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, debounceTime, tap} from 'rxjs';
import {
	StudentWithUnit,
	SubjectService,
	TestService,
} from '@esgi/main/features/teacher/student-detail';
import {NotTested, Test} from '@esgi/main/kits/reports';
import {MarkingPeriod} from './types';

export class ClassGradesService extends BaseService {
	public readonly students$ = new BehaviorSubject<StudentWithUnit[]>([]);
	public readonly tests$ = new BehaviorSubject<Test['id'][]>([]);
	public readonly markingPeriod$ = new BehaviorSubject<MarkingPeriod | null>(null);
	public readonly notTested$ = new BehaviorSubject<NotTested | null>(null);
	public readonly subject$ = new BehaviorSubject(0);
	public subjectService = new SubjectService();
	public testService = new TestService();

	constructor() {
		super();

		this.completeOnDestroy(combineLatest([this.subject$, this.students$]))
			.pipe(
				debounceTime(100),
				tap(([subjectId, students]) => {
					const subject = this.subjectService.get(subjectId);
					this.testService.getTests(subject, students);
				})).subscribe();

		this.completeOnDestroy(this.subjectService.loaded$)
			.pipe(tap((value) => {
				if (value) {
					this.subject$.next(this.subjectService.getByIndex(0).id);
				}
			})).subscribe();
	}

	public setSubject(value: number) {
		this.subject$.next(value);
	}

	public setTests(value: Test['id'][]) {
		this.tests$.next(value);
	}

	public setMarkingPeriod(value: MarkingPeriod) {
		this.markingPeriod$.next(value);
	}

	public setNotTested(value: NotTested) {
		this.notTested$.next(value);
	}


	public override dispose() {

	}
}
