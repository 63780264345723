import {Renderable, RenderableFunction} from '@esgillc/ui-kit/core';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {join} from '@esgillc/ui-kit/utils';
import {DataType} from 'csstype';
import styles from './selector-box.module.less';
import {ReactNode} from 'react';

interface Props {
	justify?: 'start' | 'center' | 'end' | DataType.ContentDistribution;
	align?: 'center' | 'end' | 'start' | 'self-end' | 'self-start';
	className?: string;
	children?: ReactNode;
}

export default function SelectorBoxBody(props: Props): JSX.Element {
	const className = join(styles.body, props.className);

	return <FlexBox direction='column'
					justify={props.justify}
					align={props.align}
					className={className}>
		{props.children}
	</FlexBox>;
}
