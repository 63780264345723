import React, {ChangeEvent} from 'react';
import styles from '../../../../styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {Checkbox} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {IncludeGradeOption} from './components/include-grade-option';
import {User} from '../../../../../../../models';

interface Props {
	onIncludeUntestedQuestionChange: (v: ChangeEvent<HTMLInputElement>) => void;
	onShowSessionDateChange: (v: ChangeEvent<HTMLInputElement>) => void;
	includeSessionClicked: (v: ChangeEvent<HTMLInputElement>) => void;
	teacher: User
	hasGradeScales: boolean
	showSessionDateOption: boolean
	includeSessionOption: boolean
	includeUntestedQuestionsOption: boolean
	includeGradeOption: boolean
	onIncludeGradeOptionChange: (v: ChangeEvent<HTMLInputElement>) => void;
}

export function ResultsBlock(props: Props) {
	const {
		teacher,
		onShowSessionDateChange,
		onIncludeUntestedQuestionChange,
		includeSessionClicked,
		hasGradeScales,
		showSessionDateOption,
		includeSessionOption,
		includeUntestedQuestionsOption,
		includeGradeOption,
		onIncludeGradeOptionChange,
	} = props;
	return <>
		<div className={styles.filtersContainer}>
			<div className={styles.formGroup}>
				<strong>Results: </strong>
			</div>
		</div>
		<div className={join(styles.filtersContainer, styles.separator)}>
			{teacher.includeGradeOption &&
				<IncludeGradeOption includeGradeOption={includeGradeOption} hasGradeScales={hasGradeScales}
				                    onIncludeGradeOptionChange={onIncludeGradeOptionChange}/>}
			{teacher.includeUntestedQuestionsOption && <>
				<div className={styles.formGroup}>
					<Checkbox checked={includeUntestedQuestionsOption}
					          onChange={onIncludeUntestedQuestionChange}>Skipped
						Questions</Checkbox>
					<OnHoverTooltip message='Help'>
						<a href='https://support.esgisoftware.com/hc/en-us/articles/209159806-Parent-Letter#Skipped'
						   target='_blank' rel='noreferrer'>
							<sup className='fa fa-question-circle' aria-hidden='true'/>
						</a>
					</OnHoverTooltip>
				</div>
			</>}
			{teacher.showSessionDateOption && <>
				<div className={styles.formGroup}>
					<Checkbox
						checked={showSessionDateOption}
						onChange={onShowSessionDateChange}>Session Date</Checkbox>
				</div>
			</>}
			<div className={styles.filtersContainer}>
				<div className={styles.formGroup}>
					<Checkbox
						checked={includeSessionOption}
						onChange={includeSessionClicked}>Choose Session
					</Checkbox>
				</div>
			</div>
		</div>
	</>;
}
