import * as React from 'react';
import type {SVGProps} from 'react';

export function ExpandChart(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M5.4375 9.04688C5.4375 7.77835 6.49523 6.75 7.8 6.75H16.2C17.5048 6.75 18.5625 7.77835 18.5625 9.04688V14.9531C18.5625 16.2217 17.5048 17.25 16.2 17.25H7.8C6.49523 17.25 5.4375 16.2217 5.4375 14.9531V9.04688ZM7.8 7.89844C7.14761 7.89844 6.61875 8.41261 6.61875 9.04688V14.9531C6.61875 15.5874 7.14761 16.1016 7.8 16.1016H16.2C16.8524 16.1016 17.3812 15.5874 17.3812 14.9531V9.04688C17.3812 8.41261 16.8524 7.89844 16.2 7.89844H7.8Z' fill='#333333'/>
			<path d='M10.7531 9.04688H7.8V11.918H8.98125V10.1953H10.7531V9.04688ZM16.2 14.9531V12.082H15.0187V13.8047H13.2469V14.9531H16.2Z' fill='#333333'/>
		</svg>
	);
}
