import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Test} from './components/tests-control/types';
import {Group, Class, Student, SubjectTab} from '@esgi/main/libs/store';
import {Response as InitResponse} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/pie-charts/init/response';
import {StudentModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/pie-charts/models/student-model';

export {TestType} from '@esgi/contracts/esgi/types/esgi.enums/test-type';
export {StudentSort} from '@esgi/contracts/esgi/types/esgi.enums/student-sort';
export {type Test};

interface StudentData extends Omit<StudentModel, 'testResults'> {
	testResults: StudentModel['testResults'] | null;
}

export interface ReportData extends Omit<InitResponse, 'students'> {
	students: StudentData[];
}

export interface DownloadRequest {
	userID: number;
	studentID: Student['id'] | null;
	studentIDs: Student['id'][];
	testIDs: Test['id'][];
	globalSchoolYearID: number;
	timeZone: string;
	showEachStudent: boolean;
	zip: boolean;
	districtName: string;
	schoolName: string;
	teacherName: string;
	className: Class['name'];
	groupName: Group['name'];
	subjectName: string;
}

export interface UnitDataSettings {
	selectedGroup: Group | null;
	selectedClass: Class | null;
	selectedStudent: Student | null;
	selectedSubject: SubjectTab;
	selectedTests: Test[];
	studentIDs: Student['id'][];
}

export interface PrintSettings extends DownloadRequest {
	printInColor: boolean;
	showEachStudent: boolean;
	gradeLevelID: number;
}

export interface Entity extends Pick<SingleSelectState, 'classId' | 'groupId' | 'studentId'> {
	fromClassId?: Class['id'] | null;
	fromGroupId?: Group['id'] | null;
}

export interface ChartOptions {
	chartSize: {width: number, height: number, max: number, min?: number};
	testName: string;
	reportData: ReportData;
	sortOptionMode: string;
	userLevel: string;
	stackIncorrect: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	updateScrollbarDisplay: () => void;
	normalizeChart: (event) => void;
	selectPoint: (event) => void;
	updateTitleSize: () => void;
	clone?: boolean;
}

export enum FileType {
	PDF,
	ZIP,
}

export interface TestResultModel {
	testID: number;
	correctAnswers: number;
	lastTestDate: string;
}

export interface TestResultCombinedModel {
	testID: number;
	correctAnswers: number;
	studentsTested: number;
}

export interface ChartBlockModel {
	correctPercent: number;
	combinedMaxScore?: number;
	combinedTestResult?: TestResultCombinedModel;
	testResult?: TestResultModel;
	testID: number;
	studentID: number;
	pieChartOptions: any;
}

export interface BackgroundGenerationModel {
	reportGuid: string;
}
