import {useEffect, useState} from 'react';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {Reports} from '@esgi/main/kits/reports';
import {Content, RightSidebar} from '../../layout/index.styled';
import {
	gradeRangesMock, makeSelectedGroupData,
	ParentLetter as ParentLetterUI,
	useParentLetter,
	ParentLetterResultData,
} from '@esgi/main/features/teacher/parent-letter';
import {withNewOldReports} from '../../old-reports';
import {OldParentLetterReport} from '../../old-reports/students/parent-letter';
import {FormWrapper, LeftSidebar, ParentLetterPage, SettingsContainer} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';

function ParentLetter() {
	const {service} = useParentLetter();

	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const studentList = useBehaviorSubject(service.studentService.students$);

	const isSubjectsDataLoaded = useBehaviorSubject(service.isSubjectsDataLoaded);

	const tests = useBehaviorSubject(service.testService.tests$);
	const isTestDataLoaded = useBehaviorSubject(service.testService.loading$);
	const defaultSelectedTestsIDs = useBehaviorSubject(service.testService.defaultSelectedTestsIDs);

	const selectedStudentsIDs = useBehaviorSubject(service.selectedStudentsIDs$);
	const selectedSubject = useBehaviorSubject(service.selectedSubject);
	const selectedTestIDs = useBehaviorSubject(service.selectedTestIDs);

	const subjects = useBehaviorSubject(service.subjects);
	const [isReportButtonDisabled, setReportButtonDisabled] = useState(false);
	const [isParentLetterModalOpen, setParentLetterModalOpen] = useState(false);
	const [isRunReportDialogOpen, setRunReportDialogOpen] = useState(false);
	const [reportData, setReportData] = useState<ParentLetterResultData>(null);
	const [emptyTestsListMessage, setEmptyTestsListMessage] = useState('');

	useEffect(() => {
		setReportButtonDisabled(!selectedTestIDs?.length);
	}, [selectedTestIDs?.length]);

	const onReport = () => {
		service.report(classes, groups, studentList, tests).subscribe({
			next: (resultData) => setReportData(resultData),
			complete: () => setRunReportDialogOpen(true),
		});
	};

	const onViewAndEditTemplate = () => {
		setParentLetterModalOpen(true);
	};

	const handleChange = (value: SingleSelectState) => {
		const {studentIDs, groupItemLevel, groupItemID, emptyListMessage} = makeSelectedGroupData(value, groups, classes);
		setEmptyTestsListMessage(emptyListMessage);

		service.singleSelectState.next(value);
		service.setStudents(studentIDs);
		service.groupLevelData.next({groupItemID, groupItemLevel});
	};

	//TODO: Need to use Activities.StudentsControl after the new BE side is ready
	return <ParentLetterPage justify='between'>
		{/*<LeftSidebar>*/}
		{/*	<Activities.StudentsControl*/}
		{/*		skeleton={!loadedStudents}*/}
		{/*		classes={classes}*/}
		{/*		groups={groups}*/}
		{/*		students={studentList}*/}
		{/*		onChange={(value) => service.setStudents(value.map(s => s.studentId))}*/}
		{/*	/>*/}
		{/*</LeftSidebar>*/}

		<LeftSidebar>
			<Reports.StudentLevel.SingleStudentPanel
				withoutAllClass
				withoutStudents
				hideEmptyClassAndGroup
				onChange={handleChange}
			/>
		</LeftSidebar>

		<Content>
			<Reports.Header
				student={service.studentService.get(selectedStudentsIDs[0])}
				subjects={subjects}
				skeleton={!isSubjectsDataLoaded}
				onChangeSubject={(value) => service.selectedSubject.next(subjects.find(s => s.id.toString() === value))}
				selectedSubject={selectedSubject}
				withCounter={selectedStudentsIDs.length !== 1}
				studentCounter={selectedStudentsIDs.length || 0}
			/>
			<Reports.TestsControl
				withExpandableListItem
				tests={tests}
				onChange={(value) => service.selectedTestIDs.next(value)}
				skeleton={!isTestDataLoaded}
				onChangeTestsData={(testID, session) => service.testService.updateTestCardsData(testID, session)}
				hasSelectedStudents={Boolean(selectedStudentsIDs.length)}
				emptyListMessage={emptyTestsListMessage}
				defaultSelectedTestIDs={defaultSelectedTestsIDs}
			/>
		</Content>

		<RightSidebar>
			<FormWrapper controller={service.form}>
				<SettingsContainer>
					<GridBox>
						<Reports.ReportPanel
							reportTitle='Run Report'
							onReport={onReport}
							disabled={isReportButtonDisabled}
						/>

						<Reports.SettingsControl
							includeSkippedQuestionsControl={service.form.controls.isIncludeSkippedQuestions}
							showNotesControl={service.form.controls.isShowNotes}
							showSessionDateControl={service.form.controls.isShowSessionDate}
							showSummaryControl={service.form.controls.isShowSummary}
						/>

						<Reports.GradesControl
							includeGradesControl={service.form.controls.isIncludeGrades}
							gradeRanges={gradeRangesMock}
						/>

						<Reports.LetterTemplateControl
							includeParentMessage={service.form.controls.isIncludeParentMessage}
							onViewAndEditTemplate={onViewAndEditTemplate}
						/>
					</GridBox>
					<ParentLetterUI.ColorSelectorControl printInColor={service.form.controls.printInColor}/>
				</SettingsContainer>
			</FormWrapper>
			{isParentLetterModalOpen && <ParentLetterUI.ParentLetterPreviewModal
				selectedStudentID={selectedStudentsIDs[0]}
				onClose={() => setParentLetterModalOpen(false)}
			/>}
		</RightSidebar>

		{isRunReportDialogOpen &&
			<ParentLetterUI.ReportDialog
				onClose={() => setRunReportDialogOpen(false)}
				data={reportData}
			/>}
	</ParentLetterPage>;
}

export default withNewOldReports(OldParentLetterReport, OldParentLetterReport);

