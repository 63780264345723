import * as React from 'react';
import type {SVGProps} from 'react';

export function ParentConferencer(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path fillRule='evenodd' clipRule='evenodd' d='M8.83333 6C7.17667 6 5.83333 7.34333 5.83333 9C5.83333 10.6567 7.17667 12 8.83333 12C10.49 12 11.8333 10.6567 11.8333 9C11.8333 7.34333 10.49 6 8.83333 6ZM8.83333 7.33333C9.754 7.33333 10.5 8.07933 10.5 9C10.5 9.92067 9.754 10.6667 8.83333 10.6667C7.91267 10.6667 7.16667 9.92067 7.16667 9C7.16667 8.07933 7.91267 7.33333 8.83333 7.33333Z' fill='#333333'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M6.54134 12.896C5.31867 13.4007 4.5 14.6373 4.5 16V17.3333C4.5 17.7013 4.79867 18 5.16667 18H12.5C12.868 18 13.1667 17.7013 13.1667 17.3333V16C13.1667 14.6693 12.3433 13.4627 11.1253 12.9373C10.9533 12.8633 10.754 12.8613 10.5833 12.9373C9.99333 13.202 9.41867 13.3333 8.83333 13.3333C8.25 13.3333 7.67333 13.1947 7.06266 12.9167C6.89466 12.84 6.71201 12.8253 6.54134 12.896ZM6.79799 14.26C7.42799 14.4987 8.18733 14.6667 8.83333 14.6667C9.474 14.6667 10.202 14.51 10.8193 14.282C11.4213 14.6247 11.8333 15.266 11.8333 16V16.6667H5.83333V16C5.83333 15.2233 6.27066 14.5687 6.79799 14.26Z' fill='#333333'/>
			<path d='M15.8014 6C14.5304 6 13.5 7.04756 13.5 8.33961L13.5 12.9082C13.5 13.3902 14.0374 13.6727 14.4171 13.3832L16.5747 11.7384H19.4486C20.7196 11.7384 21.75 10.6908 21.75 9.3988V8.33961C21.75 7.04756 20.7196 6 19.4486 6L15.8014 6ZM15.8014 7.16981L19.4486 7.16981C20.0844 7.16981 20.5993 7.6933 20.5993 8.33961V9.3988C20.5993 10.0451 20.0844 10.5686 19.4486 10.5686H16.3767C16.2525 10.5686 16.1345 10.6025 16.035 10.6786L14.6507 11.7384V8.33961C14.6507 7.6933 15.1656 7.16981 15.8014 7.16981Z' fill='#333333'/>
		</svg>
	);
}
