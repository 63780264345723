import {deepCopy} from 'shared/utils';
import {Criteria, Level} from '../models';
import {CriteriaInfo, LevelsInfo} from './common';
import {criteriaSorter, levelSorter} from './sorting';

export function recalculateCriteriaOrders(criteria: Criteria[]): CriteriaInfo {
	const safeCriteria = deepCopy(criteria)
		.sort(criteriaSorter)
		.map((item, index) => {
			const newOrder = index + 1;
			item.order = newOrder;
			return item;
		});

	return {
		criteria: safeCriteria,
	};
}

export function recalculateLevelsOrders(levels: Level[], isZero: boolean = false): LevelsInfo {
	const safeLevels = deepCopy(levels)
		.sort(levelSorter)
		.map((item, index) => {
			if(isZero){
				item.score = index;
				if(item.score < 5) {
					item.metaInfo = {defaultField: true};
				}
			} else {
				item.score = index + 1;
			}
			return item;
		});

	return {
		levels: safeLevels,
	};
}
