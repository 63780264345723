import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const LoadingText = styled(Text, {
	padding: '$4',
	textAlign: 'center',
	color: '$neutral64',
});

export const ToolbarContainer = styled(FlexBox, {
	flexDirection: 'row',
	justifyContent: 'space-between',
	padding: '0px 10px',
});

export const EditorContainer = styled(Box, {
	overflowY: 'auto',
	color: '$neutral12',

	'& h1': {
		fontSize: '18px',
	},

	'& h2': {
		fontSize: '16px',
	},

	'& em': {
		fontStyle: 'italic',
	},

	'div[data-slate-editor="true"]:focus-visible': {
		color: '$primary',
		outline: '2px solid transparent',
		outlineOffset: '2px',
	},
});

export const BlockContainer = styled(Box, {
	borderRadius: '12px',
	borderTopRightRadius: '0px',
	borderTopLeftRadius: '0px',
	backgroundColor: '$neutral99',
	borderColor: '$neutral56',
	borderWidth: '2px',
	padding: '$5',
	marginBottom: '8px',
});

export const VoidContainer = styled(Box, {
	borderRadius: '12px',
	borderBottomRightRadius: '0px',
	borderBottomLeftRadius: '0px',
	backgroundColor: '$neutral98',
	color: '$neutral64 !important',
	borderColor: '$neutral56',
	borderWidth: '2px',
	borderTopWidth: '0px',
	padding: '$5',
	paddingTop: '$3',
	paddingBottom: '0px',
});
