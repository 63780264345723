export function EmptyChart() {
	return (
		<div
			dir='ltr'
			className='highcharts-container'
			style={{position: 'relative', overflow: 'hidden', width: '80px', height: '80px', textAlign: 'left', lineHeight: 'normal', zIndex: 0, WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'}}
		>
			<svg
				version='1.1'
				className='highcharts-root'
				style={{fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif', fontSize: '12px'}}
				xmlns='http://www.w3.org/2000/svg'
				width='80'
				height='80'
				viewBox='0 0 80 80'
			>
				<defs>
					<clipPath id='highcharts-aycwxjj-5711-'>
						<rect x='0' y='0' width='60' height='55' fill='none'/>
					</clipPath>
				</defs>
				<rect fill='none' className='highcharts-background' x='0' y='0' width='80' height='80' rx='0' ry='0'/>
				<rect fill='none' className='highcharts-plot-background' x='10' y='10' width='60' height='55'/>
				<rect fill='none' className='highcharts-plot-border' data-z-index='1' x='10' y='10' width='60' height='55'/>
				<g className='highcharts-series-group' data-z-index='3'>
					<g data-z-index='0.1' className='highcharts-series highcharts-series-0 highcharts-pie-series  highcharts-tracker' transform='translate(10,10) scale(1 1)' style={{cursor: 'pointer'}}>
						<path fill='#eee' d='M 29.992565928065126 -8.999999242939367 A 36.5 36.5 0 1 1 29.949302232260386 -8.999964790891873 M 30 27.5 A 0 0 0 1 0 30 27.5' transform='translate(0,0)' stroke='#D8D8D8' strokeWidth='1' opacity='1' strokeLinejoin='round' className='highcharts-point highcharts-point-select highcharts-color-0'/>
					</g>
					<g data-z-index='0.1' className='highcharts-markers highcharts-series-0 highcharts-pie-series' transform='translate(10,10) scale(1 1)'/>
				</g>
				<text x='40' textAnchor='middle' className='highcharts-title' data-z-index='4' style={{color: '#333333', fontSize: '18px', fill: '#333333'}} y='24'/>
				<text x='40' textAnchor='middle' className='highcharts-subtitle' data-z-index='4' style={{color: '#666666', fill: '#666666'}} y='24'/>
			</svg>
		</div>
	);
}
