import {HttpClient} from '@esgi/api';
import moment from 'moment';
import React from 'react';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {TestScreenType, TestType} from '@esgi/core/enums';
import {Modal, ModalBody} from '@esgi/deprecated/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {Api} from './api/api';
import {TestSessionDetailsEvents} from './events';
import {Footer} from './modal/footer';
import Header from './modal/header';
import {SS, State as SSState} from './modal/types/ss';
import {State as YNState, YN} from './modal/types/yn';
import {Models} from './models';
import {ExceptionReporter} from 'shared/alert/exception-reporter/reporter';
import {Loader} from '@esgi/deprecated/jquery';
import {SsoTracker} from '@esgi/core/tracker';
import {InitRequest} from 'shared/modules/test/test-session-details/api/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {selectedStudentID} from 'modules/hierarchy/utils';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import TestSubset from 'modules/assessments/general/session-details/test-subset/test-subset';
import IEPGoalTab from './components/iep-goal';
import {
	IEPGoalModel,
	IEPStatusModel, StudentModel,
} from './components/iep-goal/models';
import {IEPFooter} from './modal/iep-footer';
import {IEPGoalFormService} from './components/iep-goal/service';
import {Subject} from './types';
import {Tabs, TabPanel} from '@esgillc/ui-kit/tabs-with-panel';
import {hasIEPLogic} from 'modules/assessments';
import {EventBusDispatcher} from '@esgillc/events';
import {capitalizeFirstLetter} from '@esgillc/ui-kit/utils';
import './test-session-details.less';

class Props {
	close: () => void;
	classID: number;
	specialistGroupID: number;
	studentID: number;
	testId: number;
	subject: Subject;
	hierarchy: HierarchySnapshot;
}

class State {
	loaded: boolean = false;

	bodyYn: YNState = new YNState();
	bodySs: SSState = new SSState();

	editMode: boolean = false;
	iepEditMode: boolean = false;
	activeTab: 'details' | 'iep' = 'details';
	testSessions: Models.TestSession[];
	testSubsetOpened: boolean = false;
}


export default class TestSessionDetails extends React.Component<Props, State> {
	private currentUser = userStorage.get();
	private loader: Loader = null;
	private service: IEPGoalFormService;

	constructor(props?: Props) {
		super(props);
		this.bindScope();

		this.state = new State();
		this.service = new IEPGoalFormService();
		this.service.form.controls.isCompleted.onChanged.subscribe(({reason}) => {
			if (reason === 'value' && !this.state.iepEditMode) {
				this.saveButtonClickerIEP();
			}
		})
	}

	private bindScope() {
		this.close = this.close.bind(this);
		this.setEditMode = this.setEditMode.bind(this);
		this.saveButtonClicked = this.saveButtonClicked.bind(this);
		this.restoreButtonClicked = this.restoreButtonClicked.bind(this);
		this.cancelButtonClicked = this.cancelButtonClicked.bind(this);
		this.testSubsetButtonClicked = this.testSubsetButtonClicked.bind(this);
		this.setEditModeIEP = this.setEditModeIEP.bind(this);
		this.saveButtonClickerIEP = this.saveButtonClickerIEP.bind(this);
		this.cancelButtonClickedIEP = this.cancelButtonClickedIEP.bind(this);
		this.onSelectTab = this.onSelectTab.bind(this)
	}

	componentDidMount(): void {
		this.init();
	}

	componentWillUnmount() {
		this.service.destroy();
	}

	private studentName: string = '';
	private testName: string = '';
	private questions: Models.Question[];
	private testType: TestType;
	private canEdit: boolean = false;
	private canTest: boolean = false;
	private totalPossible: number = 0;
	private testResultsCorrectVerbiage: string;
	private testResultsIncorrectVerbiage: string;
	private nowProfileTZ: moment.Moment;
	private showTestedBy: boolean;
	private iepGoal: IEPGoalModel;
	private iepStatuses: IEPStatusModel[];
	private student: StudentModel;


	private api: Api = new Api();

	get tabs() {
		const result = [
			{name: 'details', label: 'Test sessions'},
		];
		if (this.iepGoal) {
			result.push({name: 'iep', label: 'IEP Goal'});
		}
		return result;
	}

	private init() {
		const request: InitRequest = {
			testID: this.props.testId,
			studentID: this.props.studentID || selectedStudentID(this.props.hierarchy),
		};

		return this.api.init(request)
			.subscribe({
				next: (r) => {
					this.questions = Models.Question.FromResponseArray(r.questions);
					this.setTestSessions(Models.TestSession.FromResponseArray(r.testSessionsInfo));
					this.testName = r.testName;
					this.studentName = r.studentFirstName + ' ' + r.studentLastName;
					this.canEdit = r.canEdit;
					this.canTest = r.canTest;
					this.testType = TestType[r.testType];
					this.totalPossible = r.totalPossible;
					this.testResultsCorrectVerbiage = capitalizeFirstLetter(r.testResultsCorrectVerbiage);
					this.testResultsIncorrectVerbiage = capitalizeFirstLetter(r.testResultsIncorrectVerbiage);
					this.nowProfileTZ = moment(r.nowProfileTZ);
					this.showTestedBy = r.testSessionsInfo.length > 0 && r.isUserLinkedDistrict;
					if (r.iepGoal) {
						this.iepGoal = {
							id: r.iepGoal.id,
							studentID: this.props.studentID || selectedStudentID(this.props.hierarchy),
							goal: r.iepGoal.goal,
							benchmark: r.iepGoal.benchmark,
							isCompleted: r.iepGoal.isCompleted,
							statusID: r.iepGoal.statusID,
						};
						this.iepStatuses = r.iepStatuses;
						this.student = {
							firstName: r.studentFirstName,
							fullName: r.studentFirstName + ' ' + r.studentLastName,
							lastName: r.studentLastName,
							studentID: this.props.studentID || selectedStudentID(this.props.hierarchy),
						}

						this.service.init(this.iepGoal, this.iepStatuses, this.student);
					}

					this.setState({loaded: true}, () => {
						this.loader = new Loader($('.tsd-modal .modal-content'));
					});
				},
			});
	}

	private close() {
		if (!this.state.bodyYn.dirty && !this.state.bodySs.dirty) {
			this.props.close();
		} else {
			OldAlerts.bsconfirm(
				'Are you sure you want to close the Details without saving? Any changes you have just made will be lost.',
				yes => {
					if (yes) {
						this.props.close();
					}
				});
		}
	}

	private setEditMode(mode: boolean) {
		this.setState({editMode: mode});
	}

	private setEditModeIEP(mode: boolean) {
		this.setState({iepEditMode: mode});
	}

	private setTestSessions(sessions: Models.TestSession[]) {
		this.setState({testSessions: sessions, loaded: true});
	}

	private saveButtonClicked() {
		EventBusDispatcher.dispatch(TestSessionDetailsEvents.TestSessionUpdate, TestSessionDetailsEvents.TestSessionUpdate());
	}

	private saveButtonClickerIEP() {
		this.service.update(() => {
			this.setEditModeIEP(false);
		});
	}

	private restoreButtonClicked() {
		EventBusDispatcher.dispatch(TestSessionDetailsEvents.TestSessionRestore, TestSessionDetailsEvents.TestSessionRestore());
	}

	private cancelButtonClicked() {
		if (this.state.editMode) {
			if (!this.state.bodyYn.dirty && !this.state.bodySs.dirty) {
				EventBusDispatcher.dispatch(TestSessionDetailsEvents.NewTestSessionCanceled, TestSessionDetailsEvents.NewTestSessionCanceled());
				this.setEditMode(false);
			} else {
				OldAlerts.bsconfirm('Are you sure you want to discard changes?',
					result => {
						if (result) {
							EventBusDispatcher.dispatch(TestSessionDetailsEvents.ResetPanels, TestSessionDetailsEvents.ResetPanels());
							this.setEditMode(false);
						}
					});
			}
		} else {
			this.close();
		}
	}

	private cancelButtonClickedIEP() {
		if (this.state.iepEditMode) {
			this.service.resetIEPGoalFormData();
			this.setEditModeIEP(false);
		} else {
			this.close();
		}
	}

	private testSubsetButtonClicked() {
		this.setState({testSubsetOpened: true});
	}

	get canTestSubset(): boolean {
		return (!this.selectedSession || !this.selectedSession.deleted) && this.canEdit && this.canTest && this.testType === TestType.YN;
	}

	get loaded(): boolean {
		return this.state.loaded
			&& (this.state.bodyYn.loaded || this.state.bodySs.loaded);
	}

	private subsetStarted(): any {
		this.props.close();
	}

	private renderSubsetModal() {
		return (this.state.testSubsetOpened)
			? <TestSubset
				testID={this.props.testId}
				testSessionID={this.selectedSession?.id}
				testSessionNumber={this.selectedSession?.number}
				closed={() => this.setState({testSubsetOpened: false})}
				started={() => this.subsetStarted()}/>
			: null;
	}

	private onSelectTab({tab}) {
		this.setState({activeTab: tab.name, editMode: false})
		if(tab.name === 'details') {
			this.init();
		}
	}

	get allowEdit(): boolean {
		if (!this.canEdit) {
			return false;
		}
		if (this.allowRestore) {
			// deleted. don't show edit button.
			return false;
		}

		if (this.state.editMode) {
			// in edit mode. don't show edit button.
			return false;
		}

		const selected = this.selectedSession;
		if (!selected) {
			//null or test sessions is not present.
			return false;
		}

		const userType = this.currentUser.userType;
		if (userType === UserType.T) {
			return this.currentUser.userID === selected.userID || selected.userID === null;
		} else {
			return this.currentUser.userID === selected.userID;
		}
	}

	get allowRestore(): boolean {
		return this.state.bodySs.allowRestore || this.state.bodyYn.allowRestore;
	}

	get bodyValid(): boolean {
		return this.state.bodySs.validation.valid && this.state.bodyYn.validation.valid;
	}

	get selectedSession(): Models.TestSession {
		let selected = null;
		if(this.testType === TestType.YN && this.state.bodyYn.selectedTestSessionId > 0) {
			selected = this.state.testSessions.find(ts => ts.id === this.state.bodyYn.selectedTestSessionId);
		}
		if(this.testType === TestType.Score && this.state.bodySs.selectedTestSessionId > 0) {
			selected = this.state.testSessions.find(ts => ts.id === this.state.bodySs.selectedTestSessionId);
		}
		return selected;
	}

	get testedByName() {
		const selected = this.selectedSession;
		if(selected) {
			return selected.userName;
		}
		return '';
	}

	get nonDeletedSessionsNumber(){
		if (this.state.testSessions){
			return this.state.testSessions.filter(x => !x.deleted).length;
		}
		return 0;
	}

	download = (current: boolean) => {
		SsoTracker.trackEvent({
			trackingEvent: 'TestSessionDetailsDownloadPDF',
		});

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		let filename = 'Test_Session_Details_' + this.testName + '_' + this.studentName + '_' + year + '-' + month + '-' + day + '.pdf';
		filename = filename.replace(/ /g, '_');

		this.loader.mask();

		HttpClient.default.ESGIApi.file('assessments/test-session-details', 'download', filename, {
			classID: this.props.classID,
			specialistGroupID: this.props.specialistGroupID,
			studentID: this.props.studentID,
			testID: this.props.testId,
			sessionID: current ? this.selectedSession.id : 0,
			subject: this.props.subject.name,
		}).subscribe(() => this.loader.unmask(), () => {
			const reporter = new ExceptionReporter();
			reporter.report('Unable to load pdf file.');
			this.loader.unmask();
		});
	};

	render() {
		return <>
			{this.state.loaded &&
			<Modal animate={true}
			       className={('tsd-modal ' + (this.testType === TestType.YN ? 'yn' : 'score') + (this.iepGoal && ' iep'))}
			       loading={!this.loaded}
			       onCatchError={() => this.props.close()}>
				<Header
					studentName={this.studentName}
					testName={this.testName}
					testBy={this.testedByName}
					showTestedBy={this.selectedSession?.testScreenType === TestScreenType.SelfAssessment ? true : this.showTestedBy}
					showDownload={!this.state.editMode && this.state.activeTab === 'details'}
					showIEP={!!this.iepGoal}
					testSessionsNumber={this.nonDeletedSessionsNumber}
					deletedCurrentSession={this.selectedSession ? this.selectedSession.deleted : true}
					download={this.download}
					isSelfAssess={this.selectedSession?.testScreenType === TestScreenType.SelfAssessment}
				/>
				<ModalBody>
					{hasIEPLogic() && this.iepGoal
						? (
							<div className='tsd-tabs'>
								<Tabs
									items={this.tabs}
									active={this.state.activeTab}
									onSelect={this.onSelectTab}
								/>
								<div className='tsd-tabs-panel-wrapper'>
									<TabPanel key='details' name='details'>
										{this.renderDetails()}
									</TabPanel>
									<TabPanel key='iep' name='iep'>
										<IEPGoalTab
											service={this.service}
											editMode={this.state.iepEditMode}
										/>
									</TabPanel>
								</div>
							</div>
						)
						: this.renderDetails()
					}
				</ModalBody>
				{this.state.activeTab === 'details'
					? (
						<Footer
							state={null}
							onChange={null}
							close={this.close}
							allowEdit={this.allowEdit}
							allowRestore={this.allowRestore}
							closeButtonTitle={((this.state.editMode) ? 'Cancel' : 'Close')}
							allowSave={!this.allowRestore && this.canEdit && this.state.editMode}
							saveDisabled={!this.bodyValid}
							setEditMode={this.setEditMode}
							saveClicked={this.saveButtonClicked}
							restoreClicked={this.restoreButtonClicked}
							cancelClicked={this.cancelButtonClicked}
						/>
					)
					: (
						<IEPFooter
							editMode={this.state.iepEditMode}
							onSave={this.saveButtonClickerIEP}
							onEdit={() => this.setEditModeIEP(true)}
							onCancel={this.cancelButtonClickedIEP}
						/>
					)
				}
				<SnackbarLayout/>
			</Modal>}

			{this.renderSubsetModal()}
		</>;
	}

	private renderDetails() {
		if (this.testType === TestType.YN) {
			return (
				<YN
					hierarchy={this.props.hierarchy}
					state={this.state.bodyYn}
					onChange={(ch, cb) => this.setState({bodyYn: ch}, cb)}
					close={this.close}
					questions={this.questions}
					testSessions={this.state.testSessions}
					canChangeSession={this.canEdit}
					editMode={this.state.editMode}
					api={this.api}
					setEditMode={this.setEditMode}
					canSubset={this.canTestSubset}
					testSubsetHandler={this.testSubsetButtonClicked}
					readOnly={!this.canEdit}
					studentName={this.studentName}
					testName={this.testName}
					testId={this.props.testId}
					studentID={this.props.studentID}
					testResultsCorrectVerbiage={this.testResultsCorrectVerbiage}
					testResultsIncorrectVerbiage={this.testResultsIncorrectVerbiage}
					nowProfileTZ={this.nowProfileTZ}
				/>
			);
		}
		return (
			<SS
				hierarchy={this.props.hierarchy}
				state={this.state.bodySs}
				onChange={(ch, cb) => this.setState({bodySs: ch}, cb)}
				close={this.close}
				questions={this.questions}
				testSessions={this.state.testSessions}
				canChangeSession={this.canEdit}
				editMode={this.state.editMode}
				api={this.api}
				setEditMode={this.setEditMode}
				readOnly={!this.canEdit}
				studentName={this.studentName}
				testName={this.testName}
				testId={this.props.testId}
				studentID={this.props.studentID}
				totalPossible={this.totalPossible}
				nowProfileTZ={this.nowProfileTZ}
			/>
		);
	}
}

