import './styles.less';
import React from 'react';
import {DataService} from '../../../services/data-service';

class State {
	showHidden: boolean = false;
}

class Props {
	dataService: DataService;
}

export class HiddenSwitch extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = new State();
	}
	render() {
		return <div className='hidden-switch'>
			<label htmlFor='hidden-switch'>
				<input type='checkbox' checked={this.state.showHidden} onChange={() => this.changed()}
				       className='ace' id='hidden-switch'/>
				<span className='lbl'>
                        Show hidden results
                    </span>
			</label>
		</div>;
	}

	changed = () => {
		let newValue = !this.state.showHidden;

		this.setState({showHidden: newValue}, () => {
			this.props.dataService.filter.update({showHidden: newValue});
		});

	}
}
