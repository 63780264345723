import '@esgi/deprecated/old-libs';
import React from 'react';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {Loader} from '@esgi/deprecated/jquery';
import AppHistory from 'shared/tools/history/history';
import {environment} from '@esgi/core/environments';
import {afterLoginHandler} from '../redirect-module/redirect';
import {Authenticator, LoginResponse} from '@esgi/core/authentication';
import {HttpClient} from '@esgi/api';

export default class ByLinkedClever extends React.PureComponent {

	public componentDidMount() {
		const loader = new Loader('.holder');
		loader.mask();

		const params = new URLSearchParams(window.location.search);
		const token = params.get('token');
		if (!token) {
			OldAlerts.bsalert('Invalid token', () => AppHistory.navigateOutside('/'));
			return;
		}

		const base64Url = token.split('.')[1];
		if (!base64Url) {
			OldAlerts.bsalert('Invalid token', () => AppHistory.navigateOutside('/'));
			return;
		}

		const base64 = base64Url.replace('-', '+').replace('_', '/');
		const parsedToken: any = JSON.parse(window.atob(base64));

		let exp = parsedToken.exp;
		if (!exp) {
			OldAlerts.bsalert('Invalid token', () => AppHistory.navigateOutside('/'));
			return;
		}

		exp = exp * 1000;

		const current = Date.now();

		//token expired. redirect back to clever for get new token.
		if (current > exp) {
			sessionStorage.setItem('esgi-registration-disallow', 'true');

			AppHistory.navigateOutside(`${environment.ssoApiURL}/integrations/clever/authesgi`);
			return;
		}

		HttpClient.default.ESGIApi.post<LoginResponse>('Login', 'ByLinkedClever', {token: token})
			.subscribe(async (r) => {
				const validator = new Authenticator();
				const authenticatorResult = await validator.authenticate(r);

				if (authenticatorResult.valid) {
					afterLoginHandler(r.user);
				} else if (!authenticatorResult.redirect){
					AppHistory.navigateOutside('/');
				}
				loader.unmask();
			});
	}

	public render() {
		return <div className='holder'/>;
	}
}

