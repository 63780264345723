import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type SelectableListGroupContextValue = {
	/** Determines whether a single or multiple items can be pressed at a time. */
	type: 'single' | 'multiple';

	/** The controlled value of the pressed item when type is "single" or "multiple". */
	value: string[];

	/** Handler called for set item value to "controlled value". */
	onItemActivate: (value: string) => void;

	/** Handler called for remove item value from "controlled value". */
	onItemDeactivate: (value: string) => void;

	/** Contain all passed items value. */
	valuesVocabulary: string[];

	/** Add a value to vocabuldary state. */
	addValueToVocabulary: (value: string) => void;

	/** Remove a value from vocabuldary state. */
	removeValueFromVocabulary: (value: string) => void;

	/** Add all items into a controlled state value. Use for multiple type. For type "single" use plug. */
	onActivateAllItems: VoidFunction;

	/** Remove all items from a controlled state value. Use for multiple type. For type "single" use plug. */
	onDeactivateAllItems: VoidFunction;
};

export const SelectableListGroupContext = createContext<SelectableListGroupContextValue>({
	type: 'single',
	value: [],
	onItemActivate: noop,
	onItemDeactivate: noop,
	valuesVocabulary: [],
	addValueToVocabulary: noop,
	removeValueFromVocabulary: noop,
	onActivateAllItems: noop,
	onDeactivateAllItems: noop,
});

export function useSelectableListGroupContext() {
	return useContext(SelectableListGroupContext);
}
