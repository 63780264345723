import {useCallback, useEffect, useMemo, useState} from 'react';
import {skip, tap} from 'rxjs/operators';
import {Buttons} from '@esgillc/ui-kit/button';
import {Loader, ServiceLoader} from '@esgillc/ui-kit/loader';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportModal, ReportModalCloseIcon, ReportModalHeader, ReportModalTitle} from '@esgi/deprecated/ui-kit/report/modal';
import {SubjectType} from '@esgi/core/enums';
import {Header} from './components/header';
import {SelectedInfo, SubjectTab} from './models';
import {Table} from './components/table';
import {TestSelectorService} from './service';
import {TestsSourceService} from './types';
import {HierarchySnapshot} from 'modules/hierarchy/models';

import styles from './styles.module.less';

type Props = {
	subjectID: number,
	subjectType: SubjectType,
	hierarchy: HierarchySnapshot,
	includeAll: boolean,
	title: string,

	testsSourceService: TestsSourceService,

	onSubjectChanged: (subject: SubjectTab) => void,
	onClose: () => void,
	onRunReportClicked: (info: SelectedInfo) => void,
};

export function TestSelector(props: Props): JSX.Element {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const modalManager = useModal();
	const close = useCloseModal(modalManager, props.onClose);

	const service = useMemo(() => new TestSelectorService(props.testsSourceService), []);

	useEffect(() => {
		const sub = service.init(props.subjectID, props.subjectType, props.hierarchy, props.includeAll)
			.pipe(tap(() => setIsLoading(false)))
			.subscribe({error: () => close()});
		return () => sub.unsubscribe();
	}, []);

	useEffect(() => {
		const sub = service.selectedSubject
			.pipe(skip(1))
			.subscribe((s) => props.onSubjectChanged(s));
		return () => sub.unsubscribe();
	}, []);

	const selectedTests = useBehaviorSubject(service.selectedTestsIDs);

	const run = useCallback(() => {
		props.onRunReportClicked({
			subject: {...service.selectedSubject.value},
			tests: service.selectedTestsIDs.value.map(v => service.tests.value.find(t => t.testID === v)),
		});
	}, []);

	if (isLoading) {
		return <Loader show={true} fullscreen/>;
	}

	return (
		<ReportModal modalManagerRef={modalManager}>
			<ReportModalHeader>
				<ReportModalTitle className={styles.title}>{props.title}</ReportModalTitle>
				<ReportModalCloseIcon onClick={close}/>
			</ReportModalHeader>
			<Modal.Body className={styles.modalBody}>
				<div>
					<Header service={service}/>
					<Table service={service}/>
				</div>
				<ServiceLoader trackingService={service} fullscreen/>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={close}>
					CANCEL
				</Buttons.Gray>
				<Buttons.Contained disabled={!selectedTests.length} onClick={run}>
					RUN REPORT
				</Buttons.Contained>
			</Modal.Footer>
		</ReportModal>
	);
}
