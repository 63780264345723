import React, {createRef, PureComponent} from 'react';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import {Alert, AlertBody, AlertFooter, AlertHeader} from '@esgi/deprecated/ui-kit/modal/alert';
import styles from './styles.module.less';

interface Props {
	onCancelClicked: () => void;
	onViewDetailsClicked: () => void;
}

export class AfterCopyAlert extends PureComponent<Props> {
	private readonly modalManagerRef: ModalManagerRef = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef}>
			<AlertHeader>
				<Title>
					Your new test has been created.
				</Title>
			</AlertHeader>
			<AlertBody className={styles.afterCopyAlertBody}>
				Would you like to view the details now?
			</AlertBody>
			<AlertFooter>
				<LinkButton onClick={() => this.modalManagerRef.current.close(this.props.onCancelClicked)}>
					CANCEL
				</LinkButton>
				<LinkButton onClick={() => this.modalManagerRef.current.close(this.props.onViewDetailsClicked)}>
					VIEW DETAILS
				</LinkButton>
			</AlertFooter>
		</Alert>;
	}
}
