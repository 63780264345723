import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {validatePin} from './utils';
import {userStorage} from '@esgi/core/authentication';


export class PinSettingService extends BaseService {

	public pin: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	public confirmPin: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

	public newPin = new BehaviorSubject<boolean>(false);
	public pinValid = new BehaviorSubject<boolean>(false);
	public pinsEqual = new BehaviorSubject<boolean>(false);
	public confirmPinValid = new BehaviorSubject<boolean>(false);

	private readonly controller: string = 'user-settings';
	private initialPin: string;

	constructor() {
		super();
		this.confirmPin.pipe(
			takeUntil(this.destroy$),
			map(p => validatePin(p)),
			tap(p => this.confirmPinValid.next(p)),
		).subscribe();

		this.pin.pipe(
			takeUntil(this.destroy$),
			map(p => validatePin(p)),
			tap(v => this.pinValid.next(v)),
		).subscribe();

		combineLatest(this.pin, this.confirmPin)
			.pipe(
				takeUntil(this.destroy$),
				map(d => {
					const pin = d[0];
					const confirmPin = d[1];
					return pin.every((p, index) => confirmPin[index] === p);
				}),
				tap(p => this.pinsEqual.next(p)),
			).subscribe();
	}

	public init(): Observable<string> {
		return this.httpClient.ESGIApi.get<{ pin: string }>(this.controller, 'screen-lock-pin')
			.pipe(
				takeUntil(this.destroy$),
				map((r) => {
					userStorage.update({screenLockPIN: r.pin});
					const pin = (r?.pin || '').split('');
					this.pin.next([...pin]);
					this.confirmPin.next([...pin]);
					this.initialPin = r.pin;
					return r.pin;
				}),
			).asObservable();
	}

	public save(): Observable<void> {
		const pin = this.pin.value.join('');
		if (pin !== this.initialPin) {
			return this.httpClient.ESGIApi.post<void>(this.controller, 'update-screen-lock-pin', {pin: pin})
				.pipe(takeUntil(this.destroy$), tap(() => userStorage.update({screenLockPIN: pin})))
				.asObservable();
		}

		return new Observable((observer) => {
			observer.next();
			observer.complete();
		});
	}
}
