import {join, useStreamEffect} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {FormControl, FormElement, FormGroup, useForm, Validators} from '@esgillc/ui-kit/form';
import {DragIcon} from 'shared/modules/auto-test-creator/icons/drag-icon';
import {RemoveIcon} from 'shared/modules/auto-test-creator/icons/remove-icon';
import {maxLengths} from 'shared/modules/auto-test-creator/constants';
import styles from './styles.module.less';

interface Props {
	orderNumber: number;
	name: string;
	id: string;
	isShowDrag: boolean;
	onDelete: (index: string) => void;
	onChange: (id: string, name: string, isValid: boolean) => void;
	className?: string;
}

export function QuestionRow({
	orderNumber,
	name,
	id,
	isShowDrag,
	onDelete,
	onChange,
	className,
}: Props) {

	const questionForm = useForm(() => new FormGroup({
		question: new FormControl(name, {validators: [Validators.required(), Validators.length.max(maxLengths.question)]}),
	}));
	useStreamEffect(questionForm.controls.question.onChanged, (value) => {
		onChange(id, value.currState.value, !!value.currState.value?.length);
	});

	return <div id={id} data-cy='question-row' className={join(styles.questionRow, className)}>
		<div className={styles.questionRowContainer}>
			<div data-cy='order-number' className={styles.order}>{orderNumber + 1}</div>

			<div data-cy='drag' className={styles.dragIconContainer}>
				{isShowDrag && <DragIcon className={join(styles.drag, 'drag')}/>}
			</div>

			<div data-cy='question-row' className={styles.questionInputContainer}>
				<FormElement className={styles.questionInput} control={questionForm.controls.question}>
					<Input className={styles.question} maxLength={maxLengths.question}/>
					<OnErrorTooltip showOnError='required' placement='right'>
						Text required
					</OnErrorTooltip>
					<OnErrorTooltip showOnError='length-max' placement='right'>
						30 characters max
					</OnErrorTooltip>
				</FormElement>

				<div data-cy='remove' className={styles.removeIconContainer} onClick={() => onDelete(id)}>
					<OnHoverTooltip message='Remove question from test'>
						<RemoveIcon className={styles.removeIcon}/>
					</OnHoverTooltip>
				</div>
			</div>
		</div>
	</div>;
}
