import {Text} from '@esgi/ui/typography';
import {Container} from './index.styled';
import {SectionBlock} from '../../../../components/section-block';
import {GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';
import {Dispatch} from 'react';
import {AssignmentState} from '@esgi/main/kits/assignments';

type Props = {
	canCreateAssignment: boolean;
	canCreateAsDraft: boolean;
	hasStudentsWithoutCredentials: boolean;
	onCreateAssignment: Dispatch<AssignmentState>;
};

export function AlternativeOptionsSection({
	canCreateAssignment,
	canCreateAsDraft,
	hasStudentsWithoutCredentials,
	onCreateAssignment,
}: Props) {
	return (
		<Container>
			<SectionBlock title='Alternative Option'>
				{hasStudentsWithoutCredentials && (
					<GridBox dataCy='cant-create-save-draft' gap='3'>
						<Text size='medium' color='neutral40'>
							You cannot create this assignment, because some students do not have their credentials set up.
						</Text>
						<Text size='medium' color='neutral40'>
							You may{' '}
							<Text size='medium' color='tertiary'>
								save this assignment as a draft{' '}
							</Text>
							and create the assignment once all students' credentials are set.
						</Text>
					</GridBox>
				)}

				{canCreateAssignment && (
					<GridBox dataCy='can-create-by-click' gap='3'>
						<Text size='medium' color='neutral40'>
							You can create this assignment by clicking the{' '}
							<Text size='medium' color='tertiary'>
								Create{' '}
							</Text>
							button at the top of this section.
						</Text>

						<Text size='medium' color='neutral40'>
							Alternatively, you may{' '}
							<Text size='medium' color='tertiary'>
								save this assignment as a draft{' '}
							</Text>
							or{' '}
							<Text size='medium' color='tertiary'>
								post it to students right away.{' '}
							</Text>
							Posting to students now ensures they receive the assignment immediately.
						</Text>
					</GridBox>
				)}
			</SectionBlock>
			<GridBox flow='column' gap={3}>
				<Button
					disabled={!canCreateAsDraft}
					color='secondary'
					onClick={() => onCreateAssignment(AssignmentState.Draft)}
				>
					<Text size='medium' bold>
						Create Draft
					</Text>
				</Button>
				<Button
					disabled={!canCreateAssignment}
					color='secondary'
					onClick={() => onCreateAssignment(AssignmentState.Published)}
				>
					<Text size='medium' bold>
						Post Now
					</Text>
				</Button>
			</GridBox>
		</Container>
	);
}
