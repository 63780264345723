import {FormField, Validators} from '@esgi/deprecated/knockout';
import {ValidationServer} from '../../server';
import {AsyncFieldContainer} from '../../common/component';

import './username-input.less';


export class UsernameInputComponent extends AsyncFieldContainer<string> {
	public buildField(initValue): FormField<string> {
		const uniqueUserNameValidator = Validators.Ajax((field) => {
			this.inProcess(true);
			return ValidationServer.checkUserName(field.value()).always((r) => {
				this.inProcess(false);
			});
		}, r => {
			if (r.exist) {
				return '<div>This user name is already taken.</div><div>Please enter another one.</div>';
			}
		});
		const f = this.createField<string>(initValue || '',
			Validators.Required('<div>Please enter a username.</div>'),
			Validators.Length(3, null, '<div>User name must be at least 3 characters long</div>'),
			Validators.Length(null, 40, '<div>Sorry. Please create a username that is 40 characters or less</div>'),
			Validators.Regex(/^\s*[A-Za-z0-9@\._-]{3,40}\s*$/, '<div>A username can only contain letters A-Z, numbers 0-9,</div><div>@ signs, periods and underscores</div>'),
			uniqueUserNameValidator);
		f.validation.successValidation(true);
		f.validation.showValidation(true);

		f.validation.validationMessageTitleUseHtml = true;

		return f;
	}

	trySetUserName(value: string): void {
		if (!value) {
			return;
		}
		const reg = /^\s*[A-Za-z0-9@\._-]{3,40}\s*$/;
		if (reg.test(value)) {
			ValidationServer.checkUserName(value).done((r) => {
				if (!r.exist) {
					this.field.value(value);
				}
			});
		}
	}

	public template = () =>
		<div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group username-field'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation} ,afterRender: true">
					<label className='control-label'>Create Username</label>
					<input id='new-username' name='new-username' type='text' className='form-control input-md'
					       data-bind='value: value'/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
					<ko data-bind='if: !root.inProcess()'>
						<i data-bind="css: {'fa-check': showSuccessValidation, 'fa-close': showErrorValidation}" className='fa form-control-feedback'/>
					</ko>
					<ko data-bind='if: root.inProcess()'>
						<i className='fa fa-spinner fa-spin form-control-feedback'/>
					</ko>
				</div>
			</div>
		</div>;
}
