import {ReportFormat} from './types';

export const getFileType = (fileType: ReportFormat): number => {
	if (fileType === ReportFormat.SingleFile) {
		return 1;
	}
	return 2;
};

export const getFileFormatName = (fileType: ReportFormat): string => {
	if (fileType === ReportFormat.SingleFile) {
		return 'PDF';
	}
	return 'ZIP';
};