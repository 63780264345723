import {Input as BaseInput, InputProps} from '@esgi/ui/controls';
import {forwardRef} from 'react';
import {inputWithTooltipError} from '../hocs';

type Props = Omit<InputProps, 'error'> & {
	error?: string | undefined;
};

const InputImp = forwardRef<HTMLInputElement, Props>(({error, ...props}, forwardedRef) => (
	<BaseInput {...props} ref={forwardedRef} />
));

export const Input = inputWithTooltipError(InputImp);
