import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {routes} from '@esgi/main/libs/core';

export enum FlashCardParams {
	studentID = 'studentId',
	testID = 'testId',
	subjectID = 'subjectId'
}

export type FlashCardStateParams = Record<FlashCardParams, string | undefined>;

export function useFlashcardUrlWithParams() {
	const navigate = useNavigate();

	return useCallback(
		(params: FlashCardStateParams) => {
			const cleanedParams = Object.fromEntries(
				Object.entries(params).filter(([_, value]) => value)
			);
			navigate(routes.teacher.activities.flashcards, {state: {
				initialSelectedParams: cleanedParams,
			}});
		},
		[navigate],
	);
}
