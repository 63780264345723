import {Dispatch, createContext, useContext} from 'react';
import {noop} from 'underscore';
import {Point} from './types';

export type InformationScaleContextValue = {
	/**
	 * Set an start angle for rendering information scale dots.
	 */
	setStartAngleForRenderDots: Dispatch<number>;

	/**
	 * The information scale radius.
	 */
	scaleRadius: number;

	/**
	 ** Set the information scale radius.
	 */
	setScaleRadius: Dispatch<number>;

	/**
	 *
	 * Get coordinates using the passed angle in grades.
	 * Calculating depends on the scale Radius and size.
	 *
	 * @param angle
	 * @returns
	 */
	getCoordinatesByAngle: (angle: number) => Point;

	/**
	 * The dot elipse radius.
	 */
	dotElipseWideRadius: number;

	/**
	 * The dor circle radius.
	 */
	dotCircleRadius: number;

	/**
	 * The width and height for the main information scale svg.
	 */
	svgSize: number;
};

export const InformationScaleContext = createContext<InformationScaleContextValue>({
	setStartAngleForRenderDots: noop,
	scaleRadius: 0,
	setScaleRadius: noop,
	getCoordinatesByAngle: () => ({x: 0, y: 0}),
	dotElipseWideRadius: 0,
	dotCircleRadius: 0,
	svgSize: 0,
});

export function useInformationScaleContext() {
	return useContext(InformationScaleContext);
}
