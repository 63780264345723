import './footer.less';

export interface Props {
		editMode: boolean;
    onEdit: () => void;
    onSave: () => void;
    onCancel: () => void;
}

export function IEPFooter(props: Props) {
	const {onCancel, onEdit, onSave} = props;

	return (
		<div className='tsd-footer'>
			<button
				className='btn btn-close close-button'
				onClick={onCancel}
			>
				{props.editMode ? 'Cancel' : 'Close'}
			</button>
			<button
				className='btn btn-primary btn-edit save-button'
				onClick={props.editMode ? onSave : onEdit}
			>
				{props.editMode ? 'Save' : 'Edit'}
			</button>
		</div>
	);
}