import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Header = styled(GridBox, {
	paddingTop: 18,
	gap: 18,
});

export const ContentWrapper = styled(GridBox, {
	justifyContent: 'space-between',
	alignItems: 'center',
	gridAutoFlow: 'column',
});

export const Content = styled(GridBox, {
	gap: '$1',
	alignItems: 'center',
	gridAutoFlow: 'column',
	color: '$neutral56',

	[`& ${Text}`]: {
		color: 'currentColor',
	},
	'& span': {
		lineHeight: '12px',
	},
	'& span svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const ActionButtons = styled(GridBox, {
	gap: 20,
	alignItems: 'center',
	gridAutoFlow: 'column',
});
