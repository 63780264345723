import {SelectableListGroup} from './components/selectable-list-group';
import {SelectableListGroupDroppable} from './components/selectable-list-group-droppable';
import {SelectableListGroupRoot} from './components/selectable-list-group-root';
import {SelectableListItem} from './components/selectable-list-item';
import {SelectableListItemDraggable} from './components/selectable-list-item-draggable';
import {SelectableListRoot} from './components/selectable-list-root';
import {SelectableListStyledButton} from './components/selectable-list-styled-button';
import {SelectableListTrigger} from './components/selectable-list-trigger';
import {SelectableListTriggerIcon} from './components/selectable-list-trigger-icon';

export const SelectableList = Object.assign(SelectableListRoot, {
	GroupRoot: SelectableListGroupRoot,
	Trigger: SelectableListTrigger,
	TriggerIcon: SelectableListTriggerIcon,
	StyledButton: SelectableListStyledButton,
	Group: SelectableListGroup,
	GroupDroppable: SelectableListGroupDroppable,
	Item: SelectableListItem,
	ItemDraggable: SelectableListItemDraggable,
});

export * from './components/selectable-list-group-root/context';