import {useMemo} from 'react';
import {BaseTrackDateModel, TrackDateModelState} from '../types';
import moment from 'moment';

export function useDateValuesChanged({
	currentTrackDates,
	datesInRowsState,
}: {
	currentTrackDates: BaseTrackDateModel[];
	datesInRowsState: TrackDateModelState[];
}) {
	return useMemo(() => {
		if (currentTrackDates.length !== datesInRowsState.length) {
			return true;
		}

		return datesInRowsState.some(({dateFrom, dateTo}, index) => {
			return (
				moment(dateFrom.value).diff(moment(currentTrackDates[index]!.dateFrom)) !== 0 ||
				moment(dateTo.value).diff(moment(currentTrackDates[index]!.dateTo)) !== 0
			);
		});
	}, [currentTrackDates, datesInRowsState]);
}
