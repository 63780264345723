export interface UserSettingsResponse {
	isCleverAccountLinked: boolean;
	isOneClickAccountLinked: boolean;
	sortBy: StudentSort;
	sortByVariants: StudentSortModel[];
	timeZones: TimeZoneResponse[];
	selectedTimeZone: TimeZoneResponse;
	showExpiredUsers: boolean;
}

export interface TimeZoneResponse {
	id: string;
	name: string;
}

export class UpdateModel {
	constructor(
		public sortBy: StudentSort,
		public timeZone: string,
		public showExpiredUsers: boolean,
	) {
	}
}

export interface StudentSortModel {
	value: StudentSort;
	name: string;
}

export interface StudentSortModel {
	value: StudentSort;
	name: string;
}

export enum StudentSort {
	FirstName = 'FirstName',
	LastName = 'LastName',
}