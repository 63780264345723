import {useEffect, useMemo} from 'react';
import {Chart} from './components/chart';
import {Header} from './components/header';
import {Modal} from './components/modal';
import {Note} from './components/note';
import {StudentLevelReportService} from '../../services/student-level-report-service';
import {StudentLevelReportModel} from '../../types/student-level-report-service-types';
import styles from './styles.module.less';
import {Table} from './components/table';
import {useServiceFactory} from '@esgi/core/service';
import {useStream} from '@esgillc/ui-kit/utils';
import {FiltersService} from '../../services/filters-service';

type StudentLevelReportProps = {
	report: StudentLevelReportModel;
	onClosed: () => void;
	filterService: FiltersService;
};

export function StudentLevelReport({report, onClosed, filterService}: StudentLevelReportProps) {
	const service = useServiceFactory(() => new StudentLevelReportService(filterService));

	useEffect(() => {
		service.init(report);
	}, []);

	const loading = useStream(service.loading$);
	const tables = useStream(service.tables$);
	const filter = useStream(service.filter$);
	const charts = useStream(service.charts$);
	const notes = useStream(service.notes$);

	const chartsContent = useMemo(
		() =>
			charts?.map(({chart, studentID}) => {
				const table = tables?.find(({studentID: tableStudentId}) => tableStudentId === studentID);
				const note = notes?.find(({studentID: noteStudentId}) => noteStudentId === studentID);

				return (
					<div key={studentID} className={styles.page} data-cy='student-level-report-chart'>
						<Table table={table} />
						<Chart chart={chart} />
						<Note showNotes={filter.showNotes} showSummary={filter.showSummary} note={note} />
					</div>
				);
			}),
		[charts, tables, notes, filter],
	);

	return (
		<Modal service={service} loading={loading} onClosed={onClosed}>
			<Header service={service} />
			<div className={styles.container}>{chartsContent}</div>
		</Modal>
	);
}
