import {Dispatch, useCallback} from 'react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectName} from '@esgi/main/kits/common';

type Props = {
	selectedSubjectID: SubjectTab['id'];
	setSelectedSubjectID: Dispatch<SubjectTab['id']>;
	subjects: SubjectTab[];
	skeleton: boolean;
};

export function SubjectSelect({subjects, skeleton, selectedSubjectID, setSelectedSubjectID}: Props) {
	const onValueChange = useCallback((value: string) => setSelectedSubjectID(Number(value)), [setSelectedSubjectID]);

	return (
		<Select.Root value={String(selectedSubjectID)} onValueChange={onValueChange} dataCy='subject-select'>
			<Select.Field placeholder='Subject' skeleton={skeleton} disabled={skeleton} />
			<Select.Content>
				<Select.Option value='-1'>
					<Text bold size='medium' color='primary'>
						All
					</Text>
				</Select.Option>
				{subjects.map(({id, name, level}) => (
					<Select.Option value={String(id)} key={id}>
						<SubjectName subjectLevel={level} size='medium'>
							{name}
						</SubjectName>
					</Select.Option>
				))}
			</Select.Content>
		</Select.Root>
	);
}
