import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Wrapper = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: '16px',
	height: '16px',
	borderRadius: '4px',
	border: '1px solid: $border',
	backgroundColor: '$border',

	'& svg': {
		display: 'none',
		width: '16px',
		height: '16px',
	},

	'& path': {
		fill: '$primary',
	},

	variants: {
		checked: {
			true: {
				backgroundColor: '$primary92',

				'& svg': {
					display: 'inline-block',
				},
			},
		},
		disabled: {
			true: {
				border: '1px solid $mild',
				backgroundColor: '$vivid',
			},
		},
	},
});
