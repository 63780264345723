import React, {useCallback, useEffect} from 'react';
import {DesignVersion, useDesignVersion} from '@esgi/main/libs/core';
import styles from './styles.module.less';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {ArrowRightIcon} from '@esgillc/ui-kit/icons';


export function VersionToggleBanner({onClose}: { onClose: VoidFunction }) {
	const [, setDesignVersion] = useDesignVersion();

	const onNewDesignClicked = useCallback(() => {
		setDesignVersion(DesignVersion.Redesign);
		location.href = '/home';
	}, []);

	useEffect(() => {
		let inlineManualTriggerNodes;

		const observer = new MutationObserver(() => {
			inlineManualTriggerNodes = document.querySelectorAll('.inmplayer-trigger');

			for (const node of inlineManualTriggerNodes) {
				node?.setAttribute('style', 'top: 45px !important');
			}
		});

		observer.observe(document, {subtree: true, childList: true});

		return () => {
			if (observer) {
				observer.disconnect();

				for (const node of inlineManualTriggerNodes) {
					node?.removeAttribute('style');
				}
			}
		};
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<span className={styles.orangeText}>
					ESGI has a new homepage!
				</span>
				<span>
					Learn more about the new beta version
					<a className={styles.link} href='https://info.esgisoftware.com/release-notes/new-teacher-dashboard-beta' target='_blank' rel='noreferrer'> here </a>
					or check it out now by clicking 'Go Now’.
				</span>
				<Buttons.Contained className={styles.navigateButton} onClick={onNewDesignClicked}>
					<span>Go Now</span>
					<ArrowRightIcon height={8}/>
				</Buttons.Contained>
			</div>
			<CloseIcon onClick={onClose}/>
		</div>
	);
}