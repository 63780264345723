import {useEffect} from 'react';
import {useService} from '@esgi/core/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {Form} from '@esgillc/ui-kit/form';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {AdditionalService} from './service';
import {TabFooter} from 'modules/forms/students-form/components/profile-modal/components/tab-footer';
import {StudentProfileMode, StudentProfileTab} from 'modules/forms/students-form/types';
import {LocationFormProps} from '../location/types';
import {AdditionalInfoForm} from './componenets/additional-info-form';

export function Additional({
	mode,
	studentID,
	initData,
	preSelected,
	tabsApi,
	onClose,
}: LocationFormProps) {

	const service = useService(AdditionalService);
	const tabs = useBehaviorSubject(service.tabsStatus);

	useEffect(() => {
		service.init(mode, initData, preSelected, studentID, tabsApi);
	}, []);

	const onSave = (isSaveAndAdd?: boolean) => {
		if (tabs[StudentProfileTab.Additional]?.isTouched) {
			service.save(isSaveAndAdd).subscribe(() => {
				if (!isSaveAndAdd) {
					onClose();
				}
			});
		} else {
			if (!isSaveAndAdd) {
				onClose();
			}
		}
	};

	return <div data-cy='additional-form'>
		<ServiceLoader trackingService={service} fullscreen/>
			<Form controller={service.form} noValidate>
				<AdditionalInfoForm
					initData={initData}
					form={service.form}
					isViewMode={mode === StudentProfileMode.view}
				/>
			</Form>
		<TabFooter
			tabsApi={tabs}
			canSaveAndAdd={initData.isOpenedWithAdd}
			currentTab={StudentProfileTab.Additional}
			mode={mode}
			onSave={() => onSave(false)}
			onSaveAndAdd={() => onSave(true)}
		/>
	</div>;
}
