import {useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {SubjectType} from '@esgi/core/enums';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import {FlashCardsWrapper} from 'shared/modules/reports/flash-cards/settings-form/wrapper';
import {ViewFlashCardsSettingsEvent} from 'modules/reports/parent-letter/events';

interface Props {
	applyHomepageChanges: (changes: ChangesCollector) => void;
	subjectID: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	noStudents: boolean;
}

function Icon() {
	return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='16' height='15'
	            viewBox='0 0 16 15'
	            fill='none'>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M1.00209 0C0.448651 0 0 0.43905 0 0.980645V6.86452C0 7.40611 0.448651 7.84516 1.00209 7.84516H5.51149C6.06493 7.84516 6.51358 7.40611 6.51358 6.86452V0.980645C6.51358 0.43905 6.06493 0 5.51149 0H1.00209ZM9.51985 0C8.96641 0 8.51776 0.43905 8.51776 0.980645V3.92258C8.51776 4.46418 8.96641 4.90323 9.51985 4.90323H14.0292C14.5827 4.90323 15.0313 4.46418 15.0313 3.92258V0.980645C15.0313 0.43905 14.5827 0 14.0292 0H9.51985ZM0 10.7871C0 10.2455 0.448651 9.80645 1.00209 9.80645H5.51149C6.06493 9.80645 6.51358 10.2455 6.51358 10.7871V13.729C6.51358 14.2706 6.06493 14.7097 5.51149 14.7097H1.00209C0.448651 14.7097 0 14.2706 0 13.729V10.7871ZM9.51985 6.86452C8.96641 6.86452 8.51776 7.30357 8.51776 7.84516V13.729C8.51776 14.2706 8.96641 14.7097 9.51985 14.7097H14.0292C14.5827 14.7097 15.0313 14.2706 15.0313 13.729V7.84516C15.0313 7.30357 14.5827 6.86452 14.0292 6.86452H9.51985Z'
		      fill='#0088CC'/>
	</svg>;
}

export default function FlashCardsButton(props: Props): JSX.Element {
	const [open, setOpen] = useState(false);
	const [initModel, setInitModel] = useState<Models.InitModel>();
	const [changesCollector, setChangesCollector] = useState<ChangesCollector>();

	const openFlashCards = () => {
		setChangesCollector(new ChangesCollector({
			id: props.subjectID,
			type: props.subjectType,
		}, props.hierarchy.snapshot));
		setOpen(true);
	};

	useEventEffect(ViewFlashCardsSettingsEvent, (args) => {
		setInitModel(args.initModel);
		openFlashCards();
	});

	const report = open && <FlashCardsWrapper hierarchySnapshot={props.hierarchy.snapshot}
	                                          initModel={initModel}
	                                          changesCollector={changesCollector}
	                                          parentLetterOnClosed={(emit, changes) => emit && props.applyHomepageChanges(changes)}
	                                          onCloseClicked={() => {
		                                          props.applyHomepageChanges(changesCollector);
		                                          setInitModel(null);
		                                          setChangesCollector(null);
		                                          setOpen(false);
	                                          }}
	                                          subjectID={props.subjectID}
	                                          subjectType={props.subjectType}/>;

	return <>
		<NoStudentProtectedButton title='Flashcards'
		                          icon={<Icon/>}
		                          linkClassName='flash-cards-report-link'
		                          hoverTitle='Generate practice cards based on student results'
		                          noStudents={props.noStudents}
		                          onClicked={openFlashCards}/>
		{report}
	</>;
}
