import {Button} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';
import {SkeletonShape} from '../../../skeleton-shape';
import {useDrawerContext} from '../../context/drawer-context';
import {Content, DrawerWrapper, Header} from './index.styled';
import {Divider} from '../divider.styled';

export function DrawerSkeleton() {
	const {closeDrawer} = useDrawerContext();

	return (
		<DrawerWrapper>
			<Header>
				<SkeletonShape shape='round' width={24} height={24} />
				<SkeletonShape width={100} />
				<Button.Icon onClick={closeDrawer} withBackgroundHover>
					<Close />
				</Button.Icon>
			</Header>
			<Divider />
			<Content>
				{new Array(6).fill(null).map((_, index) => (
					<SkeletonShape width='100%' height={42} key={index} />
				))}
			</Content>
		</DrawerWrapper>
	);
}
