import {useMemo, useState} from 'react';
import {TestExplorer} from '@esgi/main/features/test-explorer';
import {PersonalSubjectDialog} from '@esgi/main/features/teacher/personal-subject-dialog';
import {
	SubjectLevel,
	SubjectTab,
	SubjectType,
	useSubjects,
} from '@esgi/main/libs/store';
import {Props as AutoTestCreatorProps} from 'shared/modules/auto-test-creator';

export default function () {
	const [showCreateSubjectModal, setShowCreateSubjectModal] = useState(false);

	const [{data, loaded: isSubjectsLoaded}] = useSubjects();

	const subjectsData = data as SubjectTab[];

	const subjects = useMemo(() => {
		return subjectsData.reduce<{
			common: SubjectTab[];
			autoTestCreator: AutoTestCreatorProps['subjects'];
		}>(
			(subjectsResult, subject) => {
				const isAppropriateType =
					subject.type === SubjectType.Stock ||
					subject.type === SubjectType.Personal;
				const isTeacher = subject.level === SubjectLevel.Teacher;
				const isPersonalSubject =
					isAppropriateType && isTeacher && !subject.hidden;

				if (isPersonalSubject) {
					const autoTestCreatorSubject: AutoTestCreatorProps['subjects'][0] = {
						id: subject.id,
						name: subject.name,
						subjectType: subject.type as any,
						tests: [],
					};
					subjectsResult.common.push(subject);
					subjectsResult.autoTestCreator.push(autoTestCreatorSubject);
				}

				return subjectsResult;
			},
			{
				common: [],
				autoTestCreator: [],
			}
		);
	}, [subjectsData]);

	const onNewSubjectCreate = () => {
		setShowCreateSubjectModal(true);
	};

	const onNewSubjectClose = () => {
		setShowCreateSubjectModal(false);
	};

	return (
		<>
			<TestExplorer
				subjects={subjects}
				isSubjectsLoaded={isSubjectsLoaded}
				onNewSubjectCreate={onNewSubjectCreate}
				isNewSubjectCreateDisabled={false}
			/>
			{showCreateSubjectModal && (
				<PersonalSubjectDialog.CreatePersonalSubject
					onClose={onNewSubjectClose}
					forceClose
				/>
			)}
		</>
	);
}
