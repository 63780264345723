import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {forwardRef} from 'react';
import {ThemeProvider, styled} from '../../../theme';
import {BaseComponentProps} from 'libs/ui/src/types';
import {Property} from '@stitches/react/types/css';

type ContextMenuContentProps = DropdownMenu.DropdownMenuContentProps &
	BaseComponentProps & {
		/** Properties which pass into Portal component. */
		portalProps?: DropdownMenu.DropdownMenuPortalProps;

		/**
		 * CSS property sets the z-order of a positioned element.
		 * @default 700
		 */
		zIndex?: Property.ZIndex;
	};

export const ContextMenuContent = forwardRef<HTMLDivElement, ContextMenuContentProps>(
	({dataCy, portalProps = {}, sideOffset, zIndex = 700, ...props}, forwardedRef) => (
		<DropdownMenu.Portal {...portalProps}>
			<ThemeProvider>
				<Content
					style={{zIndex}}
					data-cy={dataCy ?? 'ui-kit-context-menu-content'}
					ref={forwardedRef}
					sideOffset={sideOffset ?? 5}
					{...props}
				/>
			</ThemeProvider>
		</DropdownMenu.Portal>
	),
);

const Content = styled(DropdownMenu.Content, {
	minWidth: 'var(--radix-dropdown-menu-trigger-width)',
	boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',
	borderRadius: 6,
});
