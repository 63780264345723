import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {getUser} from '@esgi/core/authentication';
import {RoutesMap} from '@esgi/main/libs/core';

export function useAuthorization(enabled: boolean) {
	const navigate = useNavigate();

	useEffect(() => {
		if (enabled) {
			const user = getUser();
			if (user && !user.userID || !user) {
				navigate(RoutesMap.login);
			}
		}
	}, []);
}