export interface SelectedValues<T extends object> {
	values: T;
	userInfo: {
		globalSchoolYearId: null | number;
		userId: null | number
	};
}


export class BaseSelectedValuesStorage<T extends object> {

	constructor(public storageKey: string) {
	}

	public getSelectedValues = (globalSchoolYearId: number, userId: number) => {
		try {
			const valuesFromStorage: SelectedValues<T> = JSON.parse(localStorage.getItem(this.storageKey));
			if (valuesFromStorage?.userInfo.globalSchoolYearId !== globalSchoolYearId || valuesFromStorage?.userInfo.userId !== userId) {
				this.clear();
				return null;
			}
			return valuesFromStorage;
		} catch (_) {
			return null;
		}
	};

	public setSelectedValues = (selectedValues: SelectedValues<T>) => {
		localStorage.setItem(this.storageKey, JSON.stringify(selectedValues));
	};

	public clear = () => {
		localStorage.removeItem(this.storageKey);
	};
}
