import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import PreassessActivityReportIcon from 'pages/home/components/report-button/buttons/icons/preassess-activity-report-icon';

const PreassessActivityReportModal = lazyComponent(() => import('modules/reports/preassess-activity'));

class Props {
	noStudents: boolean;
}
class State {
	opened: boolean = false;
}

export default class PreassessActivityButton extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

	public render() {
		return <>
			<NoStudentProtectedButton title='Teacher Activity'
			                  icon={<PreassessActivityReportIcon />}
			                  hoverTitle='Details Pre-Assess usage of their ESGI accounts'
			                  onClicked={() => this.clickHandler()}
			                  noStudents={this.props.noStudents}
			                  linkClassName='preassess-activity-report-link'/>
			{this.renderReport()}
		</>;
	}

	public renderReport() {
		if (this.state.opened) {
			return <Suspense fallback={<div/>}>
				<PreassessActivityReportModal onCloseClicked={() => {
					this.setState({opened: false});
				}}/>
			</Suspense>;
		}
	}

	private clickHandler = () => {
		SsoTracker.trackEvent({
			trackingEvent: 'PreassessActivityReport',
		});

		this.setState({opened: true});
	};
}
