import {GradeScalesApi} from 'api/grade-scales-api';
import {SubjectType} from '@esgi/core/enums';
import {Loader} from '@esgi/deprecated/jquery';
import GradeScaleReportLauncher from '../../reports/grade-scale-report/launcher/launcher';
import {IModalRef, openModal} from '@esgi/deprecated/react';
import React from 'react';
import Wizard from 'shared/modules/grade-scale/wizard/component';
import {GradeScaleHierarchyLevel} from 'shared/modules/reports/grade-scale-report/models';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {HomepageStateChanges} from 'shared/modules/reports/grade-scale-report/launcher/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {from, of} from 'rxjs';

export class GradeScaleLauncher {
	api: GradeScalesApi;
	creationWizardModal: IModalRef;

	constructor(
		public level: GradeScaleHierarchyLevel,
		private subjectID: number,
		private subjectType: SubjectType,
		private hierarchy: HierarchySnapshot,
	) {
		this.api = new GradeScalesApi();
	}

	private loadReportConfigurationLauncher() {
		let launcher = new GradeScaleReportLauncher(this.level, this.subjectID, this.subjectType, this.hierarchy);
		launcher.events.homepageStateChanged((e, changes) => $(this).trigger('homepageStateChanged', changes));
		launcher.load();
		launcher.events.onClose(() => $(this).trigger('close'));
	}

	private loadCreationWizard() {
		this.creationWizardModal = openModal(
			<Wizard
				hierarchy={this.hierarchy}
				initialStep={WizardStep.GradeScale}
				onClosed={() => this.closeCreationWizard()}
				subjectID={this.subjectID}
				onGradeRangeSaved={() => {
					this.closeCreationWizard();
					this.loadReportConfigurationLauncher();
				}}
			/>,
		);
	}

	private closeCreationWizard() {
		if (this.creationWizardModal) {
			this.creationWizardModal.close();
		}
		$(this).trigger('close');
	}

	private resetGradeScales(flag: boolean) {
		if (!flag) {
			return from(Promise.resolve());
		}
		return this.server.clearAllGradeScales();
	}

	load(clearAllGradeScales: boolean = false) {
		this.resetGradeScales(clearAllGradeScales)
			.subscribe(() => {
				const loader: Loader = new Loader($('body'));
				loader.mask();

				return this.server.hasScales(this.subjectID, this.subjectType, this.hierarchy)
					.withCustomErrorHandler(() => loader.unmask())
					.subscribe(result => {
						if (result) {
							this.loadReportConfigurationLauncher();
						} else {
							this.loadCreationWizard();
						}

						$(this).trigger('onload');
						loader.unmask();
					});
			});
	}

	events = {
		onLoad: (callback: () => void) => {
			if (callback) {
				$(this).on('onload', callback);
			}
		},
		onClose: (callback: (_, id: number) => any) => {
			$(this).on('classChanged', callback);
		},
		closed: (callback: () => any) => {
			$(this).on('close', callback);
		},
		groupChanged: (callback: (_, id: number) => any) => {
			$(this).on('groupChanged', callback);
		},
		homepageStateChanged: (callback: (_, stateChanges: HomepageStateChanges) => any) => {
			$(this).on('homepageStateChanged', callback);
		},
	};

	private server =
		{
			hasScales: (subjectID: number, subjectType: SubjectType, hierarchy: HierarchySnapshot) => {
				return this.api.hasScales(subjectID, subjectType, hierarchy);
			},
			clearAllGradeScales: () => {
				return this.api.clearAllGradeScales();
			},
		};
}

export default GradeScaleLauncher;