import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useService} from '@esgi/core/service';

import {useClassData} from '../hooks';
import {CardBox} from '../../card/styled';
import {Container, NumberRow} from '../kit';
import {UntestedStudentsService} from './service';
import {Content, DetailsButton, TestsCountSmallText} from './styled';
import {useNavigate} from 'react-router-dom';
import {routes} from '@esgi/main/libs/core';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	transparent?: boolean,
};

export function UntestedStudents(props: Props) {
	const service = useService(UntestedStudentsService);
	const navigate = useNavigate();

	const {loaded, data} = useClassData(service, props.testIDs, props.studentIDs);

	const {students} = data;

	return <CardBox dataCy='widget-card'>
		<Container direction='column' justify='between' padding20={!props.transparent} noPadding={props.transparent}>
			<FlexBox justify='between'>
				<Text color='neutral40' size='medium' data-cy='widget-name'>Untested Students</Text>
			</FlexBox>
			<Content justify='between'>
				<FlexBox justify='between' align='start' direction='column'>
					<NumberRow text={students} label='Students' skeleton={!loaded}/>
					<FlexBox align='end' dataCy='tests-number'>
						<TestsCountSmallText size='xSmall' font='mono' color='neutral56'>in</TestsCountSmallText>
						<Text size='xLarge' color='base' css={{padding: '0 4px'}}>
							{props.testIDs.length}
						</Text>
						<TestsCountSmallText size='xSmall' font='mono' color='neutral56'>Tests</TestsCountSmallText>
					</FlexBox>
				</FlexBox>
				<FlexBox direction='column' justify='end' align='end'>
					<DetailsButton color='secondary' onClick={() => navigate(routes.teacher.reports.untestedStudents)}>
						<Text size='medium'>Details</Text>
					</DetailsButton>
				</FlexBox>
			</Content>
		</Container>
	</CardBox>;
}