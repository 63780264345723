import {BaseComponentProps, ProgressBar} from '@esgi/ui';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {PropsWithChildren, useMemo} from 'react';

type Props = BaseComponentProps &
	PropsWithChildren & {
		progressBarTitle?: string;
		progressBarSize?: number;
		progressBarCircleStrokeWidth?: number;
	} & (
		| {
				value: number;
				maxValue: number;
				percentageType: false;
		  }
		| {
				/**
				 * In range [0, 100]
				 */
				value: number;
				percentageType: true;
		  }
	);

export function ProgressWithPointer({
	dataCy = 'test-dashboard-progress-with-pointer',
	css = {},
	progressBarSize = 60,
	progressBarTitle,
	progressBarCircleStrokeWidth = 8,
	children,
	...props
}: Props) {
	const {maxValue, percentageType, value} = useMemo(() => {
		if (props.percentageType) {
			const {value, percentageType} = props;

			return {
				maxValue: 100,
				value,
				percentageType,
			};
		}

		if (props.percentageType === false) {
			const {value, percentageType, maxValue} = props;

			return {
				maxValue,
				value,
				percentageType,
			};
		}

		throw new Error('prop percentageType is not a boolean');
	}, [props]);

	const {valueTranscript, decimalValue} = useMemo(() => {
		if (percentageType) {
			return {
				valueTranscript: `${Math.round(value)}%`,
				decimalValue: null,
			};
		}

		const [valueTranscript, decimalValue] = value.toFixed(1).split('.');

		return {
			valueTranscript,
			decimalValue: decimalValue === '0' ? null : decimalValue,
		};
	}, [value, percentageType]);

	const isProgressSuccess = value / maxValue > 0.5;

	return (
		<GridBox dataCy={dataCy} css={css} gap={4} display='inlineGrid'>
			<GridBox gap='3' flow='column'>
				<ProgressBar value={Math.max(0, value)} maxValue={maxValue}>
					<ProgressBar.RingWithPointer
						size={progressBarSize}
						pointerFillColor={isProgressSuccess ? 'green' : 'neutral40'}
						progressValueColor={isProgressSuccess ? 'positiveVivid' : 'lowContrast'}
						circleStrokeWidth={progressBarCircleStrokeWidth}
					>
						<FlexBox align={decimalValue ? 'baseline' : 'center'}>
							<Text size='medium' bold font='mono' color={isProgressSuccess ? 'green' : 'base'}>
								{valueTranscript}
							</Text>
							{decimalValue && (
								<Text size='xSmall' bold font='mono' color={isProgressSuccess ? 'green' : 'base'}>
									{'.'}
									{decimalValue}
								</Text>
							)}
						</FlexBox>
					</ProgressBar.RingWithPointer>
				</ProgressBar>
				{children}
			</GridBox>

			{progressBarTitle && (
				<Text size='small' font='mono' color='neutral56' data-cy='progress-bar-label'>
					{progressBarTitle}
				</Text>
			)}
		</GridBox>
	);
}
