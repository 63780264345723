import React, {createRef} from 'react';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import styles from './reset-levels-modal.module.less';

interface Props {
	onConfirm: () => void;
	onCancel: () => void;
}

export default class ResetLevelsModal extends React.PureComponent<Props> {
	private modalManagerRef: ModalManagerRef = createRef();

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader>
				<Title className={styles.modalTitle}>Reset Levels?</Title>
			</ModalHeader>
			<ModalBody className={styles.modalBody}>
				<div className={styles.body}>
					Changing the Level Type will reset the rubric levels and remove any levels you have added.
				</div>
			</ModalBody>
			<ModalFooter>
				<LinkButton onClick={() => this.modalManagerRef.current.close(this.props.onCancel)}>CANCEL</LinkButton>
				<LinkButton onClick={() => this.modalManagerRef.current.close(this.props.onConfirm)}>CONTINUE</LinkButton>
			</ModalFooter>
		</Modal>;
	}
}
