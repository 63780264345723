import {PropsWithChildren} from 'react';
import {ReportFiltersContentStyled} from './index.styled';

type FiltersContentBlockProps = PropsWithChildren<{
	className?: string | undefined;
}>;

export function ReportFiltersContent({children}: FiltersContentBlockProps) {
	return <ReportFiltersContentStyled>{children}</ReportFiltersContentStyled>;
}
