import {ExpandablePanelContent, ExpandablePanelStyled, SectionBox} from './index.styled';
import {PropsWithChildren} from 'react';

type Props = PropsWithChildren<{
	loaded: boolean;
	dataCy?: string
}>;

export function ExpandableSubjectPanel({
	loaded,
	children,
	dataCy = 'expandable-panel',
}: Props) {
	return (
	<SectionBox dataCy={`${dataCy}-section-box`}>
		<ExpandablePanelStyled skeleton={!loaded} open={true}>
			<ExpandablePanelContent dataCy={`${dataCy}-content`}>{children}</ExpandablePanelContent>
		</ExpandablePanelStyled>
	</SectionBox>
	);
}
