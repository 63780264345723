import {Dispatch, useCallback, useMemo} from 'react';
import {SelectField, SelectOption} from './index.styled';
import {Option} from './types';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectName} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';

interface Props {
	subjects: SubjectTab[];
	value: SubjectTab['id'];
	onChange: Dispatch<SubjectTab['id']>;
}

export function SubjectTabControl({subjects, onChange, value}: Props) {
	const handleValueChanged = useCallback(
		(value: string) => {
			onChange(Number(value));
		},
		[onChange],
	);

	const selectItems = useMemo<Option[]>(
		() => [
			{
				value: -1,
				label: 'All',
				level: null,
			},
			...subjects.map<Option>(({id, name, level}) => ({
				value: id,
				label: name,
				level,
			})),
		],
		[subjects],
	);

	return (
		<Select.Root dataCy='subject-select' value={String(value)} onValueChange={handleValueChanged}>
			<SelectField placeholder='Subject Tab' />
			<Select.Content>
				{selectItems.map(({value, label, level}) => (
					<SelectOption key={value} value={String(value)}>
						<SubjectName subjectLevel={level ?? undefined}>{label}</SubjectName>
						<Text size='medium' bold data-placeholder-value=''>
							{label}
						</Text>
					</SelectOption>
				))}
			</Select.Content>
		</Select.Root>
	);
}
