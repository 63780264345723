import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Primary} from '@esgi/deprecated/elements/buttons';
import {join} from '@esgillc/ui-kit/utils';
import {BingoService} from 'shared/modules/bingo/service/service';

import styles from './footer.module.less';

interface FooterProps {
	service: BingoService;
	step: number;
	prevStep: () => void;
	nextStep: () => void;
	setStep: (step: number) => void
	getValidationTitle?: () => string;
	validateStep: boolean | number;
	stepsCount?: number;
	createGame?: () => void;
	isDownloadDisabled?: boolean;
	downloadGame: () => void;
}


export default function Footer ({
	step,
	prevStep,
	nextStep,
	setStep,
	service,
	validateStep,
	isDownloadDisabled,
	downloadGame,
}: FooterProps) {

	const getValidationTitle = () => {
		if (!service.validateStep(step)) {
			if (step === 1) {
				return 'Please select at least one student.';
			}
		}
	};

	return (
		<div className={styles.container}>
			{step < 5 ? (
				<div className={styles.stepSlider}>
					<div className={styles.icon}>
						<i onClick={prevStep} className='glyphicon glyphicon-chevron-left'/>
					</div>
					<div className={styles.counter}>{step + '/4'}</div>
					<div className={styles.icon} data-cy={'chevron-right'}>
						{step < 4 &&
							<OnHoverTooltip message={getValidationTitle()}>
								<i
									onClick={nextStep}
									className={join(
										'glyphicon glyphicon-chevron-right',
										validateStep
											? styles.arrowIcon
											: styles.disabled,
									)}
								/>
							</OnHoverTooltip>}
					</div>
				</div>
			) : (
				<div className={styles.previewQuestionsFooter}>
					<div className={styles.backButton} onClick={prevStep}>
						<div className={styles.arrowIcon}>
							<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12'
							     fill='none'>
								<path d='M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z' fill='#0088CC'/>
							</svg>
						</div>
						<div className={styles.backLabel}>Back</div>
					</div>
					<Primary
						onClick={() => {
							setStep(6);
							downloadGame();
						}}
						title='DOWNLOAD GAME'
						disabled={isDownloadDisabled}
					/>
				</div>
			)}
		</div>
	);
}
