import {ChangeFunction} from '@esgi/main/kits/activities';
import {Student, SubjectTab} from '@esgi/main/libs/store';
import {StudentAvatar} from '../student-avatar';
import {StudentCounter} from '../student-counter';
import {SubjectControl} from './components/subject-control';
import {Divider, Header as HeaderBox} from './index.styled';

interface Props {
	studentCounter?: number;
	student: Student;
	subjects: SubjectTab[];
	selectedSubject: SubjectTab;
	disabled?: boolean;
	onChangeSubject: ChangeFunction<string>;
	skeleton?: boolean
	withCounter?: boolean
}

export function Header(props: Props) {
	const {subjects, selectedSubject, onChangeSubject, student, studentCounter, withCounter, skeleton} = props;

	const renderStudent = () => {

		if(withCounter){
			return <StudentCounter count={studentCounter} skeleton={skeleton}/>;
		}

		if (student) {
			const {firstName, lastName, photoUrl} = student;
			return <StudentAvatar
				firstName={firstName}
				lastName={lastName}
				photoUrl={photoUrl}
				skeleton={skeleton}
			/>;
		}
	};

	return (
		<HeaderBox flow='column' gap='4' align='center'>
			{renderStudent()}
			<Divider/>
			<SubjectControl subjects={subjects} value={selectedSubject?.id.toString()} onChange={onChangeSubject} skeleton={skeleton}/>
		</HeaderBox>
	);
}
