import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {
	FormState,
	IGradeRange,
	IGradeScaleEntry,
	ISubject,
	ITrack,
} from 'shared/modules/grade-scale/models';
import {Item} from '@esgi/deprecated/elements/custom-dropdown/dropdown';
import {IGradeRangeSetView, IGradeRangeView} from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/models';
import {Api} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/api';
import {Loader} from '@esgi/deprecated/jquery';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {
	ISharedGradeRangeSet,
	ITest,
	ITestUpdateModel,
} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class SharedGradeRangeService extends BaseService {
	public initialized: boolean;
	public setUpGradeScaleMode: boolean;
	private loader: Loader;
	private defaultVisibleSetsCount: number = 8;
	private initialStep: WizardStep;
	private initialTestsState: ITest[] = [];
	private testsLoading: boolean;
	private selectTestAction: () => void;

	private readonly track: BehaviorSubject<ITrack> = new BehaviorSubject<ITrack>(null);
	private readonly subjects: BehaviorSubject<ISubject[]> = new BehaviorSubject<ISubject[]>([]);
	private readonly tests: BehaviorSubject<ITest[]> = new BehaviorSubject<ITest[]>([]);

	private readonly selectedTestID: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	private readonly selectedSubjectID: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	private readonly selectedGradeRangeSet: BehaviorSubject<ISharedGradeRangeSet> = new BehaviorSubject<ISharedGradeRangeSet>(null);

	private readonly formState: BehaviorSubject<FormState> = new BehaviorSubject<FormState>(FormState.None);
	private readonly selectedGradeRangesViewAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly sharedGradeRangeSetsViewAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly showConfirmationMessage: Subject<boolean> = new Subject<boolean>();

	constructor(setUpGradeScaleMode: boolean, private hierarchy: HierarchySnapshot) {
		super();
		this.setUpGradeScaleMode = setUpGradeScaleMode;
		this.loader = new Loader($('body'));

		this.completeOnDestroy(combineLatest(this.tests, this.selectedSubjectID)).subscribe(data => {
			const tests = data[0];
			const selectedSubjectID = data[1];

			if (this.testsLoading || !selectedSubjectID) {
				return;
			}

			const subject = this.subjects.value.filter(s => s.id === selectedSubjectID)[0];

			if (!tests.some(t => t.subjectID === selectedSubjectID)) {
				this.loader.mask();
				this.testsLoading = true;

				Api.getTests(subject.id, subject.subjectType, subject.subjectLevel, hierarchy).subscribe(responseTests => {
					this.loader.unmask();
					this.testsLoading = false;

					this.initialTestsState = JSON.parse(JSON.stringify(this.initialTestsState.concat(responseTests)));
					this.tests.next(responseTests.concat(tests));

					if (!this.selectedTestID.value){
            this.selectTestAction();
          }
				});
			} else {
        if (!this.selectedTestID.value) {
          this.selectTestAction();
        }
			}
		});

		this.completeOnDestroy(combineLatest(this.formState, this.toSaveTests$)).subscribe(data => {
			const formState = data[0];
			const toSaveTests = data[1];

			if (formState === FormState.Saving) {
				const hasOtherScales = this.initialStep !== WizardStep.SharedGradeRange;

				if (hasOtherScales && toSaveTests.length === 0) {
					this.showConfirmationMessage.next(true);
					this.setFormState(FormState.None);
				} else if (toSaveTests.length > 0) {
					this.setFormState(FormState.Valid);
				} else {
					this.setFormState(FormState.Saved);
				}
			}
		});
	}

	public init(gradeScaleID: number, initialStep: WizardStep, subjectID: number, testID: number) {
		if (this.initialized) {
			this.selectSubject(subjectID, () => this.selectSpecificTest(testID));
		} else {
			this.initialStep = initialStep;

			Api.init(gradeScaleID, this.hierarchy).subscribe(response => {
				this.subjects.next(response.subjects);
				this.track.next(response.track);

				if (response.subjects.every(s => s.id !== subjectID)) {
					subjectID = response.subjects[0].id;
				}

				this.selectSubject(subjectID, () => this.selectSpecificTest(testID));

				this.initialized = true;
			});
		}
	}

	private selectFirstTest() {
		const subjectTests = this.initialTestsState.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		this.selectTest(subjectTests[0].id);
	}

	private selectSpecificTest(testID?: number) {
		this.selectTest(testID);
	}

	private selectLastTest() {
		const testsInNewSubject = this.tests.value.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		this.selectTest(testsInNewSubject[testsInNewSubject.length - 1].id);
	}

	private tests$ = this.completeOnDestroy(combineLatest([this.tests, this.selectedSubjectID]).pipe(map(data => {
		const tests = data[0];
		const selectedSubjectID = data[1];

		if (!selectedSubjectID) {
			return [];
		}

		return tests.filter(t => t.subjectID === selectedSubjectID);
	})));

	public selectedTest$ = this.completeOnDestroy(combineLatest([this.selectedTestID, this.tests$]).pipe(map(data => {
		const selectedTestID = data[0];
		const tests = data[1];

		if (!selectedTestID) {
			return null;
		}

		const test = tests.filter(t => t.id === selectedTestID);

		if (test.length > 0) {
			return test[0];
		}

		return null;
	})));

	public toSaveTests$ = this.completeOnDestroy(this.tests$).pipe(map(tests => {
		if (tests.length === 0 || this.initialTestsState.length === 0) {
			return [];
		}

		let changedTests = [];
		tests.forEach(t => {
			const initialTest = this.initialTestsState.filter(s => s.id === t.id)[0];
			if (t.applyDefaultRange) {
				changedTests.push(t);
				return;
			} else {
				if (initialTest.selectedGradeRangeSetID !== t.selectedGradeRangeSetID) {
					changedTests.push(t);
					return;
				}
			}
		});

		return changedTests.map(t => {
			return {
				gradeRangeSetId: t.selectedGradeRangeSetID,
				gradeScaleId: t.gradeScale.id,
				testID: t.id,
				applyDefaultRange: t.applyDefaultRange,
				defaultRangeApplied: t.defaultRangeApplied,
			} as ITestUpdateModel;
		});
	}));

	public selectedGradeRangeSet$ = this.completeOnDestroy(combineLatest([this.selectedTest$, this.selectedGradeRangeSet]).pipe(map(data => {
		const selectedTest = data[0];
		const selectedGradeRangeSet = data[1];

		if (!selectedTest) {
			return null;
		}

		if (selectedGradeRangeSet) {
			if (selectedTest.gradeRangeSets.some(grs => grs.id === selectedGradeRangeSet.id)) {
				return this.mapToSetView(selectedGradeRangeSet, selectedTest);
			}
		}

		if (selectedTest.gradeRangeSets.length === 0) {
			return null;
		}

		if (selectedTest.selectedGradeRangeSetID) {
			const selectedSet = selectedTest.gradeRangeSets.filter(grs => grs.id === selectedTest.selectedGradeRangeSetID)[0];
			return this.mapToSetView(selectedSet, selectedTest);
		}

		return this.mapToSetView(selectedTest.gradeRangeSets[0], selectedTest);
	})));

	public selectedGradeRanges$ = this.completeOnDestroy(combineLatest([this.selectedGradeRangeSet$, this.selectedGradeRangesViewAll])
		.pipe(map(data => {
			const selectedGradeRangeSet = data[0];
			const gradeRangesViewAll = data[1];

			if (!selectedGradeRangeSet) {
				return [];
			}

			if (!gradeRangesViewAll) {
				return selectedGradeRangeSet.gradeRanges.filter(gr => gr.markingPeriod === 1);
			}

			return selectedGradeRangeSet.gradeRanges;
		})));

	public gradeRangeSetsView$ = this.completeOnDestroy(combineLatest([this.sharedGradeRangeSetsViewAll, this.selectedTest$]).pipe(map(data => {
		const viewAll = data[0];
		const selectedTest = data[1];

		if (!selectedTest) {
			return [];
		}

		return selectedTest.gradeRangeSets
			.slice(0, viewAll ? selectedTest.gradeRangeSets.length : this.defaultVisibleSetsCount)
			.map(g => this.mapToSetView(g, selectedTest));
	})));

	public dropdownTests$ = this.completeOnDestroy(this.tests$).pipe(map(tests => {
		return tests.map(s => this.mapTestToDropdownItem(s));
	}));

	public rangeSelected$ = this.completeOnDestroy(combineLatest([this.selectedTest$, this.selectedGradeRangeSet$]).pipe(map(data => {
		const selectedTest = data[0];
		const selectedGradeRangeSet = data[1];

		if (!selectedTest || !selectedGradeRangeSet) {
			return;
		}

		return selectedTest.selectedGradeRangeSetID === selectedGradeRangeSet.id;
	})));

	public subjectsView$ = this.completeOnDestroy(this.subjects).pipe(map(subject => {
		return subject.map(s => this.mapSubjectToDropdownItem(s));
	}));

	public selectedSubject$ = this.completeOnDestroy(combineLatest(this.selectedSubjectID, this.subjects).pipe(map(data => {
		const selectedSubjectID = data[0];
		const subjects = data[1];

		const subject = subjects.filter(s => s.id === selectedSubjectID)[0]

		if (subject){
			return this.mapSubjectToDropdownItem(subject);
		}

		return null;
	})));

	public track$: Observable<ITrack> = this.completeOnDestroy(this.track);

	public selectDropdownSubject(subjectID: number) {
		this.selectTestAction = this.selectFirstTest;
		this.selectedSubjectID.next(subjectID);
	}

	private selectSubject(subjectID: number, action?: () => void) {
		this.selectTestAction = action;
		this.selectedSubjectID.next(subjectID);
	}

	public selectTest(testID: number) {
		this.selectedTestID.next(testID);
	}

	public selectGradeRangeSet(setID: number) {
		const test = this.tests.value.filter(t => t.id === this.selectedTestID.value && t.subjectID === this.selectedSubjectID.value)[0];
		const gradeRangeSet = test.gradeRangeSets.filter(grs => grs.id === setID)[0];
		this.selectedGradeRangeSet.next({...gradeRangeSet});
	}

	public applyDefaultRange(apply: boolean) {
		const test = this.tests.value.filter(t => t.id === this.selectedTestID.value && t.subjectID === this.selectedSubjectID.value)[0];
		test.applyDefaultRange = apply;
		this.updateTest(test);
	}

	public clickChooseThisRange(choose: boolean, setID: number) {
		const test = this.tests.value.filter(t => t.id === this.selectedTestID.value && t.subjectID === this.selectedSubjectID.value)[0];
		test.selectedGradeRangeSetID = choose ? setID : undefined;
		this.updateTest(test);
	}

	public nextTest() {
		const selectedTest = this.tests.value.filter(grs => grs.id === this.selectedTestID.value)[0];
		const testsInSubject = this.tests.value.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		const selectedTestNumber = testsInSubject.findIndex(t => t.id === selectedTest.id);
		if (selectedTestNumber + 1 === testsInSubject.length) {
			const selectedSubjectNumber = this.subjects.value.findIndex(t => t.id === this.selectedSubjectID.value);
			if (selectedSubjectNumber + 1 < this.subjects.value.length) {
				this.selectSubject(this.subjects.value[selectedSubjectNumber + 1].id, this.selectFirstTest);
			}
		} else {
			this.selectTest(testsInSubject[selectedTestNumber + 1].id);
		}
	}

	public prevTest() {
		const selectedTest = this.tests.value.filter(grs => grs.id === this.selectedTestID.value)[0];
		const testsInSubject = this.tests.value.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		const selectedTestNumber = testsInSubject.findIndex(t => t.id === selectedTest.id);
		if (selectedTestNumber === 0) {
			const selectedSubjectNumber = this.subjects.value.findIndex(t => t.id === this.selectedSubjectID.value);
			if (selectedSubjectNumber > 0) {
				const newSubjectID = this.subjects.value[selectedSubjectNumber - 1].id;
				this.selectSubject(newSubjectID, this.selectLastTest);
			}
		} else {
			this.selectTest(testsInSubject[selectedTestNumber - 1].id);
		}
	}

	public setSharedGradeRangeSetsViewAll() {
		this.sharedGradeRangeSetsViewAll.next(!this.sharedGradeRangeSetsViewAll.value);
	}

	public setSelectedGradeRangesViewAll() {
		this.selectedGradeRangesViewAll.next(!this.selectedGradeRangesViewAll.value);
	}

	public sharedGradeRangeSetsViewAll$ = this.completeOnDestroy(this.sharedGradeRangeSetsViewAll);
	public selectedGradeRangesViewAll$ = this.completeOnDestroy(this.selectedGradeRangesViewAll);

	private mapToSetView(gradeRangeSet: ISharedGradeRangeSet, test: ITest): IGradeRangeSetView {
		return {
			id: gradeRangeSet.id,
			periodType: gradeRangeSet.periodType,
			readonly: test.readonly,
			ownerName: gradeRangeSet.ownerName,
			gradeRanges: gradeRangeSet.gradeRanges.map(gr => {
				return this.mapToView(gradeRangeSet, gr, test.gradeScale.entries.filter(gse => gse.id === gr.gradeScaleEntryID)[0]);
			}),
		} as IGradeRangeSetView;
	}

	private mapToView(gradeRangeSet: ISharedGradeRangeSet, rangeEntry: IGradeRange, scaleEntry: IGradeScaleEntry): IGradeRangeView {
		return {
			id: rangeEntry.id,
			color: scaleEntry.color,
			name: scaleEntry.gradeName,
			to: rangeEntry.to,
			from: rangeEntry.from,
			gradeScaleEntryID: rangeEntry.gradeScaleEntryID,
			markingPeriod: rangeEntry.markingPeriod,
			type: gradeRangeSet.type,
		} as IGradeRangeView;
	}

	private mapTestToDropdownItem(test: ITest) {
		return {
			key: test.id,
			title: test.name,
			value: test.id,
		} as Item;
	}

	private mapSubjectToDropdownItem(subject: ISubject) {
		return {
			key: subject.id,
			title: subject.name,
			value: subject.id,
		} as Item;
	}

	private updateTest(test: ITest) {
		const newTests = this.tests.value.map(t => {
			if (t.id === test.id) {
				return test;
			} else {
				return {...t};
			}
		});

		this.tests.next(newTests);
	}

	public save() {
		if (this.formState.value === FormState.None) {
			this.setFormState(FormState.Saving);
		}
	}

	public setFormState(formState: FormState) {
		this.formState.next(formState);
	}

	public formState$: Observable<FormState> = this.completeOnDestroy(this.formState);

	public get showConfirmationMessage$(): Observable<boolean> {
		return this.completeOnDestroy(this.showConfirmationMessage);
	}
}
