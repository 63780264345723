import {UserType} from '@esgi/core/authentication';
import {environment} from '@esgi/core/environments';
import {ServerClient} from 'pages/landing/kit/server-client';
import {PurchaseFormModel} from '../kit/purchase-form';
import {ReceiptUtil} from '../kit/stripe';
import {ExpirationProductModel, StudentCartModel} from './models';
import {Renewal} from './renewal';
import {OldAlerts} from '../../../../../../libs/deprecated/src/knockout';

export class InitResponse {
	public userID: number;
	public rateScheduleID?: number;
	public userName: string;
	public userType: UserType;
	public email: string;
	public students: StudentsModel;
	public products: ServiceModel[];

	public esgiExpireDaysThresholdApplyDiscount: number;
	public studentYearPrice: number;

	public allowUseAc: boolean;
	public allowUseCc: boolean;
	public allowUsePc: boolean;
	public renewForbindanceReason: string;
	public promoCode: string;
}

export class StudentsModel {
	public limit: number;
	public defaultLimit: number;
	public ratePerYear: number;
}

export class ServiceModel {
	public name: 'ESGI';
	public ratePerYear: number;
	public expirationDate: Date;
}

export class RenewRequest {
	public userID: number;
	public renewalType: RenewalType;
	public products: ProductModel[];
	public students: StudentsMaxModel;
	public activationCode: ActivationCodeRequest;
	public creditCard: CreditCardModel;
	public stripePaymentMethodId: string;
}

export class StudentsMaxModel {
	public currentMaxStudents: number;
	public newMaxStudents: number;
}

export interface ActivationCodeRequest {
	code: string;
	moveIsForbidden: boolean;
	schoolID: number;
	districtID: number;
	trackID: number;
	moveStudents: boolean;
	movePrevStudents: boolean;
	schoolType: 'NonFederal' | 'Federal';
	currentDistrictID: number;
	agreement: string;
}

export class CreditCardModel {
	public cvc: string;
	public cardNumber: string;
	public expirationDate: string;
	public nameOnCard: string;
}

export class ProductModel {
	public product: Product;
	public newExpirationDate: string;
	public isSelected: boolean;
}

export class RenewResponce {
	public error: string;
	public joinToDistrictID: number;
	public amount: number;
	public transactionID: string;
	public paymentID: number;
	public paymentInfoID: number;
	public payments: ProductPaymentModel[];
	public token: string;
	public acExpirationDate?: string;
	public newMaxStudentsLimit: number;
}

export interface OrderInfo {
	orderId: number;
	price: number;
	products: ProductModel[];
	students: StudentsMaxModel;
}

export interface PayPalInitPaymentResponce {
	orderInfo: OrderInfo;
	clientId: string;
}

export interface PayPalCaptureTransactionResponce {
	succeeded: boolean;
	renewalModel: RenewResponce;
}

export class PayPalCancelPaymentRequest {
	public payPalTransactionID: string;
	public userID: number;
}

export class PayPalPaymentStatusRequest {
	public payPalTransactionID: string;
	public userID: number;
}


export class ProductPaymentModel {
	public title: 'Students' | 'ESGI';
	public quantity: number;
	public durationMonths: number;
	public price: number;
	public newExpirationDate: Date;
}

export enum RenewalType {
	None = 0,
	ActivationCode = 1,
	CreditCard = 2,
	PayPal = 3
}

export enum Product {
	ESGI = 1
}

export enum ProductName {
	ESGI = 'ESGI'
}

declare type RenewalProduct = Product | ProductName | 'ESGI';

export function isESGI(value: RenewalProduct): boolean {
	if (value == ProductName.ESGI || value == Product.ESGI) {
		return true;
	}

	return false;
}

export function isSameProduct(value1: RenewalProduct, value2: RenewalProduct): boolean {
	let product1Type = isESGI(value1);
	let product2Type = isESGI(value2);

	return product1Type == product2Type;
}


export class CalculateRenewalCartPriceRequest {
	public userID: number;
	public products: ProductModel[];
	public students: StudentsMaxModel;
}

export class CalculateRenewalCartPriceResponce {
	public products: ProductPriceModel[];
	public students: StudentsPriceModel[];
	public total: number;
}

export class ProductPriceModel {
	public product: Product;
	public price: number;
	public oneYearPrice: number;
	public months: number;
}

export class StudentsPriceModel {
	public payingMonths: number;
	public price: number;
	public quantity: number;
}

export class IsAllowedToRenewValidationResponse {
	public isAllowedToRenew: boolean;
	public reason: string;
}

export class DownloadRenewRequest {
	public paymentID: number;
}

export class ValidatePromoCodeResponse {
	public valid: boolean;
	public errorMessage: string;
	public IsActivationCode: boolean;
}

export interface ChangePromoCodeResponse {
	success: boolean;
}

export interface ChangePromoCodeRequest {
	promoCode: string;
}

export class AddStudentsRequest {
	userId: number;
	newStudentsMaxLimit: number;
}

export class RenewalApi {
	api = {
		init: () => {
			return ServerClient.SSOAPI.Get<InitResponse>('Renewal', 'Init');
		},
		renew: (model: RenewRequest) => {
			return ServerClient.SSOAPI.Post<RenewResponce>('Renewal', 'Renew', {data: model})
				.fail( (error) => {
					OldAlerts.bsalert(error.Message);
				});
		},
		addStudents: (model: AddStudentsRequest) => {
			return ServerClient.SSOAPI.Post('Renewal', 'AddStudents', {data: model});
		},
		renewalCartPrice: (model: CalculateRenewalCartPriceRequest) => {
			return ServerClient.SSOAPI.Get<CalculateRenewalCartPriceResponce>('Renewal', 'RenewalCartPrice', {data: model});
		},
		renewAllowedValidation: () => {
			return ServerClient.SSOAPI.Get<IsAllowedToRenewValidationResponse>('Renewal', 'IsAllowedToRenew');
		},
		renewCheckPromoCodeValidation: (promoCode: string) => {
			return ServerClient.SSOAPI.Get<ValidatePromoCodeResponse>('Renewal', 'ValidatePromoCode', {data: {promoCode: promoCode}});
		},
		changePromoCode: (model: ChangePromoCodeRequest) => {
			return ServerClient.SSOAPI.Post<ChangePromoCodeResponse>('Renewal', 'ChangePromoCode', {data: model})
				.fail((error) => {
					OldAlerts.bsalert(error.Message);
				});
		},
		downloadRenewPdf: (model: DownloadRenewRequest) => {
			this.downloadFile(`${environment.ssoApiURL}/Renewal/GetRenewPdf?paymentID=` + model.paymentID, 'pdf', 'application/pdf');
		},

		paypal: {
			initPayment: (model: RenewRequest) => {
				return ServerClient.SSOAPI.Post<PayPalInitPaymentResponce>('Renewal/PayPal', 'InitPayment', {data: model})
					.fail((error) => {
						OldAlerts.bsalert(error.Message);
					});
			},
		},
	};

	private downloadFile = (url: string, extension: string, mimeType: string) => {
		let xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'arraybuffer';
		xhr.withCredentials = true;
		xhr.onload = (e: any) => {
			if (e.target.status == 200) {
				let blob = new Blob([e.target.response], {type: mimeType});
				let filenameProvider = new ReceiptUtil();
				let filename = filenameProvider.getReceiptFileName(extension);
				let link = document.createElement('a');
				link.setAttribute('type', 'hidden');
				link.href = window.URL.createObjectURL(blob);
				link.download = filename;
				document.body.appendChild(link);
				link.click();
				link.remove();
			} else {
				OldAlerts.bsalert('Uh oh. Something went wrong on our end. Please contact Customer Support (support@esgisoftware.com) for assistance.');
			}

		};
		xhr.send();
	};
}

export class RenewRequestBuilder {
	public static GetCalculateRenewalCartPriceRequest(renewComponent: Renewal): CalculateRenewalCartPriceRequest {
		let builder = new RenewRequestBuilder();

		let request = new CalculateRenewalCartPriceRequest();
		request.userID = renewComponent.userID;
		request.products = builder.buildProducts(renewComponent.cart.expiration.products);
		request.students = builder.buildStudents(renewComponent.cart.student);

		return request;
	}

	public static GetRenewRequest(renewComponent: Renewal, model: PurchaseFormModel, stripePaymentMethodId: string): RenewRequest {
		let builder = new RenewRequestBuilder();

		let request = new RenewRequest();
		request.userID = renewComponent.userID;
		request.renewalType = renewComponent.renewalType;
		request.products = builder.buildProducts(renewComponent.cart.expiration.products);
		request.students = builder.buildStudents(renewComponent.cart.student);

		if (renewComponent.renewalType == RenewalType.CreditCard) {
			request.creditCard = builder.buildCC(model);
			request.stripePaymentMethodId = stripePaymentMethodId;
		} else if (renewComponent.renewalType == RenewalType.ActivationCode) {
			request.activationCode = renewComponent.cart.activationCode;
		}

		return request;
	}

	public static GetAddStudentsRequest(newStudentsLimit: number): AddStudentsRequest{
		const request = new AddStudentsRequest();
		request.userId = 0;
		request.newStudentsMaxLimit = newStudentsLimit;
		return request;
	}

	public buildProducts(model: ExpirationProductModel[]) {

		let products = new Array<ProductModel>();

		for (let product of model) {

			let p = new ProductModel();

			p.product = Product.ESGI;
			p.newExpirationDate = product.newExpirationDate().format('YYYY-MM-DD[T]23:59:00'); //.format() ignores timezone
			p.isSelected = product.selected();

			products.push(p);
		}

		return products;
	}

	public buildCC(model: PurchaseFormModel) {
		let creditCard = new CreditCardModel();

		if (model) {
			creditCard.cvc = model.cvc;
			creditCard.cardNumber = model.cardNumber;
			creditCard.nameOnCard = model.nameOnCard;
			creditCard.expirationDate = model.creditCardExpirationDate;
		}

		return creditCard;
	}

	public buildStudents(model: StudentCartModel) {
		let studs = new StudentsMaxModel();
		studs.currentMaxStudents = model.currentStudentLimit;
		studs.newMaxStudents = model.newStudentLimit;
		return studs;
	}
}
