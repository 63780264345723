import {Button} from '@esgi/ui';
import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardRoot = styled(Card, {
	paddingTop: 6,
	paddingBottom: 6,

	'&:not(:last-child)': {
		marginBottom: 12,
	},
});

export const RearrangeButton = styled(Button.Icon, {
	width: 32,
	height: 32,
	marginLeft: 'auto',
	cursor: 'grab',
});
