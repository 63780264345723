import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type ExpandablePanelContextValue = {
	/** Use for expandable panel. When true, expandable panel is open. */
	open: boolean;

	/** Set true / false value for open / close expandable panel. */
	setOpen: (value: boolean) => void;

	/** Use for following hovered on expandable panel. */
	panelIsHovered: boolean;

	/** Set true / false value for set hover on expandable panel. */
	setPanelIsHovered: (value: boolean) => void;

	/** Use for following width expandable panel placeholder. */
	placeholderWidth: number;

	/** Set width of expandable panel placeholder width. */
	setPlaceholderWidth: (value: number) => void;
};

export const ExpandablePanelContext = createContext<ExpandablePanelContextValue>({
	open: false,
	setOpen: noop,
	panelIsHovered: false,
	setPanelIsHovered: noop,
	placeholderWidth: 0,
	setPlaceholderWidth: noop,
});

export function useExpandablePanelContext() {
	return useContext(ExpandablePanelContext);
}
