import {ExpandablePanelModeVariant} from '../../../../kit';
import {SelectableList} from '../lists/selectable-list';
import {SubjectTab} from '@esgi/main/libs/store';

type Props = {
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: (subjectId: SubjectTab['id']) => void;
	subjects: SubjectTab[];
};

export function DefaultModeContent({selectedSubjectId, setSelectedSubjectId, subjects}: Props) {
	return (
		<ExpandablePanelModeVariant withBottomSectionButton={false}>
			<SelectableList
				selectedSubjectId={selectedSubjectId}
				setSelectedSubjectId={setSelectedSubjectId}
				subjects={subjects}
			/>
		</ExpandablePanelModeVariant>
	);
}
