import {useKeyboardEffect} from '@esgillc/ui-kit/core';
import {AnswerState} from '@esgi/core/enums';

export function useKeyboardShortcuts(callback: (state: AnswerState) => void) {
	useKeyboardEffect('keydown', (event) => {
		if(event.target && 'id' in event.target && event.target.id === 'yes-no-notes') {
			return;
		}

		let normalizeWhich = event.which;
		if (normalizeWhich === undefined || normalizeWhich <= 0) {
			normalizeWhich = event.keyCode;
		}

		if (event.key === 'UIKeyInputRightArrow') {
			normalizeWhich = 39;
		}

		if (event.key === 'UIKeyInputLeftArrow') {
			normalizeWhich = 37;
		}

		switch (normalizeWhich) {
			case 37:
			case 89: {
				callback(AnswerState.Correct);
				break;
			}
			case 39:
			case 78:
				callback(AnswerState.Incorrect);
				break;
		}
	});
}
