import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {renderReactComponent} from '@esgillc/ui-kit/utils';
import {LoginResponse} from '../types';
import {ValidationAlertType} from './types';
import {LinkToExpiredPrimaryAccount} from './custom-alerts-body/link-to-expired-primary-account';
import {NotSupportedUserRole} from './custom-alerts-body/not-supported-user-role';
import {SecondaryAccountIsInactive} from './custom-alerts-body/secondary-account-is-inactive';
import {AlertMessages} from './alert-messages';
import {AccountIsMovedToCanada} from './custom-alerts-body/account-is-moved-to-canada';

type AlertProps = {
	onClose: () => void;
	alertType: keyof typeof ValidationAlertType;
	additionalInfo: Record<string, string> | null;
};

function ValidatorAlert({onClose, alertType, additionalInfo}: AlertProps) {
	const manager = useModal();
	const close = useCloseModal(manager, onClose);

	const getAlertBody = () => {
		switch(ValidationAlertType[alertType]) {
			case ValidationAlertType.LinkedToExpiredPrimaryAccount:
				return <LinkToExpiredPrimaryAccount title={additionalInfo['title']}
																		 firstName={additionalInfo['firstName']}
																		 lastName={additionalInfo['lastName']}
																		 accountID={additionalInfo['accountID']}
																		 email={additionalInfo['email']}
																		 expDateString={additionalInfo['expDateString']} />
			case ValidationAlertType.NotSupportedUserRole:
				return <NotSupportedUserRole userType={additionalInfo['userType']} />
			case ValidationAlertType.SecondaryAccountIsInactive:
				return <SecondaryAccountIsInactive />
			case ValidationAlertType.AccountIsMovedToCanada:
				return <AccountIsMovedToCanada />;
			default:
				return <>{AlertMessages[ValidationAlertType[alertType]]}</>
		}
	}

	return <Alert modalManagerRef={manager}>
		<Alert.Body>
			<div>
				{getAlertBody()}
			</div>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={close}>OK</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}

export async function AlertHandler(value: LoginResponse): Promise<void> {
	if (value.validation?.alertType) {
		await renderReactComponent((resolve) => <ValidatorAlert onClose={resolve}
																																						alertType={value.validation.alertType}
																																						additionalInfo={value.validation.additionalInfo}/>);
	}
}
