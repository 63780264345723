import {useMemo} from 'react';

export type AnswersStatistics = {
	correctPercentage: number;
	incorrectPercentage: number;
	notTested: number;
	notTestedPercentage: number;
	correct: number;
	incorrect: number;
};

export function useItemStatistics({
	sessionsCount,
	correct,
	incorrect,
}: {
	sessionsCount: number;
	correct: number;
	incorrect: number;
}) {
	return useMemo<AnswersStatistics>(() => {
		const correctPercentage = Math.round((correct / sessionsCount) * 100);
		const incorrectPercentage = Math.round((incorrect / sessionsCount) * 100);

		const notTested = sessionsCount - correct - incorrect;
		const notTestedPercentage = 100 - correctPercentage - incorrectPercentage;

		return {
			correctPercentage,
			incorrectPercentage,
			notTested,
			notTestedPercentage,
			correct,
			incorrect,
		};
	}, [correct, incorrect, sessionsCount]);
}
