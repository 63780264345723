import {useAiAnalysisContext} from '../../layout/outlet-context';
import {Text} from '@esgi/ui/typography';
import {Container, ReportTypeContainer} from './index.styled';
import {ReportSelection} from '../report-selection-panel';

export function SetupPanel() {
	const {selectedReportType} = useAiAnalysisContext();
	return selectedReportType ? (
		<Container>
			<ReportTypeContainer>
				{selectedReportType.icon && (
					<selectedReportType.icon height={24} width={24} />
				)}
				<Text size='large' bold>
					{selectedReportType.label}
				</Text>
				<Text size='medium' color='neutral72'>
					{selectedReportType.content}
				</Text>
				<Text size='medium' color='neutral72'>
					Select at least one test to start generating.
				</Text>
			</ReportTypeContainer>
		</Container>
	) : (
		<ReportSelection />
	);
}
