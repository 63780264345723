import {useCallback} from 'react';
import {useQueryValue} from '@esgi/ui';
import {QuestionBankDrawer, useQuestionBankListener} from '@esgi/main/features/question-bank';
import {SettingsDrawer} from './settings-panel';
import {UserAccountDrawer} from './user-account';
import {StudentScreenDrawer} from './studen-screen';
import {ScreenSize} from '@esgi/main/kits/common';

const drawerSearchKey = 'drawer';

type DrawerName =
	| 'user-account'
	| 'settings'
	| 'student-screen'
	| 'question-bank';

export function useOpenDrawer(): (drawerName: DrawerName) => void {
	const [, setDrawerName] = useQueryValue(drawerSearchKey);
	return setDrawerName;
}

export default function TeacherDrawers({screenSize}: {screenSize: ScreenSize}) {
	const [drawerName, setDrawerName] = useQueryValue<DrawerName>(drawerSearchKey);

	const closeDrawer = useCallback(() => {
		setDrawerName(undefined);
	}, [setDrawerName]);

	const handleOpenQuestionBank = useCallback(() => {
		setDrawerName('question-bank');
	}, [setDrawerName]);

	const {initialTest} = useQuestionBankListener({
		onOpen: handleOpenQuestionBank,
		onClose: closeDrawer,
	});

	return (
		<>
			{drawerName === 'user-account' && (
				<UserAccountDrawer onClose={closeDrawer} />
			)}
			{drawerName === 'student-screen' && (
				<StudentScreenDrawer onClose={closeDrawer} />
			)}
			{drawerName === 'settings' && (
				<SettingsDrawer onClose={closeDrawer} />
			)}
			{drawerName === 'question-bank' && (
				<QuestionBankDrawer test={initialTest} onClose={closeDrawer} screenSize={screenSize} />
			)}
		</>
	);
}
