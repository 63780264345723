import {useCallback, useEffect, useRef, useState} from 'react';
import {AsyncResponse, AsyncStatus, CancellableRequestReturnType} from './types';
import {useObservableCallbackRef} from '../use-observable-callback-ref';
import {Observable, Unsubscribable} from 'rxjs';

export function useCancelableRequest<F extends (...args: any[]) => Observable<any>>(
	request: F,
): readonly [AsyncResponse<CancellableRequestReturnType<F>>, (...args: Parameters<F>) => void] {
	const requestRef = useObservableCallbackRef(request);
	const subscription = useRef<Unsubscribable | null>(null);

	const [response, setResponse] = useState<AsyncResponse<CancellableRequestReturnType<F>>>({
		asyncStatus: AsyncStatus.Idle,
		value: null,
		errors: null,
	});

	const execute = useCallback(
		(...args: Parameters<F>) => {
			subscription.current?.unsubscribe();

			setResponse({
				asyncStatus: AsyncStatus.Pending,
				value: null,
				errors: null,
			});

			subscription.current = requestRef.current(...args).subscribe({
				next: (data) => {
					setResponse({
						asyncStatus: AsyncStatus.Succeeded,
						data,
					});
				},
				error: (error) => {
					setResponse({
						asyncStatus: AsyncStatus.Failed,
						data: undefined,
						error,
					});
				},
			});
		},
		[requestRef],
	);

	useEffect(() => () => subscription.current?.unsubscribe(), []);

	return [response, execute];
}
