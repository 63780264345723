import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const SVG = styled('svg', {
	width: 8,
	height: 8,
	fill: '$vivid',
	'&:hover': {
		'& circle:first-child': {
			fill: '$vivid',
		},
		'& circle:nth-child(2)': {
			fill: '$primary',
		},
	},
	variants: {
		hidden: {
			true: {
				opacity: 0,
				'&:hover': {
					opacity: 1,
				},
			},
		},
	},
});

export const Text = styled('text', {
	fontSize: 10,
	variants: {
		fill: {
			lowContrast: {
				fill: '$lowContrast',
			},
			blue: {
				fill: '$blue',
			},
		},
	},
});

export const Circle = styled('circle', {
	variants: {
		color: {
			primary: {
				fill: '$blue',
			},
			baseline: {
				fill: '$secondary',
			},
			negative: {
				fill: '$negative',
			},
			base: {
				fill: '$base',
			},
		},
		stroke: {
			primaryVivid: {
				stroke: '$primaryVivid',
			},
			secondary: {
				stroke: '$secondary',
			},
			negative: {
				stroke: '$negative',
			},
			base: {
				stroke: '$base',
			},
		},
	},
});


export const ChartContainer = styled(GridBox, {
	minHeight: 316,
	gap: '$4',
});

export const NotesContainer = styled(GridBox, {
	gap: '$5',
	gridTemplateColumns: 'auto 1fr',
	paddingLeft: 16,
	alignSelf: 'start',
});