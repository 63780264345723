import React, {ChangeEventHandler} from 'react';
import {
	FilterType,
	GradeLevelModel,
	SchoolModel,
	StudentModel,
	SubjectModel,
	TeacherModel,
	UnityModel,
} from 'shared/modules/reports/pie-chart/service/models';
import styles from '../../header.module.less';

export interface FilterProps {
	type: FilterType,
	label: string;
	entity: UnityModel[] | TeacherModel[] | SubjectModel[] | GradeLevelModel[] | SchoolModel[] | StudentModel[];
	entityID: number,
	hasAll?: boolean;
	onChange: ChangeEventHandler<HTMLSelectElement>;
}

export default function Filter ({type, label, entity, entityID, hasAll = true, onChange}: FilterProps) {
	return (
		<div data-cy='filter' className={styles.filter}>
			<span data-cy='label' className={styles.bold}>{label}: </span>
			{(type === FilterType.Student || entity.length > 1) &&
				<select
					className={styles.formControl}
					value={entityID}
					onChange={onChange}
					data-cy='dropdown'
				>
					{hasAll && <option key={0} value={0}>All</option>}
					{entity.map((c) =>
						<option key={c[`${type}ID`]} value={c[`${type}ID`]}>
							{type === FilterType.Student && `${c.firstName} ${c.lastName}` }
							{type === FilterType.Subject && c.subjectName}
							{c.name}
						</option>)
					}
				</select>
			}
			{entity.length === 1 && type !== FilterType.Student &&
				<span data-cy='name'>
					{entity[0][type === FilterType.Subject ? 'subjectName' : 'name']}
				</span>
			}
		</div>
	);
}
