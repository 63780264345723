import {ReportDisplayResultsMode} from '../../types';

export enum DisplayResultsMode {
	Score = 'Score',
	Percent = 'Percent',
	Grade = 'Grade',
}

export type ToggleItem = {
	value: ReportDisplayResultsMode;
	label: string;
};
