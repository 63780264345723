import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

interface Props {
	studentFullName: string;
	onCancel: () => void;
	onDelete: () => void;
	isLinked: boolean;
}

export function DeleteStudentAlert({studentFullName, onDelete, onCancel, isLinked}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	const onDeleteClicked = () => {
		onDelete();
		handleClose();
	};

	return <Alert data-cy='delete-student-alert' modalManagerRef={modalRef}>
		<Alert.Header>
			<Title>Delete Student?</Title>
		</Alert.Header>

		<Alert.Body className={styles.body}>
			<span data-cy='delete-student-message'>{studentFullName} {isLinked ? `student will be removed from District.` : `student will be removed from your account.`}</span>
		</Alert.Body>

		<Alert.Footer>
			<Buttons.Text onClick={handleClose}>Cancel</Buttons.Text>
			<Buttons.Text onClick={onDeleteClicked}>Delete</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
