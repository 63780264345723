import {Dispatch, useCallback, useEffect, useState} from 'react';
import {Section, Title, Label, ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot} from '../../index.styled';
import {ColorType} from './types';

interface Props {
	disabled: boolean;
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function ColorSelectorControl({disabled, onChange, value: externalValue}: Props) {
	const [value, setValue] = useState(ColorType.BlackAndWhite);

	useEffect(() => {
		setValue(externalValue ? ColorType.Colored : ColorType.BlackAndWhite);
	}, [externalValue]);

	const handleValueChanged = useCallback(
		(newValue: ColorType) => {
			setValue(newValue);
			onChange(newValue === ColorType.Colored);
		},
		[onChange],
	);

	return (
		<Section>
			<Title data-cy='title' size='small' font='mono' color='lowContrast'>
				Output
			</Title>
			<ToggleGroupRoot data-cy='output-settings-toggle-group' value={value} disabled={disabled}>
				<ToggleGroupContent>
					<ToggleGroupItem value={ColorType.Colored} onClick={() => handleValueChanged(ColorType.Colored)}>
						<Label size='medium' bold>
							Color
						</Label>
					</ToggleGroupItem>
					<ToggleGroupItem value={ColorType.BlackAndWhite} onClick={() => handleValueChanged(ColorType.BlackAndWhite)}>
						<Label size='medium' bold>
							Black & White
						</Label>
					</ToggleGroupItem>
				</ToggleGroupContent>
			</ToggleGroupRoot>
		</Section>
	);
}
