import React, {useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {Tooltip} from '@esgi/ui';
import {StyledText, WrappedText} from './index.styled';

type Props = { description: string, dataCy?: string }

export function StyledDescription({description, dataCy}: Props) {
	const textRef = useRef<HTMLElement>(null);
	const [isTextTruncated, setIsTextTruncated] = useState(false);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight, scrollTop}}) => {
		setIsTextTruncated(clientWidth < scrollWidth || (clientHeight + 1 < scrollHeight));
	});

	return (
		<Tooltip open={!isTextTruncated ? false : undefined}>
			<Tooltip.Trigger>
				<StyledText data-cy={dataCy} size='small' ref={textRef}>{description}</StyledText>
			</Tooltip.Trigger>
			<Tooltip.Content css={{width: textRef?.current?.clientWidth}} variant='default'>
				<WrappedText data-cy={`${dataCy}-tooltip`} size='small'>{description}</WrappedText>
			</Tooltip.Content>
		</Tooltip>
	);
}