import {Radio} from '@esgillc/ui-kit/control';
import {RadioButtonGroupProps} from './types';
import {ChangeEvent, useCallback, useState} from 'react';
import {Text} from '../text';
import styles from './styles.module.less';

export const RadioButtonGroup = <T extends string>({
	radioButtons,
	defaultIndex,
	onChange,
}: RadioButtonGroupProps<T>) => {
	const defaultValue = (radioButtons?.[defaultIndex] ?? radioButtons[0]).value;
	const [checkedValue, setCheckedValue] = useState(defaultValue);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value: T = event.target.value as T;

			setCheckedValue(value);
			onChange?.(value);
		},
		[onChange],
	);

	return (
		<div className={styles.radioButtonGroup}>
			{radioButtons.map(({label, value}) => (
				<Radio
					value={value}
					checked={checkedValue === value}
					key={value}
					onChange={handleChange}
				>
					<Text as='span' color='gray' size='14px' weight='400'>
						{label}
					</Text>
				</Radio>
			))}
		</div>
	);
};
