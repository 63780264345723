import moment from 'moment';
import React from 'react';
import {ConferencerItem, Language, Student} from 'pages/parent-conferencer/models/models';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {SearchableDropdown} from '@esgi/deprecated/elements/searchable-dropdown';
import {CustomDropdown, TextInput, Validators} from '@esgi/deprecated/elements';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';

export type InitialState = {
	data: ConferencerItem,
	dateID: number,
	timeID: number,
	time: string,
	defaultLengthMin: number,
	defaultLanguageID: number,
	defaultRoomName: string,
}

class State {
	itemID: number;
	studentID?: number;
	dateID: number;
	timeID: number;
	time: string;
	studentName: string;
	lengthMin: number;
	roomName: string;
	roomNameValid: boolean;
	languageID: number;
	defaultLanguageID: number;
	error: string;

	constructor(initialState: InitialState) {
		const {data, dateID, timeID, time, defaultLengthMin, defaultLanguageID, defaultRoomName} = initialState;
		this.itemID = data ? data.conferencerItemID : 0;
		this.dateID = data ? data.conferencerDateID : dateID;
		this.timeID = data ? data.conferencerTimeID : timeID;
		this.time = time;
		this.studentID = data ? data.studentID : 0;
		this.studentName = data ? data.studentName : '';
		this.lengthMin = data ? data.lengthMin : defaultLengthMin;
		this.roomName = data ? data.roomName : defaultRoomName;
		this.roomNameValid = true;
		this.languageID = data ? data.languageID : defaultLanguageID;
		this.defaultLanguageID = defaultLanguageID;
	}
}

class Props {
	initialState: InitialState;
	languages: Language[];
	students: Student[];
	close: () => void;
	process: (data: ConferencerItem) => string;
	printLetters: (type: number, studentID: number) => void;
}

export class EditItem extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = new State(props.initialState);
	}

	componentDidMount() {
		if (this.state.studentID == 0) {
			// Preselect first student from list if no student selected.
			const student = this.props.students[0];
			this.setState({
				studentID: student.studentID,
				studentName: student.firstName + ' ' + student.lastName,
			}, () => {
				this.setStudentLanguage(student.studentID);
			});
		}
	}

	setStudentLanguage = (stundentID: number) => {
		let student = this.props.students.filter(x => x.studentID == stundentID)[0];
		let languageID = this.state.defaultLanguageID;
		if (student) {
			let language = this.props.languages.filter(x => x.languageID == student.languageID)[0];
			if (language) {
				languageID = language.languageID;
			}
		}
		this.setState({languageID: languageID});
	}

	get lengthMins() {
		let mins = [5, 10, 15, 20, 25, 30, 40, 50, 60, 90, 120];
		return mins.map(x => {
			return {id: x, value: this.minsToString(x)};
		});
	}

	minsToString(value: number) {
		if (value < 60) {
			return value + ' min';
		} else {
			let hours = value / 60;
			let ending = hours >= 2 ? 's' : '';
			return hours + ' hour' + ending;
		}
	}

	get languages() {
		return this.props.languages.map(x => {
			return {id: x.languageID, value: x.name};
		});
	}

	get students() {
		return this.props.students.map((x, i) => {
			return {id: x.studentID, value: x.firstName + ' ' + x.lastName};
		});
	}

	confirm = () => {
		let data: ConferencerItem = {
			conferencerItemID: this.state.itemID,
			conferencerDateID: this.state.dateID,
			conferencerTimeID: this.state.timeID,
			studentID: this.state.studentID,
			studentName: this.state.studentName,
			lengthMin: this.state.lengthMin,
			roomName: this.state.roomName,
			languageID: this.state.languageID,
		};

		let result = this.props.process(data);
		if (result) {
			this.setState({error: result});
		} else {
			this.props.close();
		}
	}

	render() {
		let filteredStudent = this.state.studentID ? this.students.filter(x => x.id == this.state.studentID)[0] : this.students[0];
		let selectedStudent = filteredStudent ? filteredStudent : this.students[0];

		return <Modal className='edit_item_modal'>
			<ModalHeader>
			</ModalHeader>
			<ModalBody>
				<div className='edit_item'>
					{this.state.itemID > 0 &&
					<div className='downloads-dropdown-container'>
						<div className='btn-group downloads-dropdown nomargin'>
							<span data-toggle='dropdown'><i className='fa fa-download'/> Download</span>
							<ul className='dropdown-menu dropdown-default'>
								<li><a className='download-invintation-link' href='#' onClick={() => {
									this.props.printLetters(2, this.state.studentID);
								}}>Conference Invitation</a></li>
								<li><a className='download-reminder-link' href='#' onClick={() => {
									this.props.printLetters(3, this.state.studentID);
								}}>Conference Reminder</a></li>
							</ul>
						</div>
					</div>
					}
					<SearchableDropdown
						onClick={(value) => {
							const studentID = value.id;
							const studentName = value.value;
							this.setState({studentID: studentID, studentName: studentName}, () => {
								this.setStudentLanguage(studentID);
							});
						}}
						values={this.students}
						value={selectedStudent}
						className='student-filter'
						label='Student name'
					/>
					<div className='form-group item_time'>
						<label className='top-label'>Time:</label>
						<div className='form-control'>{moment(this.state.time).format('hh:mm A')}</div>
					</div>
					{this.lengthMins.length > 0
					&& <div className='form-group'>
						<label className='top-label'>Length</label>
						<CustomDropdown
							items={this.lengthMins.map(item => ({key: item.id, value: item.id, title: item.value}))}
							value={this.state.lengthMin}
							onSelect={item => this.setState({lengthMin: item.value})}
						/>
					</div>
					}
					<TextInput
						onEdit={(value, validation) => {
							const newRoomName = value;
							this.setState({roomName: newRoomName, roomNameValid: validation.valid});
						}}
						value={this.state.roomName}
						touched={true}
						validator={(value: string) => Validators.requiredValidator(value, 'Room #', 'Please enter a Room Number.')}
						label='Room #'
						inputClassName='room-input'
					/>
					{this.state.languageID
					&& <div className='form-group'>
						<label className='top-label'>Language</label>
						<CustomDropdown
							items={this.languages.map(item => ({key: item.id, value: item.id, title: item.value}))}
							value={this.state.languageID}
							onSelect={item => this.setState({languageID: item.value})}
						/>
					</div>
					}
					{this.state.error &&
					<div className='error'>{this.state.error}</div>
					}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => this.props.close()}
						title='Cancel'
						className='cancel-button'
					/>
					<Primary
						onClick={() => this.confirm()}
						title='Confirm'
						disabled={!this.state.roomNameValid}
						className='confirm-button'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}