import * as React from 'react';
import type {SVGProps} from 'react';

export function CheckAll(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M21.664 13a.729.729 0 0 0-.543.234l-3.567 3.958c-.194.215-.39.175-.542-.077l-.331-.548-1.11 1.224.165.26c.683 1.129 2.012 1.272 2.882.314l3.59-3.932a.923.923 0 0 0 0-1.199.729.729 0 0 0-.544-.234ZM16.507 13a.749.749 0 0 0-.553.234l-3.633 3.958c-.198.215-.397.175-.553-.077l-.77-1.25a.752.752 0 0 0-1.081-.235c-.355.256-.453.79-.217 1.172l.77 1.25c.694 1.128 2.048 1.271 2.934.313l3.656-3.932a.91.91 0 0 0 0-1.199.749.749 0 0 0-.553-.234Z'
			/>
		</svg>
	);
}
