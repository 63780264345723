import React, {useMemo} from 'react';
import FormService from '../../../services/form-service';
import styles from './styles.module.less';
import {ReportSettings} from './components/modal-settings';
import {TableControls} from './components/table-controls';
import {TableWrapper} from './components/table-wrapper';
import {makeSelectedEntityName} from '../../../utils';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';


interface Props {
	formService: FormService
}

export function SettingsForm({formService}: Props) {
	const selectedStudent = useBehaviorSubject(formService.settingsService.selectedStudent);
	const selectedGroupItem = useBehaviorSubject(formService.settingsService.selectedGroupItem);
	const teacher = useBehaviorSubject(formService.settingsService.teacher);
	const groupType = useBehaviorSubject(formService.settingsService.groupType);
	const selectedEntityName = useMemo(() => makeSelectedEntityName(selectedStudent, selectedGroupItem, teacher, groupType), [
		selectedStudent,
		selectedGroupItem,
		teacher,
		groupType,
	]);

	return <div className={styles.plSettingsModalContent}>
		<ReportSettings service={formService}/>
		<div className={styles.plSettingsBody}>
			<div className={styles.selectedEntityName}>
				<span>{selectedEntityName}</span>
			</div>
			<TableControls service={formService}/>
			<TableWrapper service={formService}/>
		</div>
	</div>;
}
