import {useEffect, useState} from 'react';
import moment from 'moment';
import {FormElement} from '@esgillc/ui-kit/form';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {Dropdown, Label, SelectableList, SelectableListItem, Option} from '@esgillc/ui-kit/form-control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {isDisabled} from './utils';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import styles from './styles.module.less';
import {CommonData} from 'modules/subject-details/types';
import {useStreamEffect} from '@esgillc/ui-kit/utils';

interface Props {
	onSave: () => void;
	onCancel: () => void;
	form: DistrictFormType | SchoolFormType;
	commonData: CommonData,
}

const tooltip = `Subject tabs can only be published by marking periods for a single track.
								 Please use the 'Custom Date Range' option if a tab needs to be published for Schools that use different tracks.`;

export default function MarkingPeriodModal({form, commonData, onSave, onCancel}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(!form.controls.trackDates.value.length);
	const [periods, setPeriods] = useState(commonData.trackDates.filter(td => td.trackID === form.controls.track.value[0]));

	useStreamEffect(form.controls.trackDates.onChanged, (v) => {
		if (!v.currState.value.length) {
			setSaveButtonDisabled(true);
		} else {
			setSaveButtonDisabled(false);
		}
	});
	useStreamEffect(form.controls.track.onChanged, v => {
		form.controls.trackDates.value = [];
		setPeriods(commonData.trackDates.filter(td => td.trackID === v.currState.value[0]));
	});

	useEffect(() => {
		if (!form.controls.track.value.length) {
			form.controls.track.value = [commonData.tracks[0]?.id];
		}
	}, []);

	return <Modal modalManagerRef={modalRef} onCatchError={handleClose}>
		<Modal.Header>
			<Title> Marking Period </Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<div data-cy={'marking-period-body'}>
				<div className={styles.row}>
					<FormElement control={form.controls.track} className={styles.formGroup}>
						<Label className={styles.topLabel}>
							Track
							<OnHoverTooltip message={tooltip}>
								<QuestionMarkIcon fill={'#08c'} height={'12px'} width={'12px'} className={styles.questionMarkIcon}/>
							</OnHoverTooltip>
						</Label>
						<Dropdown displayLabel={(v) => commonData.tracks.find(t => t.id === v[0])?.name}>
							{commonData.tracks.map(t => <Option value={t.id} key={t.id}>{t.name}</Option>)}
						</Dropdown>
					</FormElement>
				</div>
				<div className={styles.titleRow}>
					<div className={styles.inputLabel}>From</div>
					<div className={styles.inputLabel}>To</div>
				</div>
				<FormElement control={form.controls.trackDates}>
					<SelectableList checkboxPlacement='left'>
						{periods.map((td, index, trackDates) => {
							const selectedTrackDates = form.controls.trackDates.value.filter(td => periods.map(p => p.trackDateID).includes(td));
							return <SelectableListItem
								className={styles.period}
								disabled={isDisabled(index, trackDates, selectedTrackDates)}
								value={td.trackDateID}
								key={td.trackDateID}>
								<div data-cy={'track-date'} className={styles.circle}>{++index}</div>
								<div className={styles.date}>
									<div>{moment(td.dateFrom).format('L')}</div>
									<div>{moment(td.dateTo).format('L')}</div>
								</div>
							</SelectableListItem>;
						})}
					</SelectableList>
				</FormElement>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Gray onClick={handleClose}>Cancel</Buttons.Gray>
			<Buttons.Contained disabled={isSaveButtonDisabled} onClick={onSave}>Save</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}



