export {FlashCardsPerPage as CardSizeType} from '@esgi/contracts/esgi/types/esgi.enums/flash-cards-per-page';

export enum FileTypeType {
	Zip = 'zip',
	Pdf = 'pdf'
}

export enum FontSizeType {
	Small = '30pt',
	Medium = '40pt',
	Large = '62pt',
}

export enum FontStyleType {
	Default = 'Trace with guidelines',
}

export interface FontType {
	fontSize: FontSizeType,
	fontStyle: FontStyleType,
}

export type OptionItem = {
	value: FontSizeType | FontStyleType;
	label: string;
};

export enum UnitType {
	Class,
	Group,
	None,
}

export interface UnitData {
	id: number;
	type: UnitType;
	name?: string;
}

export interface StudentWithUnit {
	studentId: number;
	unitData: UnitData,
}

export type ChangeFunction<T> = (value: T) => void;
