import {PropsWithChildren} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {useDatePickerRootContext} from '../../context';

export function DropdownTrigger({children}: PropsWithChildren) {

	const {disabled} = useDatePickerRootContext();

	return <DropdownMenu.Trigger asChild disabled={disabled}>
		{children}
	</DropdownMenu.Trigger>;
}
