import {useEffect} from 'react';
import {Page} from './invitation';
import styles from 'pages/landing/sign-up/styles.module.less';
import useReCaptcha from 'pages/hooks/use-recaptcha';

export default function InvitationPage () {
	const {generateReCaptchaToken} = useReCaptcha();

	useEffect(() => {
		try {
			const page = new Page(generateReCaptchaToken);
			return () => page.destroy();
		} catch (e) {
			console.error(e);
		}
	}, [generateReCaptchaToken]);

	return (
		<div style={{backgroundColor: '#FFF'}}>
			<div style={{minHeight: 500}} className={styles.root} data-bind='render: invitation'>
			</div>
		</div>
	);
}
