import {useCallback, useState} from 'react';
import {useAutoControlledState} from '@esgi/ui';
import {RearrangeItem, StudentsPanel, AllOptionItem, GroupBucket} from '@esgi/main/features/teacher/students-panel';
import {updateGroupsOrderInClass} from '../../../../../types';
import {Class, Group} from '@esgi/main/libs/store';

type Props = {
	setViewMode: VoidFunction;
	updateGroupsOrderInClass: updateGroupsOrderInClass;
	selectedClassId: Class['id'] | AllOptionItem.Value;
	groupsWithStudents: GroupBucket[];
};

export function RearrangeContent({setViewMode, updateGroupsOrderInClass, selectedClassId, groupsWithStudents}: Props) {
	const [isReorderingData, setIsReorderingData] = useState(false);

	const [groupsList, setGroupsList] = useAutoControlledState({
		initialState: groupsWithStudents.map<RearrangeItem>(({id, name, students}) => ({
			id,
			groupName: name,
			groupItemsCount: students.length,
		})),
	});

	const handleSaveClick = useCallback(() => {
		setIsReorderingData(true);
		const groupsItems: Group[] = groupsList.map((groupInList) => {
			const {students, ...group} = groupsWithStudents.find(({id}) => id === groupInList.id)!;

			return {
				...group,
				studentIDs: students.map(({id}) => id),
			};
		});

		updateGroupsOrderInClass({
			classId: selectedClassId === AllOptionItem.Value ? null : selectedClassId,
			groups: groupsItems,
		}).subscribe(() => {
			setViewMode();
			setIsReorderingData(false);
		});
	}, [groupsList, groupsWithStudents, selectedClassId, setViewMode, updateGroupsOrderInClass]);

	return (
		<StudentsPanel.Tabs.TabContent
			withBottomSectionButton
			buttonText='Done'
			onBottomButtonClick={handleSaveClick}
			isBottomSectionButtonDisabled={isReorderingData}
		>
			<StudentsPanel.Lists.SelectableListGroup.Rearrange droppableItems={groupsList} onDragEnd={setGroupsList} />
		</StudentsPanel.Tabs.TabContent>
	);
}
