import {resolvedPromise} from '@esgi/deprecated/utils';
import {FormField, Validators} from'@esgi/deprecated/knockout';
import {AsyncFieldContainer} from '../../../kit/component';
import {ValidationServer} from '../../server';
import {PromoCodeInfo} from '../../types';

import './pc-input.less';

export class PCInputComponent extends AsyncFieldContainer<string> {
	defaultPromoCodeInfo = {
		valid: true,
		value: '',
		isActivationCode: false,
	};
	activationTooltip;
	promoCodeInfo: KnockoutObservable<PromoCodeInfo>;

	success = ko.computed({
		read: () => {
			return this.field.showSuccessValidation() && this.promoCodeInfo() && !this.promoCodeInfo().isActivationCode;
		},
		deferEvaluation: true,
	});

	hasError = ko.computed({
		read: () => {
			return this.promoCodeInfo() && !!this.promoCodeInfo().isActivationCode || this.field.showErrorValidation();
		},
		deferEvaluation: true,
	});

	events = {
		acFlowClicked: (callback) => {
			$(this).on('acFlow', callback);
		},
	};

	public iconClass = ko.computed(() => {
		if (this.field.showSuccessValidation()) {
			return {'fa-check': true};
		}
		if (this.field.showErrorValidation()) {
			return {'fa-close': true};
		}
		return false;
	});

	constructor(promoCode) {
		super(promoCode);
		this.promoCodeInfo = ko.observable<PromoCodeInfo>(this.defaultPromoCodeInfo);
	}

	public buildField(promoCode?): FormField<string> {
		const promoCodeValidator = Validators.Ajax(f => {
			this.inProcess(true);
			return ValidationServer.checkPromoCode(f.value())
				.done((r) => {
					const info = {
						valid: r.valid,
						isActivationCode: r.isActivationCode,
						value: this.field.value(),
					} as PromoCodeInfo;
					this.promoCodeInfo(info);
				})
				.fail(() => {
					this.promoCodeInfo(this.defaultPromoCodeInfo);
				})
				.always(() => {
					this.inProcess(false);
				});
		}, (r) => {
			if (r.isExpired) {
				this.removeTooltip();
				return '<div>This promo code already has expired</div>';
			}
			if (r.notFound && !r.isActivationCode) {
				this.removeTooltip();
				return '<div>This promo code is invalid.</div><div>Please enter a valid code.</div>';
			}
			if (r.isActivationCode) {
				setTimeout(() => {
					this.showAcTooltip();
				}, 100);
				return '';
			}
			this.removeTooltip();
		});

		const f = this.createField(promoCode, promoCodeValidator);
		f.validation.showValidation(true);
		f.validation.successValidation(true);
		f.validation.validationMessageTitleUseHtml = true;
		f.validation.errorPosition = 'bottom';
		f.value.subscribe((v) => {
			if (!v) {
				this.promoCodeInfo(this.defaultPromoCodeInfo);
				this.removeTooltip();
			}
		});
		return f;
	}

	public showAcTooltip() {
		const element = $('.form-group.promo');
		const myTestsTooltipHtml = '<span>It looks like you entered an Activation Code. Go to the&nbsp;<a id="ac-link" style="cursor: pointer;">Activation Code</a>&nbsp;form</span>';
		this.activationTooltip = element.bstooltip({
			title: myTestsTooltipHtml,
			trigger: 'manual',
			html: true,
			placement: 'right',
		});
		element.on('hidden.bs.tooltip', () => {
			element.bstooltip('show');
		});
		element.bstooltip('show');
		$('#ac-link', this.rootElement).on('click', () => {
			$(this).trigger('acFlow');
		});
	}

	public removeTooltip() {
		if (this.activationTooltip) {
			this.activationTooltip.bstooltip('destroy');
			this.activationTooltip = null;
		}
		super.removeTooltip();
	}

	public validate(silent: boolean = false): JQueryPromise<boolean> {
		if (this.inProcess()) {
			return resolvedPromise(false);
		} else {
			if (this.promoCodeInfo() && !this.promoCodeInfo().valid) {
				return resolvedPromise(false);
			} else {
				return resolvedPromise(true);
			}
		}
	}

	public template = () =>
		<div data-bind='var : {root: $data}'>
			<ko data-bind='with: field'>
				<div className='form-group promo'
				     data-bind="css: {'has-success' : root.success, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : root.hasError }, afterRender: true, visible: visible">
					<div>
						<input id='promo-code' name='promo-code' type='text'
						       className='form-control input-md pc-input'
						       data-bind='value: value'/>
						<div className='error-message visible-xs visible-sm hidden-md'>
							<span data-bind='text:validation.validationResults'/>
						</div>
						<ko data-bind='if: !root.inProcess()'>
							<i data-bind="css: {'fa-check': root.success(), 'fa-close': root.hasError(), 'fa form-control-feedback': root.iconClass()}"/>
						</ko>
						<ko data-bind='if: root.inProcess()'>
							<i className='fa fa-spinner fa-spin form-control-feedback'/>
						</ko>
					</div>
				</div>
			</ko>
		</div>;
}
