import {useLocation} from 'react-router-dom';
import {FlashCardStateParams} from './use-flashcard-url-with-params';

export function useFlashcardsHistoryState(): FlashCardStateParams {
	const location = useLocation();

	const params: FlashCardStateParams = location.state?.initialSelectedParams ?? {};

	return {...params};
}
