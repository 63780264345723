import React, {useState} from 'react';
import {join, OsChecker} from '@esgillc/ui-kit/utils';
import styles from '../card.module.less';

interface Props {
	name: string;
	color: string;
	canDrag: boolean;
	openContextMenuClicked: () => void;
}

export function CardHeader(props: Props) {
	const [mouseDown, setMouseDown] = useState(false);

	const isMac = OsChecker.isMac();
	return <div className={join(styles.header, mouseDown && styles.mousedown, props.canDrag && styles.draggable)}
	            style={{backgroundColor: '#' + props.color}}
	            onMouseDown={() => isMac && (setMouseDown(true))}
	            onMouseUp={() => isMac && setMouseDown(false)}
	            onMouseLeave={() => isMac && setMouseDown(false)}>
		<h3 className={styles.title}>
			{props.name}
		</h3>
		<a href='#' className={styles.editIcon} onClick={props.openContextMenuClicked}>
			<i className='fa fa-ellipsis-v'/>
		</a>
	</div>;
}
