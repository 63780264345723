import {FlexBox, SelectableList} from '@esgi/ui/layout';
import {SelectableListRoot, SelectableListTrigger} from './components/components.styled';
import {MouseEvent, ReactNode, useCallback, useMemo, useState} from 'react';
import {GroupItemContent} from '../components/group-item-content';
import {IdableItem} from '../../../types';
import {noop} from 'underscore';
import {IconComponent} from '@esgi/ui';

type IconBefore = {
	Component: IconComponent;
};

type IconBeforePlacement = IconBefore & {
	placement: 'before';
};

type IconAfter = {
	Component: IconComponent;
	variant?: 'styled-button';
	onClick?: VoidFunction;
};

type IconAfterPlacement = IconAfter & {
	placement: 'after';
};

type Props<Item extends IdableItem> = {
	dataCy?: string;
	value: Item['id'][];
	items: Item[];
	onItemClick: (itemId: Item['id']) => void;
	onTriggerButtonClick?: VoidFunction;
	isListSelected: boolean;
	isListOpen: boolean;
	withCounter?: boolean;
	groupName: string;
	hideArrowIcon?: boolean;
	allowSelectEmpty?: boolean;
	children?: (args: {item: Item}) => ReactNode;
	icon?: [IconBefore, IconAfter] | (IconBeforePlacement | IconAfterPlacement);
};

export function ExpandableMultiple<Item extends IdableItem>({
	dataCy,
	isListOpen,
	value,
	items,
	onItemClick,
	children,
	onTriggerButtonClick = noop,
	isListSelected,
	withCounter,
	groupName,
	hideArrowIcon,
	allowSelectEmpty,
	icon,
}: Props<Item>) {
	const isEmptyList = !items.length;

	const [hovered, setHovered] = useState(false);

	const handleTriggerButtonClick = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();
			onTriggerButtonClick();
		},
		[onTriggerButtonClick],
	);

	const handleMouseOver = useCallback(() => {
		setHovered(() => {
			if (isListOpen || isListSelected) {
				return false;
			}

			if (isEmptyList && allowSelectEmpty) {
				return true;
			}

			return true;
		});
	}, [allowSelectEmpty, isEmptyList, isListOpen, isListSelected]);

	const beforeIcon = useMemo(() => {
		if (Array.isArray(icon)) {
			return icon[0].Component;
		}

		if (icon?.placement === 'before') {
			return icon.Component;
		}

		return;
	}, [icon]);

	const afterIcon = useMemo(() => {
		if (Array.isArray(icon)) {
			const {Component, variant, onClick} = icon[1];

			return variant === 'styled-button' ? (
				<SelectableList.StyledButton onClick={onClick}>
					<Component />
				</SelectableList.StyledButton>
			) : (
				<Component onClick={onClick} />
			);
		}

		if (icon?.placement === 'after') {
			const {variant, Component} = icon;
			return variant === 'styled-button' ? (
				<SelectableList.StyledButton>
					<Component />
				</SelectableList.StyledButton>
			) : (
				<Component />
			);
		}

		return null;
	}, [icon]);
	const handleMouseOut = useCallback(() => {
		setHovered(false);
	}, [setHovered]);

	return (
		<SelectableListRoot isListOpen={isListOpen} dataCy={dataCy}>
			<SelectableList.GroupRoot type='multiple' value={value.map(String)}>
				<SelectableListTrigger
					openList={isListOpen}
					selected={isListSelected}
					onClick={!isEmptyList || allowSelectEmpty ? onTriggerButtonClick : noop}
					hovered={hovered}
					onMouseOut={handleMouseOut}
					onMouseOver={handleMouseOver}
					IconBefore={beforeIcon}
				>
					<GroupItemContent
						itemsCount={withCounter ? items.length : undefined}
						label={groupName}
						hovered={hovered}
						selected={isListSelected}
					/>
					{!isEmptyList && !hideArrowIcon && !beforeIcon && (
						<FlexBox onClick={handleTriggerButtonClick} align='center' justify='end'>
							<SelectableList.TriggerIcon opened={isListOpen} />
						</FlexBox>
					)}
					{afterIcon}
				</SelectableListTrigger>
				<SelectableList.Group>
					{items.map((item) => (
						<SelectableList.Item value={String(item.id)} key={item.id} onClick={() => onItemClick(item.id)}>
							{children?.({item})}
						</SelectableList.Item>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
