import {PropsWithChildren, useState} from 'react';
import {config, useSpring, useTransition} from 'react-spring';
import {Content, Overlay, Root} from './index.styled';
import {CSS} from '@stitches/react';
import {Property} from '@stitches/react/types/css';

type Props = {
	show: boolean;
	css?: CSS;
	onClickOutside?: VoidFunction;
	afterChangeState?: (state: boolean) => void;

	/**
	 * CSS property sets the z-order of a positioned element.
	 * @default 500
	 */
	zIndex?: Property.ZIndex;
	width?: Property.Width;
} & PropsWithChildren;

export function Drawer({width = 400, ...props}: Props) {
	const [inProgress, setInProgress] = useState(false);
	const [closeCompleted, setCloseCompleted] = useState(!props.show);

	const contentProps = useTransition(props.show, {
		from: {transform: 'translateX(100%)'},
		enter: {transform: 'translateX(0%)'},
		leave: {transform: 'translateX(100%)'},
		config: config.gentle,
	});

	const overlayProps = useSpring({
		opacity: props.show ? 0.88 : 0,
		onStart: () => {
			if (!inProgress) {
				setInProgress(true);
				setCloseCompleted(false);
			}
		},
		onRest: (e) => {
			if (e.finished === true) {
				setInProgress(false);
				if (!props.show) {
					props.afterChangeState?.(false);
					setCloseCompleted(true);
				} else {
					props.afterChangeState?.(true);
				}
			}
		},
	});

	return (
		<Root
			style={{visibility: !props.show && !inProgress && closeCompleted ? 'hidden' : 'visible'}}
			css={{zIndex: props.zIndex ?? 500}}
		>
			<Overlay onClick={() => props.onClickOutside?.()} style={overlayProps} />
			{contentProps(
				(styles, show) =>
					show && (
						<Content style={styles} css={{...props.css, width}}>
							{props.children}
						</Content>
					),
			)}
		</Root>
	);
}