import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {RootBox} from './index.styled';

type LeftPanelRootProps = ComponentPropsWithoutRef<'div'> & BaseComponentProps;

export const LeftPanelRoot = forwardRef<HTMLDivElement, LeftPanelRootProps>(
	({dataCy = 'features-left-panel-root', css = {}, children, ...props}, forwardedRef) => {
		return (
			<RootBox dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</RootBox>
		);
	},
);
