import * as React from 'react';
import type {SVGProps} from 'react';

export function Profile(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 24a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S21.523 6 16 6 6 10.477 6 16s4.477 10 10 10Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M18 14a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm2 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-5.117 7a3 3 0 0 0-2.817 1.968 8.03 8.03 0 0 1-1.642-1.231A5 5 0 0 1 14.884 19h2.233a5 5 0 0 1 4.459 2.737 8.025 8.025 0 0 1-1.642 1.23A3 3 0 0 0 17.117 21h-2.234Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
