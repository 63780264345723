import {useMemo, useState, useEffect} from 'react';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {
	TestSessionDetailsChanged,
	TestSessionRestoreEvent,
	TestSessionDeleteEvent,
	TestSessionAddEvent,
	TestSessionUpdateEvent,
	ResetPanelsEvent,
	NewTestSessionCanceledEvent,
	AnswersUpdateEvent,
} from '../../events';
import {TestSession, TestSessions, ResultRequest, Answer} from '../../types';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {TestSessionDetailsFormService} from '../../services';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {useUser} from '@esgi/core/authentication';
import {SsoTracker} from '@esgi/core/tracker';

import moment from 'moment';

interface Props {
	service: TestSessionDetailsFormService;
	getAnswers: (request: ResultRequest) => void;
	setAnswers: (answers?: Answer[]) => void;
}

export const useSessionEvents = ({service, getAnswers, setAnswers}: Props) => {
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);
	const currentUser = useUser();

	const [initTestDate, setInitTestDate] = useState<moment.Moment>(null);
	const [prevTestDate, setPrevTestDate] = useState<moment.Moment>(null);
	const [testSessions, setTestSessions] = useState<TestSessions>({});
	const [prevTestSessionId, setPrevTestSessionId] = useState(0);
	const [showDeletedSessions, setShowDeletedSessions] = useState(false);

	const visibleSessions = useMemo<TestSession[]>(
		() => {
			const sessions: TestSession[] = testSessionDetails?.testSessions || [];
			const result = sessions.filter(
				({deleted}) => showDeletedSessions || !deleted,
			);

			result.sort((a, b) => b.testDate.diff(a.testDate));

			if (result.length === 0) {
				result.push(TestSession.Empty());
			}

			return result;
		},
		[testSessionDetails, showDeletedSessions],
	);

	const setTestSession = (testSession?: TestSession) => {
		const session = testSession ?? visibleSessions[0];

		service.setCurrentTestSession(session);
		service.setDirty(false);
		service.setTestDateTouched(false);

		if (!session.isEmpty) {
			getAnswers({testSessionID: session.id});
		} else {
			setAnswers();
		}
	};

	useEventEffect(TestSessionAddEvent, () => {
		service.create().subscribe((response) => {
			const nowProfileTZ = moment(response.nowProfileTZ);
			const newTestSession = TestSession.Empty();
			newTestSession.testDate = nowProfileTZ;
			const testSessions = testSessionDetails.testSessions.slice();
			testSessions.push(newTestSession);

			service.setTestSessionDetails({nowProfileTZ, testSessions});
			service.setCurrentTestSession(newTestSession);
			setPrevTestSessionId(currentTestSession.id);
			setTestSession(newTestSession);

			service.setEditMode(true);
		});
	});

	useEventEffect(TestSessionUpdateEvent, ({request}) => {
		service.serviceData.update(request).subscribe(({testSessionID}) => {
			const {testDate} = currentTestSession;
			const {userID, firstName, lastName} = currentUser;
			let message = `You've edited Test Session ${testDate.format('MM-DD-YYYY hh:mm A')}`;

			if (currentTestSession.isEmpty) {
				const {testSessions} = testSessionDetails;
				currentTestSession.id = testSessionID;
				currentTestSession.number = Object.keys(testSessions).length ;
				currentTestSession.durationString = 'NA';
				currentTestSession.userID = userID;
				currentTestSession.userName = `${firstName} ${lastName}`;

				testSessions.push(currentTestSession);

				service.setTestSessionDetails({
					testSessions: testSessions.filter((s) => !s.isEmpty),
				});
				setPrevTestSessionId(testSessionID);

				message = `You've created Test Session ${testDate.format('MM-DD-YYYY hh:mm A')}`;
			} else {
				service.setTestSessionDetails({
					testSessions: testSessionDetails.testSessions.map(
						(session) => session.id === currentTestSession.id
							? currentTestSession
							: session,
					),
				});
			}

			showSnackbarNotification(message);
			dispatchAppEvent(
				TestSessionDetailsChanged,
				new TestSessionDetailsChanged(
					service.initData.testID,
					currentTestSession.id,
					testDate,
				),
			);

			service.setDirty(false);
			service.setSaving(false);
			service.setEditMode(false);
		});
	});

	useEventEffect(TestSessionDeleteEvent, () => {
		const {id: testSessionId, testDate} = currentTestSession;
		service.remove({testSessionId}).subscribe(() => {
			currentTestSession.deleted = true;
			service.setTestSessionDetails({
				testSessions: testSessionDetails.testSessions.map(
					(session) => session.id === testSessionId
						? currentTestSession
						: session,
				),
			});
			setPrevTestSessionId(showDeletedSessions ? testSessionId : 0);

			dispatchAppEvent(
				TestSessionDetailsChanged,
				new TestSessionDetailsChanged(service.initData.testID),
			);
			showSnackbarNotification(`You've deleted Test Session ${testDate.format('MM-DD-YYYY hh:mm A')}`);
			SsoTracker.trackEvent({
				trackingEvent: 'TestSessionDeleted',
				data: {testSessionId},
			});

			service.setEditMode(false);
		});
	});

	useEventEffect(TestSessionRestoreEvent, () => {
		const {id: testSessionId, testDate} = currentTestSession;
		service.restore({testSessionId}).subscribe(() => {
			currentTestSession.deleted = false;
			service.setTestSessionDetails({
				testSessions: testSessionDetails.testSessions.map(
					(session) => session.id === testSessionId
						? currentTestSession
						: session,
				),
			});
			setPrevTestSessionId(testSessionId);

			dispatchAppEvent(
				TestSessionDetailsChanged,
				new TestSessionDetailsChanged(service.initData.testID),
			);
			showSnackbarNotification(`You've restored Test Session ${testDate.format('MM-DD-YYYY hh:mm A')}`);
			SsoTracker.trackEvent({
				trackingEvent: 'TestSessionRestored',
				data: {testSessionId},
			});

			service.setEditMode(false);
		});
	});

	useEventEffect(ResetPanelsEvent, () => {
		if (currentTestSession.isEmpty) {
			service.setTestSessionDetails({
				testSessions: testSessionDetails.testSessions.filter((s) => !s.isEmpty),
			});
			setTestSession(testSessions[prevTestSessionId]);
		} else {
			if (initTestDate) {
				currentTestSession.testDate = initTestDate;
			}
			setTestSession(currentTestSession);
		}
	});

	useEventEffect(NewTestSessionCanceledEvent, () => {
		service.setTestSessionDetails({
			testSessions: testSessionDetails.testSessions.filter((s) => !s.isEmpty),
		});
		setTestSession(testSessions[prevTestSessionId]);
	});

	useEventEffect(TestSessionDetailsChanged, ({testDate, sessionId}) => {
		if (!prevTestDate) {
			return;
		}
		if (testDate && sessionId) {
			dispatchAppEvent(
				AnswersUpdateEvent,
				new AnswersUpdateEvent(prevTestDate, sessionId, testDate),
			);
		}
	});

	useEffect(() => {
		if (!testSessionDetails) {
			return;
		}

		const sessions = testSessionDetails.testSessions.reduce((result, item) => {
			result[item.id] = item;
			return result;
		}, {});
		setTestSessions(sessions);

		if (currentTestSession && currentTestSession.isEmpty) {
			return;
		}

		if (prevTestSessionId) {
			setTestSession(sessions[prevTestSessionId]);
		} else {
			setTestSession();
		}
	}, [testSessionDetails]);

	useEffect(() => {
		if (editMode) {
			if (prevTestSessionId === 0) {
				setPrevTestSessionId(currentTestSession.id);
			}
		} else {
			setPrevTestSessionId(0);
		}
	}, [editMode]);

	return {
		initTestDate,
		setInitTestDate,
		prevTestDate,
		setPrevTestDate,
		testSessions,
		setTestSessions,
		prevTestSessionId,
		setPrevTestSessionId,
		showDeletedSessions,
		setShowDeletedSessions,
		visibleSessions,
		setTestSession,
	};
};
