import NavigationBarRoot from './components/navigation-bar-root';
import NavigationBarHead from './components/navigation-bar-head';
import NavigationBarContent from './components/navigation-bar-content';
import NavigationBarItem from './components/navigation-bar-item';
import NavigationBarDivider from './components/navigation-bar-divider';
import NavigationBarBottom from './components/navigation-bar-bottom';

export const NavigationBar = Object.assign({}, {
	Root: NavigationBarRoot,
	Head: NavigationBarHead,
	Content: NavigationBarContent,
	Bottom: NavigationBarBottom,
	Item: NavigationBarItem,
	Divider: NavigationBarDivider,
});
