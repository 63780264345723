import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {GradeIndicator} from '../../grade-indicator';

export const GradeSectionItem = styled(GridBox, {
	padding:	'12px 10px',
	borderRadius: '6px',
	backgroundColor: '$vivid',
	marginTop: '8px',
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	gap: '6px',
});

export const GradeIndicatorBox = styled(GradeIndicator, {
	width: 'unset',
	maxHeight: '16px',
});