import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {studentsStore, Student} from '@esgi/main/libs/store';

export class StudentsRootService extends BaseService {
	private readonly students$ = new BehaviorSubject<Student[]>([]);

	private storageStudents = studentsStore();

	constructor() {
		super();

		this.storageStudents.get().subscribe({
			next: (students) => this.students$.next(students.data),
		});
	}

	public get(id: Student['id']) {
		return this.students$.value.find((item) => item.id === id);
	}

	public override dispose() {
		this.storageStudents.dispose();
	}
}