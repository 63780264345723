import {PropsWithChildren} from 'react';
import {useTestDashboardContext} from '../../../../layout/outlet-context';
import {Close, Download} from '@esgi/ui/icons';
import {Button, Portal} from '@esgi/ui';
import {Drawer, OneLinedText, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {ActionButtons, ActionButtonsDivider, DrawerBody, DrawerHeader} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = PropsWithChildren<{
	title: string;
	subTitle: string;
	onClose: VoidFunction;
	onDownloadClick: VoidFunction;
}>;

export function FullScreenDrawer({onClose, title, subTitle, children, onDownloadClick}: Props) {
	const drawerRef = useDrawerRef();
	const handleCloseDrawer = useDrawerClose(drawerRef, onClose);

	const {rootPageContentContainerRef} = useTestDashboardContext();

	return (
		<Portal container={rootPageContentContainerRef.current}>
			<Drawer width='100%' drawerRef={drawerRef} onClickOutside={handleCloseDrawer}>
				<DrawerHeader>
					<GridBox>
						<OneLinedText size='large' color='base'>
							{title}
						</OneLinedText>
						<Text size='xSmall' font='mono' color='neutral40'>
							{subTitle}
						</Text>
					</GridBox>

					<ActionButtons>
						{/* TODO: The next phase */}
						{/* <Button.Icon onClick={onDownloadClick} withBackgroundHover>
							<Download />
						</Button.Icon>

						<ActionButtonsDivider /> */}

						<Button.Icon onClick={handleCloseDrawer} withBackgroundHover>
							<Close />
						</Button.Icon>
					</ActionButtons>
				</DrawerHeader>
				<DrawerBody>{children}</DrawerBody>
			</Drawer>
		</Portal>
	);
}
