import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useCallback} from 'react';
import {getFullName} from '@esgi/main/kits/assignments';

export type StudentWitoutCredentials = {
	id: number;
	firstName: string;
	lastName: string;
};

type Props = {
	onAlertClose: VoidFunction;
	onPostToStudents: VoidFunction;
	students: StudentWitoutCredentials[];
	onOpenCredentialsForStudentsInAssignment: VoidFunction;
};

export function MissingStudentCredentialsAlert({
	onPostToStudents,
	students,
	onAlertClose,
	onOpenCredentialsForStudentsInAssignment,
}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onAlertClose);

	const postToStudentsAndClose = useCallback(() => {
		onPostToStudents();
		closeAlert();
	}, [closeAlert, onPostToStudents]);

	const openStudentsCredentialsAndClose = useCallback(() => {
		onOpenCredentialsForStudentsInAssignment();
		closeAlert();
	}, [closeAlert, onOpenCredentialsForStudentsInAssignment]);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Missing Student Credentials
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox gap='3'>
					<Text size='medium' color='neutral40'>
						{`The following student${students.length >= 2 ? 's' : ''}
						need a username and password in order to access this assignment:`}
					</Text>
					<GridBox gap='1'>
						{students.map(({firstName, lastName, id}) => (
							<Text size='medium' color='neutral40' key={id}>
								{getFullName({
									firstName,
									lastName,
								})}
							</Text>
						))}
					</GridBox>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={openStudentsCredentialsAndClose}>
						<Text size='medium' bold color='base'>
							Add Credentials
						</Text>
					</Button>
					<Button color='secondary' onClick={postToStudentsAndClose}>
						<Text size='medium' bold color='base'>
							Post To Students
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
