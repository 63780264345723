import {useCallback, useEffect, useMemo, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {
	SelectableListTriggerContent,
	SelectableListRoot,
	SelectableListTrigger,
	IconBox,
	ItemTagAndTitle,
	TriggerWrapper,
	Options,
	IndicatorWrapper, HeaderLabel, SelectableListGroupRoot, SelectableListGroup,
} from '../../index.styled';
import {HeaderRow, StudentIconBox} from './index.styled';
import {AccordionCollapse, AccordionExpand, UserListItem, UserListItemSelected} from '@esgi/ui';
import {TestListItem} from './components/test-item';
import {StudentListDataItem, TestItem} from '../../../../types';
import {StudentModel} from '@esgi/main/features/assessments';
import {Results} from '../results';

interface Props {
	student: StudentListDataItem;
	showAllStudents: boolean;
	isDisabled?: boolean;
	onStartTest: (test: TestItem, student: StudentModel) => void;
}

export function StudentList(props: Props) {
	const {
		student,
		isDisabled,
	} = props;

	const [isItemOpen, setItemOpen] = useState(true);

	const isAllTested = useMemo(() => {
		return student.notTested === 0;
	}, [student]);

	useEffect(() => {
		setItemOpen(false);
	}, [props.showAllStudents, props.student]);

	const onTestStart = (test: TestItem) => {
		const studentModel: StudentModel = {
			firstName: student.firstName,
			lastName: student.lastName,
			gradeLevelID: student.gradeLevelID,
			hasCredentials: student.hasCredentials,
			id: student.id,
			photoUrl: student.photoUrl,
			languageID: student.languageID,
		};

		const testModel: TestItem = {
			...test,
			maxScore: test.maxScore,
			untested: test.untested,
			incorrect: test.incorrect,
			correct: test.correct,
		};
		props.onStartTest(testModel, studentModel);
	};

	const onRowClick = useCallback(() => {
		if (isAllTested && !props.showAllStudents) {
			return;
		}
		setItemOpen(prev => !prev);
	}, [isAllTested, props.showAllStudents]);

	return <SelectableListRoot isListOpen={isItemOpen} onListOpenChange={setItemOpen} dataCy='full-student'>
		<SelectableListGroupRoot>
			<HeaderRow disabled={student.notTested === 0 && !props.showAllStudents} onClick={onRowClick} isListOpen={isItemOpen} dataCy='student-item'>
				<ItemTagAndTitle>
					<SelectableListTriggerContent>
						<StudentIconBox dataCy='student-icon'>
							{isItemOpen ? <UserListItemSelected /> : <UserListItem />}
						</StudentIconBox>
						<HeaderLabel data-cy='student-name' size='large' isListOpen={isItemOpen}>{student.firstName} {student.lastName}</HeaderLabel>
					</SelectableListTriggerContent>
				</ItemTagAndTitle>

				<Options>
					<IconBox>
						{isDisabled
							? <Text size='small' color='muted'>No Results</Text>
							: <Results entityType='student' isItemOpen={isItemOpen} notTested={student.notTested} />
						}
					</IconBox>
					<TriggerWrapper>
						<SelectableListTrigger isListOpen={isItemOpen} disabled={student.notTested === 0 && !props.showAllStudents}>
							<IndicatorWrapper>
								{isItemOpen ? <AccordionCollapse /> : <AccordionExpand />}
							</IndicatorWrapper>
						</SelectableListTrigger>
					</TriggerWrapper>
				</Options>
			</HeaderRow>
			<SelectableListGroup>
				{!isDisabled && <TestListItem onTestStart={onTestStart} tests={student.tests} studentId={student.id}/>}
			</SelectableListGroup>
		</SelectableListGroupRoot>
	</SelectableListRoot>;
}

