import {
	GradeLevelModel,
	SchoolModel,
	SpecialistModel,
	StudentModel,
} from '../models/models';

interface SorterOptions {
	schools: SchoolModel[];
	specialists: SpecialistModel[];
	studentSortBy: string;
	gradeLevels: GradeLevelModel[];
}

export default class StudentSorter {
	private schoolSortNames: Map<number, string> = new Map();
	private teacherSortNames: Map<number, string> = new Map();
	private classSortNames: Map<number, string> = new Map();
	private groupSortNames: Map<number, string> = new Map();
	private specialistSortNames: Map<number, string> = new Map();
	private specialistGroupsSortNames: Map<number, string> = new Map();
	private gradeLevels: number[];
	private sortBy: string;

	constructor(options: SorterOptions) {
		this.initSortNames(options);
	}

	private initSortNames(options: SorterOptions): void {
		let schools = options.schools;
		for (let i = 0; i < schools.length; i++) {
			let school = schools[i];
			this.schoolSortNames[school.schoolID] = school.name.toLowerCase();

			let teachers = school.teachers;
			for (let j = 0; j < teachers.length; j++) {
				let teacher = teachers[j];
				this.teacherSortNames[teacher.teacherID] = teacher.lastName.toLowerCase() + ' ' + teacher.firstName.toLowerCase();

				let classes = teacher.classes;
				for (let k = 0; k < classes.length; k++) {
					let _class = classes[k];
					this.classSortNames[_class.classID] = _class.name.toLowerCase();

					let groups = _class.groups;
					for (let l = 0; l < groups.length; l++) {
						let group = groups[l];
						this.groupSortNames[group.groupID] = group.name.toLowerCase();
					}
				}
			}
		}

		let specialists = options.specialists;
		for (let i = 0; i < specialists.length; i++) {
			let specialist = specialists[i];

			this.specialistSortNames[specialist.userID] = specialist.name.toLowerCase();

			let specialistGroups = specialist.groups;
			for (let j = 0; j < specialistGroups.length; j++) {
				let specialistGroup = specialistGroups[j];
				this.specialistGroupsSortNames[specialistGroup.groupID] = specialists[i].name.toLowerCase();
			}
		}
		this.sortBy = options.studentSortBy;
		this.gradeLevels = options.gradeLevels.map(g => g.gradeLevelID);
	}

	private isDigit(chr): boolean {
		return chr.match(/[0-9]/i);
	}

	private retrieveDigits(str: string): string {
		return str.split('').map(x => {
			return this.isDigit(x) ? x : '';
		}).join('');
	}

	private compareObjects(a: any, b: any): number {
		return a > b ? 1 : a < b ? -1 : 0;
	}

	private isLetter(chr) {
		return chr.match(/[a-z]/i);
	}

	private retrieveLetters(str: string): string {
		return str.toLowerCase().split('').map(x => {
			return this.isLetter(x) ? x : '';
		}).join('');
	}

	public sortStudents(sortBy: string, asc: boolean, students: StudentModel[]): StudentModel[] {
		let sortedStudents = [...students];
		sortedStudents.sort((s1: StudentModel, s2: StudentModel) => {
			let result = 0;
			switch (sortBy) {
				case 'student':
					let s1Name = this.sortBy === 'Last Name' ? this.retrieveLetters(s1.lastName) : this.retrieveLetters(s1.firstName);
					let s2Name = this.sortBy === 'Last Name' ? this.retrieveLetters(s2.lastName) : this.retrieveLetters(s2.firstName);
					result = this.compareObjects(s1Name, s2Name);
					break;
				case 'school':
					let s1SchoolName = this.schoolSortNames[s1.schoolID];
					let s2SchoolName = this.schoolSortNames[s2.schoolID];
					result = this.compareObjects(s1SchoolName, s2SchoolName);
					break;
				case 'teacher':
					let s1TeacherName = s1.primaryTeacherID ? this.teacherSortNames[s1.primaryTeacherID] : '';
					let s2TeacherName = s2.primaryTeacherID ? this.teacherSortNames[s2.primaryTeacherID] : '';
					result = this.compareObjects(s1TeacherName, s2TeacherName);
					break;
				case 'class':
					let s1ClassesName = s1.classIDs.length > 0 ? s1.classIDs.map(x => {
						return this.classSortNames[x];
					}).join(' ') : '';
					let s2ClassesName = s2.classIDs.length > 0 ? s2.classIDs.map(x => {
						return this.classSortNames[x];
					}).join(' ') : '';
					result = this.compareObjects(s1ClassesName, s2ClassesName);
					break;
				case 'group':
					let s1GroupsName = s1.groupIDs.length > 0 ? s1.groupIDs.map(x => {
						return this.groupSortNames[x];
					}).join(' ') : '';
					let s2GroupsName = s2.groupIDs.length > 0 ? s2.groupIDs.map(x => {
						return this.groupSortNames[x];
					}).join(' ') : '';
					result = this.compareObjects(s1GroupsName, s2GroupsName);
					break;
				case 'gradelevel':
					let s1GradeLevelIndex = this.gradeLevels.indexOf(s1.gradeLevelID);
					let s2GradeLevelIndex = this.gradeLevels.indexOf(s2.gradeLevelID);
					result = this.compareObjects(s1GradeLevelIndex, s2GradeLevelIndex);
					break;
				case 'createDate':
					let s1CreateDate = this.retrieveDigits(s1.createDate);
					let s2CreateDate = this.retrieveDigits(s2.createDate);
					result = this.compareObjects(s1CreateDate, s2CreateDate);
					break;
				case 'specialist':
					let s1specialist = s1.specialistGroupUserIDs.length > 0 ? s1.specialistGroupUserIDs.map(x => {
						return this.specialistSortNames[x];
					}).join(' ') : '';
					let s2specialist = s2.specialistGroupUserIDs.length > 0 ? s2.specialistGroupUserIDs.map(x => {
						return this.specialistSortNames[x];
					}).join(' ') : '';
					result = this.compareObjects(s1specialist, s2specialist);
					break;
				case 'specialistgroup':
					let s1specialistGroupName = s1.specialistGroupIDs.length > 0 ? s1.specialistGroupIDs.map(x => {
						return this.specialistGroupsSortNames[x];
					}).join(' ') : '';
					let s2specialistGroupName = s2.specialistGroupIDs.length > 0 ? s2.specialistGroupIDs.map(x => {
						return this.specialistGroupsSortNames[x];
					}).join(' ') : '';
					result = this.compareObjects(s1specialistGroupName, s2specialistGroupName);
					break;
				case 'studentID':
					result = this.compareObjects(s1.studentIDN, s2.studentIDN);
					break;
			}
			return result * (asc ? 1 : -1);
		});
		return sortedStudents;
	}
}
