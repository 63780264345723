import './styles.less';
import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TestScreenType} from '@esgi/core/enums';
import {userStorage} from '@esgi/core/authentication';
import {UserSettingsChangedEvent} from 'shared/modules/user-settings/events';
import {EventBusManager} from '@esgillc/events';

export class Props {
	testScreenTypes: TestScreenType[];
}

class State {
	showSelfAssessOption: boolean;
}

export class TestScreenTypeIcon extends React.PureComponent<Props, State> {
	private readonly eventBus = new EventBusManager();
	constructor(props) {
		super(props);
		const val = userStorage.get().showSelfAssessOption;
		this.state = {showSelfAssessOption: val};
	}
	render() {
		if (!this.props.testScreenTypes) {
			return <></>;
		}
		if (this.props.testScreenTypes.find(t => t === TestScreenType.SelfAssessment) && this.state.showSelfAssessOption) {
			return <>{this.renderSelfAssessIcon()}</>;
		}
		return <></>;
	}
	
	componentWillUnmount() {
		this.eventBus.destroy();
	}

	componentDidMount() {
		this.eventBus.subscribe(UserSettingsChangedEvent, (args: UserSettingsChangedEvent) => {
			if (args.showSelfAssessOption !== this.state.showSelfAssessOption) {
				this.setState({showSelfAssessOption: args.showSelfAssessOption});
			}
		});
	}

	private renderSelfAssessIcon() {
		return <OnHoverTooltip key='tk3' message='Self-Assess test'>
            <div className='selfAssessIcon'>
                <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='14.0385' cy='14.0385' r='12' fill='white'/>
                    <path
                        d='M21.5258 21.5385H6.55112C6.78049 17.0267 10.0946 13.5385 14.0385 13.5385C17.9823 13.5385 21.2964 17.0267 21.5258 21.5385Z'
                        fill='#BA5ACB' stroke='white'/>
                    <circle cx='14.0385' cy='10.0385' r='5' fill='#FFA53D' stroke='white' strokeWidth='2'/>
                    <circle cx='14' cy='14' r='13' stroke='#059BE0' strokeWidth='2'/>
                </svg>
            </div>
        </OnHoverTooltip>;
	}
}

