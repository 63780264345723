import {useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {Buttons} from '@esgillc/ui-kit/button';
import {TestType} from '@esgi/core/enums';
import {SummarySelectors} from './summary.selectors';
import {TabSelectedEvent} from '@esgillc/ui-kit/tabs-with-panel';
import {IEP_NAME} from './utils';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import styles from './styles.module.less';
import {IEPGoal} from './types';

interface Props {
	testType: TestType;
	subject: Subject;
	hierarchy: HierarchySnapshot;
	onOpenFlash?: () => void;
	onOpenTestDetails: () => void;
	onOpenTestHistory: () => void;
	onOpenExport?: () => void;
	onOpenIEPEdit: () => void;
	onOpenIEPReport: () => void;
	onClose: () => void;
	iepGoalLinkText?: string
}

export const SummaryButtons = (props: Props): JSX.Element => {
	const [isIEP, setIsIEP] = useState(false);

	const {
		testType,
		onOpenFlash,
		onOpenTestDetails,
		onOpenTestHistory,
		onOpenExport,
		onOpenIEPEdit,
		onOpenIEPReport,
		onClose,
	} = props;

	useEventEffect(TabSelectedEvent, ({tab}) => {
		setIsIEP(tab?.name === IEP_NAME);
	});

	if (isIEP) {
		return (
			<div className={styles.buttons}>
				<Buttons.Text
					className={styles.button}
					onClick={onOpenIEPEdit}
				>
					EDIT IEP GOAL
				</Buttons.Text>
				<Buttons.Text
					className={styles.button}
					onClick={onOpenIEPReport}
				>
					IEP PROGRESS REPORT
				</Buttons.Text>
				<Buttons.Contained
					className={styles.buttonClose}
					onClick={onClose}
				>
					Close
				</Buttons.Contained>
			</div>
		);
	}

	return (
		<div className={styles.buttons}>
			{testType === TestType.YN &&
				<Buttons.Text
					className={styles.button}
					data-cy={SummarySelectors.flashCardsButton}
					onClick={onOpenFlash}
				>
					FLASH CARDS
				</Buttons.Text>
			}
			<Buttons.Text
				className={styles.button}
				data-cy={SummarySelectors.testDetailsButton}
				onClick={onOpenTestDetails}
			>
				TEST DETAILS
			</Buttons.Text>
			<Buttons.Text
				className={styles.button}
				data-cy={SummarySelectors.testHistoryButton}
				onClick={onOpenTestHistory}
			>
				TEST HISTORY
			</Buttons.Text>
			{testType === TestType.Rubric &&
				<Buttons.Text
					className={styles.button}
					onClick={onOpenExport}
				>
					EXPORT
				</Buttons.Text>
			}
			{props.iepGoalLinkText &&
				<Buttons.Text
					className={styles.button}
					onClick={onOpenIEPEdit}
				>
					{props.iepGoalLinkText}
				</Buttons.Text>
			}
			<Buttons.Contained
				className={styles.buttonClose}
				onClick={onClose}
			>
				Close
			</Buttons.Contained>
		</div>
	);
};
