import React from 'react';
import styles from './card.module.less';
import {join} from '@esgillc/ui-kit/utils';


interface AddCardProps {
	selected?: boolean;
	clicked: () => any;
	optional: boolean;
	className?: string;
}
 
export class AddCard extends React.Component<AddCardProps> {
	get cardStyle(): string {
		const disabled = this.props.selected || styles.disabled;
		return join(styles.card, this.props.className, disabled);
	}

	render() {
		return (
			<div className={this.cardStyle}>
				<div className={styles.content}>
					<div className={join(styles.addBtnWrapper, this.props.selected && styles.active)}>
						<button type='button' onClick={this.props.clicked}>
							<svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M27.5 12.5H22.5V22.5H12.5V27.5H22.5V37.5H27.5V27.5H37.5V22.5H27.5V12.5ZM25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM25 45C13.975 45 5 36.025 5 25C5 13.975 13.975 5 25 5C36.025 5 45 13.975 45 25C45 36.025 36.025 45 25 45Z' fill='#BDBDBD'/>
							</svg>
						</button>
						<span>
							Add Test<br/>
							{!this.props.optional && <>(optional)</>}
						</span>
					</div>
				</div>
				<img width='347px' height='474px' src='https://esgiwebfiles.s3.amazonaws.com/images/selfassess/add-card-template.png'></img>
			</div>
		);
	}
}
