import React from 'react';
import {StudentSort} from '@esgi/core/enums';
import {EventBusDispatcher} from '@esgillc/events';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, ISpecialistGroupBox, IStudentBox, StudentModel} from '../../core/api';
import {HierarchyStudentAdd, HierarchyStudentEdit} from '../../core/events';
import {BoxType} from '../../core/models';
import {StudentEditIcon} from 'modules/hierarchy/components/icons/student-edit-icon';
import {getUser, UserType} from '@esgi/core/authentication';

export class State {
}

export class Props {
	boxes: IBoxInfo[];
	students: IStudentBox;
	groups: ISpecialistGroupBox;
	userID: number;
	groupID: number;
	selectedID: number;
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class Student extends React.Component<Props, State> {

	get thenBySort() {
		if (this.sortBy === 'lastName') {
			return 'firstName';
		}

		return 'lastName';
	}

	get sortBy() {
		return this.props.students.sortBy === StudentSort.LastName ? 'lastName' : 'firstName';
	}


	get items(): ItemProps[] {
		let groupIDs: number[] = [];
		if (this.props.groupID) {
			groupIDs = [this.props.groupID];
		} else if (this.props.userID) {
			groupIDs = this.props.groups.items.filter(t => t.userID === this.props.userID)
				.map(r => r.groupID);
		}

		const students = this.props.students.items.filter(t => t.specialistGroups.some(x => groupIDs.some(a => a === x)));

		function sortBy(sortBy: string, thenBy) {
			return (a: StudentModel, b: StudentModel) => {
				if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
					return -1;
				}

				if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
					return 1;
				}

				if (a[thenBy].toLowerCase() < b[thenBy].toLowerCase()) {
					return -1;
				}

				if (a[thenBy].toLowerCase() > b[thenBy].toLowerCase()) {
					return 1;
				}

				return 0;
			};
		}

		const sortFunction = sortBy(this.sortBy, this.thenBySort);
		return [...students].sort(sortFunction)
			.map(r => {
				return {
					title: `${r.firstName} ${r.lastName}`,
					id: r.studentID,
				};
			});
	}

	render(): any {
		if (!this.props.userID) {
			return null;
		}
		const canView = getUser().canViewStudents;
		const options: BoxOptions = {
			canAdd: canView && this.props.students.canCreate,
			canEdit: canView && this.props.students.canEdit,
			canView,
			editIcon: <StudentEditIcon/>,
			canCreateTooltip: null,
			title: 'All Students',
			canDrag: false,
			boxType: BoxType.PreAssessStudent,
		};

		return <Box
      open={this.props.boxes.filter(t => t.boxType === BoxType.PreAssessStudent && t.open).length > 0}
      scheduledIDs={[]}
      selectedID={this.props.selectedID}
      items={this.items}
      empty={{message: 'You have 0 students'}}
      onDragStart={null}
      onDragEnd={null}
      options={options}
      itemSelected={this.props.onSelected}
      onAddClicked={this.publish.add}
      onEditClicked={this.publish.edit}
      onOpenCloseChanged={this.props.onOpenCloseChanged}
    />;

	}

	publish = {
		add: () => {
			const event = new HierarchyStudentAdd({
				userType: UserType.PA,
				userID: this.props.userID,
				classID: null,
				groupID: this.props.groupID,
				schoolID: null,
				specialistGroupID: this.props.groupID,
			});
			EventBusDispatcher.dispatch(HierarchyStudentAdd, event);
		},
		edit: (id: number) => {
			const student = this.props.students.items.find(s => s.studentID === id);
			if (!student) {
				return;
			}

			const event = new HierarchyStudentEdit({
				studentID: id,
				userID: this.props.userID,
				userType: UserType.PA,
			});
			EventBusDispatcher.dispatch(HierarchyStudentEdit, event);
		},
	};

}
