import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text as TextUi} from '@esgi/ui/typography';

export const IconWrapper = styled(FlexBox, {
	width: 40,
	height: 40,
	borderRadius: 12,
	borderWidth: 1.5,
	borderStyle: 'solid',
	borderColor: '$vivid',
	backgroundColor: '$background',
	alignItems: 'center',
	justifyContent: 'center',
	color: '$primary',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const Text = styled(TextUi, {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});
