import {Class, Student} from '@esgi/main/libs/store';
import {max, isNull} from 'underscore';

export function getClassStudentsIDs({
	studentFromClassID,
	classesList,
	studentID,
}: {
	studentFromClassID: Class['id'] | null;
	classesList: Class[];
	studentID: Student['id'];
}): Student['id'][] {
	if (!isNull(studentFromClassID)) {
		return classesList.find(({id}) => id === studentFromClassID)?.studentIDs ?? [];
	}

	const studentsClasses = classesList.filter(({studentIDs}) => studentIDs.includes(studentID));
	const classWithMaxStudents = max(studentsClasses, ({studentIDs}) => studentIDs.length);

	return typeof classWithMaxStudents === 'number' ? [] : classWithMaxStudents.studentIDs;
}
