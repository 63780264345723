import React, {createRef} from 'react';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {
	CloseIcon,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalManagerRef,
	Title,
} from '@esgi/deprecated/ui-kit/modal';
import {ResultModel} from '../../types';
import {getInfo} from '../../../common/utils';
import {getChartOptions} from './utils';
import {EventBusManager} from '@esgillc/events';
import AddSummaryNoteButton from 'modules/assessments/testing/add-summary-notes/button';
import {AddIEPGoalButton} from 'modules/assessments/testing/iep-goal';
import {TestType} from '@esgi/core/enums';
import {SummaryInfo} from '../../../../general/testing/summary/summary-info';
import {SummaryButtons} from '../../../../general/testing/summary/summary-buttons';
import {SummaryIEP} from '../../../../general/testing/summary/summary-iep';
import {Tabs, TabPanel, Tab} from '@esgillc/ui-kit/tabs-with-panel';
import {getIEPGoal} from '../../../../general/testing/summary/api';
import {IEP_NAME} from '../../../../general/testing/summary/utils';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import {IEPGoalCreated, IEPGoalChanged} from 'modules/forms/iep-goal-form/events';
import {hasIEPLogic} from '../../../../common/utils';
import styles from './styles.module.less';

class State {
	loading: boolean = false;
	iepGoal: any = null;
	activeTab: string = null;
	tabs: Tab[] = null;
}

interface Props {
	result: ResultModel;
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClosed: () => void;
	onExportClickedProp: () => void;
	onTestDetailsClickedProp: () => void;
	onTestHistoryClickedProp: () => void;
	onIEPEdit: () => void;
	onIEPReport: () => void;
}

export default class SessionResultsDialog extends React.PureComponent<Props, State> {
	public readonly state = new State();
	private readonly modalManagerRef: ModalManagerRef = createRef();
	private readonly eventBus = new EventBusManager();

	private get options() {
		const answers = this.getAnswers();
		return getChartOptions(answers, Math.max(...answers.map(a => a.y)));
	}

	public componentDidMount(): void {
		if (!hasIEPLogic()) {
			return;
		}

		this.setState({tabs: this.getTabs()});
		getIEPGoal({
			studentId: this.props.result.studentID,
			testId: this.props.result.testModel.id,
		}).subscribe(
			({iepGoal}) =>
				this.setState({iepGoal}, () => this.setState({tabs: this.getTabs()})),
		);

		this.eventBus.subscribe(
			IEPGoalCreated,
			({goal: iepGoal}) =>
				this.setState({iepGoal}, () => this.setState({tabs: this.getTabs()})),
		);
		this.eventBus.subscribe(
			IEPGoalChanged,
			({goal: iepGoal}) =>
				this.setState({iepGoal}, () => this.setState({tabs: this.getTabs()})),
		);
	}

	public componentWillUnmount() {
		this.props.onClosed();
		this.eventBus.destroy();
	}

	public render() {
		return (
			<Modal modalManagerRef={this.modalManagerRef}>
				<ModalHeader>
					<Title>Test Session Results</Title>
					<CloseIcon onClick={this.closeDialog}/>
				</ModalHeader>
				<ModalBody className={styles.body}>
					<SummaryInfo
						showHeader={false}
						studentName={this.props.result.studentName}
						testName={this.props.result.testModel.rubricName}
					/>
					{this.renderIEP()}
				</ModalBody>
				<ModalFooter>
					<SummaryButtons
						testType={TestType.Rubric}
						hierarchy={this.props.hierarchy}
						subject={this.props.subject}
						onOpenTestDetails={this.testDetailsClicked}
						onOpenTestHistory={this.testHistoryClicked}
						onOpenExport={this.exportClicked}
						onOpenIEPEdit={this.onIEPEdit}
						onOpenIEPReport={this.onIEPReport}
						onClose={this.closeDialog}
					/>
				</ModalFooter>
			</Modal>
		);
	}

	private renderResult() {
		return (
			<>
				<div className={styles.chartHeader}>
					<div className={styles.leftScoreContainer}>
						Score: {this.getScore()}/{this.getTotalPossibleScore()}
					</div>
					<div className={styles.rightScoreContainer}>
						{hasIEPLogic() && !this.state.iepGoal &&
							<>
								<AddIEPGoalButton
									hierarchy={this.props.hierarchy}
									subject={this.props.subject}
									testID={this.props.result.testModel.id}
								/>
								<span>|</span>
							</>
						}
						<AddSummaryNoteButton
							testSessionID={this.props.result.testSessionID}
							note={this.props.result.summaryNotes}
						/>
					</div>
				</div>
				<div className={styles.chartContainer}>
					<HighchartsReact options={this.options}/>
				</div>
			</>
		);
	}

	private renderIEP() {
		if (hasIEPLogic()) {
			return (
				<>
					<Tabs
						items={this.state.tabs}
						active={this.state.activeTab}
						onSelect={({tab}) => this.setState({activeTab: tab.name})}
					/>
					<div className={styles.main}>
						<TabPanel key='name' name='result'>
							{this.renderResult()}
						</TabPanel>
						<TabPanel key={IEP_NAME} name={IEP_NAME}>
							<SummaryIEP goal={this.state.iepGoal}/>
						</TabPanel>
					</div>
				</>
			);
		}

		return (
			<div className={styles.main}>
				{this.renderResult()}
			</div>
		);
	}

	private getTabs() {
		const result = [
			{name: 'result', label: 'Results'},
		];
		if (this.state.iepGoal) {
			result.push({name: IEP_NAME, label: 'IEP Goal'});
		}
		return result;
	}

	private getAnswers() {
		const {testModel, answers} = this.props.result;
		const info = getInfo(testModel, answers);
		return answers.map(a => info.byAnswer(a))
			.sort((a, b) => a.criteria.order - b.criteria.order)
			.map(a => {
				return {
					name: a.criteria.name,
					y: a.level.score,
				};
			});
	}

	private getTotalPossibleScore(): number {
		const {testModel} = this.props.result;
		const maxLevel = testModel.levelModels
			.map(({score}) => score)
			.sort()
			.reverse()[0];
		return testModel.criteriaModels.length * maxLevel;
	}

	private getScore(): number {
		return this.props.result.answers
			.map(({score}) => score)
			.reduce((a, b) => a + b);
	}

	private testDetailsClicked = () => {
		this.modalManagerRef.current.close(this.props.onTestDetailsClickedProp);
	};

	private testHistoryClicked = () => {
		this.modalManagerRef.current.close(this.props.onTestHistoryClickedProp);
	};

	private onIEPEdit = () => {
		this.modalManagerRef.current.close(this.props.onIEPEdit);
	};

	private onIEPReport = () => {
		this.modalManagerRef.current.close(this.props.onIEPReport);
	};

	private exportClicked = () => {
		this.props.onExportClickedProp();
	};

	private closeDialog = () => {
		this.modalManagerRef.current.close(this.props.onClosed);
	};
}
