import {styled} from '@esgi/ui/theme';
import {Box, GridBox, SelectableList} from '@esgi/ui/layout';
import {Cheap as CheapUI} from '@esgi/ui';

export const Container = styled(GridBox, {
	height: '100%',
	gridTemplateRows: 'auto 1fr',
	gap: 20,
	overflow: 'hidden',
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$vivid',
});

export const ListItem = styled(SelectableList.Item, {
	marginTop: 8,
	marginBottom: 8,

	'&:first-child': {
		marginTop: 0,
	},

	'&:last-child': {
		marginBottom: 0,
	},
});

export const Description = styled(GridBox, {
	gap: 20,
});

export const TabsFiltersWrapper = styled(GridBox, {
	gap: '$3',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto 1fr',

	'& > *:last-child': {
		placeSelf: 'end',
	},
});

export const Cheap = styled(CheapUI, {
	variants: {
		counter: {
			true: {
				color: '$primaryVivid',
				backgroundColor: '$primarySurface',
				borderColor: '$primaryMuted',
				marginRight: 6,
			},
		},
	},
});

export const TabWrapper = styled(GridBox, {
	gap: '$3',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto',

	'& > *:last-child': {
		placeSelf: 'end',
	},

	variants: {
		disabled: {
			true: {
				pointerEvents: 'none',
				cursor: 'default',

				'& span': {
					color: '$muted',
				},
				'& [data-state="on"]': {
					backgroundColor: '$background',
					borderColor: '$mild',

					'& > span': {
						color: '$mild !important',
					},
				},
			},
		},
	},
});
