import {ControlValue, ElementStatus, Form, FormControl, FormElement, FormGroup} from '@esgillc/ui-kit/form';
import {Dropdown, Input, OnErrorTooltip, Option} from '@esgillc/ui-kit/form-control';
import {join, useStreamEffect} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import React, {useState} from 'react';
import {DatesPeriodModel, SchoolYearTypeModel, TrackModel} from '../../../../types';
import styles from './styles.module.less';
import {UserType, useUser} from '@esgi/core/authentication';

export type TrackFormType = FormGroup<{
	trackInput: FormControl<string>,
	periods: FormControl<DatesPeriodModel[]>,
	track: FormControl<TrackModel[]>,
	type: FormControl<SchoolYearTypeModel[]>
},
	ControlValue<{
		trackInput: FormControl<string>,
		periods: FormControl<DatesPeriodModel[]>,
		track: FormControl<TrackModel[]>, type: FormControl<SchoolYearTypeModel[]>
	}>>

interface Props {
	selectedTrack: TrackModel;
	schoolYearTypes: SchoolYearTypeModel[];
	tracks: TrackModel[];
	form: TrackFormType;
	isEditing: boolean;
	setEditing: (isEditing: boolean) => void
}

export function AddEditForm({form, selectedTrack, tracks, setEditing, schoolYearTypes}: Props) {
	const [isTrackInputMode, setTrackInputMode] = useState(false);
	const {userType} = useUser();

	useStreamEffect(form.controls.track.onChanged, (v) => {
		if (v.currState.value[0].trackID === 0) {
			setTrackInputMode(true);
			setEditing(false);
			form.controls.trackInput.status = null;
		}
	});
	const handleTrackEditModeClose = () => {
		form.controls.track.value = [selectedTrack];
		setTrackInputMode(false);
		form.controls.trackInput.status = ElementStatus.disabled;
		setEditing(true);
		form.controls.trackInput.value = '';
	};

	const handleEditClick = () => {
		setTrackInputMode(true);
		setEditing(true);
		form.controls.trackInput.status = null;
		form.controls.trackInput.value = form.controls.track.value[0].name;
	};
	return <Form controller={form}>
		<FormElement
			className={!isTrackInputMode && styles.hidden}
			control={form.controls.trackInput}
		>
			<div data-cy={'track-input'}>
				<label className={styles.label}>Track Name</label>
				<div className={styles.inputWithCloseIcon}>
					<Input/>
					<span data-cy={'close-edit-mode-icon'} className={styles.closeIcon} onClick={handleTrackEditModeClose}>&times;</span>
				</div>
				<OnErrorTooltip showOnError={'required'} placement={'top'}>
					Track name is required.
				</OnErrorTooltip>
			</div>
		</FormElement>
		<FormElement className={join(isTrackInputMode && styles.hidden)} control={form.controls.track}>
			<div data-cy={'track-dropdown'}>
				<div className={styles.labelWithIcon}>
					<label className={styles.label}>Track Name</label>
					<svg data-cy={'edit-icon'} onClick={handleEditClick} xmlns='http://www.w3.org/2000/svg' version='1.0'
						viewBox='0 0 148.000000 148.000000' preserveAspectRatio='xMidYMid meet'>
						<g transform='translate(0.000000,148.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
							<path d='M1071 1096 l-41 -42 38 -37 38 -37 40 43 c49 50 48 83 -2 103 -30 12 -33 11 -73 -30z'/>
							<path d='M511 1108 c-84 -19 -163 -86 -203 -173 -21 -46 -23 -63 -23 -225 0 -161 2 -179 23 -226 29 -64 92 -126 157 -156 46 -21 64 -23 225 -23 161 0 179 2 225 23 130 60 185 162 185 345 l0 81 -70 -69 c-64 -64 -70 -73 -70 -111 0 -53 -17 -102 -40 -114 -11 -6 -113 -10 -234 -10 -203 0 -217 1 -236 20 -19 19 -20 33 -20 236 0 121 4 223 10 234 16 29 66 40 184 40 l112 0 62 63 63 64 -113 6 c-140 8 -181 8 -237 -5z'/>
							<path d='M798 823 c-130 -131 -150 -155 -167 -205 l-19 -56 51 15 c46 13 69 31 207 168 l154 154 -34 36 c-19 19 -36 35 -40 35 -3 0 -71 -66 -152 -147z'/>
						</g>
					</svg>
				</div>

				<Dropdown optionName={'name'}>
					{tracks.map(item => <Option key={item.trackID} value={item}>{item.name}</Option>)}
					<Option value={{trackID: 0, name: 'Add new track'}}>Add new track</Option>
				</Dropdown>
			</div>

		</FormElement>


		<FormElement control={form.controls.type}>
			<div data-cy={'type-dropdown'}>
				<label className={styles.label}>Track Type</label>
				<Dropdown optionName={'name'}>
					{schoolYearTypes.map(item => <Option className={join(item.isDisabled && styles.disabled)}
					                                     key={item.id} value={item}>{item.name}</Option>)}
				</Dropdown>
				{userType === UserType.D && <OnHoverTooltip
					message='Please contact support@esgisoftware.com if you would like to create a different track type'><span
					className={styles.legend}>Why can't I select some of the options?</span></OnHoverTooltip>}
			</div>

		</FormElement>
	</Form>;
}
