import {UntestedStudentsReport, GroupingType} from '@esgi/main/features/untested-students-report';
import {SettingsBox} from './index.styled';

interface Props {
	groupingValue: GroupingType,
	onGroupingValueChange: (value: GroupingType) => void;
	showAllStudent: boolean,
	onShowAllStudentsChange: (value: boolean) => void;
	lastWeek: number;
	onLastWeekChange: (value: number) => void;
}

export function Settings(props: Props) {
	return (
		<SettingsBox align='center'>
			<UntestedStudentsReport.GroupingControl
				value={props.groupingValue}
				onChange={props.onGroupingValueChange}
			/>
			<UntestedStudentsReport.ShowAllStudentsControl
				value={props.showAllStudent}
				onChange={props.onShowAllStudentsChange}
			/>
			<UntestedStudentsReport.LastWeekControl
				value={props.lastWeek}
				onChange={props.onLastWeekChange}
			/>
		</SettingsBox>
	);
}
