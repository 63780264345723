import {PropsWithChildren} from 'react';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';

type FiltersContentBlockProps = PropsWithChildren<{
	className?: string | undefined;
}>;

export function ReportFiltersContent({className, children}: FiltersContentBlockProps) {
	return <div className={join(styles.filtersContent, className)}>{children}</div>;
}
