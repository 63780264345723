import {buildSkeletonAnimationStyles} from '../../../skeleton';
import {styled} from '../../../theme';

export const Svg = styled('svg', {
	variants: {
		skeleton: {
			true: {
				...buildSkeletonAnimationStyles('background'),
				borderRadius: 10,
			},
		},
	},
});
