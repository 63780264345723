import {SelectableList} from '@esgi/ui/layout';
import {SelectableDraggableItem, getListValue} from '../../../../kit';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectName} from '../subject-name';

type Props = {
	selectedSubjectId: SubjectTab['id'] | null;
	onDragEnd: (groupState: SubjectTab[]) => void;
	subjects: SubjectTab[];
};

export function SelectableListRearrangeable({onDragEnd, subjects, selectedSubjectId}: Props) {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedSubjectId)}>
				<SelectableList.GroupDroppable onDragEnd={onDragEnd} droppableItems={subjects}>
					{subjects.map(({name, level, id, hidden}, index) => (
						<SelectableDraggableItem value={String(id)} index={index} draggableId={String(id)} key={id}>
							<SubjectName subjectLevel={level} subjectName={name} hidden={hidden} />
						</SelectableDraggableItem>
					))}
				</SelectableList.GroupDroppable>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
