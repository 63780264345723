import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AlertBody} from './components/body';
import {menuItems} from './constants';
import {FileType} from '../../types';
import {Dispatch} from 'react';
import {Reports} from '@esgi/main/kits/reports';
import {Settings} from './components/settings';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {isNull} from 'underscore';
import {useStudentDetailService} from '../../hooks';

type Props = {
	onClose: VoidFunction;
	onDownload: Dispatch<FileType>;
};

export function PreviewDialog(props: Props) {
	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, props.onClose);
	const service = useStudentDetailService();
	const report = useBehaviorSubject(service.report$);

	return (
		<Alert
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.neutral}
			css={{
				'& [data-alert-content]': {
					maxHeight: 'calc(100% - 40px)',
					overflow: 'hidden',
					width: 'max-content',
					maxWidth: '100%',
					gridTemplateRows: 'auto auto 1fr auto',
				},
			}}
		>
			<Alert.Header withBacklight={false} onCloseIconClick={onClose}>
				<Text size='large'>Student Detail Report</Text>
			</Alert.Header>
			<Settings />
			<AlertBody />
			<Alert.Footer>
				{!isNull(report) && report.students.length > 0 && <Reports.DownloadButton menuItems={menuItems} onClick={(value) => props.onDownload(value as FileType)} />}
			</Alert.Footer>
		</Alert>
	);
}
