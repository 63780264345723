import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TooltipContainer = styled(GridBox, {
	gap: '$3',
	width: 212,
	padding: 12,
	borderRadius: 8,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$tertiaryMuted',
	backgroundColor: 'rgba(255, 251, 250, 0.80)',
	boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
});
