import {AppliedTo, Data, Library, Multigenerate, Parse, UserList, ParentConferencer, AiAnalysis, IconComponent} from '@esgi/ui';
import {NavigationPageKey} from './types';

export const menuLabelByRoute: Record<keyof NavigationPageKey, string> = {
	home: 'Home',
	reports: 'Reports',
	testExplorer: 'Test Explorer',
	activities: 'Activities',
	assignments: 'Assignments',
	studentManager: 'Student Manager',
	parentConferencer: 'Parent Conferencer',
	aiAnalysis: 'AI Analysis',
};

export const menuIconByPath: Record<keyof NavigationPageKey, IconComponent> = {
	home: Multigenerate,
	reports: Data,
	testExplorer: Library,
	activities: AppliedTo,
	assignments: Parse,
	studentManager: UserList,
	parentConferencer: ParentConferencer,
	aiAnalysis: AiAnalysis,
};
