import {GridBox} from '@esgi/ui/layout';

import {formatTimes} from '../utils';
import {useClassData} from '../../hooks';
import {Delta, NumberRow} from '../../kit';
import {TimePerQuestionService} from '../service';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	service: TimePerQuestionService,
};

export function ClassView(props: Props) {
	const {loaded, data} = useClassData(props.service, props.testIDs, props.studentIDs);

	const {lastWeekAverage, allTimeAverage, delta, deltaType} = formatTimes(data);

	return <GridBox css={{height: 80}} gapY='3' flow='row'>
		<NumberRow text={lastWeekAverage} label='This Week Average' skeleton={!loaded}>
			<Delta type={deltaType} skeleton={!loaded} value={delta} unit=''>
				Since Last Week
			</Delta>
		</NumberRow>
		<NumberRow text={allTimeAverage} label='All-time Average' skeleton={!loaded}/>
	</GridBox>;
}