import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SsoTracker} from '@esgi/core/tracker';
import {TeacherScreenClient} from './teacher-client';

export class StudentScreenService extends BaseService {

	public onJoined = new Subject<void>();
	public code = new BehaviorSubject('');
	public expireTime = new BehaviorSubject('');
	public isLoaded = new BehaviorSubject(false);

	private timerID = 0;
	private teacherScreenClient = new TeacherScreenClient();

	public init(userID: number) {
		this.teacherScreenClient.shared.pipe(takeUntil(this.destroy$)).subscribe((data) => {
			const {code} = data;
			if (code) {
				this.code.next(code);

				this.teacherScreenClient.studentJoined.pipe(takeUntil(this.destroy$)).subscribe(() => {
					clearInterval(shareScreenTimer);
					this.onJoined.next();
				});

				const shareScreenTimer = window.setInterval(() => {
					const expiredTime = 5 * 60 * 1000;
					const time = new Date(expiredTime - (Date.now() - data.createdTime));
					const seconds = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
					const minutes = '0' + time.getMinutes();
					this.expireTime.next(minutes + ':' + seconds);

					if (minutes === '00' && seconds === '00') {
						this.code.next('');
						this.expireTime.next('');
						clearInterval(shareScreenTimer);
					}
				}, 1000);

				this.timerID = shareScreenTimer;
			}
		});

		this.isLoaded.next(true);
		this.teacherScreenClient.isExpired(userID);

	}

	public createShareSession(userID: number) {
		SsoTracker.trackEvent({
			trackingEvent: 'Get2SCode',
		});
		this.teacherScreenClient.share(userID);
		window.localStorage.shareScreenSessionCreated = true;
	}

	public destroy() {
		super.destroy();
		this.teacherScreenClient?.dispose();
		clearInterval(this.timerID);
	}
}
