export class RegistrationTemplate {
	public static Render() {
		return <div data-bind='afterRender: true'>
            <div className='registration-container'>
                <main data-bind='if: currentFlow!==null'>
                    <div data-bind='render: currentFlow'>

                    </div>
                </main>
            </div>
        </div>;
	}
}
export class DefaultFooterTemplate {
	public static Render() {
		return <div className='actions'>
            <button className='btn cancel-btn' data-bind='click:cancel'>CANCEL</button>
            <button className='btn next-btn' data-bind='click:next'>CREATE TRIAL</button>
        </div>;
	}
}
