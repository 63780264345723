import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const FormInfoBox = styled(GridBox, {
	width: '100%',
	gap: '$5',

	'& form': {
		display: 'grid',
		gap: '$2',

		'& > div': {
			marginBottom: 'unset',
		},
	},
});

export const CheckboxContainer = styled(GridBox, {
	paddingTop: 5,
	paddingBottom: 5,
	paddingLeft: 8,
	paddingRight: 8,
	borderRadius: 8,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	backgroundColor: '$vivid',
});

export const DeleteButton = styled(Button, {
	alignItems: 'center',

	'&:not(:hover)': {
		color: '$negative',
		fill: '$negative',
	},
});
