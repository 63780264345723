import {observable, Form, Renderable} from '@esgi/deprecated/knockout';

export abstract class Step extends Form {
	@observable()
	title: string;

	@observable()
	enabled = false;

	@observable()
	hasBeenFinished = false;

	load() {
		return Renderable.resolvedDeferred;
	}
}
