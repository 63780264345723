import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {GroupItem, Student as StudentType, Subject as SubjectType} from '../../../../../../models';
import {ChangeEvent, useCallback, useState} from 'react';
import FormService from '../../../../../../services/form-service';

export function useReportSettings(formService: FormService) {
	const settingsService = formService.settingsService;
	const includeSessionOption = useBehaviorSubject(settingsService.includeSessionOption);
	const includeUntestedQuestionsOption = useBehaviorSubject(settingsService.includeUntestedQuestionsOption);
	const showSessionDateOption = useBehaviorSubject(settingsService.showSessionDateOption);
	const includeQuestionNotesOption = useBehaviorSubject(settingsService.includeQuestionNotesOption);
	const includeSummaryNotesOption = useBehaviorSubject(settingsService.includeSummaryNotesOption);
	const printInColorOption = useBehaviorSubject(settingsService.printInColorOption);
	const includeMessageOption = useBehaviorSubject(settingsService.includeMessageOption);
	const hasGradeScales = useBehaviorSubject(settingsService.hasGradeScales);
	const includeGradeOption = useBehaviorSubject(settingsService.includeGradeOption);
	const teacher = useBehaviorSubject(settingsService.teacher);
	const groupType = useBehaviorSubject(settingsService.groupType);
	const groupItems = useBehaviorSubject(settingsService.groupItems);
	const selectedGroupItem = useBehaviorSubject(settingsService.selectedGroupItem);
	const selectedStudent = useBehaviorSubject(settingsService.selectedStudent);
	const students = useBehaviorSubject(settingsService.students);
	const subjects = useBehaviorSubject(settingsService.subjects);
	const selectedSubject = useBehaviorSubject(settingsService.selectedSubject);
	const model = useBehaviorSubject(formService.model);
	const [showPLE, setShowPLE] = useState(false);

	const onGroupChange = useCallback((v: GroupItem[]) => {
		settingsService.selectedGroupItemChanged(v[0]);
		settingsService.changeHierarchy('groupItemsSelect');
	}, []);

	const onStudentChange = useCallback((v: StudentType[]) => {
		settingsService.selectedStudent.next(v[0]);
		settingsService.changeHierarchy('studentsSelect');
	}, []);

	const onSubjectChange = useCallback((v: SubjectType[]) => {
		settingsService.selectedSubject.next(v[0]);
		settingsService.changeHierarchy('subjectsSelect');
	}, []);

	const onIncludeSummaryChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.includeSummaryNotesOption.next(e.target.checked);
		settingsService.optionChanged(e, teacher.includeSummaryNotesOption.name);
	}, [teacher.includeSummaryNotesOption.name]);

	const onPrintInColorChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.printInColorOption.next(e.target.checked);
		settingsService.optionChanged(e, teacher.printInColorOption.name);
	}, [teacher.printInColorOption.name]);

	const onIncludeMessageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.includeMessageOption.next(e.target.checked);
		settingsService.optionChanged(e, teacher.includeMessageOption.name);
	}, [teacher.includeMessageOption.name]);

	const onIncludeQuestionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.includeQuestionNotesOption.next(e.target.checked);
		settingsService.optionChanged(e, teacher.includeQuestionNotesOption.name);
	}, [teacher.includeQuestionNotesOption.name]);
	const onIncludeGradeOptionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.includeGradeOption.next(e.target.checked);
		settingsService.optionChanged(e, teacher.includeGradeOption.name);
	}, [teacher.includeUntestedQuestionsOption.name]);

	const viewMessageClicked = useCallback(() => {
		setShowPLE(true);
	}, []);

	const onIncludeUntestedQuestionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.includeUntestedQuestionsOption.next(e.target.checked);
		settingsService.optionChanged(e, teacher.includeUntestedQuestionsOption.name);
	}, [teacher.includeUntestedQuestionsOption.name]);

	const onShowSessionDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.showSessionDateOption.next(e.target.checked);
		settingsService.optionChanged(e, teacher.showSessionDateOption.name);
	}, [teacher.showSessionDateOption.name]);

	const includeSessionClicked = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		settingsService.includeSessionOption.next(e.target.checked);
		if (!includeSessionOption) {
			formService.selectedDate.next(null);
			formService.reloadTests();
		}
	}, []);

	const closePLE = useCallback(() => setShowPLE(false), []);

	return {
		teacher, groupType, groupItems, selectedGroupItem,
		selectedStudent, students, subjects, selectedSubject, onGroupChange, onStudentChange, onSubjectChange,
		includeQuestionNotesOption, includeSummaryNotesOption, printInColorOption, includeMessageOption,
		hasGradeScales, includeGradeOption, onIncludeSummaryChange, onPrintInColorChange, onIncludeMessageChange,
		onIncludeQuestionChange, viewMessageClicked, includeSessionOption,
		includeUntestedQuestionsOption, showSessionDateOption, onIncludeUntestedQuestionChange, onShowSessionDateChange,
		includeSessionClicked, onIncludeGradeOptionChange, showPLE, model, closePLE,
	};
}
