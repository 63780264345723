import React from 'react';
import {userStorage} from '@esgi/core/authentication';
import {PinPopup} from '../../pin-popup/pin-popup';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import StarredName from '../starred-name/starred-name';
import CloseButton from '../close-button/close-button';

import styles from './finish-test-session-modal.module.less';
import {Loader} from '@esgillc/ui-kit/loader';

class State {
	showLockModal: boolean = false;
}

interface FinishTestSessionModalProps {
	studentName: string;
	closed: () => any;
	showLoader: boolean;
}


export default class FinishTestSessionModal extends React.Component<FinishTestSessionModalProps, State> {
	public readonly state = new State();

	private modalManagerRef: ModalManagerRefObject = React.createRef();

	private onClose() {
		if (userStorage.get().lockScreen) {
			this.setState({showLockModal: true});
		} else {
			this.modalManagerRef.current.close(this.props.closed);
		}
	}

	render() {
		return <>
			<Modal containerClassName={styles.modalWrapper} className={styles.modal}
			       modalManagerRef={this.modalManagerRef}>
				<Loader show={this.props.showLoader}/>
				<Modal.Header className={styles.modalHeader}>
					<div className={styles.hidden}></div>
					<div className={styles.starredNameContainer}>
						<StarredName name={this.props.studentName}/>
					</div>
					<CloseButton closed={() => this.onClose()}/>
				</Modal.Header>
				<Modal.Body className={styles.modalBody}>
					<div className={styles.intro}>
						<span>Success!
							You finished!
						</span>
						<div className={styles.snakeContainer}>
							<img className={styles.snake} alt={'Snake'}
							     src='//s3.amazonaws.com/esgiwebfiles/images/snake-new.png'/>
						</div>

					</div>
				</Modal.Body>
			</Modal>
			{this.state.showLockModal && <PinPopup onCancel={() => this.setState({showLockModal: false})}
			                                       onSuccess={() => this.setState({showLockModal: false}, () => this.modalManagerRef.current.close(this.props.closed))}/>}
		</>;
	}
}
