import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {useStudentsFilteredBySubject} from '../../hooks/use-students-filtered-by-subject';

export function StudentsContent() {
	const {students} = StudentsPanel.Tabs.Students.useStudentsContext();

	const filteredStudents = useStudentsFilteredBySubject(students);
	const {
		selected: {studentId: selectedStudentId},
		selectStudent,
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			{filteredStudents.length ? (
				<StudentsPanel.Lists.SelectableList.Single
					items={filteredStudents}
					onItemClick={(studentId) => selectStudent(studentId)}
					value={selectedStudentId}
				>
					{({item: {firstName, lastName}}) => (
						<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
					)}
				</StudentsPanel.Lists.SelectableList.Single>
			) : (
				<StudentsPanel.Tabs.EmptyTabContent size='full' templateText='No students to display' />
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
