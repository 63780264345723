import {Button, ContextMenu, Download} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {TestInfo} from '../../../../../../../types';
import {useCallback, useState} from 'react';
import {StudentInfo} from '../../../../../../../class-group/types';
import {getFileType} from './utils';
import {ReportFormat, ReportType} from './types';
import {exportAllMap} from './constants';
import {useService} from '@esgi/core/service';
import {TestSessionDetailsService} from './service';

type Props = {
	testInfo: TestInfo;
	students: StudentInfo[];
	classID: number | null;
	testID: number;
};

export function ExportAll({testInfo, students, classID, testID}: Props) {
	const [isExportAllOpen, setIsExportAllOpen] = useState(false);
	const service = useService(TestSessionDetailsService);

	const getExportOptions = useCallback((format: ReportFormat, type: ReportType) => {
		const studentsID = students
			.filter(x => x.testSessionsInfo.testSessions.filter(x => !x.isDeleted).length > 0)
			.map(x => {
				return {
					studentID: x.id,
					currentSessionID: type === ReportType.All ? 0 : x.testSessionsInfo.testSessions.filter(x => !x.isDeleted)[0].id,
				};
			});
		return {
			format: getFileType(format),
			classID: classID,
			specialistGroupID: 0,
			studentsID,
			testID: testID,
			subject: testInfo.subjectName,
		};
	}, [students, testInfo, classID, testID]);

	const startBackgroundDownload = useCallback((format: ReportFormat, type: ReportType) => {
		const options = getExportOptions(format, type);
		service.startBackgroundGeneration(options);
	}, [getExportOptions, service]);

	return (
		<ContextMenu.Root onOpenChange={setIsExportAllOpen} open={isExportAllOpen} data-cy='export-all-menu-root' modal={false}>
			<ContextMenu.Trigger dataCy='export-all-menu-trigger'>
				<Button color='tertiary' dataCy='export-all-menu-trigger-button'>
					<Download />
					<Text size='medium' bold>
						Export All
					</Text>
				</Button>
			</ContextMenu.Trigger>
			<ContextMenu.Content dataCy='export-all-menu-content' align='end'>
				<ContextMenu.Group>
					{exportAllMap.map(({itemText, dataCy, format, type}, index) => (
						<ContextMenu.Item key={index} onClick={() => startBackgroundDownload(format, type)} disabled={false} dataCy={dataCy}>
							<Text size='medium' data-cy={`${dataCy}-label`}>{itemText}</Text>
						</ContextMenu.Item>
					))}
				</ContextMenu.Group>
			</ContextMenu.Content>
		</ContextMenu.Root>
	);
}
