import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TabDescriptionBox = styled(GridBox, {
	maxWidth: 280,
	height: 'max-content',
	variants: {
		isTablet: {
			true: {
				maxWidth: '100%',
			},
		},
	},
});
