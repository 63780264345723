import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {SettingsService} from 'shared/modules/reports/flash-cards/settings-form/services/settings-service';
import {RequestModels} from 'shared/modules/reports/flash-cards/settings-form/api/models';
import {
  FlashCardsHierarchyService,
} from 'shared/modules/reports/flash-cards/settings-form/services/flash-cards-hieararchy-service';
import {Api} from 'shared/modules/reports/flash-cards/settings-form/api/flash-cards-api';
import {isIOS} from '@esgillc/ui-kit/utils';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import SortBy = Models.SortBy;
import {tap} from 'rxjs/operators';
import { DateTools } from 'global/utils/date-utils';

export class TestsService extends BaseService {
	private _testsData$: BehaviorSubject<Models.Test[]> = new BehaviorSubject<Models.Test[]>([]);
	private _resultButtonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private _cacheKey: string;
	private _testsCache: { [key: string]: Models.Test[] } = {};
	private _api: Api;
	private _isIOS: boolean = isIOS();
	private _loading$: Subject<boolean> = new Subject<boolean>();

  private _sortBy$: BehaviorSubject<SortBy> = new BehaviorSubject<SortBy>(SortBy.TestOrder);
	private _isInit: boolean;

	constructor(private hierarchyService: FlashCardsHierarchyService, private settingsService: SettingsService, private hierarchy: HierarchySnapshot) {
		super();
		this._api = new Api();
	}

	private server =
		{
			init: (selectedItems: Models.InitModel) => {
				let requestModel = (selectedItems)
					? new RequestModels.Settings.Init(selectedItems.selectedGroupItemID,
						selectedItems.selectedStudentID,
						selectedItems.subjectID,
						selectedItems.subjectType,
						this.hierarchy,
					)
					: null;

				return this._api.settingsInit(requestModel).pipe(tap(r => {
					let tests: Models.Test[];
					let printOutOption = 'IncorrectOnly';

					if (r.printOutOption) {
						printOutOption = (selectedItems && selectedItems.selectedTestID)
							? 'IncorrectOnly'
							: r.printOutOption;
					}

					const model = Models.Init.FromResponse(r);
					model.selectedSubjectId = requestModel.SubjectID;
					const cardsNumber = r.user.cardsPerPage;

					let result = false;
					const teacher = model.user;
					const testResultsCorrectVerbiage = model.testResultsCorrectVerbiage;
					const testResultsIncorrectVerbiage = model.testResultsIncorrectVerbiage;
					const incorrectVerbiageColumnHeader = this.getIncorrectVerbiageColumnHeader(testResultsIncorrectVerbiage);

					let groupItems: any;
					let groupType: string;
					let selectedGroupItem: Models.GroupItem;
					let students: Models.Student[];
					let selectedStudent: Models.Student;
					if (model.groupItems && model.selectedGroupItem) {
						groupItems = model.groupItems.sort((a, b) => {
							if (a.name < b.name) {
								return -1;
							}
							if (a.name > b.name) {
								return 1;
							}
							return 0;
						});
						groupType = model.groupItems && model.groupItems.length > 0 ? model.groupItems[0].itemType : '';
						selectedGroupItem = model.selectedGroupItem;
						students = model.selectedGroupItem.students;
						selectedStudent = model.selectedGroupItem.selectedStudent;
						result = true;
					}
					if (this._isIOS) {
						window.addEventListener('orientationchange', this.setVerbiageColumnHeader);
					}

					this.hierarchyService.init(
						students,
						selectedStudent,
						teacher,
						groupItems,
						groupType,
						selectedGroupItem,
						model.selectedGroupItem?.selectedStudent?.studentId ?? 0,
						model.selectedSubjectId,
						model.subjects,
					);

					this.settingsService.init(
						printOutOption,
						cardsNumber,
						model.tracks,
						testResultsCorrectVerbiage,
						testResultsIncorrectVerbiage,
						incorrectVerbiageColumnHeader,
					);

					if(result){
						let cacheKey = this.getCacheKeyBase(r.selectedGroupItemID, r.selectedStudentID, requestModel.SubjectID);
						tests = Models.Test.FromResponseArray(r.tests);
						this.setTests(cacheKey, tests);
						this._isInit = true;
					}
				}));
			},
			loadSubjects: (selectedStudentId: number) => {
				const student = this.hierarchyService.students.find(x => x.studentId == selectedStudentId);
				const reloadTestsModel = new Models.ReloadTestsModel();
				reloadTestsModel.student = student;

				if (!this.hierarchyService.subjectsCache[selectedStudentId]) {
					this.setLoading(true);
					const subjectRequest = new RequestModels.Settings.FetchSubject ();
					subjectRequest.Hierarchy = this.hierarchy;
					this._api.getSubjects(subjectRequest).subscribe(r => {
						this.hierarchyService.subjectsCache[selectedStudentId] = Models.SubjectModel.FromResponseArray(r.subjects);
						this.hierarchyService.setSubjects(this.hierarchyService.selectedSubject.id, selectedStudentId);
						this.reloadTests(reloadTestsModel);
					});
				} else {
					this.hierarchyService.setSubjects(this.hierarchyService.selectedSubject.id, selectedStudentId);
					this.reloadTests(reloadTestsModel);
				}
			},
			fetchHierarchy: (requestData: RequestModels.Settings.FetchHierarchy, reloadTestsModel: Models.ReloadTestsModel) => {
				let cacheKey = this.getCacheKeyBase(reloadTestsModel.groupItem.id, reloadTestsModel.student.studentId, reloadTestsModel.subject.id);
				this.setLoading(true);
				return this._api.fetchHierarchy(requestData).subscribe(r => {
					const tests = Models.Test.FromResponseArray(r.tests);
					this.setTests(cacheKey, tests);
					this.setLoading(false);
				});
			},
			fetchSessionHierarchy: (requestData: RequestModels.Settings.FetchSessionHierarchy, reloadTestsModel: Models.ReloadTestsModel) => {
				const cacheKey: string = Object.keys(this._testsCache).find(key => !!this._testsCache[key].find(test => test.id === requestData.Tests[0].TestID));
				const tests = this.getCache(cacheKey);
				this.setLoading(true);
				return this._api.fetchSessionHierarchy(requestData).subscribe(r => {
					const updatedTests = [];
					const responseTests = Models.Test.FromResponseArray(r.tests);
					tests.forEach(t => {
						const foundTest = responseTests.find(rt => rt.id === t.id);
						if (!foundTest) {
							return;
						}
						const updTest = Object.assign({}, t);
						updTest.correct = foundTest.correct;
						updTest.incorrect = foundTest.incorrect;
						updTest.untested = foundTest.untested;
						updTest.currentDate = foundTest.currentDate;
						updTest.dataExists = foundTest.dataExists;
						updatedTests.push(updTest);
						const key = this.getSessionCacheKey(reloadTestsModel, updatedTests);
						this.setTests(key, updatedTests);
					});
					this.setLoading(false);
				});
			},
			fetchTestSession: (requestData: RequestModels.Settings.FetchSessionHierarchy, m: Models.ReloadTestsModel) => {
				this.setLoading(true);

				return this._api.fetchSessionHierarchy(requestData).subscribe(r => {
					const idx = this.tests.findIndex(t => t.id === r.tests[0].id);
					const updateTests = this.tests;
					const responseTest = Models.Test.FromResponseArray(r.tests)[0];
					updateTests[idx].untested = responseTest.untested;
					updateTests[idx].incorrect = responseTest.incorrect;
					updateTests[idx].correct = responseTest.correct;
					updateTests[idx].currentDate = m.date;
					const tests = updateTests;
					const key = this.getSessionCacheKey(m, tests);
					this.setTests(key, tests);
					this.setLoading(false);
				});
			},
			fetchSubjectSessionHierarchy: (requestData: RequestModels.Settings.FetchSubjectSessionHierarchy, reloadTestsModel: Models.ReloadTestsModel) => {
				this.setLoading(true);
				return this._api.fetchSubjectSessionHierarchy(requestData).subscribe(r => {
					const tests = Models.Test.FromResponseArray(r.tests);
					const key = this.getSessionCacheKey(reloadTestsModel, tests);
					this.setTests(key, tests);
					this.setLoading(false);
				});
			},
		}

	private updateTests(tests: Models.Test[]) {
		this._testsData$.next(tests);
		this._resultButtonDisabled$.next(this.buttonDisabled);
	}

	private setLoading(value: boolean) {
		if (this.settingsService.selectedItems.selectedTestID && !value) {
			return;
		}
		this._loading$.next(value);
	}

	private setTests(cacheKey: string, tests: Models.Test[]) {
		this.updateTestsSelection(tests);
		this._cacheKey = cacheKey;
		this.setCache(cacheKey, tests);

		this.updateTests(tests);
	}

	private selectByPrintout(test: Models.Test) {
		let isSelected: boolean;
		if (this.settingsService.printOutOption === 'IncorrectUntested') {
			isSelected = (test.incorrect + test.untested) > 0;
		} else 	if (this.settingsService.printOutOption === 'FullSet') {
			isSelected = (test.correct + test.incorrect + test.untested) > 0;
		} else {
			isSelected = test.incorrect > 0;
		}

		return isSelected;
	}

	private setMax(test: Models.Test) {
		let max = 0;
		if (this.hierarchyService.selectedStudent?.studentId === 0) {
			max = test.questionsCount;
		} else {
			switch (this.settingsService.printOutOption) {
				case 'IncorrectOnly':
					max = test.incorrect;
					break;
				case 'IncorrectUntested':
					max = test.incorrect + test.untested;
					break;
				case 'FullSet':
					max = test.correct + test.incorrect + test.untested;
					break;
			}
		}
		return max;
	}

	private reloadHierarchy(reloadTestsModel: Models.ReloadTestsModel) {
		let hierarchy = this.getHierarchy(reloadTestsModel);
		hierarchy.Hierarchy = this.hierarchy;

		this.server.fetchHierarchy(hierarchy, reloadTestsModel);
	}

	private getHierarchy(reloadTestsModel: Models.ReloadTestsModel): RequestModels.Settings.FetchHierarchy {
		let result = new RequestModels.Settings.FetchHierarchy();
		result.SubjectID = reloadTestsModel.subject.id;
		result.SubjectType = reloadTestsModel.subject.subjectType;
		result.Students = [];

		if (reloadTestsModel.student.studentId === 0) {
			reloadTestsModel.groupItem.students.forEach((student) => {
				if (student.studentId !== 0) {
					result.Students.push({StudentID: student.studentId, GradeLevelID: student.gradeLevelId});
				}
			});
		} else {
			result.Students.push({
				StudentID: reloadTestsModel.student.studentId,
				GradeLevelID: reloadTestsModel.student.gradeLevelId,
			});
		}

		return result;
	}

	private reloadSessionHierarchy(reloadTestsModel: Models.ReloadTestsModel) {
		if (reloadTestsModel.subjectChanged) {
			const sessionSubjectHierarchy: RequestModels.Settings.FetchSubjectSessionHierarchy = {...this.getHierarchy(reloadTestsModel), TestDate: this.settingsService.sessionDate};
			this.server.fetchSubjectSessionHierarchy(sessionSubjectHierarchy, reloadTestsModel);
		} else{
			const sessionHierarchy = this.sessionHierarchy(reloadTestsModel);
			this.server.fetchSessionHierarchy(sessionHierarchy, reloadTestsModel);
		}
	}

	private getCacheKey(reloadTestsModel: Models.ReloadTestsModel) {
		return reloadTestsModel.includeSessionOption &&
			this.getSessionCacheKey(reloadTestsModel, this.tests) ||
			this.getCacheKeyBase(reloadTestsModel.groupItem?.id,
				reloadTestsModel.student?.studentId,
				reloadTestsModel.subject?.id);
	}

	private getSessionCacheKey(reloadTestsModel: Models.ReloadTestsModel, tests: Models.Test[]) {
		let key = this.getCacheKeyBase(
			reloadTestsModel.groupItem?.id,
			reloadTestsModel.student?.studentId,
			reloadTestsModel.subject?.id);
		tests.forEach(t => key += '_' + t.id + ':' + t.currentDate);
		return key;
	}

	private getCacheKeyBase(groupItemId: number, studentId: number, subjectId: number) {
		let key = '';

		if (groupItemId !== undefined) {
			key += groupItemId.toString() + '_';
		}
		if (studentId !== undefined) {
			key += studentId.toString() + '_';
		}

		if (subjectId !== undefined) {
			key += subjectId.toString();
		}

		return key;
	}

	private sessionHierarchy(m: Models.ReloadTestsModel): RequestModels.Settings.FetchSessionHierarchy {
		const result: RequestModels.Settings.FetchSessionHierarchy = {
			Tests: [],
			StudentIDs: [],
		};
		this.tests.forEach(t => {
			result.Tests.push({
				TestDate: DateTools.toServerString(new Date(t.currentDate)),
				TestID: t.id,
				QuestionsCount: t.questionsCount,
			});
		});
		result.StudentIDs = [];

		if (m.student.studentId === 0) {
			m.groupItem.students.forEach((student) => {
				result.StudentIDs.push(student.studentId);
			});
		} else {
			result.StudentIDs.push(m.student.studentId);
		}
		return result;
	}

	private setCache(cacheKey: string, tests: Models.Test[]) {
		this._testsCache[cacheKey] = JSON.parse(JSON.stringify(tests)) as Models.Test[];
	}

	private getCache(cacheKey: string) {
		const tests = this._testsCache[cacheKey];
		if (tests){
			return JSON.parse(JSON.stringify(this._testsCache[cacheKey])) as Models.Test[];
		}
	}

	private reloadTest(test: { id: number, date: string, questionsCount: number }) {
		const m = new Models.ReloadTestsModel();
		m.groupItem = this.hierarchyService.selectedGroupItem;
		m.student = this.hierarchyService.selectedStudent;
		m.subject = this.hierarchyService.selectedSubject;
		m.date = test.date;
		const request = this.getTestSessionRequest(test);

		this.server.fetchTestSession(request, m);
	}

	private getTestSessionRequest(test: { id: number, date: string, questionsCount: number }): RequestModels.Settings.FetchSessionHierarchy {
		const result: RequestModels.Settings.FetchSessionHierarchy = {Tests: [], StudentIDs: []};
		result.Tests = [{TestID: test.id, TestDate: DateTools.toServerString(new Date(test.date)), QuestionsCount: test.questionsCount}];

		if (this.hierarchyService.selectedStudent.studentId === 0) {
			this.hierarchyService.selectedGroupItem.students.forEach((student) => {
				result.StudentIDs.push(student.studentId);
			});
		} else {
			result.StudentIDs.push(this.hierarchyService.selectedStudent.studentId);
		}
		return result;
	}

	private setVerbiageColumnHeader = () => {
		this.settingsService.updateVerbiageColumnHeader(this.getIncorrectVerbiageColumnHeader(this.settingsService.testResultsIncorrectVerbiage));
	}

	private getIncorrectVerbiageColumnHeader(testResultsIncorrectVerbiage: string) {
		return ((this.isVerticalOrientation && this.settingsService.includeSessionOption) ? '' : 'Number of ') + testResultsIncorrectVerbiage + ' items to print';
	}

	private updateTestsSelection(tests: Models.Test[]) {
		tests.map(test => test.selected = this.selectByPrintout(test));
		tests.map(test => test.max = this.setMax(test));
		tests.map(test => test.disabled = this.setDisabled(this.setMax(test)));
	}

	private setDisabled(max: number) {
		return max === 0 && this.settingsService.printOutOption !== 'FullSet';
	}

	private printItemsValid() {
		return !this.tests.filter(x => Number.isNaN(x.printItems)).length;
	}

	private get buttonDisabled() {
		return this.selectedTests.length === 0 || !this.printItemsValid();
	}

	private get selectedTests(): { TestID: number, Max?: number, TestDate: string }[] {
		return this.tests.filter(item => item.selected &&
			(this.settingsService.printOutOption === 'IncorrectOnly'
				? item.incorrect
				: this.settingsService.printOutOption === 'FullSet'
					? 1
					: item.incorrect + item.untested) > 0)
			.map(item => {
				return {TestID: item.id, Max: item.printItems,
					TestDate: this.settingsService.includeSessionOption ? DateTools.toServerString(new Date(item.currentDate)) : null};
			});
	}

	private get fullSet() {
		return this.settingsService.printOutOption === 'FullSet';
	}

	private get tests(): Models.Test[] {
		return this._testsData$.value;
	}

	private get isVerticalOrientation() {
		return Math.abs(Math.sin(parseInt(`${window.orientation}`)* Math.PI/ 180)) < 1;
	}

	public init(selectedItems: Models.InitModel) {
		return this.server.init(selectedItems);
	}

	public selectAll(){
		const tests = this._testsData$.value;
		tests.filter(x => !x.disabled).forEach(t => t.selected = true);
		this.updateTests(tests);
	}

	public deselectAll(){
		const tests = this._testsData$.value;
		tests.filter(x => !x.disabled).forEach(t => {
			t.selected = false;
			t.printItems = null;
			t.allPrintSelected = true;
		});
		this.updateTests(tests);
	}

  public setSortBy(sortBy: SortBy) {
    const sortAlphabeticalFunc = (a, b) => a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base',
    });

    const tests = this._testsData$.value.sort((a, b) => {
      if (sortBy === SortBy.Alphabetical) {
        return sortAlphabeticalFunc(a, b);
      }

      if (sortBy === SortBy.TestOrder) {
        if (a.orderNum === b.orderNum){
          return sortAlphabeticalFunc(a, b);
        }

        return a.orderNum > b.orderNum ? 1 : -1;
      }
    });

    this.updateTests(tests);
    this._sortBy$.next(sortBy);
  }

	public updateTestsDate(date: string){
		const tests = this._testsData$.value;
		tests.forEach(t => t.currentDate = date);

		this.updateTests(tests);
	}

	public updateDate(m: Models.Test, date: string){
		this.reloadTest({id: m.id, date: date, questionsCount: m.questionsCount});
	}

	public updateSelection(m: Models.Test, value: boolean){
		const tests = this._testsData$.value;
		const test = tests.find(t => t.id === m.id);
		test.selected = value;
		test.max = this.setMax(test);
		test.disabled = this.setDisabled(test.max);
		this.updateTests(tests);
	}

	public updateAllPrintSelected(m: Models.Test, value: boolean){
		const tests = this._testsData$.value;
		const test = tests.find(t => t.id === m.id);
		test.allPrintSelected = value;
		test.printItems = this.setMax(test);
		this.updateTests(tests);
	}

	public updatePrintItems(m: Models.Test, value: number){
		const tests = this._testsData$.value;
		const test = tests.find(t => t.id === m.id);
		test.printItems = value;
		this.updateTests(tests);
	}

	public reselectTests(){
		const tests = this._testsData$.value;
		this.updateTestsSelection(tests);
		this.updateTests(tests);
	}

	public reloadTests(m: Models.ReloadTestsModel){
		m.subject = m.subject ?? this.hierarchyService.selectedSubject;
		m.student = m.student ?? this.hierarchyService.selectedStudent;
		m.groupItem = m.groupItem ?? this.hierarchyService.selectedGroupItem;
		m.includeSessionOption = m.includeSessionOption ?? this.settingsService.includeSessionOption;

		let cacheKey = this.getCacheKey(m);
		if(this._cacheKey === cacheKey){
			return;
		}
		const testsFromCache = this.getCache(cacheKey);
		if (testsFromCache) {
			this.setTests(cacheKey, testsFromCache);
		} else {
			if (m.includeSessionOption) {
				this.reloadSessionHierarchy(m);
			} else {
				this.reloadHierarchy(m);
			}
		}
	}

	public loadSubjects(id: number) {
		this.server.loadSubjects(id);
	}

	public getResultsModel() {
		let studentIds = [];
		let allStudentsSelected = true;

		if (this.hierarchyService.selectedStudent && this.hierarchyService.selectedStudent.studentId !== 0) {
			studentIds.push(this.hierarchyService.selectedStudent.studentId);
			allStudentsSelected = false;
		} else {
			this.hierarchyService.selectedGroupItem.students.forEach((student) => {
				if (student.studentId !== 0) {
					studentIds.push(student.studentId);
				}
			});
		}

		let resultsModel = new RequestModels.Results.Init();
		resultsModel.TeacherID = this.hierarchyService.teacher.userId;

		if (this.settingsService.selectedItems.selectedTestID) {
			const test = this.tests.find(t => t.id === this.settingsService.selectedItems.selectedTestID);
			resultsModel.Tests = test && [{TestID: this.settingsService.selectedItems.selectedTestID, TestDate: test.currentDate}] || [];

		} else {
			resultsModel.Tests = this.selectedTests;
		}

		resultsModel.Subject = {
			name: this.hierarchyService.selectedSubject.name,
			subjectID: this.hierarchyService.selectedSubject.id,
			subjectType: this.hierarchyService.selectedSubject.subjectType,
			level: this.hierarchyService.selectedSubject.level,
		};
		resultsModel.StudentIDs = studentIds;
		resultsModel.AllStudentsSelected = allStudentsSelected;
		resultsModel.PrintPutOption = this.settingsService.printOutOption;
		resultsModel.GroupItemID = this.hierarchyService.selectedGroupItem.id;
		resultsModel.GroupItemLevel = this.hierarchyService.selectedGroupItem.itemType;
		resultsModel.CardsPerPage = this.settingsService.cardsNumber;
		resultsModel.Hierarchy = this.hierarchy;

		return resultsModel;
	}

	public get tests$(): Observable<Models.Test[]> {
		return this.completeOnDestroy(this._testsData$.asObservable());
	}

	public get resultButtonDisabled$(): Observable<boolean> {
		return this.completeOnDestroy(this._resultButtonDisabled$.asObservable());
	}

	public get loading$(): Observable<boolean> {
		return this.completeOnDestroy(this._loading$.asObservable());
	}

  public get sortBy$(): Observable<SortBy> {
    return this.completeOnDestroy(this._sortBy$.asObservable());
  }

	public get showAlert() {
		return this.showNoTestsSelectedAlert || this.showNoTestsAlert || this.showNoStudentsAlert;
	}

	public get showNoTestsSelectedAlert() {
		return this.tests.length > 0 && (this.fullSet || this.selectedTests.length === 0);
	}

	public get showNoTestsAlert() {
		if (this.showNoStudentsAlert) {
    		return false;
    	}

		let model = new Models.ReloadTestsModel();
		model.subject = this.hierarchyService.selectedSubject;
		model.student = this.hierarchyService.selectedStudent;
		model.groupItem = this.hierarchyService.selectedGroupItem;
		model.date = this.settingsService.sessionDate;

		const cache = this.getCache(this.getCacheKey(model));
		if (!cache){
			return false;
		}

		return cache.length === 0;
	}

	public get showNoStudentsAlert() {
		return this._isInit && this.hierarchyService.students.length === 1;
	}

	public get dataAlertText() {
		const noDataByDateAlert = 'The date selected has no valid results to display. Please select a new date that contains test data for the report you’re trying to run.';
		const fullSetAlert = 'The full set option prints out a single, complete set of all the test questions.';
		const noValidDataAlert = 'The current selection has no valid results to display. Use the checkboxes on the left to select tests that contain data for the report that you\'re trying to run.';

		if (this.settingsService.includeSessionOption && this.selectedTests.length === 0) {
			return noDataByDateAlert;
		} else if (this.selectedTests.length === 0) {
			return noValidDataAlert;
		} else if (this.fullSet) {
			return fullSetAlert;
		}
	}
}
