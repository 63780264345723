import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {Loader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Label, Option} from '@esgillc/ui-kit/form-control';
import {DistrictSpecialistService} from './service';
import {TabFooter} from 'modules/forms/students-form/components/profile-modal/components/tab-footer';
import {ManagedCheckboxesList} from '../../components/managed-checkboxes-list';
import {RemoveFromSpecialistGroupsAlert} from '../../components/remove-from-specialist-groups-alert';
import {RemoveFromGroupsCheckbox} from '../../components/remove-from-groups-checkbox';
import {LocationFormProps} from '../../../location/types';
import {StudentProfileMode, StudentProfileTab} from 'modules/forms/students-form/types';
import styles from '../../styles.module.less';

export function DistrictLevelLocation({initData, mode, studentID, unitPrefix, preSelected, tabsApi, onClose}: LocationFormProps) {

	const service = useService(DistrictSpecialistService);
	const specialistGroups = useBehaviorSubject(service.specialistGroups);
	const schools = useBehaviorSubject(service.schools);
	const isLoading = useBehaviorSubject(service.serviceLoading);
	const tabs = useBehaviorSubject(service.tabsStatus);
	const selectedSpecialistGroups = useBehaviorSubject(service.selectedSpecialistGroups);

	const [isShowCheckbox, setShowCheckbox] = useState(!!selectedSpecialistGroups.length);
	const [isShowRemoveFromSpecialistGroupsAlert, setShowRemoveFromSpecialistGroupsAlert] = useState(false);
	const [isInitialized, setInitialized] = useState(false);

	useEffect(() => {
		service.init({initData, mode, studentID, preSelected, tabsApi}).subscribe({
			complete: () => {
				setInitialized(true);
			},
		});
	}, []);

	useEffect(() => {
		setShowCheckbox(Boolean(selectedSpecialistGroups.length));
	}, [selectedSpecialistGroups.length]);

	const onSave = (isSaveAndAdd?: boolean) => {
		if(tabs[StudentProfileTab.Location]?.isTouched) {
			service.save(isSaveAndAdd).subscribe(() => {
				if (!isSaveAndAdd) {
					onClose();
				}
			});
		} else {
			if (!isSaveAndAdd) {
				onClose();
			}
		}
	};

	const handelCheckboxClicked = () => {
		service.form.controls.specialistGroupIDs.value = [];
		setShowCheckbox(false);
	};

	return <div data-cy='district-specialist-location-form'>
		<Loader show={isLoading} fullscreen/>
		{isInitialized && <div className={styles.formWrapper}>
			<Form controller={service.form} className={styles.form}>
				<div className={styles.row}>
					<FormElement control={service.form.controls.school} className={styles.column}>
						<Label className={styles.topLabel}>School</Label>
						<div data-cy='school'>
							<Dropdown optionName='name' tabIndex={3} placeholder='Select School'>
								{schools.map(v => <Option key={v.schoolID} value={v}>{v.name}</Option>)}
							</Dropdown>
						</div>
					</FormElement>
				</div>

				{mode !== StudentProfileMode.view && <RemoveFromGroupsCheckbox
					label={'Remove student from all ' + unitPrefix + ' Groups'}
					onClick={() => setShowRemoveFromSpecialistGroupsAlert(true)}
					isChecked={isShowCheckbox}
				/>}

				<div data-cy='checkboxes-list' className={styles.row}>
					<ManagedCheckboxesList
						data-cy='specialist-groups-checkbox-list'
						listName={unitPrefix + ' Groups'}
						list={specialistGroups}
						control={service.form.controls.specialistGroupIDs}
					/>
				</div>
			</Form>
			{isShowRemoveFromSpecialistGroupsAlert && <RemoveFromSpecialistGroupsAlert
				studentFullName={initData.general.firstName + ' ' + initData.general.lastName}
				selectedSpecialistGroups={selectedSpecialistGroups}
				onRemove={handelCheckboxClicked}
				onCancel={() => setShowRemoveFromSpecialistGroupsAlert(false)}
			/>}

			<TabFooter
				tabsApi={tabs}
				canSaveAndAdd={initData.isOpenedWithAdd}
				currentTab={StudentProfileTab.Location}
				mode={mode}
				onSave={() => onSave(false)}
				onSaveAndAdd={() => onSave(true)}
			/>
		</div>}
	</div>;
}
