import {useRef, useState} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {INotedAuthor} from '../../../../services/landing-service';
import {AuthorTooltip} from '../../../noted-author/author-tooltip';
import {AuthorClicked} from '../../../noted-author/events';
import {Card} from '../../card/card';

type Props = {
	author: INotedAuthor;
}

export function Author(props: Props): JSX.Element {
	const linkRef = useRef<HTMLAnchorElement>();
	const [hovered, setHovered] = useState(false);

	return <Card dataName={props.author.name}>
		<img className='author-pic' src={props.author.image100url} height={110} width={110} alt={props.author.name}/>
		<a href='#' ref={linkRef} className='author-name'
		   onMouseOver={() => setHovered(true)}
		   onMouseOut={() => setHovered(false)}
		   onClick={() => dispatchAppEvent(AuthorClicked, new AuthorClicked(props.author))}>
			{props.author.name}
		</a>
		<img className='author-logo' src={props.author.logoUrl} alt={''}/>
		{hovered && <AuthorTooltip author={props.author} element={linkRef.current}/>}
	</Card>;
}
