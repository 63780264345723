export function EyeIcon() {
	return <div data-cy={'eye-icon'} className='action-icon'>
		<svg width='17'
				 height='12'
				 viewBox='0 0 17 12'
				 fill='none'
				 xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.25 0C4.5 0 1.2975 2.3325 0 5.625C1.2975 8.9175
				4.5 11.25 8.25 11.25C12 11.25 15.2025 8.9175 16.5
				5.625C15.2025 2.3325 12 0 8.25 0ZM8.25 9.375C6.18
				9.375 4.5 7.695 4.5 5.625C4.5 3.555 6.18 1.875 8.25
				1.875C10.32 1.875 12 3.555 12 5.625C12 7.695 10.32
				9.375 8.25 9.375ZM8.25 3.375C7.005 3.375 6 4.38 6
				5.625C6 6.87 7.005 7.875 8.25 7.875C9.495 7.875
				10.5 6.87 10.5 5.625C10.5 4.38 9.495 3.375 8.25 3.375Z'
				fill='#0088CC'
			/>
		</svg>
	</div>;
}