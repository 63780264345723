import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from '../styles.module.less';

interface Props {
	onClicked: () => void;
	errors: string;
}

const errorMessage = 'an unsupported field name. Supported field names include Student First ' +
	'and Last Name, ID, Grade, Language, and Gender. Please fix the file and try again.';

export function UnsupportedFieldName({onClicked, errors}: Props) {

	const verb = errors.includes(',') ? 'have' : 'has';

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>Unsupported Field Name</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<p data-cy={'unsupported-field-name'} className={styles.paragraph}>Errors:</p>
			<p data-cy={'errors'} className={styles.paragraph}>{errors} {verb} {errorMessage}</p>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}

