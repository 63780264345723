import {Flashcards as FlashcardsUI, useFlashcards, FlashcardsContext} from '@esgi/main/features/teacher/flashcards';
import {Activities, EmptyContentTemplate, ExpandablePanel} from '@esgi/main/kits/activities';
import {useFlashcardsState} from './hooks';
import {useActivitiesContext} from '../layout/outlet-context';
import {SubjectTab} from '@esgi/main/libs/store';
import {Page, LeftSidebar, RightSidebar, Header, Divider} from '../layout/index.styled';
import {Content} from './index.styled';
import {AddTestDrawer} from '@esgi/main/features/teacher/drawers';
import {useEffect} from 'react';
import {useFlashcardsHistoryState} from '../../../../../features/teacher/utils/use-flashcards-history-state';

export function Flashcards() {
	const {snackbarRef} = useActivitiesContext();
	const {service} = useFlashcards();
	const {
		preview,
		setPreview,
		showAddTestsDrawer,
		setShowAddTestsDrawer,
		valid,
		busy,
		classes,
		groups,
		studentList,
		loadedStudents,
		totalPages,
		subjects,
		loadedSubjects,
		students,
		subject,
		otherSubjectTests,
		onDownload,
	} = useFlashcardsState(service);

	const {subjectId} = useFlashcardsHistoryState();

	useEffect(()=>{
		if(subjectId && subjects){
			service.setSubject(Number(subjectId));
		}
	}, [subjects, subjectId]);

	return (
		<FlashcardsContext.Provider value={service}>
			<Page>
				<LeftSidebar>
					<Activities.StudentsControl
						skeleton={!loadedStudents}
						classes={classes}
						groups={groups}
						students={studentList}
						onChange={(value) => service.setStudents(value)}
					/>
				</LeftSidebar>
				<Content>
					<Header>
						<Activities.StudentPanel
							studentCounter={students.length}
							counterTitle='flashcards'
							student={service.studentService.get(students.length && students[0].studentId)}
						/>
						<Divider/>
						<Activities.SubjectControl
							skeleton={!loadedSubjects}
							subjects={subjects}
							value={subject.toString()}
							onChange={(value) => service.setSubject(+value)}
						/>
					</Header>
					{students.length
						?
							<FlashcardsUI.TestsControl
								extraTests={otherSubjectTests}
								onChange={(value) => service.setSessions(value)}
								onAllTestClick={() => setShowAddTestsDrawer(true)}
							/>
						:
							<EmptyContentTemplate
								type='students'
								templateText='No tests to display'
								additionalText='Please select a student'
							/>
					}
				</Content>
				<RightSidebar>
					<Activities.DownloadPanel
						downloadTitle='Generate'
						disabledPreviewTooltip='Please select a test/student to preview'
						activePreviewTooltip='Click to preview flashcards'
						disabledDownloadTooltip='Please select a test/student to generate flashcards'
						disabled={!valid || busy}
						onPreview={() => service.onPreview(() => setPreview(true))}
						onDownload={() => service.onDownload()}
					/>
					<ExpandablePanel>
						<FlashcardsUI.IncludeControl onChange={(value) => service.setInclude(value)}/>
						<Activities.CardSizeControl onChange={(value) => service.setCardSize(value)}/>
						{/*{false && // Temporarily hidden until page calculation logic is available*/}
						{/*	<Activities.FileTypeControl*/}
						{/*		visibleToggler={false}*/}
						{/*		onChange={(value) => service.setFileType(value)}*/}
						{/*	>*/}
						{/*		<Activities.CopiesPanel>{totalPages}</Activities.CopiesPanel>*/}
						{/*	</Activities.FileTypeControl>*/}
						{/*}*/}
					</ExpandablePanel>
				</RightSidebar>
			</Page>
			{preview &&
				<FlashcardsUI.PreviewDialog
					onClose={() => setPreview(false)}
					onDownload={onDownload}
				/>
			}
			{showAddTestsDrawer &&
				<AddTestDrawer<SubjectTab>
					onClose={() => setShowAddTestsDrawer(false)}
					subject={subjects.find(({id}) => id === subject)}
					snackbarRef={snackbarRef}
				/>
			}
		</FlashcardsContext.Provider>
	);
}

export default Flashcards;
