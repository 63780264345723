import {PropsWithChildren, useContext, useRef} from 'react';
import {Step, StepContent, StepHeader, StepperContext} from '@esgillc/ui-kit/layout';
import styles from './styles.module.less';

type Props = PropsWithChildren<{
	disabled: boolean;
	instructionTitle: string;
}>;

export function ThirdStepContainer({disabled, instructionTitle, children}: Props): JSX.Element {
	const context = useContext(StepperContext);
	const containerRef = useRef<HTMLDivElement>(null);

	return (
		<Step index={3} disabled={disabled}>
			<StepHeader onClick={() => !disabled && context.setActiveStep(3)}>Select Students</StepHeader>
			<StepContent containerClassName={styles.stepContainer} contentClassName={styles.stepContentContainer}>
				<div className={styles.container} ref={containerRef}>
					<div className={styles.instruction}>{instructionTitle}</div>
					{children}
				</div>
			</StepContent>
		</Step>
	);
}
