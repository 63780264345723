import {HttpClient} from '@esgi/api';
import {
	ICreateModel,
	IInitResponse,
	IUpdateModel,
} from 'shared/modules/grade-scale/grade-scale/models';
import {IReportGradeScale, SubjectLevel} from 'shared/modules/grade-scale/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class Api {
	private static controllerName = 'reports/grade-scales';

	static init(hierarchy: HierarchySnapshot, gradeScaleID: number, subjectLevel?: SubjectLevel) {
		return HttpClient.default.ESGIApi.get<IInitResponse>(this.controllerName, 'init', {gradeScaleID: gradeScaleID, subjectLevel: subjectLevel, fullHierarchy: hierarchy});
	}

	static update(model: IUpdateModel) {
		return HttpClient.default.ESGIApi.post<IReportGradeScale>(this.controllerName, 'update', model);
	}

	static create(model: ICreateModel, hierarchy: HierarchySnapshot) {
		return HttpClient.default.ESGIApi.post<IReportGradeScale>(this.controllerName, 'create', {
			gradeScaleCreate: model,
			hierarchy: hierarchy,
		});
	}

	static delete(gradeScaleID: number) {
		return HttpClient.default.ESGIApi.post(this.controllerName, 'delete', {gradeScaleID: gradeScaleID});
	}
}
