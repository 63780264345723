import React, {useEffect, useState} from 'react';
import {GeneralSection} from 'shared/modules/user-settings/components/general-section';
import {TestingSection} from 'shared/modules/user-settings/teacher-and-specialist/components/testing';
import TestSessionResultsSection
	from 'shared/modules/user-settings/teacher-and-specialist/components/test-session-results';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useService} from '@esgi/core/service';
import {TeacherAndSpecialistSettingsService} from 'shared/modules/user-settings/teacher-and-specialist/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {UserType, useUser} from '@esgi/core/authentication';
import {DesignVersion, useDesignVersion} from '@esgi/main/libs/core';
import {BoxArrowUpRight} from '@esgi/ui';


interface Props {
	userID: number;
	onClose: () => void;
	userType: UserType;
}


const TeacherAndSpecialistSettings = ({onClose, userID, userType}: Props) => {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const service = useService(TeacherAndSpecialistSettingsService);

	const [isLoaded, setLoaded] = useState(false);
	const code = useBehaviorSubject(service.code);
	const sortValues = useBehaviorSubject(service.sortValues);
	const timeZones = useBehaviorSubject(service.timeZones);
	const lockScreen = useBehaviorSubject(service.lockScreen);
	const enableShortcuts = useBehaviorSubject(service.enableShortcuts);
	const expireTime = useBehaviorSubject(service.expireTime);
	const testButtonLabels = useBehaviorSubject(service.testButtonLabels);
	const testResultVerbiage = useBehaviorSubject(service.testResultVerbiage);
	const showSelfAssessOption = useBehaviorSubject(service.showSelfAssessOption);
	const showPieChartsResults = useBehaviorSubject(service.showPieChartsResults);
	const user = useUser();

	const clientJoined = useBehaviorSubject(service.clientJoined);

	useEffect(() => {
		service.init({userID, userType}).subscribe(() => setLoaded(true));
	}, []);

	useEffect(() => {
		if (clientJoined) {
			handleClose();
		}
	}, [clientJoined]);

	const [, setDesignVersion] = useDesignVersion();

	const onNewDesignClicked = () => {
		setDesignVersion(DesignVersion.Redesign);
		location.href = '/home';
	};

	const onSubmit = () => {
		service.updateUserSettings().subscribe(handleClose);
	};
	return (
		<>
			<ServiceLoader trackingService={service} fullscreen/>
			{isLoaded && <Modal modalManagerRef={modalRef}>
				<Modal.Header>
					<Title>
						Settings
					</Title>
					<CloseIcon onClick={handleClose}/>
				</Modal.Header>
				<Modal.Body className={styles.modalBody}>
					<div data-cy='teacher-and-specialist-settings'>
						{user.userType === UserType.T &&
							<Buttons.Outlined className={styles.designSwitcherBtn} onClick={onNewDesignClicked}>
								Go to the new version
								<BoxArrowUpRight width={32} height={32}/>
							</Buttons.Outlined>}
						<GeneralSection
							sortValues={sortValues}
							timeZones={timeZones}
							form={service.generalSectionForm}
						/>
						<TestingSection
							lockScreen={lockScreen}
							enableShortcuts={enableShortcuts}
							onSaveKeyboardShortcuts={(value) => service.enableShortcuts.next(value)}
							createShareSession={service.createShareSession}
							code={code}
							codeExpireTime={expireTime}
							correctLabel={testButtonLabels.correctLabel}
							incorrectLabel={testButtonLabels.incorrectLabel}
							testButtonsLabelsEnabled={testButtonLabels.testButtonsLabelsEnabled}
							onSaveTestButtonLabels={(testButtonsLabelsEnabled, correctLabel, incorrectLabel) => service.testButtonLabels.next({
								testButtonsLabelsEnabled,
								correctLabel,
								incorrectLabel,
							})}
							showSelfAssessOption={showSelfAssessOption}
							onSaveSelfAssessOption={(showSelfAssessOption, lockScreen) => {
								service.showSelfAssessOption.next(showSelfAssessOption);
								service.lockScreen.next(lockScreen);
							}
							}
						/>
						<TestSessionResultsSection
							correctVerbiage={testResultVerbiage.correctVerbiage}
							incorrectVerbiage={testResultVerbiage.incorrectVerbiage}
							testResultsVerbiagesEnabled={testResultVerbiage.testResultsVerbiagesEnabled}
							onSaveTestResultVerbiage={(testResultsVerbiagesEnabled, correctVerbiage, incorrectVerbiage) => service.testResultVerbiage.next({
								testResultsVerbiagesEnabled,
								correctVerbiage,
								incorrectVerbiage,
							})}
							showPieChartsResults={showPieChartsResults}
							onSaveTestSessionResults={(value) => service.showPieChartsResults.next(value)}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Buttons.Gray onClick={handleClose}>Cancel</Buttons.Gray>
					<Buttons.Contained onClick={onSubmit}>Save</Buttons.Contained>
				</Modal.Footer>
			</Modal>}
		</>
	);
};

export default TeacherAndSpecialistSettings;
