import React from 'react';
import '../../component.less';
import {IGradeRangeView} from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/models';
import {GradeRangeType} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/models';

export class Props {
	entry: IGradeRangeView;
	last: boolean;
	maxSize?: boolean;
}

export default class ViewerEntry extends React.PureComponent<Props> {
	renderArrow() {
		return <div className='arrow'>
			<svg width='38' height='31' viewBox='0 0 38 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M1 22.375V8.75H21.1306V2L37 15.5L21.1306 29V22.375H1Z' fill='#81D4F7' stroke='#81D4F7'/>
			</svg>
		</div>;
	}
	
	render() {
		return <div className={this.props.last ? 'grade-range-entry last' : 'grade-range-entry'}>
			<div className='color-container' style={{backgroundColor: this.props.entry.color}}>
				{this.props.entry.name}
			</div>
			<div className={this.props.maxSize ? 'grade-range-entry-container max-size' : 'grade-range-entry-container'}>
				<span>{this.props.entry.from} {this.props.entry.type === GradeRangeType.Percent && '%'}</span>
				{this.renderArrow()}
				<span>{this.props.entry.to} {this.props.entry.type === GradeRangeType.Percent && '%'}</span>
			</div>
		</div>;
	}
}