import {Buttons, ButtonProps} from '@esgillc/ui-kit/button';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	direction: 'left' | 'right';
}

export function ArrowButton({children, direction, className, ...props}: ButtonProps & Props) {
	return <Buttons.Gray className={join(styles.arrowButton, className)} {...props}>
		{children}
		{direction === 'left' && <i className='fa fa-arrow-left'/>}
		{direction === 'right' && <i className='fa fa-arrow-right'/>}
	</Buttons.Gray>;
}
