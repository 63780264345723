import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const ListItem = styled(GridBox, {
	alignItems: 'center',
	width: '100%',
	padding: '8px 12px 16px 12px',
	gridTemplateColumns: '1fr auto auto',
	gap: '16px',
});

export const Container = styled(GridBox, {
	gridTemplateColumns: '1fr 1fr',
	gap: '8px',
});