import {StudentSort, SubjectType, TestType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class ReportModel {
	filter: ReportFilter;
	data: ReportData;
}

export class ReportData {
	students: StudentData[];
}

export class StudentData {
	id?: number;
	studentID: number;
	firstName: string;
	lastName: string;
	tests: TestData[];
}

export class TestData {
	testID: number;
	correctAnswersAll: number;
	correctAnswersOverPeriod: number;
	lastTestDate?: Date;
	lastTestDateOverPeriod?: Date;
}

export class ReportFilter {
	teacherName: string;
	lastUntestedWeeks: number;
	classID: number;
	classes: Class[];
	groupID: number;
	groups: Group[];
	specialistGroupID: number;
	specialistGroups: SpecialistGroup[];
	subjects: Subject[];
	studentSort: StudentSort;
}

export class Class {
	classID: number;
	name: string;
}

export class Group {
	groupID: number;
	name: string;
}

export class SpecialistGroup {
	specialistGroupID: number;
	name: string;
}

export class Subject {
	subjectID: number;
	name: string;
	subjectType: SubjectType;
	gradeLevelIDs: Array<number>;
	tests: Array<Test>;
}

export class Test {
	testType: TestType;
	testID: number;
	name: string;
	maxScore: number;
	isWhiteBackground: boolean;
}

export type TestRunOptions = {
	testType: TestType;
	classID: number;
	specialistGroupID: number;
	studentID: number;
	testID: number;
	subjectID: number;
	subjectType: SubjectType;
	subjectName: string;
}

export interface ReportRequest {
	subjectID: number;
	subjectType: SubjectType;
	getFilter: boolean;
	lastUntestedWeeks: number;
	periodDataOnly: boolean;
	hierarchy: HierarchySnapshot;
	testIDs: any[];
	gradeLevelIDs: any[];
	teacherID: number;
	userID: number;
	studentSort: StudentSort;
	ascending: boolean;
}
