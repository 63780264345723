import {DefaultGradeRangeEditorService} from 'shared/modules/grade-scale/grade-range/forms/default/service';
import {GradeScaleService} from 'shared/modules/grade-scale/grade-scale/service';
import {CustomSharedService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/service';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class ServicesStore {
	public setUpGradeScaleMode: boolean;
	public gradeScaleService: GradeScaleService;
	public defaultGradeRangeEditorService: DefaultGradeRangeEditorService;
	public customSharedService: CustomSharedService;
	
	constructor(setUpGradeScaleMode: boolean, hierarchy: HierarchySnapshot) {
		this.setUpGradeScaleMode = setUpGradeScaleMode;
		this.gradeScaleService = new GradeScaleService(hierarchy);
		this.defaultGradeRangeEditorService = new DefaultGradeRangeEditorService();
		this.customSharedService = new CustomSharedService(setUpGradeScaleMode, hierarchy);
	}
	
	destroy() {
		this.gradeScaleService.destroy();
		this.defaultGradeRangeEditorService.destroy();
		this.customSharedService.destroy();
	}
}
