import {Renderable, Form, OldAlerts} from '@esgi/deprecated/knockout';
import {FieldContainer} from '../../../kit/component';
import {FullNameFormComponent, GradeScaleForm, TimeZoneSelectComponent, TrackSelect} from '../../components';
import {LinkedModel} from '../../models';
import {LinkedCompleteRequestModel} from '../../server';
import {LinkedContinueContentTemplate} from './templates';

export class LinkedComplete extends Renderable {

	private fullName: FullNameFormComponent;
	private timezone: TimeZoneSelectComponent;
	private track: TrackSelect;
	private gradeLevel: GradeScaleForm;
	private fields: Array<FieldContainer | Form>;

	constructor(opts: LinkedModel) {
		super();
		this.createFields(opts);
	}

	template = () => LinkedContinueContentTemplate.Render();

	cancel() {
		OldAlerts.bsconfirm('Are you sure you want to exit? You cannot use your ESGI account until you complete this form.', (r) => {
			if (r) {
				$(this).trigger('cancel');
			}
		});
	}

	next() {
		this.isValid().done((r) => {
			const isValid = r.reduce((a, b) => a && b);
			if (isValid) {
				$(this).trigger('done', {data: this.serialize()});
			}
		});
	}

	events = {
		cancel: (callback) => {
			$(this).on('cancel', callback);
		},
		done: (callback) => {
			$(this).on('done', callback);
		},
	};

	createFields(opts: LinkedModel) {
		this.fullName = new FullNameFormComponent();
		this.timezone = new TimeZoneSelectComponent(opts.timeZoneID);
		this.track = new TrackSelect(opts.tracks);
		this.gradeLevel = new GradeScaleForm();
		this.fields = new Array<FieldContainer | Form>();
		this.fields.push(...[this.fullName, this.track, this.timezone, this.gradeLevel]);
	}

	isValid() {
		return $.whenAll(this.fields.map(s => s.validate()));
	}

	serialize(): LinkedCompleteRequestModel {
		return <LinkedCompleteRequestModel>{
			firstName: this.fullName.firstName.value(),
			lastName: this.fullName.lastName.value(),
			title: this.fullName.title.value(),
			trackID: this.track.serialize().id,
			timeZoneID: this.timezone.serialize().id,
			gradeLevels: this.gradeLevel.serialize().map(g => parseInt(g)),
		};
	}
}
