import React, {PureComponent, ReactNode} from 'react';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {AudioButton, getUrl, Question, QuestionTemplate} from '@esgi/selfassess';
import CloseButton from 'modules/assessments/testing/self-assessment/components/close-button/close-button';
import {userStorage} from '@esgi/core/authentication';

import styles from './self-assess-preview.module.less';
import {isIpad, join} from '@esgillc/ui-kit/utils';

class State {
	questionIndex: number = 0;
}

interface Props {
	testName: string;
	questions: Question[];
	onClose: () => void;
}

export default class SelfAssessPreview extends PureComponent<Props, State> {
	public readonly state = new State();

	private readonly modalManagerRef: ModalManagerRefObject = React.createRef();
	private readonly bucket = userStorage.get().awsEnvBucket;

	private get currentQuestion(): Question {
		return this.props.questions[this.state.questionIndex];
	}

	private get audioURL(): string {
		const {audioDirectionUrl} = this.currentQuestion;
		return (audioDirectionUrl && getUrl(this.bucket, audioDirectionUrl)) || '';
	}

	private get canNext(): boolean {
		return this.state.questionIndex < this.props.questions.length - 1;
	}

	private get canBack(): boolean {
		return this.state.questionIndex > 0;
	}

	public render(): ReactNode {
		const {
			directionsText,
		} = this.currentQuestion;

		return <Modal containerClassName={join(styles.modalWrapper, isIpad() && styles.isIpad)}
		              className={styles.modal}
		              modalManagerRef={this.modalManagerRef}>
			<Modal.Header className={styles.testHeader}>
				<div className={styles.testHeaderContainer}>
					<div className={styles.testName}>
						<span>{this.props.testName}</span>
					</div>
					<div className={styles.questionContainer}>
						<AudioButton id={this.currentQuestion.id} audioUrl={this.audioURL}/>
						{directionsText &&
							<span className={styles.testDirection} dangerouslySetInnerHTML={{__html: directionsText}}/>}
					</div>
				</div>
				<CloseButton className={styles.closeIcon} closed={() => this.onClose()}/>
			</Modal.Header>
			<Modal.Body className={styles.testBody}>
				<div className={styles.questionWrapper}>
					<QuestionTemplate
						isPreviewMode
						awsBucket={this.bucket}
						question={this.currentQuestion}
						selectedOptionID={[]}
						onSelectOption={(id) => ({})}
						maxOptionsSelected={true}
						questionIndex={this.state.questionIndex}
					/>

				</div>
			</Modal.Body>
			<Modal.Footer className={styles.testFooter}>
				<div className={styles.footerActions}>
					<button disabled={!this.canBack} onClick={() => this.backClicked()} className={styles.navButton}>
						<i className='fa fa-arrow-left'></i>
					</button>
					<span className={styles.questionCounter}>
					<span
						className={styles.currentQuestionIndex}>{this.state.questionIndex + 1}</span> of {this.props.questions.length}
				</span>
					<button disabled={!this.canNext} onClick={() => this.nextClicked()} className={styles.navButton}>
						<i className='fa fa-arrow-right'></i>
					</button>
				</div>
			</Modal.Footer>
		</Modal>;
	}

	private nextClicked(): void {
		if (this.canNext) {
			this.setState({questionIndex: this.state.questionIndex + 1});
		}
	}

	private backClicked(): void {
		if (this.canBack) {
			this.setState({questionIndex: this.state.questionIndex - 1});
		}
	}

	private onClose(): void {
		this.modalManagerRef.current.close(this.props.onClose);
	}
}
