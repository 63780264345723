import {TableLevelReportService} from '../../../../services/table-level-report-service';
import {ReportHeader} from '../report-header';
import {LevelsLegend} from './components/levels-legend';
import {MarkingPeriodFilter} from '../../../marking-period-filter';
import {ShowResultsFilter} from '../../../show-results-filter';
import {DisplayNotTestedFilter} from '../../../display-not-tested-filter';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportFiltersBody, ReportFiltersRow} from '@esgi/deprecated/ui-kit/report/filters';

type ReportFiltersProps = {
	service: TableLevelReportService;
};

export function ReportFilters({service}: ReportFiltersProps) {
	const filter = useBehaviorSubject(service.filter$);

	return (
		<ReportFiltersBody>
			<ReportHeader service={service} />
			<ReportFiltersRow>
				<LevelsLegend service={service} />
				<ShowResultsFilter
					onCarryForwardClicked={service.setCarryForwardFilter.bind(service)}
					onShowBaseLineClicked={service.setShowBaseLineFilter.bind(service)}
					carryScoresForward={filter?.carryScoresForward}
					showBaseline={filter?.showBaseline}
				/>
				<DisplayNotTestedFilter
					onChanged={service.setDisplayNotTestedAsFilter.bind(service)}
					displayZeroIfNotTested={filter?.displayZeroIfNotTested}
				/>
				<MarkingPeriodFilter
					onChanged={service.setMarkingPeriodFilter.bind(service)}
					showCurrentMarkingPeriod={filter?.showCurrentMarkingPeriod}
				/>
			</ReportFiltersRow>
		</ReportFiltersBody>
	);
}
