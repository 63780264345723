import {EventBusManager} from '@esgillc/events';
import {
	SubjectCreatedEvent,
	SubjectHiddenEvent,
	SubjectReorderedEvent,
	SubjectUnhiddenEvent,
	SubjectUpdatedEvent,
	TestAddedToSubjectEvent,
	TestRemovedFromSubjectEvent,
} from 'api/entities/events/subject';
import {SubjectRemovedEvent} from 'modules/manage-subjects-and-tests/events';
import {BehaviorSubject} from 'rxjs';
import {SubjectType} from '@esgi/core/enums';
import {AddTestClosedEvent} from 'shared/modules/home-add-test/events';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType} from '@esgi/core/authentication';
import {mapToEnum} from 'shared/utils';
import {notHiddenFilter} from './filters';
import {SubjectModel} from './models';
import {SubjectsStore} from './subjects-store';

export default class EventsHandlingService extends BaseService {
	private readonly eventBus: EventBusManager = new EventBusManager();
	private user = userStorage.get();

	constructor(
		private subjectsStore: SubjectsStore,
		private refreshStore: () => void,
		private selectSubject: (subject: SubjectModel) => void,
		private currentSubject$: BehaviorSubject<SubjectModel>) {
		super();

		this.eventBus.subscribe(AddTestClosedEvent, (event: AddTestClosedEvent) => {
			const {id, type} = event.lastSelectedSubject;
			if (id !== this.currentSubject$.value.id) {
				let s = this.subjectsStore.getSubject(id, type);
				if (s) {
					this.selectSubject(s);
				}
			}
		});

		this.eventBus.subscribe(SubjectReorderedEvent, (args: SubjectReorderedEvent) => {
			const source = this.subjectsStore.getSubjects(args.subjectType);
			let newOrder = [...source].sort((a, b) => args.subjectIds.indexOf(a.id) > args.subjectIds.indexOf(b.id) ? 1 : -1);
			this.subjectsStore.setSubjectsByType(newOrder, args.subjectType);
		});

		this.eventBus.subscribe(SubjectRemovedEvent, (args: SubjectRemovedEvent) => {
			this.subjectsStore.removeSubject(args.id, args.type);
		});

		this.eventBus.subscribe(TestRemovedFromSubjectEvent, (e: TestRemovedFromSubjectEvent) => {
			const subject = this.subjectsStore.getSubject(e.subjectID, e.subjectType);
			subject.testsCount--;
			this.subjectsStore.updateSubject(subject);
		});

		this.eventBus.subscribe(TestAddedToSubjectEvent, (args: TestAddedToSubjectEvent) => {
			const type = mapToEnum(args.subjectType, SubjectType);
			const subject = this.subjectsStore.getSubject(args.subjectID, type);
			subject.testsCount++;
			this.subjectsStore.updateSubject(subject);
		});

		this.eventBus.subscribe(SubjectUnhiddenEvent, (args: SubjectUnhiddenEvent) => {
			const subject = this.subjectsStore.getSubject(args.id, args.subjectType);
			subject.hidden = false;
			this.subjectsStore.updateSubject(subject);
		});

		this.eventBus.subscribe(SubjectHiddenEvent, (args: SubjectHiddenEvent) => {
			const subject = this.subjectsStore.getSubject(args.id, args.subjectType);
			subject.hidden = true;
			this.subjectsStore.updateSubject(subject);

			if (this.currentSubject$.value.id === subject.id) {
				let subjectToSelect = notHiddenFilter(this.subjectsStore.getSubjects())[0];
				this.selectSubject(subjectToSelect);
			}
		});

		this.eventBus.subscribe(SubjectCreatedEvent, (args: SubjectCreatedEvent) => {
			const s = new SubjectModel();
			s.canEdit = true;
			s.id = args.id;
			s.type = args.properties.subjectType;
			s.published = args.published;
			s.name = args.properties.name;
			s.level = this.user.userType == UserType.C ? 'School' : this.user.userType == UserType.D ? 'District' : '';
			s.gradeLevels = args.properties.gradeLevels;
			s.testsCount = 0;
			this.subjectsStore.insertSubject(s);
			if (args.selected) {
				this.selectSubject(s);
			}
		});
		this.eventBus.subscribe(SubjectUpdatedEvent, (args: SubjectUpdatedEvent) => {
			const subject = this.subjectsStore.getSubject(args.id, args.properties.subjectType);
			if (subject) {
				subject.published = args.published;
				subject.name = args.properties.name;
				subject.gradeLevels = args.properties.gradeLevels;
				this.subjectsStore.updateSubject(subject);

				if (this.currentSubject$.value && args.id === this.currentSubject$.value.id && args.properties.subjectType === this.currentSubject$.value.type) {
					if (args.properties.name !== this.currentSubject$.value.name) {
						this.selectSubject(subject);
					}
				}
			} else {
				this.refreshStore();
			}
		});
		this.eventBus.subscribe(SubjectReorderedEvent, (args: SubjectReorderedEvent) => {
			const subjects = this.subjectsStore.getSubjects(args.subjectType);
			const ids = args.subjectIds;
			const sortedSubjects = subjects.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
			this.subjectsStore.setSubjectsByType(sortedSubjects, args.subjectType);
		});
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}
}
