import {ErrorHandler, HandlingStrategy} from '@esgi/api';
import {dispatchAppEvent} from '@esgillc/events';
import {tryCall} from '@esgillc/ui-kit/utils';
import {ReportErrorEvent} from '@esgi/core/react';

function tryGetMessage(data: any): string {
	return data?.Message || data?.message || '';
}

function tryGetToken(data: any): string {
	if (data && data.Object) {
		return data.Object.token;
	}
	if(data && data.object) {
		return data.object.token;
	}

	return tryCall(data?.status?.toString);
}

function tryGetResponse(response: any): object {
	if(typeof response === 'object' && 'message' in response) {
		return response;
	}

	if(typeof response === 'string') {
		try {
			const out = JSON.parse(response);
			return out;
		} catch (e) {
			console.error(e);
			return {
				message: response,
			};
		}
	}

	return {};
}

export class DefaultErrorHandler extends ErrorHandler {
	public next<T>(response: XMLHttpRequest, strategy: HandlingStrategy): void {
		if (!response) {
			return;
		}
		if(response.status !== 500) {
			return;
		}

		if(response?.response instanceof Blob) {
			response?.response.text().then(r => {
				const resp = tryCall<any>(() => JSON.parse(r));
				if(resp) {
					const message = tryGetMessage(resp);
					const token = tryGetToken(resp);
					dispatchAppEvent(ReportErrorEvent, {
						message,
						token,
					} as ReportErrorEvent);
				}
			});
		} else {
			const data = tryGetResponse(response?.response);
			const message = tryGetMessage(data);
			const token = tryGetToken(data);
			dispatchAppEvent(ReportErrorEvent, {message, token} as ReportErrorEvent);
		}
	}
}
