import process from 'process';
import {SettingsTab} from '../../types';

const defaultGeneralItems = [
	{
		value: SettingsTab.SystemSettings,
		name: 'System Settings',
	},
];

const generalItems = [
	...defaultGeneralItems,
	{
		value: SettingsTab.LanguagesAndTemplates,
		name: 'Language & Templates',
	},
];

export const getGeneralItems = () => {
	if(process.env.ESGI_BRANCH !== 'dev'){
		return defaultGeneralItems;
	}

	return generalItems;
};
