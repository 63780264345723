import {Input} from '@esgi/ui/controls';
import {parseStudentName} from '../student-block/utils';
import {ConflictType, Validation, ValidationType} from '../../../../types';

interface Props {
	onEdit: (value: string, validation: Validation) => void;
	errorMessage?: string;
	existingStudentNames: string[];
	uploadedStudentName: string;
	isDisabled?: boolean;
}

export function Name({onEdit, errorMessage, existingStudentNames, isDisabled, uploadedStudentName}: Props) {

	const onHandleEdit = (value: string) => {
		if (!value.trim()) {
			return onEdit(value, new Validation(ValidationType.Standard, ConflictType.DuplicateStudentName, 'Please enter a student name.'));
		}

		const names = parseStudentName(value);
		if (names.length < 2 || (names[0].length === 0 || names[1].length === 0)) {
			return onEdit(value, new Validation(ValidationType.Standard, ConflictType.DuplicateStudentName, 'First and last name required.'));
		}

		const fullName = names[0] + ' ' + names[1];
		if (existingStudentNames.map(s => s.toLowerCase()).some(s => s === fullName.toLowerCase())) {
			return onEdit(value, new Validation(ValidationType.Conflict, ConflictType.DuplicateStudentName, 'This name matches another student.'));
		}

		return onEdit(value, new Validation(ValidationType.None, ConflictType.DuplicateStudentName, ''));
	};


	return <Input
		value={uploadedStudentName}
		placeholder='Name'
		error={errorMessage}
		onChange={(e) => onHandleEdit(e.target.value)}
		disabled={isDisabled}
	/>;
}
