export class GradeReportTemplate {
	public static render() {
		return (
			<div className='grade-report' data-bind='afterRender: true'>
				<div className='report-settings'>
					<div>
						<ko data-bind="if: originalReportType() == 'Class' || originalReportType() == 'Group' || originalReportType() == 'SpecialistGroup'">
							<div>
								<span data-bind="text: userTitleText() + ': '"></span>
								<span className='name' data-bind='text: args()[0]'/>
							</div>
						</ko>
						<ko data-bind="if: originalReportType() == 'SpecialistGroup' && data.specialistGroups().length > 0">
							<div>
								<span>Group: </span>
								<ko data-bind='if: data.specialistGroups().length > 1'>
									<select className='form-control' data-bind="options: data.specialistGroups, optionsText: 'name', value: selectedSpecialistGroup"/>
								</ko>
								<ko data-bind='if: data.specialistGroups().length == 1'>
									<span className='name' data-bind='text: selectedSpecialistGroup().name'/>
								</ko>
							</div>
						</ko>
						<ko data-bind="if: originalReportType() == 'Group' && data.groups().length > 0">
							<div>
								<span>Group: </span>
								<ko data-bind='if: data.groups().length > 1'>
									<select className='form-control' data-bind="options: data.groups, optionsText: 'name', value: selectedGroup"/>
								</ko>
								<ko data-bind='if: data.groups().length == 1'>
									<span className='name' data-bind='text: selectedGroup().name'/>
								</ko>
							</div>
							<div>
								<span>Class: </span>
								<span className='name' data-bind='text: args()[1]'/>
							</div>
						</ko>
						<ko data-bind='if: data.classes() && data.classes().length > 0'>
							<div>
								<span>Class: </span>
								<ko data-bind='if: data.classes().length > 1'>
									<select className='form-control' data-bind="options: data.classes, optionsText: 'name', value: selectedClass"/>
								</ko>
								<ko data-bind='if: data.classes().length == 1'>
									<span className='name' data-bind='text: selectedClass().name'/>
								</ko>
							</div>
						</ko>
						<ko data-bind='if: data.teachersGroups() && data.teachersGroups().length > 0'>
							<div>
								<span>Teacher Group: </span>
								<ko data-bind='if: data.teachersGroups().length > 1'>
									<select className='form-control' data-bind="options: data.teachersGroups, optionsText: 'name', value: selectedTeachersGroup"/>
								</ko>
								<ko data-bind='if: data.teachersGroups().length == 1'>
									<span className='name' data-bind='text: selectedTeachersGroup().name'/>
								</ko>
							</div>
						</ko>
						<ko data-bind='if: data.groupsOfSpecialists() && data.groupsOfSpecialists().length > 0'>
							<div>
								<span>Specialist Group: </span>
								<ko data-bind='if: data.groupsOfSpecialists().length > 1'>
									<select className='form-control' data-bind="options: data.groupsOfSpecialists, optionsText: 'name', value: selectedGroupOfSpecialists"/>
								</ko>
								<ko data-bind='if: data.groupsOfSpecialists().length == 1'>
									<span className='name' data-bind='text: selectedGroupOfSpecialists().name'/>
								</ko>
							</div>
						</ko>
						<ko data-bind='if: data.schoolsGroups() && data.schoolsGroups().length > 0'>
							<div>
								<span>School Group: </span>
								<ko data-bind='if: data.schoolsGroups().length > 1'>
									<select className='form-control' data-bind="options: data.schoolsGroups, optionsText: 'name', value: selectedSchoolsGroup"/>
								</ko>
								<ko data-bind='if: data.schoolsGroups().length == 1'>
									<span className='name' data-bind='text: selectedSchoolsGroup().name'/>
								</ko>
							</div>
						</ko>
						<ko data-bind="if: (originalReportType() == 'StudentsDistrict' || originalReportType() == 'DistrictSpecialistStudents') && data.schools().length > 0">
							<div>
								<span>School: </span>
								<select className='form-control' data-bind="options: data.schools, optionsText: 'name', value: selectedSchool"/>
							</div>
						</ko>
						<ko data-bind="if: originalReportType() == 'DistrictSpecialistStudents' || originalReportType() == 'SchoolSpecialistStudents' || originalReportType() =='StudentsDistrict' || originalReportType() == 'StudentsSchool'">
							<div>
								<span data-bind="if: originalReportType() =='StudentsDistrict' || originalReportType() == 'StudentsSchool'">Class: </span>
								<span data-bind="if: originalReportType() == 'DistrictSpecialistStudents' || originalReportType() == 'SchoolSpecialistStudents'">Specialist Group: </span>
								<select className='form-control' data-bind='value: selectedUserEntity'>
									<option data-bind="value: 0, text: 'All'"></option>
									<ko data-bind='foreach: data.userEntities'>
										<optgroup data-bind='attr: {label: name}, foreach: entities'>
											<option data-bind='text: name, value: id, option: $data'></option>
										</optgroup>
									</ko>
								</select>
							</div>
						</ko>
						<ko data-bind='if: data.subjects().length > 0'>
							<div>
								<span>Subject Tab: </span>
								<select className='form-control' data-bind="options: data.subjects, optionsText: 'name', optionsValue: 'subjectID', value: selectedSubjectId"/>
							</div>
						</ko>
						<ko data-bind='if: data.gradeLevels().length > 0'>
							<div>
								<span>Grade: </span>
								<select className='form-control' data-bind="options: data.gradeLevels, optionsText: 'name', value: selectedGradeLevel"/>
							</div>
						</ko>
						<div>
							<ko data-bind='foreach: data.infoRows'>
								<div className='report-help-text' data-bind='text: $data'/>
							</ko>
						</div>
					</div>
					<div data-bind='visible: data.rows().length !== 0 && selectedSubject().hasGradeScales'>
						<div>Settings:</div>
						<div className='settings-cell'>
							<div>
								Grade Scale:
								<ko data-bind='ifnot: readonly'>
									<ko data-bind='ifnot: doNotAllowEditSubjectByAdmin()'>
										<a data-bind='click: view.editGradeScaleClick'>Edit</a>
									</ko>
									<ko data-bind='if: doNotAllowEditSubjectByAdmin()'>
										<div className='disabledlink'>Edit</div>
									</ko>
								</ko>
								<ko data-bind='if: readonly'>
									<span data-bind='text: managedByLabelText()'/>
								</ko>
							</div>
							<div>
								<ko data-bind='with: data.gradeScale'>
									<div className='grade-scales-legend'>
										<ko data-bind='foreach: entries'>
											<div
												data-bind="attr: { title: description }, style: {'backgroundColor': color}"
												data-toggle='tooltip'
											>
												<div data-bind='text: gradeName'/>
											</div>
										</ko>
										<div
											data-bind="attr: { title: 'Not Tested' }, style: {'backgroundColor': '#fff'}"
											title='Not Tested' data-toggle='tooltip'
										>
											<div>NT</div>
										</div>
									</div>
								</ko>
							</div>
						</div>
						<div className='settings-cell'>
							<div>Display not tested as:</div>
							<div>
								<input id='zeroDisplayZeroIfNotTestedOption' type='radio' className='theme' name='displayZeroIfNotTestedOptions' value='Zero' data-bind='checked: displayZeroIfNotTestedOption'/>
								<label htmlFor='zeroDisplayZeroIfNotTestedOption'>Zero</label>
								<input id='ntDisplayZeroIfNotTestedOption' type='radio' className='theme' name='displayZeroIfNotTestedOptions' value='NT' data-bind='checked: displayZeroIfNotTestedOption'/>
								<label htmlFor='ntDisplayZeroIfNotTestedOption'>NT</label>
							</div>
						</div>
						<div className='settings-cell scores'>
							<div>Results:</div>
							<div>
								<input id='carryScoresForwardOption' className='theme' type='checkbox' data-bind='checked: carryScoresForward'/>
								<label htmlFor='carryScoresForwardOption'>
									<span>Carry forward</span>
									<a
										className='inline-tooltip'
										data-bind="bsTooltip: {placement:'right'}" title='Help'
										href='https://support.esgisoftware.com/hc/en-us/articles/115005887466'
										target='_blank' rel='noreferrer'
									>
										<sup className='fa fa-question-circle' aria-hidden='true'/>
									</a>
								</label>
							</div>
						</div>
						<div className='settings-cell'>
							<div>Marking Period:</div>
							<div>
								<input id='currentMarkingPeriodOption' className='theme' type='radio' name='period' value='Current' data-bind='checked: currentPeriod'/>
								<label htmlFor='currentMarkingPeriodOption'>Current</label>
								<input id='allMarkingPeriodOption' className='theme' type='radio' name='period' value='All' data-bind='checked: currentPeriod'/>
								<label htmlFor='allMarkingPeriodOption'>All</label>
							</div>
						</div>
					</div>
				</div>
				<div className='report-holder'>
					<ko data-bind='if: data.rows().length === 0 && selectedSubject().hasGradeScales'>
						<div className='empty-text-container'>
							<span className='emptyReportText'>There are no students in this group or class</span>
						</div>
					</ko>
					<ko data-bind='if: hasNotConfiguredSet()'>
						<div className='empty-text-container' data-bind='visible: hasNotConfiguredSet()'>
							<div className='emptyReportText'>
								<ko data-bind="if: selectedSubject() && selectedSubject().level == 'District'">
									Sorry. Your District Administrator has not yet configured Grade Scales which prevents
									you<br/>
									from running the Grade Report on this tab. Please contact your District Administrator
									and request <br/>
									that they create Grade Scales for your purple tabs. If you do not know who your
									District <br/>
									administrator is, please contact support@esgisoftware.com. Please note that you can
									continue to <br/>
									use Grade Scales on your gray tabs.
								</ko>
								<ko data-bind="if: selectedSubject() && selectedSubject().level == 'School'">
									Sorry. Your School Administrator has not yet configured Grade Scales which prevents<br/>
									you from running the Grade Report on this tab. Please contact your School Administrator
									and<br/>
									request that they create Grade Scales for your blue tabs. If you do not know who<br/>
									your School administrator is, please contact support@esgisoftware.com. Please note that
									you<br/>
									can continue to use Grade Scales on your gray tabs.
								</ko>
							</div>
						</div>
					</ko>
					<ko data-bind='if: selectedSubject().hasGradeScales && data.rows().length > 0'>
						<div className='report-body' data-bind="visible: !hasNotConfiguredSet() && data.rows().length > 0, css: { 'full-scrolling':isTouchDevice }">
							<div className='left-panel'>
								<div className='left-top-panel'>
									<table>
										<tr className='fake-row'>
											<td/>
										</tr>
										<tr className='first-header'>
											<ko data-bind="if: reportType() == 'Class' || reportType() == 'Group' || reportType() == 'SpecialistGroup'">
												<th className='entity-info-header' data-bind='attr: { colSpan: model.valueToSkip }'>
													<span className='header-title'>Students</span>
												</th>
											</ko>
											<ko data-bind="if: reportType() == 'StudentsSchool' || reportType() == 'SchoolSpecialistStudents'">
												<th className='entity-info-header' colSpan={2}>
													<span className='sub-header' data-bind='text: args()[0]'/>
												</th>
												<th className='entity-info-header' data-bind='attr: { colSpan: model.valueToSkip - 2}'>
													<span className='sub-header' data-bind='text: args()[1]'/>
												</th>
											</ko>
											<ko data-bind="if: reportType() == 'StudentsDistrict' || reportType() == 'DistrictSpecialistStudents' || reportType() == 'DistrictPreassess' || reportType() == 'StudentsSchoolsGroup' || reportType() == 'StudentsTeachersGroup' || reportType() == 'GroupOfSpecialistsStudents'">
												<th className='entity-info-header' data-bind='attr: { colSpan: model.valueToSkip }'>
													<span className='sub-header' data-bind='text:args()[0]'/>
												</th>
											</ko>
										</tr>
										<tr className='gr-table-subheader'>
											<ko data-bind="if: reportType() == 'Class' || reportType() == 'Group' || reportType() == 'SpecialistGroup'">
												<td className='entity-info-subheader subheader-title' data-bind='click: sort.bind($data, -1)'>
													<div>
														First Name <i data-bind='attr: {class: getCellSortClass(-1)}'/>
													</div>
												</td>
												<td className='entity-info-subheader subheader-title' data-bind='click: sort.bind($data, -2)'>
													<div>
														Last Name <i data-bind='attr: {class: getCellSortClass(-2)}'/>
													</div>
												</td>
												<ko data-bind='if: model.valueToSkip == 3'>
													<td className='entity-info-subheader subheader-title' data-bind='click: sort.bind($data, -3)'>
														<div>
															Student ID <i data-bind='attr: {class: getCellSortClass(-3)}'/>
														</div>
													</td>
												</ko>
											</ko>
											<ko data-bind="if: ['StudentsDistrict', 'StudentsSchool', 'DistrictPreassess', 'StudentsSchoolsGroup', 'StudentsTeachersGroup', 'GroupOfSpecialistsStudents'].includes(reportType())">
												<ko data-bind='foreach: data.headerColumns'>
													<td className='entity-info-subheader subheader-title' data-bind='click: function(data, event) { $parent.sort(-$index() - 1, data, event); }'>
														<div style={{position: 'relative'}}>
															<span data-bind='text: $data'/>
															<i data-bind='attr: {class: $parent.getCellSortClass(-$index() - 1)}'/>
														</div>
													</td>
												</ko>
											</ko>
										</tr>
									</table>
								</div>
								<div className='left-bottom-panel'>
									<div>
										<table className='table table-hover table-striped'>
											<tbody>
												<ko data-bind='foreach: data.rows'>
													<tr className='data-row-left'>
														<ko data-bind='foreach: entityInfo'>
															<td className='entity-info-cell' data-bind='text:$data.value'/>
														</ko>
													</tr>
												</ko>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className='right-panel'>
								<div className='right-top-panel'>
									<table data-bind="style: {width: (totalWidth() + 17) + 'px'}">
										<tr className='scale-settings'>
											<ko data-bind='foreach: data.tests'>
												<td data-bind="style: {width: width() + 'px'}">
													<a href='#' data-bind='click: $parent.view.scaleSettingsClick, visible: $parent.isMobile() || showSettings()'>View Range Settings</a>
												</td>
											</ko>
											<td className='cell4scroll'/>
										</tr>
										<tr className='test-names'>
											<ko data-bind='foreach: data.tests'>
												<td data-bind="attr: {class: 'test-name' + ($index() == $root.data.tests().length - 1 ? ' last' : '')}, style: {'width': width() + 'px'}">
													<div>
														<span data-bind='text:name'/>
														<span className='test-questionscount' data-bind="text:'(out of ' + questionsCount + ')'"/>
													</div>
												</td>
											</ko>
											<td className='cell4scroll'/>
										</tr>
										<tr className='gr-table-subheader'>
											<ko data-bind='foreach: data.tests'>
												<td data-bind="attr: {class: 'test-sort' + ($index() == $root.data.tests().length - 1 ? ' last' : '')}, style: {'width': (width() + ($index() == 0 ? 2 : 0)) + 'px'}">
													<table>
														<tr>
															<ko data-bind='if: $parent.isCellShown($index() * (periods.length + 1))'>
																<td data-bind="attr: {class: 'sort-period b-column' + ($parent.data.sortIndex() == $index() * (periods.length + 1) ? ' sorted' : '')}, style: {width: $parent.cellWidth($index() * (periods.length + 1), testID) + 'px'}, click: $parent.sort.bind($data, $index() * (periods.length + 1))">
																	<div style={{position: 'relative'}}>
																		<span>B</span>
																		<i data-bind='attr: {class: $parent.getCellSortClass($index() * (periods.length + 1))}'/>
																	</div>
																</td>
															</ko>
															<ko data-bind='foreach: periods'>
																<ko data-bind='if: $parents[1].isCellShown($parentContext.$index() * ($parent.periods.length + 1) + $index() + 1)'>
																	<td data-bind="attr: {class: 'sort-period' + ($parents[1].data.sortIndex() == $parentContext.$index() * ($parent.periods.length + 1) + $index() + 1 ? ' sorted' : '')}, click: $parents[1].sort.bind($data, $parentContext.$index() * ($parent.periods.length + 1) + $index() + 1), style: {width: $parents[1].cellWidth($parentContext.$index() * ($parent.periods.length + 1) + $index() + 1, $parentContext.$data.testID) + 'px'}">
																		<div style={{position: 'relative'}}>
																			<span data-bind='text: name'/>
																			<i data-bind='attr: {class: $parents[1].getCellSortClass($parentContext.$index() * ($parent.periods.length + 1) + $index() + 1)}'/>
																		</div>
																	</td>
																</ko>
															</ko>
														</tr>
													</table>
												</td>
											</ko>
											<td className='cell4scroll'/>
										</tr>
									</table>
								</div>
								<div className='right-bottom-panel' data-bind='event: { scroll: scrolled }'>
									<table className='table table-hover table-striped' data-bind="style: {'width': totalWidth() + 'px'}">
										<tbody>
										<ko data-bind='foreach: data.rows'>
											<tr className='data-row-right'>
												<ko data-bind='foreach: testResults'>
													<td className='result-info-cell' data-bind="text: score == null && $root.displayZeroIfNotTestedOption() == 'Zero' ? 0 : value, css: {'right-border': rightBorder, 'left-border': leftBorder }, style: {backgroundColor: score != null ? backgroundColor : null }"/>
												</ko>
											</tr>
										</ko>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</ko>
					<ko data-bind='if: !$root.selectedSubject().hasGradeScales'>
						<div className='add-scale-notification'>
							<div className='message'>Please, <a href='#' data-bind='click: view.editGradeScaleClick'>add a grade scale</a> to run a report for this subject tab.</div>
						</div>
					</ko>
				</div>
			</div>
		);
	}

	public static renderHeader() {
		return (
			<div className='report-header'>
				<a href='#' className='btn-back' data-bind='click: goBackClicked'>
					<i className='fa fa-angle-left'/>
					<span>Back</span>
				</a>
				<span className='report-name' data-bind='text: reportName'/>
				<div className='print-download-buttons'>
					<div id='downloadDropdown' className='btn-group' data-bind='visible: showDownload'>
						<span data-toggle='dropdown'><i className='fa fa-download'/> Download</span>
						<ul className='dropdown-menu dropdown-default'>
							<ko data-bind='if: allowExportPDF'>
							<li>
								<a href='#' data-bind='click: downloadPdf'>PDF</a>
							</li>
							</ko>
							<li>
								<a href='#' data-bind='click: downloadExcel'>Excel</a>
							</li>
						</ul>
					</div>
					<div data-bind='render: closer'/>
				</div>
			</div>
		);
	}
}
