import {useCallback, useEffect, useState} from 'react';
import {Portal} from '@esgillc/ui-kit/core';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './cookies-banner.module.less';
import {RoutesMap} from '@esgi/main/libs/core';
import {join} from '@esgillc/ui-kit/utils';

export function CookiesBannerLayer() {
	const cookiesAcceptedKey = 'gdpr_cookiesAccepted';
	const [showBanner, setShowBanner] = useState(false);

	useEffect(() => {
		const ready = () => setShowBanner(window.localStorage.getItem(cookiesAcceptedKey) !== '1');

		if (document.readyState !== 'loading') {
			ready();
		} else {
			document.addEventListener('DOMContentLoaded', ready);
		}
	}, []);

	const acceptCookies = useCallback(() => {
		window.localStorage.setItem(cookiesAcceptedKey, '1');
		setShowBanner(false);
	}, []);

	if (showBanner) {
		return <Portal owner={document.body}>
			<div className={join(styles.container, 'cookie-banner-container')} data-cy='cookie-banner-container'>
				<div className='cookie-banner-text' data-cy='cookie-banner-text'>
					This website stores cookies which are used to collect information in order to improve and customize
					your browsing experience. To find out more about the cookies we use, see our&nbsp;<a target='_blank' href={RoutesMap.privacyPolicy} rel='noreferrer'>Privacy Policy
					</a>.
				</div>
				<div className={join(styles.button, 'cookie-banner-button')} data-cy='cookie-banner-button'>
					<Buttons.Contained onClick={acceptCookies}>ACCEPT</Buttons.Contained>
				</div>
			</div>
		</Portal>;
	}
}