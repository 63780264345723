export class FormDataBuilder {
	static BuildParameters<T>(obj: T): FormData {
		const formData = new FormData();

		if (obj == null) {
			return formData;
		}

		FormDataBuilder.PopulateParams(formData, '', obj);
		return formData;
	}

	private static PopulateArray<T>(formData: FormData, prefix: string, val: Array<T>): void {
		for (let index = 0; index < val.length; index++) {
			const key = prefix + '[' + index + ']';
			const value = val[index];
			FormDataBuilder.PopulateParams(formData, key, value);
		}
	}

	private static PopulateObject<T>(formData: FormData, prefix: string, val: T): void {
		const objectKeys = Object.keys(val) as Array<keyof T>;

		if (prefix) {
			prefix = prefix + '.';
		}

		for (const objKey of objectKeys) {

			const value = val[objKey];
			const key = prefix + objKey.toString();

			FormDataBuilder.PopulateParams(formData, key, value);
		}
	}

	private static PopulateParams<T>(formData: FormData, key: string, value: any): void {
		if (value === null || value === undefined) {
			return;
		}

		if (value instanceof Array) {
			FormDataBuilder.PopulateArray(formData, key, value);
			return;
		}

		if (value instanceof Date) {
			formData.append(key, value.toISOString());
			return;
		}

		if (value instanceof Object) {
			FormDataBuilder.PopulateObject(formData, key, value);
			return;
		}

		formData.append(key, value.toString());
	}

}
