export function validateNumberValue(value: number) {
	if (isNaN(value)) {
		value = 1;
	}

	if (value < 1) {
		value = 1;
	}

	if (value > 40) {
		value = 40;
	}

	return value;
}