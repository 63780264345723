import {NotesModel} from '../../../../types/student-level-report-service-types';
import {isEmpty} from 'underscore';
import styles from './styles.module.less';

interface Props {
	showNotes: boolean;
	showSummary: boolean;
	note?: NotesModel | undefined;
}

export function Note({showNotes, showSummary, note}: Props) {
	if (!note) {
		return;
	}

	const {criteriaNotes, summaryNotes} = note;
	const isShowCriteriaNotes = showNotes && !isEmpty(criteriaNotes);
	const isShowSummaryNotes = showSummary && !isEmpty(summaryNotes);

	return (
		<div className={styles.container}>
			<div className={styles.notesContainer}>
				{isShowCriteriaNotes &&
					<>
						<h4 className={styles.h4}>Notes</h4>
						{criteriaNotes.map(({criteriaName, notes}, i) => (
							<p className={styles.p} key={i}>
								<span className={styles.title}>{criteriaName}: </span>
								{notes}
							</p>
						))}
					</>
				}
				{isShowSummaryNotes &&
					<>
						<h4 className={styles.h4}>Summary</h4>
						<p className={styles.summaryNotes}>{summaryNotes}</p>
					</>
				}
			</div>
		</div>
	);
}
