import React, {ReactNode} from 'react';
import {BoxType} from '../core/models';
import {AllBox, AllBoxOptions} from './all-box-item';
import {BoxItem} from './box-item';
import {EmptyBoxItem} from './empty-item';

export class State {
}

export class Props {
	items: ItemProps[];
	selectedID: number;
	preselectedID?: number;
	options: BoxOptions;
	empty: EmptyOptions;
	itemSelected: (item: number, callback: () => void) => void;
	onDragStart?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	onDragEnd?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	onAddClicked: () => void;
	onEditClicked: (id: number) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
	scheduledIDs?: Array<number>;
	open: boolean;
	afterRender?: (ref: React.RefObject<HTMLElement>) => void;
}

export interface EmptyOptions {
	message: string;
	tooltip?: string;
}

export interface BoxOptions {
	canEdit: boolean;
	canAdd: boolean;
	canView?: boolean;
	canDrag: boolean;
	boxType: BoxType;
	canViewTooltip?: string;
	canCreateTooltip: string;
	title: string;
	tooltipPostfix?: string;
	editIcon?: ReactNode;
	viewIcon?: ReactNode;
	editIconTooltip?: string;
}

export interface ItemProps {
	id: number;
	title: string;
	subItems?: SubItemProps[];
}

export interface SubItemProps {
	id: number;
	title: string;
	canEdit: boolean;
	onEdit: (id: number) => void;
}

export class Box extends React.Component<Props, State> {
	rootElement: HTMLDivElement;

	constructor(props, context) {
		super(props, context);
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (!prevProps.open && this.props.open) {
			$(this.rootElement).find('.box-body').slideDown();
		}
	}

	get items() {
		return this.props.items;
	}

	get addIconTooltipBody(): string {
		if (this.props.options.tooltipPostfix) {
			return 'Add ' + this.props.options.tooltipPostfix;
		}
	}

	get editIconTooltipBody(): string {
		if (this.props.options.tooltipPostfix) {
			return 'Edit ' + this.props.options.tooltipPostfix;
		}
	}

	get viewTooltipBody(): string {
		if (this.props.options.tooltipPostfix) {
			return 'View ' + this.props.options.tooltipPostfix;
		}
	}

	subItems(item: ItemProps): SubItemProps[] {
		return item.subItems ? item.subItems : [];
	}

	render() {
		return <div
			className={'box ' + this.props.options.boxType + ' ' + (!this.props.selectedID ? 'all-selected' : '')}
			ref={(n) => this.rootElement = n}>
			{this.renderAllRow()}
			<div className='box-body'>
				{this.props.open && this.renderBody()}
			</div>
		</div>;
	}

	renderAllRow() {
		const options: AllBoxOptions = {
			canAddTooltip: this.props.options.canCreateTooltip,
			canAdd: this.props.options.canAdd,
			title: this.props.options.title,
			addTooltip: this.addIconTooltipBody,
		};

		return <AllBox
			options={options}
			open={this.props.open}
			onOpenCloseChanged={(s) => this.openCloseChanged(s)}
			onSelectClicked={() => this.allBoxItemSelected()}
			onAddClicked={this.props.onAddClicked}
			itemsCount={this.items.length}
			selected={this.props.selectedID === 0}
			afterRender={this.props.afterRender}
		/>;
	}

	renderBody() {
		if (this.items.length) {
			return this.items.map(c => {
				return <BoxItem
					id={c.id}
					title={c.title}
					canDrag={this.props.options.canDrag}
					selected={c.id === this.props.selectedID}
					preselected={c.id === this.props.preselectedID}
					key={c.id}
					onSelect={() => this.itemSelected(c.id)}
					canEdit={this.props.options.canEdit}
					onEdit={this.props.onEditClicked}
					canView={this.props.options.canView}
					onDragStart={this.props.onDragStart}
					onDragEnd={this.props.onDragEnd}
					scheduled={this.props.scheduledIDs ? this.props.scheduledIDs.indexOf(c.id) !== -1 : false}
					subItems={this.subItems(c)}
					viewTooltip={this.props.options.editIconTooltip || this.viewTooltipBody}
					editIcon={this.props.options.editIcon}
					editTooltip={this.props.options.editIconTooltip || this.editIconTooltipBody}
				/>;
			});
		} else {
			return this.renderEmptyBox();
		}
	}

	renderEmptyBox() {
		const obj = this.props.empty;
		const tooltip = obj.tooltip ? obj.tooltip : obj.message;

		return <EmptyBoxItem title={obj.message} tooltip={tooltip}/>;
	}

	openCloseChanged(newState: boolean) {
		if (newState) {
			this.props.onOpenCloseChanged(this.props.options.boxType, newState);
		} else {
			$(this.rootElement).find('.box-body').slideUp({
				always: () => {
					this.props.onOpenCloseChanged(this.props.options.boxType, newState);
				},
			});
		}
	}

	allBoxItemSelected() {
		this.itemSelected(0);
	}

	private itemSelected = (id: number) => {
		const boxBody = $(this.rootElement).find('.box-body');
		const initialHeight = boxBody.height();
		this.props.itemSelected(id, () => {
			const height = boxBody.height();
			const oneItemHeight = $(this.rootElement).find('.item').height();
			const selectedId = this.items.findIndex(t => t.id === id);

			if ((oneItemHeight * (selectedId + 1)) > boxBody.height() && initialHeight !== height) {
				boxBody.scrollTop(oneItemHeight * selectedId);
			}
		});
	};
}
