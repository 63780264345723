import {createContext, useContext} from 'react';
import {SvgValue, UpdateShapeOffset} from './types';
import {noop} from 'underscore';

export type PieChartContextValue = {
	/**
	 * use for getting progress in float based on the [minValue, maxValue]
	 */
	getValueProgress: (value: number) => number;

	/**
	 * Containt the main svg params
	 */
	svgValue: SvgValue;

	/**
	 * Uses for update offset of each element, for updating the correct sizes and rendering figures in the svg
	 */
	updateShapeOffset: UpdateShapeOffset;

	/**
	 * Maximum offset value of all figures.
	 */
	maxShapeOffset: number;
};

export const PieChartContext = createContext<PieChartContextValue>({
	getValueProgress: () => 0,
	svgValue: {
		radius: 0,
		size: 0,
		center: {
			x: 0,
			y: 0,
		},
	},
	updateShapeOffset: noop,
	maxShapeOffset: 0,
});

export function usePieChartContext() {
	return useContext(PieChartContext);
}
