import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const Wrapper = styled(Box, {
	height: '80vh',

	'@media (max-width: 1024px)': {
		height: '60vh',
	},
});
