import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {MouseEvent, useCallback} from 'react';

type Props = {
	onClick?: VoidFunction | undefined;
	disabled?: boolean;
};

export function StartTestButton({onClick, disabled}: Props) {
	const handleClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			event.preventDefault();

			onClick?.();
		},
		[onClick],
	);

	return (
		<Button uppercase onClick={handleClick} disabled={disabled}>
			<Text size='large' color='vivid'>
				Test
			</Text>
		</Button>
	);
}
