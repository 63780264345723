import {GridBox} from '@esgi/ui/layout';
import {Delta, NumberRow} from '../../kit';
import {QuestionsService} from '../service';
import {useStudentsData} from '../../hooks';

type Props = {
	testIDs: number[],
	studentID: number,
	service: QuestionsService,
};

export function StudentView(props: Props) {
	const {loaded, data} = useStudentsData(props.service, props.studentID, props.testIDs, undefined);

	const {thisWeek, lastWeek} = data;

	return <GridBox css={{height: 80}} gapY='3' flow='row'>
		<NumberRow text={thisWeek} label='This Week' skeleton={!loaded}>
			<Delta skeleton={!loaded} value={thisWeek - lastWeek} unit='' >
				Since Last Week
			</Delta>
		</NumberRow>
		<NumberRow text={lastWeek} label='Last Week' skeleton={!loaded}/>
	</GridBox>;
}