import {Student} from '@esgi/main/libs/store';
import {getThemeColor} from '@esgi/ui';
import {AccessError} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';

type Props = Pick<Student, 'firstName' | 'lastName' | 'hasCredentials'>;

export function StudentInfo({firstName, lastName, hasCredentials}: Props) {
	const fullName = [firstName, lastName].filter(Boolean).join(' ').trim();

	return (
		<>
			<Text size='medium' bold>
				{fullName}
			</Text>
			{!hasCredentials && <AccessError width={24} height={24} color={getThemeColor('negative')} />}
		</>
	);
}
