import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {animated} from 'react-spring';

const StudentsPanelWidth = 54;

export const LayoutContainer = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	variants: {
		isTablet: {
			true: {
				gridTemplateColumns: 'auto',
				'& > div:last-child': {
					width: `calc(100% - ${StudentsPanelWidth}px)`,
					justifySelf: 'end',
				},
			},
		},
	},
});

export const IconBox = styled(Box, {
	width: 24,
	height: 24,

	'& svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: '$neutral56',
		},
	},
});

export const Overlay = styled(animated.div, {
	width: '100%',
	height: '100%',
	background: 'linear-gradient(332deg, $neutral94, $neutral98)',
	opacity: 0.88,
	variants: {
		isStudentPanelOpened: {
			false: {
				opacity: 0,
			},
		},
	},
});

export const PanelWrapper = styled(GridBox, {
	overflow: 'hidden',
	variants: {
		isTablet: {
			true: {
				position: 'absolute',
				top: '0',
				left: '0',
				width: '100%',
				height: '100%',
				zIndex: 1,
			},
		},
		isStudentPanelOpened: {
			true: {},
		},
	},
	compoundVariants: [
		{
			isTablet: true,
			isStudentPanelOpened: false,
			css: {
				width: StudentsPanelWidth,
				transition: 'width .3s',
			},
		},
	],
});
