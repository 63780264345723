import {Text} from '@esgi/ui/typography';
import {Select as SelectUI} from '@esgi/ui/controls';
import {SelectField, SelectOption, SortIcon} from './index.styled';
import {OptionItem} from '../../../../types';
import {SortDirection} from '@esgi/contracts/esgi/types/esgi.enums/sort-direction';

type Props = {
	selectedValue: string;
	items: OptionItem[];
	onValueChange: (value: string) => void;
	placeholder: string;
	disabled?: boolean;
	sortDirection?: SortDirection;
	onSortDirectionChange?: VoidFunction;
	dataCy?: string;
};

export function SortableSelect({
	selectedValue,
	onValueChange,
	onSortDirectionChange,
	items,
	placeholder,
	disabled,
	dataCy,
	sortDirection,
}: Props) {
	return (
		<SelectUI.Root value={String(selectedValue)} onValueChange={onValueChange} disabled={disabled} dataCy={dataCy} showAdditionalItem>
			<SelectField placeholder={placeholder} dataCy={`${dataCy}-field`} >
				<SortIcon onClick={onSortDirectionChange} direction={sortDirection}/>
			</SelectField>
			<SelectUI.Content dataCy={`${dataCy}-content`}>
				{items.map(({label, value}) => (
					<SelectOption value={String(value)} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</SelectOption>
				))}
			</SelectUI.Content>
		</SelectUI.Root>
	);
}