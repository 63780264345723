import React from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {BaseCardState} from './base-card';

export class State extends BaseCardState {
	url: string;
	checked: boolean = false;
	createDate: Date;
}

export class Props extends SharedProps<State> {
	selectable: boolean = false;
	click: (id: number) => any;
}

export class ImageCard extends SharedComponent<State, Props> {

	render(): JSX.Element | false | null {
		return <OnHoverTooltip message={this.state.name}>
			<div
				className={'card' + (this.state.deleted ? ' deleted' : '') + (this.state.checked ? ' checked' : '')}
				style={{backgroundImage: `url(${this.state.url})`}}
				onClick={() => this.imageClicked()}>
				<div className={'actions-row' + (this.props.selectable ? ' selectable' : '')}>
					{this.renderActions()}
				</div>
			</div>
		</OnHoverTooltip>;
	}

	renderActions() {
		if (!this.state.stock && this.props.selectable) {
			return <div>
				<div className='form-group'>
					<input type='checkbox' className='form-control' checked={this.state.checked}
					       onChange={(e) => {
						       e.stopPropagation();
						       this.setState({checked: !this.state.checked});
					       }}/>
				</div>
			</div>;
		}
	}

	imageClicked() {
		if (this.props.selectable) {
			this.setState({checked: !this.state.checked});
		} else {
			this.props.click(this.state.id);
		}
	}
}