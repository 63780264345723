import {PropsWithChildren, useMemo} from 'react';
import {ReportModalBody} from '@esgi/deprecated/ui-kit/report/modal';
import {useStream} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {RubricResultReportService} from '../../../../../../service';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Reports} from '@esgi/main/kits/reports';
import {FileTypeStudentLevel} from '../../../../../../types/table-level-report-service-types';
import {Text} from '@esgi/ui/typography';

type ModalProps = PropsWithChildren<{
	onClosed: () => void;
	loading: boolean;
	service: RubricResultReportService;
}>;

export function Modal({loading, onClosed, service, children}: ModalProps) {
	const alertRef = Alert.useRef();
	const selectedStudent = useStream(service.studentLevelService.selectedStudent$);
	const allStudentsSelected = selectedStudent?.id === 0;

	const menuItems = useMemo(() => {
		const items = [{text: 'PDF file', value: FileTypeStudentLevel.Pdf, description: 'per student or class'}];
		if (allStudentsSelected) {
			items.push({text: 'Zip file', value: FileTypeStudentLevel.Zip, description: 'per class'});
		}
		return items;
	}, [allStudentsSelected]);

	const onClose = Alert.useClose(alertRef, () => {
		onClosed();
	});

	return (
		<Alert modalManagerRef={alertRef}
		       colorConfig={alertColorsConfig.neutral}
		       defaultOpen={false}
					 dataCy='rubric-results'>
			<Alert.Header
				withBacklight={false}
				onCloseIconClick={onClose}
			>
				<Text data-cy='rubric-results-title' size='large'>Rubric Results</Text>
			</Alert.Header>
			<ReportModalBody className={styles.body}>{children}</ReportModalBody>
			<Alert.Footer>
				<Reports.DownloadButton
					menuItems={menuItems}
					onClick={(fileType) => service.studentLevelService.onDownload(fileType as FileTypeStudentLevel)}
				/>
			</Alert.Footer>
		</Alert>
	);
}
