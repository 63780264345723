import React from 'react';
import styles from './styles.module.less';
import {Input} from '@esgillc/ui-kit/control';
import {SearchIcon} from '@esgillc/ui-kit/icons';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
	value: string;
	onChange: (value: string) => void;
}

export function Search({className, onChange, value}: Props) {
	return (
		<div className={join(className, styles.container)}>
			<Input placeholder='Search Specific Student' className={styles.searchInput} value={value}
			       onChange={e => onChange(e.target.value)}/>
			<div className={styles.iconContainer}>
				<SearchIcon/>
			</div>
		</div>
	);
}
