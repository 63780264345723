import * as React from 'react';
import type {SVGProps} from 'react';

export function UserList(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M12.5 7a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm0 2a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM9.062 17.344C7.228 18.101 6 19.956 6 22v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2c0-1.996-1.235-3.806-3.062-4.594a1.021 1.021 0 0 0-.813 0c-.885.397-1.747.594-2.625.594-.875 0-1.74-.208-2.656-.625a.965.965 0 0 0-.782-.031Zm.385 2.046c.945.358 2.084.61 3.053.61.961 0 2.053-.235 2.979-.577C16.382 19.937 17 20.899 17 22v1H8v-1c0-1.165.656-2.147 1.447-2.61ZM20 11a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2h-5Zm-1 4a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-6Zm3 4a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2h-3Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
