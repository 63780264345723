import {BehaviorSubject, Observable} from 'rxjs';
import {BroadcastEventManager, dispatchAppEvent} from '@esgillc/events';
import {SchoolYearChangedEvent} from 'modules/school-year';
import {InitModel, YearModel} from 'pages/student-manager/models/models';
import {map, shareReplay} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {ClearStorageEvent} from '@esgi/main/libs/store';

export class SchoolYearsService extends BaseService {
	public readonly controller = 'home/school-year-selector';

	public readonly schoolYears$: Observable<YearModel[]>;
	public readonly currentSchoolYearID$: Observable<number>;

	public readonly selectedSchoolYearID$ = new BehaviorSubject<number>(userStorage.get().globalSchoolYearID);

	private broadcastEventBus = new BroadcastEventManager();

	constructor() {
		super();
		const request$ = this.httpClient.ESGIApi.get<InitModel>(this.controller, 'init').pipe(shareReplay()).asObservable();
		this.schoolYears$ = request$.pipe(map(r => r.schoolYears));
		this.currentSchoolYearID$ = request$.pipe(map(r => r.currentGlobalSchoolYearID));

		this.broadcastEventBus
			.aggregate({SchoolYearChangedEvent})
			.subscribe((aggregation) => {
				const schoolYear = userStorage.get().globalSchoolYearID;
				this.selectedSchoolYearID$.next(schoolYear);
			});
	}

	public switch(globalSchoolYearID: number): void {
		this.httpClient.ESGIApi
			.post<void>(this.controller, 'switch', {globalSchoolYearID})
			.subscribe(() => {
				userStorage.update({globalSchoolYearID: globalSchoolYearID});
				dispatchAppEvent(SchoolYearChangedEvent, new SchoolYearChangedEvent(globalSchoolYearID));
				dispatchAppEvent(ClearStorageEvent);

				this.selectedSchoolYearID$.next(globalSchoolYearID);
			});
	}

	public destroy() {
		super.destroy();
		this.selectedSchoolYearID$.complete();
		this.broadcastEventBus.destroy();
	}
}
