import {useEffect, useState} from 'react';
import {getGroupsContentData} from './utils';
import {Class, Group, Student} from '@esgi/main/libs/store';
import {AllOptionItem, GroupBucket} from './types';
import {StudentSort, useUser} from '@esgi/core/authentication';


export function useGroupsContentState({
	groups,
	allStudents,
	selectedClassId,
	sortByData,
}: {
	groups: Group[];
	allStudents: Student[];
	selectedClassId: Class['id'] | AllOptionItem.Value;
	sortByData?: StudentSort;
}) {
	const [groupsWithStudents, setGroupsWithStudents] = useState<GroupBucket[]>([]);
	const [studentsWithoutGroup, setStudentsWithoutGroup] = useState<Student[]>([]);
	const user = useUser();

	useEffect(() => {
		const sortBy = sortByData || user?.studentSort || StudentSort.FirstName;
		const {studentsWithoutGroup, groupsWithStudents} = getGroupsContentData({selectedClassId, groups, allStudents, sortBy});
		setGroupsWithStudents((currentGroupsList) =>
			groupsWithStudents.map<GroupBucket>((groupData) => {
				
				const current = currentGroupsList.find((statedGroupData) => groupData.id === statedGroupData.id);

				return {...groupData, isListOpen: current?.isListOpen ?? false};
			}),
		);

		setStudentsWithoutGroup(studentsWithoutGroup);
	}, [selectedClassId, groups, allStudents, sortByData, user?.studentSort]);

	return {
		groupsWithStudents,
		studentsWithoutGroup,
	};
}
