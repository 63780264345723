import {ListItem, Container} from './index.styled';
import {Select, Input} from '@esgi/ui/controls';
import {Button, isUndefined} from '@esgi/ui';
import {CorrectAnswer, Reset} from '@esgi/ui/icons';
import {TestSession} from '../../../../types';
import {useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {formatDateTimeCustom} from '../../../../utils';

interface Props {
	selectedSession: TestSession;
	sessions: TestSession[];
	onChangeSession: (session: TestSession) => void;
	testResultsIncorrectVerbiage: string;
}

export function ItemCustomizationPanel({selectedSession, sessions, onChangeSession, testResultsIncorrectVerbiage}: Props) {

	const [incorrectAnswersToInclude, setIncorrectAnswersToInclude] = useState<string | undefined>(selectedSession.incorrect.toString());
	const [selectedSessionID, setSelectedSessionID] = useState<string | undefined>(selectedSession.sessionID.toString());

	const onReset = () => {
		const lastSession = sessions[sessions.length - 1];

		if (selectedSessionID !== lastSession.sessionID.toString() || Number(incorrectAnswersToInclude) !== lastSession.incorrect) {
			setSelectedSessionID(lastSession.sessionID.toString());
			setIncorrectAnswersToInclude(lastSession.incorrect.toString());
		}
	};

	const handleChangeSessionData = () => {
		if (selectedSessionID !== selectedSession.sessionID.toString() || Number(incorrectAnswersToInclude) !== selectedSession.incorrect) {
			const session = sessions.find(s => s.sessionID === Number(selectedSessionID));

			if(!isUndefined(session) && !isUndefined(incorrectAnswersToInclude) && Number(incorrectAnswersToInclude) <= session.incorrect) {
				onChangeSession({...session, incorrect: Number(incorrectAnswersToInclude)});
			}
		}
	};

	const handleChangeSelectedSession = (sessionID: string) => {
		const session = sessions.find(s => s.sessionID === Number(sessionID));
		setSelectedSessionID(sessionID);
		setIncorrectAnswersToInclude(session.incorrect.toString());
	};

	return <ListItem>
		<Container>
			<Select.Root value={selectedSessionID} onValueChange={handleChangeSelectedSession}>
				<Select.Field placeholder='Session'/>
				<Select.Content>
					{sessions.map((session) => (
						<Select.Option key={session.sessionID} value={session.sessionID.toString()}>
							<Text size='small'>{formatDateTimeCustom(session.testDate)}</Text>
						</Select.Option>
					))}
				</Select.Content>
			</Select.Root>

			<Input
				placeholder={`${testResultsIncorrectVerbiage} answers to include`}
				value={incorrectAnswersToInclude}
				onChange={(e) => setIncorrectAnswersToInclude(e.target.value)}
			/>
		</Container>

		<Button.Icon onClick={onReset}>
			<Reset width={24} height={24}/>
		</Button.Icon>

		<Button.Icon onClick={handleChangeSessionData}>
			<CorrectAnswer width={24} height={24}/>
		</Button.Icon>
	</ListItem>;
}
