import React from 'react';
import {join} from '@esgillc/ui-kit/utils';

class Props {
	className?: string;
	onClicked?: () => void;
	title?: string;
}

export class CloseButton extends React.PureComponent<Props> {
	public render() {
		return <a href='#'
		          className={join('close', this.props.className)}
		          onClick={this.props.onClicked}
		          title={this.props.title}>
			<svg width='24' height='24' viewBox='0 0 48 48'>
				<g fill='none' fillRule='evenodd'>
					<rect width='48' height='48'/>
					<path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'
					      transform='matrix(-1 0 0 1 48 0)'/>
					<path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'/>
				</g>
			</svg>
		</a>;
	}
}
