import {useState, useRef, RefObject, useEffect} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {AddNoteIcon, EditNoteIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {NoteEditorState} from './types';
import styles from './styles.module.less';

interface Props {
	question: string;
	note: string;
	onChange: (state: NoteEditorState) => void;
	onSave: (noteText: string) => void;
	onDelete: () => void;
	canEdit: boolean;
}

export function NoteEditor({
	question,
	note,
	onChange,
	onSave,
	onDelete,
	canEdit,
}: Props): JSX.Element {
	const maxChars = 140;
	const [state, setState] = useState(new NoteEditorState(note));
	const noteRef: RefObject<HTMLTextAreaElement> = useRef();

	useEffect(() => {
		onChange(state);
	}, [state]);

	const setText = (text: string) => {
		setState({
			on: true,
			text,
			dirty: note !== text,
		});
	};

	const setOn = (on: boolean) => {
		setState({
			...state,
			on,
		});

		if (on) {
			setTimeout(() => {
				const caret = note && note.length || 0;
				noteRef.current.setSelectionRange(caret, caret);
			}, 10);
		}
	};

	const onSaveInternal = () => {
		onSave(state.text);
		setOn(false);
	};

	const onCancel = () => {
		setState({
			...state,
			text: note,
			on: false,
		});
	};

	const onDeleteInternal = () => {
		onDelete();
		setOn(false);
	};

	return (
		<>
			{(note && !!note.length) &&
				<OnHoverTooltip message={note}>
					<div className={styles.note} onClick={() => setOn(true)}>
						<EditNoteIcon/>
					</div>
				</OnHoverTooltip>
			}
			{(!note && canEdit) &&
				<OnHoverTooltip message='Add note1'>
					<div className={styles.addNote} onClick={() => setOn(true)}>
						<AddNoteIcon/>
					</div>
				</OnHoverTooltip>
			}
			{state.on &&
				<div className={styles.stickyNote}>
					<div className={styles.noteContainer}>
						<div className={styles.noteName}>
							<span>Question: {question}</span>
							{canEdit &&
								<OnHoverTooltip message={'Delete note'}>
									<a
										href='#'
										className={`action-link ${styles.deleteNoteLink}`}
										onClick={() => OldAlerts.bsconfirm({
											message: 'Delete Note?',
											modal: {
												buttons: [
													{
														title: 'CANCEL',
														className: 'btn btn-transparent btn-primary',
														closeModal: true,
													},
													{
														title: 'DELETE',
														className: 'btn btn-transparent btn-primary',
														closeModal: true,
														onClick: onDeleteInternal,
													},
												],
											},
										})}
									>
										<svg xmlns='http://www.w3.org/2000/svg' width='13' height='18' viewBox='0 0 13 13' fill='none'>
											<path
												d='M1.16671 13.3333C1.16671 14.25 1.91671 15 2.83337 15H9.50004C10.4167 15 11.1667 14.25 11.1667 13.3333V3.33333H1.16671V13.3333ZM12 0.833333H9.08337L8.25004 0H4.08337L3.25004 0.833333H0.333374V2.5H12V0.833333Z'
												fill='#0088CC'></path>
										</svg>
									</a>
								</OnHoverTooltip>
							}
						</div>
						<div className={styles.noteDescription}>
							Notes:
						</div>
						<textarea ref={noteRef}
							readOnly={!canEdit}
							className={styles.noteText}
							maxLength={maxChars}
							defaultValue={note}
							autoFocus={true}
							onInput={(e: any) => setText(e.target.value)}
						/>
						<div className={styles.noteControls}>
							<div className={styles.noteCharactersLeft}>{maxChars - state.text.length} characters left (max 140)</div>
							<div>
								<Buttons.Gray
									className='btn-close'
									onClick={onCancel}
								>
									Cancel
								</Buttons.Gray>
								{canEdit &&
									<Buttons.Contained
										disabled={!state.dirty}
										onClick={onSaveInternal}
									>
										Save
									</Buttons.Contained>
								}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
}
