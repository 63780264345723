import {BaseApi} from '@esgi/contracts';
import {useMemo} from 'react';

export function useSsoApiGateway() {
	return useMemo(() => new Api(), []);
}

export class Api extends BaseApi {
	protected apiName = 'sso-apigateway';
}
