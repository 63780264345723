/* eslint-disable @typescript-eslint/member-ordering */
import {BehaviorSubject} from 'rxjs';
import {TableService} from './table-service';
import {Student} from '../types/types';
import {ViewFilterValue} from '../components/filters/types';

export abstract class FilterService extends TableService {
	public readonly allStudentsIds$ = new BehaviorSubject<Student['id'][]>([]);

	private filterView$ = new BehaviorSubject(ViewFilterValue.AllStudents);

	protected get allStudentsIds() {
		return this.allStudentsIds$.value;
	}

	private get filterView() {
		return this.filterView$.value;
	}

	public setFilterViewValue(value: ViewFilterValue) {
		this.filterView$.next(value);
	}

	protected setTableData(studentsIds: Student['id'][]) {
		this.tableData$.next([]);

		const filteredStudents = this.allTableData.filter(({id: studentId, userName, password}) => {
			if (!studentsIds.includes(studentId)) {
				return false;
			}

			if (this.filterView === ViewFilterValue.StudentsWithoutCredentials) {
				return !userName || !password;
			}

			return true;
		});

		this.tableData$.next(filteredStudents);
		this.sortTableData();
	}

	public abstract updateTableData(): void;

	public getCountCurrentStudentsWithoutCredentials() {
		const studentsWithoutCredentials = this.tableData.filter(({userName, password}) => !userName || !password);

		return studentsWithoutCredentials.length;
	}
}
