import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Label, OnErrorTooltip, TextArea, Hint} from '@esgillc/ui-kit/form-control';
import styles from '../styles.module.less';

const MAX_LENGTH = 300;

type Props = {
	control: IFormControl;
	editMode: boolean;
}

export const IEPGoalField = ({control, editMode}: Props): JSX.Element => (
	<FormElement
		className={styles.textAreaFormElement}
		control={control}
	>
		<Label className={styles.label}>IEP Goal</Label>
		{editMode
			? <>
				<div className={styles.field}>
					<TextArea
						rows={4}
						maxLength={MAX_LENGTH}
						autoResizeToFit
						placeholder='Enter goal for this test'
					/>
					<Hint className={styles.limitCounter}>
						{(props) => `${MAX_LENGTH - (props.value?.length | 0)} characters left (${MAX_LENGTH} max)`}
					</Hint>
				</div>
				<OnErrorTooltip showOnError='required' placement='left'>Required</OnErrorTooltip>
			</>
			: <div className={styles.field}>{control.value}</div>}
	</FormElement>
);
