import {useMemo} from 'react';
import {Chart} from './components/chart';
import {Header} from './components/header';
import {Modal} from './components/modal';
import {Note} from './components/note';
import {StudentLevelReportModel} from '../../../../types/student-level-report-service-types';
import {Table} from './components/table';
import {useBehaviorSubject, useStream} from '@esgillc/ui-kit/utils';
import {RubricResultReportService} from '../../../../service';
import {ContainerStyled, PageStyled} from './index.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type StudentLevelReportProps = {
	report: StudentLevelReportModel;
	onClosed: () => void;
	service: RubricResultReportService;
};

export function StudentLevelReport({onClosed, service}: StudentLevelReportProps) {
	const showNotes = useBehaviorSubject(service.studentLevelService.showNotes$);
	const showSummary = useBehaviorSubject(service.studentLevelService.showSummary$);

	const loading = useStream(service.studentLevelService.studentLevelLoader$);
	const tables = useStream(service.studentLevelService.tables$);
	const charts = useStream(service.studentLevelService.charts$);
	const notes = useStream(service.studentLevelService.notes$);

	const chartsContent = useMemo(
		() =>
			charts?.map(({chart, studentID}) => {
				const table = tables?.find(({studentID: tableStudentId}) => tableStudentId === studentID);
				const note = notes?.find(({studentID: noteStudentId}) => noteStudentId === studentID);

				return (
					<PageStyled dataCy='rubric-results-report' key={studentID}>
						<Table table={table} />
						<Chart chart={chart} />
						<Note showNotes={showNotes} showSummary={showSummary} note={note} />
					</PageStyled>
				);
			}),
		[charts, tables, notes, showNotes, showSummary],
	);

	return (
		<Modal service={service} loading={loading} onClosed={onClosed}>
			<Header service={service} />
			<OverlayScrollbarsComponent
				defer
				style={{
					height: 'calc(100% + 0px)',
				}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}>
				<ContainerStyled>{chartsContent}</ContainerStyled>
			</OverlayScrollbarsComponent>
		</Modal>
	);
}
