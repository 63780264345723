import {TestType} from '@esgi/core/enums';
import {userStorage} from '@esgi/core/authentication';
import {RubricModel} from '../common/types';

export class RubricCreatedEvent {
	constructor(public id: number, public createdDate: string, rubricModel: RubricModel) {
		const currentUser = userStorage.get();
		this.name = rubricModel.name;
		this.color = rubricModel.color;
		this.questionsCount = rubricModel.criteriaModels.length;
		this.author = currentUser.firstName + ' ' + currentUser.lastName;
	}
	public type: TestType = TestType.Rubric;
	public name: string;
	public color: string;
	public author: string;
	public questionsCount: number;
}
