import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Checkbox} from '@esgi/ui/controls';

export const Section = styled(FlexBox, {
	flexDirection: 'column',
	padding: '0 29px',

	'& > div': {
		marginTop: '12px',
	},
});

export const Title = styled(Text, {
	padding: '20px 0 12px',
	margin: '0 12px 4px 12px',
	borderBottom: '1px solid $mild',
	textAlign: 'center',
	display: 'block',
});

export const CheckboxControl = styled(Checkbox, {
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'flex-start',
	borderRadius: '6px',
	padding: '8px',
	width: '100%',
	marginTop: '8px',
});
