import {UserType} from '@esgi/core/authentication';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	PreAssessHierarchyLevel,
	SpecialistHierarchyLevel,
} from '../core/models';
import {HierarchyState} from './hierarchy-data-service';
import {StudentSorter} from 'global/utils/StudentSorter';


export function buildHierarchyInstance(hierarchyState: HierarchyState): HierarchyInstance {
	const {mode, classic, specialist, preAssess, students, teachers, specialistGroups, classes, groups} = hierarchyState;
	const h = HierarchyInstance.build(mode, classic, specialist, preAssess);
	h.students = StudentSorter.sortStudents(students.items, students.sortBy);
	h.teachers = teachers.items;
	h.specialistGroups = specialistGroups.items;
	h.classes = classes.items;
	h.groups = groups.items;
	h.trackID = calcTrackID(h);
	h.title = renderSelectedHierarchyItemText(h, hierarchyState);
	h.studentSort = students.sortBy;
	h.snapshot.studentSort = h.studentSort;
	return h;
}

function calcTrackID(h: HierarchyInstance): number {
	let userID = 0;
	if (h.mode === HierarchyMode.Classic) {
		userID = h.classic.teacherID;
		if (userID !== 0) {
			const teacher = h.teachers.find(t => t.userID === userID);
			if (teacher) {
				return teacher.trackID;
			}
		}
		return 0;
	}

	if (h.mode === HierarchyMode.Specialist) {
		return 0;
	}

	return 0;
}

function renderSelectedHierarchyItemText(h: HierarchyInstance, hierarchyState: HierarchyState): string {
	if (h.mode === HierarchyMode.Classic) {
		const selected = h.classic.selected;
		const teacher = h.teachers.find(t => t.userID === h.classic.teacherID);
		const teacherName = teacher ? `${teacher.firstName} ${teacher.lastName}` : '';
		switch (selected.level) {
			case ClassicHierarchyLevel.Student:
				const student = hierarchyState.students.items.find(t => t.studentID === selected.levelID);
				return student ? `${student.firstName} ${student.lastName}` : '';

			case ClassicHierarchyLevel.Class:
				const cl = hierarchyState.classes.items.find(t => t.classID === selected.levelID);
				return teacherName + ' | ' + cl?.name;

			case ClassicHierarchyLevel.Group:
				const g = hierarchyState.groups.items.find(t => t.groupID === selected.levelID);
				return teacherName + ' | ' + g.name;

      case ClassicHierarchyLevel.SchoolsGroup:
        const sg = hierarchyState.schoolsGroups.items.find(t => t.groupID === selected.levelID);
        return sg.name;

      case ClassicHierarchyLevel.TeachersGroup:
        const tg = hierarchyState.teachersGroups.items.find(t => t.groupID === selected.levelID);
        return tg.name;

			case ClassicHierarchyLevel.Teacher:
				return teacherName;

			case ClassicHierarchyLevel.School:
				const school = hierarchyState.schools.items.find(s => s.schoolID === selected.levelID);
				return `${school.name} Summary`;

			case ClassicHierarchyLevel.District:
				return `${hierarchyState.districtName} Summary`;
			default:
				return '';
		}
	}

	if (h.mode === HierarchyMode.Specialist) {
		const selected = h.specialist.selected;
		const user = h.specialist.type === UserType.ISD
			? hierarchyState.districtSpecialists.find(t => t.userID === h.specialist.userID)
			: hierarchyState.schoolSpecialists.find(t => t.userID === h.specialist.userID);
		switch (selected.level) {
			case SpecialistHierarchyLevel.District:
				return `${hierarchyState.districtName} Summary`;
			case SpecialistHierarchyLevel.Type:
				const type = h.specialist.type;
				if (type === UserType.ISD) {
					return 'District Specialists Summary';
				}
				if (type === UserType.ISS) {
					return 'School Specialists Summary';
				}
				return '';
			case SpecialistHierarchyLevel.User:
				return `${user.firstName} ${user.lastName}`;
			case SpecialistHierarchyLevel.Group:
				const group = hierarchyState.specialistGroups.items.find(t => t.groupID === selected.levelID);
				return `${user.firstName} ${user.lastName} | ${group.name}`;
			case SpecialistHierarchyLevel.Student:
				const student = hierarchyState.students.items.find(t => t.studentID === selected.levelID);
				return student ? `${student.firstName} ${student.lastName}` : '';
			default:
				return '';
		}
	}

	if (h.mode === HierarchyMode.PreAssess) {
		const selected = h.preAssess.selected;
		const user = hierarchyState.preAssesses.find(t => t.userID === h.preAssess.userID);
		switch (selected.level) {
			case PreAssessHierarchyLevel.District:
				return `${hierarchyState.districtName} Summary`;
			case PreAssessHierarchyLevel.User:
				return `${user.firstName} ${user.lastName}`;
			case PreAssessHierarchyLevel.Group:
				const group = hierarchyState.specialistGroups.items.find(t => t.groupID === selected.levelID);
				return `${user.firstName} ${user.lastName} | ${group.name}`;
			case PreAssessHierarchyLevel.Student:
				const student = hierarchyState.students.items.find(t => t.studentID === selected.levelID);
				return student ? `${student.firstName} ${student.lastName}` : '';
			default:
				return '';
		}
	}

	return '';
}
