import {Student, SubjectTab} from '@esgi/main/libs/store';
import {StudentAvatar} from '../student-avatar';
import {StudentCounter} from '../student-counter';
import {Divider, Header as HeaderBox} from './index.styled';
import {SubjectName} from '@esgi/main/kits/common';

interface Props {
	studentCounter: number;
	student: Student;
	subject?: SubjectTab;
	counterTitle: string;
}

export function Header({studentCounter, student, subject, counterTitle}: Props) {
	const renderStudent = () => {
		if (student && studentCounter === 1) {
			const {firstName, lastName, photoUrl} = student;
			return <StudentAvatar firstName={firstName} lastName={lastName} photoUrl={photoUrl} />;
		}
		return <StudentCounter title={counterTitle} count={studentCounter} />;
	};

	return (
		<HeaderBox flow='column' gap='4' align='center'>
			{renderStudent()}
			{subject && (
				<>
					<Divider />
					<SubjectName subjectLevel={subject.level} noWrap size='large' bold>
						{subject.name}
					</SubjectName>
				</>
			)}
		</HeaderBox>
	);
}
