import {createContext} from '@esgi/ui';
import {MultipleSelectedState, SingleSelectedState} from '@esgi/main/kits/common';

export interface TestsPanelContextValue {
	studentsState: SingleSelectedState | MultipleSelectedState;
}

export const {
	Context: TestsPanelContext,
	useHook: useTestsPanelContext,
} = createContext<TestsPanelContextValue>('Test Panel Context');