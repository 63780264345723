import React, {Dispatch, useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {SetSelectedSubjectIdParams, SubjectTabId} from '../../types';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useAddSectionModeItems, useAllTabLists, useSectionModeButtons, useSectionTitle, useTabItems} from './hooks';
import {
	ExpandablePanelContentWrapper,
	Tabs,
	TitleWithActionButtons,
	EmptyContentTemplate,
	SubjectSectionMode,
	SubjectSection,
	useSubjectSectionContext,
} from '@esgi/main/features/teacher/home';
import {Task} from '@esgi/ui/icons';
import {getSectionModePermissionsData} from './utils';
import {DataSubjectsService} from '../../services/data-subjects-service';
import {useEventEffect} from '@esgillc/events';
import {Student, SubjectTab} from '@esgi/main/libs/store';
import {FlexBox} from '@esgi/ui/layout';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {PersonalSubjectDialog} from '@esgi/main/features/teacher/personal-subject-dialog';
import {SubjectRemovedEvent, SubjectChangedEvent, SubjectCreatedEvent} from '@esgi/main/features/teacher/personal-subject-dialog/events';

type Props = {
	dataService: DataSubjectsService;
	districtSubjects: SubjectTab[];
	schoolSubjects: SubjectTab[];
	personalSubjects: SubjectTab[];
	activeTab: SubjectTabId;
	selectedStudent: Student | null;
	setActiveTab: (tabId: SubjectTabId) => void;
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: Dispatch<SetSelectedSubjectIdParams>;
};

export function SubjectSectionContent({
	dataService,
	districtSubjects,
	schoolSubjects,
	personalSubjects,
	selectedSubjectId,
	setSelectedSubjectId,
	activeTab,
	setActiveTab,
	selectedStudent,
}: Props) {
	const {sectionMode, setSectionMode, setLoaded, loaded} = useSubjectSectionContext();

	const [isAddSubjectDialogOpened, setIsAddSubjectDialogOpened] = useState(false);
	const [openedEditSubjectID, setOpenedEditSubjectID] = useState<SubjectTab['id'] | null>(null);

	const filteredPersonalSubjects = useMemo(() => {
		if (sectionMode === SubjectSectionMode.Edit) {
			return personalSubjects;
		}

		return personalSubjects.filter(({hidden}) => !hidden);
	}, [personalSubjects, sectionMode]);

	const isLoaded = useBehaviorSubject(dataService.isLoadedData$); //TODO HOT FIX. ESGI-29506

	useEffect(() => {
		if(isLoaded) {
			setLoaded(true);
		}
	}, [isLoaded]);

	const containSubjects = Boolean(districtSubjects.length || schoolSubjects.length || personalSubjects.length);

	useEffect(() => {
		if (containSubjects && !selectedSubjectId) {
			const firstSubject = [...districtSubjects, ...schoolSubjects, ...personalSubjects][0];
			if (firstSubject) {
				setSelectedSubjectId({subjectId: firstSubject.id, belongsTo: dataService.getBelongToValue(firstSubject.id)});
			}
		}
	}, [containSubjects]);

	const filterSubjects = useCallback(
		(subjects: SubjectTab[]) => {
			if (!isNull(selectedStudent)) {
				return subjects.filter((x) => x.gradeLevels.includes(selectedStudent.gradeLevelID));
			}
			return subjects;
		},
		[selectedStudent],
	);

	const allSubjects = useMemo(
		() => [...filterSubjects(districtSubjects), ...filterSubjects(schoolSubjects), ...filteredPersonalSubjects],
		[districtSubjects, schoolSubjects, filteredPersonalSubjects, filterSubjects],
	);

	useLayoutEffect(() => {
		const selectedSubject = allSubjects.find(({id}) => id === selectedSubjectId);
		const firstSubject = allSubjects[0];
		if (isUndefined(selectedSubject) && allSubjects.length) {
			setSelectedSubjectId({subjectId: firstSubject.id, belongsTo: dataService.getBelongToValue(firstSubject.id)});
		}
	}, [allSubjects, selectedSubjectId]);

	const [sectionModePermissions, setSectionModePermissions] = useState(
		getSectionModePermissionsData({personalSubjects: filteredPersonalSubjects}),
	);

	useEffect(() => {
		setSectionModePermissions(getSectionModePermissionsData({personalSubjects: filteredPersonalSubjects}));
	}, [filteredPersonalSubjects]);

	const {allTabLists} = useAllTabLists({
		districtSubjects,
		schoolSubjects,
		personalSubjects: filteredPersonalSubjects,
		dataService,
		filterSubjects,
	});

	const {sectionTitle} = useSectionTitle({sectionMode});

	const {tabItems} = useTabItems({activeTab, sectionMode});

	const {sectionModeButtons} = useSectionModeButtons({
		activeTab,
		sectionMode,
		sectionModePermissions,
		setSectionMode,
	});

	const onOpenAddSubjectDialog = useCallback(() => {
		setIsAddSubjectDialogOpened(true);
	}, []);

	const onCloseAddSubjectDialog = useCallback(() => {
		setIsAddSubjectDialogOpened(false);
	}, []);

	const onOpenEditSubject = useCallback((subjectID: SubjectTab['id']) => {
		setOpenedEditSubjectID(subjectID);
	}, []);

	const onCloseEditSubjectDialog = useCallback(() => {
		setOpenedEditSubjectID(null);
	}, []);

	const {addSectionModeItems} = useAddSectionModeItems({
		sectionModePermissions,
		activeTab,
		onAddClick: onOpenAddSubjectDialog,
	});

	useEventEffect(SubjectCreatedEvent, ({newSubject: {id}}) => {
		setSelectedSubjectId({subjectId: id, belongsTo: 'personal'});
	});
	useEventEffect(SubjectChangedEvent, onCloseEditSubjectDialog);
	useEventEffect(SubjectRemovedEvent, onCloseEditSubjectDialog);

	const onAddSectionModeOpenChanged = useCallback(
		(open: boolean) => setSectionMode(open ? SubjectSectionMode.Add : SubjectSectionMode.View),
		[setSectionMode],
	);

	const updateSelectedSubjectId = useCallback(
		(subjectId: SubjectTab['id']) => {
			setSelectedSubjectId({subjectId, belongsTo: dataService.getBelongToValue(subjectId)});
		},
		[setSelectedSubjectId, dataService],
	);

	const getTabContent = (): React.JSX.Element | null => {
		if (!loaded) {
			return null;
		}

		if (
			(activeTab === SubjectTabId.All &&
				!districtSubjects.length &&
				!schoolSubjects.length &&
				!filteredPersonalSubjects.length) ||
			(activeTab === SubjectTabId.District && !districtSubjects.length) ||
			(activeTab === SubjectTabId.School && !schoolSubjects.length) ||
			(activeTab === SubjectTabId.Personal && !filteredPersonalSubjects.length)
		) {
			return (
				<EmptyContentTemplate size='full' templateText='No subjects to display' dataCy='subjects-panel-empty-content' />
			);
		}

		if (activeTab === SubjectTabId.All) {
			return (
				<SubjectSection.AllTabContent
					onSubjectEdit={onOpenEditSubject}
					selectedSubjectId={selectedSubjectId}
					setSelectedSubjectId={updateSelectedSubjectId}
					lists={allTabLists}
				/>
			);
		}

		const subjectsByTabId: Record<Exclude<SubjectTabId, SubjectTabId.All>, {subjects: SubjectTab[]}> = {
			[SubjectTabId.District]: {
				subjects: filterSubjects(districtSubjects),
			},
			[SubjectTabId.School]: {
				subjects: filterSubjects(schoolSubjects),
			},
			[SubjectTabId.Personal]: {
				subjects: filteredPersonalSubjects,
			},
		};

		if (activeTab === SubjectTabId.Personal && sectionMode === SubjectSectionMode.Edit) {
			return (
				<SubjectSection.EditableModeContent
					selectedSubjectId={selectedSubjectId}
					subjects={filteredPersonalSubjects}
					onSubjectEdit={onOpenEditSubject}
				/>
			);
		}

		if (activeTab === SubjectTabId.Personal && sectionMode === SubjectSectionMode.Rearrange) {
			return (
				<SubjectSection.RearrangeModeContent
					selectedSubjectId={selectedSubjectId}
					subjects={filteredPersonalSubjects}
					updateSubjectsOrder={dataService.updatePersonalSubjectsOrder.bind(dataService)}
				/>
			);
		}

		return (
			<SubjectSection.DefaultModeContent
				selectedSubjectId={selectedSubjectId}
				setSelectedSubjectId={updateSelectedSubjectId}
				subjects={subjectsByTabId[activeTab].subjects}
			/>
		);
	};

	return (
		<ExpandablePanelContentWrapper>
			<FlexBox justify='between' align='center' dataCy='title-with-action-buttons'>
				<TitleWithActionButtons
					sectionTitle={sectionTitle}
					SectionIcon={Task}
					isDisplaySectionIcon={sectionMode === SubjectSectionMode.View}
					isViewMode={sectionMode === SubjectSectionMode.View}
					sectionModeButtons={sectionModeButtons}
					addSectionModeItems={addSectionModeItems}
					isAddSectionModeActive={sectionMode === SubjectSectionMode.Add}
					isAddSectionModeDisabled={sectionMode !== SubjectSectionMode.View && sectionMode !== SubjectSectionMode.Add}
					onAddSectionModeOpenChanged={onAddSectionModeOpenChanged}
					dataCy='subjects-section-panel-header'
					addActionTooltipText='Add Subject Tab'
				/>
			</FlexBox>

			{isAddSubjectDialogOpened && (
				<PersonalSubjectDialog.CreatePersonalSubject onClose={onCloseAddSubjectDialog} />
			)}

			{!isNull(openedEditSubjectID) && (
				<PersonalSubjectDialog.EditPersonalSubject
					subjectID={openedEditSubjectID}
					onClose={onCloseEditSubjectDialog}
				/>
			)}

			<Tabs<SubjectTabId>
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				tabItems={tabItems}
				dataCy='subjects-tabs'
			/>
			<OverlayScrollbarsComponent
				defer
				style={{height: 'calc(100% + 0px)'}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>

					{getTabContent()}
			</OverlayScrollbarsComponent>
		</ExpandablePanelContentWrapper>
	);
}
