import {VariantProps} from '@stitches/react';
import {IconBox} from './styled';
import {IconComponent} from '@esgi/ui';

type Props = {
	fillColor: NonNullable<VariantProps<typeof IconBox>['fillColor']>;
	Icon: IconComponent;
};

export function ExpandablePanelIcon({fillColor, Icon}: Props) {
	return (
		<IconBox fillColor={fillColor}>
			<Icon />
		</IconBox>
	);
}
