import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {FlexBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const Title = styled(Text, {
	color: '$neutral56',
	height: '24px',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: '6px',
});

export const SkeletonList = styled(FlexBox, {
	...SkeletonStyles,
	minHeight: '38px',
	background: '$background',
});
