import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {CounterBox, FlexBox, GridBox} from '@esgi/ui/layout';

export const Root = styled(FlexBox, {
	flexDirection: 'column',
	width: '100%',
});

export const Row = styled(FlexBox, {
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '8px 0',
	marginBottom: 12,
	'&:first-child': {
		padding: 0,
	},
});

export const ToggleWrapper = styled(FlexBox, {
	alignItems: 'center',
	background: '$surface',
	height: 40,
	borderRadius: 6,
	justifyContent: 'center',
	padding: 2,
});

export const ViewToggleButton = styled(Button.Icon, {
	borderRadius: 5,
	width: 36,
	height: 36,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	variants: {
		selected: {
			true: {
				border: '1px solid $primaryMuted',
				background: '$primarySurface',
				color: '$primary',
			},
		},
	},
	'& > svg': {
		width: '66%',
		height: '66%',
	},
});

export const TitleGrid = styled(GridBox, {
	gridTemplateColumns: '24px auto auto',
	alignItems: 'center',
});

export const ActionGrid = styled(GridBox, {
	gridTemplateColumns: '78px 2fr 1fr 200px',
	alignItems: 'center',
	justifyContent: 'start',
	width: '100%',
	variants: {
		isSmallScreen: {
			true: {
				gridTemplateColumns: '78px 2fr 1fr 170px',
			},
		},
	},
});

export const StyledCounterContainer = styled(GridBox, {
	gap: 8,
	alignItems: 'center',
	justifySelf: 'start',
	gridAutoFlow: 'column',
});

export const StyledCounterBox = styled(CounterBox, {
	color: '$primaryVivid',
	borderColor: '$primaryMuted',
});