import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type TestButtonLabelsFormType = FormGroup<{
	testButtonLabels: FormControl<boolean>,
	incorrect: FormControl<string>,
	correct: FormControl<string>,
},
	ControlValue<{
		testButtonLabels: FormControl<boolean>,
		incorrect: FormControl<string>,
		correct: FormControl<string>,
	}>>

export const createTestButtonLabelsForm = ():
	TestButtonLabelsFormType => {
	return new FormGroup({
		testButtonLabels: new FormControl(false),
		incorrect: new FormControl(''),
		correct: new FormControl(''),
	});
};
