import {Answer} from '../../types';
import {AnswerPanelState} from './components/answer-panel/types';

export class YesNoState {
	constructor(
		public answers: Answer[] = [],
		public activeAnswerPanel: number = undefined,
		public summaryNote?: string,
		public correctPanel: AnswerPanelState = new AnswerPanelState(),
		public incorrectPanel: AnswerPanelState = new AnswerPanelState(),
		public notTestedPanel: AnswerPanelState = new AnswerPanelState(),
	) {}
}
