import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import React from 'react';

type Props = {
	onClose: () => void;
}

export function ContactUsErrorAlert(props: Props) {
	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);

	return <Alert modalManagerRef={modal}>
		<Alert.Body>
			Uh oh. Something went wrong on our end. Even this form is broken.
			Please click <a href='https://support.esgisoftware.com/hc/en-us/requests/new'>here</a> to contact custom
			support
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={close}>
				OK
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
