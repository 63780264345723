import {useCallback} from 'react';
import {useSlate} from 'slate-react';
import {isBlockActive} from '../../utils/is-block-active';
import {toggleBlock} from '../../utils/toggle-block';
import {ListOrdered, ListUnordered} from '../icons';
import {ButtonIcon} from './index.styled';

export const BlockButton = ({format}) => {
	const editor = useSlate();

	const getIcon = useCallback(() => {
		switch (format) {
			case 'numbered-list':
				return <ListOrdered />;
			case 'bulleted-list':
				return <ListUnordered />;
		}
	}, [format]);

	return (
    <ButtonIcon
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {getIcon()}
    </ButtonIcon>
	);
};
