import {Fade} from '@esgillc/ui-kit/transition';
import {SignUp} from './registration';
import styles from './styles.module.less';
import {withRecaptcha, withZendesk} from '../hocs';

function SignUpPage() {
	return (
		<>
			<script src='https://www.google.com/recaptcha/api.js?render=explicit' async defer></script>
			<div className={styles.root}>
				<Fade in duration={300} animateOnMount>
					<SignUp />
				</Fade>
			</div>
		</>
	);
}

export default withRecaptcha(withZendesk(SignUpPage));
