import {Student} from '@esgi/main/libs/store';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {DrawerMode} from '@esgi/main/features/teacher/home';
import {TeacherDrawerName} from '@esgi/main/features/teacher/drawers';

type Props = {
	setViewMode: VoidFunction;
	students: Student[];
};

export function EditableList({setViewMode, students}: Props) {
	const navigate = useNavigate();

	const handleStudentClicked = useCallback(
		(studentID: number) => {
			const studentProfileDrawerUrl = new URLSearchParams({
				drawerMode: DrawerMode.Edit,
				drawerName: TeacherDrawerName.TeacherStudentViaProfile,
				studentId: studentID.toString(),
			});
			navigate(`/home?${studentProfileDrawerUrl.toString()}`);
		},
		[navigate],
	);

	return (
		<StudentsPanel.Tabs.TabContent withBottomSectionButton buttonText='Done' onBottomButtonClick={setViewMode}>
			<StudentsPanel.Lists.SelectableList.Editable items={students} onItemClick={handleStudentClicked}>
				{({item: {firstName, lastName}}) => <StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />}
			</StudentsPanel.Lists.SelectableList.Editable>
		</StudentsPanel.Tabs.TabContent>
	);
}
