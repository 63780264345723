import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import {ReportTableComponentProps} from './types';
import styles from './report-table.module.less';

export function ReportTableRoot(props: ReportTableComponentProps) {
	return <div className={join(styles.outerTableContainer, props.className)}>
		<div className={styles.innerTableContainer}>
			{props.children}
		</div>
	</div>;
}

