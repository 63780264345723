import {AssignmentState} from '../types';
import {Status} from './types';

export const statusText: Status = {
	[AssignmentState.Completed]: {
		full: 'Complete',
		abbreviation: 'C',
	},
	[AssignmentState.Draft]: {
		full: 'Draft',
		abbreviation: 'D',
	},
	[AssignmentState.NotStarted]: {
		full: 'Not Started',
		abbreviation: 'NS',
	},
	[AssignmentState.Published]: {
		full: 'In Progress',
		abbreviation: 'IP',
	},
};

export const statusDescription: Status = {
	[AssignmentState.Completed]: {
		abbreviation: 'C',
		full: 'Assignment Completed',
	},
	[AssignmentState.Draft]: {
		abbreviation: 'D',
		full: 'Draft',
	},
	[AssignmentState.NotStarted]: {
		abbreviation: 'NS',
		full: 'Assignment Not Started',
	},
	[AssignmentState.Published]: {
		abbreviation: 'IP',
		full: 'Assignment In Progress',
	},
};

