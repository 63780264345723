import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';

interface Props {
	onClose: () => void;
	onSave: () => void;
}

export const DirtyAlert = ({onClose, onSave}: Props) => (
	<Modal
		animate={true}
		className='mx-question-dirty-modal white-header responsive alert-modal-white'
	>
		<ModalHeader>
			<h3>Save My Question?</h3>
		</ModalHeader>
		<ModalBody>
			<span>Do you wish to save the question before closing?</span>
		</ModalBody>
		<ModalFooter>
			<Transparent
				title='NO, CLOSE'
				onClick={onClose}
			/>
			<Transparent
				title='SAVE MY QUESTION'
				onClick={onSave}
			/>
		</ModalFooter>
	</Modal>
);
