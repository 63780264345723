import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EmptyContentBox = styled(GridBox, {
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	backgroundColor: '$background',
	paddingTop: 11,
	paddingBottom: 11,
	paddingLeft: 20,
	paddingRight: 20,
	width: 'max-content',
	height: 'max-content',
});
