import type {SVGProps} from 'react';

export function Preview(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.90625 8.625C8.5438 8.625 8.25 8.91281 8.25 9.26786C8.25 9.62291 8.5438 9.91071 8.90625 9.91071H9.46875C9.8312 9.91071 10.125 9.62291 10.125 9.26786C10.125 8.91281 9.8312 8.625 9.46875 8.625H8.90625Z'
				fill='#333333'
			/>
			<path
				d='M8.25 11.8393C8.25 11.4842 8.5438 11.1964 8.90625 11.1964H9.46875C9.8312 11.1964 10.125 11.4842 10.125 11.8393C10.125 12.1943 9.8312 12.4821 9.46875 12.4821H8.90625C8.5438 12.4821 8.25 12.1943 8.25 11.8393Z'
				fill='#333333'
			/>
			<path
				d='M8.25 14.4107C8.25 14.0557 8.5438 13.7679 8.90625 13.7679H9.46875C9.8312 13.7679 10.125 14.0557 10.125 14.4107C10.125 14.7658 9.8312 15.0536 9.46875 15.0536H8.90625C8.5438 15.0536 8.25 14.7658 8.25 14.4107Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.1755 11.9859C18.1755 10.3369 16.7931 9 15.0877 9C13.3824 9 12 10.3369 12 11.9859C12 13.635 13.3824 14.9719 15.0877 14.9719C15.6506 14.9719 16.1782 14.8263 16.6326 14.5719L18.4458 16.3251C18.6869 16.5583 19.078 16.5583 19.3191 16.3251C19.5603 16.0919 19.5603 15.7138 19.3191 15.4805L17.558 13.7778C17.9457 13.2787 18.1755 12.6583 18.1755 11.9859ZM13.3723 11.9859C13.3723 11.0698 14.1404 10.3271 15.0877 10.3271C16.0351 10.3271 16.8032 11.0698 16.8032 11.9859C16.8032 12.9021 16.0351 13.6448 15.0877 13.6448C14.1404 13.6448 13.3723 12.9021 13.3723 11.9859Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.4643 7.275C14.4643 6.92884 14.2161 6.64353 13.8964 6.60454L13.8214 6.6H7.17857C6.84889 6.6 6.57717 6.86058 6.54004 7.19628L6.53571 7.275V16.725C6.53571 17.0712 6.78388 17.3565 7.1036 17.3955L7.17857 17.4H13.8214C14.1511 17.4 14.4228 17.1394 14.46 16.8037L14.4643 16.725C14.4643 16.3522 14.7521 16.05 15.1071 16.05C15.4622 16.05 15.75 16.3522 15.75 16.725C15.75 17.8034 14.9471 18.685 13.9347 18.7466L13.8214 18.75H7.17857C6.15149 18.75 5.31193 17.907 5.25327 16.844L5.25 16.725V7.275C5.25 6.19657 6.05288 5.31503 7.06525 5.25344L7.17857 5.25H13.8214C14.8485 5.25 15.6881 6.09302 15.7467 7.15602L15.75 7.275C15.75 7.64779 15.4622 7.95 15.1071 7.95C14.7521 7.95 14.4643 7.64779 14.4643 7.275Z'
				fill='#333333'
			/>
		</svg>
	);
}
