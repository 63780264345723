import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const Wrapper = styled(Box, {
	flex: '1 1 auto',
	overflow: 'hidden',
	height: '85vh',

	'@media (max-width: 1024px)': {
		height: '65vh',
	},
});
