import {CounterBox, FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const expandableListsGap = 6;

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',

	variants: {
		isListOpen: {
			true: {
				'&:not(:last-child)': {
					marginBottom: 18,
				},
			},

			false: {
				'&:not(:last-child)': {
					marginBottom: expandableListsGap,
				},
			},
		},
	},
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	padding: '8px 6px 8px 12px',
	minHeight: 38,
	gridTemplateColumns: 'auto',

	[`${CounterBox}`]: {
		color: '$neutral56',
		borderColor: '$neutral56',
		transition: 'color .3s, background-color .3s, border-color .3s',
	},

	variants: {
		defaultCursor: {
			true: {
				cursor: 'default',
			},
		},
		selectedOverride: {
			true: {
				backgroundColor: '$primaryBackground',
				color: '$primary',

				[`&:not(:hover) ${CounterBox}`]: {
					color: '$primaryVivid !important',
					borderColor: '$primaryVivid !important',
				},

				[`${Text}`]: {
					color: '$primary',
				},
			},
		},
	},
});

export const SelectableListTriggerContent = styled(FlexBox, {
	color: 'inherit',
	alignItems: 'baseline',
	height: 24,
});

export const SelectableListTriggerLabel = styled(Text, {
	color: 'inherit',
	marginRight: 6,
});

export const SelectableLisItemContent = styled(GridBox, {
	paddingTop: 2,
	paddingBottom: 2,
});

export const ExpandableRearrangeableItem = styled(SelectableList.ItemDraggable, {
	minHeight: 38,
	padding: 6,
	borderRadius: 6,
	color: '$neutral40',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',

	'& > svg': {
		marginTop: 6,
	},

	'&:not(last-child)': {
		marginBottom: expandableListsGap,
	},

	variants: {
		selected: {
			true: {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
		},
	},
});

export const SelectableListItem = styled(SelectableList.Item, {
	padding: '6px 6px 6px 12px',
});