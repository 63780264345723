import {InfoBox} from './components/info-box';
import {Root} from './components/root';
import {InfoWithTranscript} from './components/info-with-transcript';
import {ChartsBox} from './components/charts-box';
import {ChartsDivider} from './components/charts-divider';

export const Statistics = Object.assign(Root, {
	InfoBox,
	InfoWithTranscript,
	ChartsBox,
	ChartsDivider,
});
