import React, {ReactNode} from 'react';
import {Checkbox} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';
import styles from './all-checkbox.module.less';

class State {
	selectorToggled: boolean = false;
}

interface Props {
	onClicked: (checkAll?: boolean) => void;
	value: boolean;
	disabled: boolean;
}

export default class AllCheckbox extends React.PureComponent<Props, State> {
	public override readonly state = new State();
	private html: React.RefObject<HTMLDivElement> = React.createRef();

	componentDidMount() {
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	public render() {
		return <div ref={this.html} className={styles.container}>
			<Checkbox
				onClick={() => this.props.onClicked()}
				checked={this.props.value}
				disabled={this.props.disabled}/>
			<div className={styles.selectorContainer}>
				<div className={styles.selectorArrow}
				     onClick={() => this.setState({selectorToggled: !this.state.selectorToggled})}>
					<svg width={10} height={5}>
						<path d='M0 0L5 5L10 0H0Z' fill='#828282'/>
					</svg>
				</div>
				{this.renderSelector()}
			</div>
		</div>;
	}

	private renderSelector(): ReactNode {
		if (this.state.selectorToggled) {
			return <div className={styles.selector}>
				<div
					className={join(styles.item, this.props.value && styles.selected)}
					onClick={(e) => this.props.onClicked(true)}>All
				</div>
				<div className={join(styles.item, !this.props.value && this.props.value != null && styles.selected)}
				     onClick={(e) => this.props.onClicked(false)}>None
				</div>
			</div>;
		}
	}

	private handleClickOutside(event): void {
		const element = this.html.current;
		if (element && !element.contains(event.target)) {
			this.setState({selectorToggled: false});
		}
	}
}
