import React from 'react';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import styles from '../card.module.less';
import {pieChartOptions} from '../utils';

interface Props {
	testType: string;
	studentsTested: number;
	correctPercent: number;
	incorrectPercent: number;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
}

export function CardChart({studentsTested, correctPercent, incorrectPercent, testType, testResultsCorrectVerbiage, testResultsIncorrectVerbiage}: Props) {
	const chartOptions = pieChartOptions(studentsTested, correctPercent, testType, testResultsCorrectVerbiage, testResultsIncorrectVerbiage);

	return <div className={styles.chart}>
		<HighchartsReact options={chartOptions}/>
		{studentsTested !== 0 &&
			<div className={styles.legend}>
				<div className={styles.correct}>
					<div className={styles.percents}>{correctPercent}%</div>
					<div className={styles.title}>{testResultsCorrectVerbiage.toLowerCase()}</div>
				</div>
				<div className={styles.incorrect}>
					<div className={styles.percents}>{incorrectPercent}%</div>
					<div className={styles.title}>{testResultsIncorrectVerbiage.toLowerCase()}</div>
				</div>
			</div>
		}
	</div>
}
