import {Button} from '@esgi/ui';
import {Form, FormElement} from '@esgi/ui/form';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const FormWrapper = styled(Form, {
	display: 'grid',
	gap: '15px',
});

export const FormElementWrapper = styled(FormElement, {
	paddingBottom: '4px',
	width: '100%',
});

export const CancellBtnWrapper = styled(Button, {
	marginLeft: '10px',
	minWidth: 'auto',
});

export const ButtonIcon = styled(Button.Icon, {
	backgroundColor: '$vivid',
	padding: '7px',
	border: '1px solid $mild',
	borderRadius: '8px',
	minWidth: '40px',
	minHeight: '40px',
});

export const FormElementContainer = styled(Box, {
	display: 'flex',
	gap: '12px',
});

export const CurrentPasswordErrorContainer = styled(Box, {
	'& div': {
		top: '100%',
		whiteSpace: 'normal',
		height: 'fit-content',
	},
});