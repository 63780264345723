import styles from './styles.module.less';
import {Dropdown, Option} from '@esgillc/ui-kit/control';

type ItemType = {
	[key: string]: unknown;
	id: number;
	name: string;
};

type DropdownItem<T extends ItemType> = {
	dropdownLabel: string;
	items: T[];
	selectedItems: T[];
	onItemChange: (item: T[]) => void;
	optionName: keyof T;
};

export function Filter<T extends ItemType>({
	dropdownLabel,
	items,
	onItemChange,
	optionName,
	selectedItems,
}: DropdownItem<T>) {
	return (
		<div className={styles.dropdownContainer}>
			<span className={styles.label}>{dropdownLabel}</span>
			<Dropdown value={selectedItems} setValue={onItemChange} optionName={optionName}>
				<Option value={{name: 'All', id: 0}}>All</Option>
				{items.map((c) => (
					<Option key={c.id} value={c}>
						{c.name}
					</Option>
				))}
			</Dropdown>
		</div>
	);
}
