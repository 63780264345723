import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Header = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '$8',
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$vivid',
});
