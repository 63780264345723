import {TooltipTrigger} from './components/tooltip-trigger';
import {TooltipContent} from './components/tooltip-content';
import {TooltipRoot} from './components/tooltip-root';

export {TooltipProvider, type TooltipProviderProps} from './components/tooltip-provider';

export const Tooltip = Object.assign(TooltipRoot, {
	Trigger: TooltipTrigger,
	Content: TooltipContent,
});

export {type TooltipContentProps} from './components/tooltip-content';
