import {ImageSet} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/pages/test-explorer/noted-authors/image-set';
import {ClusterModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/get/by-id/cluster-model';
import {StateStandardModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/standards/search/state-standard-model';

export enum AdditionSelectItem {
	System = 'System',
	Other = 'Other'
}

// TODO: need to separate with different OptionItem types
export type OptionItem = {
	value: number;
	label: string | AdditionSelectItem;
	title?: string;
	imageSet?: ImageSet;
	description?: string;
	searchName?: string;
	disabled?: boolean;
	contentArea?: number;
	gradeLevel?: number;
};

export type GradeLevelOptionItem = {
	value: number;
	label: string | AdditionSelectItem;
	commonGradeLevels?: number[];
	disabled?: boolean;
};

export type StandardListItem = ClusterModel & {standards: StandardsListItem[]}

export type StandardsListItem = OptionItem & ClusterModel & StateStandardModel & {children?: Array<OptionItem & StateStandardModel>}