import {Dispatch, useMemo} from 'react';
import {Class, Group, useClasses, useGroups, useStudents} from '@esgi/main/libs/store';
import {TestSectionContentGroupStudents} from './components/test-section-content/types';
import {isNull} from 'underscore';
import {TestModel} from '@esgi/main/features/teacher/home';

export function useTestSectionContentGroupStudentsProps({
	selectedClassId,
	selectedGroupId,
	handleClickTestCard,
}: {
	selectedClassId: Class['id'] | null;
	selectedGroupId: Group['id'] | null;
	handleClickTestCard: Dispatch<TestModel>;
}) {
	const [{data: classList, loaded: isClassListLoaded}] = useClasses();
	const [{data: groupsList, loaded: isGroupsListLoaded}] = useGroups();
	const [{data: studentsList, loaded: isStudentsListLoaded}] = useStudents();

	return useMemo<TestSectionContentGroupStudents | null>(() => {
		if (!isClassListLoaded || !isGroupsListLoaded || !isStudentsListLoaded) {
			return null;
		}

		if (!isNull(selectedClassId)) {
			const commonClassProps: Pick<
				TestSectionContentGroupStudents,
				'classID' | 'statisticEntity' | 'onTestCardClicked'
			> = {
				onTestCardClicked: handleClickTestCard,
				classID: selectedClassId,
				statisticEntity: 'class',
			};

			if (selectedClassId === -1) {
				const allStudentsIDs = studentsList.map(({id}) => id);

				return {
					...commonClassProps,
					sectionName: 'All Classes',
					studentsIDs: allStudentsIDs,
				};
			}

			const selectedClass = classList.find(({id}) => id === selectedClassId);

			return {
				...commonClassProps,
				sectionName: selectedClass?.name ?? '',
				studentsIDs: selectedClass?.studentIDs ?? [],
			};
		}

		if (!isNull(selectedGroupId)) {
			const selectedGroup = groupsList.find(({id}) => selectedGroupId === id);
			const selectedClassByGroupId = classList.find(({id}) => selectedGroup?.classID === id);

			return {
				sectionName: selectedGroup?.name ?? '',
				studentsIDs: selectedGroup?.studentIDs ?? [],
				onTestCardClicked: handleClickTestCard,
				classID: selectedClassByGroupId?.id ?? 0,
				statisticEntity: 'group',
			};
		}

		return null;
	}, [
		classList,
		groupsList,
		handleClickTestCard,
		isClassListLoaded,
		isGroupsListLoaded,
		isStudentsListLoaded,
		selectedClassId,
		selectedGroupId,
		studentsList,
	]);
}
