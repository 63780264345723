export const dataRoutes = {
	schools: '/data/schools',
	preAssess: '/data/pre-assess',
	teachers: '/data/teachers',
	specialists: '/data/specialists',
	classes: '/data/classes',
	groups: '/data/groups',
	specialistGroups: '/data/specialist-groups',
	students: '/data/students',
};
