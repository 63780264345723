import {HierarchyInstance} from 'modules/hierarchy/core/models';
import React, {useState} from 'react';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {SubjectType} from '@esgi/core/enums';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {getUser, UserType} from '@esgi/core/authentication';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import UntestedStudentsReportModal from 'modules/reports/untested-students-report/components/modal';

class Props {
	canRun: boolean;
	subjectID: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	noStudents: boolean;
}

export function UntestedStudentsButton ({
	                                        canRun,
	                                        subjectID,
	                                        subjectType,
	                                        hierarchy,
	                                        applyHomepageChanges,
	                                        noStudents,
}: Props) {
	const [showModal, setShowModal] = useState(false);
	const changesCollector = new ChangesCollector({id: subjectID, type: subjectType}, hierarchy.snapshot);
	const clickHandler = () => {
		const {userType} = getUser();
		if (!canRun && userType === UserType.T) {
			OldAlerts.bsalert('You are in a school year that has ended. Please switch to the current year to test. Click <a' +
				' href="https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Year" target="_blank">here</a> for more information.\n');
			return;
		}
		setShowModal(true);
	};

	const icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='17'
		            viewBox='0 0 15 17'
		            fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M5.76923 1.13151C5.76923 0.506571 6.28587 0 6.92308 0H8.07692C8.71413 0 9.23077 0.506571 9.23077 1.13151H12.6923C13.9667 1.13151 15 2.14472 15 3.39454V14.7097C15 15.9595 13.9667 16.9727 12.6923 16.9727H2.30769C1.03328 16.9727 0 15.9595 0 14.7097V3.39454C0 2.14472 1.03328 1.13151 2.30769 1.13151H5.76923ZM6.92308 1.69727C6.92308 1.38483 7.18111 1.13151 7.5 1.13151C7.81889 1.13151 8.07692 1.38483 8.07692 1.69727C8.07692 2.00971 7.81889 2.26303 7.5 2.26303C7.18111 2.26303 6.92308 2.00971 6.92308 1.69727ZM2.30769 5.37469C2.30769 4.90603 2.69531 4.52605 3.17308 4.52605H11.8269C12.3047 4.52605 12.6923 4.90603 12.6923 5.37469C12.6923 5.84335 12.3047 6.22332 11.8269 6.22332H3.17308C2.69531 6.22332 2.30769 5.84335 2.30769 5.37469ZM2.30769 8.76923C2.30769 8.30058 2.69531 7.9206 3.17308 7.9206H11.8269C12.3047 7.9206 12.6923 8.30058 12.6923 8.76923C12.6923 9.23789 12.3047 9.61787 11.8269 9.61787H3.17308C2.69531 9.61787 2.30769 9.23789 2.30769 8.76923ZM3.17308 11.3151C2.69531 11.3151 2.30769 11.6951 2.30769 12.1638C2.30769 12.6324 2.69531 13.0124 3.17308 13.0124H8.36538C8.84315 13.0124 9.23077 12.6324 9.23077 12.1638C9.23077 11.6951 8.84315 11.3151 8.36538 11.3151H3.17308Z'
			      fill='#0088CC'/>
		</svg>;
	};

	const onClose = () => {
		applyHomepageChanges(changesCollector);
		setShowModal(false);
	};

	return <>
		<NoStudentProtectedButton title='Untested Students'
		                          icon={icon()}
		                          hoverTitle='Identify untested students to administer testing'
		                          onClicked={() => clickHandler()}
		                          noStudents={noStudents}
		                          linkClassName='untested-students-report-link'/>
		{showModal && <UntestedStudentsReportModal hierarchy={hierarchy.snapshot} subjectType={subjectType} subjectID={subjectID}
		                                           changesCollector={changesCollector}
		                                           onClose={onClose}/>}
	</>;
}
