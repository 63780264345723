import {Box} from '../../../layout/box';
import {styled} from '../../../theme';

export const ProgressBarBox = styled(Box, {
	display: 'inline-flex',
	width: 'max-content',
	height: 'max-content',
	position: 'relative',
	overflow: 'hidden',
});
