import * as React from 'react';
import type {SVGProps} from 'react';

export function Question(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M12.265 11.902c0-.216.072-.414.162-.594.603-1.251 1.845-2.124 3.582-2.124 2.286 0 3.708 1.53 3.708 3.6 0 1.476-.468 2.394-1.602 3.258-.972.738-1.134 1.044-1.134 1.512v.054c0 .594-.486 1.08-1.08 1.08-.594 0-1.08-.486-1.08-1.08V17.5c0-1.224.36-1.782 1.62-2.7.882-.648 1.116-1.188 1.116-1.926 0-1.062-.594-1.746-1.584-1.746-.72 0-1.314.432-1.62 1.152-.18.432-.558.684-1.026.684a1.053 1.053 0 0 1-1.062-1.062Zm3.636 7.722c.594 0 1.08.486 1.08 1.08v.306c0 .594-.486 1.08-1.08 1.08-.594 0-1.08-.486-1.08-1.08v-.306c0-.594.486-1.08 1.08-1.08Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
