import {AssignmentCardSkeleton} from '@esgi/main/kits/assignments';
import {Content} from './index.styled';

export function Skeleton() {
	return (
		<Content>
			{new Array(8).fill(null).map((_, index) => (
				<AssignmentCardSkeleton key={index} />
			))}
		</Content>
	);
}
