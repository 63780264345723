import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';

type Props = {
	onClose: VoidFunction;
};

export function OverlimitAlert({onClose}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
			<Alert.Header onCloseIconClick={closeAlert}/>
			<Alert.Body>
				<Text size='medium' color='neutral40'>
					You have exceeded the maximum number of tests you can select. The limit is 100 tests.
				</Text>
			</Alert.Body>
			<Alert.Footer>
				<Button color='secondary' onClick={closeAlert}>
					<Text size='medium' bold color='base'>Ok</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
