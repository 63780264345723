import {ServerClient} from 'pages/landing/kit/server-client';

interface FederalSchoolModel{
	federalSchoolId: number;
	schoolId: number;
	schoolName: string;
	federalDistrictId: number;
	federalDistrictName: string;
	mailingAddress: string;
	mailingCity: string;
	phoneNumber: string;
	locationZip: string;
	locationZip4: string;
	countyName: string;
}
interface FederalDistrictModel{
	id: number;
	name: string;
}

interface CountryModel {
	stateID: number;
	name: string;
}

export interface StateModel {
	id: number;
	name: string;
}

export class Api {
	private locationControllerName = 'landing/autoquoter/location';
	private dictionariesControllerName = 'dictionaries/location';

	api = {
		getDistrictByState: (stateId: number) => {
			return ServerClient.ESGIAPI.Get<{ federalDistricts: FederalDistrictModel[] }>(this.locationControllerName, 'get-by-state', {data: {stateID: stateId}});
		},
		getSchoolByFederalDistrictId: (federalDistrictId: number) => {
			return ServerClient.ESGIAPI.Get<{ federalSchools: FederalSchoolModel[] }>(this.locationControllerName, 'get-by-federal-district-id', {data: {federalDistrictID: federalDistrictId}});
		},
		getCountries: () => {
			return ServerClient.ESGIAPI.Get<CountryModel[]>(this.dictionariesControllerName, 'countries', {cache: true});
		},
		getStatesByCountryId: (countryId: number) => {
			return ServerClient.ESGIAPI.Get<StateModel[]>(this.dictionariesControllerName, 'states', {cache: true, data: {CountryID: countryId}});
		},
	};
}
