import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Dropdown, OnErrorTooltip, Option, Label} from '@esgillc/ui-kit/form-control';
import {IEPStatusModel} from '../../models';
import {IEPGoalFormService} from '../../services';
import {AddStatusButton} from '../buttons/add-status-btn';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';

type Props = {
	control: IFormControl;
	statuses: IEPStatusModel[];
	service: IEPGoalFormService;
}

export const StatusDropdown = ({control, statuses, service}: Props) => {
	const showStatusDropdown = !!statuses.filter(s => !s.isDeleted).length;
	return (
		<FormElement
			className={styles.formElement}
			control={control}
		>
			<Label className={styles.label}>Status</Label>
			<div className={join(styles.field, styles.statusField)}>
				{showStatusDropdown &&
					<>
						<Dropdown
							placeholder='Select a status'
							className={styles.dropDownField}
							optionName='name'
							tabIndex={0}
						>
							{statuses.filter(s => !s.isDeleted).map((v, i) => <Option key={i} value={v}>{v.name}</Option>)}
						</Dropdown>
						<OnErrorTooltip
							showOnError='required'
							placement='left'
						>
							Please select a status...
						</OnErrorTooltip>
					</>
				}
				<AddStatusButton
					service={service}
					className={join(!showStatusDropdown && styles.noMg)}
				/>
			</div>
		</FormElement>
	);
}