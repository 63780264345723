import {Entities} from './group';
import {Subject} from './subject';
import {Test} from './test';
import Group = Entities.Group;

export * as SubjectEvents from './events/subject';
export * as TestEvents from './events/test';
export * as GroupEvents from './events/group';

export const SubjectEntity = Subject;
export const TestEntity = Test;
export const GroupEntity = Group;
