import {BaseService} from '@esgi/core/service';
import {ShareScreenSession} from 'shared/modules/testing/share-screen-session/share-screen-session';
import {HttpClient} from '@esgi/api';
import {
	TestButtonLabels,
	TestResultVerbiage,
	UpdateModel,
	UserSettingsResponse,
} from 'shared/modules/user-settings/teacher-and-specialist/types';
import {userStorage, UserType} from '@esgi/core/authentication';
import {SsoTracker} from '@esgi/core/tracker';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {UserSettingsChangedEvent} from 'shared/modules/user-settings/events';
import {
	TeacherAndSpecialistTestResultsVerbiagesChanged,
} from 'shared/modules/user-settings/teacher-and-specialist/events';
import {createGeneralSectionForm} from 'shared/modules/user-settings/forms/general-section';
import {BehaviorSubject, tap} from 'rxjs';
import {StudentSortModel, TimezoneValue} from 'shared/modules/user-settings/types';

export class TeacherAndSpecialistSettingsService extends BaseService {
	public generalSectionForm = createGeneralSectionForm();
	public code = new BehaviorSubject<string>('');
	public expireTime = new BehaviorSubject<string>('');
	public testResultVerbiage = new BehaviorSubject<TestResultVerbiage>(null);
	public testButtonLabels = new BehaviorSubject<TestButtonLabels>(null);
	public lockScreen = new BehaviorSubject<boolean>(false);
	public showSelfAssessOption = new BehaviorSubject<boolean>(false);
	public enableShortcuts = new BehaviorSubject<boolean>(false);
	public sortValues = new BehaviorSubject<StudentSortModel[]>([]);
	public timeZones = new BehaviorSubject<TimezoneValue[]>([]);
	public showPieChartsResults = new BehaviorSubject(false);
	public clientJoined = new BehaviorSubject(false);

	private userID: number;
	private userType: UserType;
	private eventBusManager = new EventBusManager();
	private shareScreenTimer: ReturnType<typeof setInterval>;
	private testResultVerbiageResponse: TestResultVerbiage;
	private shareScreenSocket: ShareScreenSession;
	private userTypePath: string;

	public init({userID, userType}) {
		const userTypePaths: Map<UserType, string> = new Map([
			[UserType.T, 'teacher'],
			[UserType.PA, 'preassess-account'],
			[UserType.ISS, 'specialist'],
			[UserType.ISD, 'specialist'],
		]);

		this.userID = userID;
		this.userType = userType;
		this.userTypePath = userTypePaths.get(this.userType);
		this.shareScreenSocketHandler();
		return this.getUserSettings();
	}

	public getUserSettings = () => {
		return HttpClient.default.ESGIApi.get<UserSettingsResponse>(
			'user-settings/' + this.userTypePath,
			'init')
			.pipe(tap(response => {
				this.testResultVerbiageResponse = {
					correctVerbiage: response.testResultsCorrectVerbiage || '',
					incorrectVerbiage: response.testResultsIncorrectVerbiage || '',
					testResultsVerbiagesEnabled: response.testResultsVerbiagesEnabled,
				};
				const {
					sortBy,
					sortByVariants,
					showTestSessionResults,
					enableShortcuts,
					showSelfAssessOption,
					lockScreen,
					correctTestLabel,
					incorrectTestLabel,
					testButtonsLabelsEnabled,
					timeZones,
					selectedTimeZone,
					isCleverAccountLinked,
					isOneClickAccountLinked,
				} = response;
				this.generalSectionForm.value = {
					sortBy: [response.sortByVariants.find(item => item.value === sortBy) || response.sortByVariants[0]],
					timeZone: [selectedTimeZone || timeZones[0]],
					isCleverAccountLinked,
					isOneClickAccountLinked: isOneClickAccountLinked,
				};
				this.testResultVerbiage.next(this.testResultVerbiageResponse);
				this.testButtonLabels.next({
					correctLabel: correctTestLabel || '',
					incorrectLabel: incorrectTestLabel || '',
					testButtonsLabelsEnabled,
				});
				this.lockScreen.next(lockScreen);
				this.showSelfAssessOption.next(showSelfAssessOption);
				this.enableShortcuts.next(enableShortcuts);
				this.sortValues.next(sortByVariants);
				this.timeZones.next(timeZones);
				this.showPieChartsResults.next(showTestSessionResults);
			}));
	};

	public createShareSession = () => {
		SsoTracker.trackEvent({
			trackingEvent: 'Get2SCode',
		});
		window.localStorage.shareScreenSessionCreated = true;
		this.shareScreenSocket.share(this.userID);
	};

	public updateUserSettings = () => {
		const model: UpdateModel = {
			userID: this.userID,
			sortBy: this.generalSectionForm.controls.sortBy.value[0].value,
			enableShortcuts: this.enableShortcuts.value,
			showTestSessionResults: this.showPieChartsResults.value,
			testButtonsLabelsEnabled: this.testButtonLabels.value.testButtonsLabelsEnabled,
			correctTestLabel: this.testButtonLabels.value.correctLabel,
			incorrectTestLabel: this.testButtonLabels.value.incorrectLabel,
			testResultsVerbiagesEnabled: this.testResultVerbiage.value.testResultsVerbiagesEnabled,
			testResultsCorrectVerbiage: this.testResultVerbiage.value.correctVerbiage,
			testResultsIncorrectVerbiage: this.testResultVerbiage.value.incorrectVerbiage,
			timeZone: this.generalSectionForm.controls.timeZone.value[0].id.toString(),
			showSelfAssessOption: this.showSelfAssessOption.value,
			lockScreen: this.lockScreen.value,
		};

		return this.httpClient.ESGIApi.post(
			'user-settings/' + this.userTypePath,
			'update',
			model)
			.pipe(tap(() => {
				userStorage.update({
					showSelfAssessOption: model.showSelfAssessOption,
					lockScreen: model.lockScreen,
				});
				dispatchAppEvent(UserSettingsChangedEvent, new UserSettingsChangedEvent(this.userID, model.sortBy, false, model.showSelfAssessOption));

				if (this.testResultVerbiageResponse.testResultsVerbiagesEnabled !== this.testResultVerbiage.value.testResultsVerbiagesEnabled ||
					(this.testResultVerbiage.value.testResultsVerbiagesEnabled &&
						(this.testResultVerbiageResponse.correctVerbiage !== this.testResultVerbiage.value.correctVerbiage ||
							this.testResultVerbiageResponse.incorrectVerbiage !== this.testResultVerbiage.value.incorrectVerbiage))) {
					this.eventBusManager.dispatch(TeacherAndSpecialistTestResultsVerbiagesChanged, {});
				}
			}));
	};

	public destroy() {
		super.destroy();
		this.eventBusManager.destroy();
		this.shareScreenSocket.dispose();
	}

	private shareScreenSocketHandler = () => {
		this.shareScreenSocket = new ShareScreenSession();
		this.shareScreenSocket.events.shared((e, data) => {
			const {code} = data;

			if (code) {
				this.code.next(code);
				this.shareScreenSocket.events.clientJoined(() => {
					clearInterval(this.shareScreenTimer);
					this.clientJoined.next(true);
				});

				this.shareScreenTimer = setInterval(() => {
					const now = new Date().valueOf();
					const expiredTime = 5 * 60 * 1000;
					const time = new Date(expiredTime - (now - data.createdTime));
					const seconds = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
					const minutes = '0' + time.getMinutes();
					this.expireTime.next(`${minutes}:${seconds}`);
					if (minutes === '00' && seconds === '00') {
						this.code.next('');
						this.expireTime.next('');
						clearInterval(this.shareScreenTimer);
					}
				}, 1000);
			}
		});

		this.shareScreenSocket.isExpired(this.userID);
	};


}
