export enum LevelScoreType {
	Unobserved = 0,
	BelowBasic = 1,
	Basic = 2,
	Proficient = 3,
	Exceptional = 4
}

export function getLevelName(score: number, name: string) {
	if (name.length > 0) {
		return name;
	}
	switch (score) {
		case LevelScoreType.Unobserved:
		case LevelScoreType.Basic:
		case LevelScoreType.Proficient:
		case LevelScoreType.Exceptional:
			return LevelScoreType[score];
		case LevelScoreType.BelowBasic:
			return 'Below Basic';
		default: return '';
	}
}