import React, {createRef, ReactNode} from 'react';
import {Button, LinkButton, PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import RubricPreviewForm from '../../../../../common/preview-form/rubric-preview-form';
import RubricService from '../../../../../common/rubric-service';
import {TestSnapshot} from '../../../../services/local-save/local-save';
import styles from './restore.dialog.module.less';

class State {
	showPreview: boolean = false;
}

interface Props {
	testModel: TestSnapshot;
	onRestoreClicked: () => void;
	onCancelClicked: () => void;
}

export default class RestoreDialog extends React.PureComponent<Props, State> {
	private readonly modalManagerRef: ModalManagerRef = createRef();
	private readonly testDataService: RubricService = new RubricService();

	constructor(props: Props) {
		super(props);
		this.state = new State();
		this.testDataService.initFromModel(props.testModel);
	}

	public render() {
		const time = this.props.testModel.snapshotDate?.toDateString();
		if (this.state.showPreview) {
			return this.renderPreview();
		}

		return <Modal>
			<ModalHeader>
				<Title>
					Restore from unsaved version?
				</Title>
			</ModalHeader>
			<ModalBody>
				<span>We found an unsaved version of the test from {time}</span>
			</ModalBody>
			<ModalFooter>
				<div className={styles.footer}>
					<LinkButton onClick={() => this.setState({showPreview: true})}>PREVIEW</LinkButton>
					<div className={styles.actions}>
						<LinkButton onClick={() => this.props.onCancelClicked()}>CANCEL</LinkButton>
						<PrimaryButton onClick={() => this.props.onRestoreClicked()}>RESTORE</PrimaryButton>
					</div>
				</div>
			</ModalFooter>
		</Modal>;
	}

	private renderPreview(): ReactNode {
		return <Modal>
			<ModalHeader>
				<Title>Unsaved test</Title>
			</ModalHeader>
			<ModalBody>
				<RubricPreviewForm rubricService={this.testDataService}/>
			</ModalBody>
			<ModalFooter>
				<div className={styles.footer}>
					<Button onClick={() => this.props.onCancelClicked()}>DISCARD</Button>
					<PrimaryButton onClick={() => this.props.onRestoreClicked()}>RESTORE</PrimaryButton>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
