import {Root} from './index.styled';
import {useService} from '@esgi/core/service';
import {ClassGradesService} from './service';
import useReportState from './hooks/use-report-state';
import {TestSelectionPanel} from './components/test-selection-panel';
import {SettingsPanel} from './components/settings-panel';
import {Reports} from '@esgi/main/kits/reports';
import {useReportsContext} from '../../../layout/outlet-context';
import {AddTestDrawer} from '@esgi/main/features/teacher/drawers';

export default function ClassGrades() {
	const {snackbarRef} = useReportsContext();
	const service = useService(ClassGradesService);

	const {
		selectedEntity,
		subjects,
		isSubjectsLoaded,
		selectedSubject,
		tests,
		selectedTests,
		isTestsLoading,
		isCarryForwardChecked,
		setCarryForwardChecked,
		showAddTestsDrawer,
		setShowAddTestsDrawer,
		onEntitySelect,
	} = useReportState(service);

	return (
		<Root>
			<Reports.ClassLevel.StudentPanel
				onSelect={onEntitySelect}
				hideEmptyClassAndGroup
			/>
			<TestSelectionPanel
				selectedEntity={selectedEntity}
				subjects={subjects}
				isSubjectsLoaded={isSubjectsLoaded}
				selectedSubject={selectedSubject.toString()}
				onSubjectSelect={(value) => service.setSubject(value)}
				isTestsLoading={isTestsLoading}
				tests={tests}
				onTestSelect={(value) => service.setTests(value)}
				setShowAddTestsDrawer={() => setShowAddTestsDrawer(true)}
			/>
			<SettingsPanel
				service={service}
				isCarryForwardChecked={isCarryForwardChecked}
				setCarryForwardChecked={setCarryForwardChecked}
				disabled={!selectedTests.length}
			/>
			{showAddTestsDrawer && (
				<AddTestDrawer
					onClose={() => setShowAddTestsDrawer(false)}
					subject={subjects.find((item) => item.id === selectedSubject)}
					snackbarRef={snackbarRef}
				/>
			)}
		</Root>
	);
}
