import {ReactElement, forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {BaseButton, BaseButtonProps} from '../base-button';
import {VariantProps} from '@stitches/react';

type ButtonIconProps = Omit<BaseButtonProps, 'uppercase' | 'children'> & {
	/** Content. Can only be svg element */
	children: ReactElement<React.SVGProps<SVGSVGElement>>;
} & VariantProps<typeof Button>;

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
	({dataCy, css = {}, ...props}, forwaredRef) => (
		<Button data-cy={dataCy ?? 'ui-kit-button-icon'} ref={forwaredRef} css={css} {...props} />
	),
);

const Button = styled(BaseButton, {
	width: 24,
	height: 24,
	color: '$base',

	'& svg': {
		width: '100%',
		height: '100%',
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	'&:hover:not(:disabled)': {
		color: '$secondary',
	},

	'&:disabled': {
		color: '$lowContrast',
	},

	variants: {
		/** Add a circled background on hover. */
		withBackgroundHover: {
			true: {
				borderRadius: '50%',

				'&:hover:not(:disabled)': {
					backgroundColor: '$secondaryBackground',
				},
			},
		},
	},
});
