import {TableCellInput} from './components/table-cell-input';
import {TableCellStudent} from './components/table-cell-student-modal';

export const TableCell = Object.assign(
	{},
	{
		Input: TableCellInput,
		Student: TableCellStudent,
	},
);
