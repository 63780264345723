import {createContext, RefObject, useContext} from 'react';
import {noop} from 'underscore';

export type ProgressBarContextValue = {
	/** Progress value. */
	value: number;

	/** Progress maximum value. Percentage calculation is based on the maximum value provided. */
	maxValue: number;

	/** Percentage calculated value in float (0.33 = 33%). */
	progress: number;

	/** Indicates if progress animation is finished. */
	isAnimationFinished: boolean,

	/** Set progress animation finished state. */
	setAnimationFinished: (value: boolean) => void,

	/** Progress bar line HTML item reference. */
	lineProgressBarRef: RefObject<HTMLElement>,

	/** Set progress bar line HTML item reference. */
	setLineProgressBarRef?: (value: HTMLElement) => void
};

export const ProgressBarContext = createContext<ProgressBarContextValue>({
	value: 0,
	maxValue: 0,
	progress: 0,
	isAnimationFinished: false,
	setAnimationFinished: noop,
	lineProgressBarRef: {
		current: null,
	},
	setLineProgressBarRef: noop,
});

export function useProgressBarContext() {
	return useContext(ProgressBarContext);
}
