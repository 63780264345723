import {ErrorHandler, HandlingStrategy} from '@esgi/api';
import {userStorage} from '../../../../../../libs/core/src/authentication';
import {dispatchAppEvent} from '@esgillc/events';
import {ShowMoveUserTransferredAlertEvent} from '../../layers/move-user-layers/events';

export default class MoveUserHandler extends ErrorHandler {
	private static userNotifiedOnLogin: boolean = false;

	public next<T>(response: XMLHttpRequest, strategy: HandlingStrategy): void {
		if (!response) {
			return;
		}

		if (response.status === 409) {
			const user = userStorage.get();
			if (!user || !user.userID) {
				return;
			}

			if (MoveUserHandler.userNotifiedOnLogin) {
				return;
			}
			MoveUserHandler.userNotifiedOnLogin = true;

			dispatchAppEvent(ShowMoveUserTransferredAlertEvent);
		}
	}
}
