import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const LableBox = styled(FlexBox, {
	paddingTop: 6,
	paddingBottom: 6,
	paddingInline: 12,
	borderRadius: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$background',
	alignItems: 'center',
});
