import {Text} from '@esgi/ui/typography';
import {Container} from './index.styled';
import {PropsWithChildren} from 'react';

type Props = PropsWithChildren<{
	label: string;
}>;

export function RootWrapper({label, children}: Props) {
	return (
		<Container dataCy='filter-report'>
			<Text data-cy='label' bold size='medium'>
				{label}:
			</Text>
			{children}
		</Container>
	);
}
