import {ProgressBar} from '@esgi/ui';
import {AnswersInfo} from '../../answers-info';
import {Text} from '@esgi/ui/typography';

type Props = {
	value: number | null;
	maxValue: number;
	result: number | null;
};

export function ProgressDashed({value, maxValue}: Props) {
	const valueInt = value ?? 0;

	return (
		<ProgressBar value={valueInt} maxValue={maxValue}>
			{({progress}) => {
				const isProgressSuccess = progress > 0.5;

				return (
					<ProgressBar.RingDashed
						activeStrokeColor={isProgressSuccess ? 'positive' : 'neutral24'}
						inactiveStrokeColor={isProgressSuccess ? 'positiveHighlight' : 'muted'}
					>
						<Text size='medium' bold font='mono' color={isProgressSuccess ? 'green' : 'base'} data-cy='progress-value'>
							{valueInt > 0 ? valueInt : <AnswersInfo value={value ?? 0} maxValue={maxValue} />}
						</Text>
					</ProgressBar.RingDashed>
				);
			}}
		</ProgressBar>
	);
}
