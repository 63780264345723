import {useEffect, useState} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Buttons} from '@esgillc/ui-kit/button';
import {useService} from '@esgi/core/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {join} from '@esgillc/ui-kit/utils';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {UploadPhotoService} from './service';
import {ImageCropperParams, ImageResponse} from 'modules/forms/students-form/types';
import {ImageUploader} from './components/image-uploader';
import {ImageCropper} from './components/image-cropper';
import {cropImage, convertFileToBase64} from './utils';
import styles from './styles.module.less';

interface Props {
	studentID: number;
	imageData?: ImageResponse;
	onClose: () => void;
	onSaved: (data: ImageResponse) => void;
}

export function UploadPhotoModal(props: Props): JSX.Element {
	const {studentID, imageData, onClose, onSaved} = props;
	const modalRef = useModal();
	const handelClose = useCloseModal(modalRef, onClose);

	const service = useService(UploadPhotoService);

	const [imageURL, setImageURL] = useState<string>(imageData?.imageUrl);
	const [croppedAreaParams, setCroppedAreaParams] = useState<ImageCropperParams>(null);
	const [isSaveDisabled, setSaveDisabled] = useState(true);
	const [isTouched, setTouched] = useState(false);
	const [imageFile, setImageFile] = useState<File>();
	const [crop, setCrop] = useState(imageData?.crop);
	const [isEditing, setEditing] = useState(false);

	useEffect(() => {
		if (imageFile) {
			convertFileToBase64(imageFile).subscribe(setImageURL);
		}
	}, [imageFile]);


	useEffect(() => {
		setSaveDisabled(!isTouched);
	}, [isTouched]);

	const onUpload = () => {
		if (imageURL) {
			cropImage(imageURL, croppedAreaParams).then(croppedImage => {
				service.save({
					image: imageFile ?? null,
					croppedImage: croppedImage,
					cropParameters: crop,
				}, studentID).subscribe((imageResponse) => {
					onSaved(imageResponse.value);
					handelClose();
				});
			});
		} else {
			service.removeImage(studentID).subscribe(() => {
				onSaved({
					imageUrl: '',
					imageCropUrl: '',
					crop: {
						x: 0,
						y: 0,
						zoom: 1,
					},
				});
				handelClose();
			});
		}
	};

	const onRemove = () => {
		setTouched(true);
		setImageURL(null);
		setImageFile(null);
	};

	return (
		<Modal
			modalManagerRef={modalRef}
			className={styles.modal}
		>
			<ServiceLoader trackingService={service} fullscreen/>
			<Modal.Header className={styles.header}>
				<div
					data-cy='upload-image-modal'
					className={styles.header}
				>
					<Title>
						{imageURL ? 'Edit Photo' : 'Add Photo'}
						<OnHoverTooltip message={<div>Min. size: 96X96 px, 5MB <br/> Formats: Gif, JPG, PNG, BMP, TIFF</div>}>
							<i className={join('fa fa-question-circle', styles.infoIcon)}/>
						</OnHoverTooltip>
					</Title>
					<CloseIcon onClick={handelClose}/>
				</div>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				{imageURL
					? <ImageCropper
							isEditing={isEditing}
							setEditing={(v) => {
								setEditing(v);
								setTouched(true);
							}}
							image={imageURL}
							croppedImage={imageData.imageCropUrl}
							crop={crop}
							setCrop={(v) => {
								setTouched(true);
								setCrop((prev) => ({...prev, ...v}));
							}}
							setZoom={(zoom) => setCrop((prev) => ({...prev, zoom}))}
							onRemove={onRemove}
							onCropComplete={(croppedAreaPixels) =>
								setCroppedAreaParams(croppedAreaPixels)
							}
						/>
					: <ImageUploader onChanged={(img) => {
							setEditing(true);
							setSaveDisabled(false);
							setImageFile(img);
						}}/>
				}
				<span className={join(imageURL ? styles.hide : styles.showText)}>
					Drag and drop or click to upload
				</span>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={handelClose}>CANCEL</Buttons.Gray>
				<Buttons.Contained
					disabled={isSaveDisabled || (isEditing && !croppedAreaParams)}
					onClick={onUpload}
				>
					SAVE
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	);
}
