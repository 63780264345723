import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import {FixedColumnPosition, ReportTableFixableProps} from './types';
import styles from './report-table.module.less';

export function ReportTableTD(props: ReportTableFixableProps) {
	let className = null;
	if (props.fixedColumnPosition === FixedColumnPosition.Left) {
		className = styles.fixedLeftTd;
	}

	if (props.fixedColumnPosition === FixedColumnPosition.Right) {
		className = styles.fixedRightTd;
	}

	return <td className={join(className, props.className)}>
		{props.children}
	</td>;
}

