import {ProgressBar} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Label = styled(Text, {
	textAlign: 'center',
});

export const ProgressBarRoot = styled(ProgressBar, {
	marginRight: '12px',
});

export const ProgressBarLabel = styled(Text, {
	marginTop: '2px',
});

export const ProgressBarContainer = styled(FlexBox, {
	alignItems: 'center',
	marginBottom: '8px',
});

export const CorrectAnswersCount = styled(Text, {
	marginRight: '1px',
});

export const Container = styled(FlexBox, {
	position: 'relative',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: '12px',
});

export const EmojiBox = styled(Box, {
	position: 'absolute',
});
