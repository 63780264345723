import {CloseButton} from '../../../kit/buttons/close-button';
import {QuestionFooter, QuestionHeader, QuestionLayout, QuestionTitle} from '../../../kit/question-layout';
import {ArrowButton} from '../../../kit/question-layout/arrow-button';
import {Text} from '../../../kit/question-layout/text';
import {QuestionSlider, useQuestionSlider} from '../../../kit/slider';
import {QuestionModel} from '../../../kit/models';
import {Selectors} from '../../preview.selectors';
import styles from './styles.module.less';

export interface Props {
	questions: QuestionModel[];
	testName: string;
	hasTestIntroPage: boolean;
	isWhiteBackground: boolean;
	backClicked: () => void;
	closeClicked: () => void;
}

export function PreviewQuestionSlider(props: Props) {
	const {index, current, next, back, canNext, canBack} = useQuestionSlider(props.questions);

	const currentQuestionIndex = index;

	const questionsCount = props.questions.length;
	const directions = current?.direction;

	const handleBack = () => {
		if (canBack) {
			back();
		} else if (props.hasTestIntroPage) {
			props.backClicked();
		}
	};

	return <QuestionLayout whiteBackground={props.isWhiteBackground} data-cy={Selectors.questionSlider}>
		<QuestionHeader>
			<QuestionTitle>
				<span>{props.testName}</span>
			</QuestionTitle>
			<CloseButton isWhiteBackground={props.isWhiteBackground} onClick={props.closeClicked}/>
		</QuestionHeader>
		<QuestionSlider questions={props.questions} currentIndex={currentQuestionIndex}/>
		<div>
			{directions && <div className={styles.direction}>
				Directions: <Text className={styles.directionText} data-cy={Selectors.directions}>{directions}</Text>
			</div>}
		</div>
		<QuestionFooter>
			<div className={styles.yesButtonHolder}>
				<ArrowButton tabIndex={3}
				             data-cy={Selectors.prevButton}
				             direction='left'
				             disabled={!props.hasTestIntroPage && !canBack}
				             className={styles.btnPrevious}
				             onClick={handleBack}/>
			</div>
			<div className={styles.questionsTestedHolder}>
				<Text className={styles.currentQuestion} data-cy={Selectors.currentQuestionIndex}>
					{currentQuestionIndex + 1}
				</Text>
				of
				<Text className={styles.totalQuestions} data-cy={Selectors.totalQuestions}>
					{questionsCount}
				</Text>
			</div>
			<div className={styles.noButtonHolder}>
				<ArrowButton tabIndex={4}
				             data-cy={Selectors.nextButton}
				             direction='right'
				             disabled={!canNext}
				             className={styles.btnNext}
				             onClick={next}/>
			</div>
		</QuestionFooter>
	</QuestionLayout>;
}
