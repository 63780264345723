import {MutableRefObject} from 'react';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {UntestedStudentsReportService} from '../service';
import styles from './modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService
	leftBottomPanelRef: MutableRefObject<HTMLDivElement>
	leftTopPanelRef: MutableRefObject<HTMLDivElement>
	leftPanelRef: MutableRefObject<HTMLDivElement>;
	grTableShRef: MutableRefObject<HTMLTableRowElement>
	firstHeaderRef: MutableRefObject<HTMLTableRowElement>
}

export function ReportLeftPanel ({service, leftTopPanelRef, leftBottomPanelRef, leftPanelRef, grTableShRef, firstHeaderRef}: Props) {
	const {reportService} = service;
	const rows = useBehaviorSubject(reportService.reportRows);
	const sortModel = useBehaviorSubject(reportService.sortModel);
	const lastTestedStudentID = useBehaviorSubject(service.lastTestedStudentID);

	return <div className={styles.leftPanel} ref={leftPanelRef}>
		<div ref={leftTopPanelRef} className={styles.leftTopPanel}>
			<table>
				<tbody>
					<tr className={styles.firstHeader} ref={firstHeaderRef}>
						<th className={styles.entityInfoHeader} colSpan={2}>
							<span className={styles.headerTi}>Students</span>
						</th>
					</tr>
					<tr className={styles.grTableSubheader} ref={grTableShRef}>
						<td className={join(styles.entityInfoSubheader, 'subheader-title')}>
							<div onClick={() => reportService.sort('firstName')}>
								First Name
								<i className={join(
									'fa',
									styles.sortIcon,
									sortModel.direction === 'Asc' && 'fa-arrow-down',
									sortModel.direction === 'Desc' && 'fa-arrow-up',
									sortModel.fieldName === 'firstName' && styles.visible,
								)}
								/>
							</div>
						</td>
						<td className={join(styles.entityInfoSubheader, 'subheader-title')}>
							<div onClick={() => reportService.sort('lastName')}>
								Last Name
								<i className={join(
									'fa',
									styles.sortIcon,
									sortModel.direction === 'Asc' && 'fa-arrow-down',
									sortModel.direction === 'Desc' && 'fa-arrow-up',
									sortModel.fieldName === 'lastName' && styles.visible,
								)}
								/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div className={styles.leftBottomPanel} ref={leftBottomPanelRef}>
			<div>
				<table className={join(styles.table, 'table', 'table-hover', 'table-striped')}>
					<tbody>
						{rows?.map(({studentID, firstName, lastName}) => (
							<tr
								key={studentID}
								className={join(
									'data-row-left',
									studentID === lastTestedStudentID && styles.lastTested,
								)}
							>
								<td className={styles.entityInfoCell}>{firstName}</td>
								<td className={styles.entityInfoCell}>{lastName}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	</div>;
}
