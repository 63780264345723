export const YesNoSelectors = {
	questionSlider: 'question-slider',
	directions: 'directions',
	sliderBackBtn: 'slider-back-btn',
	sliderIncorrectBtn: 'slider-incorrect-btn',
	sliderCorrectBtn: 'slider-correct-btn',
	sliderSkipBtn: 'slider-skip-btn',
	sliderQuestionIndex: 'slider-question-index',
	sliderComment: 'slider-comment',
	lostConnectionAlert: 'lost-connection-alert',
	sessionStartedAlert: 'session-started-alert',
	sliderTotalQuestionsCount: 'slider-total-questions-count',
	sessionStartedAlertAbortButton: 'session-started-alert-abort',
	sessionStartedAlertConfirmButton: 'session-started-alert-confirm',

};
