import {useCallback, useMemo} from 'react';
import {TestSession, TestInfo, AnswerState, Answer} from '../../../../../../../../types';
import {SessionItem} from '../../session-list/types';

export function useSessionItems({testSessions, testInfo}: {testSessions: TestSession[]; testInfo: TestInfo}) {
	const getCorrectAnswersCount = useCallback(
		(answers: Answer[]) =>
			answers.reduce((currentSum, {answerState}) => {
				if (answerState === AnswerState.Correct) {
					return currentSum + 1;
				}

				return currentSum;
			}, 0),
		[],
	);

	return useMemo(() => {
		const {totalPossible} = testInfo;

		return testSessions.map<SessionItem>(({answers, testDate, id}, index, iteratedSessionList) => {
			const correctAnswers = getCorrectAnswersCount(answers);

			const percent = (correctAnswers / totalPossible) * 100;

			const nextSessionCorrectAnswers = iteratedSessionList[index + 1]
				? getCorrectAnswersCount(iteratedSessionList[index + 1]!.answers)
				: 0;
			const nextSessionPercent = (nextSessionCorrectAnswers / totalPossible) * 100;

			const delta = Math.round(percent - nextSessionPercent);

			return {
				date: testDate,
				delta: index === iteratedSessionList.length - 1 ? 0 : delta,
				sessionId: id,
				totalPossible,
				sessionResult: correctAnswers,
			};
		});
	}, [testInfo, testSessions, getCorrectAnswersCount]);
}
