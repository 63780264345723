import {Drawer} from '@esgi/main/kits/common';
import {PanelContent} from '../../components/panels.styled';
import {ContentBox, UserNameBox} from '../../components/content-box.styled';
import {DisabledInput} from '../../components/disabled-input';
import {userNameTitleTranscript} from '../../constants';
import {NameTitle, UserCountry, UserState} from '../../../../types';
import {GridBox} from '@esgi/ui/layout';
import {useRef} from 'react';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {ExpirationDate} from '../../../expiration-date';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props = {
	firstName: string;
	lastName: string;
	email: string;
	nameTitle: NameTitle;
	countryId: UserCountry['countryID'];
	countries: UserCountry[];
	stateId: UserState['stateID'];
	states: UserState[];
	expirationDate: Date;
};

export function PersonalInformationMainAccountContent({
	firstName,
	lastName,
	email,
	nameTitle,
	countries,
	countryId,
	stateId,
	states,
	expirationDate,
}: Props) {
	const {
		current: {countryName, stateName},
	} = useRef({
		countryName: countries.find(({countryID}) => countryId === countryID)?.name ?? '',
		stateName: states.find(({stateID}) => stateID === stateId)?.name ?? '',
	});

	return (
		<>
			<Drawer.PanelHeader>
				<PanelHeaderTitle title='Personal Information' additionalText='Main Account' />
			</Drawer.PanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						<Drawer.ContentBlock title='Name'>
							<UserNameBox>
								<DisabledInput value={userNameTitleTranscript[nameTitle]} placeholder='Title' />
								<DisabledInput value={firstName} placeholder='First Name' />
								<DisabledInput value={lastName} placeholder='Last Name' />
							</UserNameBox>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Location'>
							<GridBox columns='2' gap='3'>
								<DisabledInput value={countryName} placeholder='Country' />
								<DisabledInput value={stateName} placeholder='State' />
							</GridBox>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Email'>
							<DisabledInput value={email} placeholder='Email' />
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Expiration Date'>
							<ExpirationDate date={expirationDate} />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
