import {Cheap} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Tooltip} from '@esgi/ui/tooltip';
import {Text} from '@esgi/ui/typography';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const SubjectGridBox = styled(GridBox, {
	width: '100%',
	gridTemplateColumns: 'auto 1fr',
	alignItems: 'center',
	gap: 6,
});

export const CheapBox = styled(Cheap, {
	alignSelf: 'start',

	variants: {
		skeleton: {
			true: {
				width: 16,
				height: 16,
				boxSizing: 'border-box',
				...SkeletonStyles,
			},
		},
	},
});

export const SubjectText = styled(Text, {
	wordBreak: 'break-word',
	variants: {
		noWrap: {
			true: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
		},

		skeleton: {
			true: {
				width: '50%',
				height: 12,
				...SkeletonStyles,
			},
		},

		hidden: {
			true: {
				color: '$muted',
			},
		},
	},
});

export const TooltipContent = styled(Tooltip.Content, {
	width: 'var(--radix-tooltip-trigger-width)',
});
