import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onClose: () => void;
}

export function SessionEndedAlert(props: Props) {
	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);

	return <Alert modalManagerRef={modal}>
		<Alert.Body>
			The Student Screen Session has ended.
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={close}>
				OK
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
