import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './base-button.module.less';
import rightPanelStyle from '../../right-panel/right-panel.module.less';

export class Props {
	title: string;
	icon: JSX.Element;
	hoverTitle: string;
	linkClassName?: string;
	linkStyleName?: string;
	onClicked: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export default class BaseReportButton extends React.PureComponent<Props> {
	render() {
		return <div className={styles.btn}>
			<OnHoverTooltip message={this.props.hoverTitle ? this.props.hoverTitle : null}>
				<a href='#' className={this.props.linkClassName ? this.props.linkClassName : ''}
				   onClick={(e) => this.props.onClicked(e)}>
					<span className={styles.icon}>
						{this.props.icon}
					</span>
					<span className={rightPanelStyle.reportTitle}>{this.props.title}</span>
				</a>
			</OnHoverTooltip>
		</div>;
	}
}
