import {useState, useEffect} from 'react';
import {tryCall, join} from '@esgillc/ui-kit/utils';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {IEPProgressReportService} from '../../services';
import {Header} from '../header';
import {Title} from './components/title';
import {Content} from './components/content';
import {InitRequest} from '../../types';
import {getDateWithoutTimeZone} from '../../utils';
import dialogStyles from '../../styles.module.less';
import styles from './styles.module.less';

interface Props {
	service: IEPProgressReportService;
	reportData: Record<string, any>;
	onBack?: () => void;
	onClose?: () => void;
}

export const Report = (props: Props): JSX.Element => {
	const {service, reportData} = props;
	const [isLoaded, setIsLoaded] = useState(false);
	const modal = useModal();

	const onBack = useCloseModal(modal, () => tryCall(props.onBack));
	const onClose = useCloseModal(modal, () => tryCall(props.onClose));

	useEffect(() => {
		setIsLoaded(false);
		service.initReport({
			fromDate: getDateWithoutTimeZone(reportData.fromDate),
			toDate: getDateWithoutTimeZone(reportData.toDate),
			latestSessionOnly: reportData.latestSessionOnly,
			showBaseline: reportData.showBaseline,
			studentId: reportData.student.id,
			testIDs: reportData.testIDs,
		} as InitRequest, () => setIsLoaded(true));
	}, []);

	return (
		<Modal
			modalManagerRef={modal}
			className={dialogStyles.modal}
		>
			<Header
				service={service}
				reportData={reportData}
				onBack={onBack}
				onClose={onClose}
			/>
			<Modal.Body className={join(dialogStyles.bodyReport, styles.iepContainer)}>
				<Title reportData={reportData}/>
				{isLoaded &&
					<Content
						service={service}
						reportData={reportData}
					/>
				}
			</Modal.Body>
		</Modal>
	);
};
