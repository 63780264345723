import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {styled} from '../theme';
import {Skeletonable} from '../types';
import {Text} from '../typography';
import {Box} from '../layout/box';
import {VariantProps, CSS} from '@stitches/react';

export type CheapColor = 'primary' | 'secondary' | 'neutral';

type CheapProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface, leveraging the given media and style map. */
	css?: CSS;
} & Skeletonable &
	VariantProps<typeof CheapContent>;

const CheapComponent = forwardRef<HTMLDivElement, CheapProps>(
	({dataCy, color = 'primary', skeleton, ...props}, forwaredRef) => (
		<CheapContent data-cy={dataCy ?? 'ui-kit-cheap'} color={color} ref={forwaredRef} {...props} />
	),
);

const CheapContent = styled(Box, {
	all: 'unset',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: 1,
	paddingBottom: 1,
	paddingLeft: 3,
	paddingRight: 3,
	borderRadius: 4,
	borderColor: '$muted',
	borderStyle: 'solid',
	borderWidth: 1,
	userSelect: 'none',

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	variants: {
		/** Color variants. */
		color: {
			primary: {
				color: '$primary',
				backgroundColor: '$primaryMuted',
			},
			secondary: {
				color: '$secondary',
				backgroundColor: '$secondaryMuted',
			},
			neutral: {
				color: '$highContrast',
				backgroundColor: '$border',
			},
		},

		/** Text style. */
		uppercase: {
			true: {
				textTransform: 'uppercase',
			},
		},
	},
});

export const Cheap = Object.assign(CheapComponent, {
	toString: () => `.${CheapContent.className}`,
});
