import RubricDetailsModal from 'modules/assets/tests/rubric/details/root';
import TestDetails from 'shared/modules/test-details/test-details';
import {TestType} from '@esgi/main/libs/core';
import {TestInfo} from 'modules/assets/tests/rubric/common/models';
import {ChangedTestModel, TestModel} from '../../service/types';
import {useCallback, useMemo, useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {TestHiddenChangedEvent} from 'modules/assets/tests/kits/test-details';
import {OptionItem} from '@esgi/main/features/standards-drawer';

type Props = {
	contentAreas: OptionItem[],
	testType: TestType,
	firstName: string,
	lastName: string
	userID: number,
	testID: number,
	close: (changedTest?: Partial<TestModel>) => void,
	onTestCreated: (closeModal?: boolean) => void
	onHiddenChanged: (testId: number, isHidden: boolean) => void;
	selectedStateStandardType: number;
	selectedStateStandardIDs: number[];
}

type HiddenStatus = {testID: number | null, triggered: boolean, hidden: boolean}

export function TestDetailsModal(props: Props) {
	const [hiddenStatus, setHiddenStatus] = useState<HiddenStatus>({triggered: false, hidden: false, testID: null});

	useEventEffect(TestHiddenChangedEvent, ({testID, isHidden}) => {
		setHiddenStatus({triggered: true, hidden: isHidden, testID});
	}, [props, hiddenStatus]);

	const handleChangeHidden = useCallback((testId: number, isHidden: boolean) => {
		setHiddenStatus({triggered: true, hidden: isHidden, testID: testId});
	}, [props, hiddenStatus]);

	const handleClose = (changedTest: ChangedTestModel) => {
		if (changedTest) {
			const changedTestData: Partial<TestModel> = {
				testID: changedTest.testID,
				name: changedTest.name,
				description: changedTest.description,
				contentArea: changedTest.contentAreaName,
				gradeLevels: changedTest.gradeLevels,
				starred: changedTest.starred,
				numberOfQuestions: changedTest.numberOfQuestions,
				totalPossible: changedTest.totalPossible,
				imageQuestionID: changedTest.imageQuestionID,
				hidden: hiddenStatus.triggered ? hiddenStatus.hidden : undefined,
			};
			if (!changedTest.stateStandardIDs?.length) {
				changedTestData.stateStandards = [];
			}
			props.close(changedTestData);
		} else {
			props.close();
		}

		if (hiddenStatus.triggered) {
			props.onHiddenChanged(hiddenStatus.testID, hiddenStatus.hidden);
		}

	};

	const handleRubricClose = useCallback((changedTest: TestInfo & {criteriaCount: number}) => {
		if (changedTest) {
			const changedTestData: Partial<TestModel> = {
				testID: changedTest.testID,
				type: props.testType,
				name: changedTest.name,
				description: changedTest.description,
				gradeLevels: changedTest.gradeLevelIDs,
				contentArea: props.contentAreas.find(item => item.value === changedTest.contentAreaID)?.label,
				numberOfQuestions: changedTest.criteriaCount,
			};
			if (!changedTest.stateStandardIDs?.length) {
				changedTestData.stateStandards = [];
			}
			props.close(changedTestData);
		}
		if (hiddenStatus.triggered) {
			props.onHiddenChanged(hiddenStatus.testID, hiddenStatus.hidden);
			setHiddenStatus({triggered: false, hidden: false, testID: null});
		}

	}, [hiddenStatus.hidden, hiddenStatus.testID, hiddenStatus.triggered, props]);

	const testDetailsStateStandardsData = useMemo(() => {
		if (props.selectedStateStandardIDs.length) {
			return {
				selectedStateStandardType: props.selectedStateStandardType,
				selectedStateStandardIDs: props.selectedStateStandardIDs,
			};
		}
		return {
			selectedStateStandardType: null,
			selectedStateStandardIDs: undefined,
		};
	}, [props.selectedStateStandardType, props.selectedStateStandardIDs]);

	return (
		<>
			{props.testType === TestType.Rubric
				? <RubricDetailsModal
					testID={props.testID}
					close={handleRubricClose}
					onTestCreated={props.onTestCreated}
					{...testDetailsStateStandardsData}
				/>
				: (
					<TestDetails
						firstName={props.firstName}
						lastName={props.lastName}
						userID={props.userID}
						testID={props.testID}
						close={(_, changedTest) => handleClose(changedTest)}
						onTestCreated={props.onTestCreated}
						onHiddenChanged={(testId, isHidden) => handleChangeHidden(testId, isHidden)}
						{...testDetailsStateStandardsData}
					/>
				)
			}
		</>
	);
}