import {createContext, useContext} from 'react';
import {useService} from '@esgi/core/service';
import {FlashcardsService} from './services';

export const useFlashcards = () => {
	const service = useService(FlashcardsService);

	return {service};
};

export const FlashcardsContext = createContext(null);
export const useFlashcardsService = () =>
	useContext<FlashcardsService>(FlashcardsContext);
