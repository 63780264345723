import {useEffect, useState} from 'react';
import {getUser} from '@esgi/core/authentication';
import {Buttons} from '@esgillc/ui-kit/button';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {
	AutoTestCreatorEditor, State as AutoTestCreatorEditorState,
} from 'shared/modules/question-editor/editors/auto-test-creator/auto-test-creator';
import {Black as ShapeDefinitionBuilder} from 'shared/modules/question-editor/utils/shape-definition-builder';
import {StateStandardsLibrary} from 'shared/modules/state-standards-library';
import {ModalWindow} from '@esgi/deprecated/knockout';
import {Field, Validators} from '@esgi/deprecated/elements';
import {EditQuestion, PreviewState, ShapeDefinitions, TileState, UpdateResult} from '../../types';
import {DeleteAlert} from './components/delete-alert';
import {SaveDialog} from './components/save-dialog';
import {Tile} from './components/tile';
import {previewRequest} from './utils';
import styles from './styles.module.less';

interface Props {
	preview: PreviewState;
	onChange: (p: TileState[]) => void;
	onClose: () => void;
	onSave: () => void;
}

export function Preview({preview, onClose, onChange, onSave}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	const {name, tiles, defaultImageId} = preview;
	const currentUser = getUser();

	const [questionsState, setQuestionsState] = useState(tiles);
	const [questionEditor, setQuestionEditor] = useState(null);
	const [shapeDefinitions, setShapeDefinitions] = useState<ShapeDefinitions>();
	const [deletedQuestionID, setDeletedQuestionID] = useState(null);
	const [selectedThumbnailID, setSelectedThumbnailID] = useState(defaultImageId);
	const [isQuestionStateChanged, setQuestionStateChanged] = useState(false);
	const [isDeleteAlertOpened, setDeleteAlertOpened] = useState(false);
	const [isShowSaveDialog, setShowSaveDialog] = useState(false);

	useEffect(() => {
		const definitions = new ShapeDefinitions();
		new ShapeDefinitionBuilder().Build(definitions);
		setShapeDefinitions(definitions);
	}, []);

	useEffect(() => {
		setQuestionsState(prev => prev.map((t) => ({
			...t,
			question: {
				...t.question,
				isDefaultImage: t.id === selectedThumbnailID,
			},
		})));
	}, [selectedThumbnailID]);

	const onSubmit = () => {
		onChange(questionsState);
		onSave();
		handleClose();
	};

	const onDelete = () => {
		setQuestionsState(prev => prev.filter(q => q.id !== deletedQuestionID));
		setDeleteAlertOpened(false);
	};

	const onCancel = () => {
		if (isQuestionStateChanged) {
			setShowSaveDialog(true);
		} else {
			handleClose();
		}
	};

	const onSaveQuestion = (update: UpdateResult) => {
		if (update) {
			const newTile = questionsState.filter(x => x.id === update.tileID)[0];
			newTile.question.directions = update.directions;
			newTile.question.name = update.name;

			if (!!newTile.question.stateStandardID && !update.stateStandardID) {
				newTile.question.stateStandardID = null;
				newTile.question.stateStandard = '';
				newTile.question.contentAreaID = 0;
			}

			newTile.question.images = update.images;
			newTile.question.xml = update.xml;
			newTile.changed = true;

			const changedQuestions = questionsState.map(x => x.id === update.tileID ? newTile : {...x});
			onChange(changedQuestions);
			setQuestionsState(changedQuestions);
			setShapeDefinitions(update.definitions);
		}
		setQuestionEditor(null);
	};

	const onDeleteQuestion = (id: string) => {
		setDeletedQuestionID(id);
		setDeleteAlertOpened(true);
	};

	const onEditQuestion = ({testName, tileID, orderNum, state}: EditQuestion) => {
		const question = state.filter(x => x.id === tileID)[0].question;
		const newState = new AutoTestCreatorEditorState();

		newState.tileID = tileID;
		newState.form.stateStandard = question.stateStandard;
		newState.form.stateStandardID = question.stateStandardID;
		newState.form.questionName = new Field(question.name);
		newState.form.directions = new Field(question.directions, Validators.successfulValidation);
		newState.question.isWhiteBackground = false;
		newState.question.testName = testName;
		newState.question.orderNum = orderNum;
		newState.question.xml = question.xml;
		newState.question.images = question.images;

		setQuestionEditor(newState);
	};

	const onUpdateQuestionState = (update: UpdateResult) => {
		onSaveQuestion(update);
		if (update) {
			setQuestionStateChanged(true);
		}
	};

	const onChangeStateStandard = (id: string, stateID: number) => {
		const tile = questionsState.find(x => x.id === id);

		const stateStandardsLibrary = new StateStandardsLibrary({
			selectedContentAreaId: tile.question.contentAreaID,
			selectedStateStandardId: tile.question.stateStandardID,
			selectedStateStandardText: tile.question.stateStandard,
			stateId: stateID,
		});

		//TODO: Remove after src/shared/modules/state-standards-library/state-standards-library.tsx will be refactored
		const modal = new ModalWindow(stateStandardsLibrary,
			{
				allowClose: true,
				showHeader: true,
				showFooter: true,
				className: 'state-standard-modal',
				title: 'State Standards Library',
				buttons: [
					{
						title: 'Add',
						className: 'btn btn-primary btn-sm',
						submit: true,
						align: 'right',
						onClick: stateStandardsLibrary.view.okClicked,
						closeModal: false,

					},
					{
						title: 'Close',
						className: 'btn btn-sm ',
						submit: true,
						align: 'right',
						icon: 'fa fa-times',
						closeModal: true,
						cancel: true,
					},
				],
			});

		stateStandardsLibrary.events.onUpdate((event, changes) => {
			tile.question.stateStandardID = changes.stateStandardId;
			tile.question.stateStandard = changes.stateStandard;
			tile.question.contentAreaID = changes.contentAreaId;

			setQuestionsState(prev => prev.map(x => x.id === id ? tile : {...x}));
			showSnackbarNotification(`State Standard has been added to ${changes.stateStandard}`);
		});

		stateStandardsLibrary.events.onClosed(() => {
			modal.close();
		});

		modal.load();
	};

	return <div data-cy='preview'>
		{questionEditor && <AutoTestCreatorEditor
			shapeDefinitions={shapeDefinitions}
			onSaved={onUpdateQuestionState}
			state={questionEditor}
			onChange={ch => setQuestionEditor(ch)}/>}

		<Modal modalManagerRef={modalRef}>
			<Modal.Header>
				<Title>Thumbnail View: {name}</Title>
				<CloseIcon onClick={onCancel}/>
			</Modal.Header>

			<Modal.Body className={styles.body}>
				<div className={styles.questionsContainer}>
					{questionsState.map((q, i) => {
						return <Tile
							key={q.id}
							tile={q}
							orderNumber={i + 1}
							author={preview.author}
							previewRequest={(xml) => previewRequest(xml)}
							isDefaultImage={selectedThumbnailID === q.id}
							onChangeDefaultImageID={setSelectedThumbnailID}
							onDelete={(id) => onDeleteQuestion(id)}
							onEdit={() => onEditQuestion({
								testName: name,
								tileID: q.id,
								orderNum: i + 1,
								state: questionsState,
							})}
							onChangeStateStandard={() => onChangeStateStandard(q.id, currentUser.stateID)}
						/>;
					})}
				</div>

				{isDeleteAlertOpened && <DeleteAlert
					tilesCount={questionsState.length}
					onDelete={onDelete}
					onCancel={() => setDeleteAlertOpened(false)}/>}

				{isShowSaveDialog && <SaveDialog
					onCancel={() => setShowSaveDialog(false)}
					goBack={handleClose}/>
				}
			</Modal.Body>

			<Modal.Footer className={styles.footer}>
				<Buttons.Gray onClick={onCancel}>BACK</Buttons.Gray>
				<Buttons.Contained onClick={onSubmit}>SAVE</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	</div>;
}

