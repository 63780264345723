import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {stickyHeight} from '../../../sticky/index.styled';

export const TestList = styled(GridBox, {
	gap: '$2',

	variants: {
		offset: {
			true: {
				paddingBottom: stickyHeight - 10,
			},
		},
	},
});
