import * as React from 'react';
import type {SVGProps} from 'react';

export function Expand(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M15.433 18.756a.78.78 0 0 0 1.134 0l3.198-3.333a.857.857 0 0 0 0-1.179.78.78 0 0 0-1.134 0L16 16.987l-2.631-2.743a.78.78 0 0 0-1.134 0 .857.857 0 0 0 0 1.18l3.198 3.332Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
