import {BaseService} from '@esgi/core/service';
import {FileType, RequestResultsInit, RequestTest, ResponseResultsInit} from '../../api-models';
import {SsoTracker} from '@esgi/core/tracker';
import {Init} from './models';
import {BehaviorSubject} from 'rxjs';
import {SettingsFormApiActions, SettingsFormApiHost} from '../../constants';
import moment from 'moment/moment';
import {map} from 'rxjs/operators';

const controllerName = 'reports/parent-letter';

export class ResultsService extends BaseService {
	private requestModel = new BehaviorSubject<RequestResultsInit>(null);
	public model = new BehaviorSubject<Init>(null);

	private dateFormat(tests: RequestTest[]){
		tests.forEach((t) => {
			if(t.testDate){
				t.testDate = moment(t.testDate, 'MM-DD-YYYY HH:mm').format();
			}
		});
	}


	public init(requestModel: RequestResultsInit) {
		this.requestModel.next(requestModel);
		this.dateFormat(requestModel.tests);
		return this.httpClient.ESGIApi.post<ResponseResultsInit>(SettingsFormApiHost, SettingsFormApiActions.results, requestModel).pipe(map(r => {
			const result = Init.FromResponse(r);
			result.testResultsCorrectVerbiage = requestModel.testResultsCorrectVerbiage;
			result.testResultsIncorrectVerbiage = requestModel.testResultsIncorrectVerbiage;
			this.model.next(result);
		}));
	}

	private downloadParameters = {
		[FileType.PDF]: {
			Extension: '.pdf',
			ContentType: 'application/pdf',
		},
		[FileType.Word]: {
			Extension: '.docx',
			ContentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		},
		[FileType.ZIP]: {
			Extension: '.zip',
			ContentType: 'application/zip',
		},
	};

	private downloadFile(method: string, reportGuid: string, requestModel: RequestResultsInit, type: FileType, zip: boolean) {
		requestModel.reportGuid = reportGuid;

		const payload = {
			requestModel: requestModel,
			zip: zip,
		};

		if (zip) {
			type = FileType.ZIP;
		}

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = 'Parent_Letter_' + year + '-' + month + '-' + day + this.downloadParameters[type].Extension;

		this.httpClient.ESGIApi.file(controllerName, method, filename, payload).subscribe();
	}

	private downloadPdfWord(method: string, type: FileType, zip: boolean, requestModel: RequestResultsInit) {
		this.downloadFile(method, this.model.value.reportGuid, requestModel, type, zip);

		if (requestModel.allStudentsSelected) {
			const eventFileType = type == FileType.PDF ? 'PDF' : 'Word';
			const eventType = eventFileType + ' ' + (zip ? 'Bulk' : 'Standard');
			SsoTracker.trackEvent({
				trackingEvent: eventType,
				data: {reportType: 'ParentLetter'},
			});
		}
	}

	public pdf(zip) {
		this.downloadPdfWord('download-pdf', FileType.PDF, zip, this.requestModel.value);
	}

	public word(zip) {
		this.downloadPdfWord('download-word', FileType.Word, zip, this.requestModel.value);
	}
}
