import {useCallback} from 'react';
import {momentDatesDiffInDays} from '../helpers/moment-dates-diff-in-days';
import moment from 'moment';
import {isNull, isUndefined} from 'underscore';
import {addDaysToMomentDate} from '../helpers/add-days-to-moment-date';
import {dateFormat, minStepsBetweenThumbs} from '../constants';
import {TrackDateModelState} from '../../../types';

export function useTrackDatesHandlers({
	setIsSliderUpdateDates,
	setSliderValue,
	datesInRowsState,
	setDatesInRowsState,
	startDate,
	endDate,
	sliderValue,
	sliderMaxDays,
	sliderMinDays,
}: {
	setIsSliderUpdateDates: React.Dispatch<React.SetStateAction<boolean>>;
	setSliderValue: React.Dispatch<React.SetStateAction<number[]>>;
	datesInRowsState: TrackDateModelState[];
	setDatesInRowsState: React.Dispatch<React.SetStateAction<TrackDateModelState[]>>;
	startDate: moment.Moment | undefined;
	endDate: moment.Moment | undefined;
	sliderValue: number[];
	sliderMaxDays: number;
	sliderMinDays: number;
}) {
	const handleSliderValueChange = useCallback(
		(newValue: number[]) => {
			setIsSliderUpdateDates(true);
			setSliderValue(newValue);

			setDatesInRowsState((currentDatesByRow) => {
				const datesInRows = [...currentDatesByRow];

				datesInRows.forEach((item, index) => {
					if (!isUndefined(newValue[index])) {
						const datesForDateFrom = newValue[index]! + (!index ? 0 : 1);

						item.dateFrom.value = addDaysToMomentDate(startDate, datesForDateFrom).toDate();

						if (!isUndefined(newValue[index + 1])) {
							item.dateTo.value = addDaysToMomentDate(startDate, newValue[index + 1]!).toDate();
						}

						item.dateFrom.error = undefined;
						item.dateTo.error = undefined;
					}
				});

				return datesInRows;
			});
		},
		[startDate, setDatesInRowsState],
	);

	const handleDateChange = ({
		rowId,
		rangeType,
		value: newDateValue,
	}: {
		rowId: string;
		rangeType: keyof Pick<TrackDateModelState, 'dateFrom' | 'dateTo'>;
		value: Date | undefined;
	}) => {
		const datesInRows = [...datesInRowsState];
		const rowIndex = datesInRows.findIndex((item) => rowId === item.rowId);

		if (rowIndex === -1) {
			return;
		}

		const [mm, dd, yyyy] = moment(newDateValue).format(dateFormat).split('/');

		if (!newDateValue || dd?.length !== 2 || mm?.length !== 2 || isNaN(Number(yyyy)) || Number(yyyy) < 1000) {
			if (datesInRows[rowIndex]![rangeType].error && isNull(datesInRows[rowIndex]![rangeType].value)) {
				return;
			}

			datesInRows[rowIndex]![rangeType].error = `Invalid date format. Please use the format ${dateFormat}.`;
			datesInRows[rowIndex]![rangeType].value = null;

			setDatesInRowsState(datesInRows);

			return;
		}

		datesInRows[rowIndex]![rangeType].value = new Date(newDateValue);

		if (rangeType === 'dateTo') {
			if (rowIndex !== datesInRows.length - 1) {
				const nextRowIndex = rowIndex + 1;

				if (datesInRows[nextRowIndex]) {
					datesInRows[nextRowIndex]!.dateFrom.value = addDaysToMomentDate(moment(newDateValue), 1).toDate();
				}
			}

			for (let i = rowIndex; i >= 0; i--) {
				const dateFrom = moment(datesInRows[i]!.dateFrom.value);
				const dateTo = moment(datesInRows[i]!.dateTo.value);

				if (momentDatesDiffInDays(dateFrom, dateTo) >= 0 || !dateFrom.isValid()) {
					datesInRows[i]!.dateFrom.value = addDaysToMomentDate(dateTo, -1).toDate();

					if (datesInRows[i - 1]) {
						datesInRows[i - 1]!.dateTo.value = addDaysToMomentDate(dateTo, -2).toDate();
					}
				}
			}
		}

		if (rangeType === 'dateFrom') {
			const dateFrom = moment(datesInRows[rowIndex]!.dateFrom.value);
			const dateTo = moment(datesInRows[rowIndex]!.dateTo.value);

			if (momentDatesDiffInDays(dateFrom, dateTo) >= 0 || !dateTo.isValid()) {
				datesInRows[rowIndex]!.dateTo.value = addDaysToMomentDate(dateFrom, 1).toDate();
			}
		}

		for (let i = rowIndex + 1; i < datesInRows.length; i++) {
			const prevDateTo = moment(datesInRows[i - 1]!.dateTo.value);

			if (!moment(prevDateTo).isValid()) {
				const prevDateFrom = moment(datesInRows[i - 1]!.dateFrom.value);

				datesInRows[i - 1]!.dateTo.value = addDaysToMomentDate(prevDateFrom, 1).toDate();

				continue;
			}

			const checkedDateFrom = moment(datesInRows[i]!.dateFrom.value);

			if (momentDatesDiffInDays(checkedDateFrom, prevDateTo) !== 1) {
				datesInRows[i]!.dateFrom.value = addDaysToMomentDate(prevDateTo, 1).toDate();
			}

			const dateFrom = moment(datesInRows[i]!.dateFrom.value);
			const dateTo = moment(datesInRows[i]!.dateTo.value);

			if (momentDatesDiffInDays(dateFrom, dateTo) >= 0) {
				datesInRows[i]!.dateTo.value = addDaysToMomentDate(dateFrom, 1).toDate();
			}
		}

		datesInRows.forEach((rowItem, index, iteratedValues) => {
			const dateFrom = moment(rowItem.dateFrom.value);
			const dateTo = moment(rowItem.dateTo.value);

			if (!isNull(rowItem.dateFrom.value)) {
				rowItem.dateFrom.error = undefined;
			}

			if (!isNull(rowItem.dateTo.value)) {
				rowItem.dateTo.error = undefined;
			}

			if (!index && momentDatesDiffInDays(startDate, dateFrom) > 0 && startDate) {
				rowItem.dateFrom.error = `Start date cannot be before ${startDate.format(dateFormat)}`;
			}

			if (index === iteratedValues.length - 1 && momentDatesDiffInDays(dateTo, endDate) > 0 && endDate) {
				rowItem.dateTo.error = `End date cannot be after ${endDate.format(dateFormat)}`;
			}

			if (momentDatesDiffInDays(dateFrom, dateTo) > 0) {
				rowItem.dateTo.error = 'Too early.';
			}
		});

		const sliderValues = datesInRows.reduce<number[]>((acc, item, index) => {
			const datesFrom = momentDatesDiffInDays(moment(item.dateFrom.value), startDate);
			const datesTo = momentDatesDiffInDays(moment(item.dateTo.value), startDate);

			if (!index) {
				return [datesFrom, datesTo];
			}

			return [...acc, datesTo];
		}, []);

		setSliderValue(
			sliderValues.map((value, index) => {
				if (isNaN(value) && !isUndefined(sliderValue[index])) {
					return sliderValue[index]!;
				}

				return value < 0
					? Math.max(value, sliderMinDays + index * minStepsBetweenThumbs)
					: Math.min(value, sliderMaxDays - index * minStepsBetweenThumbs);
			}),
		);

		setDatesInRowsState(datesInRows);
	};

	return {
		handleSliderValueChange,
		handleDateChange,
	};
}
