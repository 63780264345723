import {useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {
	Entities,
	SingleSelectState,
	StudentsPanel,
	StudentsPanelTabID,
} from '@esgi/main/features/teacher/students-panel';
import {Class, Group, Student} from '@esgi/main/libs/store';
import {ClassesContent} from './components/classes-content';
import {GroupsContent} from './components/groups-content';
import {StudentsContent} from './components/students-content';
import {StudentsPanelSkeleton} from './components/skeleton';
import {useValue} from './hooks';

interface Props {
	classes: Class[];
	groups: Group[];
	students: Student[];
	onChange: (value: any) => void;
	skeleton: boolean;
}
export function StudentSelectionPanel({
	classes,
	groups,
	students,
	onChange,
	skeleton,
}: Props) {
	const [selected, setSelected] = useValue<SingleSelectState>(
		{
			classId: null,
			groupId: null,
			studentId: null,
			studentFromClassId: null,
			studentFromGroupId: null,
		},
		onChange
	);

	const entities = useMemo<Entities>(
		() => ({
			classes,
			groups,
			students,
		}),
		[classes, groups, students]
	);

	if (skeleton) {
		return <StudentsPanelSkeleton />;
	}

	return (
		<StudentsPanel.Root
			entities={entities}
			initialTab={StudentsPanelTabID.Classes}
		>
			<StudentsPanel.Selection.Single
				selected={selected}
				onSelectedChange={setSelected}
			>
				<StudentsPanel.Header.Title>
					<Text size='small' color='neutral56'>
						Select Student
					</Text>
				</StudentsPanel.Header.Title>

				<StudentsPanel.Header.Tabs dataCy='students-tabs' />

				<StudentsPanel.Tabs.Root>
					<StudentsPanel.Tabs.Classes sectionTitle='Classes'>
						<ClassesContent />
					</StudentsPanel.Tabs.Classes>
					<StudentsPanel.Tabs.Groups sectionTitle='Groups'>
						<GroupsContent />
					</StudentsPanel.Tabs.Groups>
					<StudentsPanel.Tabs.Students sectionTitle='Students'>
						<StudentsContent />
					</StudentsPanel.Tabs.Students>
				</StudentsPanel.Tabs.Root>
			</StudentsPanel.Selection.Single>
		</StudentsPanel.Root>
	);
}
