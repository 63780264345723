import {useItemAnalysisService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgi/ui';
import {AlertBody as Body, ReportWrapper} from './index.styled';
import {Highchart} from '../highchart';
import {QuestionDetails} from '../question-details';
import {EmptyReport} from '../empty-report';
import {isEmptyReport} from '../../utils';

export function AlertBody() {
	const service = useItemAnalysisService();
	const questionDetailsShow = useBehaviorSubject(service.reportService.questionDetailsShow$);
	const report = useBehaviorSubject(service.reportService.reportData$);
	const emptyReport = isEmptyReport(report);
	const showDetail = questionDetailsShow && !['district', 'school'].includes(service.reportService.level);

	return (
		<Body
			id='iar-body'
			showChart={!emptyReport}
		>
			{!emptyReport
				? <ReportWrapper>
						{showDetail && <QuestionDetails/>}
						<Highchart/>
					</ReportWrapper>
				: <EmptyReport/>
			}
		</Body>
	);
}
