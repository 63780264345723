import {AssignmentContextMenu, TextWithIcon} from '@esgi/main/kits/assignments';
import {ActionButtonsBox, ActionButtonsDivider, CentralPanelHeader, HeaderContainer} from '../../central-panel-header';
import {EmptyContent} from '../../empty-content';
import {noop} from 'underscore';
import {AccessExport, Assignment, Setup} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';
import {useNavigate} from 'react-router-dom';
import {useCallback} from 'react';
import {CentralPanelBody} from '../../central-panel-body';
import {Text} from '@esgi/ui/typography';
import {NoDataContainer} from './index.styled';
import {routes} from '@esgi/main/libs/core';

export function AssignmentNotExisted() {
	const navigate = useNavigate();

	const handleCloseAssignment = useCallback(() => {
		navigate(routes.teacher.assignments.assignmentsList, {
			replace: true,
		});
	}, [navigate]);

	return (
		<>
			<CentralPanelHeader>
				<HeaderContainer>
					<TextWithIcon size='small' color='neutral56' Icon={Assignment}>
						Assignment
					</TextWithIcon>
					<ActionButtonsBox>
						<AssignmentContextMenu onDeleteAssignment={noop} disabledOpenMenu />
						<ActionButtonsDivider />
						<Button.Icon disabled>
							<AccessExport />
						</Button.Icon>
						<Button.Icon disabled>
							<Setup />
						</Button.Icon>
					</ActionButtonsBox>
				</HeaderContainer>
			</CentralPanelHeader>
			<CentralPanelBody>
				<NoDataContainer>
					<EmptyContent messageText={`The assignment hasn't found`} withoutDefaultHeight />
					<Button color='secondary' onClick={handleCloseAssignment}>
						<Text size='medium' bold>
							Go to list
						</Text>
					</Button>
				</NoDataContainer>
			</CentralPanelBody>
		</>
	);
}
