import React from 'react';
import {ConferencerDate, ConferencerItem, ConferencerTime} from 'pages/parent-conferencer/models/models';
import {isMobile} from '@esgillc/ui-kit/utils';
import moment from 'moment';

class Props {
	dates: ConferencerDate[];
	times: ConferencerTime[];
	items: ConferencerItem[];
	openEditDateModal: (date: ConferencerDate, top: number, left: number) => void;
	openEditTimeModal: (time: ConferencerTime, top: number, left: number) => void;
	openEditItemModal: (item: ConferencerItem, cDateID: number, cTimeID: number) => void;
	processDropItem: (event, item: ConferencerItem, cDateID: number, cTimeID: number) => void;
	processDragOver: boolean;
	delete: (id: number) => void;
}

export class Table extends React.Component<Props> {

	private desktopSafari: boolean;

	constructor(props: Props) {
		super(props);
		this.desktopSafari = bowser.safari && !(isMobile());
	}

	private getCoordinates(event) {
		let el = event.currentTarget;
		let rect = el.getBoundingClientRect();
		let top = rect.top + el.offsetHeight;
		let left = rect.left;

		return {top: top, left: left};
	}

	openDateModal = (e, date?: ConferencerDate) => {
		let coordinates = this.getCoordinates(e);
		this.props.openEditDateModal(date, coordinates.top, coordinates.left);
	}

	openTimeModal = (e, time?: ConferencerTime) => {
		let coordinates = this.getCoordinates(e);
		this.props.openEditTimeModal(time, coordinates.top, coordinates.left);
	}

	dragOver = (event) => {
		event.preventDefault();

		const res = this.props.processDragOver;
		if (res) {
			event.target.classList.add('dragged-over');
		}
	}

	dragLeave = (event) => {
		event.preventDefault();
		event.target.classList.remove('dragged-over');
	}

	drop = (event, item: ConferencerItem, cDateID: number, cTimeID: number) => {
		event.preventDefault();
		event.target.className = '';

		this.props.processDropItem(event, item, cDateID, cTimeID);
	}

	render() {
		return <>
			{this.props.dates &&
			<>
				<div className={'schedule-table-container' + (this.desktopSafari ? ' desktop-safari' : '')}>
					<table>
						<thead>
						<tr>
							<th>
								<div>Time\Date</div>
							</th>
							{this.props.dates.map((cd) =>
								this.renderDate(cd),
							)}
							<th>
								<div>
									<a className='open-add-date' href='#'
									   onClick={(event => this.openDateModal(event))}>
										<svg width='18' height='20' viewBox='0 0 18 20' fill='none'
										     xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18Z'
												fill='#0088CC'/>
										</svg>
										<div>Add Date</div>
									</a>
								</div>
							</th>
						</tr>
						</thead>
						<tbody>
						{this.props.times.map((ct) =>
							<tr key={ct.conferencerTimeID}>
								{this.renderTime(ct)}
								{this.props.dates.map((cd) =>
									this.renderItem(cd, ct),
								)}
								<td>
									<div></div>
								</td>
							</tr>,
						)}
						<tr>
							<td>
								<div>
									<a className='open-add-time' href='#'
									   onClick={(event => this.openTimeModal(event))}>
										<svg width='24' height='24' viewBox='0 0 24 24' fill='none'
										     xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
												fill='#0088CC'/>
											<path d='M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z' fill='#0088CC'/>
										</svg>
										<div>Add Time</div>
									</a>
								</div>
							</td>
							{this.props.dates.map((d) =>
								<td key={d.conferencerDateID}>
									<div></div>
								</td>,
							)}
							<td>
								<div></div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</>
			}
		</>;
	}

	renderDate(cd: ConferencerDate) {
		let dateStr = moment(cd.date).format('ddd MMM D, YYYY');
		return <th key={cd.conferencerDateID} data-name={dateStr}>
			<div onClick={(event => this.openDateModal(event, cd))}>{dateStr}</div>
		</th>;
	}

	renderTime(ct: ConferencerTime) {
		let timeStr = moment(ct.time).format('hh:mm A');
		return <td>
			<div data-name={timeStr} onClick={(event => this.openTimeModal(event, ct))}>{timeStr}</div>
		</td>;
	}

	renderItem(cd: ConferencerDate, ct: ConferencerTime) {
		let items = this.props.items.filter(x => x.conferencerDateID === cd.conferencerDateID && x.conferencerTimeID === ct.conferencerTimeID);
		let item = items.length > 0 ? items[0] : null;
		let studentName = item ? item.studentName : '';

		return <td
			data-name={studentName}
			key={ct.conferencerTimeID + '_' + cd.conferencerDateID}
			onDragOver={this.dragOver}
			onDragLeave={this.dragLeave}
			onDrop={(event) => this.drop(event, item, cd.conferencerDateID, ct.conferencerTimeID)}>
			{item &&
			<div className='occupied'>
				<div onClick={() => this.props.openEditItemModal(item, cd.conferencerDateID, ct.conferencerTimeID)}>
					{item.studentName}
				</div>
				<svg onClick={() => this.props.delete(item.conferencerItemID)} width='11' height='11'
				     viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M10.5 1.0575L9.4425 0L5.25 4.1925L1.0575 0L0 1.0575L4.1925 5.25L0 9.4425L1.0575 10.5L5.25 6.3075L9.4425 10.5L10.5 9.4425L6.3075 5.25L10.5 1.0575Z'
						fill='#828282'/>
				</svg>
			</div>
			}
			{!item &&
			<div>
				<a className='available'
				   onClick={() => this.props.openEditItemModal(item, cd.conferencerDateID, ct.conferencerTimeID)}>
					<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
							fill='#0088CC'/>
					</svg>
					<div>Add Student</div>
				</a>
			</div>
			}
		</td>;
	}
}
