import React, {
	ComponentPropsWithoutRef,
	forwardRef,
	PropsWithChildren,
	useCallback,
} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {useImageCropperContext} from '../../context';
import {fileSizeAndTypeValidator} from './utils';
import {UploaderContainer} from './index.styled';
import {ImageMimeType, UploadFileErrorMessage, UploadFileErrorType} from '../../types';

type ImageCropperUploaderProps = PropsWithChildren & {
	/** An array of allowed file types for the uploader. Example file types include. */
	fileTypes: ImageMimeType[];

	/** Maximum file size allowed for the uploaded image. (in bytes) */
	maxFileSize: number;

	/** File type and file size error messages.
	  @default {
		fileTypes: 'File size is too big',
		fileType: 'File type is invalid',
	} */
	errorMessages?: UploadFileErrorMessage;
} & ComponentPropsWithoutRef<'label'> & BaseComponentProps

export const ImageCropperUploader = forwardRef<Omit<HTMLLabelElement, 'onDragOver' | 'onDrop'>, ImageCropperUploaderProps>(({
	maxFileSize,
	fileTypes,
	children,
	dataCy = 'ui-kit-image-cropper-uploader',
	errorMessages = {
		fileTypes: 'File size is too big',
		fileType: 'File type is invalid',
	},
	...props
}) => {

	const {onChangeImageFile, onChangeErrorMessage, errorMessage} = useImageCropperContext();

	const handleChangeErrorMassage = (errorType: UploadFileErrorType) => {
		if (errorMessages) {
			onChangeErrorMessage(errorMessages[errorType] || '');
		}
	};

	const handleDragOver = useCallback((e: React.DragEvent<HTMLLabelElement>) => {
		e.preventDefault();
	}, []);

	const onImageChange = useCallback((e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLLabelElement>, isDrop: boolean) => {
		e.preventDefault();

		const files = isDrop
			? (e as React.DragEvent<HTMLLabelElement>).dataTransfer.files
			: (e as React.ChangeEvent<HTMLInputElement>).target.files;

		if (!files || files.length === 0) {
			return;
		}

		fileSizeAndTypeValidator(files, fileTypes, maxFileSize).subscribe({
			next: (validatedFiles) => {
				onChangeImageFile(validatedFiles[0]);
			},
			error: (error: { type: UploadFileErrorType }) => handleChangeErrorMassage(error.type),
		});
	}, [fileTypes, maxFileSize, onChangeImageFile, onChangeErrorMessage]);

	return <UploaderContainer
		data-cy={dataCy}
		onDrop={(e) => onImageChange(e, true)}
		onDragOver={handleDragOver}
		error={Boolean(errorMessage)}
		{...props}
	>
		<input data-cy={`${dataCy}-input`}
			   type='file' accept={fileTypes.join()}
			   onChange={(e) => onImageChange(e, false)}
		/>
		{children}
	</UploaderContainer>;
});

