import {join} from '@esgillc/ui-kit/utils';
import {SortDirection} from '@esgi/core/enums';

interface Props {
	title?: string;
	className?: string;
	clicked?: () => any;
	sortDirection?: SortDirection;
	sorted: boolean;
}

export function Column(props: Props) {
	const sortIconClassName = join('fa', props.sortDirection === SortDirection.Asc ? 'fa-angle-up' : 'fa-angle-down');

	return <th style={{zIndex: 1}} className={props.className} onClick={props.clicked}>
		<div className='cell-value-holder'>
			{props.title}
			{props.sorted && <i className={sortIconClassName}/>}
		</div>
	</th>;
}
