import {BehaviorSubject, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2PagesAssignmentsController} from '@esgi/contracts/esgi';
import {Assignment} from '../types';

export class AssignmentsListService extends BaseService {
	public assignmentsList$ = new BehaviorSubject<Assignment[]>([]);

	private controller = new V2PagesAssignmentsController();

	public init() {
		return this.controller.list().pipe(
			tap(({assignments}) => {
				this.assignmentsList$.next(assignments);
			}),
		);
	}

	public updateAssignmentInList({
		assignmentId,
		newAssignmentData,
	}: {
		assignmentId: Assignment['id'];
		newAssignmentData: Partial<Assignment>;
	}) {
		const currentAssignmentList = this.assignmentsList$.value;

		this.assignmentsList$.next(
			currentAssignmentList.map((iteratedAssignment) =>
				iteratedAssignment.id !== assignmentId ? iteratedAssignment : {...iteratedAssignment, ...newAssignmentData},
			),
		);
	}

	public removeAssignmentFromList(assignmentId: Assignment['id']) {
		const currentAssignmentList = this.assignmentsList$.value;

		this.assignmentsList$.next(currentAssignmentList.filter(({id}) => id !== assignmentId));
	}

	public addAssignmentToList(assignment: Assignment) {
		const currentAssignmentList = this.assignmentsList$.value;

		this.assignmentsList$.next([...currentAssignmentList, assignment]);
	}

	public override dispose() {
		this.controller.dispose();
	}
}
