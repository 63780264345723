import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {Checkbox} from '@esgillc/ui-kit/control';
import {SchoolAdministratorSettingsService} from './service';
import {GeneralSection} from 'shared/modules/user-settings/components/general-section';
import styles from './styles.module.less';

interface Props {
	userID: number;
	onClose: () => void;
}

function SchoolAdministratorSettings({userID, onClose}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const service = useService(SchoolAdministratorSettingsService);

	const sortValues = useBehaviorSubject(service.sortValues);
	const timeZones = useBehaviorSubject(service.timeZones);
	const showExpiredUsers = useBehaviorSubject(service.showExpiredUsers);

	const [isLoaded, setLoaded] = useState(false);

	const onSubmit = () => service.updateUserSettings().subscribe(handleClose);

	useEffect(() => {
		service.init(userID).subscribe({complete: () => setLoaded(true)});
	}, []);

	return <>
		<ServiceLoader trackingService={service} fullscreen/>;
		{isLoaded && <Modal modalManagerRef={modalRef}>
			<Modal.Header>
				<Title>SETTINGS</Title>
				<CloseIcon onClick={handleClose}/>
			</Modal.Header>

			<Modal.Body className={styles.body}>
				<div data-cy='school-administrator-settings'>
				<GeneralSection
					form={service.generalSectionForm}
					sortValues={sortValues}
					timeZones={timeZones}
				/>
				<div data-cy='show-expired-users' className={styles.row}>
					<label className={styles.label}>Show Expired Users</label>
					<Checkbox
						className={styles.checkbox}
						checked={showExpiredUsers}
						onClick={() => {
							service.showExpiredUsers.next(!showExpiredUsers);
						}}> Enabled </Checkbox>
				</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Buttons.Gray onClick={handleClose}>CANCEL</Buttons.Gray>
				<Buttons.Contained onClick={onSubmit}>SAVE</Buttons.Contained>
			</Modal.Footer>
		</Modal>}
	</>;
}

export default SchoolAdministratorSettings;
