import {
	ChangeEvent,
	ComponentPropsWithoutRef,
	FocusEvent,
	MouseEvent,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import ReactInputMask from 'react-input-mask';
import {isUndefined, useAutoControlledState, useComposedRefs} from '@esgi/ui';
import {InputState, MaskOptions, Meridiem} from './types';
import {MeridiemButtons} from './components/meridiem-buttons';
import {TimelineIcon} from './components/timeline-icon';
import {MeridiemCheap} from './components/meridiem-cheap';
import {Input} from '../input';
import moment from 'moment';

export {Meridiem} from './types';

export type TimePickerProps = Omit<ComponentPropsWithoutRef<typeof Input>, 'type' | 'error' | 'onChange' | 'value'> & {
	error?: string | undefined;
	defaultHours?: number;
	defaultMinutes?: number;
	onValueChanged?: (args: {hours: string; minutes: string; meridiem: Meridiem}) => void;
};

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
	(
		{
			dataCy = 'input-timepicker',
			focused: externalFocused,
			hovered: externalHovered,
			onFocus,
			onMouseEnter,
			onMouseLeave,
			onBlur,
			error,
			disabled,
			variantType = 'default',
			defaultHours,
			defaultMinutes,
			onValueChanged,
			...props
		},
		forwardedRef,
	) => {
		const [timeValue, setTimeValue] = useState('');

		const [focused, setFocused] = useAutoControlledState({
			initialState: false,
			controlledState: externalFocused,
		});

		const [hovered, setHovered] = useAutoControlledState({
			initialState: false,
			controlledState: externalHovered,
		});

		const [meridiem, setMeridiem] = useState(Meridiem.AM);

		useEffect(() => {
			if (isUndefined(defaultHours) && isUndefined(defaultMinutes)) {
				return;
			}

			if (typeof defaultHours === 'number') {
				if (defaultHours < 0 || defaultHours > 23) {
					throw new Error('defaultHours incorrect');
				}

				defaultHours < 12 ? setMeridiem(Meridiem.AM) : setMeridiem(Meridiem.PM);
			}

			if (typeof defaultMinutes === 'number' && (defaultMinutes < 0 || defaultMinutes > 59)) {
				throw new Error('defaultMinutes incorrect');
			}

			const time24 = `${defaultHours}:${defaultMinutes}`;
			const timeHh12 = moment(time24, 'HH:mm').format('hh');
			const timeMm12 = moment(time24, 'HH:mm').format('mm');

			const Hh = isUndefined(defaultHours) ? '__' : timeHh12;
			const Mm = isUndefined(defaultMinutes) ? '__' : timeMm12;

			setTimeValue(`${Hh}:${Mm}`);
		}, []);

		const formatChars: Record<string, string> = {
			H: '[0-1]',
			h: String(timeValue).startsWith('0') ? '[1-9]' : '[0-2]',
			M: '[0-5]',
			m: '[0-9]',
		};

		const innerInputRef = useRef<HTMLInputElement>(null);
		const inputRef = useComposedRefs(innerInputRef, forwardedRef);

		const callOnValueChanged = useCallback(
			({Hh, meridiem, Mm}: {Hh: string | undefined; Mm: string | undefined; meridiem: Meridiem}) => {
				onValueChanged?.({
					hours: !Hh ? '__' : Hh,
					minutes: Mm ?? '__',
					meridiem,
				});
			},
			[onValueChanged],
		);

		const onTimeValueChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				const value = event.target.value;

				setTimeValue(value);

				const [Hh, Mm] = value.split(':');

				callOnValueChanged({Hh, meridiem, Mm});
			},
			[callOnValueChanged, meridiem],
		);

		const handleFocus = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(true);

				onFocus?.(event);
			},
			[onFocus, setFocused],
		);

		const handleBlur = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(false);

				onBlur?.(event);
			},
			[onBlur, setFocused],
		);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(true);

				onMouseEnter?.(event);
			},
			[onMouseEnter, setHovered],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(false);

				onMouseLeave?.(event);
			},
			[onMouseLeave, setHovered],
		);

		const onMeridiemClick = useCallback(
			(value: Meridiem) => {
				const [Hh, Mm] = timeValue.split(':');

				setMeridiem(value);
				callOnValueChanged({Hh, meridiem: value, Mm});
			},
			[callOnValueChanged, timeValue],
		);

		const beforeMaskedValueChange = (
			newState: InputState,
			oldState: InputState,
			userInput: string,
			maskOptions: MaskOptions,
		): InputState => {
			if (
				oldState.value === '_0:00' &&
				newState.value === '00:00' &&
				newState.selection?.start === 1 &&
				newState.selection.end === 1
			) {
				return {
					value: '0_:__',
					selection: newState.selection,
				};
			}

			return {
				value: newState.value,
				selection: newState.selection,
			};
		};

		return (
			<ReactInputMask
				mask='Hh:Mm'
				formatChars={formatChars}
				value={timeValue}
				onChange={onTimeValueChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				disabled={disabled}
				beforeMaskedValueChange={beforeMaskedValueChange}
			>
				{(inputProps: any) => (
					<Input
						error={error}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						type='text'
						variantType={variantType}
						data-error={Boolean(error)}
						disabled={disabled}
						focused={focused}
						hovered={hovered}
						{...inputProps}
						{...props}
						ref={inputRef}
						dataCy='time-input'
					>
						{focused ? (
							<MeridiemButtons activeMeridiem={meridiem} onMeridiemClick={onMeridiemClick} />
						) : (
							<TimelineIcon inputDisabled={Boolean(disabled)} inputHovered={hovered} />
						)}

						{timeValue && !focused && (
							<MeridiemCheap
								inputVariantType={variantType}
								meridiemValue={meridiem}
								inputDisabled={Boolean(disabled)}
								inputHovered={hovered}
								inputErrored={Boolean(error)}
								inputRef={innerInputRef}
							/>
						)}
					</Input>
				)}
			</ReactInputMask>
		);
	},
);
