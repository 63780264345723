import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {Users} from '@esgi/ui';
import {listCSS} from './components.styled';
import {OneLinedText} from '@esgi/main/kits/common';
import {SelectAllClassesButton} from './select-all-classes-button';

interface Props {
	withAllClassButton: boolean;
}
export function ClassesList({withAllClassButton}: Props) {
	const {
		selectClass,
		selected: {classId: selectedClassId},
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	const {classBuckets} = StudentsPanel.Tabs.Classes.useClassesContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			{withAllClassButton && <SelectAllClassesButton />}

			<StudentsPanel.Lists.SelectableList.Single
				items={classBuckets}
				onItemClick={(classId) => selectClass(classId)}
				value={selectedClassId}
				itemVariant='outlined'
				css={listCSS}
				IconBefore={Users}
				dataCy='class-list'
			>
				{({item: {name}}) => (
					<OneLinedText dataCy='class-name' size='large' color='neutral6'>
						{name}
					</OneLinedText>
				)}
			</StudentsPanel.Lists.SelectableList.Single>
		</StudentsPanel.Tabs.TabContent>
	);
}
