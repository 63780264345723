import {useCallback, useState} from 'react';
import {TestSession} from '../../../../../../../../types';

export function useSectionData({testSessions}: {testSessions: TestSession[]}) {
	const [isDetailedViewChecked, setIsDetailedViewChecked] = useState(false);

	const [selectedSessionId, setSelectedSessionId] = useState<TestSession['id']>(testSessions?.[0]?.id);

	const toggleDetailedViewCheckbox = useCallback(() => {
		setIsDetailedViewChecked((value) => !value);
	}, []);

	return {
		isDetailedViewChecked,
		toggleDetailedViewCheckbox,
		setSelectedSessionId,
		selectedSessionId,
	};
}
