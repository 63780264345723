import {useCallback, useEffect} from 'react';
import {fromEvent} from 'rxjs';
import {DesignVersion} from '@esgi/main/libs/core';
import {isNull} from 'underscore';

export function useCheckViewportContent({designVersion}: {designVersion: DesignVersion}) {
	const handleUpdateViewport = useCallback(() => {
		const mvp = document.querySelector(`[name='viewport']`);

		if (isNull(mvp)) {
			return;
		}

		const viewportContentName = 'content';
		const defaultViewportContent = 'width=device-width, initial-scale=1';

		// keep the condition and values with ESGI/Frontends/ESGI/apps/main/host/src/index.html file
		if (window.screen.width < 768 && 'ontouchstart' in window) {
			if (designVersion === DesignVersion.Old) {
				mvp.setAttribute(viewportContentName, 'width=980');
				return;
			}

			mvp.setAttribute(viewportContentName, 'initial-scale=1');
			return;
		}

		mvp.setAttribute(viewportContentName, defaultViewportContent);
	}, [designVersion]);

	useEffect(() => {
		const subscription = fromEvent(window, 'resize').subscribe(handleUpdateViewport);

		return () => {
			subscription.unsubscribe();
		};
	}, [handleUpdateViewport]);

	useEffect(() => {
		handleUpdateViewport();
	}, []);
}
