import {Typo} from '../../utils/typo';
import {from, map, mergeMap, Observable, toArray} from 'rxjs';
import * as utils from '../../utils/utils';
import {NameWritingReport, NameWritingReportDetails, NameWritingReportStudentData} from './types';
import {NameFormat, NamingWritingPrintSettings, StudentStepState} from '../../types';

export class ReportGeneratorService {
	private readonly pageWidth: number;

	constructor() {
		this.pageWidth = Typo.convertToPixels('180mm');
	}

	public generateReport(
		unitData: StudentStepState,
		printSettings: NamingWritingPrintSettings,
	): Observable<NameWritingReport> {
		const initialBodyFontSizeInPixel = Typo.convertToPixels(printSettings.selectedFontSize.key);
		const linesCountByFontSize = [{
			fontSize: Typo.convertToPixels('30pt'),
			linesCount: 10,
		}, {
			fontSize: Typo.convertToPixels('40pt'),
			linesCount: 7,
		}, {
			fontSize: Typo.convertToPixels('62pt'),
			linesCount: 5,
		}];
		const fontFamily = utils.convertEnumToFontFamily(printSettings.fontFamily)
		const fontSizeCalculation = (student): NameWritingReportStudentData[] => {
			const bodyName = utils.getNameByFormat(student.firstName, student.lastName, printSettings.nameFormat);
			const fullName = utils.getNameByFormat(student.firstName, student.lastName, NameFormat.FirstAndLastName);
			const minBodyFontSize = utils.calculateMaxPossibleFontSize(bodyName, this.pageWidth, initialBodyFontSizeInPixel, fontFamily);
			const indexOfLastCheckedValue = linesCountByFontSize.findIndex(x => x.fontSize > minBodyFontSize.fontSize);
			let linesCount = 0;
			if (indexOfLastCheckedValue === 0) {
				linesCount = linesCountByFontSize[0].linesCount;
			} else if(indexOfLastCheckedValue === -1) {
				linesCount = linesCountByFontSize[linesCountByFontSize.length - 1].linesCount;
			} else {
				linesCount = linesCountByFontSize[indexOfLastCheckedValue - 1].linesCount;
			}
			return student.units.map(unit => new NameWritingReportStudentData(
				student.id,
				minBodyFontSize.fontSize,
				bodyName,
				fullName,
				linesCount,
				unit,
			));
		};

		return from(unitData.students).pipe(mergeMap(fontSizeCalculation), toArray(), map(students => {
			const selectedFontSize = Typo.convertToPixels(printSettings.selectedFontSize.key);
			const isFontSizeOptimized = students.some(x => x.bodyFontSize !== selectedFontSize);
			const details = new NameWritingReportDetails(unitData, printSettings, isFontSizeOptimized);
			return new NameWritingReport(details, students);
		}));
	}
}