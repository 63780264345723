import {BaseService} from '../service';
import {userStorage} from '../authentication';
import {getTrackingDataModel, inlineManualDefaultOptions, inlineManualKey, inlineManualLoaderUrl, InlineManualOptions, InlineManualTracking} from './models';

export class InlineManual extends BaseService {
	private static instance: InlineManual;

	private isFeedbackButtonInjected: boolean;

	private readonly version = '2.0';
	private readonly loadUrl = `${inlineManualLoaderUrl}.${inlineManualKey}.js`;
	private readonly options: InlineManualOptions;
	private readonly trackingData: InlineManualTracking;
	private readonly currentUser = userStorage.get();

	private constructor(options?: InlineManualOptions) {
		super();

		this.isFeedbackButtonInjected = false;
		this.trackingData = getTrackingDataModel(this.currentUser);

		this.options = {
			...inlineManualDefaultOptions,
			...options,
		};

		try {
			this.inject();
			this.loadWidget();
			this.boot();
		} catch (e) {
			console.error('Error while initializing Inline Manual: ', e);
		}
	}

	public static getInstance(options?: InlineManualOptions) {
		if (!this.instance) {
			this.instance = new InlineManual(options);
		}

		return this.instance;
	}

	public update() {
		setTimeout(() => {
			try {
				window.InlineManual('update');
			} catch (e) {
				console.error(e);
			}
		}, 500);
	}

	public showWidget() {
		window.inline_manual_player.showPanel();
		if (this.isFeedbackButtonInjected) {
			return;
		}

		this.injectFeedbackButton();
	}

	public destroy() {
		super.destroy();

		InlineManual.instance = null;

		delete window.InlineManual;
		delete window.inline_manual_player;
	}

	private inject() {
		const currentDate = new Date().getTime();

		const inlineManual = function (){
			(window.InlineManual.q = window.InlineManual.q || []).push(arguments);
		};

		inlineManual.l = currentDate;
		inlineManual.snippet = {
			version: this.version,
		};

		window.InlineManual = inlineManual;
	}

	private injectFeedbackButton() {
		const footerContainer = document.querySelector('.inmplayer-panel-footer > div');

		const feedBackButton = document.createElement('a');
		feedBackButton.href = 'https://info.esgisoftware.com/new-esgi-teacher-dashboard-survey';
		feedBackButton.target = '_blank';
		feedBackButton.innerText = 'Submit Feedback';

		footerContainer.prepend(feedBackButton);

		this.isFeedbackButtonInjected = true;
	}

	private loadWidget() {
		const element = document.createElement('script');
		const target = document.getElementsByTagName('script')[0];

		element.async = true;
		element.src = this.loadUrl;
		element.id = this.loadUrl + '-InlineManual';
		target.parentNode.insertBefore(element, target);
	}

	private boot() {
		window.InlineManual('boot', this.trackingData, this.options);
	}
}