import React from 'react';
import {useState} from '@esgi/deprecated/react';
import {StudentModel, UnassignType} from '../../../../../../models/models';
import {userStorage, UserType} from '@esgi/core/authentication';
import MixedUnassignDialog from './dialogs/mixed';
import SpecialistUnassignDialog from './dialogs/specialist';
import TeacherUnassignDialog from './dialogs/teacher';

class State {
	type: UnassignType;
	selectedType: UnassignType;
}

class Props {
	close: Function;
	process: Function;
	students: StudentModel[];
}

@useState(State)
export default class UnassignModal extends React.PureComponent<Props, State> {
	private readonly currentUser = userStorage.get();

	componentDidMount(): void {
		if (this.areStudentsHavePrimaryAssign && this.areStudentsHaveSpecialistGroupAssign) {
			this.setState({type: UnassignType.PrimaryTeacher});
		}
		this.checkTargetType();
	}

	render() {
		switch (this.state.type) {
			case UnassignType.PrimaryTeacher:
				return <TeacherUnassignDialog processClicked={() => this.props.process(this.state.type)}
				                              closeClicked={this.props.close}/>;
			case UnassignType.Specialist:
				const specialistTitle = this.currentUser.userType === UserType.PA ? 'Pre-Assess' : 'Specialist';
				return <SpecialistUnassignDialog processClicked={() => this.props.process(this.state.type)}
				                                 closeClicked={this.props.close}
																				 specialistTitle={specialistTitle}/>;
			case UnassignType.Both:
				return <MixedUnassignDialog processClicked={(t) => this.props.process(t)}
				                            closeClicked={this.props.close}/>;
		}
		return <></>;
	}

	private get areStudentsHaveSpecialistGroupAssign(): boolean {
		return this.props.students.some(x => x.specialistGroupIDs.length > 0);
	}

	private get areStudentsHavePrimaryAssign(): boolean {
		return this.props.students.some(x => x.primaryTeacherID);
	}

	private updateType(type: UnassignType): void {
		if (this.state.type !== type) {
			this.setState({type: type, selectedType: type});
		}
	}

	private checkTargetType(): void {
		let isAdmin = (this.currentUser.userType === UserType.D || this.currentUser.userType === UserType.C);
		let isSpecialist = (this.currentUser.userType === UserType.ISD || this.currentUser.userType === UserType.ISS
		|| this.currentUser.userType === UserType.PA
		);
		let isTeacher = this.currentUser.userType === UserType.T;

		if (isTeacher) {
			return this.updateType(UnassignType.PrimaryTeacher);
		}
		if (isSpecialist) {
			return this.updateType(UnassignType.Specialist);
		}

		if (isAdmin) {
			if (this.areStudentsHavePrimaryAssign && !this.areStudentsHaveSpecialistGroupAssign) {
				return this.updateType(UnassignType.PrimaryTeacher);
			}

			if (this.areStudentsHavePrimaryAssign && this.areStudentsHaveSpecialistGroupAssign) {
				return this.updateType(UnassignType.Both);
			}

			if (!this.areStudentsHavePrimaryAssign && this.areStudentsHaveSpecialistGroupAssign) {
				return this.updateType(UnassignType.Specialist);
			}

			if (!this.areStudentsHavePrimaryAssign && !this.areStudentsHaveSpecialistGroupAssign) {
				return this.updateType(UnassignType.Specialist);
			}
		}

		this.updateType(UnassignType.Both);
	}
}
