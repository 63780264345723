import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {HierarchyMode} from 'modules/hierarchy/core/models';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType} from '@esgi/core/authentication';
import {FilterModel, TeacherModel} from '../models/models';
import DataService from './data-service';

interface Field {
	id: number,
	value: string,
}

export default class FilterService extends BaseService {
	private readonly currentUser = userStorage.get();
	private studentsMap: Map<number, number> = new Map<number, number>();
	public readonly filter$: BehaviorSubject<FilterModel> = new BehaviorSubject<FilterModel>(new FilterModel());

	public name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public mode$: BehaviorSubject<HierarchyMode> = new BehaviorSubject<HierarchyMode>(HierarchyMode.Classic);

	constructor(private dataService: DataService) {
		super();
		dataService.data$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			dataService.data.students.forEach(v => {
				if (v.primaryTeacherID) {
					if (!this.studentsMap.has(v.primaryTeacherID)) {
						this.studentsMap.set(v.primaryTeacherID, v.primaryTeacherID);
					}
				}

				if (v.specialistGroupUserIDs) {
					v.specialistGroupUserIDs.forEach(x => {
						if (x && !this.studentsMap.has(x)) {
							this.studentsMap.set(x, x);
						}
					});
				}
			});
		});
	}

	public updateFilter(change: Partial<FilterModel> | FilterModel): void {
		const prevValue = this.filter$.value;
		this.filter$.next({...prevValue, ...change});
	}

	public get gradeLevels$(): Observable<Field[]> {
		return this.dataService.data$.pipe(map(data => {
			let studentGradeLevels = data.students.map(x => x.gradeLevelID);
			let gradeLevels = data.gradeLevels.filter(x => studentGradeLevels.indexOf(x.gradeLevelID) !== -1).map(x => {
				return {id: x.gradeLevelID, value: x.name};
			});
			gradeLevels.unshift({id: 0, value: 'All'});
			return gradeLevels;
		}));
	}

	public get schools(): Field[] {
		if (this.currentUser.userType !== UserType.D && this.currentUser.userType !== UserType.ISD && this.currentUser.userType !== UserType.PA) {
			return null;
		}

		let schools = this.dataService.data.schools.map(x => {
			return {id: x.schoolID, value: x.name};
		});
		schools.unshift({id: 0, value: 'All'});
		return schools;
	}

	public get classes$(): Observable<Field[]> {
		return combineLatest(this.filter$, this.dataService.data$).pipe(map(p => {
			const [filter, data] = p;
			let classes = [];
			let teacherID = filter.teacherID;
			if (teacherID === 0) {
				return null;
			}
			let schoolID = this.fromFilter() ? filter.schoolID : this.currentUser.schoolID;

			let school = data.schools.filter(x => x.schoolID === schoolID)[0];
			if (school) {
				let teacher = school.teachers.filter(x => x.teacherID === teacherID)[0];
				if (teacher) {
					classes = teacher.classes.map(x => {
						return {id: x.classID, value: x.name};
					});
				}
			}
			classes.unshift({id: 0, value: 'All'});
			return classes;
		}));
	}

	public get specialistGroups$(): Observable<Field[]> {
		return combineLatest(this.filter$, this.dataService.data$).pipe(map(p => {
			const [filter, data] = p;
			let specialistID = filter.specialistUserID;
			let specialist = data.specialists.filter(x => x.userID === specialistID)[0];
			if (specialist) {
				let specialistGroups = specialist.groups!.map(x => {
					return {id: x.groupID, value: x.name, userID: specialistID};
				}).sort(function (a, b) {
					return ('' + a.value).localeCompare(b.value);
				});
				specialistGroups.unshift({id: 0, value: 'All', userID: 0});
				return specialistGroups;
			}
		}));
	}

	public get groups$(): Observable<Field[]> {
		return combineLatest(this.filter$, this.dataService.data$).pipe(map(p => {
			const [filter, data] = p;
			let classID = filter.classID;
			if (classID === 0) {
				return null;
			}

			let groups = [];
			let schoolID = this.fromFilter() ? filter.schoolID : this.currentUser.schoolID;
			let teacherID = filter.teacherID;

			let school = data.schools.filter(x => x.schoolID === schoolID)[0];
			if (school) {
				let teacher = school.teachers.filter(x => x.teacherID === teacherID)[0];
				if (teacher) {
					let _class = teacher.classes.filter(x => x.classID === classID)[0];
					if (_class) {
						groups = _class.groups.map(x => {
							return {id: x.groupID, value: x.name};
						});
					}
				}
			}
			groups.unshift({id: 0, value: 'All'});
			return groups;
		}));
	}

	public get teachers$(): Observable<Field[]> {
		return combineLatest(this.filter$, this.dataService.data$).pipe(map(p => {
			const[filter] = p;
			const schoolID = this.fromFilter() ? filter.schoolID : this.currentUser.schoolID;
			const teachersSource = this.getTeachersSource(schoolID);

			let teachers = teachersSource
				.filter(x => {
					if (!x.isExpired) {
						return true;
					}
					return this.studentsMap.has(x.teacherID);
				})
				.map(x => {
					return {id: x.teacherID, value: x.firstName + ' ' + x.lastName};
				});
			teachers.unshift({id: 0, value: 'All'});
			return teachers;
		}));
	}

	public get specialistTypes$(): Observable<Field[]> {
		return this.dataService.data$.pipe(map(data => {
			let specialistGroups = data.specialists.map(x => {
				const userType = UserType[x.userType];
				return {
					id: userType,
					value: userType === UserType.ISD || userType === UserType.PA ? 'District' : userType === UserType.ISS ? 'School' : '',
				};
			}).filter((thing, i, arr) => {
				return arr.indexOf(arr.find(t => t.id === thing.id)) === i;
			}).sort(function (a, b) {
				return ('' + a.value).localeCompare(b.value);
			});
			specialistGroups.unshift({id: 0, value: 'All'});
			return specialistGroups;
		}));
		return null;
	}

	public get specialists$(): Observable<Field[]> {
		return combineLatest(this.filter$, this.dataService.data$).pipe(map(p => {
			const [filter] = p;
			let specialists = this.specialists;
			if (filter!.specialistType) {
				specialists = specialists.filter(x => x.specialistType === filter!.specialistType || x.id === 0);
			}
			if (filter!.schoolID) {
				specialists = specialists.filter(x => x.schoolID === filter!.schoolID
					|| x.schoolID === null || x.schoolID === -1);
			}
			return specialists;
		}));
	}

	private get specialists() {
		const map = this.studentsMap;
		let specialists = this.dataService.data.specialists
			.filter(x => {
				if (!x.isExpired) {
					return true;
				}
				return map.has(x.userID);
			})
			.map(x => ({
				id: x.userID,
				value: x.name,
				schoolID: x.schoolID,
				specialistType: UserType[x.userType],
			})).reduce((accumalator, current) => {
				if (!accumalator.some(item => item.id === current.id && item.value === current.value)) {
					accumalator.push(current);
				}
				return accumalator;
			}, []).sort(function (a, b) {
				return ('' + a.value).localeCompare(b.value);
			});
		specialists.unshift({id: 0, value: 'All', schoolID: -1});
		return specialists;
	}

	private getTeachersSource(schoolID: number) {
		let teachersSource;
		if (schoolID === 0) {
			teachersSource = new Array<TeacherModel>();
			this.dataService.data.schools.forEach(x => {
				teachersSource = teachersSource.concat(x.teachers);
			});
			teachersSource.sort((a, b) => {
				if (a.lastName > b.lastName) {
					return 1;
				}
				if (a.lastName < b.lastName) {
					return -1;
				}
				if (a.firstName > b.firstName) {
					return 1;
				}
				if (a.firstName < b.firstName) {
					return -1;
				}
				return 0;
			});
		} else {
			const school = this.dataService.data.schools.filter(x => x.schoolID === schoolID)[0];
			teachersSource = school.teachers;
		}
		return teachersSource;
	}

	private fromFilter(){
		return this.currentUser.userType === UserType.D
			|| this.currentUser.userType === UserType.ISD
			|| this.currentUser.userType === UserType.PA;
	}
}
