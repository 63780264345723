import {useState} from 'react';
import {AddAssignmentService} from './service';
import {useService} from '@esgi/core/service';
import {Assignment} from '../../../types';
import {dispatchAppEvent} from '@esgillc/events';
import {AddAssignmentToList} from '../../../layout/events';
import {StudentSelectionPanel} from '../../components/student-selection-panel';
import {LeftPanelBody, LeftPanelHeader, LeftPanelRoot} from '../../components/left-panel.styled';
import {Text} from '@esgi/ui/typography';
import {SubjectTab} from '@esgi/main/libs/store';
import {CentralPanelBody, CentralPanelRoot} from '../../components/central-panel.styled';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';
import {AddTests, NewAssignment} from '@esgi/ui/icons';
import {AddTestDrawer} from '@esgi/main/features/teacher/drawers';
import {TestSelectionPanel} from '../../components/test-selection-panel';
import {RightPanelBody, RightPanelRoot} from '../../components/right-panel.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {AssignmentSettings} from '../../components/assignment-settings';
import {AssignmentSelectedStudents} from '../../components/assignment-selected-students';
import {AssignmentSelectedTest} from '../../components/assignment-selected-tests';
import {AlternativeOptionsSection} from './components/alternative-options-section';
import {Root} from '../index.styled';
import {useCommonAssignmentVariantState} from '../use-common-assignment-variant-state';
import {CommonAssignmentVariantProps} from '../types';
import {adaptedSubjectTypeWithContracts} from './constants';
import {AssignmentState} from '@esgi/main/kits/assignments';
import {CentralPanel, RightPanel, SkeletonShape} from '@esgi/main/kits/common';

export function AddAssignment({
	snackbarRef,
	onOpenStudentCredentials,
	onOpenCredentialsForGroupStudents,
}: CommonAssignmentVariantProps) {
	const [isAssignmentCreating, setIsAssignmentCreating] = useState(false);
	const [hasStudentsWithoutCredentials, setHasStudentsWithoutCredentials] = useState(false);
	const [selectedSubjectID, setSelectedSubjectID] = useState<SubjectTab['id']>(-1);
	const [showAddTestDrawer, setShowAddTestDrawer] = useState(false);

	const {
		isAssignmentSettingsValid,
		selectedStudentsIDs,
		selectedTestsIDs,
		onToggleSelectedStudentsIDs,
		onToggleSelectedTestsIDs,
		navigateToAssignmentsList,
		assignmentName,
		setAssignmentName,
		assignmentDescription,
		setAssignmentDescription,
		setIsAssignmentSettingsValid,
		onClearAllSelectedStudentsIDs,
		removeStudentIDFromState,
		onSwapTests,
		removeTestIDFromState,
		navigateToAssignment,
		isStoreDataLoaded,
		subjectsWithSelAssesTests,
		allSelfAsessTests,
		toggleEntityStudents,
	} = useCommonAssignmentVariantState();

	const service = useService(AddAssignmentService);

	const onCreateAssignment = (state: AssignmentState) => {
		setIsAssignmentCreating(true);

		let serviceState = state;

		if (!selectedTestsIDs.length || !selectedStudentsIDs.length) {
			serviceState = AssignmentState.Draft;
		}

		service
			.createAssignment({
				name: assignmentName,
				description: assignmentDescription,
				state: serviceState,
				studentIDs: selectedStudentsIDs,
				testIDs: selectedTestsIDs,
			})
			.subscribe({
				next: ({assignmentID}) => {
					setIsAssignmentCreating(false);

					const newAssignment: Assignment = {
						id: assignmentID,
						name: assignmentName,
						state: serviceState,
						posted: null,
						completed: null,
						created: new Date().toISOString(),
						tests: selectedTestsIDs.length,
						students: selectedStudentsIDs,
						testSessions: 0,
					};

					dispatchAppEvent(AddAssignmentToList, new AddAssignmentToList(newAssignment));

					navigateToAssignment({assignmentId: assignmentID});
				},
				error: () => {
					navigateToAssignmentsList();
				},
			});
	};

	const canCreateAssignmentAsDraft = isStoreDataLoaded && isAssignmentSettingsValid;

	const canCreateAssignment =
		canCreateAssignmentAsDraft &&
		Boolean(selectedStudentsIDs.length) &&
		!hasStudentsWithoutCredentials &&
		Boolean(selectedTestsIDs.length);

	return (
		<Root>
			<LeftPanelRoot dataCy='new-assignment-left-panel'>
				<LeftPanelHeader>
					{!isStoreDataLoaded ? (
						<SkeletonShape width={157} />
					) : (
						<Text data-cy='select-students-title' size='small' color='neutral56'>
							Select Students
						</Text>
					)}
				</LeftPanelHeader>
				<LeftPanelBody>
					<StudentSelectionPanel
						skeleton={!isStoreDataLoaded}
						isOpenFirstClassOnInit
						selectedStudentsIDs={selectedStudentsIDs}
						onStudentClick={onToggleSelectedStudentsIDs}
						toggleEntityStudents={toggleEntityStudents}
					/>
				</LeftPanelBody>
			</LeftPanelRoot>

			<CentralPanelRoot dataCy='new-assignment-central-panel'>
				<CentralPanel.Header>
					<FlexBox align='center' justify='between'>
						<Text data-cy='self-assess-title' size='small' color='neutral56'>
							Select Self-Assess Tests
						</Text>
						<FlexBox align='center'>
							<Button
								color='tertiary'
								disabled={selectedSubjectID === -1 || !isStoreDataLoaded}
								onClick={() => setShowAddTestDrawer(true)}
							>
								<AddTests width={32} height={32} />
								<Text data-cy='find-more' size='medium' color='neutral6'>
									Find More Self-Assess Tests
								</Text>
							</Button>
						</FlexBox>
					</FlexBox>
				</CentralPanel.Header>
				<CentralPanelBody>
					<TestSelectionPanel
						onTestClick={onToggleSelectedTestsIDs}
						selectedTestsIDs={selectedTestsIDs}
						skeleton={!isStoreDataLoaded}
						subjectsWithSelAssesTests={subjectsWithSelAssesTests}
						controlledSelectedSubjectID={selectedSubjectID}
						setControlledSelectedSubjectID={setSelectedSubjectID}
						allSelfAsessTests={allSelfAsessTests}
					/>
				</CentralPanelBody>
			</CentralPanelRoot>

			<RightPanelRoot dataCy='new-assignment-right-panel'>
				<RightPanel.Header
					sectionName='New Assignment'
					Icon={NewAssignment}
					withActionButton
					actionButtonText='Create'
					actionButtonDisabled={!canCreateAssignment || isAssignmentCreating}
					onActionButtonClick={() => onCreateAssignment(AssignmentState.NotStarted)}
					withCloseButton
					onClose={navigateToAssignmentsList}
				/>

				<RightPanelBody>
					<OverlayScrollbarsComponent
						defer
						style={{height: 'calc(100% + 0px)'}}
						options={{scrollbars: {autoHide: 'leave'}}}
					>
						<GridBox gap='5'>
							<AssignmentSettings
								assignmentName={assignmentName}
								onAssignmentNameChange={setAssignmentName}
								assignmentDescription={assignmentDescription}
								onAssignmentDescriptionChange={setAssignmentDescription}
								onValidValueChanged={setIsAssignmentSettingsValid}
								onValidateAssignmentName={service.validateAssignmentName.bind(service)}
								onValidateAssignmentDescription={service.validateAssignmentDescription.bind(service)}
								skeleton={!isStoreDataLoaded}
							/>

							<AssignmentSelectedStudents
								onClearAllClick={onClearAllSelectedStudentsIDs}
								onRemoveStudentByID={removeStudentIDFromState}
								selectedStudentsIDs={selectedStudentsIDs}
								onOpenCredentialsForGroupStudents={onOpenCredentialsForGroupStudents}
								onOpenStudentCredentials={onOpenStudentCredentials}
								onHasStudentsWithoutCredentialsChanged={setHasStudentsWithoutCredentials}
							/>

							<AssignmentSelectedTest
								allSelfAsessTests={allSelfAsessTests}
								selectedTestsIDs={selectedTestsIDs}
								onTestSwap={onSwapTests}
								onRemoveTestByID={removeTestIDFromState}
							/>
						</GridBox>
					</OverlayScrollbarsComponent>

					<AlternativeOptionsSection
						canCreateAsDraft={canCreateAssignmentAsDraft}
						canCreateAssignment={canCreateAssignment}
						hasStudentsWithoutCredentials={hasStudentsWithoutCredentials}
						onCreateAssignment={onCreateAssignment}
					/>
				</RightPanelBody>
			</RightPanelRoot>

			{showAddTestDrawer && subjectsWithSelAssesTests[selectedSubjectID] && (
				<AddTestDrawer
					onClose={() => setShowAddTestDrawer(false)}
					subject={{
						id: selectedSubjectID,
						level: subjectsWithSelAssesTests[selectedSubjectID]!.level,
						name: subjectsWithSelAssesTests[selectedSubjectID]!.name,
						type: adaptedSubjectTypeWithContracts[subjectsWithSelAssesTests[selectedSubjectID]!.type],
					}}
					snackbarRef={snackbarRef}
					selfAssess
				/>
			)}
		</Root>
	);
}
