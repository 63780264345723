import {createContext, useContext} from 'react';
import {noop} from 'underscore';
import {NavigationBarOrientation} from './types';

export type NavigationBarContextValue = {
	/** The orientation of the navigation bar */
	orientation: NavigationBarOrientation;

	/** Flag showing is navigation bar expanded or not */
	expanded: boolean;

	/** Toggle navigation bar expanded */
	setExpanded: (value: boolean) => void;
};

export const NavigationBarContext = createContext<NavigationBarContextValue>({
	orientation: NavigationBarOrientation.Vertical,
	expanded: false,
	setExpanded: noop,
});

export function useNavigationBarContext() {
	return useContext(NavigationBarContext);
}
