import React from 'react';
import {Alert} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

export interface AlertData {
	title: string;
	description: string;
	btnBlock: React.ReactNode
}


interface Props {
	data: AlertData
}

export function AssignmentAlert(props: Props) {
	const {
		data: {
			title,
			description,
			btnBlock,
		},
	} = props;
	return <Alert className={styles.alert}>
		<Alert.Header className={styles.header}>{title}</Alert.Header>
		<Alert.Body className={styles.body}><div>{description}</div></Alert.Body>
		<Alert.Footer>{btnBlock}</Alert.Footer>
	</Alert>;
}
