import React from 'react';
import {Radio} from '@esgillc/ui-kit/control';
import {BingoService} from 'shared/modules/bingo/service/service';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Print} from 'shared/modules/bingo/types/models';
import {join} from '@esgillc/ui-kit/utils';
import InputWithLabel from 'packages/app/src/shared/modules/bingo/components/input-with-label';
import {Button} from '@esgi/deprecated/elements/buttons';

import styles from './print.module.less';

interface PrintProps {
	service: BingoService;
	entityName: string;
	subjectName: string;
	testName: string;
	numberOfStudents: number;
	x3cardSize: boolean;
	twoCardsPerPage: boolean;
	questionsLoaded: boolean;
	print: Print;
	previewQuestionsClicked: () => void;
	downloadGame: () => void;
}

export default function Print ({
	entityName,
	subjectName,
	testName,
	service,
	numberOfStudents,
	x3cardSize,
	twoCardsPerPage,
	previewQuestionsClicked,
	downloadGame,
	questionsLoaded,
	print: {
		gamePlayedAtStudentHome,
		numberOfPlayersAtHome,
		cardsPerPerson,
		sheetsPerStudent,
		cardsPerStudent,
		sheetsPerClass,
	},
}: PrintProps) {

	const isValid = () => {
		if(gamePlayedAtStudentHome) {
			return cardsPerPerson && sheetsPerStudent && numberOfPlayersAtHome;
		} else {
			return cardsPerStudent && sheetsPerClass;
		}
	};

	const calculatePrintedPage = () => {
		if (gamePlayedAtStudentHome) {
			if (x3cardSize && twoCardsPerPage) {
				const coefficient = Math.ceil((cardsPerPerson * numberOfPlayersAtHome) / 2);
				return Math.ceil((numberOfStudents * coefficient) + numberOfStudents);
			} else {
				const result = numberOfStudents * (cardsPerPerson * numberOfPlayersAtHome) + numberOfStudents;
				return isNaN(result) ? 0 : result;
			}
		} else {
			if (x3cardSize && twoCardsPerPage) {
				return Math.ceil(numberOfStudents * (cardsPerStudent / 2)) + 1;
			} else {
				const result = numberOfStudents * cardsPerStudent + 1;
				return isNaN(result) ? 0 : result;
			}
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.topHeaderSub}>
				{entityName + ' | ' + subjectName + ' | ' + testName}
			</div>
			<div className={styles.topHeaderMain}>
				Set the number of call sheets and cards to print out
			</div>
			<div className={styles.content}>
				<div className={styles.left}>
					<div className={styles.cardPreview}/>
					<div className={styles.cardLegend}>Call sheet and Bingo card</div>
				</div>
				<div className={styles.right}>
					<div className={styles.panel}>
						<div className={styles.header}>
							<span>Where will the game be played?</span>
							<OnHoverTooltip message='At-Home offers the ability to create cards for other individuals playing at home.'>
								<i className='glyphicon glyphicon-question-sign tip'/>
							</OnHoverTooltip>
						</div>
						<div className={styles.radioBtn}>
							<Radio
								checked={!gamePlayedAtStudentHome}
								value={1}
								name='player-selector'
								id='player-selector-in-classroom'
								onChange={(e) => service.updatePrint('gamePlayedAtStudentHome', false)}
							>
								<span className={join(
									styles.label,
									!gamePlayedAtStudentHome && styles.checked,
								)}>
									In the classroom
								</span>
							</Radio>
							<Radio
								checked={gamePlayedAtStudentHome}
								value={2}
								name='player-selector'
								id='player-selector-at-student'
								onChange={(e) => service.updatePrint('gamePlayedAtStudentHome', true)}
							>
								<span className={join(
									styles.label,
									gamePlayedAtStudentHome && styles.checked,
								)}>
									At student’s home
								</span>
							</Radio>
						</div>
						{gamePlayedAtStudentHome
							&& <>
								<InputWithLabel
									isBig={true}
									label='How many people will play at home?'
									value={numberOfPlayersAtHome}
									onClick={(value) => service.updatePrint('numberOfPlayersAtHome', value)}
								/>
								<InputWithLabel
									label='Unique card(s) per person:'
									value={cardsPerPerson}
									onClick={(value) => service.updatePrint('cardsPerPerson', value)}
								/>
								<InputWithLabel
									label='Unique call sheet(s) per student:'
									value={sheetsPerStudent}
									onClick={(value) => service.updatePrint('sheetsPerStudent', value)}
								/>
							</>
						}
						{!gamePlayedAtStudentHome
							&& <>
								<div className={styles.labelNumber}>
									How many times do you want to play?
								</div>
								<InputWithLabel
									label='Unique card(s) per student:'
									value={cardsPerStudent}
									onClick={(value) => service.updatePrint('cardsPerStudent', value)}
								/>
								<InputWithLabel
									label='Unique call sheet(s) per class:'
									value={sheetsPerClass}
									onClick={(value) => service.updatePrint('sheetsPerClass', value)}
								/>
							</>
						}
						<div className={styles.totalPagesPrinted}>
                            <span>
                                Total students ({numberOfStudents}) Total pages printed: {calculatePrintedPage()}
                            </span>
						</div>
					</div>
					<div className={styles.buttons}>
						<Button
							onClick={() => previewQuestionsClicked()}
							title='PREVIEW QUESTIONS'
							disabled={!questionsLoaded}
							className={'btn-primary btn-transparent'}
						/>
						<Button
							onClick={downloadGame}
							title='DOWNLOAD GAME'
							className={'btn-primary'}
							disabled={!questionsLoaded || !isValid()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
