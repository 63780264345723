import React from 'react';
import {Body, IApi as BodyApi, Props as BodyProps, State as BodyState} from './body';
import {
  DeleteYNNoteRequest,
  ResultRequest,
  ResultYnResponse,
  SaveYNNoteRequest,
  TestSessionAnswer,
  UpdateResponse,
  UpdateYNRequest,
} from '../../api/models';
import {AnswerState, TestType} from '@esgi/core/enums';
import {Models, MoveDirection} from '../../models';
import {AnswerPanel, State as AnswerPanelState} from '../../components/answer-panel';
import Enumerable from 'linq';
import {QuestionMover} from '../../components/question-mover';
import {YNEvents} from './events';
import {Observable, of} from 'rxjs';
import {TestSessionDetailsEvents} from 'shared/modules/test/test-session-details/events';
import {TextArea} from '@esgillc/ui-kit/control';

export interface IApi extends BodyApi {
	ynLoad: (request: ResultRequest) => Observable<ResultYnResponse>;
  saveNote: (request: SaveYNNoteRequest) => Observable<any>;
	deleteNote: (request: DeleteYNNoteRequest) => Observable<any>;
  updateYNTestSession: (request: UpdateYNRequest) => Observable<UpdateResponse>;
}

export class Props extends BodyProps<State, IApi> {
	testSubsetHandler: () => void;
	canSubset: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
}

export class State extends BodyState {
	activeAnswerPanel: number;
  summaryNote?: string;
	correctPanel: AnswerPanelState;
	incorrectPanel: AnswerPanelState;
	notTestedPanel: AnswerPanelState;
}

export class YN extends Body<IApi, State, Props> {
	private notTestedVerbiage: string = 'Not Tested';

	constructor(props?: Props) {
		super(props);
	}

	componentDidMount(): void {
		super.componentDidMount();

		this.subscribe(TestSessionDetailsEvents.TestSessionUpdate, () => {
			super.updateTestSession();
		});
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, prevContext: any): void {
		super.componentDidUpdate(prevProps, prevState, prevContext);

		if (!this.state.activeAnswerPanel || this.props.editMode == prevProps.editMode) {
			return;
		}

		this.setActiveAnswerPanel();
	}

	get testType(): TestType {
		return TestType.YN;
	}

	protected getAnswers(answersRequest: ResultRequest) {
		this.loader.mask();
		this.props.api.ynLoad(answersRequest)
			.subscribe(resp => {
        this.setState({summaryNote: resp.summaryNote || ''});
				this.setAnswers(Models.Answer.FromResponseArray(resp.answers,
					this.props.questions,
					this.props.testSessions));
			}, () => {
				this.loader.unmask();
			}, () => {
				this.loader.unmask();
			});
	}

	protected setAnswers(answers?: Models.Answer[]) {
		this.setState({
			answers: answers ?? this.props.questions.map(q => Models.Answer.Empty(q)),
		});
	}

	private setActiveAnswerPanel(panelState?: AnswerState) {
		if (panelState === this.state.activeAnswerPanel) {
			return;
		}

		this.dispatch(YNEvents.ChangeActivePanel, YNEvents.ChangeActivePanel(panelState));

		this.setState({activeAnswerPanel: panelState});
	}

	private moverClick(targetMove: AnswerState) {
		let answers = this.state.answers;
		let states = (this.state.activeAnswerPanel == AnswerState.Correct)
			? this.state.correctPanel.selectedAnswers
			: (this.state.activeAnswerPanel == AnswerState.Incorrect)
				? this.state.incorrectPanel.selectedAnswers
				: this.state.notTestedPanel.selectedAnswers;
		let dirty = false;

		for (let i = 0; i < answers.length; i++) {
			let stateIndx = states.indexOf(answers[i].questionId);
			if (stateIndx == -1) {
				continue;
			}

			answers[i].answerState = targetMove;
			dirty = true;
		}

		if (dirty) {
			this.setAnswers(answers);
			this.setState({dirty: true});

			this.setActiveAnswerPanel();
		}
	}

	private getAnswersByState(state: AnswerState): Models.Answer[] {
		return (this.state.answers)
			? Enumerable.from(this.state.answers)
				.where(a => a.answerState === state).toArray()
			: [];
	}

	private renderFirstMover() {
		if (!this.state || this.state.activeAnswerPanel === undefined) {
			return null;
		}

		return (this.state.activeAnswerPanel == AnswerState.Correct)
			? <QuestionMover
				direction={MoveDirection.Right}
				title={'Move to ' + this.props.testResultsIncorrectVerbiage}
				click={() => this.moverClick(AnswerState.Incorrect)}
				editMode={this.props.editMode} />
			: <QuestionMover
				direction={MoveDirection.Left}
				title={'Move to ' + this.props.testResultsCorrectVerbiage}
				click={() => this.moverClick(AnswerState.Correct)}
				editMode={this.props.editMode} />;
	}

	private renderSecondMover() {
		if (!this.state || this.state.activeAnswerPanel === undefined) {
			return null;
		}

		return (this.state.activeAnswerPanel == AnswerState.NotTested)
			? <QuestionMover
				direction={MoveDirection.Left}
				title={'Move to ' + this.props.testResultsIncorrectVerbiage}
				click={() => this.moverClick(AnswerState.Incorrect)}
				editMode={this.props.editMode} />
			: <QuestionMover
				direction={MoveDirection.Right}
				title={'Move to ' + this.notTestedVerbiage}
				click={() => this.moverClick(AnswerState.NotTested)}
				editMode={this.props.editMode} />;
	}

	private saveNoteClicked = (testSessionId: number, questionID: number, comment: string) => {
		const answers = this.state.answers.map(a => a);
		const ynRequest = {
			testSessionID: testSessionId,
			questionID: questionID,
			comment: comment,
		};

		this.props.api.saveNote(ynRequest)
			.subscribe();

		const answer = answers.find(a => a.questionId == questionID);
		answer.comment = comment;

		this.setAnswers(answers);
	};

	private deleteNoteClicked = (testSessionId: number, questionID: number) => {
		const answers = [...this.state.answers];
		const ynRequest: DeleteYNNoteRequest = {
			testSessionID: testSessionId,
			questionID: questionID,
		};

		this.props.api.deleteNote(ynRequest)
			.subscribe();

		const answer = answers.find(a => a.questionId === questionID);
		answer.comment = null;

		this.setAnswers(answers);
	};

	protected renderEditButtons() {
		if (!this.props.editMode) {
			return <>
				{(this.props.canChangeSession)
					? <a href='#' className='add-test-session-link' onClick={() => this.addTestSessionClicked()}>
						<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM22.5 16.5H16.5V22.5H13.5V16.5H7.5V13.5H13.5V7.5H16.5V13.5H22.5V16.5Z' fill='#059BE0' />
						</svg>
						<span>Add Test Session</span>
					</a>
					: (this.currentTestSession && !this.currentTestSession.deleted && !this.currentTestSession.isEmpty)
						? <a href='#' className='delete-test-session-link' onClick={() => this.deleteTestSession()}>Delete test session</a>
						: null}
				{this.props.canSubset &&
					<><span>|</span>
						<a href='#' className='run-subset-link' onClick={this.props.testSubsetHandler}><span>Run Subset</span></a></>
				}
			</>;
		} else {
			return (this.currentTestSession && !this.currentTestSession.deleted && !this.currentTestSession.isEmpty)
				? <a href='#' className='delete-test-session-link' onClick={() => this.deleteTestSession()}>Delete test session</a>
				: null;
		}
	}

	protected renderCorrectAnswersHeader() {
		return <>
			{(this.currentTestSession && !this.currentTestSession.isEmpty) &&
				<div className='correctAnswersHeader'>
					{this.props.testResultsCorrectVerbiage} Answers: {this.getAnswersByState(AnswerState.Correct).length.toString()}/{this.props.questions.length.toString()}
				</div>
			}
		</>;
	}

  private summaryNotesOnChange(event) {
    const value = event.target.value.replace(/[\r\n\v]+/g, '');
    this.setState({summaryNote: value});
  }

	protected renderAnswersBlock() {
		return <>
			<div className={this.props.readOnly ? 'question-container full' : 'question-container'}>
			<AnswerPanel
				state={this.state.correctPanel}
				onChange={(changes, c) => {
					this.setState({correctPanel: changes}, c);
				}}
				editMode={this.props.editMode}
				testSession={this.currentTestSession}
				answerState={AnswerState.Correct}
				answers={this.getAnswersByState(AnswerState.Correct)}
				title={this.props.testResultsCorrectVerbiage}
				setActive={(panelState?: AnswerState) => this.setActiveAnswerPanel(panelState)}
				saveNoteHandler={this.saveNoteClicked}
				deleteNoteHandler={this.deleteNoteClicked}
				studentName={this.props.studentName}
				testName={this.props.testName}
				readOnly={!this.props.canChangeSession}
			/>

			{this.renderFirstMover()}

			<AnswerPanel
				state={this.state.incorrectPanel}
				onChange={(changes, c) => {
					this.setState({incorrectPanel: changes}, c);
				}}
				editMode={this.props.editMode}
				testSession={this.currentTestSession}
				answerState={AnswerState.Incorrect}
				answers={this.getAnswersByState(AnswerState.Incorrect)}
				title={this.props.testResultsIncorrectVerbiage}
				setActive={(panelState?: AnswerState) => this.setActiveAnswerPanel(panelState)}
				saveNoteHandler={this.saveNoteClicked}
				deleteNoteHandler={this.deleteNoteClicked}
				studentName={this.props.studentName}
				testName={this.props.testName}
				readOnly={!this.props.canChangeSession} />

			{this.renderSecondMover()}

			<AnswerPanel
				state={this.state.notTestedPanel}
				onChange={(changes, c) => {
					this.setState({notTestedPanel: changes}, c);
				}}
				editMode={this.props.editMode}
				testSession={this.currentTestSession}
				answerState={AnswerState.NotTested}
				answers={this.getAnswersByState(AnswerState.NotTested)}
				title={this.notTestedVerbiage}
				setActive={(panelState?: AnswerState) => this.setActiveAnswerPanel(panelState)}
				saveNoteHandler={this.saveNoteClicked}
				deleteNoteHandler={this.deleteNoteClicked}
				studentName={this.props.studentName}
				testName={this.props.testName}
				readOnly={!this.props.canChangeSession} />
			</div>

      {!this.props.readOnly &&
        <div className='summary-notes-container'>
          <TextArea value={this.state.summaryNote}
                    maxLength={255}
                    disabled={!this.props.editMode || !this.props.canChangeSession}
                    rows={3}
                    placeholder='Summary: Enter session summary (optional)'
                    onChange={(e) => this.summaryNotesOnChange(e)}
          />
        </div>
      }
		</>;
	}

	get tsas(): TestSessionAnswer[] {
		return this.state.answers.map((a) => {
			return {
				answerState: a.answerState,
				questionID: a.questionId,
			};
		});
	}

	protected updateAction(): Observable<UpdateResponse> {
		const request: UpdateYNRequest = {
			testSessionID: (this.currentTestSession.id > 0) ? this.currentTestSession.id : 0,
			testDate: this.currentTestSession.testDateIso,
			testDateTouched: this.testDateTouched,
			testID: this.props.testId,
			listTsa: this.tsas,
      testType: TestType.YN,
			studentID: this.props.studentID,
      notes: this.state.summaryNote || null,
		};

		return this.props.api.updateYNTestSession(request);
	}
}
