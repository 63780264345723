import './styles.less';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import moment from 'moment';
import React, {createRef, RefObject} from 'react';
import {OldAlerts} from '@esgi/deprecated/knockout';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {Loader} from '@esgi/deprecated/jquery';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {Hierarchy} from 'modules/hierarchy/hierarchy';
import {HierarchyModalHandler} from 'modules/hierarchy/hierarchy-modal-handler';
import {HierarchyDataService} from 'modules/hierarchy/services/hierarchy-data-service';
import {CenterPanel} from 'shared/page/layout/center-panel';
import Layout from 'shared/page/layout/layout';
import {SidePanel} from 'shared/page/layout/side-panel/side-panel';
import {ErrorBoundary} from '@esgi/core/react';
import {
	ConferencerDate,
	ConferencerItem,
	ConferencerTime,
	DataModel,
	Student,
} from 'pages/parent-conferencer/models/models';
import {EditItem, InitialState as ItemInitialState} from 'pages/parent-conferencer/components/edit-item';
import {EditTime, InitialState as TimeInitialState} from 'pages/parent-conferencer/components/edit-time';
import {EditDate, InitialState as DateInitialState} from 'pages/parent-conferencer/components/edit-date';
import {Header} from 'pages/parent-conferencer/components/header';
import {Table} from 'pages/parent-conferencer/components/table';
import {Footer} from 'shared/page/footer/footer';
import {AlreadyAssignedToTimeSlotAlert} from './components/already-assigned-to-time-slot-alert';
import {DataService} from './data-service';

export class State {
	hierarchy: HierarchyInstance;

	editDate: DateInitialState = null;
	editTime: TimeInitialState = null;
	editItem: ItemInitialState = null;

	data: DataModel = null;
	filteredStudents: Student[] = null;
	dragStarted: boolean = false;

	IsClearPending: boolean = false;
	showAlreadyAssignedToTimeSlotAlert: boolean = false;
}

export default class ParentConferencerPage extends React.Component<{excludeLayout?: boolean}, State> {
	public override readonly state = new State();

	private defaultLanguageID: number = 2;
	private loader: Loader = null;
	private hierarchyServiceRef: RefObject<HierarchyDataService> = createRef();
	private dataService = new DataService(this.hierarchyServiceRef);

	public componentDidMount(): void {
		this.loader = new Loader($('.main'));
	}

	public componentWillUnmount() {
		this.dataService.destroy();
	}

	private init() {
		this.dataService.data$.subscribe(data => this.setState({data}));
	}

	private recalcCoordinate(coord, modalOffsetByCoord, totalOffset) {
		let modalAnotherCoord = coord + modalOffsetByCoord;
		if (modalAnotherCoord > totalOffset) {
			return modalAnotherCoord - totalOffset;
		}
		return 0;
	}

	private openEditDateModal = (date: ConferencerDate, top: number, left: number) => {
		let defaultDate = null;
		if (date == null) {
			defaultDate = this.getDefaultDate();
		}
		const initialState = {data: date, defaultDate};
		this.setState({editDate: initialState}, () => {
			left -= this.adjustEditDateModal(left);
			$('.edit_datetime_modal .modal-dialog').css('top', top + 'px').css('left', left + 'px');
		});
	};

	private getDefaultDate = () => {
		let dates = this.state.data.conferencerDates;
		if (dates.length === 0) {
			return;
		}

		return moment(dates[dates.length - 1].date).add(1, 'd').toDate();
	};

	private adjustEditDateModal(left) {
		let modalWidth = $('.edit_datetime_modal .modal-dialog').width();

		let totalWidth = window.innerWidth
			|| document.documentElement.clientWidth
			|| document.body.clientWidth;

		return this.recalcCoordinate(left, modalWidth, totalWidth);
	}

	private processEditDateModal = (data: ConferencerDate) => {
		const selectedDateId = data.conferencerDateID;

		if (this.state.data.conferencerDates.some(x => x.date === data.date && (selectedDateId === 0 || x.conferencerDateID !== selectedDateId))) {
			return 'This date already exists. Please choose a unique date.';
		}

		this.dataService.saveDate(data).subscribe(() => this.setState({IsClearPending: false}));
	};

	openEditTimeModal = (time: ConferencerTime, top: number, left: number) => {
		let defaultTime = null;
		if (time == null) {
			defaultTime = this.getDefaultTime();
		}

		const initialState = {time, defaultTime};

		this.setState({editTime: initialState}, () => {
			top -= this.adjustEditTimeModal(top);
			$('.edit_datetime_modal .modal-dialog').css('top', top + 'px').css('left', left + 'px');
		});
	};

	private getDefaultTime = () => {
		let times = this.state.data.conferencerTimes;
		if (times.length === 0) {
			return;
		}

		let time = times[times.length - 1].time;
		let confLength = this.state.data.defaultConferenceLength;
		if (this.state.data.conferencerItems.length > 0) {
			let dates = this.state.data.conferencerDates;
			let items = this.state.data.conferencerItems.sort((a: ConferencerItem, b: ConferencerItem) => {
				let aTime = moment(times.filter(x => x.conferencerTimeID === a.conferencerTimeID)[0].time);
				let at = aTime.hours() * 60 + aTime.minutes();
				let bTime = moment(times.filter(x => x.conferencerTimeID === b.conferencerTimeID)[0].time);
				let bt = bTime.hours() * 60 + bTime.minutes();

				if (at > bt) {
					return -1;
				}
				if (at < bt) {
					return 1;
				}

				let ad = dates.filter(x => x.conferencerDateID === a.conferencerDateID)[0].date;
				let bd = dates.filter(x => x.conferencerDateID === b.conferencerDateID)[0].date;

				if (ad > bd) {
					return 1;
				}
				if (ad < bd) {
					return -1;
				}

				return 0;
			});
			confLength = items[0].lengthMin;
		}

		return moment(time).add(confLength, 'm').toDate();
	};

	private adjustEditTimeModal(top) {
		let modalHeight = $('.edit_datetime_modal .modal-dialog').height();

		let totalHeight = window.innerHeight
			|| document.documentElement.clientHeight
			|| document.body.clientHeight;

		return this.recalcCoordinate(top, modalHeight, totalHeight);
	}

	private processEditTimeModal = (data: ConferencerTime) => {
		let selectedTimeId = data.conferencerTimeID;
		let timeStr = moment(data.time).format('HH/mm');
		if (this.state.data.conferencerTimes
			.some(x => moment(x.time).format('HH/mm') === timeStr
				&& (selectedTimeId === 0 || x.conferencerTimeID !== selectedTimeId))) {
			return 'This time has already been selected.';
		}

		let timeStrToSave = moment(data.time).utcOffset(0, true).format();
		timeStrToSave = timeStrToSave.substring(0, timeStrToSave.length - 1);

		let time: ConferencerTime = {
			conferencerTimeID: selectedTimeId,
			time: timeStrToSave,
		};

		this.dataService.saveTime(time).subscribe(() => this.setState({IsClearPending: false}));
	};

	private openEditItemModal = (item: ConferencerItem, cDateID: number, cTimeID: number) => {
		let time = this.state.data.conferencerTimes.filter(x => x.conferencerTimeID === cTimeID)[0].time;
		const initialState = {
			data: item,
			dateID: cDateID,
			timeID: cTimeID,
			time,
			defaultLengthMin: this.state.data.defaultConferenceLength,
			defaultLanguageID: this.defaultLanguageID,
			defaultRoomName: this.state.data.defaultConferenceRoom,
		};
		this.setState({
			editItem: initialState,
		});
	};

	processEditItemModal = (data: ConferencerItem) => {
		this.saveItem(data);
		return '';
	};

	onStudentDragStart = (event, id?: number) => {
		event.dataTransfer.setData('StudentID', id);
		this.setState({dragStarted: true});
	};

	onStudentDragEnd = (event, id?: number) => {
		this.setState({dragStarted: false});
	};

	processDropItem = (event, item: ConferencerItem, cDateID: number, cTimeID: number) => {
		this.setState({dragStarted: false});

		let studentID = event.dataTransfer.getData('StudentID');

		if (!studentID) {
			return;
		}

		let itemToSave = this.getItemToSave(item, cDateID, cTimeID, +studentID);
		this.saveItem(itemToSave);
	};

	getItemToSave(item: ConferencerItem, cDateID: number, cTimeID: number, studentID?: number): ConferencerItem {
		let student: Student = null;
		if (studentID > 0) {
			student = this.state.data.students.filter(x => x.studentID === studentID)[0];
		}

		let partialItem: Partial<ConferencerItem>;
		if (item == null) {
			partialItem = {
				conferencerItemID: 0,
				lengthMin: this.state.data.defaultConferenceLength,
				languageID: student && (student.languageID === 1 || student.languageID === 3) ? student.languageID : this.defaultLanguageID,
				roomName: this.state.data.defaultConferenceRoom,
				isActual: true,
			};
		}
		item = {...partialItem, ...item};
		item.conferencerDateID = cDateID;
		item.conferencerTimeID = cTimeID;
		item.studentID = studentID;
		let studentName = '';
		if (student) {
			studentName = student.firstName + ' ' + student.lastName;
		}
		item.studentName = studentName;

		return item;
	}

	get scheduledStudents() {
		if (!this.state.data) {
			return [];
		}
		let studentIDs = this.state.data.conferencerItems.map(x => x.studentID);
		return studentIDs.filter((id, i) => studentIDs.indexOf(id) === i);
	}

	private printSchedule = () => {
		if (this.state.data.conferencerItems.filter(x => !x.isActual).length > 0) {
			this.showAlertNotActualStudents(() => this.dataService.downloadSchedulerPDF());
		} else {
			this.dataService.downloadSchedulerPDF();
		}
	};

	private printLetters = (type: number, studentID: number) => {
		if (this.state.data.conferencerItems.filter(x => !x.isActual).length > 0) {
			this.showAlertNotActualStudents(() => this.dataService.downloadParentLetterPDF(type, studentID));
		} else {
			this.dataService.downloadParentLetterPDF(type, studentID);
		}
	};

	private clearSchedule = () => {
		(this.state.IsClearPending ? this.dataService.restoreSchedule() : this.dataService.clearSchedule())
			.subscribe(() => this.setState({IsClearPending: !this.state.IsClearPending}));
	};

	private showAlertNotActualStudents(onclick) {
		OldAlerts.bsalert({
			message: `The conference schedule contains ${this.state.data.conferencerItems.filter(x => !x.isActual).length} students from a previous school year. Please make adjustments to the schedule if necessary.`,
			modal:
				{
					allowClose: false,
					buttons: [
						{
							align: 'left',
							title: 'BACK',
							className: 'btn btn-sm btn-link',
							closeModal: true,
						},
						{
							align: 'right',
							title: 'CONTINUE',
							className: 'btn btn-sm btn-link',
							closeModal: true,
							onClick: () => {
								onclick();
							},
						},
					],
				},
		});
	}

	public render(): JSX.Element | false | null {
		return <>
			<Layout
				redesign={this.props.excludeLayout}
				loaded={[this.state.data != null]}>
				<SidePanel collapsable>
					<Hierarchy onHierarchyChanged={(c) => this.setState({hierarchy: c})}
					           hierarchyServiceRef={this.hierarchyServiceRef}
					           onStudentDragStart={this.onStudentDragStart}
					           onStudentDragEnd={this.onStudentDragEnd}
					           scheduledStudentIDs={this.scheduledStudents}
					           onReady={() => this.init()}
					/>
					<HierarchyModalHandler hierarchyState={this.state.hierarchy}
					                       schoolID={this.state.hierarchy && this.state.hierarchy.classic.schoolID}
					                       schools={this.hierarchyServiceRef.current?.hierarchyState$.value.schools}/>
				</SidePanel>
				<CenterPanel>
					{this.state.data &&
						<div className='parent-conferencer-page'>
							<ErrorBoundary fillSpace={true}>
								<Header
									clearClicked={this.state.IsClearPending}
									clearSchedule={this.clearSchedule}
									conferencerItems={this.state.data.conferencerItems}
									students={this.state.data.students}
									letterTypes={this.state.data.letterTypes}
									languages={this.state.data.languages}
									teacherName={this.state.data.teacherName}
									schoolName={this.state.data.schoolName}
									printSchedule={this.printSchedule}
									printLetters={this.printLetters}
								/>
							</ErrorBoundary>
							<ErrorBoundary fillSpace={true}>
								<Table
									dates={this.state.data.conferencerDates}
									times={this.state.data.conferencerTimes}
									items={this.state.data.conferencerItems}
									openEditDateModal={this.openEditDateModal}
									openEditTimeModal={this.openEditTimeModal}
									openEditItemModal={this.openEditItemModal}
									processDropItem={this.processDropItem}
									processDragOver={this.state.dragStarted}
									delete={this.dataService.removeEditItem}
								/>
							</ErrorBoundary>
						</div>
					}
					<Footer/>
				</CenterPanel>
			</Layout>
			{this.state.data && <ServiceLoader trackingService={this.dataService} fullscreen/>}
			{this.state.editDate &&
				<EditDate
					initialState={this.state.editDate}
					close={() => this.setState({editDate: null})}
					process={this.processEditDateModal}
					delete={this.dataService.removeEditDate}
				/>
			}
			{this.state.editTime &&
				<EditTime
					initialState={this.state.editTime}
					close={() => this.setState({editTime: null})}
					process={this.processEditTimeModal}
					delete={this.dataService.removeEditTime}
				/>
			}
			{this.state.editItem && (!this.state.filteredStudents || this.state.filteredStudents.length > 0) &&
				<EditItem
					initialState={this.state.editItem}
					languages={this.state.data.languages}
					students={this.state.filteredStudents ? this.state.filteredStudents : this.state.data.students}
					close={() => this.setState({editItem: null})}
					process={this.processEditItemModal}
					printLetters={this.printLetters}
				/>
			}
			<BackgroundDownloadManager/>
			{this.state.showAlreadyAssignedToTimeSlotAlert && <AlreadyAssignedToTimeSlotAlert
				close={() => this.setState({showAlreadyAssignedToTimeSlotAlert: false})}/>}
		</>;
	}

	private saveItem(itemToSave: ConferencerItem) {
		if (this.state.data.conferencerItems.some(x => x.conferencerItemID !== itemToSave.conferencerItemID && x.studentID === itemToSave.studentID)) {
			this.setState({showAlreadyAssignedToTimeSlotAlert: true});
		} else {
			this.dataService.saveItem(itemToSave).subscribe(() => this.setState({IsClearPending: false}));
		}
	}
}
