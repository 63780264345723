import React, {ChangeEvent, useEffect, useMemo} from 'react';
import {Input} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {RightPanel} from '@esgi/main/kits/common';
import {useBehaviorSubject} from '@esgi/ui';
import {Activities} from '@esgi/main/kits/activities';
import {Box, FlexBox, GridBox, InfoBlock} from '@esgi/ui/layout';
import {useBingoService} from '../../hooks';
import {BingoCardSize, BingoPlace, Difficulty} from '../../types';
import {BingoTestSettings, getCardSizeItemList, difficultyItemList, testLocationItemList, minQuestionsCountForMediumCardSize} from '../../model';
import {validateNumberValue} from './model';
import {StyledBodyContainer, StyledSideBar} from './index.styled';

export function BingoPanel() {
	const service = useBingoService();
	const selectedTests = useBehaviorSubject(service.selectedTests$);
	const selectedStudents = useBehaviorSubject(service.selectedStudents$);
	const testSettings = useBehaviorSubject(service.testSettingsServiceData.testSettings$);
	const isBusy = useBehaviorSubject(service.downloadServiceData.isBusy$);

	const isBigCardSizeAvailable = useMemo(() => {
		if (selectedTests.length === 0) {
			return false;
		}

		return selectedTests.every((item) => (
			item.questionsCount >= minQuestionsCountForMediumCardSize
		));
	}, [selectedTests]);

	const isActive = useMemo(() => {
		return (selectedTests.length > 0 && selectedStudents.length > 0);
	}, [selectedTests, selectedStudents]);

	const cardSizeItemList = useMemo(() => (
		getCardSizeItemList(isBigCardSizeAvailable)
	), [isBigCardSizeAvailable]);

	const pageCount = useMemo(() => {
		const testsCount = selectedTests.length;
		const studentsCount = selectedStudents.length;
		const peopleAtHomeCount = Number(testSettings.peopleAtHomeCount);
		const studentCardsCount = studentsCount * Number(testSettings.cardsCount);

		if (testSettings.place === BingoPlace.Home) {
			return (studentCardsCount * peopleAtHomeCount + studentsCount) * testsCount;
		}

		return (studentCardsCount + 1) * testsCount;
	}, [selectedTests.length, selectedStudents.length, testSettings.peopleAtHomeCount, testSettings.cardsCount, testSettings.place]);

	const pageLimitError = pageCount >= 1000;

	const handleChangeSettings = (field: keyof BingoTestSettings) => (value: string) => {
		service.setTestSettingsData(field, Number(value));
	};

	const handleInputNumber = (field: keyof BingoTestSettings) => (event: ChangeEvent<HTMLInputElement>) => {
		service.setTestSettingsData(field, validateNumberValue(event.target.value));
	};

	/*
	 Guard if user picked 5x5 card size,
	 and then picked a test with less than 25 (minQuestionsCountForMediumCardSize) questions
	 */
	useEffect(() => {
		if (isBigCardSizeAvailable) {
			return;
		}

		service.setTestSettingsData('cardSize', BingoCardSize.Small);
	}, [service, isBigCardSizeAvailable]);

	return (
		<StyledSideBar dataCy='bingo-panel'>
			<Activities.DownloadPanel
				downloadTitle='Download'
				disabledPreviewTooltip={ isBusy ? '' : 'Please select a test/student to preview'}
				activePreviewTooltip='Click to preview bingo game'
				disabledDownloadTooltip='Please select a test/student to download'
				disabled={!isActive || isBusy || pageLimitError}
				onPreview={() => service.createGame(true)}
				onDownload={() => service.createGame()}
			/>
			<RightPanel.Body>
				<StyledBodyContainer>
					<RightPanel.ContentBlock dataCy='difficulty-block' title='Difficulty'>
						<Activities.ToggleGroup<Difficulty>
							itemList={difficultyItemList}
							selectedItem={testSettings.difficulty}
							onToggleItem={handleChangeSettings('difficulty')}
						/>
					</RightPanel.ContentBlock>
					<RightPanel.ContentBlock dataCy='card-size-block' title='Card Size'>
						<Activities.ToggleGroup<BingoCardSize>
							itemList={cardSizeItemList}
							selectedItem={testSettings.cardSize}
							onToggleItem={handleChangeSettings('cardSize')}
						/>
					</RightPanel.ContentBlock>
					<RightPanel.ContentBlock dataCy='place-block' title='Where will the game be played?'>
						<Activities.ToggleGroup<BingoPlace>
							itemList={testLocationItemList}
							selectedItem={testSettings.place}
							onToggleItem={handleChangeSettings('place')}
						/>
					</RightPanel.ContentBlock>
					<RightPanel.ContentBlock dataCy='games-amount-block' title='How many games will be played?'>
						<GridBox flow='row' gap={3}>
							{testSettings.place === BingoPlace.Home && (
								<Input
									data-cy='people-at-home-input'
									type='number'
									placeholder='How many people will play at home'
									value={testSettings.peopleAtHomeCount}
									onInput={handleInputNumber('peopleAtHomeCount')}
								/>
							)}
							<GridBox columns={2} gap={3}>
								<Input
									data-cy='cards-per-student-input'
									type='number'
									placeholder='Cards per student'
									value={testSettings.cardsCount}
									onInput={handleInputNumber('cardsCount')}
								/>
								<Input
									data-cy='call-sheets-per-class-input'
									type='number'
									placeholder='Сall sheets per class'
									value={testSettings.sheetsCount}
									onInput={handleInputNumber('sheetsCount')}
								/>
							</GridBox>
						</GridBox>
					</RightPanel.ContentBlock>
					<FlexBox direction='column' justify='end'>
						<RightPanel.ContentBlock dataCy='output-block' title='Output'>
							<InfoBlock>
								<InfoBlock.Item data-cy='pages-to-print-title' title='Pages to print:'>
									<Box>
										<Text
											color={pageLimitError ? 'negativeVivid' : 'neutral6'}
											data-cy='pages-to-print-number'
											size='small'
										>
											{pageCount}
										</Text>
									</Box>
								</InfoBlock.Item>
							</InfoBlock>
						</RightPanel.ContentBlock>
					</FlexBox>
				</StyledBodyContainer>
			</RightPanel.Body>
		</StyledSideBar>
	);
}