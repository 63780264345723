import {FlashCardsWrapper} from 'shared/modules/reports/flash-cards/settings-form/wrapper';
import React, {Fragment, Suspense, useEffect, useState} from 'react';
import {TestType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {ChangesCollector, notifyHierarchyChanges} from 'shared/modules/reports/utils/changes-collector';
import {TestLauncher, RubricSessionDetails} from 'modules/assessments';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {TestRunOptions} from '../models';

const TestHistoryModal = lazyComponent(() => import('modules/reports/test-history'));
const TestSessionDetails = lazyComponent(() => import('shared/modules/test/test-session-details/test-session-details'));

interface Props {
	hierarchy: HierarchySnapshot;
	runOptions: TestRunOptions;
}

export function TestLauncherWrapper(props: Props) {
	const [flashCardsOpened, setFlashCardsOpened] = useState(false);
	const [testLauncherOpened, setTestLauncherOpened] = useState(false);
	const [testSessionDetailOpened, setTestSessionDetailOpened] = useState(false);
	const [testHistoryOpened, setTestHistoryOpened] = useState(false);

	const [changesCollector, setChangesCollector] = useState<ChangesCollector>();

	useEffect(() => {
		if (props.runOptions) {
			setTestLauncherOpened(true);
		}
	}, [props.runOptions]);

	function testSessionDetails() {
		if (!testSessionDetailOpened) {
			return;
		}
		if (props.runOptions.testType === TestType.Rubric) {
			return (
				<Suspense fallback={<Fragment/>}>
					<RubricSessionDetails
						classID={props.runOptions.classID}
						rubricID={props.runOptions.testID}
						studentID={props.runOptions.studentID}
						hierarchy={props.hierarchy}
						subject={{
							id: props.runOptions.subjectID,
							name: props.runOptions.subjectName,
							type: props.runOptions.subjectType,
						}}
						onClose={() => setTestSessionDetailOpened(false)}
					/>
				</Suspense>
			);
		}
		return (
			<Suspense fallback={<Fragment/>}>
				<TestSessionDetails
					testId={props.runOptions.testID}
					studentID={props.runOptions.studentID}
					classID={props.runOptions.classID}
					specialistGroupID={props.runOptions.specialistGroupID}
					hierarchy={props.hierarchy}
					subject={{
						id: props.runOptions.subjectID,
						name: props.runOptions.subjectName,
						type: props.runOptions.subjectType,
					}}
					close={() => setTestSessionDetailOpened(false)}
				/>
			</Suspense>
		);
	}

	return <>
		{testLauncherOpened &&
			<TestLauncher
				testID={props.runOptions.testID}
				classID={props.runOptions.classID}
				testType={props.runOptions.testType}
				studentID={props.runOptions.studentID}
				hierarchy={props.hierarchy}
				subject={{
					id: props.runOptions.subjectID,
					name: props.runOptions.subjectName,
					type: props.runOptions.subjectType,
				}}
				onClosed={() => setTestLauncherOpened(false)}
				onFinished={() => setTestLauncherOpened(false)}
				onFlashCardsClicked={() => {
					setChangesCollector(new ChangesCollector({
						id: props.runOptions.subjectID,
						type: props.runOptions.subjectType,
					}, props.hierarchy));
					setFlashCardsOpened(true);
				}}
				onTestHistoryClicked={() => setTestHistoryOpened(true)}
				onTestSessionDetailsClicked={() => setTestLauncherOpened(true)}/>}
		{flashCardsOpened &&
			<FlashCardsWrapper
				hierarchySnapshot={props.hierarchy}
				changesCollector={changesCollector}
				parentLetterOnClosed={(emit, changes) => emit && notifyHierarchyChanges(changes)}
				onCloseClicked={() => {
					notifyHierarchyChanges(changesCollector);
					setChangesCollector(null);
					setFlashCardsOpened(false);
				}}
				subjectID={props.runOptions.subjectID}
				subjectType={props.runOptions.subjectType}/>}
		{testSessionDetails()}
		{testHistoryOpened && <React.Suspense fallback={<React.Fragment/>}>
			<TestHistoryModal hierarchy={props.hierarchy}
			                  subjectID={props.runOptions.subjectID}
			                  subjectType={props.runOptions.subjectType}
			                  testId={props.runOptions.testID}
			                  onClose={() => setTestHistoryOpened(false)}
			                  testType={props.runOptions.testType}/>
		</React.Suspense>}
	</>;
}
