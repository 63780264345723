import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Container = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	gap: '$8',
});

export const ReportTypeContainer = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	gap: '$2',
	padding: '20px',
	maxWidth: '260px',
	textAlign: 'center',
});
