import {useCallback, useEffect, useState} from 'react';
import {getGroupsData} from './utils';
import {AllOptionItem, GroupState, OptionType} from './types';
import {GroupType, StudentType} from '../../types';

interface Props {
	groups: GroupType[];
	students: StudentType[];
	selected: GroupType['id'][];
}

export function useGroupList({groups, students, selected}: Props) {
	const [selectedClassId, setSelectedClassId] = useState<OptionType>(AllOptionItem.Value);
	const [groupsWithStudents, setGroupsWithStudents] = useState<GroupState[]>([]);
	const [studentsWithoutGroup, setStudentsWithoutGroup] = useState<StudentType[]>([]);

	useEffect(() => {
		const {studentsWithoutGroup, groupsWithStudents} = getGroupsData({selectedClassId, groups, students});

		setGroupsWithStudents((currentState) =>
			groupsWithStudents.map<GroupState>((groupData) => {
				const current = currentState.find(({id}) => groupData.id === id);

				return {...groupData, isListOpen: current?.isListOpen ?? false};
			}),
		);

		setStudentsWithoutGroup(studentsWithoutGroup);
	}, [selectedClassId, groups, students]);

	useEffect(() => {
		setGroupsWithStudents((currentState) => currentState.map<GroupState>(
			(current) => ({...current, isListOpen: selected.includes(current.id)})
		));
	}, []);

	const onToggle = useCallback((isListOpen: boolean, groupId: GroupType['id']) => {
		setGroupsWithStudents((currentGroupsList) => {
			const copied = [...currentGroupsList];
			const copiedGroup = copied.find(({id}) => id === groupId);

			if (copiedGroup) {
				copiedGroup.isListOpen = isListOpen;
			}

			return copied;
		});
	}, []);

	const onSelectAll = (
		groupId: GroupType['id'],
		selectedStudentIds: StudentType['id'][],
	): StudentType['id'][] => {
		const {students} = groupsWithStudents.find(({id}) => groupId === id);
		const studentIds = students.map(({id}) => id);
		const isSelected = studentIds.length && studentIds.every((id) => selectedStudentIds.includes(id));
		const notSelected = studentIds.filter((id) => !selectedStudentIds.includes(id));
		return isSelected ? studentIds : notSelected;
	};

	return {
		setSelectedClassId,
		groupsWithStudents,
		studentsWithoutGroup,
		onToggle,
		onSelectAll,
	};
}
