import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Root = styled(Box, {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	overflow: 'hidden',
	paddingTop: 0,
	paddingRight: 12,
	variants: {
		columns: {
			1: {
				display: 'grid',
				alignItems: 'center',
				justifyContent: 'center',
				justifyItems: 'center',
				columnGap: 12,
				gridTemplateColumns: '1fr',
			},
			2: {
				display: 'grid',
				alignItems: 'center',
				justifyContent: 'center',
				justifyItems: 'center',
				columnGap: 12,
				gridTemplateColumns: '1fr 1fr',
			},
			3: {
				display: 'grid',
				alignItems: 'center',
				justifyContent: 'center',
				justifyItems: 'center',
				columnGap: 12,
				gridTemplateColumns: '1fr 1fr 1fr',
			},
			4: {
				display: 'grid',
				alignItems: 'center',
				justifyContent: 'center',
				justifyItems: 'center',
				columnGap: 12,
				gridTemplateColumns: '1fr 1fr 1fr 1fr',
			},
		},
	},
});