import {BaseService} from '@esgi/core/service';
import {V2PagesAssignmentsController} from '@esgi/contracts/esgi';

export class AssignmentsService extends BaseService {
	private assignmentsController = new V2PagesAssignmentsController();

	public deleteAssignment(assignmentID: number) {
		return this.assignmentsController.makeDelete({assignmentID}).subscribe();
	}

	public override dispose() {
		this.assignmentsController.dispose();
	}
}
