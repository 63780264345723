import {Correct} from '@esgi/ui/icons';
import {Button, Container} from './index.styled';

type Props = {
	checked: boolean;
	disabled: boolean;
	onClick: VoidFunction;
};

export function ButtonCheckbox({checked, disabled, onClick}: Props) {
	return (
		<Container dataCy='checkbox'>
			<Button checked={checked} disabled={disabled} onClick={onClick}>
				{checked && <Correct />}
			</Button>
		</Container>
	);
}
