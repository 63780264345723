import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';

export const DeleteButton = styled(Button, {
	alignItems: 'center',

	'&:not(:hover)': {
		color: '$negative',
		fill: '$negative',
		borderColor: '$negativeMuted',
		backgroundColor: '$negativeBackground',
	},
});