import React from 'react';
import styles from './styles.module.less';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../../../services/report-service';
import {Test} from '../../../../../../../models/models';

interface Props {
	test: Test;
	service: ReportService;
}

export function DetailReportNotes({test, service}: Props) {
	const includeQuestionNotes = useBehaviorSubject(service.settingsService.includeQuestionNotes);
	const includeSummaryNotes = useBehaviorSubject(service.settingsService.includeSummaryNotes);

	const answers = test.answers.filter(i => !!i.note).map((answer, index) =>
		<>
			{test.type === 'YN' && <>
				<sup><b>{index + 1}</b></sup>
			</>}
			{test.type === 'Rubric' && <>
				<span><b>{answer.text}</b>:&nbsp;</span>
			</>}
			<span>{answer.text}</span>
			{(index !== test.answers.filter(i => !!i.note).length - 1) && <>
				,&nbsp;
			</>}
		</>,
	);


	return <div className={styles.innerNotesRow}>
		{includeQuestionNotes || includeSummaryNotes && <>
			<div className={styles.notesContainer}>
				{includeQuestionNotes && <>
					<span>Notes</span>
					<div className={styles.notesList}>
						<div>
							{answers}
							{test.type === 'Score' && <>
								<span>{test.summaryNotes}</span>
							</>}
						</div>
					</div>
					{includeSummaryNotes && <>
						<div className={styles.summarySeparator}/>
					</>}
				</>}
				{includeSummaryNotes && <>
					<span>Summary</span>
					<div className={styles.notesList}>
						<div>
							<span>{test.summaryNotes}</span>
						</div>
					</div>
				</>}
			</div>
		</>}
	</div>;
}
