import React, {createRef} from 'react';
import {Alert, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import styles from './no-students-protected-button.module.less';
import {Link} from '@esgi/deprecated/elements/buttons';
import BaseReportButton from 'pages/home/components/report-button/buttons/base-button';
import {Props} from './base-button';

class ProtectedButtonProps extends Props {
	noStudents: boolean;
	messageText?: string;
}

class State {
	show: boolean = false;
}

export class NoStudentProtectedButton extends React.PureComponent<ProtectedButtonProps, State> {
	private modalManagerRef: ModalManagerRefObject = createRef();
	public state = new State();

	public render() {
		const {noStudents, onClicked, ...props} = this.props;
		return <>
			<BaseReportButton onClicked={(e) => {
				if (this.props.noStudents) {
					this.setState({show: true});
					return;
				}

				this.props.onClicked(e);
			}} {...props} />

			{this.state.show && <Alert className={styles.alert} modalManagerRef={this.modalManagerRef}>
				<Alert.Body>
					{props.messageText ? props.messageText : 'No students have been added to your class. Please add some before running the report.'}
				</Alert.Body>
				<Alert.Footer>
					<Link onClick={() => this.modalManagerRef.current.close(() => this.setState({show: false}))}>OK</Link>
				</Alert.Footer>
			</Alert>}
		</>;
	}
}
