import {AnswerState} from './components/answer/types';
import {Answer} from '../../../../types';

export class AnswerPanelState {
	constructor(
		public selectedAnswers: number[] = [],
		public stateAnswers: AnswerState[] = [],
		public answers: Answer[][] = [],
		public prevAnswers: Answer[] = [],
	) {}
}
