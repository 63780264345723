import React from 'react';
import {Modal} from '@esgillc/ui-kit/modal';
import {SettingsForm} from '../settings-form';
import FormService from '../../../services/form-service';
import styles from './styles.module.less';

interface Props {
	service: FormService
}

export function ModalBody ({service}: Props) {
	return <Modal.Body className={styles.modalBody}>
		<SettingsForm formService={service}/>
	</Modal.Body>;
}
