import React, {createRef, ReactNode, RefObject} from 'react';
import {TextArea} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Description} from '../../../../models';
import RubricTooltip from '../../rubric-tooltip/rubric-tooltip';
import {ClearIcon} from '../../../icons';
import DetailsDialog from './components/details-dialogs/details-dialog';
import styles from './description.module.less';

class State {
	showValidation: boolean = false;
	detailsEditDialogOpened: boolean = false;
	focus: boolean = false;
}

interface Props {
	className?: string;
	isActive: boolean;
	isEditMode: boolean;
	onNameChanged: (v: string) => void;
	onDetailsChanged: (v: string) => void;
	description: Description;
	levelName: string;
	criteriaName: string;
	tooltipContainer: HTMLElement;
}

export class DescriptionActiveView extends React.PureComponent<Props, State> {
	private get isInvalid(): boolean {
		return this.state.showValidation && !this.props.description.description.trim();
	}
	public readonly boxRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
	public readonly textAreaRef: RefObject<TextArea> = React.createRef<TextArea>();
	public state = new State();

	public showValidation(showValidation: boolean): void {
		this.setState({showValidation});
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if(prevProps.isEditMode !== this.props.isEditMode && this.state.showValidation) {
			this.setState({showValidation: false});
		}
	}

	render() {
		return <>
			<div ref={this.boxRef}
			     className={join(styles.box,
				     this.props.isEditMode && styles.active,
				     this.state.focus && styles.focus,
				     this.isInvalid && styles.invalid,
				     this.props.className)}>
				<div className={styles.innerBox}>
					<div className={styles.header}>
						{this.renderTitle()}
					</div>
					{this.renderBody()}
					{this.renderFooter()}
					{this.renderDetailsEditingDialog()}
				</div>
			</div>
			{this.renderValidationTooltip()}
		</>;
	}

	private renderTitle(): ReactNode {
		return <div className={join(styles.title, !this.props.isEditMode && styles.hidden)}>
			<span>Description</span>
			<div className={styles.clear}>
				{this.props.description.description && <ClearIcon onClick={() => this.props.onNameChanged('')}/>}
			</div>
		</div>;
	}

	private renderBody(): ReactNode {
		if(!this.props.isActive) {
			return;
		}

		if (this.props.isEditMode) {
			return <div className={styles.description}>
				<TextArea ref={this.textAreaRef}
				              className={styles.name}
				              autoResizeToFit
				              hidePlaceholderOnFocus
				              maxLength={110}
				              cols={11}
				              rows={4}
				              value={this.props.description.description}
				              onChange={(v) => this.props.onNameChanged(v.target.value)}
				              onFocus={() => this.setState({focus: true})}
				              onBlur={() => this.setState({focus: false})}
				              placeholder='Enter description'/>
			</div>;
		}

		return <div className={styles.description}><span>{this.props.description.description}</span></div>;
	}

	private renderFooter(): ReactNode {
		const detailsExist = !!this.props.description.details?.trim();
		return <div className={styles.footer}>
			{this.props.isEditMode && <>
				<AnchorLikeButton onClick={() => this.setState({detailsEditDialogOpened: true})}>
					<OnHoverTooltip message={detailsExist ? 'View Details' : 'Add Details'}>
						<span>
							Details
							{detailsExist && <svg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M11.59 0L5 6.59L1.41 3.01L0 4.42L5 9.42L13 1.42L11.59 0Z' fill='#0077B8'/>
							</svg>}
						</span>
					</OnHoverTooltip>
				</AnchorLikeButton>
				<div className={styles.counter}>
					<div
						className={join(!this.state.focus && styles.hidden)}>{this.props.description.description.length}/110
					</div>
				</div>
			</>}
		</div>;
	}


	private renderValidationTooltip(): ReactNode {
		if(this.isInvalid) {
			return <RubricTooltip
				element={null}
				elementRef={this.boxRef}
				placement='right'
				container={this.props.tooltipContainer}>
				Please enter a description.
			</RubricTooltip>;
		}
	}

	private renderDetailsEditingDialog(): ReactNode {
		if (this.state.detailsEditDialogOpened) {
			return <DetailsDialog criteriaName={this.props.criteriaName}
			                      levelName={this.props.levelName}
			                      initValue={this.props.description.details}
			                      onClose={() => this.setState({detailsEditDialogOpened: false})}
			                      onSave={(value) => this.setState({detailsEditDialogOpened: false}, () => this.props.onDetailsChanged(value))}/>;
		}
	}
}
