import React, {createRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {ModalManager} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {IEPGoalFormService} from '../../services';

import styles from './styles.module.less';

interface Props {
	service: IEPGoalFormService;
	onClosed: () => void;
}

export default class SaveMyChangesDialog extends React.PureComponent<Props> {
	private modalManagerRef: React.RefObject<ModalManager> = createRef();

	public render() {
		return <Alert className={styles.modal} modalManagerRef={this.modalManagerRef}>
			<Alert.Body className={styles.body}>
                <div>
                    <p className={styles.headerText}>Save My Changes?</p>
                    <p className={styles.bodyText}>You have made edits to this goal. Do you wish to save your changes?</p>
                </div>
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text className={styles.button} onClick={this.closeDialog}>
					CANCEL
				</Buttons.Text>
				<Buttons.Text className={styles.button} onClick={this.saveClicked}>
					SAVE
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}

    private closeDialog = () => {
        this.modalManagerRef.current.close(() => this.props.onClosed());
    }

	private saveClicked = () => {
		this.props.service.update(this.closeDialog.bind(this));
	};
}