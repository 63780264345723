import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const ErrorMessage = styled(Text, {
	fontSize: '9px',
	fontWeight: '700',
	color: '$negativeVivid',
	lineHeight: '10px',
	opacity: 1,

	variants: {
		hidden: {
			true: {
				opacity: 0,
			},
		},
	},
});
