import {StudentWithUnit} from '../types';
import {Student} from '@esgi/main/libs/store';

export enum StudentTabId {
	Classes = 'classes',
	Groups = 'groups',
	Students = 'students',
}

export type StudentType = Student;

export type ClassType = {
	id: number;
	name: string;
	studentIDs: StudentType['id'][];
};

export type GroupType = {
	id: number;
	name: string;
	classID: number;
	studentIDs: StudentType['id'][];
};

export type InitStudentsResponse = {
	classes: ClassType[];
	groups: GroupType[];
	students: StudentType[];
};

export type ToggleStudentFunction = (
	studentId: StudentWithUnit['studentId'],
	unitData?: StudentWithUnit['unitData'],
) => void;

export type ToggleStudentsFunction = (
	studentIds: StudentWithUnit['studentId'][],
	unitData?: StudentWithUnit['unitData'],
) => void;
