import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {CSS} from '@stitches/react';
import {Skeletonable} from '../../types';
import {Counter} from './index.styled';

type CounterBoxProps = ComponentPropsWithoutRef<'div'> & {
	/** Data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface, leveraging the given media and style map. */
	css?: CSS;
} & Skeletonable;

export const CounterBox = Counter;
