import {join} from '@esgillc/ui-kit/utils';

interface Props {
	rotate: number;
	className?: string;
}

export function ArrowIcon({rotate, className}: Props) {
	return <svg data-cy={'arrow-icon'}
							className={join(className)}
							transform={`rotate(${rotate})`}
							width='14' height='9'
							viewBox='0 0 14 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M1.645 8.645L7 3.30166L12.355 8.645L14 7L7 -2.86102e-06L0 7L1.645 8.645Z'
					fill='#0088CC'/>
	</svg>;
}