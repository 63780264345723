import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const StyledButton = styled(Button, {
	marginTop: 'auto',
	position: 'sticky',
	bottom: 0,

	variants: {
		justifySelf: {
			end: {
				justifySelf: 'end',
			},

			stretch: {
				justifySelf: 'stretch',
			},
		},

		width: {
			full: {
				width: '100%',
			},

			maxContent: {
				width: 'max-content',
			},
		},
	},
});
