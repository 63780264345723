import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Table = styled(Box, {
	marginTop: '30px',
	width: '100%',
});

export const TableHeader = styled(Box, {
	border: '1px solid $neutral72',
	borderBottom: 'none',
});

export const TableBody = styled(Box, {
	borderBottom: '1px solid $neutral72',
});

export const TableRow = styled(GridBox, {
	gridTemplateColumns: '1fr 1fr',

	variants: {
		hasScales: {
			true: {
				gridTemplateColumns: '1fr 1fr auto',
			},
		},
	},
});

export const Notes = styled(GridBox, {
	borderTop: '1px solid $neutral72',
	width: '100%',

	variants: {
		hasScales: {
			true: {
				gridTemplateColumns: '1fr auto',
			},
		},
	},
});

export const TableBox = styled(FlexBox, {
	padding: '5px 10px',
	borderLeft: '1px solid $neutral72',

	variants: {
		borderBottom: {
			true: {
				borderBottom: '1px solid $neutral72',
			},
		},

		borderRight: {
			true: {
				borderRight: '1px solid $neutral72',
			},
		},
	},
});

export const GradeScaleBox = styled(FlexBox, {
	padding: '5px 10px',
	minWidth: '58px',
	border: '1px solid $neutral72',
	borderTop: 'none',
});

export const TopBorder = styled(GridBox, {
	gridTemplateColumns: '1fr 1fr auto',
	borderTop: '3px solid $neutral48',
});

export const TextHeader = styled(Text, {
	width: '100%',
	textAlign: 'center',
});

export const Description = styled(Text, {
	fontSize: '12px',
	width: '100%',

	variants: {
		centralised: {
			true: {
				textAlign: 'center',
			},
		},
	},
});
