import {ShapeType} from 'shared/modules/question-editor/models';
import {
	ContextMenuAction,
	ContextMenu,
} from 'shared/modules/question-editor/mx/editor/canvas/header/context-menu/context-menu';
import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import './canvas-header.less';

export class Props {
	testName?: string;
	questionNumber?: number;
	onDeleted: () => void;
	onUndo: () => void;
	onRedo: () => void;
	showUndoRedo: boolean;
	shapeType: ShapeType;
	contextMenuClicked: (action: ContextMenuAction) => void;
	selectedCells: string[] = [];
}

export class CanvasHeader extends React.PureComponent<Props> {
	get isQuestionEditor() {
		return !!this.props.questionNumber;
	}

	get hasName() {
		return !!this.props.testName && this.props.testName.toLowerCase().indexOf('draft') === -1;
	}

	get itemsSelected() {
		return this.props.selectedCells.length > 0;
	}

	getHeader() {
		if (this.isQuestionEditor) {
			if (this.hasName) {
				return <div className='test-info'><span>{this.props.testName}</span>:<span
					className='question-name'>Question {this.props.questionNumber}</span></div>;
			} else {
				return <div className='test-info'><span>Question {this.props.questionNumber}</span></div>;
			}
		}
	}

	render() {
		return <div className={this.isQuestionEditor ? 'mx-graph-canvas-header' : 'mx-graph-canvas-header test-intro'}>
			{this.getHeader()}
			<div className='controls-container'>
				{this.props.showUndoRedo &&
				<>
					<div className='header-item' onClick={() => this.props.onUndo()}>
						<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M15.4286 8.57143C15.4286 4.32254 11.9632 0.857142 7.71429 0.857142C5.73549 0.857142 3.81696 1.65067 2.40067 2.98661L1.09487 1.69085C0.914063 1.5 0.632812 1.44978 0.401786 1.55022C0.160714 1.65067 0 1.8817 0 2.14286V6.64286C0 6.99442 0.291295 7.28571 0.642857 7.28571H5.14286C5.40402 7.28571 5.63504 7.125 5.73549 6.88393C5.83594 6.6529 5.78571 6.37165 5.59487 6.19085L4.21875 4.80469C5.1529 3.9308 6.39844 3.42857 7.71429 3.42857C10.5469 3.42857 12.8571 5.73884 12.8571 8.57143C12.8571 11.404 10.5469 13.7143 7.71429 13.7143C6.11719 13.7143 4.64062 12.9911 3.65625 11.7154C3.60603 11.6451 3.51563 11.6049 3.42522 11.5949C3.33482 11.5949 3.24442 11.625 3.17411 11.6853L1.79799 13.0714C1.6875 13.1819 1.67746 13.3728 1.7779 13.5033C3.24442 15.2712 5.41406 16.2857 7.71429 16.2857C11.9632 16.2857 15.4286 12.8203 15.4286 8.57143Z'
								fill='white'/>
						</svg>
						<span>Undo</span>
					</div>
					<div className='header-item' onClick={() => this.props.onRedo()}>
						<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M15.4286 2.14286C15.4286 1.8817 15.2679 1.65067 15.0368 1.55022C14.7958 1.44978 14.5145 1.5 14.3337 1.69085L13.0279 2.98661C11.6116 1.65067 9.69308 0.857142 7.71429 0.857142C3.4654 0.857142 0 4.32254 0 8.57143C0 12.8203 3.4654 16.2857 7.71429 16.2857C10.0145 16.2857 12.1842 15.2712 13.6507 13.5033C13.7511 13.3728 13.7511 13.1819 13.6306 13.0714L12.2545 11.6853C12.1842 11.625 12.0938 11.5949 12.0033 11.5949C11.9129 11.6049 11.8225 11.6451 11.7723 11.7154C10.7879 12.9911 9.31138 13.7143 7.71429 13.7143C4.8817 13.7143 2.57143 11.404 2.57143 8.57143C2.57143 5.73884 4.8817 3.42857 7.71429 3.42857C9.03013 3.42857 10.2757 3.9308 11.2199 4.80469L9.83371 6.19085C9.64286 6.37165 9.59263 6.6529 9.69308 6.88393C9.79353 7.125 10.0246 7.28571 10.2857 7.28571H14.7857C15.1373 7.28571 15.4286 6.99442 15.4286 6.64286V2.14286Z'
								fill='white'/>
						</svg>
						<span>Redo</span>
					</div>
				</>}

				{this.itemsSelected &&
				<>
					<OnHoverTooltip message='Delete'>
						<div className='header-item' onClick={() => this.props.onDeleted()}>
							<svg width='15' height='19' viewBox='0 0 15 19' fill='none'
								 xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M1.75 16.25C1.75 17.35 2.65 18.25 3.75 18.25H11.75C12.85 18.25 13.75 17.35 13.75 16.25V4.25H1.75V16.25ZM14.75 1.25H11.25L10.25 0.25H5.25L4.25 1.25H0.75V3.25H14.75V1.25Z'
									fill='white'/>
							</svg>
						</div>
					</OnHoverTooltip>
					<ContextMenu
						selectedCells={this.props.selectedCells}
						onClick={(action) => this.props.contextMenuClicked(action)}
						shapeType={this.props.shapeType}
					/>
				</>
				}
			</div>
		</div>;
	}
}
