import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	firstName: string;
	lastName: string;
	textSize?: VariantProps<typeof Text>['size'];
	dataCy?: string
};

export function StudentFullName({firstName, lastName, textSize = 'medium', dataCy = 'student-full-name'}: Props) {
	const fullName = [firstName, lastName].filter(Boolean).join(' ').trim();

	return (
		<OneLinedText size={textSize} bold dataCy={dataCy}>
			{fullName}
		</OneLinedText>
	);
}
