import {ColorConfig} from './types';

export const alertContentInlinePadding = 20;

export const alertColorsConfig: Record<'negative' | 'positive' | 'neutral', ColorConfig> = {
	negative: {
		backlightColor: 'negativeHighlight',
		borderColor: 'red92',
		headerBgColor: 'red98',
		headerTextColor: 'negative',
	},
	positive: {
		backlightColor: 'positiveHighlight',
		borderColor: 'positiveMuted',
		headerBgColor: 'positiveBackground',
		headerTextColor: 'green',
	},
	neutral: {
		backlightColor: 'muted',
		borderColor: 'mild',
		headerBgColor: 'background',
		headerTextColor: 'neutral24',
	},
};
