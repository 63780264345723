import {useEffect, useMemo, useState} from 'react';
import {TestSession} from '../../../types';
import {FilterItem} from './types';
import {AnswerState} from '../../../../kit';

export function useFilterItems({sessionInfo}: {sessionInfo: TestSession}) {
	const [correctAnswers, setCorrectAnswers] = useState(sessionInfo.correctAnswers);
	const [incorrectAnswers, setIncorrectAnswers] = useState(0);
	const [notTestedAnswers, setNotTestedAnswers] = useState(0);

	useEffect(() => {
		const {correctAnswers, inCorrectAnswers, notTestesAnswers} = sessionInfo.answers.reduce(
			(currentState, {answerState}) => {
				switch (answerState) {
					case AnswerState.Correct:
						currentState.correctAnswers = currentState.correctAnswers + 1;
						break;
					case AnswerState.Incorrect:
						currentState.inCorrectAnswers = currentState.inCorrectAnswers + 1;
						break;
					case AnswerState.NotTested:
						currentState.notTestesAnswers = currentState.notTestesAnswers + 1;
						break;
				}

				return currentState;
			},
			{
				correctAnswers: 0,
				inCorrectAnswers: 0,
				notTestesAnswers: 0,
			},
		);

		setCorrectAnswers(correctAnswers);
		setIncorrectAnswers(inCorrectAnswers);
		setNotTestedAnswers(notTestesAnswers);
	}, []);

	const filterItems = useMemo<FilterItem[]>(
		() => [
			{
				answerState: AnswerState.Correct,
				countAnswers: correctAnswers,
			},
			{
				answerState: AnswerState.Incorrect,
				countAnswers: incorrectAnswers,
			},
			{
				answerState: AnswerState.NotTested,
				countAnswers: notTestedAnswers,
			},
		],
		[correctAnswers, incorrectAnswers, notTestedAnswers],
	);

	return {
		filterItems,
		correctAnswers,
		setCorrectAnswers,
		setIncorrectAnswers,
		setNotTestedAnswers,
	};
}
