import {IModule, Module, OldAlerts} from '@esgi/deprecated/knockout';
import {userStorage} from '@esgi/core/authentication';
import {Container, ContainerResponse, ExportApi} from 'shared/modules/export/export';
import React from 'react';
import {ExportHistory, ExportTrackingContainer} from 'shared/modules/export/history-module/export-history';
import {ManualModule} from 'shared/modules/export/manual-module/manual-export';
import {ScheduledModule} from 'shared/modules/export/scheduled-module/scheduled-export';
import './container-export.less';

class TabContainer {
	title: string;
	name: string;
	module: IModule;

	constructor(title: string, name: string, module: IModule) {
		this.title = title;
		this.name = name;
		this.module = module;
	}

}

export class ExportContainer extends Module {

	private userContext;

	private tabs: Array<TabContainer>;

	private currentTab: KnockoutObservable<TabContainer>;

	private data: Container;

	private historyContainer: ExportTrackingContainer;

	private server: ExportApi;

	constructor() {
		super();
		this.userContext = userStorage.get();
		this.server = new ExportApi();

		this.historyContainer = new ExportTrackingContainer({count: 20, interval: 5000, context: this.userContext});

		this.tabs = [];
		this.tabs.push(new TabContainer('Manual', 'manual', new ManualModule(this.userContext,
			new ExportHistory(this.userContext, {showDeliveryStatus: false, historyContainer: this.historyContainer}),
			() => {
				return $.Deferred<Container>().resolve(this.data).promise();
			})));
		this.tabs.push(new TabContainer('Scheduled', 'scheduled', new ScheduledModule(this.userContext,
			new ExportHistory(this.userContext, {showDeliveryStatus: true, historyContainer: this.historyContainer}),
			() => {
				return $.Deferred<Container>().resolve(this.data).promise();
			})));
		this.currentTab = ko.observable(this.tabs[0]);
	}

	load(): JQueryPromise<any> {
		const deferred = $.Deferred();
		this.server.initContainer(this.userContext.globalSchoolYearID).then((model: ContainerResponse) => {
			this.historyContainer.loadFromServer();
			this.data = Container.get(model);
			deferred.resolve(model);
		}).catch((obj) => {
			OldAlerts.bsalert(obj.Message);
			deferred.reject(obj);
		});
		return deferred.promise();
	}

	view = {
		tabClicked: (tab: TabContainer) => {
			this.currentTab(tab);
		},
	};

	template = () => <div className='export-container' style={{display: 'none'}}
	                      data-bind='afterRender: true,visible: true'>
		<div className='tabbable' id='export-types'>
			<ul className='nav nav-tabs' data-bind='foreach: tabs'>
				<li data-bind='css: { active: $parent.currentTab() == $data }'>
					<a href='#' data-bind='click: $parent.view.tabClicked'>
						<span data-bind='text: title'/>
					</a>
				</li>
			</ul>
			<div className='tab-content'>
				<div className='tab-pane active'
				     data-bind='module: {data: currentTab().module, loaded: function(){} } '/>
			</div>
		</div>
	</div>
}
