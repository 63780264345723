import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Box} from '@esgi/ui/layout';

type RightPanelBodyProps = ComponentPropsWithoutRef<'div'> & BaseComponentProps;

export const RightPanelBody = forwardRef<HTMLDivElement, RightPanelBodyProps>(
	({dataCy = 'features-right-panel-body', css = {}, children, ...props}, forwardedRef) => {
		return (
			<Box dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</Box>
		);
	},
);
