import * as utils from '../../utils/utils';
import {Typo} from '../../utils/typo';
import {map, Observable, of} from 'rxjs';
import {
	FontFamily,
	FontSizeCalculationResult,
	NameFormat,
	SightWordsPrintSettings,
	SightWordsTestQuestion,
	StudentStepState,
	StudentWithUnits,
} from '../../types';
import {
	SightWord,
	SightWordColumn,
	SightWordsReport,
	SightWordsReportDetails,
	SightWordsReportStudentData,
} from './types';

export class ReportGeneratorService {
	private readonly pageWidth: number;
	private readonly columnSpacing: number;

	constructor() {
		this.pageWidth = Typo.convertToPixels('180mm');
		this.columnSpacing = 20;
	}

	public generateReport(
		unitData: StudentStepState,
		printSettingsFormState: SightWordsPrintSettings,
	): Observable<SightWordsReport> {
		const totalColumnSpacing = this.columnSpacing * (printSettingsFormState.columnsStyle.length - 1);
		const columnWidth = this.pageWidth / printSettingsFormState.columnsStyle.length - totalColumnSpacing;
		const fontSize = Typo.convertToPixels(printSettingsFormState.selectedFontSize.key);
		// The font size should be such that the longest word would fit in the column
		const wordWithMaxLength = printSettingsFormState.selectedQuestions
			.map(x => x.name)
			.sort((first, second) => this.descendingSortFunction(first.length, second.length))[0];
		const columnsProcessing = of(printSettingsFormState.columnsStyle)
			.pipe(
				map(columns => {
					return columns
						.map(fontStyle => this.calculateMaxPossibleFontSize(wordWithMaxLength, columnWidth, fontSize, fontStyle))
						.sort((first: FontSizeCalculationResult, second: FontSizeCalculationResult) => {
							return this.descendingSortFunction(first.fontSize, second.fontSize)
						})[0];
				}),
			);

		return columnsProcessing.pipe(map(maxFontSize => {
			const columns = this.mapToReportColumns(printSettingsFormState.columnsStyle);
			const words = this.mapToReportWords(printSettingsFormState.selectedQuestions);
			const students = this.mapToReportStudents(unitData.students);
			const selectedFontSize = Typo.convertToPixels(printSettingsFormState.selectedFontSize.key);
			const isFontSizeOptimized = maxFontSize.fontSize !== selectedFontSize;
			const maxWordsCount = 700 / maxFontSize.height;
			const details = new SightWordsReportDetails(
				unitData,
				printSettingsFormState,
				maxFontSize.fontSize,
				columns,
				isFontSizeOptimized,
				maxWordsCount,
			);
			return new SightWordsReport(details, students, words);
		}));
	}

	private mapToReportColumns(columns: FontFamily[]) {
		return columns.map(f => new SightWordColumn(f));
	}

	private mapToReportWords(questions: SightWordsTestQuestion[]) {
		return questions.map(q => new SightWord(q.name));
	}

	private mapToReportStudents(students: StudentWithUnits[]) {
		return students.flatMap(student => {
			const fullName = utils.getNameByFormat(student.firstName, student.lastName, NameFormat.FirstAndLastName);
			return student.units.map(unit => new SightWordsReportStudentData(student.studentId, fullName, unit))
		});
	}

	private calculateMaxPossibleFontSize(text: string, columnSize: number, initialFontSize: number, fontStyle: FontFamily): FontSizeCalculationResult {
		if (text == null) {
			return {
				fontSize: 0,
			} as FontSizeCalculationResult;
		}
		const fontFamily = utils.convertEnumToFontFamily(fontStyle);
		return utils.calculateMaxPossibleFontSize(text, columnSize, initialFontSize, fontFamily)
	}

	private descendingSortFunction(firstVal: number, secondVal: number) {
		if (firstVal > secondVal) {
			return -1;
		} else if (firstVal === secondVal) {
			return 0;
		} else {
			return 1;
		}
	}
}