import {
	ChangeEvent,
	ComponentPropsWithoutRef,
	FocusEvent,
	MouseEvent,
	ReactNode,
	forwardRef,
	useCallback,
	useMemo,
} from 'react';
import {generateRandomString, useAutoControlledState} from '@esgi/ui/utils';
import {Text} from '@esgi/ui/typography';
import {Skeletonable} from '../../../../types';
import {CSS, VariantProps} from '@stitches/react';
import {ErrorDiv, InputDiv, InputStyled, Placeholder} from './index.styled';

export type InputProps = ComponentPropsWithoutRef<'input'> &
	Skeletonable & {
		/** Data attribute used by test automation team. */
		dataCy?: string;

		/** Returns a Style interface for input from a configuration, leveraging the given media and style map. */
		css?: CSS | undefined;

		/** Focused mode. */
		focused?: boolean;

		/** Hovered mode. */
		hovered?: boolean;

		/**
		 * Render Error Message. If prop is passed, error mode styles would enable.
		 * @deprecated
		 */
		error?: string | ReactNode;

		/** Component appearance.
		 * @default "default"
		 */
		variantType?: VariantProps<typeof InputStyled>['variant'];
	};

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			dataCy = 'ui-kit-input',
			css = {},
			type = 'text',
			autoComplete = 'off',
			onChange,
			placeholder,
			focused: externalFocused,
			onFocus,
			onBlur,
			onMouseEnter,
			onMouseLeave,
			hovered: externalHovered,
			value: externalValue,
			disabled,
			error,
			children,
			skeleton,
			id = 'ui-kit-input-id-' + generateRandomString(),
			variantType = 'default',
			...props
		},
		ref,
	) => {
		const [value, setValue] = useAutoControlledState({
			initialState: '',
			controlledState: externalValue,
		});

		const [focused, setFocused] = useAutoControlledState({
			initialState: false,
			controlledState: externalFocused,
		});

		const [hovered, setHovered] = useAutoControlledState({
			initialState: false,
			controlledState: externalHovered,
		});

		const handleChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				const value = event.target.value;

				setValue(value);
				onChange?.(event);
			},
			[onChange, setValue],
		);

		const handleFocus = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(true);

				onFocus?.(event);
			},
			[onFocus, setFocused],
		);

		const handleBlur = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				const value = event.target.value;

				event.target.scrollLeft = 0;

				setValue(value);
				setFocused(false);

				onBlur?.(event);
			},
			[onBlur, setFocused, setValue],
		);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(true);

				onMouseEnter?.(event);
			},
			[onMouseEnter, setHovered],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(false);

				onMouseLeave?.(event);
			},
			[onMouseLeave, setHovered],
		);

		const errorContent = useMemo(() => {
			if (!error) {
				return null;
			}

			if (typeof error === 'string') {
				return (
					<Text size='xSmall' font='mono' color='negativeVivid'>
						{error}
					</Text>
				);
			}

			return error;
		}, [error]);

		const commonDataAttributes = useMemo(
			() => ({
				'data-disabled': disabled,
				'data-state': value ? 'valued' : 'none-valued',
				'data-hovered': hovered,
				'data-focused': focused,
				'data-error': Boolean(errorContent),
			}),
			[disabled, value, hovered, focused, errorContent],
		);

		const {isShowPlaceholder, placeholderTextSize} = useMemo<{
			isShowPlaceholder: boolean;
			placeholderTextSize: VariantProps<typeof Text>['size'];
		}>(() => {
			const isShowPlaceholder = Boolean(
				(variantType === 'default' || (variantType === 'mini' && !value)) && placeholder && !focused,
			);

			if (variantType === 'mini') {
				return {
					isShowPlaceholder,
					placeholderTextSize: 'small',
				};
			}

			if (value && variantType === 'default') {
				return {
					isShowPlaceholder,
					placeholderTextSize: 'xSmall',
				};
			}

			return {
				isShowPlaceholder,
				placeholderTextSize: 'medium',
			};
		}, [variantType, focused, placeholder, value]);

		return (
			<>
				<InputDiv css={css} skeleton={skeleton}>
					{isShowPlaceholder && (
						<Placeholder
							data-cy={`${dataCy}-placeholder`}
							size={placeholderTextSize}
							font='mono'
							{...commonDataAttributes}
						>
							{placeholder}
						</Placeholder>
					)}

					<InputStyled
						data-cy={dataCy}
						id={id}
						{...commonDataAttributes}
						type={type}
						autoComplete={autoComplete}
						value={value}
						onChange={handleChange}
						placeholder={undefined}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						disabled={disabled}
						// data-attribute for disabling lastPass prompts
						data-lpignore={autoComplete === 'off' ? true : undefined}
						ref={ref}
						variant={variantType}
						{...props}
					/>

					{children}
				</InputDiv>

				{errorContent && typeof errorContent !== 'boolean' && (
					<ErrorDiv data-cy={`${dataCy}-error`}>{errorContent}</ErrorDiv>
				)}
			</>
		);
	},
);
