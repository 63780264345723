import {EmptyContentTemplate, ExpandablePanelModeVariant} from '@esgi/main/features/teacher/home';
import {List} from '../list';
import {Student} from '@esgi/main/libs/store';
import {Dispatch} from 'react';

export type Props = {
	students: Student[];
	selectedStudentsIDs: Student['id'][];
	toggleStudent: Dispatch<Student['id']>;
	showEmptyTemplate?: boolean;
};

export function StudentList({students, selectedStudentsIDs, toggleStudent, showEmptyTemplate = true}: Props) {
	return (
		<ExpandablePanelModeVariant withBottomSectionButton={false}>
			{students.length && (
				<List.SelectableListMultiple
					students={students}
					selectedStudentId={selectedStudentsIDs}
					setSelectedStudentId={(studentId) => toggleStudent(studentId)}
				/>
			)}
			{!students.length && showEmptyTemplate && (
				<EmptyContentTemplate size='full' templateText='No students to display' />
			)}
		</ExpandablePanelModeVariant>
	);
}
