import React, {createRef} from 'react';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import styles from './dialogs.module.less';

interface Props {
	name: string;
	onConfirm: () => void;
	onCancel: () => void;
}

export default class RemoveLevelDialog extends React.PureComponent<Props> {
	private modalManagerRef: ModalManagerRef = createRef();

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader>
				<Title className={styles.header}>{`Remove Level ${this.props.name}?`}</Title>
			</ModalHeader>
			<ModalBody className={styles.modalBody}>
				<div className={styles.body}>
					Removing this level will also remove the descriptions associated with that level.
				</div>
			</ModalBody>
			<ModalFooter>
				<LinkButton onClick={() => this.modalManagerRef.current.close(this.props.onCancel)}>CANCEL</LinkButton>
				<LinkButton onClick={() => this.modalManagerRef.current.close(this.props.onConfirm)}>REMOVE</LinkButton>
			</ModalFooter>
		</Modal>;
	}
}
