import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Wrapper = styled(GridBox, {
	gap: '$2',
	gridAutoFlow: 'column',
	gridAutoRows: 'max-content',
	alignItems: 'center',
});

export const Counter = styled(GridBox, {
	padding: '2px 4px',
	backgroundColor: '$vivid',
	border: '1px solid $primaryHighlight',
	borderRadius: '4px',
});