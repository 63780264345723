import React from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {TestType} from '@esgi/core/enums';
import styles from '../styles.module.less';
import {HelpIcon} from './help-icon';

interface Props {
	readonly: boolean;
	type: TestType;
}

export class TestTypeField extends React.PureComponent<Props> {
	public render() {
		return <div className={styles.testTypeField}>
			<div className={join(styles.requiredField, this.props.readonly && styles.readonly)}>
				Test Type: <a href='https://support.esgisoftware.com/hc/en-us/articles/209160406-Creating-Tests'
				              className={styles.helpIcon}
				              target='_blank'
				              rel='noreferrer'>
				<HelpIcon/>
			</a>
			</div>
			<div className={styles.fieldContent}>
				<span className={styles.typeText}>
					{TestType[this.props.type]}
				</span>
			</div>
		</div>;
	}
}
