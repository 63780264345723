import {lazyComponent} from '@esgi/core/react';
import {OldReportLauncherWrapper} from '../old-report-launcher';
import {useState} from 'react';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Page} from '../../layout/index.styled';
import {Reports} from '@esgi/main/kits/reports';

const PieChartsReport = lazyComponent(() => import('shared/modules/reports/pie-chart/report'));

export function OldPieChart() {
	const [singleSelectedState, setSingleSelectedState] = useState<SingleSelectState>();

	return <Page>
		<Reports.StudentLevel.SingleStudentPanel withoutAllClass onChange={(value) => {
			setSingleSelectedState(value);
		}}/>
		<OldReportLauncherWrapper singleState={singleSelectedState}>
			{(version, isOpen, close, hierarchySnapshot, subject, changesCollector) => {
				if(isOpen) {
					return <PieChartsReport
						hierarchy={hierarchySnapshot}
						close={close}
						changeCollector={changesCollector}
						subjectID={subject.id}
					/>;
				}
			}}
		</OldReportLauncherWrapper>
	</Page>;
}

export default OldPieChart;
