import React from 'react';
import styles from '../../styles.module.less';
import {TestHistoryService} from '../../../../../../services/test-history-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';

interface Props {
	service: TestHistoryService
}

export function Students({service}: Props) {
	const students = useBehaviorSubject(service.students);
	const selectedStudent = useBehaviorSubject(service.selectedStudent);
	return students.length > 0 && <>
		<div>
			<span className={styles.settingsTitle}>Student: </span>
			<Dropdown value={selectedStudent}
			          setValue={(v) => service.selectedStudent.next(v)} optionName='name'
			          className={styles.fluid} autoWidth={true}>
				{students.map(v => <Option key={v.studentID} value={v}>{v.name}</Option>)}
			</Dropdown>
		</div>
	</>;
}
