import {ComponentProps, forwardRef, useCallback} from 'react';
import {ElementStatus, useFormElementContext} from '../../form';
import {CheckboxGroup as BaseCheckboxGroup} from '../../controls/checkbox-group';

type CheckboxGroupRootProps = Omit<ComponentProps<typeof BaseCheckboxGroup.Root>, 'onCheckedChange' | 'checked' | 'onChange'>;

export const CheckboxGroupRoot = forwardRef<HTMLDivElement, CheckboxGroupRootProps>(({children, ...props}, ref) => {

	const {status, setValue, value} = useFormElementContext({array: true}, CheckboxGroup);
	const onCheckedChange = useCallback((value: (string | number)[]) => setValue(value), [setValue]);

	const commonProps = {
		disabled: status === ElementStatus.disabled,
		checked: value,
		onChange: onCheckedChange,
		ref,
		...props,
	};

	return <BaseCheckboxGroup.Root {...commonProps}>
		{children}
	</BaseCheckboxGroup.Root>;
});

export const CheckboxGroup = {
	Root: CheckboxGroupRoot,
	Checkbox: BaseCheckboxGroup.Checkbox,
};
