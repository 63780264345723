import {Step} from './steps';
import {StudentLimitDecreasedTemplate} from '../html/student-limit-decreased-template';
import {CartModel} from '../models';

export class StudentLimitDecreasedStep extends Step {

	constructor(public cart: CartModel) {
		super();
		this.title = 'Fewer Students';
	}

	template = StudentLimitDecreasedTemplate;

	view = {
		cancel: () => {
			$(this).trigger('cancel');
		},
	};

	events = {
		cancel: (callback) => {
			$(this).on('cancel', callback);
		},
	};
}