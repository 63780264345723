import {styled} from '@esgi/ui/theme';
import {Alert} from '@esgi/ui/alert';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
	flexDirection: 'column',
});

export const AddButton = styled(Button, {
	marginLeft: '16px',
});

export const Description = styled(Text, {
	marginBottom: '24px',
});
