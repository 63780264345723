import {Text} from '@esgi/ui/typography';
import {Avatar} from '@esgi/ui/avatar';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {FlexBox, SelectableList} from '@esgi/ui/layout';
import {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Drawer, useDrawerRef, OneLinedText} from '@esgi/main/kits/common';
import {Correct, Folder, Link, Users} from '@esgi/ui';
import {ClassModel, StudentModel} from '../../types';
import {
	AvatarContainer,
	Container,
	ContentBlock,
	Divider,
	GroupBlock,
	ListItem,
	ListLabelContainer,
	UserIcon,
	AvatarRoot,
} from './index.styled';
import {StudentManagerService} from '../../service';

type Props = {
	student: StudentModel,
	classes: ClassModel[],
	onClose: VoidFunction,
	service: StudentManagerService,
	snackbarRef: RefObject<SnackbarManager>,
}

export function AssignDrawer({student, ...props}: Props) {
	const drawerRef = useDrawerRef();

	const [loading, setLoading] = useState(false);
	const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
	const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

	const classes = useMemo(() => props.classes.filter(c => selectedClasses.includes(c.classID.toString())), [selectedClasses, props.classes]);
	const groups = useMemo(() => classes.flatMap(({groups}) => groups), [classes]);

	const close = useCallback((force?: boolean) => {
		if (!loading || force) {
			drawerRef.current.close(props.onClose);
		}
	}, [loading, drawerRef, props.onClose]);

	//Remove previously selected groups when class deselected.
	useEffect(() => {
		const possibleGroupIDs = classes.map(c => c.groups).flat().map(g => g.groupID.toString());
		setSelectedGroups((values) => values.filter(v => possibleGroupIDs.includes(v)));
	}, [classes, setSelectedGroups]);

	const assign = useCallback(() => {
		setLoading(true);
		props.service.assign([student.studentID], classes.map(c => c.classID), selectedGroups.map((g) => Number.parseInt(g)))
			.subscribe({
				next: () => {
					const studentName = student.firstName + ' ' + student.lastName;
					const classesNames = classes.map(c => c.name).join(', ');
					const groupsNames = classes
						.map(c => c.groups)
						.flat()
						.filter(g => selectedGroups.includes(g.groupID.toString()))
						.map(g => g.name)
						.join(', ');

					if (selectedClasses.length === 1 && selectedGroups.length <= 1) {
						props.snackbarRef.current.showSnackbar(<Text>{studentName} has been assigned to {classesNames}</Text>);

						if(selectedGroups.length) {
							props.snackbarRef.current.showSnackbar(<Text>{studentName} has been assigned to {groupsNames}</Text>);
						}
					} else {
						props.snackbarRef.current.showSnackbar(<Text>{studentName} has been assigned to {[classesNames, groupsNames].join(', ')}</Text>);
					}

					drawerRef.current.close(props.onClose);
				},
				complete: () => setLoading(false),
			});
	}, [props.service, student, props.snackbarRef, selectedClasses, selectedGroups, drawerRef, props.onClose, classes]);

	return <Drawer drawerRef={drawerRef} onClickOutside={close}>
		<Drawer.Header Icon={Link} sectionName='Assign'
		               actionButtonDisabled={!selectedClasses.length || loading}
		               withActionButton
		               onActionButtonClick={assign}
		               actionButtonText='Update'
		               closeDrawer={close}/>
		<Drawer.Body>
			<Container>
				<AvatarContainer align='center' justify='start'>
					<AvatarRoot>
						<Avatar.Image src=''/>
						<Avatar.Fallback>
							{student.firstName[0]} {student.lastName[0]}
						</Avatar.Fallback>
					</AvatarRoot>
					<OneLinedText css={{paddingLeft: 12}} size='small' color='neutral40'>
						{student.firstName} {student.lastName}
					</OneLinedText>
				</AvatarContainer>
				<Divider/>
				<OverlayScrollbarsComponent defer style={{height: 'calc(100% - 73px)'}} options={{scrollbars: {autoHide: 'leave'}}}>
					<Drawer.ContentBlock title='Classes'>
						<SelectableList>
							<SelectableList.GroupRoot type='multiple' value={selectedClasses} onValueChange={setSelectedClasses}>
								{props.classes.map(({classID, name}) => <ListItem
									key={classID}
									variant='outlined'
									disabled={loading}
									withActiveBackground
									IconBefore={Users}
									value={classID.toString()}
								>
									{(selected) => <ListLabelContainer>
										<OneLinedText>{name}</OneLinedText>
										{selected && <Correct width={24} height={24}/>}
									</ListLabelContainer>}
								</ListItem>)}
							</SelectableList.GroupRoot>
						</SelectableList>
					</Drawer.ContentBlock>
					{(Boolean(classes.length) && Boolean(groups.length)) &&
						<ContentBlock>
							<Divider/>
							<Drawer.ContentBlock title='Groups'>
								{classes.map(({groups, classID, name}) => {
									if (!groups.length) {
										return null;
									}

									return <GroupBlock key={classID}>
										<FlexBox justify='start' align='center'>
											<UserIcon width={24} height={24}/>
											<Text size='large' color='lowContrast'>{name}</Text>
										</FlexBox>
										<SelectableList>
											<SelectableList.GroupRoot type='multiple' value={selectedGroups} onValueChange={setSelectedGroups}>
												{groups.map(({groupID, name}) => <ListItem
													key={groupID}
													variant='outlined'
													disabled={loading}
													withActiveBackground
													IconBefore={Folder}
													value={groupID.toString()}
												>
													{(selected) => <ListLabelContainer>
														<OneLinedText>{name}</OneLinedText>
														{selected && <Correct width={24} height={24}/>}
													</ListLabelContainer>}
												</ListItem>)}
											</SelectableList.GroupRoot>
										</SelectableList>
									</GroupBlock>;
								})}
							</Drawer.ContentBlock>
						</ContentBlock>
					}
				</OverlayScrollbarsComponent>
			</Container>
		</Drawer.Body>
	</Drawer>;
}
