import {SubjectLevel, SubjectType, SortDirection, StudentSort} from '@esgi/core/enums';
import {CriteriaResult, Filter, PeriodResult} from './filters-service-types';

export {SubjectType, SortDirection, StudentSort, SubjectLevel} from '@esgi/core/enums';

export enum FileType {
	Excel = 'excel',
	Pdf = 'pdf'
}

export enum FileTypeStudentLevel {
	Zip = 'zip',
	Pdf = 'pdf'
}

export interface SortModel {
	fieldName: string;
	fieldValue?: (value: StudentResult) => number;
	direction: string;
	relatedSorts?: SortModel[];
	rowIDField?: string;
}

export const sortDirectionMap = ['None', 'Asc', 'Desc'];

export interface DownloadData {
	rubricName: string;
	schoolEntityName: string;
	schoolEntityType: string;
	userName: string;
}

export enum DownloadType {
	Excel = 0,
	PDF = 1,
}

export interface Entity {
	id: number;
	value: string;
}

export interface SubjectEntity {
	subjectID: number;
	name: string;
	subjectType: SubjectType;
	subjectLevel: SubjectLevel;
}

export interface HeaderInfo {
	teacherGroups: Entity[];
	teacherClasses: Entity[];
	specialistGroups: Entity[];
	rubrics: Entity[];
	subjects: SubjectEntity[];
	userName: string;
}

export interface InitResponse {
	teacherGroups: Entity[];
	teacherClasses: Entity[];
	specialistGroups: Entity[];
	rubrics: Entity[];
	subjects: SubjectEntity[];
	subjectID: number;
	subjectType: SubjectType;
	filter: Filter;
	userName: string;
	sortBy: StudentSort;
	trackID: number;
	invalidTrack: boolean;
	schoolYearName: string;
	sessionGuid: string;
}

export interface LevelModel {
	name: string;
	score: number;
}

export interface ReportErrors {
	noRubrics?: boolean;
	noStudents?: boolean;
	invalidTrack?: boolean;
	schoolYearName?: string;
}

export interface StudentResult {
	id?: number;
	studentID: number;
	firstName: string;
	lastName: string;
	criteriaResults: CriteriaResult[];
	hasResult: boolean;
}

export interface Settings {
	key: string;
	value: string;
}

export interface SortByFirstLastName {
	sortKey: keyof Pick<StudentResult, 'firstName' | 'lastName'>;
	direction: SortDirection;
}

export interface SortByCriteriaResults {
	sortKey: keyof Pick<StudentResult, 'criteriaResults'>;
	direction: SortDirection;
	criteriaName: CriteriaResult['criteriaName'];
	periodResultName: PeriodResult['periodName'];
}

export type SortByCriteriaResultsOptions = Record<
	keyof Pick<SortByCriteriaResults, 'periodResultName' | 'criteriaName'>,
	string
>;

export type SortableKeys = keyof Pick<StudentResult, 'firstName' | 'lastName' | 'criteriaResults'>;

export interface SortBy {
	sortKey: SortableKeys;
	direction: SortDirection;
}

export type SortByType = Omit<SortByFirstLastName, 'direction'> | Omit<SortByCriteriaResults, 'direction'>

export type ReportSelectOption = {id: number, name: string};
