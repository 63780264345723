import {useCallback} from 'react';
import {EmptyContentTemplate} from '../../../empty-content-template';
import {ExpandablePanel} from '../../../expandable-panel';
import {Folder} from '@esgi/ui/icons';
import {List} from '../list';
import {useClassList} from './hooks';
import {StudentType, ClassType, ToggleStudentsFunction} from '../../types';
import {StudentWithUnit, UnitType} from '../../../types';

interface Props {
	classes: ClassType[];
	students: StudentType[];
	selectedStudents: StudentWithUnit[] | null;
	toggleStudents: ToggleStudentsFunction;
	selected: ClassType['id'][];
	setSelected: (id: ClassType['id']) => void;
}

export function ClassList(props: Props) {
	const {classes, students, selectedStudents, toggleStudents, selected, setSelected} = props;
	const {state, onToggle, onSelectAll} = useClassList({classes, students, selected});
	const selectedStudentId = (selectedStudents || []).map(({studentId}) => studentId);
	const isListSelected = useCallback((classId) => {
		if (!classes.length){
			return false;
		}
		const {studentIDs} = classes.find(({id}) => id === classId);
		return studentIDs.length && studentIDs.every((id) => selectedStudentId.includes(id));
	}, [classes, selectedStudentId]);

	return (
		<ExpandablePanel>
			{state.length ? (
				state.map(({id, name, students, isListOpen}) => (
					<List.ExpandableSelectableListMultiple
						key={id}
						IconBefore={Folder}
						triggerLabel={name}
						isListOpen={isListOpen}
						onTriggerButtonClick={() => {
							onToggle(!isListOpen, id);
							setSelected(id);
						}}
						selectedStudentId={selectedStudentId}
						students={students}
						setSelectedStudentId={((value) => toggleStudents([value], {id, name, type: UnitType.Class}))}
						onStyledButtonClick={() => toggleStudents(onSelectAll(id, selectedStudentId), {id, name, type: UnitType.Class})}
						isListSelected={isListSelected(id)}
					/>
				))
			) : (
				<EmptyContentTemplate
					size='full'
					templateText='No classes to display'
				/>
			)}
		</ExpandablePanel>
	);
}
