import {BehaviorSubject, combineLatest, switchMap, debounceTime} from 'rxjs';
import {SortModel, ExportRequest} from './report-type';
import {Comparator} from './report-comporator';

interface Row {
	id?: number;
}

export class ReportService<T extends Row> {
	public sortModel = new BehaviorSubject<SortModel>({
		fieldName: 'id',
		direction: 'Desc',
	});
	public sortRules = new BehaviorSubject<SortModel[]>([]);
	public sortFilter = new BehaviorSubject<(row: T) => boolean>((row: T) => true);
	public reportRows = new BehaviorSubject<T[]>([]);
	public optionsToExport = new BehaviorSubject<ExportRequest>(null);
	public cacheKey: string = '';
	protected rowIdField = 'rowID';
	protected initialReportRows = new BehaviorSubject<T[]>([]);
	private rows = combineLatest([
		this.initialReportRows,
		this.sortModel,
		this.sortRules,
		this.sortFilter,
	]).pipe(
		debounceTime(100),
		switchMap(([initialReportRows, sortModel, sortRules, sortFilter]) => {
			const reportRows = initialReportRows.filter(sortFilter ?? (() => true));
			if (sortModel) {
				const sortRule = sortRules.find((rule) =>
					rule.fieldName?.toLowerCase() === sortModel.fieldName?.toLowerCase(),
				);
				const additionalSortModels = sortRule?.relatedSorts || [];
				const comparator = new Comparator(sortModel, additionalSortModels);
				reportRows.sort(
					(left, right) => comparator.compare(left, right),
				);
			}
			this.optionsToExport.next({
				cacheKey: this.cacheKey,
				rows: reportRows.map(({id}) => id),
			});
			this.reportRows.next(reportRows);
			return reportRows;
		}),
	).subscribe();

	public destroy() {
		this.rows.unsubscribe();
	}

	public setData(rows: T[]) {
		const reportRows = rows.map((item: Record<string, any>, index) => ({
			...item,
			id: item[this.rowIdField] ?? index,
		}));
		this.initialReportRows.next(reportRows as T[]);
	}

	public setSortModel(sortModel: SortModel) {
		this.sortModel.next(sortModel);
	}

	public setSortRules(sortRules: SortModel[]) {
		this.sortRules.next(sortRules);
	}

	public setSortFilter(sortFilter: (row: T) => boolean) {
		this.sortFilter.next(sortFilter);
	}

	public sort(fieldName?: string, isInitial?: boolean) {
		let direction;
		const field = fieldName || this.sortModel.value.fieldName;
		if (isInitial) {
			direction = this.sortModel.value.direction;
		} else {
			if (this.sortModel.value.fieldName === field) {
				direction = this.getSortDirection(this.sortModel.value.direction);
			} else {
				direction = this.getSortDirection('None');
			}
		}
		this.sortModel.next({direction, fieldName});
	}

	private getSortDirection(oldDirection: string) {
		if (oldDirection === 'Desc' || oldDirection === 'None') {
			return 'Asc';
		}
		if (oldDirection === 'Asc') {
			return 'Desc';
		}
		return 'None';
	}
}
