import {useRef, useState} from 'react';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {OnErrorTooltip, TextArea} from '@esgillc/ui-kit/form-control';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';
import {EditIcon} from '../../icons/edit-icon';
import styles from './styles.module.less';

interface Props {
	control: FormControl;
	isTopicSelected: boolean;
	title: string;
	placeholder?: string;
	maxLength?: number;
	isRequired?: boolean;
	message?: string;
	dataCy?: string;
}

export function PropertiesTextarea({
	control,
	isTopicSelected,
	title,
	placeholder,
	maxLength,
	isRequired,
	message,
	dataCy,
}: Props) {

	const ref = useRef<FormElement>(null);
	const [isFocused, setFocused] = useState(false);

	const onEditButtonClick = () => {
		ref.current?.state.fieldRef.focus();
	};

	return <div data-cy={join(dataCy || 'properties-textarea')} className={styles.row}>
		<div data-cy='properties-textarea-title' className={styles.requiredTitle}>
			<span data-cy='required-star' className={join(isRequired ? styles.requiredStar : styles.hide)}>*</span>
			<label className={styles.title}>{title}</label>
			{message && <OnHoverTooltip message={message}>
				<QuestionMarkIcon className={styles.questionMarkIcon}/>
			</OnHoverTooltip>}
		</div>

		<FormElement ref={ref} className={styles.descriptionContainer} control={control}>
			<TextArea
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				placeholder={placeholder}
				className={styles.descriptionArea}
				maxLength={maxLength}
			/>

			{isTopicSelected && !isFocused && <EditIcon onClick={onEditButtonClick} className={styles.editIcon}/>}
			{isRequired && <OnErrorTooltip showOnError='required' placement='top'>
				Please enter a test {title}
			</OnErrorTooltip>}
			{maxLength && <OnErrorTooltip showOnError='length-max' placement='top'>
				Test {title} must be not greater {maxLength} characters in length.
			</OnErrorTooltip>}
		</FormElement>
	</div>;
}
