import {useEffect, useState} from 'react';
import {TestSessionStatus} from '../../kit/enums';
import {TestingModel} from '../models';
import {AlertType} from '../types';

export function useTestingAlerts(testSessionStatus: TestSessionStatus, testModel: TestingModel): AlertType {
	const [alert, setAlert] = useState(AlertType.None);

	useEffect(() => {
		if (testSessionStatus > 0) {
			setAlert(AlertType.TestSessionStatus);
		}
	}, [testSessionStatus]);

	useEffect(() => {
		if (testModel && testModel.progress.started) {
			setAlert(AlertType.SessionStarted);
		}
	}, [testModel]);

	return alert;
}
