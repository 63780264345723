import {TestSessionStatus} from '../enums';

export function getTestSessionStatus(status: TestSessionStatus, studentName: string, testName: string) {
	switch (status) {
		case TestSessionStatus.Saved:
			return 'The results for this session have already been saved. Another teacher may have taken over this session or it was inactive for over an hour so ESGI has saved the existing test results. You will now be returned to the homepage.';

		case TestSessionStatus.Started:
			return `Testing of ${studentName} on ${testName} was started in another web browser. This test will now be closed.`;

		case TestSessionStatus.UserMoved:
			return 'This student is no longer associated with this class. This could happen if the student was moved by an administrator or by another teacher logged into this account from a second browser. You will now be returned to the homepage.';
	}
}
