export type SortingModel = {
	direction: SortDirection;
	field: SortField;
}
export enum SortDirection {
	Asc = 'asc',
	Desc = 'desc',
}

export enum SortField {
	PostedAt = 'postedAt',
	Status = 'status',
	Title = 'title',
}

export const sortFieldLabels = {
	[SortField.Title]: 'Title',
	[SortField.PostedAt]: 'Create Date',
	[SortField.Status]: 'Status',
};
