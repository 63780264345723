import {AnswerState} from '../types';
import {items} from './constants';
import {Button, Container} from './index.styled';

type Props = {
	activeState: AnswerState;
	onToggle: (args: {newState: AnswerState; previousState: AnswerState}) => void;
};

export function YNAnswerToggleState({activeState, onToggle}: Props) {
	return (
		<Container>
			{items.map(({Icon, state}, index) => (
				<Button
					onClick={() =>
						onToggle({
							newState: state,
							previousState: activeState,
						})
					}
					isActive={state === activeState}
					disabled={state === activeState}
					key={index}
					dataCy={'button-' + state.toLowerCase()}
				>
					<Icon />
				</Button>
			))}
		</Container>
	);
}
