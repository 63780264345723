import {useEffect, useMemo, useState} from 'react';
import {useModal} from '@esgillc/ui-kit/modal';
import ItemAnalysisReportService from './services/item-analysis-report-service';
import {
	ReportModal,
	ReportModalBody,
	ReportModalCloseIcon,
	ReportModalHeader,
	ReportModalTitle,
} from '@esgi/deprecated/ui-kit/report/modal';
import {Loader, ServiceLoader} from '@esgillc/ui-kit/loader';
import {useService} from '@esgi/core/service';
import ItemAnalysisReport from './item-analysis-report';
import {SubjectType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {ExportService} from './services/export-service';
import {ReportDownload} from '@esgi/deprecated/ui-kit/report/modal/download-button/download-reports-button';
import styles from './item-analysis-report.module.less';

interface Props {
	subjectID: number;
	subjectType: SubjectType;
	hierarchy: HierarchySnapshot;
	onClose: () => void;
	changesCollector: ChangesCollector,
}

export default function ItemAnalysisReportModal(props: Props) {
	const modalManager = useModal();
	const exportService = useService(ExportService);
	const service = useMemo(() => new ItemAnalysisReportService(exportService), []);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const closeHandler = () => {
		modalManager.current.close(() => {
			const options = service.settings;
			props.changesCollector.applyChanges({
				classID: options.classID,
				groupID: options.groupID,
				subjectTabID: options.subjectTabID,
				subjectTabType: options.subjectTabType,
			});
			props.onClose();
		});
	};

	useEffect(() => {
		service.initModel(props.subjectID, props.subjectType, props.hierarchy).finally(() => {
			setIsLoading(false);
		});
	}, []);

	return <>
		{isLoading && <Loader show={isLoading} fullscreen/>}
		{!isLoading && <ReportModal modalManagerRef={modalManager}>
			<ReportModalHeader>
				<ReportModalTitle className={styles.modalTitle}>Item Analysis</ReportModalTitle>
				<ReportDownload.Button>
					<ReportDownload.ButtonItem onSelect={() => service.downloadReportPdf()}>
						PDF
					</ReportDownload.ButtonItem>
					<ReportDownload.ButtonItem onSelect={() => {
						console.log('IAR: Excel download button has been clicked!');
						service.downloadReportExcel();
					}}>
						Excel
					</ReportDownload.ButtonItem>
				</ReportDownload.Button>
				<ReportModalCloseIcon onClick={closeHandler}/>
			</ReportModalHeader>
			<ReportModalBody className={styles.itemAnalysisReport}>
				<ItemAnalysisReport itemAnalysisReportService={service}/>
				<ServiceLoader trackingService={service} fullscreen/>
			</ReportModalBody>
		</ReportModal>}
	</>;
}
