import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {BehaviorSubject, combineLatest, of, Subscription, switchMap, tap} from 'rxjs';
import {
	DownloadRequest,
	DownloadTestModel,
	MarkingPeriod,
	RequestReportModel,
	TestModel,
	ReportDisplayResultsMode,
	Report,
	GradeScale,
	ReportData,
	ReportSettingsValue,
	ReportSettingValueChangeParameters,
	SubjectInfo,
	TrackDatesModel,
	SubjectInfoForRequest,
} from '../types';
import {StudentService} from './student-service';
import {TestService} from './test-service';
import {SubjectService} from './subject-service';
import {V2PagesReportsController, V2PagesReportsStudentProgressController} from '@esgi/contracts/esgi';
import {Test} from '../kit/tests-control/types';
import {isNull} from 'underscore';
import {Student, SubjectLevel, SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {isUndefined} from '@esgi/ui';
import {SsoTracker} from '../../../../../../../libs/core/src/tracker';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {EventBusDispatcher} from '@esgillc/events';

export class StudentProgressReportService extends BaseService {
	public isPageLoading$ = new BehaviorSubject(true);

	public studentService = new StudentService();
	public subjectService = new SubjectService();
	public testService = new TestService();

	public readonly selectedStudentsIDs$ = new BehaviorSubject<Student['id'][]>([]);
	public readonly selectedSubjectID$ = new BehaviorSubject<SubjectTab['id']>(-1);
	public readonly selectedTestsIDs$ = new BehaviorSubject<Test['id'][]>([]);

	public readonly markingPeriods$ = new BehaviorSubject<MarkingPeriod[]>([]);
	public readonly gradeScales$ = new BehaviorSubject<GradeScale[]>([]);

	public displayType$ = new BehaviorSubject(ReportDisplayResultsMode.Score);
	public reportSettings$ = new BehaviorSubject<ReportSettingsValue>({
		carryScoresForward: false,
		showBaseline: false,
		displayZeroIfNotTested: false,
		showGradeColors: false,
	});

	public readonly canRunReportPreview$ = this.completeOnDestroy(
		combineLatest([this.selectedStudentsIDs$, this.selectedTestsIDs$]),
	).pipe(switchMap(([students, tests]) => of(Boolean(students.length) && Boolean(tests.length))));

	public readonly isReportAlertOpen$ = new BehaviorSubject(false);
	public readonly report$ = new BehaviorSubject<Report | null>(null);
	public readonly testResultsCorrectVerbiage$ = new BehaviorSubject('');

	private initSubscription$: Subscription | null = null;

	private readonly studentProgressController = new V2PagesReportsStudentProgressController();
	private readonly pagesReportsController = new V2PagesReportsController();

	constructor() {
		super();

		// init
		this.completeOnDestroy(combineLatest([this.selectedStudentsIDs$, this.selectedTestsIDs$]))
			.pipe(
				tap(([selectedStudentsIDs, selectedTestsIDs]) => {
					const currentUser = getUser();

					if (isNull(currentUser) || !selectedStudentsIDs.length || !selectedTestsIDs.length) {
						return;
					}

					this.isPageLoading$.next(true);
					this.initSubscription$?.unsubscribe();

					const {subjectID, subjectLevel, subjectType} = this.getSubjectInfoForRequest(this.selectedSubjectID$.value);

					const reportSettings = this.reportSettings$.value;

					const requestReportModel = this.getRequestReportModel({
						reportSettings,
						reportDisplayResultsMode: this.displayType$.value,
						subjectID: subjectID === -1 ? 0 : subjectID,
						subjectLevel,
						subjectType,
						studentIDs: selectedStudentsIDs,
						testIDs: selectedTestsIDs,
					});

					this.initSubscription$ = this.studentProgressController
						.init({
							districtID: currentUser.districtID,
							globalSchoolYearID: currentUser.globalSchoolYearID,
							schoolID: currentUser.schoolID,
							userID: currentUser.userID,
							userType: currentUser.userType,
							requestReportModel,
						})
						.subscribe({
							next: ({value, isSuccess}) => {
								if (!isSuccess) {
									return;
								}

								const {gradeScales, subjectGradeScales} = value.report;
								const reportDisplayResultsMode = value.reportDisplayResultsMode;
								const gradesDisabled = gradeScales.length === 0 || subjectGradeScales.length === 0;

								let displayType = this.displayType$.value;

								if (gradesDisabled) {
									if (displayType === ReportDisplayResultsMode.Grade || reportDisplayResultsMode === ReportDisplayResultsMode.Grade) {
										displayType = ReportDisplayResultsMode.Percent;
									} else {
										displayType = reportDisplayResultsMode;
									}
								}

								this.gradeScales$.next(gradeScales);
								this.displayType$.next(displayType);

								this.reportSettings$.next({
									carryScoresForward: value.carryScoresForward,
									displayZeroIfNotTested: value.displayZeroIfNotTested,
									showBaseline: value.showBaseline,
									showGradeColors: value.showGradeColors,
								});

								this.setMarkingPeriods(value.report.trackDates);
							},
							complete: () => {
								this.isPageLoading$.next(false);
							},
						});
				}),
			)
			.subscribe();

		this.completeOnDestroy(combineLatest([this.selectedSubjectID$, this.selectedStudentsIDs$]))
			.pipe(
				tap(([subjectId, selectedStudentsIDs]) => {
					if (!selectedStudentsIDs.length) {
						return;
					}

					if (subjectId === -1) {
						const allSubjects = this.subjectService.subjects$.value;
						const subjects = allSubjects.map<SubjectInfo>(({name, id, level, type}) => ({
							name,
							subjectID: id,
							subjectLevel: level,
							subjectType: type,
						}));

						this.testService.getTests({studentIDs: selectedStudentsIDs, subjects});

						return;
					}

					const subject = this.subjectService.get(subjectId);

					if (!isUndefined(subject)) {
						const subjectInfo: SubjectInfo = {
							name: subject.name,
							subjectID: subject.id,
							subjectLevel: subject.level,
							subjectType: subject.type,
						};

						this.testService.getTests({studentIDs: selectedStudentsIDs, subjects: [subjectInfo]});
					}
				}),
			)
			.subscribe();

		this.completeOnDestroy(combineLatest([this.subjectService.loaded$, this.subjectService.subjects$]))
			.pipe(
				tap(([isSubjectsLoaded, subjectsList]) => {
					if (isSubjectsLoaded && subjectsList.length) {
						this.selectedSubjectID$.next(subjectsList[0]!.id);
					}
				}),
			)
			.subscribe();

		this.displayType$
			.subscribe(value => {
				this.pagesReportsController
					.userSettingsReportDisplayResultsMode({
						value,
					})
					.subscribe();
			});

		// refresh report
		this.completeOnDestroy(combineLatest([this.isReportAlertOpen$, this.reportSettings$, this.displayType$]))
			.pipe(
				tap(([isReportAlertOpen, reportSettings, displayType]) => {
					const currentUser = getUser();

					if (!isReportAlertOpen || isNull(currentUser)) {
						return;
					}

					const selectedStudentsIDs = this.selectedStudentsIDs$.value;
					const selectedSubjectID = this.selectedSubjectID$.value;
					const selectedTestsIDs = this.selectedTestsIDs$.value;

					const {subjectID, subjectLevel, subjectType} = this.getSubjectInfoForRequest(selectedSubjectID);

					const requestReportModel = this.getRequestReportModel({
						reportSettings,
						reportDisplayResultsMode: displayType,
						subjectID,
						subjectLevel,
						subjectType,
						studentIDs: selectedStudentsIDs,
						testIDs: selectedTestsIDs,
					});

					this.studentProgressController
						.refresh({
							districtID: currentUser.districtID,
							globalSchoolYearID: currentUser.globalSchoolYearID,
							schoolID: currentUser.schoolID,
							userID: currentUser.userID,
							userType: currentUser.userType,
							requestReportModel,
						})
						.subscribe({
							next: ({value}) => {
								this.report$.next(value.report);
								this.testResultsCorrectVerbiage$.next(currentUser.testResultsCorrectVerbiage);

								this.setMarkingPeriods(value.report.trackDates);
							},
						});
				}),
			)
			.subscribe();
	}

	public override destroy() {
		super.destroy();
		this.studentService.destroy();
		this.testService.destroy();
		this.subjectService.destroy();
	}

	public override dispose() {
		super.dispose();
		this.studentProgressController.dispose();
		this.pagesReportsController.dispose();
	}

	public setStudents(value: Student['id'][]) {
		this.selectedStudentsIDs$.next(value);
	}

	public setSubject(value: number) {
		this.selectedSubjectID$.next(value);
	}

	public setTestsIDs(value: Test['id'][]) {
		this.selectedTestsIDs$.next(value);
	}

	public setDisplayType(value: ReportDisplayResultsMode) {
		this.displayType$.next(value);
	}

	public reportSettingValueChange({key, newValue}: ReportSettingValueChangeParameters) {
		const currentSettings = this.reportSettings$.value;

		this.reportSettings$.next({
			...currentSettings,
			[key]: newValue,
		});

		if (key === 'showBaseline') {
			this.studentProgressController.showBaseline({value: newValue}).subscribe();

			return;
		}

		this.studentProgressController.updateSettings({name: key, value: newValue}).subscribe();
	}

	public openReportAlert() {
		this.isReportAlertOpen$.next(true);
	}

	public closeReportAlert() {
		this.report$.next(null);
		this.isReportAlertOpen$.next(false);
	}

	public downloadPdf(zip: boolean) {
		const currentUser = getUser();

		if (isNull(currentUser)) {
			return;
		}

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = 'Student_Progress_Report' + year + '-' + month + '-' + day + '.' + (zip ? 'zip' : 'pdf');

		const selectedSubjectID = this.selectedSubjectID$.value;
		const selectedStudentsIDs = this.selectedStudentsIDs$.value;
		const selectedTestsIDs = this.selectedTestsIDs$.value;

		const reportSettings = this.reportSettings$.value;
		const displayType = this.displayType$.value;

		const {subjectID, subjectLevel, subjectType} = this.getSubjectInfoForRequest(selectedSubjectID);

		const selectedTests = selectedTestsIDs.map<DownloadTestModel>((iteratedTestID) => {
			const testSubject = this.subjectService.subjects$.value.find(({tests}) =>
				tests.find(({id: testID}) => testID === iteratedTestID),
			);

			return {
				testID: iteratedTestID,
				subjectID: testSubject?.id ?? 0,
				subjectType: testSubject?.type ?? SubjectType.Personal,
				subjectLevel: testSubject?.level ?? SubjectLevel.Teacher,
			};
		});

		const requestReportModel: DownloadRequest = {
			subjectID,
			subjectLevel,
			subjectType,
			showGradeColors: reportSettings.showGradeColors,
			carryScoresForward: reportSettings.carryScoresForward,
			displayZeroIfNotTested: reportSettings.displayZeroIfNotTested,
			reportDisplayResultsMode: displayType,
			selectedTests: selectedTests,
			zip: zip,
			studentIDs: selectedStudentsIDs,
		};

		const requestData = {
			userID: currentUser.userID,
			userType: currentUser.userType,
			districtID: currentUser.districtID,
			schoolID: currentUser.schoolID,
			globaSchoolYearID: currentUser.globalSchoolYearID,
			requestReportModel: requestReportModel,
		};

		const controller = '/v2/modules/pages/student-progress';
		const apprxPagesNum = selectedStudentsIDs.length;
		const backgroundLoading = zip && apprxPagesNum > 30;

		if (backgroundLoading){
			this.httpClient.ESGIApi.post<{reportGuid: string}>(controller, 'start-background-generation', requestData)
				.subscribe((resp) => {
					const args: BackgroundDownloadManagerEvents.StartArgs = {
						reportGuid: resp.reportGuid,
						fileType: 'ZIP',
						displayRemainingText: true,
					};
					EventBusDispatcher.dispatch(BackgroundDownloadManagerEvents.Start, args);
				});
		} else{
			this.httpClient.ESGIApi.file<ReportData>(controller, 'download-pdf', filename, requestData).subscribe();
		}

		const eventType = zip ? 'PDF Bulk' : 'PDF Standard';
		SsoTracker.trackEvent({
			trackingEvent: eventType,
			data: {reportType: 'Progress'},
		});
	}

	private setMarkingPeriods(trackDates: TrackDatesModel[]) {
		const markingPeriods: MarkingPeriod[] = [
			{
				index: 0,
				title: 'B',
			},
			...trackDates.map<MarkingPeriod>((_, index) => ({
				index: index + 1,
				title: `${index + 1}`,
			})),
		];

		this.markingPeriods$.next(markingPeriods);
	}

	private getSubjectInfoForRequest(subjectID: SubjectTab['id']): SubjectInfoForRequest {
		const subject = this.subjectService.get(subjectID);

		return {
			subjectID: subject?.id ?? 0,
			subjectLevel: subject?.level ?? SubjectLevel.Teacher,
			subjectType: subject?.type ?? SubjectType.Personal,
		};
	}

	private getRequestReportModel({
		reportSettings,
		subjectID,
		subjectLevel,
		subjectType,
		studentIDs,
		testIDs,
		reportDisplayResultsMode,
	}: {
		reportSettings: ReportSettingsValue;
		subjectID: SubjectTab['id'];
		subjectLevel: SubjectLevel;
		subjectType: SubjectType;
		studentIDs: Student['id'][];
		testIDs: Test['id'][];
		reportDisplayResultsMode: ReportDisplayResultsMode;
		zip?: boolean;
	}): RequestReportModel {

		let selectedTests: TestModel[] = [];
		if (subjectID === 0) {
			this.subjectService
				.subjects$
				.value
				.forEach(({id, level, tests, type}) => {
					tests
						.forEach(test => {
							if (testIDs.includes(test.id)) {
								selectedTests.push({
									testID: test.id,
									subjectID: id ?? 0,
									subjectType: type ?? SubjectType.Personal,
									subjectLevel: level ?? SubjectLevel.Teacher,
								});
							}
						});
				});
		} else {
			const selectedSubject = this.subjectService
				.subjects$
				.value
				.find(subject => subject.id === subjectID);

			selectedTests = selectedSubject
				.tests
				.filter(t => testIDs.some(id => id === t.id))
				.map<TestModel>(test => {
					return {
						testID: test.id,
						subjectID: selectedSubject?.id ?? 0,
						subjectType: selectedSubject?.type ?? SubjectType.Personal,
						subjectLevel: selectedSubject?.level ?? SubjectLevel.Teacher,
					};
				});
		}

		return {
			carryScoresForward: reportSettings.carryScoresForward,
			displayZeroIfNotTested: reportSettings.displayZeroIfNotTested,
			showGradeColors: reportSettings.showGradeColors,
			subjectID,
			subjectType,
			subjectLevel,
			studentIDs,
			selectedTests,
			reportDisplayResultsMode,
			zip: false,
		};
	}
}
