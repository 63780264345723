import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const ChartIconWrapper = styled(FlexBox, {
	marginRight: 20,
	'& svg': {
		marginRight: 8,
	},
});

