import {CounterBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {OneLinedText} from '@esgi/main/kits/common';

export const InlineText = styled(OneLinedText, {
	color: '$base',

	variants: {
		hovered: {
			true: {
				color: '$secondary',
			},
		},

		selected: {
			true: {},
		},
	},

	compoundVariants: [
		{
			selected: true,
			hovered: false,
			css: {
				color: '$primary',
			},
		},
	],
});

export const Counter = styled(CounterBox, {
	backgroundColor: '$surface',
	borderColor: '$border',
	color: '$mediumContrast',

	variants: {
		hovered: {
			true: {
				backgroundColor: '$secondarySurface',
				borderColor: '$secondaryMuted',
				color: '$secondary',
			},
		},

		selected: {
			true: {},
		},
	},

	compoundVariants: [
		{
			selected: true,
			hovered: false,
			css: {
				backgroundColor: '$primarySurface',
				borderColor: '$primaryMuted',
				color: '$primaryVivid',
			},
		},
	],
});
