import moment, {Moment} from 'moment';
import {ResponseModels} from '../api/models';
import {SubjectType} from '@esgi/core/enums';

export namespace Models {

    export class InitModel {
    	constructor(public selectedGroupItemID?: number,
                    public selectedStudentID?: number,
                    public subjectID?: number,
                    public subjectType?: SubjectType,
                    public selectedTestID?: number,
    	) {
    	}
    }

    export class Init {
        user: User;
        subjects: SubjectModel[];
        groupItems: GroupItem[];
        selectedGroupItem: GroupItem;
        selectedSubjectId: number;
        hasGradeScales: boolean;
        readOnly: boolean;
        testResultsCorrectVerbiage: string;
        testResultsIncorrectVerbiage: string;
        tracks: Track[];

        public static FromResponse(response: ResponseModels.Settings.Init) {
        	let model = new Init();

        	model.user = User.FromResponse(response.user);
        	model.subjects = SubjectModel.FromResponseArray(response.subjects);
        	model.groupItems = GroupItem.FromResponseArray(response.groupItems);
        	model.selectedGroupItem = model.groupItems && model.groupItems.filter(c => c.id === response.selectedGroupItemID)[0] || null;
        	model.hasGradeScales = response.hasGradeScales;
        	model.readOnly = response.readOnly;
        	model.testResultsCorrectVerbiage = response.testResultsCorrectVerbiage;
        	model.testResultsIncorrectVerbiage = response.testResultsIncorrectVerbiage;
        	model.tracks = Tracks.FromResponseArray(response.tracks);

        	return model;
        }
    }

    export class GroupItem {
        id: number;
        name: string;
        itemType: string;
        selectedStudent: Student;
        students: Student[];

        get fullName(): string {

        	let cleanName = this.name.toLowerCase();
        	let result = this.name;

        	if (cleanName.indexOf(this.itemType.toLowerCase()) === -1) {
        		result += ' ' + (this.itemType === 'SpecialistGroup' ? 'Group' : this.itemType);
        	}

        	return result;
        }

        get itemTypeName(): string {
        	return this.itemType + ': ';
        }

        public static FromResponse(response: ResponseModels.Settings.GroupItem) {
        	let model = new GroupItem();
        	model.id = response.id;
        	model.name = response.name;
        	model.itemType = response.itemType;
        	model.students = Student.FromResponseArray(response.students);

        	if (model.students) {
        		model.selectedStudent = model.students.filter(s => s.studentId === response.selectedStudentID)[0];

        		if (!model.selectedStudent) {
        			model.selectedStudent = model.students[0];
        		}
        	}

        	return model;
        }

        public static FromResponseArray(response: ResponseModels.Settings.GroupItem[]) {

        	return response ? response.map(s => GroupItem.FromResponse(s)) : null;
        }
    }

    export class Student {
        studentId: number;
        firstName: string;
        lastName: string;
        languageId: number;
        gradeLevelId: number;

        get fullName(): string {

        	let result = '';

        	if (this.firstName) {
        		result += this.firstName + ' ';
        	}

        	if (this.lastName) {
        		result += this.lastName;
        	}

        	return result;
        }

        public static FromResponse(response: ResponseModels.Settings.Student) {
        	let model = new Student();
        	model.studentId = response.studentID;
        	model.firstName = response.firstName;
        	model.lastName = response.lastName;
        	model.languageId = response.languageID;
        	model.gradeLevelId = response.gradeLevelID;

        	return model;
        }

        public static FromResponseArray(response: ResponseModels.Settings.Student[]) {

        	let results = [this.AllStudentsModel()];

        	let students = response.map(s => Student.FromResponse(s));

        	return results.concat(students);
        }

        public static AllStudentsModel() {

        	let result = new Student();

        	result.studentId = 0;
        	result.firstName = 'All';

        	return result;
        }
    }

    export class User {
        userId: number;
        firstName: string;
        lastName: string;
        title: string;

        get fullName(): string {

        	let result = '';

        	if (this.title) {
        		result += this.title + ' ';
        	}

        	if (this.lastName) {
        		result += this.lastName;
        	}

        	return result;
        }

        public static FromResponse(response: ResponseModels.Settings.User) {
        	let model = new User();
        	model.userId = response.userID;
        	model.firstName = response.firstName;
        	model.lastName = response.lastName;
        	model.title = response.title;

        	return model;
        }
    }

    export class SubjectModel {
        id: number;
        name: string;
        subjectType: string;
        level: string;

        public static FromResponse(response: ResponseModels.Subject) {
        	let model = new SubjectModel();
        	model.name = response.name;
        	model.id = response.subjectID;
        	model.subjectType = response.subjectType;
        	model.level = response.level;

        	return model;
        }

        public static FromResponseArray(response: ResponseModels.Subject[]) {
        	return response && response.map(s => SubjectModel.FromResponse(s)) || [];
        }
    }

    export class Test {
        id: number;
        name: string;
        correct: number;
        incorrect: number;
        untested: number;
        questionsCount: number;
        dataExists: boolean;

        selected: boolean;
        printItems: number;
        currentDate: string;
        allPrintSelected: boolean = true;

        max: number;
        disabled: boolean;

        orderNum: number;

        public static FromResponse(response: ResponseModels.Settings.Test): Test {
        	let model = new Test();
        	model.id = response.id;
        	model.name = response.name;
        	model.correct = response.correct;
        	model.incorrect = response.incorrect;
        	model.untested = response.untested;
        	model.questionsCount = response.questionsCount;
        	model.dataExists = response.dataExists;
          model.orderNum = response.orderNum;
        	model.currentDate = response.testDate ? moment(response.testDate).format(Models.Date.defaultFormat) : null;
        	return model;
        }

        public static FromResponseArray(response: ResponseModels.Settings.Test[]): Test[] {
        	return response ? response.map(s => Test.FromResponse(s)) : [];
        }
    }

    export interface IFooterData {
        buttonDisabled: () => boolean;
    }

    export class Tracks {
    	public static FromResponseArray(response: TrackModel[]): Track[] {
    		if (response) {
    			return response.map((t) => Track.FromResponse(t));
    		} else {
    			return [];
    		}
    	}
    }

    export class Track {
        trackID: number;
        dateTo: Moment;

        public static FromResponse(response: ResponseModels.Settings.Track): Track {
        	const track = new Track();
        	track.dateTo = moment(response.dateTo);
        	track.trackID = response.trackID;
        	return track;
        }
    }
    export class TrackModel {
        trackID: number;
        dateTo: string;
    }


    export class Date {
        public static readonly defaultFormat: string = 'MM-DD-YY';
        public static readonly currentDate: string = moment().format(Date.defaultFormat)
    }

    export class ReloadTestsModel{
        subjectChanged: boolean;
        groupChanged: boolean;

        subject: SubjectModel;
        groupItem: Models.GroupItem;
        student: Student;
        date: string;
        includeSessionOption: boolean;
    }

  export enum SortBy {
    TestOrder = 0,
    Alphabetical = 1
  }
}
