import {useState, useCallback, Suspense, Fragment} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {IepGoalFormModal} from 'modules/forms/iep-goal-form';
import {SsoTracker} from '@esgi/core/tracker';
import {tryCall} from '@esgillc/ui-kit/utils';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import styles from './styles.module.less';

interface Props {
	hierarchy: HierarchySnapshot;
	subject: Subject;
	testID: number;
	onClick?: () => void | boolean;
}

export const AddIEPGoalButton = (props: Props): JSX.Element => {
	const {hierarchy, subject, testID} = props;
	const [isOpen, setIsOpen] = useState(false);

	const onClick = useCallback(() => {
		if (tryCall(props.onClick) === false) {
			return;
		}
		SsoTracker.trackEvent({
			trackingEvent: 'IepGoalsForm',
		});
		setIsOpen(true);
	}, [props.onClick]);

	return (
		<>
			<Buttons.Text
				className={styles.add}
				onClick={onClick}
			>
				Add IEP Goal
			</Buttons.Text>
			{isOpen &&
				<Suspense fallback={<Fragment/>}>
					<IepGoalFormModal
						hierarchy={hierarchy}
						subject={subject}
						testID={testID}
						onClose={() => setIsOpen(false)}
					/>
				</Suspense>
			}
		</>
	);
};

export default AddIEPGoalButton; // for lazy import
