import {SubjectType} from '@esgi/core/enums';
import {userStorage, UserType} from '@esgi/core/authentication';

export interface SubjectTabResponse {
	name: string;
	subjectType: string;
	level: string;
	id: number;
	selected: boolean;
	hidden: boolean;
	isPublished: boolean;
	canEdit: boolean;
	testsCount: number;
	gradeLevels: number[];
}

export interface LoadResponse {
	subjects: SubjectTabResponse[];
	deployedSubjects: SubjectTabResponse[];
	stockSubjects: SubjectTabResponse[];
	selectedSubject: SubjectTabResponse;
}

export class SubjectModel {

	public static FromResponse(model: SubjectTabResponse): SubjectModel {
		const subject = new SubjectModel();
		subject.hidden = model.hidden;
		subject.id = model.id;
		subject.level = model.level;
		subject.name = model.name;
		subject.selected = model.selected;
		subject.type = SubjectType[model.subjectType];
		subject.published = model.isPublished;
		subject.canEdit = this.canEdit(subject);
		subject.testsCount = model.testsCount;
		subject.gradeLevels = model.gradeLevels || [];

		return subject;
	}

	private static canEdit(subject: SubjectModel) {
		const userType = userStorage.get().userType;
		if (subject.type === SubjectType.Personal) {
			return [UserType.T, UserType.ISS, UserType.ISD, UserType.PA].indexOf(userType) !== -1;
		}

		if (subject.type === SubjectType.Deployed) {
			if (subject.level === 'School') {
				return userType == UserType.C;
			}

			if (subject.level === 'District') {
				return userType == UserType.D;
			}
		}

		return false;
	}

	name: string;
	type: SubjectType;
	level: string;
	id: number;
	selected: boolean;
	hidden: boolean;
	published: boolean;
	canEdit?: boolean = false;
	testsCount: number;
	gradeLevels: number[];
}

export interface SubjectInfo {
	id: number;
	type: SubjectType;
}
