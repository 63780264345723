import React, {Dispatch} from 'react';
import {Checkbox, Radio} from '@esgillc/ui-kit/control';
import {isIpad} from '@esgillc/ui-kit/utils';
import {FiltersContentBlock, RadioStyled, ReportFilterLabel} from '../../index.styled';
import {FlexBox} from '@esgi/ui/layout';
import {LabelStyled, ReportsFiltersContainer} from './index.styled';
import {ReportDisplayResultsMode} from '../../../../../../types';

interface Props {
	reportDisplayResultsMode: ReportDisplayResultsMode;
	changeDisplayResultsMode: Dispatch<ReportDisplayResultsMode>
	hasGradeScales: boolean;
	showGradeColors: boolean;
	changeShowGradeColors: Dispatch<boolean>
}

export function ShowFilter(props: Props) {
	const {reportDisplayResultsMode, hasGradeScales, showGradeColors, changeShowGradeColors, changeDisplayResultsMode} = props;
	return <ReportsFiltersContainer dataCy='show-filter-container'>
			<FlexBox>
				<ReportFilterLabel>Show:</ReportFilterLabel>
			</FlexBox>
			<FiltersContentBlock>
				<FlexBox direction='column'>
					<FiltersContentBlock isIpad={isIpad()}>
						<RadioStyled value={ReportDisplayResultsMode.Score}
							id='displayResultsModeScore'
							checked={reportDisplayResultsMode === ReportDisplayResultsMode.Score}
							onChange={() => changeDisplayResultsMode(ReportDisplayResultsMode.Score)}
							data-cy='score-option'
						>
							<LabelStyled selected={reportDisplayResultsMode === ReportDisplayResultsMode.Score}>
								Score
							</LabelStyled>
						</RadioStyled>
						<RadioStyled value={1}
							id='displayResultsModePercent'
							checked={reportDisplayResultsMode === ReportDisplayResultsMode.Percent}
							onChange={() => changeDisplayResultsMode(ReportDisplayResultsMode.Percent)}
							data-cy='percent-option'
						>
							<LabelStyled selected={reportDisplayResultsMode === ReportDisplayResultsMode.Percent}>
								Percent
							</LabelStyled>
						</RadioStyled>
						<RadioStyled value={2}
							id='displayResultsModeGrade'
							disabled={!hasGradeScales}
							checked={reportDisplayResultsMode === ReportDisplayResultsMode.Grade}
							onChange={() => changeDisplayResultsMode(ReportDisplayResultsMode.Grade)}
							data-cy='grade-option'
						>
							<LabelStyled selected={reportDisplayResultsMode === ReportDisplayResultsMode.Grade} inactive={!hasGradeScales}>
								Grade
							</LabelStyled>
						</RadioStyled>
					</FiltersContentBlock>
					<FiltersContentBlock>
						{reportDisplayResultsMode === ReportDisplayResultsMode.Grade &&
							<Checkbox
								data-cy='show-grade-colors-checkbox'
								id='showGradeColors'
								checked={showGradeColors}
								onChange={() => changeShowGradeColors(!showGradeColors)}
							>
								<LabelStyled selected={showGradeColors}>In Color</LabelStyled>
							</Checkbox>
						}
					</FiltersContentBlock>
				</FlexBox>
			</FiltersContentBlock>
		</ReportsFiltersContainer>;
}
