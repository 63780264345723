import {TestColorIndicator} from '@esgi/main/kits/common';
import {PropsWithChildren} from 'react';
import {TestType} from '@esgi/main/libs/core';
import {ChildrenBox, Container, TestInfoBox} from './index.styled';
import {OneLinedText, TestTypeLabel} from '../../../kit';

type Props = PropsWithChildren<{
	name: string;
	testID: number;
	type: TestType;
}>;

export function TestName({name, testID, type, children}: Props) {
	return (
		<Container>
			<TestInfoBox>
				<TestColorIndicator testID={testID} />
				<OneLinedText size='xLarge' color='base' data-cy='test-name'>
					{name}
				</OneLinedText>
				<TestTypeLabel testType={type} />
			</TestInfoBox>

			{children && <ChildrenBox>{children}</ChildrenBox>}
		</Container>
	);
}
