import {SortTable} from '@esgi/ui/icons';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {SortDirection} from '../types';

export const ArrowsIcon = styled(SortTable);

export const SortableCell = styled(GridBox, {
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	gap: 2,
	alignItems: 'center',
	userSelect: 'none',

	[`& > ${Text}`]: {
		transition: 'сolor .3s',
	},

	[`& > ${ArrowsIcon}`]: {
		'& path': {
			transition: 'fill .3s',
		},
	},

	variants: {
		sortDirection: {
			[SortDirection.Asc]: {},

			[SortDirection.Desc]: {},
		},

		sortable: {
			true: {
				cursor: 'pointer',

				'&:hover': {
					[`& > ${Text}`]: {
						color: '$secondary',
					},
				},
			},
		},

		isActive: {
			true: {
				[`& > ${Text}`]: {
					color: '$primary',
				},
			},

			false: {
				[`& > ${Text}`]: {
					color: '$lowContrast',
				},

				[`& > ${ArrowsIcon}`]: {
					'& path:first-child': {
						fill: '$muted',
					},

					'& path:last-child': {
						fill: '$muted',
					},
				},
			},
		},
	},

	compoundVariants: [
		{
			isActive: true,
			sortDirection: SortDirection.Asc,
			sortable: true,
			css: {
				'&:hover': {
					[`& > ${ArrowsIcon}`]: {
						'& path:last-child': {
							fill: '$secondary',
						},
					},
				},

				[`& > ${ArrowsIcon}`]: {
					'& path:first-child': {
						fill: '$muted',
					},

					'& path:last-child': {
						fill: '$primary',
					},
				},
			},
		},

		{
			isActive: true,
			sortDirection: SortDirection.Desc,
			sortable: true,
			css: {
				'&:hover': {
					[`& > ${ArrowsIcon}`]: {
						'& path:first-child': {
							fill: '$secondary',
						},
					},
				},

				[`& > ${ArrowsIcon}`]: {
					'& path:first-child': {
						fill: '$primary',
					},

					'& path:last-child': {
						fill: '$muted',
					},
				},
			},
		},
	],
});
