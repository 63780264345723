import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Dropdown, OnErrorTooltip, Option, Label} from '@esgillc/ui-kit/form-control';
import {TestModel} from '../../models';
import styles from '../styles.module.less';

type Props = {
	control: IFormControl;
	tests: TestModel[];
}

export const TestDropdown = ({control, tests}: Props) => (
	<FormElement
		className={styles.formElement}
		control={control}
	>
		<Label className={styles.label}>Test Name</Label>
		<div className={styles.field}>
			<Dropdown
				placeholder='Select a test...'
				className={styles.dropDownField}
				optionName='name'
				tabIndex={0}
			>
				{tests.map((v, i) => <Option key={i} value={v}>{v.name}</Option>)}
			</Dropdown>
			<OnErrorTooltip
				showOnError='required'
				placement='left'
			>
				Please select a test...
			</OnErrorTooltip>
		</div>
	</FormElement>
);
