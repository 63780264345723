import {resolvedPromise} from '@esgi/deprecated/utils';
import {ServerClient} from 'pages/landing/kit/server-client';
import {Form, FormField, Validators} from '@esgi/deprecated/knockout';
import {InitResponse} from './types';

export class ImportRegistrationFinishForm extends Form {
	userTitle: FormField;
	firstName: FormField;
	lastName: FormField;
	country: FormField;
	state: FormField;
	password: FormField;
	confirmPassword: FormField;
	userName: FormField<string>;
	email: FormField;
	agreementAccepted: FormField;
	track: FormField<any>;

	tracks = ko.observableArray([]);
	userNameFromServer: string;

	constructor() {
		super();

		this.userTitle = this.createField('Ms.');
		this.firstName = this.builders.firstName();
		this.lastName = this.builders.lastName();
		this.country = this.builders.country();
		this.state = this.builders.state();
		this.password = this.builders.password();
		this.confirmPassword = this.builders.confirmPassword();
		this.userName = this.builders.userName();
		this.email = this.builders.email();
		this.track = this.createField(null);
		this.agreementAccepted = this.builders.agreementAccepted();
	}

	builders = {
		agreementAccepted: () => {
			let f = this.createField<boolean>(false, Validators.Required('Please accept agreement'));
			f.validation.errorPosition = 'left';

			return f;
		},
		firstName: () => {
			let f = this.createField('', Validators.Required());
			f.validation.errorValidation(true);
			f.validation.successValidation(true);
			return f;
		},
		lastName: () => {
			let f = this.createField('', Validators.Required());
			f.validation.errorValidation(true);
			f.validation.successValidation(true);
			return f;
		},
		userTitle: () => {
			let f = this.createField('Ms.');
			return f;
		},
		country: () => {
			let f = this.createField('');
			f.disabled(true);
			return f;
		},
		state: () => {
			let f = this.createField('');
			f.disabled(true);
			return f;
		},
		password: () => {
			let f = this.createField('',
				Validators.Required(),
				Validators.Length(6, null, 'Password must be at least 6 characters length'));
			f.validation.errorValidation(true);
			f.validation.successValidation(true);
			return f;
		},
		confirmPassword: () => {
			let matchPasswordValidator = Validators.Custom(field => {
				if (field.value() !== this.password.value()) {
					return resolvedPromise(false);
				}

				return resolvedPromise(true);
			}, _ => 'Passwords do not match');
			let f = this.createField('', Validators.Required(), matchPasswordValidator);
			f.validation.errorValidation(true);
			f.validation.successValidation(true);
			return f;
		},
		userName: () => {
			let uniqueUserNameValidator = Validators.Ajax((field: FormField<string>) => {
				return ServerClient.SSOAPI.Get<{ exist: boolean }>('Registration/Validations', 'UserName', {data: {userName: field.value()}});
			}, result => {
				if (this.userNameFromServer == this.userName.value()) {
					return '';
				}
				if (result.exist) {
					return 'User name is used. Please choose another one';
				}
			});

			let f = this.createField('',
				Validators.Required(),
				Validators.Length(3, null, 'User name must be at least 3 characters long'),
				Validators.Regex(/^[A-Za-z0-9@\._-]{3,40}$/, 'A username can only contain letters A-Z, numbers 0-9, @ signs, periods and underscores'),
				uniqueUserNameValidator);

			f.validation.showValidation(true);
			f.validation.successValidation(true);

			return f;
		},
		email: () => {
			let f = this.createField('', Validators.Required(), Validators.Email('Invalid email'));
			f.validation.showValidation(true);
			f.validation.successValidation(true);

			return f;
		},
	};


	fill(data: InitResponse) {
		if (data.email) {
			this.email.value(data.email);
		}

		if (data.firstName) {
			this.firstName.value(data.firstName);
		}

		if (data.lastName) {
			this.lastName.value(data.lastName);
		}

		if (data.title) {
			this.userTitle.value(data.title);
		}

		if (data.userName) {
			this.userNameFromServer = data.userName;
			this.userName.value(data.userName);
		}

		if (data.countryName) {
			this.country.value(data.countryName);
		}

		if (data.stateName) {
			this.state.value(data.stateName);
		}


		this.tracks(data.tracks);
		this.track.value(this.tracks[0]);
	}
}
