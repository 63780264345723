import {PropsWithChildren} from 'react';
import styles from './styles.module.less';

export function FiltersContainer({children}: PropsWithChildren) {
	return (
		<div className={styles.container}>
			<div className={styles.rowLabel}>Filter by:</div>
			{children}
		</div>
	);
}
