import {useCallback, useEffect, useState} from 'react';
import {
	Drawer,
	DrawerMode,
	useDrawerMode,
	useDrawerParameters,
} from '@esgi/main/features/teacher/home';
import {EditClassFormParams, TeacherDrawerName} from '../types';
import {AddClass} from './components/add-class';
import {EditClass} from './components/edit-class';
import {NoChangesConfirmation} from './components/no-changes-confirmation';
import {Student} from './types';

export * from './events';

interface Props {
	onAddStudentByRef?: VoidFunction;
}

export function DrawerWithClassForm(props: Props) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isUnsavedChangesConfirmation, setIsUnsavedChangesConfirmation] =
		useState(false);

	const drawerMode = useDrawerMode({
		drawerName: TeacherDrawerName.TeacherClass,
	});
	const [selectedStudentIds, setSelectedStudentIds] = useState<Student['id'][]>(
		[]
	);

	const teacherClassDrawerMode = useDrawerMode({
		drawerName: TeacherDrawerName.TeacherClass,
	});

	const teacherStudentViaProfileDrawerMode = useDrawerMode({
		drawerName: TeacherDrawerName.TeacherStudentViaProfile,
	});

	const {classId, schoolYear} = useDrawerParameters<EditClassFormParams>([
		'classId',
		'schoolYear',
	]);

	const onBeforeCloseDrawerCb = useCallback(() => {
		if (isFormTouched) {
			setIsUnsavedChangesConfirmation(true);
		}

		return !isFormTouched;
	}, [isFormTouched]);

	const onCloseUnsavedChangesConfirmation = useCallback(() => {
		setIsUnsavedChangesConfirmation(false);
	}, []);

	useEffect(() => {
		if (!teacherClassDrawerMode && !teacherStudentViaProfileDrawerMode) {
			setSelectedStudentIds([]);
		}
	}, [teacherClassDrawerMode, teacherStudentViaProfileDrawerMode]);

	return (
		<Drawer
			drawerName={TeacherDrawerName.TeacherClass}
			skeleton={!isLoaded}
			beforeCloseDrawerCb={onBeforeCloseDrawerCb}
			dataCy='drawer-with-class'
		>
			{drawerMode === DrawerMode.Create && (
				<AddClass
					onLoaded={setIsLoaded}
					onFormTouched={setIsFormTouched}
					onAddStudentByRef={props.onAddStudentByRef}
					selectedStudentIds={selectedStudentIds}
					setSelectedStudentIds={setSelectedStudentIds}
				/>
			)}
			{drawerMode === DrawerMode.Edit && classId && (
				<EditClass
					onLoaded={setIsLoaded}
					classID={Number(classId)}
					onFormTouched={setIsFormTouched}
					schoolYear={Number(schoolYear)}
					onAddStudentByRef={props.onAddStudentByRef}
				/>
			)}

			<NoChangesConfirmation
				isUnsavedChangesConfirmation={isUnsavedChangesConfirmation}
				onClose={onCloseUnsavedChangesConfirmation}
			/>
		</Drawer>
	);
}
