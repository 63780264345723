import {useUser} from '@esgi/core/authentication';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import {Fragment, Suspense} from 'react';
import {TestType} from '@esgi/core/enums';
import {Subject} from 'shared/modules/test/test-session-details/types';
import {lazyComponent} from '@esgi/core/react';
import {HierarchySnapshot} from 'modules/hierarchy/models';

const RubricTesting = lazyComponent(() => import('../../rubric/testing/root'));
const ScoreTesting = lazyComponent(() => import('./score'));
const YesNoTesting = lazyComponent(() => import('./yes-no'));

type Props = {
	testType: TestType;
	studentID: number;
	testID: number;
	classID: number;
	questionIds?: number[];
	hierarchy: HierarchySnapshot;
	subject?: Subject;
	onClosed: () => void;
	onFinished: () => void;
	onFlashCardsClicked: () => void;
	onTestHistoryClicked: () => void;
	onTestSessionDetailsClicked: () => void;
}

export function TestLauncher(props: Props) {
	const user = useUser();

	return (
		<Switch test={props.testType}>
			<Case value={TestType.YN}>
				<Suspense fallback={<Fragment/>}>
					<YesNoTesting
						testID={props.testID}
						classID={props.classID}
						studentID={props.studentID}
						questionIds={props.questionIds}
						hierarchy={props.hierarchy}
						subject={props.subject}
						onFlashCardsClicked={props.onFlashCardsClicked}
						onTestHistoryClicked={props.onTestHistoryClicked}
						onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
						onClose={props.onClosed}
					/>
				</Suspense>
			</Case>
			<Case value={TestType.Score}>
				<Suspense fallback={<Fragment/>}>
					<ScoreTesting
						testID={props.testID}
						studentID={props.studentID}
						hierarchy={props.hierarchy}
						subject={props.subject}
						onTestHistoryClicked={props.onTestHistoryClicked}
						onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
						onClosed={props.onClosed}
					/>
				</Suspense>
			</Case>
			<Case value={TestType.Rubric}>
				<Suspense fallback={<Fragment/>}>
					<RubricTesting
						userID={user.userID}
						testID={props.testID}
						classID={props.classID}
						studentID={props.studentID}
						hierarchy={props.hierarchy}
						subject={props.subject}
						onClosed={props.onClosed}
						onFinished={props.onFinished}
						onTestHistoryClicked={props.onTestHistoryClicked}
						onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
					/>
				</Suspense>
			</Case>
		</Switch>
	);
}
