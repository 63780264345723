import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {ProfileTrackRange} from '../track-range';
import React, {useState} from 'react';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {ProfileTrackAddEdit} from '../track-add-edit';
import {Buttons} from '@esgillc/ui-kit/button';
import {ProfileTrackTypePeriod, SchoolYearTypeModel, TrackModel} from '../../types';
import {TrackInfoFormType} from '../../forms/track-info';
import styles from '../../profiles/teacher/styles.module.less';

interface Props {
	periods: ProfileTrackTypePeriod[]
	form: TrackInfoFormType
	isAbleToAddEdit: boolean
	tracks: TrackModel[]
	schoolYearTypes: SchoolYearTypeModel[]
	districtID: number
}

export function TrackForm({periods, form, isAbleToAddEdit, tracks, schoolYearTypes, districtID}: Props) {
	const [isAddEditTrackInfoOpened, setAddEditTrackInfoOpened] = useState(false);
	return (
		<div data-cy={'track-form'}>
			<Form controller={form}>
				<div className={styles.row}>
					<FormElement control={form.controls.track}>
						<div data-cy={'track-dropdown'}>
							<div className={styles.addEditLabel}>
								<label htmlFor='add-edit-track' className={styles.label}>Track Name</label>
								{isAbleToAddEdit
									&& <Buttons.Link className={styles.linkLabel} onClick={() => {
										setAddEditTrackInfoOpened(true);
									}}>Add/edit track</Buttons.Link>}
							</div>
							<Dropdown optionName={'name'}>
								{tracks.map((item) => <Option key={item.trackID} value={item}>{item.name}</Option>)}
							</Dropdown>
						</div>
					</FormElement>
					<FormElement control={form.controls.type}>
						<div data-cy={'type-dropdown'}>
							<div>
								<label className={styles.label}>Track Type</label>
								<Dropdown optionName={'name'}>
									{!!form.controls.type.value[0] && <Option value={form.controls.type.value[0]}>
										{form.controls.type.value[0].name}
									</Option>}
								</Dropdown>
							</div>
							<ProfileTrackRange
								periods={form.controls.track.value[0] && form.controls.type.value[0] ? periods : []}
								name={form.controls.type.value[0] ? form.controls.type.value[0].name : ''}
							/>
						</div>
					</FormElement>
				</div>
				{isAddEditTrackInfoOpened
					&& <ProfileTrackAddEdit
						selectedTrack={form.controls.track.value[0]}
						districtID={districtID}
						tracks={tracks}
						schoolYearTypes={schoolYearTypes}
						onClose={() => {
							setAddEditTrackInfoOpened(false);
						}}
					/>
				}
			</Form>
		</div>

	);
}
