import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {deepEqual} from 'shared/utils';
import {LevelDisplayMode} from '../../../common/models';
import {DescriptionApiModel, RubricModel} from '../../../common/types';
import RubricCreatingService from '../../services/rubric-creating-service';

export default class TemplateDataService extends RubricCreatingService {
	private templateModels: RubricModel[] = [];

	public initByTest(testID: number): Observable<RubricModel> {
		return this.getRubricModel(testID).pipe(tap(t => this.initFromModel(t)));
	}

	public override save(): Observable<number> {
		this.testID = 0;
		return super.save();
	}

	public override serialize(includeMeta?: boolean): RubricModel {
		const model = super.serialize(includeMeta);
		model.id = 0;
		return model;
	}

	public isTouched(): boolean {
		const rubricModel = super.serialize();
		const initialRubricModel = this.templateModels[this.testID];
		return !deepEqual(initialRubricModel, rubricModel);
	}

	private getRubricModel(testID: number): Observable<RubricModel> {
		const cachedModel = this.templateModels[testID];
		if (cachedModel) {
			return of(cachedModel);
		}

		return this.httpClient.ESGIApi
			.get<RubricModel>(this.controller, 'init-template', {templateID: testID})
			.pipe(
				map(r => {
					r.levelDisplayType = LevelDisplayMode[r.levelDisplayType as any as string];
					r.name = '';
					r.description = '';

					const descriptions = [];
					let index = 1;
					for (let level of r.levelModels) {
						for (let criteria of r.criteriaModels) {
							descriptions.push({
								id: -index,
								description: '',
								details: '',
								levelID: level.id,
								criteriaID: criteria.id,
							} as DescriptionApiModel);
							index++;
						}
					}
					r.descriptionModels = descriptions;

					return r;
				}),
				tap(t => this.templateModels[testID] = t),
			).asObservable();
	}
}
