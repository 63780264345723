import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';

export const SaveButton = styled(Button, {
	width: '100%',
	marginTop: 'auto',
	position: 'sticky',
	bottom: 0,
});

export const TabContentRoot = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',
	gridTemplateRows: 'auto',
	gap: '$5',
});
