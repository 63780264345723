export function getInitials({
	firstName,
	lastName,
	textCase = 'toUpperCase',
}: {
	firstName: string;
	lastName: string;
	textCase?: keyof Pick<string, 'toUpperCase'>;
}) {
	return `${firstName[0]}${lastName[0]}`[textCase]();
}
