import React, {ReactNode} from 'react';
import styles from './box-title.module.less';

class Props {
	opened: boolean;
	className: string;
	onOpenCloseChanged: () => void;
	children?: ReactNode;
}

export class BoxTitle extends React.PureComponent<Props> {
	render() {
		return <div className={styles.titleContainer} onClick={() => this.props.onOpenCloseChanged()}>
			<div className={this.props.className}>{this.props.children}</div>
			{this.renderCollapseButton()}
		</div>;
	}

	private renderCollapseButton() {
		return <a href='#' className={styles.collapseButton}>
			{this.props.opened && <i className='fa fa-angle-up'/>}
			{!this.props.opened && <i className='fa fa-angle-down'/>}
		</a>;
	}
}
