import {FontFamily, FontSizeCalculationResult, LecturerType, FormatType} from '../types';
import {Typo} from './typo';

export function getLecturerName(lecturer: LecturerType | null) {
	if (lecturer === null) {
		return '';
	}
	return `${lecturer.title ? lecturer.title : ''} ${lecturer.firstName} ${lecturer.lastName}`.trim();
}

export function getNameByFormat(firstName: string, lastName: string, nameFormat: FormatType) {
	return nameFormat === FormatType.FirstAndLastName
		? `${firstName} ${lastName}`
		: firstName;
}

export function convertEnumToFontFamily(fontFamily: FontFamily) {
	switch (fontFamily) {
		case FontFamily.DottedLined: return 'AbcPrintDottedLined';
		case FontFamily.Normal: return 'AbcPrint';
		case FontFamily.Lined: return 'AbcPrintLined';
		default: return 'AbcPrint';
	}
}

export function calculateMaxPossibleFontSize(
	text: string,
	containerWidth: number,
	initialFontSize: number,
	fontFamily: string,
): FontSizeCalculationResult {
	return Typo.getMaxPossibleFontSize(
		text,
		containerWidth,
		initialFontSize,
		10,
		fontFamily,
	);
}