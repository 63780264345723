import {StudentInfo} from '../../../class-group/types';
import {useMemo} from 'react';
import {TestSession} from '../../../types';

export function useSessionsGroupedByDate(students: StudentInfo[]): Array<Record<number, TestSession[]>> {
	return useMemo(() =>
		Object.values(
			students
				.sort((a, b) => new Date(a.testSessionsInfo.testSessions[0]?.testDate).valueOf() - new Date (b.testSessionsInfo.testSessions[0]?.testDate).valueOf())
				.reduce((dates, student) => {
					student.testSessionsInfo.testSessions.forEach((item, idx, arr) => {
						const currDate = item.testDate.split('T')[0];

						if (!dates[currDate]) {
							dates[currDate] = {
								[student.id]: [item],
							};
						} else {
							if (dates[currDate][student.id]) {
								const sameDateIdx = dates[currDate][student.id]?.findIndex(session => session.testDate.split('T')[0] === currDate);

								if (sameDateIdx === -1) {
									dates[currDate][student.id] = [...dates[currDate][student.id], item];
								} else {
									dates[currDate][student.id]?.splice(sameDateIdx, 1, item);
								}
							} else {
								dates[currDate][student.id] = [item];
							}
						}
					});

					return dates;
				}, {})), [students]);
}