export enum LoadingState {
	None,
	Loading,
	Loaded,
}

export type State<T> = {
	data: T[],
	loadingState: LoadingState,
	loaded: boolean,
}