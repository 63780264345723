import {TestInfo} from '../../../../../types';
import {OpenTestingStudentParams, StudentInfo, StudentSort} from '../../../../../class-group/types';
import {GridBox} from '@esgi/ui/layout';
import {Dispatch, useCallback, useState} from 'react';
import {Checkbox, CheckboxesBox, getStudentClass} from '../../../../../kit';
import {defaultRowsLimit, rowsLimit} from '../../../constants';
import {useStudentsData} from './use-students-data';
import {TestType} from '@esgi/main/libs/core';
import {YesNoTable} from './components/yes-no-table';
import {ScoreTable} from './components/score-table';
import {RubricTable} from './components/rubric-table';
import {SessionDetailsData} from './types';
import {isNull} from 'underscore';
import {AddSessionDetails, TestSessionDetailsAlert} from '@esgi/main/features/teacher/test-session-details';
import {Student} from '@esgi/main/libs/store';
import {useTestDashboardContext} from '../../../../../layout/outlet-context';
import {useNavigateToTestDashboard} from '../../../../../utils';
import {ExportAll} from './components/export-all';

type Props = {
	testInfo: TestInfo;
	studentsInfo: StudentInfo[];
	sortStudentsBy: StudentSort;
	isTablet: boolean;
	onOpenTestingStudent: Dispatch<OpenTestingStudentParams>;
};

export function ClassEntity({testInfo, studentsInfo, sortStudentsBy, onOpenTestingStudent, isTablet}: Props) {
	const testedStudents = studentsInfo.filter(x => x.testSessionsInfo.testSessions.filter(x => !x.isDeleted).length > 0);
	const [openedTestSessionDetails, setOpenedTestSessionDetails] = useState<SessionDetailsData | null>(null);
	const [addSessionDetailsForStudentID, setAddSessionDetailsForStudentID] = useState<Student['id'] | null>(null);

	const [showOnlyUntestedStudents, setShowOnlyUntestedStudents] = useState(false);
	const [showDeletedSessions, setShowDeletedSessions] = useState(false);

	const [visibleStudentsCount, setVisibleStudentsCount] = useState(defaultRowsLimit);

	const {classId, classesList, groupId, groupsList, subjectID, testId, studentsList, subjectType} =
		useTestDashboardContext();

	const handleOpenTestDashboard = useNavigateToTestDashboard();

	const toggleShowOnlyUntestedStudents = useCallback(() => {
		setShowOnlyUntestedStudents((currentState) => !currentState);
		setShowDeletedSessions(false);
	}, []);

	const toggleshowDeletedSessions = useCallback(() => {
		setShowDeletedSessions((currentState) => !currentState);
		setShowOnlyUntestedStudents(false);
	}, []);

	const handleLoadMoreClick = useCallback(() => {
		setVisibleStudentsCount((currentVisibleCount) => currentVisibleCount + rowsLimit);
	}, []);

	const onOpenTestSessionDetailsAlert = useCallback(
		({sessionID, studentID}: {sessionID: number; studentID: Student['id']}) => {
			const studentClass = getStudentClass({
				classID: classId,
				classesList,
				groupID: groupId,
				groupsList,
				studentID,
			});

			setOpenedTestSessionDetails({
				sessionID,
				classID: studentClass?.id ?? 0,
			});
		},
		[classId, classesList, groupId, groupsList],
	);

	const onCloseTestSessionDetailsAlert = useCallback(() => {
		setOpenedTestSessionDetails(null);
	}, []);

	const onOpenAddSessionDetails = useCallback((studentID: Student['id']) => {
		setAddSessionDetailsForStudentID(studentID);
	}, []);

	const onCloseAddSessionDetails = useCallback(() => {
		setAddSessionDetailsForStudentID(null);
	}, []);

	const onOpenStudentTestDashboard = useCallback(
		(studentID: Student['id']) => {
			handleOpenTestDashboard({
				dashboardType: 'student',
				studentID,
				subjectID,
				subjectType,
				testID: testId,
				studentFromClassID: classId === -1 ? null : classId,
				studentFromGroupID: groupId,
			});
		},
		[classId, groupId, handleOpenTestDashboard, subjectID, subjectType, testId],
	);

	const {visibleStudents, notVisibleStudentsCount, hasDeletedSessions} = useStudentsData({
		sortStudentsBy,
		studentsInfo,
		visibleStudentsCount,
		showOnlyUntestedStudents,
		showDeletedSessions,
	});

	const handleOpenTestingStudent = useCallback(
		(studentID: Student['id']) => {
			const selectedStudentSessions =
				visibleStudents.find(({id}) => id === studentID)?.testSessionsInfo.testSessions ?? [];

			const studentSessionsLength = selectedStudentSessions.length;

			const studentSessionsDuration = selectedStudentSessions.reduce(
				(currentDuration, {duration}) => (currentDuration += duration),
				0,
			);

			onOpenTestingStudent({
				studentID,
				lastTestSession: selectedStudentSessions[0] ?? null,
				penultimateTestSession: selectedStudentSessions[1] ?? null,
				studentSessionsCount: studentSessionsLength,
				averageStudentSessionsDuration: studentSessionsLength ? studentSessionsDuration / studentSessionsLength : 0,
			});
		},
		[onOpenTestingStudent, visibleStudents],
	);

	const getTableByTestType = () => {
		switch (testInfo.testType) {
			case TestType.YN:
				return (
					<YesNoTable
						testInfo={testInfo}
						students={visibleStudents}
						notVisibleStudentsCount={notVisibleStudentsCount}
						onLoadMoreClick={handleLoadMoreClick}
						isTablet={isTablet}
						onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
						onOpenAddSessionDetailsAlert={onOpenAddSessionDetails}
						onOpenTestingStudent={handleOpenTestingStudent}
						onOpenStudentTestDashboard={onOpenStudentTestDashboard}
					/>
				);
			case TestType.Rubric:
				return (
					<RubricTable
						testInfo={testInfo}
						students={visibleStudents}
						notVisibleStudentsCount={notVisibleStudentsCount}
						onLoadMoreClick={handleLoadMoreClick}
						isTablet={isTablet}
						onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
						onOpenAddSessionDetailsAlert={onOpenAddSessionDetails}
						onOpenTestingStudent={handleOpenTestingStudent}
						onOpenStudentTestDashboard={onOpenStudentTestDashboard}
					/>
				);
			case TestType.Score:
				return (
					<ScoreTable
						testInfo={testInfo}
						students={visibleStudents}
						notVisibleStudentsCount={notVisibleStudentsCount}
						onLoadMoreClick={handleLoadMoreClick}
						isTablet={isTablet}
						onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
						onOpenAddSessionDetailsAlert={onOpenAddSessionDetails}
						onOpenTestingStudent={handleOpenTestingStudent}
						onOpenStudentTestDashboard={onOpenStudentTestDashboard}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<GridBox gapY='4'>
				<GridBox justify='between' align='end' flow='column'>
					<CheckboxesBox>
						<Checkbox
							checked={showOnlyUntestedStudents}
							onCheckedChange={toggleShowOnlyUntestedStudents}
							label='Show Only Untested Students'
							selected={showOnlyUntestedStudents}
							dataCy='show-only-untested-checkbox'
						/>
						{hasDeletedSessions && (
							<Checkbox
								checked={showDeletedSessions}
								onCheckedChange={toggleshowDeletedSessions}
								label='Show Deleted Sessions'
								selected={showDeletedSessions}
								dataCy='show-deleted-sessions'
							/>
						)}
					</CheckboxesBox>
					{testInfo.testType != TestType.Rubric && testedStudents.length > 0 &&
						<ExportAll
							testInfo={testInfo}
							students={testedStudents}
							classID={classId ? classId : groupsList.filter(x => x.id == groupId)[0].classID}
							testID={testId}
						/>
					}
				</GridBox>
				{getTableByTestType()}
			</GridBox>

			{!isNull(openedTestSessionDetails) && (
				<TestSessionDetailsAlert
					testSessionID={openedTestSessionDetails.sessionID}
					onAlertClose={onCloseTestSessionDetailsAlert}
					classID={openedTestSessionDetails.classID}
					subjectID={subjectID}
				/>
			)}

			{addSessionDetailsForStudentID && (
				<AddSessionDetails
					studentID={addSessionDetailsForStudentID}
					subjectID={subjectID}
					onAlertClose={onCloseAddSessionDetails}
					testID={testId}
				/>
			)}
		</>
	);
}
