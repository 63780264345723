import {Dispatch, useCallback, useMemo} from 'react';
import {Class, Student, useClasses, useGroups, useStudents} from '@esgi/main/libs/store';
import {TestSectionContentSingleStudent} from './components/test-section-content/types';
import {isNull, isUndefined, max} from 'underscore';
import {useNavigate} from 'react-router-dom';
import {DrawerMode, TestModel} from '@esgi/main/features/teacher/home';
import {TeacherDrawerName} from '@esgi/main/features/teacher/drawers';
import {TestLauncherState} from '@esgi/main/features/assessments';
import {UserInfo, useUser} from '@esgi/core/authentication';

type ReturnType =
	| (TestSectionContentSingleStudent & {
			selectedStudentClass: Class | null;
	  })
	| null;

const getDrawerMode = (currentUser: Readonly<UserInfo>) => {
	if (
		currentUser.canEditStudents ||
		currentUser.canEditStudentLanguage ||
		currentUser.canEditStudentMetaFields ||
		currentUser.canManageClasses
	) {
		return DrawerMode.Edit;
	}
	return DrawerMode.View;
};


export function useTestSectionContentSingleStudentProps({
	selectedStudentId,
	studentFromGroupID,
	studentFromClassID,
	handleClickTestCard,
	setTestLauncherState,
}: {
	selectedStudentId: Student['id'] | null;
	studentFromClassID: number | null;
	studentFromGroupID: number | null;
	handleClickTestCard: Dispatch<TestModel>;
	setTestLauncherState: Dispatch<TestLauncherState>;
}) {
	const [{data: classList, loaded: isClassListLoaded}] = useClasses();
	const [{data: groupsList, loaded: isGroupsListLoaded}] = useGroups();
	const [{data: studentsList, loaded: isStudentsListLoaded}] = useStudents();

	const navigate = useNavigate();
	const currentUser = useUser();

	const handleStudentClicked = useCallback(
		(studentID: Student['id']) => {
			const studentProfileDrawerUrl = new URLSearchParams({
				drawerMode: getDrawerMode(currentUser),
				drawerName: TeacherDrawerName.TeacherStudentViaProfile,
				studentId: studentID.toString(),
			});
			navigate(`/home?${studentProfileDrawerUrl.toString()}`);
		},
		[currentUser, navigate],
	);

	return useMemo<ReturnType>(() => {
		const selectedStudent = studentsList.find(({id}) => selectedStudentId === id);

		if (isUndefined(selectedStudent) || !isClassListLoaded || !isGroupsListLoaded || !isStudentsListLoaded) {
			return null;
		}

		const commonProps: Pick<
			TestSectionContentSingleStudent,
			'student' | 'onStudentClick' | 'onTestCardClicked' | 'setTestLauncherState'
		> = {
			student: selectedStudent,
			onStudentClick: handleStudentClicked,
			onTestCardClicked: handleClickTestCard,
			setTestLauncherState,
		};

		if (!isNull(studentFromGroupID)) {
			const studentGroup = groupsList.find(({id}) => id === studentFromGroupID);
			const studentClass = classList.find(({id}) => studentGroup?.classID === id);

			return {
				...commonProps,
				statisticEntity: 'group',
				studentsIDsForStatistic: studentGroup?.studentIDs ?? [],
				studentClassID: studentClass?.id ?? 0,
				selectedStudentClass: studentClass ?? null,
			};
		}

		const studentClass = classList.find(({id}) => id === studentFromClassID);

		const studentsClasses = classList.filter(({studentIDs}) => studentIDs.includes(selectedStudent.id));
		const classWithMaxStudents = max(studentsClasses, ({studentIDs}) => studentIDs.length);

		const workedStudentClass = studentClass ?? (typeof classWithMaxStudents === 'number' ? null : classWithMaxStudents);

		return {
			...commonProps,
			statisticEntity: 'class',
			studentsIDsForStatistic: workedStudentClass?.studentIDs ?? [],
			studentClassID: workedStudentClass?.id ?? 0,
			selectedStudentClass: workedStudentClass ?? null,
		};
	}, [
		classList,
		groupsList,
		handleClickTestCard,
		handleStudentClicked,
		isClassListLoaded,
		isGroupsListLoaded,
		isStudentsListLoaded,
		selectedStudentId,
		setTestLauncherState,
		studentFromClassID,
		studentFromGroupID,
		studentsList,
	]);
}
