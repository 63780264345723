import {useStudentDetailService} from '../../../../../../hooks';
import {join, useBehaviorSubject} from '@esgi/ui/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Checkbox} from '@esgillc/ui-kit/control';
import styles from '../../styles.module.less';

export function DetailReportSettingsResultsBlock() {
	const service = useStudentDetailService();
	const reportSettings = useBehaviorSubject(service.reportSettings$);
	const report = useBehaviorSubject(service.report$);

	return (
		<div className={join(styles.settingsCell, styles.resultsBlock)}>
			<span className={styles.title}>Results:</span>
			<div className={styles.resultsSettings}>
				<div>
					<Checkbox
						data-cy='carry-forward-checkbox'
						id='carry-forward-checkbox'
						className={styles.checkbox}
						checked={reportSettings.carryScoresForward}
						onClick={() => service.reportSettingValueChange({
							key: 'carryScoresForward',
							newValue: !reportSettings.carryScoresForward,
						})}
					/>
					<label
						htmlFor='carry-forward-checkbox'
						className={styles.checkboxLabel}
					>
						<span>Carry forward</span>
						<OnHoverTooltip message='Help'>
							<a
								href='https://support.esgisoftware.com/hc/en-us/articles/360024667492-Student-Detail-Report'
								target='_blank'
								rel='noreferrer'
							>
								<sup className='fa fa-question-circle' aria-hidden='true'/>
							</a>
						</OnHoverTooltip>
					</label>
				</div>
				<div>
					{report?.gradeValues.length
						? <>
								<Checkbox
									data-cy='include-grade-checkbox'
									id='include-grade-checkbox'
									className={styles.checkbox}
									checked={reportSettings.includeGradeScore}
									onClick={() => service.reportSettingValueChange({
										key: 'includeGradeScore',
										newValue: !reportSettings.includeGradeScore,
									})}
								/>
								<label
									htmlFor='include-grade-checkbox'
									className={styles.checkboxLabel}
								>
									<span>Show Grades</span>
								</label>
							</>
						: <OnHoverTooltip message='You need to configure Grade Scales to use this option.'>
								<>
									<Checkbox
										data-cy='include-grade-checkbox'
										id='include-grade-checkbox'
										className={styles.checkbox}
										checked={false}
										disabled={true}
										onClick={() => service.reportSettingValueChange({
											key: 'includeGradeScore',
											newValue: !reportSettings.includeGradeScore,
										})}
									/>
									<label
										htmlFor='include-grade-checkbox'
										className={styles.checkboxLabel}
									>
										<span>Show Grades</span>
									</label>
								</>
							</OnHoverTooltip>
					}
				</div>
				<div>
					<Checkbox
						data-cy='show-baseline-checkbox'
						id='show-baseline-checkbox'
						className={styles.checkbox}
						checked={reportSettings.showBaseline}
						onClick={() => service.reportSettingValueChange({
							key: 'showBaseline',
							newValue: !reportSettings.showBaseline,
						})}
					/>
					<label
						htmlFor='show-baseline-checkbox'
						className={styles.checkboxLabel}
					>
						<span>Show Baseline</span>
					</label>
				</div>
			</div>
		</div>
	);
}
