import {styled} from '@esgi/ui/theme';
import {TestColorIndicator as TestColorIndicatorUI} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {Box, FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {Checkbox} from '@esgi/ui/controls';

export const TestColorIndicator = styled(TestColorIndicatorUI, {
	height: '32px',
	padding: '6px 4px',
	marginRight: '12px',
});

export const SelectableListTriggerLabel = styled(Text, {
	color: 'inherit',
	marginRight: 12,

	variants: {
		disabled: {
			true: {
				color: '$neutral80',
			},
		},
	},
});

export const SelectableListTriggerContent = styled(FlexBox, {
	color: 'inherit',
	alignItems: 'center',
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	width: 'max-content',
	height: 'max-content',
	border: 'none',
	background: 'none',
	padding: 0,
	gridTemplateColumns: 'auto',
	maxHeight: '24px',
	borderRadius: '50%',

	variants: {
		isListOpen: {
			true: {
				borderRadius: '50%',
			},
		},
	},
});

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',
	marginBottom: '8px',
});

export const IconBox = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	marginLeft: '8px',
	gap: '16px',
	minWidth: '72px',
	justifyContent: 'end',
});

export const CounterText = styled(Text, {
	minWidth: '16px',
	textAlign: 'center',

	variants: {
		type: {
			correct: {
				color: '$positive',
			},
			incorrect: {
				color: '$negative',
			},
			untested: {
				color: '$neutral56',
			},
		},
		zero: {
			true: {},
		},
	},
});

export const CounterBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: '$3',
	marginLeft: 'auto',
});

export const CustomTrigger = styled(GridBox, {
	border: '1px solid $vivid',
	borderRadius: '6px',
	padding: '8px 12px',
	gridAutoFlow: 'column',
	gap: '16px',
	alignItems: 'center',
	backgroundColor: '$vivid',
	transition: '.3s ease',

	variants: {
		isChecked: {
			true: {
				borderColor: '$primaryMuted',
				backgroundColor: '$primaryBackground',
			},
		},
	},
});

export const ItemTagAndTitle = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	alignItems: 'center',
});

export const Options = styled(GridBox, {
	gridTemplateColumns: '1fr auto auto',
	gap: '16px',
});

export const TriggerWrapper = styled(GridBox, {
	'> div': {
		gridTemplateColumns: 'auto',
	},
});

export const CheckboxWrapper = styled(Box, {
	padding: '8px 12px 8px 0',
});

export const TestCheckbox = styled(Checkbox, {
	padding: '0',
});
