import React, {useEffect, useState} from 'react';
import styles from './styles.module.less';

interface Props {
	tbodyRef: React.RefObject<HTMLTableSectionElement>;
}

export function NoGoalsSetMessage({tbodyRef}: Props) {
	const [wrapperStyle, setWrapperStyle] = useState({} as React.CSSProperties);
	useEffect(() => {
		const {current} = tbodyRef;
		if (current) {
			// take the first td in the first tr
			const {width, left, top} = current.children[0].children[0].getBoundingClientRect();
			// take the height of the tbody
			const {height} = current.getBoundingClientRect();
			setWrapperStyle({width, left, top, height});

		}
	}, [tbodyRef]);

	return (
		<div className={styles.fixedWrapper}>
			<div className={styles.overlayWrapper} style={wrapperStyle}>
				<div className={styles.dataAlert}>
					<span>No IEP Goals have been set yet. Select the “Create” link to add an IEP goal for each test.</span>
				</div>
			</div>
		</div>
	);
}
