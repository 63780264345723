import {FormatType} from '../../types';
import {useValue, ChangeFunction} from '@esgi/main/kits/activities';
import {Section, Title, ToggleGroupRoot, ToggleGroupContent, ToggleGroupItem, Label} from './index.styled';

interface Props {
	disabled?: boolean;
	onChange: ChangeFunction<FormatType>;
}

export function NameFormatControl({disabled, onChange}: Props) {
	const [value, setValue] = useValue<FormatType>(FormatType.FirstAndLastName, onChange);

	return (
		<Section>
			<Title size='small' font='mono' color='lowContrast' data-cy='name-format-title'>Name Format</Title>
			<ToggleGroupRoot
				value={value.toString()}
				disabled={disabled}
			>
				<ToggleGroupContent>
					<ToggleGroupItem
						value={FormatType.FirstName.toString()}
						onClick={() => setValue(FormatType.FirstName)}
						data-cy='first-name-only-toggle'
					>
						<Label size='medium' bold>
							First Name Only
						</Label>
					</ToggleGroupItem>
					<ToggleGroupItem
						value={FormatType.FirstAndLastName.toString()}
						onClick={() => setValue(FormatType.FirstAndLastName)}
						data-cy='first-last-name-toggle'
					>
						<Label size='medium' bold>
							First & Last Name
						</Label>
					</ToggleGroupItem>
				</ToggleGroupContent>
			</ToggleGroupRoot>
		</Section>
	);
}
