import React from 'react';
import {ITrack} from 'shared/modules/grade-scale/models';
import {CustomGradeRangeService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/service';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';
import GradeRangeEditor
	from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/component';
import {
	GradeRangeEditorService,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/service';

export class Props {
	editors: GradeRangeEditorService[];
	track: ITrack;
	service: CustomGradeRangeService;
}

export class State {
	viewAll: boolean;	
}

export default class DPMPGradeRangeEditor extends React.PureComponent<Props, State> {
	ref: HTMLDivElement;

	constructor(props) {
		super(props);
		this.state = new State();
	}
	
	componentDidMount() {
		this.props.service.viewAll$.subscribe(viewAll => {
			this.setState({viewAll: viewAll});
		});
		
		this.props.service.notValidEditor$.subscribe(index =>{
			if (index === null){
				return;
			}
			
			this.scrollToEntry(index * 260).subscribe(() => {
				this.props.editors.forEach(g => g.showValidation());
			});
		});
	}

	renderViewAllButton() {
		if (!this.state.viewAll) {
			return <div className='view-all-button' onClick={() => this.props.service.setViewAll(true)}>
				<span>View all</span>
				<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z' fill='#0077B8'/>
				</svg>
			</div>;
		} else{
			return <div className='view-all-button' onClick={() => this.props.service.setViewAll(false)}>
				<span>Collapse All</span>
				<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.7738e-05L0 5.99991L1.41 7.40991Z' fill='#0077B8'/>
				</svg>
			</div>;
		}
	}

	onScroll() {
		this.props.editors.forEach(g => g.hideValidation());
	}
	
	scrollToEntry(top: number) {
		if (!this.ref){
			return of(null);
		}
		
		this.ref.scrollTop = top;
		return of(null).pipe(delay(100));
	}
	
	render() {
		return <div className='different-per-period-wrapper'>
			<div className='different-per-period-container' ref={r => this.ref = r} onScroll={() => this.onScroll()}>
				{this.props.editors.map((gre, index) => {
					const trackDate = this.props.track.trackDates[index];
					return <div className='track-editor-container' key={gre.editorID}>
						<div className='track-date-info'>
							<div className='track-number'>{index + 1}</div>
							<div className='track-period'>{trackDate.from + ' - ' + trackDate.to}</div>
						</div>
						<GradeRangeEditor key={gre.editorID} service={gre}/>
					</div>;
				})}
			</div>
			{this.renderViewAllButton()}
		</div>;
	}
}