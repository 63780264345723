import {ComponentPropsWithoutRef, MouseEvent, forwardRef, useCallback} from 'react';
import {Button, Content, Counter} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {CheckAll} from '@esgi/ui/icons';
import {BaseComponentProps, isUndefined} from '@esgi/ui';

type Props = ComponentPropsWithoutRef<'button'> &
	BaseComponentProps & {
		label: string;
		selected: boolean;
		studentsCount?: number;
	};

export const SelectAllEntitiesButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy, css = {}, label, selected, studentsCount, onClick, disabled, ...buttonProps}, forwardedref) => {
		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				if (!selected && !disabled) {
					onClick?.(event);
				}
			},
			[disabled, onClick, selected],
		);

		return (
			<Button
				dataCy={dataCy}
				css={css}
				disabled={disabled}
				onClick={handleClick}
				{...buttonProps}
				data-selected={selected}
				ref={forwardedref}
			>
				<Content>
					<Text size='large'>{label}</Text>
					{!isUndefined(studentsCount) &&
						<Counter>
							<Text size='small' font='mono'>
								{studentsCount}
							</Text>
						</Counter>
					}
				</Content>
				<CheckAll />
			</Button>
		);
	},
);
