import {observable} from '@esgi/deprecated/knockout';
import {OrderSummaryItem} from '../models';
import {Step} from '../steps';
import {PurchaseCompleteTemplate} from './purchase-complete-template';
import {ServerClient} from 'pages/landing/kit/server-client';

export class PurchaseCompleteStep extends Step {

	constructor(public quoteId: number) {
		super();
		this.title = 'Order Confirmation';
	}

	template = () => PurchaseCompleteTemplate.render();

	continueText: string;

	server = {
		initCompleteStep: () => {
			return ServerClient.SSOAPI.Get<{orderLogID: number}>('Quotes', 'InitPaymentComplete', {data: {quoteId: this.quoteId}});
		},
	};

	@observable()
	subscriptions: OrderSummaryItem[];

	@observable()
	transactionId: string;

	@observable()
	email: string;

	@observable()
	paymentID: number;

	@observable()
	orderId: number;

	@observable()
	newStudentLimit: number;

	view = {
		continue: () => {
			$(this).trigger('continueClicked');
		},
		downloadReceipt: () => {
			$(this).trigger('downloadReceiptClicked');
		},
	};

	events = {
		continueClicked: (callback) => {
			$(this).on('continueClicked', callback);
		},
		downloadReceiptClicked: (callback) => {
			$(this).on('downloadReceiptClicked', callback);
		},
	};
}
