import {Checkbox, Dropdown, Option, Radio} from '@esgillc/ui-kit/control';
import {IdNameModel, Level, Subject, Test} from '../../models';
import {UserType, useUser} from '@esgi/core/authentication';
import ItemAnalysisReportService from '../../services/item-analysis-report-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './report-settings.module.less';

interface ReportSettingsProps {
	service: ItemAnalysisReportService;
}

export default function ReportSettings({service}: ReportSettingsProps) {
	const userContext = useUser();

	const displayNotTestedAsIncorrect = useBehaviorSubject<boolean>(service.displayNotTestedAsIncorrect);
	const level: Level = service.level;
	const sortOptionMode = useBehaviorSubject<string>(service.sortOptionMode);

	const classes: IdNameModel[] = service.classes;
	const groups: IdNameModel[] = service.groups;
	const specialistGroups: IdNameModel[] = service.specialistGroups;
	const subjects: IdNameModel[] = service.subjects;

	const schoolName: string = service.schoolName;
	const districtName: string = service.districtName;
	const teacherName: string = service.teacherName;

	const testResultsIncorrectVerbiage: string = useBehaviorSubject<string>(service.testResultsIncorrectVerbiage);

	const filteredGradeLevels = useBehaviorSubject<IdNameModel[]>(service.filteredGradeLevels);
	const selectedClass = useBehaviorSubject<IdNameModel>(service.selectedClass);
	const selectedGroup = useBehaviorSubject<IdNameModel>(service.selectedGroup);
	const selectedSpecialistGroup = useBehaviorSubject<IdNameModel>(service.selectedSpecialistGroup);
	const selectedSubject = useBehaviorSubject<Subject>(service.selectedSubject);
	const selectedTest = useBehaviorSubject<Test>(service.selectedTest);
	const selectedGradeLevel = useBehaviorSubject<IdNameModel>(service.selectedGradeLevel);

	const changeSortMode = (mode) => {
		service.changeSortMode(mode);
	};
	const changeSelectedClass = (id) => {
		service.changeSelectedClass(id);
	};
	const changeSelectedGroup = (id) => {
		service.changeSelectedGroup(id);
	};
	const changeSelectedSpecialistGroup = (id) => {
		service.changeSelectedSpecialistGroup(id);
	};
	const changeSelectedSubject = (id) => {
		service.changeSelectedSubject(id);
	};
	const changeSelectedTest = (id) => {
		service.changeSelectedTest(id);
	};
	const changeSelectedGradeLevel = (id) => {
		service.changeSelectedGradeLevels(id);
	};
	const changeDisplayNotTestedAsIncorrect = (value: boolean) => {
		service.changeDisplayNotTestedAsIncorrect(value);
	};

	return <div id='iar-filter' className={styles.reportSettings}>
		<div>
			{
				specialistGroups?.length === 0 && <>
					{
						level === 'user' && userContext.userType !== UserType.T && <div>
							<span>Teacher: </span>
							<span className={styles.name}>{teacherName}</span>
						</div>
					}
					{
						level === 'school' && <div>
							<span>School: </span>
							<span className={styles.name}>{schoolName}</span>
						</div>
					}
					{
						level === 'district' && <div>
							<span>District: </span>
							<span className={styles.name}>{districtName}</span>
						</div>
					}
					{
						level === 'user' && classes?.length > 0 && <div>
							<span>Class: </span>
							{
								classes?.length > 1 && <Dropdown
									value={[selectedClass]}
									className={styles.dropdown}
									autoWidth
									optionName='name'
									setValue={(value) => changeSelectedClass(value[0])}>
									{classes?.map(item => <Option key={`item-analysis-selected-class-option-${item.id}`}
									                              value={item.id}>{item.name}</Option>)}
								</Dropdown>
							}
							{
								classes?.length === 1 && <>
									<span className={styles.name}>{selectedClass.name}</span>
								</>
							}
						</div>
					}
					{
						level === 'group' && groups?.length > 0 && <div>
							<span>Group: </span>
							{
								groups?.length > 1 && <Dropdown
									value={[selectedGroup]}
									className={styles.dropdown}
									optionName='name'
									autoWidth
									setValue={(value) => changeSelectedGroup(value[0])}>
									{groups?.map(item => <Option key={`item-analysis-selected-group-option-${item.id}`}
									                             value={item.id}>{item.name}</Option>)}
								</Dropdown>
							}
							{
								groups?.length === 1 && <>
									<span className={styles.name}>{selectedGroup.name}</span>
								</>
							}
						</div>
					}
				</>
			}
			{
				specialistGroups?.length > 0 && <>
					<div>
						<span>Specialist: </span>
						<span className={styles.name}>{teacherName}</span>
					</div>
					<div>
						<span>Group: </span>
						{
							specialistGroups?.length > 1 && <Dropdown
								value={[selectedSpecialistGroup]}
								className={styles.dropdown}
								optionName='name'
								autoWidth
								setValue={(value) => changeSelectedSpecialistGroup(value[0])}>
								{specialistGroups?.map(item => <Option key={`item-analysis-selected-specialist-group-option-${item.id}`}
								                                       value={item.id}>{item.name}</Option>)}
							</Dropdown>
						}
						{
							specialistGroups?.length === 1 && <span className={styles.name}>{selectedSpecialistGroup.name}</span>
						}
					</div>
				</>
			}
			<div>
				<div>Subject Tab:</div>
				<Dropdown
					value={[selectedSubject]}
					className={styles.dropdown}
					optionName='name'
					autoWidth
					setValue={(value) => changeSelectedSubject(value[0])}>
					{subjects?.map(item => <Option key={item?.id}
					                               value={item?.id}>{item?.name}</Option>)}
				</Dropdown>
			</div>
			{
				selectedSubject && selectedSubject.tests.length > 0 && <div>
					<span>Test: </span>
					<Dropdown
						value={[selectedTest]}
						className={styles.dropdown}
						optionName='name'
						autoWidth
						setValue={(value) => changeSelectedTest(value[0])}>
						{selectedSubject.tests?.map(item => <Option key={`item-analysis-selected-subject-test-option-${item?.id}`}
						                                            value={item?.id}>{item?.name}</Option>)}
					</Dropdown>
				</div>
			}
			{
				selectedGradeLevel !== null && filteredGradeLevels && <div>
					<span>Grade: </span>
					<Dropdown
						value={[selectedGradeLevel]}
						className={styles.dropdown}
						optionName='name'
						autoWidth
						setValue={(value) => changeSelectedGradeLevel(value[0])}>
						{filteredGradeLevels?.map(item => <Option key={`item-analysis-selected-grade-level-option-${item?.id}`}
						                                          value={item?.id}>{item?.name}</Option>)}
					</Dropdown>
				</div>
			}
		</div>
		{
			<div>
				<div className={styles.settingsCell}>
					<div>
						Sort Options
						<OnHoverTooltip message='Help'>
							<a href='https://support.esgisoftware.com/hc/en-us/articles/209159686'
							   target='_blank' rel='noreferrer' className={styles.helperContainer}>
								<i className='fa fa-question-circle' aria-hidden='true'/>
							</a>
						</OnHoverTooltip>
					</div>
					<div>
						<Radio value={0}
						       id='sortOptionLeastKnown'
						       checked={sortOptionMode === '0'}
						       onChange={() => changeSortMode('0')}>
								<span className={styles.label}>
									Least Known
								</span>
						</Radio>
						<Radio value={1}
						       id='sortOptionMostKnown'
						       checked={sortOptionMode === '1'}
						       onChange={() => changeSortMode('1')}>
								<span className={styles.label}>
									Most Known
								</span>
						</Radio>
						<Radio value={2}
						       id='sortOptionQuestionOrder'
						       checked={sortOptionMode === '2'}
						       onChange={() => changeSortMode('2')}>
								<span className={styles.label}>
									Question Order
								</span>
						</Radio>
						<Radio value={3}
						       id='sortOptionAlphabetical'
						       checked={sortOptionMode === '3'}
						       onChange={() => changeSortMode('3')}>
								<span className={styles.label}>
									Alphabetical
								</span>
						</Radio>
					</div>
				</div>
				<div className={styles.settingsCell}>
					<div>
						<Checkbox
							id='displayNotTestedAsIncorrect'
							onClick={() => changeDisplayNotTestedAsIncorrect(!displayNotTestedAsIncorrect)}
							checked={displayNotTestedAsIncorrect}>
							<span>Display Not Tested as {testResultsIncorrectVerbiage}</span>
						</Checkbox>
					</div>
				</div>
			</div>
		}
	</div>;
}
