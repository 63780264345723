import * as React from 'react';
import type {SVGProps} from 'react';

export function Copy(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path d='M8.25 8.25C6.59317 8.25 5.25 9.59325 5.25 11.25V15.75C5.25 17.4067 6.59317 18.75 8.25 18.75C9.04095 18.75 11.1123 18.75 12.75 18.75C14.4068 18.75 15.75 17.4067 15.75 15.75C15.75 15 15.75 14.25 15.75 14.25H11.75C10.6454 14.25 9.75 13.3546 9.75 12.25V8.25H8.25ZM8.25 9.75V12.75C8.25 14.25 9.75 15.75 11.25 15.75H14.25C14.25 16.5787 13.5785 17.25 12.75 17.25H8.25C7.42155 17.25 6.75 16.5787 6.75 15.75V11.25C6.75 10.4212 7.42155 9.75 8.25 9.75Z' fill='#333333'/>
			<path d='M11.25 5.25C9.59317 5.25 8.25 6.59325 8.25 8.25V12.75C8.25 14.4067 9.59317 15.75 11.25 15.75C12.041 15.75 14.1123 15.75 15.75 15.75C17.4068 15.75 18.75 14.4067 18.75 12.75C18.75 12 18.75 8.25 18.75 8.25C18.75 6.59325 17.4068 5.25 15.75 5.25H11.25ZM11.25 6.75H15.75C16.5785 6.75 17.25 7.42125 17.25 8.25V12.75C17.25 13.5787 16.5785 14.25 15.75 14.25H11.25C10.4216 14.25 9.75 13.5787 9.75 12.75V8.25C9.75 7.42125 10.4216 6.75 11.25 6.75Z' fill='#333333'/>
		</svg>
	);
}
