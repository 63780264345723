import {Class, Group, Student} from '@esgi/main/libs/store';

export type GroupItem = Omit<Group, 'studentIDs'> & {
	students: Student[];
};

export type GroupState = GroupItem & {
	isListOpen: boolean;
};

export enum AllOptionItem {
	Value = 'all',
	Label = 'All Classes',
}

export type OptionType = Class['id'] | AllOptionItem.Value;
