import {useCallback, useEffect, useMemo, useState} from 'react';
import {Test, useValue} from '@esgi/main/kits/reports';
import {Sort} from 'apps/main/kits/reports/src/kit/tests-control/types';

type ToggleFunction = (ids: Test['id']) => void;

const sortOrder: Sort[] = [
	{field: 'name', direction: 'desc'},
	{field: 'name', direction: 'asc'},
	{field: 'id', direction: 'desc'},
];

export function useTestsControl<T extends Test>(
	tests: T[] | null,
	onChange: (value: Test['id']) => void
) {
	const [sortIndex, setSortIndex] = useState(2);
	const [selected, setSelected] = useValue<Test['id']>(0, onChange);
	const sort = useMemo<Sort>(() => sortOrder[sortIndex], [sortIndex]);
	const items = useMemo(
		() => {
			if (tests === null) {
				return [];
			}
			if (sort.field === 'id') {
				return tests.sort((left, right) => left.id - right.id);
			}
			if (sort.field === 'name') {
				return tests.sort((left, right) => {
					if (sort.direction === 'asc') {
						return left.name.localeCompare(right.name);
					}
					return right.name.localeCompare(left.name);
				});
			}
			return tests;
		},
		[sort, tests],
	);

	const toggleSelected = useCallback<ToggleFunction>((id: number) => {
		setSelected(id);
	}, [setSelected]);

	const onSort = useCallback(() => {
		let next = sortIndex + 1;
		if (next > 2) {
			next = 0;
		}
		setSortIndex(next);
	}, [sortIndex]);

	useEffect(() => {
		if (tests?.length > 0) {
			setSelected(tests[0].id);
		} else {
			setSelected(null);
		}
	}, [tests]);

	return {
		items,
		selected,
		setSelected,
		toggleSelected,
		sort,
		onSort,
	};
}
