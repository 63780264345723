import React, {useRef} from 'react';
import {NavigationBar, NavigationBarOrientation} from '@esgi/ui/layout';
import {HorizontalPanelHead} from './components/horizontal-panel-head';
import {HorizontalPanelContent} from './components/horizontal-panel-content';
import {HorizontalPanelBottom} from './components/horizontal-panel-bottom';
import {NavigationPanelBaseProps} from '../../../types';

export function HorizontalPanel({
	onSettingsClick,
	onAvatarClick,
	onChangeSchoolYears,
	onCastClick,
	schoolYears,
	userInfo,
	currentSchoolYearID,
	onHelpClick,
}: NavigationPanelBaseProps) {
	const selectContentRef = useRef();
	const selectFieldRef = useRef();
	return (
		<NavigationBar.Root
			dataCy='horizontal-panel'
			orientation={NavigationBarOrientation.Horizontal}
			dependElementRefs={[selectContentRef, selectFieldRef]}
			closeOnOutsideClick
		>
			<HorizontalPanelHead />

			<HorizontalPanelContent
				currentSchoolYearID={currentSchoolYearID}
				selectFieldRef={selectFieldRef}
				selectContentRef={selectContentRef}
				schoolYears={schoolYears}
				onChangeSchoolYears={onChangeSchoolYears}
				userInfo={userInfo}
			/>

			<HorizontalPanelBottom
				onCastClick={onCastClick}
				onSettingsClick={onSettingsClick}
				onAvatarClick={onAvatarClick}
				onHelpClick={onHelpClick}
				userInfo={userInfo}
			/>
		</NavigationBar.Root>
	);
}
