import {ServerClient} from 'pages/landing/kit/server-client';
import {Form, FormField, Validators} from '@esgi/deprecated/knockout';
import {ActivationCodeFormTemplate} from './template';

import './activation-code-form.less';

export interface ActivationCodeCheckResult {
	joinToDistrictID: number;
	activationCodeID: number;
	errorMessage: string;
}

export class ActivationCodeFormComponentModel {
	joinToDistrictId: number;
	activationCodeId: number;
	activationCode: string;
}

export class ActivationCodeForm extends Form {
	constructor(initValue?: string) {
		super();
		this.activationCode = this.createActivationCode();
		if (initValue) {
			this.activationCode.value(initValue);
		}
	}

	template = ActivationCodeFormTemplate;

	activationCode: FormField<string>;

	joinToDistrictId: number;
	activationCodeId: number;

	createActivationCode() {

		const acValidator = Validators.Ajax(f => ServerClient.SSOAPI.Get<ActivationCodeCheckResult>('Renewal',
			'CheckActivationCode', {data: {activationCode: f.value()}})
			.done((result) => {
				if (result.errorMessage) {
					return;
				}
				this.joinToDistrictId = result.joinToDistrictID;
				this.activationCodeId = result.activationCodeID;

				if (result.joinToDistrictID) {
					$(this).trigger('joinToDistrict', result.joinToDistrictID);
				}
			}), resp => resp.errorMessage);

		let f = this.createField<string>(null, Validators.Required(), acValidator);
		f.validation.successValidation(true);
		f.validation.showValidation(true);
		f.validation.validationMessageTitleUseHtml = true;
		return f;
	}

	serialize() {
		let m = new ActivationCodeFormComponentModel();
		m.activationCode = this.activationCode.value();
		m.activationCodeId = this.activationCodeId;
		m.joinToDistrictId = this.joinToDistrictId;

		return m;
	}

	events = {
		joinToDistrict: (callback: (e: Event, id: number) => any) => {
			$(this).on('joinToDistrict', callback);
		},
	};
}
