import {ProgressBar} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

import {useStudentsData} from '../../hooks';
import {AveragePerformanceService} from '../service';
import {Delta, Divider, LegendContainer, LegendItem, LegendText, PercentContainer} from '../../kit';
import {ClassPerformanceRingContainer, RingsContainer, StudentPerformanceRingContainer} from '../styled';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	studentID: number,
	service: AveragePerformanceService,
};

export function StudentView(props: Props) {
	const {loaded, data} = useStudentsData(props.service, props.studentID, props.testIDs, props.studentIDs);

	const {classPercent, studentPercent, delta} = data;

	const classPercentRounded = Math.round(classPercent);
	const studentPercentRounded = Math.round(studentPercent);
	const deltaRounded = Math.round(delta);

	return <>
		<RingsContainer>
			<ClassPerformanceRingContainer>
				<ProgressBar value={loaded ? classPercentRounded : 0} maxValue={100}>
					<ProgressBar.RingWithPointer progressValueColor='lowContrast'
					                             skeleton={!loaded}
					                             size={80}
					                             circleStrokeWidth={8}
					                             pointerFillColor='neutral40'/>
				</ProgressBar>
			</ClassPerformanceRingContainer>
			<StudentPerformanceRingContainer>
				<ProgressBar value={loaded ? studentPercentRounded : 0} maxValue={100}>
					<ProgressBar.RingWithPointer progressValueColor='primaryVivid'
					                             skeleton={!loaded}
					                             size={60}
					                             circleStrokeWidth={8}
					                             pointerFillColor='primary48'>

					</ProgressBar.RingWithPointer>
				</ProgressBar>
			</StudentPerformanceRingContainer>
			{loaded && <StudentPerformanceRingContainer>
				<PercentContainer>
					<Text font='mono' bold color='primary48' size='medium' data-cy='student-percent'>
						{studentPercentRounded}%
					</Text>
					<Divider/>
					<Text font='mono' bold color='neutral40' size='small' data-cy='class-percent'>
						{classPercentRounded}%
					</Text>
				</PercentContainer>
			</StudentPerformanceRingContainer>}
		</RingsContainer>
		<LegendContainer>
			<LegendItem skeleton={!loaded} dataCy='legend-student-performance'>
				<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect x='1.25' y='1.25' width='7.5' height='7.5' rx='3.75' fill='#059DF0' stroke='#DCEFF9'
					      strokeWidth='1.5'/>
				</svg>
				<LegendText font='mono' color='primary48' size='xSmall'>Student Performance</LegendText>
			</LegendItem>
			<LegendItem skeleton={!loaded} css={{marginTop: 6}} dataCy='legend-class-performance'>
				<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect x='1.25' y='1.25' width='7.5' height='7.5' rx='3.75' fill='#666666' stroke='#E0E0E0'
					      strokeWidth='1.5'/>
				</svg>
				<LegendText font='mono' color='neutral40' size='xSmall'>Class Performance</LegendText>
			</LegendItem>
			<Delta skeleton={!loaded} css={{marginTop: 14}} value={deltaRounded}>
				Compared to Class
			</Delta>
		</LegendContainer>
	</>;
}
