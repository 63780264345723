import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {CardSeparator} from './index.styled';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & BaseComponentProps;

export const AssignmentDetailsCardDivider = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'assignment-details-card-divider', css = {}, ...props}, forwardedRef) => (
		<CardSeparator dataCy={dataCy} css={css} {...props} ref={forwardedRef} />
	),
);
