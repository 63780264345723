import {useServiceFactory} from '@esgi/core/service';
import {InitOptions, TestScreenService} from '../services/testing-service';
import {Props} from '../question-slider';

function resolveInitOptions(props: Props): InitOptions {
	const {general, progress, resumeModel} = props.testingModel;
	const questionsOrder = props.shuffleQuestions ? general.shuffledQuestionIDs : general.originalQuestionIDs;
	if (props.continueSession) {
		return {
			subset: general.subsetQuestionsIDs,
			sessionType: progress.sessionType,
			questionsOrder,
			resume: false,
			continue: true,
		};
	}
	if (props.resumeSession) {
		return {
			subset: null,
			sessionType: resumeModel.lastSessionType,
			questionsOrder: resumeModel.questionsOrder || general.originalQuestionIDs,
			resume: true,
			continue: false,
		};
	}
	
	return {
		subset: props.questionIds,
		sessionType: props.sessionType,
		questionsOrder,
		resume: false,
		continue: false,
	} as InitOptions;
}

export function useTestingService(props: Props): TestScreenService {
	return useServiceFactory(() => {
		const options = resolveInitOptions(props);

		const service = new TestScreenService(props.testingModel, props.guid, props.shareSyncService, options);
		if (props.continueSession) {
			service.startTesting(props.testingModel.progress.currentQuestionIndex + 1);
		} else if (props.resumeSession) {
			const questions = service.questions.value;
			service.startTesting(questions.indexOf(questions.find((q) => q.questionID === props.testingModel.resumeModel.lastAnsweredQuestionID)) + 1, {isSessionResumed: true});
		} else {
			service.startTesting(0);
		}

		return service;
	});
}
