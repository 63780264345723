import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useInlineManual} from '@esgi/core/inline-manual';
import {useUser} from '@esgi/core/authentication';
import {useOpenDrawer} from './drawers';
import {NavigationPanel} from './navigation-panel';
import {menuLabelByRoute} from './navigation-panel/constants';
import {SchoolYear} from './navigation-panel/types';
import {NavigationPanelLauncher} from './index.styled';
import {isUndefined} from '@esgi/ui';
import {routes} from '@esgi/main/libs/core';

interface Props {
	schoolYears: SchoolYear[];
	currentGlobalSchoolYearID: number;
	onChangeSchoolYears?: (globalSchoolYearID: number) => void;
}

export function NavigationPanelRoot({schoolYears, currentGlobalSchoolYearID, onChangeSchoolYears}: Props) {
	const {showInlineManual} = useInlineManual();

	const user = useUser();

	const {pathname} = useLocation();

	const openDrawer = useOpenDrawer();
	const openUserAccountForm = useCallback(() => openDrawer('user-account'), [openDrawer]);
	const openStudentScreenPanel = useCallback(() => openDrawer('student-screen'), [openDrawer]);
	const openUserSettingsPanel = useCallback(() => openDrawer('settings'), [openDrawer]);

	useEffect(() => {
		const currentRoute = Object.entries(routes.teacher).find(([_, route]) => {
			if(typeof route === 'string') {
				return pathname.startsWith(route);
			}

			return Object.entries(route).find(([_, nestedRoute]) => pathname.startsWith(nestedRoute));
		});

		if (isUndefined(currentRoute)) {
			document.title = 'ESGI';

			return;
		}

		document.title = menuLabelByRoute[currentRoute[0]];
	}, [pathname]);

	return (
		<NavigationPanelLauncher>
			<NavigationPanel
				userInfo={{
					firstName: user?.firstName ?? '',
					lastName: user?.lastName ?? '',
					image: user?.avatarUrl ?? '',
					canExploreStudents: user?.canExploreStudents ?? false,
				}}
				schoolYears={schoolYears}
				onHelpClick={showInlineManual}
				onAvatarClick={openUserAccountForm}
				onCastClick={openStudentScreenPanel}
				onSettingsClick={openUserSettingsPanel}
				currentSchoolYearID={currentGlobalSchoolYearID}
				onChangeSchoolYears={onChangeSchoolYears}
			/>
		</NavigationPanelLauncher>
	);
}
