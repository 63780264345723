import {BaseButton} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	width: 24,
	height: 24,
	alignItems: 'center',
	justifyContent: 'center',
});

export const Button = styled(BaseButton, {
	width: 16,
	height: 16,
	borderRadius: 4,
	backgroundColor: '$border',

	'& svg': {
		width: '100%',
		height: '100%',
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	'&:hover:not(:disabled)': {
		backgroundColor: '$secondaryMuted',
		color: '$secondary',
	},

	'&:disabled': {
		borderWidth: 0.5,
		borderRadius: 3.5,
		borderColor: '$muted',
		borderStyle: 'solid',
		backgroundColor: '$background',

		[`& ${Text}`]: {
			color: '$muted',
		},
	},

	variants: {
		checked: {
			true: {
				backgroundColor: '$primary92',
				color: '$primary',
			},
		},
	},
});
