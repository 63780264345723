import {styled} from '@esgi/ui/theme';
import {FlexBox, Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Layout = styled(FlexBox, {
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	background: '$neutral96',
});

export const SectionBox = styled(Box, {
	width: 24,
	height: 24,

	'& svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		fillColor: {
			primary: {
				color: '$primary',
			},

			secondary: {
				color: '$secondary56',
			},
		},
	},
});

export const EmptyBodyText = styled(Text, {
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $neutral88',
	background: '$neutral99',
	color: '$neutral40',
});

export const DrawerContentContainer = styled(FlexBox, {
	flexDirection: 'column',
	gap: '$2',
	height: '100%',
	width: '100%',
	paddingLeft: '$2',
	paddingRight: '$2',
	paddingBottom: '$2',
	paddingTop: '$5',

	'@media (min-width: 1024px)': {
		paddingLeft: '$5',
		paddingRight: '$5',
		paddingBottom: '$5',
		paddingTop: '$7',
	},
});
