import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {UntestedStudentsReportService} from '../../../service';
import styles from '../../modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService;
}

export function ReportClass({service}: Props) {
	const classes = useBehaviorSubject(service.classes);
	const selectedClass = useBehaviorSubject(service.selectedClass);

	return classes.length > 0 &&
		<div>
			<span className={styles.bold}>Class:</span>
			{classes.length > 1 &&
				<Dropdown value={selectedClass} setValue={(v) => service.onClassChange(v)} optionName='name'
				          className={styles.fluid} autoWidth={true}>
					{classes.map(v => <Option key={v.classID} value={v}>{v.name}</Option>)}
				</Dropdown>
			}
			{classes.length === 1 &&
				<span className={styles.name}>{selectedClass[0].name}</span>
			}
		</div>;
}
