import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {Link as LinkRouterDom} from 'react-router-dom';

export const Wrapper = styled(Box, {
	padding: '8px',
	border: '1px solid $border',
	backgroundColor: '$vivid',
	borderRadius: '8px',
	marginTop: '8px',
});

export const Link = styled(LinkRouterDom, {
	display: 'inline-block',
	lineHeight: 0,
});

export const CheckboxGroup = styled(GridBox, {
	gap: '$1',

	[`& ${Link}`]: {
		marginLeft: 'auto',
	},

	'& svg': {
		width: '24px',
		height: '24px',
	},
});
