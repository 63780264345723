import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, forkJoin, Observable, Subject} from 'rxjs';
import {
	IGradeRange,
	IGradeScale,
	IGradeScaleEntry,
} from 'shared/modules/grade-scale/models';
import {map, mergeMap} from 'rxjs/operators';
import {ValidationStatus} from '@esgi/core/enums';
import {
	IGradeRangeForm, IActiveGradeRange,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/models';
import {isNaN} from 'underscore';
import {ValidationHandler} from 'shared/modules/grade-scale/form-field/validation-handler/validation-handler';
import {
	LessThenValidator,
	MaxValidator,
	NumericValidator, OverlapValidator,
	RequiredValidator,
} from 'shared/modules/grade-scale/form-field/validation-handler/validators';
import {FormField} from 'shared/modules/grade-scale/form-field/form-field';
import GradeRangeEditor
	from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/component';

export class GradeRangeEditorService extends BaseService {
	public editorID: string;
	public formValid: boolean;
	public testID?: number;
	public subjectID?: number;
	private readonly maxEntryValue: number;

	private readonly gradeScale: BehaviorSubject<IGradeScale> = new BehaviorSubject<IGradeScale>(null);
	private readonly gradeRanges: BehaviorSubject<IGradeRange[]> = new BehaviorSubject<IGradeRange[]>([]);
	private readonly validationVisibilityStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly disabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly editor: BehaviorSubject<IGradeRangeForm[]> = new BehaviorSubject<IGradeRangeForm[]>([]);
	private readonly calculatedGradeRange: Subject<IActiveGradeRange> = new Subject<IActiveGradeRange>();
	private readonly changedGradeRange: Subject<IGradeRangeForm> = new Subject<IGradeRangeForm>();
	private readonly focusGradeRange: Subject<IActiveGradeRange> = new Subject<IActiveGradeRange>();
	private readonly goToNextEditor: Subject<boolean> = new Subject<boolean>();
	
	constructor(maxEntryValue: number) {
		super();
		this.maxEntryValue = maxEntryValue;
		this.editorID = this.generateUniqueID();
		
		this.completeOnDestroy(combineLatest(this.gradeScale, this.gradeRanges)).subscribe((data) => {
			const gradeScale = data[0];
			const gradeRanges = data[1];
			
			if (!gradeScale) {
				return;
			}

			let form = gradeScale.entries.map(gs => {
				const gradeRange = gradeRanges.filter(gr => gs.id === gr.gradeScaleEntryID)[0];
				return this.mapToForm(gradeRange, gs);
			});
			
			this.validateForm(form).subscribe(f => {
				this.formValid = form.every(
					f => f.from.validation.status === ValidationStatus.Valid
						&& f.to.validation.status === ValidationStatus.Valid);
				
				this.editor.next(form);
			});
		});
	}

	public hideValidation() {
		this.validationVisibilityStatus.next(false);
	}

	public showValidation() {
		this.validationVisibilityStatus.next(true);
	}

	public setDisabled(disable: boolean) {
		this.disabled.next(disable);
	}
	
	public calculateGradeRange(activeGradeRange: IActiveGradeRange) {
		this.calculatedGradeRange.next(activeGradeRange);
	}

	public clearFocusGradeRange() {
		this.focusGradeRange.next(null);
	}
	
	get validationVisibilityStatus$(): Observable<boolean> {
		return this.completeOnDestroy(this.validationVisibilityStatus);
	}

	public disabled$: Observable<boolean> = this.completeOnDestroy(this.disabled);
	
	public focusGradeRange$: Observable<IActiveGradeRange> = this.completeOnDestroy(this.focusGradeRange);
	
	public goToNextEditor$: Observable<boolean> = this.completeOnDestroy(this.goToNextEditor);
	
	public moveCursorToNextEmptyEntry(gradeRange: IGradeRangeForm, field: string) {
		this.clearFocusGradeRange();
		
		this.calculateGradeRange({gradeRange: gradeRange, field: field});

		if (field === 'from' && !gradeRange.to.value) {
			this.focusGradeRange.next({gradeRange: gradeRange, field: 'to'});
		} else {
			const firstEmptyGradeRange = this.editor.value.filter(grs => gradeRange.gradeScaleEntryID < grs.gradeScaleEntryID && (!grs.to.value || !grs.from.value))[0];
			if (firstEmptyGradeRange) {
				const emptyField = firstEmptyGradeRange.from.value ? 'to' : 'from';
				this.focusGradeRange.next({gradeRange: firstEmptyGradeRange, field: emptyField});
			} else {
				this.goToNextEditor.next(true);
			}
		}
	}

	public setFocusFirstEmptyGradeRange() {
		this.clearFocusGradeRange();
		
		const firstEmptyGradeRange = this.editor.value.filter(grs => (!grs.to.value || !grs.from.value))[0];
		const emptyField = firstEmptyGradeRange.from.value ? 'to' : 'from';
		
		this.focusGradeRange.next({gradeRange: firstEmptyGradeRange, field: emptyField});
	}
	
	public setEditor(gradeRanges: IGradeRange[], gradeScale: IGradeScale, subjectID?: number, testID?: number) {
		this.testID = testID;
		this.subjectID = subjectID;
		this.gradeRanges.next(gradeRanges);
		this.gradeScale.next(gradeScale);
	}

	public editor$: Observable<IGradeRangeForm[]> = this.completeOnDestroy(this.editor);

	public changedGradeRange$: Observable<IGradeRangeForm> = this.completeOnDestroy(this.changedGradeRange);
	
	public gradeRangesResult$: Observable<IGradeRange[]> = this.completeOnDestroy(this.calculatedGradeRange.pipe(mergeMap(calculatedGradeRange => {
		let gradeRange = calculatedGradeRange.gradeRange;

		if (parseInt(gradeRange.to.value) > this.maxEntryValue) {
			gradeRange.to.value = this.maxEntryValue.toString();
		}

		if (parseInt(gradeRange.from.value) > this.maxEntryValue) {
			gradeRange.from.value = this.maxEntryValue.toString();
		}

		const isFromEditable = calculatedGradeRange.field === 'from';
		const recalculatedForm = this.recalculate(gradeRange, isFromEditable);

		return this.validateForm(recalculatedForm).pipe(map(validatedForm => {
			this.editor.next(validatedForm);
			
			this.formValid = validatedForm.every(
				f => f.from.validation.status === ValidationStatus.Valid
					&& f.to.validation.status === ValidationStatus.Valid);
			
			return validatedForm.map(f => this.mapToGradeRange(f));
		}));
	})));

	public updateForm(entry: IGradeRangeForm) {
		let newForm = this.editor.value.map(f => {
			if (entry.gradeScaleEntryID === f.gradeScaleEntryID) {
				return {...entry};
			}

			return {...f};
		});
		
		this.hideValidation();
		this.changedGradeRange.next(entry);
		this.editor.next(newForm);
	}
	
	private recalculate(updatedEntry: IGradeRangeForm, isFromEditable: boolean) {
		if (isFromEditable) {
			this.correctTo(this.editor.value, updatedEntry, true);
		} else {
			this.correctFrom(this.editor.value, updatedEntry, true);
		}

		const newForm = this.editor.value.map(f => {
			if (updatedEntry.gradeScaleEntryID === f.gradeScaleEntryID) {
				return {...updatedEntry};
			}

			return {...f};
		});

		return newForm;
	}

	private correctTo(form: IGradeRangeForm[], updatedEntry: IGradeRangeForm, changedFieldTo: boolean) {
		const toValue = parseInt(updatedEntry.to.value);
		const fromValue = parseInt(updatedEntry.from.value);

		if (fromValue > toValue) {
			if (changedFieldTo) {
				updatedEntry.to = new FormField<string>((toValue >= this.maxEntryValue ? this.maxEntryValue : toValue).toString());
			} else {
				updatedEntry.to = new FormField<string>((fromValue >= this.maxEntryValue ? this.maxEntryValue : fromValue).toString());
			}

			this.correctFrom(form, updatedEntry, changedFieldTo);
		}

		let prevEntry = form.filter(x => x.orderNumber === updatedEntry.orderNumber + 1)[0];

		if (prevEntry && !isNaN(fromValue)) {
			if (changedFieldTo) {
				prevEntry.to = new FormField<string>((fromValue === 0 ? 0 : fromValue - 1).toString());
				this.correctFrom(form, prevEntry, true);
			} else {
				this.correctFrom(form, updatedEntry, true);
			}
		}
	}

	private correctFrom(form: IGradeRangeForm[], updatedEntry: IGradeRangeForm, changedFieldTo: boolean) {
		const toValue = parseInt(updatedEntry.to.value);
		const fromValue = parseInt(updatedEntry.from.value);
		
		if (fromValue > toValue) {
			if (changedFieldTo) {
				updatedEntry.from = new FormField<string>((toValue === 0 ? 0 : toValue).toString());
				this.correctTo(form, updatedEntry, changedFieldTo);
			}
		}

		let nextEntry = form.filter(x => x.orderNumber === updatedEntry.orderNumber - 1)[0];

		if (nextEntry && !isNaN(toValue)) {
			if (changedFieldTo) {
				nextEntry.from = new FormField<string>((toValue >= this.maxEntryValue ? this.maxEntryValue : toValue + 1).toString());
				this.correctTo(form, nextEntry, false);
			} else {
				this.correctFrom(form, updatedEntry, true);
			}
		}
	}

	private validateForm(form: IGradeRangeForm[]): Observable<IGradeRangeForm[]> {
		return forkJoin(form.map((entry, index) => {
			const validators = [
				this.valueValidationHandler.handle(entry.from.value).pipe(map(validateResult => {
					entry.from = new FormField(entry.from.value, {
						status: validateResult.status,
						message: validateResult.firstMessage,
					});

					return entry;
				})),

				this.valueValidationHandler.handle(entry.to.value).pipe(map(validateResult => {
					entry.to = new FormField(entry.to.value, {
						status: validateResult.status,
						message: validateResult.firstMessage,
					});

					return entry;
				})),

				this.entryValuesValidationHandler.handle(entry).pipe(map(validateResult => {
					if (entry.from.validation.status === ValidationStatus.Valid && entry.to.validation.status === ValidationStatus.Valid) {
						entry.from = new FormField(entry.from.value, {
							status: validateResult.status,
							message: validateResult.firstMessage,
						});
					}

					return entry;
				})),
			];

			if (index < form.length - 1) {
				const prevEntry = form[index + 1];
				validators.push(this.formValidationHandler.handle([prevEntry, entry]).pipe(map(validateResult => {
					if (entry.from.validation.status === ValidationStatus.Valid && prevEntry.to.validation.status === ValidationStatus.Valid) {
						entry.from = new FormField(entry.from.value, {
							status: validateResult.status,
							message: validateResult.firstMessage,
						});

						prevEntry.to = new FormField(prevEntry.to.value, {
							status: validateResult.status,
							message: validateResult.firstMessage,
						});
					}

					return entry;
				})));
			}

			return forkJoin(validators).pipe(mergeMap(s => s));
		}));
	}

	private checkNumber(value: any) {
		const regex = new RegExp('^[0-9]+$');
		return regex.test(value);
	}

	private mapToGradeRange(form: IGradeRangeForm): IGradeRange {
		const from = this.checkNumber(form.from.value) ? parseInt(form.from.value) : undefined;
		const to = this.checkNumber(form.to.value) ? parseInt(form.to.value) : undefined;

		return {
			id: form.id,
			from: from,
			to: to,
			gradeScaleEntryID: form.gradeScaleEntryID,
			markingPeriod: form.markingPeriod,
		} as IGradeRange;
	}

	private mapToForm(gradeRange: IGradeRange, scaleEntry: IGradeScaleEntry): IGradeRangeForm {
		return {
			id: gradeRange.id,
			color: scaleEntry.color,
			description: scaleEntry.description,
			name: scaleEntry.gradeName,
			to: this.checkNumber(gradeRange.to) ? new FormField<string>(gradeRange.to.toString()) : new FormField<string>(''),
			from: this.checkNumber(gradeRange.from) ? new FormField<string>(gradeRange.from.toString()) : new FormField<string>(''),
			gradeScaleEntryID: gradeRange.gradeScaleEntryID,
			gradeScaleDescription: scaleEntry.description,
			markingPeriod: gradeRange.markingPeriod,
			testID: this.testID ? this.testID : 0,
			subjectID: this.subjectID ? this.subjectID : 0,
			orderNumber: scaleEntry.orderNumber,
		} as IGradeRangeForm;
	}

	private get valueValidationHandler() {
		return ValidationHandler.Create([new RequiredValidator(), new NumericValidator(), new MaxValidator(this.maxEntryValue)]);
	}

	private get entryValuesValidationHandler() {
		return ValidationHandler.Create([new LessThenValidator()]);
	}

	private get formValidationHandler() {
		return ValidationHandler.Create([new OverlapValidator()]);
	}

	private generateUniqueID() {
		let id = '';
		const characters = 'abcdefghijkmnopqrstuvwxyz23456789';
		const charactersLength = characters.length;
		for (let i = 0; i < 32; i++) {
			id += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return id;
	}
}
