import {Checkbox} from '@esgi/ui/form-controls';
import {FormControl, FormElement} from '@esgi/ui/form';
import {SectionItem, Title, Section} from '../index.styled';

interface Props {
	includeSkippedQuestionsControl: FormControl;
	showSummaryControl: FormControl;
	showSessionDateControl: FormControl;
	showNotesControl: FormControl;
}

export function SettingsControl({
	includeSkippedQuestionsControl,
	showSummaryControl,
	showSessionDateControl,
	showNotesControl,
}: Props) {

	return (
		<Section>
			<Title size='small' font='mono' color='lowContrast'>Settings</Title>

			<SectionItem>
				<FormElement control={includeSkippedQuestionsControl}>
					<Checkbox label='Include Skipped Questions'/>
				</FormElement>

				<FormElement control={showSummaryControl}>
					<Checkbox label='Show Summary'/>
				</FormElement>

				<FormElement control={showSessionDateControl}>
					<Checkbox label='Show Session Date'/>
				</FormElement>

				<FormElement control={showNotesControl}>
					<Checkbox label='Show Notes'/>
				</FormElement>
			</SectionItem>
		</Section>
	);
}
