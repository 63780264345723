import {ChangeEvent} from 'react';
import {BehaviorSubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {ImageResponse, PreSelected, ProfileInitData, ProfileInitResponse, StudentProfileMode} from './types';
import {EventBusManager} from '@esgillc/events';
import {
	StudentChangedEvent,
	StudentCreatedEvent,
	StudentRemovedEvent,
	StudentSaveAndAddEvent,
} from 'modules/forms/students-form/events';
import {dictionary} from 'modules/forms/students-form/constants';
import {StudentManagerEvents} from 'pages/student-manager/events';

export class StudentProfileService extends BaseService {
	public studentID = new BehaviorSubject<number>(null);
	public initData = new BehaviorSubject<ProfileInitData>(null);
	public defaultInitData: ProfileInitData;
	public mode = new BehaviorSubject<StudentProfileMode>(null);
	public selectedSchoolID = new BehaviorSubject<number>(null);
	public uploadedFile = new BehaviorSubject<ChangeEvent<HTMLInputElement>>(null);
	private currentUser = getUser();
	private eventBus = new EventBusManager();
	private controller = 'modules/forms/student/profile';
	private preSelected: PreSelected;

	constructor() {
		super();
		this.eventBus.subscribe(StudentCreatedEvent, (student: StudentCreatedEvent) => {
			const {studentID, classes, groups, specialistGroups, schoolID, teacherID, ...rest} = student;
			this.studentID.next(studentID);
			const newStudentsCount = this.initData.value.limit.studentsCount + 1;
			this.initData.next({
				...this.initData.value,
				general: rest,
				limit: {
					...this.initData.value.limit,
					studentsCount: this.initData.value.limit.studentsCount + 1,
					limitExceed: this.initData.value.limit.max === newStudentsCount,
				},
				location: {
					...this.initData.value.location,
					classIDs: classes.length ? classes : this.initData.value?.location?.classIDs,
					groupIDs: groups.length ? groups : this.initData.value?.location?.groupIDs,
					schoolID,
					specialistGroupIDs: specialistGroups || this.initData.value?.location?.specialistGroupIDs,
					teacherID,
				},
			});
			this.mode.next(StudentProfileMode.edit);
		});
		this.eventBus.subscribe(StudentSaveAndAddEvent, (student) => {
			if (student.isSaveAndAddOther && !student.studentLimitExceeded) {
				this.studentID.next(null);
				this.initData.next({
					...this.defaultInitData,
					location: this.initData.value.location,
					limit: {
						...this.initData.value.limit,
						limitExceed: this.initData.value.limit.max === this.initData.value.limit.studentsCount,
					},
					isOpenedWithAdd: true,
				});
				this.mode.next(StudentProfileMode.add);
			}
		});
		this.eventBus.subscribe(StudentChangedEvent, (student: StudentChangedEvent) => {
			const {studentID, classes, groups, teacherID, schoolID, specialistGroups, additional, ...rest} = student;
			this.studentID.next(studentID);

			this.initData.next({
				...this.initData.value,
				additional: additional ?? this.initData.value.additional,
				general: {...this.initData.value.general, ...rest},
				location: {
					...this.initData.value.location,
					classIDs: classes,
					groupIDs: groups,
					specialistGroupIDs: specialistGroups ? specialistGroups.map(item => item.id) : this.initData.value?.location?.specialistGroupIDs,
					schoolID,
					teacherID,
				},
			});
			if (this.mode.value === StudentProfileMode.add && this.currentUser.canEditStudents) {
				this.mode.next(StudentProfileMode.edit);
			}
		});
	}

	public init(studentID: number | null, preSelected?: PreSelected) {
		this.preSelected = preSelected;
		this.studentID.next(studentID);
		let mode: StudentProfileMode;
		if (!studentID) {
			mode = StudentProfileMode.add;
		} else if (this.currentUser.canEditStudents) {
			mode = StudentProfileMode.edit;
		} else {
			mode = StudentProfileMode.view;
		}
		this.mode.next(mode);
		const searchParams = new URLSearchParams({
			...(studentID ? {studentID: studentID.toString()} : {}),
			...(preSelected?.userID ? {userID: preSelected.userID.toString()} : {userID: this.currentUser.userID.toString()}),
			globalSchoolYearID: this.currentUser.globalSchoolYearID?.toString(),
		});
		return this.httpClient.ESGIApi.get<ProfileInitResponse>(
			this.controller,
			`init?${searchParams}`,
		).pipe(
			tap((v) => {
				const {classIDs, groupIDs} = v.value.location;
				const {
					classIDs: currentClassIDs = [],
					groupIDs: currentGroupIDs = [],
				} = this.initData.value?.location || {};
				const initData = {
					...v.value,
					dictionary: {...dictionary, ...v.value.dictionary},
					location: {
						...v.value.location,
						classIDs: classIDs.length ? classIDs : currentClassIDs,
						groupIDs: groupIDs.length ? groupIDs : currentGroupIDs,
					},
				};
				this.initData.next(initData);
				this.defaultInitData = initData;
			}),
			tap(() => {
				if (!studentID) {
					this.initData.next({...this.initData.value, isOpenedWithAdd: true});
				}
			}),
			map((v) => v.value),
		);
	}

	public imageChanged = (data: ImageResponse) => {
		this.initData.next({...this.initData.value, photo: data});
	};

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	public deleteStudent = () => {
		const model = {
			studentID: this.studentID.value,
			userType: this.currentUser.userType,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
		};

		return this.httpClient.ESGIApi.post(this.controller, 'delete', model)
			.pipe(tap(() => {
				this.eventBus.dispatch(StudentRemovedEvent, {id: this.studentID.value});
				this.eventBus.dispatch(StudentManagerEvents.StudentsDeleted, {
					studentIDs: [this.studentID.value],
					studentName: `${this.initData.value.general.firstName} ${this.initData.value.general.lastName}`,
				});
			}));
	};

	public setSelectedSchoolId = (schoolId: number) => {
		this.selectedSchoolID.next(schoolId);
	};
}
