import {useCallback, useEffect, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {SelectableList} from '@esgi/ui/layout';
import {TestItem} from '../../../types';
import {useTestsRootContext} from '../tests-root/context';
import {SortDirectionTypes, SortFieldTypes} from '../tests-root/models';
import {options, styles} from './model';
import {StyledGridBox} from './index.styled';

type Props<ListItem extends TestItem> = {
	list: ListItem[];
	listVocabulary: string[];
	onSetSelectedEntities: (value: string[]) => void;
	children(props: ListItem): JSX.Element;
};

export function TestsList<ListItem extends TestItem>({list, listVocabulary, onSetSelectedEntities, children}: Props<ListItem>) {
	const [listState, setListState] = useState(list);

	const {sort} = useTestsRootContext();

	const handleSort = useCallback(() => {
		if (sort.field === SortFieldTypes.Name) {
			setListState((state) => {
				return [...state].sort((a, b) => {
					if (sort.direction === SortDirectionTypes.Ascending) {
						return a.name?.localeCompare(b.name);
					}

					return b.name?.localeCompare(a.name);
				});
			});
		}
	}, [sort]);

	useEffect(() => {
		handleSort();
	}, [handleSort]);

	return (
		<OverlayScrollbarsComponent style={styles} options={options} defer>
			<SelectableList>
				<SelectableList.GroupRoot
					type='multiple'
					value={listVocabulary}
					onValueChange={onSetSelectedEntities}
				>
					<SelectableList.Group>
						<StyledGridBox>
							{listState.map((listItem) => children?.(listItem))}
						</StyledGridBox>
					</SelectableList.Group>
				</SelectableList.GroupRoot>
			</SelectableList>
		</OverlayScrollbarsComponent>
	);
}
