import {ClassBucket} from './types';
import {createContext, useContext} from 'react';

export type ClassContextValue = {
	classBuckets: ClassBucket[];
};

export const ClassesContext = createContext<ClassContextValue | null>(null);

export function useClassesContext() {
	const context = useContext(ClassesContext);

	if (!context) {
		throw new Error('ClassesContext is null');
	}

	return context;
}
