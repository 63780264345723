import {Radio} from '@esgillc/ui-kit/control';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {PureComponent, ReactNode} from 'react';
import {ViewMode} from 'shared/modules/test-details/models';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

import styles from './self-assess.module.less';
import {FlexBox} from '@esgi/ui/layout';

interface Props {
	mode: ViewMode;
	onModeChanged: (mode: ViewMode) => void;
}

export class SelfAssessSwitch extends PureComponent<Props> {
	public render(): ReactNode {
		return <>
			<span className={styles.title}>View:</span>
			<FlexBox wrap='wrap' align='center' style={{width: '100%'}}>
			<Radio value={ViewMode.TeacherOneOnOne}
			       checked={this.props.mode === ViewMode.TeacherOneOnOne}
			       className={styles.radio}
			       onClick={() => this.props.onModeChanged(ViewMode.TeacherOneOnOne)}>
				<span className={styles.label}>
					Teacher-one-on-one
				</span>
			</Radio>
			<FlexBox align='center'>
			<Radio value={ViewMode.SelfAssess}
			       checked={this.props.mode === ViewMode.SelfAssess}
			       className={styles.radio}
			       onClick={() => this.props.onModeChanged(ViewMode.SelfAssess)}>
				<span className={styles.label}>
					Student-self-assess
				</span>
			</Radio>
			<OnHoverTooltip message='This test is also available for student self-assess.'>
				<QuestionMarkIcon className={styles.questionMarkIcon}/>
			</OnHoverTooltip>
			</FlexBox>
			</FlexBox>
		</>;
	}
}
