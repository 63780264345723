import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Root} from './styled';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useService} from '@esgi/core/service';
import {TestDashboard} from './service';
import {Text} from '@esgi/ui/typography';
import {StudentStats} from './components/student-stats';
import {useTestDashboardContext} from '../layout/outlet-context';
import {isNull, isUndefined, max, noop} from 'underscore';
import {Testing, TestLauncherData, TestLauncherState, TestSavedEvent} from '@esgi/main/features/assessments';
import {TestContentArea} from '@esgi/main/kits/common';
import {Header} from './components/header/index';
import {TestInfo} from '../kit/tets-info';
import {Class, Student} from '@esgi/main/libs/store';
import {useEventEffect} from '@esgillc/events';
import {
	TestSessionRubricCreatedEvent,
	TestSessionScoreCreatedEvent,
	TestSessionUpdated,
	TestSessionYNCreatedEvent,
} from '@esgi/main/features/teacher/test-session-details';
import {AnswerState, TestType} from '../types';
import {getClassStudentsIDs} from './utils/get-class-students-ids';
import {Subscription} from 'rxjs';
import {useFlashcardUrlWithParams} from '@esgi/main/features/teacher/utils';
import {ScrollArea} from '../kit';

export function StudentTestDashboard() {
	const {
		testId,
		subjectID,
		subjectType,
		classesList,
		studentId,
		studentsList,
		studentFromClassId,
		studentFromGroupId,
		groupsList,
		isTablet,
		initVisibleSection,
	} = useTestDashboardContext();
	const flashcardUrl = useFlashcardUrlWithParams();

	const [testLauncherState, setTestLauncherState] = useState<TestLauncherState | null>(null);

	const service = useService(TestDashboard);

	const initRequestSubscription = useRef<Subscription | null>(null);
	const [isInitialized, setIsInitialized] = useState(false);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	const onCloseTestLauncher = () => {
		setTestLauncherState((prev) => ({...prev, isOpen: false}));
	};

	const onStartTestHandler = () => {
		if (isNull(testInfo)) {
			return;
		}

		const commonData: Pick<TestLauncherData, 'contentArea' | 'testType' | 'testID'> = {
			contentArea: TestContentArea[testInfo.contentArea],
			testType: testInfo.testType,
			testID: testId,
		};

		if (!testSessions.length) {
			setTestLauncherState({
				isOpen: true,
				data: {
					...commonData,
					studentResult: null,
					analyticsData: null,
					isIncorrectDisabled: false,
				},
			});

			return;
		}

		const lastSession = testSessions[0]!;
		const penultimateSession = testSessions[1];

		const deltaRubric =
			testInfo.testType === TestType.Rubric
				? lastSession.correctAnswers - (penultimateSession?.correctAnswers ?? 0)
				: null;

		const correctAnswersYnScore = lastSession.answers.reduce((currentSum, {answerState}) => {
			if (answerState === AnswerState.Correct) {
				return currentSum + 1;
			}

			return currentSum;
		}, 0);

		const resultYnScore = [TestType.YN, TestType.Score].includes(testInfo.testType)
			? Math.round((correctAnswersYnScore / testInfo.totalPossible) * 100)
			: null;

		const averageSessionDuration = testSessions.reduce((acc, {duration}) => (acc += duration), 0) / testSessions.length;

		setTestLauncherState({
			isOpen: true,
			data: {
				...commonData,
				studentResult: {
					correctAnswers: lastSession.correctAnswers,
					lastTestSessionRubricAnswers: lastSession.rubricAnswers,
					result: resultYnScore ?? 0,
					delta: deltaRubric ?? 0,
				},
				isIncorrectDisabled: lastSession.correctAnswers === testInfo.totalPossible,
				analyticsData: {
					total: testInfo.totalPossible,
					averageSessionDuration,
					lastSessionDate: new Date(lastSession.testDate),
					sessionCount: testSessions.length,
				},
			},
		});
	};

	useEffect(() => {
		fetchData({withSkeleton: true});

		return () => initRequestSubscription.current?.unsubscribe();
	}, [service, testId, studentId, subjectID, subjectType, studentFromClassId, classesList]);

	const fetchData = useCallback(
		(params?: {withSkeleton?: boolean}) => {
			const {withSkeleton} = params ?? {withSkeleton: false};

			if (withSkeleton) {
				setIsDataLoaded(false);
			}

			initRequestSubscription.current?.unsubscribe();

			if (!isNull(studentId) && classesList.length) {
				initRequestSubscription.current = service
					.init({
						testID: testId,
						studentID: studentId,
						subjectID,
						subjectType,
						classStudentsIDs: getClassStudentsIDs({
							classesList,
							studentFromClassID: studentFromClassId,
							studentID: studentId,
						}),
					})
					.subscribe(() => {
						setIsInitialized(true);

						if (withSkeleton) {
							setIsDataLoaded(true);
						}
					});
			}
		},
		[classesList, service, studentFromClassId, studentId, subjectID, subjectType, testId],
	);

	const selectedStudentClass = useMemo<Class | null>(() => {
		if (!isNull(studentId)) {
			if (!isNull(studentFromClassId)) {
				return classesList.find(({id}) => studentFromClassId === id) ?? null;
			}

			if (!isNull(studentFromGroupId)) {
				const selectedGroup = groupsList.find(({id}) => studentFromGroupId === id);
				const selectedClassByGroupId = classesList.find(({id}) => selectedGroup?.classID === id);

				return selectedClassByGroupId ?? null;
			}

			const studentsClasses = classesList.filter(({studentIDs}) => studentIDs.includes(studentId));
			const classWithMaxStudents = max(studentsClasses, ({studentIDs}) => studentIDs.length);

			return typeof classWithMaxStudents === 'number' ? null : classWithMaxStudents;
		}

		return null;
	}, [classesList, groupsList, studentFromClassId, studentFromGroupId, studentId]);

	const selectedStudent = useMemo<Student | undefined>(
		() => studentsList.find(({id}) => id === studentId),
		[studentId, studentsList],
	);

	const testInfo = useBehaviorSubject(service.testInfo);
	const testSessions = useBehaviorSubject(service.testSessions);
	const trackDates = useBehaviorSubject(service.trackDates);
	const gradeRanges = useBehaviorSubject(service.gradeRanges);
	const classCorrectAnswersAvg = useBehaviorSubject(service.classCorrectAnswersAvg$);

	useEventEffect(
		TestSavedEvent,
		({data: {testSessionID}}) => {
			service.getSessionDataRequest({testSessionID}).subscribe(({testSession}) => {
				service.addTestSession(testSession);
			});
		},
		[],
	);

	useEventEffect(TestSessionYNCreatedEvent, () => {
		fetchData();
	});

	useEventEffect(TestSessionScoreCreatedEvent, () => {
		fetchData();
	});

	useEventEffect(TestSessionRubricCreatedEvent, () => {
		fetchData();
	});

	useEventEffect(TestSessionUpdated, () => {
		fetchData();
	
	});

	const handleTest = useCallback(()=> {
		fetchData();
	}, [fetchData]);

	if (!isInitialized) {
		return (
			<Root>
				<Text size='medium' bold>
					Loading...
				</Text>
			</Root>
		);
	}

	return (
		<Root dataCy='test-dashboard'>
			<Header
				userInfo={selectedStudent}
				onStartTestHandler={onStartTestHandler}
				onDownload={() => null}
				subjectID={subjectID}
				testID={testId}
			/>
			<ScrollArea isTablet={isTablet} initVisibleSection={initVisibleSection}>
				<TestInfo testID={testId} testInfo={testInfo} onTestEdited={handleTest} />

				{!isUndefined(selectedStudent) && !isNull(classCorrectAnswersAvg) && (
					<StudentStats
						gradeRanges={gradeRanges}
						isDataLoaded={isDataLoaded}
						testInfo={testInfo}
						testSessions={testSessions}
						trackDates={trackDates}
						studentClassID={selectedStudentClass?.id ?? 0}
						studentFirstName={selectedStudent.firstName}
						studentLastName={selectedStudent.lastName}
						studentID={selectedStudent.id}
						classCorrectAnswersAvg={classCorrectAnswersAvg}
						isTablet={isTablet}
					/>
				)}
			</ScrollArea>
			{testLauncherState?.isOpen && !isNull(selectedStudentClass) && selectedStudent && (
				<Testing
					onFlashCardsClicked={() =>
						flashcardUrl({
							studentId: selectedStudent?.id.toString(),
							testId: testId.toString(),
							subjectId: subjectID.toString(),
						})}
					onTestSessionDetailsClicked={noop}
					student={selectedStudent}
					subject={{
						id: subjectID,
						type: subjectType,
						name: testInfo?.subjectName ?? '',
					}}
					studentClass={selectedStudentClass}
					launcherData={testLauncherState.data}
					onClose={onCloseTestLauncher}
				/>
			)}
		</Root>
	);
}
