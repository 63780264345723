import {RadioGroupItemProps as RadioGroupItemRadixProps} from '@radix-ui/react-radio-group';
import {ComponentPropsWithoutRef, MouseEvent, forwardRef, useCallback, useId, useRef} from 'react';
import {useRadioGroupContext} from '../../context';
import {BaseComponentProps} from '../../../../types';
import {Container, Input, Label} from './index.styled';
import {useComposedRefs} from '@esgi/ui/utils';

type RadioGroupItemProps = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'button'>, 'color'> &
	Omit<RadioGroupItemRadixProps, 'asChild'>;

export const RadioGroupItem = forwardRef<HTMLButtonElement, RadioGroupItemProps>(
	(
		{dataCy = 'ui-kit-radio-group-item', css = {}, onClick, disabled = false, children, id: externalId, ...props},
		forwardedRef,
	) => {
		const {selectedValue, setSelectedValue, disabledAllItems} = useRadioGroupContext();

		const id = useId();
		const inputId = externalId ?? id;

		const inputOwnRef = useRef<HTMLButtonElement>(null);
		const inputRef = useComposedRefs(forwardedRef, inputOwnRef);

		const isItemSelected = selectedValue === props.value;

		const handleContainerClick = useCallback(() => {
			inputOwnRef.current?.click();
		}, []);

		const handleInputClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation();

				if (disabled || isItemSelected) {
					return;
				}

				setSelectedValue(props.value);
				onClick?.(event);
			},
			[onClick, props.value, setSelectedValue, disabled, isItemSelected],
		);

		return (
			<Container
				dataCy={`${dataCy}-container-box`}
				data-type={children ? 'embedded' : 'single'}
				data-state={selectedValue === props.value ? 'checked' : 'unchecked'}
				data-disabled={disabled || disabledAllItems ? '' : undefined}
				onClick={handleContainerClick}
				css={css}
			>
				<Input data-cy={dataCy} id={inputId} ref={inputRef} onClick={handleInputClick} {...props} />
				{children && (
					<Label data-cy={`${dataCy}-label`} htmlFor={inputId}>
						{children}
					</Label>
				)}
			</Container>
		);
	},
);
