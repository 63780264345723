import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {BingoTestSettings, initialTestSettingsValue} from '../model';

export class TestSettingsService extends BaseService {
	public readonly testSettings$ = new BehaviorSubject<BingoTestSettings>(initialTestSettingsValue);

	public setTestSettings(field: keyof BingoTestSettings, value: BingoTestSettings[typeof field]) {
		this.testSettings$.next({
			...this.testSettings$.value,
			[field]: value,
		});
	}
}