import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Link as ExternalLink} from 'react-router-dom';

export const Link = styled(ExternalLink, {
	all: 'unset',
	cursor: 'pointer',
});

export const UnderlinedText = styled(Text, {
	textDecoration: 'underline',
});
