import {BoxArrowUpRight, Skip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Content, StyledButton, ButtonText, Container, ContentWrapper} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import React, {useCallback} from 'react';
import {DesignVersion, routes, useDesignVersion} from '@esgi/main/libs/core';

export function VersionToggleBanner() {
	const [, setDesignVersion] = useDesignVersion();

	const onOldDesignClicked = useCallback(() => {
		setDesignVersion(DesignVersion.Old);
		location.href = routes.teacher.home.root;
	}, []);

	return (
		<Container>
			<ContentWrapper>
				<Content>
					<GridBox align='center' flow='column' gap='3'>
						<Text size='large'>Beta version</Text>
						<StyledButton>
							<Text size='large'>
								<a href='https://info.esgisoftware.com/release-notes/new-teacher-dashboard-beta' target='_blank' rel='noreferrer'>
									View Updates
								</a>
								</Text>
							<BoxArrowUpRight/>
						</StyledButton>
					</GridBox>
					<ButtonText onClick={onOldDesignClicked}>
						<Skip/>
						<Text size='small'>
							Return to Old ESGI
						</Text>
					</ButtonText>
				</Content>
			</ContentWrapper>
		</Container>
	);
}