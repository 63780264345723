import React, {ComponentPropsWithoutRef, forwardRef, PropsWithChildren} from 'react';
import {AvatarFallbackProps as RadixAvatarFallbackProps} from '@radix-ui/react-avatar';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {StyledAvatarFallback} from './styled';

type AvatarFallbackProps =
	PropsWithChildren
	& ComponentPropsWithoutRef<'div'>
	& BaseComponentProps
	& Skeletonable
	& RadixAvatarFallbackProps

export const AvatarFallback = forwardRef<HTMLDivElement, AvatarFallbackProps>(({
	children,
	dataCy = 'ui-kit-avatar-fallback',
	...props
}: AvatarFallbackProps, ref) => {
	return <StyledAvatarFallback data-cy={dataCy} ref={ref} {...props}>
		{children}
	</StyledAvatarFallback>;
});


