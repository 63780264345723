import React, {createRef, ReactNode, useRef} from 'react';
import {Card, ExtendedTestType} from '../../models';
import {
	CloseIcon,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalManager,
	Title,
} from '@esgi/deprecated/ui-kit/modal';
import styles from './test-type-selector.module.less';
import {DesignVersion, useDesignVersion} from '@esgi/main/libs/core';
import {TestCard} from '../test-card/test-card';

interface Props {
	onSelected: (type: ExtendedTestType) => void;
	onClose: () => void;
}


const cards: Card[] = [
	{
		id: 'yes-no-creating-button',
		title: 'Yes/No',
		imageSrc: '//esgiwebfiles.s3.amazonaws.com/images/tests/creating/yn.png',
		description: `Tests contain multiple questions with correct (Yes) or incorrect (No)
		answers.`,
		type: ExtendedTestType.YN,
		alt: 'YN',
		designVersion: [DesignVersion.Old, DesignVersion.Redesign],
	},
	{
		id: 'self-assess-creating-button',
		title: 'Self-Assess',
		imageSrc:
			'//esgiwebfiles.s3.amazonaws.com/images/tests/creating/self-assess.png',
		description: 'Using our Item Bank, create customized self-assess tests for students.',
		type: ExtendedTestType.SelfAssess,
		alt: 'Self-Assess',
		designVersion: [
			DesignVersion.Redesign,
			//TODO: revert this feature after question bank will be accepted by client
		],
	},
	{
		id: 'score-creating-button',
		title: 'Single Score',
		imageSrc: '//esgiwebfiles.s3.amazonaws.com/images/tests/creating/ss.png',
		description: 'Tests contain one question that receives a single score.',
		type: ExtendedTestType.Score,
		alt: 'SS',
		subtitle: '“Count to Five”',
		designVersion: [DesignVersion.Old, DesignVersion.Redesign],
	},
	{
		id: 'rubric-creating-button',
		title: 'Rubric',
		imageSrc:
			'//esgiwebfiles.s3.amazonaws.com/images/tests/creating/rubric.png',
		description: `Set criteria and levels to measure student performance. Useful for
		observational assessments.`,
		type: ExtendedTestType.Rubric,
		alt: 'Rubric',
		designVersion: [DesignVersion.Old, DesignVersion.Redesign],
	},
];

function TestTypeSelector({onSelected, onClose}: Props) {
	const [designVersion] = useDesignVersion();
	const modalManagerRef = useRef<ModalManager>();

	return (
		<Modal modalManagerRef={modalManagerRef}>
			<ModalHeader>
				<Title>Create New Test</Title>
				<CloseIcon
					className={styles.closeIcon}
					onClick={() => modalManagerRef.current.close(onClose)}
				/>
			</ModalHeader>
			<ModalBody className={styles.modalBody}>
				<div>
					<div className={styles.subHeader}>
						Begin by Selecting Your Test Type:
					</div>
					<div className={styles.cards}>
						{cards
							.filter((card) => card.designVersion.includes(designVersion))
							.map((card) => (
								<TestCard
									key={card.id}
									card={card}
									onClick={() =>
										modalManagerRef.current.close(() => onSelected(card.type))
									}
								/>
							))}
					</div>
				</div>
			</ModalBody>
			<ModalFooter />
		</Modal>
	);
}
export default TestTypeSelector;