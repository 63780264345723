import {useCallback, useRef} from 'react';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {noop} from 'underscore';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';
import {GroupItem} from 'pages/assignment-center/components/create-assignment/types/types';

export type GroupDropdownItem = GroupItem & {
	selected: boolean;
	hasStudents: boolean;
};

interface Props {
	groups: GroupDropdownItem[];
	setGroups: (value: GroupDropdownItem[]) => void;
	onSave: VoidFunction;
	onCancel: VoidFunction;
}

export function GroupDropdown({groups, setGroups, onSave, onCancel}: Props) {
	const ref = useRef<Dropdown<any>>(null);
	const placeholder = 'Select groups';

	const onGroupSelect = useCallback(
		(value: GroupDropdownItem) => {
			const updatedGroups = groups.map((group) => ({
				...group,
				selected: group.id === value.id ? !group.selected : group.selected,
			}));

			setGroups(updatedGroups);
		},
		[groups, setGroups],
	);

	const handleCloseDropdown = useCallback(() => ref.current?.closeDropdown(), []);

	const handleCancel = useCallback(() => {
		handleCloseDropdown();
		onCancel();
	}, [onCancel, handleCloseDropdown]);

	const handleSave = useCallback(() => {
		handleCloseDropdown();
		onSave();
	}, [handleCloseDropdown, onSave]);

	return (
		<Dropdown
			ref={ref}
			multiselect
			optionName='name'
			value={[]}
			setValue={noop}
			placeholder={placeholder}
			displayLabel={() => placeholder}
		>
			<div className={styles.container}>
				{groups.map((group) => {
					return (
						<OnHoverTooltip
							message={!group.hasStudents ? 'This group does not have any students.' : ''}
							className={styles.tooltip}
							key={group.id}
						>
							<div>
								<Option
									className={join(styles.class, !group.hasStudents && styles.notActiveClass)}
									value={group}
									checked={!group.hasStudents ? false : group.selected}
									disabled={!group.hasStudents}
									onClick={onGroupSelect}
								>
									{group.name}
								</Option>
							</div>
						</OnHoverTooltip>
					);
				})}
			</div>
			<div className={styles.footer}>
				<Buttons.Gray onClick={handleCancel}>Cancel</Buttons.Gray>
				<Buttons.Contained onClick={handleSave}>Save</Buttons.Contained>
			</div>
		</Dropdown>
	);
}
