import {CSS} from '@stitches/react';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {Box} from '../../../box';

type CardSeparatorProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const CardSeparator = forwardRef<HTMLDivElement, CardSeparatorProps>(({dataCy, ...props}, forwaredRef) => (
	<Separator data-cy={dataCy ?? 'ui-kit-card-separator'} {...props} ref={forwaredRef} />
));

// 12px - an inline padding of a card
const Separator = styled(Box, {
	marginLeft: -12,
	marginRight: -12,
	width: 'calc(100% + 12px + 12px)',
	height: 1,
	backgroundColor: '$border',
});
