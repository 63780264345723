import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Button, ContextMenu} from '@esgi/ui';
import {Input} from '@esgi/ui/controls';

export const Layout = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	width: '100%',
	background: '$background',
	borderBottom: '1px solid $border',
	padding: '15px 20px',
});

export const AddButton = styled(Button.Icon, {
	marginLeft: 24,
	variants: {
		active: {
			true: {
				color: '$primary',
			},
		},

		variant: {
			filled: {
				transition: 'width .3s, height .3s, border-radius .3s, background-color .3s',
				width: 28,
				height: 28,
				borderRadius: 6,
				backgroundColor: '$vivid',

				'&:hover': {
					width: 28,
					height: 28,
					borderRadius: '50%',
				},
			},
		},
	},
});

export const SearchInput = styled(Input.Iconable, {
	width: '360px',
});

export const ContextMenuItem = styled(ContextMenu.Item, {
	gap: '6px',
	cursor: 'pointer',
});