import {useState} from 'react';
import {FindMoreService} from '../../../../find-more-service';
import {FilterModel, TestModel} from '../../../../types';
import {Column} from './components/column';
import {Row} from './components/row';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {AddTestToSubject} from '../../../add-to-subject';
import {AddToTestSubjectCancel} from '../../../../events';
import {dispatchAppEvent} from '@esgillc/events';

interface Props {
	service: FindMoreService;
}

export const Results = ({service}: Props): JSX.Element => {
	const rows = useBehaviorSubject<TestModel[]>(service.rows);
	const filter = useBehaviorSubject<FilterModel>(service.filter);
	const firstLoad = useBehaviorSubject<boolean>(service.firstLoad);
	const [selectedTest, setSelectedTest] = useState<TestModel>(null);

	const onToggle = (test: TestModel, checked: boolean) => {
		if (checked) {
			setSelectedTest(test);
		}
	};

	const onSubjectModalClose = () => {
		dispatchAppEvent(AddToTestSubjectCancel, new AddToTestSubjectCancel(selectedTest.id));
		setSelectedTest(null);
	};

	return (
		<div className='results-wrapper'>
			<div className={'results ' + (!rows.length ? 'empty' : '')}>
				<table className='table table-bonnie table-hover table-striped table-responsive'>
					<thead>
						<tr>
							<Column sorted={false}/>
							<Column
								title='Test Name'
								sorted={filter.sortBy === 'TestName'}
								sortDirection={filter.sortDirection}
								clicked={() => service.applySort('TestName')}
							/>
							<Column
								title='Create Date'
								class='create-date'
								sorted={filter.sortBy === 'CreateDate'}
								sortDirection={filter.sortDirection}
								clicked={() => service.applySort('CreateDate')}
							/>
							<Column
								title='Questions' class='questions'
								sorted={filter.sortBy === 'NumberOfQuestions'}
								sortDirection={filter.sortDirection}
								clicked={() => service.applySort('NumberOfQuestions')}
							/>
							<Column
								title='Subject Tab'
								class='subjectTab'
								sorted={false}
							/>
						</tr>
					</thead>
					<tbody>
					{!rows.length && !firstLoad &&
						<tr>
							<td colSpan={6}>Search by keyword or select filters above</td>
						</tr>
					}
					{!rows.length && firstLoad &&
						<tr>
							<td colSpan={6}>Your search returned 0 results</td>
						</tr>
					}
					{rows.map(t => (
						<Row
							key={t.id}
							model={t}
							onSelected={(checked) => onToggle(t, checked)}
						/>
					))}
					</tbody>
				</table>
			</div>
			{selectedTest &&
				<AddTestToSubject test={selectedTest} onClose={onSubjectModalClose} service={service}/>
			}
		</div>
	);
};
