import {styled} from '@esgi/ui/theme';
import {Checkbox} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {Box, FlexBox} from '@esgi/ui/layout';
import {OneLinedText} from '@esgi/main/kits/assignments';

export const NameCell = styled(Box, {
	display: 'flex',
	height: '32px',
	padding: '4px 6px 4px 0',
	alignItems: 'center',
});

export const NameText = styled(OneLinedText, {
	color: '$primary',
	cursor: 'pointer',
	variants: {
		readonly: {
			true: {
				color: '$neutral40',
				cursor: 'default',
			},
		},
	},
});

export const NameCheckbox = styled(Checkbox, {
	padding: 0,
	marginRight: 10,

	'&:disabled': {
		'& > div': {
			borderColor: '$border',
			background: '$border',
		},
	},
});

export const Timestamp = styled(Text, {
	display: 'flex',
	alignItems: 'center',
	borderRadius: 2,
	height: '16px',
	background: '$vivid',
	border: '1px solid $muted',
	padding: '0 4px',
});



export const Cell = styled(FlexBox, {
	alignItems: 'center',
	width: '100%',
	height: '100%',
});