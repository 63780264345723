import React from 'react';
import {isIOS} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import Download from './download';
import './header.less';

class Props {
    studentName: string;
    testName: string;
    testBy: string;
    showTestedBy: boolean;
    showDownload: boolean;
		showIEP: boolean;
    testSessionsNumber: number;
    deletedCurrentSession: boolean;
    download: (current: boolean) => void;
    isSelfAssess: boolean = false;
}

export default class Header extends React.PureComponent<Props> {
    private isIOS: boolean = isIOS();

    componentDidMount(): void {
    	if (this.isIOS) {
    		$('.tsd-header .subheader').css('line-height', '30px');
    	}
    }

    render() {
    	return <div className='tsd-header'>
            <div className='header'>
                <div>
                    Test Session Details
                  <sup className='help-container'>
                    <OnHoverTooltip message='Help'>
                      <a href='https://support.esgisoftware.com/hc/en-us/articles/360024519772-Details-and-History-Buttons' target='_blank' rel='noreferrer'>
                        <i className='fa fa-question-circle' />
                      </a>
                    </OnHoverTooltip>
                  </sup>
                </div>
                <div className='icons-container'>
                    {this.props.isSelfAssess &&
                        <OnHoverTooltip message='Student self-assess'>
                            <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <circle cx='14.0385' cy='14.0385' r='12' fill='white'/>
                                <path d='M21.5258 21.5385H6.55112C6.78049 17.0267 10.0946 13.5385 14.0385 13.5385C17.9823 13.5385 21.2964 17.0267 21.5258 21.5385Z' fill='#BA5ACB' stroke='white'/>
                                <circle cx='14.0385' cy='10.0385' r='5' fill='#FFA53D' stroke='white' strokeWidth='2'/>
                                <circle cx='14' cy='14' r='13' stroke='#059BE0' strokeWidth='2'/>
                            </svg>
                        </OnHoverTooltip>
                    }
										{
											this.props.showIEP && <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
													<circle cx='14.0385' cy='15' r='12' fill='white'/>
													<path d='M8.13064 10.4688V19H6.42556V10.4688H8.13064ZM14.8881 17.582V19H11.1791V17.582H14.8881ZM11.7123 10.4688V19H9.99548V10.4688H11.7123ZM14.39 13.9258V15.3027H11.1791V13.9258H14.39ZM14.8763 10.4688V11.8926H11.1791V10.4688H14.8763ZM19.1963 15.9941H17.4502V14.5703H19.1904C19.4404 14.5703 19.6416 14.5156 19.7939 14.4062C19.9463 14.2969 20.0576 14.1465 20.1279 13.9551C20.1982 13.7598 20.2334 13.541 20.2334 13.2988C20.2334 13.0527 20.1982 12.8223 20.1279 12.6074C20.0576 12.3926 19.9463 12.2207 19.7939 12.0918C19.6416 11.959 19.4424 11.8926 19.1963 11.8926H18.001V19H16.29V10.4688H19.1963C19.7705 10.4688 20.2646 10.5898 20.6787 10.832C21.0927 11.0742 21.4092 11.4082 21.6279 11.834C21.8506 12.2559 21.9619 12.7402 21.9619 13.2871C21.9619 13.834 21.8506 14.3105 21.6279 14.7168C21.4052 15.123 21.0869 15.4375 20.6728 15.6602C20.2627 15.8828 19.7705 15.9941 19.1963 15.9941Z' fill='#0088CC'/>
													<circle cx='14' cy='14.9615' r='13' stroke='#059BE0' stroke-width='2'/>
												</svg>
										}
                    {this.props.showDownload &&
                        <Download
                            testSessionsNumber={this.props.testSessionsNumber}
                            deletedCurrentSession={this.props.deletedCurrentSession}
                            download={this.props.download}
                        />
                    }
                </div>
            </div>
            {this.props.showTestedBy &&
                <div className='subheader'><div className='title'>Tested by:</div><div className='value'>{this.props.isSelfAssess ? 'Student' : this.props.testBy}</div></div>
            }
            <div className='subheader'><div className='title'>Student:</div><div className='value'>{this.props.studentName}</div></div>
            <div className='subheader'><div className='title'>Test Name:</div><div className='value'>{this.props.testName}</div></div>
        </div>;
    }
}
