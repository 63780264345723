import './styles.less';
import {Portal} from '@esgillc/ui-kit/core';
import {HierarchyEvents, HierarchyLoadedEvent} from 'modules/hierarchy/core/events';
import {BoxType} from 'modules/hierarchy/core/models';
import {SchoolYearChangedEvent} from 'modules/school-year';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {EventBusManager, useEventEffect} from '@esgillc/events';
import {userStorage, UserType} from '@esgi/core/authentication';
import {HintPopup} from 'shared/alert/hint-popup/hint-popup';
import {ClassCreatedEvent, ClassRemovedEvent} from 'modules/forms/class-form/events';
import {
  GroupOfSpecialistsCreatedEvent, GroupOfSpecialistsRemovedEvent,
  SchoolsGroupCreatedEvent, SchoolsGroupRemovedEvent, TeachersGroupCreatedEvent, TeachersGroupRemovedEvent,
} from 'modules/forms/admin-group-forms/events';
import {HideGetStartedEvent, UnHideGetStartedEvent} from './events';
import {GroupCreatedEvent, GroupRemovedEvent} from 'modules/forms/group-form/events';

interface Props {
	pointToRef: React.RefObject<HTMLElement>;

	top: number;
	left: number;

	arrowTop: number;
	arrowLeft: number;
}

function getMessage() {
	const user = userStorage.get();
	const current = user.currentGlobalSchoolYearID;
	const selected = user.globalSchoolYearID;
	const isSelectedCurrentSchoolYear = current === selected;

	const userType = userStorage.get().userType;
	if (userType === UserType.T) {
		if (isSelectedCurrentSchoolYear) {
			return 'To begin testing add some students. Click the + icon to get started.';
		} else {
			return 'Students can be added by selecting the + button. Note: Students can only be tested in the current school year. To adjust your school year, select the School Year option at the top of this panel.';
		}

	}

	if (userType === UserType.ISD || userType === UserType.ISS) {
		if (isSelectedCurrentSchoolYear) {
			return 'To begin testing add some students. Click on \'Student Manager\' to get started.';
		} else {
			return 'Students can be added by clicking \'Student Manager\'. Note: Students can only be tested in the current school year. To adjust your school year, select the School Year option at the top of this panel.';
		}

	}
}

export function GetStarted(props: Props): JSX.Element {
	const popupComponent = useRef<HintPopup>();
	const [message, setMessage] = useState(getMessage());
	const [hiddenByUser, setHiddenByUser] = useState(false);
	const [visible, setVisible] = useState(false);

	const setPosition = useCallback(() => {
		setTimeout(() => popupComponent.current?.setPosition(), 300);
	}, [popupComponent]);

	useEventEffect(ClassCreatedEvent, setPosition);
	useEventEffect(ClassRemovedEvent, setPosition);
	useEventEffect(GroupCreatedEvent, setPosition);
	useEventEffect(GroupRemovedEvent, setPosition);
  useEventEffect(SchoolsGroupCreatedEvent, setPosition);
  useEventEffect(SchoolsGroupRemovedEvent, setPosition);
  useEventEffect(TeachersGroupCreatedEvent, setPosition);
  useEventEffect(TeachersGroupRemovedEvent, setPosition);
  useEventEffect(GroupOfSpecialistsCreatedEvent, setPosition);
  useEventEffect(GroupOfSpecialistsRemovedEvent, setPosition);
	useEventEffect(UnHideGetStartedEvent, () => setVisible(true));
	useEventEffect(HideGetStartedEvent, () => setVisible(false));
	useEventEffect(SchoolYearChangedEvent, () => setMessage(getMessage()));

	useEffect(() => {
		const eventBus = new EventBusManager();
		eventBus.subscribe(HierarchyEvents.Common.Toggled, (args: HierarchyEvents.Common.ToggledArgs) => {
			if (args.boxType !== BoxType.ClassicStudent && !hiddenByUser) {
				setPosition();
			}
		});
		eventBus.subscribe(HierarchyLoadedEvent, () => setPosition());
		return () => eventBus.destroy();
	});

	if (hiddenByUser || !visible) {
		return;
	}

	return <Portal owner={document.body}>
		<div>
			<div id='get-started-hint-popup'>
				<HintPopup
					ref={popupComponent}
					left={props.left}
					arrowLeft={props.arrowLeft}
					arrowTop={props.arrowTop}
					top={props.top}
					pointToRef={props.pointToRef}
				>
					<div className='row'>
						<div className='col-md-12'>
							<span className='header'>Get Started</span>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<span className='text'>{message}</span>
						</div>
					</div>
					<div className='buttons'>
						<div className='button-link' onClick={() => setHiddenByUser(true)}>CLOSE</div>
					</div>
				</HintPopup>
			</div>
		</div>
	</Portal>;
}
