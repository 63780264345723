import {BaseService} from '@esgi/core/service';
import {Comparator} from './comparator';
import {InitResponse, Row, SortModel, PreassessActivityRequest} from './types';
import {BehaviorSubject, tap} from 'rxjs';
import {getSortDirection} from 'modules/reports/preassess-activity/utils';


export class PreassessActivityReportService extends BaseService {

	public sortModel = new BehaviorSubject<SortModel>({
		fieldName: 'None',
		direction: null,
		relatedSorts: null,
	});
	public reportRows = new BehaviorSubject<Row[]>([]);
	public initialReportRows = new BehaviorSubject<Row[]>([]);

	public sortRules = new BehaviorSubject<SortModel[]>([]);
	public fileName = 'Preassess_Activity_Report';

	private cacheKey: string = '';
	private readonly controller = 'reports/preassess-activity';

	private optionsToExport: PreassessActivityRequest;

	public init() {
		return this.httpClient.ESGIApi.get<InitResponse>(this.controller, 'init').pipe(tap(response => {
			const {cacheKey, sortModel, report} = response;
			const reportRows = report.rows.map((item, index) => ({
				...item,
				id: index,
			}));
			this.reportRows.next(reportRows);
			this.initialReportRows.next(reportRows);

			this.cacheKey = cacheKey;
			this.sortModel.next(sortModel);
			this.sortRules.next(report.sortRules);
			this.optionsToExport = {cacheKey, sortModel};
			this.sort(this.sortModel.value.fieldName, true);
		}));
	}

	public exportPdf() {
		return this.httpClient.ESGIApi.file(this.controller, 'download-pdf', this.fileName, this.optionsToExport).subscribe();
	}

	public exportExcel() {
		return this.httpClient.ESGIApi.file(this.controller, 'download-excel', this.fileName, this.optionsToExport).subscribe();
	}

	public sort = (name: string, isInitial?: boolean) => {
		let direction;
		if(isInitial){
			direction = this.sortModel.value.direction;
		}else {
			if (this.sortModel.value.fieldName === name) {
				direction = getSortDirection(this.sortModel.value.direction);
			} else {
				direction = getSortDirection('None');
			}
			this.sortModel.next({direction, fieldName: name});

			this.optionsToExport = {
				sortModel: {direction, fieldName: name},
				cacheKey: this.cacheKey,
			};
		}

		const sortRule = this.sortRules.value.find(rule => rule.fieldName?.toLowerCase() === name?.toLowerCase());
		const additionalSortModels = sortRule ? sortRule.relatedSorts : [];
		const comparator = new Comparator(this.sortModel.value, additionalSortModels);

		const rows = [...this.initialReportRows.value].sort((left, right) => {
			return comparator.comparer({...left, specialist: `${left.firstName} ${left.lastName}`},
				{...right, specialist: `${right.firstName} ${right.lastName}`});
		});
		this.reportRows.next(rows);
	};
}
