import {TestType} from '@esgi/core/enums';

export enum FontStyle {
	Normal = 0,
	Bold = 1,
	Italic = 2,
	Underline = 4
}

export class TextAlign {
	static Left: string = 'left';
	static Center: string = 'center';
	static Middle: string = 'middle';
	static Right: string = 'right';
}

export enum ShapeType {
	Image = 0,
	Text = 1,
	Rectangle = 2,
	Circle = 3,
	Triangle = 4,
	Line = 5,
	Arrow = 6,
}

export class EdgePosition {
	source: Position;
	target: Position;
	type: ShapeType;

	constructor(type: ShapeType, source: Position, target: Position) {
		this.type = type;
		this.source = source;
		this.target = target;
	}
}

export class VertexPosition {
	position: Position;
	type: ShapeType;

	constructor(type: ShapeType, position: Position) {
		this.type = type;
		this.position = position;
	}
}

export class VertexSize {
	size: ShapeSize;
	type: ShapeType;

	constructor(type: ShapeType, size: ShapeSize) {
		this.size = size;
		this.type = type;
	}
}

export class StyleDefinition {
	style: IGraphStyle;
	type: ShapeType;

	constructor(type: ShapeType, style: IGraphStyle) {
		this.style = style;
		this.type = type;
	}
}

export interface IGraphStyle {
}

export interface IRotationStyle {
	rotation: number;
}


export class ShapeSize {
	width: number;
	height: number;

	constructor(width?: number, height?: number) {
		this.width = width;
		this.height = height;
	}
}

export class Position {
	x: number;
	y: number;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}
}

export interface ImageGalaryItem {
	id: number;
	url: string;
}

export class VertexStyle implements IGraphStyle, IRotationStyle {
	shape: string = '';
	editable: number = 0;
	fillColor: string = '';
	strokeColor: string = '';
	rotation: number = 0;
	strokeWidth: number = 0;

	constructor(shape: string) {
		this.shape = shape;
	}
}

export class TextStyle implements IGraphStyle, IRotationStyle {
	spacing: number = 0;
	fontColor: string = '';
	fontFamily: string = '';
	fontSize: number = 0;
	fontStyle: number = 0;
	align: string = '';
	editable: number = 0;
	fillColor: string = '';
	strokeColor: string = '';
	verticalAlign: string = '';
	rotation: number = 0;
}

export class ImageStyle implements IGraphStyle, IRotationStyle {
	editable: number = 0;
	shape: string = '';
	image: string = '';
	rotation: number = 0;

	constructor() {
		this.shape = 'image';
	}
}

export class RectangleStyle extends VertexStyle {
	constructor() {
		super('rectangle');
	}
}

export class CircleStyle extends VertexStyle {
	constructor() {
		super('ellipse');
	}
}

export class TriangleStyle extends VertexStyle {
	constructor() {
		super('triangle');
	}
}

export class EdgeStyle implements IGraphStyle {
	strokeColor: string = '';
	strokeWidth: number = 0;
	endArrow: string = '';
	editable: number = 0;
}

export class LineStyle extends EdgeStyle {

}

export class ArrowStyle extends EdgeStyle {

}

export class ShapeDefinitions {
	public styles: StyleDefinition[] = [];
	public vertexSizes: VertexSize[] = [];
	public tempVertexSizes: VertexSize[] = [];
	public edgePositions: EdgePosition[] = [];
	public tempEdgePositions: EdgePosition[] = [];
	public vertexPositions: VertexPosition[] = [];
	public tempVertexPositions: VertexPosition[] = [];
}

export interface IQuestionResponse {
	questionID: number;
	testID: number;
	xml: string;
	images: ImageModel[];
	name: string;
	directions: string;
	stateStandardID?: number;
	stateStandard: string;
	pregenerated: boolean;
	testName: string;
	testType: string;
	questionsCount: number;
	orderNum: number;
	isWhiteBackground: boolean;
	draft: boolean;
}

export class QuestionModel {
	questionID: number;
	name: string;
	xml: string;
	images: ImageModel[] = [];
	testID: number;
	testName: string;
	testType: TestType;
	questionsCount: number;
	pregenerated: boolean;
	orderNum: number;
	isWhiteBackground: boolean;
	draft: boolean;

	constructor(r?: IQuestionResponse) {
		if (r) {
			this.questionID = r.questionID;
			this.name = r.name;
			this.testID = r.testID;
			this.xml = r.xml;
			this.images = r.images;
			this.testName = r.testName;
			this.orderNum = r.orderNum + 1;
			this.testType = TestType[r.testType];
			this.questionsCount = r.questionsCount;
			this.pregenerated = r.pregenerated;
			this.isWhiteBackground = r.isWhiteBackground;
			this.draft = r.draft;
		}
	}
}

export class TestIntroModel {
	id: number;
	testID: number;
	xml: string;
	images: ImageModel[] = [];
	pregenerated: boolean;
	isWhiteBackground: boolean;
}

export class ImageModel {
	id: number;
	url: string;
}

export interface IQuestionAddOptions {
	testID: number;
	name: string;
	xml: string;
	directions: string;
	testType: TestType;
}

export interface IQuestionUpdateOptions {
	questionID: number;
	name: string;
	xml: string;
	directions: string;
	stateStandardId: number;
}

export interface ITestIntroAddOptions {
	testID: number;
	xml: string;
}

export interface ITestIntroUpdateOptions {
	id: number;
	xml: string;
}

export interface IUpdateResult {
	id: number;
	name: string;
	xml: string;
	definitions: ShapeDefinitions
}

export interface IATCUpdateResult {
	tileID: string;
	name: string;
	xml: string;
	directions: string;
	stateStandardID: number;
	definitions: ShapeDefinitions
}