import {Drawer} from '@esgi/main/kits/common';
import {ToggleGroup} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const PanelSidebar = styled(Drawer.PanelSidebar, {
	display: 'grid',
	gap: '$5',
	gridAutoRows: 'max-content',
});

export const PanelSidebarHeader = styled(GridBox, {
	gap: 2,
	alignItems: 'center',
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	'& > svg': {
		width: 24,
		height: 24,
		fill: '$neutral56',

		'& > path': {
			fill: '$neutral56',
		},
	},
});

export const PanelContent = styled(Drawer.PanelContent, {
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	paddingTop: 40,
	overflow: 'hidden',
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	marginBottom: 12,
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	minWidth: 228,
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	paddingTop: 10,
	paddingBottom: 10,
	paddingLeft: 12,
	paddingRight: 12,
	justifyContent: 'flex-start',

	'&[data-state=off] + [data-state=off]': {
		'&[data-orientation=vertical]::before': {
			width: 'calc(100% - 24px)',
			height: 1,
			top: -2,
			left: 12,
		},
	},
});
