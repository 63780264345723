import {ContextMenu} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const ContextMenuItem = styled(ContextMenu.Item, {
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 12,
	paddingRight: 12,
	cursor: 'pointer',
});

export const AllSessionsItem = styled(ContextMenu.Item, {
	display: 'grid',
	gap: 6,
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	padding: 6,

	'& svg': {
		width: '16px',
		height: '16px',
	},
});
