import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {PanelTrigger} from '../panel-trigger';

export const WrapperBox = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '$3',
	backgroundColor: '$background',
	gridArea: 'header',
	paddingTop: 18,
	paddingBottom: 18,
	paddingRight: 20,
	paddingLeft: 20,
	borderBottomColor: '$border',
	borderBottomStyle: 'solid',
	borderBottomWidth: 1,
});

export const ActionButtonsBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 20,
	alignItems: 'center',

	[`& > ${PanelTrigger}`]: {
		transform: 'rotate(-180deg)',
	},
});
