import React from 'react';
import {fromEvent, merge, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {SubjectType} from '@esgi/core/enums';
import {GradeScaleLauncher} from 'shared/modules/grade-scale/grade-scale-launcher/grade-scale-launcher';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import {GradeScaleHierarchyLevel} from 'shared/modules/reports/grade-scale-report/models';
import TrackConfirmModal from 'modules/track-confirm';
import {ITrackModel} from 'pages/home/components/school-year-selector/api';
import {UserType} from '@esgi/core/authentication';

class Props {
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	autoTrack: boolean;
	noStudents: boolean;
	trackChanged: (track: ITrackModel) => void;
}

class State {
	showTrackConfirmModal: boolean = false;
}

export default class GradeScalesButton extends React.PureComponent<Props, State> {
	private keySub: Subscription;
	private isEKeyPressed: boolean;
	state = new State();

	public componentDidMount() {
		const keyFilter = filter((e: KeyboardEvent) => e.keyCode === 90);
		const onKeyDown = fromEvent(document, 'keydown').pipe(keyFilter, map(_ => true));
		const onKeyUp = fromEvent(document, 'keyup').pipe(keyFilter, map(_ => false));
		this.keySub = merge(onKeyDown, onKeyUp).subscribe((pressed) => this.isEKeyPressed = pressed);
	}

	public componentWillUnmount() {
		this.keySub.unsubscribe();
	}

	clickHandler = async (e) => {
		e.preventDefault();
		if (!this.props.autoTrack) {
			this.runReport(e);
		} else {
			this.setState({showTrackConfirmModal: true});
		}
	};

	onConfirm = (e) => {
		this.setState({showTrackConfirmModal: false});
		this.runReport(e);
	};

	public render() {
		return <>
			<NoStudentProtectedButton title={this.title}
			                          icon={this.icon()}
			                          hoverTitle={this.hoverTitle}
			                          onClicked={(e) => this.clickHandler(e)}
			                          noStudents={this.props.noStudents}
			                          linkClassName='grade-scales-report-link'/>
			{this.state.showTrackConfirmModal && <TrackConfirmModal
				confirmed={(e) => this.onConfirm(e)} canceled={() => this.setState({showTrackConfirmModal: false})}
				trackChanged={this.props.trackChanged}
			/>}
		</>;
	}

	runReport = (event: React.MouseEvent<HTMLAnchorElement>): any => {
		SsoTracker.trackEvent({
			trackingEvent: this.gradeScaleLevel() + 'GradeReport',
		});
		const changes = new ChangesCollector({
			id: this.props.subjectID,
			type: this.props.subjectType,
		}, this.props.hierarchy.snapshot);
		const launcher = new GradeScaleLauncher(this.gradeScaleLevel(), this.props.subjectID, this.props.subjectType, this.props.hierarchy.snapshot);
		launcher.events.homepageStateChanged((e, reportChanges) => {
			changes.applyChanges({
				classID: reportChanges.classID,
				groupID: reportChanges.groupID,
				subjectTabID: reportChanges.subjectTabID,
				subjectTabType: reportChanges.subjectTabType,
				schoolID: reportChanges.schoolID,
				teacherID: reportChanges.teacherID,
			});

			this.props.applyHomepageChanges(changes);
		});
		return launcher.load(event.shiftKey && this.isEKeyPressed);
	};

	icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='15'
		            viewBox='0 0 15 15'
		            fill='none'>
			<path
				d='M6.69533 0.511692L4.86449 4.31505L0.768236 4.92692C0.0336581 5.03608 -0.260734 5.96394 0.271975 6.49538L3.23552 9.45418L2.53459 13.6339C2.40842 14.3894 3.18505 14.9553 3.83552 14.6019L7.5 12.6284L11.1645 14.6019C11.8149 14.9524 12.5916 14.3894 12.4654 13.6339L11.7645 9.45418L14.728 6.49538C15.2607 5.96394 14.9663 5.03608 14.2318 4.92692L10.1355 4.31505L8.30467 0.511692C7.97664 -0.166248 7.02617 -0.174866 6.69533 0.511692Z'
				fill='#0088CC'/>
		</svg>;
	};

	get title(): string {
		switch (this.gradeScaleLevel()) {
			case GradeScaleHierarchyLevel.Class:
				return 'Class Grades';
			case GradeScaleHierarchyLevel.Group:
				return 'Group Grades';
			case GradeScaleHierarchyLevel.StudentsSchool:
			case GradeScaleHierarchyLevel.SchoolSpecialistStudents:
				return 'School Grades';
			case GradeScaleHierarchyLevel.StudentsDistrict:
				return 'District Grades';
			case GradeScaleHierarchyLevel.SchoolsGroup:
				return 'School Group Grades';
			case GradeScaleHierarchyLevel.TeachersGroup:
				return 'Teacher Group Grades';
			case GradeScaleHierarchyLevel.GroupOfSpecialists:
				return 'Specialist Group Grades';
			default:
				return 'Grades';
		}
	}

	get hoverTitle(): string {
		switch (this.gradeScaleLevel()) {
			case GradeScaleHierarchyLevel.StudentsSchool:
			case GradeScaleHierarchyLevel.SchoolSpecialistStudents:
				return 'Apply customizable grade scales to class scores';
			case GradeScaleHierarchyLevel.StudentsDistrict:
				return 'Apply customizable grade scales to school scores';
			default:
				return 'Apply customizable grade scales to student scores';
		}
	}

	gradeScaleLevel(): GradeScaleHierarchyLevel {
		const h = this.props.hierarchy;
		if (h.mode === HierarchyMode.Classic) {
			const s = h.classic.selected;
			switch (s.level) {
				case ClassicHierarchyLevel.Student:
					return h.classic.groupID > 0 ? GradeScaleHierarchyLevel.Group : GradeScaleHierarchyLevel.Class;
				case ClassicHierarchyLevel.Teacher:
				case ClassicHierarchyLevel.Class:
					return GradeScaleHierarchyLevel.Class;
				case ClassicHierarchyLevel.Group:
					return GradeScaleHierarchyLevel.Group;
				case ClassicHierarchyLevel.School:
					return GradeScaleHierarchyLevel.StudentsSchool;
				case ClassicHierarchyLevel.District:
					return GradeScaleHierarchyLevel.StudentsDistrict;
				case ClassicHierarchyLevel.SchoolsGroup:
					return GradeScaleHierarchyLevel.SchoolsGroup;
				case ClassicHierarchyLevel.TeachersGroup:
					return GradeScaleHierarchyLevel.TeachersGroup;
			}
		}

		if (h.mode === HierarchyMode.Specialist) {
			const s = h.specialist;
			const selected = h.specialist.selected;

			if (s.userID === 0) {
				if (selected.level === SpecialistHierarchyLevel.GroupOfSpecialists) {
					return GradeScaleHierarchyLevel.GroupOfSpecialists;
				}
				if (s.type === UserType.ISS && s.filter.schoolID > 0) {
					return GradeScaleHierarchyLevel.SchoolSpecialistStudents;
				} else {
					return GradeScaleHierarchyLevel.StudentsDistrict;
				}
			} else {
				return GradeScaleHierarchyLevel.Group;
			}
		}

		if (h.mode === HierarchyMode.PreAssess) {
			const pa = h.preAssess;

			if (pa.userID === 0) {
				return GradeScaleHierarchyLevel.StudentsDistrict;
			} else {
				return GradeScaleHierarchyLevel.Group;
			}
		}

		return GradeScaleHierarchyLevel.None;
	}
}
