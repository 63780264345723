import {
	GradeRangeSelectionType,
	IGradeRange, IGradeScale, IGradeScaleEntry,
	ISubject,
	ITrack,
} from 'shared/modules/grade-scale/models';

export interface ISharedGradeRangeSet {
	id: number;
	testID: number;
	ownerName: string;
	periodType: GradeRangeSelectionType;
	type: GradeRangeType;
	gradeRanges: IGradeRange[];
}

export interface ISharedRangeGradeScale {
	id: number;
	name: string;
	entries: IGradeScaleEntry[];
	readonly: boolean;
}

export interface ITest {
	id: number;
	name: string;
	subjectID: number;
	selectedGradeRangeSetID?: number;
	defaultRangeApplied: boolean;
	applyDefaultRange: boolean;
	gradeScale: ISharedRangeGradeScale;
	gradeRangeSets: ISharedGradeRangeSet[];
	readonly: boolean;
}

export interface IInitResponse {
	track: ITrack;
	subjects: ISubject[];
}

export interface ISharedGradeRangeSetsResponse {
	readOnly: boolean;
	tests: ITest[];
}

export interface ITestUpdateModel {
	testID: number;
	gradeRangeSetId: number;
	gradeScaleId: number;
	applyDefaultRange: boolean;
	defaultRangeApplied: boolean;
}

export enum GradeRangeType
{
	Percent,
	Score,
}