import * as React from 'react';
import type {SVGProps} from 'react';

export function CollapseSide(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M17.286 16.686a.966.966 0 0 1 0-1.372l3.047-3.03a.98.98 0 0 1 1.381 0 .966.966 0 0 1 0 1.373L19.357 16l2.357 2.343a.966.966 0 0 1 0 1.373.98.98 0 0 1-1.38 0l-3.048-3.03Z'
				clipRule='evenodd'
			/>
			<rect width={2} height={8} x={10} y={12} fill='#333' rx={1} />
		</svg>
	);
}
