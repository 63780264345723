import React, {ReactNode, RefObject} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {TextArea} from '@esgillc/ui-kit/control';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {Level, LevelDisplayMode} from '../../../../models';
import RubricTooltip from '../../rubric-tooltip/rubric-tooltip';
import RemoveLevelDialog from './components/dialogs/remove-level-dialog';
import styles from './level.module.less';

class State {
	showRemoveDialog: boolean = false;
	showValidation: boolean = false;
	inFocus: boolean = false;
}

interface Props {
	className?: string;
	level: Level;
	isActive: boolean;
	isEditMode: boolean;
	highest: boolean;
	canRemove: boolean;
	hasRelatedData: boolean;
	displayMode: LevelDisplayMode;
	tooltipContainer: HTMLElement;
	removeClicked: () => void;
	onChange: (value: string) => void;
}

export class LevelActiveView extends React.PureComponent<Props, State> {
	private get isInvalid(): boolean {
		return this.state.showValidation && this.props.displayMode === LevelDisplayMode.Text && !this.props.level.name?.trim();
	}
	public readonly boxRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
	public readonly innerBoxRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
	public readonly nameInputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
	public state = new State();

	public componentDidMount() {
		if (this.props.displayMode === LevelDisplayMode.Text && !this.props.level.name) {
			this.nameInputRef.current?.focus();
		}
	}

	public showValidation(showValidation: boolean): void {
		this.setState({showValidation});
	}

	private get name(): string {
		return this.props.level.name || this.props.level.score.toString();
	}

	private remove() {
		if(this.props.hasRelatedData) {
			this.setState({showRemoveDialog: true});
		} else {
			this.props.removeClicked();
		}
	}

	public render() {
		return <>
			<div className={join(styles.box, this.props.className)} ref={this.boxRef}>
				<div className={styles.header}>
					{this.renderHeader()}
				</div>
				<div className={join(styles.level,
						this.props.isActive && styles.active,
						this.props.isEditMode && styles.edit,
						this.state.inFocus && styles.focus,
						this.isInvalid && styles.invalid)}
					ref={this.innerBoxRef}>
					{this.renderBody()}
				</div>
			</div>
			{this.renderRemoveDialog()}
			{this.renderValidation()}
		</>;
	}

	private renderHeader(): ReactNode {
		if(this.props.isActive) {
			const title = <span>Level {this.props.level.score} {this.props.highest && '(highest) ' || ''}</span>;

			if(this.props.isEditMode && this.props.canRemove) {
				return <>
					{title}
					<AnchorLikeButton className={styles.removeButton} onClick={() => this.remove()}>
						remove
					</AnchorLikeButton>
				</>;
			} else {
				return title;
			}
		}
	}

	private renderBody(): ReactNode {
		if (!this.props.isActive) {
			return;
		}
		if (this.props.isEditMode && this.props.displayMode === LevelDisplayMode.Text) {
			return <div className={styles.levelName}>
				<TextArea autoFocus={!this.props.level.name}
				              rows={1}
				              maxHeight={32}
                      maxLength={23}
				              autoResizeToFit
				              ref={(r) => {
					              (this.nameInputRef.current as any) = r?.inputRef.current;
				              }}
				              value={this.props.level.name}
				              placeholder='Enter Title'
				              onFocus={() => this.setState({inFocus: true})}
				              onBlur={() => this.setState({inFocus: false})}
				              onChange={(e) => this.props.onChange(e.target.value.replace('\n', ''))}/>
			</div>;
		}

		return <span className={styles.readonlyName}>{this.props.displayMode === LevelDisplayMode.Text ? this.props.level.name: this.props.level.score}</span>;
	}

	private renderValidation(): ReactNode {
		if (this.isInvalid) {
			return <RubricTooltip className={styles.tooltip}
			                      element={null}
			                      elementRef={this.innerBoxRef}
			                      container={this.props.tooltipContainer}
			                      placement='bottom'>
				Please enter a title for your level.
			</RubricTooltip>;
		}
	}

	private renderRemoveDialog(): ReactNode {
		if (this.state.showRemoveDialog) {
			return <RemoveLevelDialog name={this.name}
			                          onConfirm={() => this.setState({showRemoveDialog: false}, this.props.removeClicked)}
			                          onCancel={() => this.setState({showRemoveDialog: false})}/>;
		}
	}
}
