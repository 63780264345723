import {styled} from '@esgi/ui/theme';

export const IconWrapper = styled('span', {
	lineHeight: '12px',
	'& > svg': {
		width: 24,
		height: 24,
		fill: '$neutral56',

		'& > path': {
			fill: '$neutral56',
		},
	},
});