import {Modal as ModalBundle, ModalManagerRefObject, CloseIcon as NewCloseIcon} from '@esgillc/ui-kit/modal';

export {Title, ModalManager, ModalContext} from '@esgillc/ui-kit/modal';

/**
 * @deprecated Use ModalManagerRefObject instead.
 * */
export type ModalManagerRef = ModalManagerRefObject;

export const Modal = ModalBundle;

export const ModalHeader = ModalBundle.Header;

export const ModalBody = ModalBundle.Body;

export const ModalFooter = ModalBundle.Footer;

export const CloseIcon = NewCloseIcon;

