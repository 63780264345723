import {useEffect, useMemo} from 'react';
import {useEventEffect} from '@esgillc/events';
import {
	TestsPanel,
	TestItem,
	useTestsSelection,
	SelectAllTestsEvent,
} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {StudentType, SubjectTab, Test} from '../../types';

type Props = {
	selectedStudent: StudentType;
	onSubjectChange: (subject: SubjectTab) => void;
	isTestsLoading: boolean;
	isTestsLoaded: boolean;
	tests: Test[],
	setSelectedTests: (tests: string[]) => void; 
	initialSelectedSubjectId?: number; 
}

export function TestsSelectionPanel({
	selectedStudent,
	onSubjectChange,
	isTestsLoading,
	isTestsLoaded,
	tests,
	setSelectedTests,
	initialSelectedSubjectId,
}: Props) {
	const {entitySet, entitySetAll, entityUnsetAll, selectedEntities} =
		useTestsSelection();

	useEventEffect(
		SelectAllTestsEvent,
		() => {
			if (selectedEntities.length === tests.length) {
				entityUnsetAll();
				return;
			}

			entitySetAll(
				tests.map((item) => String(item.id)),
				[]
			);
		},
		[]
	);

	useEffect(() => {
		if (!isTestsLoaded) {
			return;
		}

		entityUnsetAll();
	}, [isTestsLoaded, entityUnsetAll]);

	useEffect(() => {
		setSelectedTests(selectedEntities);
	}, [selectedEntities]);

	const studentsState = useMemo(
		() => ({
			classIDs: [],
			groupIDs: [],
			studentIDs: selectedStudent?.id ? [selectedStudent.id] : [],
		}),
		[selectedStudent]
	);

	return (
		<TestsPanel.Layout.Content>
			<TestsPanel.Root studentsState={studentsState}>
				<TestsPanel.Students.Root>
					<TestsPanel.Subject.Root onSubjectChange={onSubjectChange} initialSelectedSubjectID={initialSelectedSubjectId}>
						<TestsPanel.Layout.Header>
							<TestsPanel.Students.Info counterTitle='ai-analysis' />
							<TestsPanel.Layout.Divider />
							<TestsPanel.Subject.Control />
						</TestsPanel.Layout.Header>
						<TestsPanel.Tests.Root>
							{isTestsLoading ? (
								<TestsPanel.Tests.ListLoading />
							) : !tests?.length ? (
								<TestsPanel.Tests.ListEmpty emptyText='You do not have any compatible System tests within the selected subject tab. The tool is compatible with most ESGI System-created tests so please select another tab or add some to your tab via the Homepage or Test Explorer'/>
							) : (
								<>
									<GridBox gap={4} flow='column' align='center' justify='start'>
										<TestsPanel.Tests.Controls.SelectAll />
										<TestsPanel.Tests.Controls.Sort />
									</GridBox>
									<TestsPanel.Tests.List
										list={tests}
										listVocabulary={selectedEntities}
										onSetSelectedEntities={entitySet}
									>
										{(listItem: TestItem & {hasSessions: boolean}) => (
											<TestsPanel.Tests.Item
												key={listItem.id}
												item={listItem}
												isSelectable
												isDisabled={!listItem.hasSessions}
												tooltip={!listItem.hasSessions && 'This test does not have any test sessions'}
											/>
										)}
									</TestsPanel.Tests.List>
								</>
							)}
						</TestsPanel.Tests.Root>
					</TestsPanel.Subject.Root>
				</TestsPanel.Students.Root>
			</TestsPanel.Root>
		</TestsPanel.Layout.Content>
	);
}
