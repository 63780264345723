import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Section = styled(Box, {
	padding: '0 29px',
});

export const Title = styled(Text, {
	padding: '20px 0 12px',
	margin: '0 3px 12px',
	borderBottom: '1px solid $mild',
	textAlign: 'center',
	display: 'block',
});
