import React from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {IBoxInfo, IDistrictSpecialist, ISchoolSpecialist, IUserModel} from '../../core/api';
import {BoxType} from '../../core/models';
import {Box, BoxOptions, ItemProps} from '../../components/box';

export class State {

}
export class Props {
	boxes: IBoxInfo[];
	type: UserType;
	selectedID: number;
  groupOfSpecialistsID: number;
	districtSpecialists: IDistrictSpecialist[];
	schoolSpecialists: ISchoolSpecialist[];
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;

}


export class User extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();
	get items(): ItemProps[] {
		if(this.props.groupOfSpecialistsID) {
			const schoolSpecialists = this.props.schoolSpecialists.filter(s => s.groups.some(g => g === this.props.groupOfSpecialistsID));
			if(this.currentUser.userType === UserType.D){
				const districtSpecialists = this.props.districtSpecialists.filter(s => s.groups.some(g => g === this.props.groupOfSpecialistsID));
				return schoolSpecialists.map(this.map).concat(districtSpecialists.map(this.map));
			} else if(this.currentUser.userType === UserType.C) {
				return schoolSpecialists.map(this.map);
			} else {
				return null;
			}
		}

		if (this.props.type === UserType.ISD) {
			return this.props.districtSpecialists.map(this.map);
		}

		if (this.props.type === UserType.ISS) {
			return this.props.schoolSpecialists.map(this.map);
		}

    if(this.currentUser.userType === UserType.C) {
      return this.props.schoolSpecialists.map(this.map);
    }
	}

	private map(r: IUserModel): ItemProps {
		return {
			id: r.userID,
			title: `${r.firstName} ${r.lastName}`,
		};
	}


	render(): any {
		const current = this.currentUser.userType;
		if ([UserType.D, UserType.C].indexOf(current) < 0) {
			return null;
		}

    if(this.currentUser.userType === UserType.D) {
      if (this.props.type === 0 && !this.props.groupOfSpecialistsID) {
        return null;
      }
    }

		let title = '';
    if(this.props.groupOfSpecialistsID) {
      title = 'All Specialists'
    } else {
      title = this.props.type === UserType.ISD
        ? 'District Specialists'
        : 'School Specialists';
    }

		const options: BoxOptions = {
			canAdd: false,
			canEdit: false,
			canCreateTooltip: null,
			title: title,
			canDrag: false,
			boxType: BoxType.SpecialistUser,
		};

		return <Box
			open={this.props.boxes.filter(t => t.boxType == BoxType.SpecialistUser && t.open).length > 0}
			selectedID={this.props.selectedID}
			scheduledIDs={[]}
			onDragStart={null}
			onAddClicked={() => {}}
			onEditClicked={() => {}}
			options={options}
			empty={{message: 'The list is empty'}}
			onDragEnd={null}
			items={this.items}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
			itemSelected={this.props.onSelected}
		/>;
	}
}
