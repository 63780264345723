export type ChartItem = {
	score: number;
	notes: string;
	criteriaName: string;
};

export enum YAxisId {
	YBarChart = 'YBarChart',
}

export enum XAxisId {
	XBarChart = 'XBarChart',
}

export enum StackBarName {
	AnswerInfo = 'AnswerInfo',
}
