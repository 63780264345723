import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

interface Props {
	onDelete: VoidFunction;
	onCancel: VoidFunction;
	isRemoving: boolean;
}

export function DeleteSubjectAlert({onDelete, onCancel, isRemoving}: Props) {
	const alertRef = Alert.useRef();
	const handleClose = Alert.useClose(alertRef, onCancel);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
			<Alert.Header withBacklight onCloseIconClick={onCancel}>
				<Text size='large' color='negative'>
					Delete Subject
				</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='medium' color='neutral40'>
					Are you sure you want to delete this subject? This action cannot be undone.
				</Text>
			</Alert.Body>
			<Alert.Footer align='center' gapX={3}>
				<Button color='tertiary' onClick={handleClose}>
					<Text size='medium' color='base' bold>
						Cancel
					</Text>
				</Button>
				<Button onClick={onDelete} color='secondary' disabled={isRemoving}>
					<Text size='medium' color='base' bold>
						Delete
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
