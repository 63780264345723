import {ElementStatus, FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Label, OnErrorTooltip, TextArea} from '@esgillc/ui-kit/form-control';
import moment from 'moment';
import {IEPStatusModel} from '../../models';
import styles from '../styles.module.less';

type Props = {
    benchmarkControl: FormControl<string>;
    statusControl: FormControl<IEPStatusModel[]>;
}

export function BenchmarkField ({benchmarkControl, statusControl}: Props) {
	const onFocus = () => {
		if (benchmarkControl.status === ElementStatus.untouched) {
			benchmarkControl.value = buildDateString() + (benchmarkControl.value.length
				? '\n\n' + benchmarkControl.value
				: benchmarkControl.value);
		}
	};

	const buildDateString = () => {
		const date = moment().format('MM/DD/YYYY');
		const status = statusControl.value.at(0);
		const statusName = status && !status.isDeleted ? status.name : '';
		return date + (statusName ? ` - Status: ${statusName}` : '') + '\n';
	};

	return (
		<FormElement
			className={styles.formElement}
			control={benchmarkControl}
		>
			<Label className={styles.label}>Narrative /<br /> Benchmark</Label>
			<TextArea
				className={styles.field}
				rows={10}
				autoResizeToFit
				placeholder='Enter narrative for this test'
				onFocus={onFocus}
			/>
			<OnErrorTooltip
				showOnError='required'
				placement='left'
			>
				Required
			</OnErrorTooltip>
		</FormElement>
	);
}
