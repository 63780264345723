import {Dropdown, SelectableList, SelectableListItem} from '@esgillc/ui-kit/form-control';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {GradeLevel} from '../../types';
import styles from './styles.module.less';

interface Props {
	control: FormControl
	grades: GradeLevel[],
}

export function Grade({control, grades}: Props) {
	return <div data-cy='grade' className={styles.row}>
		<div className={styles.titleContainer}>
			<label data-cy='grade-title' className={styles.title}>Grade:</label>
		</div>
		<div className={styles.checkboxesDropdown}>
			<FormElement control={control}>
				<Dropdown optionName='shortName' placeholder='Grade Level'>
					<SelectableList checkboxPlacement='left'>
						{grades.map(g => <SelectableListItem key={g.id} value={g}>
							{g.name}
						</SelectableListItem>)}
					</SelectableList>
				</Dropdown>
			</FormElement>
		</div>
	</div>;
}
