import {CSS} from '@stitches/react';
import {defaultTheme} from '../theme/variants';
import {ThemeColorKeys} from '../types';

export function generateTextColors() {
	const textColors = {
		currentColor: {
			color: 'currentColor',
		},
	};

	Object.keys(defaultTheme.colors).forEach((key) => {
		textColors[key] = {
			color: `$${key}`,
		};
	});

	return textColors as Record<ThemeColorKeys, CSS> & {currentColor: {color: 'currentColor'}};
}
