import {ArrowLeftIcon} from './components/arrow-left-icon';
import {CrossIcon} from './components/cross-icon';
import {ErrorIcon} from './components/error-icon';
import {EyeIcon} from './components/eye-icon';
import {EyeCrossedIcon} from './components/eye-crossed-icon';
import {WarningTriangleIcon} from './components/warning-tringle-icon';

export const Icon = Object.assign(
	{},
	{
		ArrowLeftIcon,
		ErrorIcon,
		CrossIcon,
		EyeIcon,
		EyeCrossedIcon,
		WarningTriangleIcon,
	},
);
