import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const WrapperBox = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',

	variants: {
		withClassesSelect: {
			true: {
				gridTemplateRows: 'auto 1fr',
				gap: '$5',
			},
		},
	},
});

export const ListsContentWrapper = styled(GridBox, {
	gap: '$5',
	overflow: 'auto',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
});
