import React from 'react';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
}

export function Divider({className}: Props) {
	return <div className={join(styles.divider, className)}/>;
}
