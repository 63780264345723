import {createContext, useContext} from 'react';

export type BoxplotChartContextValue = {
	/**
	 * The progress of the chartMinValue prop.
	 * Value in float (0.33 = 33%).
	 */
	chartMinValueProgress: number;

	/**
	 * The progress of the chartMaxValue prop.
	 * Value in float (0.33 = 33%).
	 */
	chartMaxValueProgress: number;

	/**
	 * The progress of the median of the lower half of the dataset.
	 * Value in float (0.33 = 33%).
	 * based on the quartile1 prop.
	 */
	quartile1Progress: number;

	/**
	 * The progress of the middle value in the data set
	 * Value in float (0.33 = 33%).
	 * based on the quartile3 prop.
	 */
	quartile2Progress: number;

	/**
	 * The progress of the median of the upper half of the dataset.
	 * Value in float (0.33 = 33%).
	 * based on the quartile3 prop.
	 */
	quartile3Progress: number;
};

export const BoxplotChartContext = createContext<BoxplotChartContextValue>({
	chartMinValueProgress: 0,
	chartMaxValueProgress: 0,
	quartile1Progress: 0,
	quartile2Progress: 0,
	quartile3Progress: 0,
});

export function useBoxplotChartContext() {
	return useContext(BoxplotChartContext);
}
