import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {Text} from '@esgi/ui/typography';

export const Title = styled(FlexBox, {
	flexDirection: 'row',
	justifyContent: 'space-between',
	padding: '12px 0',
	borderBottom: '1px solid $mild',
});

export const SkeletonRow = styled(Box, {
	...SkeletonStyles,
	height: 40,
	width: '100%',
	borderRadius: 4,
});

export const Link = styled('a', {
	all: 'unset',
	cursor: 'pointer',
});

export const UnderlinedText = styled(Text, {
	textDecoration: 'underline',
});
