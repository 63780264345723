import {DraggableTagIcon, DraggableTagItem} from './styled';
import {forwardRef, HTMLProps, useContext} from 'react';
import {DroppableTextEditorContext} from '../../context';
import {TagParams} from '../../types';
import {BaseComponentProps} from '../../../../types';

type DraggableTagProps = BaseComponentProps & Omit<HTMLProps<HTMLSpanElement>, 'children'> & {
	tagData: TagParams;
}

export const DraggableTag = forwardRef<HTMLSpanElement, DraggableTagProps>(({tagData: {name, value}, ...props}, ref) => {
	const {onDrag} = useContext(DroppableTextEditorContext);
	return <DraggableTagItem
		{...props}
		data-role='draggable-tag'
		draggable
		data-id={value}
		contentEditable={false}
		onDragStart={onDrag}
		ref={ref}
	>
		<DraggableTagIcon/>
		{name}
	</DraggableTagItem>;
});