import {FormElement} from '@esgillc/ui-kit/form';
import {Checkbox} from '@esgillc/ui-kit/form-control';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import Section from '../index';
import styles from '../styles.module.less';

interface Props {
	form: DistrictFormType | SchoolFormType;
}

export default function ShuffleQuestionsSection({form}: Props) {
	return <Section data-cy={'shuffle-questions-tab'} className={styles.section} title='Shuffle Questions'>
		<FormElement control={form.controls.shuffleQuestions}>
			<Checkbox id='shuffle_Questions'/>
		</FormElement>
	</Section>;
}
