import {DistrictModel, DropdownInModel, SchoolModel} from '../../types';

export class UserSecondaryProfileUpdateModel {
	constructor(
		public userName: string,
		public email: string,
		public firstName: string,
		public lastName: string,
		public password: string | null,
		public userID: number,
		public credentialID: number,
	) {
	}
}

export class PrimaryProfileUpdateModel {
	constructor(
		public accountID: string,
		public email: string,
		public title: string,
		public firstName: string,
		public globalSchoolYearID: number,
		public lastName: string,
		public password: string | null,
		public stateID: number,
		public trackID: number,
		public userID: number,
		public gradeLevels: number[],
	) {
	}
}

export enum SelectedTab {
	Primary,
	Secondary
}

export class ManageAccountSavedEvent {
	constructor(
		public states: DropdownInModel[],
		public countries: DropdownInModel[],
		public selectedState: DropdownInModel,
		public selectedCountry: DropdownInModel,
		public schoolName: string,
		public districtName: string,
	) {
	}
}

export interface ManageAccountInitArgs {
	defaultStates: DropdownInModel[];
	selectedState: DropdownInModel;
	defaultCountries: DropdownInModel[];
	selectedCountry: DropdownInModel;
	schoolName: string;
	districtName: string;
}

export class ManageAccountRequestModel {
	constructor(
		public countryID: number,
		public districtName: string,
		public schoolID: number,
		public schoolName: string,
		public stateID: number,
	) {
	}

}

export interface SchoolSearchResponseModel {
	items: SchoolModel[];
}

export interface DistrictsResponse {
	federalDistricts: DistrictModel[]
}
