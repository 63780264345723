import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';


export const EmptyBox = styled(GridBox, {
	margin: '20px auto',
	textAlign: 'center',
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $border',
	background: '$vivid',
});

export const SelectStudentText = styled(Text, {
	marginTop: '6px',
	color: '$neutral40',
});
