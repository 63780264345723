import {Dispatch, useCallback, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';


export function useQueryValue<T extends string>(name: string, defaultValue?: T): [T | undefined, Dispatch<T | undefined>] {
	const [params, setParams] = useSearchParams();
	const [value, setValue] = useState<T | undefined>(() => {
		const currentValue = params.get(name) as T;
		return currentValue || defaultValue;
	});

	useEffect(() => {
		const currentValue = params.get(name) as T;
		if(currentValue !== value) {
			setValue(currentValue);
		}
	}, [params, value, name]);

	const update = useCallback((value: T | undefined) => {
		if (!value) {
			params.delete(name);
		} else {
			params.set(name, value);
		}
		setParams(params);
		setValue(value);
	}, [name, params, setParams]);

	return [value, update];
}

export function useQueryFlag(name: string, defaultValue?: boolean): [boolean, Dispatch<boolean>] {
	const [params, setParams] = useSearchParams();
	const [value, setValue] = useState<boolean>(() => {
		const value = params.has(name);
		return value || defaultValue || false;
	});

	const update = useCallback((value: boolean) => {
		if (value) {
			params.set(name, '');
		} else {
			params.delete(name);
		}
		setParams(params);
		setValue(value);
	}, [name, params, setParams]);

	return [value, update];
}