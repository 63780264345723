export const maxLengths = {
	question: 128,
	description: 140,
	directions: 240,
	name: 65,
	standard: 30,
};

export const imageSize = {width: 253, height: 121};

export const canvasSize = {width: 708, height: 318};

export const defaultColors = {
	56: '90CAF9',
	57: '80CBC4',
	93: 'CDCDCD',
	100: 'FFCC80',
	101: 'CE93D8',
	102: 'FFF59D',
	105: '9FA8DA',
};

export const defaultValues = {
	contentAreaID: 93,
	font: 'AbcPrint',
	colors: defaultColors,
	image: imageSize,
};

export const topics = [
	{
		id: 0,
		name: 'Computation',
	},
	{
		id: 1,
		name: 'Letters',
	},
	{
		id: 2,
		name: 'Numbers',
	},
	{
		id: 3,
		name: 'Observation',
	},
	{
		id: 4,
		name: 'Sight Words',
	},
	{
		id: 5,
		name: 'Sounds',
	},
	{
		id: 6,
		name: 'Vocabulary',
	},
];

export const topicTemplates = [
	{
		contentAreaName: 'Language Arts',
		directions: 'Have the student solve the problem',
		description: 'Solve computation problems',
		testName: 'Computation',
		topicName: 'Computation',
		topicID: 0,
	},
	{
		contentAreaName: 'Language Arts',
		directions: 'What letter is this?',
		description: 'Identifying Letters',
		testName: 'Letters',
		topicName: 'Letters',
		topicID: 1,
	},
	{
		contentAreaName: 'Mathematics',
		directions: 'What number is this?',
		description: 'Count numbers',
		testName: 'Numbers',
		topicName: 'Numbers',
		topicID: 2,
	},
	{
		contentAreaName: 'Other',
		directions: 'Observe the student doing the task',
		description: 'Observe student completing task',
		testName: 'Observation',
		topicName: 'Observation',
		topicID: 3,
	},
	{
		contentAreaName: 'Language Arts',
		directions: 'What word is this?',
		description: 'Read sight words',
		testName: 'Sight Words',
		topicName: 'Sight Words',
		topicID: 4,
	},
	{
		contentAreaName: 'Language Arts',
		directions: 'What sound is this?',
		description: 'Identify or produce sounds',
		testName: 'Sounds',
		topicName: 'Sounds',
		topicID: 5,
	},
	{
		contentAreaName: 'Language Arts',
		directions: 'Have the student define the word',
		description: 'Read or define vocabulary words',
		testName: 'Vocabulary',
		topicName: 'Vocabulary',
		topicID: 6,
	},
];

export const gradeLevels = [
	{
		id: 1,
		name: 'Pre-K',
		shortName: 'PK',
	},
	{
		id: 2,
		name: 'TK',
		shortName: 'TK',
	},
	{
		id: 4,
		name: 'Kindergarten',
		shortName: 'K',
	},
	{
		id: 8,
		name: 'Grade One',
		shortName: '1',
	},
	{
		id: 16,
		name: 'Grade Two',
		shortName: '2',
	},
	{
		id: 32,
		name: 'Grade Three+',
		shortName: '3+',
	},
];

export const dictionary = {
	topics,
	topicTemplates,
	gradeLevels,
	defaultColors,
};
