import {FlexBox, SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {CollapseSide} from '@esgi/ui/icons';
import {CaptionText, IndexText} from '../list-item/index.styled';
import {TestColorIndicator as TestColorIndicatorUI} from '@esgi/main/kits/common';

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',

	variants: {
		isListOpen: {
			true: {
				'&:not(:last-child)': {
					marginBottom: '18px',
				},
			},

			false: {
				'&:not(:last-child)': {
					marginBottom: '6px',
				},
			},
		},
	},
});

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	alignItems: 'center',
	gridTemplateColumns: 'auto 1fr',
	gap: 12,
	padding: '10px 16px 10px 13px',

	'& > svg': {
		width: '24px',
		height: '24px',
		alignSelf: 'start',
	},

	'&:before': {
		content: '',
		width: '16px',
		height: '16px',
		display: 'inline-block',
		backgroundPosition: 'center',
		backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Crect width='16' height='16' rx='4' fill='%23EBEBEB'/%3E%3C/svg%3E")`,
	},

	[`& ${CaptionText}`]: {
		color: '$neutral40',
	},

	[`& ${IndexText}`]: {
		color: '$neutral72',
	},

	variants: {
		checked: {
			true: {
				'&:before': {
					backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='4' width='16' height='16' rx='4' fill='%23DCEFF9'/%3E%3Cpath d='M15.0687 9C14.8963 9 14.7165 9.06271 14.5845 9.20489L11.4062 12.6683C11.2331 12.8564 11.0586 12.8214 10.9226 12.6005L10.2491 11.5068C10.0423 11.1714 9.61126 11.0781 9.30212 11.3012C8.9923 11.525 8.90611 11.9917 9.1122 12.3264L9.78571 13.4201C10.3939 14.4073 11.5786 14.5327 12.3538 13.6942L15.553 10.2534C15.8157 9.9683 15.8157 9.48999 15.553 9.20489C15.421 9.06271 15.2412 9 15.0687 9Z' fill='%230084CC'/%3E%3C/svg%3E%0A")`,
				},

				[`& ${CaptionText}`]: {
					color: '$primary',
				},

				[`& ${IndexText}`]: {
					color: '$primary',
				},
			},
		},
	},
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	padding: '8px 8px 8px 12px',
	gap: '$3',

	'& > svg': {
		alignSelf: 'center',
	},

	variants: {
		defaultCursor: {
			true: {
				cursor: 'default',
			},
		},

		withoutStyledButton: {
			true: {
				gridTemplateColumns: 'auto 1fr auto',
			},
		},
	},
});

export const SelectableListTriggerContent = styled(FlexBox, {
	color: 'inherit',
	alignItems: 'center',
});

export const SelectableListTriggerLabel = styled(Text, {
	color: 'inherit',
	marginRight: 6,
});

export const Collapse = styled(CollapseSide, {
	'& rect': {
		fill: 'currentColor',
	},

	variants: {
		rotate: {
			true: {
				transform: 'rotate(90deg)',
			},
			false: {
				transform: 'rotate(-90deg)',
			},
		},
	},
});

export const TestColorIndicator = styled(TestColorIndicatorUI, {
	height: '32px',
	padding: '6px 4px',
	marginRight: '12px',
});

export const IconBefore = styled(FlexBox, {
	width: '16px',
	height: '16px',
	borderRadius: '4px',
	backgroundColor: '$border',

	variants: {
		checked: {
			true: {
				backgroundColor: '$primary92',

				'&:after': {
					content: '',
					width: '16px',
					height: '16px',
					display: 'inline-block',
					backgroundPosition: 'center',
					backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M16.2482 9.75C16.1031 9.75 15.9518 9.80375 15.8407 9.92562L13.166 12.8943C13.0203 13.0555 12.8735 13.0255 12.759 12.8361L12.5107 12.4255L11.6787 13.3436L11.8022 13.5386C12.3141 14.3849 13.3111 14.4923 13.9634 13.7736L16.6557 10.8243C16.8768 10.58 16.8768 10.17 16.6557 9.92562C16.5447 9.80375 16.3933 9.75 16.2482 9.75Z' fill='%230084CC'/%3E%3Cpath d='M12.3804 9.75C12.2326 9.75 12.0784 9.80375 11.9653 9.92562L9.24104 12.8943C9.09267 13.0555 8.94314 13.0255 8.82653 12.8361L8.24923 11.8987C8.072 11.6112 7.70253 11.5312 7.43755 11.7224C7.17199 11.9143 7.09811 12.3143 7.27476 12.6012L7.85206 13.5386C8.37336 14.3849 9.38882 14.4923 10.0533 13.7736L12.7954 10.8243C13.0206 10.58 13.0206 10.17 12.7954 9.92562C12.6823 9.80375 12.5282 9.75 12.3804 9.75Z' fill='%230084CC'/%3E%3C/svg%3E")`,
				},
			},
		},
	},
});
