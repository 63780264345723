import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {CardBody} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {InfoWithTranscript} from '../../../info-with-transcript';
import {DateFormatText} from '../../../date-format-text';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	BaseComponentProps & {
		studentsCount: number;
		cardDate: string | Date | null;
		cardDateTranscript: 'Posted' | 'Completed' | 'Created';
		testsCount: number;
		description: string;
	};

export const AssignmentDetailsCardBody = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'assignment-details-card-body',
			css = {},
			studentsCount,
			cardDate,
			testsCount,
			description,
			cardDateTranscript,
			...props
		},
		forwardedRef,
	) => {
		return (
			<CardBody dataCy={dataCy} css={css} {...props} withDescription={Boolean(description)} ref={forwardedRef}>
				{Boolean(description) && (
					<Text size='medium' bold color='neutral40' data-cy='assignment-description'>
						{description}
					</Text>
				)}

				<GridBox display='inlineGrid' flow='column' align='center' gap='8'>
					<InfoWithTranscript transcript='Tests' dataCy='info-tests'>
						<Text size='medium' font='mono' bold data-cy='info-value'>
							{testsCount}
						</Text>
					</InfoWithTranscript>
					<InfoWithTranscript transcript='Students' dataCy='info-students'>
						<Text size='medium' font='mono' bold data-cy='info-value'>
							{studentsCount}
						</Text>
					</InfoWithTranscript>
					{cardDate && (
						<InfoWithTranscript transcript={cardDateTranscript} dataCy='info-date'>
							<DateFormatText size='medium' font='mono' bold dateFormat='MM/DD/YY' date={cardDate} data-cy='info-value' />
						</InfoWithTranscript>
					)}
				</GridBox>
			</CardBody>
		);
	},
);
