import {useService} from '@esgi/core/service';
import {Layer} from '@esgillc/ui-kit/core';
import {Loader} from '@esgillc/ui-kit/loader';
import {useEffect, useState} from 'react';
import {ScoreFlow} from './components/score-flow';
import {ScoreService} from './service';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';

interface Props {
	testID: number;
	studentID: number;
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClosed: () => void;
	onTestHistoryClicked: () => void;
	onTestSessionDetailsClicked: () => void;
}

export default function ScoreTesting(props: Props) {
	const service = useService(ScoreService);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const {id: subjectId, type: subjectType} = props.subject;
		service.init(props.testID, props.studentID, subjectId, subjectType).subscribe({
			next: () => setLoaded(true),
			error: () => props.onClosed(),
		});
	}, []);

	if (!loaded) {
		return <Loader show fullscreen/>;
	}

	return (
		<Layer>
			<ScoreFlow
				service={service}
				hierarchy={props.hierarchy}
				subject={props.subject}
				onClosed={props.onClosed}
				onTestHistoryClicked={props.onTestHistoryClicked}
				onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
			/>
		</Layer>
	);
}
