import {Class, Group, Student} from '@esgi/main/libs/store';
import {isNull, max} from 'underscore';

export function getStudentClass({
	classID,
	classesList,
	studentID,
	groupID,
	groupsList,
}: {
	classID: Class['id'] | null;
	classesList: Class[];
	studentID: Student['id'];
	groupID: Group['id'] | null;
	groupsList: Group[];
}): Class | null {
	if (!isNull(classID)) {
		if (classID === -1) {
			const studentsClasses = classesList.filter(({studentIDs}) => studentIDs.includes(studentID));
			const classWithMaxStudents = max(studentsClasses, ({studentIDs}) => studentIDs.length);

			return typeof classWithMaxStudents === 'number' ? null : classWithMaxStudents;
		}

		const studentClass = classesList.find(({id}) => classID === id);

		return studentClass ?? null;
	}

	if (!isNull(groupID)) {
		const selectedGroup = groupsList.find(({id}) => groupID === id);
		const selectedClassByGroupId = classesList.find(({id}) => selectedGroup?.classID === id);

		return selectedClassByGroupId ?? null;
	}

	return null;
}
