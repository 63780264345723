import React, {useMemo} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {IconButton} from '../../../../icons/icon-button';

export function EditButton(props: {onClick: () => void, canEdit: boolean, subjectType: SubjectType}) {
	const tooltipText = useMemo(() => {
		if (!props.canEdit) {
			if (props.subjectType === SubjectType.Stock) {
				return 'This is a stock tab, Admins cannot rename it';
			} else {
				return 'You are not allowed to rename this tab';
			}
		}

		return 'Edit subject tab';
	}, [props.canEdit, props.subjectType]);

	return <OnHoverTooltip message={tooltipText}>
		<IconButton disabled={!props.canEdit} onClick={(e) => {
			e.stopPropagation();
			props.onClick();
		}}>
			<svg xmlns='http://www.w3.org/2000/svg' width='14'
			     height='14'
			     viewBox='0 0 14 14'
			     fill='none'>
				<path
					d='M0 10.6894V13.5019H2.8125L11.1075 5.20688L8.295 2.39438L0 10.6894ZM13.2825 3.03188C13.575 2.73938 13.575 2.26688 13.2825 1.97438L11.5275 0.219375C11.235 -0.073125 10.7625 -0.073125 10.47 0.219375L9.0975 1.59188L11.91 4.40438L13.2825 3.03188Z'
					fill='#0088CC'/>
			</svg>
		</IconButton>
	</OnHoverTooltip>;
}
