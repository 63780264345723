import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const EmptyBox = styled(Box, {
	margin: '20px auto',
	textAlign: 'center',
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $border',
	background: '$vivid',

	[`& ${Text}`]: {
		display: 'block',
	},
	[`& ${Text} + ${Text}`]: {
		marginTop: '6px',
	},
});
