import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {drawerPaddingInline} from '../drawer-root/index.styled';

export const DrawerWrapper = styled(GridBox, {
	overflow: 'hidden',
	height: '100%',
	gridTemplateRows: 'auto auto 1fr',
	gap: 20,
	paddingLeft: drawerPaddingInline,
	paddingRight: drawerPaddingInline,
	paddingBottom: 20,
});

export const Header = styled(GridBox, {
	paddingTop: 18,
	gridTemplateColumns: 'auto 1fr auto',
	gap: '$1',
	alignItems: 'center',
});

export const Content = styled(GridBox, {
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gap: '$4',
});
