import {BaseButton} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Button = styled(BaseButton, {
	display: 'grid',
	gap: 6,
	borderRadius: 6,
	borderStyle: 'dashed',
	borderWidth: 1,
	borderColor: '$neutral72',
	boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(230, 232, 239, 0.24)',
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 8,
	paddingRight: 8,
	color: '$base',
	width: '100%',
	justifyContent: 'start',
	alignItems: 'center',
	gridAutoFlow: 'column',
	boxSizing: 'border-box',
	marginBottom: 6,

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'&:hover': {
		color: '$secondary',
		borderColor: '$secondary',
		backgroundColor: '$secondaryBackground',
	},
});
