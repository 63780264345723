import {ExpandablePanelContent} from './components/expandable-panel-content';
import {ExpandablePanelPlaceholder} from './components/expandable-panel-placeholder';
import {ExpandablePanelRoot} from './components/expandable-panel-root';
import {ExpandablePanelTrigger} from './components/expandable-panel-trigger';

export const ExpandablePanel = Object.assign(ExpandablePanelRoot, {
	Trigger: ExpandablePanelTrigger,
	Placeholder: ExpandablePanelPlaceholder,
	Content: ExpandablePanelContent,
});
