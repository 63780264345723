import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {Fragment, Suspense, useState} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {NoStudentProtectedButton} from './no-students-protected-button';

const Bingo = lazyComponent(() => import('shared/modules/bingo/bingo'));

interface Props {
	hierarchy: HierarchyInstance;
	selectedSubjectID: number;
	noStudents: boolean;
}

export default function BingoButton(props: Props) {
	const [opened, setOpened] = useState(false);

	const bingo = opened && <Suspense fallback={<Fragment/>}>
		<Bingo
			selectedSubjectTabID={props.selectedSubjectID}
			hierarchy={props.hierarchy.snapshot}
			onClose={() => setOpened(false)}
		/>
	</Suspense>;

	return <>
		<NoStudentProtectedButton title='Bingo'
		                          linkClassName='bingo-report-link'
		                          icon={<Icon/>}
		                          hoverTitle='Create personalized Bingo cards based on student results'
		                          noStudents={props.noStudents}
		                          onClicked={() => setOpened(true)}/>
		{bingo}
	</>;
}

function Icon() {
	return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='17'
	            viewBox='0 0 15 17'
	            fill='none'>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M5.76923 1.13151C5.76923 0.506571 6.28587 0 6.92308 0H8.07692C8.71413 0 9.23077 0.506571 9.23077 1.13151H12.6923C13.9667 1.13151 15 2.14472 15 3.39454V14.7097C15 15.9595 13.9667 16.9727 12.6923 16.9727H2.30769C1.03328 16.9727 0 15.9595 0 14.7097V3.39454C0 2.14472 1.03328 1.13151 2.30769 1.13151H5.76923ZM6.92308 1.69727C6.92308 1.38483 7.18111 1.13151 7.5 1.13151C7.81889 1.13151 8.07692 1.38483 8.07692 1.69727C8.07692 2.00971 7.81889 2.26303 7.5 2.26303C7.18111 2.26303 6.92308 2.00971 6.92308 1.69727ZM2.30769 5.37469C2.30769 4.90603 2.69531 4.52605 3.17308 4.52605H11.8269C12.3047 4.52605 12.6923 4.90603 12.6923 5.37469C12.6923 5.84335 12.3047 6.22332 11.8269 6.22332H3.17308C2.69531 6.22332 2.30769 5.84335 2.30769 5.37469ZM2.30769 8.76923C2.30769 8.30058 2.69531 7.9206 3.17308 7.9206H11.8269C12.3047 7.9206 12.6923 8.30058 12.6923 8.76923C12.6923 9.23789 12.3047 9.61787 11.8269 9.61787H3.17308C2.69531 9.61787 2.30769 9.23789 2.30769 8.76923ZM3.17308 11.3151C2.69531 11.3151 2.30769 11.6951 2.30769 12.1638C2.30769 12.6324 2.69531 13.0124 3.17308 13.0124H8.36538C8.84315 13.0124 9.23077 12.6324 9.23077 12.1638C9.23077 11.6951 8.84315 11.3151 8.36538 11.3151H3.17308Z'
		      fill='#0088CC'/>
	</svg>;
}
