import {environment} from '@esgi/core/environments';
import {Form, FormField, Validators} from '@esgi/deprecated/knockout';
import {AutoQuoterTabModule, SchoolInfoTabModel} from './components/tab';
import {Step3Template} from './template';
import {ServerClient} from 'pages/landing/kit/server-client';

import './styles.less';

export class OutputModel {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;

	country: string;
	countryId: number;

	state: string;
	stateId: number;

	federalSchoolId: number;
	federalSchool: string;

	federalDistrictId: number;
	federalDistrict: string;

	promoCode: string;

	products: CreateProductModel[] = [];
}

export class CreateProductModel {

	constructor(init?: Partial<CreateProductModel>) {
		Object.assign(this, init);
	}

	pricingType: number;
	quoteType: number;
	licences: number;
	licenseType: number;
}

export class Step3 extends Form {

	constructor() {
		super();

		this.promoCode = this.createPromoCode();
		this.license = new FormField<number>();
		this.hasSpecialist = ko.observable();
		this.specialistsLicense50 = new FormField<number>();
		this.specialistsLicense100 = new FormField<number>();
		this.specialistsLicense150 = new FormField<number>();
		this.specialistsLicense200 = new FormField<number>();
		this.firstName = this.createFirstName();
		this.lastName = this.createLastName();
		this.email = this.createEmail();
		this.phoneNumber = this.createPhoneNumber();

		this.subscribe();
	}

	template = () => Step3Template.render();

	promoCode: FormField<string>;
	license: FormField<number>;
	hasSpecialist: KnockoutObservable<boolean>;
	checkSpecialists = false;
	specialistsLicense50: FormField<number>;
	specialistsLicense100: FormField<number>;
	specialistsLicense150: FormField<number>;
	specialistsLicense200: FormField<number>;

	licenseSubscribe: KnockoutSubscription;
	hasSpecialistSubscribe: KnockoutSubscription;
	specialistsLicense50Subscribe: KnockoutSubscription;
	specialistsLicense100Subscribe: KnockoutSubscription;
	specialistsLicense150Subscribe: KnockoutSubscription;
	specialistsLicense200Subscribe: KnockoutSubscription;
	validationRequested = false;

	firstName: FormField;
	lastName: FormField;
	email: FormField;
	phoneNumber: FormField;

	isLoaded = false;
	isStepProcced = false;
	isLicensesValid = false;
	tabModule: AutoQuoterTabModule = undefined;


	load(): JQueryPromise<any> {
		this.isLoaded = true;
		const init = new SchoolInfoTabModel();

		init.CountryID = 234;
		init.countryName = 'United States';

		this.tabModule = new AutoQuoterTabModule(null,
			() => $.Deferred<SchoolInfoTabModel>().resolve(init));
		this.tabModule.load();

		return $.Deferred().resolve();
	}

	private createPromoCode() {

		const promoCodeValidator = Validators.Ajax(
			v => {
				return ServerClient.ESGIAPI.Get<{errorMessage: string}>('landing/promo-code', 'check', {data: {promoCode: v.value()}});
			}, v => v.errorMessage);

		const f = this.createField(null, promoCodeValidator);
		f.validation.errorValidation(true);
		f.validation.successValidation(true);
		f.validation.showValidation(true);

		return f;
	}

	private createPhoneNumber() {
		const f = this.createField(null, Validators.Required('Phone number is not valid'),
			Validators.Regex(/^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4}|\d{5})$/, 'Phone number is not valid'));

		f.validation.showValidation(true);
		f.validation.errorValidation(true);

		return f;
	}

	private createFirstName() {
		return this.createField(null, Validators.Required());
	}

	private createLastName() {
		return this.createField(null, Validators.Required());
	}

	private createEmail() {
		return this.createField(null, Validators.Required(), Validators.Email());
	}


	code = {
		getIfCached: (name) => {
			if (sessionStorage[name]) {
				return sessionStorage[name];
			}
			return undefined;
		},
	};

	private subscribe() {
		this.licenseSubscribe = this.license.value.subscribe(() => {
			if (this.validationRequested) {
				this.validateLicenses();
			}
		});
		this.specialistsLicense50Subscribe = this.specialistsLicense50.value.subscribe(() => {
			if (this.validationRequested) {
				this.validateLicenses();
			}
		});
		this.specialistsLicense100Subscribe = this.specialistsLicense100.value.subscribe(() => {
			if (this.validationRequested) {
				this.validateLicenses();
			}
		});
		this.specialistsLicense150Subscribe = this.specialistsLicense150.value.subscribe(() => {
			if (this.validationRequested) {
				this.validateLicenses();
			}
		});
		this.specialistsLicense200Subscribe = this.specialistsLicense200.value.subscribe(() => {
			if (this.validationRequested) {
				this.validateLicenses();
			}
		});
		this.hasSpecialistSubscribe = this.hasSpecialist.subscribe((value) => {
			this.checkSpecialists = value;
			if (this.validationRequested) {
				this.validateLicenses();
			}
		});
	}

	private disposing() {
		this.licenseSubscribe.dispose();
		this.specialistsLicense50Subscribe.dispose();
		this.specialistsLicense100Subscribe.dispose();
		this.specialistsLicense150Subscribe.dispose();
		this.specialistsLicense200Subscribe.dispose();
		this.hasSpecialistSubscribe.dispose();
	}

	private getValue(formField: FormField<number>): number {
		if (!formField.value() || formField.value().toString() == '') {
			return 0;
		}
		return formField.value();
	}

	private validateLicenses() {
		this.validationRequested = true;
		if((this.license.value() === undefined || !this.license.value() || this.license.value() == 0 || this.license.value().toString() == '') &&
			(this.specialistsLicense50.value() === undefined || !this.specialistsLicense50.value() || this.specialistsLicense50.value() == 0 || this.specialistsLicense50.value().toString() == '') &&
			(this.specialistsLicense100.value() === undefined || !this.specialistsLicense100.value() || this.specialistsLicense100.value() == 0 || this.specialistsLicense100.value().toString() == '') &&
			(this.specialistsLicense150.value() === undefined || !this.specialistsLicense150.value() || this.specialistsLicense150.value() == 0 || this.specialistsLicense150.value().toString() == '') &&
			(this.specialistsLicense200.value() === undefined || !this.specialistsLicense200.value() || this.specialistsLicense200.value() == 0 || this.specialistsLicense200.value().toString() == '')) {

			this.license.validation.errorValidation(true);
			this.specialistsLicense50.validation.errorValidation(true);
			this.specialistsLicense100.validation.errorValidation(true);
			this.specialistsLicense150.validation.errorValidation(true);
			this.specialistsLicense200.validation.errorValidation(true);

			this.license.validation.validators.push(Validators.NotZeroRequired('Please add at least one license'));
			if (this.checkSpecialists) {
				this.specialistsLicense50.validation.validators.push(Validators.NotZeroRequired('Please add at least one license'));
				this.specialistsLicense100.validation.validators.push(Validators.NotZeroRequired('Please add at least one license'));
				this.specialistsLicense150.validation.validators.push(Validators.NotZeroRequired('Please add at least one license'));
				this.specialistsLicense200.validation.validators.push(Validators.NotZeroRequired('Please add at least one license'));
			}

			this.license.validation.validate();
			this.specialistsLicense50.validation.validate();
			this.specialistsLicense100.validation.validate();
			this.specialistsLicense150.validation.validate();
			this.specialistsLicense200.validation.validate();

			this.isLicensesValid = false;
		} else {
			this.license.validation.validators = [];
			this.specialistsLicense50.validation.validators = [];
			this.specialistsLicense100.validation.validators = [];
			this.specialistsLicense150.validation.validators = [];
			this.specialistsLicense200.validation.validators = [];

			this.license.validation.validate();
			this.specialistsLicense50.validation.validate();
			this.specialistsLicense100.validation.validate();
			this.specialistsLicense150.validation.validate();
			this.specialistsLicense200.validation.validate();

			$('#license').bstooltip('destroy');
			$('#specialistLicence50').bstooltip('destroy');
			$('#specialistLicence100').bstooltip('destroy');
			$('#specialistLicence150').bstooltip('destroy');
			$('#specialistLicence200').bstooltip('destroy');

			this.isLicensesValid = true;
		}
	}

	validate() {
		this.validateLicenses();

		return super.validate().done(valid => {
			const schoolValid = this.tabModule.validate.schoolName.validate().state() === 'resolved';
			const districtValid = this.tabModule.validate.districtName.validate().state() === 'resolved';
			const stateValid = this.tabModule.validate.stateName.validate().state() === 'resolved';
			const counrtyValid = this.tabModule.validate.countryName.validate().state() === 'resolved';

			return schoolValid && districtValid && stateValid && counrtyValid;
		});
	}

	view = {
		back: () => {
			$(this).trigger('back');
		},
		getquote: () => {

			this.validate().done(v => {
				if (v && this.isLicensesValid) {
					const outputModel = new OutputModel();
					this.tabModule.extractModel().done((selectModel) => {
						outputModel.products.push(new CreateProductModel({licences: this.getValue(this.license), pricingType: 8, quoteType: 1}));
						outputModel.products.push(new CreateProductModel({licences: this.getValue(this.specialistsLicense50), pricingType: 5, quoteType: 5}));
						outputModel.products.push(new CreateProductModel({licences: this.getValue(this.specialistsLicense100), pricingType: 1, quoteType: 5}));
						outputModel.products.push(new CreateProductModel({licences: this.getValue(this.specialistsLicense150), pricingType: 2, quoteType: 5}));
						outputModel.products.push(new CreateProductModel({licences: this.getValue(this.specialistsLicense200), pricingType: 3, quoteType: 5}));

						outputModel.firstName = this.firstName.value();
						outputModel.lastName = this.lastName.value();
						outputModel.email = this.email.value();
						outputModel.phoneNumber = this.phoneNumber.value();

						outputModel.country = selectModel.countryName;
						outputModel.countryId = selectModel.CountryID;

						outputModel.state = selectModel.StateName;
						outputModel.stateId = selectModel.StateID;

						if (selectModel.federalDistrictId > 0) {
							outputModel.federalDistrictId = selectModel.federalDistrictId;
						}
						outputModel.federalDistrict = selectModel.federalDistrictName;

						if (selectModel.federalSchoolId > 0) {
							outputModel.federalSchoolId = selectModel.federalSchoolId;
						}
						outputModel.federalSchool = selectModel.schoolName;

						if (this.promoCode.value()) {
							outputModel.promoCode = this.promoCode.value();
						}

						location.href = `${environment.ssoApiURL}/quotes/getauto?` + $.param(outputModel);
						this.disposing();
					});
				}
			});
		},
	};

	events = {
		back: (callback) => {
			$(this).on('back', callback);
		},
	};
}
