import './style.less';
import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {Api, FolderInfo} from '../../api';

export class Item {
	constructor(public value: any, public text: string, public level: number) {
	}
}

export class State {
	items: Item[] = [];
	selected: Item = null;
	active: boolean = false;
	loaded: boolean = false;
	hovered: boolean = false;
}

export class Props extends SharedProps<State> {
	itemSelected?: (item: Item) => any;
	initialFolderID?: number;
	excludeFolderID?: number;
	label?: string;
	id?: string;
	class?: string;
	onLoad?: () => any;
	disabled?: boolean = false;
	textWhenDisabled?: string = '';
	disableLastLevel?: boolean = false;
}

export class FoldersHierarchy extends SharedComponent<State, Props> {
	ref: HTMLDivElement;
	private readonly maxLevel = 2;

	componentDidMount(): void {
		Api.GetFolderHierarchy().subscribe(r => {
			const items = [{
				value: null,
				text: 'My Library',
				level: 0,
			} as Item, ...this.mapFolders(r.folderInfos, 1, this.maxLevel)];
			let currentFolder;
			if (this.props.initialFolderID) {
				currentFolder = items.find(i => i.value === this.props.initialFolderID);
			} else {
				currentFolder = items[0];
			}
			this.props.onLoad && this.props.onLoad();
			this.setState({items: items, selected: currentFolder, loaded: true});
		});
		this.ref.addEventListener('blur', () => {
			setTimeout(() => this.close(), 200);
		});
	}

	isOptionDisabled(item: Item) {
		return this.props.disableLastLevel && item.level === this.maxLevel;
	}

	render(): JSX.Element | false | null {
		return <>
			{this.props.label && <label>{this.props.label}</label>}
			<div id={this.props.id} className='wrapper-dropdown' tabIndex={1}
			     ref={ref => this.ref = ref}>
				<div className={'overlay ' + (this.props.disabled ? 'disabled' : '')}
				     onClick={() => this.onClick()}/>
				<div className='text'>{this.getText(this.state.selected)}</div>
				<div className='dropdown'>
					{this.state.items.map((i, index) => {
						return <div key={index}>
							<OnHoverTooltip
								message={this.isOptionDisabled(i) ? `You cannot move a folder into a sub-folder.` : ''}>
								<div
									className={`option level-${i.level} ${this.isOptionDisabled(i) ? 'disabled-option' : ''}`}>
									<a
										onClick={() => this.onSelect(i)}>{this.getText(i)}</a></div>
							</OnHoverTooltip>
						</div>;
					})}
				</div>
			</div>
			{this.props.disabled &&
			this.state.loaded &&
			this.props.textWhenDisabled &&
			<span className='disabled-text'>{this.props.textWhenDisabled}</span>}
		</>;
	}

	getText(item: Item): string {
		if (item) {
			return item.text;
		}
	}

	onClick() {
		if (this.props.disabled) {
			return;
		}
		const active = this.state.active;
		if (active) {
			this.ref.classList.remove('active');
		} else {
			this.ref.classList.add('active');
		}
		this.setState({active: !this.state.active});
	}

	close() {
		this.ref.classList.remove('active');
		this.setState({active: false});
	}

	onSelect(item: Item) {
		if (this.props.disableLastLevel && item.level == this.maxLevel) {
			return;
		}
		this.props.itemSelected && this.props.itemSelected(item);
		this.ref.classList.remove('active');
		this.setState({selected: item, active: false});
	}


	mapFolders(foldersInfo: FolderInfo[], level = 0, maxLevel: number): Item[] {
		if (!foldersInfo || level > maxLevel) {
			return [];
		}
		const folders = [];
		foldersInfo.filter(f => f.id !== this.props.excludeFolderID).forEach(f => {
			folders.push({value: f.id, text: f.name, level: level} as Item);
			folders.push(...this.mapFolders(f.subFolders, level + 1, maxLevel));
		});
		return folders;
	}
}