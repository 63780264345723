import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import {FixedColumnPosition, ReportTableFixableProps} from './types';
import styles from './report-table.module.less';

export function ReportTableTH(props: ReportTableFixableProps) {
	let className = styles.fixedCenterTh;

	if (props.fixedColumnPosition === FixedColumnPosition.Left) {
		className = styles.fixedLeftTh;
	}

	if (props.fixedColumnPosition === FixedColumnPosition.Right) {
		className = styles.fixedRightTh;
	}
	return <th className={join(className, props.className)}>
		{props.children}
	</th>;
}

