import {ShowFilter} from '../show-filter';
import {ShowResultsFilter} from '../../../show-results-filter';
import {MarkingPeriodFilter} from '../../../marking-period-filter';
import {useBehaviorSubject, useStream} from '@esgillc/ui-kit/utils';
import {RubricResultReportService} from '../../../../../../service';
import {MarkingPeriod} from '../../../../../../service/types';
import {ReportFilterBodyStyled, ReportFilterContainerStyled, ReportHeaderRowStyled} from './index.styled';
import {Filter} from '../../../../../../service/settings-service';
import {ReportSelect} from '../../../report-select';
import {useMemo} from 'react';

type HeaderProps = {
	service: RubricResultReportService;
};

export function Header({service}: HeaderProps) {
	const students = useStream(service.studentLevelService.students$);
	const selectedStudent = useStream(service.studentLevelService.selectedStudent$);
	const showNotes = useBehaviorSubject<boolean>(service.studentLevelService.showNotes$);
	const showSummary = useBehaviorSubject<boolean>(service.studentLevelService.showSummary$);
	const showInColor = useBehaviorSubject<boolean>(service.studentLevelService.showInColor$);
	const filter = useStream<Filter | null>(service.settingsService.filter$);

	const options = useMemo(() => {
		return students?.map(x => ({id: x.id, name: x.value}));
	}, [students]);

	return (
		<ReportFilterBodyStyled>
			<ReportHeaderRowStyled>
				<ReportFilterContainerStyled>
					{selectedStudent && (
						<ReportSelect label='Student' options={options}
						              onValueChange={(value) => service.selectStudent(Number(value))}
						              value={selectedStudent?.id.toString()}/>
					)}
				</ReportFilterContainerStyled>
				<ShowFilter
					showNotes={showNotes}
					showSummary={showSummary}
					showInColor={showInColor}
					onShowInColorChange={(value) => service.studentLevelService.setShowInColorFilter(value)}
					onShowNotesChange={(value) => service.studentLevelService.setShowNotesFilter(value)}
					onShowSummaryChange={(value) => service.studentLevelService.setShowSummaryFilter(value)}
				/>
				<ShowResultsFilter
					onCarryForwardClicked={(value) => service.settingsService.setCarryForward(value)}
					onShowBaseLineClicked={(value) => service.settingsService.setShowBaseLine(value)}
					carryScoresForward={filter?.carryScoresForward ?? false}
					showBaseline={filter?.showBaseline ?? false}
				/>
				<MarkingPeriodFilter
					onChanged={(value) => service.settingsService.setMarkingPeriod(value ? MarkingPeriod.Current : MarkingPeriod.All)}
					showCurrentMarkingPeriod={(filter?.showCurrentMarkingPeriod === MarkingPeriod.Current) ?? false}
				/>
			</ReportHeaderRowStyled>
		</ReportFilterBodyStyled>
	);
}
