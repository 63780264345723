import {StudentModel} from '../types';
import {useCallback, useEffect, useState} from 'react';

export function useStudentsSelection(students: StudentModel[]): {selectedStudents: StudentModel[], select: (student: StudentModel) => void, toggleAll: VoidFunction, unselectAll: VoidFunction} {
	const [selectedStudents, setSelectedStudents] = useState<StudentModel[]>([]);

	const select = useCallback((student: StudentModel) => {
		setSelectedStudents((values) => {
			if(values.includes(student)) {
				return values.filter(s => s !== student);
			} else {
				return [...values, student];
			}
		});
	}, []);

	const toggleAll = useCallback(() => {
		setSelectedStudents((values) => {
			const allStudentsIDs = students.map(s => s.studentID);
			const selectedStudentsIDs = values.map(s => s.studentID);
			const allSelected = allStudentsIDs.every(s => selectedStudentsIDs.includes(s));

			if(allSelected) {
				return [];
			} else {
				return students;
			}
		});
	}, [students]);

	const unselectAll = useCallback(() => {
		setSelectedStudents([]);
	}, []);

	useEffect(() => {
		setSelectedStudents((value) => {
			const ids = value.map(v => v.studentID);
			return students.filter(s => ids.includes(s.studentID));
		});
	}, [students]);

	return {
		selectedStudents,
		select,
		toggleAll,
		unselectAll,
	};
}