import React, {
	ComponentPropsWithoutRef,
	forwardRef,
	useEffect,
	useId,
	useRef,
} from 'react';
import {animated, config, useSpring} from 'react-spring';
import {CSS} from '@stitches/react';
import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {useProgressBarContext} from '../../context';
import {assignRefs} from '../../utils/assign-refs';
import {useProgressRelatedContent} from '../../utils/hooks/use-progress-related-content';
import {ThemeColorKeys} from '../../../types';
import {getThemeColor} from '../../../utils';

type LineWithPointerProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: Omit<CSS, 'width' | 'height'>;

	/** Set width for svg. */
	lineStrokeWidth?: number;

	/** Set line color. */
	lineColor?: ThemeColorKeys;

	/** Set progress value color. */
	progressValueColor?: ThemeColorKeys;

	/** Set filled color for pointer. */
	pointerFillColor?: ThemeColorKeys;

	/** Hide pointer. */
	hidePointer?: boolean;

	pointerType?: 'circle' | 'rect';

	/** Pointer width. Requried for rect pointer type */
	pointerWidth?: number;
};

export const LineWithPointer = forwardRef<HTMLDivElement, LineWithPointerProps>(
	(
		{
			dataCy = 'ui-kit-progress-bar-line-with-pointer',
			color,
			lineStrokeWidth = 6,
			lineColor = 'border',
			progressValueColor = 'lowContrast',
			pointerFillColor = 'neutral40',
			hidePointer,
			css,
			children,
			pointerType='circle',
			pointerWidth,
			...props
		},
		forwardRef,
	) => {

		const progressLineColorId = useId();
		const {progress, setAnimationFinished, setLineProgressBarRef, lineProgressBarRef} = useProgressBarContext();
		const pointerRef = useRef<SVGCircleElement>(null);
		const rectRef = useRef<SVGRectElement>(null);

		const targetRef = pointerType === 'circle' ? pointerRef : rectRef;
		const {containerFillWidth, itemWidth} = useProgressRelatedContent(lineProgressBarRef, targetRef, progress);

		useEffect(() => {
			setAnimationFinished(false);
		}, [progress]);

		const getPointTransform = () => {
			const itemWidthPart = pointerType === 'circle' ? itemWidth / 2 : itemWidth;
			if (containerFillWidth < itemWidth) {
				return itemWidthPart - containerFillWidth;
			}
			if (containerFillWidth > itemWidth) {
				return -itemWidthPart;
			}
			return 0;
		};

		const pointerCircleAnimateProps = useSpring({
			from: {
				cx: '0%',
			},
			to: {
				cx: `${progress * 100}%`,
			},
			config: config.slow,
			onRest: (e) => setAnimationFinished(Boolean(e.finished)),
		});

		const pointerRectAnimateProps = useSpring({
			from: {
				x: '0%',
			},
			to: {
				x: `${progress * 100}%`,
			},
			config: config.slow,
			onRest: (e) => setAnimationFinished(Boolean(e.finished)),
		});

		const lineProgressAnimateProps = useSpring({
			from: {
				width: '0%',
			},
			to: {
				width: `${progress * 100}%`,
			},

			config: config.slow,
		});

		return <LineBox ref={(lineRef: HTMLDivElement) => {
							setLineProgressBarRef?.(lineRef);
							assignRefs(lineRef, forwardRef);
						}}
						data-cy={dataCy}
						{...props}>

			<SVG xmlns='http://www.w3.org/2000/svg'
				 viewBox={`0 0 100% ${lineStrokeWidth}`}
				 width='100%'
				 height={lineStrokeWidth}
				 rx={lineStrokeWidth / 2}
				 data-cy={`${dataCy}-line`}
			>
				<rect cx='0'
					  cy='50%'
					  rx={lineStrokeWidth / 2}
					  fill={getThemeColor(lineColor)}
					  width='100%'
					  height={lineStrokeWidth}
				/>

				<animated.rect
					data-cy={`${dataCy}-progress-line`}
					height={lineStrokeWidth}
					cx='0'
					cy='50%'
					fill={`url(#${progressLineColorId})`}
					rx={lineStrokeWidth / 2}
					style={lineProgressAnimateProps}
				/>
				<defs>
					<linearGradient id={progressLineColorId}>
						<stop offset='0%' stopColor={getThemeColor(progressValueColor)}/>
						<stop offset='100%' stopColor={getThemeColor(progressValueColor)} stopOpacity='0.32'/>
					</linearGradient>
				</defs>
			</SVG>

			{!hidePointer && <PointerSVG
				xmlns='http://www.w3.org/2000/svg'
				viewBox={`0 0 100% ${lineStrokeWidth}`}
				height={lineStrokeWidth}
				width='100%'
				data-cy={`${dataCy}-progress-pointer`}
			>
				{pointerType === 'circle' &&
					<animated.circle
						ref={pointerRef}
						style={{
							transform: `translateX(${getPointTransform()}px)`,
							...pointerCircleAnimateProps,
						}}
						cy='50%'
						r={lineStrokeWidth / 2}
						fill={getThemeColor(pointerFillColor)}
						stroke={getThemeColor('vivid')}
						rx={lineStrokeWidth / 8}
						strokeWidth='1'
					/>
				}
				{pointerType === 'rect' &&
					<animated.rect
						ref={rectRef}
						style={{
							transform: `translateX(${getPointTransform()}px)`,
							...pointerRectAnimateProps,
						}}
						width={pointerWidth ?? lineStrokeWidth/2}
						height={lineStrokeWidth}
						fill={getThemeColor(pointerFillColor)}
					/>
				}
			</PointerSVG>}
		</LineBox>;
	},
);

const LineBox = styled(FlexBox, {
	position: 'relative',
});

const SVG = styled('svg', {
	width: '100%',
});

const PointerSVG = styled('svg', {
	width: '100%',
	position: 'absolute',
	top: '0',
	left: '0',
});
