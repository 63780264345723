import React from 'react';
import {Subscription} from 'rxjs';
import {LandingService, TestInfo} from '../../../services/landing-service';
import {SubjectItem, SubjectsService} from '../../../services/subjects-service';
import {Test} from '../test/test';
import {Tray} from '../tray/tray';

class State {
	cardsInfo: TestInfo[] = [];
	title: string = '';
	loaded: boolean = false;
	subjects: SubjectItem[];
}

class Props {
	landingService: LandingService;
	subjectsService: SubjectsService;
	onLoaded: () => void;
}

export default class GradeLevel extends React.Component<Props, State> {
	private gradeLevelSub: Subscription;
	private selfSubjectsSub: Subscription;

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	render() {
		return <Tray title={this.state.title}>
			{this.state.cardsInfo.map(c => {
				return <Test initModel={c} subjects={this.state.subjects} hidden={() => this.testHidden(c)} key={c.testId}/>;
			})}
		</Tray>;
	}

	componentDidMount(): void {
		if (this.state.loaded) {
			return;
		}

		this.gradeLevelSub = this.props.landingService.gradeLevels$
			.subscribe((r => this.setState({
				cardsInfo: r.tests,
				title: r.gradeLevel,
				loaded: true,
			}, () => this.props.onLoaded())
			));

		this.selfSubjectsSub = this.props.subjectsService.selfSubjects$
			.subscribe(r => this.setState({subjects: r}));
	}

	public componentWillUnmount() {
		this.gradeLevelSub && this.gradeLevelSub.unsubscribe();
		this.selfSubjectsSub && this.selfSubjectsSub.unsubscribe();
	}

	private testHidden(c: TestInfo) {
		let tests = this.state.cardsInfo.filter(s => s.testId !== c.testId);
		this.setState({cardsInfo: tests});
	}
}
