import React, {useCallback, useState} from 'react';
import {Modal} from '@esgillc/ui-kit/modal';
import Preview from 'shared/modules/bingo/components/preview/preview';
import Steps from 'shared/modules/bingo/steps/steps';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {BingoService} from 'shared/modules/bingo/service/service';
import {useService} from '@esgi/core/service';
import {Loader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import Finish from 'shared/modules/bingo/steps/finish';

import styles from './bingo.module.less';

const STEPS_COUNT = 6;

export interface BingoProps {
	hierarchy: HierarchySnapshot;
	selectedSubjectTabID: number;
	onClose: () => void;
}

export default function Bingo ({onClose, hierarchy, selectedSubjectTabID}: BingoProps) {
	const service = useService(BingoService);
	const loading = useBehaviorSubject(service.loading$);
	const [step, setStep] = useState(0);

	const handlePrevStep = useCallback(() => {
		setStep((step) => step - 1);
	}, []);

	const handleNextStep = useCallback(() => {
		if (service.validateStep(step)) {
			if (step + 1 <= STEPS_COUNT) {
				setStep((step) => step + 1);
			}
		}
	}, [step]);

	return (
		<Modal className={styles.modal}>
			<Loader show={loading}/>
			{step === 0 && <Preview nextClicked={handleNextStep} closeClicked={onClose}/>}
			{step > 0 && step < STEPS_COUNT && <Steps
				service={service}
				step={step}
				hierarchy={hierarchy}
				onClose={onClose}
				nextStep={handleNextStep}
				prevStep={handlePrevStep}
				setStep={setStep}
				selectedSubjectID={selectedSubjectTabID}
			/>
			}
			{step === STEPS_COUNT && <Finish onClose={onClose} prevStep={handlePrevStep} />}
		</Modal>
	);
}
