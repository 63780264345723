import {CSS} from '@stitches/react';
import {styled} from '@esgi/ui/theme';
import {useNavigationBarContext} from '../../context';
import {NavigationBarOrientation} from '../../types';

type NavigationBarDividerProps = {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export default function NavigationBarDivider({dataCy, ...props}: NavigationBarDividerProps) {
	const {expanded, orientation} = useNavigationBarContext();

	return (
		<Divider
			data-cy={dataCy ?? 'ui-kit-navigation-bar-divider'}
			expanded={expanded}
			orientation={orientation}
			{...props}
		/>
	);
}

const Divider = styled('span', {
	height: '1px',
	backgroundColor: '$border',
	variants: {
		expanded: {true: {}},
		orientation: {
			[NavigationBarOrientation.Horizontal]: {
				width: '1px',
				height: '36px',
			},
			[NavigationBarOrientation.Vertical]: {},
		},
	},
	compoundVariants: [
		{
			orientation: NavigationBarOrientation.Vertical,
			expanded: true,
			css: {
				width: 'calc(100% - 32px)',
				margin: '6px 16px',
			},
		},
		{
			orientation: NavigationBarOrientation.Vertical,
			expanded: false,
			css: {
				width: 'calc(100% - 20px)',
				margin: '6px 10px',
			},
		},
	],
});
