import {Text} from '@esgi/ui/typography';
import {ComponentProps, useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {Tooltip} from '@esgi/ui/tooltip';
import {TooltipContent, TriggerText} from './index.styled';

type Props = ComponentProps<typeof Text>;

export function OneLinedText(textProps: Props) {
	const [isTextTruncated, setIsTextTruncated] = useState(false);

	const textRef = useRef<HTMLElement>(null);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight}}) => {
		setIsTextTruncated(clientWidth < scrollWidth || clientHeight < scrollHeight);
	});

	return (
		<Tooltip open={!isTextTruncated ? false : undefined}>
			<Tooltip.Trigger>
				<TriggerText ref={textRef} {...textProps} />
			</Tooltip.Trigger>
			<TooltipContent>
				<Text size='large'>{textProps.children}</Text>
			</TooltipContent>
		</Tooltip>
	);
}
