import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Avatar} from '@esgi/ui/avatar';
import {Text} from '@esgi/ui/typography';
import {TestType} from '@esgi/main/libs/core';
import {GridBox} from '@esgi/ui/layout';
import {TestColorIndicator, OneLinedText} from '@esgi/main/kits/common';
import {TestTypeLabel, getFullName, getInitials} from '@esgi/main/features/teacher/home';
import {AlertBody, TestInfoBox, alertColorConfig, alertRootCss} from '../../styled';

type Props = {
		testID: number,
    testName: string,
    testType: TestType,
    firstName: string,
    lastName: string,
    avatarUrl: string,
    onClose: VoidFunction,
    onAddSessionClick: VoidFunction,
    onStartTestClick: VoidFunction,
};

export function StudentNotTestedDialog(props: Props) {
	const alert = Alert.useRef();
	const close = Alert.useClose(alert, props.onClose);
	const onAddSessionClick = Alert.useClose(alert, props.onAddSessionClick);
	const onStartTestClick = Alert.useClose(alert, props.onStartTestClick);

	return (
		<Alert modalManagerRef={alert} colorConfig={alertColorConfig} css={alertRootCss}>
			<Alert.Header withBacklight={false} onCloseIconClick={close}>
				<GridBox align='center' flow='column' gap='3'>
					<Avatar.Root size='xs'>
						<Avatar.Image src={props.avatarUrl} />
						<Avatar.Fallback>
							<Text color='primary' size='large'>
								{getInitials({firstName: props.firstName, lastName: props.lastName})}
							</Text>
						</Avatar.Fallback>
					</Avatar.Root>
					<OneLinedText size='small' data-cy='student-full-name' color='primary'>
						{getFullName({firstName: props.firstName, lastName: props.lastName})}
					</OneLinedText>
				</GridBox>
			</Alert.Header>
			<AlertBody>
				<TestInfoBox>
					<TestColorIndicator testID={props.testID} />
					<OneLinedText size='xLarge' color='base' data-cy='test-name'>
						{props.testName}
					</OneLinedText>
					<TestTypeLabel dataCy='test-type' testType={props.testType} />
				</TestInfoBox>

				<GridBox dataCy='test-description' gap='1'>
					<Text size='medium' color='neutral40'>
						No data is available for this test, because it has not been completed.
					</Text>
					<Text size='medium' color='neutral40'>
						You can start the test now or add a session manually by choosing an option below.
					</Text>
				</GridBox>
			</AlertBody>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' data-cy='add-session-manually-button' onClick={onAddSessionClick}>
						<Text size='medium' bold data-cy='add-session-manually-button-label'>
							Add Session Manually
						</Text>
					</Button>
					<Button color='secondary' data-cy='start-test-button' onClick={onStartTestClick}>
						<Text size='medium' bold data-cy='start-test-button-label'>
							Start Test
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
