import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	paddingTop: 4,
	gap: '$4',
	gridTemplateRows: 'auto 1fr',
	height: 'max-content',
});

export const Header = styled(GridBox, {
	gap: 20,
	gridAutoFlow: 'column',

	variants: {
		withLeftHeaderContent: {
			true: {
				justifyContent: 'space-between',
			},

			false: {
				justifyContent: 'end',
			},
		},
	},
});

export const LegendBox = styled(GridBox, {
	gap: 20,
	gridAutoFlow: 'column',
});

export const FullScreenButton = styled(Button, {
	padding: 3,
	minWidth: 'unset',

	'& > svg:last-child': {
		margin: 'unset',
	},
});
