import {forwardRef, useMemo} from 'react';
import {CheckboxProps as RadixCheckboxProps} from '@radix-ui/react-checkbox';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {CheckAll, Correct} from '@esgi/ui/icons';
import {Root, Indicator, IndicatorContainer, StyledLabel, IndeterminateIcon} from './styled';

type CheckboxProps = {
	/** The controlled checked state of the checkbox. Must be used in conjunction with onCheckedChange. */
	checked: RadixCheckboxProps['checked']

	/** Event handler called when the checked state of the checkbox changes. */
	onCheckedChange: RadixCheckboxProps['onCheckedChange']

	/** The controlled attribute of the checkbox. Must be used in conjunction with checked if we need to have to check icons. */
	allChecked?: boolean

	/** The label of the checkbox. */
	label?: string,
} & Skeletonable & RadixCheckboxProps & BaseComponentProps

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(({
	label,
	checked,
	onCheckedChange,
	disabled,
	allChecked,
	dataCy = 'ui-kit-checkbox',
	...props
}: CheckboxProps, ref) => {

	const getColorType = useMemo(() => {
		if (disabled) {
			return 'disabled';
		}
		if (checked) {
			return 'checked';
		}
	}, [disabled, checked]);

	const renderCheckboxIcon = () => {
		if (checked === true) {
			if (allChecked) {
				return <CheckAll data-cy={`${dataCy}-all-checked-icon`} width={20} height={20}/>;
			}
			return <Correct data-cy={`${dataCy}-correct-icon`} width={20} height={20}/>;
		}

		if (checked === 'indeterminate') {
			return <IndeterminateIcon size='small' font='mono'
									  data-cy={`${dataCy}-indeterminate-icon`}>-</IndeterminateIcon>;
		}
	};

	return <Root checked={checked}
				 onCheckedChange={onCheckedChange}
				 colors={getColorType}
				 disabled={disabled}
				 data-cy={dataCy}
				 ref={ref}
				 {...props}
	>
		<IndicatorContainer>
			<Indicator data-cy={`${dataCy}-indicator`}>
				{renderCheckboxIcon()}
			</Indicator>
		</IndicatorContainer>

		{Boolean(label) && <StyledLabel size='small' font='mono' data-cy={`${dataCy}-label`}>
			{label}
		</StyledLabel>}
	</Root>;
});
