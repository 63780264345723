import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import {ReportTableComponentProps} from './types';
import styles from './report-table.module.less';

export function ReportTableDarkBodyRow(props: ReportTableComponentProps) {
	return <tr className={join(styles.row, styles.dark, props.className)}>
		{props.children}
	</tr>;
}

