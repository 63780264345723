import {Drawer} from '@esgi/main/kits/common';
import {UserCard} from '@esgi/ui/icons';
import {DrawerBody} from './drawer-body.styled';
import {ExpirationDate} from './expiration-date';
import {TrackModel, UserModel} from '../types';
import {GridBox} from '@esgi/ui/layout';
import {DisabledInput} from './panels/components/disabled-input';
import {trackTypeTranscript} from '../constants';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Logout} from './logout';
import {DisabledTrackDates} from './panels/components/disabled-track-dates';
import {useMemo} from 'react';
import {isNull} from 'underscore';

type Props = {
	user: UserModel;
	activeUserTrackId: TrackModel['trackID'];
	tracks: TrackModel[] | null;
	onClose: VoidFunction;
};

export function SecondaryProfileContent({user, activeUserTrackId, tracks, onClose}: Props) {
	const visibleTrack = useMemo(() => {
		const activeTrack = tracks?.find(({trackID}) => trackID === activeUserTrackId) ?? tracks?.[0];

		return activeTrack ?? null;
	}, [activeUserTrackId, tracks]);

	if (isNull(visibleTrack)) {
		return null;
	}

	return (
		<>
			<Drawer.Header Icon={UserCard} tooltipText='My Account' sectionName='My Account' closeDrawer={onClose} />
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<DrawerBody>
					<Drawer.ContentBlock title='Expiration Date'>
						<ExpirationDate date={new Date(user.expirationDate)} />
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Current Marking Period'>
						<GridBox flow='column' gap='3'>
							<DisabledInput value={visibleTrack.name} placeholder='Marking Period Name' />
							<DisabledInput value={trackTypeTranscript[visibleTrack.schoolYearType]} placeholder='Type' />
						</GridBox>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Marking Period Dates'>
						<DisabledTrackDates rowGap='16' track={visibleTrack} counterSize='small' />
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Terminate Current Session'>
						<Logout />
					</Drawer.ContentBlock>
				</DrawerBody>
			</OverlayScrollbarsComponent>
		</>
	);
}
