import {IEPStatusModel, StudentModel, SubjectModel, TestModel} from './models';

export function getSelectedStudent(students: StudentModel[], selectedStudentID: number): StudentModel[] {
	return students.filter(s => s.studentID === selectedStudentID);
}

export function getSelectedSubject(subjects: SubjectModel[], selectedSubjectID: number): SubjectModel[] {
	const subject = subjects.filter(s => s.id === selectedSubjectID);
	return subject ?? [subjects[0]];
}

export function getSelectedTest(tests: TestModel[], selectedTestID: number): TestModel[] {
	return tests.filter(t => t.testID === selectedTestID);
}

export function getSelectedStatus(statuses: IEPStatusModel[], selectedStatusID?: number): IEPStatusModel[] {
	return statuses.filter(s => s.id === selectedStatusID);
}