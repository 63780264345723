import React from 'react';
import styles from './styles.module.less';
import {ResultsService} from '../../../../../services/results-service/results-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ParentLetterHeader} from '../parent-letter-header';
import {GradeScales} from '../grade-scales';
import {ResultsTable} from '../results-table';

interface Props {
	service: ResultsService
}

export function ResultsForm({service}: Props) {
	const model = useBehaviorSubject(service.model);
	return <div className={styles.parentLetters}>
		{model?.students && model.students.map(student => <div>
			<div className={styles.parentLetter}>
				<ParentLetterHeader model={model} student={student}/>
				<div>
					<strong className={styles.studentName}>{student.fullName}</strong>
					{student.letter && <>
						<div className={styles.letter}
						     dangerouslySetInnerHTML={{__html: student.letter.letterText}}/>
					</>}
					{model.gradeScaleEntries && <GradeScales gradeScaleEntries={model.gradeScaleEntries}/>}
					<ResultsTable model={model} student={student}/>
				</div>
			</div>
		</div>)}
	</div>;
}
