import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useModeContext} from '../../../multi-tab-panel';
import {ModePermissions, SectionMode} from '../../../types';
import {DragDropMode, Edit, NewFolder, Plus, UserCard, UserList, Users} from '@esgi/ui/icons';
import {useMemo, useState} from 'react';
import {ActionButtonsDivider, ButtonIconable, SectionBox} from './styled';
import {ContextMenu} from '@esgi/ui';
import {noop} from 'underscore';

type Props = {
	disableAdd?: boolean,
};

const titlesMap = {
	[SectionMode.View]: 'Students',
	[SectionMode.Edit]: 'Edit Mode',
	[SectionMode.Rearrange]: 'Rearrange Mode',
	[SectionMode.Add]: 'Add Mode',
};

const buttonsMap = [
	{
		activateMode: SectionMode.Edit,
		Icon: Edit,
	},
	{
		activateMode: SectionMode.Rearrange,
		Icon: DragDropMode,
	},
];

const mapPermissions = (permissions: ModePermissions) => {
	return {
		[SectionMode.Add]: permissions.canAdd,
		[SectionMode.Edit]: permissions.canEdit,
		[SectionMode.Rearrange]: permissions.canRearrange,
	};
};

const addSectionModeMap = [
	{
		itemText: 'Add a Student...',
		Icon: UserCard,
		// permitted: sectionModePermissions[SectionMode.Add][TabID.Students],
		dataCy: 'students-panel-menu-item-add-student',
	},
	{
		itemText: 'Add Students via File...',
		Icon: UserList,
		// permitted: sectionModePermissions[SectionMode.Add][TabID.Students] && Boolean(selectedClassId),
		dataCy: 'students-panel-menu-item-add-student-via-file',
	},
	{
		itemText: 'Add a Class...',
		Icon: Users,
		// permitted: sectionModePermissions[SectionMode.Add][TabID.Classes],
		dataCy: 'students-panel-menu-item-add-class',
	},
	{
		itemText: 'Add a Group...',
		Icon: NewFolder,
		// permitted: sectionModePermissions[SectionMode.Add][TabID.Groups],
		dataCy: 'students-panel-menu-item-add-group',
	},
];

export function ModeHeader(props: Props) {
	const {mode, setMode, permissions} = useModeContext();
	const isViewMode = mode === SectionMode.View;

	const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
	const title = useMemo(() => titlesMap[mode], [mode]);
	const permissionsToMode = useMemo(() => mapPermissions(permissions), [permissions]);

	return <FlexBox justify='between' align='center' data-cy='mode-header'>
		<FlexBox align='center'>
			{isViewMode && <SectionBox>
				<Users/>
			</SectionBox>}
			<Text size='small' color={isViewMode ? 'neutral56' : 'primary'} data-cy='mode-header-title'>
				{title}
			</Text>
		</FlexBox>

		<GridBox gap='1' flow='column'>
			{buttonsMap.map(({activateMode, Icon}, index) => (
				<ButtonIconable key={index} onClick={() => activateMode === mode ? setMode(SectionMode.View) : setMode(activateMode)}
				                disabled={!permissionsToMode[activateMode]}>
					{<Icon/>}
				</ButtonIconable>
			))}

			<ActionButtonsDivider/>

			<ContextMenu.Root onOpenChange={setIsAddMenuOpen} open={isAddMenuOpen} data-cy='add-context-menu-root' modal={false}>
				<ContextMenu.Trigger disabled={props.disableAdd} dataCy='add-context-menu-trigger'>
					<ButtonIconable withBackgroundHover active={isAddMenuOpen} dataCy='add-context-menu-trigger-button'>
						<Plus/>
					</ButtonIconable>
				</ContextMenu.Trigger>
				<ContextMenu.Content dataCy='add-context-menu-content'>
					<ContextMenu.Group>
						{addSectionModeMap.map(({itemText, Icon, dataCy}, index) => (
							<ContextMenu.Item key={index} onClick={noop} disabled={false} dataCy={dataCy}>
								<Icon/>
								<Text size='medium' data-cy={`${dataCy}-label`}>{itemText}</Text>
							</ContextMenu.Item>
						))}
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>
		</GridBox>
	</FlexBox>;
}