import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Checkbox, Label} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';

type Props = {
	control: IFormControl;
}

export const MarkAsCompletedField = ({control}: Props) => (
	<FormElement
		className={styles.formElement}
		control={control}
	>
		<Label className={styles.label}>&nbsp;</Label>
		<div className={join(styles.field, styles.completedField)}>
			<Checkbox id='mark_as_completed'/>
			<label
				className={styles.checkboxLabel}
				htmlFor='mark_as_completed'
			>
				Mark Goal as Complete
			</label>
		</div>
	</FormElement>
);
