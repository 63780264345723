export type ActionsProps = {
	onPost: VoidFunction;
	onEdit: VoidFunction;
	onDelete: VoidFunction;
	onComplete: VoidFunction;
}

export enum Actions {
	Post = 'Post to Students',
	Edit = 'Edit Assignment',
	Delete = 'Delete Assignment',
	Complete = 'Complete',
}
