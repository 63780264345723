import {BaseService} from '@esgi/core/service';
import {EventBusDispatcher} from '@esgillc/events';
import {BackgroundGenerationModel, BackgroundGenerationRequest} from './types';
import {HttpClient} from '@esgi/api';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {getFileFormatName} from './utils';

export class TestSessionDetailsService extends BaseService {
	 public startBackgroundGeneration(model: BackgroundGenerationRequest) {
		 HttpClient.default.ESGIApi.post<BackgroundGenerationModel>(
			 'assessments/test-session-details',
			 'start-background-generation',
			 model,
		 ).subscribe((r) => {
			 const reportGuid = r.reportGuid;
			 EventBusDispatcher.dispatch(BackgroundDownloadManagerEvents.Start, {
				 reportGuid,
				 fileType: getFileFormatName(model.format),
				 displayRemainingText: true,
			 });
		 });
	 }
}