import React from 'react';

export function getEventCoord(event: React.MouseEvent<HTMLElement>) {
	let el = event.currentTarget;
	let rect = el.getBoundingClientRect();
	let top = rect.top + el.offsetHeight;
	let left = rect.left;

	return {top: top, left: left};
}
