import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
	onAlertClose: VoidFunction;
	onDrawerClose: VoidFunction;
};

export function DeleteFileUpload({onAlertClose, onDrawerClose}: Props) {
	return (
		<>
			<Alert.Header>
				<Text size='large'>Delete File Upload?</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='medium'>No students will be added to your account.</Text>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={onAlertClose}>
						<Text size='medium' bold>
							Cancel
						</Text>
					</Button>
					<Button
						color='secondary'
						onClick={() => {
							onAlertClose();
							onDrawerClose();
						}}
					>
						<Text size='medium' bold>
							Delete File
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</>
	);
}
