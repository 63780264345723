import {BoxArrowUpRight} from '@esgi/ui/icons';
import {Avatar as AvatarUI} from '@esgi/ui/avatar';
import {Link, Avatar} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {useCallback, useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {DrawerMode} from '@esgi/main/features/teacher/home';
import {useLocation, useNavigate} from 'react-router-dom';
import {StudentProfileDrawerName} from '@esgi/main/features/teacher/drawers';

type UserInfoProps = {
	studentID: number,
	firstName: string,
	lastName: string,
	avatarUrl: string,
};

export function UserInfo({firstName, lastName, avatarUrl, studentID}: UserInfoProps) {
	const navigate = useNavigate();
	const {pathname} = useLocation();

	const fallbackText = useMemo(() => `${firstName[0]}${lastName[0]}`.toUpperCase(), [firstName, lastName]);
	const fullName = [firstName, lastName].filter(Boolean).join(' ').trim();

	const handleStudentClicked = useCallback(
		() => {
			const studentProfileDrawerUrl = new URLSearchParams({
				drawerMode: DrawerMode.Edit,
				drawerName: StudentProfileDrawerName,
				studentId: studentID.toString(),
			});
			navigate(`${pathname}?${studentProfileDrawerUrl.toString()}`);
		},
		[studentID, navigate, pathname],
	);

	return <GridBox gap='3' align='center' flow='column'>
		<Avatar size='sm'>
			<AvatarUI.Image src={avatarUrl}/>
			<AvatarUI.Fallback>{fallbackText}</AvatarUI.Fallback>
		</Avatar>
		<Link onClick={handleStudentClicked} dataCy='student-link'>
			<Text size='medium' bold>
				{fullName}
			</Text>
			<BoxArrowUpRight width={32} height={32}/>
		</Link>
	</GridBox>;
}
