import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

type AssignmentURLModel = {
	assignmentId: string;
};

export function useAssignmentId() {
	const {assignmentId} = useParams<AssignmentURLModel>();

	return useMemo(() => (assignmentId ? Number(assignmentId) : null), [assignmentId]);
}
