import React, {useState} from 'react';
import {ContactUs} from '@esgi/features/support';
import './styles.less';
import {Link} from 'react-router-dom';
import {RoutesMap} from '@esgi/main/libs/core';

export function Footer() {
	const [contactUs, setContactUs] = useState(false);

	return <div className='terms'>
		<div className='links'>
			<a href='#' onClick={() => setContactUs(true)}>
				Contact Us
			</a>
			<a href='//support.esgisoftware.com/hc/en-us/categories/201288126-ESGI?flash_digest=d1cc233aab44baa34bd1db073574c2d0fbbe2ab2'
			   target='_blank' rel='noreferrer'>
				Help Articles
			</a>
			<Link to={RoutesMap.esgiAgreement} target='_blank'>
				Terms of Use
			</Link>
			<Link to={RoutesMap.privacyPolicy} target='_blank'>
				Privacy Policy
			</Link>
		</div>
		<div className='copyright'>
			© Copyright - ESGI, LLC
		</div>
		{contactUs && <ContactUs onClose={() => setContactUs(false)} ticketSubmitted={() => setContactUs(false)}/>}
	</div>;
}
