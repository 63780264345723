import {TestType} from '@esgi/main/libs/core';
import {Student, SubjectTab} from '@esgi/main/libs/store';
import {Test} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/storage/subjects/test';
import {TestContentArea} from '@esgi/main/kits/common';
import {ComponentProps} from '@stitches/react';
import {Cheap} from '@esgi/ui';

export enum GroupingType {
	Tests = 'Tests',
	Students = 'Students',
}

export type PermittedData<T = unknown> = T & {permitted: boolean};

export type AllTabListVariant = {
	subjects: SubjectTab[];
};

export type TabItem<TabId extends string = string> = PermittedData<{
	value: TabId;
	label: string;
	valueDescription: string;
	applyDisabledStyles: boolean;
	dataCy?: string;
}> &
	(
		| {
		contentType: 'text';
	}
		| {
		contentType: 'cheap';
		color: NonNullable<ComponentProps<typeof Cheap>['color']>;
	}
		);

export interface StudentListDataItem {
	id: number;
	firstName: string;
	lastName: string;
	notTested: number;
	photoUrl: string;
	hasCredentials: boolean;
	gradeLevelID: number;
	tests: TestItem[];
	languageID: number;
}

export interface TestListDataItem {
	id: number;
	name: string;
	type: TestType;
	maxScore: number;
	contentArea: TestContentArea;
	notTested: number;
	students: StudentItem[];
}

export type StudentItem = Student & TestResults;

export type TestItem = Test & TestResults

export interface TestResults {
	correct: number;
	incorrect: number;
	untested: number;
	maxScore: number;
}

export interface Sort {
	field: 'id' | 'name';
	direction: 'asc' | 'desc';
}

export interface TestListProps {
	tests: TestItem[],
	onSort: VoidFunction;
}
