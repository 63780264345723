import {useUser} from '@esgi/core/authentication';
import {useMemo} from 'react';

export function useKeyboardShortcutsDescription() {
	const currentUser = useUser();

	return useMemo(
		() =>
			`Use the keyboard instead of the mouse to select ${currentUser?.testResultsCorrectVerbiage ?? 'correct'} or ${
				currentUser?.testResultsIncorrectVerbiage ?? 'incorrect'
			} answers on a test screen.`,
		[currentUser],
	);
}
