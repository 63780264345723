import {SpecialistHierarchyFilter} from '../../core/models/specialist-hierarchy-filter';
import React from 'react';
import {EventBusDispatcher} from '@esgillc/events';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, ISpecialistGroupBox, IStudentBox} from '../../core/api';
import {HierarchyStudentAdd, HierarchyStudentEdit} from '../../core/events';
import {BoxType} from '../../core/models';
import {StudentSorter} from 'global/utils/StudentSorter';
import {StudentEditIcon} from 'modules/hierarchy/components/icons/student-edit-icon';
import {getUser, UserType} from '@esgi/core/authentication';

export class State {
}

export class Props {
	boxes: IBoxInfo[];
	students: IStudentBox;
	specialGroups: ISpecialistGroupBox;
	userID: number;
	userType: UserType;
	specialistGroupID: number;
	filter: SpecialistHierarchyFilter;
	selectedID: number;
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class Student extends React.Component<Props, State> {

	get items(): ItemProps[] {
		let groupIDs: number[] = [];
		if (this.props.specialistGroupID) {
			groupIDs = [this.props.specialistGroupID];
		} else if (this.props.userID) {
			groupIDs = this.props.specialGroups.items.filter(t => t.userID === this.props.userID)
				.map(r => r.groupID);
		} else if (this.props.userType !== UserType.V) {
			groupIDs = this.props.specialGroups.items.filter(t => t.type === this.props.userType)
				.map(r => r.groupID);
		}

		let students = this.props.students.items
			.filter(t => t.specialistGroups.some(x => groupIDs.some(a => a === x)));

		if (this.props.filter.schoolID) {
			students = students.filter(t => t.schoolID === this.props.filter.schoolID);
		}

		if (this.props.filter.teacherID) {
			students = students.filter(t => t.teacherID === this.props.filter.teacherID);
		}

		const sortedStudents = StudentSorter.sortStudents(students, this.props.students.sortBy);

		return [...sortedStudents]
			.map(r => {
				return {
					title: `${r.firstName} ${r.lastName}`,
					id: r.studentID,
				};
			});
	}

	render(): any {
		if (this.props.userType === UserType.V || !this.props.userID) {
			return null;
		}
		const canView = getUser().canViewStudents;
		const options: BoxOptions = {
			canAdd: canView && this.props.students.canCreate,
			canEdit: canView && this.props.students.canEdit,
			canView,
			canCreateTooltip: null,
			title: 'All Students',
			canDrag: false,
			boxType: BoxType.SpecialistStudent,
			editIcon: <StudentEditIcon/>,
		};

		return <Box
			open={this.props.boxes.filter(t => t.boxType === BoxType.SpecialistStudent && t.open).length > 0}
			scheduledIDs={[]}
			selectedID={this.props.selectedID}
			items={this.items}
			empty={{message: 'You have 0 students'}}
			onDragStart={null}
			onDragEnd={null}
			options={options}
			itemSelected={this.props.onSelected}
			onAddClicked={this.publish.add}
			onEditClicked={this.publish.edit}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
		/>;

	}

	publish = {
		add: () => {
			const event = new HierarchyStudentAdd({
				userType: this.props.userType,
				userID: this.props.userID,
				classID: null,
				groupID: null,
				schoolID: this.props.filter.schoolID,
				specialistGroupID: this.props.specialistGroupID,
			});
			EventBusDispatcher.dispatch(HierarchyStudentAdd, event);
		},
		edit: (id: number) => {
			const student = this.props.students.items.find(s => s.studentID === id);
			if (!student) {
				return;
			}

			const event = new HierarchyStudentEdit({
				studentID: id,
				userID: this.props.userID,
				userType: this.props.userType,
			});
			EventBusDispatcher.dispatch(HierarchyStudentEdit, event);
		},
	};

}
