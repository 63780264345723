import * as React from 'react';
import type {SVGProps} from 'react';

export function NewFolder(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M10 7a3 3 0 0 0-3 3v11a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4v-9a1 1 0 0 0-1-1h-5c-.755 0-.998-.245-1.594-1.438C16.503 7.755 15.745 7 14 7h-4Zm0 2h4c.755 0 .998.245 1.594 1.438.13.261.184.344.312.562h-3.937a1 1 0 1 0 0 2H23v8a2 2 0 0 1-2 2H11a2 2 0 0 1-2-2V10a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M15.002 20a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
