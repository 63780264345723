import {Dispatch, useCallback} from 'react';
import {EmptyContentTemplate, ExpandablePanelModeVariant} from '@esgi/main/features/teacher/home';
import {Folder} from '@esgi/ui/icons';
import {List} from '../list';
import {useClassList} from './hooks';
import {Class, Student} from '@esgi/main/libs/store';
import {isUndefined} from '@esgi/ui';

type Props = {
	classes: Class[];
	students: Student[];
	selectedStudentsIDs: Student['id'][];
	setSelectedStudentIDs: Dispatch<Student['id'][]>;
	toggleStudent: Dispatch<Student['id']>;
};

export function ClassList({classes, students, selectedStudentsIDs, toggleStudent, setSelectedStudentIDs}: Props) {
	const {state, onToggle, onSelectAll} = useClassList({classes, students});
	const isListSelected = useCallback(
		(classId: Class['id']) => {
			const checkedClass = classes.find(({id}) => id === classId);

			if (isUndefined(checkedClass)) {
				return false;
			}

			const {studentIDs} = checkedClass;

			return Boolean(studentIDs.length) && studentIDs.every((id) => selectedStudentsIDs.includes(id));
		},
		[classes, selectedStudentsIDs],
	);

	return (
		<ExpandablePanelModeVariant withBottomSectionButton={false}>
			{state.length ? (
				state.map(({id, name, students, isListOpen}) => {
					const isAllStudentsSelected = isListSelected(id);

					return (
						<List.ExpandableSelectableListMultiple
							key={id}
							IconBefore={Folder}
							triggerLabel={name}
							isListOpen={isListOpen}
							onTriggerButtonClick={() => onToggle(!isListOpen, id)}
							selectedStudentId={selectedStudentsIDs}
							students={students}
							setSelectedStudentId={toggleStudent}
							onStyledButtonClick={() =>
								setSelectedStudentIDs(
									onSelectAll({
										classId: id,
										selectedStudentIds: selectedStudentsIDs,
										isListSelected: isAllStudentsSelected,
									}),
								)
							}
							isListSelected={isAllStudentsSelected}
						/>
					);
				})
			) : (
				<EmptyContentTemplate size='full' templateText='No classes to display' />
			)}
		</ExpandablePanelModeVariant>
	);
}
