import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useCallback} from 'react';

type Props = {
	onProceed: VoidFunction;
	onClose: VoidFunction;
};

export function PotentialDataImpactAlert({onClose, onProceed}: Props) {
	const confirmationAlert = Alert.useRef();
	const closeAlert = Alert.useClose(confirmationAlert, onClose);

	const closeAndProceed = useCallback(() => {
		onProceed();
		closeAlert();
	}, [closeAlert, onProceed]);

	return (
		<Alert
			modalManagerRef={confirmationAlert}
			css={{
				'& [data-alert-content]': {
					maxWidth: 508,
				},
			}}
		>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Attention: Potential Data Impact
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox gap='2'>
					<Text size='medium' color='neutral40'>
						Please note that changing the marking period can affect report data!
					</Text>
					<Text size='medium' color='neutral40'>
						For example, if the Marking Period is changed from trimesters to quarters, the data that originally
						displayed in 3rd trimester may now display in the 4th quarter.
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAndProceed}>
						<Text size='medium' bold color='base'>
							Proceed
						</Text>
					</Button>
					<Button color='secondary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
