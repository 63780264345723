import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import {ComponentPropsWithoutRef, forwardRef, ReactNode, useMemo} from 'react';
import {CheckboxGroupContext, CheckboxGroupContextValue} from '../../context';

export type GroupCheckboxRootProps = Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> & {
	/** Checked items names array */
	checked: (string | number)[],

	/** Change with checked items names array */
	onChange: (value: (string | number)[]) => void,

	/** Checkbox is disabled */
	disabled?: boolean

	/** ReactNode or function with context argument that returns ReactNode */
	children?: ((context: CheckboxGroupContextValue) => ReactNode) | ReactNode
} & Skeletonable & BaseComponentProps;

export const GroupCheckboxRoot = forwardRef<HTMLDivElement, GroupCheckboxRootProps>(({
	checked,
	onChange,
	disabled = false,
	children,
	dataCy = 'ui-kit-checkbox-group-root',
	...props
}: GroupCheckboxRootProps, ref) => {
	const context = useMemo<CheckboxGroupContextValue>(
		() => ({
			checked,
			onChange,
			disabled,
		}),
		[checked, onChange, disabled],
	);

	return <CheckboxGroupContext.Provider value={context}>
		<Box ref={ref} data-cy={dataCy} {...props}>
			{typeof children === 'function'
				? children(context)
				: children}
		</Box>
	</CheckboxGroupContext.Provider>;
});
