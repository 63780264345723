import {Buttons} from '@esgillc/ui-kit/button';
import {Alert, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {routes} from '@esgi/main/libs/core';
import {userStorage} from '@esgi/core/authentication';
import {StudentManagerLink} from './index.styled';
import styles from './styles.module.less';

interface Props {
	onClose: () => void,
	onSave: () => void,
	onCancel: () => void
	name?: string
}

export function SaveDuplicateConfirmModal({onClose, onSave, onCancel, name}: Props){
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const navigate = useNavigate();
	const location = useLocation();

	const canExploreStudents = useMemo(() => {
		return userStorage.get().canExploreStudents;
	}, []);
	
	const isStudentManagerPage = useMemo(() => {
		return location.pathname === routes.teacher.studentManager;
	}, [location]);

	const message = useMemo(() => {
		return canExploreStudents && !isStudentManagerPage
			? <p className={styles.text}> {name} matches a student that already exists in ESGI. Please go to 
					<StudentManagerLink href={routes.teacher.studentManager}>
						Student Manager
					</StudentManagerLink>
				to view this student if you do not wish to create a duplicate.</p>
			: <p className={styles.text}>{name} matches a student that already exists in ESGI. Would you like to save anyway?</p>;
	}, [canExploreStudents, name, navigate, isStudentManagerPage]);

	return <Alert data-cy='save-duplicate-confirm-modal' modalManagerRef={modalRef} className={styles.confirmDuplicateModal}>
		<Alert.Header className={styles.header}>
			<Title className={styles.title}>Save a duplicate?</Title>
			{message}
		</Alert.Header>
		<Alert.Footer className={styles.footer}>
			<div data-cy='footer-container'>
			<Buttons.Text onClick={() => {
				onCancel();
				handleClose();
			}
			}>Cancel</Buttons.Text>
			<Buttons.Text onClick={() => {
				onSave();
				handleClose();
			}}>SAVE ANYWAY</Buttons.Text>
			</div>
		</Alert.Footer>
	</Alert>;
}
