import * as React from 'react';
import type {SVGProps} from 'react';

export function Timeline(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M16 9a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm0 1.4a5.6 5.6 0 1 1 0 11.2 5.6 5.6 0 0 1 0-11.2Z'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M15.99 11.8a.7.7 0 0 1 .7.7v3.21l1.895 1.896a.7.7 0 0 1-.99.99l-2.1-2.1A.7.7 0 0 1 15.29 16v-3.5a.7.7 0 0 1 .7-.7Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
