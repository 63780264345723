import Selectors from '@esgi/testing/selectors';
import React, {ReactNode} from 'react';

class Props {
	className?: string;
	children?: ReactNode;
}

/**
 * @deprecated Use UI-Kit/Modal implementation.
 */
export class ModalBody extends React.PureComponent<Props> {
	private get className() {
		let className = 'modal-body';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}
	render() {
		return <div className={this.className} id='modal-inner-control' data-cy={Selectors.UIKit.Modal.Body}>
			<div className='modal-body-inner'>{this.props.children}</div>
		</div>;
	}
}
