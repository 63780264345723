import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportSettingsService} from '../../../../../../services/report-settings-service';
import {Radio} from '@esgillc/ui-kit/control';
import styles from '../../../settings/styles.module.less';

interface Props {
	service: ReportSettingsService;
}

export function DetailReportSettingsMarkingPeriods({service}: Props): JSX.Element {
	const displayZeroIfNotTested = useBehaviorSubject(service.displayZeroIfNotTested);

	return (
		<div className={join(styles.settingsCell, styles.flexColumn)}>
			<span className={styles.title}>Display not tested as:</span>
			<div className={styles.radioGroup}>
				<Radio
					name='displayZeroIfNotTestedOptions' value='Zero'
					checked={displayZeroIfNotTested}
					onChange={() => service.displayZeroIfNotTested.next(true)}
				>
					Zero
				</Radio>
				<Radio
					name='displayZeroIfNotTestedOptions' value='NT'
					checked={!displayZeroIfNotTested}
					onChange={() => service.displayZeroIfNotTested.next(false)}
				>
					NT
				</Radio>
			</div>
		</div>
	);
}
