import {BaseService} from '@esgi/core/service';
import {UserType, getUser} from '@esgi/core/authentication';

export class StudentCardsService extends BaseService {
	private readonly controller = 'assignments/student-cards';

	private getDate() {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		return `${year}-${month}-${day}`;
	}
	private getUserName() {
		const {firstName, lastName} = getUser();
		return `${firstName.replace(' ', '')}-${lastName.replace(' ', '')}`;
	}

	public all() {
		const {userType} = getUser();

		const fileName = `${this.getUserName()}_student-credentials_${this.getDate()}.pdf`;

		if(userType === UserType.T) {
			return this.httpClient.ESGIApi.file(this.controller, 'teacher/all', fileName);
		}

		if(userType === UserType.ISD || userType === UserType.ISS) {
			return this.httpClient.ESGIApi.file(this.controller, 'specialist/all', fileName);
		}

		throw new Error('<StudentCardsService />: Current user is not teacher or specialist');
	}

	public byAssignment(assignmentID: number) {
		const {firstName, lastName} = getUser();
		return this.httpClient.ESGIApi.file(this.controller, 'by-assignment', `${this.getUserName()}_student-credentials_${this.getDate()}.pdf`, {assignmentID});
	}
}