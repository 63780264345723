import './styles.less';
import {TestFavoriteChangedEvent, TestHiddenChangedEvent} from 'modules/assets/tests/kits/test-details';
import {RubricChangedEvent} from 'modules/assets/tests/rubric/details/events';
import React from 'react';
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {TestType} from '@esgi/core/enums';
import {Loader} from '@esgi/deprecated/jquery';
import {debounce} from '@esgi/deprecated/knockout';
import {EventBusManager} from '@esgillc/events';
import {DataService} from '../../services/data-service';
import {TestModel} from '../../services/search-service';
import {SubjectItem} from '../../services/subjects-service';
import SearchResultCard from './card/card';
import RubricCard from './card/rubric/rubric-card';
import {EmptyResults} from './empty-results';

class State {
	pageIndex = 1;
	cardsInfo: TestModel[] = [];
	loading: boolean = false;
	totalResults: number = 0;
	firstLoad: boolean = true;
	mySubjects: SubjectItem[] = [];
	isLoading: boolean = false;
}

class Props {
	dataService: DataService;
}

export default class SearchResults extends React.Component<Props, State> {
	public state = new State();

	private readonly eventBus = new EventBusManager();
	private readonly onDestroy$: Subject<void> = new Subject();
	private panel: HTMLDivElement;

	private canLoadMore: boolean = true;
	private rootElement: HTMLDivElement;
	private loader: Loader;

	componentDidMount(): void {
		this.props.dataService.subjects.isLoading.subscribe((isLoading) => {
			this.setState({isLoading});
		});
		const {search, subjects} = this.props.dataService;
		search.onChanged$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(value => {
				if (value.pageIndex === 1) {
					this.panel.scrollTop = 0;
				}

				this.canLoadMore = value.canLoadMore;

				this.setState({
					firstLoad: value.firstLoad,
					cardsInfo: value.tests,
					totalResults: value.totalResults,
				});
			});

		combineLatest(subjects.selfSubjects$, subjects.subjectInfos$)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(value => {
				const [selfSubjects, subjectInfos] = value;
				const hiddenSubjects = subjectInfos.filter(s => s.hidden).map(s => s.id);
				this.setState({mySubjects: selfSubjects.filter(s => !hiddenSubjects.includes(s.id))});
			});

		search.onLoadingStatusChanged$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(processing => {
				if (processing) {
					this.mask();
				} else {
					this.unmask();
				}
				this.setState({loading: processing});
			});

		let panel = document.getElementsByClassName('te-main-center')[0] as HTMLDivElement;
		if (panel) {
			panel.addEventListener('scroll', this.contentScrolled);
			this.panel = panel;
		}

		this.eventBus.subscribe(RubricChangedEvent, (args) => {
			const rubricModel = args.rubricModel;
			this.updateTestInfo(args.rubricModel.id, {
				name: rubricModel.name,
				description: rubricModel.description,
				stateStandard: rubricModel.stateStandardName,
				gradeLevels: rubricModel.gradeLevelIDs,
				contentArea: args.contentAreaName,
			});
		});

		this.eventBus.subscribe(TestFavoriteChangedEvent, (args) => {
			this.updateTestInfo(args.testID, {starred: args.isFavorite});
		});

		this.eventBus.subscribe(TestHiddenChangedEvent, (args) => {
			this.updateTestInfo(args.testID, {hidden: args.isHidden});
			this.testWasHidden(this.state.cardsInfo.find(c => c.testID === args.testID));
		});

		this.loader = new Loader('#app', {whiteBackground: true});
	}

	componentWillUnmount(): void {
		this.eventBus.destroy();
		this.onDestroy$.next();
		if (this.panel) {
			this.panel.removeEventListener('scroll', this.contentScrolled);
		}
	}

	private mask() {
		this.loader.mask();
	}

	private unmask() {
		this.loader.unmask();
	}

	contentScrolled = () => {
		$('.te-main-center').addClass('scrolling');
		this.contentScrolledThrottled();
	};

	@debounce(300)
	contentScrolledThrottled() {
		if (this.panel.scrollTop + 500 > this.panel.scrollHeight - this.panel.offsetHeight) {
			if (!this.state.loading) {
				this.loadMore();
			}
		}

		$('.te-main-center').removeClass('scrolling');
	}

	loadMore() {
		if (!this.canLoadMore) {
			return;
		}
		const nextPageIndex = this.props.dataService.search.currentValue.pageIndex + 1;
		this.setState({pageIndex: nextPageIndex}, () => {
			this.props.dataService.search.updatePageIndex(nextPageIndex);
		});
	}

	render(): JSX.Element | false | null {
		return <div className='search-results' ref={r => this.rootElement = r}>
			{this.state.cardsInfo.map(t => {
				switch (TestType[t.type]) {
					case TestType.YN:
					case TestType.Score:
						return this.renderCommonCard(t);
					case TestType.Rubric:
						return this.renderRubricCard(t);
				}
			})}
			{!this.state.firstLoad && this.state.cardsInfo.length === 0 &&
				<EmptyResults scope={this.props.dataService.filter.currentValue.scope}/>}
			{this.state.loading && this.state.pageIndex > 1 &&
				<div className='loader'><i className='fa fa-circle-o-notch fa-spin '/></div>}
		</div>;
	}

	private testWasUndrafted(t: TestModel) {
		this.removeTestFromList(t.testID);
	}

	private testWasHidden(t: TestModel) {
		if (!this.props.dataService.filter.currentValue.showHidden) {
			let tests = this.state.cardsInfo.filter(r => r.testID !== t.testID);
			this.setState({cardsInfo: tests, totalResults: this.state.totalResults - 1});
		}
	}

	private removeTestFromList(testId: number) {
		let tests = this.state.cardsInfo.filter(r => r.testID !== testId);
		this.setState({cardsInfo: tests, totalResults: this.state.totalResults - 1});
	}

	private renderCommonCard(t: TestModel) {
		return <SearchResultCard mySubjects={this.state.mySubjects}
								 showHidden={this.props.dataService.filter.currentValue.showHidden}
								 hidden={() => this.testWasHidden(t)}
								 key={t.testID}
								 initModel={t}
								 undrafted={() => this.testWasUndrafted(t)}
								 isLoading={this.state.isLoading}
		/>;
	}

	private renderRubricCard(t: TestModel) {
		return <RubricCard key={t.testID} testModel={t} subjects={this.state.mySubjects}/>;
	}

	private updateTestInfo(testID: number, info: Partial<Omit<TestModel, 'testID'>>): void {
		let tests = this.state.cardsInfo.map(test => test.testID === testID ? {...test, ...info} : test);
		this.setState({cardsInfo: [...tests]});
	}
}
