import {MutableRefObject, useRef} from 'react';
import {HierarchySnapshot} from '../../hierarchy/models';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportSettings} from './components/report-settings';
import {ReportMainContainer} from './components/report-main-container';
import {UntestedStudentsReportService} from './service';
import styles from './components/modal/modal.module.less';

interface Props {
	hierarchy: HierarchySnapshot;
	service: UntestedStudentsReportService;
	rootElement: MutableRefObject<HTMLDivElement>;
	onClose: () => void;
}

export function UntestedStudentsReport({service, rootElement, onClose, hierarchy}: Props) {
	const untestedStudentsReportRef = useRef(null);
	const emptyReport = useBehaviorSubject(service.emptyReport);

	return (
		<div
			className={styles.untestedStudentsReport}
			ref={untestedStudentsReportRef}
		>
			<ReportSettings service={service}/>
			<ReportMainContainer
				service={service}
				hierarchy={hierarchy}
				rootElement={rootElement}
				untestedStudentsReportRef={untestedStudentsReportRef}
				onClose={onClose}
			/>
			{emptyReport &&
				<div className={styles.emptyTextContainer}>
					<span className={styles.emptyReportText}>
						There are no students in this group or class
					</span>
				</div>
			}
		</div>
	);
}
