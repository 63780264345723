import React, {Component} from 'react';
import {isTouchDevice} from '@esgillc/ui-kit/utils';
import styles from './start-button.module.less';

interface Props {
	click: () => void;
}

export default class StartButton extends Component<Props> {
	private onClick(): void {
		setTimeout(() => this.props.click(), 300);
	}

	render() {
		return (
			<button onTouchStart={() => isTouchDevice() && this.onClick()} className={styles.start} type='button' onClick={() => this.onClick()}>
				<svg width='85' height='86' viewBox='0 0 85 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g filter='url(#filter0_ddd_2_135)'>
				<circle cx='42.5' cy='41.5' r='37.5' fill='#2FB454'/>
				<circle cx='42.5' cy='41.5' r='36' stroke='white' strokeWidth='3'/>
				</g>
				<path d='M35 55.3426C35 58.0031 38.2064 59.3471 40.1034 57.4816L55.3247 42.5141C56.5201 41.3386 56.5201 39.4114 55.3247 38.2359L40.1034 23.2684C38.2064 21.4029 35 22.7469 35 25.4074V55.3426Z' fill='white'/>
				<defs>
				<filter id='filter0_ddd_2_135' x='0' y='0' width='85' height='86' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
				<feFlood floodOpacity='0' result='BackgroundImageFix'/>
				<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
				<feOffset dy='1'/>
				<feGaussianBlur stdDeviation='2.5'/>
				<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
				<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2_135'/>
				<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
				<feOffset dy='3'/>
				<feGaussianBlur stdDeviation='2'/>
				<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/>
				<feBlend mode='normal' in2='effect1_dropShadow_2_135' result='effect2_dropShadow_2_135'/>
				<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
				<feOffset dy='2'/>
				<feGaussianBlur stdDeviation='2'/>
				<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/>
				<feBlend mode='normal' in2='effect2_dropShadow_2_135' result='effect3_dropShadow_2_135'/>
				<feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_2_135' result='shape'/>
				</filter>
				</defs>
				</svg>
			</button>
		);
	}
}