import {Card} from '@esgi/ui/layout';
import {History, More} from '@esgi/ui/icons';
import {TestSingleStudentModel} from '../../../../../../../../types';
import {CardHeader} from '../../components/card-header';
import {Button} from '@esgi/ui';
import {CardBody} from './components/card-body';
import {CardFooter} from './components/card-footer';
import {ActionButtonsDivider, CardRoot} from '../../components/components.styled';

type Props = Pick<TestSingleStudentModel, 'testInfo' | 'studentResult'> & {
	showSelfAssessOption: boolean;
};

export function DraggableCard({testInfo, studentResult, showSelfAssessOption}: Props) {
	return (
		<CardRoot>
			<CardHeader testID={testInfo.id} testName={testInfo.name} testType={testInfo.type} standards={testInfo.stateStandards}/>

			<Card.Separator />

			<CardBody studentResult={studentResult} testInfo={testInfo} />

			<Card.Separator />

			<CardFooter
				disableActionButtons
				showSelfAssessOption={showSelfAssessOption}
				selfAssessmentTestAllowed={testInfo.selfAssessmentTestAllowed}
			>
				<Button.Icon disabled>
					<More />
				</Button.Icon>

				<ActionButtonsDivider />

				<Button.Icon disabled>
					<History />
				</Button.Icon>
			</CardFooter>
		</CardRoot>
	);
}
