import {Timeline} from '@esgi/ui/icons';
import {IconWrapper} from './index.styled';

type Props = {
	inputDisabled?: boolean;
	inputHovered: boolean;
};

export function TimelineIcon({inputDisabled, inputHovered}: Props) {
	return (
		<IconWrapper data-disabled={Boolean(inputDisabled)} data-hovered={inputHovered}>
			<Timeline />
		</IconWrapper>
	);
}
