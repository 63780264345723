import {useEffect} from 'react';
import {MarkingPeriod, ChangeFunction} from '../types';
import {type Option, ToggleGroup} from '../toggle-group';
import {SettingsPanel} from '../settings-panel';

interface Props {
	disabled?: boolean;
	value?: MarkingPeriod;
	onChange: ChangeFunction<MarkingPeriod>;
}

const periods: Option[] = [
	{id: MarkingPeriod.Current, label: 'Current'},
	{id: MarkingPeriod.All, label: 'All'},
];

export function MarkingPeriodControl(props: Props) {
	const {disabled, value, onChange} = props;

	useEffect(() => {
		if (typeof value === 'undefined') {
			onChange(MarkingPeriod.All);
		}
	}, [value, onChange]);

	return (
		<SettingsPanel title='Marking Period'>
			<ToggleGroup
				value={value}
				options={periods}
				disabled={disabled}
				onChange={(value) => onChange(value as MarkingPeriod)}
				dataCy='marking-period-toggle-group'
			/>
		</SettingsPanel>
	);
}
