import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CheckboxesBox = styled(Box, {
	display: 'inline-grid',
	padding: 8,
	gap: 4,
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',
});
