import type {SVGProps} from 'react';

export function StudentCard(props: SVGProps<SVGSVGElement>): JSX.Element {
	return <svg width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M11.5556 7.81818H20.4444C21.4263 7.81818 22.2222 8.63221 22.2222 9.63636V22.3636C22.2222 23.3678 21.4263 24.1818 20.4444 24.1818H11.5556C10.5737 24.1818 9.77778 23.3678 9.77778 22.3636V9.63636C9.77778 8.63221 10.5737 7.81818 11.5556 7.81818ZM8 9.63636C8 7.62805 9.59188 6 11.5556 6H20.4444C22.4081 6 24 7.62805 24 9.63636V22.3636C24 24.3719 22.4081 26 20.4444 26H11.5556C9.59188 26 8 24.3719 8 22.3636V9.63636ZM13.3333 9.63636C12.8424 9.63636 12.4444 10.0434 12.4444 10.5455C12.4444 11.0475 12.8424 11.4545 13.3333 11.4545H18.6667C19.1576 11.4545 19.5556 11.0475 19.5556 10.5455C19.5556 10.0434 19.1576 9.63636 18.6667 9.63636H13.3333Z'
		      fill='#333333'/>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M18.6667 17.3636C18.6667 18.87 17.4729 20.0909 16 20.0909C14.5271 20.0909 13.3333 18.87 13.3333 17.3636C13.3333 15.8573 14.5271 14.6364 16 14.6364C17.4729 14.6364 18.6667 15.8573 18.6667 17.3636ZM16.8889 17.3636C16.8889 16.8618 16.4907 16.4545 16 16.4545C15.5093 16.4545 15.1111 16.8618 15.1111 17.3636C15.1111 17.8655 15.5093 18.2727 16 18.2727C16.4907 18.2727 16.8889 17.8655 16.8889 17.3636ZM16.9929 21.4545C18.6667 21.4545 20.4081 22.345 20.8747 24.1258C20.7369 24.1632 20.5928 24.1818 20.4444 24.1818L19.0043 24.1804C18.4925 23.6198 17.7576 23.2727 16.9929 23.2727H15.0072C14.2425 23.2727 13.5076 23.6198 12.9957 24.1803L11.5556 24.1818C11.4074 24.1818 11.2636 24.1633 11.126 24.1284L11.1111 24.1818C11.5556 22.3636 13.3159 21.4545 15.0072 21.4545H16.9929Z'
		      fill='#333333'/>
		<path
			d='M12.4444 10.5455C12.4444 10.0434 12.8424 9.63636 13.3333 9.63636H18.6667C19.1576 9.63636 19.5556 10.0434 19.5556 10.5455C19.5556 11.0475 19.1576 11.4545 18.6667 11.4545H13.3333C12.8424 11.4545 12.4444 11.0475 12.4444 10.5455Z'
			fill='#333333'/>
	</svg>;
}