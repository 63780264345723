import {Student} from '@esgi/main/libs/store';
import {SelectableList} from './list-variant/selectable-list';
import {Dispatch} from 'react';
import {EmptyContentTemplate} from '@esgi/main/features/teacher/home';

type Props = {
	students: Student[];
	selectedStudentsIDs: Student['id'][];
	onStudentClick: Dispatch<Student['id']>;
};

export function StudentsContent({students, onStudentClick, selectedStudentsIDs}: Props) {
	if (!students.length) {
		return <EmptyContentTemplate size='full' templateText='No students to display' />;
	}

	return (
		<SelectableList onStudentClick={onStudentClick} students={students} selectedStudentsIDs={selectedStudentsIDs} />
	);
}
