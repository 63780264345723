import './searchable-dropdown.less';
import React from 'react';
import {IFormControlValidatorResult} from './form-controls';

class State {
	showDropdown: boolean;
	filterText: string;
	values: InModel[];
}

class Props {
	label?: string;
	values: InModel[];
	value: InModel;
	touched?: boolean;
	validator?: (value: any) => IFormControlValidatorResult;
	onClick: (value: InModel, valid: boolean) => void;
	actionButton?: () => any;
	className?: string;
}

export class InModel {
	id: number;
	value: string;
}
/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class SearchableDropdown extends React.PureComponent<Props, State> {

	constructor(props, context) {
		super(props, context);
		this.state = {showDropdown: false, filterText: '', values: this.props.values};
		this.textInput = React.createRef();
	}

	textInput: any;
	content: HTMLElement;

	componentDidMount() {
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	handleClickOutside(event) {
		if (this.content && !this.content.contains(event.target)) {
			if (this.state.showDropdown) {
				this.toggleDropdown();
			}
		}
	}

	toggleDropdown = () => {

		this.setState({showDropdown: !this.state.showDropdown}, () => {
			if (this.state.showDropdown) {
				this.textInput.current.focus();
			}
		});
	}

	filterOnChange = (event) => {
		let value = event.target.value;
		this.setState({filterText: value});
		this.filterValues(value);
	}

	filterValues = (value: string) => {
		value = value.toLowerCase();
		let values = [...this.props.values];
		if (value) {
			values = values.filter(x => x.value.toLowerCase().indexOf(value) != -1);
		}
		this.setState({values: values});
	}

	handleSelectOnChange(item: InModel) {
		this.props.onClick(item, this.props.validator ? this.props.validator(item.value).valid : true);
		this.setState({showDropdown: false});
	}

	renderDropdownIcon() {
		return this.state.showDropdown
			? (<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 5.11133L5 -2.86102e-06L10 5.11133H0Z' fill='#828282'/>
			</svg>)
			: (<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 0L5 5.11133L10 0L0 0Z' fill='#828282'/>
			</svg>);
	}

	renderValidatingControl() {
		let validationResult = this.props.validator(this.props.value);
		let className = this.props.className ? ' ' + this.props.className : '';
		return <div
			className={this.props.touched && !validationResult.valid ? 'form-group has-error' + className : 'form-group' + className}
			ref={r => this.content = r}>
			{this.props.label && <label className='top-label'>{this.props.label}</label>}
			{this.props.actionButton && this.props.actionButton()}
			<div className='searchable-dropdown-container'>
				<div className='selected-item'
				     onClick={this.toggleDropdown}>
					<div>{this.props.value.value}</div>
					<div className='value-arrow'>{this.renderDropdownIcon()}</div>
				</div>
				<div className={'items-dropdown' + (this.state.showDropdown ? '' : ' hidden')}>
					<input className='form-control' type='text' placeholder='Search..' value={this.state.filterText}
					       onChange={this.filterOnChange} ref={this.textInput}/>
					{this.state.values.map((item) => {
						return <div key={item.id} onClick={() => {
							this.handleSelectOnChange(item);
						}}>{item.value}</div>;
					})}
				</div>
			</div>
			{this.props.touched && !validationResult.valid ?
				<div className='text-danger'>{validationResult.message}</div> : ''}
		</div>;
	}

	renderNotValidatingControl() {
		let className = this.props.className ? ' ' + this.props.className : '';
		return <div className={'form-group' + className} ref={r => this.content = r}>
			{this.props.label && <label className='top-label'>{this.props.label}</label>}
			{this.props.actionButton && this.props.actionButton()}
			<div className='searchable-dropdown-container'>
				<div
					className='form-control selected-item'
					onClick={this.toggleDropdown}
					tabIndex={0}
				>
					<div>{this.props.value.value}</div>
					<div className='value-arrow'>{this.renderDropdownIcon()}</div>
				</div>
				<div className={'items-dropdown' + (this.state.showDropdown ? '' : ' hidden')}>
					<input className='form-control' type='text' placeholder='Search..' value={this.state.filterText}
					       onChange={this.filterOnChange} ref={this.textInput}/>
					<div>
						{this.state.values.map((item) => {
							return <div key={item.id} onClick={() => {
								this.handleSelectOnChange(item);
							}}>{item.value}</div>;
						})}
					</div>
				</div>
			</div>
		</div>;
	}

	render() {
		return <>
			{this.props.validator ? this.renderValidatingControl() : this.renderNotValidatingControl()}
		</>;
	}
}
