import {unified} from 'unified';
import markdown from 'remark-parse';
import {remarkToSlate} from 'remark-slate-transformer';
import {userStorage} from '@esgi/core/authentication';
import {StudentType, GradeLevelsMap} from '../../../types';
import {
	ReportTypeOption,
	nameLangLabel,
	gradeLangLabel,
	teacherLangLabel,
} from '../../../model';

interface Props {
	text: string;
	student: StudentType;
	selectedReportType: ReportTypeOption;
	lang?: string;
}

const processor = unified()
	.use(markdown)
	.use(remarkToSlate, {
		overrides: {
			list: (node, next) => ({
				type: node.ordered ? 'numbered-list' : 'bulleted-list',
				children: next(node.children),
			}),
			listItem: (node, next) => ({
				type: 'list-item',
				children: next(node.children)[0].children,
			}),
		},
	});

export function deserialize({
	text,
	student,
	selectedReportType,
	lang,
}: Props) {
	const currentUser = userStorage.get();

	// TODO: GPT response tends to not end lists properly
	const cleanedString = text
		.replace(/ {2}/g, '')
		.replace(
			/(\n\**(Standards Need Addressing|Suggested Activities|Standards to Work On|Suggested ESGI Test)\**:)/g,
			'\n$1'
		);

	const currentLang = lang || 'english';

	const editableVoidObject = {
		type: 'editable-void',
		children: [
			{
				type: 'paragraph',
				id: 'name',
				children: [
					{text: `${nameLangLabel[currentLang]}: `, strong: true},
					{text: `${student.firstName} ${student.lastName}`},
				],
			},
		],
	};

	if (selectedReportType.value === 'parent') {
		if (GradeLevelsMap[student.gradeLevelID]) {
			editableVoidObject.children.push({
				type: 'paragraph',
				id: 'grade',
				children: [
					{text: `${gradeLangLabel[currentLang]}: `, strong: true},
					{text: GradeLevelsMap[student.gradeLevelID].shortName},
				],
			});
		}
		editableVoidObject.children.push({
			type: 'paragraph',
			id: 'teacher',
			children: [
				{text: `${teacherLangLabel[currentLang]}: `, strong: true},
				{text: `${currentUser.firstName} ${currentUser.lastName}`},
			],
		});
	}

	return [
		editableVoidObject,
		{
			type: 'block',
			children: processor.processSync(cleanedString).result,
		},
	];
}
