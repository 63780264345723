import React, {Dispatch, ReactNode} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {ReportErrorEvent} from '../error-reporter-layer/events';

class State {
	hasError: boolean = false;
}

/**
 * @property fillSpace - fit the error mask to parent size
 */
class Props {
	className?: string = '';
	message?: string = '';
	fillSpace?: boolean = false;
	onCatch?: Dispatch<Error>;
	children?: ReactNode;
}

export class ErrorOccurredEvent {

}

export class ErrorBoundary extends React.Component<Props, State> {
	private readonly defaultMessage: string = 'Uh oh. Something went wrong with the UI on our end.';

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	public override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		if (!this.state.hasError) {
			this.openAlert();
			this.props.onCatch?.(error);
		}
		this.dispatchEvent();
		this.setState({hasError: true});
	}

	private dispatchEvent() {
		dispatchAppEvent(ErrorOccurredEvent, new ErrorOccurredEvent());
	}

	get message(): string {
		return this.props.message || this.defaultMessage;
	}

	get className(): string {
		let className = 'component-error-mask ';
		if (this.props.fillSpace) {
			className += 'fill-container-mask';
		}
		if (this.props.className) {
			className += this.props.className;
		}

		return className;
	}

	openAlert(): void {
		dispatchAppEvent(ReportErrorEvent, new ReportErrorEvent(this.message));
	}

	public override render() {
		if (this.state.hasError) {
			return <div className={this.className}>Component unavailable now</div>;
		}
		return <>{this.props.children} </>;
	}
}
