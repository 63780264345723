import {useCallback} from 'react';
import {EmptyContentTemplate} from '../../../empty-content-template';
import {ExpandablePanel} from '../../../expandable-panel';
import {Folder} from '@esgi/ui/icons';
import {Box} from '@esgi/ui/layout';
import {useGroupList} from './hooks';
import {WrapperBox, ListsContentWrapper} from './index.styled';
import {List} from '../list';
import {ClassSelect} from './components/class-select';
import {StudentsWithoutGroups} from './components/students-without-groups';
import {ClassType, GroupType, StudentType, ToggleStudentsFunction} from '../../types';
import {StudentWithUnit, UnitType} from '../../../types';

interface Props {
	classes: ClassType[];
	groups: GroupType[];
	students: StudentType[];
	selectedStudents: StudentWithUnit[];
	toggleStudents: ToggleStudentsFunction;
	selected: GroupType['id'][];
	setSelected: (id: GroupType['id']) => void;
}

export function GroupList(props: Props) {
	const {classes, groups, students, selectedStudents, toggleStudents, selected, setSelected} = props;
	const {
		setSelectedClassId,
		studentsWithoutGroup,
		groupsWithStudents,
		onToggle,
		onSelectAll,
	} = useGroupList({students, groups, selected});
	const isDisplayClassesSelect = Boolean(classes.length || groups.length);
	const selectedStudentId = (selectedStudents || []).map(({studentId}) => studentId);
	const isListSelected = useCallback((groupId) => {
		const {studentIDs} = groups.find(({id}) => id === groupId);
		return studentIDs.length && studentIDs.every((id) => selectedStudentId.includes(id));
	}, [groups, selectedStudentId]);

	return (
		<WrapperBox>
			{isDisplayClassesSelect &&
				<ClassSelect
					classes={classes}
					onChange={setSelectedClassId}
				/>
			}

			<ExpandablePanel>
				<ListsContentWrapper>
					<Box>
						{groupsWithStudents.length ? (
							groupsWithStudents.map(({id, name, isListOpen, students}) => (
								<List.ExpandableSelectableListMultiple
									key={id}
									IconBefore={Folder}
									triggerLabel={name}
									isListOpen={isListOpen}
									onTriggerButtonClick={() => {
										onToggle(!isListOpen, id);
										setSelected(id);
									}}
									students={students}
									selectedStudentId={selectedStudentId}
									setSelectedStudentId={((value) => toggleStudents([value], {id, name, type: UnitType.Group}))}
									onStyledButtonClick={() => toggleStudents(onSelectAll(id, selectedStudentId), {id, name, type: UnitType.Group})}
									isListSelected={isListSelected(id)}
								/>
							))
						) : (
							<EmptyContentTemplate
								size='full'
								templateText='No groups to display'
							/>
						)}
					</Box>

					<StudentsWithoutGroups
						students={studentsWithoutGroup}
						selectedStudents={selectedStudents}
						toggleStudent={(value) => toggleStudents([value])}
					/>
				</ListsContentWrapper>
			</ExpandablePanel>
		</WrapperBox>
	);
}
