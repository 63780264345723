export enum YAxisId {
	YLineChart = 'YLineChart',
	YBarChart = 'YBarChart',
}

export enum XAxisId {
	XBarChart = 'XBarChart',
}

export enum StackBarName {
	AnswerInfo = 'AnswerInfo',
}

export type CartesianGridLineXCoordinates = {
	start: number;
	end: number;
};
