import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {Schools, Teacher} from 'modules/forms/students-form/types';

export type DistrictAdminFormType = FormGroup<{
	groupIDs: FormControl<number[]>,
	classIDs: FormControl<number[]>,
	teacher: FormControl<Teacher[]>,
	school: FormControl<Schools[]>,
},
	ControlValue<{
		groupIDs: FormControl<number[]>,
		classIDs: FormControl<number[]>,
		teacher: FormControl<Teacher[]>,
		school: FormControl<Schools[]>,
	}>>

export const createDistrictAdminForm = ():
	DistrictAdminFormType => new FormGroup({
	groupIDs: new FormControl([]),
	classIDs: new FormControl([]),
	teacher: new FormControl([], {validators: [Validators.required()]}),
	school: new FormControl([], {validators: [Validators.required()]}),
});
