import {Radio} from '@esgillc/ui-kit/form-control';
import {FormElement} from '@esgillc/ui-kit/form';
import {SchoolTab} from '../../../../../../types';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import PublishToTeachers = SchoolTab.PublishToTeachers;
import {SchoolCommonData} from 'modules/subject-details/types';
import styles from '../styles.module.less';

class Props {
	form: SchoolFormType;
	schoolSubjectData: SchoolCommonData;
}

export default function TeachersSubSection({form, schoolSubjectData}: Props) {

	return <div data-cy={'teachers-subsection'} className={styles.subsection}>
		<strong className={styles.title}>Teachers</strong>
		<FormElement control={form.controls.publishToTeacher}>
			<Radio
				value={PublishToTeachers.All}
				id='publish-to-teachers-all-school-teachers'
				className={styles.radio}
			>All School Teachers [{schoolSubjectData.teachersCount}]</Radio>
			<Radio
				value={PublishToTeachers.None}
				id='publish-to-teachers-none'
				className={styles.radio}
			>None</Radio>
		</FormElement>
	</div>;
}

