import {SubjectSectionMode, TabsPermissions} from '@esgi/main/features/teacher/home';
import {SubjectSectionState} from './state-sections-models';
import {SubjectTab} from '@esgi/main/libs/store';

export enum SubjectTabId {
	All = 'all',
	District = 'district',
	School = 'school',
	Personal = 'personal',
}

export type SubjectSectionModeButtonsPermissions = Record<
	SubjectSectionMode.Edit | SubjectSectionMode.Rearrange,
	boolean
>;

export type SubjectSectionModePermissions = Record<SubjectSectionMode, TabsPermissions<SubjectTabId>>;

export type SubjectBelongsTo = keyof Pick<SubjectSectionState, 'district' | 'school' | 'personal'>;

export type SubjectModelExtended = SubjectTab & {
	belongsTo: SubjectBelongsTo;
};

export type SetSelectedSubjectIdParams =
	| {
			subjectId: null;
	  }
	| {
			subjectId: SubjectTab['id'];
			belongsTo: SubjectBelongsTo;
	  };
