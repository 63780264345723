import React, {createRef} from 'react';
import styles from './lock-screen-modal.module.less';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {CloseIcon, Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';

interface Props {
	onClickClose: () => void;
	closeParent: () => void;
}

export default class WithButtonsModal extends React.Component<Props> {
	private modalManagerRef: ModalManagerRefObject = createRef();

	private onClose() {
		this.props.closeParent();
		this.modalManagerRef.current.close(this.props.onClickClose);
	}

	private onClickHelp () {
		window.open('https://support.esgisoftware.com/hc/en-us/articles/209159446-ESGI-Browser-Requirements', '_blank');
	}

	render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Header>
				<Title>Your device resolution does not meet minimum standards for self-assess.</Title>
				<CloseIcon onClick={() => this.onClose()}/>
			</Modal.Header>
			<Modal.Body className={styles.buttonsModal}>
				<span>Students must view assessments at a minimum resolution of 1024 x 768.</span>
			</Modal.Body>
			<Modal.Footer>
				<LinkButton onClick={() => this.onClose()} className={styles.button}>
					CLOSE
				</LinkButton>
				<LinkButton onClick={() => this.onClickHelp()} className={styles.button}>
					LEARN MORE
				</LinkButton>
			</Modal.Footer>
		</Modal>;
	}
}