import {SchoolYear} from './types';

export const transformToShortName = (schoolYears: SchoolYear[]) => {
	return schoolYears?.map(year => {

		const yearRange = year.name.split('-');

		return {
			value: year.globalSchoolYearID.toString(),
			name: `${yearRange[0].slice(2)}-${yearRange[1].slice(2)}`,
		};
	});
};

export const getInitials = (firstName: string, lastName: string) => `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
