import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';

export const Section = styled(FlexBox, {
	flexDirection: 'column',
	padding: '0 29px',

	'& > div': {
		marginTop: '12px',
	},
});

export const Title = styled(Text, {
	padding: '20px 0 12px',
	margin: '0 3px',
	borderBottom: '1px solid $mild',
	textAlign: 'center',
	display: 'block',
});

export const SelectField = styled(Select.Field, {
	minWidth: 100,
	maxHeight: 40,

	'& [data-placeholder-content]': {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},

	'& [data-value-content]': {
		display: 'grid',
		minHeight: 20,
		alignItems: 'center',
		justifyContent: 'start',

		'> *:not([data-placeholder-value])': {
			display: 'none',
		},

		[`& > ${Text}`]: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
});

export const SelectOption = styled(Select.Option, {
	'& [data-placeholder-value]': {
		display: 'none',
	},
});
