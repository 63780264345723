import {createContext, useContext} from 'react';
import {useService} from '@esgi/core/service';
import {ParentLetterService} from '../services';

export const useParentLetter = () => {
	const service = useService(ParentLetterService);

	return {service};
};

export const ParentLetterContext = createContext(null);
export const useParentLetterService = () => useContext<ParentLetterService>(ParentLetterContext);
