import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {HierarchyMode} from '../core/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {HierarchyItems, LocalSnapshot} from './types';
import {applyClassic, applyPreAssess, applySpecialist} from './utils';

export class StorageService extends BaseService {
	private readonly currentUserID: number = userStorage.get()?.userID;
	private readonly storageKey: string = 'esgi_hierarchy';

	private get localSnapshot(): HierarchySnapshot {
		const storageValue = sessionStorage.getItem(this.storageKey);
		if (storageValue) {
			try {
				const parsed = JSON.parse(storageValue) as LocalSnapshot;
				if (parsed && 'userID' in parsed) {
					return parsed.userID === this.currentUserID ? parsed : undefined;
				}
			} catch (e) {
				return undefined;
			}
		}

		return undefined;
	}

	private set localSnapshot(value: HierarchySnapshot) {
		const storageValue = {...value, userID: this.currentUserID};
		sessionStorage.setItem(this.storageKey, JSON.stringify(storageValue));
	}

	public storeState(hierarchySnapshot: HierarchySnapshot): void {
		this.localSnapshot = hierarchySnapshot;
	}

	public applyStateFromStorage(targetHierarchy: HierarchySnapshot, items: HierarchyItems): void {
		const localVersion = this.localSnapshot;
		if (localVersion) {
			const {classic, specialist, preAssess} = targetHierarchy;
			applyClassic(classic, localVersion.classic, items);
			applySpecialist(specialist, localVersion.specialist, items);
			applyPreAssess(preAssess, localVersion.preAssess, items);

			if (localVersion.specialist && items.specialistGroups.items.length && targetHierarchy.mode === HierarchyMode.Specialist && localVersion.mode === HierarchyMode.Specialist) {
				targetHierarchy.mode = HierarchyMode.Specialist;
			}
			if (localVersion.preAssess && items.specialistGroups.items.length && targetHierarchy.mode === HierarchyMode.PreAssess && localVersion.mode === HierarchyMode.PreAssess) {
				targetHierarchy.mode = HierarchyMode.PreAssess;
			}
		}

		return;
	}
}
