import {ExpandablePanelRoot, NavigationItem, NavigationItemDescription, SectionBox} from './styled';
import {Text} from '@esgi/ui/typography';
import {AppliedTo, Filenametemplate, TextIcon, SightWords} from '@esgi/ui/icons';
import {ExpandablePanel} from '@esgi/ui/layout';
import {useLocation, useNavigate} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {routes} from '@esgi/main/libs/core';

export function ActivitiesPanel() {
	const navigate = useNavigate();
	const {pathname} = useLocation();

	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(pathname === routes.teacher.activities.root);
	}, [pathname]);

	const handleNavigateToActivity = useCallback(
		(path: string) => () => {
			navigate(path);
		},
		[navigate],
	);

	const handlePanelClick = useCallback(() => {
		if (!show) {
			navigate(routes.teacher.activities.root);
		}
	}, [show, navigate]);


	return <ExpandablePanelRoot open={show} onClick={handlePanelClick} collapsed={!show} dataCy='activities-panel'>
        {({panelIsHovered, open}) => {
            return <>
                {!open && <ExpandablePanel.Trigger/>}
                <ExpandablePanel.Placeholder>
                    <SectionBox fillColor={panelIsHovered ? 'secondary' : 'primary'}>
                        <AppliedTo data-cy='activities-icon' width={24} height={24}/>
                    </SectionBox>
                </ExpandablePanel.Placeholder>
                <ExpandablePanel.Content>
                    <Text size='small' color='neutral56' css={{paddingLeft: 12}} data-cy='activities-title'>
                        Activities
                    </Text>
                    <NavigationItem onClick={handleNavigateToActivity(routes.teacher.activities.bingo)} dataCy='bingo-item'>
                        <AppliedTo/>
                        <Text size='large'>Bingo</Text>
                    </NavigationItem>
                    <NavigationItemDescription size='small' data-cy='bingo-description'>
						Customized Bingo call sheets and cards
                    </NavigationItemDescription>

                    <NavigationItem onClick={handleNavigateToActivity(routes.teacher.activities.flashcards)} dataCy='flashcards-item'>
                        <Filenametemplate/>
                        <Text size='large'>Flashcards</Text>
                    </NavigationItem>
                    <NavigationItemDescription size='small' data-cy='flashcards-description' css={{display: 'flex'}}>
						Flashcards for all, incorrect, and/or skipped questions
                    </NavigationItemDescription>

                    <NavigationItem onClick={handleNavigateToActivity(routes.teacher.activities.writingPractice)} dataCy='writing-practice-item'>
                        <TextIcon/>
                        <Text size='large'>Writing Practice</Text>
                    </NavigationItem>
                    <NavigationItemDescription size='small' data-cy='writing-practice-description'>
						Name writing practice sheets
                    </NavigationItemDescription>

                    <NavigationItem onClick={handleNavigateToActivity(routes.teacher.activities.sightWords)} dataCy='sight-words-item'>
                        <SightWords/>
                        <Text size='large'>Sight Words</Text>
                    </NavigationItem>
                    <NavigationItemDescription size='small' data-cy='sight-words-description'>
						Writing practice from sight word tests
                    </NavigationItemDescription>
                </ExpandablePanel.Content>
            </>;
        }}
    </ExpandablePanelRoot>;
}
