import {HandlingStrategy} from './handling-strategy';

export abstract class ErrorHandler {
	public abstract next<T>(response: XMLHttpRequest, strategy: HandlingStrategy): void;
}

export class CustomErrorHandler extends ErrorHandler {
	constructor(private customHandler: (response: XMLHttpRequest, strategy: HandlingStrategy) => void) {
		super();
	}

	public next<T>(response: XMLHttpRequest, strategy: HandlingStrategy): void {
		this.customHandler(response, strategy);
	}
}
