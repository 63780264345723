import {Dispatch, useCallback} from 'react';
import {TestGroupStudentsModel} from '../../../../../../../types';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {CardViewType, TestDetailsState, TestSectionGroupStudents} from '../../../../../types';
import {TestCard} from '../../../../test-cards';

type Props = TestGroupStudentsModel & {
	onTestCardClicked: Dispatch<TestGroupStudentsModel>;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	isSmallScreen: boolean;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
	cardViewType: CardViewType;
	openTestDetails: Dispatch<TestDetailsState>;
	statisticEntity: TestSectionGroupStudents['statisticEntity'];
};

export function GroupStudentsCard({
	onTestCardClicked,
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	isSmallScreen,
	onTestMoveTo,
	onRemoveTest,
	testInfo,
	analyticsData,
	classResult,
	studentsResult,
	lastTestSessions,
	classID,
	subjectID,
	cardViewType,
	statisticEntity,
}: Props) {
	const onOpenTestDetails = useCallback(() => {
		openTestDetails({testId: testInfo.id, testType: testInfo.type});
	}, [openTestDetails, testInfo.id, testInfo.type]);

	const onTestCardClick = useCallback(() => {
		onTestCardClicked({testInfo, analyticsData, classResult, studentsResult, lastTestSessions});
	}, [analyticsData, classResult, lastTestSessions, onTestCardClicked, studentsResult, testInfo]);

	if (cardViewType === CardViewType.List) {
		return (
			<TestCard.ListCard.GroupStudentsCard
				onTestCardClicked={onTestCardClick}
				userCanEditTest={userCanEditTest}
				userCanRemoveTest={userCanRemoveTest}
				openTestDetails={onOpenTestDetails}
				isSmallScreen={isSmallScreen}
				onTestMoveTo={onTestMoveTo}
				onRemoveTest={onRemoveTest}
				classID={classID}
				subjectID={subjectID}
				analyticsData={analyticsData}
				classResult={classResult}
				lastTestSessions={lastTestSessions}
				studentsResult={studentsResult}
				testInfo={testInfo}
				statisticEntity={statisticEntity}
			/>
		);
	}

	if (cardViewType === CardViewType.Grid) {
		return (
			<TestCard.GridCard.GroupStudentsCard.Default
				onTestCardClick={onTestCardClick}
				userCanEditTest={userCanEditTest}
				openTestDetails={onOpenTestDetails}
				onTestMoveTo={onTestMoveTo}
				onRemoveTest={onRemoveTest}
				classID={classID}
				subjectID={subjectID}
				analyticsData={analyticsData}
				classResult={classResult}
				lastTestSessions={lastTestSessions}
				testInfo={testInfo}
				studentsResult={studentsResult}
			/>
		);
	}

	throw new Error('cardViewType is not inconsistent with CardViewType enum');
}
