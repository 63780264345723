import {useRef, useCallback, useState} from 'react';
import {Dictionary, isEqual, object} from 'underscore';
import {TestItem} from './types';

export function useDeepMemo<T>(value: T): T {
	const valueRef = useRef(value);
	if (!isEqual(valueRef.current, value)) {
		valueRef.current = value;
	}

	return valueRef.current;
}

export function useTestsSelection() {
	const [entity, setEntity] = useState<Record<string, string[]>>({});

	const selectedEntities = useDeepMemo(Object.keys(entity));

	// Sets a list of entities without defined list of items.
	const entitySet = useCallback((entityIDs: string[]) => {
		setEntity((entity) => (
			object(entityIDs, entityIDs.map((id) => entity[id] || [])) as Dictionary<string[]>
		));
	}, []);

	/*
		Sets a list of entities with a defined list of items e.g.:
		Input: values: [1, 2, 3] / items: [[1, 2, 3], [4, 5, 6], []]
		Output: { 1: [1, 2, 3], 2: [4, 5, 6], 3: [] }
	 */
	const entitySetAll = useCallback((entityIDs: string[], items: string[][]) => {
		setEntity(object(entityIDs, items) as Dictionary<string[]>);
	}, []);

	/*
		Sets an empty entity
	 */
	const entityUnsetAll = useCallback(() => {
		setEntity({});
	}, []);

	const entityEdit = useCallback((id: TestItem['id'], list: string[]) => {
		setEntity((entities) => ({
			...entities,
			[String(id)]: list,
		}));
	}, []);

	const handleEntityEdit = useCallback((id: TestItem['id']) => (list: string[]) => {
		entityEdit(id, list);
	}, [entityEdit]);

	return {
		entity,
		entitySet,
		entitySetAll,
		entityUnsetAll,
		entityEdit,
		handleEntityEdit,
		selectedEntities,
	};
}