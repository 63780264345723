import React, {CSSProperties} from 'react';
import {TemplateProps} from '../template';
import {getUrl, isMultiple} from '../../utils';
import {BaseOption} from '../../option/option';
import styles from './with-three-columns.module.less';
import AudioButton from '../../audio-button/audio-button';
import {join} from '@esgillc/ui-kit/utils';

class WithThreeColumns extends React.Component<TemplateProps> {
	private selected(id: number): boolean {
		return this.props.selectedOptionID.includes(id);
	}

	render() {
		const shouldRenderPic = this.props.question.picUrl;
		const shouldRenderText = this.props.question.text;

		const question = this.props.question;
		const shouldRenderQuestion = shouldRenderPic || shouldRenderText;

		return (
			<div className={styles.template}>
				{shouldRenderQuestion && <div className={join(styles.questionContainer)}>
					{shouldRenderPic && this.renderPic(!shouldRenderText)}
					{shouldRenderText && this.renderText()}
				</div>}
				<div className={styles.optionsContainer}>
					{question.options.map(item =>
						<BaseOption
							bucket={this.props.awsBucket}
							type={'Vertical'}
							id={item.id}
							disabled={this.props.maxOptionsSelected && !this.props.selectedOptionID.includes(item.optionID)}
							multiselect={isMultiple(this.props.question)}
							key={item.optionID}
							text={item.text}
							clicked={() => this.props.onSelectOption(item.optionID)}
							selected={this.selected(item.optionID)}
							audioPath={item.audioUrl}
							picUrl={item.picUrl}
							className={styles.optionBox}
							disableAudio={this.props.audioPlaying}
							customize={{
								optionClassName: styles.customizeOption,
								actionClassName: styles.customizeAction,
								audiBtnClassname: styles.customizeAudiBtn,
							}}
						/>)}
				</div>
			</div>
		);
	}

	private renderText() {
		let css: CSSProperties = {};
		if (this.props.question.textSizePx) {
			css = {fontSize: `${this.props.question.textSizePx}px`};
		}
		return <div className={styles.textContainer}>
			{this.renderAudio()}
			<div className={styles.text}>
				<span style={css} dangerouslySetInnerHTML={{__html: this.props.question.text}}/>
			</div>
		</div>;
	}

	private renderAudio() {
		if (!this.props.question.audioUrl) {
			return null;
		}
		const url = getUrl(this.props.awsBucket, this.props.question.audioUrl);

		return <AudioButton
			disabled={this.props.audioPlaying}
			className={styles.audioBtn}
			id={this.props.question.id}
			audioUrl={url}
		/>;
	}

	private renderPic(shouldRenderAudio: boolean) {
		const url = getUrl(this.props.awsBucket, this.props.question.picUrl);
		return <>
			<div className={styles.imgContainer}><img src={url}/></div>
			{shouldRenderAudio && this.renderAudio()}
		</>;
	}
}

export default WithThreeColumns;
