import {Panel, Button, ButtonIcon} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Skeletonable, Tooltip} from '@esgi/ui';
import {Preview} from '@esgi/ui/icons';
import {Title} from './title';

interface Props extends Skeletonable {
	downloadTitle?: string;
	disabled?: boolean;
	onPreview?: VoidFunction;
	onDownload: VoidFunction;
	disabledPreviewTooltip?: string;
	activePreviewTooltip?: string;
	disabledDownloadTooltip?: string;
}

export function DownloadPanel(props: Props) {
	const {
		skeleton,
		downloadTitle = 'Download',
		disabled = false,
		disabledPreviewTooltip,
		disabledDownloadTooltip,
		activePreviewTooltip,
		onPreview,
		onDownload,
	} = props;

	const previewTooltipText = disabled ? disabledPreviewTooltip : activePreviewTooltip;
	return (
		<Panel dataCy='download-panel'>
			<Title/>
			{onPreview &&
				<Tooltip>
					<Tooltip.Trigger>
						<ButtonIcon
							disabled={disabled}
							onClick={onPreview}
							dataCy='preview-button'
						>
							<Preview/>
						</ButtonIcon>
					</Tooltip.Trigger>
					{previewTooltipText && <Tooltip.Content variant={disabled ? 'default' : 'secondary'}>
						<Text size='small'>{previewTooltipText}</Text>
					</Tooltip.Content>}
				</Tooltip>
			}
			<Tooltip>
				<Tooltip.Trigger>
					<Button
						skeleton={skeleton}
						color='primary'
						disabled={disabled}
						onClick={onDownload}
						dataCy='download-button'
					>
						<Text size='large' bold>{downloadTitle}</Text>
					</Button>
				</Tooltip.Trigger>
				{disabled && disabledDownloadTooltip && <Tooltip.Content>
					<Text size='small'>{disabledDownloadTooltip}</Text>
				</Tooltip.Content>}
			</Tooltip>
		</Panel>
	);
}
