import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {mapToEnum} from 'shared/utils';
import {getLevelName} from './level-score-type';
import {Criteria, Description, Level, LevelDisplayMode, TestInfo} from './models';
import {RubricModel} from './types';
import {createCriteria, createLevel, createZeroLevel} from './utils/creating';
import {initializeLevels} from './utils/initializing';
import {removeCriteria, removeLevel} from './utils/removing';
import {sortCriteria, sortLevels} from './utils/sorting';
import {updateCriteria, updateDescription, updateLevel} from './utils/updating';

export default class RubricService extends BaseService {
	public testID: number;
	public readonly criteria$: BehaviorSubject<Criteria[]> = new BehaviorSubject<Criteria[]>([]);
	public readonly levels$: BehaviorSubject<Level[]> = new BehaviorSubject<Level[]>([]);
	public readonly descriptions$: BehaviorSubject<Description[]> = new BehaviorSubject<Description[]>([]);
	public readonly levelDisplayMode$: BehaviorSubject<LevelDisplayMode> = new BehaviorSubject<LevelDisplayMode>(LevelDisplayMode.Number);
	public readonly testInfo$: BehaviorSubject<TestInfo> = new BehaviorSubject<TestInfo>({
		gradeLevelIDs: [],
		name: '',
		shared: true,
		description: '',
		color: 'CDCDCD',
		contentAreaID: 0,
		stateStandardID: 0,
		stateStandard: '',
	} as TestInfo);
	public isMinimumLevel: boolean;

	public initFromModel(model: RubricModel): void {
		this.testID = model.id;
		this.testInfo$.next({
			testID: model.id,
			name: model.name,
			description: model.description,
			contentAreaID: model.contentAreaID,
			gradeLevelIDs: model.gradeLevelIDs,
			stateStandard: model.stateStandardName,
			stateStandardID: model.stateStandardID,
			shared: model.shared,
			color: model.color,
			stateStandardIDs: model.stateStandards?.map(stateStandard => stateStandard.id),
		});

		this.levels$.next(sortLevels(model.levelModels));
		this.criteria$.next(sortCriteria(model.criteriaModels));
		this.descriptions$.next(model.descriptionModels);
		this.levelDisplayMode$.next(mapToEnum(model.levelDisplayType, LevelDisplayMode));
		this.isMinimumLevel = this.levels$.value.some(x=>x.score === 0);

	}

	public addCriteria(): Criteria {
		const result = createCriteria(this.criteria$.value, this.levels$.value, this.descriptions$.value);
		this.updateEntities(result);
		return result.entity;
	}

	public addLevel(): Level {
		const result = createLevel(this.criteria$.value, this.levels$.value, this.descriptions$.value, this.isMinimumLevel);
		this.updateEntities(result);
		return result.entity;
	}

	public updateCriteria(criteria: Criteria, changes: { order?: number, name?: string }): void {
		const result = updateCriteria(criteria, changes, this.criteria$.value, this.descriptions$.value);
		this.updateEntities(result);
	}

	public updateLevel(level: Level, changes: { score?: number, name?: string }): void {
		const result = updateLevel(level, changes, this.levels$.value, this.descriptions$.value);
		this.updateEntities(result);
	}

	public updateDescriptions(description: Description, changes: { description?: string, details?: string }): void {
		const result = updateDescription(description, changes, this.descriptions$.value);
		this.updateEntities(result);
	}

	public removeCriteria(criteria: Criteria): void {
		const result = removeCriteria(criteria, this.criteria$.value, this.descriptions$.value);
		this.updateEntities(result);
	}

	public removeLevel(level: Level): void {
		if(level.score === 0){
			this.isMinimumLevel = false;
		}
		const result = removeLevel(level, this.levels$.value, this.descriptions$.value, this.isMinimumLevel);
		this.updateEntities(result);
		
	}

	public reorderCriteria(prevIndex: number, nextIndex: number): void {
		const criteria = this.criteria$.value.find(c => c.order === prevIndex);
		this.updateCriteria(criteria, {order: nextIndex});
	}

	/**
	 * @description Create Rubric model base on current service state.
	 * @param includeMeta - If set true, will include metainfo about fields.
	 * @return RubricModel
	*/
	public serialize(includeMeta?: boolean): RubricModel {
		const {name, description, color, gradeLevelIDs, stateStandardID, stateStandard, contentAreaID, shared} = this.testInfo$.value;
		return {
			id: this.testID,
			name: name?.trim(),
			description: description?.trim(),
			gradeLevelIDs: gradeLevelIDs,
			contentAreaID,
			stateStandardID: stateStandardID ?? 0,
			stateStandardName: stateStandard,
			shared,
			color: color,
			levelDisplayType: this.levelDisplayMode$.value,

			levelModels: this.levels$.value.map(l => {
				const level = {id: l.id, name: l.name || l.score.toString(), score: l.score} as Level;
				if (includeMeta) {
					level.metaInfo = l.metaInfo;
				}
				return level;
			}),
			criteriaModels: this.criteria$.value.map(c => {
				const criteria = {id: c.id, name: c.name, order: c.order} as Criteria;
				if (includeMeta) {
					criteria.metaInfo = c.metaInfo;
				}
				return criteria;
			}),
			descriptionModels: this.descriptions$.value.map(d => ({
				id: d.id,
				description: d.description,
				details: d.details,
				levelID: d.levelID,
				criteriaID: d.criteriaID,
			})),
			stateStandardIDs: this.testInfo$.value.stateStandardIDs,
		} as RubricModel;
	}

	public updateTestInfo(changes: Partial<TestInfo>): void {
		this.testInfo$.next({...this.testInfo$.value, ...changes});
	}
	
	public updateLevelDisplayMode(displayMode: LevelDisplayMode): void {
		let levels = [...this.levels$.value];
		let descriptions = [...this.descriptions$.value];
		if (levels.some(l => !l.metaInfo?.defaultField)) { //Reset levels
			const initializeResult = initializeLevels(this.criteria$.value, this.isMinimumLevel);
			levels = initializeResult.levels;
			descriptions = initializeResult.descriptions;
		}
		if (displayMode === LevelDisplayMode.Text) {
			for (let i = 0; i < levels.length; i++) {
				levels[i].name = getLevelName(levels[i].score, levels[i].name);
			}
		} else {
			for (const level of levels) {
				level.name = '';
			}
		}

		this.updateEntities({levels: levels, descriptions: descriptions});
		this.levelDisplayMode$.next(displayMode);
	}

	public addZeroLevel(): Level {
		this.isMinimumLevel = true;
		const result = createZeroLevel(this.criteria$.value, this.levels$.value, this.descriptions$.value, this.levelDisplayMode$.value);
		this.updateEntities(result);
		return result.entity;
	}

	protected updateEntities(changes: Partial<{ criteria: Criteria[], levels: Level[], descriptions: Description[] }>): void {
		if (changes.criteria !== undefined) {
			this.criteria$.next(changes.criteria);
		}

		if (changes.levels !== undefined) {
			this.levels$.next(changes.levels);
		}

		if (changes.descriptions !== undefined) {
			this.descriptions$.next(changes.descriptions);
		}
	}
}
