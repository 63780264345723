import React from 'react';
import './color-cell.less';

export class Props {
	color: string;
	selected: boolean;
	onClick: (color: string) => void;
	className?: string = '';
}

export class Cell extends React.PureComponent<Props> {
	constructor(props) {
		super(props);
	}

	getClass() {
		let defaultClass = `mx-question-editor-color-picker-cell ${this.props.className ? this.props.className : ''}`;
		if (this.props.color === 'none') {
			defaultClass += ' transparent';
		}
		if (this.props.selected) {
			defaultClass += ' selected';
		}
		return defaultClass;
	}

	render() {
		return <div
			onMouseDown={(e) => e.preventDefault()}
			onMouseUp={(e) => e.preventDefault()}
			onClick={() => this.props.onClick(this.props.color)}
			className={this.getClass()}
			style={{backgroundColor: '#' + this.props.color}}>
		</div>;
	}
}


