import React, {useMemo, useState} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {InitModel} from 'modules/reports/parent-letter/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import ParentLetterLauncher from 'modules/reports/parent-letter/launcher';
import {useEventEffect} from '@esgillc/events';
import {ViewParentLetterEvent} from 'modules/reports/parent-letter/events';

class Props {
	applyHomepageChanges: (changes: ChangesCollector) => void;
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	noStudents: boolean;
}

export default function ParentLetterButton({
	                                           applyHomepageChanges,
	                                           noStudents,
	                                           subjectType,
	                                           subjectID,
	                                           hierarchy,
}: Props) {

	const [selectedItems, setSelectedItems] = useState<InitModel>(null);
	const changesCollector = useMemo(() => new ChangesCollector({
		id: subjectID,
		type: subjectType,
	}, hierarchy.snapshot), [subjectID, subjectType, hierarchy]);

	function onClosed() {
		setSelectedItems(null);
		applyHomepageChanges(changesCollector);
	}

	function openParentLetter() {
		setSelectedItems(new InitModel(changesCollector.groupID.initialValue, changesCollector.studentID.initialValue,
			subjectID, subjectType));
	}

	useEventEffect(ViewParentLetterEvent, (args) => {
		const {selectedGroupItemID, selectedStudentID, subjectType, subjectID} = args.initModel;
		setSelectedItems(new InitModel(selectedGroupItemID, selectedStudentID, subjectID, subjectType));
	});

	const icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='16' height='12'
		            viewBox='0 0 16 12'
		            fill='none'>
			<path
				d='M0.792055 1.8678C1.19974 2.08223 6.84484 5.04966 7.05494 5.16015C7.26522 5.27064 7.53745 5.32392 7.81169 5.32392C8.08609 5.32392 8.35799 5.27064 8.56843 5.16015C8.77854 5.04966 14.4236 2.0824 14.8312 1.8678C15.2392 1.65353 15.6253 0 14.8759 0H0.747462C-0.00143206 0 0.384038 1.65353 0.792055 1.8678ZM15.004 3.50221C14.5409 3.73871 8.85202 6.65008 8.56843 6.79456C8.28467 6.93953 8.08593 6.95833 7.81169 6.95833C7.53745 6.95833 7.3387 6.93953 7.05494 6.79456C6.77135 6.65008 1.08283 3.73871 0.619362 3.50221C0.293182 3.33599 0.296021 3.53097 0.296021 3.68167C0.296021 3.83236 0.296021 10.6572 0.296021 10.6572C0.296021 10.9997 0.762327 11.4409 1.12425 11.4409H14.4991C14.8611 11.4409 15.3274 10.9997 15.3274 10.6572C15.3274 10.6572 15.3274 3.83236 15.3274 3.68167C15.3274 3.53097 15.3307 3.33599 15.004 3.50221Z'
				fill='#0088CC'/>
		</svg>;
	};


	return <><NoStudentProtectedButton title='Parent Letter'
	                                   icon={icon()}
	                                   hoverTitle='Print student results with personalized messaging for parent review'
	                                   onClicked={openParentLetter}
	                                   noStudents={noStudents}
	                                   linkClassName='parent-letter-report-link'/>
	{selectedItems &&
			<ParentLetterLauncher initModel={selectedItems} changesCollector={changesCollector} onClosed={onClosed}
			                      hierarchy={hierarchy.snapshot}/>}
	</>;
}
