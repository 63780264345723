import {Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {ControlValue, Form, FormControl, FormElement, FormGroup} from '@esgillc/ui-kit/form';
import styles from './styles.module.less';
import React from 'react';


interface Props {
	classID: number;
	className: string;
	formController: FormGroup<{ className: FormControl<string> }, ControlValue<{ className: FormControl<string> }>>
}

export function ClassFormHeader({classID, formController}: Props) {
	return <div data-cy='class-form-header'>
		<div data-cy='header-form'>
			<Form controller={formController}>
				<FormElement control={formController.controls.className}>
					<label className={styles.formLabel}
					       data-cy='input-label'>{classID ? 'Edit Class Name' : 'Class Name'}</label>
					<Input placeholder={'i.e. New class'}/>
					<OnErrorTooltip showOnError='required' placement='right'>
						Please enter a class name.
					</OnErrorTooltip>
				</FormElement>
			</Form>
		</div>
		<label className={styles.actionText} data-cy='hint'>
			{classID ? 'Move available students into your classroom.'
				: 'Move available students into your new classroom.'}</label>
	</div>;

}

