import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Link, UnderlinedText} from './index.styled';
import {To} from 'react-router-dom';

type Props = {
	to: To;
	text: string;
};

export function TextWithLinkBlock({to, text}: Props) {
	return (
		<GridBox gap='2'>
			<Text size='medium' color='highContrast'>
				{text}
			</Text>

			<Link to={to} target='_blank'>
				<UnderlinedText size='medium' color='primary'>
					Learn More
				</UnderlinedText>
			</Link>
		</GridBox>
	);
}
