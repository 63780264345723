import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Alert} from '@esgi/ui/alert';

type Props = {
	onRestoreSession: VoidFunction;
};

export function AlertFooterDeletedSession({onRestoreSession}: Props) {
	return (
		<Alert.Footer display='grid' align='center' justify='end'>
			<Button color='secondary' onClick={onRestoreSession}>
				<Text size='medium' bold>
					Restore
				</Text>
			</Button>
		</Alert.Footer>
	);
}
