import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';

type Props = {
	onClose: VoidFunction;
};

export function TestSessionTimeConflict({onClose}: Props) {
	const conflictAlert = Alert.useRef();
	const closeAlert = Alert.useClose(conflictAlert, onClose);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={conflictAlert}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Time Overlap Warning
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox>
					<Text size='medium' color='lowContrast'>
						This session's date and time overlap with another session.
					</Text>
					<Text size='medium' color='lowContrast'>
						Please choose a different date or time.
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='secondary' onClick={closeAlert}>
						<Text size='medium' bold color='negative'>
							Got it!
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
