import {BaseComponentProps, Skeletonable} from '../../../../types';
import {useAutoControlledState} from '@esgi/ui/utils';
import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {RadioGroupContext, RadioGroupValueContextValue} from '../../context';
import {RadioGroupProps as RadioGroupRadixProps} from '@radix-ui/react-radio-group';
import {RadioGroup} from './index.styled';

type RadioGroupProps = BaseComponentProps &
	ComponentPropsWithoutRef<'div'> &
	Omit<RadioGroupRadixProps, 'orientation'> &
	Skeletonable;

export const RadioGroupRoot = forwardRef<HTMLDivElement, RadioGroupProps>(
	({dataCy = 'ui-kit-radio-group', skeleton, disabled, ...props}, forwardedRef) => {
		const [selectedValue, setSelectedValue] = useAutoControlledState({
			initialState: undefined,
			controlledState: props.value,
		});

		const contextValue = useMemo<RadioGroupValueContextValue>(
			() => ({
				selectedValue,
				setSelectedValue,
				disabledAllItems: Boolean(disabled),
			}),
			[selectedValue, setSelectedValue, disabled],
		);

		return (
			<RadioGroupContext.Provider value={contextValue}>
				<RadioGroup data-cy={dataCy} ref={forwardedRef} disabled={disabled} {...props} />
			</RadioGroupContext.Provider>
		);
	},
);
