import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {SubjectType} from '@esgi/core/enums';

export interface QuestionDetails {
	studentsCorrect: string[];
	studentsIncorrect: string[];
	studentsNotTested: string[];
}

export interface Report {
	reportFilter: ReportFilter;
	reportData: ReportData;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
}

export interface ChartOptions {
	chartSize: {width: number, height: number, max: number, min?: number};
	testName: string;
	reportData: ReportData;
	sortOptionMode: string;
	userLevel: string;
	stackIncorrect: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	updateScrollbarDisplay: () => void;
	normalizeChart: (event) => void;
	selectPoint: (event) => void;
	updateTitleSize: () => void;
	clone?: boolean;
}

export interface ReportData {
	showPercentage: boolean;
	students: StudentData[];
	questions: QuestionData[];
	maxScore: number;
}

export interface StudentData {
	studentID: number;
	name: string;
	gradeLevelID: number;
}

export interface QuestionData {
	questionID: number;
	order: number;
	label: string;
	answers: StudentAnswerData[];
	correct: number;
	incorrect: number;
}

export interface StudentAnswerData {
	studentID: number;
	answerState: number;
}

export interface ReportFilter {
	displayNotTestedAsIncorrect: boolean;
	teacherName: string;
	schoolName: string;
	districtName: string;
	classes: SelectModel<IdNameModel>;
	groups: SelectModel<IdNameModel>;
	specialistGroups: SelectModel<IdNameModel>
	subjects: SelectModel<Subject>,
	gradeLevels: IdNameModel[];
}

export interface IdNameModel {
	id: number;
	name: string;
}

export interface SelectModel<T> {
	selectedID: number;
	items: T[];
}

export interface Subject extends IdNameModel {
	type: string;
	tests: Array<Test>;
}

export interface Test extends IdNameModel {
	type: string;
}

export enum FileType {
	PDF, Excel
}

export type ZoomOption = 'Zoom In' | 'Zoom Out' | 'Zoom To Fit' | '50%' | '100%' | '200%';

export interface UpdateOptions {
	classId: number,
	groupId: number,
	specialistGroupId: number,
	subjectId: number,
	subjectType: string,
	testId: number,
	fullHierarchy: HierarchySnapshot,
}

export interface Settings {
	classID: number,
	groupID: number,
	subjectTabID: number,
	subjectTabType: SubjectType,
}

export type Level = 'user' | 'school' | 'district' | 'group';
