import React, {PureComponent} from 'react';
import {tryCall} from '@esgillc/ui-kit/utils';

interface Props {
	onClick?: () => void;
}

export class AddIcon extends PureComponent<Props> {
	render() {
		return <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={() => tryCall(this.props.onClick)}>
			<path
				d='M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
				fill='#0077B8'/>
		</svg>;
	}
}

export class ClearIcon extends React.PureComponent<{ onClick: () => void }> {
	public render() {
		return <svg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={this.props.onClick}>
			<path
				d='M10.5 1.0575L9.4425 0L5.25 4.1925L1.0575 0L0 1.0575L4.1925 5.25L0 9.4425L1.0575 10.5L5.25 6.3075L9.4425 10.5L10.5 9.4425L6.3075 5.25L10.5 1.0575Z'
				fill='#828282'/>
		</svg>;
	}
}
