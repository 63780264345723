import {ExpandablePanel, Reports, SettingsPanel as ReportsSettingsPanel} from '@esgi/main/kits/reports';
import {noop} from 'underscore';
import {Wrapper, Link, CheckboxWrapper} from './index.styled';
import {ClassGradesService} from '../../service';
import {Info} from '@esgi/ui';
import {Dispatch, SetStateAction} from 'react';
import {Checkbox} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {RightSidebar} from '../../../../../layout/index.styled';

type Props = {
	disabled: boolean,
	service: ClassGradesService,
	isCarryForwardChecked: boolean
	setCarryForwardChecked: Dispatch<SetStateAction<boolean>>,
}

export function SettingsPanel({disabled, service, isCarryForwardChecked, setCarryForwardChecked}: Props) {
	return (
		<RightSidebar>
			<Reports.ReportPanel
				disabled={disabled}
				onReport={noop}
			/>
			<ExpandablePanel>
				<Wrapper>
					<ReportsSettingsPanel title='Settings'>
						<CheckboxWrapper>
							<Checkbox
								disabled={disabled}
								label={'Carry Forward'}
								checked={isCarryForwardChecked}
								onCheckedChange={() => setCarryForwardChecked(prev => !prev)}
							/>
							<Link
								href='https://support.esgisoftware.com/hc/en-us/articles/360024667492-Grade-Report-Editing-From-Within'
								target='_blank'
								rel='noreferrer'
							>
								<Info width={24} height={24}/>
							</Link>
						</CheckboxWrapper>
					</ReportsSettingsPanel>
					<ReportsSettingsPanel title='Grades'>
						<CheckboxWrapper>
							<Text>Grades</Text>
						</CheckboxWrapper>
					</ReportsSettingsPanel>
					<Reports.MarkingPeriodControl disabled={disabled} onChange={(value) => service.setMarkingPeriod(value)}/>
					<Reports.NotTestedControl disabled={disabled} onChange={(value) => service.setNotTested(value)}/>
				</Wrapper>
			</ExpandablePanel>
		</RightSidebar>
	);
}