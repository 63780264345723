import {Drawer} from '@esgi/ui/layout';
import {Layer} from '@esgillc/ui-kit/core';
import {useService} from '@esgi/core/service';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AnswerState, SessionType} from '@esgi/core/enums';
import {TestSessionStatus} from '../../kit/enums';
import {SessionStatusAlert} from '../../kit/session-status-alert';
import {YesNoFlow} from './components/yes-no-flow';
import {TestingModel} from './models';
import {YNService} from './service';
import {getShuffleQuestion} from './utils';
import {RedesignTestLauncher} from './components/test-launcher';
import {IncludeType, Session, TestLauncherData, StudentModel, SubjectModel} from '../types';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {SessionStartedAlert} from '../../session-started-alert';
import {SessionResults} from './components/session-results';
import {AlertType, ScreenType, Summary} from './types';
import {useTestingAlerts} from './use-testing-alerts';
import {NoIncorrectItemsAlert} from './components/no-incorrect-items-alert';
import {Class} from '@esgi/main/libs/store';
import {ShareSyncService} from '../../kit/share-screen';

type YnProps = {
    questionIds?: number[];
    subject: Pick<SubjectModel, 'id' | 'type' | 'name'>;
    student: StudentModel;
    studentClass: Class;
    launcherData: TestLauncherData,
    onClose: () => void;
    onFlashCardsClicked: () => void;
    onTestSessionDetailsClicked: () => void
}

export function YesNoTesting(props: YnProps) {
	const {id: subjectId, type: subjectType} = props.subject;
	const [screenType, setScreenType] = useState<ScreenType>(ScreenType.None);
	const [shuffleQuestions, setShuffleQuestions] = useState(false);
	const [continueMode, setContinueMode] = useState(false);
	const [testSessionStatus, setTestSessionStatus] = useState<TestSessionStatus>(TestSessionStatus.None);
	const [resumeSession, setResumeSession] = useState(false);
	const [testingModel, setTestingModel] = useState<TestingModel>();
	const [sessionType, setSessionType] = useState<SessionType>();
	const [showNoIncorrectItemsAlert, setShowNoIncorrectItemsAlert] = useState(false);
	const [guid, setGuid] = useState<string>();
	const [summary, setSummary] = useState<Summary>();

	const service = useService(YNService);

	const shareSyncService = useMemo(() => {
		if(testingModel) {
			return new ShareSyncService({
				userID: testingModel?.general.userID,
				testName: testingModel?.testName,
				studentName: testingModel?.studentName,
				isWhiteBackground: testingModel?.isWhiteBackground,
			});
		}
	}, [testingModel]);

	const runSession = (sessionType: SessionType) => {
		setSessionType(sessionType);
		setScreenType(ScreenType.Flow);
	};

	const updateShuffleQuestionsValue = useCallback((value: boolean) => {
		setShuffleQuestions(value);

		service.updateShuffleQuestions(value);
	}, [service]);

	useEffect(() => {
		service.init(props.launcherData.testID, props.student.id, subjectId, subjectType as any, props.questionIds).subscribe({
			next: (model) => {
				updateShuffleQuestionsValue(getShuffleQuestion(model, subjectType));

				setTestingModel(model);
				setTestSessionStatus(model.testSessionStatus);
				setGuid(model.general.guid);
				if (model.progress.started) {
					return;
				}

				if (props.questionIds) {
					return runSession(SessionType.TestSubset);
				}

				if (model.availableSessionTypes.length > 1) {
					return setScreenType(ScreenType.SessionSelector);
				}

				if (model.resumeModel.resumeAvailable) {
					return setScreenType(ScreenType.SessionSelector);
				}

				if (model.availableSessionTypes.length === 1 && model.availableSessionTypes[0] === SessionType.TestIncorrect) {
					if (model.questions.every(q => q.oldAnswerState === AnswerState.Correct)) {
						return setShowNoIncorrectItemsAlert(true);
					}
				}

				return setScreenType(ScreenType.SessionSelector);
			},
			error: () => handleClose(),
		});
	}, []);

	const continueSession = () => service.updateGuid().subscribe({
		next: ({testSessionStatus, testSessionGuid}) => {
			if (!testSessionStatus) {
				setContinueMode(true);
				runSession(testingModel.progress.sessionType);
				setTestSessionStatus(testSessionStatus);
			}
			setGuid(testSessionGuid);
		},
		error: () => handleClose(),
	});

	const alertType = useTestingAlerts(testSessionStatus, testingModel);

	const handleClose = useCallback(() => {
		props.onClose();
		shareSyncService.destroy();
	}, [props, shareSyncService]);

	const onDoneHandler = (testSessionId, summaryText) => {
		if (summaryText !== null && summaryText.trim() !== ''){
			service.updateSummaryScore(testSessionId, summaryText);
		}
		handleClose();
	};

	return <>
		{testingModel && <Drawer show={screenType === ScreenType.SessionSelector}
                                 onClickOutside={handleClose}>
            <RedesignTestLauncher
                onClose={handleClose}
                shuffleQuestions={shuffleQuestions}
								updateShuffleQuestionsValue={updateShuffleQuestionsValue}
                onStart={(data) => {
                    if (data.session === Session.Resume) {
                        setResumeSession(true);
                        runSession(sessionType);
                    } else {
                        setShuffleQuestions(Boolean(data.include?.isShuffleQuestionOrder));
                        if (data.include?.includeType === IncludeType.AllQuestions) {
                            runSession(SessionType.TestAll);
                        } else {
                            runSession(SessionType.TestIncorrect);
                        }
                    }
                }}
                student={props.student}
                subject={props.subject}
                studentClass={props.studentClass}
                launcherData={props.launcherData}
                data={testingModel}
            />
        </Drawer>}
		{screenType !== ScreenType.SessionSelector && <Layer>
			<Switch test={screenType}>
				<Case value={ScreenType.Flow}>
					<YesNoFlow
						service={shareSyncService}
						onFinished={(summary) => {
							setSummary(summary);
							setScreenType(ScreenType.Summary);
						}}
						guid={guid}
						sessionType={sessionType}
						testingModel={testingModel}
						continueSession={continueMode}
						questionIds={props.questionIds}
						shuffleQuestions={shuffleQuestions}
						resumeSession={resumeSession}
						subject={props.subject}
						onFlashCardsClicked={props.onFlashCardsClicked}
						onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
						onSessionStatusChanged={setTestSessionStatus}
						onClose={handleClose}
					/>
				</Case>
				<Case value={ScreenType.Summary}>
					{summary && <SessionResults launcherData={props.launcherData}
					                            showPieChart={testingModel.showTestSessionResults}
												testName={testingModel.testName}
												summary={summary}
												subject={props.subject}
												student={props.student}
												onDoneClicked={onDoneHandler}
												onFlashcardsClicked={props.onFlashCardsClicked}
												studentClass={props.studentClass}/>}
				</Case>
			</Switch>
        </Layer>}
        <Switch test={alertType}>
            <Case value={AlertType.TestSessionStatus}>
                <SessionStatusAlert
                    status={testSessionStatus}
                    testName={testingModel?.testName}
                    studentName={testingModel?.studentName}
                    onConfirm={handleClose}/>
            </Case>
            <Case value={AlertType.SessionStarted}>
                <SessionStartedAlert
                    studentName={testingModel?.studentName}
                    testName={testingModel?.testName}
                    onConfirm={continueSession}
                    onCancel={handleClose}/>
            </Case>
        </Switch>
        {screenType !== ScreenType.None && <ServiceLoader fullscreen trackingService={service}/>}
        {showNoIncorrectItemsAlert && <NoIncorrectItemsAlert onClose={() => {
					setShowNoIncorrectItemsAlert(false);
					props.onClose();
				}}/>}
	</>;
}
