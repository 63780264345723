import React, {ComponentPropsWithoutRef, ReactNode, forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {Text} from '@esgi/ui/typography';
import {useToggleGroupContext} from '../../context';
import {Box} from '@esgi/ui/layout';

type ToggleGroupTitleProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Item Content. */
	children?: ReactNode | ((args: {value: string | undefined; description?: string | undefined}) => React.JSX.Element);
};

export const ToggleGroupTitle = forwardRef<HTMLDivElement, ToggleGroupTitleProps>(
	({dataCy, children, ...props}, forwaredRef) => {
		const {valueDescriptionOnHover, valueDescriptionSelected, selectedValue, hoveredValue} = useToggleGroupContext();

		const isItemHovered = Boolean(hoveredValue && valueDescriptionOnHover);
		const isItemSelected = Boolean(selectedValue && valueDescriptionSelected);

		return (
			<Title
				data-cy={dataCy ?? 'ui-kit-toggle-group-title'}
				data-hovered={isItemHovered}
				data-selected={isItemSelected && !isItemHovered}
				ref={forwaredRef}
				{...props}
			>
				{typeof children === 'function'
					? children({
							value: isItemHovered ? hoveredValue : selectedValue,
							description: isItemHovered ? valueDescriptionOnHover : valueDescriptionSelected,
					  })
					: children}
			</Title>
		);
	},
);

const Title = styled(Box, {
	textAlign: 'center',
	'&[data-hovered=true]': {
		[`& ${Text}`]: {
			color: '$secondaryVivid',
		},
	},
	'&[data-selected=true]': {
		[`& ${Text}`]: {
			color: '$lowContrast',
		},
	},
});
