import React, {ReactElement, useState} from 'react';
import styles from './styles.module.less';
import {Menu} from '@esgillc/ui-kit/menu';

interface Props {
	children: ReactElement[]
	menuHolder: ReactElement
}

export function MultiLevelMenu({children, menuHolder}: Props) {
	const [showMenu, setShowMenu] = useState(false);

	function closeMenu() {
		setShowMenu(false);
	}

	return <div className={styles.menuWrapper}>
		<div className={styles.flex} onClick={() => setShowMenu(true)}>{menuHolder}</div>
		<Menu show={showMenu}
		      keepFocusInside
		      onEscPressed={closeMenu}
		      onClickOutside={closeMenu}
		      className={styles.menu}>
			{children}
		</Menu></div>;
}
