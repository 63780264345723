import React from 'react';
import {SelectableListItem} from '@esgillc/ui-kit/control';
import styles from './question.module.less';
import {ImageUrlBuilder, createQuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {SightWordsTestQuestion} from '../../../shared/types';
import {userStorage} from '@esgi/core/authentication';

interface Props {
	question: SightWordsTestQuestion;
	testId: number;
}

export default class QuestionTile extends React.PureComponent<Props> {
	private readonly notAvailableSrc = 'https://esgiwebfiles.s3.amazonaws.com/images/img-not-available.jpg';
	private readonly ticks: number;
	private img: HTMLImageElement;
	private imageLoadingErrorTimes = 0;
	private urlBuilder: ImageUrlBuilder;

	constructor(props) {
		super(props);
		this.ticks = (new Date()).getTime();
		this.urlBuilder = createQuestionImageUrlBuilder();
	}

	render() {
		return <SelectableListItem value={this.props.question.questionID} className={styles.question}>
			<div className={styles.content}>
				<img ref={r => this.img = r} className={styles.img} src={this.getUrl()} onError={() => this.imageLoadingError()}/>
				<div className={styles.text}>
					{this.props.question.name}
				</div>
			</div>
		</SelectableListItem>
	}

	public getUrl () {
		return this.urlBuilder.medium(this.props.question.questionID, this.props.testId, this.props.question.pregenerated, this.ticks);
	}

	public imageLoadingError() {
		if (this.imageLoadingErrorTimes == 0) {
			this.img.src = this.urlBuilder.medium(this.props.question.questionID, this.props.testId, false, this.ticks);
		} else {
			this.img.src = this.notAvailableSrc;
		}

		this.imageLoadingErrorTimes++;
	}
}
