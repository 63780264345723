import React, {Ref, PropsWithChildren} from 'react';
import {ToolbarContainer} from './index.styled';

interface BaseProps {
  [key: string]: unknown;
  className: string;
}

type OrNull<T> = T | null;

export const Toolbar = React.forwardRef(
	(props: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <ToolbarContainer {...props} ref={ref} />
	)
);
