import {DragDropContext, DropResult, Droppable} from 'react-beautiful-dnd';
import {PropsWithChildren, useCallback, useId} from 'react';
import {GridBox} from '@esgi/ui/layout';

type Props<T> = PropsWithChildren<{
	droppableItems: T[];
	onDragEnd: (groupState: T[]) => void;
}>;

export function CardGroupDroppable<T>({droppableItems, onDragEnd, children}: Props<T>) {
	const droppableId = useId();

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return;
			}

			const items = [...droppableItems];

			const [removed] = items.splice(result.source.index, 1);

			if (removed) {
				items.splice(result.destination.index, 0, removed);
			}

			onDragEnd(items);
		},
		[droppableItems, onDragEnd],
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={droppableId}>
				{(provided) => (
					<GridBox ref={provided.innerRef} {...provided.droppableProps}>
						{children}
						{provided.placeholder}
					</GridBox>
				)}
			</Droppable>
		</DragDropContext>
	);
}
