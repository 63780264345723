import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox as GridBoxUI} from '@esgi/ui/layout';

export const IconWrapper = styled(FlexBox, {
	width: 40,
	height: 40,
	borderRadius: 12,
	borderWidth: 1.5,
	borderStyle: 'solid',
	borderColor: '$border',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	color: '$primary',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const GridBox = styled(GridBoxUI, {
	gridTemplateColumns: 'max-content',
	maxWidth: 300,
});
