import {DateModelForState} from '../../../types';

export function createDateForState(model: Partial<DateModelForState> = {}): DateModelForState {
	return {
		value: null,
		disabled: false,
		error: undefined,
		...model,
	};
}
