import {FlexBox} from '@esgi/ui/layout';
import {StudentFullName} from '@esgi/main/kits/common';
import {noop} from 'underscore';
import {CheckboxContainer, StyledCheckbox} from './styled';

type Props = {
	checked: boolean,
	maxWidth?: number,
	firstName: string;
	lastName: string;
};

export function StudentNameWithCheckbox(props: Props) {
	return <FlexBox align='center'>
		<CheckboxContainer>
			<StyledCheckbox checked={props.checked} onCheckedChange={noop} selectedStyles={props.checked}/>
		</CheckboxContainer>
		<StudentFullName firstName={props.firstName} lastName={props.lastName} inLine maxWidth={props.maxWidth}/>
	</FlexBox>;
}