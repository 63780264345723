import React, {useState} from 'react';
import {Radio} from '@esgillc/ui-kit/form-control';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {ElementStatus, FormElement} from '@esgillc/ui-kit/form';
import {SchoolTab} from '../../../../../../types';
import BaseSelectorModal from '../../../components/selector-modal';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import {SchoolCommonData} from 'modules/subject-details/types';
import styles from '../styles.module.less';
import PublishToSpecialists = SchoolTab.PublishToSpecialists;

class Props {
	form: SchoolFormType;
	schoolSubjectData: SchoolCommonData;
}

export default function SpecialistsSubSection({schoolSubjectData, form}: Props) {

	const [prevSelectedType, setPrevSelectedType] = useState<PublishToSpecialists>(PublishToSpecialists.All);
	const [nameStatus, setNameStatus] = useState<ElementStatus>(form.controls.subjectName.status);

	const [isShowISSSelector, setShowISSSelector] = useState<boolean>(false);
	const [selectedISSCount, setSelectedISSCount] = useState<number>(0);

	useStreamEffect(form.controls.publishToSpecialists.onChanged, v => v.reason === 'value' && setPrevSelectedType(v.prevState.value));
	useStreamEffect(form.controls.subjectName.onChanged, v => v.reason === 'status' && setNameStatus(v.currState.status));


	const openISSSelector = (e: React.MouseEvent<HTMLInputElement>): void => {
		if(nameStatus!==ElementStatus.invalid){
			setShowISSSelector(true);
		}
	};

	const renderSelectors = (): JSX.Element => {
		if (isShowISSSelector) {
			const label = `School Specialists - ${schoolSubjectData.schoolName} School`;
			const selected = schoolSubjectData.schoolSpecialists.filter(iss => form.controls.iss.value.indexOf(iss.id) > -1);
			return <BaseSelectorModal title={label}
			                          description={''}
			                          source={schoolSubjectData.schoolSpecialists}
			                          initiallySelected={selected}
			                          dropdownLabel='School Specialists'
			                          searchPlaceholder='Search by name'

			                          onConfirm={(v) => {
				                          setShowISSSelector(false);
				                          setSelectedISSCount(v.length);
				                          form.controls.iss.value = v.map(v => v.id);
			                          }}
			                          onCancel={() => {
				                          setShowISSSelector(false);
				                          form.controls.publishToSpecialists.value = prevSelectedType;
			                          }}/>;
		}
		return <></>;
	};

	return <div data-cy={'specialists-subsection'} className={styles.subsection}>
		<strong>Specialists</strong>
		<FormElement control={form.controls.publishToSpecialists}>
			{(props) => {
				let issLabel = 'Individual School Specialist(s)';
				const isISSSelected = props.value === PublishToSpecialists.IndividualISS;
				if (isISSSelected && selectedISSCount) {
					issLabel += ' [' + selectedISSCount + ']';
				}
				return <>
					<Radio value={PublishToSpecialists.All}
					       id='publish-to-specialists-all-school-specialists'
					       className={styles.radio}>All School Specialists
						{schoolSubjectData.schoolSpecialists?.length}</Radio>
					<Radio onClick={(e) => openISSSelector(e)}
					       value={PublishToSpecialists.IndividualISS}
					       id='publish-to-specialists-school-specialists'
					       className={styles.radio}>{issLabel}
					</Radio>

					<Radio value={PublishToSpecialists.None}
					       id='publish-to-specialists-none'
					       className={styles.radio}>None</Radio>
				</>;
			}}
		</FormElement>
		{renderSelectors()}
	</div>;
}
