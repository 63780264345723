import {useMemo} from 'react';
import {
	LevelModel,
	StudentResult,
	SortModel,
	SortByCriteriaResults,
	SortByCriteriaResultsOptions,
} from '../../../../../types/table-level-report-service-types';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {SortableColumn} from '../components/sortable-column';
import styles from '../styles.module.less';
import {RubricResultReportService} from '../../../../../service';
import {CellStyled, CountOfLevelsStyled, CriteriaHeaderStyled} from '../index.styled';
import {Cell, Column, HeaderCell} from '../../../../../service/types';

export function useCriteriaResultColumns({
	rows,
	levels,
	service,
	sortBy,
	sortByCriteriaResultsOptions,
}: {
	rows?: StudentResult[] | undefined;
	levels: LevelModel[] | undefined;
	service: RubricResultReportService;
	sortBy: SortModel;
	sortByCriteriaResultsOptions: SortByCriteriaResultsOptions | null;
}) {
	return useMemo<Column<StudentResult>[] | null>(() => {
		const levelLength = levels?.some(({score}) => score === 0) ? levels?.length - 1 : levels?.length;

		if (!rows?.length || !levelLength) {
			return null;
		}

		const criteriaResults = rows[0]!.criteriaResults;

		return criteriaResults.map((criteria) => {
			const outOf =
				criteria.criteriaName === 'Score'
					? criteriaResults.filter(({criteriaName}) => criteriaName !== 'Score').length * levelLength
					: levelLength;

			const topHeaderCell: HeaderCell = {
				renderer: () => (
					<CriteriaHeaderStyled data-cy={`score-header-column-${criteria.criteriaName.toLowerCase()}`} key={criteria.criteriaID}>
						<p style={{margin: '0 0 5px'}}>{criteria.criteriaName}</p>
						<CountOfLevelsStyled>(out of {outOf})</CountOfLevelsStyled>
					</CriteriaHeaderStyled>
				),
			};

			const bottomHeaderCells: HeaderCell[] = criteria.periodResults.map(({periodName, testSessionID}) => {
				const sortByType: Omit<SortByCriteriaResults, 'direction'> = {
					sortKey: 'criteriaResults',
					criteriaName: criteria.criteriaName,
					periodResultName: periodName,
				};

				return {
					renderer: () => (
						<CellStyled key={testSessionID}>
							<SortableColumn
								sortByType={sortByType}
								title={periodName}
								service={service}
								sortBy={sortBy}
								sortByCriteriaResultsOptions={sortByCriteriaResultsOptions}
							/>
						</CellStyled>
					),
				};
			});

			const cells: Cell<StudentResult>[] = criteria.periodResults.map((p, i) => ({
				renderer: (row) => {
					const criteriaResult = row.criteriaResults.find(({criteriaName}) => criteriaName === criteria.criteriaName);
					const scoreStr = criteriaResult?.periodResults[i]?.value;
					const score = parseInt(scoreStr ?? '');

					if (criteriaResult?.criteriaName !== 'Score' && !isNaN(score) && score !== 0) {
						const firstLevel = levels.find((level) => level.score === score);

						const emptyOrNumberRegex = /^(\s*|\d+)$/;
						const levelIsNumber = firstLevel && emptyOrNumberRegex.test(firstLevel.name);

						if (firstLevel && !levelIsNumber) {
							return (
								<OnHoverTooltip key={i} message={firstLevel.name}>
									<CellStyled data-cy='tooltiped-cell'>
										{score}
									</CellStyled>
								</OnHoverTooltip>
							);
						}
					}

					return (
						<CellStyled key={i} data-cy='cell'>
							{scoreStr}
						</CellStyled>
					);
				},
			}));

			const studentResultColumns: Column<StudentResult> = {
				className: styles.criteriaColumn,
				headers: [[topHeaderCell], bottomHeaderCells],
				cells,
			};

			return studentResultColumns;
		});
	}, [levels, rows, service, sortBy, sortByCriteriaResultsOptions]);
}
