import {createElement, createRef, useEffect, RefObject} from 'react';
import {createRoot} from 'react-dom/client';
import {AllStudentsReportChartView} from '../components/view/components/all-students-report-chart-view';
import {Observable} from 'rxjs';

export function RenderStudentGraph(chartOptions): Observable<RefObject<HTMLDivElement>> {
	const chartRef = createRef<HTMLDivElement>();
	return new Observable((observer) => {
		const Graph = () => {
			useEffect(() => {
				observer.next(chartRef);
				observer.complete();
			}, []);

			return <AllStudentsReportChartView
				chartRef={chartRef}
				chartOptions={chartOptions}
			/>;
		};
		const container = document.getElementById('hidden-students-graph-container');
		const element = document.createElement('div');
		container.append(element);
		const root = createRoot(element);
		root.render(createElement(Graph));
	});
}

export function GetStudentReportChartBase64(chartRef: RefObject<HTMLDivElement>): Observable<string> {
	const svg = chartRef.current?.getElementsByTagName('svg')[0];
	const canvas = document.createElement('canvas');
	canvas.width = svg?.clientWidth || 900;
	canvas.height = svg?.clientHeight || 400;
	const ctx = canvas.getContext('2d');
	const img = document.createElement('img');

	let base64 = '';
	try {
		base64 = btoa(svg?.outerHTML);
	} catch {
		base64 = btoa(encodeURIComponent(svg?.outerHTML));
	}

	return new Observable((observer) => {
		img.onload = function () {
			ctx.drawImage(img, 0, 0);
			const data = canvas.toDataURL();
			img.remove();
			canvas.remove();
			chartRef.current?.parentElement.remove();

			observer.next(data.slice(22));
			observer.complete();
		};

		img.src = 'data:image/svg+xml;base64,' + base64;
	});
}