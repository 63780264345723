import {StudentSort} from '@esgi/core/enums';

export class StudentSorter {
	private static thenBySort(sort: StudentSort) {
		if (this.sortBy(sort) === 'lastName') {
			return 'firstName';
		}

		return 'lastName';
	}

	private static sortBy(sort: StudentSort) {
		return sort === StudentSort.LastName ? 'lastName' : 'firstName';
	}

	public static sortStudents<T>(
		students: T[],
		sort: StudentSort) {
		function sortBy(sortBy: string, thenBy) {
			return (a: T, b: T) => {
				if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
					return -1;
				}

				if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
					return 1;
				}

				if (a[thenBy].toLowerCase() < b[thenBy].toLowerCase()) {
					return -1;
				}

				if (a[thenBy].toLowerCase() > b[thenBy].toLowerCase()) {
					return 1;
				}

				return 0;
			};
		}

		const sortFunction = sortBy(this.sortBy(sort), this.thenBySort(sort));

		return students.sort(sortFunction);
	}
}