import * as React from 'react';
import type {SVGProps} from 'react';

export function ClassLink(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='98' height='16' viewBox='0 0 98 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M30.4488 6.37337C30.4488 4.3191 30.0274 3.43129 28.7469 3.43129C27.2997 3.43129 26.96 4.86669 26.96 8.86183C26.96 13.1227 27.548 13.8906 28.7959 13.8906C29.8249 13.8906 30.5827 13.356 30.5827 10.3134H33.4476C33.4476 13.356 32.6897 15.9773 28.9331 15.9773C24.6374 15.9773 24.0984 12.9347 24.0984 8.6577C24.0984 4.38066 24.6374 1.33813 28.9331 1.33813C33.2287 1.33813 33.33 4.31262 33.33 6.36689H30.4488V6.37337Z' fill='#0A4D7F'/>
            <path d='M34.6758 1.61353H37.423V15.6824H34.6758V1.61353Z' fill='#0A4D7F'/>
            <path d='M38.9387 8.3466V8.09711C38.9387 5.65725 40.6243 4.82129 42.6953 4.82129C45.962 4.82129 46.5696 6.15948 46.5696 8.08091V13.6605C46.5696 14.6455 46.6382 15.0959 46.9061 15.7148H44.2797C44.0935 15.381 44.0281 14.963 43.9432 14.6131H43.9105C43.2703 15.7148 42.4961 15.9999 41.1829 15.9999C39.2784 15.9999 38.6055 14.3798 38.6055 12.9574C38.6055 10.9679 39.4156 10.1514 41.3005 9.64915L42.8489 9.23117C43.659 9.01408 43.9432 8.69654 43.9432 7.99342C43.9432 7.18986 43.5904 6.69087 42.679 6.69087C41.8362 6.69087 41.4148 7.2417 41.4148 8.07767V8.379L38.9387 8.3466ZM43.9432 10.3847C43.5741 10.6698 43.1004 10.8351 42.7639 10.9193C41.6533 11.1526 41.2155 11.6872 41.2155 12.6398C41.2155 13.4434 41.5193 14.1109 42.2445 14.1109C42.9697 14.1109 43.9301 13.6443 43.9301 12.4746L43.9465 10.3847H43.9432Z' fill='#0A4D7F'/>
            <path d='M50.1238 12.2219V12.5719C50.1238 13.4921 50.6302 14.1077 51.5546 14.1077C52.3974 14.1077 52.9528 13.6897 52.9528 12.8538C52.9528 12.1701 52.4987 11.8525 51.9597 11.6517L50.0226 10.968C48.5068 10.4333 47.7326 9.51313 47.7326 8.00969C47.7326 6.25675 48.8433 4.81812 51.6755 4.81812C54.168 4.81812 55.3309 5.91978 55.3309 7.825V8.25918H52.8711C52.8711 7.18993 52.551 6.6553 51.6592 6.6553C50.9699 6.6553 50.3623 7.05708 50.3623 7.7926C50.3623 8.31103 50.6334 8.74521 51.5579 9.03035L53.2076 9.58118C54.9422 10.1482 55.5661 11.0166 55.5661 12.6237C55.5661 14.8465 53.8968 15.9481 51.5906 15.9481C48.5068 15.9481 47.6477 14.6099 47.6477 12.5719V12.2057L50.1238 12.2219Z' fill='#0A4D7F'/>
            <path d='M58.9503 12.2219V12.5719C58.9503 13.4921 59.4566 14.1077 60.3811 14.1077C61.2239 14.1077 61.7792 13.6897 61.7792 12.8538C61.7792 12.1701 61.3251 11.8525 60.7861 11.6517L58.849 10.968C57.3333 10.4333 56.5591 9.51313 56.5591 8.00969C56.5591 6.25675 57.6697 4.81812 60.5019 4.81812C62.9944 4.81812 64.1573 5.91978 64.1573 7.825V8.25918H61.6975C61.6975 7.18993 61.3774 6.6553 60.4856 6.6553C59.7963 6.6553 59.1887 7.05708 59.1887 7.7926C59.1887 8.31103 59.4599 8.74521 60.3843 9.03035L62.034 9.58118C63.7686 10.1482 64.3925 11.0166 64.3925 12.6237C64.3925 14.8465 62.7233 15.9481 60.417 15.9481C57.3333 15.9481 56.4741 14.6099 56.4741 12.5719V12.2057L58.9503 12.2219Z' fill='#0A4D7F'/>
            <path d='M65.8594 15.6985V1.62964H68.7242V13.3753H73.7941V15.6985H65.8594Z' fill='#0A4D7F'/>
            <path d='M75.0713 1.46118H77.8186V3.78439H75.0713V1.46118ZM75.0713 5.08694H77.8186V15.6985H75.0713V5.08694Z' fill='#0A4D7F'/>
            <path d='M79.3505 5.08707H81.9769V6.32482H82.0096C82.5813 5.28796 83.5776 4.80518 84.7373 4.80518C86.4555 4.80518 87.4649 5.72539 87.4649 7.81206V15.6825H84.7177V8.37909C84.7177 7.30983 84.3649 6.90805 83.5384 6.90805C82.6107 6.90805 82.0913 7.47508 82.0913 8.66099V15.6954H79.344V5.08707H79.3505Z' fill='#0A4D7F'/>
            <path d='M91.7346 1.61353V9.04973H91.7672L94.5308 5.09024H97.5459L94.4622 9.26683L98 15.6986H94.9849L92.695 11.3373L91.7182 12.5912V15.6824H88.9709V1.61353H91.7346Z' fill='#0A4D7F'/>
            <path d='M6.55951 9.44824C5.68404 10.4333 6.55951 10.9193 6.55951 10.9193C6.59217 10.9355 6.62811 10.9517 6.66077 10.9517C6.52684 10.4171 6.51051 9.91483 6.55951 9.44824Z' fill='#0A4D7F'/>
            <path d='M12.2861 10.7348C11.5969 11.2013 10.8031 12.7404 10.8031 12.7404H10.3817C10.6528 12.1572 10.8194 11.6712 10.9207 11.2694C10.8194 11.3018 10.7508 11.318 10.7508 11.318C9.92433 11.6031 9.08153 11.736 8.34326 11.7522C9.1338 12.9219 10.5679 13.1228 10.5679 13.1228C12.5736 13.3917 14.1057 12.303 14.9125 11.5027C13.2269 10.5825 12.2861 10.7348 12.2861 10.7348Z' fill='#0A4D7F'/>
            <path d='M18.7903 6.44123C18.7903 6.44123 20.1198 3.18161 17.0883 0.942643C17.0883 0.942643 12.2373 -2.23273 9.52599 3.06496C9.52599 3.06496 7.58886 1.14353 5.19766 2.63078C3.14292 3.90093 3.49572 5.63766 3.68193 6.48983C3.68193 6.48983 -0.176008 7.61093 0.0101917 11.7033C0.144125 14.7264 3.11026 15.6466 4.25686 15.8799C4.50839 15.9285 4.76319 15.9641 5.01472 15.9641H17.6992C17.9507 15.9641 18.1892 15.9479 18.4407 15.8961C19.6037 15.6628 22.6025 14.7426 22.5502 11.5186C22.4816 7.52344 18.7935 6.43799 18.7935 6.43799L18.7903 6.44123ZM10.4668 13.7608C7.90573 13.7284 6.98126 11.8232 6.98126 11.8232C6.93226 11.7227 6.87999 11.6223 6.84732 11.5218C6.13846 11.2529 5.71706 10.7701 5.81832 10.0346C5.91959 9.29906 6.76239 8.56354 6.76239 8.56354C7.25239 7.0601 8.34672 6.03944 8.34672 6.03944C8.34672 6.03944 9.27446 5.07063 10.2675 4.5684L12.0185 4.83734C8.05926 6.44123 7.85672 9.74945 7.85672 9.74945C7.82406 10.2517 7.88939 10.6697 8.00699 11.0358C8.31079 11.0196 8.51332 10.9872 8.51332 10.9872C9.49006 10.8381 10.3329 10.5692 11.0581 10.2679C11.0417 9.6166 10.7379 9.44811 10.7379 9.44811C10.7379 9.44811 10.3851 9.04633 8.90206 8.79683L9.03599 8.59594C9.03599 8.59594 9.76119 8.76443 10.5844 8.59594C11.4762 8.42745 12.3027 7.81182 13.2304 6.55787L13.6681 6.54167C12.7763 7.72758 12.7077 8.61215 12.858 9.19862C13.7498 8.53114 14.1712 7.96087 14.1712 7.96087C15.7882 5.72191 13.5473 6.07185 13.5473 6.07185L14.2202 5.28772C17.6404 6.02324 14.5077 9.09817 14.5077 9.09817C14.1385 9.46431 13.7335 9.78185 13.3447 10.067C13.3937 10.135 14.0013 10.7345 15.1316 11.2691C15.4354 10.9516 15.5857 10.7183 15.5857 10.7183L16.0397 10.8025C13.3611 14.0103 10.4635 13.7608 10.4635 13.7608H10.4668ZM11.6787 7.39384C11.6624 7.75998 11.3586 8.06131 10.9699 8.07752C10.5485 8.07752 10.212 7.74378 10.2283 7.32579C10.2447 6.95965 10.5485 6.65832 10.9372 6.64212C11.3586 6.64212 11.6951 6.97585 11.6787 7.39384Z' fill='#0A4D7F'/>
        </svg>
	);
}
