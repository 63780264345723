import {TestColorIndicator, GradeLevelsMap} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {TestTypeLabel} from '@esgi/main/features/teacher/home';
import {Button, SelfAsses, Tooltip} from '@esgi/ui';
import {TestInfo as TestInfoModel} from '../../types';
import {Divider, GradeIcon, IconWrapper, TestDetails, TestInfoBox, TestName} from './index.styled';
import {lazyComponent} from '@esgi/core/react';
import {useCallback, useState} from 'react';
import {TestType} from '@esgi/main/libs/core';
import {useUser} from '@esgi/core/authentication';
import {testContentAreaTranscript} from './constants';
import {MultipleLabels} from '../multiple-labels';

const TestDetailsAlert = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetailsAlert = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

type TestInfoProps = {
	testID: number;
	testInfo: TestInfoModel;
	onTestEdited: () => void;
};

export function TestInfo({testID, testInfo, onTestEdited}: TestInfoProps) {
	const user = useUser();

	const {name, testType, creatorName, gradeLevels, stateStandards, selfAssessVersion, description, contentArea} =
		testInfo;

	const [isTestDetailsOpen, setIsTestDetailsOpen] = useState(false);

	const closeTestDetails = useCallback(() => {
		setIsTestDetailsOpen(false);
		onTestEdited();
	}, [onTestEdited]);

	const userStateStandard = stateStandards.filter(({stateID}) => stateID === user.stateID);
	const commonStandards = stateStandards.filter(({stateID}) => stateID === 0);

	const standards = userStateStandard.length ? userStateStandard : commonStandards;

	return (
		<>
			<TestInfoBox>
				<TestName>
					<Tooltip>
						<Tooltip.Trigger>
							<TestColorIndicator testID={testID} />
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>{testContentAreaTranscript[testInfo.contentArea]}</Tooltip.Content>
					</Tooltip>
					<Button.Text onClick={() => setIsTestDetailsOpen(true)} data-cy='test-name'>
						<Text size='xxLarge' color='neutral16'>
							{name}
						</Text>
					</Button.Text>
				</TestName>
				<TestDetails>
					<GridBox flow='column' align='center' gap={1}>
						<Text size='xSmall' font='mono' color='lowContrast'>
							Type:
						</Text>
						<TestTypeLabel testType={testType} />
					</GridBox>
					<GridBox flow='column' align='center' gap={1}>
						<Text size='xSmall' font='mono' color='lowContrast' data-cy='test-author-label'>
							Author:
						</Text>
						<Text size='xSmall' font='mono' data-cy='test-author'>
							{creatorName}
						</Text>
					</GridBox>
					<GridBox flow='column' align='center' gap={1}>
						<Text size='xSmall' font='mono' color='lowContrast' data-cy='test-standard-label'>
							Standards:
						</Text>
						<MultipleLabels items={standards.map(s => s.name)}/>
					</GridBox>
					<GridBox flow='column' align='center' gap={1}>
						<Text size='xSmall' font='mono' color='lowContrast' data-cy='test-content-area-label'>
							Content Area:
						</Text>
						<Text size='xSmall' font='mono' data-cy='test-content-area'>
							{testContentAreaTranscript[testInfo.contentArea]}
						</Text>
					</GridBox>
					<GridBox flow='column' align='center' gap={1}>
						<Text size='xSmall' font='mono' color='lowContrast' data-cy='test-grade-label'>
							Grade Level:
						</Text>
						{gradeLevels?.map((gradeLevel, index) => (
							<GradeIcon key={index} dataCy='test-grade'>
								<Text size='xSmall' font='mono' color='neutral40'>
									{GradeLevelsMap[gradeLevel].shortName}
								</Text>
							</GradeIcon>
						))}
					</GridBox>
					<GridBox flow='column' align='center' gap={1}>
						<Text size='xSmall' font='mono' color='lowContrast' data-cy='test-additional-info-label'>
							Additional Info:
						</Text>
						{selfAssessVersion ? (
							<IconWrapper>
								<SelfAsses/>
							</IconWrapper>
						) : (
							<Text size='xSmall' font='mono' data-cy='test-additional-info'>
								N/A
							</Text>
						)}
					</GridBox>
				</TestDetails>
				<Divider />
				<Text size='medium' color='neutral40' data-cy='test-description'>
					{description}
				</Text>
			</TestInfoBox>
			{isTestDetailsOpen && (testType === TestType.Score || testType === TestType.YN) && (
				<TestDetailsAlert
					testID={testID}
					firstName={user.firstName}
					lastName={user.lastName}
					userID={user.userID}
					close={closeTestDetails}
					disableCopyTest
				/>
			)}

			{isTestDetailsOpen && testType === TestType.Rubric && (
				<RubricDetailsAlert testID={testID} disableCopy disableAddToSubject close={closeTestDetails} />
			)}
		</>
	);
}
