import {styled} from '@stitches/react';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const ReportHeaderStyled = styled(FlexBox, {
	alignItems: 'center',
	paddingRight: '25px',
});

export const LabelStyled = styled(Text, {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	fontWeight: 600,
});

export const TextStyled = styled(FlexBox, {
	marginRight: '10px',
	marginLeft: '5px',
});