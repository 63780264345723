import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {CheckAll} from '@esgi/ui';

export const Container = styled(GridBox, {
	gridGap: 0,
	gridTemplateRows: 'auto',
	paddingTop: 20,
	paddingLeft: 20,
	overflow: 'hidden',
});

export const Row = styled(GridBox, {
	gap: 12,
	gridAutoFlow: 'column',
});

export const HeaderCell = styled(FlexBox, {
	height: 24,
	alignItems: 'center',
});

export const SkeletonRow = styled(Box, {
	...SkeletonStyles,
	height: 24,
	width: '100%',
	borderRadius: 4,
	margin: '4px 0',
});

export const SkeletonCell = styled(Box, {
	...SkeletonStyles,
	height: 24,
	width: '100%',
	borderRadius: 4,
	margin: '4px 0',

	variants: {
		resetMargin: {
			true: {
				margin: 'unset',
			},
		},
	},
});

export const CheckAllIcon = styled(CheckAll, {
	cursor: 'pointer',
	variants: {
		allChecked: {
			true: {
				'& path': {
					fill: '$primary',
				},
			},
		},
	},
});