import {Module} from '@esgi/deprecated/knockout';
import {UserInfo} from '@esgi/core/authentication';
import {ExportHistory} from 'shared/modules/export/history-module/export-history';
import {ManageTemplatesModule} from 'shared/modules/export/manage-templates/manage-templates';
import {Container} from 'shared/modules/export/export';
import React from 'react';
import './scheduled-export.less';

export class ScheduledModule extends Module {

	private userContext: UserInfo;
	private history: ExportHistory;
	private manage: ManageTemplatesModule;
	private rawContainer: Container;
	private initApi: () => JQueryPromise<Container>;

	constructor(userContext, history: ExportHistory, initApi: () => JQueryPromise<Container>) {
		super();
		this.userContext = userContext;
		this.history = history;
		this.initApi = initApi;
		this.manage = new ManageTemplatesModule(userContext, () => {
			return $.Deferred<Container>().resolve(this.rawContainer).promise();
		}, {allowSelectTemplate: false, showScheduleOptions: true});
	}

	load() {
		return this.initApi().then((model) => {
			this.rawContainer = model;
			return this.manage.load();
		});
	}

	template = () => <div className='scheduled-export'>
		<ko data-bind='render: manage'>
		</ko>
		<div style={{fontWeight: 'bold', margin: '10px 0 10px 0'}}>
			Report History
		</div>
		<div id='export-history'>
			<ko data-bind='render: history'>
			</ko>
		</div>
	</div>
}
