import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const StyledAvatarFilterLayer = styled(Box, {
	width: '100%',
	height: '100%',
	position: 'absolute',
	opacity: '20%',

	variants: {
		filterColor: {
			primary: {
				background: '$primary92',
			},
			secondary: {
				background: '$secondary92',
			},
			neutral: {
				background: '$border',
			},
		},
	},
});
