import {Panel} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {Title} from './title';

interface Props {
	downloadTitle?: string;
	disabled?: boolean;
	onClick: VoidFunction;
}

export function DownloadPanel(props: Props) {
	const {
		downloadTitle = 'Report',
		disabled = false,
	} = props;

	return (
		<Panel>
			<Title/>
			<Button color='primary' disabled={disabled} onClick={props.onClick} css={{whiteSpace: 'nowrap'}}>
				<Text size='large' bold>{downloadTitle}</Text>
			</Button>
		</Panel>
	);
}
