import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const TimeMeridiem = styled(FlexBox, {
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$muted',
	backgroundColor: '$muted',
	borderRadius: 2,
	alignItems: 'center',
});

export const TimeText = styled(Text, {
	paddingTop: 3,
	paddingBottom: 3,
	paddingLeft: 5,
	paddingRight: 5,
	backgroundColor: '$vivid',
	borderTopRightRadius: 2,
	borderTopLeftRadius: 2,
});

export const MeridiemText = styled(Text, {
	paddingTop: 3,
	paddingBottom: 3,
	paddingLeft: 5,
	paddingRight: 5,
});
