export interface SortModel {
  fieldName: string;
  direction: string;
  relatedSorts?: SortModel[];
}

export interface InitResponse {
  report: Report;
  sortModel: SortModel;
  cacheKey: string;
}

export interface Report {
  districtID: number;
  districtName: string;
  reportDateTime: string;
  rows: Row[];
  sortRules: any;
}

export interface Row {
	id?: number;
	userID: string;
	lastLoginDate: Date;
	lastLoginDateStr: string;
	expDate: string;
	expDateStr: string;
	firstName: string;
	lastName: string;
	loginCount: number;
	questionsAsked: number;
	schoolName: string;
	studentsEnrolled: number;
	studentsTested: number;
	teacher: string;
	testsAdministered: number;
	testsAuthored: number;
	testsCompleted: number;
}

export class SortCell {
	constructor(
    public name: string,
    public displayName: string,
	) {}
}
