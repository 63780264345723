import {HiddenInput} from './index.styled';

interface Props {
	type?: 'text' | 'password' | 'number' | 'email' | 'tel';
	name?: string
}

export const PasswordManagerDisabler = ({type='text', name}: Props) => {
	return <HiddenInput
			autoComplete='off'
			name={`${name}_disable-pwd-mgr`}
			type={type} id={`${name}_disable-pwd-mgr`}
			value={`${name}_disable-pwd-mgr`} />;
};
