import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Root = styled(GridBox, {
	gridTemplateColumns: '272px 1fr 400px',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	background: '$background',
});
