import React from 'react';
import {StudentResult} from '../../../../../../types/table-level-report-service-types';
import {
	TableCellStyled, TableColumnStyled, TableColumnWrapperStyled,
	TableHeadRowStyled,
	TableRoot,
	TableRowDarkBodyStyled,
	TableRowLightBodyStyled,
	TableStyled,
	TableWrapper,
} from './index.styled';
import {Column, FixedColumnPosition, HeaderCell} from '../../../../../../service/types';

interface Props {
  rows: StudentResult[];
  columns: Column<StudentResult>[];
}

export function Table(props: Props) {
	const renderHeaderColumn = (column: Column<StudentResult>, nextColumn: Column<StudentResult>, headerCells: HeaderCell[], rowNumber: number) => {
		const classNames = {
			light: rowNumber % 2 === 0,
			className: column.className,
			isRightFixed: column.fixedColumnPosition === FixedColumnPosition.Right,
			lastsBeforeRight: nextColumn && nextColumn.fixedColumnPosition === FixedColumnPosition.Right,
		};

		if (!headerCells) {
			return <TableColumnWrapperStyled {...classNames}>
        <TableColumnStyled></TableColumnStyled>
      </TableColumnWrapperStyled>;
		}

		if (headerCells.length === 1) {
			const cell = headerCells[0];
			return <TableColumnWrapperStyled {...classNames} header={true}>
        <TableColumnStyled className={cell.className}>{cell.renderer()}</TableColumnStyled>
      </TableColumnWrapperStyled>;
		} else {
			return <TableColumnWrapperStyled {...classNames} header={true}>
        {headerCells.map((cell, i) => {
          return <TableColumnStyled key={i} className={cell.className}>{cell.renderer()}</TableColumnStyled>;
        })}
      </TableColumnWrapperStyled>;
		}
	};

	const renderBodyColumn = (column: Column<StudentResult>, nextColumn: Column<StudentResult>, row: StudentResult, rowNumber: number) => {
		const cellClassNames = {
			dark: rowNumber % 2 !== 0,
			isLeftFixed: column.fixedColumnPosition === FixedColumnPosition.Left,
			isRightFixed: column.fixedColumnPosition === FixedColumnPosition.Right,
		};

		const columnClassNames = {
			isRightFixed: column.fixedColumnPosition === FixedColumnPosition.Right,
			lastsBeforeRight: nextColumn && nextColumn.fixedColumnPosition === FixedColumnPosition.Right,
			className: column.className,
		};

		if (column.cells.length === 1) {
			const cell = column.cells[0];
			return <TableCellStyled {...cellClassNames}>
        <TableColumnWrapperStyled {...columnClassNames}>
          <TableColumnStyled className={cell.className}>{cell.renderer(row)}</TableColumnStyled>
        </TableColumnWrapperStyled>
      </TableCellStyled>;
		} else {
			return <TableCellStyled {...cellClassNames}>
        <TableColumnWrapperStyled {...columnClassNames}>
          {column.cells.map((cell, i) => {
            return <TableColumnStyled key={i} className={cell.className}>{cell.renderer(row)}</TableColumnStyled>;
          })}
        </TableColumnWrapperStyled>
      </TableCellStyled>;
		}
	};

	return <TableRoot>
    <TableWrapper>
      <TableStyled>
        <thead>
          <tr>
            {props.columns.map((column, i) => {
              return <TableHeadRowStyled key={`column_${i}`}
																				 isLeftFixed={column.fixedColumnPosition === FixedColumnPosition.Left}
																				 isRightFixed={column.fixedColumnPosition === FixedColumnPosition.Right}>
                {column.headers.map((headerCells, j) => {
                  return renderHeaderColumn(column, props.columns[i + 1], headerCells, j);
                })}
              </TableHeadRowStyled>;
            })}
          </tr>
        </thead>
        <tbody>
          {props.rows.map((row, i) => {
            if (i % 2 === 0) {
              return <TableRowLightBodyStyled>
                {props.columns.map((column, j) => {
                  return renderBodyColumn(column, props.columns[j + 1], row, i);
                })}
              </TableRowLightBodyStyled>;
            } else {
              return <TableRowDarkBodyStyled>
                {props.columns.map((column, j) => {
                  return renderBodyColumn(column, props.columns[j + 1], row, i);
                })}
              </TableRowDarkBodyStyled>;
            }
          })}
        </tbody>
      </TableStyled>
    </TableWrapper>
  </TableRoot>;
}