import {TabItem} from '@esgi/main/features/teacher/home';


export function useTabItems<T extends string>(items: Pick<TabItem<T>, 'value' | 'label' | 'dataCy'>[]): TabItem<T>[] {
	return items.map(t => ({
		value: t.value,
		label: t.label,
		dataCy: t.dataCy,
		valueDescription: 'test',
		applyDisabledStyles: true,
		permitted: true,
		contentType: 'text',
	}));
}