import {useValue} from '../hooks';
import {FontType, FontSizeType, FontStyleType, ChangeFunction} from '../types';
import {Section, Title, Label, SelectRoot, SelectContent, SelectField, SelectOption} from './index.styled';
import {fontSizes, fontStyles, getFontSize, getFontStyle} from './utils';

interface Props {
	disabled?: boolean;
	onChange: ChangeFunction<FontType>;
}

export function FontControl({disabled, onChange}: Props) {
	const [fontSize, setFontSize] = useValue<FontSizeType>(
		FontSizeType.Small,
		(fontSize) => onChange({fontSize, fontStyle}),
	);
	const [fontStyle, setFontStyle] = useValue<FontStyleType>(
		FontStyleType.Default,
		(fontStyle) => onChange({fontSize, fontStyle}),
	);

	return (
		<Section>
			<Title size='small' font='mono' color='lowContrast' data-cy='font-title'>Font</Title>
			<SelectRoot
				value={fontSize}
				disabled={disabled}
				onValueChange={(value) => setFontSize(getFontSize(value))}
				dataCy='font-size-select'
			>
				<SelectField placeholder='Font Size' data-cy='font-size'/>
				<SelectContent>
					{fontSizes.map(({label, value}) => (
						<SelectOption
							key={value}
							value={value}
						>
							<Label size='medium' bold>{label}</Label>
						</SelectOption>
					))}
				</SelectContent>
			</SelectRoot>
			<SelectRoot
				value={fontStyle}
				disabled={disabled}
				onValueChange={(value) => setFontStyle(getFontStyle(value))}
				dataCy='font-style-select'
			>
				<SelectField placeholder='Font Style' data-cy='font-style'/>
				<SelectContent>
					{fontStyles.map(({label, value}) => (
						<SelectOption
							key={value}
							value={value}
						>
							<Label size='medium' bold>{label}</Label>
						</SelectOption>
					))}
				</SelectContent>
			</SelectRoot>
		</Section>
	);
}
