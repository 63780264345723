import {BoxType} from './models';
import {UserType} from '@esgi/core/authentication';

export class HierarchyStudentAdd {
	schoolID: number;
	userID: number;
	userType: UserType;
	classID: number;
	groupID: number;
	specialistGroupID: number;

	constructor(init: {
		schoolID: number,
		userID: number,
		userType: UserType,
		classID: number,
		groupID: number,
		specialistGroupID: number
	}) {
		this.schoolID = init.schoolID;
		this.userID = init.userID;
		this.userType = init.userType;
		this.classID = init.classID;
		this.groupID = init.groupID;
		this.specialistGroupID = init.specialistGroupID;
	}
}

export class HierarchyStudentEdit {
	userID: number;
	userType: UserType;
	studentID: number;

	constructor(init: { userID: number, userType: UserType, studentID: number }) {
		this.userType = init.userType;
		this.userID = init.userID;
		this.studentID = init.studentID;
	}
}

export namespace HierarchyEvents {
	export namespace Class {
		export function Edit(id: number, teacherID: number, name: string, countClasses: number): EditArgs {
			return {
				id: id,
				teacherID: teacherID,
				countClasses: countClasses,
				name: name,
			};
		}

		export interface EditArgs {
			id: number;
			name: string;
			teacherID: number;
			countClasses: number;
		}

		export function Add() {
		}

		export interface AddArgs {
			teacherID: number;
			countClasses: number;
		}
	}

	export namespace Group {

		export interface BaseArgs {
			classID: number;
			className: string;
			teacherID: number;
		}

		export function Edit() {
		}

		export interface EditArgs extends BaseArgs {
			id: number;
			name: string;
		}

		export function Add() {
		}

		export interface AddArgs extends BaseArgs {

		}
	}

	export namespace SchoolsGroup {
		export class Edit {
			districtID: number;
			userID: number;
			id: number;
			name: string;
		}

		export class Add {
			districtID: number;
			userID: number;
		}
	}

	export namespace TeachersGroup {
		export class Edit {
			districtID: number;
			schoolID: number;
			userTypes: UserType[];
			id: number;
			name: string;
		}

		export class Add {
			districtID: number;
			schoolID: number;
			userTypes: UserType[];
			userID: number;
		}
	}

	export namespace GroupOfSpecialists {

		export class Edit {
			districtID: number;
			schoolID: number;
			userTypes: UserType[];
			id: number;
			name: string;
		}

		export class Add {
			districtID: number;
			schoolID: number;
			userTypes: UserType[];
			userID: number;
		}
	}

	export namespace School {
		export function Edit() {

		}

		export interface EditArgs {
			id: number;
		}

		export function Add() {

		}

		export interface AddArgs {

		}
	}

	export namespace Teacher {
		export function Edit() {

		}

		export interface EditArgs {
			id: number;
		}

		export function Add() {

		}

		export interface AddArgs {

		}
	}

	export namespace SchAdmin {
		export function Add() {

		}

		export interface AddArgs {

		}
	}

	export namespace SpecialGroup {
		export function Add() {

		}

		export interface AddArgs {
			numberOfGroups: number;
			userID: number;
			userType: UserType;
		}

		export function Edit() {
		}

		export interface EditArgs {
			id: number;
			name: string;
			userID: number;
			numberOfGroups: number;
			userType: UserType;
		}
	}

	export namespace Common {
		export function Toggled() {

		}

		export interface ToggledArgs {
			boxType: BoxType,
			open: boolean
		}

		export function ChangedOutside() {

		}
	}

}

export class SchAdminEditEvent {
	constructor(public id: number) {
	}
}

export class HierarchyLoadedEvent {

}
