import {Dispatch, useMemo} from 'react';
import {usePagination} from '../../../../../kit';
import {ChartItem} from '../types';
import {TestSession} from '../../../../../types';

export function useVisibleData({
	pageSize,
	data,
	sessions,
	pageIndex,
	onPageIndexChanged,
}: {
	pageSize: number;
	data: ChartItem[];
	sessions: TestSession[];
	pageIndex: number;
	onPageIndexChanged: Dispatch<number>;
}) {
	const {currentPageIndex, totalPages, nextPage, previousPage} = usePagination({
		totalElements: data.length,
		pageSize,
		pageIndex,
		onChange: onPageIndexChanged,
	});

	const sessionNote = useMemo(() => {
		if (sessions.length === 1) {
			return sessions[0]?.notes ?? null;
		}

		return null;
	}, [sessions]);

	const visibleData = useMemo<(ChartItem | null)[]>(() => {
		const nextNotVisibleIndex = currentPageIndex * pageSize;

		const visibleItems = data.slice(nextNotVisibleIndex, nextNotVisibleIndex + pageSize);

		if (visibleItems.length === pageSize || totalPages === 1) {
			return visibleItems;
		}

		return [...visibleItems, ...new Array(pageSize - visibleItems.length).fill(null)];
	}, [currentPageIndex, data, totalPages, pageSize]);

	return {
		visibleData,
		currentPageIndex,
		totalPages,
		nextPage,
		previousPage,
		sessionNote,
	};
}
