import {PropsWithChildren, useCallback, useState} from 'react';
import {GroupMultipleProvider, GroupMultipleProviderType} from './components/group-multiple-provider';
import {GroupSingleProvider, GroupSingleProviderType} from './components/group-single-provider';

type SelectableListGroupRootProps = PropsWithChildren<GroupTypeSingle | GroupTypeMultiple>;

type GroupTypeSingle = {
	/** Determines whether a single item can be pressed at a time. */
	type?: 'single' | undefined;
} & GroupSingleProviderType;

type GroupTypeMultiple = {
	/** Determines whether a miltiple items can be pressed at a time. */
	type: 'multiple';
} & GroupMultipleProviderType;

export function SelectableListGroupRoot({children, ...props}: SelectableListGroupRootProps) {
	const [valuesVocabulary, setValuesVocabulary] = useState<string[]>([]);

	const addValueToVocabulary = useCallback((value: string) => {
		setValuesVocabulary((currentVocabulary) => [...currentVocabulary, value]);
	}, []);

	const removeValueFromVocabulary = useCallback((value: string) => {
		setValuesVocabulary((currentVocabulary) => currentVocabulary.filter((currentValue) => currentValue !== value));
	}, []);

	if (props.type === 'multiple') {
		const {value, defaultValue, onValueChange} = props;

		return (
			<GroupMultipleProvider
				value={value}
				defaultValue={defaultValue}
				valuesVocabulary={valuesVocabulary}
				addValueToVocabulary={addValueToVocabulary}
				removeValueFromVocabulary={removeValueFromVocabulary}
				onValueChange={onValueChange}
			>
				{children}
			</GroupMultipleProvider>
		);
	}

	const {value, defaultValue, onValueChange} = props;

	return (
		<GroupSingleProvider
			value={value}
			defaultValue={defaultValue}
			addValueToVocabulary={addValueToVocabulary}
			removeValueFromVocabulary={removeValueFromVocabulary}
			valuesVocabulary={valuesVocabulary}
			onValueChange={onValueChange}
		>
			{children}
		</GroupSingleProvider>
	);
}