import {OptionItem} from '@esgi/main/features/standards-drawer';
import {DrawerType} from '../../../service/types';
import {AuthorSelectItem} from './author-select-item';
import {SeriesSelectItem} from './series-select-item';
import React from 'react';

type Props = {
	type: DrawerType;
	item: OptionItem
}

export function ListItem({type, item: {label, title, description, imageSet, value}}: Props) {
	if (type === DrawerType.AUTHORS) {
		return (
				<AuthorSelectItem label={label} value={value} description={description} imageSet={imageSet} title={title} />
		);
	}

	if (type === DrawerType.SERIES) {
		return (
				<SeriesSelectItem label={label} value={value} description={description} />
		);
	}

	return null;
}