import {join} from '@esgillc/ui-kit/utils';
import {assignmentStatusLabels} from '../../../../../../typings/assignment';
import styles from './styles.module.less';

interface Props {
	status: string;
}

export const AssignmentStatus = ({status}: Props): JSX.Element => (
	<div className={join(styles[status], styles.status)}>
		{assignmentStatusLabels[status]}
	</div>
);
