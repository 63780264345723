import {DatePicker} from '@esgi/ui/controls';
import {DatePickerCalendarPanel, DatePickerWrapper} from './index.styled';
import {Text} from '@esgi/ui/typography';
import moment from 'moment';

type Props = {
	disabled?: boolean;
	error?: string | undefined;
	onValueChange: (value: Date | undefined) => void;
	value?: Date | undefined | null;
	placeholder: string;
	isSliderUpdateDates?: boolean;
};

export function TrackDatePicker({disabled, error, value, onValueChange, placeholder, isSliderUpdateDates}: Props) {
	const handleValueChange = (values: Date[]) => {
		if (isSliderUpdateDates) {
			return;
		}

		if (!values.length) {
			onValueChange(undefined);

			return;
		}

		const date1 = moment(values[0]!);
		const date2 = moment(value);

		if (date1.diff(date2, 'days') !== 0) {
			onValueChange(values[0]);
		}
	};

	return (
		<DatePickerWrapper disabled={Boolean(disabled)} withError={Boolean(error)}>
			<DatePicker.Root value={value ? [value] : []} onChange={handleValueChange} error={Boolean(error)}>
				<DatePicker.DropdownTrigger>
					<DatePicker.Input placeholder={placeholder} />
				</DatePicker.DropdownTrigger>

				{!disabled && (
					<DatePicker.DropdownBody>
						<DatePickerCalendarPanel dataCy='datepicker-calendar' />
					</DatePicker.DropdownBody>
				)}
			</DatePicker.Root>

			{error && (
				<Text size='xSmall' font='mono' color='negativeVivid'>
					{error}
				</Text>
			)}
		</DatePickerWrapper>
	);
}
