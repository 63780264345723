import {Text} from '@esgi/ui/typography';
import {DeltaWrapper, DeltaIndicatorBox} from './index.styled';
import {useMemo} from 'react';
import {isNull} from 'underscore';
import {VariantProps} from '@stitches/react';

type Props = {
	caption?: string;
	value: number | null;
	withPersantageSign?: boolean;
	dataCy?: string
};

export function DeltaIndicator({value, caption, withPersantageSign, dataCy='delta-indicator'}: Props) {
	const {deltaValue, variant} = useMemo<{
		deltaValue: string;
		variant: NonNullable<VariantProps<typeof DeltaIndicatorBox>['variant']>;
	}>(() => {
		if (isNull(value)) {
			return {
				deltaValue: '-',
				variant: 'neutral',
			};
		}

		if (value === 0) {
			return {
				deltaValue: '0',
				variant: 'neutral',
			};
		}

		return {
			deltaValue: (value > 0 ? '+' : '-') + Math.abs(value).toFixed(1) + (withPersantageSign ? '%' : ''),
			variant: value < 0 ? 'error' : 'success',
		};
	}, [value, withPersantageSign]);

	return (
		<DeltaWrapper dataCy={dataCy}>
			<DeltaIndicatorBox variant={variant} dataCy='delta-value'>
				<Text size='small' font='mono'>
					{deltaValue}
				</Text>
			</DeltaIndicatorBox>

			{caption && (
				<Text size='xSmall' font='mono' data-cy='delta-caption'>
					{caption}
				</Text>
			)}
		</DeltaWrapper>
	);
}
