import {Text} from '@esgi/ui/typography';
import {TimePickerProps} from '../..';
import {Meridiem} from '../../types';
import {MeridiemCheapBox} from './index.styled';
import {RefObject} from 'react';

type Props = {
	inputVariantType: NonNullable<TimePickerProps['variantType']>;
	meridiemValue: Meridiem;
	inputDisabled?: boolean;
	inputHovered: boolean;
	inputErrored: boolean;
	inputRef: RefObject<HTMLInputElement>;
};

export function MeridiemCheap({
	inputVariantType,
	meridiemValue,
	inputHovered,
	inputDisabled,
	inputErrored,
	inputRef,
}: Props) {
	const textValue = meridiemValue === Meridiem.AM ? 'AM' : 'PM';

	return (
		<MeridiemCheapBox
			inputVariantType={inputVariantType}
			hovered={inputHovered}
			disabled={Boolean(inputDisabled)}
			errored={inputErrored}
			onClick={() => inputRef.current?.focus()}
		>
			<Text size='xxSmall' color='vivid'>
				{textValue}
			</Text>
		</MeridiemCheapBox>
	);
}
