import {useCallback, useRef} from 'react';
import {useTestSectionContext} from '../../../../context/test-section-context';
import {ContentWrapper} from '../view-mode/index.styled';
import {BottomSectionButton} from '../../../bottom-section-button';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {CardViewType, TestSectionContentType} from '../../../../types';
import {GroupStudentsRerrangeModeContent, SingleStudentRearrangeModeContent} from './types';
import {SingleStudentCards, SingleStudentCardsManager} from './components/single-student-cards';
import {GroupStudentsCards, GroupStudentsCardsManager} from './components/group-students-cards';

type Props = {
	cardViewType: CardViewType;
} & (SingleStudentRearrangeModeContent | GroupStudentsRerrangeModeContent);

export function RearrangeMode({cardViewType, ...contentProps}: Props) {
	const {setSectionViewMode} = useTestSectionContext();

	const sigleStudentCardsRef = useRef<SingleStudentCardsManager>(null);
	const groupStudentsCardsRef = useRef<GroupStudentsCardsManager>(null);

	const handleSaveClick = useCallback(() => {
		if (contentProps.contentType === TestSectionContentType.SingleStudent) {
			const {updateTestsOrder} = contentProps;

			const items = sigleStudentCardsRef.current?.getItems();

			updateTestsOrder(items ?? []);
		}

		if (contentProps.contentType === TestSectionContentType.GroupStudents) {
			const {updateTestsOrder} = contentProps;

			const items = groupStudentsCardsRef.current?.getItems();

			updateTestsOrder(items ?? []);
		}

		setSectionViewMode();
	}, [contentProps, setSectionViewMode]);

	const getCardsContent = () => {
		if (contentProps.contentType === TestSectionContentType.SingleStudent) {
			const {allTests, showSelfAssessOption} = contentProps;

			return (
				<SingleStudentCards
					allTests={allTests}
					cardViewType={cardViewType}
					showSelfAssessOption={showSelfAssessOption}
					cardsRef={sigleStudentCardsRef}
				/>
			);
		}

		if (contentProps.contentType === TestSectionContentType.GroupStudents) {
			const {allTests} = contentProps;

			return <GroupStudentsCards allTests={allTests} cardViewType={cardViewType} cardsRef={groupStudentsCardsRef} />;
		}

		return null;
	};

	return (
		<ContentWrapper>
			<OverlayScrollbarsComponent
				defer
				style={{height: 'calc(100% + 0px)'}}
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
			>
				{getCardsContent()}
			</OverlayScrollbarsComponent>

			<BottomSectionButton onClick={handleSaveClick} buttonText='Save' justifySelf='end' width='maxContent' withIcon />
		</ContentWrapper>
	);
}
