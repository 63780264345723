import {
	StudentSectionMode,
	getStudentSectionModePermissionValue,
	updateStudentSectionModePermissions,
} from '../../types';
import {WrapperBox} from './index.styled';
import {useEffect} from 'react';
import {StudentsPanelTabID, StudentsPanel, AllOptionItem} from '@esgi/main/features/teacher/students-panel';
import {DefaultContent} from './components/default-content';
import {EditableContent} from './components/editable-content';
import {RearrangeContent} from './components/rearrange-content';
import {updateGroupsOrderInClass} from '../../../../types';

type Props = {
	sectionMode: StudentSectionMode;
	canAddGroup: boolean;
	setViewMode: VoidFunction;
	getSectionModePermissionValue: getStudentSectionModePermissionValue;
	updateSectionModePermissions: updateStudentSectionModePermissions;
	updateGroupsOrderInClass: updateGroupsOrderInClass;
};

export function GroupsContent({
	sectionMode,
	canAddGroup,
	getSectionModePermissionValue,
	setViewMode,
	updateSectionModePermissions,
	updateGroupsOrderInClass,
}: Props) {
	const {
		entities: {classes, groups},
	} = StudentsPanel.useStudentsPanelContext();

	const {studentsWithoutGroup, groupsWithStudents, selectedClassID} = StudentsPanel.Tabs.Groups.useGroupsContext();

	useEffect(() => {
		const canEdit = Boolean(groupsWithStudents.length);
		const canRearrange =
			(Boolean(groupsWithStudents.length) && selectedClassID !== AllOptionItem.Value) || classes.length === 1;

		[
			{sectionMode: StudentSectionMode.Edit, possibleValue: canEdit},
			{sectionMode: StudentSectionMode.Rearrange, possibleValue: canRearrange},
		].forEach(({sectionMode, possibleValue}) => {
			if (getSectionModePermissionValue({sectionMode, tabId: StudentsPanelTabID.Groups}) !== possibleValue) {
				updateSectionModePermissions({sectionMode, tabId: StudentsPanelTabID.Groups, newPermissions: possibleValue});
			}
		});
	}, [studentsWithoutGroup, groupsWithStudents, classes, selectedClassID]);

	const getGroupsContent = () => {
		if (sectionMode === StudentSectionMode.Rearrange) {
			return (
				<RearrangeContent
					setViewMode={setViewMode}
					selectedClassId={selectedClassID}
					updateGroupsOrderInClass={updateGroupsOrderInClass}
					groupsWithStudents={groupsWithStudents}
				/>
			);
		}

		if (sectionMode === StudentSectionMode.Edit) {
			return (
				<EditableContent
					setViewMode={setViewMode}
					groupsWithStudents={groupsWithStudents}
					studentsWithoutGroup={studentsWithoutGroup}
				/>
			);
		}

		return <DefaultContent canAddGroup={canAddGroup} groupsWithStudents={groupsWithStudents} />;
	};

	const isDisplayClassesSelect = Boolean(classes.length || groups.length);

	return (
		<WrapperBox withClassesSelect={isDisplayClassesSelect}>
			{isDisplayClassesSelect && (
				<StudentsPanel.Tabs.Groups.ClassSelect disabled={sectionMode !== StudentSectionMode.View} />
			)}

			{getGroupsContent()}
		</WrapperBox>
	);
}
