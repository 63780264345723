import {Assignment} from '../types';

export class UpdateAssignmentInList {
	constructor(public assignmentId: Assignment['id'], public newAssignmentData: Partial<Assignment>) {}
}

export class RemoveAssignmentFromList {
	constructor(public assignmentId: Assignment['id']) {}
}

export class AddAssignmentToList {
	constructor(public assignment: Assignment) {}
}
