import React from 'react';
import {join} from '@esgillc/ui-kit/utils';

import styles from './report-filters.module.less';

class Props {
	className?: string;
	children?: any;
	dataCy?: string;
}

export class ReportFiltersContainer extends React.PureComponent<Props> {
	render() {
		return <div data-cy={this.props.dataCy} className={join(styles.reportFiltersContainer, this.props.className)}>
			{this.props.children}
		</div>;
	}
}
