import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BuilderOptions, RequestBuilder} from './internal/request-builder';
import {CallbackOptions} from './types';

export abstract class BaseHttpClient {
	protected onRequestStartEmitter: Subject<void> = new Subject();
	protected onRequestEndEmitter: Subject<void> = new Subject();
	protected onDestroy$: Subject<void> = new Subject();

	/**
	 * The function returns an Observable that emits a void value when an HTTP request starts.
	 * @returns The method is returning an Observable of type `void`.
	 */
	public get onRequestStart$(): Observable<void> {
		return this.onRequestStartEmitter.pipe(takeUntil(this.onDestroy$));
	}

	/**
	 * The function returns an Observable that emits a void value when an HTTP request ends, and stops
	 * emitting when the component is destroyed.
	 * @returns The method is returning an Observable of type `void`.
	 */
	public get onRequestEnd$(): Observable<void> {
		return this.onRequestEndEmitter.pipe(takeUntil(this.onDestroy$));
	}

	protected get callbackOptions(): CallbackOptions {
		return {
			onRequestStarted: (config) => {
				this.onRequestStartEmitter.next();
			},
			onRequestEnd: (config) => {
				this.onRequestEndEmitter.next();
			},
		};
	}

	/**
	 * Emit the destroy event and cancel all current requests.
	 */
	public destroy() {
		this.onDestroy$.next();
	}

	protected innerRequest(baseUrl: string, options?: Partial<Omit<BuilderOptions, 'baseUrl'>>) {
		return new RequestBuilder({
			defaultErrorHandlers: [],
			callback: this.callbackOptions,
			cancel$: this.onDestroy$,
			...options as BuilderOptions,
			baseUrl: baseUrl,
		});
	}
}