import {ClassModel, GradeLevelModel, GroupModel, SchoolModel, StudentModel, TeacherModel} from '../../types';

export enum FieldType {
	Name = 'Name',
	Grade = 'Grade',
	Teacher = 'Primary Teacher',
	Class = 'Class',
	Groups = 'Groups',
	Created = 'Created',
	ID = 'ID',
	Specialist = 'Specialist',
}

export enum SortDirection {
	Asc = 'asc',
	Desc = 'desc',
}

export type Sorting = {field: FieldType, direction: SortDirection};

export type StudentInfo = {
	model: StudentModel,
	school: SchoolModel,
	teacher: TeacherModel | null,
	grade: GradeLevelModel,
	classes: ClassModel[],
	groups: GroupModel[],
	createDate: string,
	name: string,
	id: string,
	readonly: boolean,
}
