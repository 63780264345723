import ItemAnalysisReportService from '../../services/item-analysis-report-service';
import {QuestionDetails, Test} from '../../models';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {toDetailsPDF} from '../../utils';
import styles from './question-details.module.less';

interface Props {
	service: ItemAnalysisReportService;
}

export default function ItemAnalysisQuestionDetails({service}: Props) {
	const selectedQuestionLabel = useBehaviorSubject<string>(service.selectedQuestionLabel);
	const reportDate = useBehaviorSubject<string>(service.reportDate);
	const questionDetails = useBehaviorSubject<QuestionDetails>(service.questionDetails);
	const testResultsCorrectVerbiage = useBehaviorSubject<string>(service.testResultsCorrectVerbiage);
	const testResultsIncorrectVerbiage = useBehaviorSubject<string>(service.testResultsIncorrectVerbiage);
	const selectedTest = useBehaviorSubject<Test>(service.selectedTest);
	const questionDetailsRef = useRef(null);
	const qdBodyRef = useRef(null);

	const hideQuestionDetails = () => {
		service.setNeedRerender(true);
		service.setQuestionDetailsShow(false);
	};

	const getDetailsPdf = () => {
		if (!qdBodyRef.current) {
			return;
		}
		service.downloadDetailsPdf(toDetailsPDF(qdBodyRef.current.outerHTML, styles.body, styles.bold, styles.questionDetails));
	};

	return (
		<div id='iar-qd' className={styles.wrapper} ref={questionDetailsRef}>
			<div className={styles.header}>
				Chart Details
				<Buttons.Link
					className={styles.downloadDetails}
					onClick={getDetailsPdf}
				>
					<i className={`fa fa-download`}/>
				</Buttons.Link>
			</div>
			<div id='iar-qd-body' className={styles.body} ref={qdBodyRef}>
				<div className={styles.bold}>Test Question: <span>{selectedQuestionLabel}</span></div>
				<div className={styles.testData}>
					<div>Test: <span>{selectedTest.name}</span></div>
					<div className={styles.reportDate}>{reportDate}</div>
				</div>
				{questionDetails &&
					<div className={styles.questionDetails}>
						{questionDetails.studentsCorrect.length > 0 &&
							<div>
								<span className={styles.bold}>{testResultsCorrectVerbiage}</span>
								{questionDetails.studentsCorrect?.map((data) => (
									<div key={`iar-qd-cr-${data}`}>{data}</div>
								))}
							</div>
						}
						{questionDetails.studentsIncorrect.length > 0 &&
							<div>
								<span className={styles.bold}>{testResultsIncorrectVerbiage}</span>
								{questionDetails.studentsIncorrect?.map((data) => (
									<div key={`iar-qd-in-${data}`}>{data}</div>
								))}
							</div>
						}
						{questionDetails.studentsNotTested.length > 0 &&
							<div>
								<span className={styles.bold}>Not Tested</span>
								{questionDetails.studentsNotTested?.map((data) => (
									<div key={`iar-qd-nt-${data}`}>{data}</div>
								))}
							</div>
						}
					</div>
				}
			</div>
			<div
				className={styles.questionDetailsHide}
				onClick={() => hideQuestionDetails()}
			/>
		</div>
	);
}
