import {ComponentPropsWithoutRef, forwardRef, useMemo, useRef} from 'react';
import {Skeletonable, ThemeColorKeys} from '../types';
import {CSS} from '@stitches/react';
import {getThemeColor} from '../utils';
import {config, useTransition, animated} from 'react-spring';
import {SVG} from './index.styled';

export type GridProgressBarItem = {
	/** Row progress value. Display active items count. */
	value: number;

	/** Row progress maximum value. Display items count. */
	maxValue: number;
};

type GridProgressBarProps = ComponentPropsWithoutRef<'svg'> & {
	/** Data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: Omit<CSS, 'width' | 'height'>;

	/** Items of grid progress bar. Array item corresponds row of grid progress bar.  */
	items: GridProgressBarItem[];

	/** Set width and height for svg. */
	boxSize?: number;

	/** Set backgroundColor for active items. */
	activeItemColor?: ThemeColorKeys;

	/** Set backgroundColor for no active items. */
	inactiveItemColor?: ThemeColorKeys;
} & Skeletonable;

export const GridProgressBar = forwardRef<SVGSVGElement, GridProgressBarProps>(
	(
		{
			dataCy = 'ui-kit-grid-progress-bar',
			css,
			items,
			boxSize = 48,
			activeItemColor = 'positive',
			inactiveItemColor = 'positiveMuted',
			...props
		},
		forwardedRef,
	) => {
		const {
			current: {itemsGap, itemsBorderRadius},
		} = useRef({
			itemsGap: 1,
			itemsBorderRadius: 1,
		});

		const {maxItemsInRow, itemHeight, itemWidth} = useMemo(() => {
			const rowsCount = items.length;
			const maxItemsInRow = Math.max(...items.map(({maxValue}) => maxValue));

			const boxWidthWithoutGaps = boxSize - itemsGap * (maxItemsInRow - 1);
			const boxHeightWithoutGaps = boxSize - itemsGap * (rowsCount - 1);

			return {
				maxItemsInRow,
				itemWidth: boxWidthWithoutGaps / maxItemsInRow,
				itemHeight: boxHeightWithoutGaps / rowsCount,
			};
		}, [items, boxSize, itemsGap]);

		const progressBarItems = useMemo(
			() =>
				items.flatMap(({value, maxValue}, rowIndex) =>
					[...Array(maxItemsInRow)].map((_, itemInRowIndex) => {
						const itemGap = 1;

						const x = itemInRowIndex * (itemWidth + itemGap);
						const y = rowIndex * (itemHeight + itemGap);

						let fill = 'none';

						if (maxValue >= itemInRowIndex + 1) {
							fill = getThemeColor(value >= itemInRowIndex + 1 ? activeItemColor : inactiveItemColor);
						}

						return {
							fill,
							x,
							y,
						};
					}),
				),
			[items, itemWidth, itemHeight, maxItemsInRow, activeItemColor, inactiveItemColor],
		);

		const transitions = useTransition(progressBarItems, {
			from: {opacity: 0},
			enter: {opacity: 1},
			trail: 300 / progressBarItems.length,
			config: config.molasses,
		});

		return (
			<SVG
				xmlns='http://www.w3.org/2000/svg'
				viewBox={`0 0 ${boxSize} ${boxSize}`}
				css={{
					width: boxSize,
					height: boxSize,
					...css,
				}}
				ref={forwardedRef}
				{...props}
			>
				{transitions((style, {x, y, fill}) => (
					<animated.rect
						style={style}
						rx={itemsBorderRadius}
						ry={itemsBorderRadius}
						width={itemWidth}
						height={itemHeight}
						x={x}
						y={y}
						fill={fill}
					/>
				))}
			</SVG>
		);
	},
);
