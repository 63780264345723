import {CSSProperties} from 'react';

export class TeacherActivityRequest implements ITeacherActivityOptions {
	cacheKey: any;
	sortModel: SortModel;
	districtId: number;
	globalSchoolYearId: number;
}

export class SortModel {
	fieldName: string;
	direction: any;
	relatedSorts?: SortModel[];
}


export class SortCell {
	constructor(name, displayName, cellStyle = '', cssInline: CSSProperties = {}) {
		this.cssInline = cssInline;
		this.name = name;
		this.displayName = displayName;
		this.rawCellStyle = cellStyle;
		this.direction = ko.observable('None');
	}

	private rawCellStyle: string;
	cssInline: CSSProperties;
	cellStyle = ko.pureComputed(() => {
		if (this.direction() === 'None') {
			return this.rawCellStyle + ' show-icon-hover';
		}
		return this.rawCellStyle;
	});
	name: string;
	displayName: string;
	direction: KnockoutObservable<any>;
	directionCss = ko.pureComputed(() => {
		if (this.direction() === 'Asc') {
			return 'fa fa-arrow-down fa-fw';
		}
		if (this.direction() === 'Desc') {
			return 'fa fa-arrow-up fa-fw';
		}
		return 'fa fa-fw fa-arrow-down fa-lightgray';
	});

}

export interface ITeacherActivityOptions {
	districtId: number;
	globalSchoolYearId: number;
}


export interface IRow {
	userID: string;
	lastLoginDate: Date;
	lastLoginDateStr: string;
	expDate: string;
	expDateStr: string;
	firstName: string;
	lastName: string;
	loginCount: number;
	questionsAsked: number;
	schoolName: string;
	studentsEnrolled: number;
	studentsTested: number;
	teacher: string;
	testsAdministered: number;
	testsAuthored: number;
	testsCompleted: number;
}
export interface IInitResponse {
	report: IReport;
	sortModel: SortModel;
	cacheKey: string;
}
export interface IReport {
	districtID: number;
	districtName: string;
	reportDateTime: string;
	rows: IRow[];
	sortRules: any;
}