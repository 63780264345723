import {formatInTimeZone} from 'date-fns-tz';

export function convertToDateTimeZone(
	timeZone: string,
	incomingDateString: string | Date
): string {
	return formatInTimeZone(
		incomingDateString,
		timeZone,
		'yyyy-MM-dd HH:mm:ssXXX'
	);
}