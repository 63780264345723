import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {HierarchySnapshot, SelectedSnapshot} from '../../hierarchy/core/models';
import {TableLevelReport} from './components/table-level-report';
import {StudentLevelReport} from './components/student-level-report';
import {SubjectType} from './types/table-level-report-service-types';
import {useService, useServiceFactory} from '@esgi/core/service';
import {TableLevelReportService} from './services/table-level-report-service';
import {useCallback, useState} from 'react';
import {StudentLevelReportModel} from './types/student-level-report-service-types';
import {FiltersService} from './services/filters-service';
import {useReportValidator} from 'modules/reports/student-detail-report/hooks/use-report-validator';
import {userStorage} from '@esgi/core/authentication';

type RubricReportProps = {
	subjectID: number;
	subjectType: SubjectType;
	hierarchy: HierarchySnapshot;
	onClosed: () => void;
};

function RubricReport({hierarchy, onClosed, subjectID, subjectType}: RubricReportProps) {
	const [studentLevelReportData, setStudentLevelReportData] = useState<StudentLevelReportModel | null>(null);

	const options = new SelectedSnapshot(hierarchy);
	const reportIsValid = useReportValidator({
		classId: options.classID,
		groupId: options.groupID,
		studentId: options.studentID,
		globalSchoolYearId: userStorage.get().globalSchoolYearID,
		subjectId: subjectID,
		subjectType: subjectType,
		userId: options.teacherID,
	}, hierarchy, onClosed);

	const filterService = useService(FiltersService);

	const tableLevelReportService = useServiceFactory(() => new TableLevelReportService(hierarchy, filterService));

	useStreamEffect(tableLevelReportService.studentLevelReportData$, (studentLevelReportData) => {
		setStudentLevelReportData(studentLevelReportData);
	});

	const clearStudentLevelReportData = useCallback(() => {
		setStudentLevelReportData(null);
	}, []);

	return (
		<>
			{reportIsValid && <TableLevelReport
				service={tableLevelReportService}
				subjectID={subjectID}
				subjectType={subjectType}
				onClosed={onClosed}/>
			}
			{reportIsValid && studentLevelReportData && (
				<StudentLevelReport
					report={studentLevelReportData}
					onClosed={clearStudentLevelReportData}
					filterService={filterService}
				/>
			)}
		</>
	);
}

export default RubricReport;
