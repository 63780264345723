import {BaseService} from '@esgi/core/service';
import {DownloadPDFRequest} from '../types';
import {SsoTracker} from '@esgi/core/tracker';

export class DownloadService extends BaseService {
	protected readonly controller = 'v2/modules/activities/name-writing';

	public downloadPdf(request: Omit<DownloadPDFRequest, 'documentDate'>) {
		const documentDate = new Date().toLocaleDateString('en-US');
		SsoTracker.trackEvent({trackingEvent: 'WritingPractice-NameWriting'});

		this.httpClient.ESGIApi.file(
			this.controller,
			'downloadPdf',
			`Name_Writing_${documentDate}.pdf`,
			{...request, documentDate},
		).subscribe();
	}
}
