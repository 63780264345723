import {Answer, AnswerState, TestInfo, TestSession} from '../../../../../types';
import {TestType} from '@esgi/main/libs/core';
import {YesNoContent} from './components/yes-no-content';
import {useMemo} from 'react';
import {StudentInfo} from '../../../../../class-group/types';

type Props = {
	testInfo: TestInfo;
	studentsInfo: StudentInfo[];
	isTablet: boolean;
};

export function ClassEntity({testInfo, studentsInfo, isTablet}: Props) {
	const {sessions: sortedTestSessions, emptySessionsCount} = useMemo(() => {
		const sessions: TestSession[] = [];
		for (const {
			testSessionsInfo: {testSessions},
		} of studentsInfo) {
			if (!testSessions.length) {
				continue;
			}

			const sortedSessions = [...testSessions].sort(
				(a, b) => new Date(b.testDate).getTime() - new Date(a.testDate).getTime(),
			);

			if(sortedSessions.length){
				const session = sortedSessions[0];
				const questionIDs = session.answers.map(item => item.questionID);
				const lostQuestions = testInfo.questions.filter(question => !questionIDs.includes(question.id));
				const resultAnswers = [...session.answers, ...lostQuestions.map<Answer>(item => ({answerState: AnswerState.NotTested, comment: '', questionID: item.id}))];
				sessions.push({...session, answers: resultAnswers});
			}
		}

		return {
			sessions,
			emptySessionsCount: studentsInfo.length - sessions.length,
		};
	}, [studentsInfo, testInfo]);

	if (testInfo.testType === TestType.YN) {
		return (
			<YesNoContent
				testSessions={sortedTestSessions}
				testInfo={testInfo}
				emptySessionsCount={emptySessionsCount}
				isTablet={isTablet}
			/>
		);
	}

	return null;
}
