import {SelectOptionItem} from '@esgi/main/kits/assignments';
import {AssignmentStateTab} from '../../../layout/types';

export const selectAssignmentStatusItems: SelectOptionItem<AssignmentStateTab>[] = [
	{
		value: AssignmentStateTab.AllAssignments,
		label: 'All Assignments',
	},
	{
		value: AssignmentStateTab.Draft,
		label: 'Draft',
	},
	{
		value: AssignmentStateTab.NotStarted,
		label: 'Not Started',
	},
	{
		value: AssignmentStateTab.InProgress,
		label: 'In Progress',
	},
	{
		value: AssignmentStateTab.Completed,
		label: 'Completed',
	},
];
