import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const ParentConferencerPage = lazyComponent(() => import('pages/parent-conferencer/root'));

export default function ParentConferencer(props: {excludeLayout?: boolean}) {
	return <RouteWrapper className='pc' title={'Parent Conferencer'} authorize>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<ParentConferencerPage excludeLayout={props.excludeLayout}/>
		</Suspense>
	</RouteWrapper>;
}
