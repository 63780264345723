import {
	IQuestionAddOptions,
	IQuestionResponse, IQuestionUpdateOptions,
	IUpdateResult, QuestionModel,
	ShapeDefinitions,
} from 'shared/modules/question-editor/models';
import {Canvas, State as CanvasState, Events as CanvasEvents} from 'shared/modules/question-editor/mx/editor/canvas/canvas';
import {RightPanel, State as RightPanelState} from 'shared/modules/question-editor/mx/editor/right-panel/right-panel';
import {Form, State as FormState} from 'shared/modules/question-editor/mx/form/form';
import {XmlCodec} from 'shared/modules/question-editor/utils/xml-codec';
import {ShapeDefinitionService} from 'shared/modules/question-editor/utils/shape-definition-builder';
import * as Events from 'shared/modules/question-editor/form-events';
import {Modal, ModalBody, ModalFooter, ModalHeader, SharedProps, SharedComponent} from '@esgi/deprecated/react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {TestType} from '@esgi/core/enums';
import {Field, Validators} from '@esgi/deprecated/elements';
import {LoadFonts} from 'shared/modules/assets/fonts';
import {HttpClient} from '@esgi/api';
import {map} from 'rxjs/operators';
import {Loader} from '@esgillc/ui-kit/loader';
import {DirtyAlert} from '../components/dirty-alert';
import '../editors.less';

export class Props extends SharedProps<State> {
	onSaved: (needClose: boolean, result?: IUpdateResult) => any;
	questionAdded: () => void;
	shapeDefinitions: ShapeDefinitions;
	testType: TestType;
}

export class State {
	saving: boolean;
	copyToNextQuestion: boolean;
	question: QuestionModel = new QuestionModel();
	form: FormState = new FormState();
	canvas: CanvasState = new CanvasState();
	rightPanel: RightPanelState = new RightPanelState();
	dirtyAlert: boolean = false;
	dirtyXml: string;
	dirtyDefinitions: ShapeDefinitions;
	shapeDefinitionsXml: string;
	initialized: boolean = false;
}

export class QuestionEditor extends SharedComponent<State, Props> {
	get isEditMode() {
		return this.state.question.questionID > 0;
	}

	get valid() {
		return this.formValid && this.canvasValid;
	}

	get formValid() {
		if (this.props.testType === TestType.Score) {
			return this.state.form.directions.validation.valid;
		}

		if (this.props.testType === TestType.YN) {
			return this.state.form.questionName.validation.valid
				&& this.state.form.directions.validation.valid;
		}
	}

	get canvasValid() {
		return this.state.canvas.cells.length > 0;
	}

	async componentDidMount() {
		await LoadFonts();

		if (this.isEditMode) {
			this.server.initEdit(this.state.question.questionID).subscribe((r: IQuestionResponse) => {
				let question = new QuestionModel(r);
				question.testType = this.props.testType;
				let newState = {...this.state};

				newState.question = question;
				newState.form.stateStandardID = r.stateStandardID;
				newState.form.stateStandard = r.stateStandard;
				newState.form.questionName = new Field(r.name);
				newState.form.directions = new Field(r.directions, Validators.successfulValidation);
				newState.initialized = true;
				newState.shapeDefinitionsXml = question.xml;

				this.setState(newState);
			});
		} else {
			this.setState({initialized: true});
		}

		this.subscribe(CanvasEvents.SaveAndClose, (args: CanvasEvents.SaveAndClose.Args) => {
			if (!this.valid) {
				return;
			}

			if (this.state.form.changed || args.changed) {
				this.setState({saving: true});
				this.save(args.xml, args.definitions).subscribe((result: IUpdateResult) => {
					this.props.onSaved(true, result);
				});
			} else {
				this.props.onSaved(true);
			}
		});

		this.subscribe(CanvasEvents.SaveAndNext, (args: CanvasEvents.SaveAndNext.Args) => {
			if (this.valid) {
				this.setState({saving: true});
				this.save(args.xml, args.definitions).subscribe((result: IUpdateResult) => {
					let newState = {...this.state};
					newState.saving = false;
					newState.question.orderNum = this.state.question.orderNum + 1;
					newState.question.images = [...this.state.canvas.images];
					newState.canvas.loaded = false;
					newState.canvas.showUndoRedo = false;

					if (this.state.copyToNextQuestion) {
						newState.question.xml = result.xml;
						newState.form.questionName = new Field('', {
							valid: false,
							message: 'Please enter a Question Name.',
						});
					} else {
						newState.question.xml = '';
						newState.form = new FormState();
					}

					this.setState(newState);

					this.props.onSaved(false, result);
				});
			}
		});

		this.subscribe(CanvasEvents.Close, (args: CanvasEvents.Closed.Args) => {
			if (this.state.form.changed || args.changed) {
				this.setState({dirtyXml: args.xml, dirtyDefinitions: args.definitions, dirtyAlert: true});
			} else {
				this.props.onSaved(true);
			}
		});
	}

	save(xml: string, definitions: ShapeDefinitions) {
		let decodedXml = XmlCodec.decode(xml, this.state.canvas.images);
		let newDefinisions = new ShapeDefinitionService(definitions).saveTempDefinitions();

		if (this.isEditMode) {
			return this.update(decodedXml).pipe(map(() => {
				return {
					id: this.state.question.questionID,
					name: this.state.form.questionName.value,
					xml: decodedXml,
					definitions: newDefinisions,
				};
			}));
		} else {
			return this.add(decodedXml).pipe(map((result) => {
				this.props.questionAdded();

				return {
					id: result.questionID,
					name: this.state.form.questionName.value,
					xml: decodedXml,
					definitions: newDefinisions,
				};
			}));
		}
	}

	add(xml: string) {
		return this.server.add({
			testID: this.state.question.testID,
			directions: this.state.form.directions.value,
			name: this.props.testType === TestType.YN ? this.state.form.questionName.value : this.state.question.testName,
			testType: this.state.question.testType,
			xml: xml,
		});
	}

	update(xml: string) {
		return this.server.update({
			questionID: this.state.question.questionID,
			directions: this.state.form.directions.value,
			name: this.props.testType === TestType.YN ? this.state.form.questionName.value : this.state.question.testName,
			stateStandardId: this.state.form.stateStandardID,
			xml: xml,
		});
	}

	server = {
		update: (model: IQuestionUpdateOptions) => {
			return HttpClient.default.ESGIApi.post('assets/questions', 'update', model);
		},
		add: (model: IQuestionAddOptions) => {
			return HttpClient.default.ESGIApi.post<{questionID: number}>('assets/questions', 'create', model);
		},
		initEdit: (questionID: number) => {
			return HttpClient.default.ESGIApi.get<IQuestionResponse>('assets/questions', 'edit/init', {questionID: questionID});
		},
	};

	closeDirtyAlert(needSave: boolean) {
		if (needSave) {
			if (this.valid) {
				this.save(this.state.dirtyXml, this.state.dirtyDefinitions).subscribe((result: IUpdateResult) => {
					this.setState({dirtyAlert: false}, () => this.props.onSaved(true, result));
				});
			} else {
				this.showValidation();
				this.setState({dirtyAlert: false});
			}
		} else {
			this.setState({dirtyAlert: false}, () => this.props.onSaved(true));
		}
	}

	saveAndNextClicked() {
		this.showValidation();
		this.dispatch(Events.SaveAndNextClicked, null);
	}

	saveAndCloseClicked() {
		this.showValidation();
		this.dispatch(Events.SaveAndCloseClicked, null);
	}

	showValidation() {
		this.dispatch(Events.ShowValidation, Events.ShowValidation(this.formValid, this.canvasValid));
	}

	closeClicked() {
		this.dispatch(Events.CloseClicked, null);
	}

	renderFooter() {
		if (this.isEditMode || this.state.question.testType === TestType.Score) {
			return <div className='mx-question-footer-row'>
				<div className='mx-question-root-left-button'>
					<div className='mx-question-root-left-button-container'>
						<Button
							onClick={() => this.closeClicked()}
							title='CANCEL'
						/>
					</div>
				</div>
				<Primary
					onClick={() => this.saveAndCloseClicked()}
					title='SAVE AND CLOSE'
					disabled={this.state.saving}
					className='save-btn'
				/>
			</div>;
		}

		return <div className='mx-question-footer-row'>
			<Button
				onClick={() => this.closeClicked()}
				title='CANCEL'
			/>
			<div className='mx-question-root-left-button'>
				<label className='mx-copy-question-field'>
					<span className='tooltip-pointer'></span>
					<input className='ace' type='checkbox' checked={this.state.copyToNextQuestion}/>
					<span onClick={(e) => {
						this.setState((prevState) => {
							return {copyToNextQuestion: !prevState.copyToNextQuestion};
						});
					}} className='lbl'>Copy content to next question</span>
				</label>
				<div className='mx-question-root-left-button-container'>
					<Primary
						onClick={() => this.saveAndNextClicked()}
						disabled={this.state.saving || !this.valid}
					>
						<span>{this.state.saving &&
						<i className='fa fa-circle-o-notch fa-spin'/>}NEXT QUESTION &gt;</span>
					</Primary>
				</div>
			</div>
			<Primary
				onClick={() => this.saveAndCloseClicked()}
				title='SAVE AND CLOSE'
				disabled={this.state.saving}
				className='save-btn'
			/>
		</div>;
	}

	render() {
		return <>
      <Loader show={!this.state.initialized} fullscreen/>;
      {this.state.initialized && <>
      	{this.state.dirtyAlert &&
          <DirtyAlert
						onClose={() => this.closeDirtyAlert(false)}
            onSave={() => this.closeDirtyAlert(true)}
					/>
				}
        <Modal
          animate={true}
          className={'mx-question-editor-modal responsive white-header'}>
          <ModalHeader title={this.isEditMode ? 'Edit Question' : 'Add Question'}
                       close={{click: () => this.closeClicked()}}/>
          <ModalBody>
            <div className='mx-question-editor-modal-root'>
              <Form
                isNew={!this.isEditMode}
                testType={this.state.question.testType}
                state={this.state.form}
                onChange={(st, ch) => this.setState({form: st}, ch)}/>
              <div className='mx-question-editor'>
                <Canvas
                  images={this.state.question.images}
                  isWhiteBackground={this.state.question.isWhiteBackground}
                  xml={this.state.question.xml}
                  testName={this.state.question.testName}
                  questionNumber={this.state.question.orderNum}
                  shapeDefinitions={this.props.shapeDefinitions}
                  shapeDefinitionsXml={this.state.shapeDefinitionsXml}
                  state={this.state.canvas}
                  onChange={(st, ch) => this.setState({canvas: st}, ch)}/>
                <RightPanel
                  shapeStyle={this.state.canvas.shapeStyle}
                  shapeType={this.state.canvas.shapeType}
                  state={this.state.rightPanel}
                  onChange={(st, ch) => this.setState({rightPanel: st}, ch)}/>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.renderFooter()}
          </ModalFooter>
        </Modal>
      </>}
		</>;
	}
}
