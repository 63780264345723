import {ClassicHierarchySnapshot} from '../../models';
import {ISchoolBox, IStudentBox, StudentModel} from '../api';

export function withStudentID(h: ClassicHierarchySnapshot, studentID: number) {
	const n = {...h};
	n.studentID = studentID;
	return n;
}
export function withStudent(h: ClassicHierarchySnapshot, student: StudentModel) {
	const n = {...h};
	if (!student) {
		n.studentID = 0;
		return n;
	}

	n.studentID = student.studentID;
	n.teacherID = student.teacherID;
	if (!student.classes.some(s => s === n.classID)) {
		n.classID = student.classes[0];
		n.groupID = 0;
	}
	return n;

}
export function withGroupID(h: ClassicHierarchySnapshot, groupID: number, students: IStudentBox) {
	const n = {...h};
	const oldGroupID = n.groupID;
	n.groupID = groupID;
	if (groupID === 0) {
		n.studentID = 0;
	} else if (n.studentID !== 0) {
		if (oldGroupID === groupID) {
			n.studentID = 0;
		} else {
			const present = students.items.filter(f => f.studentID === n.studentID && f.groups.some(a => a === n.groupID));
			if (present.length === 0) {
				n.studentID = 0;
			}
		}

	}
	return n;
}

export function withSchoolsGroupID(h: ClassicHierarchySnapshot, schoolsGroupID: number, schools: ISchoolBox) {
	const n = {...h};
	const oldGroupID = n.schoolsGroupID;
	n.schoolsGroupID = schoolsGroupID;
	n.teacherID = 0;
	n.teachersGroupID = 0;
	if (schoolsGroupID === 0) {
		n.schoolID = 0;
	} else if (n.schoolID !== 0) {
		if (oldGroupID === schoolsGroupID) {
			n.schoolID = 0;
		} else {
			const present = schools.items.filter(f => f.schoolID === n.schoolID && f.groups.some(a => a === n.schoolsGroupID));
			if (present.length === 0) {
				n.schoolID = 0;
			}
		}

	}
	return n;
}

export function withTeachersGroupID(h: ClassicHierarchySnapshot, teachersGroupID: number) {
	const n = {...h};
	n.teachersGroupID = teachersGroupID;
	n.teacherID = 0;
	n.studentID = 0;
	n.classID = 0;
	n.groupID = 0;
	return n;
}

export function withClassID(h: ClassicHierarchySnapshot, classID: number, students: IStudentBox) {
	const n = {...h};
	const oldClassID = n.classID;
	n.classID = classID;
	n.groupID = 0;

	if (n.classID === 0) {
		n.studentID = 0;
		return n;
	}

	if (n.studentID !== 0) {
		if (oldClassID === classID) {
			n.studentID = 0;
		} else {
			const present = students.items.filter(f => f.studentID === n.studentID && f.classes.some(a => a === n.classID));
			if (present.length === 0) {
				n.studentID = 0;
			}
		}
	}

	return n;
}

export function withTeacherID(h: ClassicHierarchySnapshot, teacherID: number) {
	const n = {...h};
	n.teacherID = teacherID;
	n.classID = 0;
	n.groupID = 0;
	n.studentID = 0;
	return n;
}

export function withSchoolID(h: ClassicHierarchySnapshot, schoolID: number) {
	const n = {...h};
	n.schoolID = schoolID;
	n.teachersGroupID = 0;
	n.teacherID = 0;
	n.classID = 0;
	n.groupID = 0;
	n.studentID = 0;
	return n;
}
