import {BaseService} from '@esgi/core/service';
import {EventBusManager} from '@esgillc/events';
import {isIOS} from '@esgillc/ui-kit/utils';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {SsoTracker} from '@esgi/core/tracker';
import {BehaviorSubject, combineLatestWith, Observable} from 'rxjs';
import {
	ISDRSettings,
	MarkingPeriod,
	ReportInit,
	SettingsInit,
	Student,
	Subject,
	SubjectsGet,
	UnityItem,
	User,
} from '../models/models';
import {map, tap} from 'rxjs/operators';
import {ReportDataService} from './report-data-service';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {getFileName} from '../utils';
import {RequestsReportDownload, ResponsesAvailableTestsInit} from '../models/api-models';

export class ReportSettingsService extends BaseService {
	public currentPeriod = new BehaviorSubject<string>('All');
	public includeGradeScore = new BehaviorSubject<boolean>(false);
	public includeQuestionNotes = new BehaviorSubject<boolean>(false);
	public printInColor = new BehaviorSubject<boolean>(false);
	public includeSummaryNotes = new BehaviorSubject<boolean>(false);
	public showBaseline = new BehaviorSubject<boolean>(false);
	public displayZeroIfNotTestedOption = new BehaviorSubject<'Zero' | 'NT'>(null);
	public displayZeroIfNotTested = new BehaviorSubject<boolean>(false);
	public students = new BehaviorSubject<Student[]>([]);
	public initTestIds = new BehaviorSubject<number[]>([]);
	public selectedStudent = new BehaviorSubject<Student[]>([]);
	public selectedGroup = new BehaviorSubject<UnityItem[]>([]);
	public selectedSubject = new BehaviorSubject<Subject[]>([]);
	public selectedSpecialistGroup = new BehaviorSubject<UnityItem[]>([]);
	public specialistGroups = new BehaviorSubject<UnityItem[]>([]);
	public teacher = new BehaviorSubject<User | null>(null);
	public studentID = new BehaviorSubject<number>(null);
	public classes = new BehaviorSubject<UnityItem[]>([]);
	public selectedClass = new BehaviorSubject<UnityItem[]>([]);
	public subjects = new BehaviorSubject<Subject[]>([]);
	public globalSchoolYearId = new BehaviorSubject<number>(0);
	public settings = new BehaviorSubject<ISDRSettings>(null);
	public testIDs = new BehaviorSubject<number[]>(null);
	public carryScoresForward = new BehaviorSubject<boolean>(false);
	public showBaselineUpdating = new BehaviorSubject<boolean>(false);
	public markingPeriods = new BehaviorSubject<MarkingPeriod[]>([]);
	public groups = new BehaviorSubject<UnityItem[]>([]);
	public dataService = new ReportDataService();
	private initialized = new BehaviorSubject<boolean>(false);
	private hierarchy: HierarchySnapshot;

	private updateOption(name: string, value: boolean) {
		if (this.initialized.value) {
			this.httpClient.ESGIApi.post('reports/user-settings',
				'update',
				{name, value},
			).subscribe();
		}
	}

	constructor(dataService: ReportDataService) {
		super();
		this.dataService = dataService;
	}

	public showBaselineChanged() {
		this.showBaseline.next(!this.showBaseline.value);
		this.showBaselineUpdating.next(true);
		this.httpClient.ESGIApi.post('reports/student-detail', 'ShowBaseline', {value: this.showBaseline.value}).subscribe(() => {
			this.showBaselineUpdating.next(false);
		});
	}

	public periodChanged(value) {
		this.currentPeriod.next(value);
	}

	private findSelectedSubject(subjects: Subject[], selectedSubjectID) {
		let result = null;
		if (subjects && subjects.length > 0) {
			result = subjects.filter(s => s.subjectID === selectedSubjectID)[0];

			if (result == null) {
				result = subjects[0];
			}
		}
		return result;
	}

	private getReportSettings() {
		return {
			students: this.students.value,
			teacher: this.teacher.value,
			carryScoresForward: this.carryScoresForward.value,
			selectedClass: this.selectedClass.value,
			selectedGroup: this.selectedGroup.value,
			selectedSpecialistGroup: this.selectedSpecialistGroup.value,
			testIDs: this.testIDs.value,
			selectedStudent: this.selectedStudent.value,
			selectedSubject: this.selectedSubject.value,
			globalSchoolYearId: this.globalSchoolYearId.value,
			hierarchy: this.hierarchy,
		};
	}


	private subjectChanged(s: Subject) {
		this.selectedSubject.next([s]);
	}

	public eventBus = new EventBusManager();

	public onChange = this.settings.pipe(combineLatestWith(this.testIDs,
		this.students,
		this.teacher,
		this.displayZeroIfNotTested,
		this.printInColor,
		this.showBaseline,
		this.includeQuestionNotes,
		this.includeSummaryNotes,
		this.includeGradeScore,
		this.currentPeriod,
		this.markingPeriods),
	map(project => {
		const [settings, testIDs,
			students,
			teacher,
			displayZeroIfNotTested,
			printInColor,
			showBaseline,
			includeQuestionNotes,
			includeSummaryNotes,
			includeGradeScore,
			currentPeriod,
			markingPeriods] = project;
		return ({
			settings,
			testIDs,
			students,
			teacher,
			displayZeroIfNotTested,
			printInColor,
			showBaseline,
			includeQuestionNotes,
			includeSummaryNotes,
			includeGradeScore,
			currentPeriod,
			markingPeriods,
		});
	}));

	private updateSubjects() {
		const currentSubject = this.selectedSubject.value?.[0]?.subjectID;

		this.httpClient.ESGIApi.get<SubjectsGet>('reports/student-detail', 'Subjects', this.hierarchy).subscribe((response) => {
			const subjectsResult = response;

			for (let i = 0; i < subjectsResult.subjects.length; i++) {

				const subj = subjectsResult.subjects[i];

				if (!this.subjects.value.filter(s => s.subjectID === subj.subjectID).length) {
					this.subjects.next(this.subjects.value.concat(subj));
				}
			}

			for (let i = 0; i < this.subjects.value.length; i++) {

				const subj = this.subjects[i];
				if (!subjectsResult.subjects.filter(s => s.subjectID === subj?.subjectID).length) {
					this.subjects.next(this.subjects.value.filter(s => s !== subj));
				}
			}
			if (currentSubject !== this.selectedSubject.value[0].subjectID) {
				this.selectedSubject.next([this.findSelectedSubject(subjectsResult.subjects, this.selectedSubject.value[0].subjectID)]);
			} else {
				this.dataService.updateReportData(this.getReportSettings());
			}
		});
	}

	public changeHierarchy(item: string, value: any) {
		switch (item) {
			case 'classChanged':
				const selectedClass = this.classes.value.find(c => c.itemID === value[0]);
				this.selectedClass.next([selectedClass]);
				this.students.next(selectedClass?.students);
				this.selectedStudent.next([selectedClass?.selectedStudent]);
				this.changeHierarchy('student', [selectedClass.selectedStudentID]);
				break;
			case 'groupChanged':
				const selectedGroup = this.groups.value.find(c => c.itemID === value[0]);
				this.selectedGroup.next([selectedGroup]);
				this.students.next(selectedGroup?.students);
				this.selectedStudent.next([selectedGroup?.selectedStudent]);
				this.changeHierarchy('student', [selectedGroup.selectedStudentID]);
				break;
			case 'specialistGroupChanged':
				const selectedSpecialistGroup = this.specialistGroups.value.find(c => c.itemID === value[0]);
				this.selectedSpecialistGroup.next([selectedSpecialistGroup]);
				this.students.next(selectedSpecialistGroup?.students);
				this.selectedStudent.next([selectedSpecialistGroup?.selectedStudent]);
				this.changeHierarchy('student', [selectedSpecialistGroup.selectedStudentID]);
				break;
			case 'student':
				this.selectedStudent.next([this.students.value.find(s => s.studentID === value[0])]);
				this.updateSubjects();
				this.dataService.updateReport(this.getReportSettings());
				break;
			case 'subject':
				this.subjectChanged(this.subjects.value.find(s => s.subjectID === value[0]));
				this.initTestIds.next(null);
				this.dataService.updateReportData(this.getReportSettings())
					.subscribe((response: ResponsesAvailableTestsInit) => {
						this.testIDs.next(response.subjectTests.reduce((prev, curr) => {
							const v = (this.initTestIds.value)
								? curr.testIDs.filter(t => this.initTestIds.value.indexOf(<number>t) > -1)
								: curr.testIDs;
							prev.push(...v);
							return prev;
						}, []));

						if (this.testIDs.value.length === 0) {
							const report = new ReportInit();
							report.students = [];
						} else {
							return this.dataService.updateReport(this.getReportSettings());
						}
					});
				break;
		}
	}

	public init(settings: ISDRSettings, hierarchy: HierarchySnapshot) {
		this.settings.next(settings);
		this.initTestIds.next(settings.testIDs);
		this.testIDs.next(settings.testIDs);
		this.studentID.next(settings.studentId);
		this.selectedStudent.next([this.students.value.find(s => s.studentID === settings.studentId)]);
		this.selectedSubject.next([this.subjects.value.find(s => s.subjectID === settings.subjectId)]);
		this.globalSchoolYearId.next(settings.globalSchoolYearID);
		this.hierarchy = hierarchy;
		this.carryScoresForward.subscribe(v => {
			if(this.initialized.value) {
				this.dataService.updateReport(this.getReportSettings());
				this.updateOption('carryScoresForward', this.carryScoresForward.value);
			}
		});
		this.displayZeroIfNotTested.subscribe(v => {
			this.updateOption('displayZeroIfNotTested', this.displayZeroIfNotTested.value);
			this.displayZeroIfNotTestedOption.next(v ? 'Zero' : 'NT');
		});
		this.includeQuestionNotes.subscribe(v => this.updateOption('includeQuestionNotes', this.includeQuestionNotes.value));
		this.includeSummaryNotes.subscribe(v => this.updateOption('includeSummaryNotes', this.includeSummaryNotes.value));
		this.printInColor.subscribe(v => this.updateOption('printInColor', this.printInColor.value));
		this.includeGradeScore.subscribe(v => this.updateOption('includeGradeScore', this.includeGradeScore.value));
	}

	public update(settings: SettingsInit) {
		this.teacher.next(settings.teacher);
		let markingPeriods = [];
		if (this.teacher.value) {
			this.displayZeroIfNotTested.next(this.teacher.value.displayZeroIfNotTested.value);
			this.displayZeroIfNotTestedOption.next(this.teacher.value.displayZeroIfNotTested.value ? 'Zero' : 'NT');
			this.carryScoresForward.next(this.teacher.value.carryScoresForward.value);
			this.includeGradeScore.next(this.teacher.value.includeGradeScore.value);
			this.printInColor.next(this.teacher.value.printInColor.value);
			this.showBaseline.next(this.teacher.value.showBaseline);
			this.includeQuestionNotes.next(this.teacher.value.includeQuestionNotes.value);
			this.includeSummaryNotes.next(this.teacher.value.includeSummaryNotes.value);
		}

		if (!settings.report.gradeScaleAllow && !settings.report.gradeValues) {
			this.includeGradeScore.next(false);
		}
		if (settings.report) {
			markingPeriods = markingPeriods.concat({index: 0, title: 'B'});
			for (let i = 0; i < settings.report?.trackDates?.length; i++) {
				markingPeriods = markingPeriods.concat({
					index: i + 1,
					title: (i + 1).toString(),
				});
			}
			this.markingPeriods.next(markingPeriods);
		}

		let selectedClass = null;
		let selectedGroup = null;
		let selectedSpecialistGroup = null;
		let selectedStudent = null;
		let selectedSubject = null;

		if (settings.classes) {
			this.classes.next(settings.classes);

			selectedClass = settings.selectedClass;

			if (selectedClass) {
				this.students.next(selectedClass.students);
				selectedStudent = selectedClass.selectedStudent;
			}
		}

		if (settings.groups) {
			this.groups.next(settings.groups);

			selectedGroup = settings.selectedGroup;

			if (selectedGroup) {
				this.students.next(selectedGroup.students);
				selectedStudent = selectedGroup.selectedStudent;
			}
		}

		if (settings.specialistGroups) {
			this.specialistGroups.next(settings.specialistGroups);

			selectedSpecialistGroup = settings.selectedSpecialistGroup;

			if (selectedSpecialistGroup) {
				this.students.next(selectedSpecialistGroup.students);
				selectedStudent = selectedSpecialistGroup.selectedStudent;
			}
		}

		if (settings.subjects) {
			this.subjects.next(settings.subjects);

			selectedSubject = this.subjects.value.filter(s => s.subjectID === this.settings.value.subjectId)[0];
		}

		this.selectedClass.next([selectedClass]);
		this.selectedGroup.next([selectedGroup]);
		this.selectedSpecialistGroup.next([selectedSpecialistGroup]);
		this.selectedStudent.next([selectedStudent]);
		this.selectedSubject.next([selectedSubject]);
		this.initialized.next(true);
	}

	public downloadPdf(zip: boolean): Observable<any> {
		const requestModel = new RequestsReportDownload();
		requestModel.reportGuid = this.dataService.report.value.reportGuid;
		requestModel.currentPeriod = this.currentPeriod.value !== 'All';
		requestModel.includeGradeScore = this.includeGradeScore.value;
		requestModel.includeNotes = this.includeQuestionNotes.value;
		requestModel.includeSummaryNotes = this.includeSummaryNotes.value;
		requestModel.notTestedDisplayValue = this.displayZeroIfNotTestedOption.value === 'NT'
			? this.displayZeroIfNotTestedOption.value
			: '0';
		requestModel.printInColor = this.printInColor.value;
		requestModel.showBaseline = this.showBaseline.value;
		requestModel.zip = zip;

		const approximatePagesCount = this.dataService.report.value.students.map(x => Math.ceil(x.tests.length / 6)).reduce((a, b) => a + b, 0);
		if (approximatePagesCount > 20) {
			return this.httpClient.ESGIApi.post<{reportGuid: string}>('reports/student-detail', 'StartBackgroundGeneration', requestModel)
				.pipe(
					tap((resp) => {
						const args: BackgroundDownloadManagerEvents.StartArgs = {
							reportGuid: resp.reportGuid,
							fileType: zip ? 'ZIP' : 'PDF',
							displayRemainingText: zip,
						};
						this.eventBus.dispatch(BackgroundDownloadManagerEvents.Start, args);
					}),
				).asObservable();
		} else {
			const name = getFileName(!zip);
			if (isIOS()) {
				return this.httpClient.ESGIApi.file('reports/student-detail', 'JsonDownloadPdf', name, {json: JSON.stringify(requestModel)});
			} else {
				return this.httpClient.ESGIApi.file('reports/student-detail', 'DownloadPdf', name, requestModel, null, true);
			}
		}

		if (this.selectedStudent.value?.[0]?.studentID === 0) {
			const eventType = zip ? 'PDF Bulk' : 'PDF Standard';
			SsoTracker.trackEvent({
				trackingEvent: eventType,
				data: {reportType: 'Detail'},
			});
		}
	}

}
