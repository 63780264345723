import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {Loader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Label, Option} from '@esgillc/ui-kit/form-control';
import {DistrictAdminService} from './service';
import {TabFooter} from '../../../../tab-footer';
import {LocationFormProps} from '../../types';
import {ManagedCheckboxesList} from '../../components/managed-checkboxes-list';
import {RemoveFromClassesAlert} from '../../components/remove-from-classes-alert';
import {RemoveFromGroupsCheckbox} from '../../components/remove-from-groups-checkbox';
import {StudentProfileMode, StudentProfileTab} from 'modules/forms/students-form/types';
import styles from '../../styles.module.less';

export function DistrictAdminLocation({studentID, mode, initData, preSelected, tabsApi, onClose}: LocationFormProps) {

	const service = useService(DistrictAdminService);
	const schools = useBehaviorSubject(service.schools);
	const teachers = useBehaviorSubject(service.teachers);
	const classes = useBehaviorSubject(service.classes);
	const groups = useBehaviorSubject(service.groups);
	const serviceLoading = useBehaviorSubject(service.serviceLoading);
	const tabs = useBehaviorSubject(service.tabsStatus);
	const selectedClasses = useBehaviorSubject(service.selectedClasses);

	const [isShowCheckbox, setShowCheckbox] = useState(!!selectedClasses.length);
	const [isShowRemoveFromClassesAlert, setShowRemoveFromClassesAlert] = useState(false);
	const [isInitialized, setInitialized] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const onSave = (isSaveAndAdd?: boolean) => {
		if (tabs[StudentProfileTab.Location]?.isTouched) {
			service.save(isSaveAndAdd).subscribe(() => {
				if (!isSaveAndAdd) {
					onClose();
				}
			});
		} else {
			if (!isSaveAndAdd) {
				onClose();
			}
		}
	};

	const handelCheckboxClicked = () => {
		service.form.controls.classIDs.value = [];
		service.form.controls.groupIDs.value = [];
		setShowCheckbox(false);
	};

	useEffect(() => {
		setLoading(true);
		service.init({initData, mode, studentID, preSelected, tabsApi}).subscribe({
			complete: () => {
				setInitialized(true);
				setLoading(false);
			},
		});
	}, []);

	useEffect(() => {
		setShowCheckbox(Boolean(selectedClasses.length));
	}, [selectedClasses.length]);

	return <div data-cy='district-admin-location-form'>
		<Loader show={isLoading || serviceLoading} fullscreen/>
		{isInitialized && <><Form controller={service.form}>
			<div className={styles.row}>
				<FormElement control={service.form.controls.school} className={styles.column}>
					<Label className={styles.topLabel}>School</Label>
					<div data-cy='school'>
						<Dropdown optionName='name' tabIndex={3} placeholder='Select School'>
							{schools.map(v => <Option key={v.schoolID} value={v}>{v.name}</Option>)}
						</Dropdown>
					</div>
				</FormElement>

				<FormElement control={service.form.controls.teacher} className={styles.column}>
					<Label className={styles.topLabel}>Teacher</Label>
					<div data-cy='teacher'>
						<Dropdown optionName='fullName' tabIndex={3} placeholder='Select Teacher'>
							{teachers.map(v => <Option key={v.userID} value={v}>{v.fullName}</Option>)}
						</Dropdown>
					</div>
				</FormElement>
			</div>

			{mode !== StudentProfileMode.view && <RemoveFromGroupsCheckbox
				label='Remove student from all Classes / Groups'
				onClick={() => setShowRemoveFromClassesAlert(true)}
				isChecked={isShowCheckbox}
			/>}

			<div className={styles.row}>
				<ManagedCheckboxesList
					data-cy='classes-checkbox-list'
					listName='Classes'
					list={classes}
					control={service.form.controls.classIDs}
				/>
				<ManagedCheckboxesList
					data-cy='groups-checkbox-list'
					listName='Groups'
					list={groups}
					control={service.form.controls.groupIDs}
				/>
			</div>
		</Form>
			{isShowRemoveFromClassesAlert && <RemoveFromClassesAlert
				teacherFullName={service.form.controls.teacher.value?.[0]?.fullName}
				studentFullName={`${initData.general.firstName} ${initData.general.lastName}`}
				selectedClasses={selectedClasses}
				onRemove={handelCheckboxClicked}
				onCancel={() => setShowRemoveFromClassesAlert(false)}
			/>}

			<TabFooter
				tabsApi={tabs}
				canSaveAndAdd={initData.isOpenedWithAdd}
				currentTab={StudentProfileTab.Location}
				mode={mode}
				onSave={() => onSave(false)}
				onSaveAndAdd={() => onSave(true)}
			/>
		</>
		}</div>;
}
