import React from 'react';
import {Models} from 'shared/modules/reports/flash-cards/results-form/models';
import Card from 'shared/modules/reports/flash-cards/results-form/components/card/component';
import {ListRowProps} from 'react-virtualized';
import {AutoSizer, List, CellMeasurer, CellMeasurerCache} from '@esgi/deprecated/old-libs';

class Props {
	students: Models.Student[];
	subjectName: string;
	fullHeight: boolean;
}

export default class Results extends React.PureComponent<Props> {
	private cache: CellMeasurerCache;

	constructor(props?: Props) {
		super(props);
		this.cache = new CellMeasurerCache({
			fixedWidth: true,
		});
	}

	private renderCardRows() {
		if (this.props.students.length) {
			return <AutoSizer>
				{(props) => {
					return <List
						width={props.width}
						height={props.height}
						rowHeight={this.cache.rowHeight}
						overscanRowCount={0}
						rowRenderer={(props) => this.renderEachStudentRow(props)}
						rowCount={this.props.students.length}
						deferredMeasurementCache={this.cache}
					/>;
				}}
			</AutoSizer>;
		}
	}

	private renderEachStudentRow(props: ListRowProps) {
		const {key, style, index, parent} = props;
		const student = this.props.students[index];
		if (!student) {
			console.error('Error. Can\'t find student', props);
			return <div key={key} style={style}/>;
		}
		return <CellMeasurer
			cache={this.cache}
			columnIndex={0}
			key={key}
			parent={parent}
			rowIndex={index}
		>
			{({registerChild}) => (
				<div ref={registerChild} key={key} className='student-data' style={style}>
					<p className='student-name'>{student.fullName}</p>
					<p className='subject-name'>{this.props.subjectName}</p>
					{student.cardRows.map((cardsRow, index) =>
						<Card
							key={index}
							cardsRow={cardsRow}
						/>,
					)}
				</div>
			)}
		</CellMeasurer>;

	}

	render() {
		return <div className={this.props.fullHeight ? 'flash-cards increased-body' : 'flash-cards'}>
			{this.renderCardRows()}
		</div>;
	}
}
