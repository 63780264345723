import {Option} from '@esgi/main/kits/reports';
import {NotTested} from './types';

export const items: Option[] = [
	{
		id: NotTested.NT,
		label: 'NT',
	},
	{
		id: NotTested.Zero,
		label: 'Zero',
	},
];
