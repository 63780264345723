import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {AvatarImageProps as RadixAvatarImageProps} from '@radix-ui/react-avatar';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {StyledAvatarImage} from './styled';

type AvatarImageProps =
	ComponentPropsWithoutRef<'image'>
	& BaseComponentProps
	& Skeletonable
	& RadixAvatarImageProps

export const AvatarImage = forwardRef<HTMLImageElement, AvatarImageProps>(({
	dataCy = 'ui-kit-avatar-image',
	...props
}: AvatarImageProps, ref) => {
	return <StyledAvatarImage data-cy={dataCy} ref={ref} {...props}/>;
});
