import './styles.less';
import React from 'react';
import {EventBusDispatcher} from '@esgillc/events';

class Props {
	scope: string;
}

export module SearchHintEvents {
	export function Clicked() {

	}
}

export class SearchHint extends React.PureComponent<Props> {
	render() {
		if (this.props.scope != 'MyTests' && this.props.scope != 'DistrictTests') {
			return null;
		}

		return <div className='search-hint'>Search: <a href='#' onClick={() => this.clicked()}>All Tests</a></div>;
	}

	clicked() {
		EventBusDispatcher.dispatch(SearchHintEvents.Clicked, {});
	}
}