import React from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import TableService from '../../../services/table-service';
import ColumnHeader from './column-header';
import styles from '../table.module.less';

class Props {
	showSpecialistColumns: boolean;
	specialistLabel: string;
	tableService: TableService;
}

export default class TableHeader extends React.PureComponent<Props> {
	public render() {
		const currentUser = userStorage.get();
		return <thead>
		<tr>
			<th className={styles.checkboxColumnHeader}/>
			<ColumnHeader className={styles.studentNameColumnHeader} columnName='student' displayName='Students' tableService={this.props.tableService}/>
			{currentUser.userType === UserType.D &&
				<ColumnHeader columnName='school'
				              displayName='School'
				              tableService={this.props.tableService}/>}
			<ColumnHeader columnName='teacher' displayName='Primary Teacher' tableService={this.props.tableService}/>
			<ColumnHeader columnName='class' displayName='Class' tableService={this.props.tableService}/>
			<ColumnHeader columnName='group' displayName='Group' tableService={this.props.tableService}/>
			{this.props.showSpecialistColumns &&
				<ColumnHeader columnName='specialist'
				              displayName={this.props.specialistLabel}
				              tableService={this.props.tableService}/>}
			{this.props.showSpecialistColumns &&
				<ColumnHeader columnName='specialistgroup'
				              displayName={this.props.specialistLabel + ' Group'}
				              tableService={this.props.tableService}/>}
			<ColumnHeader columnName='gradelevel' displayName='Grade' tableService={this.props.tableService}/>
			<ColumnHeader columnName='createDate' displayName='Create Date' tableService={this.props.tableService}/>
			<ColumnHeader columnName='studentID' displayName='Student ID' tableService={this.props.tableService}/>
		</tr>
		</thead>;
	}
}
