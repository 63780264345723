import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {AddButton, ContextMenuItem, Layout, SearchInput} from './index.styled';
import {Button, Plus, Image, Tooltip, Search, ContextMenu, Magic, Edit} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useEffect, useState} from 'react';
import {TestExplorerService} from '../../service/service';
import {ItemsCounter} from '@esgi/main/features/standards-drawer';

type Props = {
	setShowTestCreatorLauncher: VoidFunction,
	setShowAutoTestCreatorLauncher: VoidFunction,
	setShowImageGallery: VoidFunction,
	service: TestExplorerService,
	isPortraitTablet: boolean,
	testsCount: number,
}

export function Header({
	setShowTestCreatorLauncher,
	setShowAutoTestCreatorLauncher,
	setShowImageGallery,
	service,
	isPortraitTablet,
	testsCount,
}: Props) {
	const [debouncedKeyword, setDebouncedKeyword] = useState('');

	useEffect(() => {
		const delayKeywordTimeoutId = setTimeout(() => {
			if (debouncedKeyword !== service.keyword$.value) {
				service.keyword$.next(debouncedKeyword);
			}
		}, 500);

		return () => clearTimeout(delayKeywordTimeoutId);
	}, [debouncedKeyword, service.keyword$]);
	
	return (
		<Layout>
			<GridBox gap={2} flow='column' justify='start' align='center'>
				<SearchInput placeholder='Search' value={debouncedKeyword} onChange={e => setDebouncedKeyword(e.target.value)} dataCy='search-input'>
					<Search/>
				</SearchInput>
				{isPortraitTablet && <ItemsCounter itemsCount={testsCount} label='Tests'/>}
			</GridBox>
			<FlexBox align='center'>
				<Tooltip>
					<Tooltip.Trigger>
						<Button.Icon onClick={setShowImageGallery} dataCy='image-gallery-button'>
							<Image/>
						</Button.Icon>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>Find and upload images to use in your tests.</Text>
					</Tooltip.Content>
				</Tooltip>
				<ContextMenu.Root>
					<ContextMenu.Trigger>
						<Box>
							<Tooltip>
								<Tooltip.Trigger>
									<AddButton withBackgroundHover variant='filled' color='secondary' dataCy='add-tests-button'>
										<Plus/>
									</AddButton>
								</Tooltip.Trigger>
								<Tooltip.Content variant='secondary'>
									<Text size='small'>Create Test</Text>
								</Tooltip.Content>
							</Tooltip>
						</Box>
					</ContextMenu.Trigger>
					<ContextMenu.Content>
						<ContextMenu.Group>
							<ContextMenuItem onClick={setShowTestCreatorLauncher}>
								<Edit />
								<Text size='medium' bold>Create Test</Text>
							</ContextMenuItem>
							<ContextMenuItem onClick={setShowAutoTestCreatorLauncher}>
								<Magic />
								<Text size='medium' bold>Auto Test Creator</Text>
							</ContextMenuItem>
						</ContextMenu.Group>
					</ContextMenu.Content>
				</ContextMenu.Root>
			</FlexBox>
		</Layout>
	);
}