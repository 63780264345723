import {AppEvent} from '@esgillc/events';

@AppEvent({broadcast: true})
export class TestSavedEvent {
	constructor(
		public data: {
			testID: number;
			studentID: number;
			correct?: number;
			testSessionID?: number;
		},
	) {}
}

export class TestSessionStatusUserMovedEvent {}
