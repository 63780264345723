import {MouseEvent, forwardRef, useCallback} from 'react';
import {BaseButton, BaseButtonProps} from '../../../../buttons';
import {styled} from '@esgi/ui/theme';
import {useExpandablePanelContext} from '../../context';
import {Expand} from '@esgi/ui/icons';
import {Tooltip} from '../../../../tooltip';
import {Text} from '../../../../typography';

type ExpandablePanelTriggerProps = Omit<BaseButtonProps, 'uppercase' | 'children'>;

export const ExpandablePanelTrigger = forwardRef<HTMLButtonElement, ExpandablePanelTriggerProps>(
	({dataCy, onClick, disabled, css = {}, ...props}, forwaredRef) => {
		const {open, setOpen, panelIsHovered} = useExpandablePanelContext();

		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				if (!disabled) {
					onClick?.(event);
				}
			},
			[setOpen, onClick, open, disabled],
		);

		return (
			<Tooltip>
				<Tooltip.Trigger>
					<Button
						dataCy={dataCy ?? 'expandable-panel-trigger'}
						ref={forwaredRef}
						onClick={handleClick}
						disabled={disabled}
						css={css}
						data-expandable-panel-open={open}
						data-expandable-panel-hovered={panelIsHovered}
						{...props}
					>
						<BorderIcon xmlns='http://www.w3.org/2000/svg'>
							<BorderIconPath d='M16.7678 58.4428L17.5 58.8269L17.5 58L17.5 2L17.5 1.17315L16.7678 1.55721L17 2C16.7678 1.55721 16.7677 1.55727 16.7675 1.55734L16.767 1.5576L16.7653 1.5585L16.759 1.5618L16.7353 1.57436C16.7146 1.58536 16.6841 1.6016 16.6445 1.62291C16.5652 1.66553 16.4492 1.72845 16.3008 1.81035C16.0042 1.97414 15.5782 2.21395 15.0583 2.51936C14.0189 3.12989 12.6018 4.00394 11.0915 5.05793C8.09577 7.1485 4.64141 10.0087 3.11804 12.9714C1.51869 16.0818 0.884556 20.3682 0.641429 23.8263C0.519198 25.5649 0.494764 27.1125 0.500854 28.2257C0.5039 28.7826 0.514587 29.2313 0.52455 29.5415C0.529533 29.6966 0.534335 29.8172 0.537914 29.8993L0.542234 29.9933L0.542567 30L0.542234 30.0067L0.537914 30.1007C0.534335 30.1828 0.529532 30.3034 0.52455 30.4585C0.514587 30.7687 0.5039 31.2174 0.500854 31.7742C0.494763 32.8875 0.519197 34.4351 0.641428 36.1737C0.884555 39.6318 1.51869 43.9182 3.11804 47.0286C4.64141 49.9913 8.09576 52.8515 11.0915 54.9421C12.6018 55.9961 14.0189 56.8701 15.0583 57.4806C15.5782 57.786 16.0042 58.0259 16.3008 58.1897C16.4492 58.2716 16.5652 58.3345 16.6445 58.3771C16.661 58.386 16.676 58.394 16.6893 58.4011C16.7078 58.411 16.7232 58.4192 16.7353 58.4256L16.759 58.4382L16.7653 58.4415L16.767 58.4424L16.7675 58.4427C16.7676 58.4427 16.7678 58.4428 17 58L16.7678 58.4428Z' />
						</BorderIcon>
						<ExpandIcon />
					</Button>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text size='small'>{open ? 'Collapse' : 'Expand'}</Text>
				</Tooltip.Content>
			</Tooltip>
		);
	},
);

const BorderIcon = styled('svg', {
	width: '100%',
	height: '100%',
	fill: 'none',
});

const BorderIconPath = styled('path', {
	fill: '$vivid',
});

const ExpandIcon = styled(Expand, {
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	margin: 'auto',
	scale: 2,
	fill: 'none',
	pointerEvents: 'none',
	transition: 'transform .3s ease',
});

const Button = styled(BaseButton, {
	width: 16,
	height: 60,
	position: 'absolute',
	right: 0,

	'&[data-expandable-panel-open=true]': {
		[`& ${BorderIcon}`]: {
			'& path': {
				stroke: '$border',
			},
		},

		[`& ${ExpandIcon}`]: {
			transform: 'rotate(90deg)',

			'& path': {
				fill: '$neutral72',
			},
		},
	},

	'&[data-expandable-panel-open=false]': {
		[`& ${BorderIcon}`]: {
			'& path': {
				stroke: '$primaryMuted',
			},
		},

		[`& ${ExpandIcon}`]: {
			transform: 'rotate(-90deg)',

			'& path': {
				fill: '$primary',
			},
		},
	},

	'&:hover, &[data-expandable-panel-open=false][data-expandable-panel-hovered=true]': {
		'&:not(:disabled)': {
			[`& ${BorderIcon}`]: {
				'& path': {
					stroke: '$secondaryMuted',
				},
			},

			[`& ${ExpandIcon}`]: {
				'& path': {
					fill: '$secondary',
				},
			},
		},
	},
});
