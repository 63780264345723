import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Assignment} from '../types';
import {routes} from '@esgi/main/libs/core';

export type OpenAssignmentFn = (args: {assignmentId: Assignment['id']; replace?: boolean}) => void;

export function useOpenAssignment() {
	const navigate = useNavigate();

	return useCallback<OpenAssignmentFn>(
		({assignmentId, replace}) => {
			navigate(routes.teacher.assignments.assignment.replace(':assignmentId', String(assignmentId)), {
				replace: Boolean(replace),
			});
		},
		[navigate],
	);
}
