import {useMemo, useState, useEffect} from 'react';
import {NoteEditor} from './components/note-editor';
import {Checkbox} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Answer as AnswerType} from '../../../../../../types';
import {useTestSessionDetailsService} from '../../../../../../hooks';
import {AnswerState} from './types';
import styles from './styles.module.less';

interface Props {
	answerState: AnswerState;
	answer: AnswerType;
	notTested: boolean;
	selected: boolean;
	onChange: (state: AnswerState) => void;
	onNoteSave: (noteText: string) => void;
	onNoteDelete: () => void;
	canEdit: boolean;
}

export function Answer({
	answerState,
	answer,
	notTested,
	selected,
	onChange,
	onNoteSave,
	onNoteDelete,
	canEdit,
}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const editMode = useBehaviorSubject(service.editMode$);
	const [state, setState] = useState(answerState);

	const readOnly = useMemo(() => {
		return testSessionDetails ? !testSessionDetails.canEdit: true;
	}, [testSessionDetails]);

	const className = useMemo<string>(() => {
		const result = [styles.answer, `answer-${answer.id}-row`];

		if (selected) {
			result.push(styles.selected);
		}

		if (editMode) {
			result.push(styles.hoverable);
		}

		if (answer.comment || answerState.note.on) {
			result.push(styles.existComment);
		}

		return result.join(' ');
	}, [answer, answerState, selected, editMode]);

	const setSelected = (selected: boolean) => {
		setState({
			...state,
			selected,
		});
	};

	useEffect(() => {
		onChange(state);
	}, [state]);

	useEffect(() => {
		setSelected(selected);
	}, [selected]);

	return (
		<div className={className}>
			<div>
				{editMode &&
					<Checkbox
						id={answer.questionId.toString()}
						checked={state.selected}
						onChange={(e) => setSelected(e.target.checked)}
					/>
				}
				<label
					htmlFor={answer.questionId.toString()}
					className={styles.questionText}
					title={answer.text}
				>
					{answer.text}
				</label>
			</div>

			{(answer.sessionId && (!readOnly && !notTested && !editMode)) &&
				<div className={styles.right}>
					<NoteEditor
						question={answer.text}
						note={answer.comment}
						onChange={(note) => setState({...state, note})}
						onSave={onNoteSave}
						onDelete={onNoteDelete}
						canEdit={canEdit}
					/>
				</div>
			}
		</div>
	);
}
