import React, {ComponentProps, useEffect, useState} from 'react';
import {Alert} from '@esgi/ui/alert';
import {
	Button, CroppedImage,
	ImageCropper,
	Tooltip,
	Upload,
} from '@esgi/ui';
import {Info} from '@esgi/ui/icons';
import {ImageData, Photo} from '../../types';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {errorMessages, tooltipMessage, fileTypes, maxFileSize, defaultZoomValueRange} from './constants';
import {InfoIconWrapper, BodyContainer, AlertBody, Description, Container} from './index.styled';

interface Props {
	imageData?: Photo;
	onClose: VoidFunction;
	onSave: (data: ImageData) => void;
	portalProps?: ComponentProps<typeof Alert>['portalProps'];
}

export function UploadPhotoAlert(props: Props) {
	const {imageData, onSave, onClose, portalProps} = props;

	const alertRef = Alert.useRef();
	const close = Alert.useClose(alertRef, onClose);
	const initialCrop = imageData?.crop || {x: 0, y: 0, zoom: 1};

	const [uploadPhoto, setUploadPhoto] = useState<Photo>(imageData || null);
	const [isSaveDisabled, setSaveDisabled] = useState(true);
	const [isTouched, setTouched] = useState(false);

	useEffect(() => {
		setTouched(initialCrop.zoom !== uploadPhoto?.crop?.zoom || initialCrop.x !== uploadPhoto?.crop?.x || initialCrop.y !== uploadPhoto?.crop?.y);
	}, [uploadPhoto?.crop]);

	useEffect(() => {
		setSaveDisabled(!isTouched);
	}, [isTouched]);

	const onSubmit = () => {
		onSave({
			image: uploadPhoto.imageUrl,
			croppedImage: uploadPhoto.imageCropUrl,
			cropParameters: uploadPhoto.crop,
		});
	};

	const handelCropChange = (cropData: CroppedImage) => {
		setUploadPhoto(prev => ({
			...prev,
			imageUrl: cropData.image,
			imageCropUrl: cropData.croppedImage,
			crop: cropData.crop,
		}));
	};

	return <Alert portalProps={portalProps} modalManagerRef={alertRef}>
		<Alert.Header withBacklight withCloseIcon onCloseIconClick={close}>
			<FlexBox align='center'>
				<Text size='large' color='neutral40'>Crop Photo</Text>
				<Tooltip delayDuration={400}>
					<Tooltip.Trigger>
						<InfoIconWrapper>
							<Info/>
						</InfoIconWrapper>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary' side='right'>
						<Text size='small' color='neutral24'>{tooltipMessage}</Text>
					</Tooltip.Content>
				</Tooltip>
			</FlexBox>
		</Alert.Header>

		<AlertBody>
			<BodyContainer>
				<Description>
					<Text size='medium' color='neutral24'>Drag and zoom in or out to adjust the photo.</Text>
				</Description>
				<ImageCropper
					imageData={{
						croppedImage: uploadPhoto?.imageCropUrl,
						image: uploadPhoto?.imageUrl,
						crop: uploadPhoto?.crop,
					}}
					onCropChange={handelCropChange}>

					{uploadPhoto?.imageCropUrl
						? <Container>
							<ImageCropper.Cropper cropShape='rect'/>
							<ImageCropper.Slider min={defaultZoomValueRange.min} max={defaultZoomValueRange.max}/>
						</Container>
						: <Container>
							<ImageCropper.Uploader
								fileTypes={fileTypes}
								maxFileSize={maxFileSize}
								errorMessages={errorMessages}
							>
								<Upload width={64} height={64}/>
							</ImageCropper.Uploader>
							<ImageCropper.ErrorMessage/>
						</Container>}
				</ImageCropper>
			</BodyContainer>
		</AlertBody>

		<Alert.Footer align='center' gapX={3}>
			<Button color='tertiary' onClick={close}>
				<Text size='medium' font='mono' bold>Cancel</Text>
			</Button>
			<Button color='secondary' onClick={onSubmit} disabled={isSaveDisabled}>
				<Text size='medium' font='mono' bold>Save</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}

