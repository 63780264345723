import {Button, useStreamEffect} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {
	ElementStatus,
	ErrorTooltip,
	Form,
	FormControl,
	FormElement,
	FormGroup,
	Validators,
	useForm,
} from '@esgi/ui/form';
import {Input} from '@esgi/ui/form-controls';
import {SubjectLevel, useSubjects} from '@esgi/main/libs/store';
import {useCallback, useEffect, useState} from 'react';
import {maxSubjectNameLength, validations} from '../constants';
import {of, switchMap} from 'rxjs';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {dispatchAppEvent} from '@esgillc/events';
import {SubjectCreatedEvent} from '../../events';
import {FormInfoBox} from './index.styled';
import {initialSubjectName} from './constants';
import {isNull} from 'underscore';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = {
	forceClose?: boolean;
	onClose: VoidFunction;
};

export function CreatePersonalSubject({onClose, forceClose}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isOpenNoChangesConfirmation, setIsOpenNoChangesConfirmation] = useState(false);

	const [isSaveDisabled, setIsSaveDisabled] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [{data: subjectsList, loaded: isSubjectsLoaded}] = useSubjects();

	const service = useService(Service);

	const form = useForm(
		() =>
			new FormGroup({
				subjectName: new FormControl(initialSubjectName, {
					validators: [Validators.required(), Validators.length.max(maxSubjectNameLength)],
					disableValueComparison: false,
				}),
			}),
	);

	useEffect(() => {
		if (isSubjectsLoaded) {
			form.controls.subjectName.validators.push(
				Validators.isDublicateValue(
					subjectsList.filter(({level}) => level === SubjectLevel.Teacher).map(({name}) => name),
				),
			);
		}
	}, [form.controls.subjectName.validators, isSubjectsLoaded, subjectsList]);

	useStreamEffect(form.controls.subjectName.onChanged, ({reason, currState}) => {
		setIsSaveDisabled(reason === 'status' && currState.status === ElementStatus.invalid);
	});

	useStreamEffect(form.onChanged, ({currState}) => {
		setIsFormTouched(currState.value.subjectName !== initialSubjectName);
	});

	const onCloseAlert = useCallback(() => {
		if (isFormTouched) {
			setIsOpenNoChangesConfirmation(true);
			return;
		}

		if (forceClose) {
			onClose();
			return;
		}

		closeAlert();
	}, [closeAlert, forceClose, isFormTouched, onClose]);

	const handleSaveSubject = useCallback(() => {
		setIsSaving(true);

		form
			.validate()
			.pipe(
				switchMap(({valid}) => {
					if (valid) {
						return service.createPersonalTab(form.value.subjectName);
					}

					return of(null);
				}),
			)
			.subscribe((newSubject) => {
				if (isNull(newSubject)) {
					return;
				}

				dispatchAppEvent(SubjectCreatedEvent, new SubjectCreatedEvent(newSubject));

				setIsSaving(false);
				closeAlert();
			});
	}, [closeAlert, form, service]);

	const onCloseNoChangesConfirmation = useCallback(() => {
		setIsOpenNoChangesConfirmation(false);
	}, []);

	const onCloseAnywayNoChangesConfirmation = useCallback(() => {
		setIsOpenNoChangesConfirmation(false);
		closeAlert();
	}, [closeAlert]);

	return (
		<>
			<Alert dataCy='create-personal-subject-alert' modalManagerRef={alertRef}>
				<Alert.Header onCloseIconClick={onCloseAlert} withBacklight={false}>
					<Text data-cy='title' size='small' color='base'>
						Add a New Subject
					</Text>
				</Alert.Header>
				<Alert.Body>
					<FormInfoBox>
						<Text data-cy='prompt' size='medium' color='neutral40'>
							Enter the name of the new subject tab below.
						</Text>

						<Form controller={form}>
							<FormElement control={form.controls.subjectName}>
								<Input.Base dataCy='subject-name-input' placeholder='Subject Name' autoFocus />

								{validations.map(({showOnError, message}, index) => (
									<ErrorTooltip showOnError={showOnError} key={index}>
										<Text data-cy='error-text' size='xSmall' font='mono' color='negativeVivid'>
											{message}
										</Text>
									</ErrorTooltip>
								))}
							</FormElement>
						</Form>
					</FormInfoBox>
				</Alert.Body>
				<Alert.Footer>
					<GridBox gap='3' flow='column'>
						<Button color='tertiary' onClick={onCloseAlert}>
							<Text size='medium' bold color='base'>
								Cancel
							</Text>
						</Button>
						<Button
							color='secondary'
							disabled={isSaveDisabled || isSaving || !isSubjectsLoaded || !isFormTouched}
							onClick={handleSaveSubject}
						>
							<Text size='medium' bold color='base'>
								Add
							</Text>
						</Button>
					</GridBox>
				</Alert.Footer>
			</Alert>

			{isOpenNoChangesConfirmation && (
				<UnsavedChangesConfirmation
					onCloseAnyway={onCloseAnywayNoChangesConfirmation}
					onClose={onCloseNoChangesConfirmation}
				/>
			)}
		</>
	);
}
