import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CloseIcon, Title} from '@esgillc/ui-kit/modal';
import {ModalBody, ModalFooter, ModalHeader, Modal} from '@esgi/deprecated/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {PieChartService} from './service/service';
import Header from './components/header/header';
import Body from './components/body/body';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {ChangesCollector} from '../utils/changes-collector';

import styles from './report.module.less';

export interface PieChartReportProps {
	hierarchy: HierarchySnapshot;
	subjectID: number;
	changeCollector: ChangesCollector;
	close: () => void;
}


export default function PieChartReport ({hierarchy, close, changeCollector, subjectID}: PieChartReportProps) {
	const ref = useRef(null);

	const service = useService(PieChartService);
	const settings = useBehaviorSubject(service.settings);
	const gradeLevelID = useBehaviorSubject(service.gradeLevelID);
	const showEachStudentEnabled = useBehaviorSubject(service.showEachStudentEnabled);
	const showLoader = useBehaviorSubject(service.showLoader);
	const data = useBehaviorSubject(service.reportData);
	const studentGradeLevels = service.studentGradeLevels;

	const [showDropdown, setShowDropdown] = useState(false);

	const download = useCallback((_, zip?: boolean) => {
		service.download(hierarchy, zip);
	}, [hierarchy]);

	const onClose = useCallback(() => {
		const {classID, groupID, specialistGroupID, studentID, subjectID, schoolID, teacherID, subjects} = settings;
		const subjectType = subjects.find(x => x.subjectID === subjectID)?.subjectType;

		changeCollector.applyChanges({
			classID: classID,
			groupID: groupID || specialistGroupID,
			studentID: studentID,
			subjectTabID: subjectID,
			subjectTabType: subjectType,
			schoolID: schoolID,
			teacherID: teacherID,
		});

		close();
	}, [settings]);

	useEffect(() => {
		const listener = (event) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			setShowDropdown(false);
		};

		document.addEventListener('click', listener);

		return () => document.removeEventListener('click', listener);
	}, []);

	return (
		<Modal className={styles.modal}>
			<Loader show={showLoader} fullscreen/>
			<ModalHeader className={styles.header}>
				<Title className={styles.title}>
					Pie Charts
				</Title>
				{settings && !(data && data.students.length === 0) &&
					<div className={styles.headerButtons} onClick={(e) => e.stopPropagation()}>
						<div>
							{settings.studentID > 0 &&
								<span className={styles.downloadLink} onClick={download}>
									<i className='fa fa-download'/> Download
								</span>
							}
							{settings.studentID === 0 &&
								<>
									<span
										className={styles.downloadLink}
										onClick={() => setShowDropdown(!showDropdown)}
									>
										<i className='fa fa-download'/> Download
									</span>
									{
										showDropdown &&
										<ul className={styles.dropdown} ref={ref}>
											<li className={styles.dropdownItem} onClick={download} >
												<div>One PDF file</div>
												<div>per student or class</div>
											</li>
											<li className={styles.dropdownItem} onClick={(_) => download(_, true)}>
												<div>ZIP</div>
												<div>Individual PDF files for each student in the class</div>
											</li>
										</ul>
									}
								</>
							}
						</div>
					</div>
				}
				<CloseIcon className={styles.closeIcon} color={'#fff'} onClick={onClose}/>
			</ModalHeader>
			<ModalBody className={styles.body}>
				<Header
					service={service}
					hierarchy={hierarchy}
					subjectTabID={subjectID}
					noStudentsData={data?.students.length === 0}
					studentGradeLevels={studentGradeLevels}
				/>
				<Body
					service={service}
					hierarchy={hierarchy}
					data={data}
					sortBy={settings.sortBy}
					schoolID={settings.schoolID}
					schools={settings.schools}
					teacherID={settings.teacherID}
					classID={settings.classID}
					groupID={settings.groupID}
					specialistGroupID={settings.specialistGroupID}
					studentID={settings.studentID}
					subjects={settings.subjects}
					showEachStudent={showEachStudentEnabled && settings.showEachStudent}
					gradeLevelID={gradeLevelID}
					printInColor={settings.printInColor}
					testResultsCorrectVerbiage={settings.testResultsCorrectVerbiage}
					testResultsIncorrectVerbiage={settings.testResultsIncorrectVerbiage}
				/>
			</ModalBody>
			<ModalFooter className={styles.footer}/>
		</Modal>
	);
}
