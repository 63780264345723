import {useEffect, useRef, useState} from 'react';
import {filter} from 'rxjs/operators';
import {UserInfo, userStorage, useUser} from '@esgi/core/authentication';
import {DesignVersion, useDesignVersion} from '@esgi/main/libs/core';
import {useEventEffect} from '@esgillc/events';
import {ESGI25518LongResponseTime} from '@esgi/main/features/assessments';

const getLogRocketConfig = () => {
	const isAI = location.host === 'appai.esgisoftware.com';
	const isProd = location.host === 'app.esgisoftware.com';

	const options = {
		mergeIframes: true,
	};

	if (isAI) {
		return {
			isEnabled: true,
			appID: 'qqmiuo/esgi_ai',
			options,
		};
	}

	if (isProd) {
		return {
			isEnabled: true,
			appID: 'qqmiuo/esgi_prod',
			options,
		};
	}

	return {
		isEnabled: false,
		appID: null,
		options: null,
	};
};

function identityLogRocketWithUser(user: UserInfo, logRocket: any, design: DesignVersion, userBackToOldVersion: boolean, esgi25518: boolean): void {
	if (user?.userID) {
		logRocket.identify(user.userID, {
			email: user.email,
			name: user.firstName + ' ' + user.lastName,
			userID: user.userID,
			release: design === DesignVersion.Old ? 'latest' : 'redesign',
			OldVersion: userBackToOldVersion,
			ESGI25518: esgi25518,
		});
	}
}

export function useLogRocket() {
	const initializedUser = useUser();
	const [design] = useDesignVersion();
	const lastDesignVersion = useRef<DesignVersion>(design);
	const userBackToOldVersion = useRef<boolean>(false);
	const [esgi25518, setEsgi25518] = useState(false);

	useEventEffect(ESGI25518LongResponseTime, () => setEsgi25518(true));

	useEffect(() => {
		const logRocketConfig = getLogRocketConfig();
		if (!logRocketConfig.isEnabled) {
			return;
		}

		const logrocket = require('logrocket');
		logrocket.init(logRocketConfig.appID, logRocketConfig.options);

		if (!userBackToOldVersion.current) {
			userBackToOldVersion.current = (lastDesignVersion.current === DesignVersion.Redesign && design === DesignVersion.Old);
		}

		identityLogRocketWithUser(initializedUser, logrocket, design, userBackToOldVersion.current, esgi25518);

		const sub = userStorage.onChanged$
			.pipe(filter(user => user?.userID !== initializedUser?.userID))
			.subscribe((user) => {
				if (!user) {
					return;
				}

				logrocket.startNewSession();
				identityLogRocketWithUser(user, logrocket, design, userBackToOldVersion.current, esgi25518);
			});

		lastDesignVersion.current = design;

		return () => {
			sub.unsubscribe();
		};
	}, [initializedUser, design, esgi25518]);
}