import {CheckImportFilesResponse, ReportData} from './models';
import {Import} from 'api/import-api';
import {HttpClient} from '@esgi/api';
import {Observable, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';

interface DownloadItemBase {
	reportGuid: string;
	fileID: number;
	finished: boolean;
	type: string;
}

export abstract class DownloadItem implements DownloadItemBase{
	reportGuid: string;
	fileID: number;
	finished: boolean;
	type: string;
	fileName: string;
	error: boolean;

	static create(item: DownloadItemBase): DownloadItem{
		let c: DownloadItem;
		if (item.type === 'ReportDownloadItem'){
			c = new ReportDownloadItem(item.reportGuid);
		}
		if (item.type === 'ImportDownloadItem'){
			c = new ImportDownloadItem(item.fileID);
		}

		c.reportGuid = item.reportGuid;
		c.fileID = item.fileID;
		c.finished = item.finished;
		c.error = item.fileID === -1;
		return c;
	}

	abstract checkProgress(): Observable<any>;
	abstract download(): Subscription;
}

export class ReportDownloadItem extends DownloadItem {

	constructor(reportGuid: string){
		super();

		this.reportGuid = reportGuid;
		this.fileID = 0;
		this.finished = false;
		this.type = 'ReportDownloadItem';
		this.error = false;
	}

	checkProgress(): Observable<any> {
		return HttpClient.default.ESGIApi.get<any>('reports/background-generation', 'get-progress', {reportGuid: this.reportGuid})
			.pipe(tap(r => {
				if (r.fileID) {
					this.fileID = r.fileID;
					this.finished = true;
					this.fileName = r.fileName;
					this.error = r.fileID === -1;
				}
			})).asObservable();
	}

	download(): Subscription {
		return HttpClient.default.ESGIApi.file('reports/background-generation', 'get-file', this.fileName, {
			fileID: this.fileID,
		}).subscribe();
	}
}

export class ImportDownloadItem extends DownloadItem {
	private importApi: Import;

	constructor(fileID: number){
		super();

		this.fileID = fileID;
		this.reportGuid = 'Import' + fileID;
		this.finished = false;
		this.type = 'ImportDownloadItem';
		this.importApi = new Import();
	}

	checkProgress(): Observable<any> {
		return this.importApi.checkImportFiles(this.fileID).asObservable().pipe(map((r: CheckImportFilesResponse) => {
			const data = new ReportData();
			if (r.status === 'Complete') {
				this.finished = true;
				data.fileID = this.fileID;
			}
			return data;
		}));
	}

	download() {
		return this.importApi.getZip(this.fileID).subscribe();
	}
}
