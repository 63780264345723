import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const ListItem = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
});

export const CaptionText = styled(Text, {
	color: '$neutral40',
});

export const IndexText = styled(Text, {
	color: '$neutral72',
});
