import {BehaviorSubject, Observable, switchMap, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {ElementStatus} from '@esgi/ui/form';
import {createStudentTestingOptionsForm, equalPinsValidator} from './form';
import {StudentTestingOptions} from '../../../../../types';
import {V2TeachersUserSettingsController} from '@esgi/contracts/esgi';
import {StudentTestingOptionsSaveRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/teachers/modules/user-settings/student-testing-options-save-request';
import {userStorage} from '@esgi/core/authentication';
import {isEqual} from 'underscore';

export class StudentTestingOptionsService extends BaseService {
	public initData = new BehaviorSubject<StudentTestingOptions | null>(null);
	public isUnlockHomepage = new BehaviorSubject<boolean>(false);
	public showSelfAssessOption = new BehaviorSubject<boolean>(false);
	public pin = new BehaviorSubject<string>('');
	public confirmPin = new BehaviorSubject<string>('');
	public form = createStudentTestingOptionsForm();

	private controller = new V2TeachersUserSettingsController();
	private initialPin: string = '';

	public init = (data: StudentTestingOptions) => {
		this.initData.next(data);

		const {lockScreen, screenLockPIN, showSelfAssessOption} = data;

		this.form.value = {
			screenLockPIN,
			confirmPin: screenLockPIN,
			pinsEqual: '',
			lockScreen,
			selfAssessment: showSelfAssessOption,
		};
		this.showSelfAssessOption.next(showSelfAssessOption);
		this.form.controls.selfAssessment.onChanged.subscribe((v) => {
			this.showSelfAssessOption.next(v.currState.value);
		});
		this.isUnlockHomepage.next(this.form.value.lockScreen);
		this.pin.next(screenLockPIN);
		this.confirmPin.next(screenLockPIN);
		this.initialPin = screenLockPIN;

		if (!this.form.value.lockScreen) {
			this.form.controls.screenLockPIN.status = ElementStatus.disabled;
			this.form.controls.confirmPin.status = ElementStatus.disabled;
		}

		this.form.controls.lockScreen.onChanged.subscribe((lock) => {
			const isUnlocked = lock.currState.value;

			this.form.controls.screenLockPIN.status = isUnlocked ? ElementStatus.valid : ElementStatus.disabled;
			this.form.controls.confirmPin.status = isUnlocked ? ElementStatus.valid : ElementStatus.disabled;
			this.isUnlockHomepage.next(isUnlocked);
		});

		this.form.controls.screenLockPIN.validators.push(equalPinsValidator(this.form.controls.confirmPin));
		this.form.controls.confirmPin.validators.push(equalPinsValidator(this.form.controls.screenLockPIN));
	};

	public compareStatesForEquality = () => {

		const {screenLockPIN, showSelfAssessOption, lockScreen} = this.initData.value;

		const initialState = {
			screenLockPIN,
			lockScreen,
			showSelfAssessOption,
			confirmPin: screenLockPIN,
		};

		const currentState = {
			screenLockPIN: this.form.controls.screenLockPIN.value,
			lockScreen: this.form.controls.lockScreen.value,
			showSelfAssessOption: this.form.controls.selfAssessment.value,
			confirmPin: this.form.controls.confirmPin.value,
		};

		return !isEqual(initialState, currentState);
	};

	public save = () => {
		const savedData: StudentTestingOptionsSaveRequest = {
			lockScreen: this.form.controls.lockScreen.value,
			showSelfAssessOption: this.form.controls.selfAssessment.value,
			pin: null,
		};

		if (this.showSelfAssessOption) {
			const {screenLockPIN} = this.form.value;
			savedData.pin = screenLockPIN || null;
		}

		return this.form.validate().pipe(
			switchMap((v) => {
				if(v.valid){
					return this.controller.tabsStudentTestingOptionsSave(savedData).pipe(
						tap(() => {
							userStorage.update({
								showSelfAssessOption: savedData.showSelfAssessOption,
							});
						}),
					);
				}
				return new Observable<void>((subscriber) => {
					subscriber.error();
					subscriber.complete();
				});
			}),
		);
	};

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}
