import {createContext} from '@esgi/ui';
import {SubjectTab} from '@esgi/main/libs/store';

export interface SubjectRootContextValue {
	isLoaded: boolean;
	subjects: SubjectTab[];
	selectedSubjectID: string | undefined;
	setSelectedSubject: (subjectId: string) => void;
}

export const {
	Context: SubjectRootContext,
	useHook: useSubjectRootContext,
} = createContext<SubjectRootContextValue>('Subjects Root Context');