import {BaseSelectedValuesStorage} from '@esgi/main/libs/core';

interface TeacherSelectedValues {
	classId: null | number;
	groupId: null | number;
	studentId: null | number;
	subjectId: null | number;
	studentFromClassId: null | number;
	studentFromGroupId: null | number;
}

class TeacherSelectedValuesStorage extends BaseSelectedValuesStorage<TeacherSelectedValues>{}

export const teacherSelectedValuesStorage = new TeacherSelectedValuesStorage('teacherSelectedValues');