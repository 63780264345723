import {Fragment} from 'react';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {Checkbox, ChildrenWrapper, ChildrenList, Divider, SelectableListGroup, SelectableListItem, StyledText} from './index.styled';
import {ReadMoreButton} from '../read-more-button';
import {OptionItem, StandardsListItem} from '../../types';

type Props = {
	selectedItems: OptionItem['value'][];
	items: StandardsListItem[];
	onStandardSelect?: (item: OptionItem, hasChild?: boolean) => void;
}

export function StandardsSelectableList({items, selectedItems, onStandardSelect}: Props) {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='multiple' value={selectedItems.map(item => String(item))}>
				<SelectableListGroup>
					{items.map(({value, label, description, children, hasChild}, idx) => (
						<Fragment key={value}>
							<SelectableListItem
								value={String(value)}
								variant='outlined'
								onClick={() => onStandardSelect({value, label}, hasChild)}
							>
								<GridBox gap={2} flow='column' justify='start' align='center'>
									<Checkbox data-cy='standard-checkbox' checked={selectedItems.includes(value)} onCheckedChange={() => onStandardSelect({value, label}, hasChild)}/>
									<StyledText data-cy='standard-name' size='large' selected={selectedItems.includes(value)}>{label}</StyledText>
								</GridBox>
								<ReadMoreButton description={description} />
							</SelectableListItem>
							{Boolean(children?.length) &&
								<ChildrenWrapper>
									<Divider/>
									<ChildrenList>
										{children.map(({value, label, description}) => (
											<SelectableListItem
												value={String(value)}
												key={value}
												variant='outlined'
												onClick={() => onStandardSelect({value, label})}
											>
												<GridBox gap={2} flow='column' justify='start' align='center'>
													<Checkbox data-cy='standard-checkbox' checked={selectedItems.includes(value)} onCheckedChange={() => onStandardSelect({value, label})}/>
													<StyledText data-cy='standard-name' size='large' selected={selectedItems.includes(value)}>{label}</StyledText>
												</GridBox>
												<ReadMoreButton description={description} />
											</SelectableListItem>
										))}
									</ChildrenList>
								</ChildrenWrapper>
							}
						</Fragment>
					))}
				</SelectableListGroup>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
