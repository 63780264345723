import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import React from 'react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	SharedComponent,
	SharedProps,
} from '@esgi/deprecated/react';
import {Api} from '../../../api';
import {Events} from '../../../models';
import {State as FoldersHierarchyState, FoldersHierarchy} from '../../folders-hierarchy/folders-hierarchy';

export class State {
	constructor(public ids: number[]) {
	}

	loading: boolean = false;
	select: FoldersHierarchyState = new FoldersHierarchyState();
}

export class Props extends SharedProps<State> {
	close: () => any;
	sourceFolderID: number;
}

export class MoveModal extends SharedComponent<State, Props> {
	$element;

	componentDidMount(): void {
		this.setState({loading: true});
	}

	get title() {
		if (this.state.ids.length > 1) {
			return `Move ${this.state.ids.length} items to`;
		} else {
			return 'Move 1 item to';
		}
	}

	render(): JSX.Element | false | null {
		return <Modal className='white-header move-modal ig-inner-modal base-ig-modal' animate={true}
		              onCatchError={() => this.props.close()}>
			<ModalHeader title={this.title}>
			</ModalHeader>
			<ModalBody>
				<div className='content'>
					<label>Location</label>
					<FoldersHierarchy itemSelected={(i) => {
					}} state={this.state.select} initialFolderID={this.props.sourceFolderID}
					                  onLoad={() => this.setState({loading: false})}
					                  onChange={(ch) => this.setState({select: ch})}/>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => this.props.close()}
					title='CANCEL'
					className='btn-bold'
					disabled={this.state.loading}
				/>
				<Primary
					onClick={() => this.save()}
					title='SAVE'
					className='btn-bold'
					disabled={this.state.loading}
				/>
			</ModalFooter>
		</Modal>;
	}

	save() {
		if (this.props.sourceFolderID !== this.state.select.selected.value) {
			Api.MoveItems(this.state.ids, this.state.select.selected.value).subscribe((r) => {
				this.dispatch(Events.ItemsMoved, {ids: this.state.ids});
				showSnackbarNotification(`You've moved ${this.state.ids.length} image(s) to ${this.state.select.selected.text}`);
				this.props.close();
			});
			this.setState({loading: true});
		} else {
			this.props.close();
		}
	}
}
