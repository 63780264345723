import React, {ReactNode} from 'react';
import {isFunction} from 'underscore';
import {HttpClient} from '@esgi/api';
import {AddInCircleIcon, ExpandIcon} from '@esgillc/ui-kit/icons';
import {List, ListItem} from '@esgillc/ui-kit/layout';
import {AutoDimension} from '@esgi/deprecated/react';
import ManageSubjectsOfTest from '../../../../subjects/manage-subjects/root';
import {SubjectModel} from '../models';

import styles from './subject-tab.module.less';
import {Subject} from 'modules/assets/subjects/manage-subjects/models';

interface Props {
	testID: number;
	testName: string | (() => string);
	onSave?: (subjects: number[]) => void;
}

class State {
	loaded: boolean = false;
	toggled: boolean = false;
	openSubjectManage: boolean = false;
	subjects: SubjectModel[] = [];
}

function getValueOrCall<T>(funcOrValue: T | (() => T)) {
	if (isFunction(funcOrValue)) {
		return funcOrValue();
	}
	return funcOrValue;
}

export class SubjectTabField extends React.PureComponent<Props, State> {
	public state = new State();

	public componentDidMount() {
		HttpClient.default.ESGIApi.get<{subjects: SubjectModel[]}>('assets/tests', 'subjects-by-test', {testID: this.props.testID})
			.subscribe(r => {
				this.setState({subjects: r.subjects, loaded: true});
			});
	}

	public handleSave(subjects: Subject[]) {
		const linkedSubjectIDs = subjects.filter((item) => item.linked).map((item) => item.id);

		this.setState({subjects});
		this.props.onSave?.(linkedSubjectIDs);
	}

	public render() {
		return <>
			<div className={styles.container}>
				<hr className={styles.hr}/>
				<div className={styles.titleRow}>
					<div className={styles.title}>
						Subject Tabs
					</div>
					<div className={styles.row}>
						<div className={styles.addIcon}>
							<button className={styles.iconButton}
							        onClick={() => this.setState({openSubjectManage: true})}>
								<AddInCircleIcon/>
							</button>
						</div>
						<div className={styles.expandIconContainer}
						     onClick={() => this.setState({toggled: !this.state.toggled})}>
							{this.renderExpandButton()}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.subjectsListContainer}>
				<AutoDimension fixWidth>
					<List className={styles.list}>
						{this.state.toggled && this.state.subjects.filter(s => s.linked).map(s => <ListItem className={styles.subject} key={s.id}>
							{s.name}
						</ListItem>)}
					</List>
				</AutoDimension>
			</div>
			{this.state.openSubjectManage &&
				<ManageSubjectsOfTest testID={this.props.testID}
				                      testName={getValueOrCall(this.props.testName)}
				                      subjects={this.state.subjects}
				                      onSave={(subjects) => this.handleSave(subjects)}
				                      onClose={() => this.setState({openSubjectManage: false})}
				                      onSubjectsChanged={() => this.setState({openSubjectManage: false})}/>}

		</>;
	}

	private renderExpandButton(): ReactNode {
		if (!this.state.loaded) {
			return <i className='fa fa-spinner fa-spin fa-lg'/>;
		}
		if (this.state.subjects.filter(s => s.linked).length !== 0) {
			return <ExpandIcon toggled={this.state.toggled}/>;
		}
	}
}
