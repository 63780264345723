import {ResponseModels} from 'shared/modules/reports/flash-cards/settings-form/api/models';

export namespace Models {
    export class Init {
        reportGuid: string;
        subjectName: string;
        reportDate: string;
        
        students: Student[];
        pageSize: PageSize;

        public static FromResponse(response: ResponseModels.Results.Init): Init {
        	let model = new Init();

        	model.reportGuid = response.reportGuid;
        	model.subjectName = response.subjectName;
        	model.reportDate = response.reportDate;
        	model.students = Student.FromResponseArray(response.students, response.pageSize.colCount);

        	return model;
        }
    }

    export class Student {
        studentId: number;
        firstName: string;
        lastName: string;
        cardRows: CardsRow[];

        get fullName(): string {

        	let result = '';

        	if (this.firstName) {
        		result += this.firstName + ' ';
        	}

        	if (this.lastName) {
        		result += this.lastName;
        	}

        	return result;
        }

        public static FromResponse(response: ResponseModels.Results.Student, cols: number): Student {
        	let model = new Student();

        	model.studentId = response.studentID;
        	model.firstName = response.firstName;
        	model.lastName = response.lastName;

        	model.cardRows = [];
        	for (let i = 0; i < response.cards.length; i += cols) {
        		let row = [];

        		for (let k = 0; k < cols; k++) {
        			let card = (response.cards.length > i + k)
        				? response.cards[i + k]
        				: null;

        			row.push((card)
        				? new Card(model.fullName, card.reportDate, card.testName, card.questionID, card.testID, card.ticks)
        				: Card.GetEmpty());
        		}

        		model.cardRows.push(new CardsRow(row));
        	}

        	return model;
        }

        public static FromResponseArray(response: ResponseModels.Results.Student[], cols: number): Student[] {
        	return response.map(item => Student.FromResponse(item, cols));
        }
    }

    export class Card {
    	constructor(public studentName: string,
                    public reportDate: string,
                    public testName: string,
                    public questionId: number,
                    public testId: number,
                    public ticks: number) {
    	}

    	get isEmpty(): boolean {
        	return this.questionId === 0;
    	}

    	public static GetEmpty(): Card {
        	return new Card(null, null, null, 0, 0, 0);
    	}
    }

    export class PageSize {
        rowCount: number;
        colCount: number;

        public static FromResponse(response: ResponseModels.Results.PageSize): PageSize {
        	let model = new PageSize();

        	model.rowCount = response.rowCount;
        	model.colCount = response.colCount;

        	return model;
        }
    }
    
	export class CardsRow {
		cards: Models.Card[]

		constructor(cells: Models.Card[]) {
			this.cards = cells;
		}
	}
}