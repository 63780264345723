import {Response as NotificationActiveResponseContract} from '@esgi/contracts/esgi/types/esgi.notifications/endpoints/esgi.apigateway/user-active-notifications/response';
import {NotificationModel as NotificationModelContract} from '@esgi/contracts/esgi/types/esgi.notifications/endpoints/esgi.apigateway/user-active-notifications/notification-model';

export interface SchoolYearModel {
	globalSchoolYearID: number;
	name: string;
}

export enum NotificationType {
	NewSchoolYear = 'NEW_SCHOOL_YEAR',
	RolloverNotification = 'ROLLOVER_NOTIFICATION',
}

export type NotificationModel = Omit<NotificationModelContract, 'type'> & {
	type: NotificationType;
};

export type NotificationActiveResponse = Omit<NotificationActiveResponseContract, 'notification'> & {
	notification: NotificationModel | null;
};
