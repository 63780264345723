import React, {createRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {ModalManager} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onClosed: () => void;
}

export default class UserMovedDialog extends React.PureComponent<Props> {
	private modalManagerRef: React.RefObject<ModalManager> = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef}>
			<Alert.Body>
				This student is no longer associated with this class. This could happen if the student was moved by an
				administrator or by another teacher logged into this account from a second browser. You will now be
				returned to the homepage.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={() => this.modalManagerRef.current.close(() => this.props.onClosed())}>
					OK
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}
}
