/*This class used only for Export component. Move it to Export folder after Export component will move to the new UI.*/

export interface IPromiseValidation {
	validationStatus: KnockoutObservable<boolean>;
	validate: () => JQueryPromise<any>;
}

export interface IPromiseValidationOptions {
	validateCallback: () => JQueryPromise<any>;
	defaultFailMessage: string;
	successCallback?: () => void;
	failCallback?: (event, message) => void;
}


export class FakeValidationOptions implements IPromiseValidationOptions {
	defaultFailMessage: '';

	validateCallback(): JQueryPromise<any> {
		return $.Deferred<any>().resolve().promise();
	}

	failCallback(event, message): void {
	}

	successCallback(): void {
	}
}

export class ValidationPromise implements IPromiseValidation {

	protected options: IPromiseValidationOptions;

	validate() {
		let promise = this.options.validateCallback();
		let result = $.Deferred<any>();

		promise.done(() => {
			this.validationStatus(true);
			$(this).trigger('onValidateSuccess');
			result.resolve(true);
		}).fail((object) => {
			this.validationStatus(false);
			let message = this.options.defaultFailMessage;
			if (typeof object == 'string') {
				message = object;
			}
			$(this).trigger('onValidateFail', message);
			result.reject(message);
		});
		return result.promise();
	}

	validationStatus = ko.observable<boolean>(true);

	constructor(options: IPromiseValidationOptions) {
		this.options = options;

		if (typeof this.options.successCallback === 'function') {
			this.events.onValidateSuccess(this.options.successCallback);
		}

		if (typeof this.options.failCallback === 'function') {
			this.events.onValidateFail(this.options.failCallback);
		}
	}

	events = {
		onValidateSuccess: (callback) => {
			$(this).on('onValidateSuccess', callback);
		},
		onValidateFail: (callback) => {
			$(this).on('onValidateFail', callback);
		},
	};

}

