import {SelectableListItem} from '@esgi/main/kits/reports';
import {ItemAnalysisService} from '@esgi/main/features/teacher/item-analysis';
import {useCallback, useState} from 'react';
import {useBehaviorSubject, useStream} from '@esgi/ui/utils';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';

export function useReportState(service: ItemAnalysisService) {
	const [selectedEntity, setSelectedEntity] = useState<SelectableListItem>(null);
	const valid = useStream(service.valid$, false);
	const busy = useStream(service.busy$, false);
	const preview = useBehaviorSubject(service.preview$);
	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const studentList = useBehaviorSubject(service.studentService.students$);
	const loadedStudents = useBehaviorSubject(service.studentService.loaded$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const loadedSubjects = useBehaviorSubject(service.subjectService.loaded$);
	const subject = useBehaviorSubject(service.subject$);
	const entity = useBehaviorSubject(service.entity$);
	const notTested = useBehaviorSubject(service.notTested$);
	const sort = useBehaviorSubject(service.sort$);
	const test = useBehaviorSubject(service.test$);
	const tests = useBehaviorSubject(service.testService.tests$);
	const loadingTests = useBehaviorSubject(service.testService.loading$);

	const onEntitySelect = useCallback(({classId, groupId}: SingleSelectState) => {
		let selected;

		if (classId) {
			if (classId === -1) {
				selected = {id: -1, name: 'All'};
			} else {
				selected = classes.find(({id}) => id === classId);
			}
		} else {
			selected = groups.find(({id}) => id === groupId);
		}

		setSelectedEntity({
			value: selected?.id.toString(),
			label: selected?.name,
			disabled: false,
		});
	}, [classes, groups]);

	return {
		selectedEntity,
		preview,
		setPreview: service.setPreview.bind(service),
		valid,
		busy,
		classes,
		groups,
		studentList,
		loadedStudents,
		subjects,
		loadedSubjects,
		subject,
		entity,
		notTested,
		sort,
		test,
		tests,
		loadingTests,
		onEntitySelect,
	};
}
