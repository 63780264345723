import {EventBusDispatcher} from '@esgillc/events';
import React from 'react';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, ISpecialistGroupBox} from '../../core/api';
import {HierarchyEvents} from '../../core/events';
import {BoxType} from '../../core/models';
import {UserType} from '@esgi/core/authentication';

export class State {

}

export class Props {
	boxes: IBoxInfo[];
	groups: ISpecialistGroupBox;
	userID: number;
	selectedID: number;
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}


export class Group extends React.Component<Props, State> {
	get items(): ItemProps[] {
		const source = this.props.groups.items;

		return source.filter(t => t.userID === this.props.userID)
			.map(r => {
				return {
					id: r.groupID,
					title: r.name,
				};
			});
	}


	render(): any {
		if (!this.props.userID) {
			return null;
		}

		const options: BoxOptions = {
			boxType: BoxType.PreAssessGroup,
			canDrag: false,
			title: 'Groups',
			canCreateTooltip: null,
			canEdit: this.props.groups.canEdit,
			canAdd: this.props.groups.canCreate,
		};
		return <Box
      items={this.items}
      open={this.props.boxes.filter(t => t.boxType === BoxType.PreAssessGroup && t.open).length > 0}
      onDragEnd={null}
      onDragStart={null}
      empty={{message: 'The list is empty'}}
      selectedID={this.props.selectedID}
      options={options}
      onEditClicked={this.publish.edit}
      onAddClicked={this.publish.add}
      itemSelected={this.props.onSelected}
      scheduledIDs={[]}
      onOpenCloseChanged={this.props.onOpenCloseChanged}
    />;
	}

	publish = {
		add: () => {
			const args: HierarchyEvents.SpecialGroup.AddArgs = {
				userID: this.props.userID,
				numberOfGroups: this.items.length,
				userType: UserType.PA,
			};
			EventBusDispatcher.dispatch(HierarchyEvents.SpecialGroup.Add, args);
		},
		edit: (id: number) => {
			const item = this.props.groups.items.find(t => t.groupID === id);
			const args: HierarchyEvents.SpecialGroup.EditArgs = {
				userID: item.userID,
				name: item.name,
				id: id,
				numberOfGroups: this.items.length,
				userType: UserType.PA,
			};
			EventBusDispatcher.dispatch(HierarchyEvents.SpecialGroup.Edit, args);
		},
	};
}
