import {forwardRef} from 'react';
import {MoveDown} from '@esgi/ui/icons';
import {BaseOrderButton} from './base-order-button.styled';
import {OrderButtonProps} from './types';

export const OrderButtonsMoveDown = forwardRef<HTMLButtonElement, OrderButtonProps>(
	({dataCy = 'assignments-order-buttons-move-down', css = {}, ...props}, forwardedRef) => (
		<BaseOrderButton data-cy={dataCy} ref={forwardedRef} css={css} {...props}>
			<MoveDown />
		</BaseOrderButton>
	),
);
