import {Validation} from './types';

export const correctVerbiageMaxLength = 12;

export const incorrectVerbiageMaxLength = 12;

export const validations: Record<'correctVerbiage' | 'incorrectVerbiage', Validation[]> = {
	correctVerbiage: [
		{
			showOnError: 'required',
			message: 'Enter a custom name for Correct. This will appear on all reports where test results are shown.',
		},
		{
			showOnError: 'length-max',
			message: `Max allowed characters: ${correctVerbiageMaxLength}`,
		},
	],
	incorrectVerbiage: [
		{
			showOnError: 'required',
			message: 'Enter a custom name for Incorrect. This will appear on all reports where test results are shown.',
		},
		{
			showOnError: 'length-max',
			message: `Max allowed characters: ${incorrectVerbiageMaxLength}`,
		},
	],
};
