import React from 'react';
import {IGraphStyle, ShapeType} from 'shared/modules/question-editor/models';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import './shape-selector.less';

export class Props {
	shapeStyle: IGraphStyle;
	shapeType: ShapeType;
	added: (type: ShapeType) => void;
	opened: () => void;
}

export class State {
	opened: boolean = false;
	lastTypeOfShape: ShapeType = ShapeType.Rectangle;
}


export class ShapeSelector extends React.PureComponent<Props> {
	private ref: HTMLElement;
	state: State;

	constructor(props, context) {
		super(props, context);
		this.state = new State();
	}

	addShape(type: ShapeType) {
		this.props.added(type);
		this.setState({lastTypeOfShape: type});
	}

	componentDidMount() {
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	handleClickOutside(event) {
		if (this.ref && !this.ref.contains(event.target)) {
			this.setState({opened: false});
		}
	}

	clickMenu(type: ShapeType) {
		this.addShape(type);
		this.setState({opened: false});
	}

	renderShape(type: ShapeType) {
		switch (type) {
			case ShapeType.Circle: {
				return <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<circle cx='9' cy='9' r='8' stroke='white' strokeWidth='2'/>
				</svg>;
			}
			case ShapeType.Triangle: {
				return <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M2.0718 14L9 2L15.9282 14H2.0718Z' stroke='white' strokeWidth='2'/>
				</svg>;
			}
			case ShapeType.Line: {
				return <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<line x1='1.29289' y1='16.2929' x2='16.2929' y2='1.29289' stroke='white' strokeWidth='2'/>
				</svg>;
			}
			case ShapeType.Arrow: {
				return <svg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M21.7384 6.00001C21.7384 5.44772 21.2907 5.00001 20.7384 5.00001L11.7384 5.00001C11.1861 5.00001 10.7384 5.44772 10.7384 6.00001C10.7384 6.55229 11.1861 7.00001 11.7384 7.00001L19.7384 7.00001L19.7384 15C19.7384 15.5523 20.1861 16 20.7384 16C21.2907 16 21.7384 15.5523 21.7384 15L21.7384 6.00001ZM6.70711 21.4455L21.4455 6.70711L20.0313 5.2929L5.29289 20.0313L6.70711 21.4455Z'
						fill='white'/>
				</svg>;
			}
			default: {
				return <svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect x='1' y='1' width='15' height='16' stroke='white' strokeWidth='2'/>
				</svg>;
			}
		}
	}

	render() {
		return <div className='mx-question-editor-rectangle-selector' ref={(r) => this.ref = r}>
			<OnHoverTooltip message='Shape'>
				<div className='main-button-container'>
					<div onClick={() => this.addShape(this.state.lastTypeOfShape)}
						 className={(this.props.shapeType >= ShapeType.Rectangle) ? 'toolbar-item selected' : 'toolbar-item'}>
						{this.renderShape(this.props.shapeType >= ShapeType.Rectangle ? this.props.shapeType : this.state.lastTypeOfShape)}
					</div>

					<div className='triangle-arrow-container' onClick={() => this.setState((prevState: State) => {
						return {opened: !prevState.opened};
					}, () => this.props.opened())}>
						<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M8.16678 0L0.000110626 0L4.08344 6.41667L8.16678 0Z' fill='#828282'/>
						</svg>
					</div>
				</div>
			</OnHoverTooltip>
			{this.state.opened && <div className='rectangle-selector-menu'>
				<div onClick={() => this.clickMenu(ShapeType.Rectangle)}
					 className={(this.props.shapeType === ShapeType.Rectangle) ? 'rectangle-selector-menu-item selected' : 'rectangle-selector-menu-item'}>
					<div className='menu-item-svg'>{this.renderShape(ShapeType.Rectangle)}</div>
					<span>Rectangle</span>
				</div>
				<div onClick={() => this.clickMenu(ShapeType.Circle)}
					 className={(this.props.shapeType === ShapeType.Circle) ? 'rectangle-selector-menu-item selected' : 'rectangle-selector-menu-item'}>
					<div className='menu-item-svg'>{this.renderShape(ShapeType.Circle)}</div>
					<span>Circle</span>
				</div>
				<div onClick={() => this.clickMenu(ShapeType.Triangle)}
					 className={(this.props.shapeType === ShapeType.Triangle) ? 'rectangle-selector-menu-item selected' : 'rectangle-selector-menu-item'}>
					<div className='menu-item-svg'>{this.renderShape(ShapeType.Triangle)}</div>
					<span>Triangle</span>
				</div>
				<div onClick={() => this.clickMenu(ShapeType.Line)}
					 className={(this.props.shapeType === ShapeType.Line) ? 'rectangle-selector-menu-item selected' : 'rectangle-selector-menu-item'}>
					<div className='menu-item-svg'>{this.renderShape(ShapeType.Line)}</div>
					<span>Line</span>
				</div>
				<div onClick={() => this.clickMenu(ShapeType.Arrow)}
					 className={(this.props.shapeType === ShapeType.Arrow) ? 'rectangle-selector-menu-item menu-arrow-item selected' : 'rectangle-selector-menu-item menu-arrow-item'}>
					<div className='menu-item-svg'>{this.renderShape(ShapeType.Arrow)}</div>
					<span className='menu-text-arrow'>Arrow</span>
				</div>
			</div>}
		</div>;
	}
}
