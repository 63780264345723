import {Buttons, Container} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Button, Close} from '@esgi/ui';
import {OrderButtonsRoot} from '../../../../../assignment/components/table-tests';
import {Test} from '../../../../types';
import {Dispatch} from 'react';
import {TestColorIndicator} from '@esgi/main/kits/common';
import {OrderButtons} from '@esgi/main/kits/assignments';

type Props = Pick<Test, 'id' | 'name' | 'contentArea'> & {
	onClearButtonClick: Dispatch<Test['id']>;
	moveUpDisabled: boolean;
	onMoveUpClick: VoidFunction;
	moveDownDisabled: boolean;
	onMoveDownClick: VoidFunction;
};

export function SelectedTestRow({
	id,
	name,
	onClearButtonClick,
	moveUpDisabled,
	onMoveUpClick,
	moveDownDisabled,
	onMoveDownClick,
}: Props) {
	return (
		<Container dataCy='selected-test-row'>
			<TestColorIndicator testID={id} />
			<Text size='medium' data-cy='test-name'>
				{name}
			</Text>
			<Buttons>
				<OrderButtonsRoot>
					<OrderButtons.MoveUp disabled={moveUpDisabled} onClick={onMoveUpClick} />
					<OrderButtons.MoveDown disabled={moveDownDisabled} onClick={onMoveDownClick} />
				</OrderButtonsRoot>
				<Button.Icon dataCy='clear-test-button'>
					<Close onClick={() => onClearButtonClick(id)} />
				</Button.Icon>
			</Buttons>
		</Container>
	);
}
