import {TestListProps} from '../../types';
import {SelectableListMultiple} from './components/list';
import {Action} from './components/action';
import {Wrapper} from './index.styled';

export function TestList(props: TestListProps) {
	const {tests, selected, setSelected, sort, onSort} = props;

	return (
		<Wrapper dataCy='wrapper'>
			<Action
				sort={sort}
				onSort={onSort}
			/>
			<SelectableListMultiple
				items={tests ?? []}
				selected={selected}
				setSelected={(id) => setSelected(id)}
			/>
		</Wrapper>
	);
}
