import {Dropdown as DropdownControled, Option} from '@esgillc/ui-kit/control';
import {DropdownOption, DropdownProps} from './types';
import styles from './styles.module.less';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

export const Dropdown = <T extends DropdownOption>({
	filterLabel,
	options,
	placeholder,
	disabled,
	onValueChange,
}: DropdownProps<T>) => {
	const {current: initialSelectedOptions} = useRef<T[]>([
		{
			label: 'All',
			value: null,
		} as unknown as T,
	]);

	const [selectedOptions, setSelectedOptions] = useState<T[]>(
		initialSelectedOptions,
	);

	useEffect(() => {
		setSelectedOptions(initialSelectedOptions);
		onValueChange(initialSelectedOptions[0]);
	}, [options]);

	const dropdownOptions = useMemo(
		() =>
			options.map((option) => (
				<Option value={option} key={option.value}>
					{option.label}
				</Option>
			)),
		[options],
	);

	const handleSelectedOptionChange = useCallback(
		(value: T[]) => {
			setSelectedOptions(value);
			onValueChange(value[0]);
		},
		[onValueChange],
	);

	return (
		<div className={styles.container}>
			<div className={styles.label}>{filterLabel}</div>
			<DropdownControled
				optionName='label'
				value={selectedOptions}
				setValue={handleSelectedOptionChange}
				placeholder={placeholder}
				className={styles.dropdown}
				disabled={disabled}
			>
				<div>
					<Option value={initialSelectedOptions[0]}>All</Option>
					{dropdownOptions}
				</div>
			</DropdownControled>
		</div>
	);
};
