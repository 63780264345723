import {ServerClient} from 'pages/landing/kit/server-client';
import {ImportRegistrationFinishForm} from './form';
import {InitResponse, ValidationType} from './types';
import {OldAlerts} from '../../../../../../libs/deprecated/src/knockout';

export class ImportRegistrationFinish {

	key = '';

	initData: InitResponse;

	form: ImportRegistrationFinishForm;

	constructor() {
		this.form = new ImportRegistrationFinishForm();
	}


	view =
		{
			finishButtonClicked: () => {
				this.form.validate().then((result) => {
					if (!result) {
						return $.when();
					}

					let data = {
						FirstName: this.form.firstName.value(),
						LastName: this.form.lastName.value(),
						CountryID: this.initData.countryID,
						StateID: this.initData.stateID,
						Email: this.form.email.value(),
						UserName: this.form.userName.value(),
						Password: this.form.password.value(),
						ConfirmPassword: this.form.confirmPassword.value(),
						Title: this.form.userTitle.value(),
						key: this.key,
						TrackID: this.form.track.value().trackID,
					};

					return $.when(this.server.save(data)).done(() => {
						OldAlerts.bsalert(
							'Registration completed successfully. You may login now using your username and password.',
							() => {
								document.location.href = '/';
							});


					});
				});
			},
			cancelButtonClicked: () => {

			},
			submit: () => {

			},
		};

	server =
		{
			init: (key) => {
				return ServerClient.ESGIAPI.Get<InitResponse>('import', 'init-teacher-import-finish', {
					data: {
						key: key,
					},
				});
			},
			save: (data) => {
				return ServerClient.ESGIAPI.Post('import', 'teacher-import-finish', {
					data: data,
				});
			},
		};


	showAlert(validationType: ValidationType = null) {
		let registeredMessage = 'This link has already been used once and cannot be used again. Please use the "Forgot Password?" link (found on the login page) if you wish to have a new registration link emailed to you.';
		switch (validationType) {
			case ValidationType.NotImportCreator: {
				OldAlerts.bsalert('This user was not created by the import.', () => {
					document.location.href = '/';
				});
				break;
			}
			case ValidationType.AlreadyRegistered: {
				OldAlerts.bsalert(registeredMessage, () => {
					document.location.href = '/';
				});
				break;
			}
			case ValidationType.InvalidKey: {
				OldAlerts.bsalert('Key is invalid.', () => {
					document.location.href = '/';
				});
				break;
			}
			default: {
				OldAlerts.bsalert(registeredMessage, () => {
					document.location.href = '/';
				});
				break;
			}
		}
	}

	load() {
		this.key = this.getUrlQueryParameter('key');
		if (!this.key) {
			this.showAlert();
			return $.Deferred().resolve();
		}

		return this.server.init(this.key).done((data) => {
			if (data.validationFlag == ValidationType.None) {
				this.initData = data;

				this.form.fill(data);
			} else {
				this.showAlert(data.validationFlag);
			}
		}).fail(() => {
			this.showAlert();
		});
	}

	getUrlQueryParameter(param) {
		let regex = '[?&]' + param + '=([^&#]*)';
		let results = (new RegExp(regex)).exec(window.location.href);
		if (results) {
			return results[1];
		}
		return '';
	}
}
