import {ComponentPropsWithoutRef, forwardRef, PropsWithChildren, useRef} from 'react';
import {animated} from 'react-spring';
import {CSS} from '@stitches/react';
import {styled} from '@esgi/ui/theme';
import {useProgressBarContext} from '../../context';
import {assignRefs} from '../../utils/assign-refs';
import {useProgressRelatedContent} from '../../utils/hooks/use-progress-related-content';

export type AdditionalInfoProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Additional Info animation. */
	isShowAnimation?: boolean
} & PropsWithChildren

export const AdditionalInfo = forwardRef<HTMLDivElement, AdditionalInfoProps>(({
	dataCy = 'ui-kit-progress-bar-additional-info',
	children,
	isShowAnimation,
	...props
},
forwardRef,
) => {

	const {progress, isAnimationFinished, lineProgressBarRef} = useProgressBarContext();

	const ref = useRef<HTMLDivElement>(null);

	const {needToTransform} = useProgressRelatedContent(lineProgressBarRef, ref, progress);

	return <StyledAdditionalProgressInfo needToTransform={needToTransform}
										 isShowAnimation={!isShowAnimation || isAnimationFinished}
										 ref={(itemRef: HTMLDivElement) => {
											 assignRefs(itemRef, forwardRef, ref);
										 }}
										 css={{left: `${progress * 100}%`}}
										 data-cy={dataCy}
										 {...props}>
		{children}
	</StyledAdditionalProgressInfo>;
});

const StyledAdditionalProgressInfo = styled(animated.div, {
	display: 'inline-flex',
	direction: 'column',
	position: 'relative',
	transition: '.3s ease-out',
	transform: `translateX(0)`,
	opacity: '0',

	variants: {
		needToTransform: {
			true: {
				transform: `translateX(-100%)`,
			},
		},

		isShowAnimation: {
			true: {
				opacity: '1',
			},
		},
	},
});
