import {PropsWithChildren, useLayoutEffect, useRef, useState} from 'react';
import {
	ChildrenBox,
	CommentIconBox,
	CommentTooltipContent,
	Container,
	Divider,
	inlineGapElements,
} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Comment} from '@esgi/ui/icons';
import {Tooltip} from '@esgi/ui/tooltip';

type Props = PropsWithChildren<{
	questionNumber: number;
	questionTitle: string;
	comment?: string | null;
	withQuestionUnderline: boolean;
}>;

const defaultQuestionTitleHeight = 12;

export function QuestionRowViewMode({questionNumber, questionTitle, children, comment, withQuestionUnderline}: Props) {
	const withComment = Boolean(comment);

	const [questionNumberWidth, setQuestionNumberWidth] = useState(defaultQuestionTitleHeight);
	const [questionTitleHeight, setQuestionTitleHeight] = useState(defaultQuestionTitleHeight);
	const [childrenBoxWidth, setChildrenBoxWidth] = useState(defaultQuestionTitleHeight);

	const questionNumberRef = useRef<HTMLElement>(null);
	const questionTitleRef = useRef<HTMLElement>(null);
	const childrenBoxRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		setQuestionNumberWidth(questionNumberRef.current?.clientWidth ?? 0);
		setQuestionTitleHeight(questionTitleRef.current?.clientHeight ?? 0);
		setChildrenBoxWidth(childrenBoxRef.current?.clientWidth ?? 0);
	}, []);

	return (
		<>
			<Container withComment={withComment} moreThanOneLineTitle={questionTitleHeight > defaultQuestionTitleHeight} dataCy='question-row'>
				<Text size='xSmall' font='mono' color='lowContrast' ref={questionNumberRef} data-cy='question-number'>
					{questionNumber}
				</Text>

				<Text size='small' color='neutral40' ref={questionTitleRef} data-cy='question-title'>
					{questionTitle}
				</Text>

				{withComment && (
					<Tooltip>
						<Tooltip.Trigger>
							<CommentIconBox data-comment-icon-box=''>
								<Comment />
							</CommentIconBox>
						</Tooltip.Trigger>
						<CommentTooltipContent>
							<Text size='small' font='mono' data-comment-tooltip-title=''>
								Comment:
							</Text>
							<Text size='medium' bold data-comment-tooltip-content-text=''>
								{comment}
							</Text>
						</CommentTooltipContent>
					</Tooltip>
				)}

				<ChildrenBox ref={childrenBoxRef}>{children}</ChildrenBox>
			</Container>

			{withQuestionUnderline && (
				<Divider
					css={{
						width: `calc(100% - ${questionNumberWidth}px - ${childrenBoxWidth}px - 2 * ${inlineGapElements}px)`,
						marginLeft: `calc(${questionNumberWidth}px + ${inlineGapElements}px )`,
					}}
				/>
			)}
		</>
	);
}
