import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {RadioGroupProps} from '@radix-ui/react-radio-group';
import {BaseComponentProps, Skeletonable} from '../../../../types';
import {Content} from './index.styled';

type RadioGroupContentProps = ComponentPropsWithoutRef<'div'> &
	BaseComponentProps &
	Pick<RadioGroupProps, 'orientation'> &
	Skeletonable;

export const RadioGroupContent = forwardRef<HTMLDivElement, RadioGroupContentProps>(
	({dataCy = 'ui-kit-radio-group-content', skeleton, orientation = 'horizontal', ...props}, forwardedRef) => (
		<Content data-cy={dataCy} data-orientation={orientation} ref={forwardedRef} {...props} />
	),
);
