export enum Gender {
	F = 'F',
	M = 'M',
	U = 'U'
}

export const getName = (gender: Gender) => {
	switch(gender){
		case Gender.F: return 'Female';
		case Gender.M: return 'Male';
		case Gender.U: return 'Other';
	}
};

export const genderNames = () => {
	return [
		{id: Gender.F.toString(), name: getName(Gender.F)},
		{id: Gender.M.toString(), name: getName(Gender.M)},
		{id: Gender.U.toString(), name: getName(Gender.U)},
	];
};
