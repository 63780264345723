import {HierarchySnapshot} from 'modules/hierarchy/models';
import {
	GradeRangeType,
	GradeScaleType,
	IReportGradeScale,
} from 'shared/modules/grade-scale/models';

export interface SubjectModel {}

export interface SubjectModel {
	name: string;
	subjectID: number;
	subjectType: string;
	level: string;
	hasGradeScales: boolean;
	hierarchy: HierarchySnapshot;
}

export interface IInitReportResponse {
	subjects: SubjectModel[];
	model: ReportModel;
}

export interface ReportModel {
	args: string[];
	gradeReportType: string;
	tests: TestResponse[];
	gradeScale: IReportGradeScale;
	groups: EntityModel[];
	teachersGroups: EntityModel[];
	schoolsGroups: EntityModel[];
	groupsOfSpecialists: EntityModel[];
	classes: EntityModel[];
	specialistGroups: EntityModel[];
	schools: EntityModel[];
	reportLevelId: number;
	reportGuid: string;
	sortModel: SortModelResponse;
	readonly: boolean;
	reportName: string;
	rows: Row[];
	headerRowNames: string[];
	infoRows: string[];
	carryScoresForward: boolean;
	displayZeroIfNotTested: boolean;
	hasNotConfiguredSet: boolean;
	valueToSkip: number;
	studentGradeLevels: GradeLevelModel[];
	userEntities: UserEntities[];
	markingPeriodAll: boolean;
}

export interface UserEntities {
	id: number;
	name: string;
	entities: EntityModel[];
}

export interface EntityModel {
	id: number;
	name: string;
}

export interface TestResponse {
	periods: PeriodModel[];
	name: string;
	testID: number;
	questionsCount: number;
	gradeRangeType: GradeRangeType;
}

export class SortModelResponse {
	constructor (
		public direction?: string,
		public columnIndex?: number,
	) {}
}

export class TestModel {
	periods: PeriodModel[];
	name: string;
	testID: number;
	questionsCount: number;
	gradeRangeType: GradeRangeType;

	width: KnockoutComputed<number>;
	showSettings = ko.observable<boolean>(false);

	public static FromResponse(test: TestResponse) {
		const model = new TestModel();
		model.name = test.name;
		model.testID = test.testID;
		model.questionsCount = test.questionsCount;
		model.periods = test.periods;
		model.gradeRangeType = test.gradeRangeType;

		return model;
	}
}

export interface PeriodModel {
	name: string;
}

export interface Row {
	entityID: number;
	entityInfo: EntityInfo[];
	testResults: TestResult[];
	rowsCount: number;
}

export interface TestResult {
	id: number;
	value: string;
	color: string;
	testID: number;
	score: number;
	backgroundColor: string;
	rightBorder: boolean;
	leftBorder: boolean;
}

export interface EntityInfo {
	value: string;
	color?: any;
	testID: number;
}

export class RequestModel {
	subjectId: number;
	subjectType: string;
	subjectLevel: string;
	selectedTestIds: number[];
	gradeLevelID: number;
	levelType: string;
	levelId: number;
	hierarchy: HierarchySnapshot;
}

export class GradeLevelModel {
	gradeLevelID: number;
	name: string;
}

export interface IConfigurationInitResponse {
	subjects: SubjectModel[];
	selectedSubject: SubjectModel;
	report: IReportResponse;
}

export interface IReportResponse {
	readonly: boolean;
	gradeScale: IReportGradeScale;
	tests: ITestResponse[];
}

export interface ITestResponse {
	id: number;
	name: string;
	invalid: boolean;
	invalidMessage: string;
	invalidType: string;
	invalidLevel: string;
	questionsCount: number;
	gradeRangeInfo: IGradeRangeInfo
	hasResults: boolean;
}

export class TooltipModel {
	index: number;
	tooltip: JQuery;
}

export interface IGradeRangeInfo {
	gradeRangeType: GradeRangeType;
	gradeScaleLevel: GradeScaleType;
}

export enum GradeScaleHierarchyLevel {
	None = '',
	Class = 'Class',
	Group = 'Group',
	StudentsSchool = 'StudentsSchool',
	StudentsDistrict = 'StudentsDistrict',
	SchoolSpecialistStudents = 'SchoolSpecialistStudents',
	SchoolsGroup = 'SchoolsGroup',
	TeachersGroup = 'TeachersGroup',
	GroupOfSpecialists = 'GroupOfSpecialists',
}

export interface SubjectsResponse {
	subjects: SubjectModel[];
}

export class Download {
	public SourceID: string;
	public SelectedTestIds: number[];
	public SubjectType: string;
	public SubjectLevel: string;
	public IsLastPeriod: boolean;
	public LevelType: string;
	public Leveld: number;
	public FullHierarchy: HierarchySnapshot;
	public SubjectId: number;
	public GradeLevelID: number;
}

export interface BackgroundGenerationModel {
	reportGuid: string;
}

export interface Row extends Record<string, any> {
	id?: number;
}
