import {DeltaIndicator} from './delta-indicator';
import {ProgressWithPointer} from './progress-with-pointer';
import {ContentWrapper, ProgressBarInfo, ProgressBarTranscriptWrapper} from './progress-bars.styled';
import {isUndefined, Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {TestType} from '@esgi/main/libs/core';

type Props = {
	testType: TestType;
	value: number | null;
	maxValue: number;
	deltaIndicatorValue: number | null | undefined;
	result: number | null;
	label: string;
	onHoverText: string;
};

export function AverageProgress({
	testType,
	value,
	maxValue,
	deltaIndicatorValue,
	result,
	label,
	onHoverText,
}: Props) {
	const isPercentageType = testType !== TestType.Rubric;

	return (
		<ContentWrapper dataCy='average-progress'>
			<Tooltip>
				<Tooltip.Trigger>
					<ProgressBarInfo dataCy='progress-bar-info'>
						<ProgressWithPointer
							value={value}
							maxValue={isPercentageType ? 100 : maxValue}
							isPercentageType={isPercentageType}
							result={result}
						/>
						{!isUndefined(deltaIndicatorValue) && (
							<ProgressBarTranscriptWrapper>
								<DeltaIndicator
									value={deltaIndicatorValue}
									withPersantageSign={isPercentageType}
									dataCy='average-progress-delta'
								/>
							</ProgressBarTranscriptWrapper>
						)}
					</ProgressBarInfo>
				</Tooltip.Trigger>
				<Tooltip.Content variant='default'>
					<Text size='small'>{onHoverText}</Text>
				</Tooltip.Content>
			</Tooltip>
			<Text size='small' font='mono' color='neutral56' data-cy='average-label'>
				{label}
			</Text>
		</ContentWrapper>
	);
}
