import {Student} from '@esgi/main/libs/store';
import {useContext, createContext} from 'react';

export type StudentsContextValue = {
	students: Student[];
};

export const StudentsContext = createContext<StudentsContextValue | null>(null);

export function useStudentsContext() {
	const context = useContext(StudentsContext);

	if (!context) {
		throw new Error('StudentsContext is null');
	}

	return context;
}
