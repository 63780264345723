import {Dispatch, useCallback, useEffect} from 'react';
import {ClassType, GroupType, StudentTabId, StudentType} from './types';
import {StudentWithUnit, ChangeFunction, UnitType} from '../types';
import {useTabs, useSelections, useItems} from './hooks';
import {StudentList} from './components/student-list';
import {ClassList} from './components/class-list';
import {GroupList} from './components/group-list';
import {Tabs, ExpandablePanelContentWrapper} from '@esgi/main/features/teacher/home';
import {Title, SkeletonList} from './index.styled';
import {Skeletonable} from '@esgi/ui';
import {useFlashcardsHistoryState} from '@esgi/main/features/teacher/utils';

interface Props extends Skeletonable {
	classes: ClassType[];
	groups: GroupType[];
	students: StudentType[];
	onChange: ChangeFunction<StudentWithUnit[]>;
	onActiveTabChange?: Dispatch<StudentTabId>;
}

export function StudentsControl(props: Props) {
	const {skeleton, classes, groups, students, onChange} = props;
	const {activeTab, setActiveTab, tabItems} = useTabs();
	const {selectedStudents, setSelectedStudents, toggleStudent, toggleStudents} = useSelections(onChange);
	const {selectedItems, setSelectedClass, setSelectedGroup} = useItems();
	const {studentId} = useFlashcardsHistoryState();

	useEffect(() => {
		const studentsAll = students.map(({id: studentId}) => ({
			studentId,
			unitData: {id: 0, type: UnitType.None},
		}));

		const selectedStudent = [{
			studentId: +studentId,
			unitData: {id: 0, type: UnitType.None},
		}];
		setSelectedStudents(studentId? selectedStudent: studentsAll);
	}, [students]);

	const handleActiveTabChanged = useCallback(
		(value: StudentTabId) => {
			setActiveTab(value);
			props.onActiveTabChange?.(value);
		}, [props.onActiveTabChange]
	);

	const getTabContent = (): JSX.Element => {
		if (skeleton) {
			return <SkeletonList/>;
		}

		if (activeTab === StudentTabId.Classes) {
			return (
				<ClassList
					classes={classes}
					students={students}
					selectedStudents={selectedStudents}
					toggleStudents={toggleStudents}
					selected={selectedItems.classes}
					setSelected={setSelectedClass}
				/>
			);
		}

		if (activeTab === StudentTabId.Groups) {
			return (
				<GroupList
					classes={classes}
					groups={groups}
					students={students}
					selectedStudents={selectedStudents}
					toggleStudents={toggleStudents}
					selected={selectedItems.groups}
					setSelected={setSelectedGroup}
				/>
			);
		}

		if (activeTab === StudentTabId.Students) {
			return (
				<StudentList
					students={students}
					selectedStudents={selectedStudents}
					toggleStudent={toggleStudent}
				/>
			);
		}
	};

	return (
		<>
			<Title size='small' data-cy='select-students-title'>Select Students</Title>
			<ExpandablePanelContentWrapper>
				<Tabs<StudentTabId>
					activeTab={activeTab}
					setActiveTab={handleActiveTabChanged}
					tabItems={tabItems}
				/>
				{getTabContent()}
			</ExpandablePanelContentWrapper>
		</>
	);
}
