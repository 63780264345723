import {VariantProps} from '@stitches/react';
import {AnswerBar} from './index.styled';
import {useMemo} from 'react';
import {AnswersStatistics} from '../../../../hooks/use-item-statistics';

type AnswerBarItem = {
	type: NonNullable<VariantProps<typeof AnswerBar>['itemType']>;
	value: number;
	borderBottomWidth?: number | undefined;
};

export function useAnswerBarItems({notTestedPercentage, incorrectPercentage, correctPercentage}: AnswersStatistics) {
	return useMemo(() => {
		const answerBarItems: AnswerBarItem[] = [
			{
				type: 'notTested',
				value: notTestedPercentage,
				borderBottomWidth: notTestedPercentage !== 100 ? 0 : undefined,
			},
			{
				type: 'incorrect',
				value: incorrectPercentage,
			},
			{
				type: 'correct',
				value: correctPercentage,
			},
		];

		return answerBarItems;
	}, [notTestedPercentage, incorrectPercentage, correctPercentage]);
}
