import {ComponentProps, forwardRef, useCallback} from 'react';
import {ElementStatus, useFormElementContext} from '../../../../form';
import {RadioGroup as BaseRadioGroup} from '../../../../controls/radio-group';

type RadioGroupProps = Omit<ComponentProps<typeof BaseRadioGroup.Root>, 'onValueChange' | 'value' | 'defaultValue'>;

export const RadioGroupRoot = forwardRef<HTMLDivElement, RadioGroupProps>(({children, ...props}, ref) => {

	const {status, setValue, value} = useFormElementContext({string: true}, RadioGroupRoot);
	const onValueChange = useCallback((value: string | undefined) => setValue(value), [setValue]);

	const commonProps = {
		disabled: status === ElementStatus.disabled,
		value,
		onValueChange,
		ref,
		...props,
	};

	return <BaseRadioGroup.Root {...commonProps}>
		<BaseRadioGroup.Content>
			{children}
		</BaseRadioGroup.Content>
	</BaseRadioGroup.Root>;
});

export const RadioGroupContent = BaseRadioGroup.Content;
