import {useMemo} from 'react';
import {SubjectTab} from '@esgi/main/libs/store';
import {AllTabListVariant} from '@esgi/main/features/untested-students-report';

export function useAllTabLists({
	districtSubjects,
	schoolSubjects,
	personalSubjects,
}: {
	districtSubjects: SubjectTab[];
	schoolSubjects: SubjectTab[];
	personalSubjects: SubjectTab[];
}) {
	const allTabLists = useMemo<AllTabListVariant[]>(
		() => [
			{
				subjects: districtSubjects,
				canEdit: false,
				canRearrange: false,
			},
			{
				subjects: schoolSubjects,
				canEdit: false,
				canRearrange: false,
			},
			{
				subjects: personalSubjects,
				canEdit: false,
				canRearrange: false,
			},
		],
		[districtSubjects, schoolSubjects, personalSubjects],
	);

	return {allTabLists};
}
