import {
	Loader as NewLoader,
	ObservableLoader as NewObservableLoader,
	ServiceLoader as NewServiceLoader,
} from '@esgillc/ui-kit/loader';

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/loader instead.
 * */
export const Loader = NewLoader;

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/loader instead.
 * */
export const ObservableLoader = NewObservableLoader;

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/loader instead.
 * */
export const ServiceLoader = NewServiceLoader;
