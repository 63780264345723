import {AlertBody} from './components/alert-body';
import {AlertCloseIcon} from './components/alert-close-icon';
import {AlertFooter} from './components/alert-footer';
import {AlertHeader} from './components/alert-header';
import {AlertRoot} from './components/alert-root';
import {useAlertOpen, useAlertClose, useAlertRef} from './hooks';

export const Alert = Object.assign(AlertRoot, {
	CloseIcon: AlertCloseIcon,
	Header: AlertHeader,
	Body: AlertBody,
	Footer: AlertFooter,
	useRef: useAlertRef,
	useClose: useAlertClose,
	useOpen: useAlertOpen,
});

export {type AlertColorConfig, type AlertRef} from './types';

export {alertColorsConfig, alertContentInlinePadding} from './constants';
