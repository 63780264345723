import * as React from 'react';
import type {SVGProps} from 'react';

export function DragDrop(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 16 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M6.281 15.1a.613.613 0 0 1-.625-.6c0-.331.28-.6.625-.6H9.72c.345 0 .625.269.625.6 0 .331-.28.6-.625.6H6.28ZM6.281 18.1a.613.613 0 0 1-.625-.6c0-.331.28-.6.625-.6H9.72c.345 0 .625.269.625.6 0 .331-.28.6-.625.6H6.28ZM7.571 8.672a.622.622 0 0 1 .858 0l1.893 1.828c.237.229.237.6 0 .828a.622.622 0 0 1-.858 0L8 9.914l-1.464 1.414a.622.622 0 0 1-.858 0 .572.572 0 0 1 0-.828L7.57 8.672Zm.858 14.656a.622.622 0 0 1-.858 0L5.678 21.5a.572.572 0 0 1 0-.828.622.622 0 0 1 .858 0L8 22.086l1.464-1.414a.622.622 0 0 1 .858 0c.237.228.237.6 0 .828L8.43 23.328Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
