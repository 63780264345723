import {StudentInfo, StudentSpecialProgram} from '../../types';
import {TestType} from '../../../types';
import {sortBy} from 'underscore';

export const calculateClassAverageTestInfo = (students: StudentInfo[], totalPossible: number, filters: StudentSpecialProgram[], testType: TestType) => {

	let filteredStudentsData = getTestedStudents(students);

	if(filters.length){
		filteredStudentsData = getFilteredStudents(students, filters);
	}

	const {totalDuration, totalCorrectAnswers, totalSessionsCount, studentsHavingTestSessions} = filteredStudentsData.reduce((accumulator, student) => {
		const {averageDuration, averageCorrectAnswers, sessionsCount} = calculateStudentAverageTestSessionsInfo(student);

		accumulator.totalDuration += averageDuration;
		accumulator.totalCorrectAnswers += averageCorrectAnswers;
		accumulator.totalSessionsCount += sessionsCount;

		if (sessionsCount > 0) {
			accumulator.studentsHavingTestSessions++;
		}

		return accumulator;
	}, {totalDuration: 0, totalCorrectAnswers: 0, totalSessionsCount: 0, studentsHavingTestSessions: 0});

	let classAverageProgress = totalCorrectAnswers && studentsHavingTestSessions
		? ((totalCorrectAnswers / studentsHavingTestSessions)/ totalPossible) * 100
		: 0;

	if(testType === TestType.Rubric){
		classAverageProgress = totalCorrectAnswers / studentsHavingTestSessions;
	}

	return {
		isProgressSuccess: classAverageProgress > 50,
		progress: classAverageProgress,
		duration: totalSessionsCount ? (totalDuration / totalSessionsCount) / 1000 : 0,
		sessionsCount: totalSessionsCount,
	};
};

const calculateStudentAverageTestSessionsInfo = (student: StudentInfo) => {

	if(!student.testSessionsInfo.testSessions.length){
		return {
			averageDuration: 0,
			averageCorrectAnswers: 0,
			sessionsCount: 0,
		};
	}

	const sessionsCount = student.testSessionsInfo.testSessions.length;

	const {totalDuration, totalCorrectAnswers} = sortBy(student.testSessionsInfo.testSessions, d => new Date(d.testDate)).reduce((accumulator, session) => {
		accumulator.totalDuration += session.duration;
		accumulator.totalCorrectAnswers = session.correctAnswers;
		return accumulator;
	}, {totalDuration: 0, totalCorrectAnswers: 0});

	return {
		averageDuration: totalDuration,
		averageCorrectAnswers: totalCorrectAnswers,
		sessionsCount,
	};

};

export const calculateFilteredStudentsCount = (students: StudentInfo[], totalPossible: number, filters: StudentSpecialProgram[]) => {
	const testedStudents = getTestedStudents(students);
	let testedStudentsCount = testedStudents.length;

	if(filters.length){
		testedStudentsCount = getFilteredStudents(testedStudents, filters).length;
	}

	return {
		testedStudentsCount: testedStudentsCount,
		isProgressSuccess: testedStudentsCount > (totalPossible / 2),
	};
};

export const getTestedStudents = (students: StudentInfo[]): StudentInfo[] => students.filter(st => st.testSessionsInfo.testSessions.length);

export const getFilteredStudents = (students: StudentInfo[], filters: StudentSpecialProgram[]): StudentInfo[] => {
	return students.filter(st => st.studentSpecialPrograms.some(p => filters.includes(p)));
};

export const sortDates = (students: StudentInfo[]) => {

	const testedStudents = getTestedStudents(students);

	const getSessionsDates = testedStudents.reduce((accumulator, student) => {
		const datesList = student.testSessionsInfo.testSessions.map(session => session.testDate);

		return [...accumulator, ...datesList];
	}, []);

	const sortedDates = getSessionsDates.map(d => new Date(d)).sort((a, b) => a.getTime() - b.getTime());

	return {
		last: sortedDates[sortedDates.length - 1],
		first: sortedDates[0],
	};
};
