import {HierarchyMode} from 'modules/hierarchy/core/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {BehaviorSubject, filter, Observable, Subject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import * as utils from './utils';
import * as array from '../../shared/utils/array';
import {
	ClassResponse,
	GridModel,
	GroupResponse,
	SelectStudentFormControls,
	StudentSelectionModel,
	StudentsStepInitResponse,
} from './types';
import {BaseService} from '@esgi/core/service';
import {
	LecturerModel,
	StudentResponse,
	StudentStepState,
	StudentWithUnits,
	UnitType,
} from '../../shared/types';
import {StudentSort} from '@esgi/core/enums';
import {StudentSorter} from 'global/utils/StudentSorter';

export default class DataService extends BaseService {
	private readonly controller: string = 'writing-practice';

	private readonly studentsMaxColumnsCount = 4;
	private readonly studentsMinItemsCount = 6;

	private readonly groupsMaxColumnsCount = 2;
	private readonly groupsMinItemsCount = 3;

	public isSpecialist;

	private allClasses: ClassResponse[];
	private allGroups: GroupResponse[];
	private allStudents: StudentResponse[];
	private lecturer: LecturerModel;

	public allStudentsSelected: boolean;
	public readonly form: FormGroup<SelectStudentFormControls, any>;

	private readonly gridData: BehaviorSubject<GridModel> = new BehaviorSubject<GridModel>(new GridModel());
	public readonly filterChanged$: Observable<GridModel> = this.completeOnDestroy(this.gridData);

	private readonly studentColumns: BehaviorSubject<StudentResponse[][]> = new BehaviorSubject<StudentResponse[][]>([]);
	public readonly studentColumns$: Observable<StudentResponse[][]> = this.completeOnDestroy(this.studentColumns);

	private readonly studentsSelectionState: Subject<StudentSelectionModel> = new Subject<StudentSelectionModel>();
	public readonly studentsSelectionStateChanged$: Observable<StudentSelectionModel> = this.completeOnDestroy(this.studentsSelectionState);

	private readonly groupsColumns: BehaviorSubject<GroupResponse[][]> = new BehaviorSubject<GroupResponse[][]>([]);
	public readonly groupsColumns$: Observable<GroupResponse[][]> = this.completeOnDestroy(this.groupsColumns);
	private studentSort: StudentSort;

	constructor() {
		super();
		this.form = this.createForm();
	}

	public init(hierarchy: HierarchySnapshot, classId?: number, groupId?: number, studentIds?: number[]) {
		this.studentSort = hierarchy.studentSort;
		this.isSpecialist = hierarchy.mode === HierarchyMode.Specialist;
		const request = {
			teacherId: hierarchy.classic.teacherID,
			specialistId: hierarchy.specialist.userID,
		};
		this.httpClient
			.ESGIApi
			.get<StudentsStepInitResponse>(this.controller, 'students/init', request)
			.subscribe((response: StudentsStepInitResponse) => {
				this.lecturer = response.lecturer;
				this.allClasses = response.classes;
				this.allStudents = response.students;
				this.allGroups = this.isSpecialist
					? response.specialistGroups
					: response.groups;

				const selectedClassId = classId ?? hierarchy.classic?.classID;
				const normalizedClassId = utils.firstOrDefault(this.allClasses, selectedClassId)?.id;
				this.form.controls.class.value = normalizedClassId;

				this.addAllGroupOption(normalizedClassId);

				const filteredGroups = utils.filterGroups(this.allGroups, this.form.controls.class.value);
				const selectedGroupId = groupId ?? (this.isSpecialist ? hierarchy.specialist?.groupID : hierarchy.classic?.groupID);
				const normalizedGroupId = utils.normalizeGroupId(filteredGroups, selectedGroupId);
				this.form.controls.group.value = normalizedGroupId;

				const filteredStudents = StudentSorter.sortStudents(utils
					.filterStudents(this.allStudents, this.isSpecialist, this.form.controls.class.value, this.form.controls.group.value), this.studentSort);
				const selectedStudentIds = studentIds ?? (this.isSpecialist
						? (hierarchy.specialist?.studentID == 0 ? filteredStudents.map(x => x.id) : [hierarchy.specialist?.studentID])
						: (hierarchy.classic?.studentID == 0 ? filteredStudents.map(x => x.id) : [hierarchy.classic?.studentID])
				);
				const normalizedStudentIds = utils.normalizeStudentIds(this.allStudents,
					selectedStudentIds,
					this.isSpecialist,
					normalizedClassId,
					normalizedGroupId);
				this.form.controls.student.value = normalizedStudentIds;

				this.updateGrid(this.allClasses, filteredGroups, filteredStudents);
				this.handleAllStudentSelectionFlag(normalizedStudentIds);
				this.subscribeToFormChanges();
			});
	}

	private createForm() {
		return new FormGroup({
			class: new FormControl<number>(0),
			group: new FormControl<number>(0),
			student: new FormControl<number[]>([], {validators: [Validators.required()]}),
		} as SelectStudentFormControls)
	}

	private subscribeToFormChanges() {
		this.completeOnDestroy(this.form.controls.class.onChanged
			.pipe(filter(v => v.reason === 'value')))
			.subscribe(change => {
				const classId = change.currState.value;
				const normalizedClassId = utils.firstOrDefault(this.allClasses, classId)?.id;
				this.addAllGroupOption(normalizedClassId);

				const groups = utils.filterGroups(this.allGroups, classId);
				const students = StudentSorter.sortStudents(utils
					.filterStudents(this.allStudents, this.isSpecialist, this.form.controls.class.value, null), this.studentSort);
				const selectedStudentIds = students.map(x => x.id);
				const normalizedStudentIds = utils.normalizeStudentIds(this.allStudents,
					selectedStudentIds,
					this.isSpecialist,
					normalizedClassId,
					null);

				this.form.controls.group.value = groups[0]?.id ?? -1;
				this.form.controls.student.value = normalizedStudentIds;
				this.updateGrid(this.allClasses, groups, students);
				this.handleAllStudentSelectionFlag(normalizedStudentIds);
			});

		this.completeOnDestroy(this.form.controls.group.onChanged
			.pipe(filter(v => v.reason === 'value')))
			.subscribe(change => {
				const classId = this.form.controls.class.value;
				const normalizedClassId = utils.firstOrDefault(this.allClasses, classId)?.id;
				const groupId = change.currState.value;
				const groups = utils.filterGroups(this.allGroups, classId);
				const normalizedGroupId = utils.normalizeGroupId(groups, groupId);
				const students = StudentSorter.sortStudents(utils
					.filterStudents(this.allStudents, this.isSpecialist, classId, groupId), this.studentSort);
				const selectedStudentIds = students.map(x => x.id);
				const normalizedStudentIds = utils.normalizeStudentIds(this.allStudents,
					selectedStudentIds,
					this.isSpecialist,
					normalizedClassId,
					normalizedGroupId);
				this.form.controls.student.value = normalizedStudentIds;
				this.updateGrid(this.allClasses, groups, students);
				this.handleAllStudentSelectionFlag(normalizedStudentIds);
			});

		this.completeOnDestroy(this.form.controls.student.onChanged
			.pipe(filter(v => v.reason === 'value')))
			.subscribe(change => {
				this.handleAllStudentSelectionFlag(change.currState.value);
			})
	}

	public inverseAllStudentsSelector() {
		if (this.form.controls.student.value.length === this.gridData.value.students.length) {
			this.form.controls.student.value = [];
		} else {
			this.form.controls.student.value = this.gridData.value.students.map(x => x.id);
		}
		this.handleAllStudentSelectionFlag(this.form.controls.student.value);
	}

	private isAllStudentSelected(selectedIds: number[]) {
		return selectedIds.length > 0 && selectedIds.length === this.gridData.value.students.length
	}

	private handleAllStudentSelectionFlag(selectedIds: number[]) {
		const isAllStudentSelected = this.isAllStudentSelected(selectedIds)
		this.studentsSelectionState.next({
			selectedAllStudents: isAllStudentSelected,
			hasAnySelectedData: selectedIds.length > 0,
		});
	}

	private updateGrid(classes: ClassResponse[], groups: GroupResponse[], students: StudentResponse[]) {
		this.groupsColumns.next(this.splitGroupsIntoColumns(groups));
		this.studentColumns.next(this.splitStudentsIntoColumns(students));
		this.gridData.next({classes: classes, groups: groups, students: students} as GridModel);
	}

	public getFormState(): StudentStepState {
		const students = this.gridData
			.value
			.students
			.filter(student => this.form.controls.student.value.includes(student.id))
			.map(student => {
				if (this.isSpecialist) {
					const selectedGroups = this.form.controls.group.value === 0
						? this.gridData.value.groups.filter(x => student.specialistGroups.includes(x.id))
						: [this.gridData.value.groups.find(x => x.id === this.form.controls.group.value)]
					return new StudentWithUnits(student, selectedGroups);
				} else {
					const selectedClass = this.gridData
						.value
						.classes
						.find(x => x.id === this.form.controls.class.value);
					return new StudentWithUnits(student, [selectedClass]);
				}
			}) ?? [];

		return {
			lecturer: this.lecturer,
			classId: this.form.controls.class.value,
			groupId: this.form.controls.group.value,
			unitType: this.isSpecialist
				? UnitType.Group
				: UnitType.Class,
			students,
		} as StudentStepState;
	}

	public destroy() {
		super.destroy();
		this.form.destroy();
	}

	private splitStudentsIntoColumns(students: StudentResponse[]): StudentResponse[][] {
		return array.chunk(students, this.studentsMaxColumnsCount, this.studentsMinItemsCount);
	}

	private addAllGroupOption(classId: number) {
		if (this.allGroups.find(g => g.id === 0) == null){
			this.allGroups.unshift({
				classID: classId,
				id: 0,
				name: 'All Groups',
			} as GroupResponse);
		}
		this.allGroups.find(g => g.id === 0).classID = classId;
	}

	private splitGroupsIntoColumns(groups: GroupResponse[]): GroupResponse[][] {
		const copy = [...groups];
		return array.chunk(copy, this.groupsMaxColumnsCount, this.groupsMinItemsCount);
	}
}