export const reportRoutes = {
	parentLetter: '/reports/parent-letter',
	studentDetail: '/reports/student-details',
	studentProgress: '/reports/student-progress',
	classGrades: '/reports/class-grades',
	classTotals: '/reports/class-total',
	itemAnalysis: '/reports/item-analysis',
	pieCharts: '/reports/pie-charts',
	rubric: '/reports/rubric-results',
	untestedStudents: '/reports/untested-students',
};
