import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Sort} from '@esgi/ui';

export const Layout = styled(FlexBox, {
	flexDirection: 'column',
	background: `#F5F5F5`,
	height: '100%',
	width: '100%',
	padding: '16px 20px',
});

export const StyledSort = styled(Sort, {
	variants: {
		descending: {
			true: {
				transform: 'rotate(-180deg)',
			},
		},
	},
});

export const Filters = styled(GridBox, {
	justifyContent: 'space-between',
	alignItems: 'center',
	gridAutoFlow: 'column',
	marginBottom: 20,
});
