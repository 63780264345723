import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {TestColorIndicator as TestIndicator} from '@esgi/main/kits/common';
import {CountInfo as CountInfoBox, OrderButtons} from '@esgi/main/kits/assignments';
import {Tooltip} from '@esgi/ui/tooltip';

export const TableTests = styled(GridBox, {
	rowGap: '$4',
});

export const TableTestsRow = styled(GridBox, {
	columnGap: '$4',
	alignItems: 'center',
	gridTemplateColumns: '280px 1fr 280px 24px',
});

export const CellTestName = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gap: '$2',
	minHeight: 24,
	alignItems: 'center',
});

export const TestColorIndicator = styled(TestIndicator, {
	width: 10,
	paddingLeft: 4,
	paddingRight: 4,
});

export const SubjectsBox = styled(GridBox, {
	variants: {
		isTruncated: {
			true: {
				gridTemplateColumns: '1fr auto',
				gap: '$2',
				alignItems: 'center',
				overflow: 'hidden',
			},
		},
	},
});

export const SubjectsListContainer = styled(GridBox, {
	gridAutoColumns: 'max-content',
	gridAutoFlow: 'column',
	gap: '$2',
	alignItems: 'center',
	overflow: 'hidden',
});

export const TooltipContent = styled(Tooltip.Content, {
	width: 'var(--radix-tooltip-trigger-width)',
});

export const SubjectsListContainerTooltip = styled(FlexBox, {
	width: '100%',
	flexWrap: 'wrap',
	marginBottom: -8,

	'& > div': {
		marginRight: '$2',
		marginBottom: '$2',
	},
});

export const CellTestBarInfo = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gap: 20,
	alignItems: 'center',
});

export const CountInfo = styled(CountInfoBox, {
	marginBottom: 5,
});

export const OrderButtonsRoot = styled(OrderButtons.Root, {
	width: '100%',
	justifyContent: 'center',
});
