import {styled} from '@esgi/ui/theme';
import {Box} from '../../../box';
import {Button} from '../../../../buttons';
import {buttonDraggableHeight} from './constants';
import {ListItem} from '../list-item';

export const ItemContent = styled(Box, {
	alignSelf: 'center',
});

export const ButtonDraggable = styled(Button.Icon, {
	width: 16,
	height: buttonDraggableHeight,
	cursor: 'grab',

	'& svg': {
		width: '100%',
		height: '100%',
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const ItemDraggable = styled(ListItem, {
	transition: 'none',
	display: 'grid',
	gridTemplateColumns: '1fr auto',
	boxSizing: 'border-box',

	[`& ${ButtonDraggable}`]: {
		color: '$neutral72',
	},

	'&:hover': {
		color: '$secondary',
		borderBottomColor: '$secondaryMuted',

		[`& ${ButtonDraggable}`]: {
			color: '$secondary',
		},
	},

	variants: {
		resetVerticalPadding: {
			true: {
				paddingTop: 0,
				paddingBottom: 0,
			},
		},

		isDragging: {
			true: {
				borderWidth: 1,
				borderStyle: 'solid',
				borderRadius: 2,
				color: '$primary',
				borderColor: '$primaryMuted',
				boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',

				[`& ${ButtonDraggable}`]: {
					color: '$primary',
				},
			},
		},
	},
});
