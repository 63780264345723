export module Events {
	export function Start(reportGuid: string, fileType: string, displayRemainingText: boolean): StartArgs {
		return {
			reportGuid: reportGuid,
			fileType: fileType,
			displayRemainingText: displayRemainingText,
		};
	}

	export interface StartArgs {
		reportGuid: string;
		fileType: string;
		displayRemainingText: boolean;
	}

	export function StartImport(fileID: number): StartImportArgs {
		return {fileID: fileID};
	}

	export interface StartImportArgs {
		fileID: number;
	}
}