import React, {ReactNode} from 'react';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BottomPanel, NextColumnButton, NextRowButton, RightPanel} from '@esgi/deprecated/ui-kit/matrix';
import Matrix from '@esgi/deprecated/ui-kit/matrix/matrix';
import {PrevColumnButton} from '@esgi/deprecated/ui-kit/matrix/prev-column-button';
import {PrevRowButton} from '@esgi/deprecated/ui-kit/matrix/prev-row-button';
import {Criteria, Description, Level, LevelDisplayMode} from '../models';
import {ColumnWidth, RowHeight} from '../rubric-edit-form';
import RubricService from '../rubric-service';
import DescriptionView from './components/description/description-view';
import styles from './preview.template.module.less';

interface Props {
	rubricService: RubricService;
	showCellHeader?: boolean;
	maxWidth?: number;
	maxHeight?: number;
}

export default class RubricPreviewForm extends React.PureComponent<Props> {
	private onDestroy$: Subject<void> = new Subject();

	public componentDidMount() {
		combineLatest(
			this.props.rubricService.levels$,
			this.props.rubricService.criteria$,
			this.props.rubricService.descriptions$,
		).pipe(takeUntil(this.onDestroy$))
			.subscribe(data => {
				const levels = data[0];
				const criteria = data[1];
				const descriptions = data[2];
				this.setState({
					criteria,
					levels,
					descriptions,
				});
			});
	}

	public render() {
		const criteria = this.props.rubricService.criteria$.value;
		const levels = this.props.rubricService.levels$.value;
		const descriptions = this.props.rubricService.descriptions$.value;
		const cellGetter = (source, level, criteria) => source.find(d => d.levelID === level.id && d.criteriaID === criteria.id);

		return <Matrix maxWidth={this.props.maxWidth || 945}
		               maxHeight={this.props.maxHeight || 555}
		               className={{
			               body: styles.body,
			               prevRowButton: styles.prevRowButton,
			               nextRowButton: styles.nextRowButton,
		               }}
		               renderHeader={this.renderHeader}
		               columnHeaderOptions={{cells: levels, cellRenderer: this.renderLevel}}
		               rowHeaderOptions={{cells: criteria, cellRenderer: this.renderCriteria}}
		               cellsOptions={{cells: descriptions, cellGetter, cellRenderer: this.renderDescription}}>
			<RightPanel>
				<NextColumnButton className={styles.nextColumnButton} columnWidth={ColumnWidth}/>
			</RightPanel>
			<BottomPanel>
				<NextRowButton className={styles.nextRowButton} rowHeight={RowHeight}/>
			</BottomPanel>
		</Matrix>;
	}

	private renderHeader(_, headerRenderer) {
		return <thead>
		<tr key={-1}>
			<th className={styles.actionsCell}>
				<div className={styles.actions}>
					<PrevColumnButton className={styles.prevColumnButton} columnWidth={ColumnWidth}/>
					<PrevRowButton className={styles.prevRowButton} rowHeight={RowHeight}/>
				</div>
			</th>
			{headerRenderer()}
		</tr>
		</thead>;
	}

	private renderCriteria = (criteria: Criteria): ReactNode => <th key={criteria.order} className={styles.criteria}>
		<div key={criteria.id} className={styles.box}>
			<div className={styles.innerBox}>
				<div className={styles.header}>
					{this.props.showCellHeader && `Criteria ${criteria.order}`}
				</div>
				<div className={styles.content}>
					<span>{criteria.name}</span>
				</div>
			</div>
		</div>
	</th>;

	private renderLevel = (level: Level, index: number): ReactNode => <th key={level.score} className={styles.level}>
		<div className={styles.box}>
			<div className={styles.header}>
				{this.props.showCellHeader && `Level ${level.score}${index === 0 ? ' (highest)' : ''}`}
			</div>
			<div className={styles.content}>
				<span>
					{level.name || level.score}
				</span>
			</div>
		</div>
	</th>;


	private renderDescription = (description: Description): ReactNode => {
		const level = this.props.rubricService.levels$.value.find(l => l.id === description.levelID);
		const criteria = this.props.rubricService.criteria$.value.find(c => c.id === description.criteriaID);

		let levelName = level.name;
		if (this.props.rubricService.levelDisplayMode$.value === LevelDisplayMode.Number) {
			levelName = 'Level ' + level.score;
		}

		return <td>
			<DescriptionView key={description.id}
			                 showHeader={this.props.showCellHeader}
			                 levelName={levelName}
			                 criteriaName={'Criteria ' + criteria?.name}
			                 description={description}/>
		</td>;
	};

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
