import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {MeridiemText, TimeMeridiem, TimeText} from './index.styled';
import {VariantProps} from '@stitches/react';
import {Text} from '@esgi/ui/typography';
import {BaseComponentProps, ThemeColorKeys, getThemeColor} from '@esgi/ui';
import {useTimestampContext} from '../../context';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
		backgroundColor?: ThemeColorKeys;
		timeTextColor?: VariantProps<typeof Text>['color'];
	};

export const TimestampColorful = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'imestamp-colorful', css = {}, backgroundColor = 'lowContrast', timeTextColor = 'highContrast', ...props},
		forwardedRef,
	) => {
		const {time, meridiem} = useTimestampContext();

		return (
			<TimeMeridiem
				dataCy={dataCy}
				css={{
					borderColor: getThemeColor(backgroundColor),
					backgroundColor: getThemeColor(backgroundColor),
					...css,
				}}
				ref={forwardedRef}
				{...props}
			>
				<TimeText size='small' font='mono' bold color={timeTextColor} data-time-text=''>
					{time}
				</TimeText>
				<MeridiemText size='small' color='surface' data-meridiem-text=''>
					{meridiem}
				</MeridiemText>
			</TimeMeridiem>
		);
	},
);
