import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const IndicatorBox = styled(Box, {
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	userSelect: 'none',
	border: 'none',
	borderRadius: '4px',
	marginLeft: 'auto',

	height: '16px',
	width: 'max-content',
	minWidth: '16px',

	variants: {
		color: {
			primary: {
				backgroundColor: '$primaryVivid',
			},

			secondary: {
				backgroundColor: '$secondary',
			},

			neutral: {
				backgroundColor: '$background',
			},

			orange: {
				backgroundColor: '$neutral56',
			},

			red: {
				backgroundColor: '$negative',
			},

			yellow: {
				backgroundColor: '$yellow48',
			},

			green: {
				backgroundColor: '$positive',
			},

			indigo: {
				backgroundColor: '$indigo',
			},
		},
	},
});
