import {BaseService} from '@esgi/core/service';
import {IdNameModel, Level, Details, ReportData, ZoomOption, InitResponse, UpdateResponse, ReportInfo} from '../types';
import {BehaviorSubject} from 'rxjs';
import {calculateMetrics, getReportDate, sortQuestionData} from '../utils';

export class ReportService extends BaseService {
	public questionWidth = 35;
	public level: Level = 'user';
	public gradeLevels: Array<IdNameModel>;

	public sortOptionMode$ = new BehaviorSubject('0');
	public reportData$ = new BehaviorSubject<ReportData | null>(null);
	public testResultsCorrectVerbiage$ = new BehaviorSubject('');
	public testResultsIncorrectVerbiage$ = new BehaviorSubject('');
	public filteredGradeLevels$ = new BehaviorSubject<IdNameModel[]>([]);
	public selectedGradeLevel$ = new BehaviorSubject<IdNameModel | null>(null);
	public selectedQuestionLabel$ = new BehaviorSubject('');
	public reportDate$ = new BehaviorSubject('');
	public questionDetails$ = new BehaviorSubject<Details | null>(null);
	public questionDetailsShow$ = new BehaviorSubject<boolean>(false);
	public selectedPoint = new BehaviorSubject<any>(null);
	public zoom$ = new BehaviorSubject(1);
	public downloadingPdf$ = new BehaviorSubject(false);
	public needRerender$ = new BehaviorSubject(false);

	public resetZoom() {
		this.zoom$.next(this.calcZoomToFit(1060));
	}

	public changeZoom(option: ZoomOption) {
		const step = 0.1;
		let zoom = this.zoom$.value;
		switch (option) {
			case 'Zoom In': {
				zoom += step;
				break;
			}
			case 'Zoom Out': {
				zoom -= step;
				break;
			}
			case 'Zoom To Fit': {
				zoom = this.calcZoomToFit();
				break;
			}
			case '50%': {
				zoom = 0.5;
				break;
			}
			case '100%': {
				zoom = 1;
				break;
			}
			case '200%': {
				zoom = 2;
				break;
			}
		}

		if (zoom < 0.5) {
			zoom = 0.5;
		}
		if (zoom > 2) {
			zoom = 2;
		}
		this.zoom$.next(zoom);
	}

	public calculateMetrics(data: ReportInfo) {
		this.reportData$.next(calculateMetrics(data, this.gradeLevels, this.selectedGradeLevel$.value));
	}

	public getFilteredStudents() {
		let students = [...this.reportData$.value.students];
		if (this.gradeLevels && this.selectedGradeLevel$.value.id > 0) {
			students = students.filter(x => x.gradeLevelID === this.selectedGradeLevel$.value.id);
		}
		return students;
	}

	public updateGradeLevelData(data: ReportInfo) {
		if (!this.gradeLevels) {
			return;
		}

		const studentGradeLevels = data.students.map(x => x.gradeLevelID).filter((v, i, a) => a.indexOf(v) === i);
		let gradeLevels = [...this.gradeLevels];
		gradeLevels = gradeLevels.filter(x => studentGradeLevels.indexOf(x.id) !== -1);
		gradeLevels.unshift({id: 0, name: 'All'});

		this.filteredGradeLevels$.next(gradeLevels);
		if (this.selectedGradeLevel$.value === null) {
			this.selectedGradeLevel$.next(gradeLevels[0]);
		} else if (this.selectedGradeLevel$.value?.id === 0) {
			this.selectedGradeLevel$.next(gradeLevels[0]);
		}
	}

	public calcZoomToFit(width: number = 0) {
		if (width === 0) {
			width = window.innerWidth;
		}
		let zoom = (width * 0.98 - 110) / (this.questionWidth * this.reportData$.value.questions.length);
		if (zoom < 0.5) {
			zoom = 0.5;
		}
		if (zoom > 2) {
			zoom = 2;
		}

		return zoom;
	}

	public selectPoint({point}) {
		const selectedPoint = this.selectedPoint.value;
		if (selectedPoint != null && selectedPoint.index === point.index) {
			this.selectChartPoint(point);
			return;
		}

		const questionsData = sortQuestionData(this.reportData$.value.questions.slice(), this.sortOptionMode$.value);
		const question = questionsData[point.index];
		const questionId = question.questionID;
		this.selectedQuestionLabel$.next(question.label);
		this.reportDate$.next(getReportDate());

		this.questionDetails$.next(this.getQuestionDetails(questionId));
		this.setQuestionDetailsShow(true);
		this.selectChartPoint(point);
	}

	public setQuestionDetailsShow(show: boolean) {
		this.questionDetailsShow$.next(show);
		const selectedPoint = this.selectedPoint.value;
		if (!show && selectedPoint) {
			const {index, series} = selectedPoint;
			if (series.data){
				series.data[index].update({color: series.color});
			}
			this.setSelectedPoint(null);
		}
	}

	public setNeedRerender(value: boolean) {
		this.needRerender$.next(value);
	}

	public setSelectedPoint(point: any) {
		if (point) {
			const {index, series} = point;
			this.selectedPoint.next({index, series});
		} else {
			this.selectedPoint.next(point);
		}
	}

	public selectChartPoint(point: any) {
		const selectedPoint = this.selectedPoint.value;
		if (selectedPoint) {
			const {index, series} = selectedPoint;
			series.data[index].update({
				color: series.color,
				states: {hover: {color: series.options.states.hover.color}},
			});
		}

		const color = point.series.options.states.hover.color;
		point.update({color, states: {hover: {color}}});

		this.setSelectedPoint(point);
	}

	public getQuestionDetails(questionId: number) {
		const filteredStudents = this.getFilteredStudents();
		const studentIDs = filteredStudents.map(({id}) => id);
		const question = this.reportData$.value.questions.find(x => x.questionID === questionId);
		const answers = question.answers.filter(x => studentIDs.indexOf(x.studentID) !== -1);
		const correctAnswers = answers.filter(x => x.answerState === 1).map(x => x.studentID);
		const incorrectAnswers = answers.filter(x => x.answerState === 0).map(x => x.studentID);

		const details: Details = {
			studentsCorrect: filteredStudents.filter(({id}) => correctAnswers.includes(id)).map(({name}) => name),
			studentsIncorrect: filteredStudents.filter(({id}) => incorrectAnswers.includes(id)).map(({name}) => name),
			studentsNotTested: filteredStudents.filter(({id}) => !correctAnswers.includes(id) && !incorrectAnswers.includes(id)).map(({name}) => name),
		};

		return details;
	}

	public getFullChartWidth() {
		return Math.max(this.zoom$.value * this.questionWidth * this.reportData$.value.questions.length + 70, 340);
	}

	public initReport(data: InitResponse) {
		const {reportFilter} = data;

		this.testResultsCorrectVerbiage$.next(data.testResultsCorrectVerbiage);
		this.testResultsIncorrectVerbiage$.next(data.testResultsIncorrectVerbiage);

		if (reportFilter.gradeLevels) {
			this.gradeLevels = reportFilter.gradeLevels;
		}
	}

	public updateReport({reportData}: UpdateResponse) {
		this.setQuestionDetailsShow(false);
		this.calculateMetrics(reportData);
		this.updateGradeLevelData(reportData);
	}
}
