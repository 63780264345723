import {Student} from '@esgi/main/libs/store';
import {Question} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/modules/activities/sight-words/question';

export {type Test} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/modules/activities/sight-words/test';
export {type Question} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/modules/activities/sight-words/question';

export enum FormatType {
	FirstName,
	FirstAndLastName,
}

export interface LecturerType {
	firstName: string;
	lastName: string;
	title: string;
}

export enum FontFamily {
	Normal,
	Lined,
	DottedLined,
	Blank,
}

export class Rectangle {
	width: number;
	height: number;

	constructor(width: number, height: number) {
		this.width = width;
		this.height = height;
	}
}

export interface FontSizeCalculationResult {
	fontSize: number;
	width: number;
	height: number;
}

export interface UnitData {
	id: number;
	type: UnitType;
	name?: string;
}

export interface StudentWithUnit {
	studentId: number;
	unitData: UnitData;
}

export enum UnitType {
	Class,
	Group,
	None,
}

interface StudentWithUnitType extends Student {
	unitData: UnitData;
}

export interface UnitDataSettings {
	students: StudentWithUnitType[];
	questions: QuestionWithTest[];
	lecturer: LecturerType;
}

export interface PrintSettings {
	selectedFontSize: string;
	copiesCount: number;
	columnsStyle: FontFamily[];
	wordsPerPage: number;
}

export enum FontSizeType {
	Small = '30pt',
	Medium = '40pt',
	Large = '62pt',
}

export enum FontStyleType {
	Default = 'Trace with guidelines',
}

export interface FontType {
	fontSize: FontSizeType;
	fontStyle: FontStyleType;
}

export enum FileTypeType {
	Zip = 'zip',
	Pdf = 'pdf',
}

export interface DownloadPDFRequest {
	documentDate: string;
	lecturerName: string;
	copies: number;
	fontSize: number;
	students: DownloadPDFStudent[];
	tests: DownloadPDFTest[];
}

export interface DownloadPDFTest {
	testName: string;
	blankLine: string;
  columns: ColumnModel[];
	words: WordModel[];
}

export interface DownloadPDFStudent {
	fullName: string;
	unitType: UnitType;
	unitName: string;
}

export interface WordModel {
	word: string;
	testName?: string;
}

export interface ColumnModel {
	fontFamily: FontFamily;
}

export interface QuestionWithTest extends Question {
	testName: string;
}
