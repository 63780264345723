import {UserType} from '@esgi/core/authentication';
import {TeacherFormEvents} from 'shared/modules/forms/teacher-form/events';
import {sortBy} from 'underscore';
import {IClassModel, ITeacherModel} from '../core/api';
import {HierarchyState} from './hierarchy-data-service';
import {UserInfoChangedEvent} from '@esgi/main/features/user-profile';

export function applyTeacherChanged(args: TeacherFormEvents.Changed.Args, hierarchy: HierarchyState): HierarchyState {
	const {teachers} = hierarchy;
	teachers.items = teachers.items.map(x => x.userID === args.teacherID
		? {
			...x,
			firstName: args.firstName,
			lastName: args.lastName,
		} : x);
	return hierarchy;
}

export function applyTeacherCreated(args: TeacherFormEvents.Created.Args, hierarchy: HierarchyState): HierarchyState {
	const teacher: ITeacherModel = {
		userID: args.id,
    groups: args.groups,
		schoolID: args.schoolID,
		firstName: args.firstName,
		lastName: args.lastName,
		title: args.title,
		trackID: null,
	};

	const {teachers} = hierarchy;
	teachers.items = sortBy(teachers.items.concat([teacher]), (t) => t.lastName.toLowerCase());

	if (args.defaultClass) {
		const item: IClassModel = {
			classID: args.defaultClass.classID,
			name: args.defaultClass.name,
			userID: args.id,
		};

		const {classes} = hierarchy;
		classes.items = classes.items.concat([item]);
	}

	return hierarchy;
}

export function applyTeacherRemoved(args: TeacherFormEvents.Removed.Args, hierarchy: HierarchyState): HierarchyState {
	const {teachers, classic} = hierarchy;
	teachers.items = teachers.items.filter(s => s.userID !== args.teacherID);

	if (classic.teacherID === args.teacherID) {
		classic.studentID = 0;
	}

	return hierarchy;
}

export function userProfilesChanged(args: UserInfoChangedEvent, hierarchy: HierarchyState): HierarchyState {
	if (args.userType === UserType.C) {
		const {schAdmins} = hierarchy;

		schAdmins.items = schAdmins.items.map(x => x.userID === args.id
			? {
				...x,
				FirstName: args.firstName,
				LastName: args.lastName,
			} : x);
	}
	return hierarchy;
}
