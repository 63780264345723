import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import {getSummaryPieChartOptions} from 'modules/assessments';
import {useMemo} from 'react';
import {TestType} from '@esgi/core/enums';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {Summary} from '../../models';
import summaryStyles from './summary-styles.module.less';
import styles from './styles.module.less';

export type Props = {
	studentName: string;
	testName: string;
	testType: TestType;
	summary: Summary;
	onClose: () => void;
}

export function StudentSummary(props: Props) {
	if (!props.summary) {
		return;
	}

	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);

	const correctPercent = Math.round((props.summary.correct * 100) / props.summary.total);
	const incorrectPercent = 100 - correctPercent;

	const pieChart = useMemo(() => getSummaryPieChartOptions(props.testType, props.summary.testResultsCorrectVerbiage, correctPercent, props.summary.testResultsIncorrectVerbiage, incorrectPercent), []);

	const body = () => {
		if (props.summary.showTestSessionResults) {
			return <div>
				<div className={summaryStyles.topCloseContainer}>
					<svg onClick={close} width='14' height='14' viewBox='0 0 14 14' fill='none' data-cy='cross-icon'
					     xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
							fill='#828282'/>
					</svg>
				</div>
				<div className={summaryStyles.header}>Test Session Results</div>
				<div className={summaryStyles.sessionInfo}>
					<div className={summaryStyles.title}>Student:</div>
					<div className={summaryStyles.value} data-cy='student-name'>
						{props.studentName}
					</div>
				</div>
				<div className={summaryStyles.sessionInfo}>
					<div className={summaryStyles.title}>Test Name:</div>
					<div className={summaryStyles.value} data-cy='test-name'>
						{props.testName}
					</div>
				</div>
				<div className={summaryStyles.main}>
					<div className={summaryStyles.chart}>
						<HighchartsReact options={pieChart}/>
					</div>
					{props.testType === TestType.YN && <div className={summaryStyles.data}>
						<div data-cy='score-label'>
							<span>{props.summary.testResultsCorrectVerbiage}</span> answers: <span>{props.summary.correct}</span>/<span>{props.summary.total}</span>
						</div>
					</div>}
					{props.testType === TestType.Score && <div className={summaryStyles.data}>
						<div data-cy='score-label'>
							Score: <span>{props.summary.correct}</span>/<span>{props.summary.total}</span>
						</div>
					</div>}
				</div>
			</div>;
		} else {
			return <div className={summaryStyles.intro}>
				<div className={summaryStyles.successLabel}>Success! You finished!</div>
				<img className={summaryStyles.snake} src='//s3.amazonaws.com/esgiwebfiles/images/snake.png'/>
			</div>;
		}
	};

	return <Modal modalManagerRef={modal}>
		<div
			className={join(summaryStyles.summary, props.testType === TestType.Score && summaryStyles.score, !props.summary.showTestSessionResults && summaryStyles.simple)}>
			{body()}
			<div className={join(summaryStyles.buttons, styles.summaryButtonContainer)}>
				<Buttons.Contained onClick={close} data-cy='close'>Close</Buttons.Contained>
			</div>
		</div>
	</Modal>;
}
