import {environment, Environments} from '@esgi/core/environments';
import './jquery-extentions/bundle';
import './ace/bundle';
import './bootstrap/bundle';
import './bowser/bundle';
import './knockout/bundle';
import './styles/bundle';
import './store.min.js';

export * from './virtualized-react-18/index';

if (environment.environmentType === Environments.Prod) {
	require('./tracker/hotjar-tracker');
	require('./tracker/gtag');
}
