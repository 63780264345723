import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import {FormControl} from '@esgillc/ui-kit/form';
import {CopyTestButton, HideButton, StarButton, TestNameField} from '../../../../kits/test-details';
import RubricDetailsService from '../../rubric-details-service';
import styles from './header.module.less';

interface Props {
	readonly: boolean;
	nameControl: FormControl<string>;
	author: string;
	createDate: string;
	starred: boolean;
	hidden: boolean;
	canCopy: boolean;
	service: RubricDetailsService;
	onCopyClicked: () => void;
	onHideClicked: () => void;
	onStarClicked: () => void;
}

export default class Header extends React.PureComponent<Props> {
	public render() {
		return <div className={join(styles.header, this.props.readonly && styles.readonly)}>
			<TestNameField author={this.props.author}
			               lastEditDate={this.props.createDate}
			               readonly={this.props.readonly}
			               nameControl={this.props.nameControl}>
				<div className={styles.actions}>
					<div className={styles.actionsContent}>
						<StarButton isStarred={this.props.starred} onClick={this.props.onStarClicked}/>
						<HideButton isHidden={this.props.hidden} onClick={this.props.onHideClicked}/>
						{this.props.canCopy && <CopyTestButton onClick={this.props.onCopyClicked}
						                                       tooltipMessage='Save a copy of the test to customize your rubric.'/>}
					</div>
				</div>
			</TestNameField>
		</div>;
	}
}
