import {AnswerState} from '@esgi/core/enums';

export namespace YNEvents {
	export function ChangeActivePanel(activeState: AnswerState): ChangeActivePanel.Args {
		return {
			activePanel: activeState,
		};
	}

	export namespace ChangeActivePanel {
		export interface Args {
			activePanel: AnswerState;
		}
	}
}
