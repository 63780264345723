import {useEffect} from 'react';
import {Page} from './page';
import './styles.less';
import {RoutesMap} from '@esgi/main/libs/core';

export default function ImportRegistrationFinishedPage() {
	useEffect(() => {
		try {
			const page = new Page();
			return () => page.destroy();
		} catch (e) {
			console.error(e);
		}
	}, []);

	return <div>
		<div id='finish-registration-container'>
			<div>
				<div className='breadcrumb'>
					<ul>
						<li className='active disabled'>
							<a href='#'>Please fill this form to complete the registration</a>
						</li>
					</ul>
				</div>
				<form className='form-horizontal' data-bind='submit: view.submit'>
					<fieldset>
						<ko data-bind='with: form'>
							<div id='account-info-panel' data-bind='visible: true, afterRender: true'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='form-group' data-bind='afterRender: true, with: userTitle'>
											<label className='col-md-3 control-label' htmlFor='title'>Title</label>
											<div className='col-md-3'>
												<select id='title' name='title' className='form-control'
												        data-bind='value: value'>
													<option value='Mr.'>Mr.</option>
													<option value='Mrs.'>Mrs.</option>
													<option value='Ms.'>Ms.</option>
													<option value='Miss'>Miss</option>
													<option value='Dr.'>Dr.</option>
												</select>
											</div>
										</div>
										<ko data-bind='with: firstName'>
											<div className='form-group'
											     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
												<label className='col-md-3 control-label' htmlFor='first-name'>First
													Name</label>
												<div className='col-md-6'>
													<input id='first-name' name='first-name' type='text'
													       className='form-control input-md' data-bind='value: value'/>
													<ko data-bind='if: showValidation'>
														<ko data-bind='if: showSuccessValidation'>
															<i className='fa fa-check form-control-feedback'></i>
														</ko>
														<ko data-bind='if: showErrorValidation'>
															<i className='fa fa-close form-control-feedback'></i>
														</ko>
													</ko>
												</div>
											</div>
										</ko>
										<ko data-bind='with: lastName'>
											<div className='form-group'
											     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
												<label className='col-md-3 control-label' htmlFor='last-name'>Last
													Name</label>
												<div className='col-md-6'>
													<input id='last-name' name='last-name' type='text'
													       className='form-control input-md' data-bind='value: value'/>
													<ko data-bind='if: showValidation'>
														<ko data-bind='if: showSuccessValidation'>
															<i className='fa fa-check form-control-feedback'></i>
														</ko>
														<ko data-bind='if: showErrorValidation'>
															<i className='fa fa-close form-control-feedback'></i>
														</ko>
													</ko>
												</div>
											</div>
										</ko>
										<ko data-bind='with: country'>
											<div className='form-group' data-bind='afterRender: true'>
												<label className='col-md-3 control-label' htmlFor='country'>Country</label>
												<div className='col-md-6'>
													<input id='country' name='country' className='form-control'
													       data-bind='disable: disabled,value:value'/>
												</div>
											</div>
										</ko>
										<ko data-bind='with: state'>
											<div className='form-group'
											     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
												<label className='col-md-3 control-label'
												       htmlFor='state'>State/Region</label>
												<div className='col-md-6'>
													<input id='state' name='state' className='form-control'
													       data-bind='disable: disabled,value:value'/>
												</div>
											</div>
										</ko>
										<ko data-bind='with: email'>
											<div className='form-group'
											     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
												<label className='col-md-3 control-label' htmlFor='email'>Email</label>
												<div className='col-md-6'>
													<input id='email' name='email' type='text'
													       className='form-control input-md'
													       data-bind='value: value'/>
													<ko data-bind='if: showValidation'>
														<ko data-bind='if: showSuccessValidation'>
															<i className='fa fa-check form-control-feedback'></i>
														</ko>
														<ko data-bind='if: showErrorValidation'>
															<i className='fa fa-close form-control-feedback'></i>
														</ko>
													</ko>
												</div>
											</div>
										</ko>
										<ko data-bind='with: userName'>
											<div className='form-group'
											     data-bind="css: {'has-success' : showSuccessValidation() && valid(), 'has-feedback' : showValidation(), 'has-error' : showErrorValidation() && !valid()}, afterRender: true">
												<label className='col-md-3 control-label' htmlFor='username'>User
													Name</label>
												<div className='col-md-6'>
													<input id='username' name='username' type='text'
													       className='form-control input-md' data-bind='value: value'/>
													<ko data-bind='if: showValidation'>
														<ko data-bind='if: showSuccessValidation() && valid()'>
															<i className='fa fa-check form-control-feedback'></i>
														</ko>
														<ko data-bind='if: showErrorValidation() && !valid()'>
															<i className='fa fa-close form-control-feedback'></i>
														</ko>
														<ko data-bind='if: showValidationInProgress()'>
															<i className='fa form-control-feedback'>
																<img src='img/ajax-loader.gif'/>
															</i>
														</ko>
													</ko>
												</div>
											</div>
										</ko>
										<ko data-bind='with: password'>
											<div className='form-group'
											     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
												<label className='col-md-3 control-label'
												       htmlFor='password'>Password</label>
												<div className='col-md-6'>
													<input id='password' name='password' type='password'
													       className='form-control input-md' data-bind='value: value'/>
													<ko data-bind='if: showValidation'>
														<ko data-bind='if: showSuccessValidation'>
															<i className='fa fa-check form-control-feedback'></i>
														</ko>
														<ko data-bind='if: showErrorValidation'>
															<i className='fa fa-close form-control-feedback'></i>
														</ko>
													</ko>
												</div>
											</div>
										</ko>
										<ko data-bind='with: confirmPassword'>
											<div className='form-group'
											     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
												<label className='col-md-3 control-label' htmlFor='confirm-password'>Confirm
													Password</label>
												<div className='col-md-6'>
													<input id='confirm-password' name='confirm-password' type='password'
													       className='form-control input-md' data-bind='value: value'/>
													<ko data-bind='if: showValidation'>
														<ko data-bind='if: showSuccessValidation'>
															<i className='fa fa-check form-control-feedback'></i>
														</ko>
														<ko data-bind='if: showErrorValidation'>
															<i className='fa fa-close form-control-feedback'></i>
														</ko>
													</ko>
												</div>
											</div>
										</ko>
										<div className='form-group' data-bind='afterRender: true'>
											<label className='col-md-3 control-label' htmlFor='track'>Track</label>
											<div className='col-md-6'>
												<select id='track' name='track' className='form-control'
												        data-bind="options: tracks, optionsText: 'name', value: track.value, disable: track.disabled"></select>
											</div>
										</div>
										<ko data-bind='with: agreementAccepted'>
											<div className='agreement'
											     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
												<div>
													<label className='lbl-chk'>
														<input type='checkbox' name='agreement-accepted'
														       id='agreement-accepted-0'
														       value='' data-bind='checked: value'/>
														<span className='checkbox-label'>
																I accept the{' '}
																<a href={RoutesMap.esgiAgreement} target='_blank'>
																	Terms of Use
																</a>
															</span>
													</label>
												</div>
											</div>
										</ko>
									</div>
								</div>
							</div>
						</ko>
						<div className='form-group'>
							<div className='col-md-12'>
								<div className='pull-right'>
									<input type='button' style={{width: 100}} className='btn btn-primary'
									       data-bind='click: view.cancelButtonClicked' value='Cancel'/>
									<button style={{width: 100}} className='btn btn-primary'
									        data-bind='click: view.finishButtonClicked'>Finish
									</button>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		</div>
	</div>;
}
