import {noop} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {Folder, Link, Users} from '@esgi/ui';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {Checkbox, Select} from '@esgi/ui/controls';
import {Box, SelectableList} from '@esgi/ui/layout';
import React, {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {Drawer, useDrawerRef} from '@esgi/main/kits/common';

import {ClassModel, StudentModel} from '../../types';
import {
	AssignToGroupsBox,
	AvatarContainer,
	ListItem,
	ListLabelContainer,
	StudentsCountBox,
	StudentsIconContainer,
} from './styled';
import {StudentManagerService} from '../../service';

type Props = {
	students: StudentModel[],
	classes: ClassModel[],
	onClose: VoidFunction,
	service: StudentManagerService,
	snackbarRef: RefObject<SnackbarManager>,
}

export function MultipleAssignDrawer(props: Props) {
	const drawerRef = useDrawerRef();

	const [loading, setLoading] = useState(false);
	const [selectedClass, setSelectedClass] = useState<string>('');
	const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
	const [assignToGroup, setAssignToGroup] = useState<boolean>(false);

	const classes = props.classes;

	const selectedClassModel = useMemo(() => classes.find(c => c.classID.toString() === selectedClass), [classes, selectedClass]);

	//Remove previously selected groups when class deselected.
	useEffect(() => setSelectedGroups([]), [selectedClass]);

	const close = useCallback((force?: boolean) => {
		if (!loading || force) {
			drawerRef.current.close(props.onClose);
		}
	}, [loading, drawerRef, props.onClose]);

	const assign = useCallback(() => {
		setLoading(true);
		props.service.assign(props.students.map(s => s.studentID), [selectedClassModel.classID], selectedGroups.map((g) => Number.parseInt(g)))
			.subscribe({
				next: () => {
					const groupsNames = selectedClassModel.groups
						.filter(g => selectedGroups.includes(g.groupID.toString()))
						.map(g => g.name)
						.join(', ');

					if (selectedGroups.length <= 1) {
						props.snackbarRef.current.showSnackbar(<Text>Students have been assigned to {selectedClassModel.name}</Text>);

						if(selectedGroups.length) {
							props.snackbarRef.current.showSnackbar(<Text>Students have been assigned to {groupsNames}</Text>);
						}
					} else {
						props.snackbarRef.current.showSnackbar(<Text>Students have been assigned to {[selectedClassModel.name, groupsNames].join(', ')}</Text>);
					}

					drawerRef.current.close(props.onClose);
				},
				complete: () => setLoading(false),
			});
	}, [props.service, props.students, props.snackbarRef, drawerRef, selectedGroups, selectedClassModel, props.onClose]);

	return <Drawer drawerRef={drawerRef} onClickOutside={close}>
		<Drawer.Header Icon={Link}
		               actionButtonDisabled={loading || !selectedClassModel}
		               withActionButton
		               closeDrawer={close}
		               sectionName='Assign'
		               actionButtonText='Assign'
		               onActionButtonClick={assign}/>
		<Drawer.Body>
			<div>
				<AvatarContainer align='center' justify='start'>
					<StudentsIconContainer>
						<Users width={24} height={24}/>
					</StudentsIconContainer>
					<StudentsCountBox font='mono' size='small'>
						{props.students.length}
					</StudentsCountBox>
					<Text size='small'>
						Students
					</Text>
				</AvatarContainer>
				<AvatarContainer align='center' justify='start'>
					<Text size='medium' color='neutral40' data-cy='assign-info'>
						The students you’ve selected can be assigned to a class and/or group.
						<br/>
						Note that previous Classes/Groups for these students will remain intact and the new ones will be in addition to the existing ones.
					</Text>
				</AvatarContainer>
				<Box>
					<Select.Root value={selectedClass} onValueChange={setSelectedClass} disabled={loading} dataCy='class-select'>
						<Select.Field placeholder='Class'/>
						<Select.Content>
							{classes.map(c => <Select.Option key={c.classID} value={c.classID.toString()}>
								<Text bold size='medium'>{c.name}</Text>
							</Select.Option>)}
						</Select.Content>
					</Select.Root>
					<AssignToGroupsBox flow='column' dataCy='assign-to-group-checkbox'>
						<Checkbox checked={assignToGroup} disabled={loading} onCheckedChange={(state) => setAssignToGroup(Boolean(state))} label='Assign Selected Students to a Group too'/>
					</AssignToGroupsBox>
					{assignToGroup && <SelectableList>
						<SelectableList.GroupRoot type='multiple' value={selectedGroups}
						                          onValueChange={setSelectedGroups}>
							{selectedClassModel && selectedClassModel.groups.map(g => <ListItem
								disabled={loading}
								variant='outlined'
								IconBefore={Folder}
								withActiveBackground
								value={g.groupID.toString()} key={g.groupID}>
								{(selected) => <ListLabelContainer dataCy='list-label'>
									{g.name}
									<Checkbox css={{padding: 0}} checked={selected} onCheckedChange={noop} disabled={loading}/>
								</ListLabelContainer>}
							</ListItem>)}
						</SelectableList.GroupRoot>
					</SelectableList>}
				</Box>
			</div>
		</Drawer.Body>
	</Drawer>;
}