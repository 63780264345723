import React from 'react';
import {Radio} from '@esgillc/ui-kit/control';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	useState,
} from '@esgi/deprecated/react';
import {UnassignType} from '../../../../../../../models/models';
import BaseProps from './base-props';

class State {
	selectedType: UnassignType = UnassignType.Both;
}

@useState(State)
export default class MixedUnassignDialog extends React.PureComponent<BaseProps, State> {
	public render() {
		return <Modal className='student_operation_dialog remove_student_dialog'>
			<ModalHeader title='Unassign student(s) from'>
			</ModalHeader>
			<ModalBody>
				<div className='unassign-check-boxes'>
					<Radio
						checked={this.state.selectedType === UnassignType.PrimaryTeacher}
						value={UnassignType.PrimaryTeacher}
						onClick={() => this.setState({selectedType: UnassignType.PrimaryTeacher})}
						className='unassign-type-checkbox'
						id='unassign-type-primary-teacher'>Primary Teacher</Radio>

					<Radio
						checked={this.state.selectedType === UnassignType.Specialist}
						value={UnassignType.Specialist}
						onClick={() => this.setState({selectedType: UnassignType.Specialist})}
						className='unassign-type-checkbox'
						id='unassign-type-specialist'>Specialist</Radio>

					<Radio
						checked={this.state.selectedType === UnassignType.Both}
						value={UnassignType.Both}
						onClick={() => this.setState({selectedType: UnassignType.Both})}
						className='unassign-type-checkbox'
						id='unassign-type-both'
					>Both</Radio>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => this.props.closeClicked()}
						title='Cancel'
					/>
					<Primary
						onClick={() => this.props.processClicked(this.state.selectedType)}
						title='Confirm'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
