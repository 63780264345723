import {useService} from '@esgi/core/service';
import {Layer} from '@esgillc/ui-kit/core';
import {Loader} from '@esgillc/ui-kit/loader';
import {AnimationState, Fade} from '@esgillc/ui-kit/transition';
import {useEffect, useState} from 'react';
import {TestPreviewModel} from './models';
import {Preview} from './components/preview';
import {PreviewService} from './service';

type Props = {
	testID: number;
	onClose: () => void;
};

export function TestPreview(props: Props) {
	const [show, setShow] = useState(true);
	const service = useService(PreviewService);

	const onAnimationStateChanged = (ch: AnimationState) => {
		if (ch === 'exit') {
			props.onClose();
		}
	};

	const [testModel, setTestModel] = useState<TestPreviewModel>();
	useEffect(() => {
		const sub = service.init(props.testID).subscribe({
			next: (r) => {
				setTestModel(r);
			},
			error: () => props.onClose(),
		});
		return () => sub.unsubscribe();
	}, []);

	if (!testModel) {
		return <Loader show fullscreen/>;
	}

	return <Layer>
		<Fade in={show} animateOnMount duration='common' onStateChanged={onAnimationStateChanged}>
			<Preview testModel={testModel} onClose={() => setShow(false)}/>
		</Fade>
	</Layer>;
}
