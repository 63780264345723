import {FocusEvent, MouseEvent, ReactElement, forwardRef, useCallback} from 'react';
import {Input, InputProps} from '../input';
import {CSS} from '@stitches/react';
import {useAutoControlledState} from '@esgi/ui/utils';
import {IconWrapper} from './index.styled';

export type InputWithIconProps = InputProps & {
	/** Content. Can only be svg element. */
	children: ReactElement<React.SVGProps<SVGSVGElement>>;

	/** Returns a Style interface for icon wrapper "div" from a configuration, leveraging the given media and style map. */
	iconWrapperCss?: CSS;

	/** Callback function to handle icon click. */
	onIconClick?: VoidFunction;
};

export const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(
	(
		{
			dataCy = 'ui-kit-input-with-icon',
			css,
			children,
			iconWrapperCss = {},
			focused: externalFocused,
			hovered: externalHovered,
			onFocus,
			onMouseEnter,
			onMouseLeave,
			onBlur,
			type,
			error,
			disabled,
			onIconClick,
			...props
		},
		forwardedRef,
	) => {
		const [focused, setFocused] = useAutoControlledState({
			initialState: false,
			controlledState: externalFocused,
		});

		const [hovered, setHovered] = useAutoControlledState({
			initialState: false,
			controlledState: externalHovered,
		});

		const handleFocus = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(true);

				onFocus?.(event);
			},
			[onFocus, setFocused],
		);

		const handleBlur = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(false);

				onBlur?.(event);
			},
			[onBlur, setFocused],
		);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(true);

				onMouseEnter?.(event);
			},
			[onMouseEnter, setHovered],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(false);

				onMouseLeave?.(event);
			},
			[onMouseLeave, setHovered],
		);

		return (
			<Input
				dataCy={dataCy}
				css={{
					...css,
					'& input': {
						paddingRight: 32,
					},
				}}
				ref={forwardedRef}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				type={type}
				error={error}
				disabled={disabled}
				{...props}
			>
				<IconWrapper
					data-disabled={Boolean(disabled)}
					data-hovered={hovered}
					data-focused={focused}
					data-error={Boolean(error)}
					css={iconWrapperCss}
					onClick={onIconClick}
				>
					{children}
				</IconWrapper>
			</Input>
		);
	},
);
