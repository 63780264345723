import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	gridTemplateColumns: 'auto auto 1fr',
	alignItems: 'center',
	gap: '$3',
	gridAutoFlow: 'column',
	paddingTop: 4,
	paddingBottom: 4,
	paddingLeft: 12,
	paddingRight: 4,
});

export const Buttons = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	gap: '$2',
	justifyContent: 'end',
});
