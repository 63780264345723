import {QuestionModel} from 'modules/assessments';

export type JoinModel = {
	test: TestModel;
	summary: any;
}

export type TestModel = {
	questions: QuestionModel[];
	testName: string;
	enableShortcuts: boolean;
	hasTestIntroPage: boolean;
	currentQuestionIndex?: number;
	isWhiteBackground: boolean;
}

export type Summary = {
	total: number;
	correct: number;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	showTestSessionResults: boolean;
}

export class SessionCode {
	constructor(public code: string, public expiredTime: number) {
	}
}
