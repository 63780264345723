import React from 'react';

class Props {
	name: string;
	removeTag: () => void;
}

export default class SearchTag extends React.PureComponent<Props> {
	render() {
		return <div className='search-tag'>
			<span>{this.props.name}</span>
			<a href='#' onClick={() => this.removeTag()}>
				<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'>
					<path
						d='M9.08329 1.73916L8.26079 0.916656L4.99996 4.17749L1.73913 0.916656L0.916626 1.73916L4.17746 4.99999L0.916626 8.26082L1.73913 9.08332L4.99996 5.82249L8.26079 9.08332L9.08329 8.26082L5.82246 4.99999L9.08329 1.73916Z'
						fill='#BDBDBD'/>
				</svg>
			</a>
		</div>;
	}

	private removeTag() {
		this.props.removeTag();
	}
}
