import {useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {Buttons} from '@esgillc/ui-kit/button';
import {useModal, useCloseModal, Alert} from '@esgillc/ui-kit/modal';
import {ShowMoveUserLogoutAlertEvent, ShowMoveUserTransferredAlertEvent} from './events';
import {Countdown} from '../../../../../../libs/deprecated/src/utils';
import {forceUserLogout, logoutFromFrontend} from '../../utils';
import styles from './move-user-layouts.module.less';

export function UserMoveAlertLayer() {
	const [showLogoutAlert, setShowLogoutAlert] = useState(false);
	const [showTransferredAlert, setShowTransferredAlert] = useState(false);
	const modal = useModal();
	const close = useCloseModal(modal, () => {});

	useEventEffect(ShowMoveUserLogoutAlertEvent, () => setShowLogoutAlert(true));
	useEventEffect(ShowMoveUserTransferredAlertEvent, () => setShowTransferredAlert(true));

	if (showLogoutAlert) {
		return <Alert modalManagerRef={modal} containerClassName={styles.moveUserAlert}>
			<Alert.Body>
				Your account is being moved, which requires a logout. Please finish your task(s) now. You will be automatically logged out in 30 seconds.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={() => {
					close();
					Countdown.Clear();
					forceUserLogout();
				}}>
					Logout now
				</Buttons.Text>
				<Buttons.Text onClick={close}>
					Finish work
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}

	if (showTransferredAlert) {
		return <Alert modalManagerRef={modal} containerClassName={styles.moveUserAlert}>
			<Alert.Body>
				This account has been transferred. Updates will be made to the district, school, and/or student information. Please login again.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={() => {
					close();
					logoutFromFrontend();
				}}>
					OK
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}
	return <></>;
}
