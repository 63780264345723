import {AllTestButton} from '@esgi/main/kits/activities';
import {Text} from '@esgi/ui/typography';
import {EmptyBox} from './index.styled';

interface Props {
	onAllTestClick: VoidFunction;
}

export function EmptyList({onAllTestClick}: Props) {
	return (
		<>
			<EmptyBox dataCy='empty-box'>
				<Text data-cy='no-tests-to-display-alert' size='small' font='mono' color='neutral40'>No tests are available in the selected subject </Text>
			</EmptyBox>
			<AllTestButton onClick={onAllTestClick}/>
		</>
	);
}
