import {Test} from '../../../../types';
import {TestColorIndicator} from '@esgi/main/kits/common';
import {TestTypeLabel} from '@esgi/main/kits/reports';
import {TestNameStyled} from './index.styled';

interface Props {
	item: Test;
}

export function SelectableLisItemContent({item}: Props) {
	return (
		<>
			<TestColorIndicator testID={item.id} css={{height: 32}} />
			<TestNameStyled data-cy='test-name' size='large' color='currentColor'>
				{item.name}
			</TestNameStyled>
			<TestTypeLabel testType={item.type} css={{justifySelf: 'start'}} />
		</>
	);
}
