import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, debounceTime, tap} from 'rxjs';
import {SubjectService} from './subjects';
import {V2PagesReportsController, V2TeachersPagesReportsTotalsController} from '@esgi/contracts/esgi';
import {
	CarryScoresForwardRequest,
	Cell,
	CloseRequest,
	DisplayResults,
	DownloadRequest,
	GetReportRequest,
	MarkingPeriod,
	NotTested,
	RebuildRequest,
	Report,
	Test,
	TotalReportType,
} from './types';
import {
	InModel as MarkingPeriodRequest,
} from '@esgi/contracts/esgi/types/esgi.accounts/commands/esgi.apigateway/user-settings/marking-period-all/in-model';
import {getUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';

export class ClassTotalsService extends BaseService {

	public readonly tests$ = new BehaviorSubject<Test['id'][]>([]);
	public readonly displayResults$ = new BehaviorSubject<DisplayResults | null>(DisplayResults.Score);
	public readonly carryForward$ = new BehaviorSubject<boolean>(false);
	public readonly markingPeriod$ = new BehaviorSubject<MarkingPeriod | null>(MarkingPeriod.All);
	public readonly notTested$ = new BehaviorSubject<NotTested | null>(NotTested.NT);
	public readonly subject$ = new BehaviorSubject(0);
	public readonly reportId$ = new BehaviorSubject(0);
	public readonly reportType$ = new BehaviorSubject<TotalReportType>(TotalReportType.Class);
	public readonly reportName = new BehaviorSubject<string>('Class');
	public readonly testResultsCorrectVerbiage$ = new BehaviorSubject('');
	public result$ = new BehaviorSubject<Report | null>(null);
	public readonly busy$ = new BehaviorSubject<boolean>(false);
	public readonly sortedRowIDs = new BehaviorSubject<number[] | null>(null);

	public subjectService = new SubjectService();

	protected currentUser = getUser();
	private readonly controller = new V2TeachersPagesReportsTotalsController();
	private readonly pagesReportsController = new V2PagesReportsController();

	constructor() {
		super();

		this.completeOnDestroy(combineLatest([this.subject$])).pipe(debounceTime(100)).subscribe();

		this.completeOnDestroy(this.subjectService.loaded$)
			.pipe(tap((value) => {
				if (value) {
					this.subject$.next(this.subjectService.getByIndex(0).id);
				}
			})).subscribe();

		this.completeOnDestroy(this.notTested$)
			.subscribe((value) => {
				this.pagesReportsController.userSettingsUpdate({
					name: 'displayZeroIfNotTested',
					value: value === NotTested.Zero,
				}).subscribe();
			});

		this.completeOnDestroy(this.markingPeriod$)
			.subscribe((value) => {
				const request: MarkingPeriodRequest = {
					value: value === MarkingPeriod.All,
				};

				this.controller.markingPeriodAll(request).subscribe();
			});

		this.completeOnDestroy(this.carryForward$)
			.subscribe((value) => {
				const subject = this.subjectService.get(this.subject$.value);
				const request: CarryScoresForwardRequest = {
					sourceID: null,
					subjectID: subject.id,
					subjectType: subject.type,
					tests: this.tests$.value.map((key) => key),
					reportID: this.reportId$.value,
					reportType: this.reportType$.value,
					gradeLevelID: null,
					value: value,
				};

				const reportPage = this.result$.getValue() !== null;
				if (reportPage) {
					request.sourceID = this.result$.value.sourceID;
					this.result$.next(null);
				}
				this.controller.carryScoresForward(request).subscribe({
					next: (response) => {
						if (reportPage) {
							response.value.report.table = this.calculateAverage(response.value.report as Report);
							this.result$.next(response.value.report as Report);
						}
					},
				});
			});

		this.reportType$.subscribe(
			(value) => {
				switch (value) {
					case TotalReportType.Class:
						this.reportName.next('Class');
						return;
					case TotalReportType.Group:
						this.reportName.next('Group');
						return;
				}
			}
		);

		this.controller.init().subscribe({
			next: (response) => {
				this.setCarryForward(response.value.carryScoresForward);
				this.setNotTested(response.value.displayZero ? NotTested.Zero : NotTested.NT);
				this.setMarkingPeriod(response.value.markingPeriodAll ? MarkingPeriod.All : MarkingPeriod.Current);
			},
		});
	}

	public setSubject(value: number) {
		this.subject$.next(value);
	}

	public setTests(value: Test['id'][]) {
		this.tests$.next(value);
	}

	public setDisplayResults(value: DisplayResults) {
		this.displayResults$.next(value);
	}

	public setCarryForward(value: boolean){
		this.carryForward$.next(value);
	}

	public setMarkingPeriod(value: MarkingPeriod) {
		this.markingPeriod$.next(value);
	}

	public setNotTested(value: NotTested) {
		this.notTested$.next(value);
	}

	public setReportId(value: number){
		this.reportId$.next(value);
	}

	public setReportType(value: TotalReportType){
		this.reportType$.next(value);
	}

	public setSortedRowIDs(value: number[]){
		this.sortedRowIDs.next(value);
	}

	public getReport(){
		const subject = this.subjectService.get(this.subject$.value);
		const request: GetReportRequest = {
			subjectID: subject.id,
			subjectType: subject.type,
			tests: this.tests$.value.map((key) => key),
			reportID: this.reportId$.value,
			reportType: this.reportType$.value,
		};
		this.controller.getReport(request)
			.subscribe({
				next: (response) => {
					response.value.report.table = this.calculateAverage(response.value.report as Report);

					this.testResultsCorrectVerbiage$.next(response.value.testResultsCorrectVerbiage);
					this.result$.next(response.value.report as Report);
				},
			});
	}

	public subjectChanged(value: number){
		this.subject$.next(value);
		const subject = this.subjectService.get(this.subject$.value);

		this.tests$.next(subject.tests.map(t => t.id));

		const request: RebuildRequest = {
			sourceID: this.result$.value.sourceID,
			subjectID: subject.id,
			subjectType: subject.type,
			reportID: this.reportId$.value,
			reportType: this.reportType$.value,
			gradeLevelID: null,
			value: false,
			tests: this.tests$.value,
		};

		this.result$.next(null);
		this.controller.subjectChanged(request)
			.subscribe({
				next: (response) => {
					response.value.report.table = this.calculateAverage(response.value.report as Report);

					this.result$.next(response.value.report as Report);
				},
			});
	}

	public downloadPdf() {
		this.httpClient.ESGIApi.file(
			'/v2/teachers/pages/reports/totals',
			'download-pdf',
			this.getFileName('pdf'),
			this.getDownloadRequest())
			.subscribe({
				complete: () => {
					this.busy$.next(false);
				},
			});
	}

	public downloadExcel() {
		this.httpClient.ESGIApi.file(
			'/v2/teachers/pages/reports/totals',
			'download-excel',
			this.getFileName('xlsx'),
			this.getDownloadRequest())
			.subscribe({
				complete: () => {
					this.busy$.next(false);
				},
			});
	}

	public closeReport(){
		const request: CloseRequest = {
			type: this.reportType$.value,
			reportGuids: [this.result$.value.sourceID],
		};

		this.controller.closeReport(request)
			.subscribe(() => {
				this.result$.next(null);
			});
	}

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}

	private calculateAverage(reportResult: Report){
		const table = reportResult.table;

		if(isNull(table.rows)){
			return table;
		}

		for (const row of table.rows) {
			for (let j = reportResult.valueToSkip; j < row.cells.length; j++) {
				const cell = row.cells[j] as Cell;
				const testIndex = Math.floor((j - reportResult.valueToSkip) / (reportResult.maxMarkingPeriods + 1));
				cell.percent = (cell.value !== 'NT' && reportResult.tests[testIndex].questionsCount) ? Math.floor((parseInt(cell.value) * 100) / reportResult.tests[testIndex].questionsCount).toString() : cell.value;
				cell.zeroIsMinimumLevel = cell.value !== 'NT' ? reportResult.tests[testIndex].zeroIsMinimumLevel : false;
				let avgPercent = null;
				if (reportResult.tests[testIndex].id === 0) {
					const colIndex = j - reportResult.valueToSkip - testIndex * (reportResult.maxMarkingPeriods + 1);
					let percentsSum = 0;
					const testsCount = reportResult.tests.length - 1;
					for (let k = 0; k < testsCount; k++) {
						const c = colIndex + reportResult.valueToSkip + k * (reportResult.maxMarkingPeriods + 1);
						const percent = (row.cells[c] as Cell).percent;
						const value = row.cells[c].value;
						if (percent !== 'NT' && value !== '---' && value !== '*') {
							percentsSum += parseInt(percent);
						}
					}
					avgPercent = Math.floor(percentsSum / testsCount);
				}
				cell.avgPercent = avgPercent;
			}
		}

		return table;
	}

	private getFileName(extension: string){
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const type = TotalReportType[this.reportType$.value];
		return `${type}_Total_Report_${year}-${month}-${day}.${extension}`;
	}

	private getDownloadRequest(): DownloadRequest {
		const subject = this.subjectService.get(this.subject$.value);

		return {
			sourceID: this.result$.value.sourceID,
			subjectID: subject.id,
			subjectType: subject.type,
			tests: this.tests$.value.map((key) => key),
			reportID: this.reportId$.value,
			reportType: this.reportType$.value,
			gradeLevelID: null,
			displayZero: this.notTested$.value === NotTested.Zero,
			displayPercents: this.displayResults$.value === DisplayResults.Percent,
			currentScoreOnly: this.markingPeriod$.value === MarkingPeriod.Current,
			rows: this.sortedRowIDs.value,
		};
	}
}
