import {AppEvent} from '@esgillc/events';
import {AdditionalResponse, CredentialModel} from 'modules/forms/students-form/types';

@AppEvent({broadcast: true})
export class StudentChangedEventTest {
	firstName: string;
	lastName: string;
	gender: string;
	studentIDN: string;
	exportIDN: string;
	languageID: number;
	gradeLevelID: number;
	assignmentCode: string;
}

@AppEvent({broadcast: true})
export class StudentRemovedEvent {
	id: number;
	detachedGroups?: { id: number, userID: number }[];
}

export class StudentGeneralInfoModel {
	constructor(
		public firstName: string,
		public lastName: string,
		public exportIDN: string,
		public gender: string,
		public languageID: number,
		public gradeLevelID: number,
		public globalSchoolYearID: number,
		public districtID: number,
		public schoolID: number,
		public userType: string,
		public creatorID: number,
		public teacherID: number,
		public studentID?: number,
		public studentIDN?: string,
		public credentialModel?: CredentialModel,
		public createAnyway?: boolean,
	) {
	}
}

@AppEvent({broadcast: true})
export class LocationChangedEvent {
	constructor(
		public groupIDs: number[],
		public classIDs: number[],
		public specialistGroupIDs?: number[],
		public primaryTeacherID?: number,
		public schoolID?: number,
	) {
	}
}

@AppEvent({broadcast: true})
export class AdditionalInfoChangedEvent {
	constructor(
		public studentID: number,
		public birthDate: string,
		public race: string,
		public lunchStatus: string,
		public programs: string[],
		public comments: string,
	) {
	}
}

export class StudentSavedEvent {
}

@AppEvent({broadcast: true})
export class StudentCreatedEvent {
	constructor(
		public studentID: number,
		public exportIDN: string,
		public firstName: string,
		public lastName: string,
		public gender: string,
		public gradeLevelID: number,
		public languageID: number,
		public studentIDN: string,
		public districtID: number,
		public schoolID: number,
		public teacherID: number,
		public classes?: number[],
		public groups?: number[],
		public specialistGroups?: number[],
		public credentialModel?: CredentialModel,
	) {
	}
}

@AppEvent({broadcast: true})
export class StudentChangedEvent {
	constructor(
		public studentID: number,
		public firstName: string,
		public lastName: string,
		public gender: string,
		public gradeLevelID: number,
		public languageID: number,
		public studentIDN: string,
		public schoolID: number,
		public teacherID: number,
		public classes: number[],
		public groups: number[],
		public specialistGroups: { id: number, userID: number }[],
		public additional?: AdditionalResponse,
		public credentialModel?: CredentialModel,
	) {
	}
}

@AppEvent({broadcast: true})
export class StudentSaveAndAddEvent {
	constructor(
		public isSaveAndAddOther?: boolean,
		public studentLimitExceeded?: boolean,
	) {
	}
}

export class StudentViewEvent extends StudentCreatedEvent {
}
