import React, {createRef} from 'react';
import {CustomDropdown, TextInput, Validators} from '@esgi/deprecated/elements';
import {InModel} from '@esgi/deprecated/elements/checkbox-list';
import {dispatchAppEvent, EventBusDispatcher} from '@esgillc/events';
import {GroupForm} from '../../components/group-form';
import styles from 'modules/forms/admin-group-forms/styles.module.less';
import {CloseIcon, Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {Buttons} from '@esgillc/ui-kit/button';
import {Loader} from '@esgillc/ui-kit/loader';
import {PreModel} from 'modules/forms/admin-group-forms/models/types';
import {UsersGroupService} from 'modules/forms/admin-group-forms/services/users-group-service';
import {
	GroupOfSpecialistsChangedEvent,
	GroupOfSpecialistsCreatedEvent,
	GroupOfSpecialistsRemovedEvent,
} from 'modules/forms/admin-group-forms/events';
import {SsoTracker} from '@esgi/core/tracker';
import {userStorage, UserType} from '@esgi/core/authentication';
import {RemoveAlert} from 'modules/forms/admin-group-forms/containers/specialists-group/delete-alert';
import {ValidateAlert} from 'modules/forms/admin-group-forms/validate-alert';

export class Props {
	preModel: PreModel;
	onClose: () => void;
}

export class State {
	groupName: string = '';
	groupNameValid: boolean;
	availableStudents: InModel[] = [];
	touched: boolean = false;
	saving: boolean;
	loaded: boolean;
	selectedSchoolID = 0;
	selectedSpecialistType = null;
	showRemoveAlert: boolean;
	validationMessage: string;
}

export class SpecialistsGroupContainer extends React.Component<Props, State> {
	private service = new UsersGroupService();
	private modalManagerRef: ModalManagerRefObject = createRef();
	private user = userStorage.get();
	private specialistTypes = [
		{title: 'All', key: null, value: null},
		{title: 'ISD', key: 'ISD', value: 'ISD'},
		{title: 'ISS', key: 'ISS', value: 'ISS'},
	];
	labelRef: Node = null;

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	state: State;

	componentDidMount() {
		if (this.props.preModel.groupName) {
			this.updateGroupName(this.props.preModel.groupName, true);
		} else {
			this.updateGroupName('', false);
		}
	}

	componentWillUnmount() {
		$(this.labelRef).bstooltip('dispose');
	}

	showTooltip() {
		$(this.labelRef).bstooltip('destroy');
		setTimeout(() => {
			if ($(this.labelRef).width() >= 225) {
				$(this.labelRef).bstooltip({
					placement: 'bottom',
					title: this.state.groupName,
				});
			}
		}, 10);
	}

	updateGroupName(value, valid) {
		this.props.preModel.groupName = value;
		this.setState({groupName: value, groupNameValid: valid});
		this.showTooltip();
	}

	private closeModal = () => {
		this.modalManagerRef.current?.close(() => {
			this.props.onClose();
			this.setState({saving: false});
		});
	};

	save() {
		const valid = this.validate();
		if (!valid){
			return;
		}
		this.setState({saving: true});
		if (this.props.preModel.groupID) {
			this.service.update(this.props.preModel).subscribe(() => {
				let args = new GroupOfSpecialistsChangedEvent();
				args.groupId = this.props.preModel.groupID;
				args.name = this.state.groupName;
				args.specialists = this.service.itemsInGroup$.value.map(x => x.id);
				dispatchAppEvent(GroupOfSpecialistsChangedEvent, args);
				this.showTooltip();
				this.closeModal();
			}, () => this.setState({saving: false}));
		} else {
			this.service.create(this.props.preModel).subscribe((response) => {
				let args = new GroupOfSpecialistsCreatedEvent();
				args.groupId = response.groupID;
				args.name = this.state.groupName;
				args.specialists = this.service.itemsInGroup$.value.map(x => x.id);
				EventBusDispatcher.dispatch(GroupOfSpecialistsCreatedEvent, args);
				this.closeModal();
			}, () => this.setState({saving: true}));
		}
	}

	onSchoolSelected(id: number) {
		this.setState({selectedSchoolID: id});
		this.service.updateList(id, 0, this.state.selectedSpecialistType);
	}

	onTypeSelected(type: string) {
		this.setState({selectedSpecialistType: type});
		this.service.updateList(this.state.selectedSchoolID, 0, type);
	}
	remove() {
		this.setState({saving: true});
		this.service.remove(this.props.preModel).subscribe(() => {
			SsoTracker.trackEvent({
				trackingEvent: 'GroupDeleted',
				data: {groupId: this.props.preModel.groupID},
			});

			let args = new GroupOfSpecialistsRemovedEvent();
			args.id = this.props.preModel.groupID;
			EventBusDispatcher.dispatch(GroupOfSpecialistsRemovedEvent, args);
			this.closeModal();
		}, () => this.setState({saving: false}));
	}

	renderRemoveAlert() {
		if (this.state.showRemoveAlert) {
			return <RemoveAlert
				onCancel={() => this.setState({showRemoveAlert: false})}
				onConfirm={() => this.remove()}
			/>;
		}
	}

	validate(){
		if (!this.state.groupName){
			this.setState({validationMessage: 'Group name is required'});
			return false;
		}

		if (this.service.itemsInGroup$.value.length === 0) {
			this.setState({validationMessage: 'Please select specialists'})
			return false;
		}

		return true;
	}

	renderValidateAlert() {
		if (this.state.validationMessage) {
			return <ValidateAlert
				onOk={() => this.setState({validationMessage: null})}
				message={this.state.validationMessage}
			/>;
		}
	}

	render() {
		return <Modal className={styles.modal}
									onCatchError={this.props.onClose}
									modalManagerRef={this.modalManagerRef}>
			<Loader show={this.state.saving} fullscreen/>
			<Modal.Header className={styles.header}>
				<FlexBox>
					<Title className={styles.title}>
						{!this.props.preModel.groupID ? 'Add Specialist Group' : 'Edit Specialist Group'}
						{this.props.preModel.groupID && <Buttons.Text
							className={styles.headerLink}
							disabled={this.state.saving}
							onClick={() => this.setState({showRemoveAlert: true})}>
							{'Remove'}
						</Buttons.Text>}
					</Title>
				</FlexBox>
				<CloseIcon onClick={() => this.modalManagerRef.current.close(this.props.onClose)}/>
			</Modal.Header>
			<Modal.Body>
				<div className={styles.container}>
					<div className={styles.name}>
						{
							this.user.userType === UserType.D ?
								<label className={styles.captionsInline}>District Name</label> :
								<label className={styles.captionsInline}>School Name</label>
						}
						<label className={styles.captionsInlineValue}>{this.service.name}</label>
					</div>
					<div className={styles.flex}>
						<div className={styles.firstInput}>
							<label
								className={styles.captionsInline}>{this.props.preModel.groupID ? 'Edit Specialist Group Name' : 'Specialist Group Name'}</label>
							<TextInput
								value={this.state.groupName}
								touched={this.state.touched}
								onEdit={(value, validation) => this.updateGroupName(value, validation.valid)}
								validator={(value: string) => Validators.requiredValidator(value)}
								placeholder={'i.e. ELL Learners'}
							/>
						</div>
						{this.user.userType === UserType.D && !this.props.preModel.groupID && <div className={styles.secondInput}>
							<label className={styles.captionsInline}>{'School Name'}</label>
							<CustomDropdown
								value={this.state.selectedSchoolID}
								onSelect={item => this.onSchoolSelected(item.key)}
								items={this.service.schools}/>
						</div>}
						{this.user.userType === UserType.D && !this.props.preModel.groupID && <div>
							<label className={styles.captionsInline}>{'Specialist Type'}</label>
							<CustomDropdown
								value={this.state.selectedSpecialistType}
								onSelect={item => this.onTypeSelected(item.key)}
								items={this.specialistTypes}/>
						</div>}
					</div>
					<GroupForm preModel={this.props.preModel}
										 service={this.service}
										 title={'Available Specialists'}
										 newGroupLabel={'New Specialist Group'}
										 loaded={() => this.setState({loaded: true})}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={() => this.modalManagerRef.current.close(this.props.onClose)}>Cancel</Buttons.Gray>
				<div>
					<Buttons.Contained className={styles.okButton} disabled={this.state.saving} onClick={() => this.save()}>
						Save
					</Buttons.Contained>
				</div>
			</Modal.Footer>
			<Loader show={!this.state.loaded} fullscreen/>
			{this.renderRemoveAlert()}
			{this.renderValidateAlert()}
		</Modal>;
	}
}
