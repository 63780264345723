import './styles.less';
import React from 'react';

class Props {
	scope: string;
	keyword: string;
}

export class SearchDescription extends React.PureComponent<Props> {
	render() {
		return <div className='search-description'>Results for: {this.scopeAsText()}{this.formatKeyword()}</div>;
	}


	private formatKeyword() {
		if (!this.props.keyword) {
			return null;
		}

		return <> / <b>{this.props.keyword}</b></>;
	}

	private scopeAsText() {
		switch (this.props.scope) {
			case 'AllTests':
				return 'All Tests';

			case 'MyTests':
				return 'My Tests';

			case 'DistrictTests':
				return 'District Tests';

			case 'TestsGallery':
				return 'Test Gallery';

			case 'DraftTests':
				return 'My Drafts';

			case 'SelfAssessTests':
				return 'Self-Assess';
		}

		return 'N/A Scope';

	}
}