import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const RingsContainer = styled(Box, {
	position: 'relative',
	width: 80,
	height: 80,
});

export const StudentPerformanceRingContainer = styled(Box, {
	position: 'absolute',
	width: 80,
	height: 80,
	top: 0,
	left: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const ClassPerformanceRingContainer = styled(Box, {
	position: 'absolute',
	top: 0,
	left: 0,
});