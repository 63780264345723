import {
	TeachersGroupChangedEvent,
	TeachersGroupCreatedEvent,
	TeachersGroupRemovedEvent,
} from 'modules/forms/admin-group-forms/events';
import {GroupModelForAdmin} from '../core/api';
import {HierarchyState} from './hierarchy-data-service';


export function applyTeachersGroupChanged(args: TeachersGroupChangedEvent, hierarchy: HierarchyState): HierarchyState {
	const {teachers, teachersGroups} = hierarchy;

	const teachersInGroup = teachers.items.filter(x => x.groups.some(c => c === args.groupId));
	const forRemove = teachersInGroup.filter(x => args.teachers.every(c => c !== x.userID));
	const forAddIds = args.teachers.filter(x => teachersInGroup.every(c => c.userID !== x));

	teachers.items = teachers.items.map(x => {
		if (forRemove.some(c => c.userID === x.userID)) {
			const newTeacher = {...x};
			newTeacher.groups = x.groups.filter(x => x !== args.groupId);
			return newTeacher;
		}
		if (forAddIds.some(c => c === x.userID)) {
			const newTeacher = {...x};
			newTeacher.groups.push(args.groupId);
			return newTeacher;
		}
		return x;
	});

	const item = teachersGroups.items.filter(s => s.groupID === args.groupId)[0];
	const newItem: GroupModelForAdmin = {...item, name: args.name};

	teachersGroups.items = teachersGroups.items.map(s => s.groupID === newItem.groupID ? newItem : s);

	return hierarchy;
}

export function applyTeachersGroupCreated(args: TeachersGroupCreatedEvent, hierarchy: HierarchyState): HierarchyState {
	const {teachers, teachersGroups} = hierarchy;
	teachers.items = teachers.items.map(x => {
		if (args.teachers.some(c => c === x.userID)) {
			const newState = {...x};
			newState.groups.push(args.groupId);
			return newState;
		}
		return x;
	});

	const group: GroupModelForAdmin = {
		groupID: args.groupId,
		userID: args.userID,
		name: args.name,
		orderNum: 0,
	};

	teachersGroups.items = teachersGroups.items.concat(group);

	return hierarchy;
}

export function applyTeachersGroupRemoved(args: TeachersGroupRemovedEvent, hierarchy: HierarchyState): HierarchyState {
	const {teachersGroups, classic} = hierarchy;
	teachersGroups.items = teachersGroups.items.filter(s => s.groupID !== args.id);

	if (classic.teachersGroupID === args.id) {
		classic.teachersGroupID = 0;
	}
	return hierarchy;
}
