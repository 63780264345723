import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Label, ButtonLabel} from '../../index.styled';
import {ComponentProps} from 'react';

interface Props {
	onSave: VoidFunction;
	onCancel: VoidFunction;
	portalProps?: ComponentProps<typeof Alert>['portalProps'];
}

export function SaveWithoutClassesAlert({onSave, onCancel, portalProps}: Props) {
	const alertRef = Alert.useRef();

	return (
		<Alert
			portalProps={portalProps}
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.negative}
		>
			<Alert.Header withBacklight onCloseIconClick={onCancel}>
				<Text>Alert: Class Not Assigned</Text>
			</Alert.Header>
			<Alert.Body>
				<Label size='medium'>
					No class has been selected for this student. Do you want to proceed
					without assigning a class?
				</Label>
			</Alert.Body>
			<Alert.Footer align='center' gapX={3}>
				<Button color='tertiary' onClick={onCancel}>
					<ButtonLabel>Cancel</ButtonLabel>
				</Button>
				<Button
					onClick={onSave}
					color='secondary'
				>
					<ButtonLabel>Save</ButtonLabel>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
