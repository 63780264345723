export enum LevelDisplayMode {
	Numerical = 1,
	Text = 2,
}

export interface InitModel {
	answers: RubricAnswer[];
	studentName: string;
	rubric: RubricModel;
	session: string;
	cacheAvailable: boolean;
	sessionStatus: SessionStatus;
	summaryNotes: string;
}

export enum SessionStatus {
	None = -1,
	Normal = 0,
	Started = 1,
	Saved = 2,
	UserMoved = 3,
}

export interface ResultModel {
  testSessionID: number;
  summaryNotes: string;
	studentID: number;
	studentName: string;
	testModel: RubricModel;
	answers: RubricAnswer[];
}

export interface RubricModel {
	id?: number;
	rubricName: string;
	description: string;
	levelDisplayType: number;

	levelModels: Level[];
	criteriaModels: Criteria[];
	descriptionModels: Description[];
}

export interface Level {
	id: number;
	name: string;
	score: number;
}

export interface Criteria {
	id: number;
	name: string;
	order: number;
}

export interface Description {
	id: number;
	criteriaID: number;
	levelID: number;
	description: string;
	details: string;
}

export interface RubricAnswer {
	criteriaID: number;
	score?: number;
	notes: string;
}
