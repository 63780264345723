import {AsyncStatus} from '@esgi/ui';
import {IndicatorType} from '@esgi/ui/controls';

export function getInputIndicatorByAsyncStatus({asyncStatus, hasError}: {asyncStatus: AsyncStatus; hasError: boolean}) {
	const statuses: Record<AsyncStatus, IndicatorType> = {
		[AsyncStatus.Idle]: IndicatorType.None,
		[AsyncStatus.Pending]: IndicatorType.Pending,
		[AsyncStatus.Succeeded]: IndicatorType.Succeeded,
		[AsyncStatus.Failed]: IndicatorType.Failed,
	};

	if (hasError) {
		return IndicatorType.Failed;
	}

	return statuses[asyncStatus];
}
