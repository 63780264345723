import {Text} from '@esgi/ui/typography';
import {CheapBox, HideIconBox, SubjectGridBox, SubjectText} from './index.styled';
import {useUser} from '@esgi/core/authentication';
import {SubjectLevel} from '@esgi/main/libs/store';
import {cheapContent} from './constants';
import {Hide} from '@esgi/ui/icons';

type Props = {
	subjectLevel?: SubjectLevel;
	subjectName: string;
	hidden: boolean;
};

export function SubjectName({subjectLevel, subjectName, hidden}: Props) {
	const user = useUser();

	const isUserNotLinked = user?.agreementLevelCode === 'T';

	const subjectsCheapInfo = subjectLevel ? cheapContent[subjectLevel] : null;

	const withCheapBox = !!(subjectsCheapInfo && !isUserNotLinked);

	const dataCy = 'store-subject-name';

	return (
		<SubjectGridBox withCheapBox={withCheapBox} withHideIcon={hidden} dataCy={dataCy}>
			{withCheapBox && (
				<CheapBox color={subjectsCheapInfo.cheapColor} dataCy={`${dataCy}-cheap`}>
					<Text size='medium' bold data-cy={`${dataCy}-cheap-title`}>
						{subjectsCheapInfo.cheapTitle}
					</Text>
				</CheapBox>
			)}

			{hidden && (
				<HideIconBox>
					<Hide width={20} height={20}/>
				</HideIconBox>
			)}

			<SubjectText data-cy={`${dataCy}-title`} size='medium' bold color={hidden ? 'muted' : 'base'}>
				{subjectName}
			</SubjectText>
		</SubjectGridBox>
	);
}
