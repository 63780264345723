import {GridBox} from '@esgi/ui/layout';

import {Delta, NumberRow} from '../../kit';
import {QuestionsService} from '../service';
import {useClassData} from '../../hooks';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	service: QuestionsService,
};

export function ClassView(props: Props) {
	const {loaded, data} = useClassData(props.service, props.testIDs, props.studentIDs);

	const {lastWeek, thisWeek} = data;

	return <GridBox css={{height: 80}} gapY='3' flow='row'>
		<NumberRow text={thisWeek} label='This Week' skeleton={!loaded}>
			<Delta skeleton={!loaded} value={thisWeek - lastWeek} unit=''>
				Since Last Week
			</Delta>
		</NumberRow>
		<NumberRow text={lastWeek} label='Last Week' skeleton={!loaded}/>
	</GridBox>;
}