import {useSearchParams} from 'react-router-dom';
import {DrawerMode, DrawerSearchKey} from '../types';
import {isUndefined} from 'underscore';

export function useSwitchDrawerMode({drawerName}: {drawerName: string}) {
	const [searchParams, updateSearchParams] = useSearchParams();

	return <T extends Record<string, string | undefined>>({mode, search}: {mode: DrawerMode; search?: T}) => {
		searchParams.set(DrawerSearchKey.DrawerMode, mode);
		searchParams.set(DrawerSearchKey.DrawerName, drawerName);

		if (search) {
			Object.entries(search).forEach(([key, value]) => {
				if (!isUndefined(value)) {
					searchParams.set(key, value);
				}
			});
		}

		updateSearchParams(searchParams);
	};
}
