import {RubricModel} from '../common/types';
import {SubjectType} from '@esgi/core/enums';

export interface TestInfo extends RubricModel {
	rubricID: number;
	studentFirstName: string;
	studentLastName: string;
	canEdit: boolean;
	canRemove: boolean;
	nowProfileTZ: string;
	iepGoal?: IEPGoal;
	iepStatuses?: IEPStatus[];
}

export interface InitResponse extends TestInfo {
	sessions: SessionModel[];
}


export enum LevelDisplayMode {
	Numerical = 1,
	Text = 2,
}

export interface SessionModel {
	userID: number;
	userName: string;
	isDeleted: boolean;
	sessionID: number;
	duration: string;
	testDate: string;
	summaryNotes: string;
}

export interface IEPGoal {
	benchmark: string;
	goal: string;
	id: number;
	isCompleted: boolean;
	statusID: number;
}

export interface IEPStatus {
	id: number;
	name: string;
	isDeleted: boolean;
}

export interface Subject {
	id: number;
	name?: string;
	type: SubjectType;
}