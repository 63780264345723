import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const StudentsIconContainer = styled(FlexBox, {
	borderRadius: '12px',
	border: '1px solid $border',
	background: '$vivid',
	padding: 8,
});

export const StudentsCountBox = styled(Text, {
	padding: '2px 4px',
	border: '1px solid $secondaryMuted',
	background: '$secondarySurface',
	borderRadius: 4,
	marginLeft: 12,
	marginRight: 6,
	color: '$secondary',
});

export const AvatarContainer = styled(FlexBox, {
	height: 'max-content',
	paddingBottom: 20,
});

export const AssignToGroupsBox = styled(GridBox, {
	margin: '20px 0',
	padding: 8,
	gap: 4,
	borderRadius: 8,
	border: `1px solid $border`,
	background: '$vivid',
});

export const ListLabelContainer = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',

	'& svg': {
		fill: '$primary',

		'& path': {
			fill: '$primary',
		},
	},
});

export const ListItem = styled(SelectableList.Item, {
	height: 40,
	marginTop: 8,
	marginBottom: 8,

	'&:first-child': {
		marginTop: 0,
	},

	'&:last-child': {
		marginBottom: 0,
	},
});