import type {SVGProps} from 'react';

export function History(props: SVGProps<SVGSVGElement>): React.JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.125 23.5C24.6083 23.5 25 23.1163 25 22.6429C25 22.1695 24.6083 21.7857 24.125 21.7857H17.375C16.8917 21.7857 16.5 22.1695 16.5 22.6429C16.5 23.1163 16.8917 23.5 17.375 23.5H24.125Z'
				fill='#333333'
			/>
			<path
				d='M25 18.2143C25 18.6877 24.6083 19.0714 24.125 19.0714H20.375C19.8917 19.0714 19.5 18.6877 19.5 18.2143C19.5 17.7409 19.8917 17.3571 20.375 17.3571H24.125C24.6083 17.3571 25 17.7409 25 18.2143Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13 14C13.4833 14 13.875 13.6163 13.875 13.1429C13.875 12.6695 13.4833 12.2857 13 12.2857L10.25 12.2857C9.76674 12.2857 9.375 12.6695 9.375 13.1429C9.375 13.6163 9.76674 14 10.25 14L13 14Z'
				fill='#333333'
			/>
			<path
				d='M25 14C25 14.4734 24.6083 14.8571 24.125 14.8571H22.375C21.8917 14.8571 21.5 14.4734 21.5 14C21.5 13.5266 21.8917 13.1429 22.375 13.1429H24.125C24.6083 13.1429 25 13.5266 25 14Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.1429 13.125C12.1429 13.6083 12.5266 14 13 14C13.4734 14 13.8572 13.6083 13.8572 13.125L13.8572 10.3101C13.8572 9.82683 13.4735 9.43509 13.0001 9.43509C12.5267 9.43509 12.1429 9.82683 12.1429 10.3101L12.1429 13.125Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13 17.5C15.4853 17.5 17.5 15.4853 17.5 13C17.5 10.5147 15.4853 8.5 13 8.5C10.5147 8.5 8.5 10.5147 8.5 13C8.5 15.4853 10.5147 17.5 13 17.5ZM13 19C16.3137 19 19 16.3137 19 13C19 9.68629 16.3137 7 13 7C9.68629 7 7 9.68629 7 13C7 16.3137 9.68629 19 13 19Z'
				fill='#333333'
			/>
		</svg>
	);
}
