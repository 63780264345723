import {SubjectSectionMode} from '@esgi/main/features/teacher/home';
import {SubjectTabId, SubjectSectionModePermissions} from '../../../types';
import {SubjectTab} from '@esgi/main/libs/store';

export function getSectionModePermissionsData({
	personalSubjects,
}: {
	personalSubjects: SubjectTab[] | null;
}): SubjectSectionModePermissions {
	return {
		[SubjectSectionMode.View]: {
			[SubjectTabId.All]: true,
			[SubjectTabId.District]: true,
			[SubjectTabId.School]: true,
			[SubjectTabId.Personal]: true,
		},
		[SubjectSectionMode.Edit]: {
			[SubjectTabId.All]: Boolean(personalSubjects?.length),
			[SubjectTabId.District]: false,
			[SubjectTabId.School]: false,
			[SubjectTabId.Personal]: Boolean(personalSubjects?.length),
		},
		[SubjectSectionMode.Rearrange]: {
			[SubjectTabId.All]: Boolean(personalSubjects?.length),
			[SubjectTabId.District]: false,
			[SubjectTabId.School]: false,
			[SubjectTabId.Personal]: Boolean(personalSubjects?.length),
		},
		[SubjectSectionMode.Add]: {
			[SubjectTabId.All]: true,
			[SubjectTabId.District]: false,
			[SubjectTabId.School]: false,
			[SubjectTabId.Personal]: true,
		},
	};
}
