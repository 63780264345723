import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import styles from './styles.module.less';

type Props = {
	onClose: () => void;
	onOk: () => void;
}

export function EditWarningDialog({onClose, onOk}: Props) {
	return (
		<Modal className='white-header' onCatchError={onClose}>
			<ModalHeader title='Warning' />
			<ModalBody>
				<div className={styles.body}>
					<p className={styles.warning}>
					This question has a self-assess version that cannot be edited. 
					<br />Editing the one-on-one version may result in mismatched question content. 
					<br />Are you sure you want to continue?
					</p>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button onClick={onClose} title={'Cancel'} />
				
				<Primary onClick={onOk}>OK</Primary>
			</ModalFooter>
		</Modal>
	);
}
