import {environment} from '@esgi/core/environments';
import {defaultValues} from 'shared/modules/auto-test-creator/constants';

export const previewRequest = (xml) => {

	const {height, width} = defaultValues.image;

	const searchParams = new URLSearchParams({
		height: height.toString(),
		width: width.toString(),
		'xml': xml ? xml : '',
	});

	return `${environment.esgiApiURL}/assets/quick-test-creator/image?${searchParams}`;
};