import {PropsWithChildren} from 'react';
import {Divider, Title, TitleWrapper, Wrapper} from './index.styled';

type Props = PropsWithChildren<{
	title: string;
	titleBold?: boolean;
	withDivider?: boolean;
}>;

export function DrawerContentBlock({title, titleBold, children, withDivider}: Props) {
	return (
		<Wrapper dataCy='content-block-wrapper'>
			<TitleWrapper dataCy='content-block-title'>
				<Title size='small' font='mono' bold={Boolean(titleBold)} color='mediumContrast'>
					{title}
				</Title>
				{withDivider && <Divider/>}
			</TitleWrapper>
			{children}
		</Wrapper>
	);
}
