import React from 'react';
import ParentLetterEditorModal from 'modules/reports/parent-letter/parent-letter-editor-modal';
import {
	Language,
	LetterTemplate,
	LetterType,
} from 'pages/parent-conferencer/models/models';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {FlexBox} from '@esgillc/ui-kit/layout';
import styles from './parent-letter-editor.module.less';
import {Service} from 'pages/parent-conferencer/components/parent-letter-editor/service';

interface Props {
  onClosed: () => void;
  languages: Language[];
  letterTypes: LetterType[];
}

class State {
	template: LetterTemplate;
	letterType: LetterType;
	language: Language;
	loading: boolean;
}

export default class ParentLetterEditor extends React.Component<Props, State> {
	state = new State();
	service: Service = new Service();

	public componentDidMount() {
		this.service.selectedLetterType$.subscribe(letterType => this.setState({letterType: letterType}));
		this.service.selectedLanguage$.subscribe(language => this.setState({language: language}));
		this.service.template$.subscribe((template) => this.setState({template: template}));

		this.service.setLetterType(this.props.letterTypes[0]);
		this.service.setLanguage(this.props.languages.find(s => s.languageID === 2));
	}

	public componentWillUnmount() {
		this.service.destroy();
	}

	public render() {
		if (!this.state.template) {
			return;
		}

		return (
			<ParentLetterEditorModal
				defaultMessage={this.state.template.defaultBody}
				title={'Conference Invitation and Reminder Messages'}
				message={this.state.template.body}
				onCloseClicked={this.props.onClosed}
				onSaveClicked={(message: string) => this.service.save(message)}
			>
				<ServiceLoader trackingService={this.service} fullscreen/>
				<FlexBox
					justify='space-between'
					className={styles.dropdownsContainer}
				>
					<FlexBox align='center'>
						<label className={styles.dropdownsLabel}>Template:</label>
						<Dropdown
							setValue={(v) => this.service.setLetterType(v[0])}
							className={styles.letterTypeDropdown}
							optionName='name'
							value={[this.state.letterType]}
						>
							{(this.props.letterTypes || []).map(v => (
								<Option key={v.letterTypeID} value={v}>{v.name}</Option>
							))}
						</Dropdown>
					</FlexBox>
					<FlexBox align='center'>
						<label className={styles.dropdownsLabel}>Message Language:</label>
						<Dropdown
							setValue={(v) => this.service.setLanguage(v[0])}
							className={styles.languageDropdown}
							optionName='name'
							value={[this.state.language]}
						>
							{(this.props.languages || []).map(v => (
								<Option key={v.languageID} value={v}>{v.name}</Option>
							))}
						</Dropdown>
					</FlexBox>
				</FlexBox>
			</ParentLetterEditorModal>
		);
	}
}
