import React, {ReactNode} from 'react';
import styles from './parent-letter-editor.module.less';
import TeacherTitleDraggableButton from '../draggable-buttons/buttons/teacher-title';
import TeacherFirstNameDraggableButton from '../draggable-buttons/buttons/teacher-first-name';
import TeacherLastNameDraggableButton from '../draggable-buttons/buttons/teacher-last-name';
import StudentFirstNameDraggableButton from '../draggable-buttons/buttons/student-first-name';
import StudentLastNameDraggableButton from '../draggable-buttons/buttons/student-last-name';
import StudentIdDraggableButton from '../draggable-buttons/buttons/student-id';
import {TextArea} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
  onReverted: () => void;
  onChanged: (message: string) => void;
  message: string;
  readonly: boolean;
  readonlyMessage?: string;
  editable: boolean;
  revertable: boolean;
  afterTextAreaRenderer?: () => ReactNode;
}

export default class ParentLetterEditor extends React.Component<Props> {
	private get editable() {
		return !this.props.readonly && this.props.editable;
	}

	private revert(){
		if (!this.editable){
			return;
		}

		this.props.onReverted();
	}

	onDrop(){
		setTimeout(() => window.getSelection().collapseToEnd(), 1);
	}

	public render() {
		return <div className={styles.container}>
      <div className={styles.header}>
        <span className={join(styles.message, !this.editable && styles.readonly)}>Message</span>
        {(this.props.revertable && !this.props.readonly) && <span onClick={() => this.revert()} className={join(styles.revert, !this.editable && styles.readonly)}>
          <svg className={styles.revertIcon} width='12' height='12' viewBox='0 0 12 12' fill='none'
               xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10.2375 1.7625C9.15001 0.675 7.65751 0 6.00001 0C2.68501 0 0.00750732 2.685 0.00750732 6C0.00750732 9.315 2.68501 12 6.00001 12C8.79751 12 11.13 10.0875 11.7975 7.5H10.2375C9.62251 9.2475 7.95751 10.5 6.00001 10.5C3.51751 10.5 1.50001 8.4825 1.50001 6C1.50001 3.5175 3.51751 1.5 6.00001 1.5C7.24501 1.5 8.35501 2.0175 9.16501 2.835L6.75001 5.25H12V0L10.2375 1.7625Z'
            fill={this.editable ? '#0077B8' : '#828282'}/></svg>
          Revert to default message
        </span>}
      </div>
      <TextArea
        onChange={(e) => this.props.onChanged(e.currentTarget.value)}
        onDrop ={(e) => this.onDrop()}
        value={this.props.message}
        className={join(styles.textarea, !this.editable && styles.readonly)}
        readOnly={!this.editable}
      />
      {(this.props.readonly && this.props.readonlyMessage) &&
        <span className={styles.tagsTitle}>
          {this.props.readonlyMessage}
        </span>
      }

      {this.props.afterTextAreaRenderer && this.props.afterTextAreaRenderer()}

      {this.editable &&
        <>
          <span className={styles.tagsTitle}>
            <b>Optional Titles:</b> Drag these titles into the message to auto-populate information.':
          </span>
          <div className={styles.tagsContainer}>
            <TeacherTitleDraggableButton/>
            <TeacherFirstNameDraggableButton/>
            <TeacherLastNameDraggableButton/>
            <StudentFirstNameDraggableButton/>
            <StudentLastNameDraggableButton/>
            <StudentIdDraggableButton/>
          </div>
        </>
      }
    </div>;
	}
}
