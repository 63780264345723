export interface SchoolModel {
	federalDistrictID: number;
	federalDistrictName: string;
	federalSchoolID: number;
	federalSchoolName: string;
}

export interface CountryModel {
	countryID: number;
	name: string;
}

export interface ProfileTrackTypePeriod {
	from: Date;
	to: Date;
}

export class DropdownInModel {
	constructor(public id: number, public value: string, public disabled?: boolean) {
	}
}

export interface UserInfo {
	schoolName: string,
	isLinked: boolean,
	notRenewable: boolean,
	canAllowRenewByCC: boolean,
	expirationDate: string,
	agreementLevelCode: string,
	initialLogin: string,
	isPreassess?: boolean
	canEditGradeLevels?: boolean;
	gradeLevels?: DropdownInModel[]
}


export interface DistrictModel {
	id: number;
	name: string;
}

export interface InitialData {
	selectedUserID: number,
	selectedUserType: string
}

export class DatesPeriodModel {
	constructor(
		public trackDateID: number,
		public trackID: number,
		public dateFrom: string,
		public dateTo: string,
		public number?: number,
	) {
	}

}

export class TrackModel {
	constructor(
		public districtID: number,
		public globalSchoolYearID: number,
		public name: string,
		public schoolYearTypeID: number,
		public schoolYearType: string,
		public trackID: number,
		public trackDates: DatesPeriodModel[],
		public number?: number,
		public isDisabled?: boolean,
	) {
	}
}
export class TrackDatesModel {
	dateFrom: string;
	dateTo: string;
}

export class SchoolYearTypeModel {
	constructor(
		public id: number,
		public name: string,
		public defaultTrackDates: TrackDatesModel[],
		public minDate: string,
		public maxDate: string,
		public isDisabled: boolean,
	) {
	}
}

export interface SecondaryProfileModel {
	credentialID: number;
	userCredentialType: string;
	firstName: string;
	lastName: string;
	email: string;
	userName: string;
}

export interface UserModel {
	email: string
	title: string;
	firstName: string
	lastName: string
	countryID: number
	stateID: number
	userName: string
	expirationDate: string
	districtID: number
	districtName: string
	schoolName: string;
	isLinked: boolean;
	notRenewable: boolean;
	canAllowRenewByCC: boolean;
	agreementLevelCode: string;
	canEditGradeLevels: boolean;
	gradeLevels: number[];
}

export interface UserInfoResponse {
	user: UserModel;
	states: {
		stateID: number;
		name: string;
	}[];
	countries: CountryModel[];
	secondaryProfile: SecondaryProfileModel;
}

export interface TrackInfoResponse {
	tracks: TrackModel[];
	schoolYearTypes: SchoolYearTypeModel[];
	selectedTrackID: number;
}

export class AdminProfileModel {
	constructor(
		public userName: string,
		public email: string,
		public title: string,
		public firstName: string,
		public globalSchoolYearID: number,
		public lastName: string,
		public password: string,
		public stateID: number,
		public trackID: number,
		public userID: number,
	) {
	}
}


export enum DefaultProfileControllers {
	Common = 'profiles/common',
	Teacher = 'profiles/teacher',
	SchoolAdministrator = 'profiles/school-admin',
	DistrictAdministrator = 'profiles/district-admin',
	DistrictSpecialist = 'profiles/district-specialist',
	SchoolSpecialist = 'profiles/school-specialist',
	PreAssess = 'profiles/preassess-account',
}
