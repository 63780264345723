import {SelectableList} from '@esgi/ui/layout';
import {getListValue} from './utils/get-list-value';
import {AssignmentListModel} from './types';
import {SelectableListItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {AssignmentStatus} from '../assignment-status';
import {Tooltip} from '@esgi/ui';
import {statusDescription} from '../assignment-status/constants';

export {type AssignmentListModel} from './types';

type Props = {
	assignments: AssignmentListModel[];
	selectedAssignmentId: AssignmentListModel['id'] | null;
	onAssignmentClick: (assignmentId: AssignmentListModel['id']) => void;
};

export function AssignmentsSelectableList({assignments, selectedAssignmentId, onAssignmentClick}: Props) {
	return (
		<SelectableList dataCy='assignments-selectable-list'>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedAssignmentId)}>
				<SelectableList.Group>
					{assignments.map(({id, name, state}) => (
						<SelectableListItem
							value={String(id)}
							key={id}
							forceSelect={selectedAssignmentId !== id}
							onClick={() => onAssignmentClick(id)}
							variant='outlined'
							outlined
							selected={selectedAssignmentId === id}
						>
							<Text size='large' color='neutral40'>
								{name}
							</Text>
							<Tooltip>
								<Tooltip.Trigger>
									<AssignmentStatus state={state} likeAbbreviation />
								</Tooltip.Trigger>
								<Tooltip.Content variant='secondary'>
									<Text size='small'>{statusDescription[state].full}</Text>
								</Tooltip.Content>
							</Tooltip>
						</SelectableListItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
