import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {CSS} from '@stitches/react';
import {Box} from '../../../box';

type CardBodyProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const CardBody = forwardRef<HTMLDivElement, CardBodyProps>(({dataCy, children, ...props}, forwaredRef) => (
	<Box data-cy={dataCy ?? 'ui-kit-card-body'} {...props} ref={forwaredRef}>
		{children}
	</Box>
));
