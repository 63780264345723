import React from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onConfirm: () => void;
	onCancel: () => void
}

export function RemoveAlert({onConfirm, onCancel}: Props): JSX.Element {
	const modalManager = useModal();
	const confirm = useCloseModal(modalManager, onConfirm);
	const cancel = useCloseModal(modalManager, onCancel);

	return <Alert modalManagerRef={modalManager}>
		<Alert.Body>
			Are you sure you want to delete this specialist group?
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={cancel}>
				NO
			</Buttons.Text>
			<Buttons.Text onClick={confirm}>
				YES
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}