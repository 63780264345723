import {BehaviorSubject, Subject} from 'rxjs';
import {FormDataBuilder, HttpClient} from '@esgi/api';
import {BaseService} from '@esgi/core/service';
import {tap} from 'rxjs/operators';
import {SessionType, SubjectType} from '@esgi/core/enums';
import {mapToEnum} from 'shared/utils';
import {ScoreModel} from './types';

export class ScoreService extends BaseService {
	public testingModel = new BehaviorSubject<ScoreModel>(null);
	public onLostConnection: Subject<void> = new Subject<void>();
	protected readonly controller = 'assessment/test-screen/score';

	public init(testId: number, studentId: number, subjectId: number, subjectType: SubjectType) {
		return HttpClient.default.ESGIApi.post<ScoreModel>(
			this.controller,
			'run-test',
			{testId, studentId, subjectId, subjectType},
		).pipe(tap((r) => this.testingModel.next(r)));
	}

	public finishTest(score: number, notes: string, duration: number) {
		const data = FormDataBuilder.BuildParameters({
			duration: duration || 0,
			testId: this.testingModel.value.testID,
			studentId: this.testingModel.value.studentID,
			sessionType: mapToEnum(this.testingModel.value.sessionType, SessionType),
			score,
			notes,
		});

		return this.httpClient.ESGIApi.post<{testSessionID: number}>(
			this.controller,
			'end-test',
			data,
		).withCustomErrorHandler((error, strategy) => {
			if ([0, 400].includes(error.status)) {
				strategy.stopPropagation();
				this.onLostConnection.next();
			}
		});
	}
}
