import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {SubjectModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/student-detail/report/init/subject-model';
import {ReportData as ReportInfo} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/item-analysis/models/report-data';
import {QuestionModel as QuestionInfo} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/item-analysis/models/question-model';
import {Test} from './components/tests-control/types';
import {Group, Class} from '@esgi/main/libs/store';

export {type Response as InitResponse} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/item-analysis/init/response';
export {type Response as UpdateResponse} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/item-analysis/update/response';
export {type ScoreModel as Score} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/student-detail/report/init/score-model';
export {TestType} from '@esgi/contracts/esgi/types/esgi.enums/test-type';
export {StudentSort} from '@esgi/contracts/esgi/types/esgi.enums/student-sort';

export {type ReportInfo, type Test};

export interface DownloadOptions {
	testId: Test['id'],
	testName: Test['name'],
	classId: Entity['classId'],
	className: Class['name'],
	groupId: Entity['groupId'],
	groupName: Group['name'],
	subjectId: SubjectModel['subjectID'],
	subjectType: SubjectModel['subjectType'],
	subjectName: SubjectModel['name'],
	sortOptionMode: Sort
}

export interface UnitDataSettings {
	selectedSubject: SubjectModel;
	selectedTest: Test;
	selectedGroup: Group | null;
	selectedClass: Class | null;
}

export interface PrintSettings extends DownloadOptions {
	displayNotTestedAsIncorrect: boolean;
	sortOptionMode: Sort;
}

export enum Sort {
	LeastKnown,
	MostKnown,
	QuestionOrder,
	Alphabetical,
}

export interface Entity extends Pick<SingleSelectState, 'classId' | 'groupId'> {
}

export type Level = 'user' | 'school' | 'district' | 'group';

export interface Details {
	studentsCorrect: string[];
	studentsIncorrect: string[];
	studentsNotTested: string[];
}

export type ChangeFunction<T> = (value: T) => void;
export type DownloadFunction = (value: FileType) => void;

export interface ReportData extends Omit<ReportInfo, 'questions'> {
	questions: QuestionData[];
	maxScore: number;
}

export interface ChartOptions {
	chartSize: {width: number, height: number, max: number, min?: number};
	testName: string;
	reportData: ReportData;
	sortOptionMode: string;
	userLevel: string;
	stackIncorrect: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	updateScrollbarDisplay: () => void;
	normalizeChart: (event) => void;
	selectPoint: (event) => void;
	updateTitleSize: () => void;
	clone?: boolean;
}

export interface StudentData {
	studentID: number;
	name: string;
	gradeLevelID: number;
}

export interface QuestionData extends QuestionInfo {
	correct: number;
	incorrect: number;
}

export interface IdNameModel {
	id: number;
	name: string;
}

export enum FileType {
	PDF,
	Excel,
}

export type ZoomOption = 'Zoom In' | 'Zoom Out' | 'Zoom To Fit' | '50%' | '100%' | '200%';
