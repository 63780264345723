import {VariantProps} from '@stitches/react';
import {Cell} from './index.styled';
import {FontFamily} from '../../../../types';

export const fontFamilyMap: Record<FontFamily, NonNullable<VariantProps<typeof Cell>['type']>> = {
	[FontFamily.Lined]: 'lined',
	[FontFamily.Normal]: 'normal',
	[FontFamily.DottedLined]: 'dotted',
	[FontFamily.Blank]: 'blank',
};
