import {switchMap, tap} from 'rxjs/operators';
import {createAdditionalForm} from 'modules/forms/students-form/components/forms/additional';
import {
	AdditionalInfoChangedEvent,
	StudentChangedEvent,
	StudentSaveAndAddEvent,
} from 'modules/forms/students-form/events';
import {
	PreSelected,
	ProfileInitData,
	StudentProfileMode,
	StudentProfileTab,
	TabsApi,
} from 'modules/forms/students-form/types';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {BehaviorSubject, of} from 'rxjs';
import {RefObject} from 'react';
import {BaseTabService} from 'modules/forms/students-form/components/profile-modal/components/tabs/base-tab-service';
import moment from 'moment';
import {LunchStatus} from '../../../../../enums/lunch-status';
import {getUser} from '@esgi/core/authentication';

export class AdditionalService extends BaseTabService {

	public form = createAdditionalForm();
	protected tab = StudentProfileTab.Additional;

	private preSelected = new BehaviorSubject<PreSelected>(null);
	private currentUser = getUser();

	public init = (mode: StudentProfileMode, initData: ProfileInitData, preSelected: PreSelected, studentID: number, tabsApi: RefObject<TabsApi>) => {
		this.studentID = studentID;
		this.preSelected.next(preSelected);
		this.initData.next(initData);
		window.moment = moment;
		if (mode !== StudentProfileMode.add) {

			const {additional} = initData;

			if (additional) {
				const {race, comments, studentSpecialPrograms, lunchStatus, birthDate} = additional;
				const {races, specialPrograms, lunchStatuses} = initData.dictionary;

				this.form.value = {
					programs: studentSpecialPrograms ? specialPrograms.filter(v => studentSpecialPrograms.includes(v?.id)) : [],
					lunchStatus: lunchStatus
						? lunchStatuses.filter(({id}) => id === LunchStatus[lunchStatus]?.toString())
						: [],
					race: race ? races.filter(v => v.id === race) : races.filter(v => v.id === 'UnknownOther'),
					comments: comments || '',
					birthDate,
				};
			}

			this.initTabsApi(tabsApi);

		}

		if (!this.currentUser.canEditStudentMetaFields) {
			this.form.status = ElementStatus.disabled;
		}
	};

	public save = (isSaveAndAdd?: boolean) => {

		const {
			race,
			lunchStatus,
			programs,
			comments,
			birthDate,
		} = this.form.value;

		const model = new AdditionalInfoChangedEvent(
			this.studentID,
			birthDate || null,
			race.length ? race[0].id : null,
			lunchStatus.length ? lunchStatus[0].id : null,
			programs.length ? programs.map(v => v.id) : [],
			comments || '',
		);
		return this.form.validate().pipe(switchMap((v) => {
			if(v.valid) {
				return this.httpClient.ESGIApi.post<AdditionalInfoChangedEvent>(this.controller, 'profile/additional/save', model)
					.pipe(tap(() => {
						const {
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
						} = this.initData.value.general;
						const event = new StudentChangedEvent(
							this.studentID,
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
							this.initData.value?.location?.schoolID,
							this.initData.value?.location?.teacherID || this.preSelected.value?.userID,
							this.initData.value?.location?.classIDs,
							this.initData.value?.location?.groupIDs,
							null,
							{...model, studentSpecialPrograms: model.programs},
						);
						this.eventBus.dispatch(StudentChangedEvent, event);
						this.eventBus.dispatch(StudentSaveAndAddEvent, new StudentSaveAndAddEvent(isSaveAndAdd));
					}));
			}
			return of();
		}));

	};
}

