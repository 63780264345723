import {Buttons} from '@esgillc/ui-kit/button';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {FieldError, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {Step, StepContent, StepHeader, StepperContext} from '@esgillc/ui-kit/layout';
import React, {useContext} from 'react';
import {AssignmentNameFormGroup} from '../../types/types';
import {StepFooter} from '../step-footer';
import styles from './styles.module.less';

interface Props {
	formGroup: AssignmentNameFormGroup;
}

const stepIndex = 1;

export function FirstStep({formGroup}: Props) {
	const context = useContext(StepperContext);
	const next = () => {
		formGroup.validate().subscribe(r => {
			if (r.valid) {
				context.next();
			}
		});
	};

	return <Step index={stepIndex}>
		<StepHeader onClick={() => context.setActiveStep(stepIndex)}>Assignment Name & Description</StepHeader>
		<StepContent>
			<div className={styles.container}>
				<Form controller={formGroup} autoComplete={'off'}>
					<FormElement control={formGroup.controls.name} disableValidateOnBlur>
						<label className={styles.label}>Assignment Name</label>
						<Input type='text' placeholder='i.e. Language Arts - Beg of Year' maxLength={50}
						       data-lpignore={'true'}
						       id={'assignment-name'}
						       showCharactersLeft={true}/>
						<FieldError showOnError='required' showOnInit
						            placement='left-bottom'>
							Please enter an assignment name to continue.
						</FieldError>
						<FieldError showOnError='length-max' showOnInit
						            placement='left-bottom'>
							Maximum length is 50 characters.
						</FieldError>
						<FieldError showOnError='unique'
						            placement='left-bottom'
						            showOnInit>
							Assignment with this name already exist.
						</FieldError>
					</FormElement>
					<FormElement control={formGroup.controls.description}>
						<label className={styles.label}>Assignment Description</label>
						<Input
							showCharactersLeft={true}
							type='text'
							placeholder='Enter description (optional)'
							maxLength={150}
							data-lpignore={'true'}
							id={'assignment-description'}
						/>
						<OnErrorTooltip innerClassName={styles.innerTooltip} showOnError='length-max' showOnInit
						                placement='left-bottom'
						                className={styles.tooltip}>
							Maximum length is 150 characters.
						</OnErrorTooltip>
					</FormElement>
				</Form>
			</div>
			<StepFooter>
				<Buttons.Contained onClick={next}>
					NEXT
				</Buttons.Contained>
			</StepFooter>
		</StepContent>
	</Step>;
}
