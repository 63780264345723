import {StudentSort} from '@esgi/core/enums';
import {ClassicHierarchySnapshot, SpecialistHierarchySnapshot} from '../models';
import {BoxType, HierarchyInstance, HierarchyMode} from './models';
import {HttpClient} from '@esgi/api';
import {PreAssessHierarchySnapshot} from 'modules/hierarchy/core/models/snapshot/preassess-hierarchy-snapshot';
import {UserType} from '@esgi/core/authentication';

export class Api {
	private controller = 'modules/hierarchy';

	init(hierarchy: HierarchyInstance) {
		return HttpClient.default.ESGIApi.get<IInitResponse>(this.controller, 'init', {fullHierarchy: hierarchy});
	}

	boxOpenClose(boxType: BoxType, open: boolean) {
		return HttpClient.default.ESGIApi.post(this.controller, 'open-close', {boxType: boxType, open: open});
	}
}


export interface IInitResponse {
    snapshot: IFullHierarchyModelResponse;
    classes: IClassBox;
    groups: IGroupBox;
    schoolsGroups: SchoolsGroupBox;
    students: IStudentBox;
    teachers: ITeacherBox;
    specialistGroups: ISpecialistGroupBox;
    teachersGroups: TeachersGroupBox;
    groupsOfSpecialists: GroupOfSpecialistsBox;
    schAdmins: ISchAdminBox;
    schoolSpecialists: ISchoolSpecialist[];
    districtSpecialists: IDistrictSpecialist[];
    schools: ISchoolBox;
    preAssesses: IPreAssessModel[];
    districtName: string;
    boxes: IBoxInfo[];
}

export interface IBoxInfo {
    boxType: BoxType;
    open: boolean;
}

export interface IUserModel {
    userID: number;
    firstName: string;
    lastName: string;
    groups: number[];
}

export interface ISchoolSpecialist extends IUserModel {
    schoolID: number;
}

export interface IDistrictSpecialist extends IUserModel {
}

export interface IFullHierarchyModelResponse {
    districtID: number;
	  userID: number;
    mode: string;
    classic: ClassicHierarchySnapshot;
    specialist: SpecialistHierarchySnapshot;
    preAssess: PreAssessHierarchySnapshot;
}

export interface IFullHierarchyModel {
    districtID: number;
    mode: HierarchyMode;
    classic: ClassicHierarchySnapshot;
    specialist: SpecialistHierarchySnapshot;
    preAssess: PreAssessHierarchySnapshot;
}

export interface IClassModel {
    classID: number;
    name: string;
    userID: number;
}

export interface IClassBox extends IBoxModel<IClassModel> {

}

export interface IGroupModel {
    groupID: number;
    name: string;
    classID: number;
    orderNum: number;
}

export interface GroupModelForAdmin {
    groupID: number;
    name: string;
    userID: number;
    orderNum: number;
}

export interface IGroupBox extends IBoxModel<IGroupModel> {

}

export interface SchoolsGroupBox extends IBoxModel<GroupModelForAdmin> {

}

export interface TeachersGroupBox extends IBoxModel<GroupModelForAdmin> {

}

export interface GroupOfSpecialistsBox extends IBoxModel<GroupModelForAdmin> {

}

export interface IStudentBox extends IBoxModel<StudentModel> {
    sortBy: StudentSort;
}

export interface ISchoolModel {
    schoolID: number;
    name: string;
    groups: number[];
}

export interface ISchoolBox extends IBoxModel<ISchoolModel> {
}

export interface ISchAdminBox extends IBoxModel<ISchAdminModel> {

}

export interface ISchAdminModel {
    userID: number;
    firstName: string;
    lastName: string;
    schoolID: number;
}

export interface ISpecialistGroupModel {
    groupID: number;
    name: string;
    userID: number;
    type: UserType;
}

export interface ISpecialistGroupBox extends IBoxModel<ISpecialistGroupModel> {

}

export class StudentModel {
    constructor(
        public firstName: string,
        public lastName: string,
        public studentID: number,
        public teacherID: number,
        public schoolID: number,
        public districtID: number,
        public gradeLevelID: number,
        public classes: number[],
        public groups: number[],
        public specialistGroups: number[],
    ) {}
}


export interface ITeacherModel extends IUserModel{
    schoolID: number;
    title: string;
    trackID: number;
}

export interface ITeacherBox extends IBoxModel<ITeacherModel> {

}

export interface IPreAssessModel {
  userID: number;
  firstName: string;
  lastName: string;
}

export interface IBoxModel<T> {
    items: T[];
    canCreate: boolean;
    canEdit: boolean;
}
