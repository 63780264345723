import {PreviewDialog} from './components/preview-dialog';
import {NameFormatControl} from './components/name-format-control';

export * from './services';
export * from './hooks';
export * from './types';

export const WritingPractice = {
	PreviewDialog,
	NameFormatControl,
};
