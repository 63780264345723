import {StudentsTable} from './components/table';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dispatch, useCallback, useEffect, useRef, useState} from 'react';
import {GoogleAnalyticsTracker} from '@esgi/core/tracker';
import {
	AlertBody,
	ContextMenuItem,
	ReportModalBodyStyled,
	TotalReportContainer,
	TotalReportSettings,
	TotalReportWrapper,
	SettingsText,
	SelectsContainer,
	OverlayBox,
} from './index.styled';
import {ClassTotalsService} from '../../service';
import {Reports} from '@esgi/main/kits/reports';
import {Class, Group, SubjectTab} from '@esgi/main/libs/store';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ThemeProvider} from '@esgi/ui/theme';
import {SelectableClassGroup} from './components/classes';
import {CarryForward} from './components/carry-forward';
import {DisplayResults, NotTested, MarkingPeriod, Report} from '../../service/types';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Button, ContextMenu} from '@esgi/ui';
import {Setting} from './components/setting';
import {displayResultsSettings, markingPeriodSettings, notTestedSettings} from './constants';
import useResizeObserver from '@react-hook/resize-observer';
import {AlertContentSize} from './types';

type Props = {
	onClose: VoidFunction;
	service: ClassTotalsService;
	subjects: SubjectTab[];
	isSubjectsLoaded: boolean;
	onSubjectSelect: (value: number) => void;
	selectedSubject: string;
	selectedEntity: SingleSelectState;
	classes: Class[];
	groups: Group[];
	setSelected: Dispatch<SingleSelectState>;
};

export function PreviewDialog(props: Props) {
	const alertRef = Alert.useRef();
	const report = useBehaviorSubject<Report | null>(props.service.result$);

	const [contentSizes, setContentSizes] = useState<AlertContentSize>({
		width: 0,
		height: 0,
	});

	const onClose = Alert.useClose(alertRef, () => {
		props.service.closeReport();
		props.onClose();
	});

	useEffect(() => {
		GoogleAnalyticsTracker.trackEvent('TotalsReport');
	}, []);

	const displayResults = useBehaviorSubject<DisplayResults | null>(props.service.displayResults$);
	const notTested = useBehaviorSubject<NotTested | null>(props.service.notTested$);
	const carryForward = useBehaviorSubject<boolean>(props.service.carryForward$);
	const markingPeriod = useBehaviorSubject<MarkingPeriod | null>(props.service.markingPeriod$);

	const overlayRef = useRef<HTMLDivElement>(null);

	useResizeObserver(overlayRef, ({contentRect}) =>
		setContentSizes({width: contentRect.width, height: contentRect.height}),
	);

	const renderSettings = useCallback(() => {
		return (
			<FlexBox>
				<SettingsText size='medium'>Settings:</SettingsText>
				<Setting
					value={displayResults}
					setValue={(value) => props.service.setDisplayResults(value)}
					options={displayResultsSettings}
					settingName='Display results:'
				/>
				<Setting
					value={notTested}
					setValue={(value) => props.service.setNotTested(value)}
					options={notTestedSettings}
					settingName='Display not tested as:'
				/>
				<CarryForward checked={carryForward} onChange={(value) => props.service.setCarryForward(value)} />
				<Setting
					value={markingPeriod}
					setValue={(value) => props.service.setMarkingPeriod(value)}
					options={markingPeriodSettings}
					settingName='Marking period:'
				/>
			</FlexBox>
		);
	}, [displayResults, notTested, carryForward, markingPeriod, props.service]);

	const renderSelects = useCallback(() => {
		return (
			<SelectsContainer data-cy='selects-container'>
				<Box>
					<Text data-cy='teacher-label' size='medium'>
						Teacher:{' '}
					</Text>
					<Text data-cy='teacher-name' size='medium' bold={false}>
						{report?.args[0]}
					</Text>
				</Box>
				<SelectableClassGroup
					data-cy='group-class-dropdown'
					skeleton={!props.classes || !props.groups}
					classes={props.classes}
					groups={props.groups}
					value={props.selectedEntity}
					onChange={(value) => {
						props.setSelected(value);
						props.service.getReport();
					}}
				/>
				<Reports.SubjectControl
					data-cy='subject-dropdown'
					skeleton={!props.isSubjectsLoaded}
					subjects={props.subjects}
					value={props.selectedSubject}
					onChange={(value) => props.onSubjectSelect(+value)}
				/>
			</SelectsContainer>
		);
	}, [props, report]);

	return (
		<Alert
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.neutral}
			dataCy='total-report-preview'
			css={{
				'& [data-alert-content]': {
					maxHeight: 'calc(100% - 25px)',
					transform: 'none',
					top: `calc(50% - ${contentSizes.height}px / 2)`,
					left: `calc(50% - ${contentSizes.width}px / 2)`,
				},
			}}
		>
			<OverlayBox ref={overlayRef} />
			<Alert.Header onCloseIconClick={onClose}>
				<Text size='large'>{props.service.reportName.value} Total Report</Text>
			</Alert.Header>
			<AlertBody>
				<ThemeProvider>
					<ReportModalBodyStyled flow='row'>
						<TotalReportSettings flow='row' gap={5}>
							{renderSelects()}
							{renderSettings()}
						</TotalReportSettings>
						<TotalReportWrapper data-cy='spr-container'>
							<TotalReportContainer>
								<StudentsTable skeleton={report === null} service={props.service} />
							</TotalReportContainer>
						</TotalReportWrapper>
						<ServiceLoader trackingService={props.service} fullscreen />
					</ReportModalBodyStyled>
				</ThemeProvider>
			</AlertBody>
			<Alert.Footer>
				<ContextMenu.Root>
					<ContextMenu.Trigger disabled={report?.table?.rows?.length === 0}>
						<Button color='primary'>
							<Text size='medium' bold>
								Download
							</Text>
						</Button>
					</ContextMenu.Trigger>
					<ContextMenu.Content>
						<ContextMenu.Group>
							<ContextMenuItem data-cy='download-option-pdf' onClick={() => props.service.downloadPdf()}>
								<Text size='medium'>PDF</Text>
							</ContextMenuItem>
							<ContextMenuItem data-cy='download-option-excel' onClick={() => props.service.downloadExcel()}>
								<Text size='medium'>Excel</Text>
							</ContextMenuItem>
						</ContextMenu.Group>
					</ContextMenu.Content>
				</ContextMenu.Root>
			</Alert.Footer>
		</Alert>
	);
}
