import {GridBox} from '@esgi/ui/layout';
import {CardBody as CardBodyContainer} from '../../../../components/components.styled';
import {RingFullFilledProgress} from '../../../../components/ring-full-filled-progress';
import {AnswersInfo} from '../../../../components/answers-info';
import {TestGroupStudentsModel} from '../../../../../../../../../../types';
import {useAnswersInfoData} from './use-answers-info-data';

type Props = Pick<TestGroupStudentsModel, 'testInfo' | 'classResult' | 'studentsResult'>;

export function CardBody({classResult, testInfo, studentsResult}: Props) {
	const answersInfo = useAnswersInfoData({
		classResultValue: classResult.result,
		testType: testInfo.type,
		totalPossible: testInfo.totalPossible,
		testedStudents: studentsResult.testedStudents,
		totalStudents: studentsResult.totalStudents,
	});

	return (
		<CardBodyContainer>
			<RingFullFilledProgress value={answersInfo.value} maxValue={answersInfo.maxValue} />

			<GridBox gap='3'>
				<AnswersInfo
					transcriptText='Student Tested'
					value={studentsResult.testedStudents >= 1 ? studentsResult.testedStudents : null}
					maxValue={studentsResult.totalStudents}
				/>

				<AnswersInfo
					transcriptText={answersInfo.transcript}
					value={answersInfo.value}
					maxValue={answersInfo.maxValue}
				/>
			</GridBox>
		</CardBodyContainer>
	);
}
