import {useServiceFactory} from '@esgi/core/service';
import {dispatchAppEvent} from '@esgillc/events';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import {useCallback, useState} from 'react';
import {SessionType, TestType} from '@esgi/core/enums';
import {TestSessionStatus} from '../../../kit/enums';
import {TestSavedEvent} from '../../../kit/events';
import {TestIntro} from '../../../kit/test-intro';
import {TestingModel} from '../../models';
import {ShareSyncService} from '../../../kit/share-screen';
import {TestSummary} from '../../../summary';
import {Summary} from '../../types';
import {YNQuestionSlider} from './components/question-slider/question-slider';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import {IepGoalFormModal} from 'modules/forms/iep-goal-form';
import {IepProgressReport} from 'modules/reports/iep-progress';

export type Props = {
	guid: string;
	continueSession: boolean;
	sessionType: SessionType;
	shuffleQuestions: boolean;
	resumeSession: boolean;
	testingModel: TestingModel;
	questionIds?: number[];
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClose: () => void;
	onTestHistoryClicked: () => void;
	onFlashCardsClicked: () => void;
	onTestSessionDetailsClicked: () => void;
	onSessionStatusChanged: (sessionStatus: TestSessionStatus) => void;
}

enum Screen {
	None,
	Intro,
	Slider,
	Summary,
	IEPEdit,
	IEPReport,
}

export function YesNoFlow({testingModel, ...props}: Props): JSX.Element {
	const [screen, setScreen] = useState(() => testingModel.testIntro && !props.continueSession && !props.resumeSession ? Screen.Intro : Screen.Slider);
	const [summary, setSummary] = useState<Summary>({} as Summary);

	const shareSyncService = useServiceFactory(() => new ShareSyncService({
		userID: testingModel.general.userID,
		testName: testingModel.testName,
		studentName: testingModel.studentName,
		isWhiteBackground: testingModel.isWhiteBackground,
	}));

	const onFinished = useCallback((summary: Summary) => {
		const {testID, studentID} = testingModel.general;
		setSummary(summary);
		setScreen(Screen.Summary);
		dispatchAppEvent(TestSavedEvent, new TestSavedEvent(testID, studentID, summary.correct));
	}, []);

	const {
		testName,
		studentName,
		showTestSessionResults,
		testResultsIncorrectVerbiage,
		testResultsCorrectVerbiage,
	} = testingModel;
	const {testID: testId, studentID: studentId} = testingModel.general;

	return (
		<Switch test={screen}>
			<Case value={Screen.Intro}>
				<TestIntro
					testName={testingModel.testName}
					bucketName={testingModel.bucketName}
					testID={testId}
					studentName={testingModel.studentName}
					testIntroID={testingModel.testIntro?.id}
					modifyTicks={testingModel.testIntro?.modifyTicks}
					pregenerated={testingModel.testIntro?.pregenerated}
					isWhiteBackground={testingModel.isWhiteBackground}
					onStart={() => setScreen(Screen.Slider)}
					onClose={props.onClose}
					onCancel={props.onClose}
				/>
			</Case>
			<Case value={Screen.Slider}>
				<YNQuestionSlider
					guid={props.guid}
					testingModel={testingModel}
					questionIds={props.questionIds}
					sessionType={props.sessionType}
					shareSyncService={shareSyncService}
					resumeSession={props.resumeSession}
					continueSession={props.continueSession}
					shuffleQuestions={props.shuffleQuestions}
					closeClicked={props.onClose}
					toIntroClicked={() => setScreen(Screen.Intro)}
					onEndTest={onFinished}
					onSessionStatusChanged={props.onSessionStatusChanged}
				/>
			</Case>
			<Case value={Screen.Summary}>
				<TestSummary
					testName={testName}
					total={summary.total}
					testID={testId}
					testType={TestType.YN}
					studentName={studentName}
					correct={summary.correct}
					note={props.resumeSession ? testingModel.resumeModel.notes : undefined}
					testSessionID={summary.testSessionID}
					showPieChart={showTestSessionResults}
					hierarchy={props.hierarchy}
					subject={props.subject}
					testResultsCorrectVerbiage={testResultsCorrectVerbiage}
					testResultsIncorrectVerbiage={testResultsIncorrectVerbiage}
					iep={{testId, studentId}}
					onFlashCardsClicked={props.onFlashCardsClicked}
					onTestHistoryClicked={props.onTestHistoryClicked}
					onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
					onIEPEdit={() => setScreen(Screen.IEPEdit)}
					onIEPReport={() => setScreen(Screen.IEPReport)}
					onClose={props.onClose}
				/>
				</Case>
				<Case value={Screen.IEPEdit}>
					<IepGoalFormModal
						hierarchy={props.hierarchy}
						subject={props.subject}
						onClose={props.onClose}
						testID={testingModel.general?.testID}
					/>
				</Case>
				<Case value={Screen.IEPReport}>
					<IepProgressReport
						selected={[testId]}
						studentId={studentId}
						subjectId={props.subject.id}
						hierarchy={props.hierarchy}
						onClose={props.onClose}
					/>
			</Case>
		</Switch>);
}
