import moment from 'moment';
import React from 'react';
import {Button, PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import {SubjectItem} from '../../../../services/subjects-service';

interface Props {
	testID: number;
	draft: boolean;
	lastTestDate: string;
	mySubjects: SubjectItem[];
	addToSubjectClicked: () => void;
	onPreviewClicked: () => void;
}

export default class Footer extends React.PureComponent<Props> {
	public render() {
		return <footer>
			<div className='info-message'>
				{this.renderInfoMessage()}
			</div>
			<div className='buttons'>
				{!this.props.draft && <Button onClick={() => this.props.addToSubjectClicked()}>Add Test</Button>}
				<PrimaryButton onClick={() => this.props.onPreviewClicked()}>Preview</PrimaryButton>
			</div>
		</footer>;
	}

	private renderInfoMessage() {
		if (this.props.lastTestDate) {
			return `Last used on ${moment(this.props.lastTestDate).format('MM-DD-YY')}`;
		}

		let subjects = this.props.mySubjects.map(s => s.tests.filter(t => t.testID === this.props.testID).map(t => {
			return {
				AddedAt: moment(t.addedAt),
				Name: s.name,
			};
		})).filter(t => t.length > 0);

		if (!subjects.length) {
			return null;
		}

		let flatten = [].concat.apply([], subjects);
		let sorted = flatten.sort((a, b) => a.AddedAt.isAfter(b.AddedAt)).reverse();

		let subject = sorted[0];

		return `Test added to ${subject.Name} on ${subject.AddedAt.format('MM-DD-YYYY')}`;
	}
}
