import {TestModel} from 'modules/reports/student-progress-report/models';
import {useModal} from '@esgillc/ui-kit/modal';
import {useService} from '@esgi/core/service';
import {useEffect, useState} from 'react';
import {Loader, ServiceLoader} from '@esgillc/ui-kit/loader';
import {
	ReportModal,
	ReportModalBody,
	ReportModalHeader,
} from '@esgi/deprecated/ui-kit/report/modal';
import StudentProgressReportService
	from 'modules/reports/student-progress-report/services/student-progress-report-service';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import StudentProgressReport from 'modules/reports/student-progress-report/student-progress-report';
import {GoogleAnalyticsTracker} from '@esgi/core/tracker';
import styles from './student-progress-report.module.less';
import {SettingsModalHeader} from 'modules/reports/student-progress-report/components/modal-header';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';

interface Props {
	globalSchoolYearId: number;
	classId: number;
	groupId: number;
	specialistGroupID: number;
	studentId: number;
	subjectId: number;
	subjectType: string;
	subjectLevel: string;
	teacherId: number;
	selectedTests: TestModel[];
	onClose: () => void;
	onBack: () => void;
	hierarchy: HierarchySnapshot;
	changesCollector: ChangesCollector;
}

export default function StudentProgressReportModal({...props}: Props) {
	const modalManager = useModal();
	const service = useService(StudentProgressReportService);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const closeHandler = () => {
		const options = service.settings;
		props.changesCollector.applyChanges({
			classID: options.classID,
			groupID: options.groupID || options.specialistGroupID,
			studentID: options.studentID,
			subjectTabID: options.subjectTabID,
			subjectTabType: options.subjectTabType,
		});
		modalManager.current.close(() => {
			props.onClose();
		});
	};

	const onBack = () => {
		modalManager.current.close();
		service.destroy();
		props.onBack();
	};

	useEffect(() => {
		GoogleAnalyticsTracker.trackEvent('StudentProgressReport');
		service.initReport(props).subscribe(() => {
			setIsLoading(false);
		});
	}, []);

	if (isLoading) {
		return <Loader show={true} fullscreen/>;
	}
	return (
		<ReportModal modalManagerRef={modalManager}>
			<ReportModalHeader>
				<SettingsModalHeader service={service} onGoBack={onBack} onClose={closeHandler} />
			</ReportModalHeader>
			<ReportModalBody className={styles.modal}>
				<StudentProgressReport service={service}/>
				<ServiceLoader trackingService={service} fullscreen/>
			</ReportModalBody>
		</ReportModal>
	);
}
