import {SelectField, SelectOption} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Select as SelectUI} from '@esgi/ui/controls';
import {ComponentProps} from 'react';
import {ReportSelectOption} from '../../../../../../types/table-level-report-service-types';
import {RootWrapper} from '../../components/root-wrapper';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectName} from '@esgi/main/kits/common';

type Option = ReportSelectOption & {
	level: SubjectTab['level'];
};

type Props = Pick<ComponentProps<typeof SelectUI.Root>, 'value' | 'onValueChange'> & {
	label: string;
	options: Option[];
};

export function Subjects({label, options, value, onValueChange}: Props) {
	return (
		<RootWrapper label={label}>
			{options.length > 1 && (
				<SelectUI.Root value={value} onValueChange={onValueChange}>
					<SelectField />
					<SelectUI.Content>
						{options.map(({id, name, level}) => (
							<SelectOption value={String(id)} key={id}>
								<SubjectName subjectLevel={level}>{name}</SubjectName>
								<Text size='medium' bold data-placeholder-value=''>
									{name}
								</Text>
							</SelectOption>
						))}
					</SelectUI.Content>
				</SelectUI.Root>
			)}

			{options.length === 1 && <SubjectName subjectLevel={options[0]!.level}>{options[0]!.name}</SubjectName>}
		</RootWrapper>
	);
}
