import {Route} from 'pages/assignment-center/hooks/use-navigation';
import {NavLink} from 'react-router-dom';
import styles from './styles.module.less';
import {StudentCredentialsSearchData} from '../../../../../student-credentials/types/types';

type CredentialsBodyCellProps = {
	hasCredentials: boolean;
	studentId: number;
};

export function CredentialsBodyCell({hasCredentials, studentId}: CredentialsBodyCellProps) {
	const addCredentialsSearch = new URLSearchParams({
		[StudentCredentialsSearchData.StudentId]: String(studentId),
	});

	if (hasCredentials) {
		return <>Yes</>;
	}

	return (
		<NavLink
			to={{
				pathname: Route.StudentCredentials,
				search: addCredentialsSearch.toString(),
			}}
			className={styles.navLink}
		>
			Add
		</NavLink>
	);
}
