import {Tooltip} from '@esgi/ui';
import {Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {IconButton} from '../index.styled';

type Props = {
	disabled: boolean;
	onClick: VoidFunction;
};

export function AddButton({disabled, onClick}: Props) {
    return (
        <Tooltip>
            <Tooltip.Trigger>
                <IconButton onClick={onClick} disabled={disabled}>
                    <Plus />
                </IconButton>
            </Tooltip.Trigger>
            <Tooltip.Content>
                <Text size='xSmall' font='mono' color='tertiary'>
                    Add New Student
                </Text>
            </Tooltip.Content>
        </Tooltip>
    );
}