import {GridBox} from '@esgi/ui/layout';
import {ResultHeaderRow, Table} from '../index.styled';
import {Text} from '@esgi/ui/typography';
import {LoadMoreButton} from '../../../load-more-button';
import {StudentInfo} from '../../../../../../class-group/types';
import {TestInfo} from '../../../../../../types';
import {StudentInfoRow} from './student-info-row';
import {StudentSingleSessionRow} from './student-single-session-row';
import {StudentNotTestedRow} from './student-not-tested-row';
import {Student} from '@esgi/main/libs/store';
import {Dispatch} from 'react';

type Props = {
	students: StudentInfo[];
	testInfo: TestInfo;
	notVisibleStudentsCount: number;
	onLoadMoreClick: VoidFunction;
	onOpenTestSessionDetailsAlert: (args: {sessionID: number; studentID: Student['id']}) => void;
	onOpenAddSessionDetailsAlert: (studentID: Student['id']) => void;
	onOpenTestingStudent: (studentID: Student['id']) => void;
	onOpenStudentTestDashboard: Dispatch<Student['id']>;
	isTablet: boolean;
};

export function ScoreTable({
	students,
	testInfo,
	notVisibleStudentsCount,
	onLoadMoreClick,
	onOpenTestSessionDetailsAlert,
	onOpenAddSessionDetailsAlert,
	onOpenTestingStudent,
	onOpenStudentTestDashboard,
	isTablet,
}: Props) {
	return (
		<Table dataCy='table' isTablet={isTablet}>
			<GridBox dataCy='student-name-table-header'>
				<Text size='xSmall' font='mono' color='mediumContrast'>
					Student
				</Text>
			</GridBox>
			<GridBox dataCy='session-date-table-header'>
				<Text size='xSmall' font='mono' color='mediumContrast'>
					Session Date
				</Text>
			</GridBox>
			<ResultHeaderRow dataCy='last-session-result-table-header'>
				<Text size='xSmall' font='mono' color='mediumContrast'>
					Latest Score
				</Text>
			</ResultHeaderRow>

			{students.map((student, index) => {
				if (!student.testSessionsInfo.testSessions.length) {
					return (
						<StudentNotTestedRow
							firstName={student.firstName}
							lastName={student.lastName}
							onAddSessionClick={() => onOpenAddSessionDetailsAlert(student.id)}
							onTestClick={() => onOpenTestingStudent(student.id)}
							onOpenStudentTestDashboard={() => onOpenStudentTestDashboard(student.id)}
							key={student.id}
							index={index}
						/>
					);
				}

				if (student.testSessionsInfo.testSessions.length === 1) {
					const session = student.testSessionsInfo.testSessions[0]!;

					return (
						<StudentSingleSessionRow
							session={session}
							student={student}
							sessionResult={{
								value: session.correctAnswers,
								maxValue: testInfo.totalPossible,
							}}
							onOpenTestSessionDetailsAlert={(sessionID) =>
								onOpenTestSessionDetailsAlert({sessionID, studentID: student.id})
							}
							onOpenStudentTestDashboard={() => onOpenStudentTestDashboard(student.id)}
							withTestButton
							onTestClick={() => onOpenTestingStudent(student.id)}
							key={student.id}
							isTablet={isTablet}
							index={index}
						/>
					);
				}

				return (
					<StudentInfoRow
						student={student}
						sessions={student.testSessionsInfo.testSessions}
						sessionResult={{
							valueKey: 'correctAnswers',
							maxValue: testInfo.totalPossible,
						}}
						onOpenTestSessionDetailsAlert={(sessionID) =>
							onOpenTestSessionDetailsAlert({sessionID, studentID: student.id})
						}
						onTestClick={() => onOpenTestingStudent(student.id)}
						onOpenStudentTestDashboard={() => onOpenStudentTestDashboard(student.id)}
						key={student.id}
						isTablet={isTablet}
						index={index}
					/>
				);
			})}

			<LoadMoreButton itemsCount={notVisibleStudentsCount} onClick={onLoadMoreClick} />
		</Table>
	);
}
