import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const Container = styled(FlexBox, {
	minWidth: '100%',
	flexFlow: 'row nowrap',
	justifyContent: 'center',
});

export const Chart = styled(FlexBox, {
	height: '150px',
	width: '200px',
	justifyContent: 'center',
});

export const Graph = styled(Box, {
	width: 'calc(100% - 200px)',
	height: '200px',
	padding: '5px',
});
