import {isUndefined} from '@esgi/ui';
import moment from 'moment';

export function momentDatesDiffInDays(date1: moment.Moment | undefined, date2: moment.Moment | undefined) {
	if (isUndefined(date1)) {
		throw new Error('date1 is undefined');
	}

	if (isUndefined(date2)) {
		throw new Error('date2 is undefined');
	}

	return date1.diff(date2, 'days');
}
