import {Route} from '../../hooks/use-navigation';
import {AssignmentInfo} from '../../services/types';
import {AssignmentStatus} from '../../typings/assignment';

export function mapStatusFromRoute(route: Route): AssignmentStatus {
	switch (route) {
		case Route.ListInProgress:
			return AssignmentStatus.Published;
		case Route.ListNotStarted:
			return AssignmentStatus.NotStarted;
		case Route.ListCompleted:
			return AssignmentStatus.Completed;
		case Route.ListDraft:
			return AssignmentStatus.Draft;
		default:
			return undefined;
	}
}

export function filterByStatus(status: string, source: AssignmentInfo[]): AssignmentInfo[] {
	return status ? source.filter((assignment) => assignment.state.toLowerCase() === status?.toLowerCase()) : source;
}
