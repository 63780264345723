import {useEffect, useState} from 'react';
import {BaseTrackDateModel, SchoolYearModel, TrackDateModelState, TrackType} from '../../types';
import moment from 'moment';
import {TrackDatePicker} from '../track-date-picker';
import {generateRandomString} from '@esgi/ui';
import {
	ContentBoxWrapper,
	Counter,
	DotsContainer,
	MonthLine,
	MonthsLine,
	SliderRange,
	SliderRoot,
	SliderThumb,
	SliderTrack,
	ThumbDot,
	TrackDatesRow,
	TrackDatesWrapper,
} from './index.styled';
import {createDateForState} from './helpers/create-date-for-state';
import {addDaysToMomentDate} from './helpers/add-days-to-moment-date';
import {momentDatesDiffInDays} from './helpers/moment-dates-diff-in-days';
import {minStepsBetweenThumbs} from './constants';
import {VariantProps} from '@stitches/react';
import {Text} from '@esgi/ui/typography';
import {useTrackStartEndDates} from './hooks/use-track-start-end-dates';
import {useTrackDatesHandlers} from './hooks/use-track-dates-handlers';

type Props = {
	schoolYearTypes: SchoolYearModel[];
	defaultTrackType: TrackType;
	defaultTrackDates: BaseTrackDateModel[];
	selectedTrackType: TrackType;
	withDateSlider?: boolean;
	datesInRowsState: TrackDateModelState[];
	setDatesInRowsState: React.Dispatch<React.SetStateAction<TrackDateModelState[]>>;
	counterItemSize?: VariantProps<typeof Counter>['size'];
	itemRowGap?: VariantProps<typeof TrackDatesWrapper>['rowGap'];
};

type MonthModel = {
	width: number;
	name: string;
};

export function TrackDatesWithSlider({
	schoolYearTypes,
	defaultTrackType,
	defaultTrackDates,
	selectedTrackType,
	withDateSlider,
	datesInRowsState,
	setDatesInRowsState,
	counterItemSize = 'medium',
	itemRowGap = '20',
}: Props) {
	const [startDate, setStartDate] = useState<moment.Moment>();
	const [endDate, setEndDate] = useState<moment.Moment>();

	const [sliderValue, setSliderValue] = useState<number[]>([]);
	const [monthsList, setMonthsList] = useState<MonthModel[]>([]);
	const [isSliderUpdateDates, setIsSliderUpdateDates] = useState(false);

	useEffect(() => {
		if (!startDate) {
			return;
		}

		const yearInfo = schoolYearTypes.find(({name}) => name === selectedTrackType);

		if (!yearInfo) {
			throw new Error(`Year info isn't found`);
		}

		const iteratedDates = selectedTrackType !== defaultTrackType ? yearInfo.defaultTrackDates : defaultTrackDates;

		const newSliderValue = iteratedDates.reduce<number[]>((acc, track, index) => {
			const daysBetweenStartAndDateFrom = momentDatesDiffInDays(moment(track.dateFrom), startDate);
			const daysBetweenStartAndDateTo = momentDatesDiffInDays(moment(track.dateTo), startDate);

			if (!index) {
				return [daysBetweenStartAndDateFrom, daysBetweenStartAndDateTo];
			}

			return [...acc, daysBetweenStartAndDateTo];
		}, []);

		setSliderValue(newSliderValue);

		const datesInRows: TrackDateModelState[] = [];

		newSliderValue.forEach((daysFromStart, index, iteratedValues) => {
			const date = addDaysToMomentDate(startDate, daysFromStart);

			if (!index) {
				datesInRows[index] = {
					rowId: generateRandomString(),
					dateFrom: createDateForState({value: date.toDate()}),
					dateTo: createDateForState(),
				};

				return;
			}

			datesInRows[index - 1]!.dateTo.value = date.toDate();

			if (index !== iteratedValues.length - 1) {
				datesInRows[index] = {
					rowId: generateRandomString(),
					dateFrom: createDateForState({value: addDaysToMomentDate(date, 1).toDate(), disabled: true}),
					dateTo: createDateForState(),
				};
			}
		});

		setDatesInRowsState(datesInRows);
	}, [startDate, selectedTrackType, schoolYearTypes, setDatesInRowsState, defaultTrackDates, defaultTrackType]);

	const {sliderMaxDays, sliderMinDays} = useTrackStartEndDates({
		schoolYearTypes,
		selectedTrackType,
		setEndDate,
		setMonthsList,
		setStartDate,
	});

	const {handleDateChange, handleSliderValueChange} = useTrackDatesHandlers({
		datesInRowsState,
		endDate,
		setDatesInRowsState,
		setIsSliderUpdateDates,
		setSliderValue,
		sliderMaxDays,
		sliderMinDays,
		sliderValue,
		startDate,
	});

	return (
		<ContentBoxWrapper withDateSlider={Boolean(withDateSlider)}>
			{withDateSlider && (
				<>
					<MonthsLine>
						<MonthLine css={{width: 1}} />
						{monthsList.map(({width, name}, index) => (
							<MonthLine css={{width: `${width}%`}} key={index} dataCy='months-line-item'>
								<Text size='xSmall' font='mono' color='neutral72'>
									{name}
								</Text>
							</MonthLine>
						))}
					</MonthsLine>

					<SliderRoot
						value={sliderValue}
						step={1}
						minStepsBetweenThumbs={minStepsBetweenThumbs}
						min={sliderMinDays}
						max={sliderMaxDays}
						onValueChange={handleSliderValueChange}
						onValueCommit={() => {
							setIsSliderUpdateDates(false);
						}}
					>
						<SliderTrack>
							<SliderRange />
						</SliderTrack>

						{sliderValue.map((_, index) => (
							<SliderThumb key={index}>
								<DotsContainer>
									<ThumbDot />
									<ThumbDot />
									<ThumbDot />
								</DotsContainer>
							</SliderThumb>
						))}
					</SliderRoot>
				</>
			)}

			<TrackDatesWrapper rowGap={itemRowGap}>
				{datesInRowsState.map(({dateFrom, dateTo, rowId}, index) => (
					<TrackDatesRow key={rowId} dataCy='track-dates-row'>
						<Counter size={counterItemSize}>
							<Text data-cy='item-counter' size='large' color='neutral40'>
								{`${index + 1}`.padStart(2, '0')}
							</Text>
						</Counter>

						<TrackDatePicker
							disabled={dateFrom.disabled}
							error={dateFrom.error}
							value={dateFrom.value}
							onValueChange={(value) => {
								handleDateChange({rowId, rangeType: 'dateFrom', value});
							}}
							placeholder='From'
							isSliderUpdateDates={isSliderUpdateDates}
						/>

						<TrackDatePicker
							disabled={dateTo.disabled}
							error={dateTo.error}
							value={dateTo.value}
							onValueChange={(value) => {
								handleDateChange({rowId, rangeType: 'dateTo', value});
							}}
							placeholder='To'
							isSliderUpdateDates={isSliderUpdateDates}
						/>
					</TrackDatesRow>
				))}
			</TrackDatesWrapper>
		</ContentBoxWrapper>
	);
}
