import {ContextMenu, Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {ContextMenuItem} from './index.styled';

export interface MenuItems {
	text: string;
	value: number | string;
	description?: string;
}

interface Props {
	menuItems: MenuItems[];
	onClick: (value: MenuItems['value']) => void;
}

export function DownloadButton({menuItems, onClick}: Props) {
	return (
		<ContextMenu.Root>
			<ContextMenu.Trigger>
				<Button dataCy='download-button' color='primary'>
					<Text data-cy='download-label' size='medium' bold>
						Download
					</Text>
				</Button>
			</ContextMenu.Trigger>
			<ContextMenu.Content>
				<ContextMenu.Group>
					{menuItems.map(({text, value, description}) => (
						<ContextMenuItem
							key={value}
							onClick={() => onClick(value)}
						>
							<GridBox>
								<Text size='medium' bold>{text}</Text>
								{description && <Text size='small' color='neutral56'>{description}</Text>}
							</GridBox>
						</ContextMenuItem>
					))}
				</ContextMenu.Group>
			</ContextMenu.Content>
		</ContextMenu.Root>
	);
}
