import {Box, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {AnswerBarBoxHorizontal} from '../../answer-bar-box';
import {Tooltip} from '@esgi/ui/tooltip';
import {TooltipContent} from '../../tooltip-content';
import {useCallback, useState} from 'react';

type Props = {
	sessionsCount: number;
	correct: number;
	incorrect: number;
	questionTitle: string;
	questionNumber: number;
	barWidth: number;
	barHeight: number;
	isTablet: boolean;
};

export function TestContentRow({
	sessionsCount,
	correct,
	incorrect,
	questionTitle,
	questionNumber,
	barWidth,
	barHeight,
	isTablet,
}: Props) {
	const [openTooltip, setOpenTooltip] = useState<boolean>(false);
	const onClick = useCallback(() => {
		if(isTablet) {
			setOpenTooltip(true);
		}
	}, [isTablet]);

	return (
		<>
			<GridBox dataCy={`question-number-${questionNumber}`} justify='end' align='center' css={{minHeight: barHeight}}>
				<Text data-cy={`question-number-title-${questionNumber}`} size='xSmall' font='mono' color='lowContrast'>
					{questionNumber}
				</Text>
			</GridBox>
			<GridBox dataCy={`question-title-${questionNumber}`} justify='start' align='center' css={{minHeight: barHeight}}>
				<Text data-cy={`question-name-${questionNumber}`} size='small' color='neutral40'>
					{questionTitle}
				</Text>
			</GridBox>

			<Tooltip delayDuration={0} open={openTooltip} onOpenChange={setOpenTooltip}>
				<Tooltip.Trigger>
					<Box dataCy={`bar-${questionNumber}`} onClick={onClick}>
						<AnswerBarBoxHorizontal
							width={barWidth}
							height={barHeight}
							correct={correct}
							incorrect={incorrect}
							sessionsCount={sessionsCount}
						/>
					</Box>
				</Tooltip.Trigger>
				<Tooltip.Content
					sideOffset={0}
					css={{
						border: 'none',
						padding: 0,
						backgroundColor: 'unset',
					}}
				>
					<TooltipContent sessionsCount={sessionsCount} correct={correct} incorrect={incorrect} />
				</Tooltip.Content>
			</Tooltip>
		</>
	);
}
