import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {UntestedStudentsReportService} from '../../../service';
import styles from '../../modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService
}

export function ReportSubjectTab({service}: Props) {
	const selectedSubject = useBehaviorSubject(service.selectedSubject);
	const emptyReport = useBehaviorSubject(service.emptyReport);
	const subjects = useBehaviorSubject(service.subjects);
	const tests = useBehaviorSubject(service.tests);
	const selectedTest = useBehaviorSubject(service.selectedTest);
	return !emptyReport &&
		<>
			<div>
				<span className={styles.bold}>Subject Tab:</span>
				<Dropdown value={selectedSubject} setValue={(v) => service.onSubjectChanged(v)}
				          optionName='name' className={styles.fluid} autoWidth={true}>
					{subjects.map(subject => <Option key={subject.subjectID}
					                                 value={subject}>{subject.name}</Option>)}
				</Dropdown>
			</div>
			{selectedSubject && selectedSubject[0]?.tests.length > 0 &&
				<div>
					<span className={styles.bold}>Test:</span>
					<Dropdown value={selectedTest} setValue={(v) => service.onSelectedTestChanged(v)}
					          optionName='name'
					          className={styles.fluid} autoWidth={true}>
						{tests.map(test => <Option key={test.testID} value={test}>{test.name}</Option>)}
					</Dropdown>
				</div>
			}
		</>;
}
