import React, {ReactElement} from 'react';
import {Observable, Subject} from 'rxjs';

type ModalComponentType = ReactElement | JSX.Element;
export interface IModalRef{
	close();
}
class ModalRef implements IModalRef {
	public close = (): void => {
		ModalManager.instance.closeModal(this);
	};
}

interface IOpenModalRequest {
	ref: IModalRef,
	component: ModalComponentType,
}

interface ICloseModalRequest {
	ref: IModalRef,
}

class ModalManager {
	private static _instance: ModalManager;
	private modals: IModalRef[];
	private onOpenModalRequestEmitter: Subject<IOpenModalRequest>;
	private onCloseModalRequestEmitter: Subject<ICloseModalRequest>;

	private constructor() {
    	this.modals = [];
    	this.onOpenModalRequestEmitter = new Subject<IOpenModalRequest>();
    	this.onCloseModalRequestEmitter = new Subject<ICloseModalRequest>();
	}

	public static get instance(): ModalManager {
    	let instance = this._instance;
    	if (!instance) {
    		instance = this._instance = new ModalManager();
    	}
    	return instance;
	}
    
	public openModal(component: ModalComponentType): IModalRef {
    	const ref = new ModalRef();
    	this.modals.push(ref);
    	this.onOpenModalRequestEmitter.next({ref: ref, component: component});
    	return ref;
	}

	public closeModal(ref: IModalRef) {
    	const _ref = this.modals.find(i => i === ref);
    	if (_ref) {
    		this.modals = this.modals.filter(i => i !== _ref);
    		this.onCloseModalRequestEmitter.next({ref: _ref});
    	} else {
    		console.warn('Modal Manager:\n closeModal called on already closed modal');
    	}
	}

	public get onOpenRequest(): Observable<IOpenModalRequest> {
    	return this.onOpenModalRequestEmitter.asObservable();
	}

	public get onCloseRequest(): Observable<ICloseModalRequest> {
    	return this.onCloseModalRequestEmitter.asObservable();
	}

	public destroy(): void {
    	this.onOpenModalRequestEmitter.complete();
    	this.onCloseModalRequestEmitter.complete();
    	ModalManager._instance = null;
	}
}

export function openModal(component: ModalComponentType): IModalRef {
	return ModalManager.instance.openModal(component);
}

class State {
	modals: { component: ModalComponentType, ref: IModalRef }[] = [];
}

export class ModalDOMHolder extends React.Component<any, State> {

	constructor(props) {
		super(props);
		this.state = new State();

		ModalManager.instance.onOpenRequest.subscribe(rq => {
			const modals = this.state.modals;
			modals.push({component: rq.component, ref: rq.ref});
			this.setState({modals: [...modals]});
		});

		ModalManager.instance.onCloseRequest.subscribe(rq => {
			let modals = this.state.modals;
			modals = modals.filter(m => m.ref !== rq.ref);
			this.setState({modals: modals});
		});
	}
    
	render() {
		return <>
			{this.state.modals.map((m, i) => React.cloneElement(m.component, {key: i, ...m.component.props}))}
		</>;
	}

	componentWillUnmount() {
		ModalManager.instance.destroy();
	}
}
