import {styled} from '@stitches/react';
import {Box, FlexBox} from '@esgi/ui/layout';
import {ToggleGroup} from '@esgi/ui/controls';

export const ProfileBody = styled(FlexBox, {
	width: '100%',
	height: '100%',
	alignItems: 'start',
	padding: '0 20px',
	flexDirection: 'column',
	flex: 1,
});

export const QuestionsWrapper = styled(Box, {
	flex: 1,
	width: '100%',
});

export const DrawerContainer = styled(FlexBox, {
	flexDirection: 'column',
	height: '100%',
});

export const TestInfoContainer = styled(FlexBox, {
	width: '100%',
	height: '100%',
	padding: '20px 0',
	flexDirection: 'column',
	flex: '0',
});

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr',
});
