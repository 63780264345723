import React from 'react';
import {Level} from '../../../../../common/types';
import styles from './level.module.less';

interface Props {
	level: Level;
	highest: boolean;
}

export function LevelView ({level, highest}: Props) {
	return (
		<div className={styles.box}>
			<div className={styles.header}>
				<span>Level {level.score} {highest && '(highest)' || ''}</span>
			</div>
			<div className={styles.level}>
				<span>{level.name}</span>
			</div>
		</div>
	);
}
