import {ChangeFunction} from '../types';
import {SubjectTab} from '@esgi/main/libs/store';
import {SelectField, SelectOption} from './index.styled';
import {Select} from '@esgi/ui/controls';
import {Skeletonable} from '@esgi/ui';
import {SubjectName} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';

interface Props extends Skeletonable {
	subjects: SubjectTab[];
	value: string;
	disabled?: boolean;
	onChange: ChangeFunction<string>;
}

export function SubjectControl(props: Props) {
	const {skeleton, subjects, value, disabled, onChange} = props;

	return (
		<Select.Root
			dataCy='subject-control-root'
			value={value}
			disabled={disabled}
			onValueChange={(value) => onChange(value)}
		>
			<SelectField data-cy='subject-control-select-field' skeleton={skeleton} placeholder='Subject Tab' />
			<Select.Content dataCy='subject-control-item'>
				{subjects.map(({id, name, level}) => (
					<SelectOption key={id} value={id.toString()}>
						<SubjectName subjectLevel={level}>{name}</SubjectName>
						<Text size='medium' bold data-placeholder-value=''>
							{name}
						</Text>
					</SelectOption>
				))}
			</Select.Content>
		</Select.Root>
	);
}
