import {join} from '@esgi/ui/utils';
import {Radio} from '@esgillc/ui-kit/control';
import styles from '../../../settings/styles.module.less';
import {Dispatch} from 'react';

interface Props {
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function DetailReportSettingsDisplayZero({value, onChange}: Props) {
	return (
		<div className={join(styles.settingsCell, styles.flexColumn)}>
			<span className={styles.title}>Display not tested as:</span>
			<div className={styles.radioGroup}>
				<Radio
					data-cy='zero-option'
					checked={value}
					value='Zero'
					onChange={() => onChange(true)}
				>
					Zero
				</Radio>
				<Radio
					data-cy='nt-option'
					checked={!value}
					value='NT'
					onChange={() => onChange(false)}
				>
					NT
				</Radio>
			</div>
		</div>
	);
}
