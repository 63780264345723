import {Class, Student} from '@esgi/main/libs/store';
import {Dispatch, useMemo} from 'react';
import {ExpandableList} from './list-variant/expandable-list';
import {EmptyContentTemplate} from '@esgi/main/features/teacher/home';

type Props = {
	classes: Class[];
	visibleStudents: Student[];
	onStudentClick: Dispatch<Student['id']>;
	selectedStudentsIDs: Student['id'][];
	isOpenFirstClassOnInit?: boolean | undefined;
	toggleEntityStudents: Dispatch<{studentsIDs: Student['id'][]; method: 'add' | 'remove'}>;
};

export function ClassesContent({
	classes,
	visibleStudents,
	onStudentClick,
	selectedStudentsIDs,
	isOpenFirstClassOnInit,
	toggleEntityStudents,
}: Props) {
	const classesWithStudents = useMemo(
		() =>
			classes.map(({studentIDs, ...classModel}) => ({
				...classModel,
				students: visibleStudents.filter(({id}) => studentIDs.includes(id)),
			})),
		[classes, visibleStudents],
	);

	if (!classesWithStudents.length) {
		return <EmptyContentTemplate size='full' templateText='No classes to display' />;
	}

	return (
		<>
			{classesWithStudents.map(({id, name: classLabel, students}, index) => (
				<ExpandableList
					key={id}
					onStudentClick={onStudentClick}
					students={students}
					triggerLabel={classLabel}
					selectedStudentsIDs={selectedStudentsIDs}
					initialOpened={Boolean(isOpenFirstClassOnInit) && index === 0}
					toggleEntityStudents={toggleEntityStudents}
				/>
			))}
		</>
	);
}
