import {ComponentProps, PropsWithChildren} from 'react';
import {CentralPanelBody, CentralPanelDivider, CentralPanelTablesContainer} from '../../central-panel-body';
import {AssignmentDetailsCard, AssignmentState, SortDirection} from '@esgi/main/kits/assignments';
import {EmptyContent} from '../../empty-content';
import {StudentModel} from '../../../types';
import {StudentsTable} from './students-table';

type Props = PropsWithChildren<{
	name: string;
	state: AssignmentState;
	description: string;
	testsCount: number;
	displayedTime: string | Date | null;
	displayedTimeType: ComponentProps<typeof AssignmentDetailsCard.Body>['cardDateTranscript'];
	students: StudentModel[];
	sortStudentsByKey: (args: {key: keyof StudentModel; sortDirection: SortDirection}) => void;
	onOpenStudentCredentials: (studentId: StudentModel['studentID']) => void;
	onOpenCredentialsForStudentsInAssignment: VoidFunction;
}>;

export function AssignmentBody({
	children,
	name,
	state,
	description,
	testsCount,
	displayedTime,
	displayedTimeType,
	students,
	sortStudentsByKey,
	onOpenStudentCredentials,
	onOpenCredentialsForStudentsInAssignment,
}: Props) {
	const showEmptyContent = !testsCount && !students.length;

	return (
		<CentralPanelBody>
			<AssignmentDetailsCard.Root>
				<AssignmentDetailsCard.Header name={name} state={state} />
				<AssignmentDetailsCard.Divider />
				<AssignmentDetailsCard.Body
					cardDate={displayedTime}
					cardDateTranscript={displayedTimeType}
					studentsCount={students.length}
					testsCount={testsCount}
					description={description}
				/>
			</AssignmentDetailsCard.Root>

			{showEmptyContent ? (
				<EmptyContent messageText='No data to display' />
			) : (
				<CentralPanelTablesContainer>
					{children}
					<CentralPanelDivider />
					<StudentsTable
						sortStudentsByKey={sortStudentsByKey}
						students={students}
						testsCount={testsCount}
						onOpenStudentCredentials={onOpenStudentCredentials}
						onOpenCredentialsForStudentsInAssignment={onOpenCredentialsForStudentsInAssignment}
					/>
				</CentralPanelTablesContainer>
			)}
		</CentralPanelBody>
	);
}
