import React from 'react';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import CustomGradeRange from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/component';
import SharedGradeRange from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/component';
import {userStorage} from '@esgi/core/authentication';
import {FormState} from 'shared/modules/grade-scale/models';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import {CustomSharedService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/service';
import {Subscription} from 'rxjs';

export class Props {
	testID: number;
	subjectID: number;
	gradeScaleID: number;
	step: WizardStep;
	sourceStep: WizardStep = WizardStep.None;
	initialStep: WizardStep = WizardStep.None;
	onClosed: () => void;
	onSaved: () => void;
	onCanceled: () => void;
	onGoToStep: (step: WizardStep, subjectID: number, testID: number, gradeScaleID?: number) => void;
	service: CustomSharedService;
}

export class State {
	showNotCustomizedAllTestsAlert: boolean;
	selectedSubjectName: string;
}

export default class CustomSharedGradeRange extends React.PureComponent<Props, State> {
	subscribers: Subscription[] = [];

	constructor(props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		this.subscribers.push(this.props.service.formState$.subscribe(fs => {
			if (fs === FormState.Saved) {
				this.props.service.setFormState(FormState.None);
				this.props.onSaved();
			}

			if (fs === FormState.Canceled) {
				this.props.service.setFormState(FormState.None);
				this.props.onCanceled();
			}

			if (fs === FormState.Closed) {
				this.props.service.setFormState(FormState.None);
				this.props.onClosed();
			}
		}));

		this.subscribers.push(this.props.service.showNotCustomizedAllTestsAlert$.subscribe(show => {
			this.setState({showNotCustomizedAllTestsAlert: show});
		}));

		this.subscribers.push(this.props.service.selectedSubject$.subscribe(subject => {
			if (subject) {
				this.setState({selectedSubjectName: subject.title});
			}
		}));

		this.props.service.setStep(this.props.step);
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.step !== this.props.step) {
			this.props.service.setStep(this.props.step);
		}
	}

	componentWillUnmount() {
		this.subscribers.map(s => s.unsubscribe());
	}

	renderNotCustomizedAllTestsAlert() {
		return <Modal animate={true}
                  className={'range-not-selected-alert white-header responsive alert-modal-white'}>
			<ModalHeader>
				<h3>Save With Default Percent Range?</h3>
			</ModalHeader>
			<ModalBody>
						<span>
							<p>You have not customized all tests in {this.state.selectedSubjectName}.</p>
							<p>Tests that have not been customized will use the default percentage range.</p>
						</span>
			</ModalBody>
			<ModalFooter>
				<Transparent
					onClick={() => this.props.service.saveNotCustomizedAllTests(true)}
					title='Save Anyway'
				/>
				<Transparent
					onClick={() => this.props.service.saveNotCustomizedAllTests(false)}
					title='Cancel'
				/>
			</ModalFooter>
		</Modal>;
	}

	renderStep() {
		switch (this.props.step) {
			case WizardStep.CustomGradeRange: {
				return <CustomGradeRange
					service={this.props.service.customGradeRangeService}
					subjectID={this.props.subjectID}
					testID={this.props.testID}
					onGoToStep={(step, subjectID, testID, gradeScaleID) =>
						this.props.onGoToStep(step, subjectID, testID, gradeScaleID)}
					sourceStep={this.props.sourceStep}
					userType={userStorage.get().userType}
				/>;
			}

			case WizardStep.SharedGradeRange: {
				return <SharedGradeRange
					service={this.props.service.sharedGradeRangeService}
					subjectID={this.props.subjectID}
					testID={this.props.testID}
					initialStep={this.props.initialStep}
					onGoToStep={(step, subjectID, testID) =>
						this.props.onGoToStep(step, subjectID, testID)}
					gradeScaleID={this.props.gradeScaleID}
				/>;
			}

			default:
				return <></>;
		}
	}

	render() {
		return <>
			{this.state.showNotCustomizedAllTestsAlert && this.renderNotCustomizedAllTestsAlert()}
			{this.renderStep()}
		</>;
	}
}
