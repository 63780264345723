import {useMemo} from 'react';
import {OptionItem} from '../../select/types';
import {SortByOption, TestSectionContentType} from '../../../types';

export function useSortByOptions({contentType}: {contentType: TestSectionContentType}) {
	return useMemo<OptionItem<SortByOption>[]>(
		() => [
			{
				label: 'Default',
				value: SortByOption.Default,
				permitted: true,
			},
			{
				label: 'Test Name',
				value: SortByOption.TestName,
				permitted: true,
			},
			{
				label: 'Last Tested',
				value: SortByOption.LastTested,
				permitted: true,
			},
			{
				label: 'Content Area',
				value: SortByOption.ContentArea,
				permitted: true,
			},
			{
				label: 'Number of Questions',
				value: SortByOption.NumberOfQuestions,
				permitted: true,
			},
			{
				label: 'Students Tested Percentage',
				value: SortByOption.StudentsTestedPercentage,
				permitted: contentType === TestSectionContentType.GroupStudents,
			},
		],
		[contentType],
	);
}
