import {PurchaseFormTemplate} from 'pages/landing/kit/purchase-form/template';
import {ButtonTemplate} from 'pages/landing/kit/modal/templates';


export class PurchaseTemplate {
	static render() {
		return <div data-bind='afterRender: true, var: {step: $data}'>
			<ko data-bind='with: purchaseForm'>
				{PurchaseFormTemplate.render()}
			</ko>
			<div className='clearfix' style={{marginTop: '25px'}}>

				<ko data-bind='with: purchaseButton'>
					{ButtonTemplate.render()}
				</ko>
			</div>
			<aside>
				<div className='order-details'>
					<div className='title'>Order Summary</div>
					<ko>
						<section>
							<div className='order-detail'>
								<div className='row'>
									<div className='col-md-6 section-name'>Subscriptions</div>
									<div className='col-md-3 section-name'>Quantity</div>
									<div className='col-md-3 section-name'>Amount</div>
								</div>
								<ko data-bind='foreach: orderSummary.items'>

									<div className='row'>
										<div className='col-md-6'><span
											data-bind="text: name + ' (' + months + 'mo)'"></span></div>
										<div className='col-md-3'><span data-bind='text: quantity'></span></div>
										<div className='col-md-3'>$<span data-bind='text: amount'></span></div>
									</div>
								</ko>
							</div>
						</section>
					</ko>
					<h3 className='quote-total'><b>Total: $<span data-bind='text: orderSummary.total'></span></b>
					</h3>
				</div>
			</aside>
		</div>;
	}
}
