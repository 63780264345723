import {Box, SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';

export const selectableListItemMargin = 8;

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	padding: '7px 11px',

	'&:not(last-child)': {
		marginBottom: '6px',
		marginRight: `${selectableListItemMargin}px`,
	},

	variants: {
		columnView: {
			true: {
				padding: '11px',
				'&:not(last-child)': {
					margin: '0px',
				},
			},
		},
		disabled: {
			true: {
				color: '$muted',
				backgroundColor: '$vivid',
				cursor: 'default',
				pointerEvents: 'none',
				[`& ${Text}`]: {
					'&:not(hover)': {
						color: 'currentColor !important',
					},
				},
			},
		},
	},
});

export const Wrapper = styled(Box, {
	display: 'flex',
	flexWrap: 'wrap',
	width: '100%',

	variants: {
		columnView: {
			true: {
				display: 'grid',
				gap: '$2',
			},
		},
	},
});

export const StyledButton = styled(Button, {
	minWidth: 'auto',
	height: 'fit-content',
	padding: '11px 12px',
});

export const StyledText = styled(Text, {
	variants: {
		selected: {
			false: {
				color: '$neutral6 !important',
			},
			true: {
				color: 'currentСolor',
			},
		},
	},
});
