import {OverlayScrollbars} from 'overlayscrollbars';

function getCurrentPos(osInstance: OverlayScrollbars): {x: number, y: number} {
	if(osInstance) {
		return {
			x: osInstance.elements().viewport.scrollLeft,
			y: osInstance.elements().viewport.scrollTop,
		};
	}
}

export function scrollYUp(osInstance: OverlayScrollbars, cellHeight: number) {
	if (osInstance) {
		const offsetY = (osInstance.elements().viewport.scrollTop % cellHeight) || cellHeight;
		const {y} = getCurrentPos(osInstance);
		scrollTo(osInstance, {y: y - offsetY});
	}
}

export function scrollYDown(osInstance: OverlayScrollbars, cellHeight: number) {
	if (osInstance) {
		const offsetY = Math.abs(osInstance.elements().viewport.scrollTop % cellHeight - cellHeight);
		const {y} = getCurrentPos(osInstance);
		scrollTo(osInstance, {y: y + offsetY});
	}
}

export function scrollYToBottom(osInstance: OverlayScrollbars) {
	if (osInstance) {
		const y = osInstance.elements().viewport.scrollHeight;
		scrollTo(osInstance, {y});
	}
}

export function scrollYToTop(osInstance: OverlayScrollbars) {
	if (osInstance) {
		scrollTo(osInstance, {y: 0});
	}
}

export function scrollXRight(osInstance: OverlayScrollbars, cellWidth: number): void {
	if (osInstance) {
		const offsetX = Math.abs(Math.round(osInstance.elements().viewport.scrollLeft % cellWidth) - cellWidth);
		const {x} = getCurrentPos(osInstance);
		scrollTo(osInstance, {x: x + offsetX});
	}
}

export function scrollXLeft(osInstance: OverlayScrollbars, cellWidth: number): void {
	if (osInstance) {
		const offsetX = (osInstance.elements().viewport.scrollLeft % cellWidth) || cellWidth;
		const {x} = getCurrentPos(osInstance);
		scrollTo(osInstance, {x: x - offsetX});
	}
}

export function scrollXStart(osInstance: OverlayScrollbars): void {
	if (osInstance) {
		scrollTo(osInstance, {x: 0});
	}
}

export function scrollTo(osInstance: OverlayScrollbars, options: Partial<{x: number, y: number}>): void {
	setTimeout(() => {
		osInstance.elements().viewport.scroll({
			left: +options.x,
			top: +options.y,
			behavior: 'smooth',
		});
	}, 60);
}

export function scrollToElement(element: HTMLElement, osInstance: OverlayScrollbars, elementTopOffset: number, elementLeftOffset: number): void {
	if(!osInstance) {
		return;
	}

	const content = osInstance.elements().content;
	const scrollBounds = content?.getBoundingClientRect() as DOMRect;
	const elementBounds = element.getBoundingClientRect();

	const relativeTop = Math.round(elementBounds.top - scrollBounds.top) - elementTopOffset;
	const relativeLeft = Math.round(elementBounds.left - scrollBounds.left) - elementLeftOffset;

	osInstance.elements().viewport.scroll({left: relativeLeft, top: relativeTop, behavior: 'smooth'});
}
