import {dispatchAppEvent} from '@esgillc/events';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import React, {ReactNode} from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import moment from 'moment';
import {Loader} from '@esgi/deprecated/jquery';
import {BaseDropdown, Option} from '@esgi/deprecated/ui-kit/dropdown';
import {userStorage} from '@esgi/core/authentication';
import {SsoTracker} from '@esgi/core/tracker';
import {TestSessionDetailsChanged, TestSessionDetailsEvents} from '../../events';
import {Models} from '../../models';
import {DateTimePicker} from '@esgi/deprecated/elements/date-time-picker';
import Enumerable from 'linq';
import {TestScreenType, TestType} from '@esgi/core/enums';
import {IFormControlValidatorResult} from '@esgi/deprecated/elements/form-controls';
import {Checkbox} from '@esgi/deprecated/elements/checkbox';
import './body.less';
import {Observable, Subscription} from 'rxjs';
import {
	CreateSessionResponse,
	DeleteSessionRequest,
	RestoreSessionRequest, ResultRequest, UpdateResponse,
} from 'shared/modules/test/test-session-details/api/models';
import styles from './body.module.less';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export interface IApi {
	create: () => Observable<CreateSessionResponse>;
	restore: (request: RestoreSessionRequest) => Observable<any>;
	delete: (request: DeleteSessionRequest) => Observable<any>;
}

export abstract class Props<TState extends State, TApi extends IApi> extends SharedProps<TState> {
	close: () => void;
	testSessions: Models.TestSession[];
	questions: Models.Question[];
	canChangeSession: boolean;
	editMode: boolean = false;
	setEditMode: (mode: boolean) => void;
	api: TApi;
	readOnly: boolean;
	studentName: string;
	testName: string;
	testId: number;
	studentID: number;
	nowProfileTZ: moment.Moment;
	hierarchy: HierarchySnapshot;
}

export abstract class State {
	selectedTestSessionId: number;
	showDeletedSessions: boolean = false;
	answers: Models.Answer[] = [];
	loaded: boolean = false;
	dirty: boolean;
	allowRestore: boolean = false;
	nowProfileTZ: moment.Moment;

	scoreValidation: IFormControlValidatorResult = {
		valid: true,
		message: null,
	};

	testDateValidation: IFormControlValidatorResult = {
		valid: true,
		message: null,
	};

	validation: IFormControlValidatorResult = {
		valid: true,
		message: null,
	};
}

export abstract class Body<TApi extends IApi, TState extends State, TProps extends Props<TState, TApi>> extends SharedComponent<TState, TProps> {
	private currentUser = userStorage.get();
	protected testSessions: {[key: number]: Models.TestSession} = {};
	protected currentTestSession: Models.TestSession;
	protected prevTestSessionId: number;
	protected testDateTouched: boolean = false;
	protected loader: Loader = null;
	protected initTestDate: moment.Moment = undefined;
	private prevDate: moment.Moment;

	constructor(props?: TProps) {
		super(props);

		this.setState({nowProfileTZ: props.nowProfileTZ});
	}

	private allValidations = () => {
		return [
			this.state.scoreValidation,
			this.state.testDateValidation,
		];
	};

	protected validateAll() {
		let firstNotValid = this.allValidations().find(s => !s.valid);
		const validationResult = {
			valid: firstNotValid ? false : true,
			message: firstNotValid ? firstNotValid.message : null,
		};
		if (this.state.validation.valid !== validationResult.valid) {
			this.setState({validation: validationResult});
		}
	}

	componentDidMount(): void {
		this.loader = new Loader($('.tsd').parents('.modal-content'));

		this.subscribe(TestSessionDetailsEvents.TestSessionRestore, () => {
			this.restoreTestSession();
		});
		this.subscribe(TestSessionDetailsEvents.ResetPanels, () => {
			this.resetEdit();
		});
		this.subscribe(TestSessionDetailsEvents.NewTestSessionCanceled, () => {
			this.newTestSessionCanceled();
		});

		this.subscribe(TestSessionDetailsChanged, (args: TestSessionDetailsChanged) => {
			if (!this.prevDate) {
				return;
			}
			const {testDate, sessionId} = args;
			if (testDate && sessionId) {
				const answers = this.state.answers;
				const prevDateFormatted = this.prevDate.format('MM-DD-YYYY');
				const updAnswers = answers.map(a => {
					if (a.sessionId === sessionId && a.testDate.format('MM-DD-YYYY') === prevDateFormatted) {
						a.testDate = testDate;
					}
					return a;
				});
				this.setState({answers: updAnswers});
			}
		});

		if (this.props.testSessions) {
			this.props.testSessions.forEach(ts => {
				this.testSessions[ts.id] = ts;
			});
		}

		if (!this.state.loaded) {
			this.setState({loaded: true});
		}

		this.setTestSession();
	}

	componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>, prevContext: any): void {
		super.componentDidUpdate(prevProps, prevState, prevContext);
	}

	protected resetEdit() {
		this.setTestSession(this.currentTestSession);
		if (this.initTestDate) {
			this.currentTestSession.testDate = this.initTestDate;
		}
	}

	private newTestSessionCanceled() {
		this.setTestSession(this.testSessions[this.prevTestSessionId]);
	}

	protected updateTestSession(): Subscription {
		if (!this.state.validation.valid) {
			return;
		}

		this.loader.mask();

		return this.updateAction()
			.subscribe({
				next: (resp) => {
					if (!this.currentTestSession.id || this.currentTestSession.id < 0) {
						this.currentTestSession.id = resp.testSessionID;
						this.currentTestSession.number = Object.keys(this.testSessions).length + 1;
						this.currentTestSession.durationString = 'NA';
						this.currentTestSession.userID = this.currentUser.userID;
						this.currentTestSession.userName = this.currentUser.firstName + ' ' + this.currentUser.lastName;
						this.testSessions[resp.testSessionID] = this.currentTestSession;

						this.setTestSession(this.currentTestSession);
						showSnackbarNotification(`You've created Test Session ${this.currentTestSession.testDate.format('MM-DD-YYYY hh:mm A')}`);
					} else {
						showSnackbarNotification(`You've edited Test Session ${this.currentTestSession.testDate.format('MM-DD-YYYY hh:mm A')}`);
					}

					this.props.setEditMode(false);
					this.setState({dirty: false});

					this.dispatch(TestSessionDetailsChanged, new TestSessionDetailsChanged(this.props.testId, this.currentTestSession.id, this.currentTestSession.testDate));
				},
				error: () => {
					this.loader.unmask();
				},
				complete: () => {
					this.loader.unmask();
				},
			});

	}

	protected restoreTestSession() {
		this.loader.mask();

		let request = {
			testSessionId: this.currentTestSession.id,
		};

		this.props.api.restore(request)
			.subscribe({
				next: () => {
					this.currentTestSession.deleted = false;

					this.setTestSession(this.currentTestSession);

					this.props.setEditMode(false);

					dispatchAppEvent(TestSessionDetailsChanged, new TestSessionDetailsChanged(this.props.testId));
					showSnackbarNotification(`You've restored Test Session ${this.currentTestSession.testDate.format('MM-DD-YYYY hh:mm A')}`);
					SsoTracker.trackEvent({
						trackingEvent: 'TestSessionRestored',
						data: {testSessionId: this.currentTestSession.id},
					});
				},
				error: () => {
					this.loader.unmask();
				},
				complete: () => {
					this.loader.unmask();
				},
			});
	}

	protected deleteTestSession() {
		this.loader.mask();

		let request = {
			testSessionId: this.currentTestSession.id,
		};

		this.props.api.delete(request)
			.subscribe({
				next: () => {
					this.currentTestSession.deleted = true;

					this.setTestSession(((this.state.showDeletedSessions)
						? this.currentTestSession
						: null));

					this.props.setEditMode(false);

					dispatchAppEvent(TestSessionDetailsChanged, new TestSessionDetailsChanged(this.props.testId));
				},
				error: () => {
					this.loader.unmask();
				},
				complete: () => {
					this.loader.unmask();
					const deletedTestSession = this.testSessions[request.testSessionId];

					showSnackbarNotification(`You've deleted Test Session ${deletedTestSession?.testDate.format('MM-DD-YYYY hh:mm A')}`);

					SsoTracker.trackEvent({
						trackingEvent: 'TestSessionDeleted',
						data: {testSessionId: deletedTestSession?.id},
					});
				},
			});
	}

	get visibleSessions(): Models.TestSession[] {
		let results: Models.TestSession[] = [];

		for (let key in this.testSessions) {
			let value = this.testSessions[key];

			if (this.state.showDeletedSessions || !value.deleted) {
				results.push(value);
			}
		}

		let array = Enumerable.from(results).orderByDescending(r => r.testDate.valueOf()).toArray();

		if (array.length == 0) {
			array.push(Models.TestSession.Empty());
		}

		return array;
	}

	get existsDeletedSessions(): boolean {
		let result = false;

		for (let key in this.testSessions) {
			if (!this.testSessions.hasOwnProperty(key)) {
				continue;
			}

			if (this.testSessions[key].deleted) {
				result = true;
				break;
			}
		}

		return result;
	}

	protected testSessionChanged(testSessionId: number) {
		this.setTestSession(this.testSessions[testSessionId]);

		let args = new TestSessionDetailsEvents.TestedByChanged.Args();
		args.testedBy = this.currentTestSession.userName;
		this.dispatch(TestSessionDetailsEvents.TestedByChanged, args);
	}

	protected setTestSession(testSession?: Models.TestSession) {
		this.currentTestSession = testSession ?? this.visibleSessions[0];
		this.setState({
			selectedTestSessionId: this.currentTestSession.id,
			allowRestore: this.currentTestSession.deleted,
			dirty: false,
		});

		this.testDateTouched = false;

		if (!this.currentTestSession.isEmpty) {
			let answersRequest: ResultRequest = {
				testSessionID: this.currentTestSession.id,
			};

			this.getAnswers(answersRequest);
		} else {
			this.setAnswers();
		}
	}

	protected addTestSessionClicked() {
		this.props.api.create()
			.subscribe({
				next: (resp) => {
					const nowProfileTZ = moment(resp.nowProfileTZ);
					this.setState({nowProfileTZ: nowProfileTZ}, () => {
						this.prevTestSessionId = this.currentTestSession.id;
						let newTestSession = Models.TestSession.Empty();
						newTestSession.id = -1;
						newTestSession.testDate = nowProfileTZ;
						this.setTestSession(newTestSession);

						this.props.testSessions.push(newTestSession);
						this.props.setEditMode(true);
					});
				},
			});
	}

	protected abstract setAnswers(answers?: Models.Answer[]);

	protected changeDate(date: moment.Moment) {
		if (date === this.currentTestSession.testDate) {
			return;
		}

		if (!this.initTestDate) {
			this.initTestDate = this.currentTestSession.testDate;
		}

		this.testDateTouched = true;
		this.prevDate = this.currentTestSession.testDate;
		this.currentTestSession.testDate = date;
		this.setState({dirty: true});

	}

	abstract get testType(): TestType;

	protected abstract getAnswers(answersRequest: ResultRequest);

	protected abstract renderEditButtons();

	protected abstract renderCorrectAnswersHeader();

	protected abstract renderAnswersBlock();

	protected abstract updateAction(): Observable<UpdateResponse>;

	private setShowDeletedOption(selected: boolean) {
		this.setState({showDeletedSessions: selected}, () => {
			if (selected) {
				if (Enumerable.from(this.props.testSessions).all(ts => ts.deleted)) {
					this.setTestSession(this.props.testSessions[0]);
				}
			} else {
				this.setTestSession();
			}
		});
	}

	private validateTestDate(value: moment.Moment) {
		let result = !value.isValid() ? {message: 'Please enter a test session date.', valid: false} : {
			valid: true,
			message: '',
		};
		if (this.state.testDateValidation.valid !== result.valid) {
			this.setState({testDateValidation: result});
		}
		this.validateAll();
		return result;
	}

	render() {
		return <div className='tsd'>
			{this.currentTestSession && this.currentTestSession.number &&
				<div className='session-info'>
					<div className='number'>Test Session {this.currentTestSession.number.toString()}</div>
					<div className='duration'>Duration: {this.currentTestSession.durationString}</div>
				</div>
			}
			<div className='tsd-sessions'>
				<div className='session-selector'>
					<div className='selector-holder'>
						{(this.props.editMode && this.currentTestSession.id && !this.currentTestSession.deleted)
							? <DateTimePicker
								id={'session_time_' + this.currentTestSession.id.toString()}
								date={this.currentTestSession.testDate}
								endDate={this.state.nowProfileTZ}
								validator={val => this.validateTestDate(val)}
								onSelect={(d) => this.changeDate(d)}
							/>
							: this.renderDropdown()
						}
					</div>
				</div>
				<div className='edit-buttons'>
					{this.renderEditButtons()}
				</div>
			</div>

			{!this.props.editMode && this.existsDeletedSessions &&
				<div className='left'>
					<Checkbox
						checked={this.state.showDeletedSessions}
						label='Show deleted sessions'
						onClick={(checked: boolean) => this.setShowDeletedOption(checked)}
						id='showDeletedSessions'
						className={'show-deleted-option'}
					/>
				</div>
			}

			{this.renderCorrectAnswersHeader()}

			<div className='tsd-answers'>
				{this.renderAnswersBlock()}
			</div>
		</div>;
	}

	private renderDropdown(): ReactNode {
		const values = this.visibleSessions;
		const value = [values.find(v => this.state.selectedTestSessionId === v.id)];
		return <BaseDropdown value={value}
		                     optionName='testDateString'
		                     setValue={(value) => this.testSessionChanged(value[0]?.id)}
		                     className={styles.sessionSelector}
		                     disabled={(values.length < 2 || this.props.editMode)}>
			{values.map(v => <Option key={v.id} value={v}>
				{v.testScreenType === TestScreenType.SelfAssessment ? `${v.testDateString} (SA)` : v.testDateString}
			</Option>)}
		</BaseDropdown>;
	}
}
