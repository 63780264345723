export const baseTheme = {
	fonts: {
		Roboto: 'Roboto, "Open Sans", serif',
		RobotoMono: 'Roboto Mono, "Open Sans", serif',
	},
	fontSizes: {
		xxLarge: '22px',
		xLarge: '18px',
		large: '14px',
		medium: '13px',
		small: '11px',
		xSmall: '10px',
		xxSmall: '9px',
	},
	space: {
		1: '4px',
		2: '8px',
		3: '12px',
		4: '16px',
		5: '24px',
		6: '32px',
		7: '40px',
		8: '48px',
		9: '64px',
	},
	media: {
		portraitTablet: '(max-width: 744px)',
		landscapeTablet: '(max-width: 1024px)',
		smallLaptop: '(max-width: 1280px)',
		averageLaptop: '(max-width: 1440px)',
		largeLaptop: '(max-width: 1650px)',
	},
}