import React from 'react';
import styles from '../../../../styles.module.less';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {Student as StudentType} from '../../../../../../../models';
import {SettingsFormSelectors} from '../../../../../../test/selectors';

interface Props {
	selectedStudent: StudentType
	students: StudentType[]
	onChange: (v: StudentType[]) => void
}

export function Student({students, selectedStudent, onChange}: Props) {
	return students?.length > 0 && <>
		<div className={styles.formGroup}>
			<label>Student: </label>
			<Dropdown value={[selectedStudent]} optionName='fullName' setValue={onChange} className={styles.formControl} autoWidth={true}
			data-cy={SettingsFormSelectors.studentDropdown}>
				{students.map(student => {
					return <Option key={student.studentId}
					               value={student}>{student.fullName}</Option>;
				})}
			</Dropdown>
		</div>
	</>;
}
