import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {BaseHttpClient} from './base-http-client';
import {ErrorHandler} from './error-handler';
import {ObservableBuilder} from './internal/observable-builder';
import {BuilderOptions, RequestBuilder} from './internal/request-builder';

export interface Environment {
	esgiApiURL: string;
	ssoApiURL: string;
}

/* The `HttpClient` class is a TypeScript class that extends `BaseHttpClient` and provides methods for
making HTTP requests to ESGI and SSO APIs. */
export default class HttpClient extends BaseHttpClient {
	public static default: Omit<HttpClient, 'destroy' | 'onRequestEnd$' | 'onRequestStart$'>;
	constructor(private defaultErrorHandlers: ErrorHandler[], private environment: Environment) {
		super();
	}

	/**
	 * The function returns a RequestBuilder object for the ESGI API using the specified environment's
	 * URL.
	 * @returns The ESGI API RequestBuilder is being returned.
	 */
	public get ESGIApi(): RequestBuilder {
		return this.innerRequest(this.environment.esgiApiURL);
	}

	/**
	 * The function returns a RequestBuilder object for the SSO API URL.
	 * @returns The SSO API RequestBuilder is being returned.
	 */
	public get SSO(): RequestBuilder {
		return this.innerRequest(this.environment.ssoApiURL);
	}

	/**
	 * The function `byAxiosConfig` takes an AxiosRequestConfig object and returns an ObservableBuilder
	 * object based on provided config.
	 * @param {AxiosRequestConfig} config - The `config` parameter is an object of type
	 * `AxiosRequestConfig` which contains various options for making an HTTP request using Axios.
	 * @returns The method is returning an instance of `ObservableBuilder<T>`.
	 */
	public byAxiosConfig<T>(config: AxiosRequestConfig): ObservableBuilder<T> {
		return this.innerRequest(config.baseURL, {defaultErrorHandlers: this.defaultErrorHandlers}).custom<T>(config);
	}

	/**
	 * @param {string} baseUrl - A string representing the base URL for the request.
	 * @param [options] - The `options` parameter is an optional object that allows you to customize the
	 * behavior of the request. It's a partial object of the `BuilderOptions` type, excluding the `baseUrl` property.
	 * @returns The method is returning a RequestBuilder object.
	 */
	protected override innerRequest(baseUrl: string, options?: Partial<Omit<BuilderOptions, 'baseUrl'>>): RequestBuilder {
		return super.innerRequest(baseUrl, {
			defaultErrorHandlers: this.defaultErrorHandlers,
			responseMapper: (data) => {
				if (data instanceof Blob) {
					return data;
				}
				if (data) {
					return (data as any).object;
				}
				return undefined;
			},
			...options,
		});
	}
}