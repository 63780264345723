import {createContext, useContext} from 'react';
import {useService} from '@esgi/core/service';
import {SightWordsService} from './services';

export const useSightWords = () => {
	const service = useService(SightWordsService);

	return {service};
};

export const SightWordsContext = createContext(null);
export const useSightWordsService = () =>
	useContext<SightWordsService>(SightWordsContext);
