import {useCallback} from 'react';
import {Test, TestListProps} from '../../types';
import {ExpandablePanel} from '@esgi/main/kits/activities';
import {Action} from './components/action';
import {ExpandableSelectableListMultiple} from './components/list';
import {Wrapper} from './index.styled';

export function TestList(props: TestListProps) {
	const {disabledItems = false, state, selectedSessions, toggleSessions, sort, onSort, onToggle, onSelectAll, onSelectTestAll} = props;
	const isListSelected = useCallback((testId: Test['id']) => {
		const {sessions = []} = state.find(({id}) => id === testId) || {};
		return sessions.length && sessions.every(({id}) => selectedSessions?.includes(id));
	}, [state, selectedSessions]);

	return (
		<Wrapper dataCy='test-list-wrapper'>
			<Action
				sort={sort}
				onSort={onSort}
				onSelectAll={onSelectAll}
			/>
			<ExpandablePanel style={{height: 'calc(100% - 60px)'}}>
				{state.map(({id, name, sessions, isEmpty = false, isListOpen}) => (
					<ExpandableSelectableListMultiple
						key={id}
						id={id}
						name={name}
						selectedSessionId={selectedSessions}
						sessions={sessions}
						setSelectedSessionId={((value) => toggleSessions([value]))}
						isListOpen={isListOpen}
						isEmpty={isEmpty}
						isListSelected={Boolean(isListSelected(id))}
						disabledItems={disabledItems}
						onToggle={() => onToggle(!isListOpen, id)}
						onSelectAll={() => toggleSessions(onSelectTestAll(id, selectedSessions))}
					/>
				))}
			</ExpandablePanel>
		</Wrapper>
	);
}
