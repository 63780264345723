import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {ComponentProps, forwardRef} from 'react';

type TextColor = NonNullable<VariantProps<typeof Text>['color']>;

type Props = ComponentProps<typeof GridBox> & {
	value: number;
	valueTextColor: TextColor;
	maxValue: number;
	maxValueTextColor: TextColor;
};

export const CountInfo = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'assignments-count-info', css = {}, value, maxValue, valueTextColor, maxValueTextColor, ...props},
		forwardedRef,
	) => (
		<GridBox
			dataCy={dataCy}
			css={{
				gap: 2,
				...css,
			}}
			ref={forwardedRef}
			{...props}
		>
			<FlexBox align='end'>
				<Text size='medium' bold font='mono' color={valueTextColor}>
					{value}
				</Text>
				<Text size='xSmall' font='mono' color={maxValueTextColor}>
					/{maxValue}
				</Text>
			</FlexBox>
		</GridBox>
	),
);
