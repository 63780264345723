import {AssignmentStateTab} from '../../../layout/types';
import {TabItem} from './types';

export const tabItems: TabItem[] = [
	{
		value: AssignmentStateTab.AllAssignments,
		label: 'All Assignments',
		labelDataCy: 'assignments-panel-tab-item-all',
	},
	{
		value: AssignmentStateTab.Draft,
		label: 'Draft',
		labelDataCy: 'assignments-panel-tab-item-draft',
	},
	{
		value: AssignmentStateTab.NotStarted,
		label: 'Not Started',
		labelDataCy: 'assignments-panel-tab-item-notStarted',
	},
	{
		value: AssignmentStateTab.InProgress,
		label: 'In Progress',
		labelDataCy: 'assignments-panel-tab-item-inProgress',
	},
	{
		value: AssignmentStateTab.Completed,
		label: 'Completed',
		labelDataCy: 'assignments-panel-tab-item-completed',
	},
];
