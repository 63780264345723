import React from 'react';
import './component.less';
import {Link} from '@esgi/deprecated/elements/buttons/link';

export class Props {
	onClick: () => void;
	opened: boolean;
}

export default class ShowMoreButton extends React.PureComponent<Props> {
	render() {
		return <div className='grade-scale-selector-show-more-button'>
			{!this.props.opened && <Link
				onClick={() => this.props.onClick()}
				className='grade-entry-component-button'
				contentOnRight={true}
				title='Show more'>
				<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M0 0L5 5.11133L10 0L0 0Z' fill='#0077B8'/>
				</svg>
			</Link>}

			{this.props.opened && <Link
				onClick={() => this.props.onClick()}
				className='grade-entry-component-button'
				contentOnRight={true}
				title='Show less'>
				<svg className='grade-scale-selector-show-more-button less' width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M0 0L5 5.11133L10 0L0 0Z' fill='#0077B8'/>
				</svg>
			</Link>}
		</div>;
	}
}