import {useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {QuestionItemProps} from '../../../../../../types';
import {
	getItemGradeLevels,
	getItemTextColor,
} from '../../../../../../utils';
import {Tag} from '../tag';
import {ImageWrapper, Row, Image, StyledCheckbox, StyledMetaWrapper, StyledTileName, TileCard} from './index.styled';
import {noop} from 'underscore';
import {MultipleLabels} from '../../../../../multiple-labels';
import {createSelfAssessImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';

export function QuestionTileItem({
																	 question,
																	 selected,
																	 onCheckedChanged,
																	 onPreviewClick,
																	 stateStandardNames,
																 }: QuestionItemProps) {
	const urlBuilder = createSelfAssessImageUrlBuilder();

	const [cardEntered, setCardEntered] = useState(false);

	const textColor = getItemTextColor(cardEntered, selected);
	const gradeLevels = getItemGradeLevels(
		question?.gradeLevelIDs,
	);

	const handleCheck = (e: React.MouseEvent<unknown>) => {
		if (!question) {
			return;
		}
		e.stopPropagation();
		onCheckedChanged(question);
	};

	const handleMouseLeave = () => {
		setCardEntered(false);
	};

	const handleMouseEnter = () => {
		setCardEntered(true);
	};

	const handlePreviewButtonClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		if (!question || !onPreviewClick) {
			return;
		}

		onPreviewClick(question);
	};

	return (
		<TileCard selected={selected} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={handleCheck}>
			{question && (
				<StyledCheckbox onCheckedChange={noop} entered={cardEntered} onClick={handleCheck} checked={selected} />
			)}
			<ImageWrapper entered={cardEntered} skeleton={!question} selected={selected} onClick={handlePreviewButtonClick}>
				{question && <Image src={urlBuilder(question.thumbnailUrl, question.id)} />}
			</ImageWrapper>
			<StyledTileName skeleton={!question} selected={selected}>
				{question && (
					<Text size='large' color={textColor} bold>
						{question.name}
					</Text>
				)}
			</StyledTileName>
			<StyledMetaWrapper onClick={handleCheck}>
				<Row>
					<Text size='xSmall' color='mediumContrast' font='mono'>
						Content Area:
					</Text>
					<Tag>{question?.contentAreaName}</Tag>
				</Row>
				<Row>
					<Text size='xSmall' color='mediumContrast' font='mono'>
						Grade Level:
					</Text>
					<MultipleLabels items={gradeLevels} />
				</Row>
				<Row>
					<Text size='xSmall' color='mediumContrast' font='mono'>
						Standards:
					</Text>
					<MultipleLabels items={stateStandardNames} />
				</Row>
			</StyledMetaWrapper>
		</TileCard>
	);
}