import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';

export const listCSS: CSS = {
	height: 'auto',

	'& li': {
		minHeight: 40,
	},

	'& li:not(:last-child)': {
		marginBottom: 6,
	},
};

export const Button = styled(StudentsPanel.Tabs.SelectAllEntitiesButton, {
	marginBottom: 20,
});
