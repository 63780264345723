export const colors = {
	primary: {
		primary: 'hsla(201, 100%, 40%, 1)',
		primary6: 'hsla(200, 89%, 6%, 1)',
		primary14: 'hsla(200, 89%, 14%, 1)',
		primary16: 'hsla(200, 89%, 16%, 1)',
		primary20: 'hsla(200, 89%, 20%, 1)',
		primary24: 'hsla(200, 89%, 24%, 1)',
		primary40: 'hsla(201.18, 100%, 40%, 1)',
		primary48: 'hsla(201, 100%, 48%, 1)',
		primary56: 'hsla(201, 100%, 56%, 1)',
		primary64: 'hsla(201, 100%, 64%, 1)',
		primary72: 'hsla(201, 100%, 72%, 1)',
		primary80: 'hsla(201, 100%, 80%, 1)',
		primary88: 'hsla(201, 100%, 88%, 1)',
		primary92: 'hsla(201, 100%, 92%, 1)',
		primary96: 'hsla(201, 100%, 96%, 1)',
		primary98: 'hsla(201, 100%, 98%, 1)',
		primary99: 'hsla(201, 100%, 99%, 1)',
	},
	secondary: {
		secondary: 'hsla(277, 52%, 40%, 1)',
		secondary12: 'hsla(277, 52%, 12%, 1)',
		secondary16: 'hsla(277, 52%, 16%, 1)',
		secondary20: 'hsla(277, 52%, 20%, 1)',
		secondary40: 'hsla(277, 52%, 40%, 1)',
		secondary48: 'hsla(277, 52%, 48%, 1)',
		secondary56: 'hsla(277, 52%, 56%, 1)',
		secondary64: 'hsla(277, 52%, 64%, 1)',
		secondary72: 'hsla(277, 52%, 72%, 1)',
		secondary80: 'hsla(277, 52%, 80%, 1)',
		secondary88: 'hsla(277, 52%, 88%, 1)',
		secondary92: 'hsla(277, 52%, 92%, 1)',
		secondary96: 'hsla(277, 52%, 96%, 1)',
		secondary99: 'hsla(277, 52%, 99%, 1)',
	},
	red: {
		red: 'hsla(352, 100%, 40%, 1)',
		red16: 'hsla(352, 100%, 16%, 1)',
		red32: 'hsla(352, 100%, 32%, 1)',
		red48: 'hsla(352, 100%, 48%, 1)',
		red56: 'hsla(352, 100%, 56%, 1)',
		red64: 'hsla(352, 100%, 64%, 1)',
		red72: 'hsla(352, 100%, 72%, 1)',
		red80: 'hsla(352, 100%, 80%, 1)',
		red88: 'hsla(352, 100%, 88%, 1)',
		red92: 'hsla(352, 100%, 92%, 1)',
		red96: 'hsla(352, 100%, 96%, 1)',
		red98: 'hsla(352, 100%, 98%, 1)',
		red99: 'hsla(352, 100%, 99%, 1)',
	},
	green: {
		green: 'hsla(133, 53%, 40%, 1)',
		green12: 'hsla(133, 53%, 12%, 1)',
		green16: 'hsla(133, 53%, 16%, 1)',
		green20: 'hsla(133, 53%, 20%, 1)',
		green32: 'hsla(133, 53%, 32%, 1)',
		green48: 'hsla(133, 53%, 48%, 1)',
		green56: 'hsla(133, 53%, 56%, 1)',
		green64: 'hsla(133, 53%, 64%, 1)',
		green72: 'hsla(133, 53%, 72%, 1)',
		green80: 'hsla(133, 53%, 80%, 1)',
		green88: 'hsla(133, 53%, 88%, 1)',
		green92: 'hsla(133, 53%, 92%, 1)',
		green96: 'hsla(133, 53%, 96%, 1)',
		green99: 'hsla(133, 53%, 99%, 1)',
	},
	orange: {
		orange: 'hsl(18, 96%, 40%, 1)',
		orange12: 'hsla(18, 96%, 12%, 1)',
		orange16: 'hsla(18, 96%, 16%, 1)',
		orange24: 'hsla(18, 96%, 24%, 1)',
		orange32: 'hsla(18, 96%, 32%, 1)',
		orange48: 'hsla(18, 96%, 48%, 1)',
		orange56: 'hsla(18, 100%, 56%, 1)',
		orange64: 'hsla(18, 100%, 64%, 1)',
		orange72: 'hsla(18, 100%, 72%, 1)',
		orange80: 'hsla(18, 100%, 80%, 1)',
		orange88: 'hsla(18, 100%, 88%, 1)',
		orange92: 'hsla(18, 100%, 92%, 1)',
		orange96: 'hsla(18, 100%, 96%, 1)',
		orange99: 'hsla(18, 100%, 99%, 1)',
	},
	yellow: {
		yellow: 'hsla(37, 100%, 40%, 1)',
		yellow16: 'hsla(37, 100%, 16%, 1)',
		yellow48: 'hsla(37, 100%, 48%, 1)',
		yellow56: 'hsla(37, 100%, 56%, 1)',
		yellow64: 'hsla(37, 100%, 64%, 1)',
		yellow72: 'hsla(37, 100%, 72%, 1)',
		yellow80: 'hsla(37, 100%, 80%, 1)',
		yellow88: 'hsla(37, 100%, 88%, 1)',
		yellow92: 'hsla(37, 100%, 92%, 1)',
		yellow96: 'hsla(37, 100%, 96%, 1)',
		yellow99: 'hsla(37, 100%, 99%, 1)',
	},
	neutral: {
		neutral: 'hsla(0, 0%, 0%, 1)',
		neutral6: 'hsla(0, 0%, 6%, 1)',
		neutral8: 'hsla(0, 0%, 8%, 1)',
		neutral10: 'hsla(0, 0%, 10%, 1)',
		neutral12: 'hsla(0, 0%, 12%, 1)',
		neutral16: 'hsla(0, 0%, 16%, 1)',
		neutral20: 'hsla(0, 0%, 20%, 1)',
		neutral24: 'hsla(0, 0%, 24%, 1)',
		neutral32: 'hsla(0, 0%, 32%, 1)',
		neutral40: 'hsla(0, 0%, 40%, 1)',
		neutral48: 'hsla(0, 0%, 48%, 1)',
		neutral56: 'hsla(0, 0%, 56%, 1)',
		neutral64: 'hsla(0, 0%, 64%, 1)',
		neutral72: 'hsla(0, 0%, 72%, 1)',
		neutral80: 'hsla(0, 0%, 80%, 1)',
		neutral88: 'hsla(0, 0%, 88%, 1)',
		neutral92: 'hsla(0, 0%, 92%, 1)',
		neutral94: 'hsla(0, 0%, 94%, 1)',
		neutral96: 'hsla(0, 0%, 96%, 1)',
		neutral98: 'hsla(0, 0%, 98%, 1)',
		neutral99: 'hsla(0, 0%, 99%, 1)',
	},
	indigo: {
		indigo16: 'hsla(237, 52%, 16%, 1)',
		indigo40: 'hsla(237, 52%, 40%, 1)',
		indigo48: 'hsla(237, 52%, 48%, 1)',
		indigo64: 'hsla(237, 52%, 64%, 1)',
		indigo88: 'hsla(237, 52%, 89%, 1)',
	},
	blue: {
		blue40: 'hsla(200, 96%, 40%, 1)',
		blue64: 'hsla(200, 84%, 64%, 1)',
		blue88: 'hsla(200, 64%, 88%, 1)',
		blue96: 'hsla(200, 60%, 96%, 1)',
	},
};
