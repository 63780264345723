import {BaseService} from '@esgi/core/service';
import {Observable, of} from 'rxjs';

export interface TestTypeInterface {
	load(request: Record<string, any>): Observable<any>;
	update(request: Record<string, any>): Observable<any>;
}

export class TestTypeService extends BaseService implements TestTypeInterface {
	protected controller: string = 'assessments/test-session-details';

	public load(request: Record<string, any>) {
		return of(request);
	}

	public update(request: Record<string, any>) {
		return of(request);
	}
}
