import {AppEvent} from '@esgillc/events';

@AppEvent({broadcast: true})
export class TestSessionDetailsChanged {
	constructor(
		public testId: number,
		public sessionId?: number,
		public testDate?: moment.Moment,
	) {
	}
}

export namespace TestSessionDetailsEvents {
	export namespace TestedByChanged {
		export class Args {
			testedBy: string;
		}
	}

	export function TestSessionUpdate(): void {
		return;
	}

	export function TestSessionRestore(): void {
		return;
	}

	export function ResetPanels(): void {
		return;
	}

	export function NewTestSessionCanceled(): void {
		return;
	}
}
