import {GradeRange, TestInfo, TestSession, TrackDate} from '../../../types';
import {useMemo} from 'react';
import moment from 'moment';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	trackDates: TrackDate[],
	gradeRanges: GradeRange[];
}

type TableData = {
	columnTitles: string[],
	scores: number[],
	percents: number[],
	grades: GradeRange['gradeScaleEntry'][],
}

export function useTableData({testSessions, testInfo, trackDates, gradeRanges}: Props) {
	const baselineScore = testSessions.sort(({testDate: first}, {testDate: second}) =>
		new Date(first).valueOf() - new Date(second).valueOf())[0].correctAnswers;
	const baselinePercent = Math.round(baselineScore / testInfo.totalPossible * 100);
	const baselineGrade = gradeRanges.find(({from, to}) => baselinePercent >= from && baselinePercent <= to)?.gradeScaleEntry || null;

	return useMemo(() => trackDates.reduce((acc, {dateFrom, dateTo}, idx): TableData => {
		const currentPeriodSessions = testSessions.filter(({testDate}) => moment(testDate).isBetween(dateFrom, dateTo));
		const currentPeriodSessionLastSession = currentPeriodSessions[currentPeriodSessions.length - 1];

		const score = currentPeriodSessionLastSession?.correctAnswers;
		const percent = Math.round(score / testInfo.totalPossible * 100);
		const grade = gradeRanges.find(({from, to}) => percent >= from && percent <= to)?.gradeScaleEntry || null;

		return {
			...acc,
			columnTitles: [...acc.columnTitles, String(idx + 1)],
			scores: [...acc.scores, score],
			percents: [...acc.percents, percent],
			grades: [...acc.grades, grade],
		};
	}, {
		columnTitles: ['B'],
		scores: [baselineScore],
		percents: [baselinePercent],
		grades: [baselineGrade],
	}),
	[baselineGrade, baselinePercent, baselineScore, gradeRanges, testInfo.totalPossible, testSessions, trackDates]);
}