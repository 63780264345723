import React, {useCallback} from 'react';
import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Link, UnderlinedText} from '../rollover-notofication/components/text-with-link-block/index.styled';
import {Content} from './index.styled';
import {SchoolYearModel} from '../../types';

type Props = {
	onClose: VoidFunction;
	schoolYear: SchoolYearModel;
};

const goToSupportLink = 'https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Years';

export function NewSchoolYear({onClose, schoolYear}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef);

	const handleAlertClose = useCallback(() => {
		closeAlert();
		onClose();
	}, [closeAlert, onClose]);

	return <Alert>
		<Alert.Header onCloseIconClick={handleAlertClose} withBacklight={false}>
			<Text size='large' color='base'>{`You are Viewing the ${schoolYear.name} School Year.`}</Text>
		</Alert.Header>

		<Alert.Body>
			<Content align='center'>
				<Text size='medium' color='base'>To view a different year, select it from the dropdown.</Text>
				<Link to={goToSupportLink} target='_blank'>
					<UnderlinedText size='medium' color='primary'>
						Learn more
					</UnderlinedText>
				</Link>
			</Content>
		</Alert.Body>

		<Alert.Footer>
			<Button color='secondary' onClick={handleAlertClose}>
				<Text size='medium' bold color='base'>
					Got It
				</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}
