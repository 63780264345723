import React, {useEffect, useMemo, useState} from 'react';
import styles from './styles.module.less';
import {CloseIcon, Modal, useModal} from '@esgillc/ui-kit/modal';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {InitModel} from '../models';
import {ModalFooter} from './components/modal-footer';
import {ModalBody} from './components/modal-body';
import FormService from '../services/form-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {ResultsFormModal} from '../results-form-modal';
import {RequestResultsInit} from '../api-models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';

export type ParentLetterInitArgs = {
	initStudentId: number; initGroupItemId: number, initGroupItemType: string,
	initSubjectId: number, init: boolean
}

interface Props {
	onModalClose: () => void;
	onSettingsFormClose: () => void;
	hierarchy: HierarchySnapshot;
	selectedItems?: InitModel;
	goToFlashCardsClicked: () => void;
	changesCollector: ChangesCollector
}

export function SettingsFormModal({
	                                  onModalClose,
	                                  goToFlashCardsClicked,
	                                  hierarchy,
	                                  selectedItems,
	                                  changesCollector,
}: Props) {
	const service = useMemo(() => new FormService(hierarchy, changesCollector, selectedItems), [hierarchy, changesCollector, selectedItems]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const viewParentLetterDisabled = useBehaviorSubject(service.viewParentLetterDisabled);
	const [reportModel, setReportModel] = useState<RequestResultsInit>(null);
	const modalManagerRef = useModal();

	useEffect(() => {
		service.init().subscribe(() => setDataLoaded(true));
		return () => service.destroy();
	}, [service]);

	return <>
		<ServiceLoader trackingService={service} fullscreen={true}/>
		{dataLoaded && <Modal modalManagerRef={modalManagerRef}>
			<Modal.Header className={styles.blueHeader}>
				<div className={styles.title}>Parent Letter Settings</div>
				<CloseIcon color='white' onClick={() => modalManagerRef.current.close(onModalClose)}/>
			</Modal.Header>
			<ModalBody service={service}/>
			<ModalFooter viewParentLetterClicked={() => setReportModel(service.makeReportModel())}
			             goToFlashCardsClicked={goToFlashCardsClicked}
			             viewParentLetterDisabled={viewParentLetterDisabled}/>
		</Modal>}
		{reportModel && <ResultsFormModal reportModel={reportModel} onGoBack={() => setReportModel(null)}
		                                  onClose={() => onModalClose()}/>}
	</>;
}
