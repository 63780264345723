import {AssignmentCardHeader} from './components/assignment-card-header';
import {AssignmentCardBody} from './components/assignment-card-body';
import {AssignmentCardRoot} from './components/assignment-card-root';

export const AssignmentCard = {
	Root: AssignmentCardRoot,
	Header: AssignmentCardHeader,
	Body: AssignmentCardBody,
};

export {AssignmentCardSkeleton} from './components/assignment-card-skeleton';
