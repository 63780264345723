import {Button, ContextMenu} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const ContextMenuItem = styled(ContextMenu.Item, {
	padding: 6,
	display: 'grid',
	gridAutoFlow: 'column',
	gap: 6,
});

export const ButtonIcon = styled(Button.Icon, {
	variants: {
		applyButtonSizes: {
			true: {
				width: 24,
				height: 24,
				color: '$base',
			},
		},
	},

	defaultVariants: {
		applyButtonSizes: true,
	},
});
