// @ts-nocheck
export async function openZopim(): Promise<any> {
	return new Promise<any>(async (resolve, reject) => {
		await loadZopim();

		try {
			resolve(window.$zopim);
			window.$zopim.livechat.window.show();
		} catch (e) {
			console.error(e);
			reject(e);
		}
	});
}

export async function loadZopim() {
	return new Promise<any>(async (resolve, reject) => {
		if (!((window as any).$zopim)) {
			await initZopim();
		}

		const interval = setInterval(() => {
			if (!window.$zopim) {
				return;
			}
			clearInterval(interval);
			resolve();
		}, 100);
	});
}

function initZopim(): Promise<void> {
	return new Promise<void>((resolve, reject) => {
		const zopimScriptTag = document.createElement('script');
		zopimScriptTag.async = true;
		zopimScriptTag.setAttribute('charset', 'utf-8');
		zopimScriptTag.src = 'https://v2.zopim.com/?2IBdQgHPQjBfg5AGOFbyCTx1rD7raBBY';
		zopimScriptTag.type = 'text/javascript';
		zopimScriptTag.onload = () => resolve();
		zopimScriptTag.onerror = () => reject();
		document.head.appendChild(zopimScriptTag);
	});
}
