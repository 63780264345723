import React from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {Box, BoxOptions, EmptyOptions, ItemProps} from '../../components/box';
import {IBoxInfo, IStudentBox, StudentModel, ITeacherBox} from '../../core/api';
import {BoxType} from '../../core/models';

export class Props {
    boxes: IBoxInfo[];
    teachers: ITeacherBox;
    students: IStudentBox;
    selectedID: number;
    studentID: number;
    schoolID: number;
    groupID: number;
    onSelected: (id: number, callback: () => void) => void;
    onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class State {
}

export class Teacher extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();

	items(): ItemProps[] {
		let teachers = [...this.props.teachers.items];
		if (this.props.schoolID) {
			teachers = teachers.filter(s => s.schoolID === this.props.schoolID);
		}
		if (this.props.groupID) {
			const students = this.props.students.items
				.filter(s => s.specialistGroups.some(t => t === this.props.groupID))
				.map(r => r.teacherID);

			teachers = teachers.filter(s => students.indexOf(s.userID) >= 0);
		}

		return teachers
			.map(r => {
				return {
					id: r.userID,
					title: `${r.firstName} ${r.lastName}`,
				};
			});
	}

	get students(): StudentModel[] {
		let source = [...this.props.students.items];

		if (this.props.schoolID) {
			source = source.filter(s => s.schoolID === this.props.schoolID);
		}

		if (this.props.groupID) {
			source = source.filter(s => s.specialistGroups.some(t => t === this.props.groupID));
		}

		return source;
	}

	render() {
		const current = this.currentUser.userType;
		if ([UserType.ISD, UserType.D, UserType.C].indexOf(current) >= 0 && this.props.schoolID === 0 && this.props.groupID === 0) {
			return null;
		}

		const options: BoxOptions = {
			canAdd: false,
			canEdit: false,
			canCreateTooltip: null,
			title: 'All Teachers',
			canDrag: false,
			boxType: BoxType.SpecialistTeacher,
		};

		const students = this.students;
		let preselectedID = null;
		if (!this.props.selectedID) {
			const student = students.find(t => t.studentID === this.props.studentID);
			if (student) {
				preselectedID = student.teacherID;
			}
		}

		let emptyObj: EmptyOptions = {
			message: 'You have 0 teachers',
		};

		if (students.length > 0 && !students.some(t => t.teacherID)) {
			emptyObj = {
				message: 'Unassigned Students',
				tooltip: 'These students are not currently assigned to a primary teacher',
			};
		}



		return <Box
            open={this.props.boxes.filter(t => t.boxType === BoxType.SpecialistTeacher && t.open).length > 0}
            options={options}
            selectedID={this.props.selectedID}
            preselectedID={preselectedID}
            itemSelected={this.props.onSelected}
            onDragEnd={null}
            onDragStart={null}
            onEditClicked={() => {}}
            onAddClicked={() => {}}
            scheduledIDs={[]}
            items={this.items()}
            onOpenCloseChanged={this.props.onOpenCloseChanged}
            empty={emptyObj}
        />;
	}
}
