import React from 'react';
import {
	GradeValue,
} from '@esgi/main/features/teacher/student-progress-report';
import {FiltersContentBlock, ReportFilterLabel} from '../../index.styled';
import {FlexBox} from '@esgi/ui/layout';
import {GradeScaleBlock, GradeScaleLegendContainer, ReportFiltersContainerStyled} from './index.styled';


interface Props {
	gradeValues: GradeValue[];
}

export function GradeScaleLegend(props: Props) {
	return <ReportFiltersContainerStyled dataCy='grade-scale-container'>
			<FlexBox>
				<ReportFilterLabel>Grade Scale:</ReportFilterLabel>
			</FlexBox>
			<FiltersContentBlock>
						<GradeScaleLegendContainer>
							{
								props.gradeValues.map((item) => <GradeScaleBlock title={item.description} key={item.name}
								                                      style={{'backgroundColor': item.name !== 'NT' ? item.color : '#FFF'}}>
									{item.name}
								</GradeScaleBlock>)
							}
						</GradeScaleLegendContainer>
			</FiltersContentBlock>
		</ReportFiltersContainerStyled>;
}
