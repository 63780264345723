import React, {forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RootContext, TagParams} from '../../types';
import {DroppableTextEditorContext} from '../../context';
import {
	convertEditorHTMLToText,
	convertTextToEditorHTML,
	createInsideTagElementNode, isElementInsideEditor,
} from '../../utils';
import {BaseComponentProps} from '../../../../types';
import {Box} from '../../../../layout/box';

export type DroppableTextEditorRootProps = BaseComponentProps & {
	/**
	 * The current text value of the editor, in string format. (Required)
	 */
	value: string;

	/**
	 * Callback function called when the text value changes. (Required)
	 * @param value - The updated text value.
	 */
	onChange: (value: string) => void;

	/**
	 * If true, disables interaction with the component, including drag and drop functionality.
	 * Optional. Default is `false`.
	 */
	disabled?: boolean;

	/**
	 * Optional child components or elements to render inside the component.
	 */
	children?: ReactNode;

	/**
	 * Indicates whether the editor is in an error state, typically used for validation feedback.
	 * Optional. Default is `false`.
	 */
	error?: boolean;

	/**
	 * Content to display when the editor is in an error state.
	 * Optional.
	 */
	errorContent?: string;

	/**
	 * Placeholder text to display.
	 * Optional.
	 */
	placeholder?: string;

	/**
	 * An array of tag parameters used for managing and rendering draggable tags within the editor. (Required)
	 */
	tags: TagParams[];
}


export const DroppableTextEditorRoot = forwardRef<HTMLDivElement, DroppableTextEditorRootProps>(
	({
		children,
		value,
		tags,
		disabled,
		css,
		onChange,
		error,
		errorContent,
		placeholder,
	},
	ref) => {
		const editorRef = useRef<HTMLDivElement>(null);
		const [draggedElement, setDraggedElement] = useState<HTMLElement | null>(null);

		const handleDrag = useCallback((e: React.DragEvent<HTMLElement>) => {
			if (!disabled) {
				const dragElement = e.target as HTMLElement;
				if (dragElement.tagName === 'SPAN' && dragElement.getAttribute('data-role') === 'draggable-tag') {
					setDraggedElement(dragElement);
					e.dataTransfer.setData('Text/html', `<b>${dragElement.getAttribute('data-id')}</b>`);
				}
			}

		}, [disabled]);

		const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
			if (!disabled) {
				const selection = window.getSelection();

				if (!selection || selection.rangeCount === 0) {
					return;
				}
				if (e.key === 'Enter') {
					e.preventDefault();
					const range = selection.getRangeAt(0);
					range.deleteContents();

					const newLineWithEmptySpace = document.createTextNode('\n\u200B');
					range.insertNode(newLineWithEmptySpace);

					range.setStartAfter(newLineWithEmptySpace);
					range.collapse(true);
					selection.removeAllRanges();
					selection.addRange(range);
					onChange(convertEditorHTMLToText(editorRef.current?.innerHTML));
				}
			}
		}, [disabled]);


		const handleDrop = useCallback(() => {
			if (!disabled) {
				setTimeout(() => {
					const placeholderElement = editorRef.current?.querySelector('b');
					let newElement: HTMLElement | null = null;
					if (draggedElement) {
						if (!isElementInsideEditor(draggedElement)) {
							const tagValue = tags.find(item => item.name === draggedElement.textContent);
							newElement = createInsideTagElementNode(tagValue);
						} else {
							newElement = draggedElement as HTMLElement;
						}
					}
					if (newElement && placeholderElement) {
						placeholderElement.parentNode?.insertBefore(newElement, placeholderElement);
						placeholderElement.parentNode?.removeChild(placeholderElement);
						const selection = window.getSelection();
						if (selection) {
							const range = selection.getRangeAt(0);
							const space = document.createTextNode('\u0020');
							range.setStartAfter(newElement);
							range.insertNode(space);
							range.setStartAfter(space);
						}
						onChange(convertEditorHTMLToText(editorRef.current?.innerHTML));
					}
					setDraggedElement(null);
				}, 0);
			}
		}, [handleDrag, draggedElement, disabled]);

		useEffect(() => {
			if (editorRef.current) {
				const currentInsideValue = convertEditorHTMLToText(editorRef.current.innerHTML);
				if (value !== currentInsideValue) {
					editorRef.current.innerHTML = convertTextToEditorHTML(value, tags);
				}

			}
		}, [value]);

		const contextValue = useMemo<RootContext>(
			() => ({
				onDrag: handleDrag,
				onDrop: handleDrop,
				onKeyDown: handleKeyDown,
				editorRef,
				setDraggedElement,
				disabled,
				onChange,
				error,
				errorContent,
				placeholder,
				value,
			}),
			[handleDrag, handleDrop, handleKeyDown, value, placeholder, error, errorContent, setDraggedElement, disabled],
		);

		return <DroppableTextEditorContext.Provider value={contextValue}>
			<Box css={css} ref={ref}>
				{children}
			</Box>
		</DroppableTextEditorContext.Provider>;
	});
