import {styled} from '@esgi/ui/theme';
import {FlexBox, Box} from '@esgi/ui/layout';

export const DrawerContainer = styled(FlexBox, {
	flexDirection: 'row',
	overflow: 'hidden',
});

export const DrawerLeftPanel = styled(FlexBox, {
	flexGrow: 1,
	flexDirection: 'column',
});

export const DrawerRightPanel = styled(FlexBox, {
	flexShrink: 0,
	flexDirection: 'column',
	display: 'none',

	'@media (min-width: 1024px)': {
		display: 'flex',
		width: 340,
	},
});

export const VerticalDivider = styled(Box, {
	width: 1,
	backgroundColor: '$neutral88',
	margin: '0px 20px',
});
