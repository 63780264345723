import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@stitches/react';

export const Container = styled(GridBox, {
	alignItems: 'center',
	justifyContent: 'space-between',
	gridAutoFlow: 'column',
	paddingTop: '$3',
	paddingRight: 20,
	paddingLeft: 20,
});


export const ResetFiltersButton = styled(Button, {
	alignItems: 'center',
	color: 'mediumContrast',
});
