import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import React from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import BaseProps from './base-props';

export default class TeacherUnassignDialog extends React.PureComponent<BaseProps> {
	public render() {
		const currentUser = userStorage.get();
		return <Modal className='student_operation_dialog remove_student_dialog'>
			<ModalHeader title='Unassign student(s) from'>
			</ModalHeader>
			<ModalBody>
				Are you sure you want to unassign the selected student(s) from their {(currentUser.userType !== UserType.T ? 'Teacher, ' : '')}Classes and Groups?
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => this.props.closeClicked()}
						title='Cancel'
					/>
					<Primary
						onClick={() => this.props.processClicked()}
						title='Confirm'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
