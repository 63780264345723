import {useCallback, useEffect, useState} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {CreateAssignmentSpecialistService} from '../../../services/create-assignment-specialist-service';
import {StudentInfoSpecialist} from '../../../types/specialist-types';
import {GroupDropdown, GroupDropdownItem} from './group-dropdown';
import {StudentsDropdown} from './students-dropdown';
import {AddSpecificModalSpecialist} from './add-specific-modal/add-specific-modal-specialist';

interface Props {
	service: CreateAssignmentSpecialistService;
}

export function DropdownsSectionSpecialist({service}: Props) {
	const [showAddModal, setShowAddModal] = useState(false);

	const groups = useBehaviorSubject(service.groups);
	const students = useBehaviorSubject(service.students);
	const selectedStudents = useBehaviorSubject(service.selectedStudents);

	const [dropdownGroups, setDropdownGroups] = useState<GroupDropdownItem[]>([]);
	const [previousDropdownGroups, setPreviousDropdownGroups] = useState<GroupDropdownItem[] | null>(null);

	useEffect(() => {
		const selectedStudentIds = selectedStudents.map(({id}) => id);

		const dropdownGroupList: GroupDropdownItem[] = groups.map((group) => {
			const selected = group.students.every(({id}) => selectedStudentIds.includes(id));

			return {
				id: group.id,
				name: group.name,
				hasStudents: Boolean(group.students.length),
				selected,
			};
		});

		setDropdownGroups(dropdownGroupList);
	}, [groups, selectedStudents]);

	const onSaveDropdownGroups = useCallback(() => {
		setPreviousDropdownGroups(dropdownGroups);

		const selectedGroupIds = dropdownGroups.filter(({selected}) => selected).map(({id}) => id);

		const selectedStudentIdsInGroups = groups
			.map(({id, students}) => (selectedGroupIds.includes(id) ? students : []))
			.flat()
			.map(({id}) => id);

		const filteredStudents = students.filter(({id}) => selectedStudentIdsInGroups.includes(id));

		service.setSelectedStudents(filteredStudents);
	}, [dropdownGroups, selectedStudents, groups, students]);

	const onCancelDropdownGroups = useCallback(() => {
		if(previousDropdownGroups) {
			setDropdownGroups(previousDropdownGroups);
		}
	}, [previousDropdownGroups]);

	const appendSelectedStudents = (students: StudentInfoSpecialist[]) => {
		service.setSelectedStudents([...selectedStudents, ...students]);
	};

	const [prevSelectedStudents, setPrevSelectedStudents] = useState<StudentInfoSpecialist[]>([]);

	return (
		<div className={styles.dropdownsSection}>
			<div>
				<div className={styles.label}>Groups</div>
				<GroupDropdown
					groups={dropdownGroups}
					setGroups={setDropdownGroups}
					onSave={onSaveDropdownGroups}
					onCancel={onCancelDropdownGroups}
				/>
			</div>
			<div>
				<div className={styles.label}>Students</div>
				<StudentsDropdown
					students={students}
					selectedStudents={selectedStudents}
					setSelectedStudents={service.setSelectedStudents.bind(service)}
					showAddSpecific={() => setShowAddModal(true)}
					setPrevSelectedStudents={setPrevSelectedStudents}
				/>
			</div>
			{showAddModal && (
				<AddSpecificModalSpecialist
					service={service}
					onClose={() => setShowAddModal(false)}
					onSelectedStudentsChange={appendSelectedStudents}
					prevSelectedStudents={prevSelectedStudents}
				/>
			)}
		</div>
	);
}
