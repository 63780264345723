import {FlexBox} from '@esgillc/ui-kit/layout';
import styles from './settings.module.less';
import {ReactNode} from 'react';

interface Props {
	title: string;
	className?: string;
	children?: ReactNode;
}

export default function SettingsItem(props: Props): JSX.Element {
	return <FlexBox direction='row' className={styles.item}>
		<div className={styles.title}>
			{props.title}:
		</div>
		<div>
			{props.children}
		</div>
	</FlexBox>;
}
