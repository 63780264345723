import {PropsWithChildren} from 'react';
import styles from './styles.module.less';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

type AddSpecificModalContainerProps = PropsWithChildren<{
	subTitle: string;
	onClose: VoidFunction;
	onSave: VoidFunction;
}>;

export function AddSpecificModalContainer({subTitle, children, onClose, onSave}: AddSpecificModalContainerProps) {
	const modalManagerRef = useModal();

	const handleCancel = useCloseModal(modalManagerRef, onClose);
	const handleSave = useCloseModal(modalManagerRef, () => {
		onSave();
		onClose();
	});

	return (
		<Modal modalManagerRef={modalManagerRef} className={styles.modal}>
			<Modal.Header className={styles.container}>
				<div className={styles.title}>Add Specific Students</div>
				<div className={styles.subTitle}>{subTitle}</div>
			</Modal.Header>
			<Modal.Body className={styles.body}>{children}</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={handleCancel}>Cancel</Buttons.Gray>
				<Buttons.Contained onClick={handleSave}>Save</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	);
}
