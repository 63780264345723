import {TestTypeService} from './test-type-service';
import {Observable} from 'rxjs';
import {
	ResultRequest,
	ResultScoreResponse,
	UpdateResponse,
	UpdateSSRequest,
} from '../types';

export class SingleScoreService extends TestTypeService {
	public load(request: ResultRequest): Observable<ResultScoreResponse> {
		return this.httpClient.ESGIApi.get<ResultScoreResponse>(
			this.controller,
			'score-result',
			request,
		).asObservable();
	}

	public update(request: UpdateSSRequest): Observable<UpdateResponse> {
		return this.httpClient.ESGIApi
			.post<UpdateResponse>(this.controller, 'update-ss-session', request)
			.asObservable();
	}
}
