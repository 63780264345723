import Api from 'pages/home/components/report-button/api';
import {Box} from 'shared/right-panel/box/box';
import {BoxType} from 'shared/right-panel/box/models';
import {BoxModel} from 'pages/home/components/report-button/models';
import {BoxTitle} from 'shared/right-panel/box/components/box-title/box-title';
import {BoxBody} from 'shared/right-panel/box/components/box-body/box-body';
import React from 'react';
import {SubjectType} from '@esgi/core/enums';
import BingoButton from './buttons/bingo';
import FlashCardsButton from './buttons/flash-cards';
import GradeScalesButton from './buttons/grade-scales';
import GrowthReportButton from './buttons/growth-report';
import ItemAnalysisButtons from './buttons/item-analysis';
import ParentLetterButton from './buttons/parent-letter';
import PieChartsButton from './buttons/pie-charts';
import {StudentDetailButton} from 'pages/home/components/report-button/buttons/student-detail-button';
import StudentProgressButton from './buttons/student-progress';
import StudentsReportButton from './buttons/students-report';
import TeacherActivityButton from './buttons/teacher-activity';
import TotalReportButton from './buttons/total-report';
import {UntestedStudentsButton} from './buttons/untested-students';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	PreAssessHierarchyLevel,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {RightPanelBox} from 'shared/right-panel/right-panel';
import rightPanelStyles from '../right-panel/right-panel.module.less';
import styles from './report-button.module.less';
import {EventBusDispatcher} from '@esgillc/events';
import {HomepageOutsideChangeEvent} from './events';
import {SubjectModel} from '../../services/subjects-service/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import SpecialistActivityButton from './buttons/specialist-activity';
import RubricReportButton from './buttons/rubric';
import WritingPractice from './buttons/writing-practice';
import {userStorage, UserType} from '@esgi/core/authentication';
import PreassessActivityButton from './buttons/preassess-activity';
import {ITrackModel} from 'pages/home/components/school-year-selector/api';
import {IepGoalsButton} from '../iep-goals-button/iep-goals';
import {IepProgressButton} from './buttons/iep-progress';

class State {
	boxes: BoxModel[];
	loaded: boolean;
}

class Props {
	subject: SubjectModel;
	hierarchy: HierarchyInstance;
	canTest: boolean;
	autoTrack: boolean;
	globalSchoolYearID: number;
	loaded: () => void;
	visible: boolean;
	noStudents: boolean;
	trackChanged: (value: ITrackModel) => void;
}

export default class ReportsPanel extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();

	constructor(props) {
		super(props);
		this.state = {
			boxes: [],
			loaded: false,
		};
	}

	render() {
		if (!this.props.visible) {
			return null;
		}

		return <>
			<RightPanelBox className={styles.rightPanelBox}>
				<hr/>
				{this.renderBody()}
			</RightPanelBox>
		</>;
	}

	componentDidMount() {
		Api.init().subscribe(r => this.setState({boxes: r.boxes, loaded: true}, () => this.props.loaded()));
	}

	getBoxState(level: BoxType) {
		if (this.state.loaded) {
			const result = this.state.boxes.find(x => x.level === level);
			if (result) {
				return result.open;
			}
		}

		return true;
	}

	public renderBody() {
		const h = this.props.hierarchy;

		if (!h) {
			return null;
		}

		if (h.mode === HierarchyMode.Classic) {
			const selected = h.classic.selected;

			switch (selected.level) {
				case ClassicHierarchyLevel.Class:
				case ClassicHierarchyLevel.Group:
				case ClassicHierarchyLevel.Student:
					return this.renderClassBody();
				case ClassicHierarchyLevel.Teacher:
					return this.renderTeacherBody();
				case ClassicHierarchyLevel.School:
					return this.renderSchoolBody();
				case ClassicHierarchyLevel.District:
					return this.renderDistrictBody();
				case ClassicHierarchyLevel.SchoolsGroup:
					return this.renderSchoolsGroupBody();
				case ClassicHierarchyLevel.TeachersGroup:
					return this.renderTeacherGroupBody();
				default:
					return null;
			}
		}
		if (h.mode === HierarchyMode.Specialist) {
			const s = h.specialist;
			const selected = h.specialist.selected;

			if (s.userID === 0) {
				if (s.type === UserType.ISS || this.currentUser.userType === UserType.C) {
					if (selected.level === SpecialistHierarchyLevel.GroupOfSpecialists) {
						return this.renderGroupOfSpecialistsSchoolBody();
					}
					return this.renderSpecialistSchoolBody();
				}
				if (s.type === 0 || s.type === UserType.ISD) {
					if (selected.level === SpecialistHierarchyLevel.GroupOfSpecialists) {
						return this.renderGroupOfSpecialistsDistrictBody();
					}
					return this.renderSpecialistDistrictBody();
				}
			} else if (s.groupID === 0) {
				return this.renderTeacherBody();
			}
			return this.renderSpecialistGroupBody();
		}
		if (h.mode === HierarchyMode.PreAssess) {
			const pa = h.preAssess;

			if (pa.userID === 0) {
				return this.renderSpecialistDistrictBody();
			} else if (pa.groupID === 0) {
				return this.renderTeacherBody();
			}
			return this.renderClassBody();
		}
	}

	public renderSpecialistGroupBody() {
		const params = new URLSearchParams(location.search);
		const viewAsAdmin = (params.get('fromAdmin') === 'true'
			&& params.get('reviewData') === 'true')
			|| this.currentUser.userType === UserType.D;

		return (
			<>
				<Box>
					<BoxTitle
						opened={this.getBoxState(BoxType.StudentActivities)}
						className={rightPanelStyles.boxTitle}
						onOpenCloseChanged={() => this.changed.openClose(BoxType.StudentActivities, !this.getBoxState(BoxType.StudentActivities))}
					>
						Student Activities
					</BoxTitle>
					<BoxBody opened={this.getBoxState(BoxType.StudentActivities)}>
						{this.renderBingo()}
						{this.renderFlashCards()}
						{this.renderWritingPractice()}
					</BoxBody>
				</Box>
				<hr/>
				<Box>
					<BoxTitle
						opened={this.getBoxState(BoxType.StudentReports)}
						className={rightPanelStyles.boxTitle}
						onOpenCloseChanged={() => this.changed.openClose(BoxType.StudentReports, !this.getBoxState(BoxType.StudentReports))}
					>
						Student Reports
					</BoxTitle>
					<BoxBody opened={this.getBoxState(BoxType.StudentReports)}>
						{this.renderParentLetter()}
						{this.renderStudentDetail()}
						{this.renderStudentProgress()}
					</BoxBody>
				</Box>
				<hr/>
				{!viewAsAdmin &&
					<>
						<Box>
							<BoxTitle
								opened={this.getBoxState(BoxType.SpecialistReports)}
								className={rightPanelStyles.boxTitle}
								onOpenCloseChanged={() => this.changed.openClose(BoxType.SpecialistReports, !this.getBoxState(BoxType.SpecialistReports))}
							>
								Specialist Reports
							</BoxTitle>
							<BoxBody opened={this.getBoxState(BoxType.SpecialistReports)}>
								{this.renderIepGoals()}
								{this.renderIepProgress()}
							</BoxBody>
						</Box>
						<hr/>
					</>
				}
				<Box>
					<BoxTitle
						opened={this.getBoxState(BoxType.ClassReports)}
						className={rightPanelStyles.boxTitle}
						onOpenCloseChanged={() => this.changed.openClose(BoxType.ClassReports, !this.getBoxState(BoxType.ClassReports))}
					>
						Class Reports
					</BoxTitle>
					<BoxBody opened={this.getBoxState(BoxType.ClassReports)}>
						{this.renderGradeReport()}
						{this.renderTotalReport()}
						{this.renderItemAnalysis()}
						{this.renderPieCharts()}
						{this.renderRubricReport()}
						{this.renderUntestedStudents()}
					</BoxBody>
				</Box>
			</>
		);
	}

	renderClassBody() {
		return <>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.StudentActivities)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.StudentActivities, !this.getBoxState(BoxType.StudentActivities))}>
					Student Activities
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.StudentActivities)}>
					{this.renderBingo()}
					{this.renderFlashCards()}
					{this.renderWritingPractice()}
				</BoxBody>
			</Box>
			<hr/>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.StudentReports)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.StudentReports, !this.getBoxState(BoxType.StudentReports))}>
					Student Reports
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.StudentReports)}>
					{this.renderParentLetter()}
					{this.renderStudentDetail()}
					{this.renderStudentProgress()}
				</BoxBody>
			</Box>
			<hr/>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.ClassReports)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.ClassReports, !this.getBoxState(BoxType.ClassReports))}>
					Class Reports
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.ClassReports)}>
					{this.renderGradeReport()}
					{this.renderTotalReport()}
					{this.renderItemAnalysis()}
					{this.renderPieCharts()}
					{this.renderRubricReport()}
					{this.renderUntestedStudents()}
				</BoxBody>
			</Box>
		</>;
	}

	renderTeacherBody() {
		return <>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.StudentActivities)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.StudentActivities, !this.getBoxState(BoxType.StudentActivities))}>
					Student Activities
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.StudentActivities)}>
					{this.renderBingo()}
					{this.renderWritingPractice()}
				</BoxBody>
			</Box>
			<hr/>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.ClassReports)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.ClassReports, !this.getBoxState(BoxType.ClassReports))}>
					Class Reports
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.ClassReports)}>
					{this.renderItemAnalysis()}
					{this.renderPieCharts()}
					{this.renderUntestedStudents()}
				</BoxBody>
			</Box>
		</>;
	}

	renderSchoolBody() {
		return <>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.SchoolReports)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.SchoolReports, !this.getBoxState(BoxType.SchoolReports))}>
					School Reports
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.SchoolReports)}>
					{this.renderItemAnalysis()}
					{this.renderPieCharts()}
					{this.renderGradeReport()}
					{this.renderStudentsReport()}
					{this.renderTotalReport()}
				</BoxBody>
			</Box>
		</>;
	}

	renderSpecialistSchoolBody() {
		return <>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.SpecialistReports)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.SpecialistReports, !this.getBoxState(BoxType.SpecialistReports))}>
					Specialist Reports
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.SpecialistReports)}>
					{this.renderStudentsReport()}
					{this.renderTotalReport()}
					{this.renderGradeReport()}
					{this.renderItemAnalysis()}
					{this.renderPieCharts()}
					<hr/>
				</BoxBody>
			</Box>;
		</>;
	}

	renderDistrictBody() {
		return <>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.DistrictReports)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.changed.openClose(BoxType.DistrictReports, !this.getBoxState(BoxType.DistrictReports))}>
					District Reports
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.DistrictReports)}>
					{this.renderGradeReport()}
					{this.renderGrowthReport()}
					{this.renderStudentsReport()}
					{this.renderTotalReport()}
					{this.renderItemAnalysis()}
					{this.renderPieCharts()}
					{this.renderTeacherActivity()}
				</BoxBody>
			</Box>
			<hr/>

		</>;
	}

  renderSchoolsGroupBody() {
    return <>
      <Box>
        <BoxTitle opened={this.getBoxState(BoxType.SchoolsGroupReports)}
                  className={rightPanelStyles.boxTitle}
                  onOpenCloseChanged={() => this.changed.openClose(BoxType.SchoolsGroupReports, !this.getBoxState(BoxType.SchoolsGroupReports))}>
          School Group Reports
        </BoxTitle>
        <BoxBody opened={this.getBoxState(BoxType.SchoolsGroupReports)}>
          {this.renderGradeReport()}
          {this.renderGrowthReport()}
          {this.renderStudentsReport()}
          {this.renderTotalReport()}
          {this.renderItemAnalysis()}
          {this.renderPieCharts()}
          {this.renderTeacherActivity()}
        </BoxBody>
      </Box>
      <hr/>

    </>;
  }

  renderTeacherGroupBody() {
    if (this.currentUser.userType === UserType.D) {
      return <>
        <Box>
          <BoxTitle opened={this.getBoxState(BoxType.TeachersGroupReports)}
                    className={rightPanelStyles.boxTitle}
                    onOpenCloseChanged={() => this.changed.openClose(BoxType.TeachersGroupReports, !this.getBoxState(BoxType.TeachersGroupReports))}>
            Teacher Group Reports
          </BoxTitle>
          <BoxBody opened={this.getBoxState(BoxType.TeachersGroupReports)}>
            {this.renderGradeReport()}
            {this.renderGrowthReport()}
            {this.renderStudentsReport()}
            {this.renderTotalReport()}
            {this.renderItemAnalysis()}
            {this.renderPieCharts()}
            {this.renderTeacherActivity()}
          </BoxBody>
        </Box>
        <hr/>
      </>;
    } else {
      return <>
        <Box>
          <BoxTitle opened={this.getBoxState(BoxType.TeachersGroupReports)}
                    className={rightPanelStyles.boxTitle}
                    onOpenCloseChanged={() => this.changed.openClose(BoxType.TeachersGroupReports, !this.getBoxState(BoxType.TeachersGroupReports))}>
            Teacher Group Reports
          </BoxTitle>
          <BoxBody opened={this.getBoxState(BoxType.TeachersGroupReports)}>
            {this.renderItemAnalysis()}
            {this.renderPieCharts()}
            {this.renderGradeReport()}
            {this.renderStudentsReport()}
            {this.renderTotalReport()}
          </BoxBody>
        </Box>
        <hr/>
      </>;
    }
  }

  renderGroupOfSpecialistsDistrictBody() {
    return <>
      <Box>
        <BoxTitle opened={this.getBoxState(BoxType.GroupOfSpecialistsReports)}
                  className={rightPanelStyles.boxTitle}
                  onOpenCloseChanged={() => this.changed.openClose(BoxType.GroupOfSpecialistsReports, !this.getBoxState(BoxType.GroupOfSpecialistsReports))}>
          Specialist Group Reports
        </BoxTitle>
        <BoxBody opened={this.getBoxState(BoxType.GroupOfSpecialistsReports)}>
          {this.renderStudentsReport()}
          {this.renderTotalReport()}
          {this.renderGradeReport()}
          {this.renderGrowthReport()}
          {this.renderPieCharts()}
          {this.renderItemAnalysis()}
          {this.renderTeacherActivity()}
        </BoxBody>
      </Box>
      <hr/>

    </>;
  }

  renderGroupOfSpecialistsSchoolBody() {
    return <>
      <Box>
        <BoxTitle opened={this.getBoxState(BoxType.GroupOfSpecialistsReports)}
                  className={rightPanelStyles.boxTitle}
                  onOpenCloseChanged={() => this.changed.openClose(BoxType.GroupOfSpecialistsReports, !this.getBoxState(BoxType.GroupOfSpecialistsReports))}>
          Specialist Group Reports
        </BoxTitle>
        <BoxBody opened={this.getBoxState(BoxType.GroupOfSpecialistsReports)}>
          {this.renderStudentsReport()}
          {this.renderTotalReport()}
          {this.renderGradeReport()}
          {this.renderItemAnalysis()}
          {this.renderPieCharts()}
        </BoxBody>
      </Box>
      <hr/>

    </>;
  }

	renderSpecialistDistrictBody() {
		return <Box>
			<BoxTitle opened={this.getBoxState(BoxType.SpecialistReports)}
			          className={rightPanelStyles.boxTitle}
			          onOpenCloseChanged={() => this.changed.openClose(BoxType.SpecialistReports, !this.getBoxState(BoxType.SpecialistReports))}>
				Specialist Reports
			</BoxTitle>
			<BoxBody opened={this.getBoxState(BoxType.SpecialistReports)}>
				{this.renderStudentsReport()}
				{this.renderTotalReport()}
				{this.renderGradeReport()}
				{this.renderGrowthReport()}
				{this.renderPieCharts()}
				{this.renderItemAnalysis()}
				{this.renderTeacherActivity()}
				<hr/>
			</BoxBody>
		</Box>;
	}

	renderTeacherActivity() {
		const h = this.props.hierarchy;
		if (h) {
			switch (h.mode) {
				case HierarchyMode.Classic:
					return <TeacherActivityButton
            schoolsGroupID={h.classic.schoolsGroupID}
            teachersGroupID={h.classic.teachersGroupID}
            noStudents={this.props.noStudents}/>;
				case HierarchyMode.Specialist:
					return <SpecialistActivityButton noStudents={this.props.noStudents} groupOfSpecialistID={h.specialist.groupOfSpecialistsID}/>;
				case HierarchyMode.PreAssess:
					return <PreassessActivityButton noStudents={this.props.noStudents}/>;
			}
		}
		return null;
	}

	renderPieCharts() {
		if (this.props.subject) {
			return <PieChartsButton
				hierarchy={this.props.hierarchy}
				subjectID={this.props.subject.id}
				subjectType={this.props.subject.type}
				applyHomepageChanges={this.applyHomepageChanges}
				noStudents={this.props.noStudents}
			/>;
		}
	}

	renderStudentsReport() {
		if (this.props.subject) {
			return <StudentsReportButton hierarchy={this.props.hierarchy}
			                             subjectId={this.props.subject.id}
			                             subjectType={this.props.subject.type}
			                             applyHomepageChanges={this.applyHomepageChanges}
			                             noStudents={this.props.noStudents}
			                             globalSchoolYearId={this.props.globalSchoolYearID}/>;
		}
	}

	renderItemAnalysis() {
		if (this.props.subject) {
			return <ItemAnalysisButtons
				hierarchy={this.props.hierarchy}
				subjectID={this.props.subject.id}
				subjectType={this.props.subject.type}
				applyHomepageChanges={this.applyHomepageChanges}
				noStudents={this.props.noStudents}
			/>;
		}
	}

	renderTotalReport() {
		if (this.props.subject) {
			return <TotalReportButton hierarchy={this.props.hierarchy}
			                          subjectId={this.props.subject.id}
			                          subjectType={this.props.subject.type}
			                          applyHomepageChanges={this.applyHomepageChanges}
			                          autoTrack={this.props.autoTrack}
			                          noStudents={this.props.noStudents}
			                          globalSchoolYearId={this.props.globalSchoolYearID}
																trackChanged={this.props.trackChanged}/>;
		}
	}

	renderRubricReport() {
		if (this.props.subject) {
			return <RubricReportButton
				subjectID={this.props.subject.id}
				subjectType={this.props.subject.type}
				hierarchy={this.props.hierarchy}/>;
		}
	}

	renderGradeReport() {
		if (this.props.subject) {
			return <GradeScalesButton
				hierarchy={this.props.hierarchy}
				subjectID={this.props.subject.id}
				subjectType={this.props.subject.type}
				applyHomepageChanges={this.applyHomepageChanges}
				autoTrack={this.props.autoTrack}
				noStudents={this.props.noStudents}
				trackChanged={this.props.trackChanged}
			/>;
		}
	}

	renderUntestedStudents() {
		if (this.props.subject) {
			return <UntestedStudentsButton
				applyHomepageChanges={this.applyHomepageChanges}
				hierarchy={this.props.hierarchy}
				canRun={this.props.canTest}
				subjectID={this.props.subject.id}
				subjectType={this.props.subject.type}
				noStudents={this.props.noStudents}
			/>;
		}
	}

	renderGrowthReport() {
		if (this.props.subject) {
			return <GrowthReportButton hierarchy={this.props.hierarchy}
			                           subjectID={this.props.subject.id}
			                           noStudents={this.props.noStudents}
			                           subjectType={this.props.subject.type}/>;
		}
	}

	renderParentLetter() {
		if (this.props.subject) {
			return <ParentLetterButton
				hierarchy={this.props.hierarchy}
				applyHomepageChanges={this.applyHomepageChanges}
				subjectID={this.props.subject.id}
				subjectType={this.props.subject.type}
				noStudents={this.props.noStudents}
			/>;
		}
	}

	renderFlashCards() {
		if (this.props.subject) {
			return <FlashCardsButton
				subjectID={this.props.subject.id}
				subjectType={this.props.subject.type}
				applyHomepageChanges={this.applyHomepageChanges}
				hierarchy={this.props.hierarchy}
				noStudents={this.props.noStudents}
			/>;
		}
	}

	renderBingo() {
		if (this.props.subject) {
			return <BingoButton hierarchy={this.props.hierarchy}
			                    selectedSubjectID={this.props.subject.id}
			                    noStudents={this.props.noStudents}/>;
		}
	}

	renderWritingPractice() {
		return <WritingPractice hierarchy={this.props.hierarchy}/>;
	}

	renderStudentDetail() {
		let available: boolean;
		const h = this.props.hierarchy;
		if (h.mode === HierarchyMode.Classic) {
			const selected = h.classic.selected;
			available = selected.level === ClassicHierarchyLevel.Class
				|| selected.level === ClassicHierarchyLevel.Group
				|| selected.level === ClassicHierarchyLevel.Student;
		} else if (h.mode === HierarchyMode.Specialist) {
			const selected = h.specialist.selected;
			available = selected.level === SpecialistHierarchyLevel.Group
				|| selected.level === SpecialistHierarchyLevel.Student;
		} else if (h.mode === HierarchyMode.PreAssess) {
			const selected = h.preAssess.selected;
			available = selected.level === PreAssessHierarchyLevel.Group
				|| selected.level === PreAssessHierarchyLevel.Student;
		}
		if (this.props.subject && available) {
			return <StudentDetailButton hierarchy={this.props.hierarchy}
			                            subjectId={this.props.subject.id}
			                            subjectType={this.props.subject.type}
			                            autoTrack={this.props.autoTrack}
			                            applyHomepageChanges={this.applyHomepageChanges}
			                            noStudents={this.props.noStudents}
																	trackChanged={this.props.trackChanged}
			/>;
		}

		return null;
	}

	renderStudentProgress() {
		if (this.props.subject) {
			return <StudentProgressButton subjectId={this.props.subject.id}
			                              subjectType={this.props.subject.type}
			                              hierarchy={this.props.hierarchy}
			                              autoTrack={this.props.autoTrack}
			                              noStudents={this.props.noStudents}
			                              applyHomepageChanges={this.applyHomepageChanges}
																		trackChanged={this.props.trackChanged}

			/>;
		}

		return null;
	}

	public renderIepGoals() {
		if (this.props.subject) {
			return (
				<IepGoalsButton
					noStudents={this.props.noStudents}
					hierarchy={this.props.hierarchy.snapshot}
					subjectId={this.props.subject.id}
					subjectType={this.props.subject.type}
					globalSchoolYearID={this.props.globalSchoolYearID}
				/>
			);
		}
	}

	public renderIepProgress() {
		if (this.props.subject) {
			return (
				<IepProgressButton
					hierarchy={this.props.hierarchy}
					noStudents={this.props.noStudents}
					subjectId={this.props.subject.id}
				/>
			);
		}
	}

	private applyHomepageChanges = (changes: ChangesCollector) => {
		if (changes.isTouched) {
			const event = new HomepageOutsideChangeEvent();
			if (changes.subjectTabID.touched || changes.subjectTabType.touched) {
				event.subject = {id: changes.subjectTabID.value, type: changes.subjectTabType.value};
			}
			if (changes.classID?.touched) {
				event.hierarchy.classID = changes.classID.value;
			}
			if (changes.groupID?.touched) {
				event.hierarchy.groupID = changes.groupID.value;
			}
			if (changes.studentID?.touched) {
				event.hierarchy.studentID = changes.studentID.value;
			}
			if (changes.schoolID?.touched) {
				event.hierarchy.schoolID = changes.schoolID.value;
			}
			if (changes.teacherID?.touched) {
				event.hierarchy.teacherID = changes.teacherID.value;
			}
			EventBusDispatcher.dispatch(HomepageOutsideChangeEvent, event);
		}
	};

	changed = {
		openClose: (type: BoxType, state: boolean) => {
			const boxes = [...this.state.boxes];
			if (boxes.filter(t => t.level === type).length === 0) {
				boxes.push({level: type, open: state});
			} else {
				const box = boxes.find(t => t.level === type);
				box.open = state;
			}

			Api.boxOpenClose(type, state);

			this.setState({boxes: boxes});
		},
	};
}

export namespace ReportsPanelEvents {
	export function SubjectChanged(type: SubjectType, id: number): SubjectChanged.Args {
		return {
			type,
			id,
		};
	}

	export module SubjectChanged {
		export interface Args {
			type: SubjectType,
			id: number
		}
	}
}
