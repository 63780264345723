import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {Class, classesStore, Student, studentsStore} from '@esgi/main/libs/store';
import {StudentWithUnit} from '@esgi/main/kits/activities';
import {BingoGenerateParams, BingoStudent} from '../model';
import {BingoPlace} from '../types';

export class DownloadService extends BaseService {
	public readonly isBusy$ = new BehaviorSubject(false);
	public previewURL$ = new BehaviorSubject<string | null>(null);

	private readonly classes$ = new BehaviorSubject<Class[]>([]);
	private readonly students$ = new BehaviorSubject<Student[]>([]);

	private readonly currentUser = userStorage.get();
	private readonly storageClasses = classesStore();
	private readonly storageStudents = studentsStore();

	constructor() {
		super();

		this.storageClasses.get().subscribe((value) => this.classes$.next(value.data));
		this.storageStudents.get().subscribe((value) => this.students$.next(value.data));
	}

	public clearPreviewData() {
		this.previewURL$.next(null);
	}

	public handleDownload(props: BingoGenerateParams, isPreview: boolean) {
		const documentDate = new Date().toLocaleDateString('en-US');
		const fileName = `Bingo_${documentDate}.pdf`;
		if (this.previewURL$.value) {
			this.downloadByURL(this.previewURL$.value, fileName);
			return;
		}

		const request = this.generateRequest(props);
		this.isBusy$.next(true);
		this.httpClient.ESGIApi.file(
			'/v2/modules/activities/bingo',
			'create-game',
			fileName,
			request,
			null,
			false,
			isPreview
		).subscribe({
			next: (blob) => {
				if (isPreview) {
					this.previewURL$.next(URL.createObjectURL(blob));
				}
			},
			complete: () => {
				this.isBusy$.next(false);
			},
		});
	}

	public override dispose() {
		super.dispose();
		this.storageClasses.dispose();
		this.storageStudents.dispose();
	}
	
	private downloadByURL (url: string, filename: string) {
		const link = document.createElement('a');
		link.setAttribute('type', 'hidden');
		link.href = url;
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	private generateRequest({subject, settings, students, tests, questions}: BingoGenerateParams) {
		const selectedStudents = this.getSelectedStudents(students);
		const selectedClasses = this.getSelectedClasses(selectedStudents);
		const cardsCountMultiplier = settings.place === BingoPlace.Home ? Number(settings.peopleAtHomeCount) : 1;

		return {
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
			difficulty: settings.difficulty,
			cardSize: settings.cardSize,
			place: settings.place,
			cardsCount: Number(settings.cardsCount) * cardsCountMultiplier,
			sheetsCount: settings.sheetsCount,
			teacherID: this.currentUser.userID,
			specialistID: 0,
			specialistGroupNames: [],
			classNames: selectedClasses,
			students: selectedStudents,
			tests,
			questions,
			subject,
		};
	}

	private getSelectedStudents(students: StudentWithUnit[]) {
		const studentList = students.map((item) => (
			this.students$.value.find((student) => student.id === item.studentId)
		));

		return studentList.map((item) => ({
			id: item.id,
			name: `${item.firstName} ${item.lastName}`,
		}));
	}

	private getSelectedClasses(selectedStudents: BingoStudent[]) {
		const classList = selectedStudents.map((student) => (
			this.classes$.value.find((classItem) => classItem.studentIDs.includes(student.id))
		));

		return Array.from(new Set(classList.map((item) => item.name)));
	}
}