import {createContext} from '@esgi/ui';
import {SortType} from './models';

export interface TestsRootContextValue {
	sort: SortType;
	handleSelectAll: () => void;
	handleSortDirectionChange: () => void;
}

export const {
	Context: TestsRootContext,
	useHook: useTestsRootContext,
} = createContext<TestsRootContextValue>('Tests Root Context');