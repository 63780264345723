import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const InfoItemWrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	gap: '$2',

	variants: {
		viewType: {
			grid: {
				justifyContent: 'start',
				gridTemplateColumns: '74px auto',
			},
		},
	},
});