import {Observable, of, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {RubricModel} from '../../../common/types';
import {executeRequest, updateValue} from './utils';

export type TestSnapshot = RubricModel & {
	snapshotDate?: Date,
};

export default class LocalSavingService extends BaseService {
	private db: IDBDatabase;

	private get testsStore(): IDBObjectStore {
		return this.db?.transaction('tests', 'readwrite').objectStore('tests');
	}

	public init(): Observable<void> {
		const subject = new Subject<void>();
		let openRequest = indexedDB.open('temp-rubric-test', 1);

		openRequest.onupgradeneeded = function () {
			let db = openRequest.result;

			if (!db.objectStoreNames.contains('tests')) {
				const store = db.createObjectStore('tests', {keyPath: 'userID', autoIncrement: true});
			}
		};

		openRequest.onsuccess = () => {
			this.db = openRequest.result;
			subject.next();
			subject.complete();
		};

		openRequest.onerror = function () {
			console.error('Error', openRequest.error);
			subject.error(openRequest.error);
		};

		return subject.asObservable();
	}

	public removeTest() {
		const userID = userStorage.get().userID;
		return executeRequest<void>(this.testsStore.delete(userID));
	}

	public saveTest(data: TestSnapshot) {
		if (!this.db) {
			return of();
		}
		const userID = userStorage.get().userID;
		const newData = {...data, snapshotDate: new Date(), userID: userID} as TestSnapshot;

		return this.getTest().pipe(switchMap((r) => {
			if (r) {
				return updateValue<any>(this.testsStore, userID, newData);
			} else {
				return executeRequest(this.testsStore.add(newData));
			}
		}));
	}

	public getTest(): Observable<TestSnapshot> {
		if (!this.db) {
			return of(null);
		}
		const userID = userStorage.get().userID;
		return executeRequest<TestSnapshot>(this.testsStore.get(userID));
	}
}
