import React from 'react';
import {OverlayScrollbars} from 'overlayscrollbars';
import {ScrollActionsState} from './types';

type MatrixContextType = {
	osInstance: OverlayScrollbars;
	scrollActionState: ScrollActionsState;
};

export const MatrixContext = React.createContext<MatrixContextType>({} as MatrixContextType);
