import {ComponentProps, forwardRef} from 'react';
import {FooterWrapper} from './index.styled';
import {GridBox} from '@esgi/ui/layout';

type AlertFooterProps = ComponentProps<typeof GridBox>;

export const AlertFooter = forwardRef<HTMLDivElement, AlertFooterProps>(
	(
		{dataCy = 'ui-kit-alert-footer', display = 'inlineGrid', flow = 'column', justify = 'end', ...props},
		forwardedRef,
	) => {
		return (
			<FooterWrapper dataCy={dataCy} display={display} flow={flow} justify={justify} {...props} ref={forwardedRef} />
		);
	},
);
