import {ToggleGroup} from '@esgi/ui/controls';
import {tabItems} from './constants';
import {Text} from '@esgi/ui/typography';
import {ToggleGroupContent, ToggleGroupItem} from './index.styled';
import {StudentTabId} from '../../types';
import React from 'react';

type Props = {
	activeStudentTab: StudentTabId;
	setActiveStudentTab: React.Dispatch<React.SetStateAction<StudentTabId>>;
};

export function StudentTabs({activeStudentTab, setActiveStudentTab}: Props) {
	return (
		<ToggleGroup.Root onValueChange={(tabId) => setActiveStudentTab(tabId as StudentTabId)} value={activeStudentTab}>
			<ToggleGroupContent dataCy='student-tabs'>
				{tabItems.map(({label, tabId}) => (
					<ToggleGroupItem value={tabId} disabled={tabId === activeStudentTab} applyDisabledStyles={false} key={tabId}>
						<Text size='medium' bold>
							{label}
						</Text>
					</ToggleGroupItem>
				))}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
