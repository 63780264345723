import {ClassicHierarchySnapshot, PreAssessHierarchySnapshot, SpecialistHierarchySnapshot} from '../models';
import {UserType} from '@esgi/core/authentication';
import {HierarchyItems} from './types';

export function applyClassic(targetClassicSnapshot: ClassicHierarchySnapshot, originClassicSnapshot: ClassicHierarchySnapshot, item: HierarchyItems): void {
	const {schoolID, teacherID, classID, groupID, studentID} = originClassicSnapshot;
	const {schools, groups, classes, students, teachers} = item;

	const selectedSchool = schools.items.find(s => s.schoolID === schoolID);
	let selectedTeacher = teachers.items.find(t => t.userID === teacherID);
	let selectedClass = classes.items.find(c => c.classID === classID);
	let selectedGroup = groups.items.find(g => g.groupID === groupID);
	const selectedStudent = students.items.find(s => s.studentID === studentID);

	if (selectedSchool) {
		targetClassicSnapshot.schoolID = schoolID;
	}

	if (selectedTeacher && selectedTeacher.schoolID === selectedSchool?.schoolID) {
		targetClassicSnapshot.teacherID = teacherID;
	} else {
		selectedTeacher = undefined;
	}

	if (selectedClass && selectedClass.userID === selectedTeacher?.userID) {
		targetClassicSnapshot.classID = classID;
	} else {
		selectedClass = undefined;
	}

	if (selectedGroup && selectedGroup.classID === selectedClass?.classID) {
		targetClassicSnapshot.groupID = groupID;
	} else {
		selectedGroup = undefined;
	}

	if (selectedStudent && (
		selectedStudent.classes.some(c => c === selectedClass?.classID)
		|| selectedStudent.groups.some(g => g === selectedGroup?.groupID)
	)) {
		targetClassicSnapshot.studentID = studentID;
	}
}

export function applySpecialist(targetSpecialistSnapshot: SpecialistHierarchySnapshot, originSpecialistSnapshot: SpecialistHierarchySnapshot, item: HierarchyItems): void {
	const {groupID, studentID, filter, userID} = originSpecialistSnapshot;
	const {teachers, specialistGroups, students, districtSpecialists, schoolSpecialists} = item;

	if (originSpecialistSnapshot.type > 0) {
		switch (originSpecialistSnapshot.type) {
			case UserType.ISD && districtSpecialists.length:
				targetSpecialistSnapshot.type = UserType.ISD;
				break;
			case UserType.ISS && schoolSpecialists.length:
				targetSpecialistSnapshot.type = UserType.ISS;
				break;
			default:
				return;
		}
	}

	const selectedGroup = specialistGroups.items.find(g => g.groupID === groupID && g.userID === userID);
	const selectedStudent = students.items.find(s => s.studentID === studentID);
	const selectedTeacher = teachers.items.find(t => t.userID === filter?.teacherID && t.schoolID === filter.schoolID);
	const selectedUser = (targetSpecialistSnapshot.type === UserType.ISD ? districtSpecialists : schoolSpecialists).find(s => s.userID === userID);

	if (selectedUser) {
		targetSpecialistSnapshot.userID = selectedUser.userID;
	} else {
		targetSpecialistSnapshot.userID = 0;
	}

	if (selectedGroup) {
		targetSpecialistSnapshot.groupID = groupID;
	} else {
		targetSpecialistSnapshot.groupID = 0;
	}

	if (selectedStudent) {
		const userSpecialistGroups = specialistGroups.items.filter(g => g.userID === userID);
		const studentHasLinkedToGroups = selectedStudent.specialistGroups.some(sg => userSpecialistGroups.some(usg => usg.groupID === sg));

		if (studentHasLinkedToGroups) {
			targetSpecialistSnapshot.studentID = studentID;
			if (selectedTeacher && selectedStudent.teacherID === selectedTeacher.userID) {
				targetSpecialistSnapshot.filter = {
					teacherID: selectedStudent.teacherID,
					schoolID: selectedStudent.schoolID,
				};
			} else {
				targetSpecialistSnapshot.filter = {teacherID: 0, schoolID: selectedStudent.schoolID};
			}
		}
	}

	if (selectedTeacher && !selectedStudent) {
		const hasTeacherStudentsInSpecialistGroup = students.items.some(s => s.teacherID === selectedTeacher.userID && s.specialistGroups.some(g => g === selectedGroup?.groupID));
		if (hasTeacherStudentsInSpecialistGroup) {
			targetSpecialistSnapshot.filter = {teacherID: selectedTeacher.userID, schoolID: selectedTeacher.schoolID};
		}
	}
}

export function applyPreAssess(targetPreAssessSnapshot: PreAssessHierarchySnapshot, originPreAssessSnapshot: PreAssessHierarchySnapshot, item: HierarchyItems): void {
	const {groupID, studentID, userID} = originPreAssessSnapshot;
	const {preAssesses, specialistGroups, students} = item;

	let selectedPreAssess = preAssesses.find(t => t.userID === userID);
	let selectedGroup = specialistGroups.items.find(g => g.groupID === groupID);
	const selectedStudent = students.items.find(s => s.studentID === studentID);

	if (selectedPreAssess) {
		targetPreAssessSnapshot.userID = userID;
	}

	if (selectedGroup) {
		targetPreAssessSnapshot.groupID = groupID;
	} else {
		selectedGroup = undefined;
	}

	if (selectedStudent && selectedStudent.specialistGroups.some(g => g === selectedGroup?.groupID)) {
		targetPreAssessSnapshot.studentID = studentID;
	}
}
