import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const DatePickerWrapper = styled(GridBox, {
	width: '100%',
	position: 'relative',

	'& input': {
		minWidth: 'unset',
	},
});
