import {BaseService} from '@esgi/core/service';
import {ReportService} from '../utils/report-service';
import {InitResponse, Row} from './types';
import {tap} from 'rxjs';

export class SpecialistActivityReportService extends BaseService {
	public reportService: ReportService<Row> = new ReportService();
	private readonly fileName = 'Specialist_Activity_Report';
	private readonly controller = 'reports/specialist-activity';

	public destroy(): void {
		super.destroy();
		this.reportService.destroy();
	}

	public init(groupOfSpecialistsID: number) {
		return this.httpClient.ESGIApi.get<InitResponse>(
			this.controller,
			'init',
			{groupOfSpecialistsID},
		).pipe(tap(({cacheKey, report}) => {
			this.reportService.cacheKey = cacheKey;
			this.reportService.setData(
				report.rows.map((item) => ({
					...item,
					specialist: `${item.firstName} ${item.lastName}`,
				})),
			);
		}));
	}

	public exportPdf() {
		return this.httpClient.ESGIApi.file(
			this.controller,
			'download-pdf',
			this.fileName,
			this.reportService.optionsToExport.value,
		).subscribe();
	}

	public exportExcel() {
		return this.httpClient.ESGIApi.file(
			this.controller,
			'download-excel',
			this.fileName,
			this.reportService.optionsToExport.value,
		).subscribe();
	}
}
