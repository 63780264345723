import {AssignmentInfo} from '../../services/types';
import {SortDirection, SortField, SortingModel} from './sorting-model';
import moment from 'moment';
import {sortBy} from 'underscore';
import {AssignmentStatus} from '../../typings/assignment';

function getIteratee(field: SortField): (a: AssignmentInfo) => any {
	switch (field) {
		case SortField.PostedAt:
			return (a) => a.posted;
		case SortField.Status:
			return (a) => a.state;
		case SortField.Title:
		default:
			return (a) => a.name.toUpperCase();
	}
}

export function formatPostedDate(date: string) {
	return moment(date).format('MMM D, YYYY [at] h:mm A');
}

function sortByStatus(source: AssignmentInfo[]) {
// 	sort by status in the following order: In Progress, Not Started, Completed, Draft
	const statusWeight = {
		[AssignmentStatus.Published]: 3,
		[AssignmentStatus.NotStarted]: 2,
		[AssignmentStatus.Completed]: 1,
		[AssignmentStatus.Draft]: 0,
	};

	return source.sort((a, b) => statusWeight[a.state] - statusWeight[b.state]);
}

export function sortByModel(sortingModel: SortingModel, source: AssignmentInfo[]) {
	const {direction, field} = sortingModel;
	const sorted = (field === SortField.Status) ? sortByStatus(source) : sortBy(source, getIteratee(field));

	if (direction === SortDirection.Desc) {
		return sorted.reverse();
	}

	return sorted;
}
