import {
	selectedClassID,
	selectedGroupID,
	selectedSchoolID,
	selectedSpecialistGroupID,
	selectedStudentID,
	selectedUserID,
} from '../../../utils';
import {HierarchySnapshot} from './hierarchy-snapshot';

export class SelectedSnapshot {
	constructor(hierarchy?: HierarchySnapshot) {
		if (hierarchy) {
			this.studentID = selectedStudentID(hierarchy);
			this.schoolID = selectedSchoolID(hierarchy);
			this.groupID = selectedGroupID(hierarchy);
			this.classID = selectedClassID(hierarchy);
			this.districtID = hierarchy.districtID;
			this.teacherID = selectedUserID(hierarchy);
			this.specialistGroupID = selectedSpecialistGroupID(hierarchy);
		}
	}

	districtID: number;
	schoolID: number;
	teacherID: number;
	classID: number;
	groupID: number;
	specialistGroupID: number;
	studentID: number;
}
