import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	'& > button': {
		borderRadius: 8,
	},

	'& > button:first-child': {
		'&:has(~ button)': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,

			'& ~ button': {
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
			},
		},
	},
});
