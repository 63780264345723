import {Text} from '@esgi/ui/typography';
import {ContentWrapperBox} from '../../content-wrapper-box.styled';

type Props = {
	isLinkedToDistrict: boolean;
};

export function LinkedToDistrictInfo({isLinkedToDistrict}: Props) {
	return (
		<ContentWrapperBox gap='2' flow='column' justify='between'>
			<Text data-cy='linked-to-district-base' size='small' font='mono' color='neutral40'>
				Linked to District:
			</Text>
			<Text data-cy='linked-to-district-indicator' size='small' font='mono' bold color='base'>
				{isLinkedToDistrict ? 'YES' : 'NO'}
			</Text>
		</ContentWrapperBox>
	);
}
