import {EmptyList} from './components/empty-list';
import {TestList} from './components/test-list';
import {SkeletonList} from './index.styled';
import {Test} from './types';
import {useTestsControl} from './hooks';
import {Skeletonable} from '@esgi/ui';

interface Props extends Skeletonable {
	tests: Test[];
	disabled?: boolean;
	value: Test['id'] | null;
	onChange: (value: Test['id'] | null) => void;
}

export function TestsControl({skeleton, tests, value, onChange}: Props) {
	const {items, onSort, sort} = useTestsControl({tests});

	if (skeleton) {
		return <SkeletonList/>;
	}

	if (!tests?.length) {
		return <EmptyList content="There's no test data for the students"/>;
	}

	return <TestList tests={items} sort={sort} onSort={onSort} selected={value} setSelected={onChange} />;
}
