import React from 'react';
import styles from '../../../../styles.module.less';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {Subject as SubjectType} from '../../../../../../../models';
import {SettingsFormSelectors} from '../../../../../../test/selectors';

interface Props {
	subjects: SubjectType[]
	selectedSubject: SubjectType
	onChange: (v: SubjectType[]) => void
}
export function Subject({subjects, selectedSubject, onChange}: Props) {
	return subjects?.length > 0 && <>
		<div className={styles.formGroup}>
			<label>Subject Tab: </label>
			<Dropdown value={[selectedSubject]} optionName='name' setValue={onChange} className={styles.formControl} autoWidth={true}
			data-cy={SettingsFormSelectors.subjectDropdown}>
				{subjects.map(subject => <Option key={subject.id}
				                                 value={subject}>{subject.name}</Option>)}
			</Dropdown>
		</div>
	</>;
}
