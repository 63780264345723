import {noop} from 'underscore';
import {AlertHeader} from '../alert-header';
import {AlertFooter} from '../alert-footer';
import {AlertBody} from '../components.styled';
import {
	Container as TestNameContainer,
	TestInfoBox as TestNameTestInfoBox,
	ChildrenBox as TestNameChildrenBox,
} from '../test-name/index.styled';
import {SkeletonShape} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {
	Container as SessionTimeInfoContainer,
	DurationBox as SessionTimeDurationBox,
} from '../session-time-info/index.styled';
import {
	Divider as QuestionRowDivider,
	ChildrenBox as QuestionRowChildrenBox,
} from '../question-row-view-mode/index.styled';
import {QuestionsContainer} from '../../../kit';

type Props = {
	canEdit: boolean;
	onCloseAlert: VoidFunction;
};

export function SkeletonTestSession({onCloseAlert, canEdit}: Props) {
	return (
		<>
			<AlertHeader
				canEdit={canEdit}
				skeleton
				isEditMode={false}
				onCloseAlert={onCloseAlert}
				onDeleteSession={noop}
				onEditModeClick={noop}
				student={{
					firstName: '',
					lastName: '',
					gradeLevelID: 0,
					hasCredentials: false,
					id: 0,
					photoUrl: '',
					languageID: 0,
				}}
			/>
			<AlertBody>
				<TestNameContainer>
					<TestNameTestInfoBox>
						<SkeletonShape width={12} height='100%' />
						<SkeletonShape width={147} height={20} />
						<SkeletonShape width={72} height={24} />
					</TestNameTestInfoBox>

					<TestNameChildrenBox>
						<GridBox flow='column' align='center' justify='start' gap='3'>
							<SkeletonShape width={40} height={16} />
							<SkeletonShape width={40} height={40} shape='round' />
						</GridBox>
					</TestNameChildrenBox>
				</TestNameContainer>

				<SessionTimeInfoContainer>
					<SkeletonShape width={64} height={16} />
					<SkeletonShape width={68} height={18} />

					<SessionTimeDurationBox>
						<SkeletonShape width={60} height={12} />
						<SkeletonShape width={42} height={12} />
					</SessionTimeDurationBox>
				</SessionTimeInfoContainer>

				<QuestionsContainer>
					{new Array(8).fill(null).map((_, index) => (
						<GridBox
							gap='3'
							css={{
								gridTemplateColumns: 'auto 1fr auto',
								paddingTop: 8,
							}}
							key={index}
						>
							<SkeletonShape width={12} height={16} />

							<GridBox gap='2'>
								<SkeletonShape width='75%' height={16} />
								{index !== 7 && <QuestionRowDivider />}
							</GridBox>

							<QuestionRowChildrenBox>
								<SkeletonShape width={16} height={16} />
							</QuestionRowChildrenBox>
						</GridBox>
					))}
				</QuestionsContainer>

				<SkeletonShape width='100%' height={56} />
			</AlertBody>
			<AlertFooter isEditMode={false} onDownload={noop} onRunSubset={noop} skeleton />
		</>
	);
}
