import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import SubjectDetails from 'modules/subject-details';
import _ from 'underscore';
import {Starred, Unstarred} from 'api/entities/events/test';
import {Test as TestEntity} from 'api/entities/test';
import {Subject} from 'api/entities/subject';
import {Button} from '@esgi/deprecated/elements/buttons';
import {SubjectType} from '@esgi/core/enums';
import {EventBusManager} from '@esgillc/events';
import {userStorage} from '@esgi/core/authentication';
import {TestInfo} from '../../../services/landing-service';
import {SubjectItem} from '../../../services/subjects-service';
import {AddTestToSubject} from '../../add-tests-to-subject';
import {DetailCard} from '../../detail-card/detail-card';
import {ContentAreaIcon} from '../../icons/content-area';
import {GradeLevelIcon} from '../../icons/grade-level';
import {Author} from '../../noted-author/author';
import {Card} from '../card/card';
import {ContextMenu} from './context-menu/context-menu';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));

class State extends TestInfo {
	contextMenuOpened: boolean = false;
	detailCardOpened: boolean = false;
	subjectCreatorOpened: boolean = false;
	addTestToSubjectOpened: boolean = false;
	testDetailsOpened: boolean;
}

class Props {
	initModel: TestInfo;
	hidden: () => any;
	subjects: SubjectItem[];
}

export class Test extends React.Component<Props, State> {
	private currentUser = userStorage.get();
	private ellipsisMenu: HTMLAnchorElement;
	private eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		const state = new State();
		if (props.initModel) {
			_.extend(state, props.initModel);
		}
		this.state = state;
	}

	componentDidMount(): void {
		this.eventBus.subscribe(Starred, (args) => this.starEventHandler(args));
		this.eventBus.subscribe(Unstarred, (args) => this.unstarEventHandler(args));
	}

	render() {
		return <Card dataName={this.state.title}>
			<header style={{backgroundColor: this.state.color}}>
				<a href='#' className='test-name' onClick={() => this.openTestDetails()}>
					{this.state.title}
				</a>
				{this.state.testDetailsOpened &&
				<Suspense fallback={<div/>}>
					<TestDetails
						testID={this.state.testId}
						firstName={this.currentUser.firstName}
						lastName={this.currentUser.lastName}
						userID={this.currentUser.userID}
						close={() => this.setState({testDetailsOpened: false})}
					/>
				</Suspense>
				}
				<div className='controls'>

					{this.state.starred &&
					<a href='#' className='unstar-link' onClick={() => this.unstar()}>
						<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'
						     fill='none'>
							<path
								d='M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z'
								fill='#424242'/>
						</svg>
					</a>}
					{!this.state.starred &&
					<a href='#' className='star-link' onClick={() => this.star()}>
						<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'
						     fill='none'>
							<path
								d='M8 1.61803L9.32058 5.68237L9.43284 6.02786H9.79611H14.0696L10.6123 8.53976L10.3184 8.75329L10.4306 9.09878L11.7512 13.1631L8.29389 10.6512L8 10.4377L7.70611 10.6512L4.24877 13.1631L5.56936 9.09878L5.68162 8.75329L5.38772 8.53976L1.93039 6.02786H6.20389H6.56716L6.67942 5.68237L8 1.61803Z'
								stroke='#424242'/>
						</svg>
					</a>}
					<a href='#' className='menu-link' onClick={() => this.showDropdownMenu()}
					   ref={r => this.ellipsisMenu = r}>
						<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16'>

							<g>
								<rect fill='none' id='canvas_background' height='18' width='18' y='-1'
								      x='-1'/>
							</g>
							<g>
								<path id='svg_1' fill='#666666'
								      d='m8,4.75c0.8937,0 1.625,-0.73125 1.625,-1.625c0,-0.89375 -0.7313,-1.625 -1.625,-1.625c-0.8938,0 -1.625,0.73125 -1.625,1.625c0,0.89375 0.7312,1.625 1.625,1.625zm0,1.625c-0.8938,0 -1.625,0.73125 -1.625,1.625c0,0.8938 0.7312,1.625 1.625,1.625c0.8937,0 1.625,-0.7312 1.625,-1.625c0,-0.89375 -0.7313,-1.625 -1.625,-1.625zm0,4.875c-0.8938,0 -1.625,0.7312 -1.625,1.625c0,0.8938 0.7312,1.625 1.625,1.625c0.8937,0 1.625,-0.7312 1.625,-1.625c0,-0.8938 -0.7313,-1.625 -1.625,-1.625z'/>
							</g>
						</svg>
					</a>
					{this.renderContextMenu()}
				</div>
			</header>
			<main>
				<Author author={this.state.author}/>
				<p className='description'>{this.state.description}</p>
			</main>
			<footer>
				<div className='icons'>
					<ContentAreaIcon contentArea={this.state.contentArea}/>
					<GradeLevelIcon gradeLevels={this.state.gradeLevels}/>
				</div>
				<Button
					onClick={() => this.setState({detailCardOpened: true})}
					title='Details'
					className='btn-link btn-details'
				/>
			</footer>

			{this.state.detailCardOpened && <DetailCard testID={this.state.testId}
			                                            close={() => this.setState({detailCardOpened: null})}
			                                            subjects={this.props.subjects}/>}

			{this.renderSubjectCreater()}
			{this.renderAddToSubject()}

		</Card>;
	}

	renderContextMenu() {
		if (!this.state.contextMenuOpened) {
			return null;
		}

		const coordinates = this.getOffsetRelativeToBody(this.ellipsisMenu);

		return <ContextMenu
			addTestToSubject={() => this.openAddTestToSubject()}
			close={() => this.closeContextMenu()}
			createSubject={() => this.createSubject()}
			x={coordinates.left}
			y={coordinates.top}
			testId={this.state.testId}
			hideClicked={() => this.hide()}
			subjects={this.props.subjects}/>;
	}

	getOffsetRelativeToBody(el: HTMLElement) {
		const rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return {top: rect.top + scrollTop + 15, left: rect.left + scrollLeft + 20};
	}

	private hide() {
		TestEntity.hide(this.state.testId).subscribe(() => showSnackbarNotification(`You've hidden ${this.state.title} from your search results`, {vPos: 'bottom'}));
		this.props.hidden();
	}

	private unstar() {
		TestEntity.unstar(this.state.testId);
	}

	private star() {
		TestEntity.star(this.state.testId);

	}

	private showDropdownMenu() {
		this.setState({contextMenuOpened: true});
	}

	private closeContextMenu() {
		this.setState({contextMenuOpened: false});
	}

	createSubject() {
		this.setState({subjectCreatorOpened: true, contextMenuOpened: false});
	}

	private renderSubjectCreater() {
		if (!this.state.subjectCreatorOpened) {
			return null;
		}

		return <SubjectDetails onClosed={() => this.setState({subjectCreatorOpened: false})}
		                       onSaved={(id, name, type) => this.addTestToSubject(id, type)}/>;
	}

	private addTestToSubject(id: number, type: SubjectType) {
		Subject.addTest(id, type, this.state.testId);
	}

	private openAddTestToSubject() {
		this.closeContextMenu();
		this.setState({addTestToSubjectOpened: true});
	}

	private openTestDetails() {
		this.setState({testDetailsOpened: true});
	}

	private renderAddToSubject() {
		if (!this.state.addTestToSubjectOpened) {
			return null;
		}

		return <AddTestToSubject mySubjects={this.props.subjects}
		                         testName={this.state.title}
		                         testId={this.state.testId}
		                         close={() => this.setState({addTestToSubjectOpened: false})}/>;
	}

	private starEventHandler(args: Starred.Args) {
		if (args.testId !== this.state.testId) {
			return;
		}

		this.setState({starred: true});
		showSnackbarNotification(`You have starred ${this.state.title}`);
	}

	private unstarEventHandler(args: Unstarred.Args) {
		if (args.testId !== this.state.testId) {
			return;
		}

		this.setState({starred: false});
		showSnackbarNotification(`You have unstarred  ${this.state.title}`);
	}

}
