import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {ColorPicker, State as ColorPickerState} from '@esgi/deprecated/elements';
import {ContentAreaItem, DefaultColors} from '../../types';
import styles from './styles.module.less';

interface Props {
	control: FormControl
	contentAreas: ContentAreaItem[]
	colorPicker: ColorPickerState,
	defaultColors: DefaultColors
	onChangeColor: (c: ColorPickerState) => void
}

export function ContentArea({onChangeColor, colorPicker, control, contentAreas, defaultColors}: Props) {

	useStreamEffect(control.onChanged, (c) => {
		onChangeColor({...colorPicker, selectedColor: defaultColors[c.currState.value[0].id]});
	});

	return <div data-cy='content-area' className={styles.row}>
		<div className={styles.titleContainer}>
			<label data-cy='content-area-title' className={styles.title}>Content Area:</label>
		</div>

		<div className={styles.contentAreaContainer}>
			<FormElement className={styles.dropdown} control={control}>
				<Dropdown optionName='name'>
					{contentAreas.map(v => <Option value={v} key={v.id}>{v.name}</Option>)}
				</Dropdown>
			</FormElement>
			<ColorPicker
				className={styles.colorPicker}
				align='up' hideTitle={true}
				state={colorPicker}
				onChange={(ch, cb) => {
					onChangeColor(ch);
					cb?.();
				}}
			/>
		</div>
	</div>;
}
