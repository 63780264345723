import React, {useMemo, useRef, useState} from 'react';
import TestRow from '../../../../../services/test-row';
import {join} from '@esgillc/ui-kit/utils';
import {Checkbox} from '@esgillc/ui-kit/control';
import styles from '../../styles.module.less';
import FormService from '../../../../../services/form-service';
import {Validators, ValidatedElement} from '@esgillc/ui-kit/form';
import {Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {SessionDropdown} from '../../../../../session-dropdown';
import {Portal} from '@esgillc/ui-kit/core';
import {SettingsFormSelectors} from '../../../../test/selectors';

interface Props {
	model: TestRow;
	service: FormService
	includeSessionOption: boolean
}

export function TestsRow({model, includeSessionOption, service}: Props) {
	const {name, type} = model;
	const tracks = useMemo(() => model.tracks.map(track => {
		return {
			id: track.trackID,
			dateTo: track.dateTo?.format('MM-DD-YY'),
			dateFrom: track.dateFrom?.format('MM-DD-YY'),
		};
	}), [model.tracks]);
	const portalRef = useRef();
	const {
		selected,
		noData,
		correct,
		incorrect,
		untested,
		printAllItems,
		max,
		currentDate,
		printAmount,
	} = model;
	const [isSessionVisible, setIsSessionVisible] = useState(false);
	const validators = useMemo(() => max === 0 ? [Validators.range(0, 0)] : [Validators.range(1, max)], [max]);


	return <tr data-name='name' key={model.id}>
		<td className='test-name-cell' data-cy={SettingsFormSelectors.testNameCell}>
			<Checkbox checked={selected} disabled={noData}
			          onClick={(e) => service.toggleSelectedTest(model.id)}>{name}</Checkbox>
		</td>
		<td className={join('table-block', styles.resultsCell, 'text-center')}>{correct}</td>
		<td className={join(styles.resultsCell, 'text-center')}>{(type === 'YN') ? incorrect : 'N/A'}</td>
		<td className={join(styles.resultsCell, 'text-center')}>{(type === 'YN') ? untested : 'N/A'}</td>
		<td className={join(styles.maxItemsCell, 'table-block')}>
			{type === 'YN' && <>
				<ul>
					<li>
						<div className={join(styles.formGroup, 'form-inline')}>
							<label>
								<Checkbox checked={printAllItems} disabled={!selected || noData}
								          onClick={() => service.togglePrintAll(model.id)}>All</Checkbox>
							</label>
						</div>
					</li>
					{!printAllItems && selected && <>
						<li>
							<div className={join(styles.formGroup, 'form-inline')}>
								<ValidatedElement setValue={(v) => service.changePrintAmount(v, model.id)}
								                  validateOnInit={true}
								                  value={printAmount} validators={validators}
								                  className={styles.formElement}>
									<Input type='number' max={max} min={1}/>
									<OnErrorTooltip showOnError='range' placement='right'>Incorrect
										value</OnErrorTooltip>
								</ValidatedElement>
							</div>
						</li>
						<li>
							of <span>{max}</span>
						</li>
					</>}
				</ul>
			</>}
			{type !== 'YN' && <>
				N/A
			</>
			}        </td>
		{includeSessionOption && <>
			<td className={join(styles.maxItemsCell, styles.resultsCell, styles.textLeft)}>
				<div ref={portalRef} className={styles.sessionDropdown}/>
				<div className={styles.dateWrapper} onClick={() => setIsSessionVisible(true)}>
					{noData && <>
						N/A
					</>}
					{!noData && <>
						<a href='#' onClick={() => open}>
							<input className={join(styles.dateInput, styles.formControl, 'form-control date-input')}
							       readOnly={true} value={currentDate}/>
							<i className='fa fa-caret-down' aria-hidden='true'/>
						</a>
					</>}
				</div>
			</td>
			{portalRef.current && !noData && <Portal owner={portalRef.current}>
				<SessionDropdown tracks={tracks} date={model.currentDate}
				                 onHide={() => setIsSessionVisible(false)}
				                 isVisible={isSessionVisible}
				                 onChange={(newDate) => {
					                 service.mpChanged(model.id, model.questionCount, newDate);
				                 }}/>
			</Portal>}
		</>}
	</tr>;
}
