import {INotedAuthor, LandingService} from 'pages/test-explorer/services/landing-service';
import React from 'react';
import {Subscription} from 'rxjs';
import {EventBusManager} from '@esgillc/events';
import {FilterType} from '../../../enums';
import {TestExplorerBackEvent} from '../../../events';
import {RemoveFilterEvent} from '../../header/search-tags/search-tags';
import {AuthorClicked} from '../../noted-author/events';
import {BoxItem} from '../box-item/box-item';
import {Box} from '../box/box';
import {Loading} from '../enums';
import {ClearAllEvent} from '../events';

class State {
	notedAuthors: INotedAuthor[] = [];
	loading: Loading = Loading.NotLoaded;
	selected: INotedAuthor = null;
}

class Props {
	landingService: LandingService;
	hasChanged: (author: INotedAuthor | null) => any;
}

export class NotedAuthor extends React.Component<Props, State> {
	private sub: Subscription;
	private readonly eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	render() {
		return <Box title='Noted Author' collapsible={true}
		            canBeCleared={!!this.state.selected}
		            filterType={FilterType.NotedAuthor}
		            onOpened={() => this.onBoxOpened()}
		            cleared={() => this.changed(null)} loading={false}>
			{this.state.notedAuthors.map((g, index) => {
				return <BoxItem
					key={g.name}
					selected={this.state.selected === g}
					title={g.name}
					onSelect={() => this.changed(g)}
				/>;
			})}
		</Box>;
	}

	onBoxOpened() {
		if (this.state.loading === Loading.NotLoaded) {
			this.setState({loading: Loading.Loading});
			this.sub = this.props.landingService.notedAuthors$.subscribe(r => {
				r.sort((a, b) => {
					if (a.name === b.name) {
						return 0;
					}
					return a.name > b.name ? 1 : -1;
				});
				this.setState({notedAuthors: r, loading: Loading.Loaded});
			});
		}
	}

	changed(author: INotedAuthor) {
		this.setState({selected: author}, () => {
			this.props.hasChanged(author);
		});
	}

	componentDidMount(): void {
		this.eventBus.subscribe(AuthorClicked, (args) => {
			if (args.author !== this.state.selected) {
				this.changed(args.author);
			}
		});

		this.eventBus.subscribe(ClearAllEvent, () => {
			this.changed(null);
		});

		this.eventBus.subscribe(RemoveFilterEvent, (args) => {
			if (args.type === FilterType.NotedAuthor) {
				this.changed(null);
			}
		});

		this.eventBus.subscribe(TestExplorerBackEvent, () => {
			this.setState({selected: null});
		});
	}

	componentWillUnmount() {
		this.eventBus.destroy();
		this.sub && this.sub.unsubscribe();
	}

}
