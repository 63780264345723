import React from 'react';
import styles from './styles.module.less';
import {GradeScale} from '../../../../../services/results-service/models';

interface Props {
	gradeScaleEntries: GradeScale[]
}

export function GradeScales({gradeScaleEntries}: Props) {
	return <div className={styles.legendsWrapper}>
		{gradeScaleEntries.map(gradeScaleEntry => <div className={styles.gradeScaleLegend}>
			<div className={styles.gradeColor}
			     style={{backgroundColor: gradeScaleEntry.color}}>
				<strong>{gradeScaleEntry.name}</strong>
			</div>
			<div className={styles.gradeDescription}>
				<span>{gradeScaleEntry.description}</span>
			</div>
		</div>)}
	</div>;
}
