import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {Subject} from 'api/entities/subject';
import {Subject as RxJSSubject} from 'rxjs';
import React from 'react';
import {takeUntil} from 'rxjs/operators';
import {SubjectType, TestType} from '@esgi/core/enums';
import {SsoTracker} from '@esgi/core/tracker';
import {Filter} from './filter/filter';
import {Results} from './results/result';
import {SubjectModel} from './models/models';
import {ContentAreaResponse, InitResponse} from './models/response-models';
import './home-add-test.less';
import * as TestDetailsEvents from 'shared/modules/test-details/events';
import {TestCreated, TestPublished} from 'shared/modules/test-details/events';
import SearchService from './results/search-service';
import {TestModel} from './results/types';
import {RubricCreatedEvent} from 'modules/assets/tests/rubric/creator/events';
import {HttpClient} from '@esgi/api';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {EventBusManager} from '@esgillc/events';

class State {
	subjects: SubjectModel[] = [];
	contentAreas: ContentAreaResponse[] = [];
	selectedSubjectID: number;
	selectedContentArea: number = -1;
	scope: string = '';
	keyword: string = '';
	selectedTestIDs: number[] = [];
}

class Props {
	hierarchy: HierarchySnapshot;
	subjectId: number;
	limitToSelectedSubjectId: boolean;
	testAddedToSubject?: (testId: number, type: TestType, testName: string, draft: boolean) => any;
	testRemovedFromSubject?: (testId: number) => any;
	changedSelectedSubject?: (s: SubjectModel) => void;
}

export default class HomeAddTest extends React.Component<Props, State> {
	public state = new State();
	private onDestroy$: RxJSSubject<void> = new RxJSSubject();

	private testOperationInProgress: boolean = false;
	private eventBusManager: EventBusManager = new EventBusManager();
	private searchService = new SearchService(this.props.hierarchy);

	public get selectedSubject() {
		return this.state.subjects.find(({id}) => id === this.state.selectedSubjectID);
	}

	public componentDidMount(): void {
		this.searchService.filter
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(filter => this.setState({
				scope: filter.scope,
				keyword: filter.keyword,
				selectedContentArea: filter.contentAreaId,
			}));

		this.eventBusManager.subscribe(TestDetailsEvents.TestCreated, this.testCreated);
		this.eventBusManager.subscribe(RubricCreatedEvent, this.testCreated);
		this.eventBusManager.subscribe(TestDetailsEvents.TestPublished, this.testPublished);

		HttpClient.default.ESGIApi.get<InitResponse>(
			'modules/home-add-test',
			'init',
		).subscribe(({contentAreas, ...s}) => {
			const subjects = s.subjects.map(
				({id, name, subjectType, testIDs}): SubjectModel => ({
					id,
					name,
					subjectType: SubjectType[subjectType],
					testIds: testIDs,
				}),
			);

			this.setState(
				(prev) => {
					const exists = subjects.find(({id}) => id === prev.selectedSubjectID);
					let {id: selectedSubjectID, testIds: selectedTestIDs} = subjects[0];
					if (exists) {
						selectedSubjectID = exists.id;
						selectedTestIDs = exists.testIds;
					}
					return {
						subjects,
						selectedSubjectID,
						contentAreas,
						selectedTestIDs,
					};
				},
				() => {
					const subject = subjects.find(({id}) => id === this.state.selectedSubjectID);
					this.props.changedSelectedSubject && this.props.changedSelectedSubject(subject);
				},
			);
		});
		this.setState({selectedSubjectID: this.props.subjectId});
	}

	public componentWillUnmount() {
		this.searchService.destroy();
		this.eventBusManager.destroy();
		this.onDestroy$.next();
	}

	testCreated = (args: TestCreated.Args) => {
		this.addTestToSubject(args.id, args.type, args.name, args.draft);
	};

	testPublished = (args: TestPublished.Args) => {
		this.addTestToSubject(args.id, TestType[args.type], args.name, false);
	};

	public render(): JSX.Element | false | null {
		return <div className='add-test'>
			<h4 className='results-title'>Find test(s) to add to your subject tab</h4>
			<Filter scope={this.state.scope}
			        subjects={this.state.subjects.map(i => {
				        return {id: i.id, name: i.name};
			        })}
			        contentAreas={this.state.contentAreas.map(i => {
				        return {id: i.id, name: i.name};
			        })}
			        selectedSubjectID={this.state.selectedSubjectID}
			        selectedContentAreaID={this.state.selectedContentArea}
			        limitToSelectedSubjectId={this.props.limitToSelectedSubjectId}
			        onScopeChanged={(value) => this.scopeChanged(value)}
			        onKeywordChanged={(value) => this.keywordChanged(value)}
			        onSubjectChanged={(value) => this.subjectChanged(value)}
			        onContentAreaChanged={(value) => this.contentAreaChanged(value)}
			/>
			<Results searchService={this.searchService}
			         testChecked={(test, checked) => this.testCheckedChanged(test, checked)}
			         selectedTestIds={this.state.selectedTestIDs}
			/>
		</div>;
	}

	private testCheckedChanged(test: TestModel, checked: boolean) {
		if (checked) {
			this.addTestToSubject(test.id, test.type, test.name, false);
		} else {
			this.removeTestFromSubject(test.id);
		}
	}

	private addTestToSubject(testID: number, type: TestType, name: string, draft: boolean) {
		if (this.testOperationInProgress || !name || draft) {
			return;
		}

		const subject = this.selectedSubject;
		if (subject) {
			this.testOperationInProgress = true;
			Subject.addTest(subject.id, subject.subjectType, testID, {name, type, draft, creatorID: 0})
				.then(() => {
					SsoTracker.trackEvent({
						trackingEvent: 'AddTestHompage',
						data: {subjectId: subject.id, subjectType: subject.subjectType, testId: testID},
					});

					const selectedTestIDs = subject.testIds.concat([testID]);
					const newSubject = Object.assign({}, subject, {testIds: selectedTestIDs});
					const subjects = this.state.subjects.map(s => s.id === subject.id ? newSubject : s);

					showSnackbarNotification(`You've added ${name} to ${subject.name}`, {
						vPos: 'bottom',
						hPos: 'center',
					});

					this.setState({subjects: subjects, selectedTestIDs: selectedTestIDs});
				}).finally(() => {
					this.testOperationInProgress = false;
				});
			this.props.testAddedToSubject && this.props.testAddedToSubject(testID, type, name, draft);
		}
	}

	private removeTestFromSubject(testID: number) {
		if (this.testOperationInProgress) {
			return;
		}

		const subject = this.selectedSubject;
		if (subject) {
			this.testOperationInProgress = true;

			Subject.removeTest(subject.id, subject.subjectType, testID)
				.then(() => {
					const selectedTestIDs = subject.testIds.filter(s => s !== testID);
					const newSubject = Object.assign({}, subject, {testIds: selectedTestIDs});
					const subjects = this.state.subjects.map(s => s.id === subject.id ? newSubject : s);

					this.setState({subjects: subjects, selectedTestIDs: selectedTestIDs});
				}).finally(() => {
					this.testOperationInProgress = false;
				});
			this.props.testRemovedFromSubject && this.props.testRemovedFromSubject(testID);
		}
	}

	private scopeChanged(scope: string) {
		let selectedContentArea = this.state.selectedContentArea;

		if (selectedContentArea === -1) {
			selectedContentArea = -2;
		}

		this.searchService.applyFilter({scope: scope, contentAreaId: selectedContentArea});
	}

	private keywordChanged(value: string) {
		let selectedContentArea = this.state.selectedContentArea;
		let scope = this.state.scope;

		if (selectedContentArea === -1) {
			selectedContentArea = -2;
		}

		if (scope === '') {
			scope = 'alltests';
		}

		this.searchService.applyFilter({keyword: value, contentAreaId: selectedContentArea, scope: scope});
	}

	private contentAreaChanged(contentAreaId: number) {
		if (isNaN(contentAreaId)) {
			contentAreaId = -2;
		}

		if (contentAreaId === -1) {
			contentAreaId = -2;
		}

		const newState: Partial<State> = {
			selectedContentArea: contentAreaId,
		};

		if (this.state.scope === '') {
			newState.scope = 'alltests';
		}

		this.searchService.applyFilter({contentAreaId});
	}

	private subjectChanged(id: number) {
		const subject = this.state.subjects.find(i => i.id === id);
		if (subject) {
			this.setState({
				selectedSubjectID: subject.id,
				selectedTestIDs: Array.from(subject.testIds),
			}, () => this.props.changedSelectedSubject && this.props.changedSelectedSubject(subject));
		}
	}
}
