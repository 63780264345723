import {ToggleGroupRoot, ToggleGroupContent, ToggleGroupItem, ToggleGroupTitle} from './index.styled';
import {Text} from '@esgi/ui/typography';

export interface Option {
	id: string | number;
	label: string;
	description?: string;
}

interface Props {
	value: Option['id'];
	options: Option[];
	disabled?: boolean;
	onChange: (value: Option['id']) => void;
	dataCy?: string;
}

export function ToggleGroup({value, options, disabled, onChange, dataCy}: Props) {
	return (
		<ToggleGroupRoot
			value={value.toString()}
			disabled={disabled}
			dataCy={dataCy}
		>
			<ToggleGroupContent>
				{options?.map(({id, label, description}) =>
					<ToggleGroupItem
						key={id}
						value={id.toString()}
						valueDescription={description}
						onClick={() => onChange(id)}
					>
						<Text size='medium' bold>{label}</Text>
					</ToggleGroupItem>
				)}
			</ToggleGroupContent>
			<ToggleGroupTitle>
				{({description}) => (
					<Text size='small' font='mono'>{description}</Text>
				)}
			</ToggleGroupTitle>
		</ToggleGroupRoot>
	);
}
