import {HttpEventBusManager} from '@esgi/api';
import {AxiosResponse} from 'axios';
import {Countdown} from '@esgi/deprecated/utils';
import {MoveUserStatus} from '@esgi/core/enums';
import {forceUserLogout} from '../utils';
import {useEffect, useMemo, useRef} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {ShowMoveUserLogoutAlertEvent} from '../layers/move-user-layers/events';

export function useMoveUserHandler() {
	const forceLogoutWindowShown = useRef(false);

	const eventBus = useMemo(() => new HttpEventBusManager(), []);

	useEffect(() => {
		const handleRequest = (response: AxiosResponse): void => {
			const status = MoveUserStatus[response.headers['esgi-move-user-status'] as string];
			if (status === MoveUserStatus.UserWarned) {
				const remainingSecondsHeader = response.headers['esgi-move-user-remaining-seconds'];
				if (remainingSecondsHeader) {
					const remainingSeconds = parseInt(remainingSecondsHeader);
					Countdown.Start(remainingSeconds, () => {
						forceUserLogout();
					});
					if (!forceLogoutWindowShown.current) {
						forceLogoutWindowShown.current = true;
						dispatchAppEvent(ShowMoveUserLogoutAlertEvent);
					}
				}
			}
		};
		eventBus.subscribe((r) => handleRequest(r));
		return () => eventBus.destroy();
	}, [eventBus]);
}