import {useCallback} from 'react';
import {SelectableList, SelectableListItem} from './index.styled';
import {SelectableLisItemCheckbox} from '../checkbox';
import {SelectableLisItemContent} from '../list-item';
import {ExpandablePanel} from '@esgi/main/kits/reports';
import {Test} from '../../../../types';
import {isNull} from 'underscore';

const getListValue = (value: string | number | null) => isNull(value)
	? ''
	: String(value);

interface Props {
	items: Test[];
	selected: Test['id'][] | null;
	setSelected: (itemId: Test['id']) => void;
}

export function SelectableListMultiple({items, selected, setSelected}: Props) {
	const value = (selected || []).map(getListValue);
	const checked = useCallback((id: string) => value.includes(id), [value]);

	return (
		<ExpandablePanel style={{height: 'calc(100% - 60px)'}}>
			<SelectableList dataCy='test-list'>
				<SelectableList.GroupRoot
					type='multiple'
					value={value}
				>
					<SelectableList.Group>
						{items.map((item) => {
							const isChecked = checked(String(item.id));

							return (
								<SelectableListItem
									key={item.id}
									value={String(item.id)}
									checked={isChecked}
									setTextCurrentColor={false}
									onClick={() => setSelected(item.id)}
									dataCy='test-list-item'
								>
									<SelectableLisItemCheckbox checked={isChecked}/>
									<SelectableLisItemContent item={item}/>
								</SelectableListItem>
							);
						})}
					</SelectableList.Group>
				</SelectableList.GroupRoot>
			</SelectableList>
		</ExpandablePanel>
	);
}
