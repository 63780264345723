import {Text} from '@esgi/ui/typography';
import {StyledButton} from './index.styled';
import {Block} from '@esgi/ui/icons';
import {VariantProps} from '@stitches/react';

type Props = {
	onClick: VoidFunction;
	buttonText: string;
	disabled?: boolean;
	withIcon?: boolean;
	justifySelf?: VariantProps<typeof StyledButton>['justifySelf'];
	width?: VariantProps<typeof StyledButton>['width'];
};

export function BottomSectionButton({
	onClick,
	buttonText,
	disabled,
	withIcon,
	justifySelf = 'stretch',
	width = 'full',
}: Props) {
	return (
		<StyledButton color='secondary' onClick={onClick} disabled={disabled} justifySelf={justifySelf} width={width}>
			{withIcon && <Block />}
			<Text size='medium' bold>
				{buttonText}
			</Text>
		</StyledButton>
	);
}
