import {PropsWithChildren, useEffect, useState} from 'react';
import {
	ReportModal as ReportModalRoot,
	ReportModalHeader,
	ReportModalBody,
	ReportModalCloseIcon,
	ReportModalTitle,
} from '@esgi/deprecated/ui-kit/report/modal';
import {TableLevelReportService} from '../../../../services/table-level-report-service';
import styles from './styles.module.less';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {useStream} from '@esgillc/ui-kit/utils';
import {ReportDownload} from '@esgi/deprecated/ui-kit/report/modal/download-button/download-reports-button';

type ReportModalProps = PropsWithChildren<{
	onClosed: () => void;
	service: TableLevelReportService;
}>;

export function ReportModal({onClosed, service, children}: ReportModalProps) {
	const [initialized, setInitialized] = useState<boolean>();

	const modal = useModal();
	const onModalClose = useCloseModal(modal, onClosed);

	const loading = useStream(service.loading$);

	useEffect(() => {
		if (!initialized) {
			setInitialized(!loading);
		}
	}, [loading]);

	return (
		<ReportModalRoot modalManagerRef={modal}>
			<Loader show={loading} fullscreen />
			{initialized && (
				<>
					<ReportModalHeader>
						<ReportModalTitle>Rubric Results</ReportModalTitle>
						<ReportDownload.Button>
							<ReportDownload.ButtonItem onSelect={service.downloadPDF.bind(service)}>
								PDF
							</ReportDownload.ButtonItem>
							<ReportDownload.ButtonItem onSelect={service.downloadExcel.bind(service)}>
								Excel
							</ReportDownload.ButtonItem>
						</ReportDownload.Button>
						<ReportModalCloseIcon onClick={onModalClose} />
					</ReportModalHeader>
					<ReportModalBody className={styles.body}>{children}</ReportModalBody>
				</>
			)}
		</ReportModalRoot>
	);
}
