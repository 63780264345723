import {MultiTabPanel} from './components/multi-tab-panel';
import {FlexBox} from '@esgi/ui/layout';
import {StudentsPanelRoot} from './components/students-panel-root';
import {SingleSelect, useSingleSelectContext, MultiSelect, useMultiSelectContext} from './components/select-variant';
import {TabsRoot} from './components/students-panel-tabs/tabs-root';
import {
	ClassesTabContent,
	GroupsTabContent,
	StudentsTabContent,
	ClassSelect,
	useClassesContext,
	useGroupsContext,
	useStudentsContext,
} from './components/students-panel-tabs';
import {Lists} from './components/lists';
import {TabContent} from './components/students-panel-tabs/tab-content';
import {StudentFullName} from './components/student-full-name';
import {EmptyTabContent} from './components/students-panel-tabs/empty-tab-content';
import {useTabsContext} from '../multi-tab-panel/context';
import {useStudentsPanelContext} from './context';
import {SelectAllEntitiesButton} from './components/students-panel-tabs/select-all-entities';

export * from './types';

export {type SingleSelectState, type MultiSelectState} from './components/select-variant';
export {type RearrangeItem} from './components/lists/variants/selectable-list-group/rearrange';
export {type ClassBucket, AllOptionItem, type GroupBucket} from './components/students-panel-tabs';

export const StudentsPanel = {
	Root: StudentsPanelRoot,
	useStudentsPanelContext,
	Selection: {
		Single: Object.assign(SingleSelect, {
			useSingleSelectContext,
		}),
		MultiSelect: Object.assign(MultiSelect, {
			useMultiSelectContext,
		}),
	},
	Header: {
		Title: FlexBox,
		Tabs: MultiTabPanel.TabsBar,
	},
	Tabs: {
		Root: TabsRoot,
		TabContent,
		EmptyTabContent: EmptyTabContent,
		SelectAllEntitiesButton,
		Classes: Object.assign(ClassesTabContent, {
			useClassesContext,
		}),
		Groups: Object.assign(GroupsTabContent, {
			ClassSelect,
			useGroupsContext,
		}),
		Students: Object.assign(StudentsTabContent, {
			useStudentsContext,
		}),
		useTabsContext,
	},
	Lists,
	StudentFullName,
};
