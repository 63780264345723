import {TestSession} from '../../../types';
import {useMemo} from 'react';
import {StudentInfo} from '../../../class-group/types';

export function usePreparedTestSessions(sessionsByDate: Array<Record<number, TestSession[]>>, showOnlyTestedStudents: boolean, students: StudentInfo[], totalPossible: number) {
	const combinedSessions = useMemo(() => sessionsByDate.reduce((acc, item, idx) => ([...acc, {...acc[idx - 1], ...item}]), [] as Array<Record<number, TestSession[]>>), [sessionsByDate]);

	return useMemo(() => combinedSessions.reduce((acc: TestSession[], item)=> {
		const session = Object.values(item).flat().reduce((acc, currSession, idx, arr) => {
			if (!acc.correctAnswers) {
				return {
					...currSession,
					totalPossible,
					correctAnswers: +(currSession.correctAnswers / (showOnlyTestedStudents ? arr.length : students.length)).toFixed(1),
				};
			} else {
				return ({
					...acc,
					...currSession,
					totalPossible,
					correctAnswers: +(acc.correctAnswers + currSession.correctAnswers / (showOnlyTestedStudents ? arr.length : students.length)).toFixed(1),
					duration: acc.duration + currSession.duration,
					testDate: Date.parse(currSession.testDate) > Date.parse(acc.testDate) ? currSession.testDate : acc.testDate,
				});
			}
		}, {} as TestSession);

		return [...acc, session];
	}, []).sort((a, b) => new Date(a.testDate).getTime() - new Date(b.testDate).getTime())
	, [showOnlyTestedStudents, combinedSessions, students.length, totalPossible]);
}