import {useMemo, useEffect} from 'react';
import {Drawer} from '@esgi/main/kits/common';
import {AiAnalysis} from '@esgi/ui';
import {Header} from './components/header';
import {ReportSelection} from '../report-selection-panel';
import {TranslatePanel} from './components/translate-panel';
import {TextEditor} from './components/text-editor';
import {withReact} from 'slate-react';
import {createEditor, Descendant, Transforms} from 'slate';
import {
	DrawerContainer,
	DrawerLeftPanel,
	DrawerRightPanel,
	VerticalDivider,
} from './index.styled';
import {StudentType} from '../../types';
import {
	ReportTypeOption,
	LangTypeOption,
	TranslationModel,
} from '../../model';
import {
	serializeHtml,
	serializeMarkdown,
	deserialize,
	withEditableVoids,
} from './utils';
import {useLangTypes} from './hooks/use-lang-types';
import {useAiAnalysisContext} from '../../layout/outlet-context';

interface Props {
	onClose: VoidFunction;
	student: StudentType;
	report: string;
	isReportLoading: boolean;
	handleDownload: (
		reportHTML: string,
		reportType: ReportTypeOption,
		student: StudentType
	) => void;
	completedLangTypes: LangTypeOption[];
	handleTranslate: (
		report: string,
		completed: LangTypeOption[],
		selected: LangTypeOption[]
	) => void;
	isTranslationsLoading: boolean;
	activeTranslations: TranslationModel[];
}

export function PreviewDrawer({
	onClose,
	student,
	report,
	isReportLoading,
	handleDownload,
	completedLangTypes,
	handleTranslate,
	isTranslationsLoading,
	activeTranslations,
}: Props) {
	const {
		selectedReportType,
		targetReportType,
		showSelectionDrawer,
		setShowSelectionDrawer,
		showTranslatePanel,
		showTranslateDrawer,
		setShowTranslateDrawer,
		setShowTranslatePanel,
		setShowExitPreviewDialog,
		setShowGeneratePreviewDialog,
		windowWidth,
	} = useAiAnalysisContext();
	const editor = useMemo(
		() => withEditableVoids(withReact(createEditor())),
		[]
	);
	const {selectedLangTypes, toggleLangType, clearSelectedLangTypes} =
		useLangTypes();
	useEffect(() => {
		if (activeTranslations?.length > 0) {
			activeTranslations.forEach((translation: TranslationModel) => {
				Transforms.insertNodes(
					editor,
					deserialize({
						text: translation.text,
						student,
						selectedReportType,
						lang: translation.language,
					}),
					{
						at: [editor.children.length],
					}
				);
			});
			clearSelectedLangTypes();
		}
	}, [activeTranslations, student, selectedReportType]);
	return (
		<Drawer
			width={windowWidth < 1024 ? '100%' : 1024}
			onClickOutside={() => setShowExitPreviewDialog(true)}
		>
			{/* Empty div to counter grid template on DrawerWrapper */}
			<div />
			<DrawerContainer>
				<DrawerLeftPanel>
					<Header
						student={student}
						reportType={selectedReportType}
						handleDownload={() =>
							handleDownload(serializeHtml(editor), selectedReportType, student)
						}
						isReportLoading={isReportLoading}
					/>
					<Drawer.ContentBlock title='Report Preview' withDivider>
						<TextEditor
							editor={editor}
							initialValue={deserialize({
								text: report ?? 'No report generated',
								student,
								selectedReportType,
							})}
							isTextLoading={isReportLoading}
							isTranslationsLoading={isTranslationsLoading}
							showTranslateButton={selectedReportType.value === 'parent'}
						/>
					</Drawer.ContentBlock>
				</DrawerLeftPanel>
				{windowWidth >= 1024 && (
					<>
						<VerticalDivider />
						<DrawerRightPanel>
							{showTranslatePanel ? (
								<>
									<Drawer.Header
										Icon={AiAnalysis}
										sectionName='Add Translations'
										withActionButton
										actionButtonText='Add Translation'
										actionButtonDisabled={
											!selectedLangTypes.length || isTranslationsLoading
										}
										onActionButtonClick={async () => {
											const secondChild = editor.children?.[1];
											if (
												secondChild &&
												typeof secondChild === 'object' &&
												'children' in secondChild
											) {
												handleTranslate(
													serializeMarkdown(
														secondChild.children as Descendant[]
													),
													completedLangTypes,
													selectedLangTypes
												);
												setShowTranslateDrawer(false);
											}
										}}
										closeDrawer={() => setShowTranslatePanel(false)}
									/>
									<TranslatePanel
										completedLangTypes={completedLangTypes}
										selectedLangTypes={selectedLangTypes}
										toggleLangType={toggleLangType}
									/>
								</>
							) : (
								<>
									<Drawer.Header
										Icon={AiAnalysis}
										sectionName='Settings'
										withActionButton
										actionButtonText='Generate'
										actionButtonDisabled={
											targetReportType &&
											selectedReportType.value === targetReportType.value
										}
										onActionButtonClick={() =>
											setShowGeneratePreviewDialog(true)
										}
										closeDrawer={onClose}
									/>
									<ReportSelection />
								</>
							)}
						</DrawerRightPanel>
					</>
				)}
				{showTranslateDrawer && (
					<Drawer
						width={360}
						onClickOutside={() => setShowTranslateDrawer(false)}
					>
						<DrawerRightPanel>
							<Drawer.Header
								Icon={AiAnalysis}
								sectionName='Add Translations'
								withActionButton
								actionButtonText='Add Translation'
								actionButtonDisabled={
									!selectedLangTypes.length || isTranslationsLoading
								}
								onActionButtonClick={async () => {
									const secondChild = editor.children?.[1];
									if (
										secondChild &&
										typeof secondChild === 'object' &&
										'children' in secondChild
									) {
										handleTranslate(
											serializeMarkdown(secondChild.children as Descendant[]),
											completedLangTypes,
											selectedLangTypes
										);
									}
								}}
								closeDrawer={() => setShowTranslateDrawer(false)}
							/>
							<TranslatePanel
								completedLangTypes={completedLangTypes}
								selectedLangTypes={selectedLangTypes}
								toggleLangType={toggleLangType}
							/>
						</DrawerRightPanel>
					</Drawer>
				)}
				{showSelectionDrawer && (
					<Drawer
						width={360}
						onClickOutside={() => setShowSelectionDrawer(false)}
					>
						<DrawerRightPanel>
							<Drawer.Header
								Icon={AiAnalysis}
								sectionName='Settings'
								withActionButton
								actionButtonText='Generate'
								actionButtonDisabled={
									targetReportType &&
									selectedReportType.value === targetReportType.value
								}
								onActionButtonClick={() => setShowGeneratePreviewDialog(true)}
								closeDrawer={() => setShowSelectionDrawer(false)}
							/>
							<ReportSelection />
						</DrawerRightPanel>
					</Drawer>
				)}
			</DrawerContainer>
		</Drawer>
	);
}
