import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {UserInfo} from './types';
import {userStorage} from './user-storage';

/**
 * The function `getUser` returns the current user's information if available, otherwise it returns
 * null.
 * @returns The function `getUser()` returns either a `Readonly<UserInfo>` object or `null`.
 */
export function getUser(): Readonly<UserInfo> | null {
	return userStorage.get();
}

/**
 * The function `getSelectedSchoolYearID` returns the global school year ID of the user, or 0 if it is
 * not available.
 * @returns the global school year ID of the selected user, or 0 if the user is not found.
 */
export function getSelectedSchoolYearID(): number {
	return getUser()?.globalSchoolYearID || 0;
}

/**
 * The function `getDistrictID` returns the district ID of the user, or 0 if it is not available.
 * @returns the district ID of the user, or 0 if the user does not have a district ID.
 */
export function getDistrictID(): number {
	return getUser()?.districtID || 0;
}

/* The initial value of the context is set
to the result of the `getUser()` function. This context can be used to provide and consume the
current user information throughout the application. */
export const userContext = createContext(getUser());

/**
 * The UserInfoProvider component is a wrapper that provides user information to its children
 * components.
 * @param  - - `children`: The child components that will be wrapped by the `UserInfoProvider`
 * component.
 */
export function UserInfoProvider({children}: { children: ReactNode }): JSX.Element {
	const [user, setUser] = useState<UserInfo | null>(getUser());
	useEffect(() => {
		const sub = userStorage.onChanged$.subscribe((v) => {
			setUser(v);
		});
		return () => sub.unsubscribe();
	}, []);
	return <userContext.Provider value={user}>{children}</userContext.Provider>;
}

/**
 * The function returns the user information or null if it is not available.
 * @returns either a Readonly<UserInfo> object or null.
 */
export function useUser(): Readonly<UserInfo> | null {
	return useContext(userContext);
}
