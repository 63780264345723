import {ExpandFullAlt} from '@esgi/ui/icons';
import {PanelTriggerButton} from './index.styled';
import {useDrawerContext} from '../../../../context/drawer-context';
import {MouseEvent, useCallback} from 'react';

function Trigger() {
	const {togglePanelOpen} = useDrawerContext();

	const handleClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			togglePanelOpen();
		},
		[togglePanelOpen],
	);

	return (
		<PanelTriggerButton withBackgroundHover onClick={handleClick}>
			<ExpandFullAlt />
		</PanelTriggerButton>
	);
}

export const PanelTrigger = Object.assign(Trigger, {
	toString: () => `.${PanelTriggerButton.className}`,
});
