import {CloseIcon, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';

interface Props {
	onClose: (closeParent?: boolean) => void;
	onAddIndividually: () => void;
	onUploadRoaster: () => void;
}

export function AddStudent({onClose, onAddIndividually, onUploadRoaster}: Props) {

	const modalRef = useModal();
	const handleClose = (closeParent?: boolean) => {
		modalRef.current.close(() => onClose(closeParent));
	};

	return <div data-cy={'add-student'}><Alert modalManagerRef={modalRef} className={styles.addStudentDialog}>
		<Alert.Header className={styles.header}>
			<Title className={styles.title}>Add Student(s)</Title>
			<CloseIcon className={styles.closeIcon} onClick={() => handleClose(true)}/>
			<p className={styles.text}>Add a list of students by uploading a file or add students one by one.</p>
		</Alert.Header>
		<Alert.Footer className={styles.footer}>
			<Buttons.Contained onClick={() => {
				onUploadRoaster();
				handleClose();
			}}>UPLOAD ROSTER</Buttons.Contained>
			<Buttons.Contained onClick={() => {
				onAddIndividually();
				handleClose();
			}}>ADD INDIVIDUALLY</Buttons.Contained>
		</Alert.Footer>
	</Alert></div>;
}
