import {AppEvent} from '@esgillc/events';

export class BaseGroupEvent {
	groupId: number;
	userID: number;
	name: string;
}

@AppEvent({broadcast: true})
export class SchoolsGroupCreatedEvent extends BaseGroupEvent {
	schools: number[];
}

@AppEvent({broadcast: true})
export class SchoolsGroupChangedEvent extends BaseGroupEvent {
	schools: number[];
}

@AppEvent({broadcast: true})
export class SchoolsGroupRemovedEvent {
	id: number;
}

@AppEvent({broadcast: true})
export class TeachersGroupCreatedEvent extends BaseGroupEvent {
	teachers: number[];
}

@AppEvent({broadcast: true})
export class TeachersGroupChangedEvent extends BaseGroupEvent {
	teachers: number[];
}

@AppEvent({broadcast: true})
export class TeachersGroupRemovedEvent {
	id: number;
}

@AppEvent({broadcast: true})
export class GroupOfSpecialistsCreatedEvent extends BaseGroupEvent {
	specialists: number[];
}

@AppEvent({broadcast: true})
export class GroupOfSpecialistsChangedEvent extends BaseGroupEvent {
	specialists: number[];
}

@AppEvent({broadcast: true})
export class GroupOfSpecialistsRemovedEvent {
	id: number;
}
