import {TabItem} from '@esgi/main/features/teacher/home';
import {SectionTabId} from '../types';
import {useMemo} from 'react';

export function useSectionTabItems({activeSectionTab}: {activeSectionTab: SectionTabId}) {
	const tabItems = useMemo(
		() =>
			[
				{
					tabId: SectionTabId.Students,
					label: 'Students',
					valueDescription: 'Students',
					dataCy: 'students-section',
				},
				{
					tabId: SectionTabId.Subjects,
					label: 'Subjects',
					valueDescription: 'Subjects',
					dataCy: 'subjects-section',
				},
			].map<TabItem<SectionTabId>>(({tabId, label, valueDescription, dataCy}) => ({
				value: tabId,
				label,
				valueDescription,
				permitted: activeSectionTab !== tabId,
				applyDisabledStyles: false,
				contentType: 'text',
				dataCy,
			})),
		[activeSectionTab],
	);

	return {tabItems};
}