import {Select} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const SelectField = styled(Select.Field, {
	textAlign: 'left',
	'& > :first-child': {
		width: '150px',
		overflow: 'hidden',

		'span:last-child': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},

	'& [data-value-content]': {
		'> *:not([data-placeholder-value])': {
			display: 'none',
		},
	},
});

export const SelectOption = styled(Select.Option, {
	'& [data-placeholder-value]': {
		display: 'none',
	},
});
