import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from 'root/route-wrapper';
import DefaultRouteFallback from 'root/routes/default-route-fallback';

const CompleteRegistrationRoot = lazyComponent(() => import('pages/landing/complete-registration'));

export default function CompleteRegistration() {
	return <RouteWrapper title='Complete Registration'>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<CompleteRegistrationRoot/>
		</Suspense>
	</RouteWrapper>;
}
