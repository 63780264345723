import {Class, Group, Student} from '@esgi/main/libs/store';

export enum StudentsPanelTabID {
	Classes = 'classes',
	Groups = 'groups',
	Students = 'students',
}

export type Entities = {
	classes: Class[];
	students: Student[];
	groups: Group[];
};
