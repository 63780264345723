import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Skeletonable} from '../../../../types';
import {CSS} from '@stitches/react';
import {Li} from './index.styled';

export type ListItemProps = ComponentPropsWithoutRef<'li'> &
	Skeletonable & {
		/** Data attribute used by test automation team. */
		dataCy?: string;

		/** Returns a Style interface from a configuration, leveraging the given media and style map. */
		css?: CSS;

		/**
		 *  Set css property color: currentColor for all nested Text components.
		 * @default true
		 */
		setTextCurrentColor?: boolean;
	};

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
	({dataCy = 'ui-kit-list-item', skeleton, setTextCurrentColor = true, ...props}, forwaredRef) => (
		<Li data-cy={dataCy} ref={forwaredRef} setTextCurrentColor={setTextCurrentColor} {...props} />
	),
);
