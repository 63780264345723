import {ObservableBuilder, HttpClient} from '@esgi/api';
import {
	ITestIntroAddOptions,
	ITestIntroUpdateOptions,
	IUpdateResult,
	ShapeDefinitions,
	TestIntroModel,
} from 'shared/modules/question-editor/models';
import {Canvas, State as CanvasState, Events as CanvasEvents} from 'shared/modules/question-editor/mx/editor/canvas/canvas';
import {RightPanel, State as RightPanelState} from 'shared/modules/question-editor/mx/editor/right-panel/right-panel';
import {XmlCodec} from 'shared/modules/question-editor/utils/xml-codec';
import {ShapeDefinitionService} from 'shared/modules/question-editor/utils/shape-definition-builder';
import * as Events from 'shared/modules/question-editor/form-events';
import {Modal, ModalBody, ModalFooter, ModalHeader, SharedProps, SharedComponent} from '@esgi/deprecated/react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import './test-intro-editor.less';
import {LoadFonts} from 'shared/modules/assets/fonts';
import {map} from 'rxjs/operators';
import {Loader} from '@esgillc/ui-kit/loader';
import {DirtyAlert} from '../components/dirty-alert';
import '../editors.less';

export class Props extends SharedProps<State> {
	onSaved: (result?: IUpdateResult) => any;
	shapeDefinitions: ShapeDefinitions;
}

export class State {
	saving: boolean;
	testIntro: TestIntroModel = new TestIntroModel();
	canvas: CanvasState = new CanvasState();
	rightPanel: RightPanelState = new RightPanelState();
	dirtyAlert: boolean = false;
	dirtyXml: string;
	dirtyDefinitions: ShapeDefinitions;
	shapeDefinitionsXml: string;
	initialized: boolean = false;
}

export class TestIntroEditor extends SharedComponent<State, Props> {
	get isEditMode() {
		return this.state.testIntro.id > 0;
	}

	get valid() {
		return this.state.canvas.cells.length > 0;
	}

	async componentDidMount() {
		await LoadFonts();

		if (this.isEditMode) {
			this.server.initEdit(this.state.testIntro.id).subscribe((testIntro: TestIntroModel) => {
				let newState = {...this.state};
				newState.testIntro = testIntro;
				newState.initialized = true;

				newState.shapeDefinitionsXml = testIntro.xml;

				this.setState(newState);
			});
		} else {
			this.setState({initialized: true});
		}

		this.subscribe(CanvasEvents.SaveAndClose, (args: CanvasEvents.SaveAndClose.Args) => {
			if (!this.valid) {
				return;
			}

			if (args.changed) {
				this.setState({saving: true});
				this.save(args.xml, args.definitions).subscribe((result: IUpdateResult) => {
					this.props.onSaved(result);
				});
			} else {
				this.props.onSaved();
			}
		});

		this.subscribe(CanvasEvents.Close, (args: CanvasEvents.Closed.Args) => {
			if (args.changed) {
				this.setState({dirtyXml: args.xml, dirtyDefinitions: args.definitions}, () => {
					this.setState({dirtyAlert: true});
				});
			} else {
				this.props.onSaved();
			}
		});
	}

	save(xml: string, definitions: ShapeDefinitions): ObservableBuilder<{ id: number; definitions: ShapeDefinitions }> {
		let decodedXml = XmlCodec.decode(xml, this.state.canvas.images);
		let newDefinitions = new ShapeDefinitionService(definitions).saveTempDefinitions();

		if (this.isEditMode) {
			return this.update(decodedXml).pipe(map(() => {
				return {
					id: this.state.testIntro.id,
					definitions: newDefinitions,
				};
			}));
		} else {
			return this.add(decodedXml).pipe(map(result => {
				return {
					id: result.testIntroPageID,
					definitions: newDefinitions,
				};
			}));
		}
	}

	add(xml: string) {
		return this.server.add({
			testID: this.state.testIntro.testID,
			xml: xml,
		});
	}

	update(xml: string) {
		return this.server.update({
			id: this.state.testIntro.id,
			xml: xml,
		});
	}

	server = {
		update: (model: ITestIntroUpdateOptions) => {
			return HttpClient.default.ESGIApi.post<any>('assets/tests', 'intro-page/update', model);
		},
		add: (model: ITestIntroAddOptions) => {
			return HttpClient.default.ESGIApi.post<{testIntroPageID: number, definitions: ShapeDefinitions}>('assets/tests', 'intro-page/create', model);
		},
		initEdit: (testIntroPageId: number) => {
			return HttpClient.default.ESGIApi.get<TestIntroModel>('assets/tests', 'intro-page/init', {testIntroPageId: testIntroPageId});
		},
	};

	closeDirtyAlert(needSave: boolean) {
		if (needSave) {
			if (this.valid) {
				this.save(this.state.dirtyXml, this.state.dirtyDefinitions).subscribe((result: IUpdateResult) => {
					this.setState({dirtyAlert: false}, () => this.props.onSaved(result));
				});
			} else {
				this.showValidation();
				this.setState({dirtyAlert: false});
			}
		} else {
			this.setState({dirtyAlert: false}, () => this.props.onSaved());
		}
	}

	saveAndCloseClicked() {
		this.showValidation();
		this.dispatch(Events.SaveAndCloseClicked, null);
	}

	showValidation() {
		this.dispatch(Events.ShowValidation, Events.ShowValidation(true, this.valid));
	}

	closeClicked() {
		this.dispatch(Events.CloseClicked, null);
	}

	render() {
		return <>
      <Loader show={!this.state.initialized} fullscreen/>;
      {this.state.initialized && <>
      	{this.state.dirtyAlert &&
					<DirtyAlert
						onClose={() => this.closeDirtyAlert(false)}
						onSave={() => this.closeDirtyAlert(true)}
					/>
        }
        <Modal
          animate={true}
          className={'mx-test-intro-editor-modal responsive white-header'}>
          <ModalHeader title='Introduction Page'
                       close={{click: () => this.closeClicked()}}>
          </ModalHeader>
          <ModalBody>
            <div className='mx-question-editor-modal-root'>
              <div className='test-intro-header'>
                Create an example question for your students or include test instructions
                for the teacher. View our <a target='_blank'
                                             href='https://support.esgisoftware.com/hc/en-us/articles/360019756471-Test-Intro-Page'
                                             rel='noreferrer'>full
                tutorial</a>.
              </div>
              <div className='mx-question-editor'>
                <Canvas
                  shapeDefinitionsXml={this.state.shapeDefinitionsXml}
                  images={this.state.testIntro.images}
                  isWhiteBackground={this.state.testIntro.isWhiteBackground}
                  xml={this.state.testIntro.xml}
                  shapeDefinitions={this.props.shapeDefinitions}
                  state={this.state.canvas}
                  onChange={(st, ch) => this.setState({canvas: st}, ch)}/>
                <RightPanel
                  shapeStyle={this.state.canvas.shapeStyle}
                  shapeType={this.state.canvas.shapeType}
                  state={this.state.rightPanel}
                  onChange={(st, ch) => this.setState({rightPanel: st}, ch)}/>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className='mx-test-intro-editor-footer-row'>
              <div className='mx-test-intro-editor-left-button'>
                <div className='mx-test-intro-editor-left-button-container'>
                  <Button
                    onClick={() => this.closeClicked()}
                    title='CANCEL'
                  />
                </div>
              </div>
              <Primary
                onClick={() => this.saveAndCloseClicked()}
                title='SAVE'
                disabled={this.state.saving}
                className='save-btn'
              />
            </div>
          </ModalFooter>
        </Modal>
      </>}
		</>;
	}
}
