/**
 * @example 'data:image/png;base64,YourBase64StringHere'
 * @param combinedString
 */
export function parseDataUrlBased64Text(combinedString: string) {
	const [data, base64String] = combinedString.split(',');

	if (!data || !base64String) {
		throw new Error('combinedString is not match to base64 format');
	}

	const contentType = data.replace('data:', '').split(';')[0] ?? '';

	return {
		contentType,
		base64String,
	};
}
