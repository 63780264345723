import {DatePickerInput} from './components/input';
import {DatePickerCalendarPanel} from './components/calendar-panel';
import {DatePickerRoot} from './components/root';
import {DropdownTrigger} from './components/dropdown-trigger';
import {DropdownBody} from './components/dropdown-body';

export const DatePicker = Object.assign({}, {
	Root: DatePickerRoot,
	Input: DatePickerInput,
	CalendarPanel: DatePickerCalendarPanel,
	DropdownTrigger: DropdownTrigger,
	DropdownBody: DropdownBody,
});
