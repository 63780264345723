import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {
	Class,
	Group,
	Cards,
	InitModel,
	PlayersResponse,
	Players,
	Print,
	Questions,
	Subject,
	Subjects,
	Test,
	Tests,
	Question,
	QuestionResponse,
	SpecialistGroup,
	Student,
	EntityModel,
	ResultModel,
	Difficulty,
} from 'shared/modules/bingo/types/models';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {getSpecialistGroupID, getSpecialistID, sortBy} from './utils';
import {SubjectType} from '@esgi/core/enums';
import {SortBy} from 'shared/modules/bingo/steps/preview-questions/preview-questions';


export const players: Players = {
	classes: [],
	groups: [],
	specialistGroups: [],
	students: [],
	loaded: false,
};

export const subjects: Subjects = {
	subjects: [],
	selectedSubject: {
		id: null,
		name: '',
		subjectType: null,
		published: false,
		level: '',
	},
	loaded: false,
};

export const tests: Tests = {
	tests: [],
	selectedTest: undefined,
	loaded: false,
};

export const cards: Cards = {
	x3cardSize: true,
	twoCardsPerPage: false,
	difficulty: Difficulty.Medium,
};

export const questions: Questions = {
	questions: [],
	selectedQuestionsIDs: [],
	selectedSortBy: SortBy.TestOrder,
	warning: '',
	valid: true,
};

export const print: Print = {
	gamePlayedAtStudentHome: false,
	numberOfPlayersAtHome: 1,
	cardsPerStudent: 1,
	cardsPerPerson: 1,
	sheetsPerClass: 1,
	sheetsPerStudent: 1,
};

export class BingoService extends BaseService {
	readonly loading$ = new BehaviorSubject<boolean>(false);

	public players$: BehaviorSubject<Players> = new BehaviorSubject<Players>(players);

	public subjects$: BehaviorSubject<Subjects> = new BehaviorSubject<Subjects>(subjects);

	public tests$: BehaviorSubject<Tests> = new BehaviorSubject<Tests>(tests);

	public cards$: BehaviorSubject<Cards> = new BehaviorSubject<Cards>(cards);

	public questions$: BehaviorSubject<Questions> = new BehaviorSubject<Questions>(questions);

	public print$: BehaviorSubject<Print> = new BehaviorSubject<Print>(print);

	get specialistGroup () {
		return this.players$.value.specialistGroups ? this.players$.value.specialistGroups.filter(x => x.id !== 0 && x.selected)[0] : null;
	}

	get group () {
		return this.players$.value.groups ? this.players$.value.groups.filter(x => x.id !== 0 && x.selected)[0] : null;
	}

	get selectedClass () {
		return this.players$.value.classes ? this.players$.value.classes.filter(x => x.selected)[0] : null;
	}

	public get students () {
		return this.specialistGroup
			? this.players$.value.students.filter(x => x.selected && x.specialistGroups.some(id => id === this.specialistGroup?.id))
			: this.group
				? this.players$.value.students.filter(x => x.selected && x.groups.some(id => id === this.group?.id))
				: this.players$.value.students.filter(x => x.selected && x.classes.some(id => id === this.selectedClass?.id));
	}

	public getEntityName () {
		return (this.specialistGroup ? this.specialistGroup : this.group ? this.group : this.selectedClass)?.name;
	}

	public getPlayers(hierarchy: HierarchySnapshot) {
		this.loading$.next(true);
		const model = new InitModel();

		model.GroupID = hierarchy.classic.groupID;
		model.ClassID = hierarchy.classic.classID;
		model.SpecialistGroupID = getSpecialistGroupID(hierarchy);
		model.TeacherID = hierarchy.classic.teacherID;
		model.SpecialistID = getSpecialistID(hierarchy);

		return this.httpClient.ESGIApi.get<PlayersResponse>('bingo', 'students', model)
			.pipe(tap(response => {
				let stateGroups = null;

				const groups = response.groups;
				if (groups) {
					groups.unshift({
						id: 0,
						name: 'All Students',
						classID: 0,
						selected: !response.groups.some(x => x.selected),
					});
					stateGroups = groups.map((x) => new Group(x));
				}

				let stateClasses = null;

				const classes = response.classes;
				if (classes) {
					stateClasses = classes
						.map((x) => new Class(x))
						.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1);
				}

				let stateSpecialistGroups = null;

				const specialistGroups = response.specialistGroups;
				if (specialistGroups) {
					if (!specialistGroups.some(x => x.selected)) {
						specialistGroups[0].selected = true;
					}
					stateSpecialistGroups = specialistGroups.map((x) => new SpecialistGroup(x));
				}

				const students = response.students;
				students.unshift({
					id: 0,
					firstName: 'Select',
					lastName: 'All',
					classes: [],
					groups: [],
					specialistGroups: [],
				});

				this.players$.next({
					loaded: true,
					classes: stateClasses,
					students: response.students.map((x) => {
						const c = new Student(x);
						c.selected = true;
						return c;
					}),
					groups: stateGroups,
					specialistGroups: stateSpecialistGroups,
					testResultsVerbiagesEnabled: response.testResultsVerbiagesEnabled,
					testResultsCorrectVerbiage: response.testResultsCorrectVerbiage,
					testResultsIncorrectVerbiage: response.testResultsIncorrectVerbiage,
				});

				this.loading$.next(false);
			}));
	}

	public getSubjects (hierarchy: HierarchySnapshot, selectedSubjectID) {
		this.loading$.next(true);

		return this.httpClient.ESGIApi.get<Subject[]>('bingo', 'subjects', {hierarchy: hierarchy})
			.pipe(tap((subjects) => {
				const parsedSubjects = subjects.map(x => ({
					id: x.id,
					name: x.name,
					subjectType: x.subjectType,
					published: x.published,
					level: x.level,
				}));
				const selectedSubject = parsedSubjects.filter(x => x.id === selectedSubjectID);

				this.subjects$.next({
					subjects: parsedSubjects,
					selectedSubject: selectedSubject.length > 0 ? selectedSubject[0] : parsedSubjects[0],
					loaded: true,
				});

				this.loading$.next(false);
			}));
	}

	public setSelectedSubject(subject: Subject) {
		this.subjects$.next({
			...this.subjects$.value,
			selectedSubject: subject,
		});
	}

	public getTests (subjectType, subjectID) {
		return this.httpClient.ESGIApi.get<Test[]>('bingo', 'tests', {subjectType, subjectID})
			.pipe(tap((tests) => {
				const parsedTests = tests.map(item => ({
					id: item.id,
					name: item.name,
					color: item.color,
					questionCount: item.questionCount,
				}));

				this.tests$.next({
					tests: parsedTests,
					selectedTest: this.tests$.value.selectedTest ?? {...parsedTests[0]},
					loaded: true,
				});
			}));
	}

	public getQuestions (id) {
		this.loading$.next(true);

		return this.httpClient.ESGIApi.get<QuestionResponse[]>('bingo', 'questions', {testID: id})
			.pipe(tap((resp) => {
				const questions = sortBy(resp.map(x => new Question(x)), SortBy.TestOrder );
				const selectedQuestions = questions.map(x => x.questionID);

				this.questions$.next({
					questions: questions,
					selectedQuestionsIDs: selectedQuestions,
					selectedSortBy: SortBy.TestOrder,
					warning: '',
					valid: true,
				});

				this.loading$.next(false);
			}));
	}

	public setSelectedTest(test: Test) {
		this.tests$.next({
			...this.tests$.value,
			selectedTest: test,
		});
	}

	public sortQuestions(all: boolean) {
		this.questions$.next({
			...this.questions$.value,
			selectedQuestionsIDs: all ? this.questions$.value.questions.map(x => x.questionID) : [],
		});
		this.validateQuestions();
	}

	public setSelectedQuestions(questions: number[]) {
		this.questions$.next({
			...this.questions$.value,
			selectedQuestionsIDs: questions,
		});
		this.validateQuestions();
	}

	public setQuestionsSortedBy(value: SortBy) {
		this.questions$.next({
			...this.questions$.value,
			questions: sortBy(this.questions$.value.questions.map(x => x), value ),
			selectedSortBy: value,
		});
	}

	public validateQuestions = () => {
		const limit = this.cards$.value.x3cardSize ? 8 : 24;
		const cardSize = this.cards$.value.x3cardSize ? '3X3' : '5X5';
		const selectedQuestions = this.questions$.value.selectedQuestionsIDs;

		let warning = '';
		let valid = true;

		if (selectedQuestions.length === limit) {
			valid = true;
			warning = 'You have reached the minimum number of questions required';
		}

		if (selectedQuestions.length < limit) {
			valid = false;
			warning = `To create a ${cardSize} card select at least ${limit} questions`;
		}

		this.questions$.next({
			...this.questions$.value,
			warning: warning,
			valid: valid,
		});
	};

	public updatePrint(field: keyof Print, value: boolean | number) {
		this.print$.next({
			...this.print$.value,
			[field]: value,
		});
	}

	public updateCards(field: keyof Cards, value: boolean | string) {
		this.cards$.next({
			...this.cards$.value,
			[field]: value,
		});
	}

	public updateSpecialistGroups = (id: number) => {
		const players = this.players$.value;

		this.players$.next({
			...players,
			specialistGroups: players.specialistGroups.map(x => x.id === id ? {...x, selected: true} : {
				...x,
				selected: false,
			}),
			students: players.students.map(x => ({...x, selected: true})),

		});
	};

	public updateGroups = (id: number) => {
		const players = this.players$.value;
		this.players$.next( {
			...players,
			groups: players.groups.map(x => x.id === id ? {...x, selected: true} : {...x, selected: false}),
			students: players.students.map(x => ({...x, selected: true})),
		});
	};

	public updateStudents = (id: number) => {
		const players = this.players$.value;
		const students = players.students.map(x => x.id === id ? {...x, selected: !x.selected} : x);
		students[0].selected = students.filter(x => x.id > 0 ? !x.selected : false).length === 0;

		this.players$.next({
			...players,
			students: id === 0
				? players.students.map((x, _, arr) => ({...x, selected: !arr[0].selected}))
				: students,

		});
	};

	public updateClasses = (id: number) => {
		const players = this.players$.value;

		this.players$.next({
			...players,
			classes: players.classes.map(x => x.id === id ? {...x, selected: true} : {...x, selected: false}),
			groups: players.groups.map(x => x.id === 0 ? {...x, selected: true} : {...x, selected: false}),
			students: players.students.map(x => ({...x, selected: true})),
		});
	};

	public validateStep = (step): boolean | number => {
		if (step === 1) {
			return this.students.length > 0;
		}
		if (step === 2) {
			return !!this.subjects$.value.selectedSubject && !!this.tests$.value.selectedTest;
		}
		if (step === 4) {
			if (this.print$.value.gamePlayedAtStudentHome) {
				return this.print$.value.cardsPerPerson && this.print$.value.sheetsPerStudent && this.print$.value.numberOfPlayersAtHome;
			} else {
				return this.print$.value.cardsPerStudent && this.print$.value.sheetsPerClass;
			}
		}
		return true;
	};

	public getModel (hierarchy: HierarchySnapshot): ResultModel {
		const model = new ResultModel();
		model.className = this.selectedClass?.name;
		model.specialistGroupName = this.specialistGroup?.name;
		model.difficulty = this.cards$.value.difficulty;
		model.inClassroom = !this.print$.value.gamePlayedAtStudentHome;
		model.oneCardPerPage = !this.cards$.value.twoCardsPerPage;
		model.questions = this.questions$.value.questions
			.filter(x => this.questions$.value.selectedQuestionsIDs.some(s => s == x.questionID))
			.map(x => new EntityModel(x.questionID, x.name));
		model.students = this.students.map(x => new EntityModel(x.id, x.name));
		model.subject = this.subjects$.value.selectedSubject.name;
		model.test = new EntityModel(this.tests$.value.selectedTest.id, this.tests$.value.selectedTest.name);
		model.x3cardSize = this.cards$.value.x3cardSize;
		model.sheetsCount = this.print$.value.gamePlayedAtStudentHome
			? this.print$.value.sheetsPerStudent
			: this.print$.value.sheetsPerClass;

		model.cardsCount = this.print$.value.gamePlayedAtStudentHome
			? this.print$.value.cardsPerPerson * this.print$.value.numberOfPlayersAtHome
			: this.print$.value.cardsPerStudent;
		model.teacherID = hierarchy.classic.teacherID;
		model.specialistID = getSpecialistID(hierarchy);

		return model;
	}

	public exportPDF (hierarchy: HierarchySnapshot) {
		const model = this.getModel(hierarchy);

		this.loading$.next(true);

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = 'Bingo_' + year + '-' + month + '-' + day + '.pdf';

		return this.httpClient.ESGIApi.file('bingo', 'create-game', filename, model, null, true);
	}
}
