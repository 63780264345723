import React from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import AddSummaryNoteModal from 'modules/assessments/testing/add-summary-notes/modal';
import {HttpClient} from '@esgi/api';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
  testSessionID: number;
  note: string;
  isWhite?: boolean;
}

export class State {
	opened: boolean;
	note: string;
}

export default class AddSummaryNoteButton extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	public componentDidMount() {
		if (this.props.note) {
			this.setState({note: this.props.note});
		}
	}

	public toggleOpened() {
		this.setState((prevState) => ({opened: !prevState.opened}));
	}

	public save(note: string) {
		this.setState(
			() => ({note}),
			() => {
				this.saveNote().subscribe();
				this.toggleOpened();
			},
		);
	}

	public renderButton() {
		return (
			<Buttons.Text
				className={join(styles.button, this.props.isWhite && styles.white)}
				onClick={() => this.toggleOpened()}
			>
				{this.state.note ? 'View Summary' : 'Add Summary'}
			</Buttons.Text>
		);
	}

	public renderModal() {
		if (this.state.opened) {
			return (
				<AddSummaryNoteModal
					note={this.state.note}
					onSaveClicked={(note: string) => this.save(note)}
					onCancelClicked={() => this.toggleOpened()}
				/>
			);
		}
	}

	public saveNote() {
		return HttpClient.default.ESGIApi
			.post('assessment/test-screen', 'update-summary-note', {
				testSessionID: this.props.testSessionID,
				note: this.state.note || null,
			});
	}

	public render() {
		return (
			<>
				{this.renderButton()}
				{this.renderModal()}
			</>
		);
	}
}
