import {HighchartsReact} from '@esgi/deprecated/highcharts';
import styles from 'modules/reports/test-history/components/view/styles.module.less';

interface Props {
	chartOptions: any,
	chartRef: React.MutableRefObject<HTMLDivElement>
}

export function AllStudentsReportChartView({chartOptions, chartRef}: Props) {
	return (
		<div className={styles.chart} ref={chartRef}>
			<HighchartsReact options={chartOptions} immutable={true}/>
		</div>
	);
}