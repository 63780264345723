import {ToggleGroup} from '@esgi/ui/controls';
import {TabId} from '../../types';
import {Dispatch, SetStateAction} from 'react';
import {ToggleGroupContent, ToggleGroupItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {tabItems} from './constants';

type Props = {
	activeTab: TabId;
	setActiveTab: Dispatch<SetStateAction<TabId>>;
};

export function ToggleDiagramView({activeTab, setActiveTab}: Props) {
	return (
		<ToggleGroup.Root dataCy='view-toggle-group' onValueChange={(tabId) => setActiveTab(tabId as TabId)} value={activeTab}>
			<ToggleGroupContent>
				{tabItems.map(({label, tabId}) => (
					<ToggleGroupItem value={tabId} disabled={tabId === activeTab} applyDisabledStyles={false} key={tabId}>
						<Text size='medium' bold>
							{label}
						</Text>
					</ToggleGroupItem>
				))}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
