import React, {useEffect, useRef, useState} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {ModalHeader} from '@esgi/deprecated/ui-kit/modal';
import {SettingsModalHeader} from './components/settings-modal-header';
import styles from './styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useService} from '@esgi/core/service';
import {StudentDetailReport} from '../student-detail-report';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {ReportService} from '../../services/report-service';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';

interface ISDRSettings {
	classId: number;
	groupId: number;
	studentId: number;
	subjectId: number;
	subjectType: SubjectType;
	testIDs: number[];
	globalSchoolYearID: number;
}


interface Props {
	iSDRSettings: ISDRSettings;
	modalManagerRef: ModalManagerRefObject;
	onClose: () => void;
	hierarchy: HierarchyInstance;
	changesCollector: ChangesCollector
}

export function SettingsModal({modalManagerRef, iSDRSettings, onClose, hierarchy, changesCollector}: Props) {
	const reportService = useService(ReportService);
	const [initiallyLoaded, setInitiallyLoaded] = useState(false);
	const modalBodyRef = useRef(null);
	const selectedStudent = useBehaviorSubject(reportService.settingsService.selectedStudent);

	function fillChangesCollector() {
		changesCollector.applyChanges({
			classID: reportService.settingsService.selectedClass.value[0]?.itemID,
			groupID: reportService.settingsService.selectedGroup.value[0]?.itemID,
			studentID: selectedStudent[0]?.studentID,
			subjectTabID: reportService.settingsService.selectedSubject.value[0]?.subjectID,
			subjectTabType: SubjectType[reportService.settingsService.selectedSubject.value[0]?.subjectType],
		});
	}

	function onBack() {
		closeOnlySDR();
	}

	function closeOnlySDR() {
		fillChangesCollector();
		modalManagerRef.current.close();
		reportService.destroy();
		reportService.settingsService.destroy();
		reportService.dataService.destroy();
		setInitiallyLoaded(false);
	}

	function closeModal() {
		// onClose should be invoked in advanced (not in mangerRef close callback) so the test selector opened before
		// is closed before the SDR is hidden
		onClose();
		closeOnlySDR();
	}


	useEffect(() => {
		reportService.init(iSDRSettings, hierarchy).subscribe(() => setInitiallyLoaded(true));
	}, [iSDRSettings, hierarchy, reportService]);


	return <>
		<ServiceLoader trackingService={reportService.dataService} fullscreen
		               longLoadingText='Please wait. This report may take a few minutes to load.'/>
		<ServiceLoader trackingService={reportService} fullscreen
		               longLoadingText='Please wait. This report may take a few minutes to load.'/>
		{initiallyLoaded && <Modal modalManagerRef={modalManagerRef}
		                           className={join(styles.studentDetailReportDialog, 'component-scroll', styles.modalContent)}>
			<ModalHeader className={join(styles.blueHeader)}>
				<SettingsModalHeader onGoBack={onBack} onClose={closeModal}
				                     service={reportService}/>
			</ModalHeader>
			<Modal.Body ref={modalBodyRef} className={styles.modalBody}>
				<StudentDetailReport service={reportService}/>
			</Modal.Body>
		</Modal>}
	</>;
}
