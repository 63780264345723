import React, {useEffect} from 'react';
import {useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

interface Props {
	onClosed: () => void;
	show: boolean;
}

export function NoGraphAlert({onClosed, show}: Props) {
	const modalManagerRef = useModal();

	useEffect(() => {
		if (show) {
			modalManagerRef.current.open();
		}
	}, [show]);

	return show && <Alert modalManagerRef={modalManagerRef}>
		<Alert.Body>
			No graph image stored
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={() => modalManagerRef.current.close(() => onClosed())}>
				OK
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
