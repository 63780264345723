import React from 'react';
import './component.less';
import {IGradeRangeSetView} from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/models';
import {SharedGradeRangeService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/service';

export class Props {
	gradeRangeSets: IGradeRangeSetView[];
	selectedGradeRangeSet: IGradeRangeSetView;
	service: SharedGradeRangeService;
}

export default class GradeRangesList extends React.PureComponent<Props> {
	getItemClass(grs: IGradeRangeSetView) {
		let cls = 'grade-range-shared-list-item';
		
		if (this.props.selectedGradeRangeSet.id === grs.id) {
			cls+= ' selected';
		}
		
		return cls;
	}
	
	render() {
		return <div className='grade-range-shared-list'>
			{this.props.gradeRangeSets.map(grs => {
				return <div key={grs.id} className={this.getItemClass(grs)} onClick={() => this.props.service.selectGradeRangeSet(grs.id)}>
					{grs.ownerName}
				</div>;
			})}
		</div>;
	}
}