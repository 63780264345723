import {SkeletonShape} from '@esgi/main/kits/common';
import {CentralPanelBody, CentralPanelDivider, CentralPanelTablesContainer} from '../central-panel-body';
import {CentralPanelHeader, ActionButtonsBox, ActionButtonsDivider, HeaderContainer} from '../central-panel-header';
import {TableTestsSkeleton} from '../table-tests';
import {AssignmentDetailsCardSkeleton} from '@esgi/main/kits/assignments';
import {TableStudentsSkeleton} from '../table-students/components/table-students-skeleton';

export function AssignmentSkeleton() {
	return (
		<>
			<CentralPanelHeader>
				<HeaderContainer>
					<SkeletonShape width={88} />
					<ActionButtonsBox>
						<SkeletonShape width={24} height={24} />
						<ActionButtonsDivider />
						<SkeletonShape width={24} height={24} />
						<SkeletonShape width={24} height={24} />
						<SkeletonShape width={100} height={36} />
					</ActionButtonsBox>
				</HeaderContainer>
			</CentralPanelHeader>
			<CentralPanelBody>
				<AssignmentDetailsCardSkeleton />
				<CentralPanelTablesContainer>
					<TableTestsSkeleton />
					<CentralPanelDivider />
					<TableStudentsSkeleton />
				</CentralPanelTablesContainer>
			</CentralPanelBody>
		</>
	);
}
