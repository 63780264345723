import {useMemo} from 'react';
import {SessionItem} from '../../session-list/types';
import {TestSession, TestInfo} from '../../../../../../../../types';

export function useSessionItems({testSessions, testInfo}: {testSessions: TestSession[]; testInfo: TestInfo}) {
	return useMemo(() => {
		const {totalPossible} = testInfo;

		return testSessions.map<SessionItem>(({correctAnswers, testDate, id}, index, iteratedSessionList) => {
			const percent = (correctAnswers / totalPossible) * 100;

			const nextSessionCorrectAnswers = iteratedSessionList[index + 1]?.correctAnswers ?? 0;
			const nextSessionPercent = (nextSessionCorrectAnswers / totalPossible) * 100;

			const delta = Math.round(percent - nextSessionPercent);

			return {
				date: testDate,
				delta: index === iteratedSessionList.length - 1 ? 0 : delta,
				sessionId: id,
				totalPossible,
				sessionResult: correctAnswers,
			};
		});
	}, [testSessions, testInfo]);
}
