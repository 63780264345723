import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const tableStyles: CSS = {
	gridTemplateColumns: 'repeat(4, 1fr)',
	rowGap: '$4',
	columnGap: 40,
	alignItems: 'center',
};

export const Table = styled(GridBox, {
	...tableStyles,
	paddingLeft: 12,
	variants: {
		isTablet: {
			true: {
				gridTemplateColumns: 'repeat(4, 1fr)',
				paddingLeft: 0,
				columnGap: 14,
			},
		},
	},
});

export const ResultHeaderRow = styled(GridBox, {
	gridColumnStart: 3,
	gridColumnEnd: 5,
});

export const ResultBox = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'baseline',
	gridTemplateColumns: 'auto 142px',
	gap: 20,

	variants: {
		withOpacity: {
			true: {
				opacity: 0.4,
			},
		},
	},
});

export const SessionDetailsBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	columnGap: '$4',
	justifyContent: 'end',
	alignItems: 'center',

	'& > svg': {
		fill: '$muted',

		'& path': {
			fill: '$muted',
		},
	},
});
