import './styles.less';
import React from 'react';
import ReactDOM from 'react-dom';
import {SubjectItem} from '../../../../services/subjects-service';

export class Props {
	close: () => any;
	testId: number;
	hideClicked: () => any;
	subjects: SubjectItem[];
	x: number;
	y: number;
	createSubject: () => any;
	addTestToSubject: () => any;
}

export class ContextMenu extends React.PureComponent<Props> {
	private rootElement: HTMLUListElement;

	constructor(props: Props) {
		super(props);

		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	render() {
		return ReactDOM.createPortal(<ul className='dropdown-menu card-context-menu'
		                                 style={{left: this.props.x, top: this.props.y}}
		                                 ref={r => this.rootElement = r}>

			<li>
				<a href='#' onClick={() => this.props.addTestToSubject()}>Add test to subject tab
				</a>
			</li>
			<li>
				<a href='#' onClick={() => this.props.hideClicked()}>Hide from View</a>
			</li>
		</ul>, document.body);
	}


	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		if (this.rootElement && !this.rootElement.contains(event.target)) {
			this.props.close();
		}
	}
}
