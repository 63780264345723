import {dispatchAppEvent} from '@esgillc/events';
import {CreateSubject, SubjectCreatedEvent, SubjectUpdatedEvent, UpdateSubject} from 'api/entities/events/subject';
import {SubjectType} from '@esgi/core/enums';

export function dispatchCreateEvent(subjectID: number, published: boolean, name: string, subjectType: SubjectType, gradeLevels: number[], selected: boolean) {
	dispatchAppEvent(SubjectCreatedEvent, new SubjectCreatedEvent(subjectID, published, {
		name: name,
		subjectType: subjectType,
		gradeLevels: gradeLevels,
	} as CreateSubject.InModel, selected));
}

export function dispatchUpdateEvent(subjectID: number, published: boolean, name: string, subjectType: SubjectType, gradeLevels: number[]) {
	dispatchAppEvent(SubjectUpdatedEvent, new SubjectUpdatedEvent(subjectID, published, {
		name: name,
		subjectType: subjectType,
		gradeLevels: gradeLevels,
	} as UpdateSubject.InModel));
}

