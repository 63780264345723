import {join} from '@esgillc/ui-kit/utils';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {SchoolModel} from '../../../../../types';
import styles from '../styles.module.less';

interface Props {
	isVisible: boolean;
	onClose: () => void;
	availableSchools: SchoolModel[];
	onSelect: (school: SchoolModel) => void
}

export function SchoolMenuList({isVisible, onClose, availableSchools, onSelect}: Props) {
	return <Menu className={join(styles.schoolList, !isVisible && styles.listMenuClosed)}
	             show={isVisible}
	             onClickOutside={onClose}>
		{availableSchools.map(school => <MenuItem key={school.federalSchoolID}
		                                          onSelect={() => {
													  onSelect(school);
													  onClose();
		                                          }}>
			{school.federalSchoolName} ({school.federalDistrictName})
		</MenuItem>)}
		<hr className={styles.listItemLine}/>
		<MenuItem onSelect={() => {
			onSelect(null);
			onClose();
		}}>My school isn't listed</MenuItem>
	</Menu>;
}
