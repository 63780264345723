import {Criteria, Description, Level} from '../models';

export type CommonInfo<T> = { entity: T };
export type CriteriaInfo = { criteria: Criteria[] };
export type LevelsInfo = { levels: Level[] };
export type DescriptionsInfo = { descriptions: Description[] };

export function getNextTempID<T extends { id: number }>(arr: T[]): number {
	const ids = arr.map(i => i.id);
	const negativeIds = ids.filter(i => i < 0);
	const sortedIds = negativeIds.sort((a, b) => a - b).reverse();
	const minID = sortedIds.pop() || 0;
	return minID - 1;
}
