export interface InitArguments {
	groupID: number;
	teacherID: number;
	classID: number;
	groupName: string;
}


export class InitCreateModel {
	items: StudentModel[];
}

export class InitEditModel {
	availableItems: StudentModel[];
	itemsInGroup: StudentModel[];
	groups: GroupModel[];
	position: string;
}

export class StudentModel {
	id: number;
	name: string;
}

class GroupModel {
	id: number;
	name: string;
	checked: boolean;
	orderNum: number;

	constructor(id?: number, name?: string, checked: boolean = false) {
		this.id = id;
		this.name = name;
		this.checked = checked;
	}
}

export class InModel {
	constructor(public id: number, public label: string, public checked: boolean) {
	}
}

export enum ArrowDirection {
	None,
	Left,
	Right
}
