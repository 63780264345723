import {useState} from 'react';
import styles from './styles.module.less';

type Props = {
	correctButtonLabel: string;
	incorrectButtonLabel: string;
	yesClicked: () => void;
	noClicked: () => void;
};

export function MobileButtons(props: Props) {
	const [showLeftTestButtons, setShowLeftTestButtons] = useState<boolean>(false);
	const [showRightTestButtons, setShowRightTestButtons] = useState<boolean>(false);

	const toggleLeftButtons = () => {
		setShowLeftTestButtons(!showLeftTestButtons);
		changeSessionKeyState('expandLeftTestButton', showLeftTestButtons);
	};

	const toggleRightButtons = () => {
		setShowRightTestButtons(!showRightTestButtons);
		changeSessionKeyState('expandRightTestButton', showRightTestButtons);
	};

	const changeSessionKeyState = (testButtonSize, isShowButton) => {
		if (isShowButton) {
			sessionStorage.setItem(testButtonSize, 'true');
		} else {
			sessionStorage.setItem(testButtonSize, '');
		}
	};

	const buttons = <>
		<button
			className='btn btn-answer btn-yes'
			data-cy='correct-button'
			tabIndex={4}
			onClick={props.yesClicked}>
			{props.correctButtonLabel}
		</button>
		<button
			className='btn btn-answer btn-no'
			data-cy='incorrect-button'
			tabIndex={5}
			onClick={props.noClicked}>
			{props.incorrectButtonLabel}
		</button>
	</>;

	return <div>
		<div className={styles.leftTabletButton}>
			<button className='btn btn-link collapse-button left-collapse-button'
			        data-cy='left-dock-buttons'
			        onClick={toggleLeftButtons}>
				Dock buttons <i className='fa fa-angle-double-right'/>
			</button>
			{showLeftTestButtons && buttons}
		</div>
		<div className={styles.rightTabletButton}>
			<button className='btn btn-link collapse-button right-collapse-button'
			        data-cy='right-dock-buttons'
			        onClick={toggleRightButtons}>
				<i className='fa fa-angle-double-left'/> Dock buttons
			</button>
			{showRightTestButtons && buttons}
		</div>
	</div>;
}
