import React, {useRef, useEffect} from 'react';
import {LogoModel} from '../../parent-letter-editor/types';
import {addFile, fileTypes} from './utils';
import {CameraIcon, TrashIcon} from '@esgillc/ui-kit/icons';
import styles from './styles.module.less';

interface Props {
	logo?: LogoModel;
	onChanged: (logo: LogoModel | null) => void;
}

const ImageUploader = ({logo, onChanged}: Props) => {
	const uploadInput = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!logo) {
			uploadInput.current.value = '';
		}
	}, [logo]);

	const onFilesDrop = (event: React.DragEvent<HTMLLabelElement>) => {
		event.stopPropagation();
		event.preventDefault();
		addFile(event.dataTransfer.files, onChanged);
	};

	const onFilesDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
		event.stopPropagation();
		event.preventDefault();
		event.dataTransfer.dropEffect = 'copy';
	};

	const onUploadInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			addFile(event.target.files, onChanged);
		}
	};

	const clearImage = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		uploadInput.current.value = '';
		onChanged(null);
	};

	return (
		<label
			data-cy='image-uploader'
			onDragOver={onFilesDragOver}
			onDrop={onFilesDrop}
			className={styles.upload}
		>
			<input
				data-cy='input-file'
				type='file'
				ref={(input) => (uploadInput.current = input)}
				onChange={onUploadInputChange}
				accept={fileTypes.join(', ')}
			/>

			{logo
				? <>
						<div
							data-cy='logo'
							className={styles.image}
							style={{backgroundImage: logo ? `url('data:${logo.contentType};base64,${logo.base64}')` : null}}
						></div>
						<div
							data-cy='remove-button'
							className={styles.trashIcon}
							onClick={clearImage}
						>
							<TrashIcon/>
						</div>
					</>

				: <>
						<div
							data-cy='upload-logo'
							className={styles.cameraIcon}
						>
							<CameraIcon/>
						</div>
						Add Logo
					</>
			}
		</label>
	);
};

export default ImageUploader;
