import {resolvedPromise} from '@esgi/deprecated/utils';
import {Form, FormElement, FormField, Validators} from '@esgi/deprecated/knockout';
import {StripeMode, StripeUtil} from '../../../kit/stripe';
import {FieldTool} from '../../../kit/component';
import {StripeFormTemplate} from './template';

export class StripeFormModel {
}

export class StripeForm extends Form {
	private resizeTimer;
	private readonly EmptyStateValidationMessage = 'Required';
	private readonly ValidStateValidationMessage = '';

	constructor() {
		super();
		this.cardNumber = this.createCardField(StripeUtil.ElementNameCardNumber);
		this.cardExpiry = this.createCardField(StripeUtil.ElementNameCardExpiry);
		this.cardCvc = this.createCardField(StripeUtil.ElementNameCardCvc);
		this.address = this.createCardField(StripeUtil.ElementNameAddress);
		this.billingZip = this.createBillingZip();
		this.resolveValidationDisplaying();
		$(window).resize(() => {
			clearTimeout(this.resizeTimer);
			this.resizeTimer = setTimeout(() => {
				this.resolveValidationDisplaying();
			}, 300);
		});
	}

	template = () => StripeFormTemplate.render();

	private resolveValidationDisplaying() {
		const width = $(window).width();
		const show = width > 1024;
		this.innerElements.forEach((f) => {
			FieldTool.showTooltipAfterValidation(f as FormElement, show);
		});
	}

	stripeUtil = new StripeUtil();
	stripeElementState = [];
	cardNumber: FormField;
	cardExpiry: FormField;
	cardCvc: FormField;
	address: FormField;
	billingZip: FormField;

	serialize(): StripeFormModel {
		const m = new StripeFormModel();
		return m;
	}

	private createCardField = (elementType: string) => {
		let validator = Validators.Custom(_ => {
			return resolvedPromise(this.stripeElementState[elementType] === '');
		}, _ => this.stripeElementState[elementType]);

		const f = this.createField(this.ValidStateValidationMessage, validator);
		f.validation.showValidation(true);
		f.validation.errorValidation(true);
		f.validation.successValidation(true);
		f.validation.errorPosition = this.GetElementTooltipPosition(elementType);
		f.validation.validationMessageTitleUseHtml = true;

		return f;
	};

	private GetElementTooltipPosition = (elementType: string) => {
		switch (elementType) {
			case StripeUtil.ElementNameCardCvc:
				return 'right';
			default:
				return 'left';
		}
	};

	private createBillingZip = () => {
		const f = this.createField('zip', Validators.Required());
		f.validation.showValidation(true);
		f.validation.errorValidation(true);
		f.validation.successValidation(true);
		return f;
	};

	afterRender(rootElement: JQuery) {
		setTimeout(() => {
			this.stripeUtil.StripeInit(StripeMode.MultipleFields).done(() => {
				this.createElement('cardNumber', this.stripeUtil.CardNumber, '#stripe-card-number', this.getEmptyStateValidationMessageText('cardNumber'));
				this.createElement('cardExpiry', this.stripeUtil.CardExpiry, '#stripe-card-expiry', this.getEmptyStateValidationMessageText('cardExpiry'));
				this.createElement('cardCvc', this.stripeUtil.CardCvc, '#stripe-card-cvc', this.getEmptyStateValidationMessageText('cardCvc'));
				this.createElement('address', this.stripeUtil.Address, '#stripe-address', this.getEmptyStateValidationMessageText('address'));
			});
		}, 100);
		return resolvedPromise(rootElement);
	}

	private createElement(formElement: string, element: any, elementSelector: string, emptyStateValidationMessage: string = this.EmptyStateValidationMessage) {
		this[formElement].value('');
		this.stripeElementState[formElement] = emptyStateValidationMessage;

		element.mount(elementSelector);
		element.on('change', (event) => {
			this.processEvent(event);
		});
	}

	private processEvent(event: any) {
		let type = event.elementType;
		if (event.error) {
			this.stripeElementState[type] = event.error.message;
			this.setDummyFieldValue(type, 'error');
		} else if (event.complete) {
			this.stripeElementState[type] = this.ValidStateValidationMessage;
			this.setDummyFieldValue(type, 'complete');
		} else if (event.empty) {
			this.stripeElementState[type] = this.getEmptyStateValidationMessageText(type);
			this.setDummyFieldValue(type, '');
		}
	}

	private setDummyFieldValue(elementType: string, fieldValue: string) {
		let fieldName = elementType == StripeUtil.ElementNameCard ? StripeUtil.ElementNameCardNumber : elementType;
		this[fieldName].value(fieldValue);
	}

	private getEmptyStateValidationMessageText(elementType: string): string {
		switch (elementType) {
			case 'cardNumber':
				return '<div>Please enter your credit card number.</div>';

			case 'cardExpiry':
				return '<div>Please enter your card expiration date.</div>';

			case 'cardCvc':
				return '<div>Please enter the 3-4 digit CVC number</div><div>found on the back of your credit card.</div>';

			case 'address':
				return '<div>Please enter your billing address.</div>';

			default:
				return this.EmptyStateValidationMessage;
		}
	}
}
