import React from 'react';

const BingoLogo: React.FC = () => {
	return (
	<svg width='61px' height='25px' viewBox='0 0 61 25'>
		<path
			d=' M 38.804 24.782 C 37.471 24.782 36.235 24.61 35.097 24.268 C 33.958 23.925 32.961 23.393 32.11 22.671 C 31.258 21.95 30.592 21.032 30.11 19.92 C 29.628 18.809 29.389 17.476 29.389 15.92 C 29.389 14.458 29.634 13.165 30.125 12.044 C 30.616 10.924 31.283 9.988 32.126 9.24 C 32.969 8.489 33.958 7.926 35.098 7.546 C 36.237 7.166 37.463 6.977 38.779 6.977 C 40.502 6.977 41.871 7.245 42.89 7.783 C 43.908 8.321 44.417 9.006 44.417 9.84 C 44.417 10.266 44.306 10.64 44.084 10.964 C 43.862 11.288 43.601 11.543 43.306 11.728 C 42.843 11.433 42.265 11.155 41.57 10.895 C 40.876 10.636 40.056 10.505 39.113 10.505 C 38.335 10.505 37.627 10.63 36.987 10.879 C 36.348 11.13 35.797 11.487 35.334 11.948 C 34.871 12.411 34.51 12.976 34.25 13.642 C 33.989 14.309 33.86 15.057 33.86 15.891 C 33.86 16.797 33.985 17.59 34.234 18.265 C 34.485 18.941 34.832 19.502 35.276 19.946 C 35.72 20.389 36.252 20.723 36.873 20.945 C 37.494 21.167 38.166 21.279 38.887 21.279 C 39.331 21.279 39.734 21.232 40.095 21.14 C 40.455 21.047 40.737 20.954 40.941 20.862 L 40.941 17.723 L 37.747 17.723 C 37.635 17.538 37.535 17.297 37.442 17.002 C 37.35 16.707 37.303 16.39 37.303 16.057 C 37.303 15.465 37.442 15.029 37.72 14.751 C 37.998 14.473 38.358 14.334 38.804 14.334 L 43.47 14.334 C 43.989 14.334 44.396 14.477 44.693 14.764 C 44.99 15.052 45.137 15.455 45.137 15.972 L 45.137 21.749 C 45.137 22.287 45.035 22.685 44.832 22.943 C 44.628 23.204 44.304 23.462 43.86 23.721 C 43.6 23.869 43.285 24.008 42.915 24.137 C 42.544 24.267 42.132 24.378 41.678 24.471 C 41.225 24.564 40.752 24.637 40.262 24.693'
			fill='rgb(255,255,255)'/>
		<path
			d=' M 3.625 23.74 C 3.208 23.323 3 22.764 3 22.06 L 3 9.782 C 3 9.078 3.208 8.518 3.625 8.101 C 4.042 7.685 4.601 7.476 5.305 7.476 L 14.499 7.476 C 14.611 7.662 14.711 7.893 14.804 8.171 C 14.896 8.449 14.942 8.763 14.942 9.116 C 14.942 9.728 14.807 10.181 14.539 10.478 C 14.271 10.775 13.895 10.922 13.415 10.922 L 7.25 10.922 L 7.25 14.033 L 13.694 14.033 C 13.806 14.218 13.907 14.45 13.999 14.728 C 14.092 15.005 14.138 15.32 14.138 15.673 C 14.138 16.284 14.009 16.734 13.748 17.019 C 13.488 17.307 13.12 17.449 12.637 17.449 L 7.25 17.449 L 7.25 20.922 L 14.611 20.922 C 14.723 21.107 14.823 21.348 14.915 21.643 C 15.008 21.94 15.054 22.264 15.054 22.615 C 15.054 23.227 14.919 23.67 14.651 23.948 C 14.383 24.226 14.017 24.365 13.554 24.365 L 5.305 24.365 C 4.601 24.365 4.042 24.156 3.625 23.74 Z '
			fill='rgb(255,255,255)'/>
		<path
			d=' M 19.857 24.573 C 19.136 24.434 18.125 24.236 17.597 23.975 C 17.068 23.717 16.652 23.397 16.347 23.017 C 16.042 22.637 15.888 22.206 15.888 21.724 C 15.888 21.225 16.03 20.808 16.318 20.474 C 16.605 20.14 16.923 19.872 17.277 19.668 C 17.776 20.075 18.401 20.436 19.151 20.752 C 19.902 21.066 20.749 21.225 21.694 21.225 C 22.676 21.225 23.374 21.053 23.791 20.711 C 24.207 20.368 24.416 19.957 24.416 19.475 C 24.416 19.031 24.254 18.674 23.93 18.406 C 23.606 18.138 23.119 17.903 22.471 17.698 L 21.055 17.255 C 20.278 17.013 19.574 16.751 18.945 16.464 C 18.314 16.176 17.778 15.835 17.334 15.436 C 16.891 15.038 16.543 14.566 16.293 14.02 C 16.042 13.474 15.918 12.812 15.918 12.033 C 15.918 10.478 16.507 9.245 17.682 8.339 C 18.858 7.432 20.492 6.977 22.585 6.977 C 23.382 6.977 24.117 7.033 24.794 7.143 C 25.469 7.255 26.048 7.424 26.53 7.656 C 27.012 7.887 27.39 8.169 27.668 8.503 C 27.946 8.836 28.085 9.224 28.085 9.67 C 28.085 10.096 27.978 10.47 27.764 10.794 C 27.552 11.119 27.305 11.392 27.028 11.614 C 26.601 11.319 26.036 11.068 25.334 10.864 C 24.63 10.659 23.862 10.559 23.029 10.559 C 22.139 10.559 21.482 10.698 21.057 10.976 C 20.631 11.254 20.419 11.605 20.419 12.031 C 20.419 12.401 20.558 12.683 20.835 12.878 C 21.113 13.073 21.547 13.271 22.141 13.476 L 23.642 13.975 C 24.512 14.272 25.276 14.591 25.934 14.934 C 26.592 15.277 27.141 15.665 27.587 16.101 C 28.031 16.537 28.364 17.037 28.586 17.602 C 28.808 18.167 28.92 18.809 28.92 19.533 C 28.92 20.31 28.767 21.018 28.461 21.659 C 28.156 22.297 27.701 22.849 27.099 23.312 C 26.497 23.775 25.756 24.135 24.877 24.396 C 23.997 24.654 22.982 24.785 21.835 24.785'
			fill='rgb(255,255,255)'/>
		<path
			d=' M 48.657 24.591 C 47.824 24.591 47.226 24.477 46.865 24.249 C 46.505 24.022 46.323 23.578 46.323 22.918 L 46.323 13.233 C 46.509 13.186 46.767 13.138 47.101 13.092 C 47.434 13.046 47.805 13.02 48.212 13.02 C 49.045 13.02 49.643 13.138 50.004 13.373 C 50.365 13.609 50.546 14.056 50.546 14.716 L 50.546 24.402 C 50.361 24.448 50.102 24.492 49.768 24.531'
			fill='rgb(255,255,255)'/>
		<path
			d=' M 48.084 5.797 C 48.306 6.304 48.497 6.822 48.682 7.344 C 49.061 6.87 49.445 6.396 49.846 5.937 C 51.653 3.863 55.367 0.057 58.331 0.22 C 59.214 0.267 59.19 0.541 58.452 0.892 C 55.26 2.416 52.595 6.541 50.754 9.477 C 50.292 10.217 49.63 11.505 48.875 11.907 C 48.397 12.161 47.747 12.094 47.3 11.802 C 46.446 11.245 45.425 8.487 44.83 7.441 C 44.377 6.543 44.737 5.448 45.635 4.993 C 46.533 4.54 47.629 4.899 48.084 5.797 Z '
			fillRule='evenodd' fill='rgb(255,255,255)'/>
	</svg>
	);
};

export default BingoLogo;
