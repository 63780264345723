import {Dispatch, useCallback, useEffect, useState} from 'react';
import {SettingsPanel, ToggleGroup} from '@esgi/main/kits/reports';
import {MarkingPeriod} from './types';
import {items} from './constants';

interface Props {
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function MarkingPeriodControl({value: externalValue, onChange}: Props) {
	const [value, setValue] = useState(MarkingPeriod.All);

	useEffect(() => {
		setValue(externalValue ? MarkingPeriod.All : MarkingPeriod.Current);
	}, [externalValue]);

	const handleValueChanged = useCallback(
		(newValue: string | number) => {
			const adaptedValue = newValue as MarkingPeriod;

			setValue(adaptedValue);
			onChange(adaptedValue === MarkingPeriod.All);
		},
		[onChange],
	);

	return (
		<SettingsPanel data-cy='marking-period-settings-panel' title='Marking Period'>
			<ToggleGroup
				value={value}
				options={items}
				onChange={handleValueChanged}
				dataCy='marking-period-toggle-group'
			/>
		</SettingsPanel>
	);
}
