import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EmptyContent = styled(GridBox, {
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	backgroundColor: '$background',
	paddingTop: 11,
	paddingBottom: 11,
	paddingLeft: 20,
	paddingRight: 20,
	height: 'max-content',
	justifyContent: 'center',
	gap: 6,
	textAlign: 'center',

	variants: {
		size: {
			full: {
				width: '100%',
			},
		},

		position: {
			center: {
				alignSelf: 'center',
				justifySelf: 'center',
			},
			middle: {
				margin: '0 auto',
			},
		},
	},
});
