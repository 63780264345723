import React from 'react';
import styles from '../../styles.module.less';
import {TestHistoryService} from '../../../../../../services/test-history-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {Subject as SubjectType} from '../../../../../../models';

interface Props {
	service: TestHistoryService
}

export function Subject({service}: Props) {
	const subjects = useBehaviorSubject(service.subjects);
	const selectedSubject = useBehaviorSubject(service.selectedSubject);

	const onSubjectChange = (subject: SubjectType[]) => {
		service.selectedSubject.next(subject);
		service.selectedTest.next([subject[0].tests[0]]);
	};

	return <div>
		<div className={styles.settingsTitle}>Subject:</div>
		<Dropdown value={selectedSubject} setValue={onSubjectChange}
		          optionName='name'
		          className={styles.fluid} autoWidth={true}>
			{subjects.map(v => <Option key={v.subjectID} value={v}>{v.name}</Option>)}
		</Dropdown>
	</div>;
}
