import {MutableRefObject, useMemo} from 'react';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {SubjectType, TestType} from '@esgi/core/enums';
import {TestHistoryRootService} from '../../services/test-history-root-service';
import styles from './styles.module.less';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportSettings} from './components/report-settings';
import {ChartFilter} from './components/chart-filter';
import {getChartOptions} from '../../utils';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {AllStudentsReportView} from './components/all-students-report-view';

interface Props {
	hierarchy: HierarchySnapshot,
	subjectID: number,
	subjectType: SubjectType,
	testId: number,
	testType: TestType,
	studentId?: number;
	service: TestHistoryRootService;
	chartRef: React.MutableRefObject<HTMLDivElement>
	withoutSettings?: boolean;
}

export function View({service, chartRef, withoutSettings}: Props) {
	const selectedTest = useBehaviorSubject(service.testHistoryService.selectedTest);
	const reportData = useBehaviorSubject(service.testHistoryService.reportData);
	const selectedStudent = useBehaviorSubject(service.testHistoryService.selectedStudent);

	const allStudentsReport = selectedStudent.length > 0 && selectedStudent[0].studentID === -1;
	const allStudentsData = useBehaviorSubject(service.testHistoryAllStudentsService.reportData);
	const emptyReport = allStudentsReport ? allStudentsData == null : reportData?.testSessions.length === 0;

	const allClassReport = reportData?.allClassData !== undefined && reportData?.allClassData != null;

	const studentDisplayName = service.testHistoryService.getStudentDisplayName();
	const dateFrom = useBehaviorSubject(service.testHistoryService.dateFrom);
	const dateTo = useBehaviorSubject(service.testHistoryService.dateTo);
	const testResultsIncorrectVerbiage = service.testHistoryService.testResultsIncorrectVerbiage;
	const testResultsCorrectVerbiage = service.testHistoryService.testResultsCorrectVerbiage;

	const isTestHistoryReport = !emptyReport && !allStudentsReport;
	const isAllStudentsReport = !emptyReport && allStudentsReport && allStudentsData != null;

	const chartOptions = useMemo(() => getChartOptions(
		reportData, dateTo, dateFrom, selectedStudent, service.testHistoryService.testType,
		testResultsCorrectVerbiage, testResultsIncorrectVerbiage, chartRef,
	), [
		reportData, dateTo, dateFrom, selectedStudent, service.testHistoryService.testType,
		testResultsCorrectVerbiage, testResultsIncorrectVerbiage, chartRef,
	]);

	return <div className={styles.testHistory}>
		{!withoutSettings && <ReportSettings service={service.testHistoryService}/>}
		<div className={styles.mainContainer}>
			{isTestHistoryReport &&
				<>
					<ChartFilter service={service}/>
					<div className={styles.chart} ref={chartRef}>
						<HighchartsReact options={chartOptions} immutable={true}/>
					</div>
				</>
			}
			{isAllStudentsReport &&
				<AllStudentsReportView
					service={service.testHistoryService}
					reportData={allStudentsData}
				/>
			}
			{emptyReport &&
				<div className={styles.emptyReport}>
					<span>{studentDisplayName}</span> has not been tested
					on <span>{selectedTest[0]?.name}</span>. Please choose a different test.
				</div>
			}
			<div
				id='hidden-students-graph-container'
				className={styles.hiddenChart}
			/>
		</div>
	</div>;
}

