import {Editor, Transforms, Element as SlateElement} from 'slate';
import {isBlockActive} from './is-block-active';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
	);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: n =>
			!Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      // @ts-ignore: Type '{ type: any; }' is not assignable to type 'Partial<BaseElement>'. See: https://github.com/ianstormtaylor/slate/issues/5612
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
		split: true,
	});
	let newProperties: Partial<SlateElement>;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			// @ts-ignore: Type '{ type: any; }' is not assignable to type 'Partial<BaseElement>'. See: https://github.com/ianstormtaylor/slate/issues/5612
			align: isActive ? undefined : format,
		};
	} else {
		newProperties = {
			// @ts-ignore: Type '{ type: any; }' is not assignable to type 'Partial<BaseElement>'. See: https://github.com/ianstormtaylor/slate/issues/5612
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		};
	}
	Transforms.setNodes<SlateElement>(editor, newProperties);

	if (!isActive && isList) {
		const block = {type: format, children: []};
		Transforms.wrapNodes(editor, block);
	}
};
