import styles from './styles.module.less';
import {uniqueId} from 'underscore';

const rows = new Array(7).fill(0).map(() => uniqueId());
const columns = new Array(5).fill(0).map(() => uniqueId());
const titles = ['Name', 'Grade', 'Gender', 'ID', 'Language'];


export function RosterTable() {
	return <div data-cy='roster-table' className={styles.table}>
		<table>
			<thead>
			<tr className={styles.row}>
				{titles.map((title) => <td key={title} className={styles.title}>{title}</td>)}
			</tr>
			</thead>

			<tbody className={styles.tableContainer}>
			{rows.map((row) => <tr key={row} className={styles.row}>{columns.map((column) =>
				<td key={column} className={styles.cellContainer}>
					<div className={styles.cell}/>
				</td>)}
			</tr>)}
			</tbody>
		</table>
	</div>;
}
