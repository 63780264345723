import {BehaviorSubject, from, Observable, switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import moment from 'moment/moment';
import {BaseService} from '@esgi/core/service';
import {
	DistrictCommonData,
	DistrictTabFields,
	SaveResult,
	CustomDateRange, PublishToSpecialists, DistrictSaveResponse,
	DistrictTab} from 'modules/subject-details/types';
import {SessionType, SubjectPublishType} from '@esgi/core/enums';
import {subjectNameValidator} from 'modules/subject-details/forms/subject-name-validator';
import {createDistrictForm} from 'modules/subject-details/forms/district';
import {ElementStatus} from '@esgillc/ui-kit/form';
import PublishToTeachers = DistrictTab.PublishToTeachers;

export class DistrictDetailsService extends BaseService {

	public commonData = new BehaviorSubject<DistrictCommonData>(null);
	public gradeLevelsIDs = new BehaviorSubject<number[]>([]);
	public form = createDistrictForm();

	private subjectID: number;
	private controller = 'subjects/district';

	public init(subjectID: number) {
		this.subjectID = subjectID;

		this.form.controls.subjectName.validators
			.push((validationChain) => subjectNameValidator(this.httpClient, subjectID, validationChain));

		if (!this.subjectID) {
			return this.initCreateDistrictSubject();
		} else {
			return this.initEditDistrictSubject(subjectID);
		}
	}

	public save = (): Observable<SaveResult> => {
		return this.form.validate().pipe(switchMap(res => {
			if (res.valid) {
				const {
					subjectName,
					publishToSpecialists,
					customDateRange,
					publishType,
					track,
					schools,
					isd,
					iss,
					publishToTeacher,
					forceTest,
					trackDates,
					sessionType,
					gradeLevels,
					shuffleQuestions,
				} = this.form.value;

				let publishFromDate = null;
				let publishToDate = null;

				if (publishType === SubjectPublishType.MarkingPeriod) {
					let selectedTrackDates = this.commonData.value.trackDates.filter(td => trackDates.indexOf(td.trackDateID) > -1);
					selectedTrackDates = selectedTrackDates.sort((a, b) => moment(a.dateTo).unix() - moment(b.dateFrom).unix());
					publishFromDate = selectedTrackDates[0].dateFrom;
					publishToDate = selectedTrackDates[selectedTrackDates.length - 1].dateTo;
				}

				if (publishType === SubjectPublishType.CustomDateRange) {
					publishFromDate = moment(customDateRange.from).format();
					publishToDate = moment(customDateRange.to).format();
				}

				const model = new DistrictSaveResponse(
					this.subjectID,
					gradeLevels.map(v => v.gradeLevelID),
					forceTest ? sessionType : null,
					publishType,
					track[0],
					publishFromDate ? publishFromDate : null,
					publishToDate ? publishToDate : null,
					shuffleQuestions,
					subjectName,
					publishToTeacher,
					publishToSpecialists,
					isd,
					iss,
					schools,
				);

				if (!this.subjectID) {
					return this.createDistrictTab(model);
				} else {
					return this.updateDistrictTab(model);
				}
			}
			return from([]);
		}));
	};

	private initCreateDistrictSubject() {
		return this.httpClient.ESGIApi.get<DistrictCommonData>(this.controller, 'init').pipe(tap(response => {
			this.gradeLevelsIDs.next(response.gradeLevels.map(v => v.gradeLevelID));
			this.commonData.next(response);
		}));
	}

	private initEditDistrictSubject(subjectID: number) {
		return this.httpClient.ESGIApi.get<{ commonModel: DistrictCommonData, editModel: DistrictTabFields }>(this.controller, 'initEdit', {subjectID: subjectID})
			.pipe(tap(response => {

				if(response) {
					this.form.controls.subjectName.status = ElementStatus.valid;
				} else {
					this.form.controls.subjectName.status = ElementStatus.untouched;
				}

				const {commonModel, editModel} = response;

				this.commonData.next(commonModel);
				this.gradeLevelsIDs.next(commonModel.gradeLevels.map(v => v.gradeLevelID));

				const gradeLevels = commonModel.gradeLevels.filter(gl => editModel.selectedGradeLevels.indexOf(gl.gradeLevelID) > -1) || [];
				const publishToTeacher: PublishToTeachers = editModel ? PublishToTeachers[editModel.publishToTeachers] : PublishToTeachers.All;
				const publishToSpecialists: PublishToSpecialists = editModel ? PublishToSpecialists[editModel.publishToSpecialists] : PublishToSpecialists.All;
				const publishType: SubjectPublishType = editModel.publishType ? SubjectPublishType[editModel.publishType] : SubjectPublishType.Indefinitely;
				const sessionType: SessionType = editModel && editModel.forceTestType ? SessionType[editModel.forceTestType] : SessionType.TestIncorrect;
				const track = editModel?.publishTrackID ? [editModel.publishTrackID] : [];

				const customDateRange: CustomDateRange = editModel ? {
					from: moment(editModel.publishFromDate).format('L'),
					to: moment(editModel.publishToDate).format('L'),
				} : {
					from: moment(new Date()).format('L'),
					to: moment(new Date()).add(1, 'days').format('L'),
				};

				this.form.value = {
					subjectName: editModel.name ? editModel.name : '',
					gradeLevels,
					publishToTeacher,
					schools: editModel.selectedSchools || [],
					publishToSpecialists,
					isd: editModel.selectedDistrictSpecialists || [],
					iss: editModel.selectedSchoolSpecialists || [],
					publishType,
					customDateRange,
					track,
					trackDates: editModel ? editModel.selectedTrackDates : [],
					forceTest: editModel ? !!editModel.forceTestType : false,
					sessionType,
					shuffleQuestions: editModel ? editModel.shuffleQuestions : false,
				};

				return response;
			}));
	}

	private createDistrictTab(model: DistrictSaveResponse) {
		return this.httpClient.ESGIApi.post<SaveResult>(this.controller, 'create', model);
	}

	private updateDistrictTab(model: DistrictSaveResponse) {
		return this.httpClient.ESGIApi.post<SaveResult>(this.controller, 'update', model);
	}
}
