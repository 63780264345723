import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {CSS} from '@stitches/react';
import {forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {baseButtonStyles} from '../../../buttons';

type ContextMenuTriggerProps = DropdownMenu.DropdownMenuTriggerProps & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const ContextMenuTrigger = forwardRef<HTMLButtonElement, ContextMenuTriggerProps>(
	({dataCy, asChild = true, ...props}, forwaredRef) => {
		return <Trigger data-cy={dataCy ?? 'ui-kit-context-menu-trigger'} ref={forwaredRef} asChild={asChild} {...props} />;
	},
);

const Trigger = styled(DropdownMenu.Trigger, baseButtonStyles);
