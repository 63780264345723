import React from 'react';
import {ArrowIcon} from './arrow-icon';
import {MatrixContext} from './matrix-context';
import styles from './matrix.module.less';
import {scrollXRight} from './scrolling';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
	columnWidth: number;
	nextColumnRef?: React.MutableRefObject<ArrowIcon>;
}

export class NextColumnButton extends React.PureComponent<Props> {
	public render() {
		return <MatrixContext.Consumer>
			{(context) => <div
				className={join(
					styles.nextColumn,
					this.props.className,
					!context.scrollActionState?.canNextColumn && styles.hidden,
				)}>
				<ArrowIcon ref={this.props.nextColumnRef} className={styles.arrowIcon} onClick={() => scrollXRight(context.osInstance, this.props.columnWidth)}/>
			</div>}
		</MatrixContext.Consumer>;
	}
}
