import {BaseService} from '@esgi/core/service';
import {EventBusManager} from '@esgillc/events';
import {ObservableBuilder} from '@esgi/api';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {FormGroup} from '@esgillc/ui-kit/form';
import {MutableRefObject} from 'react';
import {ProfileInitData, StudentProfileTab, TabsApi} from 'modules/forms/students-form/types';
import {tabsApiInitial} from 'modules/forms/students-form/components/profile-modal/use-tabs-api';
import {deepCopy} from 'shared/utils';

export class BaseTabService extends BaseService {
	public initData = new BehaviorSubject<ProfileInitData>(null);
	public form: FormGroup<any, any>;
	public serviceLoading = new BehaviorSubject<boolean>(false);
	public tabsStatus = new BehaviorSubject<Record<StudentProfileTab, { isTouched: boolean }>>(deepCopy(tabsApiInitial));

	protected studentID: number;
	protected eventBus = new EventBusManager();
	protected tab: StudentProfileTab;
	protected controller = 'modules/forms/student';
	protected tabsApi: MutableRefObject<TabsApi>;
	protected save: (...args: any) => ObservableBuilder<any> | Observable<any>;


	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	protected initTabsApi = <SaveArgs extends []>(tabsApi: MutableRefObject<TabsApi>) => {
		this.tabsApi = tabsApi;
		this.tabsStatus.next(tabsApi.current);
		tabsApi.current[this.tab].form = this.form;
		const save = this.save;
		this.save = (...args: SaveArgs) => {
			this.serviceLoading.next(true);
			return (save(...args) as ObservableBuilder<any>).pipe((tap)({
				next: () => {
					this.tabsApi.current[this.tab].isTouched = false;
					this.tabsApi.current[this.tab].isSaved = true;
					this.tabsStatus.next({...this.tabsStatus.value, [this.tab]: {
						isTouched: false,
						isSaved: true,
					}});
				}, complete: () => {
					this.serviceLoading.next(false);
				},
			}));
		};
		this.tabsApi.current[this.tab].save = this.save;
		this.form.onChanged.subscribe((r) => {
			if (r.reason === 'value') {
				this.tabsApi.current[this.tab] = {
					...this.tabsApi.current[this.tab],
					isTouched: true,
					isSaved: false,
				};
				this.tabsStatus.next({...this.tabsStatus.value, [this.tab]: {
					isTouched: true,
					isSaved: false,
				}});

			}
		});
	};
}
