import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';

export interface InitModel {
	schoolYears: YearModel[];
	currentGlobalSchoolYearID: number;
}

export interface YearModel {
	globalSchoolYearID: number;
	name: string;
}

export class SchoolYearsService extends BaseService {
	public readonly controller = 'home/school-year-selector';

	public readonly schoolYears$: Observable<YearModel[]>;
	public readonly currentSchoolYearID$: Observable<number>;

	public readonly selectedSchoolYearID$ = new BehaviorSubject<number>(userStorage.get().globalSchoolYearID);


	constructor() {
		super();
		const request$ = this.httpClient.ESGIApi.get<InitModel>(
			this.controller, 'init',
		).pipe(shareReplay()).asObservable();
		this.schoolYears$ = request$.pipe(map(r => r.schoolYears));
		this.currentSchoolYearID$ = request$.pipe(map(r => r.currentGlobalSchoolYearID));
	}

	public destroy() {
		super.destroy();
		this.selectedSchoolYearID$.complete();
	}
}
