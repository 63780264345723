import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';

interface Props {
	onClicked: () => void;
	onDownloadClicked: () => void;
	errors: string;
}

export function MissingRequiredInformation({onClicked, onDownloadClicked, errors}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>Your File is Missing Required Information</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<span data-cy={'missing-required-information'} className={styles.text}>
				Please make sure to include Students' First and Last Name and Grade Level, or use
				<Buttons.Link onClick={onDownloadClicked}>our template</Buttons.Link>
			</span>
			{errors &&
				<div data-cy={'errors'}>
					<p className={styles.paragraph}>Errors:</p>
					<p className={styles.paragraph}>{errors}</p>
				</div>
			}
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}