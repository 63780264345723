import {useEffect, useState, useRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {IEPGoal} from './types';
import {nl2br} from './utils';
import styles from './styles.module.less';

interface Props {
	goal: IEPGoal;
}

export const SummaryIEP = (props: Props): JSX.Element => {
	const {goal, status, benchmark} = props.goal;
	const [collapsed, setCollapsed] = useState(false);
	const collapseRef = useRef(null);

	useEffect(() => {
		const {height} = collapseRef.current.getBoundingClientRect();
		setCollapsed(height > 64);
	}, []);

	return (
		<dl className={styles.iepContainer}>
			<dt>IEP Goal</dt>
			<dd>{goal}</dd>
			<dt>Status</dt>
			<dd>{status}</dd>
			<dt>Narrative /<br/> Benchmark</dt>
			<dd>
				<div
					ref={collapseRef}
					className={collapsed ? styles.collapse : undefined}
					dangerouslySetInnerHTML={{__html: nl2br(benchmark)}}
				/>
				{collapsed &&
					<div className={styles.right}>
						<Buttons.Text
							className={styles.more}
							onClick={() => setCollapsed(!collapsed)}
						>
							view more
						</Buttons.Text>
					</div>
				}
			</dd>
		</dl>
	);
};
