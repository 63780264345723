import {IndicatorBox} from './index.styled';
import {Text} from '@esgi/ui/typography';

interface Props {
	label?: string;
	color?: string;
}

export function GradeIndicator({label = '-', color}: Props) {
	return <IndicatorBox css={{backgroundColor: color}}>
		<Text size='small' font='mono' color='vivid' bold>{label}</Text>
	</IndicatorBox>;
}
