import {PageSectionsState} from '../types';

export const defaultPageSectionState: PageSectionsState = {
	studentSection: {
		opened: true,
		classes: {
			opened: true,
			selectedIDs: [],
		},
		groups: {
			opened: false,
			selectedIDs: [],
		},
		students: {
			opened: false,
			selectedIDs: [],
		},
	},
	subjectSection: {
		opened: true,
		district: {
			opened: false,
			selectedIDs: [],
		},
		school: {
			opened: false,
			selectedIDs: [],
		},
		personal: {
			opened: false,
			selectedIDs: [],
		},
	},
};
