import React from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {ReportSettingsService} from '../../../../../../services/report-settings-service';

interface Props {
	service: ReportSettingsService;
}

export function DetailReportSettingsStudents({service}: Props) {
	const selectedStudent = useBehaviorSubject(service.selectedStudent);
	const students = useBehaviorSubject(service.students);
	return students && students.length > 0 && selectedStudent && <>
		<div>
			<strong>Student: </strong>
			<Dropdown autoWidth={true} setValue={(v) => service.changeHierarchy('student', v)}
			          optionName={'fullName'}
			          value={selectedStudent}>
				{students.map(student => <Option key={student.studentID}
				                                 value={student.studentID}>{student.fullName}</Option>)}
			</Dropdown>
		</div>
	</>;
}
