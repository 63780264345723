import {Checkbox} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import React, {useMemo} from 'react';
import {ClassTeacherItem, StudentInfoTeacher, GroupTeacherItem} from '../../../../types/teacher-types';
import {
	Table,
	TableBody,
	TableColumn,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	useSortableColumns,
} from '@esgillc/ui-kit/table';
import styles from './styles.module.less';
import {CreateAssignmentTeacherService} from '../../../../services/create-assignment-teacher-service';
import {AddSpecificModalContainer} from './components/add-specific-modal-container';
import {EmptyContent} from './components/empty-content';
import {Filter} from './components/filter';
import {FiltersContainer} from './components/filters-container';

interface Props {
	service: CreateAssignmentTeacherService;
	onClose: VoidFunction;
	onSelectedStudentsChange: (students: StudentInfoTeacher[]) => void;
	prevSelectedStudents: StudentInfoTeacher[];
}

export function AddSpecificModalTeacher({
	service,
	onClose,
	onSelectedStudentsChange,
	prevSelectedStudents = [],
}: Props) {
	const [selectedStudents, setSelectedStudents] = React.useState<StudentInfoTeacher[]>(prevSelectedStudents);
	const classes = useBehaviorSubject(service.classes);
	const groups = useBehaviorSubject(service.groups);

	const students = useBehaviorSubject(service.students);
	const previouslySelectedStudents = useBehaviorSubject(service.selectedStudents);
	const previouslyNotSelectedStudents = useMemo(
		() => students.filter((s) => !previouslySelectedStudents.includes(s)),
		[students, previouslySelectedStudents],
	);

	const [selectedClass, setSelectedClass] = React.useState<ClassTeacherItem[]>([
		{name: 'All', id: 0} as ClassTeacherItem,
	]);
	const [selectedGroup, setSelectedGroup] = React.useState<GroupTeacherItem[]>([
		{name: 'All', id: 0} as GroupTeacherItem,
	]);
	const filteredStudents = useMemo(() => {
		return previouslyNotSelectedStudents.filter((s) => {
			const isClassSelected = selectedClass[0]?.name === s.class || selectedClass[0]?.name === 'All';
			const studentGroups = s.group?.split(',');
			const isGroupSelected =
				selectedGroup[0]?.name === 'All' || studentGroups?.some((g) => g === selectedGroup[0]?.name);
			return isClassSelected && isGroupSelected;
		});
	}, [selectedClass, selectedGroup, previouslyNotSelectedStudents]);

	const allSelected = selectedStudents.length === students.length;

	const toggleAllSelected = () => {
		allSelected ? setSelectedStudents([]) : setSelectedStudents(students);
	};

	const toggleStudentSelected = (student: StudentInfoTeacher) => {
		if (selectedStudents.includes(student)) {
			setSelectedStudents(selectedStudents.filter((s) => s !== student));
		} else {
			setSelectedStudents([...selectedStudents, student]);
		}
	};

	const {data, sortableColumns} = useSortableColumns({
		data: filteredStudents,
		columns: {
			name: true,
			class: true,
			group: true,
		},
		activeSortedKey: 'name',
	});

	if (!sortableColumns.name || !sortableColumns.class || !sortableColumns.group) {
		return null;
	}

	return (
		<AddSpecificModalContainer
			subTitle='Select students in your classes and groups who have not been previously selected.'
			onClose={onClose}
			onSave={() => onSelectedStudentsChange(selectedStudents)}
		>
			<>
				<FiltersContainer>
					<Filter
						items={classes}
						dropdownLabel='Class'
						onItemChange={setSelectedClass}
						optionName='name'
						selectedItems={selectedClass}
					/>
					<Filter
						items={groups}
						dropdownLabel='Group'
						onItemChange={setSelectedGroup}
						optionName='name'
						selectedItems={selectedGroup}
					/>
				</FiltersContainer>
				<Table className={styles.table}>
					<TableHeader className={styles.tableHeader} autoHeight>
						<TableHeaderColumn>
							<Checkbox checked={allSelected} onClick={toggleAllSelected} />
						</TableHeaderColumn>
						<TableHeaderColumn.Sortable {...sortableColumns.name} className={styles.columnHeader}>
							Students
						</TableHeaderColumn.Sortable>
						<TableHeaderColumn.Sortable {...sortableColumns.class} className={styles.columnHeader}>
							Class
						</TableHeaderColumn.Sortable>
						<TableHeaderColumn.Sortable {...sortableColumns.group} className={styles.columnHeader}>
							Group
						</TableHeaderColumn.Sortable>
					</TableHeader>
					<TableBody className={styles.tableBody}>
						{data.map((item) => (
							<TableRow key={item.id} className={styles.row}>
								<TableColumn className={styles.columnCheckbox}>
									<Checkbox checked={selectedStudents.includes(item)} onClick={() => toggleStudentSelected(item)} />
								</TableColumn>
								<TableColumn className={styles.column}>{item.name}</TableColumn>
								<TableColumn className={styles.column}>{item.class}</TableColumn>
								<TableColumn className={styles.column}>{item.group}</TableColumn>
							</TableRow>
						))}
						{!data.length && <EmptyContent />}
					</TableBody>
				</Table>
			</>
		</AddSpecificModalContainer>
	);
}
