import {useState} from 'react';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {Buttons} from '@esgillc/ui-kit/button';
import ModalExport from 'shared/modules/export/modal/modal-export';
import {GlobalSchoolYearModel} from '../../types';
import {SectionWrapper} from '../../../components/section-wrapper';
import {TriangleIcon} from '../../components/export/icons/arrow-icon';
import {ExportIcon} from '../../components/export/icons/export-icon';
import {OpenExportModalIcon} from '../../components/export/icons/open-export-modal-icon';
import styles from './styles.module.less';

interface Props {
	selectedGlobalSchoolYear: GlobalSchoolYearModel;
	globalSchoolYears: GlobalSchoolYearModel[];
	onSelectGlobalSchoolYear: (selectedGlobalSchoolYear: GlobalSchoolYearModel) => void;
	exportRosterClickHandler: () => void;
}

export default function ExportSection({
	selectedGlobalSchoolYear,
	globalSchoolYears,
	onSelectGlobalSchoolYear,
	exportRosterClickHandler,
}: Props) {

	const [showExportModal, setShowExportModal] = useState<boolean>(false);
	const [isShowMenu, setShowMenu] = useState<boolean>(false);

	const exportRosterHandler = (event) => {
		event.preventDefault();
		exportRosterClickHandler();
	};

	const exportTestDataClickHandler = (event) => {
		event.preventDefault();
		setShowExportModal(true);
	};

	return <SectionWrapper title={'EXPORT'} className={styles.exportSectionHeader}>

		<div data-cy={'export-section'} className={styles.exportSection}>
			<div data-cy='export-test-data' className={styles.exportTestData}>
				<Buttons.Link
					onClick={exportTestDataClickHandler}
					className={styles.exportBtn}>
					<OpenExportModalIcon className={styles.exportIcon}/>
					Export Test Data
				</Buttons.Link>
			</div>

			<div data-cy='school-years' className={styles.schoolYears}>
				<div data-cy={'menu'} className={styles.menuButton} onClick={() => setShowMenu(prev => !prev)}>
					<div className={styles.menuHeader}>
						<div className={styles.menuButtonName}>{selectedGlobalSchoolYear.name}</div>
						<TriangleIcon className={isShowMenu ? styles.iconUp : styles.iconDown}/>
					</div>
					<Menu show={isShowMenu} onClickOutside={() => setShowMenu(false)} className={styles.menu}>
						{globalSchoolYears.map(item =>
							<MenuItem
								key={item.globalSchoolYearID}
								onSelect={() => onSelectGlobalSchoolYear(item)}
							>{item.name}</MenuItem>)}
					</Menu>
				</div>

				<p className={styles.line}>{'|'}</p>

				<Buttons.Link
					onClick={exportRosterHandler}
					className={styles.exportBtn}>
					<ExportIcon/>
					Export Roster for School Year
				</Buttons.Link>
			</div>
		</div>
		{showExportModal &&
			<div data-cy='modal-export'>
				<ModalExport close={() => setShowExportModal(false)}/>
			</div>}
	</SectionWrapper>;
}
