import {useCallback, useEffect, useMemo, useState} from 'react';
import {useValue} from '../../hooks';
import {Test, Sort} from './types';

type ToggleFunction = (ids: Test['id'][]) => void;

const sortOrder: Sort[] = [
	{field: 'name', direction: 'desc'},
	{field: 'name', direction: 'asc'},
	{field: 'default', direction: 'desc'},
];

export function useTestsControl(
	tests: Test[] | null,
	onChange: (value: Test['id'][]) => void
) {
	const [sortIndex, setSortIndex] = useState(2);
	const [selected, setSelected] = useValue<Test['id'][]>([], onChange);
	const sort = useMemo<Sort>(() => sortOrder[sortIndex], [sortIndex]);
	const isSelectedAll = useMemo(
		() => tests?.length && tests.every(({id}) => selected.includes(id)),
		[selected, tests],
	);
	const items = useMemo(
		() => {
			if (tests === null) {
				return [];
			}
			const testsClone = JSON.parse(JSON.stringify(tests));

			if (sort.field === 'name') {
				return testsClone.sort((left, right) => {
					if (sort.direction === 'asc') {
						return left.name.localeCompare(right.name);
					}
					return right.name.localeCompare(left.name);
				});
			}

			return testsClone;
		},
		[sort, tests],
	);

	const toggleSelected = useCallback<ToggleFunction>((ids) => {
		const copied = [...selected];
		ids.forEach((id) => {
			const index = copied.findIndex((testId) => id === testId);
			if (index !== -1) {
				copied.splice(index, 1);
			} else {
				copied.push(id);
			}
		});
		setSelected(copied);
	}, [selected]);

	const onSelectAll = useCallback(() => {
		if (isSelectedAll) {
			setSelected([]);
		} else {
			setSelected(tests.map(({id}) => id)
			);
		}
	}, [tests, isSelectedAll]);

	const onSort = useCallback(() => {
		let next = sortIndex + 1;
		if (next > 2) {
			next = 0;
		}
		setSortIndex(next);
	}, [sortIndex]);

	useEffect(() => {
		setSelected(tests.filter(({isTested}) => isTested).map(({id}) => id));
	}, [tests]);

	return {
		items,
		selected,
		setSelected,
		toggleSelected,
		sort,
		onSort,
		onSelectAll,
	};
}
