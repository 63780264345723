import {styled} from '@esgi/ui/theme';
import {Overlay, Content} from '@radix-ui/react-alert-dialog';
import {animated} from 'react-spring';

export const AlertOverlay = styled(Overlay, {
	position: 'fixed',
	inset: 0,
	backgroundColor: '$mild',
	opacity: 0.8,
});

export const AlertContentStyled = styled(Content, {
	display: 'grid',
	gap: '$4',
	paddingBottom: 20,
	borderRadius: 12,
	backgroundColor: '$vivid',
	boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	borderStyle: 'solid',
	borderWidth: 1,
	minWidth: 508,
	boxSizing: 'border-box',
	overflow: 'hidden',

	'&:focus': {outline: 'none'},

	variants: {
		contentWithHeader: {
			false: {
				paddingTop: 14,
			},
		},
	},
});

export const AnimatedDiv = styled(animated.div);
