import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const Layout = styled(FlexBox, {
	justifyContent: 'space-between',
	width: '100%',
	borderBottom: '1px solid $mild',
	padding: '20px',
});

export const Divider = styled(Box, {
	height: 20,
	width: 1.5,
	borderRadius: 1,
	background: '$vivid',
});
