import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {BehaviorSubject, Observable, combineLatest, debounceTime, switchMap, of, tap} from 'rxjs';
import {FontType, FontFamily, FileTypeType, UnitDataSettings, PrintSettings, StudentWithUnit, Test, Question, QuestionWithTest, DownloadPDFRequest} from '../types';
import {GenerateService} from './generate-service';
import {DownloadService} from './download-service';
import {StudentService} from './student-service';
import {SubjectService} from './subject-service';
import {V2ActivitiesSightWordsController} from '@esgi/contracts/esgi';
import {Request} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/modules/activities/sight-words/request';

const wordsPerPage = {
	'40pt': 11,
	'62pt': 7,
};

export class SightWordsService extends BaseService {
	public readonly students$ = new BehaviorSubject<StudentWithUnit[]>([]);
	public readonly subject$ = new BehaviorSubject(0);
	public readonly questions$ = new BehaviorSubject<Question['questionID'][]>([]);
	public readonly font$ = new BehaviorSubject<FontType>(null);
	public readonly copies$ = new BehaviorSubject<string>(null);
	public readonly fileType$ = new BehaviorSubject<FileTypeType>(null);
	public readonly report$ = new BehaviorSubject<Omit<DownloadPDFRequest, 'documentDate'>>(null);
	public readonly unitDataSettings$ = new BehaviorSubject<UnitDataSettings>(null);
	public readonly printSettings$ = new BehaviorSubject<PrintSettings>(null);
	public readonly tests$ = new BehaviorSubject<Test[]>([]);
	public readonly loaded$ = new BehaviorSubject(false);
	public readonly totalPages$: Observable<number> = this.completeOnDestroy(
		combineLatest([this.copies$, this.students$, this.font$, this.questions$])
	).pipe(
		debounceTime(100),
		switchMap(([copies, students, font, questions]) => {
			if (isNaN(+copies) || !questions.length) {
				return of(0);
			}

			const questionsByTest = this.tests$.value.map(({questions: list}) => {
				return list.filter(({questionID}) => questions.includes(questionID));
			});

			const totalPages = questionsByTest.reduce((result, testQuestions) => {
				const wordsCount = testQuestions.length;
				const words = wordsPerPage[font.fontSize] || 14;
				const pagesPerStudent = Math.ceil(wordsCount / words);
				return result + (pagesPerStudent * students.length * +copies);
			}, 0);

			return of(totalPages);
		}),
	);
	public readonly valid$: Observable<boolean> = this.completeOnDestroy(
		combineLatest([this.students$, this.questions$])
	).pipe(
		debounceTime(100),
		switchMap(([students, questions]) => {
			return of(Boolean(students.length) && Boolean(questions.length));
		}),
	);
	public readonly busy$ = new BehaviorSubject<boolean>(false);
	public studentService = new StudentService();
	public subjectService = new SubjectService();
	protected generateService = new GenerateService();
	protected downloadService = new DownloadService();
	protected currentUser = getUser();
	private readonly controller = new V2ActivitiesSightWordsController();

	constructor() {
		super();

		this.completeOnDestroy(
			combineLatest([this.copies$, this.font$])
		).pipe(
			debounceTime(100),
			tap(([copies, font]) => {
				if (copies === null || font === null) {
					return;
				}
				this.printSettings$.next({
					selectedFontSize: font.fontSize,
					copiesCount: +copies,
					wordsPerPage: wordsPerPage[font.fontSize] || 14,
					columnsStyle: [FontFamily.Lined, FontFamily.DottedLined, FontFamily.Blank],
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.students$, this.questions$, this.tests$])
		).pipe(
			debounceTime(100),
			tap(([students, questions, tests]) => {
				const allQuestions: QuestionWithTest[] = tests.reduce((result, test) => [
					...result,
					...test.questions.map((question): QuestionWithTest => ({
						...question,
						testName: test.name,
					})),
				], []);
				this.unitDataSettings$.next({
					students: students.map(({studentId, unitData}) => {
						const student = this.studentService.students$.value.find(
							({id}) => id === studentId,
						);
						return {...student, unitData};
					}),
					questions: allQuestions.filter(({questionID}) =>
						questions.includes(questionID)
					),
					lecturer: {
						firstName: this.currentUser.firstName,
						lastName: this.currentUser.lastName,
						title: '',
					},
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.subject$])
		).pipe(
			debounceTime(100),
			tap(([subjectID]) => {
				if (subjectID) {
					const {type: subjectType} = this.subjectService.get(subjectID);
					this.getTests({subjectID, subjectType});
				}
			}),
		).subscribe();

		this.completeOnDestroy(
			this.subjectService.loaded$
		).pipe(tap((value) => {
			if (value) {
				this.subject$.next(this.subjectService.getByIndex(0)?.id || 0);
			}
		})).subscribe();

		this.completeOnDestroy(
			combineLatest([this.downloadService.busy])
		).pipe(
			debounceTime(100),
			tap(([busy]) => this.busy$.next(busy)),
		).subscribe();
	}

	public destroy() {
		super.destroy();
		this.generateService.destroy();
		this.downloadService.destroy();
		this.studentService.destroy();
	}

	public override dispose() {
		this.controller.dispose();
	}

	public setStudents(value: StudentWithUnit[]) {
		this.students$.next(value);
	}

	public setSubject(value: number) {
		this.subject$.next(value);
	}

	public setFont(value: FontType) {
		this.font$.next(value);
	}

	public setCopies(value: string) {
		this.copies$.next(value);
	}

	public setFileType(value: FileTypeType) {
		this.fileType$.next(value);
	}

	public setQuestions(value: Question['questionID'][]) {
		this.questions$.next(value);
	}

	public onPreview() {
		this.generateReport();
	}

	public onDownload() {
		const report = this.generateReport();
		this.downloadService.downloadPdf(report);
	}

	private generateReport() {
		const report = this.generateService.generateReport(
			this.unitDataSettings$.value,
			this.printSettings$.value
		);
		this.report$.next(report);
		return report;
	}

	private getTests(request: Request) {
		this.loaded$.next(false);
		this.controller.tests(request).subscribe({
			next: ({value}) => this.tests$.next(value.tests),
			complete: () => this.loaded$.next(true),
		});
	}
}
