import './styles.less';
import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

export class Props {
	gradeLevels: number[];
}

export class GradeLevelIcon extends React.PureComponent<Props> {
	render() {

		if (!this.props.gradeLevels.length) {
			return null;
		}

		let ordered = this.props.gradeLevels.sort((a, b) => {
			return a - b;
		});

		if (!ordered.length) {
			return null;
		}

		if (ordered.length < 2) {
			return this.renderMultiple(ordered);
		} else {
			return this.renderCombined(ordered);
		}
	}

	private renderCombined(gradeLevels: number[]) {

		let lowest = gradeLevels[0];
		if (!lowest) {
			return null;
		}

		let lowestIcon = this.icons[lowest];
		if(!lowestIcon) {
			return null;
		}

		let highest = gradeLevels[gradeLevels.length - 1];
		let highestIcon = lowestIcon.icons[highest];

		if(!highestIcon) {
			return null;
		}

		return <div className='gl-icon-set'>
            {highestIcon.render()}
        </div>;

	}

	private renderMultiple(gls: number[]) {
		return <div className='gl-icon-set'>
            {gls.map(g => {
                return this.icons[g].render();
            })}
        </div>;
	}

	icons: { [levelId: number]: GradeLevelModel } = {
		1: {
			render: this.renderPk,
			icons: {
				8: {render: this.renderPkOne},
				16: {render: this.renderPkTwo},
				32: {render: this.renderPkThree},
				2: {render: this.renderPkTK},
				4: {render: this.renderPkK},
			},
		},
		2: {
			render: this.renderTk,
			icons: {
				16: {render: this.renderTkTwo},
				32: {render: this.renderTkThree},
				4: {render: this.renderTkK},
				8: {render: this.renderTkOne},
			},
		},
		4: {
			render: this.renderK,
			icons: {8: {render: this.renderKOne}, 16: {render: this.renderKTwo}, 32: {render: this.renderK3Plus}},
		},
		8: {render: this.render1, icons: {16: {render: this.renderOneTwo}, 32: {render: this.renderOneThree}}},
		16: {render: this.render2, icons: {32: {render: this.renderTwoThree}}},
		32: {render: this.render3, icons: {}},
	};

	private renderTkThree() {
    	return <OnHoverTooltip key='tk3' message='Transitional Kindergarten | 3rd Grade+'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
                <path
                    d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z'
                    fill='#D99F28'/>
                <path
                    d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z'
                    fill='white'/>
                <path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
                <path
                    d='M12.6309 4.9043H10.5215V12H8.79297V4.9043H6.71875V3.46875H12.6309V4.9043ZM16.0234 8.70703L15.3438 9.5625V12H13.6211V3.46875H15.3438V7.18945L15.8887 6.26953L17.4766 3.46875H19.5859L17.1426 7.21875L19.627 12H17.582L16.0234 8.70703Z'
                    fill='white'/>
                <path
                    d='M9.74453 18.9785H10.5473C10.852 18.9785 11.0746 18.873 11.2152 18.6621C11.3598 18.4473 11.432 18.1621 11.432 17.8066C11.432 17.4668 11.3578 17.2031 11.2094 17.0156C11.0648 16.8242 10.8695 16.7285 10.6234 16.7285C10.393 16.7285 10.2035 16.8184 10.0551 16.998C9.91055 17.1777 9.83828 17.4043 9.83828 17.6777H8.18008C8.18008 17.2324 8.28164 16.834 8.48477 16.4824C8.6918 16.127 8.97891 15.8496 9.34609 15.6504C9.71719 15.4512 10.1273 15.3516 10.5766 15.3516C11.3617 15.3516 11.977 15.5684 12.4223 16.002C12.8676 16.4355 13.0902 17.0293 13.0902 17.7832C13.0902 18.1699 12.9887 18.5293 12.7855 18.8613C12.5863 19.1895 12.3227 19.4414 11.9945 19.6172C12.3969 19.7891 12.6957 20.0469 12.891 20.3906C13.0902 20.7344 13.1898 21.1406 13.1898 21.6094C13.1898 22.3672 12.9477 22.9746 12.4633 23.4316C11.9828 23.8887 11.35 24.1172 10.5648 24.1172C9.83437 24.1172 9.24062 23.8926 8.78359 23.4434C8.33047 22.9902 8.10391 22.3965 8.10391 21.6621H9.76211C9.76211 21.9668 9.84219 22.2227 10.0023 22.4297C10.1625 22.6367 10.3656 22.7402 10.6117 22.7402C10.8969 22.7402 11.1215 22.6348 11.2855 22.4238C11.4535 22.2129 11.5375 21.9316 11.5375 21.5801C11.5375 20.7402 11.2113 20.3184 10.559 20.3145H9.74453V18.9785ZM16.2145 19.2188H17.8727V20.7598H16.2145V23.1445H14.6441V20.7598H12.9566V19.2188H14.6441V16.9336H16.2145V19.2188Z'
                    fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderTkTwo() {
    	return <OnHoverTooltip key='tk2' message='Transitional Kindergarten | 2nd Grade'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
                <path
                    d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z'
                    fill='#059BE0'/>
                <path
                    d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z'
                    fill='white'/>
                <path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
                <path
                    d='M12.6309 4.9043H10.5215V12H8.79297V4.9043H6.71875V3.46875H12.6309V4.9043ZM16.0234 8.70703L15.3438 9.5625V12H13.6211V3.46875H15.3438V7.18945L15.8887 6.26953L17.4766 3.46875H19.5859L17.1426 7.21875L19.627 12H17.582L16.0234 8.70703Z'
                    fill='white'/>
                <path
                    d='M15.5488 24H10.5215V22.8281L12.8301 19.8926C13.123 19.4863 13.3379 19.1309 13.4746 18.8262C13.6152 18.5176 13.6855 18.2227 13.6855 17.9414C13.6855 17.5625 13.6191 17.2656 13.4863 17.0508C13.3574 16.8359 13.1699 16.7285 12.9238 16.7285C12.6543 16.7285 12.4395 16.8535 12.2793 17.1035C12.123 17.3535 12.0449 17.7051 12.0449 18.1582H10.3809C10.3809 17.6348 10.4883 17.1562 10.7031 16.7227C10.9219 16.2891 11.2266 15.9531 11.6172 15.7148C12.0117 15.4727 12.457 15.3516 12.9531 15.3516C13.7148 15.3516 14.3047 15.5625 14.7227 15.9844C15.1445 16.4023 15.3555 16.998 15.3555 17.7715C15.3555 18.252 15.2383 18.7402 15.0039 19.2363C14.7734 19.7285 14.3535 20.3359 13.7441 21.0586L12.6484 22.623H15.5488V24Z'
                    fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderPkThree() {
    	return <OnHoverTooltip key='pk3' message='Pre-Kindergarten | 3rd Grade+'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
                <path
                    d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z'
                    fill='#D99F28'/>
                <path
                    d='M8.01562 13.0259H3.09033V12H8.01562V13.0259ZM12.9785 13.0259H8.05322V12H12.9785V13.0259ZM17.9414 13.0259H13.0161V12H17.9414V13.0259ZM22.9043 13.0259H17.979V12H22.9043V13.0259Z'
                    fill='white'/>
                <path d='M3.07422 13.0742H22.9258V13.6113H3.07422V13.0742Z' fill='white'/>
                <path
                    d='M8.66992 8V11H6.94727V2.46875H9.85352C10.6973 2.46875 11.3691 2.73047 11.8691 3.25391C12.373 3.77734 12.625 4.45703 12.625 5.29297C12.625 6.12891 12.377 6.78906 11.8809 7.27344C11.3848 7.75781 10.6973 8 9.81836 8H8.66992ZM8.66992 6.56445H9.85352C10.1816 6.56445 10.4355 6.45703 10.6152 6.24219C10.7949 6.02734 10.8848 5.71484 10.8848 5.30469C10.8848 4.87891 10.793 4.54102 10.6094 4.29102C10.4258 4.03711 10.1797 3.9082 9.87109 3.9043H8.66992V6.56445ZM16.1816 7.70703L15.502 8.5625V11H13.7793V2.46875H15.502V6.18945L16.0469 5.26953L17.6348 2.46875H19.7441L17.3008 6.21875L19.7852 11H17.7402L16.1816 7.70703Z'
                    fill='white'/>
                <path
                    d='M9.14453 17.9785H9.94727C10.252 17.9785 10.4746 17.873 10.6152 17.6621C10.7598 17.4473 10.832 17.1621 10.832 16.8066C10.832 16.4668 10.7578 16.2031 10.6094 16.0156C10.4648 15.8242 10.2695 15.7285 10.0234 15.7285C9.79297 15.7285 9.60352 15.8184 9.45508 15.998C9.31055 16.1777 9.23828 16.4043 9.23828 16.6777H7.58008C7.58008 16.2324 7.68164 15.834 7.88477 15.4824C8.0918 15.127 8.37891 14.8496 8.74609 14.6504C9.11719 14.4512 9.52734 14.3516 9.97656 14.3516C10.7617 14.3516 11.377 14.5684 11.8223 15.002C12.2676 15.4355 12.4902 16.0293 12.4902 16.7832C12.4902 17.1699 12.3887 17.5293 12.1855 17.8613C11.9863 18.1895 11.7227 18.4414 11.3945 18.6172C11.7969 18.7891 12.0957 19.0469 12.291 19.3906C12.4902 19.7344 12.5898 20.1406 12.5898 20.6094C12.5898 21.3672 12.3477 21.9746 11.8633 22.4316C11.3828 22.8887 10.75 23.1172 9.96484 23.1172C9.23438 23.1172 8.64062 22.8926 8.18359 22.4434C7.73047 21.9902 7.50391 21.3965 7.50391 20.6621H9.16211C9.16211 20.9668 9.24219 21.2227 9.40234 21.4297C9.5625 21.6367 9.76562 21.7402 10.0117 21.7402C10.2969 21.7402 10.5215 21.6348 10.6855 21.4238C10.8535 21.2129 10.9375 20.9316 10.9375 20.5801C10.9375 19.7402 10.6113 19.3184 9.95898 19.3145H9.14453V17.9785ZM16.8145 18.2188H18.4727V19.7598H16.8145V22.1445H15.2441V19.7598H13.5566V18.2188H15.2441V15.9336H16.8145V18.2188Z'
                    fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderPkTwo() {
    	return <OnHoverTooltip key='pk2' message='Pre-Kindergarten | 2nd Grade'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
                <path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#059BE0'/>
                <path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
                <path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
                <path d='M8.66992 9V12H6.94727V3.46875H9.85352C10.6973 3.46875 11.3691 3.73047 11.8691 4.25391C12.373 4.77734 12.625 5.45703 12.625 6.29297C12.625 7.12891 12.377 7.78906 11.8809 8.27344C11.3848 8.75781 10.6973 9 9.81836 9H8.66992ZM8.66992 7.56445H9.85352C10.1816 7.56445 10.4355 7.45703 10.6152 7.24219C10.7949 7.02734 10.8848 6.71484 10.8848 6.30469C10.8848 5.87891 10.793 5.54102 10.6094 5.29102C10.4258 5.03711 10.1797 4.9082 9.87109 4.9043H8.66992V7.56445ZM16.1816 8.70703L15.502 9.5625V12H13.7793V3.46875H15.502V7.18945L16.0469 6.26953L17.6348 3.46875H19.7441L17.3008 7.21875L19.7852 12H17.7402L16.1816 8.70703Z' fill='white'/>
                <path d='M15.5488 24H10.5215V22.8281L12.8301 19.8926C13.123 19.4863 13.3379 19.1309 13.4746 18.8262C13.6152 18.5176 13.6855 18.2227 13.6855 17.9414C13.6855 17.5625 13.6191 17.2656 13.4863 17.0508C13.3574 16.8359 13.1699 16.7285 12.9238 16.7285C12.6543 16.7285 12.4395 16.8535 12.2793 17.1035C12.123 17.3535 12.0449 17.7051 12.0449 18.1582H10.3809C10.3809 17.6348 10.4883 17.1562 10.7031 16.7227C10.9219 16.2891 11.2266 15.9531 11.6172 15.7148C12.0117 15.4727 12.457 15.3516 12.9531 15.3516C13.7148 15.3516 14.3047 15.5625 14.7227 15.9844C15.1445 16.4023 15.3555 16.998 15.3555 17.7715C15.3555 18.252 15.2383 18.7402 15.0039 19.2363C14.7734 19.7285 14.3535 20.3359 13.7441 21.0586L12.6484 22.623H15.5488V24Z' fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderPkOne() {
    	return <OnHoverTooltip key='pk1' message='Pre-Kindergarten | 1st Grade'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
                <path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#005796'/>
                <path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
                <path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
                <path d='M8.66992 9V12H6.94727V3.46875H9.85352C10.6973 3.46875 11.3691 3.73047 11.8691 4.25391C12.373 4.77734 12.625 5.45703 12.625 6.29297C12.625 7.12891 12.377 7.78906 11.8809 8.27344C11.3848 8.75781 10.6973 9 9.81836 9H8.66992ZM8.66992 7.56445H9.85352C10.1816 7.56445 10.4355 7.45703 10.6152 7.24219C10.7949 7.02734 10.8848 6.71484 10.8848 6.30469C10.8848 5.87891 10.793 5.54102 10.6094 5.29102C10.4258 5.03711 10.1797 4.9082 9.87109 4.9043H8.66992V7.56445ZM16.1816 8.70703L15.502 9.5625V12H13.7793V3.46875H15.502V7.18945L16.0469 6.26953L17.6348 3.46875H19.7441L17.3008 7.21875L19.7852 12H17.7402L16.1816 8.70703Z' fill='white'/>
                <path d='M14.1719 24H12.5078V17.4844L10.8848 18.1113V16.7285L13.9961 15.457H14.1719V24Z' fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderK3Plus() {
    	return <OnHoverTooltip key='k3+' message='Kindergarten | 3rd Grade+'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'
                 fill='none'>
                <path
                    d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z'
                    fill='#D99F28'/>
                <path
                    d='M8.01562 13.0259H3.09033V12H8.01562V13.0259ZM12.9785 13.0259H8.05322V12H12.9785V13.0259ZM17.9414 13.0259H13.0161V12H17.9414V13.0259ZM22.9043 13.0259H17.979V12H22.9043V13.0259Z'
                    fill='white'/>
                <path d='M3.07422 13.0742H22.9258V13.6113H3.07422V13.0742Z' fill='white'/>
                <path
                    d='M12.7656 7.70703L12.0859 8.5625V11H10.3633V2.46875H12.0859V6.18945L12.6309 5.26953L14.2188 2.46875H16.3281L13.8848 6.21875L16.3691 11H14.3242L12.7656 7.70703Z'
                    fill='white'/>
                <path
                    d='M9.14453 17.9785H9.94727C10.252 17.9785 10.4746 17.873 10.6152 17.6621C10.7598 17.4473 10.832 17.1621 10.832 16.8066C10.832 16.4668 10.7578 16.2031 10.6094 16.0156C10.4648 15.8242 10.2695 15.7285 10.0234 15.7285C9.79297 15.7285 9.60352 15.8184 9.45508 15.998C9.31055 16.1777 9.23828 16.4043 9.23828 16.6777H7.58008C7.58008 16.2324 7.68164 15.834 7.88477 15.4824C8.0918 15.127 8.37891 14.8496 8.74609 14.6504C9.11719 14.4512 9.52734 14.3516 9.97656 14.3516C10.7617 14.3516 11.377 14.5684 11.8223 15.002C12.2676 15.4355 12.4902 16.0293 12.4902 16.7832C12.4902 17.1699 12.3887 17.5293 12.1855 17.8613C11.9863 18.1895 11.7227 18.4414 11.3945 18.6172C11.7969 18.7891 12.0957 19.0469 12.291 19.3906C12.4902 19.7344 12.5898 20.1406 12.5898 20.6094C12.5898 21.3672 12.3477 21.9746 11.8633 22.4316C11.3828 22.8887 10.75 23.1172 9.96484 23.1172C9.23438 23.1172 8.64062 22.8926 8.18359 22.4434C7.73047 21.9902 7.50391 21.3965 7.50391 20.6621H9.16211C9.16211 20.9668 9.24219 21.2227 9.40234 21.4297C9.5625 21.6367 9.76562 21.7402 10.0117 21.7402C10.2969 21.7402 10.5215 21.6348 10.6855 21.4238C10.8535 21.2129 10.9375 20.9316 10.9375 20.5801C10.9375 19.7402 10.6113 19.3184 9.95898 19.3145H9.14453V17.9785ZM16.8145 18.2188H18.4727V19.7598H16.8145V22.1445H15.2441V19.7598H13.5566V18.2188H15.2441V15.9336H16.8145V18.2188Z'
                    fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderK() {
    	return <OnHoverTooltip key='k' message='Kindergarten'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'
                 fill='none' key='4'>
                <circle cx='13' cy='13' r='13' fill='#27AE60'/>
                <path d='M12.7656 13.707L12.0859 14.5625V17H10.3633V8.46875H12.0859V12.1895L12.6309 11.2695L14.2188 8.46875H16.3281L13.8848 12.2188L16.3691 17H14.3242L12.7656 13.707Z' fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderTk() {
    	return <OnHoverTooltip key='tk' message='Transitional Kindergarten'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'
                 fill='none' key='2'>
                <circle cx='13' cy='13' r='13' fill='#27AE60'/>
                <path d='M12.6309 9.9043H10.5215V17H8.79297V9.9043H6.71875V8.46875H12.6309V9.9043ZM16.0234 13.707L15.3438 14.5625V17H13.6211V8.46875H15.3438V12.1895L15.8887 11.2695L17.4766 8.46875H19.5859L17.1426 12.2188L19.627 17H17.582L16.0234 13.707Z' fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderPk() {
    	return <OnHoverTooltip key='pk' message='Pre-Kindergarten'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'
                 fill='none' key='1'>
                <circle cx='13' cy='13' r='13' fill='#27AE60'/>
                <path
                    d='M8.4082 14V17H6.68555V8.46875H9.5918C10.4355 8.46875 11.1074 8.73047 11.6074 9.25391C12.1113 9.77734 12.3633 10.457 12.3633 11.293C12.3633 12.1289 12.1152 12.7891 11.6191 13.2734C11.123 13.7578 10.4355 14 9.55664 14H8.4082ZM8.4082 12.5645H9.5918C9.91992 12.5645 10.1738 12.457 10.3535 12.2422C10.5332 12.0273 10.623 11.7148 10.623 11.3047C10.623 10.8789 10.5312 10.541 10.3477 10.291C10.1641 10.0371 9.91797 9.9082 9.60938 9.9043H8.4082V12.5645ZM15.9199 13.707L15.2402 14.5625V17H13.5176V8.46875H15.2402V12.1895L15.7852 11.2695L17.373 8.46875H19.4824L17.0391 12.2188L19.5234 17H17.4785L15.9199 13.707Z'
                    fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private renderPkTK() {
		return <OnHoverTooltip key='tk' message='Pre-Kindergarten | Transitional Kindergarten'>
			<svg viewBox='0 0 26 26' fill='none' width='26' height='26' xmlns='http://www.w3.org/2000/svg'>
				<path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#27AE60'/>
				<path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
				<path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
				<path d='M8.66992 9V12H6.94727V3.46875H9.85352C10.6973 3.46875 11.3691 3.73047 11.8691 4.25391C12.373 4.77734 12.625 5.45703 12.625 6.29297C12.625 7.12891 12.377 7.78906 11.8809 8.27344C11.3848 8.75781 10.6973 9 9.81836 9H8.66992ZM8.66992 7.56445H9.85352C10.1816 7.56445 10.4355 7.45703 10.6152 7.24219C10.7949 7.02734 10.8848 6.71484 10.8848 6.30469C10.8848 5.87891 10.793 5.54102 10.6094 5.29102C10.4258 5.03711 10.1797 4.9082 9.87109 4.9043H8.66992V7.56445ZM16.1816 8.70703L15.502 9.5625V12H13.7793V3.46875H15.502V7.18945L16.0469 6.26953L17.6348 3.46875H19.7441L17.3008 7.21875L19.7852 12H17.7402L16.1816 8.70703Z' fill='white'/>
				<path d='M 12.599 16.876 L 10.489 16.876 L 10.489 23.971 L 8.761 23.971 L 8.761 16.876 L 6.686 16.876 L 6.686 15.44 L 12.599 15.44 L 12.599 16.876 Z M 15.991 20.678 L 15.312 21.534 L 15.312 23.971 L 13.589 23.971 L 13.589 15.44 L 15.312 15.44 L 15.312 19.161 L 15.856 18.241 L 17.444 15.44 L 19.554 15.44 L 17.11 19.19 L 19.595 23.971 L 17.55 23.971 L 15.991 20.678 Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderPkK() {
		return <OnHoverTooltip key='pk' message='Pre-Kindergarten | Kindergarten'>
			<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='13' cy='13' r='13' fill='#27AE60'/>
				<path d='M8.66992 9V12H6.94727V3.46875H9.85352C10.6973 3.46875 11.3691 3.73047 11.8691 4.25391C12.373 4.77734 12.625 5.45703 12.625 6.29297C12.625 7.12891 12.377 7.78906 11.8809 8.27344C11.3848 8.75781 10.6973 9 9.81836 9H8.66992ZM8.66992 7.56445H9.85352C10.1816 7.56445 10.4355 7.45703 10.6152 7.24219C10.7949 7.02734 10.8848 6.71484 10.8848 6.30469C10.8848 5.87891 10.793 5.54102 10.6094 5.29102C10.4258 5.03711 10.1797 4.9082 9.87109 4.9043H8.66992V7.56445ZM16.1816 8.70703L15.502 9.5625V12H13.7793V3.46875H15.502V7.18945L16.0469 6.26953L17.6348 3.46875H19.7441L17.3008 7.21875L19.7852 12H17.7402L16.1816 8.70703Z' fill='white'/>
				<path d='M12.7656 19.707L12.0859 20.5625V23H10.3633V14.4688H12.0859V18.1895L12.6309 17.2695L14.2188 14.4688H16.3281L13.8848 18.2188L16.3691 23H14.3242L12.7656 19.707Z' fill='white'/>
				<path d='M6.41992 13.1719H19.5742V13.7578H6.41992V13.1719Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderTkK() {
		return <OnHoverTooltip key='tkk' message='Transitional Kindergarten | Kindergarten'>
			<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='13' cy='13' r='13' fill='#27AE60'/>
				<path d='M12.6309 4.9043H10.5215V12H8.79297V4.9043H6.71875V3.46875H12.6309V4.9043ZM16.0234 8.70703L15.3438 9.5625V12H13.6211V3.46875H15.3438V7.18945L15.8887 6.26953L17.4766 3.46875H19.5859L17.1426 7.21875L19.627 12H17.582L16.0234 8.70703ZM12.7656 19.707L12.0859 20.5625V23H10.3633V14.4688H12.0859V18.1895L12.6309 17.2695L14.2188 14.4688H16.3281L13.8848 18.2188L16.3691 23H14.3242L12.7656 19.707Z' fill='white'/>
				<path d='M6.41992 13.1719H19.5742V13.7578H6.41992V13.1719Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderTkOne() {
		return <OnHoverTooltip key='tk1' message='Transitional Kindergarten | 1st Grade'>
			<svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
				<path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#005796'/>
				<path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
				<path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
				<path d='M12.6309 4.9043H10.5215V12H8.79297V4.9043H6.71875V3.46875H12.6309V4.9043ZM16.0234 8.70703L15.3438 9.5625V12H13.6211V3.46875H15.3438V7.18945L15.8887 6.26953L17.4766 3.46875H19.5859L17.1426 7.21875L19.627 12H17.582L16.0234 8.70703Z'
					fill='white'/>
				<path d='M14.1719 24H12.5078V17.4844L10.8848 18.1113V16.7285L13.9961 15.457H14.1719V24Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderKOne() {
		return <OnHoverTooltip key='k1' message='Kindergarten | 1st Grade'>
			<svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
				<path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#005796'/>
				<path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
				<path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
				<path d='M12.7656 7.70703L12.0859 8.5625V11H10.3633V2.46875H12.0859V6.18945L12.6309 5.26953L14.2188 2.46875H16.3281L13.8848 6.21875L16.3691 11H14.3242L12.7656 7.70703Z'
					fill='white'/>
				<path d='M14.1719 24H12.5078V17.4844L10.8848 18.1113V16.7285L13.9961 15.457H14.1719V24Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderKTwo() {
		return <OnHoverTooltip key='k2' message='Kindergarten | 2nd Grade'>
			<svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
				<path
					d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z'
					fill='#059BE0'/>
				<path
					d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z'
					fill='white'/>
				<path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
				<path d='M12.7656 7.70703L12.0859 8.5625V11H10.3633V2.46875H12.0859V6.18945L12.6309 5.26953L14.2188 2.46875H16.3281L13.8848 6.21875L16.3691 11H14.3242L12.7656 7.70703Z'
					  fill='white'/>
				<path
					d='M15.5488 24H10.5215V22.8281L12.8301 19.8926C13.123 19.4863 13.3379 19.1309 13.4746 18.8262C13.6152 18.5176 13.6855 18.2227 13.6855 17.9414C13.6855 17.5625 13.6191 17.2656 13.4863 17.0508C13.3574 16.8359 13.1699 16.7285 12.9238 16.7285C12.6543 16.7285 12.4395 16.8535 12.2793 17.1035C12.123 17.3535 12.0449 17.7051 12.0449 18.1582H10.3809C10.3809 17.6348 10.4883 17.1562 10.7031 16.7227C10.9219 16.2891 11.2266 15.9531 11.6172 15.7148C12.0117 15.4727 12.457 15.3516 12.9531 15.3516C13.7148 15.3516 14.3047 15.5625 14.7227 15.9844C15.1445 16.4023 15.3555 16.998 15.3555 17.7715C15.3555 18.252 15.2383 18.7402 15.0039 19.2363C14.7734 19.7285 14.3535 20.3359 13.7441 21.0586L12.6484 22.623H15.5488V24Z'
					fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderOneTwo() {
		return <OnHoverTooltip key='key12' message='1st Grade | 2nd Grade'>
			<svg viewBox='0 0 26 26' fill='none' width='26' height='26' xmlns='http://www.w3.org/2000/svg'>
				<path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#059BE0'/>
				<path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
				<path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
				<path d='M 13.881 11.899 L 12.217 11.899 L 12.217 5.384 L 10.594 6.011 L 10.594 4.628 L 13.706 3.356 L 13.881 3.356 L 13.881 11.899 Z' fill='white'/>
				<path d='M15.5488 24H10.5215V22.8281L12.8301 19.8926C13.123 19.4863 13.3379 19.1309 13.4746 18.8262C13.6152 18.5176 13.6855 18.2227 13.6855 17.9414C13.6855 17.5625 13.6191 17.2656 13.4863 17.0508C13.3574 16.8359 13.1699 16.7285 12.9238 16.7285C12.6543 16.7285 12.4395 16.8535 12.2793 17.1035C12.123 17.3535 12.0449 17.7051 12.0449 18.1582H10.3809C10.3809 17.6348 10.4883 17.1562 10.7031 16.7227C10.9219 16.2891 11.2266 15.9531 11.6172 15.7148C12.0117 15.4727 12.457 15.3516 12.9531 15.3516C13.7148 15.3516 14.3047 15.5625 14.7227 15.9844C15.1445 16.4023 15.3555 16.998 15.3555 17.7715C15.3555 18.252 15.2383 18.7402 15.0039 19.2363C14.7734 19.7285 14.3535 20.3359 13.7441 21.0586L12.6484 22.623H15.5488V24Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderOneThree() {
		return <OnHoverTooltip key='key13' message='1st Grade | 3rd Grade+'>
			<svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#D99F28'/>
				<path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
				<path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
				<path d='M 13.881 11.899 L 12.217 11.899 L 12.217 5.384 L 10.594 6.011 L 10.594 4.628 L 13.706 3.356 L 13.881 3.356 L 13.881 11.899 Z' fill='white'/>
				<path d='M 12.178 18.978 L 12.981 18.978 C 13.285 18.978 13.508 18.873 13.649 18.662 C 13.793 18.447 13.865 18.162 13.865 17.807 C 13.865 17.467 13.791 17.203 13.643 17.016 C 13.498 16.824 13.303 16.728 13.057 16.728 C 12.826 16.728 12.637 16.818 12.488 16.998 C 12.344 17.178 12.272 17.404 12.272 17.678 L 10.613 17.678 C 10.613 17.232 10.715 16.834 10.918 16.482 C 11.125 16.127 11.412 15.85 11.779 15.65 C 12.151 15.451 12.561 15.352 13.01 15.352 C 13.795 15.352 14.41 15.568 14.856 16.002 C 15.301 16.435 15.524 17.029 15.524 17.783 C 15.524 18.17 15.422 18.529 15.219 18.861 C 15.02 19.189 14.756 19.441 14.428 19.617 C 14.83 19.789 15.129 20.047 15.324 20.391 C 15.524 20.734 15.623 21.141 15.623 21.609 C 15.623 22.367 15.381 22.975 14.897 23.432 C 14.416 23.889 13.783 24.117 12.998 24.117 C 12.268 24.117 11.674 23.893 11.217 23.443 C 10.764 22.99 10.537 22.396 10.537 21.662 L 12.195 21.662 C 12.195 21.967 12.276 22.223 12.436 22.43 C 12.596 22.637 12.799 22.74 13.045 22.74 C 13.33 22.74 13.555 22.635 13.719 22.424 C 13.887 22.213 13.971 21.932 13.971 21.58 C 13.971 20.74 13.645 20.318 12.992 20.314 L 12.178 20.314 L 12.178 18.978 Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private renderTwoThree() {
		return <OnHoverTooltip key='key23' message='2nd Grade | 3rd Grade+'>
			<svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M26 13C26 20.1797 20.1797 26 13 26C9.36244 26 6.07382 24.506 3.71429 22.0981C1.41665 19.7534 0 16.5421 0 13C0 5.8203 5.8203 0 13 0C16.6376 0 19.9262 1.49401 22.2857 3.90189C24.5833 6.2466 26 9.45786 26 13Z' fill='#D99F28'/>
				<path d='M8.01562 14.0259H3.09033V13H8.01562V14.0259ZM12.9785 14.0259H8.05322V13H12.9785V14.0259ZM17.9414 14.0259H13.0161V13H17.9414V14.0259ZM22.9043 14.0259H17.979V13H22.9043V14.0259Z' fill='white'/>
				<path d='M3.07422 14.0742H22.9258V14.6113H3.07422V14.0742Z' fill='white'/>
				<path d='M 15.71 11.899 L 10.683 11.899 L 10.683 10.728 L 12.991 7.792 C 13.284 7.386 13.499 7.03 13.636 6.726 C 13.777 6.417 13.847 6.122 13.847 5.841 C 13.847 5.462 13.78 5.165 13.648 4.95 C 13.519 4.735 13.331 4.628 13.085 4.628 C 12.816 4.628 12.601 4.753 12.441 5.003 C 12.284 5.253 12.206 5.605 12.206 6.058 L 10.542 6.058 C 10.542 5.534 10.65 5.056 10.864 4.622 C 11.083 4.189 11.388 3.853 11.779 3.614 C 12.173 3.372 12.618 3.251 13.114 3.251 C 13.876 3.251 14.466 3.462 14.884 3.884 C 15.306 4.302 15.517 4.897 15.517 5.671 C 15.517 6.151 15.4 6.64 15.165 7.136 C 14.935 7.628 14.515 8.235 13.905 8.958 L 12.81 10.522 L 15.71 10.522 L 15.71 11.899 Z' fill='white'/>
				<path d='M 12.178 18.978 L 12.981 18.978 C 13.285 18.978 13.508 18.873 13.649 18.662 C 13.793 18.447 13.865 18.162 13.865 17.807 C 13.865 17.467 13.791 17.203 13.643 17.016 C 13.498 16.824 13.303 16.728 13.057 16.728 C 12.826 16.728 12.637 16.818 12.488 16.998 C 12.344 17.178 12.272 17.404 12.272 17.678 L 10.613 17.678 C 10.613 17.232 10.715 16.834 10.918 16.482 C 11.125 16.127 11.412 15.85 11.779 15.65 C 12.151 15.451 12.561 15.352 13.01 15.352 C 13.795 15.352 14.41 15.568 14.856 16.002 C 15.301 16.435 15.524 17.029 15.524 17.783 C 15.524 18.17 15.422 18.529 15.219 18.861 C 15.02 19.189 14.756 19.441 14.428 19.617 C 14.83 19.789 15.129 20.047 15.324 20.391 C 15.524 20.734 15.623 21.141 15.623 21.609 C 15.623 22.367 15.381 22.975 14.897 23.432 C 14.416 23.889 13.783 24.117 12.998 24.117 C 12.268 24.117 11.674 23.893 11.217 23.443 C 10.764 22.99 10.537 22.396 10.537 21.662 L 12.195 21.662 C 12.195 21.967 12.276 22.223 12.436 22.43 C 12.596 22.637 12.799 22.74 13.045 22.74 C 13.33 22.74 13.555 22.635 13.719 22.424 C 13.887 22.213 13.971 21.932 13.971 21.58 C 13.971 20.74 13.645 20.318 12.992 20.314 L 12.178 20.314 L 12.178 18.978 Z' fill='white'/>
			</svg>
		</OnHoverTooltip>;
	}

	private render1() {
    	return <OnHoverTooltip key='1' message='1st Grade'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'
                 fill='none' key='8'>
                <circle cx='13' cy='13' r='13' fill='#005796'/>
                <path d='M14.207 17H12.543V10.4844L10.9199 11.1113V9.72852L14.0312 8.45703H14.207V17Z'
                      fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private render2() {
    	return <OnHoverTooltip key='2' message='2nd Grade'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'
                 fill='none' key='16'>
                <circle cx='13' cy='13' r='13' fill='#059BE0'/>
                <path
                    d='M15.584 17H10.5566V15.8281L12.8652 12.8926C13.1582 12.4863 13.373 12.1309 13.5098 11.8262C13.6504 11.5176 13.7207 11.2227 13.7207 10.9414C13.7207 10.5625 13.6543 10.2656 13.5215 10.0508C13.3926 9.83594 13.2051 9.72852 12.959 9.72852C12.6895 9.72852 12.4746 9.85352 12.3145 10.1035C12.1582 10.3535 12.0801 10.7051 12.0801 11.1582H10.416C10.416 10.6348 10.5234 10.1562 10.7383 9.72266C10.957 9.28906 11.2617 8.95312 11.6523 8.71484C12.0469 8.47266 12.4922 8.35156 12.9883 8.35156C13.75 8.35156 14.3398 8.5625 14.7578 8.98438C15.1797 9.40234 15.3906 9.99805 15.3906 10.7715C15.3906 11.252 15.2734 11.7402 15.0391 12.2363C14.8086 12.7285 14.3887 13.3359 13.7793 14.0586L12.6836 15.623H15.584V17Z'
                    fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}

	private render3() {
    	return <OnHoverTooltip key='3' message='3rd Grade+'>
            <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'
                 fill='none' key='32'>
                <circle cx='13' cy='13' r='13' fill='#D99F28'/>
                <path
                    d='M9.0332 11.9785H9.83594C10.1406 11.9785 10.3633 11.873 10.5039 11.6621C10.6484 11.4473 10.7207 11.1621 10.7207 10.8066C10.7207 10.4668 10.6465 10.2031 10.498 10.0156C10.3535 9.82422 10.1582 9.72852 9.91211 9.72852C9.68164 9.72852 9.49219 9.81836 9.34375 9.99805C9.19922 10.1777 9.12695 10.4043 9.12695 10.6777H7.46875C7.46875 10.2324 7.57031 9.83398 7.77344 9.48242C7.98047 9.12695 8.26758 8.84961 8.63477 8.65039C9.00586 8.45117 9.41602 8.35156 9.86523 8.35156C10.6504 8.35156 11.2656 8.56836 11.7109 9.00195C12.1562 9.43555 12.3789 10.0293 12.3789 10.7832C12.3789 11.1699 12.2773 11.5293 12.0742 11.8613C11.875 12.1895 11.6113 12.4414 11.2832 12.6172C11.6855 12.7891 11.9844 13.0469 12.1797 13.3906C12.3789 13.7344 12.4785 14.1406 12.4785 14.6094C12.4785 15.3672 12.2363 15.9746 11.752 16.4316C11.2715 16.8887 10.6387 17.1172 9.85352 17.1172C9.12305 17.1172 8.5293 16.8926 8.07227 16.4434C7.61914 15.9902 7.39258 15.3965 7.39258 14.6621H9.05078C9.05078 14.9668 9.13086 15.2227 9.29102 15.4297C9.45117 15.6367 9.6543 15.7402 9.90039 15.7402C10.1855 15.7402 10.4102 15.6348 10.5742 15.4238C10.7422 15.2129 10.8262 14.9316 10.8262 14.5801C10.8262 13.7402 10.5 13.3184 9.84766 13.3145H9.0332V11.9785ZM17.3031 12.2188H18.9613V13.7598H17.3031V16.1445H15.7328V13.7598H14.0453V12.2188H15.7328V9.93359H17.3031V12.2188Z'
                    fill='white'/>
            </svg>
        </OnHoverTooltip>;
	}


}

interface GradeLevelModel {
    render: () => React.ReactNode;
    icons?: { [levelId: number]: GradeLevelModel }
}
