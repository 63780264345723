import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	columnGap: '$3',
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
});

export const DurationBox = styled(FlexBox, {
	borderRadius: 2,
	borderWidth: 1,
	borderStyle: 'solid',
	paddingTop: 1,
	paddingBottom: 1,
	paddingLeft: 4,
	paddingRight: 4,

	variants: {
		borderColor: {
			tertiaryHighlight: {
				borderColor: '$tertiaryHighlight',
			},

			mild: {
				borderColor: '$mild',
			},
		},
	},
});
