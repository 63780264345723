import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {AssignmentState} from '../types';

export const StatusBox = styled(Box, {
	display: 'inline-flex',
	width: 'max-content',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	paddingTop: 5,
	paddingBottom: 5,

	variants: {
		likeAbbreviation: {
			true: {
				paddingLeft: 10,
				paddingRight: 10,
			},

			false: {
				paddingLeft: 12,
				paddingRight: 12,
			},
		},

		assignmentState: {
			[AssignmentState.Completed]: {
				color: '$green',
				borderColor: '$positiveMuted',
				backgroundColor: '$positiveBackground',
			},

			[AssignmentState.Draft]: {
				color: '$lowContrast',
				borderColor: '$mild',
				backgroundColor: '$background',
			},

			[AssignmentState.NotStarted]: {
				color: '$neutral40',
				borderColor: '$mild',
				backgroundColor: '$background',
			},

			[AssignmentState.Published]: {
				color: '$primary',
				borderColor: '$primaryMuted',
				backgroundColor: '$primaryBackground',
			},
		},
	},
});
