import {TestSectionContent} from './components/test-section-content';
import {TestSectionRoot} from './components/test-section-root';

export const TestSection = {
	Root: TestSectionRoot,
	Content: TestSectionContent,
};

export {
	type TestSectionSingleStudent,
	type TestSectionGroupStudents,
	TestSectionContentType,
	CardViewType,
} from './types';

export {type TestSectionContentCommonProps} from './components/test-section-content';

export {ProgressWithPointer} from './components/progress-bars';
