import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Container = styled(FlexBox, {
	padding: 8,
	background: '$negativeBackground',
	border: '1px solid $red92',
	borderRadius: 3,
});

export const StyledText = styled(Text, {
	textAlign: 'center',
});
