import React, {createRef, Suspense} from 'react';
import {HttpClient} from '@esgi/api';
import {Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {AddInCircleIcon} from '@esgillc/ui-kit/icons';
import {SelectableList, SelectableListItem} from '@esgillc/ui-kit/control';
import {Buttons} from '@esgillc/ui-kit/button';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {SubjectType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {deepCopy} from 'shared/utils';
import {Subject} from './models';

const SubjectDetails = lazyComponent(() => import('modules/subject-details'));

import styles from './styles.module.less';

interface Props {
	testName: string;
	testID: number;
	subjects: Subject[];
	onClose: () => void;
	onSave: (subjects: Subject[]) => void;
	onSubjectsChanged: (subjects: Subject[]) => void;
}

class State {
	loading: boolean = false;
	subjects: Subject[] = [];
	openSubjectDetails: boolean = false;
}

export default class ManageSubjectsOfTest extends React.PureComponent<Props, State> {
	public readonly state = new State();

	private readonly modalManagerRef: ModalManagerRefObject = createRef();

	public componentDidMount() {
		this.setState({subjects: deepCopy(this.props.subjects)});
	}

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Header>
				<Title>
					Add&nbsp;<i><b>{this.props.testName}</b></i>&nbsp;to Subject Tab:
				</Title>
			</Modal.Header>
			<Modal.Body>
				<SelectableList className={styles.list}
				                value={this.state.subjects.filter(s => s.linked)}
				                onChange={null}
				                checkboxPlacement='left'>
					{this.state.subjects.map(s => {
						return <SelectableListItem value={s}
						                           key={s.id}
						                           onClick={() => this.toggleSubject(s)}>
							{s.name}
						</SelectableListItem>;
					})}
					<SelectableListItem value={undefined}
					                    checkboxPlacement='none'
					                    className={styles.createSubjectItem}
					                    onClick={() => this.setState({openSubjectDetails: true})}>
						<AddInCircleIcon/>&nbsp;Create new subject tab
					</SelectableListItem>
				</SelectableList>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray
					onClick={() => this.modalManagerRef.current.close(this.props.onClose)}>CANCEL</Buttons.Gray>
				<Buttons.Contained onClick={() => this.save()}>SAVE</Buttons.Contained>
			</Modal.Footer>
			{this.renderSubjectEditor()}
			<Loader show={this.state.loading} fullscreen/>
		</Modal>;
	}

	private toggleSubject(subject: Subject): void {
		subject.linked = !subject.linked;
		this.setState({subjects: [...this.state.subjects]});
	}

	private renderSubjectEditor() {
		if (this.state.openSubjectDetails) {
			return <Suspense fallback={<></>}>
				<SubjectDetails onClosed={() => this.setState({openSubjectDetails: false})}
				                onSaved={this.handleNewSubject}/>
			</Suspense>;
		}
	}

	private handleNewSubject = (subjectID: number, name: string, type: SubjectType, published: boolean): void => {
		const subject = {
			id: subjectID,
			name,
			type,
			linked: true,
		} as Subject;
		this.setState({openSubjectDetails: false, subjects: [...this.state.subjects, subject]});
	};

	private getChangedSubjects(): Subject[] {
		return this.state.subjects.filter(sub => {
			const original = this.props.subjects.find(s => s.id === sub.id && s.type === sub.type);
			if (!original) {
				return sub.linked;
			}
			return original.linked !== sub.linked;
		});
	}


	private save() {
		this.setState({loading: true});
		const changedSubjects = this.getChangedSubjects();
		if (changedSubjects.length) {
			HttpClient.default.ESGIApi.post(
				'assets/tests',
				'update-subjects-of-test',
				{
					testID: this.props.testID,
					subjects: this.state.subjects,
				},
			).subscribe(() => {
				this.setState({loading: false});
				this.props.onSave(this.state.subjects);
				this.modalManagerRef.current.close(this.props.onClose);
				this.showSnackbar(changedSubjects);
			});
		} else {
			this.modalManagerRef.current.close(this.props.onClose);
		}

	}

	private showSnackbar(subjects: Subject[]) {
		let message = '';
		const names = subjects.filter(s => s.linked).map(s => s.name);

		if (!names.length) {
			return;
		}

		if (names.length > 3) {
			message = names.slice(0, 3).join(', ') + ` and (${names.length - 3}) more Subject Tabs`;
		} else {
			message = names.join(', ') + ' Subject Tabs';
		}

		if (names.length === 1) {
			message = names[0] + ' Subject Tab';
		}

		showSnackbarNotification('You have added this test to ' + message);
	}
}
