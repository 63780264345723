import {CheckAllIcon, Row} from '../../index.styled';
import {useTableRowStyles} from '../../hooks/use-table-row-styles';
import {HeaderCell} from './header-cell';
import {SortBy, Sorting} from '../../../../service/types';
import {FieldType} from '../../types';

type Props = {
	sorting: Sorting,
	allSelected: boolean,
	onSelectAllClicked: VoidFunction,
	onCellClicked: (field: SortBy) => void,
	disabled: boolean
}

export function Header(props: Props) {
	const styles = useTableRowStyles(true);

	return (
		<Row css={{...styles, height: 24, marginBottom: 12}}>
			<HeaderCell name={FieldType.Name} sorting={props.sorting} onClick={() => props.onCellClicked(SortBy[FieldType.Name])} disabled={props.disabled}>
				<CheckAllIcon allChecked={props.allSelected} width={24} height={24} onClick={props.onSelectAllClicked} disabled={props.disabled}/>
			</HeaderCell>
			<HeaderCell name={FieldType.Author} sorting={props.sorting} onClick={() => props.onCellClicked(SortBy[FieldType.Author])} disabled={props.disabled}/>
			<HeaderCell name={FieldType.Questions} sorting={props.sorting} onClick={() => props.onCellClicked(SortBy[FieldType.Questions])} disabled={props.disabled}/>
			<HeaderCell name={FieldType.Created} sorting={props.sorting} onClick={() => props.onCellClicked(SortBy[FieldType.Created])} disabled={props.disabled}/>
		</Row>
	);
}
