import {useEffect, useRef} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {Modal, useModal} from '@esgillc/ui-kit/modal';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {UntestedStudentsReportService} from '../../service';
import {UntestedStudentsReport} from '../../index';
import {SsoTracker} from '@esgi/core/tracker';
import {ModalHearder} from '../modal-hearder';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import styles from './modal.module.less';

interface Props {
	hierarchy: HierarchySnapshot;
	subjectID: number;
	subjectType: SubjectType;
	changesCollector: ChangesCollector;
	onClose: () => void
}

export default function UntestedStudentsReportModal({
	subjectID,
	subjectType,
	changesCollector,
	hierarchy,
	onClose,
}: Props) {
	const rootElement = useRef<HTMLDivElement>(null);
	const service = useService(UntestedStudentsReportService);
	const modal = useModal();
	const selectedSubject = service.selectedSubject;
	const selectedClassId = useBehaviorSubject(service.selectedClass)?.[0]?.classID;
	const selectedGroupId = useBehaviorSubject(service.selectedGroup)?.[0]?.groupID;
	const selectedSpecialistGroupId = useBehaviorSubject(service.selectedSpecialistGroup)?.[0]?.specialistGroupID;
	const firstLoadingSuccess = useBehaviorSubject(service.firstLoadingSuccess);

	const closeHandler = () => {
		modal.current.close(() => {
			changesCollector.applyChanges({
				classID: selectedClassId,
				groupID: selectedGroupId || selectedSpecialistGroupId,
				subjectTabID: selectedSubject.value[0]?.subjectID,
				subjectTabType: selectedSubject.value[0]?.subjectType,
			});
			onClose();
		});
	};

	useEffect(() => {
		service.init(subjectID, subjectType, hierarchy);
	}, []);

	useEffect(() => {
		SsoTracker.trackEvent({trackingEvent: 'UntestedStudentReport'});
	}, []);

	return <>
		<ServiceLoader trackingService={service} fullscreen/>
		{firstLoadingSuccess &&
			<Modal className={styles.modal} modalManagerRef={modal}>
				<Modal.Header className={styles.modalHeader}>
					<ModalHearder closed={closeHandler} service={service}/>
				</Modal.Header>
				<Modal.Body className={styles.modalBody}>
					<UntestedStudentsReport
						hierarchy={hierarchy}
						service={service}
						rootElement={rootElement}
						onClose={onClose}
					/>
				</Modal.Body>
			</Modal>
		}
	</>;
}
