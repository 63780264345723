import {GridBox} from '@esgi/ui/layout';
import {ResultBox, ResultHeaderRow, SessionDetailsBox, Table} from '../index.styled';
import {Text} from '@esgi/ui/typography';
import {TestSession, TestInfo} from '../../../../../../types';
import React from 'react';
import {SessionDate} from '../../../session-date';
import {AnswersInfo} from '../../../../../../kit';
import {ProgressLineBar} from '../../../progress-line-bar';
import {Button} from '@esgi/ui';
import {DeleteRounded} from '@esgi/ui/icons';
import {LoadMoreButton} from '../../../load-more-button';
import {getCorrectAnswersCount} from '../../../../utils/get-correct-answers-count';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	notVisibleSessionCount: number;
	onLoadMoreClick: VoidFunction;
	onOpenTestSessionDetailsAlert: (sessionID: number) => void;
	isTablet: boolean;
};

export function YesNoTable({
	testInfo,
	testSessions,
	notVisibleSessionCount,
	onLoadMoreClick,
	onOpenTestSessionDetailsAlert,
	isTablet,
}: Props) {
	return (
		<Table isTablet={isTablet}>
			<GridBox dataCy='session-date-table-header'>
				<Text size='xSmall' font='mono' color='mediumContrast'>
					Session Date
				</Text>
			</GridBox>
			<ResultHeaderRow dataCy='result-table-header'>
				<Text size='xSmall' font='mono' color='mediumContrast'>
					Result
				</Text>
			</ResultHeaderRow>

			{testSessions.map((session, index) => {
				const correctAnswers = getCorrectAnswersCount(session.answers);

				return (
					<React.Fragment key={session.id}>
						<SessionDate
							date={session.testDate}
							isDeletedSession={session.isDeleted}
							duration={session.duration}
							dataCy={`session-date-${index}`}
						/>
						<ResultBox withOpacity={session.isDeleted} dataCy={`session-result-${index}`}>
							<AnswersInfo value={correctAnswers} maxValue={testInfo.questions.length} />
							<ProgressLineBar value={correctAnswers} maxValue={testInfo.questions.length} />
						</ResultBox>
						<SessionDetailsBox dataCy={`session-buttons-${index}`}>
							{session.isDeleted && <DeleteRounded />}
							<Button color='secondary' onClick={() => onOpenTestSessionDetailsAlert(session.id)}>
								<Text size='medium' bold>
									Session Details
								</Text>
							</Button>
						</SessionDetailsBox>
					</React.Fragment>
				);
			})}

			<LoadMoreButton itemsCount={notVisibleSessionCount} onClick={onLoadMoreClick} />
		</Table>
	);
}
