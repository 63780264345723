import {useSpring, config} from 'react-spring';
import {IndicatorBox} from './indicator.styled';
import {getThemeColor} from '../../../../../utils';

export function IndicatorPending() {
	const props = useSpring({
		from: {
			transform: 'scale(1)',
			borderColor: getThemeColor('primary80'),
		},
		to: {
			transform: 'scale(1.4)',
			borderColor: getThemeColor('blue'),
		},
		loop: true,
		config: {
			...config.slow,
			clamp: true,
		},
	});

	return (
		<IndicatorBox
			style={props}
			css={{
				borderStyle: 'solid',
				borderWidth: 1,
			}}
		/>
	);
}
