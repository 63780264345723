import {ThemeColorKeys} from '@esgi/ui';
import {TestContentArea} from '../types';
import {IndicatorColorVariant} from './types';

export const indicatorWrapperColor: Record<TestContentArea, ThemeColorKeys> = {
	[TestContentArea.LanguageArts]: 'blueBackground',
	[TestContentArea.Science]: 'purpleBackground',
	[TestContentArea.SocialStudies]: 'yellowBackground',
	[TestContentArea.Math]: 'greenBackground',
	[TestContentArea.Spanish]: 'orangeBackground',
	[TestContentArea.SEL]: 'indigoBackground',
	[TestContentArea.Other]: 'mild',
};

export const indicatorLineColor: Record<TestContentArea, IndicatorColorVariant> = {
	[TestContentArea.LanguageArts]: IndicatorColorVariant.LanguageArts,
	[TestContentArea.Science]: IndicatorColorVariant.Science,
	[TestContentArea.SocialStudies]: IndicatorColorVariant.SocialStudies,
	[TestContentArea.Math]: IndicatorColorVariant.Math,
	[TestContentArea.Spanish]: IndicatorColorVariant.Spanish,
	[TestContentArea.SEL]: IndicatorColorVariant.SEL,
	[TestContentArea.Other]: IndicatorColorVariant.Other,
};
