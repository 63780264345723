import * as React from 'react';
import type {SVGProps} from 'react';

export function User(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M10 21.281a4.568 4.568 0 0 1 2.827-4.219.905.905 0 0 1 .721.028c.846.378 1.644.567 2.452.567.81 0 1.606-.179 2.423-.539a.958.958 0 0 1 .75 0C20.86 17.832 22 19.473 22 21.281v1.813c0 .5-.413.906-.923.906H10.923a.915.915 0 0 1-.923-.906V21.28Zm6-1.812c-.895 0-1.946-.228-2.818-.553-.73.42-1.336 1.31-1.336 2.365v.907h8.308v-.907c0-.997-.57-1.87-1.404-2.335-.855.31-1.863.523-2.75.523ZM16 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 1.778a2.222 2.222 0 1 1 0 4.444 2.222 2.222 0 0 1 0-4.444Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
