export function FreeExtraStudentsTemplate() {
	return <div className='free-extra-students' data-bind='var: {step: $data}'>
        <div className='clearfix'>
            ESGI prorates the purchase of extra students based on remaining subscription time.
            Since your subscription expires in less than 2 weeks, you will not be charged for
            the extra students you are adding to your account
        </div>
        <div className='clearfix comment'>
            * At time of subscription renewal, you will be charged for the extra students at a cost of $8.00 per student.
        </div>
        <div className='clearfix' style={{marginTop: '15px'}}>
            <button className='btn btn-cancel pull-left' data-bind='click: view.cancel'>Cancel</button>
            <button className='btn btn-warning pull-right' data-bind='click: view.continue'>Confirm</button>
        </div>
    </div>;
}