import React from 'react';
import './download.less';

class Props {
    testSessionsNumber: number;
    deletedCurrentSession: boolean;
    download: (current: boolean) => void;
}

export default class Download extends React.PureComponent<Props> {
	get downloadEnabled(): boolean {
    	return this.props.testSessionsNumber > 0;
	}

	get allSessionsEnabled(): boolean {
    	return this.props.testSessionsNumber > 1;
	}

	render() {
    	return <div className='download btn-group'>
            <span className={this.downloadEnabled ? null : 'disabled'}
                data-toggle={this.downloadEnabled ? 'dropdown' : 'none'}>
                <i className='fa fa-download'/> Download
            </span>
            <ul className='dropdown-menu dropdown-default'>
                <li>
                    <a href='#' className={!this.props.deletedCurrentSession ? null : 'disabled'}
                        onClick={(e) => {
                            if(!this.props.deletedCurrentSession){
                                this.props.download(true);
                            } else {
                                e.stopPropagation();
                            }
                        }}>Current Session</a>
                </li>
                <li>
                    <a href='#' className={this.allSessionsEnabled ? null : 'disabled'}
                        onClick={(e) => {
                            if(this.allSessionsEnabled){
                                this.props.download(false);
                            } else {
                                e.stopPropagation();
                            }
                        }}>All Sessions</a>
                </li>
            </ul>
        </div>;
	}
}