import * as React from 'react';
import type {SVGProps} from 'react';

export function Search(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='m19.17 17.85 3.678 3.677a.934.934 0 0 1-1.32 1.32L17.85 19.17c.51-.363.957-.81 1.32-1.32ZM14.603 9a5.603 5.603 0 1 1 0 11.206 5.603 5.603 0 0 1 0-11.206Zm0 1.868a3.736 3.736 0 1 0 0 7.47 3.736 3.736 0 0 0 0-7.47Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
