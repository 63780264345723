import {V2SessionDetailsScoreController} from '@esgi/contracts/esgi';
import {BaseService} from '@esgi/core/service';
import {Test} from '../../../types';
import {Student} from '@esgi/main/libs/store';

export class Service extends BaseService {
	private controllerScoreSession = new V2SessionDetailsScoreController();

	public init({testID, studentID}: {testID: Test['id']; studentID: Student['id']}) {
		return this.controllerScoreSession.initCreate({
			studentID,
			testID,
		});
	}

	public save({
		testDate,
		testID,
		notes,
		score,
		studentID,
	}: {
		testID: Test['id'];
		studentID: Student['id'];
		testDate: string;
		notes: string;
		score: number;
	}) {
		return this.controllerScoreSession.create({
			score,
			studentID,
			testDate,
			testID,
			notes,
		});
	}

	public override dispose() {
		this.controllerScoreSession.dispose();
	}
}
