import React, {ReactNode} from 'react';
import styles from './report-modal-download-button.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

interface Props {
	onClick?: () => void;
	name?: string;
	title?: string;
	options?: (() => ReactNode)[];
}

export class State {
	opened: boolean;
}

export class ReportModalDownloadButton extends React.PureComponent<Props, State> {
	state = new State();
	buttonRef: React.RefObject<HTMLDivElement> = React.createRef();
	ulRef: React.RefObject<HTMLUListElement> = React.createRef();

	get title() {
		return this.props.title ? this.props.title : 'Download';
	}

	get name() {
		return this.props.name ? this.props.name : 'Download';
	}

	get withOptions(){
		return !!this.props.options;
	}

	componentDidMount() {
		if (this.withOptions) {
			document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
		}
	}

	componentWillUnmount() {
		if (this.withOptions) {
			document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
		}
	}

	handleClickOutside(event) {
		if (this.state.opened) {
			if (this.ulRef.current && this.ulRef.current.contains(event.target)) {
				setTimeout(() =>{
					this.toggleOptions();
				}, 150);
			} else if (this.buttonRef.current && !this.buttonRef.current.contains(event.target)) {
				this.toggleOptions();
			}
		}
	}

	toggleOptions() {
		this.setState((prevState) =>{
			return {
				opened: !prevState.opened,
			}
		});
	}

	onClick() {
		if (this.withOptions) {
			this.toggleOptions();
		} else {
			this.props.onClick();
		}
	}

	public render() {
		return <div data-cy='report-modal-download-button' className={styles.container}>
			{this.state.opened && <ul className={styles.ul} ref={this.ulRef}>
				{this.props.options.map((renderer, index) => {
					return <li key={index} className={styles.li}>{renderer()}</li>;
				})}
			</ul>}
			<div ref={this.buttonRef}>
				<OnHoverTooltip message={this.title}>
					<div className={styles.downloadButton} onClick={() => this.onClick()}>
						<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z' fill='white'/>
						</svg>
						{this.name}
					</div>
				</OnHoverTooltip>
			</div>
		</div>;
	}
}
