export class DateTools {
  public static clone(date: Date): Date{
    return date ? new Date(date.valueOf()) : date;
  }

  public static addDays(date: Date, days: number){
    if (date != null){
      date.setDate(date.getDate() + days);
    }
  }

  public static nowDateWithoutTime(): Date {
    const d = new Date();

    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }

  public static trimTime(date: Date | string): Date | null {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0) : null;
  }

  public static toDate(str: string): Date {
    if (!str) {
      return null;
    }

    let year = 0;
    let month = 0;
    let day = 0;
    if (str.indexOf('-') != -1){
      const parts = str.split('T')[0].split('-');
      year = +parts[0];
      month = +parts[1] - 1;
      day = +parts[2];
    }
    if (str.indexOf('/') != -1){
      const parts = str.split('/');
      year = +parts[2];
      month = +parts[0] - 1;
      day = +parts[1];
    }

    return new Date(year, month, day, 0, 0, 0, 0);
  }

  public static toUIString(val: Date, format: string = 'MM/DD/YYYY'): string
  {
    const month = val.getMonth() + 1;
    const date = val.getDate();

    const YYYY = val.getFullYear().toString();
    const MM = (month < 10 ? '0' : '') + month;
    const DD = (date < 10 ? '0' : '') + date;

    return format.replace(/YYYY/g, YYYY).replace(/MM/g, MM).replace(/DD/g, DD);
  }

  public static toServerString(val: Date | string | null, includeTime: boolean = false): string {
		if (val == null) {
			return '';
		}
    if (val instanceof Date) {
      let date = `${val.getFullYear()}-${DateTools.normalizeDate(val.getMonth() + 1)}-${DateTools.normalizeDate(val.getDate())}`;
      if (includeTime) {
        const hours = DateTools.normalizeDate(val.getHours());
        const minutes = DateTools.normalizeDate(val.getMinutes());
        const seconds  = DateTools.normalizeDate(val.getSeconds());
        let rawOffset = val.getTimezoneOffset();
        const sign = rawOffset <= 0 ? '-' : '+';
        rawOffset = Math.abs(rawOffset);
        const offsetHours = Math.floor(rawOffset / 60);
        const offsetMinutes = rawOffset - (offsetHours * 60);
        date += `T${hours}:${minutes}:${seconds}${sign}${DateTools.normalizeDate(offsetHours)}:${DateTools.normalizeDate(offsetMinutes)}`;
      }

      return date;
    }

		if (val.indexOf('/') != -1) {
			const parts = val.split('/');
			return parts[2] + '-' + parts[0] + '-' + parts[1] + 'T00:00:00';
		}
		if (val.indexOf('T') != -1) {
			return val.split('+')[0];
		}

    return val;
  }

  private static normalizeDate(num: number): string {
    if (num < 10) {
      return '0' + num.toString();
    }
    return num.toString();
  }
}
