import {Text} from '@esgi/ui/typography';
import {ComponentProps, useRef, useState} from 'react';
import {CheapBox, SubjectGridBox, SubjectText, TooltipContent} from './index.styled';
import {useUser} from '@esgi/core/authentication';
import {cheapContent} from './constants';
import useResizeObserver from '@react-hook/resize-observer';
import {Tooltip} from '@esgi/ui/tooltip';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {SkeletonShape} from '../skeleton-shape';
import {SubjectLevel} from '@esgi/main/libs/store';

type Props = BaseComponentProps &
	ComponentProps<typeof Text> &
	Skeletonable & {
		subjectLevel?: SubjectLevel;
		forceRenderSubjectLevel?: boolean | undefined;
		noWrap?: boolean | undefined;
	};

export function SubjectName({
	dataCy = 'store-subject-name',
	css = {},
	subjectLevel,
	forceRenderSubjectLevel = false,
	noWrap = false,
	skeleton,
	...textProps
}: Props) {
	const user = useUser();

	const [isTextTruncated, setIsTextTruncated] = useState(false);

	const textRef = useRef<HTMLElement>(null);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight}}) => {
		setIsTextTruncated(clientWidth < scrollWidth || clientHeight < scrollHeight);
	});

	const isUserNotLinked = user?.agreementLevelCode === 'T';

	const subjectsCheapInfo = subjectLevel ? cheapContent[subjectLevel] : null;

	const withCheapBox = !!(subjectsCheapInfo && (forceRenderSubjectLevel || !isUserNotLinked));

	return (
		<SubjectGridBox dataCy={dataCy} css={css}>
			{skeleton && <SkeletonShape width={16} height={16} />}

			{withCheapBox && !skeleton && (
				<CheapBox color={subjectsCheapInfo.cheapColor} dataCy={`${dataCy}-cheap`}>
					<Text size='medium' bold data-cy={`${dataCy}-cheap-title`}>
						{subjectsCheapInfo.cheapTitle}
					</Text>
				</CheapBox>
			)}

			<Tooltip open={noWrap && isTextTruncated && !skeleton ? undefined : false}>
				<Tooltip.Trigger>
					<SubjectText data-cy={`${dataCy}-title`} noWrap={noWrap} ref={textRef} skeleton={skeleton} {...textProps} />
				</Tooltip.Trigger>
				<TooltipContent>
					<Text data-cy={`${dataCy}-tooltiped-title`} size='large'>
						{textProps.children}
					</Text>
				</TooltipContent>
			</Tooltip>
		</SubjectGridBox>
	);
}
