import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const Root = styled(GridBox, {
	gridTemplateColumns: '272px 1fr 400px',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	background: '$neutral96',
});

export const Panel = styled(Box, {
	overflow: 'hidden',
	height: '100%',
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',

	'> div': {
		width: '100%',
	},
});