import {environment} from '@esgi/core/environments';
import {fromEvent, merge} from 'rxjs';
import {io, Socket} from 'socket.io-client';
import {ShareTestingModel} from './models';

export class TeacherScreenClient {
	public readonly host = environment.url.replace('https://', 'wss://');
	public readonly socket: Socket = io(`${this.host}/teacher`, {
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 3000,
		path: '/share-screen/api',
		reconnectionAttempts: Infinity,
		withCredentials: true,
		transports: ['websocket', 'polling'],
	});

	public shared = fromEvent(this.socket, 'shared');
	public codeExpired = fromEvent(this.socket, 'codeExpired');
	public sessionEnded = fromEvent(this.socket, 'sessionEnded');
	public connectionFail = fromEvent(this.socket, 'connect_error');
	public studentJoined = fromEvent(this.socket, 'studentJoined');
	public reconnectAttempt = fromEvent(this.socket, 'reconnect_attempt');
	public connectError = merge(
		fromEvent(this.socket, 'reconnect_error'),
		fromEvent(this.socket, 'connect_error'),
		fromEvent(this.socket, 'connect_timeout'),
	);

	constructor() {
		this.socket.on('reconnect_attempt', () => {
			this.socket.io.opts.transports = ['polling', 'websocket'];
		});

		this.socket.on('connect', () => {
		});
	}

	public startTest(teacherID: number, test: ShareTestingModel) {
		this.socket.emit('start', {test: test, teacherID: teacherID});

		this.socket.on('reconnect', () => {
			this.socket.emit('reconnect_teacher', teacherID);
		});
	}

	public endSession = (code: string) => this.socket.emit('endSession', code);
	public endTest = () => this.socket.emit('endTest');
	public share = (teacherID: number) => this.socket.emit('share', teacherID);
	public isShared = (teacherID: number) => this.socket.emit('isShared', teacherID);
	public isExpired = (teacherID: number) => this.socket.emit('isExpired', teacherID);
	public showQuestion = (questionIndex: number) => this.socket.emit('showQuestion', questionIndex);

	public summary(total: number, correct: number, testResultsCorrectVerbiage: string, testResultsIncorrectVerbiage: string, showTestSessionResults: boolean) {
		this.socket.emit('summary',
			{
				total: total,
				correct: correct,
				testResultsCorrectVerbiage: testResultsCorrectVerbiage,
				testResultsIncorrectVerbiage: testResultsIncorrectVerbiage,
				showTestSessionResults: showTestSessionResults,
			});
	}

	public dispose() {
		this.socket.close();
	}
}
