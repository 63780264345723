import React from 'react';
import './component.less';
import './../../../component.less';
import {ITrack} from 'shared/modules/grade-scale/models';
import GradeRangeViewer from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/grade-range-viewer/component';
import {IGradeRangeView} from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/models';
import {SharedGradeRangeService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/service';

export class Props {
	track: ITrack;
	maxSize?: boolean;
	service: SharedGradeRangeService;
}

export class State {
	viewAll: boolean;
	selectedGradeRanges: IGradeRangeView[] = [];
}

export default class DPMPGradeRangeViewer extends React.PureComponent<Props, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		this.props.service.selectedGradeRangesViewAll$.subscribe(viewAll => {
			this.setState({viewAll: viewAll});
		});

		this.props.service.selectedGradeRanges$.subscribe(selectedGradeRanges => {
			this.setState({selectedGradeRanges: selectedGradeRanges});
		});
	}
	
	renderViewAllButton() {
		const className = this.props.maxSize ? 'view-all-button' : 'view-all-button min-size';
		
		if (!this.state.viewAll) {
			return <div className={className} onClick={() => this.props.service.setSelectedGradeRangesViewAll()}>
				<span>View all</span>
				<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z' fill='#0077B8'/>
				</svg>
			</div>;
		} else{
			return <div className={className} onClick={() => this.props.service.setSelectedGradeRangesViewAll()}>
				<span>Collapse All</span>
				<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.7738e-05L0 5.99991L1.41 7.40991Z' fill='#0077B8'/>
				</svg>
			</div>;
		}
	}
	
	render() {
		return <div className='different-per-period-wrapper'>
			<div className='different-per-period-container'>
				{this.props.track.trackDates.map((trackDate, index) => {
					const gradeRanges = this.state.selectedGradeRanges.filter(sgr => sgr.markingPeriod === index + 1);
					if (gradeRanges.length === 0) {
						return null;
					}
					
					return <div className='track-editor-container' key={index}>
						<div className='track-date-info'>
							<div className='track-number'>{index + 1}</div>
							<div className='track-period'>{trackDate.from + ' - ' + trackDate.to}</div>
						</div>
						<GradeRangeViewer
							gradeRanges={gradeRanges}
							maxSize={this.props.maxSize}
						/>
					</div>;
				})}
			</div>
			{this.renderViewAllButton()}
		</div>;
	}
}