import {ReportDisplayResultsMode} from '../../types';
import {DisplayResultsMode, ToggleItem} from './types';

export const adaptedToStringDisplayResultsMode: Record<ReportDisplayResultsMode, DisplayResultsMode> = {
	[ReportDisplayResultsMode.Score]: DisplayResultsMode.Score,
	[ReportDisplayResultsMode.Grade]: DisplayResultsMode.Grade,
	[ReportDisplayResultsMode.Percent]: DisplayResultsMode.Percent,
};

export const items: ToggleItem[] = [
	{
		value: ReportDisplayResultsMode.Grade,
		label: 'Grade',
	},
	{
		value: ReportDisplayResultsMode.Percent,
		label: 'Percent',
	},
	{
		value: ReportDisplayResultsMode.Score,
		label: 'Score',
	},
];
