import {createContext as reactCreateContext, useContext} from 'react';

export function createContext<T>(name: string, disableContextCheck?: boolean) {
	const context = reactCreateContext<T | null>(null);

	const useHook = (): T => {
		const ctx = useContext(context);

		if (!disableContextCheck && !context) {
			throw new Error(`${name} is null. Check you tree.`);
		}

		return ctx as T;
	};

	return {
		Context: context,
		useHook,
	};
}