import styles from './empty-block.module.less';

interface Props {
	emptyReport: boolean;
	noStudents: boolean;
}

export default function EmptyBlock(props: Props) {
	return <>
		{
			props.noStudents && <div className={styles.emptyTextContainer}>
                        <span className={styles.emptyReportText}>
                            There are no students in this group or class
                        </span>
			</div>
		}
		{
			props.emptyReport && <div className={styles.emptyTextContainer}>
                        <span className={styles.emptyReportText}>
                            There's no test data for the student
                        </span>
			</div>
		}
	</>;
}
