export enum ChartIconType {
	CORRECT = 'Correct',
	INCORRECT = 'Only',
	RETEST = 'All',
	BASELINE = 'Baseline',
}

export type FiltersState = {
	period?: string,
	dateRange?: Date[],
}