export class RoutesMap {
	protected static prefix: string = '';

	public static get home(): string {
		return this.prefix + '/';
	}

	public static get adminHome(): string {
		return this.prefix + '/admin-home';
	}

	public static get testExplorer(): string {
		return this.prefix + '/test-explorer';
	}
	public static get studentManager(): string {
		return this.prefix + '/student-manager';
	}

	public static get assignmentCenter(): string {
		return this.prefix + '/assignment-center';
	}

	public static get parentConferencer(): string {
		return this.prefix + '/parent-conferencer';
	}

	public static get signUp(): string {
		return this.prefix + '/sign-up';
	}

	public static get autoquoter(): string {
		return this.prefix + '/autoquoter';
	}

	public static get repautoquoter (): string {
		return this.prefix + 'riversidesales/autoquoter';
	}

	public static get requestAQuoteForm(): string {
		return this.prefix + '/forms/request-a-quote';
	}

	public static get quotePayment(): string {
		return this.prefix + '/quote-payment';
	}

	public static get esgiAgreement(): string {
		return this.prefix + '/esgi-agreement';
	}

	public static get privacyPolicy(): string {
		return this.prefix + '/privacy-policy';
	}

	public static get login(): string {
		return this.prefix + '/login';
	}

	public static get resetPassword(): string {
		return this.prefix + '/reset-password';
	}

	public static get renewal(): string {
		return this.prefix + '/renewal';
	}

	public static get completeRegistration(): string {
		return this.prefix + '/complete-registration';
	}

	public static get completeImport(): string {
		return this.prefix + '/complete-import';
	}

	public static get invitation(): string {
		return this.prefix + '/invitation';
	}

	public static get devLogin(): string {
		return this.prefix + '/dev-login';
	}

	public static get agreements(): string {
		return this.prefix + '/agreements';
	}

	public static get byCRM(): string {
		return this.prefix + '/login/bycrmtoken';
	}

	public static get byRenew(): string {
		return this.prefix + '/login/byrenew';
	}

	public static get byClassLink(): string {
		return this.prefix + '/login/byclasslink';
	}

	public static get byLinkedClever(): string {
		return this.prefix + '/login/byclever/linked';
	}

	public static get byNotLinkedClever(): string {
		return this.prefix + '/login/byclever/not-linked';
	}

	public static get studentsScreen(): string {
		return this.prefix + '/student-screen';
	}

	public static get loginCRM(): string {
		return this.prefix + '/crm/login';
	}

	public static get cleverRegistration(): string {
		return this.prefix + '/clever-registration';
	}
}
