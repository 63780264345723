import * as React from 'react';
import type {SVGProps} from 'react';

export function FieldConnector(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 16a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-1.5 6a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM9 13a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
