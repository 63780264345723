/*
	Hack to fix content width on tablets & phones
 */
export function resizeToWindow() {
	const mvp = document.querySelector(`meta[name='viewport']`);
	if (!mvp) {
		return;
	}

	mvp.removeAttribute('content');
	mvp.setAttribute('content', `width=${window.outerWidth}`);
}