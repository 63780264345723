export interface IFormControlValidatorResult {
	valid: boolean;
	message: string;
}

export class Field {
	value: string;
	touched: boolean;
	validation: IFormControlValidatorResult;

	constructor(value?: string, validation?: IFormControlValidatorResult) {
		this.value = value ?? '';
		if (validation) {
			this.validation = validation;
		} else {
			this.validation = {valid: !!value, message: ''};
		}
		this.touched = false;
	}
}

/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class Validators {
	static get successfulValidation() {
		return {
			valid: true,
			message: '',
		};
	}

	static get unsuccessfulValidation() {
		return {
			valid: false,
			message: '',
		};
	}

	static requriedValidator(value: string, fieldName?: string, customValidationMessage?: string): IFormControlValidatorResult {
		let text = fieldName || 'This field';
		return {
			valid: /\S/.test(value),
			message: customValidationMessage || text + ' is required',
		};
	}

	static requiredValidator(value: string, fieldName?: string, customValidationMessage?: string): IFormControlValidatorResult {
		return this.requriedValidator(value, fieldName, customValidationMessage);
	}

	static numberValidator(value: number, min: number, max: number, fieldName?: string, customValidationMessage?: string): IFormControlValidatorResult {
		let text = fieldName || 'This field';
		return {
			valid: value >= min && value <= max,
			message: customValidationMessage || text + ' must be between ' + min.toString() + ' - ' + max.toString(),
		};
	}

	static integerValidator(value: number, min: number, max: number, fieldName?: string, customValidationMessage?: string): IFormControlValidatorResult {
		let text = fieldName || 'This field';
		if (
			(typeof value === 'number' && !Number.isInteger(value))
			|| (typeof value === 'string' && !/^\d+$/.test(value))
		) {
			return {
				valid: false,
				message: customValidationMessage || text + ' must be a whole number.',
			};
		}
		return this.numberValidator(value, min, max, fieldName, customValidationMessage);
	}

	static maxLengthValidator(value: string, length: number, fieldName?: string, customValidationMessage?: string): IFormControlValidatorResult {
		let text = fieldName || 'This field';
		return {
			valid: value.length <= length,
			message: customValidationMessage || text + ' must be not greater ' + length + ' characters in length.',
		};
	}

	static minLengthValidator(value: string, length: number, fieldName?: string, customValidationMessage?: string): IFormControlValidatorResult {
		let text = fieldName || 'This field';
		return {
			valid: !!value && value.length >= length,
			message: customValidationMessage || text + ' must be at least ' + length + ' characters in length.',
		};
	}

	static emailValidator(value: string, message?: string): IFormControlValidatorResult {
		let regexp = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
		return {
			valid: !!value && regexp.test(value),
			message: message || 'Invalid email',
		};
	}

	static colorHexValidator(value: string): IFormControlValidatorResult {
		if (value === 'none') {
			return {
				valid: true,
				message: '',
			};
		}

		let regexp = new RegExp('^(#[a-fA-F0-9]{6}|[a-fA-F0-9]{6})$');
		return {
			valid: !!value && regexp.test(value),
			message: 'Please enter a color in the format \'#RRGGBB\'',
		};
	}
}
