export function getUrlVars() {
	const url = window.location.href;
	const vars: any = {};
	const hashes = url.split('?')[1];
	if (hashes) {
		const hash = hashes.split('&');

		for (let i = 0; i < hash.length; i++) {
			const params = hash[i].split('=');
			vars[params[0]] = params[1];
		}
	}

	return vars;
}
