import {ExpandFullAlt} from '@esgi/ui/icons';
import {PanelTriggerButton} from './index.styled';
import {useDrawerContext} from '../../../../context/drawer-context';
import {MouseEvent, useCallback} from 'react';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

type TriggerProps = {
	tooltipTitle?: string;
	dataCy?: string;
}
function Trigger({tooltipTitle, dataCy}: TriggerProps) {
	const {togglePanelOpen} = useDrawerContext();

	const handleClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			togglePanelOpen();
		},
		[togglePanelOpen],
	);

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<PanelTriggerButton withBackgroundHover onClick={handleClick} dataCy={dataCy} data-panel-header-trigger=''>
					<ExpandFullAlt />
				</PanelTriggerButton>
			</Tooltip.Trigger>
			{tooltipTitle && <Tooltip.Content variant='secondary'>
				<Text size='small'>
					{tooltipTitle}
				</Text>
			</Tooltip.Content>}
		</Tooltip>
	);
}

export const PanelTrigger = Object.assign(Trigger, {
	toString: () => `.${PanelTriggerButton.className}`,
});
