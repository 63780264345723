import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Dropdown, OnErrorTooltip, Option, Label} from '@esgillc/ui-kit/form-control';
import {StudentModel} from '../../models';
import {IEPDatesButton} from '../buttons/iep-dates-btn';
import {IEPGoalFormService} from '../../services';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';

type Props = {
	control: IFormControl;
	students: StudentModel[];
	service: IEPGoalFormService;
}

export const StudentDropdown = ({control, students, service}: Props) => (
	<FormElement
		className={styles.formElement}
		control={control}
	>
		<Label className={styles.label}>Student</Label>
		<div className={join(styles.field, styles.studentField)}>
			<Dropdown
				placeholder='Select a student...'
				className={styles.dropDownField}
				optionName='fullName'
				tabIndex={0}
			>
				{students.map((v, i) => <Option key={i} value={v}>{v.fullName}</Option>)}
			</Dropdown>
			<OnErrorTooltip
				showOnError='required'
				placement='left'
			>
				Please select a student...
			</OnErrorTooltip>
		</div>
	</FormElement>
);
