import {TestItem} from '@esgi/main/features/untested-students-report';
import {TestType} from '@esgi/main/libs/core';
import {
	ItemTagAndTitle,
	ListItem, Container,
	Item, CounterText, CounterBox,
	TestButton, NotTestedText,
} from '../../../../index.styled';
import {SelectableListTriggerContent} from './components/selectable-list-trigger-content';

interface Props {
	studentId: number,
	tests: TestItem[],
	onTestStart: (test: TestItem) => void;
}

function TestResult (props: {test: TestItem, studentId: number}) {
	if (props.test.type.toString() === String(TestType.YN)) {
		return <>
			<CounterText data-cy='correct' type='correct' bold>{props.test.correct}</CounterText>
			<CounterText data-cy='incorrect' type='incorrect' bold>{props.test.incorrect}</CounterText>
			<CounterText data-cy='untested' type='untested' bold>{props.test.untested}</CounterText>
		</>;
	}
	if (props.test.type.toString() === String(TestType.Score) || props.test.type.toString() === String(TestType.Rubric)) {
		return <>
			<strong>{props.test.correct}</strong>
			<span>/</span>
			<strong>{props.test.maxScore}</strong>
		</>;
	}
	return null;
}

export function TestListItem({tests, studentId, onTestStart}: Props) {
	return <ListItem dataCy='test-list'>
		<Container>
			{tests.map(test => <Item dataCy='test-list-item' key={`student_test_${test.id}_${studentId}`}>
				<ItemTagAndTitle>
					<SelectableListTriggerContent testID={test.id} testName={test.name} testType={test.type} />
				</ItemTagAndTitle>
				<CounterBox dataCy='test-result-counter'>
					{
						(test.correct + test.incorrect) !== 0 ? <TestResult test={test} studentId={studentId}/> :
							<NotTestedText data-cy='not-tested-test-title'>
								Not Tested
							</NotTestedText>
					}
				</CounterBox>
				<TestButton dataCy='test-button' onClick={() => onTestStart(test)}>Test</TestButton>
			</Item>)}
		</Container>
	</ListItem>;
}
