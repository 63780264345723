import {EntityModel} from '../../shared/types';

export class TestSelectorState {
	constructor(public selectedTestId: number) {
	}
}

export interface TestSelectorInitResponse {
	tests: SightWordsTest[];
}

export interface SightWordsTest extends EntityModel {
	subjectTabs: string[];
}
