import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {OneLinedText} from '@esgi/main/kits/common';

export const Container = styled(GridBox, {
	textAlign: 'left',
	width: '160px',
});

export const SettingName = styled(OneLinedText, {
	fontWeight: 400,
});
