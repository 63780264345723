import {useState} from 'react';
import {ContextMenu, Button} from '@esgi/ui';
import {More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {ContextMenuItem} from './index.styled';

interface Props {
	onDeleteStudent?: VoidFunction;
	isInitialized: boolean;
	isDisabled?: boolean;
}

export function MoreOptions({onDeleteStudent, isInitialized, isDisabled}: Props) {
	const [isOpen, setOpen] = useState(false);

	return <ContextMenu.Root open={isOpen} onOpenChange={setOpen}>
		<ContextMenu.Trigger disabled={isDisabled}>
			<Button.Icon skeleton={!isInitialized} withBackgroundHover>
				<More width={24} height={24}/>
			</Button.Icon>
		</ContextMenu.Trigger>
		<ContextMenu.Content>
			<ContextMenu.Group>
				<ContextMenuItem onClick={() => {
					setOpen(false);
					onDeleteStudent();
				}}>
					<Text size='medium'>Delete</Text>
				</ContextMenuItem>
			</ContextMenu.Group>
		</ContextMenu.Content>
	</ContextMenu.Root>;
}

