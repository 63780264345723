import {useCallback} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Checkbox} from '@esgillc/ui-kit/control';
import {ReportFiltersContainer, ReportFiltersLabel} from '@esgi/deprecated/ui-kit/report/filters';
import styles from './styles.module.less';
import {ReportFiltersLabelContainer, ReportFiltersContent} from '../../../report-filter';
import {isIpad, join} from '@esgillc/ui-kit/utils';

type ShowFilterProps = {
	showNotes: boolean;
	showSummary: boolean;
	showInColor: boolean;
	onShowInColorChange: (value: boolean) => void;
	onShowSummaryChange: (value: boolean) => void;
	onShowNotesChange: (value: boolean) => void;
};

export function ShowFilter({
	showNotes,
	showSummary,
	showInColor,
	onShowInColorChange,
	onShowNotesChange,
	onShowSummaryChange,
}: ShowFilterProps) {
	const onChangeShowNotes = useCallback(() => {
		onShowNotesChange(!showNotes);
	}, [showNotes]);

	const onChangeShowSummary = useCallback(() => {
		onShowSummaryChange(!showSummary);
	}, [showSummary]);

	const onChangeShowInColor = useCallback(() => {
		onShowInColorChange(!showInColor);
	}, [showInColor]);

	return (
		<ReportFiltersContainer dataCy='rubric-report-filter' className={styles.columnOrientation}>
			<ReportFiltersLabelContainer>
				<ReportFiltersLabel>Show:</ReportFiltersLabel>
			</ReportFiltersLabelContainer>
			<ReportFiltersContent>
				<div className={styles.checkboxesContainer}>
					<ReportFiltersContent className={join(isIpad() && styles.rowOrientation)}>
						<OnHoverTooltip message='Notes'>
							<div>
								<Checkbox className={styles.checkbox} onChange={onChangeShowNotes} checked={showNotes}>
									Notes
								</Checkbox>
							</div>
						</OnHoverTooltip>
						<OnHoverTooltip message='Summary'>
							<div>
								<Checkbox className={styles.checkbox} onChange={onChangeShowSummary} checked={showSummary}>
									Summary
								</Checkbox>
							</div>
						</OnHoverTooltip>
					</ReportFiltersContent>
					<ReportFiltersContent>
						<OnHoverTooltip message='In Color'>
							<div>
								<Checkbox className={styles.checkbox} onChange={onChangeShowInColor} checked={showInColor}>
									In Color
								</Checkbox>
							</div>
						</OnHoverTooltip>
					</ReportFiltersContent>
				</div>
			</ReportFiltersContent>
		</ReportFiltersContainer>
	);
}
