import {SnackbarCloseIcon} from './components/snackbar-close-icon';
import {Snackbar as SnackbarComponent} from './components/snackbar';
import {useSnackbarRef, useSnackbarClose, useSnackbarOpen} from './hooks';

export const Snackbar = Object.assign(SnackbarComponent, {
	CloseIcon: SnackbarCloseIcon,
	useRef: useSnackbarRef,
	useOpen: useSnackbarOpen,
	useClose: useSnackbarClose,
});

export {type SnackbarManager, type SnackbarOptions} from './types';
