import {SchoolYearChangedEvent} from 'modules/school-year';
import {BroadcastEventManager, dispatchAppEvent} from '@esgillc/events';
import {
	SubjectCreatedEvent,
	SubjectHiddenEvent,
	SubjectReorderedEvent,
	SubjectUnhiddenEvent,
	SubjectUpdatedEvent,
	TestAddedToSubjectEvent,
	TestMovedBetweenSubjectsEvent,
	TestRemovedFromSubjectEvent,
} from 'api/entities/events/subject';
import {RubricSessionChangedEvent} from 'modules/assessments';
import {RefObject} from 'react';
import {firstValueFrom} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {HierarchyDataService} from 'modules/hierarchy/services/hierarchy-data-service';
import {
	TestMovedToSubjectByMSATEvent,
	TestsReorderedByMSATEvent,
} from 'modules/manage-subjects-and-tests/events';
import {TestBackgroundChangedEvent, TestChanged, TestQuestionListChanged} from 'shared/modules/test-details/events';
import {TestSessionDetailsChanged} from 'shared/modules/test/test-session-details/events';
import {TestSessionDetailsChanged as TestSessionDetailsChangedNew} from 'modules/forms/test-session-details-form/events';
import {UserSettingsChangedEvent} from 'shared/modules/user-settings/events';
import {userStorage} from '@esgi/core/authentication';
import {hasHierarchyRelatedEvents, hierarchyAggregationPattern} from '../../../core/hierarchy-events';
import {PieChartReorderedEvent} from '../../components/pie-charts/events';
import {SchoolYearChangedOutsideEvent} from '../../components/school-year-selector/events';
import PieChartsService from '../pie-charts-service';
import SubjectsService from '../subjects-service/subjects-service';

export class CrossTabEventsService extends BaseService {
	private readonly broadcastEventBus = new BroadcastEventManager();
	private selectedSchoolYear = userStorage.get().globalSchoolYearID;

	constructor(
		private subjectsService: SubjectsService,
		private pieChartsService: PieChartsService,
		private hierarchyService: RefObject<HierarchyDataService>,
	) {
		super();

		this.broadcastEventBus.aggregate({
			SchoolYearChangedEvent,

			...hierarchyAggregationPattern,
			/*Subjects events*/
			SubjectCreatedEvent,
			SubjectHiddenEvent,
			SubjectReorderedEvent,
			SubjectUnhiddenEvent,
			SubjectUpdatedEvent,
			/*Pie chart's related events*/
			TestRemovedFromSubjectEvent,
			TestAddedToSubjectEvent,
			TestsReorderedByMSATEvent,
			TestMovedToSubjectByMSATEvent,
			TestBackgroundChangedEvent,
			RubricSessionChangedEvent,
			TestMovedBetweenSubjectsEvent,
			TestQuestionListChanged,
			UserSettingsChangedEvent,
			TestSessionDetailsChanged,
			TestSessionDetailsChangedNew,
			PieChartReorderedEvent,
			TestChanged,

		}).subscribe(async (aggregation) => {

			const hasSchoolYearChanged = aggregation.has(SchoolYearChangedEvent);
			const userSelectedYear = userStorage.get().globalSchoolYearID;
			if (hasSchoolYearChanged && this.selectedSchoolYear !== userSelectedYear) {
				dispatchAppEvent(SchoolYearChangedOutsideEvent, new SchoolYearChangedOutsideEvent(userSelectedYear));
				this.selectedSchoolYear = userSelectedYear;
				return;
			}

			const hasHierarchyChanged = hasHierarchyRelatedEvents(aggregation);
			let hierarchy = this.hierarchyService.current.hierarchyInstance;

			if (hasHierarchyChanged) {
				hierarchy = await firstValueFrom(this.hierarchyService.current.reload());
			}

			const hasSubjectsChanged = aggregation.has(
				SubjectCreatedEvent,
				SubjectHiddenEvent,
				SubjectReorderedEvent,
				SubjectUnhiddenEvent,
				SubjectUpdatedEvent);

			let subject = this.subjectsService.selectedSubject.value;
			if (hasSubjectsChanged) {
				subject = await this.subjectsService.initialize(hierarchy);
			} else if (hasHierarchyChanged) {
				subject = await this.subjectsService.updateSubjectsOutside(hierarchy);
			}

			const hasPieChartsRelatedEvents = aggregation.has(
				TestRemovedFromSubjectEvent,
				TestAddedToSubjectEvent,
				TestsReorderedByMSATEvent,
				TestMovedToSubjectByMSATEvent,
				TestBackgroundChangedEvent,
				RubricSessionChangedEvent,
				TestMovedBetweenSubjectsEvent,
				TestQuestionListChanged,
				UserSettingsChangedEvent,
				TestSessionDetailsChanged,
				PieChartReorderedEvent,
				TestChanged,
			);

			if(hasPieChartsRelatedEvents || hasSubjectsChanged || hasHierarchyChanged) {
				await firstValueFrom(this.pieChartsService.loadPieCharts(subject, hierarchy));
			}
		});
	}

	public destroy() {
		super.destroy();
		this.broadcastEventBus.destroy();
	}
}
