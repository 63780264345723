export const displayCellValue = (value, isGrowth) => {
	if (value == null) {
		if (isGrowth) {
			return 'N/A';
		} else {
			return 'NT';
		}
	}
	return value + '%';
}

export const decapitalize = (str) => {
	return str[0].toLowerCase() + str.slice(1)
}
