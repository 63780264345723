import {ReportData} from '../../types';

export function isEmptyReport(report: ReportData | null) {
	if (!report) {
		return true;
	}
	if ((report.students || []).length === 0) {
		return true;
	}
	const answers = report.questions.flatMap(({answers}) => answers);
	if (!answers.length) {
		return true;
	}
	return false;
}
