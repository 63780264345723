import {ClickOutsideListener} from '@esgillc/ui-kit/core';
import {join} from '@esgillc/ui-kit/utils';
import {Link} from '@esgi/deprecated/elements/buttons';
import {SchoolYearChangedEvent} from 'modules/school-year';
import React, {createRef} from 'react';
import {ModalAlertContainer} from 'shared/alert/modal-alert/component';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {UserProfile, UserInfoTrackChangedEvent} from '@esgi/main/features/user-profile';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {userStorage, UserType} from '@esgi/core/authentication';
import {Api, ITrackModel, IYearModel} from './api';
import {SchoolYearChangedOutsideEvent} from './events';
import './school-year-selector.less';
import {TrackConfirmEvents} from 'modules/track-confirm/models/track-confirm-events';
import {Alert, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import styles from 'pages/home/components/report-button/buttons/no-students-protected-button.module.less';
import {ClearStorageEvent} from '@esgi/main/libs/store';

function removeFromStorage(key: string): void {
	localStorage.removeItem(key);
}

class State {
	open: boolean = false;
	years: IYearModel[] = [];
	track: ITrackModel = null;
	currentGlobalSchoolYearID: number = null;
	profileOpened: boolean = false;
	error: string = null;
}


class Props {
	hierarchy: HierarchyInstance;
	selectedGlobalSchoolYearId: number;
	globalSchoolYearChanged: (id: number) => any;
	trackChanged: (value: ITrackModel) => void;
	onLoading?: (v: boolean) => void;
}

export default class SchoolYearSelector extends React.Component<Props, State> {
	private readonly eventBus = new EventBusManager();
	private readonly currentUser = userStorage.get();
	private modalManagerRef: ModalManagerRefObject = createRef();
	private ref: React.RefObject<HTMLDivElement> = createRef();
	private api: Api = new Api();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.hierarchy == null && this.props.hierarchy != null) {
			let trackID = 0;
			if (this.state.track) {
				trackID = this.state.track.trackID;
			}
			if (!trackID) {
				trackID = this.props.hierarchy.trackID;
			}
			this.fillTrack(trackID);
		} else if (prevProps.hierarchy != null && this.props.hierarchy != null) {
			if (prevProps.hierarchy.trackID !== this.props.hierarchy.trackID) {
				this.fillTrack(this.props.hierarchy.trackID);
			}
		}
	}

	public componentDidMount(): void {
		this.eventBus.subscribe(UserInfoTrackChangedEvent, (args: UserInfoTrackChangedEvent) => {
			const track: ITrackModel = {
				...this.state.track,
				trackName: args.name,
				trackID: args.trackID,
			};
			this.api.refreshTrack(track);
			this.changeTrack(track);
		});

		this.eventBus.subscribe(TrackConfirmEvents, () => {
			const track: ITrackModel = {
				...this.state.track,
				autoTrack: false,
			};
			this.api.refreshTrack(track);
			this.changeTrack(track);
		});

		this.eventBus.subscribe(SchoolYearChangedOutsideEvent, (args) => this.schoolYearSelected(args.schoolYearID));

		this.api.init().then(r => {
			this.setState({
				years: r.schoolYears,
				track: r.track,
				currentGlobalSchoolYearID: r.currentGlobalSchoolYearID,
			});
		});
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	public render() {
		const yearName = this.state.years.filter(y => y.globalSchoolYearID === this.props.selectedGlobalSchoolYearId).map(y => y.name)[0];

		return <div className='school-year-selector'>
			{this.renderTrack()}
			<div className='school-year'>School Year:
				<ClickOutsideListener onClickedOutside={this.handleClickOutside} active={this.state.open}>
					<div style={{display: 'inline-block'}} className={join('dropdown', this.state.open && 'open')}
					     ref={this.ref}>
						<Link className='school-year-dropdown-link dropdown-toggle'
						      onClick={() => this.setState({open: !this.state.open})}>
							{yearName}
							<span className='caret'/>
						</Link>
						<ul className='dropdown-menu'>
							{this.state.years.map(y =>
								<li key={y.globalSchoolYearID} data-name={y.name}>
									<a className='school-year-link' href='#'
									   onClick={() => this.schoolYearSelected(y.globalSchoolYearID)}>{y.name}</a>
								</li>,
							)}
						</ul>
					</div>
				</ClickOutsideListener>
				{this.state.years.length && this.state.currentGlobalSchoolYearID !== this.props.selectedGlobalSchoolYearId &&
					<a href='https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Year'
					   target='_blank'
					   title="You're not viewing the current school year. Click the icon to learn how to switch school years"
					   rel='noreferrer'><i
						className='fa fa-warning school-year-warning'/></a>}
			</div>
			{this.renderModalAlert()}
			{this.renderErrorAlert()}
		</div>;
	}

	private setLoading(v: boolean) {
		this.props.onLoading(v);
	}

	private schoolYearSelected = (globalSchoolYearId: number) => {
		if (this.props.selectedGlobalSchoolYearId === globalSchoolYearId) {
			return;
		}

		this.setLoading(true);
		this.api.switch(globalSchoolYearId).then(data => {
			if (data.error) {
				this.setState({error: 'School Year does not exist. The page should be reloaded.'});
			}else{
				removeFromStorage('firstClassIsSelectedOnLogin');
				userStorage.update({globalSchoolYearID: globalSchoolYearId});
				this.props.globalSchoolYearChanged(globalSchoolYearId);
				dispatchAppEvent(SchoolYearChangedEvent, {schoolYearID: globalSchoolYearId});
				dispatchAppEvent(ClearStorageEvent);
				this.changeTrack(data.track);
			}
			this.setLoading(false);
		});

		this.setState({open: false});
	};

	private handleClickOutside = () => {
		if (this.state.open) {
			this.setState({open: false});
		}
	};

	private changeTrack(track: ITrackModel) {
		this.props.trackChanged(track);
		this.setState({track: track});
	}

	private renderModalAlert() {
		let arrowTop = 60;
		if (this.currentUser.userType !== UserType.T) {
			arrowTop = 48;
		}

		return <ModalAlertContainer pointToRef={this.ref} top={-40} left={110} arrowTop={arrowTop} arrowLeft={-7}/>;
	}

	private renderErrorAlert(){
		return this.state.error && <Alert className={styles.alert} modalManagerRef={this.modalManagerRef}>
			<Alert.Body>
				{this.state.error}
			</Alert.Body>
			<Alert.Footer>
				<Link onClick={() => this.modalManagerRef.current.close(() => location.reload())}>OK</Link>
			</Alert.Footer>
		</Alert>
	}

	private renderTrack() {
		if (!this.state.track) {
			return null;
		}


		const trackName = this.state.track.trackName;

		return <div>
			<div className='track'>Track Name: <a href='#' title={trackName}
			                                      onClick={() => this.setState({profileOpened: true})}
			                                      className='value'>{trackName}</a>
			</div>
			{this.renderProfile()}
		</div>;
	}

	private renderProfile() {
		if (!this.state.profileOpened) {
			return null;
		}

		return <UserProfile
			close={() => this.setState({profileOpened: false})}
		/>;
	}

	private fillTrack(trackID: number) {
		if (!trackID) {
			this.changeTrack(null);
			return;
		}

		this.api.trackInfo(trackID).then(track => {
			this.changeTrack(track);
		});
	}
}
