import React from 'react';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {FormControl} from '@esgillc/ui-kit/form';
import {Buttons} from '@esgillc/ui-kit/button';
import {join} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {ModalWindow} from '@esgi/deprecated/knockout';
import {StateStandardsLibrary} from 'shared/modules/state-standards-library';
import {userStorage} from '@esgi/core/authentication';
import {ClearIcon} from '../../../../../../common/rubric-edit-form/icons';
import RubricService from '../../../../../../common/rubric-service';
import {Field} from '../type';

import styles from '../confirm.module.less';

class State {
	stateStandardName = '';
}

interface Props {
	rubricService: RubricService;
	contentAreaControl: FormControl<Field>;
}

export default class StateStandardField extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		const state = new State();
		state.stateStandardName = props.rubricService.testInfo$.value.stateStandard;
		this.state = state;
	}

	public render() {
		return <div className={join(styles.inlineField, styles.stateStandardField)}>
			<div className={styles.label}>State Standard:</div>
			<div className={styles.element}>
				<Buttons.Link className={join(styles.stateStandardButton, this.state.stateStandardName ? '' : styles.disabledButton)}>
										<span>
											{this.state.stateStandardName || 'State Standard'}
										</span>
				</Buttons.Link>
			</div>
		</div>;
	}
}
