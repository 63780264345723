import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

type ContextMenuGroupProps = DropdownMenu.DropdownMenuGroupProps & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const ContextMenuGroup = forwardRef<HTMLDivElement, ContextMenuGroupProps>(
	({dataCy, ...props}, forwardedRef) => (
		<Group data-cy={dataCy ?? 'ui-kit-context-menu-group'} ref={forwardedRef} {...props} />
	),
);

const Group = styled(DropdownMenu.Group);
