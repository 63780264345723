import React, {useEffect, useMemo, useRef} from 'react';
import styles from './styles.module.less';
import {
	DetailReportScores,
} from './components/scores';
import {
	DetailReportCharts,
} from './components/charts';
import {
	DetailReportAnswers,
} from './components/answers';
import {
	DetailReportNotes,
} from './components/notes';
import {ReportService} from '../../../../../services/report-service';
import {Test} from '../../../../../models/models';

interface Props {
	tests: Test[];
	service: ReportService;
}

export function TestsRow({tests, service}: Props) {
	const testsMemoized = useMemo(() => tests.map(test =>
		<div className={styles.detailContainer} key={test.testID}>
			<div className={styles.detailContainerInner}>
				<div id={`${test.testID}-test-name`} className={styles.testName}>{test.testName}</div>
				<div className={styles.flexRow}>
					<DetailReportScores test={test} service={service}/>
				</div>
				<DetailReportCharts test={test} service={service}/>
				<DetailReportAnswers test={test} service={service}/>
				<DetailReportNotes test={test} service={service}/>
			</div>
		</div>,
	), [tests]);

	return <div className={styles.testsRow}>
		{testsMemoized}
	</div>;
}
