import {useMemo, useRef} from 'react';
import {Checkbox} from '@esgillc/ui-kit/control';
import {Buttons} from '@esgillc/ui-kit/button';
import {useBehaviorSubject, join} from '@esgillc/ui-kit/utils';
import {IEPProgressReportService} from '../../services';
import {FilterStatus, Row} from '../../types';
import {NoGoalsSetMessage} from './components/no-goals-set-message';
import styles from './styles.module.less';

interface Props {
	service: IEPProgressReportService,
	onClick: (row: Row) => void;
}

export const Table = ({service, onClick}: Props): JSX.Element => {
	const rows = useBehaviorSubject(service.rows$);
	const tbodyRef = useRef<HTMLTableSectionElement>(null);
	const selected = useBehaviorSubject(service.selected$);
	const status = useBehaviorSubject(service.settings$)?.status;
	const hasGoal = useMemo(
		() => rows.length > 0 && rows.some(({iepGoal}) => iepGoal),
		[rows],
	);

	const selectedAll = hasGoal && rows
		.filter(({iepGoal}) => iepGoal)
		.every(({id}) => selected.includes(id));
	const showNoGoalsSet = rows.length && rows.every(({iepGoal}) => !iepGoal);
	const noTestsWithGoals = !rows.length;
	const noTestsMessage = `There are no tests ${status === FilterStatus.Progress ? 'in progress with an' : 'with a' +
		' completed'} IEP goal`;

	return (
		<div className={styles.tableWrapper}>
			{!!showNoGoalsSet && <NoGoalsSetMessage tbodyRef={tbodyRef}/>}
			<table className={styles.table}>
				<thead>
				<tr>
					<th>
						<Checkbox
							className={styles.selectAll}
							checked={selectedAll}
							disabled={!hasGoal}
							onChange={({target}) => service.selectAll(target.checked)}
						>
							Tests to Include
						</Checkbox>
					</th>
					<th>IEP Goals</th>
				</tr>
				</thead>
				<tbody ref={tbodyRef} className={join(showNoGoalsSet && rows.length < 2 && styles.heightPlaceholder)}>
				{noTestsWithGoals && <tr className={styles.noTestsWrapper}>
					<div className={styles.noTests}>
						{noTestsMessage}
					</div>
				</tr>}
				{rows.map((row) => (
					<tr key={row.id}>
						<td>
							<Checkbox
								disabled={!row.iepGoal}
								checked={selected.includes(row.id)}
								onChange={({target}) => service.select(row.id, target.checked)}
							>
								{row.name}
							</Checkbox>
						</td>
						<td>
							<Buttons.Link
								className={styles.tableFont}
								onClick={() => onClick(row)}
							>
								{row.iepGoal ? 'View' : 'Create'}
							</Buttons.Link>
						</td>
					</tr>
				))}
				</tbody>
			</table>
		</div>
	);
};
