import {useCallback, useMemo, useState} from 'react';
import {Test, Sort} from './types';

const sortOrder: Sort[] = [
	{field: 'name', direction: 'desc'},
	{field: 'name', direction: 'asc'},
	{field: 'default', direction: 'desc'},
];

interface Props {
	tests: Test[];
}

export function useTestsControl({tests}: Props) {
	const [sortIndex, setSortIndex] = useState(2);
	const sort = useMemo<Sort>(() => sortOrder[sortIndex], [sortIndex]);
	const items = useMemo(
		() => {
			if (tests === null) {
				return [];
			}
			const testsClone = JSON.parse(JSON.stringify(tests));

			if (sort.field === 'name') {
				return testsClone.sort((left, right) => {
					if (sort.direction === 'asc') {
						return left.name.localeCompare(right.name);
					}
					return right.name.localeCompare(left.name);
				});
			}
			return testsClone;
		},
		[sort, tests],
	);

	const onSort = useCallback(() => {
		let next = sortIndex + 1;
		if (next > 2) {
			next = 0;
		}
		setSortIndex(next);
	}, [sortIndex]);

	return {
		items,
		sort,
		onSort,
	};
}
