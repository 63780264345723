import {SkeletonShape} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {InfoWithTranscript} from './index.styled';
import {CardRoot} from '../assignment-details-card-root/index.styled';
import {CardHeader} from '../assignment-details-card-header/index.styled';
import {CardSeparator} from '../assignment-details-card-divider/index.styled';
import {CardBody} from '../assignment-details-card-body/index.styled';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & BaseComponentProps;

export const AssignmentDetailsCardSkeleton = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'assignment-details-card-skeleton', css = {}, ...props}, forwardedRef) => (
		<CardRoot dataCy={dataCy} css={css} {...props} ref={forwardedRef}>
			<CardHeader>
				<GridBox display='inlineGrid' flow='column' align='center' gap='3'>
					<SkeletonShape width={140} height={36} />
					<SkeletonShape width={80} />
				</GridBox>
			</CardHeader>
			<CardSeparator />
			<CardBody withDescription>
				<SkeletonShape width={140} />
				<GridBox display='inlineGrid' flow='column' align='center' gap='8'>
					{new Array(3).fill(null).map((_, index) => (
						<InfoWithTranscript key={index}>
							<SkeletonShape width={64} height={16} />
							<SkeletonShape width={40} height={12} />
						</InfoWithTranscript>
					))}
				</GridBox>
			</CardBody>
		</CardRoot>
	),
);
