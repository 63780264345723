/* eslint-disable @typescript-eslint/naming-convention */
import {createContext, useContext, useEffect, useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {TestSubsetOpenEvent} from './components/yes-no/events';
import {TestSessionDetailsFormService} from './services';

export const TestSessionDetailsContext = createContext(null);
export const useTestSessionDetailsService = (): TestSessionDetailsFormService => useContext(TestSessionDetailsContext);

export const useTestSessionDetails = (init: () => void) => {
	const [testSubsetOpened, setTestSubsetOpened] = useState(false);

	useEventEffect(TestSubsetOpenEvent, () => {
		setTestSubsetOpened(true);
	});

	useEffect(() => {
		init();
	}, []);

	return {
		testSubsetOpened,
		setTestSubsetOpened,
	};
};
