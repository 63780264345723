import {HierarchyMode} from 'modules/hierarchy/core/models/enums';
import {HierarchySnapshot} from 'modules/hierarchy/core/models/snapshot/hierarchy-snapshot';
import {Question} from 'shared/modules/bingo/types/models';
import {SortBy} from 'shared/modules/bingo/steps/preview-questions/preview-questions';

export function getSpecialistGroupID(hierarchy: HierarchySnapshot): number {
	switch (hierarchy.mode) {
		case HierarchyMode.Specialist:
			return hierarchy.specialist.groupID;
		case HierarchyMode.PreAssess:
			return hierarchy.preAssess.groupID;
		default:
			return 0;
	}
}

export function getSpecialistID(hierarchy: HierarchySnapshot): number {
	switch (hierarchy.mode) {
		case HierarchyMode.Specialist:
			return hierarchy.specialist.userID;
		case HierarchyMode.PreAssess:
			return hierarchy.preAssess.userID;
		default:
			return 0;
	}
}

export function sortBy(questions: Question[], sortById: SortBy): Question[] {
	const copy = [...questions];

	if (sortById === SortBy.TestOrder) {
		if (questions.filter(x => x.orderNum === 0).length > 1) {
			copy.sort((a: Question, b: Question) => {
				if (a.questionID < b.questionID) {
					return -1;
				}
				if (a.questionID > b.questionID) {
					return 1;
				}
				return 0;
			});
		} else {
			copy.sort((a: Question, b: Question) => {
				if (a.orderNum < b.orderNum) {
					return -1;
				}
				if (a.orderNum > b.orderNum) {
					return 1;
				}
				return 0;
			});
		}
	}

	if (sortById === SortBy.Alphabetical) {
		copy.sort((a, b) => {
			return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
		});
	}

	return copy;
}
