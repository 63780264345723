import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const QuotePaymentPage = lazyComponent(() => import('pages/landing/quote-payment'));

export default function QuotePayment() {
	return (
		<RouteWrapper title='Quote Payment'>
			<Suspense fallback={<DefaultRouteFallback />}>
				<QuotePaymentPage />
			</Suspense>
		</RouteWrapper>
	);
}
