import React from 'react';
import {ReportType, SubjectType} from '@esgi/core/enums';
import {lazyClass} from '@esgi/core/react';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {SsoTracker} from '@esgi/core/tracker';
import {UserType} from '@esgi/core/authentication';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';

const totalReportModal = lazyClass(() => import('shared/modules/reports/total-report/total-report-modal'));

class Props {
	hierarchy: HierarchyInstance;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	subjectId: number;
	subjectType: SubjectType;
	globalSchoolYearId: number;
	noStudents: boolean;
}

export default class StudentsReportButton extends React.PureComponent<Props> {

	public render() {
		return <NoStudentProtectedButton title={this.title}
		                         icon={this.icon()}
		                         hoverTitle={this.hoverTitle}
		                         onClicked={() => this.clickHandler()}
		                         noStudents={this.props.noStudents}
		                         linkClassName='students-report-link'/>;
	}

	icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='17'
		            viewBox='0 0 15 17'
		            fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M5.76923 1.13151C5.76923 0.506571 6.28587 0 6.92308 0H8.07692C8.71413 0 9.23077 0.506571 9.23077 1.13151H12.6923C13.9667 1.13151 15 2.14472 15 3.39454V14.7097C15 15.9595 13.9667 16.9727 12.6923 16.9727H2.30769C1.03328 16.9727 0 15.9595 0 14.7097V3.39454C0 2.14472 1.03328 1.13151 2.30769 1.13151H5.76923ZM6.92308 1.69727C6.92308 1.38483 7.18111 1.13151 7.5 1.13151C7.81889 1.13151 8.07692 1.38483 8.07692 1.69727C8.07692 2.00971 7.81889 2.26303 7.5 2.26303C7.18111 2.26303 6.92308 2.00971 6.92308 1.69727ZM5.76923 6.22332C5.76923 5.28594 6.54391 4.52605 7.5 4.52605C8.45609 4.52605 9.23077 5.28594 9.23077 6.22332C9.23077 7.1607 8.45609 7.9206 7.5 7.9206C6.54391 7.9206 5.76923 7.1607 5.76923 6.22332ZM4.61538 11.8809C4.61538 10.3186 5.9067 9.05211 7.5 9.05211C9.0933 9.05211 10.3846 10.3186 10.3846 11.8809V12.5723C10.3846 13.1279 9.92544 13.5782 9.35922 13.5782H5.64078C5.07456 13.5782 4.61538 13.1279 4.61538 12.5723V11.8809Z'
			      fill='#0088CC'/>
		</svg>;
	}

	get title(): string {
		return `${this.getPrefix()} Students`;
	}

	private getPrefix() {
		const h = this.props.hierarchy;
		if (h.mode === HierarchyMode.Classic) {
			const selected = h.classic.selected;
			switch (selected.level) {
				case ClassicHierarchyLevel.Class:
				case ClassicHierarchyLevel.Student:
					return 'Class';
				case ClassicHierarchyLevel.Group:
					return 'Group';
				case ClassicHierarchyLevel.Teacher:
					return ''; //not supported
				case ClassicHierarchyLevel.School:
					return 'School';
				case ClassicHierarchyLevel.District:
					return 'District';
        case ClassicHierarchyLevel.SchoolsGroup:
          return 'School Group';
        case ClassicHierarchyLevel.TeachersGroup:
          return 'Teacher Group';
			}
		}

		if (h.mode === HierarchyMode.Specialist) {
			const s = h.specialist;
      const selected = h.specialist.selected;

			if (s.userID === 0) {
        if(selected.level === SpecialistHierarchyLevel.GroupOfSpecialists){
          return 'Specialist Group'
        }
				if (s.type === UserType.ISS && s.filter.schoolID > 0) {
					return 'School';
				} else {
					return 'District';
				}
			} else {
				return 'Group';
			}
		}

		if (h.mode === HierarchyMode.PreAssess) {
			const pa = h.preAssess;

			if (pa.userID === 0) {
				return 'District';
			} else {
				return 'Group';
			}
		}

		return '';
	}

	get hoverTitle(): string {
		const prefix = this.getPrefix();
		let levelPrefix = 'Sortable Subject Tab report of growth over time';

		if (prefix === 'District') {
			levelPrefix = 'Review and compare student scores for a subject tab at the District level';
		}
		if (prefix === 'School') {
			levelPrefix = 'Review and compare student scores for a subject tab at the School level';
		}

		return levelPrefix;
	}

	private build(): { type: ReportType, id: number } {
		const h = this.props.hierarchy;
		if (h.mode === HierarchyMode.Classic) {
			const selected = h.classic.selected;
			switch (selected.level) {
				case ClassicHierarchyLevel.Class:
					return {type: ReportType.Class, id: selected.levelID};
				case ClassicHierarchyLevel.Student:
					if (h.classic.groupID) {
						return {type: ReportType.Group, id: h.classic.groupID};
					} else {
						return {type: ReportType.Class, id: h.classic.classID};
					}
				case ClassicHierarchyLevel.Group:
					return {type: ReportType.Group, id: selected.levelID};
				case ClassicHierarchyLevel.Teacher:
					return {type: ReportType.Teacher, id: selected.levelID};
				case ClassicHierarchyLevel.School:
					return {type: ReportType.StudentsSchool, id: selected.levelID};
				case ClassicHierarchyLevel.District:
					return {type: ReportType.StudentsDistrict, id: selected.levelID};
        case ClassicHierarchyLevel.SchoolsGroup:
          return {type: ReportType.StudentsSchoolsGroup, id: selected.levelID};
        case ClassicHierarchyLevel.TeachersGroup:
          return {type: ReportType.StudentsTeachersGroup, id: selected.levelID};
			}
		}

		if (h.mode === HierarchyMode.Specialist) {
			const s = h.specialist;

			if (s.userID === 0) {
        if(h.specialist.groupOfSpecialistsID > 0) {
          return {type: ReportType.GroupOfSpecialistsStudents, id: h.specialist.groupOfSpecialistsID};
        }
				if (s.type === UserType.ISS && s.filter.schoolID > 0) {
					return {type: ReportType.SchoolSpecialistStudents, id: s.filter.schoolID};
				} else {
					return {type: ReportType.DistrictSpecialistStudents, id: s.districtID};
				}
			} else {
				return {type: ReportType.SpecialistGroup, id: s.groupID};
			}
		}

		if (h.mode === HierarchyMode.PreAssess) {
			const pa = h.preAssess;

			if (pa.userID === 0) {
				return {type: ReportType.DistrictPreassessStudents, id: pa.districtID};
			} else {
				return {type: ReportType.SpecialistGroup, id: pa.groupID};
			}
		}

		return {type: ReportType.None, id: 0};
	}

	clickHandler = () => {
		SsoTracker.trackEvent({
			trackingEvent: this.getPrefix() + 'TotalsReport',
		});

		const type = this.build();
		const snapshot = this.props.hierarchy.snapshot;
		const changesCollector = new ChangesCollector({id: this.props.subjectId, type: this.props.subjectType}, snapshot);
		totalReportModal.load().then(trm => {
			const ctr = new trm({
				globalSchoolYearId: this.props.globalSchoolYearId,
				reportId: type.id,
				subjectId: this.props.subjectId,
				reportType: type.type,
				subjectType: this.props.subjectType,
			}, changesCollector, snapshot);

			ctr.load();
			ctr.events.onClosed(() => this.props.applyHomepageChanges(changesCollector));
		});
	}
}
