import {SelectableList} from '@esgi/ui/layout';
import {IdableItem} from '../../types';
import {getListValue} from '../../utils/get-list-value';
import {CommonProps} from './types';
import {ComponentProps} from 'react';
import {IconComponent} from '@esgi/ui';

type Props<Item extends IdableItem> = CommonProps<Item> & {
	value?: Item['id'] | undefined | null;
	itemVariant?: ComponentProps<typeof SelectableList.Item>['variant'];
	IconBefore?: IconComponent;
};

export function Single<Item extends IdableItem>({
	items,
	dataCy,
	value,
	onItemClick,
	children,
	itemVariant,
	css = {},
	IconBefore,
}: Props<Item>) {
	return (
		<SelectableList dataCy={dataCy} css={css}>
			<SelectableList.GroupRoot type='single' value={getListValue(value)}>
				<SelectableList.Group>
					{items.map((item) => (
						<SelectableList.Item
							value={String(item.id)}
							key={item.id}
							forceSelect={value !== item.id}
							onClick={() => onItemClick(item.id)}
							variant={itemVariant}
							IconBefore={IconBefore}
						>
							{children?.({
								item,
							})}
						</SelectableList.Item>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
