import {FlexBox} from '@esgi/ui/layout';
import {Users} from '@esgi/ui';
import {SubjectTab} from '@esgi/main/libs/store';
import {HeaderBox, ClassName, Divider, IconWrapper, Text} from './index.styled';
import {isNull} from 'underscore';
import {SubjectName} from '@esgi/main/kits/common';

interface Props {
	headerName?: string | null;
	subject: SubjectTab | null;
}

export function Header({headerName, subject}: Props) {
	return (
		<HeaderBox>
			<FlexBox align='center' dataCy='untested-students-reports-title'>
				<Text size='small' color='neutral56' data-cy='untested-students-reports-title'>
					Untested Students
				</Text>
			</FlexBox>
			<Divider />
			<FlexBox align='center'>
				{headerName &&
					<>
						<IconWrapper dataCy='user-icon'>
							<Users />
						</IconWrapper>
						<ClassName color='neutral56' size='small' dataCy='class-name'>
							{headerName}
						</ClassName>
					</>
				}
			</FlexBox>
			{!isNull(subject) &&
				<>
					<Divider />
					<SubjectName subjectLevel={subject.level} size='medium' bold css={{width: 'auto'}} dataCy='subject-name'>
						{subject.name}
					</SubjectName>
				</>
			}
		</HeaderBox>
	);
}
