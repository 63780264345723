import {useLocation} from 'react-router-dom';
import {Text} from '@esgi/ui/typography';
import {User} from '@esgi/ui/icons';
import {IconComponent} from '@esgi/ui';
import {routes} from '@esgi/main/libs/core';

export function Title() {
	const {pathname} = useLocation();
	const data: Record<string, [IconComponent, string]> = {
		[routes.teacher.reports.studentProgress]: [User, 'Student Progress Report'],
	};
	const [Icon = null, text = ''] = data[pathname] || [null, ''];

	return (
		<>
			<Icon data-cy='run-panel-icon'/>
			<Text data-cy='run-panel-title' size='small'>{text}</Text>
		</>
	);
}
