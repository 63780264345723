import * as React from 'react';
import type {SVGProps} from 'react';

export function Library(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M9 7a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4l4.009.01c.426 0 .872.196 1.383.667.199.184.437.414.599.629.18.24.415.697 1.009.694.594-.003.787-.407 1-.688.161-.198.354-.387.553-.571C18.065 23.27 18.574 23 19 23h4a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4h-4c-1.211 0-2.266.556-3 1.406C15.266 7.556 14.211 7 13 7H9Zm0 2h4a2 2 0 0 1 2 2l.006 10.649C14.382 21.244 13.712 21 13 21H9a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2Zm10 0h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-4c-.712 0-1.38.257-2.004.662L17 11a2 2 0 0 1 2-2Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M13 11.002H9v2h4v-2Zm0 3H9v2h4v-2Zm-4 3h4v2H9v-2Zm14-6h-4v2h4v-2Zm-4 3h4v2h-4v-2Zm4 3h-4v2h4v-2Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
