import {BaseButton} from '@esgi/ui';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const MeridiemButtonsDivider = styled(Box, {
	width: 1,
	height: '100%',
	backgroundColor: '$primaryMuted',
});

export const MeridiemButtonsContainer = styled(GridBox, {
	position: 'absolute',
	top: 0,
	bottom: 0,
	right: 12,
	gridAutoFlow: 'column',
	margin: 'auto',
	height: 'max-content',
	alignItems: 'center',

	'&:hover': {
		[`& ${MeridiemButtonsDivider}`]: {
			backgroundColor: '$secondaryMuted',
		},
	},
});

export const MeridiemButton = styled(BaseButton, {
	paddingTop: 4,
	paddingBottom: 4,
	paddingLeft: 6,
	paddingRight: 6,
	borderWidth: 1,
	borderStyle: 'solid',

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'&:hover': {
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',
		color: '$secondary',
	},

	variants: {
		meridiem: {
			AM: {
				borderTopLeftRadius: 6,
				borderBottomLeftRadius: 6,
				borderRight: 'none',
			},

			PM: {
				borderTopRightRadius: 6,
				borderBottomRightRadius: 6,
				borderLeft: 'none',
			},
		},

		isActive: {
			true: {
				'&:not(:hover)': {
					background: 'linear-gradient(272deg, #F3F9FC 48.17%, #F7FBFD 100%)',
					borderColor: '$primaryMuted',
					color: '$primary',
				},
			},

			false: {
				backgroundColor: '$background',
				borderColor: '$mild',
				color: '$neutral40',
			},
		},
	},
});
