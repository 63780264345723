import {Dispatch, memo, useCallback} from 'react';
import {NameCheckbox, StyledText, Row} from './index.styled';
import {Test} from '../../../../types';
import {TestColorIndicator} from '@esgi/main/kits/common';

type Props = Pick<Test, 'id' | 'name'> & {
	selected: boolean;
	onTestClick: Dispatch<Test['id']>;
	disabled: boolean;
};

function TestRowImpl({id, name, onTestClick, selected, disabled}: Props) {
	const handleCheckedChange = useCallback(() => {
		if (!disabled) {
			onTestClick(id);
		}
	}, [disabled, onTestClick, id]);

	return (
		<Row selected={selected} dataCy='test-row'>
			<NameCheckbox disabled={disabled} checked={selected} active={selected} onCheckedChange={handleCheckedChange} />
			<TestColorIndicator testID={id} />
			<StyledText disabled={disabled} size='medium' selected={selected}>
				{name}
			</StyledText>
		</Row>
	);
}

export const TestRow = memo(TestRowImpl);
