import React from 'react';
import './component.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

export class Props {
	onClick: () => void;
	onHoverMessage: string;
	className: string;
}

export default class NavigateButton extends React.PureComponent<Props> {
	render() {
		return <OnHoverTooltip message={this.props.onHoverMessage}>
			<div className={'carousel-button-container ' + this.props.className}>
				<div className='carousel-button' onClick={() => this.props.onClick()}>
					<div className='carousel-arrow '>
						<svg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M12.9675 19.0325L4.95253 11L12.9675 2.9675L10.5 0.5L3.05176e-05 11L10.5 21.5L12.9675 19.0325Z' fill='#BDBDBD'/>
						</svg>
					</div>
				</div>
			</div>
		</OnHoverTooltip>;
	}
}