import React from 'react';
import {join} from '@esgillc/ui-kit/utils';

import styles from './report-filters.module.less';

class Props {
	className?: string;
	children?: any;
}

export class ReportFiltersLabel extends React.PureComponent<Props> {
    render() {
    	return <div className={join(styles.reportFiltersLabel, this.props.className)}>
			{this.props.children}
		</div>;
    }
}
