import {Fragment, Suspense, useState} from 'react';
import {useUser} from '@esgi/core/authentication';
import {TestType} from '@esgi/core/enums';
import {TestModel} from '../../../../../../types';
import {lazyComponent} from '@esgi/core/react';
import styles from './styles.module.less';
import {ViewMode} from 'shared/modules/test-details/models';
import {useEventEffect} from '@esgillc/events';
import {AddToTestSubjectCancel} from 'pages/assignment-center/components/create-assignment/components/find-more-modal/events';

// eslint-disable-next-line @typescript-eslint/naming-convention
const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
// eslint-disable-next-line @typescript-eslint/naming-convention
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

interface Props {
	onSelected: (checked: boolean) => any;
	model: TestModel;
}

export function Row(props: Props) {
	const user = useUser();
	const [testDetailsOpened, setTestDetailsOpened] = useState<boolean>(false);
	const {name, id, date, questions, subject} = props.model;
	const [isChecked, setIsChecked] = useState<boolean>(false);

	useEventEffect(AddToTestSubjectCancel, (args) => {
		if (args.testID === props.model.id) {
			setIsChecked(false);
		}
	}, []);

	const checkedChanged = (checked: boolean) => {
		props.onSelected(checked);
		setIsChecked(checked);
	};

	const openTestDetails = () => {
		setTestDetailsOpened(true);
	};

	return (
		<tr data-name={name}>
			<td>
				<label>
					<input
						type='checkbox'
						className='ace'
						onChange={(e) => checkedChanged(e.target.checked)}
						checked={isChecked}
					/>
					<span className='lbl'/>
				</label>
			</td>
			<td className='name'>
				<a href='#' onClick={() => openTestDetails()}>
					{name}
				</a>
				{testDetailsOpened && props.model.type !== TestType.Rubric &&
					<Suspense fallback={<Fragment/>}>
						<TestDetails
							testID={id}
							firstName={user.firstName}
							lastName={user.lastName}
							userID={user.userID}
							close={() => setTestDetailsOpened(false)}
							disableCopyTest={true}
							viewMode={ViewMode.SelfAssess}
						/>
					</Suspense>
				}
				{testDetailsOpened && props.model.type === TestType.Rubric &&
					<Suspense fallback={<Fragment/>}>
						<RubricDetails
							testID={id}
							disableCopy
							disableAddToSubject
							close={() => setTestDetailsOpened(false)}
						/>
					</Suspense>
				}
			</td>
			<td className='create-date'>{date}</td>
			<td className='questions'>{questions}</td>
			<td className={styles.subjectTab}>{subject ? subject.name : 'N/A'}</td>
		</tr>
	);
}
