import {SessionDate} from '../../../../session-date';
import {ResultBox, SessionDetailsBox} from '../../index.styled';
import {StudentCell} from '../student-cell';
import {AnswersInfo} from '../../../../../../../kit';
import {ProgressLineBar} from '../../../../progress-line-bar';
import {DeleteRounded} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {StudentInfo} from '../../../../../../../class-group/types';
import {TestSession} from '../../../../../../../types';
import {TestButton, TestButtonStub} from './index.styled';

type WithTestedButton = {
	withTestButton: true;
	onTestClick: VoidFunction;
};

type WithoutTestedButton = {
	withTestButton?: false;
};

type Props = {
	student?: Omit<StudentInfo, 'testSessionsInfo'>;
	session: TestSession;
	sessionResult: {
		value: number;
		maxValue: number;
	};
	withProgressBar?: boolean;
	isTablet: boolean;
	onOpenTestSessionDetailsAlert: (sessionID: number) => void;
	onOpenStudentTestDashboard: VoidFunction;
	index: number;
} & (WithTestedButton | WithoutTestedButton);

export function StudentSingleSessionRow({
	session,
	student,
	sessionResult,
	isTablet,
	withProgressBar = true,
	onOpenTestSessionDetailsAlert,
	onOpenStudentTestDashboard,
	index,
	...props
}: Props) {
	return (
		<>
			{student && (
				<StudentCell
					firstName={student.firstName}
					lastName={student.lastName}
					withExpandedButton={false}
					onOpenStudentTestDashboard={onOpenStudentTestDashboard}
					index={index}
				/>
			)}
			<SessionDate dataCy={`session-date-${index}`} dateOnly={isTablet} date={session.testDate} isDeletedSession={session.isDeleted} />
			<ResultBox dataCy={`session-result-${index}`} withOpacity={session.isDeleted}>
				<AnswersInfo value={sessionResult.value} maxValue={sessionResult.maxValue} />
				{withProgressBar && <ProgressLineBar value={sessionResult.value} maxValue={sessionResult.maxValue} />}
			</ResultBox>
			<SessionDetailsBox dataCy={`session-buttons-${index}`}>
				{session.isDeleted && <DeleteRounded />}
				<Button dataCy='session-details-button' color='secondary' onClick={() => onOpenTestSessionDetailsAlert(session.id)}>
					<Text size='medium' bold>
						Session Details
					</Text>
				</Button>
				{props.withTestButton ? (
					<TestButton dataCy='test-button' color='primary' uppercase onClick={props.onTestClick}>
						<Text size='large'>Test</Text>
					</TestButton>
				) : (
					<TestButtonStub />
				)}
			</SessionDetailsBox>
		</>
	);
}
