import {CounterBox} from '@esgi/ui/layout';
import {SelectableListTriggerContent} from '../styled';
import {Text} from '@esgi/ui/typography';
import {ReactNode} from 'react';
import {OneLinedText} from '../../../../one-lined-text';

type WithCounter = {
	withCounter: true,
	count: number,
}

type WithoutCounter = {
	withCounter?: false,
}

type Props = {
	label: string,
	children?: ReactNode,
} & (WithCounter | WithoutCounter);

export function Trigger(props: Props) {
	const {
		label,
		withCounter,
		children,
	} = props;

	return (
		<SelectableListTriggerContent align='center' justify='start'>
			<OneLinedText size='large' css={{maxWidth: '153px'}}>{label}</OneLinedText>
			{withCounter && <CounterBox css={{marginLeft: 6}}>
				<Text size='small' font='mono'>
					{props.withCounter ? props.count : undefined}
				</Text>
			</CounterBox>}
			{children}
		</SelectableListTriggerContent>
	);
}
