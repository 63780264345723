import {useCallback, useState} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {Student} from '../../../../types/types';

type TableCellStudentModalProps = {
	studentId: Student['id'];
	studentName: string;
};

const StudentFormModal = lazyComponent(
	() => import('modules/forms/students-form'),
);

export function TableCellStudent({
	studentId,
	studentName,
}: TableCellStudentModalProps) {
	const [isShowStudentFormModal, setIsShowStudentFormModal] = useState(false);

	const onClose = useCallback(() => {
		setIsShowStudentFormModal(false);
	}, []);

	const openStudentFormModal = useCallback(() => {
		setIsShowStudentFormModal(true);
	}, []);

	return (
		<>
			<Buttons.Link onClick={openStudentFormModal} className={styles.button}>
				{studentName}
			</Buttons.Link>
			{isShowStudentFormModal && (
				<StudentFormModal
					studentID={studentId}
					onClose={onClose}
					preSelected={null}
				/>
			)}
		</>
	);
};
