import * as React from 'react';
import type {SVGProps} from 'react';

export function ListView(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6.00003 7.26228C6.00003 6.56514 6.56556 6 7.26319 6H16.7368C17.4345 6 18 6.56514 18 7.26228V9.23772C18 9.93486 17.4345 10.5 16.7368 10.5H7.26319C6.56556 10.5 6.00003 9.93486 6.00003 9.23772L6.00003 7.26228Z'
				fill='#333333'
			/>
			<path
				d='M6.00003 14.7623C6.00003 14.0651 6.56556 13.5 7.26319 13.5H16.7368C17.4345 13.5 18 14.0651 18 14.7623L18 16.7377C18 17.4349 17.4344 18 16.7368 18H7.26316C6.56554 18 6 17.4349 6 16.7377L6.00003 14.7623Z'
				fill='#333333'
			/>
		</svg>
	);
}
