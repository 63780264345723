import {useMemo} from 'react';
import {
	LevelModel,
	StudentResult,
	SortModel,
	SortByCriteriaResults,
	SortByCriteriaResultsOptions,
} from '../../../../../types/table-level-report-service-types';
import {Column, Cell, HeaderCell} from '@esgi/deprecated/ui-kit/report/table/types';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {SortableColumn} from '../components/sortable-column';
import {TableLevelReportService} from '../../../../../services/table-level-report-service';
import cellStyles from '../styles/cell.module.less';
import styles from '../styles/criteria-results-columns.module.less';

export function useCriteriaResultColumns({
	rows,
	levels,
	service,
	sortBy,
	sortByCriteriaResultsOptions,
}: {
	rows?: StudentResult[] | undefined;
	levels: LevelModel[] | undefined;
	service: TableLevelReportService;
	sortBy: SortModel;
	sortByCriteriaResultsOptions: SortByCriteriaResultsOptions | null;
}) {
	return useMemo<Column<StudentResult>[] | null>(() => {
		const levelLength = levels?.some(({score}) => score === 0) ? levels?.length - 1 : levels?.length;

		if (!rows?.length || !levelLength) {
			return null;
		}

		const criteriaResults = rows[0]!.criteriaResults;

		return criteriaResults.map((criteria) => {
			const outOf =
				criteria.criteriaName === 'Score'
					? criteriaResults.filter(({criteriaName}) => criteriaName !== 'Score').length * levelLength
					: levelLength;

			const topHeaderCell: HeaderCell = {
				renderer: () => (
					<div className={styles.criteriaTopHeaderColumn} key={criteria.criteriaID}>
						<p>{criteria.criteriaName}</p>
						<p className={styles.countOfLevels}>(out of {outOf})</p>
					</div>
				),
			};

			const bottomHeaderCells: HeaderCell[] = criteria.periodResults.map(({periodName, testSessionID}) => {
				const sortByType: Omit<SortByCriteriaResults, 'direction'> = {
					sortKey: 'criteriaResults',
					criteriaName: criteria.criteriaName,
					periodResultName: periodName,
				};

				return {
					renderer: () => (
						<div className={cellStyles.cell} key={testSessionID}>
							<SortableColumn
								sortByType={sortByType}
								title={periodName}
								service={service}
								sortBy={sortBy}
								sortByCriteriaResultsOptions={sortByCriteriaResultsOptions}
							/>
						</div>
					),
				};
			});

			const cells: Cell<StudentResult>[] = criteria.periodResults.map((p, i) => ({
				renderer: (row) => {
					const criteriaResult = row.criteriaResults.find(({criteriaName}) => criteriaName === criteria.criteriaName);
					const scoreStr = criteriaResult?.periodResults[i]?.value;
					const score = parseInt(scoreStr ?? '');

					if (criteriaResult?.criteriaName !== 'Score' && !isNaN(score) && score !== 0) {
						const firstLevel = levels.find((level) => level.score === score);

						const emptyOrNumberRegex = /^(\s*|\d+)$/;
						const levelIsNumber = firstLevel && emptyOrNumberRegex.test(firstLevel.name);

						if (firstLevel && !levelIsNumber) {
							return (
								<OnHoverTooltip key={i} message={firstLevel.name}>
									<div className={cellStyles.cell} data-cy='tooltiped-cell'>
										{score}
									</div>
								</OnHoverTooltip>
							);
						}
					}

					return (
						<div className={cellStyles.cell} key={i} data-cy='cell'>
							{scoreStr}
						</div>
					);
				},
			}));

			const studentResultColumns: Column<StudentResult> = {
				className: styles.criteriaColumn,
				headers: [[topHeaderCell], bottomHeaderCells],
				cells,
			};

			return studentResultColumns;
		});
	}, [rows, service, sortBy, levels]);
}
