import {useState} from 'react';
import {SortDirection, SortField, sortFieldLabels, SortingModel} from '../../sorting-model';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {SortIcon} from '@esgillc/ui-kit/icons';
import styles from './styles.module.less';

interface Props {
	sorting: SortingModel;
	sortingChange?: (sorting: SortingModel) => void;
}

export function Sorting({sorting: {field, direction}, sortingChange}: Props) {
	const [show, setShow] = useState(false);
	const onDirChange = () => {
		sortingChange?.({
			field,
			direction: direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc,
		});
	};

	return (
		<div className={styles.sortingContainer}>
			<span className={styles.textStyle}>Sort By</span>
			<div>
				<span
					className={styles.dropdownTitle}
					onClick={() => setShow(true)}
				>
					{sortFieldLabels[field]}
				</span>
				<Menu
					show={show}
					className={styles.menu}
					onClickOutside={() => setShow(false)}
				>
					{Object.values(SortField).map((field) => (
						<MenuItem
							key={field}
							onSelect={() => {
								sortingChange?.({field, direction});
								setShow(false);
							}}
						>
							{sortFieldLabels[field]}
						</MenuItem>
					))}
				</Menu>
			</div>
			<SortIcon
				direction={direction}
				onClick={onDirChange}
			/>
		</div>
	);
}

