import {Outlet} from 'react-router-dom';
import {LayoutContainer} from './index.styled';
import {ReportsPanel as NewReportsPanel} from './components/reports-panel';
import {Snackbar} from '@esgi/ui/snackbar';
import {useMemo} from 'react';
import {ReportsContext} from './outlet-context';
import {OldReportsPanel, withNewOldReports} from '../old-reports';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';

const ReportsPanel = withNewOldReports(OldReportsPanel, NewReportsPanel);

export function ReportsLayout() {
	const snackbarRef = Snackbar.useRef();

	const outletContext = useMemo<ReportsContext>(
		() => ({snackbarRef}),
		[snackbarRef],
	);

	return (
		<LayoutContainer>
			<ReportsPanel/>
			<Outlet context={outletContext}/>
			<Snackbar snackbarRef={snackbarRef}/>
			<BackgroundDownloadManager/>
		</LayoutContainer>
	);
}
