import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Layout, SectionBox} from './index.styled';
import {routes} from '@esgi/main/libs/core';
import {RightPanel} from '@esgi/main/kits/common';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {userStorage} from '@esgi/core/authentication';
import {AiAnalysis, Users} from '@esgi/ui';
import {StudentSelectionPanel} from './components/students-panel';
import {TestsSelectionPanel} from './components/tests-panel';
import {PreviewDrawer} from './components/preview-drawer';
import {SetupPanel} from './components/setup-panel';
import {ReportSelection} from './components/report-selection-panel';
import {ExitPreviewDialog} from './components/exit-preview-dialog';
import {GeneratePreviewDialog} from './components/generate-preview-dialog';
import {ExpandablePanel, Box} from '@esgi/ui/layout';
import {useAiAnalysis} from './hooks';
import {useAiAnalysisContext} from './layout/outlet-context';

export default function () {
	const navigate = useNavigate();
	const {service} = useAiAnalysis();
	const {
		studentsPanelOpen,
		toggleStudentsPanelOpen,
		setSelectedReportType,
		targetReportType,
		showPreviewDrawer,
		setShowPreviewDrawer,
		hasPreviewDrawerBeenOpened,
		setHasPreviewDrawerBeenOpened,
		showExitPreviewDialog,
		setShowExitPreviewDialog,
		showGeneratePreviewDialog,
		setShowGeneratePreviewDialog,
		setShowSelectionDrawer,
		setCanEdit,
		handleClosePreviewPanel,
		windowWidth,
	} = useAiAnalysisContext();

	const classes = useBehaviorSubject(service.studentServiceData.classes$);
	const initialSelectedStateSubjectId = useBehaviorSubject(service.selectedStateSubjectId$);
	const selectedSubjectLoaded = useBehaviorSubject(service.selectedStateSubjectLoaded$);
	const groups = useBehaviorSubject(service.studentServiceData.groups$);
	const students = useBehaviorSubject(service.studentServiceData.students$);
	const isTestsLoaded = useBehaviorSubject(service.isTestsLoaded$);
	const isTestsLoading = useBehaviorSubject(service.isTestsLoading$);
	const tests = useBehaviorSubject(service.tests$);
	const selectedTests = useBehaviorSubject(service.selectedTests$);
	const selectedStudent = useBehaviorSubject(service.selectedStudent$);

	const report = useBehaviorSubject(service.report$);
	const isReportLoading = useBehaviorSubject(service.isReportLoading$);
	const completedLangTypes = useBehaviorSubject(service.completedLangTypes$);
	const isTranslationsLoading = useBehaviorSubject(
		service.isTranslationsLoading$
	);
	const activeTranslations = useBehaviorSubject(service.activeTranslations$);

	const currentUser = userStorage.get();

	useEffect(() => {
		if (!selectedStudent) {
			handleClosePreviewPanel()
		}
	}, [selectedStudent])

	useEffect(() => {
		if (currentUser?.globalSchoolYearID < 38) {
			navigate(routes.teacher.aiAnalysis.setup);
		}
	}, [currentUser, navigate]);

	return (
		<Layout>
			<ExpandablePanel
				open={windowWidth >= 1024 || studentsPanelOpen}
				dataCy='students-panel'
				style={{width: 'max-content'}}
			>
				{({panelIsHovered, open}) => (
					<>
						<ExpandablePanel.Trigger
							onClick={windowWidth >= 1024 ? null : toggleStudentsPanelOpen}
							css={windowWidth >= 1024 ? {display: 'none'} : {}}
							disabled={windowWidth >= 1024}
						/>
						<ExpandablePanel.Placeholder>
							<SectionBox fillColor={panelIsHovered ? 'secondary' : 'primary'}>
								<Users data-cy='students-icon' width={24} height={24} />
							</SectionBox>
						</ExpandablePanel.Placeholder>
						<ExpandablePanel.Content style={{ height: 'calc(100% - 40px)' }}>
							<StudentSelectionPanel
								skeleton={!classes.length}
								classes={classes}
								groups={groups}
								students={students}
								onChange={(value) =>
									service.setSelectedStudent(value.studentId)
								}
							/>
						</ExpandablePanel.Content>
					</>
				)}
			</ExpandablePanel>
			<Box style={{minWidth: 'min-content', width: '100%'}}>
				{selectedSubjectLoaded && <TestsSelectionPanel
					selectedStudent={selectedStudent}
					onSubjectChange={(subject) => service.setSelectedSubject(subject)}
					isTestsLoading={isTestsLoading}
					isTestsLoaded={isTestsLoaded}
					tests={tests}
					setSelectedTests={(selectedEntities) =>
						service.setSelectedTests(selectedEntities)
					}
					initialSelectedSubjectId={initialSelectedStateSubjectId}
				/>}
			</Box>
			<RightPanel.Root style={{minWidth: '320px', maxWidth: '360px'}}>
				<RightPanel.Header
					sectionName='AI Analysis'
					Icon={AiAnalysis}
					withActionButton
					actionButtonDisabled={!selectedTests.length || !targetReportType}
					actionButtonText='Generate'
					onActionButtonClick={() => {
						service.handleGenerateReport(
							selectedStudent,
							selectedTests,
							targetReportType
						);
						setSelectedReportType(targetReportType);
						setShowPreviewDrawer(true);
					}}
				/>
				<RightPanel.Body>
					{hasPreviewDrawerBeenOpened ? <ReportSelection /> : <SetupPanel />}
				</RightPanel.Body>
			</RightPanel.Root>
			{selectedStudent && showPreviewDrawer && (
				<PreviewDrawer
					onClose={() => setShowExitPreviewDialog(true)}
					student={selectedStudent}
					report={report}
					isReportLoading={isReportLoading}
					handleDownload={(reportHTML, reportType, student) =>
						service.handleDownload(reportHTML, reportType, student)
					}
					completedLangTypes={completedLangTypes}
					handleTranslate={(report, completed, selected) =>
						service.handleTranslate(report, completed, selected)
					}
					isTranslationsLoading={isTranslationsLoading}
					activeTranslations={activeTranslations}
				/>
			)}
			{showExitPreviewDialog && (
				<ExitPreviewDialog
					onConfirm={() => {
						service.handleClosePreview();
						handleClosePreviewPanel();
						setHasPreviewDrawerBeenOpened(true);
					}}
					onCancel={() => setShowExitPreviewDialog(false)}
				/>
			)}
			{showGeneratePreviewDialog && (
				<GeneratePreviewDialog
					onConfirm={() => {
						setCanEdit(false);
						service.handleGenerateReport(
							selectedStudent,
							selectedTests,
							targetReportType
						);
						setSelectedReportType(targetReportType);
						setShowGeneratePreviewDialog(false);
						setShowSelectionDrawer(false);
					}}
					onCancel={() => setShowGeneratePreviewDialog(false)}
				/>
			)}
		</Layout>
	);
}
