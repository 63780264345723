import React from 'react';
import '../../component.less';
import {ValidationStatus} from '@esgi/core/enums';
import ValidationWrapper from '@esgi/deprecated/elements/validation-wrapper';
import EntryValue
	from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-entry/entry-value/component';
import {IGradeRangeForm} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/models';
import {FormField} from 'shared/modules/grade-scale/form-field/form-field';
import {
	GradeRangeEditorService,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/service';

export class State {
	withErrorTooltip: boolean = true;
	fromFocus: boolean;
	toFocus: boolean;
	disabled: boolean;
}

export class Props {
	service: GradeRangeEditorService;
	gradeRange: IGradeRangeForm;
	first: boolean;
	last: boolean;
	showValidation: boolean;
}

export default class GradeRangeEntry extends React.PureComponent<Props, State> {
	ref: HTMLDivElement;

	constructor(props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		this.props.service.disabled$.subscribe(disabled => {
			this.setState({disabled: disabled});
		});
		
		this.props.service.focusGradeRange$.subscribe(focusedGradeRange => {
			if (!focusedGradeRange || focusedGradeRange.gradeRange.gradeScaleEntryID !== this.props.gradeRange.gradeScaleEntryID) {
				if (this.state.toFocus) {
					this.setState({toFocus: false});
				}

				if (this.state.fromFocus) {
					this.setState({fromFocus: false});
				}

				return;
			}

			if (focusedGradeRange.gradeRange.gradeScaleEntryID === this.props.gradeRange.gradeScaleEntryID) {
				if (focusedGradeRange.field === 'from') {
					this.setState({
						fromFocus: true,
						toFocus: false,
					});
				}

				if (focusedGradeRange.field === 'to') {
					this.setState({
						fromFocus: false,
						toFocus: true,
					});
				}
			}
		});
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (this.isVisible()) {
			this.setState({withErrorTooltip: true});
		} else {
			this.setState({withErrorTooltip: false});
		}
	}

	onChange(field: string, value: string) {
		const newForm = {...this.props.gradeRange};
		newForm[field] = new FormField(value);
		this.props.service.updateForm(newForm);
	}

	isVisible() {
		if (this.ref) {
			const tableTopSide = 310;
			const containerHeight = 500;
			
			const tableBottomSide = tableTopSide + containerHeight;

			const position = this.ref.getBoundingClientRect();
			return tableTopSide < position.top && (position.bottom < tableBottomSide + 20);
		}

		return false;
	}

	renderArrow() {
		return <div className='arrow'>
			<svg width='38' height='31' viewBox='0 0 38 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M1 22.375V8.75H21.1306V2L37 15.5L21.1306 29V22.375H1Z' fill='#81D4F7' stroke='#81D4F7'/>
			</svg>
		</div>;
	}

	get validationMessage() {
		if (this.props.gradeRange.from.validation.status === ValidationStatus.Invalid) {
			return this.props.gradeRange.from.validation.message;
		}

		if (this.props.gradeRange.to.validation.status === ValidationStatus.Invalid) {
			return this.props.gradeRange.to.validation.message;
		}

		return '';
	}

	get showValidation() {
		return (this.props.gradeRange.from.validation.status === ValidationStatus.Invalid
				|| this.props.gradeRange.to.validation.status === ValidationStatus.Invalid)
			&& this.props.showValidation;
	}

	moveCursorToNextEntry(key: React.KeyboardEvent<HTMLInputElement>, gradeRange: IGradeRangeForm, field: string){
		if (key.code === 'Enter') {
			this.props.service.moveCursorToNextEmptyEntry(gradeRange, field);
		}	
	}

	render() {
		return <div className={this.props.last ? 'grade-range-entry last' : 'grade-range-entry'} ref={r => this.ref = r}>
			<div className='color-container' style={{backgroundColor: this.props.gradeRange.color}}>
				{this.props.gradeRange.name}
			</div>
			<ValidationWrapper
				message={this.validationMessage}
				showValidation={this.showValidation}
				withTooltip={this.state.withErrorTooltip}
			>
			<div className='grade-range-entry-container'>
				<div className='description-container'>{this.props.gradeRange.description}</div>
				<div className='entry-value-input'>
					<EntryValue
						onKeyUp={(key) => this.moveCursorToNextEntry(key, this.props.gradeRange, 'from')}
						editable={!this.props.last}
						disabled={this.state.disabled}
						value={this.props.gradeRange.from.value}
						onBlur={() => {
							this.props.service.clearFocusGradeRange();
							this.props.service.calculateGradeRange({gradeRange: this.props.gradeRange, field: 'from'});
						}}
						onChange={(value) => this.onChange('from', value)}
						focus={this.state.fromFocus}
					/>
				</div>
				{this.renderArrow()}
				<div className='entry-value-input'>
					<EntryValue
						onKeyUp={(key) => this.moveCursorToNextEntry(key, this.props.gradeRange, 'to')}
						editable={!this.props.first}
						disabled={this.state.disabled}
						value={this.props.gradeRange.to.value}
						onBlur={() => {
							this.props.service.clearFocusGradeRange();
							this.props.service.calculateGradeRange({gradeRange: this.props.gradeRange, field: 'to'});
						}}
						onChange={(value) => this.onChange('to', value)}
						focus={this.state.toFocus}
					/>
				</div>
			</div>
			</ValidationWrapper>
		</div>;
	}
}