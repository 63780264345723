export function ArrowIcon({className}: { className?: string }) {
	return (
		<svg className={className}
		     xmlns='http://www.w3.org/2000/svg'
		     version='1.0'
		     viewBox='0 0 437 416'
		     preserveAspectRatio='xMidYMid meet'
		>
			<g transform='translate(0,416) scale(0.1,-0.1)'
			   fill='#5F5F5D'
			   stroke='none'
			>
				<path d='M1879 3917 c-103 -29 -171 -88 -218 -187 l-26 -55 -3 -915 -3 -914 -387 386 c-439 439 -438 438
                     -573 438 -115 0 -164 -29 -321 -187 -153 -155 -178 -198 -178 -314 0 -141 -45 -90 912 -1045 963 -960 891
                     -899 1042 -892 60 2 93 9 127 27 34 17 280 256 890 867 950 949 909 902 909 1043 0 117 -28 165 -187 323
                     -155 153 -198 178 -313 178 -134 0 -134 0 -572 -438 l-388 -386 -2 434 c0 239 -2 651 -2 915 l-1 480 -33
                     67 c-37 76 -82 120 -162 157 -53 25 -62 26 -260 28 -142 2 -219 -1 -251 -10z'
				/>
			</g>
		</svg>
	);
}
