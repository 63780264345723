import {useCallback, useLayoutEffect, useMemo, useState} from 'react';
import {DasboardEntityType, URLParams} from '../types';
import {useClasses, useStudents, useGroups} from '@esgi/main/libs/store';
import {Entities, SingleSelectState, StudentsPanelTabID} from '@esgi/main/features/teacher/students-panel';
import {useNavigateToTestDashboard} from '../../utils';
import {isNull} from 'underscore';
import {useLocation} from 'react-router-dom';
import {NavigationState} from '../../types';

export function useTestDashboardState({urlParams}: {urlParams: URLParams | null}) {
	const [activeStudentTab, setActiveStudentTab] = useState(StudentsPanelTabID.Classes);
	const [isStudentPanelOpened, setIsStudentPanelOpened] = useState(false);
	const [selected, setSelected] = useState<SingleSelectState>({
		classId: null,
		groupId: null,
		studentId: null,
		studentFromClassId: null,
		studentFromGroupId: null,
	});

	const {state} = useLocation();
	const initVisibleSection = (state as NavigationState | null)?.initVisibleSection ?? null;

	const [{data: classes, loaded: classesLoaded}] = useClasses();
	const [{data: groups, loaded: groupsLoaded}] = useGroups();
	const [{data: students, loaded: studentListLoaded}] = useStudents();


	const entities = useMemo<Entities>(
		() => ({
			classes,
			groups,
			students,
		}),
		[classes, groups, students],
	);

	const isPanelLoaded = classesLoaded && groupsLoaded && studentListLoaded;

	const toggleStudentPanelOpened = useCallback(() => setIsStudentPanelOpened((prev) => !prev), []);

	useLayoutEffect(() => {
		if (urlParams?.entityType === DasboardEntityType.Class) {
			const {classID} = urlParams;

			setSelected({
				classId: classID,
				groupId: null,
				studentId: null,
				studentFromClassId: null,
				studentFromGroupId: null,
			});

			setActiveStudentTab(StudentsPanelTabID.Classes);

			return;
		}

		if (urlParams?.entityType === DasboardEntityType.Group) {
			const {groupID} = urlParams;

			setSelected({
				classId: null,
				groupId: groupID,
				studentId: null,
				studentFromClassId: null,
				studentFromGroupId: null,
			});

			setActiveStudentTab(StudentsPanelTabID.Groups);

			return;
		}

		if (urlParams?.entityType === DasboardEntityType.Student) {
			const {studentID, studentFromGroupID, studentFromClassID} = urlParams;

			if (studentFromClassID) {
				setActiveStudentTab(StudentsPanelTabID.Classes);
			}

			if (studentFromGroupID) {
				setActiveStudentTab(StudentsPanelTabID.Groups);
			}

			if (!studentFromClassID && !studentFromGroupID) {
				setActiveStudentTab(StudentsPanelTabID.Students);
			}

			setSelected({
				classId: null,
				groupId: null,
				studentId: studentID,
				studentFromClassId: studentFromClassID,
				studentFromGroupId: studentFromGroupID,
			});
		}
	}, [urlParams]);

	const handleOpenTestDashboard = useNavigateToTestDashboard();

	const onSetSelected = useCallback(
		(value: SingleSelectState) => {
			if (!isNull(urlParams)) {
				const {subjectID, subjectType, testID} = urlParams;

				if (value.classId) {
					handleOpenTestDashboard({
						dashboardType: 'class',
						classID: value.classId,
						subjectID,
						subjectType,
						testID,
					});

					return;
				}

				if (value.groupId) {
					handleOpenTestDashboard({
						dashboardType: 'group',
						groupID: value.groupId,
						subjectID,
						subjectType,
						testID,
					});

					return;
				}

				if (value.studentId) {
					handleOpenTestDashboard({
						dashboardType: 'student',
						studentID: value.studentId,
						subjectID,
						subjectType,
						testID,
						studentFromClassID: value.studentFromClassId,
						studentFromGroupID: value.studentFromGroupId,
					});

					return;
				}
			}
		},
		[handleOpenTestDashboard, urlParams],
	);

	return {
		activeStudentTab,
		setActiveStudentTab,
		isPanelLoaded,
		entities,
		selected,
		onSetSelected,
		isStudentPanelOpened,
		toggleStudentPanelOpened,
		initVisibleSection,
	};
}
