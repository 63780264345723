import {CroppedImage} from '@esgi/ui';
import {UserPhoto} from '../../../../../../types';

export function isPhotoAndControlledAvatarEqual({
	photo,
	controlledUserAvatar,
}: {
	photo: UserPhoto | null;
	controlledUserAvatar: CroppedImage | null;
}) {
	return (
		photo?.imageUrl === controlledUserAvatar?.image &&
		photo?.imageCropUrl === controlledUserAvatar?.croppedImage &&
		photo?.crop.x === controlledUserAvatar?.crop?.x &&
		photo?.crop.y === controlledUserAvatar?.crop?.y &&
		photo?.crop.zoom === controlledUserAvatar?.crop?.zoom
	);
}
