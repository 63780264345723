import {SettingsTab} from '../../types';
import {getGeneralItems} from './utils';

export const tabItems = {
	general: getGeneralItems(),

	testing: [
		{
			value: SettingsTab.KeyboardShortcuts,
			name: 'Keyboard Shortcuts',
		},
		{
			value: SettingsTab.TestButtonLabels,
			name: 'Test Button Labels',
		},
		{
			value: SettingsTab.StudentTestingOptions,
			name: 'Student Testing Options',
		},
	],

	testSessionResults: [
		{
			value: SettingsTab.TestResultVerbiage,
			name: 'Test Result Verbiage',
		},
		{
			value: SettingsTab.TestSessionResults,
			name: 'Test Session Results',
		},
	],
};


export const initialSettingsTabsData = {
	[SettingsTab.SystemSettings]: false,
	[SettingsTab.KeyboardShortcuts]: false,
	[SettingsTab.TestButtonLabels]: false,
	[SettingsTab.StudentTestingOptions]: false,
	[SettingsTab.TestResultVerbiage]: false,
	[SettingsTab.TestSessionResults]: false,
	[SettingsTab.GradeScales]: false,
	[SettingsTab.LanguagesAndTemplates]: false,
};
