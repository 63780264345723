import {Text} from '@esgi/ui/typography';
import {StyledCounterBox} from './index.styled';
import {VariantProps} from '@stitches/react';

type Props = {
	count: number;
	colorVariant: NonNullable<VariantProps<typeof StyledCounterBox>['colorVariant']>;
};

export function CounterBox({count, colorVariant}: Props) {
	return (
		<StyledCounterBox colorVariant={colorVariant}>
			<Text size='small' font='mono'>
				{count}
			</Text>
		</StyledCounterBox>
	);
}
