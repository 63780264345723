import {Validation} from './types';

export const maxSubjectNameLength = 35;

export const validations: Validation[] = [
	{
		showOnError: 'required',
		message: 'Subject name is required. Please enter a name.',
	},
	{
		showOnError: 'length-max',
		message: 'Subject name is too long. Please limit to 35 characters.',
	},
	{
		showOnError: 'isDublicateValue',
		message: 'This subject name already exists. Please choose a different name.',
	},
];
