import {LogoutEvent, userStorage} from '@esgi/core/authentication';
import {useService} from '@esgi/core/service';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useCallback} from 'react';
import {LogoutService} from './logout-service';
import {dispatchAppEvent} from '@esgillc/events';

export function Logout() {
	const service = useService(LogoutService);

	const handleLogout = useCallback(() => {
		service.logout().subscribe({
			next: () => {
				userStorage.clear();
				sessionStorage.clear();
				delete localStorage.shareScreenSessionCreated;
			},
			error: console.error,
			complete: () => {
				dispatchAppEvent(LogoutEvent, new LogoutEvent());
			},
		});
	}, [service]);

	return (
		<Button dataCy='logout-button' color='secondary' onClick={handleLogout}>
			<Text size='medium' bold>
				Logout
			</Text>
		</Button>
	);
}
