import {noop} from 'underscore';
import {TableStudents, TableStudentsRow} from './components.styled';
import {TableStudentsHeader} from './table-students-header';
import {SkeletonShape} from '@esgi/main/kits/common';
import {CellTestBarInfo} from '../../table-tests';
import {Box, GridBox} from '@esgi/ui/layout';

export function TableStudentsSkeleton() {
	return (
		<TableStudents>
			<TableStudentsHeader
				disableSortingCells='all'
				sortStudentsData={noop}
				studentsCount={1}
				testsCount={3}
				onOpenCredentialsForStudentsInAssignment={noop}
			/>

			<Box>
				{new Array(5).fill(null).map((_, index) => (
					<TableStudentsRow withBottomMargin key={index}>
						<SkeletonShape width='50%' height={20} />
						<SkeletonShape width='50%' height={20} />
						<SkeletonShape width='50%' height={20} />
						<CellTestBarInfo>
							<SkeletonShape width={40} height={16} />
							<GridBox
								css={{
									width: '100%',
									gap: 4,
									gridTemplateColumns: 'repeat(3, 1fr)',
								}}
							>
								{new Array(3).fill(null).map((_, indexProgress) => (
									<SkeletonShape width='100%' height={6} key={`${index}-${indexProgress}`} />
								))}
							</GridBox>
						</CellTestBarInfo>
						<SkeletonShape />
					</TableStudentsRow>
				))}
			</Box>
		</TableStudents>
	);
}
