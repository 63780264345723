import {useState} from 'react';
import {FormElement} from '@esgillc/ui-kit/form';
import {Checkbox, Radio} from '@esgillc/ui-kit/form-control';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {AutoDimension} from '@esgi/deprecated/react';
import {SessionType} from '@esgi/core/enums';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import Section from '../index';

const tooltipText = `If Force Test Type is checked, all tests in this subject tab will be administered as either "testing only incorrect
            				 items" or "retesting all items". If Force Test Type is unchecked, teachers will have both options.`;

interface Props {
	form: DistrictFormType | SchoolFormType;
}

export default function ForceTestTypeSection({form}: Props) {
	const [isChecked, setChecked] = useState(form.controls.forceTest.value);
	useStreamEffect(form.controls.forceTest.onChanged, ch => {
		if (ch.reason === 'value') {
			setChecked(ch.currState.value);
		}
	});


	return <Section data-cy={'force-test-type-section'} title='Force Test Type' tooltipText={tooltipText}>
		<AutoDimension fixWidth={true}>
			<div>
				<FormElement control={form.controls.forceTest}>
					<Checkbox id='force_type'/>
				</FormElement>
				{isChecked && <div data-cy={'force-test-types'}>
					<FormElement control={form.controls.sessionType}>
						<Radio value={SessionType.TestIncorrect} id='forceTestType_incorrect'>
							Incorrect and not tested
						</Radio>
						<Radio value={SessionType.TestAll} id='forceTestType_untested'>
							Retest All
						</Radio>
					</FormElement>
				</div>}
			</div>
		</AutoDimension>
	</Section>;
}

