import {BaseService} from '@esgi/core/service';
import {Observable, of, tap} from 'rxjs';
import {
	V2TeachersPagesHomeWidgetsByStudentController,
	V2TeachersPagesHomeWidgetsByStudentsController,
} from '@esgi/contracts/esgi';
import {clone, isEqual} from 'underscore';

import {ClassBundle, StudentBundle} from './types';

export abstract class WidgetService<StudentResponse, ClassResponse> extends BaseService {
	protected studentsController = new V2TeachersPagesHomeWidgetsByStudentController();
	protected classController = new V2TeachersPagesHomeWidgetsByStudentsController();

	protected studentsCache = new CacheMap<StudentBundle, StudentResponse>();
	protected classesCache = new CacheMap<ClassBundle, ClassResponse>();

	public override dispose() {
		super.dispose();
		this.studentsController.dispose();
		this.classController.dispose();
	}

	public getStudentData(request: StudentBundle): Observable<StudentResponse> {
		const cachedValue = this.studentsCache.get(request);

		if(cachedValue) {
			return of(cachedValue);
		}

		if (!request.testIDs.length) {
			return new Observable<StudentResponse>();
		}

		return this.requestStudentData(request).pipe(tap(response => this.studentsCache.set(request, response)));
	}

	public getClassData(request: ClassBundle): Observable<ClassResponse> {
		const cachedValue = this.classesCache.get(request);

		if(cachedValue) {
			return of(cachedValue);
		}

		if (!request.testIDs.length) {
			return new Observable<ClassResponse>();
		}

		return this.requestClassData(request).pipe(tap(response => this.classesCache.set(request, response)));
	}

	public clearCache(): void {
		this.classesCache.clear();
		this.studentsCache.clear();
	}

	protected abstract requestStudentData(request: StudentBundle): Observable<StudentResponse>;

	protected abstract requestClassData(request: ClassBundle): Observable<ClassResponse>;
}

class CacheMap<Key, Value> {
	protected store = new Map<Key, Value>();

	public set(key: Key, value: Value) {
		const kCopy = clone(key);
		const vCopy = clone(value);
		this.store.set(kCopy, vCopy);
	}

	public get(key: Key): Value | undefined {
		for (const entry of this.store.entries()) {
			const [entryKey, value] = entry;
			if(isEqual(entryKey, key)) {
				return value;
			}
		}
	}

	public clear() {
		this.store.clear();
	}
}