import './checkbox-options.less';
import React from 'react';

class Props {
    inputClassName?: string;
    className?: string;
    disabled?: boolean;
    onClick: (checked: boolean) => any;
    checked: CheckedMode;
}

class State {
    open: boolean = false;
    arrowUp: boolean = false;
}

export enum CheckedMode {
    None = 0,
    All = 1,
    Partially = 2
}

/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class CheckboxOptions extends React.PureComponent<Props, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

    private html: HTMLElement;

    renderCheckbox() {
    	return <>
            <div className='checkbox-component' onClick={() => {
                this.checkboxClicked();
            }}>
								<input
									disabled={this.props.disabled}
									className={this.getClass()}
									type='checkbox'
									checked={this.props.checked === CheckedMode.All || this.props.checked === CheckedMode.Partially}
									onChange={() => {}}
								/>
                <label className='lbl options-lbl'/>
            </div>
            <div className='selector-arrow' onClick={() => {
                this.toggleSelector();
            }}>
                <svg className='svg-arrow'>
                    <path d='M0 0L5 5L10 0H0Z' fill='#828282'/>
                </svg>
            </div>
    	</>;
    }

    getClass() {
    	let className = 'ace';
    	if (this.props.inputClassName) {
    		className += ' ' + this.props.inputClassName;
    	}
    	if (this.props.checked === CheckedMode.Partially) {
    		className += ' minus';
    	}
    	return className;
    }

    checkboxClicked() {
    	if (!this.props.disabled) {
    		if (this.props.checked === CheckedMode.None) {
    			this.props.onClick(true);
    		}
    		if (this.props.checked === CheckedMode.All || this.props.checked === CheckedMode.Partially) {
    			this.props.onClick(false);
    		}
    	}
    }

    toggleSelector() {
    	if (!this.props.disabled) {
    		this.setState((prevState) => {
    			const open = !prevState.open;
    			return {
    				...this.state,
    				open: open,
    				arrowUp: open,
    			};
    		});
    	}
    }

    selectorClicked(all: boolean) {
    	if (!this.props.disabled) {
    		this.setState((prevState) => {
    			const open = !prevState.open;
    			return {
    				...this.state,
    				open: open,
    				arrowUp: open,
    			};
    		}, () => this.props.onClick(all));
    	}
    }

    componentDidMount() {
    	document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    componentWillUnmount() {
    	document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    handleClickOutside(event) {
    	if (this.html && !this.html.contains(event.target)) {
    		this.toggleSelector();
    	}
    }

    render() {
    	const className = this.props.className ? this.props.className : 'checkbox-options-component';
    	if (this.state.open) {
    		return <div className={className} ref={r => this.html = r}>
                {this.renderCheckbox()}
                {this.renderSelector()}
            </div>;
    	} else {
    		return <div className={className}>
                {this.renderCheckbox()}
            </div>;
    	}
    }

    renderSelector() {
    	return <div className='checkbox-options-selector'>
            <div
                className={'checkbox-options-item all-option' + (this.props.checked === CheckedMode.All ? ' selected' : '')}
                onClick={(e) => {
                    this.selectorClicked(true);
                }}>All
            </div>
            <div
                className={'checkbox-options-item none-option' + (this.props.checked === CheckedMode.None ? ' selected' : '')}
                onClick={(e) => {
                    this.selectorClicked(false);
                }}>None
            </div>
        </div>;
    }
}
