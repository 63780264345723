import React, {createRef} from 'react';
import {CloseIcon, Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import styles from './details.module.less';

interface Props {
	notes: string;
	criteriaName: string;
	levelName: string;
	onClose: () => void;
}

export default class NoteReadonlyDialog extends React.PureComponent<Props> {
	private modalManagerRef: ModalManagerRef = createRef();

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader>
				<Title>
					{`Notes: ${this.props.criteriaName} | ${this.props.levelName}`}
				</Title>
				<CloseIcon onClick={() => this.modalManagerRef.current.close(() => this.props.onClose())}/>
			</ModalHeader>
			<ModalBody className={styles.body}>
				<div className={styles.inputContainer}>
					{this.props.notes}
				</div>
			</ModalBody>
			<ModalFooter/>
		</Modal>;
	}
}
