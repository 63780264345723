import * as React from 'react';
import type {SVGProps} from 'react';

export function AddTests(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none' xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path fillRule='evenodd' clipRule='evenodd' d='M4.875 14.25C4.875 15.9068 6.21818 17.25 7.875 17.25H16.125C17.7818 17.25 19.125 15.9068 19.125 14.25V9.75C19.125 8.09317 17.7818 6.75 16.125 6.75L7.875 6.75C6.21817 6.75 4.875 8.09317 4.875 9.75L4.875 14.25ZM6.375 14.25L6.375 9.75C6.375 8.92155 7.04655 8.25 7.875 8.25L16.125 8.25C16.9534 8.25 17.625 8.92155 17.625 9.75V14.25C17.625 15.0785 16.9534 15.75 16.125 15.75H7.875C7.04655 15.75 6.375 15.0785 6.375 14.25Z' fill='#8F8F8F'/>
			<path d='M8.25 19.5C8.25 19.0858 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 19.0858 15.75 19.5C15.75 19.9142 15.4142 20.25 15 20.25H9C8.58579 20.25 8.25 19.9142 8.25 19.5Z' fill='#8F8F8F'/>
			<path d='M9 3.75C8.58579 3.75 8.25 4.08579 8.25 4.5C8.25 4.91421 8.58579 5.25 9 5.25L15 5.25C15.4142 5.25 15.75 4.91421 15.75 4.5C15.75 4.08579 15.4142 3.75 15 3.75L9 3.75Z' fill='#8F8F8F'/>
			<path d='M12 14.25C11.6688 14.25 11.4 13.9812 11.4 13.65V12.6H10.35C10.0188 12.6 9.75 12.3312 9.75 12C9.75 11.6688 10.0188 11.4 10.35 11.4H11.4V10.35C11.4 10.0188 11.6688 9.75 12 9.75C12.3312 9.75 12.6 10.0188 12.6 10.35V11.4H13.65C13.9812 11.4 14.25 11.6688 14.25 12C14.25 12.3312 13.9812 12.6 13.65 12.6H12.6V13.65C12.6 13.9812 12.3312 14.25 12 14.25Z' fill='#8F8F8F'/>
		</svg>
	);
}
