import {useUser} from '@esgi/core/authentication';
import {useMemo} from 'react';
import {isNull} from 'underscore';

export function useInitialUserDate() {
	const currentUser = useUser();

	return useMemo(() => {
		const currentUserDate = new Date();

		if (isNull(currentUser)) {
			return currentUserDate;
		}

		const dateInTimeZone = currentUserDate.toLocaleString('en-US', {
			timeZone: currentUser.timeZone,
		});

		return new Date(dateInTimeZone);
	}, [currentUser]);
}
