import React, {ReactNode} from 'react';

export class Props {
	onClick: () => void;
	selected: boolean;
	children?: ReactNode;
}

export class ToolButton extends React.PureComponent<Props> {
	render() {
		return <div
			onMouseDown={(e) => e.preventDefault()}
			onMouseUp={(e) => {
				e.preventDefault();
				this.props.onClick();
			}}
			className={this.props.selected ? 'toolbar-item selected' : 'toolbar-item'}>
			{this.props.children}
		</div>;
	}
}
