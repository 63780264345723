ko.bindingHandlers.bsTooltip = {
    init: function (element, valueAccessor) {
        let local = ko.utils.unwrapObservable(valueAccessor()), options = {className:'esgi-tooltip'};

        ko.utils.extend(options, ko.bindingHandlers.bsTooltip.options);
        ko.utils.extend(options, local);
        $(element).addClass("esgi-tooltip");
        new KnockoutOnHoverTooltip(element, {...options, trigger: 'manual'});
        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(element).bstooltip("destroy");
        });
    },
    options: {
        placement: "right",
        viewport: "body",
    },
};

class KnockoutOnHoverTooltip {
    private isReady: boolean;
    private isHide: boolean = true;
    private showTooltipTimerID?: number;
    private hideTooltipTimerID?: number;

    private toggleTooltip(show: boolean) {
        if (this.showTooltipTimerID) {
            clearTimeout(this.showTooltipTimerID);
            this.showTooltipTimerID = null;
        }
        if (this.hideTooltipTimerID) {
            clearTimeout(this.hideTooltipTimerID);
            this.hideTooltipTimerID = null;
        }
        if (show) {
            if (this.isHide) {
                this.showTooltipTimerID = window.setTimeout(() => {
                    $(this.element).bstooltip('show');
                    this.showTooltipTimerID = null;
                    this.isHide = false;
                }, this.showDelay);
            }
        } else {
            if (!this.isHide) {
                this.hideTooltipTimerID = window.setTimeout(() => {
                    $(this.element).bstooltip('hide');
                    this.hideTooltipTimerID = null;
                    this.isHide = true;
                }, this.hideDelay);
            }
        }
    }

    constructor(private element, options: any, private showDelay: number = 700, private hideDelay: number = 200) {
        $(element).bstooltip(options);
        $(element).on('mouseenter', () => this.toggleTooltip(true));
        $(element).on('mouseleave', () => this.toggleTooltip(false));
        $(element).on('inserted.bs.tooltip', (e) => {
            const tooltipID = e.target.attributes.getNamedItem('aria-describedby');
            if (tooltipID) {
                const tooltipEl = $('#' + tooltipID.value);
                if (options.className) {
                    tooltipEl.addClass(options.className);
                }
            }
        });
        $(element).on('shown.bs.tooltip', (e) => {
            if (!this.isReady) {
                const tooltipID = e.target.attributes.getNamedItem('aria-describedby');
                if (tooltipID) {
                    const tooltipEl = $('#' + tooltipID.value);
                    if (tooltipEl[0]) {
                        tooltipEl.on('mouseenter', () => {
                            clearTimeout(this.hideTooltipTimerID);
                        });

                        tooltipEl.on('mouseleave', () => {
                            this.toggleTooltip(false);
                        });
                    } else {
                        console.error('Tooltip root element not defined');
                    }
                    this.isReady = true;
                }
            }
        })
    }
}