import {Text} from '@esgi/ui/typography';
import {AnswersInfoBox, Container} from './index.styled';

type Props = {
	value: number | null;
	maxValue: number;
	transcriptText: string;
};

export function AnswersInfo({value, maxValue, transcriptText}: Props) {
	return (
		<Container>
			<AnswersInfoBox data-cy='answer-info-value'>
				<Text size='medium' bold font='mono' color='base'>
					{value ?? '-'}
				</Text>
				<Text size='xSmall' font='mono' color='lowContrast'>
					/{maxValue}
				</Text>
			</AnswersInfoBox>

			<Text size='xSmall' font='mono' color='mediumContrast' data-cy='answer-info-caption'>
				{transcriptText}
			</Text>
		</Container>
	);
}
