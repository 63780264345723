import {Button, Skeletonable} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = Skeletonable & {
	onSave: VoidFunction;
	saveDisabled: boolean;
};

export function AlertFooter({onSave, saveDisabled, skeleton}: Props) {
	return (
		<Alert.Footer display='grid'>
			<GridBox align='center' justify='end' gap='4'>
				<Button
					color='secondary'
					disabled={saveDisabled || skeleton}
					onClick={onSave}
					skeleton={skeleton}
					dataCy='add-button'
				>
					<Text size='medium' bold>
						Add
					</Text>
				</Button>
			</GridBox>
		</Alert.Footer>
	);
}
