import {
	TestAddedToSubjectEvent,
	TestMovedBetweenSubjectsEvent,
	TestRemovedFromSubjectEvent,
} from 'api/entities/events/subject';
import {clearDebounce} from '@esgi/deprecated/knockout';
import {RubricSessionChangedEvent} from 'modules/assessments';
import React from 'react';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SubjectType, TestType} from '@esgi/core/enums';
import {Loader} from '@esgi/deprecated/jquery';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {AddTestClosedEvent} from 'shared/modules/home-add-test/events';
import {
	TestMovedToSubjectByMSATEvent,
	TestsReorderedByMSATEvent,
} from 'modules/manage-subjects-and-tests/events';
import {TestSubjectsChanged, TestBackgroundChangedEvent, TestQuestionListChanged} from 'shared/modules/test-details/events';
import * as TestDetailsEvents from 'shared/modules/test-details/events';
import {TestSessionDetailsChanged} from 'shared/modules/test/test-session-details/events';
import {TestSessionDetailsChanged as TestSessionDetailsChangedNew} from 'modules/forms/test-session-details-form/events';
import {
	TeacherAndSpecialistTestResultsVerbiagesChanged,
} from 'shared/modules/user-settings/teacher-and-specialist/events';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {userStorage, UserType} from '@esgi/core/authentication';
import {OsChecker, join} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import PieChartsService from '../../services/pie-charts-service';
import {SubjectModel} from '../../services/subjects-service/models';
import AddTestCard from './add-test-card/add-test-card';
import Card from './card/card';
import cardStyles from './card/card.module.less';
import {PieChartReorderedEvent} from './events';
import {PieChartModel} from './models';
import {StudentRemovedEvent} from 'modules/forms/students-form/events';
import {UserSettingsChangedEvent} from 'shared/modules/user-settings/events';
import {IEPGoalCreated} from 'modules/forms/iep-goal-form/events';
import {HttpClient} from '@esgi/api';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import './pie-charts.less';

class State {
	loading?: boolean = false;
	noStudents?: boolean = false;
	noTests?: boolean = false;
	pieCharts?: PieChartModel[] = [];
	canTest: boolean = false;
	dragging: boolean = false;
	testResultsCorrectVerbiage: string = '';
	testResultsIncorrectVerbiage: string = '';
	currentSubject: SubjectModel;
	hierarchy: HierarchyInstance;
	testType?: TestType = null;
	selfAssessEnabled: boolean;
}

class Props {
	hierarchy: HierarchySnapshot;
	pieChartsService: PieChartsService;
	disableCards: boolean;
	rightPanelFixed: boolean;
	onReloaded?: (info: { canTest: boolean, testCount: number }) => void;
}

const testTypes = [
	{id: null, name: 'All'},
	{id: TestType.YN, name: 'Yes/No'},
	{id: TestType.Score, name: 'Single Score'},
	{id: TestType.Rubric, name: 'Rubric'},
];

const os = OsChecker.isMac() && 'mac' || '';

export default class PieCharts extends React.Component<Props, State> {
	view = {
		reload: () => this.reloadPieCharts(),
	};

	public override readonly state = new State();
	private onDestroy$: Subject<void> = new Subject();

	private readonly eventBus: EventBusManager = new EventBusManager();
	private pieChartsRef: HTMLDivElement;
	private ref: HTMLDivElement;
	private loader: Loader = null;

	public componentDidMount() {
		this.loader = new Loader('#app', {whiteBackground: true});

		this.props.pieChartsService.busy$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((loading) => this.setState(
				{loading},
				() => this.loader[loading ? 'mask' : 'unmask'](),
			));

		this.props.pieChartsService.pieChartsInfo$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((r) => {
				let {testType} = this.state;
				const hasSameType = r.pieCharts.filter(
					({testType: type}) => TestType[type] === testType,
				).length;
				if (!hasSameType) {
					testType = null;
				}
				this.setState(
					{...r, testType},
					() => this.props.onReloaded && this.props.onReloaded({
						canTest: this.state.canTest,
						testCount: this.state.pieCharts.length,
					}),
				);
			});

		this.switchDrag();

		this.eventBus.subscribe(
			TestSubjectsChanged,
			({subjectIDs}: TestSubjectsChanged) => {
				const {id} = this.state.currentSubject;
				if (subjectIDs.includes(id)) {
					return;
				}

			},
		);

		this.eventBus.subscribe(
			AddTestClosedEvent,
			({lastSelectedSubject, changedSubjectIds}: AddTestClosedEvent) => {
				// user added a tests to this subject and then switched the subject
				// this may end up wrong pie charts loaded
				// since we know that the subject was changed, therefore pie charts will be reloaded on subject change event
				// therefore we don't realod here
				// see ESGI-17006
				const {id} = this.state.currentSubject;
				if (lastSelectedSubject?.id !== id) {
					return;
				}

				if (changedSubjectIds.indexOf(id) > -1) {
					this.reloadPieCharts();
				}
			},
		);

		this.eventBus.subscribe(
			TestRemovedFromSubjectEvent,
			({subjectID, testID}: TestRemovedFromSubjectEvent) => {
				const {currentSubject, canTest, pieCharts: charts} = this.state;
				if (currentSubject.id === subjectID) {
					const pieCharts = charts.filter(({testID: id}) => id !== testID);
					this.setState(
						{pieCharts},
						() => this.props.onReloaded({
							canTest,
							testCount: pieCharts.length,
						}),
					);
				}
			},
		);

		this.eventBus.subscribe(
			TestAddedToSubjectEvent,
			({subjectID, testID}: TestAddedToSubjectEvent) => {
				const {currentSubject, canTest, pieCharts: charts} = this.state;
				if (currentSubject.id === subjectID) {
					const pieCharts = charts.filter(({testID: id}) => id !== testID);
					this.setState(
						{pieCharts},
						() => this.props.onReloaded({
							canTest,
							testCount: this.state.pieCharts.length,
						}),
					);
				}
			},
		);

		this.eventBus.subscribe(
			TestsReorderedByMSATEvent,
			({subjectID, testIDs}: TestsReorderedByMSATEvent) => {
				const {currentSubject, pieCharts: charts} = this.state;
				if (subjectID === currentSubject.id) {
					const pieCharts = [...charts].sort(
						(a, b) => testIDs.indexOf(a.testID) - testIDs.indexOf(b.testID),
					);
					this.setState({pieCharts});
				}
			},
		);

		this.eventBus.subscribe(
			TestMovedToSubjectByMSATEvent,
			({prevSubjectID, newSubjectID}: TestMovedToSubjectByMSATEvent) => {
				const {id} = this.state.currentSubject;
				if (prevSubjectID === id || newSubjectID === id) {
					this.reloadPieCharts();
				}
			},
		);

		this.eventBus.subscribe(
			TestBackgroundChangedEvent,
			({id, isWhiteBackground}: TestBackgroundChangedEvent) => {
				const pieCharts = this.state.pieCharts.map((x) => x.testID === id
					? {...x, isWhiteBackground}
					: x,
				);
				this.setState({pieCharts});
			},
		);

		this.eventBus.subscribe(
			StudentRemovedEvent,
			() => {
				const {mode, classic, specialist} = this.state.hierarchy;
				if ((
					mode === HierarchyMode.Classic
					&& classic.selected.level !== ClassicHierarchyLevel.Student
				) || (
					mode === HierarchyMode.Specialist
					&& specialist.selected.level !== SpecialistHierarchyLevel.Student
				)) {
					this.reloadPieCharts();
				}
			},
		);

		this.eventBus.subscribe(
			TeacherAndSpecialistTestResultsVerbiagesChanged,
			() => this.reloadPieCharts(),
		);

		this.eventBus.subscribe(
			TestSessionDetailsChanged,
			() => this.reloadPieCharts(),
		);

		this.eventBus.subscribe(
			TestSessionDetailsChangedNew,
			() => this.reloadPieCharts(),
		);

		this.eventBus.subscribe(
			RubricSessionChangedEvent,
			() => this.reloadPieCharts(),
		);

		this.eventBus.subscribe(
			TestMovedBetweenSubjectsEvent,
			({sourceSubjectID, destinationSubjectID}: TestMovedBetweenSubjectsEvent) => {
				const {id} = this.state.currentSubject;
				if (id === sourceSubjectID || id === destinationSubjectID) {
					this.reloadPieCharts();
				}
			},
		);

		this.eventBus.subscribe(
			TestQuestionListChanged,
			({id}: TestDetailsEvents.TestQuestionListChanged) => {
				if (this.state.pieCharts.filter(({testID}) => testID === id).length) {
					this.reloadPieCharts();
				}
			},
		);

		this.eventBus.subscribe(
			IEPGoalCreated,
			() => this.reloadPieCharts(),
		);

		this.eventBus.subscribe(
			UserSettingsChangedEvent,
			(args: UserSettingsChangedEvent) => {
				if (args.showSelfAssessOption !== this.state.selfAssessEnabled) {
					this.setState({selfAssessEnabled: args.showSelfAssessOption});
				}
			},
		);

		this.setState({selfAssessEnabled: userStorage.get().showSelfAssessOption});

		this.reloadPieCharts();
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}

	public componentDidUpdate() {
		this.switchDrag();
	}

	public render() {
		return <div className='pie-charts-wrapper' ref={(r) => this.ref = r}>
			{this.renderHeader()}
			<div
				className={join(this.state.dragging && 'draggable', os, 'pie-charts')}
				ref={(r) => this.pieChartsRef = r}
			>
				{this.renderNoTests()}
				{this.renderPieCharts()}
				{this.renderAddTest()}
			</div>
		</div>;
	}

	public renderHeader() {
		if (this.state.currentSubject) {
			return <header className='header'>
				{this.renderSubjectSubHeader()}
				{this.renderTestTypeSubHeader()}
			</header>;
		}
	}

	public renderSubjectSubHeader() {
		return <span className='subject-header sub-header'>
			<span>Subject Tab:</span>
			<span className='subject-name'>{this.state.currentSubject.name}</span>
		</span>;
	}

	public renderTestTypeSubHeader() {
		const {pieCharts, testType} = this.state;
		return <span className='sub-header'>
			<span>View by Test Type:</span>
			{testTypes
				.filter(({id}) => (
					id === null
					|| pieCharts.filter(({testType: type}) => TestType[type] === id).length
				))
				.map(({id, name}) => (
					<button
						key={id}
						className={join('test-type-option', testType === id && 'selected')}
						onClick={() => this.selectTestType(id)}
					>
						{name}
					</button>
				))
			}
		</span>;
	}

	public renderAddTest() {
		if (
			this.state.testType === null
			&& this.state.currentSubject
			&& this.state.currentSubject.canEdit
		) {
			return <AddTestCard hierarchy={this.props.hierarchy} subjectId={this.state.currentSubject.id}/>;
		}
	}

	public renderNoTests() {
		if (!this.state.loading	&& !this.state.currentSubject) {
			return <div className='resultsOverlay'>
				<div className='test-results-message'>
					There are no Subject Tabs with tests available for the student(s) selected.
					Please add a Subject Tab.
					<OnHoverTooltip message='Help'>
						<a
							target='_blank'
							href='https://support.esgisoftware.com/hc/en-us/articles/209160046-Adding-a-Subject-Tab'
							rel='noreferrer'
						>
							<i className='fa fa-question-circle'/>
						</a>
					</OnHoverTooltip>
				</div>
			</div>;
		}
	}

	public renderPieCharts() {
		const {noTests, currentSubject, testType} = this.state;
		if (noTests || !currentSubject) {
			return;
		}

		return this.state.pieCharts
			.filter(({testType: type}) => [null, TestType[type]].includes(testType))
			.map((p) => (
				<Card
					key={p.testID}
					selfAssessEnabled={this.state.selfAssessEnabled}
					initModel={p}
					canDrag={this.canDrag()}
					disabled={this.props.disableCards || this.state.loading}
					hierarchy={this.state.hierarchy}
					canTest={this.state.canTest}
					subject={this.state.currentSubject}
					testResultsIncorrectVerbiage={this.state.testResultsIncorrectVerbiage}
					testResultsCorrectVerbiage={this.state.testResultsCorrectVerbiage}
					reloadPieCharts={() => this.reloadPieCharts()}
				/>
			));
	}

	private canDrag(): boolean {
		if (!this.state.currentSubject || this.state.testType != null) {
			return false;
		}

		if ('ontouchstart' in document.documentElement) {
			return false;
		}

		switch (userStorage.get().userType) {
			case UserType.T:
			case UserType.ISD:
			case UserType.ISS:
				return this.state.currentSubject.type === SubjectType.Personal;

			case UserType.C:
				const cc = this.state.hierarchy.classic.selected;
				return cc.level === ClassicHierarchyLevel.School
					&& this.state.currentSubject.level === 'School'
					&& this.state.currentSubject.type === SubjectType.Deployed;

			case UserType.D:
				const cd = this.state.hierarchy.classic.selected;
				return cd.level === ClassicHierarchyLevel.District
					&& this.state.currentSubject.level === 'District'
					&& this.state.currentSubject.type === SubjectType.Deployed;

			default:
				return false;
		}
	}

	private reloadPieCharts(): void {
		if (this.state.currentSubject) {
			this.props.pieChartsService.loadPieCharts(
				this.state.currentSubject,
				this.state.hierarchy,
			).subscribe(() => this.selectTestType(this.state.testType));
		} else {
			this.clear();
		}
	}

	private clear(): void {
		this.setState({pieCharts: [], noTests: true});
		clearDebounce(this, 'loadPieCharts');
	}

	private selectTestType(testType?: TestType) {
		this.setState({testType});
	}

	private switchDrag(): void {
		if (this.canDrag()) {
			this.turnDragOn();
		} else {
			this.turnDragOff();
		}
	}

	private turnDragOff(): void {
		if (this.state.dragging) {
			$(this.pieChartsRef).sortable('destroy');
			this.setState({dragging: false});
		}
	}

	private turnDragOn(): void {
		const {dragging, pieCharts} = this.state;
		if (dragging || pieCharts.length < 2) {
			return;
		}

		$(this.pieChartsRef).sortable({
			items: `.${cardStyles.card}`,
			handle: `.${cardStyles.header}`,
			containment: '.center-main',
			placeholder: cardStyles.cardPlaceholder,
			forceHelperSize: true,
			forcePlaceholderSize: true,
			tolerance: 'pointer',
			opacity: 0.8,
			distance: 10,
			start(event, ui) {
				if (OsChecker.isMac()) {
					$(ui.item).children('.header').css({cursor: 'grabbing'});
				}
				$('.subject-tab').addClass('selection-disabled');
			},
			stop(event, ui) {
				if (OsChecker.isMac()) {
					$(ui.item).children('.header').remove('mousedown').css({cursor: 'grab'});
				}
				$('.subject-tab').removeClass('selection-disabled');
			},
			update: (event, ui) => {
				const {id: subjectId, type: subjectType} = this.state.currentSubject;
				const testIds = $(this.pieChartsRef)
					.find('.' + cardStyles.card)
					.toArray()
					.map((e) => parseInt(e.getAttribute('data-id')))
					.filter(e => !isNaN(e));
				const pieCharts = this.state.pieCharts.sort(
					(a, b) => testIds.indexOf(a.testID) - testIds.indexOf(b.testID),
				);

				HttpClient.default.ESGIApi.post(
					'pages/home/test-results',
					'pie-charts/reorder',
					{subjectId, subjectType, testIds},
				).subscribe(() => this.setState(
					{pieCharts},
					() => dispatchAppEvent(PieChartReorderedEvent),
				));
			},
		});

		this.setState({dragging: true});
	}
}
