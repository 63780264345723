import {UserType} from '@esgi/core/authentication';
import {computed, observable, FormField, Validators} from '@esgi/deprecated/knockout';
import moment, {Moment} from 'moment';
import {MainStepTemplate} from '../html/main-step-template';
import {ExpirationProductModel, StudentCartModel} from '../models';
import {RenewalApi} from '../server';
import {Step} from './steps';

export class MainStep extends Step {

	constructor(private productsMaxExpirationDate: () => Moment, students: number, private renewApi: RenewalApi, private userType: UserType) {
		super();
		this.promoCode = this.createPromoCode();
		this.students = students;
		this.isAddStudentsEnabled = !this.isAllProductsExpired;
		this.isAllowRenewAction = userType !== UserType.ISS && userType !== UserType.ISD;
	}

	template = MainStepTemplate;

	promoCode: FormField<string>;
	products: ExpirationProductModel[];
	acEnabled: boolean;
	allowUsePromoCode = true;

	@observable()
	students: number;

	@observable()
	isAddStudentsEnabled: boolean;

	@observable()
	isAllowRenewAction: boolean;

	@computed()
	get isAllProductsExpired() {
		$('.btn-students-wrapper', this.rootElement).bstooltip('destroy');

		let tooltipMessage;
		if (this.students < StudentCartModel.defaultStudensLimit) {
			tooltipMessage = 'Sorry, your account type does not allow the addition of extra students. Please contact Customer Support (support@esgisoftware.com)</br>for purchasing options.';
		} else {
			const now = moment();
			const expired = this.productsMaxExpirationDate().isBefore(now);

			if (expired) {
				tooltipMessage = 'You must first extend your subscription prior to adding students.';
			}
		}
		if (tooltipMessage) {
			setTimeout(() => {
				const tooltipHtml = '<span><i>' + tooltipMessage + '</i></span>';
				$('.btn-students-wrapper', this.rootElement).bstooltip({
					placement: 'top',
					html: true,
					title: tooltipHtml,
				});
			}, 1);

			return true;
		}

		return false;
	}

	private createPromoCode() {
		const promoCodeValidator = Validators.Ajax(f => {

			f.validation.validationInProgress(true);
			f.validation.showValidationInProgress(true);

			return this.renewApi.api.renewCheckPromoCodeValidation(f.value()).done((resp) => {
				f.validation.successValidation(true);
				f.validation.errorValidation(true);
				return resp;
			}).always(() => {
				f.validation.validationInProgress(false);
				f.validation.showValidationInProgress(false);
			});
		}, v => v.errorMessage);

		let f = this.createField('', promoCodeValidator);

		f.validation.showValidation(true);
		f.validation.successValidation(true);
		f.disabled(true);

		return f;
	}

	view = {
		addStudents: () => {

			if (this.isAddStudentsEnabled) {
				this.validate().done(valid => {
					if (valid) {
						$(this).trigger('addStudentsClicked');
					} else {
						setTimeout(() => {
							$(this).trigger('addStudentsClicked');
						}, 3000);
					}
				});
			}
		},
		addTime: () => {
			this.validate().done(valid => {
				if (valid) {
					$(this).trigger('addTimeClicked');
				} else {
					setTimeout(() => {
						$(this).trigger('addTimeClicked');
					}, 3000);
				}
			});
		},
		applyActivationCode: () => {
			$(this).trigger('activationCodeClicked');
		},
		modifyPromoCode: () => {
			$(this).trigger('modifyPromoCodeClicked');
		},
	};

	events = {
		addStudentsClicked: (callback) => {
			$(this).on('addStudentsClicked', callback);
		},
		addTimeClicked: (callback) => {
			$(this).on('addTimeClicked', callback);
		},
		activationCodeClicked: (callback) => {
			$(this).on('activationCodeClicked', callback);
		},
		modifyPromoCodeClicked: (callback) => {
			$(this).on('modifyPromoCodeClicked', callback);
		},
	};
}
