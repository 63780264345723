import {Text} from '@esgi/ui/typography';
import {StyledEmptyWrapper, StyledEmptyBox, Button} from './index.styled';

interface Props {
	onAllTestClick?: VoidFunction;
	emptyText?: string;
}

export function TestsListEmpty({onAllTestClick, emptyText = 'No tests to display'}: Props) {
	return (
		<StyledEmptyWrapper>
			<StyledEmptyBox>
				<Text size='small'>{emptyText}</Text>
			</StyledEmptyBox>
			{onAllTestClick && <Button
				color='secondary'
				onClick={onAllTestClick}
			>
				<Text size='medium' bold>Browse All Tests</Text>
			</Button>}
		</StyledEmptyWrapper>
	);
}
