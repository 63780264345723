import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {District} from '../../../kit/district';
import {ArrowIcon} from '@esgillc/ui-kit/icons';
import {Loader} from '@esgillc/ui-kit/loader';
import {Fade} from '@esgillc/ui-kit/transition';
import {VerticalStepper} from '@esgillc/ui-kit/layout';
import {TestDetailsModal} from '../second-step/components/test-details-modal';
import {PropsWithChildren} from 'react';
import {UserInfo} from '@esgi/core/authentication';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {useValidations} from '../../hooks/use-validations';
import {CreateAssignmentService} from '../../services/create-assignment-service';
import {StudentItem, TestFilter} from '../../types/types';

type CreateAssignmentContentProps<
	Service extends CreateAssignmentService,
	SelectedStudent extends StudentItem,
> = PropsWithChildren<{
	currentUser: Readonly<UserInfo>;
	step: number;
	setStep: (step: number) => void;
	loaded: boolean;
	setLoaded: (value: boolean) => void;
	onGoBack?: VoidFunction | undefined;
	service: Service;
	selectedStudents: SelectedStudent[];
	isEdit: boolean;
	onCancel?: VoidFunction | undefined;
	extractData:
		| ((data: {
				name: string;
				description: string;
				selectedTestsFilters: TestFilter[];
				selectedStudents: SelectedStudent[];
				selectedTestsIds: number[];
		  } | null) => void)
		| undefined;
	onSaved?: ((assignmentID?: number) => void) | undefined;
}>;

export function CreateAssignmentContent<Service extends CreateAssignmentService, SelectedStudent extends StudentItem>({
	children,
	currentUser,
	step,
	setStep,
	loaded,
	setLoaded,
	onGoBack,
	service,
	selectedStudents,
	isEdit,
	extractData,
	onCancel,
	onSaved,
}: CreateAssignmentContentProps<Service, SelectedStudent>) {
	const testDetailsId = useBehaviorSubject(service.testDetailsId);
	const subjectsForModal = useBehaviorSubject(service.subjectsForModal);

	useStreamEffect(service.savingInProgress, (isLoading) => {
		setLoaded(!isLoading);
	});

	const {onNextClick, renderAlert, onFinish, credentialsAlert} = useValidations({
		editMode: isEdit,
		extractData,
		onCreated: onSaved,
		selectedStudents,
		service,
		step,
	});

	const goBackHandler = () => {
		extractData?.(null);
		onGoBack?.();
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<Buttons.Text className={styles.backBtn} onClick={goBackHandler}>
					<ArrowIcon direction='left' />
					Back
				</Buttons.Text>
				{isEdit ? 'Edit' : 'Create'} Assignment
				<District />
			</div>
			<div className={styles.content}>
				<Loader show={!loaded} />
				<Fade in duration='common'>
					<VerticalStepper
						step={step}
						initialStep={step}
						className={styles.stepper}
						onGoBack={onGoBack}
						onStepChanged={setStep}
						onNextClick={onNextClick}
					>
						{children}
					</VerticalStepper>
				</Fade>
				<div className={styles.btnBlock}>
					<Buttons.Gray onClick={onCancel}>CANCEL</Buttons.Gray>
					<Buttons.Contained onClick={onFinish}>SAVE AND CONTINUE</Buttons.Contained>
				</div>
			</div>
			{testDetailsId && (
				<TestDetailsModal
					testDetailsId={testDetailsId}
					currentUser={currentUser}
					subjectsForModal={subjectsForModal}
					onClose={service.hideTestDetails.bind(service)}
				/>
			)}
			{renderAlert}
			{credentialsAlert}
		</div>
	);
}
