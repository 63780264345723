import {Buttons} from '@esgillc/ui-kit/button';
import {StudentProfileMode, StudentProfileTab} from 'modules/forms/students-form/types';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {getUser} from '@esgi/core/authentication';
import {useEffect, useState} from 'react';

interface Props {
	mode: StudentProfileMode;
	canSaveAndAdd?: boolean;
	className?: string;
	tabsApi: Record<StudentProfileTab, { isTouched: boolean }>;
	currentTab: StudentProfileTab;
	onSaveAndAdd?: () => void;
	onSave: () => void;
}

export function TabFooter({mode, canSaveAndAdd, className, currentTab, onSave, onSaveAndAdd, tabsApi}: Props) {
	const canEditMetaFields = currentTab === StudentProfileTab.Additional && getUser().canEditStudentMetaFields;
	const canEditLanguage = currentTab === StudentProfileTab.General && getUser().canEditStudentLanguage;
	const hasSaveAndAdd = mode !== StudentProfileMode.view
		&& canSaveAndAdd
		&& (currentTab === StudentProfileTab.Additional ? getUser().canEditStudentMetaFields : true);
	const hasSave = (mode !== StudentProfileMode.view
		&& currentTab !== StudentProfileTab.Additional)
		|| canEditMetaFields
		|| canEditLanguage;

	const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(false);

	useEffect(() => {
		setSaveButtonDisabled(!tabsApi[currentTab]?.isTouched);
	}, [tabsApi[currentTab]?.isTouched]);

	return (
		<div
			className={join(styles.footerContainer, className)}
			data-cy='mode-footer'
		>
			{hasSaveAndAdd &&
				<Buttons.Outlined disabled={isSaveButtonDisabled} onClick={onSaveAndAdd}>SAVE AND ADD ANOTHER</Buttons.Outlined>
			}
			{hasSave &&
				<Buttons.Contained disabled={isSaveButtonDisabled} onClick={onSave}>SAVE</Buttons.Contained>
			}
		</div>
	);
}
