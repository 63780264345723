import {Avatar} from '@esgi/ui/avatar';

export function AvatarHoverLayer() {
	return (
		<Avatar.HoverLayer
			css={{
				borderRadius: 'inherit',

				'&:hover': {
					background: '$secondary92',
					mixBlendMode: 'multiply',
				},
			}}
		/>
	);
}
