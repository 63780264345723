import React from 'react';
import {ModalFooter} from '@esgi/deprecated/ui-kit/modal';
import {Button, PrimaryButton, TransparentButton} from '@esgi/deprecated/ui-kit/buttons';
import {join} from '@esgillc/ui-kit/utils';
import {ArrowIcon} from './components/arrow-icon';
import styles from './footer.module.less';

interface Props {
	currentStep: number;
	isAvailable: (step: number) => boolean;
	onNextStepClicked: () => void;
	onPrevStepClicked: () => void;
	onCancelClicked: () => void;
	onSaveClicked: () => void;
	onPreviewClicked: () => void;
}

export default class Footer extends React.PureComponent<Props> {
	public render() {
		return <ModalFooter className={styles.footer}>
			<div className={styles.desktopStepFooter}>
				{this.renderStepperActions()}
			</div>
			<div className={styles.actions}>
				<div className={styles.buttonContainer}>
					<Button onClick={this.props.onCancelClicked}>
						CANCEL
					</Button>
				</div>
				<div className={styles.mobileStepFooter}>
					{this.renderStepperActions()}
				</div>
				<div className={styles.buttonContainer}>
					{this.props.currentStep === 3 && <>
						<TransparentButton onClick={this.props.onPreviewClicked}>PREVIEW RUBRIC</TransparentButton>
						<PrimaryButton onClick={this.props.onSaveClicked}>SAVE</PrimaryButton>
					</>}
				</div>
			</div>
		</ModalFooter>;
	}

	private renderStepperActions() {
		return <div className={styles.stepperFooter}>
			<div className={join(styles.arrow, styles.activeArrow, this.props.currentStep === 1 && styles.hidden)}>
				<ArrowIcon className={styles.prevStepButton} onClick={this.props.onPrevStepClicked}/>
			</div>
			<div>
					<span className={styles.stepCounter}>
						{this.props.currentStep} of 3
					</span>
			</div>
			<div className={join(styles.arrow, this.props.isAvailable(this.props.currentStep + 1) && styles.activeArrow, this.props.currentStep === 3 && styles.hidden)}>
				<ArrowIcon onClick={this.props.onNextStepClicked}/>
			</div>
		</div>;
	}
}
