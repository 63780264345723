import {useEffect, useState} from 'react';
import {useOpacityAnimation} from '../../utils/use-opacity-animation';
import {useArcAnimation} from '../utils/use-arc-animation';

export function useAnimationData({
	nonActiveArcStartValueProgress,
	nonActiveArcEndValueProgress,
	activeArcStartValueProgress,
	activeArcEndValueProgress,
}: {
	nonActiveArcStartValueProgress: number;
	nonActiveArcEndValueProgress: number;
	activeArcStartValueProgress: number;
	activeArcEndValueProgress: number;
}) {
	const [forceUpdatedOnInit, setForceUpdatedOnInit] = useState(false);

	// This useEffect triggers animations
	useEffect(() => {
		if (!forceUpdatedOnInit) {
			setForceUpdatedOnInit(true);
		}
	}, [forceUpdatedOnInit]);

	const nonActiveAnimationArcProps = useArcAnimation({
		progressFrom: nonActiveArcStartValueProgress,
		progressTo: nonActiveArcEndValueProgress,
	});

	const activeAnimationArcProps = useArcAnimation({
		progressFrom: activeArcStartValueProgress,
		progressTo: activeArcEndValueProgress,
	});

	const pointerAnimationProps = useOpacityAnimation();
	const cubeAnimationProps = useOpacityAnimation();

	return {
		nonActiveAnimationArcProps,
		activeAnimationArcProps,
		pointerAnimationProps,
		cubeAnimationProps,
	};
}
