import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {Student} from '../../types';
import {InitCreateClassResponse} from './types';
import {EventBusManager} from '@esgillc/events';
import {AddStudent} from '../../../drawer-with-student-via-profile/events';

export class ClassDataService extends BaseService {
	public students$ = new BehaviorSubject<Student[] | null>(null);

	private readonly eventBus = new EventBusManager();
	private controller = 'v2/teachers/modules/classes';

	constructor() {
		super();
		this.eventBus.subscribe(AddStudent, (args) => this.addStudent(args));
	}

	public init() {
		return this.httpClient.ESGIApi.get<InitCreateClassResponse>(this.controller, 'create')
			.pipe(
				tap(({students}) => {
					this.students$.next(students);
				}),
			)
			.asObservable();
	}

	public createClass({name, studentIDs}: {name: string; studentIDs: Student['id'][]}) {
		return this.httpClient.ESGIApi.post<{classID: number}>(this.controller, 'create', {name, studentIDs});
	}

	private addStudent(student: AddStudent) {
		const {id, firstName, lastName} = student.studentsData.student;
		this.students$.next([...this.students$.value, {id, firstName, lastName}]);
	}
}
