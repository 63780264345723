import {Checkbox} from '@esgillc/ui-kit/control';
import {FilterStatus} from '../../../../types';
import styles from '../../styles.module.less';

interface Props {
	value: FilterStatus;
	onChange: (value: FilterStatus) => void;
}

const statuses = [
	{label: 'All', value: FilterStatus.All},
	{label: 'In Progress', value: FilterStatus.Progress},
	{label: 'Complete', value: FilterStatus.Complete},
];

export const Status = (props: Props): JSX.Element => (
	<div className={styles.field}>
		<label className={styles.title}>Status:</label>
		{statuses.map(({label, value}) => (
			<Checkbox
				key={value}
				checked={props.value === value}
				onChange={() => props.onChange(value)}
			>
				{label}
			</Checkbox>
		))}
	</div>
);
