import {Text} from '@esgi/ui/typography';
import {ArrowsIcon, SortableCell} from './index.styled';
import {Property} from '@stitches/react/types/css';
import {SortDirection} from '../types';

type Props<T extends string> = {
	cellKey: T;
	cellName: string;
	isActive: boolean;
	sortDirection: SortDirection;
	disableSorting?: boolean;
	onCellClick: (args: { cellKey: T; nextSortDirection: SortDirection }) => void;
	justifyContent?: Property.JustifyContent | undefined;
};

export * from './types';

export function TableHeaderCellSortable<T extends string>({
	cellName,
	sortDirection,
	isActive,
	cellKey,
	onCellClick,
	disableSorting,
	justifyContent,
}: Props<T>) {
	const handleCellClick = () => {
		onCellClick({
			cellKey,
			nextSortDirection: sortDirection === SortDirection.Desc || !isActive ? SortDirection.Asc : SortDirection.Desc,
		});
	};

	if (disableSorting) {
		return (
			<SortableCell
				css={{
					justifyContent,
				}}
			>
				<Text size='small' color='lowContrast' data-cy='sortable-cell'>
					{cellName}
				</Text>
			</SortableCell>
		);
	}

	return (
		<SortableCell
			isActive={isActive}
			sortDirection={sortDirection}
			onClick={handleCellClick}
			css={{
				justifyContent,
			}}
			sortable
		>
			<Text size='small' data-cy='sortable-cell'>{cellName}</Text>
			<ArrowsIcon/>
		</SortableCell>
	);
}
