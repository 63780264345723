import type {SVGProps} from 'react';

export function GridNine(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path
				d='M3.75 6.75C3.75 6.33579 4.08579 6 4.5 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75V8.25C8.25 8.66421 7.91421 9 7.5 9H4.5C4.08579 9 3.75 8.66421 3.75 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M9.75 6.75C9.75 6.33579 10.0858 6 10.5 6H13.5C13.9142 6 14.25 6.33579 14.25 6.75V8.25C14.25 8.66421 13.9142 9 13.5 9H10.5C10.0858 9 9.75 8.66421 9.75 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M15.75 6.75C15.75 6.33579 16.0858 6 16.5 6H19.5C19.9142 6 20.25 6.33579 20.25 6.75V8.25C20.25 8.66421 19.9142 9 19.5 9H16.5C16.0858 9 15.75 8.66421 15.75 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M3.75 11.25C3.75 10.8358 4.08579 10.5 4.5 10.5H7.5C7.91421 10.5 8.25 10.8358 8.25 11.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5H4.5C4.08579 13.5 3.75 13.1642 3.75 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M9.75 11.25C9.75 10.8358 10.0858 10.5 10.5 10.5H13.5C13.9142 10.5 14.25 10.8358 14.25 11.25V12.75C14.25 13.1642 13.9142 13.5 13.5 13.5H10.5C10.0858 13.5 9.75 13.1642 9.75 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M15.75 11.25C15.75 10.8358 16.0858 10.5 16.5 10.5H19.5C19.9142 10.5 20.25 10.8358 20.25 11.25V12.75C20.25 13.1642 19.9142 13.5 19.5 13.5H16.5C16.0858 13.5 15.75 13.1642 15.75 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M3.75 15.75C3.75 15.3358 4.08579 15 4.5 15H7.5C7.91421 15 8.25 15.3358 8.25 15.75V17.25C8.25 17.6642 7.91421 18 7.5 18H4.5C4.08579 18 3.75 17.6642 3.75 17.25V15.75Z'
				fill='#333333'
			/>
			<path
				d='M9.75 15.75C9.75 15.3358 10.0858 15 10.5 15H13.5C13.9142 15 14.25 15.3358 14.25 15.75V17.25C14.25 17.6642 13.9142 18 13.5 18H10.5C10.0858 18 9.75 17.6642 9.75 17.25V15.75Z'
				fill='#333333'
			/>
			<path
				d='M15.75 15.75C15.75 15.3358 16.0858 15 16.5 15H19.5C19.9142 15 20.25 15.3358 20.25 15.75V17.25C20.25 17.6642 19.9142 18 19.5 18H16.5C16.0858 18 15.75 17.6642 15.75 17.25V15.75Z'
				fill='#333333'
			/>
		</svg>
	);
}
