import React from 'react';
import {Student} from './boxes/student';
import {Group} from './boxes/group';
import {User} from './boxes/user';
import {Type} from './boxes/type';
import {Teacher} from './boxes/teacher';
import {School} from './boxes/school';
import {
	withGroupID,
	withGroupOfSpecialistsID,
	withSchoolID,
	withStudent,
	withTeacherID,
	withUserID,
	withUserType,
} from '../core/builders/specialist';
import {
	GroupOfSpecialistsBox,
	IBoxInfo,
	IDistrictSpecialist,
	ISchoolBox,
	ISchoolSpecialist,
	ISpecialistGroupBox,
	IStudentBox,
	ITeacherBox,
} from '../core/api';
import {BoxType} from 'modules/hierarchy/core/models';
import {userStorage, UserType} from '@esgi/core/authentication';
import {SpecialistHierarchySnapshot} from '../models';
import {GroupOfSpecialists} from './boxes/group-of-specialists';

export class SpecialistHierarchy extends React.Component<Props, State> {
	private currentUser = userStorage.get();

	render() {
		return <div className='hierarchy'>
      {this.renders.groupsOfSpecialists()}
			{this.renders.types()}
			{this.renders.users()}
			{this.renders.groups()}
			{this.renders.schools()}
			{this.renders.teachers()}
			{this.renders.students()}
		</div>;
	}

	renders = {
		groupsOfSpecialists: () => {
			return <GroupOfSpecialists
        boxes={this.props.boxes}
        selectedID={this.props.snapshot.groupOfSpecialistsID}
				selectedSpecialistID={this.props.snapshot.userID}
        onSelected={this.onSelected.groupsOfSpecialists}
        groups={this.props.groupsOfSpecialists}
        type={this.props.snapshot.type}
        onOpenCloseChanged={this.props.onOpenCloseChanged}
        districtSpecialists={this.props.districtSpecialists}
        schoolSpecialists={this.props.schoolSpecialists}/>;
		},
		students: () => {
			return <Student
				boxes={this.props.boxes}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				selectedID={this.props.snapshot.studentID}
				students={{...this.props.students}}
				userType={this.props.snapshot.type}
				specialGroups={this.props.specialistGroups}
				onSelected={this.onSelected.students}
				userID={this.props.snapshot.userID}
				filter={this.props.snapshot.filter}
				specialistGroupID={this.props.snapshot.groupID}
			/>;
		},
		groups: () => {
			return <Group
				boxes={this.props.boxes}
				selectedID={this.props.snapshot.groupID}
				userID={this.props.snapshot.userID}
				onSelected={this.onSelected.groups}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				specialistGroups={this.props.specialistGroups}
				type={this.props.snapshot.type}/>;
		},
		users: () => {
			return <User
				boxes={this.props.boxes}
				schoolSpecialists={this.props.schoolSpecialists}
        groupOfSpecialistsID={this.props.snapshot.groupOfSpecialistsID}
				districtSpecialists={this.props.districtSpecialists}
				onSelected={this.onSelected.users}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				selectedID={this.props.snapshot.userID}
				type={this.props.snapshot.type}/>;
		},
		types: () => {
			return <Type
				boxes={this.props.boxes}
				selectedID={this.props.snapshot.type}
				onSelected={this.onSelected.types}
        groupOFSpecialistsID={this.props.snapshot.groupOfSpecialistsID}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				districtSpecialists={this.props.districtSpecialists}
				schoolSpecialists={this.props.schoolSpecialists}/>;
		},
		teachers: () => {
			return <Teacher
				boxes={this.props.boxes}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				selectedID={this.props.snapshot.filter.teacherID}
				studentID={this.props.snapshot.studentID}
				onSelected={this.onSelected.teachers}
				schoolID={this.props.snapshot.filter.schoolID}
				teachers={this.props.teachers}
				groupID={this.props.snapshot.groupID}
				students={this.props.students}/>;
		},
		schools: () => {
			return <School
				boxes={this.props.boxes}
				selectedID={this.props.snapshot.filter.schoolID}
				onSelected={this.onSelected.schools}
				studentID={this.props.snapshot.studentID}
				districtSpecialists={this.props.districtSpecialists}
				schoolSpecialists={this.props.schoolSpecialists}
				userID={this.props.snapshot.userID}
				students={this.props.students}
				schools={this.props.schools}
				specialGroups={this.props.specialistGroups}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				userType={this.props.snapshot.type}/>;
		},
	};

	onSelected = {
		students: (id: number, cb: () => void) => {
			const s = this.props.students.items.find(t => t.studentID === id);
			const h = withStudent(this.props.snapshot, s);
			return this.props.onChanged(h, cb);
		},
		groups: (id: number, cb: () => void) => {
			let h = withGroupID(this.props.snapshot, id);
			if (this.currentUser.userType === UserType.ISS) {
				h = withSchoolID(h, this.currentUser.schoolID);
			}
			this.props.onChanged(h, cb);
		},
		types: (id: UserType, cb: () => void) => {
			const h = withUserType(this.props.snapshot, id);
			this.props.onChanged(h, cb);
		},
		groupsOfSpecialists: (id: number, cb: () => void) => {
			const h = withGroupOfSpecialistsID(this.props.snapshot, id);
			this.props.onChanged(h, cb);
		},
		users: (id: number, cb: () => void) => {
			let h = withUserID(this.props.snapshot, id);

			if ([UserType.D, UserType.C].indexOf(this.currentUser.userType) >= 0) {
				const groups = this.props.specialistGroups.items.filter(t => t.userID === id);
				if (groups.length) {
					h = withGroupID(h, groups[0].groupID);
				}
			}

			if (this.props.snapshot.groupOfSpecialistsID) {
				const isSchoolSpecialist = this.props.schoolSpecialists.some(s => s.userID === id);
				const type= isSchoolSpecialist ? UserType.ISS : UserType.ISD;

				h = withUserType(this.props.snapshot, type);
				h = withUserID(h, id);
			}

			this.props.onChanged(h, cb);
		},
		schools: (id: number, cb: () => void) => {
			const h = withSchoolID(this.props.snapshot, id);
			this.props.onChanged(h, cb);
		},
		teachers: (id: number, cb: () => void) => {
			const h = withTeacherID(this.props.snapshot, id);
			this.props.onChanged(h, cb);

		},
	};
}

export class State {
}

export class Props {
	boxes: IBoxInfo[];
	snapshot: SpecialistHierarchySnapshot;
	students: IStudentBox;
	specialistGroups: ISpecialistGroupBox;
	districtSpecialists: IDistrictSpecialist[];
	schoolSpecialists: ISchoolSpecialist[];
	groupsOfSpecialists: GroupOfSpecialistsBox;
	schools: ISchoolBox;
	teachers: ITeacherBox;
	onChanged: (h: SpecialistHierarchySnapshot, cb: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}
