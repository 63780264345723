import React from 'react';
import {InfoBlock} from '@esgi/ui/layout';
import {InfoBlockLabel, Wrapper} from './styled';

interface Props {
	testName: string;
	className: string;
	studentName: string;
	subjectName: string;
}

export function TestInfo({testName, className, studentName, subjectName}: Props) {

	return <Wrapper>
		<InfoBlock>
			<InfoBlock.Group>
				<InfoBlock.Item title='Test:'>
					<InfoBlockLabel dataCy='test-name' font='mono' color='neutral40'>{testName}</InfoBlockLabel>
				</InfoBlock.Item>
			</InfoBlock.Group>
		</InfoBlock>
		<InfoBlock>
			<InfoBlock.Group>
				<InfoBlock.Item title='Class:'>
					<InfoBlockLabel dataCy='class-name' font='mono' color='neutral40'>{className}</InfoBlockLabel>
				</InfoBlock.Item>
				<InfoBlock.Item title='Student:'>
					<InfoBlockLabel dataCy='student-name' font='mono' color='neutral40'>{studentName}</InfoBlockLabel>
				</InfoBlock.Item>
				<InfoBlock.Item title='Subject:'>
					<InfoBlockLabel data-cy='subject-name' font='mono' color='neutral40'>{subjectName}</InfoBlockLabel>
				</InfoBlock.Item>
			</InfoBlock.Group>
		</InfoBlock>
	</Wrapper>;
}
