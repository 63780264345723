import {ExpandablePanel as ExpandablePanelUi} from '@esgi/ui/layout';
import {ExpandablePanelContent, ExpandablePanelStyled, SectionBox} from './index.styled';
import {PropsWithChildren} from 'react';
import {IconComponent} from '@esgi/ui';
import {ExpandablePanelIcon} from '../expandable-panel-icon';
import {CSS} from '@stitches/react';

type Props = PropsWithChildren<{
	isPanelOpened: boolean;
	togglePanelOpened: VoidFunction;
	onClick: VoidFunction;
	disablePanelToggling: boolean;
	loaded: boolean;
	css?: CSS;
	PanelIcon: IconComponent;
	dataCy?: string
	isTablet?: boolean;
}>;

export function ExpandablePanel({
	isPanelOpened,
	togglePanelOpened,
	disablePanelToggling,
	loaded,
	PanelIcon,
	children,
	css,
	onClick,
	dataCy = 'expandable-panel',
	isTablet,
}: Props) {
	return (
		<SectionBox css={css} dataCy={`${dataCy}-section-box`}>
			<ExpandablePanelStyled isTablet={isTablet} skeleton={!loaded} open={isPanelOpened} onClick={onClick} collapsed={!isPanelOpened}>
				{({panelIsHovered}) => (
					<>
						<ExpandablePanelUi.Trigger dataCy={`${dataCy}-trigger`} disabled={disablePanelToggling} onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							togglePanelOpened();
						}} />
						<ExpandablePanelUi.Placeholder dataCy={`${dataCy}-placeholder`}>
							<ExpandablePanelIcon fillColor={panelIsHovered ? 'secondary' : 'primary'} Icon={PanelIcon} />
						</ExpandablePanelUi.Placeholder>
						<ExpandablePanelContent dataCy={`${dataCy}-content`}>{children}</ExpandablePanelContent>
					</>
				)}
			</ExpandablePanelStyled>
		</SectionBox>
	);
}
