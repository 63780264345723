import './styles.less';
import React from 'react';

class Props {
    name: string;
    contentArea: string;
    onClick: () => void;
}

export class ContentAreaColors {
    color: string;
    darkerColor: string;

    constructor(color: string, darkerColor: string) {
    	this.color = color;
    	this.darkerColor = darkerColor;
    }
}

export class Banner extends React.PureComponent<Props> {
	get contentArea() {
		switch (this.props.contentArea.toLowerCase()) {
			case 'mathematics':
				return new ContentAreaColors('#80CBC4', '#507B77');
			case 'language arts':
				return new ContentAreaColors('#90CAF9', '#005796');
			case 'social studies':
				return new ContentAreaColors('#FFF59D', '#938B4B');
			case 'science':
				return new ContentAreaColors('#CE93D8', '#5A3E5F');
			case 'sel':
				return new ContentAreaColors('#9FA8DA', '#6B7195');
			case 'spanish':
				return new ContentAreaColors('#FFCC80', '#99794B');
			case 'technologies':
				return new ContentAreaColors('#A5D6A7', '#5C7D5E');
			case 'other':
				return new ContentAreaColors('#CDCDCD', '#828282');
			default:
				return new ContentAreaColors('#FFFFFF', '#828282');
		}
	}

	get isMultiple() {
		return this.props.contentArea === 'multiple';
	}

	renderTitle() {
		return <>
            <div className={this.isMultiple ? 'banner-title multiple' : 'banner-title'}>Featured Series</div>
            <div className='banner-name' onClick={() => this.props.onClick()}>{this.props.name}</div>
		</>;
	}

	renderBar() {
		if (this.isMultiple) {
			return <div className='color-bar multiple'>
                {this.renderTitle()}
                <div className='box'/>
            </div>;
		}

		return <div className='color-bar' style={{background: this.contentArea.color}}>{this.renderTitle()}</div>;
	}

	render() {
		return <div className='banner'>
            <div className='inner'>
                {this.renderBar()}
                <div className='corner' style={{borderRightColor: this.contentArea.darkerColor}}/>
            </div>
        </div>;
	}
}