import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TabsRoot = styled(Box, {
	position: 'relative',
	width: '100%',
	height: '80vh',
	overflow: 'hidden',

	'@media (max-width: 1024px)': {
		height: '65vh',
	},
});
