import React, {ReactNode} from 'react';
import {
	DraggableProvided,
	DraggableProvidedDragHandleProps,
	DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import {join} from '@esgillc/ui-kit/utils';
import DragNDropRow from './drag-n-drop-row';
import styles from '../../test-form.module.less';

interface Props {
	id: number;
	order: number;
	canDrag: boolean;
	className?: string;
	dragContainer: HTMLElement;
	children?: (dragHandleProps?: DraggableProvidedDragHandleProps) => ReactNode;
}

export default class RowBody extends React.PureComponent<Props> {
	public render() {
		return <DragNDropRow canDrag={this.props.canDrag} draggableId={'criteria' + this.props.id} index={this.props.order}
		                     scrollContainer={this.props.dragContainer}>
			{(provided, snapshot, refSetter) => this.renderTableRow(provided, snapshot, refSetter)}
		</DragNDropRow>;
	}

	private renderTableRow(provided?: DraggableProvided, snapshot?: DraggableStateSnapshot, refSetter?) {
		const providedProps = provided || {} as DraggableProvided;
		return <tr className={join(snapshot?.isDragging && styles.dragging, this.props.className)}
		           ref={refSetter}
		           {...providedProps.draggableProps}>
			{this.props.children(providedProps.dragHandleProps)}
		</tr>;
	}
}
