import {useCallback, useMemo, useState} from 'react';
import {Carousel} from './carousel';
import {AddWidgetCard, AddWidgetCardCompact, CustomizeWidgetsCard} from './card';
import {SettingsDrawer} from './settings-drawer';
import {WidgetType, WidgetsInfo} from './types';
import {AveragePerformance} from './widgets/average-performance';
import {QuestionsAnswered} from './widgets/questions-answered';
import {TimePerQuestion} from './widgets/time-per-question';
import {TestsTaken} from './widgets/tests-taken';
import {sortBy} from 'underscore';
import {UntestedStudents} from './widgets/untested-students';
import {useDeepMemo} from './hooks';

export * from './events';
export * from './types';

type Props = {
	studentIDsInClass: number[],
	singleStudentID: number,
	testIDs: number[],
	widgets: WidgetsInfo,
};

function resolveWidget(widget: WidgetType, studentIDs: number[], testIDs: number[], studentID: number) {
	switch (widget) {
		case WidgetType.AveragePerformance:
			return <AveragePerformance key={WidgetType.AveragePerformance} studentIDs={studentIDs} testIDs={testIDs}
			                           studentID={studentID}/>;
		case WidgetType.QuestionsAnswered:
			return <QuestionsAnswered key={WidgetType.QuestionsAnswered} studentIDs={studentIDs} testIDs={testIDs}
			                          studentID={studentID}/>;
		case WidgetType.TestsTaken:
			if (studentID) {
				return <TestsTaken key={WidgetType.TestsTaken} studentIDs={studentIDs} testIDs={testIDs}
				                   studentID={studentID}/>;
			}
			return;
		case WidgetType.TimePerQuestion:
			return <TimePerQuestion key={WidgetType.TimePerQuestion} studentIDs={studentIDs} testIDs={testIDs}
			                        studentID={studentID}/>;
		case WidgetType.UntestedStudents:
			if (!studentID) {
				return <UntestedStudents key={WidgetType.UntestedStudents} testIDs={testIDs} studentIDs={studentIDs}/>;
			}
			return;
	}
}

export function Widgets(props: Props) {
	const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);

	const canAddMoreWidgets = useMemo(() => (props.widgets.classGroupLevel.length + props.widgets.studentLevel.length) !== 8, [props.widgets]);

	const availableWidgets = useMemo(() => {
		if (props.singleStudentID) {
			return props.widgets.studentLevel;
		} else {
			return props.widgets.classGroupLevel;
		}
	}, [props.studentIDsInClass, props.singleStudentID, props.widgets]);

	const testIDs = useDeepMemo(props.testIDs);
	const studentIDs = useDeepMemo(props.studentIDsInClass);

	const openDrawer = useCallback(() => {
		setShowSettingsDrawer(true);
	}, []);

	const widgets = sortBy(availableWidgets).map(w => resolveWidget(w, studentIDs, testIDs, props.singleStudentID));

	if (canAddMoreWidgets) {
		if (widgets.length) {
			widgets.push(<AddWidgetCard onClick={openDrawer}/>);
		} else {
			widgets.push(<AddWidgetCardCompact onClick={openDrawer}/>);
		}
	} else {
		widgets.push(<CustomizeWidgetsCard onClick={() => setShowSettingsDrawer(true)}/>);
	}

	return <>
		<Carousel>
			{...widgets}
		</Carousel>
		{showSettingsDrawer && <SettingsDrawer
			studentIDsInClass={props.studentIDsInClass}
			singleStudentID={props.singleStudentID}
			testIDs={props.testIDs}
			initialWidgets={props.widgets}
			onClose={() => setShowSettingsDrawer(false)}/>}
	</>;
}