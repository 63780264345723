export function getInitials({
	firstName,
	lastName,
	textCase = 'toUpperCase',
}: {
	firstName?: string | undefined;
	lastName?: string | undefined;
	textCase?: keyof Pick<string, 'toUpperCase'>;
}) {
	return [firstName?.[0], lastName?.[0]].filter(Boolean).join('').trim()[textCase]();
}
