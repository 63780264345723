import {Student, useStudents} from '@esgi/main/libs/store';
import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {AssignmentModel, StudentModel} from './types';
import {AlertBody} from './index.styled';
import {StudentsList} from './components/students-list';
import {useService} from '@esgi/core/service';
import {Service} from './service';

type SingleAssignment = {
	type: 'singleAssignment';
	assignmentName: string;
};

type MultipleAssignments = {
	type: 'multipleAssignments';
	assignments: AssignmentModel[];
};

type Props = {
	studentsIDs: Student['id'][];
	onCloseAlert: VoidFunction;
} & (SingleAssignment | MultipleAssignments);

export function ExportStudentsCredentialsAlert({studentsIDs, onCloseAlert, ...props}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onCloseAlert);

	const [{data: studentList, loaded: isStudentListLoaded}] = useStudents();

	const [students, setStudents] = useState<StudentModel[]>([]);

	const [selectedStudentsIDs, setSelectedStudentsIDs] = useState<Student['id'][]>([]);

	const service = useService(Service);

	const multipleAssignmentsNames = useMemo<Record<Student['id'], string[]>>(() => {
		if (props.type === 'multipleAssignments') {
			const {assignments} = props;

			return assignments.reduce((currentStudentsState, iteratedAssignment) => {
				iteratedAssignment.studentsIDs.forEach((id) => {
					const currentStudentAssignments = currentStudentsState[id] ?? [];

					currentStudentAssignments.push(iteratedAssignment.name);

					currentStudentsState[id] = currentStudentAssignments;
				});

				return currentStudentsState;
			}, {} as Record<Student['id'], string[]>);
		}

		return {};
	}, [props]);

	useEffect(() => {
		if (isStudentListLoaded) {
			const printableStudents = studentsIDs.reduce((currentStudents, iteratedStudentID) => {
				const student = studentList.find(({id}) => id === iteratedStudentID);

				if (!student) {
					return currentStudents;
				}

				const studentWithAssignment: StudentModel = {
					...student,
					joinedAssignmentName:
						props.type === 'singleAssignment'
							? props.assignmentName
							: multipleAssignmentsNames[iteratedStudentID]?.join(', ') ?? '',
				};

				return [...currentStudents, studentWithAssignment];
			}, [] as StudentModel[]);

			setStudents(printableStudents);
		}
	}, [isStudentListLoaded, studentList, studentsIDs]);

	const printStudentsCards = useCallback(() => {
		service.downloadStudentsCards({studentIDs: selectedStudentsIDs}).subscribe();
	}, [selectedStudentsIDs, service]);

	return (
		<Alert
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					minWidth: 560,
					maxWidth: 560,
					maxHeight: 'calc(100% - 25px)',
					gridTemplateRows: 'auto 1fr auto',
				},
			}}
			dataCy='export-student-credentials-dialog'
		>
			<Alert.Header onCloseIconClick={closeAlert} withBacklight={false}>
				<Text size='small' color='base' data-cy='print-students-title'>
					Print Students Credentials Cards
				</Text>
			</Alert.Header>
			<AlertBody>
				<Text size='medium' color='neutral40' data-cy='select-students-title'>
					Please select the students you want to include.
				</Text>

				<StudentsList
					selectedStudentsIDs={selectedStudentsIDs}
					setSelectedStudentsIDs={setSelectedStudentsIDs}
					setStudents={setStudents}
					students={students}
				/>
			</AlertBody>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
					<Button color='secondary' onClick={printStudentsCards} disabled={!selectedStudentsIDs.length}>
						<Text size='medium' bold color='base'>
							Print
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
