import {BaseComponentProps, composeRefs} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import useResizeObserver from '@react-hook/resize-observer';
import {ComponentPropsWithoutRef, forwardRef, useRef, useState} from 'react';
import {ProgressProps} from './types';
import {PercentageProgress} from './components/percentage-progress';
import {DashedProgress} from './components/dashed-progress';

type Props = BaseComponentProps & ProgressProps & ComponentPropsWithoutRef<'div'>;

export const ProgressDashedLine = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'assignments-progress-dashed-line',
			css = {},
			activeLineColor = 'primaryMuted',
			lastActiveLineBorderColor = 'primary48',
			notActiveLineColor = 'border',
			progressLineHeight = 6,
			value,
			maxValue,
			...props
		},
		forwardedRef,
	) => {
		const [containerWidth, setContainerWidth] = useState(0);

		const ownContainerRef = useRef<HTMLDivElement>(null);
		const containerRef = composeRefs(ownContainerRef, forwardedRef);

		useResizeObserver(ownContainerRef, (entry) => setContainerWidth(entry.contentRect.width));

		const linesGap = 4;
		const minLineWidth = 2;

		const maxPossibleLines = Math.floor((containerWidth - linesGap) / (minLineWidth + linesGap));

		const getProgressVariant = () => {
			const commonRequiredProgressProps: Required<ProgressProps> = {
				activeLineColor,
				lastActiveLineBorderColor,
				maxValue,
				value,
				notActiveLineColor,
				progressLineHeight,
			};

			if (maxValue > maxPossibleLines) {
				return <PercentageProgress {...commonRequiredProgressProps} />;
			}

			return <DashedProgress {...commonRequiredProgressProps} linesGap={linesGap} />;
		};

		return (
			<Box
				dataCy={dataCy}
				ref={containerRef}
				css={{
					display: 'inline-flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					boxSizing: 'border-box',
					...css,
				}}
				{...props}
			>
				{getProgressVariant()}
			</Box>
		);
	},
);
