import React, {ComponentPropsWithoutRef, ReactNode, forwardRef, useCallback, useMemo} from 'react';
import {BaseComponentProps} from '../../../types';
import {ProgressBarBox} from './index.styled';
import {BoxplotChartContext, BoxplotChartContextValue} from '../../context';

type ChildrenCbArgs = {
	chartMinValueProgress: number;
	chartMaxValueProgress: number;
	quartile1Progress: number;
	quartile2Progress: number;
	quartile3Progress: number;
};

type ProgressBarRootProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	BaseComponentProps & {
		/**
		 * The minimum value of the data range.
		 * Percentage progress values are based on the provided minValue and maxValue.
		 */
		minValue: number;

		/**
		 * The maximum value of the data range.
		 * Percentage progress values are based on the provided minValue and maxValue.
		 */
		maxValue: number;

		/**
		 * The lowest data point in the data set excluding any outliers
		 */
		chartMinValue: number;

		/**
		 * The highest data point in the data set excluding any outliers
		 */
		chartMaxValue: number;

		/**
		 * The median of the lower half of the dataset.
		 */
		quartile1: number;

		/**
		 * The middle value in the data set
		 */
		quartile2: number;

		/**
		 * The median of the upper half of the dataset.
		 */
		quartile3: number;

		/**
		 * Item Content. All progress values in float (0.33 = 33%).
		 * */
		children?: ReactNode | ((args: ChildrenCbArgs) => React.JSX.Element);
	};

/**
 * @see https://en.wikipedia.org/wiki/Box_plot
 */
export const BoxPlotChartRoot = forwardRef<HTMLDivElement, ProgressBarRootProps>(
	(
		{
			dataCy = 'ui-kit-boxplot-chart-root',
			minValue,
			maxValue,
			chartMinValue,
			chartMaxValue,
			quartile1,
			quartile2,
			quartile3,
			children,
			...props
		},
		forwaredRef,
	) => {
		const getValueProgress = useCallback(
			(value: number) => {
				return (value - minValue) / (maxValue - minValue);
			},
			[maxValue, minValue],
		);

		const contextValue = useMemo<BoxplotChartContextValue>(
			() => ({
				chartMinValueProgress: getValueProgress(chartMinValue),
				chartMaxValueProgress: getValueProgress(chartMaxValue),
				quartile1Progress: getValueProgress(quartile1),
				quartile2Progress: getValueProgress(quartile2),
				quartile3Progress: getValueProgress(quartile3),
			}),
			[chartMinValue, chartMaxValue, getValueProgress, quartile1, quartile2, quartile3],
		);

		return (
			<BoxplotChartContext.Provider value={contextValue}>
				<ProgressBarBox
					dataCy={dataCy}
					role='progressbar'
					aria-valuemax={maxValue}
					aria-valuemin={minValue}
					data-chart-min-value={chartMinValue}
					data-chart-max-value={chartMaxValue}
					data-quartile-1={quartile1}
					data-quartile-2={quartile2}
					data-quartile-3={quartile3}
					ref={forwaredRef}
					{...props}
				>
					{typeof children === 'function'
						? children({
								chartMinValueProgress: contextValue.chartMinValueProgress,
								chartMaxValueProgress: contextValue.chartMaxValueProgress,
								quartile1Progress: contextValue.quartile1Progress,
								quartile2Progress: contextValue.quartile2Progress,
								quartile3Progress: contextValue.quartile3Progress,
						  })
						: children}
				</ProgressBarBox>
			</BoxplotChartContext.Provider>
		);
	},
);
