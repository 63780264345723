import {FlexBox} from '@esgillc/ui-kit/layout';
import {Modal} from '@esgillc/ui-kit/modal';
import React from 'react';
import NavigationBar from '../../shared/components/navigation-bar/navigation-bar';
import styles from './sight-words-settings-step.module.less';
import {BasePrintSettings} from '../../shared/components/base-print-settings/base-print-settings';
import {Loader, ServiceLoader} from '@esgillc/ui-kit/loader';
import {Buttons} from '@esgillc/ui-kit/button';
import TemplatePreview from '../../shared/components/template-preview/template-preview';
import DataService from './data-service';
import {SightWordsTemplate} from '../../shared/templates/sight-words/sight-words-template';
import {SightWordsReport} from '../../shared/templates/sight-words/types';
import {OptionModel, SightWordsPrintSettings, StudentStepState} from '../../shared/types';
import {ReportGeneratorService} from '../../shared/templates/sight-words/report-generator-service';
import {SightWordsPdfDownloadingService} from '../../shared/services/sight-words-pdf-downloading-service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

interface Props {
  unitData: StudentStepState;
  testId: number;
  currentStep: number;
  totalStepsCount: number;
  printData: SightWordsPrintSettings;
  goToBack: (data: SightWordsPrintSettings) => void;
  goToPreview: (data: SightWordsPrintSettings, report: SightWordsReport) => void;
  pdfDownloaded: (report: SightWordsReport) => void;
}

class State {
  report: SightWordsReport;
  printData: SightWordsPrintSettings;

  constructor(data: SightWordsPrintSettings) {
    this.printData = data;
  }
}

export default class SightWordsSettingsStep extends React.PureComponent<Props, State> {
  public readonly fontSizeOptions: OptionModel<string>[] = [
    new OptionModel('30pt', '30 pts'),
    new OptionModel('40pt', '40 pts'),
    new OptionModel('62pt', '62 pts'),
  ];
  private readonly downloadPdfService: SightWordsPdfDownloadingService;
  private readonly reportGenerator: ReportGeneratorService;
  private readonly destroy$ = new Subject<void>();
  private readonly service: DataService;

  constructor(props) {
    super(props);
    const normalizedPrintData = this.props.printData == null
        ? new SightWordsPrintSettings(this.fontSizeOptions[0], 1)
        : this.props.printData;
    this.state = new State(normalizedPrintData);
    this.reportGenerator = new ReportGeneratorService();
    this.downloadPdfService = new SightWordsPdfDownloadingService();
    this.service = new DataService();
  }

  componentDidMount() {
    this.service.init(this.props.testId).subscribe(result => {
      const selectedQuestions = this.state.printData?.selectedQuestions == null
        ? result
        : this.state.printData.selectedQuestions.filter(x => result.some(z => z.questionID == x.questionID));
      this.onSettingsChanged({
        allQuestions: result,
        selectedQuestions: selectedQuestions,
      } as SightWordsPrintSettings);
    });
  }

  componentWillUnmount() {
    this.service.destroy();
    this.downloadPdfService.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  render() {
    return <>
      <ServiceLoader trackingService={this.downloadPdfService}/>
      <Modal.Body>
        <FlexBox align='stretch' justify='stretch' direction='column' className={styles.body}>
          <div className={styles.unitName}>
            {this.props.unitData.students[0]?.units?.[0]?.name}
          </div>
          <div className={styles.bodyTitle}>
            Select the settings for your print-out.
          </div>
          <FlexBox align='stretch' justify='space-around' direction='row' wrap='wrap'>
            {this.renderSettings()}
            {this.renderPreview()}
          </FlexBox>
        </FlexBox>
      </Modal.Body>
      <Modal.Footer>
        <FlexBox align='center' justify='stretch' className={styles.footer}>
          <FlexBox justify='end' className={styles.navigation}>
            <NavigationBar
                prevStepEnabled={true}
                nextStepEnabled={false}
                currentStep={this.props.currentStep}
                totalSteps={this.props.totalStepsCount}
                previousClicked={() => this.props.goToBack(this.state.printData)}/>
          </FlexBox>
          <FlexBox justify='end' className={styles.buttons}>
            <Buttons.Outlined onClick={e => this.props.goToPreview(this.state.printData, this.state.report)}>Edit Sight Words</Buttons.Outlined>
            <Buttons.Contained disabled={this.downloadDisabled()} onClick={e => this.onDownloadPdf()}>Download</Buttons.Contained>
          </FlexBox>
        </FlexBox>
      </Modal.Footer>
    </>;
  }

  private renderSettings() {
    return <FlexBox align='stretch' justify='stretch' direction='column' className={styles.settingsContainer}>
      <BasePrintSettings
          fontSizeWarningMessage='The selected font size is too large to fit some words within the practice sheet. The font size will be reduced to fit all words.'
          isFontSizeOptimized={this.state.report?.details.isFontSizeOptimized}
          fontSizeOptions={this.fontSizeOptions}
          studentsCount={this.props.unitData.students.length}
          selectedFontSize={this.state.printData.selectedFontSize}
          copiesCount={this.state.printData.copiesCount}
          wordsCount={this.state.printData.allQuestions.length}
          settingsChanged={(settings) => this.onSettingsChanged(settings as SightWordsPrintSettings)}/>
    </FlexBox>;
  }

  protected renderPreview() {
    return <TemplatePreview className={styles.preview}>
      {
          this.state.report &&
          <SightWordsTemplate
              className={styles.template}
              details={this.state.report.details}
              student={this.state.report.students[0]}
              words={this.state.report.words}/>
      }
    </TemplatePreview>;
  }

  private onSettingsChanged(settings: SightWordsPrintSettings) {
    const newSettings = {...this.state.printData, ...settings};
    this.setState({printData: newSettings});
    this.generateReport(newSettings);
  }

  private generateReport(settings) {
    this.reportGenerator
        .generateReport(this.props.unitData, settings)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.setState({report: result});
        });
  }

  private onDownloadPdf() {
    const report = this.state.report;
    this.downloadPdfService
        .downloadPdf(report)
        .subscribe(result => {
          if (result.isSuccess) {
            this.props.pdfDownloaded(report);
          }
        });
  }

  private downloadDisabled(){
    return this.state.printData.selectedQuestions == null
        || this.state.printData.selectedQuestions.length < 1
        || this.state.printData.copiesCount < 1
        || isNaN(this.state.printData.copiesCount);
  }
}
