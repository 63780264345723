import './common.less';
import factory from 'mxgraph';

const mxImageBasePath = 'https://esgi-mxgraph.s3.amazonaws.com';

(window as any).mxImageBasePath = mxImageBasePath;

export default factory({
	mxImageBasePath: mxImageBasePath,
	mxLoadStylesheets: false,
	mxLoadResources: false,
});