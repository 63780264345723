import {RoutesMap} from '@esgi/main/libs/core';
import {RedirectCountryLinks} from '../../../redirect-country-links';

export class ACTemplate {
	public static Render() {
		return <div className='registration-modal fixed'>
      <div className='inner-modal'>
        <div className='ac-header'>
          <div className='top-row'>
						<span>Already have an account?&nbsp;
							<a href='/login/'>
								<b>LOGIN</b>
							</a>
						</span>
          </div>
          <div className='title-container'>
            <div className='title'><b>Account Sign Up</b></div>
          </div>
        </div>
        <div className='fields'>
          <div className='trial-content'>

            <div data-bind='render:ac'/>
            <ko data-bind='ifnot:school.schools.length === 0'>
              <label>School</label>
              <div data-bind='render:school'>
              </div>
            </ko>
            <div data-bind='render:email'/>
            <div data-bind='if:showNameAndPasswordFields()'>
              <div data-bind='render:username'/>
              <div data-bind='render:password'/>
            </div>
            <div className='terms-policy'>
                                <span>
                                    By creating an account, you are agreeing to our&nbsp;
                                  <a href={RoutesMap.esgiAgreement}>
																			Terms of Use
																		</a>
                                    &nbsp;and&nbsp;
																		<a href={RoutesMap.privacyPolicy}>
																			Privacy Policy
																		</a>.
                                </span>
            </div>
						<div className='redirectCountryLinksContainer'>
							<RedirectCountryLinks />
						</div>
          </div>
        </div>
        <div className='footer'>
          <div className='actions row'>
            <div className='col-sm-2 col-md-3 hidden-xs'/>
            <div className='col-xs-12 col-sm-10 col-md-9 actions-container'>
              <button className='btn cancel-btn' data-bind='click:back'>BACK</button>
              <button className='btn submit-btn' data-bind='enable: !userCreating(), click:next'>
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>;

	}
}
