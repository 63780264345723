import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type LanguagesAndTemplatesFormType = FormGroup<{
	title: FormControl<string>,
	templateName: FormControl<string>,
	message: FormControl<string>,
},
	ControlValue<{
		title: FormControl<string>,
		templateName: FormControl<string>,
		message: FormControl<string>,
	}>>

export const createLanguagesAndTemplatesForm = ():
	LanguagesAndTemplatesFormType => new FormGroup({
	title: new FormControl(''),
	templateName: new FormControl(''),
	message: new FormControl(''),
});
