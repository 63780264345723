import {SkeletonBox} from './index.styled';
import {SkeletonShape} from '@esgi/main/kits/common';

export function TestListSkeleton() {
	return(
		<SkeletonBox>
			{new Array(10).fill(null).map((_, index) => (
				<SkeletonShape width='100%' height={40} key={index} />
			))}
		</SkeletonBox>
	);
}