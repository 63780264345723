import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CardRoot as AssignmentCardRoot} from '../assignment-card-root/index.styled';

export const CardRoot = styled(AssignmentCardRoot, {
	cursor: 'default',
});

export const InfoWithTranscript = styled(Box, {
	display: 'inline-grid',
	gap: 6,
	justifyItems: 'end',
});
