import {useDatePickerRootContext} from '../../context';
import {TabID} from '../../types';
import {styled} from '../../../../theme';
import {ToggleGroup} from '../../../toggle-group';
import {Text} from '../../../../typography';
import {BaseComponentProps} from '@esgi/ui';
import {forwardRef} from 'react';

type DateRangeToggleProps = {
	/** The currently selected tab (start or end date). */
	selectedTabID: TabID

	/** Callback function to handle tab change. */
	onChange: (tabID: TabID) => void
} & BaseComponentProps

export const DateRangeToggle = forwardRef<HTMLDivElement, DateRangeToggleProps>(({selectedTabID, onChange, dataCy = 'date-range-toggle'}, ref) => {

	const {dateObjects} = useDatePickerRootContext();

	return <Root dataCy={dataCy} value={selectedTabID} ref={ref}>
		<Content>
			<Item dataCy={`${dataCy}-start`} value='start' onClick={() => onChange('start')}>
				<Text size='medium' bold>
					Start Date
				</Text>
			</Item>
			<Item dataCy={`${dataCy}-end`} value='end' disabled={!dateObjects.length}
				  onClick={() => onChange('end')}>
				<Text size='medium' bold>
					End Date
				</Text>
			</Item>
		</Content>
	</Root>;
});

const Root = styled(ToggleGroup.Root, {
	width: '100%',
	height: '40px',
	borderRadius: '6px',
	backgroundColor: '$background',
});

const Content = styled(ToggleGroup.Content, {
	height: '100%',
	width: '100%',
	display: 'flex',
	background: 'none',
});

const Item = styled(ToggleGroup.Item, {
	flex: 1,
});
