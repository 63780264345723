interface Props {
	width: string;
	height: string;
}

export function BellIcon({width, height}: Props) {
	return <svg data-cy={'bell-icon'} width={width} height={height} viewBox='0 0 12 14' fill='none'
							xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M6.25 14C7.03974 14 7.6859 13.3538 7.6859 12.5641H4.8141C4.8141 13.3538 5.45308 14 6.25 14ZM10.5577 9.69231V6.10256C10.5577 3.89846 9.38025 2.05333 7.32692 1.56513V1.07692C7.32692 0.481026 6.8459 0 6.25 0C5.6541 0 5.17308 0.481026 5.17308 1.07692V1.56513C3.11256 2.05333 1.94231 3.89128 1.94231 6.10256V9.69231L0.506409 11.1282V11.8462H11.9936V11.1282L10.5577 9.69231Z'
			fill='#FEC702'/>
	</svg>;
}