import {Student} from '@esgi/main/libs/store';
import {StudentAvatar} from '../student-avatar';
import {StudentCounter} from '../student-counter';

interface Props {
	studentCounter: number;
	student: Student | undefined;
	counterTitle: string;
}

export function StudentPanel({studentCounter, student, counterTitle}: Props) {
	if (student && studentCounter === 1) {
		const {firstName, lastName, photoUrl} = student;
		return <StudentAvatar
			firstName={firstName}
			lastName={lastName}
			photoUrl={photoUrl}
		/>;
	}

	return <StudentCounter count={studentCounter} title={counterTitle}/>;
}
