import {TestType} from '@esgi/core/enums';
import {Modal} from '@esgillc/ui-kit/modal';
import {Tabs, TabPanel} from '@esgillc/ui-kit/tabs-with-panel';
import {YesNo} from '../yes-no';
import {SingleScore} from '../single-score';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../hooks';
import {hasIEPLogic} from 'modules/assessments';
import IEPGoalTab from '../../components/iep-goal';
import styles from './body.module.less';

interface Props {}

export function Body({}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const iepGoal = useBehaviorSubject(service.iepGoal$);
	const iepEditMode = useBehaviorSubject(service.iepEditMode$);
	const tabs = useBehaviorSubject(service.tabs$);
	const activeTab = useBehaviorSubject(service.activeTab$);

	const renderDetails = () => {
		if (testSessionDetails.testType === TestType.YN) {
			return <YesNo/>;
		}
		if (testSessionDetails.testType === TestType.Score) {
			return <SingleScore/>;
		}
	};

	return (
		<Modal.Body className={styles.modalBody}>
			{(hasIEPLogic() && iepGoal)
				? (
					<div className={styles.tabs}>
						<Tabs
							items={tabs}
							active={activeTab}
							onSelect={({tab}) => service.setActiveTab(tab.name)}
						/>
						<TabPanel key='details' name='details'>
							{renderDetails()}
						</TabPanel>
						<TabPanel key='iep' name='iep'>
							<IEPGoalTab
								service={service.serviceIEP}
								editMode={iepEditMode}
							/>
						</TabPanel>
					</div>
				)
				: renderDetails()
			}
		</Modal.Body>
	);
}
