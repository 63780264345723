import * as React from 'react';
import type {SVGProps} from 'react';

export function Play(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path fillRule='evenodd' clipRule='evenodd' d='M14.7935 11.4853C15.0795 11.7696 15.0795 12.2304 14.7935 12.5147L11.758 14.7868C11.4721 15.0711 11.0084 15.0711 10.7225 14.7868C10.4365 14.5025 10.5079 13.875 10.5079 13.5L10.508 10.125C10.5081 9.75 10.4365 9.49747 10.7225 9.2132C11.0084 8.92893 11.4721 8.92893 11.758 9.2132L14.7935 11.4853Z' fill='#333333'/>
		</svg>
	);
}
