import {OptionItem} from '@esgi/main/features/teacher/home';
import {IconComponent} from '@esgi/ui';
import {Test, StudentType} from './types';
import {File, StudentProgress} from '@esgi/ui/icons';

export {type TranslationModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/modules/ai-analysis/translate-report/translation-model';

export interface ReportTypeOption extends OptionItem {
	content?: string;
	icon?: IconComponent;
	disabled?: boolean;
}

export const reportTypeList: ReportTypeOption[] = [
	{
		value: 'teacher',
		label: 'Student Analysis',
		icon: StudentProgress,
		content:
			'Individualized summary report highlighting student\'s strengths and improvement areas.',
	},
	{
		value: 'parent',
		label: 'Parent Report',
		icon: File,
		content:
			'Parent-focused report summarizing performance and suggested activities.',
	},
];

export interface ReportGenerateParams {
	tests: Test[];
	student: StudentType;
	reportType: ReportTypeOption;
}

export interface LangTypeOption extends OptionItem {
	disabled?: boolean;
}

export const langTypeList: LangTypeOption[] = [
	{
		value: 'spanish',
		label: 'Spanish',
	},
	{
		value: 'mandarin',
		label: 'Mandarin',
	},
	{
		value: 'korean',
		label: 'Korean',
	},
	{
		value: 'japanese',
		label: 'Japanese',
	},
	{
		value: 'french',
		label: 'French',
	},
	{
		value: 'german',
		label: 'German',
	},
	{
		value: 'portuguese',
		label: 'Portuguese',
	},
	{
		value: 'russian',
		label: 'Russian',
	},
];

export interface LangLabel {
	english: string;
	spanish: string;
	mandarin: string;
	korean: string;
	japanese: string;
	french: string;
	german: string;
	portuguese: string;
	russian: string;
}

export const nameLangLabel: LangLabel = {
	english: 'Name',
	spanish: 'Nome',
	mandarin: '名字',
	korean: '이름',
	japanese: '氏名',
	french: 'Nom',
	german: 'Name',
	portuguese: 'Nome',
	russian: 'имя',
};

export const gradeLangLabel: LangLabel = {
	english: 'Grade',
	spanish: 'Grado',
	mandarin: '年纪',
	korean: '학년',
	japanese: '学年',
	french: 'Grade',
	german: 'Grad',
	portuguese: 'Nota',
	russian: 'классе',
};

export const teacherLangLabel: LangLabel = {
	english: 'Teacher',
	spanish: 'Profesor',
	mandarin: '老师',
	korean: '선생님',
	french: 'Professeur',
	german: 'Lehrer',
	portuguese: 'Professor',
	japanese: '教師',
	russian: 'учитель',
};
