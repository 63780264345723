import {styled} from '../../../../theme';
import {Text} from '../../../../typography';
import {Box} from '../../../../layout/box';
import {CSS} from '@stitches/react';

const inputPaddingInline = 12;
export const ContentEditableDiv = styled('div', {
	width: '100%',
	padding: '26px 12px 5px',
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$primaryMuted',
	borderRadius: 6,
	boxSizing: 'border-box',
	fontSize: '$medium',
	fontWeight: '600',
	lineHeight: '16px',
	outline: 'none',
	minHeight: 400,
	whiteSpace: 'pre-wrap',
});

export const Placeholder = styled(Text, {
	position: 'absolute',
	width: `calc(100% - 2 * 13px)`,
	left: '13px',
	boxSizing: 'border-box',
	top: 1,
	paddingTop: 5,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	pointerEvents: 'none',
	appearance: 'none',
	outline: 'none',
	fontWeight: 500,
	color: '$lowContrast',
	fontSize: '$xSmall',
});

export const ErrorWrapper = styled(Box, {
	paddingLeft: inputPaddingInline,
	paddingRight: inputPaddingInline,
	color: '$negativeVivid',
	fontSize: '$xxSmall',
	fontWeight: 700,
	padding: '3px 6px',
	backgroundColor: '$negativeBackground',
	border: '1px solid $red92',
	borderRadius: 3,
	position: 'absolute',
	bottom: 0,
	left: 6,
	transform: 'translateY(50%)',
	lineHeight: '10px',
});

const baseErrorStyles: CSS = {
	[`& > ${Placeholder}`]: {
		color: '$negative',
	},

	[`& > ${ContentEditableDiv}`]: {
		color: '$negative',
		borderColor: '$negativeHighlight',
	},
};

const valuedFocusedPlaceholderStyled: CSS = {
	color: '$primary72',
};

export const Wrapper = styled(Box, {
	position: 'relative',
	marginBottom: '12px',

	[`& > ${Placeholder}`]: {
		backgroundColor: '$vivid',
		transition: 'background-color .3s, color .3s, font-size .3s, font-weight .3s, lint-height .3s',
	},

	[`& > ${ContentEditableDiv}`]: {
		backgroundColor: '$vivid',
		color: '$primary',
		transition: 'background-color .3s, border-color .3s, color .3s',
	},

	[`&:has(> ${ContentEditableDiv}:hover:not([data-disabled=true]):not([data-error=true]):not(:focus))`]: {
		[`& > ${Placeholder}`]: {
			color: '$secondary80',
			backgroundColor: '$secondaryBackground',
		},

		[`& > ${ContentEditableDiv}`]: {
			backgroundColor: '$secondaryBackground',
			borderColor: '$secondary80',
			color: '$secondary',
		},
	},
	[`&:has(> ${ContentEditableDiv}[data-disabled=true]:not([data-error=true]):not(:focus))`]: {
		[`& > ${Placeholder}`]: {
			color: '$lowContrast',
		},
	},
	[`&:has(> ${ContentEditableDiv}[data-error=true]:hover:not([data-disabled=true]):not(:focus))`]: {
		[`& > ${Placeholder}`]: {
			backgroundColor: '$secondaryBackground',
		},

		[`& > ${ContentEditableDiv}`]: {
			backgroundColor: '$secondaryBackground',
			borderColor: '$secondary80',
		},
	},

	[`&:has(> ${ContentEditableDiv}:focus)`]: {
		[`& > ${Placeholder}`]: valuedFocusedPlaceholderStyled,
	},

	[`&:has(> ${ContentEditableDiv}[data-disabled=true])`]: {
		[`& > ${ContentEditableDiv}`]: {
			borderColor: '$mild',
			cursor: 'default',
			resize: 'none',
			userSelect: 'none',
			color: '$neutral40',
		},
	},

	variants: {
		valued: {
			true: {
				[`&:has(> ${ContentEditableDiv}:not([data-disabled=true]):not(:hover):not(:focus))`]: {
					[`& > ${ContentEditableDiv}`]: {
						color: '$primary',
					},
				},
			},

			false: {
				[`&:has(> ${ContentEditableDiv})`]: {
					[`& > ${Placeholder}`]: {
						color: '$lowContrast',
					},
				},
			},
		},

		withError: {
			true: {
				[`&:has(> ${ContentEditableDiv}:not([data-disabled=true]))`]: baseErrorStyles,
				[`&:has(> ${ContentEditableDiv}:hover:not([data-disabled=true]))`]: baseErrorStyles,
				[`&:has(> ${ContentEditableDiv}:not(:hover):not([data-disabled=true]))`]: baseErrorStyles,
				[`&:has(> ${ContentEditableDiv}:not([data-disabled=true]):not(:hover):not(:focus))`]: baseErrorStyles,
			},
		},
	},
});
