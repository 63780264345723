import {BehaviorSubject, Subscription} from 'rxjs';

type QueueItem = {
	imageUrl: string
	imageElement: HTMLImageElement
}

export class ImageQueueService {
	private imageQueue: BehaviorSubject<QueueItem[]>;
	private busy = false;
	private $sub: Subscription;

	constructor() {
		this.imageQueue = new BehaviorSubject<QueueItem[]>([]);
		this.$sub = this.imageQueue.subscribe((items) => {
			if (items.length > 0 && !this.busy) {
				this.busy = true;

				const item = this.imageQueue.value[0];
				this.imageQueue.next(this.imageQueue.value.slice(1));

				this.executeTick(item, () => {
					this.busy = false;
					this.imageQueue.next(this.imageQueue.value);
				});
			}
		});
	}

	public addToQueue(imageUrl: string, imageElement: HTMLImageElement) {
		this.imageQueue.next(this.imageQueue.value.concat({imageUrl, imageElement}));
	}

	public destroy() {
		this.busy = false;
		this.imageQueue = null;
		this.$sub.unsubscribe();
	}

	public clear() {
		this.busy = false;
		this.imageQueue.next([]);
	}

	private executeTick(item, callback: () => void) {
		item.imageElement.src = item.imageUrl;
		item.imageElement.onload = () => {
			callback();
		};
		item.imageElement.onerror = () => {
			callback();
		};
	}
}

export const imageQueueService = new ImageQueueService();