import * as React from 'react';
import type {SVGProps} from 'react';

export function View2(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M8 9.68421C8 8.75405 8.75405 8 9.68421 8H13.0526C13.9828 8 14.7368 8.75405 14.7368 9.68421V13.0526C14.7368 13.9828 13.9828 14.7368 13.0526 14.7368H9.68421C8.75405 14.7368 8 13.9828 8 13.0526V9.68421Z'
				fill='#333333'
			/>
			<path
				d='M8 18.9474C8 18.0172 8.75405 17.2632 9.68421 17.2632H13.0526C13.9828 17.2632 14.7368 18.0172 14.7368 18.9474V22.3158C14.7368 23.246 13.9828 24 13.0526 24H9.68421C8.75405 24 8 23.246 8 22.3158V18.9474Z'
				fill='#333333'
			/>
			<path
				d='M17.2632 18.9474C17.2632 18.0172 18.0172 17.2632 18.9474 17.2632H22.3158C23.246 17.2632 24 18.0172 24 18.9474V22.3158C24 23.246 23.246 24 22.3158 24H18.9474C18.0172 24 17.2632 23.246 17.2632 22.3158V18.9474Z'
				fill='#333333'
			/>
			<path
				d='M17.2632 9.68421C17.2632 8.75405 18.0172 8 18.9474 8H22.3158C23.246 8 24 8.75405 24 9.68421V13.0526C24 13.9828 23.246 14.7368 22.3158 14.7368H18.9474C18.0172 14.7368 17.2632 13.9828 17.2632 13.0526V9.68421Z'
				fill='#333333'
			/>
		</svg>
	);
}
