import {GridBox} from '@esgi/ui/layout';
import {AlertHeaderStyled} from './alert-header/index.styled';
import {Avatar} from '@esgi/ui/avatar';
import {SkeletonShape} from '@esgi/main/kits/common';
import {AlertBody} from './components.styled';
import {AlertFooter} from './alert-footer';
import {noop} from 'underscore';
import {Container as TestNameContainer} from './test-name/index.styled';

type Props = {
	onCloseAlert: VoidFunction;
};

export function DefaultSkeleton({onCloseAlert}: Props) {
	return (
		<>
			<AlertHeaderStyled withBacklight={false} onCloseIconClick={onCloseAlert}>
				<GridBox flow='column' align='center' justify='between' gap='4'>
					<GridBox flow='column' gap='3' align='center' justify='start'>
						<Avatar.Root size='xs' skeleton />

						<GridBox flow='column' gap='2' align='center' justify='start'>
							<SkeletonShape width={90} height={12} />
							<SkeletonShape width={70} height={12} />
							<SkeletonShape width={120} height={12} />
						</GridBox>
					</GridBox>
				</GridBox>
			</AlertHeaderStyled>

			<AlertBody>
				<TestNameContainer>
					<SkeletonShape width={12} height='100%' />
					<SkeletonShape width={147} height={20} />
					<SkeletonShape width={72} height={24} />
				</TestNameContainer>

				<GridBox flow='column' gap='3' columns='2' align='center'>
					<SkeletonShape width='100%' height={40} />
					<SkeletonShape width='100%' height={40} />
				</GridBox>

				<SkeletonShape width='100%' height={56} />
			</AlertBody>

			<AlertFooter saveDisabled onSave={noop} skeleton />
		</>
	);
}
