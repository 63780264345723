import $ from 'jquery';
import moment, {Moment} from 'moment';
import {IRenderable, Renderable} from '../renderable';
import {observable, observableArray} from '../decorators';
import {resolvedPromise} from '@esgi/deprecated/utils';
import {FormFieldValidator, ValidationContainer} from './validation';
import {
	CheckboxFieldTemplate,
	ColorPickerFieldTemplate,
	FormFieldTemplate,
	NumberFieldTemplate,
	SelectFormFieldTemplate,
	TextAreaFieldTemplate,
} from './template';

export interface IFormElement extends IRenderable {
	valid: KnockoutObservable<boolean>;

	validate(silent: boolean): JQueryPromise<boolean>;

	validation: ValidationContainer;

	clearValidation(recurse);

	dispose();

}

export class FormElement extends Renderable implements IFormElement {

	public validation = new ValidationContainer(this);

	@observableArray()
	public innerElements: IFormElement[] = [];

	public detectChanges() {
		ko.pureComputed(() => {
			return this.serialize();
		}).pureSubscribe(() => {
			this.hasChanged = true;
		});
	}

	public serialize(): any {
		return null;
	}

	public hasChanged: boolean = false;

	public valid = ko.computed({
		read: () => {
			let validators = this.innerElements.map(v => v.validation);
			validators.push(this.validation);

			return validators.map(v => v.valid()).reduce((a, b) => a && b, true);
		},
		deferEvaluation: true,
	}).extend({notify: 'always'});

	public validate(silent: boolean = false): JQueryPromise<boolean> {
		let validations = this.innerElements.map(v => v.validate(silent));

		return $.whenAll(validations).then(valids => {
			return valids.reduce((a, b) => a && b, true);
		}).then(valid => {
			if (valid) {
				return this.validation.validate(silent);
			}

			return resolvedPromise(false);
		}).fail(() => {
			return false;
		});
	}

	protected addElement<T extends IFormElement>(f: T, ...validators: FormFieldValidator[]) {
		f.validation.validators = f.validation.validators.concat(validators);
		this.innerElements.push(f);
		return f;
	}

	protected removeElement(f: IFormElement) {
		this.innerElements = this.innerElements.filter(i => i !== f);
	}

	public clearValidation(recurse = false) {
		this.validation.validationResults.removeAll();
		this.validation.removeError();
		if (recurse) {
			for (const e of this.innerElements) {
				e.clearValidation(recurse);
			}
		}
	}

	dispose() {
		this.validation.validationResults.removeAll();
		this.validation.removeError();

		for (const element of this.innerElements) {
			element.dispose();
		}

		return super.dispose();
	}
}

export interface IFormField<T = any> {
	tabindex: number;
	title: string;
	float: boolean;
	validation: FieldValidationContainer;
	displayValue: KnockoutComputed<any>;
	focused: KnockoutObservable<boolean>;
	visible: KnockoutObservable<boolean>;
	disabled: KnockoutObservable<boolean>;
	value: KnockoutObservable<T>;
}

export class FormField<T = any> extends FormElement implements IFormField<T> {
	public value = ko.observable<T>();

	constructor(value?: T | KnockoutObservable<T>) {
		super();

		if (value && typeof value == 'function') {
			(<any>this.value) = value;
		} else {
			this.value(<T>value);
		}

		this.value.pureSubscribe(() => {
			const silent = !this.showValidation();

			this.validation.validate(silent);
		});

		this.focused.subscribe(() => {
			$(this).triggerHandler('focus');
		});
	}

	template = () => {
		return FormFieldTemplate.render();
	};

	@observable()
	tabindex = 0;

	@observable()
	title = '';

	@observable()
	float: boolean = true;

	@observable()
	type = 'text';

	validation: FieldValidationContainer = new FieldValidationContainer(this);

	displayValue = ko.computed<any>({
		read: () => {
			return this.value();
		},
		write: (value) => {
			this.value(value);
		},
	});

	//changed = ko.observable<boolean>();

	// PROXY PROPERTIES FOR BACKWARD COMPATIBILITY


	// this is no more PROXY
	showSuccessValidation = ko.computed({
		read: () => {
			let value = this.value();
			// 0 is valid value
			if (!value && (<any>value !== 0)) {
				return false;
			}

			return this.validation.showSuccessValidation();
		}, deferEvaluation: true,
	});

	showErrorValidation = ko.computed({
		read: () => {
			return this.validation.showErrorValidation();
		}, deferEvaluation: true,
	});

	showValidation = ko.computed({
		read: () => {
			return this.validation.showValidation();
		}, deferEvaluation: true,
	});

	showValidationInProgress = ko.computed({
		read: () => {
			return this.validation.showValidationInProgress();
		}, deferEvaluation: true,
	});
	// PROXY PROPERTIES FOR BACKWARD COMPATIBILITY


	// weird, but observable do not work here, but writable computed does
	_focused = false;
	focused = ko.observable<boolean>();
	visible = ko.observable(true);
	disabled = ko.observable(false);

	serialize(): any {
		return this.value();
	}

	public validate(silent?: boolean): JQueryPromise<boolean> {
		if (this.validation) {
			return this.validation.validate(silent);
		}

		return resolvedPromise(true);
	}

	events = {
		focus: (callback: () => void) => {
			$(this).on('focus', callback);
		},
	};
}

export class CheckboxField extends FormField<boolean> {
	template = () => {
		return CheckboxFieldTemplate.render();
	};
}

export class TextAreaField<T = any> extends FormField<T> {
	template = () => {
		return TextAreaFieldTemplate.render();
	};
}

export class NumberField extends FormField<number> {
	constructor(value?: number) {
		super();

		this._value(value);
		this.type = 'number';

		this.value.pureSubscribe(() => {
			const silent = !this.showValidation();

			this.validation.validate(silent);
		});

	}

	private _value = ko.observable<number>();

	value = ko.computed<number>({
		read: () => {
			return this._value();
		},
		write: (value) => {
			let temp = value;
			if (typeof value == 'number') {
				temp = value;
			} else if (typeof value == 'string') {
				temp = parseInt(value);
			} else {
				temp = value;
			}

			this._value(temp);
		},
	});

	@observable()
	min: number;

	@observable()
	max: number;

	template = () => {
		return NumberFieldTemplate.render();
	};
}

export class SelectFormField<T = any> extends FormField<T> {

	@observableArray()
	source: T[] = [];

	@observable()
	optionsText = 'name';

	@observable()
	caption: string;

	@observable()
	valueAllowUnset = false;

	@observable()
	chosen: SelectFieldChosenSettings = new SelectFieldChosenSettings();

	template = () => {
		return SelectFormFieldTemplate.render();
	};
}

export class SelectFieldChosenSettings {
	enable: boolean = false;
	width: string = '100%';
	/*eslint-disable camelcase*/
	disable_search_threshold: number = 10;
	placeholder_text_single: string = '';
	search_contains = true;
	/*eslint-enable camelcase*/
	tabindex: number = 0;
	disabled: KnockoutObservable<boolean>;
}

export class ColorPickerField extends FormField {

	constructor(value?: string, extraColors?: string[]) {
		super(value);

		if (extraColors) {
			this.options = this.options.concat(extraColors);
		}

		this.value.subscribe((value) => {
			if (value) {

				if (value[0] != '#') {
					value = '#' + value;
				}

				if (value.length > 7) {
					value = value.substring(0, 7);
				}

				this.value(value);
			}
		});

		let color = '#CDCDCD';
		if (value) {
			if (value[0] === '#') {
				color = value;
			} else {
				color = '#' + value;
			}
		}

		if (color) {
			const exist = this.options.indexOf(color) > -1;

			if (!exist) {
				this.options.push(color);
			}
		}

		this.value(color);
	}

	afterRender(rootElement: JQuery) {
		const picker = $('select', rootElement).ace_colorpicker();
		picker.on('change', (e) => {
			this.value((e.target as any).value);
		});

		return super.afterRender(rootElement);
	}

	findErrorElement() {
		return this.rootElement;
	}

	@observableArray()
	options = [
		'#CDCDCD',
		'#90CAF9',
		'#80CBC4',
		'#CE93D8',
		'#FFF59D',
		'#FFCC80',
		'#9FA8DA',
		'#A5D6A7',
	];

	template = () => {
		return ColorPickerFieldTemplate.render();
	};

	selectColor(color: string) {
		this.value(color);
	}
}

export class DateField extends FormField<Moment> {
	constructor(date?: Moment | Date | string) {
		super();

		if (date) {
			this._value(moment(date));
		}
	}

	private _value = ko.observable<Moment>();

	value = ko.computed<Moment>({
		read: () => {
			return this._value();
		},
		write: (value) => {
			this._value(value);
		},
	});
}

export class FieldValidationContainer extends ValidationContainer {

	constructor(public element: FormField, ...validators: FormFieldValidator[]) {
		super(element, ...validators);
	}

	errorElement = 'input, select, textarea, div.validation-error-element, span.validation-error-element';

	removeError = () => {
		let input = $(this.element.rootElement).find(this.errorElement);
		if (input.length === 0){
			input = $(this.element.rootElement);
		}
		if (input.length) {
			if (this.showTooltip()) {
				input.bstooltip('destroy');
			} else {
				this.element.rootElement.find('span').remove();
			}
		}
	};
}