import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {createQuestionImageUrlBuilder, createSelfAssessImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {TestType} from '@esgi/core/enums';
import {isIOS, OsChecker} from '@esgillc/ui-kit/utils';
import {userStorage} from '@esgi/core/authentication';
import {imageQueueService} from 'pages/test-explorer/services/image-queue-service';
import './component.less';
import '../component.less';
import {ViewMode} from 'shared/modules/test-details/models';
import {EditWarningDialog} from '../../dialogs/edit-warning/component';

export class State {
	opened: boolean;
	mouseDown: boolean = false;
	isEditWarningDialogOpened: boolean = false;
}

export class Props {
	hasSelfAssessVersion: boolean;
	readOnly: boolean;
	questionId: number;
	stateStandartID?: number;
	stateStandart: string;
	orderNum: number;
	shortTitle: string;
	imageQuestion: boolean;
	questionImagePregenerated: boolean;
	questionImageIsProcessing: boolean;
	modifyTicks: number;
	testId: number;
	deleteHandler: () => void;
	editHandler: () => void;
	makeTestExplorerImageHandler: () => void;
	changeStateStandart: () => void;
	testType: TestType;
	isWhiteBackground: boolean;
	dragAndDropIsProccessing: boolean;
	viewMode: ViewMode;
	selfAssessThumbnailUrl?: string;

}

export class QuestionTile extends React.PureComponent<Props, State> {
	private img: HTMLImageElement;
	private imageLoadingErrorTimes: number = 0;
	private readonly notAvaliableSrc = '/ESGI/Images/150x100.gif';
	private isIOS: boolean = isIOS();
	private urlBuilder = createQuestionImageUrlBuilder();
	private saUrlBuilder = createSelfAssessImageUrlBuilder();
	dropdownMenuRef: HTMLDivElement;

	constructor(props, state) {
		super(props, state);
		this.state = new State();
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	componentDidMount() {
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));

		$('.dropdown-toggle').click(function (event) {
			const containerSize = $('.question-outer').height() + 22;
			const menuHeight = 150;
			const headerSize = 33;

			const parentOffset = $('.question-outer').offset();
			const relY = event.pageY - parentOffset.top - headerSize + menuHeight;
			if (relY > containerSize) {
				$('.dropdown-menu').css('top', '-116px');
			} else {
				$('.dropdown-menu').css('top', '18px');
			}
		});
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.dragAndDropIsProccessing != this.props.dragAndDropIsProccessing && this.state.opened) {
			this.dropdownToogle();
		}
	}

	handleClickOutside(event) {
		if (this.state.opened && this.dropdownMenuRef && !this.dropdownMenuRef.contains(event.target)) {
			this.dropdownToogle();
		}
	}

	private imageURL = (questionImagePregenerated = true) => {
		if (this.props.questionImageIsProcessing) {
			return this.notAvaliableSrc;
		}

		const {questionId, testId, modifyTicks} = this.props;

		if (this.props.viewMode === ViewMode.SelfAssess) {
			return this.saUrlBuilder(this.props.selfAssessThumbnailUrl, questionId);
		}

		if (this.props.testType === TestType.Score) {
			return this.urlBuilder.large(questionId, testId, questionImagePregenerated, modifyTicks);
		}

		return this.urlBuilder.medium(questionId, testId, questionImagePregenerated, modifyTicks);
	};

	private getFallbackImageURL = () => {
		if (this.imageLoadingErrorTimes <= 1) {
			imageQueueService.addToQueue(this.imageURL(false) + '&regenerate=true', this.img);
		} else {
			this.img.src = this.notAvaliableSrc;
		}

		this.imageLoadingErrorTimes++;
	};

	private finishLoad = () => {
		if (this.imageLoadingErrorTimes > 0) {
			this.imageLoadingErrorTimes--;
		}
	};

	handleEditDialogClose = (confirm: boolean) => () => {
		this.setState(prev => ({...prev, isEditWarningDialogOpened: false}));
		if(confirm) {
			this.props.editHandler();
		}
	}

	dropdownToogle() {
		this.setState((state) => {
			return {opened: !state.opened};
		});
	}
	private mouseDown = ()=> {
		setTimeout(() => this.setState({mouseDown: true}), 500);
	};
	private mouseUpOrLeave = () => this.setState({mouseDown: false});

	render() {
		let liClassName = 'te-question ' + (this.isIOS && 'mobile ' || '') + (OsChecker.isMac() && 'mac ' || '') + (this.state.mouseDown && 'mouseDown ' || '');
		liClassName += this.props.testType === TestType.Score && 'single-score' || '';

		return <li className={liClassName} data-id={this.props.questionId}
							 onMouseDown={OsChecker.isMac() && this.mouseDown || null}
							 onMouseUp={OsChecker.isMac() && this.mouseUpOrLeave || null}
							 onMouseLeave={OsChecker.isMac() && this.mouseUpOrLeave || null}
		>
			<img key={`${this.props.questionId}-${this.props.viewMode}`} ref={r => this.img = r} alt={this.props.shortTitle}
					 src={this.imageURL(this.props.questionImagePregenerated)} onError={this.getFallbackImageURL}
					 onLoad={this.finishLoad}/>
			<div className='question-tag'>
				{this.props.imageQuestion &&
					<OnHoverTooltip message='Test Explorer Image'>
                                        <span className='help-tool testImagePicture'>
                                            <i className='ace-icon fa fa-check-circle'/>
                                        </span>
					</OnHoverTooltip>}
				{!this.props.readOnly &&
					<div className='dropdown' ref={(ref) => this.dropdownMenuRef = ref}>
						<a className={this.props.isWhiteBackground ? 'dropdown-toggle white-background' : 'dropdown-toggle black-background'}
							 onClick={() => this.dropdownToogle()} href='#'>
							<i className='ace-icon fa fa-ellipsis-v white'/>
						</a>
						{this.state.opened &&
							<div className='dropdown-menu'>
								{this.props.viewMode !== ViewMode.SelfAssess && <a className='dropdown-item' href='#' onClick={() => {
									this.dropdownToogle();
									if(this.props.hasSelfAssessVersion) {
										this.setState(prev => ({...prev, isEditWarningDialogOpened: true}));
										return;
									}
									this.props.editHandler();
								}}>
									<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'
											 fill='none'>
										<path
											d='M0.25 10.9375V13.75H3.0625L11.3575 5.45498L8.545 2.64248L0.25 10.9375ZM13.5325 3.27998C13.825 2.98748 13.825 2.51498 13.5325 2.22248L11.7775 0.467483C11.485 0.174983 11.0125 0.174983 10.72 0.467483L9.3475 1.83998L12.16 4.65248L13.5325 3.27998Z'
											fill='#828282'/>
									</svg>
									<span>Edit</span>
								</a>}
								{this.props.testType === TestType.YN && <a className='dropdown-item' href='#' onClick={() => {
									this.dropdownToogle();
									this.props.deleteHandler();
								}}>
									<svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14'
											 fill='none'>
										<path
											d='M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM11.25 1H8.625L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1Z'
											fill='#828282'/>
									</svg>
									<span>Delete</span>
								</a>}
								{this.props.testType === TestType.YN && <a className='dropdown-item' href='#' onClick={() => {
									this.dropdownToogle();
									this.props.makeTestExplorerImageHandler();
								}}>
									<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'
											 fill='none'>
										<path
											d='M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM6.5 11.75L2.75 8L3.8075 6.9425L6.5 9.6275L12.1925 3.935L13.25 5L6.5 11.75Z'
											fill='#828282'/>
									</svg>
									<span>Set as thumbnail</span>
								</a>}
								<a className='dropdown-item disabled-btn' href='#'>
									{!this.props.stateStandartID && <div className='state-standart-container'>
										<div className='circle-s'>S</div>
										<span>Add State Standard</span>
									</div>}
									{this.props.stateStandartID &&
										<OnHoverTooltip message={this.props.stateStandart}>
											<div className='state-standart-container'>
												<div className='state-standart-name-row'>{this.props.stateStandart}</div>
											</div>
										</OnHoverTooltip>
									}
								</a>
							</div> || null}
					</div>
				}
			</div>
			{this.props.testType !== TestType.Score &&
				<div className='question-name'>
					<span className='label-holder'>{this.props.shortTitle}</span>
				</div> || null}
			{this.state.isEditWarningDialogOpened && <EditWarningDialog
				onClose={this.handleEditDialogClose(false)}
				onOk={this.handleEditDialogClose(true)}
			/>}
		</li>;
	}
}
