export type GradeLevel = {
	id: number;
	shortName: string;
	name: string;
};

export const GradeLevelsMap: Record<number, GradeLevel> = {
	1: {id: 1, name: 'Pre-K', shortName: 'PK'},
	2: {id: 2, name: 'TK', shortName: 'TK'},
	4: {id: 4, name: 'Kindergarten', shortName: 'K'},
	8: {id: 8, name: 'Grade One', shortName: '1'},
	16: {id: 16, name: 'Grade Two', shortName: '2'},
	32: {id: 32, name: 'Grade Three+', shortName: '3+'},
};

export const gradeLevelDictionary: Record<GradeLevel['id'], GradeLevel> = {
	1: {id: 1, name: 'Pre-K(3)', shortName: 'PK(3)'},
	2: {id: 2, name: 'Pre-K(4)', shortName: 'PK(4)'},
	3: {id: 3, name: 'Pre-K', shortName: 'PK'},
	4: {id: 4, name: 'Kindergarten', shortName: 'K'},
	5: {id: 5, name: 'Grade One', shortName: '1'},
	6: {id: 6, name: 'Grade Two', shortName: '2'},
	7: {id: 7, name: 'Grade Three', shortName: '3'},
	8: {id: 8, name: 'Grade Four', shortName: '4'},
	9: {id: 9, name: 'Grade Five', shortName: '5'},
	10: {id: 10, name: 'Grade Six', shortName: '6'},
	11: {id: 11, name: 'Grade Seven', shortName: '7'},
	12: {id: 12, name: 'Grade Eight', shortName: '8'},
	13: {id: 13, name: 'Grade Nine', shortName: '9'},
	14: {id: 14, name: 'Grade Ten', shortName: '10'},
	15: {id: 15, name: 'Grade Eleven', shortName: '11'},
	16: {id: 16, name: 'Grade Twelve', shortName: '12'},
	17: {id: 17, name: 'TK', shortName: 'TK'},
	100: {id: 100, name: 'Other', shortName: 'O'},
};
