import {FormDataBuilder, HttpClient, RequestBuilder} from '@esgi/api';


export type IServerResponse<T> = JQuery.Promise<T> & {
	code?: (errorCode: number, clb: Function) => IServerResponse<T>;
}

export class ServerClient {

	constructor(private api: RequestBuilder) {
	}

	public static get SSOAPI() {
		return new ServerClient(HttpClient.default.SSO);
	}
	
	public static get ESGIAPI() {
		return new ServerClient(HttpClient.default.ESGIApi);
	}

	public Get<T>(controllerName: string, methodName: string, ajaxOptions?: JQuery.AjaxSettings): IServerResponse<T> {
		const d = $.Deferred();
		this.api.get(controllerName, methodName, ajaxOptions?.data, {headers: ajaxOptions?.headers}).subscribe((r) => d.resolve(r), (e) => d.reject(e));
		return d.promise();
	}

	public Post<T>(controllerName: string, methodName: string, ajaxOptions?: JQuery.AjaxSettings): IServerResponse<T> {
		const d = $.Deferred();
		let data = new FormData();
		if(ajaxOptions?.data) {
			data = FormDataBuilder.BuildParameters(ajaxOptions.data);
		}
		this.api.post(controllerName, methodName, data, {headers: ajaxOptions?.headers}).subscribe((r) => d.resolve(r), (e) => d.reject(e));
		return d.promise();
	}
}
