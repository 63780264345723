import {useMemo} from 'react';
import {Avatar} from '@esgi/ui/avatar';
import {Text} from '@esgi/ui/typography';
import {getInitials} from '../../../model';
import {useStudentsRootContext} from '../students-root/context';
import {StyledAvatarWrapper} from './index.styled';

export function StudentsPreview() {
	const {selectedStudentIDs, getStudentById} = useStudentsRootContext();

	const student = useMemo(() => {
		return getStudentById(selectedStudentIDs[0]);
	}, [selectedStudentIDs, getStudentById]);

	if (!student) {
		return null;
	}

	return (
		<StyledAvatarWrapper data-cy='single-student-panel' flow='row' align='center'>
			<Avatar.Root size='sm'>
				<Avatar.Image src={student.photoUrl}/>
				<Avatar.Fallback>
					{getInitials({firstName: student.firstName, lastName: student.lastName})}
				</Avatar.Fallback>
			</Avatar.Root>
			<Text data-cy='single-student-name' color='neutral56'>{student.firstName} {student.lastName}</Text>
		</StyledAvatarWrapper>
	);
}
