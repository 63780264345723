import {SubjectType} from '@esgi/core/enums';
import {TestingModel} from './models';

export function getShuffleQuestion(testingModel: TestingModel, subjectType: SubjectType): boolean {
	if(!testingModel.tested) {
		return false;
	}

	if(subjectType === SubjectType.Deployed) {
		return testingModel.subjectShuffleQuestions;
	}

	return testingModel.teacherShuffleQuestions;
}
