import {styled} from '../../../theme';
import {alertContentInlinePadding} from '../../constants';
import {GridBox} from '@esgi/ui/layout';

export const FooterWrapper = styled(GridBox, {
	paddingLeft: alertContentInlinePadding,
	paddingRight: alertContentInlinePadding,
	paddingTop: '$4',
	gridAutoColumns: 'max-content',
});
