import React from 'react';
import {EdgeStyle, IGraphStyle, ShapeType, TextStyle, VertexStyle} from 'shared/modules/question-editor/models';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {
	Events as TextToolbarEvents,
	TextToolbar,
} from 'shared/modules/question-editor/mx/editor/toolbars/text/text-toolbar';
import {
	Events as VertexToolbarEvents,
	VertexToolbar,
} from 'shared/modules/question-editor/mx/editor/toolbars/vertex/vertex-toolbar';
import {
	EdgeToolbar,
	Events as EdgeToolbarEvents,
} from 'shared/modules/question-editor/mx/editor/toolbars/edge/edge-toolbar';
import {
	Events as ToolbarMenuEvents,
	ToolbarMenu,
} from 'shared/modules/question-editor/mx/editor/toolbar-menu/toolbar-menu';
import './right-panel.less';

export class Props extends SharedProps<State> {
	shapeStyle: IGraphStyle;
	shapeType: ShapeType;
}

export class State {
	mostRecentColors: string[] = [];
}

export class RightPanel extends SharedComponent<State, Props> {
	renderToolbar() {
		if (this.props.shapeType == null) {
			return <></>;
		}

		if (this.props.shapeType === ShapeType.Image) {
			return <></>;
		}

		if (this.props.shapeType === ShapeType.Text) {
			return <TextToolbar
				styleChanged={(style, change, value) => this.dispatch(TextToolbarEvents.StyleChanged, TextToolbarEvents.StyleChanged({
					type: ShapeType.Text, style: style, change: change, value: value,
				}))}
				mostRecentColorsChanged={(colors) => this.setState({mostRecentColors: colors})}
				mostRecentColors={this.state.mostRecentColors}
				shapeStyle={this.props.shapeStyle as TextStyle}
			/>;
		}

		if (this.props.shapeType >= ShapeType.Rectangle && this.props.shapeType < ShapeType.Line) {
			return <VertexToolbar
				styleChanged={(type, style) => this.dispatch(VertexToolbarEvents.StyleChanged, VertexToolbarEvents.StyleChanged({
					type: type, style: style as VertexStyle,
				}))}
				mostRecentColorsChanged={(colors) => this.setState({mostRecentColors: colors})}
				shapeType={this.props.shapeType}
				mostRecentColors={this.state.mostRecentColors}
				shapeStyle={this.props.shapeStyle as VertexStyle}
			/>;
		}

		if (this.props.shapeType >= ShapeType.Line) {
			return <EdgeToolbar
				styleChanged={(type, style) => this.dispatch(EdgeToolbarEvents.StyleChanged, EdgeToolbarEvents.StyleChanged({
					type: type, style: style as EdgeStyle,
				}))}
				mostRecentColorsChanged={(colors) => this.setState({mostRecentColors: colors})}
				shapeType={this.props.shapeType}
				mostRecentColors={this.state.mostRecentColors}
				shapeStyle={this.props.shapeStyle as EdgeStyle}
			/>;
		}
	}

	render() {
		return <div className='mx-question-editor-right-panel'>
			<div className='right-panel-tools-container'>
				<ToolbarMenu
					added={(type) => this.dispatch(ToolbarMenuEvents.Added, ToolbarMenuEvents.Added(type))}
					//changed={(type) => this.dispatch(ToolbarMenu.Events.TypeChanged, ToolbarMenu.Events.TypeChanged(type))}
					shapeStyle={this.props.shapeStyle}
					shapeType={this.props.shapeType}
				/>
				{this.renderToolbar()}
			</div>
		</div>;
	}
}
