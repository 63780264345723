import React from 'react';
import styles from './draggable-button.module.less';

interface Props {
  label: string;
  src: string;
  width: number;
  height: number;
}

export default class DraggableButton extends React.Component<Props> {
	onDragStart(event: React.DragEvent<HTMLDivElement>){
		const id = (event.target as Element).id;
		event.dataTransfer.setData('text/plain', id);
	}

	get label() {
		return `<${this.props.label.replace(/\s+/g, '_')}>`;
	}

	public render() {
		return <img
      onDragStart={(e) => this.onDragStart(e)}
      id={this.label}
      className={styles.img}
      src={this.props.src}
      alt={this.props.label}
      title={this.props.label}
      width={this.props.width}
      height={this.props.height}
      contentEditable='false'
      draggable='true'
    />;
	}
}
