import React, {createRef, PureComponent} from 'react';
import {Observable, Subject} from 'rxjs';
import {Hint, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {DependElement, ElementStatus, Form, FormControl, FormElement, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {takeUntil} from 'rxjs/operators';
import styles from './styles.module.less';

class State {
	isLoading: boolean = false;
}

interface Props {
	testName: string;
	onSaveClicked: (name: string) => Observable<any>;
	onCloseClicked: () => void;
	onTestCreated?: () => void;
}

export class CopyTestModal extends PureComponent<Props, State> {
	public state = new State();

	private readonly maxLength = 65;
	private readonly onDestroy$: Subject<void> = new Subject();
	private readonly modalManagerRef: ModalManagerRefObject = createRef();

	private readonly form = new FormGroup({
		name: new FormControl(this.props.testName + ' (copy)', {validators: [Validators.required()]}),
	});

	render() {
		return <Modal modalManagerRef={this.modalManagerRef}
		              onCatchError={() => this.props.onCloseClicked()}>
			<Modal.Header>
				<Title>
					Copy Test
				</Title>
			</Modal.Header>
			<Modal.Body>
				<div className={styles.body}>
					<Form controller={this.form}>
						<FormElement control={this.form.controls.name}>
							<span>Enter new test name</span>
							<Input className='test-name'
							           placeholder='Enter Test Name*'
							           maxLength={this.maxLength}
							           autoFocus
							/>
							<Hint>
								{state => <div className='limit-counter'>
									{this.maxLength - state.value.length} characters left ({this.maxLength} max)
								</div>}
							</Hint>
							<OnErrorTooltip showOnError='required' placement='right'>
								Enter test name.
							</OnErrorTooltip>
						</FormElement>
					</Form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={() => this.modalManagerRef.current.close(this.props.onCloseClicked)}
				        disabled={this.state.isLoading}>
					Cancel
				</Buttons.Gray>
				<DependElement control={this.form.controls.name}>
					{state => <Buttons.Contained disabled={state.status === ElementStatus.invalid || this.state.isLoading}
						onClick={() => this.handleSaveClicked()}>
						{this.state.isLoading && <i className='fa fa-circle-o-notch fa-spin'/>}Save
					</Buttons.Contained>}
				</DependElement>
			</Modal.Footer>
		</Modal>;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}

	private handleSaveClicked() {
		this.setState({isLoading: true});

		this.props.onSaveClicked(this.form.controls.name.value)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe({
				next: () => {
					this.modalManagerRef.current.close(this.props.onCloseClicked);
					this.props.onTestCreated?.();
				},
				error: () => this.setState({isLoading: false}),
			});
	}
}
