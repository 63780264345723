import {useService} from '@esgi/core/service';
import {Buttons} from '@esgillc/ui-kit/button';
import {ElementStatus, Form, FormElement} from '@esgillc/ui-kit/form';
import {Input, Label, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';
import {useCallback, useEffect, useRef, useState} from 'react';
import {PasswordReminderService} from './password-reminder-service';

import styles from './styles.module.less';

type Props = {
	onClose: () => void;
};

export function PasswordReminder(props: Props) {
	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);
	const service = useService(PasswordReminderService);
	const {form} = service;

	const [showSuccess, setShowSuccess] = useState(false);
	const [showFail, setShowFail] = useState(false);
	const [showForm, setShowForm] = useState(true);
	const emptyErrorTooltipTarget = useRef<HTMLDivElement>();
	const [showEmptyErrorTooltip, setShowEmptyErrorTooltip] = useState(false);

	useEffect(() => {
		const sub = service.form.onChanged.subscribe(ch => {
			const {email, userName} = ch.currState.value;
			if((email || userName)) {
				setShowEmptyErrorTooltip(false);
			}
		});
		return () => sub.unsubscribe();
	});

	const submit = useCallback(() => {
		service.submit().subscribe(r => {
			if (r) {
				setShowSuccess(true);
				setShowFail(false);
				setShowForm(false);
			} else {
				setShowFail(true);
			}
		});
	}, []);

	const reset = () => {
		const {email, userName} = form.value;
		if(!(email || userName)) {
			form.controls.email.status = ElementStatus.invalid;
			form.controls.userName.status = ElementStatus.invalid;
			setShowEmptyErrorTooltip(true);
			return;
		}

		if(email) {
			return form.controls.email.validate().subscribe(r => {
				if(!r.length) {
					submit();
				}
			});
		}

		if(userName) {
			return submit();
		}
	};

	return <Modal modalManagerRef={modal}>
		<Modal.Header>
			<Title className={styles.title}>Password Reset</Title>
			<CloseIcon onClick={close}/>
		</Modal.Header>
		{!showSuccess && <div className={styles.subtitle}>
      Please enter your email address or username.
      We will email you a link to reset a password.
    </div>}
		<Modal.Body>
			<div id='remind-password-form' data-bind='afterRender: true' className={styles.bodyContent}>
				{showFail && <div className={join('IncorrectText alert alert-danger', styles.infoBlock)}>
					<h4>
						Incorrect Information!
					</h4>
					<p>
						We were not able to locate that username or email address in our system. Please
						try re-entering the information. If you have already tried again, and it still will
						not work, please contact us. Thank you!
					</p>
				</div>}
				{showSuccess && <div className={join('SuccessfulTextEmail alert alert-success', styles.infoBlock, styles.successBlock)}>
					<h4>
						Success!
					</h4>
					<p>
						We have located your username and a message has been sent to your email address
						containing a link to reset your password. If you do not receive the email,{' '}
						<i>please check your spam folder</i>.
						If you do not receive the email within an hour, please contact us.
					</p>
					<p>
						Thank you!
					</p>
				</div>}
				{showForm && <Form controller={form}>
					<FormElement control={form.controls.email} className={styles.row}>
						<Label className={join(styles.leftCol, styles.label)}>
							Email
						</Label>
						<div className={styles.rightCol}>
							<Input id='email-address' type='email' className={styles.input}/>
							<OnErrorTooltip showOnError='email' placement='right'>
								Email format is invalid. Please try again.
							</OnErrorTooltip>
						</div>
					</FormElement>
					<div className={styles.row}>
						<div className={styles.leftCol}></div>
						<div ref={emptyErrorTooltipTarget} className={styles.rightCol} id='or-line'>-- OR --</div>
						{showEmptyErrorTooltip && <ValidationTooltip element={null} elementRef={emptyErrorTooltipTarget} placement='right'>
							Please enter your email or username.
						</ValidationTooltip>}
					</div>
					<FormElement className={styles.row} control={form.controls.userName}>
						<Label className={join(styles.leftCol, styles.label)}>
							Username
						</Label>
						<div className={styles.rightCol}>
							<Input id='user-name' type='text' className={styles.input}/>
						</div>
					</FormElement>
					<div className={styles.footer}>
						<Buttons.Contained onClick={reset}>
							Submit
						</Buttons.Contained>
					</div>
				</Form>}
			</div>
		</Modal.Body>
		<Modal.Footer/>
	</Modal>;
}
