import React, {forwardRef} from 'react';
import {useImageCropperContext} from '../../context';
import {ErrorMessage} from './index.styled';
import {BaseComponentProps} from '@esgi/ui';

type ImageCropperErrorMessageProps = BaseComponentProps

export const ImageCropperErrorMessage = forwardRef<HTMLSpanElement, ImageCropperErrorMessageProps>((
	{dataCy = 'image-cropper-error-message', ...props}) => {

	const {errorMessage} = useImageCropperContext();

	return <ErrorMessage hidden={!errorMessage} {...props}>{errorMessage}</ErrorMessage>;
});
