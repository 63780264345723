import {useState} from 'react';
import underscore from 'underscore';
import moment, {Moment} from 'moment';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import {DatePicker} from '@esgi/deprecated/elements';
import {validate} from './utils';
import styles from './styles.module.less';

interface Props {
	onCancel: () => void;
	onSave: () => void;
	form: DistrictFormType | SchoolFormType;
}

export default function CustomDateRangeModal({form, onSave, onCancel}: Props) {

	const modalRef = useModal();
	const handelClose = useCloseModal(modalRef, onCancel);

	const [selectedDates, setSelectedDates] = useState({
		from: moment(new Date()).format('L'),
		to: moment(new Date()).add(1, 'days').format('L'),
	});

	const [valid, setValid] = useState({
		fromValid: true,
		toValid: true,
	});

	const onSelectTo = (value: Moment): void => {
		if (value <= moment(selectedDates.from)) {
			const cloneTo = value.clone();
			const newFrom = cloneTo.add(-1, 'days');

			form.controls.customDateRange.value = {
				to: value.format('L'),
				from: newFrom.format('L'),
			};
			return;
		}

		form.controls.customDateRange.value = {
			...selectedDates,
			to: value.format('L'),
		};
	};

	const onSelectFrom = (value: Moment): void => {
		if (value >= moment(selectedDates.to)) {
			const cloneFrom = value.clone();
			const newTo = cloneFrom.add(1, 'days');

			form.controls.customDateRange.value = {
				from: value.format('L'),
				to: newTo.format('L'),
			};
			return;
		}

		form.controls.customDateRange.value = {
			...selectedDates,
			from: value.format('L'),
		};
	};

	useStreamEffect(form.controls.customDateRange.onChanged, s => {
		if (s.reason === 'value' && !underscore.isEqual(selectedDates, s.currState.value)) {
			setSelectedDates(s.currState.value);
		}
	});

	return <Modal onCatchError={handelClose} modalManagerRef={modalRef}>
			<Modal.Header>
				<Title>Custom Date Range</Title>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<div className={styles.row}>
					<div className={styles.inputLabel}>From</div>
					<div className={styles.inputLabel}>To</div>
				</div>

				<div className={styles.row}>
					<DatePicker
						id='date-from'
						date={moment(selectedDates.from)}
						onSelect={onSelectFrom}
						validator={(v) => validate(v, 'from', valid, setValid)}
						validationPlacement='left'
					/>

					<DatePicker
						id='date-to'
						date={moment(selectedDates.to)}
						onSelect={onSelectTo}
						validator={(v) => validate(v, 'to', valid, setValid)}
						validationPlacement='left'
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={handelClose}>Cancel</Buttons.Gray>
				<Buttons.Contained onClick={onSave} disabled={!valid.fromValid || !valid.toValid}>
					Save
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>;
}


