import React, {createRef, PureComponent} from 'react';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {isTouchDevice} from '@esgillc/ui-kit/utils';
import styles from './lock-screen-modal.module.less';
import WithButtonsModal from './with-buttons-modal';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';

class State {
	showScreenLockOpened: boolean = false;
	showScreenLockWitButtonsOpened: boolean = false;
}
interface Props {
	closeParent: () => void;
}

export class LockScreen extends PureComponent<Props, State>{
	private destroy$: Subject<void> = new Subject();
	state = new State();
	private modalManagerRef: ModalManagerRefObject = createRef();

	public componentDidMount() {
		fromEvent(window, 'resize')
			.pipe(
				takeUntil(this.destroy$),
				debounceTime(200),
			).subscribe((e: Event) => this.onResize(e.target as Window));

		this.onResize(window);
	}

	private onResize(window: Window): void {
		if (window.innerHeight < 541 || window.innerWidth < 600) {
			if (!this.state.showScreenLockWitButtonsOpened) {
				this.setState({showScreenLockWitButtonsOpened: true});
			}

			return;
		}

		if (window.innerHeight > window.innerWidth && isTouchDevice()) {
			if (!this.state.showScreenLockOpened) {
				this.setState({showScreenLockOpened: true});
			}

			return;
		}

		if (this.state.showScreenLockOpened) {
			this.setState({showScreenLockOpened: false});
		}
	}

	render() {
		return (
			<>
				{this.state.showScreenLockOpened && this.renderBaseLockScreenModal()}
				{this.state.showScreenLockWitButtonsOpened && this.renderButtonsLockScreenModal()}
			</>
		);
	}

	private onClose() {
		this.props.closeParent();
		this.modalManagerRef.current.close(() => this.setState({showScreenLockOpened: false}));
	}

	private renderBaseLockScreenModal(){
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Body className={styles.baseModal}>
				<span>Self-Assessment is only supported in horizontal viewing. Please re-orient your device.</span>
			</Modal.Body>
			<Modal.Footer>
				<LinkButton onClick={() => this.onClose()}
				            className={styles.button}>
					CLOSE
				</LinkButton>
			</Modal.Footer>
		</Modal>;
	}

	private renderButtonsLockScreenModal(){
		return <WithButtonsModal
			onClickClose={() => this.setState({showScreenLockWitButtonsOpened: false})}
			closeParent={() => this.props.closeParent()}
		/>;
	}
}