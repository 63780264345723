import {Observable, BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {RequestModels} from 'shared/modules/reports/flash-cards/settings-form/api/models';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {Api} from 'shared/modules/reports/flash-cards/settings-form/api/flash-cards-api';
import {userStorage} from '@esgi/core/authentication';

export class SettingsService extends BaseService {
	private _api: Api;
	private readonly _user = userStorage.get();

	private _settingsModel$: BehaviorSubject<SettingsModel> = new BehaviorSubject<SettingsModel>(null);
	private _incorrectVerbiageColumnHeader$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	
	public tracks: Models.Track[];
	public testResultsCorrectVerbiage: string;
	public testResultsIncorrectVerbiage: string;
	public selectedItems: Models.InitModel;
	
	constructor() {
		super();
		this._api = new Api();
	}

	private server =
		{
			printOutOptionSave: (optionValue: string) => {
				return this._api.savePrintOutOption(optionValue).subscribe();
			},
			cardsPerPageOptionSave: (optionValue: string) => {
				let requestData = new RequestModels.Settings.SaveCardsPerPage();
				requestData.UserID = this._user.userID;
				requestData.CardsPerPage = optionValue;
				return this._api.saveCardsPerPageOption(requestData).subscribe();
			},
		}

	public init(printOutOption: string,
		 cardsNumber: string,
		 tracks: Models.Track[],
		 testResultsCorrectVerbiage: string,
		 testResultsIncorrectVerbiage: string,
		 incorrectVerbiageColumnHeader: any) {
		const m = new SettingsModel();
		m.cardsNumber = cardsNumber;
		m.includeSessionOption = false;
		m.printOutOption = printOutOption;
		m.sessionDate = null;
		this._incorrectVerbiageColumnHeader$.next(incorrectVerbiageColumnHeader);
		this._settingsModel$.next(m);
		this.tracks = tracks;
		this.testResultsCorrectVerbiage = testResultsCorrectVerbiage;
		this.testResultsIncorrectVerbiage = testResultsIncorrectVerbiage;
	}

	public updateSettingsModel(m: SettingsModel){
		m.includeSessionOption = m.includeSessionOption ?? this.includeSessionOption;
		m.cardsNumber = m.cardsNumber ?? this.cardsNumber;
		m.printOutOption = m.printOutOption ?? this.printOutOption;
		m.sessionDate = m.sessionDate ?? this.sessionDate;
		if (m.printOutOption != this.printOutOption){
			this.server.printOutOptionSave(m.printOutOption);
		}
		if (m.cardsNumber != this.cardsNumber){
			this.server.cardsPerPageOptionSave(m.cardsNumber);
		}

		this._settingsModel$.next(m);
	}

	public updateVerbiageColumnHeader(incorrectVerbiageColumnHeader: string) {
		this._incorrectVerbiageColumnHeader$.next(incorrectVerbiageColumnHeader);
	}

	public setSelectedItems(initModel: Models.InitModel) {
		let selectedItems = new Models.InitModel();
		selectedItems.subjectID = initModel.subjectID;
		selectedItems.subjectType = initModel.subjectType;
		selectedItems.selectedGroupItemID = initModel.selectedGroupItemID;
		selectedItems.selectedStudentID = initModel.selectedStudentID;
		selectedItems.selectedTestID = initModel.selectedTestID;
		this.selectedItems = selectedItems;
	}

	public get settings$(): Observable<SettingsModel> {
		return this.completeOnDestroy(this._settingsModel$.asObservable());
	}

	public get incorrectVerbiageColumnHeader$(): Observable<string> {
		return this.completeOnDestroy(this._incorrectVerbiageColumnHeader$.asObservable());
	}

	public get printOutOption() {
		return this._settingsModel$.value?.printOutOption;
	}

	public get cardsNumber() {
		return this._settingsModel$.value?.cardsNumber;
	}

	public get includeSessionOption() {
		return this._settingsModel$.value?.includeSessionOption;
	}

	public get sessionDate() {
		return this._settingsModel$.value?.sessionDate;
	}


}
export class SettingsModel {
	cardsNumber: string;
	printOutOption: string;
	includeSessionOption: boolean;
	sessionDate: string;
}
