import {BaseService} from '@esgi/core/service';
import {SettingsModel, SettingsRequest, SubjectsResponse, TeacherModel, UnityModel, BackgroundGenerationModel, ChartBlockModel, ReportData, HierarchyModel} from 'shared/modules/reports/pie-chart/service/models';
import {BehaviorSubject} from 'rxjs';
import {StudentSort, SubjectType, TestType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {SsoTracker} from '@esgi/core/tracker';
import {EventBusManager} from '@esgillc/events';
import {userStorage} from '@esgi/core/authentication';
import {getStudents, getUnities, getPieChartOptions, getSortedStudents, getSubjectType} from 'shared/modules/reports/pie-chart/service/utils';

const defaultCardHeight = 184;

const CONTROLLER_NAME = 'reports/pie-charts';

export class PieChartService extends BaseService {
	public readonly settings: BehaviorSubject<SettingsModel> = new BehaviorSubject<SettingsModel>({
		sortBy: StudentSort.FirstName,
		printInColor: false,
		testResultsCorrectVerbiage: '',
		testResultsIncorrectVerbiage: '',
		districtName: '',
		showEachStudent: false,
		schoolID: null,
		teacherID: null,
		classID: null,
		schools: [],
		groupID: null,
		groups: [],
		specialistGroupID: null,
		specialistGroups: [],
		studentID: null,
		subjectID: null,
		subjects: [],
		gradeLevels: [],
	});
	public readonly originalHierarchy: BehaviorSubject<HierarchyModel> = new BehaviorSubject<HierarchyModel>(null);
	public readonly showEachStudentEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly gradeLevelID: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public readonly headerLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public readonly reportData: BehaviorSubject<ReportData> = new BehaviorSubject<ReportData>({
		tests: [],
		students: [],
	});
	public readonly reportDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly hierarchyUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly chartBlockModels: BehaviorSubject<ChartBlockModel[]> = new BehaviorSubject<ChartBlockModel[]>([]);
	public readonly cardsHeight: BehaviorSubject<number> = new BehaviorSubject<number>(defaultCardHeight);
	public readonly showLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public readonly prevListRowHeight: BehaviorSubject<number> = new BehaviorSubject<number>(defaultCardHeight);

	private readonly eventBus = new EventBusManager();

	public get studentGradeLevels() {
		return this.reportData.value.students?.map(x => x.gradeLevelID).filter((x, i, a) => a.indexOf(x) === i);
	}

	public get teachers(): TeacherModel[] {
		let teachers: TeacherModel[];
		const settings = this.settings.value;
		if (settings?.schoolID > 0) {
			teachers = settings.schools.filter(x => x.schoolID === settings.schoolID)[0].teachers;
		} else if (!settings?.districtName) {
			teachers = settings?.schools[0]?.teachers;
		}
		return teachers;
	}

	public get classes () {
		let classes: UnityModel[] = null;
		const settings = this.settings.value;
		if (settings?.teacherID > 0) {
			classes = this.teachers.filter(x => x.teacherID === settings.teacherID)[0].classes;
		}
		return classes;
	}

	public get students() {
		const settings = this.settings.value;
		const unities = getUnities(settings);

		return getStudents(settings, unities);
	}

	public get gradeLevels() {
		const settings = this.settings.value;
		if (!settings.gradeLevels || settings.gradeLevels.length === 0 || !this.studentGradeLevels) {
			return null;
		}

		return settings.gradeLevels.filter(x => this.studentGradeLevels.indexOf(x.gradeLevelID) !== -1);
	}

	public getSettings(request: SettingsRequest, subjectID: number) {
		this.showLoader.next(true);

		return this.httpClient.ESGIApi.get<SettingsModel>(
			CONTROLLER_NAME,
			'get-settings',
			request,
		).subscribe((settings: SettingsModel) => {
			settings.subjectID = subjectID;
			settings.subjects.forEach(
				(s) => (s as any).subjectType = SubjectType[s.subjectType],
			);

			const originalHierarchy = {
				schoolID: settings.schoolID,
				teacherID: settings.teacherID,
				classID: settings.classID,
				groupID: settings.groupID,
				studentID: settings.studentID,
				subjectID: settings.subjectID,
			};


			settings.showEachStudent = false;

			const showEachStudentEnabled = settings.studentID === 0;
			this.settings.next(settings);
			this.showEachStudentEnabled.next(showEachStudentEnabled);
			this.originalHierarchy.next(originalHierarchy);
			this.gradeLevelID.next(0);
			this.headerLoaded.next(true);
			this.showLoader.next(false);
		});
	}

	getHierarchyData = (hierarchy: HierarchySnapshot, hierarchyUpdated: boolean, teacherIDs: number[]): HierarchySnapshot => {
		const updatedHierarchy = {...hierarchy};
		const {classID, specialistGroupID, groupID, studentID, teacherID} = this.settings.value;

		if (hierarchyUpdated) {
			if (teacherIDs != null && teacherIDs.length === 1) {
				const teacherID = teacherIDs[0];
				updatedHierarchy.classic.teacherID = teacherID;
				updatedHierarchy.specialist.filter.teacherID = teacherID;
			} else if (teacherID === 0) {
				updatedHierarchy.classic.teacherID = 0;
				updatedHierarchy.specialist.filter.teacherID = 0;
			}

			if (groupID !== undefined) {
				updatedHierarchy.classic.groupID = groupID;
			}

			if (classID !== undefined) {
				updatedHierarchy.classic.classID = classID;
			}

			if (specialistGroupID !== undefined) {
				updatedHierarchy.specialist.groupID = specialistGroupID;
				updatedHierarchy.specialist.filter = {teacherID: 0, schoolID: 0};
			}

			updatedHierarchy.specialist.studentID = studentID;
			updatedHierarchy.classic.studentID = studentID;
		}

		return updatedHierarchy;
	};

	getDataCombined = (receivedData: ReportData) => {
		const data = [];
		for (let i = 0; i < receivedData.tests.length; i++) {
			const test = receivedData.tests[i];
			let studentsTested = 0;
			let correctAnswers = 0;

			for (let j = 0; j < receivedData.students.length; j++) {
				const testResults = receivedData.students[j].testResults.filter(x => x.testID === test.testID);
				if (testResults.length > 0) {
					const testResult = testResults[0];
					studentsTested++;
					correctAnswers += testResult.correctAnswers;
				}
			}

			if (test.type.toString() !== TestType[TestType.YN]) {
				correctAnswers = Math.floor(correctAnswers / studentsTested);
			}

			const combinedTestResult = {
				testID: null,
				studentsTested: null,
				correctAnswers: null,
			};

			combinedTestResult.testID = test.testID;
			combinedTestResult.studentsTested = studentsTested;
			combinedTestResult.correctAnswers = correctAnswers;

			data.push(combinedTestResult);
		}
		return data;
	};

	public getChartBlockModels = (data: ReportData, gradeLevelID, reportLoaded: boolean = false) => {
		const filteredStudents = gradeLevelID > 0
			? data.students.filter(x => x.gradeLevelID === gradeLevelID)
			: data.students;

		const {teacherID, testResultsCorrectVerbiage, testResultsIncorrectVerbiage, printInColor} = this.settings.value;

		if (!reportLoaded) {
			data.students = filteredStudents;
		}

		const chartBlockModels: ChartBlockModel[] = [];

		data.tests.forEach((test) => {
			data.students.forEach((student) => {
				const testResults = student.testResults.filter(x => x.testID === test.testID);
				const testResult = testResults.length > 0 ? testResults[0] : null;
				const correctAnswers = testResult != null ? testResult.correctAnswers : null;
				const correctPercent = testResult != null ? Math.round((correctAnswers * 100) / test.maxScore) : null;

				chartBlockModels.push({
					testResult: testResult,
					correctPercent: correctPercent,
					studentID: student.studentID,
					testID: test.testID,
					pieChartOptions: getPieChartOptions(
						test.type,
						correctPercent,
						testResultsCorrectVerbiage,
						testResultsIncorrectVerbiage,
						printInColor,
					),
				});
			});

			const combinedTestResult = this.getDataCombined(data).filter(x => x.testID === test.testID)[0];
			const studentsNumber = teacherID > 0 ? data.students.length : combinedTestResult.studentsTested;
			const combinedMaxScore = test.type.toString() !== TestType[TestType.YN] ? test.maxScore : test.maxScore * studentsNumber;
			const correctPercent = combinedTestResult.studentsTested > 0 ? Math.round((combinedTestResult.correctAnswers * 100) / combinedMaxScore) : null;

			chartBlockModels.push({
				correctPercent: correctPercent,
				combinedTestResult: combinedTestResult,
				combinedMaxScore: combinedMaxScore,
				testID: test.testID,
				studentID: 0,
				pieChartOptions: getPieChartOptions(
					test.type,
					correctPercent,
					testResultsCorrectVerbiage,
					testResultsIncorrectVerbiage,
					printInColor,
				),
			});
		});

		return chartBlockModels;
	};

	public getReport(subjectID, hierarchy: HierarchySnapshot, hierarchyUpdated: boolean = false) {
		this.showLoader.next(true);

		const {
			subjects,
			schools,
			schoolID,
			teacherID,
			sortBy,
			studentID,
		} = this.settings.value;

		let selectedSubjects = [...subjects];

		if (subjectID > 0) {
			selectedSubjects = selectedSubjects.filter(x => x.subjectID === subjectID);
		}
		if (selectedSubjects.length === 0) {
			if (subjects[0]) {
				selectedSubjects = [subjects[0]];
			} else {
				selectedSubjects = [];
			}
		}

		const teacherIDs = [];

		if (teacherID === 0) {
			const selectedSchools = [...schools].filter(x => {
				if (schoolID > 0) {
					return x.schoolID === schoolID;
				}
				return true;
			});
			for (let i = 0; i < selectedSchools.length; i++) {
				const school = selectedSchools[i];
				for (let j = 0; j < school.teachers.length; j++) {
					const teacher = school.teachers[j];
					teacherIDs.push(teacher.teacherID);
				}
			}
		} else {
			teacherIDs.push(teacherID);
		}

		const globalSchoolYearID = userStorage.get().globalSchoolYearID;

		const request = {
			teacherIDs: teacherIDs,
			subjects: selectedSubjects.map(item => ({...item})),
			hierarchy: this.getHierarchyData(hierarchy, hierarchyUpdated, teacherIDs),
			globalSchoolYearID: globalSchoolYearID,
		};

		return this.httpClient.ESGIApi.post<ReportData>(
			CONTROLLER_NAME,
			'get-report',
			request,
		).subscribe((resp: ReportData) => {
			const data = {...resp, students: getSortedStudents(resp.students, sortBy)};

			const chartBlockModels = this.getChartBlockModels(data, this.gradeLevelID.value, true);

			this.prevListRowHeight.next(defaultCardHeight);
			this.chartBlockModels.next(chartBlockModels);
			this.reportData.next(data);
			this.hierarchyUpdated.next(hierarchyUpdated);
			this.reportDataLoaded.next(true);
			this.cardsHeight.next(defaultCardHeight);


			if (!this.showEachStudentEnabled.value || studentID > 0) {
				this.showLoader.next(false);
			}
			SsoTracker.trackEvent({
				trackingEvent: 'PieChartReport',
			});
		},
		() => this.showLoader.next(false),
		() => this.showLoader.next(false));
	}


	public updateSubjects(settings: SettingsModel, hierarchy: HierarchySnapshot) {
		this.showLoader.next(true);

		return this.httpClient.ESGIApi.get<SubjectsResponse>(
			CONTROLLER_NAME,
			'subjects',
			hierarchy,
		).subscribe((result) => {
			const subjects = result.subjects.map(s => ({
				subjectID: s.id,
				subjectName: s.name,
				subjectType: getSubjectType(s.type),
			}));

			settings.subjects = subjects;

			const selectedSubject = subjects.find(
				({subjectID}) => subjectID === this.settings.value.subjectID,
			);

			if(selectedSubject) {
				settings.subjectID = selectedSubject.subjectID;
			} else {
				settings.subjectID = 0;
			}

			this.settings.next(settings);

			this.showLoader.next(false);
		});
	}

	public onSchoolChange(event, hierarchy: HierarchySnapshot) {
		const newState = {...this.settings.value};
		newState.schoolID = parseInt(event.target.value);
		newState.teacherID = 0;
		newState.classID = 0;
		newState.studentID = 0;

		this.updateSubjects(newState, hierarchy);
	}

	public onTeacherChange (event, hierarchy: HierarchySnapshot) {
		const newState = {...this.settings.value};
		newState.teacherID = parseInt(event.target.value);
		newState.classID = 0;
		newState.studentID = 0;

		this.updateSubjects(newState, hierarchy);
	}

	public onClassChange (event, hierarchy: HierarchySnapshot) {
		const newState = {...this.settings.value};
		newState.classID = parseInt(event.target.value);
		newState.studentID = 0;

		this.updateSubjects(newState, hierarchy);
	}

	public onGroupChange (event) {
		const newState = {...this.settings.value};
		newState.groupID = parseInt(event.target.value);
		newState.studentID = 0;


		this.settings.next(newState);
	}

	public onSpecialistGroupChange (event) {
		const newState = {...this.settings.value};
		newState.specialistGroupID = parseInt(event.target.value);
		newState.studentID = 0;

		this.settings.next(newState);
	}

	public onStudentChange (event) {
		const newState = {...this.settings.value};
		newState.studentID = parseInt(event.target.value);

		this.settings.next(newState);
		this.showEachStudentEnabled.next(newState.studentID === 0);
	}

	public onSubjectChange (event) {
		const newState = {...this.settings.value};
		newState.subjectID = parseInt(event.target.value);

		this.settings.next(newState);
	}

	public onGradeLevelChange (event) {
		const gradeLevelID = parseInt(event.target.value);

		this.gradeLevelID.next( gradeLevelID);
	}

	public onShowEachStudentChange = (event) => {
		const newState = {...this.settings.value};
		newState.showEachStudent = event.target.checked;

		this.settings.next(newState);
	};

	public onPrintInColorChange = (event) => {
		this.showLoader.next(true);

		const newState = {...this.settings.value};
		const value = event.target.checked;

		newState.printInColor = value;

		return this.httpClient.ESGIApi.post(
			'reports/user-settings',
			'update',
			{name: 'printInColor', value: value}).subscribe(() => {

			this.settings.next(newState);
			this.showLoader.next(false);
		});
	};

	public getExportOptions(hierarchy: HierarchySnapshot) {
		const settings = this.settings.value;
		let subjects = settings.subjects;
		if (settings.subjectID > 0) {
			subjects = subjects.filter(x => x.subjectID === settings.subjectID);
		}

		let schoolName = '';
		let teacherName = '';
		let className = '';
		let groupName = '';
		let specialistGroupName = '';
		let studentName = '';
		const subjectName = subjects.length === 1 ? subjects[0].subjectName : 'All';

		let schools = settings.schools;
		if (settings.schoolID > 0) {
			schools = schools.filter(x => x.schoolID === settings.schoolID);
			schoolName = schools[0].name;
			teacherName = 'All';
		}

		let unity: UnityModel = null;
		const teacherIDs = new Array<number>();
		const schoolIDs = new Array<number>();

		if (settings.teacherID === 0) {
			for (let i = 0; i < schools.length; i++) {
				const school = schools[i];
				for (let j = 0; j < school.teachers.length; j++) {
					const teacher = school.teachers[j];
					teacherIDs.push(teacher.teacherID);
				}
			}
		}

		if (settings.teacherID !== 0) {
			className = 'All';
			teacherIDs.push(settings.teacherID);
			const teacher = schools[0]?.teachers.filter(x => x.teacherID === settings.teacherID)[0];
			teacherName = teacher.name;
			if (settings.classID > 0 && teacher.classes) {
				unity = teacher.classes.filter(x => x.unityID === settings.classID)[0];
				className = unity.name;
			}
		}

		if (settings.groups) {
			className = '';
			groupName = 'All';
			if (settings.groupID > 0) {
				unity = settings.groups.filter(x => x.unityID === settings.groupID)[0];
				groupName = unity.name;
			}
		}

		if (settings.specialistGroups && settings.specialistGroupID > 0) {
			unity = settings.specialistGroups.filter(x => x.unityID === settings.specialistGroupID)[0];
			specialistGroupName = unity.name;
		}

		if (unity !== null) {
			studentName = 'All';
			if (settings.studentID > 0) {
				const student = unity.students.filter(x => x.studentID === settings.studentID)[0];
				studentName = student.firstName + ' ' + student.lastName;
			}
		}
		hierarchy.specialist.studentID = hierarchy.classic.studentID = settings.studentID;

		return {
			teacherIDs: teacherIDs,
			schoolIDs: schoolIDs,
			classID: settings.classID,
			groupID: settings.groupID,
			studentID: settings.studentID,
			specialistGroupID: settings.specialistGroupID,
			hierarchyUpdated: this.hierarchyUpdated.value,
			subjects: subjects,
			sortBy: settings.sortBy,
			showEachStudent: settings.showEachStudent,
			printInColor: settings.printInColor,
			testResultsCorrectVerbiage: settings.testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage: settings.testResultsIncorrectVerbiage,
			districtName: settings.districtName,
			schoolName: schoolName,
			teacherName: teacherName,
			allTeachersMode: settings.teacherID === 0,
			className: className,
			groupName: groupName,
			specialistGroupName: specialistGroupName,
			studentName: studentName,
			subjectName: subjectName,
			gradeLevelID: this.gradeLevelID.value,
			hierarchy: hierarchy,
		};
	}

	public downloadFile = (controller, action, extension, data) => {
		this.showLoader.next(true);

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = 'Pie_Charts_' + year + '-' + month + '-' + day + '.' + extension;

		this.httpClient.ESGIApi.file(controller, action, filename, data).subscribe({
			error: () => this.showLoader.next(false),
			complete: () => this.showLoader.next(false),
		});
	};

	public download (hierarchy: HierarchySnapshot, zip: boolean = false) {
		const options = {...this.getExportOptions(hierarchy), zip: zip};

		const pagesNum = Math.ceil(this.reportData.value.tests.length / 20) * this.reportData.value.students.filter(x => x.testResults.length > 0).length;
		const backgroundLoading = zip && pagesNum > 60;

		if (!backgroundLoading) {
			const extension = zip ? 'zip' : 'pdf';
			this.downloadFile('reports/pie-charts', 'downloadPdf', extension, options);
		} else {
			return this.httpClient.ESGIApi.post<BackgroundGenerationModel>(
				CONTROLLER_NAME,
				'start-background-generation',
				options,
			).subscribe((model: BackgroundGenerationModel) => {
				const args: any = {
					reportGuid: model.reportGuid,
					fileType: 'ZIP',
				};
				this.eventBus.dispatch(BackgroundDownloadManagerEvents.Start, args);
			});
		}

		if (this.settings.value.studentID === 0) {
			const eventType = zip ? 'PDF Bulk' : 'PDF Standard';
			SsoTracker.trackEvent({
				trackingEvent: eventType,
				data: {reportType: 'PieCharts'},
			});
		}
	}
}
