export class SuggestedGradeLevel {
	id: number;
	shortName: string;
	name: string;

	static PreK: SuggestedGradeLevel = {
		id: 1,
		name: 'Pre-K',
		shortName: 'PK',
	};

	static TK: SuggestedGradeLevel = {
		id: 2,
		name: 'TK',
		shortName: 'TK',
	};

	static K: SuggestedGradeLevel = {
		id: 4,
		name: 'Kindergarten',
		shortName: 'K',
	};

	static GradeOne: SuggestedGradeLevel = {
		id: 8,
		name: 'Grade One',
		shortName: '1',
	};

	static GradeTwo: SuggestedGradeLevel = {
		id: 16,
		name: 'Grade Two',
		shortName: '2',
	};

	static GradeThreePlus: SuggestedGradeLevel = {
		id: 32,
		name: 'Grade Three+',
		shortName: '3+',
	};

	static 1 = SuggestedGradeLevel.PreK;
	static 2 = SuggestedGradeLevel.TK;
	static 4 = SuggestedGradeLevel.K;
	static 8 = SuggestedGradeLevel.GradeOne;
	static 16 = SuggestedGradeLevel.GradeTwo;
	static 32 = SuggestedGradeLevel.GradeThreePlus;

	public static readonly list = [SuggestedGradeLevel.PreK, SuggestedGradeLevel.TK, SuggestedGradeLevel.K, SuggestedGradeLevel.GradeOne, SuggestedGradeLevel.GradeTwo, SuggestedGradeLevel.GradeThreePlus];

	static ByID(gradeLevelId: number) {
		return this.list.find(s => s.id == gradeLevelId);
	}
}