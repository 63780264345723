import {DetailReportSettings} from './components/settings';
import {DetailReportMain} from './components/report';
import {ReportService} from '../../services/report-service';
import styles from './styles.module.less';

interface Props {
	service: ReportService
}

export function StudentDetailReport({service}: Props): JSX.Element {
	return (
		<div className={styles.studentDetailReport}>
			<DetailReportSettings service={service}/>
			<DetailReportMain service={service}/>
		</div>
	);
}
