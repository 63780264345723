import {DPDay} from '@rehookify/datepicker';
import {Button} from '../../../../buttons';
import {DayButtonType, DayButtonState} from '../../types';
import {styled} from '../../../../theme';
import {forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';

type DayButtonProps = {
	/** Optional function for additional button props. */
	dayButton?: DayButtonType;

	/** Data about the day, such as selection and month status. */
	dayData: DPDay
} & BaseComponentProps

const getDeyButtonStatus = (day: DPDay) => {
	const {inCurrentMonth, selected, now, range} = day;

	if (['will-be-range-start', 'will-be-range-end', 'in-range', 'range-start', 'range-end'].includes(range) || selected) {
		return DayButtonState.Selected;
	}

	if (range === 'will-be-in-range') {
		return DayButtonState.InRange;
	}

	if (now) {
		return DayButtonState.Special;
	}

	if (inCurrentMonth) {
		return DayButtonState.Neutral;
	}

	return DayButtonState.Disabled;
};

export const DayButton = forwardRef<HTMLButtonElement, DayButtonProps>(({dayButton, dayData, dataCy = 'ui-kit-date-picker-day-button'}, ref) => {
	return <CalendarDayButton data-cy={dataCy} className={dayData.range} status={getDeyButtonStatus(dayData)} {...dayButton?.(dayData)} ref={ref}>
		{dayData.day}
	</CalendarDayButton>;
});

const CalendarDayButton = styled(Button.Text, {
	transition: 'background-color .2s, color .2s, border-color .2s, box-shadow .2s',
	fontSize: '11px',
	lineHeight: '12px',
	fontWeight: '700',
	alignItems: 'center',
	width: '24px',
	height: '24px',
	borderRadius: '8px',
	border: '1px solid transparent',

	'&:hover': {
		border: '1px solid #E6D4F2',
		backgroundColor: '$secondaryBackground',
	},

	variants: {
		status: {
			[DayButtonState.Disabled]: {
				backgroundColor: '$background',
				color: '$muted',
			},

			[DayButtonState.Selected]: {
				color: '$primary',
				border: '1px solid $primaryMuted',
				backgroundColor: '#F3F9FC',
			},

			[DayButtonState.Neutral]: {
				color: '$base',
				background: 'none',
			},

			[DayButtonState.InRange]: {
				color: '$secondary',
				border: '1px solid $secondaryBackground',
				backgroundColor: '$secondaryBackground',
			},

			[DayButtonState.Special]: {
				color: '$base',
				backgroundColor: '$secondaryBackground',
			},
		},
	},
});
