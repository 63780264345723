import {useMemo} from 'react';
import {CreateAssignmentService} from '../../../services/create-assignment-service';
import {TestItem} from '../../../types/types';
import {TestRow} from './test-row';
import {
	Table,
	TableHeader,
	TableHeaderColumn,
	TableBody,
	TableColumn,
	TableRow,
	useSortableColumns,
} from '@esgillc/ui-kit/table';
import {useBehaviorSubject, join} from '@esgillc/ui-kit/utils';
import styles from './table.module.less';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props<Service extends CreateAssignmentService> = {
	tests: TestItem[];
	selectedTests: TestItem[];
	service: Service;
	style?: React.CSSProperties;
};

type RowTestItem = {
	selected: boolean;
} & TestItem;

export function TestsTable<Service extends CreateAssignmentService>(props: Props<Service>) {
	const {tests, selectedTests, service, style} = props;
	const selectedFilter = useBehaviorSubject(service.selectedTestsFilters);

	const memorizedTests = useMemo(
		() =>
			tests.map((test) => {
				const name = selectedFilter[0]?.value?.name;
				const {subjects} = test;
				const subjectNames = subjects.map(({name}) => name);
				if (name && subjectNames.length > 1) {
					const index = subjectNames.indexOf(name);
					if (index > 0) {
						const subject = {...subjects[index]};
						subjects.splice(index, 1);
						subjects.unshift(subject);
					}
				}
				return {
					...test,
					subjects,
					selected: selectedTests.some(({id}) => id === test.id),
				} as RowTestItem;
			}),
		[selectedFilter, selectedTests, tests],
	);

	const {data, sortableColumns} = useSortableColumns({
		data: memorizedTests,
		columns: {
			name: true,
			subjects: true,
		},
		activeSortedKey: 'name',
	});

	const isSubjectTabSortableDisable = useMemo(() => {
		const uniqSubjectNames = new Set();

		data.forEach(({subjects}) => {
			const flattedSubjects = subjects.flatMap(({name}) => name).join();

			uniqSubjectNames.add(flattedSubjects);
		});

		return uniqSubjectNames.size <= 1;
	}, [data]);

	const isExceedLimit = selectedTests.length >= 3;

	return (
		<Table style={style}>
			<TableHeader className={styles.header}>
				<TableHeaderColumn.Sortable
					{...sortableColumns.name}
					className={join(styles.firstColumn, styles.tests)}
					disableSorting={data.length <= 1}
				>
					Tests in my subject tabs
				</TableHeaderColumn.Sortable>
				<TableHeaderColumn.Sortable
					{...sortableColumns.subjects}
					className={styles.tests}
					disableSorting={isSubjectTabSortableDisable}
				>
					Subject tab
				</TableHeaderColumn.Sortable>
			</TableHeader>
			<OverlayScrollbarsComponent className={styles.body}>
				<TableBody>
					{data.map((row) => (
						<TableRow key={row.id}>
							<TableColumn className={join(styles.firstColumn, styles.tests)}>
								<TestRow
									disabled={isExceedLimit}
									selected={row.selected}
									service={service}
									item={row}
								/>
							</TableColumn>
							<TableColumn className={styles.tests}>
								{row.subjects.map(({name}) => name).join(', ')}
							</TableColumn>
						</TableRow>
					))}
				</TableBody>
			</OverlayScrollbarsComponent>
		</Table>
	);
}
