import React from 'react';
import {Delete, Upload} from '@esgi/ui';
import {Avatar} from '@esgi/ui/avatar';
import {Label} from '../../index.styled';
import {
	StudentId,
	StudentName,
	StudentInfo,
	StudentDetailsContainer,
	ButtonIcon,
	StudentIdContainer,
} from './index.styled';

interface Props {
	fullName?: string;
	studentId?: string;
	isAddMode: boolean;
	imageURL?: string;
	onDeleteImage: VoidFunction;
	onUploadPhotoOpen: VoidFunction;
	isInitialized: boolean;
	isDisabled?: boolean;
}

export function StudentDetails(props: Props) {

	const {studentId, imageURL, fullName, isAddMode, onDeleteImage, onUploadPhotoOpen, isInitialized, isDisabled} = props;

	return <StudentDetailsContainer>
		<Avatar.Root size='xl' borderColor={isDisabled ? 'transparent' :'primary'} skeleton={!isInitialized}>
			{isDisabled && <Avatar.FilterLayer color='neutral'/>}
			<Avatar.Image src={imageURL}/>
			<Avatar.Fallback>
				<>
					<Upload width={24} height={24}/>
					{imageURL && <Delete width={24} height={24}/>}
				</>
			</Avatar.Fallback>
			{!isDisabled && <Avatar.HoverLayer>
				<>
					<ButtonIcon onClick={onUploadPhotoOpen}>
						<Upload/>
					</ButtonIcon>
					{imageURL && <ButtonIcon onClick={onDeleteImage}>
						<Delete/>
					</ButtonIcon>}
				</>
			</Avatar.HoverLayer>}
		</Avatar.Root>

		<StudentInfo>
			<StudentName size='large' skeleton={!isInitialized}>
				{isAddMode ? 'New Student' : fullName}
			</StudentName>
			{!isAddMode && studentId
			?<StudentIdContainer skeleton={!isInitialized}>
				<Label size='xSmall' color='lowContrast'>ID:</Label>
				<StudentId size='xSmall'>{studentId}</StudentId>
			</StudentIdContainer>
			:null}
		</StudentInfo>
	</StudentDetailsContainer>;
}

