import {styled} from '@stitches/react';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';

export const BodyWrapper = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	height: 'max-content',
	justifyContent: 'space-between',
});

export const SubTitle = styled(Text, {
	marginBottom: '40px',
	color: '$neutral40',
});

export const StyledButton = styled(Button, {
	width: '100%',
	marginBottom: 4,
});


export const ButtonDescription = styled(Text, {
	textAlign: 'center',
	marginBottom: '12px',
	color: '$neutral40',
});

export const CodeContainer = styled(FlexBox, {
	padding: '8px 12px',
	width: '100%',
	height: '40px',
	justifyContent: 'end',
	border: '1px solid $vivid',
	borderRadius: '6px',
	alignItems: 'center',
	backgroundColor: '$vivid',

	'& svg': {
		cursor: 'pointer',
	},

	'& > svg': {
		'& path': {
			fill: '$negative',
		},
	},
});

export const CodeContent = styled(FlexBox, {
	marginRight: 90,
	marginLeft: 'auto',
	'& :first-child': {
		marginRight: '8px',
		color: '$neutral40',
	},
	'& :second-child': {
		color: '$base',
	},
});

export const CodeDescription = styled(Text, {
	marginTop: '20px',
	textAlign: 'center',
	color: '$neutral24',
});

export const CodeExpiration = styled(FlexBox, {
	marginTop: '15px',
	'& :first-child': {
		marginRight: '4px',
		color: '$lowContrast',
	},
});

export const WarningText = styled(Text, {
	color: '$negative',
});