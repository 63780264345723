import {TestsControl} from './kit/tests-control';
import {GroupingControl} from './kit/grouping-control';
import {ShowAllStudentsControl} from './kit/show-all-students-control';
import {LastWeekControl} from './kit/last-week-control';
import {SubjectSection} from './kit/subject-section';
import {Tabs} from './kit/tabs';

export const UntestedStudentsReport = {
	TestsControl,
	GroupingControl,
	ShowAllStudentsControl,
	LastWeekControl,
	SubjectSection,
	Tabs,
};

export {useValue} from './kit/hooks';
export * from './types';