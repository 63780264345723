import {Fragment} from 'react';
import styles from './styles.module.less';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../services/report-service';
import {StudentResults, Test} from '../../../../../models/models';

interface Props {
	tests: Test[];
	service: ReportService;
	student: StudentResults
}

export function NotesRow({tests, service, student}: Props) {
	const includeQuestionNotes = useBehaviorSubject(service.settingsService.includeQuestionNotes);
	const includeSummaryNotes = useBehaviorSubject(service.settingsService.includeSummaryNotes);

	return tests.length && <div className={styles.notesRow}>
		{tests.map(test =>
			<Fragment key={`${student.fullName}-${test.testID}`}>
				{(includeQuestionNotes || includeSummaryNotes) &&
					<div className={styles.notesContainer}>
						{includeQuestionNotes &&
							<>
								<span>Notes</span>
								<div className={styles.notesList}>
									<div>
										{test.answers.filter(i => !!i.note).map((answer, index) =>
											<Fragment key={index}>
												{test.type === 'YN' && <sup><b>{index + 1}</b></sup>}
												{test.type === 'Rubric' && <span><b>{answer.text}</b>:&nbsp;</span>}
												<span>{answer.note}</span>
												{(index !== test.answers.filter(i => !!i.note).length - 1) && <>,&nbsp;</>}
											</Fragment>,
										)}
										{test.type === 'Score' && <span>{test.summaryNotes}</span>}
									</div>
								</div>
								{includeSummaryNotes && <div className={styles.summarySeparator}/>}
							</>
						}
						{includeSummaryNotes &&
							<>
								<span>Summary</span>
								<div className={styles.notesList}>
									<div>
										<span>{test.summaryNotes}</span>
									</div>
								</div>
							</>
						}
					</div>
				}
			</Fragment>,
		)}
	</div>;
}
