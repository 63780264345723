import {Reports, ExpandablePanel} from '@esgi/main/kits/reports';
import {ItemAnalysis as ItemAnalysisUI, useItemAnalysis, ItemAnalysisContext} from '@esgi/main/features/teacher/item-analysis';
import {useReportState} from './hooks';
import {Content, RightSidebar, Header, Divider, Wrapper, Page} from '../../layout/index.styled';
import {withNewOldReports} from '../../old-reports';
import OldItemAnalysis from '../../old-reports/class/item-analysis';
import {Panel} from './index.styled';

export function ItemAnalysis() {
	const {service} = useItemAnalysis();
	const {
		selectedEntity,
		preview,
		setPreview,
		valid,
		busy,
		subjects,
		loadedSubjects,
		subject,
		notTested,
		sort,
		test,
		tests,
		loadingTests,
		onEntitySelect,
	} = useReportState(service);

	return (
		<ItemAnalysisContext.Provider value={service}>
			<Page>
				<Panel>
					<Reports.ClassLevel.StudentPanel
						withoutAllClass={false}
						hideEmptyClassAndGroup
						onSelect={(value) => {
							onEntitySelect(value);
							service.setEntity({classId: value.classId, groupId: value.groupId});
						}}
					/>
				</Panel>
				<Content dataCy='content'>
					<Header>
						<Reports.UnitNamePanel
							skeleton={!selectedEntity?.label}
							title={selectedEntity?.label}/>
						<Divider/>
						<Reports.SubjectControl
							skeleton={!loadedSubjects}
							subjects={subjects}
							value={subject.toString()}
							onChange={(value) => service.setSubject(+value)}
						/>
					</Header>
					<ItemAnalysisUI.TestsControl
						skeleton={loadingTests}
						tests={tests}
						value={test}
						onChange={(value) => service.setTest(value)}
					/>
				</Content>
				<RightSidebar>
					<Reports.ReportPanel
						reportTitle='Run Report'
						disabled={busy || !valid}
						onReport={() => service.onPreview(() => setPreview(true))}
					/>
					<ExpandablePanel>
						<Wrapper>
							<ItemAnalysisUI.SettingsControl
								value={notTested}
								onChange={(value) => service.setNotTested(value)}
							/>
							<ItemAnalysisUI.SortControl
								value={sort}
								onChange={(value) => service.setSort(value)}
							/>
						</Wrapper>
					</ExpandablePanel>
				</RightSidebar>
				{preview &&
					<ItemAnalysisUI.PreviewDialog
						onClose={() => setPreview(false)}
						onDownload={(fileType) => service.onDownload(fileType)}
					/>
				}
			</Page>
		</ItemAnalysisContext.Provider>
	);
}

export default withNewOldReports(OldItemAnalysis, ItemAnalysis);
