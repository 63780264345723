import {useMemo} from 'react';
import {useTestSectionContext} from '../../../../../../../../context/test-section-context';
import {TestType} from '@esgi/main/libs/core';
import {isNull} from 'underscore';
import {ChartType, DeltaInfo, GridChartValue, RingFullFilledChartValue, Chart, RubricChartItem} from './types';
import {TestSingleStudentModel} from '../../../../../../../../../../types';

export function useChartData({
	testInfo,
	studentResult,
}: {
	testInfo: TestSingleStudentModel['testInfo'];
	studentResult: TestSingleStudentModel['studentResult'];
}) {
	const {testResultsCorrectVerbiage} = useTestSectionContext();

	const answersInfoTranscription = useMemo<string>(() => {
		switch (testInfo.type) {
			case TestType.YN:
				return `${testResultsCorrectVerbiage} answers`;
			case TestType.Rubric:
			case TestType.Score:
				return 'Last Score';
			default:
				return '';
		}
	}, [testResultsCorrectVerbiage, testInfo]);

	const chartValues = useMemo<RingFullFilledChartValue | GridChartValue>(() => {
		const commonChartValue: Chart = {
			value: studentResult?.correctAnswers ?? null,
			maxValue: testInfo.totalPossible,
		};

		if (testInfo.type === TestType.Rubric) {
			const maxValue = Math.round(commonChartValue.maxValue / testInfo.rubricCriteria.length);
			const rubricIteratedItems: RubricChartItem[] =
				studentResult?.lastTestSessionRubricAnswers ?? testInfo.rubricCriteria.map(() => ({score: 0}));

			const chartValue: GridChartValue = {
				type: ChartType.Grid,
				...commonChartValue,
				items: rubricIteratedItems.map(({score}) => ({
					value: score,
					maxValue,
				})),
			};

			return chartValue;
		}

		const chartValue: RingFullFilledChartValue = {
			type: ChartType.RingFullFilled,
			...commonChartValue,
		};

		return chartValue;
	}, [testInfo, studentResult]);

	const deltaInfo = useMemo<DeltaInfo>(() => {
		const deltaValue = studentResult?.delta ?? null;

		if (isNull(deltaValue)) {
			return {
				color: 'lowContrast',
				value: '-',
			};
		}

		if (deltaValue === 0) {
			return {
				color: 'mediumContrast',
				value: '0%',
			};
		}

		return {
			color: deltaValue > 0 ? 'positive' : 'negativeVivid',
			value: `${deltaValue > 0 ? '+' : '-'}${Math.abs(deltaValue).toFixed(1)}%`,
		};
	}, [studentResult]);

	return {
		answersInfoTranscription,
		chartValues,
		deltaInfo,
	};
}
