import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Title} from '@esgi/deprecated/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {StudentItem} from '../../types/types';
import {NavLink} from 'react-router-dom';
import {Route} from 'pages/assignment-center/hooks/use-navigation';

type Props<StudentInfo extends StudentItem> = {
	students: StudentInfo[];
	onSave: VoidFunction;
	onClose: VoidFunction;
};

export function CredentialsAlert<StudentInfo extends StudentItem>({
	students,
	onSave,
	onClose,
}: Props<StudentInfo>) {
	const modal = useModal();

	const onModalClose = useCloseModal(modal, onClose);

	const onModalSave = useCloseModal(modal, onSave);

	return (
		<Alert modalManagerRef={modal}>
			<Alert.Header>
				<Title>Missing Student Credentials</Title>
			</Alert.Header>
			<Alert.Body className={styles.body}>
				<div className={styles.mainInformationContainer}>
					<div className={styles.modalTextContainer}>
						Missing Student Credentials
						The following student(s)
						need a username and password in order to access this assignment:
						<div className={styles.studentList}>{
							students.map(student => <div key={student.id}>
								{student.name}
							</div>)
						}</div>
						Do you wish to add credentials now, or save and continue?
					</div>
				</div>
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={onModalClose}>
					<NavLink to={Route.StudentCredentials} className={styles.navLink}>ADD CREDENTIALS</NavLink>
				</Buttons.Text>
				<Buttons.Text onClick={onModalSave}>
					SAVE AND CONTINUE
				</Buttons.Text>
			</Alert.Footer>
		</Alert>
	);
}
