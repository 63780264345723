import React, {Suspense, useState} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import {lazyComponent} from '@esgi/core/react';

const ItemAnalysisReportModal = lazyComponent(() => import('modules/reports/item-analysis-report'));

class Props {
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	noStudents: boolean;
}

export default function ItemAnalysisButtons(props: Props) {
	const [open, setOpen] = useState<boolean>(false);
	const [changesCollector, setChangesCollector] = useState<ChangesCollector>();

	const clickHandler = () => {
		setChangesCollector(new ChangesCollector({
			id: props.subjectID,
			type: props.subjectType,
		}, props.hierarchy.snapshot));
		setOpen(true)
		SsoTracker.trackEvent({
			trackingEvent: 'ItemAnalysisReport',
		});
	};

	const icon = (): JSX.Element => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='15'
		            viewBox='0 0 15 15'
		            fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M3 0C1.34315 0 0 1.31715 0 2.94194V11.7677C0 13.3925 1.34315 14.7097 3 14.7097H12C13.6569 14.7097 15 13.3925 15 11.7677V2.94194C15 1.31715 13.6569 0 12 0H3ZM3.75 4.89124C3.33579 4.89124 3 5.22052 3 5.62672V11.0322C3 11.4384 3.33579 11.7677 3.75 11.7677C4.16421 11.7677 4.5 11.4384 4.5 11.0322V5.62672C4.5 5.22052 4.16421 4.89124 3.75 4.89124ZM6.75 3.67742C6.75 3.27122 7.08579 2.94194 7.5 2.94194C7.91421 2.94194 8.25 3.27122 8.25 3.67742V11.0323C8.25 11.4385 7.91421 11.7677 7.5 11.7677C7.08579 11.7677 6.75 11.4385 6.75 11.0323V3.67742ZM11.2637 6.85831C10.8495 6.85831 10.5137 7.18759 10.5137 7.59379V11.0323C10.5137 11.4385 10.8495 11.7677 11.2637 11.7677C11.6779 11.7677 12.0137 11.4385 12.0137 11.0323V7.59379C12.0137 7.18759 11.6779 6.85831 11.2637 6.85831Z'
			      fill='#0088CC'/>
		</svg>;
	}

	return <>
		<NoStudentProtectedButton title='Item Analysis'
		                          icon={icon()}
		                          hoverTitle='Bar chart based on least and most known items'
		                          onClicked={() => clickHandler()}
		                          noStudents={props.noStudents}
		                          linkClassName='item-analysis-report-link'/>
		{open && <Suspense fallback={<></>}><ItemAnalysisReportModal
			subjectID={props.subjectID}
			subjectType={props.subjectType}
			hierarchy={props.hierarchy.snapshot}
			changesCollector={changesCollector}
			onClose={() => {
				props.applyHomepageChanges(changesCollector);
				setOpen(false);
			}}
		/></Suspense>}
	</>
}
