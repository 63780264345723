import {forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {Box, BoxProps} from '../box';
import {VariantProps} from '@stitches/react';

type FlexBoxProps = BoxProps & VariantProps<typeof Flex>;

export const FlexBox = forwardRef<HTMLDivElement, FlexBoxProps>(({dataCy, ...props}, forwaredRef) => (
	<Flex data-cy={dataCy ?? 'ui-kit-flex-box'} ref={forwaredRef} {...props} />
));

const Flex = styled(Box, {
	variants: {
		fullWidth: {
			true: {
				width: '100%',
			},
		},
		display: {
			flex: {
				display: 'flex',
			},
			inlineFlex: {
				display: 'inline-flex',
			},
			none: {
				display: 'none',
			},
		},
		direction: {
			row: {
				flexDirection: 'row',
			},
			column: {
				flexDirection: 'column',
			},
			rowReverse: {
				flexDirection: 'row-reverse',
			},
			columnReverse: {
				flexDirection: 'column-reverse',
			},
		},
		align: {
			start: {
				alignItems: 'flex-start',
			},
			center: {
				alignItems: 'center',
			},
			end: {
				alignItems: 'flex-end',
			},
			stretch: {
				alignItems: 'stretch',
			},
			baseline: {
				alignItems: 'baseline',
			},
		},
		justify: {
			start: {
				justifyContent: 'flex-start',
			},
			center: {
				justifyContent: 'center',
			},
			end: {
				justifyContent: 'flex-end',
			},
			between: {
				justifyContent: 'space-between',
			},
		},
		wrap: {
			noWrap: {
				flexWrap: 'nowrap',
			},
			wrap: {
				flexWrap: 'wrap',
			},
			wrapReverse: {
				flexWrap: 'wrap-reverse',
			},
		},
	},
	defaultVariants: {
		display: 'flex',
	},
});
