import React, {
	ChangeEvent,
	ForwardedRef,
	forwardRef,
	useEffect,
	useRef,
	useState,
	MouseEvent,
	useCallback,
	FocusEvent,
} from 'react';
import {useDatePickerRootContext} from '../../context';
import InputMask from 'react-input-mask';
import {
	dayFormat,
	getStringDateParts,
	monthFormat,
	parseDateOrRangeStringToDate,
	parseDateRangeToString,
} from '../../utils';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {Close, Datepicker, Expand} from '@esgi/ui/icons';
import {styled} from '@esgi/ui/theme';
import {Input, InputProps} from '../../../input';
import {Box} from '@esgi/ui/layout';
import {BaseComponentProps} from '@esgi/ui';
import {DateFormat} from '../../types';

type DatePickerInputProps = Pick<InputProps, 'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onBlur' | 'focused' | 'hovered'> & {
	/** Optional callback function that is called when the clear button is clicked. */
	onClear?: () => void;

	/** Optional initial value for the date picker input. */
	value?: string;

	/** Optional placeholder text for the input field.
	 * @default 'Date'
	 * */
	placeholder?: string;
} & BaseComponentProps


const rangeFormats: Record<DateFormat, string> = {
	'dD/mM/YYYY': 'dD/mM/YYYY - aA/bB/YYYY',
	'mM/dD/YYYY': 'mM/dD/YYYY - bB/aA/YYYY',
	'mM/dD/YY': 'mM/dD/YY - bB/aA/YY',
	'YYYY/dD/mM': 'YYYY/dD/mM - YYYY/aA/bB',
	'YYYY/mM/dD': 'YYYY/mM/dD - YYYY/bB/aA',
};
export const DatePickerInput = forwardRef(({
	 onClear = () => null,
	placeholder = 'Date',
	dataCy = 'ui-kit-date-picker-input',
	onMouseEnter,
	onMouseLeave,
	onBlur,
	onFocus,
	focused,
	hovered,
	...props
}: DatePickerInputProps, ref: ForwardedRef<HTMLDivElement>) => {
	const [isFocused, setFocused] = useState(false);

	const [value, setValue] = useState('');
	const blurTimeout = useRef<ReturnType<typeof setTimeout>>();
	const formatChars = useRef({
		'Y': '[0-9]',
		'm': '[0-1]',
		'M': '[0-9]',
		'd': '[0-3]',
		'D': '[1-9]',
		'a': '[0-3]',
		'A': '[1-9]',
		'b': '[0-1]',
		'B': '[0-9]',
	});
	const {
		expanded,
		rangeMode,
		dateValue,
		setDateValue,
		setSelectedDates,
		setApprovedDate,
		isCalendarRendered,
		error,
		format='mM/dD/YYYY',
		disabled,
	} = useDatePickerRootContext();


	const inputRef = useRef<HTMLInputElement>(null);
	const beforeMaskedValueChange = (newState, oldState) => {
		const {value} = newState;
		const dateParts = value.split('-').map(part => part.trim());
		if (oldState.value === value || dateParts.every(item => !item)) {
			return newState;
		}

		const [startDate, endDate] = dateParts;
		const {day, month} = getStringDateParts(startDate, format);
		formatChars.current.D = dayFormat(day);
		formatChars.current.M = monthFormat(month);
		if (rangeMode) {
			const {day: endDay, month: endMonth} = getStringDateParts(endDate, format);
			formatChars.current.A = dayFormat(endDay);
			formatChars.current.B = monthFormat(endMonth);
		}
		return newState;
	};

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	useEffect(() => {
		setValue(dateValue);
	}, [dateValue]);

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		const dates = parseDateOrRangeStringToDate(value, format);
		setApprovedDate(dates);
		setValue(parseDateRangeToString(dates, format));
		blurTimeout.current = setTimeout(() => {
			setFocused(false);
			onBlur?.(event);
		}, 100);
	};

	const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
		clearTimeout(blurTimeout.current);
		setFocused(true);
		onFocus?.(event);
	};

	const handleClearableButtonMouseDown = useCallback((event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	}, []);

	useEffect(() => {
		return () => {
			clearTimeout(blurTimeout.current);
		};
	}, []);
	return <InputMask
		mask={rangeMode ? rangeFormats[format] : format}
		formatChars={formatChars.current}
		value={value}
		onChange={onChange}
		onBlur={handleBlur}
		beforeMaskedValueChange={beforeMaskedValueChange}
		data-cy={dataCy}
		disabled={disabled}
		onFocus={handleFocus}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		{() => <StyledInput
			placeholder={placeholder}
			opened={expanded}
			iconWrapperCss={{width: 30, height: 30, right: 0, display: 'flex', alignItems: 'center'}}
			ref={inputRef}
			error={error}
			disabled={disabled}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<FlexBox>
				<ButtonWrapper hidden={!isFocused}>
					<InputIconButton active={!disabled} data-cy={`${dataCy}-clear-button`} onClick={() => {
						setSelectedDates([]);
						setDateValue('');
						setValue('');
						setApprovedDate([]);
						onClear();
					}} onMouseDown={handleClearableButtonMouseDown}>
						<Close/></InputIconButton>
				</ButtonWrapper>
				<ButtonWrapper hidden={isFocused} {...props} ref={ref} onClick={() => {
					if (!isCalendarRendered) {
						inputRef.current?.focus?.();
					}
				}
				}>
					<InputIconButton data-cy={`${dataCy}-dropdown-trigger`} active={!disabled}>
						{
							!expanded && <Datepicker/>
						}
						{
							expanded && <Expand/>
						}
					</InputIconButton>
				</ButtonWrapper>

				</FlexBox>
			</StyledInput>}
		</InputMask>;
});

const ButtonWrapper = styled(Box, {
	transition: 'opacity .2s ease',
	variants: {
		hidden: {
			true: {
				opacity: 0,
				width: 0,
				height: 0,
				overflow: 'hidden',
			},
			false: {
				width: 30,
				height: 30,
				padding: 3,
			},
		},
	},
});

const InputIconButton = styled('button', {
	padding: 0,
	margin: 0,
	background: 'unset',
	border: 'none',
	outline: 'none',
	width: 24,
	height: 24,
	borderRadius: '50%',
	'& svg path': {
		fill: '$base',
		transition: '.2s ease',
	},

	variants: {
		active: {
			true: {
				'&:hover': {
					backgroundColor: '$secondaryBackground',

					'& svg path': {
						fill: '$secondary',
					},
				},
			},
		},
	},
});

const StyledInput = styled(Input.Iconable, {
	width: '100%',
	textAlign: 'left',
	minWidth: '232px',
	variants: {
		opened: {
			true: {
				border: '1px solid transparent',
				borderRadius: '6px 6px 0 0',
				borderBottom: '1px solid $primaryMuted',
			},
		},
	},
});
