import {PropsWithChildren} from 'react';
import {CardLayout, StyledCheckbox, WidgetContainer} from './styled';

export function Card(props: { enabled: boolean, onChange: VoidFunction } & PropsWithChildren) {
	return <CardLayout dataCy='extended-widget-card'>
		<StyledCheckbox enabled={props.enabled} checked={props.enabled} onCheckedChange={props.onChange}
		                label={props.enabled ? 'Enabled' : 'Disabled'}/>
		<WidgetContainer>
			{props.children}
		</WidgetContainer>
	</CardLayout>;
}