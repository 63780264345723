import {ServerClient} from 'pages/landing/kit/server-client';
import {
	AcUserInfoModel,
	CheckACResult,
	CheckEmailResponse,
	CheckPromoCodeResponse,
	CheckUsernameResponse,
	CreateUserResponse,
	PurchaseUserInfoModel,
	TrialUserInfoModel,
	CheckACAndEmailRequest,
	CheckACAndEmailResponse,
} from './types';
import {BaseResponse} from '@esgi/api';

export class ValidationServer {
	private static readonly controllerName = 'Registration/Validations';
	static checkEmailExistence = (email: string) => {
		return ServerClient.SSOAPI.Get<CheckEmailResponse>(ValidationServer.controllerName, 'Email', {data: {email: email}});
	};
	static checkACAndEmail = (model: CheckACAndEmailRequest) => {
		return ServerClient.SSOAPI.Get<BaseResponse<CheckACAndEmailResponse>>(ValidationServer.controllerName, 'ACAndEmail', {data: model});
	};
	static checkPromoCode = (code: string) => {
		return ServerClient.SSOAPI.Get<CheckPromoCodeResponse>(ValidationServer.controllerName, 'PromoCode', {data: {promoCode: code}});
	};
	static checkActivationCode = (code: string) => {
		return ServerClient.SSOAPI.Get<CheckACResult>(ValidationServer.controllerName, 'ActivationCode', {data: {activationCode: code}});
	};
	static checkUserName = (username: string) => {
		return ServerClient.SSOAPI.Get<CheckUsernameResponse>(ValidationServer.controllerName, 'UserName', {
			data: {
				userName: username,
				cache: true,
			},
		});
	};
}

export class CreateServer {
	private static readonly controllerName = 'Registration/Create';
	static createTrialUser = (model: TrialUserInfoModel) => {
		return ServerClient.SSOAPI.Post<BaseResponse<CreateUserResponse>>(CreateServer.controllerName, 'Trial', {
			data: model,
			headers: {'ajax': 'true'},
		});
	};
	static createUserByAC = (model: AcUserInfoModel) => {
		return ServerClient.SSOAPI.Post<BaseResponse<CreateUserResponse>	>(CreateServer.controllerName, 'ActivationCode', {
			data: model,
		});
	};
	static createUserByCreditCard = (model: PurchaseUserInfoModel, stripePaymentMethodId: string) => {
		model.stripePaymentMethodId = stripePaymentMethodId;
		return ServerClient.SSOAPI.Post<BaseResponse<CreateUserResponse>>(CreateServer.controllerName, 'CreditCard', {
			data: model,
			headers: {'ajax': 'true'},
		});
	};
}
