import {useRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {Alert} from '@esgillc/ui-kit/modal';
import {ModalManager} from '@esgillc/ui-kit/modal';

type Props = {
	close: () => void;
};

export function AlreadyAssignedToTimeSlotAlert(props: Props): JSX.Element {
	const manager = useRef<ModalManager>();
	return <Alert modalManagerRef={manager}>
		<Alert.Body>
			This student has already been assigned to a time slot.
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={() => manager.current.close(props.close)}>
				OK
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
