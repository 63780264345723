import {BaseService} from '@esgi/core/service';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {TestsService} from './tests-service';
import {SettingsService} from 'shared/modules/reports/flash-cards/settings-form/services/settings-service';
import {ResultsService} from 'shared/modules/reports/flash-cards/results-form/services/results-service';
import {
	FlashCardsHierarchyService,
} from 'shared/modules/reports/flash-cards/settings-form/services/flash-cards-hieararchy-service';

export class DataService extends BaseService {
	private readonly _hierarchyService: FlashCardsHierarchyService = new FlashCardsHierarchyService();
	private readonly _settingsService: SettingsService = new SettingsService();
	private readonly _testsService: TestsService = new TestsService(this._hierarchyService, this._settingsService, this.hierarchy);
	private readonly _resultsService: ResultsService = new ResultsService(this._settingsService, this._testsService);

	constructor(private hierarchy: HierarchySnapshot) {
		super();
	}

	public get testsService(): TestsService {
		return this._testsService;
	}

	public get hierarchyService(): FlashCardsHierarchyService {
		return this._hierarchyService;
	}

	public get settingsService(): SettingsService {
		return this._settingsService;
	}

	public get resultsService(): ResultsService {
		return this._resultsService;
	}

	public destroy() {
		super.destroy();
		this._testsService.destroy();
		this._settingsService.destroy();
		this._hierarchyService.destroy();
	}
}
