import {Drawer} from '@esgi/main/features/teacher/home';
import {Form} from '@esgi/ui/form';
import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const DrawerBody = styled(Drawer.Body, {
	gridTemplateRows: 'repeat(2, auto) 1fr',
	gap: '$4',
});

export const FormWrapper = styled(Form, {
	display: 'grid',
	gap: '40px',
});

export const FormElementWrapper = styled(Box, {
	paddingBottom: '10px',
	'& > div': {
		marginBottom: '4px',
	},
});

export const ViewModeNotification = styled(FlexBox, {
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
});

export const Notification = styled(Text, {
	color: '$neutral40',
	lineHeight: '12px',
	backgroundColor: '$vivid',
	borderRadius: '6px',
	border: '1px solid $background',
	padding: '12px 20px',
});

export const Label = styled(Text, {
	color: '$neutral40',

	variants: {
		color: {
			'lowContrast': {
				color: '$lowContrast',
			},

			'neutral24': {
				color: '$neutral24',
			},
		},
	},
});

export const ButtonLabel = styled(Text, {
	fontFamily: 'Roboto',
	fontSize: '$medium',
	fontStyle: 'normal',
	fontWeight: '600',
	lineHeight: '16px',
});

export const ScrollableWrapper = styled(Box, {
	overflowY: 'auto',
});

export const StudentManagerLink = styled(Text, {
	color: '$primary',
	marginLeft: '5px',

	'&:hover': {
		textDecoration: 'underline',
	},
});
