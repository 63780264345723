import React, {CSSProperties, ReactNode, RefObject} from 'react';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';
import {useState} from '@esgi/deprecated/react';
import ScrollContext, {ScrollContextState} from './scroll-context';

type Key = string | number;

export interface RenderArgs<T> {
	item: T;
	index: number;
	itemRef: (ref: HTMLElement) => void;
	key: Key;
}

class State {
	initialized: boolean = false;
	ref: HTMLElement = null;
	from: number = 0;
	to: number = 0;
	topOffset: number = 0;
	process: boolean = true;
	viewportHeight: number = 0;
}

class Props<T> {
	estimatedHeight: number;
	rowsHeight?: Map<Key, number>;
	keySelector?: (item: T) => Key;
	topOffsetRender?: (styles: CSSProperties) => React.ReactNode;
	source: T[];
	children: (args: RenderArgs<T>) => React.ReactNode;
}

@useState(State)
export default class PartialContent<T> extends React.PureComponent<Props<T>, State> {
	private onDestroy$: Subject<void> = new Subject();
	private lastScrollPosition: number = 0;
	private lastRecalculationTime: number = 0;
	private containerHeight: number = 0;
	private expanderRef: RefObject<HTMLDivElement>;
	private ready: boolean = false;
	private rowsHeight: Map<Key, number> = new Map<Key, number>();
	private delayRecalculationEmitter: Subject<boolean> = new Subject<boolean>();

	public componentDidMount() {
		this.initRowHeightStore();
		this.delayRecalculationEmitter
			.pipe(takeUntil(this.onDestroy$), debounceTime(10))
			.subscribe((force) => this.recalculateBounds(force));
	}

	private initRowHeightStore(force?: boolean): void {
		const store = this.rowsHeightStore;
		for (let i = 0; i < this.props.source.length; i++) {
			const key = this.getKeyOfItem(this.props.source[i], i);
			const value = store.get(key);
			if (!value || force) {
				store.set(key, this.props.estimatedHeight);
			}
		}
	}

	public componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.source != this.props.source) {
			if (prevProps.source.length !== this.props.source.length) {
				this.initRowHeightStore();
				this.recalculateBounds(true);
			}
		}

		if (prevState.ref !== this.state.ref && this.state.ref) {
			this.recalculateBounds();
		}
	}

	public render() {
		return <ScrollContext.Consumer>
			{(context) => this.renderContent(context)}
		</ScrollContext.Consumer>;
	}

	private renderContent(context: ScrollContextState): ReactNode {
		if (!this.ready) {
			context.onScroll
				.pipe(
					takeUntil(this.onDestroy$),
					distinctUntilKeyChanged('pos'),
					filter(p => {
						const scrollOffset = this.props.estimatedHeight || this.rowsHeightStore[0] || 10;
						const a = Math.abs(this.lastScrollPosition - p.pos) > scrollOffset;
						const b = (new Date().getDate()) - this.lastRecalculationTime > 33;
						return a || b;
					}))
				.subscribe(e => {
					this.recalculateBounds();
				});
			context.onInit.pipe(takeUntil(this.onDestroy$)).subscribe(e => {
				this.setState({ref: e.ref});
			});
			context.onHeightChanged.pipe(takeUntil(this.onDestroy$)).subscribe(e => {
				this.setState({viewportHeight: e.height}, () => this.recalculateBounds());
			});
			this.expanderRef = context.expanderRef;
			this.ready = true;
		}

		const styles = {
			height: this.state.topOffset + 'px',
			width: '100%',
			pointerEvents: 'none',
			visibility: 'hidden',
		} as CSSProperties;

		return <>
			{this.props.topOffsetRender ? this.props.topOffsetRender(styles) : this.defaultOffsetRender(styles)}
			{this.props.source.slice(this.state.from, this.state.to).map((item) => this.renderRow(item))}
		</>;
	}

	private defaultOffsetRender(styles: CSSProperties): ReactNode {
		return <div style={styles}/>;
	}

	private renderRow(item: T): ReactNode {
		const index = this.props.source.indexOf(item);
		let key: Key = this.getKeyOfItem(item, index);
		return this.props.children({item: item, key: key, index: index, itemRef: (ref) => this.itemRendered(key, ref)});
	}

	private get rowsHeightStore(): Map<Key, number> {
		if (this.props.rowsHeight) {
			return this.props.rowsHeight;
		} else {
			return this.rowsHeight;
		}
	}

	private getKeyOfItem(item: T, index: number): Key {
		if (this.props.keySelector) {
			return this.props.keySelector(item);
		} else {
			return index;
		}
	}

	private itemRendered(key: Key, ref: HTMLElement): void {
		const store = this.rowsHeightStore;
		let value = store.get(key);
		if (ref) {
			let height = ref.clientHeight;
			if (value !== height) {
				store.set(key, height);
				this.delayRecalculationEmitter.next(false);
			}
		}
	}

	private recalculateBounds(force: boolean = false): void {
		if (!this.state.process || !this.state.viewportHeight) {
			return;
		}

		const scrollContainer = this.state.ref;
		if (scrollContainer) {
			this.lastScrollPosition = scrollContainer.scrollTop;
			this.lastRecalculationTime = new Date().getTime();

			const scrollPosition = scrollContainer.scrollTop;
			const viewportHeight = this.state.viewportHeight;
			const prevRenderedItemsCount = this.state.to - this.state.from;

			let offset = viewportHeight * 0.2;
			if (this.props.estimatedHeight * 5 > offset) {
				offset = this.props.estimatedHeight * 5;
			}

			let startIndex = null;
			let endIndex = null;

			// This is the bounds of the visible + offset layer
			let approxTopPosition = scrollPosition - offset;
			if (approxTopPosition < 0) {
				approxTopPosition = 0;
			}

			const approxBottomPosition = scrollPosition + viewportHeight + offset;

			let realHeight = 0;
			let realTopOffset = 0;

			for (let i = 0; i < this.props.source.length; i++) {
				const item = this.props.source[i];
				const itemKey = this.getKeyOfItem(item, i);
				const itemHeight = this.rowsHeightStore.get(itemKey);

				realHeight += itemHeight;

				if (endIndex == null) {
					if (realHeight > approxTopPosition && startIndex == null) {
						startIndex = i;
					}

					if (startIndex == null) {
						realTopOffset += itemHeight;
					}

					if (realHeight > approxBottomPosition) {
						if (endIndex == null) {
							const tempItemsToRenderCount = i - startIndex;
							if (prevRenderedItemsCount !== tempItemsToRenderCount) {
								if (Math.abs(prevRenderedItemsCount - tempItemsToRenderCount) === 1) {
									if (this.props.estimatedHeight >= itemHeight && (realHeight - approxBottomPosition < this.props.estimatedHeight)) {
										endIndex = startIndex + prevRenderedItemsCount;
										const curr = endIndex - startIndex;
										if (curr > prevRenderedItemsCount) {
											do {
												endIndex--;
											} while (endIndex - startIndex !== prevRenderedItemsCount);
										} else if (curr < prevRenderedItemsCount) {
											do {
												endIndex++;
											} while (endIndex - startIndex !== prevRenderedItemsCount);
										}

										continue;
									}
								}
							}
							endIndex = i;
						}
					}
				}
			}

			//Edge cases handling.
			if (startIndex == null || startIndex < 0) {
				startIndex = 0;
				realTopOffset = 0;
			}

			if (!endIndex || endIndex < 0) {
				endIndex = this.props.source.length;
			}

			if (Math.abs(this.state.from - startIndex) > 4 || Math.abs(this.state.to - endIndex) > 4 || force) {
				if (realHeight !== this.containerHeight) {
					this.containerHeight = realHeight;
					if (this.expanderRef.current) {
						this.expanderRef.current.style.height = this.containerHeight + 'px';
					}

				}

				this.setState({
					from: startIndex,
					to: endIndex,
					topOffset: realTopOffset,
				});
			}
		}
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
