import 'jqueryui';
import './ajax-retry';
import './easing';
import './jquery-appear';
import './jquery-extensions';
import './extensions';
import './jquery-ui-1.10.4.custom.css';
import './jquery.mousewheel';
import './mask';
import './jquery.floatThead';
import './jquery.floatThead.css';
import './perfect-scrollbar/perfect-scrollbar.jquery';
import './perfect-scrollbar/perfect-scrollbar.css';
import './perfect-scrollbar/perfect-scroll-bar.less';
import './print-area/jquery.PrintArea';
import './resizable-columns/jquery.resizableColumns';
import './resizable-columns/jquery.resizableColumns.css';
import './table-hover/jquery.tablehover.min';
import 'qtip2';
import 'qtip2/dist/jquery.qtip.min.css';
import './loadmask/jquery.loadmask.min';
import './loadmask/jquery.loadmask.css';
import './loadmask/animated-loading.less';
import './chosen/bundle';
import {resolvedPromise} from '@esgi/deprecated/utils';
import {
	activatePerfectScrollBarInIframe,
	activatePerfectScrollBarOnNotWindowPlatform, isNotWindowPlatform,
} from './perfect-scrollbar/perfect-scroll-bar';

const windowProvider = window as any;
windowProvider.$ = $;
windowProvider.jQuery = $;
windowProvider.resolvedPromise = resolvedPromise;
windowProvider.activatePerfectScrollBarOnNotWindowPlatform = activatePerfectScrollBarOnNotWindowPlatform;
windowProvider.activatePerfectScrollBarInIframe = activatePerfectScrollBarInIframe;
windowProvider.isNotWindowPlatform = isNotWindowPlatform;