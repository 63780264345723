import {HierarchySnapshot} from 'modules/hierarchy/models';
import React, {useEffect, useMemo, useState} from 'react';
import './footer.less';
import {InitModel as ParentLetterSettingsInitModel} from 'modules/reports/parent-letter/models';
import {SubjectType} from '@esgi/core/enums';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import {HierarchyModel} from 'shared/modules/reports/flash-cards/settings-form/services/flash-cards-hieararchy-service';
import {ChangesCollector} from '../../../../utils/changes-collector';
import {dispatchAppEvent} from '@esgillc/events';
import {ViewParentLetterEvent} from 'modules/reports/parent-letter/events';
import {useStream} from '@esgillc/ui-kit/utils';

class Props {
	hierarchy: HierarchySnapshot;
	dataService: DataService;
	onCloseClicked: () => void;
	launchHieararchyEvents: () => void;
	viewResultsClicked: () => void;
	changesCollector: ChangesCollector;

	parentLetterOnClosed: (emit: boolean, changesCollector: ChangesCollector) => void;
}

export default function FlashCardsSettingsFooter({
	                                                 dataService,
	                                                 changesCollector,
	                                                 onCloseClicked,
	                                                 viewResultsClicked,
}: Props) {
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const hierarchy = useStream(dataService.hierarchyService.hierarchy$);

	useEffect(() => {
		dataService.testsService.resultButtonDisabled$.subscribe((val) => setButtonDisabled(val));
	}, []);

	function callParentLetter() {
		const plInitModel = new ParentLetterSettingsInitModel(
			hierarchy.selectedGroupId > 0 ? hierarchy.selectedGroupId : hierarchy.selectedClassId,
			hierarchy.selectedStudent?.studentId,
			hierarchy.selectedSubject?.id,
			SubjectType[hierarchy.selectedSubject?.subjectType]);

		dispatchAppEvent(ViewParentLetterEvent,
			new ViewParentLetterEvent(plInitModel, changesCollector));
		onCloseClicked();
	}


	return <>
		<div className='fc-report-footer'>
			<div className='control-group'>
				<div className='controls'>
					<button className='btn btn-sm btn-link btn-pl go-to-parent-letter-button'
					        onClick={() => callParentLetter()}
					>
						<span style={{margin: '0 5px'}}>GO TO PARENT LETTER</span>
						<i className='fa fa-external-link'/>
					</button>
				</div>
			</div>
			<div>
				<button className='btn btn-primary view-flashcards-button'
				        onClick={viewResultsClicked}
				        disabled={buttonDisabled}
				>
					<span style={{margin: '0 5px'}}>VIEW FLASHCARDS</span>
				</button>
			</div>
		</div>
	</>;
}

