import {useMemo} from 'react';
import {Student} from '@esgi/main/libs/store';
import {StudentSort, useUser} from '@esgi/core/authentication';

export function studentsComparator(sortStudentsBy: StudentSort) {
	return (a: Student, b: Student) => {
		if (StudentSort[sortStudentsBy] === StudentSort.FirstName) {
			return a.firstName.localeCompare(b.firstName);
		}

		if (StudentSort[sortStudentsBy] === StudentSort.LastName) {
			return a.lastName.localeCompare(b.lastName);
		}

		return 0;
	};
}

export const useSortStudents = (students: Student[], sortBy?: StudentSort): Student[] => {
	const user = useUser();
	const sortStudentsBy = sortBy || user?.studentSort || StudentSort.FirstName;

	return useMemo(() => [...students].sort(studentsComparator(sortStudentsBy)), [students, sortStudentsBy]);
};
 
export const useSortStudentsInBucket = <Bucket extends {students: Student[]}>(buckets: Bucket[], sortBy?: StudentSort): Bucket[] => {
	const user = useUser();
	const sortStudentsBy = sortBy || user?.studentSort || StudentSort.FirstName;

	return useMemo(
		() => buckets.map((b) => ({...b, students: b.students.sort(studentsComparator(sortStudentsBy))})),
		[buckets, sortStudentsBy],
	);
};
