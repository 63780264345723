import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const LogoLinkContainer = styled(FlexBox, {
	height: '40px',
	padding: '0px 8px 0px 12px',
	borderRadius: '8px',
	border: '1px solid $primaryMuted',
	justifyContent: 'space-between',
	cursor: 'pointer',
	alignItems: 'center',
	'&:hover': {
		background: '$secondaryBackground',
		borderColor: '$secondaryMuted',
	},
	'&:active': {
		background: '$background',
		borderColor: '$primaryMuted',
	},
});
