import {useMemo} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CirclePlusIcon} from '@esgillc/ui-kit/icons';
import {useTestSessionDetailsService} from '../../../../hooks';
import {dispatchAppEvent} from '@esgillc/events';
import {TestSessionDeleteEvent, TestSessionAddEvent} from '../../../../events';
import {TestSubsetOpenEvent} from '../../events';
import styles from './styles.module.less';

interface Props {}

export function YesNoEditButtons ({}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);

	const canChange = useMemo<boolean>(
		() => testSessionDetails ? testSessionDetails.canEdit : false,
		[testSessionDetails],
	);

	const canSubset = useMemo<boolean>(() => {
		if (!testSessionDetails || ! currentTestSession) {
			return false;
		}

		const {canEdit, canTest} = testSessionDetails;

		return !currentTestSession.deleted && canEdit && canTest;
	}, [testSessionDetails, currentTestSession]);

	const onAdd = () => {
		dispatchAppEvent(TestSessionAddEvent, new TestSessionAddEvent());
	};

	const onDelete = () => {
		dispatchAppEvent(TestSessionDeleteEvent, new TestSessionDeleteEvent());
	};

	const onSubset = () => {
		dispatchAppEvent(TestSubsetOpenEvent, new TestSubsetOpenEvent());
	};

	if (!editMode && canChange) {
		return (
			<>
				{canChange &&
					<Buttons.Link
						className={styles.button}
						onClick={onAdd}
					>
						<CirclePlusIcon/>
						Add Test Session
					</Buttons.Link>
				}
				{canSubset &&
					<>
						<span>|</span>
						<Buttons.Link
							className={styles.button}
							onClick={onSubset}
						>
							Run Subset
						</Buttons.Link>
					</>
				}
			</>
		);
	}

	if (canChange && !currentTestSession.deleted && !currentTestSession.isEmpty) {
		return (
			<Buttons.Link
				className={styles.button}
				onClick={onDelete}
			>
				Delete test session
			</Buttons.Link>
		);
	}
}
