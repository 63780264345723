import {Modal} from '@esgi/deprecated/ui-kit/modal';
import {useModal} from '@esgillc/ui-kit/modal';
import {IconsRow} from './components/icons-row';
import {TextRow} from './components/text-row';
import {Buttons} from '@esgillc/ui-kit/button';
import {Divider} from 'pages/assignment-center/components/kit/divider';
import styles from './styles.module.less';

interface Props {
	onClose?: () => void;
}

export function IntroductionDialog({onClose}: Props) {
	const modalRef = useModal();
	return (
		<Modal className={styles.modal} modalManagerRef={modalRef}>
			<div className={styles.title}>How to Create an Assignment</div>
			<div className={styles.subTitle}>Create a group of Self-Assess tests and assign to one or many students</div>
			<IconsRow/>
			<TextRow/>
			<Divider/>
			<div className={styles.credentials}>
				<img src='//s3.amazonaws.com/esgiwebfiles/Images/sa-assignment-tutorial/addcredentials.png'/>
				<span>Add Student Credentials</span>
				<div className={styles.description}>
					Add a student username and password to grant access to your assignments.
				</div>
			</div>
			<div className={styles.footer}>
				<Buttons.Contained id='learn-more-im'>Learn More</Buttons.Contained>
				<Buttons.Contained onClick={() => modalRef.current.close(onClose)}>Got It</Buttons.Contained>
			</div>
		</Modal>
	);
}
