import {AxiosRequestConfig} from 'axios';
import {Observable} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {ErrorHandler} from '../error-handler';
import {FormDataBuilder} from './utils/form-data-builder';
import {ObservableBuilder} from './observable-builder';
import {UrlSearchParamsBuilder} from './utils/url-search-params-builder';
import {CallbackOptions} from '../types';
import {openWindowWithPost} from './utils/window-tools';

export interface BuilderOptions {
	baseUrl: string;
	defaultErrorHandlers: ErrorHandler[];
	callback?: CallbackOptions;
	cancel$: Observable<void>
	responseMapper?: (data: any) => any,
}

function concatUrl(controller: string, action: string): string {
	return [controller, action].join('/').replace('//', '/');
}

export class RequestBuilder {
	constructor(protected readonly options: Readonly<BuilderOptions>) {
		this.options = {...{useLowerCase: false}, ...options};
	}

	/* The `get` method is used to make a GET request to a specified controller and action. It takes in
	the controller and action names as parameters, along with optional data and configuration options. */
	public get<TOut>(controller: string, action: string, data?: object, config?: AxiosRequestConfig): ObservableBuilder<TOut>;
	public get<TIn, TOut>(controller: string, action: string, data?: TIn, config?: AxiosRequestConfig): ObservableBuilder<TOut>;
	public get(controller: string, action: string, data?: object, config?: AxiosRequestConfig): ObservableBuilder<any> {
		const conf = config || {};
		conf.method = 'get';
		conf.baseURL = this.options.baseUrl;
		conf.url = concatUrl(controller, action);
		conf.params = UrlSearchParamsBuilder.BuildParameters(data);
		conf.responseType = conf.responseType || 'json';
		return this.getObservableBuilder(conf);
	}

	/**
	 * The function `post` sends a POST request to a specified controller and action with optional data
	 * and configuration.
	 * @param {string} controller - The `controller` parameter is a string that represents the name of the
	 * controller in your backend API. It is used to specify the endpoint where the request will be sent.
	 * @param {string} action - The `action` parameter is a string that represents the specific action or
	 * endpoint that you want to call on the server. It is typically a URL path or route that corresponds
	 * to a specific functionality or operation on the server.
	 * @param {object} [data] - The `data` parameter is an optional object that contains the data to be
	 * sent in the request body. It is used for sending data to the server when making a POST request.
	 * @param {AxiosRequestConfig} [config] - The `config` parameter is an optional object that allows you
	 * to customize the Axios request configuration. It can include properties such as headers, timeout,
	 * authentication, and more. If no `config` object is provided, a default empty object will be used.
	 * @returns The method is returning an instance of the `ObservableBuilder<T>` class.
	 */
	public post<T>(controller: string, action: string, data?: object, config?: AxiosRequestConfig): ObservableBuilder<T> {
		const conf = config || {};
		conf.method = 'post';
		conf.baseURL = this.options.baseUrl;
		conf.url = concatUrl(controller, action);
		conf.data = data;
		conf.responseType = conf.responseType || 'json';
		return this.getObservableBuilder(conf);
	}

	/**
	 * The function `put` sends a PUT request to a specified controller and action with optional data and
	 * configuration.
	 * @param {string} controller - A string representing the name of the controller to be called.
	 * @param {string} action - The `action` parameter is a string that represents the specific action or
	 * endpoint that you want to perform on the controller. It is used to construct the URL for the
	 * request by appending it to the controller name.
	 * @param {object} [data] - The `data` parameter is an optional object that contains the data to be
	 * sent in the request body. It is used for sending data to the server when making a PUT request.
	 * @param {AxiosRequestConfig} [config] - The `config` parameter is an optional object that allows you
	 * to customize the Axios request configuration. It can include properties such as headers, timeout,
	 * authentication, and more. If no `config` object is provided, a default empty object will be used.
	 * @returns The method is returning an instance of the `ObservableBuilder` class.
	 */
	public put<T>(controller: string, action: string, data?: object, config?: AxiosRequestConfig): ObservableBuilder<T> {
		const conf = config || {};
		conf.method = 'put';
		conf.baseURL = this.options.baseUrl;
		conf.url = concatUrl(controller, action);
		conf.data = data;
		conf.responseType = conf.responseType || 'json';
		return this.getObservableBuilder(conf);
	}

	/**
	 * The function `delete` sends a DELETE request to a specified controller and action with optional
	 * data and configuration.
	 * @param {string} controller - The controller parameter is a string that represents the name of the
	 * controller in your application. It is used to specify the endpoint for the delete request.
	 * @param {string} action - The `action` parameter is a string that represents the specific action or
	 * endpoint that you want to delete data from. It is used to construct the URL for the delete request.
	 * @param {object} [data] - The `data` parameter is an optional object that contains the data to be
	 * sent in the request body. It is used for sending data to the server when making a DELETE request.
	 * @param {AxiosRequestConfig} [config] - The `config` parameter is an optional object that allows you
	 * to customize the Axios request configuration. It can include properties such as headers, timeout,
	 * authentication, and more. If no `config` object is provided, a default empty object will be used.
	 * @returns The method is returning an instance of the `ObservableBuilder<T>` class.
	 */
	public delete<T>(controller: string, action: string, data?: object, config?: AxiosRequestConfig): ObservableBuilder<T> {
		const conf = config || {};
		conf.method = 'delete';
		conf.baseURL = this.options.baseUrl;
		conf.url = concatUrl(controller, action);
		conf.data = data;
		conf.responseType = conf.responseType || 'json';
		return this.getObservableBuilder(conf);
	}

	/**
	 * The function is used to send a file to a server and download the response as a Blob.
	 * @param {string} controller - The controller parameter is a string that represents the name of the
	 * controller in the backend that will handle the request.
	 * @param {string} action - The `action` parameter is a string that represents the action or endpoint
	 * that the file should be sent to. It is used to construct the URL for the file upload request.
	 * @param {string} filename - The `filename` parameter is a string that represents the name of the
	 * file that will be downloaded.
	 * @param {object} [data] - The `data` parameter is an optional object that contains the data to be
	 * sent in the request. It can be used to pass any additional information or payload to the server.
	 * @param {AxiosRequestConfig} [config] - The `config` parameter is an optional object that allows you
	 * to customize the Axios request configuration. It can include properties such as `headers`,
	 * `params`, `timeout`, `auth`, etc. These properties will be used to configure the Axios request made
	 * by the `getObservableBuilder` method. If
	 * @param {boolean} [json] - The `json` parameter is a boolean flag that indicates whether the data
	 * should be sent as JSON or as form data. If `json` is set to `true`, the data will be sent as JSON.
	 * If `json` is set to `false` or not provided, the data will be
	 * @param {boolean} [preventSaving] - The `downloadDisabled` parameter is a boolean flag that indicates whether the data
	 * should download immidiate or not.
	 * @returns an Observable of type T.
	 */
	public file<T = Blob>(controller: string,
	                      action: string,
	                      filename: string,
	                      data?: object,
	                      config?: AxiosRequestConfig,
						  json?: boolean,
						  preventSaving?: boolean): Observable<T> {
		if (!preventSaving && navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
			return new Observable<T>((observer) => {
				observer.next();
				observer.complete();
			}).pipe(tap(() => openWindowWithPost([this.options.baseUrl, controller, action].join('/'), data)));
		}

		const conf = config || {};
		const formData = FormDataBuilder.BuildParameters(data);
		conf.data = json ? data : formData;
		conf.method = 'post';
		conf.baseURL = this.options.baseUrl;
		conf.url = [controller, action].join('/');
		conf.responseType = conf.responseType || 'blob';
		conf.headers = {ajax: true, 'Content-Type': json ? undefined : 'multipart/form-data'};
		conf.withCredentials = true;

		return this.getObservableBuilder<T>(conf).pipe(map(response => {
			const blob = response;
			if (!preventSaving) {
				const link = document.createElement('a');
				link.setAttribute('type', 'hidden');
				link.href = URL.createObjectURL(blob as any);
				link.download = filename;
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
			return blob;
		})).asObservable();
	}

	/**
	 * The function `sendBeacon` sends a beacon request to a specified controller and action with optional
	 * data.
	 * @param {string} controller - The controller parameter is a string that represents the name of the
	 * controller or module that will handle the request. It is used to specify the endpoint or route on
	 * the server-side where the request will be sent.
	 * @param {string} action - The `action` parameter is a string that represents the action or method
	 * that you want to perform on the server. It could be a specific endpoint or function that you want
	 * to call.
	 * @param {object} [data] - The `data` parameter is an optional object that contains additional data
	 * to be sent along with the beacon request. It can be used to provide additional information or
	 * payload to the server.
	 * @returns a boolean value. If the browser supports the `navigator.sendBeacon` method, it will
	 * attempt to send a beacon request with the specified `controller`, `action`, and optional `data`
	 * parameters. If the beacon request is successfully sent, the function will return `true`. Otherwise,
	 * it will return `false`.
	 */
	public sendBeacon(controller: string, action: string, data?: object): boolean {
		if (navigator.sendBeacon) {
			return navigator.sendBeacon(
				[this.options.baseUrl, controller, action].join('/'),
				// @ts-ignore ignore since beacon allow to send object.
				data,
			);
		}
		return false;
	}

	/**
	 * Create an ObservableBuilder object with a specified AxiosRequestConfig.
	 * @param {AxiosRequestConfig} config - The `config` parameter is an object of type
	 * `AxiosRequestConfig`.
	 * @returns The method is returning an instance of the `ObservableBuilder<T>` class.
	 */
	public custom<T>(config: AxiosRequestConfig): ObservableBuilder<T> {
		return this.getObservableBuilder(config);
	}

	private getObservableBuilder<T>(config: AxiosRequestConfig): ObservableBuilder<T> {
		config.headers = {ajax: true, ...(config.headers || {})};
		config.withCredentials = true;
		return new ObservableBuilder<T>(
			config,
			this.options.defaultErrorHandlers,
			this.options.callback,
			this.options.responseMapper,
		).pipe(takeUntil(this.options.cancel$));
	}
}