import {SharedComponent, SharedProps} from '@esgi/deprecated/react';

export class ContextMenuState {
	x: number;
	y: number;
}

export class ContextMenuProps<ContextMenuState> extends SharedProps<ContextMenuState> {
	close: () => any;
	element: HTMLAnchorElement | HTMLDivElement;
	x: number;
	y: number;
}

export class ContextMenu<S extends ContextMenuState, P extends ContextMenuProps<S> = ContextMenuProps<S>> extends SharedComponent<S, P> {

	constructor(props: P) {
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.setState({x: props.x, y: props.y});
	}

	protected rootElement: HTMLUListElement;

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		$('.cards').on('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		document.removeEventListener('mousedown', this.handleClickOutside);
		$('.cards').unbind('scroll');
	}

	handleScroll(event: Event) {
		this.props.close();
	}

	handleClickOutside(event) {
		if (this.rootElement && !this.rootElement.contains(event.target)) {
			this.props.close();
		}
	}
	render(): JSX.Element | false | null {
		return null;
	}
}