import {useEffect, useMemo} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {EventBusDispatcher} from '@esgillc/events';
import {Alert} from '@esgillc/ui-kit/modal';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {TestSessionStatus} from '../enums';
import {TestSessionStatusUserMovedEvent} from '../events';
import {getTestSessionStatus} from './utils';

type Props = {
	status: TestSessionStatus;
	onConfirm: () => void;
	studentName: string;
	testName: string;
}

export function SessionStatusAlert(props: Props): JSX.Element {
	useEffect(() => {
		if (props.status === TestSessionStatus.UserMoved) {
			EventBusDispatcher.dispatch(TestSessionStatusUserMovedEvent, new TestSessionStatusUserMovedEvent());
		}
	}, []);

	const text = useMemo(() => {
		return getTestSessionStatus(props.status, props.studentName, props.testName);
	}, [props.status]);

	const modal = useModal();
	const close = useCloseModal(modal, props.onConfirm);

	return <Alert modalManagerRef={modal}>
		<Alert.Body>
			{text}
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={close}>
				OK
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
