import {StudentModel} from '../../../types';
import {CredentialsButton, TableStudentsRow} from './components.styled';
import {CellTestBarInfo, CountInfo} from '../../table-tests';
import {Box} from '@esgi/ui/layout';
import {Access, AccessError} from '@esgi/ui/icons';
import {OneLinedText, ProgressDashedLine, getFullName} from '@esgi/main/kits/assignments';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

type Props = StudentModel & {
	testsCount: number;
	onOpenStudentCredentials: (studentId: StudentModel['studentID']) => void;
};

export function TableStudentsRowData({
	joinedClassName,
	firstName,
	joinedGroupName,
	hasCredentials,
	lastName,
	progress,
	testsCount,
	studentID,
	onOpenStudentCredentials,
}: Props) {
	const withTestsTakenBar = testsCount >= 1;

	return (
		<TableStudentsRow withBottomMargin dataCy='table-students-row'>
			<OneLinedText size='medium' bold color='base' data-cy='student-name'>
				{getFullName({firstName, lastName})}
			</OneLinedText>
			<OneLinedText size='small' bold color='neutral40' data-cy='student-class'>
				{joinedClassName}
			</OneLinedText>
			<OneLinedText size='small' bold color='neutral40' data-cy='student-group'>
				{joinedGroupName}
			</OneLinedText>
			{withTestsTakenBar ? (
				<CellTestBarInfo>
					<Tooltip>
						<Tooltip.Trigger>
							<CountInfo
								value={progress}
								maxValue={testsCount}
								valueTextColor={!progress ? 'neutral40' : 'primary'}
								maxValueTextColor='lowContrast'
							/>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<Text size='small'>{`${progress} out of ${testsCount} tests have been completed`}</Text>
						</Tooltip.Content>
					</Tooltip>

					<ProgressDashedLine value={progress} maxValue={testsCount} />
				</CellTestBarInfo>
			) : (
				<Box />
			)}
			<Tooltip>
				<Tooltip.Trigger>
					<CredentialsButton
						withBackgroundHover
						hasCredentials={hasCredentials}
						onClick={() => onOpenStudentCredentials(studentID)}
					>
						{hasCredentials ? <Access width={24} height={24} /> : <AccessError width={24} height={24} />}
					</CredentialsButton>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text size='small'>Add, edit, or view student credentials</Text>
				</Tooltip.Content>
			</Tooltip>


		</TableStudentsRow>
	);
}
