import {AllTabContent} from './components/all-tab-content';
import {DefaultModeContent} from './components/mode-content-variants/default-mode-content';
import {EditableModeContent} from './components/mode-content-variants/editable-mode-content';
import {RearrangeModeContent} from './components/mode-content-variants/rearrange-mode-content';
import {SubjectSectionRoot} from './components/subject-section-root';

export {useSubjectSectionContext, SubjectSectionContext} from './context/subject-section-context';
export type {SubjectSectionContextValue} from './context/types';

export const SubjectSection = {
	Root: SubjectSectionRoot,
	AllTabContent,
	DefaultModeContent,
	EditableModeContent,
	RearrangeModeContent,
};

export {SubjectSectionMode} from './types';
export {type AllTabListVariant} from './components/all-tab-content';
