import {Component} from './component';
import {testColorIndicatorWidth} from './index.styled';

/**
 * @deprecated Use the TestColorLineIndicator compoent instead ({@link @esgi/main/kits/common}).
 * @see import {TestColorLineIndicator} from '@esgi/main/kits/common';
 */
export const TestColorIndicator = Object.assign(Component, {
	testColorIndicatorWidth,
});
