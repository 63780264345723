/* eslint-disable @typescript-eslint/member-ordering */
import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {ChangeEvent} from 'react';
import {ServerConflictStudentModel, UploadStudentsResponse, ValidStudentModel, ValidationStatus} from './types';

export class AddStudentsViaFileService extends BaseService {
	private controller = 'v2/teachers/modules/students/roster-tool';

	public validationStatus = new BehaviorSubject(ValidationStatus.None);
	public validationData = new BehaviorSubject<string>('');
	public className = new BehaviorSubject<string | null>(null);
	public conflictStudents = new BehaviorSubject<ServerConflictStudentModel[] | null>(null);
	public uploadedStudentsCount = new BehaviorSubject(0);

	public createdStudents = new BehaviorSubject<ValidStudentModel[] | null>(null);

	public duplicatesSaved = new BehaviorSubject(false);

	public downloadTemplate = () => {
		window.location.href = 'https://esgiwebfiles.s3.amazonaws.com/upload-roster-tool/template-2023.xlsx';
	};

	public onFileUpload(event: ChangeEvent<HTMLInputElement>, classId: string) {
		const {files} = event.target;

		if (files?.length) {
			const file = files[0]!;
			const filename = file.name;
			const size = file.size;
			const parts = filename.split('.');
			const fileType = parts.at(-1);

			if (size < 10000000 && fileType && ['csv', 'xlsx'].includes(fileType)) {
				this.uploadFile({file, classId});
			} else {
				this.validationStatus.next(ValidationStatus.IncorrectFileType);
			}
		}
	}

	private uploadFile({file, classId}: {file: File; classId: string}) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('classID', classId);

		return this.httpClient.ESGIApi.post<UploadStudentsResponse>(this.controller, 'upload', formData).subscribe({
			next: (response) => {
				if (!response) {
					return;
				}

				const {
					value: {
						validation,
						className,
						conflictStudents,
						uploadedStudentsCount,
						createdStudents: createdStudentsResponse,
					},
				} = response;

				const isCompleteStatus = [
					ValidationStatus.Complete,
					ValidationStatus.CompleteWithStudentsExistInClass,
				].includes(validation.status);

				this.validationStatus.next(validation.status);
				this.validationData.next(validation.data);
				this.className.next(className);
				this.createdStudents.next(createdStudentsResponse);
				this.conflictStudents.next(conflictStudents);

				this.uploadedStudentsCount.next(isCompleteStatus ? createdStudentsResponse?.length : uploadedStudentsCount);
			},
		});
	}
}
