import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {UntestedStudentsReportService} from '../../../service';
import styles from '../../modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService;
}

export function ReportTeacher({service}: Props) {
	const teacherName = useBehaviorSubject(service.teacherName);
	const specialistGroups = useBehaviorSubject(service.specialistGroups);

	return <div>
		{specialistGroups.length > 0 &&
			<span>Specialist:</span>}
		{specialistGroups.length == 0 &&
			<span className={styles.bold}>Teacher:</span>
		}
		<span className={styles.name}>{teacherName}</span>
	</div>;
}
