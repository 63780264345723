import {DrawerBody} from './components/drawer-body';
import {DrawerContentBlock} from './components/drawer-content-block';
import {DrawerHeader} from './components/drawer-header';
import {DrawerPanel} from './components/drawer-panel';
import {PanelContent} from './components/drawer-panel/components/panel-content';
import {PanelHeader} from './components/drawer-panel/components/panel-header';
import {PanelSidebar} from './components/drawer-panel/components/panel-sidebar';
import {DrawerRoot} from './components/drawer-root';
import {useForceDrawerClose} from './hooks/use-force-drawer-close';

export const Drawer = Object.assign(DrawerRoot, {
	Header: DrawerHeader,
	Body: DrawerBody,
	ContentBlock: DrawerContentBlock,
	Panel: DrawerPanel,
	PanelHeader: PanelHeader,
	PanelSidebar: PanelSidebar,
	PanelContent: PanelContent,
	useForceDrawerClose,
});
