import React from 'react';
import styles from './styles.module.less';
import {Modal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

interface Props {
	viewParentLetterClicked: () => void;
	goToFlashCardsClicked: () => void;
	viewParentLetterDisabled: boolean;
}

export function ModalFooter({viewParentLetterClicked, goToFlashCardsClicked, viewParentLetterDisabled}: Props) {
	return <Modal.Footer className={styles.modalFooter}>
		<div className={styles.plReportFooter}>
			<div className={styles.controls}>
				<Buttons.Text onClick={() => goToFlashCardsClicked()}>
					<span>Go To Flashcards</span>
					<i className='fa fa-external-link'/>
				</Buttons.Text>
			</div>

			<div>
				<Buttons.Contained disabled={viewParentLetterDisabled}
					onClick={() => viewParentLetterClicked()}>
					<span>View Parent Letter</span></Buttons.Contained>
			</div>
		</div>
	</Modal.Footer>;
}
