import {Sorting} from '@esgi/contracts/esgi/types/esgi.testexplorer/endpoints/esgi.apigateway/modules/tests/search/sorting';
import {TestScopeTabId, TestTypeTabId} from '../components/tabs/types';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {Test as TestModel} from '@esgi/contracts/esgi/types/esgi.testexplorer/endpoints/esgi.apigateway/modules/tests/search/test';

export type InitRequestModel = {
	keyword: string;
	contentAreaID: string;
	testType: TestTypeTabId;
	scope: TestScopeTabId;
	onlySelfAssess: boolean;
	sorting: Sorting;
	pageIndex: number;
	itemsPerPage: number;
	subjectID: number;
	subjectType: SubjectType;
};

export enum TestType {
	None = 'None',
	YN = 'YN',
	Score = 'Score',
	Rubric = 'Rubric',
}

export type Test = Omit<TestModel, 'testType'> & {
	disabled: boolean;
	testType: TestType;
};

export * from '@esgi/contracts/esgi/types/esgi.enums/sort-direction';
export * from '@esgi/contracts/esgi/types/esgi.testexplorer/endpoints/esgi.apigateway/modules/tests/search/sort-by';
export * from '@esgi/contracts/esgi/types/esgi.testexplorer/endpoints/esgi.apigateway/modules/tests/search/sorting';
export * from '@esgi/contracts/esgi/types/esgi.testexplorer/endpoints/esgi.apigateway/modules/tests/search/scope';
export * from '@esgi/contracts/esgi/types/esgi.testexplorer/endpoints/esgi.apigateway/modules/tests/search/test';
export * from '@esgi/contracts/esgi/types/esgi.testexplorer/endpoints/esgi.apigateway/modules/tests/search/sort-by';
export * from '@esgi/contracts/esgi/types/esgi.assets/endpoints/common/content-areas/models/content-area-model';
export * from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/modules/tests/search-request';
export {type Test as TestStoreModel} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/storage/subjects/test';
export {TestType as TestTypeStorage} from '@esgi/contracts/esgi/types/esgi.enums/test-type';
