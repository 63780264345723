import React, {ReactNode, RefObject} from 'react';
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd';
import {TextArea} from '@esgillc/ui-kit/control';
import {Fade} from '@esgillc/ui-kit/transition';
import {join, isIpad} from '@esgillc/ui-kit/utils';
import {Buttons} from '@esgillc/ui-kit/button';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Criteria} from '../../../../models';
import RubricTooltip from '../../rubric-tooltip/rubric-tooltip';
import {ClearIcon} from '../../../icons';
import DragIcon from './components/drag-icon';
import RemoveDialog from './components/remove-dialog/remove-dialog';
import styles from './criteria.module.less';

class State {
	showValidation: boolean = false;
	showRemoveDialog: boolean = false;
	focus: boolean = false;
}

interface Props {
	className?: string;
	criteria: Criteria;
	hasRelatedData: boolean;
	canRemove: boolean;
	editMode: boolean;
	onChanged: (value: string) => void;
	onRemoveClicked: () => void;
	tooltipContainer: HTMLElement;
	placeholder?: string;
	helpInfo?: string;
	onFocused: (element: HTMLElement) => void;
	dragProps?: DraggableProvidedDragHandleProps;
}

export class CriteriaActiveView extends React.PureComponent<Props, State> {
	public state = new State();

	public readonly boxRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
	public readonly innerBoxRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
	public readonly textAreaRef: RefObject<TextArea> = React.createRef<TextArea>();

	public showValidation(show: boolean): void {
		this.setState({showValidation: show});
	}

	private readonly isIPad = isIpad();

	private get isInvalid(): boolean {
		return this.state.showValidation && !this.props.criteria.name.trim();
	}

	public render() {
		return <>
			<div
				className={join(styles.box, this.state.focus && styles.inFocus, this.props.editMode && styles.active, this.isInvalid && styles.invalid)}
				ref={this.boxRef}>
				<Fade in={this.props.editMode} duration={100}>
					<div className={styles.dragHandler}>
						<div className={styles.dragIcon} {...(this.props.dragProps || {})} tabIndex={-1}>
							<DragIcon/>
						</div>
					</div>
				</Fade>
				<div className={join(styles.innerBox, this.props.className)} ref={this.innerBoxRef}>
					<div className={styles.criteria}>
						<div className={styles.header}>
							{this.renderTitle()}
						</div>
						{this.renderBody()}
					</div>
				</div>
			</div>
			{this.renderValidation()}
			{this.renderRemoveDialog()}
		</>;
	}

	private renderTitle() {
		const onRemoveClick = () => this.props.hasRelatedData ? this.setState({showRemoveDialog: true}) : this.props.onRemoveClicked();
		return <div className={styles.title}>
			<span>Criteria {this.props.criteria.order}</span>
			<Fade in={this.props.editMode} duration={100}>
				<div>
					<Fade in={this.props.canRemove} duration={100}>
						<Buttons.Link onClick={onRemoveClick}
						              className={styles.remove}>
							<OnHoverTooltip message={`Remove Criteria ${this.props.criteria.order}`}>
								<span>remove</span>
							</OnHoverTooltip>
						</Buttons.Link>
					</Fade>
					{this.props.helpInfo && <OnHoverTooltip message={this.props.helpInfo}>
						<QuestionMarkIcon className={styles.questionMark}/>
					</OnHoverTooltip>}
					<OnHoverTooltip message='Clear text'>
						<div className={styles.clear}>
							<ClearIcon onClick={() => this.props.onChanged('')}/>
						</div>
					</OnHoverTooltip>
				</div>
			</Fade>
		</div>;
	}

	private renderBody(): ReactNode {
		if (this.props.editMode) {
			return <>
				<div className={styles.body}>
					<div className={styles.inputContainer} onClick={() => this.textAreaRef.current?.focus()}>
						<TextArea ref={this.textAreaRef}
						          className={styles.name}
						          maxLength={44}
						          cols={11}
						          rows={1}
						          tabIndex={1}
						          autoResizeToFit
						          hidePlaceholderOnFocus
						          autoFocus={this.getAutoFocus()}
						          value={this.props.criteria.name}
						          placeholder={this.props.placeholder}
						          onFocus={(e) => this.setState({focus: true}, () => this.props.onFocused(this.innerBoxRef.current))}
						          onBlur={() => this.setState({focus: false})}
						          onKeyPress={(e) => {
							          if (e.key === 'Enter') {
								          e.stopPropagation();
								          e.preventDefault();
								          return false;
							          }
						          }}
						          onChange={(v) => {
							          this.props.onChanged(v.target.value);
						          }}/>
					</div>
				</div>
				<div className={styles.counter}>
					<div className={join(!this.state.focus && styles.hidden)}>
						{this.props.criteria.name.length}/44
					</div>
				</div>
			</>;
		}

		return <div className={styles.body}>
			<div className={styles.textContainer}>
				<div className={styles.name}><span>{this.props.criteria.name}</span></div>
			</div>
		</div>;
	}

	private renderValidation(): ReactNode {
		if (this.state.showValidation && !this.props.criteria.name?.trim()) {
			return <RubricTooltip element={null}
			                      elementRef={this.innerBoxRef}
			                      placement='right'
			                      container={this.props.tooltipContainer}>
				Please enter a title for your criteria.
			</RubricTooltip>;
		}
	}

	private renderRemoveDialog(): ReactNode {
		if (this.state.showRemoveDialog) {
			return <RemoveDialog criteriaName={this.props.criteria.name || 'Criteria ' + this.props.criteria.order}
			                     onCancel={() => this.setState({showRemoveDialog: false})}
			                     onConfirm={() => this.setState({showRemoveDialog: false}, this.props.onRemoveClicked)}/>;
		}
	}

	private getAutoFocus(): boolean {
		// We avoid autofocus for iPad due to page scrolling issues. See ESGI-21401.
		if (this.isIPad) {
			return false;
		}

		//Recently added.
		if (!this.props.placeholder && !this.props.criteria.name) {
			return true;
		}

		//Always set focus to the first criteria by default.
		if (this.props.criteria.metaInfo?.defaultField && this.props.criteria.order === 1 && !this.props.criteria.name) {
			return true;
		}

		return false;
	}
}
