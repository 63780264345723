import {ValidationAlertType} from './types';

export const AlertMessages: {[key in ValidationAlertType]?: string} =  {
	[ValidationAlertType.SiteIsUnderMaintenance]: 'The site is under maintenance. Please try again later. Sorry for inconvenience.',
	[ValidationAlertType.PreAssessModeNotEnabled]: 'Pre-Assess mode is not currently active for your district. Please reach out to customer support at support@esgisoftware.com to request access.',
	[ValidationAlertType.PreAssessAccountNotActive]: 'Pre-Assess mode is not currently active for your district. Please reach out to customer support at support@esgisoftware.com to request access.',

	[ValidationAlertType.AccountIsDeactivated]: 'Your account has been deactivated. If you think it should be active, please contact us through our website, and we will work quickly to resolve the issue. Thank you, The ESGI Support Team',
	[ValidationAlertType.SchoolIsNotActive]: 'Sorry, your school is not currently active. Please contact customer support and mention error ESGI-15011.',
	[ValidationAlertType.DistrictIsNotActive]: 'Sorry, your district is not currently active. Please contact customer support and mention error ESGI-15011.',
	[ValidationAlertType.AccountIsBeingMoved]: 'Your account is being moved. You are unable to log in until this task has been completed. We apologize for the inconvenience.',
	[ValidationAlertType.NoConfiguredSchoolYear]: 'Your account does not have the current school year configured. Please contact support@esgisoftware.com for assistance (Error 8074).',
	[ValidationAlertType.NoActiveSchoolYear]: 'The current year is not active within your account. To reactivate your account, please contact your IT department or administrator. Thank you.',
	[ValidationAlertType.NoActiveSchoolYearDueToDistrictChanges]: 'The current year is not active within your account. To reactivate your account, please contact your IT department or administrator. Thank you.',

	[ValidationAlertType.TokenIsInvalidOrExpires]: 'Token invalid or expired',
	[ValidationAlertType.IncorrectUsername]: 'Incorrect username, please try again. If you can\'t remember your username, click the "Forgot Password" link, and enter the email address you used to signup for your account.',
	[ValidationAlertType.IncorrectPassword]: 'The password that was entered is incorrect. Please try again. If you forgot your password, please click the "Forgot Password" link.',
}