import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {CardBody} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ProgressWithPointer} from '../../../progress-with-pointer';
import {InfoWithTranscript} from '../../../info-with-transcript';
import {DateFormatText} from '../../../date-format-text';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	BaseComponentProps & {
		studentsCount: number;
		cardDate: {
			date: string | Date | null;
			dateTranscript: 'Posted' | 'Completed' | 'Created';
		};
		testsCount: number;
		/** percentage */
		result: number;
	};

export const AssignmentCardBody = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'assignments-assignment-card-body', css = {}, studentsCount, cardDate, testsCount, result, ...props},
		forwardedRef,
	) => {
		return (
			<CardBody dataCy={dataCy} css={css} {...props} ref={forwardedRef}>
				<ProgressWithPointer maxValue={100} value={result}>
					<Text size='small' font='mono' color='neutral56'>
						Completed
					</Text>
				</ProgressWithPointer>

				<GridBox display='inlineGrid' flow='column' align='center' gap='8'>
					<InfoWithTranscript transcript='Tests' dataCy='assignments-info-tests'>
						<Text size='medium' font='mono' bold data-cy='info-value'>
							{testsCount}
						</Text>
					</InfoWithTranscript>
					<InfoWithTranscript transcript='Students' dataCy='assignments-info-students'>
						<Text size='medium' font='mono' bold data-cy='info-value'>
							{studentsCount}
						</Text>
					</InfoWithTranscript>
					{cardDate.date && (
						<InfoWithTranscript transcript={cardDate.dateTranscript} dataCy='assignments-info-date'>
							<DateFormatText size='medium' font='mono' bold dateFormat='MM/DD/YY' date={cardDate.date} data-cy='info-value' />
						</InfoWithTranscript>
					)}
				</GridBox>
			</CardBody>
		);
	},
);
