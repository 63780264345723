import {useCallback, useState, useMemo, useEffect} from 'react';
import {useSelectedState} from '@esgi/main/libs/core';
import {useBehaviorSubject, useStream} from '@esgi/ui/utils';
import {TestType} from '@esgi/contracts/esgi/types/esgi.enums/test-type';
import {FlashcardsService, Test} from '@esgi/main/features/teacher/flashcards';
import {teacherSelectedValuesStorage} from '../../selected-state-storage/teacher';
import {testTypeMap} from './constants';

export function useFlashcardsState(service: FlashcardsService) {
	const [selectedState] = useSelectedState(teacherSelectedValuesStorage);

	const [preview, setPreview] = useState(false);
	const [showAddTestsDrawer, setShowAddTestsDrawer] = useState(false);
	const valid = useStream(service.valid$, false);
	const busy = useStream(service.busy$, false);
	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const studentList = useBehaviorSubject(service.studentService.students$);
	const loadedStudents = useBehaviorSubject(service.studentService.loaded$);
	const totalPages = useStream(service.totalPages$, 0);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const loadedSubjects = useBehaviorSubject(service.subjectService.loaded$);
	const students = useBehaviorSubject(service.students$);
	const subject = useBehaviorSubject(service.subject$);
	const results = useBehaviorSubject(service.results$);
	const tests = useBehaviorSubject(service.tests$);

	const onDownload = useCallback(() => {
		service.onDownload(results.reportGuid);
	}, [results]);

	const subjectsWithYN = useMemo(() => subjects.filter(
		({tests}) => tests.some(({type}) => testTypeMap[type] === TestType.YN)
	), [subjects]);

	const otherSubjectTests: Test[] = useMemo(() => {
		const {tests: subjectTests = []} = subjects.find(({id}) => subject === id) || {};
		const testIds = tests.map(({id}) => id);
		return subjectTests
			.filter(({id, type}) => {
				if (testTypeMap[type] !== TestType.YN) {
					return false;
				}
				return !testIds.includes(id);
			})
			// This is a converter from Test in the subject list to Test required by the control.
			.map(({id, name, type}) => ({
				id,
				name,
				type,
				questionsCount: 0,
				orderNum: 0,
				studentSessionResults: [],
				sessions: [],
				isEmpty: true,
			}));
	}, [tests, subjects, subject]);

	useEffect(() => {
		service.init(selectedState?.values?.subjectId);
	}, [service, selectedState?.values?.subjectId]);

	return {
		preview,
		setPreview,
		showAddTestsDrawer,
		setShowAddTestsDrawer,
		valid,
		busy,
		classes,
		groups,
		studentList,
		loadedStudents,
		totalPages,
		subjects: subjectsWithYN,
		loadedSubjects,
		students,
		subject,
		otherSubjectTests,
		onDownload,
	};
}
