import React from 'react';
import styles from './styles.module.less';

function VectorIcon() {
	return <img className={styles.vector} src='//s3.amazonaws.com/esgiwebfiles/Images/sa-assignment-tutorial/vector.png'/>;
}

export function IconsRow() {
	return <div className={styles.iconsContainer}>
		<img className={styles.tests} src='//s3.amazonaws.com/esgiwebfiles/Images/sa-assignment-tutorial/tests.png'/>
		<VectorIcon />
		<img className={styles.students} src='//s3.amazonaws.com/esgiwebfiles/Images/sa-assignment-tutorial/students.png'/>
		<VectorIcon />
		<img className={styles.tests} src='//s3.amazonaws.com/esgiwebfiles/Images/sa-assignment-tutorial/preview.png'/>
		<VectorIcon />
		<img className={styles.cardTemplate} src='//s3.amazonaws.com/esgiwebfiles/Images/sa-assignment-tutorial/cardtemplate.png'/>
	</div>;
}
