import {useEffect} from 'react';
import {IEPGoalFormService} from '../../service';
import {Form, ElementStatus} from '@esgillc/ui-kit/form';
import {Row, Column} from '../components';
import {
	IEPGoalField,
	BenchmarkField,
	StatusDropdown,
	MarkAsCompletedField,
} from '../fields';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {IEPDatesButton} from '../buttons/iep-dates-btn';
import {Label} from '@esgillc/ui-kit/form-control';
import styles from '../styles.module.less';

type Props = {
	service: IEPGoalFormService;
	editMode: boolean;
}

export function EditContainer({service, editMode}: Props): JSX.Element {
	const statuses = useBehaviorSubject(service.iepStatuses$);

	const toggleFields = (value: boolean) => {
		Object.keys(service.form.controls)
			.filter((control) => control !== 'isCompleted')
			.forEach((control) => {
				service.form.controls[control].status = value
					? ElementStatus.disabled
					: ElementStatus.untouched;
			});
	};

	useEffect(() => {
		const sub = service.form.controls.isCompleted.onChanged.subscribe(
			({reason, currState: {value}}) => {
				if (reason === 'value') {
					toggleFields(value);
				}
			},
		);
		return () => sub.unsubscribe();
	}, []);

	return (
		<Form
			className={styles.form}
			controller={service.form}
		>
			<Row className={styles.studentRow}>
				<Column className={styles.columnRow}>
					<Label className={styles.label}>&nbsp;</Label>
					<div className={styles.field}>
						<IEPDatesButton service={service}/>
					</div>
				</Column>
			</Row>
			<Row>
				<Column>
					<IEPGoalField
						control={service.form.controls.goal}
						editMode={editMode}
					/>
					<StatusDropdown
						control={service.form.controls.status}
						statuses={statuses}
						editMode={editMode}
					/>
					<BenchmarkField
						benchmarkControl={service.form.controls.benchmark}
						statusControl={service.form.controls.status}
						editMode={editMode}
					/>
					<MarkAsCompletedField control={service.form.controls.isCompleted}/>
				</Column>
			</Row>
		</Form>
	);
}
