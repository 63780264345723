import {Observable, Subject} from 'rxjs';
import {isFunction} from 'underscore';

type Store = IDBIndex | IDBObjectStore;

export function updateValue<T>(store: Store, key: string | number, value: T): Observable<void>
export function updateValue<T>(store: Store, key: (value: T) => boolean, value: T): Observable<void>
export function updateValue<T>(store: Store, key: any, value: T): Observable<void> {
	const subject = new Subject<void>();
	const cursor = store.openCursor();

	cursor.onsuccess = (event: any) => {
		const cursor = event.target.result as IDBCursorWithValue;
		if (cursor) {
			let canUpdate = false;
			if (isFunction(key) && key(cursor.value)) {
				canUpdate = true;
			} else if (cursor.key === key) {
				canUpdate = true;
			}

			if (canUpdate) {
				const request = cursor.update(value);
				request.onsuccess = () => {
					subject.next();
					subject.complete();
				};
				return;
			}
			cursor.continue();
		}
	};

	cursor.onerror = (event: any) => subject.error(event);

	return subject.asObservable();
}

export function executeRequest<T>(request: IDBRequest<T>): Observable<T> {
	const subject = new Subject<T>();

	request.onsuccess = (event) => {
		subject.next(request.result);
		subject.complete();
	};
	request.onerror = (event) => {
		subject.error(event);
	};

	return subject.asObservable();
}
