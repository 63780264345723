import {InCorrectAnswer} from '@esgi/ui/icons';
import {IconBox} from './index.styled';

export function IncorrectAnswerIcon() {
	return (
		<IconBox dataCy='incorrect-answer-icon'>
			<InCorrectAnswer />
		</IconBox>
	);
}
