import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
	value: number;
	maxValue: number;
};

export function SelectedTestsInfo({value, maxValue}: Props) {
	return (
		<GridBox flow='column' align='center' gap={4}>
			<FlexBox align={value ? 'end' : 'center'} dataCy='selected-tests-value'>
				<Text size='medium' bold font='mono' color='primary'>
					{value}
				</Text>
				<Text size='xSmall' font='mono' color='lowContrast'>
					{' '}
					/{maxValue}
				</Text>
			</FlexBox>
			<Text size='xSmall' font='mono' color='lowContrast' data-cy='selected-tests-label'>
				Tests Selected
			</Text>
		</GridBox>
	);
}
