import {useEffect, useState} from 'react';
import {HttpEventBusManager} from '@esgi/api';
import {dispatchAppEvent} from '@esgillc/events';
import {FrontendNewVersionAvailableEvent, LoadScriptFailedEvent} from '@esgi/core/react';

export function useVersionCompatibility() {
	const [outdatedCatch, setOutdatedCatch] = useState(false);
	const [incompatibleCatch, setIncompatibleCatch] = useState(false);

	useEffect(() => {
		const httpEventBus = new HttpEventBusManager();

		httpEventBus.subscribe(response => {
			if (response.headers['esgi-frontend-outdated'] && !outdatedCatch) {
				dispatchAppEvent(FrontendNewVersionAvailableEvent);
				setOutdatedCatch(true);
			}

			if (response.headers['esgi-frontend-incompatible'] && !incompatibleCatch) {
				dispatchAppEvent(LoadScriptFailedEvent);
				setIncompatibleCatch(true);
			}
		});

		return () => httpEventBus.destroy();
	}, [outdatedCatch, incompatibleCatch, setOutdatedCatch, setIncompatibleCatch]);
}