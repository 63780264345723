import {DisabledInput} from '../../components/disabled-input';
import {Drawer} from '@esgi/main/kits/common';
import {PanelContent} from '../../components/panels.styled';
import {ContentBox} from '../../components/content-box.styled';
import {LinkedToDistrictInfo} from '../../components/linked-to-district-info';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props = {
	districtName: string;
	isLinkedToDistrict: boolean;
	schoolName: string;
};

export function SchoolAndDistrictContent({districtName, isLinkedToDistrict, schoolName}: Props) {
	return (
		<>
			<Drawer.PanelHeader>
				<PanelHeaderTitle title='School & District' additionalText='Main Account' />
			</Drawer.PanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox gap='60'>
						<Drawer.ContentBlock title='District'>
							<DisabledInput value={districtName} placeholder='District' />
							<LinkedToDistrictInfo isLinkedToDistrict={isLinkedToDistrict} />
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='School'>
							<DisabledInput value={schoolName} placeholder='School' />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
