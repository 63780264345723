import {ReportHeaderField} from '../report-header-field';
import {ReportSelect} from '../../../../../../../report-select';
import {ReportSelectOption} from '../../../../../../../../../../types/table-level-report-service-types';

type ReportEntityDropdownProps = {
	entityGroup?: ReportSelectOption[];
	title: string;
	groupID: number;
	onSelected: (entity: number) => void;
	showAlternativeHeader?: boolean;
	validateOn: 'length' | 'existGroup';
};

export function ReportEntityDropdown({
	entityGroup,
	title,
	groupID,
	onSelected,
	showAlternativeHeader = false,
	validateOn,
}: ReportEntityDropdownProps) {
	const selectedGroup = entityGroup?.find(({id}) => id === groupID);

	if ((validateOn === 'length' && entityGroup?.length > 1) || (validateOn === 'existGroup' && selectedGroup)) {
		return (
			<ReportHeaderField>
				<ReportSelect label={title} options={entityGroup} onValueChange={(value) => onSelected(Number(value))} value={groupID.toString()}/>
			</ReportHeaderField>
		);
	}

	if (showAlternativeHeader) {
		return <ReportHeaderField title={title} value={selectedGroup?.name} />;
	}

	return null;
}
