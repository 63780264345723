import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type TriggerWithStyledButtonValue = {
	exist: boolean;
	hovered: boolean;
};

export type SelectableListContextValue = {
	/** When true, list is expandable. True, if Trigger Button is render */
	isExpandableList: boolean;

	/** Set true / false value for expandable list. */
	setIsExpandableList: (value: boolean) => void;

	/** Use for openning list. When true, selectable list is open. */
	isListOpen: boolean;

	/** Set true / false value for open / close selectable list. */
	setIsListOpen: (value: boolean) => void;

	/** Set true / false value if SelectableListStyledButton is used in SelectableListTrigger component as children part. */
	triggerWithStyledButton: TriggerWithStyledButtonValue;

	/** Set triggerWithStyledButton state. */
	setTriggerWithStyledButton: (value: TriggerWithStyledButtonValue) => void;

	/** Value for tracking loading element. */
	isLoaded: boolean;
};

export const SelectableListContext = createContext<SelectableListContextValue>({
	isExpandableList: false,
	setIsExpandableList: noop,
	isListOpen: false,
	setIsListOpen: noop,
	triggerWithStyledButton: {
		exist: false,
		hovered: false,
	},
	setTriggerWithStyledButton: noop,
	isLoaded: false,
});

export function useSelectableListContext() {
	return useContext(SelectableListContext);
}
