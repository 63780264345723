import {styled} from '@esgi/ui/theme';

export const SelectedItemIndicator = styled('div', {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',

	'& svg': {
		width: '24px',
		height: '24px',
		marginRight: '6px',
		'& path': {
			fill: '$lowContrast',
		},
	},

	'& span': {
		color: '$lowContrast',
		whiteSpace: 'nowrap',
		display: 'flex',
		alignContent: 'center',
	},
});
