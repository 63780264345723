import {join} from '@esgillc/ui-kit/utils';
import {AssignmentInfo} from '../../../../services/types';
import {assignmentDatesLabels, AssignmentStatus, assignmentStatusLabels} from '../../../../typings/assignment';
import styles from './styles.module.less';
import moment from 'moment';

interface Props {
	onClick: VoidFunction;
	assignment: AssignmentInfo;
}

export function AssignmentTile({assignment, onClick}: Props) {
	const {name, code, state, posted, created, completed} = assignment;
	const date = completed || posted || created;

	return (
		<div className={styles.container} onClick={onClick}>
			<div>
				<div className={styles.title}>{name}</div>
				{date &&
					<div className={styles.description}>
					{state !== AssignmentStatus.Draft &&
						<div className={styles.posted}>
							{assignmentDatesLabels[state]}: {moment(date).format('MM/DD/YYYY')}
						</div>
					}
					{code && <span className={styles.posted}>&nbsp;| Code: {code}</span>}
				</div>
				}
			</div>
			<div className={join(styles[state], styles.status)}>
				{assignmentStatusLabels[state]}
			</div>
		</div>
	);
}
