import {GradeLevelModel, SchoolModel, StudentModel} from '../../../types';
import {StudentInfo} from '../types';
import {useMemo} from 'react';
import {UserType, useUser} from '@esgi/core/authentication';
import moment from 'moment';


export function useStudentsInfo(students: StudentModel[], schools: SchoolModel[], gradeLevels: GradeLevelModel[]): StudentInfo[] {
	const user = useUser();
	return useMemo(() => {
		return students.map<StudentInfo>((student) => {
			const school = schools.find(sch => sch.schoolID === student.schoolID);
			const grade = gradeLevels.find(g => g.gradeLevelID === student.gradeLevelID);
			const teacher = school?.teachers.find(t => t.teacherID === (student.primaryTeacherID)) ?? null;
			const classesList = school?.teachers?.flatMap(({classes}) => classes);
			const classes = (classesList || []).filter(c => student.classIDs.includes(c.classID));
			const groups = classes.map(c => c.groups).flat().filter(g => student.groupIDs.includes(g.groupID));
			const createDate = moment(student.createDate).format('MM/DD/YY');

			const notLinkedToDistrict = user.agreementLevelCode === 'T';
			const primaryTeacherID = student.primaryTeacherID;
			const isCanBeChecked = notLinkedToDistrict ||
				(user.userType === UserType.T ? (primaryTeacherID === null || user.userID === primaryTeacherID) && user.canViewStudents : user.canViewStudents);

			return {
				model: student,
				name: `${student.firstName} ${student.lastName}`,
				school,
				grade,
				classes,
				groups,
				teacher: classes?.length ? teacher : null,
				readonly: !isCanBeChecked,
				createDate,
				id: !student.studentIDN ? '' : student.studentIDN,
			};
		});

	}, [students, schools, gradeLevels, user]);
}
