import {LogoModel} from '../types';

const maxFileSize = 5120000;
export const fileTypes = ['.bmp', '.jpg', '.jpeg', '.png'];

export const addFile = (
	newFiles: FileList,
	onChanged: (logo: LogoModel | null) => void,
) => {

	const file = Array.from(newFiles)[0];
	if (file.size > maxFileSize) {
		return;
	}

	const reader = new FileReader();

	reader.onload = () => {
		const readerResult = reader.result as string;
		const img = new Image();

		img.onload = () => {
			const base64Result = readerResult.split(',')[1];
			onChanged({
				base64: base64Result,
				contentType: file.type,
				width: img.width,
				height: img.height,
			});
		};

		img.src = readerResult;
	};

	reader.readAsDataURL(file);
};
