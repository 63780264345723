import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EmptyDataContainer = styled(FlexBox, {
	height: 94,
	width: '100%',
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 12,
	maxWidth: 680,
	justifyContent: 'center',
	alignItems: 'center',
});

export const Container = styled(GridBox, {
	width: '100%',
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 12,
	maxWidth: 680,
	gap: '$4',
});

export const Divider = styled(GridBox, {
	height: 1,
	borderRadius: 1,
	backgroundColor: '$vivid',
});

export const TableBody = styled(GridBox, {
	gap: '$4',
});

export const TableRow = styled(GridBox, {
	gridTemplateColumns: '80px repeat(auto-fit, 64px)',
	justifyContent: 'center',
	alignItems: 'center',
	gap: 20,
	textAlign: 'center',
	'& span:first-child': {
		textAlign: 'left',
	},
	variants: {
		isTablet: {
			true: {
				justifyContent: 'start',
			},
		},
	},
});

export const IndicatorBox = styled(Box, {
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 16,
	height: 16,
	borderRadius: 4,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
});
