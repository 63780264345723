import {Dispatch, useCallback} from 'react';
import {useValue} from '../../../hooks';
import {Student} from '@esgi/main/libs/store';

export function useSelections(onChange: Dispatch<Student['id'][]>) {
	const [selectedStudentIDs, setSelectedStudentIDs] = useValue<Student['id'][]>([], onChange);

	const toggleStudent = useCallback(
		(studentId: Student['id']) => {
			if (selectedStudentIDs.includes(studentId)) {
				setSelectedStudentIDs(selectedStudentIDs.filter((id) => id !== studentId));
			} else {
				setSelectedStudentIDs([...selectedStudentIDs, studentId]);
			}
		},
		[selectedStudentIDs, setSelectedStudentIDs],
	);

	return {
		selectedStudentIDs,
		setSelectedStudentIDs,
		toggleStudent,
	};
}
