/* eslint-disable @typescript-eslint/member-ordering */
import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {HierarchyMode, HierarchySnapshot} from '../../../hierarchy/core/models';
import {FiltersService} from './filters-service';
import {
	DownloadData,
	DownloadType,
	HeaderInfo,
	InitResponse,
	LevelModel,
	ReportErrors,
	ReportResponse,
	SelectedEntitiesData,
	SubjectEntity,
	SortDirection,
	SubjectType,
	SortByCriteriaResultsOptions,
	SortByType,
	StudentResult,
	SortableKeys,
	StudentSort,
	sortDirectionMap,
} from '../types/table-level-report-service-types';
import {StudentLevelReportModel} from '../types/student-level-report-service-types';
import {filter, finalize, map} from 'rxjs/operators';
import {isEmpty, isNaN, isNull} from 'underscore';
import moment from 'moment';
import {deepCopy} from 'shared/utils';
import {ReportService} from '../../utils/report-service';

export class TableLevelReportService extends BaseService {
	private readonly controller = 'reports/rubric-results/table-level';

	private sessionGuid: string | undefined;
	private trackID: number | undefined;
	private subjectID: number | undefined;
	private subjectType: SubjectType | undefined;

	private readonly report$ = new BehaviorSubject<ReportResponse | null>(null);

	public filter$ = this.filterService.filter$;

	private readonly headerInfo = new BehaviorSubject<HeaderInfo | null>(null);

	public headerInfo$ = this.completeOnDestroy(this.headerInfo).pipe(filter((f) => !isNull(f)));

	private readonly selectedEntitiesData = new BehaviorSubject<SelectedEntitiesData | null>(null);

	public selectedEntitiesData$ = this.completeOnDestroy(this.selectedEntitiesData).pipe(filter((f) => !isNull(f)));

	private readonly selectedSubject = new BehaviorSubject<SubjectEntity | null>(null);

	public selectedSubject$ = this.completeOnDestroy(this.selectedSubject).pipe(filter((f) => !isNull(f)));

	private readonly reportErrors = new Subject<ReportErrors | null>();

	public reportErrors$ = this.completeOnDestroy(this.reportErrors);

	private readonly loading = new Subject<boolean>();
	public loading$ = this.completeOnDestroy(this.loading);

	private readonly downloadType = new BehaviorSubject<DownloadType | null>(null);
	private downloadType$ = this.completeOnDestroy(this.downloadType);

	private readonly studentLevelReportStudentID = new BehaviorSubject<number | null>(null);

	private initialSortKey: SortableKeys = 'firstName';

	private readonly sortByCriteriaResultsOptions$ = new BehaviorSubject<SortByCriteriaResultsOptions | null>(null);

	public sortByCriteriaResultsOptionsObserver$ = this.completeOnDestroy(this.sortByCriteriaResultsOptions$);

	public readonly reportService: ReportService<StudentResult> = new ReportService();

	constructor(private hierarchy: HierarchySnapshot, private readonly filterService: FiltersService) {
		super();

		this.completeOnDestroy(this.selectedEntitiesData).subscribe((selectedEntitiesData) => {
			if (isNull(selectedEntitiesData)) {
				return;
			}

			this.setLoading(true);

			const {selectedRubricID, selectedTeacherClassID, selectedTeacherGroupID, selectedSpecialistGroupID} =
				selectedEntitiesData;

			this.httpClient.ESGIApi.get<ReportResponse>(this.controller, 'build-report', {
				rubricID: selectedRubricID,
				trackID: this.trackID,
				teacherClassID: selectedTeacherClassID,
				teacherGroupID: selectedTeacherGroupID,
				specialistGroupID: selectedSpecialistGroupID,
				sessionGuid: this.sessionGuid,
			})
				.withCustomErrorHandler(() => this.setLoading(false))
				.subscribe((response) => {
					this.setLoading(false);
					this.reportErrors.next(null);

					if (response.noStudents) {
						this.report$.next(null);
						this.reportErrors.next({noStudents: true});
						return [];
					}

					this.report$.next(response);
					this.reportService.setSortModel({
						fieldName: this.initialSortKey,
						direction: sortDirectionMap[SortDirection.Asc],
					});
				});
		});

		this.completeOnDestroy(
			combineLatest([this.downloadType$, this.downloadData$, this.selectedEntitiesData]),
		).subscribe(([downloadType, downloadData, selectedEntitiesData]) => {
			if (isNull(downloadType) || isNull(downloadData) || isNull(selectedEntitiesData)) {
				return;
			}

			this.setLoading(true);

			const filename = this.buildDownloadFileName();
			const {subjectID, subjectType} = this.selectedSubject.value;
			const {
				selectedRubricID: rubricID,
				selectedTeacherClassID: teacherClassID,
				selectedTeacherGroupID: teacherGroupID,
				selectedSpecialistGroupID: specialistGroupID,
			} = selectedEntitiesData;
			const {rows} = this.reportService.optionsToExport.value;

			const downloadRequest = {
				...downloadData,
				rows,
				filter: this.filterService.getFilter(),
				sessionGuid: this.sessionGuid,
				subjectID,
				subjectType,
				rubricID,
				teacherClassID,
				teacherGroupID,
				specialistGroupID,
				filename,
			};

			const fileActions: Record<DownloadType, string> = {
				[DownloadType.Excel]: 'export/excel',
				[DownloadType.PDF]: 'export/pdf',
			};

			const fileAction = fileActions[downloadType];

			if (fileAction) {
				this.httpClient.ESGIApi.file(this.controller, fileAction, filename, downloadRequest)
					.pipe(finalize(() => this.setLoading(false)))
					.subscribe();
			}
		});

		this.completeOnDestroy(
			combineLatest([this.report$, this.filter$]),
		).subscribe(([report, filter]) => {
			if (isNull(report) || isNull(filter)) {
				return;
			}

			const rows = deepCopy<StudentResult[]>(report.results);

			if (!rows.length) {
				return;
			}

			this.filterService.applyFilters(rows, report.currentPeriod);
			this.reportService.setData(rows);
		});
	}

	public levels$: Observable<LevelModel[]> = this.completeOnDestroy(this.report$).pipe(
		map((report) => (isNull(report) ? [] : report.levels)),
	);

	private downloadData$: Observable<DownloadData | null> = this.completeOnDestroy(
		combineLatest([this.headerInfo, this.selectedEntitiesData]),
	).pipe(
		map(([headerInfo, selectedEntitiesData]) => {
			if (isNull(headerInfo) || isNull(selectedEntitiesData)) {
				return null;
			}

			const {selectedRubricID, selectedTeacherClassID, selectedTeacherGroupID, selectedSpecialistGroupID} =
				selectedEntitiesData;
			const {teacherClasses, teacherGroups, specialistGroups, userName} = headerInfo;

			const rubric = headerInfo.rubrics.find(({id}) => id === selectedRubricID);

			let entityName: string | undefined;
			let entityType: string | undefined;

			if (selectedTeacherClassID > 0) {
				const teacherClass = teacherClasses.find(({id}) => id === selectedTeacherClassID);

				entityName = teacherClass?.value;
				entityType = 'Class';
			}

			if (selectedTeacherGroupID > 0) {
				const teacherGroup = teacherGroups.find(({id}) => id === selectedTeacherGroupID);

				entityName = teacherGroup?.value;
				entityType = 'Group';
			}

			if (selectedSpecialistGroupID > 0) {
				const specialistGroup = specialistGroups.find(({id}) => id === selectedSpecialistGroupID);

				entityName = specialistGroup?.value;
				entityType = 'SpecialistGroup';
			}

			if (!entityName || !entityType || !rubric) {
				return null;
			}

			const downloadData: DownloadData = {
				rubricName: rubric.value,
				schoolEntityName: entityName,
				schoolEntityType: entityType,
				userName,
			};

			return downloadData;
		}),
	);

	public studentLevelReportData$: Observable<StudentLevelReportModel | null> = this.completeOnDestroy(
		combineLatest([
			this.studentLevelReportStudentID,
			this.report$,
			this.downloadData$,
			this.reportService.sortModel,
			this.selectedEntitiesData,
			this.selectedSubject,
		]),
	).pipe(
		map(
			([
				selectedStudentID,
				report,
				downloadData,
				sortModel,
				selectedEntitiesData,
				selectedSubject,
			]) => {
				if (
					isNull(selectedStudentID) ||
					isNull(report) ||
					isNull(downloadData) ||
					isNull(sortModel) ||
					isNull(selectedEntitiesData) ||
					isNull(selectedSubject)
				) {
					return null;
				}

				if (!this.trackID) {
					throw new Error('trackID is undefined');
				}

				if (!this.sessionGuid) {
					throw new Error('sessionGuid is undefined');
				}

				this.studentLevelReportStudentID.next(null);

				const results: StudentResult[] = deepCopy(report.results).filter(({hasResult}) => hasResult);
				const {levels, currentPeriod} = report;
				const {
					selectedRubricID: rubricID,
					selectedTeacherClassID: teacherClassID,
					selectedTeacherGroupID: teacherGroupID,
					selectedSpecialistGroupID: specialistGroupID,
				} = selectedEntitiesData;
				const {subjectID, subjectType} = selectedSubject;
				const maxLevel = levels.some(({score}) => score === 0) ? levels.length - 1 : levels.length;
				const sortBy = {
					sortKey: sortModel.fieldName as SortableKeys,
					direction: SortDirection[sortModel.direction] as SortDirection,
				};

				const studentLevelReportModel: StudentLevelReportModel = {
					trackID: this.trackID,
					currentPeriod,
					selectedStudentID,
					results,
					maxLevel,
					rubricID,
					teacherClassID,
					teacherGroupID,
					specialistGroupID,
					filter: this.filterService.getFilter(),
					sortBy,
					sessionGuid: this.sessionGuid,
					subjectID,
					subjectType,
					downloadData,
				};

				return studentLevelReportModel;
			},
		),
	);

	public init(subjectID: number, subjectType: SubjectType) {
		this.subjectID = subjectID;
		this.subjectType = subjectType;

		const hierarchy = this.hierarchy;
		this.setLoading(true);

		this.httpClient.ESGIApi.get<InitResponse>(this.controller, 'init', {
			subjectID,
			subjectType,
			hierarchy,
		})
			.withCustomErrorHandler(() => this.setLoading(false))
			.subscribe(
				({
					invalidTrack,
					schoolYearName,
					sessionGuid,
					trackID,
					rubrics,
					teacherClasses,
					teacherGroups,
					specialistGroups,
					userName,
					subjects,
					filter,
					sortBy,
				}) => {
					if (invalidTrack) {
						this.setLoading(false);
						this.report$.next(null);
						this.reportErrors.next({
							invalidTrack: true,
							schoolYearName,
						});

						return;
					}

					this.sessionGuid = sessionGuid;
					this.trackID = trackID;

					this.headerInfo.next({
						rubrics,
						teacherClasses,
						teacherGroups,
						specialistGroups,
						userName,
						subjects,
					});

					const selectedSubject = subjects.find(({subjectID}) => subjectID === this.subjectID);

					this.selectedSubject.next(selectedSubject ?? null);

					if (isEmpty(rubrics)) {
						this.setLoading(false);
						this.report$.next(null);
						this.reportErrors.next({noRubrics: true});

						return;
					}

					this.filterService.setFilter(filter);

					const direction = sortDirectionMap[SortDirection.Asc];
					const {id: selectedRubricID} = rubrics[0]!;

					if (sortBy === StudentSort.FirstName) {
						this.initialSortKey = 'firstName';
					}

					if (sortBy === StudentSort.LastName) {
						this.initialSortKey = 'lastName';
					}

					this.reportService.setSortModel({
						fieldName: this.initialSortKey,
						direction,
					});

					const {
						classID: selectedTeacherClassID,
						groupID: selectedTeacherGroupID,
					} = hierarchy.classic;
					let selectedSpecialistGroupID = hierarchy.specialist.groupID;

					if (hierarchy.mode === HierarchyMode.PreAssess) {
						selectedSpecialistGroupID = hierarchy.preAssess.groupID;
					}

					this.selectedEntitiesData.next({
						selectedTeacherClassID,
						selectedTeacherGroupID,
						selectedSpecialistGroupID,
						selectedRubricID,
					});
				},
			);
	}

	private selectEntityData(dataKey: keyof SelectedEntitiesData, value: number) {
		const selectedValue = this.selectedEntitiesData.value;

		if (isNull(selectedValue)) {
			this.selectedEntitiesData.next(null);

			return;
		}

		selectedValue[dataKey] = value;

		this.selectedEntitiesData.next({...selectedValue});
	}

	public selectClass(classID: number) {
		this.selectEntityData('selectedTeacherClassID', classID);
	}

	public selectGroup(groupID: number) {
		this.selectEntityData('selectedTeacherGroupID', groupID);
	}

	public selectSpecialistGroup(selectedSpecialistGroupID: number) {
		this.selectEntityData('selectedSpecialistGroupID', selectedSpecialistGroupID);
	}

	public selectRubric(rubricID: number) {
		this.selectEntityData('selectedRubricID', rubricID);
	}

	public selectSubject(selectedSubjectID: number) {
		const selectedSubject = this.headerInfo.value?.subjects.find(({subjectID}) => subjectID === selectedSubjectID);

		if (!selectedSubject) {
			throw new Error('selectedSubject is undefined');
		}

		this.init(selectedSubject?.subjectID, selectedSubject?.subjectType);
	}

	public downloadPDF() {
		this.downloadType.next(DownloadType.PDF);
		this.downloadType.next(null);
	}

	public downloadExcel() {
		this.downloadType.next(DownloadType.Excel);
		this.downloadType.next(null);
	}

	public openStudentLevelReport(studentID: number) {
		this.studentLevelReportStudentID.next(studentID);
	}

	public setShowBaseLineFilter(showBaseline: boolean) {
		this.filterService.setShowBaseLineFilter(showBaseline);
	}

	public setCarryForwardFilter(carryScoresForward: boolean) {
		this.filterService.setCarryForwardFilter(carryScoresForward);
	}

	public setMarkingPeriodFilter(showCurrentMarkingPeriod: boolean) {
		const report = this.report$.value;
		const sortBy = this.reportService.sortModel.value;
		const sortByCriteriaResultsOptions = this.sortByCriteriaResultsOptions$.value;

		if (
			showCurrentMarkingPeriod &&
			sortBy.fieldName === 'criteriaResults' &&
			sortByCriteriaResultsOptions &&
			report?.results?.length
		) {
			const {criteriaName, periodResultName} = sortByCriteriaResultsOptions;

			const {criteriaResults} = report.results[0]!;

			const selectedCriteriaResult = criteriaResults.find(
				(criteriaResult) => criteriaName === criteriaResult.criteriaName,
			);

			if (selectedCriteriaResult?.periodResults?.length) {
				const {periodName: currentPeriodName} = selectedCriteriaResult.periodResults.at(-1)!;

				if (currentPeriodName !== periodResultName) {
					this.sortByCriteriaResultsOptions$.next({
						criteriaName,
						periodResultName: currentPeriodName,
					});
					this.sortByCriteriaResultsOptions$.next(null);

					this.reportService.setSortModel({
						fieldName: this.initialSortKey,
						direction: sortDirectionMap[SortDirection.Asc],
					});
				}
			}
		}

		this.filterService.setMarkingPeriodFilter(showCurrentMarkingPeriod);
	}

	public setDisplayNotTestedAsFilter(displayZeroIfNotTested: boolean) {
		this.filterService.setDisplayNotTestedAsFilter(displayZeroIfNotTested);
	}

	private setLoading(loading: boolean) {
		this.loading.next(loading);
	}

	private buildDownloadFileName() {
		const date = moment().format('YYYY_M_D');

		return `Rubric_Results_Class_${date}`;
	}

	public toggleSortDirection(
		sortByType: SortByType,
		newDirection: SortDirection,
	) {
		if (sortByType.sortKey === 'criteriaResults') {
			const {criteriaName, periodResultName} = sortByType;
			this.sortByCriteriaResultsOptions$.next({
				criteriaName,
				periodResultName,
			});
		}

		const {sortKey: fieldName} = sortByType;
		this.reportService.setSortModel({
			fieldName,
			fieldValue: fieldName === 'criteriaResults'
				? this.rowValueByCriteriaResult.bind(this)
				: null,
			direction: sortDirectionMap[newDirection],
		});
	}

	private rowValueByCriteriaResult(row: StudentResult) {
		const sortByCriteriaResultsOptions = this.sortByCriteriaResultsOptions$.value;

		if (isNull(sortByCriteriaResultsOptions)) {
			return 0;
		}

		const {criteriaName, periodResultName} = sortByCriteriaResultsOptions;

		const criteriaResult = row.criteriaResults.find((criteriaResults) => criteriaResults.criteriaName === criteriaName);

		if (!criteriaResult) {
			return 0;
		}

		const periodResult = criteriaResult.periodResults.find(({periodName}) => periodName === periodResultName);

		const value = periodResult?.value;

		if (!value) {
			return 0;
		}

		const parsedValue = parseInt(value);

		return value === 'B' || isNaN(parsedValue) ? 0 : parsedValue;
	}

	public override destroy() {
		this.reportService.destroy();
		this.filterService.destroy();
		super.destroy();
	}
}
