import {ClassType, GroupType, StudentType} from '../../types';

export type GroupItem = Omit<GroupType, 'studentIDs'> & {
	students: StudentType[];
};

export type GroupState = GroupItem & {
	isListOpen: boolean;
};

export enum AllOptionItem {
	Value = 'all',
	Label = 'All Classes',
}

export type OptionType = ClassType['id'] | AllOptionItem.Value;
