import {ErrorHandler, HandlingStrategy} from '@esgi/api';
import {dispatchAppEvent} from '@esgillc/events';
import {Loader} from '@esgi/deprecated/jquery';
import {ShowNotAuthorizedAlertEvent} from '../../layers/not-authorized-layer/events';

export default class NotAuthorizedHandler extends ErrorHandler {
	public next<T>(response: XMLHttpRequest, strategy: HandlingStrategy): void {
		if (!response) {
			return;
		}

		if (response.status === 401) {
			Loader.UnmaskAll();

			dispatchAppEvent(ShowNotAuthorizedAlertEvent);
			return;
		}
	}

}
