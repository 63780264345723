import React from 'react';
import {Radio} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import {Observable, Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Loader} from '@esgi/deprecated/jquery';
import {
	AutoDimension,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	useState,
} from '@esgi/deprecated/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {AssignType, SchoolModel, SpecialistModel} from '../../../../../../../models/models';
import AssignService from '../assign-service';
import {MoveStudentModel, SpecialistAssignModel} from '../models';
import SpecialistAssign from './specialist-assign';
import TeacherAssign from './teacher-assign';
import styles from '../assign.module.less';

class State {
	primaryTeacherModel: MoveStudentModel = new MoveStudentModel();
	specialistModel: SpecialistAssignModel = new SpecialistAssignModel();
	inProcess: boolean = false;
	type: AssignType = AssignType.PrimaryTeacher;
}

class Props {
	close: () => void;
	schools: SchoolModel[] = null;
	specialists: SpecialistModel[] = null;
	selectedStudentIDs: number[];
	selectedStudentsSchoolsIDs: number[];
	assignService: AssignService;
}

@useState(State)
export default class AssignPopup extends React.Component<Props, State> {
	private readonly loader: Loader = new Loader('body');
	private currentUser = userStorage.get();
	private onDestroy$: Subject<void> = new Subject();

	componentDidMount(): void {
		if (this.isSpecialist()) {
			this.setState({type: AssignType.Specialist});
		}
	}

	private isSpecialist() {
		return this.currentUser.userType === UserType.ISS
			|| this.currentUser.userType === UserType.ISD
			|| this.currentUser.userType === UserType.PA;
	}

	private moveSelectedStudents(): Observable<void> {
		this.loader.mask();
		if (this.state.type === AssignType.PrimaryTeacher) {
			return this.props.assignService.assignByPrimaryTeacher(this.state.primaryTeacherModel, this.props.selectedStudentIDs);
		}
		if (this.state.type === AssignType.Specialist) {
			const model = this.state.specialistModel;
			if ([UserType.ISS, UserType.ISD].indexOf(this.currentUser.userType) > -1) {
				model.specialistSchoolID = this.currentUser.schoolID;
				model.specialistType = this.currentUser.userType;
			}
			return this.props.assignService.assignBySpecialistGroup(model, this.props.selectedStudentIDs);
		}
	}

	private moveProcess(): void {
		if (this.state.inProcess) {
			return;
		}

		this.setState({inProcess: true}, () => {
			this.moveSelectedStudents().pipe(finalize(() => {
				this.setState({inProcess: false}, () => {
					this.loader.unmask();
					this.props.close();
				});
			})).subscribe();
		});
	}

	private isConfirmActive(): boolean {
		const primaryTeacherConfirm =
			this.state.type === AssignType.PrimaryTeacher &&
			(this.state.primaryTeacherModel.schoolID && this.state.primaryTeacherModel.teacherID || this.state.primaryTeacherModel.classID || this.state.primaryTeacherModel.groupID);

		const specialistConfirm = this.state.type === AssignType.Specialist && this.state.specialistModel.specialistGroupID;

		return this.state.inProcess || !(primaryTeacherConfirm || specialistConfirm);
	}

	render() {
		const isAdmin = (this.currentUser.userType === UserType.D || this.currentUser.userType === UserType.C);
		return <Modal className='student_operation_dialog move_student_dialog'>
			<ModalHeader title='Assign To'>
			</ModalHeader>
			<ModalBody>
				<AutoDimension fixWidth>
					<div
						className={join(styles.formContainer, (this.currentUser.userType === UserType.T || this.isSpecialist()) && styles.smallFormContainer)}>
						{(this.props.specialists.length > 0 && isAdmin) &&
							<div className={styles.typeInputContainer}>
								<Radio
									value={AssignType.PrimaryTeacher}
									checked={this.state.type === AssignType.PrimaryTeacher}
									onClick={e => {
										this.setState({type: AssignType.PrimaryTeacher});
									}}
									className={styles.input}
									id='assign-type-primary-teacher'
								>Primary Teacher</Radio>
								<Radio
									value={AssignType.Specialist}
									checked={this.state.type === AssignType.Specialist}
									onClick={e => {
										this.setState({type: AssignType.Specialist});
									}}
									className={styles.input}
									id='assign-type-specialist'
								>Specialist</Radio>
							</div>
						}
						<TeacherAssign hidden={this.state.type !== AssignType.PrimaryTeacher}
						               onValueChange={(model) => this.setState({primaryTeacherModel: {...this.state.primaryTeacherModel, ...model}})}
						               schools={this.props.schools}/>
						<SpecialistAssign specialists={this.props.specialists}
						                  hidden={this.state.type !== AssignType.Specialist}
						                  onValueChanged={(model) => this.setState({specialistModel: {...this.state.specialistModel, ...model}})}
						                  selectedStudentsSchoolsIDs={this.props.selectedStudentsSchoolsIDs}
						                  schools={this.props.schools}/>

					</div>
				</AutoDimension>
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => {
							this.props.close();
						}}
						title='Cancel'
					/>
					<Primary
						onClick={() => {
							this.moveProcess();
						}}
						disabled={this.isConfirmActive()}
						title='Confirm'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}


	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
