import {DeleteButton, Divider, OptionButtonsBox} from './index.styled';
import {Delete} from '@esgi/ui/icons';

interface Props {
	onDeleteButtonClick: VoidFunction
}

export function OptionButtons({onDeleteButtonClick}: Props) {
	return <OptionButtonsBox>
		<DeleteButton withBackgroundHover onClick={onDeleteButtonClick}>
			<Delete width={24} height={24}/>
		</DeleteButton>
		<Divider/>
	</OptionButtonsBox>;
}

