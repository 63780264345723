import {computed} from '@esgi/deprecated/knockout';
import {CartTemplate} from '../html/cart-template';
import {CartModel} from '../models';
import {Step} from './steps';

export class CartStep extends Step {
	constructor(public cart: CartModel) {
		super();
		this.title = 'Cart';
	}

	template = CartTemplate;

	@computed()
	get isAnyProductSelected() {
		return this.cart.expiration.products.some(x => x.selected() && x.isPurchasable());
	}

	@computed()
	get isAbleToPurchaseStudents(){
		return this.cart.student.isAbleToPurchase;
	}

	@computed()
	get isAbleToPurchase(){
		return this.isAbleToPurchaseStudents || this.isAnyProductSelected;
	}

	view = {
		addStudents: () => {
			$(this).trigger('addStudents');
		},
		editStudents: () => {
			$(this).trigger('editStudents');
		},
		addTime: () => {
			$(this).trigger('addTime');
		},
		editExpiration: () => {
			$(this).trigger('editExpiration');
		},
		checkOut: () => {
			//callback must return promise in order to protect from double click
			return $(this).triggerHandler('checkOutClicked');
		},
		cancel: () => {
			$(this).trigger('cancelClicked');
		},
	};

	events = {
		addStudents: (callback) => {
			$(this).on('addStudents', callback);
		},
		editStudents: (callback) => {
			$(this).on('editStudents', callback);
		},
		addTime: (callback) => {
			$(this).on('addTime', callback);
		},
		editExpiration: (callback) => {
			$(this).on('editExpiration', callback);
		},
		checkOutClicked: (callback) => {
			$(this).on('checkOutClicked', callback);
		},
		cancelClicked: (callback) => {
			$(this).on('cancelClicked', callback);
		},
	};
}
