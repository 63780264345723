import ItemAnalysisReportService from '../../services/item-analysis-report-service';
import {ReportData, Test} from '../../models';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {mapToEnum} from 'shared/utils';
import {TestType} from '@esgi/core/enums';
import styles from './empty-report.module.less';

interface Props {
	service: ItemAnalysisReportService;
}

export default function EmptyReport({service}: Props) {
	const selectedTest = useBehaviorSubject<Test>(service.selectedTest);
	const reportData = useBehaviorSubject<ReportData>(service.reportData);
	const emptyReport = reportData ? reportData?.students.length === 0: false;
	const noStudents = reportData ? reportData?.students.length === 0: false;
	const scoreTest = mapToEnum(selectedTest.type, TestType) === TestType.Score;
	const rubricTest = mapToEnum(selectedTest.type, TestType) === TestType.Rubric;

	return <>
		{
			noStudents && <div className={styles.emptyReportText}>
				There are no students in this group or class
			</div>
		}
		{
			emptyReport && !noStudents && <div className={styles.emptyReportText}>
				There are no test results for this test
			</div>
		}
		{
			scoreTest && <>
				<div className={join(styles.emptyReportText, styles.scoreTest)}>
					Sorry. <span>{selectedTest.name}</span> is a Single Score test. Item Analysis is only supported for
					Yes/No tests. For more information click <a target='_blank'
					                                            href='https://support.esgisoftware.com/hc/en-us/articles/209160406-Creating-Tests'
					                                            rel='noreferrer'>Here</a>
				</div>
			</>
		}
		{
			rubricTest && <>
				<div className={join(styles.emptyReportText, styles.scoreTest)}>
					Sorry. <span>{selectedTest.name}</span> is a Rubric test. Item Analysis is only supported for Yes/No
					tests. For more information click <a target='_blank'
					                                     href='https://support.esgisoftware.com/hc/en-us/articles/209160406-Creating-Tests'
					                                     rel='noreferrer'>Here</a>
				</div>
			</>
		}
	</>;
}
