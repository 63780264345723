import './styles.less';
import {HttpClient} from '@esgi/api';
import {debounce} from '@esgi/deprecated/knockout';
import React from 'react';
import {Subject, Subscription} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {TextInput} from '@esgi/deprecated/elements';
import {EventBusDispatcher} from '@esgillc/events';
import {MainScreen} from '../../enums';
import {DataService} from '../../services/data-service';
import {SearchEvents} from './events';

class State {
	scope: string = '';
	keyword: string = '';
	searchInProcessing: boolean = false;
	keywordApplied: boolean = false;
}

class Props {
	dataService: DataService;
	currentScreen: MainScreen;
}

export default class Search extends React.Component<Props, State> {
	private onDestroy$: Subject<void> = new Subject();

	constructor(props: Props) {
		super(props);
		const state = new State();
		state.scope = props.dataService.filter.currentValue.scope;
		this.state = state;
	}

	private get isDisabled(): boolean {
		return this.props.currentScreen === MainScreen.Results && this.state.searchInProcessing;
	}

	render(): JSX.Element | false | null {
		return <div className={'te-search-field ' + (this.state.keywordApplied && 'active')}
		            ref={(r) => this.setRootElement(r)}>
			<div className='input-group'>
				<a href='#' className='input-group-addon magnifying-glass-icon'
				   onClick={() => this.searchButtonClicked()}>
					<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'
					     fill='none'>
						<path
							d='M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z'
							fill='#bdbdbd'/>
					</svg>
				</a>
				<TextInput
					inputClassName={'search-input ' + (this.state.keyword && 'filled')} type='text'
					value={this.state.keyword}
					placeholder='Search by Test Name, Author, or Description'
					onBlur={() => this.searchButtonClicked()}
					disabled={this.isDisabled}
					onKeyUp={(e) => this.searchFieldKeyPress(e)}
					onEdit={(value) => this.setState({keyword: value}/*, () => this.props.keywordChanged(value)*/)}/>
				{this.state.keyword &&
					<a href='#' className='input-group-addon clear-icon' onClick={() => this.clearSearch()}>
						<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'
						     fill='none'>
							<path
								d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
								fill='#828282'/>
						</svg>
					</a>
				}
			</div>
		</div>;
	}

	readonly viewAllOption = 'View all';

	rootElement: HTMLDivElement;
	$typeahead: JQuery;
	autoSuggests: string[] = [];
	autoSuggestsLoaded: boolean = false;
	lastKeyword: string = '';
	request: Subscription;

	// this field is used to determine if autosuggests should be shown
	// it may happen that suggests request is in-flight and user hits enter to submit search request
	// in  this case we should not show autosuggest
	// so the solution here is to compare searchAttempt before sending suggests query and after it's been received
	// if numbers do not match, then search request has been dispatched and we should discard suggestion results
	searchAttempt = 0;

	private searchFieldKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
		let keyCode = event.which || event.keyCode;
		if (keyCode === 13) {
			this.searchButtonClicked();
			event.currentTarget.blur();
		}
	}

	searchButtonClicked = () => {

		if (this.lastKeyword === this.state.keyword) {
			return;
		}

		this.searchAttempt++;

		EventBusDispatcher.dispatch(SearchEvents.KeywordChanged, {});

		this.props.dataService.filter.update({keyword: this.state.keyword});

		// we don't want autosuggestions show up after we submitted the query
		this.lastKeyword = this.state.keyword;
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {

		if (this.state.scope !== prevState.scope) {
			this.autoSuggestsLoaded = false;
			this.autoSuggests = [];
		}
	}

	componentDidMount(): void {
		this.props.dataService.filter.onChanged$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((value) => {
				const keyword = value.touched ? this.state.keyword : '';
				this.setState({
					scope: value.scope,
					keywordApplied: value.keyword === this.state.keyword,
					keyword: keyword,
				});
				this.lastKeyword = keyword;
			});

		this.props.dataService.search.onLoadingStatusChanged$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(processing => this.setState({
				searchInProcessing: processing,
			}));

		this.$typeahead = $('input', this.rootElement).typeahead({
			items: 6,
			minLength: 2,
			item: (item) => {
				if (item === this.viewAllOption) {
					return '<li><a class="dropdown-item view-all" onmousedown="return false" href="#" role="option"></a></li>';
				}

				return '<li><a class="dropdown-item" onmousedown="return false" href="#" role="option"></a></li>';
			},
			changeInputOnSelect: false, // do not put the text into input after clicking, we'll do it ourselves
			source: (query, process) => {
				this.loadAutosuggestions(query, process);
			},
			afterSelect: (value: string) => {
				if (value === this.viewAllOption) {
					return this.searchButtonClicked();
				}

				this.setState({keyword: value}, () => {
					this.searchButtonClicked();
				});
			},
			matcher: function (item) {
				return item;
			},
			autoSelect: false,
		});
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}

	@debounce(300)
	loadAutosuggestions(query: string, process: (suggestions) => any) {
		if (this.lastKeyword === this.state.keyword) {
			return;
		}

		let oldSearchAttempt = this.searchAttempt;
		if (this.request) {
			this.request.unsubscribe();
		}
		this.request = HttpClient.default.ESGIApi.get<{ tests: string[] }>('pages/test-explorer', 'suggestions', {
			scope: this.state.scope,
			keyword: query,
		}).pipe(filter(r => !!r.tests)).subscribe(r => {
			this.request = null;
			if (oldSearchAttempt !== this.searchAttempt) {
				return;
			}

			if (r.tests && r.tests.length > 0) {
				r.tests.push(this.viewAllOption);
			}

			this.autoSuggests = r.tests;
			process(this.autoSuggests);
			return r;
		});
	}

	private clearSearch() {
		this.setState({keyword: ''}, () => {
			this.searchButtonClicked();
			this.$typeahead.trigger('clear');
		});
	}

	private setRootElement(r: HTMLDivElement) {
		this.rootElement = r;
	}
}
