import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AlertBodyGrid} from './alert-grid-body';
import {useNavigate} from 'react-router-dom';
import {StudentManagerLink} from './index.styled';

type Props = {
	onAlertClose: VoidFunction;
	studentsCount: number;
	className: string;
	duplicatesSaved: boolean;
};

export function CompleteWithStudentsExistInClass({onAlertClose, studentsCount, className, duplicatesSaved}: Props) {

	const navigate = useNavigate();

	return (
		<>
			<Alert.Header onCloseIconClick={onAlertClose}>
				<Text size='large'>File Upload is Complete</Text>
			</Alert.Header>
			<AlertBodyGrid>
				<Text size='medium'>Your file contains students who are already uploaded to a class.</Text>
				<Text size='medium'>
					{studentsCount || 0} students have been added to {className}.
				</Text>

				{Boolean(studentsCount && duplicatesSaved) && (
					<Text size='medium'>
						Please visit <StudentManagerLink onClick={() => navigate('/student-manager')}>Student Manager</StudentManagerLink> to assign the remainder of your students to
						your class.
					</Text>
				)}
			</AlertBodyGrid>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
