export enum TemplateType {
	None = 'None',
	With1Column = 'With1Column',
	With2Columns = 'With2Columns',
	With3Columns = 'With3Columns',
	With4Columns = 'With4Columns',
	Practice = 'Practice',
	Template5 = 'Template5',
	Story = 'Story',
}

export enum ImagePosition {
	Top,
	Bottom,
}

export type QuestionOptions = TextOption[] | PicOption[] | TextAudioOption[];

export interface Question {
	id: number;
	templateType: TemplateType;
	picUrl?: string;
	text?: string;
	cards?: QuestionCard[];
	textSizePx?: number;
	directionsText: string;
	audioDirectionUrl: string;
	audioStoryUrl?: string;
	correctOptionsCount: number;
	options: QuestionOptions;
	thumbnailUrl?: string;
	audioUrl?: string;
	imagePosition?: ImagePosition;
	copiedFromQuestionID?: number;
	bankQuestionID?: number;
}

export interface QuestionCard {
	audioText: string;
	audioUrl: string;
	text: string;
	picUrl: string;
}

export const TEMPLATE_TYPE: Record<number, TemplateType> = {
	0: TemplateType.None,
	1: TemplateType.With1Column,
	2: TemplateType.With2Columns,
	3: TemplateType.With3Columns,
	4: TemplateType.With4Columns,
	5: TemplateType.Template5,
	6: TemplateType.Story,
	100: TemplateType.Practice,
};

export interface Option {
	optionID: number;
}

export interface TextOption extends Option {
	text: string;
}

export interface PicOption extends Option {
	picUrl: string;
}

export interface TextAudioOption extends TextOption {
	audioUrl: string;
}
