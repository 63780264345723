import {isNull} from 'underscore';
import {DropdownOption} from '../../../kit';

export function getDropdownValue(value: DropdownOption | null) {
	if (isNull(value) || (value && isNull(value.value))) {
		return null;
	}

	return Number(value.value);
}
