export function Hidden(testId: number): Hidden.Args {
	return {
		testId: testId,
	};
}

export module Hidden {
	export interface Args {
		testId: number;
	}
}

export function Unhidden(testId: number): Unhidden.Args {
	return {
		testId: testId,
	};
}

export module Unhidden {
	export interface Args {
		testId: number;
	}
}

export function Starred(testId: number): Starred.Args {
	return {
		testId: testId,
	};
}

export module Starred{
	export interface Args {
		testId: number;
	}
}

export function Unstarred(testId: number): Unstarred.Args {
	return {
		testId: testId,
	};
}

export module Unstarred {
	export interface Args {
		testId: number;
	}
}