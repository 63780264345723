import {BingoPanel} from './components/bingo-panel';
import {BingoTestWidget} from './components/bingo-test-widget';
import {EmptyList} from './components/empty-list';
import {PreviewDialog} from './components/preview-dialog';

export * from './hooks';

export const BingoUI = {
	BingoPanel,
	TestWidget: BingoTestWidget,
	EmptyList: EmptyList,
	PreviewDialog,
};
