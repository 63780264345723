import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../../../hooks';
import styles from './styles.module.less';

export function SessionInfo(): JSX.Element {
	const service = useTestSessionDetailsService();
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);

	return currentTestSession && currentTestSession.number && (
		<div className={styles.sessionInfo}>
			<div className={styles.duration}>
				Duration: {currentTestSession.durationString}
			</div>
		</div>
	);
}
