import {join} from '@esgillc/ui-kit/utils';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import moment from 'moment/moment';
import React, {useMemo} from 'react';
import {TestType} from '@esgi/core/enums';
import {PieChartModel} from '../../../models';
import styles from '../../card.module.less';
import {isStudentLevel} from '../../utils';

interface Props {
	studentsTested: number;
	hierarchy: HierarchySnapshot;
	testResultsCorrectVerbiage: string;
	pieChart: PieChartModel;
}

export function InfoPanel(props: Props) {
	const {testType, correctAnswers, totalAnswers, lastTestDate, studentsTested, totalStudents} = props.pieChart;

	if (props.studentsTested === 0) {
		return <div>Not Tested</div>;
	}

	const lastTestDateFormatted = useMemo(() => lastTestDate && moment(lastTestDate).format('MM-DD-YY'), [lastTestDate]);

	function studentsScoreTitle() {
		if (testType === TestType[TestType.YN]){
			return props.testResultsCorrectVerbiage + ' Answers';
		} else {
			return 'Score';
		}
	}
	
	function multipleStudentsScoreTitle() {
		if (testType === TestType[TestType.YN]){
			return props.testResultsCorrectVerbiage + ' Answers';
		}

		if (testType === TestType[TestType.Score]){
			return 'Score';
		}

		if (testType === TestType[TestType.Rubric]){
			return 'Avg. Score';
		}
	}
	
	if (isStudentLevel(props.hierarchy)) {
		return <>
			<div key='score' className={join(styles.marginTop40, styles.marginBottom7)}>
				<div>{studentsScoreTitle()}</div>
				<div className={styles.score}>{correctAnswers}/{totalAnswers}</div>
			</div>
			{lastTestDate && <div key='last-date'>
				<div>Last Test Date</div>
				<div className={styles.lastTestDate}>{lastTestDateFormatted}</div>
			</div>}
		</>;
	} else {
		return <>
			<div key='student-tested' className={join(styles.marginTop40, styles.marginBottom7)}>
				<div>Students Tested</div>
				<div>{studentsTested} / {totalStudents}</div>
			</div>
			<div className={styles.correct} key='correct'>
				<div>{multipleStudentsScoreTitle()}</div>
				<div className={styles.score}>{correctAnswers} / {totalAnswers}</div>
			</div>
		</>;
	}
}
