import {InitModel} from './models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';

export class ViewFlashCardsSettingsEvent {
	constructor(public initModel: InitModel, changesCollector?: ChangesCollector) {
	}
}

export class ViewParentLetterEvent {
	constructor(public initModel: InitModel, changesCollector?: ChangesCollector) {
	}
}
