import {TableHeaderCellSortableItem} from '@esgi/main/kits/assignments';
import {ListHeaderCellID} from '../../types';

export const sortableCells: TableHeaderCellSortableItem<ListHeaderCellID>[] = [
	{
		cellKey: ListHeaderCellID.Student,
		cellName: 'Student Name',
	},
	{
		cellKey: ListHeaderCellID.Assignment,
		cellName: 'Assignment',
	},
];
