import {useState} from 'react';
import {useBehaviorSubject, useStream} from '@esgi/ui/utils';
import {SightWordsService} from '@esgi/main/features/teacher/sight-words';

export function useSightWordsState(service: SightWordsService) {
	const [preview, setPreview] = useState(false);
	const [showAddTestsDrawer, setShowAddTestsDrawer] = useState(false);
	const valid = useStream(service.valid$, false);
	const busy = useStream(service.busy$, false);
	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const studentList = useBehaviorSubject(service.studentService.students$);
	const loadedStudents = useBehaviorSubject(service.studentService.loaded$);
	const totalPages = useStream(service.totalPages$, 0);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const loadedSubjects = useBehaviorSubject(service.subjectService.loaded$);
	const subject = useBehaviorSubject(service.subject$);
	const students = useBehaviorSubject(service.students$);

	return {
		preview,
		setPreview,
		showAddTestsDrawer,
		setShowAddTestsDrawer,
		valid,
		busy,
		classes,
		groups,
		studentList,
		loadedStudents,
		totalPages,
		subjects,
		loadedSubjects,
		subject,
		students,
	};
}
