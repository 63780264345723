import {ReportsFiltersContainer} from '../../index.styled';
import {styled} from '@stitches/react';
import {FlexBox} from '@esgi/ui/layout';


export const ReportFilterContainerStyled = styled(ReportsFiltersContainer, {
	marginBottom: '5px',
	marginRight: '7px',
	flexDirection: 'column',
});

export const TitleStyled = styled(FlexBox, {
	lineHeight: '14px',
});