import {FormField, Validators} from '@esgi/deprecated/knockout';
import {AsyncFieldContainer} from '../../../kit/component';
import {Dictionaries, StateModel} from '../../server';

export class StateSelectComponent extends AsyncFieldContainer<StateModel> {
	private declare countryID: KnockoutObservable<number>;
	private states: KnockoutObservableArray<StateModel> = ko.observableArray<StateModel>();
	state: KnockoutObservable<StateModel> = ko.observable<StateModel>();

	buildField(initValue?): FormField<StateModel> {
		this.countryID = ko.observable<number>(0);
		const f = this.createField<StateModel>(undefined, Validators.Required('Please select a state/region.'));
		this.countryID.subscribe((v) => {
			if (v) {
				Dictionaries.loadStates(v).done((states) => {
					this.states(states.states);
					ko.tasks.runEarly();
					$('select#state', this.rootElement).trigger('chosen:updated');
				});
			}
		});
		f.value.subscribe((state: StateModel) => {
			this.state(state);
		});
		f.disabled = ko.computed(() => {
			return !this.states().length;
		});

		f.validation.showValidation(true);
		f.validation.errorValidation(true);
		f.validation.errorPosition = 'left';

		return f;
	}

	public setCountry(id: number): void {
		this.countryID(id);
	}


	public template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group state'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<select id='state' name='state' className='form-control'
					        data-bind="options: root.states, optionsText: 'name', optionsCaption: '-- select state/region --', value: value, disable: disabled"/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
				</div>
			</div>
		</div>
	};
}
