import React from 'react';

export default class IepGoalsIcon extends React.Component {
  render() {
    return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='18' height='19'
                viewBox='0 0 18 19'
                fill='none'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M16.3163 6.40844C16.7663 7.45844 16.9913 8.58344 16.9913 9.70844C16.9913 10.8334 16.7663 11.9584 16.3163 13.0084C15.8663 13.9834 15.2663 14.9584 14.5163 15.7084C13.7663 16.4584 12.8663 17.0584 11.8163 17.5084C10.7663 17.9584 9.64132 18.1834 8.51632 18.1834C7.39132 18.1834 6.26632 17.9584 5.21632 17.5084C4.24132 17.0584 3.26632 16.4584 2.51632 15.7084C1.76632 14.9584 1.16632 14.0584 0.716321 13.0084C0.266321 11.9584 0.0413208 10.8334 0.0413208 9.70844C0.0413208 8.58344 0.266321 7.45844 0.716321 6.40844C1.16632 5.43344 1.76632 4.45844 2.51632 3.70844C3.26632 2.95844 4.16632 2.35844 5.21632 1.90844C6.26632 1.45844 7.39132 1.23344 8.51632 1.23344C9.64132 1.23344 10.7663 1.45844 11.8163 1.90844C12.1913 2.05844 12.4913 2.20844 12.8663 2.43344L11.8913 3.85844C10.9163 3.25844 9.79132 2.95844 8.51632 2.95844C4.76632 2.95844 1.76632 6.03344 1.76632 9.70844C1.76632 13.3834 4.76632 16.4584 8.51632 16.4584C12.2663 16.4584 15.2663 13.4584 15.2663 9.70844C15.2663 5.95844 14.8913 7.23344 14.2913 6.25844L15.7163 5.28344C15.9413 5.65844 16.1663 6.03344 16.3163 6.40844ZM8.44132 6.70844C8.89132 6.70844 9.26632 6.78344 9.64132 7.00844L10.7663 5.43344C10.0913 5.05844 9.26632 4.83344 8.44132 4.83344C5.74132 4.83344 3.56632 7.00844 3.56632 9.70844C3.56632 12.4084 5.74132 14.5834 8.44132 14.5834C11.1413 14.5834 13.3163 12.4084 13.3163 9.70844C13.3163 7.00844 13.0913 8.05844 12.7163 7.38344L11.1413 8.50844C11.2913 8.88344 11.4413 9.33344 11.4413 9.78344C11.4413 11.4334 10.0913 12.7834 8.44132 12.7834C6.79132 12.7834 6.86632 12.4834 6.34132 11.8834C5.81632 11.3584 5.51632 10.6084 5.51632 9.78344C5.51632 8.13344 6.86632 6.78344 8.51632 6.78344L8.44132 6.70844ZM8.44132 8.50844C7.76632 8.50844 7.24132 9.03344 7.24132 9.70844C7.24132 10.3834 7.76632 10.9084 8.44132 10.9084C9.11632 10.9084 9.64132 10.3834 9.64132 9.70844C9.64132 9.03344 9.49132 9.10844 9.26632 8.88344L17.9663 2.80844H15.4163L15.3413 0.183441L9.26632 8.88344C9.04132 8.65844 8.74132 8.58344 8.44132 8.58344V8.50844Z'
            fill='#0088CC'/>
    </svg>;
  }
}