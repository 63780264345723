import {OverlayScrollbarsComponent, OverlayScrollbarsComponentRef} from 'overlayscrollbars-react';
import {PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {TestDashboardSectionID} from '../../types';
import {isNull} from 'underscore';
import {ScrollAreaContext, ScrollAreaContextValue} from './context';
import {initialSectionsRefs} from './constants';

type Props = PropsWithChildren<{
	isTablet: boolean;
	initVisibleSection: TestDashboardSectionID | null;
}>;

export function ScrollArea({children, isTablet, initVisibleSection}: Props) {
	const [isOSRefInitialized, setIsOSRefInitialized] = useState(false);

	const [sectionsRefs, setSectionsRefs] = useState(initialSectionsRefs);

	const osRef = useRef<OverlayScrollbarsComponentRef<'div'>>(null);

	useEffect(() => {
		const osInstance = osRef.current?.osInstance();

		if (isOSRefInitialized && osInstance && !isNull(initVisibleSection)) {
			const {scrollOffsetElement} = osInstance.elements();

			const initVisibleSectionRef = sectionsRefs[initVisibleSection].current;

			if (!isNull(initVisibleSectionRef)) {
				scrollOffsetElement.scrollTo({
					behavior: 'smooth',
					top: initVisibleSectionRef.offsetTop,
				});
			}
		}
	}, [isOSRefInitialized, sectionsRefs]);

	const setSectionRef = useCallback(
		({sectionID, ref}: {sectionID: TestDashboardSectionID; ref: RefObject<HTMLElement>}) =>
			setSectionsRefs((currentState) => ({...currentState, [sectionID]: ref})),
		[],
	);

	const contextValue = useMemo<ScrollAreaContextValue>(
		() => ({
			setSectionRef,
		}),
		[setSectionRef],
	);

	return (
		<ScrollAreaContext.Provider value={contextValue}>
			<OverlayScrollbarsComponent
				defer
				style={{
					// 64 is height of the navigation panel in horizontal view
					height: `calc(100% + ${isTablet ? -64 : 0}px)`,
				}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
				ref={osRef}
				events={{
					initialized: () => {
						setIsOSRefInitialized(true);
					},
				}}
			>
				{children}
			</OverlayScrollbarsComponent>
		</ScrollAreaContext.Provider>
	);
}
