import {StudentsControl} from './kit/students-control';
import {DownloadPanel} from './kit/download-panel';
import {PreviewDialog} from './kit/preview-dialog';
import {DisplayTypeControl} from './kit/display-type-control';
import {ColorSelectorControl} from './kit/color-selector-control';
import {DisplayNotTestedControl} from './kit/display-not-tested-control';
import {SettingsControl} from './kit/settings-control';
import {TestsControl} from './kit/tests-control';
import {SubjectTabControl} from './kit/subject-tab-control';

export * from './services';
export * from './hooks';
export * from './types';

export const StudentProgressReport = {
	StudentsControl,
	DownloadPanel,
	PreviewDialog,
	DisplayTypeControl,
	ColorSelectorControl,
	DisplayNotTestedControl,
	SettingsControl,
	TestsControl,
	SubjectTabControl,
};
