import moment, {Moment} from 'moment';
import './styles.less';

export default class SessionDropDown {

	defaultFormat: string = 'mm/dd/yyyy';
	date: string;

	nowDate: Moment = moment();

	constructor(private left: number, private top: number, private tracks: { id: number, date: string }[], date?: string) {
		this.tracks.sort((a, b) =>{
			const aDate = moment(a.date.replace(/[-]/g, '/'));
			const bDate = moment(b.date.replace(/[-]/g, '/'));
			if (aDate.isAfter(bDate)) {
				return 1;
			}
			if (aDate.isBefore(bDate)) {
				return -1;
			}
			return 0;
		});
		this.date = date || this.tracks[0].date;
	}

	private handleClickOutside = (event) => {
		if (event.target) {
			const datePickerWrapper = $('.date-picker-wrapper');
			const markingPeriodWrapper = $('.marking-period-wrapper');
			const sessionDatePicker = $('#session-datepicker');
			const sessionDropdown = $('.session-dropdown');
			if ((datePickerWrapper.length && $.contains(datePickerWrapper[0], event.target))
				|| (markingPeriodWrapper.length && $.contains(markingPeriodWrapper[0], event.target))
				|| (sessionDatePicker.length && $.contains(sessionDatePicker[0], event.target))
				|| (sessionDropdown.length && $.contains(sessionDropdown[0], event.target))
			) {
				return;
			}
		}
		this.hideDropDown();
	};

	hideDropDown() {
		this.removeElement($('.session-wrapper'));

		document.removeEventListener('mousedown', this.handleClickOutside);
		$(this).trigger('hidden');
	}

	show = () => {
		if ($('.session-wrapper').length !== 0) {
			return;
		}
		$('body').append(this.createDateMenu());
		$('.date-item a').hover(() => this.dateOnHover(), null);
		$('.marking-period-item a').hover(() => this.markingOnHover());
		document.addEventListener('mousedown', this.handleClickOutside);
	};



	private createDateMenu() {

		const ul = document.createElement('ul');
		ul.className = 'session-dropdown dropdown-menu';

		const liDate = '<li class="date-item"><a href="#"><span>Date</span><i class="fa fa-caret-right" aria-hidden="true"></i></a></li>';
		const liMarkingPeriod = '<li class="marking-period-item"><a href="#"><span>Marking period</span><i class="fa fa-caret-right" aria-hidden="true"></i></a></li>';

		$(ul).append(liDate);
		$(ul).append(liMarkingPeriod);
		const wrapper = document.createElement('div');
		wrapper.className = 'session-wrapper';

		let leftPos = this.left;
		const outOfPageValue = document.body.clientWidth - (this.left + 385);
		if (outOfPageValue < 0) {
			leftPos = this.left + outOfPageValue;
		}

		$(wrapper).css({left: leftPos + 'px', top: this.top + 'px'});

		$(wrapper).append(ul);
		return wrapper;
	}

	private dateOnHover() {
		if ($('.date-picker-wrapper').length !== 0) {
			return;
		}
		this.removeElement($('.marking-period-wrapper'));

		const datePickerWrapper = this.createDatePickerWrapper();
		$('.session-wrapper').append(datePickerWrapper);

		//hack
		($('#session-datepicker') as any).datepicker({
			format: this.defaultFormat,
			autoclose: true,
			todayHighlight: true,
			endDate: this.nowDate.toDate(),
			beforeShowDay: (date: Date) => {
				if (moment(date).isAfter(this.nowDate)) {
					return {
						tooltip: 'This date is in the future. Please select a valid date.',
					};
				}
			},
		});

		$('#session-datepicker').datepicker('update', moment(this.date).toDate());
		$('#hidden_input').on('change', (e) => {
			this.raiseDateClicked($('#hidden_input').val() as string);
		});

		this.correctHeight(datePickerWrapper);
	}

	correctHeight(element: HTMLDivElement){
		const {top} = $('.session-wrapper').position();
		const {height} = {height: $(element).height()};
		if (document.body.clientHeight - (top + height) < 0) {
			$(element).css({top: document.body.clientHeight - (top + height) + 'px'});
		}
	}

	private markingOnHover() {
		if ($('.marking-period-wrapper').length !== 0) {
			return;
		}
		this.removeElement($('.date-picker-wrapper'));
		const markingPeriodWrapper = this.createMarkingPeriodWrapper();
		$('.session-wrapper').append(markingPeriodWrapper);

		this.correctHeight(markingPeriodWrapper);
	}

	private removeElement = (elementToRemove) => {
		if (elementToRemove.length) {
			elementToRemove.remove();
		}
	};

	private createDatePickerWrapper() {
		const datePickerWrapper = document.createElement('div');
		datePickerWrapper.className = 'date-picker-wrapper';

		const datePicker = '<div class="input-group date react-datepicker" id="session-datepicker"><input type="text" id="hidden_input" class="form-control" /></div>';
		$(datePickerWrapper).append(datePicker);

		return datePickerWrapper;
	}

	private createMarkingPeriodWrapper() {
		const wrapper = document.createElement('div');
		wrapper.className = 'marking-period-wrapper';

		const title = document.createElement('div');
		title.className = 'title';
		$(title).text('End date:');
		$(wrapper).append(title);
		$(wrapper).append(this.createMarkingPeriodOptions());

		return wrapper;
	}

	private createMarkingPeriodOptions() {
		const ul = document.createElement('ul');
		ul.className = 'marking-periods-items';
		const onClick = (target) => (e) => {
			const updDate = $($(target).children()[1]).text();
			this.raiseDateClicked(updDate);
		};
		this.tracks.forEach((track, pos) => {
			const li = document.createElement('li');
			const disabled = moment(track.date.replace(/[-]/g, '/')).isAfter(this.nowDate);
			li.className = `marking-period-item ${disabled && 'disabled' || ''}`;
			if (disabled) {
				$(li).bstooltip({
					trigger: 'hover',
					title: 'This marking period has not yet begun. Please select a valid marking period.',
					placement: 'right',
					container: 'body',
				});
			}

			const a = document.createElement('a');
			a.href = '#';
			if (!disabled){
				a.onclick = onClick(a);
			}
			const span = `<span class='position'>${pos + 1}.</span> <span>${track.date}</span>`;
			$(a).append(span);
			$(li).append(a);
			$(ul).append(li);
		});
		return ul;
	}

	raiseDateClicked(date: string) {
		this.hideDropDown();
		$(this).trigger('dateClicked', date);
	}

	events = {
		hidden: (callback: () => void) => {
			$(this).on('hidden', callback);
		},
		dateClicked: (callback: (_: Event, newDate: string) => any) => {
			$(this).on('dateClicked', callback);
		},
	};
}
