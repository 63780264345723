export class WindowTool {

	getRandomInt(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	submitToPopup(submitedform: any) {
		let newWin: Window = null;

		try {
			newWin = WindowTool.getNewWindow('', submitedform.target);
			submitedform.target = submitedform.target;
			newWin.moveTo(0, 0);
			newWin.resizeTo(screen.availWidth, screen.availHeight);
			newWin.focus();
			submitedform.submit();
		} catch (e) {
			WindowTool.sendReloadEvent(newWin, submitedform.action);
		}
	}

	submitToPopupByName(submitedform: any, popupName: any) {
		submitedform.target = popupName;
		submitedform.submit();
	}

	posttoopen(verb: string, url: string, data: any, target: string, needOpenInPopUp: boolean, popupName: string) {
		let form = document.createElement('form');
		form.name = 'form' + this.getRandomInt(1, 1000000);
		form.action = url;
		form.method = verb;
		form.target = target || '_self';
		if (data) {
			for (let key in data) {
				let input = document.createElement('input');
				input.id = key;
				input.name = key;
				input.value = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
				input.type = 'hidden';
				form.appendChild(input);
			}
		}
		form.style.display = 'none';
		document.body.appendChild(form);
		if (needOpenInPopUp) {
			if (popupName) {
				this.submitToPopupByName(form, popupName);
			} else {
				this.submitToPopup(form);
			}
		} else {
			form.submit();
		}
	}

	openAndResizeWindow(name: string, windowUrlDefer: JQueryDeferred<string>, winUrl: string = null, callback: (newWin: Window) => void = null) {

		let w: Window = null;
		try {
			if (WindowTool.getBrowserVersion().toLowerCase().indexOf('firefox') > -1 && window.opener) {
				let url = window.opener.location.href; // will throw security exception if current window is opened from another domain
			}
		} catch (e) {
			window.opener.close(); // will try to close opener window, (won't close if opener is tab)
		}

		w = WindowTool.getNewWindow('', name);
		try {

			if (window.screen) {
				w.moveTo(0, 0);
				w.resizeTo(screen.availWidth, screen.availHeight);
			}

			if (w === window) {
				let target = w.document.body;
				const observer = new MutationObserver(function (mutations) {
					mutations.forEach(function (mutation) {
						let newNodes = mutation.addedNodes; // DOM NodeList
						if (newNodes !== null) { // If there are new nodes added
							let $nodes = $(newNodes); // jQuery set
							$nodes.each(function () {
								let $node = $(this);
								if ($node.attr('id') === 'loading') {
									winUrl && (w.location.href = winUrl);
									callback && callback(w);
									observer.disconnect();
								}
							});
						}
					});
				});
				observer.observe(target, {childList: true});
				// this.addLoadingToWindowByDom(w);
			} else {
				winUrl && (w.location.href = winUrl);
				callback && callback(w);
			}
		} catch (e) {
			if (windowUrlDefer) {
				windowUrlDefer.done((windowUrl) => {
					WindowTool.sendReloadEvent(w, windowUrl);
				});
				callback && callback(w);
			}
		}

		return w;
	}

	public static postCurrentWindow(url: any) {

		// The rest of this code assumes you are not using a library.
		// It can be made less wordy if you use one.
		let form = document.createElement('form');
		form.style.display = 'none';
		form.setAttribute('method', 'POST');
		form.setAttribute('action', url);

		document.body.appendChild(form);
		form.submit();
	}

	addLoadingToWindow(wnd: Window) {
		wnd.document.write('<html><head><title>Please wait</title>');
		wnd.document.write
		(`<style>
                        #loading {
                            height: 100%;
                            left: 0;
                            position: absolute;
                            text-align: center;
                            top: 0;
                            vertical-align: middle;
                            width: 100%;
                            background-color: rgb(26, 34, 46);
                            color: rgb(151, 178, 202);
                            font-family: monospace;
                            z-index: 99999999;
                        }
                        #loading p {line-height: 1em;font-size: 98px; font-family: monospace;margin: 0 0 10px;}
                        #loading h1 {
                            display: inline;
                            font-weight: normal;
                            font-size: 98px;
                            color: #97b2ca;
                        }
                    </style>`);
		wnd.document.write('</head><body>');
		wnd.document.write('<table id=\'loading\'><tr><td><p></p><h1> Loading...</h1><p></p></td></tr></table>');
		wnd.document.write('</body></html>');
	}

	addLoadingToWindowByDom(wnd: Window) {
		wnd.document.title = 'Please wait';
		let style = wnd.document.createElement('style');
		wnd.document.head.appendChild(style);
		style.innerHTML = `
                    #loading {
                        height: 100%;
                        left: 0;
                        position: absolute;
                        text-align: center;
                        top: 0;
                        vertical-align: middle;
                        width: 100%;
                        background-color: rgb(26, 34, 46);
                        color: rgb(151, 178, 202);
                        font-family: monospace;
                        z-index: 99999999;
                    }
                    #loading p {line-height: 1em;font-size: 98px;margin: 0 0 10px;}
                    #loading h1 {
                        display: inline;
                        font-weight: normal;
                        font-size: 98px;
                        color: #97b2ca;
                    }
                        `;
		let table = wnd.document.createElement('table');
		table.id = 'loading';
		table.innerHTML = '<tr><td><p></p><h1> Loading...</h1><p></p></td></tr>';
		wnd.document.body.innerHTML = '';
		wnd.document.body.appendChild(table);
	}

	public static getESGIWindowName() {
		let title = 'ESGIAppWindow';
		let name = title + ' ' + WindowTool.getEnv();

		return name;
	}

	public static getEnv() {
		let env = 'Prod';

		if (window && window.location && window.location.origin) {
			let url = window.location.origin.toLowerCase();

			if (url.indexOf('test') > -1) {
				env = 'Test';
			}

			if (url.indexOf('beta') > -1) {
				env = 'Beta';
			}

			if (url.indexOf('reg') > -1) {
				env = 'Reg';
			}

			if (url.indexOf('temp') > -1) {
				env = 'Temp';
			}

			if (url.indexOf('localhost') > -1) {
				env = 'Dev';
			}
		}

		return env;
	}

	public static newWindowOptions = 'toolbar=0,location=0,status=0,menubar=0,resizable=1,scrollbars=1';

	public static getNewWindow(url: string, targetName: string): Window {

    	let options = window.screen
    		? WindowTool.newWindowOptions + ',width=' + screen.availWidth + ',height=' + screen.availHeight
    		: WindowTool.newWindowOptions;

    	let newWin = window.open(url, targetName, options);
    	return newWin;
	}

	public static sendReloadEvent(window: Window, windowUrl: string) {
    	try {
    		window.postMessage('reloadPageEvent', windowUrl);
    	} catch (e) {
    		let error = e;
    	}
	}

	public static getBrowserVersion() {
    	let ua = navigator.userAgent, tem,
    		M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    	if (/trident/i.test(M[1])) {
    		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    		return 'IE ' + (tem[1] || '');
    	}
    	if (M[1] === 'Chrome') {
    		tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    		if (tem != null) {
    			return tem.slice(1).join(' ').replace('OPR', 'Opera');
    		}
    	}
    	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    	if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    		M.splice(1, 1, tem[1]);
    	}
    	return M.join(' ');
	}

	public static getUrlQueryParameter(param: string): string {
    	let regex = '[?&]' + param + '=([^&#]*)';
    	let results = (new RegExp(regex)).exec(window.location.href);
    	if (results) {
    		return results[1];
    	}
    	return '';
	}

	public static parseUri(uri?: string): IParsedUri {

    	let parser = /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
    		queryKeyParser = /(?:^|&)([^&=]*)=?([^&]*)/g,
    		keys = 'href protocol authority userInfo user password host port relative path directory file query anchor'.split(' ');


    	let href = uri || location.href,
    		m = parser.exec(href),
    		uriinfo = {
    			params: {},
    		} as any;

    	keys.forEach(function (key, idx) {
    		uriinfo[key] = m[idx] || '';
    	});

    	uriinfo.query.replace(queryKeyParser, function (match: string, name: string, value: string) {

    		if (name) {
    			uriinfo.params[name] = decodeURIComponent(value);
    		}
    	});

    	return uriinfo;
	}

	public static clearUrlQueryParameters() {
    	history.pushState(null, '', location.href.split('?')[0]);
	}
}

export interface IParsedUri {
	href: string;
	protocol: string;
	authority: string;
	userInfo: string;
	user: string;
	password: string;
	host: string;
	port: string;
	relative: string;
	path: string;
	directory: string;
	file: string;
	query: string;
	anchor: string;
	params: { [id: string]: string };
}

export class CookieReader {

	static read(name: string) {
		let result = new RegExp('(?:^|; )' + encodeURIComponent(name) + '=([^;]*)').exec(document.cookie);
		return result ? result[1] : null;
	}

	static write(name: string, value: string, hours?: number, domain: string = 'esgisoftware.com') {
		if (!hours) {
			hours = 24 * 365;
		}

		let date = new Date();
		date.setTime(date.getTime() + (hours * 60 * 60 * 1000));

		let expires = '; expires=' + date.toUTCString();

		document.cookie = name + '=' + value + expires + `; domain=${domain}` + '; path=/';
	}

	static remove(name: string) {
		CookieReader.write(name, '', -1);
	}
}
