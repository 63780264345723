import {SubjectType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {Subject} from './models';

export class RequestsSettingsInit {
	subjectID: number;
	subjectType: SubjectType;
	testIDs: number[];
	hierarchy: HierarchySnapshot;
}

export class ResponsesSettingsInit {
	teacher: SettingsUser;
	classID: number;
	groupID: number;
	specialistGroupID: number;
	classes: SettingsUnityItem[];
	groups: SettingsUnityItem[];
	specialistGroups: SettingsUnityItem[];
	subjects: Subject[];
	report: ResponsesReportInit;
}

export class SettingsUser {
	userID: number;
	firstName: string;
	lastName: string;
	title: string;
	includeGradeScore: FlagItem;
	displayZeroIfNotTested: FlagItem;
	carryScoresForward: FlagItem;
	printInColor: FlagItem;
	showBaseline: boolean;
	includeQuestionNotes: FlagItem;
	includeSummaryNotes: FlagItem;
	sortBy: string;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
}

export class SettingsUnityItem {
	itemID: number;
	itemName: string;
	selectedStudentID: number;
	students: SettingsStudent[];
}

export class SettingsStudent {
	studentID: number;
	firstName: string;
	lastName: string;

	get FullName(): string {
		let result = '';

		if (this.firstName) {
			result += this.firstName + ' ';
		}

		if (this.lastName) {
			result += this.lastName;
		}

		return result;
	}

	static MakeDefaultStudent(): SettingsStudent {
		const defaultStudent = new SettingsStudent;
		defaultStudent.studentID = 0;
		defaultStudent.firstName = 'All';
		return defaultStudent;
	}
}


export class ResponsesReportInit {
	trackDates: ResponsesTrackDates[];
	students: ResponsesStudent[];
	gradeValues: ResponsesGrade[];
	reportGuid: string;
}

export class RequestsReportInit {
	hierarchy: HierarchySnapshot;
	teacherID: number;
	studentIDs: number[];
	classID: number;
	groupID: number;
	specialistGroupID: number;
	testIDs: number[];
	subject: Subject;
	sortBy: string;
	carryScores: boolean;
}

export class ResponsesTrackDates {
	from: string;
	to: string;
}

export class ResponsesStudent {
	firstName: string;
	lastName: string;
	tests: ResponsesTest[];
}

export class ResponsesTest {
	testID: number;
	testName: string;
	questionCount: number;
	answers: ResponsesAnswerModel[];
	scores: ResponsesScore[];
	testSessions: ResponsesTestSession[];
	totalCorrect: number;
	type: string;
	summaryNotes: string;
}

export class ResponsesAnswerModel {
	text: string;
	correct?: boolean;
	note: string;
}

export class ResponsesGrade {
	id: number;
	name: string;
	description: string;
	color: string;
}

export class ResponsesScore {
	raw: number;
	testDate?: string;
	grade: string;
	color: string;
}

export class ResponsesTestSession {
	date: string;
	correctAnswers: number;
}


export class ResponsesSubjectsGet {
	selectedSubjectID: number;
	subjects: Subject[];
}


export class FlagItem {
	name: string;
	value: boolean;
}

export class ResponsesAvailableTestsInit {
	subjectTests: SubjectTest[];
}

export class SubjectTest {
	subjectID: number;
	testIDs: number[];
}

export class RequestsReportDownload {
	reportGuid: string;
	currentPeriod: boolean;
	includeGradeScore: boolean;
	notTestedDisplayValue: string;
	includeNotes: boolean;
	includeSummaryNotes: boolean;
	printInColor: boolean;
	showBaseline: boolean;
	zip: boolean;
}

export class RequestsAvailableTestsInit {
	subjects: Subject[];
	globalSchoolYearID: number;
	searchBy: SearchTestResults;
	hierarchy: HierarchySnapshot;
}

export class SearchTestResults {
	byClassID?: number;
	byGroupID?: number;
	bySpecialistGroupID?: number;
	byStudentID?: number;
}
