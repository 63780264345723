import {isNull} from 'underscore';
import {
	PageSectionsState,
	StudentSectionState,
	SubjectBelongsTo,
	SubjectTabId,
	UpdateStudentPanelStateArgs,
} from '../types';
import {BehaviorSubject} from 'rxjs';
import {defaultPageSectionState} from '../constants';
import {BaseService} from '@esgi/core/service';
import {Class, Group, Student, SubjectTab} from '@esgi/main/libs/store';
import {V2TeachersPagesHomeCommonController} from '@esgi/contracts/esgi';
import {EventBusManager} from '@esgillc/events';
import {HomeState} from '@esgi/contracts/esgi/types/sso.accounts/models/user-page-states/teacher/home-state';
import {GroupingType} from '@esgi/main/features/untested-students-report';
import {DataTestsService} from './data-tests-service';
import {StudentsPanelTabID} from '@esgi/main/features/teacher/students-panel';

export class DataStateService extends BaseService {
	public isLoadedData$ = new BehaviorSubject(false);

	public sectionsState$ = new BehaviorSubject<PageSectionsState | null>(null);

	public selectedSubjectId$ = new BehaviorSubject<SubjectTab['id'] | null>(null);
	public activeSubjectTab$ = new BehaviorSubject(SubjectTabId.All);

	public selectedClassId$ = new BehaviorSubject<Student['id'] | null>(null);
	public selectedGroupId$ = new BehaviorSubject<Student['id'] | null>(null);
	public groupingFilterType = new BehaviorSubject<GroupingType>(GroupingType.Tests);
	public showAllStudents = new BehaviorSubject<boolean>(true);
	public lastWeekList = new BehaviorSubject<number[]>([1, 2, 3]);
	public selectedLastWeek = new BehaviorSubject<number>(1);

	public testsService = new DataTestsService(this.selectedLastWeek, this.showAllStudents, this.selectedClassId$, this.selectedGroupId$, this.selectedSubjectId$);

	private eventBus = new EventBusManager();
	private stateController = new V2TeachersPagesHomeCommonController();

	constructor() {
		super();

		this.stateController.init().subscribe({
			next: ({state}) => {
				if (isNull(state)) {
					this.sectionsState$.next(defaultPageSectionState);
					return;
				}
				this.sectionsState$.next(state);
				this.iniSubjectSectionState(state);
				this.iniStudentSectionState(state);
			},
			complete: () => {
				this.isLoadedData$.next(true);
			},
		});
	}

	private getSectionsState() {
		const currentSectionsState = this.sectionsState$.value;

		if (isNull(currentSectionsState)) {
			throw new Error('sectionsState is null.');
		}

		return currentSectionsState;
	}

	private get subjectStateKeysWithCorrespondTabIds(): [SubjectBelongsTo, SubjectTabId][] {
		return [
			['district', SubjectTabId.District],
			['school', SubjectTabId.School],
			['personal', SubjectTabId.Personal],
		];
	}

	private get studentStateKeysWithCorrespondTabIds(): [
		keyof Pick<StudentSectionState, 'classes' | 'groups'>,
		StudentsPanelTabID,
	][] {
		return [
			['classes', StudentsPanelTabID.Classes],
			['groups', StudentsPanelTabID.Groups],
		];
	}

	public setGroupingType(type: GroupingType) {
		this.groupingFilterType.next(type);
	}

	public setShowAllStudents(checked: boolean) {
		this.showAllStudents.next(checked);
	}

	public setLastWeek(value: number) {
		if (!value) {
			return;
		}
		this.selectedLastWeek.next(value);
	}

	private iniSubjectSectionState({subjectSection: subjectSectionState}: HomeState) {

		const selectedSubjectIds: SubjectTab['id'][] = [];

		let activeSubjectTabId: SubjectTabId | null = null;

		this.subjectStateKeysWithCorrespondTabIds.forEach(([key, subjectId]) => {
			const {selectedIDs, opened} = subjectSectionState[key];

			selectedSubjectIds.push(...selectedIDs);

			if (opened) {
				activeSubjectTabId = subjectId;
			}
		});

		if (selectedSubjectIds.length) {
			this.selectedSubjectId$.next(selectedSubjectIds[0]!);
		}

		this.activeSubjectTab$.next(activeSubjectTabId ?? SubjectTabId.All);
	}

	public setSelectedSubjectId(subjectId: SubjectTab['id'], belogsTo: SubjectBelongsTo) {
		const {subjectSection: subjectSectionStateValue} = this.getSectionsState();

		const subjectSectionState = structuredClone(subjectSectionStateValue);

		this.subjectStateKeysWithCorrespondTabIds.forEach(([key]) => {
			subjectSectionState[key].selectedIDs = key === belogsTo ? [subjectId] : [];
		});

		this.selectedSubjectId$.next(subjectId);
	}

	public setActiveSubjectTab(activeSubjectTab: SubjectTabId) {
		const {subjectSection: subjectSectionStateValue} = this.getSectionsState();

		const subjectSectionState = structuredClone(subjectSectionStateValue);

		this.activeSubjectTab$.next(activeSubjectTab);

		this.subjectStateKeysWithCorrespondTabIds.forEach(([key, subjectId]) => {
			subjectSectionState[key].opened = activeSubjectTab === subjectId;
		});
	}

	private iniStudentSectionState({studentSection: studentSectionState}: HomeState) {
		this.studentStateKeysWithCorrespondTabIds.forEach(([key, studentTabId]) => {
			const {selectedIDs, opened} = studentSectionState[key];

			if (selectedIDs.length) {
				switch (key) {
					case 'classes':
						this.selectedClassId$.next(selectedIDs[0]!);
						break;
					case 'groups':
						this.selectedGroupId$.next(selectedIDs[0]!);
						break;
				}
			}
		});
	}

	private updateStudentPanelState({classes, groups, students}: UpdateStudentPanelStateArgs) {
		const {studentSection: studentSectionStateValue} = this.getSectionsState();

		const studentSectionState = structuredClone(studentSectionStateValue);

		studentSectionState.classes.selectedIDs = classes.requestData;
		studentSectionState.groups.selectedIDs = groups.requestData;
		studentSectionState.students.selectedIDs = students.requestData;

		this.selectedClassId$.next(classes.stateData);
		this.selectedGroupId$.next(groups.stateData);
	}

	public setSelectedClassId(classId: Class['id']) {
		this.updateStudentPanelState({
			classes: {
				requestData: [classId],
				stateData: classId,
			},
			groups: {
				requestData: [],
				stateData: null,
			},
			students: {
				requestData: [],
				stateData: null,
			},
		});
	}

	public setSelectedGroupId(groupId: Group['id']) {
		this.updateStudentPanelState({
			classes: {
				requestData: [],
				stateData: null,
			},
			groups: {
				requestData: [groupId],
				stateData: groupId,
			},
			students: {
				requestData: [],
				stateData: null,
			},
		});
	}

	public override dispose() {
		super.dispose();
		this.stateController.dispose();
		this.eventBus.destroy();
	}
}
