import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';

interface BodyHeaderProps {
	isLinked: boolean;
	districtName: string;
	expirationDate: string;
	canAllowRenewByCC: boolean;
	showRenewButton: boolean;
	schoolName: string;
	onNameClick: () => void
}


export function BodyHeader({
	                           isLinked,
	                           districtName,
	                           expirationDate,
	                           canAllowRenewByCC,
	                           showRenewButton,
	                           schoolName,
	                           onNameClick,
}: BodyHeaderProps) {

	return <div className={styles.bodyHeader} data-cy={'body-header'}>
		<div>
			<div className={styles.labelRow}>
				<label className={styles.sizedLabel}>District</label>
				<div data-cy={'district-row'}>
					{isLinked ?
						<label className={styles.labelValue}>{districtName}</label>
						:
						<Buttons.Link onClick={onNameClick} className={styles.linkButton}>{districtName}</Buttons.Link>
					}
				</div>
			</div>
			<div className={styles.labelRow}>
				<label className={styles.sizedLabel}>School</label>
				<div data-cy={'school-row'}>
					{isLinked ?
						<label className={styles.labelValue}>{schoolName}</label>
						:
						<Buttons.Link onClick={onNameClick} className={styles.linkButton}>{schoolName}</Buttons.Link>
					}
				</div>
			</div>
		</div>
		<div className={styles.labelInfoRow}>
			<label>Linked to District:</label>
			<span data-cy={'is-linked-to-district'} className={styles.yesNoText}>{isLinked ? 'YES' : 'NO'}</span>
			<OnHoverTooltip message='Help'>
				<a
					href='https://support.esgisoftware.com/hc/en-us/articles/209160866-Sharing-Tests'
					target='_blank' rel='noreferrer'>
					<QuestionMarkIcon className={styles.questionIcon}/>
				</a>
			</OnHoverTooltip>
		</div>
		<div className={styles.expirationInfo}>
			<div data-cy={'expiration-and-renew'}>
				Expiration Date {expirationDate}
				{showRenewButton &&
					<Buttons.Contained onClick={() => {
						if (!canAllowRenewByCC) {
							location.href = `/renewal?page=activationcode`;
						}
						location.href =`/renewal/`;
					}}>RENEW</Buttons.Contained>}
			</div>
		</div>
	</div>;
}
