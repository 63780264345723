import {CheckboxGroupModel} from './types';

export const groups: CheckboxGroupModel[] = [
	[
		{
			key: 'showBaseline',
			label: 'Show Baseline',
		},
		{
			key: 'includeSummaryNotes',
			label: 'Show Summary',
		},
		{
			key: 'includeQuestionNotes',
			label: 'Show Notes',
		},
	],
	[
		{
			key: 'carryScoresForward',
			label: 'Carry Forward',
			hintTo: 'https://support.esgisoftware.com/hc/en-us/articles/360024667492-Student-Detail-Report',
		},
	],
];
