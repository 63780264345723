import {HttpClient} from '@esgi/api';
import React, {createRef, RefObject} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {Subject} from 'rxjs';
import {first} from 'rxjs/operators';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {HideGetStartedEvent, UnHideGetStartedEvent} from 'shared/modules/get-started/events';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {Hierarchy} from 'modules/hierarchy/hierarchy';
import {HierarchyModalHandler} from 'modules/hierarchy/hierarchy-modal-handler';
import {HierarchyDataService} from 'modules/hierarchy/services/hierarchy-data-service';
import {Footer} from 'shared/page/footer/footer';
import {CenterPanel} from 'shared/page/layout/center-panel';
import Layout from 'shared/page/layout/layout';
import {SidePanel} from 'shared/page/layout/side-panel/side-panel';
import {ErrorBoundary} from '@esgi/core/react';
import {EventBusManager, dispatchAppEvent} from '@esgillc/events';
import {userStorage, UserType} from '@esgi/core/authentication';
import {InlineManual} from 'shared/tools/inline-manual/inline-manual';
import {debounce} from '@esgi/deprecated/knockout';
import PieCharts from './components/pie-charts/pie-charts';
import {
	ManageSubjectsAndTestsPanel,
} from './components/manage-subject-tabs-and-tests-panel/manage-subject-tabs-and-tests-panel';
import ReportsPanel from './components/report-button/reports-panel';
import RightPanel from './components/right-panel/right-panel';
import {ITrackModel} from './components/school-year-selector/api';
import SchoolYearSelector from './components/school-year-selector/school-year-selector';
import SubjectTabs from './components/subject-tabs/subject-tabs';
import {CrossTabEventsService} from './services/events-service/cross-tab-events-service';
import EventsService from './services/events-service/events-service';
import PieChartsService from './services/pie-charts-service';
import {SubjectModel} from './services/subjects-service/models';
import './styles.less';
import SubjectsService from './services/subjects-service/subjects-service';
import {StudentSavedEvent} from 'modules/forms/students-form/events';
import styles from './home.module.less';
import {DateTools} from 'global/utils/date-utils';
import {Loader} from '@esgillc/ui-kit/loader';
import PreAssessLoginAlert from 'shared/alert/pre-assess-login-alert/pre-assess-login-alert';

class State {
	globalSchoolYearId: number = 0;
	hierarchy: HierarchyInstance = null;
	hierarchyUpdating: boolean = false;
	rightPanelFixed: boolean = true;
	preloaderLifted: boolean;
	subjectsLoaded: boolean = false;
	rightPanelLoaded: boolean = false;
	selectedSubject: SubjectModel;
	canTest: boolean = false;
	testCount: number;
	sidePanelOpened: boolean = true;
	currentTrack: ITrackModel;
	globalSchoolYearLoading: boolean = false;

	constructor() {
		const currentUser = userStorage.get();
		this.globalSchoolYearId = currentUser?.globalSchoolYearID;
	}
}

export default class HomePage extends React.Component<any, State> {
	public override readonly state = new State();
	private onDestroy$: Subject<void> = new Subject();
	private centerHeight: number;
	private eventBus = new EventBusManager();
	private currentUser = userStorage.get();
	private organizeTestsClicked: boolean = window.location.search && (new URLSearchParams(window.location.search)).get('organizeTests') === 'true' || false;
	private isPaLoggedIn: boolean = false;
	private readonly hierarchyServiceRef: RefObject<HierarchyDataService> = createRef();
	private readonly subjectsService: SubjectsService = new SubjectsService();
	private readonly pieChartsService: PieChartsService = new PieChartsService();
	private readonly eventsService: EventsService = new EventsService(this.subjectsService, this.pieChartsService, this.hierarchyServiceRef);
	private readonly crossTabEventsService: CrossTabEventsService = new CrossTabEventsService(this.subjectsService, this.pieChartsService, this.hierarchyServiceRef);

	public componentDidMount(): void {
		//TODO Rewrite to props callback
		this.eventBus.subscribe(StudentSavedEvent, (args: StudentSavedEvent) => {
			this.dispatchRenderGetStartedEvent(true);
		});
		this.centerHeight = $('.center-main').height();
		this.handlePaLogin();
		this.subjectsService.selectedSubject$().subscribe(s => this.setState({selectedSubject: s}));
		this.subjectsService.subjectsReloaded$().pipe(first()).subscribe(() => this.setState({subjectsLoaded: true}));
	}

	public componentDidUpdate(prevProps: any, prevState: Readonly<State>, snapshot?: any): void {
		const centerMainHeight = $('.center-main').height();
		if (this.centerHeight !== centerMainHeight) {
			this.centerHeight = centerMainHeight;
		}
		this.dispatchRenderGetStartedEvent(false);
	}

	public componentWillUnmount(): void {
		this.subjectsService.destroy();
		this.pieChartsService.destroy();
		this.eventsService.destroy();
		this.crossTabEventsService.destroy();
		this.onDestroy$.next();
		InlineManual.destroy();
	}

	public render() {
		return <div className='home'>
			<Loader show={this.state.globalSchoolYearLoading} fullscreen={true}/>
			<Layout
				loaded={[this.state.hierarchy != null, this.state.subjectsLoaded, this.state.rightPanelLoaded]}
				preloaderLifted={() => {
					this.setState({preloaderLifted: true});
					this.pageShown();
				}}>
				{this.isPaLoggedIn && <PreAssessLoginAlert onClose={() => this.setPaFalse()}/>}
				<SidePanel collapsable toggled={(opened) => this.setState({sidePanelOpened: opened})}>
					<SchoolYearSelector globalSchoolYearChanged={id => this.setState({globalSchoolYearId: id})}
					                    hierarchy={this.state.hierarchy}
					                    trackChanged={(track) => this.setState({currentTrack: track})}
					                    selectedGlobalSchoolYearId={this.state.globalSchoolYearId}
					                    onLoading={(v) => this.setState({globalSchoolYearLoading: v})}/>
					<Hierarchy onHierarchyChanged={(c) => this.setState({hierarchy: c})}
					           hierarchyServiceRef={this.hierarchyServiceRef}/>
					{this.state.hierarchy && <HierarchyModalHandler hierarchyState={this.state.hierarchy}
					                                                schoolID={this.state.hierarchy.classic.schoolID}
					                                                schools={this.hierarchyServiceRef.current.hierarchyState$.value.schools}/>}
				</SidePanel>
				<CenterPanel>
					{this.state.hierarchy && <h2 className='hierarchy-title'>{this.state.hierarchy.title}</h2>}
					<ErrorBoundary fillSpace>
						<SubjectTabs subjectsService={this.subjectsService}/>
					</ErrorBoundary>
					<div id='subject-context-menu-holder'/>
					<div className='main-panel'>
						<div className='charts-container'>
							<div className={join('center-main', styles.centerPanel)}>
								{this.state.hierarchy && this.renderPieCharts()}
							</div>
							<Footer/>
						</div>
						{this.renderRightPanel(this.state.preloaderLifted)}
					</div>
				</CenterPanel>
			</Layout>
			<BackgroundDownloadManager/>
		</div>;
	}

	private runInlineManual() {
		InlineManual.load(this.currentUser, '5b0427080af09051077710e09e679ac1', true);
	}

	private pageShown() {
		this.runInlineManual();

		if (!this.currentUser.timeZone) {
			this.updateTimeZone();
		}
		this.dispatchRenderGetStartedEvent(false);
	}

	private updateTimeZone() {
		setTimeout(() => {
			try {
				const browserDateTime = DateTools.toServerString(new Date(), true);

				let browserTimeZone = null;
				try {
					browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				} catch {
					// swallow
				}

				HttpClient.default.ESGIApi.post<{ TimeZoneID: string }>('home/layout', 'auto-set-time-zone', {
					browserTimeZone: browserTimeZone,
					browserDateTime: browserDateTime,
				}).withCustomErrorHandler((response, errorHandlingStrategy) => {
					errorHandlingStrategy.stopPropagation();
				}).subscribe(r => {
					this.currentUser.timeZone = r.TimeZoneID;
				});
			} catch (e) {
				console.warn(e);
			}
		}, 50);
	}

	private handlePaLogin() {
		const isPaLoggedIn = sessionStorage.getItem('paLoggedIn');
		if (isPaLoggedIn && isPaLoggedIn === 'true') {
			this.isPaLoggedIn = true;
		}
	}

	private setPaFalse() {
		this.isPaLoggedIn = false;
		sessionStorage.removeItem('paLoggedIn');
	}

	@debounce()
	private dispatchRenderGetStartedEvent(createdFirstStudent?: boolean) {
		if (this.state.hierarchy
			&& this.currentUser.canEditStudents
			&& userStorage.get().isLoggedByCredentials
			&& (this.currentUser.userType === UserType.T ||
				this.currentUser.userType === UserType.ISD || this.currentUser.userType === UserType.ISS
				|| this.currentUser.userType === UserType.PA)
			&& this.state.sidePanelOpened
			&& this.filteredStudentsCount() === 0
			&& !createdFirstStudent
		) {
			dispatchAppEvent(UnHideGetStartedEvent);
		} else {
			dispatchAppEvent(HideGetStartedEvent);
		}
	}

	private filteredStudentsCount(): number {
		if (!this.state.hierarchy) {
			return 0;
		}
		let students = this.state.hierarchy.students;
		if (this.state.hierarchy.mode === HierarchyMode.Classic) {
			const selected = this.state.hierarchy.classic.selected;
			switch (selected.level) {
				case ClassicHierarchyLevel.School:
					students = students.filter(s => s.schoolID === selected.levelID);
					break;

				case ClassicHierarchyLevel.Class:
					students = students.filter(s => s.classes.some(c => c === selected.levelID));
					break;

				case ClassicHierarchyLevel.Teacher:
					students = students.filter(s => s.teacherID === selected.levelID);
					break;

				default:
					break;
			}
		}

		if (this.state.hierarchy.mode === HierarchyMode.Specialist) {
			const selected = this.state.hierarchy.specialist.selected;
			switch (selected.level) {
				case SpecialistHierarchyLevel.Group:
					students = students.filter(s => s.specialistGroups.some(g => g === selected.levelID));
					break;

				default:
					break;
			}
		}
		return students.length;
	}

	private renderPieCharts() {
		return <ErrorBoundary fillSpace>
			<PieCharts hierarchy={this.state.hierarchy.snapshot}
			           disableCards={this.state.hierarchyUpdating}
			           rightPanelFixed={this.state.rightPanelFixed}
			           onReloaded={(info) => this.setState({canTest: info.canTest, testCount: info.testCount})}
			           pieChartsService={this.pieChartsService}/>
		</ErrorBoundary>;
	}

	private renderRightPanel(loaded: boolean) {
		return <RightPanel>
			<ManageSubjectsAndTestsPanel
				subject={this.state.selectedSubject}
				hierarchy={this.state.hierarchy}
				openManageSubjectsInitially={loaded && this.organizeTestsClicked}
			/>
			{<ReportsPanel
				globalSchoolYearID={this.state.globalSchoolYearId}
				canTest={this.state.canTest}
				hierarchy={this.state.hierarchy}
				subject={this.state.selectedSubject}
				loaded={() => this.setState({rightPanelLoaded: true})}
				autoTrack={this.state.currentTrack ? this.state.currentTrack.autoTrack : false}
				visible={!!this.state.testCount}
				noStudents={this.filteredStudentsCount() == 0}
				trackChanged={(track) => {
					this.setState({currentTrack: track});
				}}
			/>}
		</RightPanel>;
	}
}
