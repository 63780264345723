(function() {
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-05Y0Y2VNJE';
  script.async = true;
  document.head.appendChild(script);
})();

(function(){
  window.gaTrackPageView= function (page, title) {
    if (window.gtag) {
      if (page) {
        gtag('event', 'page_view', {page: page, title: title});
      }
    }
  }
})();
