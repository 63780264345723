import {useServiceFactory} from '@esgi/core/service';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import {useEffect, useMemo, useState} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {tap} from 'rxjs/operators';
import {TestType} from '@esgi/core/enums';
import {SessionStatusAlert} from '../../../../kit/session-status-alert';
import {TestSessionStatus} from '../../../../kit/enums';
import {QuestionModel} from '../../../../kit/models';
import {TestIntro} from '../../../../kit/test-intro';
import {convertToQuestionModel, mapToEnum} from '../../../../kit/utils';
import {ScoreService} from '../../service';
import {Summary} from '../../types';
import {ScoreSlider} from './slider/score-slider';
import {ShareSyncService} from '../../../../kit/share-screen';
import {TestSavedEvent} from '../../../../kit/events';

interface Props {
	service: ScoreService;
	shareSyncService: ShareSyncService;
	onClosed: () => void;
	onFinished: (summary: Summary) => void;
}

enum Screen {
	None,
	Intro,
	Slider,
}

export function ScoreFlow({service, shareSyncService, ...props}: Props) {
	const testingModel = useBehaviorSubject(service.testingModel);

	const [screenType, setScreenType] = useState(Screen.None);
	const [showTestSessionStatusAlert, setShowTestSessionStatusAlert] = useState(false);

	const question = useMemo<QuestionModel>(
		() => convertToQuestionModel(testingModel.question, testingModel.testID),
		[testingModel],
	);
	const {testID: testId} = testingModel;

	useEffect(() => {
		if (testingModel.testIntro) {
			setScreenType(Screen.Intro);
		} else {
			setScreenType(Screen.Slider);
		}
	}, []);

	useEffect(() => {
		if (screenType === Screen.Slider) {
			shareSyncService.start(0, [question], TestType.Score);
		}
	}, [screenType]);

	useEffect(() => {
		const status = mapToEnum(testingModel.testSessionStatus, TestSessionStatus);
		if (status > 0) {
			setShowTestSessionStatusAlert(true);
		}
	}, [testingModel.testSessionStatus]);

	const onSave = (correct: number, notes: string, duration: number) => {
		return service.finishTest(correct, notes, duration)
			.pipe(tap((resp) => {
				dispatchAppEvent(
					TestSavedEvent,
					new TestSavedEvent({
						testID: testingModel.testID,
						studentID: testingModel.studentID,
						correct,
						testSessionID: resp.testSessionID,
					}),
				);
				props.onFinished({
					testSessionID: resp.testSessionID,
					correct,
					incorrect: testingModel.totalPossible - correct,
					total: testingModel.totalPossible,
					notes,
				});
				shareSyncService.syncSummary(
					testingModel.totalPossible,
					correct,
					testingModel.showTestSessionResults,
				);
			})).asObservable();
	};

	return (
		<Switch test={screenType}>
			<Case value={Screen.Intro}>
				<TestIntro
					testID={testId}
					testName={testingModel.testName}
					studentName={testingModel.studentName}
					testIntroID={testingModel.testIntro?.id}
					bucketName={testingModel.bucketName}
					isWhiteBackground={testingModel.isWhiteBackground}
					modifyTicks={testingModel.testIntro?.modifyTicks}
					pregenerated={testingModel.testIntro?.pregenerated}
					onStart={() => setScreenType(Screen.Slider)}
					onCancel={props.onClosed}
					onClose={props.onClosed}
				/>
			</Case>
			<Case value={Screen.Slider}>
				<ScoreSlider
					service={service}
					whiteBackground={testingModel.isWhiteBackground}
					question={question}
					onSave={onSave}
					onClose={props.onClosed}
				/>
			</Case>
			{showTestSessionStatusAlert &&
				<SessionStatusAlert
					status={mapToEnum(testingModel?.testSessionStatus, TestSessionStatus)}
					onConfirm={props.onClosed}
					studentName={testingModel.studentName}
					testName={testingModel.testName}
				/>
			}
		</Switch>
	);
}
