import {HttpClient, ObservableBuilder} from '@esgi/api';
import {IInitResponse} from './models';

export class UsageReportApi {
	private static readonly controllerName = 'reports/usage';

	static init = (request: InitRequest): ObservableBuilder<IInitResponse> => {
		return HttpClient.default.ESGIApi.get<IInitResponse>(UsageReportApi.controllerName, 'init', {
      DistrictID: request.districtId,
      SchoolsGroupID: request.schoolsGroupID,
      GlobalSchoolYearID: request.globalSchoolYearId,
      TeachersGroupID: request.teachersGroupID,
    });
	};

	static removeCache = (cacheKey): ObservableBuilder<void> => {
		return HttpClient.default.ESGIApi.post(UsageReportApi.controllerName, 'remove-cache', {
			CacheKey: cacheKey,
		});
	};
}

export interface InitRequest {
  districtId: number;
  globalSchoolYearId: number;
  schoolsGroupID: number;
  teachersGroupID: number;
}
