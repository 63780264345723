/* eslint-disable @typescript-eslint/member-ordering */
import {BaseService} from '@esgi/core/service';
import {
	AssignmentNameFormGroup,
	SaveRequestParameters,
	SaveResponse,
	SubjectItem,
	TestFilter,
	TestItem,
} from '../types/types';
import {BehaviorSubject, Observable, debounceTime, map, of, skip, switchMap, tap} from 'rxjs';
import {ElementStatus, FormControl, FormGroup, Validator, Validators} from '@esgillc/ui-kit/form';
import {SsoTracker} from '@esgi/core/tracker';
import {HttpClient} from '@esgi/api';
import {isEmpty} from 'underscore';

const allSubjectsOption: TestFilter = {label: 'All', value: null};

export abstract class CreateAssignmentService extends BaseService {
	public savingInProgress = new BehaviorSubject<boolean>(false);

	public tests = new BehaviorSubject<TestItem[]>([]);
	public selectedTests = new BehaviorSubject<TestItem[]>([]);
	public selectedTestsIds = new BehaviorSubject<TestItem['id'][]>([]);

	public testDetailsId = new BehaviorSubject<TestItem['id'] | null>(null);

	public subjectsForModal = new BehaviorSubject<SubjectItem[]>([]);

	public isFirstStepValid = new BehaviorSubject<boolean>(false);
	public secondStepInvalid = new BehaviorSubject<boolean>(false);

	public testsFilters = new BehaviorSubject<TestFilter[]>([]);
	public selectedTestsFilters = new BehaviorSubject<TestFilter[]>([allSubjectsOption]);

	protected assignmentID: number | undefined;

	public nameFormGroup: AssignmentNameFormGroup = new FormGroup({
		name: new FormControl('', {validators: [Validators.required()]}),
		description: new FormControl(''),
	});

	public validateSecondStep() {
		this.secondStepInvalid.next(this.selectedTestsIds.value.length === 0);
	}

	public deselectTest(id: TestItem['id']) {
		this.selectedTestsIds.next(this.selectedTestsIds.value.filter((studentId) => studentId !== id));
	}

	public onTestToggle(id: TestItem['id']) {
		if (this.selectedTestsIds.value.includes(id)) {
			this.deselectTest(id);
		} else {
			this.selectedTestsIds.next([...this.selectedTestsIds.value, id]);
		}
	}

	public showTestDetails(id: TestItem['id']) {
		this.testDetailsId.next(id);
	}

	public hideTestDetails() {
		this.testDetailsId.next(null);
	}

	public selectFilter(filter: TestFilter[]) {
		this.selectedTestsFilters.next(filter);
	}

	protected startInitResponse(assignmentID?: number) {
		this.selectedTestsIds.pipe(skip(1)).subscribe(() => {
			this.validateSecondStep();
		});

		this.tests.subscribe((tests) => {
			const subjects = tests
				.flatMap((t) => t.subjects)
				.filter((s, i, self) => self.findIndex((ss) => ss.id === s.id) === i)
				.map((s) => ({label: s.name, value: s}));
			this.testsFilters.next([allSubjectsOption, ...subjects]);
		});

		this.selectedTestsIds.subscribe(() => {
			this.selectedTests.next(this.tests.value.filter((test) => this.selectedTestsIds.value.includes(test.id)));
		});

		this.nameFormGroup.controls.name.onChanged.subscribe((v) => {
			this.isFirstStepValid.next(this.nameFormGroup.controls.name.status === ElementStatus.valid);
		});

		if (assignmentID) {
			this.assignmentID = assignmentID;
		}
	}

	protected createAssignmentExtractedData({
		assignmentName,
		assignmentDescription,
		selectedTestsIds,
		selectedTestsFilters,
	}: {
		assignmentName: string;
		assignmentDescription: string | undefined;
		selectedTestsIds: number[];
		selectedTestsFilters: TestFilter[] | undefined;
	}) {
		this.nameFormGroup.controls.name.value = assignmentName;

		if (assignmentDescription) {
			this.nameFormGroup.controls.description.value = assignmentDescription;
		}

		this.selectedTestsIds.next(selectedTestsIds);

		if (selectedTestsFilters) {
			this.selectedTestsFilters.next(selectedTestsFilters);
		}
	}

	protected nameUniqueValidator(httpClient: HttpClient, initialName: string | undefined): Validator<any> {
		return function (validationChain) {
			return validationChain.pipe(
				debounceTime(500),
				switchMap((value) => {
					if (!value.field.currentValue || value.field.currentValue === initialName) {
						return of(value);
					}
					return httpClient.ESGIApi.get<{
						valid: boolean;
					}>('assignments/wizard', 'validate-name', {
						name: value.field.currentValue,
					})
						.pipe(
							map((res) => {
								if (!res.valid) {
									value.errors.push('unique');
								}
								return value;
							}),
						)
						.asObservable();
				}),
			);
		};
	}

	protected trackNotDraftAssignmentEvent(assignmentID: number): void {
		SsoTracker.trackEvent({
			trackingEvent: 'AssignmentCreated',
			data: assignmentID,
		});
	}

	public abstract save(data: SaveRequestParameters): Observable<SaveResponse>;

	protected createAssignment(data: SaveRequestParameters) {
		return this.httpClient.ESGIApi.post<SaveResponse>('assignments/wizard', 'create', data)
			.pipe(
				tap(({assignmentID}) => {
					if (!isEmpty(data.testIDs) && !isEmpty(data.studentIDs)) {
						this.trackNotDraftAssignmentEvent(assignmentID);
					}
				}),
			)
			.asObservable();
	}

	protected updateAssignment(data: SaveRequestParameters) {
		return this.httpClient.ESGIApi.post<SaveResponse>('assignments/edit', 'update', {
			...data,
			assignmentID: this.assignmentID,
		});
	}
}
