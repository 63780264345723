import {ComponentProps, forwardRef} from 'react';
import {ElementStatus, useFormElementContext} from '../../../../form';
import {RadioGroup as BaseRadioGroup} from '../../../../controls/radio-group';

type RadioGroupItemProps = ComponentProps<typeof BaseRadioGroup.Item>;

export const RadioGroupItem = forwardRef<HTMLButtonElement, RadioGroupItemProps>(({
	children,
	value,
	...props
}, ref) => {

	const {status} = useFormElementContext();

	const commonProps = {
		disabled: status === ElementStatus.disabled,
		value,
		ref,
		...props,
	};

	return <BaseRadioGroup.Item {...commonProps}>
		{children}
	</BaseRadioGroup.Item>;
});
