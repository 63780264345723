import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

interface Props {
	questionsCount: number;
	onClick: () => void;
	onCancel: () => void;
}

export function Randomize({onClick, onCancel, questionsCount}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	const onRandomizeClicked = () => {
		onClick();
		handleClose();
	};

	return <Alert modalManagerRef={modalRef}>
		<Alert.Header>
			<Title className={styles.title}>Randomize My Questions?</Title>
		</Alert.Header>
		<Alert.Body>
		<span data-cy='randomize' className={styles.text}>
			Are you sure you want to randomize the order of your {questionsCount} test questions?
		</span>
		</Alert.Body>
		<Alert.Footer>
			<div data-cy='randomize-footer'>
				<Buttons.Text onClick={handleClose}>CANCEL</Buttons.Text>
				<Buttons.Text onClick={onRandomizeClicked}>YES,RANDOMIZE</Buttons.Text>
			</div>
		</Alert.Footer>
	</Alert>;
}