import {StudentSpecialProgram} from '../../types';

export const specialProgramsData = [
	{
		label: 'Gifted/Talented',
		id: StudentSpecialProgram.GiftedTalented,
		value: false,
	},

	{
		label: 'SPED',
		id: StudentSpecialProgram.SPED,
		value: false,
	},

	{
		label: 'RTI',
		id: StudentSpecialProgram.RTI,
		value: false,
	},
	{
		label: 'ELL/ESL',
		id: StudentSpecialProgram.ELLESL,
		value: false,
	},
	{
		label: '504',
		id: StudentSpecialProgram.FiveOuFour,
		value: false,
	},
];
