import React, {useState, useMemo, useEffect} from 'react';
import {Loader} from '@esgillc/ui-kit/loader';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {SchoolYearTypeModel} from './types';
import TrackDatesList from 'modules/track-confirm/components/track-dates-list';
import styles from './styles.module.less';
import TrackConfirmService from 'modules/track-confirm/services/track-confirm-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useUser} from '@esgi/core/authentication';
import {ITrackModel} from 'pages/home/components/school-year-selector/api';

interface ITrackConfirmProps {
	trackConfirmService: TrackConfirmService;
	trackChanged?: (track: ITrackModel) => void;
}

export function TrackConfirm (props: ITrackConfirmProps) {
	const [schoolYearTypes, setSchoolYearTypes] = useState<SchoolYearTypeModel[]>([])
	const selectedSchoolYearType = useBehaviorSubject(props.trackConfirmService.selectedSchoolYearType)
	const userContext = useUser();
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const trackDateName = useMemo(() => selectedSchoolYearType?.name + ' Date Range', [selectedSchoolYearType?.name]);

	useEffect(() => {
		setIsLoading(true);
		props.trackConfirmService.init(userContext.userID, userContext.globalSchoolYearID)
			.subscribe(resp => {
				setSchoolYearTypes(resp.schoolYearTypes);
				props.trackConfirmService.trackID = resp.trackID;
				props.trackConfirmService.updateSelectedSchoolYearType(resp.schoolYearTypes.find(s => s?.id == resp.schoolYearTypeID));
				setIsLoading(false);
				props.trackChanged?.call({
					trackID: resp.trackID,
					autoTrack: false,
				});
			}, () => {
			});
	}, []);

	const onSelectSchoolYearType = (id) => {
		props.trackConfirmService.updateSelectedSchoolYearType(schoolYearTypes.find(s => s.id === id))
	}

	return (
		<div className={styles.trackConfirmForm}>
			<Loader show={isLoading}/>
			<div className={styles.trackTypeContainer}>
				<label className={styles.trackTypeLabel}>Track Type</label>
				<Dropdown
					value={[selectedSchoolYearType]}
					optionName='name'
		            setValue={(value) => onSelectSchoolYearType(value[0])}>
					{schoolYearTypes?.map(type => <Option key={`option-school-year-type-${type?.id}`} value={type?.id}>{type?.name}</Option>)}
				</Dropdown>
			</div>
			<div className={styles.trackDatesContainer}>
				<div className={styles.trackDatesLabel}>{trackDateName}</div>
				<TrackDatesList trackConfirmService={props.trackConfirmService}/>
			</div>
		</div>
	)
}
