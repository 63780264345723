import {useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Loader} from '@esgillc/ui-kit/loader';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import DataService from '../../../../../../services/data-service';
import styles from './add-selfassess-tests.module.less';
import {Body} from './components/body/body';

interface Props {
	dataService: DataService;
	closed: () => void;
	updateAddMoreTestsDisabled?: () => void;
}

export function AddSelfassessTests(props: Props) {
	const modal = useModal();
	const [selectedTests, setSelectedTests] = useState(() => props.dataService.availableTests.map(test => test.id));
	const [loading, setLoading] = useState(true);

	const onClose = useCloseModal(modal, props.closed);

	return <Modal modalManagerRef={modal} className={styles.modalContainer}>
		<Loader show={loading}/>
		<Modal.Header>
			<Title>Add Self-Assess Test(s)</Title>
			<div className={styles.studentNameContainer}>
				<OnHoverTooltip message='Student self-assess'>
					<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle cx='15' cy='15' r='12' fill='white'/>
						<path
							d='M22.4873 22.5H7.51267C7.74204 17.9883 11.0561 14.5 15 14.5C18.9439 14.5 22.258 17.9883 22.4873 22.5Z'
							fill='#BA5ACB' stroke='white'/>
						<circle cx='15' cy='11' r='5' fill='#FFA53D' stroke='white' strokeWidth='2'/>
						<circle cx='14.9615' cy='14.9615' r='13' stroke='#059BE0' strokeWidth='2'/>
					</svg>
				</OnHoverTooltip>
				<p className={styles.selectedStudent}>{props.dataService.selectedStudent.name}</p>
			</div>
			<CloseIcon onClick={onClose}/>
		</Modal.Header>
		<Modal.Body className={styles.modalBody}>
			<Body
				updateAddMoreTestsDisabled={props.updateAddMoreTestsDisabled}
				loading={setLoading}
				selectedTestIds={selectedTests}
				dataService={props.dataService}
				testIdsChanged={setSelectedTests}/>
		</Modal.Body>
		<Modal.Footer>
			<div className={styles.buttonsContainer}>
				<div>
					<Buttons.Contained onClick={onClose} disabled={loading}>
						DONE
					</Buttons.Contained>
				</div>
			</div>
		</Modal.Footer>
		<SnackbarLayout/>
	</Modal>;
}
