import {styled} from '@stitches/react';

export const CellStyled = styled('div', {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	fontSize: '13px',
});

export const CriteriaHeaderStyled = styled('div', {
	alignItems: 'center',
	justifyContent: 'center',
	fontSize: '12px',
	flexDirection: 'column',
	padding: '8px',

	'p': {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
	},
});

export const CountOfLevelsStyled = styled('p', {
	fontWeight: 'normal',
});

export const StudentTopHeaderColumn = styled('div', {
	fontSize: '12px',
	fontWeight: '600',
	padding: '8px',
});

export const ViewChartDisabledBlockStyled = styled('div', {
	cursor: 'default',
});

export const ColumnStyled = styled('div', {
	variants: {
		'criteriaColumn': {
			true: {
				width: '180px',
				height: '55px',
			},
		},
		'studentsColumn': {
			true: {
				minWidth: '250px',
				maxWidth: '350px',
				height: '55px',
			},
		},
		'resultsColumn': {
			true: {
				width: '100px',
				height: '55px',
			},
		},
	},
});