import $ from "jquery";

$.fn.mask = function (text?:string,delay?:number,className?:string) {
    $(this).each(function () {
        if (delay !== undefined && delay > 0) {
            const element = $(this);
            element.data("_mask_timeout", setTimeout(function () {
                $.maskElement(element, text, className)
            }, delay))
        } else {
            $.maskElement($(this), text, className)
        }
    });
    return this;
};
$.fn.unmask = function () {
    $(this).each(function () {
        $.unmaskElement($(this))
    });
    return this;
};
$.fn.isMasked = function () {
    return this.hasClass("masked")
};
$.maskElement = function (element:JQuery, c, z) {
    if (element.data("_mask_timeout") !== undefined) {
        clearTimeout(element.data("_mask_timeout"));
        element.removeData("_mask_timeout")
    }
    if (element.isMasked()) {
        $.unmaskElement(element)
    }
    if (element.css("position") == "static") {
        element.addClass("masked-relative")
    }
    element.addClass("masked");
    var e = $('<div class="loadmask' + (z ? ' ' + z : '') + '"></div>');
    if (navigator.userAgent.toLowerCase().indexOf("msie") > -1) {
        e.height(element.height() + parseInt(element.css("padding-top")) + parseInt(element.css("padding-bottom")));
        e.width(element.width() + parseInt(element.css("padding-left")) + parseInt(element.css("padding-right")))
    }
    if (navigator.userAgent.toLowerCase().indexOf("msie 6") > -1) {
        element.find("select").addClass("masked-hidden")
    }
    element.append(e);
    if (c !== undefined) {
        const b = $('<table><tr><td><div class="loadmask-msg" ><h1 class="animated-loading">' + c + (c ? '<span class="loading_dots"><span>.</span><span>.</span><span>.</span></span>' : '') + '</h1></div></td></tr></table>');
        e.append(b);
        b.show()
    }
};
$.unmaskElement = function (b) {
    if (b.data("_mask_timeout") !== undefined) {
        clearTimeout(b.data("_mask_timeout"));
        b.removeData("_mask_timeout")
    }
    b.find(".loadmask-msg,.loadmask").remove();
    b.removeClass("masked");
    b.removeClass("masked-relative");
    b.find("select").removeClass("masked-hidden")
}