import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onClose: () => void;
}

export function EndedSchoolYearAlert(props: Props) {
	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);

	return <Alert modalManagerRef={modal}>
		<Alert.Body>
			<span>
				You are in a school year that has ended. Please switch to the current year to test. Click <a
				href='https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Year'
				target='_blank'>here</a> for more information.
			</span>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={close}>OK</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
