import React from 'react';
import {Field, IFormControlValidatorResult, Validators, TextInput} from '@esgi/deprecated/elements';
import {TestType} from '@esgi/core/enums';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {OnHoverTooltip, ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {ShowValidation} from 'shared/modules/question-editor/form-events';
import './form.less';

export class Props extends SharedProps<State> {
	testType?: TestType;
	isNew: boolean;
}

export class State {
	questionName: Field = new Field('', {
		valid: false,
		message: 'Please enter a Question Name.',
	});
	directions: Field = new Field('', Validators.successfulValidation);
	stateStandardID?: number;
	stateStandard: string;
	changed: boolean;
	showValidation: boolean;
}

export class Form extends SharedComponent<State, Props> {
	directionsMaxLength: number = 240;
	initialState: State = new State();
	validationRef: HTMLLabelElement = null;

	componentDidMount() {
		this.initialState = {...this.state};
		
		this.subscribe(ShowValidation, (args: ShowValidation.Args) => {
			let newState = {...this.state};
			
			newState.questionName.touched = true;
			newState.showValidation = !args.formValid;

			this.setState(newState);
		});
	}
	
	private changed() {
		return this.state.stateStandardID !== this.initialState.stateStandardID
			|| this.state.directions.value !== this.initialState.directions.value
			|| this.state.questionName.value !== this.initialState.questionName.value;
	}

	updateQuestionName(value: string) {
		let newState = {...this.state};

		const requiredValidation = Validators.requriedValidator(value);
		const maxLengthValidation = Validators.maxLengthValidator(value, 128);

		let validation = Validators.successfulValidation;

		if (requiredValidation.valid && maxLengthValidation.valid) {
			newState.showValidation = false;
		}

		if (!requiredValidation.valid) {
			validation.valid = false;
			validation.message = 'Please enter a Question Name.';
		}

		if (!maxLengthValidation.valid) {
			newState.showValidation = true;
			value = this.state.questionName.value;
			validation.message = 'Question Name must not be greater than 128 characters.';
		}

		newState.questionName = {
			...this.state.questionName,
			value: value,
			validation: validation,
			touched: false,
		};

		this.updateInnerState(newState);
	}

	updateDirections(value: string, validation: IFormControlValidatorResult) {
		let newState = {...this.state};

		newState.directions = {
			...this.state.directions,
			value: value,
			validation: validation,
			touched: true,
		};

		this.updateInnerState(newState);
	}

	updateInnerState(state: State) {
		this.setState(state, () => this.setState({changed: this.changed()}));
	}

	removeStateStandart() {
		this.setState({
			stateStandardID: null,
			stateStandard: null,
			changed: true,
		});
	}

	render() {
		let isColumnDisplayed = this.props.testType !== TestType.Score || this.state.stateStandardID;
		return <div className='mx-question-editor-form'>
			{isColumnDisplayed && <div className='mx-editor-form-column'>
				{this.props.testType !== TestType.Score && <>
					<TextInput
						onEdit={(value, validation) => this.updateQuestionName(value)}
						placeholder='Enter name'
						touched={this.state.questionName.touched}
						validationResult={this.state.questionName.validation}
						className='mx-editor-form-text-input question-name'
						value={this.state.questionName.value}
						hideValidationTooltip={true}
						htmlLabel={(id) => {
							return <label ref={(ref) => this.validationRef = ref} className='top-label'
										  htmlFor={id}><span
								className={this.props.isNew ? 'star red' : 'star black'}>*</span> Question Name
								<OnHoverTooltip
									message='Question name appears on report results to help identify the correct and incorrect answers.'>
									<svg className='question-name-tooltip' width='10' height='10'
										 viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 8.5H4.5V7.5H5.5V8.5ZM6.535 4.625L6.085 5.085C5.725 5.45 5.5 5.75 5.5 6.5H4.5V6.25C4.5 5.7 4.725 5.2 5.085 4.835L5.705 4.205C5.89 4.025 6 3.775 6 3.5C6 2.95 5.55 2.5 5 2.5C4.45 2.5 4 2.95 4 3.5H3C3 2.395 3.895 1.5 5 1.5C6.105 1.5 7 2.395 7 3.5C7 3.94 6.82 4.34 6.535 4.625Z'
											fill='#828282'/>
									</svg>
								</OnHoverTooltip>
							</label>;
						}}
					/>
					{(this.validationRef && this.state.showValidation) &&
					<ValidationTooltip notHide={true} element={this.validationRef} placement={'right'}
									   container={this.validationRef}>
						{this.state.questionName.validation.message}
					</ValidationTooltip>}
				</> || null}
				{this.state.stateStandardID && <div className='editor-form-state-standart'>
					<span>Question Standard:</span>
					<OnHoverTooltip message={this.state.stateStandard}>
						<div className='editor-form-state-standart-value'>{this.state.stateStandard}</div>
					</OnHoverTooltip>
					{this.state.stateStandardID && <OnHoverTooltip message={'Clear'}>
						<a href='#' className='editor-form-state-standart-close'
						   onClick={() => this.removeStateStandart()}>
							<svg width='24' height='24' viewBox='0 0 48 48'>
								<g fill='none' fillRule='evenodd'>
									<rect width='48' height='48'/>
									<path stroke='#828282' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'
										  transform='matrix(-1 0 0 1 48 0)'/>
									<path stroke='#828282' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'/>
								</g>
							</svg>
						</a>
					</OnHoverTooltip>}
				</div>}
			</div> || null}
			<div className='mx-editor-form-column'>
				<TextInput
					value={this.state.directions.value}
					onEdit={(value, validation) => this.updateDirections(value, validation)}
					maxLength={this.directionsMaxLength}
					className='mx-editor-form-text-input directions'
					placeholder='ex.) What letter is this?'
					validator={(value: string) => Validators.maxLengthValidator(value, 240, 'Directions')}
					label='Directions to Student'
					touched={this.state.directions.touched}
				/>
				<div className='max-validation-container'><span>max # of characters 240</span></div>
			</div>
		</div>;
	}
}