import {ExpandablePanelIcon} from '@esgi/main/features/teacher/home';
import {ExpandablePanel} from '@esgi/ui/layout';
import {PropsWithChildren, useMemo} from 'react';
import {Setup} from '@esgi/ui/icons';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {testTypes} from '../../constants';
import {Administration, FilterScope} from '../../service/types';
import {TestExplorerService} from '../../service/service';
import {
	mappedGradeLevels,
	OptionItem,
	SelectableList,
	AddButton,
} from '@esgi/main/features/standards-drawer';
import {
	CheckboxesBox,
	DisabledCheckbox,
	ExpandablePanelContent,
	ExpandablePanelStyled,
	FiltersPanelHeader,
	FiltersPanelItem,
	FiltersPanelItems,
	Checkbox,
} from '@esgi/main/kits/common';

type Props = PropsWithChildren<{
	service: TestExplorerService;
	isPanelOpened: boolean;
	togglePanelOpened: VoidFunction;
	contentAreas: OptionItem[];
	selectedNotedSeries: OptionItem[];
	selectedNotedAuthors: OptionItem[];
	selectedStandards: OptionItem[];
	selectedStandardsIDs: number[];
	selectedGradeLevelIDs: number[];
	selectedContentAreaIDs: number[];
	selectedNotedSeriesIDs: number[];
	selectedNotedAuthorsIDs: number[];
	selectedAdministration: Administration;
	showHidden: boolean;
	selectedTestTypes: number[];
	isContentAreasLoading: boolean;
	onAddStandardClick: VoidFunction;
	onAddSeriesClick: VoidFunction;
	onAddAuthorsClick: VoidFunction;
	scope: FilterScope;
	dataCy?: string;
	isSmallScreen?: boolean;
}>;

export function FiltersPanel({
	service,
	isPanelOpened,
	isContentAreasLoading,
	dataCy = 'filters-panel',
	contentAreas,
	selectedGradeLevelIDs,
	selectedContentAreaIDs,
	selectedAdministration,
	showHidden,
	selectedStandardsIDs,
	selectedStandards,
	selectedNotedSeries,
	selectedNotedAuthors,
	selectedNotedSeriesIDs,
	selectedNotedAuthorsIDs,
	selectedTestTypes,
	onAddStandardClick,
	onAddSeriesClick,
	onAddAuthorsClick,
	scope,
	isSmallScreen,
	togglePanelOpened,
}: Props) {
	const gradeLevelsList = useMemo(() => {
		if (selectedStandards.length) {
			return mappedGradeLevels.map((gradeLevel) => ({
				...gradeLevel,
				disabled: true,
			}));
		}

		return mappedGradeLevels.map((gradeLevel) => ({
			...gradeLevel,
			disabled: false,
		}));
	}, [selectedStandards.length]);

	const contentAreasList = useMemo(() => {
		if (selectedStandardsIDs.length) {
			return contentAreas.map((contentArea) => ({
				...contentArea,
				disabled: true,
			}));
		}

		return contentAreas;
	}, [contentAreas, selectedStandardsIDs.length]);

	return (
		<ExpandablePanelStyled
			skeleton={isContentAreasLoading}
			open={!isSmallScreen || isPanelOpened}
			onClick={!isPanelOpened ? togglePanelOpened : undefined}
			isSmallScreen={isSmallScreen}
		>
			{({panelIsHovered}) => (
				<>
					{isSmallScreen && (
						<ExpandablePanel.Trigger
							onClick={(e) => {
								e.stopPropagation();
								togglePanelOpened();
							}}
						/>
					)}
					<ExpandablePanel.Placeholder dataCy={`${dataCy}-placeholder`}>
						<ExpandablePanelIcon
							fillColor={panelIsHovered ? 'secondary' : 'primary'}
							Icon={Setup}
						/>
					</ExpandablePanel.Placeholder>
					<ExpandablePanelContent dataCy={`${dataCy}-content`}>
						<FiltersPanelHeader
							isFiltersButtonDisabled={!service.isAnyFilterSelected}
							onFiltersReset={service.onFiltersReset}
						/>
						<OverlayScrollbarsComponent
							style={{height: 'calc(100% + 0px)'}}
							defer
							options={{scrollbars: {autoHide: 'leave'}}}
						>
							<FiltersPanelItems>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Standard'
									clearVisible={selectedStandardsIDs.length > 0}
									onListClear={service.standardsService.onStandardsClear}
								>
									<SelectableList
										items={selectedStandards}
										selectedItems={selectedStandardsIDs}
										onItemRemove={service.standardsService.onSelectedStandardRemove}
									/>
									<AddButton
										onClick={onAddStandardClick}
										label='Filter By Standard'
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Grade Level'
								>
									<SelectableList
										items={gradeLevelsList}
										selectedItems={selectedGradeLevelIDs}
										onClick={service.standardsService.onGradeLevelChange}
										wrapItems
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Content Area'
								>
									<SelectableList
										items={contentAreasList}
										selectedItems={selectedContentAreaIDs}
										onClick={service.standardsService.onContentAreaChange}
										wrapItems
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Test Type'
								>
									<SelectableList
										items={testTypes}
										selectedItems={selectedTestTypes}
										onClick={service.onTestTypeChange}
										wrapItems
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Administration'
								>
									<CheckboxesBox>
										<DisabledCheckbox
											data-cy='one-on-one-checkbox'
											value={Administration.OneToOne}
											label='One-on-one'
											checked
											onCheckedChange={() =>
												service.onAdministrationChange(Administration.OneToOne)
											}
											disabled
										/>
										<Checkbox
											data-cy='self-assess-checkbox'
											value={Administration.SelfAssess}
											label='Self-Assess'
											checked={
												selectedAdministration === Administration.SelfAssess
											}
											onCheckedChange={() =>
												service.onAdministrationChange(
													Administration.SelfAssess
												)
											}
										/>
									</CheckboxesBox>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Authors'
									clearVisible={selectedNotedAuthorsIDs.length > 0}
									onListClear={service.onAuthorsClear.bind(service)}
								>
									<SelectableList
										items={selectedNotedAuthors}
										selectedItems={selectedNotedAuthorsIDs}
										onItemRemove={service.onSelectedAuthorRemove}
									/>
									<AddButton
										disabled={scope === FilterScope.MyTests}
										onClick={onAddAuthorsClick}
										label='Filter By Noted Author'
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Series'
									clearVisible={selectedNotedSeriesIDs.length > 0}
									onListClear={service.onNotedSeriesClear.bind(service)}
								>
									<SelectableList
										items={selectedNotedSeries}
										selectedItems={selectedNotedSeriesIDs}
										onItemRemove={service.onSelectedSeriesRemove}
									/>
									<AddButton
										disabled={scope === FilterScope.MyTests}
										onClick={onAddSeriesClick}
										label='Filter By Series'
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Hidden Tests'
								>
									<CheckboxesBox>
										<Checkbox
											data-cy='show-hidden-tests-checkbox'
											value={2}
											label='Show Hidden Tests'
											checked={showHidden}
											onCheckedChange={() =>
												service.onShowHiddenChange(!showHidden)
											}
										/>
									</CheckboxesBox>
								</FiltersPanelItem>
							</FiltersPanelItems>
						</OverlayScrollbarsComponent>
					</ExpandablePanelContent>
				</>
			)}
		</ExpandablePanelStyled>
	);
}
