import {useUser} from '@esgi/core/authentication';
import {CorrectAnswer, InCorrectAnswer} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useMemo} from 'react';
import {TagListModel} from './types';
import {NotTestedCounter, Tag, TagList} from './index.styled';
import {Tooltip} from '@esgi/ui';
import {TestType} from '@esgi/main/libs/core';
import {Box} from '@esgi/ui/layout';

type TestEntityProps = {
	entityType: 'test';
	testType: TestType;
};

type StudentEntityProps = {
	entityType: 'student';
};

type Props = {
	isItemOpen: boolean;
	notTested: number;
} & (TestEntityProps | StudentEntityProps);

export function Results({isItemOpen, notTested, ...props}: Props) {
	const currentUser = useUser();

	const tagItems = useMemo<TagListModel[]>(
		() => [
			{
				tagType: 'green',
				content: <CorrectAnswer data-cy='green-correct'/>,
				tooltipText: currentUser?.testResultsCorrectVerbiage ?? 'Correct',
			},
			{
				tagType: 'red',
				content: <InCorrectAnswer data-cy='red-incorrect'/>,
				tooltipText: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect',
			},
			{
				content: (
					<Text size='xxSmall' font='mono' color='lowContrast' data-cy='not-tested-white'>
						NT
					</Text>
				),
				tooltipText: 'Not Tested',
			},
		],
		[currentUser],
	);

	if (isItemOpen) {
		const showTagListItems =
			(props.entityType === 'test' && props.testType === TestType.YN) || props.entityType === 'student';

		return (
			<TagList>
				{showTagListItems && (
					<>
						{tagItems.map(({content, tooltipText, tagType}) => (
							<Tooltip>
								<Tooltip.Trigger>
									<Tag type={tagType}>{content}</Tag>
								</Tooltip.Trigger>
								<Tooltip.Content variant='secondary'>
									<Text size='medium'>{tooltipText}</Text>
								</Tooltip.Content>
							</Tooltip>
						))}
					</>
				)}
			</TagList>
		);
	}

	return (
		<NotTestedCounter>
			{notTested === 0 ? (
				<>
					<Box />
					<Text size='small' color='positive' data-cy='all-tested-title'>
						All tested
					</Text>
				</>
			) : (
				<>
					<Tag>
						<Text data-cy='not-tested-count' size='xxSmall' font='mono' color='lowContrast'>
							{notTested}
						</Text>
					</Tag>
					<Text data-cy='not-tested-title' size='small' color='muted'>
						Not tested
					</Text>
				</>
			)}
		</NotTestedCounter>
	);
}
