import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useMemo, useState} from 'react';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {TestAddedToSubjectEvent, TestRemovedFromSubjectEvent} from 'api/entities/events/subject';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {AddTestClosedEvent} from '../events';
import {Footer} from './footer/footer';
import {RolloverChecker} from '../../rollover-checker/rollover-checker';
import HomeAddTest from '../home-add-test';
import {SubjectModel} from '../models/models';
import {TestType} from '@esgi/core/enums';

export class Props {
	hierarchy: HierarchySnapshot | HierarchyInstance;
	limitToSelectedSubjectId: boolean = false;
	closed: () => any;
	testAddedToSubject?: (id: number, type: TestType, name: string, draft: boolean) => any;
	testRemovedFromSubject?: (testId: number) => any;
	subjectId: number;
	hidden?: boolean;
}

export default function AddTestModal(props: Props): JSX.Element {
	const hidden = props.hidden ?? false;
	const [selectedSubject, setSelectedSubject] = useState<SubjectModel>();
	const [homeAddTestOpened, setHomeAddTestOpened] = useState(false);
	const [subjectsChangedIds, setSubjectsChangedIds] = useState([]);


	useEventEffect(TestAddedToSubjectEvent, (args: TestAddedToSubjectEvent) => {
		setSubjectsChangedIds([...subjectsChangedIds, args.subjectID]);
	});

	useEventEffect(TestRemovedFromSubjectEvent, (args: TestRemovedFromSubjectEvent) => {
		setSubjectsChangedIds([...subjectsChangedIds, args.subjectID]);
	});

	const hierarchySnapshot = useMemo(() => {
		if (props.hierarchy instanceof HierarchySnapshot) {
			return props.hierarchy;
		} else {
			return props.hierarchy?.snapshot;
		}
	}, [props.hierarchy]);

	const modal = useModal();

	const close = useCloseModal(modal, () => {
		props.closed();
		const distinctSubjectIds = subjectsChangedIds.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
		if (!hidden) {
			dispatchAppEvent(AddTestClosedEvent, new AddTestClosedEvent(distinctSubjectIds, {
				type: selectedSubject?.subjectType,
				id: selectedSubject?.id,
			}));
		}
	});

	if (homeAddTestOpened) {
		return <Modal onCatchError={() => close()} className='add-test-to-subject-modal' modalManagerRef={modal}>
			<Modal.Header>
				<Title>Add Test</Title>
				<CloseIcon onClick={close}/>
			</Modal.Header>
			<Modal.Body>
				<HomeAddTest
					hierarchy={hierarchySnapshot}
					limitToSelectedSubjectId={props.limitToSelectedSubjectId}
					subjectId={props.subjectId}
					testAddedToSubject={props.testAddedToSubject}
					testRemovedFromSubject={props.testRemovedFromSubject}
					changedSelectedSubject={setSelectedSubject}/>
			</Modal.Body>
			<Modal.Footer>
				<Footer doneClicked={() => close()}/>
			</Modal.Footer>
			<SnackbarLayout/>
		</Modal>;
	} else {
		return <RolloverChecker callback={() => setHomeAddTestOpened(true)}/>;
	}
}
