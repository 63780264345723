import {styled} from '@esgi/ui/theme';
import {Checkbox, Radio} from '@esgillc/ui-kit/control';
import {Box} from '@esgi/ui/layout';

export const RadioStyled = styled(Radio, {
	marginRight: '13px',
	fontSize: '12px',
});

export const ReportFilterContainerStyled = styled(Box, {
	display: 'flex',
	borderRight: '2px solid #FFFFFF',
	padding: '10px',
	alignItems: 'flex-start',
	flexDirection: 'column',
});

export const ReportFilterLabelStyled = styled(Box, {
	fontWeight: 700,
	textAlign: 'left',
	fontSize: '13px',
});

export const CheckboxStyled = styled(Checkbox, {
	marginBottom: '8px',

	'label': {
		marginRight: '13px',
		fontSize: '12px',
	},
});