import React from 'react';
import moment from 'moment';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import SessionDropDown from 'shared/modules/reports/session-dropdown/session-dropdown';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import {SettingsModel} from 'shared/modules/reports/flash-cards/settings-form/services/settings-service';

class State {
	settings: SettingsModel;
}

class Props {
	dataService: DataService;
	test: Models.Test;
}

export default class TestRow extends React.Component<Props, State> {
	private sessionDropDown: SessionDropDown;
	private dateRef: React.RefObject<HTMLDivElement> = React.createRef();
	private allRef: React.RefObject<HTMLInputElement> = React.createRef();

	constructor(props?: Props) {
		super(props);
		const m = new SettingsModel();
		m.includeSessionOption = false;
		m.sessionDate = null;
		m.cardsNumber = '';
		m.printOutOption = '';

		this.state = {
			settings: m,
		};
	}

	componentDidMount(): void {
		this.props.dataService.settingsService.settings$.subscribe((settings) => {
			this.setState({settings: settings});
		});
	}

	validateNumber(value: string) {
		let parsedValue = parseInt(value);
		if (isNaN(parsedValue)) {
			return NaN;
		}
		if (parsedValue < 1) {
			return 1;
		}
		if (parsedValue > this.props.test.max) {
			return this.props.test.max;
		}

		return parsedValue;
	}


	setSessionClicked() {
		let left: number;
		let top: number;
		if (this.sessionDropDown) {
			return;
		}

		if (this.dateRef.current) {
			const rect = this.dateRef.current.getBoundingClientRect();
			top = rect.top;
			left = rect.left;
		}

		const tracks = this.props.dataService.settingsService.tracks.map(track => ({id: track.trackID, date: track.dateTo.format(Models.Date.defaultFormat)}));
		this.sessionDropDown = new SessionDropDown(left, top + 40, tracks, this.props.test.currentDate);
		this.sessionDropDown.events.hidden(() => {
			this.sessionDropDown = null;
		});
		this.sessionDropDown.events.dateClicked((e, newDate: string) => {
			const date = moment(newDate.replace(/[-]/g, '/')).format(Models.Date.defaultFormat);
			if (date === this.props.test.currentDate){
				return;
			}
			this.props.dataService.testsService.updateDate(this.props.test, date);
		});
		this.sessionDropDown.show();
	}

	renderTooltip() {
		if(!this.props.test.printItems) {
			return <ValidationTooltip element={null} elementRef={this.allRef} placement='right' container='body'>
				Incorrect value
			</ValidationTooltip>;
		}
	}

	render() {
		return <tr>
				<td className='test-name-cell'>
				<label>
					<input
						className='ace test-checkbox'
						type='checkbox'
						checked={this.props.test.selected}
						disabled={this.props.test.disabled}
						onChange={() => {
							const value = !this.props.test.selected;
							this.props.dataService.testsService.updateSelection(this.props.test, value);

							if (!value){
								this.props.dataService.testsService.updateAllPrintSelected(this.props.test, true);
								this.props.dataService.testsService.updatePrintItems(this.props.test, null);
							}
						}}
					/>
					<span className='lbl'>{this.props.test.name}</span>
				</label>
			</td>
			<td className='results-cell table-block text-center'>{this.props.test.correct}</td>
			<td className='results-cell text-center'>{this.props.test.incorrect}</td>
			<td className='results-cell text-center'>{this.props.test.untested}</td>
			<td className='max-items-cell table-block'>
				<ul>
					<li>
						<label>
							<input
								className='ace test-print-all-items-checkbox'
								disabled={!this.props.test.selected || !this.props.test.dataExists}
								type='checkbox'
								checked={this.props.test.allPrintSelected}
								onChange={() => {
									const value = !this.props.test.allPrintSelected;
									this.props.dataService.testsService.updateAllPrintSelected(this.props.test, value);
									if (this.props.test.allPrintSelected){
										this.props.dataService.testsService.updatePrintItems(this.props.test, null);
									}
								}}
							/>
							<span className='lbl'>All </span>
						</label>
					</li>
					{!this.props.test.allPrintSelected && this.props.test.selected &&
					<>
						<li>
							<div className='form-group form-inline'>
								<input
									   className={this.props.test.printItems ? 'form-control input-sm test-print-items-number'
										   : 'form-control input-sm test-print-items-number has-error'}
									   type='number'
									   ref={this.allRef}
									   max={this.props.test.max}
									   onChange={(e => {
										   let value = this.validateNumber(e.target.value);
										   this.props.dataService.testsService.updatePrintItems(this.props.test, value);
									   })}
									   value={this.props.test.printItems}
									   min='1'/>
								{this.renderTooltip()}
							</div>
						</li>
						<li>
							of <span>{this.props.test.max}</span>
						</li>
					</>
					}
				</ul>
			</td>
			{this.state.settings.includeSessionOption &&
			<td className='max-items-cell results-cell text-left'>
				<div className='date-wrapper' ref={this.dateRef}>
					{!this.props.test.dataExists &&
					<span>N/A</span>
					}
					{this.props.test.dataExists &&
					<>
						{this.state.settings.printOutOption !== 'FullSet' &&
						<a href='#' onClick={() => this.setSessionClicked()}>
							<input className='form-control date-input'
								   readOnly={true}
								   value={this.props.test.currentDate}
							/>
							<i className='fa fa-caret-down' aria-hidden='true'/>
						</a>
						}

						{this.state.settings.printOutOption === 'FullSet' &&
						<input
							className='form-control date-input'
							disabled={true}
							value={this.props.test.currentDate}
							title='You are unable choose a date with ‘Full Set’ option selected. If you want to choose a date, please change this setting.'/>
						}
					</>
					}
				</div>
			</td>
			}
		</tr>;
	}
}
