import {Observable} from 'rxjs';
import {Area} from 'react-easy-crop';
import {ImageElement} from '../../types';

export const convertFileToBase64 = (file: File): Observable<string> => {
	return new Observable<string>((subscriber) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			if (typeof reader.result === 'string') {
				const base64String = reader.result;
				subscriber.next(base64String);
				subscriber.complete();
			} else {
				subscriber.error();
			}
		};

		reader.onerror = (error) => subscriber.error(error);
		reader.readAsDataURL(file);

		return () => reader.abort();
	});
};

export const convertBase64ToFile = (base64String: string, filename: string, mimeType: string): File => {
	const base64 = base64String.split(';base64,').pop() || '';
	const byteCharacters = window.atob(base64);
	const byteArrays: Uint8Array[] = [];
	const sliceSize = 512;

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new File(byteArrays, filename, {type: mimeType});
};

export const cropImage = async (imageSrc: string, cropParams: Area, imageType: string) => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');

	const ctx = canvas.getContext('2d');
	const maxSize = Math.max(image.width, image.height);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	canvas.width = safeArea;
	canvas.height = safeArea;

	if (!ctx) {
		return null;
	}

	ctx.drawImage(
		image,
		safeArea / 2 - image.width * 0.5,
		safeArea / 2 - image.height * 0.5,
	);

	const data = ctx.getImageData(0, 0, safeArea, safeArea);

	canvas.width = cropParams.width;
	canvas.height = cropParams.height;

	ctx.putImageData(
		data,
		Math.round(0 - safeArea / 2 + image.width * 0.5 - cropParams.x),
		Math.round(0 - safeArea / 2 + image.height * 0.5 - cropParams.y),
	);
	return canvas.toDataURL(imageType);
};

const createImage = (url: string) => {
	return new Promise<ImageElement>((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous');
		image.src = url;
	});
};
