import {Student} from '@esgi/main/libs/store';
import {Test} from '../../../types';
import {AlertHeader} from '../../alert-header';
import {TestName} from '../../test-name';
import {TestType} from '@esgi/main/libs/core';
import {AlertFooter} from '../../alert-footer';
import {AlertBody} from '../../components.styled';
import {EditableSessionDateTimeInfo, Input, getDateTimeWithGMT} from '../../../../kit';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {Textarea} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {DefaultSkeleton} from '../../default-skeleton';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {isUndefined} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {TestSessionScoreCreatedEvent} from '../../../events';
import {useUser} from '@esgi/core/authentication';
import {useInitialUserDate} from '../../../hooks/use-initial-user-date';

type Props = {
	student: Student;
	test: Test;
	onCloseAlert: VoidFunction;
};

export function AddSingleScoreSession({student, test, onCloseAlert}: Props) {
	const currentUser = useUser();

	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isDataSaving, setIsDataSaving] = useState(false);

	const [isSessionDateTimeValid, setIsSessionDateTimeValid] = useState(false);
	const [scoreValue, setScoreValue] = useState('0');
	const [scoreError, setScoreError] = useState<string>();
	const [sessionNote, setSessionNote] = useState('');

	const initialUserDate = useInitialUserDate();

	useEffect(() => {
		service.init({studentID: student.id, testID: test.id}).subscribe(() => {
			setIsDataLoaded(true);
		});
	}, []);

	const service = useService(Service);

	const editableSessionTimeRef = EditableSessionDateTimeInfo.useRef();

	const handleUpdateScoreValue = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;

			const onlyNumberRegex = /^\d+$/;

			if (value === '' || onlyNumberRegex.test(value)) {
				setScoreValue(value);

				if (value === '') {
					setScoreError('Score required');
					return;
				}

				const numberedValue = Number(value);

				if (numberedValue > test.maxScore) {
					setScoreError(`Score out of range 0-${test.maxScore}`);
					return;
				}

				setScoreError(undefined);
			}
		},
		[test.maxScore],
	);

	const handleUpdateSessionNote = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value;

		setSessionNote(value);
	}, []);

	const onSave = () => {
		const dateTime = editableSessionTimeRef.current?.getFullDate();

		if (isUndefined(dateTime)) {
			return;
		}

		setIsDataSaving(true);

		const adaptedDateTime = getDateTimeWithGMT({
			dateTime,
			timeZone: currentUser?.timeZone,
		});

		service
			.save({
				testID: test.id,
				studentID: student.id,
				testDate: adaptedDateTime,
				notes: sessionNote,
				score: Number(scoreValue),
			})
			.subscribe(({value: {testSessionID}}) => {
				setIsDataSaving(true);
				onCloseAlert();

				dispatchAppEvent(
					TestSessionScoreCreatedEvent,
					new TestSessionScoreCreatedEvent({
						testSessionID,
						testID: test.id,
						studentID: student.id,
						testDate: dateTime,
						notes: sessionNote,
						score: Number(scoreValue),
					}),
				);
			});
	};

	if (!isDataLoaded) {
		return <DefaultSkeleton onCloseAlert={onCloseAlert} />;
	}

	return (
		<>
			<AlertHeader student={student} onCloseAlert={onCloseAlert} data-cy='header' />

			<AlertBody dataCy='body'>
				<TestName testID={test.id} name={test.name} type={TestType.Score} data-cy='test-name' />

				<GridBox flow='column' gap='3' columns='2' align='center'>
					<EditableSessionDateTimeInfo
						initialTestDate={initialUserDate}
						setisValid={setIsSessionDateTimeValid}
						editableSessionTimeRef={editableSessionTimeRef}
						timeZone={currentUser?.timeZone}
						data-cy='date-time'
					/>
				</GridBox>

				<GridBox flow='column' gap='3' columns='2' align='center'>
					<Input
						placeholder='Score'
						value={scoreValue}
						onChange={handleUpdateScoreValue}
						error={scoreError}
						dataCy='score'
					/>
					<Input placeholder='Max score' value={test.maxScore} disabled dataCy='max-score' />
				</GridBox>

				<Textarea
					placeholder='Summary Note'
					value={sessionNote}
					onChange={handleUpdateSessionNote}
					dataCy='summary-note'
				/>
			</AlertBody>

			<AlertFooter onSave={onSave} saveDisabled={!isSessionDateTimeValid || Boolean(scoreError) || isDataSaving} />
		</>
	);
}
