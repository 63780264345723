import React from 'react';
import styles from './exit-confirmation-modal.module.less';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';


interface ExitConfirmationModalProps {
	yesClicked: () => any;
	noClicked: () => any;
}

export default class ExitConfirmationModal extends React.Component<ExitConfirmationModalProps> {
	private modalManagerRef: ModalManagerRefObject = React.createRef();

	private yesClicked() {
		this.modalManagerRef.current.close(this.props.yesClicked);
	}

	private noClicked() {
		this.modalManagerRef.current.close(this.props.noClicked);
	}

	render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Header/>
			<Modal.Body>
				<div className={styles.bodyWrapper}>
					<span>Are you sure you want to exit?</span>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<LinkButton className={styles.button} onClick={() => this.noClicked()}>
					NO
				</LinkButton>
				<LinkButton className={styles.button} onClick={() => this.yesClicked()}>
					YES
				</LinkButton>
			</Modal.Footer>
		</Modal>;
	}
}
