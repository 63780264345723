import {useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import RowTitle from '../row-title/index';
import {PinModal} from './components/pin-modal';
import {lockHomePageHelpUrl, selfAssessHelpUrl} from './constants';
import styles from './styles.module.less';

interface Props {
	isLockScreen: boolean;
	setLockScreen: (lock: boolean) => void;
	showSelfAssessOption: boolean;
	setShowSelfAssessOption: (showSelfAssessOption: boolean) => void;
}

export function SelfAssessSection({isLockScreen, setLockScreen, showSelfAssessOption, setShowSelfAssessOption}: Props) {

	const [isShowPinModal, setShowPinModal] = useState(false);
	const togglePinOption = (v: boolean) => v ? setShowPinModal(true) : setLockScreen(false);

	return <div data-cy='self-assess'>
		<div>
			<div data-cy='administration-toggle'>
				<RowTitle
					title={'SELF-ASSESS ADMINISTRATION'}
					helpUrl={selfAssessHelpUrl}
					toggled={showSelfAssessOption}
					isShowSwitch
					onChange={(v) => {
						setShowSelfAssessOption(v.target.checked);
						if (!v) {
							setLockScreen(false);
						}
					}}
				>
					<p>
						Some tests offer a self-assessment version for students. When enabled,
						you can select which version of the test you want to provide.
					</p>
				</RowTitle>
			</div>

			<RowTitle
				title={'LOCK HOMEPAGE'}
				helpUrl={lockHomePageHelpUrl}
				toggled={isLockScreen}
				isShowSwitch
				onChange={(v) => {
					togglePinOption(v.target.checked);
				}}
			>
				<p>
					A {isLockScreen
					?
					<Buttons.Link className={styles.editPin} onClick={() => setShowPinModal(true)}> PIN </Buttons.Link>
					: <div data-cy='pin' className={styles.editPin}> PIN </div>
				} must be entered to return to the homepage after a student completes self-assessment.
				</p>
			</RowTitle>
		</div>

		{isShowPinModal &&
			<PinModal onClose={() => setShowPinModal(false)}
			          onSave={() => {
				          setShowPinModal(false);
				          setLockScreen(true);
			          }}/>
		}
	</div>;
}

