import {useEffect, useState} from 'react';
import {LineModel} from '../types';
import {useOpacityAnimation} from '../../utils/use-opacity-animation';
import {useBarAnimation} from '../utils/use-bar-animation';

export function useAnimationData({
	mainBar,
	nonActiveQuartileBar,
	activeQuartileBar,
}: {
	mainBar: LineModel;
	nonActiveQuartileBar: LineModel;
	activeQuartileBar: LineModel;
}) {
	const [forceUpdatedOnInit, setForceUpdatedOnInit] = useState(false);

	// This useEffect triggers animations
	useEffect(() => {
		if (!forceUpdatedOnInit) {
			setForceUpdatedOnInit(true);
		}
	}, [forceUpdatedOnInit]);

	const pointerAnimationProps = useOpacityAnimation();
	const cubeAnimationProps = useOpacityAnimation();
	const mainBarAnimationBarProps = useBarAnimation(mainBar);
	const nonActiveAnimationBarProps = useBarAnimation(nonActiveQuartileBar);
	const activeAnimationBarProps = useBarAnimation(activeQuartileBar);

	return {
		pointerAnimationProps,
		cubeAnimationProps,
		mainBarAnimationBarProps,
		nonActiveAnimationBarProps,
		activeAnimationBarProps,
	};
}
