import {Button, ContextMenu} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';
import {OneClassLeftConfirmation} from './one-class-left-confirmation';
import {DeleteClassConfirmation} from './delete-class-confirmation';

type Props = {
	classBucketLength: number;
	className: string;
	onDeleteClass: VoidFunction;
};

export function ClassContextMenu({classBucketLength, className, onDeleteClass}: Props) {
	const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
	const [isOpenedOneClassLeftConfirmation, setIsOpenedOneClassLeftConfirmation] = useState(false);
	const [isOpenedDeleteClassConfirmation, setIsOpenedDeleteClassConfirmation] = useState(false);

	const closeOneClassLeftConfirmation = useCallback(() => {
		setIsOpenedOneClassLeftConfirmation(false);
	}, []);

	const closeDeleteConfirmation = useCallback(() => {
		setIsOpenedDeleteClassConfirmation(false);
	}, []);

	const handleRemoveItemClick = useCallback(() => {
		setIsContextMenuOpened(false);

		if (classBucketLength === 1) {
			setIsOpenedOneClassLeftConfirmation(true);
			return;
		}

		setIsOpenedDeleteClassConfirmation(true);
	}, [classBucketLength]);

	return (
		<>
			<ContextMenu.Root open={isContextMenuOpened} onOpenChange={setIsContextMenuOpened}>
				<ContextMenu.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</ContextMenu.Trigger>
				<ContextMenu.Content>
					<ContextMenu.Group>
						<ContextMenu.Item onClick={handleRemoveItemClick}>
							<Delete />
							<Text size='medium'>Delete Class</Text>
						</ContextMenu.Item>
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>

			{isOpenedOneClassLeftConfirmation && <OneClassLeftConfirmation onCloseAlert={closeOneClassLeftConfirmation} />}
			{isOpenedDeleteClassConfirmation && (
				<DeleteClassConfirmation
					onCloseAlert={closeDeleteConfirmation}
					className={className}
					onDeleteClass={onDeleteClass}
				/>
			)}
		</>
	);
}
