import {useCallback, useState} from 'react';
import {AssignmentModel} from './types/types';
import {TeachersStudent} from './types/teacher-types';
import {SpecialistsStudent} from './types/specialist-types';
import {AssignmentCardSpecialistService} from './services/assignment-card-specialist-service';
import {isNull} from 'underscore';
import {AssignmentCardTeacherService} from './services/assignment-card-teacher-service';
import {UserInfo, UserType} from '@esgi/core/authentication';
import {useServiceFactory} from '@esgi/core/service';
import {Body} from './components/body';

export function useGetData({
	currentUser,
	assignmentID,
	goToAssignmentList,
}: {
	currentUser: Readonly<UserInfo>;
	assignmentID: number;
	goToAssignmentList: VoidFunction;
}) {
	const [initialized, setInitialized] = useState(false);

	const [assignment, setAssignment] = useState<AssignmentModel>();

	const [teacherStudents, setTeacherStudents] = useState<TeachersStudent[]>([]);
	const [specialistsStudents, setSpecialistsStudents] = useState<SpecialistsStudent[]>([]);
	const [isCardsDownloadingDisabled, setIsCardsDownloadingDisabled] = useState(false);
	const isTeacher = currentUser.userType === UserType.T;
	const isSpecialist = currentUser.userType === UserType.ISD || currentUser.userType === UserType.ISS;

	const service = useServiceFactory(() => {
		if (isTeacher) {
			const service = new AssignmentCardTeacherService();

			service.init(assignmentID).subscribe((data) => {
				if (isNull(data.assignment)) {
					goToAssignmentList();

					return;
				}

				const {students, ...assignment} = data.assignment;

				if (students.every(({hasCredentials}) => !hasCredentials)) {
					setIsCardsDownloadingDisabled(true);
				}

				setInitialized(true);
				setAssignment(assignment);
				setTeacherStudents(students);
			});

			return service;
		}

		if (isSpecialist) {
			const service = new AssignmentCardSpecialistService();

			service.init(assignmentID).subscribe((data) => {
				if (isNull(data.assignment)) {
					goToAssignmentList();

					return;
				}

				const {students, ...assignment} = data.assignment;

				if (students.every(({hasCredentials}) => !hasCredentials)) {
					setIsCardsDownloadingDisabled(true);
				}

				setInitialized(true);
				setAssignment(assignment);
				setSpecialistsStudents(students);
			});

			return service;
		}

		throw new Error('<AssignmentCard />: Current user is not teacher or specialist');
	});

	const getAssignmentCardBody = useCallback(() => {
		if (!assignment) {
			return null;
		}

		if (isTeacher) {
			return <Body service={service} assignment={assignment} students={teacherStudents} userType='teacher' />;
		}

		if (isSpecialist) {
			return <Body service={service} assignment={assignment} students={specialistsStudents} userType='specialist' />;
		}

		return null;
	}, [service, assignment, teacherStudents, specialistsStudents]);

	return {
		initialized,
		service,
		assignment,
		setAssignment,
		getAssignmentCardBody,
		isCardsDownloadingDisabled,
	};
}
