import {ScrollbarsAutoHideBehavior} from 'overlayscrollbars';

export const styles = {
	height: 'calc(100% + 0px)',
};

export const options = {
	paddingAbsolute: true,
	scrollbars: {
		autoHide: 'leave' as ScrollbarsAutoHideBehavior,
	},
};