import React, {RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {Fade} from '@esgillc/ui-kit/transition';
import moment from 'moment';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {CloseIcon, Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {showSnackbarNotification, SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {DownloadButton} from './components/download-button/download-button';
import {Footer} from './components/footer/footer';
import {SessionForm} from './components/form/form';
import {SubHeader} from './components/sub-header/sub-header';
import DataService from './data-service';
import {RubricSessionChangedEvent} from './events';
import {useService, useServiceFactory} from '@esgi/core/service';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Criteria, Description, Level, RubricAnswer} from '../common/types';
import {SessionModel, Subject} from './types';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {debounce} from 'underscore';
import {hasIEPLogic} from '../../general/testing';
import {Tabs, TabPanel} from '@esgillc/ui-kit/tabs-with-panel';
import IEPGoalTab from './components/iep-goal';
import {IEPFooter} from './components/iep-footer';
import {IEPGoalFormService} from './components/iep-goal/service';
import Matrix from '@esgi/deprecated/ui-kit/matrix/matrix';
import {scrollToElement} from '@esgi/deprecated/ui-kit/matrix';

import styles from './session-details.module.less';
import {userStorage, UserType} from '@esgi/core/authentication';

export interface RubricSessionDetailsProps {
	classID: number;
	rubricID: number;
	studentID: number;
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClose: () => void;
}

export function RubricSessionDetails({rubricID, studentID, onClose, classID, subject}: RubricSessionDetailsProps) {
	const matrixRef: RefObject<Matrix<Level, Criteria, Description>> = useRef();
	const modalManagerRef: ModalManagerRefObject = useRef();

	const criteriaToRef: Map<number, RefObject<HTMLDivElement>> = new Map<number, RefObject<HTMLDivElement>>();
	const iepService = useService(IEPGoalFormService);
	const dataService = useServiceFactory(() => new DataService(iepService));

	const [answers, setAnswers] = useState<RubricAnswer[]>([]);
	const [session, setSession] = useState<SessionModel>();
	const [showValidation, setShowValidation] = useState(false);
	const [isInitialized, setIsInitialized] = useState(false);

	const [editMode, setEditMode] = useState(false);
	const [iepEditMode, setIEPEditMode] = useState(false);
	const [activeTab, setActiveTab] = useState<'details' | 'iep'>('details');
	const [widthTab, setWidthTab] = useState('');

	const onSelectTab = ({tab}) => {
		setActiveTab(tab.name);
		setEditMode(false);
	};

	const syncSize = debounce(() => {
		const width = matrixRef?.current?.overlayScrollWidth;
		width && setWidthTab(`${width}px`);
	}, 300);

	const handlerCancel = useCallback(() => {
		if (editMode) {
			setEditMode(false);
			dataService.discardUnsavedChanges();
		} else {
			modalManagerRef.current.close(onClose);
		}
	}, [onClose, modalManagerRef]);

	const dispatchChangeEvent = useCallback(() => {
		dispatchAppEvent(RubricSessionChangedEvent, new RubricSessionChangedEvent(rubricID));
	}, [rubricID]);

	const validate = useCallback(() => {
		const incorrectAnswer = answers.find(a => a.score === undefined);
		if (incorrectAnswer) {
			const criteriaRef = criteriaToRef.get(incorrectAnswer.criteriaID);
			scrollToElement(criteriaRef?.current, matrixRef.current?.osInstance, 83, 140);
			setShowValidation(true);
			return false;
		} else {
			return true;
		}
	}, [answers]);

	const saveClicked = useCallback(() => {
		if(validate()) {
			setEditMode(false);
			dataService.saveChanges().subscribe(r => {
				const date = moment(r.testDate).format('MM-DD-YYYY hh:mm A');
				if(r.createMode) {
					showSnackbarNotification(`You've created Test Session ${date}`);
				} else {
					showSnackbarNotification(`You've edited Test Session ${date}`);
				}

				dispatchChangeEvent();
			});
		}
	}, []);

	const restoreClicked = useCallback(() => {
		dataService.restoreCurrentSession()
			.subscribe(t => {
				const date = moment(t.testDate).format('MM-DD-YYYY hh:mm A');
				showSnackbarNotification(`You've restored Test Session ${date}`);
				dispatchChangeEvent();
			});
	}, [dataService, dispatchChangeEvent]);

	const tabs = useMemo( () => {
		const result = [{name: 'details', label: 'Test sessions'}];
		if (iepService?.iepGoal$.value !== null) {
			result.push({name: 'iep', label: 'IEP Goal'});
		}
		return result;
	}, [iepService?.iepGoal$.value]);

	const canEdit = useMemo( () => {
		const user = userStorage.get()
		if (!user.canAddEditTestSessions || !session){
			return false;
		}

		if (user.userType === UserType.T) {
			return user.userID === session.userID || session.userID === null;
		} else {
			return user.userID === session.userID;
		}
	}, [session]);

	const saveButtonClickerIEP = () => iepService.update(() => setIEPEditMode(false));

	const cancelButtonClickedIEP = () => {
		if (iepEditMode) {
			iepService.resetIEPGoalFormData();
			setIEPEditMode(false);
		} else {
			onClose();
		}
	};

	const renderModal = useCallback(() => {
		if (isInitialized) {
			return (
				<Modal modalManagerRef={modalManagerRef}>
					<Modal.Header>
						<Title>Test Session Details</Title>
						<CloseIcon onClick={() => modalManagerRef.current.close(onClose)}/>
						<Fade in={!editMode} duration={100}>
							<div className={styles.downloadButtonContainer}>
								<DownloadButton
									service={dataService}
									onDownloadClicked={() => dataService.exportCurrentSession(classID)}
									onDownloadAllClicked={() => dataService.exportAllSessions(classID)}
								/>
							</div>
						</Fade>
					</Modal.Header>
					<Modal.Body className={styles.body}>
						<SubHeader
							editMode={editMode}
							onSessionDeleted={dispatchChangeEvent}
							changeMode={() => setEditMode(!editMode)}
							service={dataService}
						/>
						{hasIEPLogic() && iepService?.iepGoal$.value ? (
							<div className={styles.tsdTabs}>
								<Tabs
									items={tabs}
									active={activeTab}
									onSelect={onSelectTab}
								/>
								<div
									className={styles.tsdTabsPanelWrapper}
									style={{width: widthTab}}
								>
									<TabPanel key='details' name='details'>
										<SessionForm
											matrixRef={matrixRef}
											answers={answers}
											session={session}
											editMode={editMode}
											service={dataService}
											showValidation={showValidation}
											criteriaToRef={criteriaToRef}
										/>
									</TabPanel>
									<TabPanel key='iep' name='iep'>
										<IEPGoalTab
											editMode={iepEditMode}
											service={iepService}
										/>
									</TabPanel>
								</div>
							</div>
						) : (
							<SessionForm
								matrixRef={matrixRef}
								answers={answers}
								session={session}
								editMode={editMode}
								service={dataService}
								showValidation={showValidation}
								criteriaToRef={criteriaToRef}
							/>
						)}
					</Modal.Body>
					<Modal.Footer>
						{activeTab === 'details'
							? <Footer
								canEdit={canEdit}
								editMode={editMode}
								onCancelClicked={handlerCancel}
								onEditClicked={() => setEditMode(true)}
								onSaveClicked={saveClicked}
								onRestoreClicked={restoreClicked}
								service={dataService}
							/>
							: <IEPFooter
								editMode={iepEditMode}
								onSave={saveButtonClickerIEP}
								onEdit={() => setIEPEditMode(true)}
								onCancel={cancelButtonClickedIEP}
							/>
						}
					</Modal.Footer>
					<SnackbarLayout/>
				</Modal>
			);
		}
	}, [
		answers,
		isInitialized,
		dataService,
		editMode,
		handlerCancel,
		saveClicked,
		restoreClicked,
		modalManagerRef,
		classID,
		showValidation,
		activeTab,
		widthTab,
		iepService?.iepGoal$.value,
		iepEditMode,
	]);

	useEffect(() => {
		dataService.init(rubricID, studentID, subject.id, subject.type).subscribe(r => setIsInitialized(true));
	}, []);

	useEffect(() => {
		iepService.form.controls.isCompleted.onChanged.subscribe(({reason}) => {
			if (reason === 'value' && !iepEditMode) {
				setIEPEditMode(true);
			}
		});

		window.addEventListener('resize', syncSize);

		setWidthTab(`${matrixRef?.current?.overlayScrollWidth}px`);

		syncSize();

		return () => window.removeEventListener('resize', syncSize);
	}, [matrixRef?.current?.overlayScrollWidth]);

	useStreamEffect(dataService.currentSessionAnswers, answers => setAnswers(answers));
	useStreamEffect(dataService.currentSession, session => setSession(session));

	useEffect(() => {
		if (showValidation) {
			setShowValidation(false);
		}
	}, [editMode]);

	return (
		<>
			<ServiceLoader trackingService={dataService} fullscreen/>
			{renderModal()}
		</>
	);
}
