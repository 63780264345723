import './background-download-manager.less';
import React from 'react';
import {Modal, ModalBody} from '@esgi/deprecated/react';
import {Events} from './events';
import {ReportData} from './models';
import {DownloadItem, ImportDownloadItem, ReportDownloadItem} from './download-items';
import {Import} from 'api/import-api';
import {EventBusManager} from '@esgillc/events';

class State {
	reportGuid: string = null;
	fileType: string;
	displayRemainingText: boolean;
	remainingSeconds?: number;
	files: FileData[] = [];
}

export default class BackgroundDownloadManager extends React.Component<{}, State> {
	private eventBus: EventBusManager = new EventBusManager();
	private reportDownloadsListKey: string = 'reportDownloadsList';
	private reports: DownloadItem[];
	private processingDownloads: boolean = false;
	private newFiles = new Array<string>();
	private importApi: Import;

	constructor(props: {}) {
		super(props);
		this.state = new State();
		this.importApi = new Import();
	}

	componentDidMount() {
		this.reports = this.getReportsList();

		this.eventBus.subscribe(Events.Start, (args: Events.StartArgs) => {
			const item = new ReportDownloadItem(args.reportGuid);
			this.processNewEvent(item, args.fileType, args.displayRemainingText);
		});

		this.eventBus.subscribe(Events.StartImport, (args: Events.StartImportArgs) => {
			const item = new ImportDownloadItem(args.fileID);
			this.processNewEvent(item, 'ZIP', false);
		});

		this.checkProgress();
		this.updateDownloadedFiles();
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	processNewEvent(item: DownloadItem, fileType: string, displayRemainingText: boolean) {
		this.addReportToDownloadsList(item);
		if (!this.processingDownloads) {
			this.checkProgress();
		}
		this.setState({
			reportGuid: item.reportGuid,
			fileType: fileType,
			displayRemainingText: displayRemainingText,
			remainingSeconds: undefined,
		});
	}

	getReportsList = (): DownloadItem[] => {
		let reportsList = [];
		let reportsListData = sessionStorage.getItem(this.reportDownloadsListKey);
		if (reportsListData) {
			reportsList = JSON.parse(reportsListData);
		}
		return reportsList.map(x => DownloadItem.create(x));
	}

	updateReportsList = () => {
		sessionStorage.setItem(this.reportDownloadsListKey, JSON.stringify(this.reports));
	}

	updateDownloadedFiles = () => {
		const finishedOrFailedReports = this.reports.filter(x => x.finished || x.error);
		this.setState({
			files: finishedOrFailedReports.map(x => {
				return {fileID: x.fileID, new: this.newFiles.includes(x.reportGuid), error: x.error};
			}),
		}, () => {
			this.newFiles = [];
		});
	}

	addReportToDownloadsList = (item: DownloadItem) => {
		this.reports.unshift(item);
		this.updateReportsList();
	}

	checkProgress = () => {
		let generatingReportsList = this.reports.filter(x => !x.finished && !x.error);
		if (generatingReportsList.length === 0) {
			return;
		}

		this.processingDownloads = true;
		setTimeout(() => {
			let processedReports = 0;
			for (let i = 0; i < generatingReportsList.length; i++) {
				const report = generatingReportsList[i];

				report.checkProgress().subscribe((data: ReportData) => {
					let updateReportsList = false;
					if (data.fileID && data.fileID !== -1) {
						this.newFiles.push(report.reportGuid);
						updateReportsList = true;

						if (report.reportGuid === this.state.reportGuid) {
							this.closeModal();
						}
					} else {
						if (report.reportGuid === this.state.reportGuid) {
							this.setState({remainingSeconds: data.remainingSeconds});
						}
						if (data.fileID === -1) {
							this.newFiles.push(report.reportGuid);
							updateReportsList = true;
							this.closeModal();
						}
					}

					if (updateReportsList) {
						this.updateReportsList();
						this.updateDownloadedFiles();
					}

					processedReports++;

					if (processedReports === generatingReportsList.length) {
						if (this.reports.filter(x => !x.finished && !x.error).length > 0) {
							this.checkProgress();
						} else {
							this.processingDownloads = false;
						}
					}
				});
			}
		}, 10000);
	};

	closeModal = () => {
		this.setState({reportGuid: null});
	}

	get remainingMinutesText() {
		let text = 'Please continue to use the application. You\'ll be alerted once the download is complete.';
		let minutes: number = null;
		if (this.state.remainingSeconds) {
			minutes = Math.round(this.state.remainingSeconds / 60);
			text = minutes + ' minute' + (minutes > 1 ? 's' : '') + ' remaining';
		}
		if (this.state.remainingSeconds === 0) {
			minutes = 0;
		}
		if (minutes === 0) {
			text = 'Less than a minute remaining';
		}

		return text;
	}

	removeFile = (fileID) => {
		this.reports = this.reports.filter(x => x.fileID !== fileID);
		this.updateReportsList();
		this.updateDownloadedFiles();
	}

	download(data: FileData) {
		const item = this.reports.filter(t => t.fileID === data.fileID);
		if (item.length) {
			item[0].download();
		}
	}

	render() {
		return <>
			{this.state.reportGuid &&
			<Modal className={'background-download-manager-modal responsive'} onCatchError={() => {
				this.closeModal();
			}}>
				<ModalBody>
					<div className='header'>{this.state.fileType} File is Generating</div>
					<div>The file may take several minutes to finalize.</div>
					<div className='loading-animation'>
						<div className='images'>
							<img className='list'
							     src='//s3.amazonaws.com/esgiwebfiles/Images/Reports/BackgroundDownload/list.png'/>
							<img className='arrow'
							     src='//s3.amazonaws.com/esgiwebfiles/Images/Reports/BackgroundDownload/arrow.png'/>
						</div>
						{this.state.displayRemainingText &&
						<div className='remaining-time'>{this.remainingMinutesText}</div>
						}
					</div>
					<div>We will alert you when the file is ready to download.</div>
					<div className='footer'><a href='#' onClick={() => this.closeModal()}>Got it</a></div>
				</ModalBody>
			</Modal>
			}
			{this.state.files.length > 0 &&
			<div className='background-download-manager-files'>
				{this.state.files.map((fileData) => {
					return <>{!fileData.error ? <div className={fileData.new ? 'newFile' : undefined} key={fileData.fileID}>
						<div className='text'>
							Your file is ready. <span className='text'
							                          onClick={() => {
								                          this.download(fileData);
								                          this.removeFile(fileData.fileID);
							                          }}>Download</span>
						</div>
						<div className='checklist'>
							<span onClick={() => {
								this.download(fileData);
								this.removeFile(fileData.fileID);
							}}>
								<img className='list'
								     src='//s3.amazonaws.com/esgiwebfiles/Images/Reports/BackgroundDownload/list.png'/>
							</span>
							<img className='check'
							     src='//s3.amazonaws.com/esgiwebfiles/Images/Reports/BackgroundDownload/check.png'/>
						</div>
						<div className='close-container'>
							<a href='#' title='Cancel Download' onClick={() => this.removeFile(fileData.fileID)}>
								<img className='cross'
								     src='//s3.amazonaws.com/esgiwebfiles/Images/Reports/BackgroundDownload/cross.png'/>
							</a>
						</div>
					</div> : <div className={fileData.error ? 'error' : undefined} key={fileData.fileID}>
						<div className='text'>
							Failed. Please try again. <span className='text'
																							onClick={() => {
																								this.removeFile(fileData.fileID);
																							}}>Close</span>
						</div>
					</div>
					}</>;
				})}
			</div>
			}
		</>;
	}
}

export class FileData {
	fileID: number;
	new: boolean;
	error: boolean;
}
