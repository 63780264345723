import {useServiceFactory} from '@esgi/core/service';
import {FiltersTeacher} from '../../filters/filters-teacher';
import {StudentCredentialsTeacherService} from '../../../services/student-credentials-teacher-service';
import {FilterTeacherService} from '../../../services/filter-teacher-service';
import {StudentCredentialsContent} from './student-credentials-content';
import {useEffect} from 'react';
import {StudentCardsService} from 'pages/assignment-center/services/service';

interface Props {
	setInitialized: (value: boolean) => void;
	onGoBack: VoidFunction;
	setCountStudentsWithoutCredentials: (value: number) => void;
	setShowMissingStudentsAlerts: (value: boolean) => void;
	credentialsService: StudentCardsService;
}

export function StudentCredentialsTeacher({setInitialized, ...props}: Props) {
	const service = useServiceFactory(() => {
		const filterTeacherService = new FilterTeacherService();

		return new StudentCredentialsTeacherService(filterTeacherService);
	});

	useEffect(() => {
		service.init().subscribe(() => setInitialized(true));
	}, []);

	return (
		<>
			<FiltersTeacher service={service} />
			<StudentCredentialsContent service={service} {...props} />
		</>
	);
}
