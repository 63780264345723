import React from 'react';
import {UserType, userStorage} from '@esgi/core/authentication';
import {dispatchAppEvent, EventBusDispatcher} from '@esgillc/events';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, IGroupModel, ISchAdminBox, ISchoolBox} from '../../core/api';
import {HierarchyEvents, SchAdminEditEvent} from '../../core/events';
import {BoxType} from '../../core/models';

export class State {
}

export class Props {
	boxes: IBoxInfo[];
	schools: ISchoolBox;
	schAdmins: ISchAdminBox;
  schoolsGroupID: number;
	selectedID: number;
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class School extends React.Component<Props, State> {
	private currentUser = userStorage.get();

  get items(): ItemProps[] {
    if(this.props.schoolsGroupID) {
      const source = this.props.schools.items.filter(s => s.groups.some(g => g === this.props.schoolsGroupID));
      return [...source].map(r => {
        return {
          id: r.schoolID,
          title: r.name,
        };
      });
    }
    const admins = this.props.schAdmins.items;
    return this.props.schools.items.map(r => {
      return {
        id: r.schoolID,
        title: r.name,
        subItems: admins.filter(t => t.schoolID === r.schoolID)
          .map(s => {
            return {
              canEdit: this.props.schAdmins.canEdit,
              id: s.userID,
              onEdit: this.publish.schAdminEdit,
              title: `${s.firstName} ${s.lastName}`,
            };
          }),
      };
    });
  }

	render(): any {
		if (this.currentUser.userType <= UserType.C) {
			return null;
		}

		const options: BoxOptions = {
			boxType: BoxType.ClassicSchool,
			canDrag: false,
			title: 'All Schools',
			canCreateTooltip: 'To add a new school, please contact Customer Support support@esgisoftware.com',
			canAdd: this.props.schools.canCreate,
			canEdit: this.props.schools.canEdit,
		};

		return <Box
			selectedID={this.props.selectedID}
			options={options}
			onEditClicked={this.publish.edit}
			onDragEnd={null}
			onDragStart={null}
			itemSelected={this.props.onSelected}
			empty={{message: 'The list is empty'}}
			items={this.items}
			onAddClicked={this.publish.add}
			scheduledIDs={null}
			open={this.props.boxes.filter(t => t.boxType === BoxType.ClassicSchool && t.open).length > 0}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
		/>;
	}

	publish = {
		add: () => {
			const args: HierarchyEvents.School.AddArgs = {};

			EventBusDispatcher.dispatch(HierarchyEvents.School.Add, args);
		},
		edit: (id: number) => {
			const args: HierarchyEvents.School.EditArgs = {
				id: id,
			};

			EventBusDispatcher.dispatch(HierarchyEvents.School.Edit, args);
		},
		schAdminEdit: (id: number) => {
			dispatchAppEvent(SchAdminEditEvent, new SchAdminEditEvent(id));
		},
	}
}
