import React, {ReactNode} from 'react';
import {OptionModel} from '../../types';
import {FlexBox} from '@esgillc/ui-kit/layout';
import SettingsItem from '../settings/settings-item';
import {Dropdown, Input, Option} from '@esgillc/ui-kit/control';
import SettingsFooter from '../settings/settings-footer';
import styles from './base-print-settings.module.less';
import {BasicPrintSetting} from './types';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip'
import Settings from '../settings/settings';

interface Props {
  fontSizeOptions: OptionModel<string>[];
  studentsCount: number;
  selectedFontSize: OptionModel<string>;
  copiesCount: number;
  isFontSizeOptimized: boolean;
  fontSizeWarningMessage: string;
  settingsChanged: (state: BasicPrintSetting) => void;
  wordsCount: number;
  children?: ReactNode;
}

export class BasePrintSettings extends React.PureComponent<Props> {
  render() {
    return <Settings>
      {this.props.children}
      <SettingsItem title='Font Size'>
        <FlexBox direction='row' align='center'>
          <Dropdown value={[this.props.selectedFontSize]}
                    displayLabel={value => value[0].value}
                    optionName='key'
                    className={styles.fontSizeDropdown}
                    setValue={(v) => this.onFontSizeChanged(v[0])}>
            {this.props.fontSizeOptions.map(v => <Option key={v.key} value={v}>{v.value}</Option>)}
          </Dropdown>
          {
            this.props.isFontSizeOptimized &&
            <div className={styles.warning}>
              <OnHoverTooltip message={this.props.fontSizeWarningMessage}>
                <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
                  <title></title>
                  <g data-name='layer' id='layer'>
                    <path d='M16,26a2,2,0,1,1,2-2A2,2,0,0,1,16,26Zm0-2Z'></path>
                    <path d='M16,20a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0v8A1,1,0,0,1,16,20Z'></path>
                    <path d='M27.78,30H4.22a3.19,3.19,0,0,1-2.77-1.57,3.13,3.13,0,0,1-.06-3.13L13.17,3.67a3.23,3.23,0,0,1,5.66,0L30.61,25.3a3.13,3.13,0,0,1-.06,3.13A3.19,3.19,0,0,1,27.78,30ZM16,4a1.18,1.18,0,0,0-1.07.63L3.15,26.25a1.12,1.12,0,0,0,0,1.16,1.19,1.19,0,0,0,1,.59H27.78a1.19,1.19,0,0,0,1-.59,1.12,1.12,0,0,0,0-1.16L17.07,4.63A1.18,1.18,0,0,0,16,4Z'></path>
                  </g>
                </svg>
              </OnHoverTooltip>
            </div>
          }
        </FlexBox>
      </SettingsItem>
      <SettingsItem title='Font Style'>
        Trace with guidelines
      </SettingsItem>
      <SettingsItem title='Copies'>
        <FlexBox align='center'>
          <div>
            <Input className={styles.copiesCount}
                   type='number'
                   inputMode='numeric'
                   min={1}
                   max={20}
                   value={this.props.copiesCount}
                   onChange={v => this.onCopiesCountChanged(v.target.value)}/>
          </div>
          <div>
            per student
          </div>
        </FlexBox>
      </SettingsItem>
      <SettingsItem title='Students'>
        <div className={styles.counter}>
          {this.props.studentsCount}
        </div>
      </SettingsItem>
      <SettingsFooter title='Total pages'>
        <div className={styles.counter}>
          {this.totalPages()}
        </div>
      </SettingsFooter>
    </Settings>
  }

  private onFontSizeChanged(newValue: OptionModel<string>) {
    this.props.settingsChanged({
      copiesCount: this.props.copiesCount,
      selectedFontSize: newValue,
      wordsPerPage: this.wordsPerPage(),
    } as BasicPrintSetting)
  }

  private onCopiesCountChanged(newValue: string) {
    let parsedValue = parseInt(newValue);
    if (isNaN(parsedValue)) {
      parsedValue = NaN;
    }
    if (parsedValue < 1) {
      parsedValue = 1;
    }
    if (parsedValue > 99) {
      parsedValue = 99;
    }

    this.props.settingsChanged({
      copiesCount: parsedValue,
      selectedFontSize: this.props.selectedFontSize,
      wordsPerPage: this.wordsPerPage(),
    } as BasicPrintSetting);

    return parsedValue;
  }

  private totalPages(){
    if (isNaN(this.props.copiesCount)){
      return 0;
    }
    const pagesPerStudent = Math.ceil(this.props.wordsCount / this.wordsPerPage());
    return pagesPerStudent * this.props.studentsCount * this.props.copiesCount;
  }

  private wordsPerPage() {
    if (this.props.selectedFontSize.key === '30pt') {
      return 14;
    }

    if (this.props.selectedFontSize.key === '40pt') {
      return 11;
    }

    if (this.props.selectedFontSize.key === '62pt') {
      return 7;
    }

    return 14;
  }
}
