import {ListTextItem} from './types';

export const listTextItems: ListTextItem[] = [
	{
		label: 'All subject tabs and tests have been copied from the previous school year to the new school year.',
		fontStyle: 'normal',
	},
	{
		label:
			'Continue your end-of-year testing in the current school year, and make any necessary updates to subject tabs in the new school year.',
		fontStyle: 'normal',
	},
	{
		label: 'Please ensure you are in the correct school year when making changes to your subject tabs.',
		fontStyle: 'italic',
	},
];
