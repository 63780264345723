import {ImportRegistrationFinish} from './import-registration-finish';

export class Page {
	constructor() {
		const importRegistrationFinishForm = new ImportRegistrationFinish();
		ko.applyBindings(importRegistrationFinishForm, document.body);
		importRegistrationFinishForm.load();
	}

	public destroy() {
		ko.cleanNode(document.body);
	}
}
