import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ContentWrapper = styled(GridBox, {
	gap: '$3',
	gridAutoColumns: 'max-content',
});

export const ProgressBarInfo = styled(GridBox, {
	gap: '$3',
	gridAutoFlow: 'column',
	alignItems: 'center',
});

export const ProgressBarTranscriptWrapper = styled(GridBox, {
	gap: '$2',
});
