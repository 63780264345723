import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {CloseIcon, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

interface Props {
	onSave: () => void;
	onClose: () => void;
	onCancel: () => void;
}

export function SaveTestAlert({onSave, onClose, onCancel}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	const onCloseWithoutSaving = () => {
		onClose();
		handleClose();
	};

	return <Alert modalManagerRef={modalRef}>
		<Alert.Header>
			<Title className={styles.title}>Save My Test?</Title>
			<CloseIcon onClick={handleClose}/>
		</Alert.Header>
		<Alert.Body>
		<span data-cy='save-test-alert' className={styles.text}>
			Do you want to save changes to this test? If you don't save, your changes will be lost.
		</span>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={onCloseWithoutSaving}>Close without saving</Buttons.Text>
			<Buttons.Text onClick={onSave}>Yes, Save My Test</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
