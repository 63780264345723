import {IEPStatusModel} from 'modules/forms/iep-goal-form/models';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import {ListIcon} from './list-icon';
import styles from '../styles.module.less';

interface Props {
	status: IEPStatusModel;
	onRemoveStatus: (status: IEPStatusModel) => void;
}

export const IEPStatusComponent = ({status, onRemoveStatus}: Props): JSX.Element => (
	<div className={styles.statusItemContainer}>
		<ListIcon
			className={styles.listItemIcon}
			color='#0088CC'
		/>
		<div className={styles.statusNameContainer}>
			<div>{status.name}</div>
			<CloseIcon
				color='#828282'
				onClick={() => onRemoveStatus(status)}
			/>
		</div>
	</div>
);
