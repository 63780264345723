import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {Test} from '@esgi/main/kits/reports';
import {V2TeachersPagesReportsStudentDetailsController} from '@esgi/contracts/esgi';
import {StudentWithUnit} from '../types';
import {TestContentArea} from '@esgi/main/kits/common';
import {SubjectTab} from '@esgi/main/libs/store';

export class TestService extends BaseService {
	public loading$ = new BehaviorSubject(false);
	public tests$ = new BehaviorSubject<Test[]>([]);
	public defaultSelectedTestsIDs$ = new BehaviorSubject<Test['id'][]>([]);
	private readonly controller = new V2TeachersPagesReportsStudentDetailsController();

	public override dispose() {
		this.controller.dispose();
	}

	public getTests(subject: SubjectTab | undefined, students: StudentWithUnit[]) {
		if (!subject || !students) {
			return;
		}

		this.loading$.next(true);
		const {id: subjectID, type: subjectType} = subject;
		this.controller.availableTests({
			subjects: [{subjectID, subjectType}],
			studentIDs: students.map(({studentId}) => studentId),
		}).subscribe({
			next: ({value: {subjectTests}}) => {
				const defaultSelectedTestsIDs: Test['id'][] = [];

				const value = subjectTests
					.flatMap(({tests}) => tests)
					.map(({testID: id, name, contentAreaName, correct, incorrect, untested, isTested, type, maxScore}) => {

						if(isTested) {
							defaultSelectedTestsIDs.push(id);
						}

						return {
							id,
							name,
							contentArea: contentAreaName as TestContentArea,
							isTested,
							correct,
							incorrect,
							untested,
							maxScore,
							type,
						};
					});

				this.defaultSelectedTestsIDs$.next(defaultSelectedTestsIDs);
				this.tests$.next(value);
			},
			error: () => {
				this.tests$.next([]);
				this.loading$.next(false);
			},
			complete: () => this.loading$.next(false),
		});
	}

	public get(testId: Test['id']) {
		return this.tests$.value.find(({id}) => id === testId);
	}
}
