import {TestsControl} from './tests-control';
import {StudentPanel} from './student-panel';
import {UnitNamePanel} from './unit-name-panel';
import {NoStudentPanel} from './no-student-panel';
import {ReportPanel} from './report-panel';
import {ClassLevelStudentsPanel} from './students-panel/class-level';
import {StudentLevelStudentsPanel} from './students-panel/student-level';
import {MultipleStudentsLevelStudentsPanel} from './students-panel/multiple-students';
import {SubjectControl} from './header/components/subject-control';
import {StudentAvatar} from './student-avatar';
import {SettingsControl} from './sections/settings-control';
import {GradesControl} from './sections/grades-control';
import {LetterTemplateControl} from './sections/letter-template-control';
import {ParentLetterPreview} from './sections/parent-letter-preview';
import {Header} from './header';
import {MarkingPeriodControl} from './marking-period-control';
import {NotTestedControl} from './not-tested-control';
import {DownloadButton} from './download-button';

export const Reports = {
	TestsControl,
	StudentAvatar,
	SettingsControl,
	GradesControl,
	LetterTemplateControl,
	Header,
	ReportPanel,
	StudentPanel,
	UnitNamePanel,
	NoStudentPanel,
	MarkingPeriodControl,
	NotTestedControl,
	SubjectControl,
	DownloadButton,
	ParentLetterPreview,
	ClassLevel: {
		StudentPanel: ClassLevelStudentsPanel,
	},
	StudentLevel: {
		SingleStudentPanel: StudentLevelStudentsPanel,
		MultipleStudentsPanel: MultipleStudentsLevelStudentsPanel,
	},
};

export type {Test, ReportTest} from './tests-control/types';
export {ExpandablePanel} from './expandable-panel';
export * from './types';
export {useTestsControl} from './tests-control/hooks';
export {SettingsPanel} from './settings-panel';
export {type Option, ToggleGroup} from './toggle-group';
export {useValue} from './hooks';
export {EmptyContentTemplate} from './empty-content-template';
export {TestTypeLabel} from './test-type-label';
