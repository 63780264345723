import {Text} from './index.styled';
import {TickItem} from 'recharts/types/util/types';
import {ReactElement} from 'react';

type AxisTickProps = {
	x: number,
	y: number,
	payload: TickItem & {offset: number}
}

export const renderXAxisTick = ({x, y, payload}: AxisTickProps): ReactElement<SVGElement> => {
	return (
		<svg>
			<Text
				x={x + 3}
				y={y + 37}
				transform={`rotate(${-90}, ${x + 3}, ${y + 35} )`}
				fill='lowContrast'
			>{payload.value}</Text>
		</svg>
	);
};

export const renderYAxisTick = ({x, y, payload}: AxisTickProps): ReactElement<SVGElement> => {
	return (
		<svg>
			<Text
				x={x - 25}
				y={y}
				fill='blue'
			>{payload.value}</Text>
		</svg>
	);
};