import {AssignmentsSelectableList, Select, TextWithIcon} from '@esgi/main/kits/assignments';
import {AssignmentsSkeletonBox, LeftPanelBody, LeftPanelHeader, LeftPanelRoot} from './index.styled';
import {Assignment} from '@esgi/ui/icons';
import {useUser} from '@esgi/core/authentication';
import {Text} from '@esgi/ui/typography';
import {selectAssignmentStatusItems} from '../left-panel/constants';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {useOpenAssignment} from '../../../hooks/use-open-assignment';
import {useAssignmentId} from '../../../hooks/use-assignment-id';
import {useCallback, useMemo} from 'react';
import {EmptyContent} from '../empty-content';
import {AssignmentStateTab} from '../../../layout/types';
import {useTeacherAssignmentsContext} from '../../../layout/outlet-context';
import {SkeletonShape} from '@esgi/main/kits/common';
import {Tooltip} from '@esgi/ui';

type Props = {
	skeleton: boolean;
};

export function LeftPanel({skeleton}: Props) {
	const user = useUser();

	const selectedAssignmentId = useAssignmentId();
	const onAssignmentItemClick = useOpenAssignment();

	const {selectedAssignmentState, setSelectedAssignmentState, visibleAssignmentsList} = useTeacherAssignmentsContext();

	const sortedAssignments = useMemo(
		() =>
			[...visibleAssignmentsList].sort((a, b) => {
				const aCreated = new Date(a.created).getTime();
				const bCreated = new Date(b.created).getTime();

				return bCreated - aCreated;
			}),
		[visibleAssignmentsList],
	);

	const getListContent = useCallback(() => {
		if (skeleton) {
			return (
				<AssignmentsSkeletonBox>
					{new Array(5).fill(null).map((_, index) => (
						<SkeletonShape width='100%' height={40} key={index} />
					))}
				</AssignmentsSkeletonBox>
			);
		}

		if (!sortedAssignments.length) {
			return <EmptyContent messageText='There are no assignments matching the selected filters' />;
		}

		return (
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<AssignmentsSelectableList
					onAssignmentClick={(assignmentID) => onAssignmentItemClick({assignmentId: assignmentID})}
					selectedAssignmentId={selectedAssignmentId}
					assignments={sortedAssignments}
				/>
			</OverlayScrollbarsComponent>
		);
	}, [sortedAssignments, selectedAssignmentId, onAssignmentItemClick, skeleton]);

	return (
		<LeftPanelRoot>
			<LeftPanelHeader>
				<TextWithIcon size='small' color='neutral56' Icon={Assignment}>
					Assignments
				</TextWithIcon>
				{!!user?.districtID && (
					<Tooltip>
						<Tooltip.Trigger>
							<Text size='small' color='secondary' data-cy='district-id'>
								District ID: {user.districtID}
							</Text>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<Text size='small'>District ID is needed for student access</Text>
						</Tooltip.Content>
					</Tooltip>
				)}
			</LeftPanelHeader>
			<LeftPanelBody>
				<Select<AssignmentStateTab>
					items={selectAssignmentStatusItems}
					onValueChange={setSelectedAssignmentState}
					placeholder='Status'
					selectedValue={selectedAssignmentState}
					skeleton={skeleton}
					dataCy='status-select'
				/>

				{getListContent()}
			</LeftPanelBody>
		</LeftPanelRoot>
	);
}
