import type { SVGProps } from 'react';

export function AiAnalysis(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				d="M9.9 16.8H6.7c-.1.4-.2.8-.3 1.1-.1.4-.2.7-.3 1-.1.1-.2.1-.4.1h-.5c-.5 0-.8-.1-1.1-.2-.2-.2-.4-.4-.4-.7 0-.1 0-.3.1-.4 0-.1.1-.3.1-.5.1-.3.2-.6.3-1.1.1-.4.3-.9.4-1.4.2-.5.3-1 .5-1.5s.4-1 .5-1.5c.2-.5.3-.9.5-1.3.3-.4.4-.7.5-.9.2-.1.4-.2.7-.3.3-.1.7-.1 1-.1.5 0 .9.1 1.3.2s.6.3.7.7c.2.6.4 1.2.7 2 .2.7.5 1.5.7 2.3.2.8.5 1.6.7 2.3.2.8.4 1.5.6 2.1-.1.1-.3.3-.5.3-.2.1-.5.1-.8.1-.5 0-.8-.1-1-.2-.2-.2-.3-.4-.4-.8l-.4-1.3zm-1.6-5.5c-.1.5-.3 1-.6 1.6-.2.6-.4 1.2-.6 1.9h2.3c-.2-.7-.4-1.3-.6-1.9-.1-.6-.3-1.1-.5-1.6z"
				fill="#08c"
			/>
			<path
				d="M16.7 18.9c-.1 0-.3.1-.4.1h-.6c-.5 0-.8-.1-1-.2-.2-.2-.3-.5-.3-.9v-6.6c.1 0 .3-.1.4-.1h.6c.5 0 .8.1 1 .2.2.2.3.5.3.9v6.6z"
				fill="#fab124"
			/>
			<path
				d="M14.8 7.3c.1.3.2.5.3.8.2-.2.4-.5.6-.7.9-1.1 2.8-3 4.3-2.9.4 0 .4.2.1.3-1.6.8-3 2.9-3.9 4.3-.2.4-.6 1-1 1.2-.2.1-.6.1-.8-.1-.4-.3-.9-1.7-1.3-2.2-.2-.5 0-1 .4-1.2.5-.1 1.1 0 1.3.5z"
				fill="#2fb454"
			/>
		</svg>
	);
}
