import {MutableRefObject, useState} from 'react';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {TestType} from '@esgi/core/enums';
import {mapToEnum} from 'shared/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import moment from 'moment';
import {StudentData, Test, TestRunOptions} from '../models';
import {untestedStudentsReportUtils} from '../utils';
import {HierarchySnapshot} from '../../../hierarchy/models';
import {UntestedStudentsReportService} from '../service';
import {TestLauncherWrapper} from './test-launcher-wrapper';
import styles from './modal/modal.module.less';

interface Props {
	hierarchySnapshot: HierarchySnapshot;
	service: UntestedStudentsReportService;
	rightBottomPanelRef: MutableRefObject<HTMLDivElement>
	rightTopPanelRef: MutableRefObject<HTMLDivElement>
	testNamesRef: MutableRefObject<HTMLTableRowElement>
	onClose: () => void;
}

export function ReportRightPanel({service, rightTopPanelRef, rightBottomPanelRef, testNamesRef, hierarchySnapshot}: Props) {
	const {reportService} = service;
	const rows = useBehaviorSubject(reportService.reportRows);
	const lastTestedStudentID = useBehaviorSubject(service.lastTestedStudentID);
	const lastTestedTestID = useBehaviorSubject(service.lastTestedTestID);
	const headerTests = useBehaviorSubject(service.headerTests);
	const showOnlyUntested = useBehaviorSubject(service.showOnlyUntested);
	const selectedTest = useBehaviorSubject(service.selectedTest);
	const isTeacherOrSpecialist = service.isTeacherOrSpecialist;
	const selectedClass = useBehaviorSubject(service.selectedClass);
	const selectedSpecialistGroup = useBehaviorSubject(service.selectedSpecialistGroup);
	const selectedSubject = useBehaviorSubject(service.selectedSubject);

	const [testLauncherSettings, setTestLauncherSettings] = useState<TestRunOptions>();

	const runRetestIfNeeded = (data: StudentData, test: Test) => {
		if (!isTeacherOrSpecialist) {
			return;
		}

		const classID = selectedClass ? selectedClass?.[0]?.classID : 0;
		const specialistGroupID = selectedSpecialistGroup ? selectedSpecialistGroup?.[0]?.specialistGroupID : 0;
		const tests = data.tests.filter(x => x.testID === test.testID);
		if (tests.length === 0 || tests[0].lastTestDateOverPeriod === null) {
			const subject = selectedSubject?.[0];

			setTestLauncherSettings({
				testType: mapToEnum(test.testType, TestType),
				classID: classID,
				specialistGroupID: specialistGroupID,
				studentID: data.studentID,
				testID: test.testID,
				subjectID: subject.subjectID,
				subjectType: subject.subjectType,
				subjectName: subject.name,
			});
		}
	};

	return <>
		{testLauncherSettings &&
			<TestLauncherWrapper
				hierarchy={hierarchySnapshot}
				runOptions={testLauncherSettings}
			/>
		}
		<div className={styles.rightPanel}>
			<div className={styles.rightTopPanel} ref={rightTopPanelRef}>
				<table
					className={styles.table}
					style={{width: headerTests?.length * 200 + 17 + 'px'}}
				>
					<tbody>
						<tr className={styles.showUntestedRow}>
							{headerTests?.map(test => (
								<td
									key={test.testID}
									className={join('test-cell', styles.testCell, styles.td)}
								>
									{selectedTest && selectedTest[0]?.testID > 0 &&
										<div>
											<label>
												<input
													type='checkbox'
													className='ace toggle-check-all-checkbox'
													checked={showOnlyUntested}
													onClick={() => {
														service.toggleShowOnlyUntested();
														service.filterStudentsByShowOnlyUntestedOption();
													}}
													id={'showOnlyUntested' + test.testID}
												/>
												<span className='lbl'/>
												<label htmlFor={'showOnlyUntested' + test.testID}>
													<span>Show only untested</span>
												</label>
											</label>
											<sup>
												<a
													href='https://support.esgisoftware.com/hc/en-us/articles/209159826-Untested-Students#showonlyuntested'
													className='fa fa-question-circle'
													target='_blank'
													rel='noreferrer'
												/>
											</sup>
										</div>
									}
								</td>
							))}
							<td className='cell4scroll'/>
						</tr>
						<tr ref={testNamesRef} className={join(
							'test-names', styles.tr,
						)}>
							{headerTests?.map(test => (
								<td key={test.testID} className={
									join(
										'test-cell',
										styles.testCell,
										styles.testName,
										test.testID === lastTestedTestID && styles.lastTested,
									)
								}>
									<div>
										<span>{test.name}</span>
										<span
											className={styles.testQuestionsCount}>{`(out of ${+test.maxScore})`}</span>
									</div>
								</td>
							))}
							<td className='cell4scroll'/>
						</tr>
					</tbody>
				</table>
			</div>
			<div
				className={styles.rightBottomPanel}
				ref={rightBottomPanelRef}
			>
				<table
					className='table table-hover table-striped'
					style={{width: ((headerTests?.length * 200) + 1) + 'px'}}
				>
					<tbody>
					{rows?.map(student => (
						<tr key={student.studentID}>
							{headerTests?.map((test) => {
								const testDates = student.tests.filter(x => x.testID === test.testID);
								const lastDateTested = testDates.length === 0 ? 'Never' : moment(testDates[0].lastTestDate).format('MM-DD-YY');
								const message = (
									<div className={styles.popoverContent}>
										Last date tested
										<div>{lastDateTested}</div>
									</div>
								);
								return (
									<td
										key={test.testID}
										className={join(test.testID === lastTestedTestID && student.studentID === lastTestedStudentID && styles.lastTested)}
									>
										<i className={join(styles.fa, styles.faCheckCircle, 'fa', 'fa-check-circle')}/>
										<OnHoverTooltip message={message}>
											<a
												id={student.studentID + '_' + test.testID}
												className={join(
													styles.tableLink,
													!untestedStudentsReportUtils.isAllowedToTest(student, test, isTeacherOrSpecialist) && styles.nonLink,
													untestedStudentsReportUtils.getCellText(student, test, isTeacherOrSpecialist) === '100%' && styles.hundredthPercents,
												)}
												onClick={() => runRetestIfNeeded(student, test)}
											>
												{untestedStudentsReportUtils.getCellText(student, test, isTeacherOrSpecialist)}
											</a>
										</OnHoverTooltip>
									</td>
								);
							})}
						</tr>
					))}
					</tbody>
				</table>
			</div>
		</div>
	</>;
}
