import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type RadioGroupValueContextValue = {
	/** Value of selected item  */
	selectedValue: string | undefined;

	/** Set selected item value  */
	setSelectedValue: (value: string | undefined) => void;

	/**
	 * When true, prevents the user from interacting with all radio items.
	 */
	disabledAllItems: boolean;
};

export const RadioGroupContext = createContext<RadioGroupValueContextValue>({
	selectedValue: undefined,
	setSelectedValue: noop,
	disabledAllItems: false,
});

export function useRadioGroupContext() {
	return useContext(RadioGroupContext);
}
