import * as SelectRadix from '@radix-ui/react-select';
import {SelectRoot} from './components/select-root';
import {SelectField} from './components/select-field';
import {SelectContent} from './components/select-content';
import {SelectOption} from './components/select-option';
import {ErrorText} from './components/error-text';

export const Select = Object.assign(
	{},
	{
		Root: SelectRoot,
		Field: SelectField,
		Content: SelectContent,
		Viewport: SelectRadix.Viewport,
		Option: SelectOption,
		ErrorText: ErrorText,
	},
);
