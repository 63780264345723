import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, Observable} from 'rxjs';
import {PreModel} from 'modules/forms/admin-group-forms/models/types';
import {InModel} from '@esgi/deprecated/elements/checkbox-list';

export abstract class BaseGroupService extends BaseService {
	public readonly availableItems$: BehaviorSubject<InModel[]> = new BehaviorSubject<InModel[]>([]);
	public readonly itemsInGroup$: BehaviorSubject<InModel[]> = new BehaviorSubject<InModel[]>([]);

	public abstract initCreate(model: PreModel): Observable<any>;

	public abstract initEdit(model: PreModel): Observable<any>;

	public abstract create(model: PreModel): Observable<any>;

	public abstract update(model: PreModel): Observable<any>;

	public abstract remove(model: PreModel): Observable<any>;
}
