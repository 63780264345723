import {createContext, useContext} from 'react';
import {noop} from 'underscore';
import {AlertColorConfig} from './types';
import {alertColorsConfig} from './constants';

export type AlertContextValue = {
	/**
	 * Alert appearance variant.
	 */
	colorConfig: AlertColorConfig;

	/**
	 * Set contentWithHeader value.
	 */
	setContentWithHeader: (value: boolean) => void;

	/**
	 * Set labelId value.
	 */
	setLabelId: (value: string) => void;

	/**
	 * Set describedId value.
	 */
	setDescribedId: (value: string) => void;
};

export const AlertContext = createContext<AlertContextValue>({
	colorConfig: alertColorsConfig.neutral,
	setContentWithHeader: noop,
	setLabelId: noop,
	setDescribedId: noop,
});

export function useAlertContext() {
	return useContext(AlertContext);
}
