import React, {createRef, RefObject} from 'react';
import {Subject} from 'rxjs';
import {skip, takeUntil} from 'rxjs/operators';
import {ServiceLoader} from '@esgi/deprecated/ui-kit/loader';
import {SortDirection} from '@esgi/core/enums';
import {Row, LoadingRow} from './row/row';
import {Column} from './column/column';
import SearchService from './search-service';
import {TestModel} from './types';

class State {
	rows: TestModel[] = [];
	loadingMore: boolean = false;
	sortBy: string = 'CreateDate';
	sortDirection: SortDirection = SortDirection.Desc;
	canLoadMore: boolean = true;
	firstLoad: boolean = false;
}

interface Props {
	testChecked: (test: TestModel, checked: boolean) => any;
	selectedTestIds: number[];
	searchService: SearchService;
}

export class Results extends React.PureComponent<Props, State> {
	public state = new State();
	private rootRef: RefObject<HTMLDivElement> = createRef();
	private scrollElementRef: RefObject<HTMLDivElement> = createRef();
	private onDestroy$: Subject<void> = new Subject();

	public componentDidMount() {
		this.props.searchService.filter
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(filter => this.setState({
				sortBy: filter.sortBy,
				sortDirection: filter.sortDirection,
			}));

		this.props.searchService.searchResult
			.pipe(
				takeUntil(this.onDestroy$),
				skip(1),
			)
			.subscribe(r => {
				if (r.pageIndex === 1 && this.scrollElementRef.current) {
					this.scrollElementRef.current.scrollTop = 0;
				}

				this.setState({
					rows: r.tests,
					canLoadMore: r.canLoadMore,
					firstLoad: r.pageIndex === 1,
				});
			});
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}

	render() {
		const service = this.props.searchService;
		return <div className='results-wrapper' ref={this.rootRef}>
			<ServiceLoader trackingService={this.props.searchService} fullscreen/>
			<div className={'results ' + (!this.state.rows.length ? 'empty' : '')}
			     ref={this.scrollElementRef}
			     onScroll={(e) => this.handleScroll(e)}>
				<table className='table table-bonnie table-hover table-striped table-responsive'>
					<thead>
					<tr>
						<Column sorted={false}/>
						<Column title='Test Name'
						        sorted={this.state.sortBy === 'TestName'}
						        sortDirection={this.state.sortDirection}
						        clicked={() => service.applySort('TestName')}/>
						<Column title='Author'
						        sorted={this.state.sortBy === 'TestOwner'}
						        sortDirection={this.state.sortDirection}
						        clicked={() => service.applySort('TestOwner')}/>
						<Column title='Create Date'
						        class='create-date'
						        sorted={this.state.sortBy === 'CreateDate'}
						        sortDirection={this.state.sortDirection}
						        clicked={() => service.applySort('CreateDate')}/>
						<Column title='Questions' class='questions'
						        sorted={this.state.sortBy === 'NumberOfQuestions'}
						        sortDirection={this.state.sortDirection}
						        clicked={() => service.applySort('NumberOfQuestions')}/>
					</tr>
					</thead>
					<tbody>
					{!this.state.rows.length && !this.state.firstLoad &&
						<tr>
							<td colSpan={5}>Search by keyword or select filters above</td>
						</tr>
					}
					{!this.state.rows.length && this.state.firstLoad &&
						<tr>
							<td colSpan={5}>Your search returned 0 results</td>
						</tr>
					}
					{this.state.rows.map(t => {
						return <Row
							key={t.id}
							model={t}
							onSelected={(checked) => this.props.testChecked(t, checked)}
							selected={this.props.selectedTestIds.indexOf(t.id) > -1}/>;
					})}
					{this.state.loadingMore && <LoadingRow/>}
					</tbody>
				</table>
			</div>
		</div>;
	}

	private handleScroll(e: React.UIEvent<HTMLDivElement>) {
		if (!this.state.loadingMore && this.state.canLoadMore) {
			const tableHeight = this.rootRef.current?.getElementsByTagName('table')[0].offsetHeight;
			const results = this.rootRef.current?.getElementsByClassName('results')[0] as HTMLDivElement;
			const rootHeight = results.offsetHeight;

			const scrollableArea = tableHeight - rootHeight;
			if (scrollableArea - results.scrollTop <= 250) {
				this.setState({loadingMore: true});
				this.props.searchService.loadMoreTests()
					.subscribe(r => this.setState({loadingMore: false}));
			}
		}
	}
}

class TestsResponse {
	tests: TestResponse[];
}

class TestResponse {
	testID: number;
	type: string;
	name: string;
	createDate: string;
	numberOfQuestions: number;
	creatorName: string;
	color: string;
}