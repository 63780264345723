import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {FileType} from 'modules/reports/item-analysis-report/models';
import {getTemplatePdf} from '../utils';

export class ExportService extends BaseService {
	public pages = new BehaviorSubject<string[]>([]);
	private controllerName = 'reports/item-analysis';
	private downloadParameters = {
		[FileType.PDF]: {
			method: 'FormDownloadPdf',
			extension: '.pdf',
		},
		[FileType.Excel]: {
			method: 'FormDownloadExcel',
			extension: '.xlsx',
		},
	};

	public downloadExcel(
		currentExcelSvgImage,
		testId,
		classId,
		groupId,
		specialistGroupId,
		hierarchyUpdated,
		subjectId,
		subjectType,
		sortOrder,
		gradeLevelId,
		hierarchy: HierarchySnapshot,
	) {
		console.log('IAR: Creating request params');
		const payload = {
			svgImage: currentExcelSvgImage,
			testId: testId,
			subjectType: subjectType,
			subjectId: subjectId,
			sortQuestionsBy: sortOrder,
			gradeLevelID: gradeLevelId,
			hierarchy: hierarchy,
		};

		const queryParams = new URLSearchParams({
			classId: classId,
			groupId: groupId,
			specialistGroupId: specialistGroupId,
			hierarchyUpdated: hierarchyUpdated,
		}).toString();
		console.log('IAR: Requst params created:', payload, queryParams);

		this.downloadFile(FileType.Excel, payload, '?' + queryParams);
	}

	public downloadReportPDF(
		selectedSpecialistGroup,
		userType,
		schoolName,
		districtName,
		teacherName,
		selectedClass,
		selectedGroup,
		selectedSubject,
		selectedTest,
	) {
		const titles = [];
		titles.push({
			left: selectedSpecialistGroup ? 'Specialist:' : userType === 'school' || userType === 'district' ? schoolName ? 'School:' : 'District:' : 'Teacher:',
			right: !selectedSpecialistGroup && (userType === 'school' || userType === 'district') ? userType === 'school' ? schoolName : districtName : teacherName,
		});
		if (selectedClass) {
			titles.push({left: 'Class:', right: selectedClass.name});
		}
		if (selectedGroup) {
			titles.push({left: 'Group:', right: selectedGroup.name});
		}
		if (selectedSpecialistGroup) {
			titles.push({
				left: 'Group:',
				right: selectedSpecialistGroup.name,
			});
		}
		titles.push({left: 'Subject:', right: selectedSubject.name});

		const html = getTemplatePdf(
			titles,
			selectedTest.name,
			this.pages.value,
			Array.from(document.querySelectorAll('#iar-chart .highcharts-container .highcharts-legend')).at(-1)?.innerHTML,
		);

		return this.downloadFile(FileType.PDF, {html});
	}

	public downloadDetailsPDF(html: string) {
		this.downloadFile(FileType.PDF, {html});
	}

	private downloadFile(type: FileType, payload, queryParams: string = '', details: boolean = false) {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = 'Item_Analysis_Report_' + (details ? 'Question_Details_' : '') + year + '-' + month + '-' + day + this.downloadParameters[type].extension;
		console.log('IAR: Start excel download request with filename: ', filename);
		return this.httpClient.ESGIApi.file(
			this.controllerName,
			this.downloadParameters[type].method + queryParams,
			filename,
			payload,
		).subscribe(() => {
			console.log('IAR: Request finished');
		});
	}
}
