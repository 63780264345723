import {
	IATCUpdateResult,
	QuestionModel,
	ShapeDefinitions,
} from 'shared/modules/question-editor/models';
import {Canvas, State as CanvasState, Events as CanvasEvents} from 'shared/modules/question-editor/mx/editor/canvas/canvas';
import {RightPanel, State as RightPanelState} from 'shared/modules/question-editor/mx/editor/right-panel/right-panel';
import {Form, State as FormState} from 'shared/modules/question-editor/mx/form/form';
import {XmlCodec} from 'shared/modules/question-editor/utils/xml-codec';
import {ShapeDefinitionService} from 'shared/modules/question-editor/utils/shape-definition-builder';
import * as Events from 'shared/modules/question-editor/form-events';
import {Modal, ModalBody, ModalFooter, ModalHeader, SharedProps, SharedComponent} from '@esgi/deprecated/react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {LoadFonts} from 'shared/modules/assets/fonts';
import {DirtyAlert} from '../components/dirty-alert';
import '../editors.less';

export class Props extends SharedProps<State> {
	onSaved: (result?: IATCUpdateResult) => any;
	shapeDefinitions: ShapeDefinitions;
}

export class State {
	tileID: string;
	question: QuestionModel = new QuestionModel();
	form: FormState = new FormState();
	canvas: CanvasState = new CanvasState();
	rightPanel: RightPanelState = new RightPanelState();
	dirtyAlert: boolean = false;
	dirtyDefinitions: ShapeDefinitions;
	dirtyXml: string;
}

export class AutoTestCreatorEditor extends SharedComponent<State, Props> {
	get valid() {
		return this.formValid && this.canvasValid;
	}

	get formValid() {
		return this.state.form.questionName.validation.valid
			&& this.state.form.directions.validation.valid;
	}

	get canvasValid() {
		return this.state.canvas.cells.length > 0;
	}

	async componentDidMount() {
		await LoadFonts();

		this.subscribe(CanvasEvents.SaveAndClose, (args: CanvasEvents.SaveAndClose.Args) => {
			if (this.valid) {
				this.save(args.xml, args.definitions);
			} else {
				this.props.onSaved();
			}
		});

		this.subscribe(CanvasEvents.Close, (args: CanvasEvents.Closed.Args) => {
			if (this.state.form.changed || args.changed) {
				this.setState({dirtyXml: args.xml, dirtyDefinitions: args.definitions, dirtyAlert: true});
			} else {
				this.props.onSaved();
			}
		});
	}

	save(xml: string, definitions: ShapeDefinitions) {
		let decodedXml = XmlCodec.decode(xml, this.state.canvas.images);
		let newDefinitions = new ShapeDefinitionService(definitions).saveTempDefinitions();

		let updateResult = {
			xml: decodedXml,
			directions: this.state.form.directions.value,
			name: this.state.form.questionName.value,
			stateStandardID: this.state.form.stateStandardID,
			tileID: this.state.tileID,
			definitions: newDefinitions,
			images: this.state.canvas.images,
		};

		this.props.onSaved(updateResult);
	}

	closeDirtyAlert(needSave: boolean) {
		if (needSave) {
			if (this.valid) {
				this.setState({dirtyAlert: false});
				this.save(this.state.dirtyXml, this.state.dirtyDefinitions);
			} else {
				this.showValidation();
				this.setState({dirtyAlert: false});
			}
		} else {
			this.setState({dirtyAlert: false});
			this.props.onSaved();
		}
	}

	saveAndCloseClicked() {
		this.showValidation();
		this.dispatch(Events.SaveAndCloseClicked, null);
	}

	showValidation() {
		this.dispatch(Events.ShowValidation, Events.ShowValidation(this.formValid, this.canvasValid));
	}

	closeClicked() {
		this.dispatch(Events.CloseClicked, null);
	}

	render() {
		return <div data-cy='auto-test-creator-editor'>
			{this.state.dirtyAlert &&
				<DirtyAlert
					onClose={() => this.closeDirtyAlert(false)}
					onSave={() => this.closeDirtyAlert(true)}
				/>
			}
			<Modal
				animate={true}
				className={'mx-question-editor-modal responsive white-header'}>
				<ModalHeader title='Edit Question' close={{click: () => this.closeClicked()}}/>
				<ModalBody>
					<div className='mx-question-editor-modal-root'>
						<Form
							isNew={true}
							state={this.state.form}
							onChange={(st, ch) => this.setState({form: st}, ch)}/>
						<div className='mx-question-editor'>
							<Canvas
								shapeDefinitionsXml={this.state.question.xml}
								images={this.state.question.images}
								isWhiteBackground={this.state.question.isWhiteBackground}
								xml={this.state.question.xml}
								testName={this.state.question.testName}
								questionNumber={this.state.question.orderNum}
								shapeDefinitions={this.props.shapeDefinitions}
								state={this.state.canvas}
								onChange={(st, ch) => this.setState({canvas: st}, ch)}/>
							<RightPanel
								shapeStyle={this.state.canvas.shapeStyle}
								shapeType={this.state.canvas.shapeType}
								state={this.state.rightPanel}
								onChange={(st, ch) => this.setState({rightPanel: st}, ch)}/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className='mx-question-footer-row'>
						<div className='mx-question-root-left-button'>
							<div className='mx-question-root-left-button-container'>
								<Button
									onClick={() => this.closeClicked()}
									title='CANCEL'
								/>
							</div>
						</div>
						<Primary
							onClick={() => this.saveAndCloseClicked()}
							title='SAVE'
							className='save-btn'
						/>
					</div>
				</ModalFooter>
			</Modal>
		</div>;
	}
}

