import {Button as ButtonRoot} from './components/button';
import {ButtonIcon} from './components/icon';
import {ButtonText} from './components/text';

export * from './components/base-button';

export const Button = Object.assign(ButtonRoot, {
	Text: ButtonText,
	Icon: ButtonIcon,
});
