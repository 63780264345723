import {useEffect, useMemo, useRef, useState} from 'react';
import {IEPGoalFormService} from '../../services';
import {Buttons} from '@esgillc/ui-kit/button';
import {Modal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject, tryCall} from '@esgillc/ui-kit/utils';
import {NotificationOptions} from '@esgillc/ui-kit/snackbar';
import styles from '../../styles.module.less';
import {SsoTracker} from '@esgi/core/tracker';

interface Props {
	service: IEPGoalFormService;
	onClose: () => void;
	onRunReport?: () => void;
}

export function Footer({service, ...props}: Props) {
	const iepGoal = useBehaviorSubject(service.iepGoal$);
	const previousGoalId = useBehaviorSubject(service.previousGoalId$);
	const footerRef = useRef<HTMLDivElement>(null);
	const hasGoal = useMemo(() => iepGoal?.id || false, [iepGoal]);
	const isDirty = useBehaviorSubject(service.isDirty);
	const [opts, setOpts] = useState<NotificationOptions>();
	useEffect(() => {
		const {y: offsetY} = footerRef.current?.getClientRects()[0];
		setOpts({
			offsetY,
		});
	}, [footerRef]);

	const onCreate = () => {
		service.create(false, () => {
			if (previousGoalId) {
				service.markGoalAsArchived(previousGoalId, () => props.onClose());
				return;
			}
			service.updateInitialFormValue();
			SsoTracker.trackEvent({
				trackingEvent: 'IEP Create Goal',
			});
		}, opts);
	};

	const onSave = () => {
		service.update();
	};

	const onRunReport = () => tryCall(props.onRunReport);

	const onCreateNewGoal = () => {
		service.setPreviousGoalId(iepGoal.id);
		service.resetIEPGoalFormData(true);
	};

	return (
		<Modal.Footer
			ref={footerRef}
			className={styles.modalFooter}
		>
			<div className={styles.footerPadding}/>
			{hasGoal &&
				<>
					{iepGoal.isCompleted &&
						<Buttons.Outlined
							className={styles.newGoal}
							onClick={onCreateNewGoal}
						>
							New Goal
						</Buttons.Outlined>
					}
					{!isDirty &&
						<Buttons.Outlined onClick={onRunReport}>
							Run Report
						</Buttons.Outlined>
					}
					{isDirty
						? <Buttons.Contained onClick={onSave}>
								Save
							</Buttons.Contained>
						: <Buttons.Contained onClick={props.onClose}>
								Done
							</Buttons.Contained>
					}
				</>
			}
			{!hasGoal &&
				<Buttons.Contained onClick={onCreate}>
					Create
				</Buttons.Contained>
			}
		</Modal.Footer>
	);
}
