import {useEffect, useMemo} from 'react';
import {ConflictLevel, ConflictType} from 'shared/modules/student-roster-upload-tool/types';
import {ConflictMessage} from './components/conflict-message';
import styles from './styles.module.less';

interface Props {
	conflictType: ConflictType;
}

export function ConflictMessageWizard({conflictType}: Props) {

	useEffect(() => {
		if (!conflictType) {
			conflictType = null;
		}
	}, []);

	const renderMessage = useMemo(() => {
		switch (conflictType) {

			case ConflictType.DuplicateStudentName:
				return <ConflictMessage
					conflictLevel={ConflictLevel.Warning}
					title='Possible duplicate student.'
					message='We highly recommend deleting student names that are already in ESGI to avoid data inconsistencies.'
				/>;

			case ConflictType.DuplicateStudentIDN:
				return <ConflictMessage
					conflictLevel={ConflictLevel.RequireChange}
					title='Required change.'
					message='Duplicate IDs are not allowed in ESGI. Please change or remove the student ID.'
				/>;

			case ConflictType.UnknownLanguage:
				return <ConflictMessage
					conflictLevel={ConflictLevel.Notification}
					title='Notification.'
					message='An unrecognized language has been uploaded. We have modified this to fit a default language. You can modify this via student profile upon completion of the file upload.'
				/>;

			default:
				return null;
		}
	}, [conflictType]);

	return <div className={styles.conflictMessageContainer}>{renderMessage}</div>;
}