import React, {useState} from 'react';
import {FormControl} from '@esgillc/ui-kit/form';
import {Drawer} from '@esgi/main/features/teacher/home';
import {PasswordManagerDisabler} from '@esgi/ui';
import {Eye} from '@esgi/ui/icons';
import {Input} from '@esgi/ui/form-controls';
import {FormElementWrapper} from '../../../../../index.styled';
import {StudentCredentialsWrapper, EyeIconWrapper, PasswordInput} from './index.styled';
import {noop} from 'underscore';
import {ErrorTooltip, FormElement} from '@esgi/ui/form';
import {Text} from '@esgi/ui/typography';

interface Props {
	usernameControl: FormControl;
	passwordControl: FormControl;
	skeleton?: boolean;
	usernamePlaceholder: string;
	isDisabledEyeButton?: boolean;
}

export function Credentials({passwordControl, usernameControl, skeleton, usernamePlaceholder, isDisabledEyeButton}: Props) {
	const [isPasswordHide, setPasswordHide] = useState(true);

	return <Drawer.ContentBlock title='Credentials' withDivider>
		<StudentCredentialsWrapper gapX={3}>
				<FormElementWrapper>
					<FormElement control={usernameControl}>
						<PasswordManagerDisabler name='usrnm'/>
						<Input.Base skeleton={skeleton} placeholder={usernamePlaceholder} dataCy='username-input'/>
						<ErrorTooltip showOnError='range-length-lower' notHide>
							<Text size='xxSmall' font='mono' bold>Minimum 4 characters</Text>
						</ErrorTooltip>
						<ErrorTooltip showOnError='user-name-in-use' notHide>
							<Text size='xxSmall' font='mono' bold>Username is already being used</Text>
						</ErrorTooltip>
						<ErrorTooltip showOnError='range-length-upper' notHide>
							<Text size='xxSmall' font='mono' bold>Maximum 20 characters</Text>
						</ErrorTooltip>
						<ErrorTooltip showOnError='require' notHide>
							<Text size='xxSmall' font='mono' bold>Username is required</Text>
						</ErrorTooltip>
					</FormElement>
				</FormElementWrapper>

				<FormElementWrapper>
					<FormElement control={passwordControl}>
						<PasswordManagerDisabler name='pswd'/>
						<PasswordInput skeleton={skeleton} placeholder='e.g. 1234' hide={isPasswordHide} dataCy='password-input'/>
						<ErrorTooltip showOnError='range-length-lower' notHide>
							<Text size='xxSmall' font='mono' bold>Minimum 4 characters</Text>
						</ErrorTooltip>
						<ErrorTooltip showOnError='range-length-upper' notHide>
							<Text size='xxSmall' font='mono' bold>Maximum 20 characters</Text>
						</ErrorTooltip>
						<ErrorTooltip showOnError='require' notHide>
							<Text size='xxSmall' font='mono' bold>Password is required</Text>
						</ErrorTooltip>
					</FormElement>
				</FormElementWrapper>

				<EyeIconWrapper skeleton={skeleton} onClick={() => isDisabledEyeButton ? noop() : setPasswordHide(prev => !prev)} active={!isPasswordHide} disabled={isDisabledEyeButton}>
					<Eye width={24} height={24}/>
				</EyeIconWrapper>
			</StudentCredentialsWrapper>
	</Drawer.ContentBlock>;
}
