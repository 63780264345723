import {useEffect} from 'react';
import {NotTested, ChangeFunction} from '../types';
import {SettingsPanel} from '../settings-panel';
import {type Option, ToggleGroup} from '../toggle-group';

interface Props {
	disabled?: boolean;
	value?: NotTested;
	onChange: ChangeFunction<NotTested>;
}

const displays: Option[] = [
	{id: NotTested.NT, label: 'NT'},
	{id: NotTested.Zero, label: 'Zero'},
];

export function NotTestedControl(props: Props) {
	const {disabled, value, onChange} = props;

	useEffect(() => {
		if (typeof value === 'undefined') {
			onChange(NotTested.NT);
		}
	}, [value, onChange]);

	return (
		<SettingsPanel title='Display Not Tested As'>
			<ToggleGroup
				value={value}
				options={displays}
				disabled={disabled}
				onChange={(value) => onChange(value as NotTested)}
				dataCy='display-not-tested-as-toggle-group'
			/>
		</SettingsPanel>
	);
}
