import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';

export const TabsControllerRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
	marginBottom: '12px',
	marginTop: '8px',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr',
	backgroundColor: '$background',

	variants: {
		defaultActions: {
			true: {
				gridTemplateColumns: '1fr 1fr 1fr',
			},
		},
	},
});

export const ActionName = styled(Text, {
	fontWeight: '600',
	fontSize: '$medium',
	lineHeight: '16px',
	color: '$base',
});
