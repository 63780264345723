import {ExpandablePanel, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const SectionBox = styled(FlexBox, {
	height: '100vh',
});

export const ExpandablePanelContent = styled(ExpandablePanel.Content, {
	width: 232,
	height: 'calc(100% - 40px)',
});

export const ExpandablePanelStyled = styled(ExpandablePanel, {
	background: 'neutral94',
	backgroundImage: 'linear-gradient(332deg, rgb(250, 250, 250) 17.26%, rgb(240, 240, 240) 65.48%)',
});
