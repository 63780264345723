import {useCallback, useEffect, useState} from 'react';
import {ClassState} from './types';
import {Class, Student} from '@esgi/main/libs/store';
import {isUndefined} from '@esgi/ui';

interface Props {
	classes: Class[];
	students: Student[];
}

export function useClassList({classes, students}: Props) {
	const [state, setState] = useState<ClassState[]>([]);

	useEffect(() => {
		setState((currentState) =>
			classes.map<ClassState>(({studentIDs, ...classData}, i) => {
				const classStudents = students.filter(({id}) => studentIDs.includes(id));
				const current = currentState.find(({id}) => classData.id === id);

				return {...classData, students: classStudents, isListOpen: !!current?.isListOpen || !i};
			}),
		);
	}, [classes, students]);

	const onToggle = useCallback((isListOpen: boolean, classId: Class['id']) => {
		setState((currentState) => {
			const copied = [...currentState];
			const copiedClass = copied.find(({id}) => id === classId);

			if (copiedClass) {
				copiedClass.isListOpen = isListOpen;
			}

			return copied;
		});
	}, []);

	const onSelectAll = useCallback(
		({
			classId,
			selectedStudentIds,
			isListSelected,
		}: {
			classId: Class['id'];
			selectedStudentIds: Student['id'][];
			isListSelected: boolean;
		}) => {
			const statedClass = state.find(({id}) => classId === id);

			if (isUndefined(statedClass)) {
				return selectedStudentIds;
			}

			const studentsIDsInClass = statedClass.students.map(({id}) => id);

			if (isListSelected) {
				return selectedStudentIds.filter((studentID) => !studentsIDsInClass.includes(studentID));
			}

			return [...selectedStudentIds, ...studentsIDsInClass.filter((id) => !selectedStudentIds.includes(id))];
		},
		[state],
	);

	return {
		state,
		onToggle,
		onSelectAll,
	};
}
