import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const IndicatorWrapper = styled(Box, {
	variants: {
		status: {
			'active': {
				color: '$base',
			},

			'default': {
				color: '$muted',
			},

			'inProgress': {
				color: '$primary',
			},
		},
	},
});
