import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Link} from 'react-router-dom';
import {AlertBodyGrid} from './alert-grid-body';

const supportUrl = 'https://support.esgisoftware.com/hc/en-us/requests/new';

// TODO: Should be added a renew page URL
const renewUrl = '/';

type Props = {
	onAlertClose: VoidFunction;
	validationData: string;
};

export function PurchasingStudents({onAlertClose, validationData}: Props) {
	return (
		<>
			<Alert.Header>
				<Text size='large'>Your File Exceeds the Maximum Number of Students</Text>
			</Alert.Header>
			<AlertBodyGrid>
				<Text size='medium'>You have reached a maximum number of {validationData} students in your classes.</Text>
				<Text size='medium'>
					Please remove students or increase the maximum number by{' '}
					<Link to={renewUrl} target='_blank'>
						purchasing additional student slots
					</Link>
					.
				</Text>
				<Text size='medium'>
					Please{' '}
					<Link to={supportUrl} target='_blank'>
						contact us
					</Link>{' '}
					with any questions.
				</Text>
			</AlertBodyGrid>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
