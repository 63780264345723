import {FormField, Validators} from '@esgi/deprecated/knockout';
import {AsyncFieldContainer} from '../../common/component';
import {InvitationServer, ValidateCodeResponse} from '../../server';

import './invitation-code.less';

export class InvitationCodeComponent extends AsyncFieldContainer<string> {
	private value = '';

	declare validationCodeResponse: KnockoutObservable<ValidateCodeResponse>;

	buildField(initValue?): FormField<string> {
		this.validationCodeResponse = ko.observable<ValidateCodeResponse>(
			{} as ValidateCodeResponse,
		);

		const icValidator = Validators.Ajax(
			(f) => {
				this.inProcess(true);
				return InvitationServer.validateCode(f.value()).always(() => {
					this.inProcess(false);
				});
			},
			(result) => {
				const fieldValue = this.field.value().trim();
				if (this.value !== fieldValue) {
					this.value = fieldValue;
					if (result !== this.validationCodeResponse()) {
						this.validationCodeResponse(result);
					}
				}
				if (!result.isActive) {
					return 'This invitation has been deactivated. Please contact support@esgisoftware.com with any questions';
				}
				if (result.outOfUsages) {
					return `The maximum of allowed registrations (${result.allotment}) for this code has been reached`;
				}
				if (!result.validExpirationDate) {
					let message =
						'We\'re unable to create your account as there are no current teachers in your school or district.';
					if (result.userType === 'ISS' || result.userType === 'ISD') {
						message =
							'Sorry. Your account is not permitted to make this purchase.';
					}
					return (
						message +
						'<br />Please contact support at <a href=\'https://www.esgisoftware.com/support\'>https://www.esgisoftware.com/support</a>'
					);
				}
				if (!result.isValid) {
					return 'The code is not valid';
				}

				this.field.disabled(true);
			},
		);

		const f = this.createField<string>(
			initValue,
			Validators.Required(),
			icValidator,
		);

		f.validation.successValidation(true);
		f.validation.showValidation(true);
		f.validation.validationMessageTitleUseHtml = true;
		return f;
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		return super.afterRender(rootElement);
	}

	template = () => {
		return (
			<div data-bind='var : {root: $data}, afterRender: true'>
				<ko data-bind='with: field'>
					<div
						className='form-group invitation-code-field'
						data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true"
					>
							<label>Invitation Code</label>
							<input
								type='text'
								className='form-control input-md'
								id='code'
								data-bind='value: value, disable: disabled()'
								autoFocus={true}
							/>
							<div className='error-message visible-xs visible-sm hidden-md'>
								<span data-bind='text:validation.validationResults' />
							</div>
							<ko data-bind='if: !root.inProcess()'>
								<i data-bind="css: {'fa-check': showSuccessValidation, 'fa-close': showErrorValidation}" className='fa form-control-feedback'/>
							</ko>
							<ko data-bind='if: root.inProcess()'>
								<i className='fa fa-spinner fa-spin form-control-feedback' />
							</ko>
					</div>
				</ko>
			</div>
		);
	};
}
