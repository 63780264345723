import {Class, Group, Student, SubjectType} from '@esgi/main/libs/store';

export type CommonURLParams = {
	subjectID: number;
	subjectType: SubjectType;
	testID: number;
};

export enum DasboardEntityType {
	Class = 'Class',
	Group = 'Group',
	Student = 'Student',
}

export type URLParams = CommonURLParams &
	(
		| {
				entityType: DasboardEntityType.Class;
				classID: Class['id'];
		  }
		| {
				entityType: DasboardEntityType.Group;
				groupID: Group['id'];
		  }
		| {
				entityType: DasboardEntityType.Student;
				studentID: Student['id'];
				studentFromClassID: Class['id'] | null;
				studentFromGroupID: Group['id'] | null;
		  }
	);

export enum StudentDashboardSearchKey {
	StudentFromClassID = 'studentFromClassID',
	StudentFromGroupID = 'studentFromGroupID',
}
