import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {ButtonsContainer} from './index.styled';

type Props = ComponentPropsWithoutRef<'div'> & BaseComponentProps;

export const OrderButtonsRoot = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'assignments-order-buttons-root', css = {}, children, ...props}, forwardedRef) => (
		<ButtonsContainer dataCy={dataCy} css={css} {...props} ref={forwardedRef}>
			{children}
		</ButtonsContainer>
	),
);
