import {Text} from '@esgi/ui/typography';
import {SelectField, SelectOption} from './index.styled';
import {Select as SelectUI} from '@esgi/ui/controls';

export type OptionItem<T extends string | number = string> = {
	value: T;
	label: string;
};

type Props<T extends string> = {
	selectedValue: T | undefined;
	items: OptionItem<T>[];
	onValueChange: (value: T) => void;
	placeholder: string;
	disabled?: boolean;
	minWidth?: number;
	defaultValue?: T;
	displayDefaultLabel?: boolean;
	dataCy?: string;
};

export function Select<T extends string>({
	selectedValue,
	onValueChange,
	items,
	placeholder,
	disabled,
	minWidth,
	defaultValue,
	displayDefaultLabel,
	dataCy,
}: Props<T>) {
	return (
		<SelectUI.Root value={selectedValue} onValueChange={onValueChange} disabled={Boolean(disabled)} dataCy={dataCy}>
			<SelectField placeholder={placeholder} css={{minWidth}} />
			<SelectUI.Content>
				{items.map(({label, value}) => (
					<SelectOption
						value={value}
						key={value}
						displayDefaultLabel={Boolean(defaultValue === value && displayDefaultLabel)}
					>
						<Text size='medium' bold>
							{label}
						</Text>
					</SelectOption>
				))}
			</SelectUI.Content>
		</SelectUI.Root>
	);
}
