import {SingleStudentTestCard} from './card-variant/list-cards/variants/single-student-cards';
import {GroupStudentsTestCard} from './card-variant/list-cards/variants/group-students-cards';
import {CardGroupDroppable as ListCardGroupDroppable} from './card-variant/list-cards/components/card-group-droppable';
import {DraggableCard} from './card-variant/list-cards/components/draggable-card';

import {SingleStudentCard as GridSingleStudentCard} from './card-variant/grid-cards/variants/single-student-card/default-card';
import {DraggableCard as GridSingleStudentDraggableCard} from './card-variant/grid-cards/variants/single-student-card/draggable-card';
import {GroupStudentsCard as GridGroupStudentsCard} from './card-variant/grid-cards/variants/group-students-card/default-card';
import {DraggableCard as GridGroupStudentsDraggableCard} from './card-variant/grid-cards/variants/group-students-card/draggable-card';

export const TestCard = {
	ListCard: {
		SingleStudentCard: SingleStudentTestCard,
		GroupStudentsCard: GroupStudentsTestCard,
		CardGroupDroppable: ListCardGroupDroppable,
		DraggableCard: DraggableCard,
	},
	GridCard: {
		SingleStudentCard: {
			Default: GridSingleStudentCard,
			Draggable: GridSingleStudentDraggableCard,
		},
		GroupStudentsCard: {
			Default: GridGroupStudentsCard,
			Draggable: GridGroupStudentsDraggableCard,
		},
	},
};
