import ReportTable from '@esgi/deprecated/ui-kit/report/table/report-table';
import {TableLevelReportService} from '../../../../services/table-level-report-service';
import {useStream} from '@esgillc/ui-kit/utils';
import {useStudentnameColumn} from './hooks/use-student-name-column';
import {useCriteriaResultColumns} from './hooks/use-criteria-results-columns';
import {useResultColumn} from './hooks/use-result-column';
import {isNull} from 'underscore';
import styles from './styles/styles.module.less';

type TableProps = {
	service: TableLevelReportService;
};

export function Table({service}: TableProps) {
	const rows = useStream(service.reportService.reportRows);
	const levels = useStream(service.levels$);
	const sortBy = useStream(service.reportService.sortModel);
	const sortByCriteriaResultsOptions = useStream(service.sortByCriteriaResultsOptionsObserver$);
	const studentNameColumn = useStudentnameColumn({service, sortBy});
	const criteriaResultColumns = useCriteriaResultColumns({
		rows,
		levels,
		service,
		sortBy,
		sortByCriteriaResultsOptions,
	});
	const resultColumn = useResultColumn({service, rows});

	if (isNull(criteriaResultColumns)) {
		return null;
	}

	return (
		<ReportTable
			rows={rows}
			columns={[studentNameColumn, ...criteriaResultColumns, resultColumn]}
			className={styles.tableContainer}
		/>
	);
}
