import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
	value: number | null;
	maxValue: number;
	caption?: string;
};

export function AnswersInfo({value, maxValue, caption}: Props) {
	return (
		<GridBox>
			<FlexBox align={value ? 'end' : 'center'} dataCy='answer-info-value'>
				<Text css={{lineHeight: '14px'}} size='medium' bold font='mono' color='base'>
					{value ?? '-'}
				</Text>
				<Text size='xSmall' font='mono' color='lowContrast'>
					{' '}
					/{maxValue}
				</Text>
			</FlexBox>
			{caption && (
				<Text size='xSmall' font='mono' color='mediumContrast' data-cy='answer-info-caption'>
					{caption}
				</Text>
			)}
		</GridBox>
	);
}
