import {memo, useCallback, useMemo} from 'react';
import {Row} from '../../styled';
import {useTableRowStyles} from '../../hooks/use-table-row-styles';
import {Text} from '@esgi/ui/typography';
import {FlexBox} from '@esgi/ui/layout';
import {SpecialistModel, StudentModel} from '../../../../types';
import {Cell, NameCell, NameCheckbox, NameText, Timestamp} from './styled';
import {DrawerMode, useSwitchDrawerMode} from '@esgi/main/features/teacher/home';
import {TeacherDrawerName} from '@esgi/main/features/teacher/drawers';
import {StudentInfo} from '../../types';
import {OneLinedText} from '@esgi/main/kits/assignments';
import {isNull} from 'underscore';

type Props = {
	virtualKey: any,
	selected: boolean,
	agreementLevel: string,
	studentInfo: StudentInfo,
	onSelect: (model: StudentModel) => void,
	specialists: SpecialistModel[]
	showSpecialistColumns: boolean,
}

function StudentRowImpl(props: Props) {
	const {
		name,
		classes,
		grade,
		groups,
		createDate,
		readonly,
		teacher,
		model,
		id,
	} = props.studentInfo;
	const styles = useTableRowStyles(props.agreementLevel);
	const onSelect = useCallback(() => props.onSelect(model), [props.onSelect, model]);
	const switchProfile = useSwitchDrawerMode({drawerName: TeacherDrawerName.TeacherStudentViaProfile});

	const onClick = useCallback(() => {
		if (!props.studentInfo.readonly) {
			switchProfile({mode: DrawerMode.Edit, search: {studentId: model.studentID.toString()}});
		}
	}, [switchProfile, props.studentInfo]);

	const classesStr = classes.map(c => c.name).sort().join(', ');
	const groupsStr = groups.map(g => g.name).sort().join(', ');

	const getSpecialistsNames = () => {
		if(props.specialists.length){
			const specialists = model.specialistGroupUserIDs.map(id => props.specialists.find(sp => sp.userID === id));

			return specialists.map(sp => sp.name).sort().join(', ');
		}

		return null;
	};

	const renderSpecialistColumns = useMemo(() => {
		if(!props.showSpecialistColumns){
			return null;
		}

		return <Cell dataCy='student-specialist'>
			{getSpecialistsNames()?.length ? <OneLinedText size='medium' color='neutral40'>{getSpecialistsNames()}</OneLinedText>
				: <Text size='medium' color='muted'>Unassigned</Text>}
			</Cell>;
	}, [props.showSpecialistColumns]);

	return <Row css={styles} key={props.virtualKey}>
		<NameCell>
			<NameCheckbox checked={props.selected} onCheckedChange={onSelect} disabled={readonly} dataCy='student-checkbox'/>
			<NameText size='medium' onClick={onClick} readonly={readonly} data-cy='student-name'>
				{name}
			</NameText>
		</NameCell>
		<Cell dataCy='student-grade'>
			<OneLinedText size='medium' color='neutral40'>
				{grade?.name}
			</OneLinedText>
		</Cell>
		{props.agreementLevel !== 'T' && <Cell dataCy='student-teacher'>
			{!isNull(teacher) ? <OneLinedText size='medium' color='neutral40'>{teacher.firstName} {teacher.lastName}</OneLinedText> :
				<Text size='medium' color='muted'>Unassigned</Text>}
		</Cell>}
		<Cell dataCy='student-classes'>
			{props.studentInfo.classes.length ? <OneLinedText size='medium' color='neutral40'>{classesStr}</OneLinedText> :
				<Text size='medium' color='muted'>Unassigned</Text>}
		</Cell>
		<Cell dataCy='student-groups'>
			{props.studentInfo.groups.length ? <OneLinedText size='medium' color='neutral40'>{groupsStr}</OneLinedText> :
				<Text size='medium' color='muted'>No Groups</Text>}
		</Cell>
		{renderSpecialistColumns}
		<Cell dataCy='student-created'>
			<FlexBox css={{height: '100%'}} align='center'>
				<Timestamp>
					<Text font='mono' size='small' color='neutral40'>
						{createDate}
					</Text>
				</Timestamp>
			</FlexBox>
		</Cell>
		<Cell dataCy='student-id'>
			<OneLinedText font='mono' size='medium' color='neutral40'>
				{id}
			</OneLinedText>
		</Cell>
	</Row>;
}

export const StudentRow = memo(StudentRowImpl);
