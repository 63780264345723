import {UserType} from '@esgi/core/authentication';
import {SubjectType} from '@esgi/core/enums';

export function canEditSubject(subjectType: SubjectType, userType: UserType): boolean {
	if (subjectType === SubjectType.Stock) {
		return false;
	}

	if (subjectType === SubjectType.Deployed && [UserType.D, UserType.C].includes(userType)) {
		return true;
	}

	return subjectType === SubjectType.Personal && [UserType.T, UserType.ISS, UserType.ISD, UserType.PA].includes(userType);
}
