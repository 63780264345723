import {Title} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {StudentProfileMode} from 'modules/forms/students-form/types';
import styles from './styles.module.less';

interface Props {
	mode: StudentProfileMode;
	onOpenModal?: (value: boolean) => void;
	onDeleteStudent?: () => void;
	isShowUploadRosterButton?: boolean;
	isShowDeleteStudentButton?: boolean;
}

export function ModalTitle({
	mode,
	onOpenModal,
	onDeleteStudent,
	isShowDeleteStudentButton,
	isShowUploadRosterButton,
}: Props) {
	switch (mode) {
		case StudentProfileMode.add:
			return <div data-cy='add-title' className={styles.uploadRosterTitle}>
				<Title className={styles.title}>Add Student</Title>

				{isShowUploadRosterButton && <div data-cy='upload-roster-container' className={styles.uploadRosterTitle}>
					<span className={styles.breakLine}>|</span>
					<Buttons.Link className={styles.uploadRosterButton}
												onClick={() => onOpenModal(true)}>Upload Roster</Buttons.Link>
				</div>}
			</div>;
		case StudentProfileMode.edit:
			return <div data-cy='edit-title' className={styles.uploadRosterTitle}>
				<Title className={styles.title}>Edit Profile</Title>
				{isShowDeleteStudentButton && <>
					<span className={styles.breakLine}>|</span>
					<Buttons.Link className={styles.uploadRosterButton}
								  onClick={onDeleteStudent}>Delete Student</Buttons.Link>
				</>}
			</div>;

		case StudentProfileMode.view:
			return <div data-cy='view-title'><Title data-cy='view-title' className={styles.title}>View Profile</Title>
			</div>;
	}
}
