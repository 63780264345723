import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Alert} from '@esgi/ui/alert';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
	flexDirection: 'column',
	justifySelf: 'center',
	overflow: 'auto',
	maxHeight: '75vh',
	maxWidth: '98vw',
	padding: 0,

	variants: {
		showChart: {
			true: {
				width: 'max-content',
			},
		},
	},
});

export const ReportWrapper = styled(FlexBox, {
	flexGrow: 1,
	overflow: 'hidden',
});
