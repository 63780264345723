import {CrossIcon} from '@esgillc/ui-kit/icons';
import styles from './styles.module.less';
import {CROSS_ICON_SIZE} from 'pages/assignment-center/constants';

type RemoveColumnProps = {
	removeSelectedStudent: VoidFunction;
};

export function RemoveColumn({removeSelectedStudent}: RemoveColumnProps) {
	return (
		<CrossIcon
			className={styles.icon}
			height={CROSS_ICON_SIZE}
			width={CROSS_ICON_SIZE}
			color='#0077B8'
			onClick={removeSelectedStudent}
		/>
	);
}
