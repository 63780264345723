import React from 'react';
import {Text} from '@esgi/ui/typography';
import {Info} from '@esgi/ui';
import {MainTable, TableBody, HeaderRow, Row, Cell, TableHeader} from '../index.styled';
import {AvgContainer, Link, TestCellContainer, TestName} from './index.styled';
import {ArrowIcon} from '../arrow';
import {Cell as TableCell, MarkingPeriod, Report, Row as ReportRow, TestModel} from '../../../../../service/types';
import {SortOptions} from '../../../types';

type Props = {
	report: Report;
	markingPeriod: MarkingPeriod;
	testResultsCorrectVerbiage: string;
	onSortableCellClick: (cellIndex: number) => void;
	sortableRows: ReportRow[];
	cellRender: (cell: TableCell) => string | number;
	isCellShown: (cellIndex: number) => boolean;
	showOutOf: (test: TestModel) => boolean;
	sortOptions: SortOptions;
	previousSortedColumnIndex: number;
}

export function RightPanel({
	 report,
	 markingPeriod,
	 testResultsCorrectVerbiage,
	 onSortableCellClick,
	 sortableRows,
	 cellRender,
	 isCellShown,
	 showOutOf,
	 sortOptions,
	 previousSortedColumnIndex,
}: Props) {

	return (
		<MainTable data-cy='right-panel'>
			<TableHeader>
				<HeaderRow data-cy='header-row'>
					{report.tests.map((test, index) => (
						<Cell data-cy='header-top-cell' simple bgGray key={index} colSpan={report.maxMarkingPeriods + 1}>
							{
								test.id !== 0 ? (
									<TestCellContainer data-cy='cell-test-container'>
										<TestName data-cy='test-name' size='large'>{test.name}</TestName>
										{showOutOf(test) && (
											<Text data-cy='out-of-text' size='small'>({`out of ${test.questionsCount}`})</Text>
										)}
									</TestCellContainer>
								) : (
									<AvgContainer>
										<Text size='medium'>Avg</Text>
										<Text size='medium'>{testResultsCorrectVerbiage} (%)</Text>
										<Link
											href='https://support.esgisoftware.com/hc/en-us/articles/209159646-Average-Correct'
											target='_blank'
											rel='noreferrer'
										>
											<Info width={24} height={24} />
										</Link>
									</AvgContainer>
								)}
						</Cell>
					))}
				</HeaderRow>
				<Row data-cy='header-second-row'>
					{report.table.sortRow.cells
						.map((cell, index) => (
							index >= report.valueToSkip && isCellShown(index) &&
							<Cell
								data-cy='header-bottom-cell'
								simple
								bgGray
								key={index}
								colSpan={markingPeriod === MarkingPeriod.Current ? report.maxMarkingPeriods + 1 : 1}
							>
								<ArrowIcon
									previousSortedColumnIndex={previousSortedColumnIndex}
									sortOptions={sortOptions}
									sorted={index === sortOptions.columnIndex}
									cellValue={cell.value}
									onClick={() => onSortableCellClick(index)}
								/>
							</Cell>
						))}
				</Row>
			</TableHeader>
			<TableBody>
				{sortableRows.map((row, rowIndex) => (
					<Row data-cy='body-row' bgLightgray={rowIndex % 2 !== 0} dataBody key={rowIndex}>
						{row.cells.map((cell, cellIndex) => (
							cellIndex >= report.valueToSkip && isCellShown(cellIndex) && (
								<Cell
									data-cy='body-cell'
									simple
									key={cellIndex}
									colSpan={markingPeriod === MarkingPeriod.Current ? report.maxMarkingPeriods + 1 : 1}
								>
									{cellRender(cell as TableCell)}
								</Cell>
							)
						))}
					</Row>
				))}
			</TableBody>
		</MainTable>
	);
}
