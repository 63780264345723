import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ContentBox = styled(GridBox, {
	width: 560,
	overflow: 'hidden',

	variants: {
		gap: {
			40: {
				gap: 40,
			},

			60: {
				gap: 60,
			},
		},
	},

	defaultVariants: {
		gap: 40,
	},
});

export const UserNameBox = styled(GridBox, {
	gap: '$3',
	gridTemplateColumns: 'minmax(80px, min-content) 1fr 1fr',
});
