import {GrowthReportService} from 'modules/reports/growth-report/services/growth-report-service';
import ReportOptions from 'modules/reports/growth-report/components/report-options';
import TableBody from './components/table-body';
import TableHead from 'modules/reports/growth-report/components/table-head';
import styles from './growth-report.module.less';
import {IsTruncatedDialog} from 'modules/reports/growth-report/components/IsTruncatedAlert';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

interface GrowthReportProps {
	growthReportService: GrowthReportService;
}

export default function GrowthReport(props: GrowthReportProps) {
	const {growthReportService} = props;
	const isTruncated = useBehaviorSubject(growthReportService.isTruncated)

	return (
		<div className={`report ${styles.growthReport}`}>
			<ReportOptions growthReportService={growthReportService} />
			<div className={styles.growthReportBodyContainer}>
				<div className={styles.growthReportBody} style={{height: '100%'}}>
					<div className={styles.reportHolder}>
						<table id='table-growth-report' className={styles.tableReport}>
							<TableHead growthReportService={growthReportService} />
							<TableBody growthReportService={growthReportService} />
						</table>
					</div>
				</div>
			</div>
			{isTruncated && <IsTruncatedDialog onClose={() => growthReportService.changeIsTruncated(false)} />}
		</div>
	);
}
