import {useCallback, useMemo} from 'react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';

import {AllOptionItem} from '../types';
import {useGroupsContext} from '../context';

import {useStudentsPanelContext} from '../../../../context';

type Props = {
	disabled?: boolean;
};

export function ClassSelect({disabled}: Props) {
	const {
		entities: {classes},
	} = useStudentsPanelContext();
	const {selectedClassID, setSelectedClassID} = useGroupsContext();

	const selectOptionItems = useMemo(
		() => [
			{
				value: AllOptionItem.Value,
				label: AllOptionItem.Label,
			},
			...classes.map(({id, name}) => ({value: String(id), label: name})),
		],
		[classes],
	);

	const onClassSelectValueChange = useCallback(
		(value: string) => {
			setSelectedClassID(value === AllOptionItem.Value ? value : Number(value));
		},
		[setSelectedClassID],
	);

	return (
		<Select.Root value={String(selectedClassID)} onValueChange={onClassSelectValueChange} disabled={Boolean(disabled)}>
			<Select.Field
				placeholder='Class'
				css={{
					maxHeight: 40,
				}}
			/>
			<Select.Content>
				{selectOptionItems.map(({label, value}) => (
					<Select.Option value={value} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</Select.Option>
				))}
			</Select.Content>
		</Select.Root>
	);
}
