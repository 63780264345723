import {Fragment} from 'react';
import {getUser, UserType} from '@esgi/core/authentication';
import {SubjectType} from '@esgi/core/enums';
import {dispatchCreateEvent, dispatchUpdateEvent} from './events';
import {SaveResult} from './types';
import DistrictTabDetails from './components/editors/district';
import PersonalTabDetails from './components/editors/personal';
import SchoolTabDetails from './components/editors/school';

class Props {
	subjectID?: number;
	subjectName?: string;
	selectAfterSave?: boolean = false;
	onClosed: () => void;
	onSaved: (subjectID: number, name: string, type: SubjectType, published: boolean) => void;
}

export default function SubjectDetails({
	subjectID,
	subjectName,
	selectAfterSave,
	onClosed,
	onSaved,
}: Props) {
	const currentUser = getUser().userType;
	const personalTabDetailsUserTypes = [UserType.T, UserType.ISD, UserType.ISS, UserType.PA];

	const onSave = (response: SaveResult, gradeLevels?: number[]): void => {
		const type = SubjectType[response.subjectType];
		onSaved(response.id, response.subjectName, type, response.published);

		if (subjectID) {
			dispatchUpdateEvent(response.id, response.published, response.subjectName, type, gradeLevels);
		} else {
			dispatchCreateEvent(response.id, response.published, response.subjectName, type, gradeLevels, selectAfterSave);
		}
	};

	if (currentUser === UserType.D) {
		return <DistrictTabDetails
			editMode={!!subjectID}
			subjectID={subjectID}
			onClosed={onClosed}
			onSaved={onSave}/>;
	}
	if (currentUser === UserType.C) {
		return <SchoolTabDetails
			editMode={!!subjectID}
			subjectID={subjectID}
			onClosed={onClosed}
			onSaved={onSave}/>;
	}
	if (personalTabDetailsUserTypes.includes(currentUser)) {
		return <PersonalTabDetails
			editMode={!!subjectID}
			subjectID={subjectID}
			subjectName={subjectName}
			onClosed={onClosed}
			onSaved={onSave}/>;
	}
	return <Fragment/>;
}





