import {Dispatch, useCallback} from 'react';
import {StudentFullName, getListValue} from '@esgi/main/features/teacher/home';
import {SelectableList, SelectableListItem, SelectableLisItemContent} from './index.styled';
import {Student} from '@esgi/main/libs/store';

type Props = {
	selectedStudentId: Student['id'][] | null;
	setSelectedStudentId: Dispatch<Student['id']>;
	students: Student[];
};

export function SelectableListMultiple({selectedStudentId, setSelectedStudentId, students}: Props) {
	const value = (selectedStudentId || []).map(getListValue);
	const checked = useCallback((id: string) => value.includes(id), [value]);

	return (
		<SelectableList>
			<SelectableList.GroupRoot type='multiple' value={value}>
				<SelectableList.Group>
					{students.map(({firstName, lastName, id}) => (
						<SelectableListItem
							key={id}
							value={String(id)}
							checked={checked(String(id))}
							onClick={() => setSelectedStudentId(id)}
						>
							<SelectableLisItemContent>
								<StudentFullName data-cy='student-full-name' firstName={firstName} lastName={lastName} />
							</SelectableLisItemContent>
						</SelectableListItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
