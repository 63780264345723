import {useEffect, useMemo} from 'react';
import {usePagination} from '../../../kit';
import {TestSession} from '../../../types';

export function useVisibleData({
	pageSize,
	sessions,
}: {
	pageSize: number;
	sessions: TestSession[];
}) {
	const {currentPageIndex, totalPages, nextPage, previousPage, pageChange} =
		usePagination({
			totalElements: sessions.length,
			pageSize,
		});

	useEffect(() => {
		pageChange(0);
	}, [sessions]);

	const visibleData = useMemo<TestSession[]>(() => {
		const nextNotVisibleIndex = currentPageIndex * pageSize;
		const visibleItems = sessions.slice(
			nextNotVisibleIndex,
			nextNotVisibleIndex + pageSize
		);

		if (visibleItems.length === pageSize || totalPages === 1) {
			return visibleItems;
		}

		return visibleItems;
	}, [currentPageIndex, totalPages, pageSize, sessions]);

	return {
		visibleData,
		currentPageIndex,
		totalPages,
		nextPage,
		previousPage,
	};
}
