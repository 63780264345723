import {useCallback, useMemo} from 'react';
import {SectionModeButtonItem, SubjectSectionMode} from '@esgi/main/features/teacher/home';
import {DragDropMode, Edit} from '@esgi/ui/icons';
import {SubjectSectionModeButtonsPermissions, SubjectSectionModePermissions, SubjectTabId} from '../../../types';

export function useSectionModeButtons({
	sectionMode,
	activeTab,
	setSectionMode,
	sectionModePermissions,
}: {
	sectionMode: SubjectSectionMode;
	activeTab: SubjectTabId;
	setSectionMode: (sectionMode: SubjectSectionMode) => void;
	sectionModePermissions: SubjectSectionModePermissions;
}) {
	const modeButtonsPermissions = useMemo<SubjectSectionModeButtonsPermissions>(
		() => ({
			[SubjectSectionMode.Edit]: sectionModePermissions[SubjectSectionMode.Edit][activeTab],
			[SubjectSectionMode.Rearrange]: sectionModePermissions[SubjectSectionMode.Rearrange][activeTab],
		}),
		[activeTab, sectionModePermissions],
	);

	const handleSetSectionMode = useCallback(
		(activateMode: SubjectSectionMode) => {
			setSectionMode(activateMode === sectionMode ? SubjectSectionMode.View : activateMode);
		},
		[sectionMode, setSectionMode],
	);

	const sectionModeButtons = useMemo(
		() =>
			[
				{
					activateMode: SubjectSectionMode.Edit,
					Icon: Edit,
					dataCy: 'subjects-panel-action-button-edit',
					tooltip: 'Edit',
				},
				{
					activateMode: SubjectSectionMode.Rearrange,
					Icon: DragDropMode,
					dataCy: 'subjects-panel-action-button-drag-and-drop',
					tooltip: 'Reorder',
				},
			].map<SectionModeButtonItem>(({Icon, tooltip, activateMode, dataCy}) => ({
				dataCy,
				Icon,
				tooltip,
				onClick: () => handleSetSectionMode(activateMode),
				isActive: sectionMode === activateMode,
				permitted:
					modeButtonsPermissions[activateMode] &&
					(sectionMode === SubjectSectionMode.View || sectionMode === activateMode),
			})),
		[sectionMode, handleSetSectionMode, modeButtonsPermissions],
	);

	return {sectionModeButtons};
}
