import {styled} from '@esgi/ui/theme';
import {Radio} from '@esgillc/ui-kit/control';
import {Box} from '@esgi/ui/layout';

export const RadioStyled = styled(Radio, {
	marginRight: '13px',
	fontSize: '12px',
});

export const ReportFilterContainerStyled = styled(Box, {
	display: 'flex',
	borderRight: '2px solid #FFFFFF',
	padding: '10px',
	alignItems: 'flex-start',
	flexDirection: 'column',
	paddingLeft: '0px',
});

export const ReportFilterLabelStyled = styled(Box, {
	fontWeight: 700,
	textAlign: 'left',
	fontSize: '13px',
});

export const ReportFiltersContentStyled = styled(Box, {
	display: 'flex',
	marginBottom: '5px',
	marginRight: 0,
});

export const Item = styled(Box, {
	display: 'flex',
	cursor: 'default',
	alignItems: 'center',
	justifyContent: 'center',
	width: '25px',
	height: '25px',
	backgroundColor: '#FFFFFF',
	border: '1px solid #BDBDBD',
	marginRight: '10px',
	paddingTop: '1px',
});

export const SmallLabel = styled(Box, {
	fontSize: '12px',
	marginLeft: '8px',
});