import {ComponentProps, forwardRef, useCallback} from 'react';
import {ElementStatus, useFormElementContext} from '@esgillc/ui-kit/form';
import {CheckedState} from '@radix-ui/react-checkbox';
import {Checkbox as BaseCheckbox} from '@esgi/ui/controls';

type CheckboxProps = Omit<ComponentProps<typeof BaseCheckbox>, 'onCheckedChange' | 'checked'>;

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(({children, ...props}, ref) => {

	const {status, setValue, value} = useFormElementContext({string: true, boolean: true}, Checkbox);
	const onCheckedChange = useCallback((value: CheckedState) => setValue(value), [setValue]);

	const commonProps = {
		disabled: status === ElementStatus.disabled,
		checked: value,
		onCheckedChange,
		ref,
		...props,
	};

	return <BaseCheckbox {...commonProps}>
		{children}
	</BaseCheckbox>;
});
