import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string
}

export function ThumbnailIcon({className}: Props) {
	return <svg data-cy='set-as-thumbnail-icon' className={join(className)}
							xmlns='http://www.w3.org/2000/svg'
							width='15px'
							height='15px'
							viewBox='0 0 16 16'
							fill='none'>
		<path
			d='M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14
			3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5
			8C15.5 3.86 12.14 0.5 8 0.5ZM6.5 11.75L2.75
			8L3.8075 6.9425L6.5 9.6275L12.1925 3.935L13.25 5L6.5 11.75Z'
			fill='#828282'/>
	</svg>;
}