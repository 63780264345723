
export enum FilterByKey {
	Classes = 'classes',
	Groups = 'groups',
}

export enum ViewFilterValue {
	AllStudents = 'allStudents',
	StudentsWithoutCredentials = 'studentsWithoutCredentials',
}
