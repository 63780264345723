import React, {createRef, RefObject} from 'react';
import {TextArea} from '@esgillc/ui-kit/control';
import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {isIpad} from '@esgillc/ui-kit/utils';
import {ClearIcon} from '../../../../../icons';
import styles from './details.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

class State {
	details: string = '';
}

interface Props {
	initValue: string;
	criteriaName: string;
	levelName: string;
	onSave: (value: string) => void;
	onClose: () => void;
}

export default class DetailsDialog extends React.PureComponent<Props, State> {
	private readonly modalManagerRef: ModalManagerRefObject = createRef();
	private readonly inputRef: RefObject<TextArea> = createRef();
	private readonly isIPad = isIpad();

	constructor(props: Props) {
		super(props);
		const state = new State();
		state.details = props.initValue || '';
		this.state = state;
	}

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Header>
				<Title className={styles.header}>
					{`Details: ${this.props.criteriaName} | ${this.props.levelName}`}
					<OnHoverTooltip className={styles.tooltip}
						message='Additional information can be added here to further describe the criteria level. Details can be referenced during test sessions but will not display on reports.'
					>
						<div className={styles.helpIcon}>
							<svg width='12' height='12' viewBox='0 0 12 12' fill='none'
							     xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M6 0.166664C2.78 0.166664 0.166666 2.78 0.166666 6C0.166666 9.22 2.78 11.8333 6 11.8333C9.22 11.8333 11.8333 9.22 11.8333 6C11.8333 2.78 9.22 0.166664 6 0.166664ZM6.58333 10.0833H5.41667V8.91667H6.58333V10.0833ZM7.79083 5.5625L7.26583 6.09916C6.84583 6.525 6.58333 6.875 6.58333 7.75H5.41667V7.45833C5.41667 6.81666 5.67917 6.23333 6.09917 5.8075L6.8225 5.0725C7.03833 4.8625 7.16667 4.57083 7.16667 4.25C7.16667 3.60833 6.64167 3.08333 6 3.08333C5.35833 3.08333 4.83333 3.60833 4.83333 4.25H3.66667C3.66667 2.96083 4.71083 1.91666 6 1.91666C7.28917 1.91666 8.33333 2.96083 8.33333 4.25C8.33333 4.76333 8.12333 5.23 7.79083 5.5625Z'
									fill='#828282'/>
							</svg>
						</div>
					</OnHoverTooltip>
				</Title>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<div className={styles.inputContainer}>
					<TextArea value={this.state.details}
					          ref={this.inputRef}
					          autoFocus={!this.isIPad}
					          autoResizeToFit
					          maxLength={249}
					          rows={3}
					          placeholder='Enter additional details'
					          onChange={(e) => this.setState({details: e.target.value})}/>
					<div className={styles.clear}>
						<ClearIcon onClick={() => {
							this.setState({details: ''});
							this.inputRef.current.focus();
						}}/>
					</div>
					<div className={styles.counter}>{this.state.details.length}/249</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={this.cancel}>CANCEL</Buttons.Gray>
				<Buttons.Contained onClick={this.save}>SAVE</Buttons.Contained>
			</Modal.Footer>
		</Modal>;
	}

	private cancel = (): void => this.modalManagerRef.current.close(this.props.onClose);

	private save = (): void => this.modalManagerRef.current.close(() => this.props.onSave(this.state.details));
}
