import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {SkeletonBox} from './index.styled';
import {VariantProps} from '@stitches/react';

type SkeletonShapeProps = BaseComponentProps & {
	/**
	 * Width of skeleton shape.
	 */
	width?: number | string;

	/**
	 * Height of skeleton shape.
	 */
	height?: number | string;

	/**
	 * Shape of the skeleton.
	 */
	shape?: NonNullable<VariantProps<typeof SkeletonBox>>['shape'];
} & ComponentPropsWithoutRef<'div'>;

export const SkeletonShape = forwardRef<HTMLDivElement, SkeletonShapeProps>(
	({dataCy = 'ui-kit-skeleton', css = {}, width = 24, height = 24, shape = 'square'}, forwardedRef) => {
		return <SkeletonBox dataCy={dataCy} css={{width, height, ...css}} shape={shape} aria-disabled ref={forwardedRef} />;
	},
);
