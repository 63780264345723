import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Header = styled(GridBox, {
	width: '100%',
	paddingTop: 18,
	gap: 18,
});

export const ContentWrapper = styled(GridBox, {
	alignItems: 'center',

	variants: {
		withChildren: {
			true: {},
		},

		withActionButton: {
			true: {},
		},
	},

	compoundVariants: [
		{
			withChildren: true,
			withActionButton: true,
			css: {
				gridTemplateColumns: 'auto 1fr auto',
			},
		},

		{
			withChildren: true,
			withActionButton: false,
			css: {
				gridTemplateColumns: 'auto 1fr',
			},
		},

		{
			withChildren: false,
			withActionButton: true,
			css: {
				gridTemplateColumns: 'auto auto',
				justifyContent: 'space-between',
			},
		},

		{
			withChildren: false,
			withActionButton: false,
			css: {
				gridAutoColumns: 'max-content',
			},
		},
	],
});

export const Content = styled(GridBox, {
	gap: '$1',
	alignItems: 'center',
	gridAutoFlow: 'column',
	color: '$neutral56',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const ChildrenWrapperBox = styled(Box, {
	width: '100%',
	height: '100%',
});

export const ButtonsContainer = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',
	gap: '$4',
});
