export {type InitRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/teachers/pages/reports/student-details/init-request';
export {type GradeValueModel as Grade} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/student-detail/report/init/grade-value-model';
export {type Response as Report} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/student-detail/report/init/response';
export {type TestModel as Test} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/student-detail/report/init/test-model';
export {type ScoreModel as Score} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/student-detail/report/init/score-model';
export {TestType} from '@esgi/contracts/esgi/types/esgi.enums/test-type';
export {StudentSort} from '@esgi/contracts/esgi/types/esgi.enums/student-sort';

export interface UnitData {
	id: number;
	type: UnitType;
	name?: string;
}

export interface StudentWithUnit {
	studentId: number;
	unitData: UnitData;
}

export enum UnitType {
	Class,
	Group,
	None,
}

export interface DownloadRequest {
	reportGuid: string;
	currentPeriod: boolean;
	includeGradeScore: boolean;
	includeNotes: boolean;
	includeSummaryNotes: boolean;
	notTestedDisplayValue: string;
	printInColor: boolean;
	showBaseline: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	zip: boolean;
}

export type ReportSettingsKeyName =
	| 'carryScoresForward'
	| 'displayZeroIfNotTested'
	| 'includeGradeScore'
	| 'includeQuestionNotes'
	| 'includeSummaryNotes'
	| 'printInColor'
	| 'showBaseline'
	| 'markingPeriodAll';

export interface MarkingPeriodItem {
	index: number;
	title: string;
}

export enum FileType {
	Zip = 'zip',
	Pdf = 'pdf',
}

export type ReportSettingsValue = Record<ReportSettingsKeyName, boolean>;
export type ReportSettingValueChangeParameters = {key: keyof ReportSettingsValue; newValue: boolean};
