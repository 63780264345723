import {EntityModel, FontFamily, LecturerModel, SightWordsPrintSettings, StudentStepState, UnitType} from '../../types';

export class SightWordsReport {
	constructor(
		public details: SightWordsReportDetails,
		public students: SightWordsReportStudentData[],
		public words: SightWord[],
	) {
	}
}

export class SightWordsReportDetails {
	public lecturer: LecturerModel;
	public copiesCount: number;
	public fontSize: number;
	public columns: SightWordColumn[];
	public isFontSizeOptimized: boolean;
	public wordsCountByPage: number;
	public unitType: UnitType;

	constructor(
		unitData: StudentStepState,
		printSettings: SightWordsPrintSettings,
		fontSize: number,
		columns: SightWordColumn[],
		isFontSizeOptimized: boolean,
		wordsCountByPage: number,
	) {
		this.fontSize = fontSize;
		this.unitType = unitData.unitType;
		this.lecturer = unitData.lecturer;
		this.copiesCount = printSettings.copiesCount;
		this.columns = columns;
		this.isFontSizeOptimized = isFontSizeOptimized;
		this.wordsCountByPage = wordsCountByPage;
	}
}

export class SightWordsReportStudentData {
	public unitId: number;
	public unitName: string;

	constructor(
		public studentId: number,
		public fullName: string,
		unitData: EntityModel,
	) {
		this.unitId = unitData.id;
		this.unitName = unitData.name;
	}
}

export class SightWord {
	constructor(public word: string) {
	}
}

export class SightWordColumn {
	constructor(public fontFamily: FontFamily) {
	}
}