import {SelectedTab} from '../../types';
import styles from './style.module.less';

interface TabsProps {
	onChange: (selectedTab: SelectedTab) => void;
	selectedTab: SelectedTab;
	hasSecondaryProfile: boolean;
}

export function Tabs({onChange, selectedTab, hasSecondaryProfile}: TabsProps) {
	return <>
		<div className={styles.tabsRow}>
			<div
				className={selectedTab === SelectedTab.Primary ? styles.activeTab : styles.tab}
				onClick={() => onChange(SelectedTab.Primary)}
			>
				PRIMARY ACCOUNT
			</div>
			{hasSecondaryProfile &&
				<div
					className={selectedTab === SelectedTab.Secondary ? styles.activeTab : styles.tab}
					onClick={() => onChange(SelectedTab.Secondary)}
				>
					SECONDARY PROFILE*
				</div>
			}
		</div>
		<hr className={styles.tabsLine}/>
	</>;
}
