import styles from './styles.module.less';

type ReportErrorProps = {
	errorText: string;
};

export function ReportError({errorText}: ReportErrorProps) {
	return (
		<div className={styles.errorTextContainer} data-cy='report-error'>
			<span>{errorText}</span>
		</div>
	);
}
