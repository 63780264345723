import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ContentWrapper = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',
	gridTemplateRows: '1fr auto',
	gap: '$5',
});

export const ListWrapper = styled(Box, {
	overflow: 'auto',
});
