import {CheckboxControl} from '../../index.styled';
import {Tooltip} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';


interface Props {
	disabled?: boolean;
	onChange: (value: boolean) => void;
	value: boolean;
}

export function ShowAllStudentsControl(props: Props) {
	const {disabled, onChange, value} = props;

	return <Box>
			<Tooltip delayDuration={100}>
				<Tooltip.Trigger>
					<CheckboxControl dataCy='show-all' checked={value} onCheckedChange={(value) => onChange(Boolean(value))} label='Show all' disabled={disabled}/>
				</Tooltip.Trigger>
				<Tooltip.Content variant='default'>
					If checked, all Tests/Students will display. If unchecked, only untested Tests/Students will display
				</Tooltip.Content>
			</Tooltip>
	</Box>;
}
