import {StudentProfileTab} from '../../types';
import {TabsControllerRoot, ToggleGroupContent, TabsControllerItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {items} from './constants';

interface TabsProps {
	onChange: (tab: StudentProfileTab) => void;
	selectedTab: StudentProfileTab;
	isDisabled?: boolean;
	isInitialized: boolean;
	invalidTabIDs: StudentProfileTab[];
}

export function TabsController({selectedTab, onChange, isDisabled, isInitialized, invalidTabIDs}: TabsProps) {

	return <TabsControllerRoot data-cy='student-profile-tabs' value={selectedTab} disabled={isDisabled}>
		<ToggleGroupContent>
			{items.map(({value, label}) => (
				<TabsControllerItem
					value={value}
					onClick={() => onChange(value)}
					skeleton={!isInitialized}
					error={invalidTabIDs.includes(value)}
					key={value}
				>
					<Text size='medium' color='base' bold>
						{label}
					</Text>
				</TabsControllerItem>
			))}
		</ToggleGroupContent>
	</TabsControllerRoot>;
}
