import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, concatMap, Observable, Subject} from 'rxjs';
import {
	FilterModel,
	IdNameModel,
	InitResponse,
	SearchResult,
	SubjectModel,
	TestModel,
	TestsResponse,
} from './types';
import {debounceTime, first, map, skip, switchMap, takeUntil, tap} from 'rxjs/operators';
import {EventBusManager} from '@esgillc/events';
import {SortDirection, SubjectType} from '@esgi/core/enums';
import {mapToTestModel} from './utils';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {SsoTracker} from '@esgi/core/tracker';
import {SubjectEntity} from 'api/entities';
import {TestAddedToSubjectEvent} from 'api/entities/events/subject';

export class FindMoreService extends BaseService {
	public readonly pageSize: number = 35;
	public pageIndex: number = 1;
	public contentAreas = new BehaviorSubject<IdNameModel[]>([]);
	public rows = new BehaviorSubject<TestModel[]>([]);
	public firstLoad = new BehaviorSubject<boolean>(false);
	public subjects = new BehaviorSubject<SubjectModel[]>([]);

	public filter = new BehaviorSubject<FilterModel>({
		sortBy: 'CreateDate',
		sortDirection: SortDirection.Desc,
		contentAreaId: -2,
		keyword: '',
	});

	public searchResult: BehaviorSubject<SearchResult> = new BehaviorSubject<SearchResult>({
		tests: [],
		canLoadMore: false,
		pageIndex: 1,
	});

	private readonly eventBus = new EventBusManager();
	private readonly applyFilterDelayed: Subject<Partial<FilterModel>> = new Subject<Partial<FilterModel>>();
	private onDestroy$: Subject<void> = new Subject();
	private controller = 'modules/ac-find-more-tests';

	constructor() {
		super();
		this.searchResult
			.pipe(
				takeUntil(this.onDestroy$),
				skip(1),
			)
			.subscribe(r => {
				this.rows.next(r.tests);
				this.firstLoad.next(r.pageIndex === 1);
			});

		this.applyFilterDelayed.pipe(
			debounceTime(200),
			takeUntil(this.destroy$),
			concatMap(f => this.innerApplyFilter(f)),
		).subscribe(r => this.searchResult.next(r));

		this.eventBus.subscribe(TestAddedToSubjectEvent, ({testID, subjectID}: TestAddedToSubjectEvent) => {
			const test = this.rows.value.find(({id}) => id === testID);
			const subject = this.subjects.value.find(({id}) => id === subjectID)
			if (!test) {
				return;
			}
			if (test.subject) {
				test.subject.name += `, ${subject.name}`;
			} else {
				test.subject = subject;
			}
			this.rows.next([...this.rows.value]);
		});
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	public keywordChanged(value: string) {
		this.applyFilter({keyword: value});
	}

	public contentAreaChanged(contentAreaId: number) {
		if (isNaN(contentAreaId)) {
			contentAreaId = -2;
		}

		if (contentAreaId === -1) {
			contentAreaId = -2;
		}
		this.applyFilter({contentAreaId});
	}

	public init() {
		return this.httpClient.ESGIApi.get<InitResponse>(this.controller, 'init')
			.pipe(
				tap(response => {
					this.contentAreas.next([{id: -2, name: 'All Content Areas'}, ...response.contentAreas]);
					this.subjects.next(response.subjects);
				}),
				switchMap(() => this.fetchTests()),
				tap((r) => this.searchResult.next(r)),
			);
	}

	public applyFilter(changes: Partial<FilterModel>): void {
		this.applyFilterDelayed.next(changes);
	}

	public applySort(sortBy: string): void {
		const prev = this.filter.value;
		let nextSortState: { sortDirection: SortDirection, sortBy: string };

		if (prev.sortBy === sortBy) {
			if (prev.sortDirection === SortDirection.Asc) {
				nextSortState = {
					sortBy: prev.sortBy,
					sortDirection: SortDirection.Desc,
				};
			} else {
				nextSortState = {
					sortBy: prev.sortBy,
					sortDirection: SortDirection.Asc,
				};
			}
		} else {
			nextSortState = {
				sortBy: sortBy,
				sortDirection: SortDirection.Asc,
			};
		}
		this.innerApplyFilter(nextSortState).subscribe(r => this.searchResult.next(r));
	}

	public addTestToSubject(testID: number, subjectID: number, subjectType: SubjectType): Observable<void> {
		return fromPromise(SubjectEntity.addTest(subjectID, subjectType, testID))
			.pipe(tap(() => SsoTracker.trackEvent({
				trackingEvent: 'AddTestAssessmentCenter',
				data: {subjectId: subjectID, subjectType: subjectType, testId: testID},
			})));
	}

	public removeTestFromSubject(testID: number, subjectID: number, subjectType: SubjectType): Observable<void> {
		return fromPromise(SubjectEntity.removeTest(subjectID, subjectType, testID))
			.pipe(tap(() => SsoTracker.trackEvent({
				trackingEvent: 'RemoveTestAssessmentCenter',
				data: {subjectId: subjectID, subjectType: subjectType, testId: testID},
			})));
	}

	private fetchTests(): Observable<SearchResult> {
		const {contentAreaId, sortDirection, sortBy, keyword} = this.filter.value;

		const pageIndex = this.pageIndex;
		return this.httpClient.ESGIApi.get<TestsResponse>(this.controller, 'search', {
			contentAreaId: contentAreaId === -1 || contentAreaId === -2 ? undefined : contentAreaId,
			keyword,
			sortBy,
			sortDirection,
			itemsPerPage: this.pageSize,
			pageIndex,
		}).pipe(map(r => {
			const tests = r.tests.map(mapToTestModel).map((test) => {
				const subjects = this.subjects.value.filter(
					({testIDs}) => testIDs.includes(test.id),
				);
				if (subjects.length) {
					test.subject = {
						...subjects[0],
						name: subjects.map(({name}) => name).join(', '),
					};
				}
				return test;
			});

			return {
				tests: pageIndex === 1 ? tests : this.searchResult.value.tests.concat(tests),
				canLoadMore: tests.length === this.pageSize,
				pageIndex,
			} as SearchResult;
		})).asObservable();
	}

	private innerApplyFilter(changes: Partial<FilterModel>): Observable<SearchResult> {
		this.pageIndex = 1;
		const oldValue = this.filter.value;
		this.filter.next({...oldValue, ...changes});
		return this.fetchTests().pipe(takeUntil(this.destroy$));
	}
}
