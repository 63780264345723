import React, {useMemo} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {AnswersInfo, ProgressWithPointer, Statistics, TestSessionDelta, GridProgressBar} from '../../../../../kit';
import {LastTestSession, RubricAnswer} from '../../types';
import {createGridProgressBarData} from './utils';

interface Props {
	lastSession: LastTestSession;
	totalPossible: number;
	rubricAnswers: RubricAnswer[];
	rubricCriteriaCount: number;
	classCorrectAnswersAvg: number;
}

export function RubricDiagrams({
	totalPossible,
	lastSession,
	rubricAnswers,
	rubricCriteriaCount,
	classCorrectAnswersAvg,
}: Props) {
	
	const rubricProgressItems = useMemo(
		() => createGridProgressBarData(rubricAnswers, rubricCriteriaCount, totalPossible),
		[rubricCriteriaCount, totalPossible, rubricAnswers],
	);

	return (
		<>
			<ProgressWithPointer
				percentageType={false}
				maxValue={totalPossible}
				value={classCorrectAnswersAvg}
				progressBarTitle='Class Average'
				dataCy='class-average'
			>
				<TestSessionDelta value={lastSession?.correctAnswersCount - classCorrectAnswersAvg} />
			</ProgressWithPointer>
			<Statistics.ChartsDivider />
			<GridProgressBar items={rubricProgressItems} progressBarTitle='Last Session Result' dataCy='last-session-result'>
				<GridBox>
					<AnswersInfo value={lastSession?.correctAnswersCount || 0} maxValue={totalPossible} transcriptText='Last Score' />
					<TestSessionDelta value={lastSession?.delta || 0} deltaTranscript='Since Last Test' lightZeroValue />
				</GridBox>
			</GridProgressBar>
		</>
	);
}
