import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';

export const Container = styled(FlexBox, {
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '100%',
});

export const TextContainer = styled(GridBox, {
	background: '$vivid',
	borderRadius: 6,
	border: '1px solid $vivid',
	borderStyle: 'solid',
	borderWidth: 1,
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 20,
	paddingRight: 20,
	height: 'max-content',
	justifyContent: 'center',
	gap: 6,
	textAlign: 'center',
	alignSelf: 'center',
	justifySelf: 'center',
});
