import {FiltersContainer, TestsContainer} from './index.styled';
import React, {ChangeEvent, Dispatch, useCallback, useMemo, useState} from 'react';
import {SubjectSelect} from './components/subject-select';
import {SelectedTestsInfo} from './components/selected-tests-info';
import {Search, useAutoControlledState} from '@esgi/ui';
import {Input} from '@esgi/ui/controls';
import {TestRow} from './components/test-row';
import {Skeleton} from './components/skeleton';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {SubjectWithSelAssesTests, Test} from '../../types';
import {SubjectTab} from '@esgi/main/libs/store';
import {maxSelectedTestsCount} from './constants';
import {EmptyBoxMessage} from '../empty-box-message';

type Props = {
	skeleton: boolean;
	subjectsWithSelAssesTests: SubjectWithSelAssesTests;
	selectedTestsIDs: Test['id'][];
	onTestClick: Dispatch<Test['id']>;
	controlledSelectedSubjectID?: SubjectTab['id'];
	setControlledSelectedSubjectID?: Dispatch<SubjectTab['id']>;
	allSelfAsessTests: Test[];
};

export function TestSelectionPanel({
	skeleton,
	subjectsWithSelAssesTests,
	selectedTestsIDs,
	onTestClick,
	controlledSelectedSubjectID,
	setControlledSelectedSubjectID,
	allSelfAsessTests,
}: Props) {
	const [selectedSubjectID, setSelectedSubjectID] = useAutoControlledState({
		initialState: -1,
		controlledState: controlledSelectedSubjectID,
		onChange: setControlledSelectedSubjectID,
	});

	const [testSearchInputValue, setTestSearchInputValue] = useState('');

	const subjects = useMemo(
		() =>
			Object.entries(subjectsWithSelAssesTests).map<SubjectTab>(([subjectID, subjectData]) => ({
				id: Number(subjectID),
				...subjectData,
			})),
		[subjectsWithSelAssesTests],
	);

	const filteredTests = useMemo<Test[]>(() => {
		const tests =
			selectedSubjectID === -1 ? [...allSelfAsessTests] : subjectsWithSelAssesTests[selectedSubjectID]?.tests ?? [];

		if (!testSearchInputValue) {
			return tests;
		}

		const preparedValue = testSearchInputValue.trim().toLocaleLowerCase();

		return tests.filter(({name}) => {
			return name.split(' ').some((item) => item.trim().toLocaleLowerCase().includes(preparedValue));
		});
	}, [allSelfAsessTests, selectedSubjectID, subjectsWithSelAssesTests, testSearchInputValue]);

	const onTestSearchInputValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setTestSearchInputValue(value);
	}, []);

	const getTestsContent = () => {
		if (skeleton) {
			return (
				<TestsContainer>
					<Skeleton />
				</TestsContainer>
			);
		}

		if (!filteredTests.length) {
			return <EmptyBoxMessage message='No tests to display' />;
		}

		return (
			<OverlayScrollbarsComponent
				defer
				style={{height: 'calc(100% + 0px)'}}
				options={{
					scrollbars: {
						autoHide: 'leave',
					},
				}}
			>
				<TestsContainer>
					{filteredTests.map(({id, name}) => {
						const isTestSelected = selectedTestsIDs.includes(id);

						return (
							<TestRow
								id={id}
								name={name}
								onTestClick={onTestClick}
								disabled={!isTestSelected && selectedTestsIDs.length === maxSelectedTestsCount}
								selected={isTestSelected}
								key={id}
							/>
						);
					})}
				</TestsContainer>
			</OverlayScrollbarsComponent>
		);
	};

	return (
		<>
			<FiltersContainer>
				<SubjectSelect
					subjects={subjects}
					skeleton={skeleton}
					selectedSubjectID={selectedSubjectID}
					setSelectedSubjectID={setSelectedSubjectID}
				/>
				<SelectedTestsInfo value={selectedTestsIDs.length} maxValue={maxSelectedTestsCount} />
			</FiltersContainer>

			<Input.Iconable
				disabled={skeleton}
				placeholder='Search'
				value={testSearchInputValue}
				onChange={onTestSearchInputValueChange}
				skeleton={skeleton}
				dataCy='tests-search-input'
			>
				<Search />
			</Input.Iconable>

			{getTestsContent()}
		</>
	);
}
