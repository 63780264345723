export type ImageElement = HTMLImageElement & { width: number, height: number };

export type CroppedImage = {
	croppedImage?: string | null,
	croppedImageFile?: File | null,
	image?: string | null,
	crop?: {
		x: number,
		y: number,
		zoom: number,
	},
}

export type UploadFileErrorMessage = { [key in UploadFileErrorType]: string; };

export enum ImageMimeType {
	JPEG = '.jpeg',
	PNG = '.png',
	GIF = '.gif',
	WEBP = '.webp',
	BMP = '.bmp',
	TIFF = '.tiff'
}

export enum UploadFileErrorType {
	FileSize = 'fileSize',
	FileType = 'fileType',
}