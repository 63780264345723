import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {Checkbox} from '@esgi/ui/controls';
import {FlexBox, CounterBox} from '@esgi/ui/layout';

export const Root = styled(FlexBox, {
	height: 68,
	borderTop: '1px solid $primary92',
	background: 'linear-gradient(180deg, $primaryBackground 0%, $primarySurface 80%)',
	width: '100%',
	'&::after': {
		content: '',
		width: '100%',
		height: '100%',
		background: 'repeating-linear-gradient(35deg, $surface, $surface 2px, transparent 0, transparent 10px)',
		position: 'absolute',
		zIndex: 0,
	},
});

export const Content = styled(FlexBox, {
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	padding: '0 20px',
	zIndex: 1,
});

export const ActionPanel = styled(FlexBox, {
	'& > div:first-child': {
		marginRight: 20,
		alignItems: 'center',
	},
});

export const StyledCounterBox = styled(CounterBox, {
	color: '$primaryVivid',
	borderColor: '$primaryMuted',
	marginRight: 8,
});

export const ClearButton = styled(Button.Icon, {
	color: '$primary',
});

export const StyledCheckbox = styled(Checkbox, {
	'& > div': {
		borderColor: '$primaryMuted',
	},
});
