import {SelectableList as BaseSelectableList} from '@esgi/ui/layout';
import {SelectableListGroupRoot} from './components/selectable-list-group-root';
import {SelectableListItem} from './components/selectable-list-item';
import {SelectableListItemDraggable} from './components/selectable-list-item-draggable';

export const SelectableList = {
	Root: BaseSelectableList,
	GroupRoot: SelectableListGroupRoot,
	Item: SelectableListItem,
	ItemDraggable: SelectableListItemDraggable,
	Trigger: BaseSelectableList.Trigger,
	StyledButton: BaseSelectableList.StyledButton,
	Group: BaseSelectableList.Group,
	GroupDroppable: BaseSelectableList.GroupDroppable,
};
