import React, {ReactNode} from 'react';
import {EventBusManager, EventBusDispatcher} from '@esgillc/events';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {FilterType} from '../../../enums';
import {BoxEvents} from './events';

class State {
	open: boolean;
}

class Props {
	title?: string;
	canBeCleared: boolean;
	cleared?: () => void;
	loading: boolean;
	className?: string;
	collapsible: boolean;
	filterType: FilterType;
	onOpened?: () => void;
	onClosed?: () => void;
	initiallyOpened?: boolean;
	children: ReactNode;
}

export class Box extends React.Component<Props, State> {
	private readonly eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		const state = new State();
		if (this.props.initiallyOpened) {
			state.open = true;
		}
		this.state = state;
	}

	componentWillUnmount() {
		this.eventBus.destroy();
	}

	render() {
		return <div className={'box ' + (this.props.className || '')} ref={(n) => this.setRef(n)}>
			{this.props.title &&
			<div className='item all'>
				<a href='#' className='title' onClick={this.expandCollapseChanged}>
					<span>{this.props.title}</span>
				</a>
				{this.props.canBeCleared &&
				<OnHoverTooltip message='Remove filter'>
					<a href='#' className='reset'
					   onClick={() => this.props.cleared && this.props.cleared()}>
						Reset
					</a>
				</OnHoverTooltip>
				}
				{this.props.collapsible &&
				<a href='#' className={'roll ' + (this.state.open ? 'up' : 'down')}
				   onClick={this.expandCollapseChanged}>
					{this.props.loading && <i className='fa fa-circle-o-notch fa-spin'/>}
					{!this.props.loading &&
					<>
						<i className='fa fa-angle-up'/>
						<i className='fa fa-angle-down'/>
					</>
					}
				</a>
				}
			</div>
			}

			<div className='box-body'>
				{this.state.open && this.props.children}
			</div>
		</div>;
	}

	boxRef: HTMLDivElement;

	expandCollapseChanged = () => {
		if (!this.state.open) {
			this.open();
		} else {
			this.close();
		}
	};

	open() {
		if (this.state.open) {
			return;
		}

		let collapsibleElement = $(this.boxRef).find('.box-body');

		this.setState({open: true}, () => {
			this.props.onOpened && this.props.onOpened();
			$(collapsibleElement).slideDown();
		});

		EventBusDispatcher.dispatch(BoxEvents.Open, BoxEvents.Open(this.props.filterType));
	}

	close() {
		if (!this.props.collapsible) {
			return;
		}

		if (!this.state.open) {
			return;
		}

		let collapsibleElement = $(this.boxRef).find('.box-body');
		$(collapsibleElement).slideUp({
			always: () => {
				this.setState({open: false}, () => this.props.onClosed && this.props.onClosed());
			},
		});
	}

	componentDidMount(): void {
		this.eventBus.subscribe(BoxEvents.Open, (args: BoxEvents.Open.Args) => {
			if (this.props.filterType !== args.type) {
				this.close();
			}
		});
	}

	private setRef(ref: HTMLDivElement) {
		this.boxRef = ref;
	}
}
