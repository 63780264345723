import {EmptyDataContainer} from './index.styled';
import {EmptyContent} from '../../../../../../kit';

export function EmptyDataMessage() {
	return (
		<EmptyDataContainer>
			<EmptyContent templateText='No data to display' />
		</EmptyDataContainer>
	);
}
