import React, {createRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {ModalManager, Title} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onClose: () => void;
	onConfirm: () => void;
}

export default class ConfirmCancelDialog extends React.PureComponent<Props> {
	private modalMangerRef: React.RefObject<ModalManager> = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalMangerRef}>
			<Alert.Header>
				<Title>
					End Test Session?
				</Title>
			</Alert.Header>
			<Alert.Body>
				Are you sure you want to end the test session? A test session cannot be created for partially completed
				rubrics.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={() => this.modalMangerRef.current.close(this.props.onClose)}>
					BACK TO TEST
				</Buttons.Text>
				<Buttons.Contained onClick={() => this.modalMangerRef.current.close(this.props.onConfirm)}>
					CONFIRM
				</Buttons.Contained>
			</Alert.Footer>
		</Alert>;
	}
}
