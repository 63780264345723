import {Dispatch} from 'react';
import {createContext} from '@esgi/ui';
import {Student} from '@esgi/main/libs/store';
import {AllOptionItem, GroupBucket} from './types';

export type GroupsContextValue = {
	canSelectClass: boolean,
	setCanSelectClass: Dispatch<boolean>,
	selectedClassID: number | AllOptionItem.Value,
	setSelectedClassID: (id: number | AllOptionItem.Value) => void,
	groupBuckets: GroupBucket[],
	triggerListOpen: (isListOpened: boolean, selectableGroupListId: number) => void,
	studentsWithoutGroup: Student[],
}

export const {Context, useHook: useGroupsContext} = createContext<GroupsContextValue>('GroupsContext');