import {Text} from '@esgi/ui/typography';
import {IncludeType} from '../../types';
import {useValue, ChangeFunction} from '@esgi/main/kits/activities';
import {Section, Title, ToggleGroupRoot, ToggleGroupContent, ToggleGroupItem, ToggleGroupTitle, Label} from './index.styled';
import {useUser} from '@esgi/core/authentication';
import {useMemo} from 'react';

interface Props {
	disabled?: boolean;
	onChange: ChangeFunction<IncludeType>;
}

export function IncludeControl({disabled, onChange}: Props) {
	const currentUser = useUser();
	const [value, setValue] = useValue<IncludeType>(IncludeType.IncorrectOnly, onChange);

	const includes = useMemo<[IncludeType, string, string][]>(() => {
		const testResultsIncorrectVerbiage = currentUser?.testResultsIncorrectVerbiage ?? 'incorrect';
		const testResultsCorrectVerbiage = currentUser?.testResultsCorrectVerbiage ?? 'correct';

		return [
			[
				IncludeType.IncorrectOnly,
				testResultsIncorrectVerbiage,
				`Only ${testResultsIncorrectVerbiage} questions will print`,
			],
			[
				IncludeType.IncorrectUntested,
				`${testResultsIncorrectVerbiage} + Skipped`,
				`The ${testResultsCorrectVerbiage} answers won’t be included`,
			],
			[IncludeType.FullSet, 'Full Set', 'All questions will print'],
		];
	}, [currentUser]);

	return (
		<Section dataCy='include-section'>
			<Title data-cy='include-title' size='small' font='mono' color='lowContrast'>
				Include
			</Title>
			<ToggleGroupRoot value={value.toString()} disabled={disabled}>
				<ToggleGroupContent>
					{includes.map(([value, label, description]) => (
						<ToggleGroupItem
							key={value}
							value={value.toString()}
							valueDescription={description}
							onClick={() => setValue(value)}
						>
							<Label size='medium' bold>
								{label}
							</Label>
						</ToggleGroupItem>
					))}
				</ToggleGroupContent>
				<ToggleGroupTitle>
					{({description}) => (
						<Text size='small' font='mono'>
							{description}
						</Text>
					)}
				</ToggleGroupTitle>
			</ToggleGroupRoot>
		</Section>
	);
}
