import {UserType} from '@esgi/core/authentication';
import {SubjectType} from '@esgi/core/enums';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode, PreAssessHierarchyLevel,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {districtTabsFilter, notHiddenFilter, schoolTabsFilter} from './filters';
import {SubjectModel} from './models';

export function gradeLevelsByHierarchy(hierarchyInstance: HierarchyInstance): number[] {
	if (!hierarchyInstance) {
		return [];
	}

	let source = hierarchyInstance.students;

	if (hierarchyInstance.mode === HierarchyMode.Classic) {
		const selected = hierarchyInstance.classic.selected;
		switch (selected.level) {
			case ClassicHierarchyLevel.Student:
				source = source.filter(s => s.studentID === selected.levelID);
				break;

			case ClassicHierarchyLevel.Group:
				source = source.filter(s => s.groups.some(g => g === selected.levelID));
				break;

			case ClassicHierarchyLevel.Class:
				source = source.filter(s => s.classes.some(c => c === selected.levelID));
				break;

			case ClassicHierarchyLevel.Teacher:
				source = source.filter(s => s.teacherID === selected.levelID);
				break;

			case ClassicHierarchyLevel.School:
				source = source.filter(s => s.schoolID === selected.levelID);
				break;

			default:
				break;
		}
	}

	if (hierarchyInstance.mode === HierarchyMode.Specialist) {
		const specialist = hierarchyInstance.specialist;
		const selected = specialist.selected;

		source = source.filter(s => s.specialistGroups.length > 0);

		switch (selected.level) {
			case SpecialistHierarchyLevel.Type:
				if (selected.levelID > 0) {
					const groups = hierarchyInstance.specialistGroups.filter(x => x.type === selected.levelID);
					source = source.filter(s => groups.some(x => x.groupID));
				}
				break;

			case SpecialistHierarchyLevel.User:
				if (selected.levelID > 0) {
					const groups = hierarchyInstance.specialistGroups.filter(x => x.userID === selected.levelID);
					source = source.filter(s => groups.some(x => x.groupID));
				}
				break;

			case SpecialistHierarchyLevel.Group:
				source = source.filter(s => s.specialistGroups.some(x => x === selected.levelID));
				break;

			case SpecialistHierarchyLevel.Student:
				source = source.filter(s => s.studentID === selected.levelID);
				break;

			default:
				break;
		}

		if (specialist.filter.schoolID > 0) {
			source = source.filter(s => s.schoolID === specialist.filter.schoolID);
		}

		if (specialist.filter.teacherID > 0) {
			source = source.filter(s => s.teacherID === specialist.filter.teacherID);
		}
	}

	if (hierarchyInstance.mode === HierarchyMode.PreAssess){
		const preAssess = hierarchyInstance.preAssess;
		const selected = preAssess.selected;

		source = source.filter(s => s.specialistGroups.length > 0);

		switch(selected.level){
			case PreAssessHierarchyLevel.User:
				if (selected.levelID > 0) {
					const groups = hierarchyInstance.specialistGroups.filter(x => x.userID === selected.levelID);
					source = source.filter(s => groups.some(x => x.groupID));
				}
				break;

			case PreAssessHierarchyLevel.Group:
				source = source.filter(s => s.specialistGroups.some(x => x === selected.levelID));
				break;

			case PreAssessHierarchyLevel.Student:
				source = source.filter(s => s.studentID === selected.levelID);
				break;
		}
	}

	return source.map(s => s.gradeLevelID).filter((s, i, all) => all.indexOf(s) === i);
}

export function canPassFilter(subject: SubjectModel, grades: number[], hierarchy: HierarchyInstance, userType: UserType): boolean {
	let filteredSubject = [];
	if (subject.type === SubjectType.Deployed) {
		if (subject.level === 'District') {
			filteredSubject = districtTabsFilter([subject], userType, grades, hierarchy);
		} else {
			filteredSubject = schoolTabsFilter([subject], userType, grades, hierarchy);
		}
	} else {
		filteredSubject = notHiddenFilter([subject]);
	}
	return !!filteredSubject.length;
}

export function firstVisibleSubject(source: SubjectModel[], hierarchy: HierarchyInstance, userType: UserType): SubjectModel | undefined {
	const grades = gradeLevelsByHierarchy(hierarchy);
	for (const subjectModel of source) {
		if (canPassFilter(subjectModel, grades, hierarchy, userType)) {
			return subjectModel;
		}
	}
}
