import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Container = styled(GridBox, {
	paddingLeft: 62,
	gap: '$4',
});

export const StyledText = styled(Text, {
	marginLeft: 12,
});

export const StyledRow = styled(FlexBox, {
	flex: '1 0 0',
	alignItems: 'center',
	margin: '0 24px',
});

export const ScoreBox = styled(FlexBox, {
	justifyContent: 'end',
	padding: '0 4px',
});

export const QuestionsBox = styled(GridBox, {
	gap: '20px',
});

export const Score = styled(FlexBox, {
	justifyContent: 'center',
	marginLeft: 4,
	width: 16,
});