import {useState} from 'react';
import {QuestionModel} from '../models';

type QuestionSliderModel = {
	current: QuestionModel;
	index: number;
	next: () => void;
	back: () => void;
	canNext: boolean;
	canBack: boolean;
}

export function useQuestionSlider(questions: QuestionModel[], startIndex?: number): QuestionSliderModel {
	const [index, setIndex] = useState<number>(startIndex || 0);
	const canNext = index !== questions.length - 1;
	const canBack = index !== 0;

	return {
		current: questions[index],
		index,
		next: () => canNext && setIndex(index + 1),
		back: () => canBack && setIndex(index - 1),
		canNext,
		canBack,
	};
}
