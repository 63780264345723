import {Expand} from '@esgi/ui/icons';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {GridBox} from '../../../grid-box';
import {Text} from '@esgi/ui/typography';
import {SelectableListStyledButton} from '../selectable-list-styled-button';

export const ArrowTop = styled(Expand, {
	transform: 'rotateX(180deg)',
});

const triggerHoveredStyles: CSS = {
	color: '$secondary',
	backgroundColor: '$secondaryBackground',
	borderColor: '$secondaryMuted',
};

export const Div = styled(GridBox, {
	userSelect: 'none',
	padding: 6,
	cursor: 'pointer',
	color: '$neutral40',
	backgroundColor: '$vivid',
	transition: 'color .3s, background-color .3s, border-color .3s',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	gap: 6,
	borderRadius: 6,

	'& > svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',
		alignSelf: 'start',

		'& path': {
			fill: 'currentColor',
		},
	},

	[`& > ${Text}`]: {
		color: 'currentColor',
	},

	variants: {
		withStyledButton: {
			true: {
				gridTemplateColumns: 'auto 1fr auto',
			},

			false: {
				gridTemplateColumns: 'auto 1fr',
			},
		},

		isListOpen: {
			true: {
				color: '$primary',
				borderColor: 'transparent',
				borderBottomColor: '$primaryMuted',
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
		},

		selected: {
			true: {},
			false: {},
		},

		hovered: {
			true: {},
			false: {},
		},

		styledButtonHovered: {
			true: {},
			false: {},
		},

		disabled: {
			true: {
				color: '$muted',
				backgroundColor: '$background',
				borderColor: '$border',
				cursor: 'default',
				pointerEvents: 'none',

				[`${SelectableListStyledButton}`]: {
					color: '$muted',
				},
			},
			false: {},
		},
	},

	compoundVariants: [
		{
			selected: false,
			isListOpen: false,
			styledButtonHovered: false,
			hovered: true,
			disabled: false,
			css: triggerHoveredStyles,
		},

		{
			selected: false,
			isListOpen: true,
			styledButtonHovered: false,
			hovered: true,
			disabled: false,
			css: triggerHoveredStyles,
		},

		{
			selected: true,
			isListOpen: false,
			hovered: true,
			styledButtonHovered: false,
			disabled: false,
			css: {
				...triggerHoveredStyles,

				[`${SelectableListStyledButton}`]: {
					color: '$base',
				},
			},
		},

		{
			selected: true,
			isListOpen: true,
			hovered: true,
			styledButtonHovered: false,
			disabled: false,
			css: {
				...triggerHoveredStyles,

				[`${SelectableListStyledButton}`]: {
					color: '$base',
				},
			},
		},

		{
			selected: true,
			hovered: false,
			styledButtonHovered: false,
			disabled: false,
			isListOpen: false,
			css: {
				backgroundColor: '$primaryBackground',
				color: '$primary',

				[`${SelectableListStyledButton}`]: {
					color: '$primary',
				},
			},
		},
	],
});
