import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
	onClick?: () => void;
}

export function EditIcon({className, onClick}: Props) {
	return <svg data-cy='edit-icon' onClick={onClick} className={join(className)} version='1.0' width='16px'
				height='16px' viewBox='0 0 72 68'
				preserveAspectRatio='xMidYMid meet'>
		<g transform='translate(0,68) scale(0.1,-0.1)' fill='#000000' stroke='none'>
			<path d='M449 603 l-40 -38 81 -80 81 -80 35 37 c18 21 34 47 34 59 0 26 -109 139 -134 139 -9 0 -35 -17 -57 -37z'/>
			<path d='M217 372 l-157 -157 0 -83 0 -82 78 0 77 0 158 158 c86 86 157 162
				157 167 0 10 -140 155 -150 155 -3 0 -76 -71 -163 -158z m183 90 c0 -14 -219
				-226 -227 -219 -7 8 205 227 219 227 5 0 8 -3 8 -8z m-208 -279 c45 -44 47
				-48 31 -65 -9 -10 -29 -18 -45 -18 -23 0 -28 4 -28 25 0 20 -5 25 -25 25 -19
				0 -25 5 -25 22 0 20 25 58 39 58 3 0 27 -21 53 -47z'
			/>
		</g>
	</svg>;
}
