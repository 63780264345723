import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {TestColorIndicator as TestColorIndicatorUI} from '@esgi/main/kits/common';

export const SkeletonList = styled(FlexBox, {
	...SkeletonStyles,
	minHeight: '86px',
});

export const TestColorIndicator = styled(TestColorIndicatorUI, {
	height: '32px',
	padding: '6px 4px',
	marginRight: '12px',
});

export const HeaderLabel = styled(Text, {
	color: 'inherit',
	marginRight: 6,
	wordBreak: 'break-word',

	variants: {
		isListOpen: {
			true: {
				color: 'rgba(0, 132, 204, 1)',
			},
		},
	},
});

export const SelectableListTriggerContent = styled(FlexBox, {
	color: 'inherit',
	alignItems: 'center',
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	width: 'max-content',
	height: 'max-content',
	border: 'none',
	background: 'none',
	padding: 0,
	gridTemplateColumns: 'auto',
	maxHeight: '24px',
	borderRadius: '50%',

	variants: {
		isListOpen: {
			true: {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
		},
	},
});

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',
	margin: '12px 0',
	boxShadow: '0px 2px 6px 0px rgba(230, 232, 239, 0.24)',

	'&:first-child': {
		marginTop: 0,
	},
});

export const SelectableListGroupRoot = styled(SelectableList.GroupRoot, {
	borderRadius: '6px',
});

export const SelectableListGroup = styled(SelectableList.Group, {
	borderRadius: '6px',
});

export const IconBox = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	marginLeft: '8px',
	gap: '16px',
});

export const CounterBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: '$3',
	marginLeft: 'auto',
});

export const HeaderRow = styled(GridBox, {
	border: '1px solid $vivid',
	borderRadius: '6px',
	padding: '8px 12px',
	gridAutoFlow: 'column',
	gap: '16px',
	alignItems: 'center',
	backgroundColor: '$vivid',
	transition: '.3s ease',
	cursor: 'pointer',
	position: 'sticky',
	top: 0,
	zIndex: 1,

	variants: {
		isListOpen: {
			true: {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
		},
		disabled: {
			true: {
				cursor: 'default',
			},
		},
	},
});

export const ItemTagAndTitle = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr 1fr',
	alignItems: 'center',
});

export const Options = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	gap: '30px',
});

export const TriggerWrapper = styled(GridBox, {
	'> div': {
		gridTemplateColumns: 'auto',
	},
});

export const IndicatorWrapper = styled(Box, {
	variants: {
		status: {
			'active': {
				color: '$base',
			},

			'default': {
				color: '$neutral80',
			},

			'inProgress': {
				color: '$primary',
			},
		},
	},
});

//list items

export const ListItem = styled(GridBox, {
	alignItems: 'center',
	width: '100%',
	gridTemplateColumns: 'auto',
	borderBottomLeftRadius: '6px',
	borderBottomRightRadius: '6px',
});

export const Item = styled(GridBox, {
	alignItems: 'center',
	gridTemplateColumns: '1fr auto auto',
	gap: '16px',
	width: '100%',
	padding: '7px 12px',
	borderTop: '1px solid $border',
});

export const Container = styled(GridBox, {
	gridTemplateColumns: '1fr',
	width: '100%',
	backgroundColor: '$vivid',
	borderBottomLeftRadius: '6px',
	borderBottomRightRadius: '6px',
});

export const CounterText = styled(Text, {
	minWidth: '16px',
	textAlign: 'center',
	fontSize: '11px',
	fontWeight: 700,

	variants: {
		type: {
			correct: {
				color: '$green48',
			},
			incorrect: {
				color: '$red48',
			},
			untested: {
				color: '$neutral56',
			},
		},
		zero: {
			true: {},
		},
	},
});

export const Title = styled(GridBox, {
	color: '$neutral40',
	fontSize: '11px',
	fontWeight: 600,
	wordBreak: 'break-all',
});

export const TestButton = styled(Button.Text, {
	border: ' 1px solid $neutral88',
	backgroundColor: '$vivid',
	fontSize: '13px',
	fontWeight: 600,
	color: '$base',
	padding: '8px 14px',
	borderRadius: '6px',
});

export const NotTestedText = styled(Text, {
	minWidth: '16px',
	textAlign: 'center',
	fontSize: '11px',
	fontWeight: 700,
	color: '$neutral80',
});
