import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useServiceFactory} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {TestSessionDetailsFormService} from './services';
import {IEPGoalFormService} from './components/iep-goal/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {selectedStudentID} from 'modules/hierarchy/utils';
import {Header, Body, Footer} from './components/modal';
import {NewTestSessionCanceledEvent, ResetPanelsEvent} from './events';
import {Subject} from './types';
import TestSubset from 'modules/assessments/general/session-details/test-subset/test-subset';
import {dispatchAppEvent} from '@esgillc/events';
import {SsoTracker} from '@esgi/core/tracker';
import {ExceptionReporter} from 'shared/alert/exception-reporter/reporter';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {TestSessionDetailsContext, useTestSessionDetails} from './hooks';

interface Props {
	classID: number;
	specialistGroupID: number;
	studentID: number;
	testID: number;
	subject: Subject;
	hierarchy: HierarchySnapshot;
	onClose: () => void;
}

export default function TestSessionDetailsFormModal({
	classID,
	specialistGroupID,
	studentID,
	testID,
	subject,
	hierarchy,
	onClose,
}: Props): JSX.Element {
	const modalManagerRef = useModal();
	const handleClose = useCloseModal(modalManagerRef, onClose);
	const service = useServiceFactory(() => {
		const service = new TestSessionDetailsFormService(
			new IEPGoalFormService(),
		);
		service.serviceIEP.form.controls.isCompleted.onChanged.subscribe(
			({reason}) => {
				if (reason === 'value' && !service.iepEditMode$.value) {
					service.serviceIEP.update(() => {
						service.setIEPEditMode(false);
					});
				}
			},
		);
		return service;
	});
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);
	const dirty = useBehaviorSubject(service.dirty$);
	const loaded = useBehaviorSubject(service.loaded$);

	const {testSubsetOpened, setTestSubsetOpened} = useTestSessionDetails(() => {
		service.init({
			testID,
			studentID: studentID || selectedStudentID(hierarchy),
		});
	});

	// Methods
	const onCancel = () => {
		if (editMode) {
			if (!dirty) {
				dispatchAppEvent(NewTestSessionCanceledEvent, new NewTestSessionCanceledEvent());
				service.setEditMode(false);
			} else {
				OldAlerts.bsconfirm(
					'Are you sure you want to discard changes?',
					(result) => {
						if (result) {
							dispatchAppEvent(ResetPanelsEvent, new ResetPanelsEvent());
							service.setEditMode(false);
						}
					},
				);
			}
		} else {
			handleClose();
		}
	};

	const onDownload = (current: boolean) => {
		SsoTracker.trackEvent({trackingEvent: 'TestSessionDetailsDownloadPDF'});

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = [
			'Test_Session_Details',
			testSessionDetails.testName,
			testSessionDetails.studentName,
			`${year}-${month}-${day}.pdf`,
		].join('_').replace(/ /g, '_');

		service.download(filename, {
			classID,
			specialistGroupID,
			studentID,
			testID,
			sessionID: current ? currentTestSession.id : 0,
			subject: subject.name,
		}).subscribe({
			error: () => {
				const reporter = new ExceptionReporter();
				reporter.report('Unable to load pdf file.');
			},
		});
	};

	return (
		<TestSessionDetailsContext.Provider value={service}>
			<ServiceLoader trackingService={service} fullscreen/>
			{loaded &&
				<Modal modalManagerRef={modalManagerRef}>
					<Header onDownload={onDownload}/>
					<Body/>
					<Footer onCancel={onCancel}/>
				</Modal>
			}
			{testSubsetOpened &&
				<TestSubset
					testID={testID}
					testSessionID={currentTestSession.id}
					testSessionNumber={currentTestSession.number}
					closed={() => setTestSubsetOpened(false)}
					started={onClose}
				/>
			}
		</TestSessionDetailsContext.Provider>
	);
}
