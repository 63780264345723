import {Box} from '../../../layout/box';
import {styled} from '../../../theme';
import {alertContentInlinePadding} from '../../constants';
import {Text} from '../../../typography';

export const BodyWrapper = styled(Box, {
	display: 'inline-flex',
	paddingLeft: alertContentInlinePadding,
	paddingRight: alertContentInlinePadding,
	overflow: 'auto',

	[`& > ${Text}`]: {
		color: '$neutral40',
	},
});
