import './component.less';
import React from 'react';

export class EsgiLetters extends React.Component {
	render(): JSX.Element | false | null {
		return <h1 className='esgi-letters'>
			<span>E</span>
			<span>S</span>
			<span>G</span>
			<span>I</span>
		</h1>;
	}

	static Letters: Letter[] = [
    	{
    		title: 'E',
    		path: <path key='E'
                        d='M0.839402 31.8841C0.279801 31.3245 0 30.5732 0 29.6276V13.1374C0 12.1918 0.279801 11.4405 0.839402 10.8809C1.399 10.3213 2.15032 10.0415 3.09594 10.0415H15.4434C15.5937 10.2902 15.7284 10.6011 15.8528 10.9742C15.9771 11.3472 16.0393 11.7695 16.0393 12.2436C16.0393 13.0649 15.858 13.6737 15.4978 14.0727C15.1377 14.4717 14.6325 14.6686 13.9874 14.6686H5.70741V18.8475H14.3631C14.5134 19.0962 14.6481 19.4071 14.7724 19.7801C14.8968 20.1532 14.959 20.5755 14.959 21.0496C14.959 21.8709 14.7854 22.4745 14.4356 22.8579C14.0859 23.244 13.5911 23.4357 12.9434 23.4357H5.70741V28.099H15.5937C15.744 28.3477 15.8787 28.6716 16.003 29.068C16.1274 29.4669 16.1896 29.9022 16.1896 30.3737C16.1896 31.195 16.0082 31.7908 15.6481 32.1639C15.288 32.537 14.7958 32.7235 14.174 32.7235H3.09594C2.15032 32.7235 1.399 32.4437 0.839402 31.8841Z'
                        fill='#0088CC'/>,
    	},
    	{
    		title: 'S',
    		path: <path key='S'
                        d='M22.6406 33.0033C21.6717 32.8167 20.3141 32.5499 19.6042 32.2001C18.8944 31.853 18.3348 31.4229 17.9254 30.9125C17.5161 30.4022 17.3088 29.8244 17.3088 29.1767C17.3088 28.5057 17.5006 27.9461 17.8866 27.4979C18.2726 27.0497 18.7001 26.6896 19.1742 26.415C19.8452 26.9616 20.6846 27.4461 21.6924 27.871C22.7002 28.2933 23.8375 28.5057 25.107 28.5057C26.4257 28.5057 27.3635 28.2752 27.9231 27.8166C28.4827 27.3554 28.7625 26.8036 28.7625 26.1559C28.7625 25.5601 28.5449 25.0808 28.1097 24.7207C27.6744 24.3605 27.0215 24.0445 26.1511 23.7699L24.2494 23.174C23.2054 22.8501 22.2598 22.4978 21.4152 22.1118C20.568 21.7258 19.8478 21.2672 19.2519 20.7309C18.656 20.1972 18.1897 19.5625 17.8529 18.8293C17.5161 18.0961 17.3503 17.2075 17.3503 16.1608C17.3503 14.0727 18.1405 12.4172 19.7182 11.1995C21.2986 9.9819 23.493 9.37048 26.3039 9.37048C27.3739 9.37048 28.361 9.44562 29.2703 9.59329C30.1771 9.74355 30.9543 9.97154 31.602 10.2824C32.2497 10.5933 32.7575 10.9716 33.1305 11.4198C33.5036 11.868 33.6901 12.3887 33.6901 12.9872C33.6901 13.5597 33.5476 14.0623 33.2601 14.4976C32.9751 14.9328 32.6435 15.3007 32.2704 15.5986C31.6978 15.2023 30.9388 14.8655 29.9957 14.5908C29.0501 14.3162 28.019 14.1815 26.8998 14.1815C25.7054 14.1815 24.822 14.368 24.252 14.7411C23.6795 15.1142 23.3945 15.5857 23.3945 16.1582C23.3945 16.6557 23.581 17.0339 23.9541 17.2956C24.3272 17.5572 24.9101 17.8241 25.708 18.0987L27.7236 18.7697C28.8921 19.1687 29.918 19.5962 30.8014 20.0573C31.6849 20.5185 32.4233 21.0392 33.0217 21.6247C33.6176 22.2102 34.0658 22.8812 34.3637 23.6403C34.6617 24.3994 34.8119 25.2621 34.8119 26.2337C34.8119 27.2777 34.6073 28.2285 34.1953 29.0886C33.786 29.9462 33.1746 30.6871 32.3663 31.3089C31.5579 31.9307 30.5631 32.4152 29.3817 32.7649C28.2003 33.1121 26.8376 33.2883 25.2961 33.2883'
                        fill='#FF0A3B'/>,
    	},
    	{
    		title: 'G',
    		path: <path key='G'
                        d='M48.0868 33.2831C46.2966 33.2831 44.636 33.0525 43.1074 32.5939C41.5789 32.1328 40.2395 31.4177 39.0969 30.4488C37.9518 29.4799 37.058 28.2467 36.4103 26.7544C35.7627 25.2621 35.4414 23.4719 35.4414 21.3812C35.4414 19.4174 35.7704 17.6816 36.4311 16.1764C37.0891 14.6712 37.9855 13.4146 39.1177 12.4094C40.2498 11.4016 41.5789 10.6451 43.11 10.1348C44.6386 9.62438 46.2863 9.37048 48.0532 9.37048C50.3667 9.37048 52.2061 9.7306 53.574 10.4534C54.942 11.1762 55.6259 12.0959 55.6259 13.2152C55.6259 13.7877 55.4756 14.2903 55.1777 14.7256C54.8798 15.1608 54.53 15.5028 54.1336 15.7515C53.5119 15.3551 52.7346 14.982 51.802 14.6323C50.8693 14.2851 49.7682 14.109 48.5014 14.109C47.4573 14.109 46.5065 14.2774 45.6464 14.6116C44.7888 14.9484 44.0479 15.4277 43.4261 16.0468C42.8043 16.6686 42.3198 17.4277 41.9701 18.3215C41.6203 19.2179 41.4468 20.2231 41.4468 21.3423C41.4468 22.56 41.6152 23.6248 41.9494 24.5315C42.2862 25.4383 42.7525 26.1922 43.3484 26.7881C43.9442 27.3839 44.6593 27.8321 45.4935 28.1301C46.3277 28.428 47.2293 28.5783 48.1982 28.5783C48.7941 28.5783 49.3356 28.5161 49.82 28.3917C50.3045 28.2674 50.6828 28.143 50.9574 28.0187V23.8035H46.6671C46.5168 23.5548 46.3821 23.231 46.2578 22.8346C46.1334 22.4382 46.0712 22.0133 46.0712 21.5651C46.0712 20.7698 46.2578 20.1843 46.6308 19.8112C47.0039 19.4381 47.4884 19.2516 48.0868 19.2516H54.3539C55.0508 19.2516 55.5974 19.4433 55.9964 19.8293C56.3954 20.2153 56.5923 20.7568 56.5923 21.4511V29.2104C56.5923 29.9332 56.455 30.4669 56.1829 30.8141C55.9083 31.1638 55.4731 31.511 54.8772 31.8582C54.5274 32.0577 54.1051 32.2442 53.6077 32.4178C53.1103 32.5913 52.5559 32.7416 51.9471 32.866C51.3382 32.9903 50.7035 33.0888 50.0454 33.1639'
                        fill='#FAB124'/>,
    	},
    	{
    		title: 'I',
    		path: <path key='I'
                        d='M61.3204 33.0266C60.2011 33.0266 59.398 32.8738 58.9135 32.568C58.4291 32.2623 58.1855 31.6665 58.1855 30.7804V17.7723C58.4343 17.7101 58.7814 17.6453 59.2296 17.5832C59.6778 17.521 60.1752 17.4873 60.7219 17.4873C61.8411 17.4873 62.6442 17.6453 63.1287 17.9614C63.6132 18.2775 63.8567 18.8785 63.8567 19.7646V32.7727C63.608 32.8349 63.2608 32.8945 62.8126 32.9463'
                        fill='#2FB454'/>,
    	},
    	{
    		title: 'IUpperMark',
    		path: <path key='IM' fillRule='evenodd' clipRule='evenodd'
                        d='M60.55 7.78628C60.8478 8.46661 61.1046 9.16234 61.3536 9.8632C61.8619 9.22652 62.3779 8.58984 62.9171 7.97369C65.3431 5.1882 70.3313 0.076758 74.3132 0.294976C75.4993 0.359158 75.4659 0.726278 74.4749 1.19866C70.1876 3.24477 66.6088 8.78495 64.1365 12.7283C63.5152 13.7218 62.627 15.4522 61.6129 15.9913C60.9711 16.3327 60.0982 16.2429 59.4974 15.8501C58.3499 15.103 56.979 11.3984 56.1805 9.99413C55.5721 8.78752 56.0547 7.31647 57.2614 6.70546C58.468 6.09701 59.939 6.57966 60.55 7.78628Z'
                        fill='#2FB454'/>,
    	},
	];
}

interface Letter {
	title: string,
	path: JSX.Element
}
