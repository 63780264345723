import {BaseService} from '@esgi/core/service';
import {
	IInitReportResponse,
	ReportModel,
	SortModelResponse,
	RequestModel,
	SubjectsResponse,
	BackgroundGenerationModel,
	Row,
} from './models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {ReportService} from 'modules/reports/utils/report-service';
import {tap} from 'rxjs';

export class GradeScaleReportService extends BaseService {
	public reportService: ReportService<Row> = new ReportService();
	private readonly controller = 'reports/grade-report-result';

	public closeGradeReport(request) {
		return this.httpClient.ESGIApi.post(
			this.controller,
			'close-grade-report',
			request,
		).subscribe();
	}

	public markingPeriodAll(value: boolean) {
		return this.httpClient.ESGIApi.post(
			this.controller,
			'change-marking-period-all',
			{value},
		);
	}

	public get(request: RequestModel) {
		return this.httpClient.ESGIApi.get<ReportModel>(
			this.controller,
			'get-grade-report',
			request,
		);
	}

	public init(request: RequestModel) {
		return this.httpClient.ESGIApi.post<IInitReportResponse>(
			this.controller,
			'init-grade-report',
			request,
		).pipe(tap(() => {
			this.reportService.setSortModel(null);
		}));
	}

	public selectGradeScale(request) {
		return this.httpClient.ESGIApi.post(
			this.controller,
			'update-grade-report',
			request,
		);
	}

	public subjects(hierarchy: HierarchySnapshot) {
		return this.httpClient.ESGIApi.get<SubjectsResponse>(
			this.controller,
			'subjects',
			hierarchy,
		);
	}

	public startBackgroundGeneration(options) {
		return this.httpClient.ESGIApi.post<BackgroundGenerationModel>(
			this.controller,
			'start-background-generation',
			options,
		);
	}

	public downloadFile(extension, options) {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = `Grade_Report_${year}-${month}-${day}.${extension}`;
		const actions = {
			pdf: 'download-grade-report-pdf',
			xlsx: 'download-grade-report-excel',
		};
		const {rows} = this.reportService.optionsToExport.value;

		return this.httpClient.ESGIApi.file(
			this.controller,
			actions[extension],
			filename,
			{...options, rows},
		);
	}

	public carryScoresForward(value: boolean) {
		return this.updateSettings('carryScoresForward', value);
	}

	public displayZeroIfNotTested(value: boolean) {
		return this.updateSettings('displayZeroIfNotTested', value);
	}

	private updateSettings(name, value) {
		return this.httpClient.ESGIApi.post(
			'reports/user-settings',
			'update',
			{name, value},
		);
	}
}
