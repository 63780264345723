import {Table, TableBody, TableHeader, TableHeaderColumn, useSortableColumns} from '@esgillc/ui-kit/table';
import {useCallback, useRef} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {OverlayScrollbarsComponent, OverlayScrollbarsComponentRef} from 'overlayscrollbars-react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useScrollToStudent} from '../hooks/use-scroll-to-student';
import {StudentCardsService} from 'pages/assignment-center/services/service';
import {FilterService} from '../../../services/filter-service';
import {StudentCredentialsService} from '../../../services/student-credentials-service';
import {StudentTableRow} from '../../table-row';
import styles from '../../../styles.module.less';
import {Icon} from '../../../kit';

type StudentCredentialsContentProps<Filter extends FilterService, Service extends StudentCredentialsService<Filter>> = {
	service: Service;
	onGoBack: VoidFunction;
	setCountStudentsWithoutCredentials: (value: number) => void;
	setShowMissingStudentsAlerts: (value: boolean) => void;
	credentialsService: StudentCardsService;
};

export function StudentCredentialsContent<
	Filter extends FilterService,
	Service extends StudentCredentialsService<Filter>,
>({
	service,
	onGoBack,
	setCountStudentsWithoutCredentials,
	setShowMissingStudentsAlerts,
	credentialsService,
}: StudentCredentialsContentProps<Filter, Service>) {
	const tableRef = useRef<OverlayScrollbarsComponentRef<'div'>>(null);
	const tableBodyRef = useRef<HTMLDivElement>(null);

	const tableData = useBehaviorSubject(service.filterService.tableData$);
	const isPasswordsHidden = useBehaviorSubject(service.filterService.isPasswordsHidden$);

	const {data, sortableColumns} = useSortableColumns({
		data: tableData,
		columns: {
			name: true,
		},
		activeSortedKey: 'name',
	});

	const isDownloadCredentialsDisabled = data.every(({userName, password}) => !userName || !password);

	const {activeStudentRowIndex} = useScrollToStudent({data, tableRef, tableBodyRef});

	const onPasswordHeadClick = useCallback(() => {
		service.filterService.setIsPasswordsHidden(!isPasswordsHidden);
	}, [isPasswordsHidden]);

	const downloadCredentials = useCallback(() => {
		credentialsService.all().subscribe();
	}, []);

	const onDoneButtonClick = useCallback(() => {
		const countStudentsWithoutCredentials = service.filterService.getCountCurrentStudentsWithoutCredentials();

		setCountStudentsWithoutCredentials(countStudentsWithoutCredentials);

		if (!service.filterService.isBannedModal && countStudentsWithoutCredentials) {
			setShowMissingStudentsAlerts(true);
			return;
		}

		onGoBack();
	}, [onGoBack, service.filterService.isBannedModal, setCountStudentsWithoutCredentials, setShowMissingStudentsAlerts]);

	if (!sortableColumns.name) {
		return null;
	}

	return (
		<div className={styles.tableContainer}>
			<Table className={styles.table}>
				<TableHeader className={styles.tableHead}>
					<TableHeaderColumn.Sortable {...sortableColumns.name} className={styles.nameColumn}>
						Students&nbsp;
					</TableHeaderColumn.Sortable>
					<TableHeaderColumn className={styles.usernameColumn}>Username</TableHeaderColumn>
					<TableHeaderColumn className={styles.passwordColumn} onClick={onPasswordHeadClick}>
						Password
						<OnHoverTooltip message={!isPasswordsHidden ? 'Hide passwords' : 'Show passwords'}>
							<div className={styles.passwordColumnIcon}>
								{!isPasswordsHidden ? <Icon.EyeIcon /> : <Icon.EyeCrossedIcon />}
							</div>
						</OnHoverTooltip>
					</TableHeaderColumn>
					<TableHeaderColumn className={styles.warningColumn} />
				</TableHeader>
				<OverlayScrollbarsComponent className={styles.tableBodyOverlayScrollbar} ref={tableRef}>
					<TableBody ref={tableBodyRef}>
						{data.map((student, index) => (
							<StudentTableRow
								key={student.id}
								service={service}
								student={student}
								isPasswordsHidden={isPasswordsHidden}
								isRowSelected={activeStudentRowIndex === index}
							/>
						))}
					</TableBody>
				</OverlayScrollbarsComponent>
			</Table>

			<div className={styles.bottomButtonsWrapper}>
				<OnHoverTooltip message={isDownloadCredentialsDisabled ? 'Please add student credentials to print cards.' : null} >
					<Buttons.Contained disabled={isDownloadCredentialsDisabled} onClick={downloadCredentials}>Print Student Cards</Buttons.Contained>
				</OnHoverTooltip>
				<Buttons.Contained onClick={onDoneButtonClick}>Done</Buttons.Contained>
			</div>
		</div>
	);
}
