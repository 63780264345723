import {FlexBox, FlexBoxProps} from '@esgillc/ui-kit/layout';
import {Buttons} from '@esgillc/ui-kit/button';
import React, {ReactNode} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import styles from './template-selector.module.less';

interface Props {
	available: boolean;
	buttonTitle: string;
	selected: () => void;
	className?: string;
	children: ReactNode;
}

class State{
	zoom: boolean = false;
}

export default class TemplateSelector extends React.PureComponent<Props, State> {
	public override readonly state = new State();
	private ref = React.createRef<HTMLDivElement>();

	constructor(props) {
		super(props);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}
	
	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick);
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick);
	}
	public override render(): ReactNode {
		const zoom = this.state.zoom ? styles.zoomed : '';
		const available = this.props.available ? styles.available : styles.unavailable;
		return <FlexBox direction='column' justify='center' align='center'>
			<FlexBox className={join(this.props.className, styles.selector,	available, zoom)}
					 onClick={() => this.handleClick()} ref={this.ref}>
				{this.props.children}
			</FlexBox>
			{this.props.available && this.renderAvailableButton()}
			{!this.props.available && this.renderUnavailableButton()}
		</FlexBox>;
	}

	private renderAvailableButton() {
		return <Buttons.Contained className={styles.btn} onClick={() => this.props.selected()}>
			{this.props.buttonTitle}
		</Buttons.Contained>;
	}

	private renderUnavailableButton() {
		return <Buttons.Gray className={styles.btn} disabled>
			{this.props.buttonTitle}
		</Buttons.Gray>;
	}
	private handleClick(){
		if (this.state.zoom === false){
			this.setState({zoom: true});
		} else {
			this.setState({zoom: false});
		}
	}

	private handleOutsideClick(event){
		if (this.ref && !this.ref.current.contains(event.target)){
			if (this.state.zoom === true){
				this.setState({zoom: false});
			}
		}
	}
}
