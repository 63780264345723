import {Text} from '@esgi/ui/typography';
import {ConflictedStudentInfoRow, LineNumber} from './index.styled';

interface Props {
	studentFullName: string;
	conflictedLineNumber: number;
	withRedColor?: boolean;
}

export function ConflictedStudentInfo({studentFullName, conflictedLineNumber, withRedColor}: Props) {

	return <ConflictedStudentInfoRow withRedColor={withRedColor}>
		<LineNumber>
			<Text data-cy='line-base' size='small' color={withRedColor ? 'negative' :'neutral40'}>Line</Text>
			<Text data-cy='line-number' size='small' color={withRedColor ? 'negative' :'neutral40'}>{conflictedLineNumber}</Text>
		</LineNumber>

		<Text data-cy='student-name' size='small' color={withRedColor ? 'negative' :'base'} bold>{studentFullName}</Text>
	</ConflictedStudentInfoRow>;
}
