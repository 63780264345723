import {Wrapper, ButtonText, SortTable} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {TestListProps} from '../../../../types';

interface Props extends Pick<TestListProps, 'sort' | 'onSort'> {}

export function Action({sort, onSort}: Props) {
	return (
		<Wrapper>
			<ButtonText onClick={onSort}>
				<Text data-cy='test-name-sort' size='small'>Test Name</Text>
				<SortTable field={sort.field} direction={sort.direction}/>
			</ButtonText>
		</Wrapper>
	);
}
