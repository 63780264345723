import {Fade} from '@esgillc/ui-kit/transition';
import styles from './styles.module.less';

export function WaitingScreen() {
	return <Fade in animateOnMount duration='common'>
		<div className={styles.waitScreen}>
			<span>Connected, waiting for a test to start...</span>
		</div>
	</Fade>;
}
