import {Cast, Question, Setting} from '@esgi/ui/icons';
import {Avatar} from '@esgi/ui/avatar';
import {styled} from '@esgi/ui/theme';
import {FlexBox, NavigationBar} from '@esgi/ui/layout';
import {UserInfo} from '../../../..//types';
import {OutlinedButtonIcon} from '../../../outlined-button-icon';
import {HelpButtonIcon} from '../../../help-button-icon';
import React from 'react';
import {AvatarHoverLayer} from '../../avatar-hover-layer';
import {AvatarFallback} from '../../avatar-fallback';
import {CustomNavItem} from '../../../custom-nav-item';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

interface Props {
	userInfo: UserInfo;
	onAvatarClick: () => void;
	onCastClick: () => void;
	onSettingsClick: () => void;
	onHelpClick: () => void;
}

const BottomNavItem = styled(NavigationBar.Item, {
	marginBottom: 0,
	padding: '6px 0',
});

const HelpNavItem = styled(CustomNavItem, {
	marginBottom: 0,
	padding: '6px 0',
});

export function VerticalPanelBottom({onAvatarClick, onSettingsClick, onHelpClick, onCastClick, userInfo}: Props) {
	return (
		<NavigationBar.Bottom>
			<FlexBox
				css={{
					alignItems: 'center',
					flexDirection: 'column',
					padding: '0 8px',
					width: '100%',
				}}
			>
				<BottomNavItem dataCy='nav-item-cast' onClick={onCastClick}>
					<Tooltip>
						<Tooltip.Trigger>
							<OutlinedButtonIcon css={{width: 40, height: 40}}>
								<Cast width={24} height={24} />
							</OutlinedButtonIcon>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small'>Student Screen</Text>
						</Tooltip.Content>
					</Tooltip>
				</BottomNavItem>
				<BottomNavItem dataCy='nav-item-settings' onClick={onSettingsClick}>
					<Tooltip>
						<Tooltip.Trigger>
							<OutlinedButtonIcon css={{width: 40, height: 40}}>
								<Setting width={24} height={24} />
							</OutlinedButtonIcon>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small'>System Settings</Text>
						</Tooltip.Content>
					</Tooltip>
				</BottomNavItem>
				<BottomNavItem>
					<Tooltip>
						<Tooltip.Trigger>
							<Avatar.Root
								onClick={onAvatarClick}
								size='lg'
								borderType='solid'
								css={{
									borderColor: '$mild',
								}}
							>
								<Avatar.Image src={userInfo.image} />
								<AvatarFallback firstName={userInfo.firstName} lastName={userInfo.lastName} />
								{Boolean(userInfo.image) && <AvatarHoverLayer />}
							</Avatar.Root>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small'>My Account</Text>
						</Tooltip.Content>
					</Tooltip>
				</BottomNavItem>

				<HelpNavItem css={{paddingBottom: 0}} data-cy='nav-item-help' onClick={onHelpClick}>
					<Tooltip>
						<Tooltip.Trigger>
							<HelpButtonIcon css={{width: 40, height: 36}}>
								<Question width={24} height={24} />
							</HelpButtonIcon>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small'>Help</Text>
						</Tooltip.Content>
					</Tooltip>
				</HelpNavItem>
			</FlexBox>
		</NavigationBar.Bottom>
	);
}
