import React from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {ReportSettingsService} from '../../../../../../services/report-settings-service';

interface Props {
	service: ReportSettingsService;
}

export function DetailReportSettingsGroups({service}: Props) {
	const groups = useBehaviorSubject(service.groups);
	const selectedGroup = useBehaviorSubject(service.selectedGroup);
	return groups && groups.length > 0 && selectedGroup && <>
		<div>
			<strong>Group: </strong>
			{groups.length > 1 && <>
				<Dropdown autoWidth={true}
				          setValue={(v) => service.changeHierarchy('groupChanged', v)}
				          optionName={'itemName'}
				          value={selectedGroup}>
					{groups.map(group => <Option key={group.itemID}
					                             value={group.itemID}>{group.itemName}</Option>)}
				</Dropdown>
			</>}
			{groups.length == 1 && <>
				<span>{groups[0].itemName}</span>
			</>}
		</div>
	</>;

}
