import {FontSizes} from 'shared/modules/assets/fonts';
import mx from 'shared/modules/assets/mx-graph/mx-graph-factory';
import {
	ContentAreaItem,
	GetBestSize,
	GetXML,
	Question,
	SerializeQuestionsProps,
} from './types';
import {canvasSize} from 'shared/modules/auto-test-creator/constants';

export const getContentAreaId = (name: string, contentAreas: ContentAreaItem[]): ContentAreaItem => {
	return contentAreas.filter(s => s.name === name)[0];
};

export const getBestSize = ({elements, fontFamily, canvasHeight, canvasWidth}: GetBestSize): number => {
	const startPos = FontSizes.length - 1;
	let bestSize = ptToPx(parseInt(FontSizes[startPos].replace('pt', ''), 10));

	for (let i = startPos; i >= 0; i--) {
		const ptSize = FontSizes[i];
		let foundedSizes = 0;

		elements.forEach((e, j) => {
			const value = mx.mxUtils.htmlEntities(elements[j], false).replace(' ', '&#160;');
			const pxSize = ptToPx(parseInt(ptSize.replace('pt', ''), 10));
			const size = mx.mxUtils.getSizeForString(value, pxSize, fontFamily, null, 0);

			const width = Math.ceil(size.width);
			const height = Math.ceil(size.height);

			if (width < canvasWidth && height < canvasHeight) {
				foundedSizes++;
				if (pxSize < bestSize) {
					let newBestSize = pxSize;
					if (i > 0) {
						newBestSize = ptToPx(parseInt(FontSizes[i - 1].replace('pt', ''), 10));
					}
					bestSize = newBestSize;
				}
			}
		});

		if (foundedSizes === elements.length) {
			break;
		}
	}
	return bestSize;
};

export const serializeQuestions = ({questions, directions, fontFamily, questionAreaSize}: SerializeQuestionsProps) => {
	const elements = questions.map(q => q.name);

	const size = getBestSize({
		elements,
		fontFamily: fontFamily,
		canvasWidth: canvasSize.width,
		canvasHeight: canvasSize.height,
	});

	if (questionAreaSize.width > canvasSize.width) {
		questionAreaSize = {
			...questionAreaSize,
			width: canvasSize.width,
		};
	}

	return elements.map((q, i) => {
		const text = q.trim();

		const xml = getXml({
			text,
			size,
			fontFamily,
			areaWidth: questionAreaSize.width,
			areaHeight: questionAreaSize.height,
		});

		return new Question(
			xml,
			text,
			directions,
			i === 0,
			i,
		);
	});
};

export const getXml = ({text, size, fontFamily, areaWidth, areaHeight}: GetXML): string => {
	const gridSize = 10;

	const safeText = replaceMultipleSpaces(mx.mxUtils.htmlEntities(text, false));
	const cellSize = mx.mxUtils.getSizeForString(safeText, size, fontFamily, null, 0);

	const width = Math.ceil(cellSize.width / gridSize) * gridSize;
	const height = Math.ceil(cellSize.height / gridSize) * gridSize;

	const left = Math.ceil(((areaWidth - cellSize.width) / 2) / gridSize) * gridSize;
	const top = Math.ceil(((areaHeight - cellSize.height) / 2) / gridSize) * gridSize;

	const x = parseInt(left.toString(), 10);
	const y = parseInt(top.toString(), 10);

	return `<mxGraphModel><root><mxCell id="0"/><mxCell id="1" parent="0"/><mxCell id="2" parent="1" style="spacing=0;fontColor=#f5f5f5;fontFamily=${fontFamily};fontSize=${size};fontStyle=0;align=left;editable=1;fillColor=transparent;strokeColor=transparent;verticalAlign=center;" type="1" value="${safeText}" vertex="1"><mxGeometry height="${height}" width="${width}" x="${x}" y="${y}" as="geometry"/></mxCell></root></mxGraphModel>`;
};

const ptToPx = (pt: number): number => {
	return Math.round((pt / 72) * 96);
};

const replaceMultipleSpaces = (text: string): string => {
	return text.replace(/\s{2,}/g, (m, i) => i % 2 === 0 ? '&#160;' : ' ');
};


