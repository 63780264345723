import {CardBody} from './components/card-body';
import {CardFooter} from './components/card-footer';
import {CardHeader} from './components/card-header';
import {CardRoot} from './components/card-root';
import {CardSeparator} from './components/card-separator';

export const Card = Object.assign(CardRoot, {
	Header: CardHeader,
	Body: CardBody,
	Footer: CardFooter,
	Separator: CardSeparator,
});
