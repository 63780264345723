import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {Test} from '@esgi/main/kits/reports';
import {SubjectType, SubjectLevel} from '@esgi/core/enums';
import {BehaviorSubject, Observable, combineLatest, debounceTime, switchMap, of, tap} from 'rxjs';
import {UnitDataSettings, PrintSettings, Sort, Entity, FileType} from '../types';
import {GenerateService} from './generate-service';
import {DownloadService} from './download-service';
import {StudentService} from './student-service';
import {SubjectService} from './subject-service';
import {TestService} from './test-service';
import {ReportService} from './report-service';
import {V2TeachersPagesReportsItemAnalysisController} from '@esgi/contracts/esgi';

export class ItemAnalysisService extends BaseService {
	public readonly entity$ = new BehaviorSubject<Entity>({classId: null, groupId: null});
	public readonly subject$ = new BehaviorSubject(0);
	public readonly test$ = new BehaviorSubject<Test['id'] | null>(null);
	public readonly sort$ = new BehaviorSubject<Sort>(Sort.LeastKnown);
	public readonly notTested$ = new BehaviorSubject(false);
	public readonly unitDataSettings$ = new BehaviorSubject<UnitDataSettings| null>(null);
	public readonly printSettings$ = new BehaviorSubject<PrintSettings | null>(null);
	public readonly preview$ = new BehaviorSubject(false);
	public readonly valid$: Observable<boolean> = this.completeOnDestroy(
		combineLatest([this.entity$, this.test$])
	).pipe(
		debounceTime(100),
		switchMap(([entity, testId]) => {
			const {classId, groupId} = entity;
			return of(Boolean(classId || groupId) && Boolean(testId));
		}),
	);
	public readonly busy$ = new BehaviorSubject(false);
	public studentService = new StudentService();
	public subjectService = new SubjectService();
	public testService = new TestService();
	public reportService = new ReportService();
	protected generateService = new GenerateService();
	protected downloadService = new DownloadService(this.reportService);
	protected currentUser = getUser();
	private readonly controller = new V2TeachersPagesReportsItemAnalysisController();

	constructor() {
		super();

		this.completeOnDestroy(
			combineLatest([this.testService.tests$])
		).pipe(
			tap(([tests]) => {
				this.setTest(tests[0]?.id ?? null);
			})
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.unitDataSettings$, this.testService.tests$])
		).pipe(
			debounceTime(500),
			tap(([unitDataSettings]) => {
				if (!unitDataSettings || !this.preview$.value) {
					return;
				}

				this.onPreview();
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.subject$])
		).pipe(
			debounceTime(100),
			tap(([subjectId]) => {
				if (!subjectId) {
					return;
				}
				const subject = this.subjectService.get(subjectId);
				this.testService.getTests(subject).subscribe(({value}) => {
					const {reportFilter} = value;

					this.notTested$.next(reportFilter.displayNotTestedAsIncorrect);
					this.reportService.initReport(value);
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			this.subjectService.loaded$
		).pipe(tap((value) => {
			if (value) {
				this.subject$.next(this.subjectService.getByIndex(0)?.id || 0);
			}
		})).subscribe();

		this.completeOnDestroy(
			combineLatest([this.entity$, this.test$, this.subject$])
		).pipe(
			debounceTime(100),
			tap(([entity, testId, subjectId]) => {
				if (!subjectId) {
					return;
				}

				const {
					id: subjectID,
					name,
					type: subjectType,
					level: subjectLevel,
				} = this.subjectService.get(subjectId);
				const selectedTest = this.testService.get(testId);
				const selectedGroup = this.studentService.getGroup(entity.groupId) || null;
				const selectedClass = this.studentService.getClass(entity.classId) || null;

				this.unitDataSettings$.next({
					selectedSubject: {
						subjectID,
						name,
						subjectType: SubjectType[subjectType],
						subjectLevel: SubjectLevel[subjectLevel],
					},
					selectedTest,
					selectedGroup,
					selectedClass,
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([
				this.notTested$,
				this.sort$,
			]),
		).pipe(
			debounceTime(100),
			tap(([notTested, sort]) => {
				this.printSettings$.next({
					...this.printSettings$.value,
					sortOptionMode: sort,
					displayNotTestedAsIncorrect: notTested,
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.downloadService.busy, this.downloadService.busyController])
		).pipe(
			debounceTime(100),
			tap(([busy, busyController]) => this.busy$.next(busy || busyController)),
		).subscribe();
	}

	public destroy() {
		super.destroy();
		this.subjectService.destroy();
		this.testService.destroy();
		this.downloadService.destroy();
		this.reportService.destroy();
	}

	public override dispose() {
		this.controller.dispose();
	}

	public setEntity(value: Entity) {
		this.entity$.next(value);
	}

	public setSubject(value: number) {
		this.subject$.next(value);
	}

	public setTest(value: Test['id'] | null) {
		this.test$.next(value);
	}

	public setSort(value: Sort) {
		this.sort$.next(value);
		this.reportService.sortOptionMode$.next(value.toString());
	}

	public setNotTested(value: boolean) {
		this.notTested$.next(value);
	}

	public getPages() {
		return this.downloadService.pages$.value;
	}

	public setPages(pages: string[]) {
		this.downloadService.pages$.next(pages);
	}

	public setPreview(value: boolean) {
		this.preview$.next(value);
	}

	public onPreview(callback?: VoidFunction) {
		const {id: subjectID, type: subjectType} = this.subjectService.get(this.subject$.value);
		const {classId, groupId} = this.entity$.value;
		this.downloadService.preview({
			subjectID,
			subjectType: SubjectType[subjectType],
			classID: classId === -1 ? 0 : classId,
			groupID: groupId,
			testID: this.test$.value,
		}).subscribe(
			({value}) => {
				this.reportService.updateReport(value);
				callback?.();
			},
		);
	}

	public onDownload(fileType: FileType) {
		const report = this.generateService.generateReport(
			this.unitDataSettings$.value,
			this.printSettings$.value
		);
		if (fileType === FileType.PDF) {
			this.downloadService.downloadReportPdf(report);
		}
		if (fileType === FileType.Excel) {
			this.downloadService.downloadReportExcel(report);
		}
		return this.downloadService.downloaded$;
	}

	public onDownloadDetails(html: string) {
		this.downloadService.downloadDetails(html);
	}
}
