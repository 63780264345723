import {useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {DownloadIcon} from '@esgillc/ui-kit/icons';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	testSessionsNumber: number;
	deletedCurrentSession: boolean;
	onDownload: (current: boolean) => void;
}

export function Download(props: Props): JSX.Element {
	const {testSessionsNumber, deletedCurrentSession, onDownload} = props;
	const downloadEnabled = testSessionsNumber > 0;
	const allSessionsEnabled = testSessionsNumber > 1;
	const [showMenu, setShowMenu] = useState(false);
	const toggle = () => setShowMenu(!showMenu);

	return (
		<div className={join(styles.download)}>
			<Buttons.Text
				className={join(styles.toggler, !downloadEnabled && styles.disabled)}
				onClick={toggle}
			>
				<DownloadIcon/>
				Download
			</Buttons.Text>
			<Menu
				show={showMenu}
				keepFocusInside
				onClickOutside={toggle}
				onEscPressed={toggle}
				className={styles.menu}
			>
				<MenuItem
					className={join(deletedCurrentSession && styles.disabled)}
					onSelect={() => onDownload(true)}
				>
						Current Session
					</MenuItem>
				<MenuItem
					className={join(!allSessionsEnabled && styles.disabled)}
					onSelect={() => onDownload(false)}
				>
					All Sessions
				</MenuItem>
			</Menu>
		</div>
	);
}
