import React from 'react';
import {Modal} from '@esgillc/ui-kit/modal';
import {FlexBox} from '@esgillc/ui-kit/layout';
import styles from './name-writing-preview-step.module.less';
import {NameWritingTemplate} from '../../shared/templates/name-writing/name-writing-template';
import {Buttons} from '@esgillc/ui-kit/button';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {NameWritingReport} from '../../shared/templates/name-writing/types';
import {NameWritingPdfDownloadingService} from '../../shared/services/name-writing-pdf-downloading-service';

interface Props {
	goToBack: () => void;
	pdfDownloaded: () => void;
	report: NameWritingReport;
	currentStep: number;
	totalStepsCount: number;
}

class State {
	report: NameWritingReport;

	constructor(report: NameWritingReport) {
		this.report = report;
	}
}

export default class NameWritingPreviewStep extends React.PureComponent<Props, State> {
	private readonly downloadPdfService: NameWritingPdfDownloadingService;

	constructor(props) {
		super(props);
		this.state = new State(this.props.report);
		this.downloadPdfService = new NameWritingPdfDownloadingService();
	}

	componentWillUnmount() {
		this.downloadPdfService.destroy();
	}

	render() {
		return <>
			<ServiceLoader trackingService={this.downloadPdfService}/>
			<Modal.Body className={styles.body}>
				<FlexBox align='stretch' justify='stretch' direction='column'>
					{this.renderStudentPreview()}
				</FlexBox>
			</Modal.Body>
			<Modal.Footer className={styles.footer}>
				<FlexBox align='center' justify='space-between' className={styles.navigation}>
					<div onClick={e => this.props.goToBack()} className={styles.backBtn}>
						<span className='glyphicon glyphicon-chevron-left arrow-icon'></span>
						<span>Back</span>
					</div>
					<div>
						<Buttons.Contained onClick={e => this.onDownloadPdf()}>Download</Buttons.Contained>
					</div>
				</FlexBox>
			</Modal.Footer>
		</>;
	}

	private renderStudentPreview() {
		return this.state.report?.students?.map((item, index) => <div className={styles.page} key={`preview_full_${index}`}>
			<NameWritingTemplate className={styles.template} details={this.state.report.details} student={item}/>
		</div>);
	}

	private onDownloadPdf() {
		this.downloadPdfService
			.downloadPdf(this.props.report)
			.subscribe(result => {
				if (result.isSuccess) {
					this.props.pdfDownloaded();
				}
			});
	}
}
