import {HttpClient} from '@esgi/api';
import {Observable, retry, timeout, catchError} from 'rxjs';
import {tap} from 'rxjs/operators';
import {dispatchAppEvent} from '@esgillc/events';
import {ESGI25518LongResponseTime} from '../../../../../events';

function sendSubmitAnswer(httpClient: HttpClient, data: FormData) {
	const startTime = Date.now();
	return httpClient.ESGIApi.post<any>(
		'assessment/test-screen/yn',
		'answer-question',
		data,
	).pipe(
		tap(() => {
			const duration = Date.now() - startTime;
			if(duration > 1e4) {
				dispatchAppEvent(ESGI25518LongResponseTime, new ESGI25518LongResponseTime());
				console.warn(`Request takes ${duration/1e3} s.`, data);
			}
		}),
	).asObservable();
}

export function submitAnswer(httpClient: HttpClient, data: FormData, withRetry?: boolean) {

	if (!withRetry) {
		return sendSubmitAnswer(httpClient, data);
	}

	return new Observable<any>((subscriber) => {
		const attemptRef = {current: 1};
		const sub = new Observable((requestSubscriber) => {
			const requestSub = sendSubmitAnswer(httpClient, data)
				.pipe(
					timeout({first: attemptRef.current * 1000}),
					catchError((err, caught) => {
						console.log(`catch Timeout error, attempt ${attemptRef.current}, retry.`);
						attemptRef.current++;
						throw err;
					}),
				).subscribe(requestSubscriber);
			return () => requestSub.unsubscribe();
		}).pipe(
			catchError((err) => {
				if (err?.name !== 'TimeoutError') {
					console.log(`catch unknown error, attempt ${attemptRef.current}, stop retry.`, err);
					subscriber.error(err);
				}

				throw err;
			}),
			retry(),
		).subscribe(subscriber);

		return () => sub?.unsubscribe();
	});
}