import {BehaviorSubject, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {GradeRange, TestInfo, TestSession, TrackDate} from '../types';
import {Student, SubjectType} from '@esgi/main/libs/store';
import {V2SessionDetailsController, V2TeachersTestDashboardsController} from '@esgi/contracts/esgi';

export class TestDashboard extends BaseService {
	public testInfo = new BehaviorSubject<TestInfo | null>(null);
	public gradeRanges = new BehaviorSubject<GradeRange[]>([]);
	public testSessions = new BehaviorSubject<TestSession[]>([]);
	public trackDates = new BehaviorSubject<TrackDate[]>([]);
	public classCorrectAnswersAvg$ = new BehaviorSubject<number | null>(null);

	private controller = new V2TeachersTestDashboardsController();
	private sessionDetailsController = new V2SessionDetailsController();

	public init({
		testID,
		studentID,
		subjectID,
		subjectType,
		classStudentsIDs,
	}: {
		testID: number;
		studentID: number;
		subjectID: number;
		subjectType: SubjectType;
		classStudentsIDs: Student['id'][];
	}) {
		return this.controller
			.byStudent({
				testID: testID,
				studentID: studentID,
				subjectID: subjectID,
				subjectType: subjectType,
				classStudentsIDs,
			})
			.pipe(
				tap((r) => {
					this.testInfo.next(r.testInfo);
					this.gradeRanges.next(r.gradeRanges);
					this.testSessions.next(r.testSessionsInfo.testSessions);
					this.classCorrectAnswersAvg$.next(r.testSessionsInfo.classCorrectAnswersAvg);
					this.trackDates.next(r.trackDates);
				}),
			);
	}

	public addTestSession(data: TestSession) {
		const currentTestSessions = this.testSessions.value;

		this.testSessions.next([...currentTestSessions, data]);
	}

	public getSessionDataRequest({testSessionID}: {testSessionID: number}) {
		return this.sessionDetailsController.init({testSessionID});
	}

	public override dispose() {
		this.controller.dispose();
		this.sessionDetailsController.dispose();
	}
}
