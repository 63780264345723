import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Skeletonable} from '../../../../types';
import {styled} from '../../../../theme';
import {CSS} from '@stitches/react';
import {Box} from '../../../box';

type CardRootProps = ComponentPropsWithoutRef<'div'> &
	Skeletonable & {
		/** Mandatory data attribute used by test automation team. */
		dataCy?: string;

		/** Returns a Style interface from a configuration, leveraging the given media and style map. */
		css?: CSS;
	};

export const CardRoot = forwardRef<HTMLDivElement, CardRootProps>(
	({dataCy, children, skeleton, ...props}, forwaredRef) => (
		<Div data-cy={dataCy ?? 'ui-kit-card'} {...props} ref={forwaredRef}>
			{children}
		</Div>
	),
);

const Div = styled(Box, {
	all: 'unset',
	borderRadius: 12,
	boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',
	backgroundColor: '$vivid',
	padding: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: 'transparent',
	transition: 'box-shadow .3s, border-color .3s',

	'&:hover': {
		borderColor: '$secondaryMuted',
		boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
	},
});
