import {BaseService} from '@esgi/core/service';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
	FormState,
	GradeRangeSelectionType,
	IGradeRange,
	ISubject,
	ITrack,
	SubjectLevel,
} from 'shared/modules/grade-scale/models';
import {Item} from '@esgi/deprecated/elements/custom-dropdown/dropdown';
import {ICustomGradeRangeSet} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/models';
import {Api} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/api';
import {Loader} from '@esgi/deprecated/jquery';
import {userStorage, UserType} from '@esgi/core/authentication';
import {
	GradeRangeEditorService,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/service';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class CustomGradeRangeService extends BaseService {
	public setUpGradeScaleMode: boolean;
	private loader: Loader;
	private initialized: boolean;
	private testsLoading: boolean;
	private toSaveGradeRanges: ICustomGradeRangeSet[] = [];
	private selectTestAction: () => void;

	private readonly track: BehaviorSubject<ITrack> = new BehaviorSubject<ITrack>(null);
	private readonly subjects: BehaviorSubject<ISubject[]> = new BehaviorSubject<ISubject[]>([]);
	private readonly gradeRangeSets: BehaviorSubject<ICustomGradeRangeSet[]> = new BehaviorSubject<ICustomGradeRangeSet[]>([]);
	private readonly gradeRangeEditors: BehaviorSubject<GradeRangeEditorService[]> = new BehaviorSubject<GradeRangeEditorService[]>([]);

	private readonly selectedTestID: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	private readonly selectedSubjectID: BehaviorSubject<number> = new BehaviorSubject<number>(null);

	private readonly selectedGradeRangeType: BehaviorSubject<GradeRangeSelectionType> = new BehaviorSubject<GradeRangeSelectionType>(null);

	private readonly formState: BehaviorSubject<FormState> = new BehaviorSubject<FormState>(FormState.None);
	private readonly viewAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(setUpGradeScaleMode: boolean, private hierarchy: HierarchySnapshot) {
		super();
		this.setUpGradeScaleMode = setUpGradeScaleMode;
		this.loader = new Loader($('body'));

		this.completeOnDestroy(combineLatest([this.selectedSubjectID, this.selectedTestID, this.selectedGradeRangeType]))
			.subscribe(data => {
				const selectedSubjectID = data[0];
				const selectedTestID = data[1];
				const selectedGradeRangeType = data[2];

				if (!selectedSubjectID || !selectedTestID) {
					return;
				}

				const gradeRangeSet = this.gradeRangeSets.value.filter(gre => gre.subjectID === selectedSubjectID && gre.testID === selectedTestID)[0];

				if (!gradeRangeSet) {
					return;
				}

				let editors = [];

				if (gradeRangeSet.applyDefaultRange) {
					editors = [this.createEditor(gradeRangeSet.defaultGradeRanges, gradeRangeSet)];
					editors[0].setDisabled(true);
				} else {
					let type = gradeRangeSet.type;

					if (selectedGradeRangeType !== null) {
						type = gradeRangeSet.type !== selectedGradeRangeType ? selectedGradeRangeType : gradeRangeSet.type;
					}

					if (type === GradeRangeSelectionType.SameAllYear) {
						editors = [this.createEditor(gradeRangeSet.allYearGradeRanges, gradeRangeSet)];
					}

					if (type === GradeRangeSelectionType.DifferentPerPeriod) {
						editors = this.createTrackEditors(gradeRangeSet.perPeriodGradeRanges, gradeRangeSet);
					}
				}

				this.gradeRangeEditors.value.forEach(gre => {
					gre.destroy();
				});

				this.gradeRangeEditors.next(editors);
			});

		this.completeOnDestroy(combineLatest([this.selectedSubjectID, this.track]))
			.subscribe(data => {
				const selectedSubjectID = data[0];
				const track = data[1];

				if (!selectedSubjectID) {
					return;
				}

				const subject = this.subjects.value.filter(s => s.id === selectedSubjectID)[0];

				if (!this.gradeRangeSets.value.some(t => t.subjectID === selectedSubjectID)) {
					this.loader.mask();
					this.testsLoading = true;

					Api.getGradeRangeSets(subject.id, subject.subjectType, subject.subjectLevel, track ? track.trackDates.length : 0, this.hierarchy).subscribe(response => {
						this.loader.unmask();
						this.testsLoading = false;

						this.gradeRangeSets.next(this.gradeRangeSets.value.concat(response.gradeRangeSets));

						this.selectTestAction();
					});
				} else {
					this.selectTestAction();
				}
			});

		this.completeOnDestroy(combineLatest([
			this.formState,
			this.toSaveGradeRanges$,
			this.gradeRangeEditors,
			this.viewAll,
			this.selectedGradeRangeSet$,
		])).subscribe(data => {
			const formState = data[0];
			const toSaveGradeRanges = data[1];
			const gradeRangeEditors = data[2];
			const viewAll = data[3];
			const selectedGradeRangeSet = data[4];

			if (formState === FormState.Saving) {
				const formValid = selectedGradeRangeSet.applyDefaultRange || gradeRangeEditors.every(e => e.formValid);

				if (formValid) {
					if (toSaveGradeRanges.length > 0) {
						this.setFormState(FormState.Valid);
					} else {
						this.setFormState(FormState.Closed);
					}
				} else {
					if (selectedGradeRangeSet.type === GradeRangeSelectionType.SameAllYear) {
						this.gradeRangeEditors.value[0].showValidation();
					}

					if (selectedGradeRangeSet.type === GradeRangeSelectionType.DifferentPerPeriod && !viewAll) {
						let hiddenRangesInvalid = false;
						this.gradeRangeEditors.value.forEach((gre, index) => {
							if (index > 0 && !hiddenRangesInvalid) {
								hiddenRangesInvalid = !gre.formValid;
							}
						});

						if (hiddenRangesInvalid) {
							this.viewAll.next(true);
						}
					}

					this.setFormState(FormState.None);
				}
			}
		});
	}

	public init(subjectID: number, testID?: number) {
		if (this.initialized) {
			this.selectSubject(subjectID, () => this.selectSpecificTest(testID));
			this.gradeRangeEditors.value.forEach(gre => gre.hideValidation());
		} else {
			this.loader.mask();

			Api.init(this.hierarchy).subscribe(response => {
				this.subjects.next(response.subjects);
				this.track.next(response.track);

				if (response.subjects.every(s => s.id !== subjectID)) {
					subjectID = response.subjects[0].id;
          testID = 0;
				}

				const context = userStorage.get();

				if (this.setUpGradeScaleMode && context.userType === UserType.C) {
					const selectedSubject = response.subjects.filter(s => s.id === subjectID)[0];
					if (selectedSubject.subjectLevel === SubjectLevel.District) {
						const schoolSubject = response.subjects.filter(s => s.subjectLevel === SubjectLevel.School)[0];
						if (schoolSubject) {
							subjectID = schoolSubject.id;
						}
					}
				}

				const selectAction = testID ? () => this.selectSpecificTest(testID) : () => this.selectFirstTest();
				this.selectSubject(subjectID, selectAction);

				this.initialized = true;
			});
		}
	}

	private selectFirstTest() {
		const subjectTests = this.gradeRangeSets.value.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		this.selectTest(subjectTests[0].testID);
	}

	private selectSpecificTest(testID?: number) {
		this.selectTest(testID);
	}

	private selectLastTest() {
		const subjectTests = this.gradeRangeSets.value.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		this.selectTest(subjectTests[subjectTests.length - 1].testID);
	}

	private gradeRangeSets$: Observable<ICustomGradeRangeSet[]> = this.completeOnDestroy(
		combineLatest([this.gradeRangeSets, this.selectedSubjectID])
			.pipe(map(data => {
				const gradeRangeSetsState = data[0];
				const selectedSubjectID = data[1];

				if (!selectedSubjectID) {
					return [];
				}

				return gradeRangeSetsState.filter(grs => grs.subjectID === selectedSubjectID);
			})));

	public nextTest() {
		const selectedTest = this.gradeRangeSets.value.filter(grs => grs.testID === this.selectedTestID.value)[0];
		const testsInSubject = this.gradeRangeSets.value.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		const selectedTestNumber = testsInSubject.findIndex(t => t.testID === selectedTest.testID);
		if (selectedTestNumber + 1 === testsInSubject.length) {
			const selectedSubjectNumber = this.subjects.value.findIndex(t => t.id === this.selectedSubjectID.value);
			if (selectedSubjectNumber + 1 < this.subjects.value.length) {
				this.selectSubject(this.subjects.value[selectedSubjectNumber + 1].id, this.selectFirstTest);
			}
		} else {
			this.selectTest(testsInSubject[selectedTestNumber + 1].testID);
		}
	}

	public prevTest() {
		const selectedTest = this.gradeRangeSets.value.filter(grs => grs.testID === this.selectedTestID.value)[0];
		const testsInSubject = this.gradeRangeSets.value.filter(grs => grs.subjectID === this.selectedSubjectID.value);
		const selectedTestNumber = testsInSubject.findIndex(t => t.testID === selectedTest.testID);
		if (selectedTestNumber === 0) {
			const selectedSubjectNumber = this.subjects.value.findIndex(t => t.id === this.selectedSubjectID.value);
			if (selectedSubjectNumber > 0) {
				const newSubjectID = this.subjects.value[selectedSubjectNumber - 1].id;
				this.selectSubject(newSubjectID, this.selectLastTest);
			}
		} else {
			this.selectTest(testsInSubject[selectedTestNumber - 1].testID);
		}
	}

	public selectedGradeRangeSet$: Observable<ICustomGradeRangeSet> = this.completeOnDestroy(combineLatest(this.gradeRangeSets$, this.selectedTestID)
		.pipe(map(data => {
			const gradeRangeSets = data[0];
			const selectedTestID = data[1];

			if (gradeRangeSets.length === 0 || !selectedTestID) {
				return null;
			}

			return gradeRangeSets.filter(g => g.testID === selectedTestID)[0];
		})));

	public subjects$: Observable<Item[]> = this.completeOnDestroy(this.subjects).pipe(map(subjects => {
		return subjects.map(s => this.mapSubjectToDropdownItem(s));
	}));

	public dropdownTests$: Observable<Item[]> = this.completeOnDestroy(this.gradeRangeSets$)
		.pipe(map(gradeRangeSets => {
			return gradeRangeSets.map(s => this.mapSetToDropdownItem(s));
		}));

	public selectedSubject$: Observable<Item> = this.completeOnDestroy(combineLatest([this.selectedSubjectID, this.subjects])).pipe(map(data => {
		const selectedSubjectID = data[0];
		const subjects = data[1];

		if (!selectedSubjectID) {
			return null;
		}

		let subject = subjects.filter(s => s.id === selectedSubjectID)[0];
		return this.mapSubjectToDropdownItem(subject);
	}));

	public gradeRangeEditors$: Observable<GradeRangeEditorService[]> = this.completeOnDestroy(combineLatest(this.gradeRangeEditors, this.viewAll)
		.pipe(map(data => {
			const gradeRangeEditors = data[0];
			const viewAll = data[1];

			if (gradeRangeEditors.length === 0) {
				return [];
			}

			if (!viewAll) {
				return [gradeRangeEditors[0]];
			}

			return gradeRangeEditors;
		})));

	public save() {
		if (this.formState.value === FormState.None || this.formState.value === FormState.Changing) {
			this.setFormState(FormState.Saving);
		}
	}

	public notValidEditor$: Observable<number> = this.completeOnDestroy(combineLatest(this.gradeRangeEditors, this.formState).pipe(map(data => {
		const gradeRangeEditors = data[0];
		const formState = data[1];

		if (gradeRangeEditors.length === 0 || formState !== FormState.Saving) {
			return null;
		}

		return gradeRangeEditors.findIndex(e => !e.formValid);
	})));

	public formState$: Observable<FormState> = this.completeOnDestroy(this.formState);

	public track$: Observable<ITrack> = this.completeOnDestroy(this.track);

	public viewAll$: Observable<boolean> = this.completeOnDestroy(this.viewAll);

	public toSaveGradeRanges$: Observable<ICustomGradeRangeSet[]> = this.completeOnDestroy(
		combineLatest([this.gradeRangeEditors, this.selectedGradeRangeSet$])
			.pipe(map(data => {
				const gradeRangeEditors = data[0];
				const selectedGradeRangeSet = data[1];

				if (!selectedGradeRangeSet
				|| gradeRangeEditors.length === 0
				|| selectedGradeRangeSet.subjectID !== gradeRangeEditors[0].subjectID
				|| selectedGradeRangeSet.testID !== gradeRangeEditors[0].testID) {
					return [];
				}

				let formValid = gradeRangeEditors.every(e => e.formValid) || selectedGradeRangeSet.applyDefaultRange;

				if (formValid) {
					const existing = this.toSaveGradeRanges.some(g => g.testID === selectedGradeRangeSet.testID
					&& g.subjectID === selectedGradeRangeSet.subjectID);

					if (existing) {
						this.toSaveGradeRanges = this.toSaveGradeRanges.map(grs => {
							if (grs.testID === selectedGradeRangeSet.testID && grs.subjectID === selectedGradeRangeSet.subjectID) {
								return {...selectedGradeRangeSet};
							}
							return {...grs};
						});
					} else {
						this.toSaveGradeRanges.push(selectedGradeRangeSet);
					}
				} else {
					const removedIndex = this.toSaveGradeRanges.findIndex(g => g.testID === selectedGradeRangeSet.testID
					&& g.subjectID === selectedGradeRangeSet.subjectID);

					if (removedIndex !== -1) {
						this.toSaveGradeRanges.splice(removedIndex, 1);
					}
				}

				//remove duplicate tests
				return this.toSaveGradeRanges
					.filter((grs, i, self) => self.findIndex(s => s.testID === grs.testID) === i);
			})));

	public selectDropdownSubject(subjectID: number) {
		this.selectTestAction = this.selectFirstTest;
		this.selectedSubjectID.next(subjectID);
	}

	private selectSubject(subjectID: number, action?: () => void) {
		this.selectTestAction = action;
		this.selectedSubjectID.next(subjectID);
	}

	public selectTest(testID: number) {
		this.selectedTestID.next(testID);
	}

	public setViewAll(viewAll: boolean) {
		this.gradeRangeEditors.value.forEach(gre => gre.hideValidation());
		this.viewAll.next(viewAll);
	}

	public setShareRange(share: boolean) {
		this.setFormState(FormState.Changing);
		const gradeRangeSet = {...this.gradeRangeSets.value.filter(grs => grs.testID === this.selectedTestID.value
				&& grs.subjectID === this.selectedSubjectID.value)[0]};
		gradeRangeSet.shareRange = share;
		this.updateGradeRangeSets(gradeRangeSet);
	}

	public applyDefaultRange(apply: boolean) {
		this.setFormState(FormState.Changing);
		const gradeRangeSet = {...this.gradeRangeSets.value.filter(grs => grs.testID === this.selectedTestID.value
				&& grs.subjectID === this.selectedSubjectID.value)[0]};
		gradeRangeSet.applyDefaultRange = apply;
		this.updateGradeRangeSets(gradeRangeSet);
		this.updateGradeRangeType(gradeRangeSet.type);

		if (apply) {
			showSnackbarNotification(`The default range has been applied.`);
		}
	}

	public updateGradeRangeType(type: GradeRangeSelectionType) {
		const gradeRangeSet = {...this.gradeRangeSets.value.filter(grs => grs.testID === this.selectedTestID.value
				&& grs.subjectID === this.selectedSubjectID.value)[0]};

		gradeRangeSet.type = type;
		this.selectedGradeRangeType.next(type);
		this.setFormState(FormState.Changing);
		this.updateGradeRangeSets(gradeRangeSet);
	}

	public setFormState(formState: FormState) {
		this.formState.next(formState);
	}

	private createEditor(gradeRanges: IGradeRange[], selectedGradeRangeSet: ICustomGradeRangeSet) {
		const service = new GradeRangeEditorService(selectedGradeRangeSet.questionsCount);

		service.gradeRangesResult$.subscribe((gradeRanges) => {
			this.updateGradeRanges(gradeRanges);
		});

		service.changedGradeRange$.subscribe((grf) => {
			this.formState.next(FormState.Changing);
		});

		service.goToNextEditor$.subscribe(() => {
			const editors = this.gradeRangeEditors.value;
			const gradeRangeSet = this.gradeRangeSets.value.filter(gre => gre.subjectID === this.selectedSubjectID.value && gre.testID === this.selectedTestID.value)[0];

			if (gradeRangeSet.perPeriodGradeRanges) {
				const selfIndex = editors.findIndex((e, i) => {
					return e.editorID === service.editorID;
				});

				const nextService = editors.filter((e, i) => e.editorID !== service.editorID && i > selfIndex)[0];

				if (nextService) {
					nextService.setFocusFirstEmptyGradeRange();
				}
			}
		});

		service.setEditor(gradeRanges, {...selectedGradeRangeSet.gradeScale}, this.selectedSubjectID.value, this.selectedTestID.value);

		return service;
	}

	private createTrackEditors(gradeRanges: IGradeRange[], selectedGradeRangeSet: ICustomGradeRangeSet) {
		return this.track.value.trackDates.map((trackDate, index) => {
			const byPeriodGradeRanges = gradeRanges.filter(gr => gr.markingPeriod === index + 1);
			return this.createEditor(byPeriodGradeRanges, selectedGradeRangeSet);
		});
	}

	private mapSetToDropdownItem(test: ICustomGradeRangeSet) {
		return {
			key: test.testID,
			title: test.testName,
			value: test.testID,
		} as Item;
	}

	private mapSubjectToDropdownItem(subject: ISubject) {
		return {
			key: subject.id,
			title: subject.name,
			value: subject.id,
		} as Item;
	}

	private updateGradeRanges(gradeRanges: IGradeRange[]) {
		const newGradeRangeSet = this.gradeRangeSets.value.filter(grs => grs.testID === this.selectedTestID.value
			&& grs.subjectID === this.selectedSubjectID.value)[0];

		if (newGradeRangeSet.type === GradeRangeSelectionType.SameAllYear) {
			newGradeRangeSet.allYearGradeRanges = gradeRanges;
		}

		if (newGradeRangeSet.type === GradeRangeSelectionType.DifferentPerPeriod) {
			newGradeRangeSet.perPeriodGradeRanges = newGradeRangeSet.perPeriodGradeRanges.map(gr => {
				const newGradeRange = {...gr};
				const updatedGradeRange = gradeRanges.filter(gr => gr.gradeScaleEntryID === newGradeRange.gradeScaleEntryID
					&& gr.markingPeriod === newGradeRange.markingPeriod)[0];

				return updatedGradeRange ? updatedGradeRange : newGradeRange;
			});
		}

		this.updateGradeRangeSets(newGradeRangeSet);
	}

	private updateGradeRangeSets(gradeRangeSet: ICustomGradeRangeSet) {
		const newGradeRangeSets = this.gradeRangeSets.value.map(t => {
			if (t.testID === gradeRangeSet.testID && t.subjectID === gradeRangeSet.subjectID) {
				return gradeRangeSet;
			} else {
				return {...t};
			}
		});

		this.gradeRangeSets.next(newGradeRangeSets);
	}

	public destroy() {
		this.gradeRangeEditors.value.forEach(gre => {
			gre.destroy();
		});

		super.destroy();
	}
}
