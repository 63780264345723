import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RightPanelBox} from 'shared/right-panel/right-panel';
import {RightPanelBoxItem} from 'shared/right-panel/right-panel-box';
import {SubjectItem} from '../../services/subjects-service';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import rightPanelStyles from 'pages/test-explorer/components/side-panels/right-panel.module.less';

const TestCreatorLauncher = lazyComponent(() => import('../../../../modules/assets/tests/creating-launcher/root'));
const AutoTestCreator = lazyComponent(() => import('shared/modules/auto-test-creator'));
const ManageSubjectsAndTestsModal = lazyComponent(() => import('modules/manage-subjects-and-tests'));

export class State {
	manageSubjectsOpened: boolean = false;
	testCreatorLauncherOpened: boolean = false;
	autoTestCreatorOpened: boolean = false;
}

export class Props {
	subjects: SubjectItem[] = [];
}

export class CreateTestPanel extends React.Component<Props, State> {
	public state = new State();

	render(): JSX.Element | false | null {
		return <RightPanelBox title='Subject Tabs & Tests' className='create-test-panel'
		                      titleClassName={rightPanelStyles.boxTitle}>
			<RightPanelBoxItem>
				<OnHoverTooltip message='Add, edit and organize subject tabs and tests'>
					<a href='#' onClick={() => this.openManageSubjects()}>
						<svg className='icon' xmlns='http://www.w3.org/2000/svg' width='18' height='18'
						     viewBox='0 0 16 16'
						     fill='none'>
							<path
								d='M13.95 8.78C13.98 8.53 14 8.27 14 8C14 7.73 13.98 7.47 13.94 7.22L15.63 5.9C15.78 5.78 15.82 5.56 15.73 5.39L14.13 2.62C14.03 2.44 13.82 2.38 13.64 2.44L11.65 3.24C11.23 2.92 10.79 2.66 10.3 2.46L10 0.34C9.97001 0.14 9.80001 0 9.60001 0H6.40001C6.20001 0 6.04001 0.14 6.01001 0.34L5.71001 2.46C5.22001 2.66 4.77001 2.93 4.36001 3.24L2.37001 2.44C2.19001 2.37 1.98001 2.44 1.88001 2.62L0.280007 5.39C0.180007 5.57 0.220008 5.78 0.380008 5.9L2.07001 7.22C2.03001 7.47 2.00001 7.74 2.00001 8C2.00001 8.26 2.02001 8.53 2.06001 8.78L0.370007 10.1C0.220007 10.22 0.180007 10.44 0.270007 10.61L1.87001 13.38C1.97001 13.56 2.18001 13.62 2.36001 13.56L4.35001 12.76C4.77001 13.08 5.21001 13.34 5.70001 13.54L6.00001 15.66C6.04001 15.86 6.20001 16 6.40001 16H9.60001C9.80001 16 9.97001 15.86 9.99001 15.66L10.29 13.54C10.78 13.34 11.23 13.07 11.64 12.76L13.63 13.56C13.81 13.63 14.02 13.56 14.12 13.38L15.72 10.61C15.82 10.43 15.78 10.22 15.62 10.1L13.95 8.78ZM8.00001 11C6.35001 11 5.00001 9.65 5.00001 8C5.00001 6.35 6.35001 5 8.00001 5C9.65001 5 11 6.35 11 8C11 9.65 9.65001 11 8.00001 11Z'
								fill='#0088CC'/>
						</svg>
						<span className={rightPanelStyles.link}>Manage Tabs &amp; Tests</span>
					</a>
				</OnHoverTooltip>
			</RightPanelBoxItem>
			<RightPanelBoxItem>
				<OnHoverTooltip message='Add a new test to your test library.'>
					<a href='#' className='create-test-link' onClick={() => this.openTestDetails()}>
						<svg className='icon' xmlns='http://www.w3.org/2000/svg' width='16' height='16'
						     viewBox='0 0 16 16' fill='none'>
							<path
								d='M8.8 4H7.2V7.2H4V8.8H7.2V12H8.8V8.8H12V7.2H8.8V4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z'
								fill='#0088CC'/>
						</svg>
						<span className={rightPanelStyles.link}>Create New Test</span>
					</a>
				</OnHoverTooltip>
			</RightPanelBoxItem>
			<RightPanelBoxItem>
				<OnHoverTooltip message='Quickly create a test using our auto create tool.'>
					<a href='#' className='auto-test-creator-link' onClick={() => this.openAutoTestCreatorNew()}>
						<svg className='icon' xmlns='http://www.w3.org/2000/svg' width='16' height='16'
						     viewBox='0 0 16 16' fill='none'>
							<path
								d='M8.8 4H7.2V7.2H4V8.8H7.2V12H8.8V8.8H12V7.2H8.8V4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z'
								fill='#0088CC'/>
						</svg>
						<span className={rightPanelStyles.link}>Auto Test Creator</span>
					</a>
				</OnHoverTooltip>
			</RightPanelBoxItem>
			{this.renderManageSubjects()}
			{this.renderAutoTestCreator()}
			{this.renderTestsDetails()}
		</RightPanelBox>;
	}

	openManageSubjects() {
		this.setState({manageSubjectsOpened: true});
	}

	openAutoTestCreatorNew() {
		this.setState({autoTestCreatorOpened: true});
	}

	renderManageSubjects() {
		if (this.state.manageSubjectsOpened) {
			return <Suspense fallback={<></>}>
				<ManageSubjectsAndTestsModal hierarchy={null} closed={() => {
					this.setState({manageSubjectsOpened: false});
				}}/>
			</Suspense>;
		}

		return null;
	}

	onCloseAutoTestCreator = () => {
		this.setState({autoTestCreatorOpened: false});
	};

	renderAutoTestCreator() {
		if (this.state.autoTestCreatorOpened) {
			return <Suspense fallback={<></>}>
				<AutoTestCreator close={this.onCloseAutoTestCreator}
				                         subjects={this.props.subjects}
																 onTestCreated={this.onCloseAutoTestCreator}
																 />
			</Suspense>;
		}

		return null;
	}

	onCloseTestDetails = () => {
		this.setState({testCreatorLauncherOpened: false});
	};

	renderTestsDetails() {
		if (this.state.testCreatorLauncherOpened) {
			return <Suspense fallback={<div/>}>
				<TestCreatorLauncher onClose={this.onCloseTestDetails} onTestCreated={this.onCloseTestDetails}/>
			</Suspense>;
		}
	}

	private openTestDetails() {
		this.setState({testCreatorLauncherOpened: true});
	}
}
