import * as React from 'react';
import type {SVGProps} from 'react';

export function Prev(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='m14.121 12.293-2.828 2.828a1 1 0 0 0-.26.964c.035.196.128.385.28.536l2.828 2.829a1 1 0 1 0 1.415-1.415L14.32 16.8l4.773.001a1 1 0 1 0 0-2l-4.65-.001 1.093-1.092a1 1 0 1 0-1.415-1.414Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
