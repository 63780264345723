import {OldAlerts, Renderable} from '@esgi/deprecated/knockout';
import {resolvedPromise} from '@esgi/deprecated/utils';
import {FieldContainer} from '../../../kit/component';
import {
	ACInputComponent,
	EmailInputComponent,
	PasswordInputComponent,
	RegularSchoolSelectComponent,
	UsernameInputComponent,
} from '../../components';
import {AcUserInfoModel, FlowResultModel} from '../../types';
import {ACTemplate} from './templates';
import {resizeToWindow} from '../../utils';
import {ValidationServer} from '../../server';

import './ac.less';
import {Loader} from '@esgi/deprecated/jquery';


export class ACFlow extends Renderable {
	email: EmailInputComponent;
	ac: ACInputComponent;
	school: RegularSchoolSelectComponent;
	username: UsernameInputComponent;
	password: PasswordInputComponent;
	fields: Array<FieldContainer>;
	userCreating: KnockoutObservable<boolean> = ko.observable(false);
	showNameAndPasswordFields: KnockoutObservable<boolean> = ko.observable(false);
	generateReCaptchaToken;
	loader: Loader = new Loader($('#app'));


	constructor(opts?: AcUserInfoModel) {
		super();
		this.userCreating(false);
		this.showNameAndPasswordFields(opts.showNameAndPasswordFields || false);
		this.generateReCaptchaToken = opts.generateReCaptchaToken;
		this.createFields(opts);
	}

	template = () => {
		resizeToWindow();
		return ACTemplate.Render();
	};

	createFields(opts: AcUserInfoModel) {
		this.email = new EmailInputComponent(opts && opts.email, false, true);
		this.username = new UsernameInputComponent(opts && opts.userName);
		this.password = new PasswordInputComponent(opts && opts.password);
		this.ac = new ACInputComponent(opts && {activationCode: opts.activationCode, activationCodeInfo: opts.activationCodeInfo});
		this.school = new RegularSchoolSelectComponent(opts?.activationCodeInfo?.schools || []);
		this.fields = new Array<FieldContainer>();
		this.fields.push(...[this.ac, this.email, this.username, this.password]);
	}

	isValid() {
		let schoolValidation;
		if (!!this.ac.activationCodeInfo().schools && this.ac.activationCodeInfo().schools.length) {
			schoolValidation = this.school.validate();
		} else {
			schoolValidation = resolvedPromise(true);
		}
		return $.whenAll([...this.fields.map(f => f.validation.validate()), resolvedPromise(this.ac.activationCodeInfo().valid), schoolValidation]);
	}

	serialize(): FlowResultModel<AcUserInfoModel> {
		const school = this.ac.activationCodeInfo().schools && this.ac.activationCodeInfo().schools.length ? this.school.serialize() : null;
		return {
			model: {
				email: this.email.serialize(),
				password: this.password.serialize(),
				userName: this.username.serialize(),
				activationCode: this.ac.serialize(),
				schoolID: school ? school.schoolID : null,
				federalSchoolID: school ? school.federalSchoolID : null,
			},
		} as FlowResultModel<AcUserInfoModel>;
	}

	events = {
		back: (callback) => {
			$(this).on('backClicked', callback);
		},
		done: (callback) => {
			$(this).on('doneClicked', callback);
		},
		submit: (callback) => {
			$(this).on('submitClicked', () => {
				callback(this.email.serialize(), this.ac.serialize(), this.ac.activationCodeInfo());
			});
		},
	};


	private back() {
		$(this).trigger('backClicked');
	}

	private async next() {
		if (this.showNameAndPasswordFields()) {
			this.done();
			return;
		}

		await this.email.validate();
		await this.ac.validate();

		if(this.email.validation.valid() && this.ac.validation.valid()) {
			this.loader.mask();
			const action = 'checkACAndEmail';
			await this.generateReCaptchaToken(action);

			const model = {
				email: this.email.serialize(),
				activationCode: this.ac.serialize(),
				action: action,
				captchaToken: localStorage.getItem('RECAPTCHA_TOKEN'),
			};

			ValidationServer.checkACAndEmail(model)
				.done(response => {
					this.loader.unmask();
					if (!response.isSuccess) {
						const errorMessage = response?.errors?.[0].description;
						errorMessage && OldAlerts.bsalert(errorMessage);
						return;
					}
					const {acValidationResult, emailValidationResult} = response.value;
					this.ac.activationCodeInfo(acValidationResult);
					const notAllowedEmails = acValidationResult.notAllowedEmails?.map((e) =>e.toLowerCase());
					if (notAllowedEmails.includes(this.email.serialize().trim().toLowerCase())) {
						this.email.setNotAllowedEmails(notAllowedEmails);
						return;
					}

					if (!acValidationResult.valid) {
						return;
					}

					if (emailValidationResult.exist) {
						$(this).trigger('submitClicked');
						return;
					}

					this.school.setSchools(acValidationResult.schools || []);
					this.ac.disableField();
					this.email.disableField();
					this.showNameAndPasswordFields(true);
				})
				.fail(() => OldAlerts.bsalert('Uh oh. Something went wrong on our end. Please contact Customer Support' +
          ' (support@esgisoftware.com) for assistance.', () => document.location.reload()),
				);
		}
	}

	private done() {
		this.isValid().done((r) => {
			const isValid = r.reduce((a, b) => a && b);
			if (isValid) {
				$(this).trigger('doneClicked', {data: this.serialize()});
			} else {
				this.userCreating(false);
			}
		});
	}
}
