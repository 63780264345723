import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {AlertBody, CustomLink} from './index.styled';

type Props = {
	studentsLimit: number,
	onClose: VoidFunction,
};

export function StudentsLimitDialog({studentsLimit, onClose}: Props) {
	const modal = Alert.useRef();
	const close = Alert.useClose(modal, onClose);

	return <Alert modalManagerRef={modal} colorConfig={alertColorsConfig.negative}>
		<Alert.Header withCloseIcon={false}>
			<Text size='medium'>You Reached the Maximum Number of Students</Text>
		</Alert.Header>
		<AlertBody>
			<Text size='large'>
				You have reached a maximum number of {studentsLimit} students in
				your classes. Please remove students or increase the maximum number by
				purchasing additional student slots. Please contact us with any
				questions.
			</Text>
			<Text size='large'>
				Links for purchasing additional slots:
				<CustomLink to='/renewal/'>Renewal</CustomLink>
			</Text>
			<Text size='large'>
				contact us:
				<CustomLink
					to='https://support.esgisoftware.com/hc/en-us/requests/new'
					target='_blank'
				>
					Support
				</CustomLink>
			</Text>
		</AlertBody>
		<Alert.Footer>
			<Button onClick={close}>
				<Text size='large'>Close</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}