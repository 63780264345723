import {getUser} from '@esgi/core/authentication';
import {UnitType} from '@esgi/main/kits/activities';
import {DownloadRequest, PrintSettings, StudentSettings, TestModelRequest, UnitDataSettings} from '../types';
import {uniq} from 'underscore';

export class GenerateService {
	protected currentUser = getUser();

	public generateReport(
		unitDataSettings: UnitDataSettings,
		printSettings: PrintSettings,
	): DownloadRequest['generateSettings'] {
		const tests: TestModelRequest[] = uniq(unitDataSettings.sessions.map((id) => {
			const [testId] = id.split('-');
			return +testId;
		})).map((testId) => {
			const {id} = unitDataSettings.tests.find(({id}) => id === testId) || {};

			return {
				testID: id || testId,
				max: null,
				testDate: null,
			};
		});
		const students: Record<number, StudentSettings> = unitDataSettings.sessions.reduce((result, id) => {
			const [testId, studentId] = id.split('-');
			const {
				studentId: studentID = 0,
				unitData: {id: groupItemID, type} = {id: 0, type: UnitType.Group},
			} = unitDataSettings.students.find(({studentId: id}) => id === +studentId) || {};

			if (!result[studentID]) {
				result[studentID] = {
					studentID,
					groupItemID,
					groupItemLevel: type === UnitType.Class ? 'Class' : 'Group',
					testIDs: [],
				};
			}
			result[studentID].testIDs.push(+testId);
			return result;
		}, {});
		const studentsSettings = Object.values(students).filter(({studentID}) => studentID);

		return {
			tests,
			teacherID: this.currentUser.userID,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
			subject: unitDataSettings.subject,
			studentsSettings,
			...printSettings,
		};
	}

	public destroy() {}
}
