import {getDistrictID} from '@esgi/core/authentication';
import styles from './styles.module.less';

export const District = (): JSX.Element => {
	const districtId = getDistrictID();

	return (
		<div className={styles.district}>
			District ID: {districtId}
		</div>
	);
};
