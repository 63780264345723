import React from 'react';
export class AlertTemplate {
	static render(): React.ReactElement {
		return <div className='alert' data-bind='keypress: keypressed, afterRender: true'>
			<div className='body' data-bind='html: content'/>
			<ko data-bind="if: type() == 'Prompt'">
				<div className='form-group'>
					<input type='text' className='form-control' data-bind='textInput: value'/>
				</div>
			</ko>
		</div>;
	}
}