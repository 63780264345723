import {useMemo, useState} from 'react';
import {createTestIntroImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';

export function useQuestionImage(testIntroID: number, testID: number, pregenerated: boolean, modifyTicks: number): JSX.Element {
	const [failed, setFailed] = useState(false);

	const src = useMemo(() => {
		const urlBuilder = createTestIntroImageUrlBuilder();
		const directUrl = urlBuilder.large(testIntroID, testID, pregenerated, modifyTicks);
		const fallbackUrl = urlBuilder.large(testIntroID, testID, false, modifyTicks);
		return failed ? fallbackUrl : directUrl;
	}, [failed]);

	return <img src={src} alt='' onError={() => setFailed(true)}/>;
}
