import {Text} from '@esgi/ui/typography';
import {EmptyBox} from './index.styled';

interface Props {
	content: string
}
export function EmptyList({content}: Props) {
	return (
		<EmptyBox>
			<Text size='small' font='mono' color='neutral40'>{content}</Text>
		</EmptyBox>
	);
}
