import {FormControl, FormElement} from '@esgi/ui/form';
import {Select} from '@esgi/ui/form-controls';
import {FormElementSelectOption} from './types';
import {SelectField} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {BaseComponentProps} from '@esgi/ui';

type Props<T extends string = string> = Pick<BaseComponentProps, 'dataCy'> & {
	placeholder: string;
	items: FormElementSelectOption<T>[];
	controlElement: FormControl<T[]>;
};

export function FormElementSelect<T extends string = string>({items, controlElement, placeholder, dataCy}: Props<T>) {
	return (
		<FormElement control={controlElement}>
			<Select.Root dataCy={dataCy}>
				<SelectField placeholder={placeholder} />
				<Select.Content>
					{items.map(({label, value}) => (
						<Select.Option value={value} key={value}>
							<Text size='medium' bold>
								{label}
							</Text>
						</Select.Option>
					))}
				</Select.Content>
			</Select.Root>
		</FormElement>
	);
}
