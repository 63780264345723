import {HttpClient} from '@esgi/api';
import {BaseService} from '@esgi/core/service';
import {StudentExportModel} from '../models/models';
import DataService from './data-service';
import TableService from './table-service';

export default class ExportService extends BaseService {
	private exportInProcess = false;
	constructor(private tableService: TableService, private dataService: DataService) {
		super();
	}

	public download = () => {
		if(!this.exportInProcess) {
			let students = this.getExportData();
			this.export(students);
		}
	}

	private getExportData = () => {
		return this.tableService.studentsToShow$.value.map(x => {
			let school = this.dataService.data.schools.filter(s => s.schoolID === x.schoolID)[0];
			let teacherName = '';
			let classesName = '';
			let groupsName = '';
			let teachers = school.teachers.filter(t => t.teacherID === x.primaryTeacherID);
			if (teachers.length > 0) {
				let teacher = teachers[0];
				teacherName = teacher.firstName + ' ' + teacher.lastName;
				let classes = teacher.classes.filter(c => x.classIDs.indexOf(c.classID) !== -1);
				if (classes.length > 0) {
					classesName = classes.map(c => c.name).join(', ');

					for (let i = 0; i < classes.length; i++) {
						let c = classes[i];
						let groups = c.groups.filter(g => x.groupIDs.indexOf(g.groupID) !== -1);
						if (groups.length > 0) {
							groupsName += (groupsName !== '' ? ', ' : '') + groups.map(x => x.name).join(', ');
						}
					}
				}
			}

			let specialists = this.dataService.data.specialists.filter(s => x.specialistGroupUserIDs.indexOf(s.userID) !== -1);
			let specialistNames = specialists.map(x => x.name).join(', ');
			let specialistGroupsName = '';
			for (let i = 0; i < specialists.length; i++) {
				let s = specialists[i];
				let specialistGroups = s.groups.filter(g => x.specialistGroupIDs.indexOf(g.groupID) !== -1);
				if (specialistGroups.length > 0) {
					specialistGroupsName += (specialistGroupsName !== '' ? ', ' : '') + specialistGroups.map(x => x.name).join(', ');
				}
			}

			let student = new StudentExportModel();
			student.firstName = x.firstName;
			student.lastName = x.lastName;
			student.school = school.name;
			student.teacher = teacherName;
			student.classes = classesName;
			student.groups = groupsName;
			student.specialists = specialistNames;
			student.specialistGroups = specialistGroupsName;
			student.grade = this.dataService.data.gradeLevels.filter(g => g.gradeLevelID === x.gradeLevelID)[0].name;
			student.studentIDN = x.studentIDN;

			return student;
		});
	}

	private export(students: StudentExportModel[]) {
		//TODO Rewrite to using FileLoader
		let date = new Date();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		let filename = 'Student_Manager_' + year + '-' + month + '-' + day + '.csv';
		
		this.exportInProcess = true;
		HttpClient.default.ESGIApi.file('student-explorer', 'export', filename, {studentsData: JSON.stringify(students)}).subscribe(() => this.exportInProcess = false);
	}
}
