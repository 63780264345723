import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const IntroductionBlockStyled = styled(FlexBox, {
	marginTop: 40,
	gap: 16,

	'@media (max-width: 1024px)': {
		flexWrap: 'wrap',
		justifyContent: 'center',
	},

	variants: {
		isAlert: {
			true: {
				borderTop: '1px solid #EBEBEB',
				marginTop: 20,
				padding: '20px 20px 0 20px',

				'@media (max-width: 1024px)': {
					justifyContent: 'center',
					width: '100%',
					overflow: 'auto',
				},
			},
		},
	},
});

export const Wrapper = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',

	variants: {
		isAlert: {
			true: {
				paddingTop: 0,
				borderTop: 'none',
				alignItems: 'flex-start',

				'@media (max-width: 1024px)': {
					overflow: 'hidden',
				},
			},
		},
	},
});

export const TitleStyled = styled(FlexBox, {
	flexDirection: 'column',
	textAlign: 'center',
	width: '216px',
});

export const TitleAlertStyled = styled(FlexBox, {
	flexDirection: 'flex-start',
	textAlign: 'start',
	padding: '0 20px',
});

export const TextSmall = styled(Text, {
	color: '#525252',
	marginTop: 12,
});

export const FlexBlock = styled(FlexBox, {
	flexDirection: 'row',
	gap: 16,
});