import {StudentType} from '../../../../types';
import {ReportTypeOption} from '../../../../model';
import {useAiAnalysisContext} from '../../../../layout/outlet-context';
import {Layout, VerticalDivider} from './index.styled';
import {getInitials} from '../../utils/get-initials';
import {Avatar} from '@esgi/ui/avatar';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button, Download, Edit, Tooltip, Setting, Close} from '@esgi/ui';

interface Props {
	student: StudentType;
	reportType: ReportTypeOption;
	handleDownload: VoidFunction;
	isReportLoading: boolean;
}

export function Header({student, reportType, handleDownload, isReportLoading}: Props) {
	const {
		canEdit,
		setCanEdit,
		setShowTranslatePanel,
		setShowExitPreviewDialog,
		setShowSelectionDrawer,
		windowWidth,
	} = useAiAnalysisContext();
	return (
		<Layout>
			<GridBox gap='4' align='center' flow='column'>
				{student && (
					<>
						<GridBox flow='column' align='center' gap='4'>
							<Avatar.Root size='sm'>
								<Avatar.Image src={student.avatarURL} />
								<Avatar.Fallback>
									{getInitials({
										firstName: student.firstName,
										lastName: student.lastName,
									})}
								</Avatar.Fallback>
							</Avatar.Root>
							<Text color='neutral56'>
								{student.firstName} {student.lastName}
							</Text>
						</GridBox>
						<VerticalDivider />
					</>
				)}
				{reportType && (
					<Text size='large' bold>
						{reportType.label}
					</Text>
				)}
			</GridBox>
			<GridBox align='center' flow='column' gap='4'>
				{windowWidth < 1024 && (
					<Tooltip delayDuration={400}>
						<Tooltip.Trigger>
							<Button.Icon
								onClick={() => setShowSelectionDrawer(true)}
								dataCy='selection-drawer-button'
							>
								<Setting />
							</Button.Icon>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>Setting</Tooltip.Content>
					</Tooltip>
				)}
				<Tooltip delayDuration={400}>
					<Tooltip.Trigger>
						<Button.Icon
							onClick={handleDownload}
							disabled={isReportLoading}
							dataCy='download-button'
						>
							<Download />
						</Button.Icon>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>Download report</Tooltip.Content>
				</Tooltip>
				<Tooltip delayDuration={400}>
					<Tooltip.Trigger>
						<Button.Icon
							css={{color: canEdit ? '$primary' : '$neutral40'}}
							onClick={() => {
								if (canEdit) {
									setShowTranslatePanel(false);
								}
								setCanEdit(!canEdit);
							}}
							disabled={isReportLoading}
							dataCy='edit-report-button'
						>
							<Edit />
						</Button.Icon>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						Edit report text
					</Tooltip.Content>
				</Tooltip>
				{windowWidth < 1024 && (
					<Tooltip delayDuration={400}>
						<Tooltip.Trigger>
							<Button.Icon
								onClick={() => setShowExitPreviewDialog(true)}
								dataCy='close-preview-button'
							>
								<Close />
							</Button.Icon>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>Close</Tooltip.Content>
					</Tooltip>
				)}
			</GridBox>
		</Layout>
	);
}
