import {useService} from '@esgi/core/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useCallback, useEffect, useState} from 'react';
import {CenterPanel} from 'shared/page/layout/center-panel';
import Layout from 'shared/page/layout/layout';
import AssignmentCenterList from './components/asisgnments-list';
import {AssignmentCard} from './components/assignment-card';
import {CreateAssignment} from './components/create-assignment';
import {IntroductionDialog} from './components/introduction-dialog';
import {AssignmentCenterMenu} from './components/kit/assignement-center-menu';
import {Route, useNavigationRoutes} from './hooks/use-navigation';
import {AssignmentService} from './services/assignment-service';
import styles from './styles.module.less';
import {StudentCredentials} from './components/student-credentials';
import {AssignmentModel} from './components/assignment-card/types/types';
import {useEventEffect} from '@esgillc/events';
import {AssignmentDeletedEvent} from 'pages/assignment-center/components/assignment-card/events';
import {UserType, useUser} from '@esgi/core/authentication';

export default function Assignment() {
	const currentUser = useUser();

	const isTeacher = currentUser?.userType === UserType.T;
	const isSpecialist = currentUser?.userType === UserType.ISD || currentUser?.userType === UserType.ISS;

	const currentUserTypeError = '<Assignment />: Current user is not teacher or specialist';

	const service = useService(AssignmentService);
	const assignments = useBehaviorSubject(service.assignments);
	const createAssignmentExtractedTeacherData = useBehaviorSubject(service.createAssignmentExtractedTeacherData);
	const createAssignmentExtractedSpecialistData = useBehaviorSubject(service.createAssignmentExtractedSpecialistData);

	const [introducing, setIntroducing] = useState(false);

	const {setRoute, route, back, data} = useNavigationRoutes();

	const [initialized, setInitialized] = useState(false);

	useEventEffect(AssignmentDeletedEvent, () => {
		setRoute(Route.List, undefined, {replace: true});
	});

	useEffect(() => {
		service.init().subscribe(r => {
			setInitialized(true);
			if (r.assignments.length) {
				setRoute(route || Route.List, data, {replace: true});
			} else {
				if (route !== Route.CreateWizard) {
					setIntroducing(true);
				}
			}
		});
	}, []);

	const onEdit = useCallback((assignmentId: AssignmentModel['id']) => {
		setRoute(Route.EditWizard, {id: String(assignmentId)});
	}, [setRoute]);

	const onAssignmentCreated = useCallback((id?: number) => {
		service.init().subscribe(() => {
			if (id) {
				return setRoute(Route.View, {id: String(id)});
			}
			setRoute(Route.List);
		});
	}, [service, setRoute]);

	const closeIntroducing = useCallback(() => {
		if (!assignments.length) {
			setRoute(Route.CreateWizard);
		}
		setIntroducing(false);
	}, [assignments.length]);

	const onCreateAssignmentClick = useCallback(() => {
		if (isTeacher) {
			service.extractTeacherData(null);
		}

		if (isSpecialist) {
			service.extractSpecialistData(null);
		}

		setRoute(Route.CreateWizard);
	}, [setRoute]);

	const onCancel = useCallback(() => {
		if (route === Route.CreateWizard) {
			setRoute(Route.List);
		} else {
			back();
		}
	}, [route, back]);

	const goToAssignmentList = useCallback(() => {
		setRoute(Route.List, undefined, {replace: true});
	}, [setRoute]);

	function screen() {
		if (route === Route.CreateWizard) {
			if (isTeacher) {
				return (
					<CreateAssignment
						onGoBack={back}
						onCancel={onCancel}
						onSaved={onAssignmentCreated}
						userType={currentUser.userType}
						extractedData={createAssignmentExtractedTeacherData}
						extractData={service.extractTeacherData.bind(service)}
						currentUser={currentUser}
					/>
				);
			}

			if (isSpecialist) {
				return (
					<CreateAssignment
						onGoBack={back}
						onCancel={onCancel}
						onSaved={onAssignmentCreated}
						userType={currentUser.userType}
						extractedData={createAssignmentExtractedSpecialistData}
						extractData={service.extractSpecialistData.bind(service)}
						currentUser={currentUser}
					/>
				);
			}

			if(currentUser) {
				throw new Error(currentUserTypeError);
			}
		}

		if (route === Route.EditWizard) {
			if(!currentUser) {
				return null;
			}

			if (!isTeacher && !isSpecialist) {
				throw new Error(currentUserTypeError);
			}

			if (data?.id) {
				return (
					<CreateAssignment
						onGoBack={back}
						onCancel={onCancel}
						onSaved={() => service.init().subscribe(() => setRoute(Route.View, data))}
						assignmentID={Number(data.id)}
						userType={currentUser.userType}
						goToAssignmentList={goToAssignmentList}
						currentUser={currentUser}
					/>
				);
			}

			return null;
		}

		switch (route) {
			case Route.View:
				return data?.id && currentUser && <AssignmentCard onStatusChanged={() => service.init().subscribe()} assignmentID={Number(data.id)}
				                       onGoBack={() => setRoute(Route.List)} onEdit={onEdit} goToAssignmentList={goToAssignmentList}
															 currentUser={currentUser} />;
			case Route.List:
			case Route.ListInProgress:
			case Route.ListNotStarted:
			case Route.ListCompleted:
			case Route.ListDraft:
				return (
					<AssignmentCenterList
						assignments={assignments}
						route={route}
						onCreateClick={onCreateAssignmentClick}
						onAssignmentClick={(id) => setRoute(Route.View, {id: String(id)})}
					/>
				);
			case Route.StudentCredentials:
				return (
					<StudentCredentials
						onGoBack={back}
						onCreateClick={onCreateAssignmentClick}
						currentUser={currentUser}
					/>
				);
		}
	}

	const isAssignmentList = [
		Route.List,
		Route.ListInProgress,
		Route.ListNotStarted,
		Route.ListCompleted,
		Route.ListDraft,
	].includes(route as Route);

	return (
		<Layout loaded={[initialized]} layoutClassName={join(isAssignmentList && styles.assignmentsListLayout)}>
			<AssignmentCenterMenu
				assignments={assignments}
				route={route}
				onItemClick={setRoute}
				onHelpClick={() => setIntroducing(true)}
			/>
			<CenterPanel className={styles.center}>
				<div className={styles.content}>{screen()}</div>
				{initialized && <ServiceLoader trackingService={service} fullscreen whiteBackground/>}
				{introducing && <IntroductionDialog onClose={closeIntroducing}/>}
			</CenterPanel>
		</Layout>
	);
}
