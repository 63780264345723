import {ActivationCodeFormTemplate} from '../components/activation-code/template';

export function ActivationCodeTemplate() {
	return <div data-bind='afterRender: true'>
		<ko data-bind='with: activationCodeForm'>
			<ActivationCodeFormTemplate/>
		</ko>
		<div className='clearfix' style={{marginTop: '25px'}}>
			<ko data-bind='if: backEnabled'>
				<ko data-bind='render: cancelButton'>
				</ko>
			</ko>
			<ko data-bind='render: nextButton'>
			</ko>
		</div>
	</div>;
}
