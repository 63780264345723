import './image-gallery.less';
import React from 'react';
import {Api} from './api';
import {Home, State as HomeState} from './home';
import {Main, State as MainState} from './main';
import {FolderModel, Library, Page} from './models';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';

export class State {
	constructor(folderID?: number, library?: Library) {
		if(library) {
			this.library = library;
			this.currentPage = Page.Main;
			this.main.currentFolder = {id: folderID} as FolderModel;
		}
	}

	home: HomeState = new HomeState();
	main: MainState = new MainState();
	currentPage: Page = Page.Home;
	library: Library;
	keywords: string = '';
}

export class Props {
	selectMode: boolean;
	onClosed: () => void;
	onImageSelected: (id: number, height: number, width: number, url: string) => void;
}

export default class ImageGallery extends React.Component<Props, State> {
	private isImageSelected: boolean = false;
	private isImageInfoLoaded: boolean = false;
	private windowProvider: any;
	constructor(props) {
		super(props);
		this.windowProvider = window as any;
		//TODO rewrite to session storage?
		this.state = new State(this.windowProvider.imageGalleryFolderID, this.windowProvider.imageGalleryFolderSource);
	}

	render(): JSX.Element | false | null {
		return <div className={this.state.currentPage === Page.Home ? 'home-container' : 'main-container'}>
			<a className='close-btn' onClick={() => this.props.onClosed()}><i className='fa fa-times fa-lg'/></a>
			{this.state.currentPage === Page.Home ? this.homePage() : this.mainPage()}
			<SnackbarLayout/>
		</div>;
	}

	private homePage(): JSX.Element {
		return <Home onChange={(ch, cb) => this.setState({home: ch}, cb)} state={this.state.home}
		                       search={(e) => this.searchClicked(e)}
		                       cardClicked={(e) => this.homeLibClicked(e)}/>;
	}

	private mainPage(): JSX.Element {
		return <Main state={this.state.main} onChange={(ch, cb) => this.setState({main: ch}, cb)}
		                       selectMode={this.props.selectMode}
		                       library={this.state.library}
		                       imageClicked={(id) => this.onImageSelected(id)}
		                       returnToHome={() => this.returnToHome()}/>;
	}

	private searchClicked(e: string) {
		const main = this.state.main;
		main.header.search.keyword = e;
		this.setState({currentPage: Page.Main, keywords: e, main: main, library: Library.Search});
	}

	private returnToHome() {
		//TODO szus315 rewrite this to store in the sessionStorage
		this.windowProvider.imageGalleryFolderID = null;
		this.windowProvider.imageGalleryFolderSource = null;
		this.setState({currentPage: Page.Home});
	}

	private homeLibClicked(e: Library) {
		//TODO szus315 rewrite this to store in the sessionStorage
		this.windowProvider.imageGalleryFolderSource = e;
		const mainState = this.state.main;
		this.setState({main: mainState, currentPage: Page.Main, library: e});
	}

	private onImageSelected(id: number) {
		if(!this.isImageSelected) {
			this.isImageSelected = true;
			Api.GetImage(id).subscribe(r => {
				if(!this.isImageInfoLoaded) {
					this.isImageInfoLoaded = true;
					this.props.onImageSelected(id, r.height, r.width, r.url);
				}
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (this.state.main.currentFolder && prevState.main.currentFolder && prevState.main.currentFolder.id !== this.state.main.currentFolder.id) {
			this.windowProvider.imageGalleryFolderID = this.state.main.currentFolder.id;
		}
	}

	public events = {
		onClosed: (callback) => {
			$(this).on('close', callback);
		},
		onImageSelected: (callback) => {
			$(this).on('imageSelected', callback);
		},
	};
}
