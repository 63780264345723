import {Checkbox} from '../../../../../../../../../kit';
import {CheckboxValue} from '../../types';

type Props = {
	isDetailedViewChecked: boolean;
	toggleDetailedViewCheckbox: VoidFunction;
	isAllSessionsChecked: boolean;
	toggleAllSessionsCheckbox: VoidFunction;
};

export function DiagramCheckboxes({
	isDetailedViewChecked,
	isAllSessionsChecked,
	toggleAllSessionsCheckbox,
	toggleDetailedViewCheckbox,
}: Props) {
	return (
		<>
			<Checkbox
				value={CheckboxValue.DetailedView}
				label='Show Detailed View'
				checked={isDetailedViewChecked}
				onCheckedChange={toggleDetailedViewCheckbox}
				selected={isDetailedViewChecked}
			/>
			<Checkbox
				value={CheckboxValue.AllSessions}
				label='Show All Sessions'
				checked={isAllSessionsChecked}
				onCheckedChange={toggleAllSessionsCheckbox}
				selected={isAllSessionsChecked}
			/>
		</>
	);
}
