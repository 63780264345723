import {OptionItem} from '@esgi/main/features/standards-drawer';
import {
	FilterScope,
	TabItem,
	TestsSortBy,
	TestType as TestTypeModel,
} from './service/types';
import {TestType} from '@esgi/main/libs/core';

export const testLabelByTestType: Record<TestType, string> = {
	[TestType.YN]: 'Yes/No',
	[TestType.Score]: 'Single Score',
	[TestType.Rubric]: 'Rubric',
	[TestType.None]: '',
};

export const sortByOptions: OptionItem[] = [
	{
		label: 'Create Date',
		value: TestsSortBy.CreateDate,
	},
	{
		label: 'Test Name',
		value: TestsSortBy.TestName,
	},
	{
		label: 'Content Area',
		value: TestsSortBy.ContentArea,
	},
	{
		label: 'Standard',
		value: TestsSortBy.StateStandard,
	},
	{
		label: 'Author',
		value: TestsSortBy.TestOwner,
	},
	{
		label: '# of Questions',
		value: TestsSortBy.NumberOfQuestions,
	},
];

export const tabItems: TabItem<FilterScope>[] = [
	{
		value: FilterScope.AllTests,
		label: 'All Tests',
		labelDataCy: 'test-type-tab-item-all',
		tooltipText: 'All pre-loaded tests',
	},
	{
		value: FilterScope.DistrictTests,
		label: 'District Tests',
		labelDataCy: 'test-type-tab-item-district',
		tooltipText: 'Published and shared district tests',
	},
	{
		value: FilterScope.MyTests,
		label: 'My Tests',
		labelDataCy: 'test-type-tab-item-my-tests',
		tooltipText: 'Tests created by you',
	},
	{
		value: FilterScope.DraftTests,
		label: 'My Drafts',
		labelDataCy: 'test-type-tab-item-drafts',
		tooltipText: 'Your draft tests',
	},
	{
		value: FilterScope.Favorites,
		label: 'Favorites',
		labelDataCy: 'test-type-tab-item-favorites',
		tooltipText: 'Tests you have favorited',
	},
];

export const NoTestsMessage: Record<FilterScope, string> = {
	[FilterScope.AllTests]: 'Nothing to display',
	[FilterScope.MyTests]: 'Nothing to display. Click the + icon above to create a test of your own.',
	[FilterScope.DistrictTests]: 'You are not linked to your district. Contact customer support to get linked to your district to access any shared tests.',
	[FilterScope.DraftTests]: 'Nothing to display',
	[FilterScope.Favorites]: 'Nothing to display. Click the star icon associated with a test to add to Favorites.',
};

export const testTypes: OptionItem[] = [
	{value: TestTypeModel.YN, label: 'Yes/No'},
	{value: TestTypeModel.Score, label: 'Single Score'},
	{value: TestTypeModel.Rubric, label: 'Rubric'},
];

export const questionLabelByTestType: Record<TestTypeModel, string> = {
	[TestTypeModel.YN]: 'Questions',
	[TestTypeModel.Rubric]: 'Criteria',
	[TestTypeModel.Score]: 'Max score',
	[TestTypeModel.None]: 'Questions',
};