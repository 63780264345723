import {SkeletonRow} from '../../index.styled';
import React from 'react';

const SkeletonRowArray = new Array(30).fill('');

export function Skeleton() {
	return (
		<>
			{SkeletonRowArray.map((_, index) => <SkeletonRow key={index}/>)}
		</>
	);
}