import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gridTemplateColumns: '1fr 1fr auto',
	alignItems: 'center',
	gap: '$3',
});

export const ViewCredentialsButton = styled(Button, {
	width: 40,
	height: 40,
	minWidth: 'unset',
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 8,
	paddingRight: 8,
	alignItems: 'center',
	justifyContent: 'center',

	'& svg:last-child': {
		margin: 'unset',
	},

	variants: {
		isShownCredentials: {
			true: {
				color: '$primary',
				borderColor: '$primaryMuted',
				background: 'linear-gradient(272deg, $primaryBackground 48.17%, $primary98 100%)',
			},
		},

		withoutBoxShadow: {
			true: {
				boxShadow: 'unset',

				'&:hover': {
					boxShadow: 'unset',
				},
			},
		},
	},

	defaultVariants: {
		withoutBoxShadow: true,
	},
});
