import {useEventEffect} from '@esgillc/events';
import {TestSubsetStartedEvent} from 'modules/assessments/general/session-details/test-subset/events';
import {TestLauncher, RubricSessionDetails} from 'modules/assessments';
import {HierarchyMode, HierarchySnapshot} from 'modules/hierarchy/core/models';
import {Fragment, Suspense, useCallback, useState} from 'react';
import {TestType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {FlashCardsWrapper} from 'shared/modules/reports/flash-cards/settings-form/wrapper';
import {ChangesCollector, notifyHierarchyChanges} from 'shared/modules/reports/utils/changes-collector';
import SelfAssessModal from 'shared/modules/testing/selfassess-modal/selfassess-modal';
import {mapToEnum} from 'shared/utils';
import {SubjectModel} from '../../../../../services/subjects-service/models';
import {PieChartModel} from '../../../models';
import {getStudentID} from '../../utils';
import {FooterActions} from './components/actions';
import {EndedSchoolYearAlert} from './components/ended-school-year-alert';

const SelfAssessment = lazyComponent(() => import('modules/assessments/testing/self-assessment/root'));
const TestHistoryModal = lazyComponent(() => import('modules/reports/test-history'));
const TestSessionDetailsNew = lazyComponent(() => import('modules/forms/test-session-details-form'));
const TestSessionDetailsAllStudents = lazyComponent(() => import('modules/forms/test-session-details-all-students-form'));

interface Props {
	subject: SubjectModel;
	pieChart: PieChartModel
	studentsTested: number;
	hierarchy: HierarchySnapshot;
	disabled: boolean;
	testingFinished: () => void;
	canTest: boolean;
	selfAssessEnabled: boolean;
}

export function CardFooter(props: Props) {
	const {testID} = props.pieChart;
	const studentID = getStudentID(props.hierarchy);
	const testType = mapToEnum(props.pieChart.testType, TestType);
	const classID = props.hierarchy.mode === HierarchyMode.Classic ? props.hierarchy.classic.classID : 0;
	const specialistGroupID = props.hierarchy.mode === HierarchyMode.Specialist ? props.hierarchy.specialist.groupID : 0;

	const [testSessionDetailOpened, setTestSessionDetailOpened] = useState(false);
	const [testLauncherOpened, setTestLauncherOpened] = useState(false);
	const [flashCardsOpened, setFlashCardsOpened] = useState(false);
	const [subsetQuestionIDs, setSubsetQuestionIDs] = useState<number[]>();
	const [selfAssessModalOpened, setSelfAssessModalOpened] = useState(false);
	const [selfAssessTestingOpened, setSelfAssessTestingOpened] = useState(false);
	const [showEndedSchoolYearAlert, setShowEndedSchoolYearAlert] = useState(false);
	const [showTestHistory, setShowTestHistory] = useState(false);

	const [changesCollector, setChangesCollector] = useState<ChangesCollector>();

	useEventEffect(TestSubsetStartedEvent, (args) => {
		if (args.testID === props.pieChart.testID) {
			setSubsetQuestionIDs(args.questionsIDs);
			setTestLauncherOpened(true);
		}
	}, [props.pieChart.testID]);

	function testSessionDetails() {
		if (!testSessionDetailOpened) {
			return;
		}
		if (!studentID && testType !== TestType.Rubric) {
			return (
				<Suspense fallback={<Fragment/>}>
					<TestSessionDetailsAllStudents
						testId={testID}
						studentId={studentID}
						classId={classID}
						specialistGroupId={specialistGroupID}
						subject={props.subject}
						hierarchy={props.hierarchy}
						onClose={() => setTestSessionDetailOpened(false)}
					/>
				</Suspense>
			);
		}
		if (studentID && testType === TestType.Rubric) {
			return (
				<Suspense fallback={<Fragment/>}>
					<RubricSessionDetails
						classID={props.hierarchy.classic.classID}
						rubricID={testID}
						studentID={studentID}
						hierarchy={props.hierarchy}
						subject={props.subject}
						onClose={() => setTestSessionDetailOpened(false)}
					/>
				</Suspense>
			);
		}
		return (
			<Suspense fallback={<Fragment/>}>
				<TestSessionDetailsNew
					testID={testID}
					studentID={studentID}
					classID={classID}
					specialistGroupID={specialistGroupID}
					hierarchy={props.hierarchy}
					subject={props.subject}
					onClose={() => setTestSessionDetailOpened(false)}
				/>
			</Suspense>
		);
	}

	const showTestRunner = useCallback(() => {
		if (!props.canTest) {
			setShowEndedSchoolYearAlert(true);
		} else {
			setTestLauncherOpened(true);
		}
	}, [props.canTest]);

	const runTest = useCallback(() => {
		if (props.selfAssessEnabled && props.pieChart.selfAssessmentTestAllowed) {
			setSelfAssessModalOpened(true);
			return;
		}
		showTestRunner();
	}, [props.selfAssessEnabled, props.pieChart.selfAssessmentTestAllowed, showTestRunner]);

	const selfAssessClicked = useCallback(() => {
		if (!props.canTest) {
			setShowEndedSchoolYearAlert(true);
		} else {
			setSelfAssessTestingOpened(true);
			setSelfAssessModalOpened(false);
		}
	}, [props.canTest]);

	return <>
		<FooterActions
			testID={props.pieChart.testID}
			studentsTested={props.studentsTested}
			hierarchy={props.hierarchy}
			disabled={props.disabled}
			runTestClicked={runTest}
			testType={testType}
			openTestHistoryClicked={() => setShowTestHistory(true)}
			openTestSessionDetailsClicked={() => setTestSessionDetailOpened(true)}
		/>
		{showEndedSchoolYearAlert &&
			<EndedSchoolYearAlert onClose={() => setShowEndedSchoolYearAlert(false)}/>
		}
		{testLauncherOpened &&
			<TestLauncher
				testID={testID}
				classID={classID}
				testType={testType}
				studentID={studentID}
				questionIds={subsetQuestionIDs}
				hierarchy={props.hierarchy}
				subject={props.subject}
				onClosed={() => {
					setSubsetQuestionIDs(null);
					setTestLauncherOpened(false);
				}}
				onFinished={props.testingFinished}
				onFlashCardsClicked={() => {
					setChangesCollector(new ChangesCollector({
						id: props.subject.id,
						type: props.subject.type,
					}, props.hierarchy));
					setFlashCardsOpened(true);
				}}
				onTestHistoryClicked={() => setShowTestHistory(true)}
				onTestSessionDetailsClicked={() => setTestSessionDetailOpened(true)}
			/>
		}
		{flashCardsOpened &&
			<FlashCardsWrapper
				hierarchySnapshot={props.hierarchy}
				changesCollector={changesCollector}
				parentLetterOnClosed={(emit, changes) => emit && notifyHierarchyChanges(changes)}
				onCloseClicked={() => {
					notifyHierarchyChanges(changesCollector);
					setChangesCollector(null);
					setFlashCardsOpened(false);
				}}
				subjectID={props.subject.id}
				subjectType={props.subject.type}
			/>
		}
		{showTestHistory &&
			<Suspense fallback={<Fragment/>}>
				<TestHistoryModal
					hierarchy={props.hierarchy}
					subjectID={props.subject.id}
					subjectType={props.subject.type}
					testId={testID}
					testType={testType}
					onClose={() => setShowTestHistory(false)}
				/>
			</Suspense>
		}
		{selfAssessTestingOpened &&
			<Suspense fallback={<Fragment/>}>
				<SelfAssessment
					studentID={studentID}
					testSessionEnded={props.testingFinished}
					closed={() => setSelfAssessTestingOpened(false)}
					subject={props.subject}
					testID={testID}
				/>
			</Suspense>
		}
		{selfAssessModalOpened &&
			<SelfAssessModal
				showTestRunner={() => {
					setSelfAssessModalOpened(false);
					showTestRunner();
				}}
				selfAssessClicked={() => selfAssessClicked()}
				close={() => setSelfAssessModalOpened(false)}
			/>
		}
		{testSessionDetails()}
	</>;
}
