import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Wrapper = styled(GridBox, {
	gap: '$3',
	gridTemplateRows: 'auto 1fr',
	overflow: 'hidden',
});

export const TitleWrapper = styled(GridBox, {
	gap: '$3',
	paddingLeft: 12,
	paddingRight: 12,
});

export const Title = styled(Text, {
	textAlign: 'center',
});

export const Divider = styled(Box, {
	width: '100%',
	height: 1,
	backgroundColor: '$mild',
});
