import {CSSProperties} from 'react';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import styles from './styles.module.less';

type ChartProps = {
	chart: object;
	isFluid?: boolean;
	containerStyle?: CSSProperties;
};

export function Chart({chart, containerStyle, isFluid}: ChartProps) {
	return (
		<div className={styles.container}>
			<HighchartsReact options={chart} isFluid={isFluid} containerStyle={containerStyle} />
		</div>
	);
}
