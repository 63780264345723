import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from '../card.module.less';

export const SelfAssessIcon = (): JSX.Element => (
	<div className={styles.selfAssessIcon}>
		<OnHoverTooltip message='Student self-assess'>
			<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='15' cy='15' r='12' fill='white'/>
				<path
					d='M7.51267 22.5C7.74204 17.9883 11.0561 14.5 15 14.5C18.9439 14.5 22.258 17.9883 22.4873 22.5H7.51267Z'
					fill='#BA5ACB' stroke='white'/>
				<circle cx='15' cy='11' r='5' fill='#FFA53D' stroke='white' strokeWidth='2'/>
				<circle cx='14.9615' cy='14.9615' r='13' stroke='#059BE0' strokeWidth='2'/>
			</svg>
		</OnHoverTooltip>
	</div>
);
