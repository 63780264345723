import './layout.less';
import React, {createRef, ReactNode} from 'react';
import {Header} from '../header/header';
import {LeftMenu} from '../left-menu/left-menu';
import Preloader from '../preloader/preloader';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {LayoutContext} from '../layout-context';
import {join} from '@esgillc/ui-kit/utils';
import {VersionToggleBanner} from 'shared/page/version-toggle-banner';
import {userStorage, UserType} from '../../../../../../libs/core/src/authentication';

class State {
	preloaderFinished: boolean = false;
	versionBannerWasShown: boolean = JSON.parse(window.localStorage.getItem('versionBannerWasShown'));
}

class Props {
	loaded: boolean[];
	preloaderLifted?: () => any;
	children?: ReactNode;
	redesign?: boolean;
	layoutClassName?: string;
}

export default class Layout extends React.Component<Props, State> {
	public state = new State();
	layoutRef = createRef<HTMLDivElement>();
	mainRef = createRef<HTMLDivElement>();
	public currentUser = userStorage.get();

	private hamburgerRef = createRef<HTMLButtonElement>();

	render() {
		return <>
			{!this.state.preloaderFinished &&
			<Preloader loaded={this.props.loaded} lifted={() => this.preloaderLifted()}/>}
			{!this.state.versionBannerWasShown && !this.props.redesign && this.currentUser.userType === UserType.T && (
				<VersionToggleBanner onClose={() => this.closeBanner()}/>
			)}
			{!this.props.redesign && <Header hamburgerRef={this.hamburgerRef}/>}
			<div ref={this.layoutRef} className={join('layout', this.props.redesign && 'redesign-embedded', this.props.layoutClassName)}>
				<div ref={this.mainRef} className='main'>
					{!this.props.redesign && <LeftMenu triggerRef={this.hamburgerRef}/>}
					<LayoutContext.Provider value={{layoutRef: this.layoutRef, mainRef: this.mainRef}}>
						{this.props.children}
					</LayoutContext.Provider>

				</div>
			</div>
			<SnackbarLayout/>
		</>;
	}

	preloaderLifted() {
		this.setState({preloaderFinished: true}, () => this.props.preloaderLifted && this.props.preloaderLifted());
	}

	closeBanner() {
		this.setState(prev => ({...prev, versionBannerWasShown: true}), () => window.localStorage.setItem('versionBannerWasShown', 'true'));
	}
}
