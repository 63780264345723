import {Avatar} from '@esgi/ui/avatar';
import {avatarRootCss} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Student} from '@esgi/main/libs/store';
import {GridBox} from '@esgi/ui/layout';
import {getInitials} from '../../../kit';
import {HeaderTitle} from './components/header-title';
import {Alert} from '@esgi/ui/alert';

type Props = {
	student: Student;
	onCloseAlert: VoidFunction;
};

export function AlertHeaderDeletedSession({student, onCloseAlert}: Props) {
	return (
		<Alert.Header withBacklight={false} onCloseIconClick={onCloseAlert}>
			<GridBox flow='column' gap='3' align='center' justify='start'>
				<Avatar.Root size='xs' css={avatarRootCss}>
					<Avatar.Image src={student.photoUrl} />
					<Avatar.Fallback>
						<Text size='small' color='neutral16'>
							{getInitials({
								firstName: student.firstName,
								lastName: student.lastName,
							})}
						</Text>
					</Avatar.Fallback>
				</Avatar.Root>

				<GridBox flow='column' gap='2' align='center' justify='start'>
					<HeaderTitle isEditMode={false} firstName={student.firstName} lastName={student.lastName} />
				</GridBox>
			</GridBox>
		</Alert.Header>
	);
}
