import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Drawer} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';

export const Wrapper = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	overflow: 'hidden',
});

export const HeaderContent = styled(GridBox, {
	gridTemplateColumns: 'repeat(2, 272px)',
	borderBottom: '1px solid $mild',
	variants: {
		isSmallScreen: {
			true: {
				gridTemplateColumns: '272px',
			},
		},
	},
});

export const StandardsWrapper = styled(Drawer.Body, {
	gap: 0,
	gridTemplateRows: 'auto 1fr',
	alignContent: 'flex-start',
	overflow: 'hidden',
	padding: 20,
});

export const DrawerHeaderWrapper = styled(GridBox, {
	padding: '0px 14px',
});

export const Column = styled(GridBox, {
	alignContent: 'start',
	borderRight: '1px solid $mild',
	padding: '18px 20px',
	overflow: 'hidden',
});

export const Title = styled(Text, {
	textAlign: 'center',
	padding: '12px 0',
	margin: '0 16px',
	borderBottom: '1px solid $mild',
});

export const ListWrapper = styled(GridBox, {
	gap: '$4',
	gridTemplateRows: 'auto 1fr',
	overflow: 'hidden',
	variants: {
		withTopPadding: {
			true: {
				paddingTop: '14px',
			},
		},
	},
});
