import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useCallback} from 'react';

type Props = {
	onCloseAlert: VoidFunction;
	onDeleteClass: VoidFunction;
	className: string;
};

export function DeleteClassConfirmation({onCloseAlert, className, onDeleteClass}: Props) {
	const alertRef = Alert.useRef();

	const closeAlert = Alert.useClose(alertRef, onCloseAlert);

	const deleteClassAndCloseAlert = useCallback(() => {
		closeAlert();
		onDeleteClass();
	}, [closeAlert, onDeleteClass]);

	return (
		<Alert
			colorConfig={alertColorsConfig.negative}
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					maxWidth: 510,
				},
			}}
		>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Delete Class
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox gap='3'>
					<Text size='medium' color='neutral40'>
						Are you sure you want to delete the class {className}?
					</Text>
					<Text size='medium' color='neutral40'>
						Note: This action will only remove the class and its groups. Enrolled students and their data, including
						test results, will not be deleted and can be reassigned to other classes. This action cannot be undone.
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={deleteClassAndCloseAlert}>
						<Text size='medium' bold color='base'>
							Confirm
						</Text>
					</Button>
					<Button color='secondary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
