import {GridBox} from '@esgi/ui/layout';
import {Input, EditableSessionDateTimeInfo, EditableSessionDateTimeRef, ValidateOnMinDate} from '../../kit';
import {RefObject, useMemo} from 'react';
import {TestSession} from '../types';
import {isNull} from 'underscore';
import {useUser} from '@esgi/core/authentication';

type Props = {
	sessionInfo: TestSession;
	setTouched: (value: boolean) => void;
	setisValid: (value: boolean) => void;
	editableSessionTimeRef: RefObject<EditableSessionDateTimeRef | undefined>;
	validateOnMinDate?: ValidateOnMinDate;
};

function Component({sessionInfo, setTouched, setisValid, editableSessionTimeRef, validateOnMinDate}: Props) {
	const currentUser = useUser();

	const durationValue = useMemo(() => {
		const {duration} = sessionInfo;

		if (isNull(duration) || duration === 0) {
			return null;
		}

		const time = duration / 1000;

		const minutes = Math.floor(time / 60);
		const seconds = Math.round(time % 60);

		const secondsString = String(seconds).padStart(2, '0');

		return `${String(minutes).padStart(2, '0')}:${secondsString}`;
	}, [sessionInfo]);

	const withDuration = !isNull(durationValue);

	return (
		<GridBox flow='column' gap='3' columns={withDuration ? '3' : '2'} align='center'>
			<EditableSessionDateTimeInfo
				initialTestDate={sessionInfo.testDate}
				setTouched={setTouched}
				setisValid={setisValid}
				editableSessionTimeRef={editableSessionTimeRef}
				validateOnMinDate={validateOnMinDate}
				timeZone={currentUser?.timeZone}
			/>

			{withDuration && <Input placeholder='Duration' value={durationValue} disabled dataCy='duration-input' />}
		</GridBox>
	);
}

export const EditableSessionTimeInfo = Object.assign(Component, {
	useRef: EditableSessionDateTimeInfo.useRef,
});
