import React, {Suspense} from 'react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';
import {lazyComponent} from '@esgi/core/react';

const TestExplorerPage = lazyComponent(() => import('pages/test-explorer/root'));

export default function TestExplorer(props: {excludeLayout?: boolean}) {
	return <RouteWrapper className='te' title={'Test Explorer'} authorize>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<TestExplorerPage excludeLayout={props.excludeLayout}/>
		</Suspense>
	</RouteWrapper>;
}
