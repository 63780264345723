export const fileAdditionalInfo = [
	{
		sectionTitle: 'Required Fields',
		items: ['Student’s First Name', 'Student’s Last Name', 'Grade Level', 'Language', 'Gender'],
	},
	{
		sectionTitle: 'Optional Fields',
		items: [
			'Student ID',
			'Gifted/Talanted',
			'Birth Date',
			'SPED',
			'Race & Ethnicity',
			'ELL/ESL',
			'504',
			'RTI',
			'Lunch Status',
		],
	},
	{
		sectionTitle: 'Accepted file formats',
		items: ['CSV', 'XLS'],
	},
];
