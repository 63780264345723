import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ClassTotalsService} from '../../../../service';
import {MarkingPeriod, NotTested, Report, Table, TotalReportType} from '../../../../service/types';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {LeftPanel} from './letf-panel';
import {RightPanel} from './right-panel';
import {SkeletonShape} from '@esgi/main/kits/common';
import {Skeletonable} from '@esgi/ui';
import {useSortableTable} from './use-sortable-table';
import React, {useCallback, useRef} from 'react';
import {DisplayResults} from '@esgi/main/kits/reports';
import {TableWrapper, Wrapper} from './index.styled';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {isNull} from 'underscore';
import {mapToEnum} from 'shared/utils';

type Props = Skeletonable & {
	service: ClassTotalsService;
}

export function StudentsTable(props: Props) {
	const {service, skeleton} = props;
	const report = useBehaviorSubject<Report | null>(service.result$);
	const markingPeriod = useBehaviorSubject<MarkingPeriod | null>(service.markingPeriod$);
	const displayType = useBehaviorSubject<DisplayResults | null>(service.displayResults$);
	const displayNotTestedType = useBehaviorSubject<NotTested | null>(service.notTested$);
	const testResultsCorrectVerbiage = useBehaviorSubject<string>(service.testResultsCorrectVerbiage$);

	const {
		onSortableCellClick,
		sortableRows,
		showOutOf,
		cellRender,
		isCellShown,
		sortableOptions,
		previousSortedColumnIndex,
	} = useSortableTable(service, report, markingPeriod, displayType, displayNotTestedType);

	const leftScrollRef = useRef<OverlayScrollbarsComponentRef>(null);
	const rightScrollRef = useRef<OverlayScrollbarsComponentRef>(null);
	const isSyncing = useRef(false);

	const syncScroll = (sourceRef: React.MutableRefObject<OverlayScrollbarsComponentRef>, targetRef: React.MutableRefObject<OverlayScrollbarsComponentRef>) => {
		if (isSyncing.current){
			return;
		}
		isSyncing.current = true;

		const sourceInstance = sourceRef.current.osInstance();
		const targetInstance = targetRef.current.osInstance();

		if (sourceInstance && targetInstance) {
			const {viewport: {scrollTop}} = sourceInstance.elements();
			const targetElements = targetInstance.elements();

			requestAnimationFrame(() => {
				targetElements.viewport.scrollTo({top: scrollTop});
				isSyncing.current = false;
			});
		} else {
			isSyncing.current = false;
		}
	};

	function hasTestedTest(table: Table){
		const skip = table.countInfoColumn;
		const lastResultColumn = skip + table.countResultColumn;
		for (const row of table.rows){
			for (let i = skip; i < lastResultColumn; i++){
				const cell = row.cells[i];
				if (cell?.value !== 'NT'){
					return true;
				}
			}
		}
		return false;
	}

	const renderSelectedEntity = useCallback(() => {
		if (skeleton) {
			return <SkeletonShape width='100%' height={430}/>;
		}

		const type = mapToEnum(report?.type, TotalReportType);
		const unitLabel = type === TotalReportType.Group ? 'group' : 'class';
		if (isNull(report) || !report.table.rows.length){
			return <Box>
				<Text size='medium' color='negativeVivid'>There are no students in this {unitLabel}.</Text>
			</Box>;
		}

		if (report?.table && !hasTestedTest(report.table)) {
			return <Box>
				<Text size='medium' color='negativeVivid'>There is no test data for any student in this {unitLabel}.</Text>
			</Box>;
		}

		return <TableWrapper>
			<OverlayScrollbarsComponent
				options={{scrollbars: {visibility: 'hidden'}, overflow: {x: 'hidden', y: 'scroll'}}}
				ref={leftScrollRef}
				events={{scroll: () => syncScroll(leftScrollRef, rightScrollRef)}}>
				<LeftPanel report={report}
									 onSortableCellClick={onSortableCellClick}
									 sortableRows={sortableRows}
									 sortOptions={sortableOptions}
									 previousSortedColumnIndex={previousSortedColumnIndex}/>
			</OverlayScrollbarsComponent>
			<OverlayScrollbarsComponent options={{overflow: {x: 'scroll', y: 'scroll'}}}
																	ref={rightScrollRef}
																	events={{scroll: () => syncScroll(rightScrollRef, leftScrollRef)}}>
				<RightPanel report={report}
										markingPeriod={markingPeriod}
										testResultsCorrectVerbiage={testResultsCorrectVerbiage}
										onSortableCellClick={onSortableCellClick}
										sortableRows={sortableRows}
										sortOptions={sortableOptions}
										cellRender={cellRender}
										isCellShown={isCellShown}
										showOutOf={showOutOf}
										previousSortedColumnIndex={previousSortedColumnIndex}/>
			</OverlayScrollbarsComponent>
		</TableWrapper>;
	}, [report, markingPeriod, testResultsCorrectVerbiage, skeleton, sortableRows, cellRender, isCellShown, showOutOf, onSortableCellClick, sortableOptions]);

	return <Wrapper>
		{ renderSelectedEntity() }
		</Wrapper>;
}