import React, {useEffect, useMemo, useState} from 'react';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {ResultsBody} from './components/results-body';
import {RequestResultsInit} from '../api-models';
import {ResultsHeader} from './components/results-header';
import {ResultsService} from '../services/results-service/results-service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useService} from '@esgi/core/service';

interface Props {
	reportModel: RequestResultsInit
	onClose: () => void;
	onGoBack: () => void;
}

export function ResultsFormModal(props: Props) {
	const {reportModel, onClose, onGoBack} = props;
	const service = useService(ResultsService);
	const [dataLoaded, setDataLoaded] = useState(false);
	const modalManagerRef = useModal();
	const close = useCloseModal(modalManagerRef, onClose);
	const goBack = useCloseModal(modalManagerRef, onGoBack);

	useEffect(() => {
		service.init(reportModel).subscribe(() => setDataLoaded(true));
	}, []);

	return <>
		<ServiceLoader trackingService={service} fullscreen longLoadingText='Please wait. This report may take a few minutes to load.' />
		{dataLoaded && <Modal modalManagerRef={modalManagerRef}>
			<ResultsHeader onClose={close} onGoBack={goBack} service={service}
			               allStudentsSelected={reportModel.allStudentsSelected}/>
			<ResultsBody service={service}/>
		</Modal>};
	</>;
}
