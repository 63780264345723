import {useItemAnalysisService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {ContextMenu} from '@esgi/ui';
import {ContextMenuItem, ZoomRoot, ZoomLabel, ZoomArrow} from './index.styled';
import {ZoomOption} from '../../../../types';

const zoomOptions: ZoomOption[] = ['Zoom In', 'Zoom Out', 'Zoom To Fit', '50%', '100%', '200%'];

export function Zoom() {
	const service = useItemAnalysisService();
	const zoom = useBehaviorSubject<number>(service.reportService.zoom$);
	const zoomPercent = useMemo(() => `${Math.round(zoom * 100)}%`, [zoom]);

	return (
		<ZoomRoot id='iar-zoom'>
			<Text size='medium'>Zoom level:</Text>
			<ContextMenu.Root modal={true}>
				<ContextMenu.Trigger>
				<ZoomLabel>
					<Text size='medium'>{zoomPercent}</Text>
					<ZoomArrow/>
				</ZoomLabel>
				</ContextMenu.Trigger>
				<ContextMenu.Content>
					<ContextMenu.Group>
						{zoomOptions.map((item, index) =>
							<ContextMenuItem
								key={`${item}_${index}`}
								onClick={() => service.reportService.changeZoom(item)}
							>
								<Text size='medium'>{item}</Text>
							</ContextMenuItem>
						)}
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>
		</ZoomRoot>
	);
}
