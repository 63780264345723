import React, {ReactNode} from 'react';
import {Subject} from 'rxjs';
import {join} from '@esgillc/ui-kit/utils';
import {
	CriteriaActiveIcon,
	DescriptionActiveIcon,
	FirstStepIcon,
	LevelActiveIcon,
	SecondStepIcon,
	ThirdStepIcon,
} from './components/icons';
import StepIcon from './components/step-icon';
import styles from './header.module.less';

interface Props {
	currentStep: number;
	onStepClicked: (step: number) => void;

	isAvailable: (step: number) => boolean;

	levelsStepVisited: boolean;
	criteriaValid: boolean;
	levelsValid: boolean;
}

export default class Header extends React.PureComponent<Props> {

	private getClassNameForStep = (step: number) => {
		let className = '';
		if (this.props.currentStep > step) {
			className = styles.visited;
		}
		if (!className && this.props.currentStep === step) {
			className = styles.active;
		}
		if (!className && this.props.isAvailable(step)) {
			className = styles.available;
		}
		if (!className) {
			className = styles.disabled;
		}
		return className;
	};

	private onDestroy$: Subject<void> = new Subject();

	public render() {
		return <div className={styles.header}>
			<div className={styles.stepper}>
				<div className={styles.steps}>
					{this.renderFirstStep()}
					{this.renderSecondStep()}
					{this.renderThirdStep()}
					<div className={styles.lineContainer}>
						<hr className={join(styles.line, !this.props.isAvailable(2) && styles.disabledLine)}/>
						<hr className={join(styles.line, !this.props.isAvailable(3) && styles.disabledLine)}/>
					</div>
				</div>
			</div>
		</div>;
	}

	private renderFirstStep(): ReactNode {
		const onClick = () => this.props.onStepClicked(1);
		return <StepIcon title={<span onClick={onClick}>Add Criteria</span>}
		                 stepLogo={<CriteriaActiveIcon onClick={onClick}/>}
		                 stepIcon={<FirstStepIcon onClick={onClick}/>}
		                 className={this.getClassNameForStep(1)}/>;
	}

	private renderSecondStep(): ReactNode {
		const onClick = () => this.props.onStepClicked(2);
		return <StepIcon title={<span onClick={onClick}>Add Levels</span>}
		                 stepLogo={<LevelActiveIcon onClick={() => this.props.onStepClicked(2)}/>}
		                 stepIcon={<SecondStepIcon onClick={() => this.props.onStepClicked(2)}/>}
		                 className={this.getClassNameForStep(2)}/>;
	}

	private renderThirdStep(): ReactNode {
		const onClick = () => this.props.levelsStepVisited && this.props.onStepClicked(3);
		return <StepIcon title={<span onClick={onClick}>Add Descriptions</span>}
		                 tooltip={this.getDescriptionStepTooltip()}
		                 stepLogo={<DescriptionActiveIcon onClick={onClick}/>}
		                 stepIcon={<ThirdStepIcon onClick={onClick}/>}
		                 className={this.getClassNameForStep(3)}/>;
	}

	private getDescriptionStepTooltip(): string {
		const {criteriaValid, levelsValid, levelsStepVisited} = this.props;

		if ((!criteriaValid || !levelsValid || !levelsStepVisited) && this.props.currentStep === 1) {
			let baseMessage = 'Please complete Step ';
			if (!criteriaValid) {
				baseMessage += !criteriaValid && (!levelsValid || !levelsStepVisited) ? '1 and 2' : '1';
			} else {
				baseMessage += '2';
			}
			return baseMessage + '.';
		}
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
