export enum DrawerMode {
	Edit = 'edit',
	Create = 'create',
	View = 'view',
}

export enum DrawerSearchKey {
	DrawerMode = 'drawerMode',
	DrawerName = 'drawerName',
}

export type DrawerRef = {
	closeDrawer: (cb?: VoidFunction) => void;
};
