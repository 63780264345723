import * as React from 'react';
import type {SVGProps} from 'react';

export function Fav(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path fillRule='evenodd' clipRule='evenodd' d='M14.8112 7.23189C15.1854 6.08025 16.8146 6.08025 17.1888 7.23189L18.79 12.16H23.9717C25.1826 12.16 25.6861 13.7095 24.7065 14.4212L20.5144 17.4669L22.1156 22.395C22.4898 23.5466 21.1717 24.5043 20.1921 23.7926L16 20.7468L11.8079 23.7926C10.8283 24.5043 9.51019 23.5466 9.88438 22.395L11.4856 17.4669L7.29354 14.4212C6.3139 13.7095 6.81737 12.16 8.02827 12.16H13.21L14.8112 7.23189ZM16 8.42718L14.5804 12.7962C14.4131 13.3113 13.9331 13.66 13.3916 13.66H8.7977L12.5142 16.3602C12.9523 16.6785 13.1357 17.2427 12.9683 17.7577L11.5487 22.1268L15.2653 19.4266C15.7034 19.1082 16.2966 19.1082 16.7347 19.4266L20.4513 22.1268L19.0317 17.7577C18.8643 17.2427 19.0477 16.6785 19.4858 16.3602L23.2023 13.66H18.6084C18.0669 13.66 17.5869 13.3113 17.4196 12.7962L16 8.42718Z' fill='#333333'/>
		</svg>

	);
}
