import {Text} from '@esgi/ui/typography';
import {Button} from './index.styled';
import {CounterBox} from '@esgi/ui/layout';

type Props = {
	onClick: VoidFunction;
	itemsCount: number;
};

export function LoadMoreButton({itemsCount, onClick}: Props) {
	if (itemsCount <= 0) {
		return null;
	}

	return (
		<Button dataCy='load-more-button' color='tertiary' onClick={onClick}>
			<Text size='medium' bold>
				Load More...
			</Text>

			<CounterBox>
				<Text data-cy='counter' size='small' font='mono'>
					{itemsCount}
				</Text>
			</CounterBox>
		</Button>
	);
}
