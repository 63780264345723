import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {useState} from 'react';
import {ConfirmAbortAlert} from './components/confirm-abort-alert';

type Props = {
	dataCy?: string;
	onRetry: () => void;
	onAbort: () => void;
}

export function LostConnectionAlert(props: Props) {
	const [showConfirmAbortAlert, setShowConfirmAbortAlert] = useState(false);
	const modal = useModal();

	const onRetry = useCloseModal(modal, props.onRetry);
	const onAbort = useCloseModal(modal, props.onAbort);

	return <>
		<Alert
			modalManagerRef={modal}
			data-cy={props.dataCy}
		>
			<Alert.Body>
				Uh oh. It looks like the internet connection has been lost (test results not saved).
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Contained
					className='btn btn-danger'
					data-cy='retry-button'
					onClick={onRetry}
				>
					RETRY
				</Buttons.Contained>
				<Buttons.Contained
					data-cy='abort-button'
					onClick={() => setShowConfirmAbortAlert(true)}
				>
					ABORT TEST SESSION
				</Buttons.Contained>
			</Alert.Footer>
		</Alert>
		{showConfirmAbortAlert &&
			<ConfirmAbortAlert
				onConfirm={onAbort}
				onCancel={() => setShowConfirmAbortAlert(false)}
			/>
		}
	</>;
}
