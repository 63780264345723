import {TestListProps} from '../../types';
import {SelectableListMultiple} from './components/list';
import {Action} from './components/action';
import {Wrapper} from './index.styled';

export function TestList(props: TestListProps) {
	const {tests, selectedTests, toggleTests, sort, onSort, onSelectAll} = props;

	return (
		<Wrapper>
			<Action
				sort={sort}
				onSort={onSort}
				onSelectAll={onSelectAll}
			/>
			<SelectableListMultiple
				items={tests}
				selected={selectedTests}
				setSelected={(id) => toggleTests([id])}
			/>
		</Wrapper>
	);
}
