import {useState} from 'react';
import {TestType} from '@esgi/core/enums';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {SingleScoreState} from './types';
import {ResultRequest} from '../../types';
import {TestSessionUpdateEvent, TestSessionNeedUpdateEvent} from '../../events';
import {TestSessionDetailsFormService, SingleScoreService} from '../../services';

export const useSingleScore = (service: TestSessionDetailsFormService) => {
	const serviceData = service.serviceData as SingleScoreService;
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const testDateTouched = useBehaviorSubject(service.testDateTouched$);
	const validation = useBehaviorSubject(service.validation$);
	const [state, setState] = useState(new SingleScoreState());

	const getAnswers = (request: ResultRequest) => {
		serviceData.load(request).subscribe(({score, note}) => {
			setState({
				...state,
				score,
				note: note || '',
			});
			service.setAnswersLoaded(true);
		});
	};

	const setAnswers = () => {
		setState({
			...state,
			score: 0,
			note: '',
		});
	};

	useEventEffect(TestSessionNeedUpdateEvent, () => {
		if (!validation.valid) {
			return;
		}

		const {testID, studentID} = service.initData;
		const testSessionID = currentTestSession.isEmpty ? 0 : currentTestSession.id;
		const testDate = currentTestSession.testDateIso;
		const notes = state.note || null;

		dispatchAppEvent(
			TestSessionUpdateEvent,
			new TestSessionUpdateEvent({
				testSessionID,
				testDate,
				testDateTouched,
				notes,
				testType: TestType.Score,
				score: state.score,
				testID,
				studentID,
			}),
		);
	});

	return {
		state,
		setState,
		getAnswers,
		setAnswers,
	};
};
