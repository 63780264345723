import React from 'react';
import AppHistory from 'shared/tools/history/history';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {Loader} from '@esgi/deprecated/jquery';
import {afterLoginHandler} from '../redirect-module/redirect';
import {Authenticator, LoginResponse} from '@esgi/core/authentication';
import {HttpClient} from '@esgi/api';

export default class ByCRM extends React.PureComponent {

	public componentDidMount() {
		const loader = new Loader('.holder');
		loader.mask();

		const params = new URLSearchParams(window.location.search);
		const userId = params.get('userId');

		if (!userId) {
			OldAlerts.bsalert('userid was not found', () => AppHistory.navigateOutside('/'));
			return;
		}

		const token = params.get('token');
		if (!token) {
			OldAlerts.bsalert('Invalid token', () => AppHistory.navigateOutside('/'));
			return;
		}

		HttpClient.default.ESGIApi.post<LoginResponse>('Login', 'ByCrmToken', {token: token, userId: userId})
			.subscribe(async (r) => {
				const validator = new Authenticator();
				const authenticatorResult = await validator.authenticate(r);

				if (authenticatorResult.valid) {
					afterLoginHandler(r.user);
				} else if (!authenticatorResult.redirect){
					AppHistory.navigateOutside('/');
				}
				loader.unmask();
			});
	}

	public render() {
		return <div className='holder'/>;
	}
}

