import {Student} from '@esgi/main/libs/store';
import {Test} from '../../../types';
import {AlertHeader} from '../../alert-header';
import {TestName} from '../../test-name';
import {TestType} from '@esgi/main/libs/core';
import {AlertFooter} from '../../alert-footer';
import {AlertBody} from '../../components.styled';
import {
	QuestionRowEditable,
	QuestionsContainer,
	EditableSessionDateTimeInfo,
	ToggleScoreResult,
	maxRubricCommentLength,
} from '../../../../kit';
import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {CriteriaModel, RubricScoreState} from './types';
import {Textarea} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {ScoreResultText} from './index.styled';
import {DefaultSkeleton} from '../../default-skeleton';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {isUndefined, useBehaviorSubject, useStreamEffect} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {TestSessionRubricCreatedEvent} from '../../../events';
import {useInitialUserDate} from '../../../hooks/use-initial-user-date';
import {useUser} from '@esgi/core/authentication';

type Props = {
	student: Student;
	test: Test;
	onCloseAlert: VoidFunction;
};

export function AddRubricSession({student, test, onCloseAlert}: Props) {
	const currentUser = useUser();

	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isDataSaving, setIsDataSaving] = useState(false);

	const initialUserDate = useInitialUserDate();

	const service = useService(Service);

	const rubricCriteria = useBehaviorSubject(service.rubricCriteria$);

	useEffect(() => {
		service.init({studentID: student.id, testID: test.id}).subscribe(() => {
			setIsDataLoaded(true);
		});
	}, []);

	const maxCriteriaValue = test.maxScore / rubricCriteria.length;

	const questionTitlesById = useMemo(() => {
		const questionTitlesById: Record<CriteriaModel['id'], CriteriaModel['name']> = {};

		rubricCriteria.forEach(({id, name}) => (questionTitlesById[id] = name));

		return questionTitlesById;
	}, [rubricCriteria]);

	const [rubricScoreState, setRubricScoreState] = useState<RubricScoreState[]>([]);

	const [isSessionDateTimeValid, setIsSessionDateTimeValid] = useState(false);
	const [sessionNote, setSessionNote] = useState('');

	useStreamEffect(service.rubricCriteria$, (rubricCriteria) => {
		setRubricScoreState(
			rubricCriteria.map(({id}) => ({
				criteriaID: id,
				comment: null,
				score: 0,
			})),
		);
	});

	const editableSessionTimeRef = EditableSessionDateTimeInfo.useRef();

	const updateRubricScoreState = useCallback(
		(args: {criteriaID: CriteriaModel['id']; newState: Partial<Omit<RubricScoreState, 'criteriaID'>>}) => {
			const {criteriaID, newState} = args;

			setRubricScoreState((currentAnswersState) =>
				currentAnswersState.map((iteratedState) =>
					iteratedState.criteriaID !== criteriaID
						? iteratedState
						: {
								...iteratedState,
								...newState,
						  },
				),
			);
		},
		[],
	);

	const onAddComment = useCallback(
		({criteriaID, comment}: {criteriaID: CriteriaModel['id']; comment: string}) => {
			updateRubricScoreState({criteriaID, newState: {comment}});
		},
		[updateRubricScoreState],
	);

	const onDeleteComment = useCallback(
		({criteriaID}: {criteriaID: CriteriaModel['id']}) => {
			updateRubricScoreState({criteriaID, newState: {comment: null}});
		},
		[updateRubricScoreState],
	);

	const onSetScore = useCallback(
		({criteriaID, newScore}: {criteriaID: CriteriaModel['id']; newScore: number}) => {
			updateRubricScoreState({criteriaID, newState: {score: newScore}});
		},
		[updateRubricScoreState],
	);

	const handleUpdateSessionNote = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value;

		setSessionNote(value);
	}, []);

	const onSave = () => {
		const dateTime = editableSessionTimeRef.current?.getFullDate();

		if (isUndefined(dateTime)) {
			return;
		}

		setIsDataSaving(true);

		service
			.save({
				testID: test.id,
				studentID: student.id,
				testDate: dateTime,
				summaryNotes: sessionNote,
				rubricScoreState,
			})
			.subscribe(({testSessionID}) => {
				setIsDataSaving(true);
				onCloseAlert();

				dispatchAppEvent(
					TestSessionRubricCreatedEvent,
					new TestSessionRubricCreatedEvent({
						testSessionID,
						testID: test.id,
						studentID: student.id,
						testDate: dateTime,
						summaryNotes: sessionNote,
						rubricAnswers: rubricScoreState.map(({comment, criteriaID, score}) => ({
							criteriaID,
							score,
							notes: comment,
						})),
					}),
				);
			});
	};

	if (!isDataLoaded) {
		return <DefaultSkeleton onCloseAlert={onCloseAlert} />;
	}

	return (
		<>
			<AlertHeader student={student} onCloseAlert={onCloseAlert} />

			<AlertBody>
				<TestName testID={test.id} name={test.name} type={TestType.Rubric} />

				<GridBox flow='column' gap='3' columns='2' align='center'>
					<EditableSessionDateTimeInfo
						initialTestDate={initialUserDate}
						setisValid={setIsSessionDateTimeValid}
						editableSessionTimeRef={editableSessionTimeRef}
						validateOnMinDate={{
							value: new Date(2020, 7, 29), // 2020-08-29
							message: 'Incorrect Date',
						}}
						timeZone={currentUser?.timeZone}
					/>
				</GridBox>

				<QuestionsContainer>
					{rubricScoreState.map(({criteriaID, comment, score}, index) => (
						<QuestionRowEditable
							questionNumber={index + 1}
							questionTitle={questionTitlesById[criteriaID] ?? ''}
							comment={comment}
							withQuestionUnderline={index !== rubricScoreState.length - 1}
							key={criteriaID}
							onAddComment={(comment) => onAddComment({criteriaID, comment})}
							onDeleteComment={() => onDeleteComment({criteriaID})}
							maxCommentLength={maxRubricCommentLength}
						>
							<GridBox gap='3' flow='column'>
								<ScoreResultText size='xSmall' font='mono' bold color='primary'>
									{score}
								</ScoreResultText>
								<ToggleScoreResult
									maxScore={maxCriteriaValue}
									score={score}
									onClick={(newScore) => onSetScore({criteriaID, newScore})}
								/>
							</GridBox>
						</QuestionRowEditable>
					))}
				</QuestionsContainer>

				<Textarea placeholder='Summary Note' value={sessionNote} onChange={handleUpdateSessionNote} />
			</AlertBody>

			<AlertFooter onSave={onSave} saveDisabled={!isSessionDateTimeValid || isDataSaving} />
		</>
	);
}
