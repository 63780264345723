import {TestsPanelRoot} from './root';
import {
	TestsPanelContent,
	TestsPanelHeader,
	TestsPanelDivider,
	StudentsRoot,
	StudentsCounter,
	StudentsPreview,
	StudentsInfo,
	SubjectControl,
	SubjectRoot,
	TestsRoot,
	TestsList,
	TestsItem,
	Sort,
	SelectAll,
	TestsListEmpty,
	TestsListLoading,
} from './components';

export const TestsPanel = {
	Root: TestsPanelRoot,
	Layout: {
		Content: TestsPanelContent,
		Header: TestsPanelHeader,
		Divider: TestsPanelDivider,
	},
	Students: {
		Root: StudentsRoot,
		Counter: StudentsCounter,
		Preview: StudentsPreview,
		Info: StudentsInfo,
	},
	Subject: {
		Root: SubjectRoot,
		Control: SubjectControl,
	},
	Tests: {
		Root: TestsRoot,
		Controls: {
			Sort: Sort,
			SelectAll: SelectAll,
		},
		List: TestsList,
		Item: TestsItem,
		ListEmpty: TestsListEmpty,
		ListLoading: TestsListLoading,
	},
};

export * from './hooks';
export * from './model';
export * from './types';