/* eslint-disable @typescript-eslint/member-ordering */
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {BaseService} from '@esgi/core/service';
import {FilterService} from './filter-service';
import {getSelectedSchoolYearID, userStorage} from '@esgi/core/authentication';
import {CheckAnotherLinksResponse} from '../types/types';

export abstract class StudentCredentialsService<Filters extends FilterService> extends BaseService {
	constructor(public readonly filterService: Filters) {
		super();
	}

	protected readonly studentCredentialsController = 'assignments/student-credentials';

	protected readonly currentUserId = userStorage.get().userID;

	public abstract init(): Observable<unknown>;

	private setUserCredentials(valueType: 'Username' | 'Password') {
		showSnackbarNotification(`${valueType} has been saved.`, {
			offsetX: this.filterService.tableOffsetLeft,
		});
	}

	private getGeneralValidateMessage(value: string, {maxLenth, minLenght}: {maxLenth: number; minLenght: number}) {
		if (!value) {
			return;
		}

		const space = ' ';

		if (value.length < minLenght) {
			return `Minimum ${minLenght} characters`;
		}

		if (value.length > maxLenth) {
			return `Maximum ${maxLenth} characters`;
		}

		if (value.includes(space)) {
			return 'Spaces Not Allowed';
		}
	}

	public validateUsername(value: string, studentID: number): Observable<string | undefined | null> {
		if (!value) {
			return of(null);
		}

		const validateMessage = this.getGeneralValidateMessage(value, {minLenght: 4, maxLenth: 20});

		if (validateMessage) {
			return of(validateMessage);
		}

		return this.httpClient.ESGIApi.get<{isUnique: boolean}>(this.studentCredentialsController, 'validate-username', {
			studentID,
			userName: value,
		})
			.pipe(map((r) => (!r.isUnique ? 'This username already exists.' : undefined)))
			.asObservable();
	}

	public validatePassword(value: string): Observable<string | null> {
		const validateMessage = this.getGeneralValidateMessage(value, {minLenght: 4, maxLenth: 12});

		if (validateMessage) {
			return of(validateMessage);
		}

		return of(null);
	}

	public saveUsername(studentID: number, username: string) {
		this.setUserCredentials('Username');
		const student = this.filterService.allTableData$.value.find((v) => v.id === studentID);

		if (student) {
			student.userName = username;

			this.httpClient.ESGIApi.post('assignments/student-credentials', 'set-username', {
				studentID,
				username,
			}).subscribe();
		}
	}

	public saveUserPassword(studentID: number, password: string) {
		this.setUserCredentials('Password');
		const student = this.filterService.allTableData$.value.find((v) => v.id === studentID);

		if (student) {
			student.password = password;

			this.httpClient.ESGIApi.post('assignments/student-credentials', 'set-password', {
				studentID,
				password,
			}).subscribe();
		}
	}

	public checkStudentToOtherMembers(studentId: number) {
		const globalSchoolYearID = getSelectedSchoolYearID();

		return this.httpClient.ESGIApi.get<CheckAnotherLinksResponse>(
			this.studentCredentialsController,
			'check-another-links',
			{
				studentId,
				globalSchoolYearID,
				userId: this.currentUserId,
			},
		).asObservable();
	}
}
