import {Fragment} from 'react';
import {Container, List, Separator, Sup} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Test, TestType} from '../../../../../../types';
import {getTestType, getAnswersWithNote} from '../../../../utils';

interface Props {
	test: Test;
	includeQuestionNotes: boolean;
	includeSummaryNotes: boolean;
}

export function Notes(props: Props) {
	const {test, includeQuestionNotes, includeSummaryNotes} = props;

	if (!includeQuestionNotes && !includeSummaryNotes) {
		return null;
	}

	return (
		<Container>
			{includeQuestionNotes &&
				<>
					<Text data-cy='notes-label'>Notes</Text>
					<List data-cy='notes-section'>
						{getAnswersWithNote(test).map(({text, note}, index) => (
							<Fragment key={text}>
								{getTestType(test.type) === TestType.YN &&
									<Sup size='small' bold>{index + 1}</Sup>
								}
								{getTestType(test.type) === TestType.Rubric &&
									<Text bold>{text}:&nbsp;</Text>
								}
								<Text>{note}</Text>
								{index !== getAnswersWithNote(test).length - 1 ? ', ' : ''}
							</Fragment>
						))}
						{getTestType(test.type) === TestType.Score &&
							<Text>{test.summaryNotes}</Text>
						}
					</List>
					{includeSummaryNotes && <Separator/>}
				</>
			}
			{includeSummaryNotes &&
				<>
					<Text data-cy='summary-label'>Summary</Text>
					<List data-cy='summary-section'>
						<Text>{test.summaryNotes}</Text>
					</List>
				</>
			}
		</Container>
	);
}
