import React, {useCallback, useEffect, useState} from 'react';
import {DesignVersion, routes, useDesignVersion} from '@esgi/main/libs/core';
import {Drawer, UnsavedChangesConfirmation, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {SettingsTabTouchedData, TestSessionResult} from './types';
import {Form} from '@esgillc/ui-kit/form';
import {BoxArrowUpRight, Setting} from '@esgi/ui';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {LockHomepage} from './components/lock-homepage';
import {Panel} from './components/full-setup-settings-panel';
import {SingleCheckboxSection} from './components/single-checkbox-section';
import {TestSessionResultsView} from './components/test-session-results-view';
import {BodyContainer} from './components/quick-setup-settings-panel/index.styled';
import {QuickSetupSettingsService} from './components/quick-setup-settings-panel/service';
import {Text} from '@esgi/ui/typography';
import {StyledButton} from './components/full-setup-settings-panel/index.styled';
import {useKeyboardShortcutsDescription} from './use-keyboard-shortcuts-description';
import {merge} from 'rxjs';
import {initialSettingsTabsData} from './components/full-setup-settings-panel/constants';

type Props = {
	onClose: VoidFunction;
};

export function SettingsDrawer(props: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, props.onClose);

	const [, setDesignVersion] = useDesignVersion();
	const onOldDesignClicked = useCallback(() => {
		setDesignVersion(DesignVersion.Old);
		location.href = routes.teacher.home.root;
	}, []);

	const service = useService(QuickSetupSettingsService);

	const isShowTestSessionResult = useBehaviorSubject(
		service.isShowTestSessionResult
	);
	const isUnlockHomepage = useBehaviorSubject(service.isUnlockHomepage);
	const isKeyboardShortcuts = useBehaviorSubject(
		service.isKeyboardShortcutsEnabled
	);

	const [isInit, setIsInit] = useState(false);
	const [isDisableButton, setIsDisableButton] = useState(true);
	const [isUnsavedChangesAlertOpen, setUnsavedChangesAlertOpen] = useState(false);
	const [tabsTouchedData, setTabsTouchedData] = useState<SettingsTabTouchedData>(initialSettingsTabsData);
	const initData = useBehaviorSubject(service.initData);

	useStreamEffect(merge(service.form.onChanged, service.isShowTestSessionResult), () => {
		setIsDisableButton(!service.compareQuickSetupStatesForEquality());
	});

	useEffect(() => {
		service.init().subscribe(() => {
			setIsInit(true);
		});
	}, []);

	const handleActionButtonClick = () => {
		service.save().subscribe(() => {
			setIsDisableButton(true);
		});
	};

	const changeSessionResult = (value: TestSessionResult) => {
		service.isShowTestSessionResult.next(
			value === TestSessionResult.InProgress
		);
	};

	const keyboardShortcutsDescription = useKeyboardShortcutsDescription();

	const handelCloseDrawer = (isTouched: boolean) => {
		if(isTouched) {
			setUnsavedChangesAlertOpen(true);
		}else {
			close();
		}
	};

	const onUnsavedChangesAlertOpen = () => {
		const isTouched = service.compareQuickSetupStatesForEquality();

		handelCloseDrawer(isTouched);
		setIsDisableButton(!isTouched);
	};

	const handelCollapsePanel = () => {
		service.setupQuickSettings();
		setTabsTouchedData(initialSettingsTabsData);
	};

	const onCloseUnsavedChangesConfirmation = useCallback(() => {
		setUnsavedChangesAlertOpen(false);
	}, []);

	return (
		<Drawer drawerRef={drawerRef} onClickOutside={onUnsavedChangesAlertOpen} onPanelCollapse={handelCollapsePanel}>
			{isInit && (
				<>
					<Drawer.Header
						Icon={Setting}
						withActionButton
						closeDrawer={onUnsavedChangesAlertOpen}
						sectionName='Quick Setup'
						actionButtonText='Save Changes'
						actionButtonDisabled={isDisableButton}
						onActionButtonClick={handleActionButtonClick}
					/>
					<Drawer.Body>
						<OverlayScrollbarsComponent
							defer
							style={{height: 'calc(100% + 0px)'}}
							options={{scrollbars: {autoHide: 'leave'}}}
						>
							<Form controller={service.form}>
								<BodyContainer>
									<Drawer.ContentBlock
										title='Legacy Version Switch'
										withDivider
									>
										<StyledButton
											color='secondary'
											onClick={onOldDesignClicked}
										>
											<Text size='medium' color='base'>
												Go back to old ESGI
											</Text>
											<BoxArrowUpRight />
										</StyledButton>
									</Drawer.ContentBlock>

									<Drawer.ContentBlock title='Lock Homepage' withDivider>
										<LockHomepage
											pinControl={service.form.controls?.screenLockPIN}
											confirmPinControl={service.form.controls?.confirmPin}
											unlockControl={service.form.controls?.lockScreen}
											isUnlocked={isUnlockHomepage}
										/>
									</Drawer.ContentBlock>

									<Drawer.ContentBlock title='Keyboard Shortcuts' withDivider>
										<SingleCheckboxSection
											checkboxControl={service.form.controls.enableShortcuts}
											isDisabled={!isKeyboardShortcuts}
											description={keyboardShortcutsDescription}
											dataCy='keyboard-shortcuts-checkbox'
										/>
									</Drawer.ContentBlock>

									<Drawer.ContentBlock
										title='Test Session Results View'
										withDivider
									>
										<TestSessionResultsView
											testSessionResult={
												isShowTestSessionResult
													? TestSessionResult.InProgress
													: TestSessionResult.Success
											}
											testSessionProgress={{
												correctAnswers: 33,
												questions: 45,
											}}
											onChange={(v) => changeSessionResult(v)}
										/>
									</Drawer.ContentBlock>
								</BodyContainer>
							</Form>
						</OverlayScrollbarsComponent>
					</Drawer.Body>
					<Panel
						initData={initData}
						onChangeDesignVersion={onOldDesignClicked}
						onUpdateInitData={service.updateInitDataState}
						onCloseDrawer={handelCloseDrawer}
						tabsTouchedData={tabsTouchedData}
						onChangeTabsTouchedData={setTabsTouchedData}
					/>

					{isUnsavedChangesAlertOpen && <UnsavedChangesConfirmation onCloseAnyway={close} onClose={onCloseUnsavedChangesConfirmation}/>}
				</>
			)}
		</Drawer>
	);
}
