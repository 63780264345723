import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, of} from 'rxjs';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {tap, map, takeUntil, filter, concatMap} from 'rxjs/operators';
import {IEPGoalModel, IEPStatusModel, StudentModel, IEPDatesModel, IEPGoalModelRequest} from '../models';
import {ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {IEPGoalForm} from '../types';
import {DateTools} from 'global/utils/date-utils';
import {userStorage} from '@esgi/core/authentication';

export class IEPGoalFormService extends BaseService {
	public form: IEPGoalForm = new FormGroup({
		status: new FormControl<IEPStatusModel[]>([]),
		student: new FormControl<StudentModel[]>([]),
		goal: new FormControl<string>(''),
		benchmark: new FormControl<string>(''),
		isCompleted: new FormControl<boolean>(false),
	});
	public statusForm = new FormGroup({
		statusName: new FormControl<string>(''),
	});
	public readonly iepGoal$: BehaviorSubject<IEPGoalModel> = new BehaviorSubject<IEPGoalModel>(null);
	public readonly iepStatuses$: BehaviorSubject<IEPStatusModel[]> = new BehaviorSubject<IEPStatusModel[]>([]);
	public readonly iepDates$: BehaviorSubject<IEPDatesModel> = new BehaviorSubject<IEPDatesModel>(null);
	public student: StudentModel;
	private readonly controllerName = 'assessments/test-session-details';
	private readonly iepDatesControllerName = 'iep-dates';
	private defaultIepGoal: IEPGoalModel;
	private readonly currentUser = userStorage.get();

	public initStatusFormValidators(statuses: IEPStatusModel[]): void {
		this.statusForm.controls.statusName.validators.push(Validators.isDublicateValue(statuses.map(s => s.name)));
	}

	public init(iepGoal: IEPGoalModel, statuses, student: StudentModel) {
		this.iepGoal$.next(iepGoal);
		this.iepStatuses$.next(statuses);
		this.initFormValidators();
		this.initFormValues(iepGoal, statuses);
		this.loadIEPDates(iepGoal.studentID);
		this.student = student;
		this.defaultIepGoal = iepGoal;

		this.form.controls.isCompleted.onChanged.subscribe((value) => {
			if (!value) {
				return;
			}
			this.iepGoal$.next({
				...this.iepGoal$.value,
				isCompleted: value.currState.value,
			});
		});
	}

	public update(callback?: () => void) {
		this.form.validate().pipe(
			map(res => res.map(r => !r.length)),
			filter(res => res.every(Boolean)),
			concatMap(() => {
				this.httpClient.ESGIApi.post<IEPGoalModel>(
					this.controllerName,
					'update-iep-goal',
					this.buildIEPGoal(),
				).subscribe(() => {
					this.resetFormControlStatuses();
					showSnackbarNotification(`IEP Goal updated`);
					const iepGoal = Object.keys(this.form.controls).reduce(
						(result, key) => {
							if (['benchmark', 'goal', 'isCompleted', 'status'].includes(key)) {
								if (key === 'status') {
									result.statusID = this.form.controls[key].value[0]?.id || null;
								} else {
									result[key] = this.form.controls[key].value;
								}
							}
							return result;
						},
						{} as Record<string, any>,
					);
					this.iepGoal$.next({
						...this.iepGoal$.value,
						...iepGoal,
					});
					this.defaultIepGoal = this.iepGoal$.value;
					if (callback) {
						callback();
					}
				});
				return of();
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public loadIEPDates(studentID: number) {
		this.httpClient.ESGIApi.get<IEPDatesModel>(
			this.iepDatesControllerName,
			'init',
			{studentID},
		).pipe(
			tap((r) => this.iepDates$.next(r)),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public createIEPDates(start: Date, end: Date, callback?: () => void) {
		const studentID = this.student.studentID;
		const startDate = DateTools.toServerString(start);
		const endDate = DateTools.toServerString(end);

		return this.httpClient.ESGIApi.post<void>(
			this.iepDatesControllerName,
			'create',
			{studentID, startDate, endDate},
		).pipe(
			tap(() => {
				this.iepDates$.next({startDate: start, endDate: end} as IEPDatesModel);
				showSnackbarNotification(`IEP Dates saved`);
				if (callback) {
					callback();
				}
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public updateIEPDates(start: Date, end: Date, callback?: () => void) {
		const studentID = this.student.studentID;
		const startDate = DateTools.toServerString(start);
		const endDate = DateTools.toServerString(end);

		return this.httpClient.ESGIApi.post<void>(
			this.iepDatesControllerName,
			'update',
			{studentID, startDate, endDate},
		).pipe(
			tap(() => {
				this.iepDates$.next({startDate: start, endDate: end} as IEPDatesModel);
				showSnackbarNotification(`IEP Dates saved`);
				if (callback) {
					callback();
				}
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public reinitializeStatusFormValidators(statuses: IEPStatusModel[]) {
		this.statusForm.controls.statusName.clearValidators();
		this.initStatusFormValidators(statuses);
	}

	public resetIEPGoalFormData() {
		this.form.controls.goal.value = this.defaultIepGoal.goal;
		this.form.controls.benchmark.value = this.defaultIepGoal.benchmark;
		this.form.controls.status.value = this.iepStatuses$.value.filter(
			({id}) => id === this.defaultIepGoal.statusID,
		);
		this.form.controls.isCompleted.value = this.defaultIepGoal.isCompleted;
		this.resetFormControlStatuses();
	}

	public destroy() {
		super.destroy();
	}

	private initFormValidators(): void {
		this.form.controls.goal.validators.push(Validators.required());
	}

	private initFormValues(iepGoal: IEPGoalModel, statuses: IEPStatusModel[]) {
		this.form.controls.status.value = statuses.filter(
			({id}) => id === iepGoal.statusID,
		);
		this.form.controls.goal.value = iepGoal.goal || '';
		this.form.controls.benchmark.value = iepGoal.benchmark || '';
		this.form.controls.isCompleted.value = iepGoal.isCompleted;
		this.resetFormControlStatuses();
	}

	private resetFormControlStatuses(){
		Object.values(this.form.controls).forEach(
			control => control.status = ElementStatus.untouched,
		);
	}

	private buildIEPGoal(): IEPGoalModelRequest {
		return {
			id: this.iepGoal$.value.id,
			goal: this.getStringValue(this.form.controls.goal.value),
			statusID: this.form.controls.status.value[0]?.id || null,
			benchmark: this.getStringValue(this.form.controls.benchmark.value),
			isCompleted: this.form.controls.isCompleted.value,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
		} as IEPGoalModelRequest;
	}

	private getStringValue(value: string) {
		return value && value.length ? value : null;
	}
}
