import {ConflictAction, StudentField} from '../../../../types';

export const determineConflictedFieldsActionsData = (action: ConflictAction) => {

	let changedActionData = {
		name: ConflictAction.Fix,
		studentIDN: ConflictAction.Fix,
		language: ConflictAction.Fix,
	};

	if(action === ConflictAction.Fix){
		changedActionData = updateActionsData(action);
	}

	if(action === ConflictAction.Delete){
		changedActionData = updateActionsData(action);
	}

	if(action === ConflictAction.AddAnyway){
		changedActionData = {
			...updateActionsData(ConflictAction.Fix),
			name: ConflictAction.AddAnyway,
		};
	}

	return changedActionData;
};

const updateActionsData = (action: ConflictAction) => {
	return {studentIDN: action, language: action, name: action};
};

export const determineFieldAction = (currentAction: ConflictAction, fieldType: StudentField) => {
	return fieldType !== StudentField.Name && currentAction === ConflictAction.AddAnyway
		? ConflictAction.Fix
		: currentAction;
};

export const parseStudentName = (name: string) => {
	const names = name.split(' ');
	const firstName = names[0];
	let lastName = '';
	for (let i = 1; i < names.length; i++) {
		const value = names[i];
		if (value !== '') {
			lastName += ' ' + value;
		}
	}
	return [firstName.trim(), lastName.trim()];
};
