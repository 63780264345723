import * as React from 'react';
import type {SVGProps} from 'react';

export function Comment(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M7.86924 6.64551C6.34834 6.64551 5.11523 7.87776 5.11523 9.3976V17.6539C5.11523 18.2208 5.75829 18.5531 6.2127 18.2126L8.79459 16.2778H16.1313C17.6522 16.2778 18.8853 15.0456 18.8853 13.5257V9.3976C18.8853 7.87776 17.6522 6.64551 16.1313 6.64551H7.86924ZM7.86924 8.02156H16.1313C16.8921 8.02156 17.5083 8.63734 17.5083 9.3976V13.5257C17.5083 14.286 16.8921 14.9018 16.1313 14.9018H8.55775C8.40903 14.9018 8.26789 14.9417 8.14878 15.0311L6.49224 16.2778V9.3976C6.49224 8.63734 7.10845 8.02156 7.86924 8.02156Z' fill='#333'/>
			<path d='M8.55775 10.778C8.55775 10.3978 8.866 10.0895 9.24625 10.0895H14.7543C15.1345 10.0895 15.4428 10.3978 15.4428 10.778C15.4428 11.1583 15.1345 11.4665 14.7543 11.4665H9.24625C8.866 11.4665 8.55775 11.1583 8.55775 10.778Z' fill='#333'/>
		</svg>
	);
}
