import {styled} from '@esgi/ui/theme';
import {Users} from '@esgi/ui/icons';
import {CSS} from '@stitches/react';
import {Alert, AlertColorConfig} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		width: 560,
		overflow: 'hidden',
	},
};

export const alertColorConfig: AlertColorConfig = {
	borderColor: 'mild',
	headerBgColor: 'background',
	headerTextColor: 'primary',
};

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$3',
});

export const TestInfoBox = styled(GridBox, {
	minHeight: 36,
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'start',
	gap: '$3',
	marginBottom: 8,
});

export const UsersIcon = styled(Users, {
	'& path': {
		fill: '$primary',
	},
});
