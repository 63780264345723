import React from 'react';
import styles from '../../../../styles.module.less';
import {User} from '../../../../../../../models';

interface Props {
	teacher: User
	groupType: string
}
export function Teacher({teacher, groupType}: Props) {
	return teacher && <>
		<div className={styles.formGroup}>
			<label>{groupType === 'SpecialistGroup' ? 'Specialist: ' : 'Teacher: '}</label>
			<span>{teacher.fullName}</span>
		</div>
	</>;
}
