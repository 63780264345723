import {keyframes} from '../theme';

export const activeColor = '$neutral94';
export const mainColor = '$border';

export function buildSkeletonAnimationStyles(field: 'background' | 'fill' | 'stroke') {
	const animation = keyframes({
		'50%': {
			[field]: activeColor,
		},
	});

	return {
		[field]: mainColor,
		animation: `${animation} 1s cubic-bezier(.4,0,.6,1) infinite`,
	};
}

export const SkeletonStyles = {
	position: 'relative',
	overflow: 'hidden',
	'&::after': {
		content: '',
		top: 0,
		display: 'block',
		position: 'absolute',
		height: '100%',
		width: '100%',
		...buildSkeletonAnimationStyles('background'),
	},
};

export const SkeletonStylesWithBorders = {
	...SkeletonStyles,
	borderColor: mainColor,
	'&:hover': {
		borderColor: `${mainColor} !important`,
	},
};

export const SkeletonVariant = {
	skeleton: {
		true: {
			...SkeletonStyles,
		},
	},
};

export const SkeletonWithBordersVariant = {
	skeleton: {
		true: {
			...SkeletonStylesWithBorders,
		},
	},
};