import React from 'react';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {TextInput, Field, IFormControlValidatorResult, Validators} from '@esgi/deprecated/elements';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	SharedProps,
} from '@esgi/deprecated/react';
import {Api} from '../../../api';
import {Events} from '../../../models';
import {IGModal} from '../modal';
import {State as FoldersHierarchySelectState, FoldersHierarchy} from '../../folders-hierarchy/folders-hierarchy';

export class State {
	loading: boolean = false;
	folderNameTouched: boolean = false;
	folderName: Field = new Field();
	locationSelect: FoldersHierarchySelectState = new FoldersHierarchySelectState();
}

export class Props extends SharedProps<State> {
	currentFolderID: number;
	close: (id?: number) => any;
}

export class AddFolderModal extends IGModal<State, Props> {
	private readonly maxLen = 25;
	inputRef: TextInput;

	validator(value: string): IFormControlValidatorResult {
		const rqResult = Validators.requriedValidator(value);
		if (!rqResult.valid) {
			return rqResult;
		}

		const lvResult = Validators.maxLengthValidator(value, this.maxLen);
		if (!lvResult.valid) {
			return lvResult;
		}

		return {valid: true, message: ''};
	}

	onEnter = () => this.save();

	render(): JSX.Element | false | null {
		return <Modal className='white-header edit-folder-modal ig-inner-modal base-ig-modal' animate={true}
		                         onCatchError={() => this.props.close()}>
			<ModalHeader title='Add Folder'/>
			<ModalBody>
				<div className='content form-group'>
					<TextInput label='Folder Name' ref={r => this.inputRef = r}
					                                       value={this.state.folderName.value}
					                                       maxLength={this.maxLen}
					                                       touched={this.state.folderNameTouched}
					                                       onEdit={(value, validation) => {
						                                       this.setState({
							                                       folderName: {
								                                       ...this.state.folderName,
								                                       value: value,
								                                       validation: validation,
							                                       },
							                                       folderNameTouched: true,
						                                       });
					                                       }}
					                                       validator={(value: string) => this.validator(value)}/>
					<div className='limit-counter'>{this.maxLen - this.state.folderName.value.length} characters left
						({this.maxLen} max)
					</div>
					<label>Location</label>
					<FoldersHierarchy state={this.state.locationSelect}
					                  disableLastLevel={true}
					                  initialFolderID={this.props.currentFolderID}
					                  onChange={(ch) => this.setState({locationSelect: ch})}/>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => this.props.close()}
					title='CANCEL'
					className='btn-bold'
				/>
				<Primary
					onClick={() => this.save()}
					title='SAVE'
					className='btn-bold'
				/>
			</ModalFooter>
		</Modal>;
	}

	save(): void {
		if (this.state.folderName.validation.valid && this.state.folderNameTouched) {
			this.setState({loading: true});
			Api.CreateFolder(this.state.locationSelect.selected.value, this.state.folderName.value).subscribe(r => {
				this.props.close(r.id);
				this.dispatch(Events.ItemCreated, {});
			});
		} else {
			this.inputRef.ref.focus();
			this.setState({
				folderName: {
					...this.state.folderName,
					validation: this.validator(this.state.folderName.value),
				},
				folderNameTouched: true,
			});
		}
	}
}