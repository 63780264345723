import {SectionBlock} from '../../kit';
import {EntityType, TestInfo, TestSession, TrackDate} from '../../types';
import {StudentInfo} from '../../class-group/types';
import {ClassEntity} from './components/selected-entity/class-entity';
import {StudentEntity} from './components/selected-entity/student-entity';

type Props = | {
	entityType: EntityType.Student,
	trackDates: TrackDate[],
	testSessions: TestSession[],
	isTablet: boolean,
	testInfo: TestInfo | null;
} | {
	entityType: EntityType.ClassGroup,
	trackDates: TrackDate[],
	students: StudentInfo[],
	isTablet: boolean,
	testInfo: TestInfo | null;
}

export function TestHistorySection(props: Props) {
	if (props.entityType === 'student') {
		const {testSessions, trackDates, isTablet, testInfo} = props;
		return (
			<SectionBlock isTablet={isTablet} title='Test History' dataCy='test-history-block'>
				<StudentEntity
					entityType={props.entityType}
					testSessions={testSessions?.filter(el=>!el.isDeleted)}
					trackDates={trackDates}
					isTablet={isTablet}
					testInfo={testInfo}
				/>
			</SectionBlock>
		);
	}

	if (props.entityType === 'class-group') {
		const {students, trackDates, testInfo, isTablet} = props;
		return (
			<SectionBlock isTablet={isTablet} title='Test History' dataCy='test-history-block'>
				<ClassEntity
					entityType={props.entityType}
					trackDates={trackDates}
					students={students}
					isTablet={isTablet}
					testInfo={testInfo}
				/>
			</SectionBlock>
		);
	}

	return null;
}
