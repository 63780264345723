import './root.less';
import React from 'react';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {CenterPanel} from 'shared/page/layout/center-panel';
import Layout from 'shared/page/layout/layout';
import AppHistory from 'shared/tools/history/history';
import {RoutesMap} from '@esgi/main/libs/core';
import Card from './card/card';
import {Api} from './api/api';
import './magnific-popup/magnific-popup';
import './magnific-popup/magnific-popup.css';
import {ContactUs, openZopim} from '@esgi/features/support';

class State {
	contactUs: boolean = false;
	videoHidden: boolean = true;
}

class Props {

}

export default class AdminHome extends React.Component<Props, State> {
	private api: Api = new Api();
	private videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

	constructor(props?: Props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		this.api.init();
		let popup = ($('#started-video-link, #overview-video-link') as any).magnificPopup({
			items: {
				src: '#started-video-wrapper',
				type: 'inline',
			},
			callbacks:
				{
					open: () => {
						this.setState({videoHidden: false}, () => {
							this.videoRef.current.play();
						});
					},
					close: () => {
						this.setState({videoHidden: true}, () => {
							this.videoRef.current.pause();
						});
					},
				},
		});
	}

	onReviewPerformanceClicked() {
		AppHistory.navigate(RoutesMap.home, {fromAdmin: true, reviewData: true});
	}

	onOrganizeTestsClicked() {
		AppHistory.navigate(RoutesMap.home, {fromAdmin: true, organizeTests: true});
	}

	onAccessPreloadedTestsClicked() {
		AppHistory.navigate(RoutesMap.testExplorer, {fromAdmin: true, accessPreloadedTests: true});
	}

	private openLivechat(): Promise<void> {
		return openZopim().then((zopim) => zopim.livechat.window.show());
	}

	render(): JSX.Element | false | null {
		return <div className='admin-home-page'>
			<Layout loaded={[true]}>
				<CenterPanel>
					<div className='admin-home'>
						<div className='title'>Admin Dashboard</div>
						<div className='admin-cards'>
							<Card
								className='review-performance-data'
								color='blue'
								title='Review Performance Data'
								onClicked={() => this.onReviewPerformanceClicked()}
								imagePath='//s3.amazonaws.com/ESGI/Images/AdminPage/ReviewPerfomanceData.png'
								descriptions={['Access School/District Reports', 'View Student Performance Trends', 'Examine Gap Analyses Charts & Graphs​']}/>
							<Card
								className='organize-tests'
								color='green'
								title='Organize Tests'
								onClicked={() => this.onOrganizeTestsClicked()}
								imagePath='//s3.amazonaws.com/ESGI/Images/AdminPage/OrganizeTests.png'
								descriptions={['Create Subject Tabs', 'Publish to Grade Level Teachers', 'Add District or School Custom Tests​​​']}/>
							<Card
								className='access-preloaded-tests'
								color='purple'
								title='Access Preloaded Tests'
								onClicked={() => this.onAccessPreloadedTestsClicked()}
								imagePath='//s3.amazonaws.com/ESGI/Images/AdminPage/AccessPreloadedTests.png'
								descriptions={['Find Standards-Aligned & Skills Tests', 'Copy & Edit Existing Tests​', 'Create Your Own Tests​']}/>
						</div>
						<div className='admin-support'>
							<div className='support-part left-part'>
								<a className='logo video-logo' id='started-video-link'>
									<svg width='185' height='118' viewBox='0 0 185 118' fill='none'
									     xmlns='http://www.w3.org/2000/svg'>
										<g filter='url(#filter0_ddd)'>
											<path
												d='M18.4202 98.9775L5 4L179.014 20.7196C179.014 20.7196 181.233 55.0241 179.014 82.7472L119.529 91.0278L105.383 111L96.8898 91.0278L18.4202 98.9775Z'
												fill='#FAB124'/>
										</g>
										<circle cx='151' cy='54' r='17' fill='white'/>
										<path d='M147 45V63.9189L162.231 54.4595L147 45Z' fill='#FAB124'/>
										<defs>
											<filter id='filter0_ddd' x='0' y='0' width='185' height='118'
											        filterUnits='userSpaceOnUse'
											        colorInterpolationFilters='sRGB'>
												<feFlood floodOpacity='0' result='BackgroundImageFix'/>
												<feColorMatrix in='SourceAlpha' type='matrix'
												               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/>
												<feOffset dy='1'/>
												<feGaussianBlur stdDeviation='2.5'/>
												<feColorMatrix type='matrix'
												               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
												<feBlend mode='normal' in2='BackgroundImageFix'
												         result='effect1_dropShadow'/>
												<feColorMatrix in='SourceAlpha' type='matrix'
												               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/>
												<feOffset dy='3'/>
												<feGaussianBlur stdDeviation='2'/>
												<feColorMatrix type='matrix'
												               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/>
												<feBlend mode='normal' in2='effect1_dropShadow'
												         result='effect2_dropShadow'/>
												<feColorMatrix in='SourceAlpha' type='matrix'
												               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/>
												<feOffset dy='2'/>
												<feGaussianBlur stdDeviation='2'/>
												<feColorMatrix type='matrix'
												               values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/>
												<feBlend mode='normal' in2='effect2_dropShadow'
												         result='effect3_dropShadow'/>
												<feBlend mode='normal' in='SourceGraphic'
												         in2='effect3_dropShadow' result='shape'/>
											</filter>
										</defs>
									</svg>
									<span>
										Let’s Get<br/>
                                            Started
                                        </span>
								</a>
								<div className={`${this.state.videoHidden && 'hidden' || ''}`}
								     id='started-video-wrapper'>
									<div className='video-content'>
										<video
											width='100%'
											height='100%'
											controls
											preload='none'
											controlsList='nodownload'
											disablePictureInPicture
											ref={this.videoRef}
										>
											<source
												src='https://s3.amazonaws.com/esgiwebfiles/Videos/Get_to_Know_Your_Admin_Account_in_5_Minutes.mp4'
												type='video/mp4'/>
										</video>
									</div>
								</div>
								<aside>
									<a id='overview-video-link' href='#'><span>Play 5 Minute Overview</span></a>
									<a href='https://forms.office.com/Pages/ResponsePage.aspx?id=nusOTCzlh0uyb73dsHS5_6qa3WD6NtlIqWhdXIAfVVZUMkVJTkJGM1U0QjY5WkhER0xXQU9RWTNaNSQlQCN0PWcu' rel='noreferrer' target='_blank'><span>Request Product Training</span></a>
								</aside>
							</div>
							<div className='support-part  right-part'>
								<div className='logo contact-us-logo'>
									<svg width='141' height='122' viewBox='0 0 141 122' fill='none'
									     xmlns='http://www.w3.org/2000/svg'>
										<path fillRule='evenodd' clipRule='evenodd'
										      d='M58 92C90.0325 92 116 71.4051 116 46C116 20.5949 90.0325 0 58 0C25.9675 0 0 20.5949 0 46C0 59.1353 6.94177 70.9848 18.0744 79.3666L1.48741 99.9111L34.991 88.2383C42.0451 90.6586 49.825 92 58 92Z'
										      fill='#2F4DBA'/>
										<path fillRule='evenodd' clipRule='evenodd'
										      d='M101 118.774C79.4609 118.774 62 105.169 62 88.387C62 71.6047 79.4609 58 101 58C122.539 58 140 71.6047 140 88.387C140 96.1806 136.234 103.289 130.043 108.668L141 122L120.151 114.864C114.494 117.353 107.96 118.774 101 118.774Z'
										      fill='#3E94D6'/>
									</svg>
									<span className='contact'>Contact</span>
									<span className='us'>Us</span>
								</div>
								<aside>
									<a onClick={() => this.openLivechat()}><span>Start a Chat</span></a>
									<a href='#'
									   onClick={() => this.setState({contactUs: true})}><span>Contact Support</span></a>
									<a href='https://www.esgisoftware.com/support' rel='noreferrer'
									   target='_blank'><span>Visit Support Pages</span></a>
								</aside>
							</div>
						</div>
					</div>
				</CenterPanel>
			</Layout>
			<BackgroundDownloadManager/>
			<div className='admin-footer'/>
			{this.state.contactUs &&
				<ContactUs onClose={() => this.setState({contactUs: false})}
				           ticketSubmitted={() => this.setState({contactUs: false})}/>}
		</div>;
	}
}
