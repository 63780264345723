import {AppEvent} from '@esgillc/events';
import {SubjectType, TestType} from '@esgi/core/enums';

@AppEvent({broadcast: true})
export class SubjectRemovedEvent {
	constructor(public id: number, public type: SubjectType) {
	}
}

@AppEvent({broadcast: true})
export class SubjectReorderedEvent {
	constructor(public ids: number[], public type: SubjectType) {
	}
}

@AppEvent({broadcast: true})
export class TestsReorderedByMSATEvent {
	constructor(public subjectID: number, subjectType: SubjectType, public testIDs: number[]) {
	}
}

@AppEvent({broadcast: true})
export class TestMovedToSubjectByMSATEvent {
	constructor(
		public testID: number,
		public newSubjectID: number,
		public testName: string,
		public prevSubjectID: number,
		public orderNumber: number,
		public creatorID: number,
		public type: TestType,
	) {
	}
}
