import React from 'react';
import {IFormControlValidatorResult} from './form-controls';

class Props {
    label?: string;
    values: DropdownInModel[];
    value: DropdownInModel;
    touched?: boolean;
    validator?: (value: any) => IFormControlValidatorResult;
    onClick: (value: DropdownInModel, valid: boolean) => void;
    actionButton?: () => any;
    className?: string;
    selectClassName?: string;
    disabled?: boolean;
}

export class DropdownInModel {
    id: number;
    value: string;
    disabled?: boolean;
}
/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class Dropdown extends React.PureComponent<Props> {
	handleSelectOnChange(e: any) {
		const value = e.target.value;
		let selectModel = this.props.values.filter((x) => x.id == value)[0];
		this.props.onClick(selectModel, this.props.validator ? this.props.validator(value).valid : true);
	}

	renderValidatingControl = () => {
		const validationResult = this.props.validator(this.props.value);
		let className = this.props.className ? ' ' + this.props.className : '';
		return <div
            className={this.props.touched && !validationResult.valid ? 'form-group has-error' + className : 'form-group' + className}>
            {this.props.label && <label className='top-label'>{this.props.label}</label>}
            {this.props.actionButton && this.props.actionButton()}
            <select disabled={this.props.disabled} onChange={(e) => this.handleSelectOnChange(e)}
                    value={this.props.value && this.props.value.id}
                    className={'form-control' + (this.props.selectClassName ? ' ' + this.props.selectClassName : '')}>
                {this.props.values.map((item) => {
                    return <option key={item.id} disabled={item.disabled} value={item.id}>{item.value}</option>;
                })}</select>
            {this.props.touched && !validationResult.valid ?
                <div className='text-danger'>{validationResult.message}</div> : ''}
        </div>;
	}

	renderNotValidatingControl = () => {
		return <div className={'form-group ' + this.props.className}>
            {this.props.label && <label className='top-label'>{this.props.label}</label>}
            {this.props.actionButton && this.props.actionButton()}
            <select disabled={this.props.disabled} onChange={(e) => {
                this.handleSelectOnChange(e);
            }} value={this.props.value.id}
                    className={'form-control' + (this.props.selectClassName ? ' ' + this.props.selectClassName : '')}>{this.props.values.map((item) => {
                return <option key={item.id} disabled={item.disabled} value={item.id}>{item.value}</option>;
            })}</select>
        </div>;
	}

	render() {
		return <>
			{this.props.validator ? this.renderValidatingControl() : this.renderNotValidatingControl()}
		</>;
	}
}
