import {useMemo} from 'react';
import {ConflictLevel} from 'shared/modules/student-roster-upload-tool/types';
import {ExclamationMarkWarningIcon} from 'shared/modules/student-roster-upload-tool/icons/exclamation-mark-warning-icon';
import {ExclamationMarkErrorIcon} from 'shared/modules/student-roster-upload-tool/icons/exclamation-mark-error-icon';
import {BellIcon} from 'shared/modules/student-roster-upload-tool/icons/bell-icon';

interface Props {
	conflictLevel: ConflictLevel;
}

export function RenderConflictIcon({conflictLevel}: Props) {

	const renderConflictIcon = useMemo(() => {
		switch (conflictLevel) {
			case ConflictLevel.Warning: {
				return <ExclamationMarkWarningIcon/>;
			}
			case ConflictLevel.RequireChange: {
				return <ExclamationMarkErrorIcon/>;
			}
			case ConflictLevel.Notification: {
				return <BellIcon width={'12'} height={'16'}/>;
			}
			default:
				return null;
		}
	}, [conflictLevel]);

	return <div data-cy={'conflict-icon'}>
		{renderConflictIcon}
	</div>;
}