import * as React from 'react';
import type {SVGProps} from 'react';

export function Parse(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path fillRule='evenodd' clipRule='evenodd' d='M4.875 5C4.39174 5 4 5.38374 4 5.85714C4 6.33054 4.39174 6.71429 4.875 6.71429L10.125 6.71429C10.6083 6.71429 11 6.33054 11 5.85714C11 5.38374 10.6083 5 10.125 5L4.875 5ZM4.875 8.42857C4.39174 8.42857 4 8.81231 4 9.28571C4 9.75911 4.39174 10.1429 4.875 10.1429H6.625C7.10826 10.1429 7.5 9.75911 7.5 9.28571C7.5 8.81231 7.10826 8.42857 6.625 8.42857H4.875Z' fill='currentColor'/>
			<path d='M4 12.7143C4 12.2409 4.39174 11.8571 4.875 11.8571H6.625C7.10826 11.8571 7.5 12.2409 7.5 12.7143C7.5 13.1877 7.10826 13.5714 6.625 13.5714H4.875C4.39174 13.5714 4 13.1877 4 12.7143Z' fill='currentColor'/>
			<path d='M4 16.1429C4 15.6695 4.39174 15.2857 4.875 15.2857H6.625C7.10826 15.2857 7.5 15.6695 7.5 16.1429C7.5 16.6163 7.10826 17 6.625 17H4.875C4.39174 17 4 16.6163 4 16.1429Z' fill='currentColor'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M18.234 12.9813C18.234 10.7825 16.3908 9 14.117 9C11.8432 9 10 10.7825 10 12.9813C10 15.1801 11.8432 16.9625 14.117 16.9625C14.8674 16.9625 15.571 16.7684 16.1769 16.4291L18.5944 18.7668C18.9159 19.0777 19.4373 19.0777 19.7588 18.7668C20.0804 18.4558 20.0804 17.9517 19.7588 17.6407L17.4106 15.3704C17.9276 14.7049 18.234 13.8777 18.234 12.9813ZM11.8298 12.9813C11.8298 11.7597 12.8538 10.7694 14.117 10.7694C15.3802 10.7694 16.4042 11.7597 16.4042 12.9813C16.4042 14.2028 15.3802 15.1931 14.117 15.1931C12.8538 15.1931 11.8298 14.2028 11.8298 12.9813Z' fill='currentColor'/>
		</svg>

	);
}
