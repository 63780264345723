export function mapToEnum<T>(value: string | number, targetEnum: T) {
	if(typeof value === 'string') {
		return targetEnum[value];
	} else {
		return value;
	}
}

export function enumRemap<T>(value: number | string, targetEnum: T) {
	return targetEnum[value];
}
