import {BaseService} from '@esgi/core/service';
import {ImageData, ImageResponse} from 'modules/forms/students-form/types';
import {tap} from 'rxjs/operators';
import {getUser} from '@esgi/core/authentication';

export class UploadPhotoService extends BaseService {

	private globalSchoolYearID = getUser().globalSchoolYearID;

	public save(imageFileData: ImageData, studentID: number) {
		const form = new FormData();
		form.append('Image', imageFileData.image ?? null);
		form.append('ImageCrop', imageFileData.croppedImage);
		form.append('Crop.X', imageFileData.cropParameters.x.toString());
		form.append('Crop.Y', imageFileData.cropParameters.y.toString());
		form.append('Crop.Zoom', imageFileData.cropParameters.zoom?.toString() || '1');
		form.append('studentID', studentID.toString());
		form.append('GlobalSchoolYearID', this.globalSchoolYearID.toString());
		return this.httpClient.ESGIApi.post<{ value: ImageResponse }>('modules/forms/student/profile/photo', 'save', form);
	}

	public removeImage(studentID) {
		return this.httpClient.ESGIApi.post('modules/forms/student/profile/photo', 'delete', {studentID}).pipe(tap());
	}
}
