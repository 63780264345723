export function MainStepTemplate() {
	return <div className='main' data-bind='var: {step: $data}'>
		<table className='table table-hover products'>
			<tbody data-cy='renewal-table'>
			<tr data-cy='headers-row'>
				<th data-cy='services-column-header'>Services</th>
				<th data-cy='students-column-header' className='studentsCol'>Students</th>
				<th data-cy='expiration-column-header' className='expirationCol'>Expiration</th>
			</tr>

			<ko data-bind='foreach: products'>
				<tr data-cy='renewal-row'>
					<td data-cy='service-name'>
						<span data-bind='text: name'/>
					</td>
					<td data-bind='text: step.students' data-cy='students-limit'></td>
					<td className='expiration-cell' data-bind="css: {'expired': expired}" data-cy='expiration-date'>
						<i className='fa fa-warning'></i> <span
						data-bind="text: expirationDate.format('MM/DD/YY')"></span>
					</td>
				</tr>
			</ko>
			<tr>
				<td data-cy='promo-code'>
					<ko data-bind='with: promoCode'>
						<div className='promocode'
						     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation, 'only-hidden' : !$parent.allowUsePromoCode }, afterRender: true">
							<label htmlFor='pcode'>Have a Promo code?</label>
							<input id='pcode' placeholder='Promo Code' data-bind='value: value, disable: disabled'/>
							<span data-bind='click: $parent.view.modifyPromoCode'>
								<svg width='14' height='14' viewBox='0 0 14 14' fill='none'
								     xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M0.25 10.9375V13.75H3.0625L11.3575 5.45498L8.545 2.64248L0.25 10.9375ZM13.5325 3.27998C13.825 2.98748 13.825 2.51498 13.5325 2.22248L11.7775 0.467483C11.485 0.174983 11.0125 0.174983 10.72 0.467483L9.3475 1.83998L12.16 4.65248L13.5325 3.27998Z'
									fill='#0088CC'/>
								</svg>
							</span>
						</div>
					</ko>
				</td>
				<td data-bind='if: isAllowRenewAction'>
					<div className='btn-students-wrapper'>
						<a href='#' className='btn btn-transparent btn-primary btn-add' data-cy='add-students-button'
						   data-bind='click: view.addStudents, disabled: !isAddStudentsEnabled'>
							<svg className='icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20'
							     viewBox='0 0 20 20' fill='none'>
								<path
									d='M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
									fill='#0088CC'/>
							</svg>
							Add Students
						</a>
					</div>
				</td>
				<td data-bind='if: isAllowRenewAction'>
					<button className='btn btn-success btn-time' data-bind='click: view.addTime' data-cy='buy-renew-button'>BUY / RENEW</button>
				</td>
			</tr>
			<ko data-bind='if: acEnabled'>
				<tr className='activationcode'>
					<td data-cy='extend-my-account-label'>
						Extend my account:
					</td>
					<td colSpan={2}>
						<button className='btn have-ac-btn' data-bind='click: view.applyActivationCode' data-cy='add-activation-code-button'>Add My
							Activation Code
						</button>
					</td>
				</tr>
			</ko>
			</tbody>
		</table>
	</div>
}
