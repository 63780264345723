import {useEffect, useState} from 'react';
import {Drawer} from '@esgi/main/kits/common';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {TestSessionResultsService} from './service';
import {TestSessionResultsView} from '../../../../test-session-results-view';
import {SettingsTab, TestSessionResult} from '../../../../../types';
import {Text} from '@esgi/ui/typography';
import {ContentWrapper, PanelContent} from '../../../index.styled';
import {OutModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/teacher/init/out-model';

export function ExpandedTestSessionResults({
	showTestSessionResults,
	onUpdateInitData,
	onCloseDrawer,
	onUpdateTabTouchedData,
}: {
	showTestSessionResults: boolean;
	onUpdateInitData: (props: Partial<OutModel>) => void;
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData?: (tab: SettingsTab, isTouched: boolean) => void;
}) {
	const service = useService(TestSessionResultsService);
	const isShowTestSessionResult = useBehaviorSubject(
		service.isShowTestSessionResult,
	);

	const [isDisableButton, setIsDisableButton] = useState(false);

	useEffect(() => {
		service.init(showTestSessionResults);
	}, []);

	const changeSessionResult = (value: TestSessionResult) => {
		service.isShowTestSessionResult.next(
			value === TestSessionResult.InProgress,
		);

		const isTouched = !(service.isShowTestSessionResult.getValue() === showTestSessionResults);

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.TestSessionResults, isTouched);
	};

	const onSave = () => {
		setIsDisableButton(true);
		service.save().subscribe({error: () => setIsDisableButton(false), next: () => {
			onUpdateInitData({showTestSessionResults: service.isShowTestSessionResult.getValue()});
			onUpdateTabTouchedData(SettingsTab.TestSessionResults, false);
		}});
	};

	return (
		<>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={onSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
				data-cy='test-results-header'
			>
				<Text size='large' color='neutral24' bold data-cy='test-results-title'>
					Test Session Results
				</Text>
			</Drawer.PanelHeader>
			<PanelContent dataCy='test-result-content'>
				<ContentWrapper>
					<Drawer.ContentBlock title='Test Session Results View' withDivider>
						<TestSessionResultsView
							testSessionResult={
								isShowTestSessionResult
									? TestSessionResult.InProgress
									: TestSessionResult.Success
							}
							testSessionProgress={{correctAnswers: 33, questions: 45}}
							onChange={(v) => changeSessionResult(v)}
						/>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</>
	);
}
