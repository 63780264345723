import {useService} from '@esgi/core/service';
import {TestDashboard} from './service';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Root} from './index.styled';
import {isUndefined, useBehaviorSubject} from '@esgi/ui';
import {useTestDashboardContext} from '../layout/outlet-context';
import {Text} from '@esgi/ui/typography';
import {StudentsStats} from './components/students-stats';
import {Subscription} from 'rxjs';
import {isNull, noop} from 'underscore';
import {Header} from './components/header/index';
import {TestInfo} from '../kit/tets-info';
import {OpenTestingStudentParams, SectionData, TestLauncherState} from './types';
import {useEventEffect} from '@esgillc/events';
import {
	TestSessionRubricCreatedEvent,
	TestSessionScoreCreatedEvent,
	TestSessionUpdated,
	TestSessionYNCreatedEvent,
} from '@esgi/main/features/teacher/test-session-details';
import {AnswerState, TestType} from '../types';
import {useNavigate} from 'react-router-dom';
import {Testing, TestLauncherData, TestSavedEvent} from '@esgi/main/features/assessments';
import {TestContentArea} from '@esgi/main/kits/common';
import {routes} from '@esgi/main/libs/core';
import {getStudentClass, ScrollArea} from '../kit';

export function ClassGroupTestDashboard() {
	const [testLauncherState, setTestLauncherState] = useState<TestLauncherState | null>(null);

	const navigate = useNavigate();

	const {
		testId,
		subjectID,
		subjectType,
		classId,
		groupId,
		studentsList,
		classesList,
		groupsList,
		isTablet,
		initVisibleSection,
	} = useTestDashboardContext();

	const initRequestSubscription = useRef<Subscription | null>(null);

	const service = useService(TestDashboard);

	const [isInitialized, setIsInitialized] = useState(false);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	useEventEffect(TestSessionYNCreatedEvent, () => {
		fetchData();
	});

	useEventEffect(TestSessionScoreCreatedEvent, () => {
		fetchData();
	});

	useEventEffect(TestSessionRubricCreatedEvent, () => {
		fetchData();
	});

	useEventEffect(TestSessionUpdated, () => {
		fetchData();
	});

	const {sectionName, selectedStudentsIDs} = useMemo<SectionData>(() => {
		if (!isNull(classId)) {
			if (classId === -1) {
				return {
					selectedStudentsIDs: studentsList.map(({id}) => id),
					sectionName: 'All Classes',
				};
			}

			const selectedClass = classesList.find(({id}) => classId === id);

			return {
				selectedStudentsIDs: selectedClass?.studentIDs ?? [],
				sectionName: selectedClass?.name ?? '',
			};
		}

		if (!isNull(groupId)) {
			const selectedGroup = groupsList.find(({id}) => groupId === id);

			return {
				sectionName: selectedGroup?.name ?? '',
				selectedStudentsIDs: selectedGroup?.studentIDs ?? [],
			};
		}

		return {
			selectedStudentsIDs: [],
			sectionName: '',
		};
	}, [classId, classesList, groupId, groupsList, studentsList]);

	useEffect(() => {
		fetchData({withSkeleton: true});

		return () => initRequestSubscription.current?.unsubscribe();
	}, [selectedStudentsIDs, subjectID, subjectType, testId]);

	const fetchData = useCallback(
		(params?: {withSkeleton?: boolean}) => {
			const {withSkeleton} = params ?? {withSkeleton: false};

			if (withSkeleton) {
				setIsDataLoaded(false);
			}

			initRequestSubscription.current?.unsubscribe();

			initRequestSubscription.current = service
				.init({studentIds: selectedStudentsIDs, subjectID, subjectType, testID: testId})
				.subscribe(() => {
					if (!isInitialized) {
						setIsInitialized(true);
					}

					if (withSkeleton) {
						setIsDataLoaded(true);
					}
				});
		},
		[isInitialized, selectedStudentsIDs, service, subjectID, subjectType, testId],
	);

	const testInfo = useBehaviorSubject(service.testInfo);
	const studentsInfo = useBehaviorSubject(service.studentsInfo);
	const trackDates = useBehaviorSubject(service.trackDates);
	const gradeRanges = useBehaviorSubject(service.gradeRanges);
	const studentSort = useBehaviorSubject(service.studentSort);

	useEventEffect(
		TestSavedEvent,
		({data: {testSessionID, studentID}}) => {
			service.getSessionDataRequest({testSessionID}).subscribe(({testSession}) => {
				service.addTestSession({sessionData: testSession, studentID});
			});
		},
		[],
	);

	const onOpenTestingStudent = useCallback(
		({
			studentID,
			averageStudentSessionsDuration,
			studentSessionsCount,
			lastTestSession,
			penultimateTestSession,
		}: OpenTestingStudentParams) => {
			const studentClass = getStudentClass({
				classID: classId,
				classesList,
				groupID: groupId,
				groupsList,
				studentID,
			});

			const student = studentsList.find(({id}) => id === studentID);

			if (!isNull(studentClass) && !isUndefined(student) && !isNull(testInfo)) {
				const commonData: Pick<TestLauncherData, 'contentArea' | 'testType' | 'testID'> = {
					contentArea: TestContentArea[testInfo.contentArea],
					testType: testInfo.testType,
					testID: testId,
				};

				if (isNull(lastTestSession)) {
					setTestLauncherState({
						studentClass,
						student,
						data: {
							...commonData,
							studentResult: null,
							analyticsData: null,
						},
					});

					return;
				}

				const deltaRubric =
					testInfo.testType === TestType.Rubric
						? lastTestSession.correctAnswers - (penultimateTestSession?.correctAnswers ?? 0)
						: null;

				const correctAnswersYn = lastTestSession.answers.reduce((currentSum, {answerState}) => {
					if (answerState === AnswerState.Correct) {
						return currentSum + 1;
					}

					return currentSum;
				}, 0);

				const lastTestSessionResultByTestType: Record<TestType, number> = {
					[TestType.YN]: Math.round((correctAnswersYn / testInfo.totalPossible) * 100),
					[TestType.Score]: Math.round((lastTestSession.correctAnswers / testInfo.totalPossible) * 100),
					[TestType.Rubric]: 0,
					[TestType.None]: 0,
				};

				setTestLauncherState({
					studentClass,
					student,
					data: {
						...commonData,
						studentResult: {
							correctAnswers: lastTestSession.correctAnswers,
							lastTestSessionRubricAnswers: lastTestSession.rubricAnswers,
							result: lastTestSessionResultByTestType[testInfo.testType],
							delta: deltaRubric ?? 0,
						},
						isIncorrectDisabled: lastTestSession.correctAnswers === testInfo.totalPossible,
						analyticsData: {
							total: testInfo.totalPossible,
							averageSessionDuration: averageStudentSessionsDuration ?? 0,
							lastSessionDate: new Date(lastTestSession.testDate),
							sessionCount: studentSessionsCount,
						},
					},
				});
			}
		},
		[classId, classesList, groupId, groupsList, studentsList, testId, testInfo],
	);
	
	const handleTest = useCallback(()=> {
		fetchData();
	}, [fetchData]);

	const onCloseTestLauncher = useCallback(() => {
		setTestLauncherState(null);
	}, []);

	if (!isInitialized) {
		return (
			<Root>
				<Text size='medium' bold>
					Loading...
				</Text>
			</Root>
		);
	}

	return (
		<Root dataCy='test-dashboard'>
			<Header sectionName={sectionName} onDownload={() => null} subjectID={subjectID} />

			<ScrollArea isTablet={isTablet} initVisibleSection={initVisibleSection}>
				<TestInfo testID={testId} testInfo={testInfo} onTestEdited={handleTest} />
				<StudentsStats
					isDataLoaded={isDataLoaded}
					studentsInfo={studentsInfo}
					testInfo={testInfo}
					trackDates={trackDates}
					studentSort={studentSort}
					isTablet={isTablet}
					onOpenTestingStudent={onOpenTestingStudent}
				/>
			</ScrollArea>

			{!isNull(testLauncherState) && !isNull(testInfo) && (
				<Testing
					onFlashCardsClicked={() => navigate(routes.teacher.activities.flashcards)}
					onTestSessionDetailsClicked={noop}
					student={testLauncherState.student}
					subject={{
						id: subjectID,
						type: subjectType,
						name: testInfo.subjectName,
					}}
					studentClass={testLauncherState.studentClass}
					launcherData={testLauncherState.data}
					onClose={onCloseTestLauncher}
				/>
			)}
		</Root>
	);
}
