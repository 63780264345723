import {Class} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/classes/class';
import {Group} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/groups/group';
import {
	Student,
} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/students/student';

export enum TabID {
	Classes = 'classes',
	Groups = 'groups',
	Students = 'students',
}

export type LoadedInfo = {
	classes: false,
	groups: false,
	students: false,
};

export type Entities = {
	classes: Class[],
	students: Student[],
	groups: Group[],
	loaded?: LoadedInfo,
}

export type SingleSelectedState = {
	classID: number,
	groupID: number,
	studentID: number,
}

export type MultipleSelectedState = {
	classIDs: number[],
	groupIDs: number[],
	studentIDs: number[],
}

export enum SectionMode {
	View = 'view',
	Edit = 'edit',
	Rearrange = 'rearrange',
	Add = 'add',
}

export type ModePermissions = {
	canAdd: boolean,
	canEdit: boolean,
	canRearrange: boolean,
}