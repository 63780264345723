import React from 'react';
import {Text} from '@esgi/ui/typography';
import {ToggleOption} from './types';
import {StyledToggleGroupRoot, StyledToggleGroupContent, StyledToggleGroupItem} from './index.styled';

type Props<T extends string | number> = {
	itemList: ToggleOption<T>[];
	selectedItem: T;
	onToggleItem: (value: string) => void;
}

export function ToggleGroup<T extends string | number>({itemList, selectedItem, onToggleItem}: Props<T>) {
	return (
		<StyledToggleGroupRoot value={String(selectedItem)} onValueChange={onToggleItem}>
			<StyledToggleGroupContent>
				{itemList.map((item) => (
					<StyledToggleGroupItem key={item.value} value={String(item.value)} disabled={item.disabled}>
						{item.content && (
							<Text size='xxLarge' role='img' aria-label={String(item.value)}>
								{item.content}
							</Text>
						)}
						{item.icon && (
							<Text size='xLarge' role='img' aria-label={String(item.value)}>
								<item.icon height={24} width={24} fill='currentColor' />
							</Text>
						)}
						<Text size='medium'>{item.label}</Text>
					</StyledToggleGroupItem>
				))}
			</StyledToggleGroupContent>
		</StyledToggleGroupRoot>
	);
}