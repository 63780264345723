import {ComponentPropsWithoutRef, ReactNode, forwardRef, useMemo} from 'react';
import {BaseComponentProps, Skeletonable} from '../../types';
import {Placeholder, TextareaStyled, Wrapper, ErrorWrapper} from './index.styled';
import {generateRandomString} from '@esgillc/ui-kit/utils';
import {Text} from '@esgi/ui/typography';
import {Box} from '../../layout/box';

export type TextareaProps = BaseComponentProps &
	Skeletonable &
	Omit<ComponentPropsWithoutRef<'textarea'>, 'value' | 'onChange'> & {
		/** Ability to manual resize */
		resize?: boolean;

		/** Render Error Message. If prop is passed, error mode styles would enable. */
		error?: string | ReactNode;

		/** The value of the textarea. */
		value: string;

		/** Change event handler. */
		onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	(
		{
			dataCy = 'ui-kit-textarea',
			css = {},
			placeholder,
			id = 'ui-kit-textarea-id-' + generateRandomString(),
			autoComplete,
			value,
			onChange,
			required,
			resize = false,
			children,
			error,
			skeleton,
			className,
			...props
		},
		forwardedRef,
	) => {
		const errorContent = useMemo(() => {
			if (!error) {
				return null;
			}

			if (typeof error === 'string') {
				return (
					<Text size='xSmall' font='mono'>
						{error}
					</Text>
				);
			}

			return error;
		}, [error]);

		return (
			<>
				<Wrapper valued={Boolean(value)} withError={Boolean(error)} css={css} className={className} data-textarea-box>
					{placeholder && (
						<Placeholder data-cy={`${dataCy}-placeholder`} font='mono' data-textarea-placeholder>
							{placeholder}
						</Placeholder>
					)}
					<TextareaStyled
						data-cy={dataCy}
						autoComplete={autoComplete}
						value={value}
						onChange={onChange}
						placeholder={undefined}
						resize={resize}
						required={required}
						// data-attribute for disabling lastPass prompts
						data-lpignore={autoComplete === 'off' ? true : undefined}
						aria-required={Boolean(required)}
						{...props}
						ref={forwardedRef}
					/>
				</Wrapper>

				{errorContent && (
					<ErrorWrapper data-cy={`${dataCy}-error`} data-textarea-error>
						{errorContent}
					</ErrorWrapper>
				)}

				{children && <Box data-children-box>{children}</Box>}
			</>
		);
	},
);
