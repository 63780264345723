import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import styles from './add-level-button.module.less';

interface Props {
	readonly: boolean;
	levelsCount: number;
	onClicked: () => void;
}

export default class AddLevelButton extends React.PureComponent<Props> {
	public render() {
		return <div className={join(styles.levelAddButton, this.props.readonly && styles.readonly)}
		            tabIndex={0}
		            onClick={() => !this.props.readonly && this.props.onClicked()}>
			<div className={styles.levelCounter}>
				<span>{this.props.levelsCount}</span>
			</div>
			<div className={styles.icon}>
				<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z' fill='#0077B8'/>
				</svg>
			</div>
		</div>;
	}
}
