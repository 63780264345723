import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {UntestedStudentsReportService} from '../../../service';
import styles from '../../modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService;
}

export function ReportGroup({service}: Props) {
	const groups = useBehaviorSubject(service.groups);
	const selectedGroup = useBehaviorSubject(service.selectedGroup);

	return groups.length > 0 &&
		<div>
			<span className={styles.bold}>Group:</span>
			{groups.length > 1 &&
				<Dropdown value={selectedGroup} setValue={service.onGroupChanged} optionName='name' autoWidth={true}>
					{groups.map(group => <Option key={group.groupID} value={group}>{group.name}</Option>)}
				</Dropdown>
			}
			{groups.length == 1 &&
				<span className={styles.name}>{selectedGroup[0]?.name}</span>
			}
		</div>;
}
