import {Student} from '@esgi/main/libs/store';

export enum FormatType {
	FirstName,
	FirstAndLastName
}

export interface LecturerType {
	firstName: string;
	lastName: string;
	title: string;
}

export enum FontFamily {
	Normal,
	Lined,
	DottedLined,
	Blank
}

export class Rectangle {
	width: number;
	height: number;

	constructor(width: number, height: number) {
		this.width = width;
		this.height = height;
	}
}

export interface FontSizeCalculationResult {
	fontSize: number;
	width: number;
	height: number;
}

export interface UnitData {
	id: number;
	type: UnitType;
	name?: string;
}

export interface StudentWithUnit {
	studentId: number;
	unitData: UnitData,
}

export enum UnitType {
	Class,
	Group,
	None,
}

interface StudentWithUnitType extends Student {
	unitData: UnitData,
}

export interface UnitDataSettings {
	students: StudentWithUnitType[];
	lecturer: LecturerType;
}

export interface PrintSettings {
	nameFormat: FormatType;
	selectedFontSize: string;
	copiesCount: number;
	fontFamily: FontFamily;
	wordsPerPage: number;
}

export enum FontSizeType {
	Small = '30pt',
	Medium = '40pt',
	Large = '62pt',
}

export enum FontStyleType {
	Default = 'Trace with guidelines',
}

export interface FontType {
	fontSize: FontSizeType,
	fontStyle: FontStyleType,
}

export type OptionItem = {
	value: FontSizeType | FontStyleType;
	label: string;
};

export enum FileTypeType {
	Zip = 'zip',
	Pdf = 'pdf'
}

export interface DownloadPDFRequest {
	documentDate: string;
	lecturerName: string;
	copies: number;
	students: DownloadPDFStudent[];
}

export interface DownloadPDFStudent {
	fullName: string;
	bodyName: string;
	bodyFontSize: number;
	titleNameFontSize: FontFamily;
	linesCount: number;
	unitType: UnitType;
	unitName: string;
}

export interface DownloadPDFResponse {}
