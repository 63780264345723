import {Text} from '../../kit';

type TableHeaderProps = {
	headerText: string;
};

export const TableHeader = ({headerText}: TableHeaderProps) => (
	<Text color='black' size='12px' as='span'>
		{headerText}
	</Text>
);
