import React from 'react';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {EventBusManager} from '@esgillc/events';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {Question, TemplateType, Test} from '../../../../models';
import DataService from '../../../../services/data-service';
import CloseButton from '../../../close-button/close-button';
import {
	AudioButton,
	BackButton,
	NextButton,
	QuestionTemplate,
	getUrl,
	AudioStartPlayEvent,
	AudioEndPlayEvent,
} from '@esgi/selfassess';
import styles from './test-modal.module.less';
import {userStorage} from '@esgi/core/authentication';
import SessionRemovedModal from './components/session-removed-modal/session-removed-modal';

interface TestModalProps {
	closed: () => any;
	dataService: DataService;
	test: Test;
}

class TestModalState {
	question: Question;
	selectedOptionID: number[] = [];
	audioPlaying: boolean = false;
	showSessionRemovedModal: boolean = false;
}

export default class TestModal extends React.Component<TestModalProps, TestModalState> {
	private modalManagerRef: ModalManagerRefObject = React.createRef();
	private destroy$: Subject<void> = new Subject();
	private questionStartTime: number = 0;
	private readonly eventBus = new EventBusManager();
	state = new TestModalState();
	private readonly bucket = userStorage.get().awsEnvBucket;

	componentDidMount(): void {
		this.props.dataService.currentQuestion$
			.pipe(
				takeUntil(this.destroy$),
				tap(_ => this.questionStartTime = new Date().getTime()),
			)
			.subscribe(question => this.setState({question}));

		this.props.dataService.finishTest$
			.pipe(takeUntil(this.destroy$))
			.subscribe(_ => this.onClose());

		this.eventBus.subscribe(AudioStartPlayEvent, () => this.setState({audioPlaying: true}));
		this.eventBus.subscribe(AudioEndPlayEvent, () => this.setState({audioPlaying: false}));
	}

	componentWillUnmount(): void {
		this.destroy$.next();
		this.eventBus.destroy();
	}

	private onSelectOption(id: number) {

		if (this.state.question.correctOptionsCount === 1) {
			return this.setState({selectedOptionID: [id]});
		}

		const options = [...this.state.selectedOptionID];

		if (options.includes(id)) {
			this.setState({selectedOptionID: [...options.filter(t => t !== id)]});
		} else {
			this.setState({selectedOptionID: [...options, id]});
		}
	}

	private nextClicked() {
		const endTime = new Date().getTime();
		const duration = endTime - this.questionStartTime;

		this.props.dataService.nextQuestion(this.state.selectedOptionID, duration)
			.pipe(takeUntil(this.destroy$))
			.subscribe(r => {
				return this.setState({ selectedOptionID: r.optionIDs, showSessionRemovedModal: !r.testFromCacheAvailable });
			});
	}

	private backClicked(): void {
		const optionID = this.props.dataService.previousQuestion();
		this.setState({selectedOptionID: optionID});
	}

	private onClose() {
		this.modalManagerRef.current.close(this.props.closed);
	}

	private get maxOptionsSelected() {
		if (this.state.question.correctOptionsCount === 1) {
			return false;
		}

		return this.state.question.correctOptionsCount === this.state.selectedOptionID.length;
	}

	private get nextButtonDisabled(): boolean {
		if (this.state.audioPlaying) {
			return true;
		}

		if (this.state.question.correctOptionsCount > 1) {
			if (this.state.question.options.length > 3) {
				return this.state.selectedOptionID.length <= 1;
			} else {
				return this.state.question.correctOptionsCount !== this.state.selectedOptionID.length;
			}

		}

		if (this.state.question.templateType === TemplateType.Practice) {
			return false;
		}

		return this.state.selectedOptionID.length === 0;
	}

	private get backButtonDisabled(): boolean {
		if (this.state.audioPlaying) {
			return true;
		}

		return this.props.dataService.isLastQuestion;
	}

	render() {
		const currentQuestionIndex = this.props.test.questions.findIndex(v => v.id === this.state.question?.id);
		return this.state.question && <Modal containerClassName={styles.modalWrapper} className={styles.modal}
		                                     modalManagerRef={this.modalManagerRef}>
			<Modal.Header className={styles.testHeader}>
				<div className={styles.questionContainer}>
					<AudioButton disabled={this.state.audioPlaying} id={this.state.question.id} audioUrl={getUrl(this.bucket, this.state.question.audioDirectionUrl)}/>
					{this.state.question.directionsText && <span dangerouslySetInnerHTML={{__html: this.state.question.directionsText}}/>}
				</div>
				<CloseButton closed={() => this.onClose()}/>
			</Modal.Header>
			<Modal.Body className={styles.testBody}>
				<div className={styles.questionWrapper}>
					<QuestionTemplate
						awsBucket={this.bucket}
						question={this.state.question}
						selectedOptionID={this.state.selectedOptionID}
						onSelectOption={(id) => this.onSelectOption(id)}
						maxOptionsSelected={this.maxOptionsSelected}
						audioPlaying={this.state.audioPlaying}
						questionIndex={currentQuestionIndex}
					/>
				</div>
				{this.state.showSessionRemovedModal && <SessionRemovedModal okClicked={() => {
					window.location.reload();
				}}/>}
			</Modal.Body>
			<Modal.Footer className={styles.testFooter}>
				<BackButton disabled={this.backButtonDisabled} clicked={() => this.backClicked()}/>
				<NextButton disabled={this.nextButtonDisabled} clicked={() => this.nextClicked()}/>
			</Modal.Footer>
		</Modal>;
	}
}
