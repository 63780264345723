import {Text} from '@esgi/ui/typography';
import {TrackModel} from '../../../../../../../../types';
import {ActiveLabel, SelectableTrackButton} from './index.styled';

type Props = {
	tracks: TrackModel[];
	visibleTrackId: TrackModel['trackID'];
	activeUserTrackId: TrackModel['trackID'];
	onTrackClick: (track: TrackModel) => void;
};

export function TrackList({tracks, visibleTrackId, activeUserTrackId, onTrackClick}: Props) {
	return (
		<>
			{tracks.map((track) => (
				<SelectableTrackButton
					selected={track.trackID === visibleTrackId}
					disabled={track.trackID === visibleTrackId}
					onClick={() => onTrackClick(track)}
					key={track.trackID}
				>
					<Text size='large' color='neutral40'>
						{track.name}
					</Text>
					{activeUserTrackId === track.trackID && (
						<ActiveLabel>
							<Text size='small' color='neutral40'>
								Active
							</Text>
						</ActiveLabel>
					)}
				</SelectableTrackButton>
			))}
		</>
	);
}
