import {Avatar} from '@esgi/ui/avatar';
import {Text} from '@esgi/ui/typography';
import {ExistingStudentInfoContainer, StudentFullName} from './index.styled';
import {FlexBox} from '@esgi/ui/layout';

interface ExistingStudentInfo {
	studentIDN: string,
	name: string,
	imageSrc: string,
}

interface Props {
	existingStudentsInfo: ExistingStudentInfo[];
	onViewExistingStudentProfile: (studentIDN: string) => void;
}

export function ExistingStudentsInfo({existingStudentsInfo, onViewExistingStudentProfile}: Props) {

	const getStudentFallback = (fullName: string): string => {
		const names = fullName.trim().split(' ');
		if (names.length >= 2) {
			return `${names[0][0]}${names[1][0]}`;
		}
		return fullName[0];
	};

	return <ExistingStudentInfoContainer align='start'>
		{existingStudentsInfo.map(st => (
			<FlexBox align='center' onClick={() => onViewExistingStudentProfile(st.studentIDN)} key={st.studentIDN}>
				<Avatar.Root size='xs' color='transparent'>
					<Avatar.FilterLayer color='secondary'/>
					<Avatar.Image src={st.imageSrc}/>
					<Avatar.Fallback>
						<Text size='small' color='secondary'>{getStudentFallback(st.name)}</Text>
					</Avatar.Fallback>
					<Avatar.HoverLayer/>
				</Avatar.Root>

				<StudentFullName>
					<Text size='small' color='primary' bold>{st.name}</Text>
				</StudentFullName>
			</FlexBox>
		))}
	</ExistingStudentInfoContainer>;
}
