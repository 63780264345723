import {Body} from './body';
import {Wrapper, Header, CardText} from './index.styled';
import {FlexBox} from '@esgi/ui/layout';
import {Card as CardType} from '../../../../types';

class Props {
	card: CardType;
	studentName: string;
}

export function Card({card, studentName}: Props) {
	return (
		<Wrapper data-cy='card-wrapper'>
			<Header>
				<FlexBox align='center' justify='between'>
					<CardText data-cy='body-student-name' type='studentName' size='small'>{studentName}</CardText>
					<CardText data-cy='body-report-date' type='reportDate' size='small'>{card.reportDate}</CardText>
				</FlexBox>
				<CardText data-cy='body-test-name' type='testName' size='small' bold>{card.testName}</CardText>
			</Header>
			<Body card={card}/>
		</Wrapper>
	);
}
