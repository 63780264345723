import {Buttons} from '@esgillc/ui-kit/button';
import {useState} from 'react';
import IepDatesFormModal from '../../components/iep-dates/index';
import {IEPGoalFormService} from '../../service';

import styles from '../styles.module.less';

type Props = {
	service: IEPGoalFormService;
}

export function IEPDatesButton ({service}: Props) {
	const [isShowed, setIsShowed] = useState(false);

	const onClose = () => {
		setIsShowed(false);
	}

	return <>
		{isShowed &&
			<IepDatesFormModal
				service={service}
				onClose={onClose}
			/>
		}
        <Buttons.Link className={styles.link} onClick={() => setIsShowed(true)}>IEP Dates</Buttons.Link>
	</>
}