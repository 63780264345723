import React, {RefObject} from 'react';
import {Observable} from 'rxjs';

export interface InitEventArgs {
	ref: HTMLElement;
}

export interface OnHeightChangedEventArgs {
	height: number;
}

export interface OnScrollEventArgs {
	e: Event;
	pos: number;
	ref: HTMLElement;
}

export interface ScrollContextState {
	onInit: Observable<InitEventArgs>;
	onHeightChanged: Observable<OnHeightChangedEventArgs>;
	onScroll: Observable<OnScrollEventArgs>;
	expanderRef: RefObject<HTMLDivElement>;
}

const ScrollContext = React.createContext<ScrollContextState>(null);
export default ScrollContext;
