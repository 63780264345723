import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import './style.less';

export class IGModalProps<T> extends SharedProps<T> {
	close: () => any;
}

export class IGModal<S, P extends IGModalProps<S> = IGModalProps<S>> extends SharedComponent<S, P> {
	constructor(props: P) {
		super(props);
		this.keyDown = this.keyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.keyDown);
	}

	private keyDown(e: KeyboardEvent) {
		if (e.code === 'Escape') {
			this.onESC(e);
		}
		if (e.code === 'Enter') {
			this.onEnter(e);
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		document.removeEventListener('keydown', this.keyDown);
	}

	onEnter = (e) => {
	};
	onESC = (e) => {
		this.props.close();
	}
	render(): JSX.Element | false | null {
		return null;
	}
}