import {Group, Student} from '@esgi/main/libs/store';
import {AllOptionItem, GroupItem, OptionType} from './types';

export function getGroupsData({
	groups,
	students,
	selectedClassId,
}: {
	groups: Group[];
	students: Student[];
	selectedClassId: OptionType;
}) {
	const studentIdsInGroups = new Set(groups.map(({studentIDs}) => studentIDs).flat());
	const studentsWithoutGroup: Student[] = [];

	students.forEach((student) => {
		if (!studentIdsInGroups.has(student.id)) {
			studentsWithoutGroup.push(student);
		}
	});

	const groupsWithStudents: GroupItem[] = [];

	groups.forEach(({studentIDs, ...groupData}) => {
		if (groupData.classID === selectedClassId || selectedClassId === AllOptionItem.Value) {
			const groupStudents: Student[] = students.filter(({id}) => studentIDs.includes(id));

			const groupItem: GroupItem = {
				...groupData,
				students: groupStudents,
			};

			groupsWithStudents.push(groupItem);
		}
	});

	return {
		groupsWithStudents,
		studentsWithoutGroup,
	};
}
