import {styled} from '@esgi/ui/theme';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Wrapper = styled(GridBox, {
	overflow: 'hidden',
	gridAutoFlow: '1fr auto',
	gap: '$5',
	alignContent: 'start',
	'& > div': {
		overflow: 'hidden',
	},
});

export const Section = styled(GridBox, {
	overflow: 'hidden',
	gap: '$5',
	'& > button': {
		justifyContent: 'center',
	},
});

export const SelectableListItem = styled(SelectableList.Item, {
	padding: '8px 12px',
});

export const TestListItem = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	height: '40px',
	padding: '6px',
});


export const SubTitle = styled(Text, {
	textAlign: 'center',
	padding: '20px 0px 12px 0px',
	borderBottom: '1px solid $border',
});