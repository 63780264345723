import {ChangeEvent, forwardRef, useCallback} from 'react';
import {Textarea as TextareaBase, TextareaProps} from '../../controls/textarea';
import {ElementStatus, useFormElementContext} from '../../form';
import {useComposedRefs} from '../../utils/hooks';

export const Textarea = forwardRef<HTMLTextAreaElement, Omit<TextareaProps, 'value' | 'onChange' | 'onBlur'>>(
	(props, forwardedRef) => {
		const {status, setValue, value, validateOnBlur, setRef} = useFormElementContext({string: true}, Textarea);

		const handleChange = useCallback(
			(event: ChangeEvent<HTMLTextAreaElement>) => {
				setValue(event.target.value);
			},
			[setValue],
		);

		const textareaRef = useComposedRefs(setRef, forwardedRef);

		const handleBlur = useCallback(validateOnBlur, [validateOnBlur]);

		return (
			<TextareaBase
				value={value}
				onChange={handleChange}
				onBlur={handleBlur}
				disabled={status === ElementStatus.disabled}
				error={status === ElementStatus.invalid}
				aria-invalid={status === ElementStatus.invalid}
				ref={textareaRef}
				{...props}
			/>
		);
	},
);
