import {tryCall} from '@esgillc/ui-kit/utils';
import {FocusEvent, useCallback, useRef, useState} from 'react';
import {Observable, of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Input, InputCleanableProps} from '../../../../kit';
import {debounce} from 'underscore';

type TableCellInputProps = {
	value: string;
	placeholder: string;
	getValidateMessage?: (value: string) => Observable<string | null | undefined>;
	updateUserCredentials?: (value: string) => void;
	hidden?: boolean
	focused?: boolean
};

export function TableCellInput({
	                               value: initialValue,
	                               placeholder,
	                               getValidateMessage,
	                               updateUserCredentials,
																 hidden,
																 focused,
}: TableCellInputProps) {
	const [previousSavedValue, setPreviousSavedValue] = useState(initialValue ?? '');
	const [errorMessage, setErrorMessage] = useState<string>();
	const validation$ = useRef<Subscription>();

	const checkValidateInput = useCallback((value: string, onValidated?: (valid: boolean) => void) => {
		if (validation$.current) {
			validation$.current.unsubscribe();
		}

		validation$.current = of(0).pipe(
			switchMap(() => getValidateMessage?.(value)),
		).subscribe((r) => {
			validation$.current = undefined;
			setErrorMessage(r);
			tryCall(onValidated, !r);
		});
	}, []);

	const onChange = debounce((
		event: FocusEvent<HTMLInputElement>,
		{value}: InputCleanableProps,
	) => {
		if (errorMessage) {
			setErrorMessage(undefined);
		}

		if(value !== previousSavedValue) {
			checkValidateInput(value, (valid) => {
				if (valid) {
					updateUserCredentials?.(value);
					setPreviousSavedValue(value);
				}
			});
		}
	}, 300);

	const onClearValue = (value: string) => {
		checkValidateInput(value, (valid) => {
			if (valid) {
				updateUserCredentials?.(value);
				setPreviousSavedValue(value);
			}
		});
	};

	return (
		<Input.Сleanable
			onChange={onChange}
			errorMessage={errorMessage}
			value={previousSavedValue}
			placeholder={placeholder}
			onClearValue={onClearValue}
			shouldBeHidden={hidden}
			focused={focused}
		/>
	);
}
