import {useOutletContext} from 'react-router-dom';
import {TestDashboardContext} from './types';

export function useTestDashboardContext() {
	const context = useOutletContext<TestDashboardContext | null>();

	if (!context) {
		throw new Error('TestDashboardContext is null');
	}

	return context;
}
