import {Button} from '@esgi/ui';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gap: 12,
	gridTemplateColumns: 'auto 1fr auto',

	variants: {
		moreThanOneLineTitle: {
			true: {
				paddingTop: 8,
				paddingBottom: 8,
				alignItems: 'start',
			},

			false: {
				paddingTop: 4,
				paddingBottom: 4,
				alignItems: 'center',
			},
		},
	},
});

export const CommentButton = styled(Button.Icon, {
	variants: {
		editComment: {
			true: {
				color: '$tertiary',
			},
		},
	},
});

export const Divider = styled(Box, {
	width: '100%',
	height: 1,
	borderRadius: 1,
	backgroundColor: '$border',
});
