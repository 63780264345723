import {
	cardViewItemCSS,
	ContentWrapper,
	FiltersContainer,
	FiltersTabsContainer,
	Layout,
} from './index.styled';
import {Header} from './components/header';
import {Tabs} from './components/tabs';
import {useTestExplorerState} from './hooks/use-test-explorer-state';
import {
	CardViewType,
	DrawerType,
	FilterScope,
	TabItem,
	TestModel,
} from './service/types';
import {ScreenSize, useRootPageContext} from '@esgi/main/kits/common';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {View1, View2} from '@esgi/ui';
import {TestsList} from './components/tests-list';
import {useService, useServiceFactory} from '@esgi/core/service';
import {TestExplorerService} from './service/service';
import TestCreatorLauncher from 'modules/assets/tests/creating-launcher/root';
import AutoTestCreator, {Props as AutoTestCreatorProps} from 'shared/modules/auto-test-creator';
import ImageGalleryModal from 'shared/modules/image-gallery/modal';
import {SelectedTestsBar} from './components/selected-tests-bar';
import {sortByOptions, tabItems} from './constants';
import {FiltersPanel} from './components/filters-panel';
import {AddFilterDrawer} from './components/add-filter-drawer';
import {AddToSubjectDrawer} from './components/add-to-subject-drawer';
import {Snackbar} from '@esgi/ui/snackbar';
import {Subscription} from 'rxjs';
import {useUser} from '@esgi/core/authentication';
import {TestDetailsModal} from './components/test-details-modal';
import {CloseSelectedBarAlert} from './components/close-selected-bar-alert';
import {OverlimitAlert} from './components/overlimit-alert';
import {SubjectTab} from '@esgi/main/libs/store';
import {
	StandardsService,
	useStandardsDrawerState,
	ItemsCounter,
	Select,
	AddStandardsDrawer,
	OptionItem,
	mappedGradeLevels,
} from '@esgi/main/features/standards-drawer';
import {DictionariesContentAreaController} from '@esgi/contracts/esgi';

interface Props {
	subjects: {
		common: SubjectTab[];
		autoTestCreator: AutoTestCreatorProps['subjects'];
	}
	isSubjectsLoaded: boolean;
	onNewSubjectCreate: (selectedTests: TestModel[]) => void;
	isNewSubjectCreateDisabled: boolean;
}

export const TestExplorer = (props: Props) => {
	const contentAreaController = new DictionariesContentAreaController();
	const [contentAreas, setContentAreas] = useState<OptionItem[]>([]);
	const [gradeLevels, setGradeLevels] = useState<OptionItem[]>(mappedGradeLevels);

	const standardsService = useService(StandardsService);
	const testExplorerService = useServiceFactory<TestExplorerService>(
		() => new TestExplorerService(standardsService)
	);
	const requestSubscription = useRef<Subscription | null>(null);
	const {firstName, lastName, userID} = useUser();

	const {
		selectedStandardType,
		selectedStandardsFilter,
		selectedStandardsIDs,
		selectedGradeLevelIDs,
		selectedContentAreaIDs,
		showAddStandardsDrawer,
		showAddStandardsDrawerToggle,
		isContentAreasLoading,
	} = useStandardsDrawerState({service: standardsService, contentAreas, setGradeLevels});

	const {
		selectedSubject,
		showOnlySelectedTests,
		addSelectedToFavoritesChecked,
		hideTestsChecked,
		selectedTestIDs,
		visibleTests,
		subjectSelectingTests,
		testsCount,
		cardView,
		onCardViewChange,
		onNextPage,
		isLoaded,
		drawerIsLoaded,
		scope,
		hasDrafts,
		sortBy,
		showTestCreatorLauncher,
		showTestCreatorLauncherToggle,
		showAutoTestCreatorLauncher,
		showAutoTestCreatorToggle,
		showImageGallery,
		showImageGalleryToggle,
		showFiltersPanel,
		showFiltersPanelToggle,
		notedAuthors,
		notedSeries,
		selectedAdministration,
		showHidden,
		selectedTestTypes,
		selectedNotedSeries,
		selectedNotedAuthors,
		selectedNotedSeriesIDs,
		selectedNotedAuthorsIDs,
		selectedSortDirection,
		showAddSeriesDrawer,
		showAddSeriesDrawerToggle,
		showAddAuthorsDrawer,
		showAddAuthorsDrawerToggle,
		showAddToSubjectDrawer,
		showAddToSubjectDrawerToggle,
		selectedTestDetails,
		showTestDetailsModal,
		showTestDetailsModalToggle,
		showCloseSelectedBarAlert,
		showCloseSelectedBarAlertToggle,
		showOverlimitAlert,
		showOverlimitAlertToggle,
		snackbarRef,
		osRef,
		onTestCreated,
	} = useTestExplorerState({
		service: testExplorerService,
		selectedContentAreaIDs,
		selectedGradeLevelIDs,
	});

	const {screenSize} = useRootPageContext();

	const isSmallScreen = screenSize === ScreenSize.Small;
	const isPortraitTablet = screenSize === ScreenSize.PortraitTablet;
	const isTablet = screenSize === ScreenSize.Tablet;
	const isAnySmallScreen = isTablet || isPortraitTablet || isSmallScreen;

	const {current: cardViewItems} = useRef<TabItem<CardViewType>[]>([
		{
			value: CardViewType.List,
			label: <View1 width='24' height='24' />,
			css: cardViewItemCSS,
			tooltipText: 'Expanded View',
		},
		{
			value: CardViewType.Grid,
			label: <View2 width='24' height='24' />,
			css: cardViewItemCSS,
			tooltipText: 'Classic View',
		},
	]);

	const mappedTestsWithSubjects = useMemo(() => {
		const visibleTestMap = new Map(
			visibleTests.map((test) => [test.testID, {...test, subjects: []}])
		);
	
		props.subjects.common.forEach((subject) => {
			subject.tests.forEach((test) => {
				const mappedTest = visibleTestMap.get(test.id);
				if (mappedTest) {
					mappedTest.subjects.push(subject);
				}
			});
		});
	
		return Array.from(visibleTestMap.values());
	}, [props.subjects.common, visibleTests]);
	
	const scopeTabItems = useMemo(
		() =>
			hasDrafts
				? tabItems
				: tabItems.filter(({value}) => value !== FilterScope.DraftTests),
		[hasDrafts]
	);

	const onAddAuthorsClick = useCallback(() => {
		showAddAuthorsDrawerToggle();
		if (!notedAuthors.length) {
			testExplorerService.getNotedAuthors();
		}
	}, [notedAuthors.length, testExplorerService, showAddAuthorsDrawerToggle]);

	const onAddSeriesClick = useCallback(() => {
		showAddSeriesDrawerToggle();
		if (!notedSeries.length) {
			testExplorerService.getNotedSeries();
		}
	}, [notedSeries.length, testExplorerService, showAddSeriesDrawerToggle]);

	const onAddToFavoritesClick = useCallback(
		(test: TestModel) => {
			requestSubscription.current?.unsubscribe();
			requestSubscription.current =
				testExplorerService.onAddToFavoriteClick(test);

			return () => requestSubscription.current?.unsubscribe();
		},
		[testExplorerService]
	);

	const onCloseSelectedBarConfirm = useCallback(() => {
		showCloseSelectedBarAlertToggle();
		testExplorerService.closeSelectedTestsBar();
	}, [testExplorerService, showCloseSelectedBarAlertToggle]);

	const onHideTestsClick = () => {
		testExplorerService.hideSelectedTests((tests: TestModel[]) => {
			if (tests.length === 0) {
				return;
			}
			const message = `You've ${!hideTestsChecked ? 'hidden' : 'unhidden'} ${
				tests.length > 1 ? 'multiple tests' : tests[0].name
			}`;
			snackbarRef?.current.showSnackbar(message);
		});
	};

	const getContentAreas = () => {
		return contentAreaController.all().subscribe(({contentAreas: recievedContentAreas}) => {
			setContentAreas(
				recievedContentAreas.map(({id, name}) => ({
					value: id,
					label: name,
				}))
			);
		});
	};

	useEffect(() => {
		getContentAreas();
	}, []);

	return (
		<>
			<Layout data-cy='test-explorer' isSmallScreen={isAnySmallScreen}>
				<FiltersPanel
					service={testExplorerService}
					isPanelOpened={showFiltersPanel}
					contentAreas={contentAreas}
					togglePanelOpened={showFiltersPanelToggle}
					selectedStandards={selectedStandardsFilter}
					selectedStandardsIDs={selectedStandardsIDs}
					selectedGradeLevelIDs={selectedGradeLevelIDs}
					selectedContentAreaIDs={selectedContentAreaIDs}
					selectedAdministration={selectedAdministration}
					showHidden={showHidden}
					selectedTestTypes={selectedTestTypes}
					selectedNotedAuthors={selectedNotedAuthors}
					selectedNotedSeries={selectedNotedSeries}
					selectedNotedSeriesIDs={selectedNotedSeriesIDs}
					selectedNotedAuthorsIDs={selectedNotedAuthorsIDs}
					onAddStandardClick={showAddStandardsDrawerToggle}
					onAddSeriesClick={onAddSeriesClick}
					onAddAuthorsClick={onAddAuthorsClick}
					isContentAreasLoading={isContentAreasLoading}
					scope={scope}
					isSmallScreen={isAnySmallScreen}
				/>
				<ContentWrapper isSmallScreen={isAnySmallScreen}>
					<Header
						service={testExplorerService}
						setShowTestCreatorLauncher={showTestCreatorLauncherToggle}
						setShowAutoTestCreatorLauncher={showAutoTestCreatorToggle}
						setShowImageGallery={showImageGalleryToggle}
						isPortraitTablet={isPortraitTablet}
						testsCount={testsCount}
					/>
					<FiltersContainer isSmallScreen={isAnySmallScreen}>
						<FiltersTabsContainer>
							{!isPortraitTablet && (
								<Tabs
									activeTab={cardView}
									setActiveTab={onCardViewChange}
									tabItems={cardViewItems}
									isSmallScreen={isSmallScreen}
									dataCy='view-type-tabs'
								/>
							)}
							<Tabs
								activeTab={scope}
								setActiveTab={testExplorerService.onScopeChange.bind(
									testExplorerService
								)}
								tabItems={scopeTabItems}
								isSmallScreen={isTablet || isSmallScreen}
								dataCy='test-type-tabs'
							/>
							{!isPortraitTablet && (
								<ItemsCounter itemsCount={testsCount} label='Tests' />
							)}
						</FiltersTabsContainer>
						<Select
							placeholder='Sort By'
							items={sortByOptions}
							sortDirection={selectedSortDirection}
							disabled={scope === FilterScope.DraftTests}
							onSortDirectionChange={testExplorerService.onSortDirectionChange.bind(
								testExplorerService
							)}
							onValueChange={testExplorerService.onSortByChange.bind(
								testExplorerService
							)}
							selectedValue={sortBy}
							showAdditionalItem
							dataCy='sort-by-option-select'
						/>
					</FiltersContainer>
					<TestsList
						selectedStandardType={selectedStandardType}
						selectedStandardsIDs={selectedStandardsIDs}
						tests={mappedTestsWithSubjects}
						showOnlySelectedTests={showOnlySelectedTests}
						selectedTestIDs={selectedTestIDs}
						onNextPage={onNextPage}
						onTestSelect={testExplorerService.onTestSelect.bind(
							testExplorerService
						)}
						onAddToFavoriteClick={onAddToFavoritesClick}
						showTestDetails={showTestDetailsModalToggle}
						isSmallScreen={isAnySmallScreen}
						isLoaded={isLoaded && props.isSubjectsLoaded}
						scope={scope}
						cardView={!isPortraitTablet ? cardView : CardViewType.List}
						osRef={osRef}
					/>
					{Boolean(selectedTestIDs.length) && (
						<SelectedTestsBar
							addSelectedToFavoritesChecked={addSelectedToFavoritesChecked}
							hideTestsChecked={hideTestsChecked}
							showOnlySelectedTests={showOnlySelectedTests}
							onAddSelectedClick={showAddToSubjectDrawerToggle}
							onAddToFavoritesClick={testExplorerService.addSelectedTestsToFavorites.bind(
								testExplorerService
							)}
							onHideTestsClick={onHideTestsClick}
							onShowOnlySelectedClick={testExplorerService.showOnlySelectedClick.bind(
								testExplorerService
							)}
							onClose={showCloseSelectedBarAlertToggle}
							selectedTestsCount={selectedTestIDs.length}
						/>
					)}
				</ContentWrapper>
				{showTestCreatorLauncher && (
					<TestCreatorLauncher
						onTestCreated={onTestCreated}
						onClose={showTestCreatorLauncherToggle}
					/>
				)}
				{showAutoTestCreatorLauncher && (
					<AutoTestCreator
						subjects={props.subjects.autoTestCreator}
						close={showAutoTestCreatorToggle}
						onTestCreated={onTestCreated}
					/>
				)}
				{showImageGallery && (
					<ImageGalleryModal onClosed={showImageGalleryToggle} />
				)}
				{showCloseSelectedBarAlert && (
					<CloseSelectedBarAlert
						onCancel={showCloseSelectedBarAlertToggle}
						onConfirm={onCloseSelectedBarConfirm}
					/>
				)}
				{showOverlimitAlert && (
					<OverlimitAlert onClose={showOverlimitAlertToggle} />
				)}
				{showTestDetailsModal && (
					<TestDetailsModal
						contentAreas={contentAreas}
						firstName={firstName}
						lastName={lastName}
						userID={userID}
						testID={selectedTestDetails.testID}
						testType={selectedTestDetails.type}
						close={showTestDetailsModalToggle(null, null)}
						onTestCreated={onTestCreated}
						onHiddenChanged={(testId, isHidden) =>
							testExplorerService.onModalTestHidden(testId, isHidden)
						}
						selectedStateStandardType={selectedStandardType}
						selectedStateStandardIDs={selectedStandardsIDs}
					/>
				)}
				{showAddStandardsDrawer && (
					<AddStandardsDrawer
						service={standardsService}
						onClose={showAddStandardsDrawerToggle}
						isSmallScreen={isAnySmallScreen}
						contentAreas={contentAreas}
						gradeLevels={gradeLevels}
						setGradeLevels={setGradeLevels}
						onFiltersReset={testExplorerService.onFiltersReset}
					/>
				)}
				{showAddSeriesDrawer && (
					<AddFilterDrawer
						items={notedSeries}
						selectedItems={selectedNotedSeriesIDs}
						sectionName='Add Series Filter'
						onClose={showAddSeriesDrawerToggle}
						onAddButtonClick={testExplorerService.onSeriesSelect.bind(
							testExplorerService
						)}
						loaded={drawerIsLoaded}
						type={DrawerType.SERIES}
					/>
				)}
				{showAddAuthorsDrawer && (
					<AddFilterDrawer
						items={notedAuthors}
						selectedItems={selectedNotedAuthorsIDs}
						sectionName='Add Author Filter'
						onClose={showAddAuthorsDrawerToggle}
						onAddButtonClick={testExplorerService.onAuthorsSelect.bind(
							testExplorerService
						)}
						loaded={drawerIsLoaded}
						type={DrawerType.AUTHORS}
					/>
				)}
				{showAddToSubjectDrawer && (
					<AddToSubjectDrawer
						tests={subjectSelectingTests}
						service={testExplorerService}
						subjects={props.subjects.common}
						selectedSubject={selectedSubject}
						onClose={showAddToSubjectDrawerToggle}
						onTestSelect={testExplorerService.onTestSelect.bind(
							testExplorerService
						)}
						onSubjectSelect={testExplorerService.onSubjectSelect.bind(
							testExplorerService
						)}
						snackbarRef={snackbarRef}
						loaded={drawerIsLoaded}
						onNewSubjectCreate={props.onNewSubjectCreate}
						isNewSubjectCreateDisabled={props.isNewSubjectCreateDisabled}
					/>
				)}
			</Layout>
			<Snackbar snackbarRef={snackbarRef} />
		</>
	);
};
