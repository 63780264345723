import {FlexBox, SelectableList} from '@esgi/ui/layout';
import {SelectableListRoot, SelectableListTrigger} from './components/components.styled';
import {getListValue} from '../../../utils/get-list-value';
import {MouseEvent, ReactNode, useCallback, useState} from 'react';
import {GroupItemContent} from '../components/group-item-content';
import {IdableItem} from '../../../types';
import {noop} from 'underscore';

type Props<Item extends IdableItem> = {
	dataCy?: string;
	value: Item['id'] | null | undefined;
	items: Item[];
	onItemClick: (itemId: Item['id']) => void;
	onTriggerButtonClick?: VoidFunction;
	isListSelected: boolean;
	isListOpen: boolean;
	withCounter?: boolean;
	groupName: string;
	hideArrowIcon?: boolean;
	allowSelectEmpty?: boolean;
	children?: (args: {item: Item}) => ReactNode;
};

export function ExpandableSingle<Item extends IdableItem>({
	dataCy,
	isListOpen,
	value,
	items,
	onItemClick,
	children,
	onTriggerButtonClick = noop,
	isListSelected,
	withCounter,
	groupName,
	hideArrowIcon,
	allowSelectEmpty,
}: Props<Item>) {
	const isEmptyList = !items.length;

	const [hovered, setHovered] = useState(false);

	const handleTriggerButtonClick = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();
			onTriggerButtonClick();
		},
		[onTriggerButtonClick],
	);

	const handleMouseOver = useCallback(() => {
		setHovered(() => {
			if (isListOpen || isListSelected) {
				return false;
			}

			if (isEmptyList && allowSelectEmpty) {
				return true;
			}

			return true;
		});
	}, [allowSelectEmpty, isEmptyList, isListOpen, isListSelected]);

	const handleMouseOut = useCallback(() => {
		setHovered(false);
	}, [setHovered]);

	return (
		<SelectableListRoot isListOpen={isListOpen} dataCy={dataCy}>
			<SelectableList.GroupRoot type='single' value={getListValue(value)}>
				<SelectableListTrigger
					openList={isListOpen}
					selected={isListSelected}
					onClick={!isEmptyList || allowSelectEmpty ? onTriggerButtonClick : noop}
					hovered={hovered && !isListSelected}
					onMouseOut={handleMouseOut}
					onMouseOver={handleMouseOver}
					defaultCursor={isListSelected}
					isListSelected={isListSelected}
				>
					<GroupItemContent
						itemsCount={withCounter ? items.length : undefined}
						label={groupName}
						hovered={hovered}
						selected={isListSelected}
					/>
					{!isEmptyList && !hideArrowIcon && (
						<FlexBox onClick={handleTriggerButtonClick} align='center' justify='end'>
							<SelectableList.TriggerIcon opened={isListOpen} />
						</FlexBox>
					)}
				</SelectableListTrigger>
				<SelectableList.Group>
					{items.map((item) => (
						<SelectableList.Item
							value={String(item.id)}
							key={item.id}
							forceSelect={value !== item.id}
							onClick={() => onItemClick(item.id)}
						>
							{children?.({item})}
						</SelectableList.Item>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
