import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {Fragment, Suspense, useState} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from '../report-button/buttons/no-students-protected-button';
import IepGoalsIcon from './icons/iep-goals-icon';
import {SubjectType} from '@esgi/core/enums';

const IepGoalFormModal = lazyComponent(() => import('modules/forms/iep-goal-form'));

interface Props {
	noStudents: boolean;
	subjectId: number;
	subjectType: SubjectType;
	hierarchy: HierarchySnapshot;
	globalSchoolYearID: number,
}

export const IepGoalsButton = (props: Props): JSX.Element => {
	const {hierarchy, subjectId, subjectType, globalSchoolYearID, noStudents} = props;
	const [opened, setOpened] = useState(false);

	const onClick = () => {
		SsoTracker.trackEvent({
			trackingEvent: 'IepGoalsForm',
		});
		setOpened(true);
	};

	return (
		<>
			<NoStudentProtectedButton
				title='IEP Goals'
				icon={<IepGoalsIcon />}
				hoverTitle='Create or Edit IEP Goals'
				onClicked={onClick}
				noStudents={noStudents}
				linkClassName='iep-goals-form-link'
				messageText='No students have been added to your class. Please add some to create IEP Goals.'
			/>
			{opened &&
				<Suspense fallback={<Fragment/>}>
					<IepGoalFormModal
						hierarchy={hierarchy}
						subject={{id: subjectId, type: subjectType}}
						globalSchoolYearID={globalSchoolYearID}
						onClose={() => setOpened(false)}
					/>
				</Suspense>
			}
		</>
	);
};
