import {styled} from '@esgi/ui/theme';
import {SubjectLevel} from '@esgi/main/libs/store';
import {GridBox} from '@esgi/ui/layout';
import {Task} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';

export const StyledButton = styled(GridBox, {
	width: '100%',
	borderRadius: '8px',
	border: '1px solid $secondaryMuted',
	padding: '0px 8px',
	alignItems: 'center',
	cursor: 'pointer',
});

export const ContentWrapper = styled(GridBox, {
	gap: '6px',
	padding: '6px 0px',
});

export const SubjectNameWrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	padding: '7px 8px',
	borderRadius: '8px',
	background: '$primarySurface',
	borderWidth: 1,
	borderStyle: 'solid',

	variants: {
		subjectLevel: {
			[SubjectLevel.Teacher]: {
				borderColor: '$border',
			},
			[SubjectLevel.District]: {
				borderColor: '$secondaryMuted',
				background: '$secondarySurface',
			},
			[SubjectLevel.School]: {
				borderColor: '$primaryMuted',
			},
		},
	},
});

export const TaskIconStyled = styled(Task, {
	width: '24px',
	height: '24px',
});

export const TextStyled = styled(Text, {
	display: 'flex',
	alignItems: 'center',
});