import React from 'react';
import {SortDirection} from '@esgi/core/enums';

interface Props {
	title?: string;
	class?: string;
	clicked?: () => any;
	sortDirection?: SortDirection;
	sorted: boolean;
}

export function Column(props: Props) {
	return <th style={{zIndex: 1}} className={props.class} onClick={() => props.clicked && props.clicked()}>
			<div className='cell-value-holder'>
				{props.title}
				{props.sorted && <i className={'fa ' + (props.sortDirection === SortDirection.Asc ? 'fa-angle-up' : 'fa-angle-down')}/>}
			</div>
		</th>;
}
