import {
  CreateSessionResponse, DeleteSessionRequest, DeleteYNNoteRequest,
  InitRequest,
  InitResponse, RestoreSessionRequest,
  ResultRequest,
  ResultScoreResponse,
  ResultYnResponse, SaveYNNoteRequest,
  UpdateResponse, UpdateSSRequest, UpdateYNRequest,
} from './models';
import {IApi as SSApi} from '../modal/types/ss';
import {IApi as YNApi} from '../modal/types/yn';
import {HttpClient} from '@esgi/api';
import {Observable} from 'rxjs';

export class Api implements YNApi, SSApi {
	private readonly _controllerName: string = 'assessments/test-session-details';

	public init(request: InitRequest): Observable<InitResponse> {
    	return HttpClient.default.ESGIApi.get<InitResponse>(this._controllerName, 'init', request).asObservable();
	}

	public ynLoad(request: ResultRequest): Observable<ResultYnResponse> {
    	return HttpClient.default.ESGIApi.get<ResultYnResponse>(this._controllerName, 'yn-result', request).asObservable();
	}

	public scoreLoad(request: ResultRequest): Observable<ResultScoreResponse> {
    	return HttpClient.default.ESGIApi.get<ResultScoreResponse>(this._controllerName, 'score-result', request).asObservable();
	}

	public updateYNTestSession(request: UpdateYNRequest): Observable<UpdateResponse> {
    	return HttpClient.default.ESGIApi
		    .post<UpdateResponse>(this._controllerName, 'update-yn-session', request)
		    .asObservable();
	}

	public updateSSTestSession(request: UpdateSSRequest): Observable<UpdateResponse> {
		return HttpClient.default.ESGIApi
			.post<UpdateResponse>(this._controllerName, 'update-ss-session', request)
			.asObservable();
	}

	public create(): Observable<CreateSessionResponse> {
    	return HttpClient.default.ESGIApi
		    .get<CreateSessionResponse>(this._controllerName, 'create-session')
		    .asObservable();
	}

	public restore(request: RestoreSessionRequest): Observable<any> {
    	return HttpClient.default.ESGIApi
		    .post(this._controllerName, 'session-restore', request)
		    .asObservable();
	}

	public delete(request: DeleteSessionRequest): Observable<any> {
    	return HttpClient.default.ESGIApi
		    .post(this._controllerName, 'session-delete', request)
		    .asObservable();
	}

	public saveNote(request: SaveYNNoteRequest): Observable<any> {
    	return HttpClient.default.ESGIApi
		    .post(this._controllerName, 'add-yn-comment', request)
		    .asObservable();
	}

	public deleteNote(request: DeleteYNNoteRequest): Observable<any> {
    	return HttpClient.default.ESGIApi
		    .post(this._controllerName, 'delete-yn-comment', request)
		    .asObservable();
	}
}
