import {BaseService} from '@esgi/core/service';
import {TestType} from '@esgi/core/enums';
import {Observable, BehaviorSubject, tap, Subscription} from 'rxjs';
import {IFormControlValidatorResult} from '@esgi/deprecated/elements/form-controls';
import {capitalizeFirstLetter} from '@esgillc/ui-kit/utils';
import moment from 'moment';
import {
	CreateSessionResponse,
	DeleteSessionRequest,
	InitRequest,
	InitResponse,
	RestoreSessionRequest,
	Question,
	TestSession,
	DownloadRequest,
	TestSessionDetails,
	TestSessions,
} from '../types';
import {IEPGoalFormService} from '../components/iep-goal/service';
import {TestTypeService} from './test-type-service';
import {YesNoService} from './yes-no-service';
import {SingleScoreService} from './single-score-service';
import {IEPGoalModel, IEPStatusModel} from '../components/iep-goal/models';

type TabNames = 'details' | 'iep';

export class TestSessionDetailsFormService extends BaseService {
	public readonly initData = {} as InitRequest;
	public readonly testSessionDetails$ = new BehaviorSubject<TestSessionDetails>(null);
	public readonly currentTestSession$ = new BehaviorSubject<TestSession>(null);
	public readonly iepGoal$ = new BehaviorSubject<IEPGoalModel>(null);
	public readonly iepStatuses$ = new BehaviorSubject<IEPStatusModel[]>(null);
	public readonly editMode$ = new BehaviorSubject<boolean>(false);
	public readonly iepEditMode$ = new BehaviorSubject<boolean>(false);
	public readonly dirty$ = new BehaviorSubject<boolean>(false);
	public readonly loaded$ = new BehaviorSubject<boolean>(false);
	public readonly answersLoaded$ = new BehaviorSubject<boolean>(false);
	public readonly validation$ = new BehaviorSubject<Record<string, any>>({valid: true});
	public readonly validator$ = new BehaviorSubject<Record<string, IFormControlValidatorResult>>({
		scoreValidation: {
			valid: true,
			message: null,
		},
		testDateValidation: {
			valid: true,
			message: null,
		},
	});
	public readonly tabs$ = new BehaviorSubject<{name: string, label: string}[]>([]);
	public readonly activeTab$ = new BehaviorSubject<TabNames>('details');
	public readonly testDateTouched$ = new BehaviorSubject<boolean>(false);
	public readonly testSessions$ = new BehaviorSubject<TestSessions>({});
	public readonly isSaving$ = new BehaviorSubject(false);
	private readonly controller: string = 'assessments/test-session-details';

	constructor(
		public serviceIEP: IEPGoalFormService,
		public serviceData?: TestTypeService,
	) {
		super();

		this.iepGoal$.pipe(tap((value) => {
			const result = [{name: 'details', label: 'Test sessions'}];
			if (value !== null) {
				result.push({name: 'iep', label: 'IEP Goal'});
			}
			this.tabs$.next(result);
		})).subscribe();
	}

	public init(request: InitRequest): Subscription {
		Object.assign(this.initData, request);
		return this.httpClient.ESGIApi
			.get<InitResponse>(this.controller, 'init', request)
			.pipe(tap(({
				questions,
				testSessionsInfo,
				testName,
				testType,
				studentFirstName,
				studentLastName,
				canEdit,
				canTest,
				totalPossible,
				testResultsCorrectVerbiage,
				testResultsIncorrectVerbiage,
				nowProfileTZ,
				isUserLinkedDistrict,
				iepGoal,
				iepStatuses,
			}) => {
				const testSessions = TestSession.FromResponseArray(testSessionsInfo);
				this.setTestSessionDetails({
					questions: Question.FromResponseArray(questions),
					testSessions,
					testName,
					studentName: `${studentFirstName} ${studentLastName}`,
					canEdit: canEdit,
					canTest,
					testType: TestType[testType],
					totalPossible,
					testResultsCorrectVerbiage: capitalizeFirstLetter(testResultsCorrectVerbiage),
					testResultsIncorrectVerbiage: capitalizeFirstLetter(testResultsIncorrectVerbiage),
					nowProfileTZ: moment(nowProfileTZ),
					showTestedBy: testSessionsInfo.length > 0 && isUserLinkedDistrict,
				});
				this.setCurrentTestSession(testSessions.filter(({deleted}) => !deleted)[0]);
				if (iepGoal) {
					this.iepGoal$.next({...iepGoal, studentID: request.studentID});
					this.iepStatuses$.next(iepStatuses);
					const student = {
						firstName: studentFirstName,
						lastName: studentLastName,
						fullName: `${studentFirstName} ${studentLastName}`,
						studentID: request.studentID,
					};

					this.serviceIEP.init(this.iepGoal$.value, this.iepStatuses$.value, student);
				}
				if (TestType[testType] === TestType.YN) {
					this.serviceData = new YesNoService();
				}
				if (TestType[testType] === TestType.Score) {
					this.serviceData = new SingleScoreService();
				}
			}))
			.subscribe(() => this.setLoaded(true));
	}

	public create(): Observable<CreateSessionResponse> {
		return this.httpClient.ESGIApi
			.get<CreateSessionResponse>(this.controller, 'create-session')
			.asObservable();
	}

	public restore(request: RestoreSessionRequest): Observable<any> {
		return this.httpClient.ESGIApi
			.post(this.controller, 'session-restore', request)
			.asObservable();
	}

	public remove(request: DeleteSessionRequest): Observable<any> {
		return this.httpClient.ESGIApi
			.post(this.controller, 'session-delete', request)
			.asObservable();
	}

	public download(filename: string, request: DownloadRequest): Observable<any> {
		return this.httpClient.ESGIApi
			.file(this.controller, 'download', filename, request);
	}

	public setSaving(value: boolean) {
		this.isSaving$.next(value);
	}

	public setAnswersLoaded(value: boolean) {
		this.answersLoaded$.next(value);
	}

	public setEditMode(value: boolean) {
		this.editMode$.next(value);
	}

	public setIEPEditMode(value: boolean) {
		this.iepEditMode$.next(value);
	}

	public setActiveTab(value: TabNames) {
		this.activeTab$.next(value);
		this.setEditMode(false);
	}

	public setCurrentTestSession(session: TestSession) {
		this.currentTestSession$.next(new TestSession(session));
	}

	public setDirty(value: boolean) {
		this.dirty$.next(value);
	}

	public setLoaded(value: boolean) {
		this.loaded$.next(value);
	}

	public setTestDateTouched(value: boolean) {
		this.testDateTouched$.next(value);
	}

	public setTestSessionDetails(data: Partial<TestSessionDetails>) {
		this.testSessionDetails$.next({
			...this.testSessionDetails$.value,
			...data,
		});
	}

	public setTestSessions(sessions: TestSessions) {
		this.testSessions$.next(sessions);
	}

	public validate(state) {
		this.validator$.next({
			...this.validator$.value,
			...state,
		});
	}

	public validateAll() {
		const firstNotValid = Object.values(this.validator$.value).find(
			({valid}) => !valid,
		);
		const validation = {
			valid: firstNotValid ? false : true,
			message: firstNotValid ? firstNotValid.message : null,
		};
		if (this.validation$.value.valid !== validation.valid) {
			this.validation$.next(validation);
		}
	}

	public destroy(): void {
		this.serviceIEP.destroy();
		this.serviceData?.destroy();
		super.destroy();
	}
}
