import {SVGProps} from 'react';

export const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='16'
		height='15'
		viewBox='0 0 16 15'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M7.50836 -0.00012207C3.36836 -0.00012207 0.00836182 3.35988 0.00836182 7.49988C0.00836182 11.6399 3.36836 14.9999 7.50836 14.9999C11.6484 14.9999 15.0084 11.6399 15.0084 7.49988C15.0084 3.35988 11.6484 -0.00012207 7.50836 -0.00012207ZM8.25836 11.2499H6.75836V9.74988H8.25836V11.2499ZM8.25836 8.24988H6.75836V3.74988H8.25836V8.24988Z'
			fill='#FF0C3E'
		/>
	</svg>
);
