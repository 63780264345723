import {PropsWithChildren, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {animated, useSpring} from 'react-spring';

type Props = {
	id: string,
	viewportRef: RefObject<HTMLDivElement>,
} & PropsWithChildren;

export function CarouselItem(props: Props) {
	const [visible, setVisible] = useState(true);

	const ref = useRef<HTMLDivElement>();

	const style = useSpring({opacity: visible ? 1 : 0.4});

	const recalculateVisibility = useCallback(() => {
		if(props.viewportRef.current) {
			const {left: divLeft, right: divRight} = ref.current.getBoundingClientRect();
			const {left: portLeft, right: portRight} = props.viewportRef.current.getBoundingClientRect();

			if(Math.round(divLeft) >= Math.round(portLeft - 10) && Math.round(divRight) <= Math.round(portRight + 20)) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}
	}, [props.viewportRef]);

	useEffect(() => {
		const tracker = props.viewportRef.current.getElementsByClassName('use-spring-carousel-track-wrapper')[0] as HTMLDivElement;
		const mutationObserver = new MutationObserver(() => recalculateVisibility());
		mutationObserver.observe(tracker, {attributes: true, attributeFilter: ['style']});
		return () => mutationObserver.disconnect();
	}, [props.viewportRef, recalculateVisibility]);

	useLayoutEffect(() => recalculateVisibility(), []);

	return <animated.div style={style} ref={ref}>
		{props.children}
	</animated.div>;
}

export function CarouselItem1(props: PropsWithChildren & {active: boolean}) {
	const ref = useRef<HTMLDivElement>();

	const style = useSpring({opacity: props.active ? 1 : 0.4});

	return <animated.div style={style} ref={ref}>
		{props.children}
	</animated.div>;
}



export function CarouselItem2(props: Props) {
	const [visible, setVisible] = useState(true);

	const ref = useRef<HTMLDivElement>();

	const style = useSpring({opacity: visible ? 1 : 0.4});

	const recalculateVisibility = useCallback(() => {
		if(props.viewportRef.current) {
			const {left: divLeft, right: divRight} = ref.current.getBoundingClientRect();
			const {left: portLeft, right: portRight} = props.viewportRef.current.getBoundingClientRect();

			if(Math.round(divLeft) >= Math.round(portLeft - 10) && Math.round(divRight) <= Math.round(portRight + 20)) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}
	}, [props.viewportRef]);

	useEffect(() => {
		const tracker = props.viewportRef.current.getElementsByClassName('use-spring-carousel-track-wrapper')[0] as HTMLDivElement;
		const mutationObserver = new MutationObserver(() => recalculateVisibility());
		mutationObserver.observe(tracker, {attributes: true, attributeFilter: ['style']});
		return () => mutationObserver.disconnect();
	}, [props.viewportRef, recalculateVisibility]);

	useLayoutEffect(() => recalculateVisibility(), []);

	return <animated.div style={style} ref={ref}>
		{props.children}
	</animated.div>;
}