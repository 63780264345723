import {Fragment, useCallback, useMemo} from 'react';
import styles from './styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../../../services/report-service';
import {Test} from '../../../../../../../models/models';
import {formate, styleCell} from '../../../../../../../utils';

interface Props {
	test: Test;
	service: ReportService;
}

export function DetailReportScores({test, service}: Props) {
	const report = useBehaviorSubject(service.dataService.report);
	const includeGradeScore = useBehaviorSubject(service.settingsService.includeGradeScore);
	const showBaseline = useBehaviorSubject(service.settingsService.showBaseline);
	const displayZeroIfNotTestedOption = useBehaviorSubject(service.settingsService.displayZeroIfNotTestedOption);
	const currentPeriod = useBehaviorSubject(service.settingsService.currentPeriod);
	const markingPeriods = useBehaviorSubject(service.settingsService.markingPeriods);
	const printInColor = useBehaviorSubject(service.settingsService.printInColor);
	const showAll = currentPeriod === 'All';

	const shouldRenderCell = useCallback((index: number) => {
		if (index === 0 && showBaseline || index === markingPeriods.length - 1) {
			return true;
		} else if (showAll && index !== 0) {
			return true;
		}
		return false;
	}, [showBaseline, showAll]);

	const headers = useMemo(() => markingPeriods.map((mPeriod, index) =>
		<Fragment key={`header-${test.testID}-${mPeriod.title}-${mPeriod.index}`}>
			{shouldRenderCell(index) &&
				<td className={join(styles.bgGray, index === 0 && styles.basis)}>
					{mPeriod.title}
				</td>
			}
		</Fragment>,
	), [markingPeriods, shouldRenderCell]);

	const scores = useMemo(() => markingPeriods.map((mPeriod, index) =>
		<Fragment key={`score-${test.testID}-${mPeriod.title}-${mPeriod.index}`}>
			{shouldRenderCell(index) &&
				<td style={{backgroundColor: styleCell(mPeriod, test, report, includeGradeScore, printInColor)}}>
					{test.scores[index]?.testDate === null && displayZeroIfNotTestedOption === 'Zero' ? '0' : formate(mPeriod, test, 'raw')}
				</td>
			}
		</Fragment>,
	), [markingPeriods, shouldRenderCell, displayZeroIfNotTestedOption, includeGradeScore, report, test, printInColor]);

	const grades = useMemo(() => markingPeriods.map((mPeriod, index) =>
		<Fragment key={`grade-${test.testID}-${mPeriod.title}-${mPeriod.index}`}>
			{shouldRenderCell(index) &&
				<td style={{backgroundColor: styleCell(mPeriod, test, report, includeGradeScore, printInColor)}}>
					{test.scores[index]?.testDate === null && displayZeroIfNotTestedOption === 'Zero' ? '0' : formate(mPeriod, test, 'grade')}
				</td>
			}
		</Fragment>,
	), [markingPeriods, shouldRenderCell, displayZeroIfNotTestedOption, test, report, includeGradeScore, printInColor]);

	return test && <div className={styles.scoreContainer}>
		<table>
			<tbody>
			<tr>
				<td className={styles.bgGray}/>
				{headers}
			</tr>
			<tr>
				<td>Score</td>
				{scores}
			</tr>
			{(report.gradeScaleAllow && includeGradeScore) &&
				<tr>
					<td>Grade</td>
					{grades}
				</tr>
			}
			</tbody>
		</table>
	</div>;
}
