import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/ui-kit/modal';
import {TextArea} from '@esgillc/ui-kit/control';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {CrossIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

interface Props {
	onSaveClicked: (note: string) => void;
  onCancelClicked: () => void;
  note: string;
}

export class State {
	note: string = '';
}

export default class AddSummaryNoteModal extends React.Component<Props, State> {
  noteMaxLength: number = 255;

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		this.setState({note: this.props.note || ''});
	}

  private summaryNotesOnChange(event) {
    const value = event.target.value.replace(/[\r\n\v]+/g, '');
    this.setState({note: value});
  }

	get disabled() {
		if (this.props.note) {
			return this.state.note.length > this.noteMaxLength;
		}

		return !this.state.note || this.state.note.length > this.noteMaxLength;
	}

	render() {
		return <Modal className={styles.modal}>
        <ModalHeader className={styles.header}>
          Summary
          <sup className='help-container'>
            <OnHoverTooltip message="Add notes about the student's test session here. These notes are optional to display in the Parent Letter and Student Detail reports.">
              <i className='fa fa-question-circle' />
            </OnHoverTooltip>
          </sup>
        </ModalHeader>
        <ModalBody>
          <TextArea value={this.state.note}
                    maxLength={255}
                    rows={3}
                    autoResizeToFit={true}
                    className={styles.textarea}
                    placeholder='Enter session summary (optional)'
                    onChange={(e) => this.summaryNotesOnChange(e)}
          >
          </TextArea>
          <div onClick={() => this.setState({note: ''})} className={styles.crossIcon}>
            <CrossIcon color={'#BDBDBD'} />
          </div>
          <div className={styles.counter}>{this.state.note.length}/{this.noteMaxLength}</div>
        </ModalBody>
        <ModalFooter>
          <Buttons.Gray
            onClick={() => this.props.onCancelClicked()}
          >
            CANCEL
          </Buttons.Gray>
          <Buttons.Contained
            disabled={this.disabled}
            onClick={() => this.props.onSaveClicked(this.state.note)}
          >
            SAVE
          </Buttons.Contained>
        </ModalFooter>
      </Modal>;
	}
}
