import React from 'react';
import styles from '../../styles.module.less';
import {TestHistoryService} from '../../../../../../services/test-history-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';

interface Props {
	service: TestHistoryService
}

export function SpecialistGroups({service}: Props) {
	const specialistGroups = useBehaviorSubject(service.specialistGroups);
	const selectedSpecialistGroup = useBehaviorSubject(service.selectedSpecialistGroup);
	return specialistGroups.length > 0 && <>
		<div>
			<span className={styles.settingsTitle}>Group: </span>
			{specialistGroups.length > 1 && <>
				<Dropdown value={selectedSpecialistGroup}
				          setValue={(v) => service.selectedSpecialistGroup.next(v)}
				          optionName='name'
				          className={styles.fluid} autoWidth={true}>
					{specialistGroups.map(v => <Option key={v.specialistGroupID}
					                                   value={v}>{v.name}</Option>)}
				</Dropdown>
			</>}
			{specialistGroups.length == 1 && <>
				<span className={styles.name}>{selectedSpecialistGroup[0].name}</span>
			</>}
		</div>
	</>;
}
