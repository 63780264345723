import {Alert} from '@esgi/ui/alert';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		minWidth: 560,
		maxHeight: 'calc(100% - 25px)',
		gridTemplateRows: 'auto 1fr auto',
	},
};

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$3',
	overflow: 'hidden',
});

export const avatarRootCss: CSS = {
	cursor: 'default',
};
