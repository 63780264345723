import {FunctionComponent, useState} from 'react';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {SubjectTab} from '@esgi/main/libs/store';
import {ErrorBoundary} from '@esgi/core/react';
import {Reports} from '@esgi/main/kits/reports';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';

import {OldReportLauncherWrapper} from '../old-report-launcher';
import {Page, Panel} from '../../layout/index.styled';

type Props = {
	open: boolean,
	close: VoidFunction,
	hierarchy: HierarchySnapshot,
	subject: SubjectTab,
}

export function withClassReportPage<P extends Props>(Component: (props: P) => any | FunctionComponent<P>) {
	return (props: Omit<P, keyof Props>) => {
		const [selectedEntity, setSelectedEntity] = useState<SingleSelectState>();

		return <Page>
			<Panel>
				<Reports.ClassLevel.StudentPanel 
					onSelect={setSelectedEntity} 
					hideEmptyClassAndGroup
				/>
			</Panel>
			<OldReportLauncherWrapper singleState={selectedEntity}>
				{(version, isOpen, close, hierarchySnapshot, subject, changesCollector) =>
					<ErrorBoundary onCatch={close}>
						<Component open={isOpen} close={close} hierarchy={hierarchySnapshot}
						           subject={subject} {...props as any}/>
					</ErrorBoundary>
				}
			</OldReportLauncherWrapper>
		</Page>;
	};
}