import {styled} from '@esgi/ui/theme';
import {CounterBox, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const AllClassesButtonContainer = styled(FlexBox, {
	width: '232px',
	height: '40px',
	padding: '8px 6px 8px 12px',
	borderRadius: '6px',
	border: `1px solid $$border`,
	background: '$vivid',
	marginBottom: 20,
	cursor: 'pointer',
	transition: 'color .3s, background-color .3s, border-color .3s',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',
		alignSelf: 'start',

		'& path': {
			fill: 'currentColor',
		},
	},

	'&:hover': {
		color: '$secondary',
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',
	},


	[`& ${Text}`]: {
		color: 'currentColor',
	},

	variants: {
		selected: {
			true: {
				backgroundColor: '$primaryBackground',
				color: '$primary',

				[`${CounterBox}`]: {
					transition: 'color .3s, background-color .3s, border-color .3s',
				},
				[`&:not(:hover) ${CounterBox}`]: {
					color: '$primaryVivid',
					borderColor: '$primaryVivid',
				},
			},
		},

		disabled: {
			true: {
				color: '$muted',
				backgroundColor: '$background',
				borderColor: '$border',
				cursor: 'default',
				pointerEvents: 'none',
			},
		},
	},
});

export const AllClassesButtonText = styled(FlexBox, {

});