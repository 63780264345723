import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import SortRow from 'modules/reports/growth-report/components/sort-row';
import {GrowthReportService} from 'modules/reports/growth-report/services/growth-report-service';
import {useMemo} from 'react';
import styles from './table-head.module.less'

interface TableHeadProps {
	growthReportService: GrowthReportService;
}

export default function TableHead(props: TableHeadProps) {
	const {growthReportService} = props;
	const tests = useBehaviorSubject(growthReportService.tests);
	const subjectName = useBehaviorSubject(growthReportService.subjectName);
	const showNumberOfStudents = useBehaviorSubject(growthReportService.showNumberOfStudents);
	const showMarkingPeriods = useBehaviorSubject(growthReportService.showMarkingPeriods);
	const numberPeriods = useBehaviorSubject(growthReportService.numberPeriods);
	const totalColumn = useMemo(() => {
		return growthReportService.totalColumn
	}, [showNumberOfStudents, showMarkingPeriods, numberPeriods])

	return (
		<thead id='growth-report-table-head'>
			<tr className={styles.headerRow}>
				<th className={`${styles.verticalAlignTop} ${styles.districtName}`} rowSpan={2} colSpan={growthReportService.headerColspan}>
					Subject: {subjectName}
				</th>
				{
					tests?.map((test, index) => <th key={`test-name-cell-${index}`}
					                                className={`${styles.borderCell} ${styles.verticalAlignTop} ${styles.testNameCell}`}
					                                colSpan={totalColumn}>
						{test.testName}
					</th>)
				}
			</tr>
			<tr className={styles.headerRow}>
				{tests?.map((test, index) => <th key={`questions-count-cell-${index}`}
				                                 className={`${styles.borderCell} ${styles.verticalAlignTop} ${styles.borderBottomNone}`}
				                                 colSpan={totalColumn}>
												<span style={{fontWeight: 'normal'}}>
													(out of {test.questionsCount})
												</span>
				</th>)}
			</tr>
			<SortRow growthReportService={growthReportService}/>
		</thead>
	)
}
