import React, {ReactNode} from 'react';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import Matrix from '@esgi/deprecated/ui-kit/matrix/matrix';
import {Criteria, Description, Level} from '../../../common/models';
import RubricService from '../../../common/rubric-service';
import styles from './styles.module.less';

class State {
	criteria: Criteria[] = [];
	levels: Level[] = [];
	descriptions: Description[] = [];
}

interface Props {
	rubricService: RubricService;
}

export default class MiniPreviewForm extends React.PureComponent<Props, State> {
	private onDestroy$: Subject<void> = new Subject();
	public state = new State();

	public componentDidMount() {
		combineLatest(this.props.rubricService.levels$, this.props.rubricService.criteria$, this.props.rubricService.descriptions$)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(data => {
				const levels = data[0];
				const criteria = data[1];
				const descriptions = data[2];
				this.setState({criteria, levels, descriptions});
			});
	}

	public render() {
		const cellGetter = (source, level, criteria) => source.find(d => d.levelID === level.id && d.criteriaID === criteria.id);
		return <Matrix maxWidth={582}
		               maxHeight={405}
		               className={{
						   body: styles.body,
						   table: styles.table,
		               }}
		               columnHeaderOptions={{cells: this.state.levels, cellRenderer: this.columnHeaderRenderer}}
		               rowHeaderOptions={{cells: this.state.criteria, cellRenderer: this.rowHeaderRenderer}}
		               cellsOptions={{
			               cells: this.state.descriptions,
			               cellRenderer: this.cellRenderer,
			               cellGetter: cellGetter,
		               }}/>;
	}

	private columnHeaderRenderer = (data: Level, index: number): ReactNode => {
		return <th key={data.score} className={styles.level}>
			<div className={styles.box}>
				<div className={styles.boxTitle}>Level {data.score}</div>
				<div className={styles.innerBox}>
					<span>
						{data.name || data.score}
					</span>
				</div>
			</div>
		</th>;
	};

	private rowHeaderRenderer = (data: Criteria): ReactNode => {
		return <th key={data.id} className={styles.criteria}>
			<div className={styles.box}>
				<div className={styles.innerBox}>
					<div className={styles.boxTitle}>
						Criteria {data.order}
					</div>
					<div className={styles.criteriaName}>
						<span>{data.name}</span>
					</div>
				</div>
			</div>
		</th>;
	};

	private cellRenderer = (data: Description): ReactNode => {
		return <td key={data.id} className={styles.description}>
			<div className={styles.box}>
				<div className={styles.innerBox}>
					<div className={styles.boxTitle}>
						Description
					</div>
					<span>
						{data.description}
					</span>
				</div>
			</div>
		</td>;
	};

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
