import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const Wrapper = styled(Box, {
	position: 'relative',
});

export const Container = styled(Box, {
	position: 'fixed',
	width: 560,
	zIndex: 10,
});