import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {TestType} from '@esgi/main/libs/core';
import {GridBox} from '@esgi/ui/layout';
import {TestColorIndicator, OneLinedText} from '@esgi/main/kits/common';
import {AlertBody, TestInfoBox, UsersIcon, alertColorConfig, alertRootCss} from '../../styled';
import {TestTypeLabel} from '@esgi/main/features/teacher/home';

type Props = {
	testID: number,
	testName: string,
	testType: TestType,
	className: string,
	onClose: VoidFunction,
};

export function ClassNotTestedDialog(props: Props) {
	const alert = Alert.useRef();
	const close = Alert.useClose(alert, props.onClose);

	return (
		<Alert modalManagerRef={alert} colorConfig={alertColorConfig} css={alertRootCss}>
			<Alert.Header withBacklight={false} onCloseIconClick={close}>
				<GridBox align='center' flow='column' gap='3'>
					<UsersIcon width={24} height={24} />
					<Text size='small' data-cy='student-full-name' color='primary'>
						{props.className}
					</Text>
				</GridBox>
			</Alert.Header>
			<AlertBody>
				<TestInfoBox>
					<TestColorIndicator testID={props.testID} />
					<OneLinedText size='xLarge' color='base' data-cy='test-name'>
						{props.testName}
					</OneLinedText>
					<TestTypeLabel dataCy='test-type' testType={props.testType} />
				</TestInfoBox>
				<Text size='medium' data-cy='test-description' color='neutral40'>
					No data is available for this test, because it has not been completed by any students in this class.
				</Text>
			</AlertBody>
			<Alert.Footer>
				<Button color='secondary' data-cy='start-test-button' onClick={close}>
					<Text size='medium' bold data-cy='start-test-button-label'>
						OK
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
