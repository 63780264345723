import {PropsWithChildren, useEffect, useState} from 'react';
import {Box} from '@esgi/ui/layout';
import {animated, useSpring} from 'react-spring';
import {useTabsContext} from '../context';
import {TabInfo} from '../types';

type Props<T extends string> = PropsWithChildren<TabInfo<T>>;

export function TabContent<Tab extends string>({
	children,
	id,
	description,
	name,
	applyDisabledStyles,
	dataCy,
	disabled,
}: Props<Tab>) {
	const context = useTabsContext<Tab>();

	const show = context.active === id;

	const [hide, setHide] = useState(!show);

	useEffect(() => {
		context.add({
			id,
			description,
			name,
			applyDisabledStyles,
			dataCy,
			disabled,
		});

		return () => context.remove(id);
	}, [id]);

	useEffect(() => {
		if (context.hasTabInList(id)) {
			context.updateTabInfo({
				id,
				data: {
					description,
					name,
					applyDisabledStyles,
					dataCy,
					disabled,
				},
			});
		}
	}, [description, name, applyDisabledStyles, dataCy, disabled]);

	const styles = useSpring({
		opacity: show ? 1 : 0,
		delay: show ? 150 : 0,
		config: {
			duration: 200,
		},
		onStart: () => {
			if (show) {
				setHide(false);
			}
		},
		onRest: () => {
			if (!show) {
				setHide(true);
			}
		},
	});

	if (!hide) {
		return (
			<Box style={{height: '100%'}}>
				<animated.div style={{...styles, height: '100%'}}>{children}</animated.div>
			</Box>
		);
	}

	return <></>;
}
