import React, {Dispatch, useCallback} from 'react';
import {Checkbox} from '@esgi/ui/controls';
import {TestSection} from '../test-section';
import {CheckboxContainer, Label, ToggleGroupItem} from './styled';
import {ToggleGroupRoot, ToggleGroupContent} from '../../styled';
import {IncludeType} from '../../../../../types';
import {useUser} from '@esgi/core/authentication';
import {CheckedState} from '@radix-ui/react-checkbox';

interface Props {
	isIncorrectDisabled?: boolean | undefined;
	shuffleQuestions: boolean;
	updateShuffleQuestionsValue: Dispatch<boolean>;
	includeType: IncludeType;
	setIncludeType: Dispatch<IncludeType>;
}

export function NewSession({
	isIncorrectDisabled,
	updateShuffleQuestionsValue,
	shuffleQuestions,
	includeType,
	setIncludeType,
}: Props) {
	const currentUser = useUser();

	const setShuffleQuestionOrder = useCallback(
		(state: CheckedState) => {
			updateShuffleQuestionsValue(state === 'indeterminate' ? false : state);
		},
		[updateShuffleQuestionsValue],
	);

	return (
		<TestSection title='Include'>
			<ToggleGroupRoot value={includeType}>
				<ToggleGroupContent>
					<ToggleGroupItem value={IncludeType.AllQuestions} onClick={() => setIncludeType(IncludeType.AllQuestions)}>
						<Label size='medium' bold>
							All Questions
						</Label>
					</ToggleGroupItem>
					<ToggleGroupItem
						disabled={isIncorrectDisabled}
						value={IncludeType.IncorrectAndNotTested}
						onClick={() => setIncludeType(IncludeType.IncorrectAndNotTested)}
					>
						<Label size='medium' bold>
							{currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect'} & Not Tested
						</Label>
					</ToggleGroupItem>
				</ToggleGroupContent>
			</ToggleGroupRoot>

			<CheckboxContainer>
				<Checkbox dataCy='shuffle-question-checkbox' label='Shuffle question order' checked={shuffleQuestions} onCheckedChange={setShuffleQuestionOrder} />
			</CheckboxContainer>
		</TestSection>
	);
}
