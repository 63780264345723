import {SubjectType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export namespace RequestModels {
	export namespace Settings {
		export class Init {
			constructor(public SelectedGroupItemID: number,
			            public SelectedStudentID: number,
			            public SubjectID: number,
			            public SubjectType: SubjectType,
			            public Hierarchy: HierarchySnapshot) {
			}
		}

		export class FetchHierarchy {
			SubjectID: number;
			SubjectType: string;
			Students: Student[];
			Hierarchy: HierarchySnapshot;
		}

		export class FetchSubject {
			Hierarchy: HierarchySnapshot;
		}

		export interface FetchSessionHierarchy {
			Tests: Test[];
			StudentIDs: number[];
		}

		export interface FetchSubjectSessionHierarchy extends FetchHierarchy {
			TestDate: string;
		}


		export class Student {
			StudentID: number;
			GradeLevelID: number;
		}

		export class SaveCardsPerPage {
			UserID: number;
			CardsPerPage: string;
		}

		export class Test {
			TestID: number;
			TestDate: string;
			QuestionsCount: number;
		}
	}

	export namespace Results {
		export class Init {
			Tests: Test[];
			Subject: ResponseModels.Subject;
			StudentIDs: number[];
			AllStudentsSelected: boolean;
			TeacherID: number;
			PrintPutOption: string;
			GlobalSchoolYearID: number;
			BreakByTest: boolean;
			GroupItemID: number;
			GroupItemLevel: string;
			CardsPerPage: string;
			Hierarchy: HierarchySnapshot;
		}

		export interface Test {
			TestID: number;
			Max?: number;
			TestDate: string;
		}
	}
}

export namespace ResponseModels {
	export namespace Settings {
		export class Init {
			selectedGroupItemID: number;
			selectedStudentID: number;
			user: User;
			subjects: Subject[];
			groupItems: GroupItem[];
			hasGradeScales: boolean;
			readOnly: boolean;
			printOutOption: string;
			tests: Test[];
			testResultsCorrectVerbiage: string;
			testResultsIncorrectVerbiage: string;
			tracks: Track[];
		}

		export class Subjects {
			subjects: Subject[];
			selectedSubjectID: number;
		}

		export class GroupItem {
			id: number;
			name: string;
			itemType: string;
			selectedStudentID: number;
			students: Student[];
		}

		export class Student {
			studentID: number;
			firstName: string;
			lastName: string;
			languageID: number;
			gradeLevelID: number;
		}

		export class User {
			userID: number;
			firstName: string;
			lastName: string;
			title: string;
			cardsPerPage: string;
		}

		export interface FetchHierarchy {
			tests: Test[];
		}

		export interface Test {
			id: number;
			name: string;
			correct: number;
			incorrect: number;
			untested: number;
			questionsCount: number;
			dataExists: boolean;
			testDate: Date;

			orderNum: number;
		}

		export class Track {
			trackID: number;
			dateTo: string;
		}
	}

	export namespace Results {

		export class Init {
			reportGuid: string;
			subjectName: string;
			reportDate: string;
			students: Student[];
			pageSize: PageSize;
		}

		export class Student {
			studentID: number;
			firstName: string;
			lastName: string;
			cards: Card[];
		}

		export class Card {
			testID: number;
			testName: string;
			questionID: number;
			shortTitle: string;
			reportDate: string;
			orderNumber: number;
			ticks: number;
		}

		export class PageSize {
			rowCount: number;
			colCount: number;
		}
	}

	export class Subject {
		name: string;
		subjectID: number;
		subjectType: string;
		level: string;
	}
}

export enum FileType {
	PDF, Word, ZIP
}
