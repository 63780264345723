import {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {Drawer, ScreenSize, SubjectName, useDrawerClose, useDrawerRef, useRootPageContext} from '@esgi/main/kits/common';
import {AddTests, Search, useBehaviorSubject} from '@esgi/ui';
import {Cheap, Container, Description, Divider, TabsFiltersWrapper, TabWrapper} from './index.styled';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Select, Tabs} from '@esgi/main/features/teacher/home';
import {useDrawerState} from './hooks/use-drawer-state';
import {useTabItems} from './components/tabs/use-tab-items';
import {testScopeTabItems, testTypeTabItems} from './components/tabs/constants';
import {Checkbox, CheckboxBox} from './components/checkbox.styled';
import {Pagination, usePagination} from './components/pagination';
import {Sticky} from './components/sticky';
import {Input} from '@esgi/ui/controls';
import {useTestSelection} from './hooks/use-tests-selection';
import {Table} from './components/table';
import {useService} from '@esgi/core/service';
import {DataService} from './service/service';
import {TestScopeTabId, TestTypeTabId} from './components/tabs/types';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {SubjectLevel} from '@esgi/main/libs/store';
import {pageSize} from './constants';

type Props<T> = {
	subject: T,
	onClose: VoidFunction,
	snackbarRef: RefObject<SnackbarManager>,
	selfAssess?: boolean,
}

export function AddTestDrawer<T extends {id: number, type: SubjectType, name: string, level: SubjectLevel}>({subject, onClose, snackbarRef, selfAssess = false}: Props<T>) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, onClose);

	const service = useService(DataService);
	const loaded = useBehaviorSubject(service.loaded$);
	const initialized = useBehaviorSubject(service.initialized$);
	const contentAreas = useBehaviorSubject(service.contentAreas$);
	const tests = useBehaviorSubject(service.tests$);
	const totalCount = useBehaviorSubject(service.totalCount$);

	const [showSelectedTest, setShowSelectedTest] = useState(false);
	const [showPage, setShowPage] = useState(0);
	const {
		osRef,
		contentAreaID,
		setContentAreaID,
		testType,
		onTestTypeChange,
		scope,
		onScopeChange,
		onlySelfAssess,
		toggleSelfAssessChecked,
		keyword,
		setKeyword,
		sorting,
		setSorting,
		fetchData,
	} = useDrawerState<T>({service, selfAssess, subject});
	const {selectedTests, selectedTestList, setSelectedTests, select, toggleAll} = useTestSelection(tests);
	const {screenSize} = useRootPageContext();
	const {pageChange: setPage, ...paging} = usePagination({
		pageSize,
		totalElements: totalCount,
		onChange: (page) => fetchData(page + 1),
	});
	const {pageChange: pageChange2, ...pagingSelected} = usePagination({
		pageSize,
		totalElements: selectedTestList.length,
		onChange: (page) => setShowPage(page),
	});
	const testsToShow = useMemo(() => {
		if (showSelectedTest) {
			return selectedTestList.slice(showPage * pageSize, (showPage + 1) * pageSize);
		}
		return tests;
	}, [tests, selectedTestList, showSelectedTest, showPage]);
	const testTypeTabs = useTabItems<TestTypeTabId>(testTypeTabItems);
	const testScopeTabs = useTabItems<TestScopeTabId>(testScopeTabItems);

	const onAddSelectedTabs = useCallback(() => {
		if (selectedTests.length && subject) {
			service.addSelectedTabs(subject.id, subject.type, selectedTests).subscribe({
				next: () => {
					snackbarRef.current.showSnackbar(`You have added selected tests to ${subject.name} Subject`);
					setSelectedTests([]);
				},
			});
		}
	}, [selectedTests, service, setSelectedTests, snackbarRef, subject]);

	const [debouncedKeyword, setDebouncedKeyword] = useState('');

	useEffect(() => {
		const delayKeywordTimeoutId = setTimeout(() => {
			setKeyword(debouncedKeyword);
		}, 500);

		return () => clearTimeout(delayKeywordTimeoutId);
	}, [debouncedKeyword, setKeyword]);

	useEffect(() => {
		service.getSubjectTestIDs(subject.id, subject.type);
		service.getContentAreas();
	}, [initialized, subject]);

	useEffect(() => setPage(0), [fetchData]);

	return (
		<Drawer width={screenSize === ScreenSize.PortraitTablet ? '100%' : 1050} drawerRef={drawerRef} onClickOutside={close}>
			<Drawer.Header
				Icon={AddTests}
				sectionName='Add Tests'
				actionButtonDisabled={!loaded || !selectedTests.length}
				closeDrawer={close}
				childrenAlignment='left'
			>
				<GridBox flow='column' gap='4' align='center'>
					{subject && (
						<>
							<Divider />
							<SubjectName
								subjectLevel={subject.level}
								size='large'
								noWrap
							>
								{subject.name}
							</SubjectName>
						</>
					)}
				</GridBox>
			</Drawer.Header>
			<Drawer.Body>
				<Container>
					<Description>
						<Text size='small' color='neutral40'>Description:</Text>
						<TabsFiltersWrapper>
							<Select
								placeholder='Content Areas'
								items={contentAreas}
								onValueChange={setContentAreaID}
								selectedValue={contentAreaID}
								minWidth={232}
								dataCy='content-areas-select'
								disabled={showSelectedTest}
							/>
							{!selfAssess && <>
								<TabWrapper disabled={showSelectedTest}>
									<Tabs<TestTypeTabId>
										activeTab={testType}
										setActiveTab={onTestTypeChange}
										tabItems={testTypeTabs}
										showTitle={false}
										dataCy='test-type-toggle'
									/>
									<Tabs<TestScopeTabId>
										activeTab={scope}
										setActiveTab={onScopeChange}
										tabItems={testScopeTabs}
										showTitle={false}
										dataCy='test-scope-toggle'
									/>
								</TabWrapper>
                <CheckboxBox dataCy='self-assess-checkbox'>
                  <Checkbox
                    selected={onlySelfAssess}
                    value='selfAssess'
                    label='Self-Assess'
                    checked={onlySelfAssess}
										disabled={showSelectedTest}
                    onCheckedChange={toggleSelfAssessChecked}
                  />
                </CheckboxBox>
							</>}
						</TabsFiltersWrapper>
						<Input.Iconable
							placeholder='Search'
							value={debouncedKeyword}
							onChange={(e) => setDebouncedKeyword(e.target.value)}
							dataCy='search-input'
							disabled={showSelectedTest}
						>
							<Search/>
						</Input.Iconable>
						<FlexBox justify='between'>
							<Box>
								<Cheap counter>
									<Text size='small' font='mono'>{totalCount}</Text>
								</Cheap>
								<Text size='small' font='mono' color='mediumContrast'>Tests Available</Text>
							</Box>
							<Pagination {...(showSelectedTest ? pagingSelected : paging)}/>
						</FlexBox>
					</Description>
					<Table
						tests={testsToShow}
						selectedTests={selectedTests}
						loaded={loaded}
						toggleAll={toggleAll}
						sorting={sorting}
						setSorting={setSorting}
						osRef={osRef}
						onTestSelect={select}
						keyword={keyword}
						disabledActions={showSelectedTest}
						scope={scope}
					/>
				</Container>
				<Sticky
					selectedCount={selectedTests.length}
					onDeselect={() => setSelectedTests([])}
					onShowSelectedOnly={setShowSelectedTest}
					onSave={onAddSelectedTabs}
				/>
			</Drawer.Body>
		</Drawer>
	);
}
