import {
	CartesianGrid,
	Label,
	Line,
	LineChart,
	ReferenceLine,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import {ChartContainer, NotesContainer} from './index.styled';
import {ChartControls} from '../chart-controls';
import React, {useCallback, useMemo, useState} from 'react';
import {renderXAxisTick, renderYAxisTick} from './utils';
import {
	BASELINE_COLOR,
	DIAGRAM_HEIGHT,
	PRIMARY_COLOR,
	SECONDARY_COLOR,
	WHITE_COLOR,
	MIN_DIAGRAM_SESSIONS_COUNT,
} from './constants';
import {EntityType, TestSession, TestType} from '../../../../types';
import {DateTools} from '@esgi/main/features/user-profile';
import {EmptyContent, getStudentClass, Pagination} from '../../../../kit';
import {ChartCustomDot} from '../chart-custom-dot';
import {Class} from '@esgi/main/libs/store';
import {isNull} from 'underscore';
import {useTestDashboardContext} from '../../../../layout/outlet-context';
import {TestSessionDetailsAlert} from '@esgi/main/features/teacher/test-session-details';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {useVisibleData} from '../../hooks/use-visible-data';
import {LineChartData} from './types';

type Props = {
	entityType: EntityType,
	testSessions: TestSession[],
	showOnlyTestedStudents?: boolean,
	toggleShowOnlyTestedStudent?: () => void,
	showExpandedView: VoidFunction,
	testType: TestType,
	totalPossible?: number;
	isTablet: boolean;
}

const pageSize = 15;

export function Chart({
	entityType,
	testSessions,
	showOnlyTestedStudents,
	toggleShowOnlyTestedStudent,
	showExpandedView,
	testType,
	totalPossible,
	isTablet,
}: Props) {
	const [showBaseline, setShowBaseLine] = useState(false);

	const firstSession = useMemo(
		() =>
			testSessions
				.map(session=>({...session}))?.sort(({testDate: first}, {testDate: second}) =>
					new Date(first).valueOf() - new Date(second).valueOf())?.[0],
		[testSessions]
	);

	const [openedTestSessionDetailsID, setOpenedTestSessionDetailsID] = useState<number | null>(null);

	const {subjectID, classId, groupId, studentFromClassId, studentFromGroupId, studentId, classesList, groupsList} =
		useTestDashboardContext();

	const onOpenTestSessionDetailsAlert = useCallback((sessionID: number) => {
		setOpenedTestSessionDetailsID(sessionID);
	}, []);
	const onCloseTestSessionDetailsAlert = useCallback(() => {
		setOpenedTestSessionDetailsID(null);
	}, []);
	const sessionNote = testSessions[testSessions.length - 1]?.notes;
	const classID = useMemo<Class['id']>(() => {
		if (!isNull(classId)) {
			if (classId === -1) {
				return 0;
			}

			return classId;
		}

		if (!isNull(groupId)) {
			const selectedGroup = groupsList.find(({id}) => groupId === id);
			const selectedClassByGroupId = classesList.find(
				({id}) => selectedGroup?.classID === id
			);

			return selectedClassByGroupId?.id ?? 0;
		}

		if (!isNull(studentId)) {
			return (
				getStudentClass({
					classID: studentFromClassId,
					groupID: studentFromGroupId,
					classesList,
					groupsList,
					studentID: studentId,
				})?.id ?? 0
			);
		}

		return 0;
	}, [
		classId,
		classesList,
		groupId,
		groupsList,
		studentFromClassId,
		studentFromGroupId,
		studentId,
	]);

	const getFormattedDate = (date: Date | string | null) => date ? DateTools.toUIString(new Date(date), 'MM/DD') : '-';

	const {visibleData, currentPageIndex, totalPages, nextPage, previousPage} = useVisibleData({
		pageSize,
		sessions: testSessions,
	});

	const preparedChartData = useMemo<LineChartData[]>(
		() => {
			const score = firstSession?.correctAnswers || 0;
			const preparedData = visibleData.map<LineChartData>(
				(data: TestSession) => ({
					score: data?.correctAnswers,
					session: getFormattedDate(data?.testDate),
					presentScore: score,
					totalPossible,
					...data,
				}));

			if(visibleData.length >= MIN_DIAGRAM_SESSIONS_COUNT) {
				return preparedData;
			}

			const emptyLineChartData: LineChartData = {
				score: null,
				session: null,
				presentScore: score,
				totalPossible,
				...visibleData[0],
			};

			return [...preparedData, ...new Array(MIN_DIAGRAM_SESSIONS_COUNT - visibleData?.length).fill(emptyLineChartData)];
		}, [firstSession, visibleData, totalPossible]);

	const toggleBaseLine = useCallback(() => {
		setShowBaseLine((prev) => !prev);
	}, []);

	if (!visibleData.length) {
		return (
			<ChartContainer dataCy='test-history-no-data'>
				<EmptyContent templateText='No data to display' />
			</ChartContainer>
		);
	}

	return (
		<ChartContainer>
			<ChartControls
				entityType={entityType}
				showBaseline={showBaseline}
				toggleBaseLine={toggleBaseLine}
				showOnlyTestedStudents={showOnlyTestedStudents}
				toggleShowOnlyTestedStudent={toggleShowOnlyTestedStudent}
				showExpandedView={showExpandedView}
				testType={testType}
			/>
			<ResponsiveContainer height={DIAGRAM_HEIGHT}>
				<LineChart
					data={preparedChartData}
					margin={{top: 35, right: 12, bottom: 25, left: 12}}
				>
					<CartesianGrid stroke={WHITE_COLOR} horizontal={false} />
					<Line
						type='monotone'
						dataKey='score'
						dot={({cx, cy, payload}) => (
							<ChartCustomDot
								cx={cx}
								cy={cy}
								payload={payload}
								entityType={entityType}
								baselineId={firstSession?.id}
								openedTestSessionDetailsID={openedTestSessionDetailsID}
								onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
								testType={testType}
								isTablet={isTablet}
							/>
						)}
						stroke={PRIMARY_COLOR}
					/>
					{showBaseline && (
						<ReferenceLine
							y={firstSession?.correctAnswers || 0}
							stroke={BASELINE_COLOR}
							strokeDasharray='3 3'
						/>
					)}
					<XAxis
						tickLine={false}
						stroke='none'
						dataKey='session'
						tick={renderXAxisTick}
					>
						<Label
							style={{fontSize: 10, fill: SECONDARY_COLOR}}
							position={{x: -28, y: 28}}
							value='Session'
						/>
					</XAxis>
					<YAxis
						allowDecimals={false}
						tickLine={false}
						stroke='none'
						dataKey='score'
						tick={renderYAxisTick}
					>
						<Label
							style={{fontSize: 10, fill: PRIMARY_COLOR}}
							position={{x: 32, y: -24}}
							value='Score'
						/>
					</YAxis>
				</LineChart>
			</ResponsiveContainer>
			{totalPages > 1 && <GridBox justify='end'>
					<Pagination
						currentPageIndex={currentPageIndex}
						nextPage={nextPage}
						previousPage={previousPage}
						totalPages={totalPages}
					/>
			</GridBox>}
			{entityType === EntityType.Student && sessionNote && <NotesContainer>
				<Text data-cy='notes-label' size='xSmall' font='mono' color='lowContrast'>
					Notes
				</Text>
				<Text data-cy='notes-field' size='xSmall' font='mono' color='neutral40'>
					{sessionNote}
				</Text>
			</NotesContainer>}
			{!isNull(openedTestSessionDetailsID) && (
				<TestSessionDetailsAlert
					testSessionID={openedTestSessionDetailsID}
					onAlertClose={onCloseTestSessionDetailsAlert}
					classID={classID}
					subjectID={subjectID}
				/>
			)}
		</ChartContainer>
	);
}
