import {SortDirection, TestType} from '@esgi/core/enums';
import {FeaturedSeriesModel} from '../components/landing/featured-series/featured-series';
import {TestsSortBy} from '../enums';
import {INotedAuthor} from '../services/landing-service';

export class FilterModel {
	scope: string = 'AllTests';
	subjectId: number;
	contentAreaId: number;
	gradeLevelId: number;
	keyword: string = '';
	notedAuthor: INotedAuthor;
	sortBy: TestsSortBy = TestsSortBy.CreateDate;
	sortDirection: SortDirection = SortDirection.Desc;
	showHidden: boolean = false;
	featuredSeries: FeaturedSeriesModel = new FeaturedSeriesModel();
	notedSeries: { id: number, name: string };
	source: string = 'ES';
	testType: TestType = null;
}
