import {BehaviorSubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {BaseService} from '@esgi/core/service';
import {InitResponse, LetterTemplateModel, LogoModel} from './types';

export class ParentLetterEditorService extends BaseService {
	public readonly published = new BehaviorSubject<boolean>(null);
	public readonly logo = new BehaviorSubject<LogoModel>(null);
	public readonly letterTemplate = new BehaviorSubject<LetterTemplateModel>(null);

	private initialLogo: LogoModel;
	private requestInProgress: boolean;
	private readonly controller: string = 'user-settings/district-admin/parent-letter';

	constructor() {
		super();
		this.busy.subscribe((busy) => this.requestInProgress = busy);
	}

	public init() {
		return this.httpClient.ESGIApi.get<InitResponse>(this.controller, 'init')
			.pipe(tap((response) => {
				const {logo, published, templateID, body, defaultBody} = response;

				this.initialLogo = logo;
				this.logo.next(logo);
				this.published.next(published);
				this.letterTemplate.next({templateID, body, defaultBody});
			}));
	}

	public revertLogo() {
		this.logo.next(this.initialLogo);
	}

	public setPublished(published: boolean) {
		this.httpClient.ESGIApi.post<{ templateID: number }>(
			this.controller,
			'publish',
			{published, templateID: this.letterTemplate.value.templateID},
		).subscribe(({templateID}) => {
			const message = published
				? 'Your message has been published.'
				: 'Your message has been unpublished.';
			showSnackbarNotification(message);
			this.letterTemplate.next({
				...this.letterTemplate.value,
				templateID,
			});
		});
		this.published.next(published);
	}

	public setLogo(logo: LogoModel) {
		if (this.requestInProgress) {
			return;
		}

		const {templateID, body, defaultBody} = this.letterTemplate.value;
		const bodyIsDefault = body === defaultBody;

		return this.httpClient.ESGIApi.post<{ templateID: number }>(
			this.controller,
			'save',
			{templateID, body, bodyIsDefault, logo},
		).subscribe(() => {
			const message = logo
				? 'Your logo has been saved.'
				: 'Your logo has been deleted.';
			showSnackbarNotification(message);
			this.initialLogo = logo;
			this.logo.next(logo);
		});
	}

	public save(body: string) {
		if (this.requestInProgress) {
			return;
		}

		const {templateID, defaultBody} = this.letterTemplate.value;
		const bodyIsDefault = body === defaultBody;

		return this.httpClient.ESGIApi.post<{ templateID: number }>(
			this.controller,
			'save',
			{templateID, body, bodyIsDefault, logo: this.logo.value},
		).subscribe(({templateID}) => {
			this.letterTemplate.next({
				templateID,
				body,
				defaultBody,
			});

			if (this.published.value) {
				this.setPublished(this.published.value);
			}

			this.initialLogo = this.logo.value;
		});
	}
}
