import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';


export const AvgContainer = styled(FlexBox, {
	position: 'relative',
	flexDirection: 'column',
	minWidth: '167px',
	height: '100%',
	justifyContent: 'space-evenly',
});

export const Link = styled('a', {
	cursor: 'pointer',
	position: 'absolute',
	right: '7px',
	top: '10px',
	lineHeight: 0,
});

export const TestCellContainer = styled(FlexBox, {
	flexDirection: 'column',
	minWidth: '167px',
	height: '100%',
	justifyContent: 'space-evenly',
});

export const TestName = styled(Text, {
	textWrap: 'wrap',
});