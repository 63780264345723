import {InputCleanable} from './components/input-cleanable';

export * from './components/input-cleanable';

export const Input = Object.assign(
	{},
	{
		Сleanable: InputCleanable,
	},
);
