import {ComponentProps} from 'react';
import {ChartItem} from '../../../types';
import {Bar} from 'recharts';
import {isNull} from 'underscore';
import {getThemeColor} from '@esgi/ui';

type Props = Omit<ComponentProps<typeof Bar>, 'dataKey'> & {
	payload?: ChartItem | null;
};

export function BarBackgroundRect({x, y, width, height, payload}: Props) {
	if (isNull(payload)) {
		return null;
	}

	return (
		<rect
			x={x}
			y={y}
			width={width}
			height={height}
			fill={getThemeColor('vivid')}
			strokeWidth={1}
			stroke={getThemeColor('mild')}
		/>
	);
}
