import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AppliedTo} from '@esgi/ui/icons';
import {AlertHeader, Embed} from './index.styled';

type Props = {
	onClose: VoidFunction;
	previewURL: string;
	onDownload: VoidFunction;
};

export function PreviewDialog({previewURL, onClose, onDownload}: Props) {
	const alertRef = Alert.useRef();
	const handleClose = Alert.useClose(alertRef, onClose);

	return (
		<Alert
			dataCy='bingo-preview-content'
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.neutral}
			defaultOpen={false}
		>
			<AlertHeader
				withBacklight={false}
				onCloseIconClick={handleClose}	
			>
				<AppliedTo />
				<Text size='large'>Bingo</Text>
			</AlertHeader>
			<Alert.Body>
				<Embed type='application/pdf' src={previewURL} />
			</Alert.Body>
			<Alert.Footer>
				<Button
					color='primary'
					onClick={() => {
						onDownload();
						onClose();
					}}
				>
					<Text size='medium' bold>Download</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
