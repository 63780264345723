import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type KeyboardShortcutsFormType = FormGroup<{
	isKeyboardShortcuts: FormControl<boolean>,
},
	ControlValue<{
		isKeyboardShortcuts: FormControl<boolean>,
	}>>

export const createKeyboardShortcutsForm = ():
	KeyboardShortcutsFormType => new FormGroup({
	isKeyboardShortcuts: new FormControl(false),
});