import {useEffect} from 'react';
import {ClassicHierarchyLevel, HierarchySnapshot} from 'modules/hierarchy/core/models';
import {SubjectTab} from '@esgi/main/libs/store';
import GradeScaleLauncher from 'shared/modules/grade-scale/grade-scale-launcher/grade-scale-launcher';
import {GradeScaleHierarchyLevel} from 'shared/modules/reports/grade-scale-report/models';
import {withClassReportPage} from './wrapper';

function gradeLevel(h: HierarchySnapshot): GradeScaleHierarchyLevel {
	const s = h.classic.selected;
	switch (s.level) {
		case ClassicHierarchyLevel.Student:
			return h.classic.groupID > 0 ? GradeScaleHierarchyLevel.Group : GradeScaleHierarchyLevel.Class;
		case ClassicHierarchyLevel.Teacher:
		case ClassicHierarchyLevel.Class:
			return GradeScaleHierarchyLevel.Class;
		case ClassicHierarchyLevel.Group:
			return GradeScaleHierarchyLevel.Group;
		case ClassicHierarchyLevel.School:
			return GradeScaleHierarchyLevel.StudentsSchool;
		case ClassicHierarchyLevel.District:
			return GradeScaleHierarchyLevel.StudentsDistrict;
		case ClassicHierarchyLevel.SchoolsGroup:
			return GradeScaleHierarchyLevel.SchoolsGroup;
		case ClassicHierarchyLevel.TeachersGroup:
			return GradeScaleHierarchyLevel.TeachersGroup;
	}
}

type Props = {
	open: boolean,
	close: VoidFunction,
	hierarchy: HierarchySnapshot,
	subject: SubjectTab
};

function GradeReport(props: Props) {
	const {
		open,
		close,
		hierarchy,
		subject,
	} = props;

	useEffect(() => {
		if(open) {
			const launcher = new GradeScaleLauncher(gradeLevel(hierarchy), subject.id, subject.type as unknown as any, hierarchy);
			launcher.load(false);
			launcher.events.closed(close);
		}

	}, [open]);
}

export default withClassReportPage(GradeReport);