import {useCallback, useState} from 'react';
import {useAutoControlledState} from '@esgi/ui';
import {ClassBucket, RearrangeItem, StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {Class} from '@esgi/main/libs/store';
import {SelectAllClassesButton} from './select-all-classes-button';
import {Observable} from 'rxjs';

type Props = {
	setViewMode: VoidFunction;
	updateClassesOrder: (classes: Class[]) => Observable<void>;
	classBuckets: ClassBucket[];
};

export function RearrangeContent({setViewMode, updateClassesOrder, classBuckets}: Props) {
	const [isReorderingData, setIsReorderingData] = useState(false);

	const [classesList, setClassesList] = useAutoControlledState({
		initialState: classBuckets.map<RearrangeItem>(({id, name, students}) => ({
			id,
			groupName: name,
			groupItemsCount: students.length,
		})),
	});

	const handleSaveClick = useCallback(() => {
		setIsReorderingData(true);

		const classesItems: Class[] = classesList.map((classInList) => {
			const {students, ...restClassData} = classBuckets.find(({id}) => id === classInList.id)!;

			return {
				...restClassData,
				studentIDs: students.map(({id}) => id),
			};
		});

		updateClassesOrder(classesItems).subscribe(() => {
			setViewMode();
			setIsReorderingData(false);
		});
	}, [classBuckets, classesList, setViewMode, updateClassesOrder]);

	return (
		<StudentsPanel.Tabs.TabContent
			withBottomSectionButton
			buttonText='Save'
			onBottomButtonClick={handleSaveClick}
			isBottomSectionButtonDisabled={isReorderingData}
		>
			<SelectAllClassesButton disabled />

			<StudentsPanel.Lists.SelectableListGroup.Rearrange droppableItems={classesList} onDragEnd={setClassesList} />
		</StudentsPanel.Tabs.TabContent>
	);
}
