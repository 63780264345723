import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const StyledSideBar = styled(GridBox, {
	gap: 20,
	height: '100%',
	maxWidth: 400,
	paddingBottom: 20,
	borderLeftWidth: 1,
	borderLeftStyle: 'solid',
	borderLeftColor: '$mild',
	backgroundColor: '$background',
	gridTemplateRows: 'auto 1fr',
});

export const StyledBodyContainer = styled(GridBox, {
	gap: '$5',
	gridTemplateRows: 'repeat(4, auto) 1fr',
	height: '100%',
	width: 400,
	paddingTop: 28,
	paddingLeft: 20,
	paddingRight: 20,
});