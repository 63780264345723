import {
	StudentChangedEvent,
	StudentCreatedEvent,
	StudentRemovedEvent,
} from 'modules/forms/students-form/events';
import {StudentRosterToolEvents} from 'shared/modules/student-roster-upload-tool/events';
import {StudentModel} from '../core/api';
import {HierarchyState} from './hierarchy-data-service';


export function applyStudentChanged(args: StudentChangedEvent, hierarchy: HierarchyState): HierarchyState {
	const {students} = hierarchy;
	const student = students.items.filter(s => s.studentID === args.studentID)[0];
	const studentSpecialistGroups = student?.specialistGroups || [];
	const newStudent =
		{
			...student,
			firstName: args.firstName,
			lastName: args.lastName,
			classes: args.classes,
			gradeLevelID: args.gradeLevelID,
			groups: args.groups,
			teacherID: args.teacherID,
			schoolID: args.schoolID,
			specialistGroups: args.specialistGroups ? args.specialistGroups.map(item => item.id) : studentSpecialistGroups,
			studentID: args.studentID,
		};
	students.items = students.items.map(s => s.studentID === newStudent.studentID ? newStudent : s);
	return hierarchy;
}

export function applyStudentCreated(args: StudentCreatedEvent, hierarchy: HierarchyState): HierarchyState {
	const newStudent = new StudentModel(
		args.firstName,
		args.lastName,
		args.studentID,
		args.teacherID,
		args.schoolID,
		args.districtID,
		args.gradeLevelID,
		args.classes,
		args.groups,
		args.specialistGroups,
	);

	const {students, classic} = hierarchy;

	if(classic){
		classic.studentID = args.studentID;
	}

	students.items = [...students.items, newStudent];
	return hierarchy;
}

export function applyStudentRemoved(args: StudentRemovedEvent, hierarchy: HierarchyState): HierarchyState {
	const {students, classic, specialist} = hierarchy;
	students.items = students.items.filter(s => s.studentID !== args.id);

	if (classic.studentID === args.id) {
		classic.studentID = 0;
	}

	if (specialist.studentID === args.id) {
		specialist.studentID = 0;
	}

	return hierarchy;
}

export function applyStudentUploaded(args: StudentRosterToolEvents.Uploaded.Args, hierarchy: HierarchyState): HierarchyState {
	const {students} = hierarchy;
	const newStudents = args.students.map((s) => {
		const item: StudentModel = {
			studentID: s.id,
			lastName: s.lastName,
			firstName: s.firstName,
			schoolID: args.schoolID,
			teacherID: args.teacherID,
			classes: [args.classID],
			districtID: args.districtID,
			gradeLevelID: s.gradeLevelID,
			groups: [],
			specialistGroups: [args.specialistGroupID],
		};

		return item;
	});

	students.items = [...students.items]
		// remove new student from other groups
		.filter(s => newStudents.findIndex(ns => ns.studentID === s.studentID) === -1)
		.concat(newStudents);
	return hierarchy;
}
