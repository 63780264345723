import {RefObject} from 'react';

export type RootPageContext = {
	screenSize: ScreenSize | null;
	rootPageContentContainerRef: RefObject<HTMLDivElement>;
};

export enum ScreenSize {
	Small = 'Small',
	Tablet = 'Tablet',
	PortraitTablet = 'PortraitTablet',
}
