import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Hint, Label, OnErrorTooltip, TextArea} from '@esgillc/ui-kit/form-control';
import styles from '../styles.module.less';

const MAX_LENGTH = 300;

type Props = {
	control: IFormControl;
}

export const NotesField = ({control}: Props) => (
	<FormElement className={styles.textAreaFormElement} control={control}>
		<Label className={styles.label}>Notes</Label>
		<div className={styles.field}>
			<TextArea
					rows={5}
					maxLength={MAX_LENGTH}
					autoResizeToFit
					placeholder='Enter notes for this test'
			/>
			<Hint className={styles.limitCounter}>
				{(props) => `${MAX_LENGTH - (props.value?.length | 0)} characters left (${MAX_LENGTH} max)`}
			</Hint>
		</div>
		<OnErrorTooltip
			showOnError='required'
			placement='left'
		>
			Required
		</OnErrorTooltip>
	</FormElement>
);
