import {CounterBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Trigger} from './trigger';

export const expandableListsGap = 6;

const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',

	variants: {
		isListOpen: {
			true: {
				'&:not(:last-child)': {
					marginBottom: 18,
				},
			},

			false: {
				'&:not(:last-child)': {
					marginBottom: expandableListsGap,
				},
			},
		},
	},
});

const SelectableListTrigger = styled(SelectableList.Trigger, {
	padding: '8px 6px 8px 12px',
	minHeight: 38,
	gridTemplateColumns: 'auto',

	[`${CounterBox}`]: {
		color: '$neutral56',
		borderColor: '$neutral56',
		transition: 'color .3s, background-color .3s, border-color .3s',
	},

	variants: {
		defaultCursor: {
			true: {
				cursor: 'default',
			},
		},
		selectedOverride: {
			true: {
				backgroundColor: '$primaryBackground',
				color: '$primary',

				[`&:not(:hover) ${CounterBox}`]: {
					color: '$primaryVivid !important',
					borderColor: '$primaryVivid !important',
				},

				[`${Text}`]: {
					color: '$primary',
				},
			},
		},
	},
});

const SelectableLisItemContent = styled(GridBox, {
	paddingTop: 2,
	paddingBottom: 2,
});

export const PreStyledGroupList = {
	Root: SelectableListRoot,
	GroupRoot: SelectableList.GroupRoot,
	Trigger: SelectableListTrigger,
	TriggerText: Trigger,
	TriggerIcon: SelectableList.TriggerIcon,
	Group: SelectableList.Group,
	Item: SelectableList.Item,
	ItemContent: SelectableLisItemContent,
}