import {dispatchAppEvent} from '@esgillc/events';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import {useCallback, useState} from 'react';
import {SessionType} from '@esgi/core/enums';
import {TestingModel} from '../../models';
import {SubjectModel} from '../../../types';
import {Summary} from '../../types';
import {TestSessionStatus} from '../../../../kit/enums';
import {TestIntro} from '../../../../kit/test-intro';
import {TestSavedEvent} from '../../../../kit/events';
import {ShareSyncService} from '../../../../kit/share-screen';
import {YNQuestionSlider} from './question-slider/question-slider';

export type Props = {
	service: ShareSyncService;
	guid: string;
	continueSession: boolean;
	sessionType: SessionType;
	shuffleQuestions: boolean;
	resumeSession: boolean;
	testingModel: TestingModel;
	questionIds?: number[];
	subject: Pick<SubjectModel, 'id' | 'type' | 'name'>;
	onClose: () => void;
	onFinished: (summary: Summary) => void;
	onFlashCardsClicked: () => void;
	onTestSessionDetailsClicked: () => void;
	onSessionStatusChanged: (sessionStatus: TestSessionStatus) => void;
}

enum Screen {
	None,
	Intro,
	Slider,
	Summary,
}

export function YesNoFlow({testingModel, service, ...props}: Props): JSX.Element {
	const [screen, setScreen] = useState(() => testingModel.testIntro && !props.continueSession && !props.resumeSession ? Screen.Intro : Screen.Slider);

	const onFinished = useCallback((summary: Summary) => {
		const {testID, studentID} = testingModel.general;
		props.onFinished(summary);
		dispatchAppEvent(TestSavedEvent, new TestSavedEvent({
			testID,
			studentID,
			correct: summary.correct,
			testSessionID: summary.testSessionID,
		}));
	}, [props, testingModel]);

	const {testID: testId} = testingModel.general;

	return (
		<Switch test={screen}>
			<Case value={Screen.Intro}>
				<TestIntro
					testName={testingModel.testName}
					bucketName={testingModel.bucketName}
					testID={testId}
					studentName={testingModel.studentName}
					testIntroID={testingModel.testIntro?.id}
					modifyTicks={testingModel.testIntro?.modifyTicks}
					pregenerated={testingModel.testIntro?.pregenerated}
					isWhiteBackground={testingModel.isWhiteBackground}
					onStart={() => setScreen(Screen.Slider)}
					onClose={props.onClose}
					onCancel={props.onClose}
				/>
			</Case>
			<Case value={Screen.Slider}>
				<YNQuestionSlider
					guid={props.guid}
					testingModel={testingModel}
					questionIds={props.questionIds}
					sessionType={props.sessionType}
					shareSyncService={service}
					resumeSession={props.resumeSession}
					continueSession={props.continueSession}
					shuffleQuestions={props.shuffleQuestions}
					closeClicked={props.onClose}
					toIntroClicked={() => setScreen(Screen.Intro)}
					onEndTest={onFinished}
					onSessionStatusChanged={props.onSessionStatusChanged}
				/>
			</Case>
		</Switch>);
}
