import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ContentWrapper = styled(GridBox, {
	gridTemplateAreas: `
    "sidebar header"
    "sidebar content"
  `,
	height: '100%',
	width: '100%',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: 'auto 1fr',
});
