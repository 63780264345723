import {ContextMenu} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const ContextMenuItem = styled(ContextMenu.Item, {
	padding: 6,
	display: 'grid',
	gridAutoFlow: 'column',
	gap: 6,
	justifyContent: 'start',
});
