/* eslint-disable @typescript-eslint/member-ordering */
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {
	CreateAssignmentExtractedSpecialistData,
	EditInitSpecialistResponse,
	GroupSpecialistItem,
	InitSpecialistResponse,
	StudentInfoSpecialist,
} from '../types/specialist-types';
import {CreateAssignmentService} from './create-assignment-service';
import {isEmpty, uniq} from 'underscore';
import {HttpStatusCode} from 'axios';
import {SaveRequestParameters, SaveResponse, TestItem} from '../types/types';

export class CreateAssignmentSpecialistService extends CreateAssignmentService {
	public students = new BehaviorSubject<StudentInfoSpecialist[]>([]);
	public selectedStudents = new BehaviorSubject<StudentInfoSpecialist[]>([]);

	public groups = new BehaviorSubject<GroupSpecialistItem[]>([]);

	private initEditResponse: EditInitSpecialistResponse | undefined;

	public setSelectedStudents(students: StudentInfoSpecialist[]) {
		const uniqueStudents = uniq(students, (s) => s.id);
		this.selectedStudents.next(uniqueStudents);
	}

	public removeSelectedStudent(id: number) {
		this.setSelectedStudents(this.selectedStudents.value.filter((student) => student.id !== id));
	}

	public init(
		assignmentID?: number,
		goToAssignmentList?: VoidFunction,
		createAssignmentExtractedData?: CreateAssignmentExtractedSpecialistData | null,
	) {
		this.startInitResponse(assignmentID);

		if (assignmentID) {
			return this.httpClient.ESGIApi.get<EditInitSpecialistResponse>('assignments/edit/specialist', 'init', {
				assignmentID,
			})
				.withCustomErrorHandler((httpRequest) => {
					if (httpRequest.status === HttpStatusCode.InternalServerError) {
						goToAssignmentList?.();
					}
				})
				.pipe(
					tap((resp) => this.initForm(resp)),
					tap((resp) => (this.initEditResponse = resp)),
					tap((resp) => {
						this.nameFormGroup.controls.name.value = resp.assignment.name;
						this.nameFormGroup.controls.description.value = resp.assignment.description;
						this.selectedTestsIds.next(resp.assignment.tests);

						const students = this.students.value.filter((s) => resp.assignment.students?.includes(s.id));
						this.setSelectedStudents(students);
					}),
				);
		}

		return this.httpClient.ESGIApi.get<InitSpecialistResponse>('assignments/wizard/specialist', 'init').pipe(
			tap((resp) => this.initForm(resp, createAssignmentExtractedData)),
		);
	}

	private initForm(
		data: {tests: TestItem[]; groups: GroupSpecialistItem[]; assignment?: {name: string}},
		createAssignmentExtractedData?: CreateAssignmentExtractedSpecialistData | null,
	) {
		this.nameFormGroup.controls.name.validators.push(this.nameUniqueValidator(this.httpClient, data.assignment?.name));

		this.tests.next(data.tests);

		const students = uniq(data.groups.map(({students}) => students).flat(), ({id}) => id);

		const studentInfos = students.map((s) => {
			const studentGroups = data.groups.filter(({students}) => students.map(({id}) => id).includes(s.id));

			const studentInfo: StudentInfoSpecialist = {
				name: s.name,
				id: s.id,
				group: studentGroups.map(({name}) => name).join(','),
				hasCredentials: s.hasCredentials,
			};

			return studentInfo;
		});

		this.groups.next(data.groups);
		this.students.next(studentInfos);

		if (createAssignmentExtractedData) {
			this.createAssignmentExtractedData({
				assignmentName: createAssignmentExtractedData.name,
				assignmentDescription: createAssignmentExtractedData.description,
				selectedTestsIds: createAssignmentExtractedData.selectedTestsIds,
				selectedTestsFilters: createAssignmentExtractedData.selectedTestsFilters,
			});

			if (createAssignmentExtractedData.selectedStudents) {
				const selectedStudentsIds = createAssignmentExtractedData.selectedStudents.map(({id}) => id);

				this.selectedStudents.next(studentInfos.filter(({id}) => selectedStudentsIds.includes(id)));
			}
		}
	}

	public save(data: SaveRequestParameters): Observable<SaveResponse> {
		if (this.assignmentID) {
			return this.updateAssignment(data)
				.pipe(
					tap(() => {
						if (isEmpty(data.testIDs) || isEmpty(data.studentIDs)) {
							return;
						}

						if (
							(isEmpty(this.initEditResponse?.assignment.tests) ||
								isEmpty(this.initEditResponse?.assignment.students)) &&
							this.assignmentID
						) {
							this.trackNotDraftAssignmentEvent(this.assignmentID);
						}
					}),
				)
				.asObservable();
		}

		return this.createAssignment(data);
	}
}
