import {CSS} from '@stitches/react';


export function useTableRowStyles(header?: boolean, selected?: boolean): CSS {
	const commonCellStyles: CSS = {
		gridTemplateColumns: '8fr 3fr 2fr 1fr',
		paddingLeft: 11,
		paddingRight: 11,
	};

	const selectedRowStyles: CSS = {
		...commonCellStyles,
		paddingTop: 7,
		paddingBottom: 7,
		border: '1px solid $primaryMuted',
		borderRadius: 6,
		background: '$primary98',
	};

	if (selected) {
		return selectedRowStyles;
	}

	if (header) {
		return commonCellStyles;
	}

	return {
		...commonCellStyles,
		paddingTop: 7,
		paddingBottom: 7,
		borderRadius: 6,
		border: '1px solid $vivid',
		background: '$vivid',
	};
}