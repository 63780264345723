import React from 'react';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Checkbox} from '@esgillc/ui-kit/form-control';
import styles from '../styles.module.less';

interface Props {
	control: FormControl<boolean>;
}

export class ShareTestField extends React.PureComponent<Props> {
	public render() {
		return <div className={styles.field}>
			<div className={styles.fieldTitle}>
				Share Test:
			</div>
			<div className={styles.fieldContent}>
				<FormElement control={this.props.control}>
					{(props) => <div className={styles.shareTest}>
						<Checkbox/>
						<div className={styles.shareTestLabel}
						     onClick={() => props.setValue(!props.value)}>
							Share with District
						</div>
					</div>}
				</FormElement>
			</div>
		</div>;
	}
}
