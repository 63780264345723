import {ComponentType, RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {DrawerManager} from './types';
import {setRef, tryCall} from '@esgi/ui/utils';

type TargetProps = {
	open: boolean,
	onStateChanged: (open: boolean) => void,
}

type ManagerWrapperProps = {
	drawerRef?: RefObject<DrawerManager>,
}

type TransitionInfo = { callback?: VoidFunction, open: boolean };

export function withDrawerManager<T extends TargetProps>(Component: ComponentType<T>) {
	function DrawerManagerWrapper({drawerRef, ...props}: Omit<T, 'open' | 'onStateChanged'> & ManagerWrapperProps) {
		const [open, setOpen] = useState(true);
		const [transitionInfo, setTransitionInfo] = useState<TransitionInfo | undefined>(undefined);

		const toggleDrawer = useCallback((toggle: boolean, callback?: VoidFunction) => setOpen(open => {
			if (open === toggle) {
				tryCall(callback);
			} else {
				setTransitionInfo({callback, open: toggle});
			}
			return toggle;
		}), []);

		const manager = useRef(new DrawerManager(
			(callback) => toggleDrawer(true, callback),
			(callback) => toggleDrawer(false, callback),
		));

		useEffect(() => {
			if (drawerRef) {
				setRef(manager.current, drawerRef);
			}
		}, [drawerRef]);
		
		useEffect(() => () => manager.current?.destroy(), []);

		const handleTransitionEnd = useCallback(() => {
			tryCall(transitionInfo?.callback);
			setTransitionInfo(undefined);
		}, [transitionInfo]);
		
		// @ts-ignore
		return <Component open={open} onStateChanged={handleTransitionEnd} {...props}/>;
	}

	return DrawerManagerWrapper;
}