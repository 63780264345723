import {styled} from '@esgi/ui/theme';
import {Card, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Layout = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	gap: '40px',
	background: '$neutra88',
	height: '100%',
	width: '100%',
	padding: '16px 20px',
});

export const TextContainer = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	gap: '12px',
});

export const TitleText = styled(Text, {
	color: '$neutral6',
});

export const DescriptionText = styled(Text, {
	fontSize: '16px',
	color: '$neutral40',
});

export const NavigationItem = styled(Card, {
	display: 'inline-flex',
	gap: '$2',
	cursor: 'pointer',
	flexDirection: 'column',
	justifyContet: 'center',
	alignItems: 'center',
	padding: '20px',
	maxWidth: '273px',
	transition: 'color .3s',

	'&:hover': {
		color: '$secondary56',
	},
});

export const NavigationItemIcon = styled(FlexBox, {
	width: '32px',
	height: '32px',
});

export const NavigationItemText = styled(Text, {
	fontWeight: '400',
	textAlign: 'center',
});

export const EmptyBodyText = styled(Text, {
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $neutral99',
	background: '$neutral99',
	color: '$neutral40',
});
