import {Fade} from '@esgillc/ui-kit/transition';
import {QuestionImage} from './components/question-image';
import {QuestionLike} from './types';
import styles from './styles.module.less';

export type SliderProps = {
	questions: QuestionLike[];
	currentIndex: number;
	minIndex?: number;
	maxIndex?: number;
}


const isInRange = (itemIndex: number, minIndex?: number, maxIndex?: number) => {
	if(minIndex && itemIndex<minIndex){
		return false;
	}
	if(maxIndex && itemIndex>maxIndex){
		return false;
	}
	return true;
};
export function QuestionSlider({questions, maxIndex, minIndex, currentIndex}: SliderProps): JSX.Element {
	const currentQuestion = questions[currentIndex];

	if (!(questions && currentQuestion)) {
		return;
	}

	return (
		<div className={styles.tableHolder} data-cy='question-slider'>
			{questions.map((question, itemIndex) => (
				<div className={styles.imageWrapper} key={question.questionID}>
					{isInRange(itemIndex, minIndex, maxIndex) && (
						<Fade
							in={question.questionID === currentQuestion?.questionID}
							animateOnMount
							duration={'fastest'}
						>
							<QuestionImage
								id={question.questionID}
								directUrl={question.directUrl || question.url}
						    	fallbackUrl={question.fallbackUrl || question.url}
							/>
						</Fade>
					)}
				</div>
			))}
		</div>
	);
}
