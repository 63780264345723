import {PieChartModel} from '../models';

export class State {
	studentsTested: number;
	totalStudents: number;
	correctAnswers: number;
	totalPossible: number;
	color: string;
	testID: number;
	name: string;
	lastTestDate?: string;
	lastTestDateString: string;
	testType: string;
	canEdit: boolean;
	totalAnswers: number;
	isWhiteBackground: boolean;
	mouseDown: boolean;
	contextMenuOpened: boolean = false;
	selfAssessmentTestAllowed: boolean = false;
	showRemoveTestAlert: boolean = false;
	hasIEPGoal: boolean = false;
}

export const createStateFromPieChartModel = (initModel: PieChartModel): State =>
	Object.assign(new State(), initModel);

export const stateToPieChartConverter = (state: State): PieChartModel =>
	({...state});
