export enum ReportType {
	None = 'None',
	District = 'District',
	School = 'School',
	Class = 'Class',
	Group = 'Group',
	StudentsSchool = 'StudentsSchool',
	StudentsDistrict = 'StudentsDistrict',
	Teacher = 'Teacher',
	SpecialistGroup = 'SpecialistGroup',
  SchoolsGroup = 'SchoolsGroup',
  TeachersGroup = 'TeachersGroup',
	GroupOfSpecialists = 'GroupOfSpecialists',
}
