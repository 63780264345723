import {SelectableList} from '../lists/selectable-list';
import {SubjectTab} from '@esgi/main/libs/store';
import {ListWrapper} from './index.styled';

type Props = {
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: (subjectId: SubjectTab['id']) => void;
	subjects: SubjectTab[];
};

export function DefaultModeContent({selectedSubjectId, setSelectedSubjectId, subjects}: Props) {
	return (<ListWrapper>
			<SelectableList
				selectedSubjectId={selectedSubjectId}
				setSelectedSubjectId={setSelectedSubjectId}
				subjects={subjects}
			/>
		</ListWrapper>);
}
