import React from 'react';
import styles from './session-removed-modal.module.less';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';


interface SessionRemovedModalProps {
	okClicked: () => void;
}

export default class SessionRemovedModal extends React.Component<SessionRemovedModalProps> {
	private modalManagerRef: ModalManagerRefObject = React.createRef();

	private okClicked() {
		this.modalManagerRef.current.close(this.props.okClicked);
	}

	render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Header/>
			<Modal.Body>
				<div className={styles.bodyWrapper}>
					<span>Your test(s) have started on a new device.<br/>You can no longer test on this device.<br/>Please see your teacher for assistance.</span>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<LinkButton className={styles.button} onClick={() => this.okClicked()}>
					OK
				</LinkButton>
			</Modal.Footer>
		</Modal>;
	}
}
