import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupContent, ToggleGroupItem, ToggleGroupTitle} from './index.styled';
import {PossibleTabID, useTabsContext} from '../features';

type Props = {
	'data-cy'?: string,
}

export function TabsBar<T extends PossibleTabID>(props: Props) {
	const dataCy = props['data-cy'];
	const {active, setActive, availableTabs} = useTabsContext<T>();

	return <FlexBox justify='center'>
		<ToggleGroup.Root css={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} onValueChange={setActive} value={active} dataCy={dataCy}>
			<ToggleGroupContent dataCy={`${dataCy}-content`}>
				{availableTabs.map(({id, name, description, disabled}) => (
					<ToggleGroupItem
						value={id}
						valueDescription={description}
						key={id}
						disabled={active === id || disabled}
						dataCy={dataCy}
					>
						<Text size='medium' bold data-cy={dataCy}>
							{name}
						</Text>
					</ToggleGroupItem>
				))}
			</ToggleGroupContent>
			<ToggleGroupTitle dataCy={`${dataCy}-title`}>
				{({description}) => (
					<Text size='small' font='mono' data-cy={`${dataCy}-description`}>
						{description}
					</Text>
				)}
			</ToggleGroupTitle>
		</ToggleGroup.Root>
	</FlexBox>;
}