import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {GradeRange, TestInfo, TestSession, TrackDate} from '../types';
import {StudentInfo, StudentSort} from './types';
import {Student, SubjectType} from '@esgi/main/libs/store';
import {V2SessionDetailsController, V2TeachersTestDashboardsController} from '@esgi/contracts/esgi';

export class TestDashboard extends BaseService {
	public testInfo = new BehaviorSubject<TestInfo | null>(null);
	public gradeRanges = new BehaviorSubject<GradeRange[]>([]);
	public studentsInfo = new BehaviorSubject<StudentInfo[]>([]);
	public trackDates = new BehaviorSubject<TrackDate[]>([]);
	public studentSort = new BehaviorSubject(StudentSort.FirstName);

	private controller = new V2TeachersTestDashboardsController();
	private sessionDetailsController = new V2SessionDetailsController();

	public init({
		testID,
		studentIds,
		subjectID,
		subjectType,
	}: {
		testID: number;
		subjectID: number;
		subjectType: SubjectType;
		studentIds: Student['id'][];
	}) {
		return this.controller.byStudents({testID, studentIDs: studentIds, subjectID, subjectType}).pipe(
			tap(({studentsInfo, testInfo, trackDates, gradeRanges, studentSort}) => {
				this.testInfo.next(testInfo);
				this.gradeRanges.next(gradeRanges);
				this.studentsInfo.next(studentsInfo);
				this.trackDates.next(trackDates);
				this.studentSort.next(studentSort);
			}),
		);
	}

	public addTestSession({sessionData, studentID}: {sessionData: TestSession; studentID: Student['id']}) {
		const currentStudentsInfo = this.studentsInfo.value;

		this.studentsInfo.next(
			currentStudentsInfo.map((iteratedStudent) =>
				iteratedStudent.id === studentID
					? {
							...iteratedStudent,
							testSessionsInfo: {
								...iteratedStudent.testSessionsInfo,
								testSessions: [...iteratedStudent.testSessionsInfo.testSessions, sessionData],
							},
					  }
					: iteratedStudent,
			),
		);
	}

	public getSessionDataRequest({testSessionID}: {testSessionID: number}) {
		return this.sessionDetailsController.init({testSessionID});
	}

	public override dispose() {
		this.controller.dispose();
		this.sessionDetailsController.dispose();
	}
}
