import moment from 'moment';
import React, {createRef, RefObject} from 'react';
import {BaseInput} from '@esgi/deprecated/ui-kit/text-input';
import styles from './picker.module.less';

interface Props {
	id: string;
	date: string;
	endDate: string;
	onSelect: (value: string) => void;
}

class State {
}

export default class DateTimePicker extends React.PureComponent<Props, State> {
	private ref: HTMLInputElement;
	private containerRef: RefObject<HTMLDivElement> = createRef();

	public componentDidMount() {
		const format = 'M-D-YY h:mm A';

		let date = moment(this.props.date);
		const maxDate = moment(this.props.endDate);

		if (maxDate.date() < date.date()) {
			date = date = maxDate.clone();
		}

		$(this.ref).datetimepicker({
			defaultDate: date,
			maxDate: maxDate,
			useCurrent: false,
			format: format,
		}).on('dp.change', (e: any) => {
			if(!e.date) {
				$(this.ref).data('DateTimePicker').defaultDate(e.oldDate);
			} else {
				this.props.onSelect(e.date.format());
			}
		});
	}

	public render() {
		return <div ref={this.containerRef} className={styles.container}>
			<BaseInput ref={(r) => this.ref = r} type='text' autoComplete='off'/>
			<label className={styles.label} htmlFor={this.props.id} onClick={() => this.ref?.focus()}>
				<i className='fa fa-calendar'/>
			</label>
		</div>;
	}

	componentWillUnmount() {
		$(this.ref).datepicker('destroy');
	}
}
