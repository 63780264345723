import {useMemo} from 'react';
import {AllTabListVariant} from '@esgi/main/features/teacher/home';
import {DataSubjectsService} from '../../../services/data-subjects-service';
import {SubjectTab} from '@esgi/main/libs/store';

export function useAllTabLists({
	districtSubjects,
	schoolSubjects,
	personalSubjects,
	dataService,
	filterSubjects,
}: {
	districtSubjects: SubjectTab[];
	schoolSubjects: SubjectTab[];
	personalSubjects: SubjectTab[];
	dataService: DataSubjectsService;
	filterSubjects: (subjects: SubjectTab[]) => SubjectTab[];
}) {
	const allTabLists = useMemo<AllTabListVariant[]>(
		() => [
			{
				subjects: filterSubjects(districtSubjects),
				canEdit: false,
				canRearrange: false,
			},
			{
				subjects: filterSubjects(schoolSubjects),
				canEdit: false,
				canRearrange: false,
			},
			{
				subjects: personalSubjects,
				canEdit: true,
				canRearrange: true,
				updateSubjectsOrder: dataService.updatePersonalSubjectsOrder.bind(dataService),
			},
		],
		[districtSubjects, schoolSubjects, personalSubjects, dataService, filterSubjects],
	);

	return {allTabLists};
}
