import * as React from 'react';
import type {SVGProps} from 'react';

export function NewAssignment(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path fillRule='evenodd' clipRule='evenodd' d='M10.4063 15.375C10.0438 15.375 9.75 15.0872 9.75 14.7321C9.75 14.3771 10.0438 14.0893 10.4063 14.0893H10.9688C11.3312 14.0893 11.625 14.3771 11.625 14.7321C11.625 15.0872 11.3312 15.375 10.9688 15.375H10.4063Z' fill='#8F8F8F'/>
			<path d='M9.75 11.8929C9.75 12.2479 10.0438 12.5357 10.4063 12.5357H10.9688C11.3312 12.5357 11.625 12.2479 11.625 11.8929C11.625 11.5378 11.3312 11.25 10.9688 11.25H10.4063C10.0438 11.25 9.75 11.5378 9.75 11.8929Z' fill='#8F8F8F'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M15.9643 16.725C15.9643 17.0712 15.7161 17.3565 15.3964 17.3955L15.3214 17.4L8.67857 17.4C8.34889 17.4 8.07718 17.1394 8.04004 16.8037L8.03572 16.725L8.03571 7.275C8.03571 6.92884 8.28388 6.64353 8.6036 6.60454L8.67857 6.6L9.32143 6.6C9.65111 6.6 9.75 6.6 9.75 6.6L14.325 6.6C14.6978 6.6 15 6.29779 15 5.925C15 5.55221 14.6977 5.24953 14.3249 5.25083C14.1805 5.25133 14.1086 5.25214 14.25 5.25344V5.25L9.75 5.25344L9.32143 5.25L8.67857 5.25C7.65149 5.25 6.81193 6.09302 6.75327 7.15602L6.75 7.275L6.75 16.725C6.75 17.8034 7.55288 18.685 8.56525 18.7466L8.67857 18.75H15.3214C16.3485 18.75 17.1881 17.907 17.2467 16.844L17.25 16.725C17.25 16.3522 16.9622 16.05 16.6071 16.05C16.2521 16.05 15.9643 16.3522 15.9643 16.725Z' fill='#8F8F8F'/>
			<path d='M9.75 9C9.75 9.35505 10.0438 9.64286 10.4063 9.64286H10.9688C11.3312 9.64286 11.625 9.35505 11.625 9C11.625 8.64495 11.3312 8.35714 10.9688 8.35714H10.4063C10.0438 8.35714 9.75 8.64495 9.75 9Z' fill='#8F8F8F'/>
			<path d='M16.5 13.425C16.1688 13.425 15.9 13.1562 15.9 12.825V11.025H14.1C13.7688 11.025 13.5 10.7562 13.5 10.425C13.5 10.0938 13.7688 9.825 14.1 9.825H15.9V8.025C15.9 7.6938 16.1688 7.425 16.5 7.425C16.8312 7.425 17.1 7.6938 17.1 8.025V9.825H18.9C19.2312 9.825 19.5 10.0938 19.5 10.425C19.5 10.7562 19.2312 11.025 18.9 11.025H17.1V12.825C17.1 13.1562 16.8312 13.425 16.5 13.425Z' fill='#8F8F8F'/>
		</svg>
	);
}
