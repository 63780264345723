import './right-panel.less';
import React, {ReactNode} from 'react';


export class Props {
	className?: string;
	title?: string;
	titleClassName?: string;
	children: ReactNode;
} 

export class RightPanelBox extends React.PureComponent<Props> {
	render() {
		return <div className={this.props.className}>
			{this.props.title && <div className={this.props.titleClassName}>{this.props.title}</div>}
			{this.props.children}
		</div>;
	}
}
