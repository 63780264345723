import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const MoreButton = styled(Text, {
	width: 'fit-content',
	color: '$primary !important',
	textDecoration: 'underline',
	padding: '4px 0',
	marginTop: -4,
});

export const StyledText = styled(Text, {
	color: '$mediumContrast !important',
	maxHeight: 48,

	variants: {
		showFullDescription: {
			true: {
				maxHeight: 'none',
			},
			false: {
				overflow: 'hidden',
			},
		},
	},
});