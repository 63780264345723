import {useCallback, useEffect, useRef, useState} from 'react';
import {NavigateOptions, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {isEmpty, isEqual} from 'underscore';

export enum Route {
	CreateWizard = 'create',
	EditWizard = 'edit',
	View = 'view',
	List = 'list',
	ListInProgress = 'list/in-progress',
	ListNotStarted = 'list/not-started',
	ListCompleted = 'list/completed',
	ListDraft = 'list/draft',
	StudentCredentials = 'student-credentials',
}

export const dataRequiredRoutes = [Route.EditWizard, Route.View];

type Data = Partial<{
	id: string;
}>;

interface Return {
	route: Route | '';
	setRoute: (route: Route, data?: Data, navigateOptions?: NavigateOptions) => void;
	back: () => void;
	data: Data;
}

type CurrentRoute = {
	['*']: Route | '';
};

export function useNavigationRoutes(): Return {
	const navigate = useNavigate();

	const history = useRef<{route: Route; data: Data}[]>([]);

	const [searchParams] = useSearchParams();
	const {['*']: currentRoute} = useParams<CurrentRoute>();

	const [data, setData] = useState<Data>();

	useEffect(() => {
		const newData = convertSearchParamsToObject<Data>(searchParams);

		setData(newData);
	}, [searchParams]);

	useEffect(() => {
		const searchParamsData = convertSearchParamsToObject<Data>(searchParams);

		if (
			currentRoute === '' ||
			(!searchParamsData?.id && [Route.View, Route.EditWizard].includes(currentRoute)) ||
			(currentRoute === Route.List && !isEmpty(searchParamsData))
		) {
			navigate(Route.List, {replace: true});
		}
	}, [currentRoute]);

	const updateRoute = useCallback(
		(route: Route, newData?: Data, navigateOptions?: NavigateOptions) => {
			if (route === currentRoute && isEqual(data, newData)) {
				return;
			}

			const search = newData ? new URLSearchParams(newData).toString() : undefined;

			if (navigateOptions?.replace) {
				history.current.pop();
			}

			history.current.push({route: currentRoute as Route, data: data});

			navigate({pathname: route, search}, navigateOptions);
		},
		[currentRoute],
	);

	const handleStudentCredentialsRoute = useCallback(() => {
		while ([Route.CreateWizard, Route.StudentCredentials].includes(history.current.at(-1)?.route)) {
			history.current.pop();
		}

		const previousRoute = history.current.at(-1);

		if (!previousRoute) {
			updateRoute(Route.List, undefined, {replace: true});

			return;
		}

		updateRoute(previousRoute.route, previousRoute.data, {replace: true});
	}, [history]);

	const back = useCallback(() => {
		if (!history.current.length) {
			window.history.back();
			return;
		}

		const routeEntity = history.current.at(-1);

		if (currentRoute === Route.StudentCredentials && routeEntity.route === Route.CreateWizard) {
			handleStudentCredentialsRoute();

			return;
		}

		const isValid = validateRoute(routeEntity.route, routeEntity.data);

		if (isValid) {
			window.history.back();
		} else {
			back();
		}
	}, [history, currentRoute, handleStudentCredentialsRoute]);

	return {
		back,
		setRoute: updateRoute,
		route: currentRoute,
		data,
	};
}

function validateRoute(route: Route, data: Data): boolean {
	const requiredData = dataRequiredRoutes.includes(route);
	return (requiredData && !!data) || !requiredData;
}

const convertSearchParamsToObject = <T extends Partial<Record<string, string>>>(searchParams: URLSearchParams): T =>
	Object.fromEntries(searchParams.entries()) as unknown as T;
