import {TrackDateRow} from 'modules/track-confirm/types';
import moment from 'moment';

export class DateCorrector {
	public correctDates(trackDates: TrackDateRow[], index: number, isFrom: boolean) {
		trackDates = this.correctForward(trackDates, index, isFrom);
		trackDates = this.correctBackward(trackDates, index, isFrom);
		return trackDates;
	}

	private correctForward(trackDates: TrackDateRow[], index: number, isFrom: boolean) {
		if(isFrom) {
			if (trackDates[index].from.getTime() > trackDates[index].to.getTime()) {
				trackDates[index].to = this.correctNext(trackDates[index].from);
			}
			return this.correctForward(trackDates, index, false);
		} else {
			if (index + 1 !== trackDates.length) {
				if (trackDates[index].to.getTime() > trackDates[index + 1].from.getTime()) {
					trackDates[index + 1].from = this.correctNext(trackDates[index].to);
				}
				return this.correctForward(trackDates, index + 1, true);
			} else {
				return trackDates;
			}
		}
	}

	private correctBackward(trackDates: TrackDateRow[], index: number, isFrom: boolean) {
		if(isFrom) {
			if (index !== 0) {
				if (trackDates[index].from.getTime() < trackDates[index - 1].to.getTime() ||
					this.correctPrev(trackDates[index].from).getDate() !== trackDates[index - 1].to.getDate()) {
					trackDates[index - 1].to = this.correctPrev(trackDates[index].from);
				}
				return this.correctBackward(trackDates, index - 1, false);
			} else {
				return trackDates;
			}
		} else {
			if (trackDates[index].to.getTime() < trackDates[index].from.getTime()) {
				trackDates[index].from = this.correctPrev(trackDates[index].to);
			}
			return this.correctBackward(trackDates, index, true);
		}
	}

	private correctNext(date: Date): Date {
		return moment(date).add(1, 'days').toDate()
	}

	private correctPrev(date: Date): Date {
		return moment(date).add(-1, 'days').toDate()
	}
}
