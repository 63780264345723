import {useEffect, useState} from 'react';
import {FormControl, FormElement} from '@esgi/ui/form';
import {useStreamEffect} from '@esgi/ui/utils';
import {Text} from '@esgi/ui/typography';
import {Checkbox} from '@esgi/ui/form-controls';
import {SectionItem, Title, Section, ViewAndEditButton} from '../index.styled';

interface Props {
	includeParentMessage: FormControl;
	onViewAndEditTemplate: VoidFunction;
}

export function LetterTemplateControl({includeParentMessage, onViewAndEditTemplate}: Props) {

	const [isDisabled, setDisabled] = useState(includeParentMessage.value);

	useEffect(() => {
		setDisabled(!includeParentMessage.value);
	}, []);

	useStreamEffect(includeParentMessage.onChanged, (include) => {
		setDisabled(!include.currState.value);
	});

	return (
		<Section>
			<Title size='small' font='mono' color='lowContrast'>Letter Template</Title>

			<SectionItem>
				<FormElement control={includeParentMessage}>
					<Checkbox label='Include the Parent Message'/>
				</FormElement>
			</SectionItem>

			<ViewAndEditButton color='secondary' onClick={onViewAndEditTemplate} disabled={isDisabled}>
				<Text size='medium' color='base' bold>View & Edit Letter Template</Text>
			</ViewAndEditButton>
		</Section>
	);
}

