import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const OptionButtonsBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: '20px',
});

export const Divider = styled(Box, {
	height: '100%',
	width: '1px',
	border: '1px solid $mild',
	borderRadius: '1px',
});

export const DeleteButton = styled(Button.Icon, {
	color: '$neutral80',
});