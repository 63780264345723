import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';

export const Section = styled(Box, {
	padding: '0 20px',
});

export const SectionItem = styled(FlexBox, {
	flexDirection: 'column',
	padding:	'4px 8px 4px 4px',
	borderRadius: '6px',
	border: '1px solid $border',
	backgroundColor: '$vivid',

	'& > div': {
		marginBottom: 0,
	},
});

export const Title = styled(Text, {
	padding: '20px 0 12px',
	margin: '0 12px 12px',
	borderBottom: '1px solid $mild',
	textAlign: 'center',
	display: 'block',
});

export const ViewAndEditButton = styled(Button, {
	marginTop: '8px',
	width: '100%',
});
