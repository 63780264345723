import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';
import {DistrictModel} from '../../../../../types';

interface Props {
	isVisible: boolean;
	onClose: () => void;
	availableDistricts: DistrictModel[];
	setDistrictAvailableInList: (isAvailable: boolean) => void;
	setDontHaveDistrict: () => void;
	onSelect: (district: DistrictModel) => void
}

export function DistrictMenuList({
	                                 isVisible,
	                                 onClose,
	                                 availableDistricts,
	                                 setDistrictAvailableInList,
	                                 setDontHaveDistrict,
	                                 onSelect,
}: Props) {
	return <Menu className={join(styles.districtList, !isVisible && styles.listMenuClosed)}
	             show={isVisible}
	             onClickOutside={onClose}>
		{availableDistricts.map(district => <MenuItem key={district.id}
		                                              onSelect={() => {
														  onSelect(district);
														  onClose();
		                                              }}>
			{district.name}
		</MenuItem>)}
		<hr className={styles.listItemLine}/>
		<MenuItem onSelect={() => {
			setDistrictAvailableInList(false);
			onClose();
		}}>My district isn't listed</MenuItem>
		<MenuItem onSelect={() => {
			setDontHaveDistrict();
			onClose();
		}}>My school does not belong to district</MenuItem>
	</Menu>;
}
