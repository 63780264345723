import {getThemeColor} from '@esgi/ui';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {ProgressProps} from '../types';

type Props = Required<ProgressProps> & {
	linesGap: number;
};

export function DashedProgress({
	maxValue,
	activeLineColor,
	lastActiveLineBorderColor,
	notActiveLineColor,
	progressLineHeight,
	value,
	linesGap,
}: Props) {
	const notActiveLinesCount = maxValue - value;
	const activeLinesCount = Math.min(value, maxValue);

	return (
		<GridBox
			css={{
				width: '100%',
				gap: linesGap,
				gridTemplateColumns: `repeat(${maxValue}, 1fr)`,
			}}
			data-dashed-progress=''
		>
			{new Array(activeLinesCount).fill(null).map((_, index) => (
				<FlexBox
					css={{
						backgroundColor: getThemeColor(activeLineColor),
						borderRadius: 1,
						height: progressLineHeight,
						borderRightStyle: 'solid',
						borderRightWidth: index === activeLinesCount - 1 ? 2 : 0,
						borderRightColor: getThemeColor(lastActiveLineBorderColor),
					}}
					key={index}
				/>
			))}
			{notActiveLinesCount >= 1 &&
				new Array(notActiveLinesCount).fill(null).map((_, index) => (
					<FlexBox
						css={{
							backgroundColor: getThemeColor(notActiveLineColor),
							borderRadius: 1,
							height: progressLineHeight,
						}}
						key={value + index}
					/>
				))}
		</GridBox>
	);
}
