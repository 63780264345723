import {throttle} from 'underscore';
import {Tooltip, TooltipContentProps} from '@esgi/ui/tooltip';
import {BaseComponentProps} from '../../../types';
import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {ElementStatus, useFormElementContext} from '@esgillc/ui-kit/form';
import {Box} from '@esgi/ui/layout';
import {createPortal} from 'react-dom';

type ErrorTooltipProps = PropsWithChildren &
	BaseComponentProps &
	Omit<TooltipContentProps, 'dataCy' | 'css' | 'variant'> & {
		/**
		 * Your can use predefault value or your custom string
		 * @type {'required' | 'range' | 'email' | 'length-min' | 'length-max' | 'hex'}
		 */
		showOnError: 'required' | 'range' | 'email' | 'length-min' | 'length-max' | 'hex' | 'isDublicateValue' | string;
		notHide?: boolean;
	};

export function ErrorTooltip({
	dataCy = 'ui-kit-form-error-tooltip',
	css = {},
	children,
	sideOffset = -5,
	alignOffset = 5,
	align = 'start',
	showOnError,
	size = 'small',
	notHide,
	...contentProps
}: ErrorTooltipProps) {
	const [elementRect, setElementRect] = useState<{x: number; y: number; width: number; height: number}>();
	const [elementHovered, setElementHovered] = useState(false);
	const [elementFocused, setElementFocused] = useState(false);

	const [tooltipContentHovered, setTooltipContentHovered] = useState(false);

	const toggleTooltipContentHover = useCallback(() => {
		setTooltipContentHovered((isHover) => !isHover);
	}, []);

	const context = useFormElementContext({}, ErrorTooltip);
	const error = context.firstError();

	const toggleInputHovering = useCallback(() => {
		setElementHovered((isHover) => !isHover);
	}, []);

	const setElementFocus = useCallback(() => {
		setElementFocused(true);
	}, []);

	const resetElementFocus = useCallback(() => {
		setElementFocused(false);
	}, []);

	useEffect(() => {
		const element = context.ref as HTMLElement | undefined;

		if (!element) {
			return;
		}

		element.addEventListener('mouseenter', toggleInputHovering);
		element.addEventListener('mouseleave', toggleInputHovering);
		element.addEventListener('focus', setElementFocus);
		element.addEventListener('blur', resetElementFocus);

		return () => {
			element.removeEventListener('mouseenter', toggleInputHovering);
			element.removeEventListener('mouseleave', toggleInputHovering);
			element.removeEventListener('focus', setElementFocus);
			element.removeEventListener('blur', resetElementFocus);
		};
	}, [context.ref, setElementFocus, resetElementFocus, toggleInputHovering]);

	const isTooltipOpen =
		error === showOnError &&
		context.status !== ElementStatus.disabled &&
		Boolean(children) &&
		(elementHovered || elementFocused || tooltipContentHovered || Boolean(notHide));

	useEffect(() => {
		const element = context.ref as HTMLElement | undefined;

		if (element && isTooltipOpen) {
			const observer = new ResizeObserver(throttle(() => {
				const {width, height, left, top} = element.getBoundingClientRect();

				setElementRect({
					width,
					height,
					x: left + window.scrollX,
					y: top + window.scrollY,
				});
			}, 30));

			observer.observe(element);

			return () => {
				observer.disconnect();
			};
		}

		setElementRect(undefined);
	}, [context.ref, isTooltipOpen]);

	return createPortal(
		<Tooltip open={elementRect && isTooltipOpen}>
			<Tooltip.Trigger asChild>
				<Box
					css={{
						width: elementRect?.width,
						height: elementRect?.height,
						position: 'absolute',
						left: elementRect?.x,
						top: elementRect?.y,
						visibility: 'hidden',
					}}
				/>
			</Tooltip.Trigger>
			<Tooltip.Content
				dataCy={`${dataCy}-content`}
				variant='error'
				sideOffset={sideOffset}
				align={align}
				alignOffset={alignOffset}
				size={size}
				css={css}
				onMouseEnter={toggleTooltipContentHover}
				onMouseLeave={toggleTooltipContentHover}
				{...contentProps}
			>
				{children}
			</Tooltip.Content>
		</Tooltip>,
		document.body,
	);
}
