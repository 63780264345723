import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
}

export function AdvancedEditIcon({className}: Props) {
	return <svg data-cy='advanced-edit-icon' className={join(className)} xmlns='http://www.w3.org/2000/svg' width='14'
				height='14' viewBox='0 0 14 14' fill='none'>
		<path
			d='M0.25 10.9375V13.75H3.0625L11.3575 5.455L8.545 2.6425L0.25 10.9375ZM13.5325
			3.28C13.825 2.9875 13.825 2.515 13.5325 2.2225L11.7775 0.467498C11.485 0.174998
			11.0125 0.174998 10.72 0.467498L9.3475 1.84L12.16 4.6525L13.5325 3.28Z'
			fill='#828282'/>
	</svg>;
}
