import {ComponentPropsWithoutRef, forwardRef, useEffect, useMemo, useState} from 'react';
import {SelectableListContext, SelectableListContextValue, TriggerWithStyledButtonValue} from '../../context';
import {CSS} from '@stitches/react';
import {Div} from './index.styled';
import {useAutoControlledState} from '../../../../utils';

type SelectableListRootProps = ComponentPropsWithoutRef<'div'> & {
	/** Data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	isListOpen?: boolean

	onListOpenChange?: (isOpen: boolean) => void
};

export const SelectableListRoot = forwardRef<HTMLDivElement, SelectableListRootProps>(
	({dataCy = 'ui-kit-selectable-list-root', children, isListOpen: outsideListOpenState, onListOpenChange, ...props}, forwaredRef) => {
		const [isExpandableList, setIsExpandableList] = useState(false);
		const [isListOpen, setIsListOpen] = useAutoControlledState({initialState: true, controlledState: outsideListOpenState, onChange: onListOpenChange});
		const [triggerWithStyledButton, setTriggerWithStyledButton] = useState<TriggerWithStyledButtonValue>({
			exist: false,
			hovered: false,
		});

		const [isLoaded, setIsLoaded] = useState(false);

		useEffect(() => {
			setIsLoaded(true);
		}, []);

		const contextValue = useMemo<SelectableListContextValue>(
			() => ({
				isExpandableList,
				setIsExpandableList,
				isListOpen,
				setIsListOpen,
				triggerWithStyledButton,
				setTriggerWithStyledButton,
				isLoaded,
			}),
			[isListOpen, isExpandableList, triggerWithStyledButton, isLoaded],
		);

		return (
			<SelectableListContext.Provider value={contextValue}>
				<Div dataCy={dataCy} ref={forwaredRef} {...props}>
					{children}
				</Div>
			</SelectableListContext.Provider>
		);
	},
);
