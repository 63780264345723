import React from 'react';
import styles from '../../styles.module.less';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../../services/report-service';

interface Props {
	service: ReportService;
}

export function DetailReportSettingsGradeScale({service}: Props) {
	const report = useBehaviorSubject(service.dataService.report);
	return report.gradeValues != null && report.gradeValues.length != 0 && <>
		<div className={styles.settingsCell}>
			<div className={styles.title}>Grade Scale:</div>
			<div>
				<div className={styles.gradeScalesLegend}>
					{report.gradeValues.map(({description, name, color, id}) => (
						<div
							key={id}
							title={description}
							style={{'backgroundColor': id !== 0 ? color : '#fff'}}
							data-toggle='tooltip'>
							<span>{name}</span>
						</div>

					))}
				</div>
			</div>
		</div>
	</>;
}
