import React, {createRef} from 'react';
import {Button, PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import {ModalManagerRef} from '@esgi/deprecated/ui-kit/modal';
import {Alert, AlertBody, AlertFooter} from '@esgi/deprecated/ui-kit/modal/alert';

interface Props {
	onCancel: () => void;
	onContinue: () => void;
}

export class ConfirmSwitch extends React.PureComponent<Props> {
	private readonly modalManagerRef: ModalManagerRef = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef}>
			<AlertBody>
				<span>
					You have made changes to the template. Any changes will not be saved.
				</span>
			</AlertBody>
			<AlertFooter>
				<Button onClick={() => this.modalManagerRef.current.close(this.props.onCancel)}>
					CANCEL
				</Button>
				<PrimaryButton onClick={() => this.modalManagerRef.current.close(this.props.onContinue)}>
					CONTINUE
				</PrimaryButton>
			</AlertFooter>
		</Alert>;
	}
}
