import {useItemAnalysisService} from '../../../../hooks';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {useEventEffect, dispatchAppEvent} from '@esgillc/events';
import {useEffect, useState, useMemo} from 'react';
import {getChartOptions} from '../../../../utils';
import {ChartOptions} from '../../../../types';
import {ChartRenderedEvent, ChartPagesReadyEvent} from '../../../../events';
import {debounce} from 'underscore';
import {Chart} from './index.styled';

export function HighchartPdf() {
	const service = useItemAnalysisService();
	const pageQuestions = 30;
	const [page, setPage] = useState(-1);
	const [chartOptions, setChartOptions] = useState<any>(null);
	const sortOptionMode = useBehaviorSubject(service.sort$);
	const stackIncorrect = useBehaviorSubject(service.notTested$);
	const testResultsCorrectVerbiage = useBehaviorSubject(service.reportService.testResultsCorrectVerbiage$);
	const testResultsIncorrectVerbiage = useBehaviorSubject(service.reportService.testResultsIncorrectVerbiage$);
	const userLevel = service.reportService.level;
	const reportData = useBehaviorSubject(service.reportService.reportData$);
	const downloadingPdf = useBehaviorSubject(service.reportService.downloadingPdf$);
	const maxPage = useMemo(
		() => Math.floor(reportData.questions.length / pageQuestions),
		[reportData],
	);
	const min = useMemo(
		() => {
			const count = page * pageQuestions;
			if (count < 0) {
				return 0;
			}
			return count;
		},
		[page],
	);
	const max = useMemo(
		() => {
			const count = (page + 1) * pageQuestions;
			if (count > reportData.questions.length) {
				return reportData.questions.length;
			}
			return count;
		},
		[page],
	);

	useEffect(
		() =>{
			setPage(downloadingPdf ? 0 : -1);
			if (!downloadingPdf) {
				service.setPages([]);
			}
		},
		[downloadingPdf],
	);

	useEffect(() => {
		if (page < 0) {
			return;
		}
		const chartSize = calcChartSize();
		const chartOptions: ChartOptions = {
			chartSize,
			testName: '',
			reportData,
			sortOptionMode: sortOptionMode.toString(),
			userLevel,
			stackIncorrect,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			selectPoint: () => {},
			updateScrollbarDisplay: () => {},
			normalizeChart: () => {},
			updateTitleSize: () => {},
			clone: true,
		};
		setChartOptions(getChartOptions(chartOptions));
	}, [page]);

	useEventEffect(ChartRenderedEvent, debounce(() => {
		if (!downloadingPdf) {
			return;
		}
		const pages = service.getPages();
		const scrollbar: SVGGElement = document.querySelector('#iar-chart .highcharts-scrollbar');
		if (scrollbar && scrollbar.style.display === 'none') {
			const html = document.querySelector('#iar-chart .highcharts-container')?.innerHTML;
			pages[page] = html;
			service.setPages(pages);
			dispatchAppEvent(ChartPagesReadyEvent, new ChartPagesReadyEvent());
			return;
		}
		const html = document.querySelector('#pdf .highcharts-container')?.innerHTML;
		pages[page] = html;
		service.setPages(pages);
		if (page < maxPage) {
			setTimeout(() => setPage(page + 1), 200);
		} else {
			dispatchAppEvent(ChartPagesReadyEvent, new ChartPagesReadyEvent());
		}
	}, 600));

	const calcChartSize = () => {
		let width = 1200;
		const height = 480;
		const questions = max - min;
		if (questions < pageQuestions) {
			width = (questions * 30) + (questions * 10 + 20) + 120;
			if (width > 1200) {
				width = 1200;
			}
		}
		return {width, height, min, max};
	};

	return (
		<Chart id='pdf'>
			{chartOptions && <HighchartsReact options={chartOptions}/>}
		</Chart>
	);
}
