import * as React from 'react';
import type {SVGProps} from 'react';

export function Reset(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 9a6.963 6.963 0 0 0-4.374 1.535 1 1 0 0 1-1.252-1.56A8.963 8.963 0 0 1 16 7a9 9 0 0 1 9 8.999h-2a7 7 0 0 0-7-7Zm-9 7h2a7 7 0 0 0 11.375 5.465 1 1 0 0 1 1.25 1.56A9 9 0 0 1 7 16Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M26.193 16c.33 0 .525.376.327.64l-2.2 2.933a.4.4 0 0 1-.64 0l-2.2-2.933c-.198-.264-.001-.64.328-.64h4.385ZM5.808 16c-.33 0-.526-.376-.328-.64l2.2-2.933a.4.4 0 0 1 .64 0l2.2 2.933a.402.402 0 0 1-.326.64H5.808Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
