import {Outlet} from 'react-router-dom';
import {LayoutContainer} from './index.styled';
import {ActivitiesPanel} from './components/activities-panel';
import {Snackbar} from '@esgi/ui/snackbar';
import {useMemo} from 'react';
import {ActivitiesContext} from './outlet-context';

export function ActivitiesLayout() {
	const snackbarRef = Snackbar.useRef();

	const outletContext = useMemo<ActivitiesContext>(
		() => ({snackbarRef}),
		[snackbarRef],
	);

	return (
		<LayoutContainer>
			<ActivitiesPanel/>
			<Outlet context={outletContext}/>
			<Snackbar snackbarRef={snackbarRef} />
		</LayoutContainer>
	);
}
