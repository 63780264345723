import {Box, SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const SelectableEditableItem = styled(SelectableList.Item, {
	display: 'grid',
	gridTemplateColumns: '1fr auto',
	paddingRight: 7,

	variants: {
		updapdateVerticalPadding: {
			true: {
				paddingTop: 4,
				paddingBottom: 4,
			},
		},
	},
});

export const SelectableEditableItemContent = styled(Box, {
	alignSelf: 'center',
});
