import {useCallback, useEffect, useRef, useState} from 'react';
import {Header} from './components/header';
import {useService} from '@esgi/core/service';
import {EditAssignmentService} from './service';
import {Subscription} from 'rxjs';
import {useBehaviorSubject, useStreamEffect} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {RemoveAssignmentFromList, UpdateAssignmentInList} from '../../../layout/events';
import {isNull} from 'underscore';
import {Root} from '../index.styled';
import {LeftPanelBody, LeftPanelRoot} from '../../components/left-panel.styled';
import {StudentSelectionPanel} from '../../components/student-selection-panel';
import {useLocation} from 'react-router-dom';
import {State} from '../../../hooks/use-open-edit-assignment';
import {CentralPanelBody, CentralPanelRoot} from '../../components/central-panel.styled';
import {TestSelectionPanel} from '../../components/test-selection-panel';
import {RightPanelBody, RightPanelRoot} from '../../components/right-panel.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {GridBox} from '@esgi/ui/layout';
import {AssignmentSettings} from '../../components/assignment-settings';
import {AssignmentSelectedStudents} from '../../components/assignment-selected-students';
import {AssignmentSelectedTest} from '../../components/assignment-selected-tests';
import {useCommonAssignmentVariantState} from '../use-common-assignment-variant-state';
import {CommonAssignmentVariantProps} from '../types';
import {AssignmentState} from '@esgi/main/kits/assignments';

type Props = CommonAssignmentVariantProps & {
	assignmentID: number;
};

export function EditAssignment({
	assignmentID,
	snackbarRef,
	onOpenCredentialsForGroupStudents,
	onOpenStudentCredentials,
}: Props) {
	const {state} = useLocation();

	const [isAssignmentUpdating, setIsAssignmentUpdating] = useState(false);
	const initRequestSubscription = useRef<Subscription | null>(null);
	const [isAssignmentDataLoaded, setIsAssignmentDataLoaded] = useState(false);

	const {
		isAssignmentSettingsValid,
		selectedStudentsIDs,
		selectedTestsIDs,
		onToggleSelectedStudentsIDs,
		onToggleSelectedTestsIDs,
		navigateToAssignmentsList,
		assignmentName,
		setAssignmentName,
		assignmentDescription,
		setAssignmentDescription,
		setIsAssignmentSettingsValid,
		onClearAllSelectedStudentsIDs,
		removeStudentIDFromState,
		onSwapTests,
		removeTestIDFromState,
		navigateToAssignment,
		setSelectedStudentsIDs,
		setSelectedTestsIDs,
		isStoreDataLoaded,
		subjectsWithSelAssesTests,
		allSelfAsessTests,
		toggleEntityStudents,
	} = useCommonAssignmentVariantState();

	const service = useService(EditAssignmentService);

	useEffect(() => {
		setIsAssignmentDataLoaded(false);

		initRequestSubscription.current?.unsubscribe();

		initRequestSubscription.current = service.fetchAssignmentInfo({assignmentID}).subscribe(() => {
			setIsAssignmentDataLoaded(true);
		});

		return () => {
			initRequestSubscription.current?.unsubscribe();
		};
	}, [assignmentID]);

	const initialAssignmentData = useBehaviorSubject(service.initialAssignmentData$);

	useStreamEffect(service.initialAssignmentData$, (assignmentInfo) => {
		setAssignmentName(assignmentInfo?.name ?? '');
		setAssignmentDescription(assignmentInfo?.description ?? '');
		setSelectedStudentsIDs(assignmentInfo?.students.map(({id}) => id) ?? []);
		setSelectedTestsIDs(assignmentInfo?.tests.map(({id}) => id) ?? []);
	});

	const closeAssignment = useCallback(() => {
		if (!isNull(state) && (state as State).from === 'assignment') {
			navigateToAssignment({
				assignmentId: assignmentID,
				replace: true,
			});

			return;
		}

		navigateToAssignmentsList();
	}, [assignmentID, navigateToAssignment, navigateToAssignmentsList, state]);

	const onUpdateAssignment = useCallback(() => {
		setIsAssignmentUpdating(true);

		service
			.saveAssignment({
				assignmentID,
				name: assignmentName,
				description: assignmentDescription,
				studentIDs: selectedStudentsIDs,
				testIDs: selectedTestsIDs,
			})
			.subscribe({
				next: () => {
					snackbarRef.current?.showSnackbar(`${assignmentName} has been successfully updated.`);
					dispatchAppEvent(
						UpdateAssignmentInList,
						new UpdateAssignmentInList(assignmentID, {
							name: assignmentName,
							students: selectedStudentsIDs,
							tests: selectedTestsIDs.length,
						}),
					);
				},
				complete: () => {
					setIsAssignmentUpdating(false);
					closeAssignment();
				},
			});
	}, [
		assignmentDescription,
		assignmentID,
		assignmentName,
		closeAssignment,
		selectedStudentsIDs,
		selectedTestsIDs,
		service,
		snackbarRef,
	]);

	const onDeleteAssignment = useCallback(() => {
		service.deleteAssignment(assignmentID);

		dispatchAppEvent(RemoveAssignmentFromList, new RemoveAssignmentFromList(assignmentID));

		if (!isNull(initialAssignmentData)) {
			snackbarRef.current?.showSnackbar(`${initialAssignmentData.name} has been successfully deleted.`);
		}

		navigateToAssignmentsList();
	}, [service, assignmentID, snackbarRef, initialAssignmentData, navigateToAssignmentsList]);

	const isAssignmentLoaded = isStoreDataLoaded && isAssignmentDataLoaded;

	const isAssignmentDataTouched =
		assignmentName !== initialAssignmentData?.name ||
		assignmentDescription !== initialAssignmentData.description ||
		selectedStudentsIDs.length !== initialAssignmentData.students.length ||
		selectedTestsIDs.length !== initialAssignmentData.tests.length ||
		!initialAssignmentData.students.every(({id}) => selectedStudentsIDs.includes(id)) ||
		selectedTestsIDs.some((testID, index) => initialAssignmentData.tests[index]?.id !== testID);

	return (
		<>
			<Header
				skeleton={!isAssignmentLoaded}
				assignmentName={initialAssignmentData?.name ?? ''}
				assignmentState={initialAssignmentData?.state ?? AssignmentState.None}
				isSaveButtonDisabled={!isAssignmentDataTouched || !isAssignmentSettingsValid || isAssignmentUpdating}
				onDeleteAssignment={onDeleteAssignment}
				onSaveClick={onUpdateAssignment}
				onClose={closeAssignment}
			/>
			<Root>
				<LeftPanelRoot>
					<LeftPanelBody>
						<StudentSelectionPanel
							skeleton={!isAssignmentLoaded}
							isOpenFirstClassOnInit
							selectedStudentsIDs={selectedStudentsIDs}
							onStudentClick={onToggleSelectedStudentsIDs}
							toggleEntityStudents={toggleEntityStudents}
						/>
					</LeftPanelBody>
				</LeftPanelRoot>

				<CentralPanelRoot>
					<CentralPanelBody>
						<TestSelectionPanel
							onTestClick={onToggleSelectedTestsIDs}
							selectedTestsIDs={selectedTestsIDs}
							skeleton={!isAssignmentLoaded}
							subjectsWithSelAssesTests={subjectsWithSelAssesTests}
							allSelfAsessTests={allSelfAsessTests}
						/>
					</CentralPanelBody>
				</CentralPanelRoot>

				<RightPanelRoot>
					<RightPanelBody>
						<OverlayScrollbarsComponent
							defer
							style={{height: 'calc(100% + 0px)'}}
							options={{
								scrollbars: {
									autoHide: 'leave',
								},
							}}
						>
							<GridBox gap='5'>
								<AssignmentSettings
									assignmentName={assignmentName}
									initialAssignmentName={initialAssignmentData?.name ?? undefined}
									initialAssignmentDescription={initialAssignmentData?.description ?? undefined}
									onAssignmentNameChange={setAssignmentName}
									assignmentDescription={assignmentDescription}
									onAssignmentDescriptionChange={setAssignmentDescription}
									onValidValueChanged={setIsAssignmentSettingsValid}
									onValidateAssignmentName={(value) =>
										service.validateAssignmentName({value, initialValue: initialAssignmentData?.name ?? ''})
									}
									onValidateAssignmentDescription={(value)=>
										service.validateAssignmentDescription({value})
									}
									skeleton={!isAssignmentLoaded}
								/>

								<AssignmentSelectedStudents
									onClearAllClick={onClearAllSelectedStudentsIDs}
									onRemoveStudentByID={removeStudentIDFromState}
									selectedStudentsIDs={selectedStudentsIDs}
									onOpenCredentialsForGroupStudents={onOpenCredentialsForGroupStudents}
									onOpenStudentCredentials={onOpenStudentCredentials}
								/>

								<AssignmentSelectedTest
									allSelfAsessTests={allSelfAsessTests}
									selectedTestsIDs={selectedTestsIDs}
									onTestSwap={onSwapTests}
									onRemoveTestByID={removeTestIDFromState}
								/>
							</GridBox>
						</OverlayScrollbarsComponent>
					</RightPanelBody>
				</RightPanelRoot>
			</Root>
		</>
	);
}
