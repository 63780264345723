import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {isUndefined} from 'underscore';
import {Counter, InlineText} from './index.styled';

type Props = {
	label: string;
	itemsCount?: number;
	hovered?: boolean;
	selected?: boolean;
};

export function GroupItemContent({label, itemsCount, hovered, selected}: Props) {
	const withCounterBox = !isUndefined(itemsCount);

	return (
		<GridBox
			align='center'
			css={{
				gap: 6,
				gridTemplateColumns: withCounterBox ? 'auto 1fr' : undefined,
			}}
		>
			<InlineText size='large' hovered={hovered} selected={selected}>
				{label}
			</InlineText>
			{withCounterBox && (
				<Counter hovered={hovered} selected={selected}>
					<Text size='small' font='mono'>
						{itemsCount}
					</Text>
				</Counter>
			)}
		</GridBox>
	);
}
