import {ReactNode} from 'react';

export type SelectableListItem = {
	value: string,
	label: string,
	disabled: boolean
}

export * from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/pages/home/sections/subjects/init/subject-tab';
export * from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/groups/group';
export * from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/classes/class';
export * from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/pages/assignments/subjects-info/subject';
export * from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/pages/assignments/subjects-info/test';
export {type Response as ReportResponse} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/rubric-results/build/response';
export {type Response as InitReportResponse} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/rubric-results/init/response';
export {type Request as BuildReportRequest} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/rubric-results/build/request';

export enum MarkingPeriod {
	All,
	Current,
}

export enum TabId {
	Classes = 'classes',
	Groups = 'groups',
	Students = 'students',
}

export interface MarkingPeriodItem {
	index: number;
	title: string;
}

export enum NotTested {
	NT,
	Zero,
}

export interface UnitData {
	id: number;
	type: UnitType;
	name?: string;
}

export interface StudentWithUnit {
	studentId: number;
	unitData: UnitData,
}

export enum UnitType {
	Class,
	Group,
	None,
}

export interface Column<RowDataT> {
	fixedColumnPosition?: FixedColumnPosition;
	className?: string;
	headers: HeaderCell[][];
	cells: Cell<RowDataT>[];
}

export interface Cell<RowDataT> {
	className?: string;
	renderer: (row: RowDataT) => ReactNode;
}

export enum FixedColumnPosition {
	Right,
	Left,
}

export interface HeaderCell {
	className?: string;
	renderer: () => ReactNode;
}