import {Dispatch, useMemo} from 'react';
import {CardBody} from '../components/card-body';
import {CardHeader} from '../components/card-header';
import {CardRoot} from '../components/card-root';
import {AverageProgress, StudentsTested} from '../../../../progress-bars';
import {useAnalyticsDataItems} from '../../../../../hooks';
import {EntityType, TestGroupStudentsModel} from '../../../../../../../types';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {Tooltip} from '@esgi/ui';
import {TestSectionGroupStudents} from '../../../../../types';
import {Text} from '@esgi/ui/typography';
import {onCardHoverText} from '../../constants';

type Props = TestGroupStudentsModel & {
	onTestCardClicked: VoidFunction;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	openTestDetails: VoidFunction;
	isSmallScreen: boolean;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
	statisticEntity: TestSectionGroupStudents['statisticEntity'];
};

export function GroupStudentsTestCard({
	onTestCardClicked,
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	isSmallScreen,
	onTestMoveTo,
	onRemoveTest,
	testInfo,
	analyticsData,
	classResult,
	studentsResult,
	lastTestSessions,
	classID,
	subjectID,
	statisticEntity,
}: Props) {
	const {analyticsDataItems} = useAnalyticsDataItems({testInfo, analyticsData});

	const statisticLabel = useMemo(() => (statisticEntity === 'group' ? 'Group' : 'Class'), [statisticEntity]);

	const progressBarsItems = useMemo<React.JSX.Element[]>(() => {
		return [
			<StudentsTested
				value={studentsResult.testedStudents}
				maxValue={studentsResult.totalStudents}
				result={studentsResult?.testedStudents ?? null}
			/>,
			<AverageProgress
				value={classResult.result}
				maxValue={testInfo.totalPossible}
				testType={testInfo.type}
				deltaIndicatorValue={undefined}
				result={!classResult?.studentsTested ? null : classResult.result}
				label={`${statisticLabel} Average`}
				onHoverText={`Difference between ${statisticLabel.toLocaleLowerCase()} and student performance`}
			/>,
		];
	}, [studentsResult, testInfo, classResult, statisticLabel]);

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<CardRoot onClick={onTestCardClicked} dataCy='test-card'>
					<CardHeader
						withSelfAssess={testInfo.selfAssessmentTestAllowed}
						testInfo={testInfo}
						lastTestSessions={lastTestSessions}
						sessionsCount={analyticsData?.sessionCount ?? 0}
						userCanEditTest={userCanEditTest}
						userCanRemoveTest={userCanRemoveTest}
						openTestDetails={openTestDetails}
						isSmallScreen={isSmallScreen}
						onTestMoveTo={onTestMoveTo}
						onRemoveTest={onRemoveTest}
						entityType={EntityType.ClassGroup}
						subjectID={subjectID}
						classID={classID}
					/>
					<CardBody
						progressBars={progressBarsItems}
						textWithTranscriptItems={analyticsDataItems}
						isSmallScreen={isSmallScreen}
						lastTestSessions={lastTestSessions}
						sessionsCount={analyticsData?.sessionCount ?? 0}
						testInfo={testInfo}
						withSelfAssess={testInfo.selfAssessmentTestAllowed}
						openTestDetails={openTestDetails}
						userCanEditTest={userCanEditTest}
						userCanRemoveTest={userCanRemoveTest}
						onTestMoveTo={onTestMoveTo}
						onRemoveTest={onRemoveTest}
						entityType={EntityType.ClassGroup}
						subjectID={subjectID}
						classID={classID}
					/>
				</CardRoot>
			</Tooltip.Trigger>
			<Tooltip.Content side='top'>
				<Text size='large' color='tertiary'>
					{onCardHoverText}
				</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
