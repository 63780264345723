import {GridBox} from '@esgi/ui/layout';
import {SkeletonStylesWithBorders} from '@esgi/ui/skeleton';
import {styled} from '@stitches/react';
import {Input} from '@esgi/ui/form-controls';

export const EyeIconWrapper = styled(GridBox, {
	padding: '6px 8px',
	border: '1px solid $mild',
	backgroundColor: '$vivid',
	borderRadius: '6px',
	width: '40px',
	height: '40px',
	cursor: 'pointer',
	transition: 'background-color .2s ease, border-color .2s ease',
	alignItems: 'center',
	justifyContent: 'center',

	'& svg': {
		fill: '$base',

		'& path': {
			fill: '$base',
		},
	},

	'&:hover': {
		borderColor: '$secondary92',
		backgroundColor: '$secondaryBackground',

		'& svg': {
			fill: '$secondary',

			'& path': {
				fill: '$secondary',
			},
		},
	},

	variants: {
		active: {
			true: {
				borderColor: '$primaryMuted',

				'& svg': {
					fill: '$primary',

					'& path': {
						fill: '$primary',
					},
				},
			},
		},

		disabled: {

			true: {
				'& svg': {
					fill: '$border',

					'& path': {
						fill: '$border',
					},
				},

				'&:hover': {
					borderColor: '$border',
					backgroundColor: '$vivid',

					'& svg': {
						fill: '$border',

						'& path': {
							fill: '$border',
						},
					},
				},
			},
		},

		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});

export const StudentCredentialsWrapper = styled(GridBox, {
	gridTemplateColumns: '1fr 1fr auto',
});

export const PasswordInput = styled(Input.Base, {
	variants: {
		hide: {
			true: {
				WebkitTextSecurity: 'disc',
			},
		},
	},
});