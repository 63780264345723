import {ChangeEvent, FocusEvent, MouseEvent, forwardRef, useCallback, useRef} from 'react';
import {Close} from '@esgi/ui/icons';
import {Input, InputProps} from '../input';
import {useAutoControlledState, useComposedRefs} from '@esgi/ui/utils';
import {Button} from '../../../../buttons';

export type InputCleanableProps = InputProps & {
	/**
	 * Simple click handler on Clear Button.
	 */
	onClearClick?: VoidFunction;
};

export const InputCleanable = forwardRef<HTMLInputElement, InputCleanableProps>(
	(
		{
			dataCy = 'ui-kit-input-cleanable',
			css,
			onChange,
			onBlur,
			onFocus,
			disabled,
			value: externalValue,
			focused: externalFocused,
			onClearClick,
			...props
		},
		forwardedRef,
	) => {
		const [value, setValue] = useAutoControlledState({
			initialState: '',
			controlledState: externalValue,
		});

		const [focused, setFocused] = useAutoControlledState({
			initialState: false,
			controlledState: externalFocused,
		});

		const inputOwnRef = useRef<HTMLInputElement>(null);
		const inputRef = useComposedRefs(forwardedRef, inputOwnRef);

		const handleChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				const value = event.target.value;

				setValue(value);
				onChange?.(event);
			},
			[onChange, setValue],
		);

		const handleFocus = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(true);

				onFocus?.(event);
			},
			[onFocus, setFocused],
		);

		const handleBlur = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(false);
				onBlur?.(event);
			},
			[onBlur, setFocused],
		);

		const handleClearClick = useCallback(() => {
			setValue('');

			inputOwnRef.current?.focus();

			onClearClick?.();
		}, [setValue, onClearClick]);

		const handleClearableButtomMouseDown = useCallback((event: MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		}, []);

		return (
			<Input
				dataCy={dataCy}
				type={props.type || 'text'}
				css={{
					...css,
					'& input': {
						paddingRight: 36,
					},
				}}
				onChange={handleChange}
				value={value}
				onFocus={handleFocus}
				focused={focused}
				onBlur={handleBlur}
				ref={inputRef}
				disabled={disabled}
				{...props}
			>
				{!disabled && Boolean(value) && focused && (
					<Button.Icon
						dataCy={`${dataCy}-button`}
						withBackgroundHover
						css={{
							width: 24,
							height: 24,
							position: 'absolute',
							top: 0,
							bottom: 0,
							right: 12,
							margin: 'auto',
						}}
						onClick={handleClearClick}
						onMouseDown={handleClearableButtomMouseDown}
					>
						<Close width='100%' height='100%' />
					</Button.Icon>
				)}
			</Input>
		);
	},
);
