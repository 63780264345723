import {Student} from '@esgi/main/libs/store';

export function getStudentResult({
	students,
	tests,
	testSessions,
}: {
	students: Student['id'][];
	tests: number;
	testSessions: number;
}) {
	const maxValue = students.length * tests;

	if (!maxValue || !testSessions) {
		return 0;
	}

	return (testSessions / maxValue) * 100;
}
