import type {SVGProps} from 'react';

export function DeleteRounded(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='22' height='22' viewBox='0 0 22 22' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.84354 7.52632V14.4737C7.84354 15.1713 8.40907 15.7368 9.1067 15.7368H12.8962C13.5938 15.7368 14.1593 15.1713 14.1593 14.4737V7.52632H15.4225V14.4737C15.4225 15.8689 14.2914 17 12.8962 17H9.1067C7.71145 17 6.58038 15.8689 6.58038 14.4737V7.52632H7.84354Z'
				fill='currentColor'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 5C10.3021 5 9.73689 5.56361 9.73689 6.25948H6.57899C6.23036 6.25948 5.94741 6.5416 5.94741 6.88921C5.94741 7.23683 6.23036 7.51895 6.57899 7.51895H15.4211C15.7697 7.51895 16.0527 7.23683 16.0527 6.88921C16.0527 6.5416 15.7697 6.25948 15.4211 6.25948H12.2632C12.2632 5.56361 11.6979 5 11 5ZM9.73828 8.80788C10.0871 8.80788 10.3699 9.08982 10.3699 9.43761V13.8439C10.3699 14.1917 10.0871 14.4737 9.73828 14.4737C9.38947 14.4737 9.1067 14.1917 9.1067 13.8439V9.43761C9.1067 9.08982 9.38947 8.80788 9.73828 8.80788ZM12.8962 9.43761C12.8962 9.08982 12.6134 8.80788 12.2646 8.80788C11.9158 8.80788 11.633 9.08982 11.633 9.43761V13.8439C11.633 14.1917 11.9158 14.4737 12.2646 14.4737C12.6134 14.4737 12.8962 14.1917 12.8962 13.8439V9.43761Z'
				fill='currentColor'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.875 11C21.875 17.0061 17.0061 21.875 11 21.875C4.9939 21.875 0.125 17.0061 0.125 11C0.125 4.9939 4.9939 0.125 11 0.125C17.0061 0.125 21.875 4.9939 21.875 11ZM11 20.75C16.3848 20.75 20.75 16.3848 20.75 11C20.75 5.61522 16.3848 1.25 11 1.25C5.61522 1.25 1.25 5.61522 1.25 11C1.25 16.3848 5.61522 20.75 11 20.75Z'
				fill='currentColor'
			/>
		</svg>
	);
}
