import {ReactNode} from 'react';
import {
	ModeContext,
	ModeContextValue,
	PossiblePermissionsType,
	TabsContext,
	TabsContextValue,
	useModeFeature,
	useTabsFeature,
} from './features';
import {Container} from '../styled';

type Children<Tab extends string, Mode extends string, PermissionsType extends PossiblePermissionsType> = ((context: { tabs: TabsContextValue<Tab>, mode: ModeContextValue<Mode, PermissionsType> }) => ReactNode) | ReactNode;

type Props<Tab extends string, Mode extends string, PermissionsType extends PossiblePermissionsType> = {
	initialTab: Tab,
	initialMode: Mode,
	children: Children<Tab, Mode, PermissionsType>,
};

function resolveChildren<Tab extends string, Mode extends string, PermissionsType extends PossiblePermissionsType>(children: Children<Tab, Mode, PermissionsType>, tabs: TabsContextValue<Tab>, mode: ModeContextValue<Mode, PermissionsType>) {
	if (typeof children === 'function') {
		return children({tabs, mode});
	}

	return children;
}

export function Root<Tab extends string, Mode extends string, PermissionsType extends PossiblePermissionsType>({initialTab, initialMode, children}: Props<Tab, Mode, PermissionsType>) {
	const {
		tab,
		setTab,
		tabInfos,
		setTabInfos,
		context: tabsContext,
	} = useTabsFeature<Tab>(initialTab);

	const {
		mode,
		setMode,
		context: modeContext,
	} = useModeFeature<Mode, PermissionsType>(initialMode);

	return <Container>
		<TabsContext.Provider value={tabsContext}>
			<ModeContext.Provider value={modeContext}>
				{resolveChildren(children, tabsContext, modeContext)}
			</ModeContext.Provider>
		</TabsContext.Provider>
	</Container>;
}