import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string,
	onClick?: () => void
}

export function RandomizeIcon({className, onClick}: Props) {
	return <svg onClick={onClick} className={join(className)} xmlns='http://www.w3.org/2000/svg' width='16' height='16'
				viewBox='0 0 16 16'
				fill='none'>
		<path
			d='M6.59 5.17L1.41 0L0 1.41L5.17 6.58L6.59 5.17ZM10.5 0L12.54 2.04L0 14.59L1.41 16L13.96
			3.46L16 5.5V0H10.5ZM10.83 9.41L9.42 10.82L12.55 13.95L10.5 16H16V10.5L13.96 12.54L10.83 9.41Z'
			fill='#0088CC'/>
	</svg>;
}
