import {SkeletonStylesWithBorders} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';
import {FlexBox, Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const StudentDetailsContainer = styled(FlexBox, {
	alignItems: 'center',
	marginBottom: '20px',
});

export const StudentInfo = styled(Box, {
	paddingLeft: '20px',
});

export const StudentName = styled(Text, {
	marginBottom: '4px',
	color: '$neutral40',

	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});

export const StudentId = styled(Text, {
	color: '$neutral63',
	marginLeft: '2px',
});

export const ButtonIcon = styled(Button.Icon, {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '100%',

	'& svg': {
		width: '24px',
		height: '24px',
	},
});

export const StudentIdContainer = styled(FlexBox, {
	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});
