import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Wrapper = styled(GridBox, {
	gridTemplateColumns: 'repeat(2, 272px)',
	overflow: 'hidden',
});

export const Title = styled(Text, {
	textAlign: 'center',
	padding: '12px 0',
	margin: '0 16px',
	borderBottom: '1px solid $mild',
});