import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$background',
	backgroundColor: '$background',
	gridTemplateColumns: 'auto auto 1fr',
	gap: '$3',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: 8,
	paddingBottom: 8,
	paddingLeft: 12,
	paddingRight: 12,
	minHeight: 41,
});

export const DurationBox = styled(GridBox, {
	justifySelf: 'end',
	gap: '$3',
	alignItems: 'center',
	gridAutoFlow: 'column',
});

export const TimeTextBox = styled(GridBox, {
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 2,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$tertiaryHighlight',
	backgroundColor: '$tertiarySurface',
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
});
