import {useCallback, useMemo} from 'react';
import {GroupTypeMultiple, GroupTypeSingle} from './types';
import {TestSession} from '../../../../../../../types';

export function useSessionListData(props: GroupTypeSingle | GroupTypeMultiple) {
	const toggleValueInState = useCallback(
		(selectedItemId: TestSession['id']) => {
			if (props.type === 'multiple') {
				const {setSelectedSessionIds} = props;

				setSelectedSessionIds((currentState) => {
					if (currentState.includes(selectedItemId)) {
						return currentState.filter((item) => item !== selectedItemId);
					}

					return [...currentState, selectedItemId];
				});

				return;
			}

			const {setSelectedSessionId} = props;

			setSelectedSessionId(selectedItemId);
		},
		[props],
	);

	const groupRootProps = useMemo(() => {
		if (props.type === 'multiple') {
			const {selectedSessionIds} = props;

			return {
				type: props.type,
				value: selectedSessionIds.map(String),
			};
		}

		const {selectedSessionId} = props;

		return {
			type: props.type,
			value: String(selectedSessionId),
		};
	}, [props]);

	const isSessionItemSelected = useCallback(
		(sessionId: TestSession['id']) => {
			if (props.type === 'multiple') {
				const {selectedSessionIds} = props;

				return {
					isItemSelected: selectedSessionIds.includes(sessionId),
					forceSelect: undefined,
				};
			}

			const {selectedSessionId} = props;

			return {
				isItemSelected: selectedSessionId === sessionId,
				forceSelect: selectedSessionId !== sessionId,
			};
		},
		[props],
	);

	return {
		toggleValueInState,
		groupRootProps,
		isSessionItemSelected,
	};
}
