import {TestScopeTabId, TestTypeTabId} from './types';
import {TabItem} from '@esgi/main/features/teacher/home';

export const testTypeTabItems: Pick<TabItem<TestTypeTabId>, 'value'| 'label' | 'dataCy'>[] = [
	{
		value: TestTypeTabId.All,
		label: 'All',
		dataCy: 'test-type-tab-item-all',
	},
	{
		value: TestTypeTabId.YN,
		label: 'Yes/No',
		dataCy: 'test-type-tab-item-yes-no',
	},
	{
		value: TestTypeTabId.Rubric,
		label: 'Rubric',
		dataCy: 'test-type-tab-item-rubric',
	},
	{
		value: TestTypeTabId.Score,
		label: 'Single Score',
		dataCy: 'test-type-tab-item-single-score',
	},
];

export const testScopeTabItems: Pick<TabItem<TestScopeTabId>, 'value'| 'label' | 'dataCy'>[] = [
	{
		value: TestScopeTabId.All,
		label: 'All',
		dataCy: 'test-scope-tab-item-all',
	},
	{
		value: TestScopeTabId.District,
		label: 'District',
		dataCy: 'test-scope-tab-item-district',
	},
	{
		value: TestScopeTabId.MyTests,
		label: 'My Tests',
		dataCy: 'test-scope-tab-item-myTests',
	},
];