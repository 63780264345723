import {resolvedPromise} from '@esgi/deprecated/utils';
import {observable, observableArray, FormField, Validators} from '@esgi/deprecated/knockout';
import {FieldContainer, FieldTool} from '../../../kit/component';
import {DistrictSearchModel, SelectionType} from '../../models';
import {Dictionaries} from '../../server';
import {SchoolInfo} from '../school-select';

export class DistrictSelectComponent extends FieldContainer<DistrictSearchModel> {
	@observable()
	public selectedDistrict: DistrictSearchModel;
	@observable()
	public selectionType: SelectionType = SelectionType.Existing;
	@observable()
	protected stateID: number;
	@observableArray()
	protected source: DistrictSearchModel[];

	public declare inputText: KnockoutObservable<string>;
	protected schoolInfo: SchoolInfo = {selectionType: SelectionType.Existing, name: ''};

	private $element: JQuery;

	constructor() {
		super();
		this.source = new Array<DistrictSearchModel>();
	}

	buildField(initValue?): FormField<DistrictSearchModel> {
		this.inputText = ko.observable<string>();
		const validator = Validators.Custom((f) => {
			if (this.selectionType === SelectionType.New && this.inputText()) {
				return resolvedPromise(true);
			}
			if (this.selectionType === SelectionType.Existing && this.selectedDistrict) {
				return resolvedPromise(true);
			}
			if (this.selectionType === SelectionType.Skip) {
				return resolvedPromise(true);
			}
			return resolvedPromise(false);
		}, (r) => '<div>Please enter a district or select</div><div>"My school does not belong to a District"</div>');

		const f = this.createField<DistrictSearchModel>(initValue, validator);
		f.validation.showValidation(true);
		f.validation.successValidation(true);
		f.validation.validationMessageTitleUseHtml = true;
		f.disabled = ko.computed({
			read: () => this.selectionType === SelectionType.Skip || !!this.selectedDistrict,
			deferEvaluation: true,
		});
		this.inputText.subscribe((v) => {
			if (!v) {
				this.clearValue();
				this.$element.trigger('focus');
			}
		});
		return f;
	}

	setState(newValue: number) {
		if (newValue) {
			this.stateID = newValue;
			if (newValue != this.stateID && this.schoolInfo.selectionType === SelectionType.New) {
				this.loadDistricts();
				this.$element.typeahead('hide');
			}
		}
	}


	setSchoolInfo(value: SchoolInfo) {
		if (value.selectionType === SelectionType.New) { // hide this field. so cleanup all
			this.clearValue();
			if (this.schoolInfo.selectionType !== value.selectionType) {
				this.loadDistricts();
			}
		} else if (this.selectionType === SelectionType.Skip && value.name) {
			this.inputText(value.name);
		} else {
			this.clearValue();
		}

		this.schoolInfo = value;
	}

	destroy() {
		this.clearValue();
		this.removeTooltip();
	}

	clearValue() {
		if (this.inputText()) {
			this.inputText('');
		}
		if (this.field.value()) {
			this.field.value(null);
		}
		this.selectionType = SelectionType.Existing;
		this.selectedDistrict = null;
		this.field.validation.successValidation(true);
		this.typehead();
	}

	loadDistricts(): void {
		if (this.stateID) {
			Dictionaries.loadDistricts(this.stateID).done(response => {
				this.source = response.federalDistricts.map(s => DistrictSearchModel.FromResponse(s));
				this.clearValue();
			});
		}
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		this.$element = $('input#district');
		setTimeout(() => this.typehead(), 100);
		return super.afterRender(rootElement);
	}

	addDistrict() {
		this.selectionType = SelectionType.New;
		this.$element.typeahead('destroy');
		this.selectedDistrict = null;
	}

	skipDistrict() {
		this.selectionType = SelectionType.Skip;
		this.inputText(this.schoolInfo.name);
	}

	serialize(): DistrictSearchModel {
		if (this.selectionType === SelectionType.New || this.selectionType === SelectionType.Skip) {
			return {id: 0, name: this.inputText()};
		} else {
			return this.selectedDistrict;
		}
	}

	typehead() {
		if (this.$element) {
			this.$element.typeahead({
				minLength: 1,
				autoSelect: false,
				showEmpty: true,
				disableEsc: true,
				source: (query, process) => {
					if (!query.trim()) {
						return;
					}
					query = query.replace(/[^a-zA-Z0-9\s]/g, '');
					const substrRegex = new RegExp(query, 'i');
					const result = this.source.filter(d => substrRegex.test(d.name));
					process(result);
				},
				afterSelect: (a: DistrictSearchModel, typeahead: any) => {
					if (a.id) {
						this.selectionType = SelectionType.Existing;
						this.selectedDistrict = a;
					}

					//Scroll to the field if the user has scrolled the page and the field is not in sight. Mostly relevant for mobile devices with a small screen resolution.
					const scrollPos = $(window).scrollTop() + 140;
					if (this.field.rootElement.offset().top <= scrollPos) {
						FieldTool.scrollToField(this.field);
					}
				},
				afterShow: (typeahead) => {
					typeahead.$menu.removeClass('required');
					typeahead.$menu.bstooltip('destroy');
					const addNew = $('<li><hr><a class="dropdown-item non-option" onmousedown="return false" href="#" role="option">My district isn\'t listed</a></li>');
					addNew.prop('not-option', true);
					addNew.prop('add-new', true);
					$('a', addNew).click(() => {
						if (this.inputText() && this.inputText().trim()) {
							typeahead.hide();
							typeahead.$menu.bstooltip('destroy');
							this.addDistrict();
						}
						return false;
					});
					typeahead.$menu.append(addNew);
					const skip = $('<li><a class="dropdown-item non-option" onmousedown="return false" href="#" role="option">My school does not belong to a district</a></li>');
					skip.prop('not-option', true);
					skip.prop('skip', true);
					$('a', skip).click(() => {
						typeahead.hide();
						typeahead.$menu.bstooltip('destroy');
						this.skipDistrict();
						return false;
					});
					typeahead.$menu.append(skip);
				},
				notOptionSelected: (typeahead, element) => {
					if (element.prop('add-new')) {
						typeahead.hide();
						typeahead.$menu.bstooltip('destroy');
						this.addDistrict();
					}
					if (element.prop('skip')) {
						typeahead.hide();
						typeahead.$menu.bstooltip('destroy');
						this.skipDistrict();
					}
				},
				blur: (typeahead: any) => {
					this.field.validation.validate()
						.then(r => {
							if (r) {
								typeahead.innerblur();
							}
						});

				},
				afterHide: (typeahead: any) => {
					typeahead.$menu.bstooltip('destroy');
				},
			});
		}
	}

	template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group district'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<input spellCheck={false} autoComplete='off' id='district' name='district' type='text'
					       className='form-control input-md'
					       data-bind='textInput: root.inputText,disable: disabled'/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
					<ko data-bind='if: disabled'>
						<ko data-bind='with:root'>
							<i data-bind='click:clearValue'
							   className='fa fa-times-circle clear-button form-control-feedback'/>
						</ko>
					</ko>
				</div>
			</div>
		</div>;
	};
}
