import React, {useEffect, useState} from 'react';
import {ClassForm} from './components/form';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';
import {InformationAlert} from 'modules/forms/class-form/components/information-alert';
import {RemoveDialog} from 'modules/forms/class-form/components/remove-dialog';
import {useService} from '@esgi/core/service';
import {ClassFormService} from 'modules/forms/class-form/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';

interface Props {
	classID: number;
	teacherID: number;
	className: string;
	numberOfClasses: number;
	onClose: () => void;
}


export default function ClassFormModal({classID, teacherID, onClose, numberOfClasses, className}: Props) {
	const modalManagerRef = useModal();
	const handleClose = useCloseModal(modalManagerRef, onClose);
	const service = useService(ClassFormService);
	const [isLoaded, setLoaded] = useState(false);
	const [isInformationAlertOpen, setInformationAlertOpen] = useState(false);
	const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);

	useEffect(() => {
		service.init({teacherID, className, classID, numberOfClasses}).subscribe(() => {
			setLoaded(true);
		});
	}, []);

	const handleRemoveClick = () => {
		if (numberOfClasses > 1) {
			setRemoveDialogOpen(true);
		} else {
			setInformationAlertOpen(true);
		}
	};

	const remove = () => {
		service.remove().subscribe((response) => {
			setRemoveDialogOpen(false);
			if (response.canRemove) {
				handleClose();
			} else {
				setInformationAlertOpen(true);
			}
		});
	};

	const handleSave = () => {
		service.form.validate().subscribe((result) => {
			if(result.valid){
				service.save().subscribe(() => {
					handleClose();
				});
			}
		});
	};


	return <Modal modalManagerRef={modalManagerRef}>
		<ServiceLoader trackingService={service}/>
		<InformationAlert onClose={() => setInformationAlertOpen(false)} isOpen={isInformationAlertOpen}/>
		<RemoveDialog onClose={() => setRemoveDialogOpen(false)} isOpen={isRemoveDialogOpen}
		              onApprove={remove}/>
		<Modal.Header>
			<div className={styles.headerLinkBlock} data-cy='header-link-block'>
				<Title>{classID ? 'Edit Class' : 'Add Class'}</Title>
				{!!classID &&
					<div className={styles.removeLink} data-cy='header-remove-button'
					     onClick={handleRemoveClick}>
						Remove class
					</div>}
			</div>
		</Modal.Header>
		<Modal.Body>
			<ClassForm
				classID={classID}
				service={service}
			/>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Gray className={styles.cancelButton} onClick={handleClose}>Cancel</Buttons.Gray>
			<Buttons.Contained disabled={!isLoaded} onClick={handleSave}>Save</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
