import {dataRoutes} from './data-routes';

export const mainRoutes = {
	dashboard: '/dashboard',
	data: {
		root: '/data',
		...dataRoutes,
	},
	subjectManagement: '/subject-management',
	testExplorer: '/test-explorer',
	studentManager: '/student-manager',
};
