import {ComponentPropsWithoutRef, forwardRef, useRef} from 'react';
import {animated} from 'react-spring';
import {CSS} from '@stitches/react';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {useProgressBarContext} from '../../context';
import {assignRefs} from '../../utils/assign-refs';
import {useProgressRelatedContent} from '../../utils/hooks/use-progress-related-content';
import {ThemeColorKeys} from '../../../types';
import {getThemeColor} from '../../../utils';

type PointerIndicatorProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Progress value pointer indicator color. */
	pointerIndicatorColor?: ThemeColorKeys;

	/**Pointer Animation */
	isShowAnimation?: boolean
}

export const PointerIndicator = forwardRef<HTMLDivElement, PointerIndicatorProps>(({
	pointerIndicatorColor = 'base',
	dataCy = 'ui-kit-progress-bar-pointer-indicator',
	isShowAnimation,
	...props
}, forwardRef) => {

	const {progress, isAnimationFinished, lineProgressBarRef} = useProgressBarContext();

	const ref = useRef<HTMLDivElement>(null);

	const {needToTransform} = useProgressRelatedContent(lineProgressBarRef, ref, progress);

	return <Indicator needToTransform={needToTransform}
					  isShowAnimation={!isShowAnimation || isAnimationFinished}
					  ref={(itemRef: HTMLDivElement) => {
						  assignRefs(itemRef, forwardRef, ref);
					  }}
					  css={{
						  color: getThemeColor(pointerIndicatorColor),
						  left: `${progress * 100}%`,
					  }}
					  data-cy={dataCy}
					  {...props}>

		<IndicatorText data-cy={`${dataCy}-value`}>
			{Math.floor(progress * 100)}%
		</IndicatorText>
	</Indicator>;
});

const Indicator = styled(animated.div, {
	display: 'inline-flex',
	position: 'relative',
	overflow: 'hidden',
	transition: '.3s ease-out',
	transform: `translateX(0)`,
	opacity: '0',

	variants: {
		needToTransform: {
			true: {
				transform: `translateX(-100%)`,
			},
		},

		isShowAnimation: {
			true: {
				opacity: '1',
			},
		},
	},
});

const IndicatorText = styled(Text, {
	size: '$medium',
	font: '$mono',
	fontWeight: 'bold',
});
