import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import {ContextMenuContent} from './components/context-menu-content';
import {ContextMenuGroup} from './components/context-menu-group';
import {ContextMenuItem} from './components/context-menu-item';
import {ContextMenuTrigger} from './components/context-menu-trigger';

export const ContextMenu = {
	Root: DropdownMenu.Root,
	Trigger: ContextMenuTrigger,
	Content: ContextMenuContent,
	Group: ContextMenuGroup,
	Item: ContextMenuItem,
};
