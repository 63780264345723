import {useService} from '@esgi/core/service';
import {join, useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {useEffect, useMemo, useState} from 'react';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Transparent} from '@esgi/deprecated/elements/buttons';
import {Checkbox} from '@esgillc/ui-kit/control';
import {ShareScreenLauncherService} from './service';
import styles from './styles.module.less';
import {environment} from '@esgi/core/environments';
import {Link, NavLink} from 'react-router-dom';
import {RoutesMap} from '@esgi/main/libs/core';

export type Props = {
	closed: () => any;
	userID: number;
}

export function ShareScreenLauncher(props: Props) {
	const service = useService(ShareScreenLauncherService);

	const code = useBehaviorSubject(service.code);
	const expireTime = useBehaviorSubject(service.expireTime);

	useEffect(() => {
		service.init(props.userID);
		service.getSettings().subscribe((resp) => setShowSessionResults(resp.showTestSessionResults));
	}, []);

	const [showSessionResults, setShowSessionResults] = useState(false);

	const onShowSessionResultsClick = () => {
		const value = !showSessionResults;
		setShowSessionResults(value);
		service.updateShowTestSessionResults(value).subscribe();
	};

	const modal = useModal();
	const close = useCloseModal(modal, props.closed);

	useStreamEffect(service.onJoined, close);

	const secondScreenLink = useMemo(() => {
		if(environment.environmentType === 'master') {
			const domain = !environment.country ? 'esgiss.com' : `${environment.country}.esgiss.com`;
			return <a href={`https://${domain}`} target='_blank' rel='noreferrer'>{domain}</a>;
		} else {
			return <Link target='_blank' to={RoutesMap.studentsScreen}>esgiss.com</Link>;
		}
	}, []);

	return <Modal modalManagerRef={modal} onCatchError={() => props.closed()}>
		<Modal.Header>
			<Title>
				Student Screen Code
			</Title>
			<CloseIcon onClick={close}/>
		</Modal.Header>
		<Modal.Body className={styles.modalBody}>
			<div className={styles.shareScreenLauncher}>
				<span className={styles.legend}>Session Information:</span>
				{code && <div className={styles.codeContainer}>
					<div className={styles.code}>{code}</div>
				</div>}
				{!code && <Transparent
					onClick={() => service.createShareSession(props.userID)}
					title='Get Code'
					className={styles.codeButton}
				/>}
				{code && <span className={styles.expired}>Code expires in: {expireTime}</span>}
				<div className={styles.text}><span className={styles.number}>1.</span>
					Go to {secondScreenLink} on your student screen.
				</div>
				<div className={styles.text}><span className={styles.number}>2.</span> Enter the code. Codes not entered within 5
					minutes will expire.
				</div>
				<div className={join(styles.text, styles.marginTop)}>
					<Checkbox
						checked={showSessionResults}
						id='session-results-checkbox'
						onClick={(e) => onShowSessionResultsClick()}
					/>
					<label className={styles.checkboxLabel} htmlFor='session-results-checkbox'>Show pie chart results</label>
				</div>
			</div>
		</Modal.Body>
		<Modal.Footer/>
	</Modal>;
}
