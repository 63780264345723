import React, {useMemo} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {IconButton} from '../../../../icons/icon-button';

export function HideButton(props: {toggled: boolean, onClick: () => void}) {
	const tooltipText = useMemo(() => {
		if (props.toggled) {
			return 'Show this subject tab';
		}

		return 'Hide this subject tab';
	}, [props.toggled]);

	return <OnHoverTooltip message={tooltipText}>
		<IconButton data-cy='hide-subject-button' onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
			props.onClick();
		}}>
			{!props.toggled &&
				<svg xmlns='http://www.w3.org/2000/svg' width='19'
				     height='13'
				     viewBox='0 0 19 13'
				     fill='none'>
					<path
						d='M9.16667 0C5 0 1.44167 2.59167 0 6.25C1.44167 9.90833 5 12.5 9.16667 12.5C13.3333 12.5 16.8917 9.90833 18.3333 6.25C16.8917 2.59167 13.3333 0 9.16667 0ZM9.16667 10.4167C6.86667 10.4167 5 8.55 5 6.25C5 3.95 6.86667 2.08333 9.16667 2.08333C11.4667 2.08333 13.3333 3.95 13.3333 6.25C13.3333 8.55 11.4667 10.4167 9.16667 10.4167ZM9.16667 3.75C7.78333 3.75 6.66667 4.86667 6.66667 6.25C6.66667 7.63333 7.78333 8.75 9.16667 8.75C10.55 8.75 11.6667 7.63333 11.6667 6.25C11.6667 4.86667 10.55 3.75 9.16667 3.75Z'
						fill='#0088CC'/>
				</svg>
			}
			{props.toggled &&
				<svg xmlns='http://www.w3.org/2000/svg' width='19'
				     height='16'
				     viewBox='0 0 19 16'
				     fill='none'>
					<path
						d='M9.16667 3.33333C11.4667 3.33333 13.3333 5.2 13.3333 7.5C13.3333 8.04167 13.225 8.55 13.0333 9.025L15.4667 11.4583C16.725 10.4083 17.7167 9.05 18.325 7.5C16.8833 3.84167 13.325 1.25 9.15833 1.25C7.99167 1.25 6.875 1.45833 5.84167 1.83333L7.64167 3.63333C8.11667 3.44167 8.625 3.33333 9.16667 3.33333ZM0.833333 1.05833L2.73333 2.95833L3.11667 3.34167C1.73333 4.41667 0.65 5.85 0 7.5C1.44167 11.1583 5 13.75 9.16667 13.75C10.4583 13.75 11.6917 13.5 12.8167 13.05L13.1667 13.4L15.6083 15.8333L16.6667 14.775L1.89167 0L0.833333 1.05833ZM5.44167 5.66667L6.73333 6.95833C6.69167 7.13333 6.66667 7.31667 6.66667 7.5C6.66667 8.88333 7.78333 10 9.16667 10C9.35 10 9.53333 9.975 9.70833 9.93333L11 11.225C10.4417 11.5 9.825 11.6667 9.16667 11.6667C6.86667 11.6667 5 9.8 5 7.5C5 6.84167 5.16667 6.225 5.44167 5.66667ZM9.03333 5.01667L11.6583 7.64167L11.675 7.50833C11.675 6.125 10.5583 5.00833 9.175 5.00833L9.03333 5.01667Z'
						fill='#0088CC'/>
				</svg>
			}
		</IconButton>
	</OnHoverTooltip>;
}
