import {styled} from '@stitches/react';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Radio} from '@esgillc/ui-kit/control';

export const FiltersContentBlock = styled(FlexBox, {
	marginLeft: '5px',
	marginBottom: '5px',
	variants: {
		isIpad: {
			true: {
				flexDirection: 'column',
			},
		},
	},
});

export const ReportFilterLabel = styled(Box, {
	fontWeight: 700,
	textAlign: 'left',
	fontSize: '13px',
	marginBottom: '5px',
	marginRight: '7px',
});

export const ReportsFiltersContainer = styled(FlexBox, {
	borderRight: '2px solid $surface',
	padding: '10px',
	alignItems: 'flex-start',
	flexDirection: 'row',
	paddingBottom: '5px',
});

export const ReportFilterRow = styled(FlexBox, {
	flexDirection: 'row',
	borderBottom: '2px solid $surface',
	padding: '0 20px',

	'&:last-child': {
		borderRight: 'none',
	},
});

export const ReportFilterBody = styled(Box, {
	backgroundColor: '$surface',
	boxShadow: '0px 10px 15px -8px rgba(0,0,0,0.1)',

	'&:last-child': {
		borderBottom: 'none',
	},
});

export const RadioStyled = styled(Radio, {
	marginRight: '13px',
	fontSize: '12px',

	'& > div': {
		width: '19px',
		height: '19px',
	},
});