import {Button} from '@esgi/ui';
import moment from 'moment';
import {useCallback} from 'react';
import {ContentWrapperBox} from './content-wrapper-box.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	date: Date | null;
} & (
	| {
			withRenewButton?: false;
	  }
	| {
			withRenewButton: true;
			canAllowRenewByCC: boolean | undefined;
	  }
);

export function ExpirationDate({date, ...props}: Props) {
	const onRenewButtonClick = useCallback(() => {
		if (props.withRenewButton) {
			const {canAllowRenewByCC = true} = props;

			if (!canAllowRenewByCC) {
				location.href = `/renewal?page=activationcode`;
			}

			location.href = `/renewal/`;
		}
	}, [props]);

	return (
		<ContentWrapperBox dataCy='expiration-date-box' gap='4' textAlign='center'>
			{date && (
				<Text data-cy='expiration-date-text' size='medium' font='mono' bold color='base'>
					{moment(date).format('MM/DD/YYYY')}
				</Text>
			)}

			{props.withRenewButton && (
				<Button dataCy='renew-button' color='secondary' onClick={onRenewButtonClick}>
					<Text size='medium' bold color='base'>
						Renew
					</Text>
				</Button>
			)}
		</ContentWrapperBox>
	);
}
