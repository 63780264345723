import {LabelBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {TestType} from '@esgi/main/libs/core';
import {testLabelByTestType} from '../../constants';
import {BaseComponentProps} from '@esgi/ui';

type Props = BaseComponentProps & {
	testType: TestType;
};

export function TestTypeLabel({testType, dataCy = 'test-type', css = {}}: Props) {
	return (
		<LabelBox dataCy={dataCy} css={css}>
			<Text data-cy={`${dataCy}-label`} size='small' color='highContrast'>
				{testLabelByTestType[testType]}
			</Text>
		</LabelBox>
	);
}
