import {BehaviorSubject, tap} from 'rxjs';
import moment from 'moment/moment';
import {BaseService} from '@esgi/core/service';
import {SsoTracker} from '@esgi/core/tracker';
import {SubjectLevel, SubjectType} from '@esgi/main/libs/store';
import {getUser, StudentSort, UserType} from '@esgi/core/authentication';
import {V2PagesReportsParentLetterController} from '@esgi/contracts/esgi';
import {FileType, RequestResultsInit, RequestTest, ResponseResultsInit} from './api-models';
import {Hierarchy, ReportRequest, GroupItemData, ParentLetterResultData, ColorType} from '../../types';
import {Init} from './models';

/** This is a temporary solution, with minimal adaptation,
 * 	Need to refactor after the new BE side is ready
 * 	*/

const controllerName = 'reports/parent-letter';

export class ResultsService extends BaseService {
	public model = new BehaviorSubject<Init>(null);

	private requestModel = new BehaviorSubject<ReportRequest>(null);
	private controller = new V2PagesReportsParentLetterController();

	private groupItemData = new BehaviorSubject<GroupItemData>(null);

	private downloadParameters = {
		[FileType.PDF]: {
			extension: '.pdf',
			contentType: 'application/pdf',
		},
		[FileType.Word]: {
			extension: '.docx',
			contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		},
		[FileType.ZIP]: {
			extension: '.zip',
			contentType: 'application/zip',
		},
	};

	public init(requestModel: ParentLetterResultData, hierarchy: Hierarchy) {
		const {
			globalSchoolYearID,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			userType,
			userID,
			districtID,
			schoolID,
		} = getUser();

		const {settings, subject, studentIDs, tests, groupItemLevel, groupItemID} = requestModel;

		const request: ReportRequest = {
			// TODO : get reportGuid
			reportGuid: 'ss',
			groupItemID,
			groupItemLevel,
			teacherID: userID,
			subject,
			studentIDs,
			tests,
			hierarchy,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			userType,
			globalSchoolYearID,
			userID,
			districtID,
			schoolID,
			...settings,
			printInColor: settings.printInColor === ColorType.Colored,
		};

		this.groupItemData.next({groupItemID, groupItemLevel});
		this.requestModel.next(request);
		this.dateFormat(request.tests);

		return this.controller.results(request).pipe(tap((response) => {
			const result = Init.fromResponse(response as ResponseResultsInit);

			result.testResultsCorrectVerbiage = request.testResultsCorrectVerbiage;
			result.testResultsIncorrectVerbiage = request.testResultsIncorrectVerbiage;

			this.model.next(result);
		}));
	}

	public pdf(zip: boolean) {
		const downloadRequest = this.makeDownloadRequestModel(this.groupItemData.value);
		this.downloadPdfWord('download-pdf', FileType.PDF, zip, downloadRequest);
	}

	public word(zip: boolean) {
		const downloadRequest = this.makeDownloadRequestModel(this.groupItemData.value);
		this.downloadPdfWord('download-word', FileType.Word, zip, downloadRequest);
	}

	public downloadPdfWord(method: string, type: FileType, zip: boolean, requestModel: RequestResultsInit) {
		this.downloadFile(method, this.model.value.reportGuid, requestModel, type, zip);

		if (requestModel.allStudentsSelected) {
			const eventFileType = type === FileType.PDF ? 'PDF' : 'Word';
			const eventType = eventFileType + ' ' + (zip ? 'Bulk' : 'Standard');
			SsoTracker.trackEvent({
				trackingEvent: eventType,
				data: {reportType: 'ParentLetter'},
			});
		}
	}

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}

	private makeDownloadRequestModel = (groupItemData: GroupItemData): RequestResultsInit => {
		const {
			globalSchoolYearID,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			userID,
		} = getUser();

		const {
			subject,
			includeTestNotes,
			includeSummaryNotes,
			includeLetter,
			includeGradeScale,
			printInColor,
			showSessionDate,
			includeUntestedQuestions,
			tests,
			studentIDs,
			hierarchy,
			reportGuid,
		} = this.requestModel.value;

		const request = {
			reportGuid,
			groupItemID: groupItemData.groupItemID,
			groupItemLevel: groupItemData.groupItemLevel,
			teacherID: userID,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			globalSchoolYearID,
			includeTestNotes,
			includeSummaryNotes,
			includeLetter,
			includeGradeScale,
			printInColor,
			showSessionDate,
			includeUntestedQuestions,
		};

		const hierarchySnapshot = {
			districtID: hierarchy.districtID,
			mode: hierarchy.mode,
			classic: hierarchy.classic,
			studentSort: StudentSort.FirstName,
			preAssess: hierarchy.preAssess,
			specialist: {
				...hierarchy.specialist,
				type: UserType.T,
			},
		};

		return {
			...request,
			tests,
			studentIDs,
			allStudentsSelected: false,
			looseMode: false,
			subject: {
				...subject,
				subjectType: SubjectType[subject.subjectType],
				subjectLevel: SubjectLevel[subject.subjectLevel],
			},
			hierarchy: hierarchySnapshot,
		};
	};

	private downloadFile(method: string, reportGuid: string, requestModel: RequestResultsInit, type: FileType, zip: boolean) {
		requestModel.reportGuid = reportGuid;

		const payload = {
			requestModel: requestModel,
			zip: zip,
		};

		if (zip) {
			type = FileType.ZIP;
		}

		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = 'Parent_Letter_' + year + '-' + month + '-' + day + this.downloadParameters[type].extension;

		this.httpClient.ESGIApi.file(controllerName, method, filename, payload).subscribe();
	}

	private dateFormat(tests: RequestTest[]) {
		tests.forEach((t) => {
			if (t.testDate) {
				t.testDate = moment(t.testDate, 'MM-DD-YYYY HH:mm').format();
			}
		});
	}
}
