import React from 'react';

export default class PressessActivityReportIcon extends React.Component {
  render() {
    return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='15'
                viewBox='0 0 15 15'
                fill='none'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M3 0C1.34315 0 0 1.34314 0 3V12C0 13.6569 1.34315 15 3 15H12C13.6569 15 15 13.6569 15 12V3C15 1.34314 13.6569 0 12 0H3ZM6.99938 3.69805C6.95003 3.50258 6.78303 3.35821 6.58082 3.33622C6.37861 3.31423 6.18396 3.41929 6.09299 3.5995L4.58333 6.59004L4.0459 5.52542C3.96356 5.3623 3.79524 5.25927 3.61111 5.25927H2.15278C1.88431 5.25927 1.66667 5.47483 1.66667 5.74075C1.66667 6.00666 1.88431 6.22223 2.15278 6.22223H3.31068L4.14854 7.88199C4.23089 8.04511 4.39921 8.14815 4.58333 8.14815C4.76746 8.14815 4.93578 8.04511 5.01813 7.88199L6.37489 5.19431L8.00062 11.6353C8.04997 11.8308 8.21697 11.9751 8.41918 11.9971C8.62139 12.0191 8.81604 11.914 8.90701 11.7338L10.3266 8.92167L10.9277 10.7078C10.9939 10.9044 11.1797 11.037 11.3889 11.037H12.8472C13.1157 11.037 13.3333 10.8215 13.3333 10.5555C13.3333 10.2896 13.1157 10.0741 12.8472 10.0741H11.7393L10.8778 7.51441C10.8157 7.32966 10.6472 7.2002 10.4511 7.1864C10.255 7.17259 10.0698 7.27716 9.98187 7.45134L8.62511 10.139L6.99938 3.69805Z'
            fill='#0088CC'/>
    </svg>;
  }
}
