import {Checkbox} from '@esgillc/ui-kit/control';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {SelectDropdownVariants} from 'modules/forms/group-form/components/checkbox-list-box/types';
import React, {useState} from 'react';
import {
	ArrowIcon,
} from 'modules/forms/group-form/components/checkbox-list-box/components/checkbox-options/components/arrow-icon';
import styles from './styles.module.less';

export function CheckboxOptions({isCheckboxChecked, isCheckboxDisabled, onCheck, onSelect}) {
	const [showMenu, setShowMenu] = useState(false);
	const toggleMenu = () => {
		setShowMenu(prev => !prev);
	};
	const handleSelect = (variant: SelectDropdownVariants) => {
		onSelect(variant);
		setShowMenu(false);
	};
	return <div className={styles.checkboxOptions} data-cy='checkbox-options'>
		<Checkbox checked={isCheckboxChecked}
		          disabled={isCheckboxDisabled}
		          onChange={onCheck}/>
		<div onClick={() => setShowMenu(true)} className={styles.menuArrow}>
			<ArrowIcon/>
		</div>
		<div className={styles.menuWrapper}>
			<Menu className={styles.menu} show={showMenu} onClickOutside={toggleMenu} onEscPressed={toggleMenu}>
				<MenuItem onSelect={() => handleSelect(SelectDropdownVariants.All)}>All</MenuItem>
				<MenuItem onSelect={() => handleSelect(SelectDropdownVariants.None)}>None</MenuItem>
			</Menu>
		</div>
	</div>;
}
