import {ProgressBarRoot} from './components/progress-bar-root';
import {RingDashed} from './components/ring-dashed';
import {RingWithPointer} from './components/ring-with-pointer';
import {LineWithPointer} from './components/line-with-pointer';
import {PointerIndicator} from './components/pointer-indicator';
import {AdditionalInfo} from './components/additional-info';
import {RingFullFilled} from './components/ring-full-filled';

export const ProgressBar = Object.assign(ProgressBarRoot, {
	RingWithPointer,
	RingDashed,
	LineWithPointer,
	PointerIndicator,
	AdditionalInfo,
	RingFullFilled,
});
