import React, {ReactNode} from 'react';
import styles from './matrix.module.less';

interface Props {
	children?: ReactNode;
}

export class RightPanel extends React.PureComponent<Props> {
	public render() {
		return <div className={styles.rightPanel}>
			{this.props.children}
		</div>;
	}
}
