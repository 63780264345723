import {Text} from '@esgi/ui/typography';
import {ComponentProps} from 'react';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = ComponentProps<typeof Text> & {
	inLine?: boolean;
	dataCy?: string;
	firstName: string;
	lastName: string;
};

export function StudentFullName({
	inLine,
	dataCy = 'student-full-name',
	firstName,
	lastName,
	size = 'medium',
	...textProps
}: Props) {
	const fullName = [firstName, lastName].filter(Boolean).join(' ').trim();

	if (inLine) {
		return (
			<OneLinedText size={size} data-cy={dataCy} {...textProps}>
				{fullName}
			</OneLinedText>
		);
	}

	return (
		<Text size={size} data-cy={dataCy} {...textProps}>
			{fullName}
		</Text>
	);
}
