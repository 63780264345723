import {styled} from '@stitches/react';
import {FiltersContentBlock} from '../../index.styled';
import {FlexBox} from '@esgi/ui/layout';
import {Checkbox} from '@esgillc/ui-kit/control';

export const FiltersContentBlockStyled = styled(FiltersContentBlock, {
	width: '120px',
	flexDirection: 'column',
});

export const ReportsFiltersContainer = styled(FlexBox, {
	borderRight: '2px solid #FFFFFF',
	padding: '10px',
	alignItems: 'flex-start',
	flexDirection: 'row',
	paddingBottom: '5px',
});

export const CheckboxStyled = styled(Checkbox, {
	marginBottom: '8px',
	whiteSpace: 'nowrap',
	'label': {
		marginRight: '13px !important',
		fontSize: '12px !important',
	},
	variants: {
		selected: {
			true: {
				'label': {
					fontWeight: 'bold',
				},
			},
		},
	},
});

export const IconStyled = styled('sup', {
	color: '#828282',
});