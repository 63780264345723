import {Drawer} from '@esgi/main/kits/common';
import {useCallback, useState} from 'react';
import {PanelContent} from '../../../components/panels.styled';
import {ContentBox} from '../../../components/content-box.styled';
import {DisabledInput} from '../../../components/disabled-input';
import {SecondaryProfile} from '../../../../../types';
import {PanelHeaderTitle} from '../../../components/panel-header-title';
import {Logout} from '../../../../logout';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Observable, of, switchMap} from 'rxjs';
import {useStreamEffect} from '@esgi/ui';
import {ErrorTooltip, FormControl, FormElement, FormGroup, Validators, useForm} from '@esgi/ui/form';
import {Input} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {StyledForm} from './index.styled';

type Props = {
	firstName: string;
	lastName: string;
	email: string;
	saveSecondaryProfileData: (body: Partial<SecondaryProfile>) => Observable<unknown>;
};

export function PersonalInformationContent({
	firstName: initialFirstName,
	lastName: initialLastName,
	email,
	saveSecondaryProfileData,
}: Props) {
	const [isFormValid, setIsFormValid] = useState(true);
	const [isFormTouched, setIsFormTouched] = useState(false);

	const form = useForm(
		() =>
			new FormGroup({
				firstName: new FormControl(initialFirstName, {
					validators: [Validators.required()],
				}),
				lastName: new FormControl(initialLastName, {
					validators: [Validators.required()],
				}),
			}),
	);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {firstName, lastName},
			},
		}) => {
			setIsFormTouched(firstName !== initialFirstName || lastName !== initialLastName);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		},
	);

	const handleSave = useCallback(() => {
		form
			.validate()
			.pipe(
				switchMap(({valid}) => {
					if (valid) {
						return saveSecondaryProfileData({
							firstName: form.value.firstName,
							lastName: form.value.lastName,
						});
					}

					return of();
				}),
			)
			.subscribe(() => {
				setIsFormTouched(false);
			});
	}, [form, saveSecondaryProfileData]);

	return (
		<>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={!isFormTouched || !isFormValid}
			>
				<PanelHeaderTitle title='Personal Information' />
			</Drawer.PanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						<Drawer.ContentBlock title='Name'>
							<StyledForm controller={form}>
								<FormElement control={form.controls.firstName}>
									<Input.Base placeholder='First Name' />

									<ErrorTooltip showOnError='required'>
										<Text size='xSmall' font='mono' color='negativeVivid'>
											First Name is required
										</Text>
									</ErrorTooltip>
								</FormElement>

								<FormElement control={form.controls.lastName}>
									<Input.Base placeholder='Last Name' />

									<ErrorTooltip showOnError='required'>
										<Text size='xSmall' font='mono' color='negativeVivid'>
											Last Name is required
										</Text>
									</ErrorTooltip>
								</FormElement>
							</StyledForm>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Email'>
							<DisabledInput
								value={email}
								placeholder='Email'
								inputTranscript='To change your email, please contact our Customer Support team or your system administrator.'
								dataCy='email-disabled-input'
							/>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Terminate Current Session'>
							<Logout />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
