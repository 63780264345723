import mx from '../../../../assets/mx-graph/mx-graph-factory';

export default function overrideMxGraph() {
	mx.mxCodec.prototype.decode = function (node, into) {
		this.updateElements();
		let obj = null;

		if (node != null && node.nodeType == mx.mxConstants.NODETYPE_ELEMENT) {
			let ctor = null;

			try {
				ctor = window[node.nodeName];
				if (!ctor) {
					ctor = mx[node.nodeName];
				}
			} catch (err) {
				// ignore
			}

			let dec = mx.mxCodecRegistry.getCodec(ctor);

			if (dec != null) {
				obj = dec.decode(this, node, into);
			} else {
				obj = node.cloneNode(true);
				obj.removeAttribute('as');
			}
		}

		return obj;
	};

	mx.mxGraph.prototype.cellSizeUpdated = function (cell, ignoreChildren, textWidth) {
		if (cell != null) {
			this.model.beginUpdate();
			try {
				let size = this.getPreferredSizeForCell(cell, textWidth);
				let geo = this.model.getGeometry(cell);

				if (size != null && geo != null) {
					let collapsed = this.isCellCollapsed(cell);
					geo = geo.clone();

					if (this.isSwimlane(cell)) {
						let style = this.getCellStyle(cell);
						let cellStyle = this.model.getStyle(cell);

						if (cellStyle == null) {
							cellStyle = '';
						}

						if (mx.mxUtils.getValue(style, mx.mxConstants.STYLE_HORIZONTAL, true)) {
							cellStyle = mx.mxUtils.setStyle(cellStyle,
								mx.mxConstants.STYLE_STARTSIZE, size.height + 8);

							if (collapsed) {
								geo.height = size.height + 8;
							}

							geo.width = size.width;
						} else {
							cellStyle = mx.mxUtils.setStyle(cellStyle,
								mx.mxConstants.STYLE_STARTSIZE, size.width + 8);

							if (collapsed) {
								geo.width = size.width + 8;
							}

							geo.height = size.height;
						}

						this.model.setStyle(cell, cellStyle);
					} else {
						let state = this.view.createState(cell);
						let align = (state.style[mx.mxConstants.STYLE_ALIGN] || mx.mxConstants.ALIGN_CENTER);

						if (align == mx.mxConstants.ALIGN_RIGHT) {
							geo.x += geo.width - size.width;
						} else if (align == mx.mxConstants.ALIGN_CENTER) {
							geo.x += Math.round((geo.width - size.width) / 2);
						}

						let valign = this.getVerticalAlign(state);

						if (valign == mx.mxConstants.ALIGN_BOTTOM) {
							geo.y += geo.height - size.height;
						} else if (valign == mx.mxConstants.ALIGN_MIDDLE) {
							geo.y += Math.round((geo.height - size.height) / 2);
						}

						geo.width = size.width;
						geo.height = size.height;
					}

					if (!ignoreChildren && !collapsed) {
						let bounds = this.view.getBounds(this.model.getChildren(cell));

						if (bounds != null) {
							let tr = this.view.translate;
							let scale = this.view.scale;

							let width = (bounds.x + bounds.width) / scale - geo.x - tr.x;
							let height = (bounds.y + bounds.height) / scale - geo.y - tr.y;

							geo.width = Math.max(geo.width, width);
							geo.height = Math.max(geo.height, height);
						}
					}

					this.cellsResized([cell], [geo], false);
				}
			} finally {
				this.model.endUpdate();
			}
		}
	};

	//textWidth - new param
	mx.mxGraph.prototype.updateCellSize = function (cell, ignoreChildren, textWidth) {
		ignoreChildren = (ignoreChildren != null) ? ignoreChildren : false;

		this.model.beginUpdate();
		try {
			this.cellSizeUpdated(cell, ignoreChildren, textWidth);
			this.fireEvent(new mx.mxEventObject(mx.mxEvent.UPDATE_CELL_SIZE,
				'cell', cell, 'ignoreChildren', ignoreChildren));
		} finally {
			this.model.endUpdate();
		}

		return cell;
	};


	mx.mxGraphHandler.prototype.updateLivePreview = function (dx, dy) {
		if (!this.suspended) {
			let states = [];

			if (this.allCells != null) {
				this.allCells.visit(mx.mxUtils.bind(this, function (key, state) {
					let realState = this.graph.view.getState(state.cell);

					// Checks if cell was removed or replaced
					if (realState != state) {
						state.destroy();

						if (realState != null) {
							this.allCells.put(state.cell, realState);
						} else {
							this.allCells.remove(state.cell);
						}

						state = realState;
					}

					if (state != null) {
						// Saves current state
						let tempState = state.clone();
						states.push([state, tempState]);

						// Makes transparent for events to detect drop targets
						if (state.shape != null) {
							if (state.shape.originalPointerEvents == null) {
								state.shape.originalPointerEvents = state.shape.pointerEvents;
							}

							state.shape.pointerEvents = false;

							if (state.text != null) {
								if (state.text.originalPointerEvents == null) {
									state.text.originalPointerEvents = state.text.pointerEvents;
								}

								state.text.pointerEvents = false;
							}
						}

						// Temporarily changes position
						if (this.graph.model.isVertex(state.cell)) {
							state.x += dx;
							state.y += dy;

							//ESGI change. We need to set right coordinates that are inside canvas for moved cells
							if (state.y < 0) {
								state.y = 0;
							}

							if ((state.y + state.cell.geometry.height) > 318) {
								state.y = 318 - state.cell.geometry.height;
							}

							if (state.x < 0) {
								state.x = 0;
							}

							if ((state.x + state.cell.geometry.width) > 708) {
								state.x = 708 - state.cell.geometry.width;
							}

							// Draws the live preview
							if (!this.cloning) {
								state.view.graph.cellRenderer.redraw(state, true);

								// Forces redraw of connected edges after all states
								// have been updated but avoids update of state
								state.view.invalidate(state.cell);
								state.invalid = false;

								// Hides folding icon
								if (state.control != null && state.control.node != null) {
									state.control.node.style.visibility = 'hidden';
								}
								// eslint-disable-next-line brace-style
							}
							// Clone live preview may use text bounds
							else if (state.text != null) {
								state.text.updateBoundingBox();

								// Fixes preview box for edge labels
								if (state.text.boundingBox != null) {
									state.text.boundingBox.x += dx;
									state.text.boundingBox.y += dy;
								}

								if (state.text.unrotatedBoundingBox != null) {
									state.text.unrotatedBoundingBox.x += dx;
									state.text.unrotatedBoundingBox.y += dy;
								}
							}
						}
					}
				}));
			}

			// Resets the handler if everything was removed
			if (states.length == 0) {
				this.reset();
			} else {
				// Redraws connected edges
				let s = this.graph.view.scale;

				for (let i = 0; i < states.length; i++) {
					let state = states[i][0];
					if (this.graph.model.isEdge(state.cell)) {
						let geometry = this.graph.getCellGeometry(state.cell);
						let points = [];

						if (geometry != null && geometry.points != null) {
							for (let j = 0; j < geometry.points.length; j++) {
								if (geometry.points[j] != null) {
									points.push(new mx.mxPoint(
										geometry.points[j].x + dx / s,
										geometry.points[j].y + dy / s));
								}
							}
						}

						let source = state.visibleSourceState;
						let target = state.visibleTargetState;
						let pts = states[i][1].absolutePoints;

						//ESGI change. We need to set right coordinates that are inside canvas for moved cells
						if (source == null && target == null) {
							let pt1 = pts[0];
							let pt2 = pts[pts.length - 1];

							let sourceX = pt1.x + dx;
							let sourceY = pt1.y + dy;
							let targetX = pt2.x + dx;
							let targetY = pt2.y + dy;

							if (sourceY <= 0) {
								targetY = Math.abs(sourceY) + targetY;
								sourceY = 0;
							}

							if (sourceY >= 318) {
								targetY = 318 - (sourceY - targetY);
								sourceY = 318;
							}

							if (sourceX <= 0) {
								targetX = Math.abs(sourceX) + targetX;
								sourceX = 0;
							}

							if (sourceX >= 708) {
								targetX = 708 - (sourceX - targetX);
								sourceX = 708;
							}

							if (targetY <= 0) {
								sourceY = Math.abs(targetY) + sourceY;
								targetY = 0;
							}

							if (targetY >= 318) {
								sourceY = 318 - (targetY - sourceY);
								targetY = 318;
							}

							if (targetX <= 0) {
								sourceX = Math.abs(targetX) + sourceX;
								targetX = 0;
							}

							if (targetX >= 708) {
								sourceX = 708 - (targetX - sourceX);
								targetX = 708;
							}

							state.setAbsoluteTerminalPoint(new mx.mxPoint(sourceX, sourceY), true);
							state.setAbsoluteTerminalPoint(new mx.mxPoint(targetX, targetY), false);
						}

						state.view.updatePoints(state, points, source, target);
						state.view.updateFloatingTerminalPoints(state, source, target);
						state.view.updateEdgeLabelOffset(state);
						state.invalid = false;

						// Draws the live preview but avoids update of state
						if (!this.cloning) {
							state.view.graph.cellRenderer.redraw(state, true);
						}
					}
				}

				this.graph.view.validate();
				this.redrawHandles(states);
				this.resetPreviewStates(states);
			}
		}
	};

	mx.mxSvgCanvas2D.prototype.updateTextNodes = function (x, y, w, h, align, valign, wrap, overflow, clip, rotation, g) {
		let s = this.state.scale;

		mx.mxSvgCanvas2D.createCss(w + 2, h, align, valign, wrap, overflow, clip,
			(this.state.fontBackgroundColor != null) ? this.state.fontBackgroundColor : null,
			(this.state.fontBorderColor != null) ? this.state.fontBorderColor : null,
			'display: flex; align-items: unsafe ' +
			((valign == mx.mxConstants.ALIGN_TOP) ? 'flex-start' :
				((valign == mx.mxConstants.ALIGN_BOTTOM) ? 'flex-end' : 'center')) + '; ' +
			'justify-content: unsafe ' + ((align == mx.mxConstants.ALIGN_LEFT) ? 'flex-start' :
				((align == mx.mxConstants.ALIGN_RIGHT) ? 'flex-end' : 'center')) + '; ',
			this.getTextCss(), s, mx.mxUtils.bind(this, function(dx, dy, flex, item, block) {
				x += this.state.dx;
				y += this.state.dy;

				let fo = g.firstChild;
				let div = fo.firstChild;
				let box = div.firstChild;
				let text = box.firstChild;
				let r = ((this.rotateHtml) ? this.state.rotation : 0) + ((rotation != null) ? rotation : 0);
				let t = ((this.foOffset != 0) ? 'translate(' + this.foOffset + ' ' + this.foOffset + ')' : '') +
					((s != 1) ? 'scale(' + s + ')' : '');

				text.setAttribute('style', block);
				box.setAttribute('style', item);

				// Workaround for clipping in Webkit with scrolling and zoom
				fo.setAttribute('width', Math.ceil(1 / Math.min(1, s) * 100) + '%');
				fo.setAttribute('height', Math.ceil(1 / Math.min(1, s) * 100) + '%');
				let yp = Math.round(y + dy);

				// Allows for negative values which are causing problems with
				// transformed content where the top edge of the foreignObject
				// limits the text box being moved further up in the diagram.
				// KNOWN: Possible clipping problems with zoom and scrolling
				// but this is normally not used with scrollbars as the
				// coordinates are always positive with scrollbars.
				// Margin-top is ignored in Safari and no negative values allowed
				// for padding.
				if (yp < 0) {
					fo.setAttribute('y', yp);
				} else {
					fo.removeAttribute('y');
					flex += 'padding-top: ' + yp + 'px; ';
				}

				//ESGI change. add word-break.
				div.setAttribute('style', 'word-break: break-word;' + flex + 'margin-left: ' + Math.round(x + dx) + 'px;');
				t += ((r != 0) ? ('rotate(' + r + ' ' + x + ' ' + y + ')') : '');

				// Output allows for reflow but Safari cannot use absolute position,
				// transforms or opacity. https://bugs.webkit.org/show_bug.cgi?id=23113
				if (t != '') {
					g.setAttribute('transform', t);
				} else {
					g.removeAttribute('transform');
				}

				if (this.state.alpha != 1) {
					g.setAttribute('opacity', this.state.alpha);
				} else {
					g.removeAttribute('opacity');
				}
			}));
	};

	mx.mxGraph.prototype.getCells = function (x, y, width, height, parent, result, intersection, ignoreFn, includeDescendants) {
		result = (result != null) ? result : [];

		if (width > 0 || height > 0 || intersection != null) {
			let model = this.getModel();
			let right = x + width;
			let bottom = y + height;

			if (parent == null) {
				parent = this.getCurrentRoot();

				if (parent == null) {
					parent = model.getRoot();
				}
			}

			if (parent != null) {
				let childCount = model.getChildCount(parent);

				for (let i = 0; i < childCount; i++) {
					let cell = model.getChildAt(parent, i);
					let state = this.view.getState(cell);

					if (state != null && this.isCellVisible(cell) &&
						(ignoreFn == null || !ignoreFn(state))) {
						let deg = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_ROTATION) || 0;
						let box = state;

						if (deg != 0) {
							box = mx.mxUtils.getBoundingBox(box, deg);
						}
						//ESGI change. We need to select cell when one edge is in rectangle
						let hit = (intersection != null && model.isVertex(cell) && mx.mxUtils.intersects(intersection, box))
							|| (intersection == null && model.isVertex(cell) && mx.mxUtils.intersects(cell.geometry, new mx.mxRectangle(x, y, width, height)))
							|| (intersection == null && model.isEdge(cell) && mx.mxUtils.rectangleIntersectsSegment(new mx.mxRectangle(x, y, width, height), cell.geometry.sourcePoint, cell.geometry.targetPoint));

						if (hit) {
							result.push(cell);
						}

						if (!hit || includeDescendants) {
							this.getCells(x, y, width, height, cell, result, intersection, ignoreFn, includeDescendants);
						}
					}
				}
			}
		}

		return result;
	};

	mx.mxGraph.prototype.getPreferredSizeForCell = function (cell, textWidth) {
		let result = null;

		if (cell != null) {
			let state = this.view.createState(cell);
			let style = state.style;

			if (!this.model.isEdge(cell)) {
				let fontSize = style[mx.mxConstants.STYLE_FONTSIZE] || mx.mxConstants.DEFAULT_FONTSIZE;
				let dx = 0;
				let dy = 0;

				// Adds dimension of image if shape is a label
				if (this.getImage(state) != null || style[mx.mxConstants.STYLE_IMAGE] != null) {
					if (style[mx.mxConstants.STYLE_SHAPE] == mx.mxConstants.SHAPE_LABEL) {
						if (style[mx.mxConstants.STYLE_VERTICAL_ALIGN] == mx.mxConstants.ALIGN_MIDDLE) {
							dx += parseFloat(style[mx.mxConstants.STYLE_IMAGE_WIDTH]) || mx.mxLabel.prototype.imageSize;
						}

						if (style[mx.mxConstants.STYLE_ALIGN] != mx.mxConstants.ALIGN_CENTER) {
							dy += parseFloat(style[mx.mxConstants.STYLE_IMAGE_HEIGHT]) || mx.mxLabel.prototype.imageSize;
						}
					}
				}

				// Adds spacings
				dx += 2 * (style[mx.mxConstants.STYLE_SPACING] || 0);
				dx += style[mx.mxConstants.STYLE_SPACING_LEFT] || 0;
				dx += style[mx.mxConstants.STYLE_SPACING_RIGHT] || 0;

				dy += 2 * (style[mx.mxConstants.STYLE_SPACING] || 0);
				dy += style[mx.mxConstants.STYLE_SPACING_TOP] || 0;
				dy += style[mx.mxConstants.STYLE_SPACING_BOTTOM] || 0;

				// Add spacing for collapse/expand icon
				// LATER: Check alignment and use constants
				// for image spacing
				let image = this.getFoldingImage(state);

				if (image != null) {
					dx += image.width + 8;
				}

				// Adds space for label
				//ESGI change
				let value = this.isEditing() ? this.cellEditor.textarea.innerHTML : this.cellRenderer.getLabelValue(state);

				if (value != null) {
					if (!this.isHtmlLabel(state.cell)) {
						value = mx.mxUtils.htmlEntities(value, false);
					}

					value = value.replace(/\n/g, '<br>');

					let size = mx.mxUtils.getSizeForString(value, fontSize,
						style[mx.mxConstants.STYLE_FONTFAMILY], textWidth,
						style[mx.mxConstants.STYLE_FONTSTYLE]);
					let width = size.width + dx;
					let height = size.height + dy;

					if (!mx.mxUtils.getValue(style, mx.mxConstants.STYLE_HORIZONTAL, true)) {
						let tmp = height;

						height = width;
						width = tmp;
					}

					if (this.gridEnabled) {
						width = this.snap(width + this.gridSize / 2);
						height = this.snap(height + this.gridSize / 2);
					}

					result = new mx.mxRectangle(0, 0, width, height);
				} else {
					let gs2 = 4 * this.gridSize;
					result = new mx.mxRectangle(0, 0, gs2, gs2);
				}
			}
		}

		return result;
	};

	mx.mxCellEditor.prototype.startEditing = function (cell, trigger) {
		this.stopEditing(true);
		this.align = null;

		// Creates new textarea instance
		if (this.textarea == null) {
			this.init();
		}

		if (this.graph.tooltipHandler != null) {
			this.graph.tooltipHandler.hideTooltip();
		}

		let state = this.graph.getView().getState(cell);

		if (state != null) {
			// Configures the style of the in-place editor
			let scale = this.graph.getView().scale;
			let size = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_FONTSIZE, mx.mxConstants.DEFAULT_FONTSIZE);
			let family = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_FONTFAMILY, mx.mxConstants.DEFAULT_FONTFAMILY);
			let color = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_FONTCOLOR, 'black');
			let align = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_ALIGN, mx.mxConstants.ALIGN_LEFT);
			let bold = (mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_FONTSTYLE, 0) &
				mx.mxConstants.FONT_BOLD) == mx.mxConstants.FONT_BOLD;
			let italic = (mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_FONTSTYLE, 0) &
				mx.mxConstants.FONT_ITALIC) == mx.mxConstants.FONT_ITALIC;
			let txtDecor = [];

			if ((mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_FONTSTYLE, 0) &
				mx.mxConstants.FONT_UNDERLINE) == mx.mxConstants.FONT_UNDERLINE) {
				txtDecor.push('underline');
			}

			if ((mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_FONTSTYLE, 0) &
				mx.mxConstants.FONT_STRIKETHROUGH) == mx.mxConstants.FONT_STRIKETHROUGH) {
				txtDecor.push('line-through');
			}

			this.textarea.style.lineHeight = (mx.mxConstants.ABSOLUTE_LINE_HEIGHT) ? Math.round(size * mx.mxConstants.LINE_HEIGHT) + 'px' : mx.mxConstants.LINE_HEIGHT;
			this.textarea.style.backgroundColor = this.getBackgroundColor(state);
			this.textarea.style.textDecoration = txtDecor.join(' ');
			this.textarea.style.fontWeight = (bold) ? 'bold' : 'normal';
			this.textarea.style.fontStyle = (italic) ? 'italic' : '';
			this.textarea.style.fontSize = Math.round(size) + 'px';
			this.textarea.style.zIndex = this.zIndex;
			this.textarea.style.fontFamily = family;
			this.textarea.style.textAlign = align;
			this.textarea.style.outline = 'none';
			this.textarea.style.color = color;
			//ESGI change
			this.textarea.style.width = cell.geometry.width + 'px';

			let dir = this.textDirection = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_TEXT_DIRECTION, mx.mxConstants.DEFAULT_TEXT_DIRECTION);

			if (dir == mx.mxConstants.TEXT_DIRECTION_AUTO) {
				if (state != null && state.text != null && state.text.dialect != mx.mxConstants.DIALECT_STRICTHTML &&
					!mx.mxUtils.isNode(state.text.value)) {
					dir = state.text.getAutoDirection();
				}
			}

			if (dir == mx.mxConstants.TEXT_DIRECTION_LTR || dir == mx.mxConstants.TEXT_DIRECTION_RTL) {
				this.textarea.setAttribute('dir', dir);
			} else {
				this.textarea.removeAttribute('dir');
			}

			// Sets the initial editing value
			this.textarea.innerHTML = this.getInitialValue(state, trigger) || '';
			this.initialValue = this.textarea.innerHTML;

			// Uses an optional text value for empty labels which is cleared
			// when the first keystroke appears. This makes it easier to see
			// that a label is being edited even if the label is empty.
			if (this.textarea.innerHTML.length == 0 || this.textarea.innerHTML == '<br>') {
				this.textarea.innerHTML = this.getEmptyLabelText();
				this.clearOnChange = true;
			} else {
				this.clearOnChange = this.textarea.innerHTML == this.getEmptyLabelText();
			}

			this.graph.container.appendChild(this.textarea);

			// Update this after firing all potential events that could update the cleanOnChange flag
			this.editingCell = cell;
			this.trigger = trigger;
			this.textNode = null;

			if (state.text != null && this.isHideLabel(state)) {
				this.textNode = state.text.node;
				this.textNode.style.visibility = 'hidden';
			}

			// Workaround for initial offsetHeight not ready for heading in markup
			if (this.autoSize && (this.graph.model.isEdge(state.cell) || state.style[mx.mxConstants.STYLE_OVERFLOW] != 'fill')) {
				window.setTimeout(mx.mxUtils.bind(this, function () {
					this.resize();
				}), 0);
			}

			this.resize();

			// Workaround for NS_ERROR_FAILURE in FF
			try {
				// Prefers blinking cursor over no selected text if empty
				//this.textarea.focus();

				if (this.isSelectText() && this.textarea.innerHTML.length > 0 &&
					(this.textarea.innerHTML != this.getEmptyLabelText() || !this.clearOnChange)) {
					//ESGI change
					//document.execCommand('selectAll', false, null);
				}
			} catch (e) {
				// ignore
			}
		}
	};

	mx.mxCellEditor.prototype.resize = function () {
		let state = this.graph.getView().getState(this.editingCell);

		if (state == null) {
			this.stopEditing(true);
		} else if (this.textarea != null) {
			let isEdge = this.graph.getModel().isEdge(state.cell);
			let scale = this.graph.getView().scale;
			let m = null;

			if (!this.autoSize || (state.style[mx.mxConstants.STYLE_OVERFLOW] == 'fill')) {
				// Specifies the bounds of the editor box
				this.bounds = this.getEditorBounds(state);
				this.textarea.style.width = Math.round(this.bounds.width / scale) + 'px';
				this.textarea.style.height = Math.round(this.bounds.height / scale) + 'px';

				// FIXME: Offset when scaled
				if ((document as any).documentMode == 8 || mx.mxClient.IS_QUIRKS) {
					this.textarea.style.left = Math.round(this.bounds.x) + 'px';
					this.textarea.style.top = Math.round(this.bounds.y) + 'px';
				} else {
					this.textarea.style.left = Math.max(0, Math.round(this.bounds.x + 1)) + 'px';
					this.textarea.style.top = Math.max(0, Math.round(this.bounds.y + 1)) + 'px';
				}

				// Installs native word wrapping and avoids word wrap for empty label placeholder
				if (this.graph.isWrapping(state.cell) && (this.bounds.width >= 2 || this.bounds.height >= 2) &&
					this.textarea.innerHTML != this.getEmptyLabelText()) {
					this.textarea.style.wordWrap = mx.mxConstants.WORD_WRAP;
					this.textarea.style.whiteSpace = 'normal';

					if (state.style[mx.mxConstants.STYLE_OVERFLOW] != 'fill') {
						this.textarea.style.width = Math.round(this.bounds.width / scale) + this.wordWrapPadding + 'px';
					}
				} else {
					this.textarea.style.whiteSpace = 'nowrap';

					if (state.style[mx.mxConstants.STYLE_OVERFLOW] != 'fill') {
						this.textarea.style.width = '';
					}
				}
			} else {
				let lw = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_LABEL_WIDTH, null);
				m = (state.text != null && this.align == null) ? state.text.margin : null;

				if (m == null) {
					m = mx.mxUtils.getAlignmentAsPoint(this.align || mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_ALIGN, mx.mxConstants.ALIGN_CENTER),
						mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_VERTICAL_ALIGN, mx.mxConstants.ALIGN_MIDDLE));
				}

				let tmp;
				if (isEdge) {

					this.bounds = new mx.mxRectangle(state.absoluteOffset.x, state.absoluteOffset.y, 0, 0);

					if (lw != null) {
						tmp = (parseFloat(lw) + 2) * scale;
						this.bounds.width = tmp;
						this.bounds.x += m.x * tmp;
					}
				} else {
					let bds = mx.mxRectangle.fromRectangle(state);
					let hpos = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_LABEL_POSITION, mx.mxConstants.ALIGN_CENTER);
					let vpos = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_VERTICAL_LABEL_POSITION, mx.mxConstants.ALIGN_MIDDLE);

					bds = (state.shape != null && hpos == mx.mxConstants.ALIGN_CENTER && vpos == mx.mxConstants.ALIGN_MIDDLE) ? state.shape.getLabelBounds(bds) : bds;

					if (lw != null) {
						bds.width = parseFloat(lw) * scale;
					}

					//ESGI change
					if (!state.view.graph.cellRenderer.legacySpacing || state.style[mx.mxConstants.STYLE_OVERFLOW] != 'width') {
						let spacing = 0;// parseInt(state.style[mx.mxConstants.STYLE_SPACING] || 2) * scale;
						let spacingTop = 0; //(parseInt(state.style[mx.mxConstants.STYLE_SPACING_TOP] || 0) + mxText.prototype.baseSpacingTop) * scale + spacing;
						let spacingRight = 0; //(parseInt(state.style[mx.mxConstants.STYLE_SPACING_RIGHT] || 0) + mxText.prototype.baseSpacingRight) * scale + spacing;
						let spacingBottom = 0; //(parseInt(state.style[mx.mxConstants.STYLE_SPACING_BOTTOM] || 0) + mxText.prototype.baseSpacingBottom) * scale + spacing;
						let spacingLeft = 0; //(parseInt(state.style[mx.mxConstants.STYLE_SPACING_LEFT] || 0) + mxText.prototype.baseSpacingLeft) * scale + spacing;

						let hpos = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_LABEL_POSITION, mx.mxConstants.ALIGN_CENTER);
						let vpos = mx.mxUtils.getValue(state.style, mx.mxConstants.STYLE_VERTICAL_LABEL_POSITION, mx.mxConstants.ALIGN_MIDDLE);

						bds = new mx.mxRectangle(bds.x + spacingLeft, bds.y + spacingTop,
							bds.width - ((hpos == mx.mxConstants.ALIGN_CENTER && lw == null) ? (spacingLeft + spacingRight) : 0),
							bds.height - ((vpos == mx.mxConstants.ALIGN_MIDDLE) ? (spacingTop + spacingBottom) : 0));
					}

					this.bounds = new mx.mxRectangle(bds.x + state.absoluteOffset.x, bds.y + state.absoluteOffset.y, bds.width, bds.height);
				}

				// Needed for word wrap inside text blocks with oversize lines to match the final result where
				// the width of the longest line is used as the reference for text alignment in the cell
				// TODO: Fix word wrapping preview for edge labels in helloworld.html
				if (this.graph.isWrapping(state.cell) && (this.bounds.width >= 2 || this.bounds.height >= 2) &&
					this.textarea.innerHTML != this.getEmptyLabelText()) {
					this.textarea.style.wordWrap = mx.mxConstants.WORD_WRAP;
					this.textarea.style.whiteSpace = 'normal';

					// Forces automatic reflow if text is removed from an oversize label and normal word wrap
					tmp = Math.round(this.bounds.width / (((document as any).documentMode == 8) ? scale : scale)) + this.wordWrapPadding;

					if (this.textarea.style.position != 'relative') {
						this.textarea.style.width = tmp + 'px';

						if (this.textarea.scrollWidth > tmp) {
							this.textarea.style.width = this.textarea.scrollWidth + 'px';
						}
					} else {
						this.textarea.style.maxWidth = tmp + 'px';
					}
				} else {
					// KNOWN: Trailing cursor in IE9 quirks mode is not visible
					this.textarea.style.whiteSpace = 'nowrap';
					//this.textarea.style.width = '';
				}

				// LATER: Keep in visible area, add fine tuning for pixel precision
				// Workaround for wrong measuring in IE8 standards
				if ((document as any).documentMode == 8) {
					this.textarea.style.zoom = '1';
					this.textarea.style.height = 'auto';
				}

				let ow = this.textarea.scrollWidth;
				let oh = this.textarea.scrollHeight;

				// TODO: Update CSS width and height if smaller than minResize or remove minResize
				//if (this.minResize != null)
				//{
				//	ow = Math.max(ow, this.minResize.width);
				//	oh = Math.max(oh, this.minResize.height);
				//}

				// LATER: Keep in visible area, add fine tuning for pixel precision
				if ((document as any).documentMode == 8) {
					// LATER: Scaled wrapping and position is wrong in IE8
					this.textarea.style.left = Math.max(0, Math.ceil((this.bounds.x - m.x * (this.bounds.width - (ow + 1) * scale) + ow * (scale - 1) * 0 + (m.x + 0.5) * 2) / scale)) + 'px';
					this.textarea.style.top = Math.max(0, Math.ceil((this.bounds.y - m.y * (this.bounds.height - (oh + 0.5) * scale) + oh * (scale - 1) * 0 + Math.abs(m.y + 0.5) * 1) / scale)) + 'px';
					// Workaround for wrong event handling width and height
					this.textarea.style.width = Math.round(ow * scale) + 'px';
					this.textarea.style.height = Math.round(oh * scale) + 'px';
				} else if (mx.mxClient.IS_QUIRKS) {
					this.textarea.style.left = Math.max(0, Math.ceil(this.bounds.x - m.x * (this.bounds.width - (ow + 1) * scale) + ow * (scale - 1) * 0 + (m.x + 0.5) * 2)) + 'px';
					this.textarea.style.top = Math.max(0, Math.ceil(this.bounds.y - m.y * (this.bounds.height - (oh + 0.5) * scale) + oh * (scale - 1) * 0 + Math.abs(m.y + 0.5) * 1)) + 'px';
				} else {
					this.textarea.style.left = Math.max(0, Math.round(this.bounds.x - m.x * (this.bounds.width - 2)) + 1) + 'px';
					this.textarea.style.top = Math.max(0, Math.round(this.bounds.y - m.y * (this.bounds.height - 4) + ((m.y == -1) ? 3 : 0)) + 1) + 'px';
				}
			}

			if (mx.mxClient.IS_VML) {
				this.textarea.style.zoom = scale;
			} else {
				mx.mxUtils.setPrefixedStyle(this.textarea.style, 'transformOrigin', '0px 0px');
				mx.mxUtils.setPrefixedStyle(this.textarea.style, 'transform',
					'scale(' + scale + ',' + scale + ')' + ((m == null) ? '' :
						' translate(' + (m.x * 100) + '%,' + (m.y * 100) + '%)'));
			}
		}
	};

	mx.mxSvgCanvas2D.prototype.createCss = function (w, h, align, valign, wrap, overflow, clip, bg, border, flex, block, s, callback) {
		let item = 'box-sizing: border-box; font-size: 0; text-align: ' + ((align == mx.mxConstants.ALIGN_LEFT) ? 'left' :
			((align == mx.mxConstants.ALIGN_RIGHT) ? 'right' : 'center')) + '; ';
		let pt = mx.mxUtils.getAlignmentAsPoint(align, valign);
		let ofl = 'overflow: hidden; ';
		let fw = 'width: 1px; ';
		let fh = 'height: 1px; ';
		let dx = pt.x * w;
		let dy = pt.y * h;

		//ESGI change
		if (w === 710) {
			w = 708;
		}

		if (clip) {
			fw = 'width: ' + Math.round(w) + 'px; ';
			item += 'max-height: ' + Math.round(h) + 'px; ';
			dy = 0;
		} else if (overflow == 'fill') {
			fw = 'width: ' + Math.round(w) + 'px; ';
			fh = 'height: ' + Math.round(h) + 'px; ';
			block += 'width: 100%; height: 100%; ';
			item += fw + fh;
		} else if (overflow == 'width') {
			fw = 'width: ' + Math.round(w) + 'px; ';
			block += 'width: 100%; ';
			item += fw;
			dy = 0;

			if (h > 0) {
				item += 'max-height: ' + Math.round(h) + 'px; ';
			}
		} else {
			ofl = '';
			dy = 0;
		}

		let bgc = '';

		if (bg != null) {
			bgc += 'background-color: ' + bg + '; ';
		}

		if (border != null) {
			bgc += 'border: 1px solid ' + border + '; ';
		}

		if (ofl == '' || clip) {
			block += bgc;
		} else {
			item += bgc;
		}

		if (wrap && w > 0) {
			block += 'white-space: normal; word-wrap: ' + mx.mxConstants.WORD_WRAP + '; ';
			fw = 'width: ' + Math.round(w) + 'px; ';

			if (ofl != '' && overflow != 'fill') {
				dy = 0;
			}
		} else {
			block += 'white-space: nowrap; ';

			if (ofl == '') {
				dx = 0;
			}
		}

		callback(dx, dy, flex + fw + fh, item + ofl, block, ofl);
	};

	mx.mxVertexHandler.prototype.rotateVertex = function (me) {
		let point = new mx.mxPoint(me.getGraphX(), me.getGraphY());
		let dx = this.state.x + this.state.width / 2 - point.x;
		let dy = this.state.y + this.state.height / 2 - point.y;
		this.currentAlpha = (dx != 0) ? Math.atan(dy / dx) * 180 / Math.PI + 90 : ((dy < 0) ? 180 : 0);

		if (dx > 0) {
			this.currentAlpha -= 180;
		}

		this.currentAlpha -= this.startAngle;

		// Rotation raster
		if (this.rotationRaster && this.graph.isGridEnabledEvent(me.getEvent())) {
			let dx = point.x - this.state.getCenterX();
			let dy = point.y - this.state.getCenterY();
			let dist = Math.sqrt(dx * dx + dy * dy);
			let raster;
			if (dist - this.startDist < 2) {
				raster = 15;
			} else if (dist - this.startDist < 25) {
				raster = 5;
			} else {
				raster = 1;
			}

			this.currentAlpha = Math.round(this.currentAlpha / raster) * raster;
		} else {
			this.currentAlpha = this.roundAngle(this.currentAlpha);
		}

		//ESGI change
		if (this.selectionBorder != null) {
			this.selectionBorder.rotation = this.currentAlpha;
			this.selectionBorder.redraw();
		}

		if (this.livePreviewActive) {
			this.redrawHandles();
		}
	};

	mx.mxVertexHandler.prototype.mouseMove = function (sender, me) {
		if (!me.isConsumed() && this.index != null) {
			//ESGI change
			if (this.index == mx.mxEvent.ROTATION_HANDLE && this.selectionBorder != null) {
				if (this.currentAlpha != null) {
					let delta = this.currentAlpha - (this.state.style[mx.mxConstants.STYLE_ROTATION] || 0);

					if (delta != 0) {
						this.rotateCell(this.state.cell, delta);
					}
				}
			}

			// Checks tolerance for ignoring single clicks
			this.checkTolerance(me);

			if (!this.inTolerance) {
				if (this.index <= mx.mxEvent.CUSTOM_HANDLE) {
					if (this.customHandles != null) {
						this.customHandles[mx.mxEvent.CUSTOM_HANDLE - this.index].processEvent(me);
						this.customHandles[mx.mxEvent.CUSTOM_HANDLE - this.index].active = true;

						if (this.ghostPreview != null) {
							this.ghostPreview.apply(this.state);
							this.ghostPreview.strokewidth = this.getSelectionStrokeWidth() /
								this.ghostPreview.scale / this.ghostPreview.scale;
							this.ghostPreview.isDashed = this.isSelectionDashed();
							this.ghostPreview.stroke = this.getSelectionColor();
							this.ghostPreview.redraw();

							if (this.selectionBounds != null) {
								this.selectionBorder.node.style.display = 'none';
							}
						} else {
							if (this.movePreviewToFront) {
								this.moveToFront();
							}

							this.customHandles[mx.mxEvent.CUSTOM_HANDLE - this.index].positionChanged();
						}
					}
				} else if (this.index == mx.mxEvent.LABEL_HANDLE) {
					this.moveLabel(me);
				} else {
					if (this.index == mx.mxEvent.ROTATION_HANDLE) {
						this.rotateVertex(me);
					} else {
						this.resizeVertex(me);
					}

					this.updateHint(me);
				}
			}

			me.consume();
			// eslint-disable-next-line brace-style
		}
		// Workaround for disabling the connect highlight when over handle
		else if (!this.graph.isMouseDown && this.getHandleForEvent(me) != null) {
			me.consume(false);
		}
	};

	mx.mxUtils.getSizeForString = function (text, fontSize, fontFamily, textWidth, fontStyle) {
		fontSize = (fontSize != null) ? fontSize : mx.mxConstants.DEFAULT_FONTSIZE;
		fontFamily = (fontFamily != null) ? fontFamily : mx.mxConstants.DEFAULT_FONTFAMILY;
		let div = document.createElement('div');

		// Sets the font size and family
		div.style.fontFamily = fontFamily;
		div.style.fontSize = Math.round(fontSize) + 'px';
		div.style.lineHeight = Math.round(fontSize * mx.mxConstants.LINE_HEIGHT) + 'px';

		// Sets the font style
		if (fontStyle != null) {
			if ((fontStyle & mx.mxConstants.FONT_BOLD) == mx.mxConstants.FONT_BOLD) {
				div.style.fontWeight = 'bold';
			}

			if ((fontStyle & mx.mxConstants.FONT_ITALIC) == mx.mxConstants.FONT_ITALIC) {
				div.style.fontStyle = 'italic';
			}

			let txtDecor = [];

			if ((fontStyle & mx.mxConstants.FONT_UNDERLINE) == mx.mxConstants.FONT_UNDERLINE) {
				txtDecor.push('underline');
			}

			if ((fontStyle & mx.mxConstants.FONT_STRIKETHROUGH) == mx.mxConstants.FONT_STRIKETHROUGH) {
				txtDecor.push('line-through');
			}

			if (txtDecor.length > 0) {
				div.style.textDecoration = txtDecor.join(' ');
			}
		}

		// Disables block layout-deprecated and outside wrapping and hides the div
		div.style.position = 'absolute';
		div.style.visibility = 'hidden';
		div.style.display = (mx.mxClient.IS_QUIRKS) ? 'inline' : 'inline-block';
		(div.style as any).zoom = '1';

		if (textWidth != null) {
			div.style.width = textWidth + 'px';
			div.style.whiteSpace = 'normal';
			div.style.wordBreak = 'break-word';
		} else {
			div.style.whiteSpace = 'nowrap';
		}

		if (text != null && text.length == 0) {
			text = 'I';
		}

		// Adds the text and inserts into DOM for updating of size
		div.innerHTML = text;
		document.body.appendChild(div);

		//ESGI change. We need to get lower height. This need for display 200pt font size.
		let height = div.offsetHeight - fontSize / 9;

		// Gets the size and removes from DOM
		let size = new mx.mxRectangle(0, 0, div.offsetWidth, height);
		document.body.removeChild(div);

		return size;
	};

	mx.mxCellEditor.prototype.getInitialValue = function (state, trigger) {
		let result = this.graph.getEditingValue(state.cell, trigger);

		if (mx.mxUtils.getValue(state.style, 'nl2Br', '1') == '1') {
			result = result.replace(/\n/g, '<br/>');
		}
		return result;
	};

	mx.mxCellEditor.prototype.getCurrentValue = function (state) {
		let result = this.textarea.innerHTML;

		if (mx.mxUtils.getValue(state.style, 'nl2Br', '1') == '1') {
			result = result.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>');
		} else {
			result = result.replace(/\r\n/g, '').replace(/\n/g, '');
		}

		return result;
	};
}
