import {useEffect} from 'react';
import {Sort, ChangeFunction} from '../../types';
import {SettingsPanel} from '@esgi/main/kits/reports';
import {Text} from '@esgi/ui/typography';
import {Wrapper, Radiobox, Link, SettingsPanelTitle} from './index.styled';
import {Info} from '@esgi/ui/icons';
import {RadioGroup} from '@esgi/ui/controls';

interface Props {
	disabled?: boolean;
	value?: Sort;
	onChange: ChangeFunction<Sort>;
}

const options: [Sort, string][] = [
	[Sort.LeastKnown, 'Least Known'],
	[Sort.MostKnown, 'Most Known'],
	[Sort.QuestionOrder, 'Question Order'],
	[Sort.Alphabetical, 'Alphabetical'],
];

export function SortControl(props: Props) {
	const {disabled, value, onChange} = props;

	useEffect(() => {
		if (typeof value === 'undefined') {
			onChange(Sort.LeastKnown);
		}
	}, []);

	return (
		<SettingsPanel>
			<SettingsPanelTitle>
				<Text data-cy='sort-title' size='small' font='mono' color='lowContrast'>
					Sort
				</Text>

				<Link
					href='https://support.esgisoftware.com/hc/en-us/articles/209159686#Sort%20Options'
					target='_blank'
					rel='noreferrer'
					data-cy='help-link'
				>
					<Info />
				</Link>
			</SettingsPanelTitle>
			<Wrapper>
				<RadioGroup.Root
					disabled={disabled}
					value={value.toString()}
					onValueChange={(value) => onChange(Number(value) as Sort)}
					dataCy='sort-radio-group'
				>
					<RadioGroup.Content orientation='vertical'>
						{options.map(([value, lavel]) => (
							<Radiobox value={value.toString()} key={value}>
								<Text data-cy='radio-button' size='small' font='mono' color='currentColor'>
									{lavel}
								</Text>
							</Radiobox>
						))}
					</RadioGroup.Content>
				</RadioGroup.Root>
			</Wrapper>
		</SettingsPanel>
	);
}
