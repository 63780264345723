import React from 'react';
import moment from 'moment';
import {SortDirection} from '@esgi/core/enums';
import {RowModel} from './model';
import {AvailableTest} from '../../../../../../../../../../models';
import _ from 'underscore';
import {Row} from './components/row';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {Column} from './components/column';

class State {
	rows: RowModel[] = [];
	sortBy: string = 'CreateDate';
	sortDirection: SortDirection = SortDirection.Desc;
}

interface Props {
	unSelectedTests: AvailableTest[];
	contentAreaId: number;
	keyword: string;
	testChecked: (testID: number, checked: boolean) => void;
	selectedTestIds: number[];
}

export class TableResults extends React.Component<Props, State> {
	public state = new State();

	componentDidMount(): void {
		this.loadTests();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		if (prevProps.contentAreaId !== this.props.contentAreaId ||
			prevProps.keyword !== this.props.keyword ||
			prevState.sortBy !== this.state.sortBy ||
			prevState.sortDirection !== this.state.sortDirection ||
			prevProps.unSelectedTests !== this.props.unSelectedTests) {
			this.loadTests();
			return;
		}
	}

	createRow(author: string, date: string, id: number, name: string, numberOfQuestions: number): RowModel {
		return {
			author: author,
			date: moment(date).format('MM/DD/YYYY'),
			id: id,
			name: name,
			questions: numberOfQuestions,
		};
	}

	loadTests() {
		this.setState({rows: []});
		if (this.props.unSelectedTests.length === 0) {
			return;
		}
		const tests = this.props.unSelectedTests
			.filter(test => test.name.toLowerCase().includes(this.props.keyword) || test.creatorName.toLowerCase().includes(this.props.keyword))
			.filter(t => this.props.contentAreaId === -1 || t.contentAreaID === this.props.contentAreaId)
			.map(t => this.createRow(t.creatorName, t.createDate, t.id, t.name, t.questionsCount));
		const sorted = _.sortBy(tests, this.state.sortBy);
		if (this.state.sortDirection !== SortDirection.Asc) {
			sorted.reverse();
		}
		this.setState({rows: sorted});
	}

	render() {
		return <div className={styles.resultsWrapper}>
			<div className={join(styles.results, !this.state.rows.length && styles.empty)}>
				<div className={styles.tbody}>
					<table className='table table-bonnie table-hover table-striped table-responsive table-results'>
						<thead>
						<tr>
							<Column sorted={false}/>
							<Column title='Test Name'
							        sorted={this.state.sortBy === 'name'}
							        sortDirection={this.state.sortDirection}
							        clicked={() => this.changeSort('name')}/>
							<Column title='Author'
							        sorted={this.state.sortBy === 'author'}
							        sortDirection={this.state.sortDirection}
							        clicked={() => this.changeSort('author')}/>
							<Column title='Create Date'
							        sorted={this.state.sortBy === 'date'}
							        sortDirection={this.state.sortDirection}
							        clicked={() => this.changeSort('date')}/>
							<Column title='Questions' className={styles.questions}
							        sorted={this.state.sortBy === 'questions'}
							        sortDirection={this.state.sortDirection}
							        clicked={() => this.changeSort('questions')}/>
						</tr>
						</thead>
						<tbody>
						{!this.state.rows.length &&
							<tr>
								<td colSpan={5}>Your search returned 0 results</td>
							</tr>
						}
						{this.state.rows.map(t => {
							return <Row
								key={t.id}
								row={t}
								onSelected={(checked) => this.props.testChecked(t.id, checked)}
								selected={this.props.selectedTestIds.indexOf(t.id) > -1}/>;
						})}
						</tbody>
					</table>
				</div>

			</div>
		</div>;
	}

	private changeSort(sortBy: string) {
		this.setState((prev) => {
			if (prev.sortBy === sortBy) {
				if (prev.sortDirection === SortDirection.Asc) {
					return {
						sortBy: prev.sortBy,
						sortDirection: SortDirection.Desc,
					};
				} else {
					return {
						sortBy: prev.sortBy,
						sortDirection: SortDirection.Asc,
					};
				}
			} else {
				return {
					sortBy: sortBy,
					sortDirection: SortDirection.Asc,
				};
			}
		});
	}
}
