import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {SettingsResponse, Subject as SubjectType} from '../../../../types';
import styles from '../../styles.module.less';

interface Props {
	value: SubjectType;
	settings: SettingsResponse;
	onChange: (value: SubjectType) => void;
}

export const Subject = (props: Props): JSX.Element => {
	const {subjects} = props.settings;

	return (
		<div className={styles.field}>
			<label className={styles.title}>Subject Tab:</label>
			<Dropdown
				optionName='name'
				autoWidth={true}
				value={[props.value]}
				setValue={(subject) => props.onChange(subject[0])}
			>
				{subjects.map((subject) => (
					<Option
						key={subject.id}
						value={subject}
					>
						{subject.name}
					</Option>
				))}
			</Dropdown>
		</div>
	);
};
