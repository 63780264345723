import React from 'react';
import DraggableButton from '../draggable-button';

export default class StudentIdDraggableButton extends React.Component {
  public render() {
    return <DraggableButton
      label='Student ID'
      src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFUAAAAeCAYAAABdalL1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVKSURBVHgB7ZpPTBxVHMd/b3ZKLKyGpii9FJn2UA60tL2oXRKJlO2NPz0hoYWGmmgaBWIJsR7KHsQ0YASJoYklQNuIpwp6kqoNSWnqpVLtwSa2u8LFrRg3ykKjyzx/37fOssCy7AxwsfNJyM68N3/e+77fnzfvISgDgn/IXNJjBzWPVkVSlJGUhSQol/7vSIoITUxJklNmTE4Yz+ijmdwm0lVCTP0pajYXzZYnQsT1CWmaNhR7/HjY2LE9tNZFa4o6My+bTdPscMVMSUhIGSjw6kOpKlOKOj0vP5SSrdMlLUJoPQXZonVV+cqC6ejioCRqJJeMYAGHCnI8p5LLtOQTZaGuoLaAXtAtuSxhqcG5WKMmxCC5OMIU1Gpke3pwrETlLF+oZZk3+LCQXJwSMf/WDGOHiCj317OogVxBN0ouZcVUcleW+kt0MUg2RZ2PztH0wwc0+yhMec/lU9H+EtoM8MyRS/3U3tlNW4XVdqvNOEYZyM7xUsGeveQQZa168M9YNdkUdPbRr3Th3FnKezaf8vJ30di9H5Swb77boRr10493uZFROvziEbILOof7N8qd25OqLakGG3Vjn16hroGr6ryv8zzBvtCf2d/CnH0k9yXgRFz15amTkGXrfFitYpQbVFRcQk0tbYkyiDw+9jlV151QovzOFuxE1M3izu1btjyoqraeSo8eU8fjY9dUfwIf9fMzdpEtBFXrHuEpkZgY2GCBrXAnN3hZo149oZ7Y914HizqlymbPhZUbo2xf8QHyVx1PNHom+CAxKNe/uMYDdTml600//JnDwUU1UKhram5TvzgfG7mijm9+/VW8DXUN5K+sobbT9TQ/F3dnDG7y4GcC2vn9d7eUkdS99oatezXheVmTQh4km1RwwyHEQE9XwlVhEUX7D6gQUFF5nK3Ul4iLcGkrZlnns+GwOr75zbhqfHvnB8odkwcLYaavs4MOvXCEBr+8Tr5X/MqCrGfh3buNvfTxZ6M8qCfZpS+ruq5LV+kQvx/tsCuoBd45w7HWPjyTIrL/bQ8BIQDsG1bY1lSvxHHCJN/nK69IWCgGwwIJBC85/JJPJUT8Zntz4uUM3Lu03B+/j+vigxelzQDvcUiuTg5Bh07/ZwUQdOSTfnVsddLus1IRz8pRuvDO28vKIR5CxVZieZITIGqEbFor3L6q7mRCDAiJ2Hef3dGuqBBnLetCXV5+PgV6L66q24wZQjomvx1XIcUBPPmXFCKbwBWt+AXwi8C+M38pUyYLhTiJhGFdiyRlsdvYo+Iz4ie4f29JrNKjfmUxmAJZ945yckqOz+lwEgrwbMyTeTHe0dybc9SUbkpzQhOarWSF4I9sfaa2RlkSOo6k4K+MZ3c0BpkZWRhJo5qtGgnmTG01xyrvsmkK6tCR82+9rur2FS91BJba1HJWZX90FBndV34sI9dHG+BRC/zsTJLVQG+3+sOzfext7e93rxmW0iFNeVcE//qnjFezb5ADYAlWfMvOyUlZl7csm4dTXmtdD1LVrXdvuvale+ZWYC7KGoEtE15MwWequ8K/UQSFns/2GBpWVXjbpJdcNgyH0mH8xhepYzrWASPk4hy2UtJiQzhUoiprlYsBcnGMMGXA2B7fYU1spxjerB43DDiD3b43eWd11fIUb6sM8bZKA7lkhCnlsOHVG5PLtJUX4QLXYjMDFrpSUKCluth4elsLj8ApFXxdUoEc1Gp4t6X834j0//azIAspxrusHq2BP2cLySU+/fTqPYYQa86WMl7yV9sumiwTJEqEEPisfRI+FiK8sxKSZE6wUY2ymFPpxLT4F49AaoXuN/RsAAAAAElFTkSuQmCC'
      width={85}
      height={30}
    />;
  }
}
