import {createContext, RefObject, useContext} from 'react';

interface LayoutContext {
	layoutRef: RefObject<HTMLElement>;
	mainRef: RefObject<HTMLElement>;
}

export const LayoutContext = createContext<LayoutContext>({
	layoutRef: null,
	mainRef: null,
});

export const useLayoutContext = () => useContext(LayoutContext);
