/* eslint-disable @typescript-eslint/member-ordering */
import {
	ClassTeacherItem,
	CreateAssignmentExtractedTeacherData,
	EditInitTeacherResponse,
	GroupTeacherItem,
	InitTeacherResponse,
	StudentInfoTeacher,
} from '../types/teacher-types';
import {CreateAssignmentService} from './create-assignment-service';
import {HttpStatusCode} from 'axios';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {SaveRequestParameters, SaveResponse, TestItem} from '../types/types';
import {isEmpty, uniq} from 'underscore';

export class CreateAssignmentTeacherService extends CreateAssignmentService {
	public students = new BehaviorSubject<StudentInfoTeacher[]>([]);
	public selectedStudents = new BehaviorSubject<StudentInfoTeacher[]>([]);

	public groups = new BehaviorSubject<GroupTeacherItem[]>([]);

	public classes = new BehaviorSubject<ClassTeacherItem[]>([]);
	public selectedClass = new BehaviorSubject<ClassTeacherItem[]>([]);

	private initEditResponse: EditInitTeacherResponse | undefined;

	public setStudents(students: StudentInfoTeacher[]) {
		const uniqueStudents = uniq(students, (s) => s.id);
		this.selectedStudents.next(uniqueStudents);
	}

	public removeSelectedStudent(id: number) {
		this.setStudents(this.selectedStudents.value.filter((student) => student.id !== id));
	}

	public init(
		assignmentID?: number,
		goToAssignmentList?: VoidFunction,
		createAssignmentExtractedData?: CreateAssignmentExtractedTeacherData | null,
	) {
		this.startInitResponse(assignmentID);

		if (assignmentID) {
			return this.httpClient.ESGIApi.get<EditInitTeacherResponse>('assignments/edit/teacher', 'init', {assignmentID})
				.withCustomErrorHandler((httpRequest) => {
					if (httpRequest.status === HttpStatusCode.InternalServerError) {
						goToAssignmentList?.();
					}
				})
				.pipe(
					tap((resp) => this.initForm(resp)),
					tap((resp) => (this.initEditResponse = resp)),
					tap((resp) => {
						this.nameFormGroup.controls.name.value = resp.assignment.name;
						this.nameFormGroup.controls.description.value = resp.assignment.description;
						this.selectedTestsIds.next(resp.assignment.tests);

						const students = this.students.value.filter((s) => resp.assignment.students?.includes(s.id));
						this.setStudents(students);
					}),
				);
		}

		return this.httpClient.ESGIApi.get<InitTeacherResponse>('assignments/wizard/teacher', 'init').pipe(
			tap((resp) => this.initForm(resp, createAssignmentExtractedData)),
		);
	}

	private initForm(
		data: {tests: TestItem[]; classes: ClassTeacherItem[]; assignment?: {name: string}},
		createAssignmentExtractedData?: CreateAssignmentExtractedTeacherData | null,
	) {
		this.nameFormGroup.controls.name.validators.push(this.nameUniqueValidator(this.httpClient, data.assignment?.name));

		this.tests.next(data.tests);

		const classes = data.classes.map((c) => {
			c.groupIds = c.groups.map(({id}) => id);
			return c;
		});

		const students = uniq(classes.map(({students}) => students).flat(), ({id}) => id);
		const groups = uniq(classes.map(({groups}) => groups).flat(), ({id}) => id);

		const studentInfos = students.map((s) => {
			const studentClasses = classes.filter(({students}) => students.some(({id}) => id === s.id));
			const studentGroups = groups.filter(({studentIDs}) => studentIDs.includes(s.id));

			const studentInfo: StudentInfoTeacher = {
				class: studentClasses.map(({name}) => name).join(','),
				name: s.name,
				id: s.id,
				group: studentGroups.map(({name}) => name).join(','),
				hasCredentials: s.hasCredentials,
			};

			return studentInfo;
		});

		this.classes.next(classes);
		this.groups.next(groups);
		this.students.next(studentInfos);

		if (createAssignmentExtractedData) {
			this.createAssignmentExtractedData({
				assignmentName: createAssignmentExtractedData.name,
				assignmentDescription: createAssignmentExtractedData.description,
				selectedTestsIds: createAssignmentExtractedData.selectedTestsIds,
				selectedTestsFilters: createAssignmentExtractedData.selectedTestsFilters,
			});

			if (createAssignmentExtractedData.selectedStudents) {
				const selectedStudentsIds = createAssignmentExtractedData.selectedStudents.map(({id}) => id);

				this.selectedStudents.next(studentInfos.filter(({id}) => selectedStudentsIds.includes(id)));
			}
		}
	}

	public save(data: SaveRequestParameters): Observable<SaveResponse> {
		if (this.assignmentID) {
			return this.updateAssignment(data)
				.pipe(
					tap(() => {
						if (isEmpty(data.testIDs) || isEmpty(data.studentIDs)) {
							return;
						}

						if (
							(isEmpty(this.initEditResponse?.assignment.tests) ||
								isEmpty(this.initEditResponse?.assignment.students)) &&
							this.assignmentID
						) {
							this.trackNotDraftAssignmentEvent(this.assignmentID);
						}
					}),
				)
				.asObservable();
		}

		return this.createAssignment(data);
	}
}
