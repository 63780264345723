import type {SVGProps} from 'react';

export function GridTwelve(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='25' height='24'
			viewBox='0 0 25 24'
			fill='none'
			{...props}
		>
			<path
				d='M8.58331 6.75C8.58331 6.33579 8.9191 6 9.33331 6H10.8333C11.2475 6 11.5833 6.33579 11.5833 6.75V8.25C11.5833 8.66421 11.2475 9 10.8333 9H9.33331C8.9191 9 8.58331 8.66421 8.58331 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M13.0833 6.75C13.0833 6.33579 13.4191 6 13.8333 6H15.3333C15.7475 6 16.0833 6.33579 16.0833 6.75V8.25C16.0833 8.66421 15.7475 9 15.3333 9H13.8333C13.4191 9 13.0833 8.66421 13.0833 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M17.5833 6.75C17.5833 6.33579 17.9191 6 18.3333 6H19.8333C20.2475 6 20.5833 6.33579 20.5833 6.75V8.25C20.5833 8.66421 20.2475 9 19.8333 9H18.3333C17.9191 9 17.5833 8.66421 17.5833 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M8.58331 11.25C8.58331 10.8358 8.9191 10.5 9.33331 10.5H10.8333C11.2475 10.5 11.5833 10.8358 11.5833 11.25V12.75C11.5833 13.1642 11.2475 13.5 10.8333 13.5H9.33331C8.9191 13.5 8.58331 13.1642 8.58331 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M13.0833 11.25C13.0833 10.8358 13.4191 10.5 13.8333 10.5H15.3333C15.7475 10.5 16.0833 10.8358 16.0833 11.25V12.75C16.0833 13.1642 15.7475 13.5 15.3333 13.5H13.8333C13.4191 13.5 13.0833 13.1642 13.0833 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M17.5833 11.25C17.5833 10.8358 17.9191 10.5 18.3333 10.5H19.8333C20.2475 10.5 20.5833 10.8358 20.5833 11.25V12.75C20.5833 13.1642 20.2475 13.5 19.8333 13.5H18.3333C17.9191 13.5 17.5833 13.1642 17.5833 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M8.58331 15.75C8.58331 15.3358 8.9191 15 9.33331 15H10.8333C11.2475 15 11.5833 15.3358 11.5833 15.75V17.25C11.5833 17.6642 11.2475 18 10.8333 18H9.33331C8.9191 18 8.58331 17.6642 8.58331 17.25V15.75Z'
				fill='#333333'
			/>
			<path
				d='M4.08331 6.75C4.08331 6.33579 4.4191 6 4.83331 6H6.33331C6.74753 6 7.08331 6.33579 7.08331 6.75V8.25C7.08331 8.66421 6.74753 9 6.33331 9H4.83331C4.4191 9 4.08331 8.66421 4.08331 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M4.08331 11.25C4.08331 10.8358 4.4191 10.5 4.83331 10.5H6.33331C6.74753 10.5 7.08331 10.8358 7.08331 11.25V12.75C7.08331 13.1642 6.74753 13.5 6.33331 13.5H4.83331C4.4191 13.5 4.08331 13.1642 4.08331 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M4.08331 15.75C4.08331 15.3358 4.4191 15 4.83331 15H6.33331C6.74753 15 7.08331 15.3358 7.08331 15.75V17.25C7.08331 17.6642 6.74753 18 6.33331 18H4.83331C4.4191 18 4.08331 17.6642 4.08331 17.25V15.75Z'
				fill='#333333'
			/>
			<path
				d='M13.0833 15.75C13.0833 15.3358 13.4191 15 13.8333 15H15.3333C15.7475 15 16.0833 15.3358 16.0833 15.75V17.25C16.0833 17.6642 15.7475 18 15.3333 18H13.8333C13.4191 18 13.0833 17.6642 13.0833 17.25V15.75Z'
				fill='#333333'
			/>
			<path
				d='M17.5833 15.75C17.5833 15.3358 17.9191 15 18.3333 15H19.8333C20.2475 15 20.5833 15.3358 20.5833 15.75V17.25C20.5833 17.6642 20.2475 18 19.8333 18H18.3333C17.9191 18 17.5833 17.6642 17.5833 17.25V15.75Z'
				fill='#333333'
			/>
		</svg>
	);
}
