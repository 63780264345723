import React, {ReactNode} from 'react';


export class RightPanelBoxItem extends React.PureComponent<{ children?: ReactNode, }> {
	render() {
		return <div className='right-panel-box-item'>
			{this.props.children}
		</div>;
	}
}
