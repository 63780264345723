import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import SpecialistActivityReportIcon
	from 'pages/home/components/report-button/buttons/icons/specialist-activity-report-icon';

const SpecialistActivityReportModal = lazyComponent(() => import('modules/reports/specialist-activity'));

class Props {
	noStudents: boolean;
	groupOfSpecialistID: number;
}

class State {
	opened: boolean = false;
}

export default class SpecialistActivityButton extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

	public render() {
		return <>
			<NoStudentProtectedButton title='Specialist Activity'
			                          icon={<SpecialistActivityReportIcon/>}
			                          hoverTitle='Details specialist usage of their ESGI accounts'
			                          onClicked={() => this.clickHandler()}
			                          noStudents={this.props.noStudents}
			                          linkClassName='specialist-activity-report-link'/>
			{this.renderReport()}
		</>;
	}

	public renderReport() {
		if (this.state.opened) {
			return <Suspense fallback={<div/>}>
				<SpecialistActivityReportModal
					groupOfSpecialistsID={this.props.groupOfSpecialistID}
					onCloseClicked={() => this.setState({opened: false})}/>
			</Suspense>;
		}
	}

	private clickHandler = () => {
		SsoTracker.trackEvent({
			trackingEvent: 'SpecialistActivityReport',
		});

		this.setState({opened: true});
	};
}
