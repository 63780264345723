import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {renderReactComponent} from '@esgillc/ui-kit/utils';
import {LoginResponse} from '../types';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December',
];

type AlertProps = {
	onClose: () => void;
	expirationDate: string;
};

function ExpirationAlert({onClose, expirationDate}: AlertProps) {
	const manager = useModal();
	const close = useCloseModal(manager, onClose);
	return <Alert modalManagerRef={manager}>
		<Alert.Header>
			Your account is expired
		</Alert.Header>
		<Alert.Body>
			<div className='district-expired-alert'>
				The expiration date for this account is:&nbsp;<span style={{fontWeight: 'bold'}}>{expirationDate}</span>.
				<br/>
				Please contact the decision-maker in your school or district regarding renewal, or you can&nbsp;
				<a href='//support.esgisoftware.com/anonymous_requests/new'>Contact Us</a> if you have any questions.
				<br/><br/>
				Purchase Order Information can be found <a href='//www.esgisoftware.com/purchase-renew-faq'>here</a>.
			</div>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Contained onClick={close}>OK</Buttons.Contained>
		</Alert.Footer>
	</Alert>;
}

const formatDate = (date: Date) => `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;

export function ExpirationHandler(value: LoginResponse){
	const responseDate = new Date(value.validation.message);
	let expirationDate: string;
	if (responseDate.getFullYear() > 1970) {
		expirationDate = formatDate(responseDate);
	} else {
		expirationDate = 'not set';
	}

	return renderReactComponent((resolve) => {
		return <ExpirationAlert onClose={resolve} expirationDate={expirationDate}/>;
	});
}
