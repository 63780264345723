import {Buttons} from '@esgillc/ui-kit/button';
import {CaretDownIcon} from '@esgillc/ui-kit/icons';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {useCallback, useState} from 'react';
import {AssignmentStatus} from '../../../../../../typings/assignment';
import {AssignmentModel} from '../../../../types/types';
import {Actions, ActionsProps} from '../../types';
import styles from './styles.module.less';

type Props = {
	assignment: AssignmentModel;
} & ActionsProps;

const actionsMap = {
	[AssignmentStatus.NotStarted]: [Actions.Post, Actions.Edit, Actions.Delete],
	[AssignmentStatus.Published]: [Actions.Complete],
	[AssignmentStatus.Completed]: [Actions.Delete],
	[AssignmentStatus.Draft]: [Actions.Edit, Actions.Delete],
};

export function AssignmentActions({assignment, ...actionsProps}: Props) {
	const [open, setOpen] = useState(false);
	const actions = actionsMap[assignment.state];

	const getActionCallback = useCallback((action: Actions) => {
		switch (action) {
			case Actions.Complete: return actionsProps.onComplete;
			case Actions.Delete: return actionsProps.onDelete;
			case Actions.Edit: return actionsProps.onEdit;
			case Actions.Post: return actionsProps.onPost;
		}
	}, [actionsProps]);

	const close = useCallback(() => setOpen(false), [setOpen]);

	return (
		<div className={styles.container}>
			<Buttons.Outlined
				className={styles.button}
				onClick={() => setOpen(!open)}
			>
				actions
				<CaretDownIcon
					className={styles.arrowIcon}
					direction={open ? 'down' : 'up'}
				/>
				<Menu
					className={styles.menu}
					show={open}
					onEscPressed={close}
					onClickOutside={close}
				>
					{actions.map((action, index) => (
						<MenuItem
							key={index}
							className={styles.item}
							onSelect={getActionCallback(action)}
						>
							{action}
						</MenuItem>
					))}
				</Menu>
			</Buttons.Outlined>
		</div>
	);
}
