import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {StudentSectionMode} from '../../types';
import {EditableList} from './components/editable-list';
import {useTeacherPageContext} from '../../../../context/teacher-page-context';
import {Class} from '@esgi/main/libs/store';

type Props = {
	sectionMode: StudentSectionMode;
	canAddStudent: boolean;
	setViewMode: VoidFunction;
	selectedClassId: Class['id'] | null;
};

export function StudentsContent({sectionMode, canAddStudent, setViewMode, selectedClassId}: Props) {
	const {openAddStudentViaProfile} = useTeacherPageContext();

	const {students} = StudentsPanel.Tabs.Students.useStudentsContext();

	const {
		selected: {studentId: selectedStudentId},
		selectStudent,
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	if (sectionMode === StudentSectionMode.Edit) {
		return <EditableList setViewMode={setViewMode} students={students} />;
	}

	return (
		<StudentsPanel.Tabs.TabContent
			withBottomSectionButton={!students.length}
			buttonText='Add a Student'
			isBottomSectionButtonDisabled={!canAddStudent}
			onBottomButtonClick={() =>
				openAddStudentViaProfile({classId: String(selectedClassId), studentId: String(selectedStudentId)})
			}
		>
			{students.length ? (
				<StudentsPanel.Lists.SelectableList.Single
					items={students}
					onItemClick={(studentId) => selectStudent(studentId)}
					value={selectedStudentId}
				>
					{({item: {firstName, lastName}}) => (
						<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
					)}
				</StudentsPanel.Lists.SelectableList.Single>
			) : (
				<StudentsPanel.Tabs.EmptyTabContent
					size='full'
					templateText='No students to display'
					dataCy='students-panel-empty-content'
				/>
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
