import {useCallback, useEffect, useState, useRef} from 'react';
import {TestScopeTabId, TestTypeTabId} from '../components/tabs/types';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {isUndefined} from 'underscore';
import {DataService} from '../service/service';
import {useSorting} from '../components/table/hooks/use-sorting';
import {SubjectLevel} from '@esgi/main/libs/store';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {Subscription} from 'rxjs';
import {pageSize} from '../constants';

type Props<T> = {
	service: DataService,
	selfAssess?: boolean,
	subject: T,
}

export function useDrawerState<T extends {id: number, type: SubjectType, name: string, level: SubjectLevel}>({service, selfAssess, subject}: Props<T>) {
	const osRef = useRef<OverlayScrollbarsComponentRef>(null);
	const [onlySelfAssess, setOnlySelfAssess] = useState(selfAssess);
	const [keyword, setKeyword] = useState('');
	const [testType, setTestType] = useState(selfAssess ? TestTypeTabId.YN : TestTypeTabId.All);
	const [scope, setScope] = useState(TestScopeTabId.All);
	const [contentAreaID, setContentAreaID] = useState('0');
	const [sorting, setSorting] = useSorting();
	const initRequestSubscription = useRef<Subscription | null>(null);

	const toggleSelfAssessChecked = useCallback(() => setOnlySelfAssess((prev) => !prev), []);

	const onTestTypeChange = useCallback((type: TestTypeTabId) => {
		if (!service.loaded$) {
			return;
		}

		if (type) {
			setTestType(type);
		} else {
			setTestType(TestTypeTabId.All);
		}
	}, [service.loaded$]);

	const onScopeChange = useCallback((scope: TestScopeTabId) => {
		if (!service.loaded$) {
			return;
		}

		if (scope) {
			setScope(scope);
		} else {
			setScope(TestScopeTabId.All);
		}
	}, [service.loaded$]);

	const fetchData = useCallback((pageIndex?: number, itemsPerPage: number = pageSize) => {
		initRequestSubscription.current?.unsubscribe();

		initRequestSubscription.current = service.fetchData({
			keyword,
			contentAreaID,
			testType,
			sorting,
			scope,
			onlySelfAssess,
			subjectID: subject.id,
			subjectType: subject.type,
			pageIndex,
			itemsPerPage,
		});
	}, [contentAreaID, keyword, onlySelfAssess, scope, sorting, subject, testType]);

	useEffect(() => {
		fetchData(0);

		const osInstance = osRef.current?.osInstance();
		const elements = osInstance?.elements();
		if (!isUndefined(elements)) {
			const {viewport} = elements;
			viewport.scrollTo({top: 0});
		}

		return () => initRequestSubscription.current?.unsubscribe();
	}, [fetchData]);

	return {
		osRef,
		contentAreaID,
		setContentAreaID,
		testType,
		onTestTypeChange,
		scope,
		onScopeChange,
		onlySelfAssess,
		toggleSelfAssessChecked,
		keyword,
		setKeyword,
		sorting,
		setSorting,
		fetchData,
	};
}
