import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {ContentAreaItem, FontItem, GradeLevel, QuestionItem, TopicItem} from './types';
import {maxLengths} from './constants';

export type AutoTestCreatorFormType = FormGroup<{
	testName: FormControl<string>,
	description: FormControl<string>,
	directions: FormControl<string>,
	shareTest: FormControl<boolean>
	font: FormControl<FontItem[]>,
	topic: FormControl<TopicItem[]>,
	grade: FormControl<GradeLevel[]>,
	contentArea: FormControl<ContentAreaItem[]>,
	hexColor: FormControl<string>,
	questionAdder: FormControl<string>,
	questions: FormControl<QuestionItem[]>,
	invalidQuestionIDs: FormControl<string[]>,
},
	ControlValue<{
		testName: FormControl<string>,
		description: FormControl<string>,
		directions: FormControl<string>,
		shareTest: FormControl<boolean>
		font: FormControl<FontItem[]>,
		topic: FormControl<TopicItem[]>,
		grade: FormControl<GradeLevel[]>,
		contentArea: FormControl<ContentAreaItem[]>,
		hexColor: FormControl<string>,
		questionAdder: FormControl<string>,
		questions: FormControl<QuestionItem[]>,
		invalidQuestionIDs: FormControl<string[]>,
	}>>

export const createAutoTestCreatorForm = ():
	AutoTestCreatorFormType => new FormGroup({
	testName: new FormControl('', {validators: [Validators.required()]}),
	description: new FormControl('', {validators: [Validators.required(), Validators.length.max(maxLengths.description)]}),
	directions: new FormControl(''),
	shareTest: new FormControl(false),
	font: new FormControl([]),
	topic: new FormControl([]),
	grade: new FormControl([]),
	contentArea: new FormControl([]),
	hexColor: new FormControl(''),
	questionAdder: new FormControl('', {validators: [Validators.required(), Validators.length.max(maxLengths.question)]}),
	questions: new FormControl([]),
	invalidQuestionIDs: new FormControl<string[]>([]),
});