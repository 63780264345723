import {TableColumn, TableRow} from '@esgillc/ui-kit/table';
import {StudentCredentialsService} from '../services/student-credentials-service';
import {FilterService} from '../services/filter-service';
import {Student} from '../types/types';
import styles from '../styles.module.less';
import {TableCell} from './table-cell';
import {useCallback, useState} from 'react';
import {Icon} from '../kit';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {isNull} from 'underscore';

type Props<Filter extends FilterService, Service extends StudentCredentialsService<Filter>> = {
	service: Service;
	student: Student;
	isPasswordsHidden: boolean;
	isRowSelected?: boolean;
};

export function StudentTableRow<Filter extends FilterService, Service extends StudentCredentialsService<Filter>>(
	props: Props<Filter, Service>,
) {
	const {id, name, userName, password} = props.student;

	const [warningTooltipMessage, setWarningTooltipMessage] = useState<string | null>(null);

	const checkStudentToOtherMembers = useCallback(() => {
		if (!isNull(warningTooltipMessage)) {
			return;
		}

		if (!userName && !password) {
			setWarningTooltipMessage('');
			return;
		}

		props.service.checkStudentToOtherMembers(id).subscribe((value) => {
			const hasLinks = Boolean(value.links.length);

			if (!hasLinks) {
				setWarningTooltipMessage('');
				return;
			}

			const teachersNames = value.links.join(', ');

			setWarningTooltipMessage(
				`${name} belongs to ${teachersNames} class/group. Please reach out to let them know of these modifications.`,
			);
		});
	}, [props.service, warningTooltipMessage, userName]);

	const updateUsername = useCallback(
		(value: string) => {
			props.service.saveUsername(id, value);
			props.service.filterService.updateTableData();

			checkStudentToOtherMembers();
		},
		[id, props.service, checkStudentToOtherMembers],
	);

	const updatePassword = useCallback(
		(value: string) => {
			props.service.saveUserPassword(id, value);
			props.service.filterService.updateTableData();

			checkStudentToOtherMembers();
		},
		[id, props.service, checkStudentToOtherMembers],
	);

	return (
		<TableRow key={id} className={styles.tableRow}>
			<TableColumn className={styles.nameColumn}>
				<TableCell.Student studentId={id} studentName={name} />
			</TableColumn>
			<TableColumn className={styles.usernameColumn}>
				<TableCell.Input
					value={userName}
					getValidateMessage={(value) => props.service.validateUsername(value, id)}
					placeholder={'i.e. ' + name.replace(' ', '.')}
					updateUserCredentials={updateUsername}
					focused={Boolean(props.isRowSelected && !userName)}
				/>
			</TableColumn>
			<TableColumn className={styles.passwordColumn}>
				<TableCell.Input
					value={password}
					placeholder='i.e. 1234'
					hidden={props.isPasswordsHidden && !!password}
					getValidateMessage={(value) => props.service.validatePassword(value)}
					updateUserCredentials={updatePassword}
					focused={Boolean(props.isRowSelected && !!userName && !password)}
				/>
			</TableColumn>
			<TableColumn className={styles.warningColumn}>
				{warningTooltipMessage ? (
					<OnHoverTooltip message={warningTooltipMessage}>
						<div>
							<Icon.WarningTriangleIcon />
						</div>
					</OnHoverTooltip>
				) : null}
			</TableColumn>
		</TableRow>
	);
}
