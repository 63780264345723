import {BehaviorSubject, combineLatest, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {SubjectTab} from '@esgi/main/libs/store';
import {userStorage} from '@esgi/core/authentication';
import {StudentWithUnit} from '@esgi/main/kits/activities';
import {V2ActivitiesBingoController} from '@esgi/contracts/esgi';
import {BingoTestSettings} from '../model';
import {TestModel, QuestionModel} from '../types';
import {StudentService} from './student-service';
import {DownloadService} from './download-service';
import {TestSettingsService} from './test-settings-service';

export class BingoService extends BaseService {
	public readonly selectedStudents$ = new BehaviorSubject<StudentWithUnit[]>([]);
	public readonly selectedSubject$ = new BehaviorSubject<SubjectTab | null>(null);
	public readonly selectedTests$ = new BehaviorSubject<TestModel[]>([]);

	public readonly tests$ = new BehaviorSubject<TestModel[]>([]);
	public readonly questions$ = new BehaviorSubject<QuestionModel[]>([]);

	public readonly isTestsLoaded$ = new BehaviorSubject(false);
	public readonly isTestsLoading$ = new BehaviorSubject(false);

	private readonly currentUser = userStorage.get();
	private readonly controller = new V2ActivitiesBingoController();

	constructor(
		private studentService: StudentService,
		private testSettingsService: TestSettingsService,
		private downloadService: DownloadService,
	) {
		super();

		this.completeOnDestroy(
			combineLatest([this.selectedSubject$, this.selectedStudents$]),
		).pipe(tap(([subject, selectedStudents]) => {
			this.handleGetTests(subject, selectedStudents);
		})).subscribe();

		this.completeOnDestroy(
			this.selectedTests$,
		).pipe(tap((selectedTests) => {
			this.handleGetQuestions(selectedTests);
		})).subscribe();
	}

	
	public get previewURL$ () {
		return this.downloadService.previewURL$;
	}

	public get studentServiceData() {
		return {
			classes$: this.studentService.classes$,
			groups$: this.studentService.groups$,
			students$: this.studentService.students$,
		};
	}

	public get testSettingsServiceData() {
		return {
			testSettings$: this.testSettingsService.testSettings$,
		};
	}

	public get downloadServiceData() {
		return {
			isBusy$: this.downloadService.isBusy$,
		};
	}

	public setSelectedStudentList(studentList: StudentWithUnit[]) {
		this.selectedStudents$.next(studentList);
	}

	public setSelectedSubject(subject: SubjectTab) {
		this.selectedSubject$.next(subject);
	}

	public setSelectedTests(testIDs: string[]) {
		this.selectedTests$.next(testIDs.map((item) => this.getTestById(item)));
	}

	public getTestById(testID: string) {
		return this.tests$.value.find((item) => String(item.id) === testID);
	}

	public setTestSettingsData(field: keyof BingoTestSettings, value: BingoTestSettings[typeof field]) {
		this.testSettingsService.setTestSettings(field, value);
	}

	public createGame(preview?: boolean) {
		this.downloadService.handleDownload({
			tests: this.selectedTests$.value,
			questions: this.questions$.value,
			students: this.selectedStudents$.value,
			settings: this.testSettingsService.testSettings$.value,
			subject: this.selectedSubject$.value,
		}, preview);
	}

	

	public clearPreview() {
		this.downloadService.clearPreviewData();
	}

	public override dispose() {
		this.controller.dispose();
	}

	private handleGetTests(selectedSubject: SubjectTab, selectedStudents: StudentWithUnit[]) {
		if (!selectedSubject || selectedStudents.length === 0) {
			return;
		}

		this.isTestsLoaded$.next(false);
		this.isTestsLoading$.next(true);

		const studentIDs = selectedStudents.map((item) => item.studentId);
		this.controller.tests(
			{
				subjectID: selectedSubject.id,
				subjectType: selectedSubject.type,
				globalSchoolYearID: this.currentUser.globalSchoolYearID,
				studentIDs,
			}
		).subscribe({
			next: (value) => {
				this.tests$.next(value.tests);
			},
			complete: () => {
				this.isTestsLoaded$.next(true);
				this.isTestsLoading$.next(false);
			},
		});
	}

	private handleGetQuestions(selectedTests: TestModel[]) {
		if (selectedTests.length === 0) {
			return;
		}

		const testIDs = selectedTests.map((item) => item.id);
		this.controller.questions({
			testIDs,
		}).subscribe({
			next: (value) => {
				this.questions$.next(value.questions);
			},
		});
	}
}