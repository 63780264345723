import './styles.less';
import {SuggestedGradeLevel} from 'api/entities/suggested-grade-level';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import {TestPreview} from 'modules/assessments';
import moment from 'moment';
import React, {Suspense} from 'react';
import {SubjectType, TestType} from '@esgi/core/enums';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {lazyComponent} from '@esgi/core/react';
import {userStorage} from '@esgi/core/authentication';
import {QuestionModel} from '../../services/search-service';
import {SubjectItem} from '../../services/subjects-service';
import {AddTestToSubject} from '../add-tests-to-subject';
import QuestionSlider from '../question-slider/question-slider';
import {CardDetail} from './api';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));

class State {

	constructor(testId: number) {
		this.testId = testId;
	}

	testId: number;
	loaded: boolean = false;
	contentArea: string;
	stateStandard: string;
	gradeLevels: number[] = [];
	type: TestType;
	description: string;
	author: string;
	createDate: string;
	name: string;
	numberOfQuestions: number;
	isWhiteBackground: boolean;

	addTestToSubjectOpened: boolean = false;
	questionSliderItems: QuestionModel[] = [];
	imageQuestionID?: number;
	testDetailsOpened: boolean;
	testPreviewOpened: boolean;
}

class Props {
	testID: number;
	close: () => any;
	subjects: SubjectItem[];
}

export class Subject {
	id: number;
	name: string;
	type: SubjectType;
}

export class DetailCard extends React.Component<Props, State> {
	private currentUser = userStorage.get();

	constructor(props, context) {
		super(props, context);
		this.state = new State(props.testID);
	}


	componentDidMount(): void {
		CardDetail(this.state.testId)
			.subscribe((r) => {
				this.setState(
					{
						loaded: true,
						contentArea: r.contentArea,
						description: r.description,
						gradeLevels: r.gradeLevels,
						author: r.author,
						name: r.name,
						numberOfQuestions: r.questions.length,
						questionSliderItems: r.questions,
						imageQuestionID: r.imageQuestionID,
						createDate: r.createDate,
						stateStandard: r.stateStandard,
						type: TestType[r.type],
						isWhiteBackground: r.isWhiteBackground,
					});
			});
	}

	get gradeLevelsString() {
		if (!this.state.gradeLevels.length) {
			return 'N/A';
		}

		return this.state.gradeLevels.map(g => SuggestedGradeLevel[g] as SuggestedGradeLevel)
			.filter(g => !!g)
			.map(s => s.shortName)
			.reduce((a, b) => a + ', ' + b);
	}


	render() {
		return <Modal className='white-header detail-card-modal' loading={!this.state.loaded}>
			<ModalHeader
				close={{click: () => this.props.close()}}>
				<h3>
					<a href='#' className='name' onClick={() => this.openTestDetails()}>
						{this.state.name}
					</a>
					{this.state.testDetailsOpened &&
					<Suspense fallback={<div/>}>
						<TestDetails
							testID={this.state.testId}
							firstName={this.currentUser.firstName}
							lastName={this.currentUser.lastName}
							userID={this.currentUser.userID}
							subjectsForModal={this.props.subjects}
							close={() => this.setState({testDetailsOpened: false})}
						/>
					</Suspense>
					}
				</h3>
			</ModalHeader>
			<ModalBody>
				<div className='card-detail'>
					<div className='slider-wrapper'>
						<QuestionSlider questions={this.state.questionSliderItems}
						                startImageID={this.state.imageQuestionID}
						                testId={this.state.testId}
						                isWhiteBackground={this.state.isWhiteBackground}
						/>
					</div>
					<div className='attributes'>
						<div className='attribute'>
							<label>Content:</label>
							<div>{this.state.contentArea}</div>
						</div>
						<div className='attribute'>
							<label>Author:</label>
							<div>{this.state.author}</div>
						</div>
						<div className='attribute'>
							<label>Grade:</label>
							<div>{this.gradeLevelsString}</div>
						</div>
						<div className='attribute'>
							<label>Standard:</label>
							<div>{this.state.stateStandard || 'N/A'}</div>
						</div>
						<div className='attribute'>
							<label>Questions:</label>
							<div>{this.state.numberOfQuestions}</div>
						</div>
						<div className='attribute'>
							<label>Date:</label>
							<div>{moment(this.state.createDate).format('MMMM DD, YYYY')}</div>
						</div>
						<div className='attribute'>
							<label>Test Type:</label>
							<div>{this.state.type === TestType.YN ? 'Y/N' : 'Single Score'}</div>
						</div>
						<div className='attribute'>
							<label>Description:</label>
							<div>{this.state.description}</div>
						</div>
					</div>
				</div>

			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => this.openAddTestToSubject()}
					title='Add Test'
				/>
				<Primary
					onClick={() => this.setState({testPreviewOpened: true})}
					title='Preview'
				/>
			</ModalFooter>
			{this.state.addTestToSubjectOpened &&
			<AddTestToSubject close={() => this.setState({addTestToSubjectOpened: false})} testId={this.state.testId}
			                  testName={this.state.name} mySubjects={this.props.subjects}/>}
			{this.state.testPreviewOpened && <TestPreview testID={this.state.testId} onClose={() => this.setState({testPreviewOpened: false})}/>}
		</Modal>;
	}

	private openAddTestToSubject() {
		this.setState({addTestToSubjectOpened: true});
	}

	private openTestDetails() {
		this.setState({testDetailsOpened: true});
	}
}
