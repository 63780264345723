import {useMemo, useCallback} from 'react';
import {SelectableList} from '@esgi/ui/layout';
import {Question} from '../../../../../../types';
import {SelectableLisItemContent} from '../list-item';
import {SelectableListRoot, SelectableListTrigger, SelectableListTriggerLabel, SelectableListItem, SelectableListTriggerContent, Collapse, TestColorIndicator, IconBefore} from './index.styled';
import {noop, isNull} from 'underscore';
import {TestContentArea} from '@esgi/main/kits/common';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

const getListValue = (value: string | number | null) => isNull(value)
	? ''
	: String(value);

interface Props {
	testID: number;
	toggleLabel: string;
	isListOpen: boolean;
	isListSelected: boolean;
	questions: Question[];
	selectedQuestionId: Question['questionID'][] | null;
	setSelectedQuestionId: (value: Question['questionID']) => void;
	onToggle: VoidFunction;
	onSelectAll: VoidFunction;
}

export function ExpandableSelectableListMultiple({
	isListOpen,
	isListSelected,
	toggleLabel,
	questions,
	selectedQuestionId,
	setSelectedQuestionId,
	onToggle,
	onSelectAll,
	testID,
}: Props) {
	const value = useMemo(
		() => (selectedQuestionId || []).map(getListValue),
		[selectedQuestionId],
	);

	const checked = useCallback(
		(id: string) => value.includes(id),
		[value],
	);

	return (
		<SelectableListRoot isListOpen={isListOpen} dataCy='test-item'>
			<SelectableList.GroupRoot
				type='multiple'
				value={value}
			>
				<SelectableListTrigger
					openList={isListOpen}
					onClick={questions.length ? onToggle : noop}
					selected={isListSelected}
					hovered={questions.length ? undefined : false}
					defaultCursor={!questions.length}
					withoutStyledButton
				>
					<IconBefore
						checked={isListSelected}
						onClick={(event) => {
							event.stopPropagation();
							onSelectAll();
						}}
						dataCy='select-all-words'
					/>
					<SelectableListTriggerContent>
						<TestColorIndicator testID={testID}/>
						<SelectableListTriggerLabel size='large' data-cy='test-name'>{toggleLabel}</SelectableListTriggerLabel>
					</SelectableListTriggerContent>
					<Collapse rotate={isListOpen}/>
				</SelectableListTrigger>
				<SelectableList.Group>
					{questions.map(({name, questionID: id}, index) => (
						<SelectableListItem
							key={id}
							value={String(id)}
							checked={checked(String(id))}
							onClick={() => setSelectedQuestionId(id)}
						>
							<Tooltip>
								<Tooltip.Trigger>
									<SelectableLisItemContent
										name={name}
										index={index + 1}
									/>
								</Tooltip.Trigger>
								<Tooltip.Content>
									<Text size='small'>
										{checked(String(id))
										? 'Deselect a question to remove from the template'
										:'Select a question to add to the template'}
									</Text>
								</Tooltip.Content>
							</Tooltip>

						</SelectableListItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
