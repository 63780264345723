import React, {useEffect, useRef, useState} from 'react';
import {Validators} from '@esgillc/ui-kit/form';
import {Buttons} from '@esgillc/ui-kit/button';
import {join, useStreamEffect} from '@esgillc/ui-kit/utils';
import {uniqueId} from 'underscore';
import {DragAndDrop} from 'shared/modules/drag-and-drop/component';
import {AutoTestCreatorFormType} from '../../form';
import {QuestionItem} from '../../types';
import {ClearAll} from './components/clear-all';
import {Randomize} from './components/randomize';
import {QuestionRow} from './components/question-row';
import {QuestionAdder} from './components/question-adder';
import {RandomizeIcon} from '../../icons/randomize-icon';
import styles from './styles.module.less';

interface Props {
	form: AutoTestCreatorFormType;
}

export function QuestionContainer({form}: Props) {

	const sortContainerRef = useRef(null);

	const [isRandomizeAlertOpen, setRandomizeAlertOpen] = useState(false);
	const [isClearAllAlertOpen, setClearAllAlertOpen] = useState(false);
	const [isAddButtonVisible, setAddButtonVisible] = useState(false);
	const [isRowsOptionsVisible, setShowRowsVisible] = useState(false);
	const [isModernizationOptionsVisible, setModernizationOptionsVisible] = useState(false);
	const [questions, setQuestions] = useState<QuestionItem[]>(form.controls.questions.value);

	useStreamEffect(form.controls.questionAdder.onChanged, (value => {
		setAddButtonVisible(Boolean(value.currState.value.length));
	}));

	useEffect(() => {
		setModernizationOptionsVisible(questions.length > 1);
		setShowRowsVisible(Boolean(questions.length));
		form.controls.questions.value = questions;
	}, [questions]);

	const paste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		const clipboardData = event.clipboardData || (window as any).clipboardData;
		const pastedData = clipboardData.getData('Text');
		if (pastedData) {
			const rows = pastedData.split('\n');
			if (rows.length > 1) {
				event.stopPropagation();
				event.preventDefault();
				const questions = rows.map(row => row.replace('\r', '').trim()).filter(Boolean);
				const newQuestions = questions.map((questionName) => ({name: questionName, id: uniqueId()}));
				setQuestions(prev => [...prev, ...newQuestions]);
				if (form.controls.questionAdder.validators.length) {
					form.controls.questionAdder.validators.shift();
				}
			}
		}
	};

	const onAddQuestion = () => {
		setQuestions((prevQuestions) => [...prevQuestions, {id: uniqueId(), name: form.controls.questionAdder.value}]);
		form.controls.questionAdder.value = '';
		scrollToTheBottom();
		if (form.controls.questionAdder.validators.length) {
			form.controls.questionAdder.validators.shift();
		}
	};

	const onChangeQuestion = (id: string, name: string, isValid: boolean) => {
		setQuestions((prevQuestions) => prevQuestions.map(q => q.id === id ? {...q, name} : q));
		form.controls.invalidQuestionIDs.value = isValid ? form.controls.invalidQuestionIDs.value.filter(id => id !== id) :
			[...form.controls.invalidQuestionIDs.value, id];
	};

	const onDeleteQuestion = id => {
		setQuestions((prevQuestions) => {
			const updatedQuestions = prevQuestions.filter(q => id !== q.id);
			if (!updatedQuestions.length) {
				form.controls.questionAdder.validators.push(Validators.required());
			}
			return updatedQuestions;
		});
	};

	const onRandomizeQuestions = () => setQuestions((prevQuestions) => [...prevQuestions].sort(() => Math.random() - 0.5));

	const onOrderChange = (elements: HTMLElement[]) => {
		const orderMap = elements.map(e => e.id);
		setQuestions(prevQuestions => [...prevQuestions].sort((a, b) => orderMap.indexOf(a.id) - orderMap.indexOf(b.id)));
	};

	const scrollToTheBottom = () => {
		if (sortContainerRef.current) {
			sortContainerRef.current.scroll({
				top: sortContainerRef.current.scrollHeight - sortContainerRef.current.offsetHeight,
				behavior: 'smooth',
			});
		}
	};

	return <div data-cy='question-container' className={styles.questionsContainer}>
		<div className={styles.topButtons}>
			<div>
				<div data-cy='randomize-button' onClick={() => setRandomizeAlertOpen(true)}
					 className={join(isModernizationOptionsVisible ? styles.randomizeButtonContainer : styles.hide)}>
					<RandomizeIcon/>
					<Buttons.Link className={styles.linkButton}>Randomize</Buttons.Link>
				</div>
			</div>
			<div data-cy='clear-all-button'
				 className={join(isRowsOptionsVisible ? styles.clearButtonContainer : styles.hide)}>
				<Buttons.Link className={styles.linkButton} onClick={() => setClearAllAlertOpen(true)}>Clear
					all</Buttons.Link>
			</div>
		</div>

		<QuestionAdder form={form} isShowAddButton={isAddButtonVisible} onAddQuestion={onAddQuestion} onPaste={paste}/>

		{isRowsOptionsVisible && <div data-cy='rows-header' className={styles.rowsHeader}>
			<span className={styles.order}>Order</span>
			<div className={styles.dragTitleContainer}>
				{isModernizationOptionsVisible && <span data-cy='drag-title' className={styles.drag}>Drag</span>}
			</div>
			<span className={styles.name}>Name</span>
		</div>}

		<DragAndDrop enabled={isModernizationOptionsVisible} itemSelector={'.question-row'}
					 placeholder='question-row placeholder' handle='.drag'
					 orderChanged={(elements) => onOrderChange(elements)}>

			<div ref={(r) => sortContainerRef.current = r}>
				{questions.map((q, index) => <QuestionRow key={q.id}
														  orderNumber={index}
														  id={q.id}
														  name={q.name}
														  isShowDrag={isModernizationOptionsVisible}
														  className={'question-row'}
														  onDelete={onDeleteQuestion}
														  onChange={(id, name, isValid) => onChangeQuestion(id, name, isValid)}
				/>)}
			</div>
		</DragAndDrop>

		{isClearAllAlertOpen && <ClearAll onClear={() => setQuestions([])}
										  onCancel={() => setClearAllAlertOpen(false)}
		/>}

		{isRandomizeAlertOpen && <Randomize questionsCount={form.controls.questions.value.length}
											onClick={onRandomizeQuestions}
											onCancel={() => setRandomizeAlertOpen(false)}
		/>}
	</div>;
}


