import {BaseService} from '@esgi/core/service';
import {V2TeachersUserSettingsController} from '@esgi/contracts/esgi';

export class TestResultsVerbiageService extends BaseService {
	private controller = new V2TeachersUserSettingsController();

	public save = ({correct, incorrect, state}: {correct: string | null; incorrect: string | null; state: boolean}) => {
		return this.controller.tabsTestResultVerbiageSave({
			correct,
			incorrect,
			state,
		});
	};

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}
