import React, {useEffect, useMemo, useState} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {useModal} from '@esgillc/ui-kit/modal';
import {SelectedInfo, TestSelector} from '../test-selector';
import {TestsSelectorSourceService} from './services/tests-selector-source-service';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {
	SettingsModal,
} from './components/settings-modal';
import {HierarchyInstance} from '../../hierarchy/core/models';
import {useReportValidator} from './hooks/use-report-validator';
import {LauncherOptions} from './models/models';

interface Props {
	changesCollector: ChangesCollector
	hierarchy: HierarchyInstance;
	options: LauncherOptions;
	onClose: () => void;
}


function StudentDetailLauncher({
	                               options, onClose,
	                               hierarchy, changesCollector,
}: Props) {
	const [info, setInfo] = useState<SelectedInfo | undefined>();
	const testSelectorService = useMemo(() => new TestsSelectorSourceService(hierarchy.snapshot), [hierarchy.snapshot]);
	const modalManagerRef = useModal();
	const reportIsValid = useReportValidator(options, hierarchy.snapshot, onClose);

	const iSDRSettings = useMemo(() => info && {
		classId: options.classId,
		groupId: options.groupId,
		studentId: options.studentId,
		globalSchoolYearID: options.globalSchoolYearId,
		subjectId: info.subject.id,
		subjectType: info.subject.subjectType,
		testIDs: info.tests.map(t => t.testID),
	}, [info, options]);

	function initReport(selectedInfo: SelectedInfo) {
		setInfo(selectedInfo);
		modalManagerRef.current?.open();
	}

	function closeHandler() {
		onClose();
	}

	useEffect(() => {
		return () => testSelectorService.destroy();
	}, []);

	return <>
		{reportIsValid && <TestSelector hierarchy={hierarchy.snapshot}
		                                title='Student Detail Report'
		                                testsSourceService={testSelectorService}
		                                subjectID={options.subjectId}
		                                subjectType={options.subjectType}
		                                includeAll={false}
		                                onSubjectChanged={(s) => {
			                                changesCollector.applyChanges({
				                                subjectTabID: s.id,
				                                subjectTabType: s.subjectType,
			                                });
		                                }}
		                                onRunReportClicked={initReport}
		                                onClose={closeHandler}
		/>
		} {info && <SettingsModal modalManagerRef={modalManagerRef}
		                          iSDRSettings={iSDRSettings} onClose={closeHandler} hierarchy={hierarchy}
		                          changesCollector={changesCollector}
	/>
		}
	</>;
}

export default StudentDetailLauncher;
