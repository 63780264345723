import {HttpClient} from '@esgi/api';
import {Subscription} from 'rxjs';

interface IOptions {
	trackingEvent: string,
	data?: any,
}

export class SsoTracker {
	private static readonly controllerName = 'tracking';

	public static trackEvent(options: IOptions): Subscription {
		return HttpClient.default.ESGIApi.get<void>(this.controllerName, 'track-event', {
			trackingEvent: options.trackingEvent,
			data: options.data ? JSON.stringify(options.data) : null,
		}).subscribe();
	}
}
