import {Box, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';


export const CarouselBox = styled(Box, {
	width: '100%',
	display: 'flex',
	overflowX: 'hidden',
	position: 'relative',
});

export const CarouselContent = styled(Box, {
	width: '100%',
	position: 'relative',
	overflow: 'hidden',
});

export const CarouselTracker = styled(FlexBox, {
	position: 'relative',
	overflow: 'hidden',
	scrollSnapType: 'x mandatory',
	variants: {
		withPaddings: {
			true: {
				scrollPadding: '66px',
				padding: '0 0 0 66px',
			},
			false: {
				scrollPadding: '24px',
				padding: '0 0 0 24px',
			},
		},
	},
});

export const LeftButton = styled(Button.Icon, {
	position: 'absolute',
	top: 55,
	left: 24,
	width: '32px',
	height: '32px',
	borderRadius: '8px',
	border: '1px solid $vivid',
	background: '$background',

	'&:hover:enabled': {
		borderColor: '$secondary92 !important',
		backgroundColor: '$secondaryBackground !important',
	},

	'> svg': {
		borderRadius: '8px',
	},
});
export const RightButton = styled(LeftButton, {
	position: 'absolute',
	right: 24,
	left: 'unset',

	'> svg': {
		transform: 'rotate(180deg)',
	},
});