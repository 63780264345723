import {useCallback} from 'react';
import {Test, TestListProps} from '../../types';
import {ExpandablePanel} from '@esgi/main/kits/activities';
import {Action} from './components/action';
import {ExpandableSelectableListMultiple} from './components/list';
import {Wrapper} from './index.styled';

export function TestList(props: TestListProps) {
	const {state, selectedQuestions, toggleQuestions, sort, onSort, onToggle, onSelectAll, onSelectTestAll} = props;
	const isListSelected = useCallback((testId: Test['testID']) => {
		const {questions = []} = state.find(({testID: id}) => id === testId) || {};
		return questions.length && questions.every(({questionID: id}) => selectedQuestions?.includes(id));
	}, [state, selectedQuestions]);

	return (
		<Wrapper>
			<Action
				sort={sort}
				onSort={onSort}
				onSelectAll={onSelectAll}
			/>
			<ExpandablePanel style={{height: 'calc(100% - 60px)'}} data-cy='tests-list'>
				{state.map(({testID: id, name, questions, isListOpen}) => (
					<ExpandableSelectableListMultiple
						key={id}
						toggleLabel={name}
						isListOpen={isListOpen}
						selectedQuestionId={selectedQuestions}
						questions={questions}
						setSelectedQuestionId={((value) => toggleQuestions([value]))}
						isListSelected={Boolean(isListSelected(id))}
						onToggle={() => onToggle(!isListOpen, id)}
						onSelectAll={() => toggleQuestions(onSelectTestAll(id, selectedQuestions))}
						testID={id}
					/>
				))}
			</ExpandablePanel>
		</Wrapper>
	);
}
