import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import React from 'react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {Loader} from '@esgi/deprecated/jquery';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	SharedProps,
} from '@esgi/deprecated/react';
import {Api, FolderInfo, ImageDetailResponse, TagModel} from '../../../../api';
import {Events, ItemType} from '../../../../models';
import {ChipsInput} from '../../../chips-input/chips-input';
import {TagSelect, State as TagSelectState} from '../../../tag-select/tag-select';
import {IGModal} from '../../modal';
import {State as NameFieldState, NameField} from './name-field';
import {State as AddFolderState, AddFolderModal} from '../../folder/add';
import {State as RemoveState, RemoveModal} from '../../remove/modal';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

export class State {
	data: ImageDetailResponse;
	showFolderDropdown: boolean = false;

	constructor(public imageID: number) {
	}

	imageNameField: NameFieldState = new NameFieldState();
	tags: TagModel[] = [];
	tagSelect: TagSelectState = new TagSelectState();

	folderID: number = 0;

	addFolder: AddFolderState = null;
	removeImage: RemoveState = null;

	touched: boolean = false;
	loaded: boolean = false;
}

export class Props extends SharedProps<State> {
	imageID?: number;
	tagClicked: (tag: TagModel) => any;
	close: () => any;
}

export class ImageDetailModal extends IGModal<State, Props> {
	private loader: Loader = null;

	componentDidMount(): void {
		super.componentDidMount();
		this.loader = new Loader($('.image-detail-modal .modal-content'));
		this.reloadData();
	}

	reloadData(): void {
		Api.ImageDetail(this.state.imageID).subscribe(data => {
			const imageName = new NameFieldState();
			imageName.value = data.imageData.name;
			this.setState({
				data: data,
				folderID: data.imageData.isStock ? null : data.imageData.folderID,
				tags: [...data.imageData.tags],
				loaded: true,
				imageNameField: imageName,
			});
		}, () => this.props.close());
	}

	get isTouched() {
		const nameTouched = this.state.imageNameField.value !== this.state.data.imageData.name;
		const parentFolderTouched = this.state.folderID !== this.state.data.imageData.folderID;
		const existedTags = this.state.tags.map(i => i.id).sort();
		const currentTags = this.state.data.imageData.tags.map(i => i.id).sort();
		const tagsTouched = JSON.stringify(existedTags) !== JSON.stringify(currentTags);
		return nameTouched || parentFolderTouched || tagsTouched;
	}

	componentWillUnmount(): void {
		super.componentWillUnmount();
		this.hideLoader();
	}

	showLoader = () => {
		this.loader.mask();
	}

	hideLoader = () => {
		this.loader.unmask();
	}

	toggleFolderDropdown = () => {
		this.setState({showFolderDropdown: !this.state.showFolderDropdown});
	}

	selectFolder = (folderID) => {
		this.setState({showFolderDropdown: false, folderID: folderID, touched: true});
	}

	get folderName() {
		let folderName = 'My Library';
		if (this.state.folderID > 0) {
			let folder = this.seekFolder(this.state.data.folderInfos, this.state.folderID);
			if (folder) {
				folderName = folder.name;
			}
		}
		return folderName;
	}

	seekFolder(folderInfos: FolderInfo[], folderID: number): FolderInfo {
		let folder: FolderInfo = null;

		for (let i = 0; i < folderInfos.length; i++) {
			let folderInfo = folderInfos[i];

			if (folderInfo.id === folderID) {
				folder = folderInfo;
				break;
			} else {
				folder = this.seekFolder(folderInfo.subFolders, folderID);
				if (folder) {
					break;
				}
			}
		}

		return folder;
	}

	folderAdded = (id: number) => {
		let newState = {...this.state};

		if (id) {
			newState.data.folderInfos = this.addFolder(id, this.state.addFolder.folderName.value, this.state.addFolder.locationSelect.selected.value);
			newState.folderID = id;
		}

		newState.addFolder = null;
		newState.showFolderDropdown = false;
		this.setState(newState);
	}

	addFolder = (id: number, name: string, parentFolderID: number): FolderInfo[] => {
		let folders = [...this.state.data.folderInfos];

		let folder = {id: id, name: name, subFolders: []} as FolderInfo;
		let parentFolder = this.seekFolder(folders, parentFolderID);
		if (parentFolder) {
			parentFolder.subFolders.push(folder);
			parentFolder.subFolders = parentFolder.subFolders.sort(this.sortByName);
		} else {
			folders.push(folder);
			folders = folders.sort(this.sortByName);
		}
		return folders;
	}

	sortByName(a: FolderInfo, b: FolderInfo) {
		return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
	}

	onTagCreated(t: TagModel) {
		const a = this.state.tags;
		a.push(t);
		this.setState({tags: a});
	}

	onTagDeleted(t: TagModel) {
		let a = this.state.tags;
		a = a.filter(i => i.name !== t.name);
		this.setState({tags: a});
	}

	removeClicked() {
		const parentFolder = this.state.data.folderInfos.find(i => i.id === this.state.data.imageData.folderID);
		const parentFolderName = parentFolder ? parentFolder.name : 'My Library';
		this.setState({removeImage: new RemoveState([this.state.imageID], ItemType.Image, this.state.data.imageData.name, parentFolderName)});
	}

	private renderIcon() {
		return this.state.showFolderDropdown
			? (<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 5.11133L5 -2.86102e-06L10 5.11133H0Z' fill='#828282'/>
			</svg>)
			: (<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 0L5 5.11133L10 0L0 0Z' fill='#828282'/>
			</svg>);
	}

	render() {
		return <Modal
			className={'white-header base-ig-modal image-detail-modal ' + (!this.state.loaded ? 'hidden' : 'visible')}
			animate={true}>
			<ModalHeader>
				<a className='close-btn' onClick={() => this.props.close()}><i className='fa fa-times'/></a>
			</ModalHeader>
			<ModalBody>
				<div className='content'>
					{this.state.data &&
					<>
						<div className='image-container'>
							<img src={this.state.data.imageData.url}/>
						</div>
						<div>
							<div className='top-panel'>
								<div className='image-name'>
									<NameField state={this.state.imageNameField}
									           onChange={(ch, cb) => this.setState({imageNameField: ch}, cb)}
									           editable={!this.state.data.imageData.isStock}/>
								</div>
								{!this.state.data.imageData.isStock && <div className='actions'>
									<OnHoverTooltip message={'Remove image'}>
										<div
											onClick={() => this.removeClicked()}>
											<svg width='18' height='18' viewBox='0 0 18 18' fill='none'
											     xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25H4.5V14.25ZM14.25 3H11.625L10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3Z'
													fill='#0088CC'/>
											</svg>
										</div>
									</OnHoverTooltip>
								</div>}
							</div>
							<div>
								<div className='folder-dropdown-container'>
									<label>{this.state.data.imageData.isStock === true ? 'Copy To:' : 'Move to:'}</label>
									<div className='form-control selected-folder'
									     onClick={() => this.toggleFolderDropdown()}
									     tabIndex={0}
									>
										<div>{this.folderName}</div>
										<div className='next-level'>
											{this.renderIcon()}
										</div>
									</div>
									<div
										className={'folder-dropdown' + (this.state.showFolderDropdown ? '' : ' hidden')}>
										<ul>
											<li><a href='#'
											       className={'level-0' + (!this.state.folderID ? ' selected' : '')}
											       onClick={() => this.selectFolder(0)}>My Library</a></li>
											{this.state.data.folderInfos.map((folderInfo) => this.renderFolderInfo(folderInfo, 1))}
											<li className='add-new-folder'>
												<a href='#'
												   onClick={() => this.setState({addFolder: new AddFolderState()})}>
                                                                <span
	                                                                className='plus-ico'>+</span><span>Add new folder</span>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div>
								{!this.state.data.imageData.isStock && <>
									<div className='tag-select-container'>
										<label>Add Tag:</label>
										<div>
											<TagSelect state={this.state.tagSelect}
											           onChange={(ch, cb) => this.setState({tagSelect: ch}, cb)}
											           existTags={this.state.tags}
											           tagCreated={(t) => this.onTagCreated(t)}
											/>
										</div>
									</div>
								</>}
							</div>
							<div>
								{(!!this.state.tags.length) &&
								<div className='tags-container'>
									<ChipsInput
										onChipClicked={e => this.props.tagClicked({id: e.id, name: e.text})}
										editable={!this.state.data.imageData.isStock}
										onChipDeleteClicked={e => this.onTagDeleted({
											id: e.id,
											name: e.text,
										})}
										items={this.state.tags.map(i => {
											return {id: i.id, text: i.name};
										})}/>
								</div>}
							</div>
						</div>
					</>
					}
				</div>
				{this.state.addFolder &&
				<AddFolderModal state={this.state.addFolder}
				                currentFolderID={this.state.folderID}
				                onChange={(ch) => this.setState({addFolder: ch})}
				                close={this.folderAdded}/>
				}
				{this.state.removeImage &&
				<RemoveModal state={this.state.removeImage}
				             onChange={(ch) => this.setState({removeImage: ch})}
				             close={(ids) => {
					             this.setState({removeImage: null});
					             if (!!ids && ids[0] === this.state.imageID) {
						             this.props.close();
					             }
				             }}/>
				}
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => this.props.close()}
					title='CANCEL'
					className='btn-bold'
				/>
				<Primary
					onClick={() => this.save()}
					title='SAVE'
					className='btn-bold'
					disabled={this.state.imageNameField && !this.state.imageNameField.valid}
				/>
			</ModalFooter>
		</Modal>;
	}

	renderFolderInfo(folderInfo: FolderInfo, level: number) {
		return <>
			<li key={folderInfo.id}><a href='#'
			                           className={'level-' + level + (this.state.folderID === folderInfo.id ? ' selected' : '')}
			                           onClick={() => this.selectFolder(folderInfo.id)}>{folderInfo.name}</a>
			</li>
			{folderInfo.subFolders.map((folderInfo) => this.renderFolderInfo(folderInfo, level + 1))}
		</>;
	}

	save = () => {
		if (!this.state.imageNameField.valid) {
			return;
		}
		if (this.state.data.imageData.isStock) {
			this.showLoader();

			Api.CopyImage(this.state.imageID, this.state.folderID).subscribe((r) => {
				this.hideLoader();
				showSnackbarNotification(`You've copied ${this.state.data.imageData.name} to ${this.folderName}`);
				this.props.close();
			});
		} else {
			if (this.isTouched) {
				this.showLoader();
				Api.EditImage(this.state.imageID, this.state.folderID, this.state.tags, this.state.imageNameField.value).subscribe(r => {
					if (this.state.folderID !== this.state.data.imageData.folderID) {
						this.dispatch(Events.ItemsMoved, {ids: [this.state.imageID]});
						showSnackbarNotification(`You've moved 1 image(s) to ${this.folderName}`);
					}
					this.hideLoader();
					this.dispatch(Events.ItemPropertyChanged, {});
					this.props.close();
				}, () => this.hideLoader());
			} else {
				this.props.close();
			}
		}
	}
}
