import {StudentSectionRoot} from './root';
import {
	ClassesList,
	StudentsContent,
	GroupsContent,
	MultiSelect,
	SingleSelect,
	GroupList,
	TabContent,
	ModeHeader,
	SelectableList,
} from './components';
import {ClassSelect} from './components/tabs/groups/components/class-select';
import {StudentsWithoutGroups} from './components/tabs/groups/components/students-without-groups';
import {MultiTabPanel} from './multi-tab-panel';
import {SelectableGroupList, IconOptions as ReexportIconOptions} from './components/lists/group-list/selectable';
import {PropsWithChildren} from 'react';
import {Box, FlexBox} from '@esgi/ui/layout';
import {ClassesDefaultList, DefaultGroupsList, StudentsDefaultList} from './pre-styled';

export * from './types';
export * from './types';

export {useModeContext, useTabsContext, useTabActivator} from './multi-tab-panel';
export {useStudentsPanelContext} from './context';
export {useSelectionContext} from './components/hooks';
export {useClassesContext} from './components/tabs/classess/context';
export {useGroupsContext} from './components/tabs/groups/context';
export {useStudentContext} from './components/tabs/students/context';

export {PreStyledGroupList} from './components';

export const TabsRoot = ({children}: PropsWithChildren) => (<Box css={{position: 'relative', width: '100%', height: '100%', overflow: 'hidden'}}>{children}</Box>);

export const StudentsPanel = {
	Root: StudentSectionRoot,
	Header: {
		Title: FlexBox,
		ModeHeader: ModeHeader,
		Tabs: MultiTabPanel.TabsBar,
	},
	Selection: {
		Single: SingleSelect,
		Multiple: MultiSelect,
	},
	TabsRoot: TabsRoot,
	TabContent: TabContent,
	Classes: ClassesList,
	Students: StudentsContent,
	Groups: {
		Root: GroupsContent,
		ClassSelect,
		StudentsWithoutGroups,
	},
	List: {
		Selectable: SelectableList,
		Group: GroupList,
		SelectableGroup: SelectableGroupList,
	},
};

export const PreStyledLists = {
	Classes: ClassesDefaultList,
	Groups: DefaultGroupsList,
	Students: StudentsDefaultList,
};

export * from './components/text';
export * from './components/other/empty-content';

export type IconOptions = ReexportIconOptions;