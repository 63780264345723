import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {YesNoSelectors} from '../../yes-no.selectors';

type Props = {
	studentName: string,
	testName: string,
	onConfirm: () => void,
	onCancel: () => void,
};

export function SessionStartedAlert(props: Props): JSX.Element {
	const modal = useModal();
	const confirm = useCloseModal(modal, props.onConfirm);
	const cancel = useCloseModal(modal, props.onCancel);

	return <Alert modalManagerRef={modal} data-cy={YesNoSelectors.sessionStartedAlert}>
		<Alert.Body>
			{props.studentName} is currently being tested on {props.testName} in our system, would you like to take over this session?
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={cancel} data-cy={YesNoSelectors.sessionStartedAlertAbortButton}>
				NO
			</Buttons.Text>
			<Buttons.Text onClick={confirm} data-cy={YesNoSelectors.sessionStartedAlertConfirmButton}>
				YES
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
