import React from 'react';

export default class ClearIcon extends React.PureComponent<{ onClick: () => void }> {
	public render() {
		return <svg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={this.props.onClick}>
			<path
				d='M10.5 1.0575L9.4425 0L5.25 4.1925L1.0575 0L0 1.0575L4.1925 5.25L0 9.4425L1.0575 10.5L5.25 6.3075L9.4425 10.5L10.5 9.4425L6.3075 5.25L10.5 1.0575Z'
				fill='#828282'/>
		</svg>;
	}
}
