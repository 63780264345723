import styles from './styles.module.less';

type IFramedFormProps = {
	pageContext: {
		formUrl: string;
	};
};

export default function IFramedForm({pageContext}: IFramedFormProps) {
	return (
		<div className={styles.formContainer}>
			<iframe
				src={pageContext.formUrl}
				style={{
					width: '650px',
					height: '100%',
					margin: 0,
					borderStyle: 'none',
				}}
			>
				Loading...
			</iframe>
		</div>
	);
}
