import {useState, useMemo} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {CloseIcon, Title} from '@esgillc/ui-kit/modal';
import {DownloadIcon, ChevronIcon} from '@esgillc/ui-kit/icons';
import {useBehaviorSubject, join} from '@esgillc/ui-kit/utils';
import {ReportModalHeader} from '@esgi/deprecated/ui-kit/report/modal';
import {IEPProgressReportService} from '../../services';
import {Loader} from '@esgillc/ui-kit/loader';
import styles from './styles.module.less';

interface Props {
	service: IEPProgressReportService;
	reportData: Record<string, any>;
	onBack?: () => void;
	onClose: () => void;
}

export const Header = (props: Props): JSX.Element => {
	const {service, reportData, onBack, onClose} = props;
	const data = useBehaviorSubject(service.reportData$);
	const [isDownloading, setIsDownloading] = useState(false);

	const hasDownload = useMemo(
		() => onBack && (data || []).length !== 0,
		[data, onBack],
	);

	const onDownload = () => {
		setIsDownloading(true);
		service.exportPdf(reportData, () => setIsDownloading(false));
	};

	return (
		<ReportModalHeader className={styles.modalHeader}>
			{onBack &&
				<Buttons.Icon
					className={styles.back}
					onClick={onBack}
				>
					<ChevronIcon/>
					Back
				</Buttons.Icon>
			}
			<Title className={join(styles.title, onBack ? styles.center : false)}>IEP Progress</Title>
			{hasDownload &&
				<Buttons.Icon
					className={styles.downloadButton}
					onClick={onDownload}
				>
					<DownloadIcon/>
					Download
				</Buttons.Icon>
			}
			<CloseIcon
				color='#fff'
				onClick={onClose}
			/>
			<Loader show={isDownloading}/>
		</ReportModalHeader>
	);
};
