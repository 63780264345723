import {EntityType, TestInfo, TrackDate} from '../../../types';
import {ItemAnalysisSection} from '../../../sections/item-analysis';
import {EmptyContent} from '../../../kit';
import {Container, EmptyDataContainer} from './index.styled';
import {OpenTestingStudentParams, StudentInfo, StudentSort} from '../../types';
import {SessionsSection} from '../../../sections/sessions';
import {BodyStats} from '../body-stats';
import {Dispatch, useMemo} from 'react';
import {TestHistorySection} from '../../../sections/test-history';

type Props = {
	isDataLoaded: boolean;
	testInfo: TestInfo | null;
	trackDates: TrackDate[];
	studentsInfo: StudentInfo[];
	studentSort: StudentSort;
	onOpenTestingStudent: Dispatch<OpenTestingStudentParams>;
	isTablet: boolean;
};

export function StudentsStats({
	isDataLoaded,
	testInfo,
	trackDates,
	studentsInfo,
	studentSort,
	onOpenTestingStudent,
	isTablet,
}: Props) {
	const hasSessions = useMemo(
		() => studentsInfo.some(({testSessionsInfo}) => testSessionsInfo.testSessions.length),
		[studentsInfo],
	);

	if (!isDataLoaded) {
		return (
			<EmptyDataContainer>
				<EmptyContent templateText='Loading...' />
			</EmptyDataContainer>
		);
	}

	if (!hasSessions) {
		return (
			<EmptyDataContainer>
				<EmptyContent templateText='No previous test results available for selected group/class' />
			</EmptyDataContainer>
		);
	}

	return (
		<Container>
			<BodyStats
				testType={testInfo?.testType}
				questionsCount={testInfo?.questions.length}
				totalPossible={testInfo?.totalPossible}
				students={studentsInfo}
				criteriaCount={testInfo?.rubricCriteria.length}
			/>
			<TestHistorySection
				entityType={EntityType.ClassGroup}
				trackDates={trackDates}
				students={studentsInfo}
				testInfo={testInfo}
				isTablet={isTablet}
			/>
			<ItemAnalysisSection
				entityType='class-group'
				studentsInfo={studentsInfo}
				testInfo={testInfo}
				isTablet={isTablet}
			/>
			<SessionsSection
				entityType='class-group'
				studentsInfo={studentsInfo}
				testInfo={testInfo}
				sortStudentsBy={studentSort}
				isTablet={isTablet}
				onOpenTestingStudent={onOpenTestingStudent}
			/>
		</Container>
	);
}
