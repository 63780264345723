import React from 'react';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';

interface Props {
	onCancelClicked: () => void;
	onDeleteFileClicked: () => void;
}

export function DeleteFileUpload({onDeleteFileClicked, onCancelClicked}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancelClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>Delete File Upload?</Title>
		</Modal.Header>
		<Modal.Body>
			<span data-cy={'delete-file-upload'} className={styles.text}>
				No students will be added to your account.
			</span>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Contained onClick={handleClose}>CANCEL</Buttons.Contained>
			<Buttons.Contained onClick={onDeleteFileClicked}>DELETE FILE</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
