import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ValidationStatus} from '@esgi/core/enums';
import {IValidationHandler, IValidator} from 'shared/modules/grade-scale/form-field/validation-handler/types';

export class ValidationHandlerResult {
	status: ValidationStatus;
	messages: string[];

	public get firstMessage() {
		return this.messages[0];
	}
}

export class ValidationHandler<T> implements IValidationHandler<T> {

	constructor(private validators: IValidator<T>[]) {
	}

	public handle(value: T): Observable<ValidationHandlerResult> {
		return forkJoin(this.validators.map(v => {
			const result = v.validate(value);
			if (result instanceof Observable){
				return result;
			} else {
				return of(result);
			}
		})).pipe(
			map(result => {
				let vhr = new ValidationHandlerResult();
				vhr.status = result.every(r => r.status === ValidationStatus.Valid) ? ValidationStatus.Valid : ValidationStatus.Invalid;
				vhr.messages = result.filter(m => m.status === ValidationStatus.Invalid).map(r => r.message);
				return vhr;
			}),
		);
	}
	
	static Create<T>(validators: IValidator<T>[]){
		return new ValidationHandler(validators);
	}
}