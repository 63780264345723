import {PropsWithChildren, useCallback} from 'react';
import {CentralPanelHeaderContainer} from './index.styled';
import {Close} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';
import {useNavigate} from 'react-router-dom';
import {routes} from '@esgi/main/libs/core';

export * from './index.styled';

type Props = PropsWithChildren;

export function CentralPanelHeader({children}: Props) {
	const navigate = useNavigate();

	const handleCloseAssignment = useCallback(() => {
		navigate(routes.teacher.assignments.assignmentsList, {
			replace: true,
		});
	}, [navigate]);

	return (
		<CentralPanelHeaderContainer>
			{children}
			<Button.Icon withBackgroundHover onClick={handleCloseAssignment} dataCy='close-button'>
				<Close />
			</Button.Icon>
		</CentralPanelHeaderContainer>
	);
}
