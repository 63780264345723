import {useCallback, useState} from 'react';
import {Container, ScoreButton} from './index.styled';

type Props = {
	maxScore: number;
	score: number;
	onClick: (value: number) => void;
};

export function ToggleScoreResult({maxScore, score, onClick}: Props) {
	const [hoveredButtonIndex, setHoveredButtonIndex] = useState(-1);

	const handleClick = useCallback(
		(value: number) => {
			if (score !== value) {
				onClick(value);
			}
		},
		[onClick, score],
	);

	return (
		<Container>
			{new Array(maxScore).fill(null).map((_, index) => (
				<ScoreButton
					active={hoveredButtonIndex === -1 && index + 1 <= score}
					hovered={index <= hoveredButtonIndex}
					onMouseEnter={() => setHoveredButtonIndex(index)}
					onMouseLeave={() => setHoveredButtonIndex(-1)}
					onClick={() => handleClick(index + 1)}
					key={index}
				/>
			))}
		</Container>
	);
}
