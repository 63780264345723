import {RefObject, useEffect, useMemo, useRef, useState} from 'react';
import {useSpring, config} from 'react-spring';
import useResizeObserver from '@react-hook/resize-observer';
import {useComposedRefs} from '@esgi/ui';
import {PanelAnimatedDiv} from './index.styled';
import {drawerWrapperWidth} from '../drawer-root/index.styled';

type Props = {
	panelRef: RefObject<HTMLDivElement>;
	isPanelOpen: boolean;
	panelWidth: number;
	setPanelWidth: React.Dispatch<React.SetStateAction<number>>;
};

export function PanelAnimatedBox({isPanelOpen, panelRef, panelWidth, setPanelWidth}: Props) {
	const [wasFirstAnimation, setWasFirstAnimation] = useState(false);
	const [panelIsHidden, setPanelIsHidden] = useState(true);

	useEffect(() => {
		// used to hide the panel on the first render
		if (!wasFirstAnimation && isPanelOpen) {
			setWasFirstAnimation(true);
		}
	}, [isPanelOpen, wasFirstAnimation]);

	const innerRef = useRef<HTMLDivElement>(null);
	const ref = useComposedRefs(panelRef, innerRef);

	useResizeObserver(innerRef, (entry) => setPanelWidth(entry.contentRect.width));

	const initialLeftPosition = useMemo(() => panelWidth - drawerWrapperWidth, [panelWidth]);

	const panelAnimatedStyles = useSpring({
		left: isPanelOpen ? 0 : initialLeftPosition,
		config: {
			...config.gentle,
			clamp: true,
		},
		onChange: ({value}) => {
			setPanelIsHidden(value.left === initialLeftPosition);
		},
	});

	return <PanelAnimatedDiv ref={ref} style={panelAnimatedStyles} hidden={panelIsHidden || !wasFirstAnimation} />;
}
