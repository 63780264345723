import {Text} from '@esgi/ui/typography';
import {styled} from '@esgi/ui/theme';
import {FlexBox} from '../flex-box';

export const Counter = styled(FlexBox, {
	width: 'min-content',
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
	backgroundColor: '$secondarySurface',
	color: '$secondary',
	userSelect: 'none',
	appearance: 'none',
	borderRadius: 4,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$secondaryMuted',

	[`& > ${Text}`]: {
		color: 'currentColor',
	},
	
	variants: {
		color: {
			blue: {
				color: '#28B1F6',
				borderColor: '#CDE7F4',
				backgroundColor: '#FBFDFE',
			},
		},
	},
});
