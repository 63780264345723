import React, {RefObject, useRef, useState} from 'react';
import {Popup} from '@esgillc/ui-kit/control';
import {join, useStreamEffect} from '@esgillc/ui-kit/utils';
import {List, ListItem} from '@esgillc/ui-kit/layout';
import DataService from '../../data-service';
import styles from '../../session-details.module.less';

interface Props {
	onDownloadClicked: () => void;
	onDownloadAllClicked: () => void;
	service: DataService;
}

export function DownloadButton ({onDownloadClicked, onDownloadAllClicked, service}: Props) {
	const menuDivRef: RefObject<HTMLDivElement> = useRef();
	const [sessionsCount, setSessionsCount] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const dropdownClickHandler = () => {
		if (sessionsCount === 1) {
			onDownloadClicked();
			return;
		}
		setIsOpen(!isOpen);
	};

	useStreamEffect(service.sessions, sessions =>
		setSessionsCount(sessions.filter(s => !s.isDeleted).length),
	);

	return (
		<div className={join(styles.downloadButton, !sessionsCount && styles.disabled)}
		     tabIndex={0}
		     onBlur={() => setIsOpen(false)}>
			<div data-cy='download-report' onClick={sessionsCount ? dropdownClickHandler : undefined}>
				<i className='fa fa-download'/>
				<span> Download</span>
			</div>
			<div ref={menuDivRef} className={styles.menu}>
				<Popup toggled={isOpen} targetRef={menuDivRef}>
					<List>
						<ListItem className={styles.menuItem} onClick={() => {
							setIsOpen(false);
							onDownloadClicked();
						}}>
							Current Session
						</ListItem>
						<ListItem className={styles.menuItem} onClick={() => {
							setIsOpen(false);
							onDownloadAllClicked();
						}}>
							All Sessions
						</ListItem>
					</List>
				</Popup>
			</div>
		</div>
	);
}
