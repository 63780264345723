import React from 'react';
import {IQuestionResponse} from '../api';
import {Checkbox, CheckboxOptions, CheckedMode} from '@esgi/deprecated/elements';
import styles from './styles.module.less';

class Props {
	questions: IQuestionResponse[];
	answerStateVerbiage: string;
	checked: (questions: IQuestionResponse[], checked: boolean) => void;
}

class State {
	checked: number[] = [];
}

export default class QuestionsSelector extends React.PureComponent<Props, State> {

	constructor(props?: Props) {
		super(props);

		this.state = new State();
	}

	private selectAllChecked(checked: boolean) {
		if (checked) {
			this.checkAll();
		} else {
			this.uncheckAll();
		}
	}

	private checkAll() {
		this.setState({checked: this.props.questions.map(q => q.questionID)});

		this.checkParent(true);
	}

	private uncheckAll() {
		this.setState({checked: []});

		this.checkParent(false);
	}

	private checked(questions: IQuestionResponse[], checked: boolean) {
		const questionIDs = questions.map(x => x.questionID);

		if (checked) {
			this.setState({checked: this.state.checked.concat(questionIDs)});
		} else {
			this.setState({checked: this.state.checked.filter(s => !questionIDs.includes(s))});
		}

		this.props.checked(questions, checked);
	}

	private checkParent(checked: boolean) {
		this.props.checked(this.props.questions, checked);
	}

	get questionsChecked() {
		if (this.state.checked.length === 0) {
			return CheckedMode.None;
		}

		if (this.state.checked.length === this.props.questions.length) {
			return CheckedMode.All;
		}

		if (this.state.checked.length > 0) {
			return CheckedMode.Partially;
		}
	}

	render() {
		return <div className={styles.questionsSelector}>
			<div className={styles.selectAllCheckbox}>
				<CheckboxOptions
					onClick={(checked) => this.selectAllChecked(checked)}
					checked={this.questionsChecked}
					disabled={this.props.questions.length === 0}
				/>
				<span>{this.props.answerStateVerbiage} ({this.props.questions.length})</span>
			</div>
			<ul>
				{this.props.questions.map(q => {
					return <li key={q.questionID}>
						<Checkbox
							id={q.questionID + ''}
							label={q.name}
							onClick={(e) => this.checked([q], e)}
							key={q.questionID}
							checked={this.state.checked.indexOf(q.questionID) > -1}
						/>
					</li>;
				})}
			</ul>
		</div>;
	}
}
