import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';

type Props = {
	title: string;
	additionalText?: string;
	titleColor?: VariantProps<typeof Text>['color'];
};

export function PanelHeaderTitle({title, additionalText, titleColor = 'neutral24'}: Props) {
	if (additionalText) {
		return (
			<GridBox flow='column' gap='3'>
				<Text size='large' bold color='neutral40'>
					{additionalText}
				</Text>
				<Text size='large' bold color={titleColor}>
					{title}
				</Text>
			</GridBox>
		);
	}

	return (
		<Text size='large' bold color={titleColor}>
			{title}
		</Text>
	);
}
