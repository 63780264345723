import {styled} from '@esgi/ui/theme';
import {ContextMenu} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';

export const ContextMenuItem = styled(ContextMenu.Item, {
	cursor: 'pointer',
	'&:hover': {
		background: '$secondaryBackground',
	},
});

export const ZoomRoot = styled(FlexBox, {
	justifyContent: 'flex-end',
	position: 'absolute',
	zIndex: 999,
	right: 0,
	top: 15,
	minWidth: 150,
	whiteSpace: 'nowrap',
	paddingRight: 15,
});

export const ZoomLabel = styled(FlexBox, {
	cursor: 'pointer',
	marginLeft: 5,
});

export const ZoomArrow = styled('span', {
	borderTop: '6px solid #828282',
	borderLeft: '6px solid transparent',
	borderRight: '6px solid transparent',
	margin: '5px 0 0 3px',
});
