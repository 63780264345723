import {CSSProperties} from 'react';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {ContainerStyled} from './index.styled';

type ChartProps = {
	chart: object;
	isFluid?: boolean;
	containerStyle?: CSSProperties;
};

export function Chart({chart, containerStyle, isFluid}: ChartProps) {
	return (
		<ContainerStyled dataCy='chart'>
			<HighchartsReact options={chart} isFluid={isFluid} containerStyle={containerStyle} />
		</ContainerStyled>
	);
}
