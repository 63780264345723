import * as React from 'react';
import type {SVGProps} from 'react';

export function Eye(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 8c-2.963 0-5.46 1.454-7.51 3.844a16.729 16.729 0 0 0-1.815 2.562c-.302.528-.486.936-.594 1.188a1.057 1.057 0 0 0 0 .812c.108.252.292.66.594 1.188.5.872 1.113 1.745 1.815 2.562C10.54 22.546 13.037 24 16 24s5.46-1.454 7.51-3.844c.7-.817 1.316-1.69 1.814-2.562.302-.528.487-.936.595-1.188a1.057 1.057 0 0 0 0-.812 11.32 11.32 0 0 0-.595-1.188 16.718 16.718 0 0 0-1.814-2.562C21.46 9.454 18.963 8 16 8Zm0 2c2.294 0 4.304 1.171 6.008 3.156a14.494 14.494 0 0 1 1.564 2.25c.155.271.222.411.313.594-.09.183-.158.323-.313.594-.44.768-.951 1.536-1.564 2.25C20.304 20.829 18.294 22 16 22s-4.304-1.171-6.008-3.156a14.569 14.569 0 0 1-1.565-2.25c-.154-.271-.221-.411-.312-.594.09-.183.158-.323.312-.594.44-.768.952-1.536 1.565-2.25C11.696 11.171 13.706 10 16 10Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 12.002a4 4 0 1 0 4 4 1 1 0 0 0-2 0 2 2 0 1 1-2-2c.245 0 .496.041.719.125A.997.997 0 0 0 18 13.533a.997.997 0 0 0-.594-1.28 3.957 3.957 0 0 0-1.406-.25Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
