import {Buttons} from '@esgillc/ui-kit/button';
import {Modal} from '@esgillc/ui-kit/modal';
import {useState} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';
import styles from './styles.module.less';

const SubjectDetails = lazyComponent(() => import('modules/subject-details'));

type Props = {
	doneClicked: () => any;
}

export function Footer(props: Props) {
	const [subjectCreatorOpened, setSubjectCreatorOpened] = useState(false);

	return <Modal.Footer>
		<div className={styles.footer}>
			<div>
				<Buttons.Outlined onClick={() => setSubjectCreatorOpened(true)}>
					CREATE NEW SUBJECT TAB
					<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
						<path
							d='M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
							fill='#0088CC'/>
					</svg>
				</Buttons.Outlined>
			</div>
			<div>
				<Buttons.Contained onClick={props.doneClicked}>
					DONE
				</Buttons.Contained>
			</div>
			{subjectCreatorOpened && <SubjectDetails
				selectAfterSave
				onClosed={() => setSubjectCreatorOpened(false)}
				onSaved={(id: number) => {
					setSubjectCreatorOpened(false);
					SsoTracker.trackEvent({
						trackingEvent: 'AddSubjectModal',
						data: {subjectId: id},
					});
				}}
			/>}
		</div>
	</Modal.Footer>;
}
