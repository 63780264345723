import {PanelHeaderTitle} from '../../../../../../components/panel-header-title';
import {SchoolYearModel, TrackModel, UpdateTrackRequestParams} from '../../../../../../../../types';
import {Ref, useImperativeHandle, useRef, useState} from 'react';
import {AddEditModeContent, AddEditModeContentRef} from './add-edit-mode-content';
import {SetActivePeriodButton} from '../../components/set-active-period-button';
import {Observable} from 'rxjs';
import {PotentialDataImpactAlert} from '../../../../../../components/potential-data-impact';
import {DrawerPanelHeader} from '../../components/components.styled';

export type EditModeRef = {
	isMarkingPeriodTouched: boolean;
};

type Props = {
	editableTrack: TrackModel;
	schoolYearTypes: SchoolYearModel[] | null;
	activeUserTrackId: TrackModel['trackID'];
	editModeRef: Ref<EditModeRef | undefined>;
	updateTrackRequest: (args: UpdateTrackRequestParams) => Observable<void>;
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
};

export function EditMode({
	editableTrack,
	schoolYearTypes,
	activeUserTrackId,
	editModeRef,
	updateTrackRequest,
	setActiveTrackId,
}: Props) {
	const [isPotentialDataImpactOpen, setIsPotentialDataImpactOpen] = useState(false);

	const [isDataUpdating, setIsDataUpdating] = useState(false);
	const [isMarkingPeriodTouched, setIsMarkingPeriodTouched] = useState(false);
	const [isMarkingPeriodValid, setIsMarkingPeriodValid] = useState(true);

	const addEditModeContentRef = useRef<AddEditModeContentRef>();

	const handleSave = () => {
		if (addEditModeContentRef.current?.isDatesChanged) {
			setIsPotentialDataImpactOpen(true);
			return;
		}

		handleUpdateTrack();
	};

	const handleUpdateTrack = () => {
		setIsDataUpdating(true);

		const contentData = addEditModeContentRef.current?.getContentData();

		if (!contentData) {
			return;
		}

		const {datesInRowsState, markingPeriodName, markingPeriodType} = contentData;

		updateTrackRequest({
			updatedTrack: editableTrack,
			trackType: markingPeriodType,
			trackDates: datesInRowsState,
			trackName: markingPeriodName,
		}).subscribe(() => {
			setIsDataUpdating(false);
			setIsMarkingPeriodTouched(false);
		});
	};

	useImperativeHandle(editModeRef, () => ({
		isMarkingPeriodTouched,
	}));

	return (
		<>
			<DrawerPanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={!isMarkingPeriodTouched || !isMarkingPeriodValid || isDataUpdating}
			>
				<PanelHeaderTitle title={editableTrack.name} />
			</DrawerPanelHeader>

			<AddEditModeContent
				defaultMarkingPeriodName={editableTrack.name}
				defaultMarkingPeriodType={editableTrack.schoolYearType}
				defaultTrackDates={editableTrack.trackDates}
				schoolYearTypes={schoolYearTypes}
				setIsMarkingPeriodTouched={setIsMarkingPeriodTouched}
				setIsMarkingPeriodValid={setIsMarkingPeriodValid}
				addEditModeContentRef={addEditModeContentRef}
			>
				<SetActivePeriodButton
					activeUserTrackId={activeUserTrackId}
					currentUserTrackId={editableTrack.trackID}
					setActiveTrackId={setActiveTrackId}
				/>
			</AddEditModeContent>

			{isPotentialDataImpactOpen && (
				<PotentialDataImpactAlert onClose={() => setIsPotentialDataImpactOpen(false)} onProceed={handleUpdateTrack} />
			)}
		</>
	);
}
