import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const RenewalImpl = lazyComponent(() => import('pages/landing/renewal'));

export default function Renewal() {
	return <RouteWrapper title='Renewal'>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<RenewalImpl/>
		</Suspense>
	</RouteWrapper>;
}
