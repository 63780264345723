import {Grade} from '../../../../../../types';
import styles from '../../styles.module.less';

interface Props {
	grades: Grade[];
}

export function DetailReportSettingsGradeScale({grades}: Props) {
	return Boolean(grades.length) && (
		<div data-cy='grade-scale' className={styles.settingsCell}>
			<div className={styles.title}>Grade Scale:</div>
			<div>
				<div className={styles.gradeScalesLegend}>
					{grades.map(({description, name, color, id}) =>
						<div
							key={id}
							title={description}
							style={{'backgroundColor': id !== 0 ? color : '#fff'}}
						>
							<span>{name}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
