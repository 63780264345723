import {InputAutocomplete} from '@esgillc/ui-kit/control';
import {ElementStatus, useFormElementContext} from '@esgillc/ui-kit/form';
import {join} from '@esgillc/ui-kit/utils';
import {useEffect, useRef, useState} from 'react';
import {Label} from '@esgillc/ui-kit/form-control';
import {getDateParts} from './utils';
import {DateOptions} from './types';
import {days, months, years} from './constants';
import styles from './styles.module.less';

interface Props {
	inputClassName?: string;
	labelClassName?: string;
}

export function FormDateInput({inputClassName, labelClassName}: Props) {
	const {status, setValue, validateOnBlur, value, setRef} = useFormElementContext({
		string: true,
		number: true,
		object: true,
	}, InputAutocomplete);

	const [focusedInputs, setFocusedInputs] = useState([]);
	const [dateOptions, setDateOptions] = useState(getDateParts(value));

	const focusTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

	const handleBlur = (option: DateOptions) => {
		clearTimeout(focusTimeoutRef.current);
		focusTimeoutRef.current = setTimeout(() => {
			setFocusedInputs(prev => prev.filter(item => item !== option));
		}, 300);
	};


	useEffect(() => {
		if (value) {
			setDateOptions(getDateParts(value));
		}
	}, [value]);

	const handleChange = (value: number, option: DateOptions) => {

		const newDateOptions = {...dateOptions, [option]: value};
		setDateOptions(newDateOptions);
		if (Object.values(newDateOptions).every(value => !!value)) {
			const {year, month, day} = newDateOptions;
			setValue(new Date(+year, +month - 1, +day, 10).toISOString());
		} else if(Object.values(newDateOptions).some(value => !!value)) {
			setValue(0);
		} else {
			setValue('');
		}
	};

	useEffect(() => {
		const triggerTimeout = setTimeout(() => {
			if(!focusedInputs.length) {
				validateOnBlur();
			}
		}, 2000);
		return () => clearTimeout(triggerTimeout);
	}, [focusedInputs, dateOptions]);

	const isDisabled = status === ElementStatus.disabled;
	const className = join(
		status === ElementStatus.invalid && styles.error,
		status === ElementStatus.valid && styles.success,
		inputClassName,
	);


	return <div data-cy='form-date-input' ref={setRef} className={styles.inputs}>
			<div data-cy='form-date-input-month'>
				<Label className={labelClassName}>Month</Label>
				<InputAutocomplete
					value={dateOptions.month}
					inputClassName={className}
					optionClassName={styles.option}
					placeholder='MM'
					disabled={isDisabled}
					options={months}
					onChange={(option) => handleChange(option?.value, DateOptions.Month)}
					onFocus={(e) => {
						e.stopPropagation();
						setFocusedInputs(prev => [...prev, DateOptions.Month]);
					}}
					onBlur={() => handleBlur(DateOptions.Month)}
				/>
			</div>
			<div data-cy='form-date-input-day'>
				<Label className={labelClassName}>Day</Label>
				<InputAutocomplete
					value={dateOptions.day}
					inputClassName={className}
					optionClassName={styles.option}
					placeholder='DD'
					disabled={isDisabled}
					options={days}
					onChange={(option) => handleChange(option?.value, DateOptions.Day)}
					onFocus={() => setFocusedInputs(prev => [...prev, DateOptions.Day])}
					onBlur={() => handleBlur(DateOptions.Day)}
				/>
			</div>
			<div data-cy='form-date-input-year'>
				<Label className={labelClassName}>Year</Label>
				<InputAutocomplete
					value={dateOptions.year}
					inputClassName={className}
					optionClassName={styles.option}
					placeholder='YYYY'
					disabled={isDisabled}
					options={years}
					onChange={(option) => handleChange(option?.value, DateOptions.Year)}
					onFocus={() => setFocusedInputs(prev => [...prev, DateOptions.Year])}
					onBlur={() => handleBlur(DateOptions.Year)}
				/>
			</div>
		</div>;
}
