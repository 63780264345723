import {useMemo, Fragment} from 'react';
import {usePieChartService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgi/ui/utils';
import CombineCard from '../cards/combine-card';
import PersonalCard from '../cards/personal-card';
import {AlertBody as Body, ReportWrapper, Student, Results} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {EmptyContentTemplate} from '@esgi/main/kits/reports';

interface Props {
	error: string;
}

export function AlertBody({error}: Props) {
	const service = usePieChartService();
	const report = useBehaviorSubject(service.reportData$);
	const printSettings = useBehaviorSubject(service.printSettings$);
	const unitDataSettings = useBehaviorSubject(service.unitDataSettings$);
	const chartBlockModels = useBehaviorSubject(service.chartBlockModels$);

	const isEachStudent = useMemo(
		() => printSettings.showEachStudent || (unitDataSettings.selectedStudent !== null || report.students.length === 1),
		[printSettings.showEachStudent, unitDataSettings.selectedStudent, report.students],
	);

	if (error.length) {
		return (
			<Body>
				<ReportWrapper>
					<EmptyContentTemplate
						templateText={error}
						position='middle'
					/>
				</ReportWrapper>
			</Body>
		);
	}

	return (
		<Body>
			<ReportWrapper>
				{!isEachStudent &&
					<Results>
						{report.tests.map((test) => {
							const chartBlockModel = chartBlockModels.find(({testID, studentID}) => testID === test.id && studentID === 0);
							return (
								<CombineCard
									key={test.id}
									test={test}
									chartBlockModel={chartBlockModel}
									printInColor={printSettings.printInColor}
									totalStudentCount={report.students.length}
								/>
							);
						})}
					</Results>
				}
				{isEachStudent && report.students.map((student) =>
					<Fragment key={student.id}>
						{unitDataSettings.selectedStudent === null &&
							<Student dataCy='student-name'>
								<Text size='xLarge'>{student.firstName + ' ' + student.lastName}</Text>
							</Student>
						}
						<Results dataCy={`results-${student.id}`}>
							{report?.tests.map((test) => {
								const chartBlockModel = chartBlockModels.find(({testID, studentID}) => testID === test.id && studentID === student.id);
								return (
									<PersonalCard
										key={test.id}
										test={test}
										chartBlockModel={chartBlockModel}
										printInColor={printSettings.printInColor}
									/>
								);
							})}
						</Results>
					</Fragment>
				)}
			</ReportWrapper>
		</Body>
	);
}
