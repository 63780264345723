import {Dispatch, useCallback, useEffect, useState} from 'react';
import {Body, Label, Section, Title, ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot} from '../../index.styled';
import {NotTested} from './types';

interface Props {
	disabled: boolean;
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function DisplayNotTestedControl({disabled, onChange, value: externalValue}: Props) {
	const [value, setValue] = useState(NotTested.Zero);

	useEffect(() => {
		setValue(externalValue ? NotTested.Zero : NotTested.NT);
	}, [externalValue]);

	const handleValueChanged = useCallback(
		(newValue: NotTested) => {
			setValue(newValue);
			onChange(newValue === NotTested.Zero);
		},
		[onChange],
	);

	return (
		<Section>
			<Title data-cy='title' size='small' font='mono' color='lowContrast'>
				Display Not Tested As
			</Title>
			<Body columns={1}>
				<ToggleGroupRoot data-cy='display-not-tested-as-toggle-group' value={value} disabled={disabled}>
					<ToggleGroupContent>
						<ToggleGroupItem value={NotTested.NT} onClick={() => handleValueChanged(NotTested.NT)}>
							<Label size='medium' bold>
								NT
							</Label>
						</ToggleGroupItem>
						<ToggleGroupItem value={NotTested.Zero} onClick={() => handleValueChanged(NotTested.Zero)}>
							<Label size='medium' bold>
								Zero
							</Label>
						</ToggleGroupItem>
					</ToggleGroupContent>
				</ToggleGroupRoot>
			</Body>
		</Section>
	);
}
