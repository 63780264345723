import {useMemo} from 'react';
import {Column, FixedColumnPosition} from '@esgi/deprecated/ui-kit/report/table/types';
import {StudentResult, SortModel} from '../../../../../types/table-level-report-service-types';
import {SortableColumn} from '../components/sortable-column';
import styles from '../styles.module.less';
import {RubricResultReportService} from '../../../../../service';
import {CellStyled, StudentTopHeaderColumn} from '../index.styled';

export function useStudentnameColumn({service, sortBy}: {service: RubricResultReportService; sortBy: SortModel | null}) {
	return useMemo<Column<StudentResult>>(
		() => ({
			fixedColumnPosition: FixedColumnPosition.Left,
			className: styles.studentsColumn,
			headers: [
				[
					{
						renderer: () => <StudentTopHeaderColumn data-cy='students-header-column'>Students</StudentTopHeaderColumn>,
					},
				],
				[
					{
						className: styles.firstNameHeader,
						renderer: () => (
							<CellStyled data-cy='first-name-header-column'>
								<SortableColumn
									title='First Name'
									sortByType={{sortKey: 'firstName'}}
									service={service}
									sortBy={sortBy}
								/>
							</CellStyled>
						),
					},
					{
						renderer: () => (
							<CellStyled data-cy='last-name-header-column'>
								<SortableColumn
									title='Last Name'
									sortByType={{sortKey: 'lastName'}}
									service={service}
									sortBy={sortBy}
								/>
							</CellStyled>
						),
					},
				],
			],
			cells: [
				{
					className: styles.nameCell,
					renderer: ({firstName}: StudentResult) => firstName,
				},
				{
					className: styles.nameCell,
					renderer: ({lastName}: StudentResult) => lastName,
				},
			],
		}),
		[service, sortBy],
	);
}
