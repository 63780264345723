import {Box, SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';

export const SelectableListGroup = styled(SelectableList.Group, {
	display: 'grid',
	gap: '$2',
});

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	gap: '$2',
	alignItems: 'center',
	padding: '8px 12px',
});

export const StyledText = styled(Text, {
	variants: {
		selected: {
			false: {
				color: '$neutral6 !important',
			},
		},
	},
});

export const Checkbox = styled(CheckboxUI, {
	padding: 4,
});

export const ChildrenWrapper = styled('li', {
	display: 'grid',
	gap: '$3',
	gridTemplateColumns: 'auto 1fr',
	paddingLeft: '$3',
	alignItems: 'center',
	justifyContent: 'start',

	'& + *': {
		marginTop: 12,
	},
});

export const ChildrenList = styled('ul', {
	display: 'grid',
	gap: '$2',
	paddingInlineStart: 0,
});

export const Divider = styled(Box, {
	width: 1,
	height: 'calc(100% - 16px)',
	backgroundColor: '$mild',
});
