import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {TableStudents, TableStudentsHeader, TableStudentsRowData} from '../../../table-students';
import {StudentModel} from '../../../../types';
import {EmptyContent} from '../../../empty-content';
import {useStudentsState} from './use-students-state';
import {SortDirection} from '@esgi/main/kits/assignments';

type Props = {
	testsCount: number;
	students: StudentModel[];
	sortStudentsByKey: (args: {key: keyof StudentModel; sortDirection: SortDirection}) => void;
	onOpenStudentCredentials: (studentId: StudentModel['studentID']) => void;
	onOpenCredentialsForStudentsInAssignment: VoidFunction;
};

export function StudentsTable({
	students,
	sortStudentsByKey,
	testsCount,
	onOpenStudentCredentials,
	onOpenCredentialsForStudentsInAssignment,
}: Props) {
	const {sortStudentsData, disableSortingCells} = useStudentsState({students, sortStudentsByKey});

	if (!students.length) {
		return <EmptyContent messageText='No students to display' />;
	}

	return (
		<TableStudents>
			<TableStudentsHeader
				studentsCount={students.length}
				disableSortingCells={disableSortingCells}
				sortStudentsData={sortStudentsData}
				testsCount={testsCount}
				onOpenCredentialsForStudentsInAssignment={onOpenCredentialsForStudentsInAssignment}
			/>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				{students.map((student) => (
					<TableStudentsRowData
						{...student}
						testsCount={testsCount}
						onOpenStudentCredentials={onOpenStudentCredentials}
						key={student.studentID}
					/>
				))}
			</OverlayScrollbarsComponent>
		</TableStudents>
	);
}
