import {PropsWithChildren, useMemo} from 'react';
import {TimestampContext, TimestampContextValue} from '../context';
import moment from 'moment';

type Props = PropsWithChildren<{
	date: Date | string;
}>;

export function TimestampRoot({date, children}: Props) {
	const {time, meridiem} = useMemo(
		() => ({
			meridiem: moment(date).format('A'),
			time: moment(date).format('hh:mm'),
		}),
		[date],
	);

	const contextValue = useMemo<TimestampContextValue>(
		() => ({
			time,
			meridiem,
		}),
		[meridiem, time],
	);

	return <TimestampContext.Provider value={contextValue}>{children}</TimestampContext.Provider>;
}
