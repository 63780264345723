import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

const titleContainerPaddingInline = 12;

export const Container = styled(GridBox, {
	width: '100%',
	gap: '$4',
	gridTemplateRows: 'auto 1fr',
});

export const TitleContainer = styled(GridBox, {
	paddingTop: 20,
	gap: '$3',
	textAlign: 'center',
});

export const Divider = styled(Box, {
	width: `calc(100% - 2 * ${titleContainerPaddingInline}px)`,
	height: 1,
	backgroundColor: '$mild',
	marginLeft: -titleContainerPaddingInline,
	justifySelf: 'center',
});
