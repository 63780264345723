import React, {createRef, RefObject} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {join} from '@esgillc/ui-kit/utils';
import {Criteria} from '../../../../types';
import NoteDialog from '../../../dialogs/note-dialog/note-dialog';
import styles from './criteria.module.less';

interface Props {
	criteria: Criteria;
	hovered: boolean;
	invalid: boolean;
	note: string;
	levelName: string;
	onNoteChanged: (value: string) => void;
}

class State {
	showNotesDialog: boolean = false;
}

export default class CriteriaView extends React.PureComponent<Props, State> {
	public boxRef: RefObject<HTMLDivElement> = createRef();
	public state = new State();

	public render() {
		return <>
			{this.renderCriteria()}
			{this.renderNotesDialog()}
		</>;
	}

	private renderCriteria() {
		const hasNotes = !!this.props.note?.trim();
		return <div className={styles.box} ref={this.boxRef}>
			<div className={styles.innerBox}>
				<div
					className={join(styles.criteria, this.props.hovered && styles.hovered, this.props.invalid && styles.invalid)}>
					<div>
						<div className={styles.title}>
							<span>Criteria {this.props.criteria.order}</span>
						</div>
					</div>
					<div className={styles.body}>
						<div className={styles.textContainer}>
							<div className={styles.name}>
								<span>{this.props.criteria.name}</span>
							</div>
						</div>
					</div>
					<div className={styles.footer}>
						<AnchorLikeButton onClick={() => this.setState({showNotesDialog: true})}>
							<OnHoverTooltip message={(hasNotes ? 'View' : 'Add') + ' Notes'}>
								<div>
									Notes
									{hasNotes && <svg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path d='M11.59 0L5 6.59L1.41 3.01L0 4.42L5 9.42L13 1.42L11.59 0Z' fill='#0077B8'/>
									</svg>}
								</div>
							</OnHoverTooltip>
						</AnchorLikeButton>
					</div>
				</div>
			</div>
		</div>;
	}

	private renderNotesDialog() {
		if (this.state.showNotesDialog) {
			return <NoteDialog initValue={this.props.note}
			                   criteriaName={this.props.criteria.name}
			                   levelName={this.props.levelName || ''}
			                   onClose={() => this.setState({showNotesDialog: false})}
			                   onSave={(value) => this.setState({showNotesDialog: false}, () => this.props.onNoteChanged(value))}/>;
		}
	}
}
