import {BaseService} from '@esgi/core/service';
import {SightWordsTestQuestion} from '../../shared/types';
import {SightWordsTestQuestionsResponse} from './types';
import {map} from 'rxjs/operators';
import {ObservableBuilder} from '@esgi/api';

export default class DataService extends BaseService {
	private readonly controller: string = 'writing-practice';

	public init(testId: number): ObservableBuilder<SightWordsTestQuestion[]> {
		const request = {
			testID: testId,
		};
		return this.httpClient.ESGIApi
			.get<SightWordsTestQuestionsResponse>(this.controller, 'sight-words/test/questions', request)
			.pipe(map(response => response.questions));

	}
}