import React, {useState} from 'react';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {TemplateProps} from '../template';
import {getUrl, isMultiple} from '../../utils';
import BaseOption from '../../option/option';
import {StoryButton} from '../../action-buttons/story-button';
import {TextAudioOption} from '@esgi/selfassess';
import {AudioModal} from './components/audio-modal';

export default function Story({
	                              awsBucket,
	                              maxOptionsSelected,
	                              selectedOptionID,
	                              question,
	                              onSelectOption,
	                              audioPlaying,
	                              questionIndex,
								  isPreviewMode,
}: TemplateProps) {
	const options = question.options as TextAudioOption[];
	const [isStoryModalOpen, setStoryModalOpen] = useState(questionIndex === 0);
	const [isListened, setListened] = useState(false);
	const isSelected = (id: number) => {
		return selectedOptionID.includes(id);
	};
	const storyUrl = getUrl(awsBucket, question.audioStoryUrl);

	const handleAudioEnd = () => {
		setListened(true);
	};

	return (
		<div className={styles.template}>
			<div className={join(styles.column, styles.left)}>
				<StoryButton clicked={() => setStoryModalOpen(true)}/>
			</div>
			<div className={join(styles.column, styles.right)}>
				{options.map(({optionID, audioUrl, text}) =>
					<BaseOption
						bucket={awsBucket}
						type={'Horizontal'}
						id={optionID}
						disabled={maxOptionsSelected && !selectedOptionID.includes(optionID)}
						multiselect={isMultiple(question)}
						key={optionID}
						text={text}
						className={styles.optionBox}
						clicked={() => onSelectOption(optionID)}
						selected={isSelected(optionID)}
						audioPath={audioUrl}
						disableAudio={audioPlaying}
						customize={{optionClassName: styles.customizeOption, actionClassName: styles.customizeAction}}
					/>)}
			</div>
			{isStoryModalOpen && <AudioModal
				id={question.id}
				onAudioEnd={handleAudioEnd}
				canClose
				url={storyUrl}
				onClose={() => setStoryModalOpen(false)}/>}
		</div>
	);
}
