import {BaseService} from '@esgi/core/service';
import {catchError, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {PdfDownloadResult} from '../types';

export abstract class BasePdfDownloadingService<TReport> extends BaseService {
	protected readonly controller: string = 'writing-practice';

	protected abstract executePdfDownloadingRequest(report: TReport): Observable<Blob>;

	public downloadPdf(report: TReport): Observable<PdfDownloadResult> {
		const downloadAction = this
			.executePdfDownloadingRequest(report)
			.pipe(
				map(result => PdfDownloadResult.success(result)),
				catchError(error => of(PdfDownloadResult.error(error))),
			);
		return this.completeOnDestroy(downloadAction);
	}
}