import {BehaviorSubject} from 'rxjs';
import {EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {SelectAllTestsEvent} from '../../../model';
import {initialSortValue, SortType, SortDirectionTypes} from './models';

export class TestsRootService extends BaseService {
	public readonly sort$ = new BehaviorSubject<SortType>(initialSortValue);

	protected eventBus = new EventBusManager();

	public handleSelectAll() {
		this.eventBus.dispatch(SelectAllTestsEvent, new SelectAllTestsEvent());
	}

	public handleSortDirectionChange() {
		const direction = this.sort$.value.direction === SortDirectionTypes.Ascending
			? SortDirectionTypes.Descending
			: SortDirectionTypes.Ascending;

		this.sort$.next({
			...this.sort$.value,
			direction,
		});
	}

	public destroy() {
		this.eventBus.destroy();
	}
}