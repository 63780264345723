import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {FlexBox} from '@esgi/ui/layout';

export const TestShortcutsInfo = styled(Text, {
	display: 'grid',
	padding: '8px 56px',
	gridTemplateColumns: 'repeat(4,auto) 1fr',
	gap: '12px',
	alignItems: 'center',
});

export const KeyboardButton = styled(FlexBox, {
	border: '1px solid $muted',
	borderRadius: '6px',
	padding: '8px',
	width: '40px',
	height: '40px',
	alignItems: 'center',
	justifyContent: 'center',

	'& > svg': {
		fill: '$base',
		width: '24px',
		height: '24px',

		'& > path': {
			fill: '$base',
		},
	},
});
