import {
	SortDirection,
	SortModel,
	SortByType,
	SortByCriteriaResultsOptions,
	sortDirectionMap,
} from '../../../../../../types/table-level-report-service-types';
import {IconArrowDown, IconArrowTop} from '../icon';
import {useCallback, useMemo} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {Text} from '@esgi/ui/typography';
import {RubricResultReportService} from '../../../../../../service';
import {IconArrowDownStyled, IconArrowTopStyled, SortableColumnStyled} from './index.styled';

type SortableColumnProps = {
	title: string;
	service: RubricResultReportService;
	sortBy: SortModel;
	sortByType: SortByType;
	sortByCriteriaResultsOptions?: SortByCriteriaResultsOptions | null;
};

export function SortableColumn({
	title,
	service,
	sortBy,
	sortByType,
	sortByCriteriaResultsOptions,
}: SortableColumnProps) {
	const isSortedByThisColumn = useMemo(() => {
		if (
			sortByType?.sortKey === 'criteriaResults' &&
			sortBy?.fieldName === 'criteriaResults' &&
			sortByCriteriaResultsOptions
		) {
			return (
				sortByType?.criteriaName === sortByCriteriaResultsOptions.criteriaName &&
				sortByType?.periodResultName === sortByCriteriaResultsOptions.periodResultName
			);
		}

		return sortBy?.fieldName === sortByType?.sortKey;
	}, [sortBy, sortByType, sortByCriteriaResultsOptions]);

	const onClick = useCallback(() => {
		if (!isSortedByThisColumn) {
			service.toggleSortDirection(sortByType, SortDirection.Asc);

			return;
		}

		const newDirection = sortBy?.direction === sortDirectionMap[SortDirection.Asc] ? SortDirection.Desc : SortDirection.Asc;

		service.toggleSortDirection(sortByType, newDirection);
	}, [isSortedByThisColumn, service, sortBy, sortByType]);

	return (
		<SortableColumnStyled onClick={onClick}>
			{sortBy?.direction === sortDirectionMap[SortDirection.Asc] || !isSortedByThisColumn ? (
				<IconArrowDownStyled visible={isSortedByThisColumn}/>
			) : (
				<IconArrowTopStyled />
			)}
			<Text>{title}</Text>
		</SortableColumnStyled>
	);
}
