import {PropsWithChildren} from 'react';
import {Divider, Title, TitleWrapper, Wrapper} from './index.styled';

type Props = PropsWithChildren<{
	title: string;
	titleBold?: boolean;
	withDivider?: boolean;
}>;

export function DrawerContentBlock({title, titleBold, children, withDivider = true}: Props) {
	return (
		<Wrapper dataCy='drawer-content-block'>
			<TitleWrapper>
				<Title size='small' font='mono' bold={Boolean(titleBold)} color='mediumContrast' data-cy='drawer-content-block-title'>
					{title}
				</Title>
				{withDivider && <Divider />}
			</TitleWrapper>
			{children}
		</Wrapper>
	);
}
