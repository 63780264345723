import React from 'react';
import {Form} from '@esgillc/ui-kit/form';
import {Modal} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import ClassSelector from './class-selector/class-selector';
import GroupSelector from './group-selector/group-selector';
import StudentSelector from './student-selector/student-selector';
import NavigationBar from '../../shared/components/navigation-bar/navigation-bar';
import {FlexBox} from '@esgillc/ui-kit/layout';
import styles from './students-step.module.less';
import DataService from './data-service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {ClassResponse, GroupResponse} from './types';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {StudentResponse, StudentStepState} from '../../shared/types';

interface Props {
	selectedClassId?: number;
	selectedGroupId?: number;
	selectedStudentIds?: number[];
	currentStep: number;
	totalStepsCount: number;
	goToBack: () => void;
	hierarchy: HierarchySnapshot;
	goToNext: (data: StudentStepState) => void;
}

class State {
	classes: ClassResponse[] = [];
	groups: GroupResponse[] = [];
	students: StudentResponse[] = [];
	isValidForm: boolean = false;
}

export default class StudentsStep extends React.PureComponent<Props, State> {
	private readonly dataService: DataService = new DataService();
	public override readonly state = new State();

	componentDidMount() {
		this.dataService.studentsSelectionStateChanged$.subscribe(state => this.setState({isValidForm: state.hasAnySelectedData}));
		this.dataService.filterChanged$.subscribe(data => this.setState({
			classes: data.classes,
			groups: data.groups,
			students: data.students,
		}));
		this.dataService.init(
			this.props.hierarchy,
			this.props.selectedClassId,
			this.props.selectedGroupId,
			this.props.selectedStudentIds,
		);
	}

	componentWillUnmount(): void {
		this.dataService.destroy();
	}


	render() {
		const groupClassName = !this.dataService.isSpecialist
			? join('col-xs-12 col-md-6', styles.groups)
			: join('col-xs-12', styles.groups);

		return <>
			<ServiceLoader trackingService={this.dataService}/>
			<Modal.Body>
				<FlexBox align='stretch' justify='stretch' direction='column' className={styles.body}>
					<Form controller={this.dataService.form} className={styles.form}>
						<div className={styles.bodyTitle}>
							Choose the students to create a practice sheet for.
						</div>
						<div className='row'>
							{
								!this.dataService.isSpecialist &&
								<ClassSelector className={join('col-xs-12 col-md-6', styles.classes)}
											   control={this.dataService.form.controls.class}
											   classes={this.state.classes}/>
							}
							<GroupSelector className={groupClassName}
										   dataService={this.dataService}
										   control={this.dataService.form.controls.group}
										   groups={this.state.groups}/>
						</div>
						<div className={join('row', styles.students)}>
							<StudentSelector className='col-xs-12'
											 dataService={this.dataService}
											 control={this.dataService.form.controls.student}
											 students={this.state.students}/>
						</div>
					</Form>
				</FlexBox>
			</Modal.Body>
			<Modal.Footer>
				<NavigationBar prevStepEnabled={true}
							   nextStepEnabled={this.state.isValidForm}
							   currentStep={this.props.currentStep}
							   totalSteps={this.props.totalStepsCount}
							   nextClicked={() => this.onNextClicked()}
							   previousClicked={this.props.goToBack}/>
			</Modal.Footer>
		</>;
	}


	private onNextClicked(): void {
		const data = this.dataService.getFormState();
		return this.props.goToNext(data);
	}
}
