import {SortDirection} from '@esgi/core/enums';

export enum SortBy {
	CreateDate,
	Name
}
export class SortModel {
	direction: SortDirection;
	type: SortBy;
}
