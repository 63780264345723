import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Checkbox} from '@esgi/ui/controls';

export const SetActiveBox = styled(GridBox, {
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 8,
	paddingRight: 8,
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',

	'& > a': {
		width: 24,
		height: 24,

		'& > svg': {
			width: '100%',
			height: '100%',
		},
	},
});

export const CheckboxStyled = styled(Checkbox, {
	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
	},
});
