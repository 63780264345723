export function RightArrowIcon({className}: { className?: string }) {
	return <svg width='8'
	            height='12'
	            viewBox='0 0 8 12'
	            fill='none'
	            xmlns='http://www.w3.org/2000/svg'
	            className={className}
	>
		<path fill='#0088CC'
		      d='M0.590088 10.59L5.17009 6L0.590088
                1.41L2.00009 0L8.00009 6L2.00009 12L0.590088 10.59Z'>
		</path>
	</svg>;
}
