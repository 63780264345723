import React, {ComponentProps, ReactNode, useCallback} from 'react';
import {ElementStatus, useFormElementContext} from '../../form';
import {Select as BaseSelect} from '../../controls/exports';
import {SelectContent} from '../../controls/select/components/select-content';
import {SelectOption} from '../../controls/select/components/select-option';
import * as SelectRadix from '@radix-ui/react-select';
import {ErrorText} from '../../controls/select/components/error-text';
import {SelectField} from './select-field';

interface SelectProps extends Omit<ComponentProps<typeof BaseSelect.Root>, 'onValueChange' | 'value' | 'defaultValue'>{
	children?: ReactNode;
	dataCy?: string;
}

export function FormSelect({children, dataCy='ui-kit-form-select-root'}: SelectProps) {

	const {status, setValue, value} = useFormElementContext({array: true}, Select);
	const onValueChange = useCallback((value: string) => setValue([value]), [setValue]);

	const commonProps = {
		value: value[0],
		onValueChange,
		disabled: status === ElementStatus.disabled,
		error: status === ElementStatus.invalid,
		dataCy,
	};

	return <BaseSelect.Root {...commonProps}>
		{children}
	</BaseSelect.Root>;
}

export const Select = {
	Root: FormSelect,
	Field: SelectField,
	Content: SelectContent,
	Viewport: SelectRadix.Viewport,
	Option: SelectOption,
	ErrorText: ErrorText,
};
