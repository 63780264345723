import {AppEvent} from '@esgillc/events';
import {StudentSort} from './types';

@AppEvent({broadcast: true})
export class UserSettingsChangedEvent {
	constructor(
		public userID: number,
		public sortBy: StudentSort,
		public showExpiredUsersChanged: boolean,
		public showSelfAssessOption: boolean,
	) {
	}
}
