import {useLocation} from 'react-router-dom';
import {Text} from '@esgi/ui/typography';
import {IconComponent} from '@esgi/ui';
import {Users, File, StudentProgress, Reports, FieldConnector, RubricReport} from '@esgi/ui/icons';
import {routes} from '@esgi/main/libs/core';

export function Title() {
	const {pathname} = useLocation();
	const data: Record<string, [IconComponent, string]> = {
		[routes.teacher.reports.parentLetter]: [File, 'Parent Letter'],
		[routes.teacher.reports.studentDetail]: [File, 'Student Detail Report'],
		[routes.teacher.reports.studentProgress]: [StudentProgress, 'Student Progress Report'],
		[routes.teacher.reports.classGrades]: [Users, 'Class Grades'],
		[routes.teacher.reports.classTotals]: [Users, 'Class Totals'],
		[routes.teacher.reports.itemAnalysis]: [Reports, 'Item Analysis'],
		[routes.teacher.reports.pieCharts]: [FieldConnector, 'Pie Charts'],
		[routes.teacher.reports.rubric]: [RubricReport, 'Rubric'],
	};

	if (!data[pathname]) {
		return null;
	}

	const [Icon = null, text = ''] = data[pathname];

	return (
		<>
			<Icon data-cy='panel-icon'/>
			<Text data-cy='panel-label' size='small'>{text}</Text>
		</>
	);
}
