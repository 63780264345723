import {Modal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../../styles.module.less';

interface Props {
	onClose: () => void;
}

export const Footer = ({onClose}: Props) => (
	<Modal.Footer>
		<Buttons.Gray
			className={styles.cancel}
			onClick={onClose}
		>
			Close
		</Buttons.Gray>
	</Modal.Footer>
);
