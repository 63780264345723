import {RefObject} from 'react';
import {sortBy} from 'underscore';
import {shareReplay, takeUntil, tap} from 'rxjs/operators';
import {concatMap, firstValueFrom, merge, Observable, Subject} from 'rxjs';
import moment from 'moment/moment';
import {BaseService} from '@esgi/core/service';
import {isIOS} from '@esgillc/ui-kit/utils';
import {BroadcastEventManager, dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {SchoolYearChangedEvent} from 'modules/school-year';
import {
	StudentChangedEvent,
	StudentCreatedEvent,
	StudentRemovedEvent,
} from 'modules/forms/students-form/events';
import {hasHierarchyRelatedEvents, hierarchyAggregationPattern} from 'pages/core/hierarchy-events';
import {HierarchyDataService} from 'modules/hierarchy/services/hierarchy-data-service';
import {FormDataBuilder} from '@esgi/api';
import {userStorage} from '@esgi/core/authentication';
import {ParentConferencerChangedEvent} from './components/events';
import {Conferencer, ConferencerDate, ConferencerItem, ConferencerTime, DataModel, Student} from './models/models';

function sortStudents(students: Student[], sortBy: string): Student[] {
	const sortByProperty: keyof Student = sortBy === 'Last Name' ? 'lastName' : 'firstName';
	const thenBy: keyof Student = sortByProperty === 'lastName' ? 'firstName' : 'lastName';
	return students.sort((a: Student, b: Student) => {
		if (a[sortByProperty].toLowerCase() < b[sortByProperty].toLowerCase()) {
			return -1;
		}

		if (a[sortByProperty].toLowerCase() > b[sortByProperty].toLowerCase()) {
			return 1;
		}

		if (a[thenBy].toLowerCase() < b[thenBy].toLowerCase()) {
			return -1;
		}

		if (a[thenBy].toLowerCase() > b[thenBy].toLowerCase()) {
			return 1;
		}

		return 0;
	});
}

function formattedDate(): string {
	const date = new Date();
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	return `${year}-${month}-${day}`;
}

export class DataService extends BaseService {
	public readonly data$: Observable<DataModel>;

	private readonly controller: string = 'ParentConferencer';
	private readonly reloadEmitter: Subject<void> = new Subject<void>();
	private readonly updateEmitter: Subject<DataModel> = new Subject<DataModel>();
	private lastData: DataModel;
	private conferencerToRecover: Conferencer;
	private readonly eventBus = new EventBusManager();
	private readonly broadcastEventBus = new BroadcastEventManager();

	constructor(hierarchyServiceRef: RefObject<HierarchyDataService>) {
		super();

		const request$ = this.httpClient.ESGIApi.get<DataModel>(this.controller, 'Schedule');

		this.data$ = merge(
			request$,
			this.updateEmitter,
			this.reloadEmitter.pipe(concatMap(() => request$)),
		).pipe(
			tap(data => this.lastData = data),
			shareReplay(),
			takeUntil(this.destroy$),
		);

		this.broadcastEventBus.aggregate({
			...hierarchyAggregationPattern,
			SchoolYearChangedEvent,
			ParentConferencerChangedEvent,
		}).subscribe(async (aggregation) => {
			if (aggregation.has(SchoolYearChangedEvent)) {
				await hierarchyServiceRef.current?.updateGlobalSchoolYear(userStorage.get().globalSchoolYearID);
				this.reloadEmitter.next();
				return;
			}
			const hasHierarchyEvents = hasHierarchyRelatedEvents(aggregation);
			if (hasHierarchyEvents) {
				await firstValueFrom(hierarchyServiceRef.current?.reload());
			}
			if (aggregation.has(ParentConferencerChangedEvent) || hasHierarchyEvents) {
				this.reloadEmitter.next();
			}
		});

		this.eventBus.subscribe(StudentChangedEvent, (args: StudentChangedEvent) => {
			const student = this.lastData.students.find(s => s.studentID === args.studentID);
			student.firstName = args.firstName;
			student.lastName = args.lastName;
			student.languageID = args.languageID;

			let students = this.lastData.students.map(s => s.studentID === args.studentID ? {...student} : s);
			students = sortStudents(students, this.lastData.sortBy);

			const studentName = `${student.firstName} ${student.lastName}`;

			const updatedItems = this.lastData.conferencerItems
				.map((i) => i.studentID === args.studentID ? {...i, studentName} as ConferencerItem : i);

			const newData = {...this.lastData};
			newData.students = students;
			newData.conferencerItems = updatedItems;
			this.updateEmitter.next(newData);
		});

		this.eventBus.subscribe(StudentCreatedEvent, (args: StudentCreatedEvent) => {
			const student: Student = {
				firstName: args.firstName,
				lastName: args.lastName,
				studentID: args.studentID,
				languageID: args.languageID,
			};

			const students = sortStudents([...this.lastData.students, student], this.lastData.sortBy);

			const newData = {...this.lastData};
			newData.students = students;
			this.updateEmitter.next(newData);
		});

		this.eventBus.subscribe(StudentRemovedEvent, (args: StudentRemovedEvent) => {
			const newData = {...this.lastData};
			newData.students = newData.students.filter(s => s.studentID !== args.id);
			newData.conferencerItems = newData.conferencerItems.filter(c => c.studentID !== args.id);
			this.updateEmitter.next(newData);
		});
	}

	public update(data: Partial<DataModel>): void {
		this.updateEmitter.next({...this.lastData, ...data});
	}

	public removeEditDate = (conferencerDateID: number): void => {
		const payload = FormDataBuilder.BuildParameters({conferencerDateID});
		this.httpClient.ESGIApi
			.post(this.controller, 'DeleteDate', payload)
			.subscribe(() => {
				this.update({
					conferencerDates: [...this.lastData.conferencerDates.filter(x => x.conferencerDateID !== conferencerDateID)],
					conferencerItems: [...this.lastData.conferencerItems.filter(x => x.conferencerDateID !== conferencerDateID)],
				});
				dispatchAppEvent(ParentConferencerChangedEvent);
			});
	};

	public removeEditTime = (conferencerTimeID: number) => {
		const payload = FormDataBuilder.BuildParameters({conferencerTimeID});
		this.httpClient.ESGIApi
			.post(this.controller, 'DeleteTime', payload)
			.subscribe(() => {
				this.update({
					conferencerTimes: [...this.lastData.conferencerTimes.filter(x => x.conferencerTimeID !== conferencerTimeID)],
					conferencerItems: [...this.lastData.conferencerItems.filter(x => x.conferencerTimeID !== conferencerTimeID)],
				});
				dispatchAppEvent(ParentConferencerChangedEvent);
			});
	};

	public removeEditItem = (conferencerItemID: number) => {
		const payload = FormDataBuilder.BuildParameters({conferencerItemID});
		this.httpClient.ESGIApi
			.post(this.controller, 'DeleteItem', payload)
			.subscribe(() => {
				this.update({
					conferencerItems: this.lastData.conferencerItems.filter(i => i.conferencerItemID !== conferencerItemID),
				});
				dispatchAppEvent(ParentConferencerChangedEvent);
			});
	};

	public saveDate(dateModel: ConferencerDate): Observable<ConferencerDate> {
		const payload = FormDataBuilder.BuildParameters({dateModel});
		return this.httpClient.ESGIApi
			.post<ConferencerDate>(this.controller, 'EditDate', payload)
			.pipe(
				tap((r) => {
					const id = r.conferencerDateID;
					const date = {
						conferencerDateID: id,
						date: dateModel.date,
					} as ConferencerDate;
					let dates = [...this.lastData.conferencerDates];

					if (dateModel.conferencerDateID > 0) {
						dates = dates.map((d) => d.conferencerDateID === id ? date : d);
					} else {
						dates.push(date);
					}

					this.update({conferencerDates: sortBy(dates, (d) => d.date)});
					dispatchAppEvent(ParentConferencerChangedEvent);
				}),
			).asObservable();
	}

	public saveTime(timeModel: ConferencerTime): Observable<{conferencerTimeID: number}> {
		const payload = FormDataBuilder.BuildParameters({timeModel});
		return this.httpClient.ESGIApi.post<{conferencerTimeID: number}>(this.controller, 'EditTime', payload)
			.pipe(
				tap((r) => {
					const id = r.conferencerTimeID;

					const time = {
						conferencerTimeID: id,
						time: timeModel.time,
					};

					let times = [...this.lastData.conferencerTimes];
					if (timeModel.conferencerTimeID > 0) {
						times = times.map((t) => t.conferencerTimeID === id ? time : t);
					} else {
						times.push(time);
					}

					this.update({
						conferencerTimes: sortBy(times, (t) => {
							const time = moment(t.time);
							return time.hours() * 60 + time.minutes();
						}),
					});

					dispatchAppEvent(ParentConferencerChangedEvent);
				}),
			).asObservable();
	}

	public saveItem(item: ConferencerItem): Observable<any> {
		const {
			conferencerItems,
			defaultConferenceRoom,
			defaultConferenceLength,
		} = this.lastData;

		const updateDefaults = item.roomName !== defaultConferenceRoom || item.lengthMin !== defaultConferenceLength;

		const payload = FormDataBuilder.BuildParameters({item, updateDefaults});
		return this.httpClient.ESGIApi.post<ConferencerItem>(this.controller, 'EditItem', payload)
			.pipe(tap((data) => {
				const items = [...conferencerItems];
				if (item.conferencerItemID > 0) {
					const index = items.map(e => e.conferencerItemID).indexOf(data.conferencerItemID);
					const cItem = {...items[index]};
					cItem.conferencerDateID = item.conferencerDateID;
					cItem.conferencerTimeID = item.conferencerTimeID;
					cItem.studentID = item.studentID;
					cItem.studentName = item.studentName;
					cItem.lengthMin = item.lengthMin;
					cItem.roomName = item.roomName;
					cItem.languageID = item.languageID;
					items[index] = cItem;
				} else {
					item.conferencerItemID = data.conferencerItemID;
					items.push(item);
				}

				this.update({
					conferencerItems: items,
					defaultConferenceRoom: item.roomName,
					defaultConferenceLength: item.lengthMin,
				});
				dispatchAppEvent(ParentConferencerChangedEvent);
			})).asObservable();
	}

	public clearSchedule(): Observable<any> {
		this.conferencerToRecover = {
			conferencerItems: this.lastData.conferencerItems,
			conferencerDates: this.lastData.conferencerDates,
			conferencerTimes: this.lastData.conferencerTimes,
		};

		this.update({
			conferencerItems: [],
			conferencerDates: [],
			conferencerTimes: [],
		});

		return this.httpClient.ESGIApi.post<boolean>(this.controller, 'CreateClear')
			.pipe(tap(() => dispatchAppEvent(ParentConferencerChangedEvent))).asObservable();
	}

	public restoreSchedule(): Observable<any> {
		this.update({
			conferencerItems: this.conferencerToRecover.conferencerItems,
			conferencerDates: this.conferencerToRecover.conferencerDates,
			conferencerTimes: this.conferencerToRecover.conferencerTimes,
		});
		return this.httpClient.ESGIApi.post<boolean>(this.controller, 'DeleteClear')
			.pipe(tap(() => dispatchAppEvent(ParentConferencerChangedEvent))).asObservable();
	}

	public downloadSchedulerPDF = () => {
		const filename = `Parent_Conferencer_Schedule_${formattedDate()}.pdf`;

		this.httpClient.ESGIApi
			.file(this.controller, 'DownloadPDFSchedule', filename)
			.subscribe();
	};

	public downloadParentLetterPDF = (letterTypeID: number, studentID: number) => {
		let action = '';
		const pcType = (letterTypeID === 2 ? 'Letter' : 'Reminder') + 's';

		if (!isIOS()) {
			action += 'DownloadPDFLetter';
		} else {
			action += 'FormDownloadPDFLetter';
		}

		const filename = `Parent_Conferencer_${pcType}_${formattedDate()}.pdf`;

		this.httpClient.ESGIApi
			.file(this.controller, action, filename, {LetterTypeID: letterTypeID, StudentID: studentID})
			.subscribe();
	};

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
		this.reloadEmitter.complete();
		this.updateEmitter.complete();
		this.broadcastEventBus.destroy();
	}
}
