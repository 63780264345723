import React, {Component} from 'react';

const HEADER_COLORS = {
	1: '#8878E7',
	2: '#BA5ACB',
	3: '#FFA53D',
};

interface Props {
	number: number;
}

class CardTemplate extends Component<Props> {	
	render() {
		return (
			<svg width='347' height='474' viewBox='0 0 347 474' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g filter='url(#filter0_ddd_11_10)'>
					<mask id='path-1-outside-1_11_10' maskUnits='userSpaceOnUse' x='5' y='4' width='337' height='463' fill='black'>
						<rect fill='white' x='5' y='4' width='337' height='463'/>
						<path fillRule='evenodd' clipRule='evenodd' d='M173.5 7C123.225 7 80.7224 40.2734 66.8108 86H8V167V452C8 458.627 13.3726 464 20 464H327C333.627 464 339 458.627 339 452V167V86H280.189C266.278 40.2734 223.775 7 173.5 7Z'/>
					</mask>
					<path fillRule='evenodd' clipRule='evenodd' d='M173.5 7C123.225 7 80.7224 40.2734 66.8108 86H8V167V452C8 458.627 13.3726 464 20 464H327C333.627 464 339 458.627 339 452V167V86H280.189C266.278 40.2734 223.775 7 173.5 7Z' fill='white'/>
					<path d='M66.8108 86V89H69.0339L69.6809 86.8732L66.8108 86ZM8 86V83H5V86H8ZM339 86H342V83H339V86ZM280.189 86L277.319 86.8732L277.966 89H280.189V86ZM69.6809 86.8732C83.2189 42.3745 124.583 10 173.5 10V4C121.868 4 78.2258 38.1722 63.9407 85.1268L69.6809 86.8732ZM8 89H66.8108V83H8V89ZM11 167V86H5V167H11ZM11 452V167H5V452H11ZM20 461C15.0294 461 11 456.971 11 452H5C5 460.284 11.7157 467 20 467V461ZM327 461H20V467H327V461ZM336 452C336 456.971 331.971 461 327 461V467C335.284 467 342 460.284 342 452H336ZM336 167V452H342V167H336ZM336 86V167H342V86H336ZM280.189 89H339V83H280.189V89ZM173.5 10C222.417 10 263.781 42.3745 277.319 86.8732L283.059 85.1268C268.774 38.1722 225.132 4 173.5 4V10Z' fill='#E0E0E0' mask='url(#path-1-outside-1_11_10)'/>
				</g>
				<path fillRule='evenodd' clipRule='evenodd' d='M66.8108 86C80.7224 40.2734 123.225 7 173.5 7C223.775 7 266.278 40.2734 280.189 86H339V167H8V86H66.8108Z' fill={HEADER_COLORS[this.props.number]}/>
				<defs>
					<filter id='filter0_ddd_11_10' x='0' y='0' width='347' height='474' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
						<feFlood floodOpacity='0' result='BackgroundImageFix'/>
						<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
						<feOffset dy='1'/>
						<feGaussianBlur stdDeviation='2.5'/>
						<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
						<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11_10'/>
						<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
						<feOffset dy='3'/>
						<feGaussianBlur stdDeviation='2'/>
						<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/>
						<feBlend mode='normal' in2='effect1_dropShadow_11_10' result='effect2_dropShadow_11_10'/>
						<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
						<feOffset dy='2'/>
						<feGaussianBlur stdDeviation='2'/>
						<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/>
						<feBlend mode='normal' in2='effect2_dropShadow_11_10' result='effect3_dropShadow_11_10'/>
						<feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_11_10' result='shape'/>
					</filter>
				</defs>
			</svg>
		);
	}
}

export default CardTemplate;