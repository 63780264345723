import {styled} from '@esgi/ui/theme';
import {OneLinedText} from '@esgi/main/kits/common';
import {FlexBox} from '@esgi/ui/layout';

export const InfoBlockLabel = styled(OneLinedText, {
	marginLeft: 24,
	fontWeight: 600,
	fontSize: 11,
});


export const Wrapper = styled(FlexBox, {
	flexDirection: 'column',
	'& > div:first-child': {
		marginBottom: '12px',
	},
});