import {computed, observable, FormField, Validators, OldAlerts} from '@esgi/deprecated/knockout';
import moment, {Moment} from 'moment';
import {AddStudentsTemplate} from '../html/add-students-template';
import {printMoney, StudentCartModel} from '../models';
import {Step} from './steps';

export class AddStudentsStep extends Step {

	constructor(
		public studCart: StudentCartModel,
		private oneStudentPrice: number,
		private getMaxExpirationDate: () => Moment,
		private getMinExpirationDate: () => Moment,
	) {
		super();
		this.title = 'Add Students';
		this.icon = 'fa-user';

		this.students = this.createStudents(this.studCart.newStudentLimit.toString());
		this.ratePerStudentFullYear = oneStudentPrice;
		this.currentStudentLimit = this.studCart.currentStudentLimit;
	}

	template = AddStudentsTemplate;

	@observable()
	ratePerStudentFullYear = 5;

	@observable()
	public currentStudentLimit: number;

	students: FormField<string>;

	@computed()
	get nextButtonEnabled() {
		let value = parseInt(this.students.value());
		return value >= StudentCartModel.defaultStudensLimit && value <= StudentCartModel.maxStudensLimit;
	}

	@computed()
	get allowAddExtraStudents() {
		return this.currentStudentLimit >= StudentCartModel.defaultStudensLimit;
	}

	createStudents(initValue: KnockoutObservable<string> | string) {
		return this.createField<string>
		(initValue, Validators.Required(), Validators.Range
		(StudentCartModel.defaultStudensLimit, 50,
			`Number of students cannot be less than ${StudentCartModel.defaultStudensLimit} and cannot be greater than ${StudentCartModel.maxStudensLimit}`));
	}

	@computed()
	get сurrentExtraStudents() {
		const value = this.studCart.getCurrentExtraStudents(parseInt(this.students.value()));
		return value > 0 ? value : 0;
	}

	@computed()
	get сurrentNewExtraStudents() {
		const value = this.studCart.getNewExtraStudents(parseInt(this.students.value()), this.currentStudentLimit);
		return value > 0 ? value : 0;
	}

	@computed()
	get monthsForCurrentStudents () {
		return this.studCart.monthsForCurrentStudents(this.getMaxExpirationDate(), this.getMinExpirationDate());
	}

	@computed()
	get datesForCurrentStudents () {
		const now = moment().endOf('day');
		let startDate = this.getMinExpirationDate();
		if (startDate.isBefore(now)) {
			startDate = now;
		}
		return `${startDate.format('MM/DD/YY')} - ${this.getMaxExpirationDate().format('MM/DD/YY')}`;
	}

	@computed()
	get ratePerCurrentStudent() {
		return (this.monthsForCurrentStudents / 12 * this.ratePerStudentFullYear).toFixed(2);
	}

	@computed()
	get monthsForNewStudents () {
		return this.studCart.monthsForNewStudents(this.getMaxExpirationDate());
	}

	@computed()
	get datesForNewStudents () {
		const now = moment().endOf('day');
		return `${now.format('MM/DD/YY')} - ${this.getMaxExpirationDate().format('MM/DD/YY')}`;
	}

	@computed()
	get ratePerNewStudent() {
		return (this.monthsForNewStudents / 12 * this.ratePerStudentFullYear).toFixed(2);
	}

	@computed()
	get price() {
		if (!this.students) {
			return printMoney(0);
		}

		let newStudentLimit = parseInt(this.students.value());
		if (newStudentLimit <= StudentCartModel.defaultStudensLimit) {
			return printMoney(0);
		}

		return printMoney(this.studCart.calculateFullPrice(newStudentLimit, this.getMaxExpirationDate(), this.getMinExpirationDate()));
	}


	@computed()
	get studentsMonths() {
		return this.studCart.monthsForNewStudents(this.getMinExpirationDate());
	}

	currentDate = ko.computed({
		read: () => {
			return moment().format('MM/DD/YY');
		},
		deferEvaluation: true,
	});

	expirationDate = ko.computed({
		read: () => {

			return this.getMinExpirationDate().format('MM/DD/YY');
		},
		deferEvaluation: true,
	});

	studentsKeyDown = (d, evt) => {
		if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
			evt.preventDefault();
		}
		if (evt.keyCode == 13 && this.nextButtonEnabled) {
			this.view.continue();
		}
		return true;
	};

	view = {
		help: () => {
			OldAlerts.bsalert('Help Me!!!');
		},
		cancel: () => {
			$(this).trigger('cancel');
		},
		continue: () => {
			if (this.students.value()) {
				this.studCart.currentStudentLimit = this.currentStudentLimit;
				this.studCart.newStudentLimit = parseInt(<any>this.students.value());
				this.studCart.studentYearPrice = this.ratePerStudentFullYear;
			}

			$(this).trigger('continue');
		},
	};

	events = {
		cancel: (callback) => {
			$(this).on('cancel', callback);
		},
		continue: (callback) => {
			$(this).on('continue', callback);
		},
	};
}
