import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {Text} from '@esgi/ui/typography';
import {
	ReportErrorNoStudents,
	ReportModalBodyStyled,
	StudentProgressReportContainer,
	StudentProgressReportWrapper,
} from './index.styled';
import {useEffect, useMemo, useState} from 'react';
import {useBehaviorSubject, useStream, useStreamEffect} from '@esgillc/ui-kit/utils';
import {RubricResultReportService} from '../../service';
import {TableLevelReport} from './components/table-level-report';
import {ReportFilters} from './components/table-level-report/components/filter';
import {StudentLevelReport} from './components/student-level-report';
import {StudentLevelReportModel} from '../../types/student-level-report-service-types';
import {FileType, StudentResult} from '../../types/table-level-report-service-types';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Reports} from '@esgi/main/kits/reports';
import {Group} from '@esgi/main/libs/store';
import {Filter} from '../../service/settings-service';

export const menuItems = [
	{text: 'PDF file', value: FileType.Pdf, description: 'per student or class'},
	{text: 'Excel file', value: FileType.Excel, description: 'per student or class'},
];

interface Props {
	onClose: () => void;
	service: RubricResultReportService;
}

export function PreviewDialog(props: Props) {
	const filter = useStream<Filter | null>(props.service.settingsService.filter$);
	const selectedGroup = useBehaviorSubject<Group | null>(props.service.selectedGroupId$);
	const unitName = useMemo(() => selectedGroup ? 'group' : 'class', [selectedGroup]);
	const reportErrors = useStream(props.service.reportErrors$);
	const rows = useStream<StudentResult[]>(props.service.rows$);
	const hasTestResults = useMemo(
		() =>
			rows?.some((row) =>
				row.criteriaResults.some((results) => results.periodResults.some((result) => result.value !== 'NT')),
			),
		[rows],
	);

	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, () => {
		setStudentLevelReportData(null);
		props.onClose();
	});

	const [studentLevelReportData, setStudentLevelReportData] = useState<StudentLevelReportModel | null>(null);

	useStreamEffect(props.service.studentLevelReportData$, (studentLevelReportData) => {
		setStudentLevelReportData(studentLevelReportData);
	});

	const clearStudentLevelReportData = () => {
		props.service.closeStudentLevelReport();
		setStudentLevelReportData(null);
	};

	useEffect(() => {
		props.service.initReport();
	}, [props.service]);

	return <Alert
		modalManagerRef={alertRef}
		colorConfig={alertColorsConfig.neutral}
		defaultOpen={false}
		css={{
			'& [data-alert-content]': {
				transform: 'translate(-50%, 0)',
				top: '40px',
			},
		}}
	>
		<Alert.Header
			withBacklight={false}
			onCloseIconClick={onClose}
			dataCy='rubric-report-header'
		>
			<Text data-cy='rubric-report-title' size='large'>Rubric Results Report</Text>
		</Alert.Header>
		<ReportModalBodyStyled>
			<StudentProgressReportWrapper>
				<StudentProgressReportContainer>
					<>
						<ReportFilters service={props.service} />
						{reportErrors?.noStudents && <ReportErrorNoStudents>
							<Text>There are no students in this {unitName}.</Text>
						</ReportErrorNoStudents>}
						{reportErrors?.noRubrics && <ReportErrorNoStudents>
							<Text data-cy='no-rubric-error'>There are no rubrics in this subject.</Text>
						</ReportErrorNoStudents>}
						{!reportErrors?.noRubrics && !reportErrors?.noStudents && (rows?.length === 0 || (!filter?.includeNotTested && !hasTestResults)) && <ReportErrorNoStudents>
							<Text>There is no test data for any student in this {unitName}.</Text>
						</ReportErrorNoStudents>}
						{!reportErrors?.noRubrics && !reportErrors?.noStudents && (filter?.includeNotTested || hasTestResults) && <>
							<TableLevelReport service={props.service} />
						{studentLevelReportData && (
							<StudentLevelReport
								service={props.service}
								report={studentLevelReportData}
								onClosed={() => clearStudentLevelReportData()}
							/>
						)}
						</>}
					</>
				</StudentProgressReportContainer>
			</StudentProgressReportWrapper>
			<ServiceLoader trackingService={props.service} fullscreen/>
		</ReportModalBodyStyled>
		<Alert.Footer>
			<Reports.DownloadButton
				menuItems={menuItems}
				onClick={(fileType) => props.service.onDownload(fileType as FileType)}
			/>
		</Alert.Footer>
	</Alert>;
}
