import React, {useMemo} from 'react';
import {isNull} from 'underscore';
import moment from 'moment/moment';
import {ProgressBar} from '@esgi/ui';
import {Box, FlexBox, InfoBlock} from '@esgi/ui/layout';
import {TestSection} from '../test-section';
import {
	AnsweredQuestionsLabel,
	InfoBlockLabel,
	InfoBlockItem,
} from './styled';
import {Text} from '@esgi/ui/typography';

interface Props {
	lastSessionDate?: string | Date | null;
	progressValue?: number;
	progressMaxValue?: number;
}

export function Resume({lastSessionDate, progressValue, progressMaxValue}: Props) {

	const lastSession = useMemo(() => {
		if (isNull(lastSessionDate)) {
			return {formattedDate: '-', formattedTime: '-'};
		}

		const formattedDate = moment(lastSessionDate).format('MM/DD/YYYY');
		const formattedTime = moment(lastSessionDate).format('hh:mm A');

		return {formattedDate, formattedTime};
	}, [lastSessionDate]);

	return <Box>
		<TestSection title='Current Session'>
			<InfoBlock>
				<InfoBlock.Group>
					<InfoBlockItem title='Date :'>
						<InfoBlockLabel size='small' font='mono' color='neutral40'>{lastSession?.formattedDate}</InfoBlockLabel>
					</InfoBlockItem>
					<InfoBlockItem title='Time :'>
						<InfoBlockLabel size='small' font='mono' color='neutral40'>{lastSession?.formattedTime}</InfoBlockLabel>
					</InfoBlockItem>
				</InfoBlock.Group>
			</InfoBlock>
		</TestSection>

		<TestSection title='Progress Session'>
			<ProgressBar value={progressValue} maxValue={progressMaxValue}>
				<ProgressBar.PointerIndicator isShowAnimation/>
				<ProgressBar.LineWithPointer/>
				<ProgressBar.AdditionalInfo isShowAnimation>
					<FlexBox direction='column' align='end'>
						<FlexBox align='center'>
							<AnsweredQuestionsLabel size='medium' bold font='mono' color='base'>
								{progressValue}
							</AnsweredQuestionsLabel>
							<Text size='xSmall' bold font='mono' color='lowContrast'>
								/ {progressMaxValue}
							</Text>
						</FlexBox>
						<Text size='small' font='mono' color='mediumContrast'>Questions Answered</Text>
					</FlexBox>
				</ProgressBar.AdditionalInfo>
			</ProgressBar>
		</TestSection>
	</Box>;
}
