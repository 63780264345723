import {useMemo} from 'react';
import {AnswerState} from '@esgi/core/enums';
import {Answer as AnswerType} from '../../../../types';
import {Answer} from './components/answer';
import {AnswerState as AnswerComponentState} from './components/answer/types';
import {CheckboxOptions, CheckedMode} from '@esgi/deprecated/elements';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../../../hooks';
import {useAnswerPanel} from './hooks';
import {AnswerPanelState} from './types';
import styles from './styles.module.less';

interface Props {
	statePanel: AnswerPanelState;
	answers: AnswerType[];
	answerState: AnswerState;
	title: string;
	setActive: (panelSate?: AnswerState) => void;
	onChange: (state: AnswerPanelState) => void;
	onNoteSave: (testSessionId: number, questionID: number, comment: string) => void;
	onNoteDelete: (testSessionId: number, questionID: number) => void;
	canEdit: boolean;
}

export function AnswerPanel (props: Props): JSX.Element {
	const {
		statePanel,
		answers,
		answerState,
		title,
		setActive,
		onChange,
		onNoteSave,
		onNoteDelete,
		canEdit,
	} = props;
	const service = useTestSessionDetailsService();
	const editMode = useBehaviorSubject(service.editMode$);
	const {state, setState, isActive} = useAnswerPanel(
		statePanel,
		answers,
		answerState,
		onChange,
	);

	const checkboxOptionsMode = useMemo<CheckedMode>(() => {
		if (state.selectedAnswers.length === 0) {
			return CheckedMode.None;
		}

		if (state.selectedAnswers.length === answers.length) {
			return CheckedMode.All;
		}

		if (state.selectedAnswers.length > 0) {
			return CheckedMode.Partially;
		}
	}, [state]);

	const onToggleAll = (checked: boolean) => {
		const newState = {...state};

		if (checked) {
			newState.selectedAnswers = answers.map(({questionId}) => questionId);
			newState.stateAnswers = state.stateAnswers.map((a) => ({...a, selected: true}));
			setActive(answerState);
		} else {
			newState.selectedAnswers = [];
			newState.stateAnswers = state.stateAnswers.map((a) => ({...a, selected: false}));
			setActive();
		}

		setState(newState);
	};

	const setAnswerState = (changes: AnswerComponentState, answerIndex: number, questionId: number) => {
		const states = state.stateAnswers.map((a, i) => {
			return (answerIndex === i) ? changes : a;
		});

		const newSelectedState = (changes.selected)
			? state.selectedAnswers.concat(questionId)
			: state.selectedAnswers.filter(s => s !== questionId);

		if (!isActive && changes.selected) {
			setActive(answerState);
		}

		if (isActive && !newSelectedState.length) {
			setActive();
		}

		setState({
			...state,
			stateAnswers: states,
			selectedAnswers: newSelectedState,
		});
	};

	let answersCount = 0;

	return (
		<div className={styles.questionPanel + ((isActive) ? ' active' : '')}>
			<div className={styles.checkboxOptions}>
				{editMode &&
					<CheckboxOptions
						disabled={answers.length === 0}
						checked={checkboxOptionsMode}
						onClick={(checked) => onToggleAll(checked)}
					/>
				}
				<label className={styles.questionTitle}>{title} ({answers.length})</label>
			</div>
			<div className={styles.answerList}>
				{state.stateAnswers && state.answers.map((dataAnswers, i) => (
					<div className={styles.dataAnswers} key={i}>
						{answerState !== AnswerState.NotTested &&
							<div className={styles.dataTitle}>
								{dataAnswers[0].testDate.format('MM-DD-YY')}
							</div>
						}
						{dataAnswers.map((answer) => {
							const index = answersCount;
							answersCount++;

							return (
								<Answer
									key={answer.id || answer.questionId}
									answerState={state.stateAnswers[index]}
									answer={answer}
									notTested={answerState === AnswerState.NotTested}
									selected={isActive && state.selectedAnswers.includes(answer.questionId)}
									onChange={(changedState) => setAnswerState(changedState, index, answer.questionId)}
									onNoteSave={(note) => onNoteSave(answer.sessionId, answer.questionId, note)}
									onNoteDelete={() => onNoteDelete(answer.sessionId, answer.questionId)}
									canEdit={canEdit}
								/>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
}
