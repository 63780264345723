import {observable} from '@esgi/deprecated/knockout';

export class OrderSummaryItem {
	@observable()
	public name: string;
	@observable()
	public amount: string;
	@observable()
	public quantity: string;
	@observable()
	public months: number;
}

export class PurchaseResponse {
	public transactionID: string;
}

export interface IsPaymentAvailableResponse {
	isPaymentAvailable: boolean;
	wasPaymentProcessed: boolean;
	quoteId: number;
}

export interface MakePaymentResponse {
	transactionID: string;
	orderId: number;
	email: string;
	fileId: number;
}
