import {Test} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/storage/subjects/test';
import {SubjectTab} from '@esgi/main/libs/store';

export {type Test};
export {TestScreenType as TestScreenTypeContract} from '@esgi/contracts/esgi/types/esgi.enums/test-screen-type';
export {AssignmentState as AssignmentStateContract} from '@esgi/contracts/esgi/types/esgi.assignments/enums/assignment-state';

export enum TestScreenType {
	None = 'None',
	OneToOne = 'OneToOne',
	SelfAssessment = 'SelfAssessment',
}

export type SubjectWithSelAssesTests = Record<SubjectTab['id'], Omit<SubjectTab, 'id'>>;
