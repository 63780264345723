import {StudentProfileTab} from '../../types';

type Item = {
	value: StudentProfileTab;
	label: string;
};

export const items: Item[] = [
	{
		value: StudentProfileTab.Profile,
		label: 'Profile',
	},
	{
		value: StudentProfileTab.Location,
		label: 'Location',
	},
	{
		value: StudentProfileTab.Information,
		label: 'Information',
	},
];