import {PropsWithChildren, useMemo, useState} from 'react';
import {SubjectSectionContext} from '../context/subject-section-context';
import {SubjectSectionContextValue} from '../context/types';
import {ExpandableSubjectPanel} from './expandable-subject-panel';

type Props = PropsWithChildren;

export function SubjectSectionRoot({children}: Props) {
	const [loaded, setLoaded] = useState(false);

	const value = useMemo<SubjectSectionContextValue>(
		() => ({
			loaded,
			setLoaded,
		}),
		[setLoaded, loaded],
	);

	return (
		<SubjectSectionContext.Provider value={value}>
			<ExpandableSubjectPanel
				loaded={loaded}
				dataCy='subjects-expandable-panel'
			>
				{children}
			</ExpandableSubjectPanel>
		</SubjectSectionContext.Provider>
	);
}
