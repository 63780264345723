import {Buttons} from '@esgillc/ui-kit/button';
import React from 'react';
import {useModal, Alert} from '@esgillc/ui-kit/modal';

type Props = {
    message: string,
    onClose: VoidFunction,
    onContactUs: VoidFunction,
}

export function ErrorAlert(props: Props) {
	const modal = useModal();
	return <Alert modalManagerRef={modal} unsafeDesiredZIndex={11000}>
        <Alert.Body>
            {props.message}
        </Alert.Body>
        <Alert.Footer>
            <Buttons.Text onClick={() => modal.current.close(props.onClose)}>CANCEL</Buttons.Text>
            <Buttons.Text onClick={() => modal.current.close(props.onContactUs)}>
                CONTACT US
            </Buttons.Text>
        </Alert.Footer>
    </Alert>;
}