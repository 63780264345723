import {PrintSettings, UnitDataSettings, DownloadOptions} from '../types';

export class GenerateService {
	public generateReport(
		unitDataSettings: UnitDataSettings,
		printSettings: PrintSettings,
	): DownloadOptions {
		const {id: testId, name: testName} = unitDataSettings.selectedTest;
		const {subjectID: subjectId, subjectType, name: subjectName} = unitDataSettings.selectedSubject;
		const {id: classId = 0, name: className = ''} = unitDataSettings.selectedClass || {};
		const {id: groupId = 0, name: groupName = ''} = unitDataSettings.selectedGroup || {};

		return {
			testId,
			testName,
			classId,
			className,
			groupId,
			groupName,
			subjectId,
			subjectType,
			subjectName,
			sortOptionMode: printSettings.sortOptionMode,
		};
	}
}
