import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {MouseEvent, forwardRef, useCallback} from 'react';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {Text} from '../../../typography';

type ContextMenuItemProps = DropdownMenu.DropdownMenuItemProps & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const ContextMenuItem = forwardRef<HTMLDivElement, ContextMenuItemProps>(
	({dataCy, disabled, onClick, ...props}, forwardedRef) => {
		const handleClick = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				if (disabled) {
					return;
				}

				onClick?.(event);
			},
			[disabled, onClick],
		);

		return (
			<Item
				data-cy={dataCy ?? 'ui-kit-context-menu-item'}
				onClick={handleClick}
				disabled={disabled}
				ref={forwardedRef}
				{...props}
			/>
		);
	},
);

const Item = styled(DropdownMenu.Item, {
	padding: 6,
	backgroundColor: '$vivid',
	boxSizing: 'border-box',
	userSelect: 'none',
	transition: 'backgroun-color .3s, color .3s',
	outline: 'none',
	appearance: 'none',
	display: 'flex',
	alignItems: 'center',
	overflow: 'hidden',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	color: '$base',

	'&:first-child': {
		borderTopRightRadius: 6,
		borderTopLeftRadius: 6,
	},

	'&:last-child': {
		borderBottomRightRadius: 6,
		borderBottomLeftRadius: 6,
	},

	'&:not(:last-child)': {
		borderBottomWidth: 0,
	},

	[`& > ${Text}`]: {
		color: 'currentColor',
	},

	'& > svg': {
		width: 16,
		height: 16,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	'&[data-highlighted]': {
		color: '$secondary',
		backgroundColor: '$secondaryBackground',
	},

	'&[data-disabled]': {
		color: '$muted',
		backgroundColor: '$background',
	},
});
