import {debounce} from '@esgi/deprecated/knockout';
import React from 'react';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isTouchDevice} from '@esgillc/ui-kit/utils';
import {useState} from '@esgi/deprecated/react';
import {SubjectModel} from '../../services/subjects-service/models';
import SubjectsService from '../../services/subjects-service/subjects-service';
import CreateSubject from './create-subject/create-subject';
import DistrictTray from './tray/district-tray';
import PersonalTray from './tray/personal-tray';
import SchoolTray from './tray/school-tray.ts';
import StockTray from './tray/stocik-tray';
import subjectStyles from './subject/subject.module.less';
import './component.less';

class State {
	selectedSubject? = new SubjectModel();
	scrollLeft?: number = 0;
	scrollWidth?: number = 0;
	offsetWidth?: number = 0;
	canScrollRight: boolean = false;
	canScrollLeft: boolean = false;
	loaded: boolean = false;
}

class Props {
	subjectsService: SubjectsService;
}

@useState(State)
export default class SubjectTabs extends React.Component<Props, State> {
	private container: HTMLElement;
	private rootElement: HTMLDivElement;
	private onDestroy$: Subject<void> = new Subject();

	public componentDidMount(): void {
		if (!isTouchDevice()) {
			fromEvent(window, 'resize')
				.pipe(takeUntil(this.onDestroy$))
				.subscribe(this.windowResized);
		}

		this.props.subjectsService.subjectsReloaded$()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => this.recalculateScrollPosition());

		this.props.subjectsService.selectedSubject$()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(subject => this.scrollToSubjectIfNotVisible(subject));
	}

	public render() {
		return <div className='subject-tabs' ref={r => this.rootElement = r}>
			{this.state.canScrollLeft &&
				<>
					<a href='#' className='control start' onClick={() => this.previousPage()}>
						<i className='fa fa-angle-double-left'/>
					</a>
					< a href='#' className='control back' onClick={() => this.back()}>
						<i className='fa fa-angle-left'/>
					</a>
				</>
			}
			<div className='subject-tabs-container' ref={(r) => this.container = r}>
				<DistrictTray subjectsService={this.props.subjectsService} dragStarted={() => this.dragStarted()}/>
				<SchoolTray subjectsService={this.props.subjectsService} dragStarted={() => this.dragStarted()}/>
				<PersonalTray subjectsService={this.props.subjectsService} dragStarted={() => this.dragStarted()}/>
				<StockTray subjectsService={this.props.subjectsService} dragStarted={() => this.dragStarted()}/>
				<CreateSubject/>
			</div>

			{this.state.canScrollRight &&
				<>
					<a href='#' className='control next' onClick={() => this.next()}><i
						className='fa fa-angle-right'/></a>
					<a href='#' className='control end' onClick={() => this.nextPage()}><i
						className='fa fa-angle-double-right'/></a>
				</>
			}
		</div>;
	}

	public componentWillUnmount(): void {
		this.onDestroy$.next();
	}

	@debounce()
	private recalculateScrollPosition() {
		const scrollWidth = this.container.scrollWidth;
		const offsetWidth = this.container.offsetWidth; // minus padding
		const canScrollLeft = this.state.scrollLeft > 0;
		const canScrollRight = this.state.scrollLeft + offsetWidth < scrollWidth;

		if (scrollWidth !== this.state.scrollWidth) {
			this.setState({scrollWidth: scrollWidth});
		}

		if (offsetWidth !== this.state.offsetWidth) {
			this.setState({offsetWidth: offsetWidth});
		}

		if (canScrollLeft !== this.state.canScrollLeft) {
			this.setState({canScrollLeft: canScrollLeft});
		}

		if (canScrollRight !== this.state.canScrollRight) {
			this.setState({canScrollRight: canScrollRight});
		}
	}

	private windowResized = () => {
		this.recalculateScrollPosition();
	};

	// when user starts dragging a subject and the position is on the farthest right edge
	// the position jumps on the widths of the subject to the left
	// I think because the DOM element is removed that makes scrollleft change
	// but when ghost element is inserted scrollleft does not change back
	// so we want the position back and set it manually to the value in the state
	// see ESGI-17112
	private dragStarted() {
		this.container.scrollLeft = this.state.scrollLeft;
	}

	private scrollToSubjectIfNotVisible(s: SubjectModel) {
		if (!s) {
			return;
		}
		let subject = this.rootElement && this.rootElement.querySelector(
			`.${subjectStyles.subjectTab}[data-id='${s.id}']`,
		) as HTMLDivElement;
		if (subject) {
			const leftPositionOfContainer = this.state.scrollLeft;
			const rightPositionOfContainer = this.state.scrollLeft + this.state.offsetWidth;

			// if subject is hidden to the left
			if (subject.offsetLeft < leftPositionOfContainer) {
				this.scrollTo(subject.offsetLeft);
			}

			// subject is hidden by the right
			if (rightPositionOfContainer < subject.scrollWidth + subject.offsetLeft + 50) {
				this.scrollTo(subject.offsetLeft);
			}
		}
	}

	private scrollTo(scrollLeft: number) {
		if (scrollLeft < 0) {
			scrollLeft = 0;
		}

		if (this.state.scrollLeft === scrollLeft) {
			return;
		}

		this.setState({scrollLeft: scrollLeft});

		this.recalculateScrollPosition();

		$(this.container).animate({scrollLeft: scrollLeft}, 400, () => {
			this.setState({scrollLeft: this.container.scrollLeft});
		});
	}

	private next() {
		const containerLeft = this.container.scrollLeft;
		this.scrollForward(containerLeft);
	}

	// this methods returns 3 adjacent tabs even though they may be in different trays
	// middle tab is always one that partially hidden by the right edge of the container
	private getSubjectsOnTheEdge(offsetLeft: number) {
		let prev: HTMLDivElement = null;
		let middle: HTMLDivElement = null;
		let next: HTMLDivElement = null;

		const trays = this.container.children;

		for (let i = 0; i < trays.length; i++) {
			const nodes = trays[i].children;

			for (let j = 0; j < nodes.length; j++) {
				const subjectDiv = nodes[j] as HTMLDivElement;

				if (subjectDiv.offsetLeft >= offsetLeft) {
					middle = subjectDiv;

					// previous tab
					if (j > 0) {
						prev = nodes[j - 1] as HTMLDivElement;
					} else {
						if (i > 0) { // it's not first tray
							const prevTray = trays[i - 1];
							prev = prevTray.children[prevTray.children.length - 1] as HTMLDivElement;
						}
					}

					// next tab
					if (j < nodes.length - 1) {
						next = nodes[j + 1] as HTMLDivElement;
					} else {
						if (i < trays.length - 1) { // not last tray
							const nextTray = trays[i + 1];
							next = nextTray.children[0] as HTMLDivElement;
						}
					}

					return {prev, middle, next};
				}
			}
		}

		const lastTray = trays[trays.length - 1];
		if (lastTray) {
			next = lastTray.children[lastTray.children.length - 1] as HTMLDivElement;
		}
		prev = trays[0].children[0] as HTMLDivElement;

		return {prev, middle, next};
	}

	private scrollForward(offsetLeft: number) {
		const {prev, middle, next} = this.getSubjectsOnTheEdge(offsetLeft);

		if (next) {
			return this.scrollTo(next.offsetLeft);
		}

		if (middle) {
			return this.scrollTo(middle.offsetLeft);
		}

		return;

		if (middle) {
			const leftWidth = prev ? prev.offsetWidth : 0;
			if (!prev || leftWidth + prev.offsetLeft > offsetLeft) {
				return this.scrollTo(middle.offsetLeft);
			}

		}

		if (next) {
			const middleWidth = middle.offsetWidth;
			if (middleWidth + middle.offsetLeft < offsetLeft + 5) {
				return this.scrollTo(next.offsetLeft);
			}
		}

		if (middle) {
			return this.scrollTo(middle.offsetLeft - 5);
		}
	}

	private scrollBack(offsetLeft: number) {
		const {prev, middle, next} = this.getSubjectsOnTheEdge(offsetLeft);
		console.log(prev, middle, next);
		if (prev) {
			return this.scrollTo(prev.offsetLeft);
		}

		if (middle) {
			return this.scrollTo(middle.offsetLeft);
		}
	}

	private back() {
		const containerLeft = this.container.scrollLeft;
		this.scrollBack(containerLeft);
	}

	private nextPage() {
		const offsetLeft = this.container.scrollLeft + this.container.offsetWidth;
		this.scrollForward(offsetLeft);
	}

	private previousPage() {
		const offsetLeft = Math.max(this.container.scrollLeft - this.container.offsetWidth, 0);
		this.scrollBack(offsetLeft);
	}
}
