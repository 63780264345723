import {Criteria, Description, Level, RubricAnswer, RubricModel} from './types';

interface AnswerToTestInfo {
	answer: RubricAnswer,
	level: Level,
	criteria: Criteria,
	description: Description,
}

export function getInfo(testModel: RubricModel, answers: RubricAnswer[]) {
	return {
		byAnswer: function (answer: RubricAnswer): AnswerToTestInfo {
			const criteria = testModel.criteriaModels.find(c => c.id === answer.criteriaID);
			const level = testModel.levelModels.find(l => l.score === answer.score);
			const description = testModel.descriptionModels.find(d => d.levelID === level.id && d.criteriaID === criteria.id);
			return {answer, criteria, description, level};
		},
		byDescription: function (descriptionID: number): AnswerToTestInfo {
			const description = testModel.descriptionModels.find(d => d.id === descriptionID);
			const level = testModel.levelModels.find(l => l.id === description.levelID);
			const criteria = testModel.criteriaModels.find(c => c.id === description.criteriaID);
			const answer = answers.find(a => a.criteriaID === criteria.id && a.score === level.score);
			return {answer, criteria, level, description};
		},
		byCriteria: function (criteriaID: number): AnswerToTestInfo {
			const criteria = testModel.criteriaModels.find(c => c.id === criteriaID);
			const answer = answers.find(a => a.criteriaID === criteria.id);

			if (answer) {
				const level = testModel.levelModels.find(l => l.score === answer.score);
				const description = testModel.descriptionModels.find(d => d.criteriaID === criteriaID && d.levelID === level?.id);
				return {criteria, answer, level, description};
			}

			return {criteria, answer, level: undefined, description: undefined};
		},
	};
}

export function buildAnswers(existAnswers: RubricAnswer[], testModel: RubricModel): RubricAnswer[] {
	let out = [];
	for (const criteria of testModel.criteriaModels) {
		let answer = existAnswers.find(a => a.criteriaID === criteria.id);
		if(!answer) {
			answer = {
				criteriaID: criteria.id,
				score: undefined,
				notes: '',
			} as RubricAnswer;
		}

		out.push(answer);
	}
	return out;
}

export function isDescriptionSelected(answers: RubricAnswer[], testModel: RubricModel, descriptionID: number): boolean {
	return !!getInfo(testModel, answers).byDescription(descriptionID).answer;
}

export function calcScore(answers: RubricAnswer[]): number {
	return answers.map(a => a.score).reduce((a, b) => (a || 0) + (b || 0), 0);
}

export function calcTotalScore(testModel: RubricModel): number {
	let levelsLength = testModel.levelModels.length;
	if(testModel.levelModels.some(l => l.score === 0)){
		--levelsLength;
	}
	return testModel.criteriaModels.length * levelsLength;
}

export function validateCriteria(criteriaID: number, testModel: RubricModel, answers: RubricAnswer[]): boolean {
	const {answer} = getInfo(testModel, answers).byCriteria(criteriaID);
	return answer.score !== undefined;
}
