import {Observable, BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';

export class FlashCardsHierarchyService extends BaseService {
	private _subjectsCache: { [key: number]: Models.SubjectModel[] } = {};
	private _hierarchyModel$: BehaviorSubject<HierarchyModel> = new BehaviorSubject<HierarchyModel>(null);
	private _selectedEntity$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor() {
		super();
	}

	private updateHierarchy(m: HierarchyModel) {
		this._hierarchyModel$.next(m);
		this._selectedEntity$.next(this.selectedEntityName);
	}

	private get selectedEntityName(): string {
		if (this.selectedStudent && this.selectedStudent.studentId !== 0) {
			return this.selectedStudent.fullName;
		} else {
			if (this.selectedGroupItem) {
				if (this.teacher) {
					return this.teacher.fullName + '\'s | ' + this.selectedGroupItem.fullName;
				} else {
					return '' + this.selectedGroupItem.fullName;
				}
			} else {
				return '';
			}
		}
	}

	public init(
		students: Models.Student[],
		selectedStudent: Models.Student,
		teacher: Models.User,
		groupItems: Models.GroupItem[],
		groupType: string,
		selectedGroupItem: Models.GroupItem,
		studentId: number, selectedSubjectId: number,
		subjects: Models.SubjectModel[],
	) {
		const m = new HierarchyModel();
		m.selectedStudent = selectedStudent;
		m.selectedGroupItem = selectedGroupItem;
		m.students = students;
		m.teacher = teacher;
		m.groupItems = groupItems;
		m.groupType = groupType;
		this.updateHierarchy(m);
		this._subjectsCache[studentId] = subjects;
		this.setSubjects(selectedSubjectId, studentId);
	}

	public updateHierarchyModel(m: HierarchyModel){
		m.selectedStudent = m.selectedStudent ?? this.selectedStudent;
		m.selectedSubject = m.selectedSubject ?? this.selectedSubject;
		m.selectedGroupItem = m.selectedGroupItem ?? this.selectedGroupItem;
		m.subjects = m.subjects.length > 0 ? m.subjects : this.subjects;
		m.students = m.students.length > 0 ? m.students : this.students;
		m.groupItems = m.groupItems.length > 0 ? m.groupItems : this.groupItems;
		m.groupType = m.groupType ?? this.groupType;
		m.teacher = m.teacher ?? this.teacher;

		switch (m.groupType) {
			case 'Class':
				m.selectedClassId = m.selectedGroupItem.id;
				break;
			case 'SpecialistGroup':
			case 'Group':
				m.selectedGroupId = m.selectedGroupItem.id;
			default:
				break;
		}

		this.updateHierarchy(m);
	}


	public setSubjects(selectedSubjectId: number, selectedStudentId: number) {
		let subjects = this._subjectsCache[selectedStudentId];

		if (!selectedSubjectId || selectedSubjectId === 0) {
			selectedSubjectId = this.selectedSubject?.id;
		}

		let selectedSubject = subjects.filter(s => s.id === selectedSubjectId)[0];

		if (!selectedSubject) {
			selectedSubject = this.subjects[0];
		}
		const m = new HierarchyModel();
		m.selectedSubject = selectedSubject;
		m.subjects = subjects;
		this.updateHierarchyModel(m);
	}

	public get hierarchy$(): Observable<HierarchyModel> {
		return this.completeOnDestroy(this._hierarchyModel$.asObservable());
	}

	public get selectedEntity$(): Observable<string> {
		return this.completeOnDestroy(this._selectedEntity$.asObservable());
	}

	public get selectedStudent() {
		return this._hierarchyModel$.value?.selectedStudent;
	}

	public get selectedSubject() {
		return this._hierarchyModel$.value?.selectedSubject;
	}

	public get selectedGroupItem() {
		return this._hierarchyModel$.value?.selectedGroupItem;
	}

	public get subjects() {
		return this._hierarchyModel$.value?.subjects;
	}

	public get students() {
		return this._hierarchyModel$.value?.students;
	}

	public get groupItems() {
		return this._hierarchyModel$.value?.groupItems;
	}

	public get groupType() {
		return this._hierarchyModel$.value?.groupType;
	}

	public get teacher() {
		return this._hierarchyModel$.value?.teacher;
	}

	public get selectedClassId() {
		return this._hierarchyModel$.value?.selectedClassId;
	}

	public get selectedGroupId() {
		return this._hierarchyModel$.value?.selectedGroupId;
	}

	public get subjectsCache() {
		return this._subjectsCache;
	}
}

export class HierarchyModel {
	selectedStudent: Models.Student;
	selectedSubject: Models.SubjectModel;
	selectedGroupItem: Models.GroupItem;
	subjects: Models.SubjectModel[] = [];
	students: Models.Student[] = [];
	groupItems: Models.GroupItem[] = [];
	groupType: string;
	teacher: Models.User;
	selectedGroupId: number;
	selectedClassId: number;
}
