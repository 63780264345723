import {SubjectModel} from '../../../../services/subjects-service/models';
import styles from '../subject.module.less';

type Props = {subject: SubjectModel, selected: boolean};

export function Lock({subject, selected}: Props): JSX.Element {
	let color;

	if (subject.level === 'District') {
		if (selected) {
			color = '#AA7DFF';
		} else {
			color = '#D7C3FC';
		}
	}

	if (subject.level === 'School') {
		if (selected) {
			color = '#03A9F4';
		} else {
			color = '#B3E5FC';
		}
	}

	return <div className={styles.lock}>
		<div className={styles.stick}>
			<svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M6.0126 0C3.20682 0 0.934769 2.27576 0.934769 5.07784V9.99629C0.934769 10.126 0.982952 10.252 1.06449 10.3521L1.0682 10.3558C1.17198 10.4781 1.32395 10.5448 1.48332 10.5448H1.97257C2.13195 10.5448 2.28391 10.4744 2.38769 10.3558C2.47294 10.2557 2.51742 10.1297 2.51742 10V5.07784C2.51742 4.14381 2.88065 3.26538 3.5404 2.60563C4.20015 1.94589 5.07857 1.58265 6.0126 1.58265C6.93921 1.58265 7.82875 1.94959 8.48479 2.60563C9.14454 3.26538 9.50777 4.14381 9.50777 5.07784V10.8784V12.4611V15.9785H11.0904V12.4611V5.07784C11.0941 2.27576 8.81837 0 6.0126 0Z'
					fill={color}/>
			</svg>
		</div>
		<div className={styles.body}>
			<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M7.0126 0.971085C3.13936 0.971085 0 4.11045 0 7.98369C0 11.8569 3.13936 14.9963 7.0126 14.9963C10.8858 14.9963 14.0252 11.8569 14.0252 7.98369C14.0252 4.11045 10.8858 0.971085 7.0126 0.971085ZM7.92068 8.35804V10.2594H6.10823V8.35804C5.75982 8.08747 5.53744 7.66493 5.53744 7.19051C5.53744 6.37509 6.19718 5.71163 7.01631 5.71163C7.83543 5.71163 8.49518 6.37138 8.49518 7.19051C8.49148 7.66493 8.26538 8.08747 7.92068 8.35804Z'
					fill={color}/>
			</svg>
		</div>
	</div>;
}
