import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const AssignmentNameInputBox = styled(Box, {
	position: 'relative',

	'& > svg': {
		position: 'absolute',
		top: 5,
		right: 5,
		width: 10,
		height: 10,

		'& path': {
			fill: '$tertiary',
		},
	},
});
