import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';

export const Wrapper = styled(Box, {
	padding: '8px 12px',
	border: '1px solid transparent',
	backgroundColor: '$vivid',
	borderRadius: '8px',
	marginTop: '8px',

	variants: {
		border: {
			true: {
				padding: '8px',
				border: '1px solid $border',
			},
		},
	},
});

export const Checkbox = styled(CheckboxUI, {
	justifyContent: 'flex-start',

	'& > span': {
		display: 'inline-flex',
	},
});

export const TagList = styled(GridBox, {
	gap: '6px',
	gridAutoFlow: 'column',
	justifyContent: 'flex-start',
	color: '$vivid',
	lineHeight: 1,
});

export const Tag = styled(Box, {
	minHeight: '16px',
	minWidth: '16px',
	padding: '2px 4px',
	borderRadius: '4px',
	textAlign: 'center',

	variants: {
		disabled: {
			true: {
				background: '$muted',
			},
		},
	},
});
