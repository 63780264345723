import {useMemo} from 'react';
import {TestSession} from '../../../../../types';

export function useSessionsData({
	testSessions,
	showDeleted,
	rowsCount,
}: {
	testSessions: TestSession[];
	rowsCount: number;
	showDeleted: boolean;
}) {
	const {sortedTestSessions, hasDeletedSessions} = useMemo(() => {
		const sortedTestSessions = [...testSessions].sort(
			(a, b) => new Date(b.testDate).getTime() - new Date(a.testDate).getTime(),
		);

		const totalSessionCount = testSessions.length;
		const filteredSessionCount = testSessions.filter(({isDeleted}) => !isDeleted).length;

		return {
			sortedTestSessions,
			hasDeletedSessions: totalSessionCount !== filteredSessionCount,
		};
	}, [testSessions]);

	const {visibleSessions, notVisibleSessionCount} = useMemo(() => {
		const totalSessions = showDeleted ? [...sortedTestSessions] : sortedTestSessions.filter((row) => !row.isDeleted);
		const visibleSessions = totalSessions.slice(0, rowsCount);

		return {
			visibleSessions,
			notVisibleSessionCount: totalSessions.length - visibleSessions.length,
		};
	}, [sortedTestSessions, showDeleted, rowsCount]);

	return {
		hasDeletedSessions,
		visibleSessions,
		notVisibleSessionCount,
	};
}
