import {useCallback, useEffect, useState} from 'react';
import {CreateTrackRequest, SchoolYearModel, TrackModel, UpdateTrackRequestParams} from '../../../../../types';
import {ViewMode} from './variant/view-mode';
import {AddEditMode} from './variant/add-edit-mode';
import {isNull} from 'underscore';
import {OnTrackClick} from './types';
import {Observable} from 'rxjs';

type Props = {
	tracks: TrackModel[];
	schoolYearTypes: SchoolYearModel[] | null;
	updateTrackRequest: (args: UpdateTrackRequestParams) => Observable<void>;
	activeUserTrackId: TrackModel['trackID'];
	isLinkedToDistrict: boolean;
	onOpenNoChangesConfirmation: (onCloseAlert: VoidFunction) => void;
	createTrackRequest: CreateTrackRequest;
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
};

export function MarkingPeriods({
	activeUserTrackId,
	tracks,
	schoolYearTypes,
	updateTrackRequest,
	isLinkedToDistrict,
	onOpenNoChangesConfirmation,
	createTrackRequest,
	setActiveTrackId,
}: Props) {
	const [visibleTrack, setVisibleTrack] = useState<TrackModel | null>(null);

	const setVisibleTrackById = useCallback(
		(trackId: TrackModel['trackID']) => {
			const activeTrack = tracks.find(({trackID}) => trackID === trackId) ?? tracks[0];

			setVisibleTrack(activeTrack ?? null);
		},
		[tracks],
	);

	useEffect(() => {
		setVisibleTrackById(activeUserTrackId);
	}, [activeUserTrackId]);

	useEffect(() => {
		setVisibleTrack(
			(currentVisibleTrack) =>
				tracks.find(({trackID}) => currentVisibleTrack?.trackID === trackID) ?? tracks[0] ?? null,
		);
	}, [tracks]);

	const onTrackClick = useCallback<OnTrackClick>(
		({track, openNoChangesConfirmation}) => {
			if (openNoChangesConfirmation) {
				onOpenNoChangesConfirmation(() => setVisibleTrack({...track}));

				return;
			}

			setVisibleTrack({...track});
		},
		[onOpenNoChangesConfirmation],
	);

	if (isNull(visibleTrack)) {
		return null;
	}

	if (isLinkedToDistrict) {
		return (
			<ViewMode
				activeUserTrackId={activeUserTrackId}
				onTrackClick={onTrackClick}
				tracks={tracks}
				visibleTrack={visibleTrack}
				setActiveTrackId={setActiveTrackId}
			/>
		);
	}

	return (
		<AddEditMode
			activeUserTrackId={activeUserTrackId}
			onTrackClick={onTrackClick}
			tracks={tracks}
			visibleTrack={visibleTrack}
			schoolYearTypes={schoolYearTypes}
			onOpenNoChangesConfirmation={onOpenNoChangesConfirmation}
			updateTrackRequest={updateTrackRequest}
			createTrackRequest={createTrackRequest}
			setVisibleTrack={setVisibleTrack}
			setActiveTrackId={setActiveTrackId}
		/>
	);
}
