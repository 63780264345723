import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import * as TestEntityEvents from 'api/entities/events/test';
import {Test as TestEntity} from 'api/entities/test';
import {Button} from '@esgi/deprecated/elements/buttons';
import {HideButton, StarButton} from 'modules/assets/tests/kits/test-details';
import moment from 'moment';
import React, {Suspense} from 'react';
import {TestScreenType, TestType} from '@esgi/core/enums';
import * as QuestionPanelEvents from 'shared/modules/test-details/components/question-panel/events';
import {TestBackgroundChangedEvent} from 'shared/modules/test-details/events';
import * as TestDetailsEvents from 'shared/modules/test-details/events';
import {lazyComponent} from '@esgi/core/react';
import {EventBusManager} from '@esgillc/events';
import {userStorage} from '@esgi/core/authentication';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {INotedAuthor} from '../../../services/landing-service';
import {QuestionModel, TestModel} from '../../../services/search-service';
import {SubjectItem} from '../../../services/subjects-service';
import {AddTestToSubject} from '../../add-tests-to-subject';
import {ContentAreaIcon} from '../../icons/content-area';
import {GradeLevelIcon} from '../../icons/grade-level';
import {Author} from '../../noted-author/author';
import QuestionSlider from '../../question-slider/question-slider';
import {TestScreenTypeIcon} from '../../icons/test-screen-type';
import {PreviewButton} from './components/preview-button/preview-button';
import {Loader} from '@esgillc/ui-kit/loader';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));

class State {
	constructor(s: TestModel) {
		this.testId = s.testID;
		this.name = s.name;
		this.starred = s.starred;
		this.hidden = s.hidden;
		this.createDate = s.createDate;
		this.gradeLevels = s.gradeLevels;
		this.contentArea = s.contentArea;
		this.creatorName = s.creatorName;
		this.stateStandard = s.stateStandard;
		this.numberOfQuestions = s.numberOfQuestions;
		this.testType = s.type === 'YN' ? TestType.YN : TestType.Score;
		this.description = s.description;
		this.lastTestDate = s.lastTestDate;
		this.draft = s.draft;
		this.isWhiteBackground = s.isWhiteBackground;
		this.currQuestionID = s.imageQuestionID || s.questions.map(s => s.questionID)[0];
		this.sliderQuestions = s.questions;
		this.district = s.district;
		this.school = s.school;
		this.author = s.author;
		this.testScreenTypes = s.testScreenTypes;
	}

	testId: number;
	name: string;
	starred: boolean;
	hidden: boolean;
	createDate: string;
	contentArea: string;
	gradeLevels: number[];
	creatorName: string;
	stateStandard: string;
	numberOfQuestions: number;
	testType: TestType;
	description: string;
	lastTestDate: string;
	draft: boolean;
	eyeHovered: boolean = false;
	isWhiteBackground: boolean;

	district: boolean;
	school: boolean;

	currQuestionID: number;
	sliderQuestions: QuestionModel[] = [];
	addTestToSubjectOpened: boolean = false;
	testDetailsOpened: boolean;
	author: INotedAuthor;
	testScreenTypes: TestScreenType[];
}

class Props {
	initModel: TestModel;
	mySubjects: SubjectItem[];
	showHidden: boolean;
	hidden: () => any;
	undrafted: () => any;
	isLoading: boolean;
}

export default class SearchResultCard extends React.Component<Props, State> {
	private currentUser = userStorage.get();
	private rootElement: HTMLDivElement;
	private readonly eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		this.state = new State(props.initModel);
	}

	render() {
		return <div className='card' ref={r => this.rootElement = r}>
			<div className='slider-wrapper'>
				<QuestionSlider testId={this.state.testId}
				                questions={this.state.sliderQuestions}
				                startImageID={this.state.currQuestionID}
				                isWhiteBackground={this.state.isWhiteBackground}/>
			</div>
			<div className='card-info'>
				<header>
					<div className='title'>
						<div className='top-row'>
							<div className='star-switch'>
								<StarButton isStarred={this.state.starred} onClick={() => this.state.starred ? this.unstar() : this.star()}/>
							</div>
							<div className='hide-switch'>
								<HideButton isHidden={this.state.hidden} onClick={() => this.state.hidden ? this.unhide() : this.hide()}/>
							</div>
							<a href='#' className='name' onClick={() => this.openTestDetails()}>
								{this.state.name}
							</a>
							{this.state.testDetailsOpened &&
								<Suspense fallback={<div/>}>
									<TestDetails
										testID={this.state.testId}
										firstName={this.currentUser.firstName}
										lastName={this.currentUser.lastName}
										userID={this.currentUser.userID}
										close={() => this.closeTestDetails()}
										subjectsForModal={this.props.mySubjects}
									/>
								</Suspense>
							}
						</div>
						<div>
							<div className='create-date'>
								{moment(this.state.createDate).format('MM-DD-YYYY')}
							</div>
						</div>
					</div>
					<div className='icons'>
						{this.state.school && <div className='school'>School</div>}
						<TestScreenTypeIcon testScreenTypes={this.state.testScreenTypes}/>
						{this.state.district && <div className='district'>District</div>}
						<ContentAreaIcon contentArea={this.state.contentArea}/>
						<GradeLevelIcon gradeLevels={this.state.gradeLevels}/>
					</div>
				</header>
				<main>
					<div className='author-wrapper'>
						<Author author={this.state.author}/>
					</div>
					<div className='test-info'>
						{this.state.stateStandard &&
							<div>
								<b>Standard: </b>
								<OnHoverTooltip message={this.state.stateStandard}>
									<span className='state-standard-title'>{this.state.stateStandard}</span>
								</OnHoverTooltip>
							</div>
						}
						<div>
							<b>Questions: </b> <span>{this.state.numberOfQuestions}</span>
						</div>
						<div>
							<b>Test Type: </b>
							<span>{this.state.testType === TestType.YN ? 'Yes/No' : 'Single Score'}</span>
						</div>
					</div>
					<div className='description' dangerouslySetInnerHTML={{__html: this.state.description}}>
					</div>
				</main>
				<footer>
					<div className='info-message'>
						{this.renderInfoMessage()}
					</div>
					<div className='buttons'>
						{!this.state.draft &&
							<Button
								onClick={() => this.openAddTestToSubject()}
								title='Add Test'
							/>
						}
						<PreviewButton
							testScreenTypes={this.state.testScreenTypes}
							testID={this.state.testId}
							testName={this.state.name}
							isWhiteBackground={this.state.isWhiteBackground}/>
					</div>
				</footer>
			</div>
			{this.renderAddToSubject()}
		</div>;
	}

	componentDidMount() {
		this.eventBus.subscribe(TestEntityEvents.Starred, (args) => this.starEventHandler(args));
		this.eventBus.subscribe(TestEntityEvents.Unstarred, (args) => this.unstarEventHandler(args));
		this.eventBus.subscribe(TestEntityEvents.Hidden, (args) => this.hideEventHandler(args));
		this.eventBus.subscribe(TestEntityEvents.Unhidden, (args) => this.unhideEventHandler(args));
		this.eventBus.subscribe(TestDetailsEvents.TestChanged, (args) => this.testUpdateEventHandler(args));
		this.eventBus.subscribe(TestDetailsEvents.TestBackgroundChangedEvent, (args) => this.testBackgroundEventHandler(args));
		this.eventBus.subscribe(QuestionPanelEvents.TestImageUpdated, (args) => this.testImageUpdatedEventHandler(args));
	}

	private renderInfoMessage() {
		if (this.state.lastTestDate) {
			return `Last used on ${moment(this.state.lastTestDate).format('MM-DD-YY')}`;
		}

		let subjects = this.props.mySubjects.map(s => s.tests.filter(t => t.testID === this.state.testId).map(t => {
			return {
				AddedAt: moment(t.addedAt),
				Name: s.name,
			};
		})).filter(t => t.length > 0);

		if (!subjects.length) {
			return null;
		}

		let flatten = [].concat.apply([], subjects);
		let sorted = flatten.sort((a, b) => a.AddedAt.isAfter(b.AddedAt)).reverse();

		let subject = sorted[0];

		return `Test added to ${subject.Name} on ${subject.AddedAt.format('MM-DD-YYYY')}`;
	}

	private unstarEventHandler(args: TestEntityEvents.Unstarred.Args) {
		if (args.testId !== this.state.testId) {
			return;
		}

		this.setState({starred: false});
		showSnackbarNotification(`You have unstarred  ${this.state.name}`);
	}

	private starEventHandler(args: TestEntityEvents.Starred.Args) {
		if (args.testId !== this.state.testId) {
			return;
		}

		this.setState({starred: true});
		showSnackbarNotification(`You have starred ${this.state.name}`);
	}

	private hideEventHandler(args: TestEntityEvents.Hidden.Args) {
		if (args.testId !== this.state.testId) {
			return;
		}

		if (!this.props.showHidden) {
			$(this.rootElement).fadeOut({
				duration: 1000, always: () => {
					this.delayedHideTest();
				},
			});
		} else {
			this.hideTest();
		}
		showSnackbarNotification(`You've hidden ${this.state.name}`);
	}

	private delayedHideTest() {
		// If Test Details modal is open, then postpone hiding test as it will close the modal.
		if (this.state.testDetailsOpened) {
			this.delayedHiddenStatus = true;
		} else {
			this.hideTest();
		}
	}

	private hideTest() {
		this.setState({hidden: true});
		this.props.hidden();
	}

	private unhideTest() {
		this.setState({hidden: false});
		showSnackbarNotification(`You've unhidden ${this.state.name}`);
	}

	private unhideEventHandler(args: TestEntityEvents.Unhidden.Args) {
		if (args.testId !== this.state.testId) {
			return;
		}

		this.unhideTest();
	}


	private testBackgroundEventHandler(args: TestBackgroundChangedEvent) {
		if (args.id !== this.state.testId) {
			return;
		}

		let newState = {...this.state};
		newState.isWhiteBackground = args.isWhiteBackground;
		newState.sliderQuestions = newState.sliderQuestions.map((x) => {
			let q = {...x};
			q.ticks = (new Date()).getTime();
			q.pregenerated = false;
			return q;
		});

		this.setState(newState);
	}

	private testImageUpdatedEventHandler(args: QuestionPanelEvents.TestImageUpdated.Args) {
		if (args.testID !== this.state.testId) {
			return;
		}
		this.setState({currQuestionID: args.questionID});
	}

	private testUpdateEventHandler(args: TestDetailsEvents.TestChanged.Args) {
		if (args.id !== this.state.testId) {
			return;
		}

		if (this.state.draft && !args.draft) {
			$(this.rootElement).fadeOut({
				duration: 1000, always: () => {
					this.props.undrafted();
				},
			});
		} else {
			this.setState({
				description: args.description,
				gradeLevels: args.gradeLevels,
				contentArea: args.contentArea,
				stateStandard: args.stateStandard,
				name: args.newName,
				numberOfQuestions: args.questionsNumber,
				testType: args.testType,
			});
		}
	}

	private unstar() {
		TestEntity.unstar(this.state.testId).subscribe();
	}

	private star() {
		TestEntity.star(this.state.testId).subscribe();
	}

	private delayedHiddenStatus: boolean = null;

	private hide() {
		this.delayedHiddenStatus = true;

		TestEntity.hide(this.state.testId).subscribe();
	}

	private unhide() {
		this.delayedHiddenStatus = false;
		TestEntity.unhide(this.state.testId).subscribe();
	}

	private openAddTestToSubject() {
		this.setState({addTestToSubjectOpened: true});
	}

	private renderAddToSubject() {
		if (!this.state.addTestToSubjectOpened) {
			return null;
		}

		return <>
			<Loader fullscreen show={this.props.isLoading}/>
			{!this.props.isLoading && <AddTestToSubject mySubjects={this.props.mySubjects}
															testName={this.state.name}
															testId={this.state.testId}
															close={() => this.setState({addTestToSubjectOpened: false})}/>}
		</>;
	}

	private eyeHovered() {
		this.setState({eyeHovered: true});
	}

	private eyeBlured() {
		this.setState({
			eyeHovered: false,
			hidden: this.delayedHiddenStatus === null ? this.state.hidden : this.delayedHiddenStatus,
		});
		this.delayedHiddenStatus = null;
	}

	private openTestDetails() {
		this.setState({testDetailsOpened: true});
	}

	private closeTestDetails() {
		this.setState({testDetailsOpened: false});
		if (this.delayedHiddenStatus) {
			this.hideTest();
			this.delayedHiddenStatus = null;
		}
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}
}
