import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Container = styled(Box, {
	textAlign: 'center',
	width: '100%',
	border: 'none',
	borderTop: '1px solid $neutral94',

	[`& > ${Text}`]: {
		fontWeight: 'bold',
		fontSize: '14px',
		lineHeight: '42px',
		color: '$base',
	},
});

export const List = styled(Box, {
	textAlign: 'start',
	fontSize: '12px',
	overflow: 'hidden',
	overflowWrap: 'break-word',
	padding: '0 10px',
	color: '$base',
});

export const Separator = styled(Box, {
	borderTop: '2px solid $neutral94',
});

export const Sup = styled(Text, {
	verticalAlign: 'top',
});
