import {useEffect, useMemo, useState, Suspense, Fragment} from 'react';
import {useService} from '@esgi/core/service';
import {Loader, ObservableLoader} from '@esgillc/ui-kit/loader';
import {
	Modal,
	CloseIcon,
	useCloseModal,
	useModal,
} from '@esgillc/ui-kit/modal';
import {lazyComponent} from '@esgi/core/react';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {debounceTime} from 'rxjs/operators';
import {IEPGoalFormService} from './services';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {Body} from './components/body';
import {Footer} from './components/footer';
import {ElementStatus} from '@esgillc/ui-kit/form';
import SaveAsDraftDialog from './components/dialogs/save-as-draft-dialog';
import SaveMyChangesDialog from './components/dialogs/save-my-changes-dialog';
import {getSelectedSchoolYearID} from '@esgi/core/authentication';
import {Subject} from 'shared/modules/test/test-session-details/types';
import styles from './styles.module.less';

interface Props {
	hierarchy: HierarchySnapshot;
	subject: Subject;
	testID?: number;
	globalSchoolYearID?: number,
	onClose: () => void;
}

const IepProgressReport = lazyComponent(() => import('modules/reports/iep-progress'));

export const IepGoalFormModal = (props: Props): JSX.Element => {
	const {id: subjectID, type: subjectType} = props.subject;
	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);
	const [loaded, setLoaded] = useState(false);
	const [showSaveAsDraftDialog, setShowSaveAsDraftDialog] = useState(false);
	const [showSaveMyChangesDialog, setShowSaveMyChangesDialog] = useState(false);
	const [showReportDialog, setShowReportDialog] = useState(false);
	const globalSchoolYearID = props.globalSchoolYearID || getSelectedSchoolYearID();

	const service = useService(IEPGoalFormService);
	const isLoading$ = useMemo(() => service.busy.pipe(debounceTime(1000)), []);

	useEffect(() => {
		service
			.initFilters(props.hierarchy, globalSchoolYearID, subjectID, subjectType)
			.subscribe(r => {
				const studentID = props.hierarchy.specialist.studentID || r.students[0]?.studentID;
				const testID = props.testID || r.subjectTests.find(s => s.subjectID === subjectID)?.tests[0].testID;
				service
					.init(props.hierarchy.districtID, studentID, testID, subjectID)
					.subscribe(r => setLoaded(true), props.onClose);
			});
	}, []);

	const onCloseClick = (): void => {
		const isNewGoal = !service.iepGoal$.value || service.iepGoal$.value.id === 0;
		if (isFormTouched()) {
			if (isNewGoal) {
				service.form.validate().subscribe((r) => {
					if (r.valid) {
						setShowSaveAsDraftDialog(true);
					} else {
						close();
					}
				});
			} else {
				setShowSaveMyChangesDialog(true);
			}
		} else {
			close();
		}
	};

	const isFormTouched = () => Object.values(service.form.controls).some(
		({status}) => status !== ElementStatus.untouched,
	);

	if (!loaded) {
		return <Loader show={!loaded} fullscreen/>;
	}

	return (
		<>
			{showSaveAsDraftDialog &&
				<SaveAsDraftDialog
					service={service}
					onClosed={close}
				/>
			}
			{showSaveMyChangesDialog &&
				<SaveMyChangesDialog
					service={service}
					onClosed={close}
				/>
			}
			{showReportDialog &&
				<Suspense fallback={<Fragment/>}>
					<IepProgressReport
						selected={[service.form.controls.test.value[0].testID]}
						studentId={service.form.controls.student.value[0].studentID}
						subjectId={service.form.controls.subject.value[0].id}
						hierarchy={props.hierarchy}
						onClose={() => setShowReportDialog(false)}
					/>
				</Suspense>
			}
			<Modal
				modalManagerRef={modal}
				className={styles.modal}
				containerClassName={styles.modalLayer}
				onCatchError={close}
			>
				<ObservableLoader observable={isLoading$} fullscreen/>
				<CloseIcon
					color='#666666'
					className={styles.closeIcon}
					onClick={onCloseClick}
				/>
				<Body
					service={service}
					hierarchy={props.hierarchy}
				/>
				<Footer
					service={service}
					onClose={props.onClose}
					onRunReport={() => setShowReportDialog(true)}
				/>
				<SnackbarLayout/>
			</Modal>
		</>
	);
};

export default IepGoalFormModal; // for lazy loading
