import {useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {join} from '@esgillc/ui-kit/utils';
import {ReportFormat, ReportType} from '../../../../models';
import styles from './styles.module.less';

interface Props {
	students: number;
	onDonwload: (format: ReportFormat, type: ReportType) => void;
}

export const Download = ({students, onDonwload}: Props) => {
	const [showMenu, setShowMenu] = useState(false);
	const onlyCurrentSession = students > 300;

	return (
		<div className={styles.download}>
			<Buttons.Icon
				className={styles.button}
				onClick={() => setShowMenu(!showMenu)}
			>
				<i className={join('fa fa-download', styles.icon)}/>
				Download
			</Buttons.Icon>
			<Menu
				className={styles.menu}
				show={showMenu}
				keepFocusInside
				onClickOutside={() => setShowMenu(false)}
				onEscPressed={() => setShowMenu(false)}
				onClick={() => setShowMenu(false)}
			>
				<MenuItem onSelect={() => onDonwload(ReportFormat.SingleFile, ReportType.Current)}>
					<b>One PDF file for current session</b>
					one file for all class
				</MenuItem>
				{!onlyCurrentSession &&
					<MenuItem onSelect={() => onDonwload(ReportFormat.SingleFile, ReportType.All)}>
						<b>One PDF file for all session</b>
						one file for all class
					</MenuItem>
				}
				<MenuItem onSelect={() => onDonwload(ReportFormat.Zip, ReportType.Current)}>
					<b>ZIP current session</b>
					Individual PDF files for each students in the class
				</MenuItem>
				{!onlyCurrentSession &&
					<MenuItem onSelect={() => onDonwload(ReportFormat.Zip, ReportType.All)}>
						<b>ZIP all session</b>
						Individual PDF files for each students in the class
					</MenuItem>
				}
			</Menu>
		</div>
	);
};
