import {Modal} from '@esgi/deprecated/ui-kit/modal';
import React, {useRef} from 'react';
import {ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {TrackConfirm} from 'modules/track-confirm/track-confirm';
import {Button} from '@esgi/deprecated/elements/buttons';
import styles from 'modules/track-confirm/styles.module.less';
import TrackConfirmService from 'modules/track-confirm/services/track-confirm-service';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ITrackModel} from 'pages/home/components/school-year-selector/api';

interface TrackConfirmModalProps {
	confirmed: (e?) => void;
	canceled?: () => void;
	trackChanged: (track: ITrackModel) => void;
}

export default function TrackConfirmModal(props: TrackConfirmModalProps) {
	const modalManagerRef: ModalManagerRefObject = useRef();
	const trackConfirmService = useService(TrackConfirmService);
	const isValid = useBehaviorSubject(trackConfirmService.isValid);

	const onClose = () => {
		modalManagerRef.current.close(props.canceled);
	};

	const onConfirm = () => {
		trackConfirmService.submit().subscribe(() => {
			modalManagerRef.current.close(props.confirmed);
		});
	};

	return <>
		<Modal modalManagerRef={modalManagerRef}>
			<Modal.Header>
				<div className={styles.title}>
					<span>Select Your Marking Periods</span>
					<a href='https://support.esgisoftware.com/hc/en-us/articles/209159566-Marking-Periods'
						 title='Help'
						 target='_blank' rel='noreferrer'>
						<i className='fa fa-question-circle'> </i>
					</a>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div><TrackConfirm trackConfirmService={trackConfirmService} trackChanged={props.trackChanged}/></div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => onClose()}>
					Cancel
				</Button>
				<Button className={styles.confirmButton} onClick={() => onConfirm()} disabled={!isValid}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	</>;
}
