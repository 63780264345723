import {ControlValue, FormControl, FormGroup} from '@esgillc/ui-kit/form';
import {StudentSortModel, TimezoneValue} from 'shared/modules/user-settings/types';


export type GeneralSectionFormType = FormGroup<{
 sortBy: FormControl<StudentSortModel[]>
 timeZone: FormControl<TimezoneValue[]>
 isCleverAccountLinked: FormControl<boolean>
 isOneClickAccountLinked: FormControl<boolean>

},
  ControlValue<{
    sortBy: FormControl<StudentSortModel[]>
    timeZone: FormControl<TimezoneValue[]>
    isCleverAccountLinked: FormControl<boolean>
    isOneClickAccountLinked: FormControl<boolean>
  }>>

export const createGeneralSectionForm = ():
  GeneralSectionFormType => new FormGroup({
	sortBy: new FormControl([], {validators: []}),
	timeZone: new FormControl([], {validators: []}),
	isCleverAccountLinked: new FormControl(false, {validators: []}),
	isOneClickAccountLinked: new FormControl(false, {validators: []}),
});
