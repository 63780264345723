import React from 'react';
import ImageGalleryModal from 'shared/modules/image-gallery/modal';
import {BoxType} from 'shared/right-panel/box/models';
import {Box} from 'shared/right-panel/box/box';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from 'shared/right-panel/box/box.module.less';
import {BoxModel} from 'pages/home/components/report-button/models';
import Api from 'pages/test-explorer/components/side-panels/api';
import {BoxTitle} from 'shared/right-panel/box/components/box-title/box-title';
import {join} from '@esgillc/ui-kit/utils';
import {BoxBody} from 'shared/right-panel/box/components/box-body/box-body';
import rightPanelStyles from './right-panel.module.less';

class State {
	boxes: BoxModel[];
	loaded: boolean;
	imageGalleryOpened: boolean = false;
}

class Props {
}

export class RelatedToolsPanel extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

	componentDidMount(): void {
		Api.init().subscribe(r => this.setState({boxes: r.boxes, loaded: true}));
	}

	getBoxState(level: BoxType) {
		if (this.state.loaded) {
			const result = this.state.boxes.find(x => x.level === level);
			if (result) {
				return result.open;
			}
		}

		return true;
	}

	render(): JSX.Element | false | null {
		return <>
			<Box>
				<BoxTitle opened={this.getBoxState(BoxType.RelatedTools)}
				          className={rightPanelStyles.boxTitle}
				          onOpenCloseChanged={() => this.openClose(BoxType.RelatedTools, !this.getBoxState(BoxType.RelatedTools))}>
					Related Tools
				</BoxTitle>
				<BoxBody opened={this.getBoxState(BoxType.RelatedTools)}>
					<a href='#' className='image-gallery-link'
					   onClick={() => this.setState({imageGalleryOpened: true})}>
						<i className={join(styles.icon, ' fa fa-image')}/>
						<OnHoverTooltip message='Find and upload images to use in your tests.'>
							<span className={rightPanelStyles.link}>Image Gallery</span>
						</OnHoverTooltip>
					</a>
				</BoxBody>
			</Box>
			{this.renderImageGallery()}
		</>;
	}

	renderImageGallery() {
		if (this.state.imageGalleryOpened) {
			return <ImageGalleryModal selectMode={false} onClosed={() => this.setState({imageGalleryOpened: false})}/>;
		}
	}

	openClose(type: BoxType, state: boolean) {
		const boxes = [...this.state.boxes];
		if (boxes.filter(t => t.level === type).length === 0) {
			boxes.push({level: type, open: state});
		} else {
			const box = boxes.find(t => t.level === type);
			box.open = state;
		}
		Api.boxOpenClose(type, state);

		this.setState({boxes: boxes});
	}
}
