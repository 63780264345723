import {AnswerState} from '@esgi/core/enums';
import {QuestionModel, QuestionResponseModel} from './models';
import {createQuestionImageUrlBuilder, createShareScreenImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {ShareQuestion} from './share-screen';


export function convertToQuestionModel(q: QuestionResponseModel, testID: number, restoreOldAnswerState?: boolean, continueSession?: boolean) {

	const urlBuilder = createQuestionImageUrlBuilder();
	const shareScreenUrlBuilder = createShareScreenImageUrlBuilder('');

	const question = new QuestionModel();
	question.directUrl = urlBuilder.large(q.questionID, testID, q.pregenerated, q.modifyTicks);
	question.fallbackUrl = urlBuilder.large(q.questionID, testID, false, q.modifyTicks);
	question.shareScreenUrl = shareScreenUrlBuilder.large(q.questionID, testID, q.pregenerated, q.modifyTicks);
	question.direction = q.directions;
	question.questionID = q.questionID;

	question.oldAnswerState = mapToEnum(q.oldAnswerState, AnswerState);
	question.answerState = mapToEnum(restoreOldAnswerState ? q.oldAnswerState : q.cacheAnswerState, AnswerState);
	question.comment = (restoreOldAnswerState || continueSession) ? q.comment : undefined;
	question.duration = q.duration;

	return question;
}

export function convertToShareQuestion(model: QuestionModel, index: number): ShareQuestion {
	return {
		index: index,
		id: model.questionID,
		direction: model.direction,
		url: model.shareScreenUrl,
	} as ShareQuestion;
}

export function mapToEnum<T>(value: string | number, targetEnum: T) {
	if(typeof value === 'string') {
		return targetEnum[value];
	} else {
		return value;
	}
}
