import {HierarchyMode} from 'modules/hierarchy/core/models';
import {UserType} from '@esgi/core/authentication';

export class DataModel {
	districtName: string;
	userName: string;
	sortBy: string;
	supportAddress: string;
	renewalUrl: string;
	gradeLevels: GradeLevelModel[];
	schools: SchoolModel[];
	specialists: SpecialistModel[];
	students: StudentModel[];
	allowUseCc: boolean;
	showSpecialistFilter: boolean;
	agreementLevelCode: string;
	canAddStudents: boolean;
}

export class GradeLevelModel {
	gradeLevelID: number;
	name: string;
}

export class SchoolModel {
	schoolID: number;
	name: string;
	teachers: TeacherModel[];
}

export class TeacherModel {
	teacherID: number;
	firstName: string;
	lastName: string;
	title: string;
	studentsMax: number;
	isExpired: boolean;
	classes: ClassModel[];
}

export class ClassModel {
	classID: number;
	name: string;
	groups: GroupModel[];
}

export class GroupModel {
	groupID: number;
	name: string;
}

export class SpecialistModel {
	userID: number;
	name: string;
	schoolID: number;
	userType: keyof typeof UserType;
	studentsMax: number;
	isExpired: boolean;
	groups: SpecialistGroupModel[];
}

export class SpecialistGroupModel {
	groupID: number;
	name: string;
}

export class StudentModel {
	constructor(
		public firstName: string,
		public lastName: string,
		public studentID: number,
		public studentIDN: string,
		public classIDs: number[],
		public groupIDs: number[],
		public gradeLevelID: number,
		public specialistGroupIDs: number[],
		public specialistGroupUserIDs: number[],
		public specialistGroupTypes: string[],
		public createDate: string,
		public creatorID?: number,
		public primaryTeacherID?: number,
		public schoolID?: number,
		public editable?: boolean,
	) {}
}


export class FilterModel {
	name: string;
	schoolID: number = 0;
	teacherID: number = 0;
	classID: number = 0;
	groupID: number = 0;
	gradeLevelID: number = 0;
	specialistUserID: number = 0;
	specialistGroupID: number = 0;
	specialistType: number = 0;
	mode: HierarchyMode;
}

export class SortModel {
	Column: string;
	Asc: boolean;

	constructor(column: string, asc: boolean) {
		this.Column = column;
		this.Asc = asc;
	}
}

export class StudentExportModel {
	firstName: string;
	lastName: string;
	school: string;
	teacher: string;
	classes: string;
	groups: string;
	specialists: string;
	specialistGroups: string;
	grade: string;
	studentIDN: string;
}

export class AssignToSpecialistModel {
	StudentIDs: number[];
	SpecialistGroupID: number;
}

export interface MoveSelectedStudentRequest {
	studentIDs: number[];
	schoolID: number;
	teacherID: number;
	classID: number;
	groupID: number;
}

export enum UnassignType {
	PrimaryTeacher,
	Specialist,
	Both,
}

export enum AssignType {
	PrimaryTeacher = 0,
	Specialist = 1
}

export interface RemoveModel {
	unassignedGroups: number[];
	unassignedUserIDs: number[];
}

export interface InitModel {
	schoolYears: YearModel[];
	currentGlobalSchoolYearID: number;
}

export interface YearModel {
	globalSchoolYearID: number;
	name: string;
}
