import React from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {ReportSettingsService} from '../../../../../../services/report-settings-service';

interface Props {
	service: ReportSettingsService;
}

export function DetailReportSettingsClasses({service}: Props) {
	const classes = useBehaviorSubject(service.classes);
	const selectedClass = useBehaviorSubject(service.selectedClass);
	return classes && classes.length > 0 && selectedClass && <>
		<div>
			<strong>Class: </strong>
			{classes.length > 1 && <>
				<Dropdown autoWidth={true}
				          setValue={(v) => service.changeHierarchy('classChanged', v)}
				          optionName={'itemName'}
				          value={selectedClass}>
					{classes.map(c => <Option key={c.itemID} value={c.itemID}>{c.itemName}</Option>)}
				</Dropdown>
			</>}
			{classes.length == 1 && <>
				<span>{classes[0].itemName}</span>
			</>}
		</div>
	</>;
}
