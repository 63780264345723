import {styled} from '@esgi/ui/theme';
import {GridBox, SelectableList, CounterBox} from '@esgi/ui/layout';
import {TestColorIndicator as TestColorIndicatorUI} from '@esgi/main/kits/common';

export {SelectableList} from '@esgi/ui/layout';

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto 1fr',
	gap: 12,
	padding: '7px 11px',
	borderRadius: '6px',
	border: '1px solid $vivid',
	color: '$neutral40',

	'&:not(:first-child)': {
		marginTop: '8px',
	},

	'& > svg': {
		width: '24px',
		height: '24px',
		alignSelf: 'start',
	},

	variants: {
		checked: {
			true: {
				borderColor: '$primaryMuted',

				'&:not(:last-child)': {
					borderBottomColor: '$primaryMuted',
				},

				'&:last-child': {
					borderBottomColor: '$primaryMuted',
				},

				'&:hover:not(:last-child)': {
					borderBottomColor: '$primaryMuted',
				},
			},
			false: {
				'&:hover': {
					borderColor: '$secondaryMuted',
				},
			},
		},
		disabled: {
			true: {
				color: '$muted',
				backgroundColor: '$vivid',
				cursor: 'default',
				pointerEvents: 'none',
			},
		},
	},
});

export const TestColorIndicator = styled(TestColorIndicatorUI, {
	height: '32px',
	padding: '6px 4px',
	marginRight: '12px',
});

export const Counter = styled(CounterBox, {
	color: '$neutral56',
	borderColor: '$mild',
	backgroundColor: '$vivid',
});

export const SelectableLisItemContent = styled(GridBox, {
	paddingTop: '2px',
	paddingBottom: '2px',
});
