import {PropsWithChildren, useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {AddMoreWidgetBackground, AddMoreWidgetsBox, AddMoreWidgetsContent, CardBox, CustomiseButton} from './styled';
import {Plus, Setup} from '@esgi/ui/icons';
import {generateRandomString} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';

export function WidgetCard(props: PropsWithChildren) {
	return <CardBox>
		{props.children}
	</CardBox>;
}

export function AddWidgetCard(props: {onClick: VoidFunction}) {
	const gradientID = useMemo(() => generateRandomString(), []);

	const gradientID2 = '1' + gradientID;

	return <AddMoreWidgetsBox onClick={props.onClick} dataCy='widgets-setup-card'>
		<AddMoreWidgetBackground>
			<svg width='274' height='136' viewBox='0 0 274 136' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect x='0.5' y='0.5' width='273' height='135' rx='11.5' fill={`url(#${gradientID})`}/>
				<rect x='0.5' y='0.5' width='273' height='135' rx='11.5' stroke={`url(#${gradientID2})`}/>
				<defs>
					<linearGradient id={gradientID} x1='2.04146e-06' y1='68' x2='60.7066' y2='68' gradientUnits='userSpaceOnUse'>
						<stop stopColor='#F5F5F5'/>
						<stop offset='1' stopColor='#F5F5F5' stopOpacity='0'/>
					</linearGradient>
					<linearGradient id={gradientID2} x1='2.04146e-06' y1='68' x2='64.8084' y2='68' gradientUnits='userSpaceOnUse'>
						<stop stopColor='#FCFCFC'/>
						<stop offset='1' stopColor='#FCFCFC' stopOpacity='0'/>
					</linearGradient>
				</defs>
			</svg>
		</AddMoreWidgetBackground>
		<AddMoreWidgetsContent>
			<Text color='lowContrast' size='medium'>Add more widgets</Text>
			<CustomiseButton><Plus/></CustomiseButton>
		</AddMoreWidgetsContent>
	</AddMoreWidgetsBox>;
}

export function AddWidgetCardCompact(props: {onClick: VoidFunction}) {
	const gradientID = useMemo(() => generateRandomString(), []);

	const gradientID2 = '1' + gradientID;

	return <AddMoreWidgetsBox onClick={props.onClick} compact dataCy='widgets-setup-card'>
		<AddMoreWidgetBackground compact>
			<svg width='274' height='56' viewBox='0 0 274 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect x='0.5' y='0.5' width='273' height='55' rx='11.5' fill={`url(#${gradientID})`}/>
				<rect x='0.5' y='0.5' width='273' height='55' rx='11.5' stroke={`url(#${gradientID2})`}/>
				<defs>
					<linearGradient id={gradientID} x1='2.04146e-06' y1='28' x2='60.7066' y2='28'
					                gradientUnits='userSpaceOnUse'>
						<stop stopColor='#F5F5F5'/>
						<stop offset='1' stopColor='#F5F5F5' stopOpacity='0'/>
					</linearGradient>
					<linearGradient id={gradientID2} x1='2.04146e-06' y1='28' x2='64.8084' y2='28'
					                gradientUnits='userSpaceOnUse'>
						<stop stopColor='#FCFCFC'/>
						<stop offset='1' stopColor='#FCFCFC' stopOpacity='0'/>
					</linearGradient>
				</defs>
			</svg>
		</AddMoreWidgetBackground>
		<FlexBox css={{padding: 12}} align='center' justify='start'>
			<CustomiseButton><Plus/></CustomiseButton>
			<Text css={{paddingLeft: 16}} color='lowContrast' size='medium'>Add widgets</Text>
		</FlexBox>
	</AddMoreWidgetsBox>;
}

export function CustomizeWidgetsCard(props: { onClick: VoidFunction }) {
	const gradientID = useMemo(() => generateRandomString(), []);

	const gradientID2 = '1' + gradientID;

	return <AddMoreWidgetsBox onClick={props.onClick} dataCy='widgets-setup-card'>
		<AddMoreWidgetBackground>
			<svg width='274' height='136' viewBox='0 0 274 136' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect x='0.5' y='0.5' width='273' height='135' rx='11.5' fill={`url(#${gradientID})`}/>
				<rect x='0.5' y='0.5' width='273' height='135' rx='11.5' stroke={`url(#${gradientID2})`}/>
				<defs>
					<linearGradient id={gradientID} x1='2.04146e-06' y1='68' x2='60.7066' y2='68'
					                gradientUnits='userSpaceOnUse'>
						<stop stopColor='#F5F5F5'/>
						<stop offset='1' stopColor='#F5F5F5' stopOpacity='0'/>
					</linearGradient>
					<linearGradient id={gradientID2} x1='2.04146e-06' y1='68' x2='64.8084' y2='68'
					                gradientUnits='userSpaceOnUse'>
						<stop stopColor='#FCFCFC'/>
						<stop offset='1' stopColor='#FCFCFC' stopOpacity='0'/>
					</linearGradient>
				</defs>
			</svg>
		</AddMoreWidgetBackground>
		<AddMoreWidgetsContent>
			<Text color='lowContrast' size='medium'>Customize widgets</Text>
			<CustomiseButton><Setup/></CustomiseButton>
		</AddMoreWidgetsContent>
	</AddMoreWidgetsBox>;
}