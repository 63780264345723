import React from 'react';
import {
	IBoxInfo, IPreAssessModel,
	ISpecialistGroupBox,
	IStudentBox,
} from '../core/api';
import {withGroupID, withStudent, withUserID} from '../core/builders/pre-assess';
import {BoxType} from '../core/models';
import {PreAssessHierarchySnapshot} from '../models';
import {Group} from './boxes/group';
import {Student} from './boxes/student';
import {User} from './boxes/user';

export class PreAssessHierarchy extends React.Component<Props, State> {

	render() {
		return <div className='hierarchy'>
      {this.renders.users()}
      {this.renders.groups()}
      {this.renders.students()}
    </div>;
	}

	renders = {
		groups: () => {
			return <Group
        boxes={this.props.boxes}
        groups={this.props.groups}
        selectedID={this.props.snapshot.groupID}
        userID={this.props.snapshot.userID}
        onOpenCloseChanged={this.props.onOpenCloseChanged}
        onSelected={(id, callback) => this.on.selected.group(id, callback)}/>;
		},
		students: () => {
			return <Student
        boxes={this.props.boxes}
        userID={this.props.snapshot.userID}
        selectedID={this.props.snapshot.studentID}
        groupID={this.props.snapshot.groupID}
        groups={this.props.groups}
        students={this.props.students}
        onOpenCloseChanged={this.props.onOpenCloseChanged}
        onSelected={(id, cb) => this.on.selected.student(id, cb)}
      />;
		},
		users: () => {
			return <User
        boxes={this.props.boxes}
        selectedID={this.props.snapshot.userID}
        preAssesses={this.props.users}
        onSelected={this.on.selected.user}
        onOpenCloseChanged={this.props.onOpenCloseChanged} />;
		},
	};

	on = {
		selected: {
			group: (id: number, cb: () => void) => {
				this.props.onChanged(withGroupID(this.props.snapshot, id), cb);
			},
			student: (id: number, cb: () => void) => {
				const s = this.props.students.items.find(s => s.studentID === id);
				const h = withStudent(this.props.snapshot, s);
				this.props.onChanged(h, cb);
			},
			user: (id: number, cb: () => void) => {
				let h = withUserID(this.props.snapshot, id);

				const groups = this.props.groups.items.filter(t => t.userID === id);
				if (groups.length) {
					h = withGroupID(h, groups[0].groupID);
				}

				this.props.onChanged(h, cb);
			},
		},
	};
}

export class State {

}

export class Props {
	boxes: IBoxInfo[];
	snapshot: PreAssessHierarchySnapshot;
	users: IPreAssessModel[];
	groups: ISpecialistGroupBox;
	students: IStudentBox;

	onChanged: (item: PreAssessHierarchySnapshot, cb: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
	onDragStart?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	onDragEnd?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	scheduledIDs?: Array<number>;
}
