import {SelectableList} from '@esgi/ui/layout';
import {getListValue, SelectableEditableItem} from '../../../../kit';
import {SubjectLevel, SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {SubjectName} from '../subject-name';

type Props = {
	selectedSubjectId: SubjectTab['id'] | null;
	onSubjectEdit: (subjectId: SubjectTab['id']) => void;
	subjects: SubjectTab[];
};

export function SelectableListEditable({selectedSubjectId, onSubjectEdit, subjects}: Props) {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedSubjectId)}>
				<SelectableList.Group>
					{subjects.map(({name, level, id, type, hidden}) => {
						const canEdit = level === SubjectLevel.Teacher && type === SubjectType.Personal;
						const handleSubjectClick = () => onSubjectEdit(id);

						const children = <SubjectName subjectLevel={level} subjectName={name} hidden={hidden} />;
						const value = String(id);

						if (canEdit) {
							return (
								<SelectableEditableItem
									key={id}
									onItemClick={handleSubjectClick}
									value={value}
									setTextCurrentColor={!hidden}
								>
									{children}
								</SelectableEditableItem>
							);
						}

						return (
							<SelectableList.Item key={id} forceSelect={false} withActiveBackground={false} value={value}>
								{children}
							</SelectableList.Item>
						);
					})}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
