import {useMemo} from 'react';
import {useUser} from '@esgi/core/authentication';
import {TestType} from '@esgi/core/enums';
import {ChartBlockModel} from '../../../../types';
import {TestModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/pie-charts/models/test-model';
import {BaseCard} from './base-card';
import {Text} from '@esgi/ui/typography';
import {LegendItem, LegendBox} from './card.styled';

export interface Props {
	test: TestModel;
	chartBlockModel: ChartBlockModel;
	printInColor: boolean;
	totalStudentCount: number;
}

export default function CombineCard({
	test,
	chartBlockModel,
	printInColor,
	totalStudentCount,
}: Props) {
	const currentUser = useUser();
	const {testResultsCorrectVerbiage} = currentUser;
	const withLegend = chartBlockModel.combinedTestResult.studentsTested > 0;
	const scoreTitle = useMemo(() => {
		if (test.type.toString() === TestType[TestType.YN]) {
			return `${testResultsCorrectVerbiage} Answers`;
		}

		if (test.type.toString() === TestType[TestType.Score]) {
			return 'Score';
		}

		if (test.type.toString() === TestType[TestType.Rubric]) {
			return 'Avg. Score';
		}
	}, [test.type]);

	return (
		<BaseCard
			testName={test?.name}
			chartBlockModel={chartBlockModel}
			isNotTested={chartBlockModel.combinedTestResult.studentsTested === 0}
			withLegend={withLegend}
			printInColor={printInColor}
		>
			{withLegend &&
				<>
					<LegendItem type='info'>
						<LegendBox>
							<Text data-cy='student-tested-title' size='small' font='mono'>Students Tested</Text>
						</LegendBox>
						<LegendBox data-cy='students-tested'>
							<Text data-cy='students-tested-amount' size='medium'>{chartBlockModel.combinedTestResult.studentsTested}/{totalStudentCount}</Text>
						</LegendBox>
					</LegendItem>
					<LegendItem type='info'>
						<LegendBox>
							<Text data-cy='score-title' size='small' font='mono'>{scoreTitle}</Text>
						</LegendBox>
						<LegendBox data-cy='correct-answers'>
							<Text data-cy='correct-amount' size='medium' bold color='neutral24'>{chartBlockModel.combinedTestResult.correctAnswers}/{chartBlockModel.combinedMaxScore}</Text>
						</LegendBox>
					</LegendItem>
				</>
			}
		</BaseCard>
	);
}
