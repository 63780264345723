import {styled} from '@esgi/ui/theme';
import {CounterBox, GridBox, SelectableList} from '@esgi/ui/layout';

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	gridTemplateColumns: 'auto auto',
	padding: '6px 6px 6px 10px',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: 'max-content',
});

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',

	variants: {
		isListOpen: {
			true: {
				'&:not(:last-child)': {
					marginBottom: 18,
				},
			},

			false: {
				'&:not(:last-child)': {
					marginBottom: 6,
				},
			},
		},
	},
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	variants: {
		defaultCursor: {
			true: {
				cursor: 'default',
			},
		},
	},
});

export const SelectableListTriggerContent = styled(GridBox, {
	alignItems: 'center',
	gap: 6,
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content',
});

export const SelectableLisItemContent = styled(GridBox, {
	paddingTop: 2,
	paddingBottom: 2,
});

export const Counter = styled(CounterBox, {
	alignSelf: 'start',
	marginTop: 4,
});