import {IntroductionBlockStyled, Wrapper, TitleStyled, TextSmall, FlexBlock, TitleAlertStyled} from './index.styled';
import {SelectStudentCard} from './components/select-students';
import React from 'react';
import {AddSelfAssessCard} from './components/add-self-assess';
import {AddStudentCredentialsCard} from './components/add-student-creds';
import {PostToStudentCard} from './components/post-to-students';
import {Text} from '@esgi/ui/typography';

interface Props {
	alertMode: boolean;
}

export function IntroductionBlock({alertMode}: Props) {
	return <Wrapper isAlert={alertMode}>
		{alertMode
			? <TitleAlertStyled direction='column'>
				<TextSmall size='small'>Create a group of self-assess tests and assign to one or many students</TextSmall>
			</TitleAlertStyled>
			: <TitleStyled direction='column'>
			<Text size='large'>How to create an assignment</Text>
			<TextSmall size='small'>Create a group of self-assess tests and assign to one or many students</TextSmall>
		</TitleStyled>}
		<IntroductionBlockStyled isAlert={alertMode}>
			<FlexBlock>
				<SelectStudentCard />
				<AddSelfAssessCard />
			</FlexBlock>
			<FlexBlock>
				<AddStudentCredentialsCard />
				<PostToStudentCard />
			</FlexBlock>
		</IntroductionBlockStyled>
	</Wrapper>;
}