import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Item = styled(GridBox, {
	padding: '0 12px',
	'& svg>path': {
		fill: '$negative',
	},
	gap: 8,
	gridAutoFlow: 'column',
	justifyContent: 'start',
	alignItems: 'center',
});

export const Title = styled(GridBox, {
	justifyContent: 'center',
	alignItems: 'center',
	padding: '20px 0 12px 0',
	margin: '0 12px',
	borderBottom: '1px solid $mild',
});

export const Container = styled(GridBox, {
	gap: 12,
	width: '100%',
});