import {BehaviorSubject, combineLatest, filter, Observable, of, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2TeachersPagesReportsRubricResultsController} from '@esgi/contracts/esgi';
import {
	ChartCriteriaResult,
	CriteriaNoteModel,
	NotesModel,
	StudentLevelChartModel,
	StudentLevelReportModel,
	StudentLevelTableModel,
} from '../types/student-level-report-service-types';
import {map, mergeMap} from 'rxjs/operators';
import {isEmpty, isNull} from 'underscore';
import {Entity, FileTypeStudentLevel, StudentResult} from '../types/table-level-report-service-types';
import {buildChart, ChartSeria} from '../utils/build-chart';
import {SettingsService} from './settings-service';
import moment from 'moment/moment';
import {InitReportResponse, MarkingPeriod, NotTested} from './types';
import {getUser, StudentSort} from '@esgi/core/authentication';

export class StudentLevelService extends BaseService {
	public showNotes$ = new BehaviorSubject<boolean>(false);
	public showSummary$ = new BehaviorSubject<boolean>(false);
	public showInColor$ = new BehaviorSubject<boolean>(false);
	public studentLevelLoader$ = new BehaviorSubject<boolean>(false);
	public reportData$ = new BehaviorSubject<StudentLevelReportModel | null>(null);
	public downloadFilter$ = new BehaviorSubject<any>(null);

	private notesCache: NotesModel[] = [];

	constructor(
    private controller: V2TeachersPagesReportsRubricResultsController,
    private selectedStudentID: BehaviorSubject<number>,
    private studentLevelReportData$: Observable<StudentLevelReportModel | null>,
		private initData$: BehaviorSubject<InitReportResponse>,
    private settingsService: SettingsService) {
		super();

		this.completeOnDestroy(this.studentLevelReportData$)
			.subscribe((data) => this.reportData$.next(data));

		this.completeOnDestroy(this.initData$)
			.subscribe((data) => {
				this.showSummary$.next(data?.filter?.showSummary);
				this.showNotes$.next(data?.filter?.showNotes);
				this.showInColor$.next(data?.filter?.showInColor);
			});

		this.completeOnDestroy(combineLatest([this.settingsService.filter$, this.showNotes$, this.showInColor$, this.showSummary$]))
			.subscribe(([filter, showNotes, showInColor, showSummary]) => {
				if(!isNull(filter)) {
					this.downloadFilter$.next({
						showBaseline: filter.showBaseline,
						displayZeroIfNotTested: filter.displayZeroIfNotTested === NotTested.Zero,
						showCurrentMarkingPeriod: filter.showCurrentMarkingPeriod === MarkingPeriod.Current,
						carryScoresForward: filter.carryScoresForward,
						showNotes,
						showInColor,
						showSummary,
					});
				}
			});
	}

	public students$ = this.completeOnDestroy(this.studentLevelReportData$).pipe(
		map((report) => {
			if (isNull(report)) {
				return [];
			}

			const allOption: Entity = {
				id: 0,
				value: 'All',
			};

			const reportResultsOptions: Entity[] = report.results.map(({studentID, firstName, lastName}) => ({
				id: studentID,
				value: `${firstName} ${lastName}`,
			}));

			const options: Entity[] = [allOption, ...reportResultsOptions];

			return options;
		}),
	);

	public selectedStudent$ = this.completeOnDestroy(combineLatest([this.selectedStudentID, this.students$])).pipe(
		map(([selectedStudentID, students]) => {
			if (isNull(selectedStudentID) || !students.length) {
				return null;
			}

			return students.find(({id}) => id === selectedStudentID);
		}),
	);

	public charts$: Observable<StudentLevelChartModel[] | null> = this.completeOnDestroy(
		combineLatest([this.selectedStudentID, this.studentLevelReportData$, this.showInColor$]),
	).pipe(
		map(([selectedStudentID, report, showInColor]) => {
			if (isNull(report) || isNull(selectedStudentID)) {
				return null;
			}

			const studentResults =
        selectedStudentID === 0
        	? report.results
        	: report.results.filter(({studentID}) => studentID === selectedStudentID);

			return studentResults.map(({criteriaResults, studentID, firstName, lastName}) => {
				const chartCriteriaResults = criteriaResults
					.filter(({criteriaName}) => criteriaName !== 'Score')
					.map(({periodResults, criteriaName}) => {
						const lastResult = [...periodResults].reverse().find(({testSessionID}) => testSessionID !== 0);

						const chartCriteriaResult: ChartCriteriaResult = {
							name: criteriaName,
							score: parseInt(lastResult?.value ?? '0'),
						};

						return chartCriteriaResult;
					});

				const series: ChartSeria[] = chartCriteriaResults.map(({name, score}) => {
					return {
						name,
						y: score,
						color: showInColor ? '#51C3F2' : '#BDBDBD',
					};
				});

				const maxScore = report.maxLevel * chartCriteriaResults.length;
				const score = chartCriteriaResults.reduce((sum, {score}) => sum + score, 0);

				const studentLevelChartModel: StudentLevelChartModel = {
					studentID,
					firstName: firstName,
					lastName: lastName,
					chart: buildChart(series, score, maxScore, report.maxLevel),
				};

				return studentLevelChartModel;
			});
		}),
		filter((chartModel) => !isNull(chartModel)),
		map((studentChartModelList) => {
			return studentChartModelList.sort((a, b) => {
				const currentUser = getUser();
				if (isNull(currentUser) || currentUser.studentSort === StudentSort.FirstName) {
					return a.firstName.localeCompare(b.firstName);
				}
				if (currentUser.studentSort === StudentSort.LastName) {
					return a.lastName.localeCompare(b.lastName);
				}
				return 0;
			}).map(x => ({studentID: x.studentID, chart: x.chart}));
		})
	);

	public notes$: Observable<NotesModel[]> = this.completeOnDestroy(
		combineLatest([this.selectedStudentID, this.studentLevelReportData$, this.showNotes$, this.showSummary$]),
	).pipe(
		mergeMap(([selectedStudentID, report, showNotes, showSummary]) => {
			if (isNull(report) || isNull(selectedStudentID) || !(showNotes || showSummary)) {
				return [];
			}

			const studentResults =
        selectedStudentID === 0
        	? report.results
        	: report.results.filter(({studentID}) => studentID === selectedStudentID);

			const lastTestSessionIDs = this.getLastTestSessionIDs(studentResults);

			if (!lastTestSessionIDs.length) {
				return [];
			}

			if (
				studentResults.every((studentResult) =>
					this.notesCache.some(({studentID}) => studentID === studentResult.studentID),
				)
			) {
				return of(
					selectedStudentID === 0
						? this.notesCache
						: this.notesCache.filter(({studentID}) => studentID === selectedStudentID),
				);
			}

			this.studentLevelLoader$.next(true);
			return this.controller.studentLevelNotes({testSessionIDs: lastTestSessionIDs, sessionGuid: report.sessionGuid})
				.pipe(
					map((response) => {
						this.notesCache = studentResults
							.map((studentResult) => {
								const studentNotes = response.value.notes.find(({studentID}) => studentID === studentResult.studentID);

								if (!studentNotes) {
									const notesModel: NotesModel = {
										studentID: studentResult.studentID,
										criteriaNotes: [],
										summaryNotes: '',
									};

									return notesModel;
								}

								const {criteriaNotes, summaryNotes} = studentNotes;

								const result = criteriaNotes
									.map((criteriaNote) => {
										const criteria = studentResult.criteriaResults.find(
											(criteriaResult) => criteriaResult.criteriaID === criteriaNote.criteriaID,
										);

										if (!criteria) {
											throw new Error('criteria is undefined');
										}

										const criteriaNoteModel: CriteriaNoteModel = {
											notes: criteriaNote.notes,
											criteriaName: criteria.criteriaName,
										};

										return criteriaNoteModel;
									})
									.filter(({notes}) => !isEmpty(notes));

								const notesModel: NotesModel = {
									studentID: studentResult.studentID,
									criteriaNotes: result,
									summaryNotes,
								};

								return notesModel;
							})
							.filter((noteModel) => !isNull(noteModel));

						return this.notesCache;
					}),
					tap(() => this.studentLevelLoader$.next(false)),
				);
		}),
		filter((noteModel) => !isNull(noteModel)),
	);

	public tables$ = this.completeOnDestroy(
		combineLatest([this.selectedStudentID, this.studentLevelReportData$, this.students$, this.settingsService.filter$]),
	).pipe(
		map(([selectedStudentID, report, students, filter]) => {
			if (isNull(report) || isNull(selectedStudentID) || !students.length) {
				return [];
			}

			const studentResults =
        selectedStudentID === 0
        	? report.results
        	: report.results.filter(({studentID}) => studentID === selectedStudentID);

			const copyStudentResults: StudentResult[] = this.settingsService.applyFilters(studentResults, filter, report.currentPeriod);

			return copyStudentResults.map(({criteriaResults, studentID}) => {
				const totalPossible =
          criteriaResults.filter(({criteriaName}) => criteriaName !== 'Score').length * report.maxLevel;

				const totalScores = criteriaResults.find(({criteriaName}) => criteriaName === 'Score');

				const student = students.find(({id}) => id === studentID);

				if (!student) {
					throw new Error('student is undefined');
				}

				if (!totalScores) {
					throw new Error('totalScores is undefined');
				}

				const studentLevelTableModel: StudentLevelTableModel = {
					rubricName: report.downloadData.rubricName,
					studentID,
					studentName: student.value,
					totalScores: totalScores.periodResults,
					totalPossible,
				};

				return studentLevelTableModel;
			});
		}),
		map(tables => {
			const currentUser = getUser();
			const sort = currentUser?.studentSort;
			return tables.sort((a, b) => {
				if (isNull(currentUser) || sort === StudentSort.FirstName) {
					return a.studentName.localeCompare(b.studentName);
				}
				if (sort === StudentSort.LastName) {
					return a.studentName.localeCompare(b.studentName);
				}
				return 0;
			});
		})
	);

	public setShowNotesFilter(showNotes: boolean) {
		this.showNotes$.next(showNotes);
		this.controller.updateShowNotes({value: showNotes ? 'True' : 'False'}).subscribe();
	}

	public setShowSummaryFilter(showSummary: boolean) {
		this.showSummary$.next(showSummary);
		this.controller.updateShowSummary({value: showSummary ? 'True' : 'False'}).subscribe();
	}

	public setShowInColorFilter(showInColor: boolean) {
		this.showInColor$.next(showInColor);
		this.controller.updateShowInColor({value: showInColor ? 'True' : 'False'}).subscribe();
	}

	public onDownload(fileType: FileTypeStudentLevel = FileTypeStudentLevel.Pdf) {
		if (fileType === FileTypeStudentLevel.Pdf) {
			this.downloadFile(false);
		} else {
			this.downloadFile(true);
		}
	}

	private downloadFile(splitByStudent: boolean) {
		const date = moment().format('YYYY_M_D');
		const extension = splitByStudent ? 'zip' : 'pdf';

		const filename = `Rubric_Results_Student_${date}.${extension}`;

		const studentLevelReport = this.reportData$.value;

		if (isNull(studentLevelReport)) {
			throw new Error('studentLevelReport is undefined');
		}

		const {
			results,
			downloadData,
			rubricID,
			teacherClassID,
			teacherGroupID,
			specialistGroupID,
			trackID,
			subjectID,
			subjectType,
			sortBy,
			sessionGuid,
		} = studentLevelReport;

		const studentResults =
      this.selectedStudentID.value === 0
      	? results
      	: results.filter(({studentID}) => studentID === this.selectedStudentID.value);

		const lastTestSessionIDs = this.getLastTestSessionIDs(studentResults);

		this.httpClient.ESGIApi.file('/v2/teachers/pages/reports/rubric-results/student-level', 'export/pdf', filename, {
			...downloadData,
			studentID: this.selectedStudentID.value,
			splitByStudent,
			filename,
			testSessionIDs: lastTestSessionIDs,
			rubricID,
			teacherClassID,
			teacherGroupID,
			specialistGroupID,
			trackID,
			subjectID,
			subjectType,
			filter: this.downloadFilter$.value,
			sortBy,
			sessionGuid,
		}).subscribe();
	}

	private getLastTestSessionIDs(studentResults: StudentResult[]) {
		return studentResults
			.map(({criteriaResults}) => criteriaResults[0])
			.map((criteriaResult) => {
				if (!criteriaResult) {
					return 0;
				}

				const lastResult = [...criteriaResult.periodResults].reverse().find(({testSessionID}) => testSessionID !== 0);

				return lastResult?.testSessionID ?? 0;
			})
			.filter((x) => x > 0);
	}

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}