import {BaseService} from '@esgi/core/service';
import {Models} from 'shared/modules/reports/flash-cards/results-form/models';
import {Api} from 'shared/modules/reports/flash-cards/results-form/api/api';
import {TestsService} from 'shared/modules/reports/flash-cards/settings-form/services/tests-service';
import {BehaviorSubject, Observable} from 'rxjs';
import {SettingsService} from 'shared/modules/reports/flash-cards/settings-form/services/settings-service';
import {tap} from 'rxjs/operators';

export class ResultsService extends BaseService {
	private _api: Api;
	private _resultsModel$: BehaviorSubject<ResultsModel> = new BehaviorSubject<ResultsModel>(null);

	constructor(private settingsService: SettingsService, private testsService: TestsService) {
		super();
		this._api = new Api();
	}

	private server =
		{
			init: () => {
				const requestModel = this.testsService.getResultsModel();
				return this._api.initResultsForm(this.testsService.getResultsModel())
					.pipe(tap((r) => {
						const result = new ResultsModel();
						result.model = Models.Init.FromResponse(r);
						result.allStudentsSelected = requestModel.AllStudentsSelected;
						this._resultsModel$.next(result);
						this.settingsService.selectedItems.selectedTestID = null;
					}));
			},
		}

	public init(){
		return this.server.init();
	}

	public get results$(): Observable<ResultsModel> {
		return this.completeOnDestroy(this._resultsModel$.asObservable());
	}

}
export class ResultsModel {
	allStudentsSelected: boolean;
	model: Models.Init;
}
