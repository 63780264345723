import React from 'react';
import {ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters';
import styles from './elements.module.less';

class Props {
	title: string;
	text: string;
}

export function FiltersTextBlock(props: Props) {
    	return <ReportFiltersContainer className={styles.filterContainer}>
		    <div className={styles.filterTextBlock}>
			    <span className={styles.filterTextTitle}>{props.title}</span>
			    <span className={styles.filterText}>{props.text}</span>
		    </div>
		</ReportFiltersContainer>;
}
