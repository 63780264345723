import {Checkbox, CheckboxesBox} from '../../../../../../../kit';
import {Container, Sidebar} from '../content.styled';
import {SessionList} from '../session-list';
import {noop} from 'underscore';
import {DiagramBox} from '../../../../diagram-box';
import {useSessionItems} from './hooks/use-session-items';
import {useSectionData} from './hooks/use-section-data';
import {useMemo, useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {CheckboxValue} from './types';
import {TestSession, TestInfo} from '../../../../../../../types';
import {LegendWithTranscriptItem} from '../../../../legend-with-transcript';
import {DetailedRubricDiagram, RubricDiagram} from '../../../../rubric-analysis-diagram';

type Props = {
	testSessions: TestSession[];
	testInfo: TestInfo;
	isTablet: boolean;
};

export function RubricContent({testSessions, testInfo, isTablet}: Props) {
	const [visibleSessionItemsCount, setVisibleSessionItemsCount] = useState<number>(5);
	const [diagramBoxHeight, setDiagramBoxHeight] = useState(0);

	const diagramBoxRef = useRef<HTMLDivElement>(null);

	useResizeObserver(diagramBoxRef, (entry) => setDiagramBoxHeight(entry.contentRect.height));

	const {selectedSessionId, setSelectedSessionId, isDetailedViewChecked, toggleDetailedViewCheckbox} = useSectionData({
		testSessions,
	});

	const selectedSession = testSessions.find(({id}) => selectedSessionId === id);
	const sessionItems = useSessionItems({testSessions, testInfo});

	const diagramLegendItems: LegendWithTranscriptItem[] = useMemo(
		() => [
			{
				transcriptText: 'Score Achieved',
				transcriptTextColor: 'neutral40',
				backgroundColor: 'primaryMuted',
				borderType: isDetailedViewChecked ? 'right' : 'top',
				borderColor: 'primary48',
			},
		],
		[isDetailedViewChecked],
	);

	return (
		<Container columnGap='20'>
			<Sidebar
				isTablet={isTablet}
				gap='20'
				css={{
					height: diagramBoxHeight,
					minHeight: !isTablet && 296,
				}}
			>
				<CheckboxesBox isTablet={isTablet}>
					<Checkbox
						value={CheckboxValue.DetailedView}
						label='Show Detailed View'
						checked={isDetailedViewChecked}
						onCheckedChange={toggleDetailedViewCheckbox}
						selected={isDetailedViewChecked}
						key={CheckboxValue.DetailedView}
					/>
				</CheckboxesBox>

				<SessionList
					selectedSessionId={selectedSessionId}
					setSelectedSessionId={setSelectedSessionId}
					sessionItems={sessionItems}
					type='single'
					indicatorPercentageType
					visibleSessionItemsCount={visibleSessionItemsCount}
					setVisibleSessionItemsCount={setVisibleSessionItemsCount}
				/>
			</Sidebar>

			<DiagramBox legendItems={diagramLegendItems} onFullScreenButtonClick={noop} ref={diagramBoxRef}>
				{isDetailedViewChecked ? (
					<DetailedRubricDiagram session={selectedSession} testInfo={testInfo} />
				) : (
					<RubricDiagram session={selectedSession} testInfo={testInfo} />
				)}
			</DiagramBox>
		</Container>
	);
}
