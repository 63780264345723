import {styled} from '../../../../../theme';
import {animated} from 'react-spring';

export const IndicatorBox = styled(animated.div, {
	position: 'absolute',
	boxSizing: 'border-box',
	width: 4,
	height: 4,
	top: 5,
	right: 5,
	borderRadius: '50%',
	backgroundColor: 'transparent',
});
