import {Scores} from './components/scores';
import {Charts} from './components/charts';
import {Answers} from './components/answers';
import {Notes} from './components/notes';
import {Row, Container, TestName} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {CommonProps} from '../../types';
import {MarkingPeriodItem, Test} from '../../../../types';

type Props = CommonProps & {
	tests: Test[];
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	markingPeriods: MarkingPeriodItem[];
	gradeScaleAllow: boolean;
};

export function Tests({
	tests,
	testResultsCorrectVerbiage,
	testResultsIncorrectVerbiage,
	report,
	reportSettings,
	markingPeriods,
	gradeScaleAllow,
}: Props) {
	return (
		<Row>
			{tests.map((test) => (
				<Container data-cy='test-container' key={test.testID}>
					<TestName data-cy='test-name' id={`${test.testID}-test-name`}>
						<Text>{test.testName}</Text>
					</TestName>
					<Scores
						test={test}
						report={report}
						reportSettings={reportSettings}
						gradeScaleAllow={gradeScaleAllow}
						markingPeriods={markingPeriods}
					/>
					<Charts test={test} report={report} reportSettings={reportSettings} />
					<Answers
						test={test}
						testResultsCorrectVerbiage={testResultsCorrectVerbiage}
						testResultsIncorrectVerbiage={testResultsIncorrectVerbiage}
						includeQuestionNotes={reportSettings.includeQuestionNotes}
					/>
					<Notes
						test={test}
						includeQuestionNotes={reportSettings.includeQuestionNotes}
						includeSummaryNotes={reportSettings.includeSummaryNotes}
					/>
				</Container>
			))}
		</Row>
	);
}
