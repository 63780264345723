import {Checkbox} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {isIpad, join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {ReportFiltersLabelContainer, ReportFiltersContent} from '../report-filter';
import {ReportFiltersLabel, ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters';

type ShowResultsFilterProps = {
	carryScoresForward: boolean;
	showBaseline: boolean;
	onCarryForwardClicked: (carryScoresForward: boolean) => void;
	onShowBaseLineClicked: (showBaseLine: boolean) => void;
};

export function ShowResultsFilter({
	carryScoresForward,
	showBaseline,
	onCarryForwardClicked,
	onShowBaseLineClicked,
}: ShowResultsFilterProps) {
	return (
		<ReportFiltersContainer dataCy='rubric-report-results-filter' className={join(!isIpad() && styles.columnOrientation)}>
			<ReportFiltersLabelContainer>
				<ReportFiltersLabel>Results:</ReportFiltersLabel>
			</ReportFiltersLabelContainer>
			<ReportFiltersContent className={styles.rowOrientation}>
				<OnHoverTooltip message='Carry forward'>
					<div>
						<Checkbox
							className={styles.checkbox}
							onChange={() => onCarryForwardClicked(!carryScoresForward)}
							checked={!!carryScoresForward}
						>
							Carry forward
						</Checkbox>
					</div>
				</OnHoverTooltip>
				<OnHoverTooltip message='Show Baseline'>
					<div>
						<Checkbox
							className={styles.checkbox}
							onChange={() => onShowBaseLineClicked(!showBaseline)}
							checked={!!showBaseline}
						>
							Show Baseline
						</Checkbox>
					</div>
				</OnHoverTooltip>
			</ReportFiltersContent>
		</ReportFiltersContainer>
	);
}
