import {useCallback} from 'react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {TeacherModel} from '../../../types';
import {SelectField} from './index.styled';

type Props = {
	selected: number,
	teachers: TeacherModel[],
	skeleton?: boolean,
	onChange: (value: number) => void,
}

export function TeacherSelect(props: Props) {
	const onChange = useCallback((value: string) => props.onChange(Number(value)), [props.onChange]);

	return <Select.Root value={props.selected.toString()} onValueChange={onChange}>
		<SelectField placeholder='Primary Teacher' skeleton={props.skeleton}/>
		<Select.Content>
			<Select.Option value='0'><Text bold size='medium'>All</Text></Select.Option>
			{props.teachers.map((i, index) => <Select.Option value={i.teacherID.toString()} key={i.teacherID}>
				<Text bold size='medium'>{i.firstName} {i.lastName}</Text>
			</Select.Option>)}
		</Select.Content>
	</Select.Root>;
}
