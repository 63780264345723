import React, {useCallback, useState} from 'react';
import moment from 'moment';
import {Buttons} from '@esgillc/ui-kit/button';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {Fade, Scale} from '@esgillc/ui-kit/transition';
import DataService from '../../data-service';
import {SessionModel} from '../../types';
import SessionPicker from './components/session-picker/session-picker';

import styles from './sub-header.module.less';
import {useStreamEffect} from '@esgillc/ui-kit/utils';

interface Props {
	service: DataService;
	editMode: boolean;
	changeMode: () => void;
	onSessionDeleted: () => void;
}

export function SubHeader({service, editMode, changeMode, onSessionDeleted}: Props) {
	const [selectedSession, setSelectedSession] = useState<SessionModel[]>([]);
	const [sessions, setSessions] = useState<SessionModel[]>([]);
	const [showDeletedSessions, setShowDeletedSessions] = useState(false);

	const currentSession = selectedSession[0];
	const hasNoSession = !currentSession;

	const renderAddRemoveActionsButton = useCallback(() => {
		const isEditMode = editMode && selectedSession[0]?.sessionID > 0;
		const isCreateMode = !isEditMode && editMode;
		const isViewMode = !isEditMode && !isCreateMode;
		const {canEdit, canRemove} = service.testInfo;

		if (isCreateMode || isViewMode && !canEdit || isEditMode && !canRemove) {
			return;
		}

		return (
			<Buttons.Link
				className={styles.addSessionButton}
				onClick={() => {
					if (isEditMode) {
						service.deleteCurrentSession().subscribe(r => {
							const date = moment(r.testDate).format('MM-DD-YYYY hh:mm A');
			                showSnackbarNotification(`You've deleted Test Session ${date}`);
			                changeMode();
			                onSessionDeleted();
						});
					} else {
						service.startNewSession().subscribe(changeMode);
					}
				}}>
				<Scale in={isViewMode} duration={50}>
					<svg width='30' height='30'
					     viewBox='0 0 30 30'
					     fill='none'
					     xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM22.5 16.5H16.5V22.5H13.5V16.5H7.5V13.5H13.5V7.5H16.5V13.5H22.5V16.5Z'
							fill='#059BE0'/>
					</svg>
				</Scale>
				<span>
				{isEditMode ? 'Delete test session' : 'Add Test Session'}
				</span>
			</Buttons.Link>
		);
	}, [editMode]);

	useStreamEffect(service.currentSession, session => {
		if (session) {
			setSelectedSession([session]);
		} else {
			setSelectedSession([]);
		}
	});

	useStreamEffect(service.sessions, sessions => setSessions(sessions));

	return (
		<div>
			<div>
				<div>
					<div className={styles.studentInfo}>
						<span className={styles.studentInfoLabel}>Student:</span>
						<span className={styles.studentInfoText}>
							{service.testInfo.studentFirstName + ' ' + service.testInfo.studentLastName}
						</span>
					</div>
					<div className={styles.studentInfo}>
						<span className={styles.studentInfoLabel}>Rubric Name:</span>
						<span className={styles.studentInfoText}>{service.testInfo.rubricName}</span>
					</div>
				</div>
			</div>
			<div>
				<Fade in={!hasNoSession} duration={100}>
					<div className={styles.sessionSelectorTitleContainer}>
						<span className={styles.duration}>
							Duration: {currentSession?.duration || 'n/a'}
						</span>
					</div>
				</Fade>
				<div className={styles.sessionSelectorContainer}>
					<div className={styles.dropdownContainer}>
						<SessionPicker
							sessions={sessions}
							selectedSession={selectedSession}
			                showDeletedSessions={showDeletedSessions}
			                changeShowDeletedSessions={() => setShowDeletedSessions(!showDeletedSessions)}
			                editMode={editMode}
			                service={service}
						/>
					</div>
					<div className={styles.addSessionButtonContainer}>
						{renderAddRemoveActionsButton()}
					</div>
				</div>
			</div>
		</div>
	);
}
