import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';

export const ButtonIcon = styled(Button.Icon, {
	color: '$neutral40',
	backgroundColor: '$neutral99',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderRadius: '6px',
	borderColor: '$neutral88',
	padding: '4px',
	width: 32,
	height: 32,

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		active: {
			true: {
				color: '$primary20',
				backgroundColor: '$primary96',
				borderColor: '$primary88',
			},
			false: {
				'&:hover': {
					color: '$secondary20',
					backgroundColor: '$secondary96',
					borderColor: '$secondary88',
				},
			},
		},
	},
});

export const ButtonText = styled(Button.Text, {
	color: '$neutral40',
	backgroundColor: '$neutral99',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderRadius: '6px',
	borderColor: '$neutral88',
	display: 'inline-flex',
	alignItems: 'center',
	padding: '4px 8px',
	fontWeight: 'bold',

	variants: {
		active: {
			true: {
				color: '$primary',
				backgroundColor: '$primary96',
				borderColor: '$primary88',
			},
			false: {
				'&:hover': {
					color: '$secondary20',
					backgroundColor: '$secondary96',
					borderColor: '$secondary88',
				},
			},
		},
	},
});
