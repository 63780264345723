/* eslint-disable @typescript-eslint/member-ordering */
import {BehaviorSubject} from 'rxjs';
import {Student} from '../types/types';
import {isNull} from 'underscore';
import {FilterService} from './filter-service';
import {SpecialistGroup} from '../types/specialist-types';

export class FilterSpecialistService extends FilterService {
	public readonly groups$ = new BehaviorSubject<SpecialistGroup[]>([]);

	public selectedGroupId$ = new BehaviorSubject<SpecialistGroup['id'] | null>(null);

	private get selectedGroupId() {
		return this.selectedGroupId$.value;
	}

	private get groups() {
		return this.groups$.value;
	}

	public setSelectedGroupId(value: SpecialistGroup['id'] | null) {
		this.selectedGroupId$.next(value);
	}

	private getStudentsIds(): Student['id'][] {
		const isGroupSelected = !isNull(this.selectedGroupId);

		if (isGroupSelected) {
			const group = this.groups.find(({id}) => id === this.selectedGroupId);

			if (group) {
				return [...group.students.map(({id}) => id)];
			}
		}

		return [...this.allStudentsIds];
	}

	public updateTableData() {
		const studentsIds = this.getStudentsIds();

		this.setTableData(studentsIds);
	}
}
