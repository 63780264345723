import {DateObject} from './types';

export function getDateParts(value: Date | string): DateObject {
	if (value) {
		const date = new Date(value);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		return {day, month, year};
	}

	return {day: null, month: null, year: null};

}
