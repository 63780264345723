import * as SelectRadix from '@radix-ui/react-select';
import {SelectProps} from '@radix-ui/react-select';
import {useMemo, useState} from 'react';
import {SelectContext, SelectContextValue} from '../../context';
import {GridBox} from '@esgi/ui/layout';

type SelectRootProps = SelectProps & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Additional select item. */
	showAdditionalItem?: boolean;

	/** Select error message. */
	errorMessage?: string | undefined;
};

export function SelectRoot({dataCy, children, errorMessage, showAdditionalItem = false, ...props}: SelectRootProps) {
	const [open, setOpen] = useState(false);

	const contextValue = useMemo<SelectContextValue>(
		() => ({
			disabled: props.disabled,
			showAdditionalItem,
			errorMessage,
			onClick: (e) => {
				e.stopPropagation();
				setOpen(prev => !prev);
			},
		}),
		[props.disabled, errorMessage, showAdditionalItem, setOpen],
	);

	return (
		<SelectRadix.Root
			{...props}
			open={showAdditionalItem ? open : props.open}
			onOpenChange={showAdditionalItem ? () => setOpen(false) : props.onOpenChange}
		>
			<SelectContext.Provider value={contextValue}>
				<GridBox data-cy={dataCy ?? 'ui-kit-select'} display='inlineGrid' css={{width: '100%'}}>
					{children}
				</GridBox>
			</SelectContext.Provider>
		</SelectRadix.Root>
	);
}
