import {Text} from '@esgi/ui/typography';
import {TagWrapper} from './index.styled';
import React from 'react';

type TagProps = {
	variant?: 'first' | 'last' | 'middle';
	children: React.ReactNode;
	color?: 'orange' | 'default' | undefined;
};

export function Tag({variant, children, color}: TagProps) {
	return (
		<TagWrapper variant={variant} color={color}>
			<Text size='small'>
				{children}
			</Text>
		</TagWrapper>
	);
}
