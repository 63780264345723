import React from 'react';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {IQuestionResponse, Load} from './api';
import {TestSubsetStartedEvent} from './events';
import QuestionsSelector from './components/questions-selector';
import {AnswerState} from '@esgi/core/enums';
import {dispatchAppEvent} from '@esgillc/events';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {Modal, Title} from '@esgillc/ui-kit/modal';
import {capitalizeFirstLetter} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

class State {
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	questions: IQuestionResponse[] = [];
	checked: number[] = [];
}

class Props {
	testID: number;
	testSessionID: number;
	testSessionNumber?: number;
	closed: () => any;
	started: () => any;
}

export default class TestSubset extends React.Component<Props, State> {
	state = new State();

	public render() {
		return (
			<Modal onCatchError={() => this.props.closed()}>
				<Modal.Header>
					<Title>
						Subset Questions
						<sup className={styles.helpContainer}>
							<OnHoverTooltip message='Help'>
								<a href='https://support.esgisoftware.com/hc/en-us/articles/209160386-Subset-Test' target='_blank' rel='noreferrer'>
									<i className='fa fa-question-circle' />
								</a>
							</OnHoverTooltip>
						</sup>
					</Title>
				</Modal.Header>
				<Modal.Body>
					<div className={styles.body}>
						<div className={styles.description}>
							<div className={styles.top}>Select questions to include in subset test</div>
							<div className={styles.bottom}>
								{this.props.testSessionNumber &&
									<div>Results from Test Session {this.props.testSessionNumber}</div>
								}
								<div>Total Questions ({this.state.questions.length})</div>
							</div>
						</div>
						<div className={styles.questions}>
							<QuestionsSelector
								questions={this.state.questions.filter(x => AnswerState[x.answerState] === AnswerState.Correct)}
								answerStateVerbiage={this.state.testResultsCorrectVerbiage}
								checked={this.checked}
							/>
							<QuestionsSelector
								questions={this.state.questions.filter(x => AnswerState[x.answerState] === AnswerState.Incorrect)}
								answerStateVerbiage={this.state.testResultsIncorrectVerbiage}
								checked={this.checked}
							/>
							<QuestionsSelector
								questions={this.state.questions.filter(x => AnswerState[x.answerState] === AnswerState.NotTested)}
								answerStateVerbiage='Not Tested'
								checked={this.checked}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className={styles.footer}>
						<Button
							onClick={() => this.close()}
							title='Close'
						/>
						<Primary
							onClick={() => this.startTest()}
							title='Start Test'
							disabled={!this.state.checked.length}
						/>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}

	public componentDidMount(): void {
		Load(this.props.testID, this.props.testSessionID).subscribe(r => {
			this.setState({
				testResultsCorrectVerbiage: capitalizeFirstLetter(r.testResultsCorrectVerbiage),
				testResultsIncorrectVerbiage: capitalizeFirstLetter(r.testResultsIncorrectVerbiage),
				questions: r.questions,
			});
		});
	}

	public close() {
		this.props.closed();
	}

	checked = (questions: IQuestionResponse[], checked: boolean) => {
		const questionIDs = questions.map(x => x.questionID);

		if (checked) {
			this.setState({checked: this.state.checked.concat(questionIDs)});
		} else {
			this.setState({checked: this.state.checked.filter(s => !questionIDs.includes(s))});
		}
	};

	private startTest() {
		if (!this.state.checked.length) {
			OldAlerts.bsalert('Please select at least one test in order to run a subset test.');
			return;
		}

		dispatchAppEvent(
			TestSubsetStartedEvent,
			new TestSubsetStartedEvent(this.props.testID, this.state.checked),
		);
		this.close();
		this.props.started();
	}
}
