import {Column} from '../index.styled';
import {Wrapper, Title} from './index.styled';
import {Select} from '../select';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {GridBox} from '@esgi/ui/layout';
import {Skeleton} from '../skeleton';
import {SelectableList} from '../selectable-list-simple';
import {OptionItem} from '../../types';

type Props = {
	standardTypeOptions: OptionItem[],
	onStandardTypeChange: (id: string) => void,
	selectedStandardType: OptionItem['value'],
	onContentAreaSelect: (id: number) => void,
	selectedContentArea: OptionItem['value'],
	gradeLevels: OptionItem[],
	onGradeLevelSelect: (id: number) => void,
	selectedGradeLevel: OptionItem['value'],
	isContentAreasLoading: boolean
	isDomainsLoading: boolean
	contentAreas: OptionItem[],
	domainsList: OptionItem[],
	selectedDomain: OptionItem['value'],
	onDomainSelect: (id: number) => void,
}

export function LargeScreenContent({
	standardTypeOptions,
	onStandardTypeChange,
	selectedStandardType,
	onContentAreaSelect,
	selectedContentArea,
	gradeLevels,
	onGradeLevelSelect,
	selectedGradeLevel,
	isContentAreasLoading,
	isDomainsLoading,
	contentAreas,
	domainsList,
	selectedDomain,
	onDomainSelect,
}: Props) {
	return (
		<Wrapper>
			<Column gap={5}>
				<Select
					placeholder='Type'
					items={standardTypeOptions}
					disabled={false}
					onValueChange={onStandardTypeChange}
					selectedValue={selectedStandardType}
					dataCy='type-select'
				/>
				<OverlayScrollbarsComponent style={{height: 'calc(100% + 0px)'}} defer options={{scrollbars: {autoHide: 'leave'}}}>
					<GridBox gap={5}>
						<GridBox data-cy='content-area-section' gap={2}>
							{isContentAreasLoading
								? <Skeleton variant='listItem'/>
								: (
									<>
										<Title data-cy='content-area-title' font='mono' size='small' color='mediumContrast'>
											Content Area
										</Title>
										<SelectableList
											items={contentAreas}
											selected={selectedContentArea}
											onClick={onContentAreaSelect}
										/>
									</>
								)}
						</GridBox>
						<GridBox data-cy='grade-level-section' gap={2}>
							{isContentAreasLoading
								? <Skeleton variant='listItem'/>
								: (
									<>
										<Title data-cy='grade-level-title' font='mono' size='small' color='mediumContrast'>
											Grade Level
										</Title>
										<SelectableList
											items={gradeLevels}
											selected={selectedGradeLevel}
											onClick={onGradeLevelSelect}
										/>
									</>
								)}
						</GridBox>
					</GridBox>
				</OverlayScrollbarsComponent>
			</Column>
			<Column data-cy='domain-column'>
				<OverlayScrollbarsComponent style={{height: 'calc(100% + 0px)'}} defer options={{scrollbars: {autoHide: 'leave'}}}>
					{isDomainsLoading
						? <GridBox data-cy='domain-section' gap={2}><Skeleton variant='listItem'/></GridBox>
						: (
							<SelectableList
								items={domainsList}
								selected={selectedDomain}
								onClick={onDomainSelect}
							/>
						)}
				</OverlayScrollbarsComponent>
			</Column>
		</Wrapper>
	);
}