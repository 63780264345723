import React from 'react';
import {ArrowIcon} from './arrow-icon';
import {MatrixContext} from './matrix-context';
import styles from './matrix.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {scrollXLeft} from './scrolling';

interface Props {
	className?: string;
	columnWidth: number;
}

export class PrevColumnButton extends React.PureComponent<Props> {
	public render() {
		return <MatrixContext.Consumer>
			{(context) => <div
				className={join(
					styles.prevColumn,
					this.props.className,
					!context.scrollActionState?.canPrevColumn && styles.hidden,
				)}>
				<ArrowIcon className={styles.arrowIconLeft} onClick={() => scrollXLeft(context.osInstance, this.props.columnWidth)}/>
			</div>}
		</MatrixContext.Consumer>;
	}
}
