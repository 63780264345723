import React, {ReactNode} from 'react';

class Props {
	dataName?: string;
	children?: ReactNode;
}

export class Card extends React.PureComponent<Props> {
	render() {
		return <div className='card' data-name={this.props.dataName}>
			{this.props.children}
		</div>;
	}

}
