import {Tooltip} from '@esgi/ui/tooltip';
import {InputContainer, TooltipContent} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {InputProps} from '@esgi/ui/controls';
import {FocusEvent, MouseEvent, forwardRef, useCallback, useRef, useState} from 'react';
import {useAutoControlledState, useComposedRefs} from '@esgi/ui';

type Props = Omit<InputProps, 'error'> & {
	error?: string | undefined;
};

export function inputWithTooltipError<T extends Props>(Component: React.ComponentType<T>) {
	return forwardRef<HTMLInputElement, T>(
		(
			{
				error,
				focused: externalFocused,
				hovered: externalHovered,
				onFocus,
				onBlur,
				onMouseEnter,
				onMouseLeave,
				...props
			},
			forwardedRef,
		) => {
			const [focused, setFocused] = useAutoControlledState({
				initialState: false,
				controlledState: externalFocused,
			});

			const [hovered, setHovered] = useAutoControlledState({
				initialState: false,
				controlledState: externalHovered,
			});

			const [tooltipContentHovered, setTooltipContentHovered] = useState(false);

			const innerInputRef = useRef<HTMLInputElement>(null);
			const inputRef = useComposedRefs(forwardedRef, innerInputRef);

			const handleFocus = useCallback(
				(event: FocusEvent<HTMLInputElement>) => {
					setFocused(true);

					onFocus?.(event);
				},
				[onFocus, setFocused],
			);

			const handleBlur = useCallback(
				(event: FocusEvent<HTMLInputElement>) => {
					setFocused(false);

					onBlur?.(event);
				},
				[onBlur, setFocused],
			);

			const handleMouseEnter = useCallback(
				(event: MouseEvent<HTMLInputElement>) => {
					setHovered(true);

					onMouseEnter?.(event);
				},
				[onMouseEnter, setHovered],
			);

			const handleMouseLeave = useCallback(
				(event: MouseEvent<HTMLInputElement>) => {
					setHovered(false);

					onMouseLeave?.(event);
				},
				[onMouseLeave, setHovered],
			);

			const toggleTooltipContentHover = useCallback(() => {
				setTooltipContentHovered((isHover) => !isHover);
			}, []);

			const isTooltipOpen = Boolean(error) && (hovered || focused || tooltipContentHovered);

			return (
				<Tooltip open={isTooltipOpen}>
					<Tooltip.Trigger asChild>
						<InputContainer data-error={Boolean(error)} dataCy='input-container'>
							<Component
								focused={focused}
								hovered={hovered}
								onFocus={handleFocus}
								onBlur={handleBlur}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
								onMouseDown={() => {
									innerInputRef.current?.focus();
								}}
								{...(props as T)}
								ref={inputRef}
							/>
						</InputContainer>
					</Tooltip.Trigger>
					<TooltipContent
						variant='error'
						sideOffset={-5}
						align='start'
						alignOffset={5}
						size='small'
						onMouseEnter={toggleTooltipContentHover}
						onMouseLeave={toggleTooltipContentHover}
					>
						<Text size='xxSmall' font='mono' color='negativeVivid'>
							{error}
						</Text>
					</TooltipContent>
				</Tooltip>
			);
		},
	);
}
