import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {SkeletonStylesWithBorders} from '@esgi/ui/skeleton';

export const AvatarWrapper = styled(GridBox, {
	gap: '$3',
	gridTemplateColumns: 'auto 1fr',
});

export const StudentName = styled(Text, {
	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});
