import {AppContextProvider} from '@esgi/deprecated/react';
// to react index.ts. It will produce circular dependency
import {checkElementInDom, waitElementInDom} from '@esgi/deprecated/utils';

ko.bindingHandlers.afterRender = {
    init: function (element, valueAccessor, allBindings, viewModel) {
        ko.bindingHandlers.afterRender.handler($(element), viewModel);
    },
    handler: function ($element, component) {
        setTimeout(function () {
            const callAfterRender = () => {
                if (typeof component['afterRender'] !== "undefined")
                    component.afterRender($element);

                if (typeof component["_rendered"] !== 'undefined') {
                    for (var i = 0; i < component["_rendered"].length; i++) {
                        var key = component["_rendered"][i];
                        if (key)
                            component[key]($element);
                    }
                }

                $(component).trigger('rendered', $element);
            }
            if($.contains(document,$element[0])) {
                callAfterRender();
            } else {
                setTimeout(() => callAfterRender(),1);
            }
        }, 1);
    }
};

ko.bindingHandlers.click = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
        var accessor = valueAccessor();
        
        // this way we not only prevent double clicks,
        // but also double $().trigger('click')
        var clicked = false;

        $(element).click(function (event) {
            if(clicked)
                return;
            
            clicked = true;
            
            var link = this.tagName.toLowerCase() === "a";
            var button = this.tagName.toLowerCase() === "button";
            
            if (this.tagName && (link || button)) {
                event.preventDefault();
            }

            var processingDisabled = this.classList.contains("disable-mltplclk-processing");

            if (accessor && (processingDisabled || !event.originalEvent || event.originalEvent.detail === 0 || event.originalEvent.detail === 1)) {
                var el = $(this).attr("disabled", true).css("pointer-events", "none");

                try {
                    var result = accessor.call(viewModel, context.$data, event);
                } catch (e) {
                    console.log(e);
                }

                var timeoutId = 0;
                var iconTag = null;
                var icon = null;
                var that = this;

                if (typeof result !== 'undefined' && result.promise) {
                    timeoutId = setTimeout(function () {
                        iconTag = $(that).find('i.fa');
                        if (iconTag.length) {
                            icon = iconTag[0].classList.toString();
                            iconTag[0].className = 'fa fa-circle-o-notch fa-spin';
                        } else if (button) {
                            $(that).prepend('<i class="fa fa-circle-o-notch fa-spin" />')
                        }
                    }, 150);
                }

                $.when(result).always(function () {
                    el.removeAttr("disabled").css("pointer-events", "");
                    clearTimeout(timeoutId);

                    if (iconTag && iconTag.length) {
                        iconTag[0].className = icon;
                    } else if (button) {
                        $(that).find('.fa-circle-o-notch').remove();
                    }
                    clicked = false;
                });
            } else {
                event.preventDefault();
                clicked = false;
            }
        });
    }
};

ko.toPlainObject = function (object) {
    if (!object)
        return undefined;

    var newObject = {};

    for (var i in object) {
        if (object.hasOwnProperty(i)) {
            var propertyValue = object[i];
            if (typeof propertyValue === 'function' && (ko.isObservable(propertyValue) || ko.isComputed(propertyValue)))
                propertyValue = propertyValue();

            newObject[i] = propertyValue;
        }
    }

    return newObject;
}

ko.render = function (control, template) {
    var deferred = $.Deferred();

    var ultimateTemplate = control.template || template || control.$data.template;
    if (!control || !ultimateTemplate) {
        console.log("Unable to render control", control);
        deferred.resolve("<div style='height:100%; width:100%; display: flex; align-content: center; justify-content: center'>Control cannot be rendred</div>");
    } else {

        if (typeof ultimateTemplate != 'function')
            ultimateTemplate = function () {
                return ultimateTemplate;
            };

        var result = ultimateTemplate();
        if (typeof result.then === 'undefined')
            result = resolvedPromise(result);
        
        result.then(function(templateHtml){
            var deferred = $.Deferred();
            if(templateHtml.$$typeof)
            {
                var div = document.createElement('div');
                ReactDOM.render(templateHtml, div);

                    deferred.resolve(div.childNodes[0]);
            }
            else 
                deferred.resolve(templateHtml);
            
            return deferred;
        }).then(function (templateHtml) {
            if(templateHtml)
            {
                control.rootElement = $(templateHtml);
                ko.applyBindings(control, control.rootElement[0]);
            }

            deferred.resolve(control.rootElement);
        }).fail(function () {
            deferred.reject();
        });
    }

    return deferred.promise();
};


ko.bindingHandlers.stopBinding = {
    init: function () {
        return {controlsDescendantBindings: true};
    }
};

ko.bindingHandlers.var = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var data = ko.unwrap(valueAccessor());
        bindingContext = $.extend(bindingContext, data);
    }
}

ko.virtualElements.allowedBindings.var = true;

ko.bindingHandlers.proxy = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var data = ko.unwrap(valueAccessor());
        var target = data.target;
        var property = data.property;

        Object.defineProperty(bindingContext, property, {
            get: function () {
                return target[property];
            },
            enumerable: true,
            configurable: true
        });
    }
}

ko.bindingHandlers.module =
    {
        init: function (element) {
            return {controlsDescendantBindings: true};
        },
        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var module = ko.unwrap(valueAccessor());
            var data = ko.utils.unwrapObservable(module.data);

            ko.ignoreDependencies(function () {


                $(data).on('disposed', function () {
                    if (data.rootElement)
                        data.rootElement.remove();
                });

                var deferred = $.Deferred();
                if (data) {
                    if (!data.rootElement) {
                        deferred = data.load().then(function () {
                            return ko.render(data);
                        });
                    } else {
                        deferred.resolve(data.rootElement);
                    }
                }

                deferred.done(function (renderedHtml) {
                    var found = false;
                    for (var i = 0; i < element.children.length; i++) {
                        var e = element.children[i];
                        var m = ko.dataFor(e);

                        if (m === data) {
                            e.style.display = '';
                            found = true;
                        } else {
                            e.style.display = 'none';
                        }
                    }

                    if (!found) {
                        element.appendChild(renderedHtml[0]);
                    }

                    if (module.loaded)
                        module.loaded.call(viewModel, data, renderedHtml);
                }).fail(function() {
                    if(module.failed)
                        module.failed.call(viewModel);
                })
            });
        }
    }

ko.bindingHandlers.debug = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        debugger;
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        debugger;
    }
};


/*
 * File input binding for knockout.js
 * Jesse Kipp - Nov 14, 2013
 *
 * A special binding for file input elements. Think of it like 'value', but
 * storing the encoded data from a FileReader object.
 *
 * Usage 1:
 * <input type='file' data-bind='file: fileInput'>
 * If fileInput is an observable, it will be assigned the value of the base64
 * encoded file data.
 *
 * Usage 2:
 * <input type='file' data-bind='file: {data: fileInput, name: fileName}'>
 * When passing an object, use the following keys
 *   data: the observable to store the file data (required)
 *   name: the observable to store the file name
 *   allowed: permitted content types (text/javascript, etc), as a string or
 *     array of strings. All other types will be ignored.
 *   prohibited: content types that will always be ignored.
 *   reader: an existing FileReader object. If not defined, creates a new
 *     one internally.
 */

ko.bindingHandlers['file'] = {
    init: function (element, valueAccessor, allBindings) {

        var observable = valueAccessor();

        var handler = function () {
            var file = element.files[0];
            observable(file);
        }

        ko.utils.registerEventHandler(element, 'change', handler);
    }
}

ko.bindingHandlers.fadeVisible = {
    init: function (element, valueAccessor) {
        // Initially set the element to be instantly visible/hidden depending on the value
        var value = valueAccessor();
        $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
    },
    update: function (element, valueAccessor) {
        // Whenever the value subsequently changes, slowly fade the element in or out
        var value = valueAccessor();
        ko.unwrap(value) ? $(element).fadeIn() : $(element).fadeOut();
    }
};

ko.extenders.trackChange = function (target, track) {
    if (track) {
        target.isDirty = ko.observable(false);
        target.originalValue = target();
        target.setOriginalValue = function (startingValue) {
            target.originalValue = startingValue;
        };
        target.subscribe(function (newValue) {
            target.isDirty(newValue != target.originalValue);
        });
    }
    return target;
};

ko.bindingHandlers.dateTimePicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        //initialize datepicker with some optional options
        var options = $.extend({format: 'MM-DD-YYYY hh:mm A'}, allBindingsAccessor().dateTimePickerOptions);
        var picker = $(element).datetimepicker(options);

        //when a user changes the date, update the view model
        ko.utils.registerEventHandler(element, "dp.change", function (event) {

            var value = valueAccessor();
            if (ko.isObservable(value)) {
                if (event.date === false)
                    value(null);
                else
                    value(event.date);
            }
        });
    },
    update: function (element, valueAccessor) {
        var widget = $(element).data("DateTimePicker");
        //when the view model is updated, update the widget

        if (widget) {
            var value = ko.utils.unwrapObservable(valueAccessor());
            if (value) {
                var date = moment(value);
                if (date.isValid()) {
                    widget.date(date);
                    return;
                }
            }

            widget.clear();
        }
    }


};


ko.bindingHandlers.for = {
    init: function (element,
                    valueAccessor,
                    allBindingsAccessor,
                    viewModel,
                    context) {

        var index = ko.utils.unwrapObservable(valueAccessor());
        var docFrag = document.createDocumentFragment();

        var clone = $(element.children[0]).clone();
        $(element).empty();

        for (var i = 0; i < index; i++) {
            var c = clone.clone();

            var childContext = context.createChildContext({$index: i});

            ko.applyBindings(childContext, c[0]);
            docFrag.appendChild(c[0]);
        }

        element.appendChild(docFrag);

        return {controlsDescendantBindings: true};
    }
};


ko.bindingHandlers.readonly = {
    update: function (element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        if (value) {
            $(element).attr("readonly", "readonly");
        } else {
            $(element).removeAttr("readonly");
        }
    }
};

ko.bindingHandlers.datepicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var options = {
            autoclose: true,
            format: 'm/d/yy',
            todayHighlight: true,
            assumeNearbyYear: 100
        }

        var datepickerOptions = allBindingsAccessor().datepickerOptions || {};
        $.extend(options, datepickerOptions);

        $(element).datepicker(options);

        function convertToUtc(date) {
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
        }

        $(element).datepicker().on("changeDate", function (event) {
            var value = valueAccessor();
            if (ko.isObservable(value)) {
                if ($(element).is(':focus')) {
                    $(element).one('blur', function () {
                        var dateVal = $(element).datepicker("getDate");
                        if (dateVal == null) {
                            value(null);
                        } else {
                            value(convertToUtc(dateVal));
                        }
                    });
                } else {
                    if (event.date != null) {
                        var date = convertToUtc(event.date);
                        if (value() == undefined || date.getTime() != value().getTime()) {
                            value(date);
                        }
                    } else {
                        value(null);
                    }
                }
            }
        }).on('change', function () {
            if (!$(element).val()) {
                var value = valueAccessor();
                if (ko.isObservable(value)) {
                    value(null);
                }
            }
        });
    },
    update: function (element, valueAccessor) {
        var widget = $(element).data("datepicker");
        //when the view model is updated, update the widget
        if (widget) {
            var val = ko.utils.unwrapObservable(valueAccessor());
            if (val == null) {
                $(element).datepicker("clearDates");
            } else
                $(element).datepicker("setUTCDate", val);
        }
    }
};

ko.bindingHandlers.chosen = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var $element = $(element);
        var options = ko.unwrap(valueAccessor());

        if (typeof options === 'object') {
            if (typeof options.enable !== 'undefined' && !options.enable)
                return;

            $element.chosen(options);
        } else
            $element.chosen();

        ko.dependentObservable(function () {
            ['options', 'selectedOptions', 'value', 'optionsText', 'enable', 'disable'].forEach(function (propName) {
                if (allBindings.has(propName)) {
                    var prop = allBindings.get(propName);
                    var value = ko.unwrap(prop);
                }
            });

            setTimeout(function () {
                $element.trigger('chosen:updated');
            }, 1);

        }, null, {disposeWhenNodeIsRemoved: element});

    }
}


ko.bindingHandlers.numeric = {
    init: function (element, valueAccessor) {
        $(element).on("keydown", function (event) {
            // Allow: backspace, delete, tab, escape, and enter
            if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
                // Allow: Ctrl+A
                (event.keyCode == 65 && event.ctrlKey === true) ||
                // Allow: . ,
                (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 110) ||
                // Allow: home, end, left, right
                (event.keyCode >= 35 && event.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            } else {
                // Allow CTRL+V
                if (event.ctrlKey && event.keyCode == 86) {
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                    event.preventDefault();
                }
            }
        });
    }
};

ko.bindingHandlers.timepicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var options = {}

        var timepickerOptions = allBindingsAccessor().timepickerOptions || {};
        $.extend(options, timepickerOptions);
        $(element).timepicker(options).on('changeTime.timepicker', function (e) {
            var val = ko.utils.unwrapObservable(valueAccessor());
            val.value(e.time.value);
            val.hours(e.time.hours);
            val.minutes(e.time.minutes);
            val.meridian(e.time.meridian);
        });

    },
    update: function (element, valueAccessor) {
        var widget = $(element).data("timepicker");
        if (widget) {
            var val = ko.utils.unwrapObservable(valueAccessor());
            if (val.value != null) {
                $(element).timepicker("setTime", val.value());
            }
        }
    }
};

ko.bindingHandlers.render = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        return {controlsDescendantBindings: true};
    },
    update: function (element,
                      valueAccessor,
                      allBindingsAccessor,
                      viewModel,
                      context) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        if (value) {
            var ctx = context.createChildContext(value);

            ko.render(ctx).then(function (html) {

                var $html = $(html);

                ko.virtualElements.emptyNode(element);
                ko.virtualElements.prepend(element, $html[0]);

                // TODO: check this for producing double 'afterRender' calls
                if (!allBindingsAccessor()['afterRender'])
                    ko.bindingHandlers.afterRender.handler($html, value);
            });
        }
    }
}


ko.virtualElements.allowedBindings.render = true;

ko.bindingHandlers.highchart = {
    'init': function () {
        // Prevent binding on the dynamically-injected text node (as developers are unlikely to expect that, and it has security implications).
        // It should also make things faster, as we no longer have to consider whether the text node might be bindable.
        return { 'controlsDescendantBindings': true };
    },
    update: function (element, valueAccessor, allBindings) {
        const options = ko.unwrap(valueAccessor()) || {};
        if(checkElementInDom(element)) {
            const chart = $(element).highcharts();
            if(chart) {
                chart.update(options)
            }
        }
        waitElementInDom(element,(element) => {
            $(element).highcharts(options);
            $(element).on("remove",() => $(element).highcharts() && $(element).highcharts().destroy())
        })
    }
};

ko.bindingHandlers.trimedValue = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        $(element).on("change", function () {
            var observable = valueAccessor();
            var trimedValue = $.trim($(this).val());
            $(this).val(trimedValue);
            observable(trimedValue);
        });
    }
};

ko.bindingHandlers.ellipsis = {
    'init': function () {
        // Prevent binding on the dynamically-injected text node (as developers are unlikely to expect that, and it has security implications).
        // It should also make things faster, as we no longer have to consider whether the text node might be bindable.
        return {'controlsDescendantBindings': true};
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        var value = ko.unwrap(valueAccessor());
        var text = value.data;
        var length = value.length;

        if (text && text.length > length) {
            text = text.substring(0, length) + '...';
        }

        ko.utils.setHtml(element, text)
    }
}

ko.bindingHandlers.format = {
    'init': function () {
        // Prevent binding on the dynamically-injected text node (as developers are unlikely to expect that, and it has security implications).
        // It should also make things faster, as we no longer have to consider whether the text node might be bindable.
        return {'controlsDescendantBindings': true};
    },
    'update': function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());
        var text = value.data;

        switch (value.type) {
            case 'money':
                text = ko.bindingHandlers.format.money(text);
        }

        ko.utils.setTextContent(element, text);

    },

    money: function (value) {
        var numberValue = parseFloat(value);

        if (!isNaN(numberValue)) {
            return '$' + numberValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        }
        return '$' + 0;
    }

};

ko.bindingHandlers.moment = {
    'init': function () {
        // Prevent binding on the dynamically-injected text node (as developers are unlikely to expect that, and it has security implications).
        // It should also make things faster, as we no longer have to consider whether the text node might be bindable.
        return {'controlsDescendantBindings': true};
    },
    'update': function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());
        var text = value.data;
        var format = value.format;

        if (!text)
            ko.utils.setTextContent(element, '');
        else {
            var momentValue = text;
            if (!moment.isMoment(momentValue))
                momentValue = moment(momentValue());

            ko.utils.setTextContent(element, momentValue.format(format));
        }
    },
}

ko.bindingHandlers.react = {
    'init': function () {
        // Prevent binding on the dynamically-injected text node (as developers are unlikely to expect that, and it has security implications).
        // It should also make things faster, as we no longer have to consider whether the text node might be bindable.
        return {'controlsDescendantBindings': true};
    },
    'update': function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());
        if(value) {
            
            var reactorFactory = function () {
                return value;
            }
            reactorFactory.displayName = "Dynamic React Component created by Knockout";
            reactorFactory.contextType = AppContextProvider;
            var reactObject = React.createElement(reactorFactory, value.props);

            ReactDOM.render(reactObject, element);
        }
        else {
            ReactDOM.unmountComponentAtNode(element);
        }
    }
};


ko.options.deferUpdates = true;
