import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	onCloseAlert: VoidFunction;
};

export function OneClassLeftConfirmation({onCloseAlert}: Props) {
	const alertRef = Alert.useRef();

	const closeAlert = Alert.useClose(alertRef, onCloseAlert);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Deleting the Last Class
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox>
					<Text size='medium' color='neutral40'>
						This is the last class in your list and cannot be deleted.
					</Text>
					<Text size='medium' color='neutral40'>
						Please create or be assigned to a new class before deleting this one.
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<Button color='secondary' onClick={closeAlert}>
					<Text size='medium' bold color='base'>
						Close
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
