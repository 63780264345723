//TODO Move outside test-details kit.
export const contentAreaDefaultColors: { [key: number]: {color: string, title: string} } = {
	[56]: {color: '90CAF9', title: 'Blue'}, // Language Arts
	[57]: {color: '80CBC4', title: 'Teal'}, // Mathematics
	[93]: {color: 'CDCDCD', title: 'PaleGray'}, // Other
	[100]: {color: 'FFCC80', title: 'Orange'}, // Spanish
	[101]: {color: 'CE93D8', title: 'Magenta'}, // Science
	[102]: {color: 'FFF59D', title: 'Yellow'}, // Social Studies
	[105]: {color: '9FA8DA', title: 'Purple'}, // SEL
};
