import {BaseService} from '@esgi/core/service';
import {FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {takeUntil} from 'rxjs';
import {BaseResponse} from '@esgi/api';
import {RegistrationResult} from './models';
import AppHistory from 'shared/tools/history/history';
import {WindowTool} from '@esgi/deprecated/utils';

export class CleverRegistrationService extends BaseService {
	private token: string;

	public form = new FormGroup({
		firstName: new FormControl('', {validators: [Validators.required()]}),
		lastName: new FormControl('', {validators: [Validators.required()]}),
        userName: new FormControl('', {validators: [Validators.required()]}),
        email: new FormControl('', {validators: [Validators.required(), Validators.email()]})
	});

	constructor() {
		super();
	}

	init() {
		this.token = WindowTool.getUrlQueryParameter('esgi-token');
		if (!this.token) {
            console.error('Esgi Token is required');
			document.location.href = '/';
		}
	}

    checkStatus() {
        return this.httpClient.SSO.get<BaseResponse<RegistrationResult>>('registration/create', 'clever/check-status', {
            token: this.token
        }).pipe(
            takeUntil(this.destroy$)
        );
    }

    reLoginToClever(cleverUrl: string) {
        AppHistory.navigateOutside(cleverUrl);
    }

	continue() {
        AppHistory.navigateOutside(`/complete-registration?esgi-token=${this.token}`);
	}
}
