import {useMemo} from 'react';
import {TeacherClassModel} from '../../../types/teacher-types';
import {DropdownOption} from '../../../kit';

export function useDropdownClassesOptions(classes: TeacherClassModel[]) {
	return useMemo<DropdownOption[]>(
		() => classes.map(({id, name}) => ({label: name, value: String(id)})),
		[classes],
	);
}
