import {Wrapper} from './index.styled';
import {CorrectAnswer} from '@esgi/ui/icons';

interface Props {
	checked: boolean;
	disabled: boolean;
}

export function SelectableLisItemCheckbox({checked, disabled}: Props) {
	return (
		<Wrapper
			data-cy='test-item-checkbox'
			checked={checked}
			disabled={disabled}
		>
			<CorrectAnswer/>
		</Wrapper>
	);
}
