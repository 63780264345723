import {useCallback, useRef, useState} from 'react';

export function useNoChangesConfirmationState({
	isDataTouched,
	setIsEditMode,
	onCloseAlert,
}: {
	isDataTouched: boolean;
	onCloseAlert: VoidFunction;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const [isOpenNoChangesConfirmation, setIsOpenNoChangesConfirmation] = useState(false);
	const closeAnywayNoChangesConfirmation = useRef<VoidFunction | null>(null);

	const setViewMode = () => {
		if (isDataTouched) {
			setIsOpenNoChangesConfirmation(true);
			closeAnywayNoChangesConfirmation.current = () => setIsEditMode(false);

			return;
		}

		setIsEditMode(false);
	};

	const onCloseNoChangesConfirmation = useCallback(() => {
		closeAnywayNoChangesConfirmation.current = null;
		setIsOpenNoChangesConfirmation(false);
	}, []);

	const handleCloseAlert = () => {
		if (isDataTouched) {
			setIsOpenNoChangesConfirmation(true);
			closeAnywayNoChangesConfirmation.current = onCloseAlert;

			return;
		}

		onCloseAlert();
	};

	return {
		handleCloseAlert,
		setViewMode,
		onCloseNoChangesConfirmation,
		isOpenNoChangesConfirmation,
		onCloseAnyway: closeAnywayNoChangesConfirmation.current,
	};
}
