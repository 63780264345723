import {BaseService} from '@esgi/core/service';
import {V2PersonalSubjectsController} from '@esgi/contracts/esgi';
import {SubjectTab, subjectsStore} from '@esgi/main/libs/store';
import {tap} from 'rxjs';

export class Service extends BaseService {
	private subjectStorage = subjectsStore();

	private readonly controller = new V2PersonalSubjectsController();

	public removePersonalTab(subjectID: SubjectTab['id']) {
		return this.controller.remove({subjectID}).pipe(
			tap(() => {
				setTimeout(() => this.subjectStorage.remove(s => s.id === subjectID), 100); //TODO HOTFIXNeeds to be rewritten.
			}),
		);
	}

	public updatePersonalTab({
		subjectID,
		subjectName,
		hidden,
	}: {
		subjectID: SubjectTab['id'];
		subjectName: string;
		hidden: boolean;
	}) {
		return this.controller.update({subjectID, name: subjectName, hidden}).pipe(
			tap(() =>
				this.subjectStorage.update((iteratedSubject) => {
					if (iteratedSubject.id === subjectID) {
						return {
							...iteratedSubject,
							name: subjectName,
							hidden,
						};
					}

					return iteratedSubject;
				}),
			),
		);
	}

	public override dispose() {
		this.controller.dispose();
	}
}
