import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

const FiltersPanelWidth = 54;

export const StyledContentContainer = styled(FlexBox, {
	position: 'relative',
	flexDirection: 'column',
	justifyContent: 'center',
	height: '100%',
	width: 'calc(100% - 400px)',
	padding: '28px 20px 0px 20px',
	backgroundColor: '$background',
	variants: {
		isSmallScreen: {
			true: {
				width: `calc(100% - ${FiltersPanelWidth}px)`,
			},
		},
	},
});

export const StyledQuestionListContainer = styled(Box, {
	position: 'relative',
	height: '100%',
	width: '100%',
	overflow: 'hidden',
});

export const SelectionPanelWrapper = styled(Box, {
	position: 'absolute',
	left: 0,
	right: 0,
	bottom: 0,
	zIndex: 1000,
});