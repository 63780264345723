import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {Text} from '@esgi/ui/typography';

export const LegendContainer = styled(Box, {
	paddingLeft: 12,
});

export const LegendItem = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'start',
	variants: {
		skeleton: {
			true: SkeletonStyles,
		},
	},
});

export const Container = styled(FlexBox, {
	padding: 12,
	height: '100%',
	variants: {
		noPadding: {
			true: {
				padding: 0,
			},
		},
		padding20: {
			true: {
				padding: '12px 20px',
			},
		},
	},
});

export const LegendText = styled(Text, {
	paddingLeft: 8,
});

export const PercentContainer = styled(FlexBox, {
	flexDirection: 'column',
	width: 32,
	alignItems: 'center',
	justifyContent: 'center',
});

export const Divider = styled(Box, {
	height: 1,
	width: '100%',
	background: '$mild',
	margin: '3px 0',
});