import {BehaviorSubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Import} from 'api/import-api';
import {BaseService} from '@esgi/core/service';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {StudentSortModel, TimezoneValue} from '../../user-settings/types';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {UserSettingsChangedEvent} from '../../user-settings/events';
import {createGeneralSectionForm} from '../../user-settings/forms/general-section';
import {
	DownloadImportFilesResponse,
	GlobalSchoolYearModel,
	InitResponse,
	ReportShowStudentID,
	UpdateRequest,
} from './types';

export class DistrictAdministratorSettingsService extends BaseService {

	public showExpiredUsers = new BehaviorSubject<boolean>(false);
	public generalSectionForm = createGeneralSectionForm();

	public sortValues = new BehaviorSubject<StudentSortModel[]>([]);
	public timeZones = new BehaviorSubject<TimezoneValue[]>([]);
	public reportShowStudentID = new BehaviorSubject<ReportShowStudentID>(ReportShowStudentID.None);

	public globalSchoolYears = new BehaviorSubject<GlobalSchoolYearModel[]>([]);
	public selectedGlobalSchoolYears = new BehaviorSubject<GlobalSchoolYearModel>({
		globalSchoolYearID: 0,
		name: '',
	});

	private eventBusManager = new EventBusManager();
	private controllerName = 'user-settings/district-admin';
	private initialShowExpiredUsers = false;
	private importApi = new Import();
	private userID;

	public init = (userID: number) => {
		this.userID = userID;
		return this.httpClient.ESGIApi.get<InitResponse>(this.controllerName, 'init', {})
			.pipe(tap(response => {
				const {
					timeZones,
					selectedTimeZone,
					sortBy,
					sortByVariants,
					isCleverAccountLinked,
					isOneClickAccountLinked,
					showExpiredUsers,
					globalSchoolYears,
					reportShowStudentID,
				} = response;

				this.initialShowExpiredUsers = showExpiredUsers;

				this.timeZones.next(timeZones);
				this.sortValues.next(sortByVariants);
				this.showExpiredUsers.next(showExpiredUsers);
				this.reportShowStudentID.next(reportShowStudentID);

				this.globalSchoolYears.next(globalSchoolYears);
				this.selectedGlobalSchoolYears.next(this.globalSchoolYears.value[0]);

				this.generalSectionForm.value = {
					sortBy: [response.sortByVariants.find(item => item.value === sortBy) || response.sortByVariants[0]],
					timeZone: [selectedTimeZone || timeZones[0]],
					isCleverAccountLinked,
					isOneClickAccountLinked,
				};
			}));
	};

	public updateUserSettings = () => {
		if (!this.generalSectionForm.controls.timeZone.value || !this.generalSectionForm.controls.sortBy.value) {
			return;
		}
		const request: UpdateRequest = {
			showExpiredUsers: this.showExpiredUsers.value,
			sortBy: this.generalSectionForm.controls.sortBy.value[0].value,
			timeZone: this.generalSectionForm.controls.timeZone.value[0].id,
			reportShowStudentID: this.reportShowStudentID.value,
		};

		return this.httpClient.ESGIApi.post(this.controllerName, 'update', request).pipe(tap(() => {
			dispatchAppEvent(UserSettingsChangedEvent, new UserSettingsChangedEvent(this.userID, request.sortBy, this.initialShowExpiredUsers !== this.showExpiredUsers.value, false));
		}));

	};

	public exportRosterClickHandler = () => {
		this.importApi.downloadImportFiles({globalSchoolYearId: this.selectedGlobalSchoolYears.value.globalSchoolYearID}).then((data: DownloadImportFilesResponse) => {
			const args: BackgroundDownloadManagerEvents.StartImportArgs = {fileID: data.id};
			this.eventBusManager.dispatch(BackgroundDownloadManagerEvents.StartImport, args);
		}).catch((err) => {
			console.error(err);
		});
	};
}