import {PreAssessHierarchySnapshot, SpecialistHierarchySnapshot} from '../../models';
import {IStudentBox, StudentModel} from '../api';

export function withStudentID(h: PreAssessHierarchySnapshot, studentID: number) {
  const n = {...h};
  n.studentID = studentID;
  return n;
}
export function withStudent(h: PreAssessHierarchySnapshot, student: StudentModel) {
  const n = {...h};
  if (!student) {
    n.studentID = 0;
    return n;
  }

  n.studentID = student.studentID;
  if (!student.specialistGroups.some(s => s === n.groupID)) {
    n.groupID = student.specialistGroups[0];
  }
  return n;

}
export function withGroupID(h: PreAssessHierarchySnapshot, groupID: number) {
  const n = {...h};
  n.groupID = groupID;
  n.studentID = 0;

  return n;
}

export function withUserID(h: PreAssessHierarchySnapshot, userID: number) {
  const n = {...h};
  n.userID = userID;
  n.groupID = 0;
  n.studentID = 0;
  return n;
}
