import {styled} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';

export const SelectField = styled(Select.Field, {
	'& [data-placeholder-content]': {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},

	'& [data-value-content]': {
		display: 'grid',
		minHeight: 20,
		alignItems: 'center',
		justifyContent: 'start',

		[`& > ${Text}`]: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
});
