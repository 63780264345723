import {RearrangeMode} from './components/rearrange-mode';
import {ViewMode} from './components/view-mode';

export const ModeContentVariant = {
	View: ViewMode,
	Rearrange: RearrangeMode,
};

export {type GroupStudentsViewModeContent, type SingleStudentViewModeContent} from './components/view-mode/types';

export {
	type GroupStudentsRerrangeModeContent,
	type SingleStudentRearrangeModeContent,
} from './components/rearrange-mode/types';
