import {BehaviorSubject, concatMap, Observable} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {SubjectType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {mapToEnum} from 'shared/utils';
import {SubjectInfo, SubjectsResponse, SubjectTab, Test, TestsResponse} from './models';
import {TestsSourceService} from './types';

export class TestSelectorService extends BaseService {
	public readonly subjects: BehaviorSubject<SubjectTab[]> = new BehaviorSubject<SubjectTab[]>([]);
	public readonly selectedSubject: BehaviorSubject<SubjectTab> = new BehaviorSubject<SubjectTab>(null);

	public readonly tests: BehaviorSubject<Test[]> = new BehaviorSubject<Test[]>([]);
	public readonly selectedTestsIDs: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

	constructor(private testsSourceService: TestsSourceService) {
		super();
	}

	public init(subjectID: number, subjectType: SubjectType, fullHierarchy: HierarchySnapshot, includeAllSubjects: boolean): Observable<any> {
		return this.httpClient.ESGIApi
			.get<SubjectsResponse>('TestSelector', 'SubjectTabs', {subjectID, subjectType, fullHierarchy})
			.pipe(
				concatMap((r) => {
					let source = r.deployedSubjects.filter(x => !x.hidden && x.gradeLevels.length > 0)
						.concat(r.stockSubjects.filter(x => !x.hidden))
						.concat(r.subjects.filter(x => !x.hidden))
						.map(s => ({...s, subjectType: mapToEnum(s.subjectType, SubjectType)}));

					if (includeAllSubjects) {
						source.unshift({
							id: 0,
							name: 'All',
							hidden: false,
							level: 'Teacher',
							subjectType: SubjectType.Personal,
							gradeLevels: [],
						});
					}

					this.subjects.next(source);

					let selectedSubjectInfo = {id: subjectID, subjectType};
					if (r.selectedSubject) {
						selectedSubjectInfo = {
							id: r.selectedSubject.id,
							subjectType: mapToEnum(r.selectedSubject.subjectType, SubjectType),
						};
					}

					let selectedSubject = source.find((subject) => subject.subjectType === selectedSubjectInfo.subjectType && subject.id === selectedSubjectInfo.id);

					this.selectedSubject.next(selectedSubject);
					return this.fetchTests([selectedSubject]);
				}),
				map(r => {
					this.storeTests(r, [this.selectedSubject.value]);
				}),
			)
			.asObservable();
	}

	public selectSubject(subject: SubjectTab) {
		let subjects = [subject];
		if(!subject.id) {
			subjects = [...this.subjects.value];
		}

		this.selectedSubject.next(subject);
		this.fetchTests(subjects).pipe(
			takeUntil(this.destroy$),
			map(r => this.storeTests(r, subjects)),
		).subscribe();
	}

	public selectTest(test: Test): void {
		if (this.selectedTestsIDs.value.includes(test.testID)) {
			this.selectedTestsIDs.next(this.selectedTestsIDs.value.filter(id => test.testID !== id));
		} else {
			this.selectedTestsIDs.next([...this.selectedTestsIDs.value, test.testID]);
		}
	}

	public toggleAllTests(): void {
		const allTestIDs = this.tests.value.map(v => v.testID);
		if(this.selectedTestsIDs.value.length) {
			if(this.selectedTestsIDs.value.length === allTestIDs.length) {
				this.selectedTestsIDs.next([]);
			} else {
				this.selectedTestsIDs.next(allTestIDs);
			}
		} else {
			this.selectedTestsIDs.next(allTestIDs);
		}
	}

	private storeTests(response: TestsResponse, subjects: SubjectTab[]): void {
		let tests = response.subjectTests.map(st => {
			const subject = subjects.find(s => s.id === st.subjectID);
			return st.tests.map((t) => {
				if(subjects.length > 1) {
					t.name += ' (' + subject.name + ')';
				}

				t.subjectID = subject.id;
				t.subjectType = subject.subjectType;
				t.subjectLevel = subject.level;
				return t;
			});
		}).flat(1);

		this.selectedTestsIDs.next(tests.filter((t) => t.isTested).map(t => t.testID));

		this.tests.next(tests);
	}

	private fetchTests(subjects: SubjectTab[]): Observable<TestsResponse> {
		return this.testsSourceService.fetchTests(subjects.map(item => new SubjectInfo(item)));
	}
}
