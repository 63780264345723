import {HttpClient} from '@esgi/api';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {EventBusDispatcher} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType} from '@esgi/core/authentication';
import {StudentManagerEvents} from '../../../../../../events';
import {MoveSelectedStudentRequest} from '../../../../../../models/models';
import DataService from '../../../../../../services/data-service';
import StudentsService from '../../../../../../services/students-service';
import {MoveStudentModel, SpecialistAssignModel} from './models';

export default class AssignService extends BaseService {
	constructor(private dataService: DataService, private studentsService: StudentsService) {
		super();
	}

	private checkIfStudentsCanBeAdded = (teacherID: number, newStudentsNumber: number): { teacherName: string, studentsMax: number, canBeAdded: boolean } | null => {
		let result = null;

		let schools = this.dataService.data.schools;
		for (let i = 0; i < schools.length; i++) {
			let school = schools[i];
			let teachers = school.teachers.filter(x => x.teacherID === teacherID);
			if (teachers.length > 0) {
				let teacher = teachers[0];
				let classIDs = teacher.classes.map(x => x.classID);
				if (classIDs.length === 0){
					result = {
						canBeAdded: false,
					};
					return result;
				}

				let studentsNumber = this.studentsService.getStudents().filter(x => x.classIDs.some(c => classIDs.indexOf(c) !== -1)).length;

				if (teacher.studentsMax < studentsNumber + newStudentsNumber) {
					result = {
						teacherName: teacher.firstName + ' ' + teacher.lastName,
						studentsMax: teacher.studentsMax,
						canBeAdded: true,
					};
				}

				return result;
			}
		}
		return result;
	}

	private checkIfStudentsCanBeAssignedBySpecialist = (specialistUserID: number, newStudentsNumber: number) => {
		let result = null;
		let specialist = this.dataService.data.specialists.find(x => x.userID === specialistUserID);
		let studentsNumber = this.studentsService.getStudents().filter(x => x.specialistGroupUserIDs.indexOf(specialist.userID) !== -1).length;

		if (specialist.studentsMax < studentsNumber + newStudentsNumber) {
			result = {SpecialistName: specialist.name, StudentsMax: specialist.studentsMax};
			return result;
		}

		return result;
	}

	public assignByPrimaryTeacher(teacherModel: MoveStudentModel, selectedStudentsIDs: number[]): Observable<void> {
		const newTeacherStudentsNumber = this.studentsService
			.getStudents(...selectedStudentsIDs)
			.filter(s => s.schoolID !== teacherModel.schoolID || s.primaryTeacherID !== teacherModel.teacherID)
			.length;

		if (teacherModel.teacherID > 0 && newTeacherStudentsNumber > 0) {
			let result = this.checkIfStudentsCanBeAdded(teacherModel.teacherID, newTeacherStudentsNumber);
			const {supportAddress, renewalUrl, allowUseCc} = this.dataService.data;
			const currentUser = userStorage.get();

			if (result) {
				let message = 'You have reached a maximum number of ' + result.studentsMax + ' students in ' + result.teacherName + '\'s classes.<br /><br />' +
					'You must remove a student from their class before you can add another one, or you can purchase additional students by going to ' + supportAddress + '.';
				if (!result.canBeAdded) {
					message = 'Sorry. You must have at least one class.';
				} else if (currentUser.userType === UserType.T){
					if (!allowUseCc) {
						message =
							'You have reached a maximum of ' + result.studentsMax + ' students. Your district has disabled the ability to purchase additional students. <br/><br/>' +
							'For more information, please contact us at <a href=\'' + supportAddress + '\' target=\'_blank\'>' + supportAddress + '</a> with error ID ESGI-11499.'
					} else {
						const renewUrl = 'https://support.esgisoftware.com/hc/en-us/articles/209158606-Purchasing-Additional-Student-Slots';
						message = 'You have reached a maximum of ' + result.studentsMax + ' students. You must remove a student from your class before you can add another one. <br/><br/>' +
							'To purchase additional students, please go to My Account, click RENEW and Add Students. <a href=\'' + renewUrl + '\' target=\'_blank\'>Read More</a>'
					}
				}

				OldAlerts.bsalert(message);

				return of();
			}
		}

		const request: MoveSelectedStudentRequest = {
			studentIDs: selectedStudentsIDs,
			classID: teacherModel.classID > 0 ? teacherModel.classID : null,
			groupID: teacherModel.groupID > 0 ? teacherModel.groupID : null,
			schoolID: teacherModel.schoolID,
			teacherID: teacherModel.teacherID > 0 ? teacherModel.teacherID : null,
		};

		return HttpClient.default.ESGIApi.post('student-explorer', 'move', request).pipe(map(() => {
			const student = this.studentsService.getStudents(selectedStudentsIDs[0])[0];
			const args = {
				studentsIDs: selectedStudentsIDs,
				studentName: student?.firstName + ' ' + student?.lastName,
				model: teacherModel,
			} as StudentManagerEvents.StudentsAssignedToTeacher;
			EventBusDispatcher.dispatch(StudentManagerEvents.StudentsAssignedToTeacher, args);
		})).asObservable();
	}

	public assignBySpecialistGroup(specialistModel: SpecialistAssignModel, selectedStudentsIDs: number[]): Observable<void> {
		let newSpecialistStudentsNumber = this.studentsService
			.getStudents(...selectedStudentsIDs)
			.filter(s => s.specialistGroupIDs.indexOf(specialistModel.specialistGroupID) === -1)
			.length;


		if (newSpecialistStudentsNumber > 0) {
			let result = this.checkIfStudentsCanBeAssignedBySpecialist(specialistModel.specialistUserID, newSpecialistStudentsNumber);
			const {supportAddress, renewalUrl, allowUseCc} = this.dataService.data;

			if (result) {
				let message = 'You have reached a maximum number of ' + result.StudentsMax + ' students in ' + result.SpecialistName + '\'s groups.<br /><br />' +
					'You must remove a student from their group before you can add another one, or you can purchase additional students by going to ' + supportAddress + '.';

				const currentUser = userStorage.get();
				if (currentUser.userType === UserType.T) {
					if (!allowUseCc) {
						message = 'You have reached a maximum number of ' + result.StudentsMax + ' students.<br /><br />' +
							'It is possible to purchase more students, but your district has disabled the ability to purchase via credit card.<br /><br />' +
							'Please contact us at ' + supportAddress + ' with error ID ESGI-11499 for more information.';
					} else {
						message = 'You have reached a maximum number of ' + result.StudentsMax + ' students in your group(s).<br /><br />' +
							'You must remove a student from your class before you can add another one, or you can increase the maximum number by <a href=\'' + renewalUrl + '\' target=\'_blank\'>purchasing additional students slots</a>.<br /><br />' +
							'Please contact us at ' + supportAddress + ' if you have any questions.';
					}
				}

				OldAlerts.bsalert(message);

				return of();
			}
		}

		return HttpClient.default.ESGIApi.post('student-explorer', 'assign-to-specialist', {
			specialistGroupID: specialistModel.specialistGroupID,
			studentIDs: selectedStudentsIDs,
		}).pipe(map(() => {
			const student = this.studentsService.getStudents(selectedStudentsIDs[0])[0];
			const args = {
				studentsIDs: selectedStudentsIDs,
				studentName: student?.firstName + ' ' + student?.lastName,
				model: specialistModel,
			} as StudentManagerEvents.StudentsAssignedToSpecialist;
			EventBusDispatcher.dispatch(StudentManagerEvents.StudentsAssignedToSpecialist, args);
		})).asObservable();
	}
}
