import React from 'react';
import styles from './grade-scale-legend.module.less';
import {ReportFiltersLabel} from '@esgi/deprecated/ui-kit/report/filters/report-filters-label';
import {ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters/report-filters-container';
import {GradeValue} from 'modules/reports/student-progress-report/models';
import {FiltersLabelBlock} from '../filters-label-block';
import {FiltersContentBlock} from '../filters-content-block';


interface Props {
	gradeValues: GradeValue[];
}

export function GradeScaleLegend(props: Props) {
	return <ReportFiltersContainer className={styles.legendBlock}>
			<FiltersLabelBlock>
				<ReportFiltersLabel>Grade Scale:</ReportFiltersLabel>
			</FiltersLabelBlock>
			<FiltersContentBlock className={styles.rowOrientation}>
						<div className={styles.gradeScaleLegend}>
							{
								props.gradeValues?.map((item) => <div title={item.description} className={styles.gradeScaleBlock}
								                                      style={{'backgroundColor': item.name !== 'NT' ? item.color : '#FFF'}}>
									{item.name}
								</div>)
							}
						</div>
			</FiltersContentBlock>
		</ReportFiltersContainer>;
}
