import {ThemeColorKeys} from '@esgi/ui';
import {NumericValue, StringValue, ValueType} from './types';

export function isStringValue(object: StringValue | NumericValue): object is StringValue {
	return typeof object.value === 'string';
}

export function resolveColor(type: ValueType): ThemeColorKeys{
	switch (type) {
		case 'positive': return 'positive';
		case 'neutral': return 'lowContrast';
		case 'negative': return 'negativeVivid';
	}
}

export function resolveValueType(value: number): ValueType {
	return value === 0 ? 'neutral' : value > 0 ? 'positive' : 'negative';
}