import {FlexBox} from '@esgillc/ui-kit/layout';
import {join} from '@esgillc/ui-kit/utils';
import styles from './navigation-bar.module.less';

interface Props {
	totalSteps: number;
	currentStep: number;
	nextStepEnabled: boolean;
	prevStepEnabled: boolean;
	nextClicked?: () => void;
	previousClicked?: () => void;
}

export default function NavigationBar(props: Props) {
	const prevBtnClassName = join(styles.btn, props.prevStepEnabled ? styles.btnEnabled : styles.btnDisabled);
	const nextBtnClassName = join(styles.btn, props.nextStepEnabled ? styles.btnEnabled : styles.btnDisabled);

	return <FlexBox className={styles.navigator}>
		<FlexBox className={prevBtnClassName} onClick={e => props.previousClicked && props?.previousClicked()}>
			<span className='glyphicon glyphicon-chevron-left arrow-icon'></span>
		</FlexBox>
		<FlexBox className={styles.label}>
			<span className={styles.currentStep}>{props.currentStep}</span> / {props.totalSteps}
		</FlexBox>
		<FlexBox className={nextBtnClassName} onClick={e => props.nextStepEnabled && props?.nextClicked()}>
			<span className='glyphicon glyphicon-chevron-right arrow-icon'></span>
		</FlexBox>
	</FlexBox>;
}
