import {isUndefined} from 'underscore';
import {DateFormatText, TimeText, Timestamp} from '../../../../kit';
import {DurationBox, Container} from './index.styled';

type Props = {
	/**
	 * in ms.
	 */
	duration?: number;
	isDeletedSession: boolean;
	date: string | Date;
	dateOnly?: boolean;
	dataCy?: string;
};

export function SessionDate({duration, isDeletedSession, date, dateOnly = false, dataCy = 'session-date'}: Props) {
	return (
		<Container dataCy={dataCy}>
			<DateFormatText
				dateFormat='MM/DD/YYYY'
				size='medium'
				font='mono'
				date={date}
				color={isDeletedSession ? 'muted' : 'base'}
				bold
				data-cy='date'
			/>
			{!dateOnly && <>
				<Timestamp
					date={date}
					backgroundColor={isDeletedSession ? 'muted' : 'lowContrast'}
					timeTextColor={isDeletedSession ? 'muted' : 'highContrast'}
					data-cy='time'
				/>
				{!isUndefined(duration) && (
					<DurationBox borderColor={isDeletedSession ? 'mild' : 'tertiaryHighlight'}>
						<TimeText
							time={duration / 1000}
							timeFormat='mm:ss'
							size='small'
							font='mono'
							bold
							color={isDeletedSession ? 'mild' : 'tertiary'}
							doRound
							data-cy='duration'
						/>
					</DurationBox>
				)}
			</>}
		</Container>
	);
}
