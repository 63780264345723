import React from 'react';
import './te-ads.less';

export class TeAds extends React.PureComponent {
	render() {
		return <div className='te-ads'>
            <main>
                <header>Looking for more tests?</header>
                <p>View over 2,000 pre-loaded tests!</p>
                <a href='/test-explorer' className='btn btn-primary test-explorer-link'>Visit Test Explorer</a>
            </main>
            <aside>
                <img src='//esgiwebfiles.s3.amazonaws.com/Images/te-ads.png?v=1' />
            </aside>
        </div>;
	}
}