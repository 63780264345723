import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

class EmptyBoxItemProps {
	title: string;
	tooltip: string;
}

export class EmptyBoxItem extends React.Component<EmptyBoxItemProps> {
	render() {
		return <div className='item empty'>
			<OnHoverTooltip message={this.props.tooltip}>
				<div className='title'>{this.props.title}</div>
			</OnHoverTooltip>
		</div>;
	}
}