import {FormControl} from '@esgi/ui/form';
import {Checkbox} from '@esgi/ui/form-controls';
import {SectionContent, SectionDescription, CheckboxFormElementWrapper} from './index.styled';

interface Props {
	checkboxControl: FormControl;
	description?: string;
	isDisabled?: boolean;
	dataCy?: string;
}

export function SingleCheckboxSection({checkboxControl, description, isDisabled, dataCy}: Props) {
	return <SectionContent dataCy={dataCy}>
		<CheckboxFormElementWrapper control={checkboxControl}>
			<Checkbox label={isDisabled ? 'Disabled' : 'Enabled'}/>
		</CheckboxFormElementWrapper>
		<SectionDescription data-cy='description' size='small' font='mono' color='neutral24'>
			{description}
		</SectionDescription>
	</SectionContent>;
}
