import {HttpClient, ObservableBuilder} from '@esgi/api';
import {Library, UploadImagesResponse} from './models';
import {Observable} from 'rxjs';

export class Api {
	static controller = 'assets/image-gallery';

	static InitHome() {
		return HttpClient.default.ESGIApi.get<HomeInitResponse>(this.controller, 'init-home');
	}

	static InitMain(source: Library, folderID?: number) {
		return HttpClient.default.ESGIApi.get<MainInitResponse>(
			this.controller,
			'init',
			{shared: source === Library.Stock, folderID: folderID},
		);
	}

	static GetFolderHierarchy() {
		return HttpClient.default.ESGIApi.get<FolderHierarchyResponse>(this.controller, 'get-folders-hierarchy');
	}

	static GetFolder(id: number, source: Library): ObservableBuilder<FolderResponse> {
		return HttpClient.default.ESGIApi.get<FolderResponse>(
			this.controller,
			'get-folder',
			{
				folderID: id,
				shared: source === Library.Stock,
			},
		);
	}

	static EditFolder(folderID: number, parentID: number, folderName: string) {
		return HttpClient.default.ESGIApi.post(
			this.controller,
			'edit-folder',
			{
				folderID: folderID,
				parentID: parentID,
				folderName: folderName,
			},
		);
	}


	static MoveItems(itemsIDs: number[], targetID?: number) {
		return HttpClient.default.ESGIApi.post<any>(
			this.controller,
			'move-items',
			{
				targetFolderID: targetID,
				movingItemsIDs: itemsIDs,
			},
		);
	}


	static CreateFolder(folderId: number, folderName: string) {
		return HttpClient.default.ESGIApi.post<{ id: number }>(
			this.controller,
			'create-folder',
			{
				folderID: folderId,
				name: folderName,
			},
		);
	}

	static UploadImages(files: File[], folderID?: number): Observable<UploadImagesResponse> {
		const data = new FormData();
		if (folderID) {
			data.append('folderID', folderID as any);
		}
		for (let i = 0; i < files.length; i++) {
			data.append('images', files[i]);
		}

		return HttpClient.default.ESGIApi.post<UploadImagesResponse>(this.controller, 'upload-images', data).asObservable();
	}

	static InitEditFolder(id: number): ObservableBuilder<EditFolderResponse> {
		return HttpClient.default.ESGIApi.get<EditFolderResponse>(this.controller, 'init-edit-folder', {folderID: id});
	}

	static Search(keyword: string, offset: number = 0) {
		return HttpClient.default.ESGIApi.get<{ images: Array<ImageItemResponse> }>(
			this.controller,
			'search-by-keyword',
			{
				keyword: keyword,
				offset: offset,
			},
		);
	}

	static SearchByTag(id: number) {
		return HttpClient.default.ESGIApi.get<{ images: Array<ImageItemResponse> }>(this.controller, 'search-by-tag', {tagId: id});
	}

	static GetImage(id: number) {
		return HttpClient.default.ESGIApi.get<ImageInfoResponse>(this.controller, 'get-image', {imageID: id});
	}

	static ImageDetail(imageID: number) {
		return HttpClient.default.ESGIApi.get<ImageDetailResponse>(this.controller, 'image-detail', {imageID: imageID});
	}

	static EditImage(imageID: number, folderID: number, tags: TagModel[], name: string) {
		return HttpClient.default.ESGIApi.post(this.controller, 'edit-image',
			{
				itemID: imageID,
				folderID: folderID,
				tags: tags,
				name: name,
			});
	}

	static SearchTags(keyword: string) {
		return HttpClient.default.ESGIApi.get<SearchTagsResponse>(this.controller, 'search-tags', {keyword: keyword});
	}

	static CopyImage(imageID: number, folderID?: number) {
		return HttpClient.default.ESGIApi.post(this.controller, 'copy-image', {imageID: imageID, destinationFolderID: folderID});
	}

	static DeleteItems(ids: number[]) {
		return HttpClient.default.ESGIApi.post(this.controller, 'delete-items', {itemIDs: ids});
	}
}

export interface FolderHierarchyResponse {
	folderInfos: FolderInfo[];
}

export interface ImageInfoResponse {
	height: number,
	width: number,
	url
}

export interface FolderInfo {
	id: number;
	name: string;
	subFolders: FolderInfo[];
}

export interface HomeInitResponse {
	privateCount: number;
	stockCount: number;
}

export interface BreadcrumbModelResponse {
	name: string;
	id: number;
}

export interface MainInitResponse {
	breadcrumbs: BreadcrumbModelResponse[];
	folder: FolderResponse;

}

export interface SearchTagsResponse {
	Tags: TagModel[];
}

export interface InitEditImageResponse {
	ImageName: string,
	Tags: TagModel[]
}

export interface TagModel {
	id: number,
	name: string
}

export interface FolderResponse {
	id: number;
	parentID: number;
	isShared: boolean;
	name: string;
	folders: FolderItemResponse[];
	images: ImageItemResponse[];
}

export interface EditFolderResponse {
	movable: boolean;
}

export interface FolderItemResponse extends BaseItemResponse {
	subItemsCount: number;
}

export interface ImageItemResponse extends BaseItemResponse {
	normalUrl: string;
	thumbUrl: string;
	width: number;
	height: number;
	createDate: Date;
}

interface BaseItemResponse {
	id: number;
	name: string;
	canDelete: boolean;
}

export interface ImageDetailResponse {
	imageData: ImageData;
	folderInfos: FolderInfo[];
}

export interface ImageData {
	url: string;
	name: string;
	folderID?: number;
	isStock: boolean;
	tags: TagModel[];
}
