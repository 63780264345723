import {Profile} from '@esgi/ui/icons';
import {Avatar} from '@esgi/ui/avatar';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {getInitials} from '../../utils';

type Props = {
	firstName: string;
	lastName: string;
};

export function AvatarFallback({firstName, lastName}: Props) {
	return (
		<Avatar.Fallback>
			<GridBox gap='1'>
				<Profile width={24} height={24} />
				<Text size='small' color='neutral16'>
					{getInitials(firstName, lastName)}
				</Text>
			</GridBox>
		</Avatar.Fallback>
	);
}
