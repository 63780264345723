import * as React from 'react';
import type {SVGProps} from 'react';

export function Setting(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16.001 13.59a2 2 0 1 0 0 3.999 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M12.969 6.045A9.671 9.671 0 0 0 9.25 8.233c-.333.31-.435.818-.219 1.218.801 1.48-.037 3.066-1.844 3.157-.442.022-.833.35-.937.78A8.71 8.71 0 0 0 6 15.577c0 .687.074 1.465.219 2.157.09.432.466.743.906.78 1.818.158 2.718 1.545 1.906 3.313-.18.393-.098.863.219 1.157 1.062.982 2.282 1.675 3.719 2.125.41.128.873-.027 1.125-.375 1.112-1.54 2.725-1.544 3.781 0 .249.363.705.538 1.125.406a10.1 10.1 0 0 0 3.75-2.156c.33-.301.417-.788.219-1.188-.832-1.68.125-3.22 1.843-3.25.456-.008.862-.307.969-.75.173-.717.219-1.341.219-2.22 0-.753-.089-1.496-.25-2.217a.994.994 0 0 0-.969-.782c-1.691-.003-2.639-1.665-1.812-3.125a.977.977 0 0 0-.188-1.218c-1.09-.99-2.419-1.764-3.812-2.188a.986.986 0 0 0-1.125.406c-.966 1.5-2.77 1.527-3.719.032a1.023 1.023 0 0 0-1.156-.438Zm6.078 2.124a7.904 7.904 0 0 1 1.841 1.054c-.726 2.297.505 4.683 3.055 5.264.063.413.057.663.057 1.089 0 .509.007.688-.051 1.056-2.539.526-3.795 2.845-3.088 5.296-.608.44-1.043.747-1.806 1.042-1.792-1.824-4.28-1.904-6.11.012-.714-.314-1.263-.593-1.818-1.062.688-2.49-.475-4.638-3.057-5.29-.115-.456-.069-1.744-.003-2.135 2.67-.64 3.722-3.003 3.059-5.28.586-.44 1.113-.761 1.798-1.04 1.725 1.756 4.315 1.93 6.123-.006Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
