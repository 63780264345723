import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {SubjectType} from '@esgi/core/enums';
import {GoogleAnalyticsTracker} from '@esgi/core/tracker';
import {ReportSettingsService} from './report-settings-service';
import {ReportDataService} from './report-data-service';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {ISDRSettings, DetailReportServiceTransfer, SettingsInit} from '../models/models';
import {RequestsSettingsInit, ResponsesSettingsInit} from '../models/api-models';
import {map} from 'rxjs/operators';

export interface Settings {
	classId: number;
	groupId: number;
	studentId: number;
	subjectId: number;
	subjectType: SubjectType;
	testIDs: number[];
	globalSchoolYearID: number;
}

export class ReportService extends BaseService {
	public settings = new BehaviorSubject<ISDRSettings>(null);
	public testIDs = new BehaviorSubject<number[]>(null);
	public dataService = new ReportDataService();
	public settingsService = new ReportSettingsService(this.dataService);

	public init(settings: ISDRSettings, hierarchy: HierarchyInstance) {
		this.settings.next(settings);
		this.testIDs.next(settings.testIDs);
		this.settingsService.init(settings, hierarchy.snapshot);
		this.settingsService.onChange.subscribe((data: DetailReportServiceTransfer) => {
			const {settings, testIDs} = data;
			this.settings.next(settings);
			this.testIDs.next(testIDs);
			this.dataService.init(data, hierarchy.snapshot);
		});
		GoogleAnalyticsTracker.trackEvent('StudentDetailReport');
		const requestModel = new RequestsSettingsInit();
		requestModel.subjectID = this.settings.value.subjectId;
		requestModel.subjectType = this.settings.value.subjectType;
		requestModel.hierarchy = hierarchy.snapshot;
		requestModel.subjectID = this.settings.value.subjectId;
		requestModel.subjectType = this.settings.value.subjectType;
		requestModel.testIDs = this.testIDs.value;
		return this.httpClient.ESGIApi.get('reports/student-detail', 'Init', requestModel).pipe(map((data: ResponsesSettingsInit) => {
			const settings = SettingsInit.FromResponse(data);
			this.settingsService.update(settings);
			this.dataService.update(settings);
		}));
	}
}
