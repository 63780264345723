import {Avatar} from '@esgi/ui/avatar';
import {GridBox} from '@esgi/ui/layout';
import {avatarRootCss} from './components.styled';
import {getFullName, getInitials} from '@esgi/main/kits/assignments';
import {SkeletonShape} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {ComponentProps} from 'react';

type Props = {
	firstName: string;
	lastName: string;
	photoUrl: string | undefined;
	skeleton?: boolean;
	studentNameColor: NonNullable<ComponentProps<typeof Text>['color']>;
};

export function StudentInfo({skeleton, firstName, lastName, photoUrl, studentNameColor}: Props) {
	return (
		<GridBox flow='column' gap='3' align='center' justify='start'>
			<Avatar.Root size='xs' css={avatarRootCss} skeleton={skeleton}>
				<Avatar.Image src={photoUrl} />
				<Avatar.Fallback>
					<Text size='small' color='neutral16'>
						{getInitials({
							firstName,
							lastName,
						})}
					</Text>
				</Avatar.Fallback>
			</Avatar.Root>

			{skeleton ? (
				<SkeletonShape width={90} height={12} />
			) : (
				<Text size='small' color={studentNameColor}>
					{getFullName({
						firstName,
						lastName,
					})}
				</Text>
			)}
		</GridBox>
	);
}
