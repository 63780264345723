import {FormField, Validators} from '@esgi/deprecated/knockout';
import {FieldContainer} from '../../../kit/component';
import {Dictionaries} from '../../server';

export class CountrySelectComponent extends FieldContainer<number> {
	protected declare countries: KnockoutObservableArray<CountryModel>;
	declare selectedCountryID: KnockoutObservable<number>;

	constructor(initValue) {
		super(initValue);
	}

	buildField(initValue?): FormField<number> {
		this.countries = ko.observableArray<CountryModel>();
		this.selectedCountryID = ko.observable<number>(initValue);
		const f = this.createField<number>(initValue, Validators.Required());
		f.disabled = ko.computed(() => {
			return !this.countries().length;
		});
		f.value.subscribe((country: number) => {
			this.selectedCountryID(country);
			return country;
		});
		f.afterRender(this.rootElement).done(() => this.loadCountries());
		return f;
	}

	private loadCountries() {
		return Dictionaries.loadCountries().done((countries) => {
			this.countries(countries.countries);
		});
	}


	public template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group country'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<div>
						<select id='country' name='country' className='form-control'
						        data-bind="options: root.countries,optionsValue:'countryID', optionsText: 'name', value: value, valueAllowUnset: true, disable: disabled"/>

						<div className='error-message visible-xs visible-sm hidden-md'>
							<span data-bind='text:validation.validationResults'/>
						</div>
					</div>
				</div>
			</div>
		</div>
	};
}

export class CountryModel {
	countryID: number;
	name: string;
}
