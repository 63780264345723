export enum AssignmentState {
	None = 'None',
	Published = 'Published',
	NotStarted = 'NotStarted',
	Completed = 'Completed',
	Draft = 'Draft',
}

export enum SortDirection {
	Asc = 'asc',
	Desc = 'desc',
}
