import React, {ReactNode} from 'react';
import styles from './template-preview.module.less';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
	children: ReactNode;
}

export default class TemplatePreview extends React.PureComponent<Props> {
	render() {
		return <div className={join(this.props.className, styles.previewContainer)}>
			<div className={styles.template}>
				<div>{this.props.children}</div>
			</div>
		</div>;
	}
}