import {useState} from 'react';
import {RoutesMap} from '@esgi/main/libs/core';
import {useNavigate} from 'react-router-dom';
import {useEventEffect} from '@esgillc/events';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal, Alert} from '@esgillc/ui-kit/modal';
import {ShowNotAuthorizedAlertEvent} from './events';

export function NotAuthorizedAlertLayer() {
	const [showAlert, setShowAlert] = useState(false);
	const modal = useModal();
	const navigate = useNavigate();
	const close = useCloseModal(modal, () => {
		setShowAlert(false);
		navigate(RoutesMap.login);
	});

	useEventEffect(ShowNotAuthorizedAlertEvent, () => setShowAlert(true));

	if (showAlert) {
		return <Alert modalManagerRef={modal} unsafeDesiredZIndex={11000}>
			<Alert.Body>
				You are not authorized or your session is expired.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={close}>
					OK
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}
	return <></>;
}
