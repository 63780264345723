import React, {useEffect, useRef, useState} from 'react';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {StudentItem} from '../../../../types/types';
import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {Search} from '../search';
import {ViewAll} from '../view-all';

interface Props<StudentInfo extends StudentItem> {
	selectedStudents: StudentInfo[];
	setSelectedStudents: (value: StudentInfo[]) => void;
	setPrevSelectedStudents: (value: StudentInfo[]) => void;
	students: StudentInfo[];
	showAddSpecific: () => void;
}

export function StudentsDropdown<StudentInfo extends StudentItem>({
	selectedStudents,
	setSelectedStudents,
	students,
	showAddSpecific,
	setPrevSelectedStudents,
}: Props<StudentInfo>) {
	const [search, setSearch] = useState<string>('');
	const [filteredStudents, setFilteredStudents] = useState<StudentInfo[]>(students);
	const [innerValue, setInnerValue] = useState<StudentInfo[]>(selectedStudents);
	const ref = useRef<Dropdown<any>>(null);
	const placeholder = 'Select students';

	const onCancel = () => {
		setInnerValue(selectedStudents);
		closeDropdown();
	};

	const closeDropdown = () => {
		setSearch('');
		ref.current?.closeDropdown();
	};

	const onSave = () => {
		setPrevSelectedStudents([]);
		setSelectedStudents(innerValue);
		closeDropdown();
	};

	const onChange = (value: StudentInfo[]) => {
		setPrevSelectedStudents(value);
		setInnerValue(value);
	};

	useEffect(() => {
		setInnerValue(selectedStudents);
	}, [selectedStudents]);

	useEffect(() => {
		setFilteredStudents(search === ''
			? students : students.filter(student => student.name.toLowerCase().includes(search.toLowerCase())));
	}, [search, students]);

	const selectedStudentsIDs = selectedStudents.map(s => s.id);
	const options = filteredStudents.filter(s => !selectedStudentsIDs.includes(s.id));

	return (
		<Dropdown
			ref={ref}
			multiselect={true}
			optionName='name'
			value={innerValue}
			setValue={onChange}
			placeholder={placeholder}
			autoHeight
			displayLabel={() => placeholder}
			validateOnBlur={() => {
				setSearch('');
			}}
		>
			<div className={styles.searchContainer}>
				<Search className={styles.search} value={search} onChange={setSearch}/>
				<ViewAll onClick={() => {
					showAddSpecific();
					closeDropdown();
				}}/>
			</div>
			<div className={styles.mainContainer}>
				{options.map((student) => (
					<Option
						className={styles.option}
						key={`student-${student.id}`}
						value={student}
					>
						{student.name}
					</Option>
				))}
			</div>
			<div className={styles.footerContainer}>
				<Buttons.Gray onClick={onCancel}>Cancel</Buttons.Gray>
				<Buttons.Contained onClick={onSave}>Save</Buttons.Contained>
			</div>
		</Dropdown>
	);
}
