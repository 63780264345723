import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const StudentManagerLink = styled(Text, {
	color: '$primary',
	marginLeft: '5px',
	cursor: 'pointer',

	'&:hover': {
		textDecoration: 'underline',
	},
});