import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

type Props = {
	onAlertClose: VoidFunction;
	validationData: string;
};

export function InvalidField({onAlertClose, validationData}: Props) {
	return (
		<>
			<Alert.Header>
				<Text size='large'>The file contains an invalid field.</Text>
			</Alert.Header>
			<Alert.Body>{validationData && <Text size='medium'>Errors: {validationData}</Text>}</Alert.Body>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
