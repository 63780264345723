import React from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {ContextMenuState} from '../context-menu/context-menu';
import {BaseCardState} from './base-card';
import {ContextMenu} from './context-menu';

export class Props extends SharedProps<BaseCardState> {
	click: (id: number) => any;
	editClicked: () => any;
	deleteClicked: (id: number) => any;
}

export class FolderCard extends SharedComponent<BaseCardState, Props> {
	private ellipsisMenu: HTMLAnchorElement;

	render(): JSX.Element {
		return <div className={'folder-card card-item-' + this.state.id + (this.state.deleted ? ' deleted' : '')}>
			<svg className='esgi-icon folder'>
				<svg width='210' height='132' viewBox='0 0 210 132' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<g filter='url(#filter0_ddd)'>
						<path d='M192.404 125H17.2979C10.6704 125 5.29785 119.627 5.29785 113V31.5C5.29785 24.8726 10.6704 19.5 17.2979 19.5H123.764C127.243 19.5 130.551 17.99 132.83 15.3613L139.093 8.13871C141.372 5.51004 144.68 4 148.159 4H192.404C199.032 4 204.404 9.37258 204.404 16V113C204.404 119.627 199.032 125 192.404 125Z' fill='white'/>
						<path d='M17.2979 124.5H192.404C198.756 124.5 203.904 119.351 203.904 113V16C203.904 9.64873 198.756 4.5 192.404 4.5H148.159C144.825 4.5 141.655 5.94712 139.47 8.46627L133.208 15.6888C130.834 18.427 127.388 20 123.764 20H17.2979C10.9466 20 5.79785 25.1487 5.79785 31.5V113C5.79785 119.351 10.9466 124.5 17.2979 124.5Z' stroke='#BDBDBD'/>
					</g>
					<defs>
						<filter id='filter0_ddd' x='0.297852' y='0' width='209.106' height='132' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
							<feFlood floodOpacity='0' result='BackgroundImageFix'/>
							<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/>
							<feOffset dy='1'/>
							<feGaussianBlur stdDeviation='2.5'/>
							<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
							<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/>
							<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/>
							<feOffset dy='3'/>
							<feGaussianBlur stdDeviation='2'/>
							<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/>
							<feBlend mode='normal' in2='effect1_dropShadow' result='effect2_dropShadow'/>
							<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/>
							<feOffset dy='2'/>
							<feGaussianBlur stdDeviation='2'/>
							<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/>
							<feBlend mode='normal' in2='effect2_dropShadow' result='effect3_dropShadow'/>
							<feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow' result='shape'/>
						</filter>
					</defs>
				</svg>
			</svg>
			<div className='content clickable' onClick={() => this.props.click(this.state.id)}>
                        <span className='folder-name'>
                            {this.state.name}
                        </span>
			</div>
			{!this.state.stock && <div className='actions-row'>
				<a className='dots-btn' onClick={() =>
					this.showDropdownMenu()
				} ref={r => this.ellipsisMenu = r}>
					<svg className='esgi-icon dots' viewBox='0 0 16 16'>
						<path fill='#666666'
						      d='m8,4.75c0.8937,0 1.625,-0.73125 1.625,-1.625c0,-0.89375 -0.7313,-1.625 -1.625,-1.625c-0.8938,0 -1.625,0.73125 -1.625,1.625c0,0.89375 0.7312,1.625 1.625,1.625zm0,1.625c-0.8938,0 -1.625,0.73125 -1.625,1.625c0,0.8938 0.7312,1.625 1.625,1.625c0.8937,0 1.625,-0.7312 1.625,-1.625c0,-0.89375 -0.7313,-1.625 -1.625,-1.625zm0,4.875c-0.8938,0 -1.625,0.7312 -1.625,1.625c0,0.8938 0.7312,1.625 1.625,1.625c0.8937,0 1.625,-0.7312 1.625,-1.625c0,-0.8938 -0.7313,-1.625 -1.625,-1.625z'/>
					</svg>
				</a>
			</div>}
			{this.renderContextMenu()}
		</div>;
	}

	renderContextMenu() {
		if (!this.state.contextMenu) {
			return null;
		}
		let coordinates = this.getOffsetRelativeToBody(this.ellipsisMenu);

		return <ContextMenu
			itemID={this.state.id}
			close={() => this.closeContextMenu()}
			x={coordinates.left}
			y={coordinates.top}
			element={this.ellipsisMenu}
			state={this.state.contextMenu}
			editClicked={() => {
				this.closeContextMenu();
				this.props.editClicked();
			}}
			removeClicked={() => {
				this.closeContextMenu();
				this.props.deleteClicked(this.state.id);
			}}
			onChange={(ch, cb) => this.setState({contextMenu: ch}, cb)}/>;
	}

	private closeContextMenu() {
		this.setState({contextMenu: null});
	}

	private showDropdownMenu() {
		if (!this.state.contextMenu) {
			this.setState({contextMenu: new ContextMenuState()});
		}
	}

	getOffsetRelativeToBody(el: HTMLElement) {
		let rect = el.getBoundingClientRect(),
			scrollLeft = (window.pageXOffset || document.documentElement.scrollLeft) - 158,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return {top: rect.top + scrollTop + 15, left: rect.left + scrollLeft + 20};
	}
}