import {useState, useEffect} from 'react';
import {
	SettingsResponse,
	Student as StudentType,
	Subject as SubjectType,
	FilterStatus,
	FilterSession,
} from '../../types';
import {getGroupAndStudent} from '../../utils';
import {tryCall} from '@esgillc/ui-kit/utils';
import {Student} from './components/student';
import {Subject} from './components/subject';
import {Status} from './components/status';
import {Baseline} from './components/baseline';
import {Session} from './components/session';
import styles from './styles.module.less';

interface Props {
	data?: Partial<State>;
	settings: SettingsResponse;
	onChange: (data) => void;
}

interface State {
	student: StudentType;
	subject: SubjectType;
	status: FilterStatus;
	showBaseline: boolean;
	from: Date | string;
	to: Date | string;
	session: number;
	period: number;
	group: string;
}

export const Filter = (props: Props): JSX.Element => {
	const {data, settings} = props;
	const result = getGroupAndStudent(
		data.student.id,
		settings.groups,
	);
	const [state, setState] = useState<Partial<State>>({
		...data,
		status: FilterStatus.All,
		from: null,
		to: null,
		group: result ? result.group.name : settings.groups[0].name,
		session: FilterSession.Date,
		period: 0,
	});

	useEffect(
		() => tryCall(props.onChange, state),
		[state],
	);

	return (
		<div className={styles.filter}>
			<div className={styles.rowFirst}>
				<Student
					settings={settings}
					value={state.student}
					onChange={(student) => setState({...state, ...student})}
				/>
				<Subject
					settings={settings}
					value={state.subject}
					onChange={(subject) => setState({...state, subject})}
				/>
			</div>
			<div className={styles.rowSecond}>
					<Status
						value={state.status}
						onChange={(status) => setState({...state, status})}
					/>
					<Baseline
						value={state.showBaseline}
						onChange={(showBaseline) => setState({...state, showBaseline})}
					/>
					<Session
						settings={settings}
						onChange={(session) => setState({...state, ...session})}
					/>
			</div>
		</div>
	);
};
