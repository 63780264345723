import {IconComponent} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Container} from './index.styled';
import {Property} from '@stitches/react/types/css';
import {ComponentProps} from 'react';

type Props = ComponentProps<typeof Text> & {
	Icon: IconComponent;
	iconWidth?: Property.Width;
	iconHeight?: Property.Height;
	viewBox?: string;
};

export function TextWithIcon({Icon, color = 'neutral56', iconWidth = 24, iconHeight = 24, viewBox: defaultViewBox, ...textProps}: Props) {
	const viewBox = defaultViewBox ?? `0 0 ${iconWidth} ${iconHeight}`;
	return (
		<Container css={{color: color === 'currentColor' ? 'currentColor' : `$${color}`}}>
			<Icon width={iconWidth} height={iconHeight} viewBox={viewBox} />
			<Text color={color} {...textProps} />
		</Container>
	);
}
