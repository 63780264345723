import {AsyncStatus} from '@esgi/ui';
import {IndicatorType} from '@esgi/ui/controls';

export function getInputIndicatorByAsyncStatus({
	asyncStatus,
	hasError,
	isInitialValue,
}: {
	asyncStatus: AsyncStatus;
	hasError: boolean;
	isInitialValue: boolean;
}) {
	const statuses: Record<AsyncStatus, IndicatorType> = {
		[AsyncStatus.Idle]: IndicatorType.None,
		[AsyncStatus.Pending]: IndicatorType.Pending,
		[AsyncStatus.Succeeded]: IndicatorType.Succeeded,
		[AsyncStatus.Failed]: IndicatorType.Failed,
	};

	if (isInitialValue && !hasError) {
		return IndicatorType.None;
	}

	if (hasError) {
		return IndicatorType.Failed;
	}

	return statuses[asyncStatus];
}
