import React, {createRef} from 'react';
import {Button, PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import RubricService from '../../../common/rubric-service';
import RubricPreviewForm from '../../../common/preview-form/rubric-preview-form';
import styles from './preview.module.less';

interface Props {
	rubricService: RubricService;
	onBackClicked: () => void;
	onSaveClicked: () => void;
}

export default class Preview extends React.PureComponent<Props> {
	private modalManagerRef: ModalManagerRef = createRef();

	public render() {
		const {name, description} = this.props.rubricService.testInfo$.value;
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader className={styles.header}>
				<Title className={styles.title}>
					Preview
				</Title>
			</ModalHeader>
			<ModalBody className={styles.body}>
				<div className={styles.innerBody}>
					<div className={styles.infoRow}>
						<div className={styles.name}>
							<span>
								{name}
							</span>
						</div>
						{name?.trim() && description?.trim() && <div className={styles.divider}>
							:
						</div>}
						<div className={styles.description}>
							<span>
								{description}
							</span>
						</div>
					</div>
					<div className={styles.innerBody}>
						<RubricPreviewForm rubricService={this.props.rubricService}/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className={styles.footer}>
					<Button onClick={() => this.modalManagerRef.current.close(this.props.onBackClicked)}>
						BACK
					</Button>
					<PrimaryButton onClick={this.props.onSaveClicked}>
						SAVE
					</PrimaryButton>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
