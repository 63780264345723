import {ProgressBar} from '@esgi/ui';
import {useMemo} from 'react';
import {isNull} from 'underscore';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
    value: number | null;
    maxValue: number;
    result: number | null;
    isPercentageType: boolean;
};

export function ProgressWithPointer({value, maxValue, isPercentageType, result}: Props) {
	const valueInt = value ?? 0;

	const {progressValueTranscript, decimalValue} = useMemo(() => {
		if (isNull(result)) {
			return {
				progressValueTranscript: '-',
				decimalValue: null,
			};
		}

		if (isPercentageType) {
			return {
				progressValueTranscript: `${Math.round(result)}%`,
				decimalValue: null,
			};
		}

		const [progressValueTranscript, decimalValue] = result.toFixed(1).split('.');

		return {
			progressValueTranscript,
			decimalValue,
		};
	}, [result, isPercentageType]);

	const isProgressSuccess = valueInt / maxValue > 0.5;

	return (
        <ProgressBar value={valueInt} maxValue={Math.max(maxValue, 1)}>
            <ProgressBar.RingWithPointer
                pointerFillColor={isProgressSuccess ? 'green' : 'lowContrast'}
                progressValueColor={isProgressSuccess ? 'positiveVivid' : 'lowContrast'}
            >
                <FlexBox align={decimalValue ? 'baseline' : 'center'}>
                    <Text size='medium' bold font='mono' color={isProgressSuccess ? 'green' : 'base'}>
                        {progressValueTranscript}
                    </Text>
                    {decimalValue && (
                        <Text size='xSmall' bold font='mono' color={isProgressSuccess ? 'green' : 'base'}>
                            {'.'}
                            {decimalValue}
                        </Text>
                    )}
                </FlexBox>
            </ProgressBar.RingWithPointer>
        </ProgressBar>
	);
}
