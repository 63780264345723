import {Dispatch, useCallback, useState} from 'react';
import {StudentNameWithCheckbox} from './student-name-with-checkbox';
import {CheckAll, Folder} from '@esgi/ui/icons';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';

export function ClassesList() {
	const {classBuckets} = StudentsPanel.Tabs.Classes.useClassesContext();
	const [openedClassIDs, setOpenedClassIDs] = useState<number[]>([]);

	const selectClass = useCallback<Dispatch<number>>(
		(id) =>
			setOpenedClassIDs((values) => {
				if (values.includes(id)) {
					return values.filter((v) => v !== id);
				} else {
					return [...values, id];
				}
			}),
		[],
	);

	const {
		selected: {studentIDs: selectedStudentsIDs, classIDs: selectedClassIDs},
		toggleStudentId: selectStudent,
		toggleClassId: selectClassStudents,
	} = StudentsPanel.Selection.MultiSelect.useMultiSelectContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			{classBuckets.length ? (
				classBuckets.map(({id, name: classLabel, students}) => {
					const isClassOpened = openedClassIDs.includes(id);

					return (
						<StudentsPanel.Lists.SelectableListGroup.Expandable.Multiple
							withCounter
							isListOpen={isClassOpened}
							key={id}
							hideArrowIcon
							onTriggerButtonClick={() => selectClass(id)}
							groupName={classLabel}
							value={selectedStudentsIDs}
							isListSelected={selectedClassIDs.includes(id)}
							items={students}
							onItemClick={(studentId) => selectStudent(studentId)}
							icon={[
								{
									Component: Folder,
								},
								{
									Component: CheckAll,
									variant: 'styled-button',
									onClick: () => selectClassStudents(id),
								},
							]}
						>
							{({item: {firstName, lastName, id}}) => (
								<StudentNameWithCheckbox
									checked={selectedStudentsIDs.includes(id)}
									firstName={firstName}
									lastName={lastName}
								/>
							)}
						</StudentsPanel.Lists.SelectableListGroup.Expandable.Multiple>
					);
				})
			) : (
				<StudentsPanel.Tabs.EmptyTabContent
					size='full'
					templateText='No classes to display'
					dataCy='students-panel-empty-default-content'
				/>
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
