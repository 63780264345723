import {Button, useStreamEffect} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Input} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {AddButton, AlertBody, Description} from './index.styled';
import {LanguageItem} from '../../index';

import {
	Form,
	FormControl,
	FormElement,
	FormGroup,
	useForm,
	ErrorTooltip, Validators,
} from '@esgi/ui/form';
import {useCallback, useState} from 'react';

interface Props {
	onClose: VoidFunction;
	onAddClick: (name: string) => void;
	languages: LanguageItem[];
}

export function AddLanguageAlert({onClose, onAddClick, languages}: Props) {
	const alertRef = Alert.useRef();
	const handleClose = Alert.useClose(alertRef, onClose);
	const [isDisabledAdd, setDisabledAdd] = useState(true);

	const form = useForm(
		() =>
			new FormGroup({
				title: new FormControl<string>('', {
					validators: [Validators.isDublicateValue(languages.map(({name}) => name))],
				}),
			})
	);

	const handleAddClicked = useCallback(() => {
		onAddClick(form.controls.title.value);
		handleClose();
	}, [form.controls.title.value, handleClose, onAddClick]);

	useStreamEffect(form.controls.title.onChanged, ({currState: {value}}) => {
		const isAddingActive = value && !languages.find((lang) => lang.name === form.controls.title.value);
		setDisabledAdd(!isAddingActive);
	});

	return (
		<Form controller={form}>
			<Alert modalManagerRef={alertRef}>
				<Alert.Header
					withBacklight={false}
					withCloseIcon
					onCloseIconClick={handleClose}
				>
					<Text size='small' color='base' bold>
						Add a language
					</Text>
				</Alert.Header>

				<AlertBody>
					<Description size='medium' color='neutral40'>
						Enter the name of the language you would like to add.
					</Description>
					<FormElement control={form.controls.title}>
						<Input.Base placeholder='Language Name' />
						<ErrorTooltip showOnError='isDublicateValue'>
							<Text size='xSmall' font='mono' color='negativeVivid'>
								Please choose a unique name.
							</Text>
						</ErrorTooltip>
					</FormElement>
				</AlertBody>

				<Alert.Footer align='center'>
					<Button color='tertiary' onClick={handleClose}>
						<Text size='medium' color='base' bold>
							Cancel
						</Text>
					</Button>

					<AddButton color='secondary' onClick={handleAddClicked} disabled={isDisabledAdd}>
						<Text size='medium' color='base' bold>
							Add
						</Text>
					</AddButton>
				</Alert.Footer>
			</Alert>
		</Form>
	);
}
