import {Form} from '@esgi/deprecated/knockout';

export class SchoolNameComponent extends Form {
	schoolName: KnockoutObservable<string> = ko.observable(null);

	setValue(value: string) {
		this.schoolName(value);
	}

	template = () => {
		return <div data-bind='if: schoolName'>
			<label>School</label>
			<div className='form-group' data-bind='afterRender: true'>
				<input type='text' className='form-control input-md' data-bind='value: schoolName, disable: true' />
			</div>
		</div>;
	};
}
