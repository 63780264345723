import {useCallback, useEffect, useMemo, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {
	HeaderLabel,
	HeaderRow,
	IconBox,
	IndicatorWrapper,
	ItemTagAndTitle,
	Options,
	SelectableListGroup,
	SelectableListGroupRoot,
	SelectableListRoot,
	SelectableListTrigger,
	SelectableListTriggerContent,
	TestColorIndicator,
	TriggerWrapper,
} from '../../index.styled';
import {AccordionCollapse, AccordionExpand} from '@esgi/ui';
import {StudentListItem} from './components/student-item';
import {StudentItem, TestItem, TestListDataItem} from '@esgi/main/features/untested-students-report';
import {StudentModel} from '@esgi/main/features/assessments';
import {TestTypeLabel} from '@esgi/main/kits/reports';
import {Results} from '../results';

interface Props {
	test: TestListDataItem,
	showAllStudents: boolean;
	isDisabled?: boolean;
	onStartTest: (test: TestItem, student: StudentModel) => void;
}

export function TestList(props: Props) {
	const {
		test,
		isDisabled,
	} = props;

	const [isItemOpen, setItemOpen] = useState(true);

	const isAllTested = useMemo(() => {
		return test.notTested === 0;
	}, [test]);

	useEffect(() => {
		setItemOpen(false);
	}, [props.showAllStudents]);

	const onTestStart = (student: StudentItem) => {
		const studentModel: StudentModel = {
			firstName: student.firstName,
			lastName: student.lastName,
			gradeLevelID: 0,
			hasCredentials: false,
			id: student.id,
			photoUrl: '',
			languageID: student.languageID,
		};

		const testModel: TestItem = {
			contentArea: test.contentArea,
			id: test.id,
			name: test.name,
			type: test.type,
			creatorID: 0,
			color: '',
			isWhiteBackground: false,
			testScreenTypes: [],
			maxScore: student.maxScore,
			untested: student.untested,
			incorrect: student.incorrect,
			correct: student.correct,
		};
		props.onStartTest(testModel, studentModel);
	};

	const onRowClick = useCallback(() => {
		if (isAllTested && !props.showAllStudents) {
			return;
		}
		setItemOpen(prev => !prev);
	}, [isAllTested, props.showAllStudents]);

	return <SelectableListRoot isListOpen={isItemOpen} onListOpenChange={setItemOpen} dataCy='full-test'>
		<SelectableListGroupRoot>
			<HeaderRow disabled={isAllTested && !props.showAllStudents} onClick={onRowClick} isListOpen={isItemOpen} dataCy='test-item'>
				<ItemTagAndTitle>
					<SelectableListTriggerContent>
						<TestColorIndicator testID={test.id} dataCy='test-color-indicator'/>
						<HeaderLabel data-cy='test-name' size='large' isListOpen={isItemOpen}>{test.name}</HeaderLabel>
						<TestTypeLabel data-cy='test-type-label' testType={test.type}/>
					</SelectableListTriggerContent>
				</ItemTagAndTitle>

				<Options>
					<IconBox>
						{isDisabled
							? <Text size='small' color='muted'>No Results</Text>
							: <Results isItemOpen={isItemOpen} notTested={test.notTested} testType={test.type} entityType='test' />
						}
					</IconBox>
					<TriggerWrapper>
						<SelectableListTrigger isListOpen={isItemOpen} disabled={isAllTested && !props.showAllStudents}>
							<IndicatorWrapper>
								{isItemOpen ? <AccordionCollapse /> : <AccordionExpand />}
							</IndicatorWrapper>
						</SelectableListTrigger>
					</TriggerWrapper>
				</Options>

			</HeaderRow>
			<SelectableListGroup>
				{!isDisabled && <StudentListItem onStartTest={onTestStart} students={test.students} testId={test.id} testType={test.type}/>}
			</SelectableListGroup>
		</SelectableListGroupRoot>
	</SelectableListRoot>;
}

