import React from 'react';
import {EventBusManager} from '@esgillc/events';
import {TestExplorerBackEvent} from '../../../events';
import {DataService, IContentAreaModel} from '../../../services/data-service';
import {FilterType} from '../../../enums';
import {RemoveFilterEvent} from '../../header/search-tags/search-tags';
import {AuthorClicked} from '../../noted-author/events';
import {BoxItem} from '../box-item/box-item';
import {Box} from '../box/box';
import {Loading} from '../enums';
import {ClearAllEvent} from '../events';

class State {
	contentAreaId: number = null;
	contentAreas: IContentAreaModel[] = [];
	loading: Loading = Loading.NotLoaded;
}

class Props {
	dataService: DataService;
	hasChanged: (contentAreaId: number | null) => void;
}

export class ContentArea extends React.Component<Props, State> {
	public override readonly state = new State();
	private readonly eventBus = new EventBusManager();

	public componentDidMount(): void {
		this.eventBus.subscribe(AuthorClicked, () => {
			this.changed(null);
		});

		this.eventBus.subscribe(ClearAllEvent, () => {
			this.changed(null);
		});

		this.eventBus.subscribe(RemoveFilterEvent, (args: RemoveFilterEvent) => {
			if (args.type === FilterType.ContentArea) {
				this.changed(null);
			}
		});

		this.eventBus.subscribe(TestExplorerBackEvent, () => {
			this.setState({contentAreaId: null});
		});
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	public render() {
		return <Box title='Content Area' collapsible={true}
		            cleared={() => this.changed(null)}
		            canBeCleared={!!this.state.contentAreaId}
		            filterType={FilterType.ContentArea}
		            onOpened={() => this.onBoxOpened()}
		            loading={this.state.loading === Loading.Loading}>
			{this.state.contentAreas.map(g => {
				return <BoxItem
					key={g.id}
					selected={this.state.contentAreaId === g.id}
					title={g.name}
					onSelect={() => this.changed(g.id)}
				/>;
			})}
		</Box>;
	}

	private onBoxOpened() {
		if (this.state.loading === Loading.NotLoaded) {
			this.setState({loading: Loading.Loading}, () =>
				this.props.dataService
					.contentAreas
					.subscribe(r => this.setState({contentAreas: r, loading: Loading.Loaded})),
			);
		}
	}

	private changed(contentAreaId: number | null) {
		this.setState({contentAreaId: contentAreaId}, () => {
			this.props.hasChanged(contentAreaId);
		});
	}
}
