import './styles.less';
import React from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {DataService} from '../../services/data-service';
import {FilterData} from '../../services/filter-service';
import {ContentArea} from './content-area/content-area';
import {ClearAllEvent, ScopeChangedEvent} from './events';
import {FeaturedSeries} from './featured-series/featured-series';
import {GradeLevel} from './grade-level/grade-level';
import {NotedAuthor} from './noted-author/noted-author';
import {NotedSeries} from './noted-series/noted-series';
import {Scope} from './scope/scope';
import {SubjectTabs} from './subject-tabs/subject-tabs';
import {TestType} from './test-type/test-type';

class Props {
	dataService: DataService;
}

function hasFiltersApplied({gradeLevelId, contentAreaId, subjectId, notedSeries}: FilterData): boolean {
	return [gradeLevelId, contentAreaId, subjectId, notedSeries].some(Boolean);
}

export class Filter extends React.PureComponent<Props> {

	public render() {
		return <div className='filter'>
			<Scope dataService={this.props.dataService}/>
			<div className='filters-header'>
				<span>Filters: </span>
				{hasFiltersApplied(this.props.dataService.filter.currentValue) && <a href='#' onClick={() => this.clearAll()}>Clear All</a>}
			</div>
			<GradeLevel hasChanged={(gradeLevelId) => {
				this.props.dataService.filter.update({gradeLevelId: gradeLevelId});
				this.filterChanged();
			}}/>
			<TestType hasChanged={(testType) => {
				this.props.dataService.filter.update({testType});
				this.filterChanged();
			}}/>
			<ContentArea dataService={this.props.dataService} hasChanged={(contentAreaId) => {
				this.props.dataService.filter.update({contentAreaId: contentAreaId});
				this.filterChanged();
			}}/>
			<NotedSeries dataService={this.props.dataService} hasChanged={(notedSeries) => {
				let newValue;
				if(notedSeries) {
					newValue = {id: notedSeries.id, name: notedSeries.name};
				} else {
					newValue = null;
				}
				this.props.dataService.filter.update({notedSeries: newValue});
				this.filterChanged();
			}}/>
			<NotedAuthor landingService={this.props.dataService.landing} hasChanged={(notedAuthor) => {
				this.props.dataService.filter.update({notedAuthor: notedAuthor});
				this.filterChanged();
			}}/>
			<SubjectTabs dataService={this.props.dataService} hasChanged={(subjectId) => {
				this.props.dataService.filter.update({subjectId: subjectId});
				this.filterChanged();
			}}/>
			<FeaturedSeries hasChanged={(featuredSeries) => {
				this.props.dataService.filter.update({featuredSeries: featuredSeries});
				this.filterChanged();
			}}/>

		</div>;
	}

	private filterChanged() {
		dispatchAppEvent(ScopeChangedEvent);
	}

	private clearAll() {
		dispatchAppEvent(ClearAllEvent);
	}
}
