import {ControlValue, FormControl, FormGroup, ValidatorArgs, Validators} from '@esgillc/ui-kit/form';
import {DictionaryItem, Schools} from 'modules/forms/students-form/types';
import {HttpClient} from '@esgi/api';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';

export type ProfileFormType = FormGroup<{
	firstName: FormControl<string>,
	lastName: FormControl<string>,
	studentIDN: FormControl<string>,
	exportIDN: FormControl<string>,
	gradeLevel: FormControl<DictionaryItem<number>[]>,
	language: FormControl<DictionaryItem<number>[]>,
	gender: FormControl<DictionaryItem[]>,
	school: FormControl<Schools[]>,
	userName: FormControl<string>
	password: FormControl<string>
},
	ControlValue<{
		firstName: FormControl<string>,
		lastName: FormControl<string>,
		studentIDN: FormControl<string>,
		exportIDN: FormControl<string>,
		gradeLevel: FormControl<DictionaryItem<number>[]>,
		language: FormControl<DictionaryItem<number>[]>,
		gender: FormControl<DictionaryItem[]>,
		school: FormControl<Schools[]>,
		userName: FormControl<string>
		password: FormControl<string>
	}>>

export const studentIDNAvailableCustomValidator = (initialID: BehaviorSubject<string>, globalSchoolYearID: number, httpClient: HttpClient, cb: (v: { studentID: number, studentName: string }) => void) => {
	const checkID = (studentIDN: string) => {
		return httpClient.ESGIApi.get<{ studentID: number, studentName: string }>('modules/forms/student/common', 'check-exist-student', {
			globalSchoolYearID,
			studentIDN,
		});
	};
	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(debounceTime(800), switchMap((validatorArgs) => {
			if(!validatorArgs.field.currentValue){
				return new Observable<ValidatorArgs<string>>(subscriber => subscriber.next(validatorArgs));
			}
			return checkID(validatorArgs.field.currentValue).pipe(map((student) => {
				if (student.studentID && validatorArgs.field.currentValue !== initialID.value) {
					cb(student);
					validatorArgs.errors.push('id-in-use');
				}
				return validatorArgs;
			}));
		},
		));
	};
};

export const rangeLengthCustomValidator = (min: number, max: number) => {
	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(debounceTime(800), tap((validatorArgs) => {
			if (validatorArgs.field.currentValue) {
				if (validatorArgs.field.currentValue.length < min) {
					validatorArgs.errors.push('range-length-lower');
				} else if (validatorArgs.field.currentValue.length > max) {
					validatorArgs.errors.push('range-length-upper');
				}
			}
			return validatorArgs;
		}));
	};
};

export const studentUserNameAvailableCustomValidator = (initialUserName: BehaviorSubject<string>, studentID: BehaviorSubject<number>, globalSchoolYearID: number, httpClient: HttpClient) => {
	const checkUserName = (userName: string) => {
		return httpClient.ESGIApi.get<{ exists: boolean }>('modules/forms/student/common', 'check-exist-username', {
			userName,
			studentID: studentID.value,
			globalSchoolYearID,
		});
	};

	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(debounceTime(800), switchMap((validatorArgs) => {
			if (!validatorArgs.field.currentValue) {
				return new Observable<ValidatorArgs<string>>(subscriber => subscriber.next(validatorArgs));
			}
			return checkUserName(validatorArgs.field.currentValue).pipe(map((username) => {
				if (username.exists && initialUserName.value !== validatorArgs.field.currentValue) {
					validatorArgs.errors.push('user-name-in-use');
				}
				return validatorArgs;
			}));
		},
		));
	};
};

export const createGeneralTabForm = ():
	ProfileFormType => new FormGroup({
	firstName: new FormControl('', {validators: [Validators.required()]}),
	lastName: new FormControl('', {validators: [Validators.required()]}),
	studentIDN: new FormControl(''),
	exportIDN: new FormControl(''),
	gradeLevel: new FormControl<DictionaryItem<number>[]>([], {validators: [Validators.required()]}),
	language: new FormControl<DictionaryItem<number>[]>([], {validators: [Validators.required()]}),
	gender: new FormControl<DictionaryItem[]>([], {validators: [Validators.required()]}),
	school: new FormControl<Schools[]>([], {validators: [Validators.required()]}),
	userName: new FormControl('', {validators: [rangeLengthCustomValidator(4, 20)]}),
	password: new FormControl('', {validators: [rangeLengthCustomValidator(4, 12)]}),
});
