import {Validators as FormValidators} from '@esgi/deprecated/elements';
import {ValidationStatus} from '@esgi/core/enums';
import {Observable} from 'rxjs';
import {IValidationResult, IValidator} from 'shared/modules/grade-scale/form-field/validation-handler/types';
import {isNaN} from 'underscore';
import {IGradeRangeForm} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/models';
import {IGradeScaleEntry} from 'shared/modules/grade-scale/models';
import {IGradeScaleEntryForm} from 'shared/modules/grade-scale/grade-scale/models';

export abstract class Validator<T> implements IValidator<T>{
	abstract validate(value: T): Observable<IValidationResult> | IValidationResult;
	
	static createResult(valid: boolean, message: string) {
		return {
			status: valid ? ValidationStatus.Valid : ValidationStatus.Invalid,
			message: valid ? '' : message,
		} as IValidationResult;
	}
}

export class RequiredValidator extends Validator<any> {
	validate(value: any): IValidationResult {
		const result = FormValidators.requiredValidator(value);
		return Validator.createResult(result.valid, 'Please enter value');
	}
}

export class MaxLengthValidator extends Validator<string> {
	maxLength: number
	
	constructor(maxLength: number) {
		super();
		this.maxLength = maxLength;
	}
	
	validate(value: string): IValidationResult {
		const result = FormValidators.maxLengthValidator(value, this.maxLength);
		return Validator.createResult(result.valid, result.message);
	}
}

export class NumericValidator extends Validator<string> {
	validate(value: string): IValidationResult {
		const valid = !isNaN(value) && !isNaN(parseInt(value));
		return Validator.createResult(valid, 'Invalid value');
	}
}

export class MaxValidator extends Validator<string> {
	private readonly maxValue: number;

	constructor(maxValue: number) {
		super();
		this.maxValue = maxValue;
	}

	validate(value: string): IValidationResult {
		const valid = parseInt(value) <= this.maxValue;
		return Validator.createResult(valid, `value cannot exceed ${this.maxValue}`);
	}
}

export class LessThenValidator extends Validator<IGradeRangeForm> {
	validate(entry: IGradeRangeForm): IValidationResult {
		const valid = parseInt(entry.from.value) <= parseInt(entry.to.value);
		return Validator.createResult(valid, `Please enter a numerical value less than ${entry.to.value}`);
	}
}

export class OverlapValidator extends Validator<[IGradeRangeForm]> {
	validate(compareEntries: IGradeRangeForm[]): IValidationResult {
		const prevEntry = compareEntries[0];
		const currEntry = compareEntries[1];
		const valid = parseInt(prevEntry.to.value) < parseInt(currEntry.from.value);
		return Validator.createResult(valid, 'Levels cannot overlap each other.');
	}
}

export class UniqueGradeNameValidator extends Validator<{ entries: IGradeScaleEntry[], compareEntry: IGradeScaleEntryForm}> {
	validate(data: { entries: IGradeScaleEntry[], compareEntry: IGradeScaleEntryForm}): IValidationResult {
		const compareEntries = data.entries.filter(e => e.id !== data.compareEntry.id);
		const valid = !compareEntries.some(e => e.gradeName === data.compareEntry.gradeName.value);
		return Validator.createResult(valid, 'Grade name must be unique.');
	}
}