import {useMemo} from 'react';
import {TaskAdd} from '@esgi/ui/icons';
import {noop} from 'underscore';
import {SubjectSectionModePermissions, SubjectTabId} from '../../../types';
import {
	SubjectSectionMode,
	AddModeMenuListItem,
} from '@esgi/main/features/teacher/home';

export function useAddSectionModeItems({
	sectionModePermissions,
	activeTab,
	onAddClick,
}: {
	sectionModePermissions: SubjectSectionModePermissions;
	activeTab: SubjectTabId;
	onAddClick: () => void;
}) {
	const addSectionModeItems = useMemo<AddModeMenuListItem[]>(
		() => [
			{
				itemText: 'Add a Subject...',
				Icon: TaskAdd,
				permitted: sectionModePermissions[SubjectSectionMode.Add][activeTab],
				onClick: onAddClick,
				dataCy: 'subject-panel-menu-item-add-subject',
			},
		],
		[sectionModePermissions, activeTab]
	);

	return {addSectionModeItems};
}
