import {Dispatch, PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {CardViewType, TestSectionMode} from '../../types';
import {TestSectionContextValue} from '../../context/types';
import {TestSectionContext} from '../../context/test-section-context';
import {Container} from './index.styled';
import {useRootPageContext} from '@esgi/main/kits/common';
import {useAutoControlledState} from '@esgi/ui';

interface Props extends PropsWithChildren {
	testResultsIncorrectVerbiage: string;
	testResultsCorrectVerbiage: string;
	cardViewType: CardViewType;
	onCardViewTypeChanged: Dispatch<CardViewType>;
	allSessionsClick: Dispatch<{testID: number}>;
}

export function TestSectionRoot({
	children,
	testResultsCorrectVerbiage,
	testResultsIncorrectVerbiage,
	cardViewType: controlledCardViewType,
	onCardViewTypeChanged,
	allSessionsClick,
}: Props) {
	const [sectionMode, setSectionMode] = useState(TestSectionMode.View);

	const [cardViewType, setCardViewType] = useAutoControlledState({
		initialState: CardViewType.List,
		controlledState: controlledCardViewType,
		onChange: onCardViewTypeChanged,
	});

	const setSectionViewMode = useCallback(() => {
		setSectionMode(TestSectionMode.View);
	}, []);

	const {screenSize} = useRootPageContext();

	const value = useMemo<TestSectionContextValue>(
		() => ({
			setSectionViewMode,
			sectionMode,
			setSectionMode,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			cardViewType,
			setCardViewType,
			allSessionsClick,
		}),
		[setSectionViewMode, sectionMode, testResultsCorrectVerbiage, testResultsIncorrectVerbiage, cardViewType, setCardViewType, allSessionsClick],
	);

	return (
		<TestSectionContext.Provider value={value}>
			<Container screenSize={screenSize} dataCy='test-panel-root'>
				{children}
			</Container>
		</TestSectionContext.Provider>
	);
}
