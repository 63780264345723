import './style.less';
import React from 'react';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {Api, SearchTagsResponse, TagModel} from '../../api';

export class State {
	showDropdown: boolean = false;
	inputInFocus: boolean = false;
	suggestions: TagModel[] = [];
	tagInputValue: string = '';
	waitSuggestions: boolean = false;
	showTooltip: boolean = false;
}

export class Props extends SharedProps<State> {
	tagCreated: (tag: TagModel) => any;
	existTags: TagModel[];
}

export class TagSelect extends SharedComponent<State, Props> {
	private timer: number;
	private ref: React.RefObject<HTMLInputElement> = React.createRef();
	private searchDef: JQueryDeferred<SearchTagsResponse>;

	/*
		Sometimes when the user typed tag and request to find search-tags were sent, but not ended, the user can click save or close.
		If don't reject request after the user clicks to the action that closes the modal then, when the request end and call
		done callback, it calls setState, which reopens the modal. Probably it's a our react issue, don't sure about that.
	*/

	componentWillUnmount() {
		super.componentWillUnmount();
		this.rejectSearchRequest();
	}

	private rejectSearchRequest(): void {
		if (this.searchDef && this.searchDef.state() !== 'resolved') {
			this.searchDef.reject();
			clearTimeout(this.timer);
		}
	}

	private onTextTypedHandler(e): void {
		this.rejectSearchRequest();

		const value = e.target.value.trim();
		const searchDef = $.Deferred<SearchTagsResponse>();

		searchDef.then(r => {
			this.setState({
				suggestions: r && r.Tags || [],
			});
		});

		if (value.length) {
			this.timer = window.setTimeout(() => {
				Api.SearchTags(value.replace(/[^a-zA-Z0-9\s]/g, ''))
					.subscribe(r => this.searchDef.resolve(r));
			}, 300);
		} else {
			this.searchDef.resolve();
			this.setState({suggestions: []});
		}

		this.searchDef = searchDef;
		this.setState({tagInputValue: e.target.value, showTooltip: false});
	}

	private addFromSuggestionsHandler(t: TagModel): void {
		const alreadyAttached = this.props.existTags.find(i => t.name === i.name);
		if (!alreadyAttached) {
			this.tagCreated(t);
		} else {
			this.setState({showTooltip: true});
		}
	}

	private addHandler() {
		const name = this.state.tagInputValue && this.state.tagInputValue.trim();
		if (name) {
			if (this.searchDef && this.searchDef.state() !== 'resolved') {
				this.searchDef.done(r => this.addTag(name, r.Tags));
				this.setState({waitSuggestions: true});
			} else {
				this.addTag(name, this.state.suggestions);
			}
		}

	}

	private onKeyDownHandler(e): void {
		if (e.charCode === 13 && !!this.state.tagInputValue && !this.state.waitSuggestions) {
			this.addHandler();
		}
	}

	private addTag(name: string, suggestions: TagModel[]): void {
		const existingTag = suggestions && suggestions.find(i => i.name.toLowerCase() === name.toLowerCase());
		const alreadyAttached = this.props.existTags.find(i => i.name.toLowerCase() === name.toLowerCase());
		let newTag;
		if (existingTag || alreadyAttached) {
			if (!alreadyAttached) {
				newTag = existingTag;
			} else {
				this.setState({suggestions: [], waitSuggestions: false, showTooltip: true});
				return;
			}
		} else {
			newTag = {id: 0, name: name};
		}
		this.tagCreated(newTag);
	}


	private focusChangedHandler(e): void {
		setTimeout(() => this.setState({showDropdown: e, inputInFocus: e}), 100);
	}

	private get formClassName() {
		let baseClass = 'tag-select-input form-group';
		if (this.state.inputInFocus) {
			baseClass += ' focused';
		}
		if (this.state.showTooltip) {
			baseClass += ' has-error';
		}
		return baseClass;
	}

	render() {
		return <div className='tag-select'>
			<div className={this.formClassName}>
				<input type='text' disabled={this.state.waitSuggestions}
				       ref={this.ref}
				       onFocus={() => this.focusChangedHandler(true)}
				       maxLength={35} onInputCapture={e => this.onTextTypedHandler(e)}
				       onBlur={() => this.focusChangedHandler(false)} value={this.state.tagInputValue}
				       onKeyPress={e => this.onKeyDownHandler(e)}
				       className='form-control'
				/>
				<div className='add-button' onClick={_ => !this.state.waitSuggestions && this.addHandler()}>
					<svg width='11' height='12' viewBox='0 0 11 12' fill='none'
					     xmlns='http://www.w3.org/2000/svg'>
						<path d='M10.5 6.75H6V11.25H4.5V6.75H0V5.25H4.5V0.75H6V5.25H10.5V6.75Z' fill='#828282'/>
					</svg>
				</div>
			</div>
			{this.renderSuggestions()}
			{this.renderValidationTooltip()}
		</div>;
	}

	private renderSuggestions(): JSX.Element | null {
		if (!this.state.showDropdown || !this.state.suggestions.length || this.state.waitSuggestions) {
			return null;
		}
		return <div>
			<div className='tag-select-dropdown'>
				<div>
					<ul>
						{this.state.suggestions.map((i, index) => <li
							key={index}
							onMouseDown={_ => this.addFromSuggestionsHandler(i)}>
							<span>{i.name}</span></li>)}
					</ul>
				</div>
			</div>
		</div>;
	}

	private renderValidationTooltip(): JSX.Element | null {
		if (this.state.showTooltip) {
			return <ValidationTooltip element={null} elementRef={this.ref} placement={'left'}>
				This tag already exists for this image. Please create or choose a unique tag.
			</ValidationTooltip>;
		}
	}

	private tagCreated(e: TagModel): void {
		this.setState({tagInputValue: '', suggestions: [], waitSuggestions: false});
		this.props.tagCreated(e);
	}
}