import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {OneLinedText} from '../../../one-lined-text';

type Props = {
	inLine?: boolean,
	maxWidth?: number,
	firstName: string;
	lastName: string;
	textSize?: VariantProps<typeof Text>['size'];
	dataCy?: string
};

export function StudentFullName({
	                                firstName,
	                                lastName,
	                                textSize = 'medium',
	                                dataCy = 'student-full-name',
	                                inLine,
	                                maxWidth,
                                }: Props) {
	const fullName = [firstName, lastName].filter(Boolean).join(' ').trim();

	if (inLine) {
		return <OneLinedText css={{maxWidth: maxWidth || 190}}>
			<Text size={textSize} bold data-cy={dataCy}>
				{fullName}
			</Text>
		</OneLinedText>;
	}

	return (
		<Text size={textSize} bold data-cy={dataCy}>
			{fullName}
		</Text>
	);
}
