import {useCallback} from 'react';
import {useSubjectSectionContext} from '../../context/subject-section-context';
import {ExpandablePanelModeVariant} from '../../../../kit';
import {SelectableListEditable} from '../lists/selectable-list-editable';
import {SubjectTab} from '@esgi/main/libs/store';

type Props = {
	onSubjectEdit: (subjectId: SubjectTab['id']) => void;
	selectedSubjectId: SubjectTab['id'] | null;
	subjects: SubjectTab[];
};

export function EditableModeContent({onSubjectEdit, selectedSubjectId, subjects}: Props) {
	const {setSectionViewMode} = useSubjectSectionContext();

	const handleSaveData = useCallback(() => {
		setSectionViewMode();
	}, [setSectionViewMode]);

	return (
		<ExpandablePanelModeVariant
			withBottomSectionButton
			bottomSectionButtonWithIcon
			buttonText='Done'
			onBottomButtonClick={handleSaveData}
		>
			<SelectableListEditable onSubjectEdit={onSubjectEdit} subjects={subjects} selectedSubjectId={selectedSubjectId} />
		</ExpandablePanelModeVariant>
	);
}
