import {CollapseSide} from '@esgi/ui/icons';
import {useCallback} from 'react';
import {ButtonIcon} from './index.styled';

type Props = {
	isActive: boolean;
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ToggleButton({isActive, setIsActive}: Props) {
	const toggleExpanedState = useCallback(() => {
		setIsActive((currentState) => !currentState);
	}, [setIsActive]);

	return (
		<ButtonIcon onClick={toggleExpanedState} withBackgroundHover isActive={isActive}>
			<CollapseSide />
		</ButtonIcon>
	);
}
