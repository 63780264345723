import React, {ReactNode} from 'react';
import {BehaviorSubject, Observable, Unsubscribable} from 'rxjs';
import _ from 'underscore';

class State<T> {
	value: T;
}

class Props<T> {
	stream: Observable<T>;
	children: (value: T) => ReactNode;
	dynamicStream?: boolean;
}

export class ReactiveComponent<T> extends React.Component<Props<T>, State<T>> {
	public override readonly state = new State<T>();
	private sub: Unsubscribable;

	constructor(props: Props<T>) {
		super(props);
		if(props.stream instanceof BehaviorSubject) {
			this.state = {
				value: props.stream.value,
			} as State<T>;
		}
	}

	public override componentDidMount() {
		this.init();
	}

	private init(): void {
		this.sub = this.props.stream.subscribe((value: T) => {
			if (_.isEqual(this.state.value, value)) {
				this.setState({value: value});
				return;
			}
			if (value != this.state.value) {
				this.setState({value: value});
			}
		});
	}

	public override componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<State<T>>, snapshot?: any) {
		if (this.props.dynamicStream && prevProps.stream !== this.props.stream) {
			if (this.sub) {
				this.sub.unsubscribe();
			}
			this.init();
		}
	}

	public override render() {
		let renderResult = this.props.children(this.state.value);
		if (!renderResult) {
			renderResult = <></>;
		}
		return renderResult;
	}

	public override componentWillUnmount() {
		this.sub && this.sub.unsubscribe();
	}
}
