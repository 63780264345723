import {ReactNode} from 'react';

export interface ReportTableProps<RowDataT> {
	className?: string;
	columns: Column<RowDataT>[];
	rows: RowDataT[];
}

export interface Column<RowDataT> {
	fixedColumnPosition?: FixedColumnPosition;
	className?: string;
	headers: HeaderCell[][];
	cells: Cell<RowDataT>[];
}

export enum FixedColumnPosition {
	Right,
	Left,
}

export interface HeaderCell {
	className?: string;
	renderer: () => ReactNode;
}

export interface Cell<RowDataT> {
	className?: string;
	renderer: (row: RowDataT) => ReactNode;
}

export interface ReportTableComponentProps {
	className?: string;
	children?: any;
}

export interface ReportTableFixableProps extends ReportTableComponentProps {
	fixedColumnPosition: FixedColumnPosition;
}
