import React from 'react';
import {Checkbox, Radio} from '@esgillc/ui-kit/control';
import {ReportFiltersContainer, ReportFiltersLabel} from '@esgi/deprecated/ui-kit/report/filters';
import styles from './show-filter.module.less';
import {isIpad, join} from '@esgillc/ui-kit/utils';
import {FiltersContentBlock} from 'modules/reports/student-progress-report/components/common/filters-content-block';
import {FiltersLabelBlock} from '../common/filters-label-block';

interface Props {
	reportDisplayResultsMode: string;
	changeDisplayResultsMode: (value: string) => void;
	hasGradeScales: boolean;
	showGradeColors: boolean;
	changeShowGradeColors: (value: boolean) => void
}

export function ShowFilter(props: Props) {
	const {reportDisplayResultsMode, hasGradeScales, showGradeColors, changeShowGradeColors, changeDisplayResultsMode} = props;
	return <ReportFiltersContainer className={styles.columnOrientation}>
			<FiltersLabelBlock>
				<ReportFiltersLabel>Show:</ReportFiltersLabel>
			</FiltersLabelBlock>
			<FiltersContentBlock>
				<div className={styles.checkboxesContainer}>
					<FiltersContentBlock className={isIpad() ? styles.rowOrientation : ''}>
						<Radio value={0}
						       id='displayResultsModeScore'
						       checked={reportDisplayResultsMode === '0'}
						       onChange={() => changeDisplayResultsMode('0')}>
								<span className={join(styles.label, reportDisplayResultsMode === '0' ? styles.selected : null)}>
									Score
								</span>
						</Radio>
						<Radio value={1}
						       id='displayResultsModePercent'
						       checked={reportDisplayResultsMode === '1'}
						       onChange={() => changeDisplayResultsMode('1')}>
								<span className={join(styles.label, reportDisplayResultsMode === '1' ? styles.selected : null)}>
									Percent
								</span>
						</Radio>
						<Radio value={2}
						       id='displayResultsModeGrade'
						       disabled={!hasGradeScales}
						       checked={reportDisplayResultsMode === '2'}
						       onChange={() => changeDisplayResultsMode('2')}>
								<span className={join(styles.label, !hasGradeScales ? 'inactive' : null, reportDisplayResultsMode === '2' ? styles.selected : null)}>
									Grade
								</span>
						</Radio>
					</FiltersContentBlock>
					<FiltersContentBlock>
						{reportDisplayResultsMode === '2' &&
							<Checkbox
								id='showGradeColors'
								checked={showGradeColors}
								onChange={() => changeShowGradeColors(!showGradeColors)}
							>
								<span className={join(styles.label, showGradeColors ? styles.selected : null)}>In Color</span>
							</Checkbox>
						}
					</FiltersContentBlock>
				</div>
			</FiltersContentBlock>
		</ReportFiltersContainer>;
}
