import {Box, ExpandablePanel, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ExpandablePanelStyled = styled(ExpandablePanel, {
	'&[data-expandable-panel-open=true]': {
		backgroundColor: '$neutral94',
		backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',
	},
	variants: {
		collapsed: {
			true: {
				cursor: 'pointer',
			},
		},
	},
});

export const SectionBox = styled(FlexBox, {
	height: '100%',
	overflow: 'hidden',
	variants: {
		isTablet: {
			true: {
				position: 'absolute',
				zIndex: 1,
			},
		},
	},
});

export const ExpandablePanelContent = styled(ExpandablePanel.Content, {
	width: 232,
	height: 'calc(100% - 40px)',
});

export const IconBox = styled(Box, {
	width: 24,
	height: 24,

	'& svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		fillColor: {
			primary: {
				color: '$primary',
			},

			secondary: {
				color: '$secondary',
			},

			neutral56: {
				color: '$neutral56',
			},
		},
	},
});
