import {ChangeFunction} from '../../types';
import {SettingsPanel} from '@esgi/main/kits/reports';
import {useUser} from '@esgi/core/authentication';
import {Wrapper, CheckboxGroup, CheckboxGroupItem} from './index.styled';

interface Props {
	disabled?: boolean;
	value?: boolean;
	onChange: ChangeFunction<boolean>;
}

export function SettingsControl(props: Props) {
	const {disabled, value, onChange} = props;
	const currentUser = useUser();

	return (
		<SettingsPanel data-cy='settings-part' title='Settings'>
			<Wrapper>
				<CheckboxGroup>
					<CheckboxGroupItem
						disabled={disabled}
						label={`Display Not Tested as ${currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect'}`}
						checked={value}
						onCheckedChange={(value) => onChange(value as boolean)}
						dataCy='display-not-tested-as'
					/>
				</CheckboxGroup>
			</Wrapper>
		</SettingsPanel>
	);
}
