import {useState} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {determineConflictedFieldsActionsData, determineFieldAction} from './utils';
import {ConflictedStudentInfo} from '../conflict-students-info';
import {ConflictedFieldBlock} from '../conflict-control-block';
import {
	ActionsDataType,
	ConflictAction,
	ConflictStudentField,
	ConflictStudentModel,
	ConflictType,
	StudentField,
	Validation,
} from '../../../../types';
import {BlocksContainer} from './index.styled';

interface Props {
	lineNumber: number;
	conflictedStudentName: string;
	withDeleteAction: boolean;
	student: ConflictStudentModel | null;
	onViewExistingStudentProfile: (id: string) => void;
	onEdit: (student: ConflictStudentModel, validation?: Validation) => void;
	onUpdateActions: (student: ConflictStudentModel, actionsData: ActionsDataType) => void;
}

export function StudentBlock({
	lineNumber,
	conflictedStudentName,
	student,
	onEdit,
	onUpdateActions,
	...props
}: Props) {

	const [selectedAction, setSelectedAction] = useState<ConflictAction>(ConflictAction.Fix);

	const handelEditConflictedField = (value: string, validation: Validation, action: ConflictAction, field: StudentField) => {
		const changedStudent = {...student};
		changedStudent[field] = new ConflictStudentField(value, validation, action);
		onEdit(changedStudent);
	};

	const handleChangeFieldActions = (action: ConflictAction) => {
		setSelectedAction(action);
		onUpdateActions(student, determineConflictedFieldsActionsData(action));
	};

	const getFieldProps = (fieldType: StudentField) => ({
		student,
		onChangeAction: handleChangeFieldActions,
		conflictedType: student[fieldType].validation.conflictType,
		selectedAction: determineFieldAction(selectedAction, fieldType),
		onEdit: (value, validation, action) => handelEditConflictedField(value, validation, action, fieldType),
		validation: {
			valid: student[fieldType].validation.conflictType === ConflictType.None,
			message: student[fieldType].validation.message,
		},
		...props,
	});

	return <GridBox data-cy='conflicted-student-info'>
		<ConflictedStudentInfo
			studentFullName={conflictedStudentName}
			conflictedLineNumber={lineNumber}
			withRedColor={selectedAction === ConflictAction.Delete}
		/>

		<BlocksContainer>
			<ConflictedFieldBlock {...getFieldProps(StudentField.StudentIDN)}/>
			<ConflictedFieldBlock {...getFieldProps(StudentField.Name)}/>
			<ConflictedFieldBlock {...getFieldProps(StudentField.Language)}/>
		</BlocksContainer>
	</GridBox>;
}
