import {AssignmentModel} from '../../types/types';
import {Collapse} from '@esgillc/ui-kit/layout';
import {TestsTable, TeacherStudentsTable, SpecialistStudentsTable} from '../tables';
import {assignmentDatesLabels, AssignmentStatus} from 'pages/assignment-center/typings/assignment';
import {AssignmentCardService} from '../../services/assignment-card-service';
import styles from './styles.module.less';
import {TeachersStudent} from '../../types/teacher-types';
import {SpecialistsStudent} from '../../types/specialist-types';
import {useCallback, useEffect, useState} from 'react';
import moment from 'moment';

type Props<Service extends AssignmentCardService> = {
	assignment: AssignmentModel;
	service: Service;
} & (
	| {
			userType: 'teacher';
			students: TeachersStudent[];
	  }
	| {
			userType: 'specialist';
			students: SpecialistsStudent[];
	  }
);

export function Body<Service extends AssignmentCardService>({assignment, service, ...props}: Props<Service>) {
	const {created, posted, completed, tests, description, state, code} = assignment;
	const [studentsCount, setStudentsCount] = useState<number>(props.students.length);

	useEffect(() => {
		setStudentsCount(props.students.length);
	}, [props.students]);

	const date = completed || posted || created;

	const onOrder = (testIds: number[]) => {
		service.reorder(testIds);
	};

	const getStudentsTable = useCallback(() => {
		const {userType} = props;

		if (userType === 'teacher') {
			const {students} = props;

			return <TeacherStudentsTable students={students} testsCount={tests.length} />;
		}

		if (userType === 'specialist') {
			const {students} = props;

			return <SpecialistStudentsTable students={students} testsCount={tests.length} />;
		}

		return null;
	}, [props]);

	return (
		<div className={styles.bodyContainer}>
			{(date || description) && (
				<div className={styles.info}>
					<div className={styles.description}>{description}</div>
					<div className={styles.date}>
						{state !== AssignmentStatus.Draft && (
							<span className={styles.posted}>
								{assignmentDatesLabels[state]}: {moment(date).format('MM/DD/YYYY')}
							</span>
						)}
						{code && <span className={styles.posted}>&nbsp;| Code: {code}</span>}
					</div>
				</div>
			)}
			<Collapse title={`Tests (${tests.length})`} className={styles.collapse}>
				<TestsTable assignment={assignment} onOrder={onOrder} studentsCount={studentsCount} />
			</Collapse>
			<Collapse
				title={`Students (${studentsCount})`}
				contentClassName={styles.content}
				className={styles.studentCollapse}
			>
				{getStudentsTable()}
			</Collapse>
		</div>
	);
}
