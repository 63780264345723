import {SVGProps} from 'react';

export function FinishedSetup(props: SVGProps<SVGSVGElement>) {
	return <svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			fill='#A5D7F3'
			d='M6.74878 6.75C6.33478 6.75 5.99878 7.08578 5.99878 7.5C5.99878 7.91422 6.33478 8.25 6.74878 8.25H11.9988C12.4128
			8.25 12.7488 7.91422 12.7488 7.5C12.7488 7.08578 12.4128 6.75 11.9988 6.75H6.74878ZM9.74878 15.75C9.33478 15.75
			8.99878 16.0858 8.99878 16.5C8.99878 16.9142 9.33478 17.25 9.74878 17.25H17.2488C17.6628 17.25 17.9988 16.9142
			17.9988 16.5C17.9988 16.0858 17.6628 15.75 17.2488 15.75H9.74878ZM6.74878 11.25C6.33478 11.25 5.99878 11.5858
			5.99878 12C5.99878 12.4142 6.33478 12.75 6.74878 12.75H8.99879C9.41279 12.75 9.74879 12.4142 9.74879 12C9.74879
			11.5858 9.41279 11.25 8.99879 11.25H6.74878ZM14.9988 11.25C14.5848 11.25 14.2488 11.5858 14.2488 12C14.2488
			12.4142 14.5848 12.75 14.9988 12.75H17.2488C17.6628 12.75 17.9988 12.4142 17.9988 12C17.9988 11.5858 17.6628
			11.25 17.2488 11.25H14.9988Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			fill='#0084CC'
			d='M16.5 6C17.3284 6 18 6.67157 18 7.5C18 8.32843 17.3284 9 16.5
			9C15.6716 9 15 8.32843 15 7.5C15 6.67157 15.6716 6 16.5 6Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			fill='#0084CC'
			d='M5.99878 17.25C5.99878 17.664 6.33455 18 6.74878 18C7.163 18 7.49878 17.664 7.49878 17.25V15.75C7.49878 15.336
			7.163 15 6.74878 15C6.33455 15 5.99878 15.336 5.99878 15.75V17.25ZM11.2488 12.75C11.2488 13.164 11.5846 13.5 11.9988
			13.5C12.413 13.5 12.7488 13.164 12.7488 12.75V11.25C12.7488 10.836 12.413 10.5 11.9988 10.5C11.5846 10.5 11.2488
			10.836 11.2488 11.25V12.75Z'
		/>
	</svg>;
}
