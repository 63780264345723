import * as React from 'react';
import type {SVGProps} from 'react';

export function Magic(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M14 4a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V5a1 1 0 0 0-1-1ZM7.625 6.625c-.256 0-.492.117-.687.313a.99.99 0 0 0 0 1.406l1.343 1.375c.391.39 1.048.391 1.438 0a1.029 1.029 0 0 0 0-1.438L8.344 6.938a1.044 1.044 0 0 0-.719-.313Zm12.719.031a.94.94 0 0 0-.688.282l-1.375 1.343a1.03 1.03 0 0 0 0 1.438 1.03 1.03 0 0 0 1.438 0l1.343-1.375a.99.99 0 0 0 0-1.406 1.007 1.007 0 0 0-.718-.282ZM5 13a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2H5Zm16 0a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2ZM9 18a1.01 1.01 0 0 0-.719.281l-1.375 1.375a.991.991 0 0 0 0 1.406c.39.392 1.046.39 1.438 0l1.375-1.343a1.03 1.03 0 0 0 0-1.438A1.017 1.017 0 0 0 9 18Zm5 2a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1ZM14 10c-.662 0-1.305.423-1.305 1.309 0 .596-.774 1.372-1.369 1.372-1.768 0-1.768 2.617 0 2.617.595 0 1.369.776 1.369 1.373 0 1.773 2.61 1.771 2.61 0 0-.597.774-1.373 1.369-1.373 1.768 0 1.768-2.617 0-2.617-.595 0-1.369-.776-1.369-1.372 0-.886-.643-1.309-1.305-1.309Z'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='m24.473 23.621-5.594-5.634a.901.901 0 1 0-1.354 1.19l5.594 5.634a.901.901 0 0 0 1.354-1.19Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
