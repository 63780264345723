import {Fragment, Suspense, useMemo, useState} from 'react';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {lazyComponent} from '@esgi/core/react';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';

const PieChartReport = lazyComponent(() => import('shared/modules/reports/pie-chart/report'));

interface Props {
	subjectID: number;
	subjectType: number;
	hierarchy: HierarchyInstance;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	noStudents: boolean;
}

function Icon() {
	return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='15'
	            viewBox='0 0 15 15'
	            fill='none'>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M3.33308 1.23951C4.56647 0.431364 5.51657 0 6.99996 0V7.84511H14.9999C14.9999 9.29973 14.5601 10.2314 13.7358 11.4409C12.9121 12.6504 11.7407 13.5931 10.3701 14.1497C8.99955 14.7064 7.49174 14.852 6.03668 14.5683C4.58209 14.2845 3.24568 13.584 2.19686 12.5554C1.14803 11.5268 0.433682 10.2163 0.144133 8.78966C-0.145416 7.36293 0.0035089 5.88413 0.570888 4.54024C1.13876 3.19629 2.10018 2.04765 3.33308 1.23951ZM14.5054 3.93489C14.832 4.70819 15 5.5371 15 6.37416H8.50004V0C9.35355 0 10.1988 0.164896 10.9873 0.485232C11.7759 0.805508 12.4927 1.27506 13.0962 1.86695C13.6997 2.45884 14.1787 3.16152 14.5054 3.93489Z'
		      fill='#0088CC'/>
	</svg>;
}

export default function PieChartsButton(props: Props) {
	const [open, setOpen] = useState(false);
	const [changesCollector, setChangesCollector] = useState<ChangesCollector>();

	const report = open && <Suspense fallback={<Fragment/>}>
		<PieChartReport
			hierarchy={props.hierarchy.snapshot}
			close={() => {
				props.applyHomepageChanges(changesCollector);
				setOpen(false);
				setChangesCollector(null);
			}}
			changeCollector={changesCollector}
			subjectID={props.subjectID}
		/>
	</Suspense>;

	return <>
		<NoStudentProtectedButton title='Pie Charts' icon={<Icon/>}
		                          noStudents={props.noStudents}
		                          hoverTitle='Printable report of homepage piecharts'
		                          onClicked={() => {
			                          setOpen(true);
			                          setChangesCollector(new ChangesCollector({
				                          id: props.subjectID,
				                          type: props.subjectType,
			                          }, props.hierarchy.snapshot));
		                          }}/>
		{report}
	</>;
}
