import React from 'react';
import styles from './do-not-have-any-tests-modal.module.less';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';


interface DoNotHaveAnyTestsModalProps {
	addClicked: () => any;
	cancelClicked: () => any;
}
 
class DoNotHaveAnyTestsModal extends React.Component<DoNotHaveAnyTestsModalProps> {
	private modalManagerRef: ModalManagerRefObject = React.createRef();

	private onClose() {
		this.modalManagerRef.current.close(this.props.cancelClicked);
	}

	private onAdd() {
		this.modalManagerRef.current.close(this.props.addClicked);
	}

	render() { 
		return (
			<Modal modalManagerRef={this.modalManagerRef}>
				<Modal.Body className={styles.bodyWrapper}>
					<span> You do not have any self-assessment tests in this subject tab.</span>
				</Modal.Body>
				<Modal.Footer>
					<LinkButton onClick={() => this.onClose()}>
						CANCEL
					</LinkButton>
					<LinkButton onClick={() => this.onAdd()}>
						ADD NEW TEST
					</LinkButton>
				</Modal.Footer>
			</Modal>
		);
	}
}
 
export default DoNotHaveAnyTestsModal;
