import {CounterBox, GridBox, Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {StudentList, Props} from '../../student-list';

export const StudentsWithoutGroups = ({
	students,
	selectedStudentsIDs,
	toggleStudent,
}: Omit<Props, 'showEmptyTemplate'>) => (
	<GridBox gap='3'>
		<GridBox flow='column' gap='2' justify='center' align='center'>
			<Text size='small' font='mono' color='lowContrast'>
				Students without groups
			</Text>
			<Box>
				<CounterBox>
					<Text size='small' font='mono'>
						{students.length}
					</Text>
				</CounterBox>
			</Box>
		</GridBox>
		<StudentList
			students={students}
			selectedStudentsIDs={selectedStudentsIDs}
			toggleStudent={toggleStudent}
			showEmptyTemplate={false}
		/>
	</GridBox>
);
