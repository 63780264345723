import {useMemo} from 'react';
import {GroupItem} from '../../../types/types';
import {DropdownOption} from '../../../kit';

export function useDropdownGroupsOptions<Group extends GroupItem>(groups: Group[]) {
	return useMemo<DropdownOption[]>(
		() => groups.map(({id, name}) => ({label: name, value: String(id)})),
		[groups],
	);
}
