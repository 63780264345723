import {LeftPanel} from '@esgi/main/kits/common';
import {styled} from '@esgi/ui/theme';

export const LeftPanelRoot = styled(LeftPanel.Root, {
	maxWidth: 'unset',
	height: '100%',
	overflow: 'hidden',
	width: 272,
	paddingLeft: 20,
	paddingRight: 20,
	paddingTop: 20,
	paddingBottom: 20,
});

export const LeftPanelHeader = styled(LeftPanel.Header, {
	display: 'grid',
	height: 32,
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	alignItems: 'center',
	paddingTop: 6,
	paddingBottom: 7,
	gap: '$2',
});

export const LeftPanelBody = styled(LeftPanel.Body, {
	display: 'grid',
	gridTemplateRows: 'auto auto auto 1fr',
	gap: 20,
	overflow: 'hidden',
});
