import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const WrapperBox = styled(Box, {
	paddingTop: 24,
	paddingBottom: 24,
	paddingLeft: 20,
	paddingRight: 20,
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, $neutral98 17.26%, $background 65.48%)',
	boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.06), 0px 0px 8px 0px rgba(222, 227, 247, 0.40)',
	borderRightWidth: 1,
	borderRightStyle: 'solid',
	borderRightColor: '$mild',
	maxWidth: 272,
	gridArea: 'sidebar',
});
