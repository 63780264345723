import {StudentProfileTab} from 'modules/forms/students-form/types';
import styles from './style.module.less';
import {join} from '@esgillc/ui-kit/utils';

interface TabsProps {
	onChange: (tab: StudentProfileTab) => void;
	selectedTab: StudentProfileTab;
	isDisabled?: boolean
}

export function TabsController({onChange, selectedTab, isDisabled}: TabsProps) {
	const isTabSelected = (tab: StudentProfileTab) => {
		return selectedTab === tab;
	};
	return <div data-cy='tabs' className={styles.tabsContainer}>
		<div className={styles.tabsRow}>
			<div data-cy='general-tab-item' aria-selected={isTabSelected(StudentProfileTab.General)} className={join(styles.tab, isTabSelected(StudentProfileTab.General) && styles.activeTab)}
			     onClick={() => onChange(StudentProfileTab.General)}
			>
				STUDENT PROFILE
			</div>

			<div data-cy='location-tab-item' aria-disabled={isDisabled} aria-selected={selectedTab === StudentProfileTab.Location}
				className={join(styles.tab, isTabSelected(StudentProfileTab.Location) && styles.activeTab, isDisabled && styles.disabledTab)}
				onClick={() => onChange(StudentProfileTab.Location)}
			>
				LOCATION
			</div>

			<div data-cy='additional-tab-item' aria-disabled={isDisabled} aria-selected={isTabSelected(StudentProfileTab.Additional)}
				className={join(styles.tab, isTabSelected(StudentProfileTab.Additional) && styles.activeTab, isDisabled && styles.disabledTab)}
				onClick={() => onChange(StudentProfileTab.Additional)}
			>
				ADDITIONAL INFORMATION
			</div>
		</div>
		<hr className={styles.tabsLine}/>
	</div>;
}
