import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const StyledQuestionHeaderContainer = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'left',
	paddingLeft: 30,
});

export const StyledDirectionsText = styled('span', {
	color: '#FFFFFF',
	fontWeight: 500,
	fontSize: 28,
	lineHeight: 30,
	fontStyle: 'normal',
	paddingLeft: 20,
	textAlign: 'left',
});

export const StyledQuestionsWrapper = styled(Box, {
	height: '100%',
	width: '1133px',
});