import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const StyledContent = styled(GridBox, {
	gap: '$5',
	gridAutoFlow: 'row',
	alignContent: 'start',
	height: '100%',
	width: '100%',
	padding: '16px 20px',
	borderLeft: '1px solid $mild',
	overflow: 'hidden',
});