import {FormField, Validators, observable} from '@esgi/deprecated/knockout';
import {FieldContainer} from '../../../kit/component';
import {InfoModel} from '../../types';

export class RegularSchoolSelectComponent extends FieldContainer<InfoModel> {
	@observable()
		schools: Array<InfoModel>;

	buildField(initValue): FormField<InfoModel> {
		this.schools = initValue;
		const f = this.createField<InfoModel>(undefined, Validators.Required());
		f.validation.successValidation(true);
		f.validation.showValidation(true);
		return f;
	}

	setSchools(value: Array<InfoModel>) {
		this.schools = value;
		if (this.field.value()) {
			this.field.value(undefined);
		}
		this.field.validation.successValidation(true);
	}

	template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group school'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<select id='school' name='school' className='form-control'
					        data-bind="options: root.schools, optionsText:'name',optionsCaption: '', value: value, valueAllowUnset: true"/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
				</div>
			</div>
		</div>;
	};
}
