import React from 'react';
import {ArrowIcon} from './arrow-icon';
import {MatrixContext} from './matrix-context';
import {scrollYUp} from './scrolling';
import {join} from '@esgillc/ui-kit/utils';
import styles from './matrix.module.less';

interface Props {
	className?: string;
	rowHeight: number;
}

export class PrevRowButton extends React.PureComponent<Props> {
	public render() {
		return <MatrixContext.Consumer>
			{(context) => <div className={join(
				styles.prevRow,
				this.props.className,
				!context.scrollActionState?.canPrevRow && styles.hidden,
			)}>
				<ArrowIcon className={styles.arrowIconUp} onClick={() => scrollYUp(context.osInstance, this.props.rowHeight)}/>
			</div>}
		</MatrixContext.Consumer>;
	}
}
