import {ReportClass} from './components/report-class';
import {ReportGroup} from './components/report-group';
import {ReportSpecialistGroups} from './components/report-specialist-groups';
import {ReportSubjectTab} from './components/report-subject-tab';
import {ReportUntestedWeeks} from './components/report-untested-weeks';
import {ReportTeacher} from './components/report-teacher';
import {UntestedStudentsReportService} from '../../service';
import styles from '../modal/modal.module.less';

interface Props {
	service: UntestedStudentsReportService
}

export const ReportSettings = ({service}: Props) => (
	<div className={styles.reportSettings}>
		<div>
			<ReportTeacher service={service}/>
			<ReportClass service={service}/>
			<ReportGroup service={service}/>
			<ReportSpecialistGroups service={service}/>
			<ReportSubjectTab service={service}/>
		</div>
		<ReportUntestedWeeks service={service}/>
	</div>
);
