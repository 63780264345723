import {IFormControlValidatorResult} from './form-controls';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import React from 'react';

class Props {
	label?: string;
	touched?: boolean;
	validator?: (value: any) => IFormControlValidatorResult;
	validationResult?: IFormControlValidatorResult;
	validationPlacement?: 'right' | 'top' | 'bottom' | 'left';
	value: string;
	placeholder?: string;
	onTexAreaChanged: (event: any) => any;
	className?: string;
	textAreaClassName?: string;
	disabled?: boolean;
	actionButton?: () => any;
	focus?: boolean;
	maxLength?: number;
	onFocus?: () => void;
	onBlur?: () => void;
}

export class TextArea extends React.PureComponent<Props> {
	ref: HTMLTextAreaElement = null;
	containerRef: HTMLDivElement = null;

	private setRef(c: HTMLTextAreaElement) {
		this.ref = c;
	}

	private getId() {
		let postfix = '';
		if (this.props.label) {
			postfix = this.props.label;
		} else {
			if (this.props.className) {
				postfix = this.props.className;
			}
		}
		return 'text_area_' + postfix.replace(/ /g, '_');
	}

	componentDidMount() {
		if (this.props.focus) {
			$(this.ref).focus();
		}
	}

	renderValidatingControl() {
		const id = this.getId();
		let validationResult = this.props.validationResult ? this.props.validationResult : this.props.validator(this.props.value);
		return <div className={
			this.props.touched && !validationResult.valid
				? 'form-group has-error ' + this.props.className
				: 'form-group ' + (!!this.props.className && this.props.className || '')}
		            ref={(n) => {
			            this.containerRef = n;
		            }}>
			{this.props.label &&
            <label className='top-label' htmlFor={id}>{this.props.label}</label>
			}
			{this.props.actionButton && this.props.actionButton()}
			<textarea disabled={this.props.disabled}
			          onFocus={() => this.props.onFocus()}
			          onBlur={() => this.props.onBlur()}
			          id={id}
			          ref={(n) => this.setRef(n)}
			          className={'form-control ' + (!!this.props.textAreaClassName && this.props.textAreaClassName || '')}
			          placeholder={this.props.placeholder}
			          defaultValue={this.props.value}
			          maxLength={this.props.maxLength}
			          onChange={(e) => this.handleInput(e)}
			/>
			{this.ref && this.props.touched && !validationResult.valid &&
            <ValidationTooltip element={this.ref} placement={this.props.validationPlacement || 'right'}
                               notHide={true}
                               container={this.containerRef}>
				{validationResult.message}
            </ValidationTooltip>}
		</div>;
	}

	handleInput(e) {
		this.props.onTexAreaChanged ? this.props.onTexAreaChanged(e.target.value) : {};
		const caret = e.target.selectionStart;
		setTimeout(() => this.ref.setSelectionRange(caret, caret), 10);
	}

	renderNotValidatingControl() {
		const id = this.getId();
		return <div className={'form-group ' + this.props.className} ref={n => this.containerRef = n}>
			{this.props.label &&
            <label className='top-label' htmlFor={id}>{this.props.label}</label>
			}
			{this.props.actionButton && this.props.actionButton()}
			<textarea disabled={this.props.disabled}
			          onFocus={() => this.props.onFocus()}
			          onBlur={() => this.props.onBlur()}
			          id={id}
			          className={'form-control ' + (!!this.props.textAreaClassName && this.props.textAreaClassName || '')}
			          placeholder={this.props.placeholder}
			          defaultValue={this.props.value}
			          ref={n => this.setRef(n)}
			          onChange={(e) => this.handleInput(e)}/>
		</div>;
	}

	render() {
		return <>
			{(this.props.validator || this.props.validationResult) ? this.renderValidatingControl() : this.renderNotValidatingControl()}
		</>;
	}
}