import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const ButtonIconable = styled(Button.Icon, {
	width: 24,
	height: 24,
	color: '$base',

	'& svg': {
		width: '100%',
		height: '100%',
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		active: {
			true: {
				color: '$primary',
			},
		},
	},
});
