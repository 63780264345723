import {Text} from '@esgi/ui/typography';
import {EmptyBox, SelectStudentText} from './index.styled';

interface Props {
	content: string
	hasSelectedStudents?: boolean
}
export function EmptyList({content, hasSelectedStudents}: Props) {

	return (
		<EmptyBox>
			{hasSelectedStudents
				? <Text size='small' font='mono' color='neutral40'>{content}</Text>
				: <>
				<Text size='small' font='mono' color='neutral40'>No tests to display</Text>
				<SelectStudentText size='small' font='mono'>Please select a student</SelectStudentText>
				</>
			}
		</EmptyBox>
	);
}
