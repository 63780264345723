import React from 'react';
import {Label, OnErrorTooltip, TextArea, Dropdown, Input, Option} from '@esgillc/ui-kit/form-control';
import {ZendeskFieldOptionViewModel} from './api';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';

import styles from './contact-us.module.less';

export enum FieldType {
	input,
	textarea,
	select
}

interface Props {
	control: FormControl;
	title: string;
	value?: string;
	type: FieldType;
	options?: ZendeskFieldOptionViewModel[];
	isRequired?: boolean;
}

export class Field extends React.PureComponent<Props> {
	public render() {
		return <FormElement control={this.props.control} disableValidateOnBlur={false} disableValidateOnChange={this.props.type !== FieldType.select}>
			<Label>{this.props.title + (this.props.isRequired ? '*' : '')}</Label>
			{this.renderControl()}
			<OnErrorTooltip showOnError='required' placement='right'>Required</OnErrorTooltip>
			<OnErrorTooltip showOnError='email' placement='right'>Invalid email</OnErrorTooltip>
			<OnErrorTooltip showOnError='phone' placement='right'>Invalid phone</OnErrorTooltip>
		</FormElement>;
	}

	private renderControl() {
		switch (this.props.type) {
			case FieldType.input:
				return <Input/>;
			case FieldType.textarea:
				return <TextArea maxLength={300} className={styles.textArea}/>;
			case FieldType.select:
				return <Dropdown placeholder='-'
				                 displayLabel={o => this.props.options.find(x => x.value === o[0])?.name}>
					{this.props.options.map(o => <Option key={o.value} value={o.value}>{o.name}</Option>)}
				</Dropdown>;
		}
	}
}
