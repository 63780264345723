import {GridProgressBarItem, ThemeColorKeys} from '@esgi/ui';

export type DeltaInfo = {
	color: ThemeColorKeys;
	value: string;
};

export enum ChartType {
	RingFullFilled = 'RingFullFilled',
	Grid = 'Grid',
}

export type Chart<T = unknown> = T & {
	value: number | null;
	maxValue: number;
};

export type RingFullFilledChartValue = Chart<{
	type: ChartType.RingFullFilled;
}>;

export type GridChartValue = Chart<{
	type: ChartType.Grid;
	items: GridProgressBarItem[];
}>;

export type RubricChartItem = {
	score: number;
};
