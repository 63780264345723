import React from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {INotedAuthor} from '../../services/landing-service';
import {AuthorTooltip} from './author-tooltip';
import {AuthorClicked} from './events';
import './styles.less';

export class State {
	hovered: boolean = false;
}

export class Props {
	author: INotedAuthor;
}

export class Author extends React.Component<Props, State> {
	private nameref: HTMLAnchorElement;

	constructor(props) {
		super(props);
		this.state = new State();
	}

	public render() {
		let notedAuthor = this.props.author;
		if(notedAuthor) {
			return <div className={'author ' + (notedAuthor.id && 'noted')}>
				{notedAuthor.id ? this.renderNotedAuthor(notedAuthor) : this.renderOrdinaryAuthor()}
			</div>;
		} else {
			return <></>;
		}
	}

	private renderOrdinaryAuthor() {
		return <b>{this.props.author.name}</b>;
	}

	private renderNotedAuthor(notedAuthor: INotedAuthor) {
		return <>
			<img src={notedAuthor.image30url} height={39} width={39}/>
			<div className='author-description'>
				<a className='author-link' href='#' onClick={() => this.authorClicked(notedAuthor)}
				   ref={r => this.nameref = r}
				   onMouseOver={r => this.setState({hovered: true})}
				   onMouseOut={() => this.setState({hovered: false})}><b>{notedAuthor.name}</b></a>
			</div>
			{this.state.hovered && <AuthorTooltip element={this.nameref} author={notedAuthor}/>}
		</>;
	}

	private authorClicked(author: INotedAuthor) {
		dispatchAppEvent(AuthorClicked, new AuthorClicked(author));
	}
}
