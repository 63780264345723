import {GridBox} from '@esgi/ui/layout';
import React from 'react';
import {ToggleButton} from './components/toggle-button';
import {CounterBox} from './components/counter-box';
import {Text} from '@esgi/ui/typography';
import {Student} from '@esgi/main/libs/store';
import {Button} from '@esgi/ui';

type Props = Pick<Student, 'firstName' | 'lastName'> & {
	onOpenStudentTestDashboard: VoidFunction;
	index: number;
} & (
		| {
				withExpandedButton: false;
		  }
		| {
				withExpandedButton: true;
				isExpanded: boolean;
				setIsExpaned: React.Dispatch<React.SetStateAction<boolean>>;
				subTableItemsCount: number;
		  }
	);

export function StudentCell({firstName, lastName, onOpenStudentTestDashboard, index, ...props}: Props) {
	const studentFullName = [firstName, lastName].filter(Boolean).join(' ').trim();

	return (
		<GridBox dataCy={`student-full-name-${index}`} align='center' justify='between' flow='column' gapX='2'>
			<Button.Text onClick={onOpenStudentTestDashboard}>
				<Text size='medium' bold color='primary'>
					{studentFullName}
				</Text>
			</Button.Text>

			{props.withExpandedButton && (
				<GridBox dataCy={`show-more-session-block-${index}`} flow='column' align='center' gapX='2'>
					<CounterBox count={props.subTableItemsCount} colorVariant={props.isExpanded ? 'primary' : 'neutral'} />
					<ToggleButton isActive={props.isExpanded} setIsActive={props.setIsExpaned} />
				</GridBox>
			)}
		</GridBox>
	);
}
