import {HttpClient} from '@esgi/api';
import {BoxResponseModel} from 'pages/home/components/report-button/models';
import {BoxType} from 'shared/right-panel/box/models';

export default class Api {
	private static readonly controller: string = 'home/right-panel';

	public static init() {
		return HttpClient.default.ESGIApi.get<BoxResponseModel>(this.controller, 'init');
	}

	public static boxOpenClose(boxType: BoxType, open: boolean) {
		return HttpClient.default.ESGIApi.post('boxes', 'open-close', {boxType: boxType, open: open}).subscribe();
	}
}
