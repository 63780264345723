import {NavigationBar, NavigationBarOrientation, useNavigationBarContext} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {UserInfo} from '../../../../types';
import {NavigationBarItem} from '../../../nav-item';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {NavLink} from 'react-router-dom';
import {AdditionalElementType, useNavigateItems} from '../../use-navigate-items';

interface Props {
	userInfo: UserInfo;
}

const NavItemText = styled<any, any, {expanded: boolean}>(Text, {
	variants: {
		expanded: {
			true: {
				display: 'block',
			},
			false: {
				display: 'none',
			},
		},
	},
});

export function VerticalPanelContent({userInfo}: Props) {
	const {expanded} = useNavigationBarContext();

	const navigateItems = useNavigateItems({canExploreStudents: userInfo.canExploreStudents});

	return (
		<OverlayScrollbarsComponent defer style={{height: 'calc(100% + 0px)'}} options={{scrollbars: {autoHide: 'leave'}}}>
			<NavigationBar.Content>
				{navigateItems.map((item, index) => {
					if (item === AdditionalElementType.Divider) {
						return <NavigationBar.Divider key={`${item}-${index}`} />;
					}

					const {dataCy, iconDataCy, to, label, permitted = true, Icon} = item;

					if (!permitted) {
						return null;
					}

					return (
						<NavLink to={to} style={{all: 'unset'}} key={dataCy}>
							{({isActive}) => (
								<NavigationBarItem orientation={NavigationBarOrientation.Vertical} active={isActive} dataCy={dataCy}>
									<Icon data-cy={iconDataCy} width={24} height={24} />
									<NavItemText expanded={expanded} data-cy='label' size='small'>
										{label}
									</NavItemText>
								</NavigationBarItem>
							)}
						</NavLink>
					);
				})}
			</NavigationBar.Content>
		</OverlayScrollbarsComponent>
	);
}
