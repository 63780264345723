import {styled} from '@esgi/ui/theme';
import {Card, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const CardRoot = styled(Card, {
	flexDirection: 'column',
	gap: '$1',
	boxShadow: 'none',
	cursor: 'pointer',
	'&:hover': {
		color: '$secondary56',
		backgroundColor: '$secondary96',
		borderColor: '$secondary88',
	},

	variants: {
		selected: {
			true: {
				cursor: 'default',
				color: '$primary48',
				backgroundColor: '$primary96',
				borderColor: '$primary72',
				'&:hover': {
					color: '$primary48',
					borderColor: '$primary72',
					backgroundColor: '$primary96',
					boxShadow: 'none',
				},
			},
		},
		disabled: {
			true: {
				color: '$neutral80',
				backgroundColor: '$neutral96',
				borderBottomColor: '$neutral92',
				cursor: 'default',
				pointerEvents: 'none',
				'&:hover': {
					borderColor: '$neutral92',
					backgroundColor: '$neutral96',
					boxShadow: 'none',
				},
			},
		},
	},
});

export const CardHeader = styled(FlexBox, {
	justifyContent: 'space-between',
});

export const CardText = styled(Text, {
	fontWeight: '400',
	textAlign: 'center',
});
