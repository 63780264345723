import React, {ReactNode} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import styles from './right-panel.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';


class State {
	open: boolean;
	mobileLayout: boolean;
}

class Props {
	children?: ReactNode;
}

export default class RightPanel extends React.Component<Props, State> {
	private rootElement: HTMLDivElement;
	private sessionStorageKey: string = 'HomeRightPanelStatus';

	constructor(props, context) {
		super(props, context);
		const status = sessionStorage[this.sessionStorageKey];
		const matches = window.matchMedia('(min-width:769px)').matches;
		this.state = {open: status == 'opened' ? true : status == 'closed' ? false : matches, mobileLayout: !matches};
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleTouchStart = this.handleTouchStart.bind(this);
	}

	private get className(): string {
		return join(styles.panel, this.state.open && styles.open);
	}

	render() {
		return <div className={join(styles.rightPanelContainer, !this.state.open && styles.collapsed, this.state.open && styles.open)}>
			<div ref={r => this.rootElement = r}
			     className={join(this.className, !this.state.open && styles.hidden)}>
				<OnHoverTooltip message={!this.state.open && 'Subject Tabs, tests and tools' }>
					<a className={styles.btn} href='#' onClick={() => this.hideToggle()}>
						<i className={this.state.open ? 'fa fa-caret-right' : 'fa fa-caret-left'}/></a>
				</OnHoverTooltip>
				{this.props.children}
			</div>
		</div>;
	}
	private hideToggle() {
		if(this.state.open) {
			sessionStorage[this.sessionStorageKey] = 'closed';
			this.close();
		} else {
			sessionStorage[this.sessionStorageKey] = 'opened';
			this.open();
		}
	}

	componentDidMount(): void {
		window.addEventListener('orientationChange', this.close);
		const media = window.matchMedia('(min-width: 769px)');
		if (media.addEventListener) {
			const handler = e => this.setState({open: e.matches, mobileLayout: !e.matches});
			media.addEventListener('change', handler);
		}
	}

	componentWillUnmount(): void {
		window.removeEventListener('orientationChange', this.close);
		const media = window.matchMedia('(min-width: 769px)');
		if(media) {
			if (media.removeEventListener){
				media.removeEventListener('change', null);
			} else if(media.removeListener) {
				media.removeListener(null);
			}
		}
	}

	private open() {
		this.setState({open: true});

		document.addEventListener('touchstart', this.handleTouchStart, false);
		document.addEventListener('touchmove', this.handleTouchMove, false);
		document.addEventListener('touchend', this.handleTouchEnd, false);
	}

	private close = () => {
		this.setState({open: false});

		document.removeEventListener('touchstart', this.handleTouchStart, false);
		document.removeEventListener('touchmove', this.handleTouchMove, false);
		document.removeEventListener('touchend', this.handleTouchEnd, false);
	}

	xDown = null;
	yDown = null;

	getTouches(evt) {
		return evt.touches || // browser API
			evt.originalEvent.touches; // jQuery
	}

	handleTouchStart = (evt) => {
		const firstTouch = this.getTouches(evt)[0];
		this.xDown = firstTouch.clientX;
		this.yDown = firstTouch.clientY;
	};

	handleTouchEnd = (evt) => {
		this.xDown = null;
		this.yDown = null;
	}

	handleTouchMove = (evt) => {
		if (!this.xDown || !this.yDown) {
			return;
		}

		let xUp = evt.touches[0].clientX;
		let yUp = evt.touches[0].clientY;

		let xDiff = this.xDown - xUp;
		let yDiff = this.yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
			if (xDiff > 0) {
				/* left swipe */
			} else {
				if (xDiff < -20) {
					this.close();
				}
			}
		} else {
			if (yDiff > 0) {
				/* up swipe */
			} else {
				/* down swipe */
			}
		}
	};
}
