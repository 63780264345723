import {CardSizeType, ChangeFunction} from '../types';
import {useValue} from '../hooks';
import {GridSix, GridNine, GridTwelve} from '@esgi/ui/icons';
import {IconComponent} from '@esgi/ui';
import {Section, Title, ToggleGroupRoot, ToggleGroupContent, ToggleGroupItem, Label} from './index.styled';

const sizes: [CardSizeType, number, IconComponent][] = [
	[CardSizeType.Six, 6, GridSix],
	[CardSizeType.Nine, 9, GridNine],
	[CardSizeType.Twelve, 12, GridTwelve],
];

interface Props {
	disabled?: boolean;
	onChange: ChangeFunction<CardSizeType>;
}

export function CardSizeControl({disabled, onChange}: Props) {
	const [value, setValue] = useValue<CardSizeType>(CardSizeType.Six, onChange);

	return (
		<Section dataCy='card-size-control-section'>
			<Title data-cy='card-size-control-title' size='small' font='mono' color='lowContrast'>Card Size</Title>
			<ToggleGroupRoot
				value={value.toString()}
				disabled={disabled}
			>
				<ToggleGroupContent>
					{sizes.map(([value, label, Icon]) => (
						<ToggleGroupItem
							key={value}
							value={value.toString()}
							onClick={() => setValue(value)}
						>
							<Icon/>
							<Label size='medium' bold>{label}</Label>
						</ToggleGroupItem>
					))}
				</ToggleGroupContent>
			</ToggleGroupRoot>
		</Section>
	);
}
