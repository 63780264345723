import {Content, Divider, Header} from '../../../../layout/index.styled';
import {Reports} from '@esgi/main/kits/reports';
import {SelectableListItem} from '../../service/types';
import {TestList} from './components/test-list';
import {SubjectTab} from '@esgi/main/libs/store';

type Props = {
	selectedEntity: SelectableListItem | null,
	subjects: SubjectTab[],
	isSubjectsLoaded: boolean,
	onSubjectSelect: (value: number) => void;
	selectedSubject: number;
	onTestSelect: (value: number[]) => void;
}

export function TestSelectionPanel({
	selectedEntity,
	subjects,
	isSubjectsLoaded,
	selectedSubject,
	onSubjectSelect,
	onTestSelect,
}: Props) {

	return(
		<Content>
			<Header>
				<Reports.UnitNamePanel
					skeleton={!selectedEntity?.label}
					title={selectedEntity?.label}/>
				<Divider/>
				<Reports.SubjectControl
					skeleton={!isSubjectsLoaded}
					subjects={subjects}
					value={selectedSubject.toString()}
					onChange={value => onSubjectSelect(+value)}
				/>
			</Header>
			{isSubjectsLoaded && <TestList
				tests={subjects.find(s => s.id === selectedSubject)?.tests ?? []}
				onTestSelect={onTestSelect}
				isTestsLoading={!isSubjectsLoaded}/>
			}
		</Content>
	);
}