import {Dispatch, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {MultiSelectContext, MultiSelectContextValue, MultiSelectState} from './context';
import {Class, Group, Student} from '@esgi/main/libs/store';
import {useStudentsPanelContext} from '../../../../context';
import {uniq} from 'underscore';

type Props = {
	selected: MultiSelectState | undefined;
	onSelectedChange: Dispatch<MultiSelectState>;
} & PropsWithChildren;

export function MultiSelect({
	children,
	selected: externalSelectedState = {
		classIDs: [],
		groupIDs: [],
		studentIDs: [],
	},
	onSelectedChange,
}: Props) {
	const [selected, setSelected] = useState(externalSelectedState);

	useEffect(() => {
		setSelected(externalSelectedState);
	}, [externalSelectedState]);

	const {
		entities: {groups, classes},
	} = useStudentsPanelContext();

	const selectEntity = useCallback(
		({
			classID,
			groupID,
			studentID,
		}: Partial<{classID: Class['id']; groupID: Group['id']; studentID: Student['id']}>) => {
			const out = {...selected};
			const {classIDs, groupIDs, studentIDs} = out;

			if (classID) {
				const classModel = classes.find((c) => c.id === classID);
				const classGroups = groups.filter((g) => g.classID === classID).map((g) => g.id);
				const classStudents = classModel?.studentIDs || [];
				if (classIDs.includes(classID)) {
					out.classIDs = classIDs.filter((c) => c !== classID);
					out.groupIDs = groupIDs.filter((g) => !classGroups.includes(g));
					out.studentIDs = studentIDs.filter((s) => !classStudents.includes(s));
				} else {
					out.classIDs = [...classIDs, classID];
					out.groupIDs = [...groupIDs, ...groups.filter((g) => g.classID === classID).map((g) => g.id)];
					out.studentIDs = [...studentIDs, ...classStudents];
				}
			}

			if (groupID) {
				const group = groups.find((g) => g.id === groupID);
				const groupStudents = group?.studentIDs || [];

				if (groupIDs.includes(groupID)) {
					out.classIDs = classIDs.filter((c) => c !== group?.classID);
					out.groupIDs = groupIDs.filter((c) => c !== groupID);
					out.studentIDs = studentIDs.filter((s) => !groupStudents.includes(s));
				} else {
					out.groupIDs = [...groupIDs, groupID];
					out.studentIDs = [...studentIDs, ...groupStudents];
				}
			}

			if (studentID) {
				const studentsClasses = classes.filter((c) => c.studentIDs.includes(studentID)).map((c) => c.id);
				const studentsGroups = groups.filter((g) => g.studentIDs.includes(studentID)).map((g) => g.id);

				if (studentIDs.includes(studentID)) {
					out.classIDs = classIDs.filter((c) => !studentsClasses.includes(c));
					out.groupIDs = groupIDs.filter((g) => !studentsGroups.includes(g));
					out.studentIDs = studentIDs.filter((c) => c !== studentID);
				} else {
					out.studentIDs = [...studentIDs, studentID];
				}
			}

			out.studentIDs = uniq(out.studentIDs);

			const selectedClasses = classes
				.filter((c) => c.studentIDs.every((s) => out.studentIDs.includes(s)))
				.map((c) => c.id);

			const selectedGroups = groups
				.filter((g) => g.studentIDs.every((s) => out.studentIDs.includes(s)))
				.map((g) => g.id);

			out.classIDs = uniq([...out.classIDs, ...selectedClasses]);
			out.groupIDs = uniq([...out.groupIDs, ...selectedGroups]);

			setSelected(out);
		},
		[classes, groups, selected],
	);

	const handleToggleClassId = useCallback(
		(classID: Class['id']) => {
			selectEntity({classID});
		},
		[selectEntity],
	);

	const handleToggleGroupId = useCallback(
		(groupID: Group['id']) => {
			selectEntity({groupID});
		},
		[selectEntity],
	);

	const handleToggleStudentId = useCallback(
		(studentID: Student['id']) => {
			selectEntity({studentID});
		},
		[selectEntity],
	);

	const multiSelectContext = useMemo<MultiSelectContextValue>(
		() => ({
			selected,
			toggleClassId: handleToggleClassId,
			toggleGroupId: handleToggleGroupId,
			toggleStudentId: handleToggleStudentId,
		}),
		[handleToggleClassId, handleToggleGroupId, handleToggleStudentId, selected],
	);

	return <MultiSelectContext.Provider value={multiSelectContext}>{children}</MultiSelectContext.Provider>;
}
