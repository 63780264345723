import {Buttons} from '@esgillc/ui-kit/button';
import {Form, FormControl, FormElement, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {Hint, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {useStream} from '@esgillc/ui-kit/utils';
import React, {useEffect, useMemo, useState} from 'react';
import {ContinueSessionAlert} from '../continue-session-alert';
import {SecondScreenService} from '../../service';
import styles from '../../styles.module.less';

interface Props {
	service: SecondScreenService;
}

export function ConnectScreen({service}: Props) {
	const error = useStream(service.onError);

	const form = useMemo(() => {
		return new FormGroup({
			code: new FormControl('', {validators: [Validators.required(), Validators.length.min(4), Validators.length.max(4)]}),
		});
	}, []);

	const [showContinueAlert, setShowContinueAlert] = useState(false);

	useEffect(() => {
		const secret = service.getStoredSecret();
		if(secret) {
			setShowContinueAlert(true);
		}
	}, []);

	const continueSession = () => {
		setShowContinueAlert(false);
		service.joinWithSecret(service.getStoredSecret());
	};
	const rejectSession = () => {
		setShowContinueAlert(false);
		service.clearStoredSecret();
	};

	const join = () => {
		form.controls.code.validate().subscribe(r => {
			if(!r.length) {
				service.join(form.controls.code.value.toLowerCase());
			}
		});
	};

	const keyPressed = (e) => e.code ==='Enter' && join();

	return <>
		<div className={styles.secondScreen}>
			<h1>ESGI's Student Screen</h1>
			<Form controller={form}>
				<FormElement control={form.controls.code}>
					<Input placeholder='Enter Session Code' type='text' className={styles.code} onKeyPress={keyPressed}/>
					<OnErrorTooltip showOnError='required' placement='left'>
						Code is required
					</OnErrorTooltip>
					<OnErrorTooltip showOnError='length-min' placement='left'>
						This is not a valid code.
					</OnErrorTooltip>
					<OnErrorTooltip showOnError='length-max' placement='left'>
						This is not a valid code.
					</OnErrorTooltip>
					{error && <Hint>{error}</Hint>}
				</FormElement>
			</Form>
			<div className='row'>
				<Buttons.Contained onClick={join} className={styles.joinBtn}>
					Join to ESGI Session
				</Buttons.Contained>
			</div>
		</div>
		{showContinueAlert && <ContinueSessionAlert onConfirm={continueSession} onCancel={rejectSession}/>}
	</>;
}
