import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const SkeletonRow = styled(Box, {
	...SkeletonStyles,
	height: 48,
	width: '100%',
	borderRadius: 4,
});
