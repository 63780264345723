import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Wrapper = styled(GridBox, {
	gridTemplateColumns: '272px',
	overflow: 'hidden',
});

export const Column = styled(GridBox, {
	alignContent: 'start',
	borderRight: '1px solid $mild',
	overflow: 'hidden',
});

export const SelectWrapper = styled(GridBox, {
	padding: '20px',
	borderBottom: '1px solid $mild',
	overflow: 'hidden',
});

export const DomainsWrapper = styled(GridBox, {
	padding: '20px',
	overflow: 'hidden',
});