import {
	ActionsDataType,
	ConflictAction,
	ConflictStudentField,
	ConflictStudentModel,
	ConflictType,
	StudentField,
} from '../../types';

export const updateActions = (conflictedStudents: ConflictStudentModel[], studentToUpdate: ConflictStudentModel, actionsData: ActionsDataType) => {
	return conflictedStudents.map(student => {
		if(student.id === studentToUpdate.id){
			return {
				...studentToUpdate,
				studentIDN: applyConflictAction(studentToUpdate.studentIDN, actionsData.studentIDN),
				name: applyConflictAction(studentToUpdate.name, actionsData.name),
				language: applyConflictAction(studentToUpdate.language, actionsData.language),
			};
		}
		return student;
	});
};

export const handleFilterExistingStudentsData = (conflictedStudent, conflictType) => {
	const filterByAttribute = (attribute: StudentField, value: string) =>
		conflictedStudent.existingStudents
			.filter(student => student[attribute] === value)
			.map(({name, studentIDN}) => ({name, studentIDN, imageSrc: ''}));

	switch (conflictType) {
		case ConflictType.DuplicateStudentIDN:
			return filterByAttribute(StudentField.StudentIDN, conflictedStudent.studentIDN.value);

		case ConflictType.DuplicateStudentName:
			return filterByAttribute(StudentField.Name, conflictedStudent.name.value);

		default:
			return [];
	}
};

const applyConflictAction = (field: ConflictStudentField, action: ConflictAction) => ({...field, conflictAction: action});
