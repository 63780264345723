import {deepCopy} from 'shared/utils';
import {Criteria, Description, Level} from '../models';
import {CriteriaInfo, DescriptionsInfo, LevelsInfo} from './common';
import {recalculateCriteriaOrders, recalculateLevelsOrders} from './ordering';
import {sortLevels} from './sorting';

export function removeCriteria(entity: Criteria, criteria: Criteria[], descriptions: Description[]): CriteriaInfo & DescriptionsInfo {
	const safeCriteria = deepCopy(criteria).filter(c => c.order !== entity.order);
	const safeDescriptions = deepCopy(descriptions).filter(d => d.criteriaID !== entity.id);

	const recalculateResult = recalculateCriteriaOrders(safeCriteria);
	return {
		criteria: recalculateResult.criteria,
		descriptions: safeDescriptions,
	};
}

export function removeLevel(entity: Level, levels: Level[], descriptions: Description[], isZero: boolean): LevelsInfo & DescriptionsInfo {
	const safeLevels = deepCopy(levels).filter(l => l.score !== entity.score);
	const recalculateResult = recalculateLevelsOrders(safeLevels, isZero);
	const safeDescriptions = deepCopy(descriptions).filter(d => d.levelID !== entity.id).sort((d1, d2) => {
		return safeLevels.findIndex(l => l.id === d1.levelID) - safeLevels.findIndex(l => l.id === d2.levelID);
	});

	return {
		levels: sortLevels(recalculateResult.levels),
		descriptions: safeDescriptions,
	};
}
