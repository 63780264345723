import {styled} from '@esgi/ui/theme';
import {Content as ContantBase} from '../layout/index.styled';
import {Button} from '@esgi/ui';

export const Content = styled(ContantBase, {
	gap: '$4',
});

export const ButtonIcon = styled(Button.Icon, {
	marginLeft: 'auto',
	marginRight: '8px',
});
