import {styled} from '../../../../theme';
import {DraggableItem} from '../../../../icons';

export const DraggableTagItem = styled('span', {
	color: '$tertiary',
	fontWeight: 600,
	fontSize: 11,
	lineHeight: '12px',
	display: 'flex',
	padding: '0 8px 0 6px',
	alignItems: 'center',
	border: '1px solid $tertiaryMuted',
	transition: 'border-color .2s ease',
	borderRadius: '5px',
	height: '22px',
	cursor: 'grab',
	whiteSpace: 'nowrap',
	backgroundColor: '$tertiarySurface',
	'&>svg': {
		transition: 'color .2s ease',
		color: '$tertiaryHighlight',
	},
	'&:hover': {
		borderColor: '$orange56',
		'&>svg': {
			color: '$orange56',
		},
	},
});

export const DraggableTagIcon = styled(DraggableItem, {
	marginRight: '6px',
	height: '100%',
});
