import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {animated} from 'react-spring';

export const Container = styled(Box, {
	width: 16,
	borderRadius: 2,
	overflow: 'hidden',
});

export const AnswerBar = styled(animated.div, {
	boxSizing: 'border-box',
	width: '100%',

	variants: {
		itemType: {
			correct: {
				borderTopStyle: 'solid',
				borderTopWidth: 2,
				borderTopColor: '$positive',
				backgroundColor: '$positiveMuted',
			},

			incorrect: {
				borderTopStyle: 'solid',
				borderTopWidth: 2,
				borderTopColor: '$mediumContrast',
				backgroundColor: '$mild',
			},

			notTested: {
				backgroundColor: '$vivid',
				borderStyle: 'solid',
				borderWidth: 1,
				borderColor: '$mild',
			},
		},
	},
});
