import {useEffect} from 'react';
import {RubricResultReportService} from '../../../../service';
import {useStream} from '@esgillc/ui-kit/utils';
import {ReportResponse} from '../../../../service/types';
import {useStudentnameColumn} from './hooks/use-student-name-column';
import {useCriteriaResultColumns} from './hooks/use-criteria-results-columns';
import {useResultColumn} from './hooks/use-result-column';
import {isNull} from 'underscore';
import {Table} from './components/table';
import {StudentResult} from '../../../../types/table-level-report-service-types';

type TableLevelReportProps = {
	service: RubricResultReportService;
};

export function TableLevelReport({service}: TableLevelReportProps) {
	const reportData = useStream<ReportResponse>(service.reportData$);
	const rows = useStream<StudentResult[]>(service.rows$);
	const sortBy = useStream(service.sortModel);
	const sortByCriteriaResultsOptions = useStream(service.sortByCriteriaResultsOptions$);
	const studentNameColumn = useStudentnameColumn({service, sortBy});
	const criteriaResultColumns = useCriteriaResultColumns({
		rows,
		levels: reportData?.levels,
		service,
		sortBy,
		sortByCriteriaResultsOptions,
	});
	const resultColumn = useResultColumn({service, rows: reportData?.results});

	useEffect(() => {
		service.initReport();
	}, [service]);

	if (isNull(criteriaResultColumns)) {
		return null;
	}

	return <Table
		rows={rows}
		columns={[studentNameColumn, ...criteriaResultColumns, resultColumn]}
	/>
}
