import React from 'react';
import {UserInfo, userStorage} from '@esgi/core/authentication';


export class AppContext {
	get currentUser(): UserInfo {
		return userStorage.get();
	}

	touchDevice = document.body.parentElement.classList.contains('touch')
}

//TODO: refactor this;
const context = new AppContext();
(window as any).context = context;
export const AppContextProvider = React.createContext<AppContext>(context);
