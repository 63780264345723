import {Text} from '@esgi/ui/typography';
import {Container, TextContainer} from './index.styled';

type Props = {
	message: string;
};

export function NoItems({message}: Props) {
	return (
		<Container>
			<TextContainer>
				<Text data-cy='empty-box-message' font='mono' size='small' color='highContrast'>
					{message}
				</Text>
			</TextContainer>
		</Container>
	);
}
