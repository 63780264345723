import './styles.less';
import {dispatchAppEvent} from '@esgillc/events';
import {FilterType} from '../../../enums';
import PagingInfo from '../paging-info/paging-info';
import SearchTag from './search-tag';


type Props = {
	filters: Filter[];
	total: number;
}

export class Filter {
	id: any;
	name: string;
	type: FilterType;
}

export class RemoveFilterEvent {
	constructor(public id: any, public type: FilterType) {
	}
}

export function SearchTags(props: Props): JSX.Element {
	return <div className='search-tags'>
		{props.filters.map(c => {
			return <SearchTag key={c.id}
			                  name={c.name}
			                  removeTag={() => dispatchAppEvent(RemoveFilterEvent, new RemoveFilterEvent(c.id, c.type))}/>;
		})}
		<PagingInfo total={props.total}/>
	</div>;
}
