import React, {useCallback, useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {FrontendNewVersionAvailableEvent, LoadScriptFailedEvent} from '@esgi/core/react';
import {LoadErrorAlert} from './components/load-error-alert';
import {NewVersionAvailableAlert} from './components/new-version-available-alert';
import styles from './load-alerts-layer.module.less';

export function LoadAlertsLayer() {
	const [newVersionAvailable, setNewVersionAvailable] = useState(false);
	const [failToLoadScript, setFailToLoadScript] = useState(false);
	const reloadPage = useCallback(() => location.reload(), []);

	useEventEffect(FrontendNewVersionAvailableEvent, () => setNewVersionAvailable(true), [setNewVersionAvailable]);
	useEventEffect(LoadScriptFailedEvent, () => setFailToLoadScript(true), [setFailToLoadScript]);

	if(failToLoadScript) {
		return <LoadErrorAlert onClosed={reloadPage}/>;
	}

	if(newVersionAvailable) {
		return <div className={styles.alertHolder}>
			<NewVersionAvailableAlert reloadClicked={reloadPage}
			                          skipClicked={() => setNewVersionAvailable(false)}/>
		</div>;
	}
}
