import {IEPGoalModel} from './models';

export class IEPGoalCreated {
	constructor(
		public goal: IEPGoalModel,
	) {
	}
}

export class IEPGoalChanged {
	constructor(
		public goal: IEPGoalModel,
	) {
	}
}
