import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

type Props = {
	onAlertClose: VoidFunction;
};

export function MaximumStudents({onAlertClose}: Props) {
	return (
		<>
			<Alert.Header />
			<Alert.Body>
				<Text size='medium'>Your File Exceeds the Maximum Number of Students.</Text>
			</Alert.Body>
			<Alert.Footer>
				<Button.Text onClick={onAlertClose}>Got It</Button.Text>
			</Alert.Footer>
		</>
	);
}
