import {Controls, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {ZendeskFieldResponse, ZendeskFormResponse} from './api';

function createDynamicFields(fields: ZendeskFieldResponse[]): Controls {
	const out: Controls = {};

	for (const field of fields) {
		const validators = [field.isRequired && Validators.required()].filter(Boolean);
		let value = field.type.toLowerCase() === 'dropdown' ? [] : field.value;
		out[field.id] = new FormControl<any>(value, {validators});
	}
	return out;
}

export type ContactUsFormControls = {
  questionType: FormControl<any>,
  topic: FormControl<string>,
  message: FormControl<string>,
  name: FormControl<string>,
  email: FormControl<string>,
  phone: FormControl<string>
} & Controls;

export const CommonFields = ['topic', 'message', 'name', 'email', 'phone', 'question type'];
export const HiddenFields = ['exception token'];

export function filterDynamicFields(fields: ZendeskFieldResponse[]): ZendeskFieldResponse[] {
	return fields
		.filter(f => CommonFields.indexOf(f.title?.toLowerCase()) < 0)
		.filter(f => HiddenFields.indexOf(f.title?.toLowerCase()) < 0);
}

export function createForm({fields, email}: ZendeskFormResponse): FormGroup<ContactUsFormControls, any> {
	const dynamicFields = filterDynamicFields(fields);
	const dynamicFormControls = createDynamicFields(dynamicFields);

	return new FormGroup({
		questionType: new FormControl(fields.find(f => f.title.toLowerCase() === 'question type')?.value || [], {validators: [Validators.required()]}),
		topic: new FormControl('', {validators: [Validators.required()]}),
		message: new FormControl('', {validators: [Validators.required()]}),
		name: new FormControl(fields.find(f => f.title.toLowerCase() === 'name')?.value || ''),
		email: new FormControl(email, {validators: [Validators.required(), Validators.email()]}),
		phone: new FormControl('', {validators: [Validators.phone()]}),
		...dynamicFormControls,
	});
}
