
export class DrawerManager {
	protected destroyed: boolean = false;

	constructor(protected onOpenRequest: (callback?: VoidFunction) => void, protected onCloseRequest: (callback?: VoidFunction) => void) {
	}

	public open = (callback?: VoidFunction) => {
		if (!this.destroyed) {
			this.onOpenRequest(callback);
		}
	};

	public close = (callback?: VoidFunction) => {
		if (!this.destroyed) {
			this.onCloseRequest(callback);
		}
	};

	public destroy() {
		this.destroyed = true;
	}
}

export enum DrawerMode {
	Edit = 'edit',
	Create = 'create',
	View = 'view',
}

export enum DrawerSearchKey {
	DrawerMode = 'drawerMode',
	DrawerName = 'drawerName',
}
