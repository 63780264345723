import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const CardBox = styled(Box, {
	maxWidth: '274px',
	minWidth: '274px',
	height: 136,
	background: '$background',
	border: '1px solid $neutral98',
	borderRadius: 12,
	marginRight: 12,

	variants: {
		transparent: {
			true: {
				background: '$vivid',
				border: 'none',
				height: 112,
			},
		},
	},
});

export const AddMoreWidgetsBox = styled(Box, {
	maxWidth: '274px',
	minWidth: '274px',
	height: 136,
	cursor: 'pointer',
	position: 'relative',
	variants: {
		compact: {
			true: {
				height: 56,
			},
		},
	},
});

export const AddMoreWidgetBackground = styled(Box, {
	position: 'absolute',
	top: 0,
	left: 0,
	width: 274,
	height: 136,
	variants: {
		compact: {
			true: {
				height: 56,
			},
		},
	},
});

export const AddMoreWidgetsContent = styled(Box, {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	height: '100%',
	padding: 12,
	position: 'relative',
});

export const CustomiseButton = styled(Button.Icon, {
	width: 32,
	height: 32,
	borderRadius: 6,
	background: '$vivid',
	'& svg': {
		width: 24,
	},
});