import {useCallback, useMemo} from 'react';
import {Container, Table, TableBody, Row, Cell} from './index.styled';
import {CommonProps} from '../../../../types';
import {formate, styleCell} from '../../../../utils';
import {MarkingPeriodItem} from '../../../../../../types';

type Props = CommonProps & {
	markingPeriods: MarkingPeriodItem[];
	gradeScaleAllow: boolean;
};

export function Scores({
	test,
	reportSettings: {showBaseline, displayZeroIfNotTested, includeGradeScore, printInColor, markingPeriodAll},
	markingPeriods,
	gradeScaleAllow,
}: Props) {
	const notTestedDisplayValue = displayZeroIfNotTested ? '0' : 'NT';

	const shouldRenderCell = useCallback((index: number) => {
		if ((index === 0 && showBaseline) || index === markingPeriods.length - 1) {
			return true;
		}
		if (markingPeriodAll && index !== 0) {
			return true;
		}
		return false;
	}, [showBaseline, markingPeriodAll]);

	const headers = useMemo(() => markingPeriods.map((mPeriod, index) => shouldRenderCell(index) &&
		<Cell
			key={`header-${test.testID}-${mPeriod.title}-${mPeriod.index}`}
			basis={index === 0}
			gray
		>
			{mPeriod.title}
		</Cell>
	), [markingPeriods, shouldRenderCell]);

	const scores = useMemo(() => markingPeriods.map((mPeriod, index) => shouldRenderCell(index) &&
		<Cell
			key={`score-${test.testID}-${mPeriod.title}-${mPeriod.index}`}
			style={{backgroundColor: styleCell(mPeriod, test, gradeScaleAllow, includeGradeScore, printInColor)}}
		>
			{test.scores[index]?.testDate === null && notTestedDisplayValue !== 'NT' ? '0' : formate(mPeriod, test, 'raw')}
		</Cell>
	), [markingPeriods, shouldRenderCell, notTestedDisplayValue, test, gradeScaleAllow, includeGradeScore, printInColor]);

	const grades = useMemo(() => markingPeriods.map((mPeriod, index) => shouldRenderCell(index) &&
		<Cell
			key={`grade-${test.testID}-${mPeriod.title}-${mPeriod.index}`}
			style={{backgroundColor: styleCell(mPeriod, test, gradeScaleAllow, includeGradeScore, printInColor)}}
		>
			{test.scores[index]?.testDate === null && notTestedDisplayValue !== 'NT' ? '0' : formate(mPeriod, test, 'grade')}
		</Cell>
	), [markingPeriods, shouldRenderCell, notTestedDisplayValue, test, gradeScaleAllow, includeGradeScore, printInColor]);

	return (
		<Container>
			<Table data-cy='scores-table'>
				<TableBody data-cy='scores-table-body'>
					<Row data-cy='headers-row'>
						<Cell gray/>
						{headers}
					</Row>
					<Row data-cy='scores-row'>
						<Cell>Score</Cell>
						{scores}
					</Row>
					{(gradeScaleAllow && includeGradeScore) &&
						<Row data-cy='grades-row'>
							<Cell data-cy='grades-cell'>Grade</Cell>
							{grades}
						</Row>
					}
				</TableBody>
			</Table>
		</Container>
	);
}
