import {Drawer} from '@esgi/ui/layout';
import {PropsWithChildren, RefObject, useCallback, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {DrawerRef, DrawerSearchKey} from '../types';

export type DrawerWithSearchParamsProps = PropsWithChildren<{
	drawerName: string;
	drawerRef: RefObject<DrawerRef | undefined>;
	onClickOutside?: VoidFunction;
	dataCy?: string;
}>;

export function DrawerWithSearchParams({drawerName, children, drawerRef, onClickOutside}: DrawerWithSearchParamsProps) {
	const [open, setOpen] = useState(false);

	const [searchParams, updateSearchParams] = useSearchParams();

	const searchParamsHistory = useRef<Record<string, string>[]>([]);

	useLayoutEffect(() => {
		const newSearchParams: Record<string, string> = {};

		searchParams.forEach((value, key) => {
			newSearchParams[key] = value;
		});

		const currDrawerIdx = searchParamsHistory.current.findIndex((item) => item.drawerName);

		if (currDrawerIdx !== -1) {
			searchParamsHistory.current.splice(currDrawerIdx, 1, newSearchParams);
		} else {
			searchParamsHistory.current.push(newSearchParams);
		}

		const currentDrawerName = searchParams.get(DrawerSearchKey.DrawerName);

		setOpen(currentDrawerName === drawerName);
	}, [drawerName, searchParams]);

	const closeDrawer = useCallback(
		(cb?: VoidFunction) => {
			const penultSearchParams = searchParamsHistory.current.at(-2);

			searchParamsHistory.current.splice(-1, 2);

			updateSearchParams(new URLSearchParams(penultSearchParams));
			cb?.();
		},
		[updateSearchParams],
	);

	useImperativeHandle(drawerRef, () => ({
		closeDrawer,
	}));

	return (
		<Drawer show={open} onClickOutside={onClickOutside}>
			{children}
		</Drawer>
	);
}
