import {io, Socket} from 'socket.io-client';

export class ShareScreenSession {
	private socket: Socket;

	constructor() {
		this.socket = io(`wss://${location.host}/teacher`, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 3000,
			path: '/share-screen/api',
			reconnectionAttempts: Infinity,
			transports: ['websocket', 'polling'],
		});

		this.socket.on('reconnect_attempt', () => {
			this.socket.io.opts.transports = ['polling', 'websocket'];
			$(this).trigger('reconnect_attempt');
		});

		this.socket.on('reconnect_error', () => {
			$(this).trigger('connect_error');
		});

		this.socket.on('connect', () => {
		});

		this.socket.on('connect_error', () => {
			$(this).trigger('connect_error');
		});

		this.socket.on('connect_timeout', () => {
			$(this).trigger('connect_error');
		});

		this.socket.on('shared', (data) => {
			$(this).trigger('shared', data);
		});

		this.socket.on('codeExpired', () => {
			$(this).trigger('codeExpired');
		});

		this.socket.on('sessionEnded', () => {
			$(this).trigger('sessionEnded');
		});

		this.socket.on('studentJoined', () => {
			$(this).trigger('studentJoined');
		});
	}

	endSession(teacherID: string) {
		this.socket.emit('endSession', teacherID);
	}

	endTest() {
		this.socket.emit('endTest');
	}

	share(teacherID: number) {
		this.socket.emit('share', teacherID);
	}

	isShared(teacherID: number) {
		this.socket.emit('isShared', teacherID);
	}

	isExpired(teacherID: number) {
		this.socket.emit('isExpired', teacherID);
	}

	events = {
		shared: (callback) => {
			$(this).on('shared', callback);
		},
		codeExpired: (callback) => {
			$(this).on('codeExpired', callback);
		},
		sessionEnded: (callback) => {
			$(this).on('sessionEnded', callback);
		},
		connectionFail: (callback) => {
			$(this).on('connect_error', callback);
		},
		clientJoined: (callback) => {
			$(this).on('studentJoined', callback);
		},
		reconnectAttempt: (callback) => {
			$(this).on('reconnect_attempt', callback);
		},
	};

	showQuestion(questionIndex: number) {
		this.socket.emit('showQuestion', questionIndex);
	}

	summary(total: number, correct: number, testResultsCorrectVerbiage: string, testResultsIncorrectVerbiage: string, showTestSessionResults: boolean) {
		this.socket.emit('summary',
			{
				total: total,
				correct: correct,
				testResultsCorrectVerbiage: testResultsCorrectVerbiage,
				testResultsIncorrectVerbiage: testResultsIncorrectVerbiage,
				showTestSessionResults: showTestSessionResults,
			});
	}

	dispose() {
		this.socket.close();
	}
}

export class ClientScreenSession {
	private socket: Socket;

	constructor() {
		this.socket = io(`wss://${location.host}/student`, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 3000,
			path: '/share-screen/api',
			reconnectionAttempts: Infinity,
			transports: ['websocket'],
		});

		this.socket.on('reconnect_attempt', () => {
			this.socket.io.opts.transports = ['websocket'];
		});

		this.socket.on('reconnect_error', () => {
			$(this).trigger('connect_error');
		});

		this.socket.on('connect_timeout', () => {
			$(this).trigger('connect_error');
		});

		this.socket.on('badCode', () => {
			$(this).trigger('badCode');
		});

		this.socket.on('applyed', () => {
			$(this).trigger('applyed');
		});

		this.socket.on('joined', (data: any) => {
			this.socket.on('testStarted', (data: any) => {
				$(this).trigger('testStarted', data);
			});
			this.socket.on('testEnded', () => {
				$(this).trigger('testEnded');
			});

			this.socket.on('showQuestion', (index: number) => {
				$(this).trigger('showQuestion', index);
			});

			this.socket.on('summary', (data: any) => {
				$(this).trigger('summary', data);
			});

			this.socket.on('sessionEnded', () => {
				$(this).trigger('sessionEnded');
			});

			this.socket.on('reconnect', () => {
				this.socket.emit('reconnect_client', data.secretCode);
			});

			this.socket.on('reconnected', (data) => {
				$(this).trigger('reconnected', data);
			});

			this.socket.on('disconnect', () => {
				$(this).trigger('disconnected');
			});
			$(this).trigger('joined', data);
		});
	}

	joinWithSecret(code) {
		this.socket.emit('joinWithSecret', code);
	}

	join(code: string) {
		this.socket.emit('join', code);
	}
	synchronizeCurrentQuestion() {
		this.socket.emit('synchronizeCurrentQuestion');
	}
	events = {
		joined: (callback) => {
			$(this).on('joined', callback);
		},
		badCode: (callback) => {
			$(this).on('badCode', callback);
		},
		applyed: (callback) => {
			$(this).on('applyed', callback);
		},
		testStarted: (callback) => {
			$(this).on('testStarted', callback);
		},
		testEnded: (callback) => {
			$(this).on('testEnded', callback);
		},
		summary: (callback) => {
			$(this).on('summary', callback);
		},
		showQuestion: (callback) => {
			$(this).on('showQuestion', callback);
		},
		sessionEnded: (callback) => {
			$(this).on('sessionEnded', callback);
		},
		reconnected: (callback) => {
			$(this).on('reconnected', callback);
		},
		disconnected: (callback) => {
			$(this).on('disconnected', callback);
		},
		connectionFail: (callback) => {
			$(this).on('connect_error', callback);
		},
	};
}
