import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {Loader} from '@esgillc/ui-kit/loader';
import {Form} from '@esgillc/ui-kit/form';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {SchoolDetailsService} from './service';
import {SaveResult} from '../../../types';
import ForceTestTypeSection from '../../sections/force-test-type';
import GradeLevelSection from '../../sections/grade-level';
import PublishSection from '../../sections/publish';
import ShuffleQuestionsSection from '../../sections/shuffle-questions';
import SubjectTabNameSection from '../../sections/subject-tab-name';
import SchoolAdministratorPublishTo from '../../sections/publish-to/school';
import styles from './styles.module.less';

class Props {
	subjectID?: number;
	editMode: boolean;
	onClosed: () => void;
	onSaved: (response: SaveResult, gradeLevels: number[]) => void;
}

export default function SchoolTabDetails({
	subjectID,
	editMode,
	onClosed,
	onSaved,
}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClosed);

	const service = useService(SchoolDetailsService);
	const form = service.form;

	const commonData = useBehaviorSubject(service.commonData);
	const gradeLevelsIDs = useBehaviorSubject(service.gradeLevelsIDs);
	const [isInitialized, setInitialized] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);

	useEffect(() => {
		service.init(subjectID).subscribe({
			complete: () => {
				setInitialized(true);
				setLoading(false);
			},
		});
	}, []);

	useStreamEffect(service.form.onChanged, () => {
		service.form.validate(true).subscribe((v) => {
			setSaveButtonDisabled(!v.valid);
		});
	});

	const onSubmit = () => {
		setLoading(true);
		service.save().subscribe({
			next: (res) => {
				onSaved(res, gradeLevelsIDs);
				handleClose();
			},
			complete: () => setLoading(false),
		});
	};

	return <div data-cy={'school-details'}>
		<Loader show={isLoading} fullscreen/>
		{isInitialized && <Modal modalManagerRef={modalRef} onCatchError={handleClose}>
		<Modal.Header>
			<Title>{editMode ? 'Edit Subject Tab' : 'Create New Subject Tab'}</Title>
		</Modal.Header>

		<Modal.Body className={styles.body}>
			<Form controller={form}>
				<SubjectTabNameSection form={form}/>
				<GradeLevelSection form={form} commonData={commonData}/>
				<SchoolAdministratorPublishTo form={form} commonData={commonData}/>
				<PublishSection form={form} commonData={commonData}/>
				<ForceTestTypeSection form={form}/>
				<ShuffleQuestionsSection form={form}/>
			</Form>
		</Modal.Body>

			<Modal.Footer>
				<Buttons.Gray onClick={handleClose}>Cancel</Buttons.Gray>
				<Buttons.Contained disabled={isSaveButtonDisabled} onClick={onSubmit}>
					Save
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>}
	</div>;
}
