import {SnackbarManager} from '@esgi/ui/snackbar';
import {RefObject, useCallback, useMemo, useState} from 'react';
import {Avatar} from '@esgi/ui/avatar';
import {Text} from '@esgi/ui/typography';
import {Button, LinkDelete, Users} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {ClassModel, StudentModel} from '../../types';
import {Container, Counter, HeaderInfo, Item, Title} from './styled';
import {StudentManagerService} from '../../service';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	students: StudentModel[],
	classes: ClassModel[],
	snackbarRef: RefObject<SnackbarManager>,
	service: StudentManagerService,
	onSuccess: VoidFunction;
	onClose: VoidFunction,
}

export function MultipleUnAssignDialog({students, ...props}: Props) {
	const alertRef = Alert.useRef();
	const close = Alert.useClose(alertRef, props.onClose);
	const [loading, setLoading] = useState(false);

	const confirm = useCallback(() => {
		setLoading(true);
		props.service.unassign(students.map(({studentID}) => studentID)).subscribe({
			next: () => {
				close();
				props.onSuccess();
				props.snackbarRef.current.showSnackbar(
					<Text>
						{`Students have been unassigned from all classes and groups`}
					</Text>);
			},
			complete: () => setLoading(false),
		});
	}, [props.service, props.onSuccess, close, props.snackbarRef, students]);

	const classes = useMemo(() => {
		if (!students.length) {
			return [];
		}

		const selectedStudentsClassIDs = [...new Set(students.reduce((acc, {classIDs}) => acc.concat(classIDs), [] as number[]))];

		return props.classes.filter(c => selectedStudentsClassIDs.includes(c.classID));
	}, [props.classes, students]);

	const groups = useMemo(() => {
		if (!students) {
			return [];
		}

		const selectedStudentsClassIDs = [...new Set(students.reduce((acc, {groupIDs}) => acc.concat(groupIDs), [] as number[]))];

		return classes.map(c => c.groups).flat().filter(g => selectedStudentsClassIDs.includes(g.groupID));
	}, [classes, students]);

	const hasGroups = !!groups.length;

	if (!students) {
		return null;
	}

	return <Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.neutral} dataCy='unassign-dialog'>
		<Alert.Header withBacklight={false} onCloseIconClick={close} disableClose={loading}>
			<HeaderInfo>
				<Users width={24} height={24}/>
				<GridBox gap='1' flow='column' align='center'>
					<Counter>
						<Text size='small' font='mono'>
							{students.length}
						</Text>
					</Counter>
					<Text color='primary' size='small'>Students</Text>
				</GridBox>
			</HeaderInfo>
		</Alert.Header>
		<Alert.Body>
			<Container>
				<Text color='neutral40' size='medium' data-cy='unassign-info'>
					The following students will be unassigned from the following classes{hasGroups && ' and groups'}:
				</Text>
				<Title dataCy='title-students'>
					<Text size='small' color='lowContrast' font='mono'>Students</Text>
				</Title>
				{students.map(student => <Item key={student.studentID}>
						<Avatar.Root size='xs'>
							<Avatar.Fallback>
								{student.firstName[0]} {student.lastName[0]}
							</Avatar.Fallback>
						</Avatar.Root>
						<Text size='small' css={{paddingLeft: 12}} data-cy='student-name'>{student.firstName} {student.lastName}</Text>
				</Item>)}
				<Title dataCy='title-classes'>
					<Text size='small' color='lowContrast' font='mono'>Classes</Text>
				</Title>
				{classes.map(c => <Item key={c.classID} dataCy='class-name'>
						<LinkDelete width={24} height={24}/>
						<Text key={c.classID} color='negative' size='small'>
							{c.name}
						</Text>
				</Item>)}
				{hasGroups && <>
					<Title dataCy='title-groups'>
						<Text size='small' color='lowContrast' font='mono'>Groups</Text>
					</Title>
					{groups.map(g => <Item key={g.groupID} dataCy='group-name'>
							<LinkDelete width={24} height={24}/>
							<Text key={g.groupID} color='negative' size='small'>
								{g.name}
							</Text>
					</Item>)}
				</>}
			</Container>
		</Alert.Body>
		<Alert.Footer>
			<Button css={{marginRight: 12}} color='tertiary' onClick={close} disabled={loading}>
				<Text size='medium' color='base'>
					Cancel
				</Text>
			</Button>
			<Button color='secondary' onClick={confirm} disabled={loading}>
				<Text size='medium' color='base'>
					Unassign
				</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}