import React, {createContext, useContext} from 'react';
import {noop} from 'underscore';
import {
	DPCalendar,
	DPDay,
	DPOffsetValue,
	DPPropGetter,
	DPPropsGetterConfig,
} from '@rehookify/datepicker';
import {DateFormat} from './types';

export type DatePickerContextValue = {
	expanded: boolean;
	setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
	rangeMode: boolean;
	dateValue: string;
	setDateValue: (v: string) => void;
	calendars: DPCalendar[];
	weekDays: string[];
	dayButton?: (day: DPDay, config?: (DPPropsGetterConfig | undefined)) => DPPropGetter;
	subtractOffset?: (offsetValue: DPOffsetValue, config?: (DPPropsGetterConfig | undefined)) => DPPropGetter;
	addOffset?: (offsetValue: DPOffsetValue, config?: (DPPropsGetterConfig | undefined)) => DPPropGetter;
	isCalendarRendered: boolean;
	setCalendarRendered: (v: boolean) => void;
	setApprovedDate: React.Dispatch<React.SetStateAction<Date[]>>;
	error: boolean
	format?: DateFormat
	dateObjects: Date[]
	disabled?: boolean
};

export const DatePickerContext = createContext<DatePickerContextValue>({
	rangeMode: false,
	expanded: false,
	setExpanded: noop,
	setSelectedDates: noop,
	dateValue: '',
	setDateValue: noop,
	calendars: [],
	weekDays: [],
	isCalendarRendered: false,
	setCalendarRendered: noop,
	setApprovedDate: noop,
	error: false,
	format: 'mM/dD/YYYY',
	dateObjects: [],
	disabled: false,
});

export function useDatePickerRootContext() {
	return useContext(DatePickerContext);
}
