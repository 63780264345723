import {HttpClient, ObservableBuilder} from '@esgi/api';

export class ZendeskFormResponse {
	fields: ZendeskFieldResponse[];
	email: string;
}

export class ZendeskFieldOptionViewModel {
	name: string;
	value: string;
}

export class ZendeskFieldResponse {
	title: string;
	id: string;
	value: string;
	type: string;
	options: ZendeskFieldOptionViewModel[];
	isRequired: boolean;
}

export class ZendeskApi {
	private controllerName = 'zendesk';

	init(isCompact: boolean): ObservableBuilder<ZendeskFormResponse> {
    	return HttpClient.default.ESGIApi.get<ZendeskFormResponse>(this.controllerName, 'initialize', {isCompact: isCompact});
	}

	send(fields: ZendeskFieldResponse[], subject: string, description: string, name: string, email: string): ObservableBuilder<any> {
    	return HttpClient.default.ESGIApi.post(this.controllerName, 'send-ticket',
    		{
				fields: fields,
				subject: subject,
				description: description,
				name: name,
				email: email,
    		});
	}
}
