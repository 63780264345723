import {ChangeEvent, useEffect, useMemo} from 'react';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {UploadRosterService} from './service';
import {Notification, Step} from './types';
import {UploadRoster} from './components/steps/upload-roster';
import {AddStudent} from './components/steps/action-modal';
import {ContinueFileUpload} from './components/steps/continue-file-upload';
import {ConflictResolution} from './components/steps/conflict-resolution';
import {InvalidField} from './components/notifications/invalid-field';
import {ConflictExists} from './components/notifications/conflict-exists';
import {MaximumStudents} from './components/notifications/maximum-students';
import {DeleteFileUpload} from './components/notifications/delete-file-upload';
import {IncorrectFileType} from './components/notifications/incorrect-file-type';
import {DuplicateIdInFile} from './components/notifications/duplicate-id-in-file';
import {PurchasingStudents} from './components/notifications/purchasing-students';
import {FileUploadIsComplete} from './components/notifications/file-upload-is-complete';
import {UnsupportedFieldName} from './components/notifications/unsupported-field-name';
import {MissingRequiredInformation} from './components/notifications/missing-required-information';
import {SchoolSelectModal} from 'modules/forms/students-form/components/school-select-modal';
import {getUser, UserType} from 'libs/core/src/authentication';
import {ISchoolBox} from 'modules/hierarchy/core/api';

interface Props {
	onExit: () => void;
	initialStep: Step;
	userID?: number;
	userType?: UserType;
	classID?: number;
	schoolID?: number;
	specialistGroupID?: number;
	onAddIndividualityClicked: () => void;
	onSchoolSelected: (id: number) => void;
	onGetStudentProfile?: (id: number) => void;
	schools: ISchoolBox;
	onChangeFileData: (file: ChangeEvent<HTMLInputElement>) => void;
	uploadedFile?: ChangeEvent<HTMLInputElement>;
}

export function StudentRosterUploadTool(
	{
		onExit,
		initialStep,
		userID,
		userType,
		classID,
		schoolID,
		onAddIndividualityClicked,
		onGetStudentProfile,
		onSchoolSelected,
		specialistGroupID,
		schools,
		onChangeFileData,
		uploadedFile,
	}: Props) {
	const service = useService(UploadRosterService);
	const notification = useBehaviorSubject(service.notification);
	const step = useBehaviorSubject(service.step);
	const validationData = useBehaviorSubject(service.validationData);
	const className = useBehaviorSubject(service.className);
	const specialistGroupName = useBehaviorSubject(service.specialistGroupName);
	const classId = useBehaviorSubject(service.classID);
	const schoolId = useBehaviorSubject(service.schoolID);
	const duplicatesSaved = useBehaviorSubject(service.duplicatesSaved);
	const conflictedStudents = useBehaviorSubject(service.conflictedStudents);
	const uploadedStudentsCount = useBehaviorSubject(service.uploadedStudentsCount);
	const completedWithExistsStudents = useBehaviorSubject(service.completedWithExistsStudents);
	const schoolOptions = useMemo(() => {
		return schools?.items?.map(school => ({
			name: school.name,
			id: school.schoolID,
		}));
	}, [schools]);

	useEffect(() => {
		service.init(userID, userType, classID, schoolID, specialistGroupID, initialStep, uploadedFile);
		return () => service.destroy();
	}, []);

	useEffect(() => {
		service.step.next(initialStep);
	}, [initialStep]);

	const handleSchoolSelect = (schoolID: number) => {
		service.schoolID.next(schoolID);
		onSchoolSelected(schoolID);
	};

	const onClicked = () => service.notification.next(Notification.None);
	const onUploadRoster = () => {
		const currentUserType = getUser().userType;
		const isPA = currentUserType === UserType.PA
			|| (currentUserType === UserType.D && userType === UserType.PA)
		if (isPA) {
			service.step.next(Step.SchoolSelection);
		} else {
			service.step.next(Step.UploadRoster);
		}
	};

	const renderStep = useMemo(() => {
		switch (step) {
			case Step.AddStudent: {
				if (classID || specialistGroupID) {
					return <AddStudent
						onClose={onExit}
						onUploadRoaster={() => onUploadRoster()}
						onAddIndividually={() => onAddIndividualityClicked()}
					/>;
				}
				break;
			}

			case Step.UploadRoster: {
				return <UploadRoster
					onDownloadTemplateClicked={() => service.downloadTemplate()}
					onFileUploaded={(e) => {
						service.fileUpload(e);
						onChangeFileData(e);
					}}
					onExit={onExit}
				/>;
			}

			case Step.SchoolSelection: {
				return <SchoolSelectModal onClose={onExit} service={service}
				                          onSchoolSelected={handleSchoolSelect}
				                          onNext={() => service.step.next(Step.UploadRoster)} schools={schoolOptions}/>;
			}

			case Step.ContinueFileUpload: {
				return <ContinueFileUpload
					onCancelClicked={onExit}
					onUploadFileClicked={() => service.step.next(Step.UploadRoster)}
				/>;
			}

			case Step.ConflictResolution: {
				return <ConflictResolution
					onGetStudentProfile={onGetStudentProfile}
					classID={classId}
					schoolID={schoolId}
					onDeleteFileClicked={() => service.notification.next(Notification.DeleteFileUpload)}
					onFileUploadIsComplete={(isDuplicateSaved: boolean, isCompletedWithExistsStudents: boolean, studentsCreatedCount: number) => {
						service.step.next(Step.None);
						service.notification.next(Notification.FileUploadIsComplete);
						service.duplicatesSaved.next(isDuplicateSaved);
						service.uploadedStudentsCount.next(studentsCreatedCount);
						service.completedWithExistsStudents.next(isCompletedWithExistsStudents);
					}}
					specialistGroupID={specialistGroupID}
					students={conflictedStudents}
					onExit={onExit}
				/>;
			}

			default:
				return null;
		}
	}, [step, classID, schoolId]);

	const renderNotification = useMemo(() => {
		switch (notification) {
			case Notification.IncorrectFileType: {
				return <IncorrectFileType
					onClicked={onClicked}
				/>;
			}

			case Notification.MaximumStudents: {
				return <MaximumStudents
					onClicked={onClicked}
				/>;
			}

			case Notification.InvalidField: {
				return <InvalidField
					onClicked={onClicked}
					errors={validationData}
				/>;
			}

			case Notification.UnsupportedFieldName: {
				return <UnsupportedFieldName
					onClicked={onClicked}
					errors={validationData}
				/>;
			}

			case Notification.DuplicateIDInFile: {
				return <DuplicateIdInFile
					onClicked={onClicked}
					errors={validationData}
				/>;
			}

			case Notification.MissingRequiredInformation: {
				return <MissingRequiredInformation
					onClicked={onClicked}
					onDownloadClicked={() => service.downloadTemplate()}
					errors={validationData}
				/>;
			}

			case Notification.PurchasingStudents: {
				return <PurchasingStudents
					onClicked={onClicked}
					classID={classId}
					maxStudents={parseInt(validationData)}
				/>;
			}

			case Notification.FileUploadIsComplete: {
				return <FileUploadIsComplete
					specialistGroupName={specialistGroupName}
					onClicked={onExit}
					studentsCount={uploadedStudentsCount}
					className={className}
					completedWithExistsStudents={completedWithExistsStudents}
					duplicatesSaved={duplicatesSaved}
				/>;
			}

			case Notification.ConflictExists: {
				return <ConflictExists
					onCancelClicked={onClicked}
					onViewStudentsClicked={() => {
						onClicked();
						service.step.next(Step.ConflictResolution);
					}}
					specialistGroupName={specialistGroupName}
					conflictStudentsCount={conflictedStudents?.length}
					uploadedStudentsCount={uploadedStudentsCount}
					className={className}
				/>;
			}

			case Notification.DeleteFileUpload: {
				return <DeleteFileUpload
					onCancelClicked={onClicked}
					onDeleteFileClicked={onExit}
				/>;
			}

			default:
				return null;
		}
	}, [notification]);

	return <>
		<ServiceLoader trackingService={service} fullscreen/>
		{renderStep}
		{renderNotification}
	</>;
}

