import {Observable} from 'rxjs';
import {getClientConfig} from './client-config';
import {ContractHttpClient} from './contract-http-client';
import {ClientFactory} from './types';

type Method = 'GET' | 'POST';

type Endpoint<IN, OUT> = (req: IN) => Observable<OUT>;
type EndpointWithoutParameters<OUT> = () => Observable<OUT>;

function createExecutor<OUT>(method: Method, path: string, clientFactory: ClientFactory, data?: any) {
	return new Observable<OUT>(observer => {
		const httpClient = clientFactory();

		const sub = httpClient.request<OUT>(
			method,
			path,
			data,
		).subscribe(observer);

		return () => {
			sub.unsubscribe();
			httpClient.destroy();
			observer.complete();
		};
	});
}

export function createEndpoint<OUT, IN = any>(method: Method, path: string, clientFactory: ClientFactory): Endpoint<IN, OUT> {
	return (request) => createExecutor(method, path, clientFactory, request);
}

export function createEndpointWithoutParam<OUT>(method: Method, path: string, clientFactory: ClientFactory): EndpointWithoutParameters<OUT> {
	return () => createExecutor(method, path, clientFactory);
}


export abstract class Domain {
	constructor(protected clientFactory: ClientFactory) {}
}

export abstract class BaseApi {
	protected abstract apiName: string;
	protected clientFactory: ClientFactory = () => new ContractHttpClient(getClientConfig(this.apiName));
}