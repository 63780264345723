import {Button as ButtonUI} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const Button = styled(ButtonUI, {
	display: 'inline-grid',
	justifyContent: 'start',
	columnGap: '$3',
	gridAutoFlow: 'column',
	marginLeft: -10,
});
