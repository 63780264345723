import {AvatarRoot} from './components/avatar-root';
import {AvatarImage} from './components/avatar-image';
import {AvatarFallback} from './components/avatar-fallback';
import {AvatarHoverLayer} from './components/avatar-hover-layer';
import {AvatarFilterLayer} from './components/avatar-filter-layer';

export const Avatar = {
	Root: AvatarRoot,
	Image: AvatarImage,
	Fallback: AvatarFallback,
	HoverLayer: AvatarHoverLayer,
	FilterLayer: AvatarFilterLayer,
};
