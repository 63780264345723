import {Users} from '@esgi/ui/icons';
import {IconWrapper, Counter} from './index.styled';
import {CounterBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

interface Props {
	count: number;
	skeleton?: boolean;
}

export function StudentCounter({count, skeleton}: Props) {
	return (
		<Counter data-cy='student-counter' align='center' skeleton={skeleton}>
			<IconWrapper data-cy='multiple-students-icon'>
				<Users/>
			</IconWrapper>
			<CounterBox data-cy='counter-box'>
				<Text font='mono' size='small'>{count}</Text>
			</CounterBox>
			<Text data-cy='counter-label' size='small'>Students</Text>
		</Counter>
	);
}
