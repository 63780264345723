import {ErrorHandler} from './error-handler';

/* The HandlingStrategy class handles error responses from XMLHttpRequest objects by passing them to a
series of ErrorHandler objects until the propagation is stopped. */
export class HandlingStrategy {
	private _stopped: boolean = false;

	constructor(private handlers: ErrorHandler[]) {
	}

	/**
	 * The function iterates through a list of handlers with the response.
	 * @param {XMLHttpRequest} response - The response parameter is an instance of the XMLHttpRequest
	 * class, which represents an HTTP request/response.
	 */
	handle<T>(response: XMLHttpRequest): void {
		for (let handler of this.handlers) {
			if (!this._stopped) {
				handler.next(response, this);
			}
		}
	}

	/**
	 * The stopPropagation function sets a flag to indicate that event propagation should be stopped.
	 */
	public stopPropagation(): void {
		this._stopped = true;
	}
}

/* The HandlingStrategyBuilder class is used to build a HandlingStrategy object by adding error
handlers to it. */
export class HandlingStrategyBuilder {
	private readonly handlers: ErrorHandler[] = [];

	/**
	 * Adds an error handler to a list of handlers, either at the beginning or
	 * the end, depending on the value of the `placeFirst` parameter.
	 * @param {ErrorHandler} errorHandler - The errorHandler parameter is of type ErrorHandler. It
	 * represents the error handler that will be added to the list of handlers.
	 * @param {boolean} [placeFirst=false] - The `placeFirst` parameter is a boolean value that determines
	 * whether the `errorHandler` should be placed at the beginning or the end of the `handlers` array. If
	 * `placeFirst` is `true`, the `errorHandler` will be placed at the beginning of the array.
	 * @returns The method is returning an instance of the HandlingStrategyBuilder class.
	 */
	public withHandler(errorHandler: ErrorHandler, placeFirst: boolean = false): HandlingStrategyBuilder {
		if(placeFirst) {
			this.handlers.unshift(errorHandler);
		} else {
			this.handlers.push(errorHandler);
		}
		return this;
	}

	/**
	 * The build function returns a new instance of the HandlingStrategy class with the provided handlers.
	 * @returns An instance of the HandlingStrategy class is being returned.
	 */
	public build(): HandlingStrategy {
		return new HandlingStrategy(this.handlers);
	}
}
