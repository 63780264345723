import {Container, Sidebar} from '../../../content.styled';
import {CheckboxesBox} from '../../../../../../../../../kit';
import {YAxisLabel, allSessionsSelectedMessage} from '../../constants';
import {DiagramCheckboxes} from '../diagram-checkboxes';
import {CenteredText} from './index.styled';
import {useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {SessionList} from '../../../session-list';
import {DiagramBox} from '../../../../../../diagram-box';
import {ItemAnalysisDiagram, ItemAnalysisDiagramDetailed} from '../../../../../../item-analysis-diagram';
import {SynchronizedStateProps} from '../../types';

type Props = SynchronizedStateProps & {
	isTablet: boolean;
	onFullScreenButtonClick: VoidFunction;
};

export function QuickViewContent({
	isTablet,
	isAllSessionsChecked,
	isDetailedViewChecked,
	toggleAllSessionsCheckbox,
	toggleDetailedViewCheckbox,
	diagramLegendItems,
	onFullScreenButtonClick,
	isAllSessionsSelected,
	selectedSessions,
	testInfo,
	selectedSessionIds,
	setSelectedSessionIds,
	sessionItems,
	diagramPageIndex,
	setDiagramPageIndex,
}: Props) {
	const [diagramBoxHeight, setDiagramBoxHeight] = useState(0);
	const [visibleSessionItemsCount, setVisibleSessionItemsCount] = useState<number>(5);

	const diagramBoxRef = useRef<HTMLDivElement>(null);

	useResizeObserver(diagramBoxRef, (entry) => setDiagramBoxHeight(entry.contentRect.height));

	return (
		<Container isTablet={isTablet} columnGap='60'>
			<Sidebar
				gap={isAllSessionsSelected ? '16' : '20'}
				isTablet={isTablet}
				css={
					!isTablet
						? {
								height: diagramBoxHeight,
								minHeight: 296,
						  }
						: {}
				}
			>
				<CheckboxesBox isTablet={isTablet}>
					<DiagramCheckboxes
						isAllSessionsChecked={isAllSessionsChecked}
						isDetailedViewChecked={isDetailedViewChecked}
						toggleAllSessionsCheckbox={toggleAllSessionsCheckbox}
						toggleDetailedViewCheckbox={toggleDetailedViewCheckbox}
					/>
				</CheckboxesBox>

				{isAllSessionsSelected ? (
					<CenteredText isTablet={isTablet} size='small' font='mono' color='lowContrast'>
						{allSessionsSelectedMessage}
					</CenteredText>
				) : (
					<SessionList
						selectedSessionIds={selectedSessionIds}
						setSelectedSessionIds={setSelectedSessionIds}
						sessionItems={sessionItems}
						type='multiple'
						indicatorPercentageType
						visibleSessionItemsCount={visibleSessionItemsCount}
						setVisibleSessionItemsCount={setVisibleSessionItemsCount}
					/>
				)}
			</Sidebar>

			<DiagramBox
				legendItems={diagramLegendItems}
				withFullScreenButton
				onFullScreenButtonClick={onFullScreenButtonClick}
				ref={diagramBoxRef}
			>
				{isDetailedViewChecked ? (
					<ItemAnalysisDiagramDetailed
						sessions={selectedSessions}
						testInfo={testInfo}
						YAxisLabel={YAxisLabel}
						barWidth={160}
						barHeight={16}
						barsRowGap={20}
						pageIndex={diagramPageIndex}
						onPageIndexChanged={setDiagramPageIndex}
						isTablet={isTablet}
					/>
				) : (
					<ItemAnalysisDiagram
						sessions={selectedSessions}
						testInfo={testInfo}
						YAxisLabel={YAxisLabel}
						barLineWidth={20}
						diagramHeight={212}
						pageIndex={diagramPageIndex}
						onPageIndexChanged={setDiagramPageIndex}
					/>
				)}
			</DiagramBox>
		</Container>
	);
}
