import {AssignmentState} from '@esgi/main/kits/assignments';
import {noTestsMessage} from './constants';
import {TableTests, TableTestsRowData, TableTestsHeader} from '../table-tests';
import {EmptyContent} from '../empty-content';
import {CommonAssignmentProps} from './types';
import {AssignmentHeader} from './components/assignment-header';
import {AssignmentBody} from './components/assignment-body';
import {Dispatch, useCallback} from 'react';
import {Assignment} from '../../../types';

type Props = CommonAssignmentProps & {
	onAssignmentCreate: (args: {assignmentId: Assignment['id']; assignmentName: string}) => void;
	onOpenEditAssignment: Dispatch<Assignment['id']>;
	swapTests: (index1: number, index2: number) => void;
};

export function AssignmentDraft({
	assignment,
	onAssignmentCreate,
	studentsWithoutAccess,
	swapTests,
	students,
	sortStudentsByKey,
	onOpenEditAssignment,
	onDeleteAssignment,
	onOpenExportCredentialsAlert,
	onOpenStudentCredentials,
	onOpenCredentialsForStudentsInAssignment,
	disabledOpenExportStudentsCredentials,
}: Props) {
	const testsCount = assignment.tests.length;

	const getTableTests = useCallback(() => {
		if (!testsCount) {
			return <EmptyContent messageText={noTestsMessage} />;
		}

		return (
			<TableTests>
				<TableTestsHeader studentsCount={students.length} />

				{assignment.tests.map((test, index) => (
					<TableTestsRowData
						{...test}
						studentsCount={students.length}
						ordered={testsCount > 1}
						moveUpDisabled={!index}
						onMoveUpClick={() => swapTests(index, index - 1)}
						moveDownDisabled={index === testsCount - 1}
						onMoveDownClick={() => swapTests(index, index + 1)}
						key={test.id}
					/>
				))}
			</TableTests>
		);
	}, [testsCount, students, assignment, swapTests]);

	return (
		<>
			<AssignmentHeader
				studentsWithoutAccessCount={studentsWithoutAccess.length}
				hasTestsInAssignment={Boolean(testsCount)}
				onDeleteAssignment={() => onDeleteAssignment({assignmentId: assignment.id, assignmentName: assignment.name})}
				onOpenEditAssignment={() => onOpenEditAssignment(assignment.id)}
				withActionButton
				actionButtonLabel='Create'
				onActionButtonClick={() =>
					onAssignmentCreate({
						assignmentId: assignment.id,
						assignmentName: assignment.name,
					})
				}
				actionButtonDisabled={!testsCount || !students.length || Boolean(studentsWithoutAccess.length)}
				onOpenExportCredentialsAlert={onOpenExportCredentialsAlert}
				disabledOpenExportStudentsCredentials={disabledOpenExportStudentsCredentials}
			/>
			<AssignmentBody
				name={assignment.name}
				state={AssignmentState.Draft}
				description={assignment.description}
				testsCount={testsCount}
				displayedTime={assignment.created}
				displayedTimeType='Created'
				sortStudentsByKey={sortStudentsByKey}
				students={students}
				onOpenStudentCredentials={onOpenStudentCredentials}
				onOpenCredentialsForStudentsInAssignment={onOpenCredentialsForStudentsInAssignment}
			>
				{getTableTests()}
			</AssignmentBody>
		</>
	);
}
