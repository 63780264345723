import {ShapeType} from 'shared/modules/question-editor/models';
import React from 'react';
import './context-menu.less';

export enum ContextMenuAction {
	MoveToFront = 0,
	MoveToBack = 1,
	FlipHorizontal = 2,
	FlipVertical = 3,
	Duplicate = 4
}

export class State {
	opened: boolean = false;
}

export class Props {
	onClick: (action: ContextMenuAction) => void;
	shapeType: ShapeType;
	selectedCells: string[] = [];
}

export class ContextMenu extends React.PureComponent<Props, State> {
	menuRef: HTMLDivElement;

	constructor(props, context) {
		super(props, context);
		this.state = new State();

		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(prevProps: Props): void {
		if (this.props.selectedCells.length !== prevProps.selectedCells.length) {
			this.setState({opened: false});
		}

		if (+this.props.selectedCells[0] !== +prevProps.selectedCells[0]) {
			this.setState({opened: false});
		}
	}

	handleClickOutside(event) {
		if (this.menuRef && !this.menuRef.contains(event.target)) {
			this.setState({opened: false});
		}
	}

	get show() {
		return this.props.shapeType === ShapeType.Image || (this.props.shapeType >= ShapeType.Text && this.props.shapeType < ShapeType.Line);
	}

	toggle() {
		this.setState((prevState) => {
			return {opened: !prevState.opened};
		});
	}

	onClick(action: ContextMenuAction) {
		this.setState({opened: false}, () => this.props.onClick(action));
	}

	render() {
		return <div className='mx-graph-context-menu' ref={(r) => this.menuRef = r}>
			{this.show && <div className='mx-header-dots' onClick={() => this.toggle()}>
				<svg width='16' height='4' viewBox='0 0 16 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2ZM6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2ZM12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0C12.9 0 12 0.9 12 2Z'
						fill='white'/>
				</svg>
			</div>}
			{this.state.opened && < div className='mx-graph-context-menu-list'>
				<div className='mx-graph-context-menu-item'
					 onClick={() => this.onClick(ContextMenuAction.MoveToFront)}>Move to Front
				</div>
				<div className='mx-graph-context-menu-item separator'
					 onClick={() => this.onClick(ContextMenuAction.MoveToBack)}>Move to Back
				</div>
				{this.props.shapeType !== ShapeType.Text && <div className='mx-graph-context-menu-item'
																 onClick={() => this.onClick(ContextMenuAction.FlipHorizontal)}>Flip
					Horizontal</div>}
				{this.props.shapeType !== ShapeType.Text && <div className='mx-graph-context-menu-item separator'
																 onClick={() => this.onClick(ContextMenuAction.FlipVertical)}>Flip
					Vertical</div>}
				<div className='mx-graph-context-menu-item'
					 onClick={() => this.onClick(ContextMenuAction.Duplicate)}>Duplicate
				</div>
			</div>}
		</div>;
	}
}