import React, {ReactNode} from 'react';
import {distinctUntilChanged} from 'rxjs/operators';
import {Loader} from '@esgillc/ui-kit/loader';
import AlreadySavedDialog from './components/dialogs/already-saved-dialog';
import ContinueDialog from './components/dialogs/continue-dialog';
import UserMovedDialog from './components/dialogs/user-moved-dialog';
import SessionResultsDialog from './components/session-results-dialog';
import TestScreen from './components/test-screen/test-screen';
import TestingService from './testing-service';
import {ResultModel, SessionStatus} from './types';
import ShareScreenStartedDialog from './components/dialogs/share-screen-started-dialog';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import {IepGoalFormModal} from 'modules/forms/iep-goal-form';
import {IepProgressReport} from 'modules/reports/iep-progress';

class State {
	loading: boolean = false;
	initialized: boolean = false;
	status: SessionStatus = SessionStatus.None;
	showContinueDialog: boolean = false;
	showResultsDialog: boolean = false;
	showAlreadySavedDialog: boolean = false;
	showShareScreenStartedDialog: boolean = false;
	showUserMovedDialog: boolean = false;
	showIEPEditDialog: boolean = false;
	showIEPReportDialog: boolean = false;
}

interface Props {
	userID: number;
	testID: number;
	classID: number;
	studentID: number;
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClosed: () => void;
	onFinished: () => void;
	onTestHistoryClicked: () => void;
	onTestSessionDetailsClicked: () => void;
}

export default class RubricTesting extends React.PureComponent<Props, State> {
	public state = new State();
	private service: TestingService = new TestingService();

	public componentDidMount() {
		this.service
			.init(this.props.testID, this.props.studentID, this.props.userID)
			.subscribe(shareScreenStarted => {
				if (shareScreenStarted) {
					this.setState({
						initialized: true,
						showShareScreenStartedDialog: true,
					});
				} else {
					this.runTest();
				}
			});

		this.service.sessionStatus.pipe(distinctUntilChanged()).subscribe(s => {
			if (this.state.status !== s) {
				switch (s) {
					case SessionStatus.Started:
						this.setState({showContinueDialog: true});
						break;
					case SessionStatus.Saved:
						this.setState({showAlreadySavedDialog: true});
						break;
					case SessionStatus.UserMoved:
						this.setState({showUserMovedDialog: true});
						break;
					case SessionStatus.Normal:
						break;
				}
			}
		});
	}

	public componentWillUnmount() {
		this.service.destroy();
	}

	public runTest() {
		this.service
			.runTest()
			.subscribe(r => {
				if (r.sessionStatus === SessionStatus.Normal) {
					this.service.startTesting();
				}
				this.setState({initialized: true});
			});
	}

	public render() {
		return (
			<>
				<Loader fullscreen show={!this.state.initialized || this.state.loading}/>
				{this.renderModals()}
			</>
		);
	}

	public renderModals() {
		if (!this.state.initialized) {
			return;
		}

		if (this.state.showContinueDialog) {
			return this.renderContinueDialog();
		}

		if (this.state.showResultsDialog) {
			return this.renderResultsDialog();
		}

		if (this.state.showAlreadySavedDialog) {
			return this.renderAlreadySavedDialog();
		}

		if (this.state.showUserMovedDialog) {
			return this.renderUserMovedDialog();
		}

		if (this.state.showShareScreenStartedDialog) {
			return this.renderShareScreenStartedDialog();
		}

		if (this.state.showIEPEditDialog) {
			return this.renderIEPEditDialog();
		}

		if (this.state.showIEPReportDialog) {
			return this.renderIEPReportDialog();
		}

		return this.renderTestScreen();
	}

	private renderContinueDialog() {
		return <ContinueDialog service={this.service}
		                       onClosed={() => this.setState({showContinueDialog: false})}/>;
	}

	private renderResultsDialog() {
		const resultModel = {
			studentID: this.props.studentID,
			testModel: this.service.testModel,
			studentName: this.service.studentName,
			answers: this.service.answers.value,
			testSessionID: this.service.testSessionID,
			summaryNotes: this.service.summaryNotes.value,
		} as ResultModel;

		return (
			<SessionResultsDialog
				result={resultModel}
				hierarchy={this.props.hierarchy}
				subject={this.props.subject}
				onClosed={() => this.props.onClosed()}
				onExportClickedProp={() => this.export()}
				onTestHistoryClickedProp={() => {
					this.props.onTestHistoryClicked();
					this.setState({showResultsDialog: false});
				}}
				onTestDetailsClickedProp={() => {
					this.props.onTestSessionDetailsClicked();
					this.setState({showResultsDialog: false});
				}}
				onIEPEdit={() => this.setState({
					showResultsDialog: false,
					showIEPEditDialog: true,
				})}
				onIEPReport={() => this.setState({
					showResultsDialog: false,
					showIEPReportDialog: true,
				})}
			/>
		);
	}

	private renderUserMovedDialog(): ReactNode {
		return <UserMovedDialog onClosed={() => this.props.onClosed()}/>;
	}

	private renderShareScreenStartedDialog(): ReactNode {
		return <ShareScreenStartedDialog
			service={this.service}
			onDisconnected={() => this.setState({
				showShareScreenStartedDialog: false,
				initialized: false,
			}, () => this.runTest())}
			onClosed={() => this.props.onClosed()}
		/>;
	}

	private renderAlreadySavedDialog(): ReactNode {
		return <AlreadySavedDialog onClosed={() => this.props.onClosed()}/>;
	}

	private renderTestScreen(): ReactNode {
		return <TestScreen testingService={this.service}
		                   afterTestEnded={() => this.setState({showResultsDialog: true}, () => this.props.onFinished())}
		                   onCancel={() => this.props.onClosed()}/>;
	}

	private renderIEPEditDialog(): ReactNode {
		const {
			subject,
			hierarchy,
			onClosed,
			testID,
		} = this.props;
		return (
			<IepGoalFormModal
				hierarchy={hierarchy}
				subject={subject}
				onClose={onClosed}
				testID={testID}
			/>
		);
	}

	private renderIEPReportDialog(): ReactNode {
		const {
			testID: testId,
			studentID: studentId,
			subject: {id: subjectId},
			hierarchy,
			onClosed,
		} = this.props;
		return (
			<IepProgressReport
				selected={[testId]}
				studentId={studentId}
				subjectId={subjectId}
				hierarchy={hierarchy}
				onClose={onClosed}
			/>
		);
	}

	private export(): void {
		const {id: subjectId, type: subjectType} = this.props.subject;
		this.setState({loading: true});
		const hideLoader = () => this.setState({loading: false});
		this.service.exportSession(subjectId, subjectType, this.props.classID).subscribe({
			next: hideLoader,
			error: hideLoader,
		});
	}
}
