import {HierarchyMode} from '../enums';
import {ClassicHierarchySnapshot} from './classic-hierarchy-snapshot';
import {SpecialistHierarchySnapshot} from './specialist-hierarchy-snapshot';
import {StudentSort} from '@esgi/core/enums';
import {PreAssessHierarchySnapshot} from 'modules/hierarchy/core/models/snapshot/preassess-hierarchy-snapshot';

export class HierarchySnapshot {
	public districtID: number;
	public mode: HierarchyMode;
	public classic: ClassicHierarchySnapshot;
	public specialist: SpecialistHierarchySnapshot;
	public studentSort: StudentSort;
	public preAssess: PreAssessHierarchySnapshot;
}
