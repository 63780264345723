import {Box} from '@esgi/ui/layout';
import {styled} from '@stitches/react';
import {ReportsFiltersContainer} from '../../index.styled';

export const ReportFiltersContainerStyled = styled(ReportsFiltersContainer, {
	flexDirection: 'row',
	paddingBottom: '5px',
});

export const GradeScaleLegendContainer = styled(Box, {
	maxWidth: '220px',
	textAlign: 'left',
});

export const GradeScaleBlock = styled(Box, {
	border: '1px solid $neutral92',
	boxSizing: 'border-box',
	fontSize: '14px',
	fontWeight: 'normal',
	padding: '4px 9px',
	margin: '0 10px 10px 0',
	cursor: 'default',
	display: 'inline-block',
});