import './text-input.less';
import {IFormControlValidatorResult} from './form-controls';
import React, {MouseEventHandler, ReactNode} from 'react';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';

/*
    ESGI-19570.
    State is necessary to avoid cursor jumping.
	Check a React-contributor comment https://github.com/facebook/react/issues/955#issuecomment-469352730
*/
class State {
	value: string = '';
}

class Props {
	withoutContainer?: boolean;
	label?: string;
	htmlLabel?: (id: any) => any;
	touched?: boolean;
	validator?: (value: any) => IFormControlValidatorResult;
	validationResult?: IFormControlValidatorResult;
	validationPlacement?: 'right' | 'top' | 'bottom' | 'left';
	validationMessageOffsetX?: number;
	validationMessageOffsetY?: number;
	name?: string;
	tabIndex?: number;
	type?: string;
	value: string;
	placeholder?: string;
	onKeyUp?: (key: React.KeyboardEvent<HTMLInputElement>) => void;
	onEdit?: (value: any, validation: IFormControlValidatorResult) => any;
	className?: string;
	inputClassName?: string;
	disabled?: boolean;
	actionButton?: () => any;
	focus?: boolean;
	initialFocus?: boolean;
	maxLength?: number;
	autocomplete?: boolean = true;
	hideValidationTooltip?: boolean;
	onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
	children?: ReactNode;
}
/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class TextInput extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {value: props.value || ''};
	}

	ref: HTMLInputElement = null;
	containerRef: HTMLDivElement = null;

	private setRef(c: HTMLInputElement) {
		this.ref = c;
	}

	private getId() {
		let postfix = '';
		if (this.props.label) {
			postfix = this.props.label;
		} else {
			if (this.props.className) {
				postfix = this.props.className;
			}
		}
		return 'text_input_' + postfix.replace(/ /g, '_');
	}

	componentDidMount() {
		if (this.props.initialFocus) {
			$(this.ref).focus();
		}
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		//To prevent setState with value from props when local state changed.
		if(this.props !== prevProps) {
			if(this.state.value !== this.props.value) {
				this.setState({value: this.props.value});
			}

			if (this.props.focus) {
				this.ref.focus();
			}
		}
	}

	renderValidatingControl() {
		const id = this.getId();
		const validationResult = this.props.validationResult ? this.props.validationResult : this.props.validator(this.props.value);

		return <div className={
			this.props.touched && !validationResult.valid
				? 'form-group has-error ' + this.props.className
				: 'form-group ' + this.props.className}
		            ref={(n) => {
			            this.containerRef = n;
		            }}>
			{this.props.label &&
            <label className='top-label' htmlFor={id}>{this.props.label}</label>
			}
			{this.props.htmlLabel && this.props.htmlLabel(id)}
			{this.props.actionButton && this.props.actionButton()}
			<input disabled={this.props.disabled}
			       type={this.props.type ? this.props.type : 'text'}
			       id={id}
			       name={this.props.name}
			       tabIndex={this.props.tabIndex}
			       ref={(n) => this.setRef(n)}
			       className={'form-control' + (this.props.inputClassName ? ' ' + this.props.inputClassName : '')}
			       placeholder={this.props.placeholder}
			       value={this.state.value}
			       maxLength={this.props.maxLength}
			       onKeyUp={(e) => this.props.onKeyUp && this.props.onKeyUp(e)}
			       onChange={(e) => this.handleInput(e, this.props.validationResult ? this.props.validationResult : this.props.validator(e.target.value))}
			       autoComplete={this.props.autocomplete && 'on' || 'off'}
			       onInput={this.props.onInput}
			       onBlur={this.props.onBlur}
			       onPaste={this.props.onPaste}
				   onClick={this.props.onClick}
			/>
			{this.ref && this.props.touched && !this.props.hideValidationTooltip && !validationResult.valid &&
            <ValidationTooltip
                element={this.ref}
                placement={this.props.validationPlacement || 'right'}
                container={this.containerRef}
                offsetX={this.props.validationMessageOffsetX}
                offsetY={this.props.validationMessageOffsetY}
                notHide={true}
            >
				{validationResult.message}
            </ValidationTooltip>}
			{this.props.children}
		</div>;
	}

	handleInput(e, validation: IFormControlValidatorResult) {
		this.setState({value: e.target.value}, () => {
			this.props.onEdit && this.props.onEdit(e.target.value, validation);
		});
	}

	renderFieldCore(id: string) {
		return <input disabled={this.props.disabled}
		              tabIndex={this.props.tabIndex}
		              type={this.props.type ? this.props.type : 'text'}
		              id={id}
		              name={this.props.name}
		              ref={(n) => this.setRef(n)}
		              className={'form-control' + (this.props.inputClassName ? ' ' + this.props.inputClassName : '')}
		              placeholder={this.props.placeholder}
		              value={this.state.value}
					  onBlur={this.props.onBlur}
		              onKeyUp={(e) => this.props.onKeyUp && this.props.onKeyUp(e)}
		              onChange={(e) => this.handleInput(e, {valid: true, message: ''})}
		              autoComplete={this.props.autocomplete && 'on' || 'off'}
		              maxLength={this.props.maxLength}
		/>;
	}

	renderNotValidatingControl() {
		const id = this.getId();
		return <>{!this.props.withoutContainer ? <div className={'form-group ' + this.props.className} ref={n => this.containerRef = n}>
			{this.props.label &&
            <label className='top-label' htmlFor={id}>{this.props.label}</label>
			}
			{this.props.htmlLabel && this.props.htmlLabel(id)}
			{this.props.actionButton && this.props.actionButton()}
			{this.renderFieldCore(id)}
			{this.props.children}
		</div> : this.renderFieldCore(id) }</>;
	}

	render() {
		return <>
			{(this.props.validator || this.props.validationResult) ? this.renderValidatingControl() : this.renderNotValidatingControl()}
		</>;
	}
}
