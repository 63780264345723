import {useSubjectSectionContext} from '../../context/subject-section-context';
import {ListsWrapper} from './index.styled';
import {useCallback, useEffect, useState} from 'react';
import {ExpandablePanelModeVariant, ExpandablePanelModeVariantProps} from '../../../../kit';
import {SelectableListEditable} from '../lists/selectable-list-editable';
import {SelectableListRearrangeable} from '../lists/selectable-list-rearrangeable';
import {SelectableList} from '../lists/selectable-list';
import {SubjectSectionMode} from '../../types';
import {SubjectTab} from '@esgi/main/libs/store';

export type AllTabListVariant = {
	canEdit: boolean;
	subjects: SubjectTab[];
} & (
	| {
			canRearrange: false;
	  }
	| {
			canRearrange: true;
			updateSubjectsOrder: (subjects: SubjectTab[]) => void;
	  }
);

type Props = {
	lists: AllTabListVariant[];
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: (subjectId: SubjectTab['id']) => void;
	onSubjectEdit: (subjectId: SubjectTab['id']) => void;
};

export function AllTabContent({lists, selectedSubjectId, setSelectedSubjectId, onSubjectEdit}: Props) {
	const {sectionMode, setSectionViewMode} = useSubjectSectionContext();

	const [controlledLists, setControlledLists] = useState(lists);

	useEffect(() => {
		setControlledLists(lists);
	}, [lists]);

	const updateControlledListByIndex = useCallback((groupState: SubjectTab[], listIndex: number) => {
		setControlledLists((currentControlledLists) =>
			currentControlledLists.map((list, index) => (index !== listIndex ? list : {...list, subjects: groupState})),
		);
	}, []);

	const handleDoneData = useCallback(() => {
		setSectionViewMode();
	}, [setSectionViewMode]);

	const handleSaveClick = useCallback(() => {
		controlledLists.forEach((list) => {
			if (list.canRearrange) {
				list.updateSubjectsOrder(list.subjects);
			}
		});

		setSectionViewMode();
	}, [setSectionViewMode, controlledLists]);

	const getExpandablePanelModeVariantProps = useCallback<() => ExpandablePanelModeVariantProps>(() => {
		if (sectionMode === SubjectSectionMode.Edit) {
			return {
				withBottomSectionButton: true,
				bottomSectionButtonWithIcon: true,
				onBottomButtonClick: handleDoneData,
				buttonText: 'Done',
			};
		}

		if (sectionMode === SubjectSectionMode.Rearrange) {
			return {
				withBottomSectionButton: true,
				bottomSectionButtonWithIcon: true,
				onBottomButtonClick: handleSaveClick,
				buttonText: 'Save',
			};
		}

		return {
			withBottomSectionButton: false,
		};
	}, [handleDoneData, handleSaveClick, sectionMode]);

	return (
		<ExpandablePanelModeVariant {...getExpandablePanelModeVariantProps()}>
			<ListsWrapper>
				{(sectionMode === SubjectSectionMode.Rearrange ? controlledLists : lists).map(
					({canEdit, canRearrange, subjects}, index) => {
						if (!subjects.length) {
							return null;
						}

						if (sectionMode === SubjectSectionMode.Edit && canEdit) {
							return (
								<SelectableListEditable
									onSubjectEdit={onSubjectEdit}
									subjects={subjects}
									selectedSubjectId={selectedSubjectId}
									key={index}
								/>
							);
						}

						if (sectionMode === SubjectSectionMode.Rearrange && canRearrange) {
							return (
								<SelectableListRearrangeable
									onDragEnd={(groupState) => updateControlledListByIndex(groupState, index)}
									subjects={subjects}
									selectedSubjectId={selectedSubjectId}
									key={index}
								/>
							);
						}

						return (
							<SelectableList
								selectedSubjectId={selectedSubjectId}
								setSelectedSubjectId={setSelectedSubjectId}
								subjects={subjects}
								key={index}
							/>
						);
					},
				)}
			</ListsWrapper>
		</ExpandablePanelModeVariant>
	);
}
