import {CardViewType} from '../../../../../types';
import {GroupStudentsRerrangeModeContent} from '../types';
import {TestCard} from '../../../../test-cards';
import {TestGroupStudentsModel} from '../../../../../../../types';
import {Ref, useImperativeHandle, useState} from 'react';
import {GridCardsRearrangeContainer} from './grid-cards-rearrange-container';

export type GroupStudentsCardsManager = {
	getItems: () => TestGroupStudentsModel[];
};

type Props = {
	cardViewType: CardViewType;
	cardsRef: Ref<GroupStudentsCardsManager | undefined>;
} & Pick<GroupStudentsRerrangeModeContent, 'allTests'>;

export function GroupStudentsCards({cardViewType, allTests, cardsRef}: Props) {
	const [controlledItems, setControlledItems] = useState(allTests);

	useImperativeHandle(cardsRef, () => ({
		getItems: () => controlledItems,
	}));

	if (cardViewType === CardViewType.List) {
		return (
			<TestCard.ListCard.CardGroupDroppable droppableItems={controlledItems} onDragEnd={setControlledItems}>
				{controlledItems.map(({testInfo: {id, name, type}}, index) => (
					<TestCard.ListCard.DraggableCard
						draggableId={String(id)}
						index={index}
						testID={id}
						name={name}
						type={type}
						key={id}
					/>
				))}
			</TestCard.ListCard.CardGroupDroppable>
		);
	}

	if (cardViewType === CardViewType.Grid) {
		return (
			<GridCardsRearrangeContainer
				items={controlledItems}
				onDragEnd={setControlledItems}
				renderItem={({testInfo, classResult, studentsResult}) => (
					<TestCard.GridCard.GroupStudentsCard.Draggable
						testInfo={testInfo}
						classResult={classResult}
						studentsResult={studentsResult}
					/>
				)}
			/>
		);
	}

	return null;
}
