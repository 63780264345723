import {useCallback, useMemo, useState} from 'react';
import {Drawer} from '@esgi/main/kits/common';
import {Input} from '@esgi/ui/form-controls';
import {useService} from '@esgi/core/service';
import {ElementStatus, Form, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {TestResultsVerbiageService} from './service';
import {SettingsTab, TestResultsVerbiage} from '../../../../../types';
import {descriptions} from '../../../../../constants';
import {SingleCheckboxSection} from '../../../../single-checkbox-section';
import {Text} from '@esgi/ui/typography';
import {FormElementWrapper} from '../../../../../index.styled';
import {ContentWrapper, PanelContent} from '../../../index.styled';
import {Container, TestButtonLabelTag} from './index.styled';
import {useStreamEffect} from '@esgi/ui';
import {userStorage} from '@esgi/core/authentication';
import {correctVerbiageMaxLength, incorrectVerbiageMaxLength, validations} from './constants';
import {ErrorTooltips} from './components/error-tooltips';
import {of, switchMap} from 'rxjs';
import {ValidationStatus} from './types';
import {isEqual} from 'underscore';

type Props = TestResultsVerbiage;

export function TestResultsVerbiage({
	testResultsVerbiagesEnabled,
	testResultsCorrectVerbiage,
	testResultsIncorrectVerbiage,
	onUpdateInitData,
	onUpdateTabTouchedData,
	onCloseDrawer,
}: Props) {
	const [validationStatus, setValidationStatus] = useState<ValidationStatus>({
		verbiagesEnabled: true,
		correctVerbiage: true,
		incorrectVerbiage: true,
	});
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isFormSaving, setIsFormSaving] = useState(false);

	const service = useService(TestResultsVerbiageService);

	const {initVerbiagesEnabled, initCorrectVerbiage, initIncorrectVerbiage} = useMemo(
		() => ({
			initVerbiagesEnabled: testResultsVerbiagesEnabled,
			initCorrectVerbiage: testResultsCorrectVerbiage ?? '',
			initIncorrectVerbiage: testResultsIncorrectVerbiage ?? '',
		}),
		[testResultsCorrectVerbiage, testResultsIncorrectVerbiage, testResultsVerbiagesEnabled],
	);

	const handleUpdateValidationStatus = useCallback((data: Partial<ValidationStatus>) => {
		setValidationStatus((currentState) => ({...currentState, ...data}));
	}, []);

	const form = useForm(
		() =>
			new FormGroup({
				verbiagesEnabled: new FormControl(initVerbiagesEnabled),

				correctVerbiage: new FormControl(initCorrectVerbiage ?? '', {
					validators: [Validators.required(), Validators.length.max(correctVerbiageMaxLength)],
					initialValidateStatus: initVerbiagesEnabled ? ElementStatus.untouched : ElementStatus.disabled,
				}),

				incorrectVerbiage: new FormControl(initIncorrectVerbiage ?? '', {
					validators: [Validators.required(), Validators.length.max(incorrectVerbiageMaxLength)],
					initialValidateStatus: initVerbiagesEnabled ? ElementStatus.untouched : ElementStatus.disabled,
				}),
			}),
	);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {verbiagesEnabled, correctVerbiage, incorrectVerbiage},
			},
		}) => {
			if (verbiagesEnabled) {
				setIsFormTouched(
					!initVerbiagesEnabled ||
						correctVerbiage !== initCorrectVerbiage ||
						incorrectVerbiage !== initIncorrectVerbiage,
				);
			}

			onUpdateTabTouchedData(SettingsTab.TestResultVerbiage, compareStatesForEquality());
		},
	);

	useStreamEffect(form.controls.verbiagesEnabled.onChanged, ({currState: {value}}) => {
		if (!value) {
			form.controls.correctVerbiage.status = ElementStatus.disabled;
			form.controls.incorrectVerbiage.status = ElementStatus.disabled;

			setIsFormTouched(value !== initVerbiagesEnabled);
			handleUpdateValidationStatus({
				verbiagesEnabled: true,
				correctVerbiage: true,
				incorrectVerbiage: true,
			});

			return;
		}

		form.controls.correctVerbiage.status = ElementStatus.untouched;
		form.controls.incorrectVerbiage.status = ElementStatus.untouched;

		form.validate().subscribe();
	});

	useStreamEffect(form.controls.correctVerbiage.onChanged, ({reason, currState}) => {
		handleUpdateValidationStatus({
			correctVerbiage: reason === 'status' && currState.status !== ElementStatus.invalid,
		});
	});

	useStreamEffect(form.controls.incorrectVerbiage.onChanged, ({reason, currState}) => {
		handleUpdateValidationStatus({
			incorrectVerbiage: reason === 'status' && currState.status !== ElementStatus.invalid,
		});
	});

	const handleSaveWithoutValidation = useCallback(() => {
		service
			.save({
				correct: null,
				incorrect: null,
				state: false,
			})
			.subscribe(() => {
				userStorage.update({
					testResultsCorrectVerbiage: 'Correct',
					testResultsIncorrectVerbiage: 'Incorrect',
				});

				onUpdateInitData({
					testResultsVerbiagesEnabled: false,
					testResultsCorrectVerbiage: null,
					testResultsIncorrectVerbiage: null,
				});

				setIsFormSaving(false);
				setIsFormTouched(false);
			});
	}, [onUpdateInitData, service]);

	const handleSaveWithValidation = useCallback(() => {
		const {correctVerbiage, incorrectVerbiage} = form.value;

		form
			.validate()
			.pipe(
				switchMap(({valid}) => {
					if (valid) {
						return service.save({
							correct: correctVerbiage,
							incorrect: incorrectVerbiage,
							state: true,
						});
					}

					return of(false);
				}),
			)
			.subscribe((value) => {
				if (typeof value === 'boolean' && !value) {
					setIsFormSaving(false);

					return;
				}

				userStorage.update({
					testResultsCorrectVerbiage: correctVerbiage,
					testResultsIncorrectVerbiage: incorrectVerbiage,
				});

				onUpdateInitData({
					testResultsVerbiagesEnabled: true,
					testResultsCorrectVerbiage: correctVerbiage,
					testResultsIncorrectVerbiage: incorrectVerbiage,
				});

				setIsFormSaving(false);
				setIsFormTouched(false);
			});
	}, [form, onUpdateInitData, service]);

	const onSave = useCallback(() => {
		setIsFormSaving(true);

		const {verbiagesEnabled} = form.value;

		onUpdateTabTouchedData(SettingsTab.TestResultVerbiage, false);
		verbiagesEnabled ? handleSaveWithValidation() : handleSaveWithoutValidation();
	}, [form.value, handleSaveWithValidation, handleSaveWithoutValidation]);

	const isFormValid = Object.values(validationStatus).every(Boolean);

	const compareStatesForEquality = () => {

		const initialState = {
			verbiagesEnabled: testResultsVerbiagesEnabled,
			correctVerbiage: testResultsCorrectVerbiage,
			incorrectVerbiage: testResultsIncorrectVerbiage,
		};

		const {verbiagesEnabled, correctVerbiage, incorrectVerbiage} = form.value;

		const currentState = {verbiagesEnabled, correctVerbiage, incorrectVerbiage};

		return !isEqual(initialState, currentState);
	};

	return (
		<Form data-cy='test-results-verbiage-root' controller={form}>
			<Drawer.PanelHeader
				data-cy='test-results-verbiage-header'
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={onSave}
				actionButtonDisabled={!isFormTouched || !isFormValid || isFormSaving}
				onClose={onCloseDrawer}
			>
				<Text data-cy='test-results-verbiage-label' size='large' color='neutral24' bold>
					Test Results Verbiage
				</Text>
			</Drawer.PanelHeader>

			<PanelContent data-cy='test-results-verbiage-content'>
				<ContentWrapper>
					<Drawer.ContentBlock title='Test Results Alternative Verbiage' withDivider>
						<SingleCheckboxSection
							checkboxControl={form.controls.verbiagesEnabled}
							isDisabled={!form.controls.verbiagesEnabled}
							description={descriptions.testResultVerbiage}
							dataCy='alternative-verbiage-checkbox'
						/>

						<Container>
							<TestButtonLabelTag data-cy='verbiage-correct-tag' size='large'>Correct</TestButtonLabelTag>

							<FormElementWrapper control={form.controls.correctVerbiage}>
								<Input.Base data-cy='verbiage-correct-input' placeholder='Correct' />

								<ErrorTooltips validations={validations.correctVerbiage} />
							</FormElementWrapper>
						</Container>

						<Container>
							<TestButtonLabelTag data-cy='verbiage-incorrect-tag' size='large'>Incorrect</TestButtonLabelTag>
							<FormElementWrapper control={form.controls.incorrectVerbiage}>
								<Input.Base data-cy='verbiage-incorrect-input' placeholder='Incorrect' />

								<ErrorTooltips validations={validations.incorrectVerbiage} />
							</FormElementWrapper>
						</Container>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}
