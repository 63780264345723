import {useEffect, useState} from 'react';
import {SubjectItem} from '../../services/subjects-service';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {useService} from '@esgi/core/service';
import {AddTestToSubjectService} from 'pages/test-explorer/components/add-tests-to-subject/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import SubjectDetails from 'modules/subject-details';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './styles.module.less';

interface Props {
	testId: number;
	testName: string;
	close: () => void;
	mySubjects: SubjectItem[];
}

export function AddTestToSubject({testName, mySubjects, close, testId}: Props) {
	const modalManagerRef = useModal();
	const handleClose = useCloseModal(modalManagerRef, close);

	const service = useService(AddTestToSubjectService);
	const subjects = useBehaviorSubject(service.subjects);
	const selectedSubjectsIds = useBehaviorSubject(service.selectedSubjectsIds);
	const [isSubjectCreatorOpened, setSubjectCreatorOpened] = useState(false);
	const hasTestInSubject = ({tests}) =>
		tests.findIndex((t) => t.testID === testId) !== -1;

	const onSubjectDetailsSave = (subjectId, name, type) => {
		service.subjectCreated({subjectId, name, type});
		setSubjectCreatorOpened(false);
	};

	const handleSave = () => {
		if (selectedSubjectsIds[0]) {
			service.save().then(() => {
				const selectedSubject = subjects.find(({id}) => id === selectedSubjectsIds[0]);
				handleClose();
				showSnackbarNotification(`You've added ${testName} to ${selectedSubject.name}`);
			});
		}
	};

	const renderOption = (subject) => {
		if (hasTestInSubject(subject)) {
			return (
				<OnHoverTooltip
					key={subject.id}
					message='This test has already been added to the subject tab.'
				>
					<Option
						key={subject.id}
						value={subject.id}
						className={styles.disabled}
					>
						{subject.name}
					</Option>
				</OnHoverTooltip>
			);
		}
		return (
			<Option
				key={subject.id}
				value={subject.id}
			>
				{subject.name}
			</Option>
		);
	};

	useEffect(() => {
		service.init({subjects: mySubjects, testId});
	}, []);

	return <Modal modalManagerRef={modalManagerRef}>
		<Modal.Header>
			<Title className={styles.title}>
				Add {testName} to subject tab:
			</Title>
		</Modal.Header>
		<Modal.Body>
			<div className={styles.body} data-cy={'modal-body'}>
				<Dropdown
					setValue={(value) => {
						const subject = subjects.find(({id}) => id === value[0]);
						if (hasTestInSubject(subject)) {
							return;
						}
						service.changeSelectedSubject(value);
					}}
					className={styles.dropdown}
					value={selectedSubjectsIds}
					displayLabel={(selected) => subjects.find(({id}) => id === selected[0]).name}
					placeholder='Select a subject tab'
				>
					{subjects.map(renderOption)}
				</Dropdown>
				<Buttons.Link onClick={() => setSubjectCreatorOpened(true)}
				              className={styles.createButton}>
					Create new subject tab
				</Buttons.Link>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>
				Cancel
			</Buttons.Text>
			<Buttons.Contained onClick={handleSave} disabled={!selectedSubjectsIds[0]}>
				Save
			</Buttons.Contained>
		</Modal.Footer>
		{isSubjectCreatorOpened &&
			<div data-cy={'subject-details'}>
				<SubjectDetails onClosed={() => setSubjectCreatorOpened(false)} onSaved={onSubjectDetailsSave}/>
			</div>
		}
	</Modal>;
}
