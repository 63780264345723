import React, {ComponentPropsWithoutRef, forwardRef, PropsWithChildren} from 'react';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {StyledAvatarHoverLayer} from './styled';

type AvatarHoverLayerProps =
	PropsWithChildren
	& ComponentPropsWithoutRef<'div'>
	& BaseComponentProps
	& Skeletonable;

export const AvatarHoverLayer = forwardRef<HTMLDivElement, AvatarHoverLayerProps>(({
	dataCy = 'ui-kit-avatar-hover-layer',
	children,
	...props
}: AvatarHoverLayerProps, ref) => {
	return <StyledAvatarHoverLayer
		data-cy={dataCy}
		ref={ref}
		{...props}>
		{children}
	</StyledAvatarHoverLayer>;
});
