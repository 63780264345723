import {GradeRange, TestSession} from './types';
import {AnswerState} from '../../../types';

export const sortedSessions = (sessions: TestSession[])=>{
	const sortedData = sessions.concat([]).sort((a, b) => new Date(a.testDate).getTime() - new Date(b.testDate).getTime());
	return {
		firstSession: sortedData[0],
		lastSession: sortedData[sortedData.length-1],
		sessionsData: sortedData,
	};
};

export const determineGradeTemplate = (score: number, gradeRanges: GradeRange[]): GradeRange =>
	gradeRanges.find((grade) => score >= grade.from && score <= grade.to);

export const calculateCorrectAnswers = (session: TestSession) => {
	return session.answers.length
		? session.answers.reduce((currentSum, {answerState}) => {
			if (answerState === AnswerState.Correct) {
				return currentSum + 1;
			}

			return currentSum;
		}, 0)
		: session.correctAnswers;
};

export const calculateLastYNTestScores = (sessions: TestSession[], totalPossible: number) => {
	if (!sessions.length) {
		return null;
	}
	const {lastSession, sessionsData} = sortedSessions(sessions);
	const correctAnswers = calculateCorrectAnswers(lastSession);
	const progress = correctAnswers / totalPossible;
	return {
		correctAnswersCount: correctAnswers,
		testDate: lastSession?.testDate,
		isProgressSuccess: Boolean(progress > 0.5),
		progress: Math.round(progress * 100),
		delta: determineLastSessionProgressDelta(sessionsData, totalPossible),
	};
};

export const calculateLastRubricTestScores = (
	sessions: TestSession[],
	rubricCriteriaCount: number,
	totalPossible: number,
) => {
	const {lastSession, sessionsData} = sortedSessions(sessions);
	let penultimateSessionProgress = 0;

	const rubricAnswersScore = lastSession?.rubricAnswers.reduce(
		(accumulatedScores, answer) => accumulatedScores + answer.score,
		0,
	);
	if (sessionsData.length > 1) {
		penultimateSessionProgress = sessionsData[sessionsData.length-2].correctAnswers;
	}

	const lastSessionProgress = rubricAnswersScore - totalPossible;

	return {
		correctAnswersCount: rubricAnswersScore,
		testDate: lastSession?.testDate,
		isProgressSuccess: Boolean(lastSessionProgress > totalPossible / 2),
		progress: lastSession?.correctAnswers,
		delta: sessionsData?.length >= 1 ? (lastSession?.correctAnswers- penultimateSessionProgress) : null,
	};
};

const determineLastSessionProgressDelta = (sessions: TestSession[], totalPossible: number): number => {
	if (sessions.length > 1) {
		const lastSessionProgress = calculateCorrectAnswers(sessions[sessions.length-1]) / totalPossible;
		const penultimateSessionProgress = calculateCorrectAnswers(sessions[sessions.length-2]) / totalPossible;
		return Math.round((lastSessionProgress - penultimateSessionProgress) * 100);
	}
	return null;
};
