import './styles.less';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import React from 'react';
import {RoutesMap} from '@esgi/main/libs/core';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {RemoveClickedEvent, SaveAndAddClickedEvent, SaveClickedEvent} from './events';
import AppHistory from '../../../tools/history/history';
import {SpecialistGroupForm} from './specialist-group-from';
import {UserType} from '@esgi/core/authentication';
import {EventBusDispatcher} from '@esgillc/events';

class State{
	saveAndClicked: boolean = false;
}

class Props{
	groupID: number;
	groupName: string;
	userID: number;
	userType: UserType;
	numberOfGroups: number;
	onClose: () => void;
}

export default class SpecialistGroupFormModal extends React.Component<Props, State>{
	private get editMode(): boolean {
		return !!this.props.groupID;
	}

	private get title(): string {
		if (this.props.userType === UserType.PA){
			return this.props.groupID ? 'Edit Group:' : 'Add New Group:';
		}
		return this.props.groupID ? 'Edit Specialist Group:' : 'Add New Specialist Group:';
	}

	constructor(props: Props) {
		super(props);
		this.state= new State();
	}

	public render() {
		return <Modal animate={true} className='specialist-group-edit-modal white-header pull-right responsive' onCatchError={() => this.props.onClose()}>
			<ModalHeader title={this.title}>
				{this.editMode && <div className='remove-link' onClick={(e) => {
					console.debug(e);
					EventBusDispatcher.dispatch(RemoveClickedEvent);
				}}>Remove group</div>}
			</ModalHeader>
			<ModalBody>
				<SpecialistGroupForm groupID={this.props.groupID}
				                     groupName={this.props.groupName}
				                     userID={this.props.userID}
				                     numberOfGroups={this.props.numberOfGroups}
									 onSaved={() => {
									 	if(this.state.saveAndClicked) {
										    AppHistory.navigate(RoutesMap.studentManager);
									    } else {
										    this.props.onClose();
									    }
									 }}/>
			</ModalBody>
			<ModalFooter>
				<div className='bottom-buttons'>
					<div className='left-buttons'>
						<Button className='btn-close cancel-button'
						        title='Cancel'
						        onClick={() => this.props.onClose()}/>
					</div>
					<div className='right-buttons'>
						<Transparent className='btn-thumbnail-view save-and-add-button'
						             title='SAVE AND ADD STUDENTS'
						             onClick={() => {
							             this.setState(
								             {saveAndClicked: true},
								             () => EventBusDispatcher.dispatch(SaveAndAddClickedEvent));
						             }}/>
						<Primary className='save-button'
						         title='Save'
						         onClick={() => EventBusDispatcher.dispatch(SaveClickedEvent)}/>
					</div>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
