import {useSightWordsService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgi/ui';
import {AlertBody, ReportWrapper, Page, Header, HeaderText, Student, FullNameText, TitleText, Content, Row, Cell} from './index.styled';
import {UnitType, FontFamily} from '../../../../types';
import {fontFamilyMap} from './constants';

export function Body() {
	const service = useSightWordsService();
	const report = useBehaviorSubject(service.report$);

	return (
		<AlertBody>
			<ReportWrapper>
				{report.students.map((student) => (
					<Page key={`${student.fullName}`}>
						<Header dataCy='header'>
							<HeaderText data-cy='writting-title' size='medium' color='base' bold>Writing Practice:</HeaderText>
							<HeaderText data-cy='date'>{new Date().toLocaleDateString('en-US')}</HeaderText>
							{student.unitType !== UnitType.None &&
								<>
									<HeaderText data-cy='teacher-spec-name-title' size='medium' color='base' bold>{student.unitType === UnitType.Class ? 'Teacher:' : 'Specialist:'}</HeaderText>
									<HeaderText data-cy='teacher-spec-name'>{report.lecturerName}</HeaderText>
									<HeaderText data-cy='class-group-name-title' size='medium' color='base' bold>{student.unitType === UnitType.Class ? 'Class:' : 'Group:'}</HeaderText>
									<HeaderText data-cy='class-group-name'>{student.unitName}</HeaderText>
								</>
							}
						</Header>
						<Student>
							<FullNameText data-cy='student-fullname'>{student.fullName}</FullNameText>
							<TitleText data-cy='sight-words-title'>{student.unitName} Sight Words</TitleText>
						</Student>
						<Content style={{fontSize: report.fontSize}} dataCy='content'>
							{report.tests.map(({words, columns}) => words.map(({word}) => (
								<Row key={`${student.fullName}-${word}`} dataCy='row'>
									{columns.map(({fontFamily}) =>
										<Cell
											key={`${student.fullName}-${word}-${fontFamily}`}
											type={fontFamilyMap[fontFamily]}
										>
											{fontFamily === FontFamily.Blank ? '' : word}
										</Cell>
									)}
								</Row>
							)))}
						</Content>
					</Page>
				))}
			</ReportWrapper>
		</AlertBody>
	);
}
