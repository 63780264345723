import {displayCellValue} from 'modules/reports/growth-report/utils';
import {GrowthReportService} from 'modules/reports/growth-report/services/growth-report-service';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import styles from './table-body.module.less';
import {useMemo} from 'react';
import {HierarchyMode} from 'modules/hierarchy/core/models';

interface TableBodyProps {
	growthReportService: GrowthReportService;
}

export default function TableBody(props: TableBodyProps) {
	const {growthReportService} = props;
	const showNumberOfStudents = useBehaviorSubject(growthReportService.showNumberOfStudents);
	const showMarkingPeriods = useBehaviorSubject(growthReportService.showMarkingPeriods);
	const sortModel = useBehaviorSubject(props.growthReportService.sortModel);
	const selectedTrack = useBehaviorSubject(props.growthReportService.selectedTrack);
	const validNumberPeriods = growthReportService.validNumberPeriods;
	const isPreAssessMode = growthReportService.isPreAssessMode;
	const tableRows = useMemo(() => {
		return growthReportService.tableRows;
	}, [sortModel, selectedTrack]);

	const resultCellStyle = (item) => {
		let style = ' ';
		if (item == null) {
			style += styles.naResult;
		}
		if (item > 0) {
			style += styles.prosResult;
		}
		if (item < 0) {
			style += styles.consResult;
		}
		return style;
	}

	return (
		<tbody id='growth-report-table-body'>
		{
			tableRows?.map((row, index) => <tr key={`growth-report-row-${index}`}>
				{!isPreAssessMode &&
					<td className={join(styles.leftAlign, styles.nowrapText, styles.infoCell)}>{row.schoolName}</td>
				}
				<td className={join(styles.leftAlign, styles.nowrapText)}>{join(row.firstName, row.lastName)}</td>
				{showNumberOfStudents && <td className={styles.infoCell}>{row.numberOfStudents}</td>}
				<td className={join(styles.avgCell, styles.grayCell, resultCellStyle(row.averageOfGrowthScores))}>
					<span className={styles.biggerText}>{row.averageOfGrowthScores}%</span>({row.testedAverageOfGrowthScores}% tested)
				</td>
				{row?.results.map((results, resultsIndex) => <>
					{showNumberOfStudents ? <>
							<td key={`number-students-cell-${resultsIndex}`} className={join(styles.infoCell, styles.leftDarkerBorder)}>{results.numberOfTestedStudents}</td>
							<td key={`first-percent-cell-${resultsIndex}`} className={styles.grayCell}>{displayCellValue(results.firstPercent, false)}</td>
						</>
						: <td key={`first-percent-cell-no-students-${resultsIndex}`} className={join(styles.grayCell, styles.leftDarkerBorder)}>{displayCellValue(results.firstPercent, false)}</td>}
					{showMarkingPeriods && results.periodsPercent.slice(0, validNumberPeriods).map((q, periodIndex) => <td key={`period_percent_${index}_${periodIndex}`}>
						{displayCellValue(q, false)}
					</td>)}
					<td key={`growth-percent-cell-${resultsIndex}`} className={join(styles.growthCell, styles.grayCell, resultCellStyle(results.growthPercent))}>{displayCellValue(results.growthPercent, true)}</td>
				</>)}
			</tr>)
		}
		</tbody>
	)
}
