import {useCallback, useMemo} from 'react';
import {DrawerMode, useSwitchDrawerMode} from '@esgi/main/features/teacher/home';
import {TeacherPageContextValue} from './types';
import {Snackbar} from '@esgi/ui/snackbar';
import {
	AddGroupFormParams,
	AddStudentViaFileParams,
	EditClassFormParams,
	EditGroupFormParams,
	EditStudentViaProfileParams,
	TeacherDrawerName,
} from '@esgi/main/features/teacher/drawers';
import {isNull} from 'underscore';

export function useTeacherPageContextValue() {
	const snackbarRef = Snackbar.useRef();

	const switchClassForm = useSwitchDrawerMode({drawerName: TeacherDrawerName.TeacherClass});
	const switchGroupForm = useSwitchDrawerMode({drawerName: TeacherDrawerName.TeacherGroup});
	const switchStudentViaFileForm = useSwitchDrawerMode({drawerName: TeacherDrawerName.TeacherStudentViaFile});
	const switchStudentViaProfile = useSwitchDrawerMode({drawerName: TeacherDrawerName.TeacherStudentViaProfile});

	const openAddClassForm = useCallback(() => {
		switchClassForm({mode: DrawerMode.Create});
	}, [switchClassForm]);

	const openEditClassForm = useCallback(
		(search: EditClassFormParams) => {
			switchClassForm({mode: DrawerMode.Edit, search});
		},
		[switchClassForm],
	);

	const openAddGroupForm = useCallback(
		({classId}: AddGroupFormParams) => {
			switchGroupForm({
				mode: DrawerMode.Create,
				search: {
					classId: isNull(classId) || classId === -1 ? undefined : String(classId),
				},
			});
		},
		[switchGroupForm],
	);

	const openEditGroupForm = useCallback(
		(search: EditGroupFormParams) => {
			switchGroupForm({mode: DrawerMode.Edit, search});
		},
		[switchGroupForm],
	);

	const openAddStudentViaFileForm = useCallback(
		({classId}: AddStudentViaFileParams) => {
			switchStudentViaFileForm<AddStudentViaFileParams>({mode: DrawerMode.Create, search: {classId}});
		},
		[switchStudentViaFileForm],
	);

	const openAddStudentViaProfile = useCallback(
		({classId}: AddStudentViaFileParams) => {
			switchStudentViaProfile<AddStudentViaFileParams>({mode: DrawerMode.Create, search: classId ? {classId} : null});
		},
		[switchStudentViaProfile],
	);

	const openEditStudentViaProfile = useCallback(
		({studentId}: EditStudentViaProfileParams) => {
			switchStudentViaProfile<EditStudentViaProfileParams>({mode: DrawerMode.Edit, search: {studentId}});
		},
		[switchStudentViaProfile],
	);

	return useMemo<TeacherPageContextValue>(
		() => ({
			openAddClassForm,
			openEditClassForm,
			openAddGroupForm,
			openEditGroupForm,
			openAddStudentViaFileForm,
			openAddStudentViaProfile,
			openEditStudentViaProfile,
			teacherSnackbarRef: snackbarRef,
		}),
		[
			openAddClassForm,
			openAddGroupForm,
			openEditClassForm,
			openEditGroupForm,
			openAddStudentViaFileForm,
			openAddStudentViaProfile,
			openEditStudentViaProfile,
			snackbarRef,
		],
	);
}
