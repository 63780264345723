import React from 'react';
import ImageCard from 'shared/modules/reports/flash-cards/results-form/components/card/components/image/component';
import {Models} from 'shared/modules/reports/flash-cards/results-form/models';

class Props {
    cardsRow: Models.CardsRow;
}

export default class Card extends React.PureComponent<Props> {
	constructor(props?: Props) {
		super(props);
	}

	render() {
		return <div className='card-row'>
            <div>
                {this.props.cardsRow.cards.map((card, index) =>
                    <div key={index}
                        className={!card.isEmpty ? 'card-header' : ''}>
                        <div className='card-student-name'>
                            <div>
                                <span>{card.studentName}</span>
                            </div>
                            <div>
                                <span>{card.reportDate}</span>
                            </div>
                        </div>
                        <div>
                            <strong>{card.testName}</strong>
                        </div>
                    </div>)}
            </div>
            <div>
                {this.props.cardsRow.cards.map((card, index) =>
                    <ImageCard
                        key={index}
                        card={card}
                    />)}
            </div>
        </div>;
	}
}