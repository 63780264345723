/* eslint-disable @typescript-eslint/member-ordering */
import {userStorage} from '@esgi/core/authentication';
import {StudentCredentialsPageLocalStorage, StudentCredentialsPageLocalStorageUserInfo} from '../types/types';
import {BehaviorSubject} from 'rxjs';

export class LocalStorageService {
	private readonly studentCredentialsPage = 'studentCredentialsPage';
	private readonly currentUserId = userStorage.get().userID;

	public get isBannedModal() {
		return this.getValueByKey('isBannedModal') ?? false;
	}

	public get isPasswordsHidden() {
		return this.getValueByKey('isHiddenPasswords') ?? true;
	}

	public isPasswordsHidden$ = new BehaviorSubject(this.isPasswordsHidden);

	public setBanModal(value: boolean) {
		this.setValueByKey('isBannedModal', value);
	}

	public setIsPasswordsHidden(value: boolean) {
		this.setValueByKey('isHiddenPasswords', value);
		this.isPasswordsHidden$.next(value)
	}

	private getValue(): StudentCredentialsPageLocalStorage | null {
		const item = localStorage.getItem(this.studentCredentialsPage);

		if (item) {
			return JSON.parse(item) as StudentCredentialsPageLocalStorage;
		}

		return null;
	}

	private getValueByKey<K extends keyof StudentCredentialsPageLocalStorageUserInfo>(
		key: K,
	): StudentCredentialsPageLocalStorageUserInfo[K] | null {
		const item = this.getValue();
		const userInfo = item?.[this.currentUserId];

		if (userInfo) {
			return userInfo[key];
		}

		return null;
	}

	private setValueByKey<K extends keyof StudentCredentialsPageLocalStorageUserInfo>(key: K, value: StudentCredentialsPageLocalStorageUserInfo[K]) {
		const currentValue = this.getValue();
		const currentUserValue = currentValue?.[this.currentUserId];

		localStorage.setItem(this.studentCredentialsPage, JSON.stringify({
			...currentValue,
			[this.currentUserId]: {
				...currentUserValue,
				[key]: value,
			},
		}));
	}
}
