import React from 'react';
import {Modal, ModalProps} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import styles from './report-modal.module.less';

export class ReportModal extends React.PureComponent<ModalProps> {
	public render() {
		return <Modal className={join(styles.modal, this.props.className)} {...this.props}>
			{this.props.children}
		</Modal>;
	}
}

