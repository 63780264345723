import {Renderable, observable, OldAlerts} from '@esgi/deprecated/knockout';
import {Loader} from '@esgi/deprecated/jquery';
import {CookieReader, WindowTool, StripeUtil} from '@esgi/deprecated/utils';
import {ACFlow} from './flows/ac/ac';
import {ContinueDialog} from './flows/continue-dialog';
import {IframeDialog} from './flows/iframe-dialog';
import {PurchaseFlow} from './flows/purchase/purchase';
import {TrialFlow} from './flows/trial/trial';
import {CreateServer} from './server';
import {RegistrationTemplate} from './template';
import {AcUserInfoModel, BaseUserInfoModel, FlowResultModel, TrialUserInfoModel} from './types';
import {EmailExistingDialog} from './flows/email-existing-dialog/email-existing-dialog';
import {KnockoutWrapper} from '@esgi/deprecated/react';
import {useEffect, useState} from 'react';
import useReCaptcha from 'pages/hooks/use-recaptcha';

import './registration.less';

import {environment} from '@esgi/core/environments';

export interface IOptions {
	ActivationCode: boolean,
	Purchase: boolean,
	InitData: any,
	generateReCaptchaToken?: (action: string) => Promise<string>;
	showBadge?: VoidFunction;
}

export interface InitData {
	promoCode: string;
}


function resolveParams() {
	let promoCode = WindowTool.getUrlQueryParameter('promo');
	if (!promoCode) {
		promoCode = localStorage.getItem('promo');
	}
	if (!promoCode) {
		promoCode = CookieReader.read('promo');
	}
	const initData = {
		promoCode: promoCode,
	};
	return {
		ActivationCode: !!WindowTool.getUrlQueryParameter('ActivationCode'),
		Purchase: !!WindowTool.getUrlQueryParameter('Purchase'),
		InitData: initData,
	};
}

function createFBScript() {
	const fbqWindow = window as any;

	if (!fbqWindow.fbq) {
		fbqWindow.fbq = function() {
			if (fbqWindow.fbq.callMethod) {
				fbqWindow.fbq.callMethod.apply(fbqWindow.fbq, arguments);
			} else {
				fbqWindow.fbq.queue.push(arguments);
			}
		};

		if (!fbqWindow._fbq) {
			fbqWindow._fbq = fbqWindow.fbq;
		}

		fbqWindow.fbq.push = fbqWindow.fbq;
		fbqWindow.fbq.loaded = true;
		fbqWindow.fbq.version = '2.0';
		fbqWindow.fbq.queue = [];

		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://connect.facebook.net/en_US/fbevents.js';
		script.id = 'fb-script-loader';
		document.body.append(script);
	}

	fbqWindow.fbq('init', '491141843870117');
	fbqWindow.fbq('track', 'PageView');
}

function unloadFBScript() {
	const script = document.getElementById('fb-script-loader');
	if (!script) {
		return;
	}

	script.parentNode.removeChild(script);
}

function createHubspotScript() {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://js.hs-scripts.com/45005018.js';
	script.id = 'hs-script-loader';
	document.body.append(script);
}

function unloadHubspotScript() {
	const script = document.getElementById('hs-script-loader');
	if (!script) {
		return;
	}

	script.parentNode.removeChild(script);
}

export function SignUp() {
	const [loaded, setLoaded] = useState(false);
	const {showBadge, hideBadge, generateReCaptchaToken, reCaptchaLoaded} = useReCaptcha();
	const options = resolveParams();
	const [registration] = useState(() => new RegistrationComponent());
	useEffect(() => {
		try {
			if (!options.ActivationCode) {
				createHubspotScript();
			}

			if (!options.ActivationCode && !options.Purchase) {
				createFBScript();
			}

			registration.init({...options, generateReCaptchaToken, showBadge});
			registration.load().then(() => setLoaded(true));
		} catch (e) {
			console.error(e);
		}

		return () => {
			unloadHubspotScript();
			unloadFBScript();
		};
	}, []);

	useEffect(() => {
		if(!options.ActivationCode && reCaptchaLoaded) {
			hideBadge();
		}
	}, [reCaptchaLoaded, hideBadge, options.ActivationCode]);

	if (!loaded) {
		return;
	}

	return <KnockoutWrapper obj={registration}/>;
}

export class RegistrationComponent extends Renderable {

	@observable()
	currentFlow: Renderable;
	loader: Loader = new Loader($('#app'));
	generateReCaptchaToken;

	public init(options?: IOptions) {
		this.generateReCaptchaToken = options.generateReCaptchaToken;

		this.resolveFlow(options);
		// So we push different states to browser history when user navigate between flows, we must reload page when back button has been pressed.
		window.addEventListener('popstate', (e) => {
			window.location.reload();
		});

		window.addEventListener('popstate', e => {
			console.debug(e);
		});
	}

	template = () => RegistrationTemplate.Render();

	public afterRender(rootElement: JQuery): JQueryPromise<any> {
		return super.afterRender(rootElement);
	}

	public resolveFlow(opts: IOptions) {
		let flow;
		if (opts?.ActivationCode) {
			flow = this.createACFlow(opts?.InitData, opts.showBadge);
		} else {
			if (opts?.Purchase) {
				flow = this.createPurchaseFlow(opts?.InitData);
			} else {
				flow = this.createTrialFlow(opts?.InitData, opts.showBadge);
			}
		}
		this.currentFlow = flow;
	}

	public identityTrackingSession(data: FlowResultModel<BaseUserInfoModel>) {
		try {
			if ((window as any).LogRocket && data.model) {
				(window as any).LogRocket.identify(data.model.userName, {
					Email: data.model.email,
				});
			}
		} catch (e) {
			console.error(e.message);
		}
	}

	public createACFlow(initData: AcUserInfoModel, showBadge?: VoidFunction) {
		const flow = new ACFlow({...initData, generateReCaptchaToken: this.generateReCaptchaToken});

		if (showBadge) {
			showBadge();
		}

		flow.events.done(async(_, params) => {
			this.loader.mask();
			await this.generateReCaptchaToken('createUserByAC');
			const data = params.data as FlowResultModel<AcUserInfoModel>;
			this.identityTrackingSession(data);
			CreateServer.createUserByAC({
				...data.model,
				action: 'createUserByAC',
				captchaToken: localStorage.getItem('RECAPTCHA_TOKEN'),
			})
				.done((response) => {
					if (!response.isSuccess) {
						const errorMessage = response?.errors?.[0].description;
						errorMessage && OldAlerts.bsalert(errorMessage);
						return;
					}
					const {message, token} = response.value;
					if (message) {
						OldAlerts.bsalert(message, function () {
							location.href = '/';
						});
					} else {
						document.location.href = `/complete-registration?esgi-token=${token}`;
					}
				})
				.fail(() => OldAlerts.bsalert('Uh oh. Something went wrong on our end.', () => this.loader.unmask()));
		});
		flow.events.submit((email, activationCode, activationCodeInfo) => {
			this.createExistingEmailDialog(email, activationCode, activationCodeInfo);
		});
		flow.events.back(() => this.historyBack());
		return flow;
	}

	public createTrialFlow(initData, showBadge) {
		const flow = new TrialFlow(initData);
		flow.events.acFlowClicked((_, params) => {
			window.history.pushState({}, '', '/sign-up?ActivationCode=true');
			flow.clearValidationResult();
			this.resolveFlow({ActivationCode: true, InitData: params.data, showBadge} as IOptions);
		});
		flow.events.next(async (_, params) => {
			flow.clearValidationResult();
			this.loader.mask();
			const data = params.data as FlowResultModel<TrialUserInfoModel>;
			this.identityTrackingSession(data);
			const reCaptchaAction = 'createTrialFlow';
			await this.generateReCaptchaToken(reCaptchaAction);
			CreateServer.createTrialUser({
				...data.model,
				action: reCaptchaAction,
				captchaToken: localStorage.getItem('RECAPTCHA_TOKEN'),
			}).done((response) => {
				if (!response.isSuccess) {
					const errorMessage = response?.errors?.[0].description;
					errorMessage && OldAlerts.bsalert(errorMessage);
					return;
				}
				const {token} = response.value;
				this.createContinueDialog(token);
			}).always(() => this.loader.unmask());

		});
		flow.events.cancel(() => this.goToHomepage());
		flow.events.purchaseClicked((_, params) => {
			window.history.pushState({}, '', '/sign-up?Purchase=true');
			flow.clearValidationResult();
			this.resolveFlow({Purchase: true, InitData: params.data} as IOptions);
		});
		return flow;
	}

	public createPurchaseFlow(initData) {
		const flow = new PurchaseFlow(initData);
		flow.events.done(() => {
			flow.clearValidationResult();
			flow.isValid().done((r) => {
				if (r) {
					this.loader.mask();
					const data = flow.serialize();
					this.identityTrackingSession(data);
					const _that = this;
					flow.stripeForm.stripeUtil.GetAddressElement().getValue()
						.then(function(result) {
							if (result.complete) {
								flow.stripeForm.stripeUtil.StripeObject.createPaymentMethod({
									type: StripeUtil.ElementNameCard,
									card: flow.stripeForm.stripeUtil.GetCardElement(),
									// eslint-disable-next-line camelcase
									billing_details: result.value,
								}).then(async (result) => {
									if (result.error) {
										OldAlerts.bsalert(`Credit card declined. The response from payment system was:<em>${result.error.message}</em>Please check your payment information and try again.`);
										_that.loader.unmask();
									} else {
										const reCaptchaAction = 'createUserByCreditCard';
										await _that.generateReCaptchaToken(reCaptchaAction);
										CreateServer.createUserByCreditCard({
											...data.model,
											action: reCaptchaAction,
											captchaToken: localStorage.getItem('RECAPTCHA_TOKEN'),
										}, result.paymentMethod.id).done((response) => {
											if (!response.isSuccess) {
												const errorMessage = response?.errors?.[0].description;
												errorMessage && OldAlerts.bsalert(errorMessage);
												return;
											}
											const {token, error} = response.value;
											if (error) {
												OldAlerts.bsalert(`${error}<br />We've created a trial account for you instead. Please try to make your purchase again via your ESGI profile.`,
													() => {
														document.location.href = `/complete-registration?esgi-token=${token}`;
													});
											} else {
												document.location.href = `/complete-registration?esgi-token=${token}`;
											}
										}).always(() => _that.loader.unmask());
									}
								}, (resp) => {
								});
							}
						});
				}
			});
		});

		flow.events.back(() => this.historyBack());
		flow.events.toACFlow((_, params) => {
			window.history.pushState({}, '', '/sign-up?ActivationCode=true');
			flow.clearValidationResult();
			this.resolveFlow({ActivationCode: true, InitData: params.data} as IOptions);
		});

		return flow;
	}

	public createExistingEmailDialog(email, activationCode, activationCodeInfo) {
		const emailDialog = new EmailExistingDialog();

		emailDialog.events.login(() => {
			document.location.href = `/login?return_to=renewal&ActivationCode=${activationCode}`;
		});
		emailDialog.events.createNew(() => {
			window.history.pushState({}, '', 'sign-up?ActivationCode=true');
			this.resolveFlow({
				ActivationCode: true,
				InitData: {
					email: email,
					activationCode: activationCode,
					activationCodeInfo: activationCodeInfo,
					showNameAndPasswordFields: true,
				}} as IOptions);
		});

		this.currentFlow = emailDialog;
	}

	public createContinueDialog(token) {
		const continueDialog = new ContinueDialog();
		continueDialog.events.continue(() => {
			document.location.href = `/complete-registration?esgi-token=${token}`;
		});
		continueDialog.events.finishLater(() => this.goToHomepage());
		this.currentFlow = continueDialog;
	}

	public historyBack() {
		window.history.back();
	}

	goToHomepage = () => window.location.href = environment.landingUrl;
}
