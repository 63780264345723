import {useEffect, useState} from 'react';
import moment from 'moment';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {Hint, Radio} from '@esgillc/ui-kit/form-control';
import {DependElement, FormElement} from '@esgillc/ui-kit/form';
import {SubjectPublishType} from '@esgi/core/enums';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import Section from 'modules/subject-details/components/sections';
import CustomDateRangeModal from './components/custom-date-range-modal';
import MarkingPeriodModal from './components/marking-period-modal';
import {CommonData} from 'modules/subject-details/types';
import styles from './styles.module.less';

interface Props {
	form: DistrictFormType | SchoolFormType;
	commonData: CommonData;
}

export default function PublishSection({form, commonData}: Props) {

	const [isShowMarkingPeriodModal, setShowMarkingPeriodModal] = useState(false);
	const [isShowCustomDateRangeModal, setShowCustomDateRangeModal] = useState(false);

	const [markingPeriodSubLabel, setMarkingPeriodSubLabel] = useState('');
	const [customDateRangeLabel, setCustomDateRangeLabel] = useState('');

	const [prevSelected, setPrevSelected] = useState<SubjectPublishType>(SubjectPublishType.Indefinitely);
	const [subjectPublishType, setSubjectPublishType] = useState<SubjectPublishType>(SubjectPublishType.Indefinitely);


	const getCustomPeriodLabel = (): string => {
		const value = form.controls.customDateRange.value;
		return value ? `(${value.from} - ${value.to})` : '';
	};

	const getMarkingPeriodsLabel = (): string => {
		const selected = form.controls.trackDates.value;
		const source = commonData.trackDates
			.filter(td => selected.indexOf(td.trackDateID) > -1)
			.map(x => `${moment(x.dateFrom).format('L')} - ${moment(x.dateTo).format('L')}`);
		return source.length ? `(${source.join(', ')})` : '';
	};

	useStreamEffect(form.controls.publishType.onChanged, v => {
		if (v.reason === 'value') {
			setPrevSelected(subjectPublishType);
			setSubjectPublishType(v.currState.value);
		}
	});
	useStreamEffect(form.controls.trackDates.onChanged, v => {
		if (v.reason === 'value') {
			setMarkingPeriodSubLabel(getMarkingPeriodsLabel());
		}
	});

	useEffect(() => {
		setPrevSelected(form.controls.publishType.value);
		setCustomDateRangeLabel(getCustomPeriodLabel());
		setMarkingPeriodSubLabel(getMarkingPeriodsLabel());
	}, []);

	return <Section data-cy={'publish-section'} title='Publish'>
		<FormElement control={form.controls.publishType}>
			<Radio
				value={SubjectPublishType.Off}
				className={styles.radio}
				id='SubjectPublishType-off'>
				Off
			</Radio>
			<Radio
				value={SubjectPublishType.Indefinitely}
				className={styles.radio}
				id='SubjectPublishType-indefinitely'>Indefinitely
			</Radio>
			<Radio
				value={SubjectPublishType.SchoolYear}
				className={styles.radio}
				id='SubjectPublishType-schoolYear'>
				School Year
			</Radio>
			<Radio
				value={SubjectPublishType.MarkingPeriod}
				className={styles.radio}
				onClick={() => setShowMarkingPeriodModal(true)}
				id='SubjectPublishType-markingPeriod'>
				Marking Period
			</Radio>
			<DependElement control={form.controls.trackDates}>
				{() => <Hint hidden={(v) => v.value !== SubjectPublishType.MarkingPeriod} className={styles.subLabel}>
					{markingPeriodSubLabel}
				</Hint>}
			</DependElement>
			<Radio
				value={SubjectPublishType.CustomDateRange}
				className={styles.radio}
				onClick={() => setShowCustomDateRangeModal(true)}
				id='SubjectPublishType-customDateRange'
			>Custom Date Range</Radio>
			<DependElement control={form.controls.customDateRange}>
				{() => <Hint hidden={(v) => v.value !== SubjectPublishType.CustomDateRange} className={styles.subLabel}>
					{customDateRangeLabel}
				</Hint>}
			</DependElement>
		</FormElement>

		{isShowMarkingPeriodModal && <div data-cy={'marking-period-modal'}> <MarkingPeriodModal
			commonData={commonData}
			form={form}
			onCancel={() => {
				form.controls.publishType.value = prevSelected;
				setShowMarkingPeriodModal(false);
			}}
			onSave={() => {
				setShowMarkingPeriodModal(false);
			}}
		/></div>
		}

		{isShowCustomDateRangeModal && <CustomDateRangeModal
			form={form}
			onCancel={() => {
				setShowCustomDateRangeModal(false);
				form.controls.publishType.value = prevSelected;
			}}
			onSave={() => {
				setShowCustomDateRangeModal(false);
				setCustomDateRangeLabel(getCustomPeriodLabel());
			}}
		/>}
	</Section>;
}
