export class Font {
	displayName: string;
	systemName: string;

	constructor(displayName: string, systemName: string) {
		this.displayName = displayName;
		this.systemName = systemName;
	}
}

export const FontSizes = [
	'6pt', '8pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt', '30pt', '40pt', '50pt', '60pt', '70pt', '80pt', '90pt', '100pt', '110pt', '120pt', '130pt', '140pt', '150pt', '160pt', '170pt', '180pt', '190pt', '200pt',
];

export const Fonts = [
	new Font('Arial', 'Arial'),
	new Font('Comic Sans MS', 'Comic Sans MS'),
	new Font('Courier New', 'Courier New'),
	new Font('Cursive', 'AbcCursive'),
	new Font('D\'Nealian Style', 'AbcDnManuscript'),
	new Font('Zaner-Bloser New', 'AbcPrint'),
	new Font('Times New Roman', 'Times New Roman'),
	new Font('Verdana', 'Verdana'),
	new Font('Zaner Bloser Style', 'AbcBox01Print'),
	new Font('ABC Math', 'AbcMath'),
	new Font('KG Miss Kindergarten', 'KGMissKindergarten'),
];

export async function LoadFonts() {
	const tasks = [];

	for (let i = 0; i < Fonts.length; i++){
		const font = Fonts[i].systemName;

		if (!(document as any).fonts.check(`12px ${font}`)){
			tasks.push((document as any).fonts.load(`12px ${font}`));
		}
	}

	await $.whenAll(tasks);
}
