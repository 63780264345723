import {GridBox} from '@esgi/ui/layout';
import {SettingsTab, TabItemsGroup} from '../../../../types';
import {Text} from '@esgi/ui/typography';
import {
	ToggleGroupTitle,
	ToggleGroupRoot,
	ToggleGroupContent,
	ToggleGroupItem,
} from './index.styled';

interface Props {
	selectedTab: SettingsTab,
	onChange: (tab: SettingsTab) => void
	tabItems: TabItemsGroup
}

export function SidebarMenu({selectedTab, onChange, tabItems}: Props) {
	return <GridBox>
		<ToggleGroupRoot orientation='vertical' value={selectedTab}>
			<ToggleGroupTitle>
				<Text size='medium' color='lowContrast' data-cy='general-title'>General</Text>
			</ToggleGroupTitle>

			<ToggleGroupContent>
				{tabItems.general.map(t => (
					<ToggleGroupItem key={t.value} value={t.value} onClick={() => onChange(t.value)}>
					<Text size='medium' color='base' bold>{t.name}</Text>
				</ToggleGroupItem>))}
			</ToggleGroupContent>
		</ToggleGroupRoot>

		<ToggleGroupRoot orientation='vertical' value={selectedTab}>
			<ToggleGroupTitle>
				<Text size='medium' color='lowContrast' data-cy='testing-title'>Testing</Text>
			</ToggleGroupTitle>

			<ToggleGroupContent>
				{tabItems.testing.map(t => (
					<ToggleGroupItem value={t.value} onClick={() => onChange(t.value)} key={t.value}>
						<Text size='medium' color='base' bold>{t.name}</Text>
					</ToggleGroupItem>))}
			</ToggleGroupContent>
		</ToggleGroupRoot>

		<ToggleGroupRoot orientation='vertical' value={selectedTab}>
			<ToggleGroupTitle>
				<Text size='medium' color='lowContrast' data-cy='test-session-results-title'>Test Session Results</Text>
			</ToggleGroupTitle>

			<ToggleGroupContent>
				{tabItems.testSessionResults.map(t => (
					<ToggleGroupItem value={t.value} onClick={() => onChange(t.value)} key={t.value}>
						<Text size='medium' color='base' bold>{t.name}</Text>
					</ToggleGroupItem>))}
			</ToggleGroupContent>
		</ToggleGroupRoot>
	</GridBox>;
}
