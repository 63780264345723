import {useItemAnalysisService} from '../../../../hooks';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {useEffect, useRef, useState, useCallback, useMemo} from 'react';
import {getChartOptions, resizeObservable} from '../../../../utils';
import {ChartOptions} from '../../../../types';
import {Zoom} from '../zoom';
import {HighchartPdf} from '../highchart-pdf';
import {debounce} from 'underscore';
import {Chart} from './index.styled';

export function Highchart() {
	const service = useItemAnalysisService();
	const [chartOptions, setChartOptions] = useState<any>(null);
	const [isChartCreated, setIsChartCreated] = useState(false);
	const chartRef = useRef(null);
	const sortOptionMode = useBehaviorSubject(service.sort$);
	const displayNotTestedAsIncorrect = useBehaviorSubject(service.notTested$);
	const testId = useBehaviorSubject(service.test$);
	const testResultsCorrectVerbiage = useBehaviorSubject(service.reportService.testResultsCorrectVerbiage$);
	const testResultsIncorrectVerbiage = useBehaviorSubject(service.reportService.testResultsIncorrectVerbiage$);
	const questionDetailsShow = useBehaviorSubject(service.reportService.questionDetailsShow$);
	const needRerender = useBehaviorSubject(service.reportService.needRerender$);
	const userLevel = service.reportService.level;
	const zoom = useBehaviorSubject(service.reportService.zoom$);
	const questionWidth = service.reportService.questionWidth;
	const reportData = useBehaviorSubject(service.reportService.reportData$);

	const selectedTest = useMemo(() => service.testService.get(testId), [testId]);

	useEffect(() => {
		if (reportData === null) {
			return;
		}

		hideQuestionDetails();
		const chartSize = calcChartSize();
		const chartOptions: ChartOptions = {
			chartSize,
			reportData,
			selectPoint: (event) => service.reportService.selectPoint(event),
			sortOptionMode: sortOptionMode.toString(),
			stackIncorrect: displayNotTestedAsIncorrect,
			testName: selectedTest?.name,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			updateScrollbarDisplay,
			normalizeChart,
			userLevel,
			updateTitleSize: debounce(calcTitleSize, 500),
		};
		setChartOptions(getChartOptions(chartOptions));
	}, [displayNotTestedAsIncorrect, sortOptionMode, reportData]);

	useEffect(() => {
		if (chartRef.current?.chart) {
			const xAxis = chartRef.current.chart.xAxis[0];
			const chartSize = calcChartSize();

			if (xAxis.min !== 0 || xAxis.max !== chartSize.max) {
				xAxis.setExtremes(0, chartSize.max, true, false);
			}
		}
	}, [chartOptions]);

	useEffect(() => {
		const sub = resizeObservable(document.querySelector('#iar-chart'))
			.subscribe(resizeChart);
		return () => sub.unsubscribe();
	}, []);

	useEffect(() => {
		if (!chartRef.current?.chart) {
			return;
		}
		if (needRerender) {
			chartRef.current.chart.render();
			service.reportService.setNeedRerender(false);
		}
	}, [needRerender]);


	const hideQuestionDetails = () => {
		service.reportService.setQuestionDetailsShow(false);
	};

	const calcChartSize = () => {
		const defaultHeight = 480;
		let qdWidth = 0;
		const questionDetails = document.querySelector('#iar-qd');
		const report = document.querySelector('#iar-body');
		const chartHeight = report?.clientHeight || defaultHeight;
		if (questionDetailsShow) {
			qdWidth = questionDetails?.clientWidth - 15;
		}

		const chartWidth = document.documentElement.clientWidth * 0.98 - 40;
		const questionsWidth = Math.max(zoom * questionWidth * reportData.questions.length + 70, 540);
		let width = questionsWidth;
		let max = reportData.questions.length - 1;

		if (chartWidth < questionsWidth) {
			width = chartWidth;
			max = Math.min((chartWidth - 70) / (zoom * questionWidth), max);
		}
		const widthDelta = width + qdWidth + 35 + 70 - window.innerWidth;
		if (widthDelta > 0) {
			width -= widthDelta;
		}
		return {width, height: Math.min(chartHeight, defaultHeight), max};
	};

	const calcTitleSize = () => {
		if (!chartRef.current?.chart) {
			return;
		}

		const chartElement: HTMLDivElement = document.querySelector('#iar-chart .highcharts-container');
		const titleElement: HTMLSpanElement = document.querySelector('#iar-chart .highcharts-title');
		const zoomElement: HTMLDivElement = document.querySelector('#iar-zoom');
		titleElement.removeAttribute('title');
		titleElement.style.removeProperty('--width');
		const chartWidth = Math.round(chartElement?.clientWidth);
		const titleWidth = Math.round(titleElement?.clientWidth);
		const titleOffset = titleElement?.offsetLeft;
		const zoomWidth = Math.round(zoomElement?.clientWidth);
		if (titleOffset + titleWidth > chartWidth - zoomWidth) {
			const width = titleWidth - ((titleOffset + titleWidth) - (chartWidth - zoomWidth));
			titleElement.setAttribute('title', titleElement.innerText);
			titleElement.style.setProperty('--width', `${width}px`);
		}
	};

	const fitChartSize = () => {
		if (!chartRef.current?.chart) {
			return;
		}

		const {container} = chartRef.current.chart;
		const parent = container.parentElement;

		if (
			parent.clientHeight < container.clientHeight
			|| parent.clientWidth < container.clientWidth
		) {
			chartRef.current.chart.setSize(parent.clientWidth, parent.clientHeight);
		} else {
			chartRef.current.chart.setSize(void 0, void 0, false);
		}
	};

	const debouncedCalcTitleSize = debounce(calcTitleSize, 300);
	const debouncedFitChartSize = debounce(fitChartSize, 100);
	const resizeChart = () => {
		debouncedCalcTitleSize();
		debouncedFitChartSize();
	};

	const updateSpaces = useCallback((chart: any) => {
		const titlEl: HTMLSpanElement = document.querySelector('#iar-chart .highcharts-title');
		titlEl.style.width = 'auto';
		let width = titlEl?.clientWidth;
		let widthStr = 'auto';
		const chartMaxWidth = Math.round(document.getElementsByClassName('item-analysis-highchart-container')[0]?.clientWidth);
		let chartWidth = Math.round(chart.chartWidth);
		let spacing = chart.spacing[1];

		if (width + 459 > chartWidth) {
			let newChartWidth = width + 460;
			if (newChartWidth > chartMaxWidth) {
				newChartWidth = chartMaxWidth;
			}
			if (newChartWidth !== chartWidth) {
				spacing = Math.max((newChartWidth - chartWidth) / 2, 10);
			}
			chartWidth = newChartWidth;
			width = chartWidth - 460;

			widthStr = (width + 1) + 'px';
		}

		const left = (chartWidth - width) / 2;
		chart.update({
			chart: {
				width: chartWidth,
				spacingLeft: spacing,
				spacingRight: spacing,
			},
		}, true, false, {
			duration: 0,
			complete: () => {
				titlEl.style.left = `${left}px !important`;
				titlEl.style.width = `${widthStr}px !important`;
			},
		});
		titlEl.style.left = `${left}px !important`;
		titlEl.style.width = `${widthStr}px !important`;
	}, []);

	const normalizeChart = useCallback((event) => {
		setTimeout(() => {
			updateSpaces(event.target);
		}, 100);
		service.reportService.resetZoom();
	}, [updateSpaces, service.reportService.resetZoom]);

	const updateScrollbarDisplay = useCallback(() => {
		const scrollbar: SVGGElement = document.querySelector('#iar-chart .highcharts-scrollbar');
		const track: SVGGElement = document.querySelector('#iar-chart .highcharts-scrollbar-track');
		scrollbar.style.display = '';
		const trackWidth = track.getBoundingClientRect()?.width;

		let sibling = track.nextElementSibling;
		let siblingsWidth = 0;
		while (sibling) {
			siblingsWidth += sibling.getBoundingClientRect()?.width;
			sibling = sibling.nextElementSibling;
		}

		if (Math.round(trackWidth - siblingsWidth) < 20) {
			scrollbar.style.display = 'none';
		}
	}, []);

	useEffect(() => {
		if (
			!reportData?.questions
			|| !chartRef.current?.chart
		) {
			return;
		}

		const chart = chartRef.current.chart;
		const chartSize = calcChartSize();
		setTimeout(() => {
			chart.update({
				chart: {
					width: chartSize.width,
					height: chartSize.height,
					spacingLeft: 10,
					spacingRight: 10,
				},
			}, true, false, {
				duration: 0,
				complete: () => {
					setTimeout(() => {
						chart.xAxis[0].setExtremes(0, chartSize.max, true, false);
					}, 0);
					hideQuestionDetails();
				},
			});
		}, 0);
	}, [zoom]);

	const afterChartCreated = useCallback(() => {
		setIsChartCreated(true);
	}, []);

	return (
		<>
			<Chart id='iar-chart'>
				{(isChartCreated && chartOptions) &&
					<Zoom/>
				}
				{chartOptions &&
					<HighchartsReact
						ref={chartRef}
						options={chartOptions}
						callback={() => afterChartCreated()}
					/>
				}
			</Chart>
			<HighchartPdf/>
		</>
	);
}
