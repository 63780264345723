import * as React from 'react';
import type {SVGProps} from 'react';

export function Edit(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M20.002 8c.353.001.71.046 1.055.116.547.109 1.339.348 1.909.918.57.57.809 1.362.918 1.91.07.345.115.701.116 1.054V12a.802.802 0 0 1-.234.567l-9.036 9.033-1.132-1.131 8.783-8.785c-.084-.503-.18-1.16-.53-1.508-.35-.347-1.09-.481-1.536-.558l-8.786 8.782-1.13-1.13 9.036-9.035A.806.806 0 0 1 20.002 8Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M20.8 13.269 18.73 11.2l-1.13 1.131 2.068 2.069 1.131-1.131Zm-6.07 8.33-4.33-4.33-1.366 1.365a.8.8 0 0 0-.218.41l-.8 4a.8.8 0 0 0 .94.94l4-.8a.8.8 0 0 0 .41-.218l1.364-1.367Zm-4.91.581.517-2.586.063-.063 2.069 2.069-.064.063-2.585.517Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
