import {TabItem} from '../types';
import {ItemId} from './types';

const tabItems: TabItem<ItemId>[] = [
	{
		value: ItemId.PersonalInformation,
		label: 'Personal Information',
	},
	{
		value: ItemId.Login,
		label: 'Login Information',
	},
];

const mainAccountTabItems: TabItem<ItemId>[] = [
	{
		value: ItemId.SchoolAndDistrict,
		label: 'School & District',
	},
	{
		value: ItemId.PersonalInformationMainAcoount,
		label: 'Personal Information',
	},
	{
		value: ItemId.MarkingPeriods,
		label: 'Marking Periods',
	},
];

export const toggleGroupList = [
	{
		title: 'Your Account',
		tabItems,
	},
	{
		title: 'Main Account',
		tabItems: mainAccountTabItems,
	},
];
