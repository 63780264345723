import {Box} from '@esgi/ui/layout';
import {styled} from '@stitches/react';

export const ReportHeaderRowStyled = styled(Box, {
	display: 'flex',
	flexDirection: 'row',
	borderBottom: '2px solid #FFFFFF',
	padding: '0 0 0 25px',

	'$:last-child': {
		borderRight: 'none',
	},
});

export const ReportFilterBodyStyled = styled(Box, {
	backgroundColor: '#FFFFFF',

	'&:last-child': {
		borderBottom: 'none',
	},
});

export const ReportFilterContainerStyled = styled(Box, {
	display: 'flex',
	borderRight: '2px solid #FFFFFF',
	padding: '10px',
	alignItems: 'flex-start',
	flexDirection: 'column',
});