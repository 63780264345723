import {FormElement} from '@esgillc/ui-kit/form';
import {Hint, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {AutoTestCreatorFormType} from 'shared/modules/auto-test-creator/form';
import {maxLengths} from 'shared/modules/auto-test-creator/constants';
import {AddIcon} from 'shared/modules/auto-test-creator/icons/add-icon';
import styles from './styles.module.less';
import React from 'react';

interface Props {
	form: AutoTestCreatorFormType;
	isShowAddButton: boolean;
	onAddQuestion: () => void;
	onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

export function QuestionAdder({form, isShowAddButton, onAddQuestion, onPaste}: Props) {

	const onPressEnter = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (form.controls.questionAdder.value) {
				onAddQuestion();
			}
		}
	};

	return <div data-cy='question-adder' className={styles.questionAdder}>
		<label data-cy='title' className={styles.questionLabel}> Question: </label>
		<div data-cy='question-adder' className={styles.addInputContainer}>
			<FormElement className={styles.questionAdderContainer} control={form.controls.questionAdder}>
				<Input onPaste={onPaste} onKeyDown={onPressEnter} placeholder='Enter question or paste a list'
					   maxLength={maxLengths.question}/>
				<Hint className={styles.limitCounter}>
					{props => <span
						data-cy='limit-counter'>{maxLengths.question - (props.value.length | 0)} characters left ({maxLengths.question} max)</span>}
				</Hint>
				<OnErrorTooltip showOnError='required' placement='top'>
					Please add a test question
				</OnErrorTooltip>
				<OnErrorTooltip showOnError='length-max' placement='right'>
					30 characters max
				</OnErrorTooltip>
			</FormElement>
		</div>
		<div>
			{isShowAddButton && <AddIcon onClick={onAddQuestion} className={styles.addButton}/>}
		</div>
	</div>;
}


