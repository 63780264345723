import {TestType} from '@esgi/main/libs/core';
import {useCallback, useMemo} from 'react';
import {AnswersInfoData} from './types';
import {useTestSectionContext} from '../../../../../../../../context/test-section-context';

export function useAnswersInfoData({
	testType,
	testedStudents,
	totalStudents,
	totalPossible,
	classResultValue,
}: {
	testType: TestType;
	testedStudents: number;
	totalStudents: number;
	totalPossible: number;
	classResultValue: number;
}) {
	const {testResultsCorrectVerbiage} = useTestSectionContext();

	const getAdapredValue = useCallback(
		(value: number) => {
			const hasTestedStudents = testedStudents >= 1;

			return hasTestedStudents ? Math.round(value) : null;
		},
		[testedStudents],
	);

	return useMemo<AnswersInfoData>(() => {
		if (testType === TestType.YN) {
			const maxValue = testedStudents * totalPossible;

			return {
				value: getAdapredValue((maxValue * classResultValue) / 100),
				maxValue,
				transcript: `${testResultsCorrectVerbiage} answers`,
			};
		}

		if (testType === TestType.Score) {
			const maxValue = totalPossible;

			return {
				value: getAdapredValue((maxValue * classResultValue) / 100),
				maxValue,
				transcript: 'Score',
			};
		}

		if (testType === TestType.Rubric) {
			return {
				value: getAdapredValue(classResultValue),
				maxValue: totalPossible,
				transcript: 'Avg. Score',
			};
		}

		return {
			value: null,
			maxValue: 0,
			transcript: '',
		};
	}, [classResultValue, getAdapredValue, testResultsCorrectVerbiage, testType, totalPossible, totalStudents]);
}
