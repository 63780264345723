// eslint-disable-next-line check-file/filename-naming-convention
import {createStitches} from '@stitches/react';

const {
	styled,
	css,
	globalCss,
	keyframes,
	getCssText,
	theme,
	createTheme,
	config,
} = createStitches({

});


export {
	styled,
	css,
	globalCss,
	keyframes,
	getCssText,
	theme,
	config,
	createTheme,
};