import {createContext, useContext, useState, useEffect} from 'react';
import {useService} from '@esgi/core/service';
import {StudentProgressReportService} from './services';

export const useStudentProgressReport = () => {
	const service = useService(StudentProgressReportService);
	return {service};
};

export const StudentProgressReportContext = createContext(null);
export const useStudentProgressReportService = () =>
	useContext<StudentProgressReportService>(StudentProgressReportContext);

export const useValue = <T>(initValue: T, onChange: (value: T) => void) => {
	const [value, setValue] = useState<T>(initValue);

	useEffect(() => {
		onChange(value);
	}, [value]);

	return [value, setValue] as const;
};
