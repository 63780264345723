import {styled} from '@esgi/ui/theme';

export const Table = styled('table');

export const MainTable = styled(Table, {
	width: '100%',
	whiteSpace: 'nowrap',
	margin: '0 20px',
	textAlign: 'center',
});

export const TableBody = styled('tbody', {
	width: '100%',
	whiteSpace: 'nowrap',
});

export const Row = styled('tr', {
	variants: {
		dataBody: {
			true: {
				fontWeight: 'bold',
			},
		},
		bgLightgray: {
			true: {
				backgroundColor: '#f8f8f8',
			},
		},
	},
});
export const Cell = styled('td', {
	variants: {
		testInfo: {
			true: {
				padding: '0 10px 0 10px',
				border: '1px solid #bfbfbf',
				fontWeight: 'normal',

				'> table': {
					width: '100%',
					'td': {
						verticalAlign: 'bottom',
						whiteSpace: 'nowrap',
						border: '1px solid #bfbfbf',
					},
				},
			},
		},
		testName: {
			true: {
				fontWeight: 'normal',
				textAlign: 'left',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '65ch',
			},
		},
		simple: {
			true: {
				width: '42px',
				minWidth: '42px',
				height: '42px',
				fontWeight: 'normal',
				border: '1px solid #bfbfbf',
			},
		},
		bgWhite: {
			true: {
				backgroundColor: 'white',
			},
		},
		bgGray: {
			true: {
				backgroundColor: '#e0e0e0',
			},
		},
		pageBreak: {
			true: {
				paddingTop: '20px',
				textAlign: 'center',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				fontWeight: 'normal',

				'> span': {
					position: 'relative',
					display: 'inline-block',

				'&:after, &:before': {
						content: '',
						position: 'absolute',
						top: '50%',
						width: '9999px',
						height: '1px',
						borderTop: '1px dotted black',
					},
				'&:after': {
						left: '100%',
						marginLeft: '15px',
					},
				'&:before': {
						right: '100%',
						marginRight: '15px',
					},
				},
			},
		},
		space20: {
			true: {
				height: '20px',
			},
		},
		space40: {
			true: {
				height: '40px',
			},
		},
		spaceDynamic: {
			true: {
				height: '20px',
			},
			false: {
				height: '40px',
			},
		},
		studentName: {
			true: {
				textAlign: 'left',
				fontWeight: 'bold',
				fontSize: '22px',
			},
		},
		percentageCorrectLabel: {
			true: {
				fontWeight: 'normal',
				paddingBottom: '3px',
			},
		},
		subjectInfo: {
			true: {
				textAlign: 'left',
				padding: '0 10px',
				width: '1px',
				backgroundColor: '#e0e0e0',
				border: '1px solid #bfbfbf',
			},
		},
		subjectName: {
			true: {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '40ch',
			},
		},
		bold: {
			true: {
				fontWeight: 'bold',
			},
		},
		markingPeriod: {
			true: {
				'sup': {
					fontSize: '8px',
					top: '-5px',
				},
			},
		},
	},
});

export const HeaderRow = styled('tr', {
	fontWeight: 'bold',
	margin: '10px 0 0 10px',

	'td:last-child': {
		visibility: 'hidden',
	},
});

export const GradeDescriptions = styled('div', {
	display: 'flex',
	flexFlow: 'row wrap',
	alignItems: 'center',
	justifyContent: 'flex-start',
});

export const Description = styled('div', {
	fontWeight: 'normal',
});

export const GradeContainer = styled('div', {
	'&:not(:last-child)': {
		marginRight: '20px',
	},
	padding: '10px',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	flexFlow: 'row nowrap',
});

export const GradeName = styled('div', {
	fontWeight: 'bold',
	fontSize: '20px',
	textAlign: 'center',
	lineHeight: '40px',
	border: '1px solid #ddd',
	height: '40px',
	padding: '0 10px',
	marginRight: '10px',
});