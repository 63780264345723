import {SubjectType} from '@esgi/core/enums';

export const subjectDroppableType = 'subject';
export const testDroppableType = 'tests';

export type SubjectDroppableInfo = {
	id: number;
	subjectType: SubjectType;
	expanded: boolean;
};

export type TestDraggableInfo = {
	testID: number;
	subjectID: number;
	index: number;
}
