import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const SkeletonRow = styled(Box, {
	...SkeletonStyles,
	width: '100%',
	borderRadius: 4,

	variants: {
		variant: {
			default: {
				height: 200,
			},
			tiny: {
				height: 100,
			},
			listItem: {
				height: 60,
			},
			select: {
				height: 40,
			},
		},
	},
});