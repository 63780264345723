import ReportSettings from './components/report-settings';
import StudentProgressReportService
	from 'modules/reports/student-progress-report/services/student-progress-report-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Report} from './models';
import Table from './components/table';
import EmptyBlock from 'modules/reports/student-progress-report/components/empty-block';
import styles from './student-progress-report.module.less';

interface Props {
	service: StudentProgressReportService;
}
export default function StudentProgressReport(props: Props) {
	const {service} = props;
	const emptyReport = useBehaviorSubject<boolean>(service.emptyReport);
	const report = useBehaviorSubject<Report>(service.report);

	return <div className={styles.studentProgressReport} data-cy='spr-container'>
		<ReportSettings service={service}/>
		<div className={styles.mainContainer}>
			{
				!emptyReport && report !== null && <Table service={service}/>
			}
			<EmptyBlock emptyReport={emptyReport} noStudents={report?.students?.length === 0}/>
		</div>
	</div>;
}
