import {ComponentPropsWithoutRef, forwardRef, PropsWithChildren} from 'react';
import {Skeletonable, BaseComponentProps} from '@esgi/ui';
import {StyledAvatarRoot} from './styled';
import {ComponentProps} from '@stitches/react';
import {AvatarProps} from '@radix-ui/react-avatar';

type AvatarRootProps = PropsWithChildren & ComponentPropsWithoutRef<'div'> & {
	/** Avatar size. */
	size?: ComponentProps<typeof StyledAvatarRoot>['size'];

	/** Avatar border type. */
	borderType?: ComponentProps<typeof StyledAvatarRoot>['borderType'];

	/** Avatar border color. */
	borderColor?: ComponentProps<typeof StyledAvatarRoot>['borderColor'];

	/** Is circle. */
	isCircle?: boolean;
} & BaseComponentProps & Skeletonable & AvatarProps;

export const AvatarRoot = forwardRef<HTMLDivElement, AvatarRootProps>(({
	children,
	size = 'md',
	borderType = 'solid',
	borderColor = 'transparent',
	isCircle = false,
	dataCy = 'ui-kit-avatar-root',
	...props
}: AvatarRootProps, ref) => {
	return <StyledAvatarRoot data-cy={dataCy} borderType={borderType} borderColor={borderColor}
							 size={size} isCircle={isCircle} ref={ref} {...props}>
		{children}
	</StyledAvatarRoot>;
});
