import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const StyledTestResultContainer = styled(GridBox, {
	gap: '$3',
	gridAutoFlow: 'column',
	justifyContent: 'end',

	[`${Text}`]: {
		minWidth: '12px',
	},
});