import {globalCss, styled} from './stitches.config';

export const global = globalCss({
	'@import': [`url('https://fonts.googleapis.com/css?family=Roboto:500,600,700&display=swap')`, `url('https://fonts.googleapis.com/css?family=Roboto+Mono:500,600,700&display=swap')`],
	'@font-face': [{
		fontFamily: 'Roboto',
		src: `url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap')`,
	},
	{
		fontFamily: 'Roboto Mono',
		src: `url('https://fonts.googleapis.com/css?family=Roboto+Mono+Condensed&display=swap')`,
	},
	],
	'*': {
		fontFamily: 'Roboto, "Open Sans", sans-serif',
	},
});

export const Wrapper = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	overflow: 'hidden',
});