import {useRef} from 'react';
import {NavigationBar, NavigationBarOrientation} from '@esgi/ui/layout';
import {VerticalPanelHead} from './components/vertical-panel-head';
import {VerticalPanelContent} from './components/vertical-panel-content';
import {VerticalPanelBottom} from './components/vertical-panel-bottom';
import {NavigationPanelBaseProps} from '../../../types';

export function VerticalPanel({
	onSettingsClick,
	onCastClick,
	onAvatarClick,
	onHelpClick,
	schoolYears,
	userInfo,
	onChangeSchoolYears,
	currentSchoolYearID,
}: NavigationPanelBaseProps) {
	const selectContentRef = useRef();
	const selectFieldRef = useRef();

	return (
		<NavigationBar.Root
			css={{height: '100%', width: 120}}
			dataCy='vertical-panel'
			orientation={NavigationBarOrientation.Vertical}
			dependElementRefs={[selectContentRef, selectFieldRef]}
			closeOnOutsideClick
			isOpen
		>
			<VerticalPanelHead
				currentSchoolYearID={currentSchoolYearID}
				schoolYears={schoolYears}
				selectContentRef={selectContentRef}
				selectFieldRef={selectFieldRef}
				onChangeSchoolYears={onChangeSchoolYears}
			/>

			<VerticalPanelContent userInfo={userInfo} />

			<VerticalPanelBottom
				onSettingsClick={onSettingsClick}
				onCastClick={onCastClick}
				onAvatarClick={onAvatarClick}
				userInfo={userInfo}
				onHelpClick={onHelpClick}
			/>
		</NavigationBar.Root>
	);
}
