import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {InitCreateGroupResponse} from './types';
import {ClassModel, Student} from '../../types';

export class AddGroupService extends BaseService {
	public classes$ = new BehaviorSubject<ClassModel[] | null>(null);

	private controller = 'v2/teachers/modules/groups';

	public init() {
		return this.httpClient.ESGIApi.get<InitCreateGroupResponse>(this.controller, 'create')
			.pipe(
				tap(({classes}) => {
					this.classes$.next(classes);
				}),
			)
			.asObservable();
	}

	public createGroup({
		classId,
		name,
		studentIDs,
	}: {
		classId: ClassModel['id'];
		name: string;
		studentIDs: Student['id'][];
	}) {
		return this.httpClient.ESGIApi.post<{groupID: number}>(this.controller, 'create', {
			classId,
			name,
			studentIDs,
		});
	}
}
