import {
	ControlValue,
	FormControl,
	FormGroup,
	ValidatorArgs,
	Validators,
} from '@esgi/ui/form';
import {Observable, switchMap, tap} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {V2StudentsController} from '@esgi/contracts/esgi';
import {StudentGenders} from '../types';

export type ProfileFormType = FormGroup<
	{
		firstName: FormControl<string>;
		lastName: FormControl<string>;
		studentIDN: FormControl<string>;
		exportIDN: FormControl<string>;
		gradeLevel: FormControl<string[]>;
		language: FormControl<string[]>;
		gender: FormControl<string[]>;
		password: FormControl<string>;
		username: FormControl<string>;
		birthDate: FormControl<Date[]>;
	},
	ControlValue<{
		firstName: FormControl<string>;
		lastName: FormControl<string>;
		studentIDN: FormControl<string>;
		exportIDN: FormControl<string>;
		gradeLevel: FormControl<string[]>;
		language: FormControl<string[]>;
		gender: FormControl<string[]>;
		password: FormControl<string>;
		username: FormControl<string>;
		birthDate: FormControl<Date[]>;
	}>
>;

export const rangeLengthCustomValidator = (min: number, max: number, fieldName: FormControl<string>) => {
	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(
			debounceTime(300),
			tap((validatorArgs) => {
				if(validatorArgs.field.currentValue){
					if (validatorArgs.field.currentValue.length < min) {
						validatorArgs.errors.push('range-length-lower');
					} else if (validatorArgs.field.currentValue.length > max) {
						validatorArgs.errors.push('range-length-upper');
					}
				}else if(fieldName.value?.length && !validatorArgs.field.currentValue){
					validatorArgs.errors.push('require');
				}
				return validatorArgs;
			})
		);
	};
};
export const birthDateValidator = () => {
	return (validationChain: Observable<ValidatorArgs<string>>) => {
	  return validationChain.pipe(
			tap((validatorArgs) => {
		  if (validatorArgs.field.currentValue && new Date(validatorArgs.field.currentValue?.[0]) > new Date()) {
					validatorArgs.errors.push('invalid-date');
		  }
		  return validatorArgs;
			})
	  );
	};
};
export const studentUsernameAvailableCustomValidator = (
	controller: V2StudentsController,
	credentials: { userName: string; password: string },
	profileForm: ProfileFormType,
	studentID: number,
	districtID: number,
	globalSchoolYearID: number
) => {
	const checkUsername = (userName: string) => {
		return controller.checkExistUsername({
			userName,
			studentID,
			districtID,
		});
	};

	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(
			debounceTime(200),
			switchMap((validatorArgs) => {
				if (
					!validatorArgs.field.currentValue ||
					!profileForm.controls.password.value
				) {
					return new Observable<ValidatorArgs<string>>((subscriber) =>
						subscriber.next(validatorArgs)
					);
				}
				return checkUsername(validatorArgs.field.currentValue).pipe(
					map((username) => {
						if (
							username.exists &&
							credentials?.userName !== validatorArgs.field.currentValue
						) {
							validatorArgs.errors.push('user-name-in-use');
						}
						return validatorArgs;
					})
				);
			})
		);
	};
};

export const studentIDNValidator = (
	controller: V2StudentsController,
	studentID: number,
	globalSchoolYearID: number,

) => {
	const checkStudentIDN = (studentIDN: string) => {
		return controller.checkExistStudent({
			studentIDN,
			studentID,
			globalSchoolYearID,
		});
	};

	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(
			debounceTime(300),
			switchMap((validatorArgs) => {
				if(!validatorArgs.field.currentValue){
					return new Observable<ValidatorArgs<string>>(subscriber => subscriber.next(validatorArgs));
				}
				return checkStudentIDN(validatorArgs.field.currentValue).pipe(
					map((student) => {
						if (student.studentID) {
							validatorArgs.errors.push('idn-in-use');
						}
						return validatorArgs;
					}));
			},
			));
	};
};

export const createProfileTabForm = (): ProfileFormType =>
	new FormGroup({
		firstName: new FormControl('', {
			validators: [Validators.required(), Validators.length.min(2)],
		}),
		lastName: new FormControl('', {
			validators: [Validators.required(), Validators.length.min(2)],
		}),
		studentIDN: new FormControl('', {validators: []}),
		exportIDN: new FormControl(''),
		gradeLevel: new FormControl(['4'], {validators: [Validators.required()]}),
		language: new FormControl(['2'], {validators: [Validators.required()]}),
		gender: new FormControl([StudentGenders.Other.toString()], {validators: [Validators.required()]}),
		password: new FormControl(''),
		username: new FormControl(''),
		birthDate: new FormControl([], {validators: [birthDateValidator()]}),
	});
