import React, {useEffect, useState} from 'react';
import {HelpLink, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {ElementStatus, Form, FormControl, FormElement, FormGroup, useForm, Validators} from '@esgillc/ui-kit/form';
import {Hint, Input, OnErrorTooltip, Radio} from '@esgillc/ui-kit/form-control';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {Example} from 'shared/modules/user-settings/components/example';
import {
	exampleImageUrl,
	helpLink,
	maxCustomizeNameLength,
} from './constants';
import styles from './styles.module.less';


interface Props {
	correctVerbiage: string;
	incorrectVerbiage: string;
	testResultsVerbiagesEnabled: boolean;
	onClose: () => void;
	onSave: (testResultsVerbiagesEnabled: boolean, correctVerbiage: string, incorrectVerbiage: string) => void;
}

export default function TestResultVerbiageModal({
	                                                onClose,
	                                                correctVerbiage,
	                                                incorrectVerbiage,
	                                                testResultsVerbiagesEnabled,
	                                                onSave,
}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const form = useForm(() => new FormGroup({
		testResultsVerbiagesEnabled: new FormControl<number>(Number(testResultsVerbiagesEnabled)),
		correctVerbiage: new FormControl<string>(correctVerbiage, {validators: [Validators.required(), Validators.length.max(maxCustomizeNameLength)]}),
		incorrectVerbiage: new FormControl<string>(incorrectVerbiage, {validators: [Validators.required(), Validators.length.max(maxCustomizeNameLength)]}),
	}));

	const [correctVerbiageValue, setCorrectVerbiageValue] = useState(correctVerbiage);
	const [incorrectVerbiageValue, setIncorrectVerbiageValue] = useState(incorrectVerbiage);
	const [isHintsVisible, setHintsVisible] = useState(false);

	useEffect(() => {
		changeInputsEnabled(testResultsVerbiagesEnabled);
	}, []);
	useStreamEffect(form.onChanged, (v) => {
		const isVerbiagesEnabled = !!v.currState.value.testResultsVerbiagesEnabled;
		setCorrectVerbiageValue(v.currState.value.correctVerbiage);
		setIncorrectVerbiageValue(v.currState.value.incorrectVerbiage);
		changeInputsEnabled(isVerbiagesEnabled);
		setHintsVisible(isVerbiagesEnabled);
	});

	const changeInputsEnabled = (isEnabled: boolean) => {
		form.controls.incorrectVerbiage.status = isEnabled ? null : ElementStatus.disabled;
		form.controls.correctVerbiage.status = isEnabled ? null : ElementStatus.disabled;
	};

	const handleSave = () => {
		const {testResultsVerbiagesEnabled, correctVerbiage, incorrectVerbiage} = form.value;
		form.validate().subscribe(result => {
			if (result.valid) {
				onSave(!!testResultsVerbiagesEnabled, correctVerbiage, incorrectVerbiage);
				handleClose();
			}
		});

	};

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title>
				Test Result Verbiage
				<HelpLink url={helpLink}/>
			</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<div data-cy='test-result-verbiage-modal'>
				<p className={styles.description}>Test results verbiage appears on parent letters, reports and homepage
					pie
					charts.</p>
				<Form controller={form}>
					<FormElement control={form.controls.testResultsVerbiagesEnabled}>
						<div data-cy='correct-incorrect'>
							<Radio value={0} className={styles.correctRow}>
								Correct | Incorrect
							</Radio>
						</div>

						<div data-cy='customize' className={styles.row}>
							<Radio value={1}>Customize</Radio>
							<div className={styles.row}>
								<div data-cy='correct-verbiage'>
									<FormElement control={form.controls.correctVerbiage}>
										<Input maxLength={maxCustomizeNameLength} placeholder={'i.e. Mastered'}/>
										<OnErrorTooltip showOnError={'required'} placement={'top'}>
											Enter a custom name for Correct. This will appear on all reports where test
											results
											are shown.
										</OnErrorTooltip>
										<OnErrorTooltip showOnError={'length-max'} placement={'top'}>
											Enter a valid custom name for Correct. This will appear on all reports where
											test
											results are shown.
										</OnErrorTooltip>
										<Hint className={styles.hint}
										      hidden={!correctVerbiageValue.length || !isHintsVisible}>
											{maxCustomizeNameLength - correctVerbiageValue.length} characters
											left {maxCustomizeNameLength} max
										</Hint>
									</FormElement>
								</div>
								<div data-cy='incorrect-verbiage'>
									<FormElement control={form.controls.incorrectVerbiage}>
										<Input maxLength={maxCustomizeNameLength} placeholder={'i.e. Not Mastered'}/>
										<OnErrorTooltip showOnError={'required'} placement={'right'}>
											Enter a custom name for Incorrect. This will appear on all reports where
											test
											results are shown.
										</OnErrorTooltip>
										<OnErrorTooltip showOnError={'length-max'} placement={'right'}>
											Enter a valid custom name for Incorrect. This will appear on all reports
											where
											test
											results are shown.
										</OnErrorTooltip>
										<Hint className={styles.hint}
										      hidden={!incorrectVerbiageValue.length || !isHintsVisible}>
											{maxCustomizeNameLength - incorrectVerbiageValue.length} characters
											left {maxCustomizeNameLength} max
										</Hint>
									</FormElement>
								</div>
							</div>
						</div>
					</FormElement>
				</Form>
				<Example
					imgUrl={exampleImageUrl}
					text='Example of test result verbiage:'
				/>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<div>
				<Buttons.Gray onClick={handleClose}>
					Cancel
				</Buttons.Gray>
			</div>
			<div data-cy='verbiage-modal-save-button'>
				<Buttons.Contained onClick={handleSave}>
					Save
				</Buttons.Contained>
			</div>
		</Modal.Footer>
	</Modal>
}
