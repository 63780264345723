import {useUser} from '@esgi/core/authentication';
import {TestType} from '@esgi/core/enums';
import {ChartBlockModel} from '../../../../types';
import {TestModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/pie-charts/models/test-model';
import {formattedDate} from '../../../../utils';
import {BaseCard} from './base-card';
import {Text} from '@esgi/ui/typography';
import {LegendItem, LegendBox} from './card.styled';

export interface PersonalCardProps {
	test: TestModel;
	chartBlockModel: ChartBlockModel;
	printInColor: boolean;
}

export default function PersonalCard({
	test,
	chartBlockModel,
	printInColor,
}: PersonalCardProps) {
	const currentUser = useUser();
	const {testResultsCorrectVerbiage} = currentUser;
	const withLegend = !!chartBlockModel.testResult;

	return (
		<BaseCard
			testName={test?.name}
			chartBlockModel={chartBlockModel}
			isNotTested={!chartBlockModel.testResult}
			withLegend={withLegend}
			printInColor={printInColor}
		>
			{withLegend &&
				<>
					<LegendItem type='info'>
						<LegendBox>
							<Text size='small' font='mono'>{test.type.toString() === TestType[TestType.YN] ? testResultsCorrectVerbiage + ' Answers' : 'Score'}</Text>
						</LegendBox>
						<LegendBox data-cy='correct-answers'>
							<Text size='medium'>{chartBlockModel?.testResult?.correctAnswers}/{test.maxScore}</Text>
						</LegendBox>
					</LegendItem>
					<LegendItem type='info'>
						<LegendBox>
							<Text size='small' font='mono'>Last Test Date</Text>
						</LegendBox>
						<LegendBox data-cy='last-test-date'>
							<Text size='medium' bold color='neutral24'>{formattedDate(chartBlockModel?.testResult)}</Text>
						</LegendBox>
					</LegendItem>
				</>
			}
		</BaseCard>
	);
}
