import {useMemo} from 'react';
import {RadioButtonsArrayType} from '../../../kit';
import {ViewFilterValue} from '../types';

export const useRadioButtonGroup = () =>
	useMemo<RadioButtonsArrayType<ViewFilterValue>>(
		() => [
			{label: 'All Students', value: ViewFilterValue.AllStudents},
			{
				label: 'Students without credentials',
				value: ViewFilterValue.StudentsWithoutCredentials,
			},
		],
		[],
	);
