export function openWindowWithPost(url, data) {
	let form = document.createElement('form');
	form.target = '_blank';
	form.method = 'POST';
	form.action = url;
	form.style.display = 'none';

	processData('', data, form);

	document.body.appendChild(form);
	form.submit();
	document.body.removeChild(form);
}

function processData(name, data, form) {
	if (Array.isArray(data)) {
		const array = data;
		for (let i = 0; i < array.length; i++) {
			processData(name + '[' + i + ']', array[i], form);
		}
	} else {
		if (data != null && typeof data === 'object') {
			const keys = Object.keys(data);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				processData((name != '' ? name + '.' : '') + key, data[key], form);
			}
		} else {
			createFormInput(name, data, form);
		}
	}
}

function createFormInput(name, value, form) {
	let input = document.createElement('input');
	input.type = 'hidden';
	input.name = name;
	input.value = value;
	form.appendChild(input);
}
