import {useCallback, useEffect, useMemo, useState} from 'react';
import {UserInfo, UserType} from '@esgi/core/authentication';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {CreateAssignmentTeacher} from './create-assignment-teacher';
import {CreateAssignmentSpecialist} from './create-assignment-specialist';
import {CreateAssignmentExtractedTeacherData} from './types/teacher-types';
import {CreateAssignmentExtractedSpecialistData} from './types/specialist-types';

type CreateAssignmentProps = {
	onSaved?: ((assignmentID?: number) => void) | undefined;
	assignmentID?: number | undefined;
	onCancel?: VoidFunction | undefined;
	goToAssignmentList?: VoidFunction | undefined;
	onGoBack?: VoidFunction | undefined;
	currentUser: Readonly<UserInfo>;
} & (
	| {
			userType: UserType.T;
			extractData?: ((data: CreateAssignmentExtractedTeacherData | null) => void) | undefined;
			extractedData?: CreateAssignmentExtractedTeacherData | null | undefined;
	  }
	| {
			userType: UserType.ISD | UserType.ISS;
			extractData?: ((data: CreateAssignmentExtractedSpecialistData | null) => void) | undefined;
			extractedData?: CreateAssignmentExtractedSpecialistData | null;
	  }
);

export function CreateAssignment({
	onGoBack,
	onSaved,
	onCancel,
	assignmentID,
	goToAssignmentList,
	currentUser,
	...props
}: CreateAssignmentProps) {
	const [step, setStep] = useState(1);
	const [loaded, setLoaded] = useState(false);

	const isEdit = useMemo(() => !!assignmentID, [assignmentID]);

	const onSetStep = useCallback(() => {
		if (isEdit) {
			setStep(2);
		}

		if (props.extractedData) {
			setStep(3);
		}
	}, []);

	useEffect(onSetStep, []);

	const isFirstStepValid = useCallback(
		(nameStatus: ElementStatus) => Boolean(nameStatus === ElementStatus.valid || isEdit || props.extractedData),
		[isEdit, props.extractedData],
	);

	const {userType} = props;

	const isTeacher = userType === UserType.T;
	const isSpecialist = userType === UserType.ISD || userType === UserType.ISS;

	if (isTeacher) {
		const {extractData, extractedData} = props;

		return (
			<CreateAssignmentTeacher
				extractData={extractData}
				extractedData={extractedData}
				currentUser={currentUser}
				loaded={loaded}
				onGoBack={onGoBack}
				setStep={setStep}
				step={step}
				isFirstStepValid={isFirstStepValid}
				onSetStep={onSetStep}
				setLoaded={setLoaded}
				isEdit={isEdit}
				assignmentID={assignmentID}
				goToAssignmentList={goToAssignmentList}
				onCancel={onCancel}
				onSaved={onSaved}
			/>
		);
	}

	if (isSpecialist) {
		const {extractData, extractedData} = props;

		return (
			<CreateAssignmentSpecialist
				extractData={extractData}
				extractedData={extractedData}
				currentUser={currentUser}
				loaded={loaded}
				onGoBack={onGoBack}
				setStep={setStep}
				step={step}
				isFirstStepValid={isFirstStepValid}
				onSetStep={onSetStep}
				setLoaded={setLoaded}
				isEdit={isEdit}
				assignmentID={assignmentID}
				goToAssignmentList={goToAssignmentList}
				onCancel={onCancel}
				onSaved={onSaved}
			/>
		);
	}

	throw new Error('Current user is not teacher or specialist');
}
