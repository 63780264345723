import {useSearchParams} from 'react-router-dom';
import {DrawerMode, DrawerSearchKey} from '../types';

export function useDrawerMode({drawerName}: {drawerName: string}) {
	const [searchParams] = useSearchParams();

	if (searchParams.get(DrawerSearchKey.DrawerName) === drawerName) {
		return searchParams.get(DrawerSearchKey.DrawerMode) as DrawerMode | null;
	}

	return null;
}
