import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useCallback} from 'react';

type Props = {
	onCloseAlert: VoidFunction;
	onDeleteGroup: VoidFunction;
	groupName: string;
};

export function DeleteGroupConfirmation({onCloseAlert, groupName, onDeleteGroup}: Props) {
	const alertRef = Alert.useRef();

	const closeAlert = Alert.useClose(alertRef, onCloseAlert);

	const deleteGroupAndCloseAlert = useCallback(() => {
		closeAlert();
		onDeleteGroup();
	}, [closeAlert, onDeleteGroup]);

	return (
		<Alert
			colorConfig={alertColorsConfig.negative}
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					maxWidth: 510,
				},
			}}
		>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Delete Group
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox gap='3'>
					<Text size='medium' color='neutral40'>
						Are you sure you want to delete this group {groupName}?
					</Text>
					<Text size='medium' color='neutral40'>
						Students in this group will not be deleted and can be reassigned to other groups. This action cannot be
						undone.
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={deleteGroupAndCloseAlert}>
						<Text size='medium' bold color='base'>
							Confirm
						</Text>
					</Button>
					<Button color='secondary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
