import {SettingsPanel} from '@esgi/main/kits/reports';
import {Wrapper, CheckboxGroup, Link} from './index.styled';
import {Info} from '@esgi/ui/icons';
import {groups} from './constants';
import {Checkbox} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {isUndefined} from '@esgi/ui';
import {ReportSettingsValue, ReportSettingValueChangeParameters} from '../../types';

type Props = {
	value: ReportSettingsValue;
	onSettingValueChange(args: ReportSettingValueChangeParameters): void;
};

export function SettingsControl({value, onSettingValueChange}: Props) {
	return (
		<SettingsPanel title='Settings'>
			{groups.map((group, index) => (
				<Wrapper key={index}>
					<CheckboxGroup>
						{group.map(({key, label, hintTo}) => (
							<GridBox flow='column' justify='between' align='center' key={key}>
								<Checkbox
									data-cy='settings-checkbox'
									label={label}
									checked={value[key]}
									onCheckedChange={(checked) => onSettingValueChange({key, newValue: Boolean(checked)})}
								/>
								{!isUndefined(hintTo) && (
									<Link to={hintTo} target='_blank' rel='noreferrer'>
										<Info />
									</Link>
								)}
							</GridBox>
						))}
					</CheckboxGroup>
				</Wrapper>
			))}
		</SettingsPanel>
	);
}
