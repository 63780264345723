import * as React from 'react';
import type {SVGProps} from 'react';

export function SelfAsses(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.4844 8.5C17.3874 8.5 15.6875 10.1792 15.6875 12.25C15.6875 14.3208 17.3874 16 19.4844 16C21.5813 16 23.2812 14.3208 23.2812 12.25C23.2812 10.1792 21.5813 8.5 19.4844 8.5ZM19.4844 10.1667C20.6493 10.1667 21.5938 11.0992 21.5938 12.25C21.5938 13.4008 20.6493 14.3333 19.4844 14.3333C18.3194 14.3333 17.375 13.4008 17.375 12.25C17.375 11.0992 18.3194 10.1667 19.4844 10.1667ZM16.584 17.12C15.0365 17.7508 14 19.2967 14 21V22.6667C14 23.1267 14.3777 23.5 14.8438 23.5H24.125C24.591 23.5 24.9688 23.1267 24.9688 22.6667V21C24.9688 19.3367 23.9265 17.8283 22.3848 17.1717C22.1678 17.0792 21.9149 17.0767 21.6992 17.1717C20.9527 17.5025 20.2254 17.6667 19.4844 17.6667C18.7459 17.6667 18.0161 17.4933 17.2432 17.1458C17.0307 17.05 16.7998 17.0317 16.584 17.12ZM16.9084 18.825C17.7056 19.1233 18.6667 19.3333 19.4844 19.3333C20.2956 19.3333 21.217 19.1375 21.9981 18.8525C22.7596 19.2808 23.2812 20.0825 23.2812 21V21.8333H15.6875V21C15.6875 20.0292 16.2409 19.2108 16.9084 18.825Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.0205 15.4956L13.4867 13.0294C13.7143 12.8018 13.7898 12.4796 13.7131 12.1893C13.6825 12.0181 13.6016 11.8538 13.4693 11.7215L11.0031 9.25529C10.6626 8.91478 10.1105 8.91478 9.76998 9.25529C9.42947 9.5958 9.42947 10.1479 9.76998 10.4884L10.8476 11.5666L6.87193 11.5755C6.39038 11.5755 6 11.9658 6 12.4474C6 12.929 6.39038 13.3193 6.87193 13.3193L10.7395 13.3104L9.78742 14.2625C9.44691 14.603 9.44691 15.1551 9.78742 15.4956C10.1279 15.8361 10.68 15.8361 11.0205 15.4956Z'
				fill='#333333'
			/>
		</svg>
	);
}
