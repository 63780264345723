import React from 'react';
import styles from './relaunch-test-session-modal.module.less';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';


interface RelaunchTestSessionModalProps {
	name: string;
	yesClicked: () => any;
	noClicked: () => any;
	cancelClicked: () => void;
}

 
export default class RelaunchTestSessionModal extends React.Component<RelaunchTestSessionModalProps> {
	private modalManagerRef: ModalManagerRefObject = React.createRef();

	render() { 
		return <Modal modalManagerRef={this.modalManagerRef}>
				<Modal.Header/>
				<Modal.Body>
					<div className={styles.bodyWrapper}>
						<span><strong>{this.props.name}</strong> has not completed their self-assessment test(s) on another device. Do you wish to take over their sessions on this device?</span>
						<br/>
						<span>
							<LinkButton className={styles.bodyButton} onClick={() => this.modalManagerRef.current.close(this.props.yesClicked)}>
								YES
							</LinkButton>- Take over their session from the other device
						</span>
						<br/>
						<span>
							<LinkButton className={styles.bodyButton} onClick={() => this.modalManagerRef.current.close(this.props.noClicked)}>
								NO
							</LinkButton>- Begin a new session
						</span>
						<br/>
						<span>
							<LinkButton className={styles.bodyButton} onClick={() => this.modalManagerRef.current.close(this.props.cancelClicked)}>
								CANCEL
							</LinkButton>- Close out test screen and select new student
						</span>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<LinkButton className={styles.button} onClick={() => this.modalManagerRef.current.close(this.props.cancelClicked)}>
						CANCEL
					</LinkButton>
					<LinkButton className={styles.button} onClick={() => this.modalManagerRef.current.close(this.props.noClicked)}>
						NO
					</LinkButton>
					<LinkButton className={styles.button} onClick={() => this.modalManagerRef.current.close(this.props.yesClicked)}>
						YES
					</LinkButton>
				</Modal.Footer>
			</Modal>;
	}
}
