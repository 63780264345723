import {Observable} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {SubjectInfo, TestsResponse, TestsSourceService} from '../../test-selector';
import {HierarchySnapshot} from '../../../hierarchy/core/models';

export class TestsSelectorSourceService extends BaseService implements TestsSourceService {
	constructor(private hierarchy: HierarchySnapshot) {
		super();
	}
	public fetchTests(subjects: SubjectInfo[]): Observable<TestsResponse> {
		return this.httpClient.ESGIApi.get<TestsResponse>('reports/student-detail', 'AvailableTestsFull', {
			subjects,
			hierarchy: this.hierarchy,
		}).asObservable();
	}
}
