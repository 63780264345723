import {BaseCell} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/total/models/base-cell';
import {TotalReportType} from '@esgi/contracts/esgi/types/esgi.reports//enums/total-report-type';
import {SubjectType} from '@esgi/core/enums';
import {ReportResult as ReportResultContract} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/total/models/report-result';
import {Row} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/total/models/row';

export {type Table} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/total/models/table';

export type Report = Omit<ReportResultContract, 'rows'> & {
	rows: Row[] | null;
}

export type SelectableListItem = {
	value: string,
	label: string,
}

export interface Cell extends BaseCell {
	percent: string;
	zeroIsMinimumLevel: boolean;
	avgPercent?: number | null;
}

export interface DownloadRequest {
	sourceID: string;
	subjectID: number;
	subjectType: SubjectType;
	tests: number[];
	reportID: number;
	reportType: TotalReportType;
	gradeLevelID?: number;
	displayZero: boolean;
	displayPercents: boolean;
	currentScoreOnly: boolean;
	rows: number[];
}

export enum MarkingPeriod {
	All,
	Current,
}

export enum NotTested {
	NT,
	Zero,
}

export enum DisplayResults {
	Score,
	Percent,
}

export type ChangeFunction<T> = (value: T) => void;

export * from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/storage/subjects/test';

export * from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/pages/home/sections/subjects/init/subject-tab';
export * from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/groups/group';
export * from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/classes/class';
export * from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/pages/assignments/subjects-info/subject';

export * from '@esgi/contracts/esgi/types/esgi.reports/enums/total-report-type';
export * from '@esgi/contracts/esgi/types//esgi.apigateway/api/controllers/v2/teachers/pages/reports/totals/get-report-request';
export * from '@esgi/contracts/esgi/types//esgi.apigateway/api/controllers/v2/teachers/pages/reports/totals/carry-scores-forward-request';
export * from '@esgi/contracts/esgi/types//esgi.apigateway/api/controllers/v2/teachers/pages/reports/totals/rebuild-request';
export * from '@esgi/contracts/esgi/types//esgi.apigateway/api/controllers/v2/teachers/pages/reports/totals/close-request';
export * from '@esgi/contracts/esgi/types//esgi.apigateway/api/controllers/v2/teachers/pages/reports/totals/tests-request';

export * from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/total/models/test-model';
export * from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/total/models/row';