import React, {useState} from 'react';
import styles from './styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import StudentProgressReportService
	from 'modules/reports/student-progress-report/services/student-progress-report-service';

interface Props {
	service: StudentProgressReportService
	onGoBack: () => void;
	onClose: () => void;
}

export function SettingsModalHeader({onGoBack, onClose, service}: Props) {
	const report = useBehaviorSubject(service.report);
	const displayDownloadLink = report && report.students.length > 0;
	const [showMenu, setShowMenu] = useState(false);
	const closeMenu = () => setShowMenu(false);
	return <div className={styles.sdrReportHeader}>
		<span className={styles.btnBack} onClick={onGoBack}>
			<i className={join(styles.fa, styles.faAngleLeft, 'fa', 'fa-angle-left')}/>
			<span data-cy='spr-back'>Back</span>
		</span>
		<div className={styles.reportName}>Student Progress Report</div>
		<div className={styles.printDownloadButtons}>
			{displayDownloadLink && <div className={join(styles.download, 'btn-group')}>
				<div onClick={() => setShowMenu(!showMenu)}>
					<i className={join(styles.fa, 'fa', 'fa-download')}/> Download
				</div>
				<Menu show={showMenu} keepFocusInside onClickOutside={closeMenu} onEscPressed={closeMenu}
				      className={styles.menu}>
					<MenuItem onSelect={() => service.downloadPdf(false)}>
							<span>
								<div>One PDF file</div>
								<div>per student or class</div>
							</span>
					</MenuItem>
					<MenuItem onSelect={() => service.downloadPdf(true)}>
							<span>
								<div>ZIP</div>
								<div>Individual PDF files for each student in the class</div>
							</span>
					</MenuItem>
				</Menu>
			</div>}
			<CloseIcon onClick={onClose} color='white'/>
		</div>
	</div>;
}
