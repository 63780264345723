import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const SignUpImpl = lazyComponent(() => import('pages/landing/sign-up'));

export default function SignUp() {
	return <RouteWrapper title='Sign up'>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<SignUpImpl/>
		</Suspense>
	</RouteWrapper>;
}
