import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useState} from 'react';
import {TrackModel} from '../../../../../../../types';
import {PotentialDataImpactAlert} from '../../../../../components/potential-data-impact';

type Props = {
	activeUserTrackId: TrackModel['trackID'];
	currentUserTrackId: TrackModel['trackID'];
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
};

export function SetActivePeriodButton({activeUserTrackId, currentUserTrackId, setActiveTrackId}: Props) {
	const [isPotentialDataImpactOpen, setIsPotentialDataImpactOpen] = useState(false);

	return (
		<>
			<Button
				data-cy='set-active-period-button'
				color='secondary'
				disabled={activeUserTrackId === currentUserTrackId}
				onClick={() => setIsPotentialDataImpactOpen(true)}
			>
				<Text size='medium' bold>
					Set as Active
				</Text>
			</Button>

			{isPotentialDataImpactOpen && (
				<PotentialDataImpactAlert
					onClose={() => setIsPotentialDataImpactOpen(false)}
					onProceed={() => setActiveTrackId(currentUserTrackId)}
				/>
			)}
		</>
	);
}
