import {tap} from 'rxjs/operators';
import {AssignmentCardService} from './assignment-card-service';
import {AssignmentSpecialistModel} from '../types/specialist-types';

export class AssignmentCardSpecialistService extends AssignmentCardService {
	private readonly controllerViewSpecialist = 'assignments/view/specialist';

	public init(assignmentID: number) {
		this.assignmentID = assignmentID;
		return this.httpClient.ESGIApi.get<{
			assignment: AssignmentSpecialistModel | null;
		}>(this.controllerViewSpecialist, 'info', {assignmentID})
			.pipe(
				tap((r) => {
					if (r.assignment?.state) {
						this.status.next(r.assignment.state);
					}
				}),
			)
			.asObservable();
	}
}
