import {useEffect, useMemo, useState} from 'react';
import {useGroupsContentState} from './use-groups-content-state';
import {AllOptionItem} from './types';
import {StudentsPanelTabID} from '../../../types';
import {Context, GroupsContextValue} from './context';
import {TabSectionProps} from '../../types';
import {useSortStudents} from '../../hooks/use-sort-students-data';
import {MultiTabPanel} from '../../multi-tab-panel';
import {useStudentsPanelContext} from '../../../context';
import {StudentSort, useUser} from '@esgi/core/authentication';
import {useEventEffect} from '@esgillc/events';
import {ChangeSortBy} from '@esgi/main/features/teacher/events';

export {AllOptionItem, type GroupBucket} from './types';

export function GroupsTabContent({
	sectionTitle = 'Groups',
	description = '',
	children,
	...restTabProps
}: TabSectionProps<StudentsPanelTabID.Groups>) {
	const {
		entities: {groups, students: allStudents},
	} = useStudentsPanelContext();
	const user = useUser();

	const [selectedClassId, setSelectedClassId] = useState<number | AllOptionItem.Value>(AllOptionItem.Value);
	const [sortByData, setSortByData] = useState<StudentSort | undefined>();

	useEventEffect(ChangeSortBy, ({sortBy}) => {
		return setSortByData(sortBy);
	});

	useEffect(()=>{
		const sortBy = user?.studentSort;
		setSortByData(sortBy);
	}, [user]);

	const {studentsWithoutGroup, groupsWithStudents} = useGroupsContentState({
		selectedClassId,
		allStudents,
		groups,
		sortByData,
	});

	const sortedStudentsWithoutGroup = useSortStudents(studentsWithoutGroup);


	const contextValue = useMemo<GroupsContextValue>(() => {
		return {
			selectedClassID: selectedClassId,
			setSelectedClassID: setSelectedClassId,
			studentsWithoutGroup: sortedStudentsWithoutGroup,
			groupsWithStudents: groupsWithStudents,
		};
	}, [groupsWithStudents, selectedClassId, sortedStudentsWithoutGroup]);

	return (
		<MultiTabPanel.TabContent
			id={StudentsPanelTabID.Groups}
			name={sectionTitle}
			description={description}
			{...restTabProps}
		>
			<Context.Provider value={contextValue}>{children}</Context.Provider>
		</MultiTabPanel.TabContent>
	);
}
