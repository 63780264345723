import {NotTestedAnswer} from '@esgi/ui/icons';
import {IconBox} from './index.styled';

export function NotTestedAnswerIcon() {
	return (
		<IconBox dataCy='nottested-answer-icon'>
			<NotTestedAnswer />
		</IconBox>
	);
}
