import {HttpClient} from '@esgi/api';
import {
	IInitResponse,
	IUpdateModel,
} from 'shared/modules/grade-scale/grade-range/forms/default/models';

export class Api {
	private static controllerName = 'reports/grade-scales/grade-range-default';

	static init(gradeScaleID?: number) {
		return HttpClient.default.ESGIApi.get<IInitResponse>(this.controllerName, 'init', {gradeScaleID: gradeScaleID});
	}

	static update(model: IUpdateModel) {
		return HttpClient.default.ESGIApi.post(this.controllerName, 'update', model);
	}
}
