import {Avatar} from '@esgi/ui/avatar';
import {Student} from '@esgi/main/libs/store';
import {getInitials} from '@esgi/main/features/teacher/home';
import {Text} from '@esgi/ui/typography';
import {AvatarWrapper} from './index.styled';

interface Props extends Pick<Student, 'firstName' | 'lastName' | 'photoUrl'> {}

export function StudentAvatar(props: Props) {
	const {firstName, lastName, photoUrl} = props;

	return (
		<AvatarWrapper data-cy='single-student-panel' flow='row' align='center'>
			<Avatar.Root size='sm'>
				<Avatar.Image src={photoUrl}/>
				<Avatar.Fallback>
					{getInitials({firstName, lastName})}
				</Avatar.Fallback>
			</Avatar.Root>
			<Text data-cy='single-student-name' color='neutral56'>{firstName} {lastName}</Text>
		</AvatarWrapper>
	);
}
