import {BaseService} from '@esgi/core/service';
import moment from 'moment';
import {BehaviorSubject} from 'rxjs';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {Init, InitModel, SortBy, Test} from '../models';
import {
	RequestResultsInit,
	RequestSettingsFetchHierarchy,
	RequestSettingsFetchSessionHierarchy,
	RequestSettingsFetchSubjectSessionHierarchy,
	RequestSettingsInit,
	ResponseFetchHierarchy,
	ResponseSettingsInit,
} from '../api-models';
import {map, takeUntil} from 'rxjs/operators';
import TestRow from './test-row';
import {
	makeCacheKeyBase,
	makeSessionHierarchy,
	prepareTestSessionRequest,
	selectedTestsFilter,
	sortTestsBy,
} from '../utils';
import SettingsService from './settings-service';
import {SettingsFormApiActions, SettingsFormApiHost} from '../constants';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';

export default class FormService extends BaseService {
	public viewParentLetterDisabled = new BehaviorSubject<boolean>(false);
	public settingsService: SettingsService;
	public tests = new BehaviorSubject<TestRow[]>([]);
	public model = new BehaviorSubject<Init>(null);
	public sortBy = new BehaviorSubject<SortBy>(SortBy.TestOrder);
	public testResultsCorrectVerbiage: string;
	public testResultsIncorrectVerbiage: string;
	public selectedDate = new BehaviorSubject<string>('');
	private testsCache: { [key: string]: Test[] } = {};
	private	 defaultFormat: string = 'MM-DD-YY';

	constructor(public hierarchy: HierarchySnapshot, public changesCollector: ChangesCollector, public selectedItems?: InitModel) {
		super();
		this.settingsService = new SettingsService(changesCollector, hierarchy, this.reloadTests.bind(this));
		this.tests.subscribe((tests => {
			this.viewParentLetterDisabled.next(selectedTestsFilter(tests, this.settingsService.includeSessionOption.value).length === 0);
		}));
		this.settingsService.includeUntestedQuestionsOption.pipe(takeUntil(this.destroy$)).subscribe(s => this.untestedOptionChanged(s));
	}

	public get selectedTests(): { testID: number, max: number, noData: boolean, answersCount: number }[] {
		return selectedTestsFilter(this.tests.value, this.settingsService.includeSessionOption.value);
	}

	public makeReportModel(): RequestResultsInit {
		return this.settingsService.makeResultModel(this.tests.value, this.model.value);
	}

	public init() {
		const requestModel = (this.selectedItems)
			? new RequestSettingsInit(this.selectedItems.selectedGroupItemID, this.selectedItems.selectedStudentID, this.selectedItems.subjectID, this.selectedItems.subjectType, this.hierarchy)
			: null;

		return this.httpClient.ESGIApi.get<ResponseSettingsInit>(SettingsFormApiHost, SettingsFormApiActions.settings, requestModel).pipe(map(r => {
			this.model.next({...Init.FromResponse(r), selectedSubjectId: requestModel.subjectID});

			if (this.settingsService.initModel(this.model.value)) {
				const cacheKey = this.getCacheKeyBase(r.selectedGroupItemID, r.selectedStudentID, requestModel.subjectID);
				this.testsCache = ({...this.testsCache, [cacheKey]: Test.FromResponseArray(r.tests)});
				this.reloadTests();
			}
		}));
	}

	public getCache() {
		return this.testsCache[this.getCacheKey()];
	}

	public fetchHierarchy(requestData: RequestSettingsFetchHierarchy) {
		const cacheKey = this.getCacheKey();

		return this.httpClient.ESGIApi.post<ResponseFetchHierarchy>(SettingsFormApiHost, SettingsFormApiActions.settingsFetchHierarchy, requestData).pipe(map(r => {
			this.testsCache = {...this.testsCache, [cacheKey]: Test.FromResponseArray(r.tests)};
		}));
	}

	public mpChanged(id: number, questionsCount: number, newDate: string) {
		this.changeTestDate(id, newDate);
		this.selectedDate.next(null);
		this.reloadTest({id, date: newDate, questionsCount});
	}

	public changeTestDate(testId: number, newDate: string) {
		this.tests.next(this.tests.value.map(t => {
			t.currentDate = t.id === testId ? newDate : t.currentDate;
			return t;
		}));
	}

	public togglePrintAll(testId: number) {
		this.tests.next(this.tests.value.map(t => {
			if (testId === t.id) {
				t.printAllItems = !t.printAllItems;
			}
			return t;
		}));
	}

	public changePrintAmount(value: number, testId: number) {
		this.tests.next(this.tests.value.map(t => {
			if (testId === t.id) {
				t.printAmount = value;
			}
			return t;
		}));
	}

	public toggleSelectedTest(testId: number) {
		this.tests.next(this.tests.value.map(t => {
			if (testId === t.id) {
				t.selected = !t.selected;
			}
			return t;
		}));
	}

	public selectAllToggle(val: boolean) {
		this.tests.next(this.tests.value.map(t => {
			if (!val || !t.noData) {
				t.selected = val;
			}
			return t;
		}));
	}

	public setSortBy(sortByStr: string) {
		const newSorting = SortBy[sortByStr];
		this.sortBy.next(newSorting);
		this.tests.next(sortTestsBy(newSorting, [...this.tests.value]));
	}

	public fetchSessionHierarchy(requestData: RequestSettingsFetchSessionHierarchy) {
		const cacheKey: string = Object.keys(this.testsCache).find(key => !!this.testsCache[key].find(test => test.id === requestData.tests[0]?.testID));
		const tests = this.testsCache[cacheKey] || this.testsCache[Object.keys(this.testsCache)[0]];

		return this.httpClient.ESGIApi.post<ResponseFetchHierarchy>(SettingsFormApiHost, SettingsFormApiActions.settingsFetchSessionHierarchy, requestData).pipe(map(r => {
			const updatedTests = [];
			const responseTests = Test.FromResponseArray(r.tests);
			tests.forEach(t => {
				const foundTest = responseTests.find(rt => rt.id === t.id);
				if (!foundTest) {
					return;
				}
				updatedTests.push({
					...t,
					correct: foundTest.correct,
					incorrect: foundTest.incorrect,
					untested: foundTest.untested,
				});
			});
			this.testsCache[this.getSessionCacheKey()] = updatedTests;
		}));
	}


	public fetchSubjectSessionHierarchy(requestData: RequestSettingsFetchSubjectSessionHierarchy) {
		return this.httpClient.ESGIApi.post<ResponseFetchHierarchy>(SettingsFormApiHost, 'settings-fetch-subject-session-hierarchy', requestData).pipe(map(r => {
			this.testsCache = ({
				...this.testsCache,
				[this.getSessionCacheKey()]: Test.FromResponseArray(r.tests),
			});
		}));
	}

	public fetchTestSession(requestData: RequestSettingsFetchSessionHierarchy) {
		return this.httpClient.ESGIApi.post<ResponseFetchHierarchy>(SettingsFormApiHost, 'settings-fetch-session-hierarchy', requestData).pipe(map(r => {
			const idx = this.tests.value.findIndex(t => t.id === r.tests[0].id);
			const updateTests = [...this.tests.value];
			const responseTest = Test.FromResponseArray(r.tests)[0];
			updateTests[idx].untested = responseTest.untested;
			updateTests[idx].incorrect = responseTest.incorrect;
			updateTests[idx].correct = responseTest.correct;
			updateTests[idx].setValidationRange(this.settingsService.includeUntestedQuestionsOption.value, true);
			this.tests.next(updateTests);
		}));
	}

	public untestedOptionChanged(includeUntested: boolean) {
		const copy = [...this.tests.value];
		copy.forEach((test, index) => {
			test.setValidationRange(includeUntested);
		});
		this.tests.next(copy);
	}

	public updateDate(newDate: string) {
		const newVal = moment(newDate.replace(/[-]/g, '/')).format(this.defaultFormat);
		this.selectedDate.next(newVal);
		this.tests.next(this.tests.value.map(t => {
			t.currentDate = t.noData ? t.currentDate : newVal;
			return t;
		}));
		this.reloadTests();
	}

	public reloadTests(subjectChanged = false) {
		const cacheKey = this.getCacheKey();
		if (this.testsCache[cacheKey]) {
			this.initValidations();
		} else {
			if (this.settingsService.includeSessionOption.value) {
				this.reloadSessionHierarchy(subjectChanged);
			} else {
				this.reloadHierarchy();
			}
		}
	}

	private reloadSessionHierarchy(subjectChanged = false) {
		if (subjectChanged) {
			const testDate = moment(this.selectedDate.value || moment()).toISOString();
			const sessionSubjectHierarchy: RequestSettingsFetchSubjectSessionHierarchy = {
				...this.getHierarchy(),
				testDate: testDate,
			};
			this.fetchSubjectSessionHierarchy(sessionSubjectHierarchy).subscribe(() => {
				this.hierarchyLoaded();
			});
		} else {
			const sessionHierarchy = this.getSessionHierarchy();
			this.fetchSessionHierarchy(sessionHierarchy).subscribe(() => {
				this.hierarchyLoaded();
			});
		}
	}

	private reloadHierarchy() {
		const hierarchy = this.getHierarchy();
		this.fetchHierarchy(hierarchy).subscribe(() => {
			this.hierarchyLoaded();
		});
	}

	private hierarchyLoaded() {
		this.initValidations();
	}

	private reloadTest(test: { id: number, date: string, questionsCount: number }) {
		test.date = moment(test.date, 'MM-DD-YYYY HH:mm').format();
		const request = this.getTestSessionRequest(test);
		this.fetchTestSession(request).subscribe();
	}

	private getTestSessionRequest(test: { id: number, date: string, questionsCount: number }): RequestSettingsFetchSessionHierarchy {
		return prepareTestSessionRequest(test, this.settingsService.selectedStudent.value, this.settingsService.selectedGroupItem.value);
	}

	private initValidations() {
		const cacheKey = this.getCacheKey();
		const tests = this.testsCache[cacheKey];
		const copy = tests.map(t => {
			const testDate = this.selectedDate.value || null;
			const row = new TestRow(t, this.settingsService.selectedStudent.value.studentId === 0, this.model.value.tracks, testDate);
			row.setValidationRange(this.settingsService.includeUntestedQuestionsOption.value, true);
			return row;
		});
		this.tests.next(sortTestsBy(this.sortBy.value, copy));
	}

	private getCacheKey() {
		return this.settingsService.includeSessionOption.value && this.getSessionCacheKey() || this.getCacheKeyBase();
	}

	private getCacheKeyBase(classId?: number, studentId?: number, subjectId?: number) {
		return makeCacheKeyBase(this.settingsService.selectedGroupItem.value, this.settingsService.selectedStudent.value, this.settingsService.selectedSubject.value, classId, studentId, subjectId);
	}

	private getSessionCacheKey() {
		let key = this.getCacheKeyBase();
		this.tests.value.forEach(t => key += '_' + t.id + ':' + t.currentDate);
		return key;
	}

	private getHierarchy(): RequestSettingsFetchHierarchy {
		const result = new RequestSettingsFetchHierarchy();
		const fetchHierarchy = result as RequestSettingsFetchHierarchy;
		fetchHierarchy.subjectID = this.settingsService.selectedSubject.value?.id;
		fetchHierarchy.subjectType = this.settingsService.selectedSubject.value?.subjectType;


		result.students = [];

		if (this.settingsService.selectedStudent.value.studentId === 0) {
			this.settingsService.selectedGroupItem.value.students.forEach((student) => {
				result.students.push({studentID: student.studentId, gradeLevelID: student.gradeLevelId});
			});
		} else {
			result.students.push({
				studentID: this.settingsService.selectedStudent.value.studentId,
				gradeLevelID: this.settingsService.selectedStudent.value.gradeLevelId,
			});
		}
		result.hierarchy = this.hierarchy;

		return result;
	}

	private getSessionHierarchy(): RequestSettingsFetchSessionHierarchy {
		return makeSessionHierarchy(this.tests.value, this.settingsService.selectedStudent.value, this.settingsService.selectedGroupItem.value);
	}
}
