import {styled} from '@esgi/ui/theme';
import {alertContentInlinePadding} from '../../constants';
import {Box} from '../../../layout/box';
import {GridBox} from '../../../layout/grid-box';
import {Text} from '@esgi/ui/typography';
import {AlertCloseIcon} from '../alert-close-icon';

export const HeaderBox = styled(GridBox, {
	alignItems: 'center',
	position: 'relative',

	'&::after': {
		content: '',
		width: '100%',
		height: '100%',
		background: 'repeating-linear-gradient(45deg, $vivid, $vivid 3px, transparent 0, transparent 10px)',
		gridColumnStart: 1,
		gridColumnEnd: -1,
		gridRowStart: 1,
		gridRowEnd: 1,
	},
});

export const ContentWrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	gap: 12,
	alignItems: 'center',
	gridColumnStart: 1,
	gridColumnEnd: -1,
	gridRowStart: 1,
	gridRowEnd: 1,
	zIndex: 2,
	paddingLeft: alertContentInlinePadding,
	paddingRight: alertContentInlinePadding,
	paddingTop: 12,
	paddingBottom: 12,
});

export const ChildrenContentWrapper = styled(Box, {
	[`& > ${Text}`]: {
		color: 'currentColor',
	},
});

export const AlertCloseButton = styled(AlertCloseIcon, {
	alignSelf: 'baseline',
});

export const Backlight = styled(Box, {
	height: 1,
	borderRadius: 1,
	marginLeft: alertContentInlinePadding,
});
