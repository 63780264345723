import {Buttons} from '@esgillc/ui-kit/button';
import {
	HelpLink,
	Modal,
	Title,
	useCloseModal,
	useModal,
} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import moment from 'moment';
import {useMemo, useState} from 'react';
import {DatePicker, IFormControlValidatorResult} from '@esgi/deprecated/elements';
import {IEPGoalFormService} from '../../service';

import styles from './styles.module.less';

interface Props {
	service: IEPGoalFormService;
	onClose: () => void;
}

export default function IepDatesFormModal({service, onClose}: Props): JSX.Element {
	const modal = useModal();
	const close = useCloseModal(modal, onClose);
	const iepDates = useBehaviorSubject(service.iepDates$);
	const [startDate, setStartDate] = useState(iepDates.startDate ? moment(iepDates.startDate) : undefined);
	const [endDate, setEndDate] = useState(iepDates.endDate ? moment(iepDates.endDate) : moment().add(1, 'y'));

	const validateStartDate = (date: moment.Moment): IFormControlValidatorResult => {
		const message: string = 'Please enter start date';
		let valid: boolean = true;

		if (startDate && !date.isValid()) {
			valid = false;
		}

		return {valid, message} as IFormControlValidatorResult;
	};

	const validateEndDate = (date: moment.Moment): IFormControlValidatorResult => {
		let message: string = 'Please enter end date';
		let valid: boolean = true;

		if (endDate) {
			if (!date.isValid()) {
				valid = false;
			}

			if (date.diff(startDate, 'days') <=0) {
				valid = false;
				message = 'The end date should be greater than the start date';
			}
		}

		return {valid, message} as IFormControlValidatorResult;
	};

	const dateRangeIsValid = useMemo(() => {
		return validateStartDate(startDate).valid && validateEndDate(endDate).valid;
	}, [startDate, endDate]);

	const onSave = () => {
		const start = startDate.toDate();
		const end = endDate.toDate();
		if (!iepDates.startDate && !iepDates.endDate) {
			service.createIEPDates(start, end, close);
		} else {
			service.updateIEPDates(start, end, close);
		}
	};

	return (
		<Modal onCatchError={close} modalManagerRef={modal}>
			<Modal.Header>
				<Title className={styles.headerText}>
					<span>IEP Dates</span>
					<HelpLink url={'https://support.esgisoftware.com/hc/en-us/categories/201288126-ESGI'}/>
				</Title>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<p className={styles.studentName}>Student: <span>{service.student.fullName}</span></p>
				<div className={styles.datesContainer}>
					<div className={styles.dateContainer}>
						<label className={styles.dateLabel} htmlFor='start-date-picker'>From</label>
						<DatePicker
								id='start-date-picker'
								date={moment(startDate)}
								validator={value => validateStartDate(value)}
								validationPlacement='bottom'
								onSelect={(d) => {
									setStartDate(d);
									moment().add(1, 'y');
								}}
						/>
					</div>
					<div className={styles.dateContainer}>
						<label className={styles.dateLabel} htmlFor='end-date-picker'>To</label>
						<DatePicker
								id='end-date-picker'
								date={moment(endDate)}
								validator={(value) => validateEndDate(value)}
								validationPlacement='bottom'
								onSelect={(d) => setEndDate(d)}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray className={styles.cancelBtn} onClick={close}>
					CANCEL
				</Buttons.Gray>
				<Buttons.Contained onClick={onSave} disabled={!dateRangeIsValid}>
					SAVE
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	);
}
