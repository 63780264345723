import {SubjectTab as SubjectTabContract} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/teachers/storage/subjects/subject-tab';

export enum SubjectType {
	Personal = 'Personal',
	Stock = 'Stock',
	Deployed = 'Deployed',
}

export enum SubjectLevel {
	Teacher = 'Teacher',
	School = 'School',
	District = 'District',
}

export type SubjectTab = Omit<SubjectTabContract, 'level' | 'type'> & {
	type: SubjectType;
	level: SubjectLevel;
};
