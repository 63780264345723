import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const StyledAvatarHoverLayer = styled(FlexBox, {
	width: '100%',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	position: 'absolute',
	background: 'none',
	gap: '4px',
	opacity: '0',

	'&:hover': {
		transition: '.5s ease',
		background: '$secondaryBackground',
		opacity: '88%',
	},
});
