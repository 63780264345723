import {Root, IconWrapper, Text} from './index.styled';
import {Divider, Header} from '../../../../../layout/index.styled';
import {GridBox} from '@esgi/ui/layout';
import {Users} from '@esgi/ui';
import {Reports, Test} from '@esgi/main/kits/reports';
import {SelectableListItem} from '../../service/types';
import {SkeletonShape} from '@esgi/main/kits/common';
import {TestList} from './components/test-list';
import {SubjectTab} from '@esgi/main/libs/store';

type Props = {
	selectedEntity: SelectableListItem,
	subjects: SubjectTab[],
	isSubjectsLoaded: boolean,
	onSubjectSelect: (value: number) => void;
	selectedSubject: string;
	tests: Test[]
	isTestsLoading: boolean;
	onTestSelect: (value: number[]) => void;
	setShowAddTestsDrawer: VoidFunction;
}

export function TestSelectionPanel({
	selectedEntity,
	subjects,
	isSubjectsLoaded,
	selectedSubject,
	onSubjectSelect,
	tests,
	isTestsLoading,
	onTestSelect,
	setShowAddTestsDrawer,
}: Props) {
	return(
		<Root>
			<Header>
				<GridBox gap='3' align='center' flow='column'>
					{selectedEntity ? (
						<>
							<IconWrapper>
								<Users />
							</IconWrapper>
							<Text color='neutral56' size='small'>{selectedEntity?.label}</Text>
						</> ) : (
						<>
							<SkeletonShape width={40} height={40}/>
							<SkeletonShape width={100} height={12}/>
						</>
					)}
				</GridBox>
				<Divider/>
				<Reports.SubjectControl
					skeleton={!isSubjectsLoaded}
					subjects={subjects}
					value={selectedSubject}
					onChange={value => onSubjectSelect(+value)}
				/>
			</Header>
			<TestList
				isTestsLoading={isTestsLoading}
				tests={tests}
				onTestSelect={onTestSelect}
				setShowAddTestsDrawer={setShowAddTestsDrawer}
			/>
		</Root>
	);
}