import styles from '../../styles.module.less';
import React, {RefObject, useEffect, useMemo, useState} from 'react';
import {SettingsFormAlerts} from '../../../../../constants';
import {makeShowNoTestsAlert, selectedTestsFilter} from '../../../../../utils';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import FormService from '../../../../../services/form-service';

interface Props {
	service: FormService
	tableRef: RefObject<HTMLTableElement>
}

export function Alerts({service, tableRef}: Props) {
	const includeSessionOption = useBehaviorSubject(service.settingsService.includeSessionOption);
	const isInit = useBehaviorSubject(service.settingsService.isInit);
	const students = useBehaviorSubject(service.settingsService.students);
	const tests = useBehaviorSubject(service.tests);
	const selectedTests = useMemo(() => selectedTestsFilter(tests, includeSessionOption), [tests, includeSessionOption]);
	const showNoStudentsAlert = useMemo(() => isInit && students.length === 1, [students, isInit]); // Only 'All' option exists
	const showNoTestsAlert = makeShowNoTestsAlert(showNoStudentsAlert, isInit, service.getCache());
	const [height, setHeight] = useState(0);

	useEffect(() => {
		setHeight(tableRef.current?.clientHeight - 50 || 0);
	}, [tableRef.current]);
	const showNoDataAlert = useMemo(() => {
		if (showNoStudentsAlert || showNoTestsAlert) {
			return false;
		}
		return isInit && !showNoTestsAlert && selectedTests.length === 0;
	}, [showNoStudentsAlert, showNoTestsAlert, isInit, selectedTests.length]);
	const dataAlertText = useMemo(() => {
		if (includeSessionOption) {
			return SettingsFormAlerts.noDataByDateAlert;
		}
		return SettingsFormAlerts.noValidDataAlert;
	}, [includeSessionOption]);

	const getAlertStyle = () => ({height});

	return <>
		{showNoTestsAlert && <>
			<div className={styles.noTestsAlert} style={getAlertStyle()}>
                                    <span>
                                    No tests. Please select another Subject.
                                    </span>
			</div>
		</>}
		{showNoStudentsAlert && <>
			<div className={styles.noTestsAlert} style={getAlertStyle()}>
                                    <span>
                                        No students/data to display for that class/subject/group.
                                    </span>
			</div>
		</>}
		{showNoDataAlert && <>
			<div className={styles.noDataAlert} style={getAlertStyle()}>
				<span>{dataAlertText}</span>
			</div>
		</>}
	</>;
}
