import {useState, useCallback, useMemo} from 'react';
import {LangTypeOption} from '../../../model';

export function useLangTypes() {
	const [selectedLangTypes, setSelectedLangTypes] = useState<LangTypeOption[]>(
		[]
	);
	const selectedLangTypeValues = useMemo(
		() => selectedLangTypes.map(({value}) => value),
		[selectedLangTypes]
	);

	const toggleLangType = useCallback(
		(langType: LangTypeOption) => {
			if (selectedLangTypeValues.includes(langType.value)) {
				setSelectedLangTypes(
					selectedLangTypes.filter(({value}) => value !== langType.value)
				);
			} else {
				setSelectedLangTypes([...selectedLangTypes, langType]);
			}
		},
		[selectedLangTypeValues, selectedLangTypes, setSelectedLangTypes]
	);

	const clearSelectedLangTypes = useCallback(() => {
		setSelectedLangTypes([]);
	}, []);

	return {
		selectedLangTypes,
		setSelectedLangTypes,
		toggleLangType,
		clearSelectedLangTypes,
	};
}
