import {useEffect, useRef, useState} from 'react';
import {EventBusManager} from '@esgillc/events';
import {Buttons} from '@esgillc/ui-kit/button';
import {ID} from '../conflict-controls/id';
import {Language} from '../conflict-controls/language';
import {Name} from '../conflict-controls/name';
import {StudentRosterToolEvents} from 'shared/modules/student-roster-upload-tool/events';
import {
	Validation,
	ConflictStudentField,
	ConflictStudentModel,
} from 'shared/modules/student-roster-upload-tool/types';
import {ArrowIcon} from 'shared/modules/student-roster-upload-tool/icons/arrow-icon';
import {BucketIcon} from 'shared/modules/student-roster-upload-tool/icons/bucket-icon';
import {StudentHeader} from '../student-header';
import {ExistingStudentRow} from '../existing-student-row';
import styles from './styles.module.less';

interface Props {
	index: number;
	student: ConflictStudentModel;
	onEdit: (student: ConflictStudentModel, validation: Validation) => void;
	onClick: (validation: Validation) => void;
	onView: (id: number) => void;
	onRemove: () => void;
	tableHeight: number;
	tableOffset: number;
	scrolled: (offset: number) => void;
}

export function StudentRow({
	                           index,
	                           student,
	                           onClick,
	                           onEdit,
	                           onView,
	                           onRemove,
	                           tableOffset,
	                           tableHeight,
	                           scrolled,
                           }: Props) {
	const [isRowOpened, setRowOpened] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>();
	const eventBus = new EventBusManager();
	const existingStudentsExist = student?.existingStudents?.length > 0;

	useEffect(() => {
		setRowOpened(existingStudentsExist);
		eventBus.subscribe(StudentRosterToolEvents.MoveToErrorRow, (args: StudentRosterToolEvents.MoveToErrorRow.Args) => {
			if (ref.current && args.studentID === student.id) {
				scrolled(ref.current.offsetTop);
			}
		});
	}, []);

	const toggleExistingStudent = () => {
		setRowOpened(prev => !prev);
	};

	return <div data-cy={'student-row'} className={styles.studentRow} ref={ref}>
		<div className={styles.uploadedStudent}>
			<StudentHeader/>

			<div className={styles.studentContent}>
				<div onClick={toggleExistingStudent} className={styles.arrowContainer}>
					{existingStudentsExist && <ArrowIcon className={styles.arrowIcon} rotate={isRowOpened ? 0 : 180}/>}
				</div>

				<div className={styles.circleContainer}>
					<div data-cy={'index'} className={styles.circleCount}>{index}</div>
				</div>

				<div data-cy={'name'} className={styles.name}>
					<Name
						student={student}
						existingStudentNames={student.existingStudents?.map(s => s.name)}
						uploadedStudentName={student.name.value}
						validation={student.name.validation}
						tableHeight={tableHeight}
						tableOffset={tableOffset}
						onEdit={(value, validation) => {
							const st = {...student};
							st.name = new ConflictStudentField(value, validation, true);
							onEdit(st, validation);
						}}
						onClick={onClick}
					/>
				</div>
				<div data-cy={'id'} className={styles.id}>
					<ID
						student={student}
						existingStudentIDs={student.existingStudents?.filter(s => !!s.studentIDN).map(s => s.studentIDN)}
						uploadedStudentID={student.studentIDN.value}
						validation={student.studentIDN.validation}
						tableHeight={tableHeight}
						tableOffset={tableOffset}
						onEdit={(value, validation) => {
							const st = {...student};
							st.studentIDN = new ConflictStudentField(value, validation, true);
							onEdit(st, validation);
						}}
						onClick={onClick}
					/></div>
				<div data-cy={'grade'} className={styles.grade}>{student.gradeLevel}</div>
				<div className={styles.language}>
					<Language
						student={student}
						uploadedStudentLanguage={student.language.value}
						validation={student.language.validation}
						onEdit={(value, validation) => {
							const st = {...student};
							st.language = new ConflictStudentField(value, validation, true);
							onEdit(st, validation);
						}}
						onClick={onClick}
					/>
				</div>
				<div data-cy={'create-date'} className={styles.date}>{student.createDate}</div>
				<div data-cy={'remove'} className={styles.action} onClick={() => onRemove()}>
					<BucketIcon/>
					<Buttons.Link>Remove uploaded student</Buttons.Link>
				</div>
			</div>
		</div>

		{student.existingStudents?.map(es => {
			return <ExistingStudentRow key={es.studentIDN} existingStudent={es} onView={onView}
			                           isRowOpened={isRowOpened}/>;
		})}
	</div>;
}


