import {MutableRefObject} from 'react';
import {FlexBox, NavigationBar, useNavigationBarContext} from '@esgi/ui/layout';
import {SchoolYearSelect} from '../../../school-year-select';
import {SelectOption} from '../../../../types';
import {LogoBeta} from '@esgi/ui';

interface Props {
	selectContentRef: MutableRefObject<HTMLDivElement>;
	selectFieldRef: MutableRefObject<HTMLButtonElement>;
	schoolYears: SelectOption[];
	onChangeSchoolYears: (v: SelectOption['value']) => void;
	currentSchoolYearID: number;
}

export function VerticalPanelHead({
	selectContentRef,
	selectFieldRef,
	schoolYears,
	onChangeSchoolYears,
	currentSchoolYearID,
}: Props) {
	const {expanded} = useNavigationBarContext();

	return (
		<NavigationBar.Head
			css={{
				padding: '16px 20px 14px 20px',
			}}
		>
			<NavigationBar.Item
				withHoverEffects={false}
				css={{paddingBottom: expanded ? 24 : '30px', width: '100%', cursor: 'auto'}}
			>
				{<LogoBeta width={80} height={32} />}
			</NavigationBar.Item>

			<FlexBox css={{height: '40px', alignItems: 'center'}}>
				{expanded && (
					<FlexBox align='center'>
						<SchoolYearSelect
							value={currentSchoolYearID?.toString()}
							contentRef={selectContentRef}
							fieldRef={selectFieldRef}
							options={schoolYears}
							onChange={(v) => onChangeSchoolYears?.(v)}
						/>
					</FlexBox>
				)}
			</FlexBox>
		</NavigationBar.Head>
	);
}
