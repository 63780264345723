import styles from './styles.module.less';

export const StudentHeader = () => {
	return <div className={styles.studentHeader}>
		<div className={styles.studentTitle}>Uploaded Student</div>
		<div className={styles.idTitle}>ID</div>
		<div className={styles.gradeTitle}>Grade</div>
		<div className={styles.languageTitle}>Language</div>
		<div className={styles.dateTitle}>Create Date</div>
		<div className={styles.actionTitle}>Action</div>
	</div>;
};
