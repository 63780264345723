import * as React from 'react';
import type {SVGProps} from 'react';

export function Close(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			data-cy='close-icon'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='m16 17.275 4.461 4.461a.901.901 0 1 0 1.275-1.275L17.275 16l4.461-4.461a.901.901 0 1 0-1.275-1.275L16 14.725l-4.461-4.461a.901.901 0 1 0-1.275 1.275L14.725 16l-4.461 4.461a.901.901 0 1 0 1.275 1.275L16 17.275Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
