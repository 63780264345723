export enum SubjectType {
	Personal = 'Personal',
	Stock = 'Stock',
	Deployed = 'Deployed',
}

export enum SubjectLevel {
	Teacher = 'Teacher',
	School = 'School',
	District = 'District',
}
