import {ContentAreaIcon} from 'pages/test-explorer/components/icons/content-area';
import {GradeLevelIcon} from 'pages/test-explorer/components/icons/grade-level';
import React from 'react';
import {Card} from '../../card/card';
import {Banner} from './banner/banner';
import {TestScreenType} from '@esgi/core/enums';
import {TestScreenTypeIcon} from '../../../icons/test-screen-type';

export class SeriesModel {
	id: number;
	name: string;
	color: string;
	description: string;
	contentArea: string;
	gradeLevels: number[] = [];
	testScreenTypes: TestScreenType[];
}

export class Props extends SeriesModel {
	onClick: () => any;
}

export class Series extends React.Component<Props> {
	render() {
		return <Card>
			<Banner name={this.props.name} contentArea={this.props.contentArea} onClick={() => this.props.onClick()}/>
			<main>
				<p className='description'>{this.props.description}</p>
			</main>
			<footer>
				<div className='icons'>
					<ContentAreaIcon contentArea={this.props.contentArea}/>
					<GradeLevelIcon gradeLevels={this.props.gradeLevels}/>
					<TestScreenTypeIcon testScreenTypes={this.props.testScreenTypes} />
				</div>
			</footer>
		</Card>;
	}
}
