import {Checkbox} from '@esgillc/ui-kit/control';
import React from 'react';
import {AddTestClosedEvent} from 'shared/modules/home-add-test/events';
import styles from './teacher-view-modal.module.less';
import DataService from '../../services/data-service';
import {AvailableTest} from '../../models';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {Card, CardHeader, CardBody, AddCard} from '../card';
import AssignTestsSubjectName from '../assign-tests-subject-name/assign-tests-subject-name';
import StarredName from '../starred-name/starred-name';
import CloseButton from '../close-button/close-button';
import {Button, PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import AddAdditionalTests from './components/add-tests-modal/add-additional-tests';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';
import DoNotHaveAnyTestsModal from './components/do-not-have-any-tests-modal/do-not-have-any-tests-modal';
import ContentAreaIcon from '../content-area-icon/content-area-icon';
import {AddSelfassessTests} from './components/add-tests-modal/components/add-selfassess-tests/add-selfassess-tests';
import {EventBusManager} from '@esgillc/events';
import {LockScreen} from 'modules/assessments/testing/self-assessment/components/student-view-modal/components/lock-screen-modal';
import {OverflowSpan} from '@esgillc/ui-kit/layout';

interface TeacherViewModalProps {
	dataService: DataService;
	closed: () => any;
	startSessionClicked: () => any;
}

class TeacherViewModalState {
	selectedTests: AvailableTest[];
	addTestsModalOpened: boolean = false;
	addPracticeTest: boolean = false;
	showDoNotHaveAnyTestsModal: boolean = false;
	showAddTestsModal: boolean = false;
}

const CARD_COUNT_MAX = 3;


class TeacherViewModal extends React.Component<TeacherViewModalProps, TeacherViewModalState> {
	private destroy$: Subject<void> = new Subject();
	private modalManagerRef: ModalManagerRefObject = React.createRef();
	eventBusManager: EventBusManager = new EventBusManager();

	state = new TeacherViewModalState();

	componentDidMount(): void {
		this.props.dataService.selectedAvailableTests$
			.pipe(takeUntil(this.destroy$)).subscribe(selectedTests => this.setState({selectedTests, addPracticeTest: selectedTests.some(t => t.isPractice)}));
	}

	componentWillUnmount(): void {
		this.destroy$.next();
	}

	private onPracticeTestProcessing() {
		this.setState(prevState => ({addPracticeTest: !prevState.addPracticeTest}),
			() => this.props.dataService.practiceTestProcessing(this.state.addPracticeTest),
		);
	}

	private onTestRemove(test: AvailableTest): void {
		if (test.isPractice) {
			this.setState({addPracticeTest: false});
		}

		this.props.dataService.removeTest(test.id);
	}

	private onStartSession(): void {
		this.props.dataService.startTestSession$()
			.pipe(takeUntil(this.destroy$))
			.subscribe(_ => this.startTestSession());
	}

	private startTestSession() {
		this.modalManagerRef.current.close(this.props.startSessionClicked);
	}

	private renderCard(test: AvailableTest, index: number) {
		const body = test.isPractice
			? 	<span className={styles.bodyText}>
					Your student should take this practice test as many times as needed to become familiar with different question types and to be confident in completing assessments independently.
				</span>
			: <ContentAreaIcon contentAreaID={test.contentAreaID} />;

		return <Card key={test.id} selected titleNumber={index+1}>
				<CardHeader
					titleNumber={index+1}
					infoText={test.isPractice && 'Learn More'}
					infoUrl={test.isPractice && 'https://support.esgisoftware.com/hc/en-us/articles/7532410893837-Administering-a-Student-Self-Assess-Test#practice-test'}
					remove={() => this.onTestRemove(test)}
				>
					<div className={styles.cardHeader}>
						<span className={styles.contentArea}>
							{test.contentAreaName}
						</span>
						{!test.isPractice && <OverflowSpan className={styles.testName}>
							{test.name}
						</OverflowSpan>}
					</div>
				</CardHeader>
				<CardBody>
					{body}
				</CardBody>
			</Card>;
	}

	private renderCardItem(index: number) {
		if (this.state.selectedTests.length <= index) {
			const key = new Date().getTime() + index;

			return <Draggable
						isDragDisabled
						key={key}
						draggableId={index.toString()}
						index={index}
					>
						{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							style={{...provided.draggableProps.style}}
						>
							<AddCard
								optional={index === 0}
								selected={this.state.selectedTests.length === index}
								key={index}
								clicked={() => this.onAddTestsClicked()}
							/>
						</div>
						)}
					</Draggable>;
		}

		const test = this.state.selectedTests[index];

		if (test.isPractice) {
			return this.renderCard(test, index);
		}

		return <Draggable
					key={test.id}
					draggableId={test.id.toString()}
					index={index}
					isDragDisabled={test.isPractice || this.state.selectedTests.length < 2}
				>
					{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={{...provided.draggableProps.style}}
					>
						{this.renderCard(test, index)}
					</div>
					)}
				</Draggable>;
	}

	private onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (this.state.selectedTests.find(t => t.isPractice) && result.destination.index === 0) {
			return;
		}

		this.props.dataService.reorder(result.source.index, result.destination.index);
	};

	private onClose() {
		this.modalManagerRef.current.close(this.props.closed);
		const {id, type} = this.props.dataService.selectedSubject;
		this.eventBusManager.dispatch(AddTestClosedEvent, new AddTestClosedEvent([this.props.dataService.selectedSubject.id], {
			type,
			id,
		}));
	}

	private get practiceOptionDisabled(): boolean {
		return this.state.selectedTests?.length === CARD_COUNT_MAX && !this.state.selectedTests?.some(t => t.isPractice);
	}

	private addSelfassessTestsClosed(): void {
		this.setState({showAddTestsModal: false});
	}

	private onAddTestsClicked(): void {
		if (this.props.dataService.availableTests.length === 0) {
			this.setState({showDoNotHaveAnyTestsModal: true});
		} else {
			this.setState({addTestsModalOpened: true});
		}
	}

	render() {
		return (
			<>
				<Modal className={styles.modal} modalManagerRef={this.modalManagerRef}>
					<Modal.Header className={styles.launcherHeader}>
							<AssignTestsSubjectName subjectName={this.props.dataService.selectedSubject.name} />
							<div className={styles.starredNameContainer}>
								<StarredName name={this.props.dataService.selectedStudent?.name} />
							</div>
							<CloseButton closed={() => this.onClose()} />
					</Modal.Header>
					<Modal.Body className={styles.body}>
						<div className={styles.launcherBody}>
							<Checkbox
								className={styles.addPracticeTestCheckbox}
								id={'add-practice-test'}
								checked={this.state.addPracticeTest}
								onClick={() => this.onPracticeTestProcessing()}
								disabled={this.practiceOptionDisabled}
								title={this.practiceOptionDisabled ? 'You have added tests to all available slots. To add the practice test, please remove a test from a slot.' : null}
							>
								Add Practice Test
							</Checkbox>
							<div className={styles.draggableWrapper}>
								<DragDropContext onDragEnd={this.onDragEnd}>
									<Droppable droppableId='droppable' direction='horizontal'>
										{(provided, snapshot) => (
											<div
												className={styles.cardsContainer}
												ref={provided.innerRef}
												{...provided.droppableProps}
											>
												{this.state.selectedTests && new Array(CARD_COUNT_MAX).fill(0).map((_, index) => this.renderCardItem(index))}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</div>
							<span className={styles.instructions}>
								Add up to 3 Self-Assess tests then Start Session to launch the student home screen and begin student self-assessment.
							</span>
						</div>
					</Modal.Body>
					<Modal.Footer className={styles.launcherFooter}>
						<div className={styles.buttonsContainer}>
							<Button
								onClick={() => this.onClose()}
							>
								CANCEL
							</Button>
							<PrimaryButton
								onClick={() => this.onStartSession()}
								disabled={this.state.selectedTests?.length === 0}
							>
								START SESSION
							</PrimaryButton>
						</div>
					</Modal.Footer>
				</Modal>

				<LockScreen closeParent={() => this.onClose()}/>
				{this.state.addTestsModalOpened &&
					<AddAdditionalTests
						dataService={this.props.dataService}
						closed={() => this.setState({addTestsModalOpened: false})}
					/>}

				{this.state.showDoNotHaveAnyTestsModal &&
					<DoNotHaveAnyTestsModal
						addClicked={() => this.setState({showDoNotHaveAnyTestsModal: false, showAddTestsModal: true, addTestsModalOpened: true})}
						cancelClicked={() => this.setState({showDoNotHaveAnyTestsModal: false})}
					/>}

				{this.state.showAddTestsModal &&
					<AddSelfassessTests
						closed={() => this.addSelfassessTestsClosed()}
						dataService={this.props.dataService}
					/>}
			</>
		);
	}
}

export default TeacherViewModal;
