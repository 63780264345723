import {CounterBox} from '@esgi/ui/layout';
import {
	SelectableListTriggerLabel,
	SelectableListTriggerContent as SelectableListTriggerContentStyled,
} from './lists.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	itemsCount: number;
	triggerLabel: string;
};

export function SelectableListTriggerContent({triggerLabel, itemsCount}: Props) {
	return (
		<SelectableListTriggerContentStyled>
			<SelectableListTriggerLabel size='large'>{triggerLabel}</SelectableListTriggerLabel>
			<CounterBox dataCy='student-counter'>
				<Text size='small' font='mono'>
					{itemsCount}
				</Text>
			</CounterBox>
		</SelectableListTriggerContentStyled>
	);
}
