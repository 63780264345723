import {BehaviorSubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {dispatchAppEvent} from '@esgillc/events';
import {createGeneralSectionForm} from '../../user-settings/forms/general-section';
import {UserSettingsResponse, UpdateModel, StudentSortModel} from './types';
import {UserSettingsChangedEvent} from '../../user-settings/events';
import {TimezoneValue} from 'shared/modules/user-settings/types';

export class SchoolAdministratorSettingsService extends BaseService {

	public generalSectionForm = createGeneralSectionForm();

	public sortValues = new BehaviorSubject<StudentSortModel[]>([]);
	public timeZones = new BehaviorSubject<TimezoneValue[]>([]);
	public showExpiredUsers = new BehaviorSubject<boolean>(false);

	private initialShowExpiredUsers = false;
	private userID: number;

	public init(userID) {
		this.userID = userID;
		return this.httpClient.ESGIApi.get<UserSettingsResponse>('user-settings/school-admin', 'init')
			.pipe(tap((response) => {
				const {
					timeZones,
					selectedTimeZone,
					sortBy,
					sortByVariants,
					isCleverAccountLinked,
					isOneClickAccountLinked,
					showExpiredUsers,
				} = response;

				this.timeZones.next(timeZones);
				this.sortValues.next(sortByVariants);
				this.initialShowExpiredUsers = showExpiredUsers;
				this.showExpiredUsers.next(showExpiredUsers);

				this.generalSectionForm.value = {
					sortBy: [sortByVariants.find(item => item.value === sortBy) || sortByVariants[0]],
					timeZone: [selectedTimeZone || timeZones[0]],
					isCleverAccountLinked,
					isOneClickAccountLinked,
				};
			}));
	}

	public updateUserSettings = () => {
		const model = new UpdateModel(
			this.generalSectionForm.controls.sortBy.value[0].value,
			this.generalSectionForm.controls.timeZone.value[0].id,
			this.showExpiredUsers.value,
		);

		return this.httpClient.ESGIApi.post('user-settings/school-admin', 'update', model)
			.pipe(tap(() => {
				dispatchAppEvent(UserSettingsChangedEvent, new UserSettingsChangedEvent(this.userID, model.sortBy, this.initialShowExpiredUsers !== this.showExpiredUsers.value, false));
			}));
	};
}
