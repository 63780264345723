import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';

interface Props {
  title: string;
  linkTitle: string;
  onUnlinkClick: () => void;
  onLinkClick: () => void;
}

export function OneLinkedSsoLink({
	title,
	linkTitle,
	onUnlinkClick,
	onLinkClick,
}: Props) {
	return <div className={styles.ssoUnlink} data-cy={'one-linked-sso-link'}>
    <div className={styles.text} data-cy={'title'}>Your ESGI account is linked to {title}.</div>
    <div className={styles.ssoLink}>
      <div data-cy={'unlink'}>
        <Buttons.Link className={styles.link} onClick={onUnlinkClick}>Unlink</Buttons.Link>
      </div>
      <span className={styles.lineBreak}>|</span>
      <div data-cy={'link'}>
        <Buttons.Link className={styles.link} onClick={onLinkClick}>Link to {linkTitle} account</Buttons.Link>
      </div>
    </div>
  </div>;
}
