import React from 'react';
import styles from './action-button.module.less';
import {join, isTouchDevice} from '@esgillc/ui-kit/utils';


interface NextButtonProps {
	disabled?: boolean;
	clicked: () => any;
	className?: string;
}

export class NextButton extends React.Component<NextButtonProps> {
	render() {
		return (
			<button onTouchStart={this.props.clicked} onClick={isTouchDevice() ? undefined : this.props.clicked} className={join(styles.actionBtn, styles.nextBtn, this.props.className)} disabled={this.props.disabled} type='button'>
                {this.props.disabled
					?	<svg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle cx='37.5' cy='37.5' r='36' stroke='#828282' strokeWidth='3'/>
						<path fillRule='evenodd' clipRule='evenodd' d='M59.1771 35.74C59.9849 36.5253 59.9849 37.8226 59.1771 38.608L40.568 56.7002C39.3002 57.9328 37.1739 57.0345 37.1739 55.2662V47.6522C37.1739 46.5476 36.2785 45.6522 35.1739 45.6522H15.6957C14.5911 45.6522 13.6957 44.7568 13.6957 43.6522V30.6957C13.6957 29.5911 14.5911 28.6957 15.6957 28.6957H35.1739C36.2785 28.6957 37.1739 27.8002 37.1739 26.6957V19.0818C37.1739 17.3135 39.3002 16.4151 40.568 17.6478L59.1771 35.74Z' fill='#848484'/>
					</svg>
					: <svg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle cx='37.5' cy='37.5' r='36' fill='#2FB454' stroke='white' strokeWidth='3'/>
						<path fillRule='evenodd' clipRule='evenodd' d='M59.1771 35.74C59.9849 36.5253 59.9849 37.8226 59.1771 38.608L40.568 56.7002C39.3002 57.9328 37.1739 57.0345 37.1739 55.2662V47.6522C37.1739 46.5476 36.2785 45.6522 35.1739 45.6522H15.6957C14.5911 45.6522 13.6957 44.7568 13.6957 43.6522V30.6957C13.6957 29.5911 14.5911 28.6957 15.6957 28.6957H35.1739C36.2785 28.6957 37.1739 27.8002 37.1739 26.6957V19.0818C37.1739 17.3135 39.3002 16.4151 40.568 17.6478L59.1771 35.74Z' fill='white'/>
					</svg>}
			</button>
		);
	}
}
