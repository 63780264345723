import * as React from 'react';
import type {SVGProps} from 'react';

export function Data(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M9 6a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3H9Zm0 2h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M11.5 11.002a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Zm8 0a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2h-1Zm-8 8a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2h-1Zm0-4a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2h-5Zm5 4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Zm3-3a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
