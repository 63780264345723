import {Observable, of} from 'rxjs';
import {concatMap, debounceTime, map} from 'rxjs/operators';
import {HttpClient} from '@esgi/api';
import {ValidatorArgs} from '@esgillc/ui-kit/form';

export const subjectNameValidator = (httpClient: HttpClient, subjectID: number|null, validateChain: Observable<ValidatorArgs<string>>) => {
	return validateChain.pipe(debounceTime(300), concatMap(v => {
		if (!v.cancelled && v.field.currentValue.length >= 1) {
			return httpClient.ESGIApi.get<{ unique: boolean }>('assets/subjects/common', 'is-name-unique', {
				name: v.field.currentValue,
				subjectId: subjectID,
			}).pipe(map(response => {
				if (!response.unique) {
					v.errors.push('unique');
				}
				return v;
			}));
		} else {
			return of(v);
		}
	}));
};