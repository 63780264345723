import moment from 'moment';
import React, {ReactNode} from 'react';
import {Fade} from '@esgillc/ui-kit/transition';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BaseCheckbox} from '@esgi/deprecated/ui-kit/checkbox';
import {BaseDropdown, Option} from '@esgi/deprecated/ui-kit/dropdown';
import DataService from '../../../../data-service';
import {SessionModel} from '../../../../types';
import DateTimePicker from './components/date-time-picker/date-time-picker';
import styles from '../../sub-header.module.less';

interface Props {
	service: DataService;
	editMode: boolean;
	showDeletedSessions: boolean;
	changeShowDeletedSessions: () => void;
	sessions: SessionModel[];
	selectedSession: SessionModel[];
}

export default class SessionPicker extends React.PureComponent<Props> {
	private onDestroy$: Subject<void> = new Subject();

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
		const {sessions, selectedSession, showDeletedSessions} = this.props;
		if (prevProps.sessions !== sessions || prevProps.selectedSession[0]?.isDeleted !== selectedSession[0]?.isDeleted || prevProps.showDeletedSessions !== showDeletedSessions) {
			const currentSession = selectedSession[0];
			if (currentSession && currentSession.isDeleted && !showDeletedSessions) {
				const firstSession = sessions.find(s => !s.isDeleted);
				if (firstSession) {
					this.initSession(firstSession.sessionID);
				} else {
					this.initSession(null);
				}
			}
		}
		if (prevProps.showDeletedSessions !== this.props.showDeletedSessions && this.props.showDeletedSessions && !this.props.selectedSession.length) {
			const firstSession = sessions[0];
			if (firstSession) {
				this.initSession(firstSession.sessionID);
			}
		}
	}

	public render() {
		const {sessions, editMode} = this.props;
		const hasDeletedSessions = sessions.some(s => s.isDeleted);
		return <div className={styles.sessionSelector}>
			{editMode && this.renderDateTimePicker()}
			{!editMode && this.renderSelectDropdown()}
			{this.renderShowDeletedSessions(hasDeletedSessions && !editMode)}
		</div>;
	}

	private renderSelectDropdown(): ReactNode {
		const {selectedSession, showDeletedSessions, sessions} = this.props;
		const filteredSessions = sessions.filter(s => showDeletedSessions ? true : !s.isDeleted);
		const hasNoSessions = !filteredSessions.length;
		const hasOneSessions = filteredSessions.length === 1;
		const formatDate = (date) => moment(date).format('M-D-YY h:mm A');

		return <BaseDropdown value={selectedSession}
		                     setValue={(v) => this.initSession(v[0].sessionID)}
		                     displayLabel={(v) => {
			                     const s = v[0];
								 const testDate = formatDate(s.testDate);
			                     return s.isDeleted ? testDate + ' (deleted)' : testDate;
		                     }}
		                     placeholder={hasNoSessions && 'None'}
		                     disabled={hasNoSessions || hasOneSessions}
		                     className={styles.sessionSelector}>
			{filteredSessions.map(s => <Option key={s.sessionID} value={s}>
				{formatDate(s.testDate)} {s.isDeleted && ' (deleted)'}
			</Option>)}
		</BaseDropdown>;
	}

	private renderDateTimePicker(): ReactNode {
		const currentSession = this.props.selectedSession[0];

		return <DateTimePicker
			id={'session_time_' + currentSession.sessionID.toString()}
			date={currentSession.testDate}
			endDate={this.props.service.profileTime}
			onSelect={(date) => this.props.service.updateSessionDate(date)}
		/>;
	}

	private renderShowDeletedSessions(show): ReactNode {
		return <Fade in={show} duration={100}>
			<div className={styles.showDeletedSessions}>
				<BaseCheckbox checked={this.props.showDeletedSessions}
				              onChange={this.props.changeShowDeletedSessions}/>
				<div className={styles.showDeletedSessionsLabel}
				     onClick={this.props.changeShowDeletedSessions}>
					Show deleted sessions
				</div>
			</div>
		</Fade>;
	}

	private initSession(sessionID: number): void {
		this.props.service.initSession(sessionID)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe();
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
