import React from 'react';
import {FlexBox} from '@esgillc/ui-kit/layout';
import styles from './sight-words-template.module.less'
import {join} from '@esgillc/ui-kit/utils';
import * as modelUtils from '../../utils/utils';
import {FontFamily, UnitType} from '../../types';
import {SightWord, SightWordsReportDetails, SightWordsReportStudentData} from './types';

interface Props {
	details: SightWordsReportDetails;
	student: SightWordsReportStudentData;
	words: SightWord[];
	className?: string;
}

export class SightWordsTemplate extends React.PureComponent<Props> {
	public render() {
		return <FlexBox className={join(styles.page, this.props.className)} direction='column'>
			<FlexBox className={styles.header} align='start'>
				<FlexBox className={styles.headerItem}>
					<FlexBox className={styles.headerItemKey}>Writing Practice:</FlexBox>
					<FlexBox>{new Date().toLocaleDateString('en-US')}</FlexBox>
				</FlexBox>
				<FlexBox className={styles.headerItem}>
					<FlexBox className={styles.headerItemKey}>
						{
							this.props.details.unitType === UnitType.Class ? 'Teacher:' : 'Specialist:'
						}
					</FlexBox>
					<FlexBox>{modelUtils.getLecturerName(this.props.details.lecturer)}</FlexBox>
				</FlexBox>
				<FlexBox className={styles.headerItem}>
					<FlexBox className={styles.headerItemKey}>
						{
							this.props.details.unitType === UnitType.Class ? 'Class:' : 'Group:'
						}
					</FlexBox>
					<FlexBox>
						{
							this.props.student.unitName
						}
					</FlexBox>
				</FlexBox>
			</FlexBox>
			<FlexBox className={styles.studentNameBlock} direction='column' justify='stretch' align='stretch'>
				<FlexBox className={styles.studentNameBlockFullName}>{this.props.student.fullName}</FlexBox>
				<FlexBox className={styles.reportName}>{this.props.student.unitName} Sight Words</FlexBox>
			</FlexBox>
			<table className={styles.pageBody} style={{fontSize: this.props.details.fontSize}}>
				<tbody>
				{
					this.props.words?.slice(0, this.props.details.wordsCountByPage).map((word, wordIndex) => {
						return <tr key={`sw-row-${wordIndex}`}>
							{
								this.props.details.columns.map((column, columnIndex) => {
									const itemClassName = join(
										styles.lineItem,
										column.fontFamily === FontFamily.Lined && styles.lined,
										column.fontFamily === FontFamily.Normal && styles.normal,
										column.fontFamily === FontFamily.DottedLined && styles.dotted,
										column.fontFamily === FontFamily.Blank && styles.blank,
									);
									return <td className={itemClassName} key={`sw-item-${wordIndex}-${columnIndex}`}>
										{
											column.fontFamily === FontFamily.Blank
												? this.renderBlankLine()
												: word.word
										}
									</td>;
								})
							}
						</tr>;
					})
				}
				</tbody>
			</table>
		</FlexBox>;
	}

	private renderBlankLine() {
		return <div className={styles.blankLine}>
			<div className={styles.blankLineTop}></div>
			<div className={styles.blankLineBottom}></div>
		</div>
	}
}
