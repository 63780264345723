import {PropsWithChildren, useCallback, useMemo} from 'react';
import {useService} from '@esgi/core/service';
import {useTestsPanelContext} from '../../../root';
import {StudentsRootContext} from './context';
import {StudentsRootService} from './service';

export function StudentsRoot({children}: PropsWithChildren) {
	const service = useService(StudentsRootService);

	const {studentsState} = useTestsPanelContext();

	const getStudentById = useCallback((studentId: number) => {
		return service.get(studentId);
	}, [service]);

	const contextValue = useMemo(() => {
		if ('studentID' in studentsState) {
			return {
				selectedStudentIDs: [studentsState.studentID],
				getStudentById,
			};
		}

		return {
			selectedStudentIDs: studentsState.studentIDs,
			getStudentById,
		};
	}, [studentsState, getStudentById]);

	return (
		<StudentsRootContext.Provider value={contextValue}>
			{children}
		</StudentsRootContext.Provider>
	);
}