import React from 'react';
import {useNavigate} from 'react-router-dom';
import {AppliedTo, Button, Close, Download, File, Filenametemplate, Tooltip} from '@esgi/ui';
import {RoutesMap} from '@esgi/main/libs/core';
import {Text} from '@esgi/ui/typography';
import {ButtonsBox, Divider} from './index.styled';

type ActionButtonsProps = {
	onBingoGameClicked: VoidFunction;
	onFleshCardsClicked: VoidFunction;
	onDownloadClicked: VoidFunction;
	onLetterFileClicked: VoidFunction;
	withStartTestButton?: boolean;
	onStartTestHandler?: VoidFunction;
}

export function ActionButtons({
	onStartTestHandler,
	withStartTestButton,
	onDownloadClicked,
	onBingoGameClicked,
	onFleshCardsClicked,
	onLetterFileClicked,
}: ActionButtonsProps) {

	const navigate = useNavigate();
	const onClose = () => navigate(RoutesMap.home);

	return (
		<ButtonsBox>
			<Tooltip>
				<Tooltip.Trigger>
					<Button.Icon onClick={onBingoGameClicked} dataCy='bingo-icon'>
						<AppliedTo/>
					</Button.Icon>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text size='small'>Bingo</Text>
				</Tooltip.Content>
			</Tooltip>
			<Tooltip>
				<Tooltip.Trigger>
					<Button.Icon onClick={onFleshCardsClicked} dataCy='flashcards-icon'>
						<Filenametemplate/>
					</Button.Icon>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text size='small'>Flashcards</Text>
				</Tooltip.Content>
			</Tooltip>
			<Divider/>
			<Tooltip>
				<Tooltip.Trigger>
					<Button.Icon onClick={onLetterFileClicked} dataCy='parent-letter-icon'>
						<File/>
					</Button.Icon>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text size='small'>Parent Letter</Text>
				</Tooltip.Content>
			</Tooltip>

			{/*<Button.Icon onClick={onDownloadClicked}>*/}
			{/*	<Download/>*/}
			{/*</Button.Icon>*/}
			{withStartTestButton && <Button onClick={onStartTestHandler}>
				<Text size='large'>
					TEST
				</Text>
			</Button>}
			<Button.Icon onClick={onClose} dataCy='close-button'>
				<Close/>
			</Button.Icon>
		</ButtonsBox>
	);
}
