import React from 'react';
import styles from '../../styles.module.less';
import {TestHistoryService} from '../../../../../../services/test-history-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';

interface Props {
	service: TestHistoryService
}

export function Groups({service}: Props) {
	const groups = useBehaviorSubject(service.groups);
	const selectedGroup = useBehaviorSubject(service.selectedGroup);
	return groups.length > 0 && <>
		<div>
			<span className={styles.settingsTitle}>Group: </span>
			{groups.length > 1 && <>
				<Dropdown value={selectedGroup}
				          setValue={(v) => service.selectedGroup.next(v)} optionName='name'
				          className={styles.fluid} autoWidth={true}>
					{groups.map(v => <Option key={v.groupID} value={v}>{v.name}</Option>)}
				</Dropdown>
			</>}
			{groups.length === 1 && <>
				<span className={styles.name}>{selectedGroup[0].name}</span>
			</>}
		</div>
	</>;
}
