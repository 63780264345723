export function Reordered(groupIds: number[]): Hidden.Args {
	return {
		groupIds: groupIds,
	};
}

export module Hidden {
	export interface Args {
		groupIds: number[];
	}
}