import {combineLatest, Observable, of, Subject, timer} from 'rxjs';
import {debounce, map, takeUntil, catchError} from 'rxjs/operators';
import {SortDirection, SubjectType, TestScreenType, TestType} from '@esgi/core/enums';
import {TestChanged} from 'shared/modules/test-details/events';
import * as TestDetailsEvents from 'shared/modules/test-details/events';
import {BroadcastEventManager, EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import _ from 'underscore';
import {TestsSortBy} from '../enums';
import FilterService from './filter-service';
import {INotedAuthor, LandingService} from './landing-service';
import {ISubjectInfo, SubjectsService} from './subjects-service';
import {StateStandardModel} from 'shared/modules/test-details/models';
import {userStorage} from '@esgi/core/authentication';

class SearchData {
	loading: boolean = false;
	totalResults: number = 0;
	firstLoad: boolean = true;
	pageIndex: number = 1;
	tests: TestModel[] = [];
	canLoadMore: boolean = false;
}

export default class SearchService extends BaseService {
	private readonly eventBus = new EventBusManager();
	private readonly broadcastEventBus = new BroadcastEventManager();

	private readonly ITEMS_PER_PAGE: number = 20;

	private readonly loadingSubject: Subject<boolean> = new Subject();
	private readonly searchDataSubject: Subject<SearchData> = new Subject<SearchData>();

	private _currentValue: SearchData;
	private subjectTabs: ISubjectInfo[] = [];

	constructor(private controller: string,
	                   private filterService: FilterService,
	                   private subjectsService: SubjectsService,
	                   private landingService: LandingService) {
		super();
		this._currentValue = new SearchData();
		this.searchDataSubject.next(this._currentValue);
		subjectsService.subjectInfos$
			.pipe(takeUntil(this.destroy$))
			.subscribe(value => this.subjectTabs = value);

		this.filterService
			.asObservable()
			.pipe(
				debounce(() => timer(10)),
				takeUntil(this.destroy$),
			).subscribe((value) => value.touched && this.reloadTests(1));

		this.eventBus.subscribe(TestDetailsEvents.TestDeleted, (args) => this.testDeleteEventHandler(args));
		this.eventBus.subscribe(TestDetailsEvents.TestPublished, (args) => this.testPublishEventHandler(args));
		this.eventBus.subscribe(TestDetailsEvents.TestCopied, (args: TestDetailsEvents.TestCopied.Args) => this.testCopiedEventHandler(args));

		this.broadcastEventBus.aggregate({
			TestChanged,
		}).subscribe((aggregation) => {
			if(this._currentValue.tests.length) {
				this.reloadTests(1, false);
			}
		});
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
		this.broadcastEventBus.destroy();
	}

	public get currentValue(): Readonly<SearchData> {
		return this._currentValue;
	}

	public updatePageIndex(value: number): void {
		this.reloadTests(value, true);
	}

	public get onChanged$(): Observable<SearchData> {
		return this.searchDataSubject;
	}

	public get onLoadingStatusChanged$(): Observable<boolean> {
		return this.loadingSubject;
	}

	private reloadTests(pageIndex: number, loadAdditional: boolean = false) {
		this.loadingSubject.next(true);

		const filter = this.filterService.currentValue;

		const subjectID = filter.subjectId;
		let subjectType = null;
		if (subjectID > 0) {
			subjectType = this.subjectTabs.find(x => x.id === subjectID).subjectType;
		}

		const methodName = filter.source === 'SQL' ? 'Filter' : 'Elastic';

		const request$ = this.httpClient.ESGIApi.get<IRequestOutModel>(this.controller, methodName, {
			scope: filter.scope,
			gradeLevelId: filter.gradeLevelId,
			contentAreaId: filter.contentAreaId,
			subjectId: subjectID,
			subjectType: subjectType,
			partnerName: filter.notedAuthor && filter.notedAuthor.searchName,
			keyword: filter.keyword,
			itemsPerPage: this.ITEMS_PER_PAGE,
			pageIndex: pageIndex,
			sortBy: filter.sortBy,
			sortDirection: filter.sortDirection,
			hidden: filter.showHidden,
			featuredSeriesID: (filter.featuredSeries && filter.featuredSeries.id) || 0,
			notedSeriesID: (filter.notedSeries && filter.notedSeries.id) || 0,
			testType: filter.testType,
		} as IRequestInModel);

		combineLatest([request$, this.landingService.notedAuthors$, this.subjectsService.deployedTests$])
			.pipe(map((r) => {
				const searchResults = r[0];
				const notedAuthors = r[1];
				const deployedTests = r[2] || [];

				const tests = searchResults.tests.map(t => {
					const deployedTest = deployedTests.find(x => x.id === t.testID);
					if (deployedTest) {
						t.school = deployedTest.school;
						t.district = deployedTest.district;
					}

					let author = null;
					if (t.isPublic){
						author = notedAuthors.find(a => a.name === t.creatorName);
					}
					if (!author) {
						author = {name: t.creatorName, searchName: t.creatorName} as INotedAuthor;
					}
					t.author = author;

					const currentUser = userStorage.get();

					if (t.stateStandards) {
						const userStateRelatedStandards = t.stateStandards?.filter(({stateID}) => stateID === currentUser.stateID);
						const commonCoreStandards = t.stateStandards?.filter(({stateID}) => stateID === 0);

						t.stateStandard = (userStateRelatedStandards.length ? userStateRelatedStandards : commonCoreStandards)?.map(({name}) => name)?.join(',');
					} else {
						t.stateStandard = null;
					}

					return t;
				});

				const next = {
					loading: false,
					tests: tests,
					firstLoad: true,
					pageIndex: pageIndex,
				} as SearchData;

				if (loadAdditional) {
					const oldValues = Array.from(this._currentValue.tests);
					next.firstLoad = false;
					oldValues.push(...searchResults.tests);
					next.tests = oldValues;
					next.totalResults = this._currentValue.totalResults;
					next.canLoadMore = oldValues.length < this._currentValue.totalResults;
				} else {
					next.totalResults = searchResults.count;
					next.canLoadMore = searchResults.count > this.ITEMS_PER_PAGE;
				}

				this._currentValue = next;
				this.searchDataSubject.next(next);
				this.loadingSubject.next(false);
			}), catchError(err => {
				const next = {
					loading: false,
					tests: [],
					firstLoad: true,
					pageIndex: pageIndex,
				} as SearchData;

				if (loadAdditional) {
					const oldValues = Array.from(this._currentValue.tests);
					next.firstLoad = false;
					next.tests = oldValues;
					next.totalResults = this._currentValue.totalResults;
					next.canLoadMore = oldValues.length < this._currentValue.totalResults;
				} else {
					next.totalResults = 0;
					next.canLoadMore = true;
				}

				this._currentValue = next;
				this.searchDataSubject.next(next);
				this.loadingSubject.next(false);

				return of(err);
			})).subscribe();
	}

	private testPublishEventHandler = (args: TestDetailsEvents.TestPublished.Args) => {
		if (this._currentValue) {
			if (this.isMyTestScopeHasFilters) {
				return;
			}
			const card = this.createNewCard(args);
			this.addNewCard(card);
		}
	};

	private testCopiedEventHandler = (args: TestDetailsEvents.TestCopied.Args) => {
		if (this._currentValue) {
			const card = this.createNewCard(args);
			this.addNewCard(card);
		}
	};

	private testDeleteEventHandler(args: TestDetailsEvents.TestDeleted.Args) {
		if (this._currentValue) {
			const data = {...this._currentValue};
			data.tests = data.tests.filter(t => t.testID === args.id);
			this.updateData(data);
		}
	}

	private createNewCard = (args: TestDetailsEvents.TestPublished.Args | TestDetailsEvents.TestCopied.Args): TestModel => {
		let id, name, type, hasSelfAssessVersion;
		if('hasSelfAssessVersion' in args){
			hasSelfAssessVersion = args.hasSelfAssessVersion;
		}
		if ('id' in args) {
			id = args.id;
			name = args.name;
		} else {
			id = args.testId;
			name = args.copiedName;
		}

		if (_.isNumber(args.type)) {
			type = TestType[args.type];
		} else {
			type = args.type;
		}

		const test: TestModel = {
			testID: id,
			contentArea: args.contentArea,
			createDate: args.createdDate,
			creatorName: args.author,
      		isPublic: false,
			description: args.description,
			draft: false,
			gradeLevels: args.gradeLevels,
			lastTestDate: null,
			imageQuestionID: 0,
			name: name,
			questions: [],
			type: type,
			author: {name: args.author, searchName: args.author, id: 0} as INotedAuthor,
			stateStandard: args.stateStandard,
			numberOfQuestions: args.questionsCount,
			isWhiteBackground: args.isWhiteBackground,
			testScreenTypes: hasSelfAssessVersion ? [TestScreenType.OneToOne, TestScreenType.SelfAssessment] : [TestScreenType.OneToOne],
		};

		test.questions = args.questions && args.questions.map(q => {
			const model: QuestionModel = {
				pregenerated: q.questionImagePregenerated,
				questionID: q.id,
				ticks: q.modifyTicks,
			};
			return model;
		}) || [];

		return test;
	};

	private addNewCard = (card: TestModel) => {
		const data = {...this._currentValue};
		data.tests.unshift(card);
		this.updateData(data);
	};

	private updateData(value: SearchData, silent = false) {
		this._currentValue = value;
		if (!silent) {
			this.searchDataSubject.next(value);
		}
	}

	private get isMyTestScopeHasFilters() {
		const {
			contentAreaId,
			scope,
			featuredSeries,
			gradeLevelId,
			keyword,
			notedAuthor,
			notedSeries,
			showHidden,
			sortDirection,
			subjectId,
		} = this.filterService.currentValue;
		return (scope !== 'MyTests'
			|| contentAreaId
			|| (featuredSeries && featuredSeries.id !== 0)
			|| gradeLevelId
			|| keyword
			|| notedAuthor
			|| notedSeries
			|| sortDirection !== 2
			|| subjectId
			|| showHidden);
	}
}

interface IRequestInModel {
	scope: string;
	gradeLevelId: number;
	contentAreaId: number;
	subjectId: number;
	subjectType: SubjectType;
	partnerName: string;
	keyword: string;
	itemsPerPage: number;
	pageIndex: number;
	sortBy: TestsSortBy;
	sortDirection: SortDirection;
	hidden: boolean;
	featuredSeriesID: number;
	notedSeriesID: number;
}

interface IRequestOutModel {
	tests: TestModel[];
	count: number;
}

export class TestModel {
	testID: number;
	name: string;
	description: string;
	contentArea: string;
	stateStandard: string;
	stateStandards?: StateStandardModel[];
	gradeLevels: number[];
	creatorName: string;
	isPublic: boolean;
	createDate: string;
	hidden?: boolean;
	starred?: boolean;
	numberOfQuestions: number;
	imageQuestionID: number;
	type: string;
	lastTestDate: string;
	draft: boolean;
	isWhiteBackground: boolean;
	district?: boolean;
	school?: boolean;
	author?: INotedAuthor;
	testScreenTypes: TestScreenType[];

	questions: QuestionModel[];
}

export class QuestionModel {
	pregenerated: boolean;
	ticks: number;
	questionID: number;
}
