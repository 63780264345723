import './checkbox-dropdown.less';
import React from 'react';
import {Checkbox} from './checkbox';

class Props {
    values: InModel[] = [];
    selectedValues: number[] = [];
    onSelectGrade: (id: number, value: boolean) => void;
    className?: string;
    label: string;
    disabled?: boolean;
}

export class State {
    opened: boolean = false;
}

export class InModel {
    id: number;
    value: string;

    constructor(id: number, value: string) {
    	this.id = id;
    	this.value = value;
    }
}

export class CheckBoxDropDown extends React.PureComponent<Props, State> {
    private html: HTMLElement;

    constructor(props) {
    	super(props);
    	this.state = new State();
    }

    componentDidMount() {
    	document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    componentWillUnmount() {
    	document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    handleClickOutside(event) {
    	if (this.html && !this.html.contains(event.target)) {
    		if (this.state.opened) {
    			this.setState({
    				opened: false,
    			});
    		}
    	}
    }

    private get controlClassname(): string {
    	let out = ['form-control dropdown-toggle'];
    	if (this.state.opened) {
    		out.push('active');
    	}
    	if (this.props.disabled) {
    		out.push('disabled');
    	}
    	return out.join(' ');
    }

    render() {
    	return <div className={'checkbox-dropdown ' + (this.props.className ? this.props.className : '')} ref={r => this.html = r}>
		    <div
			    className={this.controlClassname}
			    onClick={() => !this.props.disabled && this.setState({opened: !this.state.opened})}>
                <span>{this.props.label}</span>
                {this.state.opened
                    ? ( <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0 5.11133L5 -2.86102e-06L10 5.11133H0Z' fill='#828282'/>
                    </svg>)
                    : ( <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0 0L5 5.11133L10 0L0 0Z' fill='#828282'/>
                    </svg>)}
            </div>
            {this.state.opened && this.props.values.length && <div className='dropdown-list'>
                {this.props.values.map(x => {
                    return <div className='list-item' key={x.id}>
                        <Checkbox
                            label={x.value}
                            checked={this.props.selectedValues.some(c => c === x.id)}
                            id={'item-' + x.id}
                            onClick={(e) => {
                                this.props.onSelectGrade(x.id, e);
                            }}
                            className='checkbox'
                        />
                    </div>;
                })}
            </div>}
        </div>;
    }
}
