import React, {useCallback, useMemo} from 'react';
import {Container} from './index.styled';
import {NoTests} from './components/no-tests';
import {Header} from './components/header';
import {SortBy, Sorting, SortDirection, Test} from '../../service/types';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {Body} from './components/body';
import {useUser} from '@esgi/core/authentication';
import {TestScopeTabId} from '../tabs/types';
import {NoTestsMessage} from '../../constants';

type Props = {
	tests: Test[],
	selectedTests: number[],
	loaded: boolean,
	toggleAll: VoidFunction,
	sorting: Sorting,
	setSorting: React.Dispatch<Sorting>
	osRef: React.MutableRefObject<OverlayScrollbarsComponentRef<'div'>>,
	onTestSelect: (id: number) => void,
	keyword: string,
	disabledActions?: boolean,
	scope: TestScopeTabId
}

export function Table({
	tests,
	loaded,
	selectedTests,
	onTestSelect,
	toggleAll,
	sorting,
	setSorting,
	osRef,
	keyword,
	disabledActions = false,
	scope,
}: Props) {
	const user = useUser();
	const onCellClicked = useCallback((by: SortBy) => {
		if(by === sorting.by) {
			setSorting({...sorting, direction: sorting.direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc});
		} else {
			setSorting({...sorting, by});
		}
	}, [sorting, setSorting]);

	const allSelected = useMemo(() => {
		const testIDs = tests?.map(s => s.id);
		return testIDs.every(s => selectedTests?.includes(s));
	}, [selectedTests, tests]);
	
	const noTests = useCallback(() => {
		if (loaded && !tests.length) {
			if (scope === 'District') {
				const userIsNotLinked = user?.agreementLevelCode === 'T';

				return <NoTests message={NoTestsMessage[userIsNotLinked ? TestScopeTabId.District : TestScopeTabId.All]}/>;
			}

			return (
				<NoTests message={NoTestsMessage[scope]}/>
			);
		}
	}, [loaded, scope, tests, user]);

	return (
		<Container>
			<Header
				sorting={sorting}
        allSelected={allSelected}
        onSelectAllClicked={toggleAll}
        onCellClicked={onCellClicked}
				disabled={disabledActions || Boolean(keyword)}
			/>
			{noTests()}
			<Body
				loaded={loaded}
				selectedTests={selectedTests}
				osRef={osRef}
				tests={tests}
				onTestSelect={onTestSelect}
				user={user}
			/>
		</Container>
	);
}
