import {Input} from '@esgillc/ui-kit/form-control';
import React, {ReactNode} from 'react';
import {useState} from '@esgi/deprecated/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import DataService from '../../services/data-service';
import FilterService from '../../services/filter-service';
import {PreSelected} from 'modules/forms/students-form/types';
import {HierarchyMode} from 'modules/hierarchy/core/models';
import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import StudentProfile from 'modules/forms/students-form';

class State {
	studentEditorOpened: boolean = false;
}

class Props {
	hasStudents: boolean;
	filterService: FilterService;
	dataService: DataService;
	nameField: IFormControl<string>;
}

@useState(State)
export default class FilterHeader extends React.PureComponent<Props, State> {
	private readonly currentUser = userStorage.get();

	private get canAddStudents(): boolean {
		if (this.currentUser.userType !== UserType.D) {
			return this.props.dataService.data.canAddStudents;
		}
		return true;
	}

	public render() {
		return <div className='subheader_operations'>
			<div className='add_student_container'>
				Students
				{this.canAddStudents &&
					<OnHoverTooltip message='Add Student'>
						<a className='add_student' href='#' onClick={() => this.setState({studentEditorOpened: true})}>
							<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'
							     fill='none'>
								<path
									d='M9.83332 4.83332H8.16666V8.16666H4.83332V9.83332H8.16666V13.1667H9.83332V9.83332H13.1667V8.16666H9.83332V4.83332ZM8.99999 0.666656C4.39999 0.666656 0.666656 4.39999 0.666656 8.99999C0.666656 13.6 4.39999 17.3333 8.99999 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99999C17.3333 4.39999 13.6 0.666656 8.99999 0.666656ZM8.99999 15.6667C5.32499 15.6667 2.33332 12.675 2.33332 8.99999C2.33332 5.32499 5.32499 2.33332 8.99999 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 12.675 12.675 15.6667 8.99999 15.6667Z'
									fill='#0088CC'/>
							</svg>
						</a>
					</OnHoverTooltip>}
			</div>
			{this.props.hasStudents &&
				<FormElement control={this.props.nameField}>
					<Input className='student-by-name-filter' placeholder='Search by student or ID'/>
				</FormElement>
			}
			{this.renderStudentEditor()}
		</div>;
	}

	private renderStudentEditor(): ReactNode {
		if (this.state.studentEditorOpened) {
			const currentUser = userStorage.get();
			let schoolID = currentUser.schoolID;
			let userID = currentUser.userID;
			let userType = this.currentUser.userType;
			if ([UserType.D, UserType.ISD].includes(currentUser.userType)) {
				schoolID = this.props.filterService.filter$.value.schoolID;
			}

			if ([UserType.D, UserType.C].includes(currentUser.userType)) {
				if (this.props.filterService.filter$.value.mode === undefined ||
					this.props.filterService.filter$.value.mode === null ||
					this.props.filterService.filter$.value.mode === HierarchyMode.Classic) {
					userType = UserType.T;
					userID = this.props.filterService.filter$.value.teacherID;
				} else {
					userType = UserType.ISD;
					if (this.props.filterService.filter$.value.specialistType === UserType.ISS) {
						userType = UserType.ISS;
					}
					if (this.props.filterService.filter$.value.specialistType === UserType.PA) {
						userType = UserType.PA;
					}
					userID = this.props.filterService.filter$.value.specialistUserID;
				}
			}

			const preSelected: PreSelected = {
				schoolID: schoolID,
				userID: userID,
				specialistGroupID: null,
				classID: null,
				groupID: null,
				userType: userType,
			};
			return <StudentProfile onClose={() => this.setState({studentEditorOpened: false})}
			                       studentID={null}
			                       preSelected={preSelected} hasStudentsInClass={this.props.hasStudents}
			/>;
		}
	}
}
