import {BaseService} from '@esgi/core/service';
import moment from 'moment/moment';
import {
	Class,
	Group,
	Report,
	ReportAllStudents,
	ReportFormat,
	ReportType,
	SpecialistGroup,
	Student,
	Subject,
	Test,
} from 'modules/reports/test-history/models';
import {userStorage} from '@esgi/core/authentication';
import {BehaviorSubject, merge, skip, forkJoin, map, switchMap, zip, of, tap} from 'rxjs';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {getChartOptions, getDateString, setLocalTime} from 'modules/reports/test-history/utils';
import {TestType} from '@esgi/core/enums';
import {GetStudentReportChartBase64, RenderStudentGraph} from 'modules/reports/test-history/utils/all-students-utils';
import {size} from 'underscore';

export class TestHistoryAllStudentsService extends BaseService {
	public reportData = new BehaviorSubject<ReportAllStudents | null>(null);

	public selectedClassId = new BehaviorSubject<number>(null);
	public selectedTestId = new BehaviorSubject<number>(null);
	public selectedGroupId = new BehaviorSubject<number>(null);
	public selectedTest = new BehaviorSubject<Test[]>([]);
	public selectedSubject = new BehaviorSubject<Subject[]>([]);
	public selectedGroup = new BehaviorSubject<Group[]>([]);
	public selectedClass = new BehaviorSubject<Class[]>([]);
	public teacherName = new BehaviorSubject<string>('');
	public students = new BehaviorSubject<Array<Student>>([]);
	public selectedSpecialistGroup = new BehaviorSubject<SpecialistGroup[]>([]);
	public dateFrom = new BehaviorSubject<Date>(new Date());
	public dateTo = new BehaviorSubject<Date>(new Date());
	private readonly currentUser = userStorage.get();

	constructor(
		public hierarchy: HierarchySnapshot,
		public testId: number,
		public classId: number,
		public groupId: number,
	) {
		super();
	}

	public init() {
		return this.httpClient.ESGIApi.post<ReportAllStudents>(
			'reports/test-history',
			'init-all-students',
			{
				testId: this.testId,
				classId: this.classId,
				groupId: this.groupId,
				userId: this.currentUser.userID,
				fullHierarchy: this.hierarchy,
				globalSchoolYearID: this.currentUser.globalSchoolYearID,
			},
		).pipe(tap((data: ReportAllStudents) => {
			this.reportData.next(data);
			this.afterInit();
		}));
	}

	public getExportOptions(fileFormat: ReportFormat, type: ReportType) {
		const isSpecialist = !!this.selectedSpecialistGroup.value[0];
		const testName = this.selectedTest.value[0].name;
		const totalPossible = this.reportData.value.totalAnswers;
		const subjectName = this.selectedSubject.value[0].name;

		const tableDateHeader = 'Test session date';
		const tableTimeHeader = 'Test session time';
		const tableCorrectHeader = 'Avg. Score';

		const dateFrom = setLocalTime(this.dateFrom.value.toDateString());
		const dateTo = setLocalTime(this.dateTo.value.toDateString());
		const range = 'Test Sessions from ' + getDateString(dateFrom) + ' to ' + getDateString(dateTo);

		const unityName = this.selectedSpecialistGroup.value[0]
			? this.selectedSpecialistGroup.value[0]?.name
			: this.selectedGroup.value[0] ? this.selectedGroup.value[0]?.name : this.selectedClass.value[0]?.name;
		const unityTitle = this.selectedSpecialistGroup.value[0] || this.selectedGroup.value[0] ? 'Group' : 'Class';
		const {userID, globalSchoolYearID} = this.currentUser;

		return forkJoin(
			this.students.value
				.filter(({studentID}) => studentID > 0)
				.map((student) => this.getStudentExportOptions(student)),
		).pipe(map(((students) => ({
			fileFormat,
			type,
			classID: this.selectedClassId?.value ?? 0,
			groupID: this.selectedGroupId?.value ?? 0,
			range,
			isSpecialist,
			teacherName: this.teacherName.value,
			testName,
			totalPossible,
			subjectName,
			tableCorrectHeader,
			tableDateHeader,
			tableTimeHeader,
			unityName,
			unityTitle,
			userID,
			globalSchoolYearID,
			students: students.filter(({sessions}) => !!sessions.length),
		}))));
	}

	private getStudentExportOptions(student: Student) {
		return this.getStudentReportData(student.studentID)
			.pipe(
				switchMap((data) => zip(of(data), this.renderStudentChart(data, student))),
				switchMap(([data, chartRef]) => zip(of(data), GetStudentReportChartBase64(chartRef))),
				map(([{reportData}, chartBase64]) => {
					const {studentID, name: studentName} = student;

					return {
						studentID,
						studentName,
						chartBase64,
						sessions: reportData.testSessions.map(
							({date, score}) => ({
								testDate: moment(date).format('L LT'),
								score,
							}),
						),
					};
				}),
			);
	}

	private renderStudentChart(report: Report, student: Student) {
		const chartOptions = getChartOptions(
			report.reportData,
			this.dateTo.value,
			this.dateFrom.value,
			[student],
			TestType[this.selectedTest.value[0].type],
			report.testResultsCorrectVerbiage,
			report.testResultsIncorrectVerbiage,
			null,
		) || {};

		if (size(chartOptions)) {
			chartOptions.chart.animation = false;
			chartOptions.plotOptions.series.animation = false;
			chartOptions.title.text = null;
			chartOptions.tooltip.enabled = false;
		}

		return RenderStudentGraph(chartOptions);
	}

	private getStudentReportData(studentId: number) {
		return this.httpClient.ESGIApi.get<Report>(
			'reports/test-history',
			'init',
			{
				testId: this.testId,
				includeOnlyTestedStudents: false,
				studentId,
				fullHierarchy: this.hierarchy,
			},
		);
	}

	private afterInit() {
		merge(
			this.selectedClassId.pipe(skip(2)),
			this.selectedTestId.pipe(skip(2)),
			this.selectedGroupId.pipe(skip(2)),
		).subscribe(() => this.updateReport());
	}

	private updateReport() {
		const {userID: userId, globalSchoolYearID} = this.currentUser;

		this.httpClient.ESGIApi.post<ReportAllStudents>(
			'reports/test-history',
			'init-all-students',
			{
				testId: this.selectedTestId.value,
				classId: this.selectedClassId?.value ?? 0,
				groupId: this.selectedGroupId?.value ?? 0,
				fullHierarchy: this.hierarchy,
				userId,
				globalSchoolYearID,
			},
		).subscribe((data: ReportAllStudents) => {
			this.reportData.next(data);
		});
	}
}
