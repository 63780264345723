import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {deepCopy} from 'shared/utils';
import {isEqual, sortBy} from 'underscore';
import {RubricAnswer} from '../common/types';
import {buildAnswers, getInfo} from '../common/utils';
import {InitResponse, SessionModel, TestInfo} from './types';
import {SubjectType} from '@esgi/core/enums';
import {DateTools} from 'global/utils/date-utils';
import {
	IEPGoalModel,
	IEPStatusModel,
	StudentModel,
} from './components/iep-goal/models';
import {IEPGoalFormService} from './components/iep-goal/service';

export default class DataService extends BaseService {
	private controller: string = 'assessment/rubric-test-session-details';

	private rubricID: number;
	private studentID: number;
	private subjectID: number;
	private subjectType: SubjectType;

	private readonly cachedSessionToAnswers: Map<number, RubricAnswer[]> = new Map<number, RubricAnswer[]>();
	private readonly storedCurrentSession: BehaviorSubject<SessionModel> = new BehaviorSubject<SessionModel>(null);
	private readonly storedSessionAnswers: BehaviorSubject<RubricAnswer[]> = new BehaviorSubject<RubricAnswer[]>([]);

	public testInfo: TestInfo = null;
	public readonly sessions: BehaviorSubject<SessionModel[]> = new BehaviorSubject<SessionModel[]>([]);
	public readonly currentSession: BehaviorSubject<SessionModel> = new BehaviorSubject<SessionModel>(null);
	public readonly currentSessionAnswers: BehaviorSubject<RubricAnswer[]> = new BehaviorSubject<RubricAnswer[]>([]);
	public profileTime: string;

	constructor(private iepService: IEPGoalFormService) {
		super();
		this.storedSessionAnswers
			.pipe(takeUntil(this.destroy$))
			.subscribe(a => this.currentSessionAnswers.next(deepCopy(a)));
		this.storedCurrentSession
			.pipe(takeUntil(this.destroy$))
			.subscribe(s => s ? this.currentSession.next({...s}) : this.currentSession.next(null));
	}

	public init(rubricID: number, studentID: number, subjectID: number, subjectType: SubjectType): Observable<void> {
		return this.httpClient.ESGIApi
			.get<InitResponse>(this.controller, 'init', {rubricID, studentID})
			.pipe(map(r => {
				this.rubricID = rubricID;
				this.studentID = studentID;
				this.subjectID = subjectID;
				this.subjectType = subjectType;

				const {sessions, ...testInfo} = r;

				if (r.iepGoal) {
					const iepGoalModel: IEPGoalModel = {
						id: r.iepGoal.id,
						studentID: studentID,
						goal: r.iepGoal.goal,
						benchmark: r.iepGoal.benchmark,
						isCompleted: r.iepGoal.isCompleted,
						statusID: r.iepGoal.statusID,
					};
					const iepStatusesModel: IEPStatusModel[] = r.iepStatuses;
					const student: StudentModel = {
						studentID: studentID,
						firstName: r.studentFirstName,
						lastName: r.studentLastName,
						fullName: `${r.studentFirstName} ${r.studentLastName}`,
					};
					this.iepService.init(iepGoalModel, iepStatusesModel, student);
				}
				this.sessions.next(this.sortSessions(sessions));
				this.testInfo = {
					...testInfo,
					criteriaModels: sortBy(testInfo.criteriaModels, (c) => c.order),
					levelModels: sortBy(testInfo.levelModels, (l) => l.score).reverse(),
				} as TestInfo;
				this.profileTime = testInfo.nowProfileTZ;
			}), switchMap(r => {
				const lastSession = this.sessions.value[0]?.sessionID;
				return this.initSession(lastSession);
			})).asObservable();
	}

	public initSession(sessionID: number): Observable<void> {
		type AnswersResponse = { answers: RubricAnswer[] };
		let request: Observable<AnswersResponse>;
		if (sessionID) {
			if (this.cachedSessionToAnswers.has(sessionID)) {
				request = of({answers: this.cachedSessionToAnswers.get(sessionID)});
			} else {
				request = this.httpClient.ESGIApi
					.get<AnswersResponse>(this.controller, 'answers', {sessionID})
					.asObservable();
			}
		} else {
			request = of({answers: buildAnswers([], this.testInfo)});
		}

		return request
			.pipe(map(r => {
				if (sessionID) {
					const session = this.sessions.value.find(s => s.sessionID === sessionID);
					this.storedCurrentSession.next({...session});
					this.cachedSessionToAnswers.set(sessionID, r.answers);
				} else {
					this.storedCurrentSession.next(null);
				}

				this.storedSessionAnswers.next(r.answers);
			}));
	}

	public selectCard(id: number) {
		let answers = [...this.currentSessionAnswers.value];
		const {description, level} = getInfo(this.testInfo, answers).byDescription(id);
		const {answer} = getInfo(this.testInfo, answers).byCriteria(description.criteriaID);

		if (answer) {
			answers = answers.filter(a => a.criteriaID !== description.criteriaID);
			if (answer.score > 0) {
				if (answer.score === level.score) {
					answer.score = 0;
				} else {
					answer.score = level.score;
				}
			} else {
				answer.score = level.score;
			}
			answers.push(answer);
		}
		this.currentSessionAnswers.next(answers);
	}

	public updateNote(criteriaID: number, value: string): void {
		this.currentSessionAnswers.next(this.currentSessionAnswers.value.map(a => {
			if (a.criteriaID === criteriaID) {
				return {
					...a,
					notes: value,
				};
			}
			return {...a};
		}));
	}

	public startNewSession() {
		return this.httpClient.ESGIApi.get<{ nowProfileTime: string }>(this.controller, 'profile-time')
			.pipe(
				takeUntil(this.destroy$),
				map(timeInfo => {
					const user = userStorage.get();
					const session = {
						sessionID: -1,
						summaryNotes: '',
						testDate: timeInfo.nowProfileTime,
						duration: '',
						isDeleted: false,
						userID: user.userID,
						userName: user.firstName + ' ' + user.lastName,
					} as SessionModel;

					this.profileTime = timeInfo.nowProfileTime;
					this.sessions.next([session, ...this.sessions.value]);
					this.storedCurrentSession.next(session);
					this.storedSessionAnswers.next(buildAnswers([], this.testInfo));
				}));
	}

	public deleteCurrentSession(): Observable<{ testDate: string }> {
		return this.httpClient.ESGIApi.post(this.controller, 'delete', {
			testSessionID: this.currentSession.value.sessionID,
		}).pipe(
			takeUntil(this.destroy$),
			map(() => {
				this.sessions.next(this.sessions.value.map(s => {
					if (s.sessionID === this.currentSession.value.sessionID) {
						return {
							...s,
							isDeleted: true,
						};
					}
					return s;
				}));
				const testDate = this.storedCurrentSession.value.testDate;
				this.storedCurrentSession.next(this.sessions.value.find(s => s.sessionID === this.currentSession.value.sessionID));
				return {testDate};
			})).asObservable();
	}

	public restoreCurrentSession(): Observable<{ testDate: string }> {
		return this.httpClient.ESGIApi.post(this.controller, 'restore', {
			testSessionID: this.currentSession.value.sessionID,
		}).pipe(takeUntil(this.destroy$), map(() => {
			this.sessions.next(this.sessions.value.map(s => {
				if (s.sessionID === this.currentSession.value.sessionID) {
					return {
						...s,
						isDeleted: false,
					};
				}
				return s;
			}));
			const testDate = this.storedCurrentSession.value.testDate;
			this.storedCurrentSession.next(this.sessions.value.find(s => s.sessionID === this.currentSession.value.sessionID));
			return {testDate};
		})).asObservable();
	}

	public updateSessionDate(date: string): void {
		const currentSession = {...this.sessions.value.find(s => s.sessionID === this.currentSession.value.sessionID)};
		currentSession.testDate = DateTools.toServerString(date);
		this.currentSession.next(currentSession);
	}

	public updateSummaryNotes(notes: string): void {
		const currentSession = {...this.sessions.value.find(s => s.sessionID === this.currentSession.value.sessionID)};
		currentSession.summaryNotes = notes;
		this.currentSession.next(currentSession);
	}

	public exportCurrentSession(classID: number): void {
		this.exportSessions([this.currentSession.value.sessionID], classID);
	}

	public exportAllSessions(classID: number): void {
		this.exportSessions(this.sessions.value.map(s => s.sessionID), classID);
	}

	public saveChanges(): Observable<{ createMode: boolean, testDate: string }> {
		const answers = deepCopy(this.currentSessionAnswers.value);
		const session = this.currentSession.value;
		const createMode = session.sessionID < 0;
		let request: Observable<{ session: SessionModel, answers: RubricAnswer[] }>;

		if (createMode) {
			request = this.httpClient.ESGIApi.post<{ testSessionID: number }>(this.controller, 'create', {
				rubricID: this.rubricID,
				studentID: this.studentID,
				answers: answers,
				testDate: session.testDate,
				summaryNotes: session.summaryNotes,
			}).pipe(takeUntil(this.destroy$), map(r => {
				return {session: {...session, sessionID: r.testSessionID}, answers};
			})).asObservable();
		} else {
			const isSessionInfoChanged = !isEqual(session, this.storedCurrentSession.value);
			let isAnswersChanged = false;
			for (const answer of answers) {
				if (!isEqual(answer, this.storedSessionAnswers.value.find(sa => sa.criteriaID === answer.criteriaID))) {
					isAnswersChanged = true;
					break;
				}
			}
			if (!isSessionInfoChanged && !isAnswersChanged) {
				//Avoid call update if there are no changes.
				request = of().pipe(map(r => {
					return {session, answers};
				}));
			} else {
				request = this.httpClient.ESGIApi.post(this.controller, 'update', {
					testSessionID: session.sessionID,
					rubricID: this.rubricID,
					studentID: this.studentID,
					answers: answers,
					testDate: session.testDate,
					summaryNotes: session.summaryNotes,
				}).pipe(takeUntil(this.destroy$), map(r => {
					return {session, answers};
				})).asObservable();
			}
		}

		return request.pipe(map((r) => {
			this.storedSessionAnswers.next(r.answers);
			const newSessions = this.sessions.value
				.map(s => {
					if (s.sessionID === this.currentSession.value.sessionID) {
						return {...r.session};
					}
					return s;
				});

			this.sessions.next(this.sortSessions(newSessions));
			this.storedCurrentSession.next({...r.session});
			this.cachedSessionToAnswers.set(r.session.sessionID, r.answers);
			return {createMode, testDate: r.session.testDate};
		}));
	}

	public discardUnsavedChanges() {
		this.currentSessionAnswers.next(deepCopy(this.storedSessionAnswers.value));
		if (this.currentSession.value.sessionID < 0) {
			this.sessions.next(this.sessions.value.filter(s => s.sessionID !== this.currentSession.value.sessionID));
			if (this.sessions.value.length) {
				this.currentSession.next(this.sessions.value[0]);
			} else {
				this.currentSession.next(null);
			}
		} else {
			this.currentSession.next(this.storedCurrentSession.value);
		}
	}

	private exportSessions(sessions: number[], classID: number): void {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = `Test_Session_Details_${year}_${month}_${day}.pdf`;

		this.httpClient.ESGIApi.file(this.controller, 'export', filename, {
			rubricID: this.rubricID,
			studentID: this.studentID,
			subjectID: this.subjectID,
			subjectType: this.subjectType,
			classID: classID,
			filename: filename,
			testSessionIDs: sessions,
		}).pipe(takeUntil(this.destroy$)).subscribe();
	}

	private sortSessions(sessions: SessionModel[]): SessionModel[] {
		return sessions?.sort((s1, s2) => new Date(s1.testDate).getTime() - new Date(s2.testDate).getTime())
			.reverse();
	}
}
