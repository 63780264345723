import {PageSectionsState} from '../types';
import {WidgetType} from '@esgi/main/features/teacher/widgets';

export const defaultPageSectionState: PageSectionsState = {
	studentSection: {
		opened: true,
		classes: {
			opened: true,
			selectedIDs: [],
		},
		groups: {
			opened: false,
			selectedIDs: [],
		},
		students: {
			opened: false,
			selectedIDs: [],
		},
	},
	subjectSection: {
		opened: true,
		district: {
			opened: false,
			selectedIDs: [],
		},
		school: {
			opened: false,
			selectedIDs: [],
		},
		personal: {
			opened: false,
			selectedIDs: [],
		},
	},
	testSection: {
		pieChartMode: false,
	},
	widgets: {
		studentLevel: [WidgetType.AveragePerformance],
		classGroupLevel: [WidgetType.AveragePerformance],
	},
};
