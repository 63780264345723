import {Loader} from '@esgillc/ui-kit/loader';
import {useCallback, useState} from 'react';
import {Divider} from '../kit/divider';
import {Header} from './components/header';
import {AssignmentStatus} from 'pages/assignment-center/typings/assignment';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {TeacherInstructions} from './components/teacher-instructions';
import styles from './styles.module.less';
import {UserInfo} from '@esgi/core/authentication';
import {AssignmentModel} from './types/types';
import {useGetData} from './use-get-data';

type Props = {
	assignmentID: number;
	onStatusChanged: (status: AssignmentStatus) => void;
	onGoBack: VoidFunction;
	onEdit: (assignmentId: AssignmentModel['id']) => void;
	goToAssignmentList: VoidFunction;
	currentUser: Readonly<UserInfo>;
};

export function AssignmentCard({
	assignmentID,
	currentUser,
	goToAssignmentList,
	onEdit,
	onGoBack,
	onStatusChanged,
}: Props) {
	const [isTeacherInstructionsOpen, setIsTeacherInstructionsOpen] = useState(false);

	const {
		assignment,
		initialized,
		service,
		setAssignment,
		getAssignmentCardBody,
		isCardsDownloadingDisabled,
	} = useGetData({
		assignmentID,
		currentUser,
		goToAssignmentList,
	});

	const status = useBehaviorSubject(service.status);
	const isTeacherInstructionsBanned = useBehaviorSubject(service.isTeacherInstructionsBanned$);

	useStreamEffect(service.status, (state) => {
		if (initialized && assignment) {
			setAssignment({
				...assignment,
				state,
			});
			onStatusChanged(state);
		}
	});

	const onPostAssignment = useCallback(() => {
		service.post();
		setIsTeacherInstructionsOpen(true);
	}, [service]);

	const onTeacherInstructionsClose = useCallback(() => {
		setIsTeacherInstructionsOpen(false);
	}, []);

	if (!initialized) {
		return <Loader show />;
	}

	if (!assignment) {
		return null;
	}

	return (
		<div className={styles.container}>
			<Header
				assignment={assignment}
				status={status}
				onGoBack={onGoBack}
				onComplete={service.complete}
				onDelete={service.delete}
				onPost={onPostAssignment}
				onEdit={() => onEdit(assignment.id)}
			/>
			<Divider className={styles.divider} />
			{getAssignmentCardBody()}
			{isTeacherInstructionsOpen && !isTeacherInstructionsBanned && assignment.name && (
				<TeacherInstructions
					assignmentID={assignment.id}
					assignmentName={assignment.name}
					onModalClose={onTeacherInstructionsClose}
					service={service}
					isCardsDownloadingDisabled={isCardsDownloadingDisabled}
				/>
			)}
		</div>
	);
}
