import {Question, QuestionOptions} from './models';

const defaultFontSize = 28;
const lineHieghtCoef = 1.3;
export function getUrl(bucketName: string, url: string): string {
	let normalize = bucketName;
	if (bucketName === 'esgi' || bucketName === 'ESGI') {
		normalize = bucketName.toUpperCase();
		return `https://s3.amazonaws.com/${normalize}/${url}`;
	}

	return `https://${bucketName}.s3.amazonaws.com/${url}`;
}

export function isMultiple(question: Question) {
	return question.correctOptionsCount > 1;
}

// font size & line height
export enum TextSizeBreakpoints {
	md = 'MD',
	sm = 'SM',
	xs = 'XS',
	xxs = 'XXS',
}

type TextStyle = {
	fontSize: number;
	lineHeight: number;
}

const breakpointsValues = {
	[TextSizeBreakpoints.md]: [20, 21, 22, 23, 24, 25, 26, 27],
	[TextSizeBreakpoints.sm]: [20, 22, 23, 24, 26, 28, 30, 32],
	[TextSizeBreakpoints.xs]: [17, 18, 19, 20, 21, 22, 23, 24],
	[TextSizeBreakpoints.xxs]: [20, 21, 22, 24, 26, 28, 30, 32],
};

export function getTextStyles(text: string, breakpointsType = TextSizeBreakpoints.md): TextStyle {
	const length = text?.length;
	let fontSize = defaultFontSize;
	if (length > 200) {
		fontSize = breakpointsValues[breakpointsType][0];
	} else if (length > 100) {
		fontSize = breakpointsValues[breakpointsType][1];
	} else if (length > 50) {
		fontSize = breakpointsValues[breakpointsType][2];
	} else if (length > 25) {
		fontSize = breakpointsValues[breakpointsType][3];
	} else if (length > 15) {
		fontSize = breakpointsValues[breakpointsType][4];
	} else if (length > 8) {
		fontSize = breakpointsValues[breakpointsType][5];
	} else if (length > 3) {
		fontSize = breakpointsValues[breakpointsType][6];
	} else {
		fontSize = breakpointsValues[breakpointsType][7];
	}

	return {fontSize, lineHeight: getLineHeightByFontSize(fontSize)};
}

export function getLineHeightByFontSize(fontSize: number){
	return Math.round(fontSize * lineHieghtCoef);
}

export function getSmallestTextSize(options: QuestionOptions) {
	const longestText = options.map((option) => {
		return {text: 'text' in option ? option.text : ''};
	}).reduce((prev, current) => {
		return (prev.text?.length > current.text?.length) ? prev : current;
	});
	return getTextStyles(longestText.text, TextSizeBreakpoints.sm);
}
