import {Dispatch, DragEvent, ReactNode, useState} from 'react';
import {GridCardsContainer} from '../../../grid-cards-container.styled';
import {CardWrapper} from './index.styled';
import {isNull} from 'underscore';

type Props<T> = {
	items: T[];
	onDragEnd: Dispatch<T[]>;
	renderItem: (item: T) => ReactNode;
};

export function GridCardsRearrangeContainer<T>({items, onDragEnd, renderItem}: Props<T>) {
	const [temporaryItems, setTemporaryItems] = useState<T[] | null>(null);
	const [draggedItem, setDraggedItem] = useState<T | null>(null);

	const renderedItems = temporaryItems || items;

	const handleDragOver = (event: DragEvent<HTMLDivElement>, item: T) => {
		event.preventDefault();

		event.dataTransfer.dropEffect = 'move';

		if (isNull(draggedItem) || draggedItem === item) {
			return;
		}

		const currentIndex = renderedItems.indexOf(draggedItem);
		const targetIndex = renderedItems.indexOf(item);

		if (currentIndex !== -1 && targetIndex !== -1) {
			const newItems = [...renderedItems];

			newItems.splice(currentIndex, 1);
			newItems.splice(targetIndex, 0, draggedItem);

			setTemporaryItems(newItems);
		}
	};

	const handleDragEnd = () => {
		onDragEnd(renderedItems);
		setDraggedItem(null);
		setTemporaryItems(null);
	};

	return (
		<GridCardsContainer>
			{renderedItems.map((item, index) => (
				<CardWrapper
					draggable
					onDragStart={() => setDraggedItem(item)}
					onDragOver={(event) => handleDragOver(event, item)}
					onDragEnd={handleDragEnd}
					isDraggedItem={item === draggedItem}
					key={index}
				>
					{renderItem(item)}
				</CardWrapper>
			))}
		</GridCardsContainer>
	);
}
