import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ExpandablePanelContentWrapper = styled(GridBox, {
	height: '100%',
	gap: '$5',
	gridAutoFlow: 'row',
	alignContent: 'start',
	overflow: 'hidden',
});
