import {useEffect} from 'react';
import {Page} from './quote-payment';

export default function QuotePayment() {
	useEffect(() => {
		try {
			const page = new Page();
			return () => page.destroy();
		} catch (e) {
      console.error(e);
    }
	}, []);

	return (
		<div style={{backgroundColor: '#FFF'}}>
			<div
				style={{minHeight: 500, paddingTop: 15}}
				data-bind='render: quotePayment'
			></div>
		</div>
	);
}
