import {createContext, useContext} from 'react';
import {useService} from '@esgi/core/service';
import {ItemAnalysisService} from './services';

export const useItemAnalysis = () => {
	const service = useService(ItemAnalysisService);

	return {service};
};

export const ItemAnalysisContext = createContext(null);
export const useItemAnalysisService = () =>
	useContext<ItemAnalysisService>(ItemAnalysisContext);
