import React from 'react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {ConflictType, Validation, ValidationType} from '../../../../types';
import {SelectField} from './index.styled';

interface Props {
	onEdit: (value: string, validation: Validation) => void;
	uploadedLanguage: string;
	errorMessage?: string;
	existingLanguages: { value: string, name: string }[];
}

export function Language({uploadedLanguage, onEdit, errorMessage, existingLanguages}: Props) {

	const handleChangeLanguage = (value: string) => {
		if (value !== 'Other') {
			return onEdit(value, new Validation(ValidationType.Standard, ConflictType.UnknownLanguage, ''));
		}

		return onEdit(value, new Validation(ValidationType.Conflict, ConflictType.UnknownLanguage, 'This Language does not exist'));
	};

	return <Select.Root value={uploadedLanguage} onValueChange={handleChangeLanguage} errorMessage={errorMessage}>
		<SelectField placeholder='Language'/>
		<Select.Content>
			{existingLanguages.map(l => <Select.Option value={l.value} key={l.value}>
				<Text bold size='medium'>{l.name}</Text>
			</Select.Option>)}
		</Select.Content>
		<Select.ErrorText>{(errorText) => <Text size='xSmall'>{errorText}</Text>}</Select.ErrorText>
	</Select.Root>;
}

