import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	imgUrl: string;
	text?: string;
	className?: string;
	fullWidth?: boolean
}

export function Example({imgUrl, text, className, fullWidth}: Props){
	return (
		<div>
			{text && <p className={join(styles.example, className)} data-cy={'example-text'}>{text}</p>}
			<img
				className={join(fullWidth && styles.fullWidth)}
				src={imgUrl}
        alt='example image'
			/>
		</div>
	);
}
