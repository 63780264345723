import React from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import './footer.less';

export class Props extends SharedProps<State> {
    close: () => void;
    allowEdit: boolean;
    allowRestore: boolean;
    allowSave: boolean;
    closeButtonTitle: string;
    setEditMode: (mode: boolean) => void;
    saveClicked: () => void;
    restoreClicked: () => void;
    cancelClicked: () => void;
    saveDisabled: boolean;
}

export class State {
}

export class Footer extends SharedComponent<State, Props> {
	constructor(props?: Props) {
		super(props);
	}

	private cancelClicked() {
		this.props.cancelClicked();
	}

	private editClicked() {
		this.props.setEditMode(true);
	}

	private saveClicked() {
		this.props.setEditMode(false);
		this.props.saveClicked();
	}

	private restoreClicked() {
		this.props.setEditMode(false);
		this.props.restoreClicked();
	}

	render() {
		return <div className='tsd-footer'>
            <button className='btn btn-close close-button' onClick={() => this.cancelClicked()}>{this.props.closeButtonTitle}</button>
            {this.props.allowEdit &&
                <button className='btn btn-primary btn-edit edit-button' onClick={() => this.editClicked()}>Edit</button>
            }
            {this.props.allowRestore &&
                <button className='btn btn-primary btn-edit restore-button' onClick={() => this.restoreClicked()}>Restore</button>
            }

            {this.props.allowSave &&
                <button className='btn btn-primary btn-edit save-button' onClick={() => this.saveClicked()} disabled={this.props.saveDisabled}>Save</button>
            }
        </div >;
	}
}