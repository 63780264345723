import {FlexBox} from '../../../flex-box';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const TitleBlock = styled(FlexBox, {
	[`& > ${Text}`]: {
		color: '$neutral40',
	},
});

export const InfoBlock = styled(FlexBox, {
	minHeight: 40,
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingLeft: 12,
	paddingRight: 12,
	paddingTop: 8,
	paddingBottom: 8,
	borderRadius: 6,

	[`& > ${Text}`]: {
		color: '$base',
	},

	variants: {
		colorVariant: {
			gray: {
				backgroundColor: '$background',
			},

			white: {
				backgroundColor: '$vivid',
			},
		},

		type: {
			standalone: {
				'&:not(:last-child)': {
					marginBottom: 12,
				},
			},
			block: {
				'&:not(:first-child)': {
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				},

				'&:not(:last-child)': {
					marginBottom: 2,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},
			},
		},
	},
});
