import React from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './new-version-available.module.less';

type Props = {
	reloadClicked: VoidFunction,
	skipClicked: VoidFunction,
}

export function NewVersionAvailableAlert(props: Props) {
	return <div className={styles.alert}>
		<div className={styles.text}>
			<h4>An ESGI update is available.</h4>
			<span>Update now or automatically refresh later.</span>
		</div>
		<div className={styles.actions}>
			<Buttons.Text onClick={() => props.skipClicked()}>LATER</Buttons.Text>
			<Buttons.Text onClick={() => props.reloadClicked()}>UPDATE NOW</Buttons.Text>
		</div>
	</div>;
}
