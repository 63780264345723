import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const StudentFullName = styled(Box, {
	marginLeft: '8px',
});

export const ExistingStudentInfoContainer = styled(GridBox, {
	marginLeft: '12px',
	gridAutoRows: 'auto',
	gap: '2px',
});
