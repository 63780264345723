import {Entities} from 'api/entities/group';
import React from 'react';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {UserType, userStorage} from '@esgi/core/authentication';
import {IBoxInfo, TeachersGroupBox, GroupModelForAdmin} from '../../core/api';
import {HierarchyEvents} from '../../core/events';
import {BoxType, ClassicHierarchySnapshot} from '../../core/models';
import {OsChecker} from '@esgillc/ui-kit/utils';
import {EventBusDispatcher} from '@esgillc/events';
import {ViewType} from 'modules/forms/admin-group-forms/models/types';

export class Props {
	boxes: IBoxInfo[];
	groups: TeachersGroupBox;
	snapshot: ClassicHierarchySnapshot;
	onSelected: (id: number, callback: () => void) => void;
	onOrderChanged: (items: GroupModelForAdmin[], viewType: ViewType) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}

export class TeachersGroup extends React.PureComponent<Props> {
	private currentUser = userStorage.get();
	cmp: Box;
	private draggingTurnedOn: boolean = false;

	get items(): ItemProps[] {
		return this.props.groups.items.map(r => {
			return {id: r.groupID, title: r.name};
		});
	}

	render() {
		if (this.currentUser.userType !== UserType.D && this.currentUser.userType !== UserType.C) {
			return null;
		}

		if (this.props.snapshot.schoolsGroupID || (this.currentUser.userType !== UserType.C && this.props.snapshot.schoolID)) {
			return null;
		}

		const options: BoxOptions = {
			canDrag: false,
			boxType: BoxType.ClassicTeachersGroup,
			title: 'Teacher Groups',
			canCreateTooltip: null,
			canAdd: this.props.groups.canCreate,
			canEdit: this.props.groups.canEdit,
			tooltipPostfix: 'teacher group',
		};

		return <Box
			options={options}
			selectedID={this.props.snapshot.teachersGroupID}
			items={this.items}
			onDragEnd={null}
			onDragStart={null}
			empty={{message: 'You have 0 groups'}}
			scheduledIDs={null}
			onEditClicked={this.publish.edit}
			onAddClicked={this.publish.add}
			itemSelected={this.props.onSelected}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
			open={this.props.boxes.filter(t => t.boxType === BoxType.ClassicTeachersGroup && t.open).length > 0}
			ref={(r) => {
				this.cmp = r;
			}}
		/>;
	}

	publish = {
		add: () => {
			const args: HierarchyEvents.TeachersGroup.Add = {
				districtID: this.currentUser.districtID,
				schoolID: this.currentUser.schoolID,
				userID: this.currentUser.userID,
				userTypes: [UserType.T],
			};

			EventBusDispatcher.dispatch(HierarchyEvents.TeachersGroup.Add, args);
		},
		edit: (id: number) => {
			const item = this.props.groups.items.find(t => t.groupID === id);
			const args: HierarchyEvents.TeachersGroup.Edit = {
				districtID: this.currentUser.districtID,
				schoolID: this.currentUser.schoolID,
				userTypes: [UserType.T],
				id: item.groupID,
				name: item.name,
			};

			EventBusDispatcher.dispatch(HierarchyEvents.TeachersGroup.Edit, args);
		},
	};

	componentDidMount(): void {
		this.toggleDrag();
	}

	componentDidUpdate(prevProps: Readonly<Props>, snapshot?: any) {
		this.toggleDrag();
	}

	private toggleDrag() {
		if (this.props.groups.canEdit) {
			this.turnDragOn();
		} else {
			this.turnDragOff();
		}
	}

	private turnDragOff() {
		if (!this.draggingTurnedOn) {
			return;
		}

		$(this.cmp.rootElement).find('.box-body').sortable('destroy');
		this.draggingTurnedOn = false;
	}

	private turnDragOn() {
		if (this.draggingTurnedOn) {
			return;
		}

		if (!this.cmp) {
			return;
		}

		$(this.cmp.rootElement).find('.box-body').sortable({
			items: '.item:not(.empty)',
			opacity: 0.8,
			revert: true,
			handle: '.drag',
			forceHelperSize: true,
			placeholder: 'item placeholder',
			containment: 'parent',
			forcePlaceholderSize: true,
			tolerance: 'pointer',
			scroll: true,

			update: () => {
				const ids = $(this.cmp.rootElement).find('.item').toArray().map((e) => parseInt(e.getAttribute('data-id')))
					.filter(e => !isNaN(e));

				const groups = [...this.props.groups.items].sort((a, b) => ids.indexOf(a.groupID) - ids.indexOf(b.groupID));

				Entities.UsersGroup.reorder(ids);
				this.props.onOrderChanged(groups, ViewType.TeachersGroup);

				return;
			},
			start: (event, ui) => {
				if (OsChecker.isMac()) {
					$(ui.item).children('.drag').css({cursor: 'grabbing'});
				}
			},
			stop: (event, ui) => {
				if (OsChecker.isMac()) {
					$(ui.item).children('.drag').removeClass('mousedown').css({cursor: 'grab'});
				}
			},
		});

		this.draggingTurnedOn = true;
	}
}
