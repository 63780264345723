import {Fade} from '@esgillc/ui-kit/transition';
import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {PrevColumnButton} from '@esgi/deprecated/ui-kit/matrix/prev-column-button';
import {PrevRowButton} from '@esgi/deprecated/ui-kit/matrix/prev-row-button';
import {HeaderRenderer} from '@esgi/deprecated/ui-kit/matrix/types';
import {LevelDisplayMode} from '../../../models';
import RubricService from '../../../rubric-service';
import {ColumnWidth, RowHeight} from '../../utils';
import AddLevelButton from './components/add-level-button/add-level-button';
import LevelModeSelector from './components/level-mode-selector/level-mode-selector';
import styles from './header.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {SetMinimumLevel} from '../set-minimum-level';

class State {
	setMinimumLevelModalShow: boolean;
}

interface Props {
	showContextActions: boolean,
	headerRenderer: HeaderRenderer,
	levelsCount: number,
	displayMode: LevelDisplayMode,
	rubricService: RubricService,
	canAddLevel: boolean;
	canChangeLevelMode: boolean;
	onAddLevelClicked: () => void;
	onSetMinimumZeroLevel?: () => void;
	showEditButton: boolean;
}

export default class Header extends React.PureComponent<Props, State> {
	public state = new State();
	
	private renderSetMinimumLevelModal() {
		if (!this.state.setMinimumLevelModalShow){
			return null;
		}
		return <SetMinimumLevel
			onClose={() => this.setState({setMinimumLevelModalShow: false})}
			setMinimemLevel={() => {
				this.props.onSetMinimumZeroLevel();
				this.setState({setMinimumLevelModalShow: false});
			}}
			/>
	}

	private renderEditButton(){
		if(this.props.showEditButton){
			return (<Buttons.Link disabled={this.props.rubricService.isMinimumLevel}
				className={styles.removeButton}
				onClick={() => this.setState({setMinimumLevelModalShow: true})}>
				edit
			</Buttons.Link>);
		}
	}
	public render() {
		return <>
			{this.renderSetMinimumLevelModal()}
			<thead>
				<tr key={-1}>
					<th className={styles.actionsCell}>
						<div className={styles.container}>
							<PrevColumnButton className={styles.prevColumn} columnWidth={ColumnWidth} />
							<PrevRowButton className={styles.prevRow} rowHeight={RowHeight} />
							<Fade in={this.props.showContextActions} duration={100}>
								<div>
									<div className={styles.modeSelectorField}>
										<div className={styles.fieldHeader}>
											<span>Level Type:</span>
										</div>
										<div>
											<LevelModeSelector rubricService={this.props.rubricService}
												canChangeLevelMode={this.props.canChangeLevelMode}
												onUpdate={(mode) => this.props.rubricService.updateLevelDisplayMode(mode)}
												levelDisplayMode={this.props.displayMode} />
										</div>
									</div>
									<div className={styles.addLevelField}>
										<div className={styles.fieldHeader}>
											<span>
											{'Levels '}
											</span>
											{this.renderEditButton()}
											
											{!this.props.canAddLevel && <OnHoverTooltip message='Levels cannot be added or removed when test sessions exist.'>
												<QuestionMarkIcon className={styles.helpIcon} />
											</OnHoverTooltip>}
										</div>
										<AddLevelButton readonly={!this.props.canAddLevel}
											levelsCount={this.props.levelsCount}
											onClicked={this.props.onAddLevelClicked} />
									</div>
								</div>
							</Fade>
						</div>
					</th>
					{this.props.headerRenderer()}
				</tr>
			</thead>
		</>
	}
}
