import {Observable} from 'rxjs';
import {UploadFileErrorType} from '../../types';

export const fileSizeAndTypeValidator = (newFile: FileList, fileTypes: string[], maxFileSize: number): Observable<FileList> => {
	return new Observable(subscriber => {
		const file = newFile[0];
		const type = `.${file.type.split('/')[1]}`;
		if (file.size > maxFileSize) {
			subscriber.error({type: UploadFileErrorType.FileSize});
			subscriber.complete();
			return;
		}
		if (!fileTypes.includes(type)) {
			subscriber.error({type: UploadFileErrorType.FileType});
			subscriber.complete();
			return;
		}

		subscriber.next(newFile);
	});
};
