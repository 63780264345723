import {Authenticator, LoginResponse} from '@esgi/core/authentication';
import {Renderable, OldAlerts} from '@esgi/deprecated/knockout';
import {WindowTool, CookieReader} from '@esgi/deprecated/utils';
import {FieldContainer, FieldTool} from './common/component';
import {
	EmailInputComponent,
	FullNameFormComponent,
	InvitationCodeComponent,
	PasswordInputComponent,
	SchoolNameComponent,
	TimeZoneSelectComponent,
	UsernameInputComponent,
	PositionSelectComponent,
} from './components';
import {FlowResultModel, InvitationServer, UserInfoModel} from './server';
import {InvitationTemplate} from './template';
import {Loader} from '@esgi/deprecated/jquery';
import {ServerClient} from 'pages/landing/kit/server-client';

import './invitation.less';


export class Page {
	invitation: InvitationComponent;

	resolveParams() {
		return {
			Code: WindowTool.getUrlQueryParameter('code'),
			generateReCaptchaToken: this.generateReCaptchaToken,
		};
	}

	constructor(private generateReCaptchaToken) {
		this.generateReCaptchaToken = generateReCaptchaToken;
		const params = this.resolveParams();
		this.invitation = new InvitationComponent(params);
		this.invitation.load().done(() => {
			ko.applyBindings(this, document.body);
		});
	}

	public destroy() {
		ko.cleanNode(document.body);
	}
}

export interface IOptions {
	Code: string;
	generateReCaptchaToken?: (action: string) => Promise<string>;
}

export class InvitationComponent extends Renderable {
	private sessionExpiredTimer;
	readonly action: string = 'invitation';
	loader: Loader;
	code: InvitationCodeComponent;
	schoolName: SchoolNameComponent;
	email: EmailInputComponent;
	username: UsernameInputComponent;
	password: PasswordInputComponent;
	fullName: FullNameFormComponent;
	timezone: TimeZoneSelectComponent;
	position: PositionSelectComponent = null;
	fields: Array<FieldContainer>;
	userCreating: KnockoutObservable<boolean> = ko.observable(false);
	codeIsValid: KnockoutObservable<boolean> = ko.observable(false);
	generateReCaptchaToken;

	constructor(options: IOptions) {
		super();
		this.sessionExpiredTimer = this.getSessionExpiredTimer();
		this.userCreating(false);
		this.createFields(options.Code);
		this.generateReCaptchaToken = options.generateReCaptchaToken;
	}

	template = () => InvitationTemplate.Render();

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		this.loader = new Loader($('.invitation-container'));
		return super.afterRender(rootElement);
	}

	createFields(code?: string): void {
		this.code = new InvitationCodeComponent(code);
		this.schoolName = new SchoolNameComponent();
		this.email = new EmailInputComponent('', true);
		this.username = new UsernameInputComponent();
		this.password = new PasswordInputComponent();
		this.fullName = new FullNameFormComponent();
		this.timezone = new TimeZoneSelectComponent();
		this.email.validationResult.subscribe((v) => {
			if (!this.username.serialize() && !v.isExist && v.isValid) {
				this.username.trySetUserName(v.value);
			}
		});
		this.code.validationCodeResponse.subscribe((r) => {
			this.codeIsValid(r.isValid);
			this.schoolName.setValue(r.schoolName);
			if (r.userType === 'D' || r.userType === 'C') {
				this.position = new PositionSelectComponent();
				this.fields.push(this.position);
			}
		});
		this.fields = new Array<FieldContainer>();
		this.fields.push(
			...[this.email, this.code, this.username, this.password],
		);
	}

	isValid(): JQueryPromise<boolean> {
		return $.whenAll([...this.fields.map((f) => f.validate())]).pipe(
			(r: Array<boolean>) => {
				const indexOfFirstFailed = r.indexOf(false);
				if (indexOfFirstFailed !== -1) {
					FieldTool.scrollToField(this.fields[indexOfFirstFailed]);
				}
				return r.reduce((a, b) => a && b);
			},
		);
	}

	serialize(): FlowResultModel<UserInfoModel> {
		return <FlowResultModel<UserInfoModel>>{
			model: {
				code: this.code.serialize(),
				email: this.email.serialize().trim(),
				userName: this.username.serialize().trim(),
				password: this.password.serialize(),
				title: this.fullName.title.value(),
				firstName: this.fullName.firstName.value(),
				lastName: this.fullName.lastName.value(),
				timeZone: this.timezone.serialize().id,
				captchaToken: localStorage.getItem('RECAPTCHA_TOKEN'),
				action: this.action,
				position: this.position != null ? this.position.serialize(): null,
			},
		};
	}

	ui = {
		register: () => {
			this.userCreating(true);
			this.isValid().done(async (r) => {
				if (r) {
					await this.generateReCaptchaToken(this.action);

					const data = this.serialize();
					this.loader.mask();
					InvitationServer.register(data.model).done((response) => {
						if (!response.isSuccess) {
							const errorMessage = response?.errors?.[0].description;
							errorMessage && OldAlerts.bsalert(errorMessage);
							return;
						}
						const {token} = response.value;
						this.loginByToken(token);
						this.userCreating(false);
						this.loader.unmask();

					});
				} else {
					this.userCreating(false);
				}
			});
		},
	};

	private loginByToken = (token: string) => {
		clearTimeout(this.sessionExpiredTimer);
		ServerClient.ESGIAPI.Post<LoginResponse>('Login', 'ByRegistration', {
			data: {token: token},
		}).done((r) => {
			const authenticator = new Authenticator();
			authenticator.authenticate(r).then((authenticated) => {
				if (authenticated) {
					document.location.href = '/esgi';
				}
			});
		});
	};

	private getSessionExpiredTimer() {
		const token = CookieReader.read('esgi-token');
		const parsedToken = this.parseJwt(token);
		if (parsedToken) {
			const expTimestamp = parsedToken.exp * 1000;
			const curTimestamp = new Date().getTime();
			return setTimeout(() => {
				OldAlerts.bsalert(
					'This session has expired, please login with your username and password',
					() => {
						this.goToLogin();
					},
				);
			}, expTimestamp - curTimestamp);
		}
	}

	private parseJwt(token: string) {
		if (!token) {
			return null;
		}
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	}

	private goToLogin = () => {
		clearTimeout(this.sessionExpiredTimer);
		window.location.replace('/login');
	};
}
