import {
	EntityModel,
	LecturerModel,
	NamingWritingPrintSettings,
	StudentStepState,
	UnitType,
} from '../../types';

export class NameWritingReportStudentData {
	public unitId: number;
	public unitName: string;
	public titleNameFontSize: number = 60;

	constructor(
		public studentId: number,
		public bodyFontSize: number,
		public bodyName: string,
		public fullName: string,
		public linesCount: number,
		unitData: EntityModel,
	) {
		this.unitId = unitData.id;
		this.unitName = unitData.name;
	}
}

export class NameWritingReportDetails {
	public unitType: UnitType;
	public lecturer: LecturerModel;
	public copiesCount: number;
	public isFontSizeOptimized: boolean;

	constructor(
		unitData: StudentStepState,
		printSettings: NamingWritingPrintSettings,
		isFontSizeOptimized: boolean,
	) {
		this.unitType = unitData.unitType;
		this.lecturer = unitData.lecturer;
		this.copiesCount = printSettings.copiesCount;
		this.isFontSizeOptimized = isFontSizeOptimized;
	}
}

export class NameWritingReport {
	constructor(
		public details: NameWritingReportDetails,
		public students: NameWritingReportStudentData[],
	) {
	}
}