import {AppEvent} from '@esgillc/events';

@AppEvent({broadcast: true})
export class ClassChangedEvent {
	constructor(public id: number, public name: string, public students: number[]) {
	}
}

@AppEvent({broadcast: true})
export class ClassCreatedEvent {
	constructor(public id: number,
	            public name: string,
	            public userID: number,
	            public students: number[]) {
	}
}

@AppEvent({broadcast: true})
export class ClassRemovedEvent {
	constructor(public id: number) {
	}
}
