import * as React from 'react';
import type {SVGProps} from 'react';

export function View1(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M8 10.183C8 9.25352 8.75405 8.5 9.68421 8.5H22.3158C23.2459 8.5 24 9.25352 24 10.183L24 12.317C24 13.2465 23.246 14 22.3158 14H9.68425C8.75408 14 8.00004 13.2465 8.00004 12.317L8 10.183Z'
				fill='#333333'
			/>
			<path
				d='M8.00004 19.683C8.00004 18.7535 8.75408 18 9.68425 18H22.3158C23.246 18 24 18.7535 24 19.683L24 21.817C24 22.7465 23.2459 23.5 22.3158 23.5H9.68421C8.75405 23.5 8 22.7465 8 21.817L8.00004 19.683Z'
				fill='#333333'
			/>
		</svg>
	);
}
