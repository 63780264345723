import React from 'react';
import {Models} from 'shared/modules/reports/flash-cards/results-form/models';
import {userStorage} from '@esgi/core/authentication';
import './component.less';
import {createQuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {environment, Environments} from '@esgi/core/environments';

class State {
	isLoaded: boolean;
}

class Props {
	card: Models.Card;
}

export default class ImageCard extends React.Component<Props, State> {
	private currentUser = userStorage.get();
	private img: HTMLImageElement;

	constructor(props?: Props) {
		super(props);
		this.state = new State();
	}

	private getAssetsSource = () => {
		const host = environment.esgiApiURL;
		const url = '/reports/flash-cards/QuestionImage?questionId=' + this.props.card.questionId + '&width=400&height=253';
		this.img.src = host + url;
	};

	private getS3Source = () => {
		const urlBuilder = createQuestionImageUrlBuilder();
		return urlBuilder.white(this.props.card.questionId, this.props.card.testId, true, this.props.card.ticks);
	};

	render() {
		if (this.props.card.isEmpty) {
			return <div></div>;
		}

		return <div className='card-image'>
			<div style={this.state.isLoaded ? {display: 'none'} : {display: 'inherit'}}>Loading</div>
			<img style={this.state.isLoaded ? {display: 'inherit'} : {display: 'none'}}
				 ref={r => this.img = r} alt={this.props.card.questionId.toString()}
				 src={this.getS3Source()}
				 onLoad={() => {
					 this.setState({isLoaded: true});
				 }}
				 onError={this.getAssetsSource}
			/>
		</div>;
	}
}
