import {Box} from '../../../../layout/box';
import {styled} from '../../../../theme';

export const InputContainer = styled(Box, {
	position: 'relative',

	'& input': {
		'&[data-state=valued]': {
			'&[data-error=true]': {
				'&:not([data-disabled=true])': {
					color: '$base',
				},
			},
		},
	},
});
