import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Container = styled(GridBox, {
	gap: 6,
	borderRadius: 6,
	borderStyle: 'dashed',
	borderWidth: 1,
	borderColor: '$primary72',
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 12,
	paddingRight: 8,
	color: '$primary',
	width: '100%',
	justifyContent: 'space-between',
	alignItems: 'center',
	gridAutoFlow: 'column',
	marginBottom: 6,
	background: 'linear-gradient(272deg, #F3F9FC 48.17%, #F7FBFD 100%)',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'&:hover': {
		color: '$secondary',
		borderColor: '$secondary',
		background: 'unset',
		backgroundColor: '$secondaryBackground',
	},
});
