import {useMemo} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CirclePlusIcon} from '@esgillc/ui-kit/icons';
import {useTestSessionDetailsService} from '../../../../hooks';
import {dispatchAppEvent} from '@esgillc/events';
import {TestSessionDeleteEvent, TestSessionAddEvent} from '../../../../events';
import styles from './styles.module.less';

interface Props {}

export function SingleScoreEditButtons({}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const editMode = useBehaviorSubject(service.editMode$);

	const canChange = useMemo<boolean>(
		() => testSessionDetails ? testSessionDetails.canEdit : false,
		[testSessionDetails],
	);

	const onAdd = () => {
		dispatchAppEvent(TestSessionAddEvent, new TestSessionAddEvent());
	};

	const onDelete = () => {
		dispatchAppEvent(TestSessionDeleteEvent, new TestSessionDeleteEvent());
	};

	if (!editMode && canChange) {
		return (
			<Buttons.Link
				className={styles.button}
				onClick={onAdd}
			>
				<CirclePlusIcon/>
				Add Test Session
			</Buttons.Link>
		);
	}

	if (canChange && !currentTestSession.deleted && !currentTestSession.isEmpty) {
		return (
			<Buttons.Link
				className={styles.button}
				onClick={onDelete}
			>
				Delete test session
			</Buttons.Link>
		);
	}
}
