import React, {useState} from 'react';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {ElementStatus, FormElement} from '@esgillc/ui-kit/form';
import {Radio} from '@esgillc/ui-kit/form-control';
import {DistrictTab} from '../../../../../../types';
import Selector from 'modules/subject-details/components/sections/publish-to/district/selector';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {DistrictCommonData} from 'modules/subject-details/types';
import styles from '../styles.module.less';
import PublishToSpecialists = DistrictTab.PublishToSpecialists;

interface Props {
	form: DistrictFormType;
	districtSubjectData: DistrictCommonData;
}

export default function SpecialistsSubSection({districtSubjectData, form}: Props) {

	const [nameStatus, setNameStatus] = useState<ElementStatus>(form.controls.subjectName.status);
	const [prevSelectedType, setPrevSelectedType] = useState<PublishToSpecialists>(PublishToSpecialists.All);

	const [isShowISDSelector, setShowISDSelector] = useState<boolean>(false);
	const [isShowISSSelector, setShowISSSelector] = useState<boolean>(false);
	const [selectedISDCount, setSelectedISDCount] = useState<number>(0);
	const [selectedISSCount, setSelectedISSCount] = useState<number>(0);
	useStreamEffect(form.controls.publishToSpecialists.onChanged, (v) => v.reason === 'value' && setPrevSelectedType(v.prevState.value));
	useStreamEffect(form.controls.subjectName.onChanged, (v) => v.reason === 'status' && setNameStatus(v.currState.status));

	const openSpecialistSelector = (event: React.MouseEvent<HTMLInputElement>, type: 'showISDSelector' | 'showISSSelector') => {
		if(nameStatus !== ElementStatus.invalid) {
			if (type === 'showISDSelector') {
				setShowISDSelector(true);
			}
			if (type === 'showISSSelector') {
				setShowISSSelector(true);
			}
		}
	};

	return <div data-cy={'specialists-subsection'}>
		<strong className={styles.title}>Specialists</strong>
		<FormElement control={form.controls.publishToSpecialists}>
			<Radio
				value={PublishToSpecialists.All}
				className={styles.radio}
			>All District and School Specialists [{districtSubjectData.specialistsCount}]</Radio>
			<Radio
				disabled={!districtSubjectData.districtSpecialists.length}
				title={!districtSubjectData.districtSpecialists.length && 'No district specialists exist'}
				value={PublishToSpecialists.IndividualISD}
				onClick={(e) => openSpecialistSelector(e, 'showISDSelector')}
				className={styles.radio}
			>District Specialists {form.controls.publishToSpecialists.value === PublishToSpecialists.IndividualISD &&
			selectedISDCount ? `[${selectedISDCount}]` : ''}</Radio>
			<Radio
				disabled={!districtSubjectData.schoolSpecialistsSchools.length}
				title={!districtSubjectData.schoolSpecialistsSchools.length && 'No school specialists exist'}
				value={PublishToSpecialists.IndividualISS}
				onClick={(e) => openSpecialistSelector(e, 'showISSSelector')}
				className={styles.radio}
			>School
				Specialists {form.controls.publishToSpecialists.value === PublishToSpecialists.IndividualISS && selectedISSCount ? `[${selectedISSCount}]` : ''}</Radio>

			<Radio
				value={PublishToSpecialists.None}
				className={styles.radio}
			>None</Radio>
		</FormElement>
		{isShowISDSelector && <div data-cy={'isd-selector'}>
			<Selector
				isShowISDSelector
				districtSubjectData={districtSubjectData}
				subjectTabName={form.controls.subjectName.value}
				initiallySelected={form.controls.isd.value}
				onCancel={() => {
					setShowISDSelector(false);
					form.controls.publishToSpecialists.value = prevSelectedType;
				}}
				onConfirm={(v) => {
					setShowISDSelector(false);
					setSelectedISDCount(v.length);
					form.controls.isd.value = v.map(v => v.id);
				}}/></div>
		}

		{isShowISSSelector && <div data-cy={'iss-selector'}>
			<Selector
				isShowISSSelector
				districtSubjectData={districtSubjectData}
				subjectTabName={form.controls.subjectName.value}
				initiallySelected={form.controls.iss.value}
				onCancel={() => {
					setShowISSSelector(false);
					form.controls.publishToSpecialists.value = prevSelectedType;
				}}
				onConfirm={(v) => {
					setShowISSSelector(false);
					setSelectedISSCount(v.length);
					form.controls.iss.value = v.map(v => v.id);
				}}/></div>
		}
	</div>;
}
