import styles from './styles.module.less';
import {Radio} from '@esgillc/ui-kit/control';
import {ReportFiltersLabel, ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters';
import {ReportFiltersLabelContainer, ReportFiltersContent} from '../report-filter';

type DisplayNotTestedFilterProps = {
	displayZeroIfNotTested: boolean;
	onChanged: (displayZeroIfNotTested: boolean) => void;
};

export function DisplayNotTestedFilter({displayZeroIfNotTested, onChanged}: DisplayNotTestedFilterProps) {
	return (
		<ReportFiltersContainer>
			<ReportFiltersLabelContainer>
				<ReportFiltersLabel>Display not tested as:</ReportFiltersLabel>
			</ReportFiltersLabelContainer>
			<ReportFiltersContent>
				<Radio
					className={styles.radioLabel}
					value='Zero'
					onChange={() => onChanged(true)}
					checked={!!displayZeroIfNotTested}
				>
					Zero
				</Radio>
				<Radio
					className={styles.radioLabel}
					value='NT'
					onChange={() => onChanged(false)}
					checked={!displayZeroIfNotTested}
				>
					NT
				</Radio>
			</ReportFiltersContent>
		</ReportFiltersContainer>
	);
}
