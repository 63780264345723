import moment from 'moment';
import {DateRange, TestModel, TestChartModel} from 'modules/reports/iep-progress/types';
import {HighchartsOptions, Highcharts} from '@esgi/deprecated/highcharts';


export const getGroupAndStudent = (studentId, groups) => {
	for (const group of groups) {
		const student = group.students.find(({id}) => id === studentId);
		if (student) {
			return {group, student};
		}
	}
	return false;
};

export const getDateWithoutTimeZone = (value: Date, format = 'toISOString') => {
	if (!value) {
		return value;
	}

	const timeZoneOffset = value.getTimezoneOffset() * 60000;
	const dateWithOffset = new Date(value.getTime() - timeZoneOffset);
	const formatted = format in dateWithOffset
		? dateWithOffset[format]()
		: dateWithOffset;
	if (format === 'toISOString') {
		return formatted.slice(0, 19);
	}
	return formatted;
};

export function convertDate(value: string): number {
	return moment.utc(value).toDate().getTime();
}

export function getDateOrDefault(value: string, minValue: number): number {
	const numValue = convertDate(value);
	if (numValue < minValue) {
		return minValue - 2629746000;
	}
	return numValue;
}

export function xPositions(model: TestModel, dateRange: DateRange) {
	const from = dateRange ? dateRange.from : model.dateRange.from;
	const to = dateRange ? dateRange.to : model.dateRange.to;
	const arr = new Array<number>();

	arr.push(convertDate(from));
	arr.push(convertDate(to));

	return arr;
}

export function positions(maxValue, dep = 1) {
	if (maxValue <= 10 && dep === 1) {
		const def = [];
		for (let i = 0; i <= maxValue; i++) {
			def.push(i);
		}
		return def;
	}

	const midValue = Math.floor(maxValue / 2);

	let divider = 1;
	for (let i = midValue; i > 1; i--) {
		if (maxValue % i === 0) {
			divider = i;
			break;
		}
	}

	let array = [0];
	if (divider !== 1) {
		for (let i = divider; i <= maxValue; i += divider) {
			array.push(i);
		}
	} else {
		array = positions(Math.floor((maxValue * 2) / 3), dep + 1);
		array.push(maxValue);
	}

	return array;
}

export function historyOptions(test: TestModel, dateRange: DateRange): HighchartsOptions {
	const from = dateRange ? dateRange.from : test.dateRange.from;
	const to = dateRange ? dateRange.to : test.dateRange.to;
	const startMp = convertDate(from);

	const model = {...test};
	let data = [{
		x: getDateOrDefault(model.testResults[0].date, startMp),
		y: model.testResults[0].score,
		marker: {
			symbol: 'diamond',
			radius: 6,
			states: {
				hover: false,
				select: false,
			},
		},
		rawDate: convertDate(model.testResults[0].date),
	}];
	const sessions = model.testResults.slice(1).map((value, index) => {
		return {
			x: getDateOrDefault(value.date, startMp),
			y: value.score,
			marker: {
				symbol: 'circle',
				radius: 4,
				states: {
					hover: false,
					select: false,
				},
			},
			rawDate: convertDate(value.date),
		};
	});

	data = [...data, ...sessions]

	return {
		title: {
			text: '',
		},

		plotOptions: {
			series: {
				events: {
					legendItemClick: function(e) {
						e.preventDefault();
					},
				},
				marker: {
					enabledThreshold: 0,
					enabled: true,
					states: {
						hover: {
							enabled: false,
						},
					},
				},
			},
		},
		chart: {
			backgroundColor: null,
		},

		xAxis: {
			title: {
				text: 'Test Session Date',
			},
			tickmarkPlacement: 'on',
			type: 'datetime',
			softMin: startMp,
			min: convertDate(from),
			softMax: convertDate(to),
			max: convertDate(to),
			startOnTick: true,
			endOnTick: true,
			minPadding: 10,
			maxPadding: 10,
			showFirstLabel: true,
			showLastLabel: true,
			gridLineWidth: 1,
			tickPositions: xPositions(model, dateRange),
			labels: {
				formatter: function () {
					if (startMp > this.value) {
						return 'B';
					}
					let str = Highcharts.dateFormat('%m/%e', this.value);
					if (str[0] === '0') {
						str = str.substr(1);
					}
					return str;
				},
			},
		},

		yAxis: {
			title: {
				text: 'Score',
			},
			softMin: 0,
			minTickInterval: 1,
			max: model.totalPossible,
			softMax: model.totalPossible,
			showLastLabel: true,
			endOnTick: true,
			startOnTick: true,
			showFirstLabel: true,
			maxPadding: 0,
			minPadding: 0,
			tickmarkPlacement: 'on',
			tickInterval: 1,
			tickPositions: positions(model.totalPossible),
			ordinal: false,
		},
		tooltip: {
			enabled: true,
			formatter: function () {
				return '<span style="font-size: 10px">' + Highcharts.dateFormat('%m-%d-%Y', this.x) + '</span><br/><b>' + this.y + ' / ' + model.totalPossible + '</b><br/>';
			},
		},
		legend: {
			enabled: true,
			align: 'center',
			verticalAlign: 'bottom',
			margin: 0,
		},

		credits: {
			enabled: false,
		},
		series: [
			{
				name: 'Baseline',
				marker: {
					symbol: 'diamond',
				},
				color: '#7B7B7B',
				states: {
					hover: {
						enabled: false,
					},
				},
				data: [],
			},
			{
				type: 'line',
				stickyTracking: false,
				name: 'Test sessions',
				color: '#7B7B7B',
				legendSymbol: 'diamond',
				states: {
					hover: {
						enabled: false,
					},
				},
				data: data,
			}],
	};
}

export function getImageFromSVG(testId, svg): Promise<TestChartModel> {
	return new Promise((resolve, reject) => {
		const imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)));
		let model: TestChartModel = {
			id: testId,
			chart: '',
		}
		let canvas = document.querySelector(`#iep-canvas-${testId}`) as any;
		if (!canvas) {
			const canvasElem = document.createElement('canvas');
			canvasElem.id = `iep-canvas-${testId}`;
			document.body.appendChild(canvasElem);
		}
		canvas = document.querySelector(`#iep-canvas-${testId}`) as any;
		const context = canvas.getContext('2d');
		canvas.setAttribute('width', 365);
		canvas.setAttribute('height', 190);
		loadImage(imgsrc).then((image) => {
			context.drawImage(image, 0, 0);
			const canvasdata = canvas.toDataURL('image/png');
			model.chart = canvasdata.substring(22);
			resolve(model);
			canvas.parentNode.removeChild(canvas);
		})
	})
}

const loadImage = (url) => new Promise((resolve, reject) => {
	const img = new Image();
	img.src = url;
	img.addEventListener('load', () => {
		resolve(img)
	});
	img.addEventListener('error', (err) => {
		reject(err)
	});
});