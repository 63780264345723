import {Text} from '@esgi/ui/typography';
import {Container} from './index.styled';
import {Button} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';

type Props = {
	onCloseIconClick: VoidFunction;
};

export function ActiveAddItem({onCloseIconClick}: Props) {
	return (
		<Container>
			<Text size='medium' bold>
				New Marking Period
			</Text>

			<Button.Icon onClick={onCloseIconClick}>
				<Close />
			</Button.Icon>
		</Container>
	);
}
