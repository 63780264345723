import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const ContainerStyled = styled(Box, {
	padding: '10px 20px 20px 20px',

	'> :last-child': {
		borderBottom: 'none',
	},
});

export const PageStyled = styled(Box, {
	borderBottom: '1px solid #BDBDBD',
});