import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const Container = styled(GridBox, {
	background: 'linear-gradient(180deg, $background 0%, $vivid 100%)',

	'&::after': {
		content: '',
		width: '100%',
		height: '100%',
		background: 'repeating-linear-gradient(35deg, $vivid, $vivid 3px, transparent 0, transparent 10px)',
		gridColumnStart: 1,
		gridColumnEnd: -1,
		gridRowStart: 1,
		gridRowEnd: 1,
	},
});

export const ContentWrapper = styled(GridBox, {
	gridColumnStart: 1,
	gridColumnEnd: -1,
	gridRowStart: 1,
	gridRowEnd: 1,
	zIndex: 2,
});

export const Content = styled(GridBox, {
	justifyItems: 'center',
	padding: '6px 16px',
	gridTemplateColumns: 'repeat(3, 1fr)',
	'& > :nth-child(1)': {
		gridColumnStart: 2,
	},
	'& > :nth-child(2)': {
		marginLeft: 'auto',
	},
});

export const StyledButton = styled(Button.Text, {
	alignItems: 'center',

	'& svg': {
		width: 32,
		height: 32,
		marginLeft: -4,
		transform: 'rotate(180deg)',
	},

	'&:hover': {
		'a': {
			color: 'currentColor',
		},
	},

	'a': {
		textDecoration: 'none',
	},
});

export const ButtonText = styled(Button.Text, {
	alignItems: 'center',
	color: '$base',

	'& svg': {
		width: 16,
		height: 16,
		marginRight: 8,
	},
});