import {Dispatch, RefObject, createContext, useContext} from 'react';
import {TestDashboardSectionID} from '../../types';
import {noop} from 'underscore';

export type ScrollAreaContextValue = {
	setSectionRef: Dispatch<{sectionID: TestDashboardSectionID; ref: RefObject<HTMLElement>}>;
};

export const ScrollAreaContext = createContext<ScrollAreaContextValue>({
	setSectionRef: noop,
});

export function useScrollAreaContext() {
	return useContext(ScrollAreaContext);
}
