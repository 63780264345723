import React from 'react';
import styles from '../../styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../../services/report-service';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Checkbox} from '@esgillc/ui-kit/control';

interface Props {
	service: ReportService;
}

export function DetailReportSettingsResultsBlock({service}: Props) {
	const teacher = useBehaviorSubject(service.settingsService.teacher);
	const carryScoresForward = useBehaviorSubject(service.settingsService.carryScoresForward);
	const includeGradeScore = useBehaviorSubject(service.settingsService.includeGradeScore);
	const report = useBehaviorSubject(service.dataService.report);
	const showBaselineUpdating = useBehaviorSubject(service.settingsService.showBaselineUpdating);
	const showBaseline = useBehaviorSubject(service.settingsService.showBaseline);
	return <div className={join(styles.settingsCell, styles.resultsBlock)}>
		<span className={styles.title}>Results:</span>
		<div className={styles.resultsSettings}>
			<div>
				<Checkbox checked={carryScoresForward}
				          className={styles.checkbox}
				          onClick={() => service.settingsService.carryScoresForward.next(!carryScoresForward)}
				          id={teacher.carryScoresForward.name}
				>
				</Checkbox>
				<label className={styles.checkboxLabel} htmlFor={teacher.carryScoresForward.name}>
					<span>Carry forward</span>
					<OnHoverTooltip message='Help'>
						<a
							href='https://support.esgisoftware.com/hc/en-us/articles/360024667492-Student-Detail-Report'
							target='_blank' rel='noreferrer'>
							<sup className='fa fa-question-circle' aria-hidden='true'/>
						</a>
					</OnHoverTooltip>
				</label>
			</div>
			<div id='include-grade-score-checkbox-container'>
				{report.gradeScaleAllow ?
					<>
						<Checkbox className={styles.checkbox}
						          checked={includeGradeScore && report.gradeScaleAllow}
						          onClick={() => service.settingsService.includeGradeScore.next(!includeGradeScore)}
						          id={teacher.includeGradeScore.name}
						>
						</Checkbox>
						<label className={styles.checkboxLabel}
						       htmlFor={teacher.includeGradeScore.name}><span>Show Grades</span></label>
					</>
					: <OnHoverTooltip message={'You need to configure Grade Scales to use this option.'}>
						<div className={styles.checkbox}>
							<Checkbox className={styles.checkbox}
							          checked={includeGradeScore && report.gradeScaleAllow}
							          disabled={!report.gradeScaleAllow}
							          onClick={() => service.settingsService.includeGradeScore.next(!includeGradeScore)}
							          id={teacher.includeGradeScore.name}
							>
							</Checkbox>
							<label className={styles.checkboxLabel}
							       htmlFor={teacher.includeGradeScore.name}><span>Show Grades</span></label>
						</div>
					</OnHoverTooltip>
				}
			</div>
			<div>
				<Checkbox
					className={styles.checkbox}
					checked={showBaseline}
					onClick={() => service.settingsService.showBaselineChanged()}
					id='show-baseline-checkbox'
					disabled={showBaselineUpdating}
				/>
				<label className={styles.checkboxLabel}
				       htmlFor='show-baseline-checkbox'><span>Show Baseline</span></label>
			</div>
		</div>
	</div>;
}
