import {ValidStudentModel} from '../../types';

export enum ConflictAction {
	Fix = 'fix',
	Delete = 'delete',
	AddAnyway = 'AddAnyway',
}

export enum StudentField {
	StudentIDN = 'studentIDN',
	Name = 'name',
	Language = 'language',
}

export enum ConflictType {
	None = 0,
	DuplicateStudentName = 1,
	DuplicateStudentIDN = 2,
	UnknownLanguage = 3,
}

export enum ValidationType {
	None = 0,
	Standard = 1,
	Conflict = 2,
	Full = 3,
}

export class ConflictStudentModel {
	constructor(
		public id: number,
		public gradeLevel: string,
		public createDate: string,
		public gender: string,
		public existingStudents: ConflictExistingStudentModel[] = [],
		public studentIDN: ConflictStudentField,
		public name: ConflictStudentField,
		public language: ConflictStudentField,
	) {
	}
}

export class ChangedStudents {
	constructor(
		public id: number,
		public firstName: string,
		public lastName: string,
		public studentIDN: string,
		public language: string,
		public createDate: string,
		public gradeLevel: string,
		public gender: string,
	) {
	}
}

export class ConflictStudentsRecheckModel {
	constructor(
		public classID: number,
		public schoolID: number,
		public changedStudents: ChangedStudents[],
		public excludedStudentsToCheck: number[],
		public excludedStudentsToNamesCheck: number[],
		public excludedStudentsToLanguageCheck: number[],
	) {
	}
}

export class Validation {
	type: ValidationType;
	message: string;
	conflictType: ConflictType;

	constructor(
		type: ValidationType = ValidationType.None,
		conflictType: ConflictType = ConflictType.None,
		message: string = '',
	) {
		this.type = type;
		this.message = message;
		this.conflictType = conflictType;
	}
}

export class ConflictExistingStudentModel {
	id: number;
	teacherID: number;
	schoolID: number;
	name: string;
	studentIDN: string;
	gradeLevelName: string;
	language: string;
	createDate: string;
	gender: string;
}

export class ConflictStudentField {
	value: string;
	validation: Validation;
	conflictAction: ConflictAction;

	constructor(value: string, validation: Validation = new Validation(), conflictAction: ConflictAction) {
		this.value = value;
		this.validation = validation;
		this.conflictAction = conflictAction;
	}
}

export interface ServerConflictStudentModel {
	id: number;
	name: ServerExistingStudentField;
	studentIDN: ServerExistingStudentField;
	gradeLevel: string;
	language: ServerExistingStudentField;
	createDate: string;
	gender: string;
	existingStudents: ConflictExistingStudentModel[];
}

export interface ServerValidation {
	status: string;
	data: string;
}

export interface SaveConflictStudentsResponse {
	createdStudents: ValidStudentModel[];
	validation: ServerValidation;
}

export interface ActionsDataType {
	studentIDN: ConflictAction,
	name: ConflictAction,
	language: ConflictAction,
}

export interface ServerExistingStudentField {
	value: string;
	validationMessage: string;
	validationType: string;
	conflictType: string;
}
