import {StudentsTestAnswer, AnswerModel, AvailableTest, StartTestSessionResponse,
	StartTestSessionRequest, TestRequest, EndTestRequest, AnswerQuestionRequest, InitTestSessionRequest,
	InitTestSessionResponse, FullTestModel, Status, GetTestsResponse, RelaunchTestSessionResponse, Student, ContentAreaModel,
	AnswerQuestionResponse} from './../models';
import {SubjectModel} from 'pages/home/services/subjects-service/models';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {map, tap, switchMap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {Test, Question} from '../models';
import {last} from 'underscore';
import {PRACTICE_TEST} from '../data/practice-test';
import {SsoTracker} from '@esgi/core/tracker';

export default class DataService extends BaseService {
	private readonly controller: string = 'assessments/self';
	private selectedTests: BehaviorSubject<Test[] | null> = new BehaviorSubject(null);
	private studentsAnswers: StudentsTestAnswer[] = [];
	private currentTest: BehaviorSubject<Test | null> = new BehaviorSubject(null);
	private currentQuestion: BehaviorSubject<Question | null> = new BehaviorSubject(null);
	private finishTest: Subject<boolean> = new Subject();
	private student: Student;
	private sessionGuid: string = null;
	private allAvailableTests: AvailableTest[] = [];
	private selectedAvailableTests: BehaviorSubject<AvailableTest[]> = new BehaviorSubject([]);
	private previousTestSessionAvailable: boolean = false;

	public bucketName: string = null;

	constructor(private readonly subject: SubjectModel, private readonly testID?: number) {
		super();
	}

	public get selectedSubject(): SubjectModel {
		return this.subject;
	}

	public get selectedTests$(): Observable<Test[]> {
		return this.selectedTests;
	}

	public get selectedAvailableTests$(): Observable<AvailableTest[]> {
		return this.selectedAvailableTests;
	}

	public get availableTests(): AvailableTest[] {
		return this.allAvailableTests;
	}

	public set availableTests(value) {
		this.allAvailableTests = value;
	}

	public get currentQuestion$(): Observable<Question> {
		return this.currentQuestion;
	}

	public get currentTest$(): Observable<Test> {
		return this.currentTest;
	}

	public get finishTest$(): Observable<boolean> {
		return this.finishTest;
	}

	public get selectedStudent(): Student {
		return this.student;
	}

	public get isLastQuestion(): boolean {
		const {questions} = this.currentTest.value;

		const {id} = this.currentQuestion.value;
		return questions.findIndex(q => q.id === id) === 0;
	}

	public initTestSession$(studentID): Observable<boolean> {
		const m: InitTestSessionRequest = {studentID, subjectID: this.subject.id, subjectType: this.subject.type};
		return this.httpClient.ESGIApi.get<InitTestSessionResponse>(this.controller, 'init-test-session', m).asObservable()
			.pipe(map(r => {
				this.student = {id: studentID, name: r.studentName?.trim()};
				this.bucketName = r.bucketName;
				this.allAvailableTests = r.tests;
				this.previousTestSessionAvailable = r.previousTestSessionAvailable;

				let selectedAvailableTests: AvailableTest[] = r.practiceTestPassed ? [] : [PRACTICE_TEST];

				if (this.testID) {
					const test = this.allAvailableTests.find(t => t.id === this.testID);
					selectedAvailableTests.push(test);
				}

				this.selectedAvailableTests.next(selectedAvailableTests);

				return this.previousTestSessionAvailable;
			}));
	}

	public addTests(testIDs: number[]): void {
		const tests = this.allAvailableTests.filter(t => testIDs.includes(t.id))
			.sort((a, b) => testIDs.indexOf(a.id) - testIDs.indexOf(b.id));

		const existingTests = this.selectedAvailableTests.value;
		this.selectedAvailableTests.next([...existingTests, ...tests.filter(t => !existingTests.includes(t))]);
	}

	public practiceTestProcessing(value: boolean): void {
		const tests = this.selectedAvailableTests.value;
		if (value) {
			return this.selectedAvailableTests.next([PRACTICE_TEST, ...tests]);
		}

		this.selectedAvailableTests.next([...tests.filter(test => !test.isPractice)]);
	}

	public reorder(startIndex: number, endIndex: number): void {
		const result = Array.from(this.selectedAvailableTests.value);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		this.selectedAvailableTests.next(result);
	}

	public removeTest(testId: number): void {
		const tests = this.selectedAvailableTests.value;

		this.selectedAvailableTests.next([...tests.filter(test => test.id !== testId)]);
	}

	public startTestSession$(): Observable<void> {
		const selectedAvailableTests = this.selectedAvailableTests.value;
		const testIDs = selectedAvailableTests.filter(t => !t.isPractice).map(t => t.id);

		const m: StartTestSessionRequest = {studentID: this.student.id, testIDs};
		return this.httpClient.ESGIApi.post<StartTestSessionResponse>(this.controller, 'start-test-session', m).asObservable()
			.pipe(
				map(r => {
					this.sessionGuid = r.sessionGuid;

					let tests: Test[] = this.mapTests(r.tests);

					if (testIDs.length !== selectedAvailableTests.length) {
						tests = [PRACTICE_TEST, ...tests];
					}

					this.selectedTests.next(tests);
					this.currentTest.next(tests[0]);
				}),
			);
	}

	public endTestSession$(): Observable<void> {
		return this.httpClient.ESGIApi.post<void>(this.controller, 'end-test-session', {studentID: this.student.id}).asObservable()
			.pipe(tap(() => this.previousTestSessionAvailable = false));
	}

	public runTest$(): Observable<void> {
		const questionIDs = this.currentTest.value.questions.map(q => q.id);

		const m: TestRequest = {sessionGuid: this.sessionGuid, testID: this.currentTest.value.id, questionIDs};
		return this.httpClient.ESGIApi.post<void>(this.controller, 'run-test', m).asObservable();
	}

	public setFirstQuestion(test: Test) {
		if (this.previousTestSessionAvailable) {
			const answerTest = this.studentsAnswers.find(t => t.testID === test.id);

			if (answerTest.answers.length === 0) {
				return this.currentQuestion.next(test.questions[0]);
			}

			const {questionID} = last(answerTest.answers);

			if (questionID) {
				const index = test.questions.findIndex(t => t.id === questionID);
				this.currentQuestion.next(test.questions[index + 1]);

				return;
			}
		}

		this.currentQuestion.next(test.questions[0]);
	}

	public testFinish$(): Observable<void> {
		const test = this.currentTest.value;
		const selectedTests = this.selectedTests.value;
		const testIndex = selectedTests.findIndex(t => t.id === test.id);

		if (testIndex < selectedTests.length - 1) {
			this.currentTest.next(this.selectedTests.value[testIndex + 1]);
		}

		if (test.isPractice) {
			const req = {studentID: this.student.id};
			return this.httpClient.ESGIApi.post<void>(this.controller, 'end-practice-test', req).asObservable()
				.pipe(tap(() => SsoTracker.trackEvent({
					trackingEvent: 'SAPracticeTestSession',
					data: req,
				})));
		}

		const answers = this.studentsAnswers.find(t => t.testID === test.id)?.answers;

		const m: EndTestRequest = {sessionGuid: this.sessionGuid, testID: test.id, studentID: this.student.id, answers};
		return this.httpClient.ESGIApi.post<void>(this.controller, 'end-test', m).asObservable()
			.pipe(tap(() => SsoTracker.trackEvent({
				trackingEvent: 'SATestSessionCompleted',
				data: m,
			})));
	}

	public nextQuestion(optionIDs: number[], duration: number): Observable<{ optionIDs: number[], testFromCacheAvailable: boolean }> {
		const question = this.currentQuestion.value;
		const test = this.currentTest.value;

		const index = test.questions.findIndex(t => t.id === question.id);

		this.setStudentAnswer(question.id, optionIDs, duration);

		let observable = new Observable<AnswerQuestionResponse>(observer => observer.next({testFromCacheAvailable: true}));

		if (!test.isPractice) {
			const m: AnswerQuestionRequest = {sessionGuid: this.sessionGuid, testID: test.id, questionID: question.id, optionIDs, duration};
			observable = this.httpClient.ESGIApi.post<AnswerQuestionResponse>(this.controller, 'answer-question', m).asObservable();
		}

		return observable.pipe(map(r => {
			if (index === test.questions.length - 1) {
				const lastTestID = this.selectedTests.value.map(t => t.id).pop();

				const isLastTest = test.id === lastTestID;
				this.finishTest.next(isLastTest);
				return { optionIDs: [], testFromCacheAvailable: r.testFromCacheAvailable };
			}

			const nextQuestion = test.questions[index+1];
			const testAnswers = this.studentsAnswers.find(a => a.testID === test.id);

			this.currentQuestion.next(nextQuestion);

			if (!testAnswers) {
				return { optionIDs: [], testFromCacheAvailable: r.testFromCacheAvailable };
			}

			const answer = testAnswers.answers.find(a => a.questionID === nextQuestion.id);

			if (!answer) {
				return { optionIDs: [], testFromCacheAvailable: r.testFromCacheAvailable };
			}

			return { optionIDs: answer.optionIDs, testFromCacheAvailable: r.testFromCacheAvailable };
		}));
	}

	public previousQuestion(): number[] {
		const question = this.currentQuestion.value;
		const test = this.currentTest.value;

		const index = test.questions.findIndex(t => t.id === question.id);

		if (index === 0) {
			return;
		}

		const {answers} = this.studentsAnswers.find(t => t?.testID === test.id);
		const previousQuestion = test.questions[index-1];
		const answer = answers.find(a => a.questionID === previousQuestion.id);

		this.currentQuestion.next(previousQuestion);

		return answer.optionIDs;
	}

	public forceEndTestSession$(): Observable<void> {
		return this.testFinish$().pipe(switchMap(() => this.endTestSession$()));
	}

	public relaunchTestSession$(): Observable<void> {
		return this.httpClient.ESGIApi.post<RelaunchTestSessionResponse>(this.controller, 'relaunch-test-session', {studentID: this.student.id}).asObservable()
			.pipe(map(r => {
				this.sessionGuid = r.sessionFromCache.sessionGuid;
				let tests: Test[] = this.mapTests(r.tests);

				this.studentsAnswers = r.sessionFromCache.testsFromCache.map(t => ({
					testID: t.id,
					answers: t.questionsFromCache,
				}));

				this.selectedTests.next(tests);

				let test = r.sessionFromCache.testsFromCache.find(t => t.status === Status.InProgress);

				if (!test) {
					test = r.sessionFromCache.testsFromCache.find(t => t.status === Status.NotStarted);
				}

				this.currentTest.next(tests.find(t => t.id === test.id));
			}));
	}

	public getTests(): Promise<GetTestsResponse> {
		return this.httpClient.ESGIApi.get<GetTestsResponse>(this.controller, 'get-tests').toPromise();
	}

	private mapTests(tests: FullTestModel[]): Test[] {
		return tests.map(t => ({
			id: t.id, name: t.name, contentAreaID: t.contentAreaID,
			contentAreaName: t.contentAreaName, questionsCount: t.questions.length,
			questions: t.questions.map(q => ({id: q.id, templateType: q.selfAssessmentQuestionTemplate, ...JSON.parse(q.template)})),
		}));
	}

	private setStudentAnswer(questionID: number, optionIDs: number[], duration: number): void {
		const currentTestID = this.currentTest.value.id;
		const value = this.studentsAnswers.find(t => t?.testID === currentTestID);
		if (!value) {
			const answers: AnswerModel[] = [{questionID, optionIDs, duration}];
			this.studentsAnswers = [...this.studentsAnswers, {testID: currentTestID, answers}];

			return;
		}

		const answerValue = value.answers.find(t => t.questionID === questionID);

		if (!answerValue) {
			const answerModel: AnswerModel = {questionID, optionIDs, duration};
			value.answers = [...value.answers, answerModel];

			return;
		}

		answerValue.duration += duration;
		answerValue.optionIDs = optionIDs;
	}
}
