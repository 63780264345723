import {useEffect, useMemo, useState} from 'react';
import {ContentBox} from '../../../components/content-box.styled';
import {BaseResponse, UserCountry, UserModel, UserState} from '../../../../../types';
import {Drawer} from '@esgi/main/kits/common';
import {Observable} from 'rxjs';
import {LinkedToDistrictInfo} from '../../../components/linked-to-district-info';
import {PanelHeaderTitle} from '../../../components/panel-header-title';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {FormElementSelect} from './components/form-element-select';
import {GridBox} from '@esgi/ui/layout';
import {useService} from '@esgi/core/service';
import {DataService} from './service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {FormSearchableList} from './components/form-searchable-list';
import {PanelContent} from './index.styled';
import {ElementStatus, Form, FormControl} from '@esgi/ui/form';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {debounce} from 'underscore';
import {DropdownItem} from './service/types';

type Props = {
	defaultCountries: UserCountry[];
	defaultStates: UserState[];
	user: UserModel,
	onSchoolNameSave: (schoolName: string) => Observable<BaseResponse>;
};

export function SchoolAndDistrictContent({defaultCountries, defaultStates, user}: Props) {
	const {parsedCountries, initialCountryID} = useMemo(() => {
		let initialCountryID: number | undefined;
		const parsedCountries = defaultCountries.map<DropdownItem>(country => {
			if(country.countryID === user.countryID) {
				initialCountryID = country.countryID;
			}

			return {
				id: country.countryID,
				value: country.name,
			};
		});

		return {
			parsedCountries,
			initialCountryID: String(initialCountryID ?? parsedCountries[0]?.id ?? ''),
		};
	}, [defaultCountries, user.countryID]);

	const {parsedStates, initialStateID} = useMemo(() => {
		let initialStateID: number | undefined;
		const parsedStates = defaultStates.map<DropdownItem>(state => {
			if(state.stateID === user.stateID) {
				initialStateID = state.stateID;
			}

			return {
				id: state.stateID,
				value: state.name,
			};
		});

		return {
			parsedStates,
			initialStateID: String(initialStateID ?? parsedStates[0]?.id ?? ''),
		};
	}, [defaultStates, user.stateID]);

	const initialDistrictName = user.districtName;
	const initialSchoolName = user.schoolName;
	const isLinkedToDistrict = user.isLinked;

	const [isFormTouched, setIsFormTouched] = useState(false);

	const service = useService(DataService);
	const isFormValid = !Object.values(service.form.controls).map((item: FormControl) => item.status).some(status => status === ElementStatus.invalid);

	const countries = useBehaviorSubject(service.countries);
	const states = useBehaviorSubject(service.states);
	const districts = useBehaviorSubject(service.filteredDistricts);
	const schools = useBehaviorSubject(service.schools);

	const selectedDistrict = useBehaviorSubject(service.selectedDistrict);
	const selectedSchool = useBehaviorSubject(service.selectedSchool);

	const handleDistrictNameChange = debounce(() => service.onDistrictNameChange(), 400);
	const handleSchoolNameChange = debounce(() => service.onSchoolNameChange(), 400);

	useStreamEffect(service.form.controls.countryID.onChanged, ({currState: {value: currentCountryIDValue}, reason}) => {
		const currentCountryID = currentCountryIDValue[0];

		if (isUndefined(currentCountryID) || reason !== 'value' || currentCountryID === initialCountryID) {
			return;
		}

		service.onCountryChange(Number(currentCountryID));
	});

	useStreamEffect(service.form.controls.stateID.onChanged, ({currState: {value: currentStateIDValue}, reason}) => {
		const currentStateID = currentStateIDValue[0];

		if (isUndefined(currentStateID) || reason !== 'value' || currentStateID === initialStateID) {
			return;
		}

		service.onStateChange(Number(currentStateID));
	});

	useStreamEffect(service.form.controls.districtName.onChanged, ({currState: {value: currentDistrictNameValue}, reason}) => {
		if (isUndefined(currentDistrictNameValue) || reason !== 'value' || currentDistrictNameValue === initialDistrictName) {
			return;
		}

		handleDistrictNameChange();
	});

	useStreamEffect(service.form.controls.schoolName.onChanged, ({currState: {value: currentSchoolNameValue}, reason}) => {
		if (!currentSchoolNameValue || reason !== 'value' || currentSchoolNameValue === initialSchoolName) {
			return;
		}

		handleSchoolNameChange();
	});

	useStreamEffect(
		service.form.onChanged,
		({
			currState: {
				value: {countryID, stateID, districtName, schoolName},
			},
			reason,
		}) => {
			if (reason === 'status') {
				setIsFormTouched(false);
			} else {
				setIsFormTouched(
					countryID[0] !== initialCountryID ||
					stateID[0] !== initialStateID ||
					districtName !== initialDistrictName ||
					schoolName !== initialSchoolName
				);
			}
		},
	);

	useEffect(() => {
		service.init({
			defaultCountries: parsedCountries,
			defaultStates: parsedStates,
			defaultSelectedCountry: initialCountryID,
			defaultSelectedState: initialStateID,
			initialDistrictName: initialDistrictName,
			initialSchoolName: initialSchoolName,
			isLinkedToDistrict,
		});
	}, [initialCountryID, initialStateID, parsedCountries, parsedStates, service, initialDistrictName, initialSchoolName, isLinkedToDistrict]);

	return (
		<>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={service.onSave.bind(service)}
				actionButtonDisabled={!isFormTouched || !isFormValid || isLinkedToDistrict}
			>
				<PanelHeaderTitle title='School & District' />
			</Drawer.PanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<Form controller={service.form}>
				<PanelContent>
					<ContentBox gap='40'>
							<LinkedToDistrictInfo isLinkedToDistrict={isLinkedToDistrict} />
							<Drawer.ContentBlock title='Location' withDivider>
								<GridBox flow='column' justify='between'>
									<FormElementSelect
										items={countries}
										placeholder='Country'
										controlElement={service.form.controls.countryID}
										key={`country-${service.form.controls.countryID.value}`}
									/>
									<FormElementSelect
										items={states}
										placeholder='State'
										controlElement={service.form.controls.stateID}
										key={`state-${service.form.controls.stateID.value}`}
									/>
								</GridBox>
							</Drawer.ContentBlock>
							<Drawer.ContentBlock title='District' withDivider>
								<FormSearchableList
									items={districts}
									placeholder='District'
									controlElement={service.form.controls.districtName}
									selectedEntityID={String(selectedDistrict?.id)}
									onSelect={service.selectDistrict.bind(service)}
									key={`district-${selectedDistrict?.id}`}
								/>
							</Drawer.ContentBlock>
							<Drawer.ContentBlock title='School' withDivider>
								<FormSearchableList
									items={schools}
									placeholder='School'
									controlElement={service.form.controls.schoolName}
									selectedEntityID={String(selectedSchool?.id)}
									onSelect={service.selectSchool.bind(service)}
									key={`school-${selectedSchool?.id}`}
								/>
							</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
				</Form>
			</OverlayScrollbarsComponent>
		</>
	);
}
