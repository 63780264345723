interface Props {
	title: string;
	firstName: string;
	lastName: string;
	accountID: string;
	email: string;
	expDateString: string
}

export function LinkToExpiredPrimaryAccount(props: Props) {
	return <div>
		<h4>Renewal Notice</h4>
		<p>Your credential is linked to {props.title} {props.firstName} {props.lastName}'s primary account
		<strong>{props.accountID}</strong> that expired on {props.expDateString}.</p><p>Please reach out to the primary account holder
		at <a href='mailto:{user.Email}'>{props.email}</a> or your school/district to renew this account.</p><p>For further questions contact <a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a></p>
	</div>
}