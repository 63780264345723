import {ExpandablePanel} from '../../../../../expandable-panel';
import {isNull} from 'underscore';
import {useCallback} from 'react';
import {SelectableLisItemContent} from '../list-item';
import {ReportTest, TestSession} from '../../../../types';

const getListValue = (value: string | number | null) => isNull(value) ? '' : String(value);

interface Props {
	isListOpen: boolean;
	items: ReportTest[];
	selected: ReportTest['id'][] | null;
	setSelected: (itemId: ReportTest['id']) => void;
	onChangeTestsData?: (testID: number, session: TestSession) => void;
}

export function ExpandableSelectableListMultiple({isListOpen, selected, items, setSelected, onChangeTestsData}: Props) {
	const value = (selected || []).map(getListValue);
	const checked = useCallback((id: string) => value.includes(id), [value]);

	return <ExpandablePanel>
		{items.map(({
									id,
									sessions,
									correct,
									incorrect,
									untested,
									testDate,
									sessionID,
									maxScore,
									...props
		}) => {

			const selectedSession = {
				correct,
				incorrect,
				untested,
				testDate,
				sessionID,
			};

			return <SelectableLisItemContent
				{...props}
				key={id}
				id={id}
				sessions={sessions}
				selectedSessionData={selectedSession}
				isChecked={checked(String(id))}
				isListOpen={isListOpen}
				isDisabled={!sessions?.length}
				setSelected={setSelected}
				maxScore={maxScore}
				onChangeTestsData={onChangeTestsData}
			/>;
		})}
	</ExpandablePanel>;
}


