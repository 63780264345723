import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	textAlign: 'left',
	width: '160px',
});

export const Link = styled('a', {
	lineHeight: 0,
});