import {HttpClient} from '@esgi/api';
import {Buttons} from '@esgillc/ui-kit/button';
import {Checkbox, Radio} from '@esgillc/ui-kit/control';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {useCallback, useMemo, useState} from 'react';
import {AnswerState, SessionType, SubjectType} from '@esgi/core/enums';
import {TestingModel} from '../../models';
import {NoIncorrectItemsAlert} from '../no-incorrect-items-alert';
import {SessionSelectorSelectors} from './session-selector.selectors';
import styles from './styles.module.less';

export type Props = {
	subjectType: SubjectType;
	testingModel: TestingModel;
	onStartTest: (sessionType: SessionType, shuffleQuestions: boolean, resume: boolean) => void;
	onCancel: () => void;
};

export function TestSessionSelector(props: Props) {
	const {
		subjectShuffleQuestions,
		teacherShuffleQuestions,
		availableSessionTypes,
		studentName,
		testName,
		resumeModel,
	} = props.testingModel;

	const resume = resumeModel?.resumeAvailable;

	const [showNoIncorrectItemsAlert, setShowNoIncorrectItemsAlert] = useState(false);
	const [resumeTestSession, setResumeTestSession] = useState(props.testingModel.resumeModel?.resumeAvailable);
	const [sessionType, setSessionType] = useState(() => availableSessionTypes[0]);

	const showShuffleQuestions = useMemo(() => {
		if (props.subjectType === SubjectType.Deployed) {
			return !subjectShuffleQuestions;
		}
		return true;
	}, []);

	const [shuffleQuestion, setShuffleQuestion] = useState(props.subjectType === SubjectType.Deployed ? subjectShuffleQuestions : teacherShuffleQuestions);

	const updateShuffleQuestions = useCallback((option: React.ChangeEvent<HTMLInputElement>) => {
		setShuffleQuestion(option.target.checked);
		HttpClient.default.ESGIApi
			.post('assessment/test-screen/yn', 'update-shuffle-questions', {
				value: option.target.checked,
				studentID: props.testingModel.general.studentID,
				testID: props.testingModel.general.testID,
			}).subscribe();
	}, []);

	const showSessionTypes = availableSessionTypes.length !== 1;

	const modal = useModal();

	const onCancelClicked = useCloseModal(modal, props.onCancel);
	const onStartClicked = useCloseModal(modal, () => {
		const selectedSessionType = resumeTestSession ? resumeModel.lastSessionType : sessionType;
		props.onStartTest(selectedSessionType, shuffleQuestion, resumeTestSession);
	});

	const onTestIncorrectSelected = () => {
		if (props.testingModel.questions.every(q => q.oldAnswerState === AnswerState.Correct)) {
			setShowNoIncorrectItemsAlert(true);
		} else {
			setSessionType(SessionType.TestIncorrect);
		}
	};

	return <>
		<Modal modalManagerRef={modal} className={styles.modal}>
			<Modal.Header className={styles.header}>
				<p><strong>Student:</strong> {studentName}</p>
				<p><strong>Test:</strong> {testName}</p>
			</Modal.Header>
			<Modal.Body data-cy={SessionSelectorSelectors.sessionSelector} className={styles.body}>
				{resume && <div className={styles.section}>
					<h4>Resume Test Session?</h4>
					<Radio checked={resumeTestSession}
					       value={1}
					       data-cy={SessionSelectorSelectors.resumeYesButton}
					       onChange={() => setResumeTestSession(true)}>
						Yes
					</Radio>
					<Radio checked={!resumeTestSession}
					       value={0}
					       data-cy={SessionSelectorSelectors.resumeNoButton}
					       onChange={() => setResumeTestSession(false)}>
						No - Start new session
					</Radio>
				</div>}
				{!resumeTestSession && showSessionTypes && <div className={styles.section}>
					<h4>Start New Session</h4>
					<Radio
						checked={sessionType === SessionType.TestAll}
						value={SessionType.TestAll}
						onChange={() => setSessionType(SessionType.TestAll)}
						data-cy={SessionSelectorSelectors.retestAll}
					>
						Test all questions
					</Radio>
					<Radio
						checked={sessionType === SessionType.TestIncorrect}
						value={SessionType.TestIncorrect}
						onChange={onTestIncorrectSelected}
						data-cy={SessionSelectorSelectors.retestIncorrect}
					>
						Test incorrect & not tested
					</Radio>
					{showShuffleQuestions && <>
						<hr/>
						<Checkbox
							checked={shuffleQuestion} onChange={updateShuffleQuestions}
							data-cy={SessionSelectorSelectors.shuffleQuestions}>
							Shuffle question order
						</Checkbox>
					</>}
				</div>}
			</Modal.Body>
			<Modal.Footer className={styles.footer}>
				<Buttons.Gray onClick={onCancelClicked} data-cy={SessionSelectorSelectors.close}>
					CANCEL
				</Buttons.Gray>
				<Buttons.Contained onClick={onStartClicked} data-cy={SessionSelectorSelectors.startTest}>
					START TEST
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>
		{showNoIncorrectItemsAlert && <NoIncorrectItemsAlert onClose={() => setShowNoIncorrectItemsAlert(false)}/>}
	</>;
}
