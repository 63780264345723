import {Dispatch, PropsWithChildren, useCallback, useMemo} from 'react';
import {StudentsPanelContext, StudentsPanelContextValue} from '../context';
import {Entities, StudentsPanelTabID} from '../types';
import {MultiTabPanel} from './multi-tab-panel';
import {Skeletonable} from '@esgi/ui';

type Props = {
	entities: Entities;
	setEntities?: Dispatch<Entities>;
	initialTab?: StudentsPanelTabID;
	activeTab?: StudentsPanelTabID;
	onActiveTabChanged?: Dispatch<StudentsPanelTabID>;
	skeleton?: boolean;
} & PropsWithChildren &
	Skeletonable;

export function StudentsPanelRoot({
	entities,
	setEntities,
	children,
	initialTab = StudentsPanelTabID.Classes,
	activeTab,
	skeleton,
	onActiveTabChanged,
}: Props) {
	const onSetEntities = useCallback(
		(entity: Partial<Entities>) => setEntities?.({...entities, ...entity}),
		[entities, setEntities],
	);

	const context = useMemo<StudentsPanelContextValue>(
		() => ({
			entities,
			setEntities: onSetEntities,
		}),
		[entities, onSetEntities],
	);

	return (
		<MultiTabPanel.Root initialTab={initialTab} activeTab={activeTab} onTabChanged={onActiveTabChanged}>
			<StudentsPanelContext.Provider value={context}>{!skeleton && children}</StudentsPanelContext.Provider>
		</MultiTabPanel.Root>
	);
}
