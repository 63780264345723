import {Cheap} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const SubjectGridBox = styled(GridBox, {
	width: '100%',
	alignItems: 'center',
	gap: 6,

	variants: {
		withCheapBox: {
			true: {},
			false: {},
		},

		withHideIcon: {
			true: {},
			false: {},
		},
	},

	compoundVariants: [
		{
			withCheapBox: true,
			withHideIcon: true,
			css: {
				gridTemplateColumns: 'auto auto 1fr',
			},
		},
		{
			withCheapBox: true,
			withHideIcon: false,
			css: {
				gridTemplateColumns: 'auto 1fr',
			},
		},
		{
			withCheapBox: false,
			withHideIcon: true,
			css: {
				gridTemplateColumns: 'auto 1fr',
			},
		},
		{
			withCheapBox: false,
			withHideIcon: false,
			css: {
				gridTemplateColumns: '1fr',
			},
		},
	],
});

export const CheapBox = styled(Cheap, {
	alignSelf: 'start',

	variants: {
		skeleton: {
			true: {
				width: 16,
				height: 16,
				boxSizing: 'border-box',
				...SkeletonStyles,
			},
		},
	},
});

export const HideIconBox = styled(GridBox, {
	alignSelf: 'start',

	'& svg': {
		width: 20,
		height: 20,

		'& path': {
			fill: '$muted',
		},
	},
});

export const SubjectText = styled(Text, {
	wordBreak: 'break-word',
});
