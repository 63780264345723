import {VariantProps} from '@stitches/react';
import {SectionBox} from './index.styled';
import {IconComponent} from '@esgi/ui';

type Props = {
	fillColor: NonNullable<VariantProps<typeof SectionBox>['fillColor']>;
	Icon: IconComponent;
};

export function ExpandablePanelIcon({fillColor, Icon}: Props) {
	return (
		<SectionBox fillColor={fillColor}>
			<Icon />
		</SectionBox>
	);
}
