import {ChangeFunction} from '@esgi/main/kits/activities';
import {useSightWordsService} from '../../hooks';
import {useBehaviorSubject} from '@esgi/ui';
import {EmptyList} from './components/empty-list';
import {TestList} from './components/test-list';
import {SkeletonList} from './index.styled';
import {Question} from '../../types';
import {useTestsControl} from './hooks';

interface Props {
	disabled?: boolean;
	onChange: ChangeFunction<Question['questionID'][]>;
	onAllTestClick: VoidFunction;
}

export function TestsControl({onChange, onAllTestClick}: Props) {
	const service = useSightWordsService();
	const tests = useBehaviorSubject(service.tests$);
	const loadedTests = useBehaviorSubject(service.loaded$);
	const {items, selected, toggleQuestions, sort, onSort, onToggle, onSelectAll, onSelectTestAll} = useTestsControl(tests, onChange);

	if (!loadedTests) {
		return <SkeletonList/>;
	}

	if (!tests.length) {
		return <EmptyList onAllTestClick={onAllTestClick}/>;
	}

	return (
		<TestList
			state={items}
			selectedQuestions={selected}
			toggleQuestions={toggleQuestions}
			sort={sort}
			onSort={onSort}
			onToggle={onToggle}
			onSelectAll={onSelectAll}
			onSelectTestAll={onSelectTestAll}
		/>
	);
}
