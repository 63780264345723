import {ReportModalBody} from '@esgi/deprecated/ui-kit/report/modal';
import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {ModalHeader} from '@esgi/deprecated/ui-kit/modal';
import {Modal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgi/ui/alert';

export const ReportModalBodyStyled = styled(ReportModalBody, {
	minWidth: '900px',
	width: 'max-content',
	maxWidth: '90vw',
	padding: '0',
	display: 'flex',
	marginBottom: '-16px',
	marginTop: '-16px',
	boxShadow: '0px 4px 8px -4px #bfbfbf',
});

export const ReportModalHeaderStyled = styled(ModalHeader, {
	display: 'flex',
	alignItems: 'center',
	padding: '3px 16px',
	backgroundColor: '#FFFFFF',
	height: '36px',
	fontSize: '14px',
});

export const StudentProgressReportWrapper = styled('div', {
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	position: 'relative',
	overflow: 'hidden',
	fontSize: '14px',
	textAlign: 'left',
	backgroundColor: 'white',
	maxHeight: '100%',
});
export const StudentProgressReportContainer = styled('div', {
	textAlign: 'center',
	height: 'auto',
	overflow: 'hidden',
	minHeight: '100px',

	'@media (max-width: 1220px)': {
		width: '100%',
	},
});

export const ReportErrorNoStudents = styled(Box, {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	margin: '50px',

	'span': {
		display: 'flex',
		height: '40px',
		alignItems: 'center',
		background: '#F0FAFE',
		border: '2px solid #51C3F2',
		borderRadius: '6px',
		padding: '7px',
	},
});