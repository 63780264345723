import {useMemo, useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {TextArea} from '@esgillc/ui-kit/control';
import {isMobile, join, useBehaviorSubject, useStream, useStreamEffect} from '@esgillc/ui-kit/utils';
import {SessionType} from '@esgi/core/enums';
import {CloseButton} from '../../../../../kit/buttons/close-button';
import {TestSessionStatus} from '../../../../../kit/enums';
import {QuestionSlider} from '../../../../../kit/slider';
import {QuestionModel} from '../../../../../kit/models';
import {MobileButtons} from './components/mobile-buttons/mobile-buttons';
import {useSliderActions} from './hooks/use-slider-actions';
import {useTestingService} from './hooks/use-testing-service';
import {ShareSyncService} from '../../../../../kit/share-screen';
import {
	QuestionFooter,
	QuestionHeader,
	QuestionLayout,
	QuestionTitle,
} from '../../../../../kit/question-layout';
import styles from './styles.module.less';
import {Loader} from '@esgillc/ui-kit/loader';
import {TestingModel} from '../../../models';
import {Summary} from '../../../types';
import {LostConnectionAlert} from '../../../../../kit/lost-connection-alert';

export type Props = {
	guid: string;
	sessionType: SessionType;
	shuffleQuestions: boolean;
	continueSession: boolean;
	resumeSession: boolean;
	testingModel: TestingModel;
	closeClicked: () => void;
	toIntroClicked: () => void;
	shareSyncService: ShareSyncService;
	questionIds?: number[];
	onSessionStatusChanged: (status: TestSessionStatus) => void;
	onEndTest: (summary: Summary) => void;
};

export function YNQuestionSlider(props: Props): JSX.Element {
	const isMobileDevice = useMemo(() => isMobile(), []);
	const [showConnectionLostAlert, setShowConnectionLostAlert] = useState(false);

	const service = useTestingService(props);

	const index = useBehaviorSubject(service.currentQuestionIdx);
	const [minIndex, maxIndex] = useBehaviorSubject(service.questionsLoadRange);
	const hasLongRequest = useBehaviorSubject(service.hasLongRequest);
	const serviceBusy = useStream(service.busy);
	const questions = useBehaviorSubject(service.questions);
	const current = questions[index] || {} as QuestionModel;
	const {direction} = current;
	const canBack = index > 0 || !!props.testingModel.testIntro;
	const isFinished = index >= questions.length;

	useStreamEffect(service.testSessionStatus, props.onSessionStatusChanged);
	useStreamEffect(service.onLostConnection, () => setShowConnectionLostAlert(true));

	const {
		submitIncorrect,
		submitCorrect,
		skip,
		backClicked,
		closeClicked,
		finishTest,
	} = useSliderActions(service, index, questions, serviceBusy, props);

	const isWhiteBackground = props.testingModel.isWhiteBackground;

	if (isFinished) {
		finishTest().subscribe();
		return;
	}

	return <>
		<Loader show={hasLongRequest}/>
		<QuestionLayout whiteBackground={isWhiteBackground}>
			{isMobileDevice && <MobileButtons
				yesClicked={submitCorrect}
				noClicked={submitIncorrect}
				correctButtonLabel={props.testingModel.correctButtonLabel}
				incorrectButtonLabel={props.testingModel.incorrectButtonLabel}
			/>}
			<QuestionHeader>
				<QuestionTitle>
					<div className={styles.studentName}>
						{props.testingModel.studentName}
					</div>
					<div className={join(styles.testName, isWhiteBackground && styles.isWhiteBackground) }>
						{props.testingModel.testName}
					</div>
				</QuestionTitle>
				<CloseButton isWhiteBackground={isWhiteBackground}
				             onClick={closeClicked}
				             disabled={serviceBusy} tooltip={!serviceBusy && 'Save and Close'}/>
			</QuestionHeader>
			<QuestionSlider questions={questions} maxIndex={maxIndex} minIndex={minIndex} currentIndex={index} />
			<QuestionFooter className={styles.footer}>
				<div>
					{direction && <div className={styles.direction}>
						Directions: <span className={join(styles.directionText, isWhiteBackground && styles.isWhiteBackground)}>{direction}</span>
					</div>}
					<div className={styles.btnHolder}>
						<div className={styles.left}>
							<div className={styles.backButtonHolder}>
								<Buttons.Text tabIndex={3}
								              onClick={backClicked}
								              disabled={!canBack}
								              className={styles.questionAction}>
									<i className='fa fa-chevron-left'/> Back
								</Buttons.Text>
							</div>
						</div>
						<div className={styles.center}>
							<div className='yes-button-holder'>
								<Buttons.Gray tabIndex={5}
								              className={styles.answerButton}
								              onClick={submitCorrect}>
									{props.testingModel.correctButtonLabel}
								</Buttons.Gray>
							</div>
							<div className={styles.questionsTestedHolder}>
								<span className={join(styles.currentQuestion, isWhiteBackground && styles.isWhiteBackground)}>{index + 1}</span>
								of
								<span className={styles.totalQuestions}>{questions.length}</span>
							</div>
							<div>
								<Buttons.Gray tabIndex={6}
								              className={styles.answerButton}
								              onClick={submitIncorrect}>
									{props.testingModel.incorrectButtonLabel}
								</Buttons.Gray>
							</div>
						</div>
						<div className={styles.right}>
							<div className={styles.commentHolder}>
								<TextArea className={styles.comment}
								          disabled={serviceBusy}
								          id='yes-no-notes'
								          maxLength={140}
								          placeholder='Notes:'
								          value={current?.comment || ''}
								          onChange={e => service.updateComment(e.target.value)}/>
							</div>
							<div id='close-button-holder'>
								<Buttons.Text tabIndex={4} onClick={skip}
								              className={styles.questionAction}>
									Skip <i className='fa fa-chevron-right'/>
								</Buttons.Text>
							</div>
						</div>
					</div>
				</div>
			</QuestionFooter>
		</QuestionLayout>
		{showConnectionLostAlert &&
			<LostConnectionAlert
				onAbort={props.closeClicked}
				onRetry={() => {
					finishTest().subscribe();
					setShowConnectionLostAlert(false);
				}}
			/>
		}
	</>;
}