import {ClassBucket} from './types';
import {createContext, useContext} from 'react';
import {Class} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/classes/class';

export type ClassContextValue = {
	selectedClassIDs: number[];
	setSelectedClassId: (value: number) => void;
	classBuckets: ClassBucket[];
	selectedStudentIDs: number[];
	setSelectedStudentId: (studentId: number) => void;
	updateClassesOrder: (classes: Class[]) => void;
}

export const ClassesContext = createContext<ClassContextValue | null>(null);

export function useClassesContext() {
	const context = useContext(ClassesContext);

	if (!context) {
		throw new Error('ClassesContext is null');
	}

	return context;
}
