import {styled} from '@esgi/ui/theme';
import {Checkbox} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {OneLinedText} from '@esgi/main/kits/common';
import {Button} from '@esgi/ui';

export const NameCell = styled(GridBox, {
	height: '32px',
	gap: '$3',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto 1fr',
});

export const NameCheckbox = styled(Checkbox, {
	padding: 0,

	'& > div': {
		background: '$border',
		borderColor: '$vivid',
	},

	variants: {
		active: {
			true: {
				'& > div': {
					background: '$primary92',
					borderColor: '$vivid',
				},
			},
		},
		disabled: {
			true: {
				'& > div': {
					background: '$mild',
				},
				'& svg': {
					'& path': {
						fill: '$lowContrast',
					},
				},
			},
		},
	},
});

export const Timestamp = styled(Text, {
	display: 'flex',
	alignItems: 'center',
	borderRadius: 2,
	height: '16px',
	background: '$vivid',
	border: '1px solid $muted',
	padding: '0 4px',
});

export const Cell = styled(FlexBox, {
	alignItems: 'center',
	width: '100%',
	height: '100%',
});

export const LableBox = styled(FlexBox, {
	paddingTop: 5,
	paddingBottom: 5,
	paddingRight: 11,
	paddingLeft: 11,
	borderRadius: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$background',
	alignItems: 'center',
});

export const TimestampContainer = styled(FlexBox, {
	height: '100%',
	alignItems: 'center',
});

export const ButtonText = styled(Button.Text, {
	display: 'grid',
});

export const StyledText = styled(Text, {
	color: '$base',
	variants: {
		selected: {
			true: {
				color: '$primary',
			},
		},
		disabled: {
			true: {
				color: '$muted',
			},
		},
	},
});
