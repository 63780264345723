!function (e) {
    "use strict";
    var t = function (t, i) {
        this.$element = e(t), this.options = e.extend({}, e.fn.typeahead.defaults, i), this.matcher = this.options.matcher || this.matcher, this.sorter = this.options.sorter || this.sorter, this.highlighter = this.options.highlighter || this.highlighter, this.updater = this.options.updater || this.updater, this.source = this.options.source, this.$menu = e(this.options.menu), this.shown = !1, this.listen()
    };
    t.prototype = {
        constructor: t, select: function () {
            var e = this.$menu.find(".active").attr("data-value");
            return this.$element.val(this.updater(e)).change(), this.hide()
        }, updater: function (e) {
            return e
        }, show: function () {
            var t = e.extend({}, this.$element.position(), {height: this.$element[0].offsetHeight});
            return this.$menu.insertAfter(this.$element).css({
                top: t.top + t.height,
                left: t.left
            }).show(), this.shown = !0, this
        }, hide: function () {
            return this.$menu.hide(), this.shown = !1, this
        }, lookup: function () {
            var t;
            return this.query = this.$element.val(), !this.query || this.query.length < this.options.minLength ? this.shown ? this.hide() : this : (t = e.isFunction(this.source) ? this.source(this.query, e.proxy(this.process, this)) : this.source, t ? this.process(t) : this)
        }, process: function (t) {
            var i = this;
            return t = e.grep(t, function (e) {
                return i.matcher(e)
            }), t = this.sorter(t), t.length ? this.render(t.slice(0, this.options.items)).show() : this.shown ? this.hide() : this
        }, matcher: function (e) {
            return ~e.toLowerCase().indexOf(this.query.toLowerCase())
        }, sorter: function (e) {
            for (var t, i = [], n = [], a = []; t = e.shift();) t.toLowerCase().indexOf(this.query.toLowerCase()) ? ~t.indexOf(this.query) ? n.push(t) : a.push(t) : i.push(t);
            return i.concat(n, a)
        }, highlighter: function (e) {
            var t = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
            return e.replace(new RegExp("(" + t + ")", "ig"), function (e, t) {
                return "<strong>" + t + "</strong>"
            })
        }, render: function (t) {
            var i = this;
            return t = e(t).map(function (t, n) {
                return t = e(i.options.item).attr("data-value", n), t.find("a").html(i.highlighter(n)), t[0]
            }), t.first().addClass("active"), this.$menu.html(t), this
        }, next: function () {
            var t = this.$menu.find(".active").removeClass("active"), i = t.next();
            i.length || (i = e(this.$menu.find("li")[0])), i.addClass("active")
        }, prev: function () {
            var e = this.$menu.find(".active").removeClass("active"), t = e.prev();
            t.length || (t = this.$menu.find("li").last()), t.addClass("active")
        }, listen: function () {
            this.$element.on("focus", e.proxy(this.focus, this)).on("blur", e.proxy(this.blur, this)).on("keypress", e.proxy(this.keypress, this)).on("keyup", e.proxy(this.keyup, this)), this.eventSupported("keydown") && this.$element.on("keydown", e.proxy(this.keydown, this)), this.$menu.on("click", e.proxy(this.click, this)).on("mouseenter", "li", e.proxy(this.mouseenter, this)).on("mouseleave", "li", e.proxy(this.mouseleave, this))
        }, eventSupported: function (e) {
            var t = e in this.$element;
            return t || (this.$element.setAttribute(e, "return;"), t = "function" == typeof this.$element[e]), t
        }, move: function (e) {
            if (this.shown) {
                switch (e.keyCode) {
                    case 9:
                    case 13:
                    case 27:
                        e.preventDefault();
                        break;
                    case 38:
                        e.preventDefault(), this.prev();
                        break;
                    case 40:
                        e.preventDefault(), this.next()
                }
                e.stopPropagation()
            }
        }, keydown: function (t) {
            this.suppressKeyPressRepeat = ~e.inArray(t.keyCode, [40, 38, 9, 13, 27]), this.move(t)
        }, keypress: function (e) {
            this.suppressKeyPressRepeat || this.move(e)
        }, keyup: function (e) {
            switch (e.keyCode) {
                case 40:
                case 38:
                case 16:
                case 17:
                case 18:
                    break;
                case 9:
                case 13:
                    if (!this.shown) return;
                    this.select();
                    break;
                case 27:
                    if (!this.shown) return;
                    this.hide();
                    break;
                default:
                    this.lookup()
            }
            e.stopPropagation(), e.preventDefault()
        }, focus: function () {
            this.focused = !0
        }, blur: function () {
            this.focused = !1, !this.mousedover && this.shown && this.hide()
        }, click: function (e) {
            e.stopPropagation(), e.preventDefault(), this.select(), this.$element.focus()
        }, mouseenter: function (t) {
            this.mousedover = !0, this.$menu.find(".active").removeClass("active"), e(t.currentTarget).addClass("active")
        }, mouseleave: function () {
            this.mousedover = !1, !this.focused && this.shown && this.hide()
        }
    };
    var i = e.fn.typeahead;
    e.fn.typeahead = function (i) {
        return this.each(function () {
            var n = e(this), a = n.data("typeahead"), s = "object" == typeof i && i;
            a || n.data("typeahead", a = new t(this, s)), "string" == typeof i && a[i]()
        })
    }, e.fn.typeahead.defaults = {
        source: [],
        items: 8,
        menu: '<ul class="typeahead dropdown-menu"></ul>',
        item: '<li><a href="#"></a></li>',
        minLength: 1
    }, e.fn.typeahead.Constructor = t, e.fn.typeahead.noConflict = function () {
        return e.fn.typeahead = i, this
    }, e(document).on("focus.typeahead.data-api", '[data-provide="typeahead"]', function () {
        var t = e(this);
        t.data("typeahead") || t.typeahead(t.data())
    })
}(window.jQuery), function (e) {
    e.fn.ace_wysiwyg = function (t) {
        var i = e.extend({speech_button: !0, wysiwyg: {}}, t),
            n = ["#ac725e", "#d06b64", "#f83a22", "#fa573c", "#ff7537", "#ffad46", "#42d692", "#16a765", "#7bd148", "#b3dc6c", "#fbe983", "#fad165", "#92e1c0", "#9fe1e7", "#9fc6e7", "#4986e7", "#9a9cff", "#b99aff", "#c2c2c2", "#cabdbf", "#cca6ac", "#f691b2", "#cd74e6", "#a47ae2", "#444444"],
            a = {
                font: {
                    values: ["Arial", "Courier", "Comic Sans MS", "Helvetica", "Open Sans", "Tahoma", "Verdana"],
                    icon: "fa fa-font",
                    title: "Font"
                },
                fontSize: {values: {5: "Huge", 3: "Normal", 1: "Small"}, icon: "fa fa-text-height", title: "Font Size"},
                bold: {icon: "fa fa-bold", title: "Bold (Ctrl/Cmd+B)"},
                italic: {icon: "fa fa-italic", title: "Italic (Ctrl/Cmd+I)"},
                strikethrough: {icon: "fa fa-strikethrough", title: "Strikethrough"},
                underline: {icon: "fa fa-underline", title: "Underline"},
                insertunorderedlist: {icon: "fa fa-list-ul", title: "Bullet list"},
                insertorderedlist: {icon: "fa fa-list-ol", title: "Number list"},
                outdent: {icon: "fa fa-outdent", title: "Reduce indent (Shift+Tab)"},
                indent: {icon: "fa fa-indent", title: "Indent (Tab)"},
                justifyleft: {icon: "fa fa-align-left", title: "Align Left (Ctrl/Cmd+L)"},
                justifycenter: {icon: "fa fa-align-center", title: "Center (Ctrl/Cmd+E)"},
                justifyright: {icon: "fa fa-align-right", title: "Align Right (Ctrl/Cmd+R)"},
                justifyfull: {icon: "fa fa-align-justify", title: "Justify (Ctrl/Cmd+J)"},
                createLink: {
                    icon: "fa fa-link",
                    title: "Hyperlink",
                    button_text: "Add",
                    placeholder: "URL",
                    button_class: "btn-primary"
                },
                unlink: {icon: "fa fa-chain-broken", title: "Remove Hyperlink"},
                insertImage: {
                    icon: "fa fa-picture-o",
                    title: "Insert picture",
                    button_text: '<i class="' + ace.vars.icon + 'fa fa-file"></i> Choose Image &hellip;',
                    placeholder: "Image URL",
                    button_insert: "Insert",
                    button_class: "btn-success",
                    button_insert_class: "btn-primary",
                    choose_file: !0
                },
                foreColor: {values: n, title: "Change Color"},
                backColor: {values: n, title: "Change Background Color"},
                undo: {icon: "fa fa-undo", title: "Undo (Ctrl/Cmd+Z)"},
                redo: {icon: "fa fa-repeat", title: "Redo (Ctrl/Cmd+Y)"},
                viewSource: {icon: "fa fa-code", title: "View Source"}
            },
            s = i.toolbar || ["font", null, "fontSize", null, "bold", "italic", "strikethrough", "underline", null, "insertunorderedlist", "insertorderedlist", "outdent", "indent", null, "justifyleft", "justifycenter", "justifyright", "justifyfull", null, "createLink", "unlink", null, "insertImage", null, "foreColor", null, "undo", "redo", null, "viewSource"];
        return this.each(function () {
            var t = ' <div class="wysiwyg-toolbar btn-toolbar center"> <div class="btn-group"> ';
            for (var n in s) if (s.hasOwnProperty(n)) {
                var r = s[n];
                if (null === r) {
                    t += ' </div> <div class="btn-group"> ';
                    continue
                }
                if ("string" == typeof r && r in a) r = a[r], r.name = s[n]; else {
                    if (!("object" == typeof r && r.name in a)) continue;
                    r = e.extend(a[r.name], r)
                }
                var o = "className" in r ? r.className : "btn-default";
                switch (r.name) {
                    case"font":
                        t += ' <a class="btn btn-sm ' + o + ' dropdown-toggle" data-toggle="dropdown" title="' + r.title + '"><i class="' + ace.vars.icon + r.icon + '"></i><i class="' + ace.vars.icon + 'fa fa-angle-down icon-on-right"></i></a> ', t += ' <ul class="dropdown-menu dropdown-light dropdown-caret">';
                        for (var l in r.values) r.values.hasOwnProperty(l) && (t += ' <li><a data-edit="fontName ' + r.values[l] + '" style="font-family:\'' + r.values[l] + "'\">" + r.values[l] + "</a></li> ");
                        t += " </ul>";
                        break;
                    case"fontSize":
                        t += ' <a class="btn btn-sm ' + o + ' dropdown-toggle" data-toggle="dropdown" title="' + r.title + '"><i class="' + ace.vars.icon + r.icon + '"></i>&nbsp;<i class="' + ace.vars.icon + 'fa fa-angle-down icon-on-right"></i></a> ', t += ' <ul class="dropdown-menu dropdown-light dropdown-caret"> ';
                        for (var c in r.values) r.values.hasOwnProperty(c) && (t += ' <li><a data-edit="fontSize ' + c + '"><font size="' + c + '">' + r.values[c] + "</font></a></li> ");
                        t += " </ul> ";
                        break;
                    case"createLink":
                        t += ' <div class="btn-group"> <a class="btn btn-sm ' + o + ' dropdown-toggle" data-toggle="dropdown" title="' + r.title + '"><i class="' + ace.vars.icon + r.icon + '"></i></a> ', t += ' <div class="dropdown-menu dropdown-caret dropdown-menu-right">							 <div class="input-group">								<input class="form-control" placeholder="' + r.placeholder + '" type="text" data-edit="' + r.name + '" />								<span class="input-group-btn">									<button class="btn btn-sm ' + r.button_class + '" type="button">' + r.button_text + "</button>								</span>							 </div>						</div> </div>";
                        break;
                    case"insertImage":
                        t += ' <div class="btn-group"> <a class="btn btn-sm ' + o + ' dropdown-toggle" data-toggle="dropdown" title="' + r.title + '"><i class="' + ace.vars.icon + r.icon + '"></i></a> ', t += ' <div class="dropdown-menu dropdown-caret dropdown-menu-right">							 <div class="input-group">								<input class="form-control" placeholder="' + r.placeholder + '" type="text" data-edit="' + r.name + '" />								<span class="input-group-btn">									<button class="btn btn-sm ' + r.button_insert_class + '" type="button">' + r.button_insert + "</button>								</span>							 </div>", r.choose_file && "FileReader" in window && (t += '<div class="space-2"></div>							 <label class="center block no-margin-bottom">								<button class="btn btn-sm ' + r.button_class + ' wysiwyg-choose-file" type="button">' + r.button_text + '</button>								<input type="file" data-edit="' + r.name + '" />							  </label>'), t += " </div> </div>";
                        break;
                    case"foreColor":
                    case"backColor":
                        t += ' <select class="hide wysiwyg_colorpicker" title="' + r.title + '"> ';
                        for (var d in r.values) t += ' <option value="' + r.values[d] + '">' + r.values[d] + "</option> ";
                        t += " </select> ", t += ' <input style="display:none;" disabled class="hide" type="text" data-edit="' + r.name + '" /> ';
                        break;
                    case"viewSource":
                        t += ' <a class="btn btn-sm ' + o + '" data-view="source" title="' + r.title + '"><i class="' + ace.vars.icon + r.icon + '"></i></a> ';
                        break;
                    default:
                        t += ' <a class="btn btn-sm ' + o + '" data-edit="' + r.name + '" title="' + r.title + '"><i class="' + ace.vars.icon + r.icon + '"></i></a> '
                }
            }
            t += " </div> ";
            var h;
            i.speech_button && "onwebkitspeechchange" in (h = document.createElement("input")) && (t += ' <input class="wysiwyg-speech-input" type="text" data-edit="inserttext" x-webkit-speech />'), h = null, t += " </div> ", t = i.toolbar_place ? i.toolbar_place.call(this, t) : e(this).before(t).prev(), t.find("a[title]").bstooltip({
                animation: !1,
                container: "body"
            }), t.find(".dropdown-menu input[type=text]").on("click", function () {
                return !1
            }).on("change", function () {
                e(this).closest(".dropdown-menu").siblings(".dropdown-toggle").dropdown("toggle")
            }).on("keydown", function (t) {
                27 == t.which ? (this.value = "", e(this).change()) : 13 == t.which && (t.preventDefault(), t.stopPropagation(), e(this).change())
            }), t.find("input[type=file]").prev().on(ace.click_event, function () {
                e(this).next().click()
            }), t.find(".wysiwyg_colorpicker").each(function () {
                e(this).ace_colorpicker({pull_right: !0}).change(function () {
                    e(this).nextAll("input").eq(0).val(this.value).change()
                }).next().find(".btn-colorpicker").bstooltip({title: this.title, animation: !1, container: "body"})
            });
            var u = e(this), f = !1;
            t.find("a[data-view=source]").on("click", function (t) {
                if (t.preventDefault(), f) {
                    var i = u.next();
                    u.html(i.val()).show(), i.remove(), e(this).removeClass("active")
                } else e("<textarea />").css({
                    width: u.outerWidth(),
                    height: u.outerHeight()
                }).val(u.html()).insertAfter(u), u.hide(), e(this).addClass("active");
                f = !f
            });
            var p = e.extend({}, {activeToolbarClass: "active", toolbarSelector: t}, i.wysiwyg || {});
            e(this).wysiwyg(p)
        }), this
    }
}(window.jQuery), function (e, t) {
    function i(t, i) {
        var n = i.max;
        n = ("" + n).length;
        var a = parseInt(Math.max(20 * n + 40, 90)), s = e(t);
        s.addClass("spinner-input form-control").wrap('<div class="ace-spinner">');
        var r = s.closest(".ace-spinner").spinner(i).wrapInner("<div class='input-group'></div>"),
            o = r.data("spinner");
        i.on_sides ? (s.before('<div class="spinner-buttons input-group-btn">					<button type="button" class="btn spinner-down btn-xs ' + i.btn_down_class + '">						<i class="' + ace.vars.icon + i.icon_down + '"></i>					</button>				</div>').after('<div class="spinner-buttons input-group-btn">					<button type="button" class="btn spinner-up btn-xs ' + i.btn_up_class + '">						<i class="' + ace.vars.icon + i.icon_up + '"></i>					</button>				</div>'), r.addClass("touch-spinner"), r.css("width", a + "px")) : (s.after('<div class="spinner-buttons input-group-btn">					<button type="button" class="btn spinner-up btn-xs ' + i.btn_up_class + '">						<i class="' + ace.vars.icon + i.icon_up + '"></i>					</button>					<button type="button" class="btn spinner-down btn-xs ' + i.btn_down_class + '">						<i class="' + ace.vars.icon + i.icon_down + '"></i>					</button>				</div>'), ace.vars.touch || i.touch_spinner ? (r.addClass("touch-spinner"), r.css("width", a + "px")) : (s.next().addClass("btn-group-vertical"), r.css("width", a + "px"))), s.on("mousewheel.spinner DOMMouseScroll.spinner", function (e) {
            var t = e.originalEvent.detail < 0 || e.originalEvent.wheelDelta > 0 ? 1 : -1;
            return o.step(t > 0), o.triggerChangedEvent(), !1
        }), r.on("changed", function () {
            s.trigger("change")
        }), this._call = function (e, t) {
            o[e](t)
        }
    }

    e.fn.ace_spinner = function (n, a) {
        var s, r = this.each(function () {
            var t = e(this), r = t.data("ace_spinner"), o = "object" == typeof n && n;
            r || (o = e.extend({}, e.fn.ace_spinner.defaults, n), t.data("ace_spinner", r = new i(this, o))), "string" == typeof n && (s = r._call(n, a))
        });
        return s === t ? r : s
    }, e.fn.ace_spinner.defaults = {
        icon_up: "fa fa-chevron-up",
        icon_down: "fa fa-chevron-down",
        on_sides: !1,
        btn_up_class: "",
        btn_down_class: "",
        max: 999,
        touch_spinner: !1
    }
}(window.jQuery), function (e) {
    var t = {
        "open-icon": ace.vars.icon + "fa fa-folder-open",
        "close-icon": ace.vars.icon + "fa fa-folder",
        selectable: !0,
        "selected-icon": ace.vars.icon + "fa fa-check",
        "unselected-icon": ace.vars.icon + "fa fa-times"
    };
    e.fn.ace_tree = function (i) {
        return t = e.extend({}, t, i), this.each(function () {
            var i = e(this);
            i.html('<div class="tree-folder" style="display:none;">				<div class="tree-folder-header">					<i class="' + ace.vars.icon + t["close-icon"] + '"></i>					<div class="tree-folder-name"></div>				</div>				<div class="tree-folder-content"></div>				<div class="tree-loader" style="display:none"></div>			</div>			<div class="tree-item" style="display:none;">				' + (null == t["unselected-icon"] ? "" : '<i class="' + ace.vars.icon + t["unselected-icon"] + '"></i>') + '				<div class="tree-item-name"></div>			</div>'), i.addClass(1 == t.selectable ? "tree-selectable" : "tree-unselectable"), i.tree(t)
        }), this
    }
}(window.jQuery), function (e) {
    e.fn.ace_wizard = function (t) {
        return this.each(function () {
            var i = e(this);
            i.wizard();
            var n = i.siblings(".wizard-actions").eq(0), a = i.data("wizard");
            a.$prevBtn.remove(), a.$nextBtn.remove(), a.$prevBtn = n.find(".btn-prev").eq(0).on(ace.click_event, function () {
                a.previous()
            }).attr("disabled", "disabled"), a.$nextBtn = n.find(".btn-next").eq(0).on(ace.click_event, function () {
                a.next()
            }).removeAttr("disabled"), a.nextText = a.$nextBtn.text();
            var s = t && (t.selectedItem && t.selectedItem.step || t.step);
            s && (a.currentStep = s, a.setState())
        }), this
    }
}(window.jQuery);