import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	alignItems: 'center',
	gap: '$2',
});

export const LegendBox = styled(Box, {
	width: 16,
	height: 16,
	borderRadius: 1,
	transition:
		'border-top-width .3s, border-right-width .3s, border-width .3s, border-top-style .3s, border-right-style .3s, border-style .3s',

	variants: {
		borderType: {
			top: {
				borderTopWidth: 2,
				borderTopStyle: 'solid',
			},

			right: {
				borderRightWidth: 2,
				borderRightStyle: 'solid',
			},

			full: {
				borderStyle: 'solid',
				borderWidth: 1,
			},
		},
	},
});
