import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	width: '100%',
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '$5',
	marginBottom: 8,
});

export const TestInfoBox = styled(GridBox, {
	minHeight: 40,
	maxWidth: 370,
	gridTemplateColumns: 'repeat(3, auto)',
	alignItems: 'center',
	gap: '$3',
});

export const ChildrenBox = styled(GridBox, {
	justifySelf: 'end',
});
