import {useCallback} from 'react';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {GradeLevelModel} from '../../../types';
import {SelectField} from './index.styled';

type Props = {
	selected: number,
	grades: GradeLevelModel[],
	skeleton?: boolean,
	onChange: (value: number) => void,
}

export function GradeSelect(props: Props) {
	const onChange = useCallback((value: string) => props.onChange(Number(value)), [props.onChange]);

	return <Select.Root value={props.selected.toString()} onValueChange={onChange} dataCy='grade-select'>
		<SelectField placeholder='Grade' css={{width: '96px'}} skeleton={props.skeleton}/>
		<Select.Content>
			<Select.Option value='0'><Text bold size='medium'>All</Text></Select.Option>
			{props.grades.map((i, index) => <Select.Option value={i.gradeLevelID.toString()} key={i.gradeLevelID}>
				<Text bold size='medium'>{i.name}</Text>
			</Select.Option>)}
		</Select.Content>
	</Select.Root>;
}
