import {Input} from '@esgillc/ui-kit/control';
import {InputStyles} from '@esgillc/ui-kit/form-control';
import {InputCleanableProps} from './types';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {ChangeEvent, MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Icon} from '../../../icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {fromEvent, map, startWith} from 'rxjs';

export function InputCleanable(props: InputCleanableProps) {
	const {
		placeholder,
		value: initialValue,
		errorMessage,
		onChange,
		onBlur,
		onClearValue,
		shouldBeHidden,
		focused: externalFocused,
	} = props;

	const [isInputHide, setIsInputHide] = useState(shouldBeHidden);
	const [value, setValue] = useState(initialValue ?? '');
	const [focused, setFocused] = useState(false);

	const [isOverflowing, setIsOverflowing] = useState(false);

	useEffect(() => {
		const resize$ = fromEvent(window, 'resize').pipe(map(() => inputRef.current));

		const subscription = resize$.pipe(startWith(inputRef.current)).subscribe(() => {
			if(inputRef.current) {
				setIsOverflowing(inputRef.current.scrollWidth > inputRef.current.clientWidth);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		setIsInputHide(shouldBeHidden);
	}, [shouldBeHidden]);

	const inputRef = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		if(externalFocused && inputRef.current) {
			requestAnimationFrame(() => {
				setFocused(true);
				inputRef.current.focus();
			});
		}
	}, [externalFocused]);

	const isErrorIconVisible = errorMessage && !focused;
	const isClearIconVisible = value && focused && !isErrorIconVisible;

	const handleInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const targetValue = event.target.value;

			setValue(targetValue);
			onChange?.(event, {...props, value: targetValue});
		},
		[onChange],
	);

	const handleFocus = useCallback(() => {
		setFocused(true);
	}, []);

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		event.target.scrollLeft = 0;

		setFocused(false);
		setIsInputHide(shouldBeHidden);

		onBlur?.(event, {...props, value});
	};

	const handleClearValue = useCallback(() => {
		const emptyValue = '';

		setValue(emptyValue);
		onClearValue?.(emptyValue);
		inputRef.current?.focus();
	}, [onClearValue]);

	const onInputContainerClick = useCallback(() => {
		setIsInputHide(false);
		inputRef.current?.focus();
	}, []);

	const handleClearableButtomMouseDown = useCallback((event: MouseEvent<SVGSVGElement>) => {
		event.preventDefault();
	}, []);

	return (
		<OnHoverTooltip message={!shouldBeHidden && isOverflowing && value || ''}>
			<div className={styles.container}>
				<div
					className={styles.inputContainer}
					onClick={onInputContainerClick}
				>
					<Input
						value={value}
						placeholder={placeholder}
						onChange={handleInputChange}
						className={join(
							styles.input,
							!!errorMessage && InputStyles.error,
							(isClearIconVisible || isErrorIconVisible) && styles.withIcon,
						)}
						autoComplete='off'
						onFocus={handleFocus}
						onBlur={handleBlur}
						type='text'
						ref={inputRef}
					/>
					{isInputHide && (
						<div className={styles.inputHide} data-hidden-value='****' />
					)}
					{isClearIconVisible && (
						<Icon.CrossIcon
							className={join(styles.inputIcon, styles.clearIcon)}
							onClick={handleClearValue}
							onMouseDown={handleClearableButtomMouseDown}
						/>
					)}
					{isErrorIconVisible && <Icon.ErrorIcon className={styles.inputIcon} />}
				</div>
				{errorMessage && (
					<span className={styles.errorMessage}>{errorMessage}</span>
				)}
			</div>
		</OnHoverTooltip>
	);
}
