import {useStudentsRootContext} from '../students-root/context';
import {StudentsPreview} from '../students-preview';
import {StudentsCounter} from '../students-counter';

export function StudentsInfo({counterTitle}:{counterTitle: string}) {
	const {selectedStudentIDs} = useStudentsRootContext();

	if (selectedStudentIDs.length === 1) {
		return (
			<StudentsPreview />
		);
	}

	return (
		<StudentsCounter title={counterTitle} />
	);
}