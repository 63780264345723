import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo} from 'react';
import {CSS} from '@stitches/react';
import {styled} from '@esgi/ui/theme';
import {useProgressBarContext} from '../../context';
import {Box} from '@esgi/ui/layout';
import {useTransition, animated, config} from 'react-spring';
import {ThemeColorKeys} from '../../../types';
import {getThemeColor} from '../../../utils';

type RingDashedProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: Omit<CSS, 'width' | 'height'>;

	/** Set width and height for svg. */
	size?: number;

	/** Set width of dashes. */
	dashesStrokeWidth?: number;

	/** Set stroke color to active dashes. */
	activeStrokeColor?: ThemeColorKeys;

	/** Set stroke color to non active dashes. */
	inactiveStrokeColor?: ThemeColorKeys;
};

export const RingDashed = forwardRef<HTMLDivElement, RingDashedProps>(
	(
		{
			dataCy,
			size = 48,
			css,
			dashesStrokeWidth = 2,
			activeStrokeColor = 'positive',
			inactiveStrokeColor = 'positiveHighlight',
			children,
			...props
		},
		forwaredRef,
	) => {
		const {maxValue, value} = useProgressBarContext();

		const circleValues = useMemo(() => {
			const radius = size / 2;
			const perimeter = 2 * Math.PI * radius;

			return {
				radius,
				perimeter,
			};
		}, [size]);

		const pieValue = useMemo(() => {
			const angle = 360 / maxValue;
			const pieValue = Math.floor(angle / 4);

			return pieValue < 1 ? 1 : pieValue;
		}, [maxValue]);

		const generatePie = useCallback(() => {
			const radius = circleValues.radius;

			const startPointX = radius;
			const startPointY = radius;

			const endLineX = radius;
			const endLineY = 0;

			const xAsixRotation = 0;

			const largeArcFlag = pieValue <= 50 ? 0 : 1;
			const sweepFlag = 1;

			const endArcX = radius + Math.sin((2 * Math.PI) / (100 / pieValue)) * radius;
			const endArcY = radius - Math.cos((2 * Math.PI) / (100 / pieValue)) * radius;

			const d = `M${startPointX} ${startPointY} L${endLineX} ${endLineY} A${radius} ${radius} ${xAsixRotation} ${largeArcFlag} ${sweepFlag} ${endArcX} ${endArcY} Z`;

			return d;
		}, [circleValues, pieValue]);

		const renderPie = useCallback(
			(index: number) => {
				const primaryRotationAngle = 360 / maxValue;
				const rotationAngle = primaryRotationAngle * index + 5;

				const rotationTransformation = `rotate(${rotationAngle}, ${circleValues.radius}, ${circleValues.radius})`;

				const dValue = generatePie();
				const fillColor = getThemeColor(index + 1 <= value ? activeStrokeColor : inactiveStrokeColor);

				return {
					rotationTransformation,
					dValue,
					fillColor,
				};
			},
			[maxValue, activeStrokeColor, circleValues.radius, generatePie, inactiveStrokeColor, value],
		);

		const pieItems = useMemo(
			() => [...Array(maxValue)].fill(null).map((_, index) => renderPie(index)),
			[maxValue, renderPie],
		);

		const transitions = useTransition(pieItems, {
			from: {opacity: 0},
			enter: {opacity: 1},
			trail: 300 / maxValue,
			config: config.molasses,
		});

		const renderSVGContent = useCallback(() => {
			const circleCommonProps = {
				r: circleValues.radius - dashesStrokeWidth,
				cx: circleValues.radius,
				cy: circleValues.radius,
			};

			if (maxValue >= 2) {
				return (
					<>
						{transitions((style, {dValue, fillColor, rotationTransformation}) => (
							<animated.path style={style} d={dValue} fill={fillColor} transform={rotationTransformation} />
						))}

						<Circle {...circleCommonProps} fill={getThemeColor('vivid')} />
					</>
				);
			}

			return (
				<Circle
					{...circleCommonProps}
					fill='none'
					stroke={getThemeColor(value >= maxValue ? activeStrokeColor : inactiveStrokeColor)}
					strokeWidth={dashesStrokeWidth}
				/>
			);
		}, [maxValue, circleValues, dashesStrokeWidth, transitions, activeStrokeColor, inactiveStrokeColor, value]);

		return (
			<RingDashedBox
				data-cy={dataCy ?? 'ui-kit-progress-bar-ring-dashed'}
				css={{
					width: size,
					height: size,
					...css,
				}}
				ref={forwaredRef}
				{...props}
			>
				<SVG
					xmlns='http://www.w3.org/2000/svg'
					viewBox={`0 0 ${size} ${size}`}
					css={{
						width: size,
						height: size,
					}}
				>
					{renderSVGContent()}
				</SVG>

				<Content>{children}</Content>
			</RingDashedBox>
		);
	},
);

const RingDashedBox = styled(Box, {
	position: 'relative',
});

const SVG = styled('svg', {
	fill: 'none',
});

const Circle = styled('circle', {
	strokeLinecap: 'round',
});

const Content = styled(Box, {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	userSelect: 'none',
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: 0,
	marginTop: 0,
	marginBottom: 0,
	marginLeft: 'auto',
	marginRight: 'auto',
});
