import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const DatePickerWrapper = styled(FlexBox, {
	'& input[type=text]': {
		paddingRight: 0,
		minWidth: 280,
	},

	'& span[data-state=none-valued]': {
		color: '$primary',
	},
});