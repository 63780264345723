import {SelectableList as SelectableListUi} from '@esgi/ui/layout';
import {getListValue} from '../../../../kit';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectName} from '../subject-name';

type Props = {
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: (subjectId: SubjectTab['id']) => void;
	subjects: SubjectTab[];
};

export function SelectableList({selectedSubjectId, setSelectedSubjectId, subjects}: Props) {
	return (
		<SelectableListUi>
			<SelectableListUi.GroupRoot type='single' value={getListValue(selectedSubjectId)}>
				<SelectableListUi.Group>
					{subjects.map(({id, name, level, hidden}) => (
						<SelectableListUi.Item
							value={String(id)}
							key={id}
							forceSelect={selectedSubjectId !== id}
							onClick={() => setSelectedSubjectId(id)}
						>
							<SubjectName subjectLevel={level} subjectName={name} hidden={hidden} />
						</SelectableListUi.Item>
					))}
				</SelectableListUi.Group>
			</SelectableListUi.GroupRoot>
		</SelectableListUi>
	);
}
