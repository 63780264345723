import React from 'react';
import styles from './styles.module.less';

const items = [
	{
		title: 'Add Self-Assess Tests',
		description: 'Add up to three multiple choice tests for students to complete on their own device.',
	},
	{
		title: 'Select Students',
		description: 'Select one or many students.',
	},
	{
		title: 'Preview Assignment',
		description: 'Preview the tests and students you have selected.',
	},
	{
		title: 'Post to Students',
		description: 'When you are ready, post the assignment to your students to begin testing.',
	},
];

function TextItem({title, description}: { title: string, description: string }) {
	return <div className={styles.item}>
		<div className={styles.title}>{title}</div>
		<div className={styles.description}>{description}</div>
	</div>;
}

export function TextRow() {
	return <div className={styles.container}>
		{items.map(({title, description}) => <TextItem key={title} title={title} description={description}/>)}
	</div>;
}
