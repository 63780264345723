import {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {ElementStatus, useFormElementContext} from '@esgillc/ui-kit/form';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

interface Props extends PropsWithChildren {
	showOnError: 'required' | 'range' | 'email' | 'length-min' | 'length-max' | 'hex' | string;
	className?: string;
}

export function ErrorText({children, showOnError, className}: Props) {
	const context = useFormElementContext({}, ErrorText);
	const [showTooltip, setShowTooltip] = useState<boolean>(null);
	const error = context.firstError();
	const showError = context.status === ElementStatus.invalid;
	const textRef = useRef<HTMLSpanElement>();
	const parentRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if(showError){
			if(parentRef.current?.offsetWidth < textRef.current?.offsetWidth){
				setShowTooltip(true);
			}
		}
	}, [children, showError]);

	if(!showError) {
		return null;
	}

	return <OnHoverTooltip className={styles.tooltip} message={showTooltip ? children as string : ''}>
			<div ref={parentRef} className={
				join(styles.errorText, showTooltip && styles.ellipsis, error === showOnError ? styles.show : styles.hide, className)
			} data-cy='error-text'><span ref={textRef}>{children}</span></div>
		</OnHoverTooltip>;
}
