import React from 'react';
import {State} from 'shared/modules/test-details/components/tiles/test-intro-tile/component';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TestType} from '@esgi/core/enums';
import {isIOS} from '@esgillc/ui-kit/utils';
import '../component.less';

export class Props {
	testId: number;
	testType: TestType;
	createHandler: () => void;
}

export class TestIntroCreaterTile extends React.PureComponent<Props, State> {
	private isIOS: boolean = isIOS();

	constructor(props?: Props) {
		super(props);
	}

	render() {
		let liClassName = 'ti-placeholder ' + (this.isIOS && 'mobile ' || '');
		liClassName += this.props.testType === TestType.Score && 'single-score' || '';
		return <OnHoverTooltip
			message='A Test Intro Page allows you to start each test session with an example question or instructional page that does not count toward the test.'>
			<li className={liClassName} onClick={this.props.createHandler}>
				<svg className='icon' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'
					 fill='none'>
					<path
						d='M8.8 4H7.2V7.2H4V8.8H7.2V12H8.8V8.8H12V7.2H8.8V4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z'
						fill='#0088CC'/>
				</svg>
				<span>Test Introduction</span>
				<span className='optional'>(optional)</span>
			</li>
		</OnHoverTooltip>;
	}
}
