import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import {ReportTableComponentProps} from './types';
import styles from './report-table.module.less';

export function ReportTableColumn(props: ReportTableComponentProps) {
	return <div className={join(styles.column, props.className)}>
		{props.children}
	</div>;
}

