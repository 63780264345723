import styles from './styles.module.less';
import React, {useState} from 'react';
import {Menu} from '@esgillc/ui-kit/menu';
import {ProfileTrackTypePeriod} from '../../types';
import {Buttons} from '@esgillc/ui-kit/button';
import {DateTools} from '../../utils';

interface Props {
	periods: ProfileTrackTypePeriod[];
	name: string;
}

export function ProfileTrackRange({periods = [], name}: Props) {
	const [isOpened, setOpened] = useState(false);

	return <div className={styles.trackRange}>
		<Menu className={styles.menu} show={isOpened} onClickOutside={() => setOpened(false)}>
			<div className={styles.menuBody} data-cy='dates-range-menu'>
				<div className={styles.title}>{name.toUpperCase()} DATE RANGE</div>
				<div className={styles.row}>
					<div/>
					<div>
						<label className={styles.label}>From</label>
					</div>
					<div>
						<label className={styles.label}>To</label>
					</div>
				</div>
				{periods.map((item, index) => <div data-cy={'single-period'} key={index} className={styles.row}>
					<div>
						<div className={styles.circle}>{index + 1}</div>
					</div>
					<div>
						<label className={styles.label}>{DateTools.toUIString(item.from)}</label>
					</div>
					<div>
						<label className={styles.label}>{DateTools.toUIString(item.to)}</label>
					</div>
				</div>)}
			</div>
		</Menu>
		<div className={styles.showRangeButton} data-cy={'show-track-range-button'}>
			<Buttons.Link onClick={() => setOpened((prev) => !prev)}>Show track date range</Buttons.Link>
		</div>

	</div>;
}
