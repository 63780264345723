import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	paddingTop: 4,
	paddingBottom: 4,
	paddingLeft: 12,
	paddingRight: 4,
	alignItems: 'center',
});

export const Buttons = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: '$3',
});
