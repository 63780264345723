import React from 'react';
import {Button} from '@esgi/ui';
import {Close, Play} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {
	Title,
	ProfileHeaderWrapper,
	ButtonsContainer,
	IconContainer,
} from './styled';
import {FlexBox} from '@esgi/ui/layout';

interface Props {
	onClose: () => void;
	onStart: () => void;
}

export function ProfileHeader({onClose, onStart}: Props) {

	return <ProfileHeaderWrapper>
		<FlexBox align='center'>
			<IconContainer>
				<Play width={24} height={24}/>
			</IconContainer>
			<Title data-cy='start-test-title' size='small' color='neutral56'>Start Test</Title>
		</FlexBox>
		<ButtonsContainer>
			<Button onClick={onStart}>
				<Text size='large' font='mono'>START</Text>
			</Button>
			<Button.Icon onClick={onClose}>
				<Close height={24} width={24}/>
			</Button.Icon>
		</ButtonsContainer>
	</ProfileHeaderWrapper>;
}
