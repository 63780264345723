import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Container = styled(FlexBox, {
	padding: '20px',
	alignItems: 'center',
	justifyContent: 'center',
});

export const Table = styled('table', {
	textAlign: 'center',
	verticalAlign: 'middle',
	backgroundColor: '$vivid',
});

// is necessary to comply with html semantics, does not require styling
export const TableBody = styled('tbody', {});

export const Row = styled('tr', {
	'&:first-child': {
		borderTop: '1px solid $neutral94',
	},
});

export const Cell = styled('td', {
	padding: '5px 10px',
	minWidth: '35px',
	border: '1px solid $neutral72',

	variants: {
		gray: {
			true: {
				backgroundColor: '$mild',
			},
		},
		basis: {
			true: {
				fontWeight: 'bold',
			},
		},
	},
});
