import {SubjectType} from '@esgi/core/enums';


export module PieChartsReportEvents {
	export function SubjectChanged(type: SubjectType, id: number): SubjectChanged.Args {
		return {
			type,
			id,
		};
	}

	export module SubjectChanged {
		export interface Args {
			type: SubjectType,
			id: number
		}
	}
}
