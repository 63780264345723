import {useCallback, useMemo} from 'react';
import {useValue} from '../../hooks';
import {ToggleStudentFunction, ToggleStudentsFunction} from '../types';
import {StudentWithUnit, UnitData, UnitType, ChangeFunction} from '../../types';

export function useSelections(onChange: ChangeFunction<StudentWithUnit[]>) {
	const [selectedStudents, setSelectedStudents] = useValue<StudentWithUnit[]>([], onChange);
	const selectedStudentIds = useMemo(
		() => selectedStudents.map(({studentId}) => studentId),
		[selectedStudents],
	);

	const defaultUnitData: UnitData = {id: 0, type: UnitType.None};

	const toggleStudent = useCallback<ToggleStudentFunction>((
		studentId,
		unitData = defaultUnitData,
	) => {
		if (selectedStudentIds.includes(studentId)) {
			setSelectedStudents(selectedStudents.filter(
				({studentId: id}) => id !== studentId)
			);
		} else {
			setSelectedStudents([
				...selectedStudents,
				{studentId, unitData},
			]);
		}
	}, [selectedStudents]);

	const toggleStudents = useCallback<ToggleStudentsFunction>((
		studentIds,
		unitData = defaultUnitData,
	) => {
		const students = [...selectedStudents];
		studentIds.forEach((studentId) => {
			const index = students.findIndex(({studentId: id}) => id === studentId);
			if (index !== -1) {
				students.splice(index, 1);
			} else {
				students.push({studentId, unitData});
			}
		});
		setSelectedStudents(students);
	}, [selectedStudents]);

	return {
		selectedStudents,
		setSelectedStudents,
		toggleStudent,
		toggleStudents,
	};
}
