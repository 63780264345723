import React, {Suspense, useMemo} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {DefaultProfileControllers, InitialData} from './types';

const TeacherProfile = lazyComponent(() => import('./profiles/teacher'));
const Profile = lazyComponent(() => import('./profiles/profile'));

interface Props {
	close: () => void;
	initialData?: InitialData;
}


export default function UserProfile({initialData, close}: Props) {
	const currentUser = userStorage.get();
	const {userID, userType} = useMemo(() => {
		if (initialData) {
			return {userID: initialData.selectedUserID, userType: initialData.selectedUserType};
		}
		return {userID: currentUser.userID, userType: currentUser.userType};
	}, [initialData, currentUser]);
	const renderProfile = useMemo(() => {
		const defaultProps = {userID, onClose: close};
		switch (userType) {
			case UserType.T:
				return <TeacherProfile {...defaultProps}/>;
			case UserType.C:
				return <Profile
					{...defaultProps}
					title={'School Admin Account'}
					defaultController={DefaultProfileControllers.SchoolAdministrator}
				/>;
			case UserType.D:
				return <Profile
					{...defaultProps}
					title={'District Account'}
					canEditTracks
					canSeeTracks
					defaultController={DefaultProfileControllers.DistrictAdministrator}
				/>;
			case UserType.ISD:
				return <Profile
					{...defaultProps}
					title={'District Account'}
					canSeeTracks
					defaultController={DefaultProfileControllers.DistrictSpecialist}
				/>;
			case UserType.ISS:
				return <Profile
					{...defaultProps}
					title={'School Specialist Account'}
					canSeeTracks
					defaultController={DefaultProfileControllers.SchoolSpecialist}
				/>;
			case UserType.PA:
				return <Profile
					{...defaultProps}
					title={'Pre-Assess Account'}
					canSeeTracks
					isSchoolNameHidden
					isExpirationDateHidden
					isUserInfoDisabled
					defaultController={DefaultProfileControllers.PreAssess}
				/>;
			default:
				return null;
		}
	}, [userType, userID]);
	return <Suspense fallback={<></>}>
		{renderProfile}
	</Suspense>;
}
