import {observable, Button, Validators} from '@esgi/deprecated/knockout';
import {PurchaseForm} from '../../kit/purchase-form';
import {PurchaseTemplate} from '../html/purchase-template';
import {RenewalApi, RenewResponce} from '../server';
import {Step} from './steps';

export class PurchaseStep extends Step {

	constructor(private renewApi: RenewalApi) {
		super();
		this.title = 'Purchase';

		this.purchaseButton = this.createPurchaseButton();
		this.purchaseForm = this.createPurchaseForm();

		this.purchaseForm.promoCode = this.createPromoCode();
	}

	template = PurchaseTemplate;

	private createPromoCode() {
		const promoCodeValidator = Validators.Ajax(f => this.renewApi.api.renewCheckPromoCodeValidation(f.value()).done((r) => {
				f.validation.successValidation(true);
				f.validation.errorValidation(true);
				return r;
			}).always(() => {
				f.validation.validationInProgress(false);
			}),
			v => v.errorMessage);

		const f = this.createField('', promoCodeValidator);

		f.validation.showValidation(true);
		f.validation.successValidation(true);
		f.disabled(true);
		f.value.subscribe(v => {
			if (!v) {
				f.visible(false);
			} else {
				f.visible(true);
			}
		});

		return f;
	}

	@observable()
	purchaseForm: PurchaseForm;

	@observable()
	purchaseButton: Button;

	load() {
		return this.purchaseForm.load().always(f => {
			//ignore promo code validation on the form submit
			super.removeElement(this.purchaseForm.promoCode);
		});
	}

	serialize() {
		return this.purchaseForm.serialize();
	}

	private createPurchaseForm() {
		const form = new PurchaseForm();
		form.allowPayPal = true;
		form.showOrderOverview = false;

		this.addElement(form);
		return form;
	}

	private createPurchaseButton() {

		let purchaseButton = new Button();
		purchaseButton.title('Purchase');
		purchaseButton.className('btn btn-warning pull-right');
		purchaseButton.iconplacement('right');

		return purchaseButton;
	}

	view = {
		back: () => {
			$(this).trigger('backClicked');
		},
		purchaseSuccess: (r: RenewResponce) => {

			$(this).trigger('purchaseSuccess', r);
		},
	};

	events = {
		backClicked: (callback) => {
			$(this).on('backClicked', callback);
		},
		purchaseClicked: (callback) => {
			this.purchaseButton.events.click(callback);
		},
		purchaseSuccess: (callback: (e: Event, data: RenewResponce) => void) => {
			$(this).on('purchaseSuccess', callback);
		},
	};
}
