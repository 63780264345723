import {Modal} from '@esgillc/ui-kit/modal';
import styles from './final-step.module.less';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {Buttons} from '@esgillc/ui-kit/button';
import React from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {TemplateType} from '../../shared/types';
import {SightWordsReport} from 'modules/reports/writing-practice/shared/templates/sight-words/types';
import {NameWritingReport} from 'modules/reports/writing-practice/shared/templates/name-writing/types';
import TemplatePreview from 'modules/reports/writing-practice/shared/components/template-preview/template-preview';
import {NameWritingTemplate} from 'modules/reports/writing-practice/shared/templates/name-writing/name-writing-template';
import {SightWordsTemplate} from 'modules/reports/writing-practice/shared/templates/sight-words/sight-words-template';

interface Props {
	templateType: TemplateType;
	goToBack: () => void;
	closeForm: () => void;
	swReport: SightWordsReport;
	nwReport: NameWritingReport;
}

class State {}

export default class FinalStep extends React.PureComponent<Props, State> {
	constructor(props) {
		super(props);
	}

	componentDidMount(){}

	render() {
		return <>
			<Modal.Body>
				<FlexBox align='center' justify='start' direction='column' className={styles.body}>
					<div className={styles.title}>Your practice sheets have been downloaded.</div>
					<div className={styles.templates}>
						<div>
							<div className={join(styles.selector, styles.leftTemplate)}>
								{this.getTemplate()}
							</div>
							<div className={join(styles.selector, styles.rightTemplate)}>
								{this.getTemplate()}
							</div>
						</div>
					</div>
				</FlexBox>
			</Modal.Body>
			<Modal.Footer className={styles.footer}>
				<FlexBox align='center' justify='space-between' className={styles.navigation}>
					<div onClick={e => this.props.goToBack()} className={styles.backBtn}>
						<span className='glyphicon glyphicon-chevron-left arrow-icon'></span>
						Back
					</div>
					<div>
						<Buttons.Contained onClick={e => this.props.closeForm()}>Finish</Buttons.Contained>
					</div>
				</FlexBox>
			</Modal.Footer>
		</>;
	}

	private getTemplate(){
		return this.props.templateType === TemplateType.NameWriting ?
			<TemplatePreview className={styles.preview}>
				{
					this.props.nwReport &&
					<NameWritingTemplate
						details={this.props.nwReport.details}
						student={this.props.nwReport.students[0]}/>
				}
			</TemplatePreview>
			:
			<TemplatePreview className={styles.preview}>
				{
					this.props.swReport &&
					<SightWordsTemplate
						details={this.props.swReport.details}
						student={this.props.swReport.students[0]}
						words={this.props.swReport.words}/>
				}
			</TemplatePreview>;
	}
}