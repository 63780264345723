import type {SVGProps} from 'react';

export function GridSix(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			{...props}
		>
			<path
				d='M5.91663 6.75C5.91663 6.33579 6.25241 6 6.66663 6H11.1666C11.5808 6 11.9166 6.33579 11.9166 6.75V8.25C11.9166 8.66421 11.5808 9 11.1666 9H6.66663C6.25241 9 5.91663 8.66421 5.91663 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M13.4166 6.75C13.4166 6.33579 13.7524 6 14.1666 6H18.6666C19.0808 6 19.4166 6.33579 19.4166 6.75V8.25C19.4166 8.66421 19.0808 9 18.6666 9H14.1666C13.7524 9 13.4166 8.66421 13.4166 8.25V6.75Z'
				fill='#333333'
			/>
			<path
				d='M5.91663 11.25C5.91663 10.8358 6.25241 10.5 6.66663 10.5H11.1666C11.5808 10.5 11.9166 10.8358 11.9166 11.25V12.75C11.9166 13.1642 11.5808 13.5 11.1666 13.5H6.66663C6.25241 13.5 5.91663 13.1642 5.91663 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M13.4166 11.25C13.4166 10.8358 13.7524 10.5 14.1666 10.5H18.6666C19.0808 10.5 19.4166 10.8358 19.4166 11.25V12.75C19.4166 13.1642 19.0808 13.5 18.6666 13.5H14.1666C13.7524 13.5 13.4166 13.1642 13.4166 12.75V11.25Z'
				fill='#333333'
			/>
			<path
				d='M5.91663 15.75C5.91663 15.3358 6.25241 15 6.66663 15H11.1666C11.5808 15 11.9166 15.3358 11.9166 15.75V17.25C11.9166 17.6642 11.5808 18 11.1666 18H6.66663C6.25241 18 5.91663 17.6642 5.91663 17.25V15.75Z'
				fill='#333333'
			/>
			<path
				d='M13.4166 15.75C13.4166 15.3358 13.7524 15 14.1666 15H18.6666C19.0808 15 19.4166 15.3358 19.4166 15.75V17.25C19.4166 17.6642 19.0808 18 18.6666 18H14.1666C13.7524 18 13.4166 17.6642 13.4166 17.25V15.75Z'
				fill='#333333'
			/>
		</svg>
	);
}
