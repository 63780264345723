import {useMemo, useState} from 'react';
import {useService} from '@esgi/core/service';
import {environment} from '@esgi/core/environments';
import {Buttons} from '@esgillc/ui-kit/button';
import {SsoLinksService} from './service';
import {OneLinkedSsoLink} from './one-linked-sso-link';
import AppHistory from 'shared/tools/history/history';
import styles from './styles.module.less';
import {ServiceLoader} from '@esgillc/ui-kit/loader';


interface Props {
	isCleverAccountLinked: boolean;
	isOneClickAccountLinked: boolean;
	setCleverAccountLinked: (isCleverAccountLinked: boolean) => void;
	setOneClickAccountLinked: (isOneClickAccountLinked: boolean) => void;
}

export function SsoLinks({
	                         isCleverAccountLinked,
	                         isOneClickAccountLinked,
	                         setCleverAccountLinked,
	                         setOneClickAccountLinked,
                         }: Props) {
	const service = useService(SsoLinksService);
	const [isCleverLinked, setCleverLinked] = useState(isCleverAccountLinked);
	const [isOneClickLinked, setOneClickLinked] = useState(isOneClickAccountLinked);

	const handleUnlinkClever = () => {
		service.unlinkClever().subscribe(() => {
			setCleverAccountLinked(false);
			setCleverLinked(false);
		});
	};

	const handleUnlinkClassLink = () => {
		service.unlinkClassLink().subscribe(() => {
			setOneClickAccountLinked(false);
			setOneClickLinked(false);
		});
	};

	const handleLinkClassLink = () => {
		service.getClassLink().subscribe((response) => {
			AppHistory.navigateOutside(response.url);
		});
	};

	const handleLinkToClever = () => AppHistory.navigateOutside(`${environment.ssoApiURL}/integrations/clever/AuthEsgi`);

	const renderSSOLinks = useMemo(() => {
		if (isCleverLinked && !isOneClickLinked) {
			return <OneLinkedSsoLink
				title={'Clever'}
				linkTitle={'ClassLink'}
				onUnlinkClick={handleUnlinkClever}
				onLinkClick={handleLinkClassLink}
			/>;
		}

		if (!isCleverLinked && isOneClickLinked) {
			return <OneLinkedSsoLink
				title={'ClassLink'}
				linkTitle={'Clever'}
				onUnlinkClick={handleUnlinkClassLink}
				onLinkClick={handleLinkToClever}
			/>;
		}

		if (isCleverLinked && isOneClickLinked) {
			return <div className={styles.ssoUnlink}>
				<div className={styles.text} data-cy={'unlink-clever-title'}>
					<span>Your ESGI account is linked to Clever.</span>
					<Buttons.Link className={styles.link} onClick={handleUnlinkClever}>Unlink</Buttons.Link>
				</div>
				<div className={styles.text} data-cy={'unlink-class-link-title'}>
					<span>Your ESGI account is linked to ClassLink.</span>
					<Buttons.Link className={styles.link} onClick={handleUnlinkClassLink}>Unlink</Buttons.Link>
				</div>
			</div>;
		}

		return <div className={styles.ssoLink} data-cy={'both-unlinked'}>
			<Buttons.Link className={styles.link} onClick={handleLinkToClever}>Link to Clever account</Buttons.Link>
			<span className={styles.lineBreak}>|</span>
			<Buttons.Link className={styles.link} onClick={handleLinkClassLink}>Link to ClassLink account</Buttons.Link>
		</div>;
	}, [isCleverLinked, isOneClickLinked]);

	return <div data-cy={'sso-links'}>
		<ServiceLoader trackingService={service} fullscreen/>
		{renderSSOLinks}
	</div>;
}
