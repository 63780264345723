import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {InfoBlock} from '@esgi/ui/layout';

export const AnsweredQuestionsLabel = styled(Text, {
	marginRight: '1px',
});

export const InfoBlockLabel = styled(Text, {
	width: 'max-content',
	textWrap: 'no-wrap',
	marginLeft: '8px',
});

export const InfoBlockItem = styled(InfoBlock.Item, {
	justifyContent: 'flex-start',
});
