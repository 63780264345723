import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import {Fragment, Suspense, useMemo, useState, useEffect, useRef} from 'react';
import {TestType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {SummarySelectors} from './summary.selectors';
import {SummaryIEP} from './summary-iep';
import {SummaryButtons} from './summary-buttons';
import {SummaryChart} from './summary-chart';
import {SummaryInfo} from './summary-info';
import {CloseIcon} from '@esgillc/ui-kit/icons';
import {Tabs, TabPanel} from '@esgillc/ui-kit/tabs-with-panel';
import {getIEPGoal} from './api';
import {IEPGoal, IEPGoalRequest} from './types';
import {IEP_NAME} from './utils';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import {debounce} from 'underscore';
import {useEventEffect} from '@esgillc/events';
import {
	IEPGoalCreated,
	IEPGoalChanged,
} from 'modules/forms/iep-goal-form/events';
import {hasIEPLogic} from '../../../common/utils';
import styles from './styles.module.less';

const AddSummaryNoteButton = lazyComponent(() => import('modules/assessments/testing/add-summary-notes/button'));

export type SummaryProps = {
	studentName: string;
	testName: string;
	correct: number;
	total: number;
	testID: number;
	testType: TestType;
	testResultsCorrectVerbiage?: string;
	testResultsIncorrectVerbiage?: string;
	showPieChart: boolean;
	testSessionID: number;
	note?: string;
	iep?: IEPGoalRequest;
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClose: () => void;
	onTestHistoryClicked: () => void;
	onFlashCardsClicked?: () => void;
	onTestSessionDetailsClicked: () => void;
	onIEPEdit: () => void;
	onIEPReport: () => void;
}

export function TestSummary(props: SummaryProps): JSX.Element {
	const modal = useModal();
	const chart = useRef<HTMLDivElement>();
	const close = useCloseModal(modal, props.onClose);
	const openFlash = () => {
		props?.onFlashCardsClicked();
		close();
	};
	const openTestHistory = () => {
		props.onTestHistoryClicked();
		close();
	};
	const openTestDetails = () => {
		props.onTestSessionDetailsClicked();
		close();
	};
	const onIEPEdit = () => {
		props.onIEPEdit();
		close();
	};
	const onIEPReport = () => {
		props.onIEPReport();
		close();
	};
	const [iepGoal, setIepGoal] = useState<IEPGoal>(null);
	const [activeTab, setActiveTab] = useState(null);
	const [widthTab, setWidthTab] = useState(null);
	const hasIEP = hasIEPLogic();
	const iepGoalLinkText = hasIEP && ((iepGoal?.id && 'VIEW IEP GOAL') || 'ADD IEP GOAL');
	const tabs = useMemo(() => {
		const result = [
			{name: 'result', label: 'Results'},
		];
		if (iepGoal) {
			result.push({name: IEP_NAME, label: 'IEP Goal'});
		}
		return result;
	}, [iepGoal]);

	const syncSize = debounce(() => {
		if (!chart.current) {
			return;
		}
		const {width} = chart.current.getBoundingClientRect();
		width && setWidthTab(`${width}px`);
	}, 300);

	useEffect(() => {
		if (!hasIEPLogic()) {
			return () => {};
		}

		getIEPGoal(props.iep).subscribe(({iepGoal}) => setIepGoal(iepGoal));
		window.addEventListener('resize', syncSize);

		syncSize();

		return () => {
			window.removeEventListener('resize', syncSize);
		};
	}, []);

	useEventEffect(IEPGoalCreated, ({goal}) => setIepGoal(goal as IEPGoal));

	useEventEffect(IEPGoalChanged, ({goal}) => setIepGoal(goal as IEPGoal));

	const renderIEP = () => {
		if (hasIEPLogic()) {
			return (
				<>
					<Tabs
						items={tabs}
						active={activeTab}
						onSelect={({tab}) => setActiveTab(tab.name)}
					/>
					<div
						className={styles.main}
						style={{width: widthTab}}
					>
						<TabPanel key='name' name='result'>
							<SummaryChart
								ref={chart}
								goal={iepGoal}
								hierarchy={props.hierarchy}
								subject={props.subject}
								{...props}
							/>
						</TabPanel>
						<TabPanel key={IEP_NAME} name={IEP_NAME}>
							<SummaryIEP goal={iepGoal}/>
						</TabPanel>
					</div>
				</>
			);
		}

		return (
			<div className={styles.main}>
				<SummaryChart
					ref={chart}
					goal={iepGoal}
					hierarchy={props.hierarchy}
					subject={props.subject}
					{...props}
				/>
			</div>
		);
	};

	return (
		<Modal modalManagerRef={modal}>
			<div
				data-cy={SummarySelectors.summary}
				className={join(styles.summary, props.testType === TestType.Score && styles.score, !props.showPieChart && styles.simple)}
			>
				{props.showPieChart &&
					<div data-cy='pie-chart-panel'>
						<div className={styles.topCloseContainer}>
							<CloseIcon
								data-cy={SummarySelectors.closeButton}
								onClick={close}
							/>
						</div>
						<SummaryInfo {...props}/>
						{renderIEP()}
					</div>
				}
				{!props.showPieChart &&
					<div
						className={styles.intro}
						data-cy={SummarySelectors.simpleView}
					>
						<div className={styles.successLabel}>Success! You finished!</div>
						<img className={styles.snake} src='//s3.amazonaws.com/esgiwebfiles/images/snake.png' alt=''/>
						<div className={styles.summaryNotesButtonContainer}>
							<Suspense fallback={<Fragment/>}>
								<AddSummaryNoteButton
									testSessionID={props.testSessionID}
									note={props.note}
								/>
							</Suspense>
						</div>
					</div>
				}
				<SummaryButtons
					testType={props.testType}
					hierarchy={props.hierarchy}
					subject={props.subject}
					onOpenFlash={openFlash}
					onOpenTestDetails={openTestDetails}
					onOpenTestHistory={openTestHistory}
					onOpenIEPEdit={onIEPEdit}
					onOpenIEPReport={onIEPReport}
					onClose={close}
					iepGoalLinkText={iepGoalLinkText}
				/>
			</div>
		</Modal>
	);
}
