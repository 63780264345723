import {deepCopy} from 'shared/utils';
import {Criteria, Description, Level} from '../models';
import {CriteriaInfo, DescriptionsInfo, LevelsInfo} from './common';
import {recalculateCriteriaOrders} from './ordering';

export function updateCriteria(entity: Criteria, changes: Partial<Pick<Criteria, 'name' | 'order'>>, criteria: Criteria[], descriptions: Description[]): CriteriaInfo {
	let safeCriteria = deepCopy(criteria);
	const newEntity = safeCriteria.find(c => c.order === entity.order && c.id === entity.id);

	if (changes.name !== undefined) {
		newEntity.name = changes.name;
	}

	if (changes.order !== undefined && changes.order !== newEntity.order) {
		newEntity.order = changes.order;

		const arrCopy = Array.from(safeCriteria);
		const item = arrCopy.splice(entity.order - 1, 1);
		arrCopy.splice(changes.order - 1, 0, item[0]);
		arrCopy.forEach((criteria, index) => criteria.order = index + 1);

		const reorderResult = recalculateCriteriaOrders(arrCopy);
		safeCriteria = reorderResult.criteria;
	}

	return {
		criteria: safeCriteria,
	};
}

export function updateLevel(entity: Level, changes: Partial<Pick<Level, 'name' | 'score'>>, levels: Level[], descriptions: Description[]): LevelsInfo & DescriptionsInfo {
	let safeLevels = deepCopy(levels);
	let outDescriptions = descriptions;
	const newEntity = safeLevels.find(c => c.score === entity.score);

	if (changes.name !== undefined) {
		newEntity.name = changes.name;
	}

	return {
		levels: safeLevels,
		descriptions: outDescriptions,
	};
}

export function updateDescription(entity: Description, changes: Partial<Pick<Description, 'description' | 'details'>>, descriptions: Description[]): DescriptionsInfo {
	const safeDescription = deepCopy(descriptions);
	const newEntity = safeDescription.find(d => d.id === entity.id);

	if (changes.description !== undefined) {
		newEntity.description = changes.description;
	}

	if (changes.details !== undefined) {
		newEntity.details = changes.details;
	}

	return {
		descriptions: safeDescription,
	};
}
