import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';

type Props = {
    studentName: string,
    testName: string,
    onConfirm: VoidFunction,
    onCancel: VoidFunction,
};

export function SessionStartedAlert(props: Props): JSX.Element {
	const alert = Alert.useRef();
	const confirm = Alert.useClose(alert, props.onConfirm);
	const cancel = Alert.useClose(alert, props.onCancel);

	return <Alert modalManagerRef={alert} colorConfig={alertColorsConfig.neutral}>
        <Alert.Header onCloseIconClick={cancel}/>
        <Alert.Body>
            {props.studentName} is currently being tested on {props.testName} in our system, would you like to take over this session?
        </Alert.Body>
        <Alert.Footer>
            <Button.Text onClick={cancel} css={{marginRight: 12}}>
                NO
            </Button.Text>
            <Button.Text onClick={confirm}>
                YES
            </Button.Text>
        </Alert.Footer>
    </Alert>;
}
