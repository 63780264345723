import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {RadioGroup} from '@esgi/ui/controls';

export const ReportSettings = styled(FlexBox, {
	flexDirection: 'column',
	padding: '0 15px',
	marginTop: -16,
	boxShadow: '0px 4px 8px -4px #bfbfbf',
});

export const Row = styled(FlexBox, {
	alignItems: 'center',
	flexWrap: 'nowrap',

	'> div': {
		display: 'flex',
		alignItems: 'center',
		margin: '0 5px 10px',
		whiteSpace: 'nowrap',

		'span + div, span + span': {
			marginLeft: 4,
		},
	},

	'& + &': {
		marginTop: 8,
	},
});

export const Link = styled('a', {
	'& + *': {
		marginLeft: 4,
	},

	'& svg': {
		width: '20px',
		height: '20px',
	},
});

export const RadioboxRoot = styled(RadioGroup.Root, {
	width: '100%',

	'& svg': {
		width: '20px',
		height: '20px',
	},
});

export const RadioboxGroup = RadioGroup.Content;

export const Radiobox = styled(RadioGroup.Item, {
	display: 'inline-flex',

	'& + label': {
		marginBottom: 0,
	},
});
