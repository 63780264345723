import React, {useMemo, useState, Suspense} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {HierarchyInstance, SelectedSnapshot} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import {SsoTracker} from '@esgi/core/tracker';
import {userStorage} from '@esgi/core/authentication';
import TrackConfirmModal from '../../../../../modules/track-confirm';
import {lazyComponent} from '@esgi/core/react';
import {ITrackModel} from 'pages/home/components/school-year-selector/api';

const StudentDetailLauncher = lazyComponent(() => import('modules/reports/student-detail-report'));

interface Props {
	subjectId: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	autoTrack: boolean;
	noStudents: boolean;
	trackChanged: (track: ITrackModel) => void
}

export function StudentDetailButton({
	                                    noStudents, autoTrack, hierarchy,
	                                    subjectId,
	                                    subjectType,
	                                    applyHomepageChanges, trackChanged,
}: Props) {
	const [showLauncher, setShowLauncher] = useState(false);
	const [showTrackConfirm, setShowTrackConfirm] = useState(false);
	const snapshot = hierarchy.snapshot;
	const {classID, studentID, groupID, teacherID} = new SelectedSnapshot(snapshot);
	const changesCollector = useMemo(() => new ChangesCollector({
		id: subjectId,
		type: subjectType,
	}, snapshot), [showLauncher]);

	function icon() {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='17'
		            viewBox='0 0 15 17' fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M5.76923 1.13151C5.76923 0.506571 6.28587 0 6.92308 0H8.07692C8.71413 0 9.23077 0.506571 9.23077 1.13151H12.6923C13.9667 1.13151 15 2.14472 15 3.39454V14.7097C15 15.9595 13.9667 16.9727 12.6923 16.9727H2.30769C1.03328 16.9727 0 15.9595 0 14.7097V3.39454C0 2.14472 1.03328 1.13151 2.30769 1.13151H5.76923ZM6.92308 1.69727C6.92308 1.38483 7.18111 1.13151 7.5 1.13151C7.81889 1.13151 8.07692 1.38483 8.07692 1.69727C8.07692 2.00971 7.81889 2.26303 7.5 2.26303C7.18111 2.26303 6.92308 2.00971 6.92308 1.69727ZM5.76923 6.22332C5.76923 5.28594 6.54391 4.52605 7.5 4.52605C8.45609 4.52605 9.23077 5.28594 9.23077 6.22332C9.23077 7.1607 8.45609 7.9206 7.5 7.9206C6.54391 7.9206 5.76923 7.1607 5.76923 6.22332ZM4.61538 11.8809C4.61538 10.3186 5.9067 9.05211 7.5 9.05211C9.0933 9.05211 10.3846 10.3186 10.3846 11.8809V12.5723C10.3846 13.1279 9.92544 13.5782 9.35922 13.5782H5.64078C5.07456 13.5782 4.61538 13.1279 4.61538 12.5723V11.8809Z'
			      fill='#0088CC'/>
		</svg>;
	}

	function afterClosed() {
		applyHomepageChanges(changesCollector);
		setShowLauncher(false);
	}

	function runReport() {
		SsoTracker.trackEvent({
			trackingEvent: 'StudentDetailReport',
		});
		setShowLauncher(true);
		setShowTrackConfirm(false);
	}

	const clickHandler = () => {
		if(autoTrack) {
			setShowTrackConfirm(true);
		} else {
			runReport();
		}
	};

	return <>
		{showTrackConfirm && <TrackConfirmModal
			confirmed={runReport} canceled={() => setShowTrackConfirm(false)}
			trackChanged={trackChanged}
		/>}
		{showLauncher && <Suspense fallback={<></>}><StudentDetailLauncher options={{
			classId: classID,
			groupId: groupID,
			studentId: studentID,
			globalSchoolYearId: userStorage.get().globalSchoolYearID,
			subjectId: subjectId,
			subjectType: subjectType, userId: teacherID,
		}}
		                                                                   onClose={afterClosed}
		                                                                   changesCollector={changesCollector}
		                                                                   hierarchy={hierarchy}
		/>
		</Suspense>}
		<NoStudentProtectedButton title='Student Detail'
		                          icon={icon()}
		                          hoverTitle='Comprehensive breakdown of correct/incorrect items, scoring, and student progress over time'
		                          linkClassName='student-detail-report-link'
		                          noStudents={noStudents}
		                          onClicked={clickHandler}/>
	</>;
}
