import {Group} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/teachers/storage/groups/group';

export class AddNewGroup {
	constructor(public newGroup: Group) {}
}

export class EditGroup {
	constructor(public groupInfo: Group) {}
}

export class RemoveGroup {
	constructor(public groupInfo: Group) {}
}