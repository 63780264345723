import {useCallback, useEffect, useState} from 'react';

export function useContactUs() {
	const [showContactUs, setShowContactUs] = useState(false);

	const handleShow = useCallback(() => setShowContactUs(true), []);
	const handleClose = useCallback(() => setShowContactUs(false), []);

	useEffect(() => {
		global.showContactUs = handleShow;

		return () => {
			delete global.showContactUs;
		};
	}, [handleShow]);

	return {
		isVisible: showContactUs,
		handleShow,
		handleClose,
	};
}