import {Dispatch} from 'react';
import {createContext} from '@esgi/ui';
import {Class, Group, Student} from '@esgi/main/libs/store';

export type MultiSelectState = {
	classIDs: Class['id'][];
	groupIDs: Group['id'][];
	studentIDs: Student['id'][];
};

export type MultiSelectContextValue = {
	selected: MultiSelectState;
	toggleClassId: Dispatch<Class['id']>;
	toggleGroupId: Dispatch<Group['id']>;
	toggleStudentId: Dispatch<Student['id']>;
};

export const {Context: MultiSelectContext, useHook: useMultiSelectContext} =
	createContext<MultiSelectContextValue>('MultiSelectContext');
