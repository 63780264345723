import {UserType} from '@esgi/core/authentication';

export class SpecialistAssignModel {
	specialistGroupID: number;
	specialistUserID: number;
	specialistSchoolID: number;
	specialistType: UserType;
}

export class MoveStudentModel {
	schoolID: number;
	teacherID: number;
	classID: number;
	groupID: number;
}

export interface Field {
	id: number,
	value: string,
}
