import {UserType} from '@esgi/core/authentication';

export class PreModel {
	classID: number;
	teacherID: number;
	groupID: number;
	groupName: string;
	className: string;
	districtID: number;
	schoolID: number;
	userID: number;
	groupType: GroupType;
	userTypes: UserType[];
}

export enum ViewType {
    SchoolsGroup = 0,
    TeachersGroup = 1,
    GroupOfSpecialists = 2,
}

export enum GroupType {
    None = 0,
    TeachersGroup = 1,
    GroupOfSpecialists = 2,
}
