import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ContentWrapperBox = styled(GridBox, {
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 20,
	paddingRight: 20,
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	backgroundColor: '$vivid',
	justifyContent: 'center',

	variants: {
		textAlign: {
			center: {
				textAlign: 'center',
			},
		},
	},
});
