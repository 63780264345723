import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {ListRow} from '../components.styled';

export const ListContainer = styled(GridBox, {
	gridTemplateRows: 'auto 1fr',
	gap: 20,
	overflow: 'hidden',
});

export const ListContentRow = styled(ListRow, {
	minHeight: 36,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomRightRadius: 1,
	borderBottomLeftRadius: 1,

	variants: {
		checked: {
			false: {
				borderBottomColor: 'transparent',
			},

			true: {
				borderBottomColor: '$primaryMuted',
				backgroundColor: '$primaryBackground',
			},
		},

		disabled: {
			true: {
				backgroundColor: '$background',
			},
		},
	},
});
