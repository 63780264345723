import {StudentsPanelTabID} from '../../../types';
import {TabSectionProps} from '../../types';
import {MultiTabPanel} from '../../multi-tab-panel';
import {useStudentsPanelContext} from '../../../context';
import {useClassesContentState} from './use-classes-content-state';
import {useSortStudentsInBucket} from '../../hooks/use-sort-students-data';
import {useEffect, useMemo, useState} from 'react';
import {ClassContextValue, ClassesContext} from './context';
import {StudentSort, useUser} from '@esgi/core/authentication';
import {useEventEffect} from '@esgillc/events';
import {ChangeSortBy} from '@esgi/main/features/teacher/events';

export * from './context';
export * from './types';

export function ClassesTabContent({
	sectionTitle = 'Classes',
	description = '',
	children,
	...restTabProps
}: TabSectionProps<StudentsPanelTabID.Classes>) {
	const {
		entities: {classes, students},
	} = useStudentsPanelContext();
	const [sortByData, setSortByData] = useState<StudentSort | undefined>();
	const user = useUser();
	const classBuckets = useClassesContentState({classes, students});
	const sortedBuckets = useSortStudentsInBucket(classBuckets, sortByData);

	useEventEffect(ChangeSortBy, ({sortBy}) => {
		return setSortByData(sortBy);
	});

	useEffect(()=>{
		const sortBy = user?.studentSort;
		setSortByData(sortBy);
	}, [user]);

	const classesContext = useMemo<ClassContextValue>(() => {
		return {
			classBuckets: sortedBuckets,
		};
	}, [sortedBuckets]);

	return (
		<MultiTabPanel.TabContent
			id={StudentsPanelTabID.Classes}
			name={sectionTitle}
			description={description}
			{...restTabProps}
		>
			<ClassesContext.Provider value={classesContext}>{children}</ClassesContext.Provider>
		</MultiTabPanel.TabContent>
	);
}
