import {ComponentPropsWithoutRef, PropsWithChildren, forwardRef} from 'react';
import {Container, Divider, TitleContainer} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {BaseComponentProps} from '@esgi/ui';
import {Property} from '@stitches/react/types/css';
import {Box} from '@esgi/ui/layout';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	NonNullable<PropsWithChildren> & {
		title: string;
		withDivider?: boolean;
		childrenOverflow?: Property.Overflow;
		isTablet?: boolean;
	};

export const SectionBlock = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'test-dashboard-section-block',
			css = {},
			withDivider = true,
			children,
			title,
			childrenOverflow = 'hidden',
			...props
		},
		forwardedRef,
	) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
			<TitleContainer dataCy={title.replace(/\s+/g, '-').toLowerCase()}>
				<Text size='small' font='mono' color='lowContrast'>
					{title}
				</Text>

				{withDivider && <Divider />}
			</TitleContainer>

			<Box css={{overflow: childrenOverflow}}>{children}</Box>
		</Container>
	),
);
