import {ButtonProps} from './default';
import React from 'react';
import {Primary} from './primary';

/**
 * @deprecated Use buttons from @esgi/ui or @esgillc/ui-kit
 **/
export class Transparent extends React.PureComponent<ButtonProps> {
	render() {
		let props = {...this.props};
		props.className = props.className ? `btn-transparent ${props.className}` : 'btn-transparent';

		return <Primary
            {...props}
        >
        </Primary>;
	}
}