export enum IndicatorColorVariant {
	LanguageArts = 'LanguageArts',
	Science = 'Science',
	SocialStudies = 'SocialStudies',
	Math = 'Math',
	Spanish = 'Spanish',
	SEL = 'SEL',
	Other = 'Other',
}

export type TestColor =
	| {
			defaultColors: {
				indicatorWrapperColor: string;
				indicatorLineColor: IndicatorColorVariant;
			};
			customColor: null;
	  }
	| {
			defaultColors: null;
			customColor: string;
	  };
