import React, {ReactNode} from 'react';
import {AutoDimension} from '@esgi/deprecated/react';
import styles from './box-body.module.less';
import {join} from '@esgillc/ui-kit/utils';

class Props {
	opened: boolean;
	children?: ReactNode;
}

export class BoxBody extends React.PureComponent<Props> {
	render() {
		return <div className={styles.boxBody}>
			<AutoDimension fixWidth={true} fixHeight={true}>
				<div className={join(!this.props.opened && styles.hidden)}>
					{this.props.children}
				</div>
			</AutoDimension>
		</div>;
	}
}
