import React, {createRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {ModalManager} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import TestingService from '../../testing-service';

interface Props {
  service: TestingService;
	onClosed: () => void;
  onDisconnected: () => void;
}

export default class ShareScreenStartedDialog extends React.PureComponent<Props> {
	private modalManagerRef: React.RefObject<ModalManager> = createRef();

	private continueClicked = () => {
		this.modalManagerRef.current.close(() => this.props.onClosed());
	};

	private disconnectClicked = () => {
		this.props.service.endShareScreenSession();
		this.modalManagerRef.current.close(() => this.props.onDisconnected());
	};

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef}>
			<Alert.Body>
        Sorry, rubric tests cannot be used with Student Screen.
        To administer a rubric test, disconnect the Student Screen.
			</Alert.Body>
			<Alert.Footer>
        <Buttons.Text onClick={this.disconnectClicked}>
          DISCONNECT
        </Buttons.Text>
				<Buttons.Text onClick={this.continueClicked}>
          CONTINUE
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}
}
