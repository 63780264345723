import {BehaviorSubject, combineLatest} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {classesStore, groupsStore, studentsStore, Class, Group, Student} from '@esgi/main/libs/store';

export class StudentService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public classes$ = new BehaviorSubject<Class[]>([]);
	public groups$ = new BehaviorSubject<Group[]>([]);
	public students$ = new BehaviorSubject<Student[]>([]);
	private storageClasses = classesStore();
	private storageGroups = groupsStore();
	private storageStudents = studentsStore();

	constructor() {
		super();

		combineLatest([
			this.storageClasses.get(),
			this.storageGroups.get(),
			this.storageStudents.get(),
		]).subscribe(([classesState, groupsState, studentsState]) => {
			const {data: classes, loaded: classesLoaded} = classesState;
			const {data: groups, loaded: groupsLoaded} = groupsState;
			const {data: students, loaded: studentsLoaded} = studentsState;

			if(classesLoaded && groupsLoaded && studentsLoaded) {
				this.classes$.next(classes.filter(x => x.studentIDs.length > 0));
				this.groups$.next(groups.filter(x => x.studentIDs.length > 0));
				this.students$.next(students);
				this.loaded$.next(true);
			}
		});
	}

	public override dispose() {
		this.storageClasses.dispose();
		this.storageGroups.dispose();
		this.storageStudents.dispose();
	}

	public get(studentId: Student['id']) {
		return this.students$.value.find(({id}) => id === studentId);
	}
}
