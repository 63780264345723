import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {Drawer} from '@esgi/main/kits/common';
import {Form} from '@esgi/ui/form';
import {StudentTestingOptionsService} from './service';
import {SettingsTab, StudentTestingOptions as StudentTestingOptionsType} from '../../../../../types';
import {SingleCheckboxSection} from '../../../../single-checkbox-section';
import {LockHomepage} from '../../../../lock-homepage';
import {descriptions} from '../../../../../constants';
import {Text} from '@esgi/ui/typography';
import {ContentWrapper, PanelContent} from '../../../index.styled';
import {useStreamEffect} from '@esgi/ui';
import {userStorage} from '@esgi/core/authentication';

export function StudentTestingOptions({
	showSelfAssessOption,
	screenLockPIN,
	lockScreen,
	onUpdateInitData,
	onCloseDrawer,
	onUpdateTabTouchedData,
}: StudentTestingOptionsType) {
	const service = useService(StudentTestingOptionsService);
	const isUnlockHomepage = useBehaviorSubject(service.isUnlockHomepage);
	const isShowSelfAssessmentOptions = useBehaviorSubject(service.showSelfAssessOption);
	const [isDisableButton, setIsDisableButton] = useState(true);
	const initialData = useBehaviorSubject(service.initData);

	useEffect(() => {
		service.init({showSelfAssessOption, screenLockPIN, lockScreen});
	}, []);

	useStreamEffect(service.form.onChanged, () => {
		const isTouched = service.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.StudentTestingOptions, isTouched);
	});

	const onSave = () => {
		setIsDisableButton(true);
		service.save().subscribe({error: () => setIsDisableButton(false), next: ()=>{

			const changedData = {
				lockScreen: service.form.controls.lockScreen.value,
				screenLockPIN: service.form.controls.screenLockPIN.value,
				showSelfAssessOption: service.form.controls.selfAssessment.value,
			};

			userStorage.update(changedData);
			onUpdateInitData(changedData);
			service.initData.next({...initialData, ...changedData});
			onUpdateTabTouchedData(SettingsTab.StudentTestingOptions, false);
		}});
	};

	return (
		<Form data-cy='student-testing-options-root' controller={service.form}>
			<Drawer.PanelHeader
				data-cy='student-testing-options-header'
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={onSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
			>
				<Text data-cy='student-testing-options-label' size='large' color='neutral24' bold>
					Student Testing Options
				</Text>
			</Drawer.PanelHeader>
			<PanelContent data-cy='student-testing-options-content'>
				<ContentWrapper>
					<Drawer.ContentBlock
						data-cy='self-assessment-administration-block'
						title='Self-assessment Administration'
						withDivider
					>
						<SingleCheckboxSection
							checkboxControl={service.form.controls.selfAssessment}
							isDisabled={!isShowSelfAssessmentOptions}
							description={descriptions.selfAssessmentAdministration}
							dataCy='sa-administration-checkbox'
						/>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock data-cy='lock-homepage-block' title='Lock Homepage' withDivider>
						<LockHomepage
							unlockControl={service.form.controls.lockScreen}
							confirmPinControl={service.form.controls.confirmPin}
							pinControl={service.form.controls.screenLockPIN}
							isUnlocked={isUnlockHomepage}
						/>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}
