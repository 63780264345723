import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const IconWrapper = styled(FlexBox, {
	width: 40,
	height: 40,
	borderRadius: 12,
	border: '1 solid $border',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	color: '$primary',
	marginRight: '12px',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const Counter = styled(FlexBox, {
	[`> ${Text}`]: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		color: '$neutral56',
		marginLeft: '6px',
	},
});
