import {ToggleGroup} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	padding: 3,
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	paddingLeft: 13,
	paddingRight: 13,
	paddingTop: 8,
	paddingBottom: 8,

	'&[data-disabled]': {
		'& svg path': {
			fill: '$muted',
		},
	},

	variants: {
		applyDisabledStylesExtended: {
			true: {
				'&[data-state=on]': {
					'& svg path': {
						fill: '$muted',
					},
				},
			},

			false: {
				'&[data-state=on]': {
					'& svg path': {
						fill: '$primary',
					},
				},
			},
		},

		isSmallScreen: {
			true: {
				paddingLeft: 10,
				paddingRight: 10,
			},
		},
	},
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	marginTop: 6,
});
