import {keys} from 'underscore';

const showDebugMessages = process.env.NODE_ENV !== 'production';

function isTypeEqual<T extends any, R extends any>(source: T, target: R) {
	if (typeof source === typeof target) {
		if (typeof source === 'object') {
			if (source instanceof Array) {
				return target instanceof Array;
			}
			if (source instanceof Date) {
				return target instanceof Date;
			}
			if (source instanceof RegExp) {
				return target instanceof RegExp;
			}
		}
		return true;
	}
	return false;
}

function equal<T>(source: T, target: T, deep: number, origin: { source: T, target: T }): boolean {
	if (deep > 20) {
		if (showDebugMessages) {
			console.warn('equal(): object comparison truncated due to deep limitation. Avoid using objects that are too deep.', this.original);
		}
		return true;
	}

	if (!isTypeEqual(source, target)) {
		return false;
	}

	switch (typeof source) {
		case 'object': {
			if (source instanceof Array && target instanceof Array) {
				return source.length === target.length && !source.some((item, index) => !equal(item, target[index], deep + 1, origin));
			}

			if (source instanceof Date && target instanceof Date) {
				return source.getTime() === target.getTime();
			}
			if (source instanceof RegExp && target instanceof RegExp) {
				return source.source === target.source;
			}
			if ((!source && target) || (source && !target)) {
				return false;
			}

			return !keys(source).some(key => !equal(source[key], target[key], deep + 1, origin));
		}
		default:
			return source === target;
	}
}

export function deepEqual<T>(source: T, target: T): boolean {
	return equal(source, target, 0, {source, target});
}

function equalDebug<T>(source: T, target: T, deep: number, origin: { source: T, target: T }, path: string = ''): string[] {
	if (deep > 20) {
		if (showDebugMessages) {
			console.warn('equal(): object comparison truncated due to deep limitation. Avoid using objects that are too deep.', this.original);
		}
		return [];
	}

	if (!isTypeEqual(source, target)) {
		return [path + ' types are different.'];
	}

	switch (typeof source) {
		case 'object': {
			if (source instanceof Array && target instanceof Array) {
				if(source.length !== target.length) {
					return [`.${(path || 'source')}.length = ${source.length} | target = ${target.length}`];
				}
				return source.map((item, index) => {
					return equalDebug(item, target[index], deep + 1, origin, path + `[${index}]`);
				}).flat();
			}

			return keys(source).map((key) => equalDebug(source[key], target[key], deep + 1, origin, (path || 'source') + '.' + key))
				.flat()
				.filter(i => !!i);
		}
		default:
			return source === target ? [] : [path + `: source - ${source} | target = ${target}`];
	}
}

export function deepEqualDebug<T>(source: T, target: T): boolean {
	const res = equalDebug(source, target, 0, {source, target}, '').flat();
	console.debug(res);
	return !res.length;
}
