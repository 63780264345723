import {BoxArrowUpRight} from '@esgi/ui/icons';
import {Avatar as AvatarUI} from '@esgi/ui/avatar';
import {Link, Avatar, IconWrapper} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {StudentFullName} from '../student-full-name';
import {Student} from '@esgi/main/libs/store';
import {getInitials} from '../../../../kit';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

type StudentInfoProps = {
	student: Student;
	onStudentClick?: (studentID: number) => void;
	canView: boolean;
};

export function StudentInfo({student, onStudentClick, canView}: StudentInfoProps) {
	return (
		<GridBox gap='3' align='center' flow='column' dataCy='test-panel-section-info'>
			<Avatar size='sm' dataCy='test-panel-section-info-avatar'>
				<AvatarUI.Image src={student?.photoUrl}/>
				<AvatarUI.Fallback>
					{getInitials({firstName: student.firstName, lastName: student.lastName, textCase: 'toUpperCase'})}
				</AvatarUI.Fallback>
			</Avatar>
			<Tooltip>
				<Tooltip.Trigger>
					<Link disabled={!canView} onClick={() => onStudentClick?.(student.id)} dataCy='test-panel-section-info-student-link'>
						<StudentFullName
							textSize='large'
							firstName={student.firstName}
							lastName={student.lastName}
							dataCy='test-panel-section-info-student-full-name'
						/>
							<IconWrapper>
								<BoxArrowUpRight width={32} height={32} />
							</IconWrapper>
					</Link>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text size='small'>View Student Profile</Text>
				</Tooltip.Content>
			</Tooltip>
		</GridBox>
	);
}
