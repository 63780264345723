import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Title = styled(Text, {
	color: '$neutral56',
	height: '24px',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: '6px',
});
