import {OptionItem, FontSizeType, FontStyleType} from '../types';

export const fontSizes: OptionItem[] = [
	{label: '30 pts', value: FontSizeType.Small},
	{label: '40 pts', value: FontSizeType.Medium},
	{label: '62 pts', value: FontSizeType.Large},
];

export const fontStyles: OptionItem[] = [
	{label: FontStyleType.Default, value: FontStyleType.Default},
];

export const getFontSize = (value: string): FontSizeType => {
	const keys = Object.keys(FontSizeType);
	const values = Object.values(FontSizeType) as string[];
	const index = values.indexOf(value);
	return index !== -1 ? FontSizeType[keys[index]] : '';
};

export const getFontStyle = (value: string) => {
	const keys = Object.keys(FontStyleType);
	const values = Object.values(FontStyleType) as string[];
	const index = values.indexOf(value);
	return index !== -1 ? FontStyleType[keys[index]] : '';
};
