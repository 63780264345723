import {useEffect, useState} from 'react';
import {Alert} from '@esgillc/ui-kit/modal';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {getUser, UserType} from '@esgi/core/authentication';
import {StudentLimit} from 'modules/forms/students-form/types';
import styles from './styles.module.less';

const supportedAddress = 'https://esgisoftware.com/support';
const purchasingAdditionalSlotsUrl = 'https://support.esgisoftware.com/hc/en-us/articles/209158606-Purchasing-Additional-Student-Slots';

interface Props {
	limit: StudentLimit;
	onClose: () => void;
}

export function StudentLimitAlert({limit, onClose}: Props): JSX.Element {
	const {userType} = getUser();
	const modalManager = useModal();
	const closeModal = useCloseModal(modalManager, onClose);

	const [isShowAllowUseCCMessage, setShowAllowUseCCMessage] = useState(false);
	const [isShowRenewalURLMessage, setShowRenewalURLMessage] = useState(false);
	const [isShowStandardMassage, setShowStandardMassage] = useState(false);

	useEffect(() => {
		if ([UserType.T, UserType.ISD, UserType.ISS].includes(userType)) {
			if (!limit.allowUseCC) {
				return setShowAllowUseCCMessage(true);
			} else {
				return setShowRenewalURLMessage(true);
			}
		} else {
			return setShowStandardMassage(true);
		}
	}, [limit.allowUseCC]);

	const openPurchaseSlotsPage = () => {
		window.open(purchasingAdditionalSlotsUrl);
	};

	const openSupportPage = () => {
		window.open(supportedAddress);
	};

	return <Alert onCatchError={closeModal} modalManagerRef={modalManager}>
		<Alert.Body className={styles.alertBody}>
			{isShowAllowUseCCMessage && <div data-cy='allow-cc-text' className={styles.text}>
				<span>
					You have reached a maximum of {limit.max} students. Your district has disabled the ability to
					purchase additional students.
				</span>
				<span>
					For more information, please contact us at <Buttons.Link className={styles.link}
					                                                        onClick={openSupportPage}>{supportedAddress}</Buttons.Link>
				    with error ID ESGI-11499.
				</span>

			</div>}

			{isShowRenewalURLMessage && <div data-cy='renewal-text' className={styles.text}>
				<span>
					You have reached a maximum of {limit.max} students.
					You must remove a student from your class before you can add another one.
				</span>
				<span>
					To purchase additional students, please go to My Account, click RENEW and Add Students.
					<Buttons.Link className={styles.link} onClick={openPurchaseSlotsPage}>Read More</Buttons.Link>
				</span>
			</div>}

			{isShowStandardMassage && <div data-cy='standard-text'>
				You have reached a maximum number of {limit.max} students.
				You must remove a student from their class before you can add another one, or you can purchase
				additional
				students by going to <Buttons.Link className={styles.link}
				                                   onClick={openSupportPage}>{supportedAddress}</Buttons.Link>.
			</div>}

		</Alert.Body>
		<Alert.Footer>
			<Buttons.Link onClick={closeModal} className={styles.okButton}>OK</Buttons.Link>
		</Alert.Footer>
	</Alert>;
}
