import {BaseService} from '@esgi/core/service';
import {tap} from 'rxjs';

export class SsoLinksService extends BaseService {
	private cleverLinkActionInProcess = false;
	private classLinkActionInProcess = false;

	public unlinkClever = () => {
		if (this.cleverLinkActionInProcess) {
			return;
		}
		this.cleverLinkActionInProcess = true;
		return this.httpClient.SSO.post('Integrations/Clever', 'Unlink')
			.pipe(tap({complete: () => this.cleverLinkActionInProcess = false}));
	};

	public getClassLink = () => {
		if (this.classLinkActionInProcess) {
			return;
		}

		this.classLinkActionInProcess = true;
		return this.httpClient.SSO.get<{ url: string }>('integrations/class-link', 'get-link').pipe(tap({complete: () => this.classLinkActionInProcess = false}));
	};

	public unlinkClassLink = () => {
		if (this.classLinkActionInProcess) {
			return;
		}
		this.classLinkActionInProcess = true;
		return this.httpClient.SSO.post('integrations/class-link', 'unlink')
			.pipe(tap({complete: () => this.classLinkActionInProcess = false}));
	};
}
