import {createContext, useContext} from 'react';
import {noop} from 'underscore';
import {ToggleGroupOrientation} from './types';

export type ToggleGroupValueContextValue = {
	/**
	 * The orientation of the group.
	 * Mainly so arrow navigation is done accordingly (left & right vs. up & down)
	 */
	orientation: ToggleGroupOrientation | undefined;

	/** Description of selected item value  */
	valueDescriptionSelected: string | undefined;

	/** Set description of selected item value  */
	setValueDescriptionSelected: (value: string | undefined) => void;

	/** Description of item on hover  */
	valueDescriptionOnHover: string | undefined;

	/** Set description of hovered item value  */
	setValueDescriptionOnHover: (value: string | undefined) => void;

	/** Value of selected item  */
	selectedValue: string | undefined;

	/** Set selected item value  */
	setSelectedValue: (value: string | undefined) => void;

	/** Value of item on hover  */
	hoveredValue: string | undefined;

	/** Set item`s value on hover */
	setHoveredValue: (value: string | undefined) => void;
};

export const ToggleGroupContext = createContext<ToggleGroupValueContextValue>({
	orientation: undefined,
	valueDescriptionSelected: undefined,
	setValueDescriptionSelected: noop,
	valueDescriptionOnHover: undefined,
	setValueDescriptionOnHover: noop,
	selectedValue: undefined,
	setSelectedValue: noop,
	hoveredValue: undefined,
	setHoveredValue: noop,
});

export function useToggleGroupContext() {
	return useContext(ToggleGroupContext);
}
