import {ArrowButton} from '../../../kit/question-layout/arrow-button';
import {CloseButton} from '../../../kit/buttons/close-button';
import {
	QuestionBody,
	QuestionFooter,
	QuestionHeader,
	QuestionLayout,
	QuestionTitle,
} from '../../../kit/question-layout';
import {Text} from '../../../kit/question-layout/text';
import {useQuestionImage, TestIntoProps, testIntroStyles} from '../../../kit/test-intro';
import {Selectors} from '../../preview.selectors';

type Props = {onNext: () => void} & Omit<TestIntoProps, 'onStart' | 'onCancel'>;

export function TestIntro(props: Props): JSX.Element {
	const image = useQuestionImage(props.testIntroID, props.testID, props.pregenerated, props.modifyTicks);

	return <QuestionLayout whiteBackground={props.isWhiteBackground} data-cy={Selectors.testIntro}>
		<QuestionHeader>
			<QuestionTitle>
				<span>{props.testName}</span> - Introduction Page
			</QuestionTitle>
			<CloseButton isWhiteBackground={props.isWhiteBackground} onClick={props.onClose}/>
		</QuestionHeader>
		<QuestionBody>
			{image}
		</QuestionBody>
		<QuestionFooter>
			<ArrowButton direction='left'
			             tabIndex={4}
			             disabled/>
			<Text className={testIntroStyles.testIntroLabel}>
				TEST INTRO
			</Text>
			<ArrowButton direction='right'
			             data-cy={Selectors.nextButton}
			             tabIndex={5}
			             onClick={props.onNext}/>
		</QuestionFooter>
	</QuestionLayout>;
}
