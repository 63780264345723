import {useWritingPracticeService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgi/ui';
import {AlertBody, ReportWrapper, Page, Header, HeaderText, Student, FullNameText, MyNameText, StudentNameText, Content, Line} from './index.styled';
import {UnitType} from '../../../../types';
import {useUser, UserType} from '@esgi/core/authentication';

export function Body() {
	const service = useWritingPracticeService();
	const report = useBehaviorSubject(service.report$);
	const user = useUser();
	const isTeacher = user.userType === UserType.T;

	return (
		<AlertBody>
			<ReportWrapper>
				{report.students.map((student) => (
					<Page key={`${student.fullName}-${student.unitName}`}>
						<Header>
							<HeaderText size='medium' color='base' bold data-cy='writting-practice-header'>Writing Practice:</HeaderText>
							<HeaderText data-cy='date'>{new Date().toLocaleDateString('en-US')}</HeaderText>
							<HeaderText size='medium' color='base' bold data-cy='writting-practice-teacher-spec'>{isTeacher ? 'Teacher:' : 'Specialist:'}</HeaderText>
							<HeaderText data-cy='name'>{report.lecturerName}</HeaderText>
							{student.unitType !== UnitType.None &&
								<>
									<HeaderText size='medium' color='base' bold data-cy='writting-practice-class-group'>{student.unitType === UnitType.Class ? 'Class:' : 'Group:'}</HeaderText>
									<HeaderText data-cy='class-group-name'>{student.unitName}</HeaderText>
								</>
							}
						</Header>
						<Student>
							<FullNameText data-cy='student-full-name'>{student.fullName}</FullNameText>
							<MyNameText data-cy='my-name-is'>My Name Is</MyNameText>
							<StudentNameText style={{fontSize: student.titleNameFontSize}} data-cy='student-name'>
								{student.bodyName}
							</StudentNameText>
						</Student>
						<Content style={{fontSize: student.bodyFontSize}} dataCy='content'>
							{[...Array(student.linesCount)].map((_, i) => (
								<Line key={`template-item-${i}`}>{student.bodyName}</Line>
							))}
						</Content>
					</Page>
				))}
			</ReportWrapper>
		</AlertBody>
	);
}
