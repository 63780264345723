import {useRef} from 'react';
import {isEqual} from 'underscore';


export function useDeepMemo<T>(value: T): T {
	const valueRef = useRef(value);
	if (!isEqual(valueRef.current, value)) {
		valueRef.current = value;
	}

	return valueRef.current;
}