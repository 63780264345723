import './countdown.less';

export class Countdown {
	private static started: boolean = false;
	private static intervalID: number;

	static Start(remainingSeconds: number, callback: Function){
    	if(Countdown.started){
    		return;
    	}

    	Countdown.started = true;
        
    	const div = document.createElement('div');
    	div.id = 'countdown-modal';
    	div.innerHTML = '<div id="countdown-modal-content"></div>';
    	document.body.appendChild(div);

		let seconds = remainingSeconds;
		Countdown.intervalID = window.setInterval(() => {
			const el = document.getElementById('countdown-modal-content');
			el.innerHTML = Countdown.GetTimeString(seconds);
            
			if (seconds === 0){
				Countdown.Clear();

				if (callback) {
					callback();
				}
			}
            
			seconds--;
		}, 1000);
	}

	static Clear(){
		if (!Countdown.started){
			return;
		}

		Countdown.started = false;

		window.clearInterval(Countdown.intervalID);

		const el = document.getElementById('countdown-modal-content');
		el.remove();
	}

	static GetTimeString(seconds: number){
		const s = seconds % 60;
		const secStr = (s < 10 ? '0' : '') + s;
		const m = Math.floor(seconds / 60);
		const minStr = (m < 10 ? '0' : '') + m;
		return minStr + ':' + secStr;
	}
}