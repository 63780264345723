import {RubricResultReportService} from '../service';
import {SubjectTab, useClasses, useGroups} from '@esgi/main/libs/store';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {SelectableListItem} from '../service/types';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Test} from '@esgi/main/kits/reports';

export default function useReportState(service: RubricResultReportService) {
	const [{data: classes}] = useClasses();
	const [{data: groups}] = useGroups();
	const [selectedEntity, setSelectedEntity] = useState<SelectableListItem>(null);

	const onEntitySelect = useCallback(({classId, groupId}: SingleSelectState) => {
		let selected;

		if (classId) {
			selected = classes.find(({id}) => id === classId);
			service.setSelectedClassId(selected);
		} else {
			selected = groups.find(({id}) => id === groupId);
			service.setSelectedGroupId(selected);
		}

		setSelectedEntity({
			value: selected?.id.toString(),
			label: selected?.name,
			disabled: false,
		});
	}, [classes, groups]);

	const isSubjectsLoaded = useBehaviorSubject<boolean>(service.subjectService.loaded$);
	const isInitLoading = useBehaviorSubject<boolean>(service.loading$);
	const subjects = useBehaviorSubject<SubjectTab[]>(service.subjectService.subjects$);
	const selectedSubject = useBehaviorSubject<SubjectTab>(service.subject$);

	const tests = useBehaviorSubject<Test[]>(service.subjectTests$);
	const selectedTest = useBehaviorSubject<number>(service.selectedTest$);

	const [showAddTestsDrawer, setShowAddTestsDrawer] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);

	const setPreview = useCallback((show: boolean) => {
		return setShowPreview(show);
	}, []);

	const disabled = useMemo(() => {
		return isInitLoading || !selectedTest;
	}, [isInitLoading, selectedTest]);

	useEffect(() => {
		if (classes.length) {
			const notEmptyClass = classes.find(({studentIDs}) => studentIDs.length);
			setSelectedEntity({
				value: notEmptyClass.id.toString(),
				label: notEmptyClass.name,
				disabled: !notEmptyClass.studentIDs.length,
			});
		}
	}, [classes]);

	return {
		classes,
		groups,
		selectedEntity,
		subjects,
		isSubjectsLoaded,
		selectedSubject,
		showAddTestsDrawer,
		setShowAddTestsDrawer,
		showPreview,
		setPreview,
		selectedTest,
		tests,
		onEntitySelect,
		disabled,
	};
}
