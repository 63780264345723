import {FlexBox, GridBox} from '@esgi/ui/layout';
import React, {useCallback, useMemo} from 'react';
import {DragDropMode, Plus, Tooltip} from '@esgi/ui';
import {
	TestSectionMode,
	TestSectionGroupStudents,
	TestSectionSingleStudent,
	TestSectionContentType,
	ModeButtonsPermissions,
	SectionModeButtonItem,
	TestSectionDefault,
} from '../../types';
import {SectionModeButton} from './components/section-mode-button';
import {Divider, Header as HeaderBox} from './index.styled';
import {ButtonIconable} from './components/button-iconable.styled';
import {StudentInfo} from '../student-info';
import {GroupStudentsInfo} from '../group-students-info';
import {useTestSectionContext} from '../../context/test-section-context';
import {SubjectTab} from '@esgi/main/libs/store';
import {SubjectName} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';

type Props = {
	subject: SubjectTab | null;
	modeButtonsPermissions: ModeButtonsPermissions;
	onAddTestClick: VoidFunction;
	canView: boolean;
} & (TestSectionSingleStudent | TestSectionGroupStudents | TestSectionDefault);

export function Header({subject, modeButtonsPermissions, onAddTestClick, canView, ...props}: Props) {
	const {sectionMode, setSectionMode} = useTestSectionContext();

	const sectionModeButtons = useMemo(() => {
		const modeButtonsContent: SectionModeButtonItem[] = [
			{
				activateMode: TestSectionMode.Rearrange,
				Icon: DragDropMode,
				permitted: modeButtonsPermissions[TestSectionMode.Rearrange],
				dataCy: 'test-panel-action-menu-item-drag-and-drop',
				tooltip: 'Reorder tests',
			},
		];

		return modeButtonsContent;
	}, [modeButtonsPermissions]);

	const getBeforeSubjectContent = useCallback(() => {
		const {contentType} = props;

		if (contentType === TestSectionContentType.SingleStudent) {
			const {student, onStudentClick} = props;
			return <StudentInfo canView={canView} student={student} onStudentClick={onStudentClick} />;
		}

		if (contentType === TestSectionContentType.GroupStudents) {
			const {sectionName} = props;
			return <GroupStudentsInfo sectionName={sectionName} />;
		}
	}, [props]);

	return (
		<HeaderBox>
			<GridBox flow='column' gap='4' align='center'>
				{subject && (
					<>
						{getBeforeSubjectContent()}
						<Divider />

						<SubjectName dataCy='test-panel-header-subject-name' subjectLevel={subject.level} size='large' bold noWrap>
							{subject.name}
						</SubjectName>
					</>
				)}
			</GridBox>

			<GridBox flow='column' gap='4' align='center'>
				{sectionModeButtons.map(
					({activateMode, Icon, permitted, tooltip, dataCy}, index) => (
						<Tooltip key={index}>
							<Tooltip.Trigger>
								<SectionModeButton
									activateMode={activateMode}
									sectionMode={sectionMode}
									setSectionMode={setSectionMode}
									permitted={permitted}
									dataCy={dataCy}
								>
									<Icon />
								</SectionModeButton>
							</Tooltip.Trigger>
							{tooltip && (
								<Tooltip.Content variant='secondary'>
									<Text size='small'>{tooltip}</Text>
								</Tooltip.Content>
							)}
						</Tooltip>
					),
				)}

				<Tooltip>
					<Tooltip.Trigger>
						<ButtonIconable
							disabled={!modeButtonsPermissions[TestSectionMode.Add]}
							withBackgroundHover
							variant='filled'
							dataCy='test-panel-header-action-menu-button'
							onClick={onAddTestClick}
						>
							<Plus />
						</ButtonIconable>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>Add test to this subject tab</Text>
					</Tooltip.Content>
				</Tooltip>
			</GridBox>
		</HeaderBox>
	);
}
