import React from 'react';
import styles from './report-table.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {ReportTableLightBodyRow} from './report-table-light-body-row';
import {ReportTableDarkBodyRow} from './report-table-dark-body-row';
import {Column, FixedColumnPosition, HeaderCell, ReportTableProps} from './types';
import {ReportTableRoot} from './report-table-root';
import {ReportTableColumn} from './report-table-column';
import {ReportTableCell} from './report-table-cell';
import {ReportTableTD} from './report-table-td';
import {ReportTableTH} from './report-table-th';


export default class ReportTable<RowDataT> extends React.Component<ReportTableProps<RowDataT>> {
	renderHeaderColumn(column: Column<RowDataT>, nextColumn: Column<RowDataT>, headerCells: HeaderCell[], rowNumber: number) {
		const className = join(
			rowNumber % 2 === 0 ? styles.lightHeaderCell : styles.darkHeaderCell,
			column.fixedColumnPosition === FixedColumnPosition.Right ? styles.fixedRightColumn : null,
			nextColumn && nextColumn.fixedColumnPosition === FixedColumnPosition.Right ? styles.lastBeforeRightFixedColumn : null,
			column.className);

		if (!headerCells) {
			return <ReportTableColumn className={className}>
				<ReportTableCell></ReportTableCell>
			</ReportTableColumn>;
		}

		if (headerCells.length === 1) {
			const cell = headerCells[0];
			return <ReportTableColumn className={className}>
				<ReportTableCell className={cell.className}>{cell.renderer()}</ReportTableCell>
			</ReportTableColumn>;
		} else {
			return <ReportTableColumn className={className}>
				{headerCells.map((cell, i) => {
					return <ReportTableCell key={i} className={cell.className}>{cell.renderer()}</ReportTableCell>;
				})}
			</ReportTableColumn>;
		}
	}

	renderBodyColumn(column: Column<RowDataT>, nextColumn: Column<RowDataT>, row: RowDataT, rowNumber: number) {
		const tdClassName = rowNumber % 2 === 0 ? styles.light : styles.dark;
		const columnClassName = join(
			column.fixedColumnPosition === FixedColumnPosition.Right ? styles.fixedRightColumn : null,
			nextColumn && nextColumn.fixedColumnPosition === FixedColumnPosition.Right ? styles.lastBeforeRightFixedColumn : null);

		if (column.cells.length === 1) {
			const cell = column.cells[0];
			return <ReportTableTD fixedColumnPosition={column.fixedColumnPosition} className={tdClassName}>
				<ReportTableColumn className={join(column.className, columnClassName)}>
					<ReportTableCell className={cell.className}>{cell.renderer(row)}</ReportTableCell>
				</ReportTableColumn>
			</ReportTableTD>;
		} else {
			return <ReportTableTD fixedColumnPosition={column.fixedColumnPosition} className={tdClassName}>
				<ReportTableColumn className={join(column.className, columnClassName)}>
					{column.cells.map((cell, i) => {
						return <ReportTableCell key={i}
						                        className={cell.className}>{cell.renderer(row)}</ReportTableCell>;
					})}
				</ReportTableColumn>
			</ReportTableTD>;
		}
	}

	renderTable() {
		const columns = this.props.columns;

		return <table className={styles.table}>
			<thead>
			<tr>
				{columns.map((column, i) => {
					return <ReportTableTH key={`column_${i}`} fixedColumnPosition={column.fixedColumnPosition}>
						{column.headers.map((headerCells, j) => {
							return this.renderHeaderColumn(column, columns[i + 1], headerCells, j);
						})}
					</ReportTableTH>;
				})}
			</tr>
			</thead>
			<tbody>
			{this.props.rows.map((row, i) => {
				if (i % 2 === 0) {
					return <ReportTableLightBodyRow>
						{columns.map((column, j) => {
							return this.renderBodyColumn(column, columns[j + 1], row, i);
						})}
					</ReportTableLightBodyRow>;
				} else {
					return <ReportTableDarkBodyRow>
						{columns.map((column, j) => {
							return this.renderBodyColumn(column, columns[j + 1], row, i);
						})}
					</ReportTableDarkBodyRow>;
				}
			})}
			</tbody>
		</table>;
	}

	public render() {
		return <ReportTableRoot className={this.props.className}>
			{this.renderTable()}
		</ReportTableRoot>;
	}
}
