import {PropsWithChildren} from 'react';
import {InfoBlock} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {InfoBlockItem} from './index.styled';

export function CopiesPanel({children}: PropsWithChildren) {
	return (
		<InfoBlock>
			<InfoBlock.Group>
				<InfoBlockItem title='Pages to Print:'>
					<Text size='small' font='mono' bold data-cy='pages-to-print-amount'>{children}</Text>
				</InfoBlockItem>
			</InfoBlock.Group>
		</InfoBlock>
	);
}
