import {MutableRefObject, useCallback, useEffect} from 'react';
import {waitElementInDom} from '@esgi/deprecated/utils';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {UntestedStudentsReportService} from '../service';

interface Props {
	rightBottomPanelRef: MutableRefObject<HTMLDivElement>
	leftBottomPanelRef: MutableRefObject<HTMLDivElement>
	rightTopPanelRef: MutableRefObject<HTMLDivElement>
	rootElement: MutableRefObject<HTMLDivElement>
	service: UntestedStudentsReportService
	testNamesRef: MutableRefObject<HTMLTableRowElement>
	grTableShRef: MutableRefObject<HTMLTableRowElement>
	leftPanelRef: MutableRefObject<HTMLDivElement>
	leftTopPanelRef: MutableRefObject<HTMLDivElement>;
	firstHeaderRef: MutableRefObject<HTMLTableRowElement>;
}

export function useUntestedReportEffects({
	                                         rightBottomPanelRef,
	                                         rightTopPanelRef,
	                                         leftBottomPanelRef,
	                                         rootElement,
	                                         service,
	                                         testNamesRef,
	                                         grTableShRef,
	                                         leftPanelRef,
	                                         leftTopPanelRef, firstHeaderRef,
}: Props) {
	const emptyReport = useBehaviorSubject(service.emptyReport);
	const startTop = {x: 0, y: 0, scrollLeft: 0};
	const touchStartTop = (event) => {

		startTop.x = event.touches[0].pageX;
		startTop.y = event.touches[0].pageY;

		startTop.scrollLeft = rightTopPanelRef.current.scrollLeft;
	};

	const touchMoveTop = (event) => {

		const offset = {x: 0, y: 0};

		offset.x = startTop.x - event.touches[0].pageX;
		offset.y = startTop.y - event.touches[0].pageY;

		if (Math.abs(offset.y) < Math.abs(offset.x)) {
			event.preventDefault();
		}

		if (offset.x) {
			const x = startTop.scrollLeft + (offset.x);
			rightTopPanelRef.current.scrollLeft = x;
			rightBottomPanelRef.current.scrollLeft = x;
		}
	};

	const start = {x: 0, y: 0, scrollTop: 0};
	const touchStart = (event) => {
		start.x = event.touches[0].pageX;
		start.y = event.touches[0].pageY;
		start.scrollTop = leftBottomPanelRef.current.scrollTop;

	};

	const touchMove = (event) => {

		const offset = {x: 0, y: 0};

		offset.x = start.x - event.touches[0].pageX;
		offset.y = start.y - event.touches[0].pageY;

		if (Math.abs(offset.x) < Math.abs(offset.y)) {
			event.preventDefault();
		}

		if (offset.y) {
			const y = start.scrollTop + (offset.y);
			leftBottomPanelRef.current.scrollTop = y;
			rightBottomPanelRef.current.scrollTop = y;
		}
	};

	const onRightBottomPanelScroll = useCallback(() => {
		leftBottomPanelRef.current.scrollTo({top: rightBottomPanelRef.current.scrollTop});
		rightTopPanelRef.current.scrollTo({left: rightBottomPanelRef.current.scrollLeft});
	}, [leftBottomPanelRef.current, rightTopPanelRef.current]);

	const onLeftBottomPanelMousewheel = useCallback(({deltaY}) => {
		if (deltaY) {
			const scrollTop = leftBottomPanelRef.current.scrollTop;
			const y = scrollTop - (deltaY * 171);
			rightBottomPanelRef.current.scrollTo({top: y});
		}
	}, []);

	const fixHeadWidth = () => {
		const head = grTableShRef.current?.[0];
		const length = head.querySelectorAll('td').length;

		for (let i = 0; i < length - 1; i++) {
			head.querySelector('td:eq(' + i + ')').clientWidth = leftBottomPanelRef.current.querySelector('table' +
				' tr:eq(0) td:eq(' + i + ')');
		}
	};

	const onLeftPanelScroll = () => {
		const left = leftPanelRef.current?.scrollLeft;
		const div = leftBottomPanelRef.current?.querySelector('> div');
		const dif = div.clientWidth - leftBottomPanelRef.current?.clientWidth;

		if (left > dif) {
			return;
		}

		leftBottomPanelRef.current.style.left = left + 'px';
		rightBottomPanelRef.current.scrollTop = leftBottomPanelRef.current.scrollTop;
	};

	const synchronizeTable = () => {

		waitElementInDom(rootElement[0], () => {
			fixHeadWidth();
		});
	};

	const fixTestNames = useCallback(() => {
		leftTopPanelRef.current.style.height = 'auto';
		firstHeaderRef.current.style.height = 'auto';
		testNamesRef.current.style.height = 'auto';
		const height = testNamesRef.current?.clientHeight;
		if (leftTopPanelRef.current?.clientHeight > height) {
			testNamesRef.current.style.height = leftTopPanelRef.current?.clientHeight + 'px';
		} else {
			firstHeaderRef.current.style.height = `${testNamesRef.current.clientHeight - grTableShRef.current.clientHeight - 1}px`;
		}
	}, [leftBottomPanelRef.current, firstHeaderRef.current, testNamesRef.current, leftTopPanelRef.current?.clientHeight, grTableShRef.current]);


	useEffect(() => {
		rightBottomPanelRef.current?.addEventListener('scroll', onRightBottomPanelScroll);
		leftBottomPanelRef.current?.addEventListener('wheel', onLeftBottomPanelMousewheel);

		leftBottomPanelRef.current?.[0]?.addEventListener('touchstart', touchStart, false);
		leftBottomPanelRef.current?.[0]?.addEventListener('touchmove', touchMove, false);

		rightTopPanelRef.current?.[0]?.addEventListener('touchstart', touchStartTop, false);
		rightTopPanelRef.current?.[0]?.addEventListener('touchmove', touchMoveTop, false);

		leftPanelRef.current?.addEventListener('scroll', onLeftPanelScroll);
		return () => {
			rightBottomPanelRef.current?.removeEventListener('scroll', onRightBottomPanelScroll);
			// @ts-ignore
			leftBottomPanelRef.current?.removeEventListener('scroll', onLeftBottomPanelMousewheel);
			leftBottomPanelRef.current?.[0]?.removeEventListener('touchstart', touchStart, false);
			leftBottomPanelRef.current?.[0]?.removeEventListener('touchmove', touchMove, false);
			rightTopPanelRef.current?.[0]?.removeEventListener('touchstart', touchStartTop, false);
			rightTopPanelRef.current?.[0]?.removeEventListener('touchmove', touchMoveTop, false);
			leftPanelRef.current?.removeEventListener('scroll', onLeftPanelScroll);
		};
	}, []);

	useEffect(() => {
		synchronizeTable();
		setTimeout(() => {
			if (!emptyReport) {
				fixTestNames();
			}
		}, 110);
	});

	useEffect(() => {
		if (leftTopPanelRef.current) {
			leftTopPanelRef.current.style.height = testNamesRef.current.clientHeight + 'px';
		}
	}, [leftBottomPanelRef.current, testNamesRef.current]);

}
