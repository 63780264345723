import {Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button} from './index.styled';

type Props = {
	onClick: VoidFunction;
};

export function AddMargingPeriodButton({onClick}: Props) {
	return (
		<Button onClick={onClick}>
			<Plus />
			<Text size='medium' bold>
				Add Marking Period
			</Text>
		</Button>
	);
}
