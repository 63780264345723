import {Provider, Root} from '@radix-ui/react-toast';
import {BaseComponentProps} from 'libs/ui/src/types';
import {Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Content, WrapperBox, Progress, SnackbarCloseButton, SnackbarViewport} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {useSpring, animated, config, useTransition, useSpringRef, useChain} from 'react-spring';
import useResizeObserver from '@react-hook/resize-observer';
import {SnackbarOptions, SnackbarManager} from '../../types';
import {Property} from '@stitches/react/types/css';

type SnackbarRootProps = BaseComponentProps & {
	/**
	 * Display close icon button at the right.
	 * @default true
	 */
	withCloseIcon?: boolean;

	/**
	 * Mandatory `Ref` to interact with the Snackbar component programmatically.
	 */
	snackbarRef: Ref<SnackbarManager | undefined>;

	/**
	 * Called when a close icon button is clicked.
	 */
	onCloseIconClick?: VoidFunction;

	/**
	 *  An optional callback function to be executed after the snackbar is closed.
	 */
	onClose?: VoidFunction;

	/**
	 * CSS property sets the z-order of a positioned element.
	 * @default 800
	 */
	zIndex?: Property.ZIndex;
};

export const Snackbar = forwardRef<HTMLLIElement, SnackbarRootProps>(
	(
		{dataCy = 'ui-kit-snackbar', withCloseIcon = true, css = {}, snackbarRef, onCloseIconClick, onClose, zIndex = 800},
		forwardedRef,
	) => {
		const [snackbarWidth, setSnackbarWidth] = useState(0);
		const [open, setOpen] = useState(false);

		const [options, setOptions] = useState<SnackbarOptions | null>(null);

		const [contentElement, setContentElement] = useState<React.JSX.Element | null>(null);
		const [contentText, setContentText] = useState<string | null>(null);

		const dismissTimerId = useRef<number>();
		const snackbarViewportRef = useRef<HTMLOListElement>(null);

		useResizeObserver(snackbarViewportRef, (entry) => setSnackbarWidth(entry.contentRect.width));

		const clearTimer = useCallback(() => {
			if (dismissTimerId.current) {
				clearInterval(dismissTimerId.current);
			}
		}, []);

		useEffect(() => {
			return clearTimer;
		}, []);

		const resetStates = useCallback(() => {
			setOpen(false);
			setOptions(null);
			setContentElement(null);
			setContentText(null);

			onClose?.();
		}, [onClose]);

		const slideTransitionRef = useSpringRef();
		const progressValueRef = useSpringRef();

		const slideTransition = useTransition(open, {
			ref: slideTransitionRef,
			from: {opacity: 0, transform: 'translateY(100%)'},
			enter: {opacity: 1, transform: 'translateY(0%)'},
			leave: {opacity: 0, transform: 'translateY(100%)'},
			config: {
				...config.gentle,
				clamp: true,
			},
		});

		const progressValueStyles = useSpring({
			ref: progressValueRef,
			width: open ? snackbarWidth : 0,
			height: '100%',
			background: 'rgba(31, 31, 31, 0.88)',
			from: {
				width: 0,
			},
			onRest: resetStates,
			reset: !open,
			config: {
				duration: options?.duration,
			},
		});

		useChain(open ? [slideTransitionRef, progressValueRef] : [progressValueRef, slideTransitionRef]);

		const showSnackbar = useCallback(
			(content: string | React.JSX.Element, options?: Partial<SnackbarOptions>) => {
				const duration = options?.duration ?? 2000;

				resetStates();
				clearTimer();

				dismissTimerId.current = window.setTimeout(() => {
					typeof content === 'string' ? setContentText(content) : setContentElement(content);

					setOptions({duration});
					setOpen(true);
				}, 100);
			},
			[resetStates, clearTimer],
		);

		const closeSnackbar = useCallback(
			(cb?: VoidFunction) => {
				resetStates();
				cb?.();
			},
			[resetStates],
		);

		useImperativeHandle(snackbarRef, () => ({
			showSnackbar,
			closeSnackbar,
		}));

		return (
			<Provider swipeDirection='right'>
				<Root data-cy={`${dataCy}-item`} open={open} duration={options?.duration} ref={forwardedRef} forceMount asChild>
					{slideTransition((styles, item) => {
						if (!item) {
							return null;
						}

						return (
							<WrapperBox style={styles}>
								<Progress dataCy={`${dataCy}-progress`}>
									<animated.div style={progressValueStyles} />
								</Progress>
								<Content>
									{contentText ? <Text size='large'>{contentText}</Text> : contentElement}
									{withCloseIcon && (contentText || contentElement) && (
										<SnackbarCloseButton onClick={onCloseIconClick ?? resetStates} withBackgroundHover />
									)}
								</Content>
							</WrapperBox>
						);
					})}
				</Root>
				<SnackbarViewport data-cy={dataCy} css={{...css, zIndex}} ref={snackbarViewportRef} />
			</Provider>
		);
	},
);
