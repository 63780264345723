import {join} from '@esgillc/ui-kit/utils';
import {StudentProfileMode} from 'modules/forms/students-form/types';
import styles from './styles.module.less';
import {StudentIcon, CameraIcon} from '@esgillc/ui-kit/icons';

interface Props {
	mode: StudentProfileMode;
	image?: string;
	onClick?: () => void;
}

export function Avatar({mode, image, onClick}: Props) {
	if ([StudentProfileMode.add, StudentProfileMode.view].includes(mode)) {
		return (
			<div
				data-cy='view-avatar'
				className={styles.avatar}
				style={{backgroundImage: image ? `url(${image})` : null}}
			>
				{!image && <StudentIcon/>}
			</div>
		);
	}

	return (
		<div
			data-cy='edit-avatar'
			className={join(styles.avatar, styles.pointer, image && styles.filled)}
			style={{backgroundImage: image ? `url(${image})` : null}}
			onClick={onClick}
		>
			<CameraIcon/>
			<label data-cy='img-label'>
				{image ? 'Edit Photo' : 'Add Photo'}
			</label>
			<div data-cy='img-container'></div>
		</div>
	);
}
