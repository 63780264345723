import {useState} from 'react';
import {Checkbox, Radio} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Buttons} from '@esgillc/ui-kit/button';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {ReportShowStudentID} from '../../types';
import {SectionWrapper} from '../../../components/section-wrapper';
import {RightArrowIcon} from '../../../components/icons/right-arrow-icon';
import ParentLetterEditor from '../parent-letter-editor';
import styles from './styles.module.less';

interface Props {
	showStudentID: ReportShowStudentID;
	onShowStudentID: (value: ReportShowStudentID) => void;
}

export function ReportsSection(props: Props): JSX.Element {
	const {showStudentID, onShowStudentID} = props;
	const [isParentLetterEditorOpened, setParentLetterEditorOpened] = useState(false);

	const checkboxClicked = () => onShowStudentID(showStudentID === ReportShowStudentID.None ? ReportShowStudentID.StudentID : ReportShowStudentID.None);

	return (
		<SectionWrapper title={'REPORTS'} className={styles.reportsSectionHeader}>
			<div
				data-cy={'reports-section'}
				className={styles.reportSection}
			>
				<div
					data-cy={'show-student-id'}
					className={styles.showStudentID}
				>
					<Checkbox
						className={styles.checkbox}
						checked={showStudentID !== ReportShowStudentID.None}
						onChange={checkboxClicked}
					>
						Show Student ID
					</Checkbox>
				</div>

				<div
					data-cy={'radio-buttons'}
					className={styles.radioInputsRow}
				>
					<Radio
						value={ReportShowStudentID.StudentID}
						disabled={showStudentID === ReportShowStudentID.None}
						checked={showStudentID === ReportShowStudentID.StudentID}
						onClick={() => onShowStudentID(ReportShowStudentID.StudentID)}
					>
						Student ID
					</Radio>

					<Radio
						value={ReportShowStudentID.ExportID}
						disabled={showStudentID === ReportShowStudentID.None}
						checked={showStudentID === ReportShowStudentID.ExportID}
						onClick={() => onShowStudentID(ReportShowStudentID.ExportID)}
					>
						Export ID
					</Radio>

					<OnHoverTooltip message='Choose Export ID if you are an importing district that imports via Clever.'>
						<QuestionMarkIcon className={styles.questionMark}/>
					</OnHoverTooltip>
				</div>

				<hr className={styles.line}/>

				<div
					className={styles.customizeParentLetterRow}
					onClick={() => setParentLetterEditorOpened(true)}
				>
					<Buttons.Link className={styles.customizeParentLetterButton}>Customize Parent Letter</Buttons.Link>
					<RightArrowIcon/>
				</div>

				{isParentLetterEditorOpened &&
					<div data-cy={'parent-letter-editor'}>
						<ParentLetterEditor onExit={() => setParentLetterEditorOpened(false)}/>
					</div>
				}
			</div>
		</SectionWrapper>
	);
}
