import * as React from 'react';
import type {SVGProps} from 'react';

export function Italic(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M14 9a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-2.246l-3.428 12H17a1 1 0 1 1 0 2h-6a1 1 0 1 1 0-2h2.246l3.428-12H15a1 1 0 0 1-1-1Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
