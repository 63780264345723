import {SelectableList} from '@esgi/ui/layout';
import {ExpandableRearrangeableItem} from './components/components.styled';
import {IdableItem} from '../../types';
import {expandableListsGap} from './constants';
import {GroupItemContent} from './components/group-item-content';

export type RearrangeItem = IdableItem & {
	groupName: string;
	groupItemsCount?: number;
};

type Props<Item extends RearrangeItem> = {
	dataCy?: string;
	droppableItems: Item[];
	onDragEnd: (groupState: Item[]) => void;
};

export function Rearrange<Item extends RearrangeItem>({dataCy, onDragEnd, droppableItems}: Props<Item>) {
	return (
		<SelectableList dataCy={dataCy}>
			<SelectableList.GroupRoot>
				<SelectableList.GroupDroppable
					onDragEnd={onDragEnd}
					droppableItems={droppableItems}
					css={{
						[`& > li:not(:last-child)`]: {
							marginBottom: expandableListsGap,
						},

						[`& > li:last-child`]: {
							borderBottomColor: '$border',
						},
					}}
				>
					{droppableItems.map((item, index) => (
						<ExpandableRearrangeableItem
							value={String(item.id)}
							index={index}
							draggableId={String(item.id)}
							key={item.id}
						>
							<GroupItemContent itemsCount={item.groupItemsCount} label={item.groupName} />
						</ExpandableRearrangeableItem>
					))}
				</SelectableList.GroupDroppable>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
