import {HeaderProps, Modal} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import styles from './report-modal.module.less';

export const ReportModalHeader = ({className, ...props}: HeaderProps) => (
	<Modal.Header
		className={join(styles.header, className)}
		{...props}
	/>
);
