import styles from './styles.module.less';
import {TableLevelReportService} from '../../../../../../services/table-level-report-service';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {useStream} from '@esgillc/ui-kit/utils';
import {ReportFiltersLabelContainer, ReportFiltersContent} from '../../../../../report-filter';
import {ReportFiltersContainer, ReportFiltersLabel} from '@esgi/deprecated/ui-kit/report/filters';

type LevelsLegendProps = {
	service: TableLevelReportService;
};

export function LevelsLegend({service}: LevelsLegendProps) {
	const levels = useStream(service.levels$);

	const levelItems = levels?.map(({name, score}, index) => {
		const spacesOrNumbersRegex = /^(\s*|\d+)$/;

		const isNumber = spacesOrNumbersRegex.test(name);
		const key = `${name}-${score}-${index}`;

		return isNumber ? (
			<div key={key} className={styles.item} data-cy='table-level-report-level-legend'>
				{score}
			</div>
		) : (
			<OnHoverTooltip key={key} message={name}>
				<div className={styles.item} data-cy='table-level-report-level-legend-tooltip'>
					{score}
				</div>
			</OnHoverTooltip>
		);
	});

	return (
		<ReportFiltersContainer className={styles.cell}>
			<ReportFiltersLabelContainer>
				<ReportFiltersLabel>Results:</ReportFiltersLabel>
				<div className={styles.smallLabel}>Highest to Lowest</div>
			</ReportFiltersLabelContainer>
			<ReportFiltersContent>{levelItems}</ReportFiltersContent>
		</ReportFiltersContainer>
	);
}
