import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {AddButton, InfoButton, Layout} from './index.styled';
import {Assignment, Button, AccessExport, Plus, Tooltip, Info} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {TextWithIcon} from '@esgi/main/kits/assignments';
import {Student} from '@esgi/main/libs/store';

type Props = {
	loaded: boolean;
	onExportClick: VoidFunction;
	onAddClick: VoidFunction;
	onOpenIntroductionAlert: VoidFunction;
	students: Student[];
};

export function Header({loaded, onAddClick, onExportClick, students, onOpenIntroductionAlert}: Props) {
	const user = useUser();

	return (
		<Layout>
			<GridBox align='center' gap='1' flow='column'>
				<TextWithIcon data-cy='assignments-title' size='small' color='neutral56' Icon={Assignment}>
					Assignments
				</TextWithIcon>
				{!!user?.districtID && (
					<Tooltip>
						<Tooltip.Trigger>
							<Text data-cy='district-id' size='small' color='secondary'>
								District ID: {user.districtID}
							</Text>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<Text size='small'>District ID is needed for student access</Text>
						</Tooltip.Content>
					</Tooltip>

				)}
			</GridBox>
			<FlexBox align='center'>
				<Tooltip>
					<Tooltip.Trigger>
						<InfoButton
							withBackgroundHover
							onClick={onOpenIntroductionAlert}
							dataCy='student-info-icon'
						>
							<Info />
						</InfoButton>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>How to create an assignment</Text>
					</Tooltip.Content>
				</Tooltip>
				<Button.Icon dataCy='print-students-credentials-button' disabled={!loaded || !students.length} onClick={onExportClick}>
					<AccessExport />
				</Button.Icon>
				<Tooltip>
					<Tooltip.Trigger>
						<AddButton data-cy='add-button' color='secondary' disabled={!loaded} onClick={onAddClick}>
							<Plus />
						</AddButton>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>Create Assignment</Text>
					</Tooltip.Content>
				</Tooltip>

			</FlexBox>
		</Layout>
	);
}
