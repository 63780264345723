import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const StyledOverlayScrollbars = styled(OverlayScrollbarsComponent, {
	height: '100%',
	width: '100%',
	paddingBottom: 24,
	overflowY: 'scroll',
});

export const StyledRowContainer = styled(Box, {
	position: 'relative',
	width: '100%',
});


export const NoDataWrapper = styled(FlexBox, {
	width: '100%',
	height: '100%',
	alignContent: 'center',
	alignItems: 'center',
	justifyContent: 'center',
});