import React from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {AddIcon} from '../../level/components/icons';
import styles from '../criteria.module.less';

export default class AddCriteria extends React.PureComponent<{onClick: () => void, className?: string}> {
	public render() {
		return <div className={styles.addCriteriaBox}>
			<div className={join(styles.innerBox, this.props.className)}>
				<div className={styles.criteria} onClick={() => this.props.onClick()}>
					<div>
						<AddIcon/>
						<span>Add Criteria</span>
					</div>
				</div>
			</div>
		</div>;
	}
}
