import * as React from 'react';
import type {SVGProps} from 'react';

export function Link(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M18 11c-1.421 0-2.578.402-3.438 1.156C13.508 13.081 13 14.451 13 16v1a1 1 0 0 0 2 0v-1c0-1.017.301-1.84.875-2.344C16.35 13.239 17.043 13 18 13h3c2.07 0 3 1.116 3 3s-.93 3-3 3h-1a1 1 0 0 0 0 2h1c3.263 0 5-2.084 5-5s-1.737-5-5-5h-3Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M11 11c-2.916 0-5 1.737-5 5s2.084 5 5 5h3c2.916 0 5-1.737 5-5v-1c0-1.333-2-1.333-2 0v1.063C16.975 18.083 15.863 19 14 19h-3c-1.884 0-3-.93-3-3s1.116-3 3-3h1a1 1 0 0 0 0-2h-1Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
