import {CounterBox as CounterBoxUI} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const StyledCounterBox = styled(CounterBoxUI, {
	transition: 'border-сolor .3s',

	[`& > ${Text}`]: {
		transition: 'сolor .3s',
	},

	variants: {
		colorVariant: {
			neutral: {
				borderColor: '$mild',
				color: '$neutral56',
			},

			primary: {
				borderColor: '$primaryMuted',
				color: '$primaryVivid',
			},
		},
	},
});
