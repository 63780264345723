import {SelectableList as SelectableListUi} from '@esgi/ui/layout';
import {SubjectTab} from '@esgi/main/libs/store';
import {isNull} from 'underscore';
import {SubjectName} from '@esgi/main/kits/common';

type Props = {
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: (subjectId: SubjectTab['id']) => void;
	subjects: SubjectTab[];
};

export function SelectableList({selectedSubjectId, setSelectedSubjectId, subjects}: Props) {
	return (
		<SelectableListUi>
			<SelectableListUi.GroupRoot type='single' value={isNull(selectedSubjectId) ? '' : String(selectedSubjectId)}>
				<SelectableListUi.Group>
					{subjects.map(({id, name, level}) => (
						<SelectableListUi.Item
							value={String(id)}
							key={id}
							forceSelect={selectedSubjectId !== id}
							onClick={() => setSelectedSubjectId(id)}
						>
							<SubjectName subjectLevel={level} size='medium' bold>
								{name}
							</SubjectName>
						</SelectableListUi.Item>
					))}
				</SelectableListUi.Group>
			</SelectableListUi.GroupRoot>
		</SelectableListUi>
	);
}
