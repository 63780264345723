import {resolvedPromise} from '@esgi/deprecated/utils';
import {ServerClient} from 'pages/landing/kit/server-client';
import {Form, FormField, Validators} from '@esgi/deprecated/knockout';
import {StripeMode, StripeUtil} from '../stripe';
import {PurchaseFormTemplate} from './template';
import {CheckPromoCodeResponse} from './types';

import './purchase-form.less';

export class PurchaseFormModel {
	promoCode: string;

	cardNumber: string;
	nameOnCard: string;
	creditCardExpirationDate: string;
	cvc: string;
	months: string;
}

export class PurchaseForm extends Form {
	constructor() {
		super();

		this.price = this.createPrice();
		this.promoCode = this.createPromoCode();

		this.month = this.createMonth();
		this.startDate = this.createStartDate();
		this.endDate = this.createEndDate();

		this.cardNumber = this.createCardField(StripeUtil.ElementNameCardNumber);
		this.cardExpiry = this.createCardField(StripeUtil.ElementNameCardExpiry);
		this.cardCvc = this.createCardField(StripeUtil.ElementNameCardCvc);
		this.address = this.createCardField(StripeUtil.ElementNameAddress);

		this.billingZip = this.createBillingZip();
	}

	private readonly EmptyStateValidationMessage = 'Required';
	private readonly ValidStateValidationMessage = '';

	template = () => PurchaseFormTemplate.render();

	allowPayPal: boolean;
	allowUsePromoCode: boolean;
	showOrderOverview: boolean;

	months = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];

	price: FormField;
	promoCode: FormField<string>;

	month: FormField;
	startDate: FormField;
	endDate: FormField;

	_startDate = ko.observable<Date>();
	_endDate: KnockoutComputed<Date>;

	stripeUtil = new StripeUtil();
	stripeElementState = [];
	cardNumber: FormField;
	cardExpiry: FormField;
	cardCvc: FormField;
	address: FormField;
	billingZip: FormField;

	serialize(): PurchaseFormModel {
		let m = new PurchaseFormModel();

		m.promoCode = this.promoCode.value();
		m.months = this.month.value();
		m.cardNumber = StripeUtil.StripeCardNumberId;

		return m;
	}

	private createCardField = (elementType: string) => {
		let validator = Validators.Custom(_ => {
			return resolvedPromise(this.stripeElementState[elementType] === '');
		}, _ => this.stripeElementState[elementType]);

		const f = this.createField(this.ValidStateValidationMessage, validator);
		f.validation.showValidation(true);
		f.validation.errorValidation(true);
		f.validation.successValidation(true);
		f.validation.errorPosition = this.GetElementTooltipPosition(elementType);
		f.validation.validationMessageTitleUseHtml = true;

		return f;
	};

	private GetElementTooltipPosition = (elementType: string) => {
		switch (elementType) {
			case StripeUtil.ElementNameCardCvc:
				return 'right';
			default:
				return 'left';
		}
	};

	private createBillingZip = () => {
		const f = this.createField('zip', Validators.Required());
		f.validation.showValidation(true);
		f.validation.errorValidation(true);
		f.validation.successValidation(true);
		return f;
	};

	private createPromoCode() {
		const promoCodeValidator = Validators.Ajax(f => this.server.checkPromoCode(f.value()).done((resp) => {
					f.validation.successValidation(true);
					f.validation.errorValidation(true);
				})
				.always(() => {
					f.validation.validationInProgress(false);
				}),
			v => '');

		let pcField = this.createField('', promoCodeValidator);

		pcField.value.subscribe((val) => {
			this.calculatePrice();
		});

		pcField.validation.showValidation(true);
		pcField.validation.successValidation(true);
		pcField.validation.errorValidation(true);

		return pcField;

	}

	private createMonth() {
		let month = new FormField();
		month.value.subscribe((value) => {
			this.calculatePrice();
		});

		return month;
	}

	private createEndDate() {
		let endDate = new FormField();
		this._endDate = ko.computed(() => {
			let date = new Date((<any>this._startDate()));
			let month = parseInt(this.month.value() as string);

			if (!isNaN(month)) {
				date.setMonth(date.getMonth() + month);
			}

			return new Date(date as any);
		});
		endDate.value = ko.computed(() => {
			let endDate = this._endDate();
			return (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear();
		});

		return endDate;
	}

	private createStartDate() {
		//todo GET Max from TodayDate and PromoCodeDate and then add months
		let startDate = new FormField();
		this._startDate(new Date());
		startDate.value = ko.computed(() => {
			let date = this._startDate();
			return (date.getMonth() + 1) +
				'/' +
				date.getDate() +
				'/' +
				date.getFullYear();
		});
		return startDate;
	}

	createPrice() {
		return new FormField();
	}


	afterRender(rootElement: JQuery) {
		$(this).trigger('paypalButtonInit');
		setTimeout(() => {
			this.stripeUtil.StripeInit(StripeMode.MultipleFields).done(() => {
				this.createElement('cardNumber', this.stripeUtil.CardNumber, '#stripe-card-number', this.getEmptyStateValidationMessageText('cardNumber'));
				this.createElement('cardExpiry', this.stripeUtil.CardExpiry, '#stripe-card-expiry', this.getEmptyStateValidationMessageText('cardExpiry'));
				this.createElement('cardCvc', this.stripeUtil.CardCvc, '#stripe-card-cvc', this.getEmptyStateValidationMessageText('cardCvc'));
				this.createElement('address', this.stripeUtil.Address, '#stripe-address', this.getEmptyStateValidationMessageText('address'));
			});
		}, 100);

		return super.afterRender(rootElement);
	}

	server = {
		getPrice: (months, promoCode): JQueryPromise<{price: number}> => {
			return ServerClient.SSOAPI.Get<{price: number}>('Registration/Pricing', 'Get', {
				data: {
					months: months,
					promoCode: promoCode,
				},
			});
		},

		checkPromoCode: (code): JQueryPromise<CheckPromoCodeResponse> => {
			return ServerClient.SSOAPI.Get<CheckPromoCodeResponse>('Registration/Validations', 'PromoCode', {data: {promoCode: code}});
		},
	};
	view =
		{
			showPromoCodeClicked: () => {
				this.promoCode.visible(true);
			},
		};

	events =
		{
			paypalButtonInit: (callback: (containerSelector: string) => void) => {
				$(this).on('paypalButtonInit', () => callback('.paypal-button-container'));
			},
		};

	calculatePrice() {

		if (!this.month.value()) {
			this.price.value('');
			return;
		}

		this.server.getPrice(this.month.value(), this.promoCode.value()).done((price) => {
			this.price.value('$' + price.price);
		}).fail(() => {
			this.price.value('');
		});
	}

	private createElement(formElement: string, element: any, elementSelector: string, emptyStateValidationMessage: string = this.EmptyStateValidationMessage) {
		this[formElement].value('');
		this.stripeElementState[formElement] = emptyStateValidationMessage;

		element.mount(elementSelector);
		element.on('change', (event) => {
			this.processEvent(event);
		});
	}

	private processEvent(event: any) {
		let type = event.elementType;
		if (event.error) {
			this.stripeElementState[type] = event.error.message;
			this.setDummyFieldValue(type, 'error');
		} else if (event.complete) {
			this.stripeElementState[type] = this.ValidStateValidationMessage;
			this.setDummyFieldValue(type, 'complete');
		} else if (event.empty) {
			this.stripeElementState[type] = this.getEmptyStateValidationMessageText(type);
			this.setDummyFieldValue(type, '');
		}
	}

	private setDummyFieldValue(elementType: string, fieldValue: string) {
		let fieldName = elementType == StripeUtil.ElementNameCard ? StripeUtil.ElementNameCardNumber : elementType;
		this[fieldName].value(fieldValue);
	}

	private getEmptyStateValidationMessageText(elementType: string): string {
		switch (elementType) {
			case 'cardNumber':
				return '<div>Please enter your credit card number.</div>';

			case 'cardExpiry':
				return '<div>Please enter your card expiration date.</div>';

			case 'cardCvc':
				return '<div>Please enter the 3-4 digit CVC number</div><div>found on the back of your credit card.</div>';

			default:
				return this.EmptyStateValidationMessage;
		}
	}
}
