import {computed, observable} from '@esgi/deprecated/knockout';
import moment, {Moment} from 'moment';
import {OrderConfirmationTemplate} from '../html/order-confirmation-template';
import {CartModel} from '../models';
import {ProductName, ProductPaymentModel} from '../server';
import {Step} from './steps';

export class OrderConfirmationStepProductPaymentModel extends ProductPaymentModel {

	public NewExpDate: Moment;

	constructor(m: ProductPaymentModel) {
		super();
		this.durationMonths = m.durationMonths;
		this.newExpirationDate = m.newExpirationDate;
		this.price = m.price;
		this.quantity = m.quantity;
		this.title = ProductName[m.title];

		this.NewExpDate = moment(this.newExpirationDate);
	}
}

export class OrderConfirmationStep extends Step {

	constructor() {
		super();
		this.title = 'Order Confirmation';
	}

	template = OrderConfirmationTemplate;

	cart: CartModel;

	Payments: OrderConfirmationStepProductPaymentModel[];
	continueText: string;

	@observable()
	transactionId: string;

	@observable()
	paymentID: number;

	@observable()
	orderId: number;

	@observable()
	newStudentLimit: number;

	@computed()
	get isAnyProductSelected() {
		return this.cart.expiration.products.some(x => x.selected());
	}

	view = {
		continue: () => {
			$(this).trigger('continueClicked');
		},
		downloadReceipt: () => {
			$(this).trigger('downloadReceiptClicked');
		},
	};

	events = {
		continueClicked: (callback) => {
			$(this).on('continueClicked', callback);
		},
		downloadReceiptClicked: (callback) => {
			$(this).on('downloadReceiptClicked', callback);
		},
	};
}
