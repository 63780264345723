import {BehaviorSubject, Observable} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {
	CreateResponse,
	FormModel,
	InitResponse,
	LanguageModel,
	LetterTemplate,
	LetterTemplateModel,
	RevertResponse,
} from './models';
import {Language} from '../models';
import {deepCopy} from 'shared/utils';
import {SubjectType} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {ParentLetterTemplateActions, ParentLetterTemplateApiHost} from '../constants';

export class Service extends BaseService {
	private controller: string = 'reports/parent-letter/template';
	private requestInProgress: boolean;

	private selectedLanguage: BehaviorSubject<LanguageModel> = new BehaviorSubject<LanguageModel>(null);
	private languages: BehaviorSubject<LanguageModel[]> = new BehaviorSubject<LanguageModel[]>([]);
	private template: BehaviorSubject<LetterTemplateModel> = new BehaviorSubject<LetterTemplateModel>(null);
	private defaultTemplate: BehaviorSubject<LetterTemplateModel> = new BehaviorSubject<LetterTemplateModel>(null);
	private form: BehaviorSubject<FormModel> = new BehaviorSubject<FormModel>(null);
	private userID: number;
	private subjectType: SubjectType;

	constructor(userID: number, subjectType: SubjectType) {
		super();

		this.userID = userID;
		this.subjectType = subjectType;

		this.completeOnDestroy(this.selectedLanguage).subscribe(language => {
			if (language === null) {
				return;
			}
			this.fetchLanguage(language.languageID);
		});

		this.busy.subscribe((busy) => this.requestInProgress = busy);
	}

	public onLanguageUpdated(language: Language) {
		const updatedLanguage = {languageID: language.languageId, languageName: language.languageName} as LanguageModel;
		const languages = this.languages.value.map(s => {
			if (s.languageID === language.languageId) {
				return updatedLanguage;
			} else {
				return {...s};
			}
		});

		this.selectedLanguage.next(updatedLanguage);
		this.languages.next(languages);
	}

	public onLanguageCreated(language: Language) {
		const newLanguage = {languageID: language.languageId, languageName: language.languageName} as LanguageModel;

		this.languages.next([...this.languages.value.filter(l => l.languageID !== 0), newLanguage, {languageID: 0, languageName: 'Add new', isDefault: true}]);
		this.selectedLanguage.next(newLanguage);
	}

	public setLanguage(language: LanguageModel) {
		this.selectedLanguage.next(language);
	}

	public init() {
		this.httpClient.ESGIApi.get<InitResponse>(ParentLetterTemplateApiHost, ParentLetterTemplateActions.init, {
			userID: this.userID,
		})
			.subscribe(response => {
				response.languages.push({languageID: 0, languageName: 'Add new', isDefault: true});

				const templateIsDistrict = this.subjectType === SubjectType.Deployed && !!response.letterTemplate.districtTemplate;

				this.form.next({
					templateIsDistrict: templateIsDistrict,
					readonly: response.readOnly,
					logo: response.logo,
					districtTemplateMessage: templateIsDistrict && '* This message has been set by your District Administrator and cannot be changed for District subject tabs.',
				});

				this.template.next({
					letterTemplateID: templateIsDistrict ? response.letterTemplate.districtTemplate.letterTemplateID : response.letterTemplate.template.letterTemplateID,
					templateBody: templateIsDistrict ? response.letterTemplate.districtTemplate.templateBody : response.letterTemplate.template.templateBody,
				});

				if (!templateIsDistrict) {
					this.languages.next(response.languages);
					this.setLanguage(response.languages.find(s => s.languageID === response.languageID));
				}

				if (response.letterTemplate.defaultTemplate) {
					this.defaultTemplate.next(response.letterTemplate.defaultTemplate);
				}
			});
	}

	private fetchLanguage(languageID: number) {
		this.httpClient.ESGIApi.get<LetterTemplate>(ParentLetterTemplateApiHost, ParentLetterTemplateActions.fetchLanguage, {
			languageID: languageID,
			userID: this.userID,
		})
			.subscribe(response => {
				this.template.next(response.template);

				if (response.defaultTemplate) {
					this.defaultTemplate.next(response.defaultTemplate);
				} else {
					const defaultText = '[Insert greeting] <Student_First_Name>,\n\n[Insert message text here]\n\n<Teacher_Title><Teacher_Last_Name>';
					this.defaultTemplate.next({
						letterTemplateID: 0,
						templateBody: defaultText,
					} as LetterTemplateModel);

					if (response.template.letterTemplateID === 0) {
						this.template.next({
							letterTemplateID: 0,
							templateBody: defaultText,
						} as LetterTemplateModel);
					}
				}
			});
	}


	public save(template: string) {
		if (this.requestInProgress) {
			return;
		}

		if (template === this.template.value.templateBody) {
			return;
		}

		if (this.defaultTemplate.value.letterTemplateID != 0
			&& this.template.value.letterTemplateID !== 0
			&& template === this.defaultTemplate.value.templateBody) {
			this.httpClient.ESGIApi.post<RevertResponse>(this.controller, 'revert', {
				userID: this.userID,
				letterTemplateID: this.template.value.letterTemplateID,
			})
				.subscribe(response => {
					this.template.next({
						templateBody: response.templateBody,
						letterTemplateID: 0,
					});
				});

			return;
		}

		if (this.template.value.letterTemplateID === 0 && template !== this.defaultTemplate.value.templateBody) {
			this.httpClient.ESGIApi.post<CreateResponse>(this.controller, 'create', {
				userID: this.userID,
				body: template,
				languageID: this.selectedLanguage.value.languageID,
			})
				.subscribe(response => {
					this.template.next({
						templateBody: template,
						letterTemplateID: response.letterTemplateID,
					});
				});

			return;
		}

		if (this.template.value.letterTemplateID != 0) {
			this.httpClient.ESGIApi.post(this.controller, 'update', {
				userID: this.userID,
				letterTemplateID: this.template.value.letterTemplateID,
				body: template,
				languageID: this.selectedLanguage.value.languageID,
			})
				.subscribe(() => {
					this.template.next({
						templateBody: template,
						letterTemplateID: this.template.value.letterTemplateID,
					});
				});
		}
	}

	public deleteLanguage(languageID: number) {
		return this.httpClient.ESGIApi.post(this.controller, 'delete-language', {
			languageID: languageID,
			userID: this.userID,
		}).subscribe(() => {
			const languages = deepCopy(this.languages.value).filter(s => s.languageID !== languageID);

			this.languages.next(languages);
			this.selectedLanguage.next(languages[0]);
		});
	}

	public template$: Observable<LetterTemplateModel> = this.completeOnDestroy(this.template);
	public defaultTemplate$: Observable<LetterTemplateModel> = this.completeOnDestroy(this.defaultTemplate);
	public selectedLanguage$: Observable<LanguageModel> = this.completeOnDestroy(this.selectedLanguage);
	public languages$: Observable<LanguageModel[]> = this.completeOnDestroy(this.languages);
	public form$: Observable<FormModel> = this.completeOnDestroy(this.form);
}
