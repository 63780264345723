import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const CompleteImportPage = lazyComponent(() => import('pages/landing/complete-import'));

export default function CompleteImport() {
	return <RouteWrapper title='Complete import'>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<CompleteImportPage/>
		</Suspense>
	</RouteWrapper>;
}
