import {loadStripe} from '@stripe/stripe-js';
import {HttpClient} from '@esgi/api';

export class ReceiptUtil {

	public getReceiptFileName = (extension: string) => {
		let today = new Date();
		let day = this.padNumber(2, today.getDate());
		let month = this.padNumber(2, today.getMonth() + 1);
		let year = today.getFullYear().toString();
		let result = 'ESGI_Purchase_Receipt_' + year + '-' + month + '-' + day + '.' + extension;
		return result;
	};

	private padNumber = (padding: number, num: number, padChar: string = '0') => {
		let result = num.toString();
		while (result.length < padding) {
			result = padChar + result;
		}
		return result;
	};
}

interface Window {
	Stripe: any;
}

export enum StripeMode {
	None,
	CombinedField,
	MultipleFields
}

export class StripeUtil {
	public static StripeCardNumberId = 'STRP';
	public static ElementNameCard = 'card';
	public static ElementNameCardNumber = 'cardNumber';
	public static ElementNameCardExpiry = 'cardExpiry';
	public static ElementNameCardCvc = 'cardCvc';

	public StripeObject: any;
	public Card: any;
	public CardNumber: any;
	public CardExpiry: any;
	public CardCvc: any;

	private StripeElements: any;
	private StripeOptions = {
		style: {
			base: {
				color: '#555555',
				fontFamily: 'Roboto, "Open Sans", sans-serif',
				fontSize: '14px',
				'::placeholder': {
					color: '#CCCCCC',
					fontSize: '13px',
				},
			},

		},
	};

	public StripeInit = (mode: StripeMode) => {
		let deferred = $.Deferred<any>();
		this.GetPublicKey().then((stripePublicKey) => {
			loadStripe(stripePublicKey).then((stripeObject) => {
				this.StripeObject = stripeObject;
				this.StripeElements = this.StripeObject.elements();
				switch (mode) {
					case StripeMode.CombinedField: {
						this.Card = this.StripeElements.create(StripeUtil.ElementNameCard, this.StripeOptions);
						break;
					}
					default: {
						this.CardNumber = this.StripeElements.create(StripeUtil.ElementNameCardNumber, this.StripeOptions);
						this.CardExpiry = this.StripeElements.create(StripeUtil.ElementNameCardExpiry, this.StripeOptions);
						this.CardCvc = this.StripeElements.create(StripeUtil.ElementNameCardCvc, this.StripeOptions);
						break;
					}
				}
				deferred.resolve();
			});
		});
		return deferred.promise();
	};

	public GetCardElement = () => {
		return this.Card || this.CardNumber;
	};

	private GetPublicKey = () => {
		return HttpClient.default.SSO.get<string>('Stripe', 'GetPublicKey').toPromise();
	};
}
