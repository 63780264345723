import {DatePicker} from '@esgi/deprecated/elements';
import moment, {Moment} from 'moment';
import React from 'react';
import styles from './styles.module.less';
import {DatesPeriodModel} from '../../../../types';

type DateValidator = (value: Moment, index: number, item: DatesPeriodModel) => {valid: boolean, message: string};
type ChangeHandler = (num: number, date: Moment) => void;

interface Props {
	periods: DatesPeriodModel[]
	onFromChange: ChangeHandler
	onToChange: ChangeHandler
	fromValidator: DateValidator
	toValidator: DateValidator
}


export function Periods({periods, onFromChange, onToChange, fromValidator, toValidator}: Props){
	return(
		<div>
			<div className={styles.row}>
				<div/>
				<div>
					<label className={styles.label}>From</label>
				</div>
				<div>
					<label className={styles.label}>To</label>
				</div>
			</div>
			{periods.map((item, index) => <div key={item.number} className={styles.row}>
				<div>
					<div className={styles.circle}>{item.number}</div>
				</div>
				<div>
					<label className={styles.label}>
						<DatePicker
							id={'track-datepicker_from-' + index}
							date={moment(item.dateFrom)}
							onSelect={(d) => onFromChange(item.number, d)}
							validationPlacement={'left'}
							validator={(value: Moment) => fromValidator(value, index, item)}
						/>
					</label>
				</div>
				<div>
					<label className={styles.label}>
						<DatePicker
							id={'track-datepicker-to-' + index}
							date={moment(item.dateTo)}
							onSelect={(d) => onToChange(item.number, d)}
							validationPlacement={'right'}
							validator={(value: Moment) => toValidator(value, index, item)}
						/>
					</label>
				</div>
			</div>)}
		</div>
	);
}
