import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {WrapperBox} from './index.styled';

export const PanelContent = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
	({children, ...props}, forwardedRef) => (
		<WrapperBox ref={forwardedRef} {...props}>
			{children}
		</WrapperBox>
	),
);
