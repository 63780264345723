import {BehaviorSubject, zip} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {Class, Group, Student, classesStore, groupsStore, studentsStore} from '@esgi/main/libs/store';

export class StudentService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public classes$ = new BehaviorSubject<Class[]>([]);
	public groups$ = new BehaviorSubject<Group[]>([]);
	public students$ = new BehaviorSubject<Student[]>([]);
	private studentNames: Record<number, string> = null;
	private storageClasses = classesStore();
	private storageGroups = groupsStore();
	private storageStudents = studentsStore();

	constructor() {
		super();

		zip(
			this.storageClasses.get(),
			this.storageGroups.get(),
			this.storageStudents.get(),
		).subscribe(([classes, groups, students]) => {
			this.classes$.next(classes.data);
			this.groups$.next(groups.data);
			this.students$.next(students.data);
			this.studentNames = students.data.reduce((result, student) => {
				result[student.id] = `${student.firstName} ${student.lastName}`;
				return result;
			}, {});
			this.loaded$.next(classes.loaded && groups.loaded && students.loaded);
		});
	}

	public override dispose() {
		this.storageClasses.dispose();
		this.storageGroups.dispose();
		this.storageStudents.dispose();
	}

	public get(studentId: Student['id'] | undefined) {
		return this.students$.value.find(({id}) => id === studentId);
	}

	public getName(studentId: Student['id']) {
		return this.studentNames[studentId] || '';
	}
}
