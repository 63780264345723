import {useCallback, useState} from 'react';
import {TileState} from 'shared/modules/auto-test-creator/types';
import {SelectIcon} from 'shared/modules/auto-test-creator/icons/select-icon';
import {MenuIcon} from 'shared/modules/auto-test-creator/icons/menu-icon';
import {ContextMenu} from '../context-menu';
import {defaultValues} from 'shared/modules/auto-test-creator/constants';
import styles from './styles.module.less';

interface Props {
	tile: TileState;
	author: string;
	orderNumber: number;
	isDefaultImage: boolean;
	previewRequest: (xml: string) => string;
	onEdit: (id, orderNumber) => void;
	onDelete: (id: string) => void;
	onChangeStateStandard: () => void;
	onChangeDefaultImageID: (id: string) => void;
}

export function Tile({
	previewRequest,
	tile,
	author,
	orderNumber,
	isDefaultImage,
	onChangeStateStandard,
	onChangeDefaultImageID,
	onDelete,
	onEdit,
}: Props) {

	const image = previewRequest(tile.question.xml);

	const [isContextMenuOpened, setContextMenuOpened] = useState(false);
	const onHandleDelete = (id) => {
		setContextMenuOpened(false);
		onDelete(id);

	};
	const onHandelEdit = (id, orderNumber) => {
		setContextMenuOpened(false);
		onEdit(id, orderNumber);

	};
	const setAsThumbnail = (id) => {
		setContextMenuOpened(false);
		onChangeDefaultImageID(id);
	};

	const onContextMenuClose = useCallback(() => setContextMenuOpened(false), []);

	return <div data-cy='tile' className={styles.tileContainer}>
		<header className={styles.header}>
			<div className={styles.selectIconContainer}>
				{isDefaultImage && <SelectIcon/>}
			</div>
			<span data-cy='author' className={styles.author}> {author} </span>
			<div className={styles.menuIconContainer}>
				<MenuIcon className={styles.contextMenuButton} onClick={() => setContextMenuOpened(true)}/>
			</div>
		</header>

		<main>
			<img data-cy='background-img' src={image} height={defaultValues.image.height} width={defaultValues.image.width} alt='question'/>
		</main>

		<footer className={styles.footer}>
			<span data-cy='question-number'> Question {orderNumber} </span>
		</footer>
		{isContextMenuOpened && <ContextMenu
			id={tile.id}
			onClose={onContextMenuClose}
			onChangeStateStandard={onChangeStateStandard}
			stateStandard={tile.question.stateStandard}
			onChangeSelectedThumbnailID={setAsThumbnail}
			stateStandardID={tile.question.stateStandardID}
			onDelete={onHandleDelete}
			onEdit={(id, order) => onHandelEdit(id, order)}
			orderNumber={orderNumber}
		/>}
	</div>;
}


