import {SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const expandableListsGap = 6;

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',

	variants: {
		isListOpen: {
			true: {
				'&:not(:last-child)': {
					marginBottom: 18,
				},
			},

			false: {
				'&:not(:last-child)': {
					marginBottom: expandableListsGap,
				},
			},
		},
	},
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	minHeight: 40,
	paddingLeft: 12,
	variants: {
		defaultCursor: {
			true: {
				cursor: 'default',
			},
		},
		isListSelected: {
			true: {
				color: '$primary',
				borderBottomColor: '$primaryMuted',
				background: 'linear-gradient(272deg, #F3F9FC 48.17%, #F7FBFD 100%)',
			},
		},
	},
});
