import {AnswerBar, Container} from './index.styled';
import {Property} from '@stitches/react/types/css';
import {useAnswerBarItems} from './use-answer-bar-items';
import {useItemStatistics} from '../../../../hooks/use-item-statistics';
import {useTransition, config} from 'react-spring';

type Props = {
	sessionsCount: number;
	correct: number;
	incorrect: number;
	width: Property.Width;
	height: Property.Height;
};

export function HorizontalBox({sessionsCount, correct, incorrect, width, height}: Props) {
	const itemStatistics = useItemStatistics({sessionsCount, correct, incorrect});
	const answerBarItems = useAnswerBarItems(itemStatistics);

	const barItemsTransition = useTransition(
		answerBarItems.filter(({value}) => value > 0),
		{
			from: {
				width: '0%',
			},
			enter: (item) => ({
				width: `${item.value}%`,
			}),
			trail: 300 / answerBarItems.length,
			config: {
				...config.gentle,
				duration: 300,
				clamp: true,
			},
		},
	);

	return (
		<Container dataCy='bar-container' css={{width, height}}>
			{barItemsTransition((styles, {type, borderLeftWidth}) => (
				<AnswerBar itemType={type} style={styles} css={{borderLeftWidth}} />
			))}
		</Container>
	);
}
