import React, {useEffect, useMemo} from 'react';
import {Modal} from '@esgillc/ui-kit/modal';
import {LanguageTemplateService} from '../../../services/language-template-service';
import styles from './styles.module.less';
import {Form, FormElement, ValidatorArgs, Validators} from '@esgillc/ui-kit/form';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Input, Label, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {Observable, of} from 'rxjs';
import {concatMap, debounceTime} from 'rxjs/operators';

interface Props {
	service: LanguageTemplateService
}

export function LanguageTemplateBody({service}: Props) {
	const languageName = useBehaviorSubject(service.languageName);
	const charactersLeft = useMemo(() => `${service.maxLength - languageName.length} characters left (${service.maxLength} max)`,
		[service.maxLength, languageName.length]);

	useEffect(() => {
		const control = service.form.controls.language;
		if (control) {
			control.validators.push((validateChain: Observable<ValidatorArgs<string>>) => {
				return validateChain.pipe(debounceTime(300), concatMap(v => {
					if (!v.cancelled && v.field.currentValue.length >= 1) {
						return service.validateUniqueness(v);
					} else {
						return of(v);
					}
				}));
			});
		}

	}, []);

	return <Modal.Body className={styles.modalBody}>
		<div className={styles.plLanguageModalContent}>
			<Form controller={service.form}>
				<FormElement control={service.form.controls.language}>
					<Label className={styles.dropdownLabel}>Language</Label>
					<Input maxLength={15} autoFocus={true}/>
					<OnErrorTooltip showOnError={'unique'} placement='right' className={styles.tooltip}>
						Please choose a unique name for this template
					</OnErrorTooltip>
					<OnErrorTooltip showOnError={'max'} placement='right' className={styles.tooltip}>
						{`You have reached the ${service.maxLength} character limit.`}
					</OnErrorTooltip>
					<OnErrorTooltip showOnError={'required'} placement='right' className={styles.tooltip}>
						Language is required
					</OnErrorTooltip>
				</FormElement>
			</Form>
			<div className={styles.noteCharactersLeft}><span>{charactersLeft}</span>
			</div>
		</div>
	</Modal.Body>;
}
