import './resizable-table.less';

export class ResizableTable {
	private readonly bodyHeight: () => number;
	private readonly rootElement;

	private headerElement() {
    	return $('.grid-header', this.rootElement);
	}

	private bodyElement() {
    	return $('.grid-body', this.rootElement);
	}

	private bodyWrapper() {
    	return $('.grid-body-wrapper', this.rootElement);
	}

	constructor(rootElement, bodyHeight: (() => number) | number, public useAceScroll: boolean = true) {
    	if (typeof bodyHeight === 'number') {
    		this.bodyHeight = () => bodyHeight;
    	} else {
    		this.bodyHeight = bodyHeight;
    	}
        
    	this.rootElement = rootElement;
	}

	load() {
    	$(this.headerElement()).resizableColumns({relatedTable: this.bodyElement()});
        
    	if (this.useAceScroll) {
    		const bodyHeight = this.bodyHeight();
    		$(this.bodyWrapper()).ace_scroll({
    			size: bodyHeight,
    			scrollEvent: true,
    			touchSwipe: true,
    		}).css('max-height', bodyHeight + 'px');
    	}
    	this.sync();
	}

	sync() {
    	$(this.bodyElement()).css('opacity', 0);
    	setTimeout(() => {
    		$(this.headerElement()).resizableColumns('syncTables');
    		if (this.useAceScroll) {
    			$(this.bodyWrapper()).ace_scroll('reset');
    		}
            
    		if (bowser.msie || bowser.edge) {
    			let innerBody = $(this.bodyElement()).parents('.modal-body-inner');
    			if(innerBody) {
    				innerBody.css('overflow', 'hidden');
    				setTimeout(() => {
    					innerBody.css('overflow', '');
    				}, 1);
    			}
    		}
    		$(this.bodyElement()).animate({opacity: 1}, 500);
    	}, 1);

	}
}
