import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const IconWrapper = styled(FlexBox, {
	border: '2px solid currentColor',
	borderRadius: '50%',
	overflow: 'hidden',
});

export const ButtonIcon = styled(Button.Icon, {
	width: 36,
	height: 36,
	alignItems: 'center',
	borderRadius: 8,
	backgroundColor: '$primary92',
	color: '$primary48',

	'& svg': {
		width: 24,
		height: 24,
		transform: 'scale(1.6) translateY(3px) translateX(1px)',
	},

	variants: {
		noClicable: {
			true: {
				cursor: 'default',

				'&:hover:not(:disabled)': {
					color: '$primary48',
				},
			},
		},
	},
});
