import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button as ButtonUI} from '@esgi/ui';

export const StyledEmptyWrapper = styled(FlexBox, {
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
});

export const StyledEmptyBox = styled(Box, {
	padding: '20px',
	textAlign: 'center',

	[`& ${Text}`]: {
		borderRadius: '6px',
		padding: '12px 20px',
		border: '1px solid $border',
		background: '$vivid',
		color: '$neutral40',
		display: 'flex',
	},
});

export const Button = styled(ButtonUI, {
	margin: '0 auto',
	maxWidth: '166px',
	width: '100%',
});
