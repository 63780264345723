import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const ButtonIconable = styled(Button.Icon, {
	variants: {
		active: {
			true: {
				color: '$primary',
			},
		},

		variant: {
			filled: {
				transition: 'width .3s, height .3s, border-radius .3s, background-color .3s',
				width: 28,
				height: 28,
				borderRadius: 6,
				backgroundColor: '$vivid',

				'&:hover': {
					width: 28,
					height: 28,
					borderRadius: '50%',
				},
			},
		},
	},
});
