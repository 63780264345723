import {PropsWithChildren} from 'react';
import {createPortal} from 'react-dom';

type DrawerPortalProps = PropsWithChildren<{
	/** The node where the portal should mount. */
	container?: Element | DocumentFragment | null | undefined;
}>;

export function Portal({container, children}: DrawerPortalProps) {
	return createPortal(children, container ?? document.body);
}
