import React from 'react';
import {Renderable} from '@esgi/deprecated/knockout';
import {resolvedPromise} from '@esgi/deprecated/utils';
import {HttpClient} from '@esgi/api';

export class RemindPassword extends Renderable {
	template = () => {
    	return <div id='remind-password-form' data-bind='afterRender: true'>
	<div className='IncorrectText alert alert-danger' style={{display: 'none'}}
                 data-bind='visible: showFailMessage'>
		<h4>
			Incorrect Information!
		</h4>
		<p>
			We were not able to locate that username or email address in our system. Please
			try re-entering the information. If you have already tried again, and it still will
			not work, please contact us. Thank you!
		</p>
	</div>
	<div className='SuccessfulTextEmail alert alert-success' style={{marginBottom: 0}}
                 data-bind='visible: showSuccessMessage'>
		<h4>
			Success!
		</h4>
		<p>
			We have located your username and a message has been sent to your email address
			containing a link to reset your password. If you do not receive the email,
			<i>please check your spam filter</i>.
			If you do not receive the email within an hour, please contact us.
		</p>
		<p>
			Thank you!
		</p>
	</div>
	<form className='form-horizontal' data-bind='visible: showForm;'>
		<fieldset>
			<div className='form-group' data-bind="css: {'has-error':emailAddressErrorMessage()}">
				<label className='col-md-5 control-label' htmlFor='textinput'>Enter your email address</label>
				<div className='col-md-7'>
					<input id='email-address' name='textinput' type='text' className='form-control input-md'
                                   autoFocus data-bind='value:emailAddress, disable: emailAddressDisabled'/>
					<span className='help-block'
                                      data-bind='visible:emailAddressErrorMessage(), text:emailAddressErrorMessage'/>
				</div>
			</div>

			<div className='form-group'>
				<div className='col-md-5'/>
				<div className='col-md-7'>-- OR --</div>
			</div>
			<div className='form-group' data-bind="css: {'has-error':userNameErrorMessage()}">
				<label className='col-md-5 control-label' htmlFor='textinput'>Enter your username</label>
				<div className='col-md-7'>
					<input id='user-name' name='textinput' type='text' className='form-control input-md'
                                   data-bind='value: userName, disable: userNameDisabled'/>
					<span className='help-block'
                                      data-bind='visible: userNameErrorMessage(), text:userNameErrorMessage'/>
				</div>
			</div>
			<div className='form-group' style={{marginBottom: 0}}>
				<label className='col-md-5 control-label'/>
				<div className='col-md-7'>
					<button className='btn btn-primary pull-right' type='button'
                                    data-bind='click: view.submitButtonClicked, disable: submitButtonDisabled'>Submit
					</button>
				</div>
			</div>
		</fieldset>
	</form>
        </div>;
	};
	rootElement = null;
	afterRender (rootElement) {
    	this.rootElement = rootElement;
    	$(this.rootElement).find('#email-address').keyup((value) => {
    		if (value) {
    			this.userNameDisabled(true);
    		} else {
    			this.userNameDisabled(false);
    		}
    	});
    	$(this.rootElement).find('#user-name').keyup((value) => {
    		if (value) {
    			this.emailAddressDisabled(true);
    		} else {
    			this.emailAddressDisabled(false);
    		}
    	});
    	return resolvedPromise(true);
	}
	emailAddress = ko.observable<string>();
	userName = ko.observable<string>();
	userNameDisabled = ko.observable(false);
	emailAddressDisabled = ko.observable(false);
	submitButtonDisabled = ko.observable(false);
	showForm = ko.observable(true);
	showSuccessMessage = ko.observable(false);
	showFailMessage = ko.observable(false);
	userNameErrorMessage = ko.observable('');
	emailAddressErrorMessage = ko.computed(() => {
    	if (this.emailAddress() && !this.isEmailValid()) {
    		return 'Please enter a valid email above';
    	}
	});
	isEmailValid = ko.computed(() => {
    	const email = this.emailAddress();
    	const emailRegEx = /^[A-Z0-9\'\`\~._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]{2,}$/i;
    	return email != '' && emailRegEx.test(email);
	});
	remindMethod = ko.computed(() => {
    	if (this.emailAddress()) {
    		return 1;
    	}
    	if (this.userName()) {
    		return 2;
    	}
    	return null;
	});
	load = function () {
    	return $.Deferred().resolve().promise();
	};
	view =
		{
        	submitButtonClicked: () => {
        		if (this.emailAddress() || this.userName()) {
        			let recipient = null;
        			if (this.emailAddress()) {
        				if (this.isEmailValid()) {
        					recipient = this.emailAddress();
        				} else {
        					return;
        				}
        			} else {
        				recipient = this.userName();
        			}
        			this.submitButtonDisabled(true);
        			this.showFailMessage(false);
        			this.showSuccessMessage(false);

					const data = new FormData();
					data.append('email', this.emailAddress());
					data.append('userName', this.userName());

					HttpClient.default.ESGIApi.post('Login', 'remind-password', data).subscribe(
						(r) => {
							if (r) {
								this.showFailMessage(false);
								this.showSuccessMessage(true);
								this.showForm(false);
							} else {
								this.showFailMessage(true);
							}
							this.submitButtonDisabled(false);
						},
						() => {
							this.submitButtonDisabled(false);
						});
        		}
        	},
		};
}
