import {useState, useEffect} from 'react';
import {ChangeFunction} from './types';

export const useValue = <T>(initValue: T, onChange: ChangeFunction<T>) => {
	const [value, setValue] = useState<T>(initValue);

	useEffect(() => {
		onChange(value);
	}, [value]);

	return [value, setValue] as const;
};
