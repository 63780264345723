import {FlexBox, GridBox} from '@esgi/ui/layout';
import {AddButton, Layout} from './styled';
import {Filter, UpdateFilter} from '../../types';
import {Button, Download, Plus, UserList, Tooltip} from '@esgi/ui';
import {TextWithIcon} from '@esgi/main/kits/assignments';

type Props = {
	loaded: boolean,
	filter: Filter,
	updateFilter: UpdateFilter,
	onExportClick: VoidFunction,
	onAddClick: VoidFunction,
	canAddStudents: boolean,
}

export function Header(props: Props) {
	return <Layout>
		<GridBox align='center' gap='1' flow='column'>
			<TextWithIcon size='small' color='neutral56' iconWidth={24} iconHeight={24} Icon={UserList} viewBox='0 0 32 32'>
				Student Manager
			</TextWithIcon>
		</GridBox>
		<FlexBox align='center'>
			<Tooltip delayDuration={400}>
				<Tooltip.Trigger>
					<Button.Icon disabled={!props.loaded} onClick={props.onExportClick} dataCy='export-button'>
						<Download/>
					</Button.Icon>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					Download student list
				</Tooltip.Content>
			</Tooltip>
			<Tooltip delayDuration={400}>
				<Tooltip.Trigger>
					<AddButton color='secondary' disabled={!props.loaded || !props.canAddStudents} onClick={props.onAddClick} dataCy='add-student-button'>
						<Plus/>
					</AddButton>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					Add student
				</Tooltip.Content>
			</Tooltip>
		</FlexBox>
	</Layout>;
}