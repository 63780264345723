import {ComponentProps, forwardRef, useCallback} from 'react';
import {ElementStatus, useFormElementContext} from '../../form';
import {ToggleGroup as BaseToggleGroup} from '../../controls/toggle-group';

type ToggleGroupProps = Omit<ComponentProps<typeof BaseToggleGroup.Root>, 'onValueChange' | 'value' | 'defaultValue'>;

export const ToggleGroupRoot = forwardRef<HTMLDivElement, ToggleGroupProps>(({children, ...props}, ref) => {

	const {status, setValue, value} = useFormElementContext({string: true}, ToggleGroup);
	const onValueChange = useCallback((value: string) => setValue(value), [setValue]);

	const commonProps = {
		disabled: status === ElementStatus.disabled,
		value,
		onValueChange,
		ref,
		...props,
	};

	return <BaseToggleGroup.Root {...commonProps}>
		{children}
	</BaseToggleGroup.Root>;
});

export const ToggleGroup = {
	Root: ToggleGroupRoot,
	Content: BaseToggleGroup.Content,
	Item: BaseToggleGroup.Item,
	Title: BaseToggleGroup.Title,
};
