import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Dropdown, Input, OnErrorTooltip, Option} from '@esgillc/ui-kit/form-control';
import {Buttons} from '@esgillc/ui-kit/button';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Loader} from '@esgillc/ui-kit/loader';
import {DistrictMenuList} from './components/district-menu-list';
import {SchoolMenuList} from './components/school-menu-list';
import {ManageAccountService} from './service';
import styles from './styles.module.less';
import {DropdownInModel} from '../../../../types';
import {ManageAccountSavedEvent} from '../../types';

interface Props {
	defaultStates: DropdownInModel[];
	selectedState: DropdownInModel;
	defaultCountries: DropdownInModel[];
	selectedCountry: DropdownInModel;
	schoolName: string;
	districtName: string;
	onClose: () => void;
	onSaved: (event: ManageAccountSavedEvent) => void
}

export function ManageAccount({
	                              defaultStates,
	                              defaultCountries,
	                              schoolName,
	                              districtName,
	                              selectedCountry,
	                              selectedState,
	                              onClose,
	                              onSaved,
}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const service = useService(ManageAccountService);

	const [isSchoolListVisible, setSchoolListVisible] = useState(false);
	const [isDistrictListVisible, setDistrictListVisible] = useState(false);
	const [isSchoolNameFocused, setSchoolNameFocused] = useState(false);
	const [isDistrictNameFocused, setDistrictNameFocused] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const states = useBehaviorSubject(service.states);
	const countries = useBehaviorSubject(service.countries);
	const availableSchools = useBehaviorSubject(service.availableSchoolsByQuery);
	const availableDistricts = useBehaviorSubject(service.availableDistrictsByQuery);
	const isSchoolAvailableInList = useBehaviorSubject(service.isSchoolAvailableInList);
	const isDistrictAvailableInList = useBehaviorSubject(service.isDistrictAvailableInList);
	const selectedSchool = useBehaviorSubject(service.selectedSchool);
	const selectedDistrict = useBehaviorSubject(service.selectedDistrict);
	const hasDistrict = useBehaviorSubject(service.hasDistrict);

	useEffect(() => {
		service.init({
			defaultStates, defaultCountries, schoolName, districtName, selectedCountry, selectedState,
		});
		service.form.controls.country.onChanged.subscribe((v) => {
			if (v.currState.value[0].id !== v.prevState.value[0]?.id) {
				setLoading(true);
				service.getStatesByCountryID(v.currState.value[0].id).subscribe(() => {
					service.resetSchool();
				}, () => null, () => {
					setLoading(false);
				});
			}
		});
	}, []);
	useEffect(() => {
		const schoolNameStream = service.form.controls.school.onChanged.subscribe((v) => {
			if (isSchoolAvailableInList && isSchoolNameFocused && v.currState.value.length > 1 && v.currState.value !== v.prevState.value) {
				setSchoolListVisible(true);
			}
		});
		const districtNameStream = service.form.controls.district.onChanged.subscribe((v) => {
			if (hasDistrict && isDistrictAvailableInList && isDistrictNameFocused && v.currState.value !== v.prevState.value) {
				setDistrictListVisible(true);
			}
		});
		return () => {
			schoolNameStream.unsubscribe();
			districtNameStream.unsubscribe();
		};
	}, [isSchoolNameFocused, isDistrictNameFocused, isSchoolAvailableInList, isDistrictAvailableInList]);

	const handleSchoolListClose = () => {
		setSchoolListVisible(false);
		service.availableSchoolsByQuery.next([]);
	};
	const handleSchoolSelect = (school) => {
		if (!school) {
			service.setSchoolAvailableInList(false);
		} else {
			service.selectSchool(school);
		}
	};
	const onSubmit = () => {
		setLoading(true);
		service.form.validate().subscribe((result) => {
			if (result.valid) {
				service.save().subscribe((savedEvent) => {
					handleClose();
					onSaved(savedEvent);
					setLoading(false);
				});
			} else {
				setLoading(false);
			}
		});
	};

	return <Modal modalManagerRef={modalRef} className={styles.modal}>
		<Loader show={isLoading} fullscreen/>
		<Modal.Header>
			<Title>
				Manage Your Account
			</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<div data-cy={'manage-account-form'}>
				<Form controller={service.form}>
					<div className={styles.row}>
						<FormElement className={styles.formElement} control={service.form.controls.state}>
							<div data-cy={'state-dropdown'}>
								<label className={styles.label}>State</label>
								<Dropdown optionName={'value'}>
									{states.map(state => <Option key={state.id}
									                             value={state}>{state.value}</Option>)}
								</Dropdown>
							</div>
						</FormElement>
						<FormElement className={styles.formElement} control={service.form.controls.country}>
							<div data-cy={'country-dropdown'}>
								<label className={styles.label}>Country</label>
								<Dropdown optionName={'value'}>
									{countries.map(country => <Option key={country.id}
									                                  value={country}>{country.value}</Option>)}
								</Dropdown>
							</div>
						</FormElement>
					</div>
					<FormElement className={styles.formElement} control={service.form.controls.school}>
						<div data-cy={'school-input'}>
							<label className={styles.label}>School</label>
							<Input onFocus={() => setSchoolNameFocused(true)}
							       onBlur={() => setSchoolNameFocused(false)}/>
							<span data-cy={'school-clear-button'} className={styles.closeIcon}
							      onClick={service.resetSchool}>&times;</span>
							<OnErrorTooltip showOnError={'required'} placement={'right'}>
								Please enter a school or select "My school isn't listed"
							</OnErrorTooltip>
							<SchoolMenuList
								isVisible={isSchoolListVisible}
								onSelect={handleSchoolSelect}
								availableSchools={availableSchools}
								onClose={handleSchoolListClose}
							/>
						</div>
					</FormElement>
					<FormElement className={join(styles.formElement, isSchoolAvailableInList && styles.hidden)}
					             control={service.form.controls.district}>
						<div data-cy={'district-input'}>
							<label className={styles.label}>District</label>
							<Input onFocus={() => setDistrictNameFocused(true)}
							       onBlur={() => setDistrictNameFocused(false)}/>
							<OnErrorTooltip showOnError={'required'} placement={'right'}>
								Please enter a district or select "My school does not belong to a District"
							</OnErrorTooltip>
							<DistrictMenuList
								setDistrictAvailableInList={service.setDistrictAvailableInList}
								isVisible={isDistrictListVisible}
								setDontHaveDistrict={service.setDontHaveDistrict}
								onSelect={service.selectDistrict}
								availableDistricts={availableDistricts}
								onClose={() => setDistrictListVisible(false)}
							/>
						</div>
					</FormElement>
				</Form>
			</div>

		</Modal.Body>
		<Modal.Footer>
			<Buttons.Gray onClick={handleClose}>
				Cancel
			</Buttons.Gray>
			<Buttons.Contained
				disabled={(isSchoolAvailableInList && !selectedSchool)
					|| (!isSchoolAvailableInList && isDistrictAvailableInList && !selectedDistrict && hasDistrict)}
				onClick={onSubmit}
			>
				Save
			</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
