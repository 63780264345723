import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gap: 2,
	justifySelf: 'end',
});

export const AnswersInfoBox = styled(FlexBox, {
	alignItems: 'baseline',
	justifySelf: 'end',
});
