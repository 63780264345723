import {Wrapper, ButtonIcon, ButtonText, TagList, Tag, SortTable} from './index.styled';
import {CheckAll, CorrectAnswer, InCorrectAnswer} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {TestListProps} from '../../../../types';
import {useUser} from '@esgi/core/authentication';
import {useMemo} from 'react';
import {TagListModel} from './types';
import {Tooltip} from '@esgi/ui';

interface Props extends Pick<TestListProps, 'sort' | 'onSort' | 'onSelectAll'> {}

export function Action({sort, onSort, onSelectAll}: Props) {
	const currentUser = useUser();

	const tagItems = useMemo<TagListModel[]>(
		() => [
			{
				tagType: 'green',
				content: <CorrectAnswer />,
				tooltipText: currentUser?.testResultsCorrectVerbiage ?? 'Correct',
			},
			{
				tagType: 'red',
				content: <InCorrectAnswer />,
				tooltipText: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect',
			},
			{
				content: (
					<Text size='xxSmall' font='mono' color='lowContrast'>
						NT
					</Text>
				),
				tooltipText: 'Not Tested',
			},
		],
		[currentUser],
	);

	return (
		<Wrapper>
			<ButtonIcon data-cy='check-all-tests-button' onClick={onSelectAll}>
				<CheckAll />
			</ButtonIcon>
			<ButtonText data-cy='sort-tests-button-root' onClick={onSort}>
				<Text data-cy='sort-tests-label' size='small'>Test Name</Text>
				<SortTable data-cy='sort-tests-direction' field={sort.field} direction={sort.direction} />
			</ButtonText>
			<TagList data-cy='tag-list'>
				{tagItems.map(({content, tooltipText, tagType}) => (
					<Tooltip>
						<Tooltip.Trigger>
							<Tag type={tagType}>{content}</Tag>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='medium'>{tooltipText}</Text>
						</Tooltip.Content>
					</Tooltip>
				))}
			</TagList>
		</Wrapper>
	);
}
