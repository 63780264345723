import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
	YAxisLabel: string;
};

export function HeaderRow({YAxisLabel}: Props) {
	return (
		<>
			<GridBox dataCy='question'>
				<Text size='xSmall' font='mono' color='lowContrast'>
					Question
				</Text>
			</GridBox>
			<GridBox justify='start' dataCy='question-title'>
				<Text size='xSmall' font='mono' color='lowContrast'>
					Question Title
				</Text>
			</GridBox>
			<GridBox justify='end' dataCy='y-axis-label'>
				<Text size='xSmall' font='mono' color='blue'>
					{YAxisLabel}
				</Text>
			</GridBox>
		</>
	);
}
