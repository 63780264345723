import {Loader} from '@esgillc/ui-kit/loader';
import {useRef, useState} from 'react';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import {Modal, useModal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {SubjectType} from '@esgi/core/enums';
import {SubjectModel, TestModel} from '../../types';
import {FindMoreService} from '../../find-more-service';
import styles from './styles.module.less';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import useResizeObserver from '@react-hook/resize-observer';

interface Props {
	test: TestModel;
	onClose: () => any;
	service: FindMoreService;
}

const selectSubjectOption: Readonly<SubjectModel> = {
	id: -1,
	name: 'Select a subject tab',
	subjectType: SubjectType.Personal,
};

export const AddTestToSubject = (props: Props): JSX.Element => {
	const {service, test, onClose} = props;
	const modalManagerRef = useModal();
	const [selectedSubject, setSelectedSubject] = useState<SubjectModel>(selectSubjectOption);
	const subjects = useBehaviorSubject<SubjectModel[]>(service.subjects);
	const [loading, setLoading] = useState(false);

	const [dropdownWidth, setDropdownWidth] = useState(0);

	const dropdownContentRef = useRef<HTMLDivElement>(null);

	useResizeObserver(dropdownContentRef, entry => setDropdownWidth(entry.contentRect.width));

	const onSave = () => {
		if (selectedSubject.id === -1) {
			return;
		}

		setLoading(true);

		const {id, subjectType, name} = selectedSubject;
		service.addTestToSubject(test.id, id, subjectType).subscribe({
			next: () => {
				showSnackbarNotification(`Test ${test.name} added to ${name} subject`);
				onClose();
			},
			complete: () => setLoading(false),
		});
	};

	const onSelect = (subject: SubjectModel) => {
		setSelectedSubject(subject);
	};


	if (loading) {
		return <Loader show={true} fullscreen/>;
	}

	return (
		<Modal modalManagerRef={modalManagerRef}>
			<Modal.Header className={styles.modalTitle}>
				Add <span className={styles.testName}>{test.name}</span> to a Subject Tab
			</Modal.Header>
			<Modal.Body className={styles.addToSubject}>
				<Dropdown
					autoWidth={dropdownWidth > 180}
					className={styles.dropdown}
					optionName='name'
					value={[selectedSubject]}
					setValue={(s) => onSelect(s[0])}
				>
					<div ref={dropdownContentRef} className={styles.dropdownContentWrapper}>
						{subjects.map((i) => <Option key={i.id} value={i}>{i.name}</Option>)}
					</div>
				</Dropdown>
			</Modal.Body>
			<Modal.Footer>
				<Button
					title='Cancel'
					onClick={() => modalManagerRef.current.close(onClose)}
				/>
				<Primary
					title='Save'
					disabled={!selectedSubject || selectedSubject.id === -1}
					onClick={onSave}
				/>
			</Modal.Footer>
		</Modal>
	);
};
