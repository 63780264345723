import {ReactNode, useCallback, useState} from 'react';
import {useEventEffect} from '@esgillc/events';
import {ReportErrorEvent} from './events';

type Props = {
	children: (error: ReportErrorEvent, next: VoidFunction) => JSX.Element;
};

/**
 * @name ErrorReporterLayer
 * Shows an error alert with the option to show the Contact us form.
 * ## Example usage:
 * ``` ts
 * import {dispatchAppEvent} from '@esgillc/events';
 * import {ReportErrorEvent} from '@esgi/core/react';
 *
 * const msg = 'Uh oh. Something went wrong with the UI on our end.'
 *
 * dispatchAppEvent(ReportErrorEvent, {
 *     message: msg,
 * } as ReportErrorEvent);
 * ```
 */
export function ErrorReporterLayer(props: Props) {
	const [errorsQueue, setErrorsQueue] = useState<ReportErrorEvent[]>([]);
	useEventEffect(ReportErrorEvent, (event) => setErrorsQueue((queue) => [...queue, event]), []);
	const nextError = useCallback(() => {
		setErrorsQueue((queue) => {
			queue.shift();
			return [...queue];
		});
	}, []);

	const error = errorsQueue[0];

	return props.children(error, nextError);
}