import {BaseButton} from '@esgi/ui';
import {CounterBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Counter = styled(CounterBox, {
	backgroundColor: '$vivid',
	borderColor: '$mild',
	color: '$neutral56',
	alignItems: 'center',
	justifyContent: 'center',
});

export const Button = styled(BaseButton, {
	display: 'grid',
	width: '100%',
	minHeight: 40,
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: 6,
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 12,
	paddingRight: 6,
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',
	color: '$neutral40',

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'& svg': {
		width: 24,
		height: 24,
		fill: '$base',

		'& path': {
			fill: '$base',
		},
	},

	'&:hover': {
		borderColor: '$secondary92',
		backgroundColor: '$secondarySurface',
		color: '$secondary',

		[`& ${Counter}`]: {
			backgroundColor: '$secondarySurface',
			borderColor: '$secondaryMuted',
			color: '$secondary',
		},

		'& svg': {
			fill: '$secondary',

			'& path': {
				fill: '$secondary',
			},
		},
	},

	'&[data-selected=true]:not(:disabled)': {
		color: '$primary',
		borderColor: '$primaryMuted',
		background: 'linear-gradient(272deg, #F3F9FC 48.17%, #F7FBFD 100%)',

		[`& ${Counter}`]: {
			borderColor: '$primaryMuted',
			backgroundColor: '$primarySurface',
			color: '$primaryVivid',
		},

		'& svg': {
			fill: '$primary',

			'& path': {
				fill: '$primary',
			},
		},
	},

	'&:disabled': {
		borderColor: '$border',
		backgroundColor: '$background',
		color: '$muted',

		[`& ${Counter}`]: {
			borderColor: '$muted',
			backgroundColor: '$background',
			color: '$muted',
		},

		'& svg': {
			fill: '$muted',

			'& path': {
				fill: '$muted',
			},
		},
	},
});

export const Content = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 6,
});
