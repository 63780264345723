import {BaseService} from '@esgi/core/service';
import moment from 'moment';
import {Class, Student, SubjectTab, SubjectType} from '@esgi/main/libs/store';

export class Service extends BaseService {
	public downloadYNScoreSessionDetails({
		testName,
		studentFirstName,
		studentLastName,
		classID,
		studentID,
		testID,
		subjectName,
	}: {
		testName: string;
		studentFirstName: string;
		studentLastName: string;
		classID: Class['id'];
		studentID: Student['id'];
		testID: number;
		subjectName: string;
	}) {
		const formattedDate = moment().format('YYYY-MM-DD');

		const filename = [
			'Test_Session_Details',
			testName,
			[studentFirstName, studentLastName].filter(Boolean).join('_'),
			`${formattedDate}.pdf`,
		]
			.join('_')
			.replace(/ /g, '_');

		return this.httpClient.ESGIApi.file('/v2/modules/session-details/download', '', filename, {
			classID,
			specialistGroupID: 0,
			studentID,
			testID,
			sessionID: 0,
			subject: subjectName,
		});
	}

	public downloadRubricSessionDetails({
		studentID,
		subjectID,
		classID,
		testSessionIDs,
		subjectType,
		testID,
	}: {
		studentID: Student['id'];
		subjectID: SubjectTab['id'];
		classID: Class['id'];
		testSessionIDs: number[];
		subjectType: SubjectType;
		testID: number;
	}) {
		const formattedDate = moment().format('YYYY_MM_DD');

		const filename = `Test_Session_Details_${formattedDate}.pdf`;

		const udaptedSubjectType: Record<SubjectType, number> = {
			[SubjectType.Personal]: 0,
			[SubjectType.Stock]: 1,
			[SubjectType.Deployed]: 2,
		};

		return this.httpClient.ESGIApi.file('/v2/modules/session-details/rubric/export', '', filename, {
			rubricID: testID,
			studentID,
			subjectID,
			subjectType: udaptedSubjectType[subjectType],
			classID,
			filename,
			testSessionIDs,
		});
	}
}
