import {EntityModel, LecturerModel, StudentResponse} from '../../shared/types';
import {FormControl} from '@esgillc/ui-kit/form';

export type SelectStudentFormControls = {
	student: FormControl<number[]>,
	group: FormControl<number>,
	class: FormControl<number>,
	specialistGroup: FormControl<number>
}

export class GridModel {
	classes: ClassResponse[];
	groups: GroupResponse[];
	students: StudentResponse[];
}

export interface GroupResponse extends EntityModel {
	id: number;
	name: string;
	classID: number;
}

export interface ClassResponse extends EntityModel {
	id: number;
	name: string;
}

export interface StudentSelectionModel {
	hasAnySelectedData: boolean;
	selectedAllStudents: boolean;
}

export interface StudentsStepInitResponse {
	students: StudentResponse[];
	classes: ClassResponse[];
	groups: GroupResponse[];
	specialistGroups: GroupResponse[];
	lecturer: LecturerModel;
}