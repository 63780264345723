import {CSSProperties, useCallback, useEffect, useRef, useState} from 'react';

export function useTestsTableStyles() {
	const tabsRef = useRef<HTMLDivElement>(null);
	const [testsTableStyles, setTestsTableStyles] = useState<CSSProperties>({});

	const updateTestsTableStyles = useCallback(() => {
		setTestsTableStyles({
			height: `calc(100% - ${tabsRef.current?.clientHeight}px)`,
		});
	}, []);

	useEffect(() => {
		updateTestsTableStyles();
		window.addEventListener('resize', updateTestsTableStyles);
		return () => window.removeEventListener('resize', updateTestsTableStyles);
	}, [updateTestsTableStyles]);

	return {
		testsTableStyles,
		tabsRef,
	};
}
