import React from 'react';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import styles from '../operation.module.less';

class Props {
	onClicked: (e: React.MouseEvent) => void;
}

export default class UnassignButton extends React.PureComponent<Props> {
	public render() {
		return <LinkButton className={styles.operationButton} onClick={(e) => this.props.onClicked(e)}
		                   title='Unassign student(s) from a class/group'>
			<svg width='16' height='16' viewBox='0 0 16 16' fill='none'
			     xmlns='http://www.w3.org/2000/svg'>
				<circle cx='8' cy='8' r='8' fill='#0088CC'/>
				<path
					d='M12.2 4.64581L11.354 3.7998L7.99999 7.15381L4.64599 3.7998L3.79999 4.64581L7.15399 7.99981L3.79999 11.3538L4.64599 12.1998L7.99999 8.84581L11.354 12.1998L12.2 11.3538L8.84599 7.99981L12.2 4.64581Z'
					fill='white'/>
			</svg>
			<div>Unassign</div>
		</LinkButton>;
	}
}
