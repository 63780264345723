import React from 'react';
import styles from '../overview.module.less';

interface Props {
	text?: string;
	onClicked: () => void;
}

export default class TemplateCard extends React.PureComponent<Props> {
	public render() {
		return <div className={styles.templateCard} onClick={() => this.props.onClicked()}>
			<svg width='131' height='117' viewBox='0 0 131 117' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M125 1.5H6C3.51472 1.5 1.5 3.51472 1.5 6V111C1.5 113.485 3.51472 115.5 6 115.5H125C127.485 115.5 129.5 113.485 129.5 111V6C129.5 3.51472 127.485 1.5 125 1.5Z' fill='white' stroke='#81D4F7' strokeWidth='3'/>
				<path d='M30 12.5H18C15.5147 12.5 13.5 14.5147 13.5 17V91C13.5 93.4853 15.5147 95.5 18 95.5H30C32.4853 95.5 34.5 93.4853 34.5 91V17C34.5 14.5147 32.4853 12.5 30 12.5Z' stroke='#81D4F7' strokeWidth='3'/>
				<path d='M113 12.5H48C45.5147 12.5 43.5 14.5147 43.5 17V23C43.5 25.4853 45.5147 27.5 48 27.5H113C115.485 27.5 117.5 25.4853 117.5 23V17C117.5 14.5147 115.485 12.5 113 12.5Z' stroke='#81D4F7' strokeWidth='3'/>
				<path d='M113 37.5H48C45.5147 37.5 43.5 39.5147 43.5 42V89C43.5 91.4853 45.5147 93.5 48 93.5H113C115.485 93.5 117.5 91.4853 117.5 89V42C117.5 39.5147 115.485 37.5 113 37.5Z' stroke='#81D4F7' strokeWidth='3'/>
			</svg>
			<div className={styles.templateName} data-cy='card-name'>{this.props.text}</div>
		</div>;
	}
}
