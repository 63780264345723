import {AppEvent} from '@esgillc/events';

@AppEvent({broadcast: true})
export class GroupCreatedEvent {
	constructor(public groupId: number, public name: string, public students: number[]) {
	}
}

@AppEvent({broadcast: true})
export class GroupChangedEvent {
	constructor(public groupId: number, public name: string, public students: number[]) {
	}
}

@AppEvent({broadcast: true})
export class GroupRemovedEvent {
	constructor(public id: number) {
	}
}
