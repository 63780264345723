import * as React from 'react';
import type {SVGProps} from 'react';

export function Multigenerate(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M6.5 19a4 4 0 0 0 4 4h11a4 4 0 0 0 4-4v-6a4 4 0 0 0-4-4h-11a4 4 0 0 0-4 4v6Zm2 0v-6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-11a2 2 0 0 1-2-2Zm2.5 7a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm1-21a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M11 16.998a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2Zm4 0a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2Zm4 0a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
