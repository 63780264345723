export enum LunchStatus
{
    Free='Free',
    Reduced='Reduced',
    Other='Other',
}

export const getName = (lunchStatus: LunchStatus) => {
	switch(lunchStatus){
		case LunchStatus.Free: return 'Free';
		case LunchStatus.Reduced: return 'Reduced';
		case LunchStatus.Other: return 'Other';
	}
};

export const lunchStatusNames = () => {
	return [
		{id: LunchStatus.Free.toString(), name: getName(LunchStatus.Free)},
		{id: LunchStatus.Reduced.toString(), name: getName(LunchStatus.Reduced)},
		{id: LunchStatus.Other.toString(), name: getName(LunchStatus.Other)},
	];
};
