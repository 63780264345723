import {fromEvent} from 'rxjs';
import {UserInfo, userStorageKey} from '@esgi/core/authentication';
import {useEffect, useMemo} from 'react';

export function useLocalStorageWatcher() {
	const storageEvent$ = useMemo(() => fromEvent<StorageEvent>(window, 'storage'), []);

	useEffect(() => {
		const storageEventSubscription = storageEvent$.subscribe(event => {
			if (event.key !== userStorageKey) {
				return;
			}

			const oldValue: UserInfo = JSON.parse(event.oldValue);
			const newValue: UserInfo = JSON.parse(event.newValue);

			if (newValue?.userID && newValue.userID !== oldValue?.userID) {
				location.reload();
			}
		});
		return () => storageEventSubscription.unsubscribe();
	}, [storageEvent$]);
}