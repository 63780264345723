import {ReportFormat, ReportType} from './types';

export const exportAllMap = [
	{
		itemText: 'One PDF file for current session',
		format: ReportFormat.SingleFile,
		type: ReportType.Current,
		dataCy: 'test-session-details-menu-item-pdf-current-session',
	},
	{
		itemText: 'One PDF file for all sessions',
		format: ReportFormat.SingleFile,
		type: ReportType.All,
		dataCy: 'test-session-details-menu-item-pdf-all-sessions',
	},
	{
		itemText: 'ZIP current session',
		format: ReportFormat.Zip,
		type: ReportType.Current,
		dataCy: 'test-session-details-menu-item-zip-current-session',
	},
	{
		itemText: 'ZIP all sessions',
		format: ReportFormat.Zip,
		type: ReportType.All,
		dataCy: 'test-session-details-menu-item-zip-all-sessions',
	},
];