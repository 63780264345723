import {styled} from '@esgi/ui/theme';
import {PanelSidebar, PanelSidebarHeader} from '../../../../../../components/panels.styled';
import {GridBox} from '@esgi/ui/layout';

export const EntireSidebarContainer = styled(PanelSidebar, {
	gridArea: 'markingPeriodSidebar',
	gridTemplateRows: 'auto 1fr',
	paddingRight: 19,
});

export const EntireSidebarHeader = styled(PanelSidebarHeader, {
	minHeight: 24,
});

export const ChildrenBox = styled(GridBox, {
	justifyItems: 'center',
	gap: 20,
	overflow: 'hidden',
	gridTemplateRows: 'auto 1fr',
});
