import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const TooltipContainer = styled(GridBox, {
	borderRadius: 8,
	border: '1px solid $tertiaryMuted',
	background: 'rgba(255, 251, 250, 0.8)',
	boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(230, 219, 238, 0.40)',
	padding: 12,
	gap: 12,
});

export const SessionTimeInfoBox = styled(GridBox, {
	alignItems: 'center',
	gridAutoFlow: 'column',
	gap: 6,
});

export const LineBox = styled(Box, {
	width: '100%',
	height: 1,
	borderRadius: 1,
	backgroundColor: '$tertiaryMuted',
});
