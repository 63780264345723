import React, {createRef} from 'react';
import {ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

class Props{
	onClose: () => void;
}
export default class PreAssessLoginAlert extends React.Component<Props> {
	private modalManagerRef: ModalManagerRefObject = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef} unsafeDesiredZIndex={11000}>
            <Alert.Body>
                You are assessing in a Pre-Assess account. In order for teachers to view this data, they will need to make sure all tests have been added to a subject tab within their account. Managed Subject Tabs are recommended.
            </Alert.Body>
            <Alert.Footer>
                <Buttons.Text onClick={() => this.modalManagerRef.current.close(this.props.onClose)}>
                    CONTINUE
                </Buttons.Text>
            </Alert.Footer>
        </Alert>;
	}
}
