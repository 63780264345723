import {useCallback, useEffect} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {FirstStep} from './components/first-step';
import {SecondStep} from './components/second-step';
import {CreateAssignmentTeacherProps} from './types/root-component-types';
import {ThirdStepTeacher} from './components/third-step/third-step-teacher';
import {CreateAssignmentContent} from './components/create-assignment-content';
import {CreateAssignmentTeacherService} from './services/create-assignment-teacher-service';
import {useService} from '@esgi/core/service';

export function CreateAssignmentTeacher({
	extractedData,
	extractData,
	setLoaded,
	onSetStep,
	isFirstStepValid,
	onCancel,
	assignmentID,
	goToAssignmentList,
	...createAssignmentProps
}: CreateAssignmentTeacherProps) {
	const service = useService(CreateAssignmentTeacherService);

	const formGroup = service.nameFormGroup;

	const selectedTestsIds = useBehaviorSubject(service.selectedTestsIds);
	const selectedTestsFilters = useBehaviorSubject(service.selectedTestsFilters);

	const selectedStudents = useBehaviorSubject(service.selectedStudents);

	const init = useCallback(() => {
		service.init(assignmentID, goToAssignmentList, extractedData).subscribe(() => setLoaded(true));

		onSetStep();
	}, [onSetStep, service]);

	useEffect(init, []);

	const onStudentCredentialClicked = () => {
		extractData?.({
			name: formGroup.controls.name.value,
			description: formGroup.controls.description.value,
			selectedTestsIds,
			selectedStudents,
			selectedTestsFilters,
		});
	};

	const firstStepValid = isFirstStepValid(formGroup.controls.name.status);

	return (
		<CreateAssignmentContent
			service={service}
			selectedStudents={selectedStudents}
			extractData={extractData}
			onCancel={onCancel}
			setLoaded={setLoaded}
			{...createAssignmentProps}
		>
			<FirstStep formGroup={formGroup} />
			<SecondStep service={service} disabled={!firstStepValid} onFindMoreClose={init} onCancel={onCancel} />
			<ThirdStepTeacher
				disabled={!firstStepValid}
				service={service}
				onStudentCredentialClicked={onStudentCredentialClicked}
			/>
		</CreateAssignmentContent>
	);
}
