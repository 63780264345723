import styles from 'modules/reports/student-detail-report/components/settings-modal/components/settings-modal-header/styles.module.less';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import React from 'react';

interface Props {
	showMenu: boolean
	download: (zip: boolean) => void;
	closeMenu: () => void;
}

export function MenuForOneStudent({showMenu, download, closeMenu}: Props){
	return <Menu show={showMenu} keepFocusInside onClickOutside={closeMenu} onEscPressed={closeMenu}
						 className={styles.menu}>
	<MenuItem onSelect={() => download(false)}>
							<span>
								<div>One PDF file</div>
								<div>for student</div>
							</span>
	</MenuItem>
</Menu>;
}