import {BehaviorSubject, concatMap, merge, Observable, Subject} from 'rxjs';
    import {filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {
	SubjectCreatedEvent,
	SubjectHiddenEvent, SubjectReorderedEvent, SubjectUnhiddenEvent, SubjectUpdatedEvent,
	TestAddedToSubjectEvent, TestMovedBetweenSubjectsEvent,
	TestRemovedFromSubjectEvent,
} from 'api/entities/events/subject';
import {SchoolYearChangedEvent} from 'modules/school-year';
import {SubjectType} from '@esgi/core/enums';
import {BroadcastEventManager, EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType} from '@esgi/core/authentication';

export class SubjectsService extends BaseService {
	private readonly currentUser = userStorage.get();
	private _allSubjects: Observable<ISubjectInfo[]> = null;
	private readonly _deployedTests$: Observable<IDeployedTest[]> = null;
	private readonly _selfSubjects: BehaviorSubject<SubjectItem[]> = new BehaviorSubject(null);
	private readonly eventBus = new EventBusManager();
	private readonly broadcastEventBus = new BroadcastEventManager();

	private readonly reloadEmitter$ = new Subject<void>();
	public isLoading = new BehaviorSubject<boolean>(true);

	constructor(private controller: string) {
		super();
		this.eventBus.subscribe(TestAddedToSubjectEvent, (args: TestAddedToSubjectEvent) => this.onTestAddedHandler(args));
		this.eventBus.subscribe(TestRemovedFromSubjectEvent, (args: TestRemovedFromSubjectEvent) => this.onTestRemovedHandler(args));
		this.eventBus.subscribe(SubjectCreatedEvent, (args: SubjectCreatedEvent) => this.onSubjectCreatedHandler(args));

		const request$ = this.httpClient.ESGIApi.get<IInitResponse>(this.controller, 'init');

		const data$ = merge(request$, this.reloadEmitter$.pipe(concatMap(() => request$)))
			.pipe(
				takeUntil(this.destroy$),
				shareReplay(),
			);

		this._deployedTests$ = data$.pipe(map(r => r.deployedTests));

		data$.pipe(map(r => r.subjects)).subscribe((s) => this._selfSubjects.next(s));

		this.broadcastEventBus.aggregate({
			SchoolYearChangedEvent,
			SubjectCreatedEvent,
			SubjectHiddenEvent,
			SubjectReorderedEvent,
			SubjectUnhiddenEvent,
			SubjectUpdatedEvent,
			TestRemovedFromSubjectEvent,
			TestAddedToSubjectEvent,
			TestMovedBetweenSubjectsEvent,
		}).subscribe((aggregation) => {
			this.reloadEmitter$.next();
		});
	}

	public get deployedTests$(): Observable<IDeployedTest[]> {
		return this._deployedTests$;
	}

	public get selfSubjects$(): Observable<SubjectItem[]> {
		return this._selfSubjects.pipe(filter(s => !!s));
	}

	public get subjectInfos$(): Observable<ISubjectInfo[]> {
		if (!this._allSubjects) {
			const request$ = this.httpClient.ESGIApi.get<ISubjectInfoResponse>(this.controller, 'subject-tabs')
				.pipe(map(r => {
					r.subjects.forEach(x => x.subjectType = SubjectType.Personal);
					r.deployedSubjects.forEach(x => x.subjectType = SubjectType.Deployed);
					r.stockSubjects.forEach(x => x.subjectType = SubjectType.Stock);

					return [UserType.T, UserType.ISS, UserType.ISD, UserType.PA].indexOf(this.currentUser.userType) !== -1
						? r.subjects.concat(r.deployedSubjects)
						: r.deployedSubjects.concat(r.stockSubjects).filter(x => !x.hidden);
				}), tap((response) => {
					if (response) {
						this.isLoading.next(false);
					}
				}));

			this._allSubjects = merge(
				request$,
				this.reloadEmitter$.pipe(concatMap(() => request$)),
			).pipe(shareReplay());
		}
		return this._allSubjects;
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
		this.broadcastEventBus.destroy();
	}

	private onTestAddedHandler(args: TestAddedToSubjectEvent): void {
		const subjects = this._selfSubjects.value;
		const subject = subjects.find(s => s.id === args.subjectID && s.subjectType === args.subjectType);
		if (subject) {
			const newState = subjects.map(s => s === subject ? {
				...subject,
				tests: [...subject.tests, {
					addedAt: new Date().toString(),
					testID: args.testID,
				}],
			} : s);
			this._selfSubjects.next(newState);
		}
	}

	private onTestRemovedHandler(args: TestRemovedFromSubjectEvent): void {
		const subjects = this._selfSubjects.value;
		const subject = subjects.find(s => s.id === args.subjectID && s.subjectType === args.subjectType);
		if (subject) {
			const newState = subjects.map(s => s === subject ? {
				...subject,
				Tests: subject.tests.filter(t => t.testID !== args.testID),
			} : s);

			this._selfSubjects.next(newState);
		}
	}

	private onSubjectCreatedHandler(args: SubjectCreatedEvent): void {
		const subjects = this._selfSubjects.value;
		const subject: SubjectItem = {
			subjectType: args.properties.subjectType,
			id: args.id,
			name: args.properties.name,
			tests: [],
			hidden: !args.published,
		};

		this._selfSubjects.next(subjects.concat([subject]));
	}
}

export interface IDeployedTest {
	id: number;
	school: boolean;
	district: boolean;
}

export class SubjectItem {
	constructor(public id: number, public name: string, public tests: ITest[], public subjectType: SubjectType, public hidden: boolean) {
	}
}

export interface ITest {
	testID: number;
	addedAt: string;
}

interface IInitResponse {
	subjects: SubjectItem[];
	deployedTests: IDeployedTest[];
}

export interface ISubjectInfo {
	id: number;
	name: string;
	hidden: boolean;
	subjectType: SubjectType;
}

interface ISubjectInfoResponse {
	subjects: ISubjectInfo[];
	deployedSubjects: ISubjectInfo[];
	stockSubjects: ISubjectInfo[];
}
