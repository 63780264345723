import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Wrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	height: '100%',
	overflow: 'hidden',
});

export const TableWrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	height: '100%',
	overflow: 'hidden',
});

export const Body = styled(GridBox, {
	overflow: 'hidden',
});

export const Table = styled('table');

export const MainTable = styled(Table, {
	width: '100%',
	whiteSpace: 'nowrap',
	border: '0',
});

export const TableHeader = styled('thead', {
	width: '100%',
	whiteSpace: 'nowrap',
});

export const TableBody = styled('tbody', {
	width: '100%',
	whiteSpace: 'nowrap',
});

export const Row = styled('tr', {
	variants: {
		dataBody: {
			true: {
				fontWeight: 'bold',
			},
		},
		markingPeriods: {
			true: {
				width: '100px',
			},
		},
		bgLightgray: {
			true: {
				backgroundColor: '#f8f8f8',
			},
		},
	},
});

export const HeaderRow = styled('tr', {
	fontWeight: 'bold',
	height: '80px',
	border: '0',
});

export const Cell = styled('td', {
	variants: {
		testInfo: {
			true: {
				padding: '0 10px 0 10px',
				border: '1px solid #bfbfbf',
				fontWeight: 'normal',

				'> table': {
					width: '100%',
					'td': {
						verticalAlign: 'bottom',
						whiteSpace: 'nowrap',
						border: '1px solid #bfbfbf',
					},
				},
			},
		},
		testName: {
			true: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '65ch',
			},
		},
		simple: {
			true: {
				width: '42px',
				minWidth: '42px',
				height: '42px',
				fontWeight: 'normal',
				border: '1px solid #bfbfbf',
			},
		},
		bgGray: {
			true: {
				backgroundColor: '#e0e0e0',
			},
		},
		studentNameHeader: {
			true: {
				textAlign: 'left',
				minWidth: '200px',
				maxWidth: '300px',
				padding: '5px',
				border: '1px solid #bfbfbf',
			},
		},
		sortable: {
			true: {
				cursor: 'pointer',
			},
		},
		studentName: {
			true: {
				textAlign: 'left',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				minWidth: '200px',
				maxWidth: '300px',
				width: '300px',
				padding: '5px',
			},
		},
		markingPeriod: {
			true: {
				'sup': {
					fontSize: '8px',
					top: '-5px',
				},
			},
		},
	},
});