import {BaseGroupService} from 'modules/forms/admin-group-forms/services/base-group-service';
import {Observable, tap} from 'rxjs';
import {PreModel} from 'modules/forms/admin-group-forms/models/types';
import {InModel} from '@esgi/deprecated/elements/checkbox-list';
import {InitCreateModel, InitEditModel} from 'modules/forms/admin-group-forms/models/group-models';

export class SchoolsGroupService extends BaseGroupService {
	public districtName: string;

	public initCreate(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.get<InitCreateModel>('schools-group', 'init-create', {
			districtID: model.districtID,
		}).pipe(tap(resp => {
			const schools = resp.items.map(t => {
				const u: InModel = {
					label: t.name,
					id: t.id,
					checked: false,
				};
				return u;
			});
			this.districtName = resp.name;
			this.availableItems$.next(schools);
		})).asObservable();
	}

	public initEdit(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.get<InitEditModel>('schools-group', 'init-edit', {
			groupID: model.groupID,
			districtID: model.districtID,
		}).pipe(tap(resp => {
			const availableStudents = resp.availableItems.map(t => {
				const u: InModel = {
					label: t.name,
					id: t.id,
					checked: false,
				};
				return u;
			});
			const studentsInGroup = resp.itemsInGroup.map(t => {
				const u: InModel = {
					label: t.name,
					id: t.id,
					checked: false,
				};
				return u;
			});
			this.districtName = resp.name;
			this.availableItems$.next(availableStudents);
			this.itemsInGroup$.next(studentsInGroup);
		})).asObservable();
	}

	public create(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.post<any>('schools-group', 'create', {
			userID: model.userID,
			name: model.groupName,
			schoolIDs: this.itemsInGroup$.value.map(x => x.id),
		}).asObservable();
	}

	public update(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.post<any>('schools-group', 'update', {
			userID: model.userID,
			groupID: model.groupID,
			districtID: model.districtID,
			groupName: model.groupName,
			schoolIDs: this.itemsInGroup$.value.map(x => x.id),
		}).asObservable();
	}

	public remove(model: PreModel): Observable<any> {
		return this.httpClient.ESGIApi.post<number>('schools-group', 'remove', {groupID: model.groupID}).asObservable();
	}
}
