import $ from 'jquery';

/**
 * @deprecated
 **/
export function resolvedPromise(value?) {
	return $.Deferred().resolve(value).promise();
}

/**
 * @deprecated
 **/
export function rejectedPromise(value?) {
	return $.Deferred().reject(value).promise();
}
