import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {SubjectType} from '@esgi/core/enums';
import {EventBusManager} from '@esgillc/events';
import {TestHistorySelectedChangedEvent, TestHistorySubjectChangedEvent} from '../events';
import {TestHistoryService} from './test-history-service';
import {TestHistoryAllStudentsService} from './test-history-all-students-service';
import {mapToEnum} from 'shared/utils';
import {
	BackgroundGenerationModel,
	BackgroundGenerationRequest,
	ReportFormat, ReportType,
} from 'modules/reports/test-history/models';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';

export class HeaderService extends BaseService {
	public visibleDownload = new BehaviorSubject<boolean>(true);
	public visibleDownloadAllStudents = new BehaviorSubject<boolean>(true);
	public showNoGraphAlert = new BehaviorSubject<boolean>(false);
	public selectedSubjectId = new BehaviorSubject<number>(null);
	public selectedSubjectType = new BehaviorSubject<string>(null);
	public selectedClassId = new BehaviorSubject<number>(null);
	public selectedGroupId = new BehaviorSubject<number>(null);
	public selectedSpecialistGroupId = new BehaviorSubject<number>(null);
	public selectedStudentId = new BehaviorSubject<number>(null);
	private downloadInProgress = new BehaviorSubject<boolean>(false);
	private readonly eventBus = new EventBusManager();

	constructor(
		private testHistoryService: TestHistoryService,
		private testHistoryAllStudentsService: TestHistoryAllStudentsService,
	) {
		super();
	}

	public startReportBackgroundGeneration(format: ReportFormat, type: ReportType) {
		this.testHistoryAllStudentsService
			.getExportOptions(format, type)
			.subscribe((args) => this.startBackgroundGeneration(args));
	}

	public exportReport(format: ReportFormat) {
		this.testHistoryService
			.getExportOptions(format)
			.subscribe((args) => this.startBackgroundGeneration(args));
	}


	public dispatchHierarchyEvents() {
		if (
			this.selectedSubjectId.value
			&& this.testHistoryService.subjectID !== this.selectedSubjectId.value
		) {
			this.eventBus.dispatch(
				TestHistorySubjectChangedEvent,
				new TestHistorySubjectChangedEvent(
					mapToEnum(this.selectedSubjectType.value, SubjectType),
					this.selectedSubjectId.value,
				),
			);
		}

		if (this.selectedSpecialistGroupId.value) {
			this.selectedGroupId.next(this.selectedSpecialistGroupId.value);
		}

		if (
			this.selectedClassId.value
			|| this.selectedGroupId.value
			|| this.selectedStudentId.value
		) {
			this.eventBus.dispatch(
				TestHistorySelectedChangedEvent,
				new TestHistorySelectedChangedEvent(
					this.selectedClassId.value,
					this.selectedGroupId.value,
					this.selectedStudentId.value,
				),
			);
		}
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	private downloadReport(action, payload) {
		if (this.downloadInProgress.value) {
			return;
		}

		this.downloadInProgress.next(true);
		this.httpClient.ESGIApi.file(
			'reports/test-history',
			action,
			'',
			payload,
		).subscribe(() => this.downloadInProgress.next(false));
	}

	private startBackgroundGeneration(model: BackgroundGenerationRequest) {
		this.httpClient.ESGIApi.post(
			'reports/test-history',
			'start-background-generation',
			model,
		).subscribe((response: BackgroundGenerationModel) =>
			this.eventBus.dispatch(BackgroundDownloadManagerEvents.Start, {
				reportGuid: response.reportGuid,
				fileType: this.getFileType(model.fileFormat),
				displayRemainingText: true,
			}),
		);
	}

	private getFileType(fileType: ReportFormat): string {
		if (fileType === ReportFormat.Excel) {
			return 'XLS';
		}
		return 'PDF';
	}
}
