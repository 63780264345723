import {HierarchySnapshot} from 'modules/hierarchy/models';
import {SubjectType} from '@esgi/core/enums';
import {Renderable, ModalCloser, ModalWindow, observable} from '@esgi/deprecated/knockout';
import {Form} from './grade-scale-report';
import {GradeReportTemplate} from './template';
import {GradeScaleHierarchyLevel} from './models';
import {UserType} from '@esgi/core/authentication';

export interface IModalOptions {
	level: GradeScaleHierarchyLevel,
	subjectId: number;
	subjectType: string;
	subjectLevel: string;
	testIds?: number[];
	userType?: UserType
}

export class GradeScaleReportModal {
	events = {
		subjectChanged: (callback: (e: Event, subject: {SubjectID: number, SubjectType: SubjectType}) => any) => {
			// @ts-ignore
			$(this).on('subjectChanged', callback);
		},
		closed: (callback: () => any) => {
			$(this).on('closed', callback);
		},
		classChanged: (callback: (e: Event, id: number) => any) => {
			// @ts-ignore
			$(this).on('classChanged', callback);
		},
		groupChanged: (callback: (e: Event, id: number) => any) => {
			// @ts-ignore
			$(this).on('groupChanged', callback);
		},
		schoolChanged: (callback: (e: Event, schoolId) => any) => {
			// @ts-ignore
			$(this).on('schoolChanged', callback);
		},
		teacherChanged: (callback: (e: Event, teacherId) => any) => {
			// @ts-ignore
			$(this).on('teacherChanged', callback);
		},
		teacherClassChanged: (callback: (e: Event, info: { classID: number, teacherID: number }) => any) => {
			$(this).on('teacherClassChanged', callback);
		},
	};

	constructor(
		public options: IModalOptions,
		private hierarchy: HierarchySnapshot,
	) {}

	public load() {
		const header = new ModalHeader();

		header.events.excel(() => {
			gsr.view.downloadExcel();
		});
		header.events.pdf(() => {
			gsr.view.downloadPdf();
		});
		header.events.closed(() => {
			gsr.view.reportClosed();
			modal.close();
			gsr.close();
		});
		header.events.goingBack(() => {
			gsr.view.reportClosed();
			modal.close(true);
		});

		const gsr = new Form(this.options.level, this.hierarchy);
		gsr.subjectId = this.options.subjectId;
		gsr.subjectType = this.options.subjectType;
		gsr.subjectLevel = this.options.subjectLevel;
		gsr.selectedTestIds = this.options.testIds;
		gsr.userType = this.options.userType;
		gsr.updateDoNotAllowEditSubjectByAdmin();

		const modal = new ModalWindow(gsr, {
			className: 'grade-report-dialog component-scroll blue-header',
			allowClose: false,
			showFooter: false,
			responsive: true,
			header,
		});

		modal.load();

		gsr.events.subjectChanged((event, subject) => {
			$(this).trigger('subjectChanged', {SubjectID: subject.subjectId, SubjectType: subject.subjectType});
		});
		gsr.events.classChanged((event, classId) => {
			$(this).trigger('classChanged', classId);
		});
		gsr.events.groupChanged((event, specialistGroupId) => {
			$(this).trigger('groupChanged', specialistGroupId);
		});
		gsr.events.schoolChanged((e, schoolID) => $(this).trigger('schoolChanged', schoolID));
		gsr.events.teacherChanged((e, teacherID) => $(this).trigger('teacherChanged', teacherID));
		gsr.events.loaded(() => {
			header.reportName = gsr.data.reportName;
			header.allowExportPDF = (gsr.data.defaultRows.length * (gsr.data.tests().flatMap(x => x.periods).length + gsr.data.tests().length)) < 80000 ;
		});
		gsr.events.aborted(() => {
			modal.close();
		});
		gsr.events.emptyReport((event, emptyReport) => {
			header.showDownload = !emptyReport;
		});
		gsr.events.closed(() => {
			$(this).trigger('closed');
		});
		gsr.events.teacherClassChanged((event, info) => {
			$(this).trigger('teacherClassChanged', info);
		});
	}
}

class ModalHeader extends Renderable {

	@observable()
	reportName: string = null;

	@observable()
	showDownload: boolean = false;

	@observable()
	allowExportPDF: boolean = true;

	closer: ModalCloser = new ModalCloser({
		click: () => $(this).trigger('closed'),
	});

	events = {
		closed: (callback) => {
			$(this).on('closed', callback);
		},
		pdf: (callback) => {
			$(this).on('pdf', callback);
		},
		excel: (callback) => {
			$(this).on('excel', callback);
		},
		goingBack: (callback) => {
			$(this).on('goingBack', callback);
		},
	};

	template = () => {
		return GradeReportTemplate.renderHeader();
	};

	public downloadPdf() {
		$(this).trigger('pdf');
	}

	public downloadExcel() {
		$(this).trigger('excel');
	}

	public goBackClicked() {
		$(this).trigger('goingBack');
	}
}
