import {styled} from '@esgi/ui/theme';
import {Alert} from '@esgi/ui/alert';
import {Link} from 'react-router-dom';

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: 20,
	width: '560px',
});

export const CustomLink = styled(Link, {
	color: '$primary',
	marginLeft: '5px',
	cursor: 'pointer',
	'&:hover': {
		textDecoration: 'underline',
	},
});