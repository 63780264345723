import {useSubjects, Student, Class} from '@esgi/main/libs/store';
import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

type ClassBucket = Omit<Class, 'studentIDs'> & {
	students: Student[];
	isListOpen: boolean;
};

export const useClassBucketFilteredBySubject = (
	classBuckets: ClassBucket[]
) => {
	const {subjectID} = useParams();
	const [{data: subjects}] = useSubjects();

	const buckets: ClassBucket[] = useMemo(() => {
		const subjectGradeLevels = new Set(
			subjects.find((subject) => subject.id === Number(subjectID))?.gradeLevels
		);
		if (subjectGradeLevels.size) {
			const filteredClassBuckets = classBuckets.map((bucket) => {
				return {
					...bucket,
					students: bucket.students.filter((el) =>
						subjectGradeLevels.has(el.gradeLevelID)
					),
				};
			});
			return filteredClassBuckets;
		} else {
			return classBuckets;
		}
	}, [subjectID, subjects, classBuckets]);
	return buckets;
};
