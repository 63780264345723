import {createContext, useContext} from 'react';
import {useService} from '@esgi/core/service';
import {WritingPracticeService} from './services';

export const useWritingPractice = () => {
	const service = useService(WritingPracticeService);

	return {service};
};

export const WritingPracticeContext = createContext(null);
export const useWritingPracticeService = () =>
	useContext<WritingPracticeService>(WritingPracticeContext);
