import {useMemo} from 'react';
import {alertColorsConfig} from '@esgi/ui/alert';
import {AlertContentType, ValidationStatus} from './types';

export function useAlertColorConfig({alertType}: {alertType: AlertContentType}) {
	return useMemo(() => {
		switch (alertType) {
			case ValidationStatus.IncorrectFileType:
			case ValidationStatus.MissingRequiredInformation:
			case ValidationStatus.InvalidField:
			case ValidationStatus.MaximumStudents:
			case ValidationStatus.PurchasingStudents:
			case ValidationStatus.ConflictExists:
			case ValidationStatus.DuplicateIDInFile:
			case ValidationStatus.UnsupportedFieldName:
				return alertColorsConfig.negative;

			case ValidationStatus.Complete:
				return alertColorsConfig.positive;
			case ValidationStatus.CompleteWithStudentsExistInClass:
				return alertColorsConfig.positive;

			default:
				return alertColorsConfig.neutral;
		}
	}, [alertType]);
}
