import {SortDirection} from '@esgi/contracts/esgi/types/esgi.enums/sort-direction';
import {
	ExtendedBankQuestionModel,
	FilterModel,
	SortDescriptor,
} from '../types';

export class LocalFilterBuilder {
	private filters: ((x: ExtendedBankQuestionModel) => boolean)[] = [];
	private sorter: (
		a: ExtendedBankQuestionModel,
		b: ExtendedBankQuestionModel
	) => number = null;
	private sortDirection: SortDirection;

	public applyFilters(filters: FilterModel) {
		if (filters.searchText) {
			const terms = filters.searchText.toLocaleLowerCase().split(' ');
			this.filters.push((item) =>
				terms.some((term) => item.name.toLocaleLowerCase().includes(term))
			);
		}
		if (filters.contentAreaIDs?.length) {
			this.filters.push((item) =>
				filters.contentAreaIDs.includes(item.contentAreaID)
			);
		}

		if (filters.gradeLevels?.length) {
			this.filters.push((item) =>
				item.gradeLevelIDs.some((id) => filters.gradeLevels.includes(id))
			);
		}
		return this;
	}

	public applySort(sorting: SortDescriptor | null) {
		let bufferSorting: (
			a: ExtendedBankQuestionModel,
			b: ExtendedBankQuestionModel
		) => number = null;
		switch (sorting?.field) {
			case 'CreatedDate': {
				bufferSorting = (a, b) => a.id - b.id;
				break;
			}
			case 'ShortTitle': {
				bufferSorting = (a, b) => (a.name >= b.name ? 1 : -1);
				break;
			}
			case 'ContentArea': {
				bufferSorting = (a, b) =>
					a.contentAreaName >= b.contentAreaName ? 1 : -1;
				break;
			}
			case 'GradeLevel': {
				bufferSorting = (a, b) =>
					Math.max(...(a.gradeLevelIDs ?? [0])) -
					Math.max(...(b.gradeLevelIDs ?? [0]));
				break;
			}
			case 'StateStandard': {
				bufferSorting = (a, b) =>
					a.stateStandardName >= b.stateStandardName ? 1 : -1;
				break;
			}
			default:
				break;
		}
		this.sortDirection = sorting.direction;
		this.sorter = bufferSorting;
		return this;
	}

	public build(data: ExtendedBankQuestionModel[]) {
		let buffer = [...data];
		for (const filter of this.filters) {
			buffer = buffer.filter(filter);
		}
		if (this.sorter) {
			if (this.sortDirection === SortDirection.Desc) {
				buffer = buffer.sort((a, b) => -this.sorter(a, b));
			} else {
				buffer = buffer.sort((a, b) => this.sorter(a, b));
			}
		}
		return buffer;
	}
}