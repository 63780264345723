import {Dispatch, SetStateAction, useCallback, useState, useMemo} from 'react';
import {Test} from '../service/types';
import {uniq} from 'underscore';

export function useTestSelection(tests: Test[]): {
	selectedTests: number[],
	selectedTestList: Test[],
	select: (testID: number) => void,
	toggleAll: VoidFunction,
	setSelectedTests: Dispatch<SetStateAction<number[]>>
} {
	const [selectedTests, setSelectedTests] = useState<number[]>([]);
	const [selectedList, setSelectedList] = useState<Test[]>([]);

	const select = useCallback((test: number) => {
		setSelectedTests((values) => {
			if (values.includes(test)) {
				return values.filter(s => s !== test);
			} else {
				setSelectedList([
					...selectedList,
					tests.find(({id}) => id === test),
				]);
				return [...values, test];
			}
		});
	}, [tests, selectedList]);

	const toggleAll = useCallback(() => {
		setSelectedTests((values) => {
			const allTestsIDs = tests.filter(({disabled}) => !disabled).map(({id}) => id);
			if (!allTestsIDs.length) {
				return [...values];
			}

			const allSelected = allTestsIDs?.every(s => values?.includes(s));

			if (allSelected) {
				return [...new Set(values.filter((id) => !allTestsIDs.includes(id)))];
			} else {
				setSelectedList([
					...selectedList,
					...tests,
				]);
				return [...new Set([...values, ...allTestsIDs])];
			}
		});
	}, [tests, selectedList]);

	const selectedTestList = useMemo(
		() => uniq(selectedList.filter(({id}) => selectedTests.includes(id)), ({id}) => id),
		[selectedList, selectedTests]
	);

	return {
		selectedTests,
		selectedTestList,
		setSelectedTests,
		select,
		toggleAll,
	};
}
