import * as React from 'react';
import type {SVGProps} from 'react';

export function Correct(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M20.092 12c-.23 0-.47.084-.646.273l-4.238 4.618c-.23.25-.463.204-.645-.09l-.898-1.459c-.275-.447-.85-.571-1.262-.274-.413.299-.528.92-.253 1.367l.898 1.458c.81 1.317 2.39 1.484 3.424.366l4.265-4.588c.35-.38.35-1.018 0-1.398a.874.874 0 0 0-.645-.273Z'
			/>
		</svg>
	);
}
