import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

class Props {
	selected: boolean;
	title: string;
	onSelect: () => any;
}

export class BoxItem extends React.PureComponent<Props> {
	protected select(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();
		this.props.onSelect();
	}

	render() {
		return <OnHoverTooltip message={this.props.title}>
			<div className={'item' + (this.props.selected ? ' selected' : '')} data-name={this.props.title}>
				<a href='#' className='title' onClick={(e) => this.select(e)}>{this.props.title}</a>
			</div>
		</OnHoverTooltip>;
	}
}