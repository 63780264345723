import {SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const SelectableListGroup = styled(SelectableList.Group, {
	display: 'grid',
	gap: '$2', 
});

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	gap: '$2',
	alignItems: 'center',
	padding: '8px 12px',

	variants: {
		disabled: {
			true: {
				backgroundColor: '$vivid',
				cursor: 'default',
				pointerEvents: 'none',
				[`& ${Text}`]: {
					color: '$muted !important',
				},
			},
		},
	},
});

export const StyledText = styled(Text, {
	variants: {
		selected: {
			false: {
				color: '$neutral6 !important',
			},
			true: {
				color: 'currentСolor',
			},
		},
	},
});
