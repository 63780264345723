import {ArrowDirection, InModel} from 'modules/forms/group-form/types';


export const checkboxOptionsMode = (students: InModel[]): boolean | undefined => !students.length ? false : students.every(s => s.checked) ?
	true : students.every(s => !s.checked) ? false : undefined;

export const getArrowDirection = (availableStudents: InModel[], studentsInGroup: InModel[]): ArrowDirection => {
	const checkedAvailableStudents = availableStudents.filter(x => x.checked).length > 0;
	const checkedStudentsInGroup = studentsInGroup.filter(x => x.checked).length > 0;

	if (!checkedAvailableStudents && !checkedStudentsInGroup) {
		return ArrowDirection.None;
	}
	if (checkedAvailableStudents) {
		return ArrowDirection.Right;
	}
	return ArrowDirection.Left;
};
