import {ComponentPropsWithoutRef} from 'react';
import {VariantProps} from '@stitches/react';
import {Text} from '@esgi/ui/typography';
import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

const EmptyContent = styled(GridBox, {
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	backgroundColor: '$background',
	paddingTop: 11,
	paddingBottom: 11,
	paddingLeft: 20,
	paddingRight: 20,
	height: 'max-content',
	justifyContent: 'center',
	gap: 6,
	textAlign: 'center',

	variants: {
		size: {
			full: {
				width: '100%',
			},
		},

		position: {
			center: {
				alignSelf: 'center',
				justifySelf: 'center',
			},
		},
	},
});


type Props = {
	templateText: string;
	additionalText?: string;
	size?: Extract<VariantProps<typeof EmptyContent>['size'], string>;
	position?: Extract<VariantProps<typeof EmptyContent>['position'], string>
	dataCy?: string
} & ComponentPropsWithoutRef<'div'>;

export function EmptyContentTemplate({templateText, additionalText, dataCy ='empty-content-template', ...props}: Props) {
	return (
		<EmptyContent {...props} dataCy={dataCy}>
			<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-text`}>
				{templateText}
			</Text>
			{additionalText && (
				<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-additional-text`}>
					{additionalText}
				</Text>
			)}
		</EmptyContent>
	);
}
