import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const CenteredText = styled(Text, {
	textAlign: 'center',
	padding: '0px 6px',
	width: 280,
	variants: {
		isTablet: {
			true: {
				width: '100%',
				height: '100%',
			},
		},
	},
});
