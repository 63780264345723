import moment from 'moment';
import {useMemo} from 'react';
import {momentDatesDiffInDays} from '../helpers/moment-dates-diff-in-days';
import {addDaysToMomentDate} from '../helpers/add-days-to-moment-date';
import {SchoolYearModel, TrackType} from '../../../types';

type MonthModel = {
	width: number;
	name: string;
};

export function useTrackStartEndDates({
	schoolYearTypes,
	selectedTrackType,
	setEndDate,
	setMonthsList,
	setStartDate,
}: {
	schoolYearTypes: SchoolYearModel[];
	selectedTrackType: TrackType;
	setStartDate: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>;
	setEndDate: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>;
	setMonthsList: React.Dispatch<React.SetStateAction<MonthModel[]>>;
}) {
	return useMemo<{sliderMinDays: number; sliderMaxDays: number}>(() => {
		const yearInfo = schoolYearTypes.find(({name}) => name === selectedTrackType);

		if (!yearInfo) {
			return {
				sliderMinDays: 0,
				sliderMaxDays: 0,
			};
		}

		const {minDate, maxDate} = yearInfo;

		const startDate = moment(minDate);
		const endDate = moment(maxDate);

		const startEndDifferent = momentDatesDiffInDays(endDate, startDate);

		let iteratedDate = addDaysToMomentDate(startDate, 1);
		const monthsList: MonthModel[] = [];

		while (momentDatesDiffInDays(endDate, iteratedDate) >= 0) {
			const currentMonth = iteratedDate.format('MMM');
			const daysInMonth = iteratedDate.daysInMonth();

			monthsList.push({name: currentMonth, width: (daysInMonth / startEndDifferent) * 100});

			iteratedDate = iteratedDate.add(1, 'month');
		}

		setStartDate(startDate);
		setEndDate(endDate);
		setMonthsList(monthsList);

		return {
			sliderMinDays: 0,
			sliderMaxDays: startEndDifferent,
		};
	}, [schoolYearTypes, selectedTrackType, setEndDate, setMonthsList, setStartDate]);
}
