import {createContext} from 'react';
import {RootContext} from './types';
import {noop} from 'underscore';

export const DroppableTextEditorContext = createContext<RootContext>({
	onDrag: noop,
	onDrop: noop,
	onKeyDown: noop,
	editorRef: null,
	setDraggedElement: noop,
	value: '',
	onChange: noop,
	disabled: false,
	error: false,
	errorContent: '',
	placeholder: '',
});