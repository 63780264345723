import {styled} from '@esgi/ui/theme';
import {FlexBox, Box} from '@esgi/ui/layout';

export const DraggableButtonsContainer = styled(FlexBox, {
	flexWrap: 'wrap',
	'> span': {
		margin: '4px',
	},
});

export const DraggableContainer = styled(Box, {
	div: {
		minHeight: '250px',
	},
});
