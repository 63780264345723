import {PropsWithChildren} from 'react';
import {animated} from 'react-spring';
import {CSS, styled} from '@stitches/react';
import {useNavigationBarContext} from '../../context';
import {NavigationBarOrientation} from '../../types';

type NavigationBarBottomProps = PropsWithChildren & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export default function NavigationBarBottom({children, dataCy, ...props}: NavigationBarBottomProps) {
	const {orientation} = useNavigationBarContext();

	return (
		<Bottom data-cy={dataCy ?? 'ui-kit-navigation-bar-bottom'} orientation={orientation} {...props}>
			{children}
		</Bottom>
	);
}

const Bottom = styled(animated.div, {
	display: 'flex',
	alignItems: 'center',
	variants: {
		orientation: {
			[NavigationBarOrientation.Vertical]: {
				marginTop: 'auto',
				width: '100%',
				padding: '0 8px 0 8px',
			},
			[NavigationBarOrientation.Horizontal]: {
				flexDirection: 'row',
				marginRight: '0',
				'& svg': {
					width: '24px',
				},
			},
		},
	},
});
