import {TrackDateResponse} from 'modules/subject-details/types';

export const isDisabled = (index: number, trackDates: TrackDateResponse[], selectedTrackDates: number[]): boolean => {
	const isSelected = (trackDateID: number): boolean => selectedTrackDates.indexOf(trackDateID) > -1;
	const curPeriod = trackDates[index];
	if (!selectedTrackDates.length || isSelected(curPeriod.trackDateID)) {
		return false;
	}

	if (index === 0) {
		const nextPeriod = trackDates[index + 1];
		return !isSelected(nextPeriod.trackDateID);
	}

	if (index === trackDates.length - 1) {
		const prevPeriod = trackDates[index - 1];
		return !isSelected(prevPeriod.trackDateID);
	}

	const nextPeriod = trackDates[index + 1];
	const prevPeriod = trackDates[index - 1];

	return !isSelected(prevPeriod.trackDateID) && !isSelected(nextPeriod.trackDateID);
};
