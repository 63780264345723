export function RenewalTemplate() {
	return <div className='renewal' data-bind='var: {root: $data}'>
		<h1 className='title'>
			<span>Account </span> <strong>Management</strong>
		</h1>
		<main data-bind='if: currentStep'>
			<div className='step' data-bind='var: {step: $data}'>
				<header className='active'>
                        <span data-cy='current-step-header' className='caption'>
                            <i className='fa' data-bind='css: currentStep.icon'></i> <span
	                        data-bind='text: currentStep.title'></span>
                        </span>
					<ko data-bind='ifnot: currentStep.title'>
						<span className='user-name pull-left' data-cy='username-label'>Username:</span>
						<span className='user-name pull-left' data-cy='username-itself' data-bind='text: userName'></span>
					</ko>
					<span className='user-name pull-right'>
                            <ko data-bind='if: showExit'>
                                <a className='exit' data-toggle='tooltip' data-placement='top' title='Exit'
                                   data-bind='click: view.exitClicked'>
                                    <svg width='14' height='14' viewBox='0 0 14 14' fill='none'
                                         xmlns='http://www.w3.org/2000/svg'>
                                        <path
	                                        d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
	                                        fill='white'/>
                                    </svg>
                                </a>
                            </ko>
                        </span>
				</header>
				<form>
					<fieldset>
						<div data-bind='render: currentStep'></div>
					</fieldset>
				</form>
			</div>
		</main>

		<ko data-bind='if: showOrderSummary '>
			<aside>
				<div className='order-details' data-cy='order-summary'>
					<div className='title'>Order Summary</div>
					<ko data-bind='if: cart.expiration && cart.expiration.products.length && isAnyProductSelected '>
						<section data-cy='extended-subscriptions-section'>
							<div className='section-name'>Extended Subscriptions</div>
							<div className='order-detail'>
								<ko data-bind='foreach: orderSummary.Products'>
									<ko data-bind='if: Price > 0'>
										<div className='clearfix'>
											<div className='pull-left' data-cy='service-description'>
												<span data-bind='text: ProductName'></span>
												<span className='small-font'> (+<span
													data-bind='text: PayingMonths'></span> months)</span>
											</div>
											<div className='pull-right' data-cy='service-cost'>$<span data-bind='text: Price'></span></div>
										</div>
									</ko>
								</ko>
							</div>
						</section>
					</ko>

					<ko data-bind='if: isNeedToPayForStudents'>
						<section data-cy='student-count-section'>
							<div className='section-name'>Increase Student Count</div>
							<div className='order-detail'>
								<ko data-bind='foreach: orderSummary.Students'>
									<ko data-bind='if: Price > 0'>
										<div className='clearfix'>
											<div className='pull-left' data-cy='service-description'>
												<span data-bind='text: Quantity'></span> student<span
												data-bind='visible: Quantity != 1'>s</span>
												<span className='small-font'> (+<span
													data-bind='text: PayingMonths'></span> month<span
													data-bind='visible: PayingMonths != 1'>s</span>)</span>

											</div>
											<div className='pull-right' data-cy='service-cost'>$<span data-bind='text: Price'></span></div>
										</div>
									</ko>
								</ko>
							</div>
						</section>
					</ko>
					<h3 className='total-wrapper' data-cy='total-cost'><span>Total:&nbsp;</span><span>$<span data-bind='text: orderSummary?.Total'></span></span></h3>
				</div>
			</aside>
		</ko>
	</div>
}
