import React from 'react';
import DataService from './data-service';
import QuestionTile from './question/question';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {SelectableList} from '@esgillc/ui-kit/form-control';
import styles from './sight-words-preview.module.less';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {Modal} from '@esgillc/ui-kit/modal';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {Buttons} from '@esgillc/ui-kit/button';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {Checkbox} from '@esgillc/ui-kit/control';
import {SightWordsPrintSettings, StudentStepState} from '../../shared/types';

interface Props {
	unitData: StudentStepState;
	settings: SightWordsPrintSettings;
	testId: number;
	goToBack: (settings: SightWordsPrintSettings) => void;
	pdfDownloaded: () => void;
}

class State {
	selectedCount: number;
	menuOpened: boolean;
	allQuestionSelected: boolean;

	constructor(selectedCount: number) {
		this.selectedCount = selectedCount;
		this.allQuestionSelected = false;
	}
}

export default class SightWordsPreviewStep extends React.PureComponent<Props, State> {
	private readonly service: DataService;

	constructor(props) {
		super(props);
		this.service = new DataService();
		this.state = new State(this.props.settings.selectedQuestions.length);
	}

	componentDidMount() {
		this.service.selectionChanged$.subscribe(value => {
			this.setState({
				selectedCount: value,
				allQuestionSelected: value === this.props.settings.allQuestions.length,
			});
		});
		this.service.init(this.props.unitData, this.props.settings);
	}

	componentWillUnmount() {
		this.service.destroy();
	}

	render() {
		return <>
			<ServiceLoader trackingService={this.service.downloadPdfService}/>
			<Modal.Body className={styles.body}>
				<FlexBox align='stretch' justify='stretch' direction='column' className={styles.questionListContainer}>
					<div className={styles.title}>Uncheck each question you wish to exclude. </div>
					<div>
						<FlexBox direction='row' align='start' justify='start'>
							<div>
								<Checkbox checked={this.state.allQuestionSelected}
										  onChange={(e) => this.toggleAllQuestionSelector()}>
									<div className={styles.caret} onClick={e => this.changeMenuVisibility(e)}>
										<span className='fa fa-caret-down'></span>
									</div>
								</Checkbox>
								<Menu className={styles.menu}
									  show={this.state.menuOpened}
									  keepFocusInside onClickOutside={() => this.onCloseMenu()}
									  onEscPressed={() => this.onCloseMenu()}>
									<MenuItem onSelect={() => this.selectAllQuestions()}>All</MenuItem>
									<MenuItem onSelect={() => this.removeQuestionSelection()}>None</MenuItem>
								</Menu>
							</div>
							<div>
								Questions:
								<div className={styles.warningPopupBox}>
									<span className={styles.counter}>{this.state.selectedCount}</span>
									{this.downloadDisabled() && this.renderWarningPopup()}
								</div>
								of
								<span className={styles.counter}>{this.props.settings.allQuestions.length}</span>
								(minimum: 1)
							</div>
						</FlexBox>
					</div>
					<div className={styles.tiles}>
						<Form controller={this.service.form}>
							<FormElement control={this.service.form.controls.selectedQuestionIds}>
								<SelectableList checkboxPlacement='left' className={styles.questionList}>
									{
										this.props.settings.allQuestions.map(question => {
											return <QuestionTile
												testId={this.props.testId}
												question={question}
												key={`question-${question.questionID}`}/>
										})
									}
								</SelectableList>
							</FormElement>
						</Form>
					</div>
				</FlexBox>
			</Modal.Body>
			<Modal.Footer className={styles.footer}>
				<FlexBox align='center' justify='space-between' className={styles.navigation}>
					<Buttons.Text disabled={this.downloadDisabled()} onClick={e => this.props.goToBack(this.service.getCurrentSettings())} className={styles.backBtn}>
						<span className='glyphicon glyphicon-chevron-left arrow-icon'></span>
						<span>Back</span>
					</Buttons.Text>
					<FlexBox justify='end' align='center'>
						<Buttons.Contained disabled={this.downloadDisabled()} onClick={e => this.onDownloadPdf()}>Download</Buttons.Contained>
					</FlexBox>
				</FlexBox>
			</Modal.Footer>
		</>;
	}

	private toggleAllQuestionSelector() {
		const newValue = !this.state.allQuestionSelected;
		if (newValue === true) {
			this.selectAllQuestions();
		} else {
			this.removeQuestionSelection();
		}
	}

	private selectAllQuestions() {
		this.service.selectAllQuestions();
		this.setState({
			allQuestionSelected: true,
			menuOpened: false,
		});
	}

	private removeQuestionSelection() {
		this.service.removeQuestionSelection();
		this.setState({
			allQuestionSelected: false,
			menuOpened: false,
		});
	}

	private changeMenuVisibility(e: React.MouseEvent<HTMLDivElement>) {
		this.setState({menuOpened: !this.state.menuOpened})
		e.preventDefault();
		e.stopPropagation();
	}

	private onCloseMenu() {
		this.setState({menuOpened: false});
	}

	private onDownloadPdf() {
		this.service.downloadPdf().subscribe(result => {
			if (result.isSuccess) {
				this.props.pdfDownloaded();
			}
		});
	}

	private downloadDisabled(){
		return this.state.selectedCount < 1;
	}

	private renderWarningPopup() {
		return <div className={styles.warningPopup}>
			<div className={styles.content}>Select at least 1 word.</div>
			<div className={styles.pointer}/>
		</div>;
	}
}