import {ServerClient} from 'pages/landing/kit/server-client';
import {Button} from '@esgi/deprecated/knockout';
import {ActivationCodeForm, ActivationCodeFormComponentModel} from '../components/activation-code';
import {JoinToDistrictForm} from '../components/join-to-district-form';
import {ActivationCodeTemplate} from '../html/activation-code-template';
import {JoinToDistrictTemplate} from '../html/join-to-district-template';
import {Step} from './steps';

export class ActivationCodeStepModel extends ActivationCodeFormComponentModel {
	currentDistrictId: number;
	agreement: string;
}

export class ActivationCodeStep extends Step {
	constructor(initValue?: string) {
		super();
		this.title = 'Activation Code';
		this.activationCodeForm = this.createActivationCode(initValue);
		this.cancelButton = this.createCancelButton();
		this.nextButton = this.createNextButton();
	}

	template = ActivationCodeTemplate;

	backEnabled: boolean = true;
	activationCodeForm: ActivationCodeForm;
	cancelButton: Button;
	nextButton: Button;

	currentDistrictId: number;
	agreement: string;

	load() {
		return ServerClient.SSOAPI.Get<{districtID: number, agreement: string}>('Renewal', 'InitActivationCodeStep').done((r) => {
			this.currentDistrictId = r.districtID;
			this.agreement = r.agreement;
		});
	}

	private createActivationCode(initValue?: string) {
		const s = new ActivationCodeForm(initValue);
		this.addElement(s);
		return s;
	}

	private createCancelButton() {
		const btn = new Button();
		btn.className('btn btn-cancel pull-left');
		btn.title('Cancel');
		return btn;
	}

	private createNextButton() {
		const btn = new Button();
		btn.className('btn btn-warning pull-right');
		btn.title('Next');
		return btn;
	}

	serialize() {
		return {
			...this.activationCodeForm.serialize(),
			currentDistrictId: this.currentDistrictId,
			agreement: this.agreement,
		} as ActivationCodeStepModel;
	}

	events = {
		cancelClicked: (callback) => {
			this.cancelButton.events.click(callback);
		},
		nextClicked: (callback: (e: Event, b: Button) => any) => {
			this.nextButton.events.click(callback);
		},
	};

}

export class JoinToDistrictStep extends Step {
	constructor(public districtId: number) {
		super();
		this.title = 'Join To District';
		this.joinToDistrictForm = this.createjoinToDistrictForm(districtId);
		this.confirmButton = this.createConfirmButton();
	}

	template = JoinToDistrictTemplate;

	joinToDistrictForm: JoinToDistrictForm;
	confirmButton: Button;

	private createjoinToDistrictForm(districtId: number) {
		let s = new JoinToDistrictForm(districtId);
		this.addElement(s);

		return s;
	}

	private createConfirmButton() {
		const btn = new Button();
		btn.title('Confirm');
		btn.iconplacement('left');
		btn.className('btn btn-warning');
		return btn;
	}

	serialize() {
		return this.joinToDistrictForm.serialize();
	}

	events = {
		nextClicked: (callback: (e: Event, b: Button) => any) => {
			this.confirmButton.events.click(callback);
		},
	};

	load() {
		return this.joinToDistrictForm.load();
	}

}
