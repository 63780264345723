import {useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {
	getItemGradeLevels,
	getItemTextColor,
} from '../../../../../../utils';
import {QuestionItemProps} from '../../../../../../types';
import {Tag} from '../tag';
import {BorderedFlexBox, MetaWrapper, Image, QuestionContent, QuestionMeta, Card, Row, ImageWrapper, StyledCheckbox} from './index.styled';
import {noop} from 'underscore';
import {MultipleLabels} from '../../../../../multiple-labels';
import {createSelfAssessImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';

export function QuestionListItem({
																	 question,
																	 virtualKey,
																	 selected,
																	 onCheckedChanged,
																	 onPreviewClick,
																	 stateStandardNames,
																 }: QuestionItemProps) {
	const [cardEntered, setCardEntered] = useState(false);
	const urlBuilder = createSelfAssessImageUrlBuilder();
	const textColor = getItemTextColor(cardEntered, selected);
	const gradeLevels = getItemGradeLevels(question?.gradeLevelIDs);

	const handleCheck = (e: React.MouseEvent<unknown>) => {
		if (!question) {
			return;
		}

		onCheckedChanged(question);
		e.stopPropagation();
	};

	const handleMouseLeave = () => {
		setCardEntered(false);
	};

	const handleMouseEnter = () => {
		setCardEntered(true);
	};

	const handlePreviewButtonClick = (e: React.MouseEvent<unknown>) => {
		e.stopPropagation();
		if (!question || !onPreviewClick) {
			return;
		}

		onPreviewClick(question);
	};

	return (
		<Card key={virtualKey} selected={selected} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={handleCheck}>
			{question && (
				<StyledCheckbox entered={cardEntered} checked={selected} onCheckedChange={noop} onClick={handleCheck} />
			)}
			<ImageWrapper entered={cardEntered} skeleton={!question} selected={selected} onClick={handlePreviewButtonClick}>
				{question && <Image src={urlBuilder(question.thumbnailUrl, question.id)} />}
			</ImageWrapper>
			<QuestionContent direction='column' justify='center'>
				<BorderedFlexBox skeleton={!question} selected={selected}>
					{question && (
						<Text bold size='large' color={textColor}>
							{question.name}
						</Text>
					)}
				</BorderedFlexBox>
				<QuestionMeta skeleton={!question}>
					{question && (
						<Row>
							<MetaWrapper>
								<Text size='small' color='mediumContrast'>
									Content Area:
								</Text>
								<Tag>{question.contentAreaName}</Tag>
							</MetaWrapper>
							<MetaWrapper>
								<Text size='small' color='mediumContrast'>
									Grade Level:
								</Text>
								<MultipleLabels items={gradeLevels} />
							</MetaWrapper>
							<MetaWrapper>
								<Text size='small' color='mediumContrast'>
									Standards:
								</Text>
								<MultipleLabels items={stateStandardNames} />
							</MetaWrapper>
						</Row>
					)}
				</QuestionMeta>
			</QuestionContent>
		</Card>
	);
}