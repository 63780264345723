import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

class Props {
	itemsCount: number | null;
	selected: boolean;
	options: AllBoxOptions;
	onOpenCloseChanged: (newState: boolean) => void;
	onAddClicked: () => void;
	onSelectClicked: () => void;
	open: boolean;
	afterRender?: (ref: React.RefObject<HTMLElement>) => void;
}

export class AllBoxOptions {
	canAdd: boolean;
	canAddTooltip: string;
	addTooltip?: string;
	title: string;
}

class State {
}

export class AllBox<P extends Props = Props> extends React.Component<P, State> {
	ref: React.RefObject<HTMLElement> = React.createRef();
	refAllBox: React.RefObject<HTMLDivElement> = React.createRef();

	constructor(props: P) {
		super(props);
	}

	componentWillUnmount() {
		if (this.ref) {
			$(this.ref).bstooltip('destroy');
		}
	}

	private openClose = (e: React.MouseEvent<HTMLAnchorElement>) => {
		const newState = !this.props.open;
		this.props.onOpenCloseChanged(newState);
		e.preventDefault();
	}

	protected select(e: React.MouseEvent<HTMLAnchorElement>) {
		if (this.props.selected) {
			e.preventDefault();
			this.openClose(e);
			return;
		} else {
			this.props.onSelectClicked();
		}
	}

	protected renderAddIcon() {
		return <a href='#' className='edit icon' onClick={() => this.props.onAddClicked()}>
			<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
				<path
					d='M9.83332 4.83332H8.16666V8.16666H4.83332V9.83332H8.16666V13.1667H9.83332V9.83332H13.1667V8.16666H9.83332V4.83332ZM8.99999 0.666656C4.39999 0.666656 0.666656 4.39999 0.666656 8.99999C0.666656 13.6 4.39999 17.3333 8.99999 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99999C17.3333 4.39999 13.6 0.666656 8.99999 0.666656ZM8.99999 15.6667C5.32499 15.6667 2.33332 12.675 2.33332 8.99999C2.33332 5.32499 5.32499 2.33332 8.99999 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 12.675 12.675 15.6667 8.99999 15.6667Z'
					fill='#0088CC'/>
			</svg>
		</a>;
	}

	renderCantCreateTooltip() {
		if (this.props.options.canAddTooltip) {
			return <>
				<OnHoverTooltip message={this.props.options.canAddTooltip}>
					<i ref={this.ref} className='glyphicon glyphicon-question-sign cant-add-icon'/>
				</OnHoverTooltip>
			</>;
		}
		return null;
	}

	renderAdd() {
		if (this.props.options.canAdd) {
			const icon = this.renderAddIcon();
			if (this.props.options.addTooltip) {
				return <OnHoverTooltip message={this.props.options.addTooltip}>
					{icon}
				</OnHoverTooltip>;
			} else {
				return icon;
			}
		}
		return null;
	}


	componentDidMount() {
		if(this.props.afterRender){
			this.props.afterRender(this.refAllBox);
		}
	}

	render() {
		return <div id={'all-box-item_' + this.props.options.title.replace(' ', '-')}
		            className={'item all ' + (this.props.selected ? 'selected' : '')} ref={this.refAllBox}>
			<a href='#' className={'title hierarchy-all'} onClick={(e) => this.select(e)}>
				<span>{this.props.options.title}</span> {this.showCount()}
				{this.renderCantCreateTooltip()}</a>
			{this.renderAdd()}
			{this.renderCollapseButton()}
		</div>;
	}

	private showCount() {
		if (this.props.itemsCount === null) {
			return null;
		}

		return <> (<span>{this.props.itemsCount}</span>)</>;
	}

	private renderCollapseButton() {
		return <a href='#' className={'roll ' + (this.props.open ? 'up' : 'down')}
		          onClick={this.openClose}>
			<i className='fa fa-angle-up'/>
			<i className='fa fa-angle-down'/>
		</a>;
	}
}