import {
	SortDirection,
	SortModel,
	SortByType,
	SortByCriteriaResultsOptions,
	sortDirectionMap,
} from '../../../../../../types/table-level-report-service-types';
import {TableLevelReportService} from '../../../../../../services/table-level-report-service';
import {IconArrowDown, IconArrowTop} from '../icon';
import {useCallback, useMemo} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

type SortableColumnProps = {
	title: string;
	service: TableLevelReportService;
	sortBy: SortModel;
	sortByType: SortByType;
	sortByCriteriaResultsOptions?: SortByCriteriaResultsOptions | null;
};

export function SortableColumn({
	title,
	service,
	sortBy,
	sortByType,
	sortByCriteriaResultsOptions,
}: SortableColumnProps) {
	const isSortedByThisColumn = useMemo(() => {
		if (
			sortByType.sortKey === 'criteriaResults' &&
			sortBy.fieldName === 'criteriaResults' &&
			sortByCriteriaResultsOptions
		) {
			return (
				sortByType.criteriaName === sortByCriteriaResultsOptions.criteriaName &&
				sortByType.periodResultName === sortByCriteriaResultsOptions.periodResultName
			);
		}

		return sortBy.fieldName === sortByType.sortKey;
	}, [sortBy.fieldName, sortByType, sortByCriteriaResultsOptions]);

	const onClick = useCallback(() => {
		if (!isSortedByThisColumn) {
			service.toggleSortDirection(sortByType, SortDirection.Asc);

			return;
		}

		const newDirection = sortBy.direction === sortDirectionMap[SortDirection.Asc] ? SortDirection.Desc : SortDirection.Asc;

		service.toggleSortDirection(sortByType, newDirection);
	}, [isSortedByThisColumn, service.toggleSortDirection, sortBy, sortByType]);

	return (
		<div className={styles.sortableColumn} onClick={onClick}>
			{sortBy.direction === sortDirectionMap[SortDirection.Asc] || !isSortedByThisColumn ? (
				<IconArrowDown className={join(isSortedByThisColumn && styles.visible)} />
			) : (
				<IconArrowTop className={styles.visible} />
			)}
			<span>{title}</span>
		</div>
	);
}
