import {Module, Renderable, ModalWindow, OldAlerts} from '@esgi/deprecated/knockout';
import {Container, ExportApi, Helper, TemplateModel} from 'shared/modules/export/export';
import {EditTemplateModule} from 'shared/modules/export/edit-template/edit-template';
import React from 'react';
import './manage-templates.less';

export class ManageTemplatesOptions {
	allowSelectTemplate: boolean;
	showScheduleOptions: boolean;
}

export class ManageTemplatesModule extends Module {

	private userContext;
	private initApi: () => JQueryPromise<Container>;
	private options: ManageTemplatesOptions;
	private helpers: Helper;
	private server: ExportApi;

	constructor(userContext, initApi: () => JQueryPromise<Container>, options: ManageTemplatesOptions) {
		super();
		this.userContext = userContext;
		this.initApi = initApi;
		this.options = options;
		this.helpers = new Helper();
		this.server = new ExportApi();
	}

	templates = {
		all: ko.observableArray<TemplateModel>([]),
		selected: ko.observable<TemplateModel>(),
		isNotSelected: ko.pureComputed(() => {
			return this.templates.selected() == null;
		}),
	};

	private initModule(response: Container) {
		this.templates.all = response.templates;
	}


	load() {
		return this.initApi().done((model) => {
			this.initModule(model);
		}).fail((obj) => {
			OldAlerts.bsalert(obj.Message);
		});
	}

	view = {
		templateClicked: (template: TemplateModel) => {
			this.templates.selected(template);
		},
		addClicked: () => {
			let editModule = new EditTemplateModule(this.userContext,
				{
					showScheduleOptions: this.options.showScheduleOptions,
					initContainer: this.initApi,
					initTemplate: () => {
						let template = new TemplateModel();
						template.schedule = false;
						template.templateID = 0;
						template.templateName = '';
						template.tests = [];
						template.userID = this.userContext.userID;
						template.teacherIDN = 'importIDN';
						template.studentIDN = 'importIDN';

						return $.Deferred<TemplateModel>().resolve(template).promise();
					},
				});
			const modal = new ModalWindow(editModule,
				{
					allowClose: true,
					showHeader: true,
					showFooter: true,
					title: 'Add Template',
					className: 'edit-template-modal',
					buttons: [
						{
							title: 'Create',
							className: 'btn btn-primary btn-sm',
							submit: true,
							align: 'right',
							closeModal: false,
							onClick: () => {
								editModule.extractTemplate().done((modelToRequest: TemplateModel) => {
									editModule.loader.mask();
									return this.server.exportTemplate.create(modelToRequest).then((model) => {
										this.templates.all.push(model);
										modal.close(true);
									}).finally(() => editModule.loader.unmask());
								});
							},
						},
						{
							title: 'Cancel',
							className: 'btn btn-sm',
							align: 'right',
							closeModal: true,
							onClick: () => {
								modal.close(true);
							},
							cancel: true,
						},
					],
				});
			modal.load();
		},
		editClicked: (template: TemplateModel) => {
			let editModule = new EditTemplateModule(this.userContext, {
				showScheduleOptions: this.options.showScheduleOptions,
				initContainer: this.initApi,
				initTemplate: () => {
					return $.Deferred<TemplateModel>().resolve(template).promise();
				},
			});
			const modal = new ModalWindow(editModule,
				{
					allowClose: true,
					showHeader: true,
					showFooter: true,
					title: 'Edit Template',
					className: 'edit-template-modal',
					buttons: [
						{
							title: 'Save',
							className: 'btn btn-primary btn-sm',
							submit: true,
							align: 'right',
							closeModal: false,
							onClick: () => {
								editModule.extractTemplate().done((modelToRequest: TemplateModel) => {
									editModule.loader.mask();
									return this.server.exportTemplate.update(modelToRequest).then(() => {
										let existTemplate = this.helpers.getById(this.templates.all(), 'templateID', modelToRequest.templateID);
										let index = this.templates.all().indexOf(existTemplate);
										let updatedTemplate = {...modelToRequest};
										this.templates.all.splice(index, 1, updatedTemplate);
										modal.close(true);
									}).finally(() => editModule.loader.unmask());
								});

							},
						},
						{
							title: 'Cancel',
							className: 'btn btn-sm',
							align: 'right',
							closeModal: true,
							onClick: () => {
								modal.close(true);
							},
							cancel: true,
						},
					],
				});
			modal.load();
		},
		removeClicked: (template: TemplateModel) => {
			OldAlerts.bsconfirm('Are you sure you want to delete the template \'' + template.templateName + '\'?', (result) => {
				if (result) {
					this.server.exportTemplate.delete(template.templateID).then(() => {
						this.templates.all.remove(template);
					}).catch((obj) => {
						OldAlerts.bsalert(obj.Message);
					});
				}
			});
		},
	};

	afterRender(rootElement) {
		this.rootElement = rootElement;
		setTimeout(() => {
			let tooltipElements = $('.tooltip-element', this.rootElement);
			for (let i = 0; i < tooltipElements.length; i++) {
				let tooltipElement = tooltipElements[i];
				$(tooltipElement).qtip({
					content: {
						text: $(tooltipElement).find('.tooltip-message').text(),
					},
					hide: {
						fixed: true,
						delay: 300,
					},
					position: {
						at: 'top center',
						my: 'bottom center',
					},
				});
			}
		}, 1);


		return Renderable.resolvedDeferred;
	}

	template = () => <div className='manage-templates' data-bind='afterRender: true'>
		<div className='templates-header'>
			<div style={{fontWeight: 'bold'}}>Available Templates:</div>
			<div>
				<button className='btn btn-primary btn-sm' data-bind='click: view.addClicked'>Add Template</button>
			</div>
		</div>
		<div className='templates-container'>
			<table className='table table-striped table-hover table-bordered'
			       data-bind="css:{'selectable': options.allowSelectTemplate}">
				<thead>
				<tr>
					<th>Template Name</th>
					<th data-bind='visible: !options.showScheduleOptions'># of Tests</th>
					<th data-bind='visible: !options.showScheduleOptions'>Export Type</th>
					<th data-bind='visible: options.showScheduleOptions'>Schedule</th>
					<th data-bind='visible: options.showScheduleOptions'>Status</th>
					<th/>
				</tr>
				</thead>
				<tbody data-bind='foreach: templates.all'>
				<tr data-bind="click: $parent.view.templateClicked,css: {'row-selected': $data==$parent.templates.selected() }">
					<td data-bind='text:templateName' style={{textAlign: 'left'}}/>
					<td data-bind='text:tests.length, visible: !$parent.options.showScheduleOptions'
					    style={{textAlign: 'center'}}/>
					<td data-bind='text:exportType, visible: !$parent.options.showScheduleOptions'
					    style={{textAlign: 'center'}}/>
					<td data-bind='visible: $parent.options.showScheduleOptions' style={{textAlign: 'center'}}>
						<ko data-bind='if: scheduleCronExpression == null'>
							<i className='fa fa-exclamation-triangle tooltip-element' aria-hidden='true'>
								<span className='tooltip-message'>This template is not configured to work on a schedule. Click on the pencil and create a schedule.</span>
							</i>
						</ko>
						<ko data-bind='if: scheduleCronExpression != null'>
							<ko data-bind='text: $parent.helpers.formatCronExpression(scheduleCronExpression)'/>
						</ko>
					</td>
					<td data-bind='visible: $parent.options.showScheduleOptions' style={{textAlign: 'center'}}>
						<ko data-bind='if: schedule == true'>
							<span className='status-enabled'>Enabled</span>
						</ko>
						<ko data-bind='if: schedule != true'>
							<span className='status-disabled'>Disabled</span>
						</ko>
					</td>
					<td style={{textAlign: 'center'}}>
						<i className='fa fa-pencil' title='Edit Template'
						   data-bind='click: $parent.view.editClicked,clickBubble: false' aria-hidden='true'
						   style={{marginRight: '10px'}}/>
						<i className='fa fa-times' title='Remove Template'
						   data-bind='click: $parent.view.removeClicked, clickBubble: false' aria-hidden='true'
						   style={{marginLeft: '10px'}}/>
					</td>
				</tr>
				</tbody>
			</table>
			<ko data-bind='if: templates.all().length == 0'>
				<span>{`You have not yet created any template. It's time to do it. Just hit the button "Add Template" located just above.`}</span>
			</ko>
		</div>
		<div className='selected-template'
		     data-bind='visible: options.allowSelectTemplate && templates.all().length>0'>
			<ko data-bind='if: templates.selected()== null'>
				To select a template just click on it.
			</ko>
			<ko data-bind='if: templates.selected() != null'>
				<span style={{fontWeight: 'bold'}}>Selected Template:</span>&nbsp;
				<ko data-bind='text: templates.selected().templateName'/>
			</ko>
		</div>
	</div>
}
