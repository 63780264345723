import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {Alert} from '@esgi/ui/alert';

export const avatarRootCss: CSS = {
	cursor: 'default',
};

export const AlertHeaderStyled = styled(Alert.Header, {
	'& > div': {
		gridTemplateColumns: '1fr auto',
	},
});
