import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert} from '@esgi/ui/alert';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
	overflow: 'auto',
	maxHeight: '75vh',
	padding: 0,
});

export const ReportWrapper = styled(FlexBox, {
	flexDirection: 'column',
	width: '100%',
	padding: '0 20px',
});

export const Page = styled(FlexBox, {
	flexDirection: 'column',

	'&:not(:last-child)': {
		marginTop: '20px',
	},
});

export const Header = styled(FlexBox, {
	alignItems: 'baseline',
	borderBottom: '1px solid #cbcaca',
});

export const HeaderText = styled(Text, {
	marginRight: '10px',
});

export const Student = styled(FlexBox, {
	flexDirection: 'column',
	marginTop: '5px',
	paddingBottom: '10px',
	borderBottom: '1px solid #cbcaca',
});

export const FullNameText = styled(Text, {
	fontSize: '21px',
	fontWeight: 700,
});

export const MyNameText = styled(Text, {
	color: '$neutral40',
	fontSize: '26px',
	padding: '20px 0 20px',
	textAlign: 'center',
});

export const StudentNameText = styled(Text, {
	color: '$neutral56',
	fontFamily: 'AbcPrint',
	textAlign: 'center',
});

export const Content = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-around',
	marginTop: '30px',
});

export const Line = styled('p', {
	fontFamily: 'AbcPrintDottedLined',
	margin: 0,
});
