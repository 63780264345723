import {Input} from '@esgi/ui/controls';
import {Search, Plus} from '@esgi/ui/icons';
import {InfoBlock, SelectableList, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Wrapper, IconButton} from './index.styled';
import {StudentFullName} from '../../student-full-name';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Subject, debounceTime} from 'rxjs';
import {EmptyContentText} from './components/empty-content-text.styled';
import {Student} from './types';
import {useSelectedIdsChanged} from './use-selected-ids-changed';
import {StudentSort, useUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {AddButton} from './components/add-button';

type Props = {
	students: Student[];
	selectedStudentIds: Student['id'][];
	initialSelectedStudentIds?: Student['id'][] | undefined | null;
	setSelectedStudentIds: (value: Student['id'][]) => void;
	setSelectedValueChanged?: (value: boolean) => void;
	onAddStudent?: VoidFunction;
	onAddStudentByRef?: VoidFunction;
};

export function StudentsSearchableList({
	students,
	selectedStudentIds,
	setSelectedStudentIds,
	initialSelectedStudentIds,
	setSelectedValueChanged,
	onAddStudent = () => {},
	onAddStudentByRef,
}: Props) {
	const user = useUser();
	const [searchValue, setSearchValue] = useState('');
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const addStudent = () => {
		if (typeof onAddStudentByRef !== 'undefined') {
			onAddStudentByRef();
		} else {
			const classId = searchParams.get('classId');
			const groupId = searchParams.get('groupId');
			const params = {
				drawerMode: 'create',
				drawerName: 'teacherStudentViaProfile',
				classId,
				groupId,
			};
			const filteredParams = Object.entries(params).filter(([key, value]) => value);
			const studentProfileDrawerUrl = new URLSearchParams(filteredParams);
			onAddStudent();
			navigate(`/home?${studentProfileDrawerUrl.toString()}`, {state: {goBack: true}});
		}
	};
	const {current: searchInputSubject$} = useRef(new Subject<string>());

	const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);

	const isSelectedValueChanged = useSelectedIdsChanged({initialSelectedStudentIds, selectedStudentIds});

	const sortedStudents = useMemo(
		() =>
			[...students].sort((a, b) => {
				if (isNull(user) || user.studentSort === StudentSort.FirstName) {
					return a.firstName.localeCompare(b.firstName);
				}

				if (user.studentSort === StudentSort.LastName) {
					return a.lastName.localeCompare(b.lastName);
				}

				return 0;
			}),
		[students, user],
	);

	useEffect(() => {
		setSelectedValueChanged?.(isSelectedValueChanged);
	}, [isSelectedValueChanged]);

	useEffect(() => {
		setFilteredStudents(sortedStudents);

		const subscription = searchInputSubject$.pipe(debounceTime(300)).subscribe((value) => {
			const lowerCaseValue = value.trim().toLocaleLowerCase();
			const searchWords = lowerCaseValue.split(' ');

			setFilteredStudents(() => {
				if (searchWords.length > 3) {
					return [];
				}

				return sortedStudents.filter(({firstName, lastName}) => {
					const fullName = [firstName, lastName].filter(Boolean).join(' ').trim().toLocaleLowerCase();
					const reversedFullName = [lastName, firstName].filter(Boolean).join(' ').trim().toLocaleLowerCase();

					return searchWords.every((word) => fullName.includes(word) || reversedFullName.includes(word));
				});
			});
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [sortedStudents]);

	const onValueChange = useCallback(
		(value: string[]) => {
			setSelectedStudentIds(value.map(Number));
		},
		[setSelectedStudentIds],
	);

	const onSarchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearchValue(value);
		searchInputSubject$.next(value);
	};

	const isUserCanAddStudents = Boolean(user?.canAddStudents);

	if (!students.length) {
		return (
			<Wrapper variant='isStudentListEmpty'>
				<FlexBox justify='center'>
					<AddButton disabled={!isUserCanAddStudents} onClick={addStudent} />
				</FlexBox>
				<EmptyContentText size='medium' bold color='neutral40'>
					No students to display
				</EmptyContentText>
			</Wrapper>
		);
	}

	return <Wrapper variant='base'>
			<Input.Iconable placeholder='Search' value={searchValue} onChange={onSarchValueChange}>
				<Search />
			</Input.Iconable>
			<FlexBox justify='center'>
				<AddButton disabled={!isUserCanAddStudents} onClick={addStudent} />
			</FlexBox>
			{filteredStudents.length ? (
				<OverlayScrollbarsComponent
					defer
					options={{
						scrollbars: {autoHide: 'leave'},
						paddingAbsolute: true,
					}}
				>
					<SelectableList>
						<SelectableList.GroupRoot
							type='multiple'
							defaultValue={selectedStudentIds.map(String)}
							onValueChange={onValueChange}
						>
							<SelectableList.Group>
								{filteredStudents.map(({firstName, lastName, id}) => (
									<SelectableList.Item value={String(id)} key={id}>
										<StudentFullName firstName={firstName} lastName={lastName} />
									</SelectableList.Item>
								))}
							</SelectableList.Group>
						</SelectableList.GroupRoot>
					</SelectableList>
				</OverlayScrollbarsComponent>
			) : (
				<EmptyContentText size='medium' bold color='neutral40'>
					No students to display
				</EmptyContentText>
			)}

			<InfoBlock>
				<InfoBlock.Item title='Total Students Selected:'>
					<Text size='small' font='mono' bold>
						{selectedStudentIds.length}
					</Text>
				</InfoBlock.Item>
			</InfoBlock>
		</Wrapper>;
}
