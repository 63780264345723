import {HttpClient} from '@esgi/api';
import {Observable, of, tap} from 'rxjs';

export class Api {
	private readonly controller: string = 'modules/notifcations';

	public init(): Observable<InitResponse> {
    	let response = NotificationsStorage.get();
    	if (response) {
    		return of(response);
    	}

    	return HttpClient.default.ESGIApi
    		.get<InitResponse>(this.controller, 'active')
    		.pipe(tap(r => {
    		  NotificationsStorage.save(r);
    		  return r;
    		}))
    		.asObservable();
	}

	public dismiss(id: number): Observable<void> {
    	NotificationsStorage.remove(id);
    	return HttpClient.default.ESGIApi
    		.post<void>(this.controller, 'dismiss', {UserNotificationID: id})
    		.asObservable();
	}

}

export interface InitResponse {
	notification: NotificationResponse;
}

export interface NotificationResponse {
	notificationID: number;
	type: string;
}

class NotificationsStorage {
	static save(response: InitResponse) {
		sessionStorage.setItem('notifications', JSON.stringify(response));
	}

	static get(): InitResponse {
		const notifications = sessionStorage.getItem('notifications');
		if (notifications) {
			return JSON.parse(notifications);
		}
	}

	static remove(id: number) {
		const response = NotificationsStorage.get();
		if (response) {
			response.notification = null;
			NotificationsStorage.save(response);
		}
	}
}
