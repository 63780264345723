import {Observable, of, map} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2PagesAssignmentsController} from '@esgi/contracts/esgi';
import {Student} from '@esgi/main/libs/store';
import {AssignmentStateContract} from '../../types';
import {AssignmentState} from '@esgi/main/kits/assignments';

export class AddAssignmentService extends BaseService {
	private assignmentsController = new V2PagesAssignmentsController();

	private adaptedAssignmentStateRequest: Record<AssignmentState, AssignmentStateContract> = {
		[AssignmentState.None]: AssignmentStateContract.None,
		[AssignmentState.Draft]: AssignmentStateContract.Draft,
		[AssignmentState.NotStarted]: AssignmentStateContract.NotStarted,
		[AssignmentState.Published]: AssignmentStateContract.Published,
		[AssignmentState.Completed]: AssignmentStateContract.Completed,
	};

	public createAssignment({
		state,
		...params
	}: {
		name: string;
		description: string;
		state: AssignmentState;
		testIDs: number[];
		studentIDs: Student['id'][];
	}) {
		return this.assignmentsController.create({
			...params,
			state: this.adaptedAssignmentStateRequest[state],
		});
	}

	public validateAssignmentName(value: string): Observable<string | null> {
		if (!value) {
			return of('Assignment name is required');
		}
		if(value.length > 50){
			return of('Assignment name cannot exceed 50 characters');
		}

		return this.assignmentsController
			.validateName({name: value})
			.pipe(map(({valid}) => (!valid ? 'Assignment with this name already exist.' : null)));
	}
	public validateAssignmentDescription(value: string): Observable<string | null> {
		if(value && value.length > 150){
			return of('Assignment name cannot exceed 150 characters');
		}
		return of(null);
	}
	public override dispose() {
		this.assignmentsController.dispose();
	}
}
