import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {
	Observable,
	OperatorFunction,
	Subscribable,
	Subscription,
	throwError,
	Unsubscribable,
	InteropObservable,
	Observer,
} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {CustomErrorHandler, ErrorHandler} from '../error-handler';
import {HandlingStrategyBuilder, HandlingStrategy} from '../handling-strategy';
import {HttpEventBus} from '../http-event-bus';
import RequestSubscriber from './request-subscriber';
import {CallbackOptions, ErrorResponse} from '../types';

export class ObservableBuilder<T> implements InteropObservable<T> {
	[Symbol.observable](): Subscribable<T> {
		return {
			subscribe: (observer: Partial<Observer<T>>): Unsubscribable => {
				return this.subscribe(observer);
			},
		};
	}

	//For case if Symbol.observable not initialized.
	'@@observable'(): Subscribable<T> {
		return {
			subscribe: (observer: Partial<Observer<T>>): Unsubscribable => {
				return this.subscribe(observer);
			},
		};
	}

	private readonly errorHandlingStrategyBuilder: HandlingStrategyBuilder = new HandlingStrategyBuilder();
	private maxRetryAttempts: number = 5;
	private operators: OperatorFunction<any, any>[] = [];

	constructor(private options: AxiosRequestConfig,
	            private defaultErrorHandlers: ErrorHandler[],
	            private callback?: CallbackOptions,
	            private responseMapper?: (data: any) => T) {
		for (const defaultErrorHandler of defaultErrorHandlers) {
			this.errorHandlingStrategyBuilder.withHandler(defaultErrorHandler);
		}
	}

	/**
	 * The function sets the maximum number of retry attempts for an ObservableBuilder object.
	 * @param {number} retryAttempts - The retryAttempts parameter is a number that represents the maximum
	 * number of times an operation should be retried.
	 * @returns The method returns an instance of the `ObservableBuilder<T>` class.
	 */
	public setMaxRetryAttempts(retryAttempts: number): ObservableBuilder<T> {
		if (retryAttempts >= 0) {
			this.maxRetryAttempts = retryAttempts;
			return this;
		}
		return this;
	}

	/**
	 * The function "withCustomErrorHandler" adds a custom error handler to the ObservableBuilder.
	 * @param errorHandler - The errorHandler parameter is a function that takes two arguments: response
	 * (which is an XMLHttpRequest object) and errorHandlingStrategy (which is a HandlingStrategy object).
	 * This function is responsible for handling any errors that occur during the execution of the
	 * request.
	 * @returns The method is returning an instance of the `ObservableBuilder<T>` class.
	 */
	public withCustomErrorHandler(errorHandler: (response: XMLHttpRequest, errorHandlingStrategy: HandlingStrategy) => void): ObservableBuilder<T> {
		this.errorHandlingStrategyBuilder.withHandler(new CustomErrorHandler(errorHandler), true);
		return this;
	}

	/**
	 * This function subscribes to an observable and returns a subscription object.
	 * @param {Partial<Observer<T>> | ((value: T) => void) | null} [observerOrNext] - The observerOrNext
	 * parameter can be one of the following:
	 * @param {((error: any) => void) | null} [error] - A function that will be called if an error occurs
	 * during the subscription. It takes one argument, which is the error object.
	 * @param {(() => void) | null} [complete] - A function that will be called when the observable
	 * completes successfully.
	 * @returns The `subscribe` method is returning a `Subscription` object.
	 */
	subscribe(
		observerOrNext?: Partial<Observer<T>> | ((value: T) => void) | null,
		error?: ((error: any) => void) | null,
		complete?: (() => void) | null,
	): Subscription {
		return this.buildRequest(this.maxRetryAttempts).subscribe(observerOrNext as any, error, complete);

	}

	/* The `pipe` method in the `ObservableBuilder` class is used to apply RxJS operators to the
	observable sequence. It allows chaining multiple operators together to transform the emitted
	values. */
	public pipe(): ObservableBuilder<T>;
	public pipe<A>(op1: OperatorFunction<T, A>): ObservableBuilder<A>;
	public pipe<A, B>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>): ObservableBuilder<B>;
	public pipe<A, B, C>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>): ObservableBuilder<C>;
	public pipe<A, B, C, D>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>): ObservableBuilder<D>;
	public pipe<A, B, C, D, E>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>): ObservableBuilder<E>;
	public pipe<A, B, C, D, E, F>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>): ObservableBuilder<F>;
	public pipe<A, B, C, D, E, F, G>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>): ObservableBuilder<G>;
	public pipe<A, B, C, D, E, F, G, H>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>, op8: OperatorFunction<G, H>): ObservableBuilder<H>;
	public pipe<A, B, C, D, E, F, G, H, I>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>, op8: OperatorFunction<G, H>, op9: OperatorFunction<H, I>): ObservableBuilder<I>;
	public pipe<A, B, C, D, E, F, G, H, I>(op1: OperatorFunction<T, A>, op2: OperatorFunction<A, B>, op3: OperatorFunction<B, C>, op4: OperatorFunction<C, D>, op5: OperatorFunction<D, E>, op6: OperatorFunction<E, F>, op7: OperatorFunction<F, G>, op8: OperatorFunction<G, H>, op9: OperatorFunction<H, I>, ...operations: OperatorFunction<any, any>[]): ObservableBuilder<{}>;
	public pipe(...operations: OperatorFunction<any, any>[]): ObservableBuilder<any> {
		for (const operationsKey of operations) {
			this.operators.push(operationsKey);
		}
		return this;
	}

	/**
	 * The function returns an Observable object.
	 * @returns An Observable of type T is being returned.
	 */
	public asObservable(): Observable<T> {
		return this.buildRequest(this.maxRetryAttempts);
	}

	/**
	 * The function `toPromise` converts an observable into a promise.
	 * @returns The method `toPromise()` is being called on the `Observable` object returned by
	 * `this.asObservable()`. The `toPromise()` method converts the `Observable` into a `Promise` object.
	 * The method `toPromise()` returns a `Promise<T>`, where `T` is the type of the values emitted by the
	 * `Observable`.
	 */
	public toPromise(): Promise<T> {
		return this.asObservable().toPromise();
	}

	/**
	 * This function builds an Observable that makes an HTTP request, handles errors, and applies
	 * operators to the response.
	 * @param {number} [maxRetryAttempts=5] - The `maxRetryAttempts` parameter is the maximum number of
	 * times the request should be retried in case of failure. It is optional and has a default value of
	 * 5.
	 * @returns The `buildRequest` function returns an `Observable<T>`.
	 */
	private buildRequest(maxRetryAttempts: number = 5): Observable<T> {
		const errorHandlingStrategy = this.errorHandlingStrategyBuilder.build();
		let request = new Observable<AxiosResponse<T>>((observer) => {
			this.callback?.onRequestStarted(this.options);
			return new RequestSubscriber<T>(observer, this.options, maxRetryAttempts);
		}).pipe(
			catchError((err: AxiosError) => {
				errorHandlingStrategy.handle(err.request);
				return throwError(err);
			}),
			finalize(() => this.callback?.onRequestEnd(this.options)),
			tap((response: AxiosResponse<T>) => HttpEventBus.instance.dispatch(response)),
			map(r => this.responseMapper ? this.responseMapper(r.data) : r.data),
		);

		for (const op of this.operators) {
			request = request.pipe(op);
		}

		return request;
	}
}
