import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const StudentManagerPage = lazyComponent(() => import('pages/student-manager/root'));

export default function StudentManager(props: {excludeLayout?: boolean, excludeSYSwitch?: boolean}) {
	return <RouteWrapper className='se' title={'Student Manager'} authorize>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<StudentManagerPage excludeLayout={props.excludeLayout} excludeSYSwitch={props.excludeSYSwitch}/>
		</Suspense>
	</RouteWrapper>;
}
