import {SummaryProps} from './index';
import styles from './styles.module.less';

type InfoProps = Pick<SummaryProps, 'studentName' | 'testName'> & {showHeader?: boolean};

export const SummaryInfo = ({showHeader = true, ...props}: InfoProps): JSX.Element => (
	<>
		{showHeader &&
			<div className={styles.header}>Test Session Results</div>
		}
		<div className={styles.sessionInfo}>
			<div className={styles.title}>Student:</div>
			<div className={styles.value}>{props.studentName}</div>
		</div>
		<div className={styles.sessionInfo}>
			<div className={styles.title}>Test Name:</div>
			<div className={styles.value}>{props.testName}</div>
		</div>
	</>
);
