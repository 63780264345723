import React, {forwardRef, SVGAttributes} from 'react';

export const AccountIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((props, ref) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14'
	            viewBox='0 0 14 14' fill='none' {...props} ref={ref}>
		<path
			d='M6.99998 7C8.84165 7 10.3333 5.50833 10.3333 3.66667C10.3333 1.825 8.84165 0.333334 6.99998 0.333334C5.15831 0.333334 3.66665 1.825 3.66665 3.66667C3.66665 5.50833 5.15831 7 6.99998 7ZM6.99998 8.66667C4.77498 8.66667 0.333313 9.78333 0.333313 12V13.6667H13.6666V12C13.6666 9.78333 9.22498 8.66667 6.99998 8.66667Z'/>
	</svg>;
});
export const SettingsIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((props, ref) => {
	return <svg className='icon' width='16' height='16' viewBox='0 0 16 16' fill='none'
	            xmlns='http://www.w3.org/2000/svg' {...props} ref={ref}>
		<path
			d='M13.95 8.78C13.98 8.53 14 8.27 14 8C14 7.73 13.98 7.47 13.94 7.22L15.63 5.9C15.78 5.78 15.82 5.56 15.73 5.39L14.13 2.62C14.03 2.44 13.82 2.38 13.64 2.44L11.65 3.24C11.23 2.92 10.79 2.66 10.3 2.46L10 0.34C9.97001 0.14 9.80001 0 9.60001 0H6.40001C6.20001 0 6.04001 0.14 6.01001 0.34L5.71001 2.46C5.22001 2.66 4.77001 2.93 4.36001 3.24L2.37001 2.44C2.19001 2.37 1.98001 2.44 1.88001 2.62L0.280007 5.39C0.180007 5.57 0.220008 5.78 0.380008 5.9L2.07001 7.22C2.03001 7.47 2.00001 7.74 2.00001 8C2.00001 8.26 2.02001 8.53 2.06001 8.78L0.370007 10.1C0.220007 10.22 0.180007 10.44 0.270007 10.61L1.87001 13.38C1.97001 13.56 2.18001 13.62 2.36001 13.56L4.35001 12.76C4.77001 13.08 5.21001 13.34 5.70001 13.54L6.00001 15.66C6.04001 15.86 6.20001 16 6.40001 16H9.60001C9.80001 16 9.97001 15.86 9.99001 15.66L10.29 13.54C10.78 13.34 11.23 13.07 11.64 12.76L13.63 13.56C13.81 13.63 14.02 13.56 14.12 13.38L15.72 10.61C15.82 10.43 15.78 10.22 15.62 10.1L13.95 8.78ZM8.00001 11C6.35001 11 5.00001 9.65 5.00001 8C5.00001 6.35 6.35001 5 8.00001 5C9.65001 5 11 6.35 11 8C11 9.65 9.65001 11 8.00001 11Z'/>
	</svg>;
});
export const ContactUsIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((props, ref) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14'
	            fill='none' {...props} ref={ref}>
		<path
			d='M15.6667 0.333336H2.33332C1.41666 0.333336 0.67499 1.08334 0.67499 2L0.666656 12C0.666656 12.9167 1.41666 13.6667 2.33332 13.6667H15.6667C16.5833 13.6667 17.3333 12.9167 17.3333 12V2C17.3333 1.08334 16.5833 0.333336 15.6667 0.333336ZM15.6667 3.66667L8.99999 7.83333L2.33332 3.66667V2L8.99999 6.16667L15.6667 2V3.66667Z'/>
	</svg>;
});
export const HelpIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((props, ref) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'
	            fill='none' {...props} ref={ref}>
		<path
			d='M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.8 13.6H7.2V12H8.8V13.6ZM10.456 7.4L9.736 8.136C9.16 8.72 8.8 9.2 8.8 10.4H7.2V10C7.2 9.12 7.56 8.32 8.136 7.736L9.128 6.728C9.424 6.44 9.6 6.04 9.6 5.6C9.6 4.72 8.88 4 8 4C7.12 4 6.4 4.72 6.4 5.6H4.8C4.8 3.832 6.232 2.4 8 2.4C9.768 2.4 11.2 3.832 11.2 5.6C11.2 6.304 10.912 6.944 10.456 7.4Z'/>
	</svg>;
});
export const LogoutIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((props, ref) => {
	return <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'
	            fill='none' {...props} ref={ref}>
		<path
			d='M6.40833 10.9917L7.58333 12.1667L11.75 8L7.58333 3.83333L6.40833 5.00833L8.55833 7.16667H0.5V8.83333H8.55833L6.40833 10.9917ZM13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V5.5H2.16667V2.16667H13.8333V13.8333H2.16667V10.5H0.5V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z'
		/>
	</svg>;
});
