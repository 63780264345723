import React from 'react';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {join} from '@esgillc/ui-kit/utils';
import TableService from '../../../services/table-service';
import styles from '../table.module.less';

class State {
	active: boolean = false;
	asc: boolean = false;
}

class Props {
	className?: string;
	columnName: string;
	displayName: string;
	tableService: TableService;
}

export default class ColumnHeader extends React.PureComponent<Props, State> {
	public readonly state = new State();
	private onDestroy$: Subject<void> = new Subject();

	public componentDidMount() {
		this.props.tableService.sortBy$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(sortBy => {
			const isActive = sortBy === this.props.columnName;
			if (this.state.active !== isActive) {
				this.setState({active: isActive});
			}
		});

		this.props.tableService.sortAsc$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(asc => {
			if (this.state.asc !== asc) {
				this.setState({asc: asc});
			}
		});
	}

	public render() {
		return <th className={join(styles.tableHeader, this.props.className)}>
			<div onClick={() => this.changeSortBy()}>
				<span>{this.props.displayName}</span>
				<div className='sort_arrows'>
					<div className={'arrow arrow_up' + (this.state.active && this.state.asc ? ' active' : '')}/>
					<div className={'arrow arrow_down' + (this.state.active && !this.state.asc ? ' active' : '')}/>
				</div>
			</div>
		</th>;
	}

	private changeSortBy(): void {
		if (this.state.active) {
			this.props.tableService.sortAsc$.next(!this.props.tableService.sortAsc$.value);
		} else {
			this.props.tableService.sortBy$.next(this.props.columnName);
		}
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
