import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const HeaderBox = styled(GridBox, {
	width: '100%',
	padding: '18px 20px',
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '$8',
	borderBottom: '1px solid $border',
});

export const Divider = styled(Box, {
	background: '$mild',
	width: '100%',
	height: 1,
	marginTop: 8,
	marginBottom: 8,
});
