import {useEffect, useMemo, useState} from 'react';
import {Box} from '@esgi/ui/layout';
import {ConflictAction, ConflictStudentModel, ConflictType, Validation, ValidationType} from '../../../../types';
import {ExistingStudentsInfo} from '../existing-students-info';
import {handleFilterExistingStudentsData} from '../../utils';
import {ConflictActionsPanel} from '../conflict-actions-panel';
import {Language} from '../conflict-controls/language';
import {Name} from '../conflict-controls/name';
import {Id} from '../conflict-controls/id';
import {languages} from './constants';
import {FieldContainer} from './index.styled';

interface Props {
	student: ConflictStudentModel;
	conflictedType: ConflictType;
	validation: { valid: boolean, message: string };
	withDeleteAction: boolean;
	selectedAction: ConflictAction;
	onChangeAction: (id: ConflictAction) => void;
	onViewExistingStudentProfile: (id: string) => void;
	onEdit: (value: string, validation: Validation, action: ConflictAction) => void;
}

export function ConflictedFieldBlock({
	student,
	onEdit,
	conflictedType,
	validation,
	withDeleteAction,
	selectedAction,
	onChangeAction,
	onViewExistingStudentProfile,
}: Props) {

	const [isAddAnywayConflictName, setAddAnywayConflictName] = useState<boolean>(selectedAction === ConflictAction.AddAnyway);
	const [isDisabledAddAnyway, setDisabledAddAnyway] = useState<boolean>(false);
	const [isShowField, setShowField] = useState<boolean>(true);


	useEffect(() => {
		setAddAnywayConflictName(selectedAction === ConflictAction.AddAnyway);
		setShowField(!(selectedAction === ConflictAction.Delete));
	}, [selectedAction]);

	const renderField = useMemo(() => {
		switch (conflictedType) {
			case ConflictType.DuplicateStudentIDN:
				return <Id existingStudentIDs={student.existingStudents.map(x => x.studentIDN)}
						   uploadedStudentID={student.studentIDN.value}
						   onEdit={(value, validation) => onEdit(value, validation, selectedAction)}
						   errorMessage={validation.message}
				/>;

			case ConflictType.DuplicateStudentName:
				return <Name existingStudentNames={student.existingStudents.map(x => x.name)}
							 uploadedStudentName={student.name.value}
							 onEdit={(value, validation) => {
								 setDisabledAddAnyway(validation.type !== ValidationType.Conflict);
								 onEdit(value, validation, selectedAction);
							 }}
							 errorMessage={!isAddAnywayConflictName ? validation.message : ''}
							 isDisabled={isAddAnywayConflictName}
				/>;

			case ConflictType.UnknownLanguage:
				return <Language uploadedLanguage={student.language.value}
								 existingLanguages={languages}
								 onEdit={(value, validation) => onEdit(value, validation, selectedAction)}
								 errorMessage={validation.message}
				/>;
		}
	}, [conflictedType, selectedAction, validation, student, isAddAnywayConflictName]);

	return <>
		{!validation.valid && <Box>
			<ConflictActionsPanel
				onChange={(action) => onChangeAction(action)}
				selectedAction={selectedAction}
				withDeleteAction={withDeleteAction}
				conflictedType={conflictedType}
				isDisabledAddAnyway={isDisabledAddAnyway}
			/>

			<FieldContainer data-cy='conflict-field-container'>
				{isShowField && renderField}
			</FieldContainer>

			{isShowField && conflictedType !== ConflictType.UnknownLanguage &&
				<ExistingStudentsInfo
					data-cy='existing-students-info'
					existingStudentsInfo={handleFilterExistingStudentsData(student, conflictedType)}
					onViewExistingStudentProfile={onViewExistingStudentProfile}
				/>}
		</Box>}
	</>;
}
