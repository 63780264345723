import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {StudentManagerEvents} from '../events';
import {UnassignType} from '../models/models';
import DataService from './data-service';

export default class NotificationService extends BaseService {
	private readonly eventBusManager: EventBusManager = new EventBusManager();

	constructor(private dataService: DataService) {
		super();
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsDeleted, (args) => this.showDeleteStudentNotification(args));
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsAssignedToTeacher, (args) => this.showAssignByTeacherNotification(args));
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsAssignedToSpecialist, (args) => this.showAssignBySpecialistNotification(args));
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsUnassigned, (args) => this.showUnassignNotification(args));
	}

	private showMessage(message: string): void {
		showSnackbarNotification(message);
	}

	private showAssignByTeacherNotification(args: StudentManagerEvents.StudentsAssignedToTeacher): void {
		const {model, studentsIDs, studentName} = args;
		const getSchool = () => this.dataService.data.schools.find(s => s.schoolID === model.schoolID);
		const getClass = () => getSchool()?.teachers
			.find(t => t.teacherID === model.teacherID)?.classes
			.find(c => c.classID === model.classID);
		const getGroup = () => getClass()?.groups.find(g => g.groupID === model.groupID);

		if (studentsIDs.length === 1) {
			if (model.classID && !model.groupID) {
				this.showMessage(`${studentName} has been assigned to ${getClass()?.name}`);
			}

			if (model.groupID) {
				this.showMessage(`${studentName} has been assigned to ${getGroup()?.name}`);
			}

			if (!model.groupID && !model.classID) {
				this.showMessage(`${studentName} has been assigned to ${getSchool()?.name}`);
			}
		} else {
			if (model.classID) {
				this.showMessage(`Students have been assigned to ${getClass()?.name}`);
			}

			if (model.groupID) {
				this.showMessage(`Students have been assigned to ${getGroup()?.name}`);
			}

			if (!model.groupID && !model.classID) {
				this.showMessage(`Students have been assigned to ${getSchool()?.name}`);
			}
		}
	}

	private showAssignBySpecialistNotification(args: StudentManagerEvents.StudentsAssignedToSpecialist): void {
		const {model, studentsIDs, studentName} = args;
		if (model.specialistGroupID) {
			const group = this.dataService.data.specialists
				.find(s => s.userID === model.specialistUserID)?.groups
				.find(x => x.groupID === model.specialistGroupID)?.name;

			if (studentsIDs.length === 1) {
				this.showMessage(`${studentName} has been assigned to ${group}`);
			} else {
				this.showMessage(`Students have been assigned to ${group}`);
			}
		}
	}

	private showUnassignNotification(args: StudentManagerEvents.StudentsUnassigned): void {
		const {studentsIDs, type, studentName, hasGroups} = args;

		if (studentsIDs.length === 1) {
			if (type === UnassignType.PrimaryTeacher) {
				if (hasGroups) {
					this.showMessage(`${studentName} has been unassigned from all classes and groups`);
				} else {
					this.showMessage(`${studentName} has been unassigned from their Primary Teacher`);
				}
			}

			if (type === UnassignType.Specialist) {
				this.showMessage(`${studentName} has been unassigned from their Specialist`);
			}

			if (type === UnassignType.Both) {
				this.showMessage(`${studentName} has been unassigned from all classes and groups`);
			}
		} else {
			if (type === UnassignType.PrimaryTeacher) {
				this.showMessage(`Students have been unassigned from their Primary Teacher`);
			}

			if (type === UnassignType.Specialist) {
				this.showMessage(`Students have been unassigned from their Specialist`);
			}

			if (type === UnassignType.Both) {
				this.showMessage(`Students have been unassigned from all classes and groups`);
			}
		}
	}

	private showDeleteStudentNotification(args: StudentManagerEvents.StudentsDeleted): void {
		if (args.studentIDs.length === 1) {
			this.showMessage(`${args.studentName} has been deleted`);
		} else {
			this.showMessage(`Students have been deleted`);
		}
	}

	public destroy() {
		super.destroy();
		this.eventBusManager.destroy();
	}
}
