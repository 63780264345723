import './styles.less';
import moment from 'moment';
import React from 'react';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {Field, IFormControlValidatorResult, TextInput, Validators} from '@esgi/deprecated/elements';
import {Dropdown, DropdownInModel} from '@esgi/deprecated/elements/dropdown';
import {EventBusManager} from '@esgillc/events';
import {userStorage} from '@esgi/core/authentication';
import {TeacherFormEvents, RemoveClickedEvent, SaveClickedEvent} from './events';
import {HttpClient} from '@esgi/api';

export class Props {
	schoolID: number;
	teacherID: number;
	onSaved: () => void;
	onLoading: () => void;
	onLoaded: (expirationDate?: string) => void;
}

export class State {
	title: DropdownInModel = new DropdownInModel();
	firstName: Field = new Field();
	lastName: Field = new Field();
	login: Field = new Field();
	email: Field = new Field();
	importID: Field = new Field();
	exportID: Field = new Field();
	password: Field = new Field();
	initialModel: TeacherResponse;
	expirationDate: string;
	stateID: number;
	titles: DropdownInModel[] = [
		{id: 1, value: 'Ms.'},
		{id: 2, value: 'Mr.'},
		{id: 3, value: 'Mrs.'},
		{id: 4, value: 'Miss'},
		{id: 5, value: 'Dr.'},
	]
}

class UpdateModel {
	firstName: string;
	lastName: string;
	title: string;
	importID: string;
	exportID: string;
	email: string;
	accountID: string;
	password: string;
	userID: number;
}

class CreateModel {
	firstName: string;
	lastName: string;
	title: string;
	email: string;
	importID: string;
	exportID: string;
	accountID: string;
	password: string;
	userID: number;
	districtID: number;
	schoolID: number;
	creatorID: number;
	stateID: number;
}

class TeacherResponse {
	firstName: string;
	lastName: string;
	title: string;
	importID: string;
	exportID: string;
	expirationDate: string;
	email: string;
	accountID: string;
}

export class TeacherForm extends React.Component<Props, State> {
  private readonly defaultPassword = '||||||||';
	private currentUser = userStorage.get();
	private eventBus = new EventBusManager();
	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	checkTimer: number;

	componentDidMount() {
		this.eventBus.subscribe(SaveClickedEvent, () => {
			this.handleSaveClicked();
		});
		this.eventBus.subscribe(RemoveClickedEvent, () => {
			this.server.remove();
		});
		this.initForm();
		if (this.props.teacherID) {
			this.server.initEdit();
		} else {
			this.server.initCreate();
		}
	}

	componentWillUnmount() {
		this.eventBus.destroy();
		if (this.checkTimer) {
			clearTimeout(this.checkTimer);
		}
	}

	initForm() {
		this.setState({
			stateID: 0,
			firstName: new Field(),
			lastName: new Field(),
			email: new Field(),
			login: new Field(),
			importID: new Field(),
			exportID: new Field(),
			password: new Field(this.props.teacherID ? this.defaultPassword : ''),
			expirationDate: '',
		});
	}

	updateField(field: string, value: string, validation: IFormControlValidatorResult) {
		let newState = {...this.state};

		newState[field] = {
			...this.state[field],
			value: value,
			touched: true,
			validation: validation,
		};

		this.setState(newState);
	}

	getCreateModel() {
		let model = new CreateModel();
		model.accountID = this.state.login.value;
		model.email = this.state.email.value;
		model.firstName = this.state.firstName.value;
		model.lastName = this.state.lastName.value;
		model.title = this.state.title.value;
		model.exportID = this.state.exportID.value;
		model.importID = this.state.importID.value;
		model.password = this.state.password.value === this.defaultPassword ? null : this.state.password.value;
		model.userID = this.props.teacherID;
		model.creatorID = this.currentUser.userID;
		model.schoolID = this.props.schoolID;
		model.districtID = this.currentUser.districtID;
		model.stateID = this.state.stateID;
		return model;
	}

	getUpdateModel() {
		let model = new UpdateModel();
		model.accountID = this.state.login.value;
		model.email = this.state.email.value;
		model.exportID = this.state.exportID.value;
		model.importID = this.state.importID.value;
		model.firstName = this.state.firstName.value;
		model.lastName = this.state.lastName.value;
		model.title = this.state.title.value;
		model.password = this.state.password.value === this.defaultPassword ? null : this.state.password.value;
		model.userID = this.props.teacherID;

		return model;
	}

	server = {
		checkTeacherIDN: (teacherIDN: string) => {
			return HttpClient.default.ESGIApi.get<boolean>('profiles/teacher', 'check-teacher-idn',
				{
					userID: this.props.teacherID,
					districtID: this.currentUser.districtID,
					teacherIDN: teacherIDN,
				});
		},
		checkUserName: (userName: string) => {
			return HttpClient.default.ESGIApi.get<boolean>('profiles/teacher', 'check-user-name',
				{
					userName: userName,
				});
		},
		initEdit: () => {
			this.props.onLoading();
			HttpClient.default.ESGIApi.get<TeacherResponse>('profiles/teacher', 'init/edit',
				{
					teacherID: this.props.teacherID,
				})
				.withCustomErrorHandler(() => this.props.onLoaded())
				.subscribe(response => {
					const expDate = moment(response.expirationDate).format('L');
					this.setState({
						title: this.state.titles.filter(x => x.value === response.title)[0],
						firstName: new Field(response.firstName),
						lastName: new Field(response.lastName),
						email: new Field(response.email),
						login: new Field(response.accountID),
						importID: new Field(response.importID),
						exportID: new Field(response.exportID),
						password: new Field(this.defaultPassword),
						expirationDate: expDate,
						initialModel: response,
					}, () => this.props.onLoaded(expDate));
				});
		},
		initCreate: () => {
			this.props.onLoading();
			return HttpClient.default.ESGIApi.get<number>('profiles/teacher', 'init/create',
				{
					districtID: this.currentUser.districtID,
				}).withCustomErrorHandler(() => this.props.onLoaded())
				.subscribe(stateID => {
					this.setState({
						stateID: stateID,
						title: this.state.titles[0],
					});
				});
		},
		create: () => {
			this.props.onLoading();
			let model = this.getCreateModel();

			return HttpClient.default.ESGIApi.post<{ userID: number, classID: number, className: string }>('profiles/teacher', 'create', model)
				.withCustomErrorHandler(() => this.props.onLoaded())
				.subscribe((result: { userID: number, classID: number, className: string }) => {
					let args = new TeacherFormEvents.Created.Args();
					args.id = result.userID;
					args.firstName = model.firstName;
					args.lastName = model.lastName;
					args.districtID = this.currentUser.districtID;
					args.schoolID = this.props.schoolID;
					args.userID = this.currentUser.userID;
					args.defaultClass = {
						classID: result.classID,
						name: result.className,
					};
					this.eventBus.dispatch(TeacherFormEvents.Created, args);
					this.props.onSaved();
				});
		},
		update: () => {
			this.props.onLoading();
			let model = this.getUpdateModel();
			return HttpClient.default.ESGIApi.post('profiles/teacher', 'form-update', model)
				.withCustomErrorHandler(() => this.props.onLoaded())
				.subscribe(() => {
					let args = new TeacherFormEvents.Changed.Args();
					args.teacherID = this.props.teacherID;
					args.firstName = model.firstName;
					args.lastName = model.lastName;
					this.eventBus.dispatch(TeacherFormEvents.Changed, args);
					this.props.onSaved();
				});
		},
		remove: () => {
			this.props.onLoading();
			OldAlerts.bsconfirm('Are you sure you want to delete this teacher?', (result) => {
				if (result) {
					return HttpClient.default.ESGIApi.post('profiles/teacher', 'remove',
						{userID: this.props.teacherID})
						.withCustomErrorHandler(() => this.props.onLoaded())
						.subscribe(() => {
							let args = new TeacherFormEvents.Removed.Args();
							args.teacherID = this.props.teacherID;
							this.eventBus.dispatch(TeacherFormEvents.Removed, args);
							this.props.onSaved();
						});
				}
			});
		},
	};

	validate() {

		//TODO Costyl. Rewrite it with AsyncTextInput or AsyncValidator.
		if(!this.state.login.value && !this.state.login.touched) {
			this.updateLogin('');
		}

		return this.state.firstName.validation.valid
			&& this.state.lastName.validation.valid
			&& this.state.email.validation.valid
			&& this.state.login.validation.valid
			&& this.state.password.validation.valid;
	}

	handleSaveClicked() {
		this.setState({
			firstName: {...this.state.firstName, touched: true},
			lastName: {...this.state.lastName, touched: true},
			email: {...this.state.email, touched: true},
			login: {...this.state.login, touched: true},
			password: {...this.state.password, touched: true},
		});

		if (this.validate()) {
			this.props.teacherID ? this.server.update() : this.server.create();
		}
	}

	async updateLogin(value: string): Promise<void> {
		let validateResult = Validators.requiredValidator(value);
		this.setState({
			login: {
				...this.state.login,
				validation: validateResult,
				touched: true,
				value: value,
			},
		});

		if (validateResult.valid) {
			validateResult = await this.checkUserName(value);
			if (!validateResult.valid) {
				this.setState({
					login: {
						...this.state.login,
						validation: validateResult,
						touched: true,
						value: value,
					},
				});
			}
		}
	}

	async updateImportID(value: string) {
		let validateResult = Validators.requiredValidator(value);
		this.setState({
			importID: {
				...this.state.importID,
				validation: validateResult,
				touched: true,
				value: value,
			},
		});

		if (validateResult.valid) {
			validateResult = await this.checkImportID(value);
			if (!validateResult.valid) {
				this.setState({
					importID: {
						...this.state.importID,
						validation: validateResult,
						touched: true,
						value: value,
					},
				});
			}
		}
	}

	async checkImportID(importID: string): Promise<IFormControlValidatorResult> {
		return new Promise((rs) => {
			if (importID) {
				clearTimeout(this.checkTimer);
				this.checkTimer = window.setTimeout(() => {
					this.server.checkTeacherIDN(importID).subscribe((exists: boolean) => {
						let valid = this.props.teacherID ? this.state.initialModel.importID == importID || !exists : !exists;
						rs({
							valid: valid,
							message: valid ? '' : 'Import ID ' + importID + ' is already in use. Please choose another.',
						});
					});
				}, 1000);
			}
		});
	}

	async checkUserName(userName: string): Promise<IFormControlValidatorResult> {
		return new Promise((rs) => {
			if (userName) {
				clearTimeout(this.checkTimer);
				this.checkTimer = window.setTimeout(() => {
					this.server.checkUserName(userName).subscribe((exists: boolean) => {
						let valid = this.props.teacherID ? this.state.initialModel.accountID == userName || !exists : !exists;
						rs({
							valid: valid,
							message: valid ? '' : 'Username ' + userName + ' is already in use. Please choose another.',
						});
					});
				}, 1000);
			}
		});
	}
	render() {
		return <div className='content-teacher-form'>
			<div className='form-group'>
				<div className='row'>
					<div className='col-md-2 col-xs-2'>
						<Dropdown
							onClick={(s) => this.setState({title: s})}
							label='Title'
							values={this.state.titles}
							value={this.state.title}
							validator={(value: string) => Validators.requiredValidator(value)}
						/>
					</div>
					<div className='col-md-5 col-xs-5'>
						<TextInput
							label='First Name'
							value={this.state.firstName.value}
							touched={this.state.firstName.touched}
							onEdit={(value, validation) => this.updateField('firstName', value, validation)}
							validator={(value: string) => Validators.requiredValidator(value)}
						/>
					</div>
					<div className='col-md-5 col-xs-5'>
						<TextInput
							label='Last Name'
							value={this.state.lastName.value}
							touched={this.state.lastName.touched}
							onEdit={(value, validation) => this.updateField('lastName', value, validation)}
							validator={(value: string) => Validators.requiredValidator(value)}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<TextInput
							label='Email'
							value={this.state.email.value}
							touched={this.state.email.touched}
							onEdit={(value, validation) => this.updateField('email', value, validation)}
							validator={(value: string) => Validators.emailValidator(value)}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<TextInput
							label='Import ID'
							value={this.state.importID.value}
							validationResult={this.state.importID.validation}
							touched={this.state.importID.touched}
							onEdit={(value) => this.updateImportID(value)}
						/>
					</div>
					<div className='col-md-6'>
						<TextInput
							label='Export ID'
							value={this.state.exportID.value}
							touched={this.state.exportID.touched}
							onEdit={(value, validation) => this.updateField('exportID', value, validation)}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<label className='text-input-label login-info'>Login Information</label>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6'>
						<TextInput
							onEdit={(value) => this.updateLogin(value)}
							label='User Name'
							touched={this.state.login.touched}
							value={this.state.login.value}
							validationResult={this.state.login.validation}
						/>
					</div>
					<div className='col-md-6'>
						<TextInput
							label='Password'
							touched={this.state.password.touched}
							value={this.state.password.value}
							type='password'
							onEdit={(value, validation) => this.updateField('password', value, validation)}
							validator={(value: string) => Validators.minLengthValidator(value, 8, 'Password')}
						/>
					</div>
				</div>
			</div>
		</div>;
	}
}
