import {Users} from '@esgi/ui/icons';
import {IconWrapper, Text} from './index.styled';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	sectionName: string;
};

export function GroupStudentsInfo({sectionName}: Props) {
	return (
		<GridBox gap='3' align='center' flow='column'>
			<IconWrapper>
				<Users />
			</IconWrapper>

			<Text size='large'>{sectionName}</Text>
		</GridBox>
	);
}
