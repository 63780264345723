import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Row = styled(GridBox, {
	justifyContent: 'center',
	gap: '$5',
	marginBottom: '$5',
	minWidth: 0,

	'@media screen and (min-width: 1200px)': {
		gridAutoFlow: 'column',
		gridTemplateColumns: '1fr 1fr',
		maxWidth: '1200px',
		minWidth: '1020px',
	},
});

export const Container = styled(FlexBox, {
	flexFlow: 'column wrap',
	width: '48%',
	border: '2px solid $neutral94',
	maxWidth: '580px',
	minWidth: '580px',
});

export const TestName = styled(Box, {
	width: '100%',
	padding: '2px',
	textAlign: 'center',
	backgroundColor: '$neutral94',

	[`& > ${Text}`]: {
		fontWeight: 'bold',
		fontSize: '22px',
	},
});
