import {useState} from 'react';
import {FormElement} from '@esgillc/ui-kit/form';
import {
	Dropdown,
	Label,
	Option,
	SelectableList,
	SelectableListItem,
	TextArea,
} from '@esgillc/ui-kit/form-control';
import {join, useStreamEffect} from '@esgillc/ui-kit/utils';
import {getUser} from '@esgi/core/authentication';
import {AdditionalFormType} from 'modules/forms/students-form/components/forms/additional';
import {ErrorText} from 'modules/forms/students-form/components/error-text';
import {ProfileInitData} from 'modules/forms/students-form/types';
import {FormDateInput} from '../form-date-input';
import styles from './styles.module.less';

interface Props {
	form: AdditionalFormType,
	initData: ProfileInitData
	isViewMode?: boolean
}

export function AdditionalInfoForm({form, initData}: Props) {

	const {lunchStatuses, races, specialPrograms} = initData.dictionary;
	const [textAreaLettersCount, setTextAreaLettersCount] = useState(form.controls.comments.value.length);

	useStreamEffect(form.controls.comments.onChanged, ({currState}) => {
		setTextAreaLettersCount(currState.value?.length);
	});

	return <div data-cy='additional-info-form' className={styles.formWrapper}>
		<FormElement disableValidateOnChange className={styles.dateInput} control={form.controls.birthDate}>
			<Label className={styles.topLabel}>Date of Birth</Label>
			<FormDateInput inputClassName={styles.datePickerInput} labelClassName={styles.datePickerLabel}/>
			<ErrorText showOnError='laterThanToday'>Date of birth cannot be set in the future.</ErrorText>
			<ErrorText showOnError='invalidDate'>Please enter a valid date.</ErrorText>
		</FormElement>

		<div data-cy='race' className={styles.ipadView}>
			<FormElement control={form.controls.race} className={join(styles.dropdown, styles.raceIpad)}>
				<Label className={styles.topLabel}>Race and Ethnicity</Label>
				<Dropdown placeholderClassName={styles.dropdownPlaceholder}
						  optionName='name' placeholder='Select Race and Ethnicity'>
					{races.map(v => <Option key={v.id} value={v}>{v.name}</Option>)}
				</Dropdown>
			</FormElement>
		</div>

		<FormElement
			control={form.controls.race}
			className={join(styles.dropdown, styles.raceDropdown, styles.desktopView)}
		>
			<Label className={styles.topLabel}>Race and Ethnicity</Label>
			<Dropdown
				placeholderClassName={styles.dropdownPlaceholder}
				placeholder={'Select Race and Ethnicity'}
				optionName='name'
			>
				{races.map(v => <Option key={v.id} value={v}>{v.name}</Option>)}
			</Dropdown>
		</FormElement>

		<FormElement
			control={form.controls.lunchStatus}
			className={join(styles.dropdown, styles.lunchStatus)}
		>
			<Label className={styles.topLabel}>Lunch Status</Label>
			<Dropdown
				placeholderClassName={styles.dropdownPlaceholder}
				placeholder={'Select Lunch Status'}
				optionName='name'
			>
				{lunchStatuses.map(v => <Option key={v.id} value={v}>{v.name}</Option>)}
			</Dropdown>
		</FormElement>

		<div data-cy={'special-programs-and-comment'} className={join(styles.row, styles.specialPrograms)}>
			<div>
				<div className={styles.labelContainer}>
					<Label className={styles.topLabel}>Special Programs</Label>
				</div>
				<div className={styles.specialProgramsList}>
					<FormElement control={form.controls.programs} className={styles.formContainer}>
						<SelectableList checkboxPlacement='left' className={styles.selectableList}>
							{specialPrograms.map(v => <SelectableListItem disabled={!getUser().canEditStudentMetaFields}
																		  className={styles.selectableListItem}
																		  key={v.name}
																		  value={v}>{v.name}</SelectableListItem>)}
						</SelectableList>
					</FormElement>
				</div>
			</div>
		</div>
		<div data-cy='comments'>
			<FormElement control={form.controls.comments} className={styles.commentBox}>
				<Label className={styles.topLabel}>Comments</Label>
				<div className={styles.textareaContainer}>
					<TextArea maxLength={500} className={styles.textarea}
							  placeholder='Enter special accommodations such as allergies, disability, etc.'/>
					<span className={styles.counter}>{textAreaLettersCount}/500</span>
				</div>
			</FormElement>
		</div>
	</div>;
}



