import {useAutoControlledState} from '@esgi/ui';
import {useCallback, useEffect} from 'react';

export function usePagination({
	pageSize = 14,
	totalElements,
	pageIndex,
	onChange,
}: {
	pageSize?: number;
	totalElements: number;
	pageIndex?: number;
	onChange?: (page: number) => void;
}) {
	const [currentPageIndex, setCurrentPageIndex] = useAutoControlledState({
		initialState: 0,
		controlledState: pageIndex,
		onChange,
	});

	const totalPages = Math.ceil(totalElements / pageSize);

	useEffect(() => {
		if (currentPageIndex + 1 > totalPages) {
			pageChange(0);
		}
	}, [currentPageIndex, totalPages]);

	const pageChange = useCallback(
		(nextPageIndex: number) => {
			setCurrentPageIndex(nextPageIndex);
			onChange?.(nextPageIndex);
		},
		[setCurrentPageIndex, onChange],
	);

	const nextPage = useCallback(() => {
		const nextPageIndex = Math.min(totalPages - 1, currentPageIndex + 1);

		pageChange(nextPageIndex);
	}, [pageChange, currentPageIndex, totalPages]);

	const previousPage = useCallback(() => {
		const nextPageIndex = Math.max(0, currentPageIndex - 1);

		pageChange(nextPageIndex);
	}, [pageChange, currentPageIndex]);

	return {
		currentPageIndex,
		totalPages,
		pageChange,
		nextPage,
		previousPage,
	};
}
