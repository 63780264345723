import React, {createRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {ModalManager} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {IEPStatusModel} from '../../models';

import styles from './styles.module.less';

interface Props {
    onClose: () => void;
	onRemoveStatus: (statusID: number, statusName: string) => void;
    status: IEPStatusModel;
}

export default class RemoveStatusDialog extends React.PureComponent<Props> {
	private modalManagerRef: React.RefObject<ModalManager> = createRef();

	public render() {
		return <Alert className={styles.modal} modalManagerRef={this.modalManagerRef}>
			<Alert.Body className={styles.body}>
                <div>
                    <p className={styles.headerText}>Remove Status?</p>
                    <p className={styles.bodyText}>
											Deleting this status will remove all previous information associated with that status for all users in the district.
										</p>
                </div>
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text className={styles.button} onClick={this.cancelClicked}>
					CANCEL
				</Buttons.Text>
				<Buttons.Text className={styles.button} onClick={this.removeClicked}>
					REMOVE
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}

	private cancelClicked = () => {
		this.modalManagerRef.current.close(this.props.onClose);
	};

	private removeClicked = () => {
		this.modalManagerRef.current.close(() => this.props.onRemoveStatus(this.props.status.id, this.props.status.name));
	};
}