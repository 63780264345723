import {BaseService} from '@esgi/core/service';
import {Class, Group, Student, classesStore, studentsStore, groupsStore} from '@esgi/main/libs/store';
import {BehaviorSubject} from 'rxjs';

export class DataStudentsService extends BaseService {
	public isLoadedData$ = new BehaviorSubject(false);
	public students$ = new BehaviorSubject<Student[]>([]);
	public classes$ = new BehaviorSubject<Class[]>([]);
	public groups$ = new BehaviorSubject<Group[]>([]);

	private studentsStorage = studentsStore();
	private classesStorage = classesStore();
	private groupsStorage = groupsStore();

	constructor() {
		super();
		this.isLoadedData$.next(false);

		this.studentsStorage.get().subscribe(students => {
			if(students.loaded) {
				this.students$.next(students.data);
				if(!this.isLoadedData$.value) {
					this.isLoadedData$.next(true);
				}
			}
		});

		this.classesStorage.get().subscribe(classes => {
			if(classes.loaded) {
				this.classes$.next(classes.data);
				if(!this.isLoadedData$.value) {
					this.isLoadedData$.next(true);
				}
			}
		});

		this.groupsStorage.get().subscribe(groups => {
			if(groups.loaded) {
				this.groups$.next(groups.data);
				if(!this.isLoadedData$.value) {
					this.isLoadedData$.next(true);
				}
			}
		});
	}

	public override dispose() {
		super.dispose();
		this.studentsStorage.dispose();
		this.classesStorage.dispose();
		this.groupsStorage.dispose();
	}
}
