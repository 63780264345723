import {PropsWithChildren} from 'react';
import {Button, Close, IconComponent, Tooltip} from '@esgi/ui';
import {ActionButtons, Content, ContentWrapper, Header} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Divider} from '../divider.styled';
import {GridBox} from '@esgi/ui/layout';

type WithoutAction = {
	withActionButton?: false,
}

type TooltipProps = {
tooltipText?: string;
}

type WithAction = {
	withActionButton: true;
	actionButtonText: string;
	actionButtonDisabled?: boolean;
	onActionButtonClick: VoidFunction;
}

type Props = {
	Icon: IconComponent;
	sectionName: string;
	closeDrawer?: VoidFunction;
	childrenAlignment?: 'left' | 'right',
} & (WithoutAction | WithAction) & PropsWithChildren & TooltipProps;

export function DrawerHeader({Icon, tooltipText, sectionName, children, childrenAlignment = 'right', ...props}: Props) {
	return <Header>
		<ContentWrapper>
			<GridBox flow='column' gap='4'>
				<Content gap='1' align='center' flow='column'>
					<Tooltip delayDuration={400}>
						<Tooltip.Trigger>
							<span>
								<Icon />
							</span>
						</Tooltip.Trigger>
						{tooltipText && <Tooltip.Content variant='secondary'>
							<Text size='small'>{tooltipText}</Text>
						</Tooltip.Content>}
					</Tooltip>
					<Text size='small' data-cy='drawer-title'>{sectionName}</Text>
				</Content>
				{childrenAlignment === 'left' && children}
			</GridBox>
			<ActionButtons>
				{childrenAlignment === 'right' && children}
				{props.withActionButton && (
					<Button color='primary' disabled={Boolean(props.actionButtonDisabled)} onClick={props.onActionButtonClick}>
						<Text size='large'>{props.actionButtonText}</Text>
					</Button>
				)}

				<Button.Icon onClick={props.closeDrawer} withBackgroundHover dataCy='close-button'>
					<Close />
				</Button.Icon>
			</ActionButtons>
		</ContentWrapper>
		<Divider />
	</Header>;
}
