import * as React from 'react';
import type {SVGProps} from 'react';

export function RainbowRing(props: SVGProps<SVGSVGElement>): JSX.Element {
	return <svg width='48' height='48' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' {...props}>
		<defs>
			<linearGradient id='rainbowGradient' gradientUnits='userSpaceOnUse' x1='0' y1='24' x2='48' y2='24'
							gradientTransform='rotate(270,24,24)'>
				<stop offset='0%' stopColor='#FF0000'/>
				<stop offset='16.66%' stopColor='#FFA500'/>
				<stop offset='33.33%' stopColor='#FFFF00'/>
				<stop offset='50%' stopColor='#008000'/>
				<stop offset='66.66%' stopColor='#0000FF'/>
				<stop offset='83.33%' stopColor='#4B0082'/>
				<stop offset='100%' stopColor='#FF0000'/>
			</linearGradient>
		</defs>
		<circle cx='24' cy='24' r='21' fill='none' stroke='url(#rainbowGradient)' strokeWidth='6'/>
	</svg>;
}
