import {PropsWithChildren, useMemo} from 'react';
import {MultipleSelectedState, SingleSelectedState} from '@esgi/main/kits/common';
import {TestsPanelContext} from './context';

export type Props = PropsWithChildren<{
	studentsState: SingleSelectedState | MultipleSelectedState,
}>;

export function TestsPanelRoot({studentsState, children}: PropsWithChildren<Props>) {
	const contextValue = useMemo(() => ({
		studentsState,
	}), [studentsState]);

	return (
		<TestsPanelContext.Provider value={contextValue}>
			{children}
		</TestsPanelContext.Provider>
	);
}

export * from './context';