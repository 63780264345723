export class LinkedContinueContentTemplate {
	public static Render() {
		return <div className='registration-modal'>
			<div className='inner-modal'>
				<div className='header'>
					<div className='title-container'>
						<div className='title'><b>Thanks for Creating Your
							Account!</b><span>Finish setting up your account now</span></div>
					</div>
				</div>
				<div className='fields'>
					<div>
						<div data-bind='render:fullName'>
						</div>
						<label>Timezone</label>
						<div data-bind='render:timezone'>
						</div>
						<label>Track</label>
						<div data-bind='render:track'>
						</div>
						<label className='control-label'>Grade Level - select all that apply to you:</label>
						<div data-bind='render:gradeLevel'>
						</div>
					</div>
				</div>
				<div className='footer'>
					<div className='actions'>
						<button className='btn cancel-btn' data-bind='click:cancel'>CANCEL</button>
						<button className='btn next-btn' data-bind='click:next'>DONE</button>
					</div>
				</div>
			</div>
		</div>;
	}
}
