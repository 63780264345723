import {DetailedProps, join} from '@esgillc/ui-kit/utils';
import {forwardRef} from 'react';
import styles from './styles.module.less';

interface Props extends DetailedProps<HTMLDivElement> {
	whiteBackground?: boolean;
}

export const QuestionLayout = forwardRef<HTMLDivElement, Props>(({whiteBackground, ...props}, ref) => (
	<div
		{...props}
		className={join(styles.questionLayout, whiteBackground && styles.white)}
	/>
));
