import React, {ReactNode} from 'react';
import {HttpClient} from '@esgi/api';
import {Loader} from '@esgi/deprecated/jquery';
import {useState} from '@esgi/deprecated/react';
import {StudentManagerEvents} from '../../../../../../events';
import {RemoveModel, UnassignType} from '../../../../../../models/models';
import StudentsService from '../../../../../../services/students-service';
import TableService from '../../../../../../services/table-service';
import UnassignButton from './unassign-button';
import UnassignModal from './unassign-modal';
import {EventBusDispatcher} from '@esgillc/events';

class State {
	modalOpened: boolean = false;
}

class Props {
	studentsService: StudentsService;
	tableService: TableService;
}

@useState(State)
export default class UnassignOperation extends React.PureComponent<Props, State> {
	private loader: Loader = new Loader('body');

	public render() {
		return <>
			<UnassignButton onClicked={(e) => this.setState({modalOpened: true})}/>
			{this.renderModal()}
		</>;
	}

	private renderModal(): ReactNode {
		if (this.state.modalOpened) {
			const students = this.props.studentsService.getStudents(...this.props.tableService.selectedStudentsIDs$.value);
			return <UnassignModal close={() => this.setState({modalOpened: false})}
			                      process={(type) => this.unassignSelectedStudents(type)}
			                      students={students}/>;
		}
	}

	private unassignSelectedStudents(type: UnassignType): void {
		let selectedStudents = this.props.tableService.selectedStudentsIDs$.value;
		this.loader.mask();

		HttpClient.default.ESGIApi.post<RemoveModel>('student-explorer', 'unassign', {studentIDs: selectedStudents, type: type})
			.subscribe((result) => this.setState({modalOpened: false}, () => {
				this.loader.unmask();
				const student = this.props.studentsService.getStudents(selectedStudents[0])[0];
				const args = {
					studentsIDs: selectedStudents,
					studentName: student?.firstName + ' ' + student?.lastName,
					hasGroups: !!student.groupIDs.length,
					type: type,
					removeModel: result,
				} as StudentManagerEvents.StudentsUnassigned;
				EventBusDispatcher.dispatch(StudentManagerEvents.StudentsUnassigned, args);
			}));
	}
}
