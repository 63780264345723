import {IndicatorBox} from './indicator.styled';

export function IndicatorFailed() {
	return (
		<IndicatorBox
			css={{
				backgroundColor: '$negativeVivid',
			}}
		/>
	);
}
