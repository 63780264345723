import {Answer, AnswerState} from '../../../types';

export function getCorrectAnswersCount(answers: Answer[]) {
	return answers.reduce((currentSum, {answerState}) => {
		if (answerState === AnswerState.Correct) {
			return currentSum + 1;
		}

		return currentSum;
	}, 0);
}
