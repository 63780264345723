import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {Checkbox} from '@esgi/ui/controls';

export const TileCard = styled(FlexBox, {
	position: 'relative',
	width: '100%',
	alignItems: 'center',
	justifyContent: 'start',
	flexDirection: 'column',
	background: '$surface',
	border: 'solid 1px $surface',
	borderRadius: 12,
	overflow: 'hidden',
	cursor: 'pointer',

	variants: {
		selected: {
			true: {
				background: '$primaryBackground',
				border: 'solid 1px $primaryHighlight',
			},
		},
	},

	'&:hover': {
		background: '$secondaryBackground',
		border: 'solid 1px $secondaryBackground',
	},
});

export const StyledCheckbox = styled(Checkbox, {
	position: 'absolute',
	top: 6,
	left: 6,
	zIndex: 1001,
	background: 'none',
	'& > div': {
		background: '$background',
	},
	'&:hover': {
		background: 'none',
	},
	variants: {
		entered: {
			true: {
				'& > div': {
					background: '$background !important',
					'& svg path': {
						fill: '$secondary !important',
					},
				},
			},
		},
	},
});

export const ImageWrapper = styled(FlexBox, {
	position: 'relative',
	width: '100%',
	background: 'black',
	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				height: 162,
				width: '100%',
				background: '$background',
			},
		},
		selected: {
			true: {
				'&:after': {
					content: '',
					left: 0,
					position: 'absolute',
					top: 0,
					zIndex: 1,
					width: '100%',
					height: '100%',
					opacity: 0.2,
					background: 'black',
				},
			},
		},
		entered: {
			true: {
				'&:after': {
					content: '',
					left: 0,
					position: 'absolute',
					top: 0,
					zIndex: 1,
					width: '100%',
					height: '100%',
					opacity: 0.2,
					background: 'black',
				},
			},
		},
	},

});

export const Image = styled('img', {
	height: 162,
	width: '100%',
	objectFit: 'contain',
	background: '$neutral',
	cursor: 'zoom-in',
	zIndex: 1000,
});

export const Row = styled(FlexBox, {
	width: '100%',
	gap: 6,
	alignItems: 'center',
});

export const StyledTileName = styled(FlexBox, {
	justifyContent: 'space-between',
	height: 56,
	width: '100%',
	padding: 12,
	borderBottom: 'solid 1px $border',
	variants: {
		selected: {
			true: {
				borderBottom: 'solid 1px $primaryHighlight',
			},
		},
		skeleton: {
			true: {
				background: '$background',
				width: '100%',
				padding: 0,
				...SkeletonStyles,
			},
		},
	},
});

export const StyledMetaWrapper = styled(GridBox, {
	gap: '$3',
	width: '100%',
	padding: 12,
});
