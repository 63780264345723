import {useMemo} from 'react';
import {useItemAnalysisService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Text} from '@esgi/ui/typography';
import {Message} from './index.styled';

export function EmptyReport() {
	const service = useItemAnalysisService();
	const entity = useBehaviorSubject(service.entity$);
	const report = useBehaviorSubject(service.reportService.reportData$);
	const noStudents = report && (report.students || []).length === 0;
	const noAnswers = report && report.questions.flatMap(({answers}) => answers).length === 0;
	const hasStudents = useMemo(() => {
		if (entity.classId) {
			const {studentIDs = []} = service.studentService.getClass(entity.classId) || {};
			return Boolean(studentIDs.length);
		}
		if (entity.groupId) {
			const {studentIDs = []} = service.studentService.getGroup(entity.groupId) || {};
			return Boolean(studentIDs.length);
		}
	}, [entity]);
	const message = useMemo(() => {
		if (noStudents && !hasStudents) {
			return `There are no students in this ${entity.classId ? 'class' : 'group'}`;
		}
		if (noAnswers || hasStudents) {
			return `There is no test data for any student in this ${entity.classId ? 'class' : 'group'}`;
		}
		return '';
	}, [entity.classId, noStudents, noAnswers, hasStudents]);

	return (
		<Message>
			<Text>{message}</Text>
		</Message>
	);
}
