import {Avatar} from '@esgi/ui/avatar';
import {Cast, Question, Setting} from '@esgi/ui/icons';
import {NavigationBar} from '@esgi/ui/layout';
import {UserInfo} from '../../../../types';
import {HelpButtonIcon} from '../../../help-button-icon';
import {OutlinedButtonIcon} from '../../../outlined-button-icon';
import {CustomNavItem} from '../../../custom-nav-item';
import {AvatarHoverLayer} from '../../avatar-hover-layer';
import {AvatarFallback} from '../../avatar-fallback';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import React from 'react';

interface Props {
	onAvatarClick: () => void;
	onCastClick: () => void;
	onSettingsClick: () => void;
	onHelpClick: () => void;
	userInfo: UserInfo;
}

export function HorizontalPanelBottom({
	onAvatarClick,
	onSettingsClick,
	onCastClick,
	onHelpClick,
	userInfo,
}: Props) {
	return (
		<NavigationBar.Bottom
			css={{
				paddingBottom: '6px',
				paddingTop: '6px',
			}}
		>
			<NavigationBar.Item
				dataCy='nav-item-cast'
				onClick={onCastClick}
				css={{marginRight: '6px'}}
			>
				<Tooltip>
					<Tooltip.Trigger>
						<OutlinedButtonIcon large>
							<Cast width={24} height={24} />
						</OutlinedButtonIcon>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>Student Screen</Text>
					</Tooltip.Content>
				</Tooltip>
			</NavigationBar.Item>

			<NavigationBar.Item
				dataCy='nav-item-settings'
				onClick={onSettingsClick}
				css={{marginRight: '6px', marginLeft: '6px'}}
			>
				<Tooltip>
					<Tooltip.Trigger>
						<OutlinedButtonIcon large>
							<Setting width={24} height={24} />
						</OutlinedButtonIcon>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>System Settings</Text>
					</Tooltip.Content>
				</Tooltip>
			</NavigationBar.Item>
			<NavigationBar.Item
				css={{
					width: '50px',
					height: '50px',
					marginLeft: '10px',
					marginRight: '10px',
					'@media(max-width:420px)': {
						display: 'none',
					},
				}}
			>
				<Tooltip>
					<Tooltip.Trigger>
						<Avatar.Root
							onClick={onAvatarClick}
							size='md'
							borderType='solid'
							css={{
								borderColor: '$mild',
							}}
						>
							<Avatar.Image src={userInfo.image} />
							<AvatarFallback
								firstName={userInfo.firstName}
								lastName={userInfo.lastName}
							/>
							{Boolean(userInfo.image) && <AvatarHoverLayer />}
						</Avatar.Root>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>My Account</Text>
					</Tooltip.Content>
				</Tooltip>
			</NavigationBar.Item>

			<CustomNavItem
				data-cy='nav-item-help'
				onClick={onHelpClick}
				css={{
					marginLeft: '6px',
					'@media(max-width:420px)': {
						display: 'none',
					},
				}}
			>
				<Tooltip>
					<Tooltip.Trigger>
						<HelpButtonIcon
							large
							css={{
								border: '1px solid #FED5C3',
								borderRight: 'none',
							}}
						>
							<Question width={24} height={24} />
						</HelpButtonIcon>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>Help</Text>
					</Tooltip.Content>
				</Tooltip>
			</CustomNavItem>
		</NavigationBar.Bottom>
	);
}
