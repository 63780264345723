import {SVGProps} from 'react';

export const EyeCrossedIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='14'
		height='12'
		viewBox='0 0 14 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M6.99998 3.08333C8.60998 3.08333 9.91665 4.39 9.91665 6C9.91665 6.37917 9.84081 6.735 9.70665 7.0675L11.41 8.77083C12.2908 8.03583 12.985 7.085 13.4108 6C12.4016 3.43917 9.91081 1.625 6.99415 1.625C6.17748 1.625 5.39581 1.77083 4.67248 2.03333L5.93248 3.29333C6.26498 3.15917 6.62081 3.08333 6.99998 3.08333ZM1.16665 1.49083L2.49665 2.82083L2.76498 3.08917C1.79665 3.84167 1.03831 4.845 0.583313 6C1.59248 8.56083 4.08331 10.375 6.99998 10.375C7.90415 10.375 8.76748 10.2 9.55498 9.885L9.79998 10.13L11.5091 11.8333L12.25 11.0925L1.90748 0.75L1.16665 1.49083ZM4.39248 4.71667L5.29665 5.62083C5.26748 5.74333 5.24998 5.87167 5.24998 6C5.24998 6.96833 6.03165 7.75 6.99998 7.75C7.12831 7.75 7.25665 7.7325 7.37915 7.70333L8.28331 8.6075C7.89248 8.8 7.46081 8.91667 6.99998 8.91667C5.38998 8.91667 4.08331 7.61 4.08331 6C4.08331 5.53917 4.19998 5.1075 4.39248 4.71667ZM6.90665 4.26167L8.74415 6.09917L8.75581 6.00583C8.75581 5.0375 7.97415 4.25583 7.00581 4.25583L6.90665 4.26167Z'
			fill='#424242'
		/>
	</svg>
);
