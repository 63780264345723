import {useStudentDetailService} from '../../../../../../hooks';
import {join, useBehaviorSubject} from '@esgi/ui/utils';
import {Checkbox} from '@esgillc/ui-kit/control';
import styles from '../../styles.module.less';

export function DetailReportSettingsShowBlock() {
	const service = useStudentDetailService();
	const reportSettings = useBehaviorSubject(service.reportSettings$);

	return (
		<div className={join(styles.settingsCell, styles.showBlock)}>
			<span className={styles.title}>Show:</span>
			<div className={styles.resultsSettings}>
				<div>
					<Checkbox
						data-cy='show-notes-checkbox'
						id='show-notes-checkbox'
						className={styles.checkbox}
						checked={reportSettings.includeQuestionNotes}
						onClick={() =>
							service.reportSettingValueChange({
								key: 'includeQuestionNotes',
								newValue: !reportSettings.includeQuestionNotes,
							})
						}
					/>
					<label htmlFor='show-notes-checkbox' className={styles.checkboxLabel}>
						<span>Notes</span>
					</label>
				</div>
				<div>
					<Checkbox
						data-cy='show-summary-checkbox'
						id='show-summary-checkbox'
						className={styles.checkbox}
						checked={reportSettings.includeSummaryNotes}
						onClick={() =>
							service.reportSettingValueChange({
								key: 'includeSummaryNotes',
								newValue: !reportSettings.includeSummaryNotes,
							})
						}
					/>
					<label htmlFor='show-summary-checkbox' className={styles.checkboxLabel}>
						<span>Summary</span>
					</label>
				</div>
				<div>
					<Checkbox
						data-cy='show-color-checkbox'
						id='show-color-checkbox'
						className={styles.checkbox}
						checked={reportSettings.printInColor}
						onClick={() =>
							service.reportSettingValueChange({
								key: 'printInColor',
								newValue: !reportSettings.printInColor,
							})
						}
					/>
					<label htmlFor='show-color-checkbox' className={styles.checkboxLabel}>
						<span>In Color</span>
					</label>
				</div>
			</div>
		</div>
	);
}
