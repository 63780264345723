import {CloseIcon, CloseIconProps} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import styles from './report-modal.module.less';

export const ReportModalCloseIcon = ({className, ...props}: CloseIconProps) => (
	<CloseIcon
		color='#FFFFFF'
		className={join(styles.closeIcon, className)}
		{...props}
	/>
);
