import {useCallback, useMemo, useState} from 'react';
import {useFilterItems} from '../../use-filter-items';
import {Answer, Question, TestInfo, TestSession, UpdateYNSessionRequestParams} from '../../../../../types';
import {useEditableCommonState} from '../../../hooks/use-editable-common-state';
import {AnswerState} from '../../../../../../kit';
import {concatNotTestedQuestions} from '../../utils';

export function useEditModeState({
	sessionInfo: defaultSessionInfo,
	testInfo,
	onUpdateTestSession,
}: {
	sessionInfo: TestSession;
	testInfo: TestInfo,
	onUpdateTestSession: (args: {
		answers: UpdateYNSessionRequestParams['answers'];
		notes: string;
		testDateTouched: boolean;
		testDate: string;
	}) => void;
}) {
	const {
		dateTimeRef,
		setIsSessionTimeInfoValid,
		setSessionTimeInfoTouched,
		sessionNote,
		onUpdateSessionNote,
		isCommonDataTouched,
		isCommonDataValid,
		sessionTimeInfoTouched,
	} = useEditableCommonState({
		sessionInfo: defaultSessionInfo,
	});

	const defaultAnswerStates = useMemo(
		() =>			
			concatNotTestedQuestions(testInfo, defaultSessionInfo.answers).reduce(
				(currentState, {questionID, answerState, comment}) => {
					currentState[questionID] = {
						state: answerState,
						comment,
					};
					return currentState;
				},
				{} as Record<
					Question['id'],
					{
						state: AnswerState;
						comment: string | null;
					}
				>,
			),
		[defaultSessionInfo.answers, testInfo],
	);

	const [answerState, setAnswerState] = useState(concatNotTestedQuestions(testInfo, defaultSessionInfo.answers));

	const {filterItems, correctAnswers, setCorrectAnswers, setIncorrectAnswers, setNotTestedAnswers} = useFilterItems({
		sessionInfo: defaultSessionInfo,
	});

	const updateAnswersState = useCallback(
		({questionID, newState}: {questionID: Question['id']; newState: Partial<Omit<Answer, 'questionID'>>}) => {
			setAnswerState((currentAnswersState) =>
				currentAnswersState.map((iteratedState) =>
					iteratedState.questionID !== questionID
						? iteratedState
						: {
								...iteratedState,
								...newState,
						  },
				),
			);
		},
		[],
	);

	const onAddComment = useCallback(
		({questionID, comment}: {questionID: Question['id']; comment: string}) => {
			updateAnswersState({questionID, newState: {comment}});
		},
		[updateAnswersState],
	);

	const onDeleteComment = useCallback(
		({questionID}: {questionID: Question['id']}) => {
			updateAnswersState({questionID, newState: {comment: null}});
		},
		[updateAnswersState],
	);

	const toggleQuestionState = useCallback(
		({
			questionId,
			newState,
			previousState,
		}: {
			questionId: Question['id'];
			newState: AnswerState;
			previousState: AnswerState;
		}) => {
			updateAnswersState({
				questionID: questionId,
				newState: {
					answerState: newState,
				},
			});

			if (newState === AnswerState.Correct) {
				setCorrectAnswers((currentValue) => currentValue + 1);
			}

			if (previousState === AnswerState.Correct) {
				setCorrectAnswers((currentValue) => currentValue - 1);
			}

			if (newState === AnswerState.Incorrect) {
				setIncorrectAnswers((currentValue) => currentValue + 1);
			}

			if (previousState === AnswerState.Incorrect) {
				setIncorrectAnswers((currentValue) => currentValue - 1);
			}

			if (newState === AnswerState.NotTested) {
				setNotTestedAnswers((currentValue) => currentValue + 1);
			}

			if (previousState === AnswerState.NotTested) {
				setNotTestedAnswers((currentValue) => currentValue - 1);
			}
		},
		[setCorrectAnswers, setIncorrectAnswers, setNotTestedAnswers, updateAnswersState],
	);

	const handleSave = useCallback(() => {
		const dateTime = dateTimeRef.current?.getFullDate();

		onUpdateTestSession({
			testDateTouched: sessionTimeInfoTouched,
			answers: answerState,
			testDate: dateTime ?? defaultSessionInfo.testDate,
			notes: sessionNote,
		});
	}, [answerState, dateTimeRef, defaultSessionInfo.testDate, onUpdateTestSession, sessionNote, sessionTimeInfoTouched]);

	const isDataTouched = Boolean(
		isCommonDataTouched ||
			answerState.some(
				({questionID, comment, answerState}) =>
					defaultAnswerStates[questionID] &&
					(defaultAnswerStates[questionID]!.comment !== comment ||
						defaultAnswerStates[questionID]!.state !== answerState),
			),
	);

	const isDataValid = isCommonDataValid;

	return {
		isDataTouched,
		correctAnswers,
		filterItems,
		setSessionTimeInfoTouched,
		setIsSessionTimeInfoValid,
		dateTimeRef,
		answerState,
		toggleQuestionState,
		sessionNote,
		onUpdateSessionNote,
		isDataValid,
		handleSave,
		onAddComment,
		onDeleteComment,
	};
}
