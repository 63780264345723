import {SubjectSectionState} from './state-sections-models';

export enum SubjectTabId {
	All = 'all',
	District = 'district',
	School = 'school',
	Personal = 'personal',
}


export type SubjectBelongsTo = keyof Pick<SubjectSectionState, 'district' | 'school' | 'personal'>;
