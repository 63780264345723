import React, {Dispatch, useMemo, useState} from 'react';
import {ProfileHeader} from './components/test-header';
import {TestSection} from './components/test-section';
import {SessionType} from './components/session-type';
import {NewSession} from './components/new-session';
import {TestInfo} from './components/test-info';
import {Resume} from './components/resume';
import {IncludeType, Session, StartData, StudentModel, SubjectModel, TestLauncherData} from '../../../types';
import {DrawerContainer, ProfileBody, QuestionsWrapper, TestInfoContainer} from './styled';
import {TestingModel} from '../../models';
import {Class} from '@esgi/main/libs/store';


interface Props {
	onClose: VoidFunction,
	onStart: (data: StartData) => void,
	student: StudentModel,
	subject: Pick<SubjectModel, 'id' | 'type' | 'name'>,
	data: TestingModel,
	launcherData: TestLauncherData,
	studentClass?: Class,
	shuffleQuestions: boolean,
	updateShuffleQuestionsValue: Dispatch<boolean>,
}

export function RedesignTestLauncher({
	onStart,
	onClose,
	student,
	subject,
	data,
	launcherData,
	studentClass,
	shuffleQuestions,
	updateShuffleQuestionsValue,
}: Props) {
	const [selectedSession, setSelectedSession] = useState<Session>(Session.NewSession);
	const [includeType, setIncludeType] = useState<IncludeType>(IncludeType.AllQuestions);

	const renderSessions = useMemo(() => {
		if (selectedSession === Session.NewSession) {
			return (
				<NewSession
					shuffleQuestions={shuffleQuestions}
					updateShuffleQuestionsValue={updateShuffleQuestionsValue}
					isIncorrectDisabled={launcherData.isIncorrectDisabled}
					includeType={includeType}
					setIncludeType={setIncludeType}
				/>
			);
		}
		if (selectedSession === Session.Resume) {
			const lastAnsweredQuestionID = data.resumeModel.lastAnsweredQuestionID;

			const lastAnsweredQuestionIDIndex = (
				data.resumeModel.questionsOrder ??
				data.general.originalQuestionIDs ??
				[]
			).findIndex((id) => id === lastAnsweredQuestionID);

			return (
				<Resume
					lastSessionDate={launcherData.analyticsData?.lastSessionDate ?? null}
					progressValue={lastAnsweredQuestionIDIndex !== -1 ? lastAnsweredQuestionIDIndex + 1 : 0}
					progressMaxValue={data.questions.length}
				/>
			);
		}
	}, [
		selectedSession,
		shuffleQuestions,
		updateShuffleQuestionsValue,
		launcherData,
		includeType,
		data,
	]);

	const onStartHandler = () => onStart({
		session: selectedSession,
		include: {
			includeType,
			isShuffleQuestionOrder: shuffleQuestions,
		},
	});

	const showTestInfo = student && data && subject;


	return <DrawerContainer dataCy='start-test-panel'>
			<ProfileHeader
				onClose={onClose}
				onStart={onStartHandler}
			/>
			<ProfileBody>
				<TestSection title='Session'>
					<SessionType
						resumeDisabled={!data.resumeModel.resumeAvailable}
						onChange={setSelectedSession}
						selected={selectedSession}
					/>
				</TestSection>
				<QuestionsWrapper>
					{renderSessions}
				</QuestionsWrapper>
				{showTestInfo && <TestInfoContainer>
					<TestInfo
						testName={data.testName}
						className={studentClass?.name}
						subjectName={subject.name}
						studentName={`${student.firstName} ${student.lastName}`}
					/>
				</TestInfoContainer>}
			</ProfileBody>
		</DrawerContainer>;
}
