import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/features/teacher/home';
import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';

export const DrawerBody = styled(Drawer.Body, {
	gridTemplateRows: 'auto 1fr auto',
});

export const TemplateTranscriptText = styled(Text, {
	textAlign: 'center',
	maxWidth: 280,
	justifySelf: 'center',
});

export const InputHidden = styled('input', {
	display: 'none',

	'&[hidden]': {
		display: 'none',
	},
});

export const InfoBlocsWrapper = styled(GridBox, {
	gap: 8,
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
});

export const InfoBlock = styled(GridBox, {
	paddingTop: 16,
	paddingBottom: 16,
	paddingLeft: 20,
	paddingRight: 20,
	backgroundColor: '$vivid',
	borderRadius: 6,
	gap: 12,
	alignItems: 'center',
	justifyContent: 'center',

	[`& > ${Text}`]: {
		textAlign: 'center',
	},
});

export const InfoBlockItemsWrapper = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	flexWrap: 'wrap',
});

export const InfoBlockItem = styled(Box, {
	display: 'inline-grid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$tertiaryMuted',
	backgroundColor: '$tertiarySurface',
	paddingTop: 8,
	paddingBottom: 8,
	paddingLeft: 12,
	paddingRight: 12,
	margin: 3,

	[`& > ${Text}`]: {
		color: '$orange',
	},
});
