import {ProgressBar, ThemeColorKeys} from '@esgi/ui';
import {useMemo} from 'react';

type Props = {
	value: number;
	maxValue: number;
};

export function ProgressLineBar({value, maxValue}: Props) {
	const {pointerFillColor, progressValueColor} = useMemo<{
		pointerFillColor: ThemeColorKeys;
		progressValueColor: ThemeColorKeys;
	}>(() => {
		const result = value / maxValue;

		if (result > 0.5) {
			return {
				pointerFillColor: 'positive',
				progressValueColor: 'positiveVivid',
			};
		}

		return {
			pointerFillColor: 'mediumContrast',
			progressValueColor: 'neutral72',
		};
	}, [value, maxValue]);

	return (
		<ProgressBar value={value} maxValue={maxValue} dataCy='progress-bar'>
			<ProgressBar.LineWithPointer
				lineColor='border'
				pointerType='rect'
				hidePointer={value === 0}
				pointerFillColor={pointerFillColor}
				progressValueColor={progressValueColor}
				lineStrokeWidth={6}
				pointerWidth={2}
			/>
		</ProgressBar>
	);
}
