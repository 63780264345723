import {MutableRefObject, useState} from 'react';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon, Modal} from '@esgillc/ui-kit/modal';
import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import {HeaderService} from '../../services/header-service';
import {ReportFormat, ReportType} from 'modules/reports/test-history/models';
import styles from './styles.module.less';

interface Props {
	service: HeaderService;
	chartRef: MutableRefObject<HTMLDivElement>;
	onClose: () => void;
}

export function ModalHeader({service, chartRef, onClose}: Props) {
	const [showMenu, setShowMenu] = useState(false);
	const visibleDownload = useBehaviorSubject(service.visibleDownload);
	const visibleDownloadAllStudents = useBehaviorSubject(service.visibleDownloadAllStudents);

	const closeHandler = () => {
		service.dispatchHierarchyEvents();
		onClose();
	};

	const renderItems = () => {
		if (visibleDownloadAllStudents) {
			return (
				<>
					<MenuItem onSelect={() => service.startReportBackgroundGeneration(ReportFormat.Pdf, ReportType.SingleFile)}>
						<b>One PDF file</b>
						one file for all class
					</MenuItem>
					<MenuItem onSelect={() => service.startReportBackgroundGeneration(ReportFormat.Excel, ReportType.SingleFile)}>
						<b>One Excel File</b>
						one file for all class with separate tabs for each student
					</MenuItem>
					<MenuItem onSelect={() => service.startReportBackgroundGeneration(ReportFormat.Pdf, ReportType.Zip)}>
						<b>ZIP PDF files</b>
						Individual PDF files for each students in the class
					</MenuItem>
					<MenuItem onSelect={() => service.startReportBackgroundGeneration(ReportFormat.Excel, ReportType.Zip)}>
						<b>ZIP Excel files</b>
						Individual Excel files for each students in the class
					</MenuItem>
				</>
			);
		}
		if (visibleDownload) {
			return (
				<>
					<MenuItem onSelect={() => service.exportReport(ReportFormat.Pdf)}>PDF</MenuItem>
					<MenuItem onSelect={() => service.exportReport(ReportFormat.Excel)}>Excel</MenuItem>
				</>
			);
		}
	};

	return (
		<Modal.Header className={styles.modalHeader}>
			<div className={styles.reportName}>Test History</div>
			{(visibleDownload || visibleDownloadAllStudents) &&
				<div className={styles.downloadDropdown}>
					<span onClick={() => setShowMenu(!showMenu)}>
						<i className={join('fa fa-download', styles.fa)}/>
						Download
					</span>
					<Menu
						className={join(styles.menu, visibleDownload && styles.small)}
						show={showMenu}
						keepFocusInside
						onClickOutside={() => setShowMenu(false)}
						onEscPressed={() => setShowMenu(false)}
						onClick={() => setShowMenu(false)}
					>
						{renderItems()}
					</Menu>
				</div>
			}
			<CloseIcon
				color='white'
				onClick={closeHandler}
			/>
		</Modal.Header>
	);
}
