export function ComparerTotalReport(sortModel, defaultSortModel, sortRow, displayZero) {

	this.sortModel = sortModel;
	this.defaultSortModel = defaultSortModel;
	this.sortRow = sortRow;
	this.displayZero = displayZero;

	this.normalizeValue = (value) => {
		if (value === 'NT') {
			return -1;
		}
		if (value === '*') {
			return -2;
		}
		if (value === '---') {
			return -3;
		}
		return value;
	};

	this.stringCompare = (left, right, direction) => {
		let l = left.trim();
		let r = right.trim();
		if (direction === 'Desc') {
			r = left.trim();
			l = right.trim();
		}
		if (l > r) {
			return 1;
		}
		if (l < r) {
			return -1;
		}
		return 0;
	};

	this.intCompare = (left, right, direction) => {
		const l = this.normalizeValue(left);
		const r = this.normalizeValue(right);
		if (direction === 'Asc') {
			return l - r;
		}
		if (direction === 'Desc') {
			return r - l;
		}
		return 0;
	};

	this.cellCompare = (leftCell, rightCell, direction, type) => {
		if (type === 'Integer') {
			return this.intCompare(this.displayZero && leftCell.avgPercent !== null ? leftCell.avgPercent : leftCell.value, this.displayZero && rightCell.avgPercent !== null ? rightCell.avgPercent : rightCell.value, direction);
		}
		if (type === 'String') {
			const leftValue = leftCell.value != null ? leftCell.value.toLowerCase() : '';
			const rightValue = rightCell.value != null ? rightCell.value.toLowerCase() : '';
			return this.stringCompare(leftValue, rightValue, direction);
		}

		return 0;
	};

	this.invertDirection = (sourceDirection) => {
		if (sourceDirection === 'Asc') {
			return 'Desc';
		}
		if (sourceDirection === 'Desc') {
			return 'Asc';
		}
		return sourceDirection;
	};

	this.comparer = (left, right) => {
		let colIndex = this.sortModel.columnIndex;
		let direction = this.sortModel.direction;
		let type = this.sortModel.type;
		const associatedSorts = this.sortRow.cells[colIndex].associatedSorts;

		if (direction === 'None') {
			return 0;
		}

		let result = this.cellCompare(left.cells[colIndex], right.cells[colIndex], direction, type);
		if (result === 0 && associatedSorts !== undefined && associatedSorts.length !== 0) {
			for (let i = 0; i < associatedSorts.length; i++) {
				const assIndex = associatedSorts[i].columnIndex;
				const assSortCell = this.sortRow.cells[assIndex];
				const assSortDirection = associatedSorts[i].associatedSortDirection === 'SameDirection' ? direction : this.invertDirection(direction);

				result = this.cellCompare(left.cells[assIndex], right.cells[assIndex], assSortDirection, assSortCell.type);
				if (result !== 0) {
					return result;
				}
			}
		}
		if (result === 0 && this.defaultSortModel) {
			colIndex = this.defaultSortModel.columnIndex;
			direction = this.defaultSortModel.direction;
			type = this.defaultSortModel.type;
			result = this.cellCompare(left.cells[colIndex], right.cells[colIndex], direction, type);
		}
		return result;
	};
}
