import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {animated} from 'react-spring';

export const Container = styled(FlexBox, {
	borderRadius: 2,
	overflow: 'hidden',
});

export const AnswerBar = styled(animated.div, {
	boxSizing: 'border-box',
	height: '100%',

	variants: {
		itemType: {
			correct: {
				borderRightStyle: 'solid',
				borderRightWidth: 2,
				borderRightColor: '$positive',
				backgroundColor: '$positiveMuted',
			},

			incorrect: {
				borderRightStyle: 'solid',
				borderRightWidth: 2,
				borderRightColor: '$mediumContrast',
				backgroundColor: '$mild',
			},

			notTested: {
				backgroundColor: '$vivid',
				borderStyle: 'solid',
				borderWidth: 1,
				borderColor: '$mild',
			},
		},
	},
});
