import {TagParams} from './types';

export const createElementFromHTML = (htmlString: string): HTMLElement => {
	const div = document.createElement('div');
	div.innerHTML = htmlString.trim();
	return div.firstChild as HTMLElement;
};


export const createInsideTagElement = (tag: TagParams) => {
	return `<span data-id="${tag.value}" data-role="draggable-tag" data-inside="true" contenteditable="false" draggable="true" style="color: #FB6423; user-select: none;">${tag.name}</span>`;
};

export const createInsideTagElementNode = (tag?: TagParams) => {
	if(!tag) {
		return null;
	}
	return createElementFromHTML(createInsideTagElement(tag));
};

export const convertTextToEditorHTML = (text: string, tags: TagParams[]) => {
	if(!text){
		return '';
	}
	const placeholderPattern = /<([a-zA-Z_]+)>/g;

	return text
		.replace(placeholderPattern, (match: string) => {
			const tagObj = tags.find(tag => tag.value === match);
			return tagObj ? createInsideTagElement(tagObj) : `${match.replace('<', '<\u200B')}`;
		});
};

export const isElementInsideEditor = (element: HTMLElement): boolean => {
	return element?.getAttribute('data-inside') === 'true';
};

export const convertEditorHTMLToText = (html?: string): string => {
	if(!html){
		return '';
	}
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	const tags = doc.querySelectorAll('span[data-id]');

	tags.forEach(tag => {
		const dataId = tag.getAttribute('data-id');
		if (dataId) {
			tag.replaceWith(dataId);
		}
	});

	return doc.body.innerHTML
		.replace(/<\/?[^>]+(>|$)/g, '') // Remove any residual HTML tags
		.replace(/&lt;/g, '<') // Convert escaped '<' back to '<'
		.replace(/&gt;/g, '>'); // Convert escaped '>' back to '>'
};