import {ControlValue, FormControl, FormGroup} from '@esgillc/ui-kit/form';
import {SchoolYearTypeModel, TrackModel} from '../types';

export type TrackInfoFormType = FormGroup<{
	track: FormControl<TrackModel[]>,
	type: FormControl<SchoolYearTypeModel[]>
}, ControlValue<{ track: FormControl<TrackModel[]>, type: FormControl<SchoolYearTypeModel[]> }>>


export const createTrackInfoForm = (): TrackInfoFormType =>
	new FormGroup({
		track: new FormControl<TrackModel[]>([]),
		type: new FormControl<SchoolYearTypeModel[]>([]),
	});
