import {Textarea} from '@esgi/ui/controls';
import {TestInfo, TestSession} from '../../../../../types';
import {SessionTimeInfo} from '../../../../session-time-info';
import {noop} from 'underscore';
import {AnswersInfo, ProgressWithPointer} from '../../../../../../kit';
import {ProgressInfoContainer} from './index.styled';
import {AlertBody} from '../../../../components.styled';
import {TestName} from '../../../../test-name';
import {TestType} from '@esgi/main/libs/core';
import {AlertFooter} from '../../../../alert-footer';
import {AlertHeader} from '../../../../alert-header';
import {Student} from '@esgi/main/libs/store';
import {AlertHeaderDeletedSession} from '../../../../alert-header/deleted-session';
import {AlertFooterDeletedSession} from '../../../../alert-footer-deleted-session';

type Props = {
	canEdit: boolean;
	sessionInfo: TestSession;
	testInfo: TestInfo;
	onCloseAlert: VoidFunction;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	student: Student;
	onDeleteSession: VoidFunction;
	onRestoreSession: VoidFunction;
	onDownloadSession: VoidFunction;
};

export function ViewModeContent({
	canEdit,
	sessionInfo,
	testInfo,
	onCloseAlert,
	setIsEditMode,
	student,
	onDeleteSession,
	onDownloadSession,
	onRestoreSession,
}: Props) {
	return (
		<>
			{sessionInfo.isDeleted ? (
				<AlertHeaderDeletedSession onCloseAlert={onCloseAlert} student={student} />
			) : (
				<AlertHeader
					canEdit={canEdit}
					isEditMode={false}
					onCloseAlert={onCloseAlert}
					onDeleteSession={onDeleteSession}
					onEditModeClick={() => setIsEditMode(true)}
					student={student}
				/>
			)}
			<AlertBody>
				<TestName name={testInfo.name} testID={testInfo.id} type={TestType.Score} />

				<SessionTimeInfo duration={sessionInfo.duration} testDate={sessionInfo.testDate} />

				<ProgressInfoContainer>
					<ProgressWithPointer value={(sessionInfo.correctAnswers / testInfo.totalPossible) * 100} percentageType>
						<AnswersInfo
							transcriptText='Test Score'
							maxValue={testInfo.totalPossible}
							value={sessionInfo.correctAnswers}
						/>
					</ProgressWithPointer>
				</ProgressInfoContainer>

				<Textarea placeholder='Summary Note' value={sessionInfo.notes ?? ''} disabled onChange={noop}
									data-cy='summary-note'/>
			</AlertBody>

			{sessionInfo.isDeleted ? (
				canEdit && <AlertFooterDeletedSession onRestoreSession={onRestoreSession} />
			) : (
				<AlertFooter isEditMode={false} onDownload={onDownloadSession} />
			)}
		</>
	);
}
