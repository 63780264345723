import {useMemo, useState, useCallback, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {LayoutContainer} from './index.styled';
import {Snackbar} from '@esgi/ui/snackbar';
import {AiAnalysisContext} from './outlet-context';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {ReportTypeOption} from '../model';

function getWindowWidth() {
	const {innerWidth: width} = window;
	return width;
}

export function AiAnalysisLayout() {
	const [windowWidth, setWindowWidth] = useState<number>(getWindowWidth());
	const [studentsPanelOpen, setStudentsPanelOpen] = useState(
		windowWidth >= 1024
	);
	const [selectedReportType, setSelectedReportType] =
		useState<ReportTypeOption | null>(null);
	const [targetReportType, setTargetReportType] =
		useState<ReportTypeOption | null>(null);
	const [showPreviewDrawer, setShowPreviewDrawer] = useState<boolean>(false);
	const [hasPreviewDrawerBeenOpened, setHasPreviewDrawerBeenOpened] =
		useState<boolean>(false);
	const [showSelectionDrawer, setShowSelectionDrawer] =
		useState<boolean>(false);
	const [showTranslatePanel, setShowTranslatePanel] = useState<boolean>(false);
	const [showTranslateDrawer, setShowTranslateDrawer] =
		useState<boolean>(false);
	const [showExitPreviewDialog, setShowExitPreviewDialog] =
		useState<boolean>(false);
	const [showGeneratePreviewDialog, setShowGeneratePreviewDialog] =
		useState<boolean>(false);
	const [canEdit, setCanEdit] = useState<boolean>(false);

	const handleClosePreviewPanel = useCallback(() => {
		setShowPreviewDrawer(false);
		setShowTranslatePanel(false);
		setShowExitPreviewDialog(false);
		setShowGeneratePreviewDialog(false);
		setCanEdit(false);
	}, []);

	const snackbarRef = Snackbar.useRef();

	useEffect(() => {
		function handleResize() {
			setWindowWidth(getWindowWidth());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const toggleStudentsPanelOpen = useCallback(() => {
		if (windowWidth < 1024) {
			setStudentsPanelOpen((prev) => !prev);
		}
	}, [windowWidth]);

	const outletContext = useMemo<AiAnalysisContext>(
		() => ({
			studentsPanelOpen,
			toggleStudentsPanelOpen,
			selectedReportType,
			setSelectedReportType,
			targetReportType,
			setTargetReportType,
			showPreviewDrawer,
			setShowPreviewDrawer,
			hasPreviewDrawerBeenOpened,
			setHasPreviewDrawerBeenOpened,
			showSelectionDrawer,
			setShowSelectionDrawer,
			showTranslatePanel,
			setShowTranslatePanel,
			showTranslateDrawer,
			setShowTranslateDrawer,
			showExitPreviewDialog,
			setShowExitPreviewDialog,
			showGeneratePreviewDialog,
			setShowGeneratePreviewDialog,
			canEdit,
			setCanEdit,
			handleClosePreviewPanel,
			snackbarRef,
			windowWidth,
		}),
		[
			studentsPanelOpen,
			toggleStudentsPanelOpen,
			selectedReportType,
			setSelectedReportType,
			targetReportType,
			setTargetReportType,
			showPreviewDrawer,
			setShowPreviewDrawer,
			hasPreviewDrawerBeenOpened,
			setHasPreviewDrawerBeenOpened,
			showSelectionDrawer,
			setShowSelectionDrawer,
			showTranslatePanel,
			setShowTranslatePanel,
			showTranslateDrawer,
			setShowTranslateDrawer,
			showExitPreviewDialog,
			setShowExitPreviewDialog,
			showGeneratePreviewDialog,
			setShowGeneratePreviewDialog,
			canEdit,
			setCanEdit,
			handleClosePreviewPanel,
			snackbarRef,
			windowWidth,
		]
	);

	return (
		<LayoutContainer>
			<Outlet context={outletContext} />
			<Snackbar snackbarRef={snackbarRef} />
			<BackgroundDownloadManager />
		</LayoutContainer>
	);
}
