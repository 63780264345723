import {TestSession, TestInfo} from '../../../../../../types';
import {useDiagramData} from '../../hooks/use-diagram-data';
import {GridBox} from '@esgi/ui/layout';
import {
	pageSize,
	xAxisDiagramGap,
	yAxisDiagramGap,
	yAxisGap,
	yAxisTicksTitleGap,
	yAxixLineChartWidth,
} from './constants';
import {Notes} from './components/notes';
import {Bar, CartesianGrid, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {CartesianGridLineXCoordinates, StackBarName, XAxisId, YAxisId} from './types';
import {BarItemTooltip} from './components/bar-item-tooltip';
import {BarRect} from './components/bar-rect';
import {getThemeColor, isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {BarBackgroundRect} from './components/bar-background-rect';
import {Pagination} from '../../../../../../kit';
import {EmptyDataMessage} from '../empty-data-message';
import {useVisibleData} from '../../hooks/use-visible-data';
import {Property} from '@stitches/react/types/css';
import {Dispatch, useEffect, useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {Subject, debounceTime} from 'rxjs';

type Props = {
	sessions: TestSession[];
	/**
	 * Prop is uses for class entity. If emptySessionsCount is number, sessions count will be increase on passed value.
	 */
	emptySessionsCount?: number;
	testInfo: TestInfo;
	YAxisLabel: string;
	barLineWidth: number;
	diagramWidth?: Property.Width;
	diagramHeight: number;
	withCartesianGrid?: boolean;
	pageIndex: number;
	onPageIndexChanged: Dispatch<number>;
};

export function DefaultDiagram({
	sessions,
	testInfo,
	YAxisLabel,
	emptySessionsCount = 0,
	barLineWidth,
	diagramWidth,
	diagramHeight,
	withCartesianGrid = false,
	pageIndex,
	onPageIndexChanged,
}: Props) {
	const [cartesianGridLineXCoordinates, setCartesianGridLineXCoordinates] =
		useState<CartesianGridLineXCoordinates | null>(null);

	const {data, sessionsCount} = useDiagramData({sessions, testInfo, emptySessionsCount});

	const {visibleData, currentPageIndex, totalPages, nextPage, previousPage, sessionNote} = useVisibleData({
		data,
		pageSize,
		sessions,
		pageIndex,
		onPageIndexChanged,
	});

	const YAxisTicksCount = sessionsCount + 1;

	const containerRef = useRef<HTMLDivElement>(null);

	const {current: resizeObserverEntry$} = useRef(new Subject<ResizeObserverEntry>());

	useEffect(() => {
		const subscription = resizeObserverEntry$.pipe(debounceTime(300)).subscribe(({target}) => {
			const rechartsXAxisTicks = target.querySelectorAll(
				'.recharts-xAxis.xAxis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick',
			);

			if (rechartsXAxisTicks.length < 2) {
				setCartesianGridLineXCoordinates(null);
				return;
			}

			const firstTick = rechartsXAxisTicks[0]!;
			const lastTick = rechartsXAxisTicks[rechartsXAxisTicks.length - 1]!;

			const start = firstTick.querySelector('.recharts-text')?.getAttribute('x');
			const end = lastTick.querySelector('.recharts-text')?.getAttribute('x');

			if (isUndefined(start) || isNull(start) || isUndefined(end) || isNull(end)) {
				setCartesianGridLineXCoordinates(null);
				return;
			}

			setCartesianGridLineXCoordinates({
				start: Number(start),
				end: Number(end),
			});
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useResizeObserver(containerRef, (value) => {
		resizeObserverEntry$.next(value);
	});

	if (!data.length) {
		return <EmptyDataMessage />;
	}

	return (
		<GridBox dataCy='diagram' gap='4' css={{width: diagramWidth}} ref={containerRef}>
			<ResponsiveContainer height={diagramHeight}>
				<ComposedChart
					data={visibleData}
					margin={{top: 24, right: 0, bottom: 0, left: 0}}
					barCategoryGap={5}
					barGap={5}
				>
					{withCartesianGrid && !isNull(cartesianGridLineXCoordinates) && (
						<CartesianGrid
							vertical={false}
							strokeDasharray='2.5'
							stroke={getThemeColor('muted')}
							x={cartesianGridLineXCoordinates.start}
							width={cartesianGridLineXCoordinates.end - cartesianGridLineXCoordinates.start}
						/>
					)}

					<YAxis
						yAxisId={YAxisId.YBarChart}
						tickCount={YAxisTicksCount}
						domain={[0, sessionsCount]}
						interval='preserveStartEnd'
						tickSize={0}
						tickMargin={0}
						tick={{
							fontSize: 10,
							fill: getThemeColor('blue'),
						}}
						axisLine={false}
					>
						<Label
							position='insideTopRight'
							value={YAxisLabel}
							offset={0}
							dy={-yAxisTicksTitleGap}
							fontSize={10}
							fill={getThemeColor('blue')}
						/>
					</YAxis>

					<YAxis
						yAxisId={YAxisId.YLineChart}
						tickCount={YAxisTicksCount}
						domain={[0, 'auto']}
						interval='preserveStartEnd'
						tickSize={0}
						tickMargin={0}
						tick={{
							fontSize: 10,
							fill: getThemeColor('purple'),
						}}
						axisLine={false}
						width={yAxixLineChartWidth}
						dx={-yAxisGap}
						// @TODO: should be removed, when should be implemented AVG time diagram
						hide
					>
						<Label
							position='insideTopRight'
							value='Sec'
							offset={0}
							dy={-yAxisTicksTitleGap}
							dx={-yAxisGap}
							fontSize={10}
							fill={getThemeColor('purple')}
						/>
					</YAxis>

					<XAxis
						xAxisId={XAxisId.XBarChart}
						tickLine={false}
						tickSize={0}
						tickMargin={xAxisDiagramGap}
						axisLine={false}
						tickFormatter={(_, index) =>
							isNull(visibleData[index]) ? '' : String(currentPageIndex * pageSize + index + 1)
						}
						padding={{
							right: barLineWidth / 2,
							left: barLineWidth + yAxisDiagramGap,
						}}
						scale='point'
						tick={{
							fontSize: 10,
							fill: getThemeColor('lowContrast'),
						}}
					>
						<Label position='left' value='Question' offset={0} dy={5} fontSize={10} fill={getThemeColor('lowContrast')} />
					</XAxis>

					<Bar
						yAxisId={YAxisId.YBarChart}
						xAxisId={XAxisId.XBarChart}
						stackId={StackBarName.AnswerInfo}
						dataKey='correct'
						barSize={barLineWidth}
						fill={getThemeColor('positiveMuted')}
						strokeWidth={1}
						stroke={getThemeColor('positiveMuted')}
						background={<BarBackgroundRect />}
						shape={<BarRect itemType='correct' borderTopColor='positive' dataCy={`correct-green`}/>}
						activeBar={false}
					/>

					<Bar
						yAxisId={YAxisId.YBarChart}
						xAxisId={XAxisId.XBarChart}
						stackId={StackBarName.AnswerInfo}
						dataKey='incorrect'
						barSize={barLineWidth}
						fill={getThemeColor('mild')}
						strokeWidth={1}
						stroke={getThemeColor('mild')}
						shape={<BarRect itemType='correct' borderTopColor='mediumContrast' dataCy='incorrect-grey'/>}
						activeBar={false}
					/>

					<Line
						yAxisId={YAxisId.YLineChart}
						xAxisId={XAxisId.XBarChart}
						type='linear'
						dataKey='sessionTimeInSec'
						stroke={getThemeColor('purple')}
						strokeWidth={1.5}
						dot={{
							stroke: getThemeColor('purple'),
							strokeWidth: 1.5,
							fill: getThemeColor('secondaryBackground'),
						}}
						// @TODO: should be update by prop, which toggle AVG time diagram
						hide
					/>

					<Tooltip cursor={false} content={<BarItemTooltip sessionsCount={sessionsCount} />} />
				</ComposedChart>
			</ResponsiveContainer>
			{totalPages > 1 && (
				<GridBox justify='end'>
					<Pagination
						currentPageIndex={currentPageIndex}
						nextPage={nextPage}
						previousPage={previousPage}
						totalPages={totalPages}
					/>
				</GridBox>
			)}
			{sessionNote && <Notes sessionNote={sessionNote} contentPaddingLeft={32} />}
		</GridBox>
	);
}
