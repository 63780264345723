import React from 'react';
import {ExportContainer} from '../container/container-export';
import {
	Modal,
	ModalBody,
	ModalHeader,
	KnockoutWrapper,
} from '@esgi/deprecated/react';

class State {
	loaded: boolean = false;
}

class Props {
	close: () => any;
}

export default class ModalExport extends React.Component<Props, State> {
	private readonly export: ExportContainer;

	constructor(props, context) {
		super(props, context);
		this.state = new State();

		this.export = new ExportContainer();
	}

	render() {
		return (<Modal loading={!this.state.loaded} className='white-header' onCatchError={() => this.props.close()}>
			<ModalHeader title='Export' close={{click: () => this.props.close()}}/>
			<ModalBody>
				<KnockoutWrapper obj={this.export} loaded={() => {
					//Just for avoid blink of modal.
					setTimeout(() => this.setState({loaded: true}));
				}}/>
			</ModalBody>
		</Modal>);
	}
}