import {useService} from '@esgi/core/service';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {useStream} from '@esgillc/ui-kit/utils';
import {useEffect, useState} from 'react';
import {TableResults} from './components/table/table-results';
import DataService from '../../../../../../../../services/data-service';
import {AvailableTest, ContentAreaModel} from '../../../../../../../../models';
import {Input, Option, Dropdown} from '@esgillc/ui-kit/control';
import {AddSelfAssessService} from './service';
import styles from './body.module.less';

interface Props {
	dataService: DataService;
	selectedTestIds: number[];
	loading: (loading: boolean) => void;
	updateAddMoreTestsDisabled?: () => void;
	testIdsChanged: (ids: number[]) => void;
	children?: any;
}

const allContentOption: Readonly<ContentAreaModel> = {id: -1, name: 'All Content Areas'};

export function Body(props: Props) {
	const service = useService(AddSelfAssessService);
	const contentAreas = useStream(service.contentAreas$, [allContentOption]);
	const tests = useStream(service.tests$);
	const [keyword, setKeyword] = useState('');
	const [unselectedTests, setUnselectedTests] = useState<AvailableTest[]>([]);
	const [selectedContentArea, setSelectedContentArea] = useState<ContentAreaModel[]>([allContentOption]);

	useEffect(() => {
		if (tests) {
			props.loading(false);
			setUnselectedTests(tests.filter(t => t.id !== props.dataService.availableTests.map(t => t.id).find(id => id === t.id)));
		}
	}, [tests]);

	const addTestToSubject = (testID: number) => {
		const newTest = tests.find(t => t.id === testID);
		const availableTests = props.dataService.availableTests;
		props.dataService.availableTests = [...availableTests, ...[newTest]];
		props.testIdsChanged([testID]);

		const subject = props.dataService.selectedSubject;
		if (subject) {
			service.addTestToSubject(newTest.id, subject.id, subject.type).subscribe(() => {
				setUnselectedTests(unselectedTests.filter(t => t.id !== testID));
				showSnackbarNotification(`You've added ${newTest.name} to ${subject.name}`);
			});
		}
		props.updateAddMoreTestsDisabled && props.updateAddMoreTestsDisabled();
	};

	return <div className={styles.addTest}>
		<p className={styles.containerText}>Find and select self-assess tests to assign to your student.</p>
		<div className={styles.subjectName}>
			<p>Add to Subject:</p> <p>{props.dataService.selectedSubject.name}</p>
		</div>
		<div className={styles.searchContainer}>
			<div className={styles.keywordContainer}>
				<Input
					placeholder='Search by test name or author'
					value={keyword}
					onChange={(ch) => {
						ch.preventDefault();
						setKeyword(ch.target.value.toLowerCase());
					}}/>
			</div>
			<div className={styles.filterByContainer}>
				<label>Filter by:</label>
				<Dropdown autoWidth optionName='name' value={selectedContentArea} setValue={setSelectedContentArea}>
					<Option key={-1} value={allContentOption}>All Content Areas</Option>
					{contentAreas.map(i => <Option key={i.id} value={i}>{i.name}</Option>)}
				</Dropdown>
			</div>
		</div>
		<TableResults
			unSelectedTests={unselectedTests}
			contentAreaId={selectedContentArea[0]?.id || -1}
			keyword={keyword}
			testChecked={addTestToSubject}
			selectedTestIds={props.selectedTestIds}
		/>
	</div>;
}
