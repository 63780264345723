import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {Checkbox} from '@esgi/ui/controls';
import {Info} from '@esgi/ui/icons';

export const CardLayout = styled(Box, {
	padding: 12,
	width: '100%',
	border: '1px solid $vivid',
	borderRadius: 12,
	background: '$vivid',
	position: 'relative',
});

export const StyledCheckbox = styled(Checkbox, {
	padding: 8,
	borderRadius: 6,
	width: 97,
	justifyContent: 'start',
	variants: {
		enabled: {
			true: {
				border: `1px solid $primaryMuted`,

				'&:hover': {
					border: `1px solid $secondaryMuted`,

				},
			},
			false: {
				border: `1px solid transparent`,
			},
		},
	},
});

export const WidgetContainer = styled(Box, {
	padding: '16px 8px 0 8px',
});

export const Body = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',
	rowGap: '20px',
	gridAutoFlow: 'row',
});
export const AverageIconBox = styled(Box, {
	position: 'absolute',
	zIndex: 1,
	right: '20px',
	top: '20px',
});
export const InfoIcon = styled(Info, {
	width: '24px',
	height: '24px',
});
