import {useState, useEffect} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {Radio, Hint, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {Modal, useModal, useCloseModal, Title, HelpLink} from '@esgillc/ui-kit/modal';
import {FormControl, useForm, FormGroup, Validators, Form, FormElement, ElementStatus} from '@esgillc/ui-kit/form';
import {Example} from '../../../../../components/example';
import {exampleImageUrl, helpLink, maxCustomizeNameLength} from './constants';
import styles from './styles.module.less';

interface Props {
	correctLabel: string;
	incorrectLabel: string;
	testButtonsLabelsEnabled: boolean;
	onClose: () => void;
	onSave: (testButtonsLabelsEnabled: boolean, correctLabel: string, incorrectLabel: string) => void;
}

export function TestButtonLabelsModal({
	                                      onClose,
	                                      correctLabel,
	                                      incorrectLabel,
	                                      testButtonsLabelsEnabled,
	                                      onSave,
}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const form = useForm(() => new FormGroup({
		testButtonsLabelsEnabled: new FormControl<number>(Number(testButtonsLabelsEnabled)),
		correctLabel: new FormControl<string>(correctLabel, {validators: [Validators.required(), Validators.length.max(maxCustomizeNameLength)]}),
		incorrectLabel: new FormControl<string>(incorrectLabel, {validators: [Validators.required(), Validators.length.max(maxCustomizeNameLength)]}),
	}));

	const [correctLabelValue, setCorrectLabelValue] = useState(correctLabel);
	const [incorrectLabelValue, setIncorrectLabelValue] = useState(incorrectLabel);
	const [isHintsVisible, setHintsVisible] = useState(false);

	useEffect(() => {
		changeInputsEnabled(testButtonsLabelsEnabled);
	}, []);
	useStreamEffect(form.onChanged, (v) => {
		const isVerbiagesEnabled = !!v.currState.value.testButtonsLabelsEnabled;
		setCorrectLabelValue(v.currState.value.correctLabel);
		setIncorrectLabelValue(v.currState.value.incorrectLabel);
		changeInputsEnabled(isVerbiagesEnabled);
		setHintsVisible(isVerbiagesEnabled);
	});

	const changeInputsEnabled = (isEnabled: boolean) => {
		form.controls.incorrectLabel.status = isEnabled ? null : ElementStatus.disabled;
		form.controls.correctLabel.status = isEnabled ? null : ElementStatus.disabled;
	};

	const handleSave = () => {
		const {testButtonsLabelsEnabled, correctLabel: correctLabel, incorrectLabel: incorrectLabel} = form.value;
		form.validate().subscribe(result => {
			if (result.valid) {
				onSave(!!testButtonsLabelsEnabled, correctLabel, incorrectLabel);
				handleClose();
			}
		});

	};

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title>
				Test Button Labels
				<HelpLink url={helpLink}/>
			</Title>
		</Modal.Header>

		<Modal.Body className={styles.body}>
			<p className={styles.description}>Label buttons to indicate whether the student answered the question
				correctly or
				not.</p>
			<Form controller={form}>
				<FormElement control={form.controls.testButtonsLabelsEnabled}>
					<div data-cy={'yn'} className={styles.correctRow}>
						<Radio value={0}>Yes | No</Radio>
					</div>

					<div data-cy={'customize'} className={styles.row}>
						<Radio value={1}>Customize</Radio>
						<div className={styles.row}>
							<div data-cy={'correct-label'}>
								<FormElement control={form.controls.correctLabel} className={styles.formElement}>
									<Input maxLength={maxCustomizeNameLength} placeholder={'i.e. Y'}/>
									<OnErrorTooltip showOnError={'required'} placement={'top'}>
										Enter a custom label for the test button to indicate whether the question was
										answered correctly.
									</OnErrorTooltip>
									<OnErrorTooltip showOnError={'length-max'} placement={'top'}>
										Enter a custom label for the test button to indicate whether the question was
										answered correctly.
									</OnErrorTooltip>
									<Hint className={styles.hint} hidden={!correctLabelValue.length || !isHintsVisible}>
										{maxCustomizeNameLength - correctLabelValue.length} characters
										left {maxCustomizeNameLength} max
									</Hint>
								</FormElement>
							</div>
							<div data-cy={'incorrect-label'}>
								<FormElement control={form.controls.incorrectLabel} className={styles.formElement}>
									<Input maxLength={maxCustomizeNameLength} placeholder={'i.e. N'}/>
									<OnErrorTooltip showOnError={'required'} placement={'right'}>
										Enter a custom label for the test button to indicate whether the question was
										answered correctly.
									</OnErrorTooltip>
									<OnErrorTooltip showOnError={'length-max'} placement={'right'}>
										Enter a custom label for the test button to indicate whether the question was
										answered correctly.
									</OnErrorTooltip>
									<Hint className={styles.hint}
									      hidden={!incorrectLabelValue.length || !isHintsVisible}>
										{maxCustomizeNameLength - incorrectLabelValue.length} characters
										left {maxCustomizeNameLength} max
									</Hint>
								</FormElement>
							</div>
						</div>
					</div>
				</FormElement>
			</Form>
			<Example className={styles.example}
			         imgUrl={exampleImageUrl}
			         text='Example of button labels on a test screen:'
			         fullWidth
			/>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Gray onClick={handleClose}> Cancel </Buttons.Gray>
			<Buttons.Contained onClick={handleSave}> Save </Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
