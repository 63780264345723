import {Checkbox} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import React from 'react';
import styles from './show-results-filter.module.less';
import {ReportFiltersLabel} from '@esgi/deprecated/ui-kit/report/filters/report-filters-label';
import {ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters/report-filters-container';
import {FiltersLabelBlock} from 'modules/reports/student-progress-report/components/common/filters-label-block';
import {FiltersContentBlock} from 'modules/reports/student-progress-report/components/common/filters-content-block';
import {join} from '@esgillc/ui-kit/utils';


interface Props {
	carryScoresForward: boolean;
	showBaseline: boolean;
	onCarryForwardClicked: (carryScoresForward: boolean) => void;
	onShowBaseLineClicked: (showBaseLine: boolean) => void;
}

export function ShowResultsFilter(props: Props) {
	return <ReportFiltersContainer className={styles.columnOrientation}>
			<FiltersLabelBlock>
				<ReportFiltersLabel>Results:</ReportFiltersLabel>
			</FiltersLabelBlock>
			<FiltersContentBlock className={styles.rowOrientation}>
					<Checkbox
						className={join(styles.checkbox, props.carryScoresForward ? styles.selected : '')}
						onClick={() => props.onCarryForwardClicked(!props.carryScoresForward)}
						checked={props.carryScoresForward}>
						Carry forward
						<OnHoverTooltip message='Help'>
							<a
								href='https://support.esgisoftware.com/hc/en-us/articles/360024914451-Student-Progress-Report'
								target='_blank' rel='noreferrer'>
								<sup className={`fa fa-question-circle ${styles.icon}`} aria-hidden='true'/>
							</a>
						</OnHoverTooltip>
					</Checkbox>
				<OnHoverTooltip message='Show Baseline'>
					<Checkbox
						className={join(styles.checkbox, props.showBaseline ? styles.selected : '')}
						onClick={() => props.onShowBaseLineClicked(!props.showBaseline)}
						checked={props.showBaseline}>
						Show Baseline
					</Checkbox>
				</OnHoverTooltip>
			</FiltersContentBlock>
		</ReportFiltersContainer>;
}
