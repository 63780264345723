import './radio.less';
import React from 'react';

class Props {
	checked: boolean;
	label?: string;
	onSelect?: (value: boolean) => any;
	onClick?: (e) => any;
	id: string;
	className?: string;
	name: string;
	disabled?: boolean;
	subLabel?: string;
	title?: string;
}

/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class Radio extends React.PureComponent<Props> {
	render() {
		return <div
			className={this.props.className ? 'radio-button-component ' + this.props.className : 'radio-button-component'}
			title={this.props.title}>
			<input type='radio' className='ace'
			       disabled={this.props.disabled}
			       id={this.props.id}
			       name={this.props.name}
			       onChange={(e) => this.props.onSelect ? this.props.onSelect(e.target.checked) : () => false}
			       onClick={(e) => this.props.onClick && this.props.onClick(e)}
			       checked={this.props.checked}/>
      <label className='lbl' htmlFor={this.props.id}>{this.props.label}</label>
			{this.props.subLabel && <span className='sub-label'>{this.props.subLabel}</span>}
		</div>;
	}
}
