import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {BehaviorSubject, Observable, combineLatest, debounceTime, switchMap, of, tap, skip} from 'rxjs';
import {UnitDataSettings, PrintSettings, Session, ResultsResponse, Test, DownloadRequest} from '../types';
import {FileTypeType, StudentWithUnit} from '@esgi/main/kits/activities';
import {GenerateService} from './generate-service';
import {DownloadService} from './download-service';
import {StudentService} from './student-service';
import {SubjectService} from './subject-service';
import {V2ActivitiesFlashCardsController} from '@esgi/contracts/esgi';

const wordsCount = 7;

export class FlashcardsService extends BaseService {
	public readonly students$ = new BehaviorSubject<StudentWithUnit[]>([]);
	public readonly subject$ = new BehaviorSubject(0);
	public readonly sessions$ = new BehaviorSubject<Session['id'][]>([]);
	public readonly include$ = new BehaviorSubject<PrintSettings['printPutOption']>(null);
	public readonly cardSize$ = new BehaviorSubject<PrintSettings['cardsPerPage']>(null);
	public readonly fileType$ = new BehaviorSubject<FileTypeType>(FileTypeType.Pdf);
	public readonly results$ = new BehaviorSubject<ResultsResponse>(null);
	public readonly unitDataSettings$ = new BehaviorSubject<UnitDataSettings>(null);
	public readonly printSettings$ = new BehaviorSubject<PrintSettings>(null);
	public readonly tests$ = new BehaviorSubject<Test[]>([]);
	public readonly selectAll$ = new BehaviorSubject(0);
	public readonly loading$ = new BehaviorSubject(false);
	public readonly totalPages$: Observable<number> = this.completeOnDestroy(
		combineLatest([this.students$])
	).pipe(
		debounceTime(100),
		switchMap(([students]) => {
			const words = 14;
			const pagesPerStudent = Math.ceil(wordsCount / words);
			return of(pagesPerStudent * students.length * 0);
		}),
	);
	public readonly valid$: Observable<boolean> = this.completeOnDestroy(
		combineLatest([this.students$, this.sessions$])
	).pipe(
		debounceTime(100),
		switchMap(([students, sessions]) => {
			return of(Boolean(students.length) && Boolean(sessions.length));
		}),
	);
	public readonly busy$ = new BehaviorSubject<boolean>(false);
	public studentService = new StudentService();
	public subjectService = new SubjectService();
	protected generateService = new GenerateService();
	protected downloadService = new DownloadService();
	protected currentUser = getUser();
	private readonly controller = new V2ActivitiesFlashCardsController();
	private prevSubject = 0;

	public init(initialSubjectID: number | null) {
		this.completeOnDestroy(
			combineLatest([this.include$])
		).pipe(
			skip(2),
			debounceTime(100),
			tap(([include]) => {
				this.controller.savePrintout({value: include.toString()}).subscribe();
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.cardSize$])
		).pipe(
			skip(2),
			debounceTime(100),
			tap(([cardsPerPage]) => {
				this.controller.saveCardsPerPage({
					userID: this.currentUser.userID,
					cardsPerPage,
				}).subscribe();
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.students$, this.subject$])
		).pipe(
			debounceTime(500),
			tap(([students, subjectId]) => {
				if (students.length && subjectId) {
					const subject = this.subjectService.subjects$.value.find(({id}) => id === subjectId);
					if (this.prevSubject !== subjectId) {
						this.loading$.next(true);
						this.prevSubject = subjectId;
					}
					this.controller.init({
						subjectID: subject.id,
						subjectType: subject.type,
						readOnly: false,
						studentIDs: students.map(({studentId}) => studentId),
					}).subscribe({
						next: ({value: {tests}}) => this.tests$.next((tests || []).map((test) => ({
							...test,
							sessions: test.studentSessionResults.map((session) => ({
								...session,
								id: `${test.id}-${session.studentID}`,
								studentName: this.studentService.getName(session.studentID),
							})),
						}))),
						complete: () => {
							this.selectAll$.next(this.selectAll$.value + 1);
							this.loading$.next(false);
						},
					});
				}
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.sessions$, this.subject$])
		).pipe(
			debounceTime(100),
			tap(([sessions, subjectId]) => {
				if (!subjectId) {
					return;
				}

				const {
					id: subjectID,
					name,
					type: subjectType,
					level: subjectLevel,
				} = this.subjectService.subjects$.value.find(({id}) => id === subjectId);

				this.unitDataSettings$.next({
					subject: {
						subjectID,
						name,
						subjectType,
						subjectLevel: subjectLevel.toString(),
					},
					sessions,
					tests: this.tests$.value,
					students: this.students$.value,
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.include$, this.cardSize$]),
		).pipe(
			debounceTime(100),
			tap(([printPutOption, cardsPerPage]) => {
				this.printSettings$.next({
					printPutOption,
					cardsPerPage,
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.downloadService.busy, this.downloadService.busyController])
		).pipe(
			debounceTime(100),
			tap(([busy, busyController]) => this.busy$.next(busy || busyController)),
		).subscribe();

		this.completeOnDestroy(
			this.subjectService.loaded$
		).pipe(tap((isLoaded) => {
			if (!isLoaded) {
				return;
			}

			this.subject$.next(initialSubjectID ?? this.subjectService.getByIndex(0)?.id ?? 0);
		})).subscribe();
	}

	public destroy() {
		super.destroy();
		this.generateService.destroy();
		this.downloadService.destroy();
		this.subjectService.destroy();
		this.studentService.destroy();
	}

	public override dispose() {
		this.controller.dispose();
	}

	public setStudents(value: StudentWithUnit[]) {
		this.students$.next(value);
	}

	public setSubject(value: number) {
		this.subject$.next(value);
	}

	public setInclude(value: PrintSettings['printPutOption']) {
		this.include$.next(value);
	}

	public setCardSize(value: PrintSettings['cardsPerPage']) {
		this.cardSize$.next(value);
	}

	public setFileType(value: FileTypeType) {
		this.fileType$.next(value);
	}

	public setSessions(value: Session['id'][]) {
		this.sessions$.next(value);
	}

	public onPreview(callback: VoidFunction) {
		const generateSettings = this.generateReport();
		this.downloadService.preview(generateSettings).subscribe(
			({value}) => {
				this.results$.next(value);
				callback();
			},
		);
	}

	public onDownload(reportGuid: DownloadRequest['reportGuid'] = null) {
		const generateSettings = this.generateReport();
		this.downloadService.downloadPdf({
			reportGuid,
			generateSettings: reportGuid ? null : generateSettings,
		});
	}

	private generateReport() {
		return this.generateService.generateReport(
			this.unitDataSettings$.value,
			this.printSettings$.value
		);
	}
}
