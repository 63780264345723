import {
	ElementStatus,
	ErrorTooltip,
	Form,
	FormControl,
	FormElement,
	FormGroup,
	Validators,
	useForm,
} from '@esgi/ui/form';
import {Text} from '@esgi/ui/typography';
import {Input} from '@esgi/ui/form-controls';
import {useStreamEffect} from '@esgi/ui';
import {useEffect} from 'react';

type Props = {
	initialClassName?: string;
	restoredClassName?: string;
	setIsFormValid: (value: boolean) => void;
	onFormTouched: (value: boolean) => void;
	setClassName: (value: string) => void;
};

export function ClassNameForm({initialClassName = '', restoredClassName = '', setIsFormValid, setClassName, onFormTouched}: Props) {
	const form = useForm(
		() =>
			new FormGroup({
				className: new FormControl(initialClassName, {
					validators: [Validators.required()],
					disableValueComparison: false,
				}),
			}),
	);

	useEffect(() => {
		form.controls.className.value = initialClassName;
	}, [initialClassName]);

	useEffect(() => {
		form.controls.className.value = restoredClassName;
		form.controls.className.status= ElementStatus.valid;
	}, [restoredClassName]);

	useStreamEffect(form.controls.className.onChanged, ({currState}) => {
		setIsFormValid(currState.status === ElementStatus.valid);
		onFormTouched(currState.value !== initialClassName);
		setClassName(currState.value);
	});

	return (
		<Form controller={form}>
			<FormElement control={form.controls.className}>
				<Input.Base placeholder='Class Name' />

				<ErrorTooltip showOnError='required'>
					<Text size='xSmall' font='mono' color='negativeVivid'>
						Class name is empty
					</Text>
				</ErrorTooltip>
			</FormElement>
		</Form>
	);
}
