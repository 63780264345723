import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Form} from '@esgillc/ui-kit/form';

export const ParentLetterPage = styled(FlexBox, {
	width: '100%',
});

export const LeftSidebar = styled(GridBox, {
	'div': {
		maxWidth: '256px',
	},
});

export const FormWrapper = styled(Form, {
	height: '100%',
});

export const SettingsContainer = styled(GridBox, {
	height: '100%',
	alignContent: 'space-between',
});
