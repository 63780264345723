import {DateTools} from 'global/utils/date-utils';
import {Session} from '../../../../../../types';
import {ListItem, DateText, TimeText, CaptionText, Counter, CounterText} from './index.styled';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useUser} from '@esgi/core/authentication';

interface Props {
	item: Omit<Session, 'id'>;
}

const normalize = (num: number): string => {
	if (num < 10) {
		return '0' + num.toString();
	}
	return num.toString();
};

export function SelectableLisItemContent({item}: Props) {
	const currentUser = useUser();

	const date = new Date(Date.parse(item.testDate));
	let hours = date.getHours();
	const minutes = date.getMinutes();
	const amOrPm = hours >= 12 ? 'pm' : 'am';
	hours = (hours % 12) || 12;
	const time = normalize(hours) + ':' + normalize(minutes);

	return (
		<ListItem data-cy='test-session-info'>
			<DateText data-cy='test-session-date' size='medium' font='mono' bold>{DateTools.toUIString(date)}</DateText>
			<TimeText data-cy='test-session-time' size='small' font='mono' bold type={amOrPm}>{time}</TimeText>
			<CaptionText data-cy='test-session-student-name' size='small'>{item.studentName}</CaptionText>
			<Counter>
				<Tooltip>
					<Tooltip.Trigger>
						<CounterText data-cy='test-session-correct-answers' size='small' font='mono' bold type='correct'>{item.correct}</CounterText>
					</Tooltip.Trigger>
					<Tooltip.Content>
						<Text size='small'>{currentUser?.testResultsCorrectVerbiage ?? 'Correct'} Questions</Text>
					</Tooltip.Content>
				</Tooltip>
				<Tooltip>
					<Tooltip.Trigger>
						<CounterText data-cy='test-session-incorrect-answers' size='small' font='mono' bold type='incorrect'>{item.incorrect}</CounterText>
					</Tooltip.Trigger>
					<Tooltip.Content>
						<Text size='small'>{currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect'} Questions</Text>
					</Tooltip.Content>
				</Tooltip>
				<Tooltip>
					<Tooltip.Trigger>
						<CounterText data-cy='test-session-untested-answers' size='small' font='mono' bold type='untested'>{item.untested}</CounterText>
					</Tooltip.Trigger>
					<Tooltip.Content>
						<Text size='small'>Skipped Questions</Text>
					</Tooltip.Content>
				</Tooltip>
			</Counter>
		</ListItem>
	);
}
