import {useSpring, config} from 'react-spring';

export function useOpacityAnimation() {
	return useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
		config: {
			...config.molasses,
			clamp: true,
		},
	});
}
