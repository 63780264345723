import {TemplateType, Test} from '../models';

export const PRACTICE_TEST: Test = {
	id: 0,
	name: 'Practice Test',
	contentAreaID: 0,
	contentAreaName: 'Practice Test',
	isPractice: true,
	questionsCount: 6,
	questions: [
		{
			id: 1,
			templateType: TemplateType.Practice,
			directionsText: 'Click on each button to hear the description',
			audioDirectionUrl: 'self-assess-images/practice/b6418046-5bea-40c6-a320-238cbd0711d3',
			options: [],
			correctOptionsCount: 0,
		},
		{
			id: 2,
			templateType: TemplateType.With4Columns,
			directionsText: 'Choose the picture of the bed.',
			audioDirectionUrl: 'self-assess-images/practice/33082632-7188-4797-abcc-7b6c8f5fc51b.mp3',
			correctOptionsCount: 1,
			options: [
				{optionID: 1, picUrl: 'self-assess-images/practice/kitten1.png'},
				{optionID: 2, picUrl: 'self-assess-images/practice/snakeee1.png'},
				{optionID: 3, picUrl: 'self-assess-images/practice/esgibed1.png'},
			],
		},
		{
			id: 3,
			templateType: TemplateType.With2Columns,
			directionsText: 'How many apples are there?',
			picUrl: 'self-assess-images/practice/apples.png',
			audioDirectionUrl: 'self-assess-images/practice/463dc8d2-d671-4731-86cc-642fcc6b7a25.mp3',
			correctOptionsCount: 1,
			options: [
				{optionID: 1, text: '1'},
				{optionID: 2, text: '2'},
				{optionID: 3, text: '3'},
			],
		},
		{
			id: 4,
			templateType: TemplateType.With2Columns,
			directionsText: 'Which word goes best in the sentence?',
			picUrl: 'self-assess-images/practice/yellowcap1.png',
			text: 'This is a ______ .',
			audioDirectionUrl: 'self-assess-images/practice/6c34269a-ff93-48e9-876a-d7ed8cc9f49a.mp3',
			correctOptionsCount: 1,
			options: [
				{optionID: 1, text: 'hat', audioUrl: 'self-assess-images/practice/7b2e7988-f216-42a6-ac58-1a83f66f37a5.mp3'},
				{optionID: 2, text: 'cat', audioUrl: 'self-assess-images/practice/06e94e04-fff5-4ea6-b1b0-8d0cff2e0afb.mp3'},
				{optionID: 3, text: 'bat', audioUrl: 'self-assess-images/practice/6e7f1f30-80ef-4a84-a310-0b5e7e56531e.mp3'},
			],
		},
		{
			id: 5,
			templateType: TemplateType.With3Columns,
			directionsText: 'What comes next in the pattern?',
			picUrl: 'self-assess-images/practice/pattern.png',
			audioDirectionUrl: 'self-assess-images/practice/ee58b188-ccec-4588-85eb-2e1a74096a96.mp3',
			correctOptionsCount: 1,
			options: [
				{optionID: 1, picUrl: 'self-assess-images/practice/butterfly2.png'},
				{optionID: 2, picUrl: 'self-assess-images/practice/basketball2.png'},
			],
		},
		{
			id: 6,
			templateType: TemplateType.With1Column,
			directionsText: 'Which two words rhyme?',
			audioDirectionUrl: 'self-assess-images/practice/449897b1-7065-49c1-8730-421cdd8320d6.mp3',
			correctOptionsCount: 2,
			options: [
				{optionID: 1, text: 'Cat', audioUrl: 'self-assess-images/practice/4b2fd5f7-8899-44c5-b678-bc96b0e4565c.mp3'},
				{optionID: 2, text: 'Hat', audioUrl: 'self-assess-images/practice/04fbb049-e6a6-4300-9e47-09641a44788c.mp3'},
				{optionID: 3, text: 'Red', audioUrl: 'self-assess-images/practice/397ed9c0-3b5e-4e89-a7ed-93f516907fc3.mp3'},
			],
		},
	],
};