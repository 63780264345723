import {ReportHeader} from './components/report-header';
import {LevelsLegend} from './components/levels-legend';
import {MarkingPeriodFilter} from '../../../marking-period-filter';
import {ShowResultsFilter} from '../../../show-results-filter';
import {DisplayNotTestedFilter} from '../../../display-not-tested-filter';
import {useStream} from '@esgillc/ui-kit/utils';
import {RubricResultReportService} from '../../../../../../service';
import {MarkingPeriod, NotTested} from '../../../../../../service/types';
import {Filter} from '../../../../../../service/settings-service';
import {ReportFilterBodyStyled, ReportFilterRowStyled} from './index.styled';

type ReportFiltersProps = {
	service: RubricResultReportService;
};

export function ReportFilters({service}: ReportFiltersProps) {
	const filter = useStream<Filter | null>(service.settingsService.filter$);

	return (
		<ReportFilterBodyStyled>
			<ReportHeader service={service} />
			<ReportFilterRowStyled>
				<LevelsLegend service={service} />
				<ShowResultsFilter
					onCarryForwardClicked={(value) => service.settingsService.setCarryForward(value)}
					onShowBaseLineClicked={(value) => service.settingsService.setShowBaseLine(value)}
					carryScoresForward={filter?.carryScoresForward ?? false}
					showBaseline={filter?.showBaseline ?? false}
				/>
				<DisplayNotTestedFilter
					onChanged={(value) => service.settingsService.setNotTested(value ? NotTested.Zero : NotTested.NT)}
					displayZeroIfNotTested={(filter?.displayZeroIfNotTested === NotTested.Zero) ?? false}
					includeNotTested={filter?.includeNotTested}
					onIncludeTestedChanged={(value) => service.settingsService.setIncludeNotTested(value)}
				/>
				<MarkingPeriodFilter
					onChanged={(value) => service.settingsService.setMarkingPeriod(value ? MarkingPeriod.Current : MarkingPeriod.All)}
					showCurrentMarkingPeriod={(filter?.showCurrentMarkingPeriod === MarkingPeriod.Current) ?? false}
				/>
			</ReportFilterRowStyled>
		</ReportFilterBodyStyled>
	);
}
