import {DropdownItem} from './service/types';

export const additionalDistrictsListItems: DropdownItem[] = [
	{
		id: null,
		value: 'My district is not listed',
	},
	{
		id: undefined,
		value: 'My school does not belong to district',
	},
];

export const additionalSchoolsListItems: DropdownItem[] = [
	{
		id: undefined,
		value: 'My school is not listed',
	},
];