import {getThemeColor} from '../../../utils';
import {ThemeColorKeys} from '../../../types';
import {ComponentPropsWithoutRef, forwardRef, useCallback, useEffect, useMemo} from 'react';
import {animated} from 'react-spring';
import {usePieChartContext} from '../../context';
import {useAnimationData} from './use-animation-data';

type PieChartArcProgressProps = Omit<
	ComponentPropsWithoutRef<typeof animated.path>,
	'children' | 'd' | 'fill' | 'stroke' | 'strokeWidth'
> & {
	/**
	 * Set stroke color for the arc progress.
	 * @default 'highContrast'
	 */
	stroke?: ThemeColorKeys;

	/**
	 * Set stroke width for the arc progress.
	 * @default 3
	 */
	strokeWidth?: number;

	/**
	 * The start value for rendering arc in a svg boundary.
	 * The startValue should be in the range [minValue, maxValue] of the root component
	 */
	startValue: number;

	/**
	 * The end value for rendering arc in a svg boundary.
	 * The endValue should be in the range [minValue, maxValue] of the root component
	 */
	endValue: number;
};

export const PieChartArcProgress = forwardRef<SVGPathElement, PieChartArcProgressProps>(
	(
		{stroke = 'highContrast', strokeWidth = 3, startValue, endValue, strokeLinecap = 'round', ...pathProps},
		forwardedRef,
	) => {
		const {getValueProgress, svgValue, updateShapeOffset, maxShapeOffset} = usePieChartContext();

		useEffect(() => {
			updateShapeOffset({shape: 'arcProgress', offset: strokeWidth});
		}, [strokeWidth, updateShapeOffset]);

		const circleValues = useMemo(() => {
			const circleSize = svgValue.size - maxShapeOffset;

			return {
				radius: circleSize / 2,
				center: svgValue.center,
			};
		}, [svgValue.size, svgValue.center, maxShapeOffset]);

		const getCoordinatesByAngle = useCallback(
			(angle: number) => {
				const angleRadian = ((angle - 90) * Math.PI) / 180;

				return {
					x: circleValues.center.x + circleValues.radius * Math.cos(angleRadian),
					y: circleValues.center.y + circleValues.radius * Math.sin(angleRadian),
				};
			},
			[circleValues],
		);

		const getArcValueD = useCallback(
			({startValueProgress, endValueProgress}: {startValueProgress: number; endValueProgress: number}) => {
				const startArcAngle = startValueProgress * 360;
				const endArcAngle = endValueProgress * 360;

				const arcAngle = endArcAngle - startArcAngle;

				const isCircle = arcAngle === 360;

				const {x: x1, y: y1} = getCoordinatesByAngle(startArcAngle);
				const {x: x2, y: y2} = getCoordinatesByAngle(isCircle ? endArcAngle - 1 : endArcAngle);

				const largeArcFlag = arcAngle <= 180 ? 0 : 1;

				const startPosition = `M ${x1} ${y1}`;
				const arc = `A ${circleValues.radius} ${circleValues.radius}, 0, ${largeArcFlag}, 1, ${x2} ${y2}`;

				return `${startPosition} ${arc}`;
			},
			[circleValues, getCoordinatesByAngle],
		);

		const animationArcProps = useAnimationData({
			startValueProgress: getValueProgress(startValue),
			endValueProgress: getValueProgress(endValue),
		});

		return (
			<animated.path
				d={animationArcProps.progress.to((progress) =>
					getArcValueD({
						startValueProgress: getValueProgress(startValue),
						endValueProgress: progress,
					}),
				)}
				fill='none'
				stroke={getThemeColor(stroke)}
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
				ref={forwardedRef}
				{...pathProps}
			/>
		);
	},
);
