import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {UntestedStudentsReportService} from '../../../service';
import styles from '../../modal/modal.module.less';
import {ChangeEvent, useCallback, useState} from 'react';

export const minValue = 1;
export const maxValue = 40;

interface Props {
	service: UntestedStudentsReportService;
}

export function ReportUntestedWeeks({service}: Props) {
	const lastUntestedWeeks = useBehaviorSubject(service.lastUntestedWeeks);
	const emptyReport = useBehaviorSubject(service.emptyReport);

	const [controlledValue, setControlledValue] = useState(String(lastUntestedWeeks));

	const handleValueChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value.trim();

			const onlyNumberRegex = /^\d+$/;

			if (value === '' || onlyNumberRegex.test(value)) {
				if (value === '') {
					setControlledValue(value);
					return;
				}

				const numberedValue = Number(value);

				if (numberedValue < minValue) {
					setControlledValue('');
					service.setLastUntestedWeeks(minValue);

					return;
				}

				if (numberedValue > maxValue) {
					setControlledValue(String(maxValue));
					service.setLastUntestedWeeks(maxValue);

					return;
				}

				setControlledValue(String(numberedValue));
				service.setLastUntestedWeeks(numberedValue);
			}
		},
		[service],
	);

	return !emptyReport &&
		<div>
			<div className={join(styles.settingsCell, styles.bold)}>
				<span>Show students who haven’t been tested in the last:</span>
				<input
					type='number'
					min={1} max={40} step={1}
					className={styles.untestedWeeks}
					value={controlledValue} onChange={handleValueChange}
				/>
				<span>{'week' + (lastUntestedWeeks != 1 ? 's' : '')}</span>
				<span>
                                <small>   (maximum 40)</small>
                            </span>
				<OnHoverTooltip message='Help'>
					<a className={styles.inlineTooltip}
					   href='https://support.esgisoftware.com/hc/en-us/articles/209159826-Untested-Students#numberofweeks'
					   target='_blank' rel='noreferrer'>
						<sup aria-hidden='true'
						     className={join(
							     'fa',
							     styles.fa,
							     'fa-question-circle',
						     )}/>
					</a>
				</OnHoverTooltip>
			</div>
		</div>;
}
