import {useEffect, useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {
	Entities,
	SingleSelectState,
	StudentsPanel,
	StudentsPanelTabID,
} from '@esgi/main/features/teacher/students-panel';
import {useClasses, useGroups, useStudents} from '@esgi/main/libs/store';
import {ClassesContent} from './components/classes-content';
import {GroupsContent} from './components/groups-content';
import {StudentsContent} from './components/students-content';
import {useValue} from '../../hooks';
import {ChangeFunction} from '../../types';
import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {useLocation} from 'react-router-dom';

interface Props {
	hideEmptyClassAndGroup?: boolean;
	withoutAllClass?: boolean;
	withoutStudents?: boolean;
	onActive?: ChangeFunction<string>;
	onChange: ChangeFunction<SingleSelectState>;
}

const Panel = styled(Box, {
	minWidth: 272,
	maxWidth: 400,
	width: '100%',
	height: '100%',
	padding: 20,
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',
});

export function StudentLevelStudentsPanel(props: Props) {
	const {onActive, onChange, withoutAllClass, hideEmptyClassAndGroup = false} = props;
	const [selected, setSelected] = useValue<SingleSelectState>({
		classId: null,
		groupId: null,
		studentId: null,
		studentFromClassId: null,
		studentFromGroupId: null,
	}, onChange);

	const [{data: classList, loaded: isClassListLoaded}] = useClasses();
	const [{data: groupList, loaded: isGroupListLoaded}] = useGroups();
	const [{data: studentList, loaded: isStudentListLoaded}] = useStudents();

	const entities = useMemo<Entities>(
		() => ({
			classes: classList.filter(({studentIDs}) => hideEmptyClassAndGroup ? studentIDs.length !== 0 : true),
			groups: groupList.filter(({studentIDs}) => hideEmptyClassAndGroup ? studentIDs.length !== 0 : true),
			students: studentList,
		}),
		[classList, groupList, studentList]
	);
	const {search} = useLocation();
	useEffect(() => {
		if (search) {
			setSelected({
				...selected,
				studentId: Number(new URLSearchParams(search).get('student-id')),
			});
		}
	}, [search]);
	const isPanelLoaded =
		isClassListLoaded && isGroupListLoaded && isStudentListLoaded;

	return (
		<Panel dataCy='student-panel'>
			<StudentsPanel.Root entities={entities} skeleton={!isPanelLoaded} initialTab={StudentsPanelTabID.Classes}>
				<StudentsPanel.Selection.Single selected={selected} onSelectedChange={setSelected}>
					<StudentsPanel.Header.Title>
						<Text data-cy='student-title' size='small' color='neutral56'>
							Select Students
						</Text>
					</StudentsPanel.Header.Title>

					<StudentsPanel.Header.Tabs
						dataCy='students-tabs'
						onActive={onActive}
					/>

					<StudentsPanel.Tabs.Root>
						<StudentsPanel.Tabs.Classes sectionTitle='Classes'>
							<ClassesContent withoutAllClass={withoutAllClass}/>
						</StudentsPanel.Tabs.Classes>
						<StudentsPanel.Tabs.Groups sectionTitle='Groups'>
							<GroupsContent />
						</StudentsPanel.Tabs.Groups>
						{!props?.withoutStudents && <StudentsPanel.Tabs.Students sectionTitle='Students'>
							<StudentsContent/>
						</StudentsPanel.Tabs.Students>}
					</StudentsPanel.Tabs.Root>
				</StudentsPanel.Selection.Single>
			</StudentsPanel.Root>
		</Panel>
	);
}

