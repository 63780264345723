import React, {useCallback, useState} from 'react';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button, Close, Tooltip} from '@esgi/ui';
import {UnselectAlert} from '../unselect-alert';
import {ActionPanel, StyledCounterBox, Root, ClearButton, StyledCheckbox, Content} from './index.styled';

type SelectionPanelProps = {
	isLoading: boolean;
	selectedCount: number;
	showOnlySelected: boolean;
	onAddSelection: () => void;
	onClear: () => void;
	onShowOnlySelectedClick: () => void;
	isSaving?: boolean;
}

export function SelectionPanel({
	selectedCount,
	isLoading,
	onAddSelection,
	onClear,
	showOnlySelected,
	onShowOnlySelectedClick,
	isSaving,
}: SelectionPanelProps){
	const [alert, setAlert] = useState(false);

	const handleShowAlert = useCallback(() => {
		setAlert(true);
	}, []);

	const handleCloseAlert = useCallback(() => {
		setAlert(false);
	}, []);

	return (
		<Root>
			{alert && (
				<UnselectAlert onProceed={onClear} onClose={handleCloseAlert} />
			)}
			<Content>
				<ActionPanel>
					<FlexBox>
						<Tooltip>
							<Tooltip.Trigger>
								<ClearButton onClick={handleShowAlert}>
									<Close />
								</ClearButton>
							</Tooltip.Trigger>
							<Tooltip.Content variant='secondary'>
								<Text size='small'>This will remove all selected items from the test.</Text>
							</Tooltip.Content>
						</Tooltip>
					</FlexBox>
					<FlexBox>
						<StyledCheckbox
							color='primary'
							checked={showOnlySelected}
							disabled={isLoading}
							label='Show only selected'
							onCheckedChange={onShowOnlySelectedClick}
						/>
					</FlexBox>
				</ActionPanel>
				<ActionPanel>
					<FlexBox>
						<StyledCounterBox>
							<Text size='small' font='mono'>{selectedCount}</Text>
						</StyledCounterBox>
						<Text size='small' color='primary'>
							Questions Selected
						</Text>
					</FlexBox>
					<Button skeleton={isSaving} color='primary' onClick={onAddSelection}>
						SAVE CHANGES
					</Button>
				</ActionPanel>
			</Content>
		</Root>
	);
}