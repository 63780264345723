import {createContext, useContext} from 'react';
import {StudentSectionContextValue} from './types';

export const StudentSectionContext = createContext<StudentSectionContextValue | null>(null);

export function useStudentSectionContext() {
	const context = useContext(StudentSectionContext);

	if (!context) {
		throw new Error('StudentSectionContext is null');
	}

	return context;
}
