export enum Meridiem {
	AM = 'AM',
	PM = 'PM',
}

export type Selection = {
	start: number;
	end: number;
};

export type InputState = {
	value: string;
	selection: Selection | null;
};

export interface MaskOptions {
	mask: string;
	maskChar: string;
	alwaysShowMask: boolean;
	formatChars: Record<string, string>;
	permanents: number[];
}
