export enum ClassicHierarchyLevel {
	None = 0,
	Student = 1,
	Group = 2,
	Class = 3,
	Teacher = 4,
	School = 5,
	District = 6,
  SchoolsGroup = 7,
  TeachersGroup = 8,
}

export enum SpecialistHierarchyLevel {
	None = 0,
	Student = 1,
	Group = 2,
	User = 3,
	Type = 4,
	District = 5,
  GroupOfSpecialists = 6,
}

export enum PreAssessHierarchyLevel {
  None = 0,
  Student = 1,
  Group = 2,
  User = 3,
  District = 4
}

export enum BoxType {
	None = 'None',
	ClassicStudent = 'ClassicStudent',
	ClassicGroup = 'ClassicGroup',
  ClassicSchoolsGroup = 'ClassicSchoolsGroup',
  ClassicTeachersGroup = 'ClassicTeachersGroup',
	ClassicClass = 'ClassicClass',
	ClassicTeacher = 'ClassicTeacher',
	ClassicSchool = 'ClassicSchool',
	SpecialistStudent = 'SpecialistStudent',
	SpecialistGroup = 'SpecialistGroup',
	SpecialistUser = 'SpecialistUser',
	SpecialistType = 'SpecialistType',
	SpecialistTeacher = 'SpecialistTeacher',
	SpecialistSchool = 'SpecialistSchool',
  PreAssessStudent = 'PreAssessStudent',
  PreAssessGroup = 'PreAssessGroup',
  PreAssessUser = 'PreAssessUser',
  SpecialistGroupOfSpecialists = 'SpecialistGroupOfSpecialists',
}

export enum HierarchyMode {
	Classic = 0,
	Specialist = 1,
  PreAssess = 2,
}
