import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../../../hooks';
import styles from './styles.module.less';

interface Props {
	isSelfAssess: boolean;
}

export function Subheader({isSelfAssess}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);

	return (
		<>
			{(isSelfAssess || testSessionDetails.showTestedBy) &&
				<div className={styles.subTitle}>
					<div className={styles.caption}>Tested by:</div>
					<div className={styles.value}>{isSelfAssess ? 'Student' : (currentTestSession?.userName || '')}</div>
				</div>
			}
			<div className={styles.subTitle}>
				<div className={styles.caption}>Student:</div>
				<div className={styles.value}>{testSessionDetails.studentName}</div>
			</div>
			<div className={styles.subTitle}>
				<div className={styles.caption}>Test Name:</div>
				<div className={styles.value}>{testSessionDetails.testName}</div>
			</div>
		</>
	);
}
