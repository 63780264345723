import React from 'react';
import {Subscription} from 'rxjs';
import {INotedAuthor, LandingService} from '../../../services/landing-service';
import {Tray} from '../tray/tray';
import {Author} from './author/author';

class State {
	loaded: boolean = false;
	authors: INotedAuthor[] = [];
}

class Props {
	landingService: LandingService;
	onLoaded: () => void;
}

export default class NotedAuthors extends React.Component<Props, State> {
	private notedAuthorSub: Subscription;
	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	render() {
		return <Tray title='Noted Authors' className='noted-authors'>
			{this.state.authors.map(c => {
				return <Author key={c.name}
				               author={c}/>;
			})}
		</Tray>;
	}

	componentDidMount(): void {
		this.notedAuthorSub = this.props.landingService.notedAuthors$.subscribe(r => {
			const authors = this.shuffle(r);
			this.setState({
				authors: authors,
				loaded: true,
			}, () => this.props.onLoaded());
		});
	}

	public componentWillUnmount() {
		this.notedAuthorSub && this.notedAuthorSub.unsubscribe();
	}

	private shuffle(partners: INotedAuthor[]) {
		return [...partners].sort(() => Math.random() - 0.5);
	}
}
