import {useEffect, useMemo, useState} from 'react';

const mediaPreset = {
	'portrait-tablet': '(max-width: 1023px)',
	'landscape-tablet': '(max-width: 1180px)',
	'small-laptop': '(max-width: 1366px)',
	'average-laptop': '(max-width: 1440px)',
	'large-laptop': '(max-width: 1650px)',
};

export function useMatchMedia(mediaQuery: (keyof typeof mediaPreset) | string): boolean | undefined {
	const mql = useMemo(() => {
		let mq: string;

		if(mediaQuery in mediaPreset) {
			mq = mediaPreset[mediaQuery];
		} else {
			mq = mediaQuery;
		}

		return window.matchMedia(mq);
	}, [mediaQuery]);

	const [matched, setMatched] = useState(() => mql.matches);

	useEffect(() => {
		const handler = () => setMatched(mql.matches);

		mql.addEventListener('change', handler);

		return () => mql.removeEventListener('change', handler);
	}, [mql]);

	return matched;
}