import {TestScreenType} from '@esgi/core/enums';

export class ReportAllStudents {
	studentsTested: number;
	correctAnswers: number;
	totalAnswers: number;
	averageScore: number;
	lastSessionDate: Date;
	testSessionsNumber: number;
}

export class Report {
	reportFilter: ReportFilter;
	reportData: ReportData;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
}

export class ReportData {
	questionsCount: number;
	testSessions: TestSessionData[];
	allClassData?: ReportAllStudents;
}

export class TestSessionData {
	date: Date;
	score: number;
	type: number;
	testScreenType: TestScreenType;
}

export class ReportFilter {
	teacherName: string;
	schoolYearStart?: string;
	currentPeriodStart?: string;
	currentPeriodEnd?: string;
	schoolName: string;
	districtName: string;
	classID: number;
	classes: Class[];
	groupID: number;
	groups: Group[];
	specialistGroupID: number;
	specialistGroups: SpecialistGroup[];
	studentID: number;
	subjects: Subject[];
	studentSort: string;
}

export class Class {
	classID: number;
	name: string;
	students: Student[];

	constructor(classID: number, name: string, students: Student[]) {
		this.classID = classID;
		this.name = name;
		this.students = students;
	}
}

export class Group {
	groupID: number;
	name: string;
	students: Student[];

	constructor(groupID: number, name: string, students: Student[]) {
		this.groupID = groupID;
		this.name = name;
		this.students = students;
	}
}

export class SpecialistGroup {
	specialistGroupID: number;
	name: string;
	students: Student[];

	constructor(specialistGroupID: number, name: string, students: Student[]) {
		this.specialistGroupID = specialistGroupID;
		this.name = name;
		this.students = students;
	}
}

export class Student {
	studentID: number;
	firstName: string;
	lastName: string;
	name: string;
	html?: string;

	constructor(studentID: number, firstName: string, lastName: string) {
		this.studentID = studentID;
		this.firstName = firstName;
		this.lastName = lastName;
		this.name = this.firstName + (this.studentID > 0 ? ' ' + this.lastName : '');
	}
}

export class Subject {
	subjectID: number;
	name: string;
	subjectType: string;
	tests: Test[];

	constructor(subjectID: number, name: string, subjectType: string, tests: Test[]) {
		this.subjectID = subjectID;
		this.name = name;
		this.subjectType = subjectType;
		this.tests = tests;
	}
}

export class Test {
	testID: number;
	name: string;
	type: string;

	constructor(testID: number, name: string, type: string) {
		this.testID = testID;
		this.name = name;
		this.type = type;
	}
}

export enum ReportType {
	None = 0,
	SingleFile = 1,
	Zip = 2,
}

export enum ReportFormat {
	None = 0,
	Excel = 1,
	Pdf = 2,
}

export interface BackgroundGenerationModel {
	reportGuid: string;
}

export interface BackgroundGenerationRequest {
	fileFormat: ReportFormat,
	type: ReportType,
	classID: number | null,
	groupID: number | null,
	range: string,
	isSpecialist: boolean,
	subjectName: string,
	teacherName: string,
	testName: string,
	unityTitle: string,
	unityName: string,
	tableDateHeader: string,
	tableTimeHeader: string,
	tableCorrectHeader: string,
	totalPossible: number,
	globalSchoolYearID: number,
	userID: number,
	students: BackgroundGenerationStudentData[],
}

export interface BackgroundGenerationStudentData {
	studentID: number,
	studentName: string,
	chartBase64: string,
	sessions: BackgroundGenerationSessionData[],
}

export interface BackgroundGenerationSessionData {
	testDate: string,
	score: number,
}