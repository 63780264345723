import React from 'react';
import './rollover-checker.less';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {getSelectedSchoolYearID, userStorage} from '@esgi/core/authentication';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import {SchoolYearsService, YearModel} from 'shared/modules/rollover-checker/service';

export class Props {
	callback: () => void;
}

export class State {
	opened: boolean = false;
	schoolYears?: YearModel[] = [];
	currentYear?: string = '';
}

export class RolloverChecker extends React.Component<Props, State> {
	private readonly user = userStorage.get();
	private readonly schoolYearsService = new SchoolYearsService();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	sessionKey = 'rollover-checker-alert-closed';

	componentDidMount() {
		this.schoolYearsService.schoolYears$.subscribe(years => {
			this.setState({
				schoolYears: years,
				currentYear: years.find((item) => item.globalSchoolYearID === getSelectedSchoolYearID()).name},
			);
		});
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (!this.state.opened) {
			this.showModal();
		}
	}

	onClick() {
		sessionStorage.setItem(this.sessionKey, 'true');
		this.setState({opened: false}, () => this.props.callback());
	}

	showModal() {
		if (!sessionStorage.getItem(this.sessionKey)
			&& this.user.lastGlobalSchoolYearID - 2 === this.user.globalSchoolYearID
			&& this.state.schoolYears.some(item => item.globalSchoolYearID === this.user.lastGlobalSchoolYearID)
		) {
			this.setState({opened: true});
		} else {
			this.props.callback();
		}
	}

	getMessage() {
		return <div className='alert-text-container'>
			<div className='modal-alert-content'>
            <span>To make changes to a different year, select it from the School Year dropdown menu.
                <a href='https://support.esgisoftware.com/hc/en-us/articles/209158766-School-Years'
                   className='external-link' target='_blank' rel='noreferrer'> Learn more</a>.<br/></span>
				<span>If you have questions, please <a href='https://support.esgisoftware.com/hc/en-us/requests/new'
				                                       className='external-link'
				                                       target='_blank'
				                                       rel='noreferrer'> contact support</a>.<br/></span>
			</div>
		</div>;
	}

	render() {
		if (this.state.opened) {
			return <Modal className='rollover-checker-modal responsive alert-modal-white'>
				<ModalHeader/>
				<ModalBody>
					<div className='rollover-body'>
						<div className='checker-title'>
							<div className='warning-icon'>
								<svg width='40' height='40' viewBox='0 0 40 40' fill='none'
								     xmlns='http://www.w3.org/2000/svg'>
									<path d='M0 14.25H16.5L8.25 0L0 14.25ZM9 12H7.5V10.5H9V12ZM9 9H7.5V6H9V9Z'
									      fill='#FEC702'/>
								</svg>
							</div>
							<span
								className='modal-alert-header'>You are Making Changes to the {this.state.currentYear} School Year.</span><br/><br/>
						</div>
						{this.getMessage()}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => this.onClick()}
						title='GOT IT'
						className='btn btn-dismiss alert-button-dismiss'
					/>
				</ModalFooter>
			</Modal>;
		}
		return null;
	}
}
