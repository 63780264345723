import * as React from 'react';
import type {SVGProps} from 'react';

export function Datepicker(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M7 11a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V11Zm14-2H11a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V11a2 2 0 0 0-2-2Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M20 5a1 1 0 0 1 1 1v2h-2V6a1 1 0 0 1 1-1Zm-8 0a1 1 0 0 1 1 1v2h-2V6a1 1 0 0 1 1-1Zm0 6a1 1 0 0 0 1-1V8h-2v2a1 1 0 0 0 1 1Zm8 0a1 1 0 0 0 1-1V8h-2v2a1 1 0 0 0 1 1Zm-4 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm3.667 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm-7.334 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
