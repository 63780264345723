import React from 'react';
import './component.less';
import './../../component.less';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import GradeRangeTypeSelector from 'shared/modules/grade-scale/grade-range/grade-range-type-selector/component';
import {SelectorGradeRangeType} from 'shared/modules/grade-scale/grade-range/grade-range-type-selector/models';
import {Checkbox, CustomDropdown} from '@esgi/deprecated/elements';
import {Item} from '@esgi/deprecated/elements/custom-dropdown/dropdown';
import {FormState, GradeRangeSelectionType, ISubject, ITrack} from 'shared/modules/grade-scale/models';
import ShowMoreButton from 'shared/modules/grade-scale/show-more-button/component';
import {IGradeRangeSetView} from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/models';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import GradeRangeViewer from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/grade-range-viewer/component';
import LeftNavigateButton from 'shared/modules/grade-scale/grade-range/navigate-button/left/component';
import RightNavigateButton from 'shared/modules/grade-scale/grade-range/navigate-button/right/component';
import {SharedGradeRangeService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/service';
import {ITest} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/models';
import DPMPGradeRangeViewer
	from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/dpmp-grade-range-viewer/component';
import GradeRangesList
	from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/grade-ranges-list/component';
import {Subscription} from 'rxjs';

export class Props {
	service: SharedGradeRangeService;
	gradeScaleID: number;
	testID?: number;
	subjectID: number;
	initialStep: WizardStep;
	onGoToStep: (step: WizardStep, subjectID: number, testID: number, gradeScaleID?: number) => void;
}

export class State {
	gradeRangeSets: IGradeRangeSetView[] = [];
	subjects: Item[] = [];
	tests: Item[] = [];
	selectedSubject: Item;
	selectedTest?: ITest;
	selectedGradeRangeSet: IGradeRangeSetView;
	track: ITrack;
	rangeSelected: boolean;
	viewAll: boolean;
	showConfirmationMessage: boolean;
	formChanged: boolean;
}

export default class SharedGradeRange extends React.PureComponent<Props, State> {
	private supportPageUri: string = 'https://support.esgisoftware.com/hc/en-us/articles/115005886826-Class-Grade-Report-Grading-Scales';
	subscribers: Subscription[] = [];
	
	constructor(props) {
		super(props);
		this.state = new State();
		this.props.service.init(this.props.gradeScaleID, this.props.initialStep, this.props.subjectID, this.props.testID);
	}

	componentDidMount() {
		this.subscribers.push(this.props.service.gradeRangeSetsView$.subscribe(gradeRangeSets => {
			this.setState({gradeRangeSets: gradeRangeSets});
		}));

		this.subscribers.push(this.props.service.dropdownTests$.subscribe(tests => {
			this.setState({tests: tests});
		}));

		this.subscribers.push(this.props.service.subjectsView$.subscribe(subjects => {
			this.setState({subjects: subjects});
		}));

		this.subscribers.push(this.props.service.selectedSubject$.subscribe(selectedSubject => {
			this.setState({selectedSubject: selectedSubject});
		}));

		this.subscribers.push(this.props.service.selectedTest$.subscribe(test => {
			this.setState({selectedTest: test});
		}));

		this.subscribers.push(this.props.service.selectedGradeRangeSet$.subscribe(set => {
			this.setState({selectedGradeRangeSet: set});
		}));

		this.subscribers.push(this.props.service.track$.subscribe(track => {
			this.setState({track: track});
		}));

		this.subscribers.push(this.props.service.rangeSelected$.subscribe(rangeSelected => {
			this.setState({rangeSelected: rangeSelected});
		}));

		this.subscribers.push(this.props.service.sharedGradeRangeSetsViewAll$.subscribe(viewAll => {
			this.setState({viewAll: viewAll});
		}));

		this.subscribers.push(this.props.service.toSaveTests$.subscribe(changedTests => {
			this.setState({formChanged: changedTests.length > 0});
		}));

		this.subscribers.push(this.props.service.showConfirmationMessage$.subscribe(showConfirmationMessage => {
			if (showConfirmationMessage) {
				this.setState({showConfirmationMessage: showConfirmationMessage});
			}
		}));
	}

	componentWillUnmount() {
		this.subscribers.map(s => s.unsubscribe());
	}
	
	renderSets() {
		if (this.state.gradeRangeSets.length === 0) {
			return <div className='filters-row has-no-scales'>
					{this.props.service.setUpGradeScaleMode && <LeftNavigateButton onClick={() => this.props.service.prevTest()}/>}
					<span>This test does not have shared grade scales</span>
					{this.props.service.setUpGradeScaleMode && <RightNavigateButton onClick={() => this.props.service.nextTest()}/>}
				</div>
		}
		
		if (this.state.selectedGradeRangeSet) {
			if (this.state.gradeRangeSets.length === 1) {
				return this.renderSingleSet();
			}

			if (this.state.gradeRangeSets.length > 1) {
				return this.renderMultipleSets();
			}
		}
	}
	
	renderRangeCheckbox(singleGradeRange?: boolean) {
		const className = singleGradeRange ? 'set-up-checkboxes-left-container' : null;
		
		if (this.state.rangeSelected) {
			return <span className={className}>You are using this scale</span>;
		} else {
			return <Checkbox
				checked={false}
				label='Choose this Range'
				className={className}
				onClick={(e) => this.props.service.clickChooseThisRange(e, this.state.selectedGradeRangeSet.id)}
				id={'choose-this-range'}
				disabled={this.state.selectedGradeRangeSet.readonly 
					|| this.state.selectedGradeRangeSet.id === 0 
					|| this.state.selectedTest.applyDefaultRange}
			/>;	
		}
	}

	renderApplyDefaultRangeCheckbox(className?: string) {
		return <Checkbox
			checked={this.state.selectedTest.applyDefaultRange}
			label='Apply default range'
			className={className}
			onClick={(e) => this.props.service.applyDefaultRange(e)}
			disabled={this.state.selectedGradeRangeSet && this.state.selectedGradeRangeSet.readonly}
			id={'set-default-range'}
		/>
	}
	
	renderDPMPSetUpCheckboxes(singleGradeRange?: boolean) {
		const className = singleGradeRange 
			? 'filters-row choose-this-range two-lines-checkboxes-row single-range' 
			: 'filters-row choose-this-range two-lines-checkboxes-row'
		
		return <div className={className}>
			{this.renderRangeCheckbox(singleGradeRange)}
			{this.renderApplyDefaultRangeCheckbox('last-row')}
		</div>
	}

	renderSingleRangeSAYSetUpCheckboxes() {
		return <div className='filters-row choose-this-range one-line-checkboxes-row'>
			{this.renderRangeCheckbox(true)}
			{this.renderApplyDefaultRangeCheckbox('set-up-checkboxes-right-container')}
		</div>
	}

	renderMultipleRangesSAYSetUpCheckboxes() {
		return <div className='filters-row choose-this-range multiple-ranges-one-line-checkboxes-row'>
			{this.renderRangeCheckbox(true)}
			{this.renderApplyDefaultRangeCheckbox()}
		</div>
	}
	
	renderMultipleSets() {
		return <div className='more-than-one-grade-range-sets'>
			<div className='filters-row grade-range-sets-header-row'>
				<div className='filters-cell left'>
					<label>OWNER</label>
				</div>
				<div className='filters-cell right'>
					<div className='top-row'>{this.state.selectedGradeRangeSet.ownerName}</div>
					<div className='bottom-row'>
						{(this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.DifferentPerPeriod)
							&& <p>Marking Periods ({this.state.track.trackDates.length})</p>}
					</div>
				</div>
			</div>
			<div className='filters-row grade-range-sets-content-row'>
				<div className='filters-cell left'>
					<GradeRangesList
						service={this.props.service}
						gradeRangeSets={this.state.gradeRangeSets}
						selectedGradeRangeSet={this.state.selectedGradeRangeSet}
					/>
					{this.state.gradeRangeSets.length >= 8 &&
						<ShowMoreButton 
							onClick={() => this.props.service.setSharedGradeRangeSetsViewAll()}
							opened={this.state.viewAll}
						/>}
				</div>
				<div className='filters-cell right'>
					<div className='filters-row center-content'>
						{this.props.service.setUpGradeScaleMode && <LeftNavigateButton onClick={() => this.props.service.prevTest()}/>}
						{(this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.SameAllYear) &&
							<div className='same-all-year-container'>
								<GradeRangeViewer gradeRanges={this.state.selectedGradeRangeSet.gradeRanges} />
							</div>
						}
						{(this.state.track && this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.DifferentPerPeriod) &&
							<DPMPGradeRangeViewer
								track={this.state.track}
								service={this.props.service}
							/>
						}
						{this.props.service.setUpGradeScaleMode && <RightNavigateButton onClick={() => this.props.service.nextTest()}/>}
					</div>
					{this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.SameAllYear 
						? this.renderMultipleRangesSAYSetUpCheckboxes()
						: this.renderDPMPSetUpCheckboxes()
					}
				</div>
			</div>
		</div>;
	}

	renderSingleSet() {
		return <>
			<div className='filters-row'>
				<div className='filters-cell'>
					<div className='top-row'>{this.state.selectedGradeRangeSet.ownerName}</div>
					{(this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.DifferentPerPeriod) &&
						<div className='bottom-row'>Marking Periods
							({this.state.track.trackDates.length})</div>}
				</div>
			</div>
			<div className='filters-row'>
				{this.props.service.setUpGradeScaleMode && <LeftNavigateButton onClick={() => this.props.service.prevTest()}/>}
				<div className='filters-row center-content'>
					{(this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.SameAllYear) &&
						<div className='same-all-year-container'>
							<GradeRangeViewer
								maxSize={true}
								gradeRanges={this.state.selectedGradeRangeSet.gradeRanges}
							/>
						</div>
					}
					{(this.state.track && this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.DifferentPerPeriod) &&
						<DPMPGradeRangeViewer
							maxSize={true}
							track={this.state.track}
							service={this.props.service}
						/>
					}
				</div>
				{this.props.service.setUpGradeScaleMode && <RightNavigateButton onClick={() => this.props.service.nextTest()}/>}
			</div>
			{this.state.selectedGradeRangeSet.periodType === GradeRangeSelectionType.SameAllYear
				? this.renderSingleRangeSAYSetUpCheckboxes()
				: this.renderDPMPSetUpCheckboxes()
			}
		</>;
	}

	renderCustomRangeConfirmationModal() {
		return <Modal animate={true}
					  className={'range-not-selected-alert white-header responsive alert-modal-white'}>
			<ModalHeader>
				<h3>Save With Custom Range?</h3>
			</ModalHeader>
			<ModalBody>
						<span>
							<p>A shared range has not been selected for this test.</p>
							<p>Clicking OK will apply the custom range to this test.</p>
							<p>Click ‘Go Back’ to select a shared range.</p>
						</span>
			</ModalBody>
			<ModalFooter>
				<Transparent
					onClick={() => this.setState(() => {
						return {
							showConfirmationMessage: false,
						};
					}, () => this.props.service.setFormState(FormState.Saved))}
					title='OK'
				/>
				<Transparent
					onClick={() => this.setState({showConfirmationMessage: false})}
					title='Go Back'
				/>
			</ModalFooter>
		</Modal>;
	}
	
	renderDefaultRangeConfirmationModal() {
		return <Modal animate={true}
					  className={'range-not-selected-alert white-header responsive alert-modal-white'}>
			<ModalHeader>
				<h3>Save With Default Percent Range?</h3>
			</ModalHeader>
			<ModalBody>
						<span>
							<p>A shared range has not been selected for this test.</p>
							<p>Clicking OK will apply the default percent range to this test.</p>
							<p>Click ‘Go Back’ to select a shared range.</p>
						</span>
			</ModalBody>
			<ModalFooter>
				<Transparent
					onClick={() => this.setState(() => {
						return {
							showConfirmationMessage: false,
						};
					}, () => this.props.service.setFormState(FormState.Saved))}
					title='OK'
				/>
				<Transparent
					onClick={() => this.setState({showConfirmationMessage: false})}
					title='Go Back'
				/>
			</ModalFooter>
		</Modal>;
	}
	
	renderConfirmationMessage(){
		if (this.props.initialStep === WizardStep.CustomGradeRange) {
			return this.renderCustomRangeConfirmationModal();
		} else {
			return this.renderDefaultRangeConfirmationModal();
		}
	}

	renderSaveButton() {
		if (this.state.formChanged) {
			return <Primary
				onClick={() => this.props.service.save()}
				title='SAVE AND CLOSE'
			/>;
		}

		return <Primary
			onClick={() => this.props.service.setFormState(FormState.Closed)}
			title='CLOSE'
		/>;
	}
	
	render() {
		return <>
			{this.state.showConfirmationMessage && this.renderConfirmationMessage()}
			<ModalHeader
				title='Grade Range'
				close={{click: () => this.props.service.setFormState(FormState.Closed)}}
			/>
			<ModalBody>
				<div className='grade-range-form-component grade-range-shared-component'>
					<GradeRangeTypeSelector
						disabled={this.state.selectedGradeRangeSet && this.state.selectedGradeRangeSet.readonly}
						selectedType={SelectorGradeRangeType.Shared}
						sharedRanges={this.state.gradeRangeSets.length}
						onClick={() => this.props.onGoToStep(
							WizardStep.CustomGradeRange,
							this.state.selectedSubject.key,
							this.state.selectedTest
								? this.state.selectedTest.id
								: 0)}
					/>
					<div className='grade-scale-header shared'>
						Set Shared Range for Each Test
						<a target='_blank' href={this.supportPageUri} rel='noreferrer'>
							<i className='glyphicon glyphicon-question-sign tip'></i>
						</a>
					</div>
					<div className='sub-header'>
						Select an owner to view their shared range. Choose the range to apply to a test.
						<a target='_blank' href={this.supportPageUri} rel='noreferrer'>
							<span>Learn more</span>
						</a>
					</div>
					{this.state.selectedTest &&
						<>
							<div className='filters-row'>
								<div className='filters-cell left'>
									<label>Subject Tab</label>
									<CustomDropdown
										items={this.state.subjects}
										value={this.state.selectedSubject.key}
										onSelect={s => this.props.service.selectDropdownSubject(s.key)}
									/>
								</div>
								<div className='filters-cell right'>
									<label>Tests [{this.state.tests.length}]</label>
									<CustomDropdown
										items={this.state.tests}
										value={this.state.selectedTest.id}
										onSelect={t => this.props.service.selectTest(t.key)}
									/>
								</div>
							</div>
							{this.renderSets()}
						</>}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='left-footer-container'>
					<Button
						onClick={() => this.props.service.setFormState(FormState.Canceled)}
						title='Cancel'
						className='cancel-button'
					/>
				</div>
				<div className='right-footer-container'>
					{this.renderSaveButton()}
				</div>
			</ModalFooter>
		</>;
	}
}