import {FormControl, FormElement} from '@esgi/ui/form';
import {Select} from '@esgi/ui/form-controls';
import {SelectField} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {BaseComponentProps} from '@esgi/ui';
import {DropdownItem} from '../../service/types';

type Props<T extends string = string> = Pick<BaseComponentProps, 'dataCy'> & {
	placeholder: string;
	items: DropdownItem[];
	controlElement: FormControl<T[]>;
};

export function FormElementSelect<T extends string = string>({items, controlElement, placeholder, dataCy}: Props<T>) {
	return (
		<FormElement control={controlElement}>
			<Select.Root dataCy={dataCy}>
				<SelectField placeholder={placeholder}/>
				<Select.Content>
					{items.map(({id, value}) => (
						<Select.Option value={String(id)} key={id}>
							<Text size='medium' bold>
								{value}
							</Text>
						</Select.Option>
					))}
				</Select.Content>
			</Select.Root>
		</FormElement>
	);
}