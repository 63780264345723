import {ReactNode} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../hooks';
import {ResultRequest, Answer} from '../../types';
import {SessionInfo} from './components/session-info';
import {SessionPicker} from './components/session-picker';
import {useSessionEvents} from './hooks';
import moment from 'moment';
import styles from './styles.module.less';

interface Props {
	children: ReactNode;
	getAnswers: (request: ResultRequest) => void;
	setAnswers: (answers?: Answer[]) => void;
}

export function Sessions(props: Props): JSX.Element {
	const {getAnswers, setAnswers} = props;
	const service = useTestSessionDetailsService();
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const {
		initTestDate,
		setInitTestDate,
		setPrevTestDate,
		showDeletedSessions,
		setShowDeletedSessions,
		visibleSessions,
		setTestSession,
	} = useSessionEvents({service, getAnswers, setAnswers});

	const changeDate = (date: moment.Moment) => {
		if (date === currentTestSession.testDate) {
			return;
		}

		if (!initTestDate) {
			setInitTestDate(currentTestSession.testDate);
		}

		service.setTestDateTouched(true);
		setPrevTestDate(currentTestSession.testDate);
		currentTestSession.testDate = date;
		service.setCurrentTestSession(currentTestSession);
		service.setDirty(true);
	};

	return (
		<>
			<SessionInfo/>
			<div className={styles.sessionList}>
				<SessionPicker
					visibleSessions={visibleSessions}
					setTestSession={setTestSession}
					setDate={changeDate}
					showDeletedSessions={showDeletedSessions}
					setShowDeletedSessions={setShowDeletedSessions}
				/>
				<div className={styles.editButtons}>
					{props.children}
				</div>
			</div>
		</>
	);
}
