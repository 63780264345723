import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const WrapperBox = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',
	gridTemplateRows: 'auto 1fr',
	gap: '$5',
});

export const ListsContentWrapper = styled(GridBox, {
	gap: '$5',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
});
