import {SubjectType} from '@esgi/core/enums';
import {SortModel} from 'shared/modules/reports/total-report/models';
import {HttpClient, ObservableBuilder} from '@esgi/api';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class GradeScalesApi {
	
	dismissAllWarnings(gradeScaleId: number, subjectId: number, subjectType: string, subjectLevel: string, hierarchy: HierarchySnapshot) {
		return HttpClient.default.ESGIApi.post<any>('reports/grade-report-setting', 'dismiss-all-warnings', {
			subjectId: subjectId,
			subjectType: subjectType,
			subjectLevel: subjectLevel,
			gradeScaleId: gradeScaleId,
			hierarchy: hierarchy,
		});
	}

	hasScales(subjectID: number, subjectType: SubjectType, hierarchy: HierarchySnapshot) {
		return HttpClient.default.ESGIApi.get<boolean>('reports/grade-report-setting', 'has-scales', {
			subjectID: subjectID,
			subjectType: subjectType,
			hierarchy: hierarchy,
		});
	}

	clearAllGradeScales() {
    	return HttpClient.default.ESGIApi.post('reports/grade-scales', 'clear-all');
	}

	changeSort(request: ChangeSortRequest) {
    	return HttpClient.default.ESGIApi.post('reports/grade-report-result', 'change-sort', {
		    model: request,
	    });
	}

	carryScoresForward(carryScoresForward): ObservableBuilder<any> {
		return HttpClient.default.ESGIApi.post(
			'reports/user-settings',
			'update',
			{name: 'carryScoresForward', value: carryScoresForward});
	}

	displayZero(displayZero): ObservableBuilder<any> {
		return HttpClient.default.ESGIApi.post(
			'reports/user-settings',
			'update',
			{name: 'displayZeroIfNotTested', value: displayZero});
	}

	markingPeriodAll(markingPeriodAll){
		return HttpClient.default.ESGIApi.post('reports/grade-report-result', 'change-marking-period-all', {value: markingPeriodAll});
	}

	closeGradeReport(model: CloseRequest) {
    	return HttpClient.default.ESGIApi.post('reports/grade-report-result', 'close-grade-report', {model: model});
	}

	startBackgroundGeneration(options) {
		return HttpClient.default.ESGIApi.post<BackgroundGenerationModel>('reports/grade-report-result', 'start-background-generation', options);
	}
}

export interface ChangeSortRequest {
	sourceID: string;
	type: string;
	sortModel: SortModel;
}

export interface CloseRequest {
	Type: string,
	SourceID: string;
}

export interface BackgroundGenerationModel {
	reportGuid: string;
}