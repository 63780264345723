import React from 'react';
import {userStorage} from '@esgi/core/authentication';
import {PinPopup} from '../../pin-popup/pin-popup';
import styles from './student-view-modal.module.less';
import {Modal, ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import StarredName from '../starred-name/starred-name';
import CloseButton from '../close-button/close-button';
import DataService from '../../services/data-service';
import {Test as TestModel} from '../../models';
import {Card, CardBody, CardHeader} from '../card';
import StartButton from '../start-button/start-button';
import TestModal from './components/test-modal/test-modal';
import {Subject} from 'rxjs';
import {takeUntil, tap, filter} from 'rxjs/operators';
import ExitConfirmationModal from './components/test-modal/components/exit-confirmation-modal/exit-confirmation-modal';
import ContentAreaIcon from '../content-area-icon/content-area-icon';
import {Loader} from '@esgillc/ui-kit/loader';
import {LockScreen} from './components/lock-screen-modal';


interface StudentViewModalProps {
	dataService: DataService;
	closed: () => any;
	testSessionEnded: () => any;
}

class StudentViewModalState {
	selectedTests: TestModel[];
	currentTest: TestModel;
	testModalOpened: boolean = false;
	exitConfirmationOpened: boolean = false;
	showPinPopup: boolean = false;
	forceEnded: boolean = false;
}


export default class StudentViewModal extends React.Component<StudentViewModalProps, StudentViewModalState> {
	private destroy$: Subject<void> = new Subject();
	private modalManagerRef: ModalManagerRefObject = React.createRef();

	state = new StudentViewModalState();

	componentDidMount(): void {
		this.props.dataService.selectedTests$
			.pipe(takeUntil(this.destroy$))
			.subscribe(selectedTests => this.setState({selectedTests}));

		this.props.dataService.finishTest$
			.pipe(
				takeUntil(this.destroy$),
				filter(isLastTest => isLastTest),
			)
			.subscribe(() => this.endTestSession());

		this.props.dataService.currentTest$
			.pipe(
				takeUntil(this.destroy$),
				tap(test => this.props.dataService.setFirstQuestion(test)),
			)
			.subscribe(currentTest => this.setState({currentTest}));
	}

	componentWillUnmount(): void {
		this.destroy$.next();
	}

	private startTestClicked() {
		if (this.state.currentTest.isPractice) {
			return this.setState({testModalOpened: true});
		}

		this.props.dataService.runTest$()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.setState({testModalOpened: true}));
	}

	private renderCard(test: TestModel, index: number) {
		const selected = this.state.currentTest.id === test.id;
		const body = test.isPractice
			? <>
				<span className={styles.bodyText}>
					?
				</span>
			</>
			: <ContentAreaIcon contentAreaID={test.contentAreaID}/>;

		return <Card
			key={test.id}
			selected={selected}
			titleNumber={index + 1}
		>
			<CardHeader
				titleNumber={index + 1}
				infoText={test.name}
			>
						<span className={styles.cardHeader}>
							{test.contentAreaName}
						</span>
			</CardHeader>
			<CardBody>
				{body}
				{selected && <StartButton click={() => this.startTestClicked()}/>}
			</CardBody>
		</Card>;
	}

	private forceEndTestSession() {
		this.setState({forceEnded: true}, () => {
			this.props.dataService.forceEndTestSession$().pipe(takeUntil(this.destroy$))
				.subscribe(() => this.endTestSession());
		});
	}

	private endTestSession() {
		if (this.state.forceEnded) {
			this.modalManagerRef.current.close(this.props.closed);
		} else {
			this.modalManagerRef.current.close(this.props.testSessionEnded);
		}
	}

	private close() {
		if (userStorage.get().lockScreen) {
			this.setState({showPinPopup: true});
		} else {
			this.setState({exitConfirmationOpened: true});
		}
	}

	render() {
		return (
			<>
				<Modal containerClassName={styles.modalWrapper} className={styles.modal}
				       modalManagerRef={this.modalManagerRef}>
					<Loader show={this.state.forceEnded}/>
					<Modal.Header className={styles.launcherHeader}>
						<div className={styles.hidden}></div>
						<div className={styles.starredNameContainer}>
							<StarredName name={this.props.dataService.selectedStudent?.name}/>
						</div>
						<CloseButton closed={() => this.close()}/>
					</Modal.Header>
					<Modal.Body className={styles.body}>
						<div className={styles.launcherBody}>
							<div className={styles.cardsContainer}>
								{this.state.selectedTests && this.state.selectedTests.map((test, index) => this.renderCard(test, index))}
							</div>
						</div>
					</Modal.Body>
				</Modal>

				{this.state.testModalOpened
					&& <TestModal
						dataService={this.props.dataService}
						test={this.state.currentTest}
						closed={() => this.setState({testModalOpened: false})}
					/>
				}

				{this.state.exitConfirmationOpened
					&& <ExitConfirmationModal
						yesClicked={() => this.forceEndTestSession()}
						noClicked={() => this.setState({exitConfirmationOpened: false})}
					/>}

				<LockScreen
					closeParent={() => this.modalManagerRef.current.close(this.props.closed)}
				/>

				{this.state.showPinPopup && <PinPopup onCancel={() => this.setState({showPinPopup: false})}
				                                      onSuccess={() => this.setState({showPinPopup: false}, () => this.forceEndTestSession())}/>}
			</>
		);
	}
}
