import React, {createRef} from 'react';
import {CloseIcon, Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import styles from './details.module.less';


export class DetailsViewer extends React.PureComponent<{ details: string, criteriaName: string, levelName: string, onClose: () => void }> {
	private readonly modalManagerRef: ModalManagerRef = createRef();

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader>
				<Title>
					{`Details: ${this.props.criteriaName} | ${this.props.levelName}`}
				</Title>
				<CloseIcon onClick={() => this.modalManagerRef.current.close(this.props.onClose)}/>
			</ModalHeader>
			<ModalBody className={styles.body}>
				<div className={styles.inputContainer}>
					{this.props.details}
				</div>
			</ModalBody>
			<ModalFooter/>
		</Modal>;
	}
}
