import ReactDOM from 'react-dom';
import React from 'react';
import {ContextMenu as BaseClass, ContextMenuProps, ContextMenuState} from '../context-menu/context-menu';

class Props extends ContextMenuProps<ContextMenuState> {
	itemID: number;
	editClicked: () => any;
	removeClicked: () => any;
}

export class ContextMenu extends BaseClass<ContextMenuState, Props> {

	render() {
		return ReactDOM.createPortal(
			<ul className='ig dropdown-menu card-context-menu'
			    style={{
				    left: this.state.x || this.props.x,
				    top: this.state.y || this.props.y,
			    }}
			    ref={r => this.rootElement = r}>

				<li>
					<a href='#' onClick={(e) => {
						this.props.editClicked();
						e.stopPropagation();
					}}>Edit</a>
				</li>
				<li>
					<a href='#' onClick={(e) => {
						this.props.removeClicked();
						e.stopPropagation();
					}}>Remove from Library</a>
				</li>
			</ul>, document.body);
	}
}