import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ListHeader} from '../list-header';
import {ListContainer} from './index.styled';
import {StudentModel} from '../../types';
import {useStudentsState} from './use-students-state';
import {Dispatch, useCallback} from 'react';
import {ContentCell} from '../content-cell';

type Props = {
	students: StudentModel[];
	setStudents: Dispatch<StudentModel[]>;
	selectedStudentsIDs: StudentModel['id'][];
	setSelectedStudentsIDs: React.Dispatch<React.SetStateAction<StudentModel['id'][]>>;
};

export function StudentsList({students, setStudents, selectedStudentsIDs, setSelectedStudentsIDs}: Props) {
	const {disableSortingCells, sortStudentsData} = useStudentsState({students, setStudents});

	const toggleSelectedStudentID = useCallback(
		(studentID: StudentModel['id']) => {
			setSelectedStudentsIDs((currentSelectedStudentsIDs) => {
				if (currentSelectedStudentsIDs.includes(studentID)) {
					return currentSelectedStudentsIDs.filter((id) => id !== studentID);
				}

				return [...currentSelectedStudentsIDs, studentID];
			});
		},
		[setSelectedStudentsIDs],
	);

	const toggleAllStudents = useCallback(() => {
		setSelectedStudentsIDs((currentSelectedStudentsIDs) => {
			const studentsWithCredentials = students.filter(({hasCredentials}) => Boolean(hasCredentials));

			return studentsWithCredentials.length === currentSelectedStudentsIDs.length
				? []
				: studentsWithCredentials.map(({id}) => id);
		});
	}, [setSelectedStudentsIDs, students]);

	return (
		<ListContainer>
			<ListHeader
				disableSortingCells={disableSortingCells}
				studentsCount={students.length}
				sortStudentsData={sortStudentsData}
				toggleAllStudents={toggleAllStudents}
			/>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				{students.map((student) => (
					<ContentCell
						student={student}
						toggleSelectedStudentID={toggleSelectedStudentID}
						selectedStudentsIDs={selectedStudentsIDs}
						key={student.id}
					/>
				))}
			</OverlayScrollbarsComponent>
		</ListContainer>
	);
}
