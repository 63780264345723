import {useCallback, useEffect, useState} from 'react';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {GeneralSection} from '../components/general-section';
import TeacherSection from './components/teacher';
import {ReportsSection} from './components/reports';
import {DistrictAdministratorSettingsService} from './service';
import ExportSection from './components/export';
import styles from './styles.module.less';
import {DesignVersion, routes, useDesignVersion} from '@esgi/main/libs/core';
import {BoxArrowUpRight} from '@esgi/ui/icons';

interface Props {
	onClose: () => void;
	userID: number;
}

function DistrictAdministratorSettings({userID, onClose}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	const service = useService(DistrictAdministratorSettingsService);

	const [isLoaded, setLoaded] = useState(false);
	const sortValues = useBehaviorSubject(service.sortValues);
	const timeZones = useBehaviorSubject(service.timeZones);
	const showExpiredUsers = useBehaviorSubject(service.showExpiredUsers);

	const reportShowStudentID = useBehaviorSubject(service.reportShowStudentID);

	const globalSchoolYears = useBehaviorSubject(service.globalSchoolYears);
	const selectedGlobalSchoolYear = useBehaviorSubject(service.selectedGlobalSchoolYears);

	const onSubmit = () => service.updateUserSettings().subscribe(handleClose);

	useEffect(() => {
		service.init(userID).subscribe(() => setLoaded(true));
	}, []);

	// const [, setDesignVersion] = useDesignVersion();
	//
	// const onNewDesignClicked = useCallback(() => {
	// 	setDesignVersion(DesignVersion.Redesign);
	// 	location.href = routes.districtAdmin.dashboard;
	// }, []);

	return (
		<>
			<ServiceLoader trackingService={service} fullscreen/>
			{isLoaded && (
				<Modal modalManagerRef={modalRef}>
					<Modal.Header>
						<Title>Settings</Title>
						<CloseIcon onClick={handleClose}/>
					</Modal.Header>

					<Modal.Body className={styles.body}>
						{/*<Buttons.Outlined className={styles.designSwitcherBtn} onClick={onNewDesignClicked}>*/}
						{/*	Go to the new version*/}
						{/*	<BoxArrowUpRight width={32} height={32}/>*/}
						{/*</Buttons.Outlined>*/}
						<div data-cy={'district-administrator-settings'}>
							<GeneralSection
								sortValues={sortValues}
								timeZones={timeZones}
								form={service.generalSectionForm}
							/>

							<TeacherSection
								showExpiredUsers={showExpiredUsers}
								onShowExpiredUsersClickHandler={(value) => service.showExpiredUsers.next(value)}
							/>

							<ExportSection
								selectedGlobalSchoolYear={selectedGlobalSchoolYear}
								globalSchoolYears={globalSchoolYears}
								onSelectGlobalSchoolYear={(value) => service.selectedGlobalSchoolYears.next(value)}
								exportRosterClickHandler={service.exportRosterClickHandler}
							/>

							<ReportsSection
								showStudentID={reportShowStudentID}
								onShowStudentID={(value) => service.reportShowStudentID.next(value)}
							/>
						</div>
					</Modal.Body>

					<Modal.Footer>
						<Buttons.Gray onClick={handleClose}>CANCEL</Buttons.Gray>
						<Buttons.Contained onClick={onSubmit}>SAVE</Buttons.Contained>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
}

export default DistrictAdministratorSettings;