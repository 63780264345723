import {useState} from 'react';

export type Props = {
	id: number,
	directUrl: string,
	fallbackUrl: string,
};
export function QuestionImage(props: Props) {
	const [hasError, setHasError] = useState(false);

	return <img data-cy={`question-${props.id}`} alt='' src={hasError ? props.fallbackUrl : props.directUrl} onError={() => setHasError(true)}/>;
}
