import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {MarkingPeriod, DetailReportServiceTransfer, ReportInit, SettingsInit, Student, Subject, UnityItem, User} from '../models/models';
import {FormDataBuilder} from '@esgi/api';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {
	RequestsAvailableTestsInit, RequestsReportInit,
	ResponsesReportInit, SearchTestResults,
} from '../models/api-models';

export interface ReportSettings {
	selectedStudent: Student[];
	selectedGroup: UnityItem[];
	selectedSubject: Subject[];
	selectedSpecialistGroup: UnityItem[];
	selectedClass: UnityItem[];
	testIDs: number[]
	carryScoresForward: boolean;
	students: Student[];
	teacher: User | null;
	globalSchoolYearId: number;
	hierarchy: HierarchySnapshot
}

export class ReportDataService extends BaseService {
	public report = new BehaviorSubject<ReportInit>(null);
	public students = new BehaviorSubject<Student[]>([]);
	public teacher = new BehaviorSubject<User | null>(null);
	public displayZeroIfNotTestedOption = new BehaviorSubject<'Zero' | 'NT'>(null);
	public displayZeroIfNotTested = new BehaviorSubject<boolean>(false);
	public printInColor = new BehaviorSubject<boolean>(false);
	public showBaseline = new BehaviorSubject<boolean>(false);
	public includeQuestionNotes = new BehaviorSubject<boolean>(false);
	public includeSummaryNotes = new BehaviorSubject<boolean>(false);
	public includeGradeScore = new BehaviorSubject<boolean>(false);
	public currentPeriod = new BehaviorSubject<string>('All');
	public markingPeriods = new BehaviorSubject<MarkingPeriod[]>([]);
	private hierarchy = new BehaviorSubject<HierarchySnapshot>(null);

	public init(data: DetailReportServiceTransfer, hierarchy: HierarchySnapshot) {
		this.students.next(data.students);
		this.teacher.next(data.teacher);
		this.displayZeroIfNotTestedOption.next(data.displayZeroIfNotTested ? 'Zero' : 'NT');
		this.displayZeroIfNotTested.next(data.displayZeroIfNotTested);
		this.printInColor.next(data.printInColor);
		this.showBaseline.next(data.showBaseline);
		this.includeQuestionNotes.next(data.includeQuestionNotes);
		this.includeSummaryNotes.next(data.includeSummaryNotes);
		this.includeGradeScore.next(data.includeGradeScore);
		this.currentPeriod.next(data.currentPeriod);
		this.markingPeriods.next(data.markingPeriods);
		this.hierarchy.next(hierarchy);
	}

	public updateReportData(args: ReportSettings) {
		const {
			selectedGroup,
			selectedSpecialistGroup,
			selectedStudent,
			selectedClass,
			selectedSubject, globalSchoolYearId,
		} = args;
		const testsRequest = new RequestsAvailableTestsInit();
		testsRequest.globalSchoolYearID = globalSchoolYearId;
		testsRequest.subjects = [selectedSubject?.[0]?.apiModel];
		testsRequest.searchBy = new SearchTestResults();
		testsRequest.searchBy.byStudentID = selectedStudent?.[0]?.studentID;
		testsRequest.hierarchy = this.hierarchy.value;

		if (selectedClass) {
			testsRequest.searchBy.byClassID = selectedClass?.[0]?.itemID;
		}
		if (selectedGroup) {
			testsRequest.searchBy.byGroupID = selectedGroup?.[0]?.itemID;
		}
		if (selectedSpecialistGroup) {
			testsRequest.searchBy.bySpecialistGroupID = selectedSpecialistGroup?.[0]?.itemID;
		}

		return this.httpClient.ESGIApi.get('reports/student-detail', 'AvailableTestsBrief', testsRequest);
	}


	public updateReport({
		                    selectedStudent, selectedClass,
		                    selectedGroup,
		                    selectedSpecialistGroup,
		                    testIDs,
		                    selectedSubject,
		                    carryScoresForward, students, teacher, hierarchy,

	                    }: ReportSettings) {
		const reportRequest = new RequestsReportInit();
		reportRequest.teacherID = teacher?.userID;

		reportRequest.studentIDs = [];

		if (selectedStudent?.[0]?.studentID === 0) {
			students.forEach((item) => {
				if (item.studentID !== 0) {
					reportRequest.studentIDs.push(item.studentID);
				}
			});
		} else {
			reportRequest.studentIDs.push(selectedStudent[0]?.studentID);
		}

		reportRequest.classID = selectedClass?.[0]?.itemID || 0;
		reportRequest.groupID = selectedGroup?.[0]?.itemID || 0;
		reportRequest.specialistGroupID = selectedSpecialistGroup?.[0]?.itemID || 0;
		reportRequest.testIDs = testIDs;
		reportRequest.subject = selectedSubject[0]?.apiModel;
		reportRequest.sortBy = teacher?.sortBy;
		reportRequest.carryScores = carryScoresForward;
		reportRequest.hierarchy = hierarchy;

		const formData = FormDataBuilder.BuildParameters(reportRequest);

		return this.httpClient.ESGIApi.post('reports/student-detail', 'Report', formData).subscribe((data: ResponsesReportInit) => {
			this.report.next(ReportInit.FromResponse(data));

			this.markingPeriods.next([{index: 0, title: 'B'}]);
			for (let i = 0; i < this.report.value.trackDates.length; i++) {
				this.markingPeriods.next(this.markingPeriods.value.concat({index: i + 1, title: (i + 1).toString()}));
			}
		});
	}


	public update(settings: SettingsInit) {
		this.report.next(settings.report);
	}
}
