import {TestContentArea} from './types';

export const legacyTestColorByContentArea: Record<TestContentArea, string> = {
	[TestContentArea.LanguageArts]: '90CAF9',
	[TestContentArea.Science]: 'ce93d8',
	[TestContentArea.SocialStudies]: 'fff59d',
	[TestContentArea.Math]: '80cbc4',
	[TestContentArea.Spanish]: 'ffcc80',
	[TestContentArea.SEL]: '9fa8da',
	[TestContentArea.Other]: 'cdcdcd',
};
