import {OverlayScrollbars} from 'overlayscrollbars';
import {scrollToElement} from '@esgi/deprecated/ui-kit/matrix';
import {Criteria, Description, Level, LevelDisplayMode} from '../models';
import {criteriaSorter, descriptionSorter, levelSorter} from '../utils/sorting';
import {TableLeftOffset, TableTopOffset} from './rubric-edit-form';
import {CriteriaActiveView} from './components/parts/criteria/criteria-active-view';
import {DescriptionActiveView} from './components/parts/description/description-active-view';
import {LevelActiveView} from './components/parts/level/level-active-view';

interface ScrollOptions<T> {
	osInstance: OverlayScrollbars;
	itemToRef: T[];
}

interface ValidateCriteriaOptions {
	criteria: Criteria[];
	scrollToIncorrect?: ScrollOptions<CriteriaActiveView>;
}

export function validateCriteria(options: ValidateCriteriaOptions): boolean {
	const {criteria, scrollToIncorrect} = options;
	const invalidCriteria = criteria.sort(criteriaSorter).filter(c => !c.name.trim());

	if (invalidCriteria.length) {
		if(scrollToIncorrect) {
			const firstInvalid = invalidCriteria[0];
			const {itemToRef, osInstance} = scrollToIncorrect;
			const criteria = itemToRef[firstInvalid.id];
			const element = criteria?.innerBoxRef?.current;
			if (element) {
				scrollToElement(element, osInstance, TableTopOffset, TableLeftOffset);
				criteria.textAreaRef.current?.focus();
			}

			for (const invalidCriterion of invalidCriteria) {
				const ref = itemToRef[invalidCriterion.id];
				ref.showValidation(true);
			}
		}
		return false;
	}
	return true;
}

interface ValidateLevelsOptions {
	levels: Level[];
	displayMode: LevelDisplayMode;
	scrollToIncorrect?: ScrollOptions<LevelActiveView>;
}

export function validateLevels(options: ValidateLevelsOptions): boolean {
	const {levels, displayMode, scrollToIncorrect} = options;

	if (displayMode === LevelDisplayMode.Text) {
		const invalidLevels = levels.sort(levelSorter).reverse().filter(l => !l.name.trim());

		if (invalidLevels.length) {
			if(scrollToIncorrect) {
				const {itemToRef, osInstance} = scrollToIncorrect;
				const firstInvalid = invalidLevels[0];
				const level = itemToRef[firstInvalid.id];
				const element = level.innerBoxRef?.current;
				if (element) {
					scrollToElement(element, osInstance, TableTopOffset, TableLeftOffset);
					level.nameInputRef.current?.focus();
				}

				for (const level of invalidLevels) {
					const ref = itemToRef[level.id];
					ref.showValidation(true);
				}
			}

			return false;
		}
	}

	return true;
}

interface ValidateDescriptionsOptions {
	descriptions: Description[];
	criteria: Criteria[];
	levels: Level[];
	scrollToIncorrect?: ScrollOptions<DescriptionActiveView>;
}

export function validateDescriptions(options: ValidateDescriptionsOptions): boolean {
	const {descriptions, criteria, levels, scrollToIncorrect} = options;
	const invalidDescriptions = descriptions.sort(descriptionSorter(criteria, levels)).filter(d => !d.description.trim());
	if (invalidDescriptions.length) {
		if(scrollToIncorrect) {
			const {itemToRef, osInstance} = scrollToIncorrect;
			const firstInvalid = invalidDescriptions[0];
			const description = itemToRef[firstInvalid.id];
			const element = description?.boxRef.current;

			if (element) {
				scrollToElement(element, osInstance, TableTopOffset, TableLeftOffset);
				description.textAreaRef.current?.focus();
			}

			for (const description of invalidDescriptions) {
				const ref = itemToRef[description.id];
				ref.showValidation(true);
			}
		}

		return false;
	}
	return true;
}
