import {LoginResponse} from '../types';

export interface ValidationInfo {
	type: keyof typeof ValidationType,
	message: string,
	url: string,
	alertType: keyof typeof ValidationAlertType,
	additionalInfo: Record<string, string>,
}

/* The ValidationResult class represents the result of a validation process, including whether it is
valid, an optional action to be performed, and an optional URL. */
export class ValidationResult {
	valid: boolean;
	action: () => Promise<any>;
	url: string;

	static Invalid(action?: () => Promise<any>, url?: string): ValidationResult {
		return {
			valid: false,
			action: action || (() => Promise.resolve()),
			url: url,
		};
	}
}

export enum ValidationType {
	None = 'None',
	Redirect = 'Redirect',
	Alert = 'Alert',
	AdminExpired = 'AdminExpired',
}

export type ValidationHandler = (value: LoginResponse) => Promise<void>;

export enum ValidationAlertType {
	//Defaults
	LinkedToExpiredPrimaryAccount, //html
	SiteIsUnderMaintenance,
	PreAssessModeNotEnabled,
	PreAssessAccountNotActive,

	//Step
	NotSupportedUserRole, //html
	AccountIsDeactivated,
	SchoolIsNotActive,
	DistrictIsNotActive,
	AccountIsBeingMoved,
	NoConfiguredSchoolYear,
	NoActiveSchoolYear,
	NoActiveSchoolYearDueToDistrictChanges,

	//Other
	TokenIsInvalidOrExpires,
	IncorrectUsername,
	IncorrectPassword,
	SecondaryAccountIsInactive, //html
	AccountIsMovedToCanada,
}
