import {getThemeColor} from '../../utils';
import {ThemeColorKeys} from '../../types';
import {ComponentPropsWithoutRef, forwardRef, useCallback, useEffect, useMemo} from 'react';
import {usePieChartContext} from '../context';
import {Point} from '../types';
import {useOpacityAnimation} from '../hooks/use-opacity-animation';
import {animated} from 'react-spring';
import {getStrokeLinecapRoundRadius} from '../utils/get-stroke-linecap-round-radius';

type PieChartProgressLineProps = Omit<
	ComponentPropsWithoutRef<typeof animated.line>,
	'children' | 'stroke' | 'strokeWidth' | 'fill' | 'x1' | 'y1' | 'x2' | 'y2'
> & {
	/**
	 * Set stroke color for progress line.
	 * @default 'primaryVivid'
	 */
	stroke?: ThemeColorKeys;

	/**
	 * Set width for the progress line.
	 * @default 2
	 */
	width?: number;

	/**
	 * Set height for the progress line.
	 * @default 2
	 */
	height?: number;

	/**
	 * The value for rendering progress line in a svg boundary.
	 * Progress should be in the range [minValue, maxValue] of the root component
	 */
	value: number;
};

export const PieChartProgressLine = forwardRef<SVGLineElement, PieChartProgressLineProps>(
	({stroke = 'primaryVivid', width = 2, height = 2, value, strokeLinecap = 'round', ...lineProps}, forwardedRef) => {
		const {getValueProgress, svgValue, updateShapeOffset, maxShapeOffset} = usePieChartContext();

		const lineHeight = useMemo(
			() => (strokeLinecap === 'round' ? height + 2 * getStrokeLinecapRoundRadius(width) : height),
			[height, strokeLinecap, width],
		);

		useEffect(() => {
			updateShapeOffset({shape: 'progressLine', offset: Math.max(width, lineHeight)});
		}, [height, lineHeight, strokeLinecap, updateShapeOffset, width]);

		const getCoordinatesByAngle = useCallback(
			(angle: number) => {
				const svgAdaptedRadius = (svgValue.size - maxShapeOffset) / 2;
				const angleRadian = ((angle - 90) * Math.PI) / 180;

				return {
					x: svgValue.center.x + svgAdaptedRadius * Math.cos(angleRadian),
					y: svgValue.center.y + svgAdaptedRadius * Math.sin(angleRadian),
				};
			},
			[maxShapeOffset, svgValue.center.x, svgValue.center.y, svgValue.size],
		);

		const lineInfo = useMemo(() => {
			const valueProgress = getValueProgress(value);
			const angle = valueProgress * 360;

			const progressPoint = getCoordinatesByAngle(angle);

			const mainVector: Point = {
				x: svgValue.center.x - progressPoint.x,
				y: svgValue.center.y - progressPoint.y,
			};

			const vectorLength = Math.sqrt(Math.pow(mainVector.x, 2) + Math.pow(mainVector.y, 2));

			const unitVector: Point = {
				x: mainVector.x / vectorLength,
				y: mainVector.y / vectorLength,
			};

			const halfLineHeight = height / 2;

			const startPoint: Point = {
				x: progressPoint.x + unitVector.x * halfLineHeight,
				y: progressPoint.y + unitVector.y * halfLineHeight,
			};

			const endPoint: Point = {
				x: progressPoint.x - unitVector.x * halfLineHeight,
				y: progressPoint.y - unitVector.y * halfLineHeight,
			};

			return {
				startPoint,
				endPoint,
			};
		}, [getCoordinatesByAngle, getValueProgress, height, svgValue.center.x, svgValue.center.y, value]);

		const lineAnimationProps = useOpacityAnimation();

		return (
			<animated.line
				style={lineAnimationProps}
				x1={lineInfo.startPoint.x}
				y1={lineInfo.startPoint.y}
				x2={lineInfo.endPoint.x}
				y2={lineInfo.endPoint.y}
				stroke={getThemeColor(stroke)}
				strokeWidth={width}
				strokeLinecap={strokeLinecap}
				ref={forwardedRef}
				{...lineProps}
			/>
		);
	},
);
