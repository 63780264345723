import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ActionButtonsDivider = styled(Box, {
	borderRadius: 1,
	width: 1.5,
	backgroundColor: '$background',
	marginLeft: 4,
	marginRight: 4,
});
