import {Dispatch, useCallback, useEffect, useState} from 'react';
import {SettingsPanel, ToggleGroup} from '@esgi/main/kits/reports';
import {NotTested} from './types';
import {items} from './constants';

interface Props {
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function NotTestedControl({value: externalValue, onChange}: Props) {
	const [value, setValue] = useState<NotTested>(NotTested.Zero);

	useEffect(() => {
		setValue(externalValue ? NotTested.Zero : NotTested.NT);
	}, [externalValue]);

	const handleValueChanged = useCallback(
		(newValue: string | number) => {
			const adaptedValue = newValue as NotTested;

			setValue(adaptedValue);
			onChange(adaptedValue === NotTested.Zero);
		},
		[onChange],
	);

	return (
		<SettingsPanel data-cy='not-tested-settings-panel' title='Display Not Tested As'>
			<ToggleGroup
				value={value}
				options={items}
				onChange={handleValueChanged}
				dataCy='display-not-tested-as-toggle-group'
			/>
		</SettingsPanel>
	);
}
