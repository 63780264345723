import React, {PropsWithChildren} from 'react';
import {ContentWrapper, ListWrapper} from './index.styled';
import {BottomSectionButton} from '../../../bottom-section-button';

export type ExpandablePanelModeVariantProps = PropsWithChildren<
	| {
			withBottomSectionButton: false;
	  }
	| {
			withBottomSectionButton: true;
			onBottomButtonClick: VoidFunction;
			buttonText: string;
			isBottomSectionButtonDisabled?: boolean;
			bottomSectionButtonWithIcon?: boolean;
	  }
>;

export function ExpandablePanelModeVariant({children, ...restProps}: ExpandablePanelModeVariantProps) {
	const WrapperTag = restProps.withBottomSectionButton ? ContentWrapper : React.Fragment;

	return (
		<WrapperTag>
			<ListWrapper>{children}</ListWrapper>

			{restProps.withBottomSectionButton && (
				<BottomSectionButton
					onClick={restProps.onBottomButtonClick}
					buttonText={restProps.buttonText}
					disabled={Boolean(restProps.isBottomSectionButtonDisabled)}
					withIcon={Boolean(restProps.bottomSectionButtonWithIcon)}
				/>
			)}
		</WrapperTag>
	);
}
