import {SortCell} from './types';

export const sortRows = [
	new SortCell('preassess', 'Teacher'),
	new SortCell('lastLoginDate', 'Last Login Date'),
	new SortCell('loginCount', 'Login Count'),
	new SortCell('testsAuthored', 'Total Tests Authored'),
	new SortCell('testsAdministered', 'Tests Administered'),
	new SortCell('testsCompleted', 'Tests Completed'),
	new SortCell('questionsAsked', 'Questions Asked'),
	new SortCell('studentsEnrolled', 'Students Enrolled'),
	new SortCell('studentsTested', 'Students Tested'),
];
