import {useEventEffect} from '@esgillc/events';
import {Dispatch, RefObject} from 'react';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {DataStudentsService} from '../../../services/data-students-service';
import {
	AddNewClass,
	AddNewGroup,
	AddNewStudentViaFile,
	AddStudent,
	EditClass,
	EditGroup,
	EditStudent,
	RemoveClass,
	RemoveGroup,
	RemoveStudentEvent,
} from '@esgi/main/features/teacher/drawers';
import {ChangeSortBy} from '@esgi/main/features/teacher/events';
import {Class, Group} from '@esgi/main/libs/store';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {isNull} from 'underscore';

export function useHandleEvents({
	dataService,
	teacherSnackbarRef,
	selectedClassId,
	selectedGroupId,
	setSelected,
	classList,
	groupList,
}: {
	dataService: DataStudentsService;
	teacherSnackbarRef: RefObject<SnackbarManager>;
	selectedClassId: Class['id'] | null;
	selectedGroupId: Group['id'] | null;
	setSelected: Dispatch<SingleSelectState>;
	classList: Class[];
	groupList: Group[];
}) {
	useEventEffect(AddNewClass, ({newClass}) => {
		dataService.addNewClass(newClass);
		teacherSnackbarRef.current?.showSnackbar(`You've created ${newClass.name}`);
	});

	useEventEffect(EditClass, ({classInfo}) => {
		dataService.editClass(classInfo);
		teacherSnackbarRef.current?.showSnackbar(`You've edited ${classInfo.name}`);
	});

	useEventEffect(RemoveClass, ({classInfo: {name, id}}) => {
		dataService.removeClass(id);

		if (!isNull(selectedClassId) && selectedClassId === id) {
			setSelected({
				classId: classList?.[0]?.id ?? null,
				groupId: null,
				studentId: null,
				studentFromClassId: null,
				studentFromGroupId: null,
			});
		}

		teacherSnackbarRef.current?.showSnackbar(`${name} deleted successfully.`);
	});

	useEventEffect(AddNewGroup, ({newGroup}) => {
		dataService.addNewGroup(newGroup);
		teacherSnackbarRef.current?.showSnackbar(`You've created ${newGroup.name}`);
	});

	useEventEffect(EditGroup, ({groupInfo}) => {
		dataService.editGroup(groupInfo);
		teacherSnackbarRef.current?.showSnackbar(`You've edited ${groupInfo.name}`);
	});

	useEventEffect(RemoveGroup, ({groupInfo: {name, id}}) => {
		dataService.removeGroup(id).subscribe((updatedGroupList) => {
			if (!isNull(selectedGroupId) && selectedGroupId === id) {
				setSelected({
					classId: !updatedGroupList.length && classList.length ? classList[0]!.id : null,
					groupId: updatedGroupList.length ? updatedGroupList[0]!.id : null,
					studentId: null,
					studentFromClassId: null,
					studentFromGroupId: null,
				});
			}
		});

		teacherSnackbarRef.current?.showSnackbar(`${name} deleted successfully.`);
	});

	useEventEffect(AddNewStudentViaFile, ({studentsData: {classId, students}}) => {
		dataService.addNewStudentsToClass({students, classId});
		teacherSnackbarRef.current?.showSnackbar(`File with ${students.length} students has been successfully uploaded.`);
	});

	useEventEffect(ChangeSortBy, ({sortBy}) => {
		dataService.changeSortBy(sortBy);
	});

	useEventEffect(AddStudent, ({studentsData: {classIds, student, groupIds}}) => {
		dataService.addStudent({student, classIds, groupIds});
		teacherSnackbarRef.current?.showSnackbar(`You've created ${student.firstName} ${student.lastName}`);
	});

	useEventEffect(EditStudent, ({studentsData: {classIds, student, groupIds}}) => {
		dataService.editStudent({student, classIds, groupIds});
		teacherSnackbarRef.current?.showSnackbar(`You've edited ${student.firstName} ${student.lastName}`);
	});
	useEventEffect(RemoveStudentEvent, ({student}) => {
		dataService.removeStudent(student.id);
		teacherSnackbarRef.current?.showSnackbar(`You've deleted ${student.firstName} ${student.lastName}`);
	});
}
