import {ArrowRightIconProps} from '@esgillc/ui-kit/icons';

export function WarningTriangleIcon(props: ArrowRightIconProps) {
	return (
		<svg {...props} width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.806213 15.1088H17.3062L9.05621 0.858765L0.806213 15.1088ZM9.80621 12.8588H8.30621V11.3588H9.80621V12.8588ZM9.80621 9.85876H8.30621V6.85876H9.80621V9.85876Z'
				fill='#FEC602'
			/>
		</svg>
	);
}
