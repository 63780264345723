import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {Language, LetterTemplate, LetterType} from 'pages/parent-conferencer/models/models';

export class Service extends BaseService {
	controller: string = 'ParentConferencer';
	private requestInProgress: boolean;

	private selectedLetterType: BehaviorSubject<LetterType> = new BehaviorSubject<LetterType>(null);
	private selectedLanguage: BehaviorSubject<Language> = new BehaviorSubject<Language>(null);
	private template: BehaviorSubject<LetterTemplate> = new BehaviorSubject<LetterTemplate>(null);

	constructor() {
		super();

		this.completeOnDestroy(combineLatest([this.selectedLetterType, this.selectedLanguage])).subscribe(data => {
			const selectedLetterType = data[0];
			const selectedLanguage = data[1];

			if (selectedLetterType === null || selectedLanguage === null) {
				return;
			}

			this.loadLetterTemplate(selectedLetterType.letterTypeID, selectedLanguage.languageID);
		});

		this.busy.subscribe((busy) =>this.requestInProgress = busy);
	}

	public setLetterType(letterType: LetterType) {
		this.selectedLetterType.next(letterType);
	}

	public setLanguage(language: Language) {
		this.selectedLanguage.next(language);
	}

	private loadLetterTemplate(letterTypeID: number, languageID: number) {
		return this.httpClient.ESGIApi.get<LetterTemplate>(this.controller, 'LoadLetterTemplate', {
			letterTypeID: letterTypeID,
			languageID: languageID,
		}).subscribe((template) => {
			this.template.next(template);
		});
	}

	public save(message: string) {
		if (this.requestInProgress){
			return;
		}

		if (message === this.template.value.body) {
			return;
		}

		const revert = !this.template.value.isDefault && message === this.template.value.defaultBody;

		this.httpClient.ESGIApi.post<{ letterTemplateID: number }>(this.controller, 'SaveLetterTemplate', {
			letterTemplateID: this.template.value.letterTemplateID,
			body: message,
			letterTypeID: this.selectedLetterType.value.letterTypeID,
			languageID: this.selectedLanguage.value.languageID,
			revert: revert,
		})
			.subscribe((response) => {
				const newTemplate = {
					body: this.template.value.body,
					defaultBody: this.template.value.defaultBody,
					isDefault: this.template.value.isDefault,
					letterTemplateID: response.letterTemplateID,
				} as LetterTemplate;

				this.template.next(newTemplate);
			});
	}


	public template$: Observable<LetterTemplate> = this.completeOnDestroy(this.template);
	public selectedLetterType$: Observable<LetterType> = this.completeOnDestroy(this.selectedLetterType);
	public selectedLanguage$: Observable<Language> = this.completeOnDestroy(this.selectedLanguage);
}
