import React, {useState} from 'react';
import {AnchorLikeButton} from '@esgi/deprecated/ui-kit/buttons';
import {join} from '@esgillc/ui-kit/utils';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import DetailsDialog from '../../../../../common/details-dialog/details-dialog';
import {Description} from '../../../../../common/types';
import styles from './description.module.less';

interface Props {
	selected: boolean;
	description: Description;
	onClicked: () => void;
	criteriaName: string;
	levelName: string;
	editMode: boolean;
}

export function DescriptionView({selected, description, onClicked, criteriaName, levelName, editMode}: Props) {
	const [showDetailsDialog, setShowDetailsDialog] = useState(false);
	const noTouch = !('ontouchstart' in document.documentElement);

	return (
		<>
			<div className={styles.box}>
				<div className={join(styles.innerBox,
					editMode && styles.editMode,
					selected && styles.selected,
					noTouch && styles.noTouch)}
				     onClick={onClicked}
				>
					<div className={styles.body}>
						<div className={styles.description}>
						<span>{description.description}</span>
							<div className={styles.selectIcon}>
								<svg width='20' height='20' viewBox='0 0 20 20' fill='none'
								     xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z'
										fill='#07D540'/>
								</svg>
							</div>
						</div>
					</div>
					<div className={styles.footer}>
						{description.details &&
							<OnHoverTooltip message={description.details} placement='bottom'>
								<div className={styles.detailsButtonContainer}>
									<AnchorLikeButton
										className={styles.detailsButton}
										onClick={(e) => {
											e.stopPropagation();
											setShowDetailsDialog(true);
										}}>Details</AnchorLikeButton>
								</div>
							</OnHoverTooltip>
						}
					</div>
				</div>
			</div>
			{showDetailsDialog &&
				<DetailsDialog
					details={description.details}
					criteriaName={criteriaName}
					levelName={levelName}
					onClose={() => setShowDetailsDialog(false)}
				/>
			}
		</>
	);
}
