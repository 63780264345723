import React from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {ReportSettingsService} from '../../../../../../services/report-settings-service';

interface Props {
	service: ReportSettingsService;
}

export function DetailReportSettingsSubjects({service}: Props) {
	const subjects = useBehaviorSubject(service.subjects);
	const selectedSubject = useBehaviorSubject(service.selectedSubject);
	return subjects && subjects.length > 0 && selectedSubject && <>
		<div>
			<strong>Subject Tab: </strong>
			<Dropdown autoWidth={true} setValue={(v) => service.changeHierarchy('subject', v)}
			          optionName={'name'}
			          value={selectedSubject}>
				{subjects.map(subject => <Option key={subject.subjectID}
				                                 value={subject.subjectID}>{subject.name}</Option>)}
			</Dropdown>
		</div>
	</>;
}
