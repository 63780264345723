import React from 'react';

class Props {
	direction: Direction;
	clicked: () => any;
}

export class ArrowButton extends React.PureComponent<Props> {
	render() {
		return <a href='#' className={'arrow-button ' + Direction[this.props.direction]}
		          onClick={() => this.props.clicked()}>
			{this.props.direction == Direction.right &&
			<svg xmlns='http://www.w3.org/2000/svg' width='13' height='22' viewBox='0 0 13 22' fill='none'>
				<path
					d='M0.0324707 19.0325L8.04747 11L0.0324707 2.9675L2.49997 0.5L13 11L2.49997 21.5L0.0324707 19.0325Z'
					fill='#BDBDBD'/>
			</svg>}
			{this.props.direction == Direction.left &&
			<svg xmlns='http://www.w3.org/2000/svg' width='13' height='22' viewBox='0 0 13 22' fill='none'>
				<path d='M12.9675 19.0325L4.9525 11L12.9675 2.9675L10.5 0.5L0 11L10.5 21.5L12.9675 19.0325Z'
				      fill='#BDBDBD'/>
			</svg>}
		</a>;
	}
}


export enum Direction {
	left,
	right
}