import './grade-scale-report.less';
import {resolvedPromise} from '@esgi/deprecated/utils';
import {HierarchyMode} from 'modules/hierarchy/core/models';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {Renderable, ModalWindow} from '@esgi/deprecated/knockout';
import {SubjectType, StudentSort} from '@esgi/core/enums';
import {Loader} from '@esgi/deprecated/jquery';
import {isIpad, isMobile} from '@esgillc/ui-kit/utils';
import {GoogleAnalyticsTracker} from '@esgi/core/tracker';
import {GradeReportTemplate} from './template';
import {PrintStudentReport} from './print-report';
import {userStorage, UserType} from '@esgi/core/authentication';
import {openModal} from '@esgi/deprecated/react';
import {
	EntityInfo,
	EntityModel,
	GradeLevelModel,
	GradeScaleHierarchyLevel,
	IInitReportResponse,
	ReportModel,
	RequestModel,
	Row,
	SubjectModel,
	TestModel,
	TestResponse,
	TestResult,
	UserEntities,
	BackgroundGenerationModel,
} from './models';
import {GradeRangeType, IReportGradeScale, SubjectLevel} from 'shared/modules/grade-scale/models';
import Wizard from 'shared/modules/grade-scale/wizard/component';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {ExceptionReporter} from 'shared/alert/exception-reporter/reporter';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {EventBusManager} from '@esgillc/events';
import {GradeScaleReportService} from './services';

export class Form extends Renderable {
	isTouchDevice = isIpad() || isMobile();
	subjectId: number;
	selectedSchoolId: number;
	subjectType: string;
	subjectLevel: string;
	selectedTestIds: number[];
	userType: UserType;
	reportId: number;
	isLastPeriod = ko.observable<boolean>();
	isMobile = ko.observable<boolean>(false);
	isEdge = ko.observable<boolean>(false);
	isIE = ko.observable<boolean>(false);
	originalReportID = 0;
	originalReportType = ko.observable<string>(null);
	carryScoresForward = ko.observable<boolean>();
	displayZeroIfNotTestedOption = ko.observable<string>();
	hasNotConfiguredSet = ko.observable<boolean>();
	areSettingsVisible = ko.observable<boolean>(false);
	model: ReportModel;
	selectedSubjectId = ko.observable<number>();
	selectedSubject = ko.observable<SubjectModel>();
	selectedGroup = ko.observable<EntityModel>();
	selectedTeachersGroup = ko.observable<EntityModel>();
	selectedSchoolsGroup = ko.observable<EntityModel>();
	selectedGroupOfSpecialists = ko.observable<EntityModel>();
	selectedSpecialistGroup = ko.observable<EntityModel>();
	selectedUserEntity = ko.observable<number>();
	selectedClass = ko.observable<EntityModel>();
	selectedSchool = ko.observable<EntityModel>();
	selectedGradeLevel = ko.observable<GradeLevelModel>(null);
	currentPeriod = ko.observable<string>('All');
	doNotAllowEditSubjectByAdmin = ko.observable<boolean>(false);
	loader: Loader;
	reportLoading: boolean = false;
	loadingByGradeLevel: boolean = false;
	readonly = ko.observable(true);
	args = ko.observable<string[]>([]);
	managedByLabelText = ko.computed({
		read: () => {
			if (this.selectedSubject().level === 'District') {
				return this.selectedSubject() ? '(Managed by district)' : '';
			}
			if (this.selectedSubject().level === 'School') {
				return this.selectedSubject() ? '(Managed by school)' : '';
			}
			if (this.selectedSubject().level === 'Teacher' && this.hierarchy.mode === HierarchyMode.Classic ) {
				return this.selectedSubject() ? '(Managed by teacher)' : '';
			}
			if (this.selectedSubject().level === 'Teacher' && this.hierarchy.mode === HierarchyMode.Specialist ) {
				return this.selectedSubject() ? '(Managed by specialist)' : '';
			}
		},
		deferEvaluation: true,
	});
	userTitleText = ko.computed({
		read: () => {
			if (this.originalReportType() === 'SpecialistGroup' && [UserType.ISD, UserType.ISS].includes(this.currentUser.userType)) {
				return 'Specialist';
			}
			return 'Teacher';
		},
		deferEvaluation: true,
	});
	totalWidth = ko.computed({
		read: () => {
			const tests = this.data.tests();
			return tests.reduce((p, t) => p + t.width(), 0);
		},
		deferEvaluation: true,
	});
	reportType = ko.observable('Class');
	intervalId: number;
	private scrollProcessing: boolean = false;
	private rowsBlockSize: number = 100;
	private currentUser = userStorage.get();
	private readonly eventBus = new EventBusManager();
	private readonly api = new GradeScaleReportService();

	constructor(public level: GradeScaleHierarchyLevel, private hierarchy: HierarchySnapshot) {
		super();
	}

	public afterRender(rootElement): JQueryPromise<any> {
		this.loader = new Loader(
			$(rootElement).parents('.modal-content'),
			{longLoadingText: 'Please wait. This report may take a few minutes to load.'},
		);

		this.synchronizeTable();
		this.cellHover();

		return super.afterRender(rootElement);
	}

	template = () => {
		return GradeReportTemplate.render();
	};

	public getCellSortClass(index: number) {
		let _class = 'fa sort-icon ';
		if (this.data.sortIndex() === index) {
			_class += 'visible ';
			if (this.data.sortDirection().toLowerCase() === 'asc') {
				_class += 'fa-arrow-down ';
			}
			_class += 'fa-arrow-up';
		} else {
			_class += index < 0 ? 'fa-arrow-down' : 'fa-arrow-up';
		}

		return _class;
	}

	data = {
		reportName: '',
		subjects: ko.observableArray<SubjectModel>([]),
		groups: ko.observableArray<EntityModel>([]),
		specialistGroups: ko.observableArray<EntityModel>([]),
		classes: ko.observableArray<EntityModel>([]),
		teachers: ko.observableArray<EntityModel>([]),
		schools: ko.observableArray<EntityModel>([]),
		teachersGroups: ko.observableArray<EntityModel>([]),
		schoolsGroups: ko.observableArray<EntityModel>([]),
		groupsOfSpecialists: ko.observableArray<EntityModel>([]),
		rows: ko.observableArray<Row>([]),
		userEntities: ko.observableArray<UserEntities>([]),
		defaultRows: [],
		gradeScale: ko.observable<IReportGradeScale>(),
		tests: ko.observableArray<TestModel>([]),
		sortIndex: ko.observable<number>(0),
		sortDirection: ko.observable('DESC'),
		headerColumns: ko.observableArray<string>([]),
		infoRows: ko.observableArray<string>([]),
		gradeLevels: ko.observableArray<GradeLevelModel>([]),
	};

	getColorWithAlpha(color) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
		const r = parseInt(result[1], 16);
		const g = parseInt(result[2], 16);
		const b = parseInt(result[3], 16);

		return `rgba(${r}, ${g}, ${b}, 1)`;
	}

	scrolled(data, event) {
		if (this.scrollProcessing) {
			return;
		}
		const elem = event.target;
		if (elem.scrollTop > (elem.scrollHeight - elem.offsetHeight - 50)) {

			const scrollTop = elem.scrollTop;
			if (this.data.rows().length < this.data.defaultRows.length) {
				this.scrollProcessing = true;

				let len = this.data.rows().length + this.rowsBlockSize;
				if (len > this.data.defaultRows.length) {
					len = this.data.defaultRows.length;
				}
				this.data.rows(this.data.defaultRows.slice(0, len));

				setTimeout(() => {
					this.synchronizeTable().done(() => {
						elem.scrollTop = scrollTop;
					});

					setTimeout(() => {
						this.scrollProcessing = false;
					}, 500);
				}, 200);
			}
		}
	}

	private loadReport(subjectId: number, subjectType: string, subjectLevel: string, selectedTestIds: number[]) {
		this.reportLoading = true;

		if (this.loader) {
			this.loader.mask();
		}

		return this.api.get(this.getRequestModel(subjectId, subjectType, subjectLevel, selectedTestIds))
			.subscribe({
				next: (model) => this.server.getRowsBlock({model, subjects: []}, false),
				complete: () => $(this).trigger('onLoaded'),
				error: () => {
					this.loader.unmask();
					$(this).trigger('onLoaded');
				},
			});
	}

	private reportLoaded(model: ReportModel, withUpdatingSortDirection = true) {
		this.hasNotConfiguredSet(model.hasNotConfiguredSet);
		$(this).trigger('emptyReport', model.rows.length === 0);


		if (model.userEntities) {
			this.selectedUserEntity(0);
			this.selectedUserEntityID = 0;
			this.data.userEntities(model.userEntities);
		}

		this.data.headerColumns.removeAll();
		for (let i = 0; i < model.headerRowNames.length; i++) {
			this.data.headerColumns.push(model.headerRowNames[i]);
		}

		this.data.infoRows.removeAll();
		for (let i = 0; i < model.infoRows.length; i++) {
			this.data.infoRows.push(model.infoRows[i]);
		}

		this.readonly(model.readonly);

		this.reportType(model.gradeReportType);

		let cols = 0;
		let periods = 0;

		this.data.tests(model.tests.map((t: TestResponse) => {
			const test = TestModel.FromResponse(t);
			test.width = ko.computed({
				read: () => {
					let width = 130;
					width = Math.max(width, (t.periods.length + 1) * 42);
					return width;
				},
				deferEvaluation: true,
			});

			periods = test.periods.length;
			cols += periods + 1;

			return test;
		}));

		const {studentSort} = this.hierarchy;
		let sortIndex = studentSort === StudentSort.FirstName ? -1 : -2;
		if (this.data.reportName !== '') {
			const headerCols = model.gradeReportType === 'StudentsSchool' ? 4 : model.gradeReportType === 'StudentsDistrict' ? 1 : 2;
			sortIndex = -headerCols;
		}
		this.data.reportName = model.reportName;
		this.model = model;
		this.args(model.args);
		this.data.gradeScale(model.gradeScale);
		this.carryScoresForward(model.carryScoresForward);
		this.displayZeroIfNotTestedOption(model.displayZeroIfNotTested ? 'Zero' : 'NT');
		this.currentPeriod(model.markingPeriodAll ? 'All' : 'Current');
		this.isLastPeriod(!model.markingPeriodAll);

		if (this.model.studentGradeLevels && !this.loadingByGradeLevel) {
			const gradeLevels = this.model.studentGradeLevels;
			gradeLevels.unshift({gradeLevelID: 0, name: 'All'});
			this.data.gradeLevels(gradeLevels);
			this.selectedGradeLevel(gradeLevels[0]);
		}

		const rows = this.model.rows;
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			if (row) {
				const results = row.testResults;
				for (let j = 0; j < results.length; j++) {
					const result = results[j];
					result.backgroundColor = this.getColorWithAlpha(result.color);
					result.rightBorder = j % (periods + 1) === periods;
					result.leftBorder = j === 0;
				}
			}
		}

		this.data.defaultRows = model.rows;

		let len = this.rowsBlockSize;
		if (len > this.data.defaultRows.length) {
			len = this.data.defaultRows.length;
		}
		this.sort(sortIndex, len, withUpdatingSortDirection);

		$('.left-top-panel').css('width', '');
		$('.left-bottom-panel > div').css('width', '');
		$('.subheader-title').css('width', '');

		setTimeout(() => {
			this.synchronizeTable();
			if (model.rows.length > 0) {
				this.synchronizeScrolls();
			}
			this.updateResultCellsDisplay();

			if (this.loader) {
				this.loader.unmask();
			}
		}, 100);
	}

	reload() {
		const {subjectID, subjectType, level} = this.selectedSubject();
		this.loadReport(subjectID, subjectType, level, this.selectedTestIds);
	}

	updateReportBySubject(subjectId) {
		this.selectedTestIds = [];

		const {subjectID, subjectType, level, hasGradeScales} = this.selectedSubject();
		if (hasGradeScales) {
			this.loadReport(subjectID, subjectType, level, this.selectedTestIds);
		}
	}

	updateReportByReport(reportId) {
		this.reportId = reportId;

		const {subjectID, subjectType, level} = this.selectedSubject();
		this.loadReport(subjectID, subjectType, level, this.selectedTestIds);
	}

	isCellShown(index: number) {
		if (isNaN(index)) {
			return false;
		}

		return !this.isLastPeriod() || (index + 1) % (this.data.tests()[0].periods.length + 1) === 0;
	}

	synchronizeTable() {
		if (this.rootElement) {
			this.fixTestNames();
			this.fixHeadWidth();
			this.fixLeftPanelWidth();
			this.fixScrollPosition();

			const report = $('.grade-report');

			if (this.isEdge() || this.isIE()) {
				report.find('#downloadDropdown li').each(function (index: number, elem: Element) {
					let display = $(elem).css('display');
					display = display != 'block' ? 'block' : '';
					$(elem).css('display', display);
				});
			}

			// ie has a bug, so scrollbars do not show up
			// this hack makes it show them
			return ModalWindow.makeIeRedraw();
		}

		return resolvedPromise(null);
	}


	cellWidth(index: number, testId: number) {
		const isCellShown = this.isCellShown(index);
		if (isCellShown) {
			const test = this.data.tests().filter(t => t.testID === testId)[0];
			const n = test.periods.length + 1;
			const firstTest = Math.floor(index / n) == 0;
			const testWidth = test.width() - (firstTest ? 2 : 0);
			let resultsWidth = 0;

			if (this.currentPeriod() == 'All') {
				resultsWidth = Math.floor(testWidth / n);
				if (index % n < testWidth % resultsWidth) {
					resultsWidth += 1;
				}
			} else {
				resultsWidth = testWidth;
			}

			return Math.max(resultsWidth, firstTest ? 41 : 42);
		}

		return 0;
	}

	updateDoNotAllowEditSubjectByAdmin() {
		this.doNotAllowEditSubjectByAdmin(this.userType == UserType.D ? this.subjectType == 'Personal' || (this.subjectType == 'Deployed' && this.subjectLevel == 'School') : this.userType == UserType.C && this.subjectType == 'Personal');
	}

	synchronizeScrolls = () => {
		if (this.rootElement) {
			$('.right-bottom-panel', this.rootElement).on('scroll',
				() => {
					$('.left-bottom-panel').scrollTop($('.right-bottom-panel').scrollTop());
					$('.right-top-panel').scrollLeft($('.right-bottom-panel').scrollLeft());
				});

			$('.left-bottom-panel', this.rootElement).on('mousewheel',
				(event, delta, deltaX, deltaY) => {
					if (deltaY) {

						const scrollTop = $('.left-bottom-panel').scrollTop();
						const y = scrollTop - (deltaY * 171);

						$('.right-bottom-panel').scrollTop(y);
					}
				});

			const start = {x: 0, y: 0, scrollTop: 0};

			const touchStart = (event) => {

				start.x = event.touches[0].pageX;
				start.y = event.touches[0].pageY;

				start.scrollTop = $('.left-bottom-panel').scrollTop();
			};

			const touchMove = (event) => {

				const offset = {x: 0, y: 0};

				offset.x = start.x - event.touches[0].pageX;
				offset.y = start.y - event.touches[0].pageY;

				if (Math.abs(offset.x) < Math.abs(offset.y)) {
					event.preventDefault();
				}

				if (offset.y) {
					const y = start.scrollTop + (offset.y);
					$('.left-bottom-panel, .right-bottom-panel').scrollTop(y);
				}
			};

			const startTop = {x: 0, y: 0, scrollLeft: 0};

			const touchStartTop = (event) => {

				startTop.x = event.touches[0].pageX;
				startTop.y = event.touches[0].pageY;

				startTop.scrollLeft = $('.right-top-panel').scrollLeft();
			};

			const touchMoveTop = (event) => {

				const offset = {x: 0, y: 0};

				offset.x = startTop.x - event.touches[0].pageX;
				offset.y = startTop.y - event.touches[0].pageY;

				if (Math.abs(offset.y) < Math.abs(offset.x)) {
					event.preventDefault();
				}

				if (offset.x) {
					const x = startTop.scrollLeft + (offset.x);
					$('.right-top-panel, .right-bottom-panel').scrollLeft(x);
				}
			};

			$('.left-bottom-panel', this.rootElement)[0].addEventListener('touchstart', touchStart, false);
			$('.left-bottom-panel', this.rootElement)[0].addEventListener('touchmove', touchMove, false);
			$('.right-top-panel', this.rootElement)[0].addEventListener('touchstart', touchStartTop, false);
			$('.right-top-panel', this.rootElement)[0].addEventListener('touchmove', touchMoveTop, false);
		}
	};

	private selectedGroupId: number = null;
	private selectedTeachersGroupId: number = null;
	private selectedSchoolsGroupId: number = null;
	private selectedGroupOfSpecialistsId: number = null;
	private selectedSpecialistGroupId: number = null;
	private selectedClassId: number = null;
	private selectedUserEntityID: number = 0;
	private timer: number = null;

	fillBlanks(data: IInitReportResponse){
		const tests = data.model.tests.flatMap(x => {
			const periodsWithBase = [...x.periods];
			periodsWithBase.unshift({name: 'B'});
			return periodsWithBase.map(y => x.testID);
		});

		data.model.rows.forEach(r => {
			const testResults: TestResult[] = [];
			for(let i = 0; i < r.rowsCount; i++){
				const testResult = r.testResults.find(x => x.id == i);
				if (testResult){
					testResults.push(testResult);
				} else{
					testResults.push({
						id: i,
						value: 'NT',
						color: '#e4e4e4',
						testID: tests[i],
						score: null,
					} as TestResult);
				}
			}
			r.testResults = testResults;
		});
		return data;
	}

	load() {
		GoogleAnalyticsTracker.trackEvent('ClassGradeReport');

		this.isEdge(window.navigator.userAgent.indexOf('Edge') > -1);
		this.isIE(window.navigator.userAgent.indexOf('Trident') > -1);
		if (this.subjectId && this.subjectType) {
			this.timer = window.setTimeout(() => {
				$(this).trigger('onLongLoading');
			}, 10000);
		}
		const deferred = $.Deferred<any>();

		this.api
			.init(this.getRequestModel(this.subjectId, this.subjectType, this.subjectLevel, this.selectedTestIds))
			.subscribe(
				(object) => deferred.resolve(this.server.getRowsBlock(object, true)),
				() => $(this).trigger('aborted'),
				() => $(this).trigger('loaded'),
			);

		return deferred.promise();
	}

	private loadReportData({subjects, ...object}: IInitReportResponse) {
		this.originalReportType(object.model.gradeReportType);
		this.originalReportID = object.model.reportLevelId;

		this.reportLoaded(object.model);

		this.data.subjects(subjects);
		let subject = subjects.find(s => s.subjectID === this.subjectId);
		if (!subject) {
			subject = subjects[0];
		}
		this.setSubject(subject);

		if (this.model.schools) {
			this.data.schools([{name: 'All', id: 0}].concat(this.model.schools));
			let s = this.data.schools().filter(s => s.id === object.model.reportLevelId)[0];
			if (!s) {
				s = this.data.schools()[0];
			}
			this.selectedSchool(s);
		} else {
			this.data.schools([]);
		}

		if (this.model.classes) {
			this.data.classes(this.model.classes);
			let c = this.model.classes.find(c => c.id === object.model.reportLevelId);
			if (!c) {
				c = this.model.classes[0];
			}
			this.selectedClass(c);
		}

		if (this.model.groups) {
			this.data.groups(this.model.groups);
			let g = this.model.groups.find(c => c.id === object.model.reportLevelId);
			if (!g) {
				g = this.model.groups[0];
			}
			this.selectedGroup(g);
		} else {
			this.data.groups([]);
		}

		if (this.model.teachersGroups) {
			this.data.teachersGroups(this.model.teachersGroups);
			let g = this.model.teachersGroups.find(c => c.id === object.model.reportLevelId);
			if (!g) {
				g = this.model.teachersGroups[0];
			}
			this.selectedTeachersGroup(g);
		} else {
			this.data.teachersGroups([]);
		}

		if (this.model.schoolsGroups) {
			this.data.schoolsGroups(this.model.schoolsGroups);
			let g = this.model.schoolsGroups.find(c => c.id === object.model.reportLevelId);
			if (!g) {
				g = this.model.schoolsGroups[0];
			}
			this.selectedSchoolsGroup(g);
		} else {
			this.data.schoolsGroups([]);
		}

		if (this.model.groupsOfSpecialists) {
			this.data.groupsOfSpecialists(this.model.groupsOfSpecialists);
			let g = this.model.groupsOfSpecialists.find(c => c.id === object.model.reportLevelId);
			if (!g) {
				g = this.model.groupsOfSpecialists[0];
			}
			this.selectedGroupOfSpecialists(g);
		} else {
			this.data.groupsOfSpecialists([]);
		}

		if (this.model.specialistGroups) {
			this.data.specialistGroups(this.model.specialistGroups);
			let sg = this.model.specialistGroups.find(c => c.id === object.model.reportLevelId);
			if (!sg) {
				sg = this.model.specialistGroups[0];
			}
			this.selectedSpecialistGroup(sg);
		} else {
			this.data.specialistGroups([]);
		}

		if (this.timer != null) {
			clearTimeout(this.timer);
		}

		$(this).trigger('onLoaded');

		this.selectedGroup.pureSubscribe((i) => {
			if (i.id === this.selectedGroupId) {
				return;
			}

			this.selectedGroupId = i.id;
			this.updateReportByReport(i.id);

			$(this).trigger('groupChanged', i.id);
		});

		this.selectedSchoolsGroup.pureSubscribe((i) => {
			if (i.id === this.selectedSchoolsGroupId) {
				return;
			}

			this.selectedSchoolsGroupId = i.id;
			this.updateReportByReport(i.id);
			this.fixScrollPosition();
		});

		this.selectedTeachersGroup.pureSubscribe((i) => {
			if (i.id === this.selectedTeachersGroupId) {
				return;
			}

			this.selectedTeachersGroupId = i.id;
			this.updateReportByReport(i.id);
			this.fixScrollPosition();
		});

		this.selectedGroupOfSpecialists.pureSubscribe((i) => {
			if (i.id === this.selectedGroupOfSpecialistsId) {
				return;
			}

			this.selectedGroupOfSpecialistsId = i.id;
			this.updateReportByReport(i.id);
			this.fixScrollPosition();
		});

		this.selectedSpecialistGroup.pureSubscribe((i) => {
			if (i.id === this.selectedSpecialistGroupId) {
				return;
			}

			this.selectedSpecialistGroupId = i.id;
			this.updateReportByReport(i.id);
			this.fixScrollPosition();

			$(this).trigger('groupChanged', i.id);
		});

		this.selectedClass.pureSubscribe((i) => {
			if (i.id === this.selectedClassId) {
				return;
			}

			this.selectedClassId = i.id;
			this.updateReportByReport(i.id);
			this.fixScrollPosition();

			$(this).trigger('classChanged', i.id);
		});

		this.selectedSchool.pureSubscribe((i) => {
			this.selectedSchoolId = i.id;
			this.selectedUserEntityID = 0;
			this.selectedUserEntity(0);
			this.updateSubjects(i.id, 0);
			$(this).trigger('schoolChanged', i.id);
		});

		this.selectedUserEntity.pureSubscribe(id => {
			if (id === this.selectedUserEntityID
				// knockout sends blank string in id oninit, if delete we'll send get-grade-report request after init.
				|| id as any === '') {
				return;
			}
			this.selectedUserEntityID = id;

			const entity = this.data.userEntities().filter(x => x.entities.filter(y => y.id === id).length > 0);
			const teacherID = entity.length ? entity[0].id : 0;
			this.updateSubjects(null, teacherID);

			$(this).trigger('teacherChanged', teacherID);

			if (this.originalReportType() === 'StudentsDistrict' || this.originalReportType() === 'StudentsSchool') {
				$(this).trigger('classChanged', id);
			}

			if (this.originalReportType() === 'DistrictSpecialistStudents' || this.originalReportType() === 'SchoolSpecialistStudents') {
				$(this).trigger('groupChanged', id);
			}
		});

		this.currentPeriod.pureSubscribe((period) => {
			if (period === 'Current') {
				this.isLastPeriod(true);
			} else {
				this.isLastPeriod(false);
			}
			this.updateResultCellsDisplay();
		});

		this.selectedSubjectId.pureSubscribe((subjectId) => {
			const subject = this.getSubject(subjectId);
			if (this.selectedSubject().subjectID !== subjectId) {
				this.selectedSubject(subject);
			}
		});

		this.selectedSubject.pureSubscribe((subject) => {
			if (subject.subjectID === this.subjectId) {
				return;
			}
			const {subjectID: subjectId, subjectType} = subject;
			this.subjectId = subjectId;
			this.subjectType = subjectType;
			this.subjectLevel = subject.level;
			this.updateDoNotAllowEditSubjectByAdmin();
			this.updateReportBySubject(subject);
			this.fixScrollPosition();

			$(this).trigger('subjectChanged', {subjectId, subjectType: SubjectType[subjectType]});
		});

		this.carryScoresForward.pureSubscribe((value) => {
			this.api.carryScoresForward(value).subscribe(() => {
				this.reload();
			});
		});

		this.displayZeroIfNotTestedOption.pureSubscribe((value) => {
			this.api.displayZeroIfNotTested(value !== 'NT').subscribe();
		});

		this.currentPeriod.pureSubscribe((value) => {
			this.api.markingPeriodAll(value !== 'Current').subscribe();
		});

		if (this.data.gradeLevels()) {
			this.selectedGradeLevel.pureSubscribe(() => {
				if (!this.reportLoading) {
					this.loadingByGradeLevel = true;
					this.reload();
				}
			});
		}
	}

	private updateResultCellsDisplay() {
		const show = !this.isLastPeriod();

		const periods = this.data.tests()[0].periods.length;
		if (periods !== 0) {
			$('.right-bottom-panel table tr').each(function () {
				const tds = $(this).find('td').filter(function (index) {
					return index % (periods + 1) !== periods;
				});

				const lastTd = $(this).find('td:eq(' + periods + ')');

				if (show) {
					lastTd.removeClass('left-border');
					tds.show();
				} else {
					tds.hide();
					lastTd.addClass('left-border');
				}
			});
		}

		$('.right-bottom-panel tr td').css('width', '');

		if (this.data.rows().length > 0) {
			const row = this.data.rows()[0];
			const results = row.testResults;
			for (let i = 0; i < results.length; i++) {
				const result = results[i];
				const width = this.cellWidth(i, result.testID) + 'px';
				$('.right-bottom-panel tr:eq(0) td:eq(' + i + ')').css('width', width);
			}
		}

		this.addHoverSupport('.data-row-right', '.left-bottom-panel');
		this.addHoverSupport('.data-row-left', '.right-bottom-panel');
	}

	addHoverSupport(hoveredTrClass: string, tableToHoverClass: string) {
		$(hoveredTrClass).hover(
			(event: any) => {
				$(tableToHoverClass + ' table tr:eq(' + $(event.currentTarget).index() + ')').addClass('hovered');
			},
			(event: any) => {
				$(tableToHoverClass + ' table tr:eq(' + $(event.currentTarget).index() + ')').removeClass('hovered');
			},
		);
	}

	private setSubject = (subject: SubjectModel) => {
		this.selectedSubjectId(subject.subjectID);
		this.selectedSubject(subject);
	};

	private getSubject = (subjectId: SubjectModel['subjectID']) => {
		return this.data.subjects().find(s => s.subjectID === subjectId);
	};

	fixHeadWidth() {
		const head = $($('.gr-table-subheader')[0]);
		const length = head.find('td').length;

		for (let i = 0; i < length - 1; i++) {
			head.find('td:eq(' + i + ')').outerWidth($('.left-bottom-panel table tr:eq(0) td:eq(' + i + ')').outerWidth());
		}
	}

	fixLeftPanelWidth() {
		$('.left-panel', this.rootElement).on('scroll', function () {
			const left = $('.left-panel').scrollLeft();
			const dif = $('.left-bottom-panel > div').width() - $('.left-bottom-panel').innerWidth();

			if (left > dif) {
				return;
			}

			const leftPx = left + 'px';

			$('.left-bottom-panel').css('left', leftPx);
			$('.left-bottom-panel table').css('left', '-' + leftPx);

			$('.right-bottom-panel').scrollTop($('.left-bottom-panel').scrollTop());
		});
	}

	fixTestNames() {
		$('.first-header').css('height', 'auto');
		$('.test-names').css('height', 'auto');
		let maxHeight = $('.test-names').height();
		if ($('.first-header').height() > maxHeight) {
			maxHeight = $('.first-header').height();
		}
		$('.first-header').height(maxHeight);
		$('.test-names').height(maxHeight);
	}

	fixScrollPosition() {
		$('.right-bottom-panel').scrollLeft(0);
		$('.right-bottom-panel').scrollTop(0);
	}

	cellHover() {
		$(this.rootElement).delegate('.result-info-cell', 'mouseenter', (e) => {
			const testResult = ko.dataFor(e.currentTarget) as TestResult;
			const test = this.data.tests().find(t => t.testID === testResult.testID);
			if (test) {
				test.showSettings(true);
			}
		}).delegate('.result-info-cell', 'mouseleave', (e) => {
			const testResult = ko.dataFor(e.currentTarget) as TestResult;
			const test = this.data.tests().find(t => t.testID === testResult.testID);
			if (test) {
				test.showSettings(false);
			}

			if (this.isEdge()) {
				this.rootElement.find('.right-top-panel').css('width', 'auto');
				setTimeout(() => {
					this.rootElement.find('.right-top-panel').css('width', '');
				}, 1);
			}
		});

		$(this.rootElement).delegate('.right-top-panel > table > tr > td:not(.cell4scroll)', 'mouseenter', (e) => {
			const test = ko.dataFor(e.currentTarget);
			if (test) {
				test.showSettings(true);
			}
		}).delegate('.right-top-panel > table > tr > td:not(.cell4scroll)', 'mouseleave', (e) => {
			const test = ko.dataFor(e.currentTarget) as TestModel;
			if (test) {
				test.showSettings(false);
			}
		});
	}

	comparer = (left: Row, right: Row) => {
		const columnIndex = this.data.sortIndex();
		let result = 0;
		if (columnIndex >= 0) {
			const leftCell = left.testResults[columnIndex].score;
			const rightCell = right.testResults[columnIndex].score;

			result = leftCell === rightCell ? 0 : (leftCell == null || leftCell < rightCell ? -1 : 1);
			if (result === 0) {
				for (let i = 0; i < left.entityInfo.length; i++) {
					const leftEntity = left.entityInfo[i].value.toLowerCase();
					const rightEntity = right.entityInfo[i].value.toLowerCase();

					result = leftEntity === rightEntity ? 0 : (leftEntity < rightEntity ? -1 : 1);
					if (result !== 0) {
						break;
					}
				}
			}
		} else {
			const entityIndex = Math.abs(columnIndex) - 1;
			result = this.getComparationResult(left.entityInfo, right.entityInfo, entityIndex);
		}

		if (this.data.sortDirection().toLowerCase() === 'desc') {
			return result * -1;
		} else {
			return result;
		}
	};

	getComparationResult = (left: EntityInfo[], right: EntityInfo[], index: number) => {
		let l = left[index].value.toLowerCase().trim();
		let r = right[index].value.toLowerCase().trim();

		if (l > r) {
			return 1;
		}
		if (l < r) {
			return -1;
		}
		return 0;
	};

	sort = (index, length = null, withUpdatingSortDirection = true) => {
		const sortIndex = this.data.sortIndex();

		if(withUpdatingSortDirection) {
			if (index === sortIndex) {
				if (this.data.sortDirection().toLowerCase() === 'asc') {
					this.data.sortDirection('DESC');
				} else {
					this.data.sortDirection('ASC');
				}
			} else {
				this.data.sortIndex(index);
				this.data.sortDirection(index < 0 ? 'ASC' : 'DESC');
			}
		}

		let len = this.data.rows().length;
		if (length !== null && !isNaN(length)) {
			len = length;
		}
		this.data.defaultRows.sort(this.comparer);
		this.data.rows(this.data.defaultRows.slice(0, len));
		this.api.reportService.setData(this.data.defaultRows);

		setTimeout(() => {
			this.fixLeftPanelWidth();
			this.fixHeadWidth();
			this.updateResultCellsDisplay();
		}, 200);
	};

	getRequestModel = (subjectId?: number, subjectType?: string, subjectLevel?: string, selectedTestIds?: number[]): RequestModel => {
		const request = new RequestModel();
		request.subjectId = subjectId || this.subjectId;
		request.subjectType = subjectType || this.subjectType;
		request.subjectLevel = subjectLevel || this.subjectLevel;
		request.selectedTestIds = selectedTestIds || this.selectedTestIds;
		request.gradeLevelID = this.selectedGradeLevel() && this.loadingByGradeLevel ? this.selectedGradeLevel().gradeLevelID : 0;
		request.hierarchy = this.hierarchy;

		const type = this.getType();
		request.levelType = type.type ?? 'None';
		request.levelId = type.id ?? 0;

		return request;
	};

	getType(): {type: string, id: number} {
		const originalReportType = this.originalReportType();
		if (originalReportType === 'Class') {
			return {type: 'Class', id: this.selectedClass().id};
		}

		if (originalReportType === 'Group') {
			return {type: 'Group', id: this.selectedGroup().id};
		}

		if (originalReportType === 'StudentsSchoolsGroup') {
			return {type: 'StudentsSchoolsGroup', id: this.selectedSchoolsGroup().id};
		}

		if (originalReportType === 'StudentsTeachersGroup') {
			return {type: 'StudentsTeachersGroup', id: this.selectedTeachersGroup().id};
		}

		if (originalReportType === 'GroupOfSpecialistsStudents') {
			return {type: 'GroupOfSpecialistsStudents', id: this.selectedGroupOfSpecialists().id};
		}

		if (originalReportType === 'SpecialistGroup') {
			return {type: 'SpecialistGroup', id: this.selectedSpecialistGroup().id};
		}
		if (originalReportType === 'DistrictSpecialistStudents') {
			if (this.selectedUserEntityID) {
				return {type: 'SpecialistGroup', id: this.selectedUserEntityID};
			}
			return {type: 'DistrictSpecialistStudents', id: this.currentUser.districtID};
		}

		if (originalReportType === 'SchoolSpecialistStudents') {
			if (this.selectedUserEntityID) {
				return {type: 'SpecialistGroup', id: this.selectedUserEntityID};
			}
			return {type: 'SchoolSpecialistStudents', id: this.currentUser.schoolID};
		}

		if (originalReportType === 'StudentsDistrict') {
			if (this.selectedUserEntityID) {
				return {type: 'Class', id: this.selectedUserEntityID};
			}

			if (this.selectedSchool() && this.selectedSchool().id === 0) {
				return {type: 'StudentsDistrict', id: this.currentUser.districtID};
			}

			if (this.selectedSchool() && this.selectedSchool().id !== 0) {
				return {type: 'StudentsSchool', id: this.selectedSchool().id};
			}
		}
		if (originalReportType === 'StudentsSchool') {
			if (this.selectedUserEntityID) {
				return {type: 'Class', id: this.selectedUserEntityID};
			}

			return {type: 'StudentsSchool', id: this.originalReportID};
		}

		if (originalReportType === 'DistrictPreassess') {
			return {type: 'DistrictPreassess', id: this.currentUser.districtID};
		}

		return {type: null, id: null};
	}

	updateSubjects(schoolID: number = null, teacherID: number = null) {
		const hierarchy = this.hierarchy;

		if (schoolID !== null) {
			hierarchy.classic.schoolID = schoolID;
		}
		if (teacherID !== null) {
			hierarchy.classic.teacherID = teacherID;
		}

		if (this.loader) {
			this.loader.mask();
		}

		return this.api.subjects(hierarchy)
			.subscribe(({subjects}) => {
				this.data.subjects(subjects);
				const {subjectID} = this.selectedSubject();
				const subject = subjects.find(s => s.subjectID === subjectID);
				if (!subject && subjects.length) {
					this.setSubject(subjects[0]);
				} else {
					this.updateReportByReport(schoolID ?? teacherID);
				}
			});
	}

	close = () => {
		clearInterval(this.intervalId);
		$(this).trigger('closed');
	};

	view = {
		downloadExcel: () => {
			const subject = this.selectedSubject();
			const type = this.getType();
			const options = {
				subjectId: subject.subjectID,
				subjectType: subject.subjectType,
				subjectLevel: subject.level,
				selectedTestIds: this.selectedTestIds,
				isLastPeriod: this.currentPeriod() !== 'All',
				sortBy: this.sort,
				gradeLevelID: this.selectedGradeLevel() ? this.selectedGradeLevel().gradeLevelID : 0,
				sourceID: this.model.reportGuid,
				levelId: type.id,
				levelType: type.type,
				fullHierarchy: this.hierarchy,
				displayZero: this.displayZeroIfNotTestedOption() == 'Zero',
			};
			const cells = this.data.defaultRows.length * (this.data.tests().flatMap(x => x.periods).length + this.data.tests().length);
			const backgroundLoading = cells > 300000; // 300 000 cells 1mb of excel file

			if (backgroundLoading) {
				this.api.startBackgroundGeneration(options).subscribe((model: BackgroundGenerationModel) => {
					const args: BackgroundDownloadManagerEvents.StartArgs = {
						reportGuid: model.reportGuid,
						fileType: 'XLS',
						displayRemainingText: true,
					};
					this.eventBus.dispatch(BackgroundDownloadManagerEvents.Start, args);
				});
			} else {
				this.loader.mask();
				this.api.downloadFile('xlsx', options).subscribe({
					error: () => {
						const reporter = new ExceptionReporter();
						reporter.report('Unable to load excel file.');
						this.loader.unmask();
					},
					complete: () => this.loader.unmask(),
				});
			}
		},
		downloadPdf: () => {
			const subject = this.selectedSubject();
			const type = this.getType();
			const options = {
				subjectId: subject.subjectID,
				subjectType: subject.subjectType,
				subjectLevel: subject.level,
				selectedTestIds: this.selectedTestIds,
				isLastPeriod: this.currentPeriod() !== 'All',
				sortBy: this.sort,
				gradeLevelID: this.selectedGradeLevel() ? this.selectedGradeLevel().gradeLevelID : 0,
				sourceID: this.model.reportGuid,
				levelId: type.id,
				levelType: type.type,
				fullHierarchy: this.hierarchy,
				displayZero: this.displayZeroIfNotTestedOption() === 'Zero',
			};

			this.loader.mask();
			this.api.downloadFile('pdf', options).subscribe({
				error: () => {
					const reporter = new ExceptionReporter();
					reporter.report('Unable to load pdf file.');
					this.loader.unmask();
				},
				complete: () => this.loader.unmask(),
			});
		},
		print: () => {
			switch (this.model.gradeReportType) {
				case 'Class':
				case 'Group':
					window.print();
					break;
				case 'StudentsSchool':
				case 'StudentsDistrict':
					const p = new PrintStudentReport($('body')[0]);
					const rows = this.data.rows();
					p.print('table', rows, this.model.gradeReportType);
					break;
				default:
			}
		},
		editGradeScaleClick: () => {
			const component = openModal(
				<Wizard
					hierarchy={this.hierarchy}
					subjectLevel={SubjectLevel[this.subjectLevel]}
					subjectID={this.subjectId}
					initialStep={WizardStep.GradeScale}
					gradeScaleID={this.data.gradeScale().id}
					onClosed={() => component.close()}
					onGradeScaleSaved={(gradeScale) => {
						this.data.gradeScale(gradeScale);

						const {
							subjectID: subjectId,
							subjectType,
							level,
						} = this.selectedSubject();

						this.api.selectGradeScale({
							gradeScaleId: gradeScale.id,
							subjectId,
							subjectType,
							level,
						}).subscribe(() => {
							const subjects = this.data.subjects();
							const {subjectID} = this.selectedSubject();
							const subject = subjects.find(x => x.subjectID == subjectID);
							subject.hasGradeScales = true;
							this.data.subjects(subjects);
							this.data.rows([]);
							this.setSubject(subject);

							subject.hierarchy = this.hierarchy;
							this.loadReport(subject.subjectID, subject.subjectType, subject.level, this.selectedTestIds);
						});
					}}
				/>,
			);
		},
		scaleSettingsClick: (test: TestModel) => {
			let wizardStep = WizardStep.None;
			switch (test.gradeRangeType) {
				case GradeRangeType.Default: {
					wizardStep = WizardStep.DefaultGradeRange;
					break;
				}
				case GradeRangeType.Custom: {
					wizardStep = WizardStep.CustomGradeRange;
					break;
				}
				case GradeRangeType.Shared: {
					wizardStep = WizardStep.SharedGradeRange;
					break;
				}
			}

			const component = openModal(
				<Wizard
					hierarchy={this.hierarchy}
					initialStep={wizardStep}
					subjectID={this.selectedSubject().subjectID}
					testID={test.testID}
					gradeScaleID={this.data.gradeScale().id}
					onClosed={() => component.close()}
					onGradeRangeSaved={() => this.reload()}
				/>,
			);
		},
		reportClosed: () => {
			this.api.closeGradeReport({Type: this.model.gradeReportType, SourceID: this.model.reportGuid});
		},
	};

	server = {
		getRowsBlock: (modelData: IInitReportResponse, isInit: boolean) => {
			this.fillBlanks(modelData);

			if (isInit) {
				this.areSettingsVisible(true);
				this.loadReportData(modelData);
			} else {
				this.reportLoaded(modelData.model, false);
			}
			if (isInit) {
				const interval = window.setInterval(() => {
					if ($('.right-bottom-panel table td').length > 0) {
						$('.grade-report').css('visibility', 'visible');
						window.clearInterval(interval);
					}
				}, 100);
			}
			setTimeout(() => {
				this.reportLoading = false;
				this.loadingByGradeLevel = false;
			}, 10);
		},
	};

	events = {
		closed: (callback) => {
			if (typeof callback === 'function') {
				$(this).on('closed', callback);
			}
		},
		subjectChanged: (callback: (e: Event, subject: { subjectId: number, subjectType: SubjectType }) => any) => {
			// @ts-ignore
			$(this).on('subjectChanged', callback);
		},
		classChanged: (callback: (e: Event, classId) => any) => {
			// @ts-ignore
			$(this).on('classChanged', callback);
		},
		groupChanged: (callback: (e: Event, specialsitGroupId) => any) => {
			// @ts-ignore
			$(this).on('groupChanged', callback);
		},
		schoolChanged: (callback: (e: Event, schoolId) => any) => {
			// @ts-ignore
			$(this).on('schoolChanged', callback);
		},
		teacherChanged: (callback: (e: Event, teacherId) => any) => {
			// @ts-ignore
			$(this).on('teacherChanged', callback);
		},
		loaded: (callback) => {
			$(this).on('loaded', callback);
		},
		aborted: (callback) => {
			$(this).on('aborted', callback);
		},
		emptyReport: (callback: (e: Event, emptyReport) => any) => {
			// @ts-ignore
			$(this).on('emptyReport', callback);
		},
		teacherClassChanged: (callback: (e: Event, info: { classID: number, teacherID: number }) => any) => {
			$(this).on('teacherClassChanged', callback);
		},
	};
}
