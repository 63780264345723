import {useEffect, useState} from 'react';
import {CloseIcon, Modal, Title, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {FindMoreTests} from './components/find-more-tests';
import {FindMoreService} from './find-more-service';
import {useService} from '@esgi/core/service';
import './find-more.less';
import {Loader, ServiceLoader} from '@esgillc/ui-kit/loader';
import {finalize} from 'rxjs/operators';

interface Props {
	assignmentName: string,
	onClose: () => void;
}

export const FindMoreModal = (props: Props): JSX.Element => {
	const modalManagerRef = useModal();
	const service = useService(FindMoreService);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		service.init().pipe(finalize(() => setLoading(false))).subscribe();
	}, []);

	return (
		<Modal modalManagerRef={modalManagerRef}>
			<Loader show={loading} fullscreen/>
			<Modal.Header>
				<Title>Find More Self-Assess Tests</Title>
				<CloseIcon onClick={() => modalManagerRef.current.close(props.onClose)}/>
			</Modal.Header>
			<Modal.Body>
				<FindMoreTests service={service} assignmentName={props.assignmentName}/>
				<ServiceLoader trackingService={service} fullscreen/>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Contained
					onClick={() => modalManagerRef.current.close(props.onClose)}>Done</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	);
};
