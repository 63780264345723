import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const RootBox = styled(GridBox, {
	paddingTop: '$4',
	paddingBottom: '$4',
	paddingLeft: 20,
	paddingRight: 20,
	gap: '$4',
	gridTemplateRows: 'auto 1fr',
	borderLeftColor: '$mild',
	borderLeftStyle: 'solid',
	borderLeftWidth: 1,
	backgroundColor: '$background',
	height: '100vh',
	width: '100%',
	overflow: 'hidden',
});
