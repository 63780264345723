import {AggregatePattern, Aggregation} from '@esgillc/events';
import {StudentChangedEvent, StudentCreatedEvent, StudentRemovedEvent} from 'modules/forms/students-form/events';
import {ClassChangedEvent, ClassCreatedEvent, ClassRemovedEvent} from 'modules/forms/class-form/events';
import {
	GroupOfSpecialistsChangedEvent,
	GroupOfSpecialistsCreatedEvent,
	GroupOfSpecialistsRemovedEvent,
  SchoolsGroupChangedEvent,
  SchoolsGroupCreatedEvent,
  SchoolsGroupRemovedEvent,
  TeachersGroupChangedEvent,
  TeachersGroupCreatedEvent,
  TeachersGroupRemovedEvent,
} from 'modules/forms/admin-group-forms/events';
import {
	SpecialistGroupChangedEvent,
	SpecialistGroupCreatedEvent,
	SpecialistGroupRemoveEvent,
} from 'shared/modules/forms/specialist-group-form/events';
import {StudentManagerEvents} from '../student-manager/events';
import {GroupChangedEvent, GroupCreatedEvent, GroupRemovedEvent} from 'modules/forms/group-form/events';

export const hierarchyAggregationPattern = {
	ClassCreatedEvent,
	ClassChangedEvent,
	ClassRemovedEvent,
	StudentChangedEvent,
	StudentCreatedEvent,
	StudentRemovedEvent,
	GroupChangedEvent,
	GroupCreatedEvent,
	GroupRemovedEvent,
  SchoolsGroupCreatedEvent,
  SchoolsGroupChangedEvent,
  SchoolsGroupRemovedEvent,
  TeachersGroupCreatedEvent,
  TeachersGroupChangedEvent,
  TeachersGroupRemovedEvent,
  GroupOfSpecialistsCreatedEvent,
  GroupOfSpecialistsChangedEvent,
  GroupOfSpecialistsRemovedEvent,
	SpecialistGroupCreatedEvent,
	SpecialistGroupChangedEvent,
	SpecialistGroupRemoveEvent,
	...StudentManagerEvents,
} as AggregatePattern;

export function hasHierarchyRelatedEvents(aggregation: Aggregation): boolean {
	return aggregation.has(...Object.values(hierarchyAggregationPattern));
}
