/* eslint-disable @typescript-eslint/naming-convention */
import {loadZopim} from '@esgi/features/support';
import React, {useEffect} from 'react';

export function withZendesk<Props>(Component: React.ComponentType<Props>) {
	return (props: Props) => {
		useEffect(() => {
			loadZopim();
		}, []);

		return <Component {...props} />;
	};
}
