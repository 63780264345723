import {BaseService} from '@esgi/core/service';
import {Subject} from 'api/entities/subject';
import {SsoTracker} from '@esgi/core/tracker';
import {BehaviorSubject} from 'rxjs';
import {SubjectItem} from 'pages/test-explorer/services/subjects-service';

export class AddTestToSubjectService extends BaseService {
	public subjects = new BehaviorSubject<SubjectItem[]>([]);
	public selectedSubjectsIds = new BehaviorSubject<number[]>([]);
	private testId: number;

	public init({subjects, testId}) {
		this.subjects.next(subjects);
		this.testId = testId;
	}

	public subjectCreated = ({subjectId, name, type}: any) => {
		const newSubject = new SubjectItem(subjectId, name, [], type, false);
		this.selectedSubjectsIds.next([subjectId]);
		const newSubjects = [...this.subjects.value, newSubject];
		this.subjects.next(newSubjects);
	};

	public save = () => {
		const selectedSubject = this.subjects.value.find(item => item.id === this.selectedSubjectsIds.value[0]);
		return Subject.addTest(selectedSubject.id, selectedSubject.subjectType, this.testId).then(() => {
			SsoTracker.trackEvent({
				trackingEvent: 'AddTestTE',
				data: {subjectId: selectedSubject.id, subjectType: selectedSubject.subjectType, testId: this.testId},
			});
		});
	};

	public changeSelectedSubject = (selected: number[]) => {
		this.selectedSubjectsIds.next(selected);
	};
}
