import {useCallback, useEffect, useState} from 'react';
import {ListHeaderCellID} from '../../types';
import {sortableCells} from './constants';
import {Button, CheckAll} from '@esgi/ui';
import {ListHeaderRow} from './index.styled';
import {TableHeaderCellSortable, SortDirection} from '@esgi/main/kits/assignments';

type Props = {
	studentsCount: number;
	disableSortingCells: ListHeaderCellID[];
	sortStudentsData: (args: { sortableCellId: ListHeaderCellID; sortDirection: SortDirection }) => void;
	toggleAllStudents: VoidFunction;
};

export function ListHeader({studentsCount, disableSortingCells, sortStudentsData, toggleAllStudents}: Props) {
	const [sortDirection, setSortDirection] = useState(SortDirection.Asc);

	const [activeCellID, setActiveCellID] = useState<ListHeaderCellID | null>(null);

	useEffect(() => {
		const initialSortableKey = sortableCells.find(({cellKey}) => !disableSortingCells.includes(cellKey))?.cellKey;

		if (!initialSortableKey) {
			setActiveCellID(null);
			return;
		}

		sortStudentsData({sortableCellId: initialSortableKey, sortDirection});

		setActiveCellID(initialSortableKey);
	}, [disableSortingCells]);

	const handleCellClick = useCallback(
		({cellKey, nextSortDirection}: { cellKey: ListHeaderCellID; nextSortDirection: SortDirection }) => {
			setActiveCellID(cellKey);
			setSortDirection(nextSortDirection);

			sortStudentsData({sortDirection: nextSortDirection, sortableCellId: cellKey});
		},
		[sortStudentsData],
	);

	return (
		<ListHeaderRow dataCy='header-row'>
			<Button.Icon withBackgroundHover onClick={toggleAllStudents} dataCy='check-all-button'>
				<CheckAll width={24} height={24}/>
			</Button.Icon>

			{sortableCells.map(({cellKey, cellName}, index) => (
				<TableHeaderCellSortable
					cellKey={cellKey}
					cellName={cellName}
					isActive={activeCellID === cellKey}
					onCellClick={handleCellClick}
					sortDirection={sortDirection}
					disableSorting={studentsCount <= 1 || disableSortingCells.includes(cellKey)}
					key={index}
				/>
			))}
		</ListHeaderRow>
	);
}
