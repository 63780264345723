import {ArrowIcon} from '@esgillc/ui-kit/icons';
import {Buttons} from '@esgillc/ui-kit/button';
import {District} from '../../../../components/kit/district';
import styles from './styles.module.less';

interface Props {
	onCreateClick: () => void;
	goToPreviousPage?: () => void;
}

export const Header = ({
	onCreateClick,
	goToPreviousPage,
}: Props) => (
	<div className={styles.header}>
		<Buttons.Text
			className={styles.backBtn}
			onClick={goToPreviousPage}
		>
			<ArrowIcon direction='left'/>
			Back
		</Buttons.Text>
		<div className={styles.title}>
			Student Credentials
			<District/>
			<Buttons.Contained onClick={onCreateClick}>
				Create Assignment
			</Buttons.Contained>
		</div>
	</div>
);
