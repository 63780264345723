import {Text} from '@esgi/ui/typography';
import {ArrowButton, Container, Dot, PageInfoBox, PageInfoContainer} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {BaseComponentProps, Enter} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';

type Props = BaseComponentProps &
	ComponentPropsWithoutRef<'div'> & {
		currentPageIndex: number;
		totalPages: number;
		nextPage: VoidFunction;
		previousPage: VoidFunction;
	};

export const Pagination = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = '', css = {}, currentPageIndex, nextPage, previousPage, totalPages, ...props},
		forwardedRef,
	) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
			<PageInfoContainer>
				<Text size='xSmall' font='mono' color='mediumContrast'>
					Page
				</Text>

				<PageInfoBox>
					<Text size='small' font='mono' bold color='base'>
						{currentPageIndex + 1}
					</Text>
					<Dot />
					<Text size='small' font='mono' bold color='mediumContrast'>
						{totalPages}
					</Text>
				</PageInfoBox>
			</PageInfoContainer>

			<GridBox flow='column' gap='3'>
				<ArrowButton color='secondary' onClick={previousPage} disabled={currentPageIndex === 0} buttonType='previous'>
					<Enter />
				</ArrowButton>

				<ArrowButton color='secondary' onClick={nextPage} disabled={currentPageIndex === totalPages - 1}>
					<Enter />
				</ArrowButton>
			</GridBox>
		</Container>
	),
);
