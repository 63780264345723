import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Container = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gap: '12px',
});

export const TestButtonLabelTag = styled(Text, {
	display: 'flex',
	color: '$neutral40',
	borderRadius: '6px',
	padding: '8px',
	width: '120px',
	height: '40px',
	border: '1px solid $mild',
	alignItems: 'center',
	justifyContent: 'center',
});
