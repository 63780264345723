export function JoinToDistrictFormTemplate() {
	return <div className='join-to-district-form clearfix' data-bind='afterRender: true'>
		<label data-cy='join-to-district-comment'>This activation code will join your account to the district <ko data-bind='if: Boolean(schoolID)'>and school</ko> selected
			below.</label>

		<ko data-bind='with: district'>
			<div className='form-group' data-cy='district-section'>
				<label className='control-label' htmlFor='schools'>District: </label>
				<input className='form-control' data-bind='value: value' disabled={true} readOnly={true}/>
			</div>
		</ko>

		<ko data-bind='if: Boolean(schoolID)'>
			<ko data-bind='with: school'>
				<div className='form-group' data-cy='school-section'>
					<label className='control-label' htmlFor='schools'>School: </label>
					<div>
						<select className='form-control' data-bind="options: source, optionsText: 'name', value: value, valueAllowUnset: true"/>
					</div>
				</div>
			</ko>
		</ko>

		<ko data-bind='with: track'>
			<div className='form-group' data-cy='track-section'>
				<label className='control-label' htmlFor='tracks'>Track: </label>
				<div>
					<select className='form-control' data-bind="options: source, optionsText: 'name', value: value"/>
				</div>
			</div>
		</ko>

		<ko data-bind="if: joinType == 'WithoutDistrict' ">
			<div className='form-group row' data-cy='are-you-still-in-district-form'>
				<label className='col-sm-9'><span>Are you still in </span> <ko data-bind='text: currentDistrictName'/>?
				</label>

				<ko data-bind='with: sameDistrict'>
					<div className='col-sm-3' style={{textAlign: 'center'}}>
						<label className='lbl-chk' style={{marginRight: '10px'}} data-cy='in-district'>
							<input type='radio' name='same-district' value='true' className='ace'
							       data-bind='checked: value'/>
							<span className='lbl'>Yes</span>
						</label>
						<label className='lbl-chk' data-cy='not-in-district'>
							<input type='radio' name='same-district' value='false' className='ace'
							       data-bind='checked: value'/>
							<span className='lbl'>No</span>
						</label>
					</div>
				</ko>
			</div>
		</ko>
		<ko data-bind="if: joinType == 'WithoutDistrict' && sameDistrict.value() === 'false'">
			<div className='form-group row' data-cy='move-students-section'>
				<label className='col-sm-9' style={{textAlign: 'justify'}}>
					<span>Should the students in your current class list be moved with you to <ko data-bind='text: district.value()'></ko>? They are listed below.</span>
				</label>
				<div className='col-sm-3' data-bind='with: moveStudents' style={{textAlign: 'center'}}>
					<label className='lbl-chk' style={{marginRight: '10px'}} data-cy='move-students'>
						<input type='radio' className='ace' name='move-students' value='true'
						       data-bind='checked: value'/>
						<span className='lbl'>Yes</span>
					</label>
					<label className='lbl-chk' data-cy='not-move-students'>
						<input type='radio' className='ace' name='move-students' value='false'
						       data-bind='checked: value'/>
						<span className='lbl'>No</span>
					</label>
				</div>
			</div>
		</ko>

		<ko data-bind="if: joinType == 'WithoutDistrict' && sameDistrict.value() === 'false' && moveStudents.value() ==='true'">
			<div className='form-group row' data-cy='move-prev-students-section'>
				<label className='col-sm-9' style={{textAlign: 'justify'}}>
					<span>Should your past students from previous school years be moved with you to <ko data-bind='text: district.value()'></ko>?</span>
				</label>
				<div className='col-sm-3' data-bind='with: movePrevStudents' style={{textAlign: 'center'}}>
					<label className='lbl-chk' style={{marginRight: '10px'}} data-cy='move-prev-students'>
						<input type='radio' className='ace' name='move-prev-students' value='true'
						       data-bind='checked: value'/>
						<span className='lbl'>Yes</span>
					</label>
					<label className='lbl-chk' data-cy='not-move-prev-students'>
						<input type='radio' className='ace' name='move-prev-students' value='false'
						       data-bind='checked: value'/>
						<span className='lbl'>No</span>
					</label>
				</div>
			</div>
		</ko>


		<ko data-bind="if: classes.length > 0 && sameDistrict.value() === 'false'">
			<div className='row'>
				<div className='col-sm-12' style={{textAlign: 'left'}} data-cy='students-list-section'>
					<label data-cy='students-list-label'>Students List:</label><br/>
					<ko data-bind=' foreach: classes '>
						<label data-bind='text: name' data-cy='students-list-class-name'></label>
						<ul data-bind='foreach: students' data-cy='students-list-class-section'>
							<li data-cy='students-list-item'><label data-bind='text:$data'></label></li>
						</ul>
					</ko>
				</div>
			</div>
		</ko>

	</div>;
}
