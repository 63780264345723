import React from 'react';
import './component.less';
import '../../../component.less';
import {TextInput} from '@esgi/deprecated/elements';

export class Props {
	value: string;
	editable: boolean;
	disabled: boolean;
	onChange: (string: string) => void;
	onKeyUp: (key: React.KeyboardEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	focus: boolean;
}

export default class EntryValue extends React.PureComponent<Props> {
	onChange(value: string) {
		if (value.length > 3) {
			return;
		}

		const regex = new RegExp('^(\\s*|\\d+)$');
		const isNumber = regex.test(value);
		
		this.props.onChange(isNumber ? value : this.props.value);
	}
	
	render() {
		if (this.props.editable) {
			return <TextInput
				value={this.props.value}
				onEdit={(value => this.onChange(value))}
				disabled={this.props.disabled}
				onKeyUp={(key) => this.props.onKeyUp(key)}
				focus={this.props.focus}
				onBlur={() => this.props.onBlur()} />;
		} else {
			return <span>{this.props.value}</span>;
		}
	}
}