import {Input} from '@esgi/ui/controls';
import {SectionBlock} from '../section-block';
import {AssignmentNameInputBox} from './index.styled';
import {ChangeEvent, Dispatch, useCallback, useEffect, useState} from 'react';
import {getInputIndicatorByAsyncStatus} from './get-input-indicator-by-async-status';
import {Requiered} from '@esgi/ui/icons';
import {Observable} from 'rxjs';
import {isAsyncIdle, isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {debounce} from 'underscore';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	skeleton: boolean;
	assignmentName: string;
	initialAssignmentName?: string | undefined;
	onAssignmentNameChange: Dispatch<string>;
	onValidateAssignmentName: (value: string) => Observable<string | null>;
	onValidValueChanged: Dispatch<boolean>;
	assignmentDescription: string;
	onAssignmentDescriptionChange: Dispatch<string>;
	onValidateAssignmentDescription: (value: string) => Observable<string | null>;
	initialAssignmentDescription?: string | undefined;
};

export function AssignmentSettings({
	initialAssignmentName,
	assignmentName,
	onAssignmentNameChange,
	skeleton,
	onValidateAssignmentName,
	onValidValueChanged,
	assignmentDescription,
	onAssignmentDescriptionChange,
	onValidateAssignmentDescription,
	initialAssignmentDescription,
}: Props) {
	const [isAssignmentNameTouched, setIsAssignmentNameTouched] = useState(false);

	const [assignmentNameError, setAssignmentNameError] = useState<string | null>(null);
	const [assignmentDescriptionError, setAssignmentDescriptionError] = useState<string | null>(null);
	const [validateAssignmentNameData, validateAssignmentName] = useCancelableRequest(onValidateAssignmentName);
	const [validateAssignmentDescriptionData, validateAssignmentDescription] = useCancelableRequest(onValidateAssignmentDescription);

	useEffect(() => {
		const isAssignmentNameDataPending = isAsyncPending(validateAssignmentNameData);
		const isAssignmentDescriptionDataPending = isAsyncPending(validateAssignmentDescriptionData);

		const assignmentNameError = isAsyncSucceed(validateAssignmentNameData) ? validateAssignmentNameData.data : null;
		const assignmentDescriptionError = isAsyncSucceed(validateAssignmentDescriptionData) ? validateAssignmentDescriptionData.data : null;

		onValidValueChanged(isAssignmentNameTouched && !assignmentNameError && !isAssignmentNameDataPending && !isAssignmentDescriptionDataPending);

		setAssignmentNameError(assignmentNameError);
		setAssignmentDescriptionError(assignmentDescriptionError);
	}, [validateAssignmentNameData, isAssignmentNameTouched, validateAssignmentDescriptionData]);

	useEffect(() => {
		if (
			!isUndefined(initialAssignmentName) &&
			initialAssignmentName === assignmentName &&
			isAsyncIdle(validateAssignmentNameData)
		) {
			validateAssignmentName(assignmentName);
			setIsAssignmentNameTouched(true);
		}
	}, [assignmentName, initialAssignmentName, validateAssignmentNameData]);

	useEffect(() => {
		if (!isUndefined(initialAssignmentDescription) &&
			initialAssignmentDescription === assignmentDescription &&
			isAsyncIdle(validateAssignmentDescriptionData)
		) {
			validateAssignmentDescription(assignmentDescription);
		}
	}, [assignmentDescription, validateAssignmentDescriptionData, initialAssignmentDescription]);

	const handleValidateAssignmentName = useCallback(
		debounce((...args: Parameters<typeof validateAssignmentName>) => validateAssignmentName(...args), 300),
		[],
	);
	const handleValidateAssignmentDescription = useCallback(
		debounce((...args: Parameters<typeof validateAssignmentDescription>) => validateAssignmentDescription(...args), 300),
		[],
	);
	const updateAssignmentName = useCallback(
		(value: string) => {
			handleValidateAssignmentName(value);
			onAssignmentNameChange(value);
		},
		[handleValidateAssignmentName, onAssignmentNameChange],
	);
	const updateAssignmentDescription = useCallback(
		(value: string) => {
			handleValidateAssignmentDescription(value);
			onAssignmentDescriptionChange(value);
		},
		[handleValidateAssignmentDescription, onAssignmentDescriptionChange],
	);
	const handleAssignmentNameChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;

			updateAssignmentName(value);
			setIsAssignmentNameTouched(true);
		},
		[updateAssignmentName],
	);

	const handleAssignmentDescriptionChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			updateAssignmentDescription(value);
		},
		[updateAssignmentDescription],
	);

	const onClearAssignmentName = useCallback(() => {
		updateAssignmentName('');
	}, [updateAssignmentName]);

	const onClearAssignmentDescription = useCallback(() => {
		updateAssignmentDescription('');
	}, [updateAssignmentDescription]);

	return (
		<SectionBlock title='Settings' dataCy='settings-section'>
			<GridBox gap='4'>
				<AssignmentNameInputBox>
					<Input.CleanableIndicator
						value={assignmentName}
						onChange={handleAssignmentNameChange}
						placeholder='Assignment Name'
						status={getInputIndicatorByAsyncStatus({
							asyncStatus: validateAssignmentNameData.asyncStatus,
							hasError: Boolean(assignmentNameError),
						})}
						error={assignmentNameError ?? undefined}
						onClearClick={onClearAssignmentName}
						disabled={skeleton}
						skeleton={skeleton}
						dataCy='assignment-name-input'
					/>
					{!isAssignmentNameTouched && !skeleton && <Requiered />}
				</AssignmentNameInputBox>
				<AssignmentNameInputBox>
					<Input.CleanableIndicator
						value={assignmentDescription}
						onChange={handleAssignmentDescriptionChange}
						placeholder='Assignment Description'
						status={getInputIndicatorByAsyncStatus({
							asyncStatus: validateAssignmentDescriptionData.asyncStatus,
							hasError: Boolean(assignmentDescriptionError),
						})}
						error={assignmentDescriptionError ?? undefined}
						onClearClick={onClearAssignmentDescription}
						disabled={skeleton}
						skeleton={skeleton}
						dataCy='assignment-description-input'
					/>
				</AssignmentNameInputBox>
			</GridBox>
		</SectionBlock>
	);
}
