import {useSearchParams} from 'react-router-dom';

export function useDrawerParameters<T extends Required<Record<string, string | number | undefined | null>>>(
	model: (keyof T)[],
) {
	const [searchParams] = useSearchParams();

	return model.reduce((params, key) => {
		params[key] = searchParams.get(String(key));

		return params;
	}, {} as Record<keyof T, string | null>);
}
