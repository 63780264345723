import {SkeletonShape} from '@esgi/main/kits/common';
import {ActionButtonsDivider, CardHeader} from '../assignment-card-header/index.styled';
import {GridBox} from '@esgi/ui/layout';
import {CardBody} from '../assignment-card-body/index.styled';
import {CardRoot, InfoWithTranscript} from './index.styled';
import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & BaseComponentProps;

export const AssignmentCardSkeleton = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'assignments-assignment-card-skeleton', css = {}, ...props}, forwardedRef) => (
		<CardRoot dataCy={dataCy} css={css} {...props} ref={forwardedRef}>
			<CardHeader>
				<GridBox display='inlineGrid' flow='column' align='center' gap='3'>
					<SkeletonShape width={140} />
					<SkeletonShape width={80} />
				</GridBox>
				<GridBox display='inlineGrid' flow='column' align='center' gap='3'>
					<SkeletonShape />
					<ActionButtonsDivider />
					<SkeletonShape />
				</GridBox>
			</CardHeader>
			<CardBody>
				<GridBox gap='3' flow='column' align='center' display='inlineGrid'>
					<SkeletonShape width={48} height={48} shape='round' />
					<SkeletonShape width={60} height={12} />
				</GridBox>

				<GridBox display='inlineGrid' flow='column' align='center' gap='8'>
					{new Array(3).fill(null).map((_, index) => (
						<InfoWithTranscript key={index}>
							<SkeletonShape width={64} height={16} />
							<SkeletonShape width={40} height={12} />
						</InfoWithTranscript>
					))}
				</GridBox>
			</CardBody>
		</CardRoot>
	),
);
