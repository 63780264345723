import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	width: '100%',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'start',
	gap: '$3',
	minHeight: 40,
	marginBottom: 8,
});
