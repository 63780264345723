import * as React from 'react';
import type {SVGProps} from 'react';

export function Access(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			data-cy='access-icon'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M11 13a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3H11Zm0 2h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M13 11a3 3 0 1 1 6 0v2h2v-2a5 5 0 0 0-10 0v2h2v-2Zm6 8.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-2 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
