import {userStorage, UserType, userTypeTransform} from '@esgi/core/authentication';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {
	StudentChangedEvent,
	StudentCreatedEvent,
	StudentRemovedEvent,
} from 'modules/forms/students-form/events';
import {BehaviorSubject, concatMap, groupBy, Observable, Subject, of} from 'rxjs';
import {debounceTime, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {deepCopy} from 'shared/utils';
import {TeacherFormEvents} from 'shared/modules/forms/teacher-form/events';
import {ClassChangedEvent, ClassCreatedEvent, ClassRemovedEvent} from 'modules/forms/class-form/events';
import {
	GroupOfSpecialistsChangedEvent,
	GroupOfSpecialistsCreatedEvent,
	GroupOfSpecialistsRemovedEvent,
	SchoolsGroupChangedEvent,
	SchoolsGroupCreatedEvent,
	SchoolsGroupRemovedEvent,
	TeachersGroupChangedEvent,
	TeachersGroupCreatedEvent,
	TeachersGroupRemovedEvent,
} from 'modules/forms/admin-group-forms/events';
import {
	SpecialistGroupChangedEvent,
	SpecialistGroupCreatedEvent,
	SpecialistGroupRemoveEvent,
} from 'shared/modules/forms/specialist-group-form/events';

import {StudentRosterToolEvents} from 'shared/modules/student-roster-upload-tool/events';
import {TestHistorySelectedChangedArgs, TestHistorySelectedChangedEvent} from '../../reports/test-history/events';
import {
	IBoxInfo,
	IClassBox,
	IDistrictSpecialist,
	IGroupBox,
	IGroupModel,
	GroupModelForAdmin,
	GroupOfSpecialistsBox,
	IInitResponse,
	IPreAssessModel,
	ISchAdminBox,
	ISchoolBox,
	SchoolsGroupBox,
	ISchoolSpecialist,
	ISpecialistGroupBox,
	IStudentBox,
	ITeacherBox,
	TeachersGroupBox,
} from '../core/api';
import {withClassID, withGroupID as classicApplyGroupID, withStudentID} from '../core/builders/classic';
import {withGroupID} from '../core/builders/specialist';
import {withGroupID as preAssessApplyGroupID} from '../core/builders/pre-assess';
import {HierarchyEvents, HierarchyLoadedEvent, HierarchyStudentAdd} from '../core/events';
import {BoxType, HierarchyInstance, HierarchyMode} from '../core/models';
import {HierarchyChangedItselfEvent, HierarchyInitializedEvent} from '../events';
import {
	ClassicHierarchySnapshot as ClassicHierarchyModel,
	HierarchySnapshot,
	SpecialistHierarchySnapshot as SpecialistHierarchyModel,
	PreAssessHierarchySnapshot as PreAssessHierarchyModel,
} from '../models';
import {StorageService} from '../storage-service/storage-service';
import {applyClassChanged, applyClassCreated, applyClassRemoved} from './class-handlers';
import {applyGroupChanged, applyGroupCreated, applyGroupRemoved} from './group-handlers';
import {
	applySpecialistGroupChanged,
	applySpecialistGroupRemoved,
	applySpecialistGroupsCreated,
} from './specialist-group-handlers';
import {applyStudentChanged, applyStudentCreated, applyStudentRemoved, applyStudentUploaded} from './student-handlers';
import {applyTeacherChanged, applyTeacherCreated, applyTeacherRemoved} from './teacher-handlers';
import {buildHierarchyInstance} from './utils';
import {
	applySchoolsGroupChanged,
	applySchoolsGroupCreated,
	applySchoolsGroupRemoved,
} from 'modules/hierarchy/services/schools-group-handlers';
import {
	applyTeachersGroupChanged,
	applyTeachersGroupCreated,
	applyTeachersGroupRemoved,
} from 'modules/hierarchy/services/teachers-group-handlers';
import {
	applyGroupOfSpecialistsChanged,
	applyGroupOfSpecialistsCreated,
	applyGroupOfSpecialistsRemoved,
} from 'modules/hierarchy/services/group-of-specialists-handlers';
import {ViewType} from 'modules/forms/admin-group-forms/models/types';
import {UserInfoChangedEvent} from '@esgi/main/features/user-profile';
import {UserSettingsChangedEvent} from 'shared/modules/user-settings/events';
import {GroupChangedEvent, GroupCreatedEvent, GroupRemovedEvent} from 'modules/forms/group-form/events';
import {TestSessionStatusUserMovedEvent} from 'modules/assessments';

export type HierarchyState = {
	mode: HierarchyMode;
	classes: IClassBox;
	groups: IGroupBox;
  schoolsGroups: SchoolsGroupBox;
  teachersGroups: TeachersGroupBox;
  groupsOfSpecialists: GroupOfSpecialistsBox;
	students: IStudentBox;
	teachers: ITeacherBox;
	specialistGroups: ISpecialistGroupBox;
	classic: ClassicHierarchyModel;
	specialist: SpecialistHierarchyModel;
  preAssess: PreAssessHierarchyModel;
	schoolSpecialists: ISchoolSpecialist[];
	districtSpecialists: IDistrictSpecialist[];
  preAssesses: IPreAssessModel[];
	boxes: IBoxInfo[];
	districtName: string;
	schools: ISchoolBox;
	schAdmins: ISchAdminBox;
};

export class HierarchyDataService extends BaseService {
	public readonly hierarchyState$: BehaviorSubject<HierarchyState> = new BehaviorSubject<HierarchyState>({
		mode: HierarchyMode.Classic,
		boxes: [],
		schoolSpecialists: [],
		districtSpecialists: [],
		preAssesses: [],
	} as HierarchyState);

	public hierarchyChanged$: Subject<HierarchyInstance> = new Subject<HierarchyInstance>();

	private readonly eventBus = new EventBusManager();
	private readonly controller = 'modules/hierarchy';
	private readonly storageService: StorageService = new StorageService();
	private openCloseRequests = new Subject<IBoxInfo>();
	private globalSchoolYearID: number = userStorage.get().globalSchoolYearID;
	private cachedHierarchy: IInitResponse = null;

	private snapshot: HierarchySnapshot;

	constructor() {
		super();

		this.eventBus.subscribe(ClassChangedEvent, (args) => this.updateHierarchy(applyClassChanged(args, this.hierarchy)));
		this.eventBus.subscribe(ClassCreatedEvent, (args) => this.updateHierarchy(applyClassCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(ClassRemovedEvent, (args) => this.updateHierarchy(applyClassRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(GroupChangedEvent, (args) => this.updateHierarchy(applyGroupChanged(args, this.hierarchy)));
		this.eventBus.subscribe(GroupCreatedEvent, (args) => this.updateHierarchy(applyGroupCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(GroupRemovedEvent, (args) => this.updateHierarchy(applyGroupRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(SchoolsGroupChangedEvent, (args) => this.updateHierarchy(applySchoolsGroupChanged(args, this.hierarchy)));
		this.eventBus.subscribe(SchoolsGroupCreatedEvent, (args) => this.updateHierarchy(applySchoolsGroupCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(SchoolsGroupRemovedEvent, (args) => this.updateHierarchy(applySchoolsGroupRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(TeachersGroupChangedEvent, (args) => this.updateHierarchy(applyTeachersGroupChanged(args, this.hierarchy)));
		this.eventBus.subscribe(TeachersGroupCreatedEvent, (args) => this.updateHierarchy(applyTeachersGroupCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(TeachersGroupRemovedEvent, (args) => this.updateHierarchy(applyTeachersGroupRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(GroupOfSpecialistsChangedEvent, (args) => this.updateHierarchy(applyGroupOfSpecialistsChanged(args, this.hierarchy)));
		this.eventBus.subscribe(GroupOfSpecialistsCreatedEvent, (args) => this.updateHierarchy(applyGroupOfSpecialistsCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(GroupOfSpecialistsRemovedEvent, (args) => this.updateHierarchy(applyGroupOfSpecialistsRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(StudentChangedEvent, (args) => this.updateHierarchy(applyStudentChanged(args, this.hierarchy)));
		this.eventBus.subscribe(StudentCreatedEvent, (args) => this.updateHierarchy(applyStudentCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(StudentRemovedEvent, (args) => this.updateHierarchy(applyStudentRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(SpecialistGroupChangedEvent, (args) => this.updateHierarchy(applySpecialistGroupChanged(args, this.hierarchy)));
		this.eventBus.subscribe(SpecialistGroupCreatedEvent, (args) => this.updateHierarchy(applySpecialistGroupsCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(SpecialistGroupRemoveEvent, (args) => this.updateHierarchy(applySpecialistGroupRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(TeacherFormEvents.Changed, (args) => this.updateHierarchy(applyTeacherChanged(args, this.hierarchy)));
		this.eventBus.subscribe(TeacherFormEvents.Created, (args) => this.updateHierarchy(applyTeacherCreated(args, this.hierarchy), true));
		this.eventBus.subscribe(TeacherFormEvents.Removed, (args) => this.updateHierarchy(applyTeacherRemoved(args, this.hierarchy), true));

		this.eventBus.subscribe(StudentRosterToolEvents.AddStudentClicked, this.addStudentClicked);
		this.eventBus.subscribe(StudentRosterToolEvents.Uploaded, (args) => this.updateHierarchy(applyStudentUploaded(args, this.hierarchy)));

		this.eventBus.subscribe(UserInfoChangedEvent, this.userProfilesChanged);
		this.eventBus.subscribe(UserSettingsChangedEvent, this.userSettingsChanged);
		this.eventBus.subscribe(TestHistorySelectedChangedEvent, this.testHistory);

		this.eventBus.subscribe(TestSessionStatusUserMovedEvent, () => {
			const {classic, specialist, preAssess, mode} = this.hierarchy;

			if (mode === HierarchyMode.Classic) {
				classic.studentID = 0;
			}

			if (mode === HierarchyMode.Specialist) {
				specialist.studentID = 0;
			}

			if (mode === HierarchyMode.PreAssess) {
				preAssess.studentID = 0;
			}

			this.updateHierarchy({classic, specialist, preAssess}, true);
			this.init(false, false, this.snapshot);
		});

		this.openCloseRequests
			.pipe(
				takeUntil(this.destroy$),
				groupBy(boxInfo => boxInfo.boxType),
				mergeMap(boxGroup$ => boxGroup$.pipe(
					debounceTime(500),
					concatMap(boxInfo => this.httpClient.ESGIApi.post(this.controller, 'open-close', boxInfo)))),
			)
			.subscribe();
	}

	public get hierarchyInstance(): HierarchyInstance {
		return buildHierarchyInstance(this.hierarchy);
	}

	public get hierarchySnapshot(): HierarchySnapshot {
		return deepCopy(this.snapshot);
	}

	protected get hierarchy(): HierarchyState {
		return deepCopy(this.hierarchyState$.value);
	}

	public updateGroups = (items: IGroupModel[]) => {
		const {groups} = this.hierarchy;
		groups.items = items;
		this.updateHierarchy({groups});
	};

	public updateAdminGroups(items: GroupModelForAdmin[], viewType: ViewType): void {
		if(viewType === ViewType.TeachersGroup) {
			const {teachersGroups} = this.hierarchy;
			teachersGroups.items = items;
			this.updateHierarchy({teachersGroups});
		}
		if(viewType === ViewType.GroupOfSpecialists) {
			const {groupsOfSpecialists} = this.hierarchy;
			groupsOfSpecialists.items = items;
			this.updateHierarchy({groupsOfSpecialists});
		}
		if(viewType === ViewType.SchoolsGroup) {
			const {schoolsGroups} = this.hierarchy;
			schoolsGroups.items = items;
			this.updateHierarchy({schoolsGroups});
		}
	}

	public toggleBox = (type: BoxType, state: boolean) => {
		const {boxes} = this.hierarchy;
		if (boxes.filter(t => t.boxType === type).length === 0) {
			boxes.push({boxType: type, open: state});
		} else {
			const box = boxes.find(t => t.boxType === type);
			if (box.open === state) {
				return;
			}
			box.open = state;
		}

		this.openCloseRequests.next({boxType: type, open: state});

		this.updateHierarchy({boxes});

		const args: HierarchyEvents.Common.ToggledArgs = {
			boxType: type,
			open: state,
		};
		this.eventBus.dispatch(HierarchyEvents.Common.Toggled, args);
	};

	public init(classicForce?: boolean, restoreFromStorage?: boolean, snapshot?: HierarchySnapshot) {
		return this.fetchHierarchy(classicForce, restoreFromStorage, snapshot)
			.pipe(tap((instance) => {
				dispatchAppEvent(
					HierarchyInitializedEvent,
					new HierarchyInitializedEvent(instance),
				);
				dispatchAppEvent(HierarchyLoadedEvent);
			}));
	}

	public reload(): Observable<HierarchyInstance> {
		return this.fetchHierarchy(false, false, this.snapshot);
	}

	public updateHierarchy(hierarchy: Partial<HierarchyState>, notify?: boolean): void {
		const result = {...this.hierarchy, ...hierarchy} as HierarchyState;
		this.hierarchyState$.next(result);
		if (notify) {
			this.pushNotify(result);
		}
	}

	public async updateGlobalSchoolYear(schoolYearID: number): Promise<HierarchyInstance> {
		this.globalSchoolYearID = schoolYearID;
		await this.fetchHierarchy().toPromise();
		return this.hierarchyInstance;
	}

	public updateOutside(hierarchy: HierarchySnapshot): HierarchyInstance {
		const h = this.hierarchy;
		h.mode = hierarchy.mode;
		h.classic = hierarchy.classic;
		h.specialist = hierarchy.specialist;
		h.preAssess = hierarchy.preAssess;

		const fullHierarchy = buildHierarchyInstance(h);
		const currentFullHierarchy = this.hierarchyInstance;

		if (currentFullHierarchy.equal(fullHierarchy)) {
			return currentFullHierarchy;
		}

		this.updateHierarchy({
			mode: fullHierarchy.mode,
			classic: fullHierarchy.classic.extract(),
			specialist: fullHierarchy.specialist.extract(),
			preAssess: fullHierarchy.preAssess.extract(),
		});
		this.pushNotify(this.hierarchy, true);
		return fullHierarchy;
	}

	public override destroy() {
		super.destroy();
		this.hierarchyState$.complete();
		this.hierarchyChanged$.complete();
	}

	private getHierarchy(
		fullHierarchy?: HierarchySnapshot,
		useCache?: boolean,
	): Observable<IInitResponse> {
		if (useCache && this.cachedHierarchy) {
			return of(this.cachedHierarchy);
		}

		return this.httpClient.ESGIApi.get<IInitResponse>(
			this.controller,
			'init',
			{fullHierarchy},
		).pipe(tap((r) => {
			this.cachedHierarchy = r;
		})).asObservable();
	}

	private fetchHierarchy(
		classicForce?: boolean,
		restoreFromStorage?: boolean,
		snapshot?: HierarchySnapshot,
	): Observable<HierarchyInstance> {
		return this.getHierarchy(snapshot, restoreFromStorage)
			.pipe(
				map(r => {
					r.snapshot.specialist.type = userTypeTransform(r.snapshot.specialist.type);
					r.specialistGroups.items.forEach(row => row.type = userTypeTransform(row.type));

					const mode = r.snapshot.mode === 'Classic' ? HierarchyMode.Classic :
						r.snapshot.mode === 'PreAssess' ? HierarchyMode.PreAssess : HierarchyMode.Specialist;
					const hierarchy = new HierarchySnapshot();
					hierarchy.mode = mode;
					hierarchy.classic = r.snapshot.classic;
					hierarchy.specialist = r.snapshot.specialist;
					hierarchy.preAssess = r.snapshot.preAssess;

					if (restoreFromStorage) {
						this.storageService.applyStateFromStorage(hierarchy, {
							classes: r.classes,
							groups: r.groups,
							schoolsGroups: r.schoolsGroups,
							teachersGroups: r.teachersGroups,
							groupsOfSpecialists: r.groupsOfSpecialists,
							students: r.students,
							teachers: r.teachers,
							specialistGroups: r.specialistGroups,
							schools: r.schools,
							schAdmins: r.schAdmins,
							schoolSpecialists: r.schoolSpecialists,
							districtSpecialists: r.districtSpecialists,
							preAssesses: r.preAssesses,
						});
					}

					this.updateHierarchy({
						mode: classicForce ? HierarchyMode.Classic : hierarchy.mode,
						classic: hierarchy.classic,
						specialist: hierarchy.specialist,
						preAssess: hierarchy.preAssess,
						classes: r.classes,
						groups: r.groups,
						schoolsGroups: r.schoolsGroups,
						teachersGroups: r.teachersGroups,
						groupsOfSpecialists: r.groupsOfSpecialists,
						schools: r.schools,
						students: r.students,
						teachers: r.teachers,
						schAdmins: r.schAdmins,
						districtSpecialists: r.districtSpecialists,
						schoolSpecialists: r.schoolSpecialists,
						preAssesses: r.preAssesses,
						specialistGroups: r.specialistGroups,
						boxes: r.boxes,
						districtName: r.districtName,
					});
					this.pushNotify(this.hierarchy, true);
					return this.hierarchyInstance;
				}),
			);
	}

	private addStudentClicked = (): void => {
		const {classic} = this.hierarchy;
		const event = new HierarchyStudentAdd({
			specialistGroupID: null,
			classID: classic.classID,
			groupID: classic.groupID,
			schoolID: classic.schoolID,
			userID: classic.teacherID,
			userType: UserType.T,
		});
		dispatchAppEvent(HierarchyStudentAdd, event);
	};

	private userProfilesChanged = (args: UserInfoChangedEvent): void => {
		if (args.userType === UserType.T) {
			const {teachers} = this.hierarchy;
			teachers.items = teachers.items.map(x => x.userID === args.id
				? {
					...x,
					firstName: args.firstName,
					lastName: args.lastName,
					name: args.firstName + ' ' + args.lastName,
				}
				: {...x});

			this.updateHierarchy({teachers});
		}
	};

	private userSettingsChanged = ({sortBy}: UserSettingsChangedEvent): void => {
		userStorage.set({...userStorage.get(), studentSort: sortBy});
		this.hierarchyState$.next({
			...this.hierarchyState$.value, students: {
				...this.hierarchyState$.value.students,
				sortBy,
			},
		});
	};

	private testHistory = (args: TestHistorySelectedChangedArgs) => {
		let {mode, specialist, classic, preAssess, students} = this.hierarchy;
		if (args.groupID > 0) {
			specialist = withGroupID(specialist, args.groupID);
		}

		if (mode === HierarchyMode.Classic) {
			if (args.classID > 0) {
				classic = withClassID(classic, args.classID, students);
			}
			if (args.groupID > 0) {
				classic = classicApplyGroupID(classic, args.groupID, students);
			}
			if (args.studentID > 0) {
				classic = withStudentID(classic, args.studentID);
			}
		} else {
			if (args.groupID > 0) {
				specialist = withGroupID(specialist, args.groupID);
			}
		}

		if (args.groupID > 0) {
			preAssess = preAssessApplyGroupID(preAssess, args.groupID);
		}

		this.updateHierarchy({specialist, classic, preAssess}, true);
	};

	private pushNotify(state: HierarchyState, silence?: boolean) {
		const hierarchy = new HierarchySnapshot();
		hierarchy.mode = state.mode;
		hierarchy.classic = state.classic;
		hierarchy.specialist = state.specialist;
		hierarchy.preAssess = state.preAssess;
		hierarchy.districtID = userStorage.get().districtID;

		this.storageService.storeState(hierarchy);
		this.snapshot = hierarchy;
		const instance = this.hierarchyInstance;
		this.hierarchyChanged$.next(instance);
		if (!silence) {
			dispatchAppEvent(HierarchyChangedItselfEvent, new HierarchyChangedItselfEvent(instance));
		}
	}
}
