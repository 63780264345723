import React from 'react';
import {Api} from './api';
import {Library} from './models';
import {Search, State as SearchState} from './components/search/search';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';

export class State {
	search: SearchState = new SearchState();
	stockCount: number;
	privateCount: number;
}

export class Props extends SharedProps<State> {
	search: (keyword: string) => any;
	cardClicked: (lib: Library) => any;
}

export class Home extends SharedComponent<State, Props> {
	render(): JSX.Element | false | null {
		return <div className='home'>
			<div className='home-container'>
				<div className='row'>
					<h3 className='text-center'>
						Image Gallery
					</h3>
				</div>
				<div className='ig-search'>
					<Search search={() => this.searchClicked()} state={this.state.search}
					        onChange={(ch, cb) => this.setState({search: ch}, cb)}/>
				</div>
				<div className='lib-title'>Libraries</div>
				<div className='cards-row'>
					<div className='home lib-card stock clickable stock-library-link'
					     onClick={() => this.props.cardClicked(Library.Stock)}>
						<div className='image'>
							<img
								src='https://s3.amazonaws.com/ESGI/Images/esgistock.logo.png'
								draggable='false'/>
						</div>
						<div className='card-label'>ESGI Stock</div>
						<div className='count'>{this.state.stockCount} images</div>
					</div>
					<div className='home lib-card clickable my-images-link'
					     onClick={() => this.props.cardClicked(Library.Private)}>
						<div className='image'>
							<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width='100pt'
							     height='100pt' className='user-icon'>
								<g>
									<g opacity='0.68'>
										<ellipse cx='50'
										         cy='50'
										         rx='50' ry='50' fill='rgb(0,136,204)'/>
									</g>
									<path
										d=' M 50 50 C 56.492 50 61.75 44.742 61.75 38.25 C 61.75 31.758 56.492 26.5 50 26.5 C 43.508 26.5 38.25 31.758 38.25 38.25 C 38.25 44.742 43.508 50 50 50 Z  M 50 55.875 C 42.157 55.875 26.5 59.811 26.5 67.625 L 26.5 73.5 L 73.5 73.5 L 73.5 67.625 C 73.5 59.811 57.843 55.875 50 55.875 Z '
										fill='rgb(255,255,255)'/>
								</g>
							</svg>
						</div>
						<div className='card-label'>My Library</div>
						<div className='count'>{this.state.privateCount} images</div>
					</div>
				</div>
			</div>
		</div>;
	}

	searchClicked(): void {
		const keyword = this.state.search.keyword.trim();
		const searchState = this.state.search;
		searchState.keyword = '';
		this.props.search(keyword);
		this.setState({search: searchState});
	}

	componentDidMount(): void {
		Api.InitHome().subscribe(r => {
			this.setState({privateCount: r.privateCount, stockCount: r.stockCount});
		});
	}
}