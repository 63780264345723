import React from 'react';
import {Radio} from '@esgillc/ui-kit/control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Cards} from 'shared/modules/bingo/types/models';

import styles from 'shared/modules/bingo/steps/cards/cards.module.less';

export interface CardSizePanelProps {
	x3cardSize: boolean;
	onChange: (field: keyof Cards, value: boolean | string) => void,
	questionCount: number;
	twoCardsPerPage: boolean;
}

export default function CardSizePanel ({x3cardSize, onChange, questionCount, twoCardsPerPage}: CardSizePanelProps) {
	return (
		<div className={styles.panel}>
			<div className={styles.header}>
				<span>Card Size</span>
			</div>
			<div className={styles.columns}>
				<div className={styles.cards}>
					<div className={styles.card}>
						<div className={styles.table}>
							<Radio
								value={'card-selector'}
								checked={x3cardSize}
								name='card-selector'
								id='x3cardSize'
								data-cy='x3cardSize'
								onChange={() => onChange('x3cardSize', true)}
							/>
							<div>
								<table>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
								</table>
								<div>3x3</div>
							</div>
						</div>
					</div>
					<div className={styles.card}>
						<OnHoverTooltip message={questionCount < 24 && 'Selected test does not contain enough questions to fill a 5x5 card'}>
							<div className={styles.table}>
								<div className={styles.rightBtn}>
									<Radio
										value={'card-selector'}
										id='x5cardSize'
										data-cy='x5cardSize'
										disabled={questionCount < 24}
										checked={!x3cardSize}
										name='card-selector'
										onChange={() => onChange('x3cardSize', false)}
									/>
								</div>
								<div>
									<table className={questionCount < 24 ? styles.disable : ''}>
										<tr><td/><td/><td/><td/><td/></tr>
										<tr><td/><td/><td/><td/><td/></tr>
										<tr><td/><td/><td/><td/><td/></tr>
										<tr><td/><td/><td/><td/><td/></tr>
										<tr><td/><td/><td/><td/><td/></tr>
									</table>
									<div>5x5</div>
								</div>
							</div>
						</OnHoverTooltip>
					</div>
				</div>
				{x3cardSize && <div className={styles.print} data-cy='card-size-print'>
					<span className='card-print-item'>Print:</span>
					<Radio
						id='card-print-1'
						checked={!twoCardsPerPage}
						name='card-print'
						value='1'
						onChange={() => onChange('twoCardsPerPage', false)}
						className='card-print-item'
					>1</Radio>
					<Radio
						id='card-print-2'
						checked={twoCardsPerPage}
						name='card-print'
						value='2'
						onChange={() => onChange('twoCardsPerPage', true)}
						className='card-print-item'
					>2 bingo cards per page</Radio>
				</div>}
			</div>
		</div>
	);
}
