import {forwardRef, Ref, RefObject} from 'react';
import {
	AutoSizer as React17AutoSizer,
	List as React17List,
	CellMeasurer as React17CellMeasurer,
	Grid as React17Grid,
	CellMeasurerCache as React17CellMeasurerCache
} from '@enykeev/react-virtualized';
import {
	CellMeasurerCache as CellMeasurerCacheType,
	GridProps,
	CellMeasurerProps,
	AutoSizerProps,
	ListProps,
} from 'react-virtualized';

export {CellMeasurerCache} from '@enykeev/react-virtualized';

export function AutoSizer(props: AutoSizerProps) {
	// @ts-ignore
	return <React17AutoSizer {...props}/>;
}

export const List = forwardRef<React17List, ListProps>((props, ref) => {
	// @ts-ignore
	return <React17List {...props} ref={ref}/>;
});

export function CellMeasurer(props: CellMeasurerProps) {
	// @ts-ignore
	return <React17CellMeasurer {...props}/>;
}


export const Grid = forwardRef<React17Grid, GridProps>((props, ref) => {
	// @ts-ignore
	return <React17Grid {...props} ref={ref}/>;
});

export type ListRef = RefObject<React17List>;
export type GridRef = RefObject<React17Grid>;
