import React from 'react';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './card.module.less';

interface CardHeaderProps {
	titleNumber: number;
	infoText?: string;
	infoUrl?: string;
	remove?: () => any;
	children?: any;
}

export class CardHeader extends React.Component<CardHeaderProps> {
	render() {
		return (
			<div className={styles.title}>
				<span className={styles.number}>
					{this.props.titleNumber}
				</span>
				<div className={styles.textContainer}>
					{this.props.children}
					<div className={styles.buttonContainer}>
						{this.props.infoText && <OnHoverTooltip message={this.props.infoText}>
							<div className={styles.infoContainer}>
								{this.props.infoUrl
									? <a href={this.props.infoUrl} className='help' target='_blank' rel='noreferrer'>
										<QuestionMarkIcon fill='white'/>
									</a>
									: <QuestionMarkIcon fill='white'/>}
							</div>
						</OnHoverTooltip>}
						{this.props.remove && <OnHoverTooltip message='Remove test'>
							<button type='button' className={styles.closeButton} onClick={this.props.remove}>
								<svg width='14' height='14' viewBox='0 0 14 14' fill='none'
								     xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
										fill='white'/>
								</svg>
							</button>
						</OnHoverTooltip>}
					</div>
				</div>
			</div>
		);
	}
}
