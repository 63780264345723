import {createContext, useContext} from 'react';
import {SubjectSectionContextValue} from './types';

export const SubjectSectionContext = createContext<SubjectSectionContextValue | null>(null);

export function useSubjectSectionContext() {
	const context = useContext(SubjectSectionContext);

	if (!context) {
		throw new Error('SubjectSectionContext is null');
	}

	return context;
}
