import {EsgiComponent} from './renewal';

export class Page {
	renewal: EsgiComponent;

	public init() {
		this.renewal = new EsgiComponent();
		ko.applyBindings(this, document.body);
		return this.renewal.load();
	}

	public destroy() {
		ko.cleanNode(document.body);
	}
}
