import React, {createRef} from 'react';
import {Alert, AlertBody, AlertFooter, AlertHeader} from '@esgi/deprecated/ui-kit/modal/alert';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {ModalManager} from '@esgillc/ui-kit/modal';

interface Props {
	onClosed: () => void;
}

export default class UserMovedDialog extends React.PureComponent<Props> {
	private modalManagerRef: React.RefObject<ModalManager> = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef}>
			<AlertBody>
				This student is no longer associated with this class. This could happen if the student was moved by an
				administrator or by another teacher logged into this account from a second browser. You will now be
				returned to the homepage.
			</AlertBody>
			<AlertFooter>
				<LinkButton onClick={() => this.modalManagerRef.current.close(() => this.props.onClosed())}>
					OK
				</LinkButton>
			</AlertFooter>
		</Alert>;
	}
}
