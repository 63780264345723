import React from 'react';
import {
	ShapeDefinitions,
	ShapeSize,
	ShapeType,
	TextStyle,
	ImageGalaryItem,
	IGraphStyle, VertexStyle, FontStyle, IRotationStyle, ImageStyle,
} from 'shared/modules/question-editor/models';
import {
	ModalHeader,
	Modal,
	ModalBody,
	ModalFooter,
	SharedProps,
	SharedComponent,
} from '@esgi/deprecated/react';
import {
	mxCell,
	mxGraph,
	mxKeyHandler,
	mxUndoManager,
	mxGraphSelectionModel,
	mxAbstractCanvas2D,
} from 'mxgraph';
import Enumerable from 'linq';
import {ShapeDefinitionService} from 'shared/modules/question-editor/utils/shape-definition-builder';
import {EditingUndoRedoManager, UndoRedoModel} from 'shared/modules/question-editor/utils/editing-undo-redo-manager';
import {ContentEditableService} from 'shared/modules/question-editor/utils/content-editable-service';
import {ReactRenderable, Modal as OldModal} from '@esgi/deprecated/knockout';
import {CanvasHeader} from 'shared/modules/question-editor/mx/editor/canvas/header/canvas-header';
import {ContextMenuAction} from 'shared/modules/question-editor/mx/editor/canvas/header/context-menu/context-menu';
import {XmlCodec} from 'shared/modules/question-editor/utils/xml-codec';
import {Events as TextToolbarEvents} from 'shared/modules/question-editor/mx/editor/toolbars/text/text-toolbar';
import {Events as ToolbarMenuEvents} from 'shared/modules/question-editor/mx/editor/toolbar-menu/toolbar-menu';
import {Events as EdgeToolbarEvents} from 'shared/modules/question-editor/mx/editor/toolbars/edge/edge-toolbar';
import {Events as VertexToolbarEvents} from 'shared/modules/question-editor/mx/editor/toolbars/vertex/vertex-toolbar';
import * as FormEvents from 'shared/modules/question-editor/form-events';
import './canvas.less';
import mx from '../../../../assets/mx-graph/mx-graph-factory';
import overrideMxGraph from './mx-graph-overrides';
import {lazyClass} from '@esgi/core/react';

const imageGallery = lazyClass(() => import('shared/modules/image-gallery/image-gallery'));

export class Props extends SharedProps<State> {
	testName?: string;
	questionNumber?: number;
	isWhiteBackground: boolean;
	xml: string;
	images: ImageGalaryItem[] = [];
	shapeDefinitions: ShapeDefinitions;
	shapeDefinitionsXml: string;
}

const Settings = {
	graphWidth: 708,
	graphHeight: 318,
	gridSize: 10,
};

export class State {
	shapeType: ShapeType = null;
	shapeStyle: IGraphStyle = null;
	textIsTooLarge: boolean = false;
	cells: string[] = [];
	showUndoRedo: boolean = false;
	images: ImageGalaryItem[] = [];
	loaded: boolean = false;
}

export class Canvas extends SharedComponent<State, Props> {
	canvasRef: HTMLDivElement;
	mxGraph: mxGraph;
	mxKeyHandler: mxKeyHandler;
	undoManager: mxUndoManager;
	shapeDefinitionService: ShapeDefinitionService;
	lastTextEditorID: number = 0;
	prevTextEditorID: number = 0;
	initialXml: string = '';
	editingUndoRedoManager: EditingUndoRedoManager = new EditingUndoRedoManager();
	canvasInFocus: boolean = false;
	lastTextWidth: number;
	lastTextHeight: number;
	imageGalleryModal: OldModal = null;

	//#region Set up graph
	constructor(props) {
		super(props);

		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleClickInside = this.handleClickInside.bind(this);
	}

	handleClickOutside(event) {
		if (this.canvasRef && !this.canvasRef.contains(event.target)) {
			this.outsideClicked();
		}
	}

	outsideClicked() {
		this.canvasInFocus = false;

		if (this.mxGraph.popupMenuHandler.isMenuShowing()) {
			this.mxGraph.popupMenuHandler.hideMenu();
		}
	}

	componentWillUnmount() {
		this.mxKeyHandler.destroy();
		this.mxGraph.destroy();
		mx.mxObjectCodec.prototype.afterDecode = (dec: any, node: any, obj: any) => obj;
		document.removeEventListener('mousedown', this.handleClickOutside);
		document.removeEventListener('mousedown', this.handleClickInside);
		$(document).unbind('copy', this.copyEvent);
		$(document).unbind('paste', this.pasteEvent);
		super.componentWillUnmount();
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		document.addEventListener('mousedown', this.handleClickInside);

		this.setOverrides();
		this.setShapeDefinitions();
		this.registerESGITriangle();
		this.setRotateImage();
		this.createGraph();
		this.setHoverHandlers();
		this.setGlobalStyles();
		this.setPopupMenu();
		this.subscribeToReactEvents();
		this.subscribeToMxGraphEvents();
		this.setXml();

		this.setPasteHandler();
	}

	handleClickInside(event) {
		if (this.canvasRef && this.canvasRef.contains(event.target)) {
			this.canvasInFocus = true;
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.questionNumber != this.props.questionNumber) {
			this.setXml();
			this.setShapeDefinitions();
		}
	}

	createGraph() {
		this.mxGraph = new mx.mxGraph(this.canvasRef);
		this.mxGraph.setEnabled(true);
		this.mxGraph.setPanning(false);
		this.mxGraph.setHtmlLabels(true);
		this.mxGraph.setConnectable(false);
		this.mxGraph.setCellsCloneable(false);
		this.mxGraph.setResizeContainer(false);
		this.mxGraph.setGridSize(Settings.gridSize);
		this.mxGraph.setCellsResizable(true);

		this.undoManager = new mx.mxUndoManager(100);

		const listener = (sender, evt) => {
			this.undoManager.undoableEditHappened(evt.getProperty('edit'));
		};

		this.mxModel.addListener(mx.mxEvent.UNDO, listener);
		this.mxGraph.getView().addListener(mx.mxEvent.UNDO, listener);

		mx.mxGraphHandler.prototype.scrollOnMove = false;
		mx.mxGraphHandler.prototype.maxLivePreview = 100;
		mx.mxGraphHandler.prototype.allowLivePreview = true;
		mx.mxVertexHandler.prototype.rotationEnabled = true;
		mx.mxVertexHandler.prototype.livePreview = true;
		mx.mxVertexHandler.prototype.isLivePreviewBorder = () => true;
		mx.mxGraph.prototype.isWrapping = (cell) => true;
		mx.mxGraph.prototype.isCellConnectable = (cell) => false;

		mx.mxEvent.disableContextMenu(this.canvasRef);

		new mx.mxRubberband(this.mxGraph);

		this.mxKeyHandler = new mx.mxKeyHandler(this.mxGraph);
		this.mxKeyHandler.bindKey(8, (evt) => {
			this.deleteSelectedItems();
		});

		this.mxKeyHandler.bindKey(46, (evt) => {
			this.deleteSelectedItems();
		});

		this.setGraphBounds();
	}

	setOverrides() {
		overrideMxGraph();
		mx.mxObjectCodec.prototype.afterDecode = (dec, node, obj) => {
			if (obj instanceof mx.mxCell) {
				if (obj.type === ShapeType.Image) {
					const image = this.props.images.filter(x => x.id === obj.imageID)[0];
					if (image){
						obj.style = obj.style + `image=${image.url};`;
					}
				}
			}
			return obj;
		};
	}

	setGlobalStyles() {
		mx.mxConstants.VERTEX_SELECTION_COLOR = '#428bca';
		mx.mxConstants.EDGE_SELECTION_COLOR = '#428bca';
		mx.mxConstants.HANDLE_FILLCOLOR = '#428bca';
		mx.mxConstants.HANDLE_STROKECOLOR = '#428bca';
		mx.mxConstants.WORD_WRAP = 'break-word';
		mx.mxConstants.LINE_HEIGHT = 1.3;
		mx.mxGraphHandler.prototype.previewColor = '#428bca';
	}

	setGraphBounds() {
		this.mxGraph.maximumGraphBounds = new mx.mxRectangle(0, 0, Settings.graphWidth, Settings.graphHeight);
		this.mxGraph.minimumGraphSize = new mx.mxRectangle(0, 0, Settings.graphWidth, Settings.graphHeight);
	}

	registerESGITriangle() {
		class mxESGITriangle extends mx.mxActor {
			redrawPath: (c: any, x: number, y: number, w: number, h: number) => void;

			constructor(bounds: any, fill: any, stroke: any, strokewidth: any) {
				super(bounds, fill, stroke, strokewidth);
			}

			isRoundable: () => boolean;
		}

		mx.mxUtils.extend(mxESGITriangle, mx.mxTriangle);

		mxESGITriangle.prototype.isRoundable = function () {
			return true;
		};

		mxESGITriangle.prototype.redrawPath = function (c: mxAbstractCanvas2D, x: number, y: number, w: number, h: number) {
			let arcSize = mx.mxUtils.getValue(this.style, mx.mxConstants.STYLE_ARCSIZE, mx.mxConstants.LINE_ARCSIZE) / 2;
			this.addPoints(c, [new mx.mxPoint(0.5 * h, 0), new mx.mxPoint(w, 1.0 * h), new mx.mxPoint(0, h)], this.isRounded, arcSize, true);
		};

		mx.mxCellRenderer.registerShape(mx.mxConstants.SHAPE_TRIANGLE, mxESGITriangle);
	}

	setHoverHandlers() {
		let self = this;
		this.mxGraph.addMouseListener({
			currentState: null,
			mouseDown: function (sender, me) {
				if (this.currentState != null) {
					this.dragLeave(me.getEvent(), this.currentState, self);
					this.currentState = null;
				}
			},
			mouseMove: function (sender, me) {
				if (this.currentState != null && me.getState() == this.currentState) {
					return;
				}

				let tmp = self.mxGraph.view.getState(me.getCell());

				if (self.mxGraph.isMouseDown || (tmp != null && !
				self.mxGraph.getModel().isVertex(tmp.cell))) {
					tmp = null;
				}

				if (tmp != this.currentState) {
					let selectedCell = self.selectedCells.length > 0 ? self.selectedCells[0] : null;


					if (this.currentState != null && !!this.currentState.shape) {
						this.dragLeave(me.getEvent(), this.currentState, self);
					}

					this.currentState = tmp;

					if (this.currentState != null && !!this.currentState.shape) {
						let isSelected = selectedCell ? +selectedCell.id === +this.currentState.cell.id : false;
						if (!isSelected) {
							this.dragEnter(me.getEvent(), this.currentState, self);
						}
					}
				}
			},
			mouseUp: function (sender, me) {
			},
			dragEnter: function (evt, state, self) {
				updateStyle(state, true, self);
				state.shape.apply(state);
				state.shape.redraw();

				if (state.text != null) {
					state.text.apply(state);
					state.text.redraw();
				}
			},
			dragLeave: function (evt, state, self) {
				updateStyle(state, false, self);
				state.shape.apply(state);
				state.shape.redraw();

				if (state.text != null) {
					state.text.apply(state);
					state.text.redraw();
				}
			},
		});

		function updateStyle(state, hover, self) {
			if (state.cell.type === ShapeType.Image) {
				if (hover) {
					state.style[mx.mxConstants.STYLE_IMAGE_BORDER] = '#428bca';
					state.style[mx.mxConstants.STYLE_STROKEWIDTH] = '2';
					state.style[mx.mxConstants.STYLE_STROKE_OPACITY] = '70';
				} else {
					delete state.style[mx.mxConstants.STYLE_IMAGE_BORDER];
					delete state.style[mx.mxConstants.STYLE_STROKE_OPACITY];
					delete state.style[mx.mxConstants.STYLE_STROKEWIDTH];
				}
			}

			if (state.cell.type === ShapeType.Text) {
				if (hover) {
					state.style[mx.mxConstants.STYLE_STROKECOLOR] = '#428bca';
					state.style[mx.mxConstants.STYLE_STROKEWIDTH] = '2';
					state.style[mx.mxConstants.STYLE_STROKE_OPACITY] = '70';
				} else {
					state.style[mx.mxConstants.STYLE_STROKECOLOR] = 'transparent';
					delete state.style[mx.mxConstants.STYLE_STROKE_OPACITY];
					delete state.style[mx.mxConstants.STYLE_STROKEWIDTH];
				}
			}

			if (state.cell.type >= ShapeType.Rectangle) {
				let shapeType = self.shapeDefinitionService.shapeStyleFactory(state.cell.type);
				let styleObg = self.shapeDefinitionService.convertStrStyleToGraphStyle(shapeType, state.cell.style) as VertexStyle;

				if (hover) {
					state.style[mx.mxConstants.STYLE_STROKECOLOR] = '#428bca';
				} else {
					state.style[mx.mxConstants.STYLE_STROKECOLOR] = styleObg.strokeColor;
				}
			}
		}
	}
	pasteEvent = (event: any) => {
		if (this.canvasInFocus) {
			if (this.mxGraph.isEditing()) {
				event.preventDefault();
				const clipboardData = event.originalEvent.clipboardData.getData('text/plain');

				const cell = this.selectedCells[0];
				const style = this.shapeDefinitionService.convertStrStyleToGraphStyle(new TextStyle(), cell.style) as TextStyle;
				const currentSize = mx.mxUtils.getSizeForString(clipboardData, style.fontSize, style.fontFamily, cell.geometry.width, style.fontStyle);

				if (currentSize.height < cell.geometry.height) {
					document.execCommand('insertText', false, clipboardData);
				} else{
					this.setState({textIsTooLarge: true});
				}
			} else {
				mx.mxClipboard.paste(this.mxGraph);
			}
		}
	};
	copyEvent = (event: any) => {
		if (this.canvasInFocus) {
			mx.mxClipboard.copy(this.mxGraph, this.selectedCells);
		}
	}

	setPasteHandler() {
		$(document).bind('copy', this.copyEvent);
		$(document).bind('paste', this.pasteEvent);
	}

	setShapeDefinitions() {
		let shapeDefinitions = JSON.parse(JSON.stringify(this.props.shapeDefinitions)) as ShapeDefinitions;
		if (this.props.shapeDefinitionsXml) {
			XmlCodec.setDefinitionsFromXml(this.props.shapeDefinitionsXml, shapeDefinitions, !!this.props.xml, this.props.questionNumber === 2);
		}
		this.shapeDefinitionService = new ShapeDefinitionService(shapeDefinitions);
	}

	setRotateImage() {
		let imgSrc = 'https://esgiwebfiles.s3.amazonaws.com/images/question-editor/rotate.png';
		mx.mxVertexHandler.prototype.createSizerShape = function (bounds, index, fillColor) {
			if (this.handleImage != null) {
				bounds = new mx.mxRectangle(bounds.x, bounds.y, this.handleImage.width, this.handleImage.height);
				let shape = new mx.mxImageShape(bounds, this.handleImage.src, '', '');
				shape.preserveImageAspect = false;

				return shape;
			} else if (index == mx.mxEvent.ROTATION_HANDLE) {
				bounds = new mx.mxRectangle(bounds.x, bounds.y, 16, 16);
				let shape = new mx.mxImageShape(bounds, imgSrc, '', '');
				shape.preserveImageAspect = false;

				return shape;
			} else {
				return new mx.mxRectangleShape(bounds, fillColor || mx.mxConstants.HANDLE_FILLCOLOR, mx.mxConstants.HANDLE_STROKECOLOR);
			}
		};
	}

	setPopupMenu() {
		let handler = new mx.mxPopupMenuHandler(this.mxGraph, (menu, cell: mxCell, evt: PointerEvent) => {
			if (cell != null) {
				menu.addItem('Undo', null, () => {
					this.undo();
				});

				menu.addItem('Redo', null, () => {
					this.redo();
				});

				menu.addSeparator();

				menu.addItem('Move to Front', null, () => {
					this.orderSelectedCells(false);
				});

				menu.addItem('Move to Back', null, () => {
					this.orderSelectedCells(true);
				});

				menu.addSeparator();

				if (cell.type === ShapeType.Image || (cell.type >= ShapeType.Rectangle && cell.type < ShapeType.Line)) {
					menu.addItem('Flip Horizontal', null, () => {
						this.mxGraph.toggleCellStyles(mx.mxConstants.STYLE_FLIPH, false, null);
					});

					menu.addItem('Flip Vertical', null, () => {
						this.mxGraph.toggleCellStyles(mx.mxConstants.STYLE_FLIPV, false, null);
					});

					menu.addSeparator();
				}

				menu.addItem('Duplicate', null, () => {
					this.duplicateShape();
				});

				menu.addItem('Delete', null, () => {
					this.deleteSelectedItems();
				});
			} else {
				menu.addItem('Undo', null, () => {
					this.undo();
				});

				menu.addItem('Redo', null, () => {
					this.redo();
				});

				menu.addSeparator();

				menu.addItem('Add text', null, () => {
					this.addShape(ShapeType.Text);
				});

				menu.addItem('Add image', null, () => {
					this.openImageGallery();
				});
			}
		});

		this.mxGraph.popupMenuHandler = handler;
	}

	//#endregion
	//#region Graph data
	getGraphData() {
		const encoder = new mx.mxCodec();
		return mx.mxUtils.getXml(encoder.encode(this.mxModel));
	}

	getXml() {
		if (this.mxGraph.isEditing()) {
			this.mxGraph.cellEditor.stopEditing(false);
			this.removeEmptyTextEditor();
		}
		return this.getGraphData();
	}

	setXml() {
		let newState = {...this.state};

		let xml = this.props.xml;

		if (!this.props.xml) {
			xml = '<mxGraphModel><root><mxCell id="0"/><mxCell id="1" parent="0"/></root></mxGraphModel>';
		}

		let xmlDocument = mx.mxUtils.parseXml(xml);
		let node = xmlDocument.documentElement;
		let decoder = new mx.mxCodec(xmlDocument);
		decoder.decode(node, this.mxModel);

		newState.cells = this.notEmptyGraphCells;
		newState.images = this.props.images ? [...this.props.images] : [];
		newState.loaded = true;
		this.initialXml = this.getGraphData();
		this.undoManager.clear();
		this.setState(newState);
	}

	//#endregion
	//#region Subscribe to events
	subscribeToReactEvents() {
		this.subscribe(FormEvents.SaveAndCloseClicked, () => {
			const xml = this.getXml();

			this.dispatch(Events.SaveAndClose, Events.SaveAndClose(xml, xml !== this.initialXml, this.shapeDefinitionService.shapeDefinition));
		});

		this.subscribe(FormEvents.SaveAndNextClicked, () => {
			const xml = this.getXml();
			this.mxSelectionModel.clear();
			this.setState({
				shapeStyle: null,
				shapeType: null,
			}, () => this.dispatch(Events.SaveAndNext, Events.SaveAndNext(xml, xml !== this.initialXml, this.shapeDefinitionService.shapeDefinition)));
		});

		this.subscribe(FormEvents.CloseClicked, () => {
			const xml = this.getXml();
			this.dispatch(Events.Close, Events.Close(xml, xml !== this.initialXml, this.shapeDefinitionService.shapeDefinition));
		});

		this.subscribe(ToolbarMenuEvents.Added, (shapeType: ShapeType) => {
			if (this.mxGraph.isEditing()) {
				this.mxGraph.cellEditor.stopEditing(false);
			}
			if (shapeType === ShapeType.Image) {
				this.openImageGallery();
			} else {
				this.addShape(shapeType);
			}
		});

		this.subscribe(TextToolbarEvents.StyleChanged, (args: TextToolbarEvents.StyleChanged.Args) => {
			const cell = this.mxSelectionModel.cells[0];
			const text = this.mxGraph.isEditing() ? this.mxGraph.cellEditor.textarea.innerHTML : cell?.value;
			let updateOnlyCell = !ContentEditableService.contentSelected(this.mxGraph.cellEditor.textarea);
			let style = args.style;

			if (args.change === 'align') {
				updateOnlyCell = true;
				style = this.shapeDefinitionService.convertStrStyleToGraphStyle(new TextStyle(), cell?.style) as TextStyle;
				style.align = args?.value;
			}

			const currentSize = this.getCellSize(cell, style);

			if (currentSize.height > Settings.graphHeight) {
				this.setState({textIsTooLarge: true});
				return;
			}

			if (!updateOnlyCell) {
				let command = '';

				if (args.change === 'fontStyle') {
					const style = args.value as FontStyle;
					switch (style) {
						case FontStyle.Bold: {
							command = 'bold';
							break;
						}
						case FontStyle.Italic: {
							command = 'italic';
							break;
						}
						case FontStyle.Underline: {
							command = 'underline';
							break;
						}
					}

					document.execCommand(command, false, null);
				}

				if (args.change === 'fontFamily') {
					document.execCommand('fontName', false, args.value);
				}

				if (args.change === 'fontColor') {
					document.execCommand('foreColor', false, args.value);
				}

				if (args.change === 'fontSize') {
					document.execCommand('fontSize', false, '1');

					const node = ContentEditableService.getEditingElement(this.mxGraph.cellEditor.textarea);
					node.removeAttribute('size');
					node.style.fontSize = args.value + 'px';
				}

				this.addToEditingUndoRedo(cell.style, this.mxGraph.cellEditor.textarea.innerHTML);

				if (!this.state.showUndoRedo && this.state.loaded) {
					this.setState({showUndoRedo: true});
				}
			}

			if (updateOnlyCell) {
				let value = text;
				if (args.change === 'fontStyle') {
					let command;
					const style = args.value as FontStyle;
					switch (style) {
						case FontStyle.Bold: {
							command = 'bold';
							break;
						}
						case FontStyle.Italic: {
							command = 'italic';
							break;
						}
						case FontStyle.Underline: {
							command = 'underline';
							break;
						}
					}

					value = ContentEditableService.sendCommandToContent(text, command);
				}

				if (args.change === 'fontFamily') {
					value = ContentEditableService.sendCommandToContent(text, 'fontName', args.value);
				}

				if (args.change === 'fontColor') {
					value = ContentEditableService.sendCommandToContent(text, 'foreColor', args.value);
				}

				if (args.change === 'fontSize') {
					const tempDiv = $(`<div>${text}</div>`);
					tempDiv.children('font').contents().unwrap();
					value = tempDiv.html();
				}

				this.updateGraph(() => {
					cell.setValue(value);
					this.updateShapeStyle(cell, style, cell.type);
					if (currentSize.width > cell.geometry.width || currentSize.height > cell.geometry.height) {
						this.updateTextareaSize(cell);
					}
				});

				if (this.mxGraph.isEditing(cell)) {
					this.updateEditableTextarea(style, value);
					this.addToEditingUndoRedo(cell.style, this.mxGraph.cellEditor.textarea.innerHTML);

					//we need to restart editing for cell.setValue to take effect
					this.mxGraph.stopEditing(true);
					this.mxGraph.startEditingAtCell(cell, null);

					this.setCaretPosition();
				}
			}

			this.setState({shapeStyle: {...style}});
			this.shapeDefinitionService.updateStyle(args.type, style);
		});

		this.subscribe(VertexToolbarEvents.StyleChanged, (args: VertexToolbarEvents.StyleChanged.Args) => {
			const cell = this.mxSelectionModel.cells[0];
			this.updateGraph(() => {
				this.updateShapeStyle(cell, args.style, cell.type);
			});

			this.setState({shapeStyle: {...args.style}});
			this.shapeDefinitionService.updateStyle(args.type, args.style);
		});

		this.subscribe(EdgeToolbarEvents.StyleChanged, (args: EdgeToolbarEvents.StyleChanged.Args) => {
			const cell = this.mxSelectionModel.cells[0];
			this.updateGraph(() => {
				this.updateShapeStyle(cell, args.style, cell.type);
			});

			this.setState({shapeStyle: {...args.style}});
			this.shapeDefinitionService.updateStyle(args.type, args.style);
		});
	}

	subscribeToMxGraphEvents() {
		this.mxGraph.addListener(mx.mxEvent.EDITING_STARTED, () => {
			const text = this.mxGraph.cellEditor.textarea;

			mx.mxEvent.addListener(text, 'mouseup', (evt: any) => {
				let style = {...this.state.shapeStyle} as TextStyle;

				const node = ContentEditableService.getEditingElement(this.mxGraph.cellEditor.textarea);
				const css = mx.mxUtils.getCurrentStyle(node);
				if (css != null) {
					style.fontFamily = css.fontFamily.replace(/['"]+/g, '');
					style.fontSize = parseInt(css.fontSize);
					style.fontColor = ContentEditableService.RGBToHex(css.color);
					style.fontStyle = ContentEditableService.getFontStyle(node);
				}

				this.setState({shapeStyle: style});
			});

			mx.mxEvent.addListener(text, 'keydown', (evt: any) => {
				const cell = this.mxSelectionModel.cells[0];

				if (this.mxGraph.isEditing(cell)) {

					const prevValue = this.mxGraph.cellEditor.textarea.innerHTML;

					//we need a timeout for the value to be set to textarea
					setTimeout(() => {
						this.addToEditingUndoRedo(cell.style, this.mxGraph.cellEditor.textarea.innerHTML);

						let cells = [];

						if (evt.target.innerHTML.length > 0) {
							cells = [...this.notEmptyGraphCells];
							cells.push(cell.id);
						} else {
							cells = this.notEmptyGraphCells.filter(x => +x != +cell.id);
						}

						this.setState({cells: cells});

						const style = this.shapeDefinitionService.convertStrStyleToGraphStyle(new TextStyle(), cell.style) as TextStyle;
						const currentSize = mx.mxUtils.getSizeForString(this.mxGraph.cellEditor.textarea.innerHTML, style.fontSize, style.fontFamily, cell.geometry.width, style.fontStyle);
            const prevSize = mx.mxUtils.getSizeForString(prevValue, style.fontSize, style.fontFamily, cell.geometry.width, style.fontStyle);

						if (currentSize.height > prevSize.height && currentSize.height > Settings.graphHeight) {
							this.mxGraph.cellEditor.textarea.innerHTML = prevValue;
							this.setCaretPosition();
						}
					}, 1);
				}
			});
		});

		this.mxGraph.addListener(mx.mxEvent.LABEL_CHANGED, (sender: mxGraph, evt) => {
			const cell = evt.getProperty('cell') as mxCell;
			this.updateTextareaSize(cell);

			const geo = cell.getGeometry();
			if (geo.height >= Settings.graphHeight) {
				cell.geometry.height = Settings.graphHeight;
				cell.geometry.y = 0;
				this.mxGraph.refresh(cell);
			}
		});

		this.mxGraph.addListener(mx.mxEvent.MOVE_CELLS, (sender: mxGraph, evt) => {
			let cell = evt.getProperty('cells')[0] as mxCell;

			if (!cell) {
				return;
			}

			if (cell.type === ShapeType.Text && !cell.value) {
				setTimeout(() => {
					this.mxGraph.startEditingAtCell(cell, null);
					this.addToEditingUndoRedo(cell.style, cell.value);
					this.setCaretPosition();
				}, 10);
			}

			if (cell.type >= ShapeType.Line) {
				this.shapeDefinitionService.updateEdgePosition(cell.type, cell.geometry.sourcePoint.x, cell.geometry.sourcePoint.y, cell.geometry.targetPoint.x, cell.geometry.targetPoint.y);
			} else if (cell.type != ShapeType.Text) {
				this.shapeDefinitionService.updateVertexSize(cell.type, cell.geometry.width, cell.geometry.height);
				this.shapeDefinitionService.updateVertexPosition(cell.type, cell.geometry.x, cell.geometry.y);
			}
		});


		this.mxGraph.addListener('EDGE_POINT_MOVED', (sender: mxGraph, evt) => {
			let cell = evt.getProperty('cell') as mxCell;
			this.shapeDefinitionService.updateEdgePosition(cell.type, cell.geometry.sourcePoint.x, cell.geometry.sourcePoint.y, cell.geometry.targetPoint.x, cell.geometry.targetPoint.y);
		});

		this.mxGraph.addListener(mx.mxEvent.RESIZE_CELLS, (sender: mxGraph, evt) => {
			let cell = evt.getProperty('cells')[0] as mxCell;

			if (cell.type != ShapeType.Text) {
				this.shapeDefinitionService.updateVertexSize(cell.type, cell.geometry.width, cell.geometry.height);
			}

			if (cell.type === ShapeType.Text) {
				setTimeout(() => {
					let currentSize = this.getCellSize(cell);

					if (currentSize.height > Settings.graphHeight) {
						this.setState({textIsTooLarge: true});
						cell.geometry.width = this.lastTextWidth;
						cell.geometry.height = this.lastTextHeight;
						this.mxGraph.refresh(cell);
					} else {
						this.updateTextareaSize(cell, true);
						this.mxGraph.startEditingAtCell(cell, null);
						this.addToEditingUndoRedo(cell.style, cell.value);
						this.setCaretPosition();
					}
				}, 10);
			}
		});

		this.mxModel.addListener(mx.mxEvent.CHANGE, (sender: mxGraphSelectionModel, evt) => {
			this.canvasInFocus = true;
			if (!!this.state.shapeType && (this.state.shapeType < ShapeType.Line)) {
				let cell = this.selectedCells[0];
				if (cell) {
					let style = this.shapeDefinitionService.convertStrStyleToGraphStyle(this.shapeDefinitionService.shapeStyleFactory(cell.type), cell.style) as IRotationStyle;
					let selectedStyle = this.state.shapeStyle as IRotationStyle;

					if (selectedStyle.rotation !== style.rotation) {
						let newStyle = {...selectedStyle};
						newStyle.rotation = style.rotation;
						this.setState({shapeStyle: newStyle});
					}
				}
			}

			if (!this.state.showUndoRedo && this.state.loaded) {
				this.setState({showUndoRedo: true});
			}
		});

		this.mxSelectionModel.addListener(mx.mxEvent.CHANGE, (sender: mxGraphSelectionModel, evt) => {
			if (sender.cells.length === 1) {
				let cell = sender.cells[0];
				if (cell instanceof mx.mxCell) {
					if (cell.type === ShapeType.Text) {
						this.lastTextWidth = cell.geometry.width;
						this.lastTextHeight = cell.geometry.height;
					}

					let style = this.shapeDefinitionService.shapeStyleFactory(cell.type);
					let shapeStyle = this.shapeDefinitionService.convertStrStyleToGraphStyle(style, cell.style);

					this.setState({shapeStyle: shapeStyle, shapeType: cell.type});
				}
			} else {
				this.removeEmptyTextEditor();
				this.editingUndoRedoManager.clear();
				this.setState({shapeStyle: null, shapeType: null});
			}

			let cells = this.notEmptyGraphCells;
			this.setState({cells: cells});
		});

		this.mxGraph.addListener(mx.mxEvent.CLICK, (sender, evt) => {
			let event = evt.getProperty('event') as PointerEvent;
			if (mx.mxEvent.isLeftMouseButton(event)) {
				let cell = evt.getProperty('cell') as mxCell;
				this.prevTextEditorID = this.lastTextEditorID;
				this.lastTextEditorID = cell ? parseInt(cell.id) : null;

				if ((!!this.lastTextEditorID && !!this.prevTextEditorID) && (this.lastTextEditorID === this.prevTextEditorID)) {
					this.lastTextEditorID = null;
					this.prevTextEditorID = null;

					if (this.state.shapeType === ShapeType.Text) {
						this.mxGraph.startEditingAtCell(cell, null);
						this.addToEditingUndoRedo(cell.style, cell.value);

						this.setCaretPosition();
					}
				}
			}
		});

		this.mxGraph.addListener(mx.mxEvent.DOUBLE_CLICK, (sender, evt) => {
			let cell = evt.getProperty('cell') as mxCell;

			if (!cell) {
				this.addShape(ShapeType.Text);
			}
		});
	}

	//#endregion
	//#region Undo redo
	addToEditingUndoRedo(style: string, value: string) {
		let model = new UndoRedoModel();
		model.style = this.shapeDefinitionService.convertStrStyleToGraphStyle(new TextStyle(), style) as TextStyle;
		model.value = value;
		this.editingUndoRedoManager.addStep(model);
	}

	undo() {
		if (this.mxGraph.isEditing()) {
			const cell = this.mxGraph.cellEditor.getEditingCell();
			let el = this.mxGraph.cellEditor.textarea;

			if (el.innerHTML) {
				const undoRedo = this.editingUndoRedoManager.undo();

				this.updateEditableTextarea(undoRedo.style, undoRedo.value);

				el.innerHTML = undoRedo.value;

				this.setCaretPosition();

				cell.setValue(undoRedo.value);
				this.updateShapeStyle(cell, undoRedo.style, cell.type);

				this.shapeDefinitionService.updateStyle(ShapeType.Text, undoRedo.style);
			}
		} else {
			const lastChange = this.undoManager.history[this.undoManager.indexOfNextAdd - 1];
			// we need to apply 2 steps, if early was added empty text editor.
			if (!!lastChange && ('value' in lastChange.changes[0])
				&& lastChange.changes[0].previous === null
				&& lastChange.changes[0].value !== null) {
				this.undoManager.undo();
			}

			this.undoManager.undo();
		}

		this.mxSelectionModel.fireEvent(new mx.mxEventObject(mx.mxEvent.CHANGE));
	}

	redo() {
		if (this.mxGraph.isEditing()) {
			const cell = this.mxGraph.cellEditor.getEditingCell();
			let el = this.mxGraph.cellEditor.textarea;

			if (el.innerHTML) {
				const undoRedo = this.editingUndoRedoManager.redo();

				this.updateEditableTextarea(undoRedo.style, undoRedo.value);

				el.innerHTML = undoRedo.value;

				this.setCaretPosition();

				cell.setValue(undoRedo.value);
				this.updateShapeStyle(cell, undoRedo.style, cell.type);

				this.shapeDefinitionService.updateStyle(ShapeType.Text, undoRedo.style);
			}
		} else {
			const lastChange = this.undoManager.history[this.undoManager.indexOfNextAdd + 1];
			// we need to apply 2 steps, if early was added empty text editor.
			if (!!lastChange && ('value' in lastChange.changes[0])
				&& lastChange.changes[0].value === null
				&& lastChange.changes[0].previous !== null) {
				this.undoManager.redo();
			}

			this.undoManager.redo();
		}

		this.mxSelectionModel.fireEvent(new mx.mxEventObject(mx.mxEvent.CHANGE));
	}


	//#endregion
	//#region Textarea
	updateTextareaSize(cell: mxCell, byWidth: boolean = false) {
		const style = this.shapeDefinitionService.convertStrStyleToGraphStyle(new TextStyle(), cell.style) as TextStyle;

		const value = this.mxGraph.isEditing() ? this.mxGraph.cellEditor.textarea.innerHTML : cell.value;
		let copyValue = value;

		//remove last break chars
		for (let i = value.length - 1; i > 0; i--) {
			let lastChar = value[i];
			if (lastChar.match(/(\r\n|\n|\r)/gm)) {
				copyValue = copyValue.slice(0, -1);
			} else {
				break;
			}
		}

		cell.value = copyValue;

		const size = mx.mxUtils.getSizeForString(value, style.fontSize, style.fontFamily, null, style.fontStyle);

		if (size.width > cell.geometry.width) {
			//cell increase if 1 character was entered
			if (cell.value.length === 1) {
				this.mxGraph.updateCellSize(cell, true);
			} else {
				this.mxGraph.updateCellSize(cell, true, cell.geometry.width);
			}
		} else if (!byWidth) {
			this.mxGraph.updateCellSize(cell, true);
		}

		//centering textarea after change fontstyle
		if (this.mxGraph.isEditing()) {
			this.mxGraph.cellEditor.textarea.style.top = cell.geometry.y + cell.geometry.height / 2 + 'px';
		}
	}

	updateEditableTextarea(style: TextStyle, value: string) {
		let textareaStyle = this.mxGraph.cellEditor.textarea.style;

		this.mxGraph.cellEditor.textarea.innerHTML = value;
		if ((style.fontStyle & FontStyle.Bold) === FontStyle.Bold) {
			textareaStyle.fontWeight = 'bold';
		} else {
			textareaStyle.fontWeight = 'normal';
		}

		if ((style.fontStyle & FontStyle.Italic) === FontStyle.Italic) {
			textareaStyle.fontStyle = 'italic';
		} else {
			textareaStyle.fontStyle = '';
		}

		if ((style.fontStyle & FontStyle.Underline) === FontStyle.Underline) {
			textareaStyle.textDecoration = 'underline';
			textareaStyle.textDecorationColor = 'initial';
			textareaStyle.textDecorationLine = 'underline';
			textareaStyle.textDecorationSkipInk = '';
			textareaStyle.textDecorationStyle = 'initial';
		} else {
			textareaStyle.textDecoration = '';
			textareaStyle.textDecorationColor = '';
			textareaStyle.textDecorationLine = '';
			textareaStyle.textDecorationSkipInk = '';
			textareaStyle.textDecorationStyle = '';
		}

		textareaStyle.color = style.fontColor;
		textareaStyle.fontSize = style.fontSize + 'px';
		textareaStyle.fontFamily = style.fontFamily;
		textareaStyle.textAlign = style.align;
	}

	removeEmptyTextEditor() {
		const emptyTextEditors = this.graphCells.filter(x => x.type === ShapeType.Text && !x.value);
		if (emptyTextEditors.length > 0) {
			const editor = emptyTextEditors[0];
			this.removeCells([editor.id]);
		}
	}

	//#endregion
	//#region Other
	getCellSize(cell: mxCell, style?: TextStyle) {
		const value = this.mxGraph.isEditing() ? this.mxGraph.cellEditor.textarea.innerHTML : cell.value;

		if (!style) {
			style = this.shapeDefinitionService.convertStrStyleToGraphStyle(new TextStyle(), cell.style) as TextStyle;
		}

		return mx.mxUtils.getSizeForString(value, style.fontSize, style.fontFamily, cell.geometry.width, style.fontStyle);
	}

	setCaretPosition() {
		const text = document.createTextNode('');
		this.mxGraph.cellEditor.textarea.appendChild(text);
		document.getSelection().collapse(this.mxGraph.cellEditor.textarea.lastChild, this.mxGraph.cellEditor.textarea.lastChild.length);
	}

	headerContextMenuClicked(action: ContextMenuAction) {
		if (action === ContextMenuAction.MoveToFront) {
			this.orderSelectedCells(false);
		}

		if (action === ContextMenuAction.MoveToBack) {
			this.orderSelectedCells(true);
		}

		if (action === ContextMenuAction.FlipHorizontal) {
			this.mxGraph.toggleCellStyles(mx.mxConstants.STYLE_FLIPH, false, null);
		}

		if (action === ContextMenuAction.FlipVertical) {
			this.mxGraph.toggleCellStyles(mx.mxConstants.STYLE_FLIPV, false, null);
		}

		if (action === ContextMenuAction.Duplicate) {
			this.duplicateShape();
		}
	}

	get mxModel() {
		if (!this.mxGraph) {
			return null;
		}

		return this.mxGraph.getModel();
	}

	get mxSelectionModel() {
		return this.mxGraph.getSelectionModel();
	}

	get graphIsEmpty() {
		if (!this.mxModel) {
			return false;
		}

		return this.mxModel.getChildCells(this.mxGraph.getDefaultParent()).length === 0;
	}

	onImageSelected(id: number, height: number, width: number, url: string) {
		this.addImage(Math.round(width), Math.round(height), url, id);
		this.closeImageGallery();
	}

	closeImageGallery() {
		this.imageGalleryModal.close();
		this.imageGalleryModal = null;
	}

	openImageGallery() {
		imageGallery.load().then(ig => {
			const component = new ig({
				onClosed: () => {
					this.closeImageGallery();
				},
				onImageSelected: (id, height, width, url) => {
					this.onImageSelected(id, height, width, url);
				},
				selectMode: true,
			});

			const modal = new OldModal(new ReactRenderable(component), {
				allowClose: true,
				showHeader: false,
				showFooter: false,
				className: 'image-gallery-modal',
			});

			modal.load();

			this.imageGalleryModal = modal;
		});
	}

	removeCells(ids: string[]) {
		this.updateGraph(() => {
			let allCells = this.mxModel.getChildCells(this.mxGraph.getDefaultParent());
			let deletedCells = Enumerable.from(allCells).where((x: mxCell) => !!ids.find(c => c === x.id)).toArray();
			this.mxGraph.removeCells(deletedCells);
		});
	}

	get graphCells(): mxCell[] {
		let cells = [];
		for (let cell in this.mxModel.cells) {
			if (this.mxModel.cells[cell].geometry) {
				cells.push(this.mxModel.cells[cell]);
			}
		}
		return cells;
	}

	get notEmptyGraphCells() {
		let cells = this.graphCells.filter(mxCell => mxCell.type !== ShapeType.Text || (mxCell.type === ShapeType.Text && mxCell.value));
		if (cells.length > 0) {
			return cells.map(x => x.id);
		}

		return [];
	}

	deleteSelectedItems() {
		this.removeCells(this.selectedCells.map(x => x.id));
	}

	updateShapeStyle(cell: mxCell, graphStyle: IGraphStyle, type: ShapeType) {
		const style = this.shapeDefinitionService.convertGraphStyleToStrStyle(graphStyle, type);
		this.mxModel.setStyle(cell, style);
	}

	setShapeStyle(cell: mxCell, type: ShapeType, graphStyle: IGraphStyle) {
		cell.type = type;
		cell.style = this.shapeDefinitionService.convertGraphStyleToStrStyle(graphStyle, type);
	}

	orderSelectedCells(back: boolean) {
		this.updateGraph(() => {
			this.mxGraph.orderCells(back, this.selectedCells);
		});
	}

	duplicateShape() {
		mx.mxClipboard.copy(this.mxGraph, this.selectedCells);
		mx.mxClipboard.paste(this.mxGraph);
	}

	addImage(width: number, height: number, url: string, imageID: number) {
		this.updateGraph(() => {
			let size = this.shapeDefinitionService.getVertexSize(ShapeType.Image);
			if (size !== null) {
				width = size.width;
				height = size.height;
			}

			let position = this.shapeDefinitionService.getVertexPosition(ShapeType.Image);
			if (position === null) {
				position = this.shapeDefinitionService.getVertexCenterCoordinates(width, height);
			}

			let cell = this.mxGraph.insertVertex(this.mxGraph.getDefaultParent(), null, null, position.x, position.y, width, height);
			cell.imageID = imageID;

			let style = this.shapeDefinitionService.getStyle(ShapeType.Image) as ImageStyle;
			style.image = url;
			this.setShapeStyle(cell, ShapeType.Image, style);
			return cell;
		}, (cell) => {
			this.shapeDefinitionService.updateVertexSize(ShapeType.Image, cell.geometry.width, cell.geometry.height);
			this.shapeDefinitionService.updateVertexPosition(ShapeType.Image, cell.geometry.x, cell.geometry.y);
			this.mxSelectionModel.setCell(cell);
			let images = [...this.state.images];
			images.push({id: imageID, url: url});
			this.setState({images: images});
		});
	}

	addShape(shapeType: ShapeType) {
		let style = this.shapeDefinitionService.getStyle(shapeType);

		if (shapeType >= ShapeType.Line) {
			let edgePosition = this.shapeDefinitionService.getEdgePosition(shapeType);
			this.addEdge(shapeType, style, edgePosition[0].x, edgePosition[0].y, edgePosition[1].x, edgePosition[1].y);
		} else {
			let size = this.shapeDefinitionService.getVertexSize(shapeType);
			let vertexPosition = this.shapeDefinitionService.getVertexPosition(shapeType);

			if (shapeType === ShapeType.Text) {
				if (Settings.graphWidth - size.width < vertexPosition.x) {
					size.width = Settings.graphWidth - vertexPosition.x;
				}
			}

			this.addVertex(shapeType, style, size, vertexPosition.x, vertexPosition.y);
		}
	}

	addEdge(shapeType: ShapeType, style: IGraphStyle, x1, y1, x2, y2) {
		this.updateGraph(() => {
			let cell = this.mxGraph.insertEdge(this.mxGraph.getDefaultParent(), null, '', null, null, null);
			cell.geometry.setTerminalPoint(new mx.mxPoint(x1, y1), true);
			cell.geometry.setTerminalPoint(new mx.mxPoint(x2, y2), false);
			cell.geometry.relative = false;

			this.setShapeStyle(cell, shapeType, style);
			return cell;
		}, (cell) => {
			this.mxSelectionModel.setCell(cell);
		});
	}

	addVertex(shapeType: ShapeType, style: IGraphStyle, size: ShapeSize, x: number, y: number) {
		this.updateGraph(() => {
			let cell = this.mxGraph.insertVertex(this.mxGraph.getDefaultParent(), null, null, x, y, size.width, size.height);
			this.setShapeStyle(cell, shapeType, style);
			return cell;
		}, (cell) => {
			this.mxSelectionModel.setCell(cell);
			if (shapeType === ShapeType.Text) {
				this.mxGraph.startEditingAtCell(cell, null);
				this.addToEditingUndoRedo(cell.style, cell.value);
				this.mxGraph.cellEditor.textarea.focus();

				this.lastTextEditorID = +cell.id;
			}
		});
	}

	get selectedCells(): mxCell[] {
		if (!this.mxModel) {
			return [];
		}

		return this.mxSelectionModel.cells;
	}

	updateGraph(callback: any, afterCallback?: any) {
		let result;
		try {
			this.mxModel.beginUpdate();
			result = callback();
		} finally {
			this.mxModel.endUpdate();
		}

		if (afterCallback) {
			afterCallback(result);
		}
	}


	//#endregion
	render() {
		return <>{this.state.textIsTooLarge &&
		<Modal animate={true} className={'white-header responsive alert-modal-white'}>
			<ModalHeader/>
			<ModalBody>
				<span>The text is too large, please adjust the Font Size</span>
			</ModalBody>
			<ModalFooter>
				<button className='btn btn-primary btn-transparent'
						onClick={() => {
							this.setState({textIsTooLarge: false});
							this.mxGraph.cellEditor.textarea.focus();
							this.canvasInFocus = true;
						}}>OK
				</button>
			</ModalFooter>
		</Modal>}
			<div
				className={this.props.isWhiteBackground ? 'mx-graph-canvas-container white' : 'mx-graph-canvas-container black'}>
				{this.graphIsEmpty &&
				<div className='mx-graph-canvas-placeholder'>DOUBLE CLICK TO ADD TEXT</div>}
				<CanvasHeader
					shapeType={this.state.shapeType}
					contextMenuClicked={(action) => this.headerContextMenuClicked(action)}
					showUndoRedo={this.state.showUndoRedo}
					testName={this.props.testName}
					questionNumber={this.props.questionNumber}
					selectedCells={this.selectedCells.map(x => x.id)}
					onDeleted={() => this.deleteSelectedItems()}
					onUndo={() => this.undo()}
					onRedo={() => this.redo()}
				/>
				<div
					className={this.props.isWhiteBackground ? 'mx-graph-canvas white' : 'mx-graph-canvas black'}
					ref={(ref) => this.canvasRef = ref}/>
			</div>
		</>;
	}
}

export namespace Events {
	export function SaveAndClose(xml: string, changed: boolean, definitions: ShapeDefinitions): Closed.Args {
		return {
			xml,
			changed,
			definitions,
		};
	}

	export namespace SaveAndClose {
		export interface Args {
			xml: string,
			changed: boolean;
			definitions: ShapeDefinitions;
		}
	}

	export function SaveAndNext(xml: string, changed: boolean, definitions: ShapeDefinitions): Closed.Args {
		return {
			xml,
			changed,
			definitions,
		};
	}

	export namespace SaveAndNext {
		export interface Args {
			xml: string,
			changed: boolean;
			definitions: ShapeDefinitions;
		}
	}

	export function Close(xml: string, changed: boolean, definitions: ShapeDefinitions): Closed.Args {
		return {
			xml,
			changed,
			definitions,
		};
	}

	export namespace Closed {
		export interface Args {
			xml: string,
			changed: boolean;
			definitions: ShapeDefinitions;
		}
	}
}

