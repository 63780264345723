import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, Observable} from 'rxjs';
import {createLanguagesAndTemplatesForm} from './form';
import {languagesAndTemplatesMock, defaultBodyText} from './test-mocks';
import {LanguageAndTemplate, Template} from './types';
import {isEqual} from 'underscore';

export class LanguagesAndTemplatesService extends BaseService {
	public form = createLanguagesAndTemplatesForm();
	public languagesData = new BehaviorSubject<LanguageAndTemplate[]>([]);
	public initialData = new BehaviorSubject<LanguageAndTemplate>(null);
	public selectedLanguage = new BehaviorSubject<LanguageAndTemplate>(languagesAndTemplatesMock[0]);

	public init = (languagesData: LanguageAndTemplate[]) => {
		this.languagesData.next(languagesData);
		this.selectedLanguage.next(languagesData[0]);
		this.initialData.next(languagesData[0]);

		this.languagesSubscribe();
	};

	public save = (): Observable<{ save: boolean }> => {
		return new Observable(observer => {
			observer.next({save: true});
			observer.complete();
		});
	};

	public changeSelectedLanguageData = (id: number) => {
		const selected = this.languagesData.value.find(item => item.id === id);
		this.selectedLanguage.next(selected);
		this.initialData.next(selected);
		this.languagesSubscribe();
	};

	public addNewLanguage = (name: string) => {

		const template: Template = {
			name,
			letterTemplateID: 0,
			body: defaultBodyText,
			isDefault: false,
		};

		const newLanguageAndTemplate: LanguageAndTemplate = {
			id: this.languagesData.value.length + 1,
			name,
			template,
		};

		if(!(this.languagesData.value.find(el=>el?.name===newLanguageAndTemplate?.name))){
			this.languagesData.next([
				...this.languagesData.value,
				newLanguageAndTemplate,
			]);
		}
	};

	public compareStatesForEquality = () => {
		const initialState = {
			title: this.initialData.value.name,
			templateName: this.initialData.value.template.name,
			message: this.initialData.value.template.body,
		};

		return !isEqual(initialState, this.form.value);
	};

	private languagesSubscribe(){
		this.selectedLanguage.subscribe((language) => {
			const selectedLanguage = this.languagesData.value.find(l => l.id === language.id);
			this.form.value = {
				title: selectedLanguage.name,
				message: selectedLanguage.template.body,
				templateName: selectedLanguage.template.name,
			};
		});
	}
}
