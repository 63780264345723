import React from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onOk: () => void;
	message: string;
}

export function ValidateAlert({onOk, message}: Props): JSX.Element {
	const modalManager = useModal();
	const confirm = useCloseModal(modalManager, onOk);

	return <Alert modalManagerRef={modalManager}>
		<Alert.Body>
			{message}
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={confirm}>
				Ok
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}