import {AssignmentCard} from '@esgi/main/kits/assignments';
import {ComponentProps} from 'react';
import {isNull} from 'underscore';

export function getBodyCardDate({
	posted,
	completed,
	created,
}: {
	posted: string | null;
	created: string;
	completed: string | null;
}): ComponentProps<typeof AssignmentCard.Body>['cardDate'] {
	if (!isNull(completed)) {
		return {
			date: completed,
			dateTranscript: 'Completed',
		};
	}

	if (!isNull(posted)) {
		return {
			date: posted,
			dateTranscript: 'Posted',
		};
	}

	return {
		date: created,
		dateTranscript: 'Created',
	};
}
