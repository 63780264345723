import {useEffect, useState} from 'react';
import {Checkbox, Input} from '@esgillc/ui-kit/control';
import {Field} from 'modules/subject-details/types';
import {SearchIcon} from './search-icon';
import styles from './styles.module.less';

class Props {
	values: Field[] = [];
	selectedValues: Field[] = [];
	searchPlaceholder?: string;
	dropdownLabel: string;
	onSelect: (values: Field[]) => void;
}

export default function SearchableList({
	values,
	selectedValues,
	searchPlaceholder,
	dropdownLabel,
	onSelect,
}: Props) {

	const [filterText, setFilterText] = useState('');
	const [value, setValue] = useState(false);

	const getAllLabel = (): string => {
		let base = 'All';
		if (selectedValues.length) {
			base += ' [' + selectedValues.length + ']';
		}
		return base;
	};

	const search = (text: string): Field[] => {
		if (!text) {
			return values;
		}
		return values.filter(x => x.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()));
	};

	const toggleAll = (): void => {
		let out = [];
		if (selectedValues.length !== values.length) {
			out = [...values];
		}
		onSelect(out);
	};

	const toggleItem = (item: Field): void => {
		if (selectedValues.find(i => i.id === item.id)) {
			onSelect([...selectedValues.filter(i => i.id !== item.id)]);
		} else {
			onSelect([...selectedValues, item]);
		}
	};

	useEffect(() => {
		setValue(selectedValues.length === values.length);
		if (selectedValues.length !== values.length && selectedValues.length !== 0) {
			setValue(undefined);
		}
	}, [selectedValues]);

	const renderList = (): JSX.Element | JSX.Element[] => {
		const out = search(filterText).map(x => {
			return <div data-cy='list-item' className={styles.listItem} key={x.id} onClick={(e) => {
				e.preventDefault();
				toggleItem(x);
			}}>
				<Checkbox
					checked={selectedValues.some(c => c.id === x.id)}
					className={styles.checkbox}
				>{x.name}</Checkbox>
			</div>;
		});
		if (!out.length && filterText) {
			return <div className={styles.emptyResult}>No such {dropdownLabel.toLocaleLowerCase()}</div>;
		}
		return <div className={styles.listContainer}>{out}</div>;
	};

	return <div data-cy='searchable-list' className={styles.searchableList}>
		<div className={styles.list}>
			<div className={styles.listInner}>
				<span data-cy='label'>{dropdownLabel}</span>
				<div className={styles.search}>
					<Input value={filterText}
								 onChange={(e) => setFilterText(e.target.value)}
								 placeholder={searchPlaceholder}
					/>
					<SearchIcon className={styles.searchIcon} fill={'#828282'}/>
				</div>
				<div data-cy='items-controller' className={styles.listItem} onClick={(e) => {
					e.preventDefault();
					toggleAll();
				}}>
					<Checkbox
						checked={value}
						className={styles.allItems}
					>{getAllLabel()}</Checkbox>
				</div>
				{renderList()}
			</div>
		</div>
	</div>;
}
