import {RubricCreatedEvent} from 'modules/assets/tests/rubric/creator/events';
import moment from 'moment';
import {BehaviorSubject, concatMap, Observable, Subject} from 'rxjs';
import {debounceTime, map, takeUntil, tap} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {SortDirection, TestType} from '@esgi/core/enums';
import {EventBusManager} from '@esgillc/events';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import * as TestDetailsEvents from '../../test-details/events';
import {FilterModel, SearchResult, TestModel, TestResponse, TestsResponse} from './types';

function mapToTestModel(sourceModel: TestResponse): TestModel {
	return {
		author: sourceModel.creatorName,
		date: moment(sourceModel.createDate).format('MM/DD/YYYY'),
		id: sourceModel.testID,
		type: TestType[sourceModel.type],
		name: sourceModel.name,
		questions: sourceModel.numberOfQuestions,
		color: '#' + sourceModel.color,
	};
}

export default class SearchService extends BaseService {
	public readonly pageSize: number = 20;
	public pageIndex: number = 1;

	public filter: BehaviorSubject<FilterModel> = new BehaviorSubject<FilterModel>({
		sortBy: 'None',
		sortDirection: SortDirection.Desc,
		contentAreaId: -1,
		scope: 'alltests',
		keyword: '',
	});

	public searchResult: BehaviorSubject<SearchResult> = new BehaviorSubject<SearchResult>({
		tests: [],
		canLoadMore: false,
		pageIndex: 1,
	});

	private readonly applyFilterDelayed: Subject<Partial<FilterModel>> = new Subject<Partial<FilterModel>>();
	private readonly eventBusManager: EventBusManager = new EventBusManager();

	constructor(private hierarchy: HierarchySnapshot) {
		super();
		this.eventBusManager.subscribe(TestDetailsEvents.TestPublished, this.testPublishedEventHandler);
		this.eventBusManager.subscribe(TestDetailsEvents.TestChanged, (event) => {
			const {tests, canLoadMore, pageIndex} = this.searchResult.value;
			const {id, newName} = event;
			this.searchResult.next({
				tests: tests.map((item) => {
					if (item.id === id) {
						item.name = newName;
					}
					return item;
				}),
				canLoadMore,
				pageIndex,
			});
		});
		this.eventBusManager.subscribe(RubricCreatedEvent, this.rubricCreatedEventHandler);
		this.applyFilterDelayed.pipe(
			debounceTime(200),
			takeUntil(this.destroy$),
			concatMap(f => this.innerApplyFilter(f)),
		).subscribe(r => this.searchResult.next(r));
	}

	public applyFilter(changes: Partial<FilterModel>): void {
		this.applyFilterDelayed.next(changes);
	}

	public applySort(sortBy: string): void {
		const prev = this.filter.value;
		let nextSortState: { sortDirection: SortDirection, sortBy: string };

		if (prev.sortBy === sortBy) {
			if (prev.sortDirection === SortDirection.Asc) {
				nextSortState = {
					sortBy: prev.sortBy,
					sortDirection: SortDirection.Desc,
				};
			} else {
				nextSortState = {
					sortBy: prev.sortBy,
					sortDirection: SortDirection.Asc,
				};
			}
		} else {
			nextSortState = {
				sortBy: sortBy,
				sortDirection: SortDirection.Asc,
			};
		}
		this.innerApplyFilter(nextSortState).subscribe(r => this.searchResult.next(r));
	}

	public loadMoreTests(): Observable<SearchResult> {
		this.pageIndex += 1;
		return this.fetchTests().pipe(tap(r => this.searchResult.next(r)));
	}

	private fetchTests(): Observable<SearchResult> {
		let {scope, contentAreaId, sortDirection, sortBy, keyword} = this.filter.value;
		let testType = 0;
		if(scope === 'rubric' || scope === 'score') {
			testType = scope === 'score' ? TestType.Score : TestType.Rubric;
			scope = 'alltests';
		}
		const pageIndex = this.pageIndex;
		return this.httpClient.ESGIApi.get<TestsResponse>('modules/home-add-test', 'search', {
			scope,
			contentAreaId: contentAreaId === -1 || contentAreaId === -2 ? undefined : contentAreaId,
			keyword,
			sortBy,
			sortDirection,
			itemsPerPage: this.pageSize,
			pageIndex,
			hierarchy: this.hierarchy,
			testType,
		}).pipe(map(r => {
			const tests = r.tests.map(mapToTestModel);
			return {
				tests: pageIndex === 1 ? tests : this.searchResult.value.tests.concat(tests),
				canLoadMore: tests.length === this.pageSize,
				pageIndex,
			} as SearchResult;
		})).asObservable();
	}

	private innerApplyFilter(changes: Partial<FilterModel>): Observable<SearchResult> {
		this.pageIndex = 1;
		const oldValue = this.filter.value;
		this.filter.next({...oldValue, ...changes});
		return this.fetchTests().pipe(takeUntil(this.destroy$));
	}

	private testPublishedEventHandler = (args: TestDetailsEvents.TestPublished.Args): void => {
		const newRow = {
			id: args.id,
			type: TestType[args.type],
			name: args.name,
			date: moment(args.createdDate).format('MM/DD/YYYY'),
			questions: args.questionsCount,
			author: args.author,
			color: args.color,
		} as TestModel;

		this.innerApplyFilter({
			sortDirection: SortDirection.Desc,
			sortBy: 'CreateDate',
			scope: 'mytests',
			contentAreaId: undefined,
			keyword: '',
		}).subscribe(r => this.searchResult.next({
			tests: [newRow, ...r.tests.filter(t => t.id !== args.id)],
			canLoadMore: r.canLoadMore,
			pageIndex: r.pageIndex,
		}));
	};

	private rubricCreatedEventHandler = (args: RubricCreatedEvent): void => {
		const newRow = {
			id: args.id,
			type: args.type,
			name: args.name,
			date: moment(args.createdDate).format('MM/DD/YYYY'),
			questions: args.questionsCount,
			author: args.author,
			color: args.color,
		} as TestModel;

		this.innerApplyFilter({
			sortDirection: SortDirection.Desc,
			sortBy: 'CreateDate',
			scope: 'mytests',
			contentAreaId: undefined,
			keyword: '',
		}).subscribe(r => this.searchResult.next({
			tests: [newRow, ...r.tests.filter(t => t.id !== args.id)],
			canLoadMore: r.canLoadMore,
			pageIndex: r.pageIndex,
		}));
	};

	public destroy() {
		super.destroy();
		this.eventBusManager.destroy();
	}
}
