import {Text} from '@esgi/ui/typography';
import {MeridiemButton, MeridiemButtonsContainer, MeridiemButtonsDivider} from './index.styled';
import {Meridiem} from '../../types';
import {MouseEvent, useCallback} from 'react';

type Props = {
	activeMeridiem: Meridiem;
	onMeridiemClick: (value: Meridiem) => void;
};

export function MeridiemButtons({activeMeridiem, onMeridiemClick}: Props) {
	const handleMeridiemButtomMouseDown = useCallback((event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	}, []);

	return (
		<MeridiemButtonsContainer>
			<MeridiemButton
				meridiem='AM'
				isActive={activeMeridiem === Meridiem.AM}
				onClick={() => onMeridiemClick(Meridiem.AM)}
				onMouseDown={handleMeridiemButtomMouseDown}
				dataCy='am-button'
			>
				<Text size='small'>AM</Text>
			</MeridiemButton>
			<MeridiemButtonsDivider />
			<MeridiemButton
				meridiem='PM'
				isActive={activeMeridiem === Meridiem.PM}
				onClick={() => onMeridiemClick(Meridiem.PM)}
				onMouseDown={handleMeridiemButtomMouseDown}
				dataCy='pm-button'
			>
				<Text size='small'>PM</Text>
			</MeridiemButton>
		</MeridiemButtonsContainer>
	);
}
