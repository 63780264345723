import {Container, Wrapper} from './index.styled';
import {BaseComponentProps, Search} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {SelectableList} from '@esgi/ui/layout';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {DropdownItem} from '../../service/types';
import {ElementStatus, ErrorTooltip, FormControl, FormElement} from '@esgi/ui/form';
import {Input} from '@esgi/ui/form-controls';
import {useCallback, useEffect, useRef, useState} from 'react';

type Props<T extends string = string> = Pick<BaseComponentProps, 'dataCy'> & {
	placeholder: string;
	items: DropdownItem[];
	controlElement: FormControl<T>;
	selectedEntityID: string;
	onSelect: (id: DropdownItem['id'], name?: string) => void
};

export function FormSearchableList({
	items,
	controlElement,
	selectedEntityID,
	placeholder,
	dataCy,
	onSelect,
}: Props) {
	const timeoutId = useRef<number>();
	const [inputFocused, setInputFocused] = useState(false);
	const inputRef = useRef<HTMLDivElement>(null);

	const onBlur = useCallback(() => {
		timeoutId.current = window.setTimeout(() => {
			setInputFocused(false);
		}, 300);
	}, []);

	const maxListHeight = inputRef.current
		? window.innerHeight - (inputRef.current.getBoundingClientRect().y + inputRef.current.getBoundingClientRect().height + 20)
		: 0;

	const errorMessage = placeholder === 'School'
		? 'Please enter a school or select \'My school isn\'t listed'
		: 'Please enter a district or select \'My school does not belong to a District';


	useEffect(() => () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}
	}, []);

	return (
		<Wrapper ref={inputRef}>
			<FormElement control={controlElement} >
				<Input.Iconable
					dataCy={dataCy}
					placeholder={placeholder}
					onFocus={() => setInputFocused(true)}
					onBlur={onBlur}
				>
					<Search/>
				</Input.Iconable>
				{controlElement.status === ElementStatus.invalid && <ErrorTooltip showOnError='required'>
					<Text size='xSmall' font='mono' color='red56'>
						{errorMessage}
					</Text>
				</ErrorTooltip>}
			</FormElement>
			<Container>
				{inputFocused && <OverlayScrollbarsComponent
          defer
          style={{height: maxListHeight || undefined}}
          options={{
						scrollbars: {autoHide: 'leave'},
						paddingAbsolute: true,
					}}
        >
          <SelectableList>
            <SelectableList.GroupRoot
              type='single'
              defaultValue={selectedEntityID}
            >
              <SelectableList.Group>
								{items.map(({id, value}) => (
									<SelectableList.Item
										value={String(id)}
										key={id}
										onClick={() => onSelect(id, value)}
									>
										<Text size='medium' bold>{value}</Text>
									</SelectableList.Item>
								))}
              </SelectableList.Group>
            </SelectableList.GroupRoot>
          </SelectableList>
        </OverlayScrollbarsComponent>}
			</Container>
		</Wrapper>
	);
}