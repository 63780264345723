import {BehaviorSubject, combineLatest, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {SubjectTab} from '@esgi/main/libs/store';
import {userStorage} from '@esgi/core/authentication';
import {V2AiAnalysisController, V2UserStateController} from '@esgi/contracts/esgi';
import {Test, StudentType, SubjectType} from '../types';
import {ReportTypeOption, LangTypeOption, TranslationModel} from '../model';
import {StudentService} from './student-service';
import {mapToEnum} from 'shared/utils';

export class AiAnalysisService extends BaseService {
	public readonly selectedStudent$ = new BehaviorSubject<StudentType | null>(
		null
	);
	public readonly selectedSubject$ = new BehaviorSubject<SubjectTab | null>(
		null
	);
	public readonly selectedStateSubjectId$ = new BehaviorSubject<number | null>(
		null
	);
	public readonly selectedStateSubjectLoaded$ = new BehaviorSubject<boolean>(false);
	public readonly selectedTests$ = new BehaviorSubject<Test[]>([]);

	public readonly tests$ = new BehaviorSubject<Test[]>([]);
	public readonly isTestsLoaded$ = new BehaviorSubject(false);
	public readonly isTestsLoading$ = new BehaviorSubject(false);

	public readonly report$ = new BehaviorSubject<string | null>(null);
	public readonly isReportLoaded$ = new BehaviorSubject<boolean>(false);
	public readonly isReportLoading$ = new BehaviorSubject<boolean>(false);

	public readonly activeTranslations$ = new BehaviorSubject<TranslationModel[]>(
		[]
	);
	public readonly isTranslationsLoading$ = new BehaviorSubject<boolean>(false);
	public readonly completedLangTypes$ = new BehaviorSubject<LangTypeOption[]>(
		[]
	);

	private readonly currentUser = userStorage.get();
	private readonly controller = new V2AiAnalysisController();
	private readonly stateController = new V2UserStateController();

	constructor(private studentService: StudentService) {
		super();

		this.completeOnDestroy(
			combineLatest([this.selectedSubject$, this.selectedStudent$])
		)
			.pipe(
				tap(([subject, selectedStudent]) => {
					this.handleGetTests(subject, selectedStudent);
				})
			)
			.subscribe();
			this.stateController.selectedSubjectInit()
			.subscribe({
				next: (result) => {
					this.selectedStateSubjectLoaded$.next(true);
					if(result) {
						this.selectedStateSubjectId$.next(result.subjectID);
					}
				},
			})
	}

	public get studentServiceData() {
		return {
			classes$: this.studentService.classes$,
			groups$: this.studentService.groups$,
			students$: this.studentService.students$,
		};
	}

	public setCompletedLangTypes(langTypes: LangTypeOption[]) {
		this.completedLangTypes$.next(langTypes);
	}

	public setSelectedStudent(studentId: number) {
		this.selectedStudent$.next(this.studentService.get(studentId));
	}

	public setSelectedSubject(subject: SubjectTab) {
		this.selectedSubject$.next(subject);
	}

	public setSelectedTests(testIds: string[]) {
		this.selectedTests$.next(testIds.map((item) => this.getTestById(item)));
	}

	public getTestById(testId: string) {
		return this.tests$.value.find((item) => String(item.id) === testId);
	}

	public handleClosePreview() {
		this.completedLangTypes$.next([]);
		this.report$.next(null);
		this.isReportLoaded$.next(false);
		this.isReportLoading$.next(false);
		this.isTranslationsLoading$.next(false);
	}

	public handleGenerateReport(
		selectedStudent: StudentType,
		selectedTests: Test[],
		reportType: ReportTypeOption
	) {
		if (!selectedStudent || !reportType) {
			return;
		}

		this.isReportLoaded$.next(false);
		this.isReportLoading$.next(true);
		this.completedLangTypes$.next([]);

		const request = {
			studentID: selectedStudent.id,
			testIDs: selectedTests.map((t) => t.id),
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
			reportType: reportType.value,
		};

		// TODO: code for debugging environment issues
		console.log('[START SCREENSHOT]');
		console.log('This is the request for generate report:');
		console.log('Student ID: ', request.studentID);
		console.log('Test IDs: ', request.testIDs.join(', '));
		console.log('Report Type: ', request.reportType);

		this.controller.generateReport(request).subscribe({
			next: (res) => {
				// TODO: code for debugging environment issues
				console.log('This is the response for generate report:');
				console.log(JSON.stringify(res));
				console.log('[END SCREENSHOT]');
				this.report$.next(res.value.report);
			},
			complete: () => {
				this.isReportLoaded$.next(true);
				this.isReportLoading$.next(false);
			},
		});
	}

	public async handleTranslate(
		text: string,
		completedLangTypes: LangTypeOption[],
		selectedLangTypes: LangTypeOption[]
	) {
		if (!text || !selectedLangTypes) {
			return [];
		}

		this.isTranslationsLoading$.next(true);

		const request = {
			text,
			languages: selectedLangTypes.map((lang) => lang.value),
		};

		// TODO: code for debugging environment issues
		console.log('[START SCREENSHOT]');
		console.log('This is the request for translate report:');
		console.log('Languages: ', request.languages.join(', '));

		this.controller.translateReport(request).subscribe({
			next: (res) => {
				// TODO: code for debugging environment issues
				console.log('This is the response for translate report:');
				console.log(JSON.stringify(res));
				console.log('[END SCREENSHOT]');

				this.activeTranslations$.next(res.value.translations);
			},
			complete: () => {
				this.completedLangTypes$.next([
					...completedLangTypes,
					...selectedLangTypes,
				]);
				this.isTranslationsLoading$.next(false);
			},
		});
	}

	public handleDownload(
		reportHTML: string,
		reportType: ReportTypeOption,
		student: StudentType
	) {
		const documentDate = new Date().toLocaleDateString('en-US');
		const fileName = `${reportType.label.replace(' ', '_')}-${
			student.firstName
		}_${student.lastName}-${documentDate}.pdf`;

		this.httpClient.ESGIApi.file(
			'/v2/modules/ai-analysis',
			'download-pdf',
			fileName,
			{
				html: `<!DOCTYPE html><html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></head><body>${reportHTML}</body></html>`,
			}
		).subscribe();
	}

	public override dispose() {
		this.controller.dispose();
	}

	private handleGetTests(
		selectedSubject: SubjectTab,
		selectedStudent: StudentType
	) {
		if (!selectedSubject || !selectedStudent) {
			return;
		}

		this.isTestsLoaded$.next(false);
		this.isTestsLoading$.next(true);

		this.controller
			.commonTestedTests({
				subjectID: selectedSubject.id,
				subjectType: mapToEnum(selectedSubject.type, SubjectType),
				globalSchoolYearID: this.currentUser.globalSchoolYearID,
				studentID: selectedStudent.id,
			})
			.subscribe({
				next: (res) => {
					this.tests$.next(res.value.tests);
				},
				complete: () => {
					this.isTestsLoaded$.next(true);
					this.isTestsLoading$.next(false);
				},
			});
	}
}
