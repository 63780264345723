import {FlexBox} from '@esgi/ui/layout';
import {Checkbox} from '@esgi/ui/controls';
import {Container, SettingName} from './index.styled';
import {Dispatch} from 'react';

interface Props<T> {
	value: T;
	setValue: Dispatch<T>;
	options: { label: string; value: T }[];
	settingName: string;
}

export function Setting<T>({value: checkedValue, setValue, options, settingName}: Props<T>) {

	return (
		<Container flow='row'>
			<SettingName size='medium'>{settingName}</SettingName>
			<FlexBox>
				{options.map(({label, value}) => (
					<Checkbox
						key={label}
						label={label}
						checked={checkedValue === value}
						onCheckedChange={() => setValue(value)}
					/>
				))}
			</FlexBox>
		</Container>
	);
}