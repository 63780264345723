import {BaseService} from '@esgi/core/service';
import {SubjectBelongsTo} from '../types';
import {SubjectLevel, subjectsStore, SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {BehaviorSubject, combineLatest, tap} from 'rxjs';
import {V2PersonalSubjectsController, V2TeachersPagesHomeSectionsSubjectsController} from '@esgi/contracts/esgi';

export class DataSubjectsService extends BaseService {
	public isLoadedData$ = new BehaviorSubject(false);

	public schoolSubjects$ = new BehaviorSubject<SubjectTab[]>([]);
	public districtSubjects$ = new BehaviorSubject<SubjectTab[]>([]);
	public personalSubjects$ = new BehaviorSubject<SubjectTab[]>([]);
	public allSubjects$ = new BehaviorSubject<SubjectTab[]>([]);

	private subjectStorage = subjectsStore();

	private readonly subjectsController = new V2TeachersPagesHomeSectionsSubjectsController();
	private readonly personalSubjectsController = new V2PersonalSubjectsController();

	constructor() {
		super();

		this.subjectStorage.get().subscribe(({data: subjects, loaded}) => {
			const filteredSubjects = subjects.filter(x => x.tests.length > 0);
			const schoolSubjects = filteredSubjects.filter(s => s.level === SubjectLevel.School);
			const districtSubjects = filteredSubjects.filter(s => s.level === SubjectLevel.District);
			const personalSubjects = filteredSubjects.filter(
				(s) =>
					(s.type === SubjectType.Stock || s.type === SubjectType.Personal) &&
					s.level === SubjectLevel.Teacher &&
					!s.hidden,
			);

			this.schoolSubjects$.next(schoolSubjects);
			this.districtSubjects$.next(districtSubjects);
			this.personalSubjects$.next(personalSubjects);

			if(loaded && !this.isLoadedData$.value) {
				this.isLoadedData$.next(loaded);
			}
		});

		this.completeOnDestroy(
			combineLatest([this.schoolSubjects$, this.personalSubjects$, this.districtSubjects$])
		).pipe(tap(([school, personal, district]) => {
			this.allSubjects$.next([...district, ...school, ...personal]);
		})).subscribe();
	}

	public getBelongToValue(subjectId: SubjectTab['id']): SubjectBelongsTo {
		const schoolSubjects = this.schoolSubjects$.value;
		const districtSubjects = this.districtSubjects$.value;
		const personalSubjects = this.personalSubjects$.value;

		const subjectsLists: {subjects: SubjectTab[]; belongsTo: SubjectBelongsTo}[] = [
			{
				subjects: schoolSubjects,
				belongsTo: 'school',
			},
			{
				subjects: districtSubjects,
				belongsTo: 'district',
			},
			{
				subjects: personalSubjects,
				belongsTo: 'personal',
			},
		];

		for (const {subjects, belongsTo} of subjectsLists) {
			if (subjects.find(({id}) => id === subjectId)) {
				return belongsTo;
			}
		}

		throw new Error('subjectId is not belong to any tab');
	}

	public override dispose() {
		this.subjectsController.dispose();
		this.personalSubjectsController.dispose();
	}
}
