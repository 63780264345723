import './form.less';
import {FormElement, FormField, IFormElement} from './elements';
import {FormFieldValidator, ValidationContainer, Validator} from './validation';

export interface IForm<T = any> extends IFormElement {

}

export class Form<T = any> extends FormElement implements IForm<T> {

	constructor() {
		super();

		$(this).on('disposed', () => {
			this.clearValidation(true);
		});
	}

	public fields = new Array<FormElement>();
	validations = new Array<ValidationContainer>();

	protected createField<T = any>(value?: T | KnockoutObservable<T>, ...validators: FormFieldValidator[]) {
    	let f = new FormField<T>(value);
    	f.validation.errorValidation(true);

    	this.addElement(f, ...validators);

    	return f;
	}

	protected addValidator(validator: Validator) {
    	this.validation.validators.push(validator);
	}
}

export interface ITypedForm<TIn, TOut = TIn> extends IForm<TIn> {
	serialize(): TOut;
}

export abstract class TypedForm<TIn, TOut = TIn> extends Form<TIn> implements ITypedForm<TIn, TOut> {
	constructor(public model?: TIn) {
		super();
	}

	abstract serialize(): TOut;
}

export class FormValidationContainer extends ValidationContainer {
	errorElement = this.element.rootElement;
}
