import {NotTested, MarkingPeriod, DisplayResults} from '../../service/types';

export const markingPeriodSettings = [
	{label: 'All', value: MarkingPeriod.All},
	{label: 'Current', value: MarkingPeriod.Current},
];

export const notTestedSettings = [
	{label: 'Zero', value: NotTested.Zero},
	{label: 'NT', value: NotTested.NT},
];

export const displayResultsSettings = [
	{label: 'Score', value: DisplayResults.Score},
	{label: 'Percent', value: DisplayResults.Percent},
];