import {styled} from '@esgi/ui/theme';
import {Alert} from '@esgi/ui/alert';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
});

export const OverlayScrollbarsComponentWrapper = styled(OverlayScrollbarsComponent, {
	width: '100%',
});
