import {Checkbox} from '@esgillc/ui-kit/control';
import {SectionWrapper} from '../../../components/section-wrapper';
import styles from './styles.module.less';

interface Props {
	showExpiredUsers: boolean;
	onShowExpiredUsersClickHandler: (showExpiredUsers: boolean) => void;
}

export default function TeacherSection({
	showExpiredUsers,
	onShowExpiredUsersClickHandler,
}: Props) {
	return <SectionWrapper title={'TEACHER'} className={styles.teacherSectionHeader}>
		<div data-cy='teacher-section' className={styles.teacherSection}>
			<div data-cy='enabled' className={styles.row}>
				<label className={styles.label}>Show Expired Users:</label>
				<Checkbox
					className={styles.checkbox}
					checked={showExpiredUsers}
					children='Enabled'
					onClick={() => onShowExpiredUsersClickHandler(!showExpiredUsers)}
				/>
			</div>
		</div>
	</SectionWrapper>;
}