import * as React from 'react';
import type {SVGProps} from 'react';

export function CorrectAnswer(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect width='16' height='16' rx='4' transform='matrix(0 -1 -1 0 16 16)' fill='none'/>
			<path d='M11.0687 5.5C10.8963 5.5 10.7165 5.56271 10.5845 5.70489L7.40619 9.16833C7.2331 9.35645 7.05865 9.32144 6.9226 9.10051L6.24908 8.00679C6.04231 7.67138 5.61126 7.57807 5.30212 7.80118C4.9923 8.02503 4.90611 8.49169 5.1122 8.82637L5.78571 9.92008C6.3939 10.9073 7.5786 11.0327 8.35381 10.1942L11.553 6.7534C11.8157 6.4683 11.8157 5.98999 11.553 5.70489C11.421 5.56271 11.2412 5.5 11.0687 5.5Z' fill='#333'/>
		</svg>
	);
}
