import {GroupModelForAdmin} from '../core/api';
import {HierarchyState} from './hierarchy-data-service';
import {
	GroupOfSpecialistsChangedEvent,
	GroupOfSpecialistsCreatedEvent,
	GroupOfSpecialistsRemovedEvent,
} from 'modules/forms/admin-group-forms/events';

export function applyGroupOfSpecialistsChanged(args: GroupOfSpecialistsChangedEvent, hierarchy: HierarchyState): HierarchyState {
	const {schoolSpecialists, districtSpecialists, groupsOfSpecialists} = hierarchy;

	updateSpecialists(schoolSpecialists, args);
	updateSpecialists(districtSpecialists, args);

	const item = groupsOfSpecialists.items.filter(s => s.groupID === args.groupId)[0];
	const newItem: GroupModelForAdmin = {...item, name: args.name};

	groupsOfSpecialists.items = groupsOfSpecialists.items.map(s => s.groupID === newItem.groupID ? newItem : s);
	return hierarchy;
}

function updateSpecialists(specialists, args: GroupOfSpecialistsChangedEvent) {
	const groupsOfSpecialists = specialists.filter(x => x.groups.some(c => c === args.groupId));
	const forRemove = groupsOfSpecialists.filter(x => args.specialists.every(c => c !== x.userID));
	const forAddIds = args.specialists.filter(x => groupsOfSpecialists.every(c => c.userID !== x));

	specialists.forEach(x => {
		if (forRemove.some(c => c.userID === x.userID)) {
			x.groups = x.groups.filter(x => x !== args.groupId);
		}
		if (forAddIds.some(c => c === x.userID)) {
			x.groups.push(args.groupId);
		}
	});
}

export function applyGroupOfSpecialistsCreated(args: GroupOfSpecialistsCreatedEvent, hierarchy: HierarchyState): HierarchyState {
	const {schoolSpecialists, districtSpecialists, groupsOfSpecialists} = hierarchy;

	createSpecialists(schoolSpecialists, args);
	createSpecialists(districtSpecialists, args);

	const group: GroupModelForAdmin = {
		groupID: args.groupId,
		userID: args.userID,
		name: args.name,
		orderNum: 0,
	};

	groupsOfSpecialists.items = groupsOfSpecialists.items.concat(group);

	return hierarchy;
}

function createSpecialists(specialists, args: GroupOfSpecialistsCreatedEvent) {
	specialists.forEach(x => {
		if (args.specialists.some(c => c === x.userID)) {
			x.groups.push(args.groupId);
		}
	});
}

export function applyGroupOfSpecialistsRemoved(args: GroupOfSpecialistsRemovedEvent, hierarchy: HierarchyState): HierarchyState {
	const {groupsOfSpecialists, specialist} = hierarchy;
	groupsOfSpecialists.items = groupsOfSpecialists.items.filter(s => s.groupID !== args.id);

	if (specialist.groupOfSpecialistsID === args.id) {
		specialist.groupOfSpecialistsID = 0;
	}
	return hierarchy;
}
