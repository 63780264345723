import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const ChartControlsWrapper = styled(FlexBox, {
	padding: '4px 0',
	marginBottom: 16,
	width: '100%',
});

export const ExpandIconContainer = styled(FlexBox, {
	borderRadius: 6,
	padding: 3,
	border: '1px solid $mild',
	boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(230, 232, 239, 0.24)',
	cursor: 'pointer',
});

export const Rect = styled('rect', {
	variants: {
		fill: {
			primaryVivid: {
				fill: '$primaryVivid',
			},
		},
	},
});

export const Path = styled('path', {
	variants: {
		fill: {
			secondary: {
				fill: '$secondary',
			},
		},
	},
});
