import {ChangeEvent} from 'react';
import {CameraIcon} from '@esgillc/ui-kit/icons';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	onChange: (event: ChangeEvent) => void;
}

const acceptFiles = ['.gif', '.jpg', '.jpeg', '.png', '.webp'];

export const ImageUpload = ({onChange}: Props) => (
	<label className={join('card', styles.cardUpload)}>
		<input
			type='file'
			accept={acceptFiles.join(', ')}
			multiple
			onChange={onChange}
		/>
		<div className={styles.upload}>
			<CameraIcon/>
			Add Image
		</div>
		<span>Drag and drop or click to upload</span>
	</label>
);
