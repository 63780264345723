import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import {CSS} from '@stitches/react';
import {Alert} from '@esgi/ui/alert';

export const avatarRootCss: CSS = {
	cursor: 'default',
};

export const AlertHeaderStyled = styled(Alert.Header, {
	'& > div': {
		gridTemplateColumns: '1fr auto',
	},
});

export const IconButton = styled(Button.Icon, {
	variants: {
		isEditMode: {
			true: {
				color: '$primary',
			},
		},
	},
});

export const HeaderDivider = styled(Box, {
	width: 1,
	height: '100%',
	borderRadius: 1,
	backgroundColor: '$border',
});
