import {Fragment, Suspense, useMemo, forwardRef} from 'react';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {getSummaryPieChartOptions} from './utils';
import {TestType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {SummarySelectors} from './summary.selectors';
import {IEPGoal} from './types';
import {SummaryProps} from './index';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import {hasIEPLogic} from '../../../common/utils';
import styles from './styles.module.less';

const AddSummaryNoteButton = lazyComponent(() => import('modules/assessments/testing/add-summary-notes/button'));
const AddIEPGoalButton = lazyComponent(() => import('modules/assessments/testing/iep-goal/button'));

type ChartProps = SummaryProps & {
	goal: IEPGoal;
	subject: Subject;
	testID: number;
	hierarchy: HierarchySnapshot;
}

export const SummaryChart = forwardRef<HTMLDivElement, ChartProps>((props: ChartProps, ref): JSX.Element => {
	const correctPercent = Math.round((props.correct * 100) / props.total);
	const incorrectPercent = 100 - correctPercent;
	const pieChart = useMemo(() => getSummaryPieChartOptions(
		props.testType,
		props.testResultsCorrectVerbiage,
		correctPercent,
		props.testResultsIncorrectVerbiage,
		incorrectPercent,
	), []);

	const renderButtons = () => (
		<div className={styles.actions}>
			<Suspense fallback={<Fragment/>}>
				{hasIEPLogic() && !props.goal &&
					<>
						<AddIEPGoalButton
							hierarchy={props.hierarchy}
							subject={props.subject}
							testID={props.testID}
						/>
						<span>|</span>
					</>
				}
				{props.testSessionID > 0 && <AddSummaryNoteButton
					testSessionID={props.testSessionID}
					note={props.note}
				/>}
			</Suspense>
		</div>
	);

	return (
		<div
			ref={ref}
			className={styles.container}
		>
			<div className={styles.chartsContainer}>
				<div
					className={styles.chart}
					data-cy={SummarySelectors.pieChart}
				>
					<HighchartsReact options={pieChart}/>
				</div>
				<div className={styles.legend}>
					<div className={styles.correct}>
						<div className={styles.percents}>{correctPercent} %</div>
						<div className={styles.title}>
							{props.testResultsCorrectVerbiage?.toLowerCase()}
						</div>
					</div>
					<div className={styles.incorrect}>
						<div className={styles.percents}>{incorrectPercent} %</div>
						<div className={styles.title}>
							{props.testResultsIncorrectVerbiage?.toLowerCase()}
						</div>
					</div>
				</div>
			</div>
			{props.testType === TestType.YN &&
				<div className={styles.data}>
					<div data-cy='score-label'>
						<span/>{props.testResultsCorrectVerbiage} answers: <span>{props.correct}</span>
						/
						<span>{props.total}</span>
					</div>
					{renderButtons()}
				</div>
			}
			{props.testType === TestType.Score &&
				<div className={styles.data}>
					<div data-cy='score-label'>
						Score: <span>{props.correct}</span>/<span>{props.total}</span>
					</div>
					{renderButtons()}
				</div>
			}
		</div>
	);
});
