import {ComponentPropsWithoutRef, forwardRef, useRef, useState} from 'react';
import Cropper, {CropperProps} from 'react-easy-crop';
import {BaseComponentProps} from '@esgi/ui';
import {useImageCropperContext} from '../../context';
import {EasyCropContainer} from './index.styled';

type ImageEasyCropperProps = {
	/** Specifies the shape of the cropping area. Default to 1 (rect).*/
	cropShape?: CropperProps['cropShape'];

	/** Aspect ratio of the cropping area (width / height). */
	aspect?: CropperProps['aspect'];
} & ComponentPropsWithoutRef<'div'> &
	BaseComponentProps;


const CROPPER_INITIAL_ZOOM_SPEED = 0.5;

export const ImageCropperCropper = forwardRef<
	HTMLDivElement,
	ImageEasyCropperProps
>(
	(
		{dataCy = 'ui-kit-image-cropper', aspect = 1, cropShape, ...props},
		ref
	) => {
		const {
			imageBase64,
			crop,
			zoom,
			minZoom,
			maxZoom,
			croppedAreaSize,
			onCropChange,
			onZoomChange,
			onChangeCroppedAreaPixels,
			step,
		} = useImageCropperContext();
		const zoomRef = useRef(zoom || 1);
		const cropperRef = useRef<Cropper | null>(null);
		const cropForFit = () => {
			const currentCrop = cropperRef?.current;
			if (currentCrop) {
				const {width, height} = currentCrop.imageRef.current!;
				const zoomX = croppedAreaSize.width / width;
				const zoomY = croppedAreaSize.height / height;
				const newZoom = Math.max(zoomX, zoomY);
				onZoomChange(newZoom);
			}
		};
		return (
			<EasyCropContainer dataCy={dataCy} ref={ref} {...props}>
				<Cropper
					ref={cropperRef}
					image={imageBase64 || ''}
					crop={crop}
					zoom={zoom}
					minZoom={minZoom}
					maxZoom={maxZoom}
					aspect={aspect}
					cropShape={cropShape}
					cropSize={croppedAreaSize}
					onCropChange={onCropChange}
					onZoomChange={(newZoom) => {
						onZoomChange?.(newZoom);
						zoomRef.current = newZoom;
					}}
					onCropComplete={(croppedArea, croppedAreaPixels) => {
						onChangeCroppedAreaPixels(croppedAreaPixels, zoomRef.current);
					}}
					onMediaLoaded={cropForFit}
					data-cy={`${dataCy}-easy-cropper`}
					zoomSpeed={step / CROPPER_INITIAL_ZOOM_SPEED}
				/>
			</EasyCropContainer>
		);
	}
);
