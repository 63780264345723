import {useEffect, useState} from 'react';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {useService} from '@esgi/core/service';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Loader} from '@esgillc/ui-kit/loader';
import {ProfileLoginInfo} from '../../components/login-info';
import {SecondaryProfileInfo} from './components/secodary-profile-info';
import {ProfileUserInfo} from '../../components/user-info';
import {Tabs} from './components/tabs';
import {TrackForm} from '../../components/track-form';
import {ManageAccount} from './components/manage-account';
import {BodyHeader} from './components/body-header';
import {TeacherProfileService} from './service';
import {SelectedTab} from './types';
import styles from './styles.module.less';

export interface Props {
	userID: number;
	onClose: () => void;
}

export default function TeacherProfile({onClose, userID}: Props) {
	const service = useService(TeacherProfileService);
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	const [isManageAccountOpen, setManageAccountOpen] = useState(false);
	const [selectedTab, setSelectedTab] = useState(SelectedTab.Primary);
	const [isLoading, setLoading] = useState(false);
	const [isInitializing, setInitializing] = useState(false);

	const states = useBehaviorSubject(service.states);
	const countries = useBehaviorSubject(service.countries);
	const tracks = useBehaviorSubject(service.tracks);
	const periods = useBehaviorSubject(service.periods);
	const schoolYearTypes = useBehaviorSubject(service.schoolYearTypes);
	const district = useBehaviorSubject(service.district);
	const hasSecondaryProfile = useBehaviorSubject(service.hasSecondaryProfile);
	const userInfo = useBehaviorSubject(service.userInfo);

	const {schoolName, canAllowRenewByCC, agreementLevelCode, notRenewable, expirationDate, isLinked} = userInfo;

	useEffect(()=>{
		if(hasSecondaryProfile){
			setSelectedTab(SelectedTab.Secondary);
		}
	}, [hasSecondaryProfile]);
	useEffect(() => {
		setInitializing(true);
		service.init(userID).subscribe(() => {
			setInitializing(false);
			service.userInfoForm.controls.country.onChanged.subscribe((v) => {
				if (v.reason !== 'value') {
					return;
				}

				setLoading(true);
				service.getStatesByCountryID(v.currState.value[0].id).subscribe({
					complete: () => setLoading(false),
				});
			});
		});
	}, []);

	const onSubmit = () => {
		setLoading(true);
		service.validateForms().subscribe((isValid) => {
			if (isValid) {
				(selectedTab === SelectedTab.Secondary ? service.updateSecondaryProfile() : service.updatePrimaryProfile())
					.subscribe(() => {
						setLoading(false);
						handleClose();
					});
			} else {
				setLoading(false);
			}
		});
	};

	return (
		<>
			<Loader show={isInitializing || isLoading} fullscreen/>
			{!isInitializing && <Modal modalManagerRef={modalRef}>
				<Modal.Header>
					<Title className={styles.title}>
						My Account
					</Title>
					<CloseIcon onClick={handleClose}/>
				</Modal.Header>

				<Modal.Body className={styles.body}>
					<BodyHeader
						onNameClick={() => setManageAccountOpen(true)}
						districtName={district.name}
						schoolName={schoolName}
						isLinked={isLinked}
						expirationDate={expirationDate}
						canAllowRenewByCC={canAllowRenewByCC}
						showRenewButton={!hasSecondaryProfile && !notRenewable}
					/>
					<Tabs selectedTab={selectedTab} onChange={setSelectedTab}
					      hasSecondaryProfile={hasSecondaryProfile}/>
					{selectedTab === SelectedTab.Primary && <>
						<ProfileUserInfo
							countries={countries}
							states={states}
							form={service.userInfoForm}
						/>
						<TrackForm
							districtID={district.id}
							isAbleToAddEdit={agreementLevelCode !== 'D' && !hasSecondaryProfile}
							schoolYearTypes={schoolYearTypes}
							tracks={tracks}
							periods={periods}
							form={service.trackInfoForm}
						/>
						<ProfileLoginInfo
							form={service.loginInfoForm}
						/>
					</>}
					{selectedTab === SelectedTab.Secondary
						&& <>
							<SecondaryProfileInfo form={service.secondaryProfileForm}/>
							<ProfileLoginInfo
								form={service.secondaryProfileLoginInfoForm}
								className={join(hasSecondaryProfile && styles.secondaryLoginInfo)}
							/>
							<div className={styles.hint}>* Secondary Account holders are associated with a primary
								account user and have a unique login profile.</div>
						</>
					}
				</Modal.Body>

				<Modal.Footer>
					<Buttons.Gray onClick={handleClose}>Cancel</Buttons.Gray>
					{(!hasSecondaryProfile || selectedTab === SelectedTab.Secondary) &&
						<Buttons.Contained onClick={onSubmit}>Save</Buttons.Contained>}
				</Modal.Footer>

				{isManageAccountOpen &&
					<ManageAccount defaultCountries={countries}
					               defaultStates={states}
					               schoolName={schoolName}
					               districtName={district.name}
					               selectedState={service.userInfoForm.controls.state.value[0]}
					               selectedCountry={service.userInfoForm.controls.country.value[0]}
					               onClose={() => setManageAccountOpen(false)}
					               onSaved={service.onManageAccountSaved}
					/>}

			</Modal>}
		</>
	);
}
