import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {BaseHttpClient} from '@esgi/api';
import {Disposable} from './types';
import {V2AssessmentsRubricController} from '@esgi/contracts/esgi';

/* The `Service` class is a TypeScript class that provides functionality for managing HTTP requests and
tracking their status. */
export class Service<T extends BaseHttpClient = BaseHttpClient> implements Disposable{
	public readonly busy: Observable<boolean>;
	protected readonly destroy$ = new Subject<void>();
	protected readonly httpClient: T;

	private readonly activeRequestsCount = new BehaviorSubject(0);
	private readonly summaryController = new V2AssessmentsRubricController();

	constructor() {
		this.httpClient = this.createHttpClient();

		this.httpClient.onRequestStart$.subscribe(() => {
			this.activeRequestsCount.next(this.activeRequestsCount.value + 1);
		});
		this.httpClient.onRequestEnd$.subscribe(() => {
			this.activeRequestsCount.next(this.activeRequestsCount.value - 1);
		});

		this.busy = this.activeRequestsCount.pipe(
			map((c) => !!c),
			distinctUntilChanged(),
			takeUntil(this.destroy$),
		);
	}

	public updateSummaryScore(testSessionID: number, summary: string) {
		this.summaryController.updateSummaryNote({testSessionID, note: summary}).subscribe();
	}

	public destroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.httpClient.destroy();
	}

	public dispose(): void {
		this.summaryController.dispose();
		this.destroy();
	}

	protected createHttpClient(): T {
		throw new Error(
			'createHttpClient() method must be implemented in derived classes.',
		);
	}

	protected completeOnDestroy<T>(source: Observable<T>): Observable<T> {
		return source.pipe(takeUntil(this.destroy$));
	}
}
