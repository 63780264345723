import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const IconWrapper = styled(Box, {
	width: 24,
	height: 24,
	position: 'absolute',
	top: 0,
	bottom: 0,
	right: 6,
	margin: 'auto',

	'& svg': {
		width: '100%',
		height: '100%',
	},

	'&[data-hovered=true]': {
		'& svg, & path': {
			fill: '$secondary',
		},
	},

	'&[data-disabled=true]': {
		'& svg, & path': {
			fill: '$muted',
		},
	},
});
