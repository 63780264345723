import React, {ReactNode, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import AppHistory from 'shared/tools/history/history';
import {useAuthorization} from 'root/routes/use-authorization';

export function RouteWrapper(props: {className?: string, title?: string, children: ReactNode, authorize?: boolean}) {
	AppHistory.navigateFn = useNavigate();

	useAuthorization(props.authorize);

	useEffect(() => {
		document.title = props.title || 'ESGI';
	}, []);

	return <div className={(props.className || '') + ' page-wrapper'}>
		{props.children}
	</div>;
}
