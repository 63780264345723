import {ReactElement, useCallback, ComponentPropsWithoutRef, forwardRef} from 'react';
import {TestSectionMode} from '../../../types';
import {ButtonIconable} from './button-iconable.styled';

type Props = {
	children: ReactElement<React.SVGProps<SVGSVGElement>>;
	activateMode: TestSectionMode;
	sectionMode: TestSectionMode;
	setSectionMode: (sectionMode: TestSectionMode) => void;
	permitted: boolean;
	dataCy?: string;
} & Omit<ComponentPropsWithoutRef<'button'>, 'onClick' | 'disabled'>;

export const SectionModeButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
	const {activateMode, children, sectionMode, setSectionMode, permitted, dataCy} = props;
	const handleSetSectionMode = useCallback(() => {
		setSectionMode(activateMode === sectionMode ? TestSectionMode.View : activateMode);
	}, [sectionMode, setSectionMode, activateMode]);

	const isButtonDisabled = (sectionMode !== TestSectionMode.View && sectionMode !== activateMode) || !permitted;
	const isButtonActive = sectionMode === activateMode;

	return (
		<ButtonIconable
			ref={ref}
			withBackgroundHover
			onClick={handleSetSectionMode}
			disabled={isButtonDisabled}
			active={isButtonActive}
			dataCy={dataCy}
		>
			{children}
		</ButtonIconable>
	);
});
