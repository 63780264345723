import {DragDropContext, DropResult, Droppable, DroppableId} from 'react-beautiful-dnd';
import {ListGroup, ListGroupProps} from '../list-group';
import {useCallback, useId} from 'react';

export type ListGroupDroppableProps<T> = Omit<ListGroupProps, 'onDragEnd'> & {
	/** An Id for Droppable Group. If prop is not passed, for generation droppableId used hook React.useId */
	droppableId?: DroppableId;

	/** Iterated items. Used for rearrange items. */
	droppableItems: T[];

	/** Function for update "droppableItems" after rearrange. */
	onDragEnd: (groupState: T[]) => void;
};

export function ListGroupDroppable<T>({
	dataCy = 'ui-kit-list-group-droppable',
	children,
	droppableId,
	droppableItems,
	onDragEnd,
	...props
}: ListGroupDroppableProps<T>) {
	const droppableOwnId = useId();

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return;
			}

			const items = [...droppableItems];

			const [removed] = items.splice(result.source.index, 1);

			if (removed) {
				items.splice(result.destination.index, 0, removed);
			}

			onDragEnd(items);
		},
		[droppableItems, onDragEnd],
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={droppableId ?? droppableOwnId}>
				{(provided) => (
					<ListGroup dataCy={dataCy} ref={provided.innerRef} {...provided.droppableProps} {...props}>
						{children}
						{provided.placeholder}
					</ListGroup>
				)}
			</Droppable>
		</DragDropContext>
	);
}
