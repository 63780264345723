import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const FormInfoBox = styled(GridBox, {
	width: '100%',
	gap: '$5',

	'& form': {
		'& > div': {
			marginBottom: 'unset',
		},
	},
});
