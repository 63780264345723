export const languages = [
	{
		value: 'English',
		name: 'English',
	},
	{
		value: 'Spanish',
		name: 'Spanish',
	},
	{
		value: 'Other',
		name: 'Other',
	},
];
