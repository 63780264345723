import React from 'react';
import styles from './description.module.less';

export class DescriptionEmptyView extends React.PureComponent {
	render() {
		return <div className={styles.box}>
			<div className={styles.innerBox}>
				<div className={styles.description}/>
			</div>
		</div>;
	}
}
