import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';

export const Wrapper = styled(Box, {
	padding: '$2',
	border: '1px solid $border',
	backgroundColor: '$vivid',
	borderRadius: '8px',
	marginTop: '$2',
	marginBottom: '$3',
});

export const Description = styled(Box, {
	variants: {
		disabled: {
			true: {
				color: '$neutral80',
			},
			false: {
				color: '$neutral40',
			},
		},
	},
});

export const Checkbox = styled(CheckboxUI, {
	justifyContent: 'flex-start',

	'& > span': {
		display: 'inline-flex',
	},
});
