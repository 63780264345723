import {SubjectType} from '@esgi/core/enums';

export enum ScreenType {
	None,
	SessionSelector,
	Flow,
	Summary,
}

export enum AlertType {
	None,
	SessionStarted,
	TestSessionStatus,
}

export type Summary = {correct: number, incorrect: number, total: number, testSessionID: number};

export type SubjectInfo = {
	id: number;
	name: string;
	type: SubjectType;
}
