import React from 'react';

export class Props {
	open: boolean;
	clicked: () => any;
}

export class CollapseButton extends React.Component<Props> {
	render() {
		return <a onClick={() => this.props.clicked()} className='collapse-button' href='#'><i
            className={'fa fa-caret-' + (this.props.open ? 'left' : 'right')}/></a>;
	}

}