import {useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, useCloseModal, useModal, Title} from '@esgillc/ui-kit/modal';
import {SelfAssessSection} from './components/self-assess-section/index';
import StudentScreenRow from './components/student-screen-row/index';
import styles from './styles.module.less';

interface Props {
	onClose: () => void;
	createShareSession: () => void;
	code: string;
	codeExpireTime: string;
	showSelfAssessOption: boolean;
	onSave: (showSelfAssessOption: boolean, lockScreen: boolean) => void;
	lockScreen: boolean;
}

export function StudentScreenModal({
	                                   onClose,
	                                   onSave,
	                                   lockScreen,
	                                   createShareSession,
	                                   code,
	                                   codeExpireTime,
	                                   showSelfAssessOption,
}: Props) {

	const modalRef = useModal();
	const handelClose = useCloseModal(modalRef, onClose);

	const [isShowSelfAssessOption, setShowSelfAssessOption] = useState<boolean>(showSelfAssessOption);
	const [isLockScreen, setLockScreen] = useState<boolean>(lockScreen);

	const onSubmit = () => {
		onSave(isShowSelfAssessOption, isLockScreen);
		handelClose();
	};

	return <Modal modalManagerRef={modalRef} onCatchError={handelClose}>
		<Modal.Header>
			<Title>Student-Testing Options</Title>
		</Modal.Header>

		<Modal.Body>
			<div data-cy='student-screen' className={styles.studentScreen}>
				<StudentScreenRow
					code={code}
					codeExpireTime={codeExpireTime}
					createShareSession={createShareSession}/>
				<hr/>
				<SelfAssessSection
					isLockScreen={isLockScreen}
					setLockScreen={setLockScreen}
					showSelfAssessOption={isShowSelfAssessOption}
					setShowSelfAssessOption={setShowSelfAssessOption}/>
			</div>
		</Modal.Body>

		<Modal.Footer className={styles.footer}>
			<Buttons.Gray onClick={handelClose}>Cancel</Buttons.Gray>
			<Buttons.Contained onClick={onSubmit}>Save</Buttons.Contained>
		</Modal.Footer>
	</Modal>;
}
