import {SightWordsSteps} from './types';
import React from 'react';
import StudentsStep from '../students/students-step';
import SightWordsTestSelectorStep from '../sight-words-test-selector/sight-words-test-selector-step';
import SightWordsSettingsStep from '../sight-words-settings/sight-words-settings-step';
import SightWordsPreviewStep from '../sight-words-preview/sight-words-preview-step';
import FinalStep from '../final/final-step';
import {SightWordsPrintSettings, StudentStepState, TemplateType} from '../../shared/types';
import {TestSelectorState} from '../sight-words-test-selector/types';
import {SightWordsReport} from '../../shared/templates/sight-words/types';
import {HierarchySnapshot} from 'modules/hierarchy/models';

interface Props {
	hierarchy: HierarchySnapshot;
	goToBack: () => void;
	close: () => void;
}

class State {
	currentStep: SightWordsSteps = SightWordsSteps.Students;
	studentState: StudentStepState;
	testSelectorState: TestSelectorState;
	printSettingsState: SightWordsPrintSettings;
	report: SightWordsReport;
}

export class SightWords extends React.PureComponent<Props, State> {
	private readonly totalStepsCount: number = 3;
	public override readonly state = new State();

	render() {
		return <>
			{
				this.state.currentStep === SightWordsSteps.Students &&
				<StudentsStep
					currentStep={1}
					totalStepsCount={this.totalStepsCount}
					hierarchy={this.props.hierarchy}
					selectedClassId={this.state.studentState?.classId}
					selectedGroupId={this.state.studentState?.groupId}
					selectedStudentIds={this.state.studentState?.students?.map(x => x.studentId)}
					goToNext={(data) => this.studentNext(data)}
					goToBack={this.props.goToBack}/>
			}
			{
				this.state.currentStep === SightWordsSteps.TestSelector &&
				<SightWordsTestSelectorStep
					currentStep={2}
					totalStepsCount={this.totalStepsCount}
					hierarchy={this.props.hierarchy}
					testSelectorState={this.state.testSelectorState}
					goToNext={(data) => this.testSelectorNext(data)}
					goToBack={() => this.testSelectorBack()}
					unitData={this.state.studentState}/>
			}
			{
				this.state.currentStep === SightWordsSteps.PrintSettings &&
				<SightWordsSettingsStep
					currentStep={3}
					totalStepsCount={this.totalStepsCount}
					unitData={this.state.studentState}
					printData={this.state.printSettingsState}
					testId={this.state.testSelectorState.selectedTestId}
					goToBack={(data) => this.printSettingsBack(data)}
					goToPreview={(data, report) => this.printSettingsPreview(data, report)}
					pdfDownloaded={(report) => this.pdfDownloaded(report)}/>
			}
			{
				this.state.currentStep === SightWordsSteps.Preview &&
				<SightWordsPreviewStep
					testId={this.state.testSelectorState.selectedTestId}
					unitData={this.state.studentState}
					settings={this.state.printSettingsState}
					goToBack={(data) => this.previewBack(data)}
					pdfDownloaded={() => this.pdfDownloaded(this.state.report)}/>
			}
			{
				this.state.currentStep === SightWordsSteps.Final &&
				<FinalStep
					goToBack={() => this.finalBack()}
					closeForm={this.props.close}
					templateType={TemplateType.SightWords}
					swReport={this.state.report}
					nwReport={null}/>
			}
		</>;
	}

	private studentNext(data: StudentStepState) {
		this.setState({
			currentStep: SightWordsSteps.TestSelector,
			studentState: data,
		});
	}

	private testSelectorNext(data: TestSelectorState) {
		this.setState({
			currentStep: SightWordsSteps.PrintSettings,
			testSelectorState: data,
		});
		if (this.state.testSelectorState != null
			&& this.state.testSelectorState.selectedTestId !== data.selectedTestId){
			this.setState({printSettingsState: null});
		}
	}

	private testSelectorBack() {
		this.setState({
			currentStep: SightWordsSteps.Students,
		});
	}

	private printSettingsBack(data: SightWordsPrintSettings) {
		this.setState({
			currentStep: SightWordsSteps.TestSelector,
			printSettingsState: data,
		});
	}

	private printSettingsPreview(data: SightWordsPrintSettings, report: SightWordsReport) {
		this.setState({
			currentStep: SightWordsSteps.Preview,
			printSettingsState: data,
			report: report,
		});
	}
	private pdfDownloaded(report: SightWordsReport) {
		this.setState({
			currentStep: SightWordsSteps.Final,
			report: report,
		});
	}

	private previewBack(data: SightWordsPrintSettings) {
		this.setState({
			currentStep: SightWordsSteps.PrintSettings,
			printSettingsState: data,
		});
	}

	private finalBack() {
		this.setState({currentStep: SightWordsSteps.PrintSettings});
	}
}
