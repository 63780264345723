import {ComponentProps, useCallback, useMemo} from 'react';
import {StudentModel} from '../../../../types';
import {HeaderCellId} from '../../../table-students/types';
import {TableStudentsHeader} from '../../../table-students';
import {StudentSort, useUser} from '@esgi/core/authentication';
import {SortDirection, getUniqValuesInData} from '@esgi/main/kits/assignments';

export function useStudentsState({
	students,
	sortStudentsByKey,
}: {
	students: StudentModel[];
	sortStudentsByKey(args: {key: keyof StudentModel; sortDirection: SortDirection}): void;
}) {
	const user = useUser();

	const disableSortingCells = useMemo<ComponentProps<typeof TableStudentsHeader>['disableSortingCells']>(() => {
		if (students.length <= 1) {
			return 'all';
		}

		const {firstName, lastName, joinedClassName, joinedGroupName, progress} = getUniqValuesInData({
			data: students,
			keys: ['firstName', 'lastName', 'joinedClassName', 'joinedGroupName', 'progress'],
		});

		return [
			user?.studentSort === StudentSort.FirstName && firstName.size <= 1 && HeaderCellId.Student,
			user?.studentSort === StudentSort.LastName && lastName.size <= 1 && HeaderCellId.Student,
			joinedClassName.size <= 1 && HeaderCellId.Class,
			joinedGroupName.size <= 1 && HeaderCellId.Group,
			progress.size <= 1 && HeaderCellId.TestsTaken,
		].filter<HeaderCellId>((value): value is HeaderCellId => !!value);
	}, [students, user]);

	const sortStudentsData = useCallback(
		({sortDirection, sortableCellId}: {sortableCellId: HeaderCellId; sortDirection: SortDirection}) => {
			if (sortableCellId === HeaderCellId.Credentials) {
				return;
			}

			const studentKeyByHeaderCellId: Record<Exclude<HeaderCellId, HeaderCellId.Credentials>, keyof StudentModel> = {
				[HeaderCellId.Student]: user?.studentSort === StudentSort.FirstName ? 'firstName' : 'lastName',
				[HeaderCellId.Class]: 'joinedClassName',
				[HeaderCellId.Group]: 'joinedGroupName',
				[HeaderCellId.TestsTaken]: 'progress',
			};

			sortStudentsByKey({
				key: studentKeyByHeaderCellId[sortableCellId],
				sortDirection,
			});
		},
		[sortStudentsByKey, user],
	);

	return {
		disableSortingCells,
		sortStudentsData,
	};
}
