import React, {ReactNode} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../header.module.less';

interface Props {
	className?: string;
	tooltip?: string;
	title: ReactNode;
	stepLogo: ReactNode;
	stepIcon: ReactNode;
}

export default class StepIcon extends React.PureComponent<Props> {
	public render() {
		return <div className={join(styles.step, this.props.className)}>
			<div className={styles.stepLogo}>{this.props.stepLogo}</div>
			<OnHoverTooltip message={this.props.tooltip}>
				<div className={styles.stepIcon}>
					{this.props.stepIcon}
				</div>
			</OnHoverTooltip>
			<div className={styles.stepTitle}>
				{this.props.title}
			</div>
		</div>;
	}
}
