import {ButtonIconable} from './button-iconable.styled';
import {SectionModeButtonItem} from '../types';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

type Props = SectionModeButtonItem & {
	dataCy?: string;
};

export function SectionModeButton({Icon, permitted, isActive, onClick, tooltip, dataCy = 'section-mode-button'}: Props) {
	return (
		<Tooltip>
			<Tooltip.Trigger>
				<ButtonIconable withBackgroundHover onClick={onClick} disabled={!permitted} active={isActive} data-cy={dataCy}>
					<Icon data-cy={`${dataCy}-icon`}/>
				</ButtonIconable>
			</Tooltip.Trigger>
			{tooltip && <Tooltip.Content variant='secondary'>
				<Text>{tooltip}</Text>
			</Tooltip.Content>}
		</Tooltip>
	);
}
