import {Alert, alertContentInlinePadding} from '@esgi/ui/alert';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$4',
});

export const Divider = styled(Box, {
	marginLeft: -alertContentInlinePadding,
	marginRight: -alertContentInlinePadding,
	borderRadius: 1,
	backgroundColor: '$neutral92',
	height: 1,
});

export const Dot = styled(Box, {
	width: 2.5,
	height: 2.5,
	backgroundColor: '$base',
	borderRadius: '50%',
});

export const ListItemBox = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gap: '$2',

	[`${Dot}`]: {
		position: 'relative',
		top: 6,
	},
});
