export class Props {
	strokeWidth: number;
	onSelect: (strokeWidth: number) => void;
}
import React from 'react';
import './stroke-selector.less';

export class StrokeSelector extends React.PureComponent<Props> {
	changeStrokeWidth(add: number) {
		let newValue = this.props.strokeWidth + add;
		newValue = newValue < 1 ? 1 : newValue;

		this.props.onSelect(newValue);
	}

	render() {
		return <div className='mx-question-editor-edge-stroke-selector'>
			<span>Stroke</span>
			<div className='stroke-selector-toolbars-container'>
				<svg width='31' height='30' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<line x1='2.62268e-07' y1='3' x2='31' y2='3' stroke='#828282' strokeWidth='6'/>
					<line x1='1.74846e-07' y1='12' x2='31' y2='12' stroke='#828282' strokeWidth='4'/>
					<line x1='8.74228e-08' y1='21' x2='31' y2='21' stroke='#828282' strokeWidth='2'/>
					<line x1='4.37114e-08' y1='29.5' x2='31' y2='29.5' stroke='#828282'/>
				</svg>
				<div className='arrows-container'>
					<div className='arrow-svg' onClick={() => this.changeStrokeWidth(1)}>
						<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M8.16663 6.41666L-4.19617e-05 6.41666L4.08329 -1.04904e-05L8.16663 6.41666Z'
								  fill='#828282'/>
						</svg>
					</div>
					<span className='stroke-width-value'>{this.props.strokeWidth}</span>
					<div className='arrow-svg' onClick={() => this.changeStrokeWidth(-1)}>
						<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M8.16663 0L-4.19617e-05 0L4.08329 6.41667L8.16663 0Z' fill='#828282'/>
						</svg>
					</div>
				</div>
			</div>
		</div>;
	}
}

