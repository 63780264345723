import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const Layout = styled(FlexBox, {
	justifyContent: 'space-between',
	width: '100%',
	borderBottom: '1px solid $mild',
	height: 72,
	padding: '18px 24px 18px 20px',
});

export const AddButton = styled(Button, {
	width: '32px',
	height: '32px',
	minWidth: `unset`,
	padding: 0,
	margin: 0,
	border: `none`,
	marginLeft: 24,
	display: 'flex',
	alignItems: 'center',

	'> svg': {
		margin: `0 !important`,
	},
});