import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const ActionButtons = styled(GridBox, {
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	alignItems: 'center',
	gap: '$3',
	variants: {
		isSmallScreen: {
			true: {
				justifyContent: 'space-between',
			},
			false: {
				justifySelf: 'end',
			},
		},
	},
});

export const ActionButtonsDivider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
});
