import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';

export type SecondaryProfileFormType = FormGroup<{ firstName: FormControl<string>, lastName: FormControl<string>, email: FormControl<string> },
	ControlValue<{
		firstName: FormControl<string>, lastName: FormControl<string>, email: FormControl<string>
	}>>


export const createSecondaryProfileForm = ():
	SecondaryProfileFormType => {
	return new FormGroup({
		firstName: new FormControl('', {validators: [Validators.required()]}),
		lastName: new FormControl('', {validators: [Validators.required()]}),
		email: new FormControl('', {validators: [Validators.required(), Validators.email()]}),
	});
};
