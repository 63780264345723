import {ListItem, CaptionText, Counter, CounterText} from './index.styled';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

export function SelectableLisItemEmptyContent() {
	return (
		<ListItem data-cy='test-session-info'>
			<CaptionText data-cy='test-session-student-name' size='small'>All</CaptionText>
			<Counter>
				<Tooltip>
					<Tooltip.Trigger>
						<CounterText data-cy='test-session-correct-answers' size='small' font='mono' bold type='correct'>0</CounterText>
					</Tooltip.Trigger>
					<Tooltip.Content>
						<Text size='small'>Correct Questions</Text>
					</Tooltip.Content>
				</Tooltip>
				<Tooltip>
					<Tooltip.Trigger>
						<CounterText data-cy='test-session-incorrect-answers' size='small' font='mono' bold type='incorrect'>0</CounterText>
					</Tooltip.Trigger>
					<Tooltip.Content>
						<Text size='small'>Incorrect Questions</Text>
					</Tooltip.Content>
				</Tooltip>
				<Tooltip>
					<Tooltip.Trigger>
						<CounterText data-cy='test-session-untested-answers' size='small' font='mono' bold type='untested'>0</CounterText>
					</Tooltip.Trigger>
					<Tooltip.Content>
						<Text size='small'>Skipped Questions</Text>
					</Tooltip.Content>
				</Tooltip>
			</Counter>
		</ListItem>
	);
}
