import {TestTypeService} from './test-type-service';
import {Observable} from 'rxjs';
import {
	DeleteYNNoteRequest,
	ResultRequest,
	ResultYnResponse, SaveYNNoteRequest,
	UpdateResponse,
	UpdateYNRequest,
} from '../types';

export class YesNoService extends TestTypeService {
	public load(request: ResultRequest): Observable<ResultYnResponse> {
		return this.httpClient.ESGIApi.get<ResultYnResponse>(
			this.controller,
			'yn-result',
			request,
		).asObservable();
	}

	public update(request: UpdateYNRequest): Observable<UpdateResponse> {
		return this.httpClient.ESGIApi
			.post<UpdateResponse>(this.controller, 'update-yn-session', request)
			.asObservable();
	}

	public saveNote(request: SaveYNNoteRequest): Observable<any> {
		return this.httpClient.ESGIApi
			.post(this.controller, 'add-yn-comment', request)
			.asObservable();
	}

	public deleteNote(request: DeleteYNNoteRequest): Observable<any> {
		return this.httpClient.ESGIApi
			.post(this.controller, 'delete-yn-comment', request)
			.asObservable();
	}
}
