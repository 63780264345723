export function AddTimeTemplate() {
	return <div className='add-time' data-bind='var: {step: $data}, afterRender: true'>
		<div data-cy='renewal-guide'><b>Please click ‘Next’ to continue to the Cart:</b></div>
		<table className='table table-hover products' data-cy='renewal-table'>
			<tbody>
			<tr data-cy='headers-row'>
				<th className='checkbox-column' data-cy='checkbox-column-header'>Renewal</th>
				<th className='services-column' data-cy='services-column-header'>Services</th>
				<th data-cy='old-expiration-date-column-header'>Expiration Date</th>
				<th data-cy='new-expiration-date-column-header'>New Expiration Date</th>
				<th data-cy='cost-column-header'>Cost</th>
			</tr>

			<ko data-bind='foreach: cart.products'>
				<tr data-cy='renewal-row'>
					<td className='checkbox-column' data-cy='service-checkbox'>
						<input name='form-field-checkbox' type='checkbox'
						       data-bind='checked: selected, visible: isPurchasable()'/>
					</td>
					<td className='services-column' data-cy='service-name'>
						<span data-bind='text: name'/>
					</td>
					<td data-bind="css: {'expired': expired}" data-cy='old-expiration-date'>
						<span data-bind="text: expirationDate.format('MM/DD/YY')"></span>
					</td>
					<td className='new-expiration-cell' data-cy='new-expiration-date'>
						<ko data-bind='if: selected() && isPurchasable()'>
							<span data-bind="text: newExpirationDate().format('MM/DD/YY ')"></span>
						</ko>
					</td>
					<td data-cy='renewal-cost'>
						$<span data-bind='text: price.toFixed(2)'></span>
					</td>
				</tr>
			</ko>
			</tbody>
		</table>
		<div className='clearfix'>
			<div className='time'>
				<div data-bind="css: { 'dropdown-hidden': !isTimeDropdownsVisible }" className='col1' data-cy='renewal-time-controls'>
					<span>Add</span>
					<ko data-bind='with: years'>
						<select id='years' className='form-control'
						        data-bind='value: value, options: [1,2,3]'></select> Year(s)
					</ko>
					<ko data-bind='with: months'>
						<select id='months' className='form-control'
						        data-bind='value: value, options: [0,1,2,3,4,5,6,7,8,9,10,11,12]'></select> Month(s)
					</ko>
				</div>
				<div className='col2' data-cy='renewal-total-price'>
					<div className='price'>Total: $<span data-bind='text: cart.price'></span></div>
				</div>
			</div>
		</div>
		<div className='clearfix' style={{marginTop: '15px'}}>
			<button className='btn btn-cancel pull-left' data-bind='click: view.cancel' data-cy='cancel-button'>Cancel</button>
			<button className='btn btn-warning pull-right' data-cy='next-button'
			        data-bind="click: view.next, css: {'disabled': !isAnyProductSelected}">Next
			</button>
		</div>
	</div>;
}
