import {BehaviorSubject, from, map, switchMap, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {EventBusManager} from '@esgillc/events';
import {getUser, UserInfo, UserType} from '@esgi/core/authentication';
import {
	Step,
	Validation,
	Notification,
	ConflictType,
	ValidationType,
	ValidationStatus,
	ValidStudentModel,
	ConflictStudentField,
	ConflictStudentModel,
	SaveConflictStudentModel,
	ServerConflictStudentModel,
	SaveConflictStudentsResponse,
} from 'shared/modules/student-roster-upload-tool/types';
import {StudentChangedEvent} from 'modules/forms/students-form/events';
import {StudentRosterToolEvents} from 'shared/modules/student-roster-upload-tool/events';
import {gradeLevels, languages} from './constants';
import {getUpdatedStudent, getUploadedStudent, parseStudentName} from './utils';


export class ConflictResolutionService extends BaseService {
	public recheckedStudents = new BehaviorSubject<ServerConflictStudentModel[]>(null);
	public conflictStudents = new BehaviorSubject<ConflictStudentModel[]>([]);
	public changedStudents = new BehaviorSubject<SaveConflictStudentModel[]>([]);
	public initialState = new BehaviorSubject<ConflictStudentModel[]>([]);
	public step = new BehaviorSubject<Step>(Step.ConflictResolution);
	public notification = new BehaviorSubject<Notification>(Notification.None);
	public selectedConflict = new BehaviorSubject<ConflictType>(ConflictType.None);
	public excludedStudentsToCheck = new BehaviorSubject<number[]>([]);
	public excludedStudentsToNamesCheck = new BehaviorSubject<number[]>([]);
	public excludedStudentsToLanguageCheck = new BehaviorSubject<number[]>([]);
	public completedWithExistsStudents = new BehaviorSubject<boolean>(false);
	public duplicatesSaved = new BehaviorSubject<boolean>(false);
	public uploadedStudentsCount = new BehaviorSubject<number>(null);
	public classID = new BehaviorSubject<number>(0);
	public schoolID = new BehaviorSubject<number>(0);
	public studentID = new BehaviorSubject<number>(null);
	public className = new BehaviorSubject<string>('');
	public isDuplicateIDConflict = new BehaviorSubject<boolean>(false);

	private specialistGroupID = new BehaviorSubject<number>(null);
	private isPA = getUser().userType === UserType.PA;
	private eventBus = new EventBusManager();
	private controller = this.isPA ? '/student-roster-upload-tool/pre-access' : '/student-roster-upload-tool/teacher';
	private context: UserInfo = getUser();

	constructor() {
		super();
		this.eventBus.subscribe(StudentChangedEvent, (args) => this.existingStudentChanged(args));
	}

	public init(classID: number, schoolID: number, students: ServerConflictStudentModel[], specialistGroupID: number) {
		this.classID.next(classID);
		this.schoolID.next(schoolID);
		this.specialistGroupID.next(specialistGroupID);
		const initialStudents = this.parseToViewStudents(students);
		this.conflictStudents.next(initialStudents);
		this.initialState.next(initialStudents);
		this.isDuplicateIDConflict.next(Boolean(this.getNotValidStudentIdn().length));
	}

	public getDuplicateNames = () => this.getStudents().filter(s => s.name.validation.type === ValidationType.Conflict);

	public reset = () => {
		this.changedStudents.next([]);
		this.excludedStudentsToNamesCheck.next([]);
		this.excludedStudentsToCheck.next([]);
		this.excludedStudentsToLanguageCheck.next([]);

		const uploadedStudents = [...this.conflictStudents.value];
		const students = this.initialState.value.map(s => {
			const student = {...s};
			const uploadedStudent = uploadedStudents.filter(es => es.id === student.id)[0];
			student.existingStudents = [...uploadedStudent.existingStudents];
			return student;
		});

		this.conflictStudents.next(students);
	};

	public onConflictTableClick = (validation: Validation) => this.selectedConflict.next(validation.conflictType);


	public onEdit = (students: ConflictStudentModel[], validation: Validation) => {
		this.conflictStudents.next(students);
		this.selectedConflict.next(validation.conflictType);
		this.isDuplicateIDConflict.next(Boolean(this.getNotValidStudentIdn().length));
	};

	public onSaveDuplicateNameClicked = () => {
		this.notification.next(Notification.None);
		return this.save(false);
	};

	public onDeleteDuplicateNamesClicked = () => {
		const students = this.conflictStudents.value.map(s => {
			const duplicate = this.getDuplicateNames().filter(x => x.id === s.id)[0];
			if (duplicate) {
				const ds = {...duplicate};
				ds.removed = true;
				return ds;
			} else {
				return {...s};
			}
		});

		this.notification.next(Notification.None);
		this.conflictStudents.next(students);
		return this.save(true);
	};


	public handleSave = () => {
		const students = this.conflictStudents.value.map(s => {
			const newStudent = {...s};
			if (s.studentIDN.validation.type === ValidationType.Conflict) {
				newStudent.studentIDN.validation.type = ValidationType.Full;
			}
			return newStudent;
		});

		this.conflictStudents.next(students);
		this.eventBus.dispatch(StudentRosterToolEvents.ShowValidation, null);

		const valid = this.validate();

		if (valid) {
			return this.save(true);
		} else {
			this.moveToConflictRow();
			return from([]);
		}
	};

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	private recheckConflicts = (students: SaveConflictStudentModel[], bySaveClicked: boolean) => {
		this.changedStudents.next([...students]);
		this.excludedStudentsToCheck.next([...this.excludedStudentsToCheck.value, ...this.conflictStudents.value.filter(s => s.removed).map(s => s.id)]);

		if (bySaveClicked) {
			const exStudentsToNamesCheck = this.getDuplicateNames().map(s => s.id);
			this.excludedStudentsToNamesCheck.next(this.excludedStudentsToNamesCheck.value.filter(s => !this.excludedStudentsToNamesCheck.value.some(x => x === s)));
			this.excludedStudentsToNamesCheck.next([...this.excludedStudentsToNamesCheck.value, ...exStudentsToNamesCheck]);

			const exStudentsToLanguageCheck = this.getStudents().filter(s => s.language.value === 'Other').map(s => s.id);
			this.excludedStudentsToLanguageCheck.next(this.excludedStudentsToLanguageCheck.value.filter(s => !exStudentsToLanguageCheck.some(x => x === s)));
			this.excludedStudentsToLanguageCheck.next([...this.excludedStudentsToLanguageCheck.value, ...exStudentsToLanguageCheck]);
		}


		return this.httpClient.ESGIApi.post<ServerConflictStudentModel[]>(this.controller, 'recheck-conflicts', {
			classID: this.classID.value,
			schoolID: this.schoolID.value,
			changedStudents: this.changedStudents.value,
			excludedStudentsToCheck: this.excludedStudentsToCheck.value,
			excludedStudentsToNamesCheck: this.excludedStudentsToNamesCheck.value,
			excludedStudentsToLanguageCheck: this.excludedStudentsToLanguageCheck.value,
		})
			.pipe(tap((r) => {
				this.recheckedStudents.next(r || []);
			}), map((r) => {
				return this.parseToViewStudents(r || []);
			}));
	};

	private parseToSaveStudents = (students: ConflictStudentModel[]) => {
		return students.map(s => {
			const student = new SaveConflictStudentModel();
			student.id = s.id;
			const names = parseStudentName(s.name.value);
			student.firstName = names[0];
			student.lastName = names[1];
			student.studentIDN = s.studentIDN.value;
			student.language = s.language.value;
			student.createDate = s.createDate;
			student.gradeLevel = s.gradeLevel;
			student.gender = s.gender;

			return student;
		});
	};

	private parseToViewStudents = (students: ServerConflictStudentModel[]) => {
		return students?.map(s => {
			return new ConflictStudentModel(
				s.id,
				s.schoolID,
				s.teacherID,
				s.gradeLevel,
				s.createDate,
				s.gender,
				false,
				s.existingStudents,
				new ConflictStudentField(
					s.studentIDN.value,
					new Validation(
						ValidationType[s.studentIDN.validationType],
						ConflictType[s.studentIDN.conflictType],
						s.studentIDN.validationMessage),
					ValidationType[s.studentIDN.validationType] !== ValidationType.None),

				new ConflictStudentField(
					s.name.value,
					new Validation(
						ValidationType[s.name.validationType],
						ConflictType[s.name.conflictType],
						s.name.validationMessage),
					ValidationType[s.name.validationType] !== ValidationType.None),

				new ConflictStudentField(
					s.language.value,
					new Validation(
						ValidationType[s.language.validationType],
						ConflictType[s.language.conflictType],
						s.language.validationMessage),
					ValidationType[s.language.validationType] !== ValidationType.None),
			);
		});
	};

	private existingStudentChanged = (args: StudentChangedEvent) => {
		const uploadedStudent = getUploadedStudent(this.getStudents(), args.studentID);
		const updatedStudent = getUpdatedStudent(uploadedStudent, args.studentID);

		updatedStudent.name = args.firstName + ' ' + args.lastName;
		let valid = uploadedStudent.existingStudents.every(s => s.name !== uploadedStudent.name.value);
		let editable = uploadedStudent.name.editable || !valid;

		uploadedStudent.name = new ConflictStudentField(
			uploadedStudent.name.value,
			new Validation(valid ? ValidationType.None : ValidationType.Conflict, valid ? ConflictType.None : ConflictType.DuplicateStudentName),
			editable);

		updatedStudent.studentIDN = args.studentIDN;
		valid = !uploadedStudent.studentIDN.value || uploadedStudent.existingStudents.every(s => s.studentIDN !== uploadedStudent.studentIDN.value);
		editable = uploadedStudent.studentIDN.editable || !valid;

		uploadedStudent.studentIDN = new ConflictStudentField(
			uploadedStudent.studentIDN.value,
			new Validation(
				valid ? ValidationType.None : ValidationType.Conflict,
				valid ? ConflictType.None : ConflictType.DuplicateStudentIDN,
				valid ? '' : 'ID must be modified or removed in order to save this student.'),
			editable);

		updatedStudent.gender = args.gender;
		updatedStudent.language = languages[args.languageID - 1];
		updatedStudent.gradeLevel = gradeLevels[args.gradeLevelID - 1];

		const students = this.getStudents().map((s) => {
			if (s.id === uploadedStudent.id) {
				const s = {...uploadedStudent};
				s.existingStudents = s.existingStudents.map((es) => {
					if (es.id === updatedStudent.id) {
						return {...updatedStudent};
					} else {
						return {...es};
					}
				});
				return s;
			} else {
				return {...s};
			}
		});

		this.recheckConflicts(this.parseToSaveStudents(this.conflictStudents.value), false).subscribe((recheckedStudents) => {
			const newConflicts = recheckedStudents.filter(s => !students.some(x => x.id === s.id));
			students.push(...newConflicts);
			this.conflictStudents.next(students);
		});

	};

	private studentsCreatedNotify(createdStudents: ValidStudentModel[]) {
		this.eventBus.dispatch(StudentRosterToolEvents.Uploaded, StudentRosterToolEvents.Uploaded(
			createdStudents,
			this.isPA ? this.schoolID.value : this.context.schoolID,
			this.context.userID,
			this.classID.value,
			this.context.districtID, this.specialistGroupID.value,
		));
	}

	private resolveConflicts = (duplicatesSaved: boolean) => {
		const form = new FormData();
		if (this.isPA) {
			form.append('schoolID', this.schoolID.value.toString());
			form.append('specialistGroupID', this.specialistGroupID.value.toString());
		} else {
			form.append('classID', this.classID.value.toString());

		}

		return this.httpClient.ESGIApi.post<SaveConflictStudentsResponse>(this.controller, 'resolve-conflicts', form).pipe(tap((res) => {
			const status = ValidationStatus[res.validation.status];
			this.step.next(Step.None);
			console.log(res);
			this.uploadedStudentsCount.next(res.createdStudents.length);

			this.notification.next(Notification.FileUploadIsComplete);
			if (status === ValidationStatus.Complete) {
				this.completedWithExistsStudents.next(true);
			}

			if (duplicatesSaved) {
				this.duplicatesSaved.next(true);
			}

			if (res.createdStudents.length > 0) {
				this.studentsCreatedNotify(res.createdStudents);
			}
		}));
	};
	private getStudents = () => this.conflictStudents.value?.filter(s => !s.removed) || [];

	private getNotValidFillName = () => this.getStudents().filter(x => x.name.validation.type === ValidationType.Standard);

	private getNotValidStudentIdn = () => this.getStudents().filter(x => x.studentIDN.validation.type > ValidationType.None);

	private getNotValidDuplicateName = () => this.getStudents().filter(x => x.name.validation.type >= ValidationType.Conflict);

	private moveToConflictRow = () => {
		const firstIdnError = this.getNotValidStudentIdn().length > 0 ? this.getNotValidStudentIdn()[0] : null;
		const firstDuplicateNameError = this.getNotValidDuplicateName().length > 0 ? this.getNotValidDuplicateName()[0] : null;
		const firstFillNameError = this.getNotValidFillName().length > 0 ? this.getNotValidFillName()[0] : null;

		let errorStudentID = 0;
		if (firstDuplicateNameError) {
			errorStudentID = firstDuplicateNameError.id;
		}
		if (firstFillNameError) {
			errorStudentID = firstFillNameError.id;
		}
		if (firstIdnError) {
			errorStudentID = firstIdnError.id;
		}
		this.eventBus.dispatch(StudentRosterToolEvents.MoveToErrorRow, StudentRosterToolEvents.MoveToErrorRow(errorStudentID));
		return from([]);
	};

	private validate = () => {
		if (this.getNotValidStudentIdn().length > 0 || this.getNotValidFillName().length > 0) {
			return false;
		}

		if (this.getNotValidDuplicateName().length > 0) {
			this.notification.next(Notification.SaveDuplicateName);
			return false;
		}
		return true;
	};

	private save = (duplicatesDeleted?: boolean) => {
		const students = this.parseToSaveStudents(this.getStudents());
		return this.recheckConflicts(students, true).pipe(switchMap((recheckStudents) => {
			if (recheckStudents?.length === 0) {
				return this.resolveConflicts(!duplicatesDeleted);
			}
			this.conflictStudents.next(recheckStudents);
			this.isDuplicateIDConflict.next(Boolean(this.getNotValidStudentIdn().length));

			return from([]);
		}));
	};
}
