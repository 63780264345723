import {useMemo, useState} from 'react';
import {CloseIcon, Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {getUser, UserType} from '@esgi/core/authentication';
import {General} from './components/tabs/general';
import {Location} from './components/tabs/location';
import {Additional} from './components/tabs/additional';
import {TabsController} from './components/tabs-controller';
import {UploadPhotoModal} from '../../components/upload-photo-modal';
import {ModalTitle} from './components/modal-title';
import {Avatar} from '../../components/avatar';
import {CloseActionConfirmModal} from 'modules/forms/students-form/components/close-action-confirm-modal';
import {useTabsApi} from 'modules/forms/students-form/components/profile-modal/use-tabs-api';
import {SaveWithoutClassConfirmModal} from 'modules/forms/students-form/components/save-without-class-confirm-modal';
import {DeleteStudentAlert} from 'modules/forms/students-form/components/delete-student-alert';
import {ImageResponse, PreSelected, ProfileInitData, StudentProfileMode, StudentProfileTab} from '../../types';
import {useLayoutContext} from 'shared/page/layout-context';
import styles from './styles.module.less';

interface Props {
	onClose: () => void;
	mode: StudentProfileMode;
	initData: ProfileInitData;
	studentID: number;
	preSelected: PreSelected;
	onImageChanged: (data: ImageResponse) => void;
	onDeleteStudent: () => void;
	setUploadRosterModalOpen: (v: boolean) => void;
}

export function ProfileModal({
	onClose,
	mode,
	initData,
	studentID,
	onImageChanged,
	onDeleteStudent,
	preSelected,
	setUploadRosterModalOpen,
}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const currentUser = getUser();
	const currentUserType = currentUser.userType;
	const [isUploadModalOpen, setUploadModalOpen] = useState(false);
	const [isDeleteStudentModalOpen, setDeleteStudentModalOpen] = useState(false);
	const tabsController = useTabsApi(initData);
	const {
		tabsApi,
		selectedTab,
		setSelectedTab,
		isConfirmModalOpen,
		onConfirmModalClose,
		onConfirmModalSave,
		onConfirmModalCancel,
		handleCloseAction,
		isConfirmSaveWithoutClassModalOpen,
		onSaveWithoutClassCancel,
		onSaveWithoutClassConfirm,
		onSaveWithoutClassClose,
		isTabsDisabled,
		handleChangeTabAction,
	} = tabsController;

	const {mainRef} = useLayoutContext();

	const handleTabsClose = () => {
		handleCloseAction(() => {
			handleClose();
		});
	};

	const renderTabs = useMemo(() => {
		switch (selectedTab) {
			case StudentProfileTab.General:
				return <General
					onClose={handleTabsClose}
					tabsController={tabsController}
					preSelected={preSelected}
					mode={mode}
					studentID={studentID}
					initData={initData}
				/>;
			case StudentProfileTab.Location:
				return <Location
					onClose={handleTabsClose}
					tabsApi={tabsApi}
					preSelected={preSelected}
					mode={mode}
					studentID={studentID}
					initData={initData}
				/>;
			case StudentProfileTab.Additional:
				return <Additional
					onClose={handleTabsClose}
					tabsApi={tabsApi}
					preSelected={preSelected}
					mode={mode}
					studentID={studentID}
					initData={initData}
				/>;
		}
	}, [selectedTab, preSelected, mode, initData]);

	const showEditDisabledText = selectedTab === StudentProfileTab.Additional ? !currentUser.canEditStudentMetaFields : mode === StudentProfileMode.view;
	const isShowUploadRosterButton = useMemo(
		() => {
			if (currentUserType === UserType.T && preSelected?.classID > 0) {
				return true;
			}
			if (preSelected?.specialistGroupID > 0) {
				if (currentUserType === UserType.PA) {
					return true;
				}
				if (currentUserType === UserType.D && preSelected?.userType === UserType.PA) {
					return true;
				}
			}

			return false;
		},
		[currentUserType, preSelected?.classID, preSelected?.specialistGroupID],
	);

	const isShowDeleteStudentButton = useMemo(
		() => currentUserType === UserType.D || currentUserType === UserType.C || currentUser.agreementLevelCode === UserType[UserType.T],
		[currentUserType],
	);

	return <Modal owner={mainRef?.current} data-cy='student-profile-modal' modalManagerRef={modalRef}
								className={styles.modal}
								containerClassName={styles.modalLayer}>
		<div data-cy='modal-content' className={styles.modalContent}>
			<Modal.Header className={styles.header}>
				<div className={styles.avatarAndTitleContainer}>
					<Avatar
						mode={mode}
						image={initData?.photo?.imageCropUrl}
						onClick={() => setUploadModalOpen(true)}
					/>
					<ModalTitle mode={mode}
								onOpenModal={setUploadRosterModalOpen}
								onDeleteStudent={() => setDeleteStudentModalOpen(true)}
								isShowUploadRosterButton={isShowUploadRosterButton}
								isShowDeleteStudentButton={isShowDeleteStudentButton}
					/>
				</div>
				<CloseIcon onClick={() => handleCloseAction(handleClose)}/>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<TabsController
					selectedTab={selectedTab}
					isDisabled={isTabsDisabled}
					onChange={(tab) => handleChangeTabAction(() => {
						setSelectedTab(tab);
					})}
				/>
				{showEditDisabledText
					&& <div data-cy='edit-disabled-text' className={styles.viewModeNotification}> *Editing has been disabled by your district.
						Please contact your district administrator to request edit permissions.</div>}
				<div className={styles.tabBody}>
					{renderTabs}
				</div>

			</Modal.Body>
		</div>
		{isConfirmModalOpen &&
			<CloseActionConfirmModal
				onSave={onConfirmModalSave}
				onCancel={onConfirmModalCancel}
				onClose={onConfirmModalClose}/>
		}
		{isConfirmSaveWithoutClassModalOpen &&
			<SaveWithoutClassConfirmModal
				onSave={onSaveWithoutClassConfirm}
				onCancel={onSaveWithoutClassCancel}
                onClose={onSaveWithoutClassClose}/>
		}
		{isUploadModalOpen && <UploadPhotoModal
			studentID={studentID}
			imageData={initData.photo}
			onSaved={onImageChanged}
			onClose={() => setUploadModalOpen(false)}
		/>}
		{isDeleteStudentModalOpen && <DeleteStudentAlert
			studentFullName={`${initData.general.firstName} ${initData.general.lastName}`}
			isLinked={currentUser.agreementLevelCode !== 'T'}
			onDelete={onDeleteStudent}
			onCancel={() => setDeleteStudentModalOpen(false)}
		/>}
	</Modal>;
}
