import {OptionItem} from '@esgi/main/features/teacher/home';
import {TrackType} from '../../../../types';
import {trackTypeTranscript} from '../../../../constants';

const trackTypeList: TrackType[] = [
	TrackType.Semester,
	TrackType.Trimester,
	TrackType.Quarter,
	TrackType.Periods5,
	TrackType.Periods6,
	TrackType.Periods7,
	TrackType.Periods8,
	TrackType.Periods9,
];

export const trackTypeOptions = trackTypeList.map<OptionItem<TrackType>>((value) => ({
	value,
	label: trackTypeTranscript[value],
}));
