import {IconComponent} from '@esgi/ui';
import {Class, Student} from '@esgi/main/libs/store';
import {TestType} from '@esgi/main/libs/core';
import {TestGroupStudentsModel, TestInfo, TestSingleStudentModel} from '../../types';
import {Dispatch} from 'react';
import {TestLauncherState} from '@esgi/main/features/assessments';

export enum TestSectionMode {
	View = 'view',
	Rearrange = 'rearrange',
	Add = 'add',
}

export enum TestSectionContentType {
	SingleStudent = 'SingleStudent',
	GroupStudents = 'groupStudents',
	Default = 'Default',
}

export enum TestSectionTabId {
	All = 'All',
	YesNo = 'YesNo',
	Rubric = 'Rubric',
	SingleScore = 'SingleScore',
}

export enum CardViewType {
	Grid = 'Grid',
	List = 'List',
}

export enum TestedOption {
	Any = 'Any',
	Yes = 'Yes',
	No = 'No',
}

export enum SortByOption {
	Default = 'Default',
	TestName = 'TestName',
	LastTested = 'LastTested',
	ContentArea = 'ContentArea',
	NumberOfQuestions = 'NumberOfQuestions',
	StudentsTestedPercentage = 'StudentsTestedPercentage',
}

export type TestSectionSingleStudent = {
	contentType: TestSectionContentType.SingleStudent;
	student: Student;
	studentsIDsForStatistic: Student['id'][];
	statisticEntity: 'class' | 'group';
	onStudentClick: Dispatch<Student['id']>;
	allTests: TestSingleStudentModel[];
	onTestCardClicked: Dispatch<TestSingleStudentModel>;
	updateTestsOrder: Dispatch<TestSingleStudentModel[]>;
	showSelfAssessOption: boolean;
	studentClassID: Class['id'];
	setTestLauncherState: Dispatch<TestLauncherState>;
};

export type TestSectionGroupStudents = {
	contentType: TestSectionContentType.GroupStudents;
	studentsIDs: Student['id'][];
	statisticEntity: 'class' | 'group';
	sectionName: string;
	allTests: TestGroupStudentsModel[];
	onTestCardClicked: Dispatch<TestGroupStudentsModel>;
	updateTestsOrder: Dispatch<TestGroupStudentsModel[]>;
	classID: Class['id'];
};

export type TestSectionDefault = {
	contentType: TestSectionContentType.Default;
};

export type PermittedData<T = unknown> = T & {permitted: boolean};

export type SectionModeButtonItem = PermittedData<{
	activateMode: TestSectionMode;
	Icon: IconComponent;
	dataCy: string;
	tooltip?: string;
}>;

export type ModeButtonsPermissions = Record<TestSectionMode.Rearrange, boolean>;

export type StudentResultDiagramData = {
	correctAnswers: number;
	result: number;
	delta: number;
};

export type TestDetailsState = {
	testType: TestType;
	testId: TestInfo['id'];
};
