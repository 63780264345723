import React from 'react';
import './component.less';
import './../../component.less';
import {ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {CustomGradeRangeService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/service';
import GradeRangeTypeSelector from 'shared/modules/grade-scale/grade-range/grade-range-type-selector/component';
import {SelectorGradeRangeType} from 'shared/modules/grade-scale/grade-range/grade-range-type-selector/models';
import {Checkbox, CustomDropdown, Radio} from '@esgi/deprecated/elements';
import {Item} from '@esgi/deprecated/elements/custom-dropdown/dropdown';
import {ICustomGradeRangeSet} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/models';
import {FormState, GradeRangeSelectionType, ITrack} from 'shared/modules/grade-scale/models';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {
	GradeRangeEditorService,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/service';
import GradeRangeEditor from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/component';
import DPMPGradeRangeEditor
	from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/dpmp-grade-range-editor/component';
import LeftNavigateButton from 'shared/modules/grade-scale/grade-range/navigate-button/left/component';
import RightNavigateButton from 'shared/modules/grade-scale/grade-range/navigate-button/right/component';
import {Subscription} from 'rxjs';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {UserType} from '@esgi/core/authentication';

export class Props {
	service: CustomGradeRangeService;
	testID?: number;
	subjectID: number;
	userType: UserType;
	onGoToStep: (step: WizardStep, subjectID: number, testID: number, gradeScaleID?: number) => void;
	sourceStep: WizardStep;
}

export class State {
	gradeRangeEditors: GradeRangeEditorService[] = [];
	subjects: Item[] = [];
	tests: Item[] = [];
	selectedSubject: Item;
	selectedGradeRangeSet: ICustomGradeRangeSet;
	track: ITrack;
	formChanged: boolean;
}

export default class CustomGradeRange extends React.PureComponent<Props, State> {
	private supportPageUri: string = 'https://support.esgisoftware.com/hc/en-us/articles/115005886826-Class-Grade-Report-Grading-Scales';
	subscribers: Subscription[] = [];

	constructor(props) {
		super(props);
		this.state = new State();
		this.props.service.init(this.props.subjectID, this.props.testID);
	}

	componentDidMount() {
		this.setState({
			formChanged: this.props.sourceStep === WizardStep.DefaultGradeRange || this.props.sourceStep === WizardStep.SharedGradeRange,
		});

		this.subscribers.push(this.props.service.gradeRangeEditors$.subscribe(gre => {
			this.setState({gradeRangeEditors: [...gre]});
		}));

		this.subscribers.push(this.props.service.dropdownTests$.subscribe(tests => {
			this.setState({tests: tests});
		}));

		this.subscribers.push(this.props.service.subjects$.subscribe(subjects => {
			this.setState({subjects: subjects});
		}));

		this.subscribers.push(this.props.service.selectedSubject$.subscribe(subject => {
			this.setState({selectedSubject: subject});
		}));

		this.subscribers.push(this.props.service.selectedGradeRangeSet$.subscribe(grs => {
			this.setState({selectedGradeRangeSet: grs});
		}));

		this.subscribers.push(this.props.service.track$.subscribe(track => {
			this.setState({track: track});
		}));

		this.subscribers.push(this.props.service.formState$.subscribe(fs => {
			if (fs === FormState.Changing) {
				this.setState({formChanged: true});
			}
		}));
	}

	componentWillUnmount() {
		this.subscribers.map(s => s.unsubscribe());
	}

	renderSaveButton(){
		const saveAction = this.state.formChanged
			? () => this.props.service.save()
			: () => this.props.service.setFormState(FormState.Closed);

		return <Primary
			onClick={() => saveAction()}
			title={this.state.formChanged ? 'SAVE AND CONTINUE' : 'OK'}
		/>;
	}

	get shareCheckBoxClassName(){
		let className = 'filters-row share-range-checkbox';

		if (this.state.selectedGradeRangeSet.type === GradeRangeSelectionType.SameAllYear || this.state.selectedGradeRangeSet.applyDefaultRange){
			return className;
		}

		if (this.state.selectedGradeRangeSet.type === GradeRangeSelectionType.DifferentPerPeriod){
			return className += ' dpmp';
		}
	}

	renderEditor() {
		if (this.state.selectedGradeRangeSet.applyDefaultRange) {
			return <div className='same-all-year-container'>
				<div className='default-grade-range-editor-header'>Range as Percentage %</div>
				<GradeRangeEditor service={this.state.gradeRangeEditors[0]}/>
			</div>
		}

		if (this.state.selectedGradeRangeSet.type === GradeRangeSelectionType.SameAllYear){
			return <div className='same-all-year-container'>
				<GradeRangeEditor service={this.state.gradeRangeEditors[0]}/>
			</div>
		}

		if (this.state.track && this.state.selectedGradeRangeSet.type === GradeRangeSelectionType.DifferentPerPeriod){
			return <DPMPGradeRangeEditor
				editors={this.state.gradeRangeEditors}
				track={this.state.track}
				service={this.props.service}
			/>
		}

		return <></>;
	}
	render() {
		return <>
			<ModalHeader
				title='Custom Range'
				close={{click: () => this.props.service.setFormState(FormState.Closed)}}
			/>
			<ModalBody>
				<div className='grade-range-form-component grade-range-custom-component'>
					<GradeRangeTypeSelector
						disabled={!this.state.selectedGradeRangeSet || this.state.selectedGradeRangeSet.sharedRangesCount === 0 || this.props.userType === UserType.D}
						selectedType={SelectorGradeRangeType.Custom}
						sharedRanges={this.state.selectedGradeRangeSet ? this.state.selectedGradeRangeSet.sharedRangesCount : 0}
						onClick={() => this.props.onGoToStep(
							WizardStep.SharedGradeRange,
							this.state.selectedSubject.key,
							this.state.selectedGradeRangeSet.testID,
							this.state.selectedGradeRangeSet.gradeScale.id,
						)}
					/>
					<div className='grade-scale-header custom'>
						Set Custom Range for Each Test
						<a target='_blank' href={this.supportPageUri} rel='noreferrer'>
							<i className='glyphicon glyphicon-question-sign tip'></i>
						</a>
					</div>
					<div className='sub-header'>
						Set a custom range for a test using a numeric range based on the total number of questions in
						each test.
						<a target='_blank' href={this.supportPageUri} rel='noreferrer'>
							<span>Learn more</span>
						</a>
					</div>
					<div className='filters-row'>
						<div className='filters-cell left'>
							<label>Subject Tab</label>
							<CustomDropdown
								items={this.state.subjects}
								value={this.state.selectedSubject ? this.state.selectedSubject.value : 0}
								onSelect={s => {
									this.props.service.setFormState(FormState.Changing);
									this.props.service.selectDropdownSubject(s.key);
								}}
								preventDefault
							/>
						</div>
						<div className='filters-cell right'>
							<label>Tests [{this.state.tests.length}]</label>
							<CustomDropdown
								items={this.state.tests}
								value={this.state.selectedGradeRangeSet ? this.state.selectedGradeRangeSet.testID : 0}
								onSelect={t => {
									this.props.service.setFormState(FormState.Changing);
									this.props.service.selectTest(t.key);
								}}
								preventDefault
							/>
						</div>
					</div>
					{this.state.selectedGradeRangeSet &&
						<>
							<div className='filters-row'>
								<Radio
									checked={this.state.selectedGradeRangeSet.type === GradeRangeSelectionType.SameAllYear}
									onSelect={(e) => this.props.service.updateGradeRangeType(GradeRangeSelectionType.SameAllYear)}
									className='filters-grade-types-radio'
									name='SameAllYear'
									label='Same all year'
									id='grade-range-types'
								/>
                <OnHoverTooltip message={this.state.track ? null : <>The district has tracks with a different number of marking periods. You are unable to set different per marking period custom ranges.<br />Please contact <a href='https://support.esgisoftware.com/hc/en-us/requests/new' target='_blank'>support</a> to remove any unused tracks.</>}>
                  <div>
                    <Radio
                      checked={this.state.selectedGradeRangeSet.type === GradeRangeSelectionType.DifferentPerPeriod}
                      onSelect={(e) => this.props.service.updateGradeRangeType(GradeRangeSelectionType.DifferentPerPeriod)}
                      className='filters-grade-types-radio'
                      name='DifferentPerMarkingPeriod'
                      label='Different per Marking Period'
                      id='different-per-marking-period'
                      disabled={!this.state.track}
                    />
                  </div>
                </OnHoverTooltip>
							</div>
							<div className='filters-row center-content'>
								{this.props.service.setUpGradeScaleMode && <LeftNavigateButton onClick={() => this.props.service.prevTest()}/>}
								{this.renderEditor()}
								{this.props.service.setUpGradeScaleMode && <RightNavigateButton onClick={() => this.props.service.nextTest()}/>}
							</div>
							<div className={this.shareCheckBoxClassName}>
								<Checkbox
									checked={this.state.selectedGradeRangeSet.shareRange}
									label='Share range with school'
									disabled={this.state.selectedGradeRangeSet.id === 0 || this.state.selectedGradeRangeSet.applyDefaultRange}
									onClick={(e) => this.props.service.setShareRange(e)}
									id={'share-range'}
								/>
								<Checkbox
									checked={this.state.selectedGradeRangeSet.applyDefaultRange}
									label='Apply default range'
									onClick={(e) => this.props.service.applyDefaultRange(e)}
									id={'set-default-range'}
								/>
							</div>
						</>}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='left-footer-container'>
					<Button
						onClick={() => this.props.service.setFormState(FormState.Canceled)}
						title='Cancel'
						className='cancel-button'
					/>
				</div>
				<div className='right-footer-container'>
					{!this.props.service.setUpGradeScaleMode &&
						<Transparent
							className='set-default-range-button'
							onClick={() => this.props.onGoToStep(
								WizardStep.DefaultGradeRange,
								this.state.selectedSubject.key,
								this.state.selectedGradeRangeSet.testID,
								this.state.selectedGradeRangeSet.gradeScale.id,
							)}
							title='SET DEFAULT RANGE'
						/>
					}
					{this.renderSaveButton()}
				</div>
				<SnackbarLayout/>
			</ModalFooter>
		</>;

	}
}
