import {Wrapper, global} from './global';
import {PropsWithChildren} from 'react';
import {styled} from './stitches.config';
import {defaultTheme} from './variants';

type Props = PropsWithChildren;
function ThemeProvider({children}: Props) {
	global();
	return <Wrapper className={defaultTheme.className}>{children}</Wrapper>;
}

export {ThemeProvider, styled};
export {css, keyframes} from './stitches.config';
