import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {EyeIcon} from 'shared/modules/student-roster-upload-tool/icons/eye-icon';
import {Buttons} from '@esgillc/ui-kit/button';
import {ConflictExistingStudentModel} from 'shared/modules/student-roster-upload-tool/types';

interface Props {
	isRowOpened: boolean,
	existingStudent: ConflictExistingStudentModel;
	onView: (id: number) => void
}

export const ExistingStudentRow = ({isRowOpened, existingStudent, onView}: Props) => {

	return (
		<div data-cy={'existing-student-row'}
				 className={join(styles.existingStudentRow, isRowOpened ? '' : styles.closedExistingRow)}>
			<div className={styles.existingContainer}>
				<div className={styles.existingStudentTitle}>Matching student in ESGI</div>
				<div className={styles.existingStudentContent}>
					<div data-cy={'student-name'} className={styles.name}>{existingStudent.name}</div>
					<div data-cy={'student-id'} className={styles.id}>{existingStudent.studentIDN}</div>
					<div data-cy={'student-grade'} className={styles.grade}>{existingStudent.gradeLevel}</div>
					<div data-cy={'student-language'} className={styles.language}>{existingStudent.language}</div>
					<div data-cy={'student-date'} className={styles.date}>{existingStudent.createDate}</div>
					<div data-cy={'view'} className={styles.action} onClick={() => onView(existingStudent.id)}>
						<EyeIcon/>
						<Buttons.Link>View ESGI student profile</Buttons.Link>
					</div>
				</div>
			</div>
		</div>
	);
};
