import {StudentInfo} from '../../../../../../class-group/types';
import {TestSession} from '../../../../../../types';
import {useRef, useState} from 'react';
import {StudentCell} from './student-cell';
import {StudentSingleSessionRow} from './student-single-session-row';
import {Box, GridBox} from '@esgi/ui/layout';
import {animated, config, useSpring} from 'react-spring';
import useResizeObserver from '@react-hook/resize-observer';
import {tableStyles as mainTableStyles} from '../index.styled';
import {Property} from '@stitches/react/types/css';
import {getCorrectAnswersCount} from '../../../../utils/get-correct-answers-count';

type Props = {
	student: Omit<StudentInfo, 'testSessionsInfo'>;
	sessions: TestSession[];
	withProgressBar?: boolean;
	sessionResult: {
		valueKey: keyof Pick<TestSession, 'answers' | 'correctAnswers'>;
		maxValue: number;
	};
	isTablet: boolean;
	onOpenTestSessionDetailsAlert: (sessionID: number) => void;
	onTestClick: VoidFunction;
	onOpenStudentTestDashboard: VoidFunction;
	index: number;
};

export function StudentInfoRow({
	student,
	sessions,
	withProgressBar = true,
	sessionResult,
	onOpenTestSessionDetailsAlert,
	onTestClick,
	isTablet,
	onOpenStudentTestDashboard,
	index,
}: Props) {
	const [rowPosition, setRowPosition] = useState<Property.Position>('absolute');

	const [isExpandedSubTable, setIsExpandedSubTable] = useState(false);
	const [subTableHeight, setSubTableHeight] = useState(0);

	const subTanleRef = useRef<HTMLDivElement>(null);

	useResizeObserver(subTanleRef, (entry) => setSubTableHeight(entry.contentRect.height));

	if (sessions.length <= 1) {
		throw new Error('sesssion should has minimum 2 items');
	}

	const [firstSession, ...restSessions] = sessions;

	const panelAnimatedStyles = useSpring({
		height: isExpandedSubTable ? subTableHeight : 0,
		overflow: isExpandedSubTable ? 'unset' : 'hidden',
		gridColumnStart: 1,
		gridColumnEnd: 5,
		config: {
			...config.gentle,
			clamp: true,
		},
		onStart: () => {
			if (isExpandedSubTable) {
				setRowPosition('static');
			}
		},
		onRest: () => {
			if (!isExpandedSubTable) {
				setRowPosition('absolute');
			}
		},
	});

	return (
		<>
			<StudentCell
				firstName={student.firstName}
				lastName={student.lastName}
				withExpandedButton
				isExpanded={isExpandedSubTable}
				setIsExpaned={setIsExpandedSubTable}
				subTableItemsCount={sessions.length}
				onOpenStudentTestDashboard={onOpenStudentTestDashboard}
				index={index}
			/>
			<StudentSingleSessionRow
				session={firstSession!}
				withProgressBar={withProgressBar}
				sessionResult={{
					value:
						sessionResult.valueKey === 'answers'
							? getCorrectAnswersCount(firstSession!.answers)
							: firstSession![sessionResult.valueKey],
					maxValue: sessionResult.maxValue,
				}}
				onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
				withTestButton
				onTestClick={onTestClick}
				isTablet={isTablet}
				onOpenStudentTestDashboard={onOpenStudentTestDashboard}
				index={index}
			/>

			<animated.div
				style={{
					...panelAnimatedStyles,
					position: rowPosition,
				}}
				data-cy={`rest-sessions-${index}`}
			>
				<GridBox ref={subTanleRef} css={mainTableStyles}>
					{restSessions.map((session) => (
						<>
							<Box />
							<StudentSingleSessionRow
								session={session}
								withProgressBar={withProgressBar}
								sessionResult={{
									value:
										sessionResult.valueKey === 'answers'
											? getCorrectAnswersCount(session.answers)
											: session[sessionResult.valueKey],
									maxValue: sessionResult.maxValue,
								}}
								isTablet={isTablet}
								onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
								onOpenStudentTestDashboard={onOpenStudentTestDashboard}
								index={index}
							/>
						</>
					))}
				</GridBox>
			</animated.div>
		</>
	);
}
