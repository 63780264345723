import {Checkbox} from '@esgillc/ui-kit/control';
import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {SelectableList, SelectableListItem} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import React from 'react';
import SelectorBox from '../../../shared/components/selector-box/selector-box';
import SelectorBoxHeader from '../../../shared/components/selector-box/header';
import SelectorBoxBody from '../../../shared/components/selector-box/body';
import styles from './student-selector.module.less';
import * as utils from '../utils';
import DataService from '../data-service';
import {StudentResponse} from '../../../shared/types';

interface Props {
	className?: string;
	students: StudentResponse[];
	control: IFormControl;
	dataService: DataService;
}

class State {
	columns: StudentResponse[][] = [];
	selectedAllStudents: boolean = false;
}

export default class StudentSelector extends React.Component<Props, State> {
	public override readonly state = new State();

	componentDidMount() {
		this.props.dataService.studentsSelectionStateChanged$.subscribe(state => this.setState({selectedAllStudents: state.selectedAllStudents}));
		this.props.dataService.studentColumns$.subscribe(columns => this.setState({columns}));
	}

	render() {
		const isDataLoaded = this.props.students != null;
		const hasData = this.state.columns.length > 0 && this.props.students.length > 0;
		return <div className={this.props.className}>
			<SelectorBox>
				<SelectorBoxHeader>Students</SelectorBoxHeader>
				<SelectorBoxBody className={join(styles.body, !hasData && styles.emptyBody)}>
					{
						isDataLoaded ? !hasData && <div className={styles.emptyBodyContent}>There are no students</div>
						: <span/>
					}
					{
						hasData &&
						<div>
							<Checkbox checked={this.state.selectedAllStudents}
									  onChange={(e) => this.onSelectAllStudents()}
									  className={join(styles.allStudents, styles.listItem)}>
								<div className={styles.listItemCaption}>All Students</div>
							</Checkbox>
							<FormElement control={this.props.control} className={styles.formGroup}>
								<SelectableList checkboxPlacement='left'>
									<div className={join('row', styles.listColumns)}>
										{this.renderColumn(this.state.columns[0], styles.column1)}
										{this.renderColumn(this.state.columns[1], styles.column2)}
										{this.renderColumn(this.state.columns[2], styles.column3)}
										{this.renderColumn(this.state.columns[3], styles.column4)}
									</div>
								</SelectableList>
							</FormElement>
						</div>
					}
				</SelectorBoxBody>
			</SelectorBox>
		</div>;
	}

	private renderColumn(students: StudentResponse[], className: string) {
		return <div className={join('col-xs-12 col-sm-6 col-md-3', className)}>
			{
				students.map(x =>
					<SelectableListItem value={x.id} key={x.id} className={join(styles.listItem, styles.studentListItem)}>
						<div className={styles.listItemCaption}>{utils.getFullName(x)}</div>
					</SelectableListItem>)
			}
		</div>;
	}

	private onSelectAllStudents() {
		this.props.dataService.inverseAllStudentsSelector();
	}
}
