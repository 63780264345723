import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';

interface Props {
	tilesCount: number
	onCancel: () => void
	onDelete: () => void
}

export function DeleteAlert({tilesCount, onDelete, onCancel}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onCancel);

	return <Alert modalManagerRef={modalRef}>
		<Alert.Body>
			<span data-cy='delete-alert'>{
				tilesCount > 1
					? 'Are you sure you want to delete this test question?'
					: 'You cannot delete the last question.'
			}</span>
		</Alert.Body>
		<Alert.Footer>
			<div data-cy='delete-alert-footer'>
				{tilesCount > 1
					? <>
						<Buttons.Text onClick={handleClose}>CANCEL</Buttons.Text>
						<Buttons.Text onClick={onDelete}>OK</Buttons.Text>
					</>
					: <Buttons.Text onClick={handleClose}>OK</Buttons.Text>
				}
			</div>
		</Alert.Footer>
	</Alert>;
}