import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useDropdownClassesOptions, useDropdownGroupsOptions} from './hooks';
import React, {useCallback} from 'react';
import {Dropdown, DropdownOption} from '../../kit';
import {StudentCredentialsTeacherService} from '../../services/student-credentials-teacher-service';
import {FiltersContainer} from './components/filters-container';
import {getDropdownValue} from './utils';

type Props = {
	service: StudentCredentialsTeacherService;
};

export function FiltersTeacher({service}: Props): React.JSX.Element {
	const classes = useBehaviorSubject(service.filterService.classes$);
	const groups = useBehaviorSubject(service.filterService.groupsOfSelectedClass$);

	const classesOptions = useDropdownClassesOptions(classes);
	const groupsOptions = useDropdownGroupsOptions(groups);

	const onClassOptionChange = useCallback((newValue: DropdownOption | null) => {
		service.filterService.setSelectedClassId(getDropdownValue(newValue));
		service.filterService.updateTableData();
	}, []);

	const onGroupOptionChange = useCallback((newValue: DropdownOption | null) => {
		service.filterService.setSelectedGroupId(getDropdownValue(newValue));
		service.filterService.updateTableData();
	}, []);

	return (
		<FiltersContainer service={service}>
			<Dropdown
				filterLabel='Classes'
				options={classesOptions}
				placeholder='Select Classes'
				onValueChange={onClassOptionChange}
			/>
			<Dropdown
				filterLabel='Groups'
				options={groupsOptions}
				placeholder='Select Classes'
				onValueChange={onGroupOptionChange}
			/>
		</FiltersContainer>
	);
}
