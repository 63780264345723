import {Avatar as AvatarUI} from '@esgi/ui/avatar';
import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';

export const Avatar = styled(AvatarUI.Root, {
	borderStyle: 'solid',
	borderWidth: 1.5,
	borderColor: '$primary',
	boxSizing: 'border-box',
	borderRadius: 12,
});

export const IconWrapper = styled('span', {
	'& svg': {
		width: 32,
		height: 32,
	},
});

export const Link = styled(Button.Text, {
	display: 'grid',
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',
	transition: 'color .3s',
	overflow: 'hidden',
	whiteSpace: 'nowrap',

	'& svg': {
		width: 32,
		height: 32,
	},

	'&:hover': {
		textDecoration: 'underline',
	},
});
