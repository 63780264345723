import {AppContext} from '@esgi/deprecated/react';
import React from 'react';
import AppHistory from 'shared/tools/history/history';
import {Authenticator, LoginResponse} from '@esgi/core/authentication';
import {afterLoginHandler} from '../redirect-module/redirect';
import styles from './styles.module.less';
import {RoutesMap} from '@esgi/main/libs/core';
import {Link} from 'react-router-dom';
import {HttpClient} from '@esgi/api';

class State {
	token: string;
}

export default class Agreements extends React.Component<any, State> {
	constructor(props: any, context: AppContext) {
		super(props, context);

		const params = new URLSearchParams(window.location.search);
		const token = params.get('token');

		this.state = new State();
		this.state = {
			token: token,
		};
	}

	accept() {
		HttpClient.default.ESGIApi.post<LoginResponse>('Login', 'ByAgreement')
			.subscribe(async (r) => {
				const validator = new Authenticator();
				const authenticatorResult = await validator.authenticate(r);

				if (authenticatorResult.valid) {
					afterLoginHandler(r.user);
				} else if (!authenticatorResult.redirect){
					AppHistory.navigateOutside('/');
				}
			});
	}

	public render() {
		return <div className={styles.agreement}>
			<div className={styles.container + ' container'}>
				<div>
					<h2>ESGI, LLC Terms of Use</h2>
					<h4>Last updated: December 5, 2023.</h4>
					<p>
						By entering into these Terms of Use (the “Terms”) with ESGI, LLC (“ESGI”), and/or by accessing or using
						the ESGI website, mobile application, and related services (collectively, the “Service”), you expressly
						acknowledge that you have read, understood, and agree to be bound by these Terms. These Terms apply to all
						visitors, users, and others who access or use the Service (each, a “Customer”). If you are agreeing to
						these Terms on behalf of an organization or entity (such as a school, district, or educational organization,
						each, a “Licensee”), you represent and warrant that you are authorized to agree to these Terms on behalf of
						the Licensee and to bind them to these Terms (in which case, references to "Customer" in these Terms
						refer to such Licensee). For good and valuable consideration, the receipt of which is acknowledged by each party,
						the parties agree as follows:
					</p>
					<p><span className={styles.bold}>1.	Definitions. </span></p>

					<p>
						“Customer Content” means any information, data, images, and other content, in any form or medium,
						that is
						submitted by or collected from or on behalf of Customer through the Service, but excluding the Usage
						Data.
					</p>
					<p>
						“Documentation” means the documentation that ESGI makes generally available to its customers in any
						form related to the Service.
					</p>
					<p>
						“Effective Date” means the effective date of these Terms, which shall be the earlier of Customer’s
						acceptance of these Terms or its access to or use of the Service.
					</p>

					<p>“Fees” means the subscription fees for the Service.</p>

					<p>
						“Intellectual Property Rights” means all patent, copyright, trademark, trade secret, rights in
						know-how, business domain names, designs, and other proprietary and intellectual property rights recognized in
						any jurisdiction worldwide, including moral rights.
					</p>

					<p> “Marks” means a party’s name, logo, and trademarks.</p>

					<p> “Reports” means the tangible reports generated by Customer’s use of the Service.</p>

					<p> “Service Term” is defined in Section 10.1 below.</p>

					<p>
						“Third-Party Service” means any products or services provided by third parties that may be
						integrated with or accessed through the Service.
					</p>

					<p>
						“Usage Data” means any anonymized and aggregated data that is derived from the processing of
						Customer Content or the access or use of the Service by or on behalf of Customer, that in no way identifies
						or refers to Customer, User, or students, and any statistical or other analysis, information, or data based on
						or derived from the foregoing.
					</p>

					<p>
						“User” means Customer’s employees, independent contractors, or other third parties who are
						authorized by Customer to access and use the Service under the rights granted to Customer in these Terms
					</p>

					<p><span className={styles.bold}>2. The Service.</span></p>
					<p>
						2.1 <u>Provision of the Service</u>.
						During the Service Term and subject to the terms and conditions of these
						Terms: (i) Customer may access and use the Service pursuant to these Terms; and (ii) ESGI hereby grants to
						Customer a limited, non-exclusive, non-transferable (except as set forth in Section 11), non-sublicensable
						license to use and copy the Documentation as reasonably required to access and use the Service.
						ESGI will use commercially reasonable efforts to maintain the security of the Service. The Website
						is intended for use by adults, and is not directed to minors under the age of 18. By accessing or using
						the Website and thereby agreeing to the terms and conditions of this Agreement, Customer represents
						and warrants to ESGI: (i) that Customer is at least 18 years of age; (ii) that Customer has not previously
						been suspended or removed from the Website; and (iii) that Customer’s registration and use of the Website
						is in compliance with any and all applicable laws and regulations. Any registration by anyone under the age of 18 is void.
					</p>
					<p>
						2.2 <u>Modifications</u>.
						ESGI may revise or remove features, functionalities, or service levels of the Service at any time.
						If any such revision or removal materially impacts Customer’s use of the Service, Customer may within
						thirty (30) days’ notice of the revision or removal terminate these Terms, without cause.
					</p>
					<p>
						2.3 <u>Privacy Policy</u>.
						ESGI’s privacy policy (located at <Link to={RoutesMap.privacyPolicy}>link</Link>)
						is incorporated herein by reference. ESGI reserves the right to modify its privacy policy in accordance with such policy.
					</p>
					<p>
						2.4 <u>Subcontractors</u>.
						Customer acknowledges and agrees that ESGI may use subcontractors and other third-party service providers
						in its provision of the Service, provided that ESGI remains liable for such third party’s breach of these Terms.
					</p>

					<p><span className={styles.bold}>3.	Customer’s Responsibilities.</span></p>
					<p>
						3.1 <u>Use of the Service</u>.
						Customer shall: (i) have sole responsibility for the accuracy, quality, integrity, legality, reliability,
						and appropriateness of all the Customer Content; (ii) use commercially reasonable efforts to prevent
						unauthorized access to or use of the Service and to notify ESGI immediately of any such unauthorized
						access or use; and (iii) provide all necessary personnel and other resources, as reasonably requested by
						ESGI, to enable ESGI to fulfill its obligations hereunder. Customer is fully responsible and liable for
						all Users’ use of the Service.
					</p>
					<p>
						3.2 <u>Restrictions</u>.
						Customer may use the Service solely as set forth in these Terms and will not: (i) copy, modify,
						or create derivative works or improvements of the Service; (ii) reverse engineer, disassemble,
						decompile, modify, or alter the Service; (iii) remove, circumvent, disable, damage, or otherwise
						interfere with or disrupt the integrity or performance of the Service or any security-related features
						therein; (iv) input, upload, transmit, or otherwise provide to or through the Service, any information
						or materials that are unlawful or injurious, or contain, transmit, send, or store material containing
						harmful computer code, files, scripts, agents, or programs; (v) rent, lease, loan, resell, sublicense,
						distribute, or otherwise commercially exploit or make available the Service to any third party or use
						the Service for service bureau or time-sharing purposes; or (vi) develop or have developed any products,
						services, or other materials that compete with the Service or otherwise compete with ESGI’s business.
						Each User’s right to access and use the Service through its individual account is personal to such User
						and such account may not be shared with other individuals. A User account may only be reassigned to a
						new User who is replacing the applicable User, as determined by an authorized account administrator.
					</p>
					<p>
						3.3 <u>Customer Content</u>.
						Customer hereby grants to ESGI a limited, non-exclusive, non-transferable (except as set forth in Section 11)
						right and license during the Service Term to: (i) download, receive, collect, access, modify, copy,
						store, retain, and otherwise use the Customer Content in order to provide and support the Service
						in accordance with these Terms and to internally develop and improve its products and services;
						and (ii) provide access to the Customer Content by a Third-Party Service selected by Customer or
						otherwise upon Customer’s consent or direction. Customer acknowledges and agrees that ESGI has no
						responsibility for the services or content of any Third-Party Service and will have no liability
						for the acts or omissions of such Third-Party Service. Any activities related to Third-Party Service,
						and any terms associated with such activities, are solely between Customer and the applicable
						Third-Party Service.
					</p>
					<p>
						3.4 <u>Aggregate Data</u>.
						Customer hereby grants to ESGI a non-exclusive, worldwide, perpetual, irrevocable,
						fully paid, royalty-free right and license to collect, retain, store, use, modify, distribute,
						and disclose the Customer Content in aggregated and anonymized form and any inferred insight
						relating to the Customer Content for any purpose whatsoever, provided that any such disclosure
						does not identify or enable the identification of Customer, Users, or students by name or
						description sufficient to enable the identification of such party as the source or subject of
						such information, in whole or in part.
					</p>

					<p><span className={styles.bold}>4.	Fees & Payment.</span></p>
					<p>
						4.1 <u>Fees</u>.
						Customer will pay the Fees mutually agreed to by the parties. All Fees are quoted in United States Dollars
						and are non-refundable except as otherwise specifically set forth in these Terms. Customer’s credit card
						will automatically be charged when payment is due. Any payment not received or able to be processed by
						the due date may accrue, at ESGI’s discretion, late charges at the rate of one and a half percent (1.5%)
						of the outstanding balance per month, or at the maximum rate permitted by law, whichever is lower,
						from the date such payment was due until the date fully paid. ESGI uses a third-party payment processor
						and Customer must agree to such third party’s terms when entering payment information, if applicable.
					</p>
					<p>
						4.2 <u>Taxes</u>.
						Fees are exclusive of all local, state, federal, and foreign taxes, levies, or duties of any nature (“Taxes”),
						and Customer is responsible for payment of all Taxes, excluding only United States taxes based on ESGI’s income.
						If ESGI has the legal obligation to pay or collect Taxes for which Customer is responsible pursuant to this
						Section 4.2, the appropriate amount will be invoiced to and paid by Customer, unless Customer provides ESGI
						with a valid tax exemption certificate authorized by the appropriate taxing authority. Customer will indemnify,
						defend, and hold ESGI, its officers, directors, consultants, employees, successors, and assigns harmless from
						all claims and liability arising from Customer’s failure to report or pay any such Taxes.
					</p>
					<p>
						4.3 <u>Additional Charges</u>.
						If Customer requests an additional number of Users of the Service or students rostered for the Service,
						Customer shall pay to ESGI any additional Fees for such Users as mutually agreed to.
					</p>
					<p>
						4.4 <u>Internal Review of Use</u>.
						Upon ESGI’s request, Customer shall certify in a signed writing that its and all Users’ use of the
						Service is in full compliance with these Terms. ESGI may run internal reports to analyze Customer’s
						use of the Service in order to determine whether such usage complies with these Terms and the applicable
						licenses granted hereunder.
					</p>

					<p><span className={styles.bold}>5.	Proprietary Rights.</span></p>
					<p>
						5.1 <u>Customer IP</u>.
						As between Customer and ESGI, Customer owns all right, title, and interest in and to: (i) the Customer Content
						and Customer’s Marks; (ii) Customer’s Confidential Information; (iii) the Reports (excluding the ESGI IP as defined below);
						and (iv) all Intellectual Property Rights in the foregoing (collectively, the “Customer IP”). Except as expressly
						set forth in these Terms, no license or other rights to any of the Customer IP are granted to ESGI, and
						all such rights are hereby expressly reserved.
					</p>
					<p>
						5.2 <u>ESGI IP</u>.
						ESGI owns all right, title, and interest in and to: (i) the Service, the Documentation, ESGI’s Marks,
						and any and all enhancements, improvements, developments, derivative works, or other modifications
						(including any changes which incorporate any of Customer’s ideas, feedback, or suggestions) made or related
						to the Service or the Documentation; (ii) the Usage Data; (iii) ESGI’s Confidential Information; and (iv)
						all Intellectual Property Rights in the foregoing (collectively, the “ESGI IP”). Except as expressly set
						forth in these Terms, no license or other rights to any of the ESGI IP are granted to Customer, and all
						such rights are hereby expressly reserved.
					</p>

					<p><span className={styles.bold}>6.	Confidentiality.</span></p>
					<p>
						6.1 <u>Confidential Information</u>.
						As used herein, “Confidential Information” means all non-public information disclosed by a party
						(the “Disclosing Party”) to the other party (the “Receiving Party”), whether orally or in writing, that
						is designated as “Confidential,” “Proprietary,” or the like and/or information that the Receiving Party
						should reasonably understand as being confidential or proprietary to the Disclosing Party given the nature
						of the information and the circumstances of the disclosure, including business and marketing plans,
						financial information, technology and technical information, designs, and business processes.
						Confidential Information will not include any information that: (i) is or becomes generally known to the
						public without the Receiving Party's breach of any obligation owed to the Disclosing Party; (ii) was
						in the rightful possession or known by the Receiving Party prior to receipt from the Disclosing Party
						without breach of any obligation owed to the Disclosing Party; (iii) is received from a third party who
						obtained such Confidential Information without any third party's breach of any obligation owed to
						the Disclosing Party; or (iv) was independently developed by the Receiving Party without the
						Receiving Party's breach of any obligation owed to the Disclosing Party.
					</p>
					<p>
						6.2 <u>Protection</u>.
						Subject to Section 6.3, each party will not disclose the other party’s Confidential Information,
						or use the other party’s Confidential Information, for any purpose other than to perform its obligations
						or exercise its rights under these Terms, and will protect the confidentiality of the Confidential
						Information of the other party in the same manner that it protects the confidentiality of its own
						proprietary and confidential information of like kind, but in no event will either party exercise
						less than reasonable care in protecting such Confidential Information. The Receiving Party may disclose
						Confidential Information to its employees, agents, contractors, Affiliates, and other representatives
						having a legitimate need to know, provided that such representatives are bound to confidentiality
						obligations no less protective of the Disclosing Party than this Section 6 and that the Receiving
						Party remains responsible for compliance by any such representative with the terms of this Section 6.
					</p>
					<p>
						6.3 <u>Compelled Disclosure</u>.
						If the Receiving Party is compelled by law to disclose Confidential Information of the Disclosing Party,
						it will provide the Disclosing Party with prior written notice of such compelled disclosure and
						reasonable assistance (at Disclosing Party's cost) if the Disclosing Party wishes to contest the disclosure.
					</p>
					<p>
						6.4 <u>Remedies</u>.
						If the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential
						Information of the Disclosing Party in breach of this Section 6, the Disclosing Party will have the right,
						in addition to any other remedies available to it, to seek injunctive relief to enjoin such acts,
						it being specifically acknowledged by the parties that any other available remedies are inadequate.
					</p>

					<p><span className={styles.bold}>7.	Representations & Warranties.</span></p>
					<p>
						7.1 <u>Mutual Warranties</u>.
						Each party represents and warrants that: (i) it is duly organized, validly existing, and in good
						standing under the laws of its jurisdiction of formation, if applicable; (ii) it has full right,
						power, and authority to enter into and perform its obligations under these Terms; (iii) these
						Terms, when executed and delivered, will constitute a valid and binding obligation of such party
						and will be enforceable against such party in accordance with its terms; and (iv) the performance
						of its obligations under these Terms will not violate any applicable law, rule, or regulation,
						including those related to data privacy.
					</p>
					<p>
						7.2 <u>By Customer</u>.
						Customer represents and warrants that its use of the Service, including the Customer Content provided
						in connection with the Service, will not violate any applicable law, rule, or regulation, infringe
						any third party’s intellectual property, privacy, or publicity right, or cause a breach of any
						agreement with any third party.
					</p>
					<p>
						7.3 <u>By ESGI</u>.
						ESGI represents and warrants that the Service will be provided in all material respects in accordance
						with the Documentation and will otherwise be performed in a manner consistent with generally accepted
						industry standards. Customer’s sole remedy and ESGI’s sole obligation for any such failure will be
						for ESGI to use commercially reasonable efforts to correct such non-compliance, provided that
						if such non-compliance cannot be corrected within thirty (30) days, Customer may terminate these
						Terms and receive a prorated refund for any prepaid, unused Fees as of the effective date of termination.
					</p>
					<p>
						7.4 <u>Disclaimer of Warranties</u>.
						EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION 7, THE SERVICE, THE REPORTS, AND ALL RELATED DELIVERABLES
						AND SERVICES ARE PROVIDED “AS IS.” ESGI MAKES NO ADDITIONAL REPRESENTATION OR WARRANTY OF ANY KIND
						WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER.
						ESGI EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
						QUALITY, ACCURACY, TITLE, AND NON-INFRINGEMENT. ESGI DOES NOT WARRANT THAT THE SERVICE IS ERROR-FREE
						OR WILL BE SECURE OR UNINTERRUPTED.
					</p>

					<p><span className={styles.bold}>8.	Indemnification.</span></p>
					<p>
						8.1 <u>By ESGI</u>.
						Subject to the other provisions of this Section 8, ESGI will defend, indemnify, and hold Customer
						and its officers, directors, consultants, employees, successors, and assigns (“Customer Indemnitees”)
						harmless against any and all losses, damages, liabilities, and costs, including reasonable
						attorneys’ fees (“Damages”), incurred by Customer Indemnitees arising out of or relating to any third-party
						claim, suit, action, or proceeding (“Claims”) that allege: (i) ESGI’s breach of any of its representations,
						warranties, or obligations set forth in Sections 6 or 7.1 of these Terms; and (ii) Customer’s use of
						the Service in compliance with these Terms infringes any third party’s Intellectual Property Right (an “IP Claim”).
						The foregoing obligation does not apply to any IP Claim to the extent arising out of or relating
						to: (a) modifications to the Service not made or authorized by ESGI; (b) materials supplied by or on
						behalf of Customer, including without limitation the Customer Content; (c) combination of the Service
						with products or services, including any Third-Party Service; (d) designs or instructions provided
						by Customer to ESGI; (e) Customer’s continued use of the allegedly infringing material after being
						notified of modifications that would have avoided such alleged infringement; or (f) Customer’s use
						of the Service not strictly in accordance with these Terms.
					</p>
					<p>
						8.2 <u>Infringement Remedies</u>.
						If ESGI or a court or other body of competent jurisdiction determines that the Service is or may
						be infringing, ESGI may, at its option and expense: (i) replace or modify the Service to be non-infringing,
						provided that such modification or replacement contains substantially similar features and
						functionality; (ii) obtain for Customer a license to continue using the Service; or (iii) if neither
						of the foregoing is commercially practicable, terminate these Terms and Customer’s rights hereunder
						and provide Customer a prorated refund for any prepaid, unused Fees. THE REMEDIES SET FORTH IN
						THIS SECTION 8 STATE CUSTOMER’S SOLE AND EXCLUSIVE REMEDY AND ESGI’S SOLE AND EXCLUSIVE
						LIABILITY IN RELATION TO AN IP CLAIM.
					</p>
					<p>
						8.3 <u>By Customer</u>.
						Subject to the other provisions of this Section 8, Customer will defend, indemnify, and hold
						ESGI and its officers, directors, consultants, employees, successors, and assigns (“ESGI Indemnitees”)
						harmless against any and all Damages incurred by ESGI Indemnitees arising out of or relating
						to a Claim that: (i) alleges Customer’s breach of any of its representations, warranties,
						or obligations in these Terms; and (ii) relates to the Customer Content as used by ESGI in
						accordance with these Terms.
					</p>
					<p>
						8.4 <u>Procedure</u>.
						Each indemnifying party’s obligations as set forth in this Section 8 are contingent on: (i) the
						indemnitee providing the indemnifying party with prompt written notice of the Claim (and any
						information that may reasonably give rise to a Claim), but only if the indemnifying party is
						materially adversely prejudiced by failure to receive such notice; (ii) the indemnifying party
						having the right to defend the Claim at its own expense and with counsel of its choosing; (iii)
						the indemnifying party having the right, in its sole discretion, to settle the Claim so long
						as such settlement does not impose any monetary or material non-monetary obligations on the
						indemnitee (other than indemnitee no longer using the Service, as the case may be), and provided
						that the indemnitee will be included in any release language as part of any such settlement;
						and (iv) the indemnitee providing (at the indemnifying party’s expense) such assistance and
						information as the indemnifying party may reasonably require to investigate, defend, or settle the Claim.
					</p>

					<p><span className={styles.bold}>9. Limitations on Liability.</span></p>
					<p>
						9.1 <u>Consequential Damages Exclusion</u>.
						EXCEPT FOR A PARTY’S GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT, NEITHER PARTY MAY BE HELD LIABLE
						FOR ANY LOSS OF PROFITS, LOSS OF USE, LOSS OF GOOD WILL, BUSINESS INTERRUPTION, COMPUTER FAILURE
						OR MALFUNCTION, LOSS OF CONTENT OR DATA, COST OF COVER, OR INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL,
						OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF THESE TERMS.
					</p>
					<p>
						9.2 <u>Limitation of Liability</u>.
						EXCEPT FOR ESGI’S GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT, ESGI’S MAXIMUM CUMULATIVE LIABILITY
						ARISING FROM OR RELATING TO THESE TERMS FOR ANY CAUSE WHATSOEVER, REGARDLESS OF THE FORM OF ANY CLAIM
						OR ACTION, WHETHER BASED IN CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, WILL NOT EXCEED THE AGGREGATE FEES
						PAID BY CUSTOMER TO ESGI IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE OCCURRENCE OF THE EVENT
						GIVING RISE TO THE CLAIM.
					</p>

					<p><span className={styles.bold}>10. Term and Termination.</span></p>
					<p>
						10.1 <u>Term</u>.
						Unless otherwise identified on an applicable purchase order or quote, these Terms begin on the
						Effective Date and, unless earlier terminated pursuant to these Terms, will continue for a period of
						twelve (12) months (the “Initial Term”). Except as may otherwise be specified on an applicable purchase
						order or quote, these Terms will automatically renew for successive twelve (12) month periods
						(each, a “Renewal Term,” and the Initial Term and each Renewal Term, the “Service Term”), unless either
						party provides at least thirty (30) days’ prior written notice (email shall suffice) to the
						other party of its intent not to renew.
					</p>
					<p>
						10.2 <u>Termination for Cause</u>.
						Either party may terminate these Terms with immediate effect, in whole or in part, by giving the
						other party prior written notice, if the other party: (i) commits a material breach of any of
						its obligations under these Terms, which breach is not cured within thirty (30) days following
						receipt of written notice, or the parties agree cannot be cured within thirty (30) days; (ii)
						becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due;
						(iii) files or has filed against it a petition for voluntary or involuntary bankruptcy or otherwise
						becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign
						bankruptcy or insolvency law; (iv) makes or seeks to make a general assignment for the benefit of
						its creditors; (v) applies for or has appointed a receiver, trustee, custodian, or similar agent
						appointed by order of any court of competent jurisdiction to take charge of or sell any material
						portion of its property or business; or (iv) has wound up or liquidated its business, voluntarily or otherwise.
					</p>
					<p>
						10.3 <u>Termination or Suspension by ESGI</u>.
						ESGI may terminate these Terms upon Customer’s failure to make any payments due hereunder,
						and not disputed in good faith, within five (5) business days following Customer’s receipt
						of written notice regarding such failure to pay. If ESGI reasonably suspects that Customer
						or a User is in breach of Section 3.2 or any applicable laws, rules, or regulations, ESGI may
						immediately terminate or suspend Customer’s or such User’s access to the Service without advanced
						notice, in addition to any other remedies as ESGI may have.
					</p>
					<p>
						10.4 <u>Effects of Termination</u>.
						Upon expiration or termination of these Terms: (i) Customer will cease all use of the Service and delete,
						destroy, or return all copies of the applicable Documentation in its possession or control; (ii)
						Customer will be responsible for payment of any monies (including any Fees and expenses) due to
						ESGI for any period prior to the effective date of such termination; and (iii) each party will,
						upon written request of the other party, return or destroy (and provide certification of such
						deletion upon request) any of the other party’s Confidential Information then in its possession,
						provided that ESGI may retain Customer Content and Customer’s Confidential Information in
						accordance with ESGI’s backup retention schedule or to comply with any legal requirement in
						relation to the retention of records. Notwithstanding the foregoing, ESGI has no obligation to
						retain the Customer Content after termination of these Terms.
					</p>
					<p>
						10.5 <u>Surviving Provisions</u>.
						Sections 1 (Definitions), 3.2 (Restrictions), 4 (Fees & Payment), 5 (Proprietary Rights), 6 (Confidentiality),
						7.4 (Disclaimer of Warranties), 8 (Indemnification), 9 (Limitations of Liability), 10.4 (Effects of Termination),
						and 11 (General Provisions) (together with all other provisions that may be reasonably interpreted as
						surviving termination or expiration of these Terms) will survive the termination or expiration of these Terms.
					</p>

					<p><span className={styles.bold}>11. Miscellaneous.</span></p>
					<p>
						11.1 <u>Publicity</u>.
						Subject to the consent of a Customer’s authorized representative, ESGI may include Customer’s Marks in
						marketing material and on its website as a customer of ESGI. Any use of ESGI’s Marks is subject
						to ESGI’s prior consent, in each instance. All use of a party’s Marks will be in accordance with
						its trademark guidelines, if provided.
					</p>
					<p>
						11.2 <u>No Waiver</u>.
						These Terms may not be altered, amended, or modified in any way except by a writing signed by both parties.
						The failure of a party to enforce any provision of these Terms will not be construed to be a waiver of the
						right of such party to thereafter enforce that provision or any other provision or right.
					</p>
					<p>
						11.3 <u>Severability</u>.
						If any provision of these Terms is determined to be invalid, illegal, or unenforceable by a court
						of competent jurisdiction, the rest of these Terms will remain in full force and effect.
					</p>
					<p>
						11.4 <u>Governing Law</u>.
						THESE TERMS WILL BE GOVERNED BY, CONSTRUED, AND INTERPRETED IN ACCORDANCE WITH THE LAWS OF THE STATE OF ILLINOIS,
						WITHOUT GIVING EFFECT TO CHOICE OF LAW OR CONFLICTS OF LAW PRINCIPLES THEREUNDER. The foregoing choice of
						law notwithstanding, copyright, trademark, and patent claims are subject only to U.S. Federal law
						and U.S. Federal court interpretation thereof. You agree that any action at law or in equity arising out of
						or relating to these Terms will be filed only in the state or federal courts located in the
						Northern District of Illinois, Eastern Division.
					</p>
					<p>
						11.5 <u>Relationship</u>.
						ESGI and Customer are independent contractors and these Terms will not establish any relationship
						of partnership, joint venture, employment, franchise, or agency between ESGI and Customer.
						Neither party has any right or authority to assume or create any obligations of any kind or
						to make any representation or warranty on behalf of the other party, whether express or implied,
						or to bind the other party in any respect whatsoever. These Terms do not confer any benefits on
						any third party unless expressly stated therein.
					</p>
					<p>
						11.6 <u>Force Majeure</u>.
						Except for Customer’s payment obligations, neither party will be liable for failure to perform or delay
						in performing any obligation under these Terms if such failure or delay is due to fire, flood, earthquake,
						strike, war (declared or undeclared), embargo, blockade, legal prohibition, governmental action, riot,
						insurrection, epidemic or pandemic, damage, destruction, power outage, telephone outage, internet
						access provider failure, or any other similar cause beyond its control.
					</p>
					<p>
						11.7 <u>Assignment</u>.
						Neither party may assign or delegate its rights, duties, and obligations under these Terms without the
						other party’s prior written consent, which consent will not be unreasonably withheld or delayed, except
						that a party may assign these Terms without the other party’s consent to a successor (including a successor
						by way of merger, acquisition, sale of assets, or operation of law) if the successor agrees to assume
						and fulfill all of the assigning party’s obligations under these Terms; provided, if Customer assigns
						these Terms to a competitor of ESGI (as reasonably determined by ESGI), ESGI may immediately terminate
						these Terms. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties and
						their respective successors and permitted assigns.
					</p>
					<p>
						11.8 <u>Notices</u>.
						Any notice required or permitted by these Terms will be in writing and will be sent by facsimile, email,
						courier, or personal delivery to the address provided to each party. Notice is effective upon receipt.
						Notwithstanding the foregoing, Customer consents to receive electronic communications that may be sent
						by ESGI (including through the Service, such as posting on Customer’s account) and any such communication
						will constitute notice under this Section 11.8.
					</p>
					<p>
						11.9 <u>Entire Agreement</u>.
						These Terms constitute the entire agreement and supersedes all prior or contemporaneous oral
						or written agreements regarding the subject matter hereof. Any purchase order, written terms or conditions,
						or other document that Customer sends to ESGI (whether prior to or after execution of these Terms)
						that contains terms that are different from, in conflict with, or in addition to these Terms are hereby
						rejected and will be void and of no effect.
					</p>
				</div>
				{this.renderBottom()}
			</div>
		</div>;
	}

	renderBottom(): JSX.Element | false | null {
		if (!this.state.token) {
			return null;
		}

		return <div className={styles.actions}>
			<button className='btn btn-primary' onClick={() => this.accept()}>
				Accept
			</button>
			&#160;
			<button className='btn' onClick={() => this.decline()}>
				Decline
			</button>
		</div>;
	}

	decline() {
		AppHistory.navigateOutside('/');
	}
}
