import {Input} from '@esgi/ui/controls';
import {ChangeEvent, useCallback, useState} from 'react';
import {maxValue, minValue} from './constants';

interface Props {
	value: number;
	onChange: (data: number) => void;
}

export function LastWeekControl({value, onChange}: Props) {
	const [controlledValue, setControlledValue] = useState(String(value));

	const handleValueChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value.trim();

			const onlyNumberRegex = /^\d+$/;

			if (value === '' || onlyNumberRegex.test(value)) {
				if (value === '') {
					setControlledValue(value);
					return;
				}

				const numberedValue = Number(value);

				if (numberedValue < minValue) {
					setControlledValue('');
					onChange(minValue);

					return;
				}

				if (numberedValue > maxValue) {
					setControlledValue(String(maxValue));
					onChange(maxValue);

					return;
				}

				setControlledValue(String(numberedValue));
				onChange(numberedValue);
			}
		},
		[onChange],
	);

	return (
		<Input
			placeholder='Last week'
			type='number'
			min={minValue}
			max={maxValue}
			step={1}
			value={controlledValue}
			onChange={handleValueChange}
			dataCy='last-week'
		/>
	);
}
