import {useCallback, useEffect, useState} from 'react';
import {TestSession} from '../../../../../../../../types';

export function useSelectionState({
	testSessions,
	initialSelectedTestSessionIDs,
	initialIsAllSessionsChecked = false,
	initialIsDetailedViewChecked = false,
}: {
	testSessions: TestSession[];
	initialSelectedTestSessionIDs: TestSession['id'][];
	initialIsAllSessionsChecked?: boolean;
	initialIsDetailedViewChecked?: boolean;
}) {
	const [isDetailedViewChecked, setIsDetailedViewChecked] = useState(initialIsDetailedViewChecked);
	const [isAllSessionsChecked, setIsAllSessionsChecked] = useState(initialIsAllSessionsChecked);

	const [selectedSessionIds, setSelectedSessionIds] = useState<TestSession['id'][]>(initialSelectedTestSessionIDs);

	const isAllSessionsSelected = selectedSessionIds.length === testSessions.length;

	useEffect(() => {
		if (isAllSessionsSelected && !isAllSessionsChecked) {
			toggleAllSessionsCheckbox();
		}
	}, [isAllSessionsSelected, isAllSessionsChecked]);

	const toggleDetailedViewCheckbox = useCallback(() => {
		setIsDetailedViewChecked((value) => !value);
	}, []);

	const toggleAllSessionItems = useCallback((isAllItemsSelected: boolean) => {
		if (isAllItemsSelected) {
			setSelectedSessionIds(testSessions.map(({id}) => id));
			return;
		}

		const firstTestSession = testSessions?.[0];
		if (!firstTestSession) {
			setSelectedSessionIds([]);
			return;
		}

		setSelectedSessionIds([firstTestSession.id]);
	}, [testSessions]);

	const toggleAllSessionsCheckbox = useCallback(() => {
		setIsAllSessionsChecked((value) => {
			const newCheckboxValue = !value;
			toggleAllSessionItems(newCheckboxValue);

			return newCheckboxValue;
		});
	}, [toggleAllSessionItems]);

	return {
		isAllSessionsSelected,
		selectedSessionIds,
		isDetailedViewChecked,
		toggleDetailedViewCheckbox,
		isAllSessionsChecked,
		toggleAllSessionsCheckbox,
		setSelectedSessionIds,
	};
}
