import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {Test} from '../kit/tests-control/types';
import {V2PagesReportsStudentProgressController} from '@esgi/contracts/esgi';
import {TestContentArea} from '@esgi/main/kits/common';
import {SubjectInfo} from '../types';
import {Student} from '@esgi/main/libs/store';

export class TestService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public tests$ = new BehaviorSubject<Test[]>([]);

	private controller = new V2PagesReportsStudentProgressController();

	public getTests({studentIDs, subjects}: {studentIDs: Student['id'][]; subjects: SubjectInfo[]}) {
		this.loaded$.next(false);

		this.controller
			.availableTests({
				studentIDs,
				subjects,
			})
			.subscribe({
				next: ({value}) => {
					const flatSubjectTest = value.subjectTests.flatMap((subjectTests) => {
						return subjectTests.tests.map<Test>(
							({testID, name, contentAreaName, correct, incorrect, untested, isTested, type, maxScore}) => {
								const testLocal: Test = {
									id: testID,
									name,
									contentArea: contentAreaName as TestContentArea,
									correct,
									incorrect,
									untested,
									isTested,
									subjectID: subjectTests.subjectID,
									subjectName: subjects.find((x) => x.subjectID === subjectTests.subjectID)?.name ?? '',
									type,
									maxScore,
								};
								return testLocal;
							},
						);
					});

					this.tests$.next(flatSubjectTest);
				},
				complete: () => {
					this.loaded$.next(true);
				},
			});
	}

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}
