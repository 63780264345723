import './styles.less';
import React from 'react';
import {Subject} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';
import {DataService, IContentAreaModel} from '../../services/data-service';
import {FilterType} from '../../enums';
import {FilterModel} from '../../models/filter-model';
import {ISubjectInfo} from '../../services/subjects-service';
import {HiddenSwitch} from './hidden-switch/hidden-switch';
import {SuggestedGradeLevel} from 'api/entities/suggested-grade-level';
import {SearchDescription} from './search-description/search-description';
import {SearchHint} from './search-hint/search-hint';
import {Filter, SearchTags} from './search-tags/search-tags';
import {SortBy} from './sort-by/sort-by';
import {TestType} from '@esgi/core/enums';

class State {
	filter: FilterModel;
	totalResults: number = 0;
	contentAreas: IContentAreaModel[];
	subjects: ISubjectInfo[];
}

class Props {
	dataService: DataService;
}

export default class SearchHeader extends React.Component<Props, State> {
	private onDestroy$: Subject<void> = new Subject();

	constructor(props: Props) {
		super(props);
		const state = new State();
		state.filter = this.props.dataService.filter.currentValue;
		this.state = state;

		this.props.dataService.filter.onChanged$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(value => {
				if (!this.state.filter.subjectId && value.subjectId) {
					this.loadSubjects();
				}
				if (!this.state.filter.contentAreaId && value.contentAreaId) {
					this.loadContentAreas();
				}
				this.setState({filter: value});
			});

		this.props.dataService.search.onChanged$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(value => this.setState({totalResults: value.totalResults}));

		if (this.state.filter.subjectId) {
			this.loadSubjects();
		}
		if (this.state.filter.contentAreaId) {
			this.loadContentAreas();
		}
	}

	private loadSubjects() {
		if (!this.state.subjects) {
			this.props.dataService.subjects.subjectInfos$
				.pipe(delay(1), takeUntil(this.onDestroy$))
				.subscribe(value => {
					this.setState({subjects: value});
				});
		}
	}

	private loadContentAreas() {
		if (!this.state.contentAreas) {
			this.props.dataService.contentAreas
				.pipe(delay(1), takeUntil(this.onDestroy$))
				.subscribe((value) => {
					this.setState({contentAreas: value});
				});
		}
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}

	render() {
		const filter = this.props.dataService.filter.currentValue;
		return <div className='search-header'>
			<div className='first-column'>
				<div className='flex-row'>
					<SearchDescription keyword={filter.keyword} scope={filter.scope}/>
					<SearchHint scope={filter.scope}/>
				</div>
				{this.renderSearchTags()}
			</div>
			<div className='second-column'>
				<HiddenSwitch dataService={this.props.dataService}/>
				<SortBy keywordUsed={!!filter.keyword}
				        sortChanged={(state) => this.props.dataService.filter.update({
					        sortBy: state.value,
					        sortDirection: state.direction,
				        })}/>
			</div>
		</div>;
	}


	private renderSearchTags() {
		const filter = this.state.filter;

		let filters: Filter[] = [];

		let featuredSeries = filter.featuredSeries;
		if (featuredSeries.id) {
			filters.push({
				id: featuredSeries.id,
				name: featuredSeries.name,
				type: FilterType.FeaturedSeries,
			});
		}

		let contentAreaId = filter.contentAreaId;
		if (contentAreaId && this.state.contentAreas) {
			filters.push({
				id: contentAreaId,
				name: this.state.contentAreas.find(c => c.id === contentAreaId).name,
				type: FilterType.ContentArea,
			});
		}

		let subjectId = filter.subjectId;
		if (subjectId && this.state.subjects) {
			filters.push({
				id: subjectId,
				name: this.state.subjects.find(c => c.id === subjectId).name,
				type: FilterType.SubjectTab,
			});
		}

		let gradeLevelId = filter.gradeLevelId;
		if (gradeLevelId) {

			let gl = SuggestedGradeLevel.ByID(gradeLevelId);
			if (gl) {
				filters.push({
					id: gradeLevelId,
					name: gl.name,
					type: FilterType.GradeLevel,
				});
			}
		}

		let notedAuthor = filter.notedAuthor;
		if (notedAuthor) {
			filters.push({
				id: notedAuthor.name,
				name: notedAuthor.name,
				type: FilterType.NotedAuthor,
			});
		}

		let notedSeries = filter.notedSeries;
		if (notedSeries) {
			filters.push({
				id: notedSeries.id,
				name: notedSeries.name,
				type: FilterType.NotedSeries,
			});
		}

    let testType = filter.testType;
    if (testType) {
      let name;
      switch (testType) {
        case TestType.YN:
          name = 'Yes/No'
          break;
        case TestType.Score:
          name = 'Single Score';
          break;
        default:
          name = TestType[testType];
          break;
      }

      filters.push({
        id: FilterType[testType],
        name: name,
        type: FilterType.TestType,
      });
    }

		return <SearchTags filters={filters} total={this.state.totalResults}/>;
	}
}
