import './custom-dropdown.less';
import React from 'react';
import {Item, Dropdown} from './dropdown';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

class Props {
	onSelect: (item: Item) => any;
	onClick?: () => void;
	value: any;
	items: Item[];
	className?: string;
	preventDefault?: boolean = false;
}

class State {
    opened: boolean = false;
    selectedItem: Item;
}

/**
 * @deprecated
 **/
export class CustomDropdown extends React.PureComponent<Props, State> {
	private ref: React.RefObject<HTMLDivElement> = React.createRef();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		if (this.ref.current) {
			this.ref.current.addEventListener('blur', () => this.handleClickOutside());
		}
	}

	componentWillUnmount() {
		if (this.ref.current) {
			this.ref.current.removeEventListener('blur', () => this.handleClickOutside());
		}
	}

	handleClickOutside() {
		setTimeout(() => this.setState({opened: false}), 200);
	}

	private renderIcon() {
		return this.state.opened
			? (<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 5.11133L5 -2.86102e-06L10 5.11133H0Z' fill='#828282' />
			</svg>)
			: (<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 0L5 5.11133L10 0L0 0Z' fill='#828282' />
			</svg>);
	}

	private get maxDropdownHeight() {
		if (this.ref.current) {
			const rect = this.ref.current.getBoundingClientRect();
			return (Math.round($(window).height() - (rect.height + rect.y + 20))) + 'px'; //20 is smooth padding    
		}
		return 'auto';
	}

	private get dropDownWidth() {
		if (this.ref.current) {
			const rect = this.ref.current.getBoundingClientRect();
			return rect.width + 'px'; //20 is smooth padding    
		}
		return 'auto';
	}

	render() {
		const safeText = this.safeGetText(this.props.items.find(item => item.value === this.props.value));
		
		return <div onMouseDown={(e) => this.props.preventDefault && e.preventDefault()}
					onMouseUp={(e) => this.props.preventDefault && e.preventDefault()}
					className={this.props.className ? `form-control dropdown-container ${this.props.className}` : 'form-control dropdown-container'}
					tabIndex={0}
					onClick={() => this.toggle()} ref={this.ref}>
			<OnHoverTooltip message={safeText}>
				<div className='text'>{safeText}</div>
			</OnHoverTooltip>
			<div className='overlay'>
				<div>{this.renderIcon()}</div>
			</div>
			{this.renderDropdown()}
		</div>;
	}

	private renderDropdown() {
		const rect = this.ref && this.ref.current && this.ref.current.getBoundingClientRect() || null;
		if (this.state.opened && rect && rect.left && rect.top) {
			return <Dropdown
				onSelect={this.props.onSelect}
				items={this.props.items}
				coords={{left: rect.left, top: rect.top + 40}}
				size={{height: this.maxDropdownHeight, width: this.dropDownWidth}}
				/>;
		}
	}

	private safeGetText(item: Item) {
		if (item) {
			return item.title;
		}
		return '';
	}

	private toggle() {
		this.setState(prevState => {
			return {opened: !prevState.opened};
		}, () => this.props.onClick && this.props.onClick());
	}
}