import {Input as BaseInput} from './components/input';
import {InputCleanable} from './components/input-cleanable';
import {InputCleanableIndicator} from './components/input-cleanable-indicator';
import {InputWithIcon} from './components/input-with-icon';

export * from './components/input-cleanable-indicator/types'

export const Input = Object.assign(BaseInput, {
	Cleanable: InputCleanable,
	CleanableIndicator: InputCleanableIndicator,
	Iconable: InputWithIcon,
});

export {type InputProps} from './components/input';
