import {
	ResponsesAnswerModel,
	ResponsesStudent,
	ResponsesTrackDates,
	ResponsesTestSession,
	ResponsesReportInit,
	ResponsesTest,
	ResponsesGrade,
	ResponsesScore,
	ResponsesSubjectsGet,
	ResponsesSettingsInit,
	SettingsUser, SettingsUnityItem, SettingsStudent,
} from './api-models';
import {SubjectType} from '@esgi/core/enums';

export interface UnityItem {
	itemID: number;
	itemName: string;
	selectedStudentID: number;
	students: Student[];
}

export interface ISDRSettings {
	classId: number;
	groupId: number;
	studentId: number;
	subjectId: number;
	subjectType: SubjectType;
	testIDs: number[];
	globalSchoolYearID: number;
}

export interface LauncherOptions {
	classId: number;
	groupId: number;
	studentId: number;
	globalSchoolYearId: number;
	subjectId: number;
	userId: number;
	subjectType: SubjectType;
}

export interface DetailReportServiceTransfer {
	settings: ISDRSettings,
	testIDs: number[],
	report: ReportInit,
	students: Student[],
	teacher: User | null,
	displayZeroIfNotTested: boolean,
	printInColor: boolean
	showBaseline: boolean,
	includeQuestionNotes: boolean,
	includeSummaryNotes: boolean,
	includeGradeScore: boolean,
	currentPeriod: string
	markingPeriods: MarkingPeriod[],
}

export class SettingsInit {
	teacher: User;
	classID: number;
	classes: UnityItem[];
	groupID: number;
	groups: UnityItem[];
	specialistGroupID: number;
	specialistGroups: UnityItem[];
	subjects: Subject[];
	report: ReportInit;

	get selectedClass(): UnityItem {
		return this.getSelectedItem(this.classes, this.classID);
	}

	get selectedGroup(): UnityItem {
		return this.getSelectedItem(this.groups, this.groupID);
	}

	get selectedSpecialistGroup(): UnityItem {
		return this.getSelectedItem(this.specialistGroups, this.specialistGroupID);
	}

	getSelectedItem(items: UnityItem[], itemID: number) {
		let result = null;

		if (items && items.length > 0) {
			result = items.filter(s => s.itemID === itemID)[0];

			if (result == null) {
				result = items[0];
			}
		}

		return result;
	}

	static FromResponse(response: ResponsesSettingsInit): SettingsInit {

		const result = new SettingsInit();

		result.teacher = User.FromResponse(response.teacher);
		result.classID = response.classID;
		result.classes = UnityItem.FromResponseArray(response.classes);
		result.groupID = response.groupID;
		result.groups = UnityItem.FromResponseArray(response.groups);
		result.specialistGroupID = response.specialistGroupID;
		result.specialistGroups = UnityItem.FromResponseArray(response.specialistGroups);
		result.subjects = Subject.FromResponseArray(response.subjects);
		result.report = ReportInit.FromResponse(response.report);

		return result;
	}
}

export class User {
	userID: number;
	firstName: string;
	lastName: string;
	title: string;
	includeGradeScore: FlagItem;
	displayZeroIfNotTested: FlagItem;
	carryScoresForward: FlagItem;
	printInColor: FlagItem;
	showBaseline: boolean;
	includeQuestionNotes: FlagItem;
	includeSummaryNotes: FlagItem;
	sortBy: string;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;

	get fullName(): string {
		let result = '';
		if (this.title) {
			result += this.title + ' ';
		}
		if (this.lastName) {
			result += this.lastName;
		}
		return result;
	}

	static FromResponse(response: SettingsUser): User {
		const result = new User();

		result.userID = response.userID;
		result.firstName = response.firstName;
		result.lastName = response.lastName;
		result.title = response.title;
		result.includeGradeScore = FlagItem.FromResponse(response.includeGradeScore);
		result.displayZeroIfNotTested = FlagItem.FromResponse(response.displayZeroIfNotTested);
		result.carryScoresForward = FlagItem.FromResponse(response.carryScoresForward);
		result.printInColor = FlagItem.FromResponse(response.printInColor);
		result.showBaseline = response.showBaseline;
		result.includeQuestionNotes = FlagItem.FromResponse(response.includeQuestionNotes);
		result.includeSummaryNotes = FlagItem.FromResponse(response.includeSummaryNotes);
		result.sortBy = response.sortBy;
		result.testResultsCorrectVerbiage = response.testResultsCorrectVerbiage;
		result.testResultsIncorrectVerbiage = response.testResultsIncorrectVerbiage;

		return result;
	}
}

export interface SimpleUnityItem {
	itemID: number;
	itemName: string;
	selectedStudentID: number;
	students: Student[];
}

export class MarkingPeriod {
	title: string;
	index: number;
}


export class UnityItem {
	itemID: number;
	itemName: string;
	selectedStudentID: number;
	students: Student[];

	get selectedStudent(): Student {
		let result = null;

		if (this.students && this.students.length > 0) {
			result = this.students.filter(s => s.studentID === this.selectedStudentID)[0];

			if (result == null) {
				result = this.students[0];
			}
		}

		return result;
	}

	static FromResponse(response: SettingsUnityItem): UnityItem {
		const result = new UnityItem();

		result.itemID = response.itemID;
		result.itemName = response.itemName;
		result.selectedStudentID = response.selectedStudentID;
		result.students = Student.FromResponseArray(response.students);
		result.students.unshift(Student.makeDefaultStudent());

		return result;
	}

	static FromResponseArray(response: SettingsUnityItem[]): UnityItem[] {
		return response ? response.map(s => {
			return UnityItem.FromResponse(s);
		}) : [];
	}
}

export class Student {
	studentID: number;
	firstName: string;
	lastName: string;

	get fullName(): string {
		let result = '';

		if (this.firstName) {
			result += this.firstName + ' ';
		}

		if (this.lastName) {
			result += this.lastName;
		}

		return result;
	}

	static FromResponse(response: SettingsStudent): Student {
		const result = new Student();

		result.studentID = response.studentID;
		result.firstName = response.firstName;
		result.lastName = response.lastName;

		return result;
	}

	static FromResponseArray(response: SettingsStudent[]): Student[] {
		return response.map(s => {
			return Student.FromResponse(s);
		});
	}

	static makeDefaultStudent(): Student {
		const defaultStudent = new Student;
		defaultStudent.studentID = 0;
		defaultStudent.firstName = 'All';
		return defaultStudent;
	}
}

export class ReportInit {
	trackDates: TrackDates[] = [];
	students: StudentResults[] = [];
	gradeValues: Grade[];
	reportGuid: string;

	get gradeScaleAllow(): boolean {
		return this.gradeValues && this.gradeValues.length > 0;
	}

	static FromResponse(response: ResponsesReportInit): ReportInit {
		const result = new ReportInit();
		if (response) {
			result.trackDates = TrackDates.FromResponseArray(response.trackDates);
			result.students = StudentResults.FromResponseArray(response.students);
			result.gradeValues = (response.gradeValues && response.gradeValues.length > 0)
				? Grade.FromResponseArray(response.gradeValues)
				: null;
			result.reportGuid = response.reportGuid;
		}

		return result;
	}
}

export class TrackDates {
	from: string;
	to: string;

	static FromResponse(response: ResponsesTrackDates): TrackDates {
		const result = new TrackDates();

		result.from = response.from;
		result.to = response.to;

		return result;
	}

	static FromResponseArray(response: ResponsesTrackDates[]): TrackDates[] {
		return response?.map(s => {
			return TrackDates.FromResponse(s);
		});
	}
}

export class StudentResults {
	firstName: string;
	lastName: string;
	tests: Test[];
	fullName: string;

	private static buildFullName(firstName, lastName): string {
		let result = '';

		if (firstName) {
			result += firstName + ' ';
		}

		if (lastName) {
			result += lastName;
		}

		return result;
	}

	static FromResponse(response: ResponsesStudent): StudentResults {
		const result = new StudentResults();

		result.firstName = response.firstName;
		result.lastName = response.lastName;
		result.fullName = this.buildFullName(response.firstName, response.lastName);
		result.tests = Test.FromResponseArray(response.tests);

		return result;
	}

	static FromResponseArray(response: ResponsesStudent[]): StudentResults[] {
		return response ? response.map(s => {
			return StudentResults.FromResponse(s);
		}) : [];
	}
}

export class Test {
	testID: number;
	testName: string;
	questionCount: number;
	answers: Answer[];
	scores: Score[];
	testSessions: TestSession[];
	totalCorrect: number;
	type: string;
	summaryNotes: string;

	static FromResponse(response: ResponsesTest): Test {
		const result = new Test();

		result.testID = response.testID;
		result.testName = response.testName;
		result.questionCount = response.questionCount;
		result.answers = response.answers.map(i => Answer.FromResponse(i));
		result.scores = Score.FromResponseArray(response.scores);
		result.testSessions = TestSession.FromResponseArray(response.testSessions);
		result.totalCorrect = response.totalCorrect;
		result.type = response.type;
		result.summaryNotes = response.summaryNotes;

		return result;
	}

	static FromResponseArray(response: ResponsesTest[]): Test[] {
		return response?.map(s => {
			return Test.FromResponse(s);
		});
	}
}

export class Answer {
	text: string;
	note: string;
	correct: boolean;

	public static FromResponse(response: ResponsesAnswerModel): Answer {
		const a = new Answer();
		a.note = response.note;
		a.text = response.text;
		a.correct = response.correct;

		return a;
	}
}

export class Grade {
	id: number;
	name: string;
	description: string;
	color: string;

	static FromResponse(response: ResponsesGrade): Grade {
		const result = new Grade();

		result.id = response.id;
		result.name = response.name;
		result.description = response.description;
		result.color = response.color;

		return result;
	}

	static FromResponseArray(response: ResponsesGrade[]): Grade[] {
		return response.map(s => {
			return Grade.FromResponse(s);
		});
	}
}

export class Score {
	raw: number;
	testDate?: string;
	grade: string;
	color: string;

	static FromResponse(response: ResponsesScore): Score {
		const result = new Score();

		result.raw = response.raw;
		result.testDate = response.testDate;
		result.grade = response.grade;
		result.color = response.color;

		return result;
	}

	static FromResponseArray(response: ResponsesScore[]): Score[] {
		return response.map(s => {
			return Score.FromResponse(s);
		});
	}
}

export class TestSession {
	date: string;
	correctAnswers: number;

	static FromResponse(response: ResponsesTestSession): TestSession {
		const result = new TestSession();

		result.date = response.date;
		result.correctAnswers = response.correctAnswers;

		return result;
	}

	static FromResponseArray(response: ResponsesTestSession[]): TestSession[] {
		return response.map(s => {
			return TestSession.FromResponse(s);
		});
	}
}

export class SubjectsGet {
	selectedSubjectID: number;
	subjects: Subject[];

	get selectedSubject(): Subject {
		let result = null;

		if (this.subjects && this.subjects.length > 0) {
			result = this.subjects.filter(s => s.subjectID === this.selectedSubjectID)[0];

			if (result == null) {
				result = this.subjects[0];
			}
		}

		return result;
	}

	static FromResponse(response: ResponsesSubjectsGet): SubjectsGet {
		const result = new SubjectsGet();

		result.selectedSubjectID = response.selectedSubjectID;
		result.subjects = Subject.FromResponseArray(response.subjects);

		return result;
	}
}

export class Subject {
	subjectID: number;
	name: string;
	subjectType: string;
	subjectLevel: string;

	get apiModel(): Subject {
		const result = new Subject();

		result.subjectID = this.subjectID;
		result.name = this.name;
		result.subjectType = this.subjectType;
		result.subjectLevel = this.subjectLevel;

		return result;
	}

	static FromResponse(response: Subject): Subject {
		const result = new Subject();

		result.subjectID = response.subjectID;
		result.name = response.name;
		result.subjectType = response.subjectType;
		result.subjectLevel = response.subjectLevel;

		return result;
	}

	static FromResponseArray(response: Subject[]): Subject[] {
		return response.map(s => {
			return Subject.FromResponse(s);
		});
	}
}

export enum FileType {
	PDF, ZIP
}

export class FlagItem {
	name: string;
	value: boolean;

	static FromResponse(response: FlagItem): FlagItem {
		const result = new FlagItem();

		result.name = response.name;
		result.value = response.value;

		return result;
	}
}
