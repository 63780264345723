import React, {useCallback, useEffect, useRef} from 'react';
import {TestType} from '@esgi/core/enums';
import {AsyncHighchartsReact} from '@esgi/deprecated/highcharts';
import {ChartBlockModel, TestModel, OnRenderedParams} from 'shared/modules/reports/pie-chart/service/models';
import {formattedDate} from 'shared/modules/reports/pie-chart/service/utils';
import NotTested from 'shared/modules/reports/pie-chart/components/cards/not-tested';

import styles from '../cards.module.less';

export interface PersonalCardProps {
	printInColor: boolean;
	uniqueID: string;
	chartBlockModel: ChartBlockModel;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	test: TestModel;
	onCardRendered?: (params: OnRenderedParams) => void;
	onChartRendered?: () => void;
}

export default function PersonalCard ({
	test,
	onCardRendered,
	onChartRendered,
	chartBlockModel,
	testResultsIncorrectVerbiage,
	testResultsCorrectVerbiage,
	printInColor,
}: PersonalCardProps) {

	const cardRef = useRef(null);

	const isNotTested = !chartBlockModel.testResult;

	const renderChart = useCallback(() => {

		if (!chartBlockModel.correctPercent) {
			chartBlockModel.pieChartOptions.series[0].data[0].color = '#FFF';
		} else {
			chartBlockModel.pieChartOptions.series[0].data[0].color = printInColor ? '#00BF96' : '#7B7B7B';
		}


		if(isNotTested) {
			return <NotTested/>;
		} else {
			return (
				<AsyncHighchartsReact
					chartRendered={onChartRendered}
					options={chartBlockModel.pieChartOptions}
					className={styles.chartContainer}
					showLoader
				/>
			);
		}
	}, [chartBlockModel.pieChartOptions, isNotTested, printInColor]);

	const renderLegend = useCallback(() => {
		return (
			<div data-cy='chart-legend' className={styles.legend}>
				<div className={styles.correct}>
					<div
						className={styles.percents + (printInColor ? ` ${styles.colored}` : '')}>{chartBlockModel.correctPercent}%
					</div>
					<div className={styles.title}>{testResultsCorrectVerbiage.toLowerCase()}</div>
				</div>
				<div className={styles.incorrect}>
					<div className={styles.percents}>{100 - chartBlockModel.correctPercent}%</div>
					<div className={styles.title}>{testResultsIncorrectVerbiage.toLowerCase()}</div>
				</div>
			</div>
		);
	}, [printInColor, testResultsCorrectVerbiage, testResultsIncorrectVerbiage, chartBlockModel.correctPercent]);

	useEffect(() => {
		const resultDataHeight = 120;
		const margin = 20;
		const bounds = cardRef.current.getBoundingClientRect();

		onCardRendered({
			width: bounds.width + margin,
			height: bounds.height + resultDataHeight + margin,
		});
	}, [onCardRendered]);

	return (
		<div className={styles.result}>
			<div data-cy='test-title' className={styles.testName} ref={cardRef}>{test?.name}</div>
			<div className={styles.resultData}>
				<div className={styles.chart}>
					<div data-cy='high-chart'>{renderChart()}</div>
					{!!chartBlockModel.testResult && renderLegend()}
				</div>
				<div className={styles.info}>
					{chartBlockModel?.testResult != null &&
						<>
							<div>
								<div className={styles.infoTitle}>
									{test.type.toString() === TestType[TestType.YN] ? testResultsCorrectVerbiage + ' Answers' : 'Score'}
								</div>
								<div data-cy='correct-answers' className={styles.correctAnswersResult}>
									{chartBlockModel?.testResult?.correctAnswers}/{test.maxScore}
								</div>
							</div>
							<div data-cy='last-test-date'>
								<div className={styles.infoTitle}>Last Test Date</div>
								<div>{formattedDate(chartBlockModel?.testResult)}</div>
							</div>
						</>
					}
					{!chartBlockModel.testResult &&
						<div>
							Not tested yet
						</div>
					}
				</div>
			</div>
		</div>
	);
}
