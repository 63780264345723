import {AppEvent} from '@esgillc/events';

@AppEvent({broadcast: true})
export class SchoolYearChangedEvent {
	constructor(public schoolYearID: number) {
	}
}

@AppEvent({broadcast: true})
export class CurrentSchoolYearEvent {
	constructor(public schoolYear: {globalSchoolYearID: number, name: string}) {
	}
}
