import {usePieChartService} from '../../../../hooks';
import {join} from '@esgillc/ui-kit/utils';
import Filter from 'shared/modules/reports/pie-chart/components/header/components/filter';
import {FilterType} from 'shared/modules/reports/pie-chart/service/models';
import {Checkbox} from '@esgillc/ui-kit/control';
import {useSettings} from './hooks';
import styles from './index.module.less';

/**
 * This is a temporary solution, so no styled components are used.
 * Just the old version with minimal adaptation.
 */
export function Settings() {
	const service = usePieChartService();
	const {
		reportData,
		unitDataSettings,
		printSettings,
		subjects,
		teachers,
		classes,
		groups,
		students,
		teacherID,
		tab,
		state,
		setState,
	} = useSettings(service);
	const noStudentsData = reportData?.students.length === 0;

	return printSettings && (
		<div className={styles.reportSettings}>
			<div className={join(styles.container, styles.filters)}>
				{teachers.length > 0 &&
					<Filter
						label='Teacher'
						type={FilterType.Teacher}
						entity={teachers}
						entityID={teacherID}
						onChange={() => {}}
					/>
				}
				{(tab !== 'groups' && classes.length > 0) &&
					<Filter
						label='Class'
						type={FilterType.Unity}
						entity={classes}
						entityID={state.classId}
						onChange={({target}) => {
							const classId = +target.value;
							service.setEntity({classId: classId || -1, groupId: null, studentId: null});
							setState({...state, classId, studentId: 0});
						}}
					/>
				}
				{(tab === 'groups' && groups.length > 0) &&
					<Filter
						label='Group'
						type={FilterType.Unity}
						entity={groups}
						entityID={state.groupId}
						onChange={({target}) => {
							let groupId = +target.value;
							let classId = null;
							if (groupId === 0) {
								groupId = null;
								classId = -1;
							}
							service.setEntity({classId, groupId, studentId: null});
							setState({...state, groupId, studentId: 0});
						}}
					/>
				}
				<Filter
					label='Student'
					type={FilterType.Student}
					entity={students}
					entityID={state.studentId}
					onChange={({target}) => {
						let studentId = +target.value;
						let classId = null;
						let groupId = null;
						if (studentId === 0 && tab !== 'groups') {
							classId = state.classId || -1;
							groupId = null;
							studentId = null;
						}
						if (studentId === 0 && tab === 'groups') {
							classId = state.groupId === 0 ? -1 : null;
							groupId = state.groupId === 0 ? null : state.groupId;
							studentId = null;
						}
						service.setEntity({
							classId,
							groupId,
							studentId,
							fromClassId: state.classId || null,
							fromGroupId: state.groupId || null,
						});
						setState({...state, studentId: studentId || 0});
					}}
				/>
				<Filter
					label='Subject Tab'
					type={FilterType.Subject}
					entity={subjects}
					entityID={unitDataSettings?.selectedSubject.id}
					hasAll={false}
					onChange={({target}) => service.setSubject(+target.value)}
				/>
			</div>
			{!noStudentsData &&
				<div className={styles.container}>
					<div data-cy='settings-title' className={styles.checkboxTitle}>Settings:</div>
					{unitDataSettings.selectedStudent === null &&
						<Checkbox
							className={join(styles.checkbox, printSettings.showEachStudent && styles.bold)}
							checked={printSettings.showEachStudent}
							onChange={({target}) => service.setShowEachStudent(target.checked)}
							data-cy='show-each-checkbox'
						>
							Show each student
						</Checkbox>
					}
					<Checkbox
						className={join(styles.checkbox, printSettings.printInColor && styles.bold)}
						checked={printSettings.printInColor}
						onChange={({target}) => service.setPrintInColor(target.checked)}
						data-cy='print-color-checkbox'
					>
						Print in color
					</Checkbox>
				</div>
			}
		</div>
	);
}
