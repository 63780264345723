import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {TestResult} from '@esgi/contracts/esgi/types/esgi.assets/endpoints/esgi.apigateway/modules/activities/bingo/tests/test-result';
import {StyledTestResultContainer} from './index.styled';

interface Props {
	testResults: TestResult | null;
}

export function BingoTestWidget({testResults}: Props) {
	if (!testResults) {
		return (
			<FlexBox justify='end'>
				<Text color='lowContrast' font='mono' size='small'>
					No Results
				</Text>
			</FlexBox>
		);
	}

	return (
		<StyledTestResultContainer>
			<Text color='positive' size='small'>
				{testResults.correctCount}
			</Text>
			<Text color='negative' size='small'>
				{testResults.incorrectCount}
			</Text>
			<Text color='neutral56' size='small'>
				{testResults.notTestedCount}
			</Text>
		</StyledTestResultContainer>
	);
}