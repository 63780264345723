import * as React from 'react';
import type {SVGProps} from 'react';

export function Folder(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M10.667 8A2.667 2.667 0 0 0 8 10.667v9.777A3.556 3.556 0 0 0 11.556 24h8.888A3.556 3.556 0 0 0 24 20.444v-8a.89.89 0 0 0-.889-.888h-4.444c-.671 0-.887-.218-1.417-1.279C16.447 8.671 15.773 8 14.222 8h-3.555Zm0 1.778h3.555c.671 0 .887.218 1.417 1.278.116.232.164.306.277.5h-3.5a.889.889 0 1 0 0 1.777h9.806v7.111c0 .983-.795 1.778-1.778 1.778h-8.888a1.777 1.777 0 0 1-1.778-1.778v-9.777a.89.89 0 0 1 .889-.89Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
