import {Users} from '@esgi/ui';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {listCSS} from './components.styled';
import {OneLinedText} from '@esgi/main/kits/common';

export function GroupsList() {
	const {
		selectGroup,
		selected: {groupId: selectedGroupId},
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	const {groupsWithStudents} = StudentsPanel.Tabs.Groups.useGroupsContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			<StudentsPanel.Lists.SelectableList.Single
				items={groupsWithStudents}
				onItemClick={(groupId) => selectGroup(groupId)}
				value={selectedGroupId}
				itemVariant='outlined'
				css={listCSS}
				IconBefore={Users}
			>
				{({item: {name}}) => (
					<OneLinedText size='large' color='neutral6'>
						{name}
					</OneLinedText>
				)}
			</StudentsPanel.Lists.SelectableList.Single>
		</StudentsPanel.Tabs.TabContent>
	);
}
