import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {StudentRosterUploadTool} from 'shared/modules/student-roster-upload-tool';
import {ProfileModal} from './components/profile-modal';
import {StudentLimitAlert} from 'modules/forms/students-form/components/student-limit-alert';
import {StudentsFormProps, useStudentsForm} from 'modules/forms/students-form/hooks/use-students-form';

function StudentProfile(props: StudentsFormProps): JSX.Element {
	const {
		isInitialized,
		isStudentProfileModalOpen,
		isLimitAlertOpen,
		isStudentRosterUploadToolOpen,
		profileKey,
		onImageChanged,
		onClose,
		studentID,
		onAddIndividually,
		onViewStudentProfile,
		onCloseStudentProfile,
		handleRoasterUpload,
		handleChangeSchoolId,
		setStudentRosterUploadTool,
		schools,
		preSelected,
		initData,
		mode,
		selectedSchoolId,
		selectedStep,
		service,
		uploadedFile,
	} = useStudentsForm(props);

	const onDeleteStudent = () => {
		service.deleteStudent().subscribe(() => {
			onClose();
		});
	};

	return <>
		<ServiceLoader trackingService={service} fullscreen/>
		{isInitialized && <>
			{isStudentProfileModalOpen &&
				<div data-cy='student-profile'>
					<ProfileModal
						key={profileKey}
						onImageChanged={onImageChanged}
						onDeleteStudent={onDeleteStudent}
						preSelected={preSelected}
						initData={initData}
						studentID={studentID}
						mode={mode}
						onClose={onCloseStudentProfile}
						setUploadRosterModalOpen={handleRoasterUpload}
					/>
				</div>}
			{isLimitAlertOpen && <StudentLimitAlert limit={initData.limit} onClose={onClose}/>}
			{isStudentRosterUploadToolOpen &&
				<StudentRosterUploadTool
					schools={schools}
					onExit={() => {
						if(!isStudentProfileModalOpen){
							onClose();
						}

						setStudentRosterUploadTool(false);
						service.uploadedFile.next(null);
					}}
					onAddIndividualityClicked={onAddIndividually}
					userID={preSelected?.userID}
					userType={preSelected?.userType}
					classID={preSelected?.classID}
					schoolID={selectedSchoolId ?? preSelected?.schoolID}
					specialistGroupID={preSelected?.specialistGroupID}
					initialStep={selectedStep}
					onSchoolSelected={handleChangeSchoolId}
					onGetStudentProfile={onViewStudentProfile}
					onChangeFileData={(f) => service.uploadedFile.next(f)}
					uploadedFile={uploadedFile}
				/>
			}

		</>}
	</>;
}

export default StudentProfile;
