import {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {DrawerWithSearchParams, DrawerWithSearchParamsProps} from '../../../drawer-with-search-params';
import {DrawerContentWrapper, DrawerWrapper, PanelBox, drawerWrapperWidth} from './index.styled';
import {DrawerContext, DrawerContextValue} from '../../context/drawer-context';
import {Skeletonable, isUndefined} from '@esgi/ui';
import {DrawerSkeleton} from '../drawer-skeleton';
import {useSpring, config} from 'react-spring';
import {PanelAnimatedBox} from '../panel-animated-box';
import {PanelTrigger} from '../drawer-panel/components/panel-trigger';

export {drawerPaddingInline} from './index.styled';

type Props = Omit<DrawerWithSearchParamsProps, 'drawerRef' | 'onClickOutside'> &
	Skeletonable & {
		onDrawerClose?: VoidFunction;

		/**
		 *
		 * @returns If beforeCloseDrawerCb returns `true`, callback on close will be executed
		 */
		beforeCloseDrawerCb?: () => boolean;
	};

export function DrawerRoot({onDrawerClose, children, skeleton, beforeCloseDrawerCb, ...props}: Props) {
	const [withPanel, setWithPanel] = useState(false);
	const [isPanelOpen, setIsPanelOpen] = useState(false);

	const [panelWidth, setPanelWidth] = useState(0);

	const drawerWrapperRef = useRef<HTMLDivElement>(null);
	const panelRef = useRef<HTMLDivElement>(null);

	const togglePanelOpen = useCallback(() => {
		setIsPanelOpen((currentValue) => !currentValue);
	}, []);

	useLayoutEffect(() => {
		if (drawerWrapperRef.current) {
			const contentWrapper = drawerWrapperRef.current.parentElement;

			if (contentWrapper) {
				contentWrapper.style.width = 'max-content';
			}
		}
	}, [drawerWrapperRef, withPanel]);

	const drawerRef = DrawerWithSearchParams.useRef();
	const closeDrawer = DrawerWithSearchParams.useClose(drawerRef, onDrawerClose);

	const handleCloseDrawer = useCallback(() => {
		if (isUndefined(beforeCloseDrawerCb) || beforeCloseDrawerCb()) {
			closeDrawer();
		}
	}, [beforeCloseDrawerCb, closeDrawer]);

	const drawerContextValue = useMemo<DrawerContextValue>(
		() => ({
			closeDrawer,
			closeDrawerWithCbBefore: handleCloseDrawer,
			withPanel,
			setWithPanel,
			togglePanelOpen,
			isPanelOpen,
			panelRef,
		}),
		[closeDrawer, handleCloseDrawer, withPanel, togglePanelOpen, isPanelOpen],
	);

	const panelAnimationStyles = useSpring({
		left: isPanelOpen ? -(panelWidth - drawerWrapperWidth) : 0,
		config: {
			...config.gentle,
			clamp: true,
		},
	});

	return (
		<>
			<DrawerWithSearchParams drawerRef={drawerRef} onClickOutside={handleCloseDrawer} {...props}>
				<DrawerContext.Provider value={drawerContextValue}>
					{skeleton && <DrawerSkeleton />}
					<DrawerContentWrapper ref={drawerWrapperRef} withPanel={withPanel} dataCy={props.dataCy}>
						{withPanel && (
							<PanelBox onClick={togglePanelOpen} style={panelAnimationStyles}>
								<PanelTrigger />
							</PanelBox>
						)}
						<DrawerWrapper>{children}</DrawerWrapper>
					</DrawerContentWrapper>
				</DrawerContext.Provider>
			</DrawerWithSearchParams>

			{/* next div uses as container for rendering panel */}
			{withPanel && (
				<PanelAnimatedBox
					isPanelOpen={isPanelOpen}
					panelRef={panelRef}
					panelWidth={panelWidth}
					setPanelWidth={setPanelWidth}
				/>
			)}
		</>
	);
}
