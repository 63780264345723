import {Observable} from 'rxjs';
import {SightWordsReport} from '../templates/sight-words/types';
import {BasePdfDownloadingService} from './base-pdf-downloading-service';
import * as modelUtils from '../utils/utils';
import {SsoTracker} from '@esgi/core/tracker';

export class SightWordsPdfDownloadingService extends BasePdfDownloadingService<SightWordsReport> {
	protected override executePdfDownloadingRequest(report: SightWordsReport): Observable<Blob> {
		const date = new Date().toLocaleDateString('en-US');
		const request = {
			documentDate: date,
			lecturerName: modelUtils.getLecturerName(report.details.lecturer),
			unitType: report.details.unitType,
			copies: report.details.copiesCount,
			fontSize: report.details.fontSize,
			students: report.students.sort((first, second) => first.unitId - second.unitId),
			words: report.words,
			columns: report.details.columns,
		};
		SsoTracker.trackEvent({trackingEvent: 'WritingPractice-SightWords'});
		return this.httpClient.ESGIApi.file(this.controller, 'sight-words/pdf', `Sight_Words_${date}.pdf`, request);
	}
}