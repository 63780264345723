import {Filter} from '../types';
import {Dispatch, useCallback, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

function toNumber(value: string, fallback: number) {
	const parsed = Number.parseInt(value);
	return Number.isNaN(parsed) ? fallback : parsed;
}

export function useFilter(): [Filter, Dispatch<Partial<Filter>>] {
	const [params, setParams] = useSearchParams();

	const [filter, setFilter] = useState(() => {
		return {
			keyword: params.get('keyword') || '',
			teacherID: toNumber(params.get('teacherID'), 0),
			classID: toNumber(params.get('classID'), 0),
			groupID: toNumber(params.get('groupID'), 0),
			gradeLevelID: toNumber(params.get('gradeLevelID'), 0),
		};
	});

	const update = useCallback((value: Partial<Filter>) => {
		const newFilter = ({...filter, ...value});
		for (const key in newFilter) {
			const value = newFilter[key];
			if (value) {
				params.set(key, value);
			} else {
				params.delete(key);
			}
		}

		setParams(params);
		setFilter(newFilter);
	}, [filter, params, setParams]);

	return [filter, update];
}