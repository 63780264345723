import React, {useRef} from 'react';
import {Input} from '@esgillc/ui-kit/control';
import {join} from '@esgillc/ui-kit/utils';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {IFormControlValidatorResult} from '@esgi/deprecated/elements';
import {ClearIcon} from 'shared/modules/student-roster-upload-tool/icons/clear-icon';
import {ConflictLevel, ValidationType} from 'shared/modules/student-roster-upload-tool/types';
import {RenderConflictIcon} from 'shared/modules/student-roster-upload-tool/components/steps/conflict-resolution/components/render-conflict-icon';
import styles from './styles.module.less';

interface Props {
	value: string;
	conflictLevel: ConflictLevel;
	validation: IFormControlValidatorResult;
	onEdit: (value: string) => void;
	onClick: () => void;
	tableHeight: number;
	tableOffset: number;
	validationType: ValidationType;
}

export function TextInputConflict({
	validation = {
		valid: false,
		message: '',
	},
	validationType,
	value,
	conflictLevel,
	onEdit,
	onClick,
}: Props) {

	const inputRef = useRef<HTMLInputElement>();

	const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		onClick();
	};

	return <div data-cy={'text-input-conflict'} className={styles.textInputConflict}>
		{!validation.valid && <ValidationTooltip show={1} placement={'top'} element={inputRef.current}>
			{validation.message}
		</ValidationTooltip>}
		<Input
			ref={inputRef}
			value={value}
			onChange={(e) => onEdit(e.target.value)}
			className={join(
				Number(!validation.valid) && styles.error,
			)}
			onClick={(e) => handleOnClick(e)}
		/>
		<div className={styles.closeTextInputButton} onClick={() => onEdit('')}>
			<ClearIcon/>
		</div>
		<div data-cy={'conflict-icon-container'}
				 onClick={(e) => handleOnClick(e)}
				 className={join(styles.conflictIcon,
					 !validation.valid && (validationType >= ValidationType.Conflict) ? styles.show : styles.hide)
				 }>
			<RenderConflictIcon conflictLevel={conflictLevel}/>
		</div>
	</div>;
}
