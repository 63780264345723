import {useMemo} from 'react';
import {HierarchySnapshot} from 'modules/hierarchy/core/models/snapshot/hierarchy-snapshot';
import {IEPGoalFormService} from '../../services';
import {AddContainer} from '../../variants/add/add-container';
import {EditContainer} from '../../variants/edit/edit-container';
import {HelpLink, Modal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import styles from '../../styles.module.less';
import {IEPDatesButton} from 'modules/forms/iep-goal-form/variants/buttons/iep-dates-btn';
import {MarkAsCompletedField} from 'modules/forms/iep-goal-form/variants/fields';

interface Props {
	service: IEPGoalFormService;
	hierarchy: HierarchySnapshot;
}

export function Body({service}: Props) {
	const iepGoal = useBehaviorSubject(service.iepGoal$);

	const hasGoal = useMemo(() => iepGoal?.id || false, [iepGoal]);

	return (
		<Modal.Body>
			<div className={styles.modalBody}>
				<div className={styles.headerText}>
					<span className={styles.title}>
						<span>IEP Goal</span>
						<HelpLink url={'https://support.esgisoftware.com/hc/en-us/categories/201288126-ESGI'}/>
					</span>
					<IEPDatesButton service={service}/>
				</div>
				{hasGoal &&
					<EditContainer service={service}/>
				}
				{!hasGoal &&
					<AddContainer service={service}/>
				}
			</div>
		</Modal.Body>
	);
}
