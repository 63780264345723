import React from 'react';
import {SsoTracker} from '@esgi/core/tracker';
import {Api} from 'shared/modules/reports/flash-cards/results-form/api/api';
import {FileType} from 'shared/modules/reports/flash-cards/settings-form/api/models';

class State {
}

class Props {
	allStudentsSelected: boolean;
	reportGuid: string;
	onLoading: (status: boolean) => void;
	close: () => any;
	settingsClose: () => void;
}

export default class ResultFormHeader extends React.Component<Props, State> {
	private api: Api;

	constructor(props?: Props) {
		super(props);
		this.state = new State();
		this.api = new Api();
	}

	download(fileType: FileType, zip: boolean, allStudentsSelected: boolean) {
		this.props.onLoading(true);

		const callBack = () => {
			this.props.onLoading(false);
		};

		this.api.downloadFile(this.props.reportGuid, fileType, zip, callBack);

		if (allStudentsSelected) {
			const eventType = zip ? 'PDF Bulk' : 'PDF Standard';
			SsoTracker.trackEvent({
				trackingEvent: eventType,
				data: {reportType: 'FlashCards'},
			});
		}
	}

	render() {
		return <div className='modal-header'>
			<div className='fc-report-header'>
				<a href='#' className='btn-back' onClick={() => this.props.close()}>
					<i className='fa fa-angle-left'/>
					<span>Back</span>
				</a>
				<div className='report-name'>Flashcards</div>
				<div className='print-download-buttons'>
					<div className='download btn-group'>
						{!this.props.allStudentsSelected &&
							<a className='download-link'
							   onClick={() => this.download(FileType.PDF, false, this.props.allStudentsSelected)}>
								<i className='fa fa-download'/> Download
							</a>
						}
						{this.props.allStudentsSelected &&
							<>
                            <span className='toggle-download-dropdown-text' data-toggle='dropdown'>
                                <i className='fa fa-download'/> Download
										</span>
								<ul className='dropdown-menu dropdown-default'>
									<li>
										<a href='#' className='download-one-pdf-link'
										   onClick={() => this.download(FileType.PDF, false, this.props.allStudentsSelected)}>
											<div>One PDF file</div>
											<div>per student or class</div>
										</a>
									</li>
									<li>
										<a href='#' className='download-zip-link'
										   onClick={() => this.download(FileType.PDF, true, this.props.allStudentsSelected)}>
											<div>ZIP</div>
											<div>Individual PDF files for each student in the class</div>
										</a>
									</li>
								</ul>
							</>
						}
					</div>
					<a href='#' className='close' onClick={() => {
						this.props.close();
						this.props.settingsClose();
					}}>
						<svg width='24' height='24' viewBox='0 0 48 48'>
							<g fill='none' fillRule='evenodd'>
								<rect width='48' height='48'/>
								<path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'
								      transform='matrix(-1 0 0 1 48 0)'/>
								<path stroke='#FFFFFF' strokeWidth='4' d='M13,13 L35,35' strokeLinecap='round'/>
							</g>
						</svg>
					</a>
				</div>
			</div>
		</div>;
	}
}