import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const TimeMeridiem = styled(Box, {
	display: 'inline-flex',
	borderWidth: 1,
	borderStyle: 'solid',
	alignItems: 'center',
	overflow: 'hidden',
	borderRadius: 2,
	height: 16,
});

export const TimeText = styled(Text, {
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 3,
	paddingRight: 3,
	backgroundColor: '$vivid',
	minWidth: 34,
	textAlign: 'center',
});

export const MeridiemText = styled(Text, {
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
});
