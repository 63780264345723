import {Suspense, useMemo} from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';

const TeacherAndSpecialistSettings = lazyComponent(() => import('./teacher-and-specialist'));
const SchoolAdministratorSettings = lazyComponent(() => import('./school-administrator'));
const DistrictAdministratorSettings = lazyComponent(() => import('./district-administrator'));

interface Props {
	close: () => void;
}

function UserSettings({close}: Props) {
	const {userID, userType} = userStorage.get();
	const renderUserSettings = useMemo(() => {
		if (userType === UserType.D) {
			return <DistrictAdministratorSettings
				onClose={close}
				userID={userID}
			/>;
		}
		if (userType === UserType.C) {
			return <SchoolAdministratorSettings
				onClose={close}
				userID={userID}
			/>;
		}
		// @ts-ignore
		if (userType !== UserType.T || userType !== UserType.ISS || userType !== UserType.ISD) {
			return <TeacherAndSpecialistSettings
				userID={userID}
				userType={userType}
				onClose={close}
			/>;
		}
		return null;

	}, [userID, UserType]);

	return <Suspense fallback={<div/>}>
		{renderUserSettings}
	</Suspense>;
}

export default UserSettings;
