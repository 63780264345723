import {ClassResponse, StudentResponse} from './types';
import {resolveValueType} from '../kit';

// time in ms
export function formatDate(time: number): string {
	const timeInSeconds = Math.round(Math.abs(time) / 1000);

	const seconds = Math.round(timeInSeconds % 60);
	const minutes = Math.floor(timeInSeconds / 60);

	const secondsString = String(seconds).padStart(2, '0');

	return `${String(minutes).padStart(2, '0')}:${secondsString}`;
}

export function formatTimes(data: ClassResponse | StudentResponse) {
	const {lastWeekAverage, allTimeAverage, delta} = data;

	const positive = delta < 0;
	const sign = positive ? '+' : '-';

	const lastWeekAverageFormatted = formatDate(lastWeekAverage);
	const allTimeAverageFormatted = formatDate(allTimeAverage);
	const deltaFormatted = (delta !== 0 ? sign : '') + formatDate(delta);

	return {
		deltaType: resolveValueType(delta),
		lastWeekAverage: lastWeekAverageFormatted,
		allTimeAverage: allTimeAverageFormatted,
		delta: deltaFormatted,
	};
}
