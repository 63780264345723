import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CardViewType} from '../../../../types';
import {gridCardsContainerCSS} from '../grid-cards-container.styled';

export const ContentWrapper = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',
	gridTemplateRows: '1fr auto',
	gap: '$5',
});

export const CardsContainer = styled(GridBox, {
	variants: {
		cardsVariant: {
			[CardViewType.Grid]: gridCardsContainerCSS,

			[CardViewType.List]: {},
		},
	},
});
