import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {Button} from './components.styled';

type Props = {
	disabled?: boolean;
};

export function SelectAllClassesButton({disabled}: Props) {
	const {
		selectClass,
		selected,
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	return (
		<Button
			label='All Classes'
			selected={selected.classId === -1}
			onClick={() => selectClass(-1)}
			disabled={disabled}
		/>
	);
}
