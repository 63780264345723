import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {join} from '@esgillc/ui-kit/utils';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	SharedProps,
} from '@esgi/deprecated/react';
import {Api, FolderInfo} from '../../../api';
import {Events} from '../../../models';
import {IGModal} from '../modal';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {ImageUpload} from './image-upload';

export class State {
	folderID?: number;
	folderInfos: FolderInfo[] = null;
	files: File[] = [];
	loading: boolean = false;
	loadCompleted: boolean = false;
	folderOptionOpened: boolean = false;
}

export class Props extends SharedProps<State> {
	folderID?: number;
	close: () => any;
}

export class UploadImagesModal extends IGModal<State, Props> {

	private readonly maxFileSize = 5120000;

	getImageTitle(file: File): string {
		if (file.size > this.maxFileSize) {
			return 'This file is too big to upload. The file must be less than 5MB';
		} else {
			return file.name.slice(0, 35);
		}
	}

	hasLargeFiles() {
		return !!this.state.files.filter(f => f.size > this.maxFileSize).length;
	}

	componentDidMount(): void {
		super.componentDidMount();
		Api.GetFolderHierarchy().subscribe(data => {
			const folderInfos = data.folderInfos;
			this.setState({folderID: this.props.folderID, folderInfos: folderInfos});
		});
	}

	onEnter = () => this.upload();

	upload() {
		if (!this.state.files.length) {
			return;
		}
		Api.UploadImages(this.state.files, this.state.folderID).subscribe({
			next: (resp) => {
				if (resp.errorType == null) {
					this.afterUpload();
					return;
				}
				if (resp.errorType === 'SizeLimit') {
					OldAlerts.bsalert('The file must be less than 5MB', () => {
						this.setState({loading: false});
					});
				} else if (resp.errorType === 'InvalidFormat') {
					OldAlerts.bsalert('Sorry. You can only upload JPEG, PNG, WebP, GIF, AVIF, TIFF and SVG files', () => {
						this.setState({loading: false});
					});
				} else {
					OldAlerts.bsalert('Uh oh. Something went wrong on our end. Please contact Customer Support (support@esgisoftware.com) for assistance.', () => {
						this.setState({loading: false});
					});
				}

			},
			error: () => {
				this.setState({loading: false});
			},
			complete: () => {
				this.setState({loading: false});
			},
		});
		this.setState({loading: true});
	}

	afterUpload() {
		setTimeout(() => {
			this.props.close();
			this.dispatch(Events.ItemCreated, {});
		}, 1000);
		this.setState({loadCompleted: true});
	}

	onUploadInputChange = event => {
		this.addFiles(event.target.files);
	};

	onFilesDrop = event => {
		event.stopPropagation();
		event.preventDefault();
		this.addFiles(event.dataTransfer.files);
	};

	onFilesDragOver = event => {
		event.stopPropagation();
		event.preventDefault();
		event.dataTransfer.dropEffect = 'copy';
	};

	addFiles = (newFiles: FileList) => {
		let files = Array.from(newFiles);
		files = files.concat(this.state.files).filter((file, index, self) => {
			return self.map(x => x.name).indexOf(file.name) === index;
		});
		this.setState({files: files});
	};

	deleteFile = (file: File) => {
		const files = this.state.files.filter(f => f !== file);
		this.setState({files: files});
	};

	selectFolder = (folderID: number) => {
		this.setState({folderID: folderID});
	};

	get folderName() {
		let folderName = 'My Library';
		if (this.state.folderID > 0) {
			const name = this.seekFolderName(this.state.folderInfos);
			if (name) {
				folderName = name;
			}
		}
		return folderName;
	}

	seekFolderName(folderInfos: FolderInfo[]): string {
		let name: string = null;

		for (let i = 0; i < folderInfos.length; i++) {
			const folderInfo = folderInfos[i];

			if (folderInfo.id === this.state.folderID) {
				return folderInfo.name;
			} else {
				name = this.seekFolderName(folderInfo.subFolders);
				if (name) {
					return name;
				}
			}
		}

		return name;
	}

	getUploadButtonTitle() {
		if (this.state.loading) {
			return 'Already in the process';
		}
		if (this.hasLargeFiles()) {
			return 'Please remove all invalid images in order to begin the upload';
		}
	}

	renderFolderInfo(folderInfo: FolderInfo) {
		return <li key={folderInfo.id}>
			<a href='#' onClick={() => this.selectFolder(folderInfo.id)}>
				{folderInfo.name}
				{folderInfo.subFolders.length > 0 &&
				<div className='next-level' style={{transform: 'rotate(90deg)'}}>
					<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M0 5.11133L5 -2.86102e-06L10 5.11133H0Z' fill='#828282'/>
					</svg>
				</div>
				}
			</a>
			{folderInfo.subFolders.length > 0 &&
			<ul>
				{folderInfo.subFolders.map((folderInfo) => this.renderFolderInfo(folderInfo))}
			</ul>
			}
		</li>;
	}

	public render() {
		return <Modal loading={this.state.loading} className='white-header upload-images-modal base-ig-modal' animate={true}
		              onCatchError={() => this.props.close()}>
			<ModalHeader>
				<h3>
					<div>Add Image(s) to</div>
					<ul className='folder'>
						<li className={this.state.folderOptionOpened ? 'opened' : undefined} onClick={() => this.setState({folderOptionOpened: !this.state.folderOptionOpened})}>
							<a href='#'>
								<div>{this.folderName}</div>
								{this.state.folderInfos && this.state.folderInfos.length > 0 &&
								<div className='next-level'>
									<svg width='10' height='6' viewBox='0 0 10 6' fill='none'
									     xmlns='http://www.w3.org/2000/svg'>
										<path d='M0 0L5 5.11133L10 0L0 0Z' fill='#828282'/>
									</svg>
								</div>
								}
							</a>
							{this.state.folderInfos && this.state.folderInfos.length > 0 &&
							<ul>
								{this.state.folderInfos.map((folderInfo) => this.renderFolderInfo(folderInfo))}
							</ul>
							}
						</li>
					</ul>
				</h3>
			</ModalHeader>
			<ModalBody>
				<div
					className={join('content', this.state.files.length === 0 && 'empty')}
					onDragOver={this.onFilesDragOver}
					onDrop={this.onFilesDrop}
				>
					<ImageUpload onChange={this.onUploadInputChange} />
					{this.state.files.map((file, index) =>
						<OnHoverTooltip message={this.getImageTitle(file)} key={index}>
							<div className='card'>
								{file.size > this.maxFileSize && <div className='file-incorrect-mask'>
									<div className='top-row'>
										<i className='fa fa-exclamation-triangle warn-ico fa-lg'/>
										<div className='file-info'>
											<span>{file.name}</span>
											<span>{Math.round(file.size / 1000000)}MB</span>
										</div>
									</div>
									<div>
										Unable to upload file. <br/>Maximum size 5MB
									</div>
								</div>}
								<a className='remove' href='#' onClick={() => {
									this.deleteFile(file);
								}}><i className='fa fa-times fa-lg' aria-hidden='true'/></a>
								<img src={URL.createObjectURL(file)}/>
							</div>
						</OnHoverTooltip>,
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				<OnHoverTooltip
					message='Images must be less than 5MB and in the following formats: .bmp, .gif, .jpg, .jpeg, .png'>
					<div className='info-circle'>
						<i className='fa fa-info-circle fa-lg'/>
					</div>
				</OnHoverTooltip>
				<div className='actions-container'>
					<Button
						onClick={() => this.props.close()}
						title='CANCEL'
						className='btn-bold'
					/>
					<Primary
						onClick={() => this.upload()}
						title='SAVE'
						className='btn-bold'
						disabled={this.state.loading || this.hasLargeFiles() || !this.state.files.length}
						onHoverMessage={this.getUploadButtonTitle()}
						hoverPlacement='left'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
