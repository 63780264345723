import {Checkbox as CheckboxUi} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const CheckboxStyled = styled(CheckboxUi, {
	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$neutral72',
	},

	variants: {
		selected: {
			true: {
				'& > div': {
					background: 'linear-gradient(272deg, $primaryBackground 48.17%, $primary98 100%)',

					'& svg': {
						width: 24,
						height: 24,
					},
				},
			},
		},
	},
});
