/* The FrontendNewVersionAvailableEvent class represents an event indicating that a new version of the
frontend is available. */
export class FrontendNewVersionAvailableEvent {

}

/* The LoadScriptFailedEvent class is used to represent an event when loading a script fails. */
export class LoadScriptFailedEvent {

}
