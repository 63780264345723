import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const IconBox = styled(GridBox, {
	'& > svg': {
		width: 16,
		height: 16,
		fill: '$red92',

		'& > rect': {
			fill: '$red92',
		},

		'& > path': {
			fill: '$negative',
		},
	},
});
