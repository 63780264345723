import {getUser} from '@esgi/core/authentication';
import {Card} from '../types';
import {createQuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';

const currentUser = getUser();

export const getS3Source = (
	questionId: Card['questionID'],
	testId: Card['testID'],
	ticks: Card['ticks'],
) => {
	const urlBuilder = createQuestionImageUrlBuilder();
	return urlBuilder.white(questionId, testId, true, ticks);
};
