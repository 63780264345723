import {Button} from './index.styled';
import {Text} from '@esgi/ui/typography';

interface Props {
	onClick: VoidFunction;
}

export function AllTestButton({onClick}: Props) {
	return (
		<Button
			color='secondary'
			onClick={onClick}
		>
			<Text size='medium' bold>Browse All Tests</Text>
		</Button>
	);
}
