/* eslint-disable @typescript-eslint/member-ordering */
import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {Filter, PeriodResult, UpdateFilterByRequest} from '../types/filters-service-types';
import {StudentResult} from '../types/table-level-report-service-types';
import {isNumber} from 'underscore';

export class FiltersService extends BaseService {
	private readonly controller = 'reports/rubric-results';

	public filter$ = new BehaviorSubject<Filter>({
		showBaseline: false,
		showNotes: false,
		showSummary: false,
		showInColor: false,
		displayZeroIfNotTested: false,
		showCurrentMarkingPeriod: false,
		carryScoresForward: false,
	});

	private getFilterApiAction(key: keyof Filter) {
		const updateReportSettingAction = 'update-report-settings';

		const apiActions: Record<keyof Filter, string> = {
			showBaseline: 'update-show-base-line',
			showNotes: 'update-show-notes',
			showSummary: 'update-show-summary',
			showInColor: 'update-show-in-color',
			carryScoresForward: updateReportSettingAction,
			showCurrentMarkingPeriod: updateReportSettingAction,
			displayZeroIfNotTested: updateReportSettingAction,
		};

		return apiActions[key];
	}

	private updateFilterByRequest({filterTypeKey, value, name}: UpdateFilterByRequest) {
		const filter = this.filter$.value;

		if (filter[filterTypeKey] === value) {
			return;
		}

		filter[filterTypeKey] = value;
		this.setFilter(filter);

		const apiAction = this.getFilterApiAction(filterTypeKey);

		if (name) {
			this.httpClient.ESGIApi.post(this.controller, apiAction, {
				name,
				value,
			}).subscribe();

			return;
		}

		const toSaveValue = value ? 'True' : 'False';

		this.httpClient.ESGIApi.post(this.controller, apiAction, {
			value: toSaveValue,
		}).subscribe();
	}

	public setShowBaseLineFilter(value: boolean) {
		this.updateFilterByRequest({filterTypeKey: 'showBaseline', value});
	}

	public setShowNotesFilter(value: boolean) {
		this.updateFilterByRequest({filterTypeKey: 'showNotes', value});
	}

	public setShowSummaryFilter(value: boolean) {
		this.updateFilterByRequest({filterTypeKey: 'showSummary', value});
	}

	public setShowInColorFilter(value: boolean) {
		this.updateFilterByRequest({filterTypeKey: 'showInColor', value});
	}

	public setCarryForwardFilter(value: boolean) {
		this.updateFilterByRequest({
			filterTypeKey: 'carryScoresForward',
			value,
			name: 'carryScoresForward',
		});
	}

	public setMarkingPeriodFilter(value: boolean) {
		this.updateFilterByRequest({
			filterTypeKey: 'showCurrentMarkingPeriod',
			value,
			name: 'currentScoreOnly',
		});
	}

	public setDisplayNotTestedAsFilter(value: boolean) {
		this.updateFilterByRequest({
			filterTypeKey: 'displayZeroIfNotTested',
			value,
			name: 'displayZeroIfNotTested',
		});
	}

	public applyCarryForwardFilter(rows: StudentResult[]) {
		rows.forEach((row) => {
			row.criteriaResults = row.criteriaResults.map((criteriaResult) => {
				const lastTestedPeriod = [...criteriaResult.periodResults]
					.reverse()
					.find(({testSessionID}) => testSessionID !== 0);

				if (!lastTestedPeriod) {
					return criteriaResult;
				}

				const testedPeriodIndex = criteriaResult.periodResults.findIndex(
					({periodName}) => periodName === lastTestedPeriod.periodName,
				);

				for (let i = testedPeriodIndex + 1; i < criteriaResult.periodResults.length; i++) {
					if (criteriaResult.periodResults[i]?.value) {
						criteriaResult.periodResults[i]!.value = lastTestedPeriod.value;
					}
				}

				return criteriaResult;
			});
		});
	}

	public applyShowBaselineFilter(rows: StudentResult[]) {
		rows.forEach((row) => {
			row.criteriaResults.forEach((criteriaResult) => {
				criteriaResult.periodResults = criteriaResult.periodResults.filter(({periodName}) => periodName !== 'B');
			});
		});
	}

	public applyMarkingPeriodFilter(rows: StudentResult[], currentPeriod?: number) {
		if (!rows[0]?.criteriaResults[0]?.periodResults.length) {
			return;
		}

		let cp = isNumber(currentPeriod) ? currentPeriod : rows[0].criteriaResults[0].periodResults.length - 1;

		if (isNumber(currentPeriod) && this.filter$.value.showBaseline) {
			cp++;
		}

		rows.forEach((row) => {
			row.criteriaResults = row.criteriaResults.map((criteriaResult) => {
				const periodResults: PeriodResult[] = [];

				if (this.filter$.value.showBaseline) {
					const filteredPeriodResults = criteriaResult.periodResults.filter(({periodName}) => periodName === 'B');

					if (filteredPeriodResults.length) {
						periodResults.push(filteredPeriodResults[0]!);
					}
				}

				const periodResult = criteriaResult.periodResults[cp];

				if (periodResult) {
					periodResults.push(periodResult);
				}

				criteriaResult.periodResults = periodResults;

				return criteriaResult;
			});
		});
	}

	public applyDisplayNotTestedFilter(rows: StudentResult[]) {
		rows.forEach((row) => {
			row.criteriaResults = row.criteriaResults.map((criteriaResult) => {
				criteriaResult.periodResults = criteriaResult.periodResults.map((periodResult) => {
					if (parseInt(periodResult.value) === 0 && periodResult.testSessionID === 0) {
						periodResult.value = 'NT';
					}

					return periodResult;
				});

				return criteriaResult;
			});
		});
	}

	public applyFilters(rows: StudentResult[], currentPeriod?: number) {
		if (!this.filter$.value.showBaseline) {
			this.applyShowBaselineFilter(rows);
		}

		if (!this.filter$.value.displayZeroIfNotTested) {
			this.applyDisplayNotTestedFilter(rows);
		}

		if (this.filter$.value.carryScoresForward) {
			this.applyCarryForwardFilter(rows);
		}

		if (this.filter$.value.showCurrentMarkingPeriod) {
			this.applyMarkingPeriodFilter(rows, currentPeriod);
		}
	}

	public applyShowNotesFilters() {
		return this.filter$.value.showSummary;
	}

	public setFilter(filter: Filter) {
		this.filter$.next({...filter});
	}

	public getFilter(): Filter {
		return this.filter$.value;
	}
}
