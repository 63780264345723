import {RepAutoquoterForm} from './rep-autoquoter-form';

import './styles.less';

export class RepAutoQuoter {
	constructor() {
		this.repAutoquoterForm = new RepAutoquoterForm();
		this.form(this.repAutoquoterForm);
	}

	repAutoquoterForm: RepAutoquoterForm;

	form = ko.observable<any>();
}
