import {ComponentPropsWithoutRef} from 'react';
import {Body} from './components/body';
import {Dialog} from '@esgi/main/kits/activities';

type Props = Pick<ComponentPropsWithoutRef<typeof Dialog>, 'onClose' | 'onDownload'>;

export function PreviewDialog(props: Props) {
	return (
		<Dialog {...props}>
			<Body/>
		</Dialog>
	);
}
