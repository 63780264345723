import {NotesModel} from '../../../../../../types/student-level-report-service-types';
import {isEmpty} from 'underscore';
import {
	ContainerStyled,
	Heading4,
	NotesContainerStyled,
	SummaryStyled,
	TextMarginedStyled,
	TitleStyled,
} from './index.styled';

interface Props {
	showNotes: boolean;
	showSummary: boolean;
	note?: NotesModel | undefined;
}

export function Note({showNotes, showSummary, note}: Props) {
	if (!note) {
		return;
	}

	const {criteriaNotes, summaryNotes} = note;
	const isShowCriteriaNotes = showNotes && !isEmpty(criteriaNotes);
	const isShowSummaryNotes = showSummary && !isEmpty(summaryNotes);

	return (
		<ContainerStyled>
			<NotesContainerStyled>
				{isShowCriteriaNotes &&
					<>
						<Heading4 data-cy='notes-header'>Notes</Heading4>
						{criteriaNotes.map(({criteriaName, notes}, i) => (
							<TextMarginedStyled data-cy='notes' key={i}>
								<TitleStyled>{criteriaName}: </TitleStyled>
								{notes}
							</TextMarginedStyled>
						))}
					</>
				}
				{isShowSummaryNotes &&
					<>
						<Heading4 data-cy='summary-header'>Summary</Heading4>
						<SummaryStyled data-cy='summary-notes'>{summaryNotes}</SummaryStyled>
					</>
				}
			</NotesContainerStyled>
		</ContainerStyled>
	);
}
