import {Renderable} from '@esgi/deprecated/knockout';
import './styles.less';

export class Step2 extends Renderable {
	template = () => <div className='step2-content'>
		<div className='container'>
			<div className='row'>
				<div className='col-md-9'>
					<h1>
						Get A <strong>Quote</strong>
					</h1>
				</div>
				<div className='col-md-3 back'>
					<h4>
						<span data-bind='click: view.back'><strong></strong> Back</span>
					</h4>
				</div>
			</div>
		</div>
		<div className='step2'>
			<div className='container'>
				<div className='center'>
					<div className='row'>
						<h2>DOES YOUR DISTRICT/SCHOOL CURRENTLY USE ESGI?</h2>
					</div>
					<div className='row'>
						<div className='col-md-4'>
							<a className='btn btn-extra-lg btn-primary-action' data-bind='click: view.no'>No</a>
						</div>
						<div className='col-md-4'>
							<a className='btn btn-extra-lg btn-primary-action' data-bind='click: view.dontknow'>Don't
								Know</a>
						</div>
						<div className='col-md-4'>
							<a className='btn btn-extra-lg btn-primary-action'
							   data-bind='click: view.yes'>Yes</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>;

	view = {
		back: () => {
			$(this).trigger('back');
		},
		no: () => {
			$(this).trigger('next');
		},
		dontknow: () => {
			$(this).trigger('next');
		},
		yes: () => {
			window.location.href = '/forms/request-a-quote';
		},
	};

	events = {
		next: (callback) => {
			$(this).on('next', callback);
		},
		back: (callback) => {
			$(this).on('back', callback);
		},
	};
}
