import {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Text} from '@esgi/ui/typography';
import {AppliedTo, Button, BoxArrowUpRight} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {TestType as TestTypeEnum} from '@esgi/main/libs/core';
import {SessionInfo, TestColorIndicator} from '@esgi/main/kits/common';
import {Summary} from '../types';
import {StudentModel, SubjectModel, TestLauncherData} from '../../types';
import {
	AlertFooter,
	ClassContainer,
	Container, Divider,
	Footer, NoScorePlaceholder,
	SessionContainer,
	SessionInfoContainer,
	StudentContainer,
	SummaryNoteArea,
	TestContainer, TestName,
	TestType,
	TextName,
	Timestamp,
} from '../../../summary';
import {useNavigateToTestDashboard} from '@esgi/main/features/teacher/test-dashboard/utils';
import {Class} from '@esgi/main/libs/store';
import {useUser} from '@esgi/core/authentication';
import {getTimeWithTimeZone} from '../../utils';

type Props = {
	showPieChart: boolean,
	testName: string,
	launcherData: TestLauncherData,
	summary: Summary,
	subject: Pick<SubjectModel, 'id' | 'type' | 'name'>;
	student: StudentModel;
	studentClass: Class;
	onDoneClicked: (testSessionId: number, summaryText: string) => void;
	onFlashcardsClicked: VoidFunction;
};

export function SessionResults(props: Props) {
	const currentUser = useUser();

	const alert = Alert.useRef();
	const [summaryNote, setSummaryNote] = useState<string>('');
	const timeZone = useUser()?.timeZone;
	const location = useLocation();
	const isShowRedirectButton = !location.pathname.includes('test-dashboard');

	const delta = useMemo(() => {
		if (props.launcherData.studentResult) {
			return (((props.summary.correct / props.summary.total) * 100) - (props.launcherData.studentResult?.result));
		} else {
			return null;
		}
	}, [props.launcherData.studentResult, props.summary]);

	const percent = (props.summary.correct / props.summary.total) * 100;
	const onTestDashboardClicked = useNavigateToTestDashboard();
	const close = Alert.useClose(alert, () => {
		props.onDoneClicked(props.summary.testSessionID, summaryNote);
	});
	const flashcardsClicked = Alert.useClose(alert, props.onFlashcardsClicked);
	const date = useMemo(() => timeZone ? getTimeWithTimeZone(new Date(), timeZone) : new Date(), []);

	return <Alert modalManagerRef={alert} colorConfig={alertColorsConfig.neutral} dataCy='session-results'>
		<Alert.Header onCloseIconClick={close}>
			<Text size='large'>Session Results</Text>
		</Alert.Header>
		<Alert.Body>
			<Container>
				<TestContainer>
					<TestName>
						<TestColorIndicator testID={props.launcherData.testID}/>
						<TextName data-cy='test-name' size='xxLarge'>
							{props.testName}
						</TextName>
						<TestType data-cy='test-type' size='small'>
							Yes/No
						</TestType>
					</TestName>
					{isShowRedirectButton && <Button.Icon
						data-cy='test-dashboard-link'
						css={{width: 36, height: 36}}
						onClick={() =>
							onTestDashboardClicked({
								dashboardType: 'student',
								subjectID: props.subject.id,
								testID: props.launcherData.testID,
								subjectType: props.subject.type,
								studentID: props.student.id,
							})
						}
					>
						<BoxArrowUpRight/>
					</Button.Icon>}
				</TestContainer>
				<Divider/>
				<SessionInfoContainer dataCy='session-info-pie-chart'>
					{props.showPieChart ? <SessionInfo value={props.summary.correct}
					                                   maxValue={props.summary.total}
					                                   testType={TestTypeEnum.YN}
					                                   deltaIndicatorValue={delta}
					                                   result={percent}
																						 testResultsCorrectVerbiage={currentUser?.testResultsCorrectVerbiage ?? 'Correct'}/> : <NoScorePlaceholder/>}
				</SessionInfoContainer>

				<ClassContainer dataCy='class-container'>
					<Text size='small'>Class: </Text>
					<Text size='small' bold>{props.studentClass?.name}</Text>
				</ClassContainer>
				<StudentContainer dataCy='student-container'>
					<Text size='small'>Student: </Text>
					<Text size='small' bold>{props.student.firstName} {props.student.lastName}</Text>
				</StudentContainer>
				<SessionContainer dataCy='session-container'>
					<Text size='small'>Session: </Text>
					<Timestamp date={date}/>
				</SessionContainer>

				{props.summary.testSessionID > 0 && <SummaryNoteArea placeholder='Summary Note' value={summaryNote}
				                 onChange={(e) => setSummaryNote(e.target.value)}/>}
			</Container>
		</Alert.Body>
		<AlertFooter>
			<Footer>
				<Button color='tertiary' onClick={flashcardsClicked}>
					<AppliedTo/>
					<Text size='medium' bold>Flashcards</Text>
				</Button>
				<Button color='secondary' onClick={close}>
					<Text size='medium' bold>Done</Text>
				</Button>
			</Footer>
		</AlertFooter>
	</Alert>;
}
