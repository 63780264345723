import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupContent, ToggleGroupItem} from './index.styled';
import {TestSectionMode} from '../../types';
import {TabItem} from './types';
import {useCallback} from 'react';
import {isNull} from 'underscore';
import {Tooltip, isUndefined} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';

type Props<TabId extends string = string> = {
	sectionMode: TestSectionMode;
	activeTab: TabId;
	setActiveTab: (tabId: TabId) => void;
	tabItems: (TabItem<TabId> | null)[];
	isSmallScreen?: boolean;
	dataCy?: string;
};

export function Tabs<TabId extends string = string>({
	sectionMode,
	activeTab,
	setActiveTab,
	tabItems,
	isSmallScreen = false,
	dataCy = 'tabs',
}: Props<TabId>) {
	const items = tabItems.filter((item): item is TabItem<TabId> => !isNull(item) && !item.disabled);
	const isViewMode = sectionMode === TestSectionMode.View;

	const getItemContent = useCallback(({label, labelDataCy}: Pick<TabItem<TabId>, 'label' | 'labelDataCy'>) => {
		if (typeof label === 'string') {
			return (
				<Text size='medium' bold data-cy={`${labelDataCy}-label`}>
					{label}
				</Text>
			);
		}

		return label;
	}, []);

	if (!items.length) {
		return null;
	}

	return (
		<ToggleGroup.Root onValueChange={setActiveTab} value={activeTab} dataCy={dataCy}>
			<ToggleGroupContent dataCy={`${dataCy}-content`}>
				{items.map(({value, label, labelDataCy, css = {}, tooltipText}) => {
					const applyDisabledStyles = !isViewMode;

					return (
						<Tooltip open={isUndefined(tooltipText) ? false : undefined}>
							<Tooltip.Trigger>
								{/*
									Keep the Item wrapped by a Box or another element
									(ToggleGroupItem and Tooltip.Trigger depends on the data-attribute with the same name ([data-state])
								*/}
								<Box>
									<ToggleGroupItem
										key={value}
										value={value}
										disabled={activeTab === value || !isViewMode}
										applyDisabledStyles={applyDisabledStyles}
										applyDisabledStylesExtended={applyDisabledStyles}
										dataCy={labelDataCy}
										isSmallScreen={isSmallScreen}
										css={css}
									>
										{getItemContent({label, labelDataCy})}
									</ToggleGroupItem>
								</Box>
							</Tooltip.Trigger>
							<Tooltip.Content variant='secondary'>
								<Text size='small'>{tooltipText}</Text>
							</Tooltip.Content>
						</Tooltip>
					);
				})}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
