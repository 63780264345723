import {forwardRef, useCallback} from 'react';
import {Skeletonable} from '../../types';
import {CSS} from '@stitches/react';
import {SliderRoot, SliderRootProps} from './components/slider-root';
import {SliderTrack} from './components/slider-track';
import {SliderRange} from './components/slider-range';
import {SliderThumb} from './components/slider-thumb';
import {isUndefined} from '../../utils/is-undefined';

type SliderProps = Omit<
	SliderRootProps,
	'children' | 'defaultValue' | 'value' | 'onValueChange' | 'onValueCommit' | 'asChild' | 'minStepsBetweenThumbs'
> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** The value of the slider when initially rendered. Use when you do not need to control the state of the slider. */
	defaultValue?: number;

	/** The controlled value of the slider. Must be used in conjunction with onValueChange. */
	value?: number;

	/** Event handler called when the value changes. */
	onValueChange?: (value: number | undefined) => void;

	/**
		Event handler called when the value changes at the end of an interaction.
	 	Useful when you only need to capture a final value e.g. to update a backend service.
	*/
	onValueCommit?: (value: number | undefined) => void;
} & Skeletonable;

export const Slider = forwardRef<HTMLSpanElement, SliderProps>(
	(
		{dataCy = 'ui-kit-slider', css = {}, skeleton, value, defaultValue, onValueChange, onValueCommit, ...props},
		forwardedRef,
	) => {
		const handleValueChange = useCallback(
			(value: number[]) => {
				onValueChange?.(value[0]);
			},
			[onValueChange],
		);

		const handleValueCommit = useCallback(
			(value: number[]) => {
				onValueCommit?.(value[0]);
			},
			[onValueCommit],
		);

		return (
			<SliderRoot
				data-cy={dataCy}
				css={css}
				defaultValue={isUndefined(defaultValue) ? undefined : [defaultValue]}
				value={isUndefined(value) ? undefined : [value]}
				onValueChange={handleValueChange}
				onValueCommit={handleValueCommit}
				ref={forwardedRef}
				{...props}
			>
				<SliderTrack data-cy={`${dataCy}-track`}>
					<SliderRange data-cy={`${dataCy}-range`} />
				</SliderTrack>
				<SliderThumb data-cy={`${dataCy}-thumb`} />
			</SliderRoot>
		);
	},
);
