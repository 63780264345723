import {DrawerWithSearchParams as Component} from './components/drawer-with-search-params';
import {useDrawerClose, useDrawerRef} from './hooks';

export const DrawerWithSearchParams = Object.assign(Component, {
	useRef: useDrawerRef,
	useClose: useDrawerClose,
});

export {type DrawerWithSearchParamsProps} from './components/drawer-with-search-params';
export {DrawerMode, DrawerSearchKey} from './types';
export * from './hooks';
