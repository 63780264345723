import {Checkbox, CheckboxesBox} from './index.styled';
import React, {ChangeEvent, Dispatch, useCallback, useMemo, useState} from 'react';
import {Search} from '@esgi/ui';
import {Input} from '@esgi/ui/controls';
import {ClassesContent} from './components/classes-content';
import {GroupsContent} from './components/groups-content';
import {StudentsContent} from './components/students-content';
import {StudentTabs} from './components/student-tabs';
import {StudentSelectionPanelSkeleton} from './components/skeleton';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Student, useClasses, useGroups, useStudents} from '@esgi/main/libs/store';
import {StudentTabId} from './types';

type Props = {
	onStudentClick: Dispatch<Student['id']>;
	selectedStudentsIDs: Student['id'][];
	skeleton: boolean;
	isOpenFirstClassOnInit?: boolean;
	toggleEntityStudents: Dispatch<{studentsIDs: Student['id'][]; method: 'add' | 'remove'}>;
};

export function StudentSelectionPanel({
	onStudentClick,
	isOpenFirstClassOnInit,
	selectedStudentsIDs,
	skeleton,
	toggleEntityStudents,
}: Props) {
	const [{data: classesList, loaded: isClassesListLoaded}] = useClasses();
	const [{data: groupsList, loaded: isGroupsListLoaded}] = useGroups();
	const [{data: studentsList, loaded: isStudentsListLoaded}] = useStudents();

	const [studentSearchInputValue, setStudentSearchInputValue] = useState('');

	const [activeStudentTab, setActiveStudentTab] = useState(StudentTabId.Classes);
	const [withCredentials, setWithCredentials] = useState(false);

	const visibleStudents = useMemo(() => {
		const lowerCaseValue = studentSearchInputValue.trim().toLocaleLowerCase();
		const searchWords = lowerCaseValue.split(' ');

		if (searchWords.length > 3) {
			return [];
		}

		const students = studentsList.filter(({hasCredentials}) => (withCredentials ? hasCredentials : true));

		return students.filter((student) => {
			const {firstName, lastName} = student;
			const fullName = [firstName, lastName].filter(Boolean).join(' ').trim().toLocaleLowerCase();
			const reversedFullName = [lastName, firstName].filter(Boolean).join(' ').trim().toLocaleLowerCase();

			return searchWords.every((word) => fullName.includes(word) || reversedFullName.includes(word));
		});
	}, [studentSearchInputValue, studentsList, withCredentials]);

	const onStudentSearchInputValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setStudentSearchInputValue(value);
	}, []);

	const toggleWithCredentialsValue = useCallback(() => {
		setWithCredentials((currentValue) => !currentValue);
	}, []);

	const getTabContent = (): React.JSX.Element | null => {
		if (activeStudentTab === StudentTabId.Classes) {
			return (
				<ClassesContent
					classes={classesList}
					visibleStudents={visibleStudents}
					onStudentClick={onStudentClick}
					selectedStudentsIDs={selectedStudentsIDs}
					isOpenFirstClassOnInit={isOpenFirstClassOnInit}
					toggleEntityStudents={toggleEntityStudents}
				/>
			);
		}

		if (activeStudentTab === StudentTabId.Groups) {
			return (
				<GroupsContent
					visibleStudents={visibleStudents}
					onStudentClick={onStudentClick}
					groups={groupsList}
					selectedStudentsIDs={selectedStudentsIDs}
					toggleEntityStudents={toggleEntityStudents}
				/>
			);
		}

		if (activeStudentTab === StudentTabId.Students) {
			return (
				<StudentsContent
					students={visibleStudents}
					selectedStudentsIDs={selectedStudentsIDs}
					onStudentClick={onStudentClick}
				/>
			);
		}

		return null;
	};

	const isPanelLoaded = skeleton && isClassesListLoaded && isGroupsListLoaded && isStudentsListLoaded;

	if (isPanelLoaded) {
		return <StudentSelectionPanelSkeleton />;
	}

	return (
		<>
			<StudentTabs activeStudentTab={activeStudentTab} setActiveStudentTab={setActiveStudentTab} />
			<Input.Iconable
				placeholder='Search'
				value={studentSearchInputValue}
				onChange={onStudentSearchInputValueChange}
				dataCy='students-search-input'
			>
				<Search />
			</Input.Iconable>
			<CheckboxesBox>
				<Checkbox
					value='withCredentials'
					label='With credentials only'
					checked={withCredentials}
					selected={withCredentials}
					disabled={!visibleStudents?.length && Boolean(studentSearchInputValue)}
					onCheckedChange={toggleWithCredentialsValue}
					dataCy='with-credentials-only-checkbox'
				/>
			</CheckboxesBox>
			<OverlayScrollbarsComponent
				defer
				style={{height: 'calc(100% + 0px)'}}
				options={{scrollbars: {autoHide: 'leave'}}}
			>
				{getTabContent()}
			</OverlayScrollbarsComponent>
		</>
	);
}
