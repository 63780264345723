import {Checkbox as NewBaseCheckbox} from '@esgillc/ui-kit/control';
import {Checkbox as ControlCheckbox} from '@esgillc/ui-kit/form-control';

/**
 * @deprecated Use new import path - @esgillc/ui-kit/control.
 * */
export const BaseCheckbox = NewBaseCheckbox;
/**
 * @deprecated Use new import path - @esgillc/ui-kit/form-control.
 * */
export const Checkbox = ControlCheckbox;
