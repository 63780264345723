import React, {ReactNode} from 'react';
import ReactDOM from 'react-dom';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ServiceLoader} from '@esgi/deprecated/ui-kit/loader';
import {TemplateModel} from '../../../common/types';
import CompleteDialog from '../complete-dialog/complete-dialog';
import Preview from '../preview-dialog/preview';
import PickDialog from './component/pick-dialog/pick-dialog';
import TemplateDataService from './template-data-service';

class State {
	initialized: boolean = false;
	completeOpened: boolean = false;
	showPreview: boolean = false;
}

interface Props {
	canAddToSubject: boolean;
	initialTemplateID: number;
	onCloseClicked: () => void;
	onSaveClicked: () => void;
	templates: TemplateModel[];
}

export default class TemplatePicker extends React.PureComponent<Props, State> {
	public state = new State();
	private readonly onDestroy$: Subject<void> = new Subject();
	private readonly templateDataService: TemplateDataService = new TemplateDataService();

	public componentDidMount() {
		this.templateDataService
			.initByTest(this.props.initialTemplateID)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => this.setState({initialized: true}));
	}

	public render() {
		return <>
			{this.renderPicker()}
			{this.renderCompleteDialog()}
			{this.renderPreview()}
			{ReactDOM.createPortal(<ServiceLoader trackingService={this.templateDataService} fullscreen/>, document.body)}
		</>;
	}

	private renderPicker() {
		if(this.state.initialized) {
			return <PickDialog initialTemplateID={this.props.initialTemplateID}
			                   templates={this.props.templates}
			                   templateDataService={this.templateDataService}
			                   onPreviewClicked={() => this.setState({showPreview: true})}
			                   onBackClicked={this.props.onCloseClicked}
			                   onSaveClicked={() => this.setState({completeOpened: true})}/>;
		}
	}
	private renderPreview(): ReactNode {
		if (this.state.showPreview) {
			return <Preview rubricService={this.templateDataService}
			                onBackClicked={() => this.setState({showPreview: false})}
			                onSaveClicked={() => this.setState({completeOpened: true})}/>;
		}
	}

	private renderCompleteDialog() {
		if (this.state.completeOpened) {
			return <CompleteDialog canAddToSubject={this.props.canAddToSubject}
			                       testDataService={this.templateDataService}
			                       backClicked={() => this.setState({completeOpened: false})}
			                       confirmClicked={() => this.save()}/>;
		}
	}

	private save(): void {
		this.templateDataService.save().subscribe(this.props.onSaveClicked);
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
