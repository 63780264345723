import {HierarchyInstance} from './core/models';

export class HierarchyChangedItselfEvent {
	constructor(public hierarchy: HierarchyInstance) {
	}
}

export class HierarchyInitializedEvent {
	constructor(public hierarchy: HierarchyInstance) {
	}
}
