import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Filenametemplate} from '@esgi/ui/icons';
import {AlertBody} from './components/body';
import {AlertHeader} from './index.styled';

type Props = {
	onClose: VoidFunction;
	onDownload: VoidFunction;
};

export function PreviewDialog(props: Props) {
	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, props.onClose);

	return (
		<Alert
			dataCy='flashcards-preview-content'
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.neutral}
			defaultOpen={false}
		>
			<AlertHeader
				withBacklight={false}
				onCloseIconClick={onClose}
			>
				<Filenametemplate/>
				<Text size='large'>Flashcards</Text>
			</AlertHeader>
			<AlertBody/>
			<Alert.Footer>
				<Button
					color='primary'
					onClick={() => {
						props.onDownload();
						onClose();
					}}
				>
					<Text size='medium' bold>Download</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
