import React from 'react';

export interface IModule {
	load(): JQueryPromise<any>;
}

export interface IHaveRootElement {
	rootElement: JQuery;
}

export interface IRenderable extends IHaveRootElement, IModule {
	template: () => JQueryPromise<string> | JQueryPromise<HTMLElement> | React.ReactElement | JQueryPromise<React.ReactElement>;

	afterRender(rootElement: JQuery): JQueryPromise<any>;
}

export abstract class Renderable implements IRenderable, IModule {
	constructor() {
		this.uniqueId = '_' + Math.random().toString(36).substr(2, 9);
		this.initUi();
	}

	public static resolvedDeferred: JQueryPromise<any> = $.Deferred<any>().resolve().promise();
	public static rejectedDeferred: JQueryPromise<any> = $.Deferred<any>().reject().promise();

	uniqueId: string;

	template: () => React.ReactElement;
	rootElement: JQuery;

	afterRender(rootElement: JQuery): JQueryPromise<any> {
    	this.rootElement = rootElement;
    	return Renderable.resolvedDeferred;
	}

	afterShow() {

	}

	dispose() {
    	$(this).trigger('disposed');
	}

	load(): JQueryPromise<any> {
    	let deferred = $.Deferred();
    	setTimeout(() => deferred.resolve(), 1);

    	return deferred.promise();
	}

	render(): JQueryPromise<HTMLElement> {
    	return this.load().then(() => {
    		return ko.render(this);
    	});
	}

	private initUi() {
    	let self = this as any;
    	let uis = self._ui;

    	if (uis) {
    		for (let key in uis) {
    			(() => {
    				let keyClosure = key;
    				let ui = uis[keyClosure];

    				let value = 0;
    				let computed = ko.computed({
    					read: () => {
    						for (let i = 0; i < ui.accessors.length; i++) {
    							ui.accessors[i](self);
    						}

    						return ++value;
    					}, deferEvaluation: true,
    				});

    				computed.pureSubscribe(function () {
    					self[keyClosure]();
    				});
    			})();
    		}
    	}
	}
}

export class ReactRenderable extends Renderable {
	constructor(public obj: React.Component) {
		super();
	}

	template = () => {
    	return <div data-bind='react: obj'/>;
	}
}

export abstract class Module extends Renderable {
	displayed = true;

	onModuleDisplayed() {
    	this.displayed = true;
	}

	onModuleHidden() {
    	this.displayed = false;
	}
}