import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Chart = styled(FlexBox, {
	justifyContent: 'center',
	flex: '1 1 auto',
	overflow: 'hidden',
	position: 'relative',
	backgroundColor: '$vivid',
});
