import {useMemo} from 'react';
import {TextWithTranscriptProps} from '../components/test-cards/card-variant/list-cards/components/text-with-transcript';
import {AnalyticsData, TestInfo} from '../../../types';
import {TestType} from '@esgi/main/libs/core';

type AnalyticsDataItem = TextWithTranscriptProps | null;

export function useAnalyticsDataItems({
	analyticsData,
	testInfo,
}: {
	analyticsData: AnalyticsData | null;
	testInfo: TestInfo;
}) {
	const analyticsDataItems = useMemo<AnalyticsDataItem[]>(() => {
		const firstItem: Record<TestType, AnalyticsDataItem> = {
			[TestType.YN]: {
				mainText: analyticsData?.total ?? testInfo.totalPossible,
				transcription: 'Questions',
				dataCy: 'test-info-questions',
			},
			[TestType.Score]: {
				mainText: 1,
				transcription: 'Question',
				dataCy: 'test-info-question',
			},
			[TestType.None]: null,
			[TestType.Rubric]: {
				mainText: testInfo.rubricCriteria.length,
				transcription: 'Criteria',
				dataCy: 'test-info-criteria',
			},
		};

		return [
			firstItem[testInfo.type],
			{
				mainText: analyticsData?.lastSessionDate ? new Date(analyticsData.lastSessionDate) : '-',
				transcription: 'Last Test Date',
				dataCy: 'test-info-last-test-date',
			},
			{
				mainText: analyticsData?.averageSessionDuration ?? 0,
				transcription: 'Avg. Session',
				mainTextAsTimeInMs: true,
				dataCy: 'test-info-av-session',
			},
			{
				mainText: analyticsData?.sessionCount ?? 0,
				transcription: 'Sessions',
				dataCy: 'test-info-session',
			},
		];
	}, [testInfo, analyticsData]);

	return {analyticsDataItems};
}
