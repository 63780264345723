import {TestType} from '@esgi/main/libs/core';
import {YesNoTesting} from './yes-no';
import {TestLauncherData, StudentModel, SubjectModel} from './types';
import {ScoreTesting} from './score';
import {RubricTesting} from './rubric';
import {useUser} from '@esgi/core/authentication';
import {Class} from '@esgi/main/libs/store';

export * from './events';

type Props = {
    launcherData: TestLauncherData,
    subject: Pick<SubjectModel, 'id' | 'type' | 'name'>;
    student: StudentModel;
    studentClass: Class;
    onClose: VoidFunction;
    onFlashCardsClicked: VoidFunction;
    onTestSessionDetailsClicked: VoidFunction;
		questionIds?: number[];
}

export function Testing(props: Props) {
	const user = useUser();

	if (props.launcherData.testType === TestType.YN) {
		return <YesNoTesting
            launcherData={props.launcherData}
            subject={props.subject}
            student={props.student}
            studentClass={props.studentClass}
            onClose={props.onClose}
            onFlashCardsClicked={props.onFlashCardsClicked}
            onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
						questionIds={props.questionIds}/>;
	}

	if (props.launcherData.testType === TestType.Score) {
		return <ScoreTesting launcherData={props.launcherData}
                             subject={props.subject} studentClass={props.studentClass}
							 onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
                             student={props.student}
                             onClosed={props.onClose}/>;
	}

	if (props.launcherData.testType === TestType.Rubric) {
		return <RubricTesting launcherData={props.launcherData}
                              subject={props.subject}
							  student={props.student}
							  studentClass={props.studentClass}
                              userID={user.userID}
                              onFinished={props.onClose}
                              onClosed={props.onClose}/>;
	}
}