import {PropsWithChildren, useCallback} from 'react';
import {RadioButtonGroup, Text} from '../../../../kit';
import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {useRadioButtonGroup} from '../../hooks';
import {FilterService} from '../../../../services/filter-service';
import {StudentCredentialsService} from '../../../../services/student-credentials-service';
import {ViewFilterValue} from '../../types';

type FiltersContainerProps<
	Filter extends FilterService,
	Service extends StudentCredentialsService<Filter>,
> = PropsWithChildren<{
	service: Service;
}>;

export function FiltersContainer<Filter extends FilterService, Service extends StudentCredentialsService<Filter>>({
	children,
	service,
}: FiltersContainerProps<Filter, Service>) {
	const radioButtonGroup = useRadioButtonGroup();

	const onRadioButtonGroupChange = useCallback(
		(value: ViewFilterValue) => {
			service.filterService.setFilterViewValue(value);
			service.filterService.updateTableData();
		},
		[service],
	);

	return (
		<div className={styles.filtersContainer}>
			<Text size='14px' color='gray' as='span' weight='500' className={styles.titleFilterBy}>
				Filter By:
			</Text>
			<div className={join(styles.filterGroup, styles.filterBy)}>{children}</div>
			<Text size='14px' color='gray' as='span' weight='500'>
				View:
			</Text>
			<div className={styles.filterGroup}>
				<RadioButtonGroup radioButtons={radioButtonGroup} defaultIndex={0} onChange={onRadioButtonGroupChange} />
			</div>
		</div>
	);
}
