import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardRoot = styled(Card, {
	display: 'grid',
	gap: 20,
	padding: 20,
	borderRadius: 'unset',
	transition: 'unset',
	boxShadow: 'unset',
	borderRight: 'unset',
	borderLeft: 'unset',
	borderBottomColor: '$mild',
	borderTopColor: '$border',

	'&:hover': {
		borderColor: 'unset',
		borderBottomColor: '$mild',
		borderTopColor: '$border',
		boxShadow: 'unset',
	},
});
