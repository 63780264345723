import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = {
	skeleton: boolean;
	resetCredentials: VoidFunction;
	isCredentialsTouched: boolean;
	onSaveUserCredentials: VoidFunction;
	isCredentialsValid: boolean;
	isCredentialsSaving: boolean;
};

export function AlertFooter({
	skeleton,
	resetCredentials,
	isCredentialsTouched,
	onSaveUserCredentials,
	isCredentialsValid,
	isCredentialsSaving,
}: Props) {
	return (
		<Alert.Footer>
			<GridBox gap='3' flow='column'>
				<Button
					color='tertiary'
					onClick={resetCredentials}
					disabled={!isCredentialsTouched || isCredentialsSaving || skeleton}
					skeleton={skeleton}
				>
					<Text size='medium' bold color='base'>
						Cancel
					</Text>
				</Button>
				<Button
					color='secondary'
					disabled={!isCredentialsValid || !isCredentialsTouched || isCredentialsSaving || skeleton}
					onClick={onSaveUserCredentials}
					skeleton={skeleton}
				>
					<Text size='medium' bold color='base'>
						Save
					</Text>
				</Button>
			</GridBox>
		</Alert.Footer>
	);
}
