import {SkeletonShape, SubjectName} from '@esgi/main/kits/common';
import {CellTestBarInfo, CellTestName, TableTests, TableTestsRow} from './components.styled';
import {TableTestsHeader} from './table-tests-header';
import {GridBox} from '@esgi/ui/layout';

export function TableTestsSkeleton() {
	return (
		<TableTests>
			<TableTestsHeader studentsCount={2} />

			{new Array(3).fill(null).map((_, index) => (
				<TableTestsRow key={index}>
					<CellTestName>
						<SkeletonShape width={10} height={24} />
						<SkeletonShape width='50%' height={20} />
					</CellTestName>
					<SubjectName skeleton />
					<CellTestBarInfo>
						<SkeletonShape width={40} height={16} />
						<GridBox
							css={{
								width: '100%',
								gap: 4,
								gridTemplateColumns: 'repeat(3, 1fr)',
							}}
						>
							{new Array(3).fill(null).map((_, indexProgress) => (
								<SkeletonShape width='100%' height={6} key={`${index}-${indexProgress}`} />
							))}
						</GridBox>
					</CellTestBarInfo>
					<SkeletonShape />
				</TableTestsRow>
			))}
		</TableTests>
	);
}
