import {TestPreview} from 'modules/assessments';
import React from 'react';
import {PrimaryButton} from '@esgi/deprecated/ui-kit/buttons';
import styles from './preview-button.module.less';
import {TestScreenType} from '@esgi/core/enums';
import SelfAssessPreview
	from 'shared/modules/test-details/components/question-panel/self-assess-preview/self-assess-preview';
import {Question, TemplateType} from '@esgi/selfassess';
import {HttpClient} from '@esgi/api';
import {ExpandIcon} from '@esgillc/ui-kit/icons';
import {Loader} from '@esgillc/ui-kit/loader';
import {DropdownContextState, Popup, DropdownContext, Option} from '@esgillc/ui-kit/control';
import {userStorage} from '@esgi/core/authentication';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

class State implements Partial<DropdownContextState> {
	toggled: boolean = false;
	showTestPreview: boolean = false;
	questions: Question[] = [];
	status: 'Loading' | 'Show' | 'Hide' | 'Error' = 'Hide';
	showSelfAssessOption: boolean = userStorage.get().showSelfAssessOption;
}

export class Props {
	testScreenTypes: TestScreenType[];
	testID: number;
	testName: string;
	isWhiteBackground: boolean;
}

export class PreviewButton extends React.PureComponent<Props, State> {
	private containerRef: React.RefObject<HTMLDivElement> = React.createRef();
	public dropdownRef: React.RefObject<HTMLDivElement> = React.createRef();
	public expandableRef: React.RefObject<HTMLDivElement> = React.createRef();
	public state = new State();
	private panel: HTMLDivElement;
	private readonly onDestroy$: Subject<void> = new Subject();

	componentDidMount(): void {
		const panel = document.getElementsByClassName('te-main-center')[0] as HTMLDivElement;
		if (panel) {
			panel.addEventListener('scroll', this.hide);
			this.panel = panel;
		}
		document.addEventListener('click', this.onUnFocus);
		userStorage.onChanged$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
			this.setState({showSelfAssessOption: userStorage.get().showSelfAssessOption});
		});
	}

	componentWillUnmount(): void {
		if (this.panel) {
			this.panel.removeEventListener('scroll', this.hide);
		}
		this.containerRef.current?.removeEventListener('click', this.onUnFocus);
		this.onDestroy$.next();
	}

	private hide = () => {
		this.setState({toggled: false});
	};

	private onUnFocus = (e) => {
		const block = this.containerRef.current;
		if (block?.contains(e.target as Node)) {
			return;
		}
		this.hide();

	};

	private handle(value: string) {
		this.setState({toggled: false});
		if (value === TestScreenType.OneToOne) {
			this.setState({showTestPreview: true});
		}
		if (value === TestScreenType.SelfAssessment) {
			this.selfAssessPreview();
		}
	}

	render() {
		const button = this.renderButton();

		return <>
			{button}
			{this.state.showTestPreview &&
				<TestPreview testID={this.props.testID} onClose={() => this.setState({showTestPreview: false})}/>}
		</>;
	}

	private renderButton() {
		if (this.state.showSelfAssessOption && this.props.testScreenTypes.includes(TestScreenType.SelfAssessment)) {
			return this.renderToggleButton();
		}

		return <PrimaryButton onClick={() => this.setState({showTestPreview: true})}>
			Preview
		</PrimaryButton>;
	}

	private renderToggleButton() {
		return <div ref={this.containerRef}>
			<div ref={this.dropdownRef} className={styles.previewContainer}>
				<PrimaryButton className={styles.primary} onClick={() => this.setState({toggled: !this.state.toggled})}>
					Preview
					<div className={styles.expand}>
						<ExpandIcon toggled={this.state.toggled} color='#ffffff'/>
					</div>
				</PrimaryButton>
			</div>

			<DropdownContext.Provider value={{
				dropdownRef: this.dropdownRef.current,
				currentValue: [Number.MAX_VALUE],
				multiselect: false,
				toggled: this.state.toggled,
				onOptionClick: (v) => this.handle(v),
			}}>
				<div ref={this.expandableRef}>
					<Popup toggled={this.state.toggled}
					       onLooseFocus={() => this.setState({toggled: false})}
					       targetRef={this.dropdownRef}>
						{this.renderSelfAssessment()}
						{this.renderOneToOne()}
					</Popup>
				</div>
			</DropdownContext.Provider>
			<Loader show={this.state.status === 'Loading'} fullscreen={true}/>
			{this.renderSelfAssessModal()}
		</div>;
	}

	private renderSelfAssessModal() {
		if (this.state.status === 'Show') {
			return <SelfAssessPreview questions={this.state.questions}
			                          testName={this.props.testName}
			                          onClose={() => this.setState({status: 'Hide'})}/>;
		}

		return null;
	}

	private renderSelfAssessment() {
		if (this.props.testScreenTypes.filter(s => s === TestScreenType.SelfAssessment)) {
			return <Option key={TestScreenType.SelfAssessment} value={TestScreenType.SelfAssessment}>
				Student self-assess
			</Option>;
		}

		return null;
	}

	private renderOneToOne() {
		if (this.props.testScreenTypes.filter(s => s === TestScreenType.OneToOne)) {
			return <Option key={TestScreenType.OneToOne} value={TestScreenType.OneToOne}>Teacher one-on-one</Option>;
		}

		return null;
	}

	private selfAssessPreview() {
		if (this.state.questions.length) {
			this.setState({status: 'Show'});
			return;
		}
		this.setState({status: 'Loading'});
		HttpClient.default.ESGIApi.get<{questions: Response[]}>('pages/test-explorer/preview-button', 'self-assess', {testID: this.props.testID})
			.subscribe(resp => {
				console.log('ressp', resp);
				const questions = resp.questions.map(q => ({
					id: q.id,
					templateType: TemplateType[q.templateType],
					...JSON.parse(q.template),
				}));
				this.setState({questions: questions, status: 'Show'});
			}, () => {
				this.setState({status: 'Error'});
			});
	}
}

interface Response {
	id: number;
	template: string;
	templateType: string;
}
