import {Dispatch, useCallback, useEffect, useState} from 'react';
import {SettingsPanel, ToggleGroup} from '@esgi/main/kits/reports';
import {ColorType} from './types';
import {items} from './constants';

interface Props {
	value: boolean;
	onChange: Dispatch<boolean>;
}

export function OutputControl({onChange, value: externalValue}: Props) {
	const [value, setValue] = useState(ColorType.BlackAndWhite);

	useEffect(() => {
		setValue(externalValue ? ColorType.Colored : ColorType.BlackAndWhite);
	}, [externalValue]);

	const handleValueChanged = useCallback(
		(newValue: ColorType) => {
			setValue(newValue);
			onChange(newValue === ColorType.Colored);
		},
		[onChange],
	);
	return (
		<SettingsPanel data-cy='output-settings-panel' title='Output'>
			<ToggleGroup value={value} options={items} onChange={(color) => handleValueChanged(color as ColorType)} />
		</SettingsPanel>
	);
}
