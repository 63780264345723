import {useSubjects, Student} from '@esgi/main/libs/store';
import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

export const useStudentsFilteredBySubject = (students: Student[]) => {
	const {subjectID} = useParams();

	const [{data: subjects}] = useSubjects();

	const filteredStudents: Student[] = useMemo(() => {
		const subjectGradeLevels = new Set(
			subjects.find((subject) => subject.id === Number(subjectID))?.gradeLevels
		);
		if (subjectGradeLevels.size) {
			const filteredStudents = students.filter((el) =>
				subjectGradeLevels.has(el.gradeLevelID)
			);
			return filteredStudents;
		} else {
			return students;
		}
	}, [subjectID, subjects, students]);

	return filteredStudents;
};
