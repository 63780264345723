import React, {
	ComponentPropsWithoutRef,
	ReactNode,
	forwardRef,
	useMemo,
	useState,
	useRef,
	useCallback,
} from 'react';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {ProgressBarContext, ProgressBarContextValue} from '../../context';
import {Box} from '@esgi/ui/layout';

type ProgressBarRootProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;

	/** Progress value. */
	value: number;

	/** Progress maximum value. Percentage calculation is based on the maximum value provided. */
	maxValue: number;

	/** Item Content. Progress is percentage calculated value in float (0.33 = 33%). */
	children?: ReactNode | ((args: {progress: number}) => React.JSX.Element);
};

export const ProgressBarRoot = forwardRef<HTMLDivElement, ProgressBarRootProps>(
	({dataCy, value, maxValue, children, ...props}, forwaredRef) => {

		const [isAnimationFinished, setAnimationFinished] = useState<boolean>(false);
		const lineProgressBarRef = useRef<HTMLElement | null>(null);
		const setLineProgressBarRef = useCallback((item: HTMLElement) => {
			lineProgressBarRef.current = item;
		}, []);

		const contextValue = useMemo<ProgressBarContextValue>(() => {
			const progress = value / maxValue;

			return {
				value,
				maxValue,
				progress: progress >= 1 ? 1 : progress,
				isAnimationFinished,
				setAnimationFinished,
				lineProgressBarRef,
				setLineProgressBarRef,
			};
		}, [value, maxValue, isAnimationFinished, setAnimationFinished]);

		return (
			<ProgressBarContext.Provider value={contextValue}>
				<ProgressBarBox
					data-cy={dataCy ?? 'ui-kit-progress-bar-ring-root'}
					aria-valuemax={maxValue}
					aria-valuemin={0}
					aria-valuenow={value}
					role='progressbar'
					data-value={value}
					data-max={maxValue}
					ref={forwaredRef}
					{...props}
				>
					{typeof children === 'function'
						? children({
								progress: contextValue.progress,
						  })
						: children}
				</ProgressBarBox>
			</ProgressBarContext.Provider>
		);
	},
);

const ProgressBarBox = styled(Box, {
	position: 'relative',
	overflow: 'hidden',
});
