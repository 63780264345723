import {Select as SelectUI} from '@esgi/ui/controls';
import {SelectField, SelectWrapper} from './index.styled';
import {Text} from '@esgi/ui/typography';

export type OptionItem = {
	value: string;
	label: string;
};

type Props = {
	selectedValue: string | undefined;
	items: OptionItem[];
	onValueChange: (value: string) => void;
	placeholder: string;
	disabled?: boolean;
};

export function Select({items, placeholder, onValueChange, selectedValue, disabled}: Props) {
	return (
		<SelectWrapper dataCy={placeholder.replace(/\s+/g, '-').toLowerCase()}>
			<SelectUI.Root
				key={selectedValue}
				value={selectedValue}
				onValueChange={onValueChange}
				disabled={Boolean(disabled)}
			>
				<SelectField placeholder={placeholder}/>
				<SelectUI.Content>
					{items.map(({value, label}) => {
						return (
							<SelectUI.Option value={value} key={value}>
								<Text size='medium' bold>{label}</Text>
							</SelectUI.Option>
						);
					})}
				</SelectUI.Content>
			</SelectUI.Root>
		</SelectWrapper>
	);
}