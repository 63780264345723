import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {dispatchAppEvent, EventBusDispatcher} from '@esgillc/events';
import {SsoTracker} from '@esgi/core/tracker';
import {
	GroupChangedEvent,
	GroupCreatedEvent,
	GroupRemovedEvent,
} from 'modules/forms/group-form/events';
import {InitCreateModel, InitEditModel, StudentModel, InitArguments, InModel} from './types';
import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {ObservableBuilder} from '@esgi/api';


class GroupFormService extends BaseService {
	groupName = new BehaviorSubject<string>('');
	availableStudents = new BehaviorSubject<InModel[]>([]);
	studentsInGroup = new BehaviorSubject<InModel[]>([]);
	form: FormGroup<{ groupName: FormControl<string> }, ControlValue<{ groupName: FormControl<string> }>> =
		new FormGroup({groupName: new FormControl('', {validators: [Validators.required()]})});
	teacherID: number;
	classID: number;
	groupID: number;

	public init = ({teacherID, classID, groupID, groupName}: InitArguments): ObservableBuilder<InitEditModel | InitCreateModel> => {
		this.teacherID = teacherID;
		this.classID = classID;
		this.groupID = groupID;
		if (groupName) {
			this.form.controls.groupName.value = groupName;
			this.groupName.next(groupName);
		}
		this.form.controls.groupName.onChanged.subscribe((field) => {
			this.groupName.next(field.currState.value);
		});

		if (this.groupID) {
			return this.initEdit();
		}
		return this.initCreate();
	};

	public create() {
		return this.httpClient.ESGIApi.post<any>('group', 'create', {
			name: this.groupName.value,
			classID: this.classID,
			teacherID: this.teacherID,
			studentIDs: this.studentsInGroup.value.map(student => student.id),
		}).pipe(tap((response) => {
			SsoTracker.trackEvent({
				trackingEvent: 'GroupCreated',
				data: {groupId: response.groupID},
			});

			const args = new GroupCreatedEvent(response.groupID, this.groupName.value,
				this.studentsInGroup.value.map(student => student.id));
			EventBusDispatcher.dispatch(GroupCreatedEvent, args);
		}));
	}

	public update() {
		return this.httpClient.ESGIApi.post<any>('group', 'update', {
			groupID: this.groupID,
			classID: this.classID,
			groupName: this.groupName.value,
			studentIDs: this.studentsInGroup.value.map(student => student.id),
		}).pipe(tap(() => {
			const event = new GroupChangedEvent(this.groupID, this.groupName.value, this.studentsInGroup.value.map(student => student.id));
			dispatchAppEvent(GroupChangedEvent, event);
		}));
	}

	public save() {
		return this.groupID ? this.update() : this.create();
	}

	public remove() {
		return this.httpClient.ESGIApi.post<number>('group', 'remove', {groupID: this.groupID})
			.pipe(tap(() => {
				SsoTracker.trackEvent({
					trackingEvent: 'GroupDeleted',
					data: {groupId: this.groupID},
				});
				const event = new GroupRemovedEvent(this.groupID);
				dispatchAppEvent(GroupRemovedEvent, event);
			}));
	}

	public selectAvailableStudent = (selectedItems: number[]): void => {
		const newStudentsInGroup = this.studentsInGroup.value.map((student) => {
			return {...student, checked: false};
		});

		const newAvailableStudents = this.availableStudents.value.map(student => {
			const isStudentChecked = selectedItems.indexOf(student.id) !== -1;
			return {...student, checked: isStudentChecked};
		},
		);

		this.studentsInGroup.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);
	};

	public selectStudentsInGroup = (selectedItems: number[]): void => {
		const newAvailableStudents = this.availableStudents.value.map((student) => {
			return {...student, checked: false};
		});
		const newStudentsInGroup = this.studentsInGroup.value.map(student => {
			const isStudentChecked = selectedItems.indexOf(student.id) !== -1;
			return {...student, checked: isStudentChecked};
		},
		);
		this.studentsInGroup.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);
	};

	public selectByFilterInGroupStudents = (checked: boolean): void => {
		let newStudentsInGroup: InModel[];
		let newAvailableStudents = this.availableStudents.value;
		if (checked) {
			newStudentsInGroup = this.studentsInGroup.value.map((student) => {
				return {...student, checked: true};
			});

			if (newStudentsInGroup.length) {
				newAvailableStudents = this.availableStudents.value.map((student) => {
					return {...student, checked: false};
				});

			}
		} else {
			newStudentsInGroup = this.studentsInGroup.value.map((student) => {
				return {...student, checked: false};
			});
		}
		this.studentsInGroup.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);
	};

	public selectByFilterAvailableStudents = (checked: boolean): void => {
		let newStudentsInGroup = this.studentsInGroup.value;
		let newAvailableStudents: InModel[];

		if (checked) {
			newAvailableStudents = this.availableStudents.value.map((student) => {
				return {...student, checked: true};
			});

			if (newAvailableStudents.length > 0) {
				newStudentsInGroup = this.studentsInGroup.value.map((student) => {
					return {...student, checked: false};
				});
			}
		} else {
			newAvailableStudents = this.availableStudents.value.map((student) => {
				return {...student, checked: false};
			});
		}

		this.studentsInGroup.next(newStudentsInGroup);
		this.availableStudents.next(newAvailableStudents);

	};

	public handleMove = (): void => {
		if (this.availableStudents.value.filter(student => student.checked).length) {
			this.moveToGroup();
			return;
		}
		this.moveToAvailable();
	};

	private initCreate() {
		return this.httpClient.ESGIApi.get<InitCreateModel>('group', 'init-create', {
			teacherID: this.teacherID,
			classID: this.classID,
		}).pipe(tap((response)=>{
			const students = this.handleLoadedStudents(response.items);
			this.availableStudents.next(students);
		}));
	}

	private initEdit() {
		return this.httpClient.ESGIApi.get<InitEditModel>('group', 'init-edit', {
			teacherID: this.teacherID,
			groupID: this.groupID,
			classID: this.classID,
		}).pipe(tap((response) => {
			const availableStudents = this.handleLoadedStudents(response.availableItems);
			const studentsInGroup = this.handleLoadedStudents(response.itemsInGroup);
			this.studentsInGroup.next(studentsInGroup);
			this.availableStudents.next(availableStudents);
		}));
	}

	private handleLoadedStudents(students: StudentModel[]): InModel[] {
		return students.map(student => new InModel(student.id, student.name, false));
	}

	private moveToAvailable(): void {
		const checkedStudents = this.studentsInGroup.value.filter(student => student.checked);
		const studentsInGroup = this.studentsInGroup.value.filter(student => !student.checked);
		const availableStudents = this.availableStudents.value.concat(checkedStudents).map(student => student.checked ? {
			...student,
			checked: false,
		} : student);

		this.studentsInGroup.next(studentsInGroup);
		this.availableStudents.next(availableStudents);
	}

	private moveToGroup(): void {
		const checkedStudents = this.availableStudents.value.filter(student => student.checked);
		const availableStudents = this.availableStudents.value.filter(student => !student.checked);
		const studentsInGroup = this.studentsInGroup.value.concat(checkedStudents).map(student => student.checked ? {
			...student,
			checked: false,
		} : student);
		this.studentsInGroup.next(studentsInGroup);
		this.availableStudents.next(availableStudents);
	}

}

export default GroupFormService;
