import {PropsWithChildren, forwardRef, useCallback} from 'react';
import {ActionButtonsBox, WrapperBox} from './index.styled';
import {Button, Close} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useDrawerContext} from '../../../../context/drawer-context';
import {Box} from '@esgi/ui/layout';
import {PanelTrigger} from '../panel-trigger';

type Props = PropsWithChildren<
	| {
			withActionButton?: false;
	  }
	| {
			withActionButton: true;
			actionButtonText: string;
			actionButtonDisabled?: boolean;
			onActionButtonClick: VoidFunction;
	  }
>;

export const PanelHeader = forwardRef<HTMLDivElement, Props>(({children, ...props}, forwardedRef) => {
	const {togglePanelOpen, closeDrawerWithCbBefore} = useDrawerContext();

	const handleCloseDrawer = useCallback(() => {
		togglePanelOpen();
		closeDrawerWithCbBefore();
	}, [togglePanelOpen, closeDrawerWithCbBefore]);

	return (
		<WrapperBox ref={forwardedRef}>
			<Box>{children}</Box>

			<ActionButtonsBox>
				<PanelTrigger />

				{props.withActionButton && (
					<Button color='primary' disabled={Boolean(props.actionButtonDisabled)} onClick={props.onActionButtonClick}>
						<Text size='large'>{props.actionButtonText}</Text>
					</Button>
				)}

				<Button.Icon onClick={handleCloseDrawer} withBackgroundHover>
					<Close />
				</Button.Icon>
			</ActionButtonsBox>
		</WrapperBox>
	);
});
