export class DistrictAndSchoolSearchResponse {
	items: DistrictAndSchoolResponseModel[];
}

export class DistrictAndSchoolResponseModel {
	federalDistrictID: number;
	federalDistrictName: string;
	federalSchoolID: number;
	federalSchoolName: string;
}

export class SchoolModel {
	districtID: number;
	districtName: string;
	schoolID: number;
	schoolName: string;
	name: string;

	public static FromResponse(model: DistrictAndSchoolResponseModel) {
		const m = new SchoolModel();
		m.districtID = model.federalDistrictID;
		m.districtName = model.federalDistrictName;
		m.schoolID = model.federalSchoolID;
		m.schoolName = model.federalSchoolName;
		m.name = `${model.federalSchoolName} (${model.federalDistrictName})`;
		return m;
	}
}

export enum SelectionType {
	Existing,
	New,
	Skip
}

export interface DistrictsResponse {
	federalDistricts: DistrictResponseModel[]
}

export interface DistrictResponseModel {
	id: number;
	name: string;
}

export class DistrictSearchModel {
	id: number;
	name: string;

	public static FromResponse(s: DistrictResponseModel) {
		const m = new DistrictSearchModel();
		m.id = s.id;
		m.name = s.name;
		return m;
	}
}

export interface InitResponseModel {
	notAllowed: boolean,
	isCompleted: boolean,
	unlinked: UnlinkedModel,
	linked: LinkedModel
}

export interface UnlinkedModel {
	countryID: number;
	stateID: number;
	timeZoneID: string;
	firstName: string;
	lastName: string;
}

export interface InfoModel {
	id: number,
	name: string
}

export interface LinkedModel {
	districtName: string,
	timeZoneID: string,
	schools: Array<InfoModel>
	tracks: Array<InfoModel>
}
