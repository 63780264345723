import {Alert as AlertBundle} from '@esgillc/ui-kit/modal';

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/alert instead.
 **/
export const Alert = AlertBundle;

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/alert instead.
 **/
export const AlertHeader = AlertBundle.Header;

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/alert instead.
 **/
export const AlertBody = AlertBundle.Body;

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/alert instead.
 **/
export const AlertFooter = AlertBundle.Footer;
