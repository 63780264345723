import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert} from '@esgi/ui/alert';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
	overflow: 'auto',
	maxHeight: '75vh',
	padding: 0,
});

export const ReportWrapper = styled(FlexBox, {
	flexDirection: 'column',
	width: '800px',
	padding: '0 20px',
});

export const Page = styled(FlexBox, {
	flexDirection: 'column',

	'&:not(:first-child)': {
		borderTop: '3px dashed #e5e5e5',
		marginTop: '20px',
	},
});

export const Header = styled(FlexBox, {
	flexDirection: 'column',
});

// There is no such set of parameters among the default ones.
export const HeaderText = styled(Text, {
	color: '$base',

	variants: {
		type: {
			studentName: {
				fontSize: '22px',
				fontWeight: '700',
				marginBottom: '10px',
				marginTop: '10px',
			},
			subjectName: {
				fontSize: '14px',
				fontWeight: '500',
				lineHeight: '20px',
				marginBottom: '10px',
			},
		},
	},
});
