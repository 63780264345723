import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo} from 'react';
import {Box} from '@esgi/ui/layout';
import {Button, Close, isUndefined, useQueryValue} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useDrawerContext} from '../../../../context/drawer-context';
import {ActionButtonsBox, WrapperBox} from './index.styled';
import {PanelTrigger} from '../panel-trigger';

type WithoutActionButton = {
	withActionButton?: false;
};

type WithActionButton = {
	withActionButton: true;
	actionButtonText: string;
	actionButtonDisabled?: boolean;
	onActionButtonClick: VoidFunction;
};

type WithOptionButtons = {
	withOptionButtons?: boolean;
	optionButtons?: JSX.Element
};

type Props = ComponentPropsWithoutRef<'div'> &
	(WithActionButton | WithoutActionButton) & WithOptionButtons & {
		onClose?: VoidFunction;
	};

export const PanelHeader = forwardRef<HTMLDivElement, Props>(({children, ...props}, forwardedRef) => {
	const {togglePanelOpen} = useDrawerContext();
	const drawerSearchKey = 'drawer';

	const {wrapperBoxProps, actionButtonProps} = useMemo(() => {
		if (props.withActionButton) {
			const {withActionButton, actionButtonText, actionButtonDisabled, onActionButtonClick, ...restProps} = props;

			return {
				actionButtonProps: {
					withActionButton: true,
					actionButtonText,
					actionButtonDisabled,
					onActionButtonClick,
				},
				wrapperBoxProps: restProps,
			};
		}

		const {withActionButton, ...restProps} = props;

		return {
			actionButtonProps: {
				withActionButton: false,
			},
			wrapperBoxProps: restProps,
		};
	}, [props]);

	const [, setDrawerName] = useQueryValue(drawerSearchKey);

	const closeDrawer = useCallback(() => setDrawerName(undefined), [setDrawerName]);
	const handleCloseDrawer = () => {
		if(isUndefined(props?.onClose)){
			togglePanelOpen();
			closeDrawer();
		} else {
			props.onClose();
		}
	};

	return (
		<WrapperBox {...wrapperBoxProps} ref={forwardedRef}>
			<Box data-children-box='' dataCy='section-title'>{children}</Box>

			<ActionButtonsBox>
				{props.withOptionButtons && <>{props.optionButtons}</>}
				<PanelTrigger tooltipTitle='View Less' dataCy='view-less-trigger' />

				{actionButtonProps.withActionButton && (
					<Button
						color='primary'
						disabled={Boolean(actionButtonProps.actionButtonDisabled)}
						onClick={actionButtonProps.onActionButtonClick}
						dataCy='action-button'
					>
						<Text size='large'>{actionButtonProps.actionButtonText}</Text>
					</Button>
				)}

				<Button.Icon dataCy='close-button' onClick={handleCloseDrawer} withBackgroundHover>
					<Close />
				</Button.Icon>
			</ActionButtonsBox>
		</WrapperBox>
	);
});
