import {IndicatorBox} from './indicator.styled';

export function IndicatorSucceeded() {
	return (
		<IndicatorBox
			css={{
				backgroundColor: '$positiveVivid',
			}}
		/>
	);
}
