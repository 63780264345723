import React from 'react';
import {SortDirection} from '@esgi/core/enums';

export class Props {
	title?: string;
	class?: string;
	clicked?: () => any;
	sortDirection?: SortDirection;
	sorted: boolean;
}

export class Column extends React.Component<Props> {

	render() {
		return <th style={{zIndex: 1}} className={this.props.class} onClick={() => this.props.clicked && this.props.clicked()}>
			<div className='cell-value-holder'>
				{this.props.title}
				{this.renderSortIcon()}
			</div>
		</th>;
	}

	private renderSortIcon() {
		if (this.props.sorted) {
			return <i
				className={'fa ' + (this.props.sortDirection === SortDirection.Asc ? 'fa-angle-up' : 'fa-angle-down')}/>;
		}

	}
}
