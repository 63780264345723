import {FormElement} from '@esgillc/ui-kit/form';
import {Hint, Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import Section from '../index';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {SchoolFormType} from 'modules/subject-details/forms/school';
import {PersonalFormType} from 'modules/subject-details/forms/personal';
import styles from './styles.module.less';

interface Props {
	form: DistrictFormType | SchoolFormType | PersonalFormType
}

const maxNameLength = 35;

export default function SubjectTabNameInput({form}: Props) {
	return <Section data-cy={'subject-section'} title='Subject Tab' className={join(styles.nameSection)} required>
		<FormElement control={form.controls.subjectName} className={styles.container}>
			<Input placeholder='Enter subject tab name' maxLength={maxNameLength}/>
			<Hint className={styles.limitCounter}>
				{props => `${maxNameLength - (props.value?.length | 0)} characters left (${maxNameLength} max)` }
			</Hint>
			<OnErrorTooltip placement='right' showOnError='required'>This field is required</OnErrorTooltip>
			<OnErrorTooltip placement='right' showOnError='unique'>Name must be unique</OnErrorTooltip>
		</FormElement>
	</Section>;
}

