import {Renderable} from '@esgi/deprecated/knockout';
import {Buttons} from '@esgillc/ui-kit/button';
import './email-existing-dialog.less';

export class EmailExistingDialog extends Renderable {
	public template = () => {
		return <div className='email-modal'>
			<div className='inner-modal'>
				<div className='title'>
					<b>An account with this email already exists!</b>
				</div>
				<div className='content'>
					Do you want to log in to the existing account?
				</div>
				<div className='dialog-actions'>
					<Buttons.Contained data-bind='click:login'>
            Yes, log in to the existing account
          </Buttons.Contained>
					<Buttons.Outlined data-bind='click:createNew'>
            No, create new account
          </Buttons.Outlined>
				</div>
			</div>
		</div>;
	};

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		window.scrollTo(0, 0);
		return super.afterRender(rootElement);
	}

	events = {
		login: (callback) => {
			$(this).on('login', callback);
		},
		createNew: (callback) => {
			$(this).on('createNew', callback);
		},
	};
	login = () => {
		$(this).trigger('login');
	};
	createNew = () => {
		$(this).trigger('createNew');
	};
}
