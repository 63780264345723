import {BaseButton} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gap: '$1',
	alignItems: 'center',
	paddingTop: 3,
	paddingBottom: 3,
	paddingLeft: '$1',
	paddingRight: '$1',
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$vivid',
	gridTemplateColumns: 'repeat(3, auto)',
	height: 'max-content',
});

export const Button = styled(BaseButton, {
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	borderRadius: 3.5,
	width: 16,
	height: 16,
	color: '$base',

	'&:hover:not(:disabled)': {
		backgroundColor: '$secondaryMuted',
		borderColor: '$secondaryMuted',
		color: '$secondary',
	},

	'& > svg': {
		'& rect': {
			stroke: 'none',
			fill: 'none',
		},

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		isActive: {
			true: {
				cursor: 'default',
				color: '$primary',
				backgroundColor: '$primary92',
				borderColor: '$primary92',
			},
		},
	},
});
