import React from 'react';
import {GroupFormHeader} from 'modules/forms/group-form/components/header';
import {CheckboxListBox} from 'modules/forms/group-form/components/checkbox-list-box';
import {MoveButton} from 'modules/forms/group-form/components/move-button';
import GroupFormService from 'modules/forms/group-form/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {checkboxOptionsMode, getArrowDirection} from './utils';
import styles from './styles.module.less';

interface Props {
	groupID: number;
	className: string;
	service: GroupFormService
}


export function GroupForm({
	                          groupID,
	                          className,
	                          service,
}: Props) {
	const availableStudents = useBehaviorSubject(service.availableStudents);
	const studentsInGroup = useBehaviorSubject(service.studentsInGroup);
	const groupName = useBehaviorSubject(service.groupName);

	const isMoveDisabled = (): boolean => {
		const students = availableStudents.concat(studentsInGroup);
		const checked = students.filter(student => student.checked);
		return !checked.length;
	};

	return <div className={styles.classGroupEditForm} data-cy='group-form'>
		<GroupFormHeader
			groupID={groupID}
			className={className}
			formController={service.form}
		/>
		<div className={styles.groupFormBody}>
			<CheckboxListBox
				isChecked={checkboxOptionsMode(availableStudents)}
				onCheckboxClick={service.selectByFilterAvailableStudents}
				onItemCheckboxClick={service.selectAvailableStudent}
				items={availableStudents}
				label={'Available Students'}
			/>
			<MoveButton
				onClick={service.handleMove}
				moveDisabled={isMoveDisabled()}
				arrowDirection={getArrowDirection(availableStudents, studentsInGroup)}
			/>
			<CheckboxListBox
				isChecked={checkboxOptionsMode(studentsInGroup)}
				onCheckboxClick={service.selectByFilterInGroupStudents}
				onItemCheckboxClick={service.selectStudentsInGroup}
				items={studentsInGroup}
				label={groupName || 'New group'}
				withLabelTooltip
			/>
		</div>
	</div>;
}
