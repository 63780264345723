import {ExpandablePanel, Reports, SettingsPanel as ReportsSettingsPanel} from '@esgi/main/kits/reports';
import {Wrapper, Link, CheckboxWrapper} from './index.styled';
import {ClassTotalsService} from '../../service';
import {Info, useBehaviorSubject} from '@esgi/ui';
import {Dispatch, SetStateAction} from 'react';
import {Checkbox} from '@esgi/ui/controls';
import {RightSidebar} from '../../../../layout/index.styled';
import {DisplayResultsControl} from './display-results-control';
import {DisplayResults, MarkingPeriod, NotTested} from '../../service/types';

type Props = {
	disabled: boolean,
	service: ClassTotalsService,
	getReport: Dispatch<void>,
}

export function SettingsPanel({disabled, service, getReport}: Props) {
	const markingPeriod = useBehaviorSubject<MarkingPeriod | null>(service.markingPeriod$);
	const notTested = useBehaviorSubject<NotTested | null>(service.notTested$);
	const displayResults = useBehaviorSubject<DisplayResults | null>(service.displayResults$);
	const carryForward = useBehaviorSubject<boolean | null>(service.carryForward$);

	return (
		<RightSidebar>
			<Reports.ReportPanel
				reportTitle='Run Report'
				disabled={disabled}
				onReport={getReport}
			/>
			<ExpandablePanel>
				<Wrapper>
					<DisplayResultsControl value={displayResults} disabled={disabled} onChange={(value) => service.setDisplayResults(value)}/>
					<ReportsSettingsPanel title='Settings'>
						<CheckboxWrapper>
							<Checkbox
								data-cy='carry-forward-checkbox'
								disabled={disabled}
								label='Carry Forward'
								checked={carryForward}
								onCheckedChange={(value: boolean) => service.setCarryForward(value)}
								dataCy='carry-forward-checkbox'
							/>
							<Link
								data-cy='carry-forward-link'
								href='https://support.esgisoftware.com/hc/en-us/articles/209159526-Class-Totals-Report'
								target='_blank'
								rel='noreferrer'
							>
								<Info width={24} height={24}/>
							</Link>
						</CheckboxWrapper>
					</ReportsSettingsPanel>
					<Reports.MarkingPeriodControl data-cy='marking-period-toggle-group' value={markingPeriod} disabled={disabled} onChange={(value) => service.setMarkingPeriod(value)}/>
					<Reports.NotTestedControl data-cy='not-tested-toggle-group' value={notTested} disabled={disabled} onChange={(value) => service.setNotTested(value)}/>
				</Wrapper>
			</ExpandablePanel>
		</RightSidebar>
	);
}