import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {SortTable as SortTableIcon} from '@esgi/ui/icons';

export const Wrapper = styled(FlexBox, {
	alignItems: 'center',
	padding: '8px 12px 8px 8px',
});

export const ButtonIcon = styled(Button.Icon, {
	marginRight: '30px',
});

export const ButtonText = styled(Button.Text, {
	marginRight: '8px',
	display: 'flex',
	alignItems: 'center',
	color: '$lowContrast',

	'& svg': {
		marginLeft: '2px',
	},

	'& span': {
		textWrap: 'nowrap',
	},
});

export const Container = styled(GridBox, {
	width: '100%',
	gridTemplateColumns: '1fr auto',
	gap: '24px',
	alignItems: 'center',
});

export const OpenListButton = styled(Button.Icon, {
	marginLeft: '16px',
	transition: '.2s ease',

	'> svg': {
		transition: '.2s ease',
	},
});

export const TagList = styled(GridBox, {
	marginLeft: 'auto',
	gridAutoFlow: 'column',
	gap: '$3',
	marginRight: '16px',
});

export const Tag = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: '16px',
	height: '16px',
	border: '1px solid $mild',
	borderRadius: '4px',
	backgroundColor: '$vivid',
	color: '$lowContrast',
	cursor: 'default',

	variants: {
		type: {
			green: {
				backgroundColor: '$positiveMuted',
				borderColor: '$positiveMuted',

				'& path': {
					fill: '$positive',
				},
			},
			red: {
				backgroundColor: '$red92',
				borderColor: '$red92',

				'& path': {
					fill: '$negative',
				},
			},
		},
	},
});

export const SortTable = styled(SortTableIcon, {
	variants: {
		direction: {
			asc: {
				'& path:last-child': {
					fill: '$primary',
				},
			},
			desc: {
				'& path:first-child': {
					fill: '$primary',
				},
			},
		},
		field: {
			default: {
				'& path:first-child, & path:last-child,': {
					fill: 'currentColor',
				},
			},
			name: {},
			id: {},
		},
	},
});
