import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {CheckboxGroup} from '@esgi/ui/controls';
import {Statistics} from '../../../kit';

export const ProgramsFilters = styled(Box, {
	padding: '6px',
	border: '1px solid $border',
	borderRadius: '8px',
	backgroundColor: '$vivid',
	width: '100%',
});

export const CheckboxGroupRoot = styled(CheckboxGroup.Root, {
	display: 'grid',
	gridTemplateColumns: 'repeat(5, auto) 1fr',
	gapX: '$4',
});

export const ProgramsFiltersContainer = styled(FlexBox, {
	padding: '20px',
	width: '100%',
});

export const StatisticsRoot = styled(Statistics, {
	width: '100%',
});
