import {routes} from '@esgi/main/libs/core';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

export function useOpenAssignmentList() {
	const navigate = useNavigate();

	return useCallback(() => {
		navigate(routes.teacher.assignments.assignmentsList);
	}, [navigate]);
}
