/**
 * @name ReportErrorEvent
 * @property {string} message   - The message that will be shown to the user in the body of the alert.
 * @property {string} token     - Optional, will be passed to the Contact Us form.
 * @see {@link ErrorReporterLayer}
 */
export class ReportErrorEvent {
	constructor(public message: string, public token?: string) {
	}
}
