import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const PrivacyPolicyPage = lazyComponent(() => import('pages/privacy-policy'));

export default function PrivacyPolicy() {
	return (
		<RouteWrapper title='ESGI, LLC Privacy Policy'>
			<Suspense fallback={<DefaultRouteFallback />}>
				<PrivacyPolicyPage />
			</Suspense>
		</RouteWrapper>
	);
}
