import {ErrorTooltip} from '@esgi/ui/form';
import {Validation} from '../types';
import {Text} from '@esgi/ui/typography';

type Props = {
	validations: Validation[];
};

export function ErrorTooltips({validations}: Props) {
	return (
		<>
			{validations.map(({message, showOnError}) => (
				<ErrorTooltip
					showOnError={showOnError}
					css={{
						maxWidth: 'calc(var(--radix-tooltip-trigger-width) - 10px)',
					}}
				>
					<Text size='xSmall' font='mono' color='negativeVivid'>
						{message}
					</Text>
				</ErrorTooltip>
			))}
		</>
	);
}
