import {PropsWithChildren, useMemo, useState} from 'react';
import {InfoBlockContext, InfoBlockContextValue} from '../../context';

export function InfoBlockRoot({children}: PropsWithChildren) {
	const [inBlock, setInBlock] = useState(false);

	const value = useMemo<InfoBlockContextValue>(
		() => ({
			inBlock,
			setInBlock,
		}),
		[inBlock],
	);

	return <InfoBlockContext.Provider value={value}>{children}</InfoBlockContext.Provider>;
}
