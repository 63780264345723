import {CentralPanel} from '@esgi/main/kits/common';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CentralPanelHeaderContainer = styled(CentralPanel.Header, {
	display: 'grid',
	alignItems: 'center',
	gridTemplateColumns: '1fr auto',
	gap: 20,
	minHeight: 36,
	paddingLeft: 20,
	paddingRight: 20,
});

export const HeaderContainer = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
});

export const ActionButtonsBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 20,
	alignItems: 'center',
});

export const ActionButtonsDivider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
	borderRadius: 1,
});
