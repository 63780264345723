import {observable, Button, Form, FormField, Validators} from '@esgi/deprecated/knockout';
import {PromoCodeTemplate} from '../html/promo-code-template';
import {RenewalApi} from '../server';
import {Step} from './steps';

export class PromoCodeStepModel {
	promoCode: string;
}

export class PromoCodeStep extends Step {
	constructor(private currentPromoCode: string, private renewApi: RenewalApi) {
		super();
		this.title = 'Promo Code';
		this.promoCodeForm = this.createPromoCode(currentPromoCode);
		this.cancelButton = this.createCancelButton();
		this.submitButton = this.createSubmitButton();

		this.promoCodeForm.valid.subscribe(v => {
			let value = this.promoCodeForm.promoCode.value();
			if (!value) {
				this.submitButton.disabled(true);
			} else {
				if (v) {
					this.submitButton.disabled(false);
				} else {
					this.submitButton.disabled(true);
				}
			}
		});

		this.promoCodeForm.validate(false);
	}

	template = PromoCodeTemplate;

	promoCodeForm: PromoCodeForm;
	cancelButton: Button;
	submitButton: Button;


	private createPromoCode(currentPc: string) {
		const s = new PromoCodeForm(this.renewApi, currentPc);
		this.addElement(s);
		return s;
	}

	private createCancelButton() {
		const btn = new Button();
		btn.className('btn btn-cancel pull-left');
		btn.title('Cancel');
		return btn;
	}

	private createSubmitButton() {
		const btn = new Button();
		btn.className('btn btn-warning pull-right');
		btn.title('Save');
		btn.disabled(true);
		return btn;
	}

	events = {
		cancelClicked: (callback) => {
			this.cancelButton.events.click(callback);
		},
		submitClicked: (callback: (e: Event, b: Button) => any) => {
			this.submitButton.events.click(callback);
		},
		acFlowClicked: (callback: (code) => any) => {
			this.promoCodeForm.onActivationFlowClicked((e) => {
				callback && callback(e);
			});
		},
	};

}

export class PromoCodeForm extends Form {
	constructor(private renewApi: RenewalApi, private currentPc: string) {
		super();
		this.promoCode = this.create();
	}

	hasError = ko.computed({
		read: () => {
			return !!this.activationTooltip || !!this.promoCode.showErrorValidation() && !this.promoCode.valid();
		},
		deferEvaluation: true,
	});
	@observable()
	activationTooltip: JQuery;

	template = PromoCodeTemplate;

	promoCode: FormField<string>;

	public showAcTooltip() {
		const element = $('.form-group.promo');
		const tooltipBody = '<span>It looks like you entered an Activation Code. Go to the&nbsp;<a id="ac-link" style="cursor: pointer;">Activation Code</a>&nbsp;form</span>';
		this.activationTooltip = element.bstooltip({
			title: tooltipBody,
			trigger: 'manual',
			html: true,
			placement: 'right',
		});
		element.bstooltip('show');
		$('#ac-link', this.rootElement).on('click', () => {
			$(this).trigger('acFlow');
		});
	}

	onActivationFlowClicked(callback) {
		$(this).on('acFlow', (e => {
			callback && callback(this.promoCode.value());
		}));
	}

	removeTooltip() {
		if (this.activationTooltip) {
			this.activationTooltip.bstooltip('destroy');
			this.activationTooltip = null;
		}
	}

	create() {

		const pcValidator = Validators.Ajax(f => {
			f.validation.validationInProgress(true);
			f.validation.showValidationInProgress(true);
			return this.renewApi.api.renewCheckPromoCodeValidation(f.value())
				.done((r) => {
					if (r.IsActivationCode) {
						f.validation.successValidation(false);
						f.validation.errorValidation(true);
						setTimeout(() => this.showAcTooltip(), 100);
					} else {
						this.removeTooltip();
						f.validation.successValidation(true);
						f.validation.errorValidation(true);
					}
					return r;
				}).always(() => {
					f.validation.validationInProgress(false);
					f.validation.showValidationInProgress(false);
				});
		}, v => v.errorMessage);

		let f = this.createField<string>(this.currentPc, pcValidator);
		f.validation.successValidation(true);
		f.validation.showValidation(true);

		return f;
	}

	serialize(): {promoCode: string;} {
		let m = {
			promoCode: this.promoCode.value(),
		};

		return m;
	}
}
