import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import React, {Suspense} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import {lazyComponent} from '@esgi/core/react';

const GrowthReportModal = lazyComponent(() => import('modules/reports/growth-report'));

class State {
	growthReportOpened: boolean = false;
}

class Props {
	subjectType: SubjectType;
	subjectID: number;
	noStudents: boolean;
	hierarchy: HierarchyInstance;
}

export default class GrowthReportButton extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	clickHandler = () => {
		SsoTracker.trackEvent({
			trackingEvent: 'GrowthReport',
		});
		this.setState({growthReportOpened: true});
	};

	icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='14'
		            viewBox='0 0 15 14'
		            fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
			      d='M11.657 0.180921L9.36348 3.74205C9.20352 3.99042 9.39556 4.30642 9.70648 4.30642L10.5 4.30642V12.6883H9.00391V6.16639C9.00391 5.65187 8.55619 5.23478 8.00391 5.23478H7C6.44772 5.23478 6 5.65187 6 6.16639V12.6883H4.50391V8.03651C4.50391 7.52199 4.05619 7.1049 3.50391 7.1049H2.5C1.94772 7.1049 1.5 7.52199 1.5 8.03651V12.6883H0.501384C0.224477 12.6883 0 12.8975 0 13.1554C0 13.4134 0.224477 13.6225 0.501384 13.6225H2.49581L2.5 13.6225H3.50391L3.50809 13.6225H6.99497L7 13.6225H8.00391L8.00894 13.6225H11.4961L11.5 13.6225H12.5039L12.5079 13.6225H14.4986C14.7755 13.6225 15 13.4134 15 13.1554C15 12.8975 14.7755 12.6883 14.4986 12.6883H13.5039V4.30642L14.2935 4.30642C14.6044 4.30642 14.7965 3.99042 14.6365 3.74205L12.343 0.180921C12.1876 -0.0603068 11.8124 -0.060307 11.657 0.180921Z'
			      fill='#0088CC'/>
		</svg>;
	};

	public render() {
		return <>
			<NoStudentProtectedButton title={this.getTitle()}
			                  icon={this.icon()}
			                  hoverTitle='Shows growth on tests in a Subject Tab'
			                  onClicked={() => this.clickHandler()}
			                  noStudents={this.props.noStudents}
			                  linkClassName='growth-report-link'/>
			{this.renderReport()}
		</>;
	}

	private renderReport() {
		if (this.state.growthReportOpened) {
			return <Suspense fallback={<div/>}>
				<GrowthReportModal hierarchy={this.props.hierarchy}
				                   onCloseClicked={() => this.setState({growthReportOpened: false})}
				                   showNumberOfStudents=''
				                   trackId={0}
				                   subjectId={this.props.subjectID}
				                   subjectType={this.props.subjectType.toString()}
				/>
			</Suspense>;
		}
	}

  private getTitle() {
    const h = this.props.hierarchy;
    if (h.mode === HierarchyMode.Classic) {
      const s = h.classic.selected;
      switch (s.level) {
        case ClassicHierarchyLevel.District:
          return 'District Growth';
        case ClassicHierarchyLevel.SchoolsGroup:
          return 'School Group Growth';
        case ClassicHierarchyLevel.TeachersGroup:
          return 'Teacher Group Growth';
        default: return 'District Growth';
      }
    }
    if (h.mode === HierarchyMode.Specialist) {
      const s = h.specialist.selected;
      switch (s.level) {
        case SpecialistHierarchyLevel.Type:
        case SpecialistHierarchyLevel.District:
          return 'District Growth';
        case SpecialistHierarchyLevel.GroupOfSpecialists:
          return 'Specialist Group Growth';
        default: return 'District Growth';
      }
    }
  }
}
