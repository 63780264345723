import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EmptyContent = styled(GridBox, {
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	backgroundColor: '$background',
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 20,
	paddingRight: 20,
	height: 'max-content',
	justifyContent: 'center',
	gap: 6,
	textAlign: 'center',
	alignSelf: 'center',
	justifySelf: 'center',
});
