export enum Environments {
	Prod = 'master',
	Test = 'test1',
	Beta = 'beta',
	Local = 'local',
	Preassess = 'preassess',
}

export interface IEnvironment {
	esgiApiURL: string;
	ssoApiURL: string;
	url: string;
	studentAppUrl: string;
	landingUrl: string;
	country: string;
}

/* The EnvironmentManager manages different environment variables. */
class EnvironmentManager implements IEnvironment {
	private environment: IEnvironment;
	public environmentType: Environments;

	get esgiApiURL() {
		return this.environment.esgiApiURL;
	}

	get ssoApiURL() {
		return this.environment.ssoApiURL;
	}

	get url() {
		return this.environment.url;
	}

	get studentAppUrl() {
		return this.environment.studentAppUrl;
	}

	get landingUrl() {
		return this.environment.landingUrl;
	}

	get country() {
		return this.environment.country;
	}

	public init(environment: IEnvironment, type: Environments) {
		this.environment = environment;
		this.environmentType = type;
	}
}

export const environment = new EnvironmentManager();