import {useState} from 'react';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Input, Label} from '@esgillc/ui-kit/form-control';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {QuestionMarkIcon} from '@esgillc/ui-kit/icons';
import {join, useStreamEffect} from '@esgillc/ui-kit/utils';
import {Icon} from 'pages/assignment-center/components/student-credentials/kit';
import {ErrorText} from 'modules/forms/students-form/components/error-text';
import {PasswordManagerDisabler} from 'shared/password-manager-disabler';
import styles from './styles.module.less';

interface Props {
	passwordControl: FormControl;
	userNameControl: FormControl;
	studentFullName?: string;
	warningTooltipMessage?: string;
}

export function Credentials({passwordControl, userNameControl, studentFullName, warningTooltipMessage}: Props) {

	const [isShowPassword, setShowPassword] = useState(false);
	const [isShowEyeIcon, setShowEyeIcon] = useState(Boolean(passwordControl.value));

	useStreamEffect(passwordControl.onChanged, () => {
		setShowEyeIcon(Boolean(passwordControl.value));
	});

	const onChangePasswordVisibility = () => setShowPassword(prev => !prev);

	return <div data-cy='credentials-section'>
		<div data-cy='credentials-section-header' className={styles.credentialsSectionHeader}>
			<Label className={styles.sectionTitle}>Credentials</Label>
			<OnHoverTooltip message="These credentials are used to access tests in the 'Assignments' page.">
				<QuestionMarkIcon className={styles.questionMarkIcon}/>
			</OnHoverTooltip>
			{Boolean(warningTooltipMessage.length) && <OnHoverTooltip message={warningTooltipMessage}>
				<Icon.WarningTriangleIcon className={styles.warningIconContainer}/>
			</OnHoverTooltip>}
		</div>

		<div data-cy='login-info' className={join(styles.row, styles.loginInfo)}>
			<div data-cy='username'>
				<FormElement disableValidateOnBlur disableValidateOnChange control={userNameControl}
							 className={styles.inputWithErrorText}>
					<Label className={styles.topLabel}>Usеr Nаmе</Label>
					<PasswordManagerDisabler name='usrnm' type='text'/>
					<Input id={null} maxLength={20} placeholder={`i.e. ${studentFullName.replace(/\s/g, '.')}`}/>
					<ErrorText showOnError='range-length-lower'>
						Minimum 4 characters
					</ErrorText>
					<ErrorText showOnError='range-length-upper'>
						Maximum 20 characters
					</ErrorText>
				<ErrorText showOnError={'user-name-in-use'}>Username is already being used</ErrorText>
			</FormElement>
			</div>
			<FormElement disableValidateOnBlur disableValidateOnChange control={passwordControl}
						 className={styles.inputWithErrorText}>
				<Label className={styles.topLabel}>Pаsswоrd</Label>
				<div data-cy='password' className={styles.passwordContainer}>
					<PasswordManagerDisabler name='pswd'/>
					<Input id={null} maxLength={12} placeholder='i.e. 1234'
						   className={join(!isShowPassword && styles.passwordInput)}/>
					{isShowEyeIcon && <OnHoverTooltip message={isShowPassword ? 'Hide password' : 'Show password'}>
						<div data-cy='eye-icon-container' className={styles.eyeIcon}>
							{isShowPassword ? <Icon.EyeIcon data-cy='eye-icon' onClick={onChangePasswordVisibility}/>
								: <Icon.EyeCrossedIcon data-cy='eye-crossed-icon' onClick={onChangePasswordVisibility}/>}
						</div>
					</OnHoverTooltip>}
				</div>
				<ErrorText showOnError='range-length-lower'>
					Minimum 4 characters
				</ErrorText>
				<ErrorText showOnError='range-length-upper'>
					Maximum 12 characters
				</ErrorText>
			</FormElement>
		</div>
	</div>;
}
