import {Checkbox} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import React, {useMemo} from 'react';
import {Filter} from './components/filter';
import {GroupSpecialistItem, StudentInfoSpecialist} from '../../../../types/specialist-types';
import {
	Table,
	TableBody,
	TableColumn,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	useSortableColumns,
} from '@esgillc/ui-kit/table';
import styles from './styles.module.less';
import {CreateAssignmentSpecialistService} from '../../../../services/create-assignment-specialist-service';
import {AddSpecificModalContainer} from './components/add-specific-modal-container';
import {EmptyContent} from './components/empty-content';
import {FiltersContainer} from './components/filters-container';

interface Props {
	service: CreateAssignmentSpecialistService;
	onClose: VoidFunction;
	onSelectedStudentsChange: (students: StudentInfoSpecialist[]) => void;
	prevSelectedStudents: StudentInfoSpecialist[];
}

export function AddSpecificModalSpecialist({
	service,
	onClose,
	onSelectedStudentsChange,
	prevSelectedStudents = [],
}: Props) {
	const [selectedStudents, setSelectedStudents] = React.useState<StudentInfoSpecialist[]>(prevSelectedStudents);

	const groups = useBehaviorSubject(service.groups);
	const students = useBehaviorSubject(service.students);
	const previouslySelectedStudents = useBehaviorSubject(service.selectedStudents);

	const [selectedGroup, setSelectedGroup] = React.useState<GroupSpecialistItem[]>([
		{name: 'All', id: 0} as GroupSpecialistItem,
	]);

	const filteredStudents = useMemo(() => {
		const notSelectedStudents = students.filter(
			({id: studentId}) => !previouslySelectedStudents.map(({id}) => id).includes(studentId),
		);

		return notSelectedStudents.filter(({group}) => {
			const selectedGroupName = selectedGroup[0]?.name;

			if (!selectedGroupName) {
				return false;
			}

			if (selectedGroupName === 'All') {
				return true;
			}

			const studentGroupList = group.split(',');

			return studentGroupList.includes(selectedGroupName);
		});
	}, [selectedGroup, students, previouslySelectedStudents]);

	const allSelected = selectedStudents.length === students.length;

	const toggleAllSelected = () => {
		allSelected ? setSelectedStudents([]) : setSelectedStudents(students);
	};

	const toggleStudentSelected = (student: StudentInfoSpecialist) => {
		if (selectedStudents.includes(student)) {
			setSelectedStudents(selectedStudents.filter((s) => s !== student));
		} else {
			setSelectedStudents([...selectedStudents, student]);
		}
	};

	const {data, sortableColumns} = useSortableColumns({
		data: filteredStudents,
		columns: {
			name: true,
			group: true,
		},
		activeSortedKey: 'name',
	});

	if (!sortableColumns.name || !sortableColumns.group) {
		return null;
	}

	return (
		<AddSpecificModalContainer
			subTitle='Select students in your classes and groups who have not been previously selected.'
			onClose={onClose}
			onSave={() => onSelectedStudentsChange(selectedStudents)}
		>
			<>
				<FiltersContainer>
					<Filter
						items={groups}
						dropdownLabel='Group'
						onItemChange={setSelectedGroup}
						optionName='name'
						selectedItems={selectedGroup}
					/>
				</FiltersContainer>
				<Table className={styles.table}>
					<TableHeader className={styles.tableHeader} autoHeight>
						<TableHeaderColumn>
							<Checkbox checked={allSelected} onClick={toggleAllSelected} />
						</TableHeaderColumn>
						<TableHeaderColumn.Sortable {...sortableColumns.name} className={styles.columnHeader}>
							Students
						</TableHeaderColumn.Sortable>
						<TableHeaderColumn.Sortable {...sortableColumns.group} className={styles.columnHeader}>
							Group
						</TableHeaderColumn.Sortable>
					</TableHeader>
					<TableBody className={styles.tableBody}>
						{data.map((item) => (
							<TableRow key={item.id} className={styles.row}>
								<TableColumn className={styles.columnCheckbox}>
									<Checkbox checked={selectedStudents.includes(item)} onClick={() => toggleStudentSelected(item)} />
								</TableColumn>
								<TableColumn className={styles.column}>{item.name}</TableColumn>
								<TableColumn className={styles.column}>{item.group}</TableColumn>
							</TableRow>
						))}
						{!data.length && <EmptyContent />}
					</TableBody>
				</Table>
			</>
		</AddSpecificModalContainer>
	);
}
