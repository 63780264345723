import moment from 'moment';
import styles from '../../styles.module.less';

interface Props {
	reportData: Record<string, any>;
}

export const Title = (props: Props): JSX.Element => {
	const {
		specialist,
		groupName,
		subject,
		student,
	} = props.reportData;

	return (
		<>
			{moment().format('L')}
			<div className={styles.breadcrumbs}>
				<div>
					<span>Specialist:</span>
					{`${specialist.title} ${specialist.lastName}`}
				</div>
				<div>
					<span>Group:</span>
					{groupName}
				</div>
				<div>
					<span>Subject:</span>
					{subject.name}
				</div>
			</div>
			<div className={styles.student}>{`${student.firstName} ${student.lastName}`}</div>
		</>
	);
};
