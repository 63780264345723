import {ZenboxCompact} from './zenbox-compact';
import {OldAlerts} from '@esgi/deprecated/knockout';

/**
 * @deprecated Use ErrorReporterLayer instead.
 * @see {@link ErrorReporterLayer}
 */
export class ExceptionReporter {
	report(message: string, token?: string, statusCode?: number) {
		let deferred = $.Deferred();
		const className = statusCode && statusCode === 500 ? 'exception-reporter-modal' : '';
		OldAlerts.bsalert({
			className: className,
			message: message,
			modal:
                {
                	allowClose: false,
                	buttons: [
                		{
                			align: 'right',
                			title: 'CANCEL',
                			className: 'btn btn-sm btn-link',
                			closeModal: true,
                			onClick: () => {
                				deferred.resolve();
                			},
                		},
                		{
                			align: 'right',
                			title: 'CONTACT US',
                			className: 'btn btn-sm btn-link',
                			closeModal: true,
                			onClick: () => {
                				const zenbox = new ZenboxCompact(token);
                				zenbox.load().always(() => {
                					deferred.resolve();
                				});
                			},
                		},
                	],
                },
		});
		return deferred;
	}
}
