import {Dispatch, PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {Container} from '../styled';
import {TabsContextValue, TabsContext} from '../context';
import {TabInfo} from '../types';
import {useAutoControlledState} from '@esgi/ui';

type Props<Tab extends string> = PropsWithChildren<{
	initialTab: Tab;
	activeTab?: Tab;
	onTabChanged?: Dispatch<Tab>;
}>;

export function Root<Tab extends string>({activeTab, initialTab, children, onTabChanged}: Props<Tab>) {
	const [tab, setTab] = useAutoControlledState({
		initialState: initialTab,
		controlledState: activeTab,
		onChange: onTabChanged,
	});
	const [tabInfos, setTabInfos] = useState<TabInfo<Tab>[]>([]);

	const updateTabInfo = useCallback(({id, data}: {id: Tab; data: Partial<Omit<TabInfo<Tab>, 'id'>>}) => {
		setTabInfos((currentTabs) =>
			currentTabs.map((currentTab) => (currentTab.id === id ? {...currentTab, ...data} : currentTab)),
		);
	}, []);

	const context = useMemo<TabsContextValue<Tab>>(
		() => ({
			active: tab,
			setActive: setTab,
			add: (tabInfo) => setTabInfos((values) => [...values, tabInfo]),
			remove: (tab) => setTabInfos((values) => values.filter((t) => t.id !== tab)),
			updateTabInfo,
			hasTabInList: (tabId: Tab) => tabInfos.some(({id}) => id === tabId),
			availableTabs: tabInfos,
		}),
		[setTab, tab, tabInfos, updateTabInfo],
	);

	return (
		<Container>
			<TabsContext.Provider value={context}>{children}</TabsContext.Provider>
		</Container>
	);
}
