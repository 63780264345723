import {getThemeColor} from '@esgi/ui';
import {ComposedChart, XAxis} from 'recharts';

type Props = {
	sessionsCount: number;
	axisWidth: number;
};

export function SessionsCounterAxis({sessionsCount, axisWidth}: Props) {
	return (
		<ComposedChart
			data={new Array(sessionsCount + 1).fill({})}
			width={axisWidth}
			height={20}
			margin={{top: 0, right: 0, bottom: 0, left: 0}}
		>
			<XAxis
				tickCount={sessionsCount + 1}
				domain={[0, sessionsCount]}
				interval='preserveStartEnd'
				tickSize={0}
				tickMargin={6}
				tick={{
					fontSize: 10,
					fill: getThemeColor('blue'),
				}}
				tickLine={false}
				axisLine={false}
				height={20}
				scale='point'
			/>
		</ComposedChart>
	);
}
