import {DatePicker, IFormControlValidatorResult} from '@esgi/deprecated/elements';
import moment, {Moment} from 'moment';
import styles from './styles.module.less';
import TrackConfirmService from 'modules/track-confirm/services/track-confirm-service';
import {setIndex} from 'modules/track-confirm/utils';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

interface TrackDatesProps {
	trackConfirmService: TrackConfirmService;
}

export default function TrackDatesList (props: TrackDatesProps) {
	const trackDates = useBehaviorSubject(props.trackConfirmService.trackDates)
	const validate = (value: Moment, index: number): IFormControlValidatorResult => {
		const message = props.trackConfirmService.validate(value, index);
		return {
			valid: message === '',
			message,
		};
	};

	const changeFromDate = (date: Moment, index) => {
		const changedTrackDates = trackDates.map((item, i) => {
			if (i === index) {
				return {dateFrom: date.format(), dateTo: item.to.toString()}
			}
			return {dateFrom: item.from.toString(), dateTo: item.to.toString()}
		})
		props.trackConfirmService.updateTrackDates(changedTrackDates, index, true);
	}

	const changeToDate = (date: Moment, index) => {
		const changedTrackDates = trackDates.map((item, i) => {
			if (i === index) {
				return {dateFrom: item.from.toString(), dateTo: date.format()}
			}
			return {dateFrom: item.from.toString(), dateTo: item.to.toString()}
		})
		props.trackConfirmService.updateTrackDates(changedTrackDates, index, false);
	}

	return (
		<div className={styles.trackDatesInnerContainer}>
			<div className={styles.trackDatesInfoRow}>
				<div className={styles.empty}></div>
				<div className={styles.fromInfoLabel}>From</div>
				<div className={styles.toInfoLabel}>To</div>
			</div>
			{trackDates?.map((trackDate, index) => <div className={styles.trackDateRow} key={'track-rate-row_' + (index + 1)}>
				<div className={styles.trackDateNumber}>{index + 1}</div>
				<div className={`input-group ${styles.inputIcon} input-icon-right`}>
					<DatePicker onSelect={(date) => changeFromDate(date, index)} id={'track-datepicker_from_' + (index + 1)}
					            date={moment(trackDate.from)}
					            validator={(value: Moment) => validate(value, setIndex(index, true))}
					            validationPlacement={'left'}/>
				</div>
				<div className={`input-group ${styles.inputIcon} input-icon-right`}>
					<DatePicker onSelect={(date) => changeToDate(date, index)} id={'track-datepicker_to_' + (index + 1)}
					            date={moment(trackDate.to)}
					            validator={(value: Moment) => validate(value, setIndex(index, false))}
					            validationPlacement={'right'}
					/>
				</div>
			</div>)}
		</div>
	)
}
