import {useEffect} from 'react';
import {SettingsPanel, ChangeFunction} from '@esgi/main/kits/reports';
import {Text} from '@esgi/ui/typography';
import {Wrapper, Description, Checkbox} from './index.styled';

interface Props {
	disabled?: boolean;
	value?: boolean;
	onChange: ChangeFunction<boolean>;
}

export function SettingsControl(props: Props) {
	const {disabled, value, onChange} = props;

	useEffect(() => {
		if (typeof value === 'undefined') {
			onChange(false);
		}
	}, []);

	return (
		<SettingsPanel data-cy='settings-root' title='Settings'>
			<Wrapper data-cy='settings-wrapper'>
				<Checkbox
					disabled={disabled}
					label='Show Each Student'
					checked={Boolean(value)}
					onCheckedChange={onChange}
					dataCy='show-each-checkbox'
				/>
			</Wrapper>
			<Description disabled={disabled}>
				<Text data-cy='setting-description' size='medium' color='currentColor'>Selecting this checkbox allows you to view individual pie charts for all students in the class.</Text>
			</Description>
		</SettingsPanel>
	);
}
