import {HttpClient} from '@esgi/api';
import {
	ICustomGradeRangeSet,
	ICustomGradeRangeSetsResponse,
	IInitResponse,
} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/models';
import {SubjectType} from '@esgi/core/enums';
import {SubjectLevel} from 'shared/modules/grade-scale/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class Api {
	private static controllerName = 'reports/grade-scales/grade-range-custom';

	static init(hierarchy: HierarchySnapshot) {
		return HttpClient.default.ESGIApi.get<IInitResponse>(this.controllerName, 'init', {fullHierarchy: hierarchy});
	}

	static getGradeRangeSets(subjectID: number, subjectType: SubjectType, subjectLevel: SubjectLevel, trackPeriodsCount: number, hierarchy: HierarchySnapshot) {
		return HttpClient.default.ESGIApi.get<ICustomGradeRangeSetsResponse>(this.controllerName, 'get-grade-range-sets',
			{subjectID: subjectID, subjectType: subjectType, subjectLevel: subjectLevel, trackPeriodsCount: trackPeriodsCount, fullHierarchy: hierarchy});
	}

	static save(gradeRangeSets: ICustomGradeRangeSet[]) {
		return HttpClient.default.ESGIApi.post(this.controllerName, 'save', {gradeRangeSets: gradeRangeSets});
	}
}
