import {FormDataBuilder} from '@esgi/api';
import {BaseService} from '@esgi/core/service';
import {map} from 'rxjs/operators';
import {AnswerState, SessionType, SubjectType} from '@esgi/core/enums';
import {TestSessionStatus} from '../../kit/enums';
import {TestingModel} from './models';
import {mapToEnum} from '../../kit/utils';
import {V2AssessmentsYNController} from '@esgi/contracts/esgi';

export class YNService extends BaseService {
	protected readonly YNcontroller: string = 'assessment/test-screen/yn';
	private testID: number;
	private studentID: number;
	private readonly controller = new V2AssessmentsYNController();

	public init(testId: number, studentId: number, subjectId: number, subjectType: SubjectType, subsetQuestionIDs?: number[]) {
		this.testID = testId;
		this.studentID = studentId;
		return this.httpClient.ESGIApi.post<TestingModel>(
			this.YNcontroller,
			'run-test',
			{testId, studentId, subjectId, subjectType, subsetQuestionIDs},
		).pipe(map((model) => {
			const out = {...model};
			out.testSessionStatus = mapToEnum(out.testSessionStatus, TestSessionStatus);
			out.progress.sessionType = mapToEnum(out.progress.sessionType, SessionType);
			out.availableSessionTypes = out.availableSessionTypes.map(t => mapToEnum(t, SessionType));
			out.questions = out.questions.map((q) => ({
				...q,
				oldAnswerState: mapToEnum(q.oldAnswerState, AnswerState),
				cacheAnswerState: mapToEnum(q.cacheAnswerState, AnswerState),
			}));
			if (out.resumeModel) {
				out.resumeModel.lastSessionType = mapToEnum(out.resumeModel.lastSessionType, SessionType);
			}
			return out as TestingModel;
		}));
	}

	public updateGuid() {
		const request = FormDataBuilder.BuildParameters({
			testId: this.testID,
			studentId: this.studentID,
		});
		return this.httpClient.ESGIApi.post<{testSessionGuid: string, testSessionStatus: TestSessionStatus}>(
			this.YNcontroller,
			'update-test-session-guid',
			request,
		).pipe(map(({testSessionGuid, testSessionStatus}) => ({
			testSessionGuid,
			testSessionStatus: mapToEnum(testSessionStatus, TestSessionStatus),
		}))).asObservable();
	}

	public updateSummaryScore(testSessionID: number, summary: string) {
		this.controller.updateSummaryNote({testSessionID, note: summary}).subscribe();
	}

	public updateShuffleQuestions(value: boolean) {
		this.controller.updateShuffleQuestions({
			studentID: this.studentID,
			testID: this.testID,
			value,
		}).subscribe();
	}

	public override dispose() {
		this.controller.dispose();
	}
}
