import {SelectableList, SelectableListItem} from './index.styled';
import {SelectableLisItemContent} from '../list-item';
import {ExpandablePanel} from '@esgi/main/kits/reports';
import {Test} from '../../../../types';
import {isNull} from 'underscore';

const getListValue = (value: string | number | null) => isNull(value)
	? ''
	: String(value);

interface Props {
	items: Test[];
	selected: Test['id'] | null;
	setSelected: (itemId: Test['id']) => void;
}

export function SelectableListMultiple({items, selected, setSelected}: Props) {
	const value = getListValue(selected);

	return (
		<ExpandablePanel style={{height: 'calc(100% - 50px)'}}>
			<SelectableList>
				<SelectableList.GroupRoot
					type='single'
					value={value}
				>
					<SelectableList.Group>
						{items.map(({id, ...item}) =>
							<SelectableListItem
								key={id}
								value={String(id)}
								checked={value === String(id)}
								setTextCurrentColor={false}
								onClick={() => setSelected(id)}
							>
								<SelectableLisItemContent item={{id, ...item}}/>
							</SelectableListItem>
						)}
					</SelectableList.Group>
				</SelectableList.GroupRoot>
			</SelectableList>
		</ExpandablePanel>
	);
}
