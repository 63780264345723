import {Hidden, Starred, Unhidden, Unstarred} from 'api/entities/events/test';
import {EventBusManager} from '@esgillc/events';
import {HttpClient} from '@esgi/api';
import {tap} from 'rxjs/operators';

export class Test {
	private static controller = '/assets/tests';
	private static readonly eventBus = new EventBusManager();
	
	static hide(testId: number) {
		return HttpClient.default.ESGIApi.post(Test.controller, 'hide', {testId: testId})
			.pipe(tap(() => this.eventBus.dispatch(Hidden, Hidden(testId))));
	}

	static unhide(testId: number) {
		return HttpClient.default.ESGIApi.post(Test.controller, 'unhide', {testId: testId})
			.pipe(tap(() => this.eventBus.dispatch(Unhidden, Unhidden(testId))));
	}

	static star(testId: number) {
		return HttpClient.default.ESGIApi.post(Test.controller, 'star', {testId: testId})
			.pipe(tap(() => this.eventBus.dispatch(Starred, Starred(testId))));
	}

	static unstar(testId: number) {
		return HttpClient.default.ESGIApi.post(Test.controller, 'unstar', {testId: testId})
			.pipe(tap(() => this.eventBus.dispatch(Unstarred, Unstarred(testId))));
	}
}
