export enum BoxType {
	// HomePage
	StudentActivities = 'StudentActivities',
	StudentReports = 'StudentReports',
	ClassReports = 'ClassReports',
	SchoolReports = 'SchoolReports',
	SpecialistReports = 'SpecialistReports',
	DistrictReports = 'DistrictReports',
  SchoolsGroupReports = 'SchoolsGroupReports',
  TeachersGroupReports = 'TeachersGroupReports',
  GroupOfSpecialistsReports = 'GroupOfSpecialistsReports',

	// Tests Explorer
	RelatedTools = 'RelatedTools',
}
