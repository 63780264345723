import React, {Suspense, useState} from 'react';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {lazyComponent} from '@esgi/core/react';

const TestCreatorLauncher = lazyComponent(() => import('modules/assets/tests/creating-launcher/root'));

export class Props {
	doneClicked: () => any;
}

export function Footer(props: Props) {
	const [testDetailsOpened, setTestDetailsOpened] = useState(false);

	const onClose = () => {
		setTestDetailsOpened(false);
	};

	return <div className='add-test-footer'>
		<div>
			<a href='#' className='btn btn-transparent btn-primary btn-add' onClick={() =>setTestDetailsOpened(true)}>
				<svg className='icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20'
				     viewBox='0 0 20 20' fill='none'>
					<path
						d='M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
						fill='#0088CC'/>
				</svg>
				Create new Test
			</a>
			{testDetailsOpened &&
				<Suspense fallback={<></>}>
					<TestCreatorLauncher
						disableAttachToSubject
						onClose={onClose}
						onTestCreated={onClose}
					/>
				</Suspense>
			}
		</div>
		<div>
			<Primary
				onClick={() => props.doneClicked()}
				title='Done'
				className='done-button'
			/>
		</div>
	</div>;
}
