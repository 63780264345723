import {TextArea} from '@esgillc/ui-kit/control';
import {ModalManagerRefObject} from '@esgillc/ui-kit/modal';
import React, {createRef} from 'react';
import {fromEvent, interval, Subject} from 'rxjs';
import {takeUntil, throttle} from 'rxjs/operators';
import {NextRowButton} from '@esgi/deprecated/ui-kit/matrix';
import {calcScore, calcTotalScore} from '../../../../utils';
import TestingService from '../../../../testing-service';
import {RowHeight} from '../../utils';
import ConfirmCancelDialog from './components/confirm-cancel-dialog';
import styles from '../../test.screen.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	testingService: TestingService;
	onShowValidation: () => void;
	submitted: () => void;
	canceled: () => void;
	maxWidth: () => number;
}

class State {
	width: number;
	summaryNotes: string;
	showValidation: boolean = false;
	showConfirmCancel: boolean = false;
}

export default class BottomPanel extends React.PureComponent<Props, State> {
	private validationModalManagerRef: ModalManagerRefObject = createRef();
	private buttonContainerRef: HTMLDivElement;
	private onDestroy$: Subject<void> = new Subject();
	public state = new State();

	public componentDidMount() {
		this.props.testingService.summaryNotes
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(summaryNotes => this.setState({summaryNotes}));
		fromEvent(window, 'resize')
			.pipe(
				takeUntil(this.onDestroy$),
				throttle(() => interval(30)),
			).subscribe(() => this.recalculateWidth());

		this.recalculateWidth();
	}

	private recalculateWidth() {
		const width = this.props.maxWidth();
		if (width && this.state.width !== width) {
			this.setState({width});
		}
	}

	private summaryNotesOnChange(event) {
		const value = event.target.value.replace(/[\r\n\v]+/g, '');
		this.props.testingService.summaryNotes.next(value);
	}

	public render() {
		const service = this.props.testingService;
		return <div className={styles.bottomPanel} style={{maxWidth: this.state.width || 'auto'}}>
			<div className={styles.actionColumn}>
				<div>
					<NextRowButton className={styles.nextRowButton} rowHeight={RowHeight}/>
				</div>
				<div>
					<Buttons.Gray onClick={this.cancel}>CANCEL</Buttons.Gray>
				</div>
			</div>
			<div className={styles.notes}>
				<TextArea placeholder='Summary Notes'
				              className={styles.textArea}
				              maxLength={255}
				              value={this.state.summaryNotes}
				              onChange={(e) => this.summaryNotesOnChange(e)}/>
				<div className={styles.counter}>
					{this.state.summaryNotes?.length}/255
				</div>
			</div>
			<div className={styles.totalColumn}>
				<div className={styles.totalScore}>
					<div>
						<span>
							{calcScore(service.answers.value)}/{calcTotalScore(service.testModel)}
						</span>
					</div>
				</div>
				<div ref={(r) => this.buttonContainerRef = r}>
					<Buttons.Contained onClick={() => this.endTest()}>SUBMIT</Buttons.Contained>
				</div>
			</div>
			{this.renderValidationDialog()}
			{this.renderConfirmCancelDialog()}
		</div>;
	}

	private renderValidationDialog() {
		if (this.state.showValidation) {
			return <Alert modalManagerRef={this.validationModalManagerRef}>
				<Alert.Header/>
				<Alert.Body>Scores have not been set for all Criteria. Please select the missing scores before submitting
					the test session.</Alert.Body>
				<Alert.Footer>
					<Buttons.Text
						onClick={() => this.validationModalManagerRef.current.close(() => this.setState({showValidation: false}, this.props.onShowValidation))}>
						CONTINUE
					</Buttons.Text>
				</Alert.Footer>
			</Alert>;
		}
	}

	private renderConfirmCancelDialog() {
		if (this.state.showConfirmCancel) {
			return <ConfirmCancelDialog onClose={() => this.setState({showConfirmCancel: false})}
			                            onConfirm={() => this.setState({showConfirmCancel: false}, this.props.canceled)}/>;
		}
	}

	private cancel = () => {
		if (this.props.testingService.answers.value.some(a => a.score === undefined)) {
			this.setState({showConfirmCancel: true});
		} else {
			this.props.canceled();
		}
	};

	private endTest() {
		if (this.props.testingService.answers.value.some(a => a.score === undefined)) {
			this.setState({showValidation: true});
		} else {
			this.props.submitted();
		}
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
