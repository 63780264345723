import React from 'react';
import {SkeletonRow} from './index.styled';

const SkeletonRowArray = new Array(30).fill('');

export function Skeleton() {
	return (
		<>
			{SkeletonRowArray.map((_, index) => (
				<SkeletonRow key={index} />
			))}
		</>
	);
}
