import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';

export const Wrapper = styled(GridBox, {
	rowGap: '$3',
	overflow: 'hidden',
	gridTemplateRows: 'auto 1fr',
});

export const SelectButton = styled(Button, {
	width: 'fit-content',
	minWidth: 'fit-content',
	padding: 8,
});
