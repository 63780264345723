import {Text} from '@esgi/ui/typography';
import {IndicatorBox} from './index.styled';
import {ComponentProps} from '@stitches/react';
import {ThemeColorKeys} from '@esgi/ui';

type Props = ComponentProps<typeof Text> & {
	cheapBackground: ThemeColorKeys;
};

export function DeltaCheap({cheapBackground, ...textProps}: Props) {
	return (
		<IndicatorBox css={{backgroundColor: `$${cheapBackground}`}}>
			<Text {...textProps} />
		</IndicatorBox>
	);
}
