import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './styles.module.less';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {historyOptions, pieChartOptions} from '../../../../../../../utils';
import {ReportService} from '../../../../../../../services/report-service';
import {Test} from '../../../../../../../models/models';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import RenderIfVisible from 'react-render-if-visible';

interface Props {
	test: Test;
	service: ReportService;
}

export function DetailReportCharts({test, service}: Props) {
	const showBaseline = useBehaviorSubject(service.settingsService.showBaseline);
	const printInColor = useBehaviorSubject(service.settingsService.printInColor);
	const report = useBehaviorSubject(service.dataService.report);
	const teacher = useBehaviorSubject(service.settingsService.teacher);
	const [itemHeight, setItemHeight] = useState(0);
	const itemRef = useRef<HTMLDivElement>(null);

	const pieChartOptionsMemo = useMemo(() => pieChartOptions(test, printInColor, teacher), [test, printInColor, teacher]);
	const historyOptionsMemo = useMemo(() => historyOptions(test, showBaseline, report, printInColor, teacher), [test, printInColor, teacher, showBaseline, report]);
	const containerStyle = useMemo(() => ({
		width: '100%',
		height: '100%',
	}), []);

	useEffect(() => {
		setItemHeight(itemRef.current?.clientHeight);
		const visibleEl = (itemRef.current.children[0] as HTMLElement);
		visibleEl.style.width = itemRef.current.clientWidth + 'px';
		visibleEl.style.display = 'flex';

	}, [itemRef.current]);

	return <div className={styles.flexRow} ref={itemRef}>
		<RenderIfVisible defaultHeight={itemHeight}>
			<div className={styles.pieChart}>
				<HighchartsReact options={pieChartOptionsMemo}
				                 containerStyle={containerStyle}/>
			</div>
			<div className={styles.graphContainer}>
				<HighchartsReact
					options={historyOptionsMemo}
					containerStyle={containerStyle}/>
			</div>
		</RenderIfVisible>
	</div>;
}
