import {Class} from '@esgi/main/libs/store';

export enum TeacherDrawerName {
	TeacherClass = 'teacherClass',
	TeacherGroup = 'teacherGroup',
	TeacherStudentViaFile = 'teacherStudentViaFile',
	TeacherStudentViaProfile = 'teacherStudentViaProfile',
	TeacherSettingsPanel = 'teacherSettingsPanel',
	StudentScreen = 'studentScreen',
	TeacherUserAccount = 'teacherUserAccount',
	AddTest = 'addTest',
}

export type AddStudentViaFileParams = {
	classId: string;
};

export type AddStudentViaProfile = {
	classId: string;
	studentId: string;
};

export type EditClassFormParams = {
	classId: string;
	schoolYear: string;
};

export type AddGroupFormParams = {
	classId: Class['id'] | null;
};

export type EditGroupFormParams = {
	classId: string;
	groupId: string;
	schoolYear: string;
};

export type EditStudentViaProfileParams = {
	studentId: string;
};
