import {Users, Skeletonable} from '@esgi/ui';
import {SkeletonShape, OneLinedText} from '@esgi/main/kits/common';
import {IconWrapper, GridBox} from './index.styled';

interface Props extends Skeletonable {
	title: string;
}

export function UnitNamePanel({title, skeleton}: Props) {
	return (
		<GridBox gap='3' align='center' flow='column'>
			{skeleton ? (
				<>
					<SkeletonShape width={40} height={40}/>
					<SkeletonShape width={100} height={12}/>
				</> ) : (
				<>
					<IconWrapper data-cy='user-icon'>
						<Users />
					</IconWrapper>
					<OneLinedText dataCy='user-name' color='neutral56' size='small'>{title}</OneLinedText>
				</>
			)}
		</GridBox>
	);
}
