import React from 'react';
import {SuggestedGradeLevel} from 'api/entities/suggested-grade-level';
import {EventBusManager} from '@esgillc/events';
import {FilterType} from '../../../enums';
import {TestExplorerBackEvent} from '../../../events';
import {RemoveFilterEvent} from '../../header/search-tags/search-tags';
import {AuthorClicked} from '../../noted-author/events';
import {BoxItem} from '../box-item/box-item';
import {Box} from '../box/box';
import {Loading} from '../enums';
import {ClearAllEvent} from '../events';

class State {
	gradeLevelId?: number = null;
	loading: Loading = Loading.NotLoaded;
}

class Props {
	hasChanged: (gradeLevelId: number | null) => void;
}

export class GradeLevel extends React.Component<Props, State> {
	private readonly eventBus = new EventBusManager();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	componentWillUnmount() {
		this.eventBus.destroy();
	}

	render() {
		return <Box title='Grade Level'
		            collapsible={true}
		            initiallyOpened={true}
		            canBeCleared={!!this.state.gradeLevelId}
		            cleared={() => this.changed(null)}
		            filterType={FilterType.GradeLevel}
		            loading={this.state.loading === Loading.Loading}>
			{SuggestedGradeLevel.list.map(g => {
				return <BoxItem
					key={g.id}
					selected={this.state.gradeLevelId === g.id}
					title={g.name}
					onSelect={() => this.changed(g.id)}
				/>;
			})}
		</Box>;
	}

	changed(gradeLevelId: number | null) {
		this.setState({gradeLevelId: gradeLevelId}, () => {
			this.props.hasChanged(gradeLevelId);
		});
	}


	componentDidMount(): void {

		this.eventBus.subscribe(AuthorClicked, () => {
			this.changed(null);
		});

		this.eventBus.subscribe(ClearAllEvent, () => {
			this.changed(null);
		});

		this.eventBus.subscribe(RemoveFilterEvent, (args) => {
			if (args.type === FilterType.GradeLevel) {
				this.changed(null);
			}
		});

		this.eventBus.subscribe(TestExplorerBackEvent, () => {
			this.setState({gradeLevelId: null});
		});
	}
}
