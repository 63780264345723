import {HierarchySnapshot} from 'modules/hierarchy/models';
import {SubjectInfo, TestsResponse} from 'modules/reports/test-selector/models';
import {TestsSourceService} from 'modules/reports/test-selector/types';
import {Observable} from 'rxjs';
import {BaseService} from '@esgi/core/service';

export class TestsSelectorSourceService extends BaseService implements TestsSourceService {
	constructor(private hierarchy: HierarchySnapshot) {
		super();
	}

	public fetchTests(subjects: SubjectInfo[]): Observable<TestsResponse> {
		return this.httpClient.ESGIApi.post<TestsResponse>('reports/student-report-data', 'available-tests', {
			subjects,
			hierarchy: this.hierarchy,
		}).asObservable();
	}
}
