import {DatePicker} from '@esgi/ui/controls';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const DatePickerWrapper = styled(Box, {
	width: '100%',
	position: 'relative',

	'& input': {
		minWidth: 0,
	},

	variants: {
		disabled: {
			true: {
				pointerEvents: 'none',

				'& input[data-state=valued]:not([data-focused=true])': {
					color: '$lowContrast',
					borderColor: '$mild',

					'& ~ div': {
						visibility: 'hidden',
					},
				},
			},
		},

		withError: {
			true: {
				'& input[data-state=valued]:not([data-focused=true])': {
					color: '$negative',
					borderColor: '$negativeHighlight',
				},

				'& > span': {
					display: 'inline-block',
					paddingLeft: 12,
					paddingRight: 12,
					marginTop: 6,
				},
			},
		},
	},
});

export const DatePickerCalendarPanel = styled(DatePicker.CalendarPanel, {
	'& > div:nth-child(3)': {
		minHeight: 215,
	},
});
