import {EmptyList} from './components/empty-list';
import {SkeletonList} from './index.styled';
import {GroupingType, TestListDataItem, StudentListDataItem, TestItem} from '../../types';
import {Skeletonable} from '@esgi/ui';
import {TestList} from './components/test-list';
import {ExpandablePanel} from '../expandable-panel';
import {StudentList} from './components/students-list';
import {StudentModel} from '@esgi/main/features/assessments';

interface Props extends Skeletonable {
	tests: TestListDataItem[] | null;
	students: StudentListDataItem[] | null;
	showAllStudents: boolean;
	mode: GroupingType;
	onStartTest: (test: TestItem, student: StudentModel) => void;
}

export function TestsControl({skeleton, tests, mode, students, showAllStudents, onStartTest}: Props) {

	if (skeleton) {
		return <SkeletonList/>;
	}

	if (!tests?.length || !students?.length) {
		return <EmptyList content="There's no test data for the student"/>;
	}

	return <ExpandablePanel>
			{mode === GroupingType.Tests && tests.map((test) => <TestList onStartTest={onStartTest} showAllStudents={showAllStudents} key={`test_list_${test.id}`} test={test}/>)}
			{mode === GroupingType.Students && students.map((student) => <StudentList onStartTest={onStartTest} showAllStudents={showAllStudents} key={`student_list_${student.id}`} student={student}/>)}
		</ExpandablePanel>;
}
