import {BehaviorSubject, from, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {getSelectedSchoolYearID, getUser, UserType} from '@esgi/core/authentication';
import {ElementStatus, Validator} from '@esgillc/ui-kit/form';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {BaseTabService} from '../base-tab-service';
import {
	createGeneralTabForm,
	studentIDNAvailableCustomValidator,
	studentUserNameAvailableCustomValidator,
} from '../../../../forms/general';
import {
	StudentChangedEvent,
	StudentCreatedEvent,
	StudentGeneralInfoModel,
	StudentSaveAndAddEvent,
} from '../../../../../events';
import {
	CredentialModel,
	PreSelected,
	ProfileInitData,
	SaveStudentResponse,
	Schools,
	StudentProfileMode,
	StudentProfileTab,
} from '../../../../../types';
import {getGeneralFormValues} from './utils';
import {useTabsApi} from '../../../../profile-modal/use-tabs-api';
import {createTeacherForm} from 'modules/forms/students-form/components/forms/location/teacher';

interface InitArgs {
	mode: StudentProfileMode,
	initData: ProfileInitData,
	studentID: number,
	preSelected: PreSelected,
	tabsController: ReturnType<typeof useTabsApi>
}

export class GeneralService extends BaseTabService {
	public form = createGeneralTabForm();
	public preSelected = new BehaviorSubject<PreSelected>(null);
	public usingIDStudent = new BehaviorSubject<{ studentID: number, studentName: string }>(null);
	public schools = new BehaviorSubject<Schools[]>([]);
	public duplicateError = new BehaviorSubject<null | string>(null);
	public afterSuccessAction = new BehaviorSubject<() => void>(() => null);
	public teacherID: number;
	public schoolID: number;
	public defaultInitData: ProfileInitData;
	public currentUser = getUser();
	public studentIDSubject = new BehaviorSubject<number | null>(null);
	public initialUsername = new BehaviorSubject<string | null>(null);
	public initialStudentIDN = new BehaviorSubject<string | null>(null);
	public canLoginInfo = new BehaviorSubject<boolean>(false);
	public canExportID = new BehaviorSubject<boolean>(this.currentUser.userType === UserType.D);
	public isRelatedToOtherUsers = new BehaviorSubject<string[]>([]);
	public shouldSelectSchool = new BehaviorSubject([
		UserType.ISD,
		UserType.PA,
		UserType.D,
	].includes(this.currentUser.userType));
	public showSelectSchool = new BehaviorSubject<boolean>([UserType.ISD, UserType.PA, UserType.D].includes(this.currentUser.userType));
	public disabledSchoolSelect = new BehaviorSubject<boolean>(false);
	public canEditStudentLanguage = new BehaviorSubject(
		this.currentUser.canEditStudentLanguage,
	);
	protected tab = StudentProfileTab.General;
	private studentCredentialsController = 'assignments/student-credentials';
	private mode: StudentProfileMode;
	private tabsController: ReturnType<typeof useTabsApi>;
	private formInitialValues = {
		firstName: '',
		lastName: '',
		language: [],
		studentIDN: '',
		gradeLevel: [],
		gender: [],
		exportIDN: '',
		school: [],
		userName: '',
		password: '',
	};

	constructor() {
		super();
		this.handleStudentUserNameValidation();
		this.handleStudentIDnValidation();
	}

	public init({mode, initData, studentID, preSelected, tabsController}: InitArgs) {
		this.mode = mode;
		this.disabledSchoolSelect.next(this.currentUser.userType === UserType.PA && mode !== StudentProfileMode.add);
		this.teacherID = preSelected?.userID || initData?.location?.teacherID;
		this.schoolID = preSelected?.schoolID || initData?.location?.schoolID;
		this.initData.next(initData);
		this.defaultInitData = initData;
		this.studentID = studentID;
		this.preSelected.next(preSelected);
		this.tabsController = tabsController;
		this.studentIDSubject.next(studentID);
		this.initialUsername.next(initData.general?.credentialModel?.userName);
		this.initialStudentIDN.next(initData.general?.studentIDN);

		const {userID, agreementLevelCode, canEditStudents, userType} = this.currentUser;
		const isStudentAssigned = initData.location?.specialistID === userID
			|| initData.location?.teacherID === userID || preSelected?.userID === userID;

		if (isStudentAssigned || canEditStudents || agreementLevelCode === 'T') {
			let canViewCredentials = ['T', 'ISS', 'ISD', 'PA'].includes(agreementLevelCode);

			if (![UserType.D, UserType.C].includes(userType) && (isStudentAssigned)) {
				canViewCredentials = true;
			}
			const canViewOnAdd = [UserType.T, UserType.ISS, UserType.ISD, UserType.PA].includes(userType) && mode === StudentProfileMode.add &&
				(preSelected.userID === userID);
			this.canLoginInfo.next(canViewCredentials || canViewOnAdd);
		}
		this.fillForm(initData, mode);
		this.handleExportIDStatus();
		this.handleEditStudentLanguageStatus(mode);
		this.initTabsApi(tabsController.tabsApi);
		this.tabsApi.current[StudentProfileTab.Location].haveClass = Boolean(preSelected?.classID || initData.location?.classIDs?.length);
		if (this.showSelectSchool.value) {
			this.getSchools().subscribe();
		}

		this.form.controls.school.status = (!this.showSelectSchool.value || this.disabledSchoolSelect.value) ? ElementStatus.disabled : ElementStatus.untouched;
		this.checkStudentToOtherMembers(studentID).subscribe(v => {
			this.isRelatedToOtherUsers.next(v.links);
		});
	}

	public save = (isSaveAndAddOther?: boolean, saveAnyway?: boolean, action?: () => void) => {
		this.afterSuccessAction.next(() => action);
		const {
			firstName,
			studentIDN,
			gradeLevel,
			language,
			gender,
			lastName,
			exportIDN,
			school,
			userName,
			password,
		} = Object.keys(this.form.value).reduce((result, key) => {
			result[key] = this.form.value[key];
			if (typeof result[key] === 'string') {
				result[key] = (result[key] as string).trim();
			}
			return result;
		}, {}) as typeof this.form.value;

		const model = new StudentGeneralInfoModel(
			firstName,
			lastName,
			exportIDN,
			gender[0]?.id,
			Number(language[0]?.id),
			Number(gradeLevel[0]?.id),
			this.currentUser.globalSchoolYearID,
			this.currentUser.districtID,
			this.showSelectSchool.value ? school?.[0]?.schoolID : this.schoolID,
			UserType[this.currentUser.userType],
			this.currentUser.userID,
			this.teacherID,
			this.studentID,
			studentIDN,
			new CredentialModel(userName, password),
		);
		const isSaveAnyway = saveAnyway || (this.initData.value?.general?.firstName === model.firstName && this.initData.value?.general?.lastName === model.lastName);
		if (isSaveAnyway) {
			model.createAnyway = true;
		}
		return this.form.validate().pipe(
			switchMap((result) => {
				if (result.valid) {
					if (this.mode === StudentProfileMode.add) {
						delete model.studentID;
					}

					return this.httpClient.ESGIApi.post<SaveStudentResponse>(
						this.controller,
						`profile/general/${this.studentID ? 'update' : 'create'}`,
						model,
					).pipe(tap(({isSuccess, errors}) => {
						if (!isSuccess) {
							const [{type, description}] = errors;
							if (type === 'duplicate') {
								this.duplicateError.next(description);
							}
							throw new Error(description);
						}

						if (!this.studentID) {
							showSnackbarNotification(`${firstName} ${lastName} has been added.`);
						}

						const event = new StudentChangedEvent(
							this.studentID,
							firstName,
							lastName,
							gender[0]?.id ?? 'U',
							Number(gradeLevel[0]?.id),
							Number(language[0]?.id),
							studentIDN,
							model.schoolID,
							this.teacherID,
							this.initData.value?.location?.classIDs ?? [],
							this.initData.value?.location?.groupIDs ?? [],
							null,
							null,
							new CredentialModel(userName, password),
						);
						this.eventBus.dispatch(StudentChangedEvent, event);

						this.tabsApi.current[StudentProfileTab.General].isTouched = false;
						this.tabsStatus.next({
							...this.tabsStatus.value, [StudentProfileTab.General]: {
								isTouched: false,
							},
						});
					}));
				}
				return from([]);
			}),
			switchMap((result) => {
				if (this.preSelected.value && result?.value?.studentID && !this.studentID) {
					return this.saveLocation(result.value.studentID).pipe(map(() => result));
				}

				if (isSaveAndAddOther) {
					this.form.value = this.formInitialValues;
					this.initData.next({
						...this.defaultInitData,
						limit: {
							...this.defaultInitData.limit,
							studentsCount: this.initData.value.limit.studentsCount + 1,
						},
					});
				}

				return new Observable((subscriber) => {
					subscriber.next();
					subscriber.complete();
				});
			}),
			tap({
				next: (result) => {
					if (!this.studentID) {
						const {
							groupID,
							classID,
							specialistGroupID,
						} = this.preSelected.value ?? {};
						const classIDs =classID ? [classID] : this.initData.value.location?.classIDs ?? [];
						let teacherID = null;
						if (classIDs.length) {
							teacherID = this.teacherID ?? this.initData.value.location?.teacherID;
						}
						const event = new StudentCreatedEvent(
							result?.value?.studentID,
							exportIDN,
							firstName,
							lastName,
							gender[0]?.id ?? 'U',
							Number(gradeLevel[0]?.id),
							Number(language[0]?.id),
							studentIDN,
							this.currentUser.districtID,
							model.schoolID,
							teacherID,
							classIDs,
							groupID ? [groupID] : this.initData.value.location?.groupIDs ?? [],
							specialistGroupID ? [specialistGroupID] : this.initData.value.location?.specialistGroupIDs ?? [],
						);
						this.initData.next({
							...this.initData.value,
							location: {
								...this.initData.value.location,
								classIDs: event.classes,
								groupIDs: event.groups,
								specialistGroupIDs: event.specialistGroups,
							},
						});
						this.eventBus.dispatch(StudentCreatedEvent, event);
						const studentsLimitExceeded = this.initData.value.limit.studentsCount + 1 >= this.initData.value.limit.max;
						this.eventBus.dispatch(StudentSaveAndAddEvent, new StudentSaveAndAddEvent(isSaveAndAddOther, studentsLimitExceeded));
						this.tabsApi.current[StudentProfileTab.Location].isSaved = false;
					}
				},
				error: () => this.serviceLoading.next(false),
			}),
		);
	};

	public getSchools() {
		this.serviceLoading.next(true);
		return this.httpClient.ESGIApi.get<{ schools: Schools[] }>(this.controller, 'location/schools')
			.pipe(tap((s) => {
				this.schools.next(s.schools);
				const locationSchoolID = this.initData.value.location.schoolID || this.preSelected.value?.schoolID;
				if (locationSchoolID) {
					this.form.controls.school.value = s.schools?.filter(item => item.schoolID === locationSchoolID);
					this.tabsApi.current[StudentProfileTab.General].isTouched = false;
				}
			})).pipe(tap({
				complete: () => this.serviceLoading.next(false),
			}));
	}

	public saveLocation = (studentID: number) => {
		const {
			groupID,
			classID,
			userID,
			schoolID,
			specialistGroupID,
		} = this.preSelected.value ?? {};
		const classIDs = classID ? [classID] : this.initData.value.location?.classIDs ?? [];
		if(this.tabsApi.current && !this.tabsApi.current[StudentProfileTab.Location].form){
			this.tabsApi.current[StudentProfileTab.Location].form = createTeacherForm();
			this.tabsApi.current[StudentProfileTab.Location].form.controls.classIDs.value = classIDs;
		}
		const model = {
			studentID,
			groupIDs: groupID ? [groupID] : this.initData.value.location?.groupIDs ?? [],
			classIDs,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
			specialistGroupIDs: specialistGroupID ? [specialistGroupID] : this.initData.value.location?.specialistGroupIDs ?? [],
			...(userID ? {teacherID: userID} : {teacherID: this.initData.value.location?.teacherID} ?? {}),
			...(schoolID ? {schoolID} : {schoolID: this.form.value.school[0]?.schoolID}),
		};
		return this.httpClient.ESGIApi.post(this.controller, 'profile/location/save', model);
	};

	private fillForm = (initData: ProfileInitData, mode: StudentProfileMode) => {
		if (mode !== StudentProfileMode.add) {
			const {general} = initData;
			this.form.value = getGeneralFormValues(general, initData.dictionary);
			if (mode === StudentProfileMode.view) {
				this.form.status = ElementStatus.disabled;
			}
		} else {
			const formValues = {
				...this.form.value,
				gender: initData.general.gender ? initData.dictionary.genders.filter(item => item.id.toString() === initData.general.gender.toString()) : [],
				language: initData.general.languageID ? initData.dictionary.languages.filter(item => item.id.toString() === initData.general.languageID.toString()): [],
				gradeLevel: initData.general.gradeLevelID ? initData.dictionary.gradeLevels.filter(item => item.id.toString() === initData.general.gradeLevelID.toString()): [],
				userName: initData.general.credentialModel?.userName ?? '',
				password: initData.general.credentialModel?.password ?? '',
			};
			this.form.value = formValues;
			this.formInitialValues = formValues;
		}
	};

	private handleExportIDStatus = () => {
		if (this.currentUser.userType === UserType.T) {
			this.canExportID.next(false);
			this.form.controls.exportIDN.status = ElementStatus.disabled;
		} else {
			this.canExportID.next(true);
		}
	};

	private handleStudentIDnValidation = () => {
		this.form.controls.studentIDN.validators.pop();
		this.form.controls.studentIDN.validators.push(
			studentIDNAvailableCustomValidator(this.initialStudentIDN, this.currentUser.globalSchoolYearID, this.httpClient, (student) => this.usingIDStudent.next(student)));
		this.form.controls.studentIDN.onChanged.subscribe((ch) => {
			if (ch.reason === 'value' && !ch.currState.value) {
				this.form.controls.studentIDN.status = ElementStatus.untouched;
			}
		});
	};

	private handleStudentUserNameValidation = () => {
		this.form.controls.userName.validators.push(
			studentUserNameAvailableCustomValidator(this.initialUsername, this.studentIDSubject, this.currentUser.globalSchoolYearID, this.httpClient));
		this.form.controls.userName.onChanged.subscribe((ch) => {
			if (ch.reason === 'value' && !ch.currState.value) {
				this.form.controls.userName.status = ElementStatus.untouched;
			}
		});
	};

	private handleEditStudentLanguageStatus = (mode) => {
		if (mode !== StudentProfileMode.add) {
			this.form.controls.language.status = this.canEditStudentLanguage.value
				? ElementStatus.untouched
				: ElementStatus.disabled;
		}
	};

	public checkStudentToOtherMembers(studentId: number) {
		const globalSchoolYearID = getSelectedSchoolYearID();

		return this.httpClient.ESGIApi.get<{ links: string[]}>(
			this.studentCredentialsController,
			'check-another-links',
			{
				studentId,
				globalSchoolYearID,
				userId: this.currentUser.userID,
			},
		).asObservable();
	}
}
