import {createContext, useContext} from 'react';
import {useServiceFactory} from '@esgi/core/service';
import {BingoService, StudentService, TestSettingsService, DownloadService} from './services';

export const useBingo = () => {
	const service = useServiceFactory(() => new BingoService(
		new StudentService(),
		new TestSettingsService(),
		new DownloadService(),
	));

	return {service};
};

export const BingoContext = createContext(null);
export const useBingoService = () =>
	useContext<BingoService>(BingoContext);