import {ChartBlockModel} from '../../../../types';
import {EmptyChart} from './empty-chart';
import {Chart} from './chart.styled';

export interface Props {
	chartBlockModel: ChartBlockModel;
	printInColor: boolean;
	isNotTested: boolean;
}

export function PieChart({chartBlockModel, printInColor, isNotTested}: Props) {
	if (!chartBlockModel.correctPercent) {
		chartBlockModel.pieChartOptions.series[0].data[0].color = '#FFF';
	} else {
		chartBlockModel.pieChartOptions.series[0].data[0].color = printInColor ? '#00BF96' : '#7B7B7B';
	}

	if (isNotTested) {
		return <EmptyChart/>;
	}

	return (
		<Chart
			options={chartBlockModel.pieChartOptions}
			showLoader
		/>
	);
}
