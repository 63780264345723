import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './tile.module.less';

export interface BingoTileProps{
	value: string;
	className?: string;
	onClick: () => any;
	color?: string;
}

export default function BingoTile ({value, onClick, className, color}: BingoTileProps) {
	const shadeColor = (hex, lum) => {
		hex = String(hex).replace(/[^0-9a-f]/gi, '');
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		lum = lum || 0;

		let rgb = '#', c, i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
			rgb += ('00' + c).substr(c.length);
		}

		return rgb;
	};
	return (
		<OnHoverTooltip message={value}>
			<div
				data-cy='bingo-tile'
				className={className}
				style={color ? {backgroundColor: '#' + color, borderColor: shadeColor(color, -0.25)} : {}}
				onClick={onClick}
			>
				<div className={styles.inner}>{value}</div>
			</div>
		</OnHoverTooltip>
	);
}
