import Selectors from '@esgi/testing/selectors';
import {Property} from 'csstype';
import React, {ReactNode} from 'react';

class Props {
	className?: string;
	justify?: Property.JustifyContent;
	children?: ReactNode;
}

/**
 * @deprecated Use UI-Kit/Modal implementation.
 */
export class ModalFooter extends React.PureComponent<Props> {
	private get className() {
		let className = 'modal-footer';
		if (this.props.className) {
			className += ' ' + this.props.className;
		}
		return className;
	}

	render() {
		return <div className={this.className} style={{justifyContent: this.props.justify}} data-cy={Selectors.UIKit.Modal.Footer}>
			{this.props.children}
		</div>;
	}
}
