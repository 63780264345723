export enum ValidationStatus {
	None = 'None',
	IncorrectFileType = 'IncorrectFileType',
	MissingRequiredInformation = 'MissingRequiredInformation',
	InvalidField = 'InvalidField',
	MaximumStudents = 'MaximumStudents',
	PurchasingStudents = 'PurchasingStudents',
	ConflictExists = 'ConflictExists',
	Complete = 'Complete',
	DuplicateIDInFile = 'DuplicateIDInFile',
	CompleteWithStudentsExistInClass = 'CompleteWithStudentsExistInClass',
	UnsupportedFieldName = 'UnsupportedFieldName',
}

export type ValidStudentModel = {
	id: number;
	firstName: string;
	lastName: string;
	gradeLevelID: number;
	studentIDN: string;
	languageID: number;
};

type ServerExistingStudentField = {
	value: string;
	validationMessage: string;
	validationType: string;
	conflictType: string;
};

type ConflictExistingStudentModel = {
	id: number;
	teacherID: number;
	schoolID: number;
	name: string;
	studentIDN: string;
	language: string;
	gender: string;
	gradeLevelName: string;
	createDate: string;
};

export type ServerConflictStudentModel = {
	id: number;
	name: ServerExistingStudentField;
	studentIDN: ServerExistingStudentField;
	language: ServerExistingStudentField;
	gradeLevel: string;
	createDate: string;
	gender: string;
	existingStudents: ConflictExistingStudentModel[];
};

type ServerValidation = {
	status: ValidationStatus;
	data: string;
};

type UploadStudentsError = {
	type: string;
	description: string;
	args: string[];
};

type UploadStudentsValueResponse = {
	className: string | null;
	uploadedStudentsCount: number;
	conflictStudents: ServerConflictStudentModel[] | null;
	createdStudents: ValidStudentModel[];
	validation: ServerValidation;
} & (
	| {
			isSuccess: true;
			errors: [];
	  }
	| {
			isSuccess: false;
			errors: UploadStudentsError[];
	  }
);

export type UploadStudentsResponse = {
	value: UploadStudentsValueResponse;
};

export enum DrawerStep {
	UploadRoster = 'UploadRoster',
	ConflictResolution = 'ConflictResolution',
}

export type AlertContentType = ValidationStatus | 'delete-file-upload' | null;
