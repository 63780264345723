import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {
	ChangeEvent,
	PropsWithChildren,
	Ref,
	useCallback,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import {PanelContent} from '../../../../../../components/panels.styled';
import {ContentBox} from '../../../../../../components/content-box.styled';
import {Drawer} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {Input} from '@esgi/ui/controls';
import {ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot} from '../../components/components.styled';
import {BaseTrackDateModel, SchoolYearModel, TrackDateModelState, TrackType} from '../../../../../../../../types';
import {markingPeriodItems} from '../../../constants';
import {Text} from '@esgi/ui/typography';
import {TrackDatesWithSlider} from '../../../../../../../track-dates-with-slider';
import {useDateValuesChanged} from '../../../../../../../../hooks/use-date-values-changed';
import {isTrackDatesValid} from '../../../../../../../../utils/is-track-dates-valid';

export type AddEditModeContentRef = {
	getContentData: () => {
		markingPeriodName: string;
		markingPeriodType: TrackType;
		datesInRowsState: TrackDateModelState[];
	};
	isDatesChanged: boolean;
};

type Props = PropsWithChildren<{
	defaultMarkingPeriodName: string;
	defaultMarkingPeriodType: TrackType;
	schoolYearTypes: SchoolYearModel[] | null;
	defaultTrackDates: BaseTrackDateModel[];
	setIsMarkingPeriodTouched?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsMarkingPeriodValid: React.Dispatch<React.SetStateAction<boolean>>;
	addEditModeContentRef: Ref<AddEditModeContentRef | undefined>;
}>;

export function AddEditModeContent({
	children,
	defaultMarkingPeriodName,
	defaultMarkingPeriodType,
	schoolYearTypes,
	defaultTrackDates,
	setIsMarkingPeriodTouched,
	setIsMarkingPeriodValid,
	addEditModeContentRef,
}: Props) {
	const [markingPeriodName, setMarkingPeriodName] = useState(defaultMarkingPeriodName);
	const [markingPeriodNameError, setMarkingPeriodNameError] = useState<string>();
	const [markingPeriodType, setMarkingPeriodType] = useState(defaultMarkingPeriodType);
	const [datesInRowsState, setDatesInRowsState] = useState<TrackDateModelState[]>([]);

	useLayoutEffect(() => {
		setMarkingPeriodName(defaultMarkingPeriodName);
	}, [defaultMarkingPeriodName]);

	useLayoutEffect(() => {
		setMarkingPeriodType(defaultMarkingPeriodType);
	}, [defaultMarkingPeriodType]);

	const handleMarkingPeriodNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		setMarkingPeriodName(value);

		if (!value) {
			setMarkingPeriodNameError('Marking Period Name is required');
			return;
		}

		if (!value.trim()) {
			setMarkingPeriodNameError('Incorrect Marking Name');
			return;
		}

		setMarkingPeriodNameError(undefined);
	}, []);

	const dataIsValid = useMemo(() => isTrackDatesValid(datesInRowsState), [datesInRowsState]);
	const isDatesChanged = useDateValuesChanged({datesInRowsState, currentTrackDates: defaultTrackDates});

	const isMarkingPeriodTouched = isDatesChanged || defaultMarkingPeriodName !== markingPeriodName;

	const isMargingPeriodValid = dataIsValid && !markingPeriodNameError;

	useEffect(() => {
		setIsMarkingPeriodTouched?.(isMarkingPeriodTouched);
	}, [isMarkingPeriodTouched]);

	useEffect(() => {
		setIsMarkingPeriodValid(isMargingPeriodValid);
	}, [isMargingPeriodValid]);

	useImperativeHandle(addEditModeContentRef, () => ({
		getContentData: () => ({
			markingPeriodName: markingPeriodName.trim(),
			markingPeriodType,
			datesInRowsState,
		}),
		isDatesChanged,
	}));

	return (
		<OverlayScrollbarsComponent
			defer
			options={{
				scrollbars: {autoHide: 'leave'},
				paddingAbsolute: true,
			}}
		>
			<PanelContent>
				<ContentBox>
					<Drawer.ContentBlock title='Current Marking Period'>
						{children}
						<GridBox dataCy='marking-period-name'>
							<Input
								value={markingPeriodName}
								onChange={handleMarkingPeriodNameChange}
								placeholder='Marking Period Name'
								error={markingPeriodNameError}
							/>
						</GridBox>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Number of Marking Periods'>
						<ToggleGroupRoot
							dataCy='number-of-marking-periods-box'
							onValueChange={(tabId) => setMarkingPeriodType(tabId as TrackType)}
							value={markingPeriodType}
						>
							<ToggleGroupContent>
								{markingPeriodItems.map(({label, value}) => (
									<ToggleGroupItem
										dataCy='number-of-marking-periods-item'
										value={value}
										key={value}
										disabled={markingPeriodType === value}
										applyDisabledStyles={false}
									>
										<Text size='medium' bold color='base'>
											{label}
										</Text>
									</ToggleGroupItem>
								))}
							</ToggleGroupContent>
						</ToggleGroupRoot>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Marking Period Dates'>
						{schoolYearTypes && (
							<TrackDatesWithSlider
								schoolYearTypes={schoolYearTypes}
								defaultTrackDates={defaultTrackDates}
								defaultTrackType={defaultMarkingPeriodType}
								selectedTrackType={markingPeriodType}
								datesInRowsState={datesInRowsState}
								setDatesInRowsState={setDatesInRowsState}
								withDateSlider
							/>
						)}
					</Drawer.ContentBlock>
				</ContentBox>
			</PanelContent>
		</OverlayScrollbarsComponent>
	);
}
