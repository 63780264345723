import {useEffect} from 'react';
import {IEPGoalFormService} from '../../services';
import {Form, ElementStatus} from '@esgillc/ui-kit/form';
import {
	StudentDropdown,
	SubjectDropdown,
	TestDropdown,
	IEPGoalField,
	BenchmarkField,
	NotesField,
	RecommendationField,
	StatusDropdown,
	MarkAsCompletedField,
} from '../fields';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';

type Props = {
	service: IEPGoalFormService;
}

export function EditContainer({service}: Props): JSX.Element {
	const students = useBehaviorSubject(service.students$);
	const subjects = useBehaviorSubject(service.subjects$);
	const statuses = useBehaviorSubject(service.iepStatuses$);
	const tests = useBehaviorSubject(service.tests$);

	useEffect(() => {
		service.toggleFields();

		const sub = service.form.controls.isCompleted.onChanged.subscribe(
			({reason, currState}) => {
				if (reason === 'value') {
					service.toggleFields(currState.value);
				}
			},
		);
		return () => sub.unsubscribe();
	}, []);

	return (
		<Form
			className={styles.form}
			controller={service.form}
		>
			<StudentDropdown
				control={service.form.controls.student}
				students={students}
				service={service}
			/>
			<SubjectDropdown
				control={service.form.controls.subject}
				subjects={subjects}
			/>
			<TestDropdown
				control={service.form.controls.test}
				tests={tests}
			/>
			<IEPGoalField control={service.form.controls.goal}/>
			<StatusDropdown
				control={service.form.controls.status}
				statuses={statuses}
				service={service}
			/>
			<BenchmarkField
				benchmarkControl={service.form.controls.benchmark}
				statusControl={service.form.controls.status}
			/>
			<NotesField control={service.form.controls.notes}/>
			<RecommendationField control={service.form.controls.recommendation}/>
			<MarkAsCompletedField control={service.form.controls.isCompleted}/>
		</Form>
	);
}
