import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';
import {getUser, UserType} from '@esgi/core/authentication';

interface Props {
	onClicked: () => void;
	studentsCount: number;
	className: string;
	specialistGroupName: string;
	duplicatesSaved: boolean;
	completedWithExistsStudents: boolean;
}

const url = '/student-manager';

export function FileUploadIsComplete({
	onClicked,
	studentsCount,
	duplicatesSaved,
	completedWithExistsStudents,
	className, specialistGroupName,
}: Props) {

	const onVisitStudentManagerPage = () => {
		window.open(url);
	};

	const isPA = getUser().userType === UserType.PA;

	const message = () => {
		const template = [];

		if (completedWithExistsStudents) {
			template.push(<>Your file contains students who are already uploaded to a class.</>);
		}

		template.push(<>{studentsCount || 0} students have been added to {isPA ? specialistGroupName : className}.</>);

		if (studentsCount > 0 && duplicatesSaved) {
			template.push(<>Please visit <Buttons.Link onClick={onVisitStudentManagerPage}>Student Manager</Buttons.Link> to assign the remainder of your
				students to your class.
			</>);
		}
		return <>{template.map((x, i) => <p key={i}>{x}</p>)}</>;
	};

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>File Upload is Complete</Title>
		</Modal.Header>
		<Modal.Body>
			<span data-cy={'file-upload-is-complete'} className={styles.text}>{message()}</span>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}
