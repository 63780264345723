import {Subject, debounceTime} from 'rxjs';
import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Input} from '@esgi/ui/controls';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {SortDirection} from '@esgi/contracts/esgi/types/esgi.enums/sort-direction';
import {Button, Close, Search, ListView, TilesView, QuestionBordered, useBehaviorSubject} from '@esgi/ui';
import {sortFields} from '../../../../constants';
import {SortableSelect} from '../sortable-select';
import {useQuestionBankService} from '../../../../hooks';
import {SortDescriptor, ViewMode} from '../../../../types';
import {ActionGrid, Root, Row, StyledCounterContainer, TitleGrid, ToggleWrapper, ViewToggleButton, StyledCounterBox} from './index.styled';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type SearchPanelProps = {
	selectedView: ViewMode;
	onViewChanged: (view: ViewMode) => void;
	isSmallScreen: boolean;
	hasChanges?: boolean;
	searchText?: string;
};

export function Header({
	selectedView,
	onViewChanged,
	hasChanges,
	searchText,
	isSmallScreen,
}: SearchPanelProps) {
	const service = useQuestionBankService();

	const test = useBehaviorSubject(service.test$);
	const pageRequest = useBehaviorSubject(service.pageRequest$);
	const pageResponse = useBehaviorSubject(service.pageResponse$);

	const [searchValue, setSearchValue] = useState<string>('');
	const [formCloseClicked, setFormCloseClicked] = useState(false);

	const searchInputSubject$ = useMemo(() => new Subject<string>(), []);

	const handleSort = useCallback((sortDescriptor: SortDescriptor) => {
		service.applySortBy(sortDescriptor);
	}, [service]);

	const handleSearch = useCallback((searchText: string) => {
		service.updateSearchText(searchText);
	}, [service]);

	const handleViewChanged = useCallback((value: ViewMode) => ()=> {
		onViewChanged(value);
	}, [onViewChanged]);

	const handleSortDirectionChange = useCallback(() => {
		handleSort({
			...pageRequest.sortBy,
			direction: pageRequest.sortBy.direction === SortDirection.Asc ? SortDirection.Desc: SortDirection.Asc,
		});
	}, [handleSort, pageRequest.sortBy]);

	const handleSortChanged = useCallback((value: string) => {
		handleSort({
			...pageRequest.sortBy,
			field: value,
		});
	}, [handleSort, pageRequest.sortBy]);

	const onSearchValueChange = useCallback(({target: {value}}: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(value);
		searchInputSubject$.next(value);
	}, [searchInputSubject$]);

	const handleFormClose = useCallback(() => {
		setFormCloseClicked(false);
		service.close();
	}, [service]);

	const handleButtonCloseClick = useCallback(() => {
		if (!hasChanges) {
			handleFormClose();
			return;
		}
		setFormCloseClicked(true);
	}, [hasChanges, handleFormClose]);

	const handleCloseAlert = useCallback(() => {
		setFormCloseClicked(false);
	}, []);


	useEffect(() => {
		const subscription = searchInputSubject$
			.pipe(debounceTime(500))
			.subscribe((value) => handleSearch(value));

		return () => {
			subscription.unsubscribe();
		};
	}, [searchInputSubject$, handleSearch]);

	useEffect(() => {
		setSearchValue(searchText);
	}, [searchText]);

	return (
		<Root>
			<Row>
				<FlexBox>
					<TitleGrid rows={1} gapX={2}>
						<FlexBox>
							<QuestionBordered />
						</FlexBox>
						<Text color='mediumContrast' size='small'>
							Add Questions To A Test
						</Text>
						<Text color='base' size='small'>
							{test?.testName ?? ''}
						</Text>
					</TitleGrid>
				</FlexBox>
				<FlexBox>
					<Button.Icon onClick={handleButtonCloseClick}>
						<Close />
					</Button.Icon>
				</FlexBox>
			</Row>
			<Row>
				<ActionGrid isSmallScreen={isSmallScreen} rows={1} gapX={4}>
					<ToggleWrapper>
						<ViewToggleButton selected={selectedView === ViewMode.List} onClick={handleViewChanged(ViewMode.List)}>
							<ListView width={24} height={24}/>
						</ViewToggleButton>
						<ViewToggleButton selected={selectedView === ViewMode.Grid} onClick={handleViewChanged(ViewMode.Grid)}>
							<TilesView width={24} height={24}/>
						</ViewToggleButton>
					</ToggleWrapper>
					<Input.Iconable placeholder='Search' value={searchValue} onChange={onSearchValueChange}>
						<Search />
					</Input.Iconable>
					<StyledCounterContainer>
						<StyledCounterBox>
							<Text size='small' font='mono'>
								{pageResponse?.total}
							</Text>
						</StyledCounterBox>
						<Text font='mono' size='small' color='mediumContrast'>
							Questions
						</Text>
					</StyledCounterContainer>
					<SortableSelect
						selectedValue={pageRequest.sortBy.field}
						items={sortFields}
						onValueChange={handleSortChanged}
						sortDirection={pageRequest.sortBy.direction}
						onSortDirectionChange={handleSortDirectionChange}
						placeholder='Sort By'/>
				</ActionGrid>
			</Row>
			{formCloseClicked && hasChanges && (
				<UnsavedChangesConfirmation onClose={handleCloseAlert} onCloseAnyway={handleFormClose} />
			)}
		</Root>
	);
}
