import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {useMemo} from 'react';

type Props = Omit<VariantProps<typeof Text>, 'children'> & {
	/**
	 * Time in sec
	 */
	time: number;
	timeFormat: 'm:ss' | 'mm:ss';
	doRound?: boolean;
};

export function TimeText({time, timeFormat, doRound = true, ...props}: Props) {
	const timeFormated = useMemo(() => {
		const seconds = doRound ? Math.round(time % 60) : time % 60;
		const minutes = Math.floor(time / 60);

		const secondsString = String(seconds).padStart(2, '0');

		if (timeFormat === 'm:ss') {
			return `${minutes}:${secondsString}`;
		}

		return `${String(minutes).padStart(2, '0')}:${secondsString}`;
	}, [time, timeFormat, doRound]);

	return <Text {...props}>{timeFormated}</Text>;
}
