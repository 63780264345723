import {Text} from '@esgi/ui/typography';
import {MultipleLabelBox, StandardsCounter} from './index.styled';
import {Tooltip} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {OneLinedText} from '@esgi/main/kits/common';
import {LabelBox} from '../test-type-label/index.styled';

type Props = {
	items: (string | number)[], dataCy?: string;
};

export function MultipleLabels({items, dataCy}: Props) {
	if (!items.length) {
		return (
			<LabelBox data-cy={dataCy}>
				<Text size='small' font='mono' color='mediumContrast'>None</Text>
			</LabelBox>
		);
	}

	if (items.length === 1) {
		return (
			<LabelBox data-cy={dataCy}>
				<OneLinedText size='small' color='highContrast'>{items[0]}</OneLinedText>
			</LabelBox>
		);
	}

	return (
		<MultipleLabelBox data-cy={dataCy}>
			<Text data-cy='first-label-name' size='small' color='highContrast'>{items[0]}</Text>
			<Tooltip>
				<Tooltip.Trigger>
					<StandardsCounter>
						<Text data-cy='other-labels-counter' size='small' color='highContrast'>
							{items.length - 1}...
						</Text>
					</StandardsCounter>
				</Tooltip.Trigger>
				<Tooltip.Content side='bottom'>
					<GridBox flow='row' gap={1}>
						{items.slice(1).map(item => (
							<Text size='small' color='tertiaryVivid' key={item}>
								{item}
							</Text>
						))}
					</GridBox>
				</Tooltip.Content>
			</Tooltip>
		</MultipleLabelBox>
	);
}