import {TextInputConflict} from '../text-input';
import {
	ConflictLevel, ConflictStudentModel,
	ConflictType,
	Validation,
	ValidationType,
} from 'shared/modules/student-roster-upload-tool/types';

interface Props {
	existingStudentIDs: string[];
	uploadedStudentID: string;
	onEdit: (value: string, validation: Validation) => void;
	onClick: (validation: Validation) => void;
	validation: Validation;
	tableHeight: number;
	tableOffset: number;
	student: ConflictStudentModel
}

export function ID({
										 existingStudentIDs,
										 uploadedStudentID,
										 onEdit,
										 onClick,
										 validation,
										 tableHeight,
										 tableOffset,
										 student,
									 }: Props) {
	if (!student.studentIDN.editable) {
		return <>{student.studentIDN.value}</>;
	}
	const onHandleEdit = (value: string) => {
		if (existingStudentIDs.some(x => x.trim() === value.trim())) {
			return onEdit(value,
				new Validation(ValidationType.Conflict,
					ConflictType.DuplicateStudentIDN,
					'ID must be modified or removed in order to save this student.'));
		}
		if (!value.trim()) {
			return onEdit(value, new Validation(ValidationType.Standard, ConflictType.None, 'Please enter a student ID.'));
		}
		return onEdit(value, new Validation());
	};

	const handleValidation = () => {
		return {
			valid: validation.type === ValidationType.None,
			message: validation.message,
		};
	};

	return <div data-cy={'id'}>
		<TextInputConflict
			value={uploadedStudentID}
			conflictLevel={ConflictLevel.RequireChange}
			validation={handleValidation()}
			validationType={validation.type}
			onEdit={(value) => onHandleEdit(value)}
			tableHeight={tableHeight}
			tableOffset={tableOffset}
			onClick={() => onClick(validation)}
		/>
	</div>;
}
