import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const EmptyBox = styled(Box, {
	padding: '12px 20px',
	textAlign: 'center',
	margin: '0 auto',
	borderRadius: '6px',
	border: '1px solid $vivid',
	background: '$vivid',
});
