import {BaseComponentProps} from '@esgi/ui';
import {ComponentProps, ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = BaseComponentProps &
	ComponentPropsWithoutRef<'div'> &
	ComponentProps<typeof Container> & {
		transcript: string;
	};

export const InfoWithTranscript = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'assignments-info-with-transcript', css = {}, transcript, children, ...props}, forwardedRef) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
			{children}
			<Text size='small' font='mono' color='neutral56' data-cy='info-label'>
				{transcript}
			</Text>
		</Container>
	),
);
