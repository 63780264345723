import {FeaturedSeriesModel} from '../../landing/featured-series/featured-series';

export namespace FeaturedSeriesEvents {
	export function SeriesClicked(featuredSeries: FeaturedSeriesModel): SeriesClicked.Args {
		return {
			featuredSeries: featuredSeries,
		};
	}

	export namespace SeriesClicked {
		export interface Args {
			featuredSeries: FeaturedSeriesModel;
		}
	}
}
