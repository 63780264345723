import {Container, ContentWrapper, Content} from './index.styled';
import {Button, Close, Fav, Tooltip, Hide, Eye} from '@esgi/ui';
import {Checkbox} from '@esgi/ui/controls';
import React from 'react';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {StyledButton} from '../card/index.styled';
import {Counter} from '@esgi/main/features/standards-drawer';

type Props = {
	selectedTestsCount: number,
	showOnlySelectedTests: boolean,
	addSelectedToFavoritesChecked: boolean,
	hideTestsChecked: boolean,
	onClose: VoidFunction,
	onAddSelectedClick: VoidFunction,
	onAddToFavoritesClick: VoidFunction,
	onHideTestsClick: VoidFunction,
	onShowOnlySelectedClick: VoidFunction,
}

export function SelectedTestsBar({
	selectedTestsCount,
	showOnlySelectedTests,
	addSelectedToFavoritesChecked,
	hideTestsChecked,
	onClose,
	onAddSelectedClick,
	onAddToFavoritesClick,
	onHideTestsClick,
	onShowOnlySelectedClick,
}: Props) {
	return (
		<Container data-cy='test-actions-panel'>
			<ContentWrapper>
				<Content>
					<GridBox gap={4} flow='column' align='center'>
						<Button.Icon onClick={onClose}>
							<Close/>
						</Button.Icon>
						<GridBox gap={2} flow='column' align='center'>
							<Checkbox dataCy='show-only-selected-checkbox' css={{padding: 4}} checked={showOnlySelectedTests} onCheckedChange={onShowOnlySelectedClick} />
							<Text data-cy='show-only-selected-label' size='small' font='mono' color='primary'>Show Only Selected</Text>
						</GridBox>
					</GridBox>
					<GridBox gap={5} flow='column'>
						<GridBox gap={2} flow='column' align='center'>
							<Text data-cy='test-counter-label' size='small' font='mono' color='primary'>Tests Selected:</Text>
							<Counter>
								<Text data-cy='test-counter-value' size='small' font='mono' color='primaryVivid'>
									{selectedTestsCount}
								</Text>
							</Counter>
						</GridBox>
						<GridBox gap={2} flow='column' align='center'>
							<Tooltip>
								<Tooltip.Trigger>
									<StyledButton checked={hideTestsChecked} onClick={onHideTestsClick} dataCy='hide-button'>
										{!hideTestsChecked ? <Hide/> : <Eye />}
									</StyledButton>
								</Tooltip.Trigger>
								<Tooltip.Content side='top'>
									<Text data-cy='hide-unhide-tooltip' size='large' color='tertiary'>
										{!hideTestsChecked ? 'Hide from search results' : 'Unhide from search results'}
									</Text>
								</Tooltip.Content>
							</Tooltip>

							<Tooltip>
								<Tooltip.Trigger>
									<StyledButton checked={addSelectedToFavoritesChecked} onClick={onAddToFavoritesClick} dataCy='favorites-button'>
										<Fav/>
									</StyledButton>
								</Tooltip.Trigger>
								<Tooltip.Content side='top'>
									<Text data-cy='favorites-tooltip' size='large' color='tertiary'>
										{addSelectedToFavoritesChecked ? 'Remove from favorites' : 'Add to favorites'}
									</Text>
								</Tooltip.Content>
							</Tooltip>

							<Button data-cy='add-selected-tests-button' onClick={onAddSelectedClick}>
								<Text size='large'>
									ADD SELECTED
								</Text>
							</Button>
						</GridBox>
					</GridBox>
				</Content>
			</ContentWrapper>
		</Container>
	);
}