import React from 'react';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {
	LoginInfoFormType,
} from '../../forms/login-info';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

export interface Props {
	form: LoginInfoFormType;
	className?: string
}

export function ProfileLoginInfo({form, className}: Props) {
	return <div className={join(styles.loginInfo, className)} data-cy={'login-info-form'}>
		<label className={styles.mainLabel}>Login Information</label>
		<Form controller={form} className={styles.form}>
			<FormElement control={form.controls.login}>
				<div data-cy={'login-field'}>
				<label className={styles.label}>
					User Name
				</label>
				<Input/>
				<OnErrorTooltip showOnError='required' placement='top'>
					Please enter a username.
				</OnErrorTooltip>
				<OnErrorTooltip showOnError='login-in-use' placement='top'>
					This user name is already taken. Please enter another one.
				</OnErrorTooltip>
				</div>
			</FormElement>
			<FormElement control={form.controls.password}>
				<div data-cy={'password-field'}>
					<label className={styles.label}>
						Password
					</label>
					<Input type={'password'}/>
					<OnErrorTooltip showOnError='required' placement='top'>
						Please enter a password.
					</OnErrorTooltip>
					<OnErrorTooltip showOnError='length-min' placement='top'>
						Please enter a valid password. Passwords must contain a minimum of 8 characters.
					</OnErrorTooltip>
				</div>
			</FormElement>
		</Form>
	</div>;
}
