import {createTheme} from './stitches.config';
import {themedColors} from './themed-colors';
import {legacyColors} from './themed-colors/legacy-colors';
import {baseTheme} from './base';

export const defaultTheme = createTheme('default', {
	...baseTheme,
	colors: {
		...themedColors.default,
		...legacyColors,
	},
});

export const darkTheme = createTheme('dark', {
	...baseTheme,
	colors: themedColors.dark,
});

export const highContrastTheme = createTheme('high-contrast', {
	...baseTheme,
	colors: themedColors.highContrast,
});
