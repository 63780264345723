import {ComponentPropsWithoutRef, forwardRef, useEffect, useMemo} from 'react';
import {usePieChartContext} from '../../context';
import {ThemeColorKeys} from '../../../types';
import {getThemeColor} from '@esgi/ui/utils';
import {Circle} from './index.styled';

type PieChartRingProps = Omit<
	ComponentPropsWithoutRef<'circle'>,
	'children' | 'stroke' | 'strokeWidth' | 'cx' | 'cy' | 'r' | 'fill'
> & {
	/**
	 * Set stroke color for the ring.
	 * @default 'border'
	 */
	stroke?: ThemeColorKeys;

	/**
	 * Set stroke width for the ring.
	 * @default 1
	 */
	strokeWidth?: number;
};

export const PieChartRing = forwardRef<SVGCircleElement, PieChartRingProps>(
	({strokeWidth = 1, stroke = 'border', ...circleProps}, forwardedRef) => {
		const {svgValue, updateShapeOffset, maxShapeOffset} = usePieChartContext();

		useEffect(() => {
			updateShapeOffset({shape: 'ring', offset: strokeWidth});
		}, [strokeWidth, updateShapeOffset]);

		const circleValue = useMemo(() => {
			const size = svgValue.size - maxShapeOffset;

			return {
				size,
				radius: size / 2,
				center: svgValue.center,
			};
		}, [maxShapeOffset, svgValue.center, svgValue.size]);

		return (
			<Circle
				cx={circleValue.center.x}
				cy={circleValue.center.y}
				r={circleValue.radius}
				strokeWidth={strokeWidth}
				fill='none'
				stroke={getThemeColor(stroke)}
				ref={forwardedRef}
				{...circleProps}
			/>
		);
	},
);
