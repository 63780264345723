import {useRef} from 'react';
import {SubjectSectionMode} from '@esgi/main/features/teacher/home';

export function useSectionTitle({sectionMode}: {sectionMode: SubjectSectionMode}) {
	const {current: sectionModeTitle} = useRef<Record<SubjectSectionMode, string>>({
		[SubjectSectionMode.View]: 'Subject Tabs',
		[SubjectSectionMode.Edit]: 'Edit Mode',
		[SubjectSectionMode.Rearrange]: 'Rearrange Mode',
		[SubjectSectionMode.Add]: 'Add Mode',
	});

	return {
		sectionTitle: sectionModeTitle[sectionMode],
	};
}
