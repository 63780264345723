import React from 'react';
import {ToggleGroup} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {ConflictAction, ConflictType} from '../../../../types';
import {ActionName, TabsControllerRoot, ToggleGroupContent} from './index.styled';

interface TabsProps {
	onChange: (action: ConflictAction) => void;
	selectedAction: ConflictAction;
	isDisabled?: boolean;
	withDeleteAction: boolean;
	conflictedType: ConflictType;
	isDisabledAddAnyway: boolean
}

export function ConflictActionsPanel({
	selectedAction,
	onChange,
	isDisabled,
	withDeleteAction,
	conflictedType,
	isDisabledAddAnyway,
}: TabsProps) {

	return <TabsControllerRoot value={selectedAction} disabled={isDisabled}>
		<ToggleGroupContent dataCy='handle-conflict-option-tabs' defaultActions={conflictedType === ConflictType.DuplicateStudentName}>
			<ToggleGroup.Item
				data-cy='fix-option'
				value={ConflictAction.Fix}
				onClick={() => onChange(ConflictAction.Fix)}
				valueDescription='You fix the conflict by changing the added value'
			>
				<ActionName>Fix</ActionName>
			</ToggleGroup.Item>

			{conflictedType === ConflictType.DuplicateStudentName && <ToggleGroup.Item
				data-cy='add-anyway-option'
				disabled={isDisabledAddAnyway}
				value={ConflictAction.AddAnyway}
				onClick={() => onChange(ConflictAction.AddAnyway)}
				valueDescription='The conflict will be ignored, duplicate will be added anyway'
			>
				<ActionName>Add Anyway</ActionName>
			</ToggleGroup.Item>}

			{withDeleteAction
				? <ToggleGroup.Item
					data-cy='delete-option'
					value={ConflictAction.Delete}
					onClick={() => onChange(ConflictAction.Delete)}
					valueDescription='The entry from the file won’t be added'
				>
					<ActionName>Delete</ActionName>
				</ToggleGroup.Item>
				: <ToggleGroup.Item
					data-cy='skip-option'
					value={ConflictAction.Delete}
					onClick={() => onChange(ConflictAction.Delete)}
					valueDescription='The entry from the file won’t be added'
				>
					<ActionName>Skip</ActionName>
				</ToggleGroup.Item>
			}
		</ToggleGroupContent>
		<ToggleGroup.Title>{({description}) => <Text size='xSmall'>{description}</Text>}</ToggleGroup.Title>
	</TabsControllerRoot>;
}
