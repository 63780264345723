import {styled} from '@stitches/react';

export const HelpButtonIcon = styled('div', {
	width: '32px',
	height: '28px',
	borderRadius: '6px 6px 0 0',
	border: '1px solid $tertiaryMuted',
	backgroundColor: '$tertiarySurface',
	borderBottom: 'none',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingBottom: '0',
	transition: '.2s ease',

	'& svg': {
		fill: '$tertiary',
		'& path': {
			fill: '$tertiary',
		},
	},

	variants: {
		large: {
			true: {
				width: '36px',
				height: '36px',
				borderRadius: '6px 0px 0px 6px',

			},
		},
	},

	'&:hover': {

		'& svg': {
			scale: 1.5,
		},
	},
});
