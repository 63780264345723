import {AssignmentContextMenu, TextWithIcon, WarningMessageText} from '@esgi/main/kits/assignments';
import {ActionButtonsBox, ActionButtonsDivider, CentralPanelHeader, HeaderContainer} from '../../central-panel-header';
import {AccessExport, Assignment, Edit} from '@esgi/ui/icons';
import {GridBox} from '@esgi/ui/layout';
import {Button, Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

type WithoutActionButton = {
	withActionButton?: false;
};

type WithActionButton = {
	withActionButton: true;
	onActionButtonClick: VoidFunction;
	actionButtonDisabled?: boolean;
	actionButtonLabel: string;
};

type Props = {
	studentsWithoutAccessCount: number;
	hasTestsInAssignment: boolean;
	onDeleteAssignment: VoidFunction;
	onOpenEditAssignment?: VoidFunction;
	disableEditDashboardButton?: boolean;
	onOpenExportCredentialsAlert: VoidFunction;
	disabledOpenExportStudentsCredentials: boolean;
} & (WithoutActionButton | WithActionButton);

export function AssignmentHeader({
	studentsWithoutAccessCount,
	hasTestsInAssignment,
	onDeleteAssignment,
	onOpenEditAssignment,
	disableEditDashboardButton,
	onOpenExportCredentialsAlert,
	disabledOpenExportStudentsCredentials,
	...props
}: Props) {
	return (
		<CentralPanelHeader>
			<HeaderContainer>
				<TextWithIcon size='small' color='neutral56' Icon={Assignment}>
					Assignment
				</TextWithIcon>
				<ActionButtonsBox>
					<GridBox gap='1'>
						{!hasTestsInAssignment && (
							<WarningMessageText size='xSmall' font='mono' bold>
								The assignment currently does not have any associated tests
							</WarningMessageText>
						)}
						{Boolean(studentsWithoutAccessCount) && (
							<WarningMessageText size='xSmall' font='mono' bold>
								{studentsWithoutAccessCount} {studentsWithoutAccessCount === 1 ? 'student' : 'students'} do not have
								credentials and cannot access their assignments
							</WarningMessageText>
						)}
					</GridBox>
					<AssignmentContextMenu onDeleteAssignment={onDeleteAssignment} />
					<ActionButtonsDivider />
					<Tooltip>
						<Tooltip.Trigger>
							<Button.Icon
								withBackgroundHover
								disabled={disabledOpenExportStudentsCredentials}
								onClick={onOpenExportCredentialsAlert}
								dataCy='student-credentials-icon'
							>
								<AccessExport />
							</Button.Icon>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small'>Add, edit, or view student credentials</Text>
						</Tooltip.Content>
					</Tooltip>
					<Button.Icon
						withBackgroundHover
						disabled={Boolean(disableEditDashboardButton)}
						onClick={onOpenEditAssignment}
						dataCy='edit-icon'
					>
						<Edit />
					</Button.Icon>
					{props.withActionButton && (
						<Button color='primary' onClick={props.onActionButtonClick} disabled={Boolean(props.actionButtonDisabled)}>
							<Text size='large'>{props.actionButtonLabel}</Text>
						</Button>
					)}
				</ActionButtonsBox>
			</HeaderContainer>
		</CentralPanelHeader>
	);
}
