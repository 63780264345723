import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const RepAutoquoterPage = lazyComponent(
	() => import('pages/landing/rep-autoquoter'),
);

export default function RepAutoquoter() {
	return (
		<RouteWrapper title='Riverside Sales Autoquoter'>
			<Suspense fallback={<DefaultRouteFallback />}>
				<RepAutoquoterPage />
			</Suspense>
		</RouteWrapper>
	);
}
