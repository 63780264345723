import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardBody = styled(Card.Body, {
	display: 'inline-grid',

	variants: {
		withDescription: {
			true: {
				gridAutoFlow: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: 20,
			},

			false: {
				justifyContent: 'end',
			},
		},
	},
});
