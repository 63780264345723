import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {
	Layout,
	TextContainer,
	TitleText,
	DescriptionText,
	NavigationItem,
	NavigationItemText,
	EmptyBodyText,
} from './index.styled';
import {ReportTypeOption, reportTypeList} from '../../../model';
import {userStorage} from '@esgi/core/authentication';

type Props = {
	setReportType: (value: ReportTypeOption) => void;
};

export function Body({setReportType}: Props) {
	const currentUser = userStorage.get();
	return (
		<Layout>
			<TextContainer>
				<TitleText size='xxLarge'>Your AI-Powered Analysis Tool</TitleText>
				<DescriptionText size='medium'>
					Unlock enhanced tools and student insights to elevate your teaching
					experience.
				</DescriptionText>
			</TextContainer>
			{currentUser?.globalSchoolYearID < 38 ? (
				<>
					<DescriptionText size='medium'>
						AI Analysis tool is only available for school year 23-24 and
						onwards.
					</DescriptionText>
					<EmptyBodyText size='small'>
						Select a valid school year to begin
					</EmptyBodyText>
				</>
			) : (
				<>
					<GridBox gap='5' flow='column' columns='2'>
						{reportTypeList.map((reportType) => (
							<NavigationItem
								onClick={() => {
									setReportType(reportType);
								}}
								key={reportType.value}
							>
								{reportType.icon && <reportType.icon height={24} width={24} />}
								<Text size='medium' bold>
									{reportType.label}
								</Text>
								<NavigationItemText size='medium' color='neutral40'>
									{reportType.content}
								</NavigationItemText>
							</NavigationItem>
						))}
					</GridBox>
					<EmptyBodyText size='small'>
						Select a Report Type to Begin
					</EmptyBodyText>
				</>
			)}
		</Layout>
	);
}
