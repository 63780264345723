import {styled} from '@esgi/ui/theme';
import {Track} from '@radix-ui/react-slider';

export const SliderTrack = styled(Track, {
	backgroundColor: '$primaryMuted',
	position: 'relative',
	flexGrow: 1,

	'&[data-orientation=horizontal]': {
		height: 1,
	},

	'&[data-orientation=vertical]': {
		width: 1,
	},

	'&[data-disabled]': {
		backgroundColor: '$mild',
	},
});
