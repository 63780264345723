import {SkeletonShape} from '@esgi/main/kits/common';

export function StudentsPanelSkeleton() {
	return (
		<>
			{new Array(5).fill(null).map((_, index) => (
				<SkeletonShape width="240px" height={40} key={index} />
			))}
		</>
	);
}
