import {ExpandablePanel as ExpandablePanelUi} from '@esgi/ui/layout';
import {ExpandablePanelContent, ExpandablePanelStyled, SectionBox} from './styled';
import {Dispatch, PropsWithChildren} from 'react';
import {IconComponent} from '@esgi/ui';
import {ExpandablePanelIcon} from './icon';
import {CSS} from '@stitches/react';

type Props = PropsWithChildren<{
	onClick?: VoidFunction;
	loaded?: boolean;
	css?: CSS;
	icon?: IconComponent;
	dataCy?: string;
	expandable?: boolean,
	isOpen?: boolean,
	toggleOpen?: Dispatch<boolean>,
	disableToggling?: boolean,
	isTablet?: boolean,
}>

export function Panel(props: Props) {
	const {
		loaded,
		icon: PanelIcon,
		children,
		css,
		onClick,
		dataCy = 'expandable-panel',
		expandable = false,
		isTablet = false,
	} = props;

	const icon = (panelIsHovered?: boolean) => {

		return PanelIcon && <ExpandablePanelUi.Placeholder dataCy={`${dataCy}-placeholder`}>
			<ExpandablePanelIcon fillColor={panelIsHovered ? 'secondary' : 'primary'}
			                     Icon={PanelIcon}/>
		</ExpandablePanelUi.Placeholder>;
	};

	if(expandable || isTablet) {
		return (
			<SectionBox isTablet={isTablet} css={css} dataCy={`${dataCy}-section-box`}>
				<ExpandablePanelStyled skeleton={!loaded} open={props.isOpen} onClick={onClick} collapsed={!props.isOpen}>
					{({panelIsHovered}) => (
						<>
							<ExpandablePanelUi.Trigger dataCy={`${dataCy}-trigger`} disabled={props.disableToggling}
							                           onClick={(e) => {
														   if(props.toggleOpen) {
															   e.stopPropagation();
															   e.preventDefault();
															   props.toggleOpen(!props.isOpen);
														   }
							                           }}/>
							{icon(panelIsHovered)}
							<ExpandablePanelContent dataCy={`${dataCy}-content`}>{children}</ExpandablePanelContent>
						</>
					)}
				</ExpandablePanelStyled>
			</SectionBox>
		);
	}

	return (
		<SectionBox css={css} dataCy={`${dataCy}-section-box`}>
			<ExpandablePanelStyled skeleton={!loaded} open onClick={onClick} collapsed={false}>
				{({panelIsHovered}) => (
					<>
						{icon(panelIsHovered)}
						<ExpandablePanelContent dataCy={`${dataCy}-content`}>{children}</ExpandablePanelContent>
					</>
				)}
			</ExpandablePanelStyled>
		</SectionBox>
	);
}
