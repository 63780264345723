import React from 'react';
import styles from '../../styles.module.less';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportService} from '../../../../../../services/report-service';
import {Checkbox} from '@esgillc/ui-kit/control';

interface Props {
	reportService: ReportService;
}

export function DetailReportSettingsShowBlock({reportService}: Props) {
	const settingsService = reportService.settingsService;
	const teacher = useBehaviorSubject(settingsService.teacher);
	const includeSummaryNotes = useBehaviorSubject(settingsService.includeSummaryNotes);
	const printInColors = useBehaviorSubject(settingsService.printInColor);
	const includeQuestionNotes = useBehaviorSubject(settingsService.includeQuestionNotes);
	return <div className={join(styles.settingsCell, styles.showBlock)}>
		<span className={styles.title}>Show:</span>
		<div className={styles.resultsSettings}>
			<div>
				<Checkbox
					className={styles.checkbox}
					checked={includeQuestionNotes}
					onClick={() => {
						settingsService.includeQuestionNotes.next(!includeQuestionNotes);
					}}
					id={teacher.includeQuestionNotes.name}
				/>
				<label className={styles.checkboxLabel} htmlFor={teacher.includeQuestionNotes.name}><span>Notes</span></label>
			</div>
			<div>
				<Checkbox
					className={styles.checkbox}
					checked={includeSummaryNotes}
					onClick={() => settingsService.includeSummaryNotes.next(!includeSummaryNotes)}
					id={teacher.includeSummaryNotes.name}
				/>
				<label className={styles.checkboxLabel} htmlFor={teacher.includeSummaryNotes.name}><span>Summary</span></label>
			</div>
			<div>
				<Checkbox
					className={styles.checkbox}
					checked={printInColors}
					onClick={(e) => settingsService.printInColor.next(!printInColors)}
					id={teacher.printInColor.name}
				/>
				<label className={styles.checkboxLabel} htmlFor={teacher.printInColor.name}><span>In Color</span></label>
			</div>
		</div>
	</div>;
}
