import React from 'react';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
}


export const PlayIcon = ({className}: Props) => {
	return (
		<svg className={join(className)} width='110' height='112' viewBox='0 0 110 112' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g filter='url(#filter0_ddd_124_175)'>
				<circle cx='55' cy='54.2608' r='50' fill='#2FB454'/>
				<circle cx='55' cy='54.2608' r='48.5' stroke='white' strokeWidth='3'/>
			</g>
			<path d='M45.0001 79.875C45.0001 80.7618 46.0689 81.2098 46.7013 80.588L74.275 53.4738C74.6735 53.082 74.6735 52.4396 74.275 52.0477L46.7013 24.9336C46.0689 24.3117 45.0001 24.7597 45.0001 25.6466V79.875Z' fill='white'/>
			<defs>
				<filter id='filter0_ddd_124_175' x='0' y='0.260803' width='110' height='111' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
					<feFlood floodOpacity='0' result='BackgroundImageFix'/>
					<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
					<feOffset dy='1'/>
					<feGaussianBlur stdDeviation='2.5'/>
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_124_175'/>
					<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
					<feOffset dy='3'/>
					<feGaussianBlur stdDeviation='2'/>
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/>
					<feBlend mode='normal' in2='effect1_dropShadow_124_175' result='effect2_dropShadow_124_175'/>
					<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
					<feOffset dy='2'/>
					<feGaussianBlur stdDeviation='2'/>
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/>
					<feBlend mode='normal' in2='effect2_dropShadow_124_175' result='effect3_dropShadow_124_175'/>
					<feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_124_175' result='shape'/>
				</filter>
			</defs>
		</svg>
	);
};
