import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TableStudents = styled(GridBox, {
	gridTemplateRows: 'auto 1fr',
	rowGap: '$4',
	overflow: 'hidden',
});

export const TableStudentsRow = styled(GridBox, {
	columnGap: '$4',
	alignItems: 'center',
	gridTemplateColumns: '280px 1fr 1fr 280px 24px',

	variants: {
		withBottomMargin: {
			true: {
				'&:not(:last-child)': {
					marginBottom: 20,
				},
			},
		},
	},
});

export const TableStudentsHeaderCellSortable = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	gap: 2,
});

export const CredentialsButton = styled(Button.Icon, {
	variants: {
		hasCredentials: {
			true: {
				color: '$base',
			},

			false: {
				color: '$negative',
			},
		},
	},
});
