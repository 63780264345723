import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {EventBusManager} from '@esgillc/events';
import {BehaviorSubject, tap, of} from 'rxjs';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {
	ReportFormat,
	ReportType,
	BackgroundGenerationModel,
	BackgroundGenerationRequest,
} from './models';
import {Subject} from '../test-session-details-form/types';

export class AllStudentsService extends BaseService {
	public readonly reportData$ = new BehaviorSubject<any>(null);
	private readonly currentUser = getUser();
	private readonly controller = 'assessments/test-session-details';
	private readonly eventBus = new EventBusManager();

	constructor(
		private hierarchy: HierarchySnapshot,
		private testId: number,
		private classId: number,
		private groupId: number,
		private subject: Subject,
	) {
		super();
	}

	public get hasClass () {
		return this.classId > 0;
	}

	public get hasGroup () {
		return this.groupId > 0;
	}

	public init() {
		const {id: subjectId, type: subjectType} = this.subject;
		return this.httpClient.ESGIApi.post(
			this.controller,
			'all-students',
			{
				subjectID: subjectId,
				subjectType,
				testId: this.testId,
				classId: this.classId,
				userId: this.currentUser.userID,
				groupId: this.groupId,
				fullHierarchy: this.hierarchy,
				globalSchoolYearID: this.currentUser.globalSchoolYearID,
			},
		).pipe(
			tap((data) => {
				this.reportData$.next(data);
			}),
		).subscribe();
	}

	public startReportBackgroundGeneration(format: ReportFormat, type: ReportType) {
		this.getExportOptions(format, type).subscribe(
			(args) => this.startBackgroundGeneration(args),
		);
	}

	public getExportOptions(format: ReportFormat, type: ReportType) {
		const {students} = this.reportData$.value;
		const studentsID = students
			.filter(({currentSessionID}) => currentSessionID !== null)
			.map(({studentID, currentSessionID}) => ({
				studentID,
				currentSessionID: type === ReportType.All ? 0 : currentSessionID,
			}));
		return of({
			format: this.getFileType(format),
			classID: this.classId,
			specialistGroupID: this.groupId,
			studentsID,
			testID: this.testId,
			subject: this.subject.name,
		});
	}

	private startBackgroundGeneration(model: BackgroundGenerationRequest) {
		this.httpClient.ESGIApi.post(
			this.controller,
			'start-background-generation',
			model,
		).subscribe(({reportGuid}: BackgroundGenerationModel) =>
			this.eventBus.dispatch(BackgroundDownloadManagerEvents.Start, {
				reportGuid,
				fileType: this.getFileFormatName(model.format),
				displayRemainingText: true,
			}),
		);
	}

	private getFileType(fileType: ReportFormat): number {
		if (fileType === ReportFormat.SingleFile) {
			return 1;
		}
		return 2;
	}

	private getFileFormatName(fileType: ReportFormat): string {
		if (fileType === ReportFormat.SingleFile) {
			return 'PDF';
		}
		return 'ZIP';
	}
}
