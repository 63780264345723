import React, {useEffect, useMemo} from 'react';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {Language} from '../models';
import {CloseIcon, Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {LanguageTemplateService} from '../services/language-template-service';
import {LanguageTemplateBody} from './components/language-template-body';
import styles from './styles.module.less';
import {Footer} from './components/footer';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {selectedUserID} from '../../../hierarchy/utils';

interface Props {
	hierarchy: HierarchySnapshot,
	readOnly: boolean,
	onLanguageUpdated?: (l: Language) => void,
	language: Language | null
	onClose: () => void
}

export function LanguageTemplateForm(props: Props) {
	const {onClose, readOnly, onLanguageUpdated, language, hierarchy} = props;
	const service = useMemo(() => new LanguageTemplateService(selectedUserID(hierarchy), language), [hierarchy, language]);
	const languageName = useBehaviorSubject(service.languageName);
	const isDirty = useBehaviorSubject(service.isDirty);
	const title = language?.languageId ? 'Edit Template name' : 'Add new';
	const mmRef = useModal();
	const closeModal = useCloseModal(mmRef, onClose);

	function onSave() {
		const name = languageName;
		const languageID = language?.languageId;
		service.form.validate().subscribe(({valid}) => {
			if (valid) {
				if (languageID) {
					service.update().subscribe(() => {
						const updatedLanguage = new Language();
						updatedLanguage.languageId = languageID;
						updatedLanguage.languageName = name;
						onLanguageUpdated(updatedLanguage);
					});
				} else {
					service.create().subscribe((r) => {
						const newLanguage = new Language();
						newLanguage.languageId = r.languageID;
						newLanguage.languageName = name;
						newLanguage.isDefault = false;
						onLanguageUpdated(newLanguage);
					});
				}
				closeModal();
			}

		});
	}

	return <Modal modalManagerRef={mmRef}>
		<ServiceLoader trackingService={service} fullscreen/>
		<Modal.Header className={styles.modalHeader}>
			{title}
			<CloseIcon color='white' onClick={closeModal}/>
		</Modal.Header>
		<LanguageTemplateBody service={service}/>
		<Footer isDirty={isDirty} closeModal={closeModal} readOnly={readOnly} onSave={onSave}/>
	</Modal>;
}
