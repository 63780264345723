import {SightWords as SightWordsUI, useSightWords, SightWordsContext} from '@esgi/main/features/teacher/sight-words';
import {Activities, ExpandablePanel} from '@esgi/main/kits/activities';
import {useSightWordsState} from './hooks';
import {useActivitiesContext} from '../layout/outlet-context';
import {SubjectTab} from '@esgi/main/libs/store';
import {Page, LeftSidebar, RightSidebar, Header, Divider} from '../layout/index.styled';
import {Content} from './index.styled';
import {AddTestDrawer} from '@esgi/main/features/teacher/drawers';

export function SightWords() {
	const {snackbarRef} = useActivitiesContext();
	const {service} = useSightWords();
	const {
		preview,
		setPreview,
		showAddTestsDrawer,
		setShowAddTestsDrawer,
		valid,
		busy,
		classes,
		groups,
		studentList,
		loadedStudents,
		totalPages,
		subjects,
		loadedSubjects,
		subject,
		students,
	} = useSightWordsState(service);

	return (
		<SightWordsContext.Provider value={service}>
			<Page>
				<LeftSidebar dataCy='left-sidebar'>
					<Activities.StudentsControl
						skeleton={!loadedStudents}
						classes={classes}
						groups={groups}
						students={studentList}
						onChange={(value) => service.setStudents(value)}
					/>
				</LeftSidebar>
				<Content dataCy='sight-words-content'>
					<Header dataCy='content-header'>
						<Activities.StudentPanel
							counterTitle='sight words'
							studentCounter={students.length}
							student={service.studentService.get(students.length && students[0].studentId)}
						/>
						<Divider/>
						<Activities.SubjectControl
							skeleton={!loadedSubjects}
							subjects={subjects}
							value={subject.toString()}
							onChange={(value) => service.setSubject(+value)}
							data-cy='subject-control'
						/>
					</Header>
					<SightWordsUI.TestsControl
						onChange={(value) => service.setQuestions(value)}
						onAllTestClick={() => setShowAddTestsDrawer(true)}
					/>
				</Content>
				<RightSidebar>
					<Activities.DownloadPanel
						disabled={!valid || busy}
						onPreview={() => {
							service.onPreview();
							setPreview(true);
						}}
						onDownload={() => service.onDownload()}
					/>
					<ExpandablePanel>
						<Activities.FontControl onChange={(value) => service.setFont(value)}/>
						<Activities.CopiesControl onChange={(value) => service.setCopies(value)}/>
						<Activities.FileTypeControl
							visibleToggler={false}
							onChange={(value) => service.setFileType(value)}
						>
							<Activities.CopiesPanel>{totalPages}</Activities.CopiesPanel>
						</Activities.FileTypeControl>
					</ExpandablePanel>
				</RightSidebar>
			</Page>
			{preview &&
				<SightWordsUI.PreviewDialog
					onClose={() => setPreview(false)}
					onDownload={() => service.onDownload()}
				/>
			}
			{showAddTestsDrawer &&
				<AddTestDrawer<SubjectTab>
					onClose={() => setShowAddTestsDrawer(false)}
					subject={subjects.find(({id}) => id === subject)}
					snackbarRef={snackbarRef}
				/>
			}
		</SightWordsContext.Provider>
	);
}

export default SightWords;
