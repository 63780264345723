import React from 'react';
import './component.less';
import {DroppableContainer} from '@esgillc/ui-kit/drag-n-drop';
import {DragDropContext} from 'react-beautiful-dnd';
import GradeScaleEntry from 'shared/modules/grade-scale/grade-scale/info/entry/component';
import {IGradeScaleEntryForm} from 'shared/modules/grade-scale/grade-scale/models';
import {GradeScaleService} from 'shared/modules/grade-scale/grade-scale/service';
import {Link} from '@esgi/deprecated/elements/buttons/link';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';
import {GradeScaleWizardType} from 'shared/modules/grade-scale/models';

export class Props {
	service: GradeScaleService;
}

export class State {
	showValidation: boolean;
	canModify: boolean;
	entries: IGradeScaleEntryForm[] = [];
}

export default class Info extends React.PureComponent<Props, State> {
	ref: HTMLDivElement;
	entryRowHeight: number = 50;
	
	constructor(props) {
		super(props);
		this.state = new State();
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	
	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		
		this.props.service.validationStatus$.subscribe((result) => {
			const entries = result[0];
			const notValidEntries = result[1];

			this.setState({entries: entries}, () => {
				if (notValidEntries.length > 0) {
					const top = notValidEntries[0].orderNumber * this.entryRowHeight;

					this.scrollToEntry(top).subscribe(() => {
						this.setState({showValidation: true});
					});
				}
			});
		});

		this.props.service.selectedEntries$.subscribe((entries) => {
			const needScroll = this.state.entries.length === 0 ? false : this.state.entries.length !== entries.length;
			this.setState({entries: entries}, () => {
				if (needScroll) {
					const top = entries.length * this.entryRowHeight;
					this.scrollToEntry(top);
				}
			});
		});
		
		this.props.service.selectedScale$.subscribe((scale) => {
			if (scale) {
				this.setState({canModify: scale.wizardType !== GradeScaleWizardType.Personal});	
			}
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	
	get canAdd() {
		return this.state.canModify && this.state.entries.length < 10;
	}

	get canRemove() {
		return this.state.canModify && this.state.entries.length > 2;
	}
	
	onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		this.props.service.orderEntries(result.source.index, result.destination.index);
	}

	get tableTop() {
		return this.ref ? this.ref.getBoundingClientRect().top : 0;
	}

	get tableHeight() {
		return this.ref ? this.ref.getBoundingClientRect().height : 0;
	}

	onScroll() {
		this.setState({showValidation: false});
	}

	scrollToEntry(top: number) {
		if (!this.ref){
			return of(null);
		}
		
		this.ref.scrollTop = top;
		return of(null).pipe(delay(100));
	}

	handleClickOutside(event) {
		if (this.ref) {
			this.setState({showValidation: false});
		}
	}
	
	render() {
		return <div className='grade-scale-info'>
			<div className='grade-scale-header'>
				Grade Description
			</div>
			<div className='grade-scale-th'>
				<div className='grade-scale-td order'>Order</div>
				<div className='grade-scale-td name'>Grade<span>high to low</span></div>
				<div className='grade-scale-td color'>Color</div>
				<div className='grade-scale-td description'>Description</div>
			</div>
			{this.state.entries.length > 0 &&
			<>
				<div className='grade-scale-list entries-list' ref={r => this.ref = r} onScroll={() => this.onScroll()}>
					<DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
						<DroppableContainer droppableId='entries' type='entry'>
							{this.state.entries.sort((a, b) => a.orderNumber - b.orderNumber).map((entry, i) => {
								return <GradeScaleEntry
									key={i}
									service={this.props.service}
									canRemove={this.canRemove}
									showValidation={this.state.showValidation}
									entry={entry}
									containerHeight={this.tableHeight}
									containerTop={this.tableTop}
								/>;
							})}
						</DroppableContainer>
					</DragDropContext>
				</div>
				{this.canAdd &&
				<div className='add-another-container'>
					<Link
						onClick={() => this.props.service.addNewEntry()}
						className='grade-entry-component-button'
						title='Add another'>
						<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
							<path
								d='M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
								fill='#0088CC'/>
						</svg>
					</Link>
				</div>}
			</>}
		</div>;
	}
}
