import {HierarchySnapshot} from '../../../hierarchy/models';
import {dispatchAppEvent} from '@esgillc/events';
import {useEffect} from 'react';
import {SsoTracker} from '@esgi/core/tracker';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {ViewFlashCardsSettingsEvent} from '../events';
import {InitModel} from '../models';
import {SettingsFormModal} from '../settings-form-modal';

interface Props {
	initModel: InitModel,
	changesCollector: ChangesCollector,
	onClosed: () => void,
	hierarchy: HierarchySnapshot,
}

function ParentLetterLauncher(props: Props) {
	const {initModel, onClosed, changesCollector, hierarchy} = props;

	function goToFlashCardsClicked() {
		const {
			classID,
			groupID,
			studentID,
			subjectTabID,
			subjectTabType,
		} = changesCollector;
		const fcInitModel = new InitModel(
			(classID.value !== 0) ? classID.value : groupID.value,
			studentID.value,
			subjectTabID.value,
			subjectTabType.value);
		dispatchAppEvent(ViewFlashCardsSettingsEvent, new ViewFlashCardsSettingsEvent(fcInitModel, changesCollector));
		onClosed();
	}
	function load() {
		SsoTracker.trackEvent({
			trackingEvent: 'ParentLetter2020',
		});
	}

	useEffect(() => load(), [initModel]);

	return <SettingsFormModal onModalClose={onClosed} onSettingsFormClose={onClosed}
	                          hierarchy={hierarchy} selectedItems={initModel}
	                          goToFlashCardsClicked={goToFlashCardsClicked} changesCollector={changesCollector}
	/>;
}

export default ParentLetterLauncher;
