import {useCallback, useEffect, useMemo, useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {
	CloseIcon,
	Modal,
	Title,
	useCloseModal,
	useModal,
} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject, join, OsChecker} from '@esgillc/ui-kit/utils';
import {IEPGoalFormService} from '../../services';
import {FormElement} from '@esgillc/ui-kit/form';
import {Input, Hint} from '@esgillc/ui-kit/form-control';
import {IEPStatusModel} from '../../models';
import {IEPStatusComponent} from './components/status';
import RemoveStatusDialog from '../dialogs/remove-status-dialog';
import {Draggable, DragDropContext, DropResult} from 'react-beautiful-dnd';
import {DroppableContainer} from '@esgillc/ui-kit/drag-n-drop';
import {HelpIcon} from 'shared/page/left-menu/icons';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {ErrorText} from 'modules/forms/students-form/components/error-text';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import styles from './styles.module.less';

const MIN_LENGTH = 1;
const MAX_LENGTH = 50;

interface Props {
	service: IEPGoalFormService;
	onClose: () => void;
}

export default function IepStatusFormModal({service, ...props}: Props): JSX.Element {
	const modal = useModal();
	const onClose = useCloseModal(modal, () => {
		props.onClose();
		service.resetStatusFormData();
	});
	const dbStatuses = useBehaviorSubject(service.iepStatuses$);
	const [statuses, setStatuses] = useState([...dbStatuses]);
	const [showRemoveAlert, setShowRemoveStatusAlert] = useState(false);
	const [removableStatus, setRemovableStatus] = useState(null);
	const isMac = useMemo(() => OsChecker.isMac(), []);
	const [isTouched, setIsTouched] = useState<boolean>(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const activeStatuses = useMemo(
		() => statuses.filter(({isDeleted}) => !isDeleted).map((s, index) => ({...s, order: index})),
		[statuses],
	);

	useEffect(() => {
		service.initStatusFormValidators(statuses);

		const sub = service.statusForm.controls.statusName.onChanged.subscribe(
			({reason, currState: {value}}) => {
				if (reason === 'value') {
					setButtonDisabled(value.length < MIN_LENGTH);
				}
			},
		);
		return () => sub.unsubscribe();
	}, []);

	const onCloseIconClick = () => {
		onClose();
		setTimeout(() => {
			service.restoreStatuses();
			setStatuses([...service.iepStatusesSnapshot].map(status => ({...status})).map((status) => {
				status.isDeleted = false;
				return status;
			}));
		}, 1000);
	};

	const onSave = () => {
		service.saveIEPStatuses(statuses, setStatuses).finally(() => {
			onClose();
		});
	};

	const onAddStatus = useCallback(() => {
		setIsTouched(true);
		service.onAddIEPStatus(statuses, setStatuses);
	}, [statuses]);

	const onRemoveStatus = useCallback((statusID: number, statusName: string) => {
		closeRemoveStatusAlert();
		setIsTouched(true);
		let newStatuses = [...statuses];

		if (statusID > 0) {
			newStatuses.forEach(status => {
				if (status.id === statusID) {
					status.isDeleted = true;
				}
			});
		} else {
			newStatuses = newStatuses.filter(
				({name}) => name.toLowerCase() !== statusName.toLowerCase(),
			);
		}

		setStatuses([...newStatuses]);
		service.reinitializeStatusFormValidators([...newStatuses]);
	}, [statuses]);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		const startIndex = result.source.index;
		const endIndex = result.destination.index;

		const reorderedObj = activeStatuses.find(x => x.order === startIndex);
		let otherStatuses = activeStatuses.filter(x => x.order !== startIndex);
		reorderedObj.order = endIndex;
		otherStatuses = otherStatuses.map((s) => {
			if (startIndex < endIndex) {
				if (s.order <= endIndex && s.order >= startIndex) {
					s.order -= 1;
				}
			} else if (startIndex >= endIndex && s.order <= startIndex) {
				if (s.order >= endIndex) {
					s.order += 1;
				}
			}
			return s;
		});

		const newStatuses = [...otherStatuses, reorderedObj].sort((left, right) => {
			return left.order > right.order ? 1 : -1;
		});
		setStatuses(newStatuses);
	};

	const onKeyDown = ({code, key, keyCode, which}) => {
		if (code === 'Enter' || key === 'Enter' || keyCode === 13 || which === 13) {
			onAddStatus();
		}
	};

	const showRemoveStatusAlert = (status: IEPStatusModel) => {
		setShowRemoveStatusAlert(true);
		setRemovableStatus(status);
	};

	const closeRemoveStatusAlert = () => {
		setShowRemoveStatusAlert(false);
		setRemovableStatus(null);
	};

	return (
		<>
			{showRemoveAlert &&
				<RemoveStatusDialog
					status={removableStatus}
					onRemoveStatus={onRemoveStatus}
					onClose={closeRemoveStatusAlert}
				/>
			}
			<Modal
				className={styles.modal}
				modalManagerRef={modal}
				onCatchError={onClose}
			>
				<ServiceLoader trackingService={service}/>
				<Modal.Header>
					<Title className={styles.headerText}>IEP Status</Title>
					<CloseIcon
						color='#666666'
						onClick={onCloseIconClick}
					/>
				</Modal.Header>
				<Modal.Body className={styles.body}>
					<p className={styles.bodyText}>{'Please enter the statuses you expect to use. '}
						<OnHoverTooltip message='These statuses will be available for all specialists to use.'>
							<HelpIcon fill='rgb(130, 130, 130)'/>
						</OnHoverTooltip></p>
					<FormElement
						control={service.statusForm.controls.statusName}
						className={styles.inputWithErrorText}
					>
						<div className={styles.label}>Status</div>
						<div className={styles.inputContainer}>
							<div>
								<Input
									className={styles.statusName}
									placeholder='Enter status, i.e. Mastered'
									minLength={MIN_LENGTH}
									maxLength={MAX_LENGTH}
									onKeyDown={onKeyDown}
								/>
								<Hint className={styles.limitCounter}>
									{({value}) => `${MAX_LENGTH - (value?.length | 0)} characters left (${MIN_LENGTH} min, ${MAX_LENGTH} max)`}
								</Hint>
							</div>
							<Buttons.Icon
								className={styles.addButton}
								disabled={buttonDisabled}
								onClick={onAddStatus}
							>
								<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'
								     fill='none'>
									<path
										d='M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z'
										fill={buttonDisabled ? '#979797' : '#0088CC'}
									/>
								</svg>
							</Buttons.Icon>
						</div>
						<ErrorText showOnError='isDublicateValue'>
							A status with this name already exists
						</ErrorText>
					</FormElement>
					<span className={styles.availableStatusesLable}>Available Statuses:</span>
					<ul className={join(styles.statusesList, activeStatuses.length === 0 && styles.emptyList)}>
						{activeStatuses.length === 0 &&
							<li>None</li>
						}
						{activeStatuses.length !== 0 &&
							<DragDropContext onDragEnd={onDragEnd}>
								<DroppableContainer
									droppableId={'iep-statuses'}
									type={'iep-status'}
									cursor={isMac && 'grabbing' || 'move'}
								>
									{activeStatuses.map((s, i) => {
										return (
											<Draggable
												key={`${s.id}-${s.name}-${s.order}-${s.isDeleted}`}
												draggableId={s.id.toString()}
												index={s.order}
											>
												{(provided) => (
													<li
														key={s.name}
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<IEPStatusComponent
															status={s}
															onRemoveStatus={showRemoveStatusAlert}
														/>
													</li>
												)}
											</Draggable>
										);
									})}
								</DroppableContainer>
							</DragDropContext>
						}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					{isTouched
						? <Buttons.Contained onClick={onSave}>
							Save
						</Buttons.Contained>
						: <Buttons.Contained onClick={onClose}>
							Close
						</Buttons.Contained>}
				</Modal.Footer>
			</Modal>
		</>
	);
}
