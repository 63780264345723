import {Alert} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';
import {userStorage} from '@esgi/core/authentication';
import {Buttons} from '@esgillc/ui-kit/button';
import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Link} from 'react-router-dom';
import {environment} from '@esgi/core/environments';
import {Checkbox} from '@esgillc/ui-kit/control';
import {useCallback, useState} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {CrossIcon} from '@esgillc/ui-kit/icons';
import {AssignmentCardService} from '../../services/assignment-card-service';
import {useService} from '@esgi/core/service';
import {StudentCardsService} from 'pages/assignment-center/services/service';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

type TeacherInstructionsProps<Service extends AssignmentCardService> = {
	assignmentName: string;
	assignmentID: number;
	onModalClose: () => void;
	service: Service;
	isCardsDownloadingDisabled: boolean;
};

export function TeacherInstructions<Service extends AssignmentCardService>({
	assignmentName,
	onModalClose,
	service,
	assignmentID,
	isCardsDownloadingDisabled,
}: TeacherInstructionsProps<Service>) {
	const [isChecked, setIsChecked] = useState(service.localStorage.isBannedModal);
	const credentialsService = useService(StudentCardsService);
	const alertRef = useModal();
	const onCloseAlert = useCloseModal(alertRef, onModalClose);

	const downloadCredentials = useCallback(() => {
		credentialsService.byAssignment(assignmentID).subscribe();
	}, []);

	const onCheckboxClick = useCallback(() => {
		setIsChecked((isChecked) => {
			service.localStorage.setBanModal(!isChecked);

			return !isChecked;
		});
	}, [service.localStorage]);

	return (
		<Alert className={styles.alert} modalManagerRef={alertRef}>
			<ServiceLoader trackingService={credentialsService} fullscreen />
			<Alert.Header className={styles.header}>
				<Title>Instructions for Student Assess</Title>
				<p>
					Assignment: <strong>{assignmentName}</strong>
				</p>
				<Buttons.Icon className={styles.closeCrossButton} onClick={onCloseAlert}>
					<CrossIcon />
				</Buttons.Icon>
			</Alert.Header>
			<Alert.Body className={styles.body}>
				<h3>Steps to begin assignment on student devices:</h3>
				<div>
					<p data-step-number='1.'>
						<OnHoverTooltip message={isCardsDownloadingDisabled ? 'Please add student credentials to print cards.' : null} >
							<Buttons.Link disabled={isCardsDownloadingDisabled} className={styles.downloadButton} onClick={downloadCredentials}>
								Download
							</Buttons.Link>
						</OnHoverTooltip>
						Student sign in cards with students user name and password.
					</p>
					<p data-step-number='2.'>
						Go to&nbsp;
						<Link to={`${environment.url}/student/`} target='_blank' rel='noopener noreferrer' className={styles.link}>
							www.esgiselfassess.com
						</Link>
					</p>
					<p data-step-number='3.' className={styles.districtId}>
						Enter District ID: <strong>{userStorage.get().districtID}</strong>
					</p>
					<p data-step-number='4.'>Enter student username and password.</p>
				</div>
				<div className={styles.checkboxContainer}>
					<Checkbox
						checked={isChecked}
						className={join(styles.checkbox, isChecked && styles.checkboxIsChecked)}
						onClick={onCheckboxClick}
					/>
					<p>Don’t show this again.</p>
				</div>
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={onCloseAlert}>Got It</Buttons.Text>
			</Alert.Footer>
		</Alert>
	);
}
