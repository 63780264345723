import {Setup} from '@esgi/ui/icons';
import {FinishedSetup} from './finished-setup';
import {TestStatus} from '../../../../types';
import {IndicatorWrapper} from './index.styled';

interface Props {
	status: TestStatus
}

export function TestStatusIndicator({status}: Props) {

	if (status !== TestStatus.Saved) {
		return <IndicatorWrapper status={status}>
			<Setup width={24} height={24}/>
		</IndicatorWrapper>;
	}

	return <FinishedSetup width={24} height={24}/>;
}
