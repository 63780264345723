import {Dispatch, useCallback} from 'react';
import {Section, Title, Body, ToggleGroupRoot, ToggleGroupItem, Label} from '../../index.styled';
import {ReportDisplayResultsMode} from '../../types';
import {ToggleGroupContent} from './index.styled';
import {adaptedToStringDisplayResultsMode, items} from './constants';

interface Props {
	disabled: boolean;
	onChange: Dispatch<ReportDisplayResultsMode>;
	value: ReportDisplayResultsMode;
	gradeDisabled: boolean;
}

export function DisplayTypeControl({disabled, onChange, value, gradeDisabled}: Props) {
	const handleValueChange = useCallback(
		(newValue: ReportDisplayResultsMode) => {
			onChange(newValue);
		},
		[onChange],
	);

	return (
		<Section>
			<Title data-cy='title' size='small' font='mono' color='lowContrast'>
				Type
			</Title>
			<Body columns={1}>
				<ToggleGroupRoot data-cy='type-option-tabs' value={adaptedToStringDisplayResultsMode[value]} disabled={disabled}>
					<ToggleGroupContent>
						{items.map(({value, label}) => (
							<ToggleGroupItem
								value={adaptedToStringDisplayResultsMode[value]}
								onClick={() => handleValueChange(value)}
								key={value}
								disabled={value === ReportDisplayResultsMode.Grade && gradeDisabled}
							>
								<Label size='medium' bold>
									{label}
								</Label>
							</ToggleGroupItem>
						))}
					</ToggleGroupContent>
				</ToggleGroupRoot>
			</Body>
		</Section>
	);
}
