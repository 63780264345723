import {ToggleGroup as ToggleGroupRadix, ToggleGroupSingleProps} from '@radix-ui/react-toggle-group';
import {styled} from '@esgi/ui/theme';
import {ComponentPropsWithoutRef, forwardRef, useMemo, useState} from 'react';
import {CSS} from '@stitches/react';
import {Skeletonable} from '../../../../types';
import {ToggleGroupContext, ToggleGroupValueContextValue} from '../../context';
import {useAutoControlledState} from '@esgi/ui/utils';
import {ToggleGroupOrientation} from '../../types';

type ToggleGroupProps = ComponentPropsWithoutRef<'div'> &
	Omit<ToggleGroupSingleProps, 'type' | 'orientation'> & {
		/** Mandatory data attribute used by test automation team. */
		dataCy?: string;

		/** Returns a Style interface from a configuration, leveraging the given media and style map. */
		css?: CSS;

		/**
		 * The orientation of the group.
		 * Mainly so arrow navigation is done accordingly (left & right vs. up & down)
		 */
		orientation?: ToggleGroupOrientation;
	} & Skeletonable;

export const ToggleGroupRoot = forwardRef<HTMLDivElement, ToggleGroupProps>(
	({dataCy, skeleton, ...props}, forwaredRef) => {
		const [valueDescriptionSelected, setValueDescriptionSelected] = useState<string>();
		const [valueDescriptionOnHover, setValueDescriptionOnHover] = useState<string>();

		const [selectedValue, setSelectedValue] = useAutoControlledState({
			initialState: undefined,
			controlledState: props.value,
		});

		const [hoveredValue, setHoveredValue] = useState<string>();

		const contextValue = useMemo<ToggleGroupValueContextValue>(
			() => ({
				orientation: props.orientation,
				valueDescriptionSelected,
				setValueDescriptionSelected,
				valueDescriptionOnHover,
				setValueDescriptionOnHover,
				selectedValue,
				setSelectedValue,
				hoveredValue,
				setHoveredValue,
			}),
			[
				valueDescriptionSelected,
				setValueDescriptionSelected,
				valueDescriptionOnHover,
				setValueDescriptionOnHover,
				props.orientation,
				hoveredValue,
				setHoveredValue,
				selectedValue,
				setSelectedValue,
			],
		);

		return (
			<ToggleGroupContext.Provider value={contextValue}>
				<ToggleGroup data-cy={dataCy ?? 'ui-kit-toggle-group'} type='single' ref={forwaredRef} {...props} />
			</ToggleGroupContext.Provider>
		);
	},
);

const ToggleGroup = styled(ToggleGroupRadix, {
	position: 'relative',
	width: '100%',
});
