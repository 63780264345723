import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from '../styles.module.less';

interface Props {
	onClicked: () => void;
}

export function IncorrectFileType({onClicked}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>Incorrect File Type</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<span data-cy={'incorrect-file-type'} className={styles.text}>
				Accepted formats include csv and xlsx. Please try again.
			</span>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}