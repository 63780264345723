import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isUndefined} from 'underscore';
import {TestModel} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/pages/reports/total/models/test-model';
import {DisplayResults} from '@esgi/main/kits/reports';
import {Cell as TableCell, MarkingPeriod, NotTested, Report, Row} from '../../../../service/types';
import {StudentSort, useUser} from '@esgi/core/authentication';
import {ClassTotalsService} from '../../../../service';
import {SortOptions} from '../../types';

export function useSortableTable(
	service: ClassTotalsService,
	report: Report | null,
	markingPeriod: MarkingPeriod,
	displayType: DisplayResults,
	displayNotTestedType: NotTested) {

	const [sortableOptions, setSortableOptions] = useState<SortOptions>({
		direction: 'asc',
		columnIndex: 0,
	});

	const user = useUser();

	useEffect(() => {
		setSortableOptions((currentState) => {
			const columnIndex = user?.studentSort === StudentSort.FirstName ? 0 : 1;

			return {
				columnIndex: columnIndex,
				direction: 'asc',
			};
		});
	}, []);

	const [previousSortedColumnIndex, setPreviousSortedColumnIndex] = React.useState(0);
	const onSortableCellClick = useCallback((columnIndex: number) => {
		setSortableOptions((currentState) => {
			if (previousSortedColumnIndex !== columnIndex) {
				setPreviousSortedColumnIndex(columnIndex);
			}

			if (currentState.columnIndex === columnIndex) {
				return {
					columnIndex,
					direction: currentState.direction === 'asc' ? 'desc' : 'asc',
				};
			}

			return {
				columnIndex,
				direction: 'asc',
			};
		});
	}, []);

	const sortableRows = useMemo(() => {
		const rows: Row[] = report?.table?.rows ?? [];

		if (rows.length === 0) {
			return [];
		}

		return [...rows].sort((a, b) => {
			const aCells = a.cells;
			const bCells = b.cells;

			const aCell = aCells[sortableOptions?.columnIndex] as TableCell;
			const bCell = bCells[sortableOptions?.columnIndex] as TableCell;

			const aValue = (aCell.avgPercent != null ? aCell.avgPercent : aCell.value).toString();
			const bValue = (bCell.avgPercent != null ? bCell.avgPercent : bCell.value).toString();

			if (isUndefined(aValue) || isUndefined(bValue)) {
				return 0;
			}

			// Handle "NT" as 0
			const aValueAdjusted = aValue === 'NT' ? '0' : aValue;
			const bValueAdjusted = bValue === 'NT' ? '0' : bValue;

			const aNumberedValue = Number(aValueAdjusted);
			const bNumberedValue = Number(bValueAdjusted);

			// Check if values are non-numeric
			const aIsNonNumeric = isNaN(aNumberedValue);
			const bIsNonNumeric = isNaN(bNumberedValue);

			if (aIsNonNumeric && bIsNonNumeric) {
				// Both values are non-numeric, sort as strings
				return sortableOptions?.direction === 'asc' ? aValueAdjusted.localeCompare(bValueAdjusted) : bValueAdjusted.localeCompare(aValueAdjusted);
			} else {
				// Both values are numeric, sort as numbers
				return sortableOptions?.direction === 'asc' ? aNumberedValue - bNumberedValue : bNumberedValue - aNumberedValue;
			}
		});
	}, [report, sortableOptions]);

	useEffect(() => {
		service.setSortedRowIDs(sortableRows?.map(r => r.enitityID));
	}, [service, sortableRows]);

	const showOutOf = useCallback((test: TestModel) => {
		return test.id > 0 && displayType === DisplayResults.Score;
	}, [displayType]);

	const cellRender = useCallback((cell: TableCell) => {
		if (displayNotTestedType === NotTested.Zero && cell.value === 'NT') {
			return '0';
		} else if (cell.avgPercent != null) {
			if(displayNotTestedType === NotTested.NT && report.carryScoresForward) {
				return cell.value === 'NT' ? 'NT' : cell.percent;
			} else {
				return cell.value === 'NT' ? 'NT' : cell.avgPercent;
			}
		} else {
			if (displayType === DisplayResults.Score) {
				return cell.value;
			} else {
				return cell.percent;
			}
		}
	}, [displayNotTestedType, displayType, report]);

	const isCellShown = useCallback((index: number) => {
		if (isNaN(index)) {
			return false;
		}

		if (markingPeriod === MarkingPeriod.All){
			return true;
		}

		return (index * report.maxMarkingPeriods + 1) % (report?.maxMarkingPeriods + 1) === 0;
	}, [markingPeriod, report]);

	return {onSortableCellClick, sortableRows, showOutOf, cellRender, isCellShown, sortableOptions, previousSortedColumnIndex};
}