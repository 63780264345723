import {AxiosResponse} from 'axios';

type Callback = (event: Readonly<AxiosResponse>) => void;

/* The HttpEventBus class is a singleton that allows subscribing, unsubscribing, and dispatching events
to callbacks. */
export class HttpEventBus {
	private static _instance: HttpEventBus
	private subscriptions: Callback[] = [];

	public static get instance(): HttpEventBus {
		let instance = this._instance;
		if (!instance) {
			this._instance = instance = new HttpEventBus();
		}
		return instance;
	}

	protected constructor() {
	}

	public subscribe(callback: Callback) {
		this.subscriptions.push(callback);
	}

	public unsubscribe(callback: Callback) {
		this.subscriptions = this.subscriptions.filter(cll => cll !== callback);
	}

	public dispatch(event: AxiosResponse) {
		for (let sub of this.subscriptions) {
			try {
				sub(event);
			} catch (e) {
				console.error(e);
			}
		}
	}
}

/* The HttpEventBusManager class manages subscriptions and unsubscriptions to an HttpEventBus. */
export default class HttpEventBusManager {
	protected callbacks: Callback[] = [];

	/**
	 * The `subscribe` function adds a callback to the list of callbacks and subscribes of the
	 * HttpEventBus.
	 * @param {Callback} callback - The callback parameter is a function that will be executed when an
	 * event occurs. It is a function that takes an argument of type T and returns void.
	 */
	public subscribe<T>(callback: Callback): void {
		this.callbacks.push(callback);
		HttpEventBus.instance.subscribe(callback);
	}

	/**
	 * The `destroy` function unsubscribes all callbacks from the `HttpEventBus` instance.
	 */
	public destroy(): void {
		for (let callback of this.callbacks) {
			HttpEventBus.instance.unsubscribe(callback);
		}
	}

}
