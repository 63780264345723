import React from 'react';

class Props {
	total: number;
}

export default class PagingInfo extends React.PureComponent<Props> {
	render() {
		return <div className='paging-info'>
			{this.props.total.toLocaleString()} results
		</div>;
	}
}
