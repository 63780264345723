import React, {ReactNode} from 'react';
import {useState} from '@esgi/deprecated/react';
import DataService from '../../../../../../services/data-service';
import StudentsService from '../../../../../../services/students-service';
import {getEventCoord} from '../utils';
import AssignButton from './assign-button';
import AssignService from './assign-service';
import AssignPopup from './assign-popup/assign-popup';

class State {
	firstTimeSelected: boolean = null;
	moveStudentOpened: boolean = false;
}

class Props {
	dataService: DataService;
	studentsService: StudentsService;
	selectedStudentIDs: number[];
	selectedStudentsSchoolsIDs: number[];
}

@useState(State)
export default class AssignOperation extends React.PureComponent<Props, State> {
	private assignService = new AssignService(this.props.dataService, this.props.studentsService);

	public render() {
		return <>
			<AssignButton hasSelectedStudents={!!this.props.selectedStudentIDs.length}
			              onClicked={(e) => this.moveClicked(e)}/>
			{this.renderModal()}
		</>;
	}

	private renderModal(): ReactNode {
		if (this.state.moveStudentOpened) {
			return <AssignPopup close={() => this.setState({moveStudentOpened: false})}
			                    schools={this.props.dataService.data.schools}
			                    selectedStudentIDs={this.props.selectedStudentIDs}
			                    selectedStudentsSchoolsIDs={this.props.selectedStudentsSchoolsIDs}
			                    specialists={this.props.dataService.data.specialists}
			                    assignService={this.assignService}
			/>;
		}
	}

	private moveClicked(e): void {
		const {top, left} = getEventCoord(e);
		this.setState({moveStudentOpened: true}, () => {
			$('.move_student_dialog .modal-dialog')
				.css('top', top + 'px')
				.css('left', left + 'px');
		});
	}

	public componentWillUnmount() {
		this.assignService.destroy();
	}
}
