import {TestType} from '@esgi/main/libs/core';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {Translations} from '../../types';

export class RequestResultsInit {
	reportGuid: string;
	teacherID: number;
	subject: ResponseSubject;
	globalSchoolYearID: number;
	includeTestNotes: boolean;
	includeSummaryNotes: boolean;
	includeLetter: boolean;
	includeGradeScale: boolean;
	printInColor: boolean;
	showSessionDate: boolean;
	includeUntestedQuestions: boolean;
	tests: RequestTest[];
	studentIDs: number[];
	allStudentsSelected: boolean;
	groupItemID: number;
	groupItemLevel: string;
	looseMode: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	hierarchy: HierarchySnapshot;
}

export class RequestTest {
	testID: number;
	max?: number;
	testDate?: string;
}

export class ResponseGroupItem {
	name: string;
	itemType: string;
}

export class ResponseUser {
	userID: number;
	firstName: string;
	lastName: string;
	title: string;
}

export interface ResponseTest {
	id: number;
	name: string;
	type: string;
	correct: number;
	incorrect: number;
	untested: number;
	orderNum: number;
	questionsCount: number;
	testDate?: string;
	dataExists: boolean;
}

export class ResponseResultsInit {
	groupItem: ResponseGroupItem;
	user: ResponseUser;
	logo: ResponseResultsLogoModel;
	letterTemplates: ResponseResultsLetterTemplate[];
	gradeScaleEntries: ResponseResultsGradeScaleEntry[];
	reportDate: string;
	subject: string;
	reportGuid: string;
	showSessionDate: boolean;
	translations: Translations;
	students: ResponseResultsStudent[];
}

export class ResponseResultsGroupItem {
	name: string;
	itemType: string;
}

export class ResponseResultsLogoModel {
	base64: string;
	contentType: string;
}

export class ResponseResultsStudent {
	firstName: string;
	lastName: string;
	studentIDN: string;
	studentID: number;
	languageID: number;
	testResults: ResponseResultsTestResult[];
}

export class ResponseResultsLetterTemplate {
	letterText: string;
	languageID: number;
}

export class ResponseResultsTestResult {
	testID: number;
	testType: TestType;
	testName: string;
	questionsTotal: number;
	answers: ResponseResultsAnswer[];
	gradeScale: ResponseResultsGradeScaleEntry;
	fullwidth: boolean;
	isSkip: boolean;
	summaryNotes: string;
	testDateString: string;
}

export class ResponseResultsAnswer {
	questionName: string;
	questionNote: string;
	score: number;
}

export class ResponseResultsUser {
	firstName: string;
	lastName: string;
	title: string;
	userID: number;
}

export class ResponseResultsGradeScaleEntry {
	name: string;
	description: string;
	color: string;
}

export class ResponseSubject {
	name: string;
	subjectID: number;
	subjectType: string;
	subjectLevel: string;
	hasScales?: boolean;
}

export enum FileType {
	PDF, Word, ZIP
}



