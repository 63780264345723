import {Dictionary} from 'modules/forms/students-form/types';
import {lunchStatusNames} from './enums/lunch-status';
import {raceNames} from './enums/race';
import {genderNames} from './enums/gender';
import {specialProgramNames} from './enums/special-program';

export const dictionary: Dictionary = {
	lunchStatuses: lunchStatusNames(),
	languages: [],
	gradeLevels: [],
	races: raceNames(),
	genders: genderNames(),
	specialPrograms: specialProgramNames(),
};
