import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';

type LeftPanelHeaderProps = ComponentPropsWithoutRef<'div'> & BaseComponentProps;

export const LeftPanelHeader = forwardRef<HTMLDivElement, LeftPanelHeaderProps>(
	({dataCy = 'features-left-panel-header', css = {}, children, ...props}, forwardedRef) => {
		return (
			<Box dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</Box>
		);
	},
);
