import {ComponentProps, forwardRef} from 'react';
import {ElementStatus, useFormElementContext} from '../../../../form';
import {SelectableList as BaseSelectableList} from '../../../../layout/selectable-list';

type SelectableListItemProps = ComponentProps<typeof BaseSelectableList.Item>

export const SelectableListItem = forwardRef<HTMLLIElement, SelectableListItemProps>(({
	children,
	value,
	disabled,
	...props
}, ref) => {

	const {status} = useFormElementContext();

	const commonProps = {
		disabled: status === ElementStatus.disabled || disabled,
		value,
		ref,
		...props,
	};

	return <BaseSelectableList.Item {...commonProps}>
		{children}
	</BaseSelectableList.Item>;
});
