import React from 'react';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {environment} from '@esgi/core/environments';
import {redirectUSLink, redirectCanadaLink} from './model';

export function RedirectCountryLinks() {
	const path = window.location.pathname + window.location.search;
	const isCanada = environment.country === 'ca';

	return (
		<FlexBox>
			{isCanada ? (
				<Text size='small'>
					Users outside of Canada please <a href={redirectUSLink + path}><b>click here</b></a> to access the login page.
				</Text>
			) : (
				<Text size='small'>
					Looking for the Canadian login page? <a href={redirectCanadaLink + path}><b>Click Here</b></a>
				</Text>
			)}
		</FlexBox>
	);
}