import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const TabsWrapper = styled(Box, {
	paddingRight: 20,
	marginTop: -2,
	paddingBottom: 2,
});

export const Divider = styled(Box, {
	height: 1,
	marginLeft: -12,
	marginRight: -12,
	backgroundColor: '$background',
});

export const ContentWrapper = styled(GridBox, {
	height: '100%',
	overflow: 'hidden',
	alignContent: 'start',
	gap: '$4',
});