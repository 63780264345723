import {useEffect, useState} from 'react';
import {Drawer} from '@esgi/main/kits/common';
import {Input} from '@esgi/ui/form-controls';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {useService} from '@esgi/core/service';
import {ElementStatus, Form, Validators} from '@esgi/ui/form';
import {TestButtonLabelsService} from './service';
import {SettingsTab, TestButtonLabels as TestButtonLabelsType} from '../../../../../types';
import {descriptions} from '../../../../../constants';
import {SingleCheckboxSection} from '../../../../single-checkbox-section';
import {Text} from '@esgi/ui/typography';
import {FormElementWrapper} from '../../../../../index.styled';
import {ContentWrapper, PanelContent} from '../../../index.styled';
import {Container, TestButtonLabelTag} from './index.styled';
import {useStreamEffect} from '@esgi/ui';
import {userStorage} from '@esgi/core/authentication';

export function TestButtonLabels({
	testButtonsLabelsEnabled,
	correctTestLabel,
	incorrectTestLabel,
	onUpdateInitData,
	onCloseDrawer,
	onUpdateTabTouchedData,
}: TestButtonLabelsType) {
	const service = useService(TestButtonLabelsService);
	const isTestButtonLabels = useBehaviorSubject(service.isTestButtonLabels);
	const initialData = useBehaviorSubject(service.initData);
	const [isDisableButton, setIsDisableButton] = useState(true);

	useEffect(() => {
		service.init({
			incorrectTestLabel,
			testButtonsLabelsEnabled,
			correctTestLabel,
		});
	}, []);

	useStreamEffect(service.form.onChanged, () => {
		const testButtonLabels = service.form.controls.testButtonLabels.value;
		if (testButtonLabels){
			service.form.controls.correct.status = ElementStatus.untouched;
			service.form.controls.incorrect.status = ElementStatus.untouched;

			if (service.form.controls.correct.validators.length === 0) {
				service.form.controls.correct.validators.push(Validators.required());
				service.form.controls.incorrect.validators.push(Validators.required());
			}

			service.form.validate().subscribe((result) => {
				if (!result.valid) {
					setIsDisableButton(!result.valid);
					onUpdateTabTouchedData(SettingsTab.TestButtonLabels, result.valid);
				} else {
					checkIfValuesChanged();
				}
			});
		} else {
			service.form.controls.correct.validators.pop();
			service.form.controls.incorrect.validators.pop();

			service.form.controls.correct.status = ElementStatus.disabled;
			service.form.controls.incorrect.status = ElementStatus.disabled;

			checkIfValuesChanged();
		}
	});

	function checkIfValuesChanged(){
		const isTouched = service.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.TestButtonLabels, isTouched);
	}

	const onSave = () => {
		const testButtonLabels = service.form.controls.testButtonLabels.value;
		service.init({correctTestLabel: service.form.controls.correct.value,
			incorrectTestLabel: service.form.controls.incorrect.value,
			testButtonsLabelsEnabled: testButtonLabels});

		setIsDisableButton(true);
		service.save().subscribe({error: () => setIsDisableButton(false), next: () => {
			let correctText = service.form.controls.correct.value.toString();
			let incorrectText = service.form.controls.incorrect.value.toString();
			if (!testButtonLabels) {
				correctText = undefined;
				incorrectText = undefined;
			}

			const changedData = {
				correctButtonLabel: correctText,
				incorrectButtonLabel: incorrectText,
			};

			userStorage.update(changedData);
			onUpdateInitData({
				...changedData,
				testButtonsLabelsEnabled: testButtonLabels,
			});

			service.initData.next({
				...initialData,
				...changedData,
				testButtonsLabelsEnabled: testButtonLabels,
			});

			onUpdateTabTouchedData(SettingsTab.TestButtonLabels, false);
		}});
	};

	return (
		<Form controller={service.form} data-cy='test-buttons-form'>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={onSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
				data-cy='alternative-test-buttons-form-header'
			>
				<Text size='large' color='neutral24' bold data-cy='test-button-labels-title'>
					Test Button Labels
				</Text>
			</Drawer.PanelHeader>

			<PanelContent dataCy='alternative-test-buttons-content'>
				<ContentWrapper>
					<Drawer.ContentBlock
						title='Alternative Test Button Labels'
						withDivider
					>
						<SingleCheckboxSection
							checkboxControl={service.form.controls.testButtonLabels}
							isDisabled={!isTestButtonLabels}
							description={descriptions.testButtonLabels}
							dataCy='alternative-labels-checkbox'
						/>

						<Container>
							<TestButtonLabelTag dataCy='yes-button'>
								<Text size='large' color='neutral40' data-cy='yes-title'>
									Yes
								</Text>
							</TestButtonLabelTag>
							<FormElementWrapper control={service.form.controls.correct}>
								<Input.Base placeholder='Yes' dataCy='yes-input'/>
							</FormElementWrapper>
						</Container>

						<Container>
							<TestButtonLabelTag dataCy='no-button'>
								<Text size='large' color='neutral40' data-cy='no-title'>
									No
								</Text>
							</TestButtonLabelTag>
							<FormElementWrapper control={service.form.controls.incorrect}>
								<Input.Base placeholder='No' dataCy='no-input'/>
							</FormElementWrapper>
						</Container>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}
