import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const SectionBox = styled(Box, {
	width: 24,
	height: 24,

	'& svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		fillColor: {
			primary: {
				color: '$primary',
			},

			secondary: {
				color: '$secondary',
			},

			neutral56: {
				color: '$neutral56',
			},
		},
	},
});
