import {getThemeColor} from '../../utils';
import {ThemeColorKeys} from '../../types';
import {ComponentPropsWithoutRef, forwardRef, useCallback, useEffect, useMemo} from 'react';
import {animated} from 'react-spring';
import {usePieChartContext} from '../context';
import {useOpacityAnimation} from '../hooks/use-opacity-animation';

type PieChartPointerProps = Omit<
	ComponentPropsWithoutRef<typeof animated.circle>,
	'children' | 'style' | 'cx' | 'cy' | 'r' | 'strokeWidth' | 'fill' | 'stroke'
> & {
	/**
	 * Set stroke color for pointer.
	 * @default 'highContrast'
	 */
	stroke?: ThemeColorKeys;

	/**
	 * Set fill color for pointer.
	 * @default 'vivid'
	 */
	fill?: ThemeColorKeys;

	/**
	 * Set stroke width for the poiner.
	 * @default 1.5
	 */
	strokeWidth?: number;

	/**
	 * The value for rendering pointer in a svg boundary.
	 * Progress should be in the range [minValue, maxValue] of the root component
	 */
	value: number;

	/**
	 * Set radius for the poiner.
	 * @default 2.5
	 */
	r?: number;
};

export const PieChartPointer = forwardRef<SVGCircleElement, PieChartPointerProps>(
	({stroke = 'highContrast', strokeWidth = 1.5, r = 2.5, fill = 'vivid', value, ...circleProps}, forwardedRef) => {
		const {getValueProgress, updateShapeOffset, svgValue, maxShapeOffset} = usePieChartContext();

		useEffect(() => {
			updateShapeOffset({shape: 'pointer', offset: strokeWidth + 2 * r});
		}, [r, strokeWidth, updateShapeOffset]);

		const getCoordinatesByAngle = useCallback(
			(angle: number) => {
				const svgAdaptedRadius = (svgValue.size - maxShapeOffset) / 2;
				const angleRadian = ((angle - 90) * Math.PI) / 180;

				return {
					x: svgValue.center.x + svgAdaptedRadius * Math.cos(angleRadian),
					y: svgValue.center.y + svgAdaptedRadius * Math.sin(angleRadian),
				};
			},
			[maxShapeOffset, svgValue.center.x, svgValue.center.y, svgValue.size],
		);

		const pointerInfo = useMemo(() => {
			const valueProgress = getValueProgress(value);
			const angle = valueProgress * 360;
			const {x, y} = getCoordinatesByAngle(angle);

			return {
				center: {
					x,
					y,
				},
			};
		}, [getCoordinatesByAngle, getValueProgress, value]);

		const pointerAnimationProps = useOpacityAnimation();

		return (
			<animated.circle
				style={pointerAnimationProps}
				cx={pointerInfo.center.x}
				cy={pointerInfo.center.y}
				r={r}
				strokeWidth={strokeWidth}
				fill={getThemeColor(fill)}
				stroke={getThemeColor(stroke)}
				ref={forwardedRef}
				{...circleProps}
			/>
		);
	},
);
