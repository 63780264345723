import {
	Container,
	CounterBox,
	CounterText,
	Item,
	ListItem,
	NotTestedText,
	TestButton,
	Title,
} from '../../../../index.styled';
import {StudentItem} from '@esgi/main/features/untested-students-report';
import {TestType} from '@esgi/main/libs/core';

interface Props {
	testId: number;
	testType: TestType;
	students: StudentItem[];
	onStartTest: (student: StudentItem) => void;
}

function TestResult (props: {student: StudentItem, testType: TestType}) {
	if (props.testType === TestType.YN) {
		return <>
			<CounterText data-cy='correct' type='correct' bold>{props.student.correct}</CounterText>
			<CounterText data-cy='incorrect' type='incorrect' bold>{props.student.incorrect}</CounterText>
			<CounterText data-cy='untested' type='untested' bold>{props.student.untested}</CounterText>
		</>;
	}
	if (props.testType === TestType.Score || props.testType === TestType.Rubric) {
		return <>
			<strong>{props.student.correct}</strong>
			<span>/</span>
			<strong>{props.student.maxScore}</strong>
		</>;
	}
	return null;
}

export function StudentListItem({students, testId, onStartTest, testType}: Props) {
	return <ListItem dataCy='students-list'>
		<Container>
			{students.map(student => <Item key={`test_student_item_${testId}_${student.id}`} dataCy='student-list-item'>
				<Title dataCy='student-name'>{student.firstName} {student.lastName}</Title>
				<CounterBox dataCy='test-result-counter'>
					{
						(student.correct + student.incorrect) !== 0 ?
							<TestResult student={student} testType={testType}/> :
							<NotTestedText data-cy='not-tested-student-title'>
								Not Tested
							</NotTestedText>
					}
				</CounterBox>
				<TestButton data-cy='test-button' onClick={() => onStartTest(student)}>Test</TestButton>
			</Item>)}
		</Container>
	</ListItem>;
}
