import React from 'react';
import styles from '../overview.module.less';

interface Props {
	onClick: () => void;
}

export default class OwnPathCard extends React.PureComponent<Props> {
	public render() {
		return <div className={styles.ownPathCard} onClick={this.props.onClick} data-cy='own-path-card'>
			<svg width='100%' height='100%' viewBox='0 0 204 185' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect x='1.5' y='1.5' width='201' height='182' rx='4.5' fill='white' stroke='#81D4F7' strokeWidth='3'/>
				<rect x='19.5' y='18.5' width='34' height='131' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
				<rect x='66.5' y='57.5' width='118' height='91' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
				<rect x='67.5' y='18.5' width='118' height='26' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
			</svg>
		</div>;
	}
}
