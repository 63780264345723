export class GoogleAnalyticsTracker {
	private static isEnabled = false;

	private static getInstance() {
		const instance = (window as any).gaTrackPageView;
		if (instance) {
			this.isEnabled = true;
		}
		return instance;
	}

	public static trackEvent(page?: string, title?: string): void {
		const instance = this.getInstance();
		if (this.isEnabled) {
			instance(page, title);
		}
	}
}
