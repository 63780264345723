import React from 'react';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import GradeScale from 'shared/modules/grade-scale/grade-scale/component';
import DefaultGradeRange from 'shared/modules/grade-scale/grade-range/forms/default/component';
import {IReportGradeScale, SubjectLevel} from 'shared/modules/grade-scale/models';
import {HistoryService} from 'shared/modules/grade-scale/wizard/history-service';
import {ServicesStore} from 'shared/modules/grade-scale/wizard/services-store';
import CustomSharedGradeRange from 'shared/modules/grade-scale/grade-range/forms/custom-shared/component';
import {Modal} from '@esgi/deprecated/react';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class Props {
	initialStep: WizardStep = WizardStep.None;
	onClosed: () => void;
	onGradeScaleSaved?: (gradeScale: IReportGradeScale) => void;
	onGradeRangeSaved?: () => void;
	gradeScaleID?: number;
	subjectID: number;
	subjectLevel?: SubjectLevel;
	testID?: number;
	hierarchy: HierarchySnapshot;
}

export class State {
	step: WizardStep = WizardStep.None;
	prevStep: WizardStep = WizardStep.None;
	gradeScaleID: number;
	gradeScaleIsNew: boolean;
	prevGradeScaleID: number;
	subjectID?: number;
	testID?: number;
}

export default class Wizard extends React.PureComponent<Props, State> {
	historyService: HistoryService;
	servicesStore: ServicesStore;

	constructor(props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		this.servicesStore = new ServicesStore(this.props.initialStep === WizardStep.GradeScale, this.props.hierarchy);
		this.historyService = new HistoryService();
		
		this.historyService.step.subscribe(step => {
			if (step === null) {
				return;
			}

			this.setState((prevState) => {
				return {
					step: step,
					prevStep: prevState.step,
				};
			});
		});

		this.historyService.next(this.props.initialStep);

		this.setState({
			subjectID: this.props.subjectID,
			testID: this.props.testID,
		});
	}

	componentWillUnmount() {
		this.servicesStore.destroy();
	}

	get gradeScaleID() {
		return this.state.gradeScaleID || this.props.gradeScaleID;
	}

	onCancel() {
		if (this.servicesStore.setUpGradeScaleMode) {
			this.historyService.prev();
		} else {
			this.historyService.none();
			this.props.onClosed();
		}
	}

	onSaved() {
		this.props.onGradeRangeSaved && this.props.onGradeRangeSaved();
		this.props.onClosed();
	}

	renderStep() {
		if (this.state.step === WizardStep.GradeScale) {
			return <GradeScale
				service={this.servicesStore.gradeScaleService}
				subjectLevel={this.props.subjectLevel}
				gradeScaleID={this.gradeScaleID}
				onClose={() => this.props.onClosed()}
				hasScales={!!this.props.gradeScaleID}
				prevGradeScaleID={this.state.prevGradeScaleID}
				needDeleteCreatedScale={this.state.gradeScaleIsNew}
				onSaved={(gradeScale, isNew) => {
					this.setState((prevState) => {
						return {
							gradeScaleID: gradeScale.id,
							gradeScaleIsNew: isNew,
							prevGradeScaleID: prevState.gradeScaleID || this.props.gradeScaleID,
						};
					});

					this.historyService.next(WizardStep.DefaultGradeRange);
					this.props.onGradeScaleSaved && this.props.onGradeScaleSaved(gradeScale);
				}}
			/>;
		}

		if (this.state.step === WizardStep.DefaultGradeRange) {
			return <DefaultGradeRange
					service={this.servicesStore.defaultGradeRangeEditorService}
					subjectID={this.state.subjectID}
					testID={this.state.testID}
					onCancel={() => this.onCancel()}
					sourceStep={this.historyService.prevStep}
					onToCustomRangeClicked={() => this.historyService.next(WizardStep.CustomGradeRange)}
					gradeScaleID={this.gradeScaleID}
					onClose={() => this.props.onClosed()}
					onSaved={() => this.onSaved()}
				/>;
		}

		if (this.state.step === WizardStep.CustomGradeRange || this.state.step === WizardStep.SharedGradeRange) {
			return <CustomSharedGradeRange
				service={this.servicesStore.customSharedService}
				subjectID={this.state.subjectID}
				testID={this.state.testID}
				onGoToStep={(step, subjectID, testID, gradeScaleID) => {
					this.setState({
						subjectID: subjectID,
						testID: testID,
						gradeScaleID: gradeScaleID,
					});
					this.historyService.next(step);
				}}
				sourceStep={this.historyService.prevStep}
				onClosed={() => this.props.onClosed()}
				onSaved={() => this.onSaved()}
				onCanceled={() => this.onCancel()}
				gradeScaleID={this.gradeScaleID}
				step={this.state.step}
				initialStep={this.props.initialStep}
			/>;
		}
	}

	render() {
		return <Modal animate={true} className='grade-scale-component blue-header'>
			{this.renderStep()}
		</Modal>;
	}
}
