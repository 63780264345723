import {
	UntestedStudentsReport,
	GroupingType,
	TestListDataItem,
	StudentListDataItem,
	TestItem,
} from '@esgi/main/features/untested-students-report';
import {Box} from '@esgi/ui/layout';
import {EmptyBox} from '../../index.styled';
import {Text} from '@esgi/ui/typography';
import {StudentModel} from '@esgi/main/features/assessments';
import {SkeletonShape} from '@esgi/main/kits/common';

interface Props {
	mode: GroupingType;
	tests: TestListDataItem[];
	students: StudentListDataItem[];
	hasRequiredFields: boolean;
	showAllStudents: boolean;
	onStartTest: (test: TestItem, student: StudentModel) => void;
	isLoadedData: boolean;
}

export function TestSection(props: Props) {
	if (!props.hasRequiredFields) {
		return (
			<EmptyBox>
				<Text size='small' font='mono' color='neutral40'>
					Select subject and class(or group)
				</Text>
			</EmptyBox>
		);
	}

	if (!props.isLoadedData) {
		return <SkeletonShape width='100%' height={500} />;
	}

	return (
		<Box>
			<UntestedStudentsReport.TestsControl
				onStartTest={props.onStartTest}
				showAllStudents={props.showAllStudents}
				tests={props.tests}
				students={props.students}
				mode={props.mode}
			/>
		</Box>
	);
}
