import {ExpandablePanelContent, ExpandablePanelStyled, FiltersPanelHeader, FiltersPanelItem, FiltersPanelItems} from '@esgi/main/kits/common';
import {ExpandablePanel} from '@esgi/ui/layout';
import {ExpandablePanelIcon} from '@esgi/main/features/teacher/home';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Setup} from '@esgi/ui';
import {AddButton, mappedGradeLevels, OptionItem, SelectableList} from '@esgi/main/features/standards-drawer';
import {useMemo} from 'react';
import {QuestionBankService} from '../../services';

interface Props {
	service: QuestionBankService
	contentAreas: OptionItem[]
	selectedStandardsFilter: OptionItem[]
	selectedStandardsIDs: number[]
	selectedGradeLevelIDs: number[]
	selectedContentAreaIDs: number[]
	selectedStandards: OptionItem[]
	showAddStandardsDrawerToggle: () => void
	isPanelOpened: boolean
	togglePanelOpened: () => void
	isSmallScreen: boolean;
	isAnyFilterSelected: boolean;
	onFiltersReset: () => void
	isContentAreasLoading: boolean
}

export function QuestionBankFilters({
	service,
	contentAreas,
	selectedStandardsFilter,
	selectedStandardsIDs,
	selectedGradeLevelIDs,
	selectedContentAreaIDs,
	selectedStandards,
	showAddStandardsDrawerToggle,
	isPanelOpened,
	togglePanelOpened,
	isSmallScreen,
	isAnyFilterSelected,
	onFiltersReset,
	isContentAreasLoading,
}: Props) {
	const gradeLevelsList = useMemo(() => {
		if (selectedStandards.length) {
			return mappedGradeLevels.map((gradeLevel) => ({
				...gradeLevel,
				disabled: true,
			}));
		}

		return mappedGradeLevels.map((gradeLevel) => ({
			...gradeLevel,
			disabled: false,
		}));
	}, [selectedStandards.length]);

	const contentAreasList = useMemo(() => {
		if (selectedStandardsIDs.length) {
			return contentAreas.map((contentArea) => ({
				...contentArea,
				disabled: true,
			}));
		}

		return contentAreas;
	}, [contentAreas, selectedStandardsIDs.length]);

	return (
		<ExpandablePanelStyled
			skeleton={isContentAreasLoading}
			open={!isSmallScreen || isPanelOpened}
			onClick={!isPanelOpened ? togglePanelOpened : undefined}
			isSmallScreen={isSmallScreen}
		>
			{({panelIsHovered}) => (
				<>
					{isSmallScreen && (
						<ExpandablePanel.Trigger
							onClick={(e) => {
								e.stopPropagation();
								togglePanelOpened();
							}}
						/>
					)}
					<ExpandablePanel.Placeholder>
						<ExpandablePanelIcon
							fillColor={panelIsHovered ? 'secondary' : 'primary'}
							Icon={Setup}
						/>
					</ExpandablePanel.Placeholder>
					<ExpandablePanelContent>
						<FiltersPanelHeader
							onFiltersReset={onFiltersReset}
							isFiltersButtonDisabled={!isAnyFilterSelected}
						/>
						<OverlayScrollbarsComponent
							style={{height: 'calc(100% + 0px)'}}
							defer
							options={{scrollbars: {autoHide: 'leave'}}}
						>
							<FiltersPanelItems>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Standard'
									clearVisible={selectedStandardsIDs.length > 0}
									onListClear={service.standardsService.onStandardsClear}
								>
									<SelectableList
										items={selectedStandardsFilter}
										selectedItems={selectedStandardsIDs}
										onItemRemove={service.standardsService.onSelectedStandardRemove}
									/>
									<AddButton
										onClick={showAddStandardsDrawerToggle}
										label='Filter By Standard'
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Grade Level'
								>
									<SelectableList
										items={gradeLevelsList}
										selectedItems={selectedGradeLevelIDs}
										onClick={service.standardsService.onGradeLevelChange}
										wrapItems
									/>
								</FiltersPanelItem>
								<FiltersPanelItem
									isInitialized={!isContentAreasLoading}
									label='Content Area'
								>
									<SelectableList
										items={contentAreasList}
										selectedItems={selectedContentAreaIDs}
										onClick={service.standardsService.onContentAreaChange}
										wrapItems
									/>
								</FiltersPanelItem>
							</FiltersPanelItems>
						</OverlayScrollbarsComponent>
					</ExpandablePanelContent>
				</>
			)}
		</ExpandablePanelStyled>
	);
}