import {useMemo} from 'react';
import levenshtein from 'js-levenshtein';
import {Filter, StudentModel} from '../types';

export function useStudentsFilter(students: StudentModel[], filter: Filter): {filteredStudents: StudentModel[], filteredWithoutGradeStudents: StudentModel[]} {
	return useMemo(() => {
		let out = students;
		let gradeSkippedOut = students;

		if (filter.keyword) {
			out = out.filter(s => {
				const keyword = filter.keyword.toLowerCase();
				const name = `${s.firstName} ${s.lastName}`.toLowerCase();
				const nameDiff = levenshtein(name, keyword);
				const nameMatch = (name.length - nameDiff) >= filter.keyword.length;

				const studentIDN = (s.studentIDN || '').toLowerCase();
				const studentIDNDiff = levenshtein(studentIDN, keyword);
				const studentIDNMatch = (studentIDN.length - studentIDNDiff) >= filter.keyword.length;

				return nameMatch || studentIDNMatch;
			});
		}

		if(filter.groupID) {
			out = out.filter(s => s.groupIDs.includes(filter.groupID));
			gradeSkippedOut = out;
		}

		if(filter.classID) {
			out = out.filter(s => s.classIDs.includes(filter.classID));
			gradeSkippedOut = out;
		}

		if(filter.teacherID) {
			out = out.filter(s => s.primaryTeacherID === filter.teacherID);
			gradeSkippedOut = out;
		}

		if(filter.gradeLevelID) {
			out = out.filter(s => s.gradeLevelID === filter.gradeLevelID);
		}

		return {filteredStudents: out, filteredWithoutGradeStudents: gradeSkippedOut};
	}, [students, filter]);
}