import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {AssignmentState} from '../../../types';
import {CardHeader} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {AssignmentStatus} from '../../../assignment-status';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	BaseComponentProps & {
		name: string;
		state: AssignmentState;
	};

export const AssignmentDetailsCardHeader = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'assignment-details-card-header', css = {}, name, state, ...props}, forwardedRef) => (
		<CardHeader dataCy={dataCy} css={css} {...props} ref={forwardedRef}>
			<GridBox display='inlineGrid' flow='column' align='center' gap='3'>
				<Text size='xxLarge' color={state === AssignmentState.Draft ? 'lowContrast' : 'neutral16'} data-cy='assignment-name'>
					{name}
				</Text>
				<AssignmentStatus state={state} />
			</GridBox>
		</CardHeader>
	),
);
