import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2TeachersUserSettingsController} from '@esgi/contracts/esgi';

export class TestSessionResultsService extends BaseService {
	public isShowTestSessionResult = new BehaviorSubject<boolean>(false);
	private controller = new V2TeachersUserSettingsController();

	public init = (data: boolean) => {
		this.isShowTestSessionResult.next(data);
	};

	public save = () => {
		return this.controller.tabsTestSessionResultsSave({
			value: this.isShowTestSessionResult.value,
		});
	};

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}
