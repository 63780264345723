import {useCallback} from 'react';
import {Entity} from '../../../../types/table-level-report-service-types';
import {TableLevelReportService} from '../../../../services/table-level-report-service';
import styles from './styles.module.less';
import {ReportHeaderField} from './components/report-header-field';
import {useStream} from '@esgillc/ui-kit/utils';
import {ReportEntityDropdown} from './components/report-entity-dropdown';
import {ReportFiltersRow} from '@esgi/deprecated/ui-kit/report/filters/report-filters-row';

type ReportHeaderProps = {
	service: TableLevelReportService;
};

export function ReportHeader({service}: ReportHeaderProps) {
	const headerInfo = useStream(service.headerInfo$);
	const selectedEntitiesData = useStream(service.selectedEntitiesData$);
	const selectedSubject = useStream(service.selectedSubject$);

	const subjectEntity: Entity[] | undefined = headerInfo?.subjects.map(({subjectID, name}) => ({
		id: subjectID,
		value: name,
	}));

	const reportHeaderFieldTitle = headerInfo?.specialistGroups?.length ? 'Specialist' : 'Teacher';

	const onSelectGroup = useCallback(
		({id}: Entity) => {
			service.selectGroup(id);
		},
		[service.selectGroup],
	);

	const onSelectClass = useCallback(
		({id}: Entity) => {
			service.selectClass(id);
		},
		[service.selectClass],
	);

	const onSelectSpecialistGroup = useCallback(
		({id}: Entity) => {
			service.selectSpecialistGroup(id);
		},
		[service.selectClass],
	);

	const onSelectRubric = useCallback(
		({id}: Entity) => {
			service.selectRubric(id);
		},
		[service.selectRubric],
	);

	const onSelectSubject = useCallback(
		({id}: Entity) => {
			service.selectSubject(id);
		},
		[service.selectSubject],
	);

	const isShowTeacherGroupsDropdown = !!(
		selectedEntitiesData?.selectedTeacherGroupID &&
		selectedEntitiesData.selectedTeacherGroupID > 0 &&
		headerInfo?.teacherGroups
	);

	const isShowTeacherClassesDropdown = !!(
		selectedEntitiesData?.selectedTeacherClassID &&
		selectedEntitiesData.selectedTeacherClassID > 0 &&
		headerInfo?.teacherClasses
	);

	const isShowSpecialistGroupsDropdown = !!(
		selectedEntitiesData?.selectedSpecialistGroupID &&
		selectedEntitiesData.selectedSpecialistGroupID > 0 &&
		headerInfo?.specialistGroups
	);

	const isShowRubricsDropdown = !!(selectedEntitiesData?.selectedRubricID && headerInfo?.rubrics);

	const isShowSubjectEntityDropdown = !!(selectedSubject?.subjectID && subjectEntity);

	return (
		<ReportFiltersRow className={styles.reportHeaderRow}>
			<ReportHeaderField title={reportHeaderFieldTitle} value={headerInfo?.userName} />

			{isShowTeacherGroupsDropdown && (
				<ReportEntityDropdown
					groupID={selectedEntitiesData.selectedTeacherGroupID}
					title='Group'
					onSelected={onSelectGroup}
					entityGroup={headerInfo.teacherGroups}
					showAlternativeHeader
					validateOn='length'
				/>
			)}

			{isShowTeacherClassesDropdown && (
				<ReportEntityDropdown
					groupID={selectedEntitiesData.selectedTeacherClassID}
					title='Class'
					onSelected={onSelectClass}
					entityGroup={headerInfo.teacherClasses}
					showAlternativeHeader
					validateOn='length'
				/>
			)}

			{isShowSpecialistGroupsDropdown && (
				<ReportEntityDropdown
					groupID={selectedEntitiesData.selectedSpecialistGroupID}
					title='Specialist Group'
					onSelected={onSelectSpecialistGroup}
					entityGroup={headerInfo.specialistGroups}
					showAlternativeHeader
					validateOn='length'
				/>
			)}

			{isShowRubricsDropdown && (
				<ReportEntityDropdown
					groupID={selectedEntitiesData.selectedRubricID}
					title='Rubric'
					onSelected={onSelectRubric}
					entityGroup={headerInfo.rubrics}
					validateOn='existGroup'
				/>
			)}

			{isShowSubjectEntityDropdown && (
				<ReportEntityDropdown
					groupID={selectedSubject.subjectID}
					title='Subject'
					onSelected={onSelectSubject}
					entityGroup={subjectEntity}
					validateOn='existGroup'
				/>
			)}
		</ReportFiltersRow>
	);
}
