import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type LocationFormType = FormGroup<{
	groupIDs: FormControl<string[]>,
	classIDs: FormControl<string[]>,
	specialistGroupIDs: FormControl<string[]>,
},
	ControlValue<{
		groupIDs: FormControl<string[]>,
		classIDs: FormControl<string[]>,
		specialistGroupIDs: FormControl<string[]>,
	}>>

export const createLocationForm = ():
	LocationFormType => new FormGroup({
	groupIDs: new FormControl([]),
	classIDs: new FormControl([]),
	specialistGroupIDs: new FormControl([]),
});
