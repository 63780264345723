import {Renderable} from '@esgi/deprecated/knockout';
import React from 'react';

export class KnockoutWrapperProps {
	obj: Renderable;
	loaded?: () => any;
}

export class KnockoutWrapper extends React.Component<KnockoutWrapperProps> {
	constructor(props: KnockoutWrapperProps) {
		super(props);
	}

	ref: HTMLDivElement;

	componentDidMount(): void {
    	let obj = this.props.obj;
    	obj.load().then(() => ko.render(obj))
    		.then(() => this.insertHtml())
    		.then(() => this.props.loaded && this.props.loaded());
	}

	componentWillUnmount() {
		try {
			ko.cleanNode(this.ref);
		} catch (e) {
			console.error(e);
		}
	}

	insertHtml() {
    	this.ref.parentElement.replaceChild((this.props.obj.rootElement as any)[0], this.ref);
	}

	render() {
    	return <div ref={(r) => this.setRef(r)} dangerouslySetInnerHTML={{__html: ''}}/>;
	}

	private setRef(r: HTMLDivElement) {
    	this.ref = r;
	}
}