import React from 'react';
import {Observable, Subject} from 'rxjs';
import {DependElement, Form, FormControl, FormElement, FormGroup} from '@esgillc/ui-kit/form';
import {Dropdown, Label, Option} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import {takeUntil} from 'rxjs/operators';
import {useState} from '@esgi/deprecated/react';
import {userStorage, UserType} from '@esgi/core/authentication';
import {SchoolModel, SpecialistModel} from '../../../../../../../models/models';
import styles from '../assign.module.less';
import {Field, SpecialistAssignModel} from '../models';

class State {
	specialistGroups: Field[] = null;
	specialistUsers: Field[] = null;
	specialistSchools: Field[] = null;
	specialistTypes: Field[] = null;
}

class Props {
	hidden: boolean;
	specialists: SpecialistModel[] = null;
	selectedStudentsSchoolsIDs: number[];
	schools: SchoolModel[] = null;
	onValueChanged: (value: Partial<SpecialistAssignModel>) => void;
}

@useState(State)
export default class SpecialistAssign extends React.PureComponent<Props, State> {
	private readonly currentUser = userStorage.get();
	private readonly onDestroy$: Subject<void> = new Subject();

	private form = new FormGroup({
		specialistGroup: new FormControl<Field[]>([], {disableValueComparison: true}),
		specialistUser: new FormControl<Field[]>([], {disableValueComparison: true}),
		specialistSchool: new FormControl<Field[]>([], {disableValueComparison: true}),
		specialistType: new FormControl<Field[]>([], {disableValueComparison: true}),
	});

	private get specialists() {
		return this.props.specialists.map(item => ({...item, userType: UserType[item.userType]}));
	}

	public componentDidMount() {
		const {specialistUser, specialistSchool, specialistType, specialistGroup} = this.form.controls;

		this.wrapWithOnDestroy(specialistType.onChanged).subscribe((event) => {
			this.refreshSchools();
			this.props.onValueChanged({specialistType: event.currState.value[0]?.id});
		});
		this.wrapWithOnDestroy(specialistSchool.onChanged).subscribe((event) => {
			this.refreshUsers();
			this.props.onValueChanged({specialistSchoolID: event.currState.value[0]?.id});
		});
		this.wrapWithOnDestroy(specialistUser.onChanged).subscribe((event) => {
			this.refreshGroups();
			const value = event.currState.value[0];
			const specialist = this.specialists.find(s => s.userID === value?.id);
			const specialistType = specialist ? specialist.userType : undefined;
			this.props.onValueChanged({specialistUserID: value?.id, specialistType});
		});
		this.wrapWithOnDestroy(specialistGroup.onChanged).subscribe((event) => {
			this.props.onValueChanged({specialistGroupID: event.currState.value[0]?.id});
		});

		this.initSpecialistsAssign();
	}

	private wrapWithOnDestroy<T>(observable: Observable<T>): Observable<T> {
		return observable.pipe(takeUntil(this.onDestroy$));
	}

	private initSpecialistsAssign(): void {
		const schools = this.getSpecialistSchools();
		const types = this.getSpecialistTypes(schools);
		this.setState({specialistTypes: types}, () => {
			this.form.controls.specialistType.value = [types[0]];
		});
	}

	private refreshSchools(): void {
		let school: Field;
		const schools = this.getSpecialistSchools();
		if (schools.length === 2) {
			school = schools[1];
		} else {
			school = schools[0];
		}
		this.setState({specialistSchools: schools}, () => {
			this.form.controls.specialistSchool.value = [school];
		});
	}

	private refreshUsers(): void {
		let user: Field;
		const {specialistType, specialistSchool} = this.form.value;
		const users = this.getSpecialistUsers(specialistType[0]?.id, specialistSchool[0]?.id);
		if (this.isSpecialist()) {
			user = {id: this.currentUser.userID, value: ''};
		} else {
			if (users.length === 2) {
				user = users[1];
			} else {
				user = users[0];
			}
		}
		this.setState({specialistUsers: users}, () => {
			this.form.controls.specialistUser.value = [user];
		});
	}

	private refreshGroups(): void {
		let group: Field;
		const groups = this.getSpecialistGroups(this.form.controls.specialistUser.value[0]?.id);
		if (groups.length === 2) {
			group = groups[1];
		} else {
			group = groups[0];
		}
		this.setState({specialistGroups: groups}, () => {
			this.form.controls.specialistGroup.value = [group];
		});
	}

	private getSpecialistGroups(specialistUserID: number): Field[] {
		let groups = [];
		if (specialistUserID) {
			const specialist = this.specialists.find(x => x.userID === specialistUserID);
			groups = specialist.groups.map(x => {
				return {id: x.groupID, value: x.name};
			});
			groups.unshift({id: 0, value: 'Select a group'});
		}
		return groups;
	}

	private getSpecialistUsers(specialistType: number, specialistSchoolID: number): Field[] {
		const specialUsers = [];
		if ((this.currentUser.userType === UserType.D && specialistType === UserType.ISD) ||
			this.currentUser.userType === UserType.C ||
			this.currentUser.userType === UserType.PA ||
			(this.currentUser.userType === UserType.D && specialistType === UserType.ISS && specialistSchoolID)
		) {
			let users = this.specialists;
			if (specialistType) {
				users = users.filter(x => x.userType === specialistType);
			}

			if (specialistSchoolID && specialistType === UserType.ISS) {
				users = users.filter(x => x.schoolID === specialistSchoolID);
			}

			specialUsers.push(...users.filter(x => !x.isExpired)
				.filter(
					(thing, i, arr) => arr.findIndex(t => t.userID === thing.userID) === i,
				).map(x => {
					return {id: x.userID, value: x.name};
				}));
			let selectLabel = 'Select a ';
			selectLabel += this.currentUser.userType === UserType.PA ? 'PA Account' : 'specialist';
			specialUsers.unshift({id: 0, value: selectLabel});
		} else {
			specialUsers.push({id: 0, value: 'Select a specialist'});
		}
		return specialUsers;
	}

	private getSpecialistTypes(specialistSchools: Field[]): Field[] {
		const types = [];
		if (this.currentUser.userType === UserType.D) {
			const filteredSpecialists = this.specialists.filter(item => !item.isExpired);
			if (filteredSpecialists.some(x => x.userType === UserType.ISD)) {
				types.push({id: UserType.ISD, value: 'District'});
			}
			if (filteredSpecialists.some(x => x.userType === UserType.PA)) {
				types.push({id: UserType.PA, value: 'District'});
			}
			if (specialistSchools && specialistSchools.length > 1) {
				types.push({id: UserType.ISS, value: 'School'});
			}

			types.unshift({id: 0, value: 'Select a Specialist Type'});
			return types;
		}
		return types;
	}

	private getSpecialistSchools(): Field[] {
		const specialistSchools = [];
		if (this.currentUser.userType === UserType.D && this.props.selectedStudentsSchoolsIDs.length === 1) {
			const specialist = this.specialists.filter(x => x.schoolID);
			let specialistGroups = specialist.map(x => x.schoolID);

			specialistGroups = specialistGroups.filter(x => this.props.selectedStudentsSchoolsIDs.indexOf(x) !== -1);

			const temp = specialistGroups.filter(
				(thing, i, arr) => arr.findIndex(t => t === thing) === i,
			).map(x => {
				return {id: x, value: this.props.schools.find(s => s.schoolID === x)?.name};
			});
			specialistSchools.push(...temp);
			specialistSchools.unshift({id: 0, value: 'Select a school'});
		}
		return specialistSchools;
	}

	private getUserLabelByType(type: UserType): string {
		let result = 'Specialist';
		if (type === UserType.ISD) {
			result = 'District Specialist';
		}
		if (type === UserType.ISS) {
			result = 'School Specialist';
		}
		if (this.currentUser.userType === UserType.PA || type === UserType.PA) {
			result = 'Pre-Assess Account';
		}
		return result;
	}

	private getGroupLabelByType(): string {
		let result = 'Specialist';
		if (this.currentUser.userType === UserType.PA || this.form.controls.specialistType.value[0]?.id === UserType.PA) {
			result = 'Pre-Assess';
		}
		return result + ' Group';
	}

	public render() {
		if (this.props.hidden) {
			return <></>;
		}
		return <div className={join(this.props.hidden && styles.hidden)}>
			<Form controller={this.form}>
				{this.state.specialistTypes && this.state.specialistTypes.length > 0 &&
					<FormElement control={this.form.controls.specialistType}>
						<Label className={styles.dropdownLabel}>Specialist Type</Label>
						<Dropdown optionName='value'>
							{this.state.specialistTypes.map(s => <Option value={s} key={s.id}>{s.value}</Option>)}
						</Dropdown>
					</FormElement>
				}
				{this.state.specialistSchools && this.state.specialistSchools.length > 0 &&
					<DependElement control={this.form.controls.specialistType}>
						{type => type && type.value[0]?.id === UserType.ISS &&
							<FormElement control={this.form.controls.specialistSchool}>
								<Label className={styles.dropdownLabel}>School</Label>
								<Dropdown optionName='value'>
									{this.state.specialistSchools.map(s => <Option value={s}
									                                               key={s.id}>{s.value}</Option>)}
								</Dropdown>
							</FormElement>}
					</DependElement>
				}
				{this.state.specialistUsers && this.state.specialistUsers.length > 1 &&
					<DependElement control={this.form.controls.specialistType}>
						{type => type && <FormElement control={this.form.controls.specialistUser}>
							<Label
								className={styles.dropdownLabel}>{this.getUserLabelByType(type.value[0]?.id)}</Label>
							<Dropdown optionName='value'>
								{this.state.specialistUsers.map(s => <Option value={s} key={s.id}>{s.value}</Option>)}
							</Dropdown>
						</FormElement>}
					</DependElement>
				}
				{this.state.specialistGroups && (this.state.specialistGroups.length > 1 || this.isSpecialist()) &&
					<FormElement control={this.form.controls.specialistGroup}>
						<Label className={styles.dropdownLabel}>{this.getGroupLabelByType()}</Label>
						<Dropdown optionName='value'>
							{this.state.specialistGroups.map(s => <Option value={s} key={s.id}>{s.value}</Option>)}
						</Dropdown>
					</FormElement>
				}
			</Form>
		</div>;
	}

	private isSpecialist() {
		return this.currentUser.userType === UserType.ISS
			|| this.currentUser.userType === UserType.ISD
			|| this.currentUser.userType === UserType.PA;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
