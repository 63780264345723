import {
	Table,
	TableBody,
	TableColumn,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	useSortableColumns,
} from '@esgillc/ui-kit/table';
import {OverlayScrollbarsComponent, OverlayScrollbarsComponentRef} from 'overlayscrollbars-react';
import React, {useMemo, useRef} from 'react';
import {TeachersStudent} from '../../../types/teacher-types';
import styles from '../styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {CredentialsBodyCell} from '../components/credentials-body-cell';
import {ProgressBodyCell} from './progress-body-cell';
import {getUniqValuesInData} from 'pages/assignment-center/utils/get-uniq-values-in-data';

type Props = {
	students: TeachersStudent[];
	testsCount: number;
};

export function TeacherStudentsTable({students, testsCount}: Props) {
	const tableRef = useRef<OverlayScrollbarsComponentRef<'div'>>(null);
	const {data, sortableColumns} = useSortableColumns({
		data: students,
		columns: {
			name: true,
			className: true,
			groupName: true,
			testCompletion: true,
			hasCredentials: true,
		},
		activeSortedKey: 'name',
	});

	const {
		isClassTabSortableDisable,
		isGroupsTabSortableDisable,
		isCredentialsTabSortableDisable,
		isTestCompletionTabSortableDisable,
	} = useMemo(() => {
		const {className, groupName, hasCredentials, testCompletion} = getUniqValuesInData({
			data,
			keys: ['className', 'groupName', 'hasCredentials', 'testCompletion'],
		});

		return {
			isClassTabSortableDisable: className.size <= 1,
			isGroupsTabSortableDisable: groupName.size <= 1,
			isCredentialsTabSortableDisable: hasCredentials.size <= 1,
			isTestCompletionTabSortableDisable: testCompletion.size <= 1,
		};
	}, [data]);

	if (
		!sortableColumns.name ||
		!sortableColumns.className ||
		!sortableColumns.groupName ||
		!sortableColumns.hasCredentials ||
		!sortableColumns.testCompletion
	) {
		return null;
	}

	return (
		<Table>
			<TableHeader bordered className={styles.tableHeader}>
				<TableHeaderColumn.Sortable
					className={join(styles.headerCell, styles.nameColumn)}
					{...sortableColumns.name}
					disableSorting={data.length <= 1}
				>
					Students
				</TableHeaderColumn.Sortable>
				<TableHeaderColumn.Sortable
					className={join(styles.headerCell, styles.xl)}
					{...sortableColumns.className}
					disableSorting={isClassTabSortableDisable}
				>
					Class
				</TableHeaderColumn.Sortable>
				<TableHeaderColumn.Sortable
					className={join(styles.headerCell, styles.sm)}
					{...sortableColumns.groupName}
					disableSorting={isGroupsTabSortableDisable}
				>
					Group
				</TableHeaderColumn.Sortable>
				<TableHeaderColumn.Sortable
					className={join(styles.headerCell, styles.sm, styles.credentialColumn)}
					{...sortableColumns.hasCredentials}
					disableSorting={isCredentialsTabSortableDisable}
				>
					Credentials
				</TableHeaderColumn.Sortable>
				<TableHeaderColumn.Sortable
					className={join(styles.headerCell, styles.progressColumn)}
					{...sortableColumns.testCompletion}
					disableSorting={isTestCompletionTabSortableDisable}
				>
					Test Completion
				</TableHeaderColumn.Sortable>
			</TableHeader>
			<OverlayScrollbarsComponent className={styles.tableBodyOverlayScrollbar} ref={tableRef}>
				<TableBody className={styles.tableBody}>
					{data.map((item) => (
						<TableRow className={styles.row} key={item.studentID}>
							<TableColumn className={join(styles.cell, styles.nameColumn, styles.name)}>{item.name}</TableColumn>
							<TableColumn className={join(styles.cell, styles.xl)}>{item.className}</TableColumn>
							<TableColumn className={join(styles.cell, styles.sm, styles.textLeft)}>{item.groupName}</TableColumn>
							<TableColumn className={join(styles.cell, styles.sm, styles.credentialColumn)}>
								<CredentialsBodyCell hasCredentials={item.hasCredentials} studentId={item.studentID} />
							</TableColumn>
							<TableColumn className={join(styles.cell, styles.progressColumn)}>
								<ProgressBodyCell
									testCompletion={item.testCompletion}
									testsCount={testsCount}
									className={styles.studentProgressCell}
								/>
							</TableColumn>
						</TableRow>
					))}
				</TableBody>
			</OverlayScrollbarsComponent>
		</Table>
	);
}
