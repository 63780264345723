import {useServiceFactory} from '@esgi/core/service';
import {Case, Switch} from '@esgillc/ui-kit/layout';
import {useEffect, useMemo, useState} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {tap} from 'rxjs/operators';
import {TestType} from '@esgi/core/enums';
import {mapToEnum} from 'shared/utils';
import {TestSavedEvent} from '../../../kit/events';
import {SessionStatusAlert} from '../../../kit/session-status-alert';
import {TestSessionStatus} from '../../../kit/enums';
import {QuestionModel} from '../../../kit/models';
import {ShareSyncService} from '../../../kit/share-screen';
import {TestIntro} from '../../../kit/test-intro';
import {convertToQuestionModel} from '../../../kit/utils';
import {TestSummary} from '../../../summary';
import {ScoreService} from '../../service';
import {ScoreSlider} from '../slider/score-slider';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {Subject} from 'shared/modules/test/test-session-details/types';
import {IepGoalFormModal} from 'modules/forms/iep-goal-form';
import {IepProgressReport} from 'modules/reports/iep-progress';

interface Props {
	service: ScoreService;
	hierarchy: HierarchySnapshot;
	subject: Subject;
	onClosed: () => void;
	onTestHistoryClicked: () => void;
	onTestSessionDetailsClicked: () => void;
}

enum Screen {
	None,
	Intro,
	Slider,
	Summary,
	IEPEdit,
	IEPReport,
}

type Summary = {correct: number, incorrect: number, total: number, testSessionID: number, notes: string};

export function ScoreFlow({service, ...props}: Props) {
	const testingModel = useBehaviorSubject(service.testingModel);

	const [screenType, setScreenType] = useState(Screen.None);
	const [summary, setSummary] = useState<Summary>();
	const [showTestSessionStatusAlert, setShowTestSessionStatusAlert] = useState(false);

	const shareSyncService = useServiceFactory(() => new ShareSyncService({
		userID: testingModel.userID,
		testName: testingModel.testName,
		studentName: testingModel.studentName,
		isWhiteBackground: testingModel.isWhiteBackground,
	}));

	const question = useMemo<QuestionModel>(
		() => convertToQuestionModel(testingModel.question, testingModel.testID),
		[testingModel],
	);
	const {testID: testId, studentID: studentId} = testingModel;

	useEffect(() => {
		if (testingModel.testIntro) {
			setScreenType(Screen.Intro);
		} else {
			setScreenType(Screen.Slider);
		}
	}, []);

	useEffect(() => {
		if (screenType === Screen.Slider) {
			shareSyncService.start(0, [question], testId, TestType.Score, testingModel.bucketName);
		}
	}, [screenType]);

	useEffect(() => {
		const status = mapToEnum(testingModel.testSessionStatus, TestSessionStatus);
		if (status > 0) {
			setShowTestSessionStatusAlert(true);
		}
	}, [testingModel.testSessionStatus]);

	const onSave = (correct: number, notes: string, duration: number) => {
		return service.finishTest(correct, notes, duration)
			.pipe(tap((resp) => {
				dispatchAppEvent(
					TestSavedEvent,
					new TestSavedEvent(testingModel.testID, testingModel.studentID, correct),
				);
				setSummary({
					testSessionID: resp.testSessionID,
					correct,
					incorrect: testingModel.totalPossible - correct,
					total: testingModel.totalPossible,
					notes,
				});
				setScreenType(Screen.Summary);
				shareSyncService.syncSummary(
					testingModel.totalPossible,
					correct,
					testingModel.showTestSessionResults,
				);
			})).asObservable();
	};

	return (
		<Switch test={screenType}>
			<Case value={Screen.Intro}>
				<TestIntro
					testID={testId}
					testName={testingModel.testName}
					studentName={testingModel.studentName}
					testIntroID={testingModel.testIntro?.id}
					bucketName={testingModel.bucketName}
					isWhiteBackground={testingModel.isWhiteBackground}
					modifyTicks={testingModel.testIntro?.modifyTicks}
					pregenerated={testingModel.testIntro?.pregenerated}
					onStart={() => setScreenType(Screen.Slider)}
					onCancel={props.onClosed}
					onClose={props.onClosed}
				/>
			</Case>
			<Case value={Screen.Slider}>
				<ScoreSlider
					service={service}
					whiteBackground={testingModel.isWhiteBackground}
					question={question}
					onSave={onSave}
					onClose={props.onClosed}
				/>
			</Case>
			<Case value={Screen.Summary}>
				<TestSummary
					note={summary?.notes}
					testType={TestType.Score}
					correct={summary?.correct}
					testID={testId}
					testName={testingModel.testName}
					total={testingModel.totalPossible}
					studentName={testingModel.studentName}
					testSessionID={summary?.testSessionID}
					showPieChart={testingModel.showTestSessionResults}
					iep={{testId, studentId}}
					hierarchy={props.hierarchy}
					subject={props.subject}
					onTestHistoryClicked={props.onTestHistoryClicked}
					onTestSessionDetailsClicked={props.onTestSessionDetailsClicked}
					onIEPEdit={() => setScreenType(Screen.IEPEdit)}
					onIEPReport={() => setScreenType(Screen.IEPReport)}
					onClose={props.onClosed}
				/>
			</Case>
			{showTestSessionStatusAlert &&
				<SessionStatusAlert
					status={mapToEnum(testingModel?.testSessionStatus, TestSessionStatus)}
					onConfirm={props.onClosed}
					studentName={testingModel.studentName}
					testName={testingModel.testName}
				/>
			}
			<Case value={Screen.IEPEdit}>
				<IepGoalFormModal
					hierarchy={props.hierarchy}
					subject={props.subject}
					onClose={props.onClosed}
					testID={testingModel.testID}
				/>
			</Case>
			<Case value={Screen.IEPReport}>
				<IepProgressReport
					selected={[testId]}
					studentId={studentId}
					subjectId={props.subject.id}
					hierarchy={props.hierarchy}
					onClose={props.onClosed}
				/>
			</Case>
		</Switch>
	);
}
