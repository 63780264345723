import React, {createRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {ModalManager} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

interface Props {
	onClosed: () => void;
}

export default class AlreadySavedDialog extends React.PureComponent<Props> {
	private modalManagerRef: React.RefObject<ModalManager> = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef}>
			<Alert.Body>
				The test session has ended due to inactivity for more than an hour. The test results have been saved.
				Please return to the homepage where you can begin a new test session.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={() => this.modalManagerRef.current.close(() => this.props.onClosed())}>
					CANCEL
				</Buttons.Text>
				<Buttons.Text onClick={() => this.modalManagerRef.current.close(() => this.props.onClosed())}>
					OK
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}
}
