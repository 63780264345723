import {useCallback, useEffect} from 'react';
import {Virtualizer} from '@tanstack/react-virtual';
import {ColumnsCountType, ViewMode} from '../../../../types';
import {pageSize, tileCardWidth} from '../../../../constants';

type VirtualListConfigProps = {
	total: number;
	viewColumnsCount: number;
	loadedQuestionsCount: number;
}

type ViewModeListenerProps = {
	virtualizer: Virtualizer<HTMLElement, Element>;
	containerWidth: number;
	selectedView: ViewMode,
	setViewColumnsCount: (value: ColumnsCountType) => void;
}

export const useVirtualListConfig = ({total, loadedQuestionsCount, viewColumnsCount}: VirtualListConfigProps) => {
	const totalRows = Math.ceil(total/viewColumnsCount);
	const totalLoadedRows = Math.ceil(loadedQuestionsCount/viewColumnsCount);
	const hasMoreRows = total && loadedQuestionsCount < total;
	const targetPageSize = pageSize * viewColumnsCount;

	return {totalRows, hasMoreRows, targetPageSize, totalLoadedRows};
};

export const useViewModeListener = ({
	virtualizer,
	containerWidth,
	selectedView,
	setViewColumnsCount,
}: ViewModeListenerProps) => {
	const recalculateColumns = useCallback((value: ViewMode) => {
		if (value === ViewMode.List) {
			setViewColumnsCount(1);
		} else {
			const maxColumns = Math.ceil(containerWidth / tileCardWidth);
			setViewColumnsCount( maxColumns > 4 ? 4 : (maxColumns || 1) as ColumnsCountType );
		}

		virtualizer.measure();
	}, [virtualizer, setViewColumnsCount, containerWidth]);

	useEffect(() => {
		recalculateColumns(selectedView);
	}, [selectedView, recalculateColumns]);

	useEffect(() => {
		const onWindowResize = () => {
			recalculateColumns(selectedView);
		};

		window.addEventListener('resize', onWindowResize);

		return () => {
			window.removeEventListener('resize', onWindowResize);
		};
	}, [recalculateColumns, selectedView]);
};