import {IEPGoalFormService} from './service';
import {EditContainer} from './variants/edit/edit-container';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

export interface Props {
	service: IEPGoalFormService;
	editMode: boolean;
}

export default function IEPGoalTab(props: Props) {
	const {service, editMode} = props;
	const iepGoal = useBehaviorSubject(service.iepGoal$);

	return (
		<>
			{iepGoal &&
				<EditContainer
					service={service}
					editMode={editMode}
				/>
			}
			<SnackbarLayout/>
		</>
	);
}
