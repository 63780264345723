import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardWrapper = styled(Box, {
	cursor: 'grab',

	variants: {
		isDraggedItem: {
			true: {
				opacity: 0.4,
				cursor: 'grabbing',
			},
		},
	},
});
