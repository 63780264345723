import {Drawer} from '@esgi/main/kits/common';
import {styled} from '@esgi/ui/theme';
import {ItemId} from './types';

export const IconWrapper = styled('span', {
	lineHeight: '12px',
	'& > svg': {
		width: 24,
		height: 24,
		fill: '$neutral56',

		'& > path': {
			fill: '$neutral56',
		},
	},
});

export const DrawerPanel = styled(Drawer.Panel, {
	variants: {
		layout: {
			[ItemId.Login]: {},
			[ItemId.PersonalInformation]: {},
			[ItemId.SchoolAndDistrict]: {},
			[ItemId.MarkingPeriods]: {
				gridTemplateAreas: `
					"sidebar markingPeriodSidebar header"
					"sidebar markingPeriodSidebar content"
				`,
				gridTemplateColumns: 'auto auto 1fr',
				gridTemplateRows: 'auto 1fr',
			},
		},
	},
});
