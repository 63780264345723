import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	titleText: string;
};

export function CardTitle({titleText}: Props) {
	return (
		<OneLinedText dataCy='test-name' size='xxLarge' color='neutral16'>
			{titleText}
		</OneLinedText>
	);
}