import {Dispatch, SetStateAction} from 'react';
import {TestInfo, TestSession} from '../../../../../../../types';
import {SessionItem} from '../session-list/types';
import {LegendWithTranscriptItem} from '../../../../legend-with-transcript';

export enum CheckboxValue {
	DetailedView = 'Detailed View',
	AllSessions = 'AllSessions',
}

export type SynchronizedStateProps = {
	testInfo: TestInfo;
	isDetailedViewChecked: boolean;
	toggleDetailedViewCheckbox: VoidFunction;
	isAllSessionsChecked: boolean;
	toggleAllSessionsCheckbox: VoidFunction;
	isAllSessionsSelected: boolean;
	selectedSessionIds: TestSession['id'][];
	setSelectedSessionIds: Dispatch<SetStateAction<TestSession['id'][]>>;
	sessionItems: SessionItem[];
	selectedSessions: TestSession[];
	diagramLegendItems: LegendWithTranscriptItem[];
	diagramPageIndex: number;
	setDiagramPageIndex: Dispatch<number>;
	isTablet: boolean;
};
