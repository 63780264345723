import {Cell} from 'shared/modules/question-editor/mx/editor/color-picker/color-cell/color-cell';
import {TextInput, Validators} from '@esgi/deprecated/elements';
import React from 'react';
import {isIpad} from '@esgillc/ui-kit/utils';
import './color-picker.less';

export class Props {
	color: string = '';
	title: string;
	bottomPlace?: boolean;
	onSelect: (value: string) => any;
	mostRecentColors: string[] = [];
	mostRecentColorsChanged: (colors: string[]) => void;
}

export class State {
	opened: boolean = false;
	color: string = '';
}

export class ColorPicker extends React.PureComponent<Props, State> {
	colors: string[];
	state: State;

	private isIpad: boolean = isIpad();
	private ref: HTMLElement;

	constructor(props, context) {
		super(props, context);
		this.state = new State();

		this.colors = [
			'none', 'FFFFFF', 'E2E2E2', 'C5C5C5', 'AAAAAA', '8E8E8E', '757575', '5B5B5B', '444444', '2D2D2D', '191919', '000000',
			'FEC4C6', 'FFE1C6', 'FFFEC7', 'E4FEC7', 'C8FFC7', 'C8FFE3', 'C7FFFF', 'C5E2FE', 'C4C6FD', 'DFC6FD', 'FDC5FE', 'FDC5E2',
			'FC8C8F', 'FEC391', 'FFFD93', 'C9FE93', '95FE92', '94FFC6', '93FFFE', '8EC7FD', '8B91FC', 'C290FC', 'FB8FFD', 'FC8EC4',
			'FC575D', 'FEA760', 'FFFD65', 'B0FE63', '68FE63', '66FFAC', '63FFFE', '5BABFC', '5661FB', 'A45FFC', 'FA5DFC', 'FB59A9',
			'FB2431', 'FD8B36', 'FFFD3F', '96FE3D', '45FE3C', '43FF92', '3DFFFE', '2A92FC', '1F38FB', '8836FB', 'F931FC', 'FB288E',
			'FB0015', 'FD711C', 'FFFD2D', '7FFE29', '35FE28', '33FF7A', '2AFFFE', '0078FB', '0022FB', '6D1FFB', 'F914FC', 'FB0075',
			'C20010', 'C35815', 'C7C323', '63C420', '29C41F', '27C55F', '20C6C4', '005FC2', '001AC2', '5618C2', 'C00FC2', 'C2005B',
			'8C000B', '8D4110', '908D19', '498D17', '1E8E16', '1C8E46', '178F8E', '00458C', '00138C', '3F118C', '8B0B8C', '8C0044',
			'5A0007', '5A2C0A', '5C5A10', '305B0F', '135B0E', '125B2E', '0F5C5B', '002E5A', '000C5A', '2A0B5A', '59075A', '5A002D',
			'2C0004', '2D1805', '2D2C08', '1A2D07', '092D07', '092D19', '072D2D', '00192C', '00062C', '17052C', '2C042C', '2C0019',
		];
	}

	componentDidMount() {
		this.setState({color: this.setDefaultFormat(this.props.color)});
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.color !== this.props.color) {
			this.setState({color: this.setDefaultFormat(this.props.color)});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	handleClickOutside(event) {
		if (this.ref && !this.ref.contains(event.target)) {
			this.setState({opened: false});
		}
	}

	setColor(value: string, valid: boolean = true) {
		this.setState({color: value});

		if (valid) {
			const newColorHex = this.setHexFormat(value);
			const newColorDefault = this.setDefaultFormat(value);

			if (this.props.color !== newColorHex) {
				this.addToLastColors(newColorDefault);
				this.props.onSelect(newColorHex);
			}
		}
	}

	renderColors(colors: string[]) {
		return colors.map((color) => {
			return <Cell
				key={color}
				onClick={(color: string) => this.setColor(color)}
				color={color}
				selected={this.setDefaultFormat(this.props.color).toLowerCase() === color.toLowerCase()}/>;
		});
	}

	addToLastColors(color: string) {
		let exists = this.props.mostRecentColors.filter(x => x.toLowerCase() === color.toLowerCase()).length > 0;

		if (!exists) {
			let mostRecentColors = [...this.props.mostRecentColors];

			if (mostRecentColors.length === 12) {
				mostRecentColors.splice(-1, 1);
			}

			mostRecentColors.unshift(color);
			this.props.mostRecentColorsChanged(mostRecentColors);
		}
	}

	renderMostRecentColors() {
		let result = [];

		for (let i = 0; i < 12; i++) {
			if (this.props.mostRecentColors.length > i) {
				const color = this.props.mostRecentColors[i];

				result.push(<Cell
					onClick={(color: string) => this.setColor(color)}
					color={color}
					selected={this.setDefaultFormat(this.props.color).toLowerCase() === color.toLowerCase()}/>);
			} else {
				const color = 'FFFFFF';

				result.push(<Cell
					className='empty'
					onClick={() => {
					}}
					color={color}
					selected={false}/>);
			}
		}

		return result;
	}

	setHexFormat(color: string) {
		if (color === 'none') {
			return 'transparent';
		}

		if (color.indexOf('#') !== -1) {
			return color;
		}

		return '#' + color;
	}

	setDefaultFormat(color: string) {
		if (color === 'transparent') {
			return 'none';
		}

		if (color.indexOf('#') === -1) {
			return color;
		}

		return color.substring(1);
	}

	get ipadClass() {
		let cl = 'mx-question-editor-color-picker';

		if (this.isIpad) {
			cl += ' ipad';
		}

		if (this.props.bottomPlace) {
			cl += ' bottom';
		}

		return cl;
	}

	toggle() {
		this.setState({opened: !this.state.opened});
	}

	render() {
		return <div className='mx-question-editor-color-picker-container' ref={(r) => this.ref = r}>
			<div className='color-picker-header'>{this.props.title}</div>
			<div
				className={this.props.color === 'transparent' ? 'toolbar-item color-picker outlined' : 'toolbar-item color-picker'}
				style={{backgroundColor: this.props.color}}
				onMouseDown={(e) => e.preventDefault()}
				onMouseUp={(e) => e.preventDefault()}
				onClick={() => this.toggle()}>
			</div>
			{this.state.opened && <div className={this.ipadClass}>
				<div className='last-colors'>
					{this.renderMostRecentColors()}
				</div>
				<div className='all-colors'>
					{this.renderColors(this.colors)}
				</div>
				<TextInput
					onEdit={(value, validation) => this.setColor(value, validation.valid)}
					value={this.state.color}
					touched={true}
					validator={(value: string) => Validators.colorHexValidator(value)}
					label='Hex:'
					className='color-picker-hex-input'
				/>
			</div>}
		</div>;
	}
}

export function MostRecentColorsChanged(args: string[]): string[] {
	return args;
}
