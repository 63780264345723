import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Panel, Title} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {isUndefined} from '@esgi/ui';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'title'> & {
	title?: string | undefined;
};

export const Component = forwardRef<HTMLDivElement, Props>(({title, children, ...props}, forwardedRef) => (
	<Panel {...props} ref={forwardedRef}>
		{!isUndefined(title) && (
			<Title>
				<Text data-cy='title' size='small' font='mono' color='lowContrast'>
					{title}
				</Text>
			</Title>
		)}
		{children}
	</Panel>
));

export const SettingsPanel = Object.assign(Component, {
	Title,
});
