import React from 'react';
import {LinkButton} from '@esgi/deprecated/ui-kit/buttons';
import {useState} from '@esgi/deprecated/react';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../operation.module.less';

class State {
	firstTimeSelected: boolean = null;
}

class Props {
	hasSelectedStudents: boolean;
	onClicked: (e: React.MouseEvent) => void;
}

@useState(State)
export default class AssignButton extends React.PureComponent<Props, State> {
	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (this.state.firstTimeSelected == prevState.firstTimeSelected) {
			if (!prevProps.hasSelectedStudents && this.props.hasSelectedStudents && this.state.firstTimeSelected == null) {
				this.setState({firstTimeSelected: true});
			}
			if (this.state.firstTimeSelected) {
				this.setState({firstTimeSelected: false});
			}
		}
	}

	public render() {
		return <LinkButton className={join(styles.operationButton, this.state.firstTimeSelected && styles.firstSelected)}
		                   onClick={(e) => this.props.onClicked(e)} title='Assign student(s) to a class/group'>
			<svg width='23' height='17' viewBox='0 0 23 19' fill='none'
			     xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M9 0H2.25C1.0125 0 0.01125 1.0125 0.01125 2.25L0 15.75C0 16.9875 1.0125 18 2.25 18H20.25C21.4875 18 22.5 16.9875 22.5 15.75V4.5C22.5 3.2625 21.4875 2.25 20.25 2.25H11.25L9 0Z'
					fill='#0088CC'/>
				<path
					d='M12.0996 8.83691H15.2725V11.1309H12.0996V14.7168H9.68262V11.1309H6.50098V8.83691H9.68262V5.40039H12.0996V8.83691Z'
					fill='white'/>
			</svg>
			<div>Assign</div>
		</LinkButton>;
	}
}
