import {environment} from '@esgi/core/environments';
import {DefaultErrorHandler} from './handler/default-handler';
import NotAuthorizedHandler from './handler/not-authorized-handler';
import {HttpClient} from '@esgi/api';
import MoveUserHandler from './handler/move-user-handler';

export class HttpClientBuilder {
	public static build(): HttpClient {
		return new HttpClient([new MoveUserHandler(), new NotAuthorizedHandler(), new DefaultErrorHandler()], environment);
	}
}
