import {BaseComponentProps, Skeletonable, ThemeColorKeys} from '../../../types';
import {ComponentPropsWithoutRef, forwardRef, useMemo, useState} from 'react';
import {Svg} from './index.styled';
import {useBoxplotChartContext} from '../../context';
import {CSS} from '@stitches/react';
import {getThemeColor} from '../../../utils';
import {getStrokeLinecapRoundRadius} from './utils/get-stroke-linecap-round-radius';
import {LineModel} from './types';
import {getCubeModel} from './utils/get-cube-model';
import {animated} from 'react-spring';
import {useAnimationData} from './hooks/use-animation-data';

export type BoxplotChartCandleProps = Omit<BaseComponentProps, 'css'> &
	Skeletonable &
	Omit<ComponentPropsWithoutRef<'svg'>, 'children'> & {
		/**
		 * Returns a Style interface from a configuration, leveraging the given media and style map.
		 * @default {}
		 */
		css?: Omit<CSS, 'width' | 'height'>;

		/**
		 * Set width for the main bar line in svg.
		 * @default 1
		 */
		width?: number;
		/**
		 * Set height for the main bar line in svg.
		 * @default 200
		 */
		height?: number;

		/**
		 * Set the main bar line color.
		 * @default 'mild'
		 */
		mainBarColor?: ThemeColorKeys;

		/**
		 * Set color for the not active quartiles.
		 * @default 'lowContrast'
		 */
		nonActiveQuartileBarColor?: ThemeColorKeys | undefined;

		/**
		 * Set color for the active quartile.
		 * @default 'highContrast'
		 */
		activeQuartileBarColor?: ThemeColorKeys | undefined;

		/**
		 * Set fill color for pointer.
		 * @default 'vivid'
		 */
		pointerFillColor?: ThemeColorKeys;
	};

export const BoxplotChartCandle = forwardRef<SVGSVGElement, BoxplotChartCandleProps>(
	(
		{
			dataCy = 'ui-kit-boxplot-chart-candle',
			css = {},
			skeleton,
			width = 1,
			height = 200,
			mainBarColor = 'mild',
			nonActiveQuartileBarColor = 'lowContrast',
			activeQuartileBarColor = 'highContrast',
			pointerFillColor = 'vivid',
			...svgProps
		},
		forwardedRef,
	) => {
		const {chartMinValueProgress, chartMaxValueProgress, quartile1Progress, quartile2Progress, quartile3Progress} =
			useBoxplotChartContext();

		const [svgWidth, setSvgWidth] = useState(0);

		const svgValues = useMemo(
			() => ({
				width: svgWidth,
				height: height,
				center: {
					x: svgWidth / 2,
					y: height / 2,
				},
			}),
			[height, svgWidth],
		);

		const mainBar = useMemo<LineModel>(() => {
			const strokeWidth = width;
			const strokeLinecapRoundRadius = getStrokeLinecapRoundRadius(strokeWidth);

			const x1 = svgValues.center.x;
			const y1 = svgValues.height - strokeLinecapRoundRadius;

			const x2 = x1;
			const y2 = 0 + strokeLinecapRoundRadius;

			return {
				x1,
				y1,
				x2,
				y2,
				strokeWidth,
				strokeColor: getThemeColor(mainBarColor),
				strokeLinecap: 'round',
			};
		}, [svgValues, width, mainBarColor]);

		const cubeInfo = useMemo(
			() => ({
				diagonalWidth: 6 * width,
			}),
			[width],
		);

		const startCube = useMemo(() => {
			const x1 = svgValues.center.x;
			const y1 = svgValues.height - svgValues.height * chartMinValueProgress;

			const x2 = x1;
			const y2 = y1 - cubeInfo.diagonalWidth;

			return getCubeModel({
				diagonalX1: x1,
				diagonalY1: y1,
				diagonalX2: x2,
				diagonalY2: y2,
			});
		}, [svgValues, chartMinValueProgress, cubeInfo]);

		const endCube = useMemo(() => {
			const x1 = svgValues.center.x;
			const y1 = svgValues.height - svgValues.height * chartMaxValueProgress;

			const x2 = x1;
			const y2 = y1 + cubeInfo.diagonalWidth;

			return getCubeModel({
				diagonalX1: x1,
				diagonalY1: y1,
				diagonalX2: x2,
				diagonalY2: y2,
			});
		}, [svgValues, chartMaxValueProgress, cubeInfo.diagonalWidth]);

		const nonActiveQuartileBar = useMemo<LineModel>(() => {
			const x1 = svgValues.center.x;
			const y1 = startCube.center.y;

			const x2 = x1;
			const y2 = endCube.center.y;

			return {
				x1,
				y1,
				x2,
				y2,
				strokeLinecap: 'butt',
				strokeColor: getThemeColor(nonActiveQuartileBarColor),
				strokeWidth: mainBar.strokeWidth,
			};
		}, [svgValues, startCube, endCube, nonActiveQuartileBarColor, mainBar]);

		const activeQuartileBar = useMemo<LineModel>(() => {
			const strokeWidth = 5 * width;

			const strokeLinecapRoundRadius = getStrokeLinecapRoundRadius(strokeWidth);

			const x1 = svgValues.center.x;
			const y1 = height - height * quartile1Progress - strokeLinecapRoundRadius;

			const x2 = x1;
			const y2 = height - height * quartile3Progress + strokeLinecapRoundRadius;

			return {
				x1,
				y1,
				x2,
				y2,
				strokeLinecap: 'round',
				strokeColor: getThemeColor(activeQuartileBarColor),
				strokeWidth,
			};
		}, [svgValues, height, quartile1Progress, quartile3Progress, activeQuartileBarColor, width]);

		const pointerInfo = useMemo(() => {
			const strokeWidth = activeQuartileBar.strokeWidth * 0.3;
			const diameter = activeQuartileBar.strokeWidth * 1.6;

			const radius = diameter / 2;

			const size = 2 * strokeWidth + diameter;

			setSvgWidth(size);

			return {
				size,
				diameter,
				radius,
				strokeWidth,
				center: {
					x: size / 2,
					y: height - height * quartile2Progress,
				},
			};
		}, [quartile2Progress, height, activeQuartileBar]);

		const {
			mainBarAnimationBarProps,
			nonActiveAnimationBarProps,
			activeAnimationBarProps,
			cubeAnimationProps,
			pointerAnimationProps,
		} = useAnimationData({mainBar, nonActiveQuartileBar, activeQuartileBar});

		const barsWithAnimationProps = useMemo(
			() => [
				{
					bar: mainBar,
					animationProps: mainBarAnimationBarProps,
				},
				{
					bar: nonActiveQuartileBar,
					animationProps: nonActiveAnimationBarProps,
				},
				{
					bar: activeQuartileBar,
					animationProps: activeAnimationBarProps,
				},
			],
			[
				activeAnimationBarProps,
				activeQuartileBar,
				mainBar,
				mainBarAnimationBarProps,
				nonActiveAnimationBarProps,
				nonActiveQuartileBar,
			],
		);

		return (
			<Svg
				data-cy={dataCy}
				xmlns='http://www.w3.org/2000/svg'
				viewBox={`0 0 ${svgValues.width} ${svgValues.height}`}
				css={{
					width: svgValues.width,
					height: svgValues.height,
					...css,
				}}
				fill='none'
				skeleton={Boolean(skeleton)}
				ref={forwardedRef}
				{...svgProps}
			>
				{!skeleton && (
					<>
						{barsWithAnimationProps.map(
							({bar: {x1, y1, x2, strokeColor, strokeLinecap, strokeWidth}, animationProps: {y: y2}}, index) => (
								<animated.line
									x1={x1}
									y1={y1}
									x2={x2}
									y2={y2}
									stroke={strokeColor}
									strokeWidth={strokeWidth}
									strokeLinecap={strokeLinecap}
									key={index}
								/>
							),
						)}

						{[startCube, endCube].map(({x1, y1, x2, y2, x3, y3, x4, y4}, index) => (
							<animated.path
								style={cubeAnimationProps}
								fill={getThemeColor(nonActiveQuartileBarColor)}
								d={`M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3} ${x4} ${y4} Z`}
								key={index}
							/>
						))}

						<animated.circle
							style={pointerAnimationProps}
							cx={pointerInfo.center.x}
							cy={pointerInfo.center.y}
							r={pointerInfo.radius}
							strokeWidth={pointerInfo.strokeWidth}
							fill={getThemeColor(pointerFillColor)}
							stroke={getThemeColor(activeQuartileBarColor)}
						/>
					</>
				)}
			</Svg>
		);
	},
);
