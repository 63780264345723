import {forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {Box, BoxProps} from '../box';
import {VariantProps} from '@stitches/react';

type GridBoxProps = BoxProps & VariantProps<typeof Grid>;

export const GridBox = forwardRef<HTMLDivElement, GridBoxProps>(({dataCy, ...props}, forwaredRef) => (
	<Grid data-cy={dataCy ?? 'ui-kit-grid-box'} ref={forwaredRef} {...props} />
));

const Grid = styled(Box, {
	variants: {
		display: {
			grid: {
				display: 'grid',
			},
			inlineGrid: {
				display: 'inline-grid',
			},
			none: {
				display: 'none',
			},
		},
		columns: {
			1: {
				gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
			},
			2: {
				gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
			},
			3: {
				gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
			},
			4: {
				gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
			},
		},
		rows: {
			1: {
				gridTemplateRows: 'repeat(1, minmax(0, 1fr))',
			},
			2: {
				gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
			},
			3: {
				gridTemplateRows: 'repeat(3, minmax(0, 1fr))',
			},
			4: {
				gridTemplateRows: 'repeat(4, minmax(0, 1fr))',
			},
		},
		flow: {
			row: {
				gridAutoFlow: 'row',
			},
			column: {
				gridAutoFlow: 'column',
			},
			dense: {
				gridAutoFlow: 'dense',
			},
			rowDense: {
				gridAutoFlow: 'row dense',
			},
			columnDense: {
				gridAutoFlow: 'column dense',
			},
		},
		align: {
			start: {
				alignItems: 'start',
			},
			center: {
				alignItems: 'center',
			},
			end: {
				alignItems: 'end',
			},
			stretch: {
				alignItems: 'stretch',
			},
			baseline: {
				alignItems: 'baseline',
			},
		},
		justify: {
			start: {
				justifyContent: 'start',
			},
			center: {
				justifyContent: 'center',
			},
			end: {
				justifyContent: 'end',
			},
			between: {
				justifyContent: 'space-between',
			},
		},
		alignContent: {
			start: {
				alignContent: 'start',
			},
			end: {
				alignContent: 'end',
			},
			center: {
				alignContent: 'center',
			},
		},
		gap: {
			1: {
				gap: '$1',
			},
			2: {
				gap: '$2',
			},
			3: {
				gap: '$3',
			},
			4: {
				gap: '$4',
			},
			5: {
				gap: '$5',
			},
			6: {
				gap: '$6',
			},
			7: {
				gap: '$7',
			},
			8: {
				gap: '$8',
			},
			9: {
				gap: '$9',
			},
		},
		gapX: {
			1: {
				columnGap: '$1',
			},
			2: {
				columnGap: '$2',
			},
			3: {
				columnGap: '$3',
			},
			4: {
				columnGap: '$4',
			},
			5: {
				columnGap: '$5',
			},
			6: {
				columnGap: '$6',
			},
			7: {
				columnGap: '$7',
			},
			8: {
				columnGap: '$8',
			},
			9: {
				columnGap: '$9',
			},
		},
		gapY: {
			1: {
				rowGap: '$1',
			},
			2: {
				rowGap: '$2',
			},
			3: {
				rowGap: '$3',
			},
			4: {
				rowGap: '$4',
			},
			5: {
				rowGap: '$5',
			},
			6: {
				rowGap: '$6',
			},
			7: {
				rowGap: '$7',
			},
			8: {
				rowGap: '$8',
			},
			9: {
				rowGap: '$9',
			},
		},
	},
	defaultVariants: {
		display: 'grid',
	},
});
