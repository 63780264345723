import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const ButtonIcon = styled(Button.Icon, {
	'& svg': {
		transition: 'transform .3s',

		'& rect': {
			fill: 'currentColor',
		},
	},

	variants: {
		isActive: {
			true: {
				'& svg': {
					transform: 'rotate(90deg)',
				},
			},

			false: {
				'& svg': {
					transform: 'rotate(-90deg)',
				},
			},
		},
	},
});
