import {useState, useEffect, useMemo} from 'react';
import {useEventEffect} from '@esgillc/events';
import {AnswerPanelState} from './types';
import {AnswerState} from '@esgi/core/enums';
import {Answer as AnswerType} from '../../../../types';
import {AnswerState as AnswerComponentState} from './components/answer/types';
import {ChangeActivePanelEvent} from '../../../yes-no/events';

export const useAnswerPanel = (
	statePanel: AnswerPanelState,
	answers: AnswerType[],
	answerState: AnswerState,
	onChange: (state: AnswerPanelState) => void,
) => {
	const [state, setState] = useState(statePanel);

	const isActive = useMemo<boolean>(() => {
		return !!state.selectedAnswers.length;
	}, [state]);

	const resetCheckboxes = () => {
		if (!isActive) {
			return;
		}
		setState({
			...state,
			selectedAnswers: [],
		});
	};

	useEffect(() => {
		let areEqual = true;
		if (state.prevAnswers.length === answers.length) {
			const ids = answers.map(({id}) => id);
			state.prevAnswers.forEach(({id}) => {
				areEqual = areEqual && ids.includes(id);
			});
		} else {
			areEqual = false;
		}

		if (areEqual) {
			return;
		}

		const prevAnswers = [...answers];
		const orderedAnswers = prevAnswers;
		orderedAnswers.sort((a1, a2) => {
			if (a1.testDate.isAfter(a2.testDate)) {
				return -1;
			}
			if (a1.testDate.isBefore(a2.testDate)) {
				return 1;
			}
			return 0;
		});

		const currentAnswers = (orderedAnswers.length)
			? []
			: null;

		if (!currentAnswers) {
			setState({
				...state,
				selectedAnswers: [],
				stateAnswers: [],
				answers: [],
				prevAnswers,
			});

			return;
		} else {
			const stateAnswers = [];

			for (let i = 0; i < orderedAnswers.length; i++) {
				const answer = orderedAnswers[i];

				if (i === 0 || answer.testDate.format('MM-DD-YY') !== orderedAnswers[i - 1].testDate.format('MM-DD-YY')) {
					currentAnswers.push([]);
				}

				currentAnswers[currentAnswers.length - 1].push(answer);
				stateAnswers.push(new AnswerComponentState());
			}

			setState({
				selectedAnswers: [],
				stateAnswers,
				answers: currentAnswers,
				prevAnswers,
			});
		}
	}, [state, answers]);

	useEffect(() => {
		onChange(state);
	}, [state]);

	useEventEffect(ChangeActivePanelEvent, ({activePanel}) => {
		if (activePanel === answerState) {
			return;
		}
		resetCheckboxes();
	});

	return {
		state,
		setState,
		isActive,
	};
};
