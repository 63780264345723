import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Content = styled(Box, {
	display: 'inline-grid',

	'&[data-orientation=horizontal]': {
		gridAutoFlow: 'column',
		alignItems: 'center',
	},

	'&[data-orientation=vertical]': {
		gridAutoFlow: 'row',
	},
});
