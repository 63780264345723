import React from 'react';
import ViewerEntry
	from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/viewer-entry/component';
import {IGradeRangeView} from 'shared/modules/grade-scale/grade-range/entries-panel/viewer/models';

export class Props {
	gradeRanges: IGradeRangeView[] = [];
	maxSize?: boolean;
}

export default class GradeRangeViewer extends React.PureComponent<Props> {
	render() {
		return <div className='grade-range-editor-component'>
			{this.props.gradeRanges.map((e, index) => {
				return <ViewerEntry
					key={e.id}
					entry={e} 
					last={index === this.props.gradeRanges.length - 1} 
					maxSize={this.props.maxSize}
				/>;
			})}
		</div>;
	}
}