import {HelpLink, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Form, FormControl, FormElement, FormGroup, useForm} from '@esgillc/ui-kit/form';
import {Radio} from '@esgillc/ui-kit/form-control';
import {Buttons} from '@esgillc/ui-kit/button';
import {Example} from 'shared/modules/user-settings/components/example';
import {helpLink, withoutScoresExampleImageUrl, withScoresExampleImageUrl} from './constants';
import styles from './styles.module.less';

interface Props {
	onClose: () => void;
	showPieChartsResults: boolean;
	onSave: (showPieChartsResults: boolean) => void;
}

export default function TestSessionResultsModal({showPieChartsResults, onSave, onClose}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	const form = useForm(() => new FormGroup({
		showResults: new FormControl<number>(Number(showPieChartsResults)),
	}));

	return (
		<Modal modalManagerRef={modalRef}>
			<Modal.Header>
				<Title>
					Test Session Results View
					<HelpLink url={helpLink}/>
				</Title>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<p className={styles.description}>View test results as a pie chart with scores or as a graphic without
					scores.</p>
				<Form controller={form}>
					<FormElement control={form.controls.showResults}>
						<div data-cy='with-scores'>
							<Radio value={1} className={styles.radio}>
								With scores
							</Radio>
						</div>
						<Example
							imgUrl={withScoresExampleImageUrl}
						/>
						<div data-cy='without-scores'>
							<Radio value={0} className={styles.radio}>
								Without scores
							</Radio>
						</div>
						<Example
							imgUrl={withoutScoresExampleImageUrl}
						/>
					</FormElement>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={handleClose}>
					Cancel
				</Buttons.Gray>
				<Buttons.Contained onClick={() => {
					onSave(Boolean(form.value.showResults));
					handleClose();
				}}>
					Save
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	);
}
