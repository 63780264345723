import {CarouselBox, CarouselContent, CarouselTracker, LeftButton, RightButton} from './styled';
import {isArray} from 'underscore';
import {ReactNode, useLayoutEffect} from 'react';
import {Prev} from '@esgi/ui/icons';
import {useSnapCarousel} from 'react-snap-carousel';
import {CarouselItem1} from './carousel-item';

type Props = {
	children: ReactNode | ReactNode[],
};

export function Carousel(props: Props) {
	const children = isArray(props.children) ? props.children : [props.children];
	const {
		scrollRef,
		pages,
		activePageIndex,
		prev,
		next,
		refresh,
	} = useSnapCarousel();

	useLayoutEffect(() => {
		refresh();
	}, [children, refresh]);

	const hasPages = pages.length !== 1;

	return <CarouselBox>
		<CarouselContent>
			<CarouselTracker withPaddings={hasPages} ref={scrollRef}>
				{children.map((c, index) => <CarouselItem1 active={pages[activePageIndex]?.includes(index)} key={index}>{c}</CarouselItem1>)}
			</CarouselTracker>
		</CarouselContent>
		{hasPages && <LeftButton onClick={() => prev()} disabled={activePageIndex === 0}>
			<Prev width={24}/>
		</LeftButton>}
		{hasPages && <RightButton onClick={() => next()} disabled={activePageIndex === (pages.length - 1)}>
			<Prev/>
		</RightButton>}
	</CarouselBox>;
}