import {styled} from '@esgi/ui/theme';
import {CounterBox, GridBox} from '@esgi/ui/layout';

export const Item = styled(GridBox, {
	padding: '0 12px',
	'& svg > path': {
		fill: '$negative',
	},
	gap: 8,
	gridAutoFlow: 'column',
	justifyContent: 'flex-start',
	alignItems: 'center',
});

export const Title = styled(GridBox, {
	justifyContent: 'center',
	alignItems: 'center',
	padding: '20px 0 12px 0',
	margin: '0 12px',
	borderBottom: '1px solid $mild',
});

export const Container = styled(GridBox, {
	gap: 12,
	width: '100%',
	maxHeight: '70vh',
});

export const HeaderInfo = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: 12,

	'& svg > path': {
		fill: '$primary',
	},
});

export const Counter = styled(CounterBox, {
	backgroundColor: '$vivid',
	borderColor: '$primary80',
	color: '$primary',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '1px 4px',
});