import {useUser} from '@esgi/core/authentication';
import {useEventEffect} from '@esgillc/events';
import {DroppableContainer} from '@esgillc/ui-kit/drag-n-drop';
import {join, useBehaviorSubject, OsChecker} from '@esgillc/ui-kit/utils';
import {SubjectCreatedEvent} from 'api/entities/events/subject';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {useEffect, useMemo, useRef} from 'react';
import {HierarchySnapshot} from '../../../hierarchy/models';
import {ManageSubjectsAndTestsService} from '../../service';
import {Subject} from './components/subject';
import {subjectDroppableType} from './types';
import {useDragDropEffects} from './use-drag-drop-effects';
import {canEditSubject} from './utils';
import styles from './styles.module.less';
import 'overlayscrollbars/overlayscrollbars.css';

interface Props {
	service: ManageSubjectsAndTestsService;
	hierarchy: HierarchySnapshot;
}

export function Body({hierarchy, service}: Props) {
	const user = useUser();
	const isMac = useMemo(() => OsChecker.isMac(), []);
	const subjects = useBehaviorSubject(service.subjects);
	const osRef = useRef<OverlayScrollbarsComponentRef>();

	useEventEffect(SubjectCreatedEvent, () => {
		const viewport = osRef.current?.osInstance().elements().viewport;
		setTimeout(() => {
			if(viewport) {
				viewport.scroll({top: viewport.scrollHeight * 2, behavior: 'smooth'});
			}
		}, 500);
	});

	const {draggingSubjectIdx, draggingTest} = useDragDropEffects(subjects, service);

	useEffect(() => {
		service.init();
	}, []);

	const disabledSubjectIDs = useMemo(() => {
		if (!draggingTest) {
			return [];
		}
		return subjects.filter(s => {
			if (s.id !== draggingTest.subjectID) {
				return s.tests.map(t => t.id).includes(draggingTest.testID);
			}
			return false;
		}).map(s => s.id);
	}, [draggingTest]);

	const droppingEnabled = (subjectID: number) => disabledSubjectIDs.indexOf(subjectID) !== -1;

	return <div className={styles.mainContainer}>
		<p className={styles.subHeader}>
			Organize your homepage by adding, removing, or reordering subject tabs and tests.
		</p>
		<h4>My Subject Tabs and Tests</h4>
		<div className={styles.subjectList}>
			<div className={styles.header}>
				<div className={join(styles.cell, styles.order)}>Order</div>
				<div className={join(styles.cell, styles.handle)}>Drag</div>
				<div className={join(styles.cell, styles.name)}>Subject Tab</div>
				<div className={styles.cell}>Type</div>
				<div className={join(styles.cell, styles.showHide)}>Show/Hide</div>
				<div className={styles.cell}>Edit</div>
				<div className={styles.cell}>Delete</div>
			</div>
			<OverlayScrollbarsComponent className={styles.overlayScrollbars} ref={osRef}>
				<div className={styles.body}>
					<DroppableContainer
						className={styles.droppableContainer}
						droppableId={subjectDroppableType} type={subjectDroppableType} cursor={isMac && 'grabbing' || 'move'}>
						{subjects.map((s, i) => (
							<Subject
								index={i}
								key={`${s.id}-${s.subjectType}`}
								subject={s}
								hierarchy={hierarchy}
								justAdded={s.justAdded}
								dragging={i === draggingSubjectIdx}
								droppingDisabled={droppingEnabled(s.id)}
								removeSubjectClicked={() => service.removeSubject(s)}
								canEdit={canEditSubject(s.subjectType, user.userType)}
								removeTestClicked={(testID) => service.removeTest(s, testID)}
								onHideToggled={(visible) => service.toggleSubjectVisibility(s, visible)}
							/>
						))}
					</DroppableContainer>
				</div>
			</OverlayScrollbarsComponent>
		</div>
	</div>;
}
