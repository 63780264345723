import {TabId} from '../../types';
import {TabItemModel} from './types';

export const tabItems: TabItemModel[] = [
	{
		tabId: TabId.QuickView,
		label: 'Quick View',
	},
	{
		tabId: TabId.DetailedView,
		label: 'Detailed View',
	},
];
