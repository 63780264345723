import {UserType, useUser} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';
import {withNewOldReports} from '../../old-reports';

const NewRubricResults = lazyComponent(() => import('./teacher'));
const OldRubricResults = lazyComponent(() => import('../../old-reports/class/rubric'));

const RubricResults = NewRubricResults;

export default function RubricReport() {
	const user = useUser();

	switch (user?.userType) {
		case UserType.T:
			return <RubricResults/>;
	}
}