import {Card} from '../../models';
import styles from './test-card.module.less'; 

type TestCardProps = {
	card: Card;
	onClick: () => void;
};

export function TestCard({card, onClick}: TestCardProps) {
	return (
		<div
			data-cy='test-type-card'
			className={styles.card}
			id={card.id}
			onClick={onClick}
		>
			<div className={styles.cardTitle}>{card.title}</div>
			<div className={styles.imageBlock}>
				<img className={styles.img} alt={card.alt} src={card.imageSrc} />
                {card.subtitle && <div className={styles.ssSubTitle}>{card.subtitle}</div>}
			</div>
			<div className={styles.description}>{card.description}</div>
		</div>
	);
}