import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	width: '100%',
	borderTop: '1px solid $primary92',
	background: 'linear-gradient(180deg, $primaryBackground 0%, $primarySurface 80%)',

	'&::after': {
		content: '',
		width: '100%',
		height: '100%',
		background: 'repeating-linear-gradient(35deg, $surface, $surface 2px, transparent 0, transparent 10px)',
		gridColumnStart: 1,
		gridColumnEnd: -1,
		gridRowStart: 1,
		gridRowEnd: 1,
	},
});

export const ContentWrapper = styled(GridBox, {
	gridColumnStart: 1,
	gridColumnEnd: -1,
	gridRowStart: 1,
	gridRowEnd: 1,
	zIndex: 2,
});

export const Content = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	padding: '16px 20px',
});