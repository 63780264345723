export class PurchaseCompleteTemplate {
	static render() {
		return <div className='order-confirmation' data-bind='var: {step: $data}'>
			<div className='title text-center'>
				Purchase Complete
			</div>
			<section>

				<div className='row'>
					<div className='col-md-10'><b>Subscriptions</b></div>
					<div className='col-md-2'><b>Quantity</b></div>
				</div>
				<ko data-bind='foreach: subscriptions'>
					<div className='row'>
						<div className='col-md-10' data-bind="text: name + ' (' + months + 'mo)'"></div>
						<div className='col-md-2' data-bind='text: quantity'></div>
					</div>
				</ko>
			</section>

			<section>
				<div className='cart-details'>
					<b><span>Your transaction ID: </span></b>
					<span data-bind='text: transactionId'></span>
				</div>
				<div className='cart-details'>
					<b><span>Your order ID: </span></b>
					<span data-bind='text: orderId'></span>
				</div>
			</section>

			<div className='text-center'>
				<b>A copy of your receipt has been emailed to <span data-bind='text: email'></span></b>
			</div>

			<button className='btn btn-block btn-link have-ac' data-bind='click: view.downloadReceipt'>Click here to
				download your receipt.
			</button>
			<div className='clearfix text-center' style={{marginBottom: '25px', marginTop: '10px'}}>
				<button className='btn btn-warning' data-bind='click: view.continue'>Continue to ESGI</button>
			</div>
		</div>

	}
}
