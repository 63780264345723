import {Drawer} from '@esgi/main/kits/common';
import {PanelHeaderTitle} from '../../../../../../../components/panel-header-title';
import {CreateTrackRequest, SchoolYearModel, TrackModel} from '../../../../../../../../../types';
import {useMemo, useRef, useState} from 'react';
import {CheckboxStyled, SetActiveBox} from './index.styled';
import {Info} from '@esgi/ui/icons';
import {Link} from 'react-router-dom';
import {defaultNewMarkingPeriodName, defaultTrackType} from './constants';
import {AddEditModeContent, AddEditModeContentRef} from '../add-edit-mode-content';
import {PotentialDataImpactAlert} from '../../../../../../../components/potential-data-impact';

type Props = {
	schoolYearTypes: SchoolYearModel[] | null;
	createTrackRequest: CreateTrackRequest;
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
	setVisibleTrack: (track: TrackModel) => void;
};

export function AddMode({schoolYearTypes, createTrackRequest, setVisibleTrack, setActiveTrackId}: Props) {
	const [isPotentialDataImpactOpen, setIsPotentialDataImpactOpen] = useState(false);

	const [isDataCreating, setIsDataCreating] = useState(false);
	const [isMarkingPeriodValid, setIsMarkingPeriodValid] = useState(true);

	const {defaultTrackDates} = useMemo(() => {
		const defaultSchoolYearType = schoolYearTypes?.find(({name}) => name === defaultTrackType) ?? schoolYearTypes?.[0];

		return {
			defaultTrackDates: defaultSchoolYearType?.defaultTrackDates,
		};
	}, [schoolYearTypes]);

	const [setAsDefault, setSetAsDefault] = useState<boolean | 'indeterminate'>(true);

	const addEditModeContentRef = useRef<AddEditModeContentRef>();

	const handleCreateTrack = () => {
		const contentData = addEditModeContentRef.current?.getContentData();

		if (!contentData) {
			return;
		}

		const {datesInRowsState, markingPeriodName, markingPeriodType} = contentData;

		setIsDataCreating(true);

		createTrackRequest({
			trackDates: datesInRowsState,
			trackName: markingPeriodName,
			trackType: markingPeriodType,
		}).subscribe({
			next: (createdTrack) => {
				setVisibleTrack({
					...createdTrack,
					schoolYearType: markingPeriodType,
				});

				if (setAsDefault) {
					setActiveTrackId(createdTrack.trackID);
				}

				setIsDataCreating(false);
			},
		});
	};

	const onCreateButtonClick = () => {
		if (setAsDefault) {
			setIsPotentialDataImpactOpen(true);
			return;
		}

		handleCreateTrack();
	};

	return (
		<>
			<Drawer.PanelHeader
				withActionButton
				actionButtonText='Create'
				onActionButtonClick={onCreateButtonClick}
				actionButtonDisabled={!isMarkingPeriodValid || isDataCreating}
			>
				<PanelHeaderTitle title={defaultNewMarkingPeriodName} titleColor='primary' />
			</Drawer.PanelHeader>

			<AddEditModeContent
				defaultMarkingPeriodName={defaultNewMarkingPeriodName}
				defaultMarkingPeriodType={defaultTrackType}
				defaultTrackDates={defaultTrackDates ?? []}
				schoolYearTypes={schoolYearTypes}
				setIsMarkingPeriodValid={setIsMarkingPeriodValid}
				addEditModeContentRef={addEditModeContentRef}
			>
				<SetActiveBox>
					<CheckboxStyled checked={setAsDefault} label='Set As Active' onCheckedChange={setSetAsDefault} />

					<Link to='https://support.esgisoftware.com/hc/en-us/articles/209159566-Marking-Periods' target='_blank'>
						<Info />
					</Link>
				</SetActiveBox>
			</AddEditModeContent>

			{isPotentialDataImpactOpen && (
				<PotentialDataImpactAlert onClose={() => setIsPotentialDataImpactOpen(false)} onProceed={handleCreateTrack} />
			)}
		</>
	);
}
