import moment from 'moment';
import Enumerable from 'linq';
import {AnswerState, TestScreenType} from '@esgi/core/enums';
import {
	AnswerResponse,
	QuestionResponse,
	TestSessionResponse,
} from 'shared/modules/test/test-session-details/api/models';

export namespace Models {
    export class TestSession {
    	id: number;
    	testDate: moment.Moment = moment();
    	number: number;
    	originalTestDate: moment.Moment;
    	deleted: boolean;
    	answers: Answer[] = [];
    	durationString: string;
    	userID: number;
    	userName: string;
    	testScreenType: TestScreenType;


    	get isEmpty(): boolean {
        	return this.id === undefined || this.id <= 0;
    	}

    	get testDateIso() {
    		if (this.id && this.testDate) {
    			return this.testDate.toISOString(true);
    		}

    		return null;
    	}

    	get testDateString() {
        	if (this.id && this.testDate) {
        		let dateStr = this.testDate.format('M-DD-YY h:mm A');
        		if (this.deleted) {
        			return dateStr + ' (deleted)';
        		}
        		return dateStr;
        	}

        	return 'None';
    	}

    	static FromResponse(response: TestSessionResponse): TestSession {
        	let m = new TestSession();
        	m.testDate = moment(response.testDate);
        	m.number = response.number;
        	m.originalTestDate = response.originalTestDate ? moment(response.originalTestDate) : null;
        	m.deleted = response.isDeleted;
        	m.id = response.sessionId;
        	m.durationString = response.durationString;
        	m.userID = response.userID;
        	m.userName = response.userName;
    		m.testScreenType = response.testScreenType;

        	return m;
    	}

    	static FromResponseArray(response: TestSessionResponse[]): TestSession[] {
        	return Enumerable.from(response).orderByDescending(r => r.number)
        		.select(s => TestSession.FromResponse(s)).toArray();
    	}

    	static Empty(): TestSession {
        	let result = new TestSession();
        	result.testDate = moment();
        	return result;
    	}
    }

    export class Answer {
    	answerState: number;
    	comment: string;
    	text: string;
    	inherited: boolean;
    	questionId: number;
    	orderNumber: number;
    	id: number;
    	sessionId: number;
    	testDate: moment.Moment;
    	userID: number;

    	static FromResponse(r: AnswerResponse, questions: Question[], sessions: TestSession[]): Answer {
        	const question = questions.find(q => q.questionId === r.questionID);
    		  const session = sessions.find(s => s.id === (r.parentTestSessionID || r.testSessionID));
        	return Answer.From(r, question, session);
    	}

    	static From(r: AnswerResponse, q: Question, s: TestSession): Answer {
        	let m = new Answer();

        	m.answerState = (r)
        		? AnswerState[r.answerState]
        		: AnswerState.NotTested;

        	if (r) {
        		m.id = r.testSessionAnswerID;
        		m.comment = r.comment || '';
        		m.inherited = !!r.parentTestSessionID;
        	}

        	m.text = q.name;
        	m.questionId = q.questionId;
        	m.orderNumber = q.orderNumber;

        m.sessionId = (r)
          ? r.testSessionID
          : (s)
            ? s.id
            : 0;

        	m.testDate = (s)
        		? moment(s.testDate)
        		: moment();

        	m.userID = (s) ? s.userID : 0;

        	return m;
    	}

    	static FromResponseArray(r: AnswerResponse[], questions: Question[], sessions: TestSession[]): Answer[] {
        	let answers = Enumerable.from(r)
        		.where(a => questions.some(q => q.questionId == a.questionID))
        		.select(a => Answer.FromResponse(a, questions, sessions))
        		.toArray();

        	if (answers.length < questions.length) {

        		let missedQuestions = Enumerable.from(questions)
        			.where(q => answers.filter(a => a.questionId == q.questionId).length === 0).toArray();

        		missedQuestions.forEach((q) => {
        			answers.push(Answer.From(null, q, sessions[0]));
        		});
        	}

        	return answers;
    	}

    	static Empty(quesiton: Question) {
        	let r = {
        		answerState: 'NotTested',
        	};
        	return Answer.From(r, quesiton, null);
    	}
    }

    export class Question {
    	name: string;
    	questionId: number;
    	orderNumber: number;

    	static FromResponse(response: QuestionResponse): Question {
        	let m = new Question();
        	m.name = response.name;
        	m.orderNumber = response.orderNumber;
        	m.questionId = response.questionId;
        	return m;
    	}

    	static FromResponseArray(response: QuestionResponse[]): Question[] {
        	return response.map(q => Question.FromResponse(q));
    	}
    }
}

export enum MoveDirection {
    Left,
    Right
}
