import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

type Props = {
	onConfirm: () => void,
	onCancel: () => void,
};

export function RemoveSubjectAlert({onConfirm, onCancel}: Props) {
	const modal = useModal();
	const cancel = useCloseModal(modal, onCancel);
	const confirm = useCloseModal(modal, onConfirm);

	return <Alert modalManagerRef={modal}>
		<Alert.Body>
			Are you sure you want to delete the subject tab? The tests (and data) will NOT be deleted
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={cancel}>
				CANCEL
			</Buttons.Text>
			<Buttons.Contained onClick={confirm} data-cy='confirm-remove-subject'>
				DELETE
			</Buttons.Contained>
		</Alert.Footer>
	</Alert>;
}
