import {UserType} from '@esgi/core/authentication';
import {TrackModel} from './types';

export class UserInfoTrackChangedEvent extends TrackModel{
}
export class UserInfoTrackCreatedEvent extends TrackModel{
}

export class UserInfoChangedEvent {
	constructor(
		public id: number,
		public firstName: string,
		public lastName: string,
		public userType: UserType,
	) {
	}
}

