import {Buttons} from '@esgillc/ui-kit/button';
import {Step, StepContent, StepHeader, StepperContext} from '@esgillc/ui-kit/layout';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import React, {useContext, useMemo, useRef, useState} from 'react';
import {Tabs, TabPanel, Tab} from '@esgillc/ui-kit/tabs-with-panel';
import {CreateAssignmentService} from '../../services/create-assignment-service';
import {FindMoreModal} from '../find-more-modal';
import {StepFooter} from '../step-footer';
import {SelectedTestsTable} from './components/selected-tests-table';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {TestsTable} from './components/tests-table';
import styles from './styles.module.less';
import {useTestsTableStyles} from './hooks/use-tests-table-styles';

interface Props<Service extends CreateAssignmentService> {
	service: Service;
	disabled: boolean;
	onFindMoreClose: VoidFunction;
	onCancel: VoidFunction | undefined;
}

enum TabName {
	SelfAssessTests = 'selfAssessTests',
	SelectedTests = 'selectedTests',
}

const stepIndex = 2;

export function SecondStep<Service extends CreateAssignmentService>({service, ...props}: Props<Service>) {
	const context = useContext(StepperContext);

	const selectedTestsIds = useBehaviorSubject(service.selectedTestsIds);
	const tests = useBehaviorSubject(service.tests);
	const selectedTests = useBehaviorSubject(service.selectedTests);
	const testsFilters = useBehaviorSubject(service.testsFilters);
	const selectedFilter = useBehaviorSubject(service.selectedTestsFilters);

	const [activeTab, setActiveTab] = useState(TabName.SelfAssessTests);
	const {testsTableStyles, tabsRef} = useTestsTableStyles();
	const filteredTests = useMemo(() => {
		return !selectedFilter[0]?.value ? tests
			: tests.filter(test => test.subjects.some(s => s.id === selectedFilter[0].value.id));
	}, [tests, selectedFilter]);

	const ref = useRef();
	const [showFindMore, setShowFindMore] = useState(false);
	const openFindMore = () => setShowFindMore(true);
	const closeFindMore = () => {
		props.onFindMoreClose();
		setShowFindMore(false);
	};

	const isFilterShow = activeTab === TabName.SelfAssessTests;

	const tabsItems = useMemo<Tab[]>(() => ([
		{
			label: 'My Self-Assess Tests',
			name: TabName.SelfAssessTests,
		},
		{
			label: `Selected Tests (${selectedTestsIds.length})`,
			name: TabName.SelectedTests,
		},
	]), [selectedTestsIds.length]);

	return <Step index={stepIndex} disabled={props.disabled}>
		<StepHeader onClick={() => !props.disabled && context.setActiveStep(stepIndex)}>
			<div ref={ref}>
				Add Self-Assess Tests
			</div>
		</StepHeader>
		<StepContent containerClassName={styles.stepContainer} contentClassName={styles.stepContentContainer}>
			<div className={styles.instruction}>
				Select a maximum of 3 self-assess tests to include in your assignment.
			</div>
			<div className={styles.container}>
				{isFilterShow && <div className={styles.filterWrapper}>
					<div className={styles.filterContainer}>
						<div className={styles.filterLabel}>Subject Tabs:</div>
						<Dropdown optionName='label' value={selectedFilter}
						          setValue={(v) => service.selectFilter(v)} className={styles.filterDropdown}>
							{testsFilters.map(filter => <Option key={filter.value?.id}
							                                    value={filter}>{filter.label}</Option>)}
						</Dropdown>
					</div>
				</div>}
				<div className={join(styles.tabsContainer, isFilterShow && styles.withFilter)}>
					<Tabs
						ref={tabsRef}
						items={tabsItems}
						active={activeTab}
						onSelect={({tab}) => setActiveTab(tab.name)}
						className={styles.tabs}
					/>
					<TabPanel key={TabName.SelfAssessTests} name={TabName.SelfAssessTests}>
						<TestsTable style={testsTableStyles} selectedTests={selectedTests} tests={filteredTests}
						            service={service}/>
					</TabPanel>
					<TabPanel key={TabName.SelectedTests} name={TabName.SelectedTests}>
						<SelectedTestsTable selectedTests={selectedTests} service={service}/>
					</TabPanel>
				</div>
			</div>
			<StepFooter>
				<div>
					<Buttons.Contained onClick={openFindMore}>
						FIND MORE TESTS
					</Buttons.Contained>
					<Buttons.Contained onClick={() => context.next()}>
						NEXT
					</Buttons.Contained>
				</div>
			</StepFooter>
		</StepContent>
		{showFindMore && <FindMoreModal assignmentName={service.nameFormGroup.value.name} onClose={closeFindMore}/>}
	</Step>;
}
