import * as React from 'react';
import type {SVGProps} from 'react';

export function Resume(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M17.5435 11.4853C17.8295 11.7696 17.8295 12.2304 17.5435 12.5147L14.508 14.7868C14.2221 15.0711 13.7584 15.0711 13.4725 14.7868C13.1865 14.5025 13.2579 13.875 13.2579 13.5L13.258 10.125C13.2581 9.75 13.1865 9.49747 13.4725 9.2132C13.7584 8.92893 14.2221 8.92893 14.508 9.2132L17.5435 11.4853Z' fill='#333333'/>
			<path d='M9.5 9.75C9.5 9.33579 9.83579 9 10.25 9C10.6642 9 11 9.33579 11 9.75V14.25C11 14.6642 10.6642 15 10.25 15C9.83579 15 9.5 14.6642 9.5 14.25V9.75Z' fill='#333333'/>
		</svg>

	);
}
