import React from 'react';
import styles from './display-not-tested-filter.module.less';
import {Radio} from '@esgillc/ui-kit/control';
import {ReportFiltersLabel} from '@esgi/deprecated/ui-kit/report/filters/report-filters-label';
import {ReportFiltersContainer} from '@esgi/deprecated/ui-kit/report/filters/report-filters-container';
import {FiltersLabelBlock} from '../filters-label-block';
import {FiltersContentBlock} from 'modules/reports/student-progress-report/components/common/filters-content-block';

interface Props {
	displayZeroIfNotTested: boolean;
	onClicked: (displayZeroIfNotTested: boolean) => void;
}

export function DisplayNotTestedFilter(props: Props) {
	return <ReportFiltersContainer className={styles.dntf}>
			<FiltersLabelBlock>
				<ReportFiltersLabel>Display not tested as:</ReportFiltersLabel>
			</FiltersLabelBlock>
			<FiltersContentBlock className={styles.displayNotTestedOptions}>
				<Radio
					className={styles.radioLabel}
					value='Zero'
					onClick={() => props.onClicked(true)}
					checked={props.displayZeroIfNotTested}
				>Zero
				</Radio>
				<Radio
					className={styles.radioLabel}
					value='NT'
					onClick={() => props.onClicked(false)}
					checked={!props.displayZeroIfNotTested}
				>NT
				</Radio>
			</FiltersContentBlock>
		</ReportFiltersContainer>;
}
