import {Drawer, DrawerMode, useDrawerMode, useDrawerParameters} from '@esgi/main/features/teacher/home';
import {Student} from '@esgi/main/libs/store';
import {AddStudentViaFileParams, TeacherDrawerName} from '../types';
import {isNull} from 'underscore';
import {useService} from '@esgi/core/service';
import {AddStudentsViaFileService} from './add-students-via-file-service';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {Alert} from '@esgi/ui/alert';
import {IncorrectFileType} from './components/incorrect-file-type';
import {MaximumStudents} from './components/maximum-students';
import {MissingRequiredInformation} from './components/missing-required-information';
import {PurchasingStudents} from './components/purchasing-students';
import {ConflictExists} from './components/conflict-exists';
import {DuplicateIDInFile} from './components/duplicate-id-in-file';
import {UnsupportedFieldName} from './components/unsupported-field-name';
import {InvalidField} from './components/invalid-field';
import {CompleteWithStudentsExistInClass} from './components/complete-with-students-exist-in-class';
import {DeleteFileUpload} from './components/delete-file-upload';
import {UploadRosterStep} from './components/upload-roster-step';
import {useAlertColorConfig} from './use-alert-color-config';
import {AlertContentType, DrawerStep, ValidationStatus} from './types';
import {ConflictResolutionStep} from './components/conflict-resolution-step';
import {getUser} from '@esgi/core/authentication';
import {dispatchAppEvent} from '@esgillc/events';
import {AddNewStudentViaFile} from './events';
import {FileUploadIsComplete} from './components/file-upload-is-complete';

export function DrawerWithStudentViaFileForm() {
	const drawerMode = useDrawerMode({drawerName: TeacherDrawerName.TeacherStudentViaFile});

	const {classId} = useDrawerParameters<AddStudentViaFileParams>(['classId']);

	const currentUser = getUser();

	const alertRef = Alert.useRef();

	const timeoutId = useRef<number>();
	const handleCloseDrawer = useRef<VoidFunction>();

	const [step, setStep] = useState(DrawerStep.UploadRoster);
	const [alertType, setAlertType] = useState<AlertContentType | null>(null);

	useEffect(() => {
		alertRef.current?.close();

		return () => {
			if (timeoutId.current) {
				clearTimeout(timeoutId.current);
			}
		};
	}, []);

	useEffect(() => {
		if (drawerMode === DrawerMode.Create) {
			setStep(DrawerStep.UploadRoster);
		}
	}, [drawerMode]);

	useEffect(() => {
		if (!isNull(alertType) && alertType !== ValidationStatus.None) {
			alertRef.current?.open();
		}
	}, [alertType]);

	const dataService = useService(AddStudentsViaFileService);

	useStreamEffect(dataService.validationStatus, setAlertType);
	const validationData = useBehaviorSubject(dataService.validationData);
	const className = useBehaviorSubject(dataService.className);
	const conflictStudents = useBehaviorSubject(dataService.conflictStudents);
	const createdStudents = useBehaviorSubject(dataService.createdStudents);
	const uploadedStudentsCount = useBehaviorSubject(dataService.uploadedStudentsCount);

	const duplicatesSaved = useBehaviorSubject(dataService.duplicatesSaved);

	useEffect(() => {
		if (alertType === ValidationStatus.Complete && createdStudents && currentUser) {
			const newStudents = createdStudents.map<Student>(({id, firstName, gradeLevelID, lastName, languageID}) => ({
				firstName,
				gradeLevelID,
				id,
				lastName,
				schoolID: currentUser.schoolID,
				teacherID: currentUser.userID,
				photoUrl: null,
				hasCredentials: false,
				languageID,
			}));

			handleCloseDrawer.current?.();

			dispatchAppEvent(
				AddNewStudentViaFile,
				new AddNewStudentViaFile({
					classId: Number(classId),
					students: newStudents,
				}),
			);
			setAlertType(ValidationStatus.Complete);
		}
	}, [alertType, createdStudents]);

	const closeAlert = useCallback(() => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}

		timeoutId.current = window.setTimeout(() => setAlertType(null), 300);
	}, [dataService]);

	const onCloseAlert = Alert.useClose(alertRef, closeAlert);

	const onAlertOpenChange = useCallback(
		(open: boolean) => {
			if (!open) {
				onCloseAlert();
			}
		},
		[closeAlert],
	);

	const alertColorConfig = useAlertColorConfig({alertType});

	const getAlertContent = () => {
		switch (alertType) {
			case ValidationStatus.IncorrectFileType:
				return <IncorrectFileType onAlertClose={onCloseAlert} />;

			case ValidationStatus.MissingRequiredInformation:
				return (
					<MissingRequiredInformation
						onAlertClose={onCloseAlert}
						validationData={validationData}
						downloadTemplate={dataService.downloadTemplate.bind(dataService)}
					/>
				);

			case ValidationStatus.InvalidField:
				return <InvalidField validationData={validationData} onAlertClose={onCloseAlert} />;

			case ValidationStatus.MaximumStudents:
				return <MaximumStudents onAlertClose={onCloseAlert} />;

			case ValidationStatus.PurchasingStudents:
				return <PurchasingStudents onAlertClose={onCloseAlert} validationData={validationData} />;

			case ValidationStatus.ConflictExists:
				return className && <ConflictExists
						className={className}
						conflictStudentsCount={conflictStudents?.length || 0}
						uploadedStudentsCount={uploadedStudentsCount}
						onViewStudentsClicked={() => {
							onCloseAlert();
							setStep(DrawerStep.ConflictResolution);
						}}
						onAlertClose={onCloseAlert}
					/>;

			case ValidationStatus.DuplicateIDInFile:
				return <DuplicateIDInFile onAlertClose={onCloseAlert} validationData={validationData} />;

			case ValidationStatus.UnsupportedFieldName:
				return <UnsupportedFieldName onAlertClose={onCloseAlert} validationData={validationData} />;

			case ValidationStatus.Complete:
				return <FileUploadIsComplete
					className={className}
					onAlertClose={onCloseAlert}
					studentsCount={uploadedStudentsCount}
				/>;

			case ValidationStatus.CompleteWithStudentsExistInClass:
				return className ? (
					<CompleteWithStudentsExistInClass
						duplicatesSaved={duplicatesSaved}
						className={className}
						onAlertClose={onCloseAlert}
						studentsCount={uploadedStudentsCount}
					/>
				) : null;

			case 'delete-file-upload':
				return <DeleteFileUpload onAlertClose={onCloseAlert} onDrawerClose={() => {
					handleCloseDrawer.current?.();
					setStep(DrawerStep.UploadRoster);
				}} />;
		}
	};

	const getDrawerContent = useCallback(() => {
		if (drawerMode !== DrawerMode.Create) {
			return null;
		}

		if (step === DrawerStep.UploadRoster && !isNull(classId)) {
			return <UploadRosterStep classId={classId} dataService={dataService} />;
		}

		if (step === DrawerStep.ConflictResolution && !isNull(classId)) {
			return (
				<ConflictResolutionStep
					classId={classId}
					conflictedStudents={conflictStudents}
					onDeleteFileClicked={() => {
						setAlertType('delete-file-upload');
						handleCloseDrawer.current?.();
					}}
					onCancel={() => setStep(DrawerStep.UploadRoster)}
					onFileUploadIsComplete={(createdStudents) => {
						setAlertType(ValidationStatus.Complete);
						dataService.createdStudents.next(createdStudents);
						setStep(DrawerStep.UploadRoster);
						handleCloseDrawer.current?.();
					}}
				/>
			);
		}

		return null;
	}, [drawerMode, classId, dataService, step, conflictStudents]);

	return (
		<>
			<Drawer drawerName={TeacherDrawerName.TeacherStudentViaFile} dataCy='drawer-with-student-via-file'>{getDrawerContent()}</Drawer>

			<Alert
				modalManagerRef={alertRef}
				colorConfig={alertColorConfig}
				onOpenChange={onAlertOpenChange}
				css={{position: 'relative', zIndex: 20}}
			>
				{getAlertContent()}
			</Alert>
		</>
	);
}
