import {parseJSONID, useDragDropManager} from '@esgillc/ui-kit/drag-n-drop';
import {useEffect, useState} from 'react';
import {DragStart, DropResult} from 'react-beautiful-dnd';
import {SubjectModel} from '../../models';
import {ManageSubjectsAndTestsService} from '../../service';
import {SubjectDroppableInfo, subjectDroppableType, testDroppableType, TestDraggableInfo} from './types';


export function useDragDropEffects(subjects: SubjectModel[], service: ManageSubjectsAndTestsService) {
	const dragDropManager = useDragDropManager();

	const [draggingSubjectIdx, setDraggingSubjectIdx] = useState(-1);
	const [draggingTest, setDraggingTest] = useState<TestDraggableInfo>();

	const onDragEnd = (result: DropResult) => {
		if (result.type === subjectDroppableType && result.reason === 'DROP') {
			setDraggingSubjectIdx(-1);
			const from = result.source.index;
			const to = result.destination.index;

			if (from === to) {
				return;
			}

			service.reorder(from, to);
		}

		if (result.type === testDroppableType && result.destination) {
			setDraggingTest(undefined);
			const destinationData = parseJSONID<SubjectDroppableInfo>(result.destination.droppableId);
			const sourceData = parseJSONID<SubjectDroppableInfo>(result.source.droppableId);
			const from = result.source.index;
			const to = result.destination.index;

			const testData = parseJSONID<TestDraggableInfo>(result.draggableId);

			if (destinationData.id === sourceData.id) {
				if (from === to) {
					return;
				}
				service.reorderTests(sourceData, from, to);
			} else {
				if (destinationData.expanded) {
					service.moveTest(sourceData, destinationData, testData.testID, result.destination.index);
				} else {
					service.moveTestToEnd(sourceData, destinationData, testData.testID);
				}
			}
		}
	};

	const onDragStart = (start: DragStart) => {
		if (start.type === testDroppableType) {
			const test = parseJSONID<TestDraggableInfo>(start.draggableId);
			if (test) {
				setDraggingTest(test);
			}
		}

		if (start.type === subjectDroppableType) {
			const idx = start.source.index;
			setDraggingSubjectIdx(idx);
		}
	};

	useEffect(() => {
		const sub = dragDropManager?.onDragEnd(onDragEnd);
		return () => dragDropManager?.unsubscribe(sub);
	});

	useEffect(() => {
		const sub = dragDropManager?.onDragStart(onDragStart);
		return () => dragDropManager?.unsubscribe(sub);
	});

	return {
		draggingSubjectIdx,
		draggingTest,
	};
}
