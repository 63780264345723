export function waitElementInDom<T extends HTMLElement>(element: T, callback: (element: T) => void, maxWaitTime: number = 1000): void {
    waitElement(element, callback, maxWaitTime);
}

function waitElement<T extends HTMLElement>(element: T, callback: (element: T) => void, maxWaitTime: number = 1000, attempt: number = 0) {
    if (checkElementInDom(element)) {
        callback(element);
    } else {
        setTimeout(() => waitElement(element, callback, maxWaitTime, attempt + 1));
    }
}

export function checkElementInDom(element: HTMLElement): boolean {
    return document.contains(element);
}