import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const SettingsBox = styled(FlexBox, {
	flexWrap: 'wrap',
	marginTop: -8,

	'> *': {
		marginTop: 8,
		marginRight: 8,
	},

	'> :last-child': {
		minWidth: 70,
		maxWidth: 200,
		width: '100%',
	},
});
