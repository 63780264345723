import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {TestColorIndicator as TestColorIndicatorUI} from '@esgi/main/kits/common';

export const ListItem = styled(FlexBox, {
	alignItems: 'center',
	width: '100%',
});

export const CaptionText = styled(Text, {
	color: 'currentColor',
	marginRight: 12,

	'@media (max-width: 1024px)': {
		maxWidth: '100px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

export const Counter = styled(GridBox, {
	marginLeft: 'auto',
	gridAutoFlow: 'column',
	gap: '$3',
});

export const CounterText = styled(Text, {
	minWidth: '16px',
	textAlign: 'center',
	color: '$muted',

	variants: {
		type: {
			correct: {
				color: '$positive',
			},
			incorrect: {
				color: '$negative',
			},
			untested: {
				color: '$neutral56',
			},
		},
		zero: {
			true: {},
		},
	},
	compoundVariants: ['correct', 'incorrect', 'untested'].map((type) => ({
		type,
		zero: true,
		css: {
			color: '$muted',
		},
	})),
});

export const TestColorIndicator = styled(TestColorIndicatorUI, {
	// @TODO: Remove flag important after migrating on styled components and check style ordering
	height: '32px !important',
	padding: '6px 4px !important',
	marginRight: '12px',
});
