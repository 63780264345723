import {BehaviorSubject, tap, catchError, map, EMPTY} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {DownloadRequest} from '../types';
import {V2ActivitiesFlashCardsController} from '@esgi/contracts/esgi';

export class DownloadService extends BaseService {
	public readonly busyController = new BehaviorSubject(false);
	private readonly controller = new V2ActivitiesFlashCardsController();

	public override dispose() {
		this.controller.dispose();
	}

	public preview(generateSettings: DownloadRequest['generateSettings']) {
		this.busyController.next(true);
		return this.controller.preview({reportGuid: null, generateSettings}).pipe(
			tap(() => this.busyController.next(false)),
			catchError(() => {
				this.busyController.next(false);
				return EMPTY;
			}),
		);
	}

	public downloadPdf(request: Omit<DownloadRequest, 'zip'>) {
		const documentDate = new Date().toLocaleDateString('en-US');

		this.httpClient.ESGIApi.file(
			'/v2/modules/activities/flash-cards',
			'generate',
			`Flash_Cards_${documentDate}.pdf`,
			{...request, zip: false},
		).subscribe();
	}

	/*
	// Uncomment when the contract issue is resolved
	public downloadPdf(request: Omit<DownloadRequest, 'zip'>) {
		const documentDate = new Date().toLocaleDateString('en-US');
		const fileName = `Flash_Cards_${documentDate}.pdf`;

		this.busyController.next(true);
		this.controller.generate({...request, zip: false})
			.pipe(
				tap(() => this.busyController.next(false)),
				map((response) => {
					console.log(response);
					const link = document.createElement('a');
					link.setAttribute('type', 'hidden');
					link.href = URL.createObjectURL(response as any);
					link.download = fileName;
					document.body.appendChild(link);
					link.click();
					link.remove();
					return response;
				})
			)
			.subscribe();
	}
	*/
}
