import {GradeLevelOptionItem} from '../types';

export const mappedGradeLevels: GradeLevelOptionItem[] = [
	{label: 'Pre-K', value: 1, disabled: true},
	{label: 'TK', value: 2, disabled: true},
	{label: 'Kindergarten', value: 4, disabled: true},
	{label: '1', value: 8, disabled: true},
	{label: '2', value: 16, disabled: true},
	{label: '3+', value: 32, disabled: true},
];
