import escapeHtml from 'escape-html';
import {Text} from 'slate';

export function serializeHtml(node) {
	if (Text.isText(node)) {
		let string = escapeHtml(node.text);
		// @ts-ignore: Property does not exist on type BaseText. Problem with Slate package.
		if (node.strong) {
			string = `<strong>${string}</strong>`;
		}
		// @ts-ignore: Property does not exist on type BaseText. Problem with Slate package.
		if (node.italic) {
			string = `<em>${string}</em>`;
		}
		return string.replace(/\n/g, '<br>');
	}

	const children = node.children.map((n) => serializeHtml(n)).join('');

	switch (node.type) {
		case 'editable-void':
			return `<div style="page-break-before: always; display: -webkit-box; -webkit-box-pack: justify;">${children}</div>`;
		case 'block':
			return `<div>${children}</div>`;
		case 'numbered-list':
			return `<ol>${children}</ol>`;
		case 'bulleted-list':
			return `<ul>${children}</ul>`;
		case 'list-item':
			return `<li>${children}</li>`;
		case 'paragraph':
			return `<p>${children}</p>`;
		case 'break':
			return `<br>`;
		default:
			return children;
	}
}
