import {PropsWithChildren, useCallback, useMemo} from 'react';
import {useAutoControlledState} from '@esgi/ui/utils';
import {SelectableListGroupContext, SelectableListGroupContextValue} from '../../context';
import {noop} from 'underscore';

export type GroupSingleProviderType = {
	/** The controlled value of the pressed item when type is "single". */
	value?: string | undefined;

	/** The values of the items to show as pressed when initially rendered and type is "single". */
	defaultValue?: string | undefined;

	/** Event handler called when the value changes. */
	onValueChange?: ((value: string) => void) | undefined;
};

type GroupSingleProviderInternalProps = {
	/** Contain all passed items value. */
	valuesVocabulary: string[];

	/** Add a value to vocabuldary state. */
	addValueToVocabulary: (value: string) => void;

	/** Remove a value from vocabuldary state. */
	removeValueFromVocabulary: (value: string) => void;
};

type GroupSingleProviderProps = PropsWithChildren<GroupSingleProviderType & GroupSingleProviderInternalProps>;

export function GroupSingleProvider({
	value: controlledValue,
	defaultValue,
	children,
	valuesVocabulary,
	addValueToVocabulary,
	removeValueFromVocabulary,
	onValueChange,
}: GroupSingleProviderProps) {
	const [value, setValue] = useAutoControlledState({
		initialState: defaultValue ?? '',
		controlledState: controlledValue,
		onChange: onValueChange,
	});

	const handleButtonDeactivate = useCallback(() => setValue(''), [setValue]);

	const contextValue = useMemo<SelectableListGroupContextValue>(
		() => ({
			value: value ? [value] : [],
			type: 'single',
			onItemActivate: setValue,
			onItemDeactivate: handleButtonDeactivate,
			onActivateAllItems: noop,
			onDeactivateAllItems: noop,
			addValueToVocabulary,
			removeValueFromVocabulary,
			valuesVocabulary,
		}),
		[value, setValue, handleButtonDeactivate, addValueToVocabulary, removeValueFromVocabulary, valuesVocabulary],
	);

	return <SelectableListGroupContext.Provider value={contextValue}>{children}</SelectableListGroupContext.Provider>;
}
