import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {Plus, Search} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {
	Wrapper,
	HeaderContent,
	Column,
	StandardsWrapper,
	DrawerHeaderWrapper,
	ListWrapper,
} from '../index.styled';
import {Input} from '@esgi/ui/controls';
import React, {useCallback, useMemo, useState} from 'react';
import {ItemsCounter} from '../items-counter'; 
import {StandardsList} from '../standards-list';
import {Content} from '../content';
import {SelectableList} from '../selectable-list';
import {useStandardsDrawerState} from '../../hooks';
import {StandardsService} from '../../services';
import {OptionItem} from '../../types';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props = {
	service: StandardsService;
	onClose: VoidFunction,
	isSmallScreen: boolean,
	contentAreas: OptionItem[],
	gradeLevels: OptionItem[]
	setGradeLevels: React.Dispatch<React.SetStateAction<OptionItem[]>>
	onFiltersReset?: () => void
}

export function AddStandardsDrawer({service, onClose, isSmallScreen, contentAreas, gradeLevels, setGradeLevels, onFiltersReset}: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, onClose);

	const {
		osRef,
		isContentAreasLoading,
		standardsContentAreas,
		selectedContentArea,
		onContentAreaSelect,
		selectedGradeLevel,
		onGradeLevelSelect,
		isDomainsLoading,
		domainsList,
		isStandardsLoading,
		standardsList,
		selectedDomain,
		onDomainSelect,
		selectedStandards,
		onStandardRemove,
		onStandardSelect,
		onStandardSelectMany,
		onNextStandardsPage,
		selectedStandardType,
		standardTypeOptions,
		onStandardTypeChange,
		searchKeyword,
		hasMoreStandards,
	} = useStandardsDrawerState({service, contentAreas, setGradeLevels});

	const onAddFilterClick = useCallback(() => {
		onFiltersReset?.();

		if (!selectedStandards.length && selectedDomain) {
			if (hasMoreStandards) {
				service.getStandards(selectedDomain, 1).subscribe((result) => {
					const {stateStandardModels, searchCount} = result.value;
					service.onStandardsLoaded(stateStandardModels, searchCount);
					const allStandards = stateStandardModels.map(({id: value, name: label}) => ({value, label}));
					service.selectedStandardsIDs$.next(allStandards.map(({value}) => value));
					service.selectedStandards$.next(allStandards);
				});
			} else {
				const selected = service.standards$.value.map(({id: value, name: label}) => ({value, label}));
				service.selectedStandardsIDs$.next(selected.map(({value}) => value));
				service.selectedStandards$.next(selected);
			}

			service.selectedContentAreaIDs$.next([selectedContentArea]);
			service.selectedGradeLevelIDs$.next([selectedGradeLevel]);
			close();
			return;
		}

		service.selectedStandardsIDs$.next(selectedStandards.map(({value}) => value));
		service.selectedStandards$.next(selectedStandards);
		service.selectedContentAreaIDs$.next([...new Set(selectedStandards.map(({contentArea}) => contentArea))].filter((v) => v));
		service.selectedGradeLevelIDs$.next([...new Set(selectedStandards.map(({gradeLevel}) => gradeLevel))].filter((v) => v));
		close();
	}, [close, hasMoreStandards, onFiltersReset, selectedContentArea, selectedDomain, selectedGradeLevel, selectedStandards, service]);

	const [debouncedKeyword, setDebouncedKeyword] = useState(searchKeyword);

	const onKeywordChange = useCallback((e) => {
		const value = e.target.value;
		setDebouncedKeyword(value);

		service.standardsSearchKeyword$.next(value);

	}, [service.standardsSearchKeyword$]);
	
	const selectedStandardsIDs = useMemo(() => selectedStandards.map(({value}) => value), [selectedStandards]);
	
	return (
		<Drawer css={{paddingLeft: 0, paddingBottom: 0, paddingRight: 0, gap: 0}} width={isSmallScreen ? 670 : 1050} drawerRef={drawerRef}>
			<Wrapper>
				<HeaderContent isSmallScreen={isSmallScreen}>
					<Column alignContent='center'>
						<Text data-cy='drawer-title' size='small' color='mediumContrast'>Standard Type</Text>
					</Column>
					{!isSmallScreen && <Column alignContent='center'>
						<Text data-cy='drawer-title' size='small' color='mediumContrast'>Domain</Text>
					</Column>}
				</HeaderContent>
				<DrawerHeaderWrapper>
					<Drawer.Header
						Icon={Plus}
						sectionName='Add Standard Filter'
						actionButtonDisabled={selectedStandards?.length === 0}
						withActionButton
						onActionButtonClick={onAddFilterClick}
						actionButtonText='Add Filter'
						closeDrawer={close}
						childrenAlignment='right'
					>
						<ItemsCounter itemsCount={selectedStandards.length}/>
					</Drawer.Header>
				</DrawerHeaderWrapper>
			</Wrapper>
			<Wrapper>
				<Content
					isSmallScreen={isSmallScreen}
					standardTypeOptions={standardTypeOptions}
					onStandardTypeChange={onStandardTypeChange}
					selectedStandardType={selectedStandardType}
					contentAreas={standardsContentAreas}
					onContentAreaSelect={onContentAreaSelect}
					selectedContentArea={selectedContentArea}
					gradeLevels={gradeLevels}
					onGradeLevelSelect={onGradeLevelSelect}
					selectedGradeLevel={selectedGradeLevel}
					isContentAreasLoading={isContentAreasLoading}
					isDomainsLoading={isDomainsLoading}
					domainsList={domainsList}
					selectedDomain={selectedDomain}
					onDomainSelect={onDomainSelect}
				/>
				<OverlayScrollbarsComponent style={{height: 'calc(100% - 0px)'}} defer ref={osRef} options={{scrollbars: {autoHide: 'leave'}}}>
				<StandardsWrapper>
					<SelectableList
						items={selectedStandards}
						selectedItems={selectedStandardsIDs}
						onItemRemove={onStandardRemove}
					/>
					<ListWrapper withTopPadding={selectedStandards.length > 0}>
						<Input.Iconable
							placeholder='Search'
							value={debouncedKeyword}
							onChange={onKeywordChange}
							dataCy='filter-search-input'
						>
							<Search/>
						</Input.Iconable>
						<StandardsList
							standards={standardsList}
							selectedStandards={selectedStandards}
							onStandardsSelect={onStandardSelect}
							onStandardSelectMany={onStandardSelectMany}
							isStandardsLoading={isStandardsLoading}
							isDomainsLoading={isDomainsLoading}
							onNextPage={onNextStandardsPage}
							osRef={osRef}
						/>
					</ListWrapper>
				</StandardsWrapper>
				</OverlayScrollbarsComponent>
			</Wrapper>
		</Drawer>
	);
}
