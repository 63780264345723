import {
	createSlice,
	createAsyncThunk, Draft, SliceSelectors,
} from '@reduxjs/toolkit';
import {LoadingState, State} from './types';
import {isArray, isFunction} from 'underscore';

export function createStoreSlice<T extends object>(name: string, fallback: () => Promise<T[]>, selectors?: SliceSelectors<State<T>>) {
	const initialState: State<T> = {
		loadingState: LoadingState.None,
		data: [],
		loaded: false,
	};

	const fetch = createAsyncThunk<
		T[],
		void,
		{
			rejectValue: Error,
		}
	>(
		`${name}/fetch`,
		async (_, api) => fallback(),
		{
			condition(_, {getState}) {
				const state = (getState() as any)[name] as State<T>;
				return state.loadingState !== LoadingState.Loading;
			},
		}
	);

	const slice = createSlice({
		name: name,
		initialState: initialState,
		reducers: (create) => ({
			add: create.reducer<(T | (T[]))>((state, {payload}) => {
				let newItems: T[] = [];
				if(!isArray(payload)) {
					newItems.push(payload);
				} else {
					newItems = payload;
				}
				state.data = [...state.data, ...newItems] as Draft<T>[];
			}),
			update: create.reducer<(((item: Draft<T>) => T))>((state, {payload}) => {
				state.data = state.data.map(payload) as Draft<T>[];
			}),
			invalidateCache: create.reducer<void>((state) => {
				state.data = [];
				state.loadingState = LoadingState.None;
				state.loaded = false;
				fetch();
			}),
			remove: create.reducer<T | ((item: Draft<T>) => boolean)>((state, action) => {
				const {payload} = action;
				if (typeof payload === 'function') {
					state.data = state.data.filter((i) => !(payload(i)));
				} else {
					state.data = state.data.filter((i) => i !== action.payload);
				}
			}),
			reorder: create.reducer<T[]>((state, action) => {
				state.data = state.data.filter((i) => i !== action.payload);
			}),
		}),
		selectors,
		extraReducers: (builder) => {
			builder
				.addCase(fetch.pending, (state, action) => {
					state.loadingState = LoadingState.Loading;
					state.loaded = false;
				})
				.addCase(fetch.fulfilled, (state, action) => {
					// @ts-ignore
					state.loadingState = LoadingState.Loaded;
					// @ts-ignore
					state.data = action.payload;
					state.loaded = true;
				});
		},
	});
	return {
		slice,
		fetch,
	};
}