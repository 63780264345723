import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Divider, Layout} from './index.styled';
import {Assignment, Button, Close} from '@esgi/ui';
import {AssignmentContextMenu, AssignmentState, AssignmentStatus, TextWithIcon} from '@esgi/main/kits/assignments';
import {SkeletonShape} from '@esgi/main/kits/common';

type Props = {
	assignmentState: AssignmentState;
	assignmentName: string;
	onSaveClick: VoidFunction;
	onDeleteAssignment: VoidFunction;
	skeleton: boolean;
	isSaveButtonDisabled: boolean;
	onClose: VoidFunction;
};

export function Header({
	assignmentState,
	assignmentName,
	onSaveClick,
	onDeleteAssignment,
	skeleton,
	isSaveButtonDisabled,
	onClose,
}: Props) {
	return (
		<Layout>
			<GridBox align='center' gap='3' flow='column'>
				<TextWithIcon size='small' color='neutral56' Icon={Assignment}>
					Edit Assignment
				</TextWithIcon>
				<Divider />
				{skeleton ? (
					<SkeletonShape height={24} width={160} />
				) : (
					<GridBox display='inlineGrid' flow='column' align='center' gap='3'>
						<Text size='medium' data-assignment-name=''>
							{assignmentName}
						</Text>
						<AssignmentStatus state={assignmentState} />
					</GridBox>
				)}
			</GridBox>
			<GridBox flow='column' gap='4' align='center'>
				<AssignmentContextMenu disabledOpenMenu={skeleton} onDeleteAssignment={onDeleteAssignment} />
				<Button color='primary' onClick={onSaveClick} disabled={skeleton || isSaveButtonDisabled} skeleton={skeleton}>
					Save Changes
				</Button>
				<Button.Icon onClick={onClose} dataCy='close-button'>
					<Close />
				</Button.Icon>
			</GridBox>
		</Layout>
	);
}
