import {UserType, getUser} from '@esgi/core/authentication';

export const hasTestSessionDetailsAllStudents = () => {
	const user = getUser();
	return [
		UserType.T,
		UserType.ISD,
		UserType.ISS,
	].includes(user.userType);
};
