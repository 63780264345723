import {Authenticator, LoginResponse} from '@esgi/core/authentication';
import {BaseService} from '@esgi/core/service';
import {Observable} from 'rxjs';
import {WindowTool} from '@esgi/deprecated/utils';
import {afterLoginHandler} from '../redirect-module/redirect';

export class DevLoginService extends BaseService {
	private readonly controller = 'Login';
	public login(username: string, password: string, secure: boolean): void {
		let request: Observable<LoginResponse>;
		if(secure) {
			request = this.secureLogin(username, password);
		} else {
			request = this.devLogin(username);
		}
		request.subscribe(async (r) => {
			const authenticatorResult = await new Authenticator().authenticate(r);
			if (authenticatorResult.valid) {
				afterLoginHandler(r.user, WindowTool.parseUri().params.r);
				return;
			}
		});
	}

	private secureLogin(username: string, password: string): Observable<LoginResponse> {
		const formData = new FormData();
		formData.set('userName', username);
		formData.set('password', password);

		return this.httpClient.ESGIApi
			.post<LoginResponse>(this.controller, 'login', formData)
			.asObservable();
	}

	private devLogin(username: string): Observable<LoginResponse> {
		const formData = new FormData();
		formData.set('userName', username);

		return this.httpClient.ESGIApi
			.post<LoginResponse>(this.controller, 'DevLogin', formData)
			.asObservable();
	}
}
