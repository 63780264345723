import {ServerClient} from 'pages/landing/kit/server-client';
import {Authenticator, LoginResponse} from '@esgi/core/authentication';
import {CookieReader, WindowTool} from '@esgi/deprecated/utils';
import {observable, OldAlerts, Renderable} from '@esgi/deprecated/knockout';
import {Loader} from '@esgi/deprecated/jquery';
import {resizeToWindow} from '../sign-up/utils';
import {LinkedComplete} from './flows/linked/linked-continue';
import {UnlinkedComplete} from './flows/unlinked/unlinked-continue';
import {InitResponseModel, LinkedModel, UnlinkedModel} from './models';
import {Server} from './server';
import * as store from 'store';

import './completeRegistration.less';

export class Page {
	registration: RegistrationComponent;

	constructor() {
		this.registration = new RegistrationComponent();
		this.registration.load().done(() => {
			ko.applyBindings(this, document.body);
		});
	}

	public destroy() {
		ko.cleanNode(document.body);
	}
}

export class RegistrationComponent extends Renderable {
	@observable()
	currentFlow: Renderable;
	loader: Loader;
	private sessionExpiredTimer;

	load(): JQueryPromise<any> {
		this.sessionExpiredTimer = this.getSessionExpiredTimer();
		store.remove('firstClassIsSelectedOnLogin');
		WindowTool.clearUrlQueryParameters();

		return Server.init().done(r => {
			this.resolve(r);
		});
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		this.loader = new Loader($('#app'));
		return super.afterRender(rootElement);
	}

	resolve(initData: InitResponseModel) {
		if (initData) {
			switch (true) {
				case initData.isCompleted: {
					this.goToESGI();
					break;
				}
				case initData.notAllowed: {
					this.goToLogin();
					break;
				}
				case !!initData.linked: {
					this.currentFlow = this.createLinkedFlow(initData.linked);
					break;
				}
				case !!initData.unlinked: {
					this.currentFlow = this.createUnlinkedFlow(initData.unlinked);
					break;
				}
			}
		}
	}

	private goToLogin = () => {
		clearTimeout(this.sessionExpiredTimer);
		window.location.replace('/login');
	};
	private goToESGI = () => {
		clearTimeout(this.sessionExpiredTimer);
		window.location.replace('/');
	};
	private loginByToken = (token: string) => {
		clearTimeout(this.sessionExpiredTimer);
		ServerClient.ESGIAPI.Post<LoginResponse>('Login', 'ByRegistration', {data: {token: token}})
			.done((r) => {
				const authenticator = new Authenticator();
				authenticator.authenticate(r).then((authenticated) => {
					if (authenticated.valid) {
						document.location.href = '/';
					}
				});
			});
	};

	createLinkedFlow(model: LinkedModel): LinkedComplete {
		const f = new LinkedComplete(model);
		f.events.done((_, params) => {
			this.loader.mask();
			Server.completeLinked(params.data).done((response) => {
				this.loginByToken(response.token);
			}).fail(_ => this.loader.unmask());
		});
		f.events.cancel(() => {
			this.goToLogin();
		});
		return f;
	}

	createUnlinkedFlow(model: UnlinkedModel): UnlinkedComplete {
		const f = new UnlinkedComplete(model);
		f.events.done((_, params) => {
			this.loader.mask();
			Server.completeUnlinked(params.data).done((response) => {
				this.loginByToken(response.token);
			}).fail(() => {
				params.onFail && params.onFail();
				this.loader.unmask();
			});
		});
		f.events.cancel(() => {
			this.goToLogin();
		});
		return f;
	}

	private parseJwt(token: string) {
		if (!token) {
			return null;
		}
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	}

	private getSessionExpiredTimer() {
		const token = CookieReader.read('esgi-token');
		const parsedToken = this.parseJwt(token);
		if (parsedToken) {
			const expTimestamp = parsedToken.exp * 1000;
			const curTimestamp = new Date().getTime();
			return setTimeout(() => {
				OldAlerts.bsalert('This session has expired, please login with your username and password', () => {
					this.goToLogin();
				});
			}, expTimestamp - curTimestamp);
		}
	}

	template = () => {
		resizeToWindow();
		return <div className='complete-registration-container'>
			<main data-bind='if: currentFlow!==null'>
				<div data-bind='render: currentFlow'/>
			</main>
		</div>;
	};
}
