import {PureComponent} from 'react';
import {IFormControlValidatorResult} from './form-controls';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import moment from 'moment';
import './datepicker.less';

export class Props {
	id: string;
	date?: moment.Moment;
	validator?: (value: moment.Moment) => IFormControlValidatorResult;
	validationPlacement?: 'right' | 'top' | 'bottom' | 'left';
	icon?: any;
	disabled?: boolean;
	placeholder?: string;
	endDate?: string;
	onSelect: (d: moment.Moment, validationResult: IFormControlValidatorResult, dateStringvalue?: string) => void;
	onInput?: (d: moment.Moment, dateStringvalue?: string) => void;
}

export class DatePicker extends PureComponent<Props> {
	defaultFormat: string = 'mm/dd/yyyy';

	ref: HTMLElement = null;
	containerRef: HTMLElement = null;

	public componentWillUnmount() {
		$(this.ref).datepicker('destroy');
	}

	public componentDidUpdate() {
		if (this.props.date) {
			$(this.ref).datepicker('update', this.props.date.toDate());
		}
	}

	public componentDidMount() {
		$(this.ref)
			.datepicker({dateFormat: this.defaultFormat})
			.on('input', (e) => {
				if (typeof this.props.onInput !== 'function') {
					return;
				}
				const formatForMoment = this.defaultFormat.toUpperCase();
				const value = (e.currentTarget as any).value;
				const dateMoment = moment(value, formatForMoment);
				this.props.onInput(dateMoment, value);
			})
			.on('changeDate', (e) => {
				$(this.ref).datepicker('hide');
				const formatForMoment = this.defaultFormat.toUpperCase();
				const value = (e.currentTarget as any).value;
				const dateMoment = moment(value, formatForMoment);
				this.props.onSelect(
					dateMoment,
					this.props.validator && this.props.validator(dateMoment),
				);
			});

		if (this.props.endDate) {
			$(this.ref).datepicker('endDate', this.props.endDate);
		}

		if (this.props.date) {
			$(this.ref).datepicker('setDate', this.props.date.toDate() ?? new Date());
		}
	}

	public renderLabel(valid: boolean) {
		return (
			<label
				className={'input-group-addon' + (valid ? '' : ' has-error')}
				htmlFor={this.props.id}
			>
				{this.props.icon
					? this.props.icon
					: <span className='glyphicon glyphicon-calendar'/>
				}
			</label>
		);
	}

	public renderPickerWithValidation() {
		const validationResult = this.props.validator(this.props.date);
		return (
			<div
				ref={(r) => this.containerRef = r}
				className={'input-group date react-datepicker' + ((validationResult.valid) ? '' : ' has-error')}
			>
				<input
					placeholder={this.props.placeholder}
					disabled={this.props.disabled}
					id={this.props.id}
					ref={(r) => this.ref = r}
					type='text'
					className='form-control'
				/>
				{this.renderLabel(validationResult.valid)}
				{(!validationResult.valid && validationResult.message.length > 0) &&
					<ValidationTooltip
						element={this.ref}
						placement={this.props.validationPlacement || 'right'}
						container={this.containerRef}
						notHide={true}
					>
						{validationResult.message}
					</ValidationTooltip>
				}
			</div>
		);
	}

	public renderPicker() {
		return (
			<div className={'input-group date react-datepicker'}>
				<input
					placeholder={this.props.placeholder}
					disabled={this.props.disabled}
					id={this.props.id}
					ref={(r) => this.ref = r}
					type='text'
					className='form-control'
				/>
				{this.renderLabel(true)}
			</div>
		);
	}

	public render() {
		if (this.props.validator) {
			return this.renderPickerWithValidation();
		}
		return this.renderPicker();
	}
}
