import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Select} from '@esgi/ui/controls';

export const SelectWrapper = styled(Box, {
	'& span': {
	 color: '$primary',
	},
});

export const SelectField = styled(Select.Field, {
	width: 280,
});

