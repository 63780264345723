import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import React, {useMemo} from 'react';
import {TestSelectorService} from '../service';

import styles from '../styles.module.less';

type Props = {
	service: TestSelectorService,
};

export function Header(props: Props) {
	const subjects = useBehaviorSubject(props.service.subjects);
	const selectedSubject = useBehaviorSubject(props.service.selectedSubject);

	const dropdownValue = useMemo(() => [selectedSubject], [selectedSubject]);

	return <div className={styles.subjectRow}>
		<h5>Subject Tab:</h5>
		<Dropdown value={dropdownValue} optionName='name' setValue={(v) => props.service.selectSubject(v[0])}
		          autoWidth={true}>
			{subjects.map((s) => <Option key={s.id} value={s}>{s.name}</Option>)}
		</Dropdown>
	</div>;
}
