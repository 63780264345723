import {Moment} from 'moment';
import {resolvedPromise} from '@esgi/deprecated/utils';
import {FormField} from './elements';
import {IHaveRootElement, Renderable} from '../renderable';

type IServerResponse<T> = JQuery.Promise<T> & {
	code?: (errorCode: number, clb: Function) => IServerResponse<T>;
}

export abstract class Validator {
	validate(value: any): JQueryPromise<string> {
		return resolvedPromise('');
	}
}

export abstract class FormFieldValidator extends Validator {
	validate(field: FormField): JQueryPromise<string> {
		super.validate(null);
		return Renderable.resolvedDeferred;
	}
}

export class RequiredValidator extends FormFieldValidator {
	constructor(public message: string = 'Required') {
		super();
	}

	validate(field: FormField) {
		return super.validate(field).then(() => {
			const value = field.value();
			// zero is a valid value
			if (!value && value !== 0) {
				return this.message;
			}
		});
	}
}

class NotZeroRequiredValidator extends FormFieldValidator {
	constructor(public message: string = 'Required') {
		super();
	}

	validate(field: FormField) {
		return super.validate(field).then(() => {
			const value = field.value();
			if (!value || value == 0 || value === undefined) {
				return this.message;
			}
		});
	}
}

export class RegexValidator extends FormFieldValidator {

	constructor(public pattern: RegExp, public message: string = 'Value does not match pattern') {
		super();
	}

	validate(field: FormField<any>): JQueryPromise<string> {
		return super.validate(field).then(() => {
			const value = field.value();
			if (value) {
				const valid = this.pattern.test(value);

				if (!valid) {
					return this.message;
				}
			}
		});
	}
}

export class EmailValidator extends RegexValidator {
	constructor(public message: string = 'Email address is not valid') {
		super(new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,9}$'), message);
	}
}

export class NumberValidator extends FormFieldValidator {
	constructor(public message: string = 'Invalid number') {
		super();
	}

	validate(field: FormField) {
		return super.validate(field).then(() => {
			const value = field.value();
			if (value) {
				const valid = !isNaN(+value);
				if (!valid) {
					return this.message;
				}
			}
		});
	}
}

export class RangeValidator extends NumberValidator {
	constructor(public from?: number, public to?: number, public message: string = '') {
		super(message);

		if (!message) {
			if ((from || from === 0) && (to || to === 0)) {
				this.message = `Must be between ${from} - ${to}`;
			}

			if (!from && from !== 0) {
				this.message = `Must be less than ${to + 1}`;
			}

			if (!to && to !== 0) {
				this.message = `Must be greater than ${from - 1}`;
			}
		}
	}

	validate(field: FormField) {
		return super.validate(field).then(e => {
			if (!e) {
				const value = field.value();
				if (value || value === 0) {
					const number = parseInt(value);

					if (this.from !== 0 && !this.from) {
						if (number > this.to) {
							return this.message;
						} else {
							return '';
						}
					}

					if (this.to !== 0 && !this.to) {
						if (number < this.from) {
							return this.message;
						} else {
							return '';
						}
					}

					if (number < this.from || number > this.to) {
						return this.message;
					} else {
						return '';
					}
				}
			}
		});
	}
}

export class AjaxValidator<T> extends FormFieldValidator {
	constructor(public promise: (f: FormField) => IServerResponse<T>, public validator: (t: T) => string) {
		super();
	}

	validate(field: FormField) {
		return super.validate(field).then(e => {
			if (!e) {
				const value = field.value();
				if (value) {
					const deferred = $.Deferred<string>();
					this.promise(field)
						.done(model => deferred.resolve(this.validator(model)))
						.fail((e) => deferred.resolve(e.Message));
					return deferred.promise();
				}
			}
		});
	}
}

export class CustomValidator<T> extends Validator {

	dependentValidation: KnockoutComputed<any>;

	constructor(public promise: (field: FormField<T>) => JQueryPromise<boolean>, public message: (field: FormField) => string) {
    	super();
	}

	validate(field: FormField) {

    	return super.validate(field).then(e => {
    		if (!e) {
    			let evaluatedPromise;
    			if (!this.dependentValidation) {
    				evaluatedPromise = this.evaluateComputed(field);
    			} else {
    				evaluatedPromise = this.promise(field);
    			}
    			const deferred = $.Deferred<string>();

    			evaluatedPromise.done(valid => {
    				if (valid) {
    					deferred.resolve('');
    				} else {
    					deferred.resolve(this.message(field));
    				}
    			}).fail((e) => {
    				deferred.resolve(e.Message(field));
    			});
    			return deferred.promise();
    		}
    	});
	}

	evaluateComputed(field) {
    	this.dependentValidation = ko.computed({
    		read: () => {
    			return this.promise(field).done((valid) => {
    				ko.ignoreDependencies(() => {
    					if (!valid) {
    						if (field.validation.validationResults.indexOf(this.message(field)) == -1) {
    							field.validation.validationResults.push(this.message(field));
    						}
    					} else {
    						field.validation.validationResults.remove(this.message(field));
    					}
    				});
    			});
    		}, deferEvaluation: true,
    	});
    	return this.dependentValidation();
	}
}

export class LengthValidator extends FormFieldValidator {
	constructor(public from?: number, public to?: number, public message: string = `Value doesn't fall into range [${from} - ${to}]`) {
		super();
	}

	validate(field: FormField) {
		return super.validate(field).then(e => {
			if (!e) {
				const value = field.value();
				if (value) {
					const length = value.length;

					// if from passed
					if ((this.from || this.from === 0) && length < this.from) {
						return this.message;
					}

					// if to passed
					if ((this.to || this.to === 0) && length > this.to) {
						return this.message;
					}
				}
			}
		});
	}
}

export class AnyValidator extends Validator {
	constructor(public promise: () => JQueryPromise<boolean>) {
		super();
	}

	validate(_: any) {
		return super.validate(_).then(e => {
			if (!e) {
				const deferred = $.Deferred<string>();
				this.promise().done(valid => {
					if (valid) {
						deferred.resolve();
					} else {
						deferred.resolve('Invalid');
					}
				}).fail((e) => {
					deferred.resolve('Invalid');
				});
				return deferred.promise();
			}
		});
	}
}

export class DateValidator extends Validator {
	constructor(public message: string = 'Date is not valid') {
		super();
	}

	validate(field: FormField) {
		return super.validate(field).then((e: any) => {
			if (!e) {
				const value = field.value();
				if (!value) {
					return '';
				}

				const isMoment = value._isAMomentObject;
				if (isMoment) {
					const moment = value as Moment;
					if (!moment.isValid()) {
						return this.message;
					}
				} else {
					const date: any = new Date(value);
					if (isNaN(date)) {
						return this.message;
					}
				}
			}
		});
	}
}

export class Validators {
	public static Required = (message?: string): FormFieldValidator => new RequiredValidator(message);
	public static NotZeroRequired = (message?: string): FormFieldValidator => new NotZeroRequiredValidator(message);
	public static Number = (message?: string): FormFieldValidator => new NumberValidator(message);
	public static Range = (from?: number, to?: number, message?: string): FormFieldValidator => new RangeValidator(from, to, message);
	public static Length = (from?: number, to?: number, message?: string): FormFieldValidator => new LengthValidator(from, to, message);
	public static Email = (message?: string): FormFieldValidator => new EmailValidator(message);

	public static Ajax<T>(promise: (f: FormField) => IServerResponse<T>, validator: (t: T) => string): FormFieldValidator {
    	return new AjaxValidator(promise, validator);
	}

	public static Custom<T = any>(promise: (v: FormField<T>) => JQueryPromise<boolean>, message: (f: FormField) => string): FormFieldValidator {
    	return new CustomValidator<T>(promise, message);
	}

	public static Regex = (pattern: RegExp, message?: string): FormFieldValidator => new RegexValidator(pattern, message);
	public static Any = (promise: () => JQueryPromise<boolean>): FormFieldValidator => new AnyValidator(promise);
	public static Date = (message?: string): FormFieldValidator => new DateValidator(message);
}

export class ValidationContainer {
	validators: Validator[];
	validationResults = ko.observableArray<string>();
	showTooltip = ko.observable<boolean>(true);
	hideValidationHints = ko.observable(false);

	constructor(public element: IHaveRootElement, ...validators: Validator[]) {
    	this.validators = validators;

    	this.validationResults.pureSubscribe((validationResults) => {
    		if (validationResults.length) {
    			this.showError(validationResults[0]);
    		} else {
    			this.removeError();
    		}
    	});
	}

	removeError = () => {
    	if (this.element.rootElement) {
    		let error = this.element.rootElement;
    		if (this.errorElement) {
    			error = this.element.rootElement.find(this.errorElement);
    		}
    		if (this.showTooltip()) {
    			error.bstooltip('destroy');
    		} else {
    			this.element.rootElement.find('span').remove();
    		}
    	}
	};

	showError = (message: string, auto: boolean = false) => {
    	if (!this.element.rootElement) {
    		return;
    	}

		if (this.hideValidationHints()) {
			return;
		}

    	let element = this.element.rootElement;

    	if (this.showValidation() && this.showErrorValidation()) {

    		if (!this.showTooltip()) {
    			element.find('span').remove();
    			element.append('<span class=\'text-danger\'>' + message + '<span>');
    			return;
    		}

    		if (this.errorElement) {
    			const errorElement = element.find(this.errorElement);
    			if (errorElement[0]) {
    				element = $(errorElement[0]);
    			}
    		}

    		if (!element) {
    			element = this.element.rootElement;
    		}

    		if (this.showTooltip()) {
    			element.bstooltip('destroy');
    		}

    		const trigger = auto ? 'hover' : 'manual';
    		if (this.showTooltip()) {
    			if (!this.errorContainer) {
    				this.errorContainer = $(element).parents('#modal-form');
    			}
    			
    			if (!this.errorContainer[0]) {
    				this.errorContainer = $(element).offsetParent();
    			}
    			
				if (!this.errorContainer[0]) {
    				this.errorContainer = 'body';
    			}

    			if (message) {
					const tooltipOptions: any = {
						trigger: trigger,
						container: this.errorContainer,
						title: message,
						placement: this.errorPosition,
						html: this.validationMessageTitleUseHtml,
					};

				    if (this.validationMessageTitleUseHtml) {
					    tooltipOptions.template = this.tooltipTitleTemplate;
				    }

					const tooltip = element.bstooltip(tooltipOptions);

					if (!auto) {
						tooltip.bstooltip('show');
					}
    			}
    		}
    	}
	};


	valid = ko.computed({
    	read: () => {
    		return !this.validationResults().length;
    	},
    	deferEvaluation: true,
	});

	errorPosition = 'right';
	errorElement = null;
	errorContainer = null;
	validationMessageTitleUseHtml: boolean = false;
	tooltipTitleTemplate = '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner bstooltip-container"></div></div>';

	// this field allows apply validation only under certain conditions
	// by default it's applied always
	validateOnlyWhen: () => boolean = () => true;

	validate(silent?: boolean) {
		const currShowValidation = this.showValidation();
		if(silent) {
			this.showValidation(false);
		}
    	if (!this.validateOnlyWhen()) {
    		return resolvedPromise(true);
    	}

    	const outerPromise = $.Deferred<boolean>();
    	let promise = $.Deferred<string>().resolve('').promise();

    	for (let i = 0; i < this.validators.length; i++) {
    		const validator = this.validators[i];

    		((validator) => {
    			promise = promise.then(errorMessage => {
    				if (!errorMessage) {
    					return validator.validate(this.element);
    				}

    				return errorMessage;
    			});
    		})(validator);
    	}

    	promise.then(errorMessage => {
    		if (errorMessage) {
    			this.showValidation(!silent);
    			this.validationResults([errorMessage]);
    			outerPromise.resolve(false);
    		} else {
			    this.showValidation(currShowValidation);
    			this.validationResults([]);
    			outerPromise.resolve(true);
    		}
    	}).fail(() => {
    		outerPromise.resolve(false);
    	});

    	return outerPromise.promise();
	}

	validationInProgress = ko.observable<boolean>();

	successValidation = ko.observable<boolean>(false);
	errorValidation = ko.observable<boolean>(true);

	showValidation = ko.observable<boolean>(false);
	showValidationInProgress = ko.observable<boolean>();
	showSuccessValidation = ko.computed(() => {
    	const showValidation = this.showValidation();
    	const successValidation = this.successValidation();
    	const valid = this.valid();
    	const showValidationInProgress = this.showValidationInProgress();
    	return showValidation && successValidation && valid && !showValidationInProgress;
	});
	showErrorValidation = ko.computed(() => {
    	const showValidation = this.showValidation();
    	const errorValidation = this.errorValidation();
    	const valid = this.valid();
    	const showValidationInProgress = this.showValidationInProgress();
    	return showValidation && errorValidation && !valid && !showValidationInProgress;
	});


}
