export function ExclamationMarkErrorIcon() {
	return <svg data-cy={'error-icon'}
							width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.25 0C4.11 0 0.75 3.36 0.75
			7.5C0.75 11.64 4.11 15 8.25 15C12.39
			15 15.75 11.64 15.75 7.5C15.75 3.36
			12.39 0 8.25 0ZM9 11.25H7.5V9.75H9V11.25ZM9
			8.25H7.5V3.75H9V8.25Z'
			fill='#FF0C3E'/>
	</svg>;
}