import {ObservableBuilder} from '@esgi/api';
import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of} from 'rxjs';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {ValidationStatus} from '@esgi/core/enums';
import {Loader} from '@esgi/deprecated/jquery';
import {
	FormState,
	GradeScaleWizardType,
	IGradeScaleEntry,
	IReportGradeScale,
	SubjectLevel,
} from 'shared/modules/grade-scale/models';
import {
	ICreateModel,
	IGradeScaleEntryForm,
	IUpdateModel,
	IWizardGradeScale,
} from 'shared/modules/grade-scale/grade-scale/models';
import {Api} from 'shared/modules/grade-scale/grade-scale/api';
import {ValidationHandler} from 'shared/modules/grade-scale/form-field/validation-handler/validation-handler';
import {
	MaxLengthValidator,
	RequiredValidator, UniqueGradeNameValidator,
	Validator,
} from 'shared/modules/grade-scale/form-field/validation-handler/validators';
import {FormField} from 'shared/modules/grade-scale/form-field/form-field';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class GradeScaleService extends BaseService {
	private readonly loader: Loader;
	private hasScales: boolean;
	private subjectLevel?: SubjectLevel;
	private initialGradeScaleID: number;
	private minQuestionsCount: number;

	private readonly colors: string[] = ['#a5d6a7', '#90caf9', '#cdcdcd', '#80cbc4', '#ce93d8', '#fff59d', '#ffcc80', '#9fa8da', '#fff9c4', '#ef9a9a'];
	private readonly scales: BehaviorSubject<IWizardGradeScale[]> = new BehaviorSubject<IWizardGradeScale[]>([]);
	private readonly selectedScale: BehaviorSubject<IWizardGradeScale> = new BehaviorSubject<IWizardGradeScale>(null);
	private readonly formState: BehaviorSubject<FormState> = new BehaviorSubject<FormState>(FormState.None);
	private readonly moreLevelsConfirmation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private readonly defaultScale: IWizardGradeScale = {
		id: 0,
		name: 'CREATE MY OWN',
		wizardType: GradeScaleWizardType.Default,
		entries: [{
			id: 0,
			color: this.colors[0],
			description: '',
			gradeName: '',
			orderNumber: 0,
		}, {
			id: 1,
			color: this.colors[1],
			description: '',
			gradeName: '',
			orderNumber: 1,
		}],
	};

	constructor(private hierarchy: HierarchySnapshot) {
		super();
		this.loader = new Loader($('body'));
	}

	public validationStatus$: Observable<IGradeScaleEntryForm[][]> =
		this.completeOnDestroy(combineLatest([this.formState, this.selectedScale]).pipe(mergeMap(data => {
			const formState = data[0];
			const selectedScale = data[1];

			if (formState !== FormState.Saving || selectedScale === null) {
				return [];
			}

			return this.validateEntries(selectedScale.entries).pipe(map(entries => {
				const notValidEntries = entries.filter(
					f => f.color.validation.status !== ValidationStatus.Valid
						|| f.gradeName.validation.status !== ValidationStatus.Valid
						|| f.description.validation.status !== ValidationStatus.Valid);

				return [entries, notValidEntries];
			}));
		})).pipe(filter(data => data.length > 0)));

	public savedGradeScale$: Observable<{ reportGradeScale: IReportGradeScale, isNew: boolean }> =
		this.completeOnDestroy(this.validationStatus$).pipe(mergeMap(validationStatus => {
			const form = validationStatus[0];
			const notValidEntries = validationStatus[1];

			if (notValidEntries.length > 0) {
				this.setFormState(FormState.None);
				return of(null);
			}

			const selectedScale = this.selectedScale.value;

			if (this.moreLevelsConfirmation.value === false && (selectedScale.entries.length > this.minQuestionsCount)) {
				this.setMoreLevelsConfirmation(true);
				this.setFormState(FormState.None);
				return of(null);
			}

			const createNew = selectedScale.id === 0
				|| selectedScale.wizardType === GradeScaleWizardType.Stock
				|| selectedScale.wizardType === GradeScaleWizardType.District;

			const entries = this.mapToEntity(form);
			let apiAction;

			if (createNew) {
				const createModel = {
					entries: entries,
					hasScales: this.hasScales,
					parentID: selectedScale.id === 0 ? null : selectedScale.id,
				} as ICreateModel;

				apiAction = Api.create(createModel, this.hierarchy);
			} else {
				const updateModel = {
					id: selectedScale.id,
					entries: entries,
					hasScales: this.hasScales,
					subjectLevel: this.subjectLevel,
				} as IUpdateModel;

				apiAction = Api.update(updateModel);
			}

			return (apiAction as ObservableBuilder<IReportGradeScale>)
				.withCustomErrorHandler(() => this.setFormState(FormState.None))
				.pipe(tap(() => this.setFormState(FormState.None)), map((resp => {
					return {
						reportGradeScale: resp,
						isNew: createNew,
					};
				})));
		})).pipe(filter(data => data != null));

	public scales$: Observable<IWizardGradeScale[]> = this.completeOnDestroy(this.scales);

	public selectedEntries$: Observable<IGradeScaleEntryForm[]> =
		this.completeOnDestroy(this.selectedScale.pipe(map(selectedScale => {
			if (selectedScale === null) {
				return [];
			}

			return this.mapToForm(selectedScale.entries);
		})));


	public formChanged$: Observable<boolean> =
		this.completeOnDestroy(combineLatest([this.selectedScale, this.scales]).pipe(map(data => {
			const selectedScale = data[0];
			const scales = data[1];

			if (scales.length === 0 || !selectedScale) {
				return false;
			}

			const initialGradeScale = scales.filter(gs => gs.id === this.initialGradeScaleID)[0];

			if (selectedScale.id !== initialGradeScale.id) {
				return true;
			}

			if (selectedScale.entries.length !== initialGradeScale.entries.length) {
				return true;
			}

			for (let i = 0; i < selectedScale.entries.length; i++) {
				const entry = selectedScale.entries[i];
				const initialEntry = initialGradeScale.entries.filter(e => e.id === entry.id)[0];

				if (entry.gradeName !== initialEntry.gradeName) {
					return true;
				}

				if (entry.orderNumber !== initialEntry.orderNumber) {
					return true;
				}

				if (entry.color !== initialEntry.color) {
					return true;
				}

				if (entry.description !== initialEntry.description) {
					return true;
				}
			}

			return false;
		})));

	public selectedScale$: Observable<IWizardGradeScale> = this.completeOnDestroy(this.selectedScale);

	public init(hasScales: boolean, gradeScaleID: number = 0, subjectLevel?: SubjectLevel) {
		this.loader.mask();
		this.hasScales = hasScales;
		this.subjectLevel = subjectLevel;
		Api.init(this.hierarchy, gradeScaleID, subjectLevel).subscribe(r => {
			this.loader.unmask();
			this.setScales([this.defaultScale, ...r.gradeScales]);
			this.minQuestionsCount = r.minQuestionsCount;
			this.initialGradeScaleID = gradeScaleID || 0;
			this.selectScale(this.initialGradeScaleID);
		});
	}

	public moreLevelsConfirmation$: Observable<boolean> = this.completeOnDestroy(this.moreLevelsConfirmation);

	public deleteGradeScale(gradeScaleID: number) {
		this.loader.mask();

		return Api.delete(gradeScaleID).pipe(tap(r => {
			this.loader.unmask();
		}));
	}

	public selectScale(gradeScaleID: number) {
    const scales = this.scales.value;
    let scale = scales.filter(s => s.id === gradeScaleID)[0];
    if (!scale){
      scale = scales[0];
    }
		this.selectedScale.next(JSON.parse(JSON.stringify(scale)));
	}

	public setFormState(formState: FormState) {
		this.formState.next(formState);
	}

	public setScales(gradeScales: IWizardGradeScale[]) {
		this.scales.next(gradeScales);
	}

	public setMoreLevelsConfirmation(set: boolean) {
		this.moreLevelsConfirmation.next(set);
	}

	public save() {
		if (this.formState.value === FormState.None) {
			this.setFormState(FormState.Saving);
		}
	}

	updateEntry(form: IGradeScaleEntryForm) {
		const newEntry = {
			id: form.id,
			description: form.description.value,
			color: form.color.value,
			gradeName: form.gradeName.value,
			orderNumber: form.orderNumber,
		} as IGradeScaleEntry;

		const scale = this.selectedScale.value;

		const updatedGradeScale = this.updateEntryInScale(scale, newEntry);
		this.selectedScale.next(updatedGradeScale);
	}

	removeEntry(form: IGradeScaleEntryForm) {
		const scale = this.selectedScale.value;

		const ge = scale.entries.filter(ge => ge.id === form.id)[0];
		const updatedGradeScale = this.removeEntryFromScale(scale, ge);
		this.selectedScale.next(updatedGradeScale);
	}

	addNewEntry() {
		let maxID = 0;
		let maxOrderNum = 0;

		const scale = this.selectedScale.value;

		if (scale.entries.length > 0) {
			maxID = Math.max(...scale.entries.map(ge => ge.id)) + 1;
			maxOrderNum = Math.max(...scale.entries.map(ge => ge.orderNumber)) + 1;
		}

		const firstAvailableColor = this.colors.filter(c => !scale.entries.map(e => e.color).includes(c))[0];

		const newEntry = {
			id: maxID,
			color: firstAvailableColor,
			orderNumber: maxOrderNum,
			gradeName: '',
			description: '',
			valid: false,
		} as IGradeScaleEntry;

		const updatedGradeScale = this.addEntryToScale(scale, newEntry);
		this.selectedScale.next(updatedGradeScale);
	}

	orderEntries(prevOrderNumber: number, newOrderNumber: number) {
		const downDirection = newOrderNumber > prevOrderNumber;

		const scale = this.selectedScale.value;

		const orderEntry = scale.entries.filter(e => e.orderNumber === prevOrderNumber)[0];
		orderEntry.orderNumber = newOrderNumber;

		scale.entries = scale.entries.map(e => {
			if (e.id !== orderEntry.id) {
				if (downDirection) {
					if (e.orderNumber <= newOrderNumber && e.orderNumber >= prevOrderNumber) {
						e.orderNumber--;
					}
				} else {
					if (e.orderNumber >= newOrderNumber && e.orderNumber <= prevOrderNumber) {
						e.orderNumber++;
					}
				}
			}

			return e;
		});

		this.selectedScale.next(scale);
	}

	private updateEntryInScale(gradeScale: IWizardGradeScale, gradeEntry: IGradeScaleEntry) {
		const newGradeScale = {...gradeScale};

		newGradeScale.entries = newGradeScale.entries.map(ge => {
			if (ge.id === gradeEntry.id) {
				return {...gradeEntry};
			}

			return {...ge};
		});

		return newGradeScale;
	}

	private removeEntryFromScale(gradeScale: IWizardGradeScale, gradeEntry: IGradeScaleEntry) {
		const newGradeScale = {...gradeScale};
		newGradeScale.entries = newGradeScale.entries.filter(e => e.id !== gradeEntry.id);
		return newGradeScale;
	}

	private addEntryToScale(gradeScale: IWizardGradeScale, gradeEntry: IGradeScaleEntry) {
		const newGradeScale = {...gradeScale};
		newGradeScale.entries.push(gradeEntry);
		return newGradeScale;
	}

	private mapToForm(entries: IGradeScaleEntry[]) {
		const entryForms = entries.map(ge => {
			const entryForm = {
				id: ge.id,
				color: new FormField<string>(ge.color),
				description: new FormField<string>(ge.description),
				gradeName: new FormField<string>(ge.gradeName),
				orderNumber: ge.orderNumber,
			} as IGradeScaleEntryForm;

			return entryForm;
		});

		return entryForms;
	}

	private mapToEntity(form: IGradeScaleEntryForm[]) {
		const entries = form.map(ge => {
			const entry = {
				id: ge.id,
				color: ge.color.value,
				gradeName: ge.gradeName.value,
				description: ge.description.value,
				orderNumber: ge.orderNumber,
			} as IGradeScaleEntry;

			return entry;
		});

		return entries;
	}

	private validateEntries(entries: IGradeScaleEntry[]): Observable<IGradeScaleEntryForm[]> {
		return forkJoin(this.mapToForm(entries).map(entryForm => {
			const validators = [
				this.colorValidationHandler.handle(entryForm.color.value).pipe(map(validateResult => {
					entryForm.color = new FormField(entryForm.color.value, {
						status: validateResult.status,
						message: validateResult.firstMessage,
					});

					return entryForm;
				})),
				this.nameValidationHandler.handle(entryForm.gradeName.value).pipe(map(validateResult => {
					entryForm.gradeName = new FormField(entryForm.gradeName.value, {
						status: validateResult.status,
						message: validateResult.firstMessage,
					});

					return entryForm;
				})),
				this.descriptionValidationHandler.handle(entryForm.description.value).pipe(map(validateResult => {
					entryForm.description = new FormField(entryForm.description.value, {
						status: validateResult.status,
						message: validateResult.firstMessage,
					});

					return entryForm;
				})),
				this.uniqueNameValidationHandler.handle({
					entries: entries,
					compareEntry: entryForm,
				}).pipe(map(validateResult => {
					entryForm.gradeName = new FormField(entryForm.gradeName.value, {
						status: validateResult.status,
						message: validateResult.firstMessage,
					});

					return entryForm;
				}))];

			return forkJoin(validators).pipe(mergeMap(s => s));
		}));
	}

	private get nameValidationHandler() {
		return ValidationHandler.Create([new RequiredValidator(), new MaxLengthValidator(3)]);
	}

	private get descriptionValidationHandler() {
		return ValidationHandler.Create([new RequiredValidator()]);
	}

	private get colorValidationHandler() {
		return ValidationHandler.Create([new RequiredValidator(), {
			validate: (color: string) => {
				const duplicates = this.selectedScale.value.entries.filter((c) => c.color === color);
				return Validator.createResult(duplicates.length === 1, 'Please enter a unique color for each level');
			},
		}]);
	}

	private get uniqueNameValidationHandler() {
		return ValidationHandler.Create([new UniqueGradeNameValidator()]);
	}
}
