import './styles.less';
import React from 'react';
import QuestionSliderItem from './question-slider-item';
import {QuestionModel} from '../../services/search-service';

class State {
	currentQuestionId: number;
}

class Props {
	testId: number;
	isWhiteBackground: boolean;
	questions: QuestionModel[] = [];
	startImageID?: number;
}

export default class QuestionSlider extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		const state = new State();
		state.currentQuestionId = props.startImageID;
		this.state = state;
	}
	private imageWrapper: HTMLDivElement;

	render() {
		return <div
			className={this.props.isWhiteBackground ? 'preview-question-slider white' : 'preview-question-slider'}>
			<a href='#' className={'arrow ' + (!this.canGoLeft ? 'disabled' : '')}
			   onClick={() => this.goLeft()}>
				<svg xmlns='http://www.w3.org/2000/svg' width='13' height='21' viewBox='0 0 13 21'
				     fill='none'>
					<path
						d='M12.5056 17.7277L5.00473 10.2104L12.5056 2.69311L10.1964 0.383881L0.369888 10.2104L10.1964 20.0369L12.5056 17.7277Z'/>
				</svg>
			</a>
			<div className='image-wrapper' ref={r => this.imageWrapper = r}>
				{this.props.questions.map((q, i) => {
					return this.renderImage(q, i);
				})}
			</div>
			<a href='#' className={'arrow ' + (!this.canGoRight ? 'disabled' : '')}
			   onClick={() => this.goRight()}>
				<svg xmlns='http://www.w3.org/2000/svg' width='13' height='21' viewBox='0 0 13 21'
				     fill='none'>
					<path
						d='M0.804688 17.7277L8.3056 10.2104L0.804688 2.69311L3.11392 0.383881L12.9404 10.2104L3.11392 20.0369L0.804688 17.7277Z'/>
				</svg>
			</a>
		</div>;
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (prevState.currentQuestionId !== this.state.currentQuestionId) {
			let scrollLeft = this.currentQuestionIndex * this.imageWrapper.offsetWidth;
			this.scrollTo(scrollLeft);
		}

		if(prevProps.startImageID !== this.props.startImageID) {
			this.setState({currentQuestionId: this.props.startImageID || this.props.questions.map(s => s.questionID)[0]});
		}
	}

	componentDidMount(): void {
		let scrollLeft = this.currentQuestionIndex * this.imageWrapper.offsetWidth;
		this.scrollTo(scrollLeft, false);
	}

	private renderImage(q: QuestionModel, index: number) {
		if (!q) {
			return null;
		}

		return <QuestionSliderItem key={q.questionID}
		                           model={q}
		                           testId={this.props.testId}
		                           distance={this.currentQuestionIndex - index}/>;
	}


	get currentQuestion(): QuestionModel {
		return this.props.questions[this.currentQuestionIndex];
	}

	get currentQuestionIndex() {
		return this.props.questions.findIndex(q => q.questionID === this.state.currentQuestionId);
	}

	private scrollTo(scrollLeft: number, animate: boolean = true) {
		if (scrollLeft < 0) {
			scrollLeft = 0;
		}

		if (animate) {
			$(this.imageWrapper).animate({scrollLeft: scrollLeft}, 400);
		} else {
			this.imageWrapper.scrollLeft = scrollLeft;
		}
	}


	get canGoLeft() {
		return this.props.questions.length > 1;
	}

	get canGoRight() {
		return this.props.questions.length > 1;
	}

	goLeft() {
		if (this.canGoLeft) {
			let questionBefore = this.props.questions[this.currentQuestionIndex - 1];
			if (!questionBefore) {
				questionBefore = this.props.questions[this.props.questions.length - 1];
			}

			this.setState({currentQuestionId: questionBefore.questionID});
		}
	}

	goRight() {
		if (this.canGoRight) {
			let questionBefore = this.props.questions[this.currentQuestionIndex + 1];
			if (!questionBefore) {
				questionBefore = this.props.questions[0];
			}

			this.setState({currentQuestionId: questionBefore.questionID});
		}
	}
}
