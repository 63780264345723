import React, {Suspense} from 'react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';
import {lazyComponent} from '@esgi/core/react';

const StudentsScreenPage = lazyComponent(() => import('pages/students-screen'));

export default function StudentsScreen() {
	return <RouteWrapper>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<StudentsScreenPage/>
		</Suspense>
	</RouteWrapper>;
}
