import {IconComponent} from '@esgi/ui';
import {SelectableList} from '@esgi/ui/layout';
import {ExpandableRearrangeableItem} from './lists.styled';
import {SelectableListTriggerContent} from './selectable-list-trigger-content';
import {getListValue} from '../../../../kit';

export type ExpandableRearrangeableListItem = {
	id: number;
	name: string;
	itemsCount: number;
};

type Props = {
	IconBefore: IconComponent;
	onDragEnd: (groupState: ExpandableRearrangeableListItem[]) => void;
	selectedValue: number | null;
	items: ExpandableRearrangeableListItem[];
};

export function ExpandableRearrangeableList({selectedValue, onDragEnd, items, IconBefore}: Props) {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedValue)}>
				<SelectableList.GroupDroppable onDragEnd={onDragEnd} droppableItems={items}>
					{items.map(({id, name, itemsCount}, index) => (
						<ExpandableRearrangeableItem
							value={String(id)}
							index={index}
							draggableId={String(id)}
							key={id}
							IconBefore={IconBefore}
							selected={selectedValue === id}
						>
							<SelectableListTriggerContent itemsCount={itemsCount} triggerLabel={name} />
						</ExpandableRearrangeableItem>
					))}
				</SelectableList.GroupDroppable>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
