import {BaseService} from '@esgi/core/service';
import {
	DefaultProfileControllers,
	DistrictModel,
	DropdownInModel,
	ProfileTrackTypePeriod,
	SchoolYearTypeModel,
	TrackModel, UserInfo,
} from './types';
import {createUserInfoForm, UserInfoProfileFormType} from './forms/user-info';
import {createLoginInfoForm, LoginInfoFormType} from './forms/login-info';
import {createTrackInfoForm, TrackInfoFormType} from './forms/track-info';
import {BehaviorSubject, tap} from 'rxjs';
import {userStorage} from '@esgi/core/authentication';
import {EventBusManager} from '@esgillc/events';
import {DateTools} from './utils';

export class BaseProfileService extends BaseService {
	public userInfoForm: UserInfoProfileFormType = createUserInfoForm();
	public loginInfoForm: LoginInfoFormType = createLoginInfoForm();
	public trackInfoForm: TrackInfoFormType = createTrackInfoForm();
	public countries = new BehaviorSubject<DropdownInModel[]>([]);
	public states = new BehaviorSubject<DropdownInModel[]>([]);

	public schoolYearTypes = new BehaviorSubject<SchoolYearTypeModel[]>([]);
	public tracks = new BehaviorSubject<TrackModel[]>([]);
	public periods = new BehaviorSubject<ProfileTrackTypePeriod[]>([]);
	public district = new BehaviorSubject<DistrictModel>({id: 0, name: ''});

	public userInfo = new BehaviorSubject<UserInfo>({
		schoolName: '',
		isLinked: false,
		agreementLevelCode: '',
		expirationDate: '',
		canAllowRenewByCC: false,
		initialLogin: '',
		notRenewable: false,
		isPreassess: false,
		canEditGradeLevels: false,
		gradeLevels: [],
	});

	protected currentUser = userStorage.get();
	protected userID: number;
	protected initialPassword = '!!!!!!!!';

	protected eventBus = new EventBusManager();

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	public getStatesByCountryID(countryID: number) {
		return this.httpClient.ESGIApi.get<{ id: number, name: string }[]>(DefaultProfileControllers.Common, 'get-states-by-country-id', {countryID})
			.pipe(tap(response => {
				const states = response.map(x => new DropdownInModel(x.id, x.name));
				this.userInfoForm.controls.state.value = [states[0]];
				this.states.next(states);
			}));
	}

	protected trackCreated = (newTrack: TrackModel) => {
		this.tracks.next([...this.tracks.value, newTrack]);
		this.trackInfoForm.controls.track.value = [newTrack];
		this.periods.next(this.getPeriods());
	};

	protected trackChanged = (editedTrack: TrackModel) => {
		this.tracks.next(this.tracks.value.map(x => x.trackID === editedTrack.trackID ? editedTrack : x));
		if (this.trackInfoForm.controls.track.value[0].trackID === editedTrack.trackID) {
			this.trackInfoForm.controls.track.value = [editedTrack];
			this.periods.next(this.getPeriods());
		}
	};

	protected getPeriods(): ProfileTrackTypePeriod[] {
		const selectedTrack = this.trackInfoForm.controls.track.value[0];
		const selectedSchoolYearType = this.trackInfoForm.controls.type.value[0];
		if (!selectedTrack.trackDates.length) {
			return selectedSchoolYearType.defaultTrackDates.map(x => {
				return {
					from: DateTools.toDate(x.dateFrom),
					to: DateTools.toDate(x.dateTo),
				};
			});
		}
		return selectedTrack.trackDates.map(x => {
			return {
				from: DateTools.toDate(x.dateFrom),
				to: DateTools.toDate(x.dateTo),
			};
		});
	}
}
