import {FileTypeTemp, MenuItem} from './types';

export const menuItems: MenuItem[] = [
	{text: 'One PDF file', value: FileTypeTemp.Pdf, description: 'per student or class'},
	{text: 'ZIP', value: FileTypeTemp.PdfZip, description: 'Individual PDF files for each student in the class'},
	{text: 'One Word file', value: FileTypeTemp.Word, description: 'per student or class'},
	{text: 'ZIP', value: FileTypeTemp.WordZip, description: 'Individual Word files for each student in the class'},
];

export const emptyTestListMessages = {
	group: 'There is no test data for any student in this group.',
	class: 'There is no test data for any student in this class.',
	student: 'There is no test data for the student.',
	anyStudents: 'There is no test data for any student selected.',
};
