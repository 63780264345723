import {Buttons} from '@esgillc/ui-kit/button';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../hooks';

export interface Props {
	onCancel: () => void;
}

export function FooterIEP(props: Props) {
	const service = useTestSessionDetailsService();
	const editMode = useBehaviorSubject(service.iepEditMode$);

	const onEdit = () => {
		service.setIEPEditMode(true);
	};
	const onSave = () => {
		service.serviceIEP.update(() => {
			service.setIEPEditMode(false);
		});
	};
	const onCancel = () => {
		if (editMode) {
			service.serviceIEP.resetIEPGoalFormData();
			service.setIEPEditMode(false);
		} else {
			props.onCancel();
		}
	};

	return (
		<>
			<Buttons.Gray
				onClick={onCancel}
			>
				{editMode ? 'Cancel' : 'Close'}
			</Buttons.Gray>
			<Buttons.Contained
				onClick={editMode ? onSave : onEdit}
			>
				{editMode ? 'Save' : 'Edit'}
			</Buttons.Contained>
		</>
	);
}
