import {ReactNode} from 'react';
import {CSS} from '@stitches/react';

export type TabItem<TabId extends number = number> = {
	value: TabId;
	label: ReactNode;
	labelDataCy?: string;
	css?: CSS;
	tooltipText?: string;
};

export enum DrawerType {
	AUTHORS,
	SERIES,
}

export enum TestScreenType {
	None = 'None',
	OneToOne = 'OneToOne',
	SelfAssessment = 'SelfAssessment',
}

export type ChangedTestModel = {
	testID: number
	name: string,
	description: string,
	contentAreaName: string,
	starred: boolean,
	numberOfQuestions: number,
	totalPossible: number,
	imageQuestionID: number,
	questions: [],
	gradeLevels: number[],
	stateStandardIDs: number[],
}

export {FilterScope} from '@esgi/contracts/esgi/types/esgi.testexplorer/features/search/filter-scope';
export {Administration} from '@esgi/contracts/esgi/types/esgi.testexplorer/features/search/administration';
export {TestsSortBy} from '@esgi/contracts/esgi/types/esgi.testexplorer/features/search/tests-sort-by';
export {SortDirection} from '@esgi/contracts/esgi/types/esgi.enums/sort-direction';
export {type TestModel} from '@esgi/contracts/esgi/types/esgi.testexplorer/features/search/test-model';
export {type QuestionModel} from '@esgi/contracts/esgi/types/esgi.testexplorer/features/search/question-model';
export {TestType} from '@esgi/contracts/esgi/types/esgi.enums/test-type';
export {type DomainModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/get/by-id/domain-model';
export {type ClusterModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/get/by-id/cluster-model';
export {type StateStandardModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/get/by-id/state-standard-model';
export {type GradeLevel} from '@esgi/contracts/esgi/types/esgi.assets/features/common/state-standards/get/grade-levels/grade-level';
export {CardView as CardViewType} from '@esgi/contracts/esgi/types/sso.accounts/models/user-page-states/teacher/card-view';