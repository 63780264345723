import {useRef} from 'react';
import {EventBusManager} from '@esgillc/events';
import {ConflictStudentModel, Validation} from 'shared/modules/student-roster-upload-tool/types';
import {StudentRosterToolEvents} from 'shared/modules/student-roster-upload-tool/events';
import {StudentRow} from './components/student-row';
import styles from './styles.module.less';

interface Props {
	students: ConflictStudentModel[];
	onEdit: (students: ConflictStudentModel[], validation: Validation) => void;
	onClick: (validation: Validation) => void;
	onReset: () => void;
	onView: (id: number) => void;
}

export function ConflictTable({students, onClick, onView, onReset, onEdit}: Props) {

	const eventBus = new EventBusManager();
	const ref = useRef<HTMLDivElement>(null);

	const onHandleEdit = (student: ConflictStudentModel, validation: Validation) => {
		const studentsList = students.map((s) => {
			if (s.id === student.id) {
				return {...student};
			} else {
				return {...s};
			}
		});
		onEdit(studentsList, validation);
	};

	const onRemove = (student: ConflictStudentModel) => {
		student.removed = true;
		onHandleEdit(student, new Validation());
	};

	const rowScrolled = (offset: number) => {
		ref.current.scrollTop = offset;
		setTimeout(() => eventBus.dispatch(StudentRosterToolEvents.ShowValidation, null), 100);
	};
	const onScroll = () => {
		eventBus.dispatch(StudentRosterToolEvents.HideValidation, null);
	};

	return <div data-cy={'conflict-table'} className={styles.rosterConflictTable}>
		<div data-cy={'reset'} className={styles.reset} onClick={onReset}>Reset</div>
		<div className={styles.conflictStudentsContainer} ref={ref} onScroll={onScroll}>
			{students?.filter(s => !s.removed).map((s, index) => {
				return <StudentRow
					key={s.id}
					student={s}
					index={index + 1}
					tableHeight={10}
					tableOffset={10}
					onEdit={(student, validation) => onHandleEdit(student, validation)}
					onClick={(validation) => onClick(validation)}
					onRemove={() => onRemove(s)}
					onView={(id) => onView(id)}
					scrolled={(offset) => rowScrolled(offset)}
				/>;
			})}
		</div>
	</div>;
}
