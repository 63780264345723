import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr',
	gap: '2px',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	padding: '12px 20px',
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	position: 'absolute',
	width: '100%',
	marginTop: 6,
});
