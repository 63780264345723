import {isUndefined} from '@esgi/ui';
import moment from 'moment';

export function addDaysToMomentDate(date: moment.Moment | undefined, days: number) {
	if (isUndefined(date)) {
		throw new Error('date is undefined');
	}

	return moment(date).add(days, 'days');
}
