import * as React from 'react';
import type {SVGProps} from 'react';

export function UnassignLink(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
            {...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.75 7.5C15.3358 7.5 15 7.83579 15 8.25C15 8.66421 15.3358 9 15.75 9H17.25C18.8025 9 19.5 9.837 19.5 11.25C19.5 12.663 18.8025 13.5 17.25 13.5H16.5C16.086 13.5 15.75 13.836 15.75 14.25C15.75 14.664 16.086 15 16.5 15H17.25C19.6973 15 21 13.437 21 11.25C21 9.063 19.6973 7.5 17.25 7.5H15.75Z'
				fill='#F50023'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.75 9C4.563 9 3 10.3028 3 12.75C3 15.1973 4.563 16.5 6.75 16.5H8.25C8.66421 16.5 9 16.1642 9 15.75C9 15.3358 8.66421 15 8.25 15H6.75C5.337 15 4.5 14.3025 4.5 12.75C4.5 11.1975 5.337 10.5 6.75 10.5H7.5C7.914 10.5 8.25 10.164 8.25 9.75C8.25 9.336 7.914 9 7.5 9H6.75Z'
				fill='#F50023'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.2123 9.96967C14.5052 10.2626 14.5052 10.7374 14.2123 11.0303L13.1511 12.0905L14.2123 13.1517C14.5052 13.4445 14.5052 13.9194 14.2123 14.2123C13.9194 14.5052 13.4445 14.5052 13.1517 14.2123L12.0905 13.1511L11.0303 14.2123C10.7374 14.5052 10.2626 14.5052 9.96967 14.2123C9.67678 13.9194 9.67678 13.4445 9.96967 13.1517L11.0298 12.0905L9.96967 11.0303C9.67678 10.7374 9.67678 10.2626 9.96967 9.96967C10.2626 9.67678 10.7374 9.67678 11.0303 9.96967L12.0905 11.0298L13.1517 9.96967C13.4445 9.67678 13.9194 9.67678 14.2123 9.96967Z'
				fill='#F50023'
			/>
		</svg>
	);
}
