import {StudentData, Test} from 'modules/reports/untested-students-report/models';
import {StudentSort} from '@esgi/core/enums';

const updatePeriodData = (students: StudentData[], newDataStudents: StudentData[]) => {
	for (let i = 0; i < students?.length; i++) {
		const studentData = students[i];
		const filteredStudentData = newDataStudents.filter(x => x.studentID === studentData.studentID);
		if (filteredStudentData.length === 0) {
			for (let j = 0; j < studentData.tests.length; j++) {
				const test = studentData.tests[j];
				test.lastTestDateOverPeriod = null;
				test.correctAnswersOverPeriod = 0;
			}
		} else {
			const newStudentData = filteredStudentData[0];
			for (let j = 0; j < studentData.tests.length; j++) {
				const test = studentData.tests[j];
				const filteredTestData = newStudentData.tests.filter(x => x.testID === test.testID);

				if (filteredTestData.length > 0) {
					const newTestData = filteredTestData[0];
					test.lastTestDateOverPeriod = newTestData.lastTestDateOverPeriod;
					test.correctAnswersOverPeriod = newTestData.correctAnswersOverPeriod;
				} else {
					test.lastTestDateOverPeriod = null;
					test.correctAnswersOverPeriod = 0;
				}
			}
		}
	}
};

const studentComparer = (a: StudentData, b: StudentData, sortColumn: StudentSort, sortAsc: boolean) => {
	const aFirstName = a.firstName.toLowerCase();
	const aLastName = a.lastName.toLowerCase();
	const bFirstName = b.firstName.toLowerCase();
	const bLastName = b.lastName.toLowerCase();

	let avalue, bvalue;
	if (sortColumn === StudentSort.FirstName) {
		avalue = [aFirstName, aLastName];
		bvalue = [bFirstName, bLastName];
	} else {
		avalue = [aLastName, aFirstName];
		bvalue = [bLastName, bFirstName];
	}

	let result = avalue[0] > bvalue[0] ? 1 : avalue[0] < bvalue[0] ? -1 : 0;
	if (result === 0) {
		result = avalue[1] > bvalue[1] ? 1 : avalue[1] < bvalue[1] ? -1 : 0;
	}

	if (!sortAsc) {
		result = -result;
	}

	return result;
};

const getCellText = (data: StudentData, test: Test, isTeacherOrSpecialist: boolean) => {
	let value = isTeacherOrSpecialist ? 'Run Test' : ('0/' + test.maxScore);
	const filteredTests = data.tests.filter(x => x.testID === test.testID);
	if (filteredTests.length > 0) {
		const testData = filteredTests[0];
		if (testData.lastTestDateOverPeriod !== null) {
			value = testData.correctAnswersOverPeriod === test.maxScore ? '100%' : (testData.correctAnswersOverPeriod + '/' + test.maxScore);
		} else if (testData.correctAnswersAll === test.maxScore) {
			value = '100%';
		} else if (!isTeacherOrSpecialist) {
			value = testData.correctAnswersAll + '/' + test.maxScore;
		}
	}
	return value;
};


const isAllowedToTest = (data: StudentData, test: Test, isTeacherOrSpecialist: boolean) => {
	let value = true;
	if (isTeacherOrSpecialist) {
		const filteredTests = data.tests.filter(x => x.testID === test.testID);
		if (filteredTests.length > 0) {
			const testData = filteredTests[0];
			value = testData.lastTestDateOverPeriod === null;
		}
	} else {
		value = false;
	}
	return value;
};


export const untestedStudentsReportUtils = {
	updatePeriodData,
	studentComparer,
	isAllowedToTest,
	getCellText,
};
