import {Validator} from 'shared/modules/grade-scale/form-field/validation-handler/validators';
import {IValidationResult} from 'shared/modules/grade-scale/form-field/validation-handler/types';

export class FormField<T> {
	validation: IValidationResult
	value: T

	constructor(value: T, validation?: IValidationResult) {
		this.value = value;
		if (validation === undefined) {
			this.validation = Validator.createResult(true, '');
		} else {
			this.validation = validation;
		}
	}
}