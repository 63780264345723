import {SmallScreenContent} from '../small-screen-content';
import {LargeScreenContent} from '../large-screen-content';
import {OptionItem} from '../../types';

type Props = {
	isSmallScreen: boolean,
	standardTypeOptions: OptionItem[],
	onStandardTypeChange: (id: string) => void,
	selectedStandardType: OptionItem['value'],
	onContentAreaSelect: (id: number) => void,
	selectedContentArea: OptionItem['value'],
	gradeLevels: OptionItem[],
	onGradeLevelSelect: (id: number) => void,
	selectedGradeLevel: OptionItem['value'],
	isContentAreasLoading: boolean
	isDomainsLoading: boolean
	contentAreas: OptionItem[],
	domainsList: OptionItem[],
	selectedDomain: OptionItem['value'],
	onDomainSelect: (id: number) => void,
}

export function Content({isSmallScreen, ...props}: Props) {
	if (isSmallScreen) {
		return <SmallScreenContent {...props}/>;
	}
	
	return <LargeScreenContent {...props}/>;
}