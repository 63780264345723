import moment, {Moment} from 'moment';
import {SubjectType} from '@esgi/core/enums';
import {
	ResponseGroupItem,
	ResponseSettingsInit,
	ResponseStudent,
	ResponseSubject,
	ResponseTest,
	ResponseTrack,
	ResponseUser,
} from './api-models';

export class InitModel {
	constructor(public selectedGroupItemID?: number,
	            public selectedStudentID?: number,
	            public subjectID?: number,
	            public subjectType?: SubjectType,
	            public selectedTestID?: number) {
	}
}

export class Init {
	user: User;
	subjects: Subject[];
	groupItems: GroupItem[];
	selectedGroupItem: GroupItem;
	groupType: string;
	selectedSubjectId: number;
	hasGradeScales: boolean;
	readOnly: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	tracks: Track[];

	public static FromResponse(response: ResponseSettingsInit): Init {
		const model = new Init();

		model.user = User.FromResponse(response.user);
		model.subjects = Subject.FromResponseArray(response.subjects);
		model.groupItems = GroupItem.FromResponseArray(response.groupItems);
		model.groupType = response.selectedGroupItemType;

		if (model.groupItems && response.selectedGroupItemID != 0) {
			model.selectedGroupItem = model.groupItems.filter(c => c.id == response.selectedGroupItemID)[0];
		}

		model.hasGradeScales = response.hasGradeScales;
		model.readOnly = response.readOnly;
		model.testResultsCorrectVerbiage = response.testResultsCorrectVerbiage;
		model.testResultsIncorrectVerbiage = response.testResultsIncorrectVerbiage;
		model.tracks = Tracks.FromResponseArray(response.tracks);

		return model;
	}
}

export class GroupItem {
	id: number;
	name: string;
	selectedStudent: Student;
	students: Student[];

	public static FromResponse(response: ResponseGroupItem): GroupItem {
		const model = new GroupItem();

		model.id = response.id;
		model.name = response.name;
		model.students = Student.FromResponseArray(response.students);

		if (model.students) {
			model.selectedStudent = model.students.filter(s => s.studentId == response.selectedStudentID)[0];

			if (!model.selectedStudent) {
				model.selectedStudent = model.students[0];
			}
		}

		return model;
	}

	public static FromResponseArray(response: ResponseGroupItem[]): GroupItem[] {

		return (response) ? response.map(s => {
			return GroupItem.FromResponse(s);
		}) : null;
	}
}

export class Student {
	studentId: number;
	firstName: string;
	lastName: string;
	languageId: number;
	gradeLevelId: number;

	get fullName(): string {

		let result = '';

		if (this.firstName) {
			result += this.firstName + '';
		}

		if (this.lastName) {
			result += ` ${this.lastName}`;
		}

		return result;
	}

	public static FromResponse(response: ResponseStudent): Student {
		const model = new Student();
		model.studentId = response.studentID;
		model.firstName = response.firstName;
		model.lastName = response.lastName;
		model.languageId = response.languageID;
		model.gradeLevelId = response.gradeLevelID;

		return model;
	}

	public static FromResponseArray(response: ResponseStudent[]): Student[] {

		const results = [this.AllStudentsModel()];

		const students = response.map(s => {
			return Student.FromResponse(s);
		});

		return results.concat(students);
	}

	public static AllStudentsModel(): Student {
		const result = new Student();
		result.studentId = 0;
		result.firstName = 'All';
		return result;
	}
}

export class User {
	userId: number;
	firstName: string;
	lastName: string;
	title: string;
	includeQuestionNotesOption: Flag;
	includeSummaryNotesOption: Flag;
	includeGradeOption: Flag;
	includeUntestedQuestionsOption: Flag;
	includeMessageOption: Flag;
	printInColorOption: Flag;
	showSessionDateOption: Flag;

	get fullName(): string {

		let result = '';

		if (this.title) {
			result += this.title + ' ';
		}

		if (this.lastName) {
			result += this.lastName;
		}

		return result;
	}

	static FromResponse(response: ResponseUser): User {
		const model = new User();
		model.userId = response.userID;
		model.firstName = response.firstName;
		model.lastName = response.lastName;
		model.title = response.title;
		model.includeQuestionNotesOption = Flag.FromResponse(response.includeQuestionNotesOption);
		model.includeSummaryNotesOption = Flag.FromResponse(response.includeSummaryNotesOption);
		model.includeGradeOption = Flag.FromResponse(response.includeGradeOption);
		model.includeUntestedQuestionsOption = Flag.FromResponse(response.includeUntestedQuestionsOption);
		model.includeMessageOption = Flag.FromResponse(response.includeMessageOption);
		model.printInColorOption = Flag.FromResponse(response.printInColor);
		model.showSessionDateOption = Flag.FromResponse(response.showSessionDate);

		return model;
	}
}

export class Flag {
	name: string;
	value: boolean;

	public static FromResponse(response: Flag): Flag {
		const model = new Flag();

		model.name = response.name;
		model.value = response.value;

		return model;
	}
}

export class Subject {
	id: number;
	name: string;
	subjectType: string;
	level: string;
	hasScales: boolean;

	public static FromResponse(response: ResponseSubject): Subject {
		const model = new Subject();
		model.name = response.name;
		model.id = response.subjectID;
		model.subjectType = response.subjectType;
		model.level = response.subjectLevel;
		model.hasScales = response.hasScales;

		return model;
	}

	public static FromResponseArray(response: ResponseSubject[]): Subject[] {
		return response && response.map(s => {
			return Subject.FromResponse(s);
		}) || [];
	}
}

export class Test {
	id: number;
	name: string;
	type: string;
	correct: number;
	incorrect: number;
	untested: number;
	questionsCount: number;
	orderNum: number;
	DataExists: boolean;

	public static FromResponse(response: ResponseTest): Test {
		const model = new Test();
		model.id = response.id;
		model.name = response.name;
		model.type = response.type;
		model.correct = response.correct;
		model.incorrect = response.incorrect;
		model.untested = response.untested;
		model.orderNum = response.orderNum;
		model.questionsCount = response.questionsCount;
		model.DataExists = response.dataExists;
		return model;
	}

	public static FromResponseArray(response: ResponseTest[]): Test[] {
		return (response)
			? response.map(s => {
				return Test.FromResponse(s);
			}) : [];
	}
}

export interface IFooterData {
	viewParentLetterDisabled: () => boolean;
}

export class Tracks {
	public static FromResponseArray(response: ResponseTrack[]): Track[] {
		if (response) {
			return response.map((t) => Track.FromResponse(t));
		} else {
			return [];
		}
	}
}

export class Track {
	trackID: number;
	dateTo: Moment;
	dateFrom?: Moment;

	public static FromResponse(response: ResponseTrack): Track {
		const track = new Track();
		track.dateTo = moment(response.dateTo);
		track.trackID = response.trackID;
		return track;
	}
}

export enum SortBy {
	TestOrder = 0,
	Alphabetical = 1
}

export class Language {
	languageId: number;

	languageName: string;

	isDefault: boolean;
}
