import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';
import {ProgressChip} from '@esgillc/ui-kit/progress-chip';

type StudentProgressBodyCell = {
	testsCount: number;
	testCompletion: number;
	className?: string | undefined;
};

export function ProgressBodyCell({testsCount, testCompletion, className}: StudentProgressBodyCell) {
	return (
		<div className={join(styles.progressCell, className)}>
			<div className={styles.progress}>
				<ProgressChip total={testsCount} completed={testCompletion} />
			</div>
			{testCompletion}/{testsCount}
		</div>
	);
}
