import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {ReportErrorEvent} from '@esgi/core/react';
import {BaseService} from '@esgi/core/service';
import {SubjectType, TestType} from '@esgi/core/enums';
import {HierarchyMode, HierarchySnapshot} from 'modules/hierarchy/core/models';
import {
	IdNameModel,
	Level,
	QuestionDetails,
	Report,
	ReportData,
	Settings,
	Subject,
	Test,
	UpdateOptions,
	ZoomOption,
} from '../models';
import {BehaviorSubject} from 'rxjs';
import {calculateMetrics, getReportDate, sortQuestionData} from '../utils';
import {ChartPagesReadyEvent} from '../events';
import {ExportService} from '../services/export-service';

export default class ItemAnalysisReportService extends BaseService {
	public sortOptionMode = new BehaviorSubject<string>('0');
	public level: Level = 'user';

	public classes: IdNameModel[] = [];
	public groups: IdNameModel[] = [];
	public specialistGroups: IdNameModel[] = [];
	public subjects: Subject[] = [];
	public reportData = new BehaviorSubject<ReportData>(null);
	public gradeLevels: Array<IdNameModel>;

	public schoolName: string = '';
	public districtName: string = '';
	public teacherName: string = '';

	public displayNotTestedAsIncorrect = new BehaviorSubject<boolean>(false);
	public testResultsCorrectVerbiage = new BehaviorSubject<string>('');
	public testResultsIncorrectVerbiage = new BehaviorSubject<string>('');

	public filteredGradeLevels = new BehaviorSubject<IdNameModel[]>([]);
	public selectedClass = new BehaviorSubject<IdNameModel>(null);
	public selectedGroup = new BehaviorSubject<IdNameModel>(null);
	public selectedSpecialistGroup = new BehaviorSubject<IdNameModel>(null);
	public selectedSubject = new BehaviorSubject<Subject>(null);
	public selectedTest = new BehaviorSubject<Test>(null);
	public selectedGradeLevel = new BehaviorSubject<IdNameModel>(null);
	public selectedQuestionLabel = new BehaviorSubject<string>('');
	public reportDate = new BehaviorSubject<string>('');
	public questionDetails = new BehaviorSubject<QuestionDetails>(null);
	public questionDetailsShow = new BehaviorSubject<boolean>(false);

	public selectedPoint = new BehaviorSubject<any>(null);
	public zoom = new BehaviorSubject<number>(1);
	public downloadingPdf = new BehaviorSubject<boolean>(false);
	public needRerender = new BehaviorSubject<boolean>(false);
	public questionWidth = 35;
	private controllerName = 'reports/item-analysis';
	private subjectID: number;
	private subjectType: SubjectType;
	private hierarchy: HierarchySnapshot;
	private readonly eventBus = new EventBusManager();

	constructor(private exportService: ExportService) {
		super();
		this.eventBus.subscribe(
			ChartPagesReadyEvent,
			() => this.startdownloadReportPdf(),
		);
	}

	public get data() {
		return this.reportData;
	}

	public get settings(): Settings {
		return {
			classID: this.selectedClass.value ? this.selectedClass.value.id : 0,
			groupID: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			subjectTabID: this.selectedSubject.value ? this.selectedSubject.value.id : 0,
			subjectTabType: SubjectType[this.selectedSubject.value.type],
		};
	}

	private get filterOptions(): UpdateOptions {
		return {
			classId: this.selectedClass.value ? this.selectedClass.value.id : 0,
			groupId: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			specialistGroupId: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			subjectId: this.selectedSubject.value ? this.selectedSubject.value.id : 0,
			subjectType: this.selectedSubject.value.type,
			testId: this.selectedTest.value.id,
			fullHierarchy: this.hierarchy,
		};
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	public changeDisplayNotTestedAsIncorrect(checked: boolean) {
		this.displayNotTestedAsIncorrect.next(checked);
		this.setQuestionDetailsShow(false);
	}

	public changeSelectedTest(id) {
		const test = this.selectedSubject.value?.tests.find(x => x.id === id);
		this.selectedTest.next(test);
		if (TestType[test.type] === TestType.YN) {
			const options: UpdateOptions = {...this.filterOptions, testId: test?.id};
			this.updateReport(options);
		}
	}

	public changeSelectedSubject(id) {
		const subject = this.subjects.find(x => x.id === id);
		const test = subject.tests[0];
		this.selectedSubject.next(subject);
		this.selectedTest.next(subject.tests[0]);
		if (TestType[test.type] === TestType.YN) {
			const options: UpdateOptions = {...this.filterOptions, subjectId: id, testId: test?.id};
			this.updateReport(options);
		}
	}

	public changeSelectedClass(id) {
		this.selectedClass.next(this.classes.find(x => x.id === id));
		const options: UpdateOptions = {...this.filterOptions, classId: id};

		this.updateReport(options);
	}

	public changeSelectedGradeLevels(id) {
		this.selectedGradeLevel.next(this.gradeLevels.find(x => x.id === id));
		this.updateReport(this.filterOptions);
	}

	public changeSelectedSpecialistGroup(id) {
		this.selectedSpecialistGroup.next(this.specialistGroups.find(x => x.id === id));
		const options: UpdateOptions = {...this.filterOptions, specialistGroupId: id};
		this.updateReport(options);
	}

	public changeSortMode(mode) {
		this.sortOptionMode.next(mode);
		this.setQuestionDetailsShow(false);
	}

	public resetZoom() {
		this.zoom.next(this.calcZoomToFit(1060));
	}

	public changeZoom(option: ZoomOption) {
		const step = 0.1;
		let zoom = this.zoom.value;
		switch (option) {
			case 'Zoom In': {
				zoom += step;
				break;
			}
			case 'Zoom Out': {
				zoom -= step;
				break;
			}
			case 'Zoom To Fit': {
				zoom = this.calcZoomToFit();
				break;
			}
			case '50%': {
				zoom = 0.5;
				break;
			}
			case '100%': {
				zoom = 1;
				break;
			}
			case '200%': {
				zoom = 2;
				break;
			}
		}

		if (zoom < 0.5) {
			zoom = 0.5;
		}
		if (zoom > 2) {
			zoom = 2;
		}
		this.zoom.next(zoom);
	}

	public changeSelectedGroup(id) {
		this.selectedGroup.next(this.groups.find(x => x.id === id));
		const options: UpdateOptions = {...this.filterOptions, groupId: id};
		this.updateReport(options);
	}

	public initModel(subjectID: number, subjectType: SubjectType, hierarchy: HierarchySnapshot) {
		this.subjectID = subjectID;
		this.subjectType = subjectType;
		this.hierarchy = hierarchy;

		if (hierarchy.mode === HierarchyMode.Specialist) {
			if (!hierarchy.specialist.userID) {
				this.level = 'district';
			}
		}

		if (hierarchy.mode === HierarchyMode.PreAssess) {
			if (!hierarchy.preAssess.userID) {
				this.level = 'district';
			}
		}

		if (hierarchy.mode === HierarchyMode.Classic) {
			if (!hierarchy.classic.teacherID) {
				if (hierarchy.classic.schoolID) {
					this.level = 'school';
				} else {
					this.level = 'district';
				}
			} else {
				if (hierarchy.classic.groupID) {
					this.level = 'group';
				}
			}
		}

		return this.initReport();
	}

	public calculateMetrics(data: ReportData) {
		calculateMetrics(data, this.gradeLevels, this.selectedGradeLevel.value);
		this.reportData.next(data);
	}

	public getFilteredStudents() {
		let students = [...this.reportData.value.students];
		if (this.gradeLevels && this.selectedGradeLevel.value.id > 0) {
			students = students.filter(x => x.gradeLevelID === this.selectedGradeLevel.value.id);
		}
		return students;
	}

	public updateGradeLevelData(data: ReportData) {
		if (!this.gradeLevels) {
			return;
		}

		const studentGradeLevels = data.students.map(x => x.gradeLevelID).filter((v, i, a) => a.indexOf(v) === i);
		let gradeLevels = [...this.gradeLevels];
		gradeLevels = gradeLevels.filter(x => studentGradeLevels.indexOf(x.id) !== -1);
		gradeLevels.unshift({id: 0, name: 'All'});

		this.filteredGradeLevels.next(gradeLevels);
		if (this.selectedGradeLevel.value === null) {
			this.selectedGradeLevel.next(gradeLevels[0]);
		} else if (this.selectedGradeLevel.value?.id === 0) {
			this.selectedGradeLevel.next(gradeLevels[0]);
		}
	}

	public calcZoomToFit(width: number = 0) {
		if (width === 0) {
			width = window.innerWidth;
		}
		let zoom = (width * 0.98 - 110) / (this.questionWidth * this.reportData.value.questions.length);
		if (zoom < 0.5) {
			zoom = 0.5;
		}
		if (zoom > 2) {
			zoom = 2;
		}

		return zoom;
	}

	selectPoint = ({point}) => {
		const selectedPoint = this.selectedPoint.value;
		if (selectedPoint != null && selectedPoint.index === point.index) {
			this.selectChartPoint(point);
			return;
		}

		const questionsData = sortQuestionData(this.reportData.value.questions.slice(), this.sortOptionMode.value);
		const question = questionsData[point.index];
		const questionId = question.questionID;
		this.selectedQuestionLabel.next(question.label);
		this.reportDate.next(getReportDate());

		this.questionDetails.next(this.getQuestionDetails(questionId));
		this.setQuestionDetailsShow(true);
		this.selectChartPoint(point);
	};

	public setQuestionDetailsShow(show: boolean) {
		this.questionDetailsShow.next(show);
		const selectedPoint = this.selectedPoint.value;
		if (!show && selectedPoint) {
			const {index, series} = selectedPoint;
			series.data[index].update({color: series.color});
			this.setSelectedPoint(null);
		}
	}

	public setNeedRerender(value: boolean) {
		this.needRerender.next(value);
	}

	public setSelectedPoint(point: any) {
		if (point) {
			const {index, series} = point;
			this.selectedPoint.next({index, series});
		} else {
			this.selectedPoint.next(point);
		}
	}

	public selectChartPoint(point: any) {
		const selectedPoint = this.selectedPoint.value;
		if (selectedPoint) {
			const {index, series} = selectedPoint;
			series.data[index].update({
				color: series.color,
				states: {hover: {color: series.options.states.hover.color}},
			});
		}

		const color = point.series.options.states.hover.color;
		point.update({color, states: {hover: {color}}});

		this.setSelectedPoint(point);
	}

	public getQuestionDetails(questionId: number) {
		const filteredStudents = this.getFilteredStudents();
		const studentIDs = filteredStudents.map(x => x.studentID);
		const question = this.reportData.value.questions.find(x => x.questionID === questionId);
		const answers = question.answers.filter(x => studentIDs.indexOf(x.studentID) !== -1);
		const correctAnswers = answers.filter(x => x.answerState === 1).map(x => x.studentID);
		const incorrectAnswers = answers.filter(x => x.answerState === 0).map(x => x.studentID);

		const details: QuestionDetails = {
			studentsCorrect: filteredStudents.filter(x => correctAnswers.includes(x.studentID)).map(x => x.name),
			studentsIncorrect: filteredStudents.filter(x => incorrectAnswers.includes(x.studentID)).map(x => x.name),
			studentsNotTested: filteredStudents.filter(x => !correctAnswers.includes(x.studentID) && !incorrectAnswers.includes(x.studentID)).map(x => x.name),
		};

		return details;
	}

	public getFullChartWidth() {
		return Math.max(this.zoom.value * this.questionWidth * this.reportData.value.questions.length + 70, 340);
	}

	public init(subjectID: number, subjectType: SubjectType, hierarchy: HierarchySnapshot) {
		return this.httpClient.ESGIApi.get<Report>(this.controllerName, 'init', {
			subjectID: subjectID,
			subjectType: subjectType,
			fullHierarchy: hierarchy,
		}).toPromise();
	}

	public update(options: UpdateOptions) {
		return this.httpClient.ESGIApi.get<Report>(this.controllerName, 'update', options).toPromise();
	}

	public downloadReportPdf() {
		this.downloadingPdf.next(true);
	}

	public downloadReportExcel() {
		console.log('IAR: downloadReportExcel called.');
		const hRoot = document.querySelector('#iar-chart .highcharts-root');
		if (!hRoot) {
			dispatchAppEvent(ReportErrorEvent, {message: `Uh oh. Something seems to be wrong on our end with export. Click Report to contact Customer Support (support@esgisoftware.com) for assistance.`});
			console.error('Unable to get hRoot');
			return;
		}

		this.downloadExcel(hRoot.outerHTML, this.getFullChartWidth(), 450);
	}

	public downloadDetailsPdf(html: string) {
		this.exportService.downloadDetailsPDF(html);
	}

	//console calls added to research by ESGI-23740
	public downloadExcel(svg, width, height) {
		console.log('IAR: Generate imgsrc.');
		const imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)));
		let canvas = document.querySelector('#iarCanvas') as any;
		if (!canvas) {
			console.log('IAR: Create canvas.');
			const canvasElem = document.createElement('canvas');
			canvasElem.id = 'iarCanvas';
			document.body.appendChild(canvasElem);
		}
		canvas = document.querySelector('#iarCanvas') as any;
		const context = canvas.getContext('2d');
		canvas.setAttribute('width', width);
		canvas.setAttribute('height', height);

		console.log('IAR: Create img.');
		const image = new Image;
		image.src = imgsrc;
		image.onload = () => {
			console.log('IAR: Img onLoad clb executed.');
			context.drawImage(image, 0, 0);
			const canvasdata = canvas.toDataURL('image/png');
			this.exportService.downloadExcel(
				canvasdata.substring(22),
				this.selectedTest.value.id,
				this.selectedClass.value ? this.selectedClass.value.id : 0,
				this.selectedGroup.value ? this.selectedGroup.value.id : 0,
				this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
				false,
				this.selectedSubject.value.id,
				this.selectedSubject.value.type,
				this.sortOptionMode.value,
				this.gradeLevels ? this.selectedGradeLevel.value.id : 0,
				this.hierarchy,
			);
			canvas.parentNode.removeChild(canvas);
		};
	}

	public getPages() {
		return this.exportService.pages.value;
	}

	public setPages(pages) {
		this.exportService.pages.next(pages);
	}

	private async initReport() {
		return this.init(this.subjectID, this.subjectType, this.hierarchy).then((data: Report) => {
			const filter = data.reportFilter;
			this.districtName = filter.districtName;
			this.schoolName = filter.schoolName;
			this.teacherName = filter.teacherName;

			if (filter.classes) {
				this.classes = filter.classes.items;
				if (this.classes.length > 1) {
					this.classes.unshift({id: 0, name: 'All'});
				}
				if (filter.classes.selectedID) {
					this.selectedClass.next(filter.classes.items.find(s => s.id === filter.classes.selectedID));
				} else {
					this.selectedClass.next({id: 0, name: 'All'});
				}
			}

			if (filter.groups) {
				this.groups = filter.groups.items;
				if (filter.groups.selectedID) {
					this.selectedGroup.next(filter.groups.items.find(s => s.id === filter.groups.selectedID));
				}
			}

			if (filter.specialistGroups) {
				this.specialistGroups = filter.specialistGroups.items;
				if (filter.specialistGroups.selectedID) {
					this.selectedSpecialistGroup.next(filter.specialistGroups.items.find(s => s.id === filter.specialistGroups.selectedID));
				}
			}

			if (filter.subjects) {
				this.subjects = filter.subjects.items;
				const selectedSubject = filter.subjects.items.find(s => s.id === filter.subjects.selectedID);
				this.selectedSubject.next(selectedSubject);
				this.selectedTest.next(selectedSubject.tests[0]);
			} else {
				this.subjects = [];
			}

			this.displayNotTestedAsIncorrect.next(filter.displayNotTestedAsIncorrect);

			this.testResultsCorrectVerbiage.next(data.testResultsCorrectVerbiage);
			this.testResultsIncorrectVerbiage.next(data.testResultsIncorrectVerbiage);

			if (filter.gradeLevels) {
				this.gradeLevels = filter.gradeLevels;
				this.updateGradeLevelData(data.reportData);
			}
			this.calculateMetrics(data.reportData);
		});
	}

	private updateReport(options: UpdateOptions) {
		this.setQuestionDetailsShow(false);
		this.update(options).then((data: Report) => {
			this.calculateMetrics(data.reportData);
			this.updateGradeLevelData(data.reportData);
		});
	}

	private startdownloadReportPdf() {
		if (!this.exportService.pages.value.length) {
			return;
		}
		this.exportService.downloadReportPDF(
			this.selectedSpecialistGroup.value,
			this.level,
			this.schoolName,
			this.districtName,
			this.teacherName,
			this.selectedClass.value,
			this.selectedGroup.value,
			this.selectedSubject.value,
			this.selectedTest.value,
		).add(() => this.downloadingPdf.next(false));
	}
}
