import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Divider, Title, TitleWrapper, Wrapper} from './index.styled';
import {BaseComponentProps} from '@esgi/ui';

type RightPanelContentBlockProps = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'title'> & {
		title: string;
		titleBold?: boolean;
		titleAlign?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start';
		withDivider?: boolean;
	};

export const RightPanelContentBlock = forwardRef<HTMLDivElement, RightPanelContentBlockProps>(
	(
		{
			dataCy = 'features-right-panel-content-block',
			css = {},
			title,
			titleBold,
			titleAlign,
			withDivider = true,
			children,
			...props
		},
		forwardedRef,
	) => {
		return (
			<Wrapper dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				<TitleWrapper>
					<Title size='small' font='mono' bold={Boolean(titleBold)} color='lowContrast' css={{textAlign: titleAlign}}>
						{title}
					</Title>
					{withDivider && <Divider />}
				</TitleWrapper>
				{children}
			</Wrapper>
		);
	},
);
