import React from 'react';
import {Teacher} from './components/teacher';
import {Group} from './components/group';
import {Student} from './components/student';
import {Subject} from './components/subject';
import {ShowBlock} from './components/show-block';
import {ResultsBlock} from './components/results-block';
import styles from './styles.module.less';
import {
	useReportSettings,
} from 'packages/app/src/modules/reports/parent-letter/settings-form-modal/components/settings-form/components/modal-settings/hooks/use-report-settings';
import {SubjectType} from '@esgi/core/enums';
import FormService from '../../../../../services/form-service';
import ParentLetterEditor from '../../../../../parent-letter-editor/parent-letter-editor';

interface Props {
	service: FormService
}

export function ReportSettings({service}: Props) {
	const modalSettings = useReportSettings(service);

	return <div className={styles.plSettingsHeader}>
		{modalSettings.showPLE &&
			<ParentLetterEditor hierarchy={service.hierarchy} userID={modalSettings.model.user.userId}
			                    onClosed={modalSettings.closePLE}
			                    subjectType={SubjectType[modalSettings.selectedSubject.subjectType]}/>}
		<div className={styles.firstRow}>
			<Teacher teacher={modalSettings.teacher} groupType={modalSettings.groupType}/>
			<Group onChange={modalSettings.onGroupChange} groupType={modalSettings.groupType}
			       groupItems={modalSettings.groupItems}
			       selectedGroupItem={modalSettings.selectedGroupItem}/>
			<Student onChange={modalSettings.onStudentChange} selectedStudent={modalSettings.selectedStudent}
			         students={modalSettings.students}/>
			<Subject onChange={modalSettings.onSubjectChange} subjects={modalSettings.subjects}
			         selectedSubject={modalSettings.selectedSubject}/>
		</div>
		<div className={styles.secondRow}>
			{modalSettings.teacher && <>
				<ShowBlock teacher={modalSettings.teacher}
				           includeSummaryNotesOption={modalSettings.includeSummaryNotesOption}
				           includeMessageOption={modalSettings.includeMessageOption}
				           includeQuestionNotesOption={modalSettings.includeQuestionNotesOption}
				           includeGradeOption={modalSettings.includeGradeOption}
				           printInColorOption={modalSettings.printInColorOption}
				           hasGradeScales={modalSettings.hasGradeScales}
				           onIncludeSummaryChange={modalSettings.onIncludeSummaryChange}
				           onIncludeQuestionChange={modalSettings.onIncludeQuestionChange}
				           onPrintInColorChange={modalSettings.onPrintInColorChange}
				           onIncludeMessageChange={modalSettings.onIncludeMessageChange}
				           viewMessageClicked={modalSettings.viewMessageClicked}
				/>
				<ResultsBlock teacher={modalSettings.teacher} includeSessionOption={modalSettings.includeSessionOption}
				              includeGradeOption={modalSettings.includeGradeOption}
				              onIncludeGradeOptionChange={modalSettings.onIncludeGradeOptionChange}
				              onIncludeUntestedQuestionChange={modalSettings.onIncludeUntestedQuestionChange}
				              onShowSessionDateChange={modalSettings.onShowSessionDateChange}
				              includeSessionClicked={modalSettings.includeSessionClicked}
				              showSessionDateOption={modalSettings.showSessionDateOption}
				              includeUntestedQuestionsOption={modalSettings.includeUntestedQuestionsOption}
				              hasGradeScales={modalSettings.hasGradeScales}
				/>
			</>}
		</div>
	</div>;
}
