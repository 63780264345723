import {SortModel} from './models';

export class Comparator {
	sortModel: SortModel;
	additionalSortModels: Array<SortModel>;

	constructor(sortModel: SortModel, additionalSortModels: Array<SortModel>) {
		this.sortModel = sortModel;
		this.additionalSortModels = additionalSortModels;
	}

	stringCompare(left, right) {
		let l = left;
		let r = right;
		if (this.sortModel.direction === 'Desc') {
			r = left;
			l = right;
		}
		r = r != null ? r.trim() : r;
		l = l != null ? l.trim() : l;

		if (l > r) {
			return 1;
		}
		if (l < r) {
			return -1;
		}
		return 0;

	}

	isDate(val) {
		let d = new Date(val);
		return !isNaN(d.valueOf());
	}

	dateCompare(left: Date, right: Date) {
		if (this.sortModel.direction === 'Asc') {
			return left.getTime() - right.getTime();
		}
		if (this.sortModel.direction === 'Desc') {
			return right.getTime() - left.getTime();
		}
		return 0;
	}

	intCompare(left, right) {
		if (this.sortModel.direction === 'Asc') {
			return left - right;
		}
		if (this.sortModel.direction === 'Desc') {
			return right - left;
		}
		return 0;
	}

	comparer(left, right) {
		if (this.sortModel.direction === 'None') {
			return 0;
		}
		let leftValue = left[this.sortModel.fieldName];
		let rightValue = right[this.sortModel.fieldName];

		let value;
		if ($.isNumeric(leftValue) && $.isNumeric(rightValue)) {
			value = this.intCompare(leftValue, rightValue);
		} else if (this.isDate(leftValue) && this.isDate(rightValue)) {
			value = this.dateCompare(new Date(leftValue), new Date(rightValue));
		} else {
			value = this.stringCompare(leftValue, rightValue);
		}

		if (this.additionalSortModels != null && this.additionalSortModels.length && value === 0) {
			for (let i = 0; i < this.additionalSortModels.length; i++) {
				let comparator = new Comparator(this.additionalSortModels[i], []);
				value = comparator.comparer(left, right);
				if (value !== 0) {
					break;
				}
			}
		}
		return value;
	}

}