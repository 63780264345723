import {useEffect, useMemo, useState} from 'react';
import {Student} from './types';

export function useSelectedIdsChanged({
	initialSelectedStudentIds,
	selectedStudentIds,
}: {
	initialSelectedStudentIds?: Student['id'][] | undefined | null;
	selectedStudentIds: Student['id'][];
}) {
	const [sortedInitialSelectedStudentIds, setSortedInitialSelectedStudentIds] = useState<Student['id'][]>();

	useEffect(() => {
		if (initialSelectedStudentIds) {
			const copiedValue = [...initialSelectedStudentIds];

			setSortedInitialSelectedStudentIds(copiedValue.sort((a, b) => a - b));
		}
	}, [initialSelectedStudentIds]);

	return useMemo(() => {
		if (!sortedInitialSelectedStudentIds) {
			return false;
		}

		if (selectedStudentIds.length !== sortedInitialSelectedStudentIds.length) {
			return true;
		}

		return !sortedInitialSelectedStudentIds.every((id) => selectedStudentIds.includes(id));
	}, [sortedInitialSelectedStudentIds, selectedStudentIds]);
}
