import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
	onClick?: () => void;
}

export function MenuIcon({className, onClick}: Props) {
	return <svg data-cy='menu-icon' className={join(className)} onClick={onClick} xmlns='http://www.w3.org/2000/svg'
				width='7' height='21' viewBox='0 0 7 21'
				fill='none'>
		<path
			d='M3.48366 5.256C4.91346 5.256 6.08329 4.08617 6.08329 2.65637C6.08329 1.22657
			4.91346 0.0567398 3.48366 0.0567398C2.05387 0.0567398 0.884033 1.22657 0.884033
			2.65637C0.884033 4.08617 2.05387 5.256 3.48366 5.256ZM3.48366 7.85563C2.05387
			7.85563 0.884033 9.02546 0.884033 10.4553C0.884033 11.8851 2.05387 13.0549 3.48366
			13.0549C4.91346 13.0549 6.08329 11.8851 6.08329 10.4553C6.08329 9.02546 4.91346
			7.85563 3.48366 7.85563ZM3.48366 15.6545C2.05387 15.6545 0.884033 16.8244 0.884033
			18.2542C0.884033 19.6839 2.05387 20.8538 3.48366 20.8538C4.91346 20.8538 6.08329
			19.6839 6.08329 18.2542C6.08329 16.8244 4.91346 15.6545 3.48366 15.6545Z'
			fill='#FAFAFA'/>
	</svg>;
}
