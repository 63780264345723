import {FormField, observableArray, Validators} from '@esgi/deprecated/knockout';
import {Dictionaries, TimeZoneModel} from '../../server';
import {FieldContainer} from '../../common/component';

export class TimeZoneSelectComponent extends FieldContainer<TimeZoneModel> {
	@observableArray()
	private timezones: Array<TimeZoneModel>;

	buildField(initValue?): FormField<TimeZoneModel> {
		this.timezones = [];
		const f = this.createField<TimeZoneModel>(undefined, Validators.Required());
		f.afterRender(this.rootElement).done(() => {
			this.loadTimezones(initValue);
		});
		return f;
	}

	loadTimezones(initValue?) {
		return Dictionaries.loadTimeZones().done((r) => {
			this.timezones = r.timeZones;
			if (initValue) {
				const tz = r.timeZones.find((e) => e.id === initValue);
				this.field.value(tz);
			} else {
				this.field.value(r.timeZones[0]);
			}
		});
	}

	public template = () => {
		return <div data-bind='var : {root: $data}, afterRender: true'>
			<div data-bind='with: field'>
				<div className='form-group state'
				     data-bind="css: {'has-success' : showSuccessValidation, 'has-feedback' : showValidation, 'has-error' : showErrorValidation}, afterRender: true">
					<select id='timezone' name='timezone' className='form-control'
					        data-bind="options: root.timezones, valueAllowUnset: true, optionsText: 'displayName',value: value"/>
					<div className='error-message visible-xs visible-sm hidden-md'>
						<span data-bind='text:validation.validationResults'/>
					</div>
				</div>
			</div>
		</div>;
	};
}
