import React from 'react';
import {ReportType, SubjectType} from '@esgi/core/enums';
import {lazyClass} from '@esgi/core/react';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {SsoTracker} from '@esgi/core/tracker';
import {NoStudentProtectedButton} from 'pages/home/components/report-button/buttons/no-students-protected-button';
import TrackConfirmModal from 'modules/track-confirm';
import {ITrackModel} from 'pages/home/components/school-year-selector/api';
import {UserType} from '@esgi/core/authentication';

const totalReportModal = lazyClass(() => import('shared/modules/reports/total-report/total-report-modal'));

class Props {
	hierarchy: HierarchyInstance;
	applyHomepageChanges: (changes: ChangesCollector) => void;
	subjectId: number;
	subjectType: SubjectType;
	globalSchoolYearId: number;
	autoTrack: boolean;
	noStudents: boolean;
	trackChanged: (track: ITrackModel) => void;
}

class State {
	showTrackConfirmModal: boolean = false;
}

export default class TotalReportButton extends React.PureComponent<Props, State> {
	state = new State();

	public render() {
		return <>
			<NoStudentProtectedButton title={this.title}
																icon={this.icon()}
																hoverTitle={this.hoverTitle}
																onClicked={() => this.clickHandler()}
																noStudents={this.props.noStudents}
																linkClassName='total-report-link'/>
			{this.state.showTrackConfirmModal && <TrackConfirmModal
				confirmed={() => this.onConfirm()}
				canceled={() => this.setState({showTrackConfirmModal: false})}
				trackChanged={this.props.trackChanged}
			/>}
		</>;
	}

	icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='15' height='15'
								viewBox='0 0 15 15'
								fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
						d='M1.5625 0C0.699558 0 0 0.699555 0 1.5625V2.8125H15V1.5625C15 0.699555 14.3004 0 13.4375 0H1.5625ZM4.375 3.75H0V6.875H4.375V3.75ZM9.6875 3.75H5.3125V6.875H9.6875V3.75ZM10.625 3.75H15V6.875H10.625V3.75ZM0 7.8125H4.375V11H0V7.8125ZM9.6875 7.8125H5.3125V11H9.6875V7.8125ZM10.625 7.8125H15V11H10.625V7.8125ZM4.375 12H0V12.9688C0 14.0906 0.909424 15 2.03125 15H4.375V12ZM5.3125 12H9.6875V15H5.3125V12ZM15 12H10.625V15H12.9688C14.0906 15 15 14.0906 15 12.9688V12Z'
						fill='#0088CC'/>
		</svg>;
	};

	get title(): string {
		return `${this.getPrefix()} Totals`;
	}

	private getPrefix() {
		const h = this.props.hierarchy;
		if (h.mode === HierarchyMode.Classic) {
			const selected = h.classic.selected;
			switch (selected.level) {
				case ClassicHierarchyLevel.Class:
					return 'Class';
				case ClassicHierarchyLevel.Group:
					return 'Group';
				case ClassicHierarchyLevel.Teacher:
					return ''; //not supported
				case ClassicHierarchyLevel.School:
					return 'School';
				case ClassicHierarchyLevel.District:
					return 'District';
        case ClassicHierarchyLevel.SchoolsGroup:
          return 'School Group';
        case ClassicHierarchyLevel.TeachersGroup:
          return 'Teacher Group';
				case ClassicHierarchyLevel.Student:
					return h.classic.groupID > 0 ? 'Group' : 'Class';
			}
		}

		if (h.mode === HierarchyMode.Specialist) {
			const s = h.specialist;
      const selected = h.specialist.selected;

      if (s.userID === 0) {
        if(selected.level === SpecialistHierarchyLevel.GroupOfSpecialists){
          return 'Specialist Group'
        }
				if (s.type === UserType.ISS && s.filter.schoolID > 0) {
					return 'School';
				} else {
					return 'District';
				}
			} else {
				return 'Group';
			}
		}

		if (h.mode === HierarchyMode.PreAssess) {
			const pa = h.preAssess;

			if (pa.userID === 0) {
				return 'District';
			} else {
				return 'Group';
			}
		}

		return '';
	}

	get hoverTitle(): string {
		const prefix = this.getPrefix();
		switch (prefix) {
			case 'Class':
			case 'Group':
				return 'Review and compare student scores for a subject tab';
			case 'School':
				return 'Review and compare class scores for a subject tab';
			case 'District':
				return 'Review and compare school scores for a subject tab';
			default:
				return '';
		}
	}

	clickHandler = async () => {
		if (!this.props.autoTrack) {
			this.runReport();
		} else {
			this.setState({showTrackConfirmModal: true});
		}
	};

	onConfirm = () => {
		this.setState({showTrackConfirmModal: false});
		this.runReport();
	};

	private build(): { type: ReportType, id: number } {
		const h = this.props.hierarchy;
		if (h.mode === HierarchyMode.Classic) {
			const selected = h.classic.selected;
			switch (selected.level) {
				case ClassicHierarchyLevel.Class:
					return {type: ReportType.Class, id: selected.levelID};
				case ClassicHierarchyLevel.Student:
					if (h.classic.groupID) {
						return {type: ReportType.Group, id: h.classic.groupID};
					} else {
						return {type: ReportType.Class, id: h.classic.classID};
					}
				case ClassicHierarchyLevel.Group:
					return {type: ReportType.Group, id: selected.levelID};
				case ClassicHierarchyLevel.Teacher:
					return {type: ReportType.Teacher, id: selected.levelID};
				case ClassicHierarchyLevel.School:
					return {type: ReportType.School, id: selected.levelID};
				case ClassicHierarchyLevel.District:
					return {type: ReportType.District, id: selected.levelID};
        case ClassicHierarchyLevel.SchoolsGroup:
          return {type: ReportType.SchoolsGroup, id: selected.levelID};
        case ClassicHierarchyLevel.TeachersGroup:
          return {type: ReportType.TeachersGroup, id: selected.levelID};
			}
		}

		if (h.mode === HierarchyMode.Specialist) {
			const s = h.specialist;
			if (s.userID === 0) {
				if (s.type === UserType.ISS && s.filter.schoolID > 0) {
					return {type: ReportType.SchoolSpecialist, id: s.filter.schoolID};
				} else if(s.groupOfSpecialistsID != 0) {
          return {type: ReportType.GroupOfSpecialists, id: s.groupOfSpecialistsID};
        } else {
					return {type: ReportType.DistrictSpecialist, id: s.districtID};
				}
			} else {
				return {type: ReportType.SpecialistGroup, id: s.groupID};
			}
		}

		if (h.mode === HierarchyMode.PreAssess) {
			const pa = h.preAssess;
			if (pa.userID === 0) {
				return {type: ReportType.DistrictPreassess, id: pa.districtID};
			} else {
				return {type: ReportType.SpecialistGroup, id: pa.groupID};
			}
		}

		return {type: ReportType.None, id: 0};
	}

	runReport = () => {
		SsoTracker.trackEvent({
			trackingEvent: this.getPrefix() + 'TotalsReport',
		});

		const type = this.build();
		totalReportModal.load().then(trm => {
			const changesCollector = new ChangesCollector({
				id: this.props.subjectId,
				type: this.props.subjectType,
			}, this.props.hierarchy.snapshot);
			const ctr = new trm({
				globalSchoolYearId: this.props.globalSchoolYearId,
				reportId: type.id,
				subjectId: this.props.subjectId,
				reportType: type.type,
				subjectType: this.props.subjectType,
			}, changesCollector, this.props.hierarchy.snapshot);

			ctr.load();

			ctr.events.onClosed(() => this.props.applyHomepageChanges(changesCollector));
		});
	};

}
