const addZero = (value: number) => {
	if(value < 10) {
		return `0${value}`;
	}
	return value.toString();
};

export const months = new Array(12).fill(0).map((_, index) => ({label: addZero(index + 1), value: index + 1}));
export const days = new Array(31).fill(0).map((_, index) => ({label: addZero(index + 1), value: index + 1}));
export const years = new Array(new Date().getFullYear() - 1900).fill(0).map((_, index) => {
	const year = (new Date().getFullYear() + 1) - (index + 1);
	return {label: year.toString(), value: year};
});
