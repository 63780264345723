import React from 'react';
import DataService from './services/data-service';
import {SubjectModel} from './models';
import TeacherViewModal from './components/teacher-view-modal/teacher-view-modal';
import StudentViewModal from './components/student-view-modal/student-view-modal';
import {Subject} from 'rxjs';
import {takeUntil, withLatestFrom, switchMap, filter} from 'rxjs/operators';
import RelaunchTestSessionModal from './components/relaunch-test-session-modal/relaunch-test-session-modal';
import FinishTestSessionModal from './components/finish-test-session-modal/finish-test-session-modal';


class State {
	showTeacherViewModal: boolean = false;
	showStudentViewModal: boolean = false;
	showRelaunchTestSessionModal: boolean = false;
	showFinishTestSessionModal: boolean = false;
	showFinishTestSessionModalLoader: boolean = true;
}

interface Props {
	closed: () => any;
	subject: SubjectModel;
	testID?: number;
	testSessionEnded: () => any;
	studentID: number;
}

class SelfAssessment extends React.PureComponent<Props, State> {
	public readonly state = new State();

	private readonly dataService: DataService = new DataService(this.props.subject, this.props.testID);
	private readonly destroy$: Subject<void> = new Subject();

	componentDidMount() {
		this.dataService.initTestSession$(this.props.studentID)
			.pipe(takeUntil(this.destroy$))
			.subscribe(testFromCacheAvailable => this.onInitTestSession(testFromCacheAvailable));

		this.dataService.finishTest$
			.pipe(
				takeUntil(this.destroy$),
				switchMap(_ => this.dataService.testFinish$()),
				withLatestFrom(this.dataService.finishTest$),
				filter(([_, isLastTest]) => isLastTest),
				switchMap(_ => this.dataService.endTestSession$()),
			)
			.subscribe(() => this.setState({showFinishTestSessionModalLoader: false}));
	}

	componentWillUnmount(): void {
		this.destroy$.next();	
		this.dataService.destroy();
	}

	private finishTestSession(): void {
		this.setState({showFinishTestSessionModal: false});
		this.props.testSessionEnded();
		this.props.closed();
	}

	private onInitTestSession(testFromCacheAvailable: boolean): void {
		testFromCacheAvailable ? this.setState({showRelaunchTestSessionModal: true}) : this.setState({showTeacherViewModal: true});
	}

	private relaunchTestSessionRefused(): void {
		this.setState({showRelaunchTestSessionModal: false, showTeacherViewModal: true});
		this.dataService.endTestSession$().subscribe();
	}

	private relaunchTestSessionAccepted(): void {
		this.dataService.relaunchTestSession$()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.setState({showRelaunchTestSessionModal: false, showStudentViewModal: true});
			});
	}

	private studentViewModalClosed(): void {
		this.props.testSessionEnded();
		this.props.closed();
	}

	render() {
		if (this.state.showTeacherViewModal) {
			return <TeacherViewModal
				dataService={this.dataService}
				closed={() => this.setState({showTeacherViewModal: false}, () => this.props.closed())}
				startSessionClicked={() => this.setState({showStudentViewModal: true, showTeacherViewModal: false})}
			/>;
		}

		if (this.state.showStudentViewModal) {
			return <StudentViewModal
				dataService={this.dataService}
				closed={() => this.setState({showStudentViewModal: false}, () => this.studentViewModalClosed())}
				testSessionEnded={() => this.setState({showStudentViewModal: false, showFinishTestSessionModal: true})}
			/>;
		}

		if (this.state.showFinishTestSessionModal) {
			return <FinishTestSessionModal
				showLoader={this.state.showFinishTestSessionModalLoader}
				studentName={this.dataService.selectedStudent.name}
				closed={() => this.finishTestSession()}
			/>;
		}

		if (this.state.showRelaunchTestSessionModal) {
			return <RelaunchTestSessionModal
				yesClicked={() => this.relaunchTestSessionAccepted()}
				noClicked={() => this.relaunchTestSessionRefused()}
				cancelClicked={() => this.props.closed()}
				name={this.dataService.selectedStudent?.name}
			/>;
		}

		return null;
	}
}

export default SelfAssessment;
