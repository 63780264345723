import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Textarea} from '@esgi/ui/controls';
import {Alert} from '@esgi/ui/alert';
import {useMemo} from 'react';
import {Button} from '@esgi/ui';
import moment from 'moment/moment';

export * from './no-score';

export const Container = styled(Box, {
	minWidth: 468,
});


export const Footer = styled(FlexBox, {
	width: '100%',
	justifyContent: 'space-between',
});
export const TestContainer = styled(FlexBox, {
	height: 60,
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingBottom: '12px',
	paddingTop: '12px',
	userSelect: 'none',
});

export const TestName = styled(FlexBox, {
	alignItems: 'center',
	height: '100%',
});

export const TextName = styled(Text, {
	paddingRight: '12px',
	paddingLeft: '12px',
});

export const TestType = styled(Text, {
	border: '1px solid $mild',
	borderRadius: '12px',
	background: '$background',
	padding: '6px 12px',
	color: '$neutral40',
	height: 24,
});

export const ClassContainer = styled(FlexBox, {
	marginTop: '20px',
	marginBottom: '2px',
	borderRadius: '6px 6px 0px 0px',
	padding: '12px',
	backgroundColor: '$background',
	width: '100%',
	height: 40,
	alignItems: 'center',
	justifyContent: 'space-between',
});

export const StudentContainer = styled(FlexBox, {
	marginTop: '2px',
	marginBottom: '2px',
	padding: '12px',
	backgroundColor: '$background',
	width: '100%',
	height: 40,
	alignItems: 'center',
	justifyContent: 'space-between',
});

export const SessionContainer = styled(FlexBox, {
	marginTop: '2px',
	marginBottom: '20px',
	borderRadius: '0px 0px 6px 6px',
	padding: '12px',
	backgroundColor: '$background',
	width: '100%',
	height: 40,
	alignItems: 'center',
	justifyContent: 'space-between',
});

export const SummaryNoteArea = styled(Textarea, {
	width: '100%',
});

export const SessionInfoContainer = styled(Box, {
	padding: '12px 0',
});

export const Divider = styled(Box, {
	background: '$mild',
	width: '100%',
	height: '1px',
	marginTop: 8,
	marginBottom: 8,
});

export const Time = styled(Text, {
	display: 'flex',
	alignItems: 'center',
	borderRadius: '2px 0px 0px 2px',
	color: '$neutral40',
	border: '1px solid $muted',
	borderRight: 'none',
	padding: '0 4px',
	marginLeft: '8px',
	height: 16,
});

export const AmPm = styled(Text, {
	display: 'flex',
	alignItems: 'center',
	borderRadius: '0px 2px 2px 0px',
	color: '$vivid',
	backgroundColor: '$neutral72',
	padding: '2px 4px',
	height: 16,
});

export const DateText = styled(Text, {
	display: 'flex',
	alignItems: 'center',
});

export const AlertFooter = styled(Alert.Footer, {
	display: 'flex !important',
});

export const ProgressBarContainer = styled(Box, {
	marginTop: 16,
	marginBottom: 12,
});

export const StyledButton = styled(Button, {
	variants: {
		showCriteria: {
			true: {
				'& > svg': {
					transform: 'rotate(90deg)',
				},
			},
			false: {
				'& > svg': {
					transform: 'rotate(-90deg)',
				},
			},
		},
	},
});

export function Timestamp(props: {date: Date}) {
	const {date, time, ampm} = useMemo(() => {
		const date = props.date;
		return {
			date: moment(date).format('MM/DD/YYYY'),
			time: moment(date).format('hh:mm'),
			ampm: date.getHours() >= 12 ? 'PM' : 'AM',
		};
	}, [props.date]);

	return <FlexBox align='center'>
		<DateText size='small' bold>{date}</DateText>
		<Time size='small'>{time}</Time>
		<AmPm size='small'>{ampm}</AmPm>
	</FlexBox>;
}