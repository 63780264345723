import {Button as ButtonUI} from '@esgi/ui';
import {CounterBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Counter = styled(CounterBox, {
	justifySelf: 'end',
});

export const Button = styled(ButtonUI, {
	minHeight: 40,
	paddingTop: 6,
	paddingRight: 12,
	paddingBottom: 6,
	paddingLeft: 10,
	display: 'grid',
	alignItems: 'center',
	justifyContent: 'start',
	gap: 10,
	gridTemplateColumns: 'auto auto 1fr',
	color: '$mediumContrast',

	'& > svg:first-child': {
		margin: 'unset',
		width: 16,
		height: 16,
	},

	'& > svg, & > svg *': {
		fill: 'unset',
	},

	'&:disabled': {
		backgroundColor: '$background',
		borderColor: '$mild',
		color: '$muted',

		'& svg': {
			fill: '$border',

			'& rect': {
				fill: '$border',
			},

			'& path': {
				fill: '$muted',
			},
		},

		[`& ${Counter}`]: {
			borderColor: '$mild',
			backgroundColor: '$secondarySurface',

			[`& > ${Text}`]: {
				color: '$muted',
			},
		},
	},

	variants: {
		isActive: {
			true: {
				'&:not(:hover):not(:disabled)': {
					color: '$primary',
					borderColor: '$primaryMuted',
					background: 'linear-gradient(272deg, #F3F9FC 48.17%, #F7FBFD 100%)',
				},
			},
		},
	},
});
