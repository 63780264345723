import {GridBox} from '@esgi/ui/layout';

import {formatTimes} from '../utils';
import {Delta, NumberRow} from '../../kit';
import {useStudentsData} from '../../hooks';
import {TimePerQuestionService} from '../service';

type Props = {
	testIDs: number[],
	studentID: number,
	service: TimePerQuestionService,
};

export function StudentView(props: Props) {
	const {loaded, data} = useStudentsData(props.service, props.studentID, props.testIDs, undefined);

	const {lastWeekAverage, allTimeAverage, delta, deltaType} = formatTimes(data);

	return <GridBox css={{height: 80}} gapY='3' flow='row'>
		<NumberRow text={lastWeekAverage} label='This Week Average' skeleton={!loaded}>
			<Delta type={deltaType} skeleton={!loaded} value={delta} unit=''>
				Since Last Week
			</Delta>
		</NumberRow>
		<NumberRow text={allTimeAverage} label='All-time Average' skeleton={!loaded}/>
	</GridBox>;
}