import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const rightPanelPaddingInline = 20;

export const RootBox = styled(GridBox, {
	height: '100vh',
	gap: 20,
	paddingLeft: rightPanelPaddingInline,
	paddingRight: rightPanelPaddingInline,
	paddingBottom: 20,
	borderLeftWidth: 1,
	borderLeftStyle: 'solid',
	borderLeftColor: '$mild',
	backgroundColor: '$background',
	gridTemplateRows: 'auto 1fr',
	maxWidth: 400,
});
