import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {styled} from '@esgi/ui/theme';
import {Button} from '@esgi/ui';

export const WeeksContainer = styled(FlexBox, {
	padding: '1px 4px',
	height: 12,
	borderRadius: 2,
	background: '$neutral56',
	color: '$vivid',
	margin: '0 6px',
});

export const Content = styled(FlexBox, {
	height: 80,
});

export const TestsCountSmallText = styled(Text, {
	paddingBottom: 2,
});

export const DetailsButton = styled(Button, {
	minWidth: 65,
});