import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {MeridiemText, TimeMeridiem, TimeText} from './index.styled';
import moment from 'moment';
import {VariantProps} from '@stitches/react';
import {Text} from '@esgi/ui/typography';
import {BaseComponentProps, ThemeColorKeys, getThemeColor} from '@esgi/ui';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
		date: Date | string;
		backgroundColor?: ThemeColorKeys;
		timeTextColor?: VariantProps<typeof Text>['color'];
	};

export const Timestamp = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'test-dashboard-timestamp',
			css = {},
			date,
			backgroundColor = 'neutral72',
			timeTextColor = 'neutral40',
			...props
		},
		forwardedRef,
	) => {
		const {time, meridiem} = useMemo(
			() => ({
				meridiem: moment(date).format('A'),
				time: moment(date).format('hh:mm'),
			}),
			[date],
		);

		return (
			<TimeMeridiem
				dataCy={dataCy}
				css={{
					borderColor: getThemeColor(backgroundColor),
					backgroundColor: getThemeColor(backgroundColor),
					...css,
				}}
				ref={forwardedRef}
				{...props}
			>
				<TimeText size='small' font='mono' bold color={timeTextColor} data-time-text='' data-cy='time'>
					{time}
				</TimeText>
				<MeridiemText size='small' font='mono' color='vivid' data-meridiem-text=''>
					{meridiem}
				</MeridiemText>
			</TimeMeridiem>
		);
	},
);
