import * as React from 'react';
import type {SVGProps} from 'react';

export function Enter(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M15.557 12.293a1 1 0 0 1 1.414 0L19.8 15.12a1 1 0 0 1 .255.978.995.995 0 0 1-.275.522L16.95 19.45a1 1 0 1 1-1.414-1.415l1.236-1.235H12a1 1 0 1 1 0-2h4.65l-1.093-1.093a1 1 0 0 1 0-1.414Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
