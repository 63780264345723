import {ComponentPropsWithoutRef} from 'react';
import {EmptyContent} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	templateText: string;
	additionalText?: string;
	dataCy?: string;
} & ComponentPropsWithoutRef<'div'>;

export function EmptyContentTemplate({
	templateText,
	additionalText,
	dataCy = 'test-panel-empty-content-template',
	...props
}: Props) {
	return (
		<EmptyContent {...props} dataCy={dataCy}>
			<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-text`}>
				{templateText}
			</Text>
			{additionalText && (
				<Text size='small' font='mono' color='neutral40' data-cy={`${dataCy}-additional-text`}>
					{additionalText}
				</Text>
			)}
		</EmptyContent>
	);
}
