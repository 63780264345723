import {
	CellTestBarInfo,
	CellTestName,
	CountInfo,
	OrderButtonsRoot,
	TableTestsRow,
	TestColorIndicator,
	SubjectsBox,
	TooltipContent,
	SubjectsListContainer,
	SubjectsListContainerTooltip,
} from './components.styled';
import {Box} from '@esgi/ui/layout';
import {OneLinedText, OrderButtons, ProgressDashedLine} from '@esgi/main/kits/assignments';
import {Test} from '@esgi/contracts/esgi/types/esgi.assignments/endpoints/esgi.apigateway/teachers/pages/assignments/info/test';
import {useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {Tooltip} from '@esgi/ui/tooltip';
import {Text} from '@esgi/ui/typography';
import {SubjectsList} from './subjects-list';

type Props = Test & {
	studentsCount: number;
} & (
		| {
				ordered?: false;
		  }
		| {
				ordered: true;
				moveUpDisabled: boolean;
				onMoveUpClick: VoidFunction;
				moveDownDisabled: boolean;
				onMoveDownClick: VoidFunction;
		  }
	);

export function TableTestsRowData({name, subjects, progress, studentsCount, id: testID, ...props}: Props) {
	const withStudentBar = studentsCount >= 1;

	const [isSubjectsTruncated, setIsSubjectsTruncated] = useState(false);

	const textRef = useRef<HTMLDivElement>(null);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight}}) => {
		setIsSubjectsTruncated(clientWidth < scrollWidth || clientHeight < scrollHeight);
	});

	return (
		<TableTestsRow dataCy='table-tests-row'>
			<CellTestName dataCy='table-tests-name'>
				<TestColorIndicator testID={testID} />
				<OneLinedText size='large' color='base'>
					{name}
				</OneLinedText>
			</CellTestName>
			<Tooltip open={!isSubjectsTruncated ? false : undefined}>
				<Tooltip.Trigger>
					<SubjectsBox isTruncated={isSubjectsTruncated}>
						<SubjectsListContainer ref={textRef}>
							<SubjectsList subjects={subjects} />
						</SubjectsListContainer>
						{isSubjectsTruncated && (
							<Text size='small' color='neutral40'>
								...
							</Text>
						)}
					</SubjectsBox>
				</Tooltip.Trigger>
				<TooltipContent>
					<SubjectsListContainerTooltip>
						<SubjectsList subjects={subjects} />
					</SubjectsListContainerTooltip>
				</TooltipContent>
			</Tooltip>
			{withStudentBar ? (
				<CellTestBarInfo>
					<Tooltip>
						<Tooltip.Trigger>
							<CountInfo
								value={progress}
								maxValue={studentsCount}
								valueTextColor={!progress ? 'neutral40' : 'primary'}
								maxValueTextColor='lowContrast'
							/>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<Text size='small'>{`${progress} out of ${studentsCount} students have been tested`}</Text>
						</Tooltip.Content>
					</Tooltip>
					<ProgressDashedLine value={progress} maxValue={studentsCount} />
				</CellTestBarInfo>
			) : (
				<Box />
			)}
			{props.ordered ? (
				<OrderButtonsRoot>
					<OrderButtons.MoveUp disabled={props.moveUpDisabled} onClick={props.onMoveUpClick} />
					<OrderButtons.MoveDown disabled={props.moveDownDisabled} onClick={props.onMoveDownClick} />
				</OrderButtonsRoot>
			) : (
				<Box />
			)}
		</TableTestsRow>
	);
}
