import {ListsWrapper} from './index.styled';
import {SelectableList} from '../lists/selectable-list';
import {SubjectTab} from '@esgi/main/libs/store';
import {AllTabListVariant} from '@esgi/main/features/untested-students-report';

type Props = {
	lists: AllTabListVariant[];
	selectedSubjectId: SubjectTab['id'] | null;
	setSelectedSubjectId: (subjectId: SubjectTab['id']) => void;
};

export function AllTabContent({lists, selectedSubjectId, setSelectedSubjectId}: Props) {
	return (
		<ListsWrapper>
			{lists.map(
				({subjects}, index) => {
					if (!subjects.length) {
						return null;
					}

					return (
						<SelectableList
							selectedSubjectId={selectedSubjectId}
							setSelectedSubjectId={setSelectedSubjectId}
							subjects={subjects}
							key={index}
						/>
					);
				},
			)}
		</ListsWrapper>
	);
}
