import {TestType} from '@esgi/core/enums';
import {DesignVersion} from '@esgi/main/libs/core';

export enum ExtendedTestType {
	None = TestType.None,
	YN = TestType.YN,
	Score = TestType.Score,
	Rubric = TestType.Rubric,
	SelfAssess = 4,
}

export type Card = {
	id: string;
	title: string;
	imageSrc: string;
	description: string;
	designVersion: DesignVersion[];
	alt: string;
	type: ExtendedTestType;
    subtitle?: string;
};
