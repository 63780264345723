import * as React from 'react';
import type {SVGProps} from 'react';

export function Assignment(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.4063 15.375C10.0438 15.375 9.75 15.0872 9.75 14.7321C9.75 14.3771 10.0438 14.0893 10.4063 14.0893H10.9688C11.3312 14.0893 11.625 14.3771 11.625 14.7321C11.625 15.0872 11.3312 15.375 10.9688 15.375H10.4063Z'
				fill='#8F8F8F'
			/>
			<path
				d='M9.75 11.8929C9.75 12.2479 10.0438 12.5357 10.4063 12.5357H10.9688C11.3312 12.5357 11.625 12.2479 11.625 11.8929C11.625 11.5378 11.3312 11.25 10.9688 11.25H10.4063C10.0438 11.25 9.75 11.5378 9.75 11.8929Z'
				fill='#8F8F8F'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.9643 16.725C15.9643 17.0712 15.7161 17.3565 15.3964 17.3955L15.3214 17.4L8.67857 17.4C8.34889 17.4 8.07718 17.1394 8.04004 16.8037L8.03572 16.725L8.03571 7.275C8.03571 6.92884 8.28388 6.64353 8.6036 6.60454L8.67857 6.6L9.32143 6.6C9.65111 6.6 9.75 6.6 9.75 6.6L14.325 6.6C14.6978 6.6 15 6.29779 15 5.925C15 5.55221 14.6977 5.24953 14.3249 5.25083C14.1805 5.25133 14.1086 5.25214 14.25 5.25344V5.25L9.75 5.25344L9.32143 5.25L8.67857 5.25C7.65149 5.25 6.81193 6.09302 6.75327 7.15602L6.75 7.275L6.75 16.725C6.75 17.8034 7.55288 18.685 8.56525 18.7466L8.67857 18.75H15.3214C16.3485 18.75 17.1881 17.907 17.2467 16.844L17.25 16.725C17.25 16.3522 16.9622 16.05 16.6071 16.05C16.2521 16.05 15.9643 16.3522 15.9643 16.725Z'
				fill='#8F8F8F'
			/>
			<path
				d='M9.75 9C9.75 9.35505 10.0438 9.64286 10.4063 9.64286H10.9688C11.3312 9.64286 11.625 9.35505 11.625 9C11.625 8.64495 11.3312 8.35714 10.9688 8.35714H10.4063C10.0438 8.35714 9.75 8.64495 9.75 9Z'
				fill='#8F8F8F'
			/>
			<path
				d='M19.5687 8.25C19.3963 8.25 19.2165 8.31271 19.0845 8.45489L16.6561 11.1683C16.4831 11.3565 16.1964 11.3701 16.0604 11.1491L15.3869 10.0554C15.1801 9.72002 14.7491 9.6267 14.4399 9.84982C14.1301 10.0737 14.0439 10.5403 14.25 10.875L14.9235 11.9687C15.75 13.125 16.8286 13.0327 17.6038 12.1942L20.053 9.5034C20.3156 9.2183 20.3156 8.73999 20.053 8.45489C19.9209 8.31271 19.7411 8.25 19.5687 8.25Z'
				fill='#8F8F8F'
			/>
		</svg>
	);
}
