import {ActionsDataType, ConflictStudentModel} from '../../types';
import {StudentBlock} from './components/student-block';
import {updateActions} from './utils';
import {ConflictTableContainer} from './index.styled';

interface Props {
	conflictedStudents: ConflictStudentModel[] | null;
	initialState: ConflictStudentModel[] | null;
	onEdit: (data: ConflictStudentModel[] | null) => void;
	onViewExistingStudentProfile: (id: string) => void;
}

export function ConflictTable({conflictedStudents, onEdit, initialState, onViewExistingStudentProfile}: Props) {

	const handleEdit = (student: ConflictStudentModel) => {
		onEdit(conflictedStudents.map(conflictStudent => conflictStudent.id === student.id ? student : conflictStudent));
	};

	const handleUpdateActions = (studentToUpdate: ConflictStudentModel, actionsData: ActionsDataType) => {
		onEdit(updateActions(conflictedStudents, studentToUpdate, actionsData));
	};

	return <ConflictTableContainer data-cy='conflict-table'>
		{conflictedStudents?.map(student => (
			<StudentBlock
				key={student.id}
				student={student}
				conflictedStudentName={initialState[student.id]?.name.value}
				lineNumber={student.id +1}
				onEdit={handleEdit}
				withDeleteAction={conflictedStudents?.length === 1}
				onUpdateActions={handleUpdateActions}
				onViewExistingStudentProfile={onViewExistingStudentProfile}
			/>
		))}
	</ConflictTableContainer>;
}
