import {BaseService} from '@esgi/core/service';
import moment from 'moment';
import {Student} from '@esgi/main/libs/store';
import {getUser} from '@esgi/core/authentication';

export class Service extends BaseService {
	public downloadStudentsCards({studentIDs}: {studentIDs: Student['id'][]}) {
		const currentUser = getUser();

		const formattedDate = moment().format('YYYY_MM_DD');

		const userName = [currentUser?.firstName.trim(), currentUser?.lastName.trim()].filter(Boolean).join('-');

		const fileName = `${userName}_student-credentials_${formattedDate}.pdf`;

		return this.httpClient.ESGIApi.file('/v2/pages/assignments/student-credentials/export', '', fileName, {
			StudentIDs: studentIDs,
		});
	}
}
