import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string
}

export const BookIcon = ({className}: Props) => {
	return <svg className={join(className)} width='266' height='204' viewBox='0 0 266 204' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M77.0179 183.774C58.4577 177.145 39.2948 173.107 19.4691 173.107C-0.356686 173.107 15.9739 171.541 15.9739 169.612V37.8827H0.607483V203.901H120.225C106.244 196.006 91.842 189.077 77.0179 183.774Z'
		      fill='white'/>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M22.9642 166.178C42.4284 166.599 61.1694 170.697 79.3681 177.205C96.6026 183.412 113.295 191.788 129.505 201.249V36.316C111.788 25.6498 94.132 16.7313 76.114 10.3437C58.8192 4.25735 41.2231 0.521189 22.9039 0.0993652V166.117L22.9642 166.178Z'
		      fill='white'/>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M136.495 36.316V201.249C152.705 191.728 169.458 183.352 186.632 177.205C204.831 170.697 223.632 166.66 243.036 166.178V0.159607C224.777 0.581431 207.12 4.25733 189.826 10.4039C171.868 16.7915 154.151 25.7101 136.435 36.3762L136.495 36.316Z'
		      fill='white'/>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M246.531 173.107C226.705 173.107 207.542 177.145 188.982 183.774C174.218 189.077 159.816 196.006 145.775 203.901H265.332V37.8827H249.966V169.612C249.966 171.541 248.399 173.107 246.471 173.107H246.531Z'
		      fill='white'/>
	</svg>;

};
