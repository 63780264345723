import {createContext, useContext} from 'react';
import {noop} from 'underscore';

export type InfoBlockContextValue = {
	/** Item`s value for displaying in group. True, if items in group. */
	inBlock: boolean;

	/** Set "inBlock" value for displaying items in group. */
	setInBlock: React.Dispatch<React.SetStateAction<boolean>>;
};

export const InfoBlockContext = createContext<InfoBlockContextValue>({
	inBlock: false,
	setInBlock: noop,
});

export function useInfoBlockContextContext() {
	return useContext(InfoBlockContext);
}
