import {BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {StudentRosterToolEvents} from 'shared/modules/student-roster-upload-tool/events';
import {EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType} from '@esgi/core/authentication';
import {uniq} from 'underscore';
import {StudentManagerEvents} from '../events';
import {StudentModel, UnassignType} from '../models/models';
import DataService from './data-service';
import {
	StudentChangedEvent,
	StudentCreatedEvent,
	StudentRemovedEvent,
} from 'modules/forms/students-form/events';

export default class StudentsService extends BaseService {
	public studentsSource$: BehaviorSubject<StudentModel[]> = new BehaviorSubject<StudentModel[]>([]);
	private eventBusManager: EventBusManager = new EventBusManager();

	constructor(private dataService: DataService) {
		super();
		this.dataService.data$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const data = this.dataService.data;
			const source = [];
			for (let i = 0; i < data.students.length; i++) {
				const student = data.students[i];
				this.setEditable(student);
				source.push({...student});
			}
			this.studentsSource$.next(source);
		});

		this.eventBusManager.subscribe(StudentRosterToolEvents.Uploaded, (args) => this.studentsUploaded(args));
		this.eventBusManager.subscribe(StudentCreatedEvent, (args) => this.studentCreated(args));
		this.eventBusManager.subscribe(StudentChangedEvent, (args) => this.studentChanged(args));
		this.eventBusManager.subscribe(StudentRemovedEvent, (args) => this.studentRemoved(args));
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsDeleted, (args) => this.remove(args));
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsUnassigned, (args) => this.unassign(args));
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsAssignedToTeacher, (args) => this.assignToTeacher(args));
		this.eventBusManager.subscribe(StudentManagerEvents.StudentsAssignedToSpecialist, (args) => this.assignToSpecialist(args));
	}

	public init(students: StudentModel[]): void {
		this.studentsSource$.next(students);
	}

	public getStudents(...ids: number[]): StudentModel[] {
		if (!ids || ids && ids.length === 0) {
			return this.studentsSource$.value;
		}
		return this.studentsSource$.value.filter(s => ids.indexOf(s.studentID) > -1);
	}

	private updateSource(students: StudentModel[]): void {
		this.studentsSource$.next(this.studentsSource$.value.map(s => {
			const index = students.findIndex(st => st.studentID === s.studentID);
			if (index > -1) {
				return students[index];
			}
			return s;
		}));
	}

	private assignToTeacher(args: StudentManagerEvents.StudentsAssignedToTeacher): void {
		const students = this.studentsSource$.value.filter(s => args.studentsIDs.indexOf(s.studentID) > -1);
		const moveModel = args.model;
		for (const student of students) {
			if (student.schoolID !== moveModel.schoolID || student.primaryTeacherID !== moveModel.teacherID) {
				student.schoolID = moveModel.schoolID;
				student.primaryTeacherID = moveModel.teacherID === -1 ? null : moveModel.teacherID;
				student.classIDs = moveModel.classID > 0 ? [moveModel.classID] : [];
				student.groupIDs = moveModel.groupID > 0 ? [moveModel.groupID] : [];
			} else {
				if (student.classIDs.indexOf(moveModel.classID) === -1) {
					student.classIDs.push(moveModel.classID);
				}
				if (student.groupIDs.indexOf(moveModel.groupID) === -1) {
					student.groupIDs.push(moveModel.groupID);
				}
			}
			this.setEditable(student);
		}
		this.updateSource(students);
	}

	private assignToSpecialist(args: StudentManagerEvents.StudentsAssignedToSpecialist): void {
		const students = this.studentsSource$.value.filter(s => args.studentsIDs.indexOf(s.studentID) > -1);
		const moveModel = args.model;
		for (const student of students) {
			student.specialistGroupIDs = uniq([...student.specialistGroupIDs, moveModel.specialistGroupID]);
			student.specialistGroupUserIDs = uniq([...student.specialistGroupUserIDs, moveModel.specialistUserID]);
			student.specialistGroupTypes = uniq([...student.specialistGroupTypes, moveModel.specialistType.toString()]);
			this.setEditable(student);
		}
		this.updateSource(students);
	}

	private unassign(args: StudentManagerEvents.StudentsUnassigned) {
		const {studentsIDs, type, removeModel} = args;
		const students = [...this.studentsSource$.value];
		for (let i = 0; i < studentsIDs.length; i++) {
			const selectedStudent = studentsIDs[i];
			const index = students.map(e => e.studentID).indexOf(selectedStudent);

			const student = students[index];
			if (type === UnassignType.PrimaryTeacher || type === UnassignType.Both) {
				student.primaryTeacherID = null;
				student.classIDs = [];
				student.groupIDs = [];
			}

			if (type === UnassignType.Specialist || type === UnassignType.Both) {
				student.specialistGroupIDs = student.specialistGroupIDs.filter(x => removeModel.unassignedGroups.indexOf(x) === -1);
				student.specialistGroupUserIDs = student.specialistGroupUserIDs.filter(x => removeModel.unassignedUserIDs.indexOf(x) === -1);
			}

			students[index] = student;
			this.setEditable(students[index]);
		}
		this.studentsSource$.next(students);
	}

	private remove(args: StudentManagerEvents.StudentsDeleted) {
		const res = [...this.studentsSource$.value];
		this.studentsSource$.next(res.filter(s => args.studentIDs.indexOf(s.studentID) === -1));
	}

	private setEditable(student: StudentModel) {
		const userTypes = [UserType.T, UserType.ISS, UserType.ISD, UserType.PA];
		const user = userStorage.get();
		const requiredCheckUserTypes = userTypes.includes(user.userType);
		const requiredCheckAgreementTypes = userTypes.includes(UserType[this.dataService.data.agreementLevelCode]);

		if(requiredCheckUserTypes) {
			switch (user.userType) {
				case UserType.T:
					const classIDs = this.dataService.data.schools[0].teachers.find(x => x.teacherID === user.userID).classes.map(x => x.classID);
					student.editable = student.classIDs.some(c => classIDs.includes(c));
					break;
				case UserType.ISS:
				case UserType.ISD:
				case UserType.PA:
					student.editable = student.specialistGroupUserIDs.includes(user.userID);
					break;
			}
		} else if(requiredCheckAgreementTypes) {
			switch (UserType[this.dataService.data.agreementLevelCode]) {
				case UserType.T:
					const classIDs = this.dataService.data.schools[0].teachers.find(x => x.teacherID === user.userID).classes.map(x => x.classID);
					student.editable = student.classIDs.some(c => classIDs.includes(c));
					break;
				case UserType.ISS:
				case UserType.ISD:
				case UserType.PA:
					student.editable = student.specialistGroupUserIDs.includes(user.userID);
					break;
			}
		} else {
			student.editable = true;
		}
	}

	private studentCreated = (args: StudentCreatedEvent) => {
		const student = new StudentModel(
			args.firstName,
			args.lastName,
			args.studentID,
			args.studentIDN,
			args.classes,
			[],
			args.gradeLevelID,
			[],
			[],
			[],
			new Date().toISOString(),
		);
		student.primaryTeacherID = args.teacherID;
		student.schoolID = args.schoolID;

		this.setEditable(student);

		this.studentsSource$.next([...this.studentsSource$.value, student]);
	};

	private studentChanged(a: StudentChangedEvent): void {
		const currentUser = userStorage.get();
		const student = {...this.studentsSource$.value.find(s => s.studentID === a.studentID)};
		student.studentIDN = a.studentIDN;
		student.firstName = a.firstName;
		student.lastName = a.lastName;
		student.schoolID = a.schoolID;
		student.gradeLevelID = a.gradeLevelID;

		if (currentUser.userType === UserType.C || currentUser.userType === UserType.D || currentUser.userType === UserType.T) {
			student.classIDs = a.classes;
			student.groupIDs = a.groups;
			student.primaryTeacherID = a.teacherID;
		}

		if (currentUser.userType === UserType.ISD || currentUser.userType === UserType.ISS || currentUser.userType === UserType.PA && a.specialistGroups) {
			student.specialistGroupUserIDs = a.specialistGroups.length > 0 ?
				student.specialistGroupUserIDs.concat(a.specialistGroups.map(x => x.userID)) :
				[];
			student.specialistGroupIDs = a.specialistGroups
				.map(x => x.id)
				.concat(student.specialistGroupIDs.filter(x => a.specialistGroups.map(x => x.id).indexOf(x) === -1));
		}

		this.setEditable(student);

		this.updateSource([student]);
	}

	private studentRemoved(a: StudentRemovedEvent): void {
		const students = [...this.studentsSource$.value];
		const index = students.map(e => e.studentID).indexOf(a.id);
		const student = {...students[index]};
		student.primaryTeacherID = 0;
		student.classIDs = [];
		student.groupIDs = [];
		students[index] = student;
		student.specialistGroupIDs = student.specialistGroupIDs.filter(x => a.detachedGroups.map(c => c.id).indexOf(x) === -1);
		student.specialistGroupUserIDs = student.specialistGroupUserIDs.filter(x => a.detachedGroups.map(c => c.userID).indexOf(x) === -1);

		this.studentsSource$.next(students);
	}

	private studentsUploaded = (args: StudentRosterToolEvents.Uploaded.Args) => {
		const students = [...this.studentsSource$.value].filter(s => !args.students.some(x => x.id === s.studentID));

		const newStudents = args.students.map((s) => {
			const student = new StudentModel(
				s.firstName,
				s.lastName,
				s.id,
				s.studentIDN,
				[],
				[],
				s.gradeLevelID,
				[],
				[],
				[],
				new Date().toISOString(),
			);
			student.schoolID = args.schoolID;
			student.primaryTeacherID = args.teacherID;
			student.classIDs = [args.classID];

			this.setEditable(student);
			return student;
		});

		this.studentsSource$.next(newStudents.concat(students));
	};

	public destroy() {
		super.destroy();
		this.eventBusManager.destroy();
	}
}
