import {TestSession, TrackDate} from '../../../types';

export function filterSessions(
	value: string | (string | Date)[],
	testSessions: TestSession[],
	trackDates: TrackDate[]
) {
	return testSessions.filter(item => {
		if (Array.isArray(value)) {
			const [dateFrom, dateTo] = value as Date[];
			const testDate = new Date(item.testDate);
			dateFrom.setHours(0, 0, 0, 0);
			dateTo.setHours(23, 59, 59);
			return testDate >= dateFrom && testDate <= dateTo;
		} else {
			const period = trackDates.find(item => item?.dateFrom === value);
			const dateFrom = new Date(period?.dateFrom);
			const dateTo = new Date(period?.dateTo);
			const testDate = new Date(item?.testDate);
			dateTo.setHours(23, 59, 59);
			return testDate >= dateFrom && testDate <= dateTo;
		}
	});
}