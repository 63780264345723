import {Observable} from 'rxjs';
import {ControlValue, FormControl, FormGroup, ValidatorArgs, Validators} from '@esgi/ui/form';
import {tap} from 'rxjs/operators';

export type QuickSetupSettingsFormType = FormGroup<{
	lockScreen: FormControl<boolean>,
	enableShortcuts: FormControl<boolean>,
	screenLockPIN: FormControl<string>,
	confirmPin: FormControl<string>,
	pinsEqual: FormControl<string>,
},
	ControlValue<{
		lockScreen: FormControl<boolean>,
		enableShortcuts: FormControl<boolean>,
		screenLockPIN: FormControl<string>,
		confirmPin: FormControl<string>,
		pinsEqual: FormControl<string>,
	}>>

export const createQuickSettingsSetupForm = ():
	QuickSetupSettingsFormType => new FormGroup({
	lockScreen: new FormControl(false),
	enableShortcuts: new FormControl(false),
	screenLockPIN: new FormControl('', {validators: [Validators.length.min(4), Validators.length.max(4)]}),
	confirmPin: new FormControl('', {validators: [Validators.length.min(4), Validators.length.max(4)]}),
	pinsEqual: new FormControl(''),
});

export const equalPinsValidator = (pin: string) => {
	return function (validationChain: Observable<ValidatorArgs<string>>) {
		return validationChain.pipe(tap(args => {
			const valid = pin === args.field.currentValue;
			if (!valid) {
				args.errors.push('pinsNotEqual');
			}
		}));
	};
};
