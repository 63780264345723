import {BaseComponentProps} from 'libs/ui/src/types';
import {ComponentPropsWithoutRef, forwardRef, useEffect, useId, useMemo, useRef, useState} from 'react';
import {AlertCloseButton, Backlight, ChildrenContentWrapper, ContentWrapper, HeaderBox} from './index.styled';
import {useAlertContext} from '../../context';
import useResizeObserver from '@react-hook/resize-observer';
import {getThemeColor, useAutoControlledState} from '@esgi/ui/utils';

type AlertHeaderProps = BaseComponentProps &
	ComponentPropsWithoutRef<'div'> & {
		/**
		 * Display close icon button at the right corner.
		 * @default true
		 */
		withCloseIcon?: boolean;

		onCloseIconClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
		disableClose?: boolean;

		/**
		 * Display backlight under the passed conetnt.
		 * Has width of passed content width.
		 * @default true
		 */
		withBacklight?: boolean;
	};

export const AlertHeader = forwardRef<HTMLDivElement, AlertHeaderProps>(
	(
		{
			dataCy = 'ui-kit-alert-header',
			css = {},
			children,
			withCloseIcon = true,
			withBacklight = true,
			id: extendedId,
			onCloseIconClick,
			disableClose,
			...props
		},
		forwardedRef,
	) => {
		const innerId = useId();
		const {colorConfig, setContentWithHeader, setLabelId} = useAlertContext();

		const [id] = useAutoControlledState({initialState: innerId, controlledState: extendedId});

		const [childrenWidth, setChildrenWidth] = useState(0);

		useEffect(() => {
			setContentWithHeader(true);

			return () => setContentWithHeader(false);
		}, []);

		useEffect(() => setLabelId(id), [id]);

		const contentInnerRef = useRef<HTMLDivElement>(null);

		useResizeObserver(contentInnerRef, (entry) => setChildrenWidth(entry.contentRect.width));

		const {headerBackground, childrenTextColor, backlightColor} = useMemo(() => {
			const headerFirstGradient = getThemeColor(colorConfig.headerBgColor ?? 'vivid');

			return {
				headerBackground: `linear-gradient(180deg, ${headerFirstGradient} 0%, $vivid 100%)`,
				childrenTextColor: getThemeColor(colorConfig.headerTextColor ?? 'neutral12'),
				backlightColor: getThemeColor(colorConfig.backlightColor ?? 'neutral12'),
			};
		}, [colorConfig.headerBgColor, colorConfig.headerTextColor, colorConfig.backlightColor]);

		return (
			<HeaderBox
				dataCy={dataCy}
				css={{
					background: headerBackground,
					...css,
				}}
				{...props}
				ref={forwardedRef}
			>
				<ContentWrapper>
					<ChildrenContentWrapper id={id} css={{color: childrenTextColor}} ref={contentInnerRef}>
						{children}
					</ChildrenContentWrapper>
					{withCloseIcon && <AlertCloseButton onClick={onCloseIconClick} disabled={disableClose} />}
				</ContentWrapper>
				{withBacklight && <Backlight css={{width: childrenWidth, backgroundColor: backlightColor}} />}
			</HeaderBox>
		);
	},
);
