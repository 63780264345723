import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useService} from '@esgi/core/service';

import {Container} from '../kit';
import {CardBox} from '../../card/styled';
import {QuestionsService} from './service';
import {ClassView} from './variants/class-view';
import {StudentView} from './variants/student-view';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	studentID: number;
	transparent?: boolean;
};

export function QuestionsAnswered(props: Props) {
	const service = useService(QuestionsService);

	return <CardBox transparent={props.transparent} dataCy='widget-card'>
		<Container direction='column' justify='between' padding20={!props.transparent} noPadding={props.transparent}>
			<FlexBox justify='start' align='center'>
				<Text color='neutral40' size='medium' data-cy='widget-name'>Questions Answered</Text>
			</FlexBox>
			<FlexBox justify='start' align='center'>
				{
					props.studentID ?
						<StudentView studentID={props.studentID} testIDs={props.testIDs} service={service}/>
						:
						<ClassView testIDs={props.testIDs} service={service} studentIDs={props.studentIDs}/>
				}
			</FlexBox>
		</Container>
	</CardBox>;
}