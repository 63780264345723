import {useMemo} from 'react';
import {UserType, useUser} from '@esgi/core/authentication';
import {Buttons} from '@esgillc/ui-kit/button';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {isStudentLevel, isUserLevel} from '../../../utils';
import styles from '../../../card.module.less';
import {hasTestSessionDetailsAllStudents} from 'modules/forms/test-session-details-all-students-form/utils';
import {TestType} from '@esgi/core/enums';

interface Props {
	testID: number;
	studentsTested: number;
	hierarchy: HierarchySnapshot;
	disabled: boolean;
	runTestClicked: () => void;
	openTestHistoryClicked: () => void;
	openTestSessionDetailsClicked: () => void;
	testType: TestType;
}

export function FooterActions(props: Props) {
	const user = useUser();
	const isStudent = isStudentLevel(props.hierarchy);
	const hasDetailsAllStudents = hasTestSessionDetailsAllStudents();

	const hasDetailsButton = useMemo(
		() => {
			console.log('TEST TYPE', TestType[props.testType]);
			return isStudent || (props.studentsTested > 0 && hasDetailsAllStudents && props.testType !== TestType.Rubric)
		},
		[isStudent, props.studentsTested, hasDetailsAllStudents, props.testType],
	);

	function renderTestButton() {
		if (isStudent && [UserType.T, UserType.ISD, UserType.ISS, UserType.PA].includes(user?.userType)) {
			return (
				<Buttons.Contained
					className={styles.testButton}
					disabled={props.disabled}
					onClick={props.runTestClicked}
				>
					Test
				</Buttons.Contained>
			);
		}
	}

	function renderHistoryButton() {
		if (props.studentsTested > 0 && isUserLevel(props.hierarchy)) {
			return (
				<Buttons.Text
					className={styles.btn}
					disabled={props.disabled}
					data-cy={`history-button-${props.testID}`}
					onClick={props.openTestHistoryClicked}
				>
					History
				</Buttons.Text>
			);
		}
	}

	const renderDetailsButton = () => hasDetailsButton && (
		<Buttons.Text
			className={styles.btn}
			disabled={props.disabled}
			data-cy={`session-details-${props.testID}`}
			onClick={props.openTestSessionDetailsClicked}
		>
			Details
		</Buttons.Text>
	);

	return <div className={styles.footer}>
		{(props.studentsTested > 0 || isStudent) && <hr/>}
		<div className={styles.historyBtnHolder}>
			{renderHistoryButton()}
		</div>
		<div className={styles.testBtnHolder}>
			{renderTestButton()}
		</div>
		<div className={styles.detailsBtnHolder}>
			{renderDetailsButton()}
		</div>
	</div>;
}
