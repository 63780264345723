import React from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {AnswerState} from '@esgi/core/enums';
import './answer-panel.less';
import {Models} from '../models';
import {Answer, State as AnswerComponentState} from './answer';
import {CheckboxOptions, CheckedMode} from '@esgi/deprecated/elements/checkbox-options';
import {YNEvents} from '../modal/types/events';

export class Props extends SharedProps<State> {
    editMode: boolean;
    answers: Models.Answer[] = [];
    testSession: Models.TestSession;
    answerState: AnswerState;
    title: string;
    setActive: (panelSate?: AnswerState) => void;
    saveNoteHandler: (testSessionId: number, questionID: number, comment: string) => void;
    deleteNoteHandler: (testSessionId: number, questionID: number) => void;
    studentName: string;
    testName: string;
    readOnly: boolean;
		reload?: boolean;
}

export class State {
    selectedAnswers: number[] = [];
    answers: AnswerComponentState[] = [];
}

export class AnswerPanel extends SharedComponent<State, Props> {
	constructor(props?: Props) {
		super(props);
		this.bindScope();

		this.state = new State();
	}

	componentDidMount() {
		this.subscribe(YNEvents.ChangeActivePanel, (args: YNEvents.ChangeActivePanel.Args) => {
			if (args.activePanel === this.props.answerState) {
				return;
			}
			this.resetCheckboxes();
		});
	}

    private answers: Models.Answer[][];

    componentDidUpdate(prevProps: Props) {
    	let areEqual = true;
    	if (prevProps.answers.length === this.props.answers.length) {

    		const ids = this.props.answers.map(a => a.id);
    		prevProps.answers.forEach(a => {
    			areEqual = areEqual && ids.includes(a.id);
    		});
				if (areEqual && !this.answers) {
					const orderedAnswers = this.orderAnswers(this.props.answers);

					this.answers = (orderedAnswers.length)
						? []
						: null;
					for (let i = 0; i < orderedAnswers.length; i++) {
						let answer = orderedAnswers[i];

						if (i == 0 || answer.testDate.format('MM-DD-YY') != orderedAnswers[i - 1].testDate.format('MM-DD-YY')) {
							this.answers.push([]);
						}

						this.answers[this.answers.length - 1].push(answer);
					}

				}
    	} else {
    		areEqual = false;
    	}

    	if (areEqual) {
    		return;
    	}
    	const orderedAnswers = this.orderAnswers(this.props.answers);

    	this.answers = (orderedAnswers.length)
    		? []
    		: null;

    	if (!this.answers) {
    		this.setState({answers: [], selectedAnswers: []});
    		return;
    	} else {
    		let answerStates = [];

    		for (let i = 0; i < orderedAnswers.length; i++) {
    			let answer = orderedAnswers[i];

    			if (i == 0 || answer.testDate.format('MM-DD-YY') != orderedAnswers[i - 1].testDate.format('MM-DD-YY')) {
    				this.answers.push([]);
    			}

    			this.answers[this.answers.length - 1].push(answer);
    			answerStates.push(new AnswerComponentState());
    		}

    		this.setState({answers: answerStates, selectedAnswers: []});
    	}
    }

    get isActive(): boolean {
    	return !!this.state.selectedAnswers.length;
    }

    resetCheckboxes = () => {
    	if (!this.isActive) {
    		return;
    	}
    	this.setState({selectedAnswers: []});
    };

		private orderAnswers(answers) {
			return answers.sort((a1, a2) => {
				if (a1.testDate.isAfter(a2.testDate)) {
					return -1;
				}
				if (a1.testDate.isBefore(a2.testDate)) {
					return 1;
				}
				return 0;
			});
		}

    private bindScope() {
    	this.resetCheckboxes = this.resetCheckboxes.bind(this);
    }

    get checkboxOptionsMode() {
    	if (this.state.selectedAnswers.length === 0) {
    		return CheckedMode.None;
    	}

    	if (this.state.selectedAnswers.length === this.props.answers.length) {
    		return CheckedMode.All;
    	}

    	if (this.state.selectedAnswers.length > 0) {
    		return CheckedMode.Partially;
    	}
    }

    selectCheckboxOptions(checked: boolean) {
    	let newState = {...this.state};

    	if (checked) {
    		newState.selectedAnswers = this.props.answers.map(x => x.questionId);
    		newState.answers = this.state.answers.map((a) => {
    			return {...a, checked: true};
    		});
    		this.props.setActive(this.props.answerState);

    	} else {
    		newState.selectedAnswers = [];
    		newState.answers = this.state.answers.map((a) => {
    			return {...a, checked: false};
    		});
    		this.props.setActive();
    	}

    	this.setState(newState);
    }

    private setAnswerState(changes: AnswerComponentState, answerIndx: number, questionId: number) {
    	let states = this.state.answers.map((a, i) => {
    		return (answerIndx == i) ? changes : a;
    	});

    	let isActive = this.isActive;

    	let newSelectedState = (changes.selected)
    		? this.state.selectedAnswers.concat(questionId)
    		: this.state.selectedAnswers.filter(s => s != questionId);

    	if (!isActive && changes.selected) {
    		this.props.setActive(this.props.answerState);
    	}

    	if (isActive && !newSelectedState.length) {
    		this.props.setActive();
    	}

    	this.setState({
    		answers: states,
    		selectedAnswers: newSelectedState,
    	});
    }

    render() {
    	let answersCount = 0;
    	return <div className={'question-panel' + ((this.isActive) ? ' active' : '')}>
            <div className='checkbox-options'>
                {this.props.editMode ? <CheckboxOptions
                    onClick={(checked) => this.selectCheckboxOptions(checked)}
                    disabled={this.props.answers.length === 0}
                    checked={this.checkboxOptionsMode}
                /> : <></>}
                <label className='question-title'>{this.props.title} ({this.props.answers.length})</label>
            </div>
            <div className={'answer-list ' + ((this.props.editMode) ? 'edit' : '')}>
                <div>
                    {this.answers &&
                        <>
                            {this.answers.map((dateAnswers, i, array ) => {
                                return <div className='date-answers' key={i}>
                                    {this.props.answerState != AnswerState.NotTested &&
                                        <div className='date-title'>
                                            {dateAnswers[0].testDate.format('MM-DD-YY')}
                                        </div>}

                                    {dateAnswers.map((answer, k) => {
                                        const indx = answersCount;
                                        answersCount++;

                                        return <Answer key={k}
                                            state={this.state.answers[indx]}
                                            onChange={(ch: AnswerComponentState) => this.setAnswerState(ch, indx, answer.questionId)}
                                            answer={answer}
                                            editMode={this.props.editMode}
                                            notTested={this.props.answerState == AnswerState.NotTested}
                                            selected={(this.isActive && this.state.selectedAnswers.indexOf(answer.questionId) > -1)}
                                            saveNoteHandler={(note: string) => {
                                                this.props.saveNoteHandler(answer.sessionId, answer.questionId, note);
                                            }}
                                            deleteNoteHandler={() => {
                                                this.props.deleteNoteHandler(answer.sessionId, answer.questionId);
                                            }}
                                            studentName={this.props.studentName}
                                            testName={this.props.testName}
                                            readOnly={this.props.readOnly}
                                        />;
                                    })}
                                </div>;
                            })}
                        </>
                    }
                </div>
            </div>
        </div>;
    }
}
