import * as React from 'react';
import type {SVGProps} from 'react';

export function Delete(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.5283 10.5789V20.4211C11.5283 21.4094 12.3295 22.2105 13.3178 22.2105H18.6862C19.6746 22.2105 20.4757 21.4094 20.4757 20.4211V10.5789H22.2652V20.4211C22.2652 22.3976 20.6628 24 18.6862 24H13.3178C11.3412 24 9.73887 22.3976 9.73887 20.4211V10.5789H11.5283Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.0001 7C15.0114 7 14.2106 7.79845 14.2106 8.78426H9.7369C9.24301 8.78426 8.84216 9.18393 8.84216 9.67638C8.84216 10.1688 9.24301 10.5685 9.7369 10.5685H22.2632C22.7571 10.5685 23.158 10.1688 23.158 9.67638C23.158 9.18393 22.7571 8.78426 22.2632 8.78426H17.7895C17.7895 7.79845 16.9887 7 16.0001 7ZM14.2126 12.3945C14.7067 12.3945 15.1073 12.7939 15.1073 13.2866V19.5289C15.1073 20.0216 14.7067 20.4211 14.2126 20.4211C13.7184 20.4211 13.3178 20.0216 13.3178 19.5289V13.2866C13.3178 12.7939 13.7184 12.3945 14.2126 12.3945ZM18.6862 13.2866C18.6862 12.7939 18.2857 12.3945 17.7915 12.3945C17.2973 12.3945 16.8968 12.7939 16.8968 13.2866V19.5289C16.8968 20.0216 17.2973 20.4211 17.7915 20.4211C18.2857 20.4211 18.6862 20.0216 18.6862 19.5289V13.2866Z'
				fill='#333333'
			/>
		</svg>
	);
}
