import React, {Suspense} from 'react';
import {Test as TestEntity} from 'api/entities/test';
import {TestFavoriteChangedEvent, TestHiddenChangedEvent} from 'modules/assets/tests/kits/test-details';
import RubricPreview from 'modules/assets/tests/rubric/preview/preview-flow';
import {tap} from 'rxjs/operators';
import {lazyComponent} from '@esgi/core/react';
import {EventBusDispatcher} from '@esgillc/events';
import {TestModel} from '../../../../services/search-service';
import {SubjectItem} from '../../../../services/subjects-service';
import {AddTestToSubject} from '../../../add-tests-to-subject';
import RubricThumbnail from './components/rubric-thumbnail';
import Body from '../card-elements/body';
import Footer from '../card-elements/footer';
import Header from '../card-elements/header';

const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

interface Props {
	testModel: TestModel;
	subjects: SubjectItem[];
}

class State {
	showPreview: boolean = false;
	showTestDetails: boolean = false;
	addTestToSubjectOpened: boolean = false;
}

export default class RubricCard extends React.PureComponent<Props, State> {
	public state = new State();

	public render() {
		const testModel = this.props.testModel;
		return <div className='card'>
			<div className='slider-wrapper'>
				<RubricThumbnail onClick={() => this.setState({showPreview: true})}/>
			</div>
			<div className='card-info'>
				<Header name={testModel.name}
				        hidden={testModel.hidden}
				        starred={testModel.starred}
				        createDate={testModel.createDate}
				        school={testModel.school}
				        district={testModel.district}
				        contentArea={testModel.contentArea}
				        gradeLevels={testModel.gradeLevels}
				        onStarClicked={() => this.star(!testModel.starred)}
				        onHideClicked={() => this.hide(!testModel.hidden)}
				        openTestDetails={() => this.setState({showTestDetails: true})}/>
				<Body author={testModel.author}
				      typeText='Rubric'
				      numberOfQuestions={testModel.numberOfQuestions}
				      stateStandard={testModel.stateStandard}
				      description={testModel.description}/>
				<Footer testID={testModel.testID}
				        draft={false}
				        lastTestDate={testModel.lastTestDate}
				        mySubjects={this.props.subjects}
				        addToSubjectClicked={() => this.setState({addTestToSubjectOpened: true})}
				        onPreviewClicked={() => this.setState({showPreview: true})}/>
			</div>
			{this.renderPreview()}
			{this.renderTestDetails()}
			{this.renderAddToSubject()}
		</div>;
	}

	private star(state: boolean) {
		const event = new TestFavoriteChangedEvent(this.props.testModel.testID, state);

		if (state) {
			TestEntity.star(this.props.testModel.testID)
				.pipe(tap(() => EventBusDispatcher.dispatch(TestFavoriteChangedEvent, event)))
				.subscribe();
		} else {
			TestEntity.unstar(this.props.testModel.testID)
				.pipe(tap(() => EventBusDispatcher.dispatch(TestFavoriteChangedEvent, event)))
				.subscribe();
		}
	}

	private delayedHiddenStatus: boolean = null;

	private hide(state: boolean) {
		const event = new TestHiddenChangedEvent(this.props.testModel.testID, state);

		if (state) {
			this.delayedHiddenStatus = true;
			TestEntity.hide(this.props.testModel.testID)
				.pipe(tap(() => EventBusDispatcher.dispatch(TestHiddenChangedEvent, event)))
				.subscribe();
		} else {
			this.delayedHiddenStatus = false;
			TestEntity.unhide(this.props.testModel.testID)
				.pipe(tap(() => EventBusDispatcher.dispatch(TestHiddenChangedEvent, event)))
				.subscribe();
		}
	}

	private renderPreview() {
		if (this.state.showPreview) {
			return <RubricPreview testID={this.props.testModel.testID}
			                      onClose={() => this.setState({showPreview: false})}/>;
		}
	}

	private renderTestDetails() {
		if (this.state.showTestDetails) {
			return <Suspense fallback={<></>}>
				<RubricDetails testID={this.props.testModel.testID}
				               close={() => this.setState({showTestDetails: false})}/>
			</Suspense>;
		}
	}

	private renderAddToSubject() {
		if (this.state.addTestToSubjectOpened) {
			return <AddTestToSubject mySubjects={this.props.subjects}
			                         testName={this.props.testModel.name}
			                         testId={this.props.testModel.testID}
			                         close={() => this.setState({addTestToSubjectOpened: false})}/>;
		}
	}
}
