import {ClassChangedEvent, ClassCreatedEvent, ClassRemovedEvent} from 'modules/forms/class-form/events';
import {IClassModel} from '../core/api';
import {HierarchyState} from './hierarchy-data-service';

export function applyClassCreated(args: ClassCreatedEvent, hierarchy: HierarchyState): HierarchyState {
	const {students, classes} = hierarchy;
	students.items = students.items.map(x => {
		if (args.students.some(c => c === x.studentID)) {
			const newStudent = {...x};
			newStudent.classes.push(args.id);
			return newStudent;
		}
		return x;
	});

	const item: IClassModel = {
		classID: args.id,
		name: args.name,
		userID: args.userID,
	};

	classes.items = classes.items.concat([item]);
	return hierarchy;
}

export function applyClassChanged(args: ClassChangedEvent, hierarchy: HierarchyState): HierarchyState {
	const {students, classes} = hierarchy;

	const studentsInClass = students.items.filter(x => x.classes.some(c => c === args.id));
	const forRemove = studentsInClass.filter(x => args.students.every(c => c !== x.studentID));
	const forAddIds = args.students.filter(x => studentsInClass.every(c => c.studentID !== x));

	students.items = students.items.map((x) => {
		if (forRemove.some(c => c.studentID === x.studentID)) {
			const newStudent = {...x};
			newStudent.classes = x.classes.filter(x => x !== args.id);
			return newStudent;
		}
		if (forAddIds.some(c => c === x.studentID)) {
			const newStudent = {...x};
			newStudent.classes.push(args.id);
			return newStudent;
		}
		return x;
	});

	classes.items = classes.items.map(x => x.classID === args.id ? {...x, name: args.name} : {...x});
	return hierarchy;
}

export function applyClassRemoved(args: ClassRemovedEvent, hierarchy: HierarchyState): HierarchyState {
	const {classes, classic} = hierarchy;
	classes.items = classes.items.filter(s => s.classID !== args.id);

	if (classic.classID === args.id) {
		if (classes.items.length) {
			const first = classes.items.find(t => t.userID === classic.teacherID);
			if (first) {
				classic.classID = first.classID;
			} else {
				classic.classID = 0;
			}
		} else {
			classic.classID = 0;
		}
		classic.groupID = 0;
		classic.studentID = 0;
	}

	return hierarchy;
}
