import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ReportFilterBodyStyled = styled(Box, {
	backgroundColor: '#FFFFFF',
	boxShadow: '0px 4px 8px -4px #bfbfbf',

	'>:last-child': {
		borderBottom: 'none',
	},
});

export const ReportFilterRowStyled = styled(Box, {
	display: 'flex',
	flexDirection: 'row',
	borderBottom: '2px solid #FFFFFF',
	padding: '0 25px',

	'>:last-child': {
		borderRight: 'none',
	},
});