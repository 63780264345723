import {observable} from '@esgi/deprecated/knockout';
import {Step} from '../steps';
import {PaymentIsUnavailableTemplate} from './payment-is-unavailable-template';

export class PaymentIsUnavailable extends Step {

	@observable()
	wasPaymentProcessed: boolean;

	constructor(wasPaymentProcessed: boolean) {
		super();
		this.wasPaymentProcessed = wasPaymentProcessed;
	}

	template = () => PaymentIsUnavailableTemplate.render();
}
