import {Dispatch} from 'react';
import {StudentModel} from '../../types';
import {ButtonCheckbox} from '../button-checkbox';
import {OneLinedText, getFullName} from '@esgi/main/kits/assignments';
import {ListContentRow} from '../students-list/index.styled';

type Props = {
	student: StudentModel;
	selectedStudentsIDs: StudentModel['id'][];
	toggleSelectedStudentID: Dispatch<StudentModel['id']>;
};

export function ContentCell({student, selectedStudentsIDs, toggleSelectedStudentID}: Props) {
	const checked = selectedStudentsIDs.includes(student.id);

	return (
		<ListContentRow checked={checked} disabled={!student.hasCredentials} key={student.id} dataCy='student-row'>
			<ButtonCheckbox
				checked={checked}
				disabled={!student.hasCredentials}
				onClick={() => toggleSelectedStudentID(student.id)}
			/>
			<OneLinedText size='medium' bold color='primary' data-cy='student-fullname'>
				{getFullName({firstName: student.firstName, lastName: student.lastName})}
			</OneLinedText>
			<OneLinedText size='medium' color='neutral40' data-cy='joined-assignment'>
				{student.joinedAssignmentName}
			</OneLinedText>
		</ListContentRow>
	);
}
