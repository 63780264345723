import {SelectableList as SelectableListUI} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {SelectableListItem, StyledButton, Wrapper, selectableListItemMargin, StyledText} from './index.styled';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Close} from '@esgi/ui';
import {OptionItem} from '../../types';

type Props = {
	items: OptionItem[];
	selectedItems: number[];
	onClick?: (id: number) => void;
	onItemRemove?: (id: number) => void;
	columnView?: boolean;
	wrapItems?: boolean;
};

export function SelectableList({items, onClick, selectedItems, columnView, onItemRemove, wrapItems}: Props) {
	const containerRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const [visibleItems, setVisibleItems] = useState(items);
	const [isCollapsed, setIsCollapsed] = useState(true);
	const showMoreButton = visibleItems.length < items.length || !isCollapsed;

	const recalculateVisibility = useCallback((newItems: OptionItem[], observer?: MutationObserver) => {
		const children = Array.from(containerRef.current.children) as HTMLElement[];

		if (!children.length) {
			setVisibleItems(newItems);
			return;
		}

		const containerWidth = containerRef.current.offsetWidth;
		const moreButtonWidth = buttonRef.current?.offsetWidth || 0;
		let childrenWidth = 0;
		let maxChildrenCount = 0;


		children.forEach(child => {
			childrenWidth += child.offsetWidth + selectableListItemMargin;
			if (childrenWidth + moreButtonWidth <= containerWidth) {
				maxChildrenCount += 1;
			} else {
				observer?.disconnect();
			}
		});

		setVisibleItems((prev) => {
			if ((!isCollapsed && newItems.length > prev.length) || newItems.length === maxChildrenCount || newItems.length === 0) {
				setIsCollapsed(true);
			}
			return isCollapsed ? newItems.slice(0, maxChildrenCount) : newItems;
		});
	}, [isCollapsed]);

	const visibleItemsToggle = useCallback(() => {
		if (isCollapsed) {
			setVisibleItems(items);
			setIsCollapsed(false);
			return;
		}

		recalculateVisibility(items);
		setIsCollapsed(true);
	}, [isCollapsed, items, recalculateVisibility]);

	useEffect(() => {
		if (wrapItems) {
			setVisibleItems(items);
			return;
		}

		recalculateVisibility(items);

		const mutationCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
			recalculateVisibility(items, observer);
		};
		const observer = new MutationObserver(mutationCallback);
		observer.observe(containerRef.current, {childList: true});

		return () => {
			observer.disconnect();
		};
	}, [items, recalculateVisibility, wrapItems]);

	return (
		<SelectableListUI>
			<SelectableListUI.GroupRoot type='multiple' value={selectedItems.map(id => String(id))}>
				<SelectableListUI.Group>
						<Wrapper columnView={Boolean(columnView)} ref={containerRef}>
							{visibleItems.map(({value, label, disabled}) => (
								<SelectableListItem
									value={String(value)}
									key={`${value}-${label}`}
									onClick={() => onClick?.(value)}
									variant='outlined'
									columnView={Boolean(columnView)}
									disabled={disabled}
								>
									<StyledText size='large' selected={!disabled && selectedItems.includes(value)}>{label}</StyledText>
									{onItemRemove &&
                    <Button.Icon onClick={() => onItemRemove(value)}>
                      <Close height={24} width={24}/>
                    </Button.Icon>
									}
								</SelectableListItem>
							))}
							{showMoreButton && Boolean(items.length) && !wrapItems &&
                <StyledButton ref={buttonRef} color='secondary' onClick={visibleItemsToggle}>
                  <Text size='medium' bold>
	                  {isCollapsed ? `${items.length - visibleItems.length} more..` : `Collapse`}
									</Text>
                </StyledButton>
							}
						</Wrapper>
				</SelectableListUI.Group>
			</SelectableListUI.GroupRoot>
		</SelectableListUI>
	);
}
