import {EntityType, SessionType, TestType} from '../../../../types';
import {Circle, SVG} from '../chart/index.styled';
import React, {useCallback, useState} from 'react';
import {Tooltip, isUndefined} from '@esgi/ui';
import {ChartTooltip} from '../chart-tooltip';
import {isNull} from 'underscore';
import {LineChartData} from '../chart/types';

type Props = {
	cx: number;
	cy: number;
	payload: LineChartData;
	entityType: EntityType,
	baselineId: number,
	openedTestSessionDetailsID: number,
	onOpenTestSessionDetailsAlert: (sessionID: number) => void,
	testType: TestType,
	isTablet: boolean,
}

export function ChartCustomDot({
	cx,
	cy,
	entityType,
	payload,
	openedTestSessionDetailsID,
	onOpenTestSessionDetailsAlert,
	testType,
	baselineId,
	isTablet,
}: Props) {
	const [openTooltip, setOpenTooltip] = useState<boolean>(false);

	const getDotColor = () => {
		const {sessionType, id} = payload;

		switch(true){
			case entityType === EntityType.ClassGroup: return 'primary';
			case baselineId === id: return 'baseline';
			case sessionType === SessionType.TestAll: return 'base';
			case sessionType === SessionType.TestIncorrect: return 'negative';
			default: return 'primary';
		}
	};

	const isDotHidden = isNull(payload.score) || isUndefined(payload.score);

	const onClick = useCallback(() => {
		if(isTablet) {
			setOpenTooltip(true);
		}
	}, [isTablet]);

	const isTooltipOpen = () => {
		if(openedTestSessionDetailsID || isDotHidden) {
			return false;
		}

		return isTablet ? openTooltip : undefined;
	};

	return (
		<Tooltip open={isTooltipOpen()} onOpenChange={setOpenTooltip} delayDuration={200}>
			<Tooltip.Trigger>
				<SVG
					x={cx - 5}
					y={cy - 5}
					hidden={isDotHidden}
					onClick={onClick}
				>
					<g transform='translate(5 5)'>
						<Circle r={5} color={getDotColor()} />
						<Circle r={3} />
					</g>
				</SVG>
			</Tooltip.Trigger>
			<Tooltip.Content side='right' css={{padding: 0, border: 'none', background: 'none'}}>
				<ChartTooltip
					testType={testType}
					payload={payload}
					onOpenTestSessionDetailsAlert={onOpenTestSessionDetailsAlert}
				/>
			</Tooltip.Content>
		</Tooltip>
	);
}