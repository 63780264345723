import {ContextMenu, Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {DownloadFunction, FileType} from '../../../../types';
import {ContextMenuItem} from './index.styled';

interface Props {
	onClick: DownloadFunction;
}

const menuItems = [
	{text: 'PDF', value: FileType.PDF},
	{text: 'Excel', value: FileType.Excel},
];

export function DownloadButton({onClick}: Props) {
	return (
		<ContextMenu.Root modal={true}>
			<ContextMenu.Trigger>
				<Button color='primary'>
					<Text size='medium' bold>
						Download
					</Text>
				</Button>
			</ContextMenu.Trigger>
			<ContextMenu.Content>
				<ContextMenu.Group>
					{menuItems.map(({text, value}) => (
						<ContextMenuItem
							key={value}
							onClick={() => onClick(value)}
						>
							<Text size='medium'>{text}</Text>
						</ContextMenuItem>
					))}
				</ContextMenu.Group>
			</ContextMenu.Content>
		</ContextMenu.Root>
	);
}
