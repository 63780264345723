import React, {useEffect} from 'react';
import {Report} from '@esgi/contracts/esgi/types/esgi.reports/endpoints/esgi.apigateway/student-progress-report/models/report';
import {StudentProgressReportService} from '@esgi/main/features/teacher/student-progress-report';
import StudentsTable from './components/table';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {GoogleAnalyticsTracker} from '@esgi/core/tracker';
import ReportSettings from './components/report-settings';
import {FileType} from '../types';
import {Reports} from '@esgi/main/kits/reports';
import {Text} from '@esgi/ui/typography';
import {FlexBox} from '@esgi/ui/layout';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {menuItems} from '../../../../student-detail/src/components/preview-dialog/constants';
import {AlertBody, OverlayScrollbarsComponentWrapper} from './index.styled';

type Props = {
	onClose: VoidFunction;
	service: StudentProgressReportService;
};

export function PreviewDialog(props: Props) {
	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, props.onClose);

	const report = useBehaviorSubject<Report>(props.service.report$);

	useEffect(() => {
		GoogleAnalyticsTracker.trackEvent('StudentProgressReport');
	}, []);

	return <Alert
		modalManagerRef={alertRef}
		colorConfig={alertColorsConfig.neutral}
		css={{
			'& [data-alert-content]': {
				maxHeight: 'calc(100% - 40px)',
				width: 'max-content',
				maxWidth: '100%',
				overflow: 'hidden',
				gridTemplateRows: 'auto auto 1fr auto',
			},
		}}
	>
		<Alert.Header withBacklight={false} onCloseIconClick={onClose}>
			<Text size='large'>Student Progress Report</Text>
		</Alert.Header>
		<ReportSettings service={props.service}/>
		<AlertBody>
			<OverlayScrollbarsComponentWrapper
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
			>
				<FlexBox direction='column' css={{overflowX: 'hidden'}}>
					{report !== null && <StudentsTable service={props.service} report={report}/>}
				</FlexBox>
				<ServiceLoader trackingService={props.service} fullscreen/>
			</OverlayScrollbarsComponentWrapper>
		</AlertBody>
		<Alert.Footer>
			<Reports.DownloadButton
				menuItems={menuItems}
				onClick={(value) => props.service.downloadPdf(value === FileType.Zip)}
			/>
		</Alert.Footer>
	</Alert>;
}
