import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {IEPProgressReportService} from '../../../../services';
import {TestResults} from '../tests-results';
import styles from '../../styles.module.less';

export function nl2br(text = '', isXhtml = false) {
	if (!(text || '')) {
		return '';
	}
	const breakTag = isXhtml ? '<br />' : '<br>';
	return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}


interface Props {
	service: IEPProgressReportService;
	reportData: Record<string, any>;
}

export const Content = (props: Props): JSX.Element => {
	const {service, reportData} = props;
	const data = useBehaviorSubject(service.reportData$);
	const dateRange = useBehaviorSubject(service.dateRange$);
	const testResultsExist = useBehaviorSubject(service.testResultsExist$);

	if (!(data || []).length) {
		return (
			<div className={styles.emptyTest}>
				{testResultsExist
					? 'The student has no test data for the specified date range'
					: 'There\'s no test data for the student'
				}
			</div>
		);
	}

	return (
		<>
			{data?.map((test) => (
				<dl key={test.testID}>
					<dt>Test Name</dt>
					<dd>
						<strong>{test?.name}</strong>
					</dd>
					<dt>IEP Goal</dt>
					<dd>{test.iepGoal?.goal ?? 'Goal is not set'}</dd>
					<dt>Status</dt>
					<dd>{test.iepGoal?.status ?? 'No status'}</dd>
					<dt>Results</dt>
					<dd>
						{test.testResults.length > 0
							? <TestResults
									test={test}
									dateRange={dateRange}
									markingPeriod={reportData.selectedMarkingPeriod}
								/>
							: 'No test results.'
						}
					</dd>
					<dt>Narrative /<br/>Benchmark</dt>
					<dd dangerouslySetInnerHTML={{__html: test.iepGoal?.benchmark ? nl2br(test.iepGoal.benchmark) : 'Benchmark is not set'}}></dd>
				</dl>
			))}
		</>
	);
};
