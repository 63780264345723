import {DrawerBody} from './components/drawer-body';
import {DrawerContentBlock} from './components/drawer-content-block';
import {DrawerHeader} from './components/drawer-header';
import {DrawerPanel} from './components/drawer-panel';
import {PanelContent} from './components/drawer-panel/components/panel-content';
import {PanelHeader} from './components/drawer-panel/components/panel-header';
import {PanelSidebar} from './components/drawer-panel/components/panel-sidebar';
import {DrawerRoot, drawerPaddingInline} from './components/drawer-root';
import {RefObject, useCallback, useRef} from 'react';
import {DrawerManager} from './types';

export * from './hooks';

export const Drawer = Object.assign(DrawerRoot, {
	Header: DrawerHeader,
	Body: DrawerBody,
	ContentBlock: DrawerContentBlock,
	Panel: DrawerPanel,
	PanelHeader: PanelHeader,
	PanelSidebar: PanelSidebar,
	PanelContent: PanelContent,
	drawerPaddingInline,
});

export function useDrawerRef() {
	return useRef<DrawerManager>(null);
}

export function useDrawerClose(drawerRef: RefObject<DrawerManager>, cb?: VoidFunction) {
	return useCallback(() => drawerRef.current?.close(cb), [drawerRef, cb]);
}
