import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {fileAdditionalInfo} from './upload-roster-step/constants';

type Props = {
	onAlertClose: VoidFunction;
	downloadTemplate: VoidFunction;
	validationData: string;
};

export function MissingRequiredInformation({onAlertClose, downloadTemplate, validationData}: Props) {
	return (
		<>
			<Alert.Header>
				<Text size='large'>Your File is Missing Required Information</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='medium'>
					Please make sure to include {fileAdditionalInfo[0]!.items.join(', ')}, or use{' '}
					<Button.Text onClick={downloadTemplate}>
						<Text size='medium' bold>
							our template
						</Text>
					</Button.Text>
				</Text>
				{validationData && <Text size='medium'>Errors: {validationData}</Text>}
			</Alert.Body>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
