import {ArrowRightIconProps} from '@esgillc/ui-kit/icons';

export const ArrowLeftIcon = (props: ArrowRightIconProps) => (
	<svg
		{...props}
		width='8'
		height='13'
		viewBox='0 0 8 13'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7.49594 11.09L2.91594 6.5L7.49594 1.91L6.08594 0.5L0.0859413 6.5L6.08594 12.5L7.49594 11.09Z'
			fill='#0088CC'
		/>
	</svg>
);
