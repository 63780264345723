import {RightArrowIcon} from '../icons/right-arrow-icon';
import styles from './styles.module.less';

interface Props {
	title: string;
	onClick: () => void;
}

export function SectionRow({title, onClick}: Props) {
	return (
		<>
			<div data-cy={'section-row'} className={styles.sectionRow} onClick={onClick}>
				<div data-cy={'section-row-title'} className={styles.sectionTitle}>{title}</div>
				<RightArrowIcon className={styles.arrowIcon}/>
			</div>
			<hr className={styles.line}/>
		</>
	);
}
