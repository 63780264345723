import React from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal, Alert} from '@esgillc/ui-kit/modal';
import styles from './new-version-available.module.less';

type Props = {
	onClosed: VoidFunction,
}

export function LoadErrorAlert(props: Props) {
	const modalManager = useModal();
	const onClose = useCloseModal(modalManager, props.onClosed);
	return <Alert className={styles.loadErrorAlert} modalManagerRef={modalManager} unsafeDesiredZIndex={11000}>
		<Alert.Body>
			A new version of ESGI is available. We need to reload the page to apply the changes. Thanks.
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={onClose}>OK</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}