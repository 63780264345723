import {Dispatch, useCallback, useEffect, useState} from 'react';
import {userStorage, UserType, useUser} from '@esgi/core/authentication';
import {HttpClient} from '@esgi/api';

export enum DesignVersion {
	Old = 'old',
	Redesign = 'redesign',
}

export function useDesignVersion(): [DesignVersion, Dispatch<DesignVersion>] {
	const user = useUser();
	const [designVersion, setDesignVersion] = useState<DesignVersion>(() => user?.showNewUIVersion ? DesignVersion.Redesign : DesignVersion.Old);

	useEffect(() => {
		if(user?.userID) {
			if(user?.showNewUIVersion) {
				setDesignVersion(DesignVersion.Redesign);
			} else {
				setDesignVersion(DesignVersion.Old);
			}
		}
	}, [user?.userID]);

	const setDesignVersionWrapper = useCallback((state: DesignVersion) => {
		const data = new FormData();
		data.append('value', String(state === DesignVersion.Redesign));

		if(user?.userType === UserType.T) {
			HttpClient.default.ESGIApi.sendBeacon('v2/teachers', 'switch-ui', data);
		}

		setDesignVersion(state);
		userStorage.update({showNewUIVersion: state === DesignVersion.Redesign});
	}, [user?.userType]);

	return [designVersion, setDesignVersionWrapper];
}