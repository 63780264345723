import {SkeletonShape} from '@esgi/main/kits/common';
import {SkeletonBox} from './index.styled';

export function StudentSelectionPanelSkeleton() {
	return (
		<>
			<SkeletonShape width='100%' height={40} />
			<SkeletonShape width='100%' height={40} />
			<SkeletonBox>
				{new Array(5).fill(null).map((_, index) => (
					<SkeletonShape width='100%' height={40} key={index} />
				))}
			</SkeletonBox>
		</>
	);
}
