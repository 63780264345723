import {RoutesMap} from '@esgi/main/libs/core';

export class PurchaseContinueTemplate {
	public static Render() {
		return <div className='registration-modal'>
            <div className='inner-modal'>
                <div className='fields'>
                    <div className='purchase-complete-content'>
                        <div className='main-title-container'>
                            <div className='main-title'><b>Buy Now</b></div>
                        </div>
                        <div className='col-xs-12 col-md-12 account-info'>
                            <div className='title-container'>
                                <div className='title'><b>Create Account</b></div>
                            </div>
                            <div>
                                <div className='trial-content'>
                                    <div data-bind='render:pricingForm'/>
                                    <div data-bind='render:email'/>

                                    <div data-bind="css: {'show': email.validationResult().isExist}"
                                         className='exist-validation-message'>
                                        An account with this email already exists. Do you want to <a
                                        href='/login/'>login</a> to your existing account?
                                    </div>

                                    <div data-bind='render:username'/>
                                    <div data-bind='render:password' />

                                    <div className='title-container'>
                                        <div className='title'><b>Billing Information</b></div>
                                    </div>
                                    <div data-bind='render:stripeForm'/>

                                    <div className='col-xs-12 col-md-12'>
                                        <div className='terms-policy'>
                                                <span>
                                                    By creating an account, you are agreeing to our&nbsp;
																										<a href={RoutesMap.esgiAgreement}>
																											Terms of Use
																										</a>
                                                    &nbsp;and&nbsp;
																										<a href={RoutesMap.privacyPolicy}>
																											Privacy Policy
																										</a>.
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer'>
                    <div className='actions row'>
                        <div className='col-sm-2 col-md-7 hidden-xs'/>
                        <div className='col-xs-12 col-sm-10 col-md-5 actions-container'>
                            <button className='btn cancel-btn' data-bind='click:eventTriggers.back'>BACK</button>
                            <button className='btn next-btn' data-bind='enable: !userCreating(), click:eventTriggers.next'>COMPLETE PURCHASE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
	}
}
