import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './icon.module.less';

interface Props {
	isStarred: boolean;
	onClick: () => void;
	scale?: number;
}

class State {
	hovered: boolean;
}

export class StarButton extends React.PureComponent<Props, State> {
	public state = new State();

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if(this.props.isStarred !== prevProps.isStarred) {
			this.setState({hovered: false});
		}
	}

	public render() {
		return <button className={styles.button} onClick={this.props.onClick}
		               onMouseOver={() => this.setState({hovered: true})}
		               onMouseLeave={() => this.setState({hovered: false})}>
			<OnHoverTooltip message={this.props.isStarred ? 'Remove as Favorite' : 'Add as Favorite'}>
				<svg xmlns='http://www.w3.org/2000/svg' width={((this.props.scale || 1) * 100) + '%'}
				     height={((this.props.scale || 1) * 100) + '%'} viewBox='0 0 14 14'
				     fill='none'>
					<path
						d='M8 1.61803L9.32058 5.68237L9.43284 6.02786H9.79611H14.0696L10.6123 8.53976L10.3184 8.75329L10.4306 9.09878L11.7512 13.1631L8.29389 10.6512L8 10.4377L7.70611 10.6512L4.24877 13.1631L5.56936 9.09878L5.68162 8.75329L5.38772 8.53976L1.93039 6.02786H6.20389H6.56716L6.67942 5.68237L8 1.61803Z'
						stroke='#424242' fill={(this.props.isStarred && !this.state.hovered || !this.props.isStarred && this.state.hovered) ? '#4F4F4F' : '#FFFFFF'}/>
				</svg>
			</OnHoverTooltip>
		</button>;
	}
}
