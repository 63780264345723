import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useService} from '@esgi/core/service';
import {DrawerMode} from '@esgi/main/features/teacher/home';
import {Button, useBehaviorSubject} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {FlexBox} from '@esgi/ui/layout';
import {ConflictResolutionService} from './service';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ConflictTable} from './components/conflict-table';
import {ServerConflictStudentModel, ValidationStatus} from '../../types';
import {SaveConflictStudentsResponse} from './types';
import {TeacherDrawerName} from '../../../types';
import {AlertBody} from './index.styled';

type Props = {
	classId: string;
	conflictedStudents: ServerConflictStudentModel[] | null;
	onFileUploadIsComplete: (createdStudents: CreatedStudents[]) => void;
	onCancel: VoidFunction;
	onDeleteFileClicked: VoidFunction;
};

interface CreatedStudents {
	id: number,
	firstName: string,
	lastName: string,
	gradeLevelID: number,
	studentIDN: string,
	languageID: number,
}

export function ConflictResolutionStep({
	classId,
	conflictedStudents,
	onDeleteFileClicked,
	onFileUploadIsComplete,
	onCancel,
}: Props) {

	const alertRef = Alert.useRef();
	const handelClose = Alert.useClose(alertRef, onCancel);

	const navigate = useNavigate();
	const service = useService(ConflictResolutionService);
	const students = useBehaviorSubject(service.conflictStudents);
	const initialState = useBehaviorSubject(service.initialState);

	const [isUploadButtonDisabled, setUploadButtonDisabled] = useState(true);

	useEffect(() => {
		service.init(conflictedStudents, classId).subscribe();
	}, []);

	useEffect(() => {
		setUploadButtonDisabled(service.validate());
	}, [students]);

	const handleFileUploadCompletion = (res: SaveConflictStudentsResponse) => {
		setUploadButtonDisabled(true);

		if (res.validation.status === ValidationStatus[ValidationStatus.Complete] ||
			res.validation.status === ValidationStatus[ValidationStatus.CompleteWithStudentsExistInClass]) {
			onFileUploadIsComplete(res.createdStudents);
		}
	};

	const onUpload = () => {
		service.upload().subscribe((res) => handleFileUploadCompletion(res));
	};

	const onViewExistingStudentProfile = (studentID: string) => {
		const studentProfileDrawerUrl = new URLSearchParams({
			drawerMode: DrawerMode.Edit,
			drawerName: TeacherDrawerName.TeacherStudentViaProfile,
			studentId: studentID.toString(),
		});

		navigate(`/home?${studentProfileDrawerUrl.toString()}`);
	};

	return <Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative}>
		<Alert.Header data-cy='conflicting-records-header' title='Conflict records' onCloseIconClick={handelClose}>
			<Text size='large' color='negative' bold>Conflicting record</Text>
		</Alert.Header>

		<AlertBody>
			<OverlayScrollbarsComponent defer style={{height: 'calc(100% + 0px)'}}
										options={{scrollbars: {autoHide: 'leave'}}}>
				<FlexBox direction='column'>
					<Text data-cy='conflict-explanation' size='medium' color='neutral40'>There are some older records with conflicting data,
						please resolve the issue, ignore or remove the entry you are trying to add</Text>
					<ConflictTable
						conflictedStudents={students}
						initialState={initialState}
						onEdit={(data) => service.edit(data)}
						onViewExistingStudentProfile={onViewExistingStudentProfile}
					/>
				</FlexBox>
			</OverlayScrollbarsComponent>
		</AlertBody>

		<Alert.Footer>
			<Button data-cy='abort-upload-button' color='tertiary' onClick={onDeleteFileClicked}>
				<Text size='medium' bold color='base'>Abort Upload</Text>
			</Button>
			<Button data-cy='proceed-with-upload-button' color='secondary' onClick={onUpload} disabled={!isUploadButtonDisabled}>
				<Text size='medium' bold color='base'>Upload</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}
