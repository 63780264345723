import {StudentTabId} from './types';
import {useTabs, useSelections} from './hooks';
import {StudentList} from './components/student-list';
import {ClassList} from './components/class-list';
import {GroupList} from './components/group-list';
import {Tabs, ExpandablePanelContentWrapper} from '@esgi/main/features/teacher/home';
import {Title} from './index.styled';
import {Class, Group, Student} from '@esgi/main/libs/store';
import React, {Dispatch, useCallback, useEffect} from 'react';

type Props = {
	classes: Class[];
	groups: Group[];
	students: Student[];
	onChange: (value: Student['id'][]) => void;
	onActiveTabChange?: Dispatch<StudentTabId>;
};

export function StudentsControl({classes, groups, students, onChange, onActiveTabChange}: Props) {
	const {activeTab, setActiveTab, tabItems} = useTabs();
	const {selectedStudentIDs, setSelectedStudentIDs, toggleStudent} = useSelections(onChange);

	useEffect(() => {
		setSelectedStudentIDs(students.map(({id}) => id));
	}, []);

	const handleActiveTabChanged = useCallback(
		(value: StudentTabId) => {
			setActiveTab(value);
			onActiveTabChange?.(value);
		}, [onActiveTabChange]
	);

	const getTabContent = (): React.JSX.Element => {
		if (activeTab === StudentTabId.Classes) {
			return (
				<ClassList
					classes={classes}
					students={students}
					selectedStudentsIDs={selectedStudentIDs}
					toggleStudent={toggleStudent}
					setSelectedStudentIDs={setSelectedStudentIDs}
				/>
			);
		}

		if (activeTab === StudentTabId.Groups) {
			return (
				<GroupList
					classes={classes}
					groups={groups}
					students={students}
					selectedStudentsIDs={selectedStudentIDs}
					toggleStudent={toggleStudent}
					setSelectedStudentIDs={setSelectedStudentIDs}
				/>
			);
		}

		if (activeTab === StudentTabId.Students) {
			return <StudentList students={students} selectedStudentsIDs={selectedStudentIDs} toggleStudent={toggleStudent} />;
		}

		return <></>;
	};

	return (
		<>
			<Title data-cy='select-students-title' size='small'>Select Students</Title>
			<ExpandablePanelContentWrapper>
				<Tabs<StudentTabId> dataCy='select-student-tabs' activeTab={activeTab} setActiveTab={handleActiveTabChanged} tabItems={tabItems} />
				{getTabContent()}
			</ExpandablePanelContentWrapper>
		</>
	);
}
