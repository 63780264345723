import {SelectableList as SelectableListUI} from '@esgi/ui/layout';
import {Student} from '@esgi/main/libs/store';
import {Dispatch} from 'react';
import {StudentInfo} from './components/student-info';
import {SelectableListItem} from './index.styled';

type Props = {
	students: Student[];
	selectedStudentsIDs: Student['id'][];
	onStudentClick: Dispatch<Student['id']>;
};

export function SelectableList({onStudentClick, selectedStudentsIDs, students}: Props) {
	return (
		<SelectableListUI>
			<SelectableListUI.GroupRoot type='multiple' value={selectedStudentsIDs.map(String)}>
				<SelectableListUI.Group>
					{students.map(({firstName, lastName, id, hasCredentials}) => (
						<SelectableListItem value={String(id)} key={id} onClick={() => onStudentClick(id)}>
							<StudentInfo firstName={firstName} lastName={lastName} hasCredentials={hasCredentials} />
						</SelectableListItem>
					))}
				</SelectableListUI.Group>
			</SelectableListUI.GroupRoot>
		</SelectableListUI>
	);
}
