import {useStudentDetailService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgi/ui';
import {Tests} from '../tests';
import {AlertBody as Body, Page, Student} from './index.styled';
import {groupTestByTwo} from '../../utils';
import {Text} from '@esgi/ui/typography';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {isNull} from 'underscore';
import {ServiceLoader} from '@esgi/deprecated/ui-kit/loader';

export function AlertBody() {
	const service = useStudentDetailService();
	const report = useBehaviorSubject(service.report$);
	const reportSettings = useBehaviorSubject(service.reportSettings$);
	const markingPeriods = useBehaviorSubject(service.markingPeriods$);
	const testResultsCorrectVerbiage = useBehaviorSubject(service.testResultsCorrectVerbiage$);
	const testResultsIncorrectVerbiage = useBehaviorSubject(service.testResultsIncorrectVerbiage$);

	return (
		<Body>
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
				events={{
					initialized: (instance) => {
						const {content} = instance.elements();
						content.scrollTo(0, 500);
						setTimeout(() => {
							content.scrollTo(0, 0);
						}, 200);
					},
				}}
			>
				{!isNull(report) && <>
					{report.students.length === 0 && <Text>There's no data for the student(s)</Text>}
					{report.students.map((student) => (
						<Page data-cy='student-page' key={student.studentID}>
							<Student>
								<Text data-cy='student-name'>{`${student.firstName || ''} ${student.lastName || ''}`}</Text>
							</Student>
							{groupTestByTwo(student.tests).map((tests, index) => (
								<Tests
									data-cy='tests'
									key={`${student.studentID}-${index}`}
									tests={tests}
									report={report}
									reportSettings={reportSettings}
									markingPeriods={markingPeriods}
									testResultsCorrectVerbiage={testResultsCorrectVerbiage}
									testResultsIncorrectVerbiage={testResultsIncorrectVerbiage}
									gradeScaleAllow={Boolean(report.gradeValues.length)}
								/>
							))}
						</Page>
					))}
				</>}
				<ServiceLoader trackingService={service} fullscreen/>
			</OverlayScrollbarsComponent>
		</Body>
	);
}
