import {Step, ValidStudentModel} from 'shared/modules/student-roster-upload-tool/types';

export namespace StudentRosterToolEvents {
	export function Open(classID: number, initialStep: Step): Open.Args {
		return {
			classID,
			initialStep,
		};
	}

	export module Open {
		export interface Args {
			classID: number;
			initialStep: Step;
			top?: number;
			left?: number;
		}
	}

	export function Uploaded(students: ValidStudentModel[], schoolID: number, teacherID: number, classID: number, districtID: number, specialistGroupID?: number): Uploaded.Args {
		return {
			students,
			schoolID,
			teacherID,
			classID,
			districtID,
			specialistGroupID,
		};
	}

	export module Uploaded {
		export interface Args {
			students: ValidStudentModel[],
			specialistGroupID?: number,
			schoolID: number,
			teacherID: number,
			classID: number,
			districtID: number,
		}
	}

	export function AddStudentClicked() {
	}


	export function ShowValidation() {
	}

	export function HideValidation() {
	}

	export function MoveToErrorRow(studentID: number): MoveToErrorRow.Args {
		return {
			studentID,
		};
	}

	export module MoveToErrorRow {
		export interface Args {
			studentID: number,
		}
	}
}
