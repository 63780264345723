import React, {Suspense} from 'react';
import TestDetails from 'shared/modules/test-details/test-details';
import {UserInfo} from '@esgi/core/authentication';
import {SubjectItem} from '../../../types/types';
import {ViewMode} from 'shared/modules/test-details/models';

interface Props {
	testDetailsId: number;
	currentUser: Readonly<UserInfo>
	onClose: () => void;
	subjectsForModal: SubjectItem[]

}

export function TestDetailsModal({testDetailsId, subjectsForModal, onClose, currentUser}: Props) {
	return <Suspense fallback={<div/>}>
		<TestDetails
			testID={testDetailsId}
			firstName={currentUser.firstName}
			lastName={currentUser.lastName}
			userID={currentUser.userID}
			subjectsForModal={subjectsForModal}
			close={onClose}
			viewMode={ViewMode.SelfAssess}
		/>
	</Suspense>;
}
