import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {RadioGroup} from '@esgi/ui/controls';
import {SettingsPanel} from '@esgi/main/kits/reports';

export const Wrapper = styled(Box, {
	padding: '8px',
	border: '1px solid $border',
	backgroundColor: '$vivid',
	borderRadius: '8px',
	marginTop: '8px',
});

export const SettingsPanelTitle = styled(SettingsPanel.Title, {
	display: 'grid',
	gridAutoFlow: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '$1',
	textAlign: 'unset',
});

export const Link = styled('a', {
	lineHeight: 0,
	width: 16,
	height: 16,

	'& svg': {
		width: '100%',
		height: '100%',
	},
});

export const Radiobox = styled(RadioGroup.Item, {
	'& + label': {
		marginBottom: 0,
	},
});
