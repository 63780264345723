import React from 'react';
import {BodyProps, Modal} from '@esgillc/ui-kit/modal';
import {join} from '@esgillc/ui-kit/utils';
import styles from './report-modal.module.less';

export class ReportModalBody extends React.PureComponent<BodyProps> {
	public render() {
		return <Modal.Body className={join(styles.body, this.props.className)}>
			{this.props.children}
		</Modal.Body>;
	}
}
