import {Checkbox as CheckboxBase} from '@esgi/main/kits/assignments';
import {Alert} from '@esgi/ui/alert';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Link as ExternalLink} from 'react-router-dom';

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$4',
});

export const Link = styled(ExternalLink, {
	textDecoration: 'underline',
});

export const DistrictCheap = styled(Box, {
	display: 'inline-flex',
	width: 'max-content',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	padding: 5,
	borderColor: '$primaryMuted',
	backgroundColor: '$primaryBackground',
});

export const Checkbox = styled(CheckboxBase, {
	marginLeft: -4,
});
