import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import moment from 'moment';

type Props = Omit<VariantProps<typeof Text>, 'children'> & {
	date: Date | string;
	/**
	 * @see https://momentjs.com/docs/#/displaying/format/
	 */
	dateFormat: string;
};

export function DateFormatText({date, dateFormat, ...props}: Props) {
	const dateFormated = moment(date).format(dateFormat);

	return <Text data-cy='formatted-date' {...props}>{dateFormated}</Text>;
}
