import React from 'react';
import GradeRangeEntry
	from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-entry/component';
import {
	IGradeRangeForm,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/models';
import _ from 'underscore';
import {
	GradeRangeEditorService,
} from 'shared/modules/grade-scale/grade-range/entries-panel/editor/grade-range-editor/service';

export class Props {
	service: GradeRangeEditorService;
}

export class State{
	form: IGradeRangeForm[] = [];
	validationVisibilityStatus: boolean;
}

export default class GradeRangeEditor extends React.PureComponent<Props, State> {
	ref: HTMLDivElement;
	
	constructor(props) {
		super(props);
		this.state = new State();
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.service.editorID === this.props.service.editorID) {
			return;
		}
		
		this.subscribe();
	}
	
	subscribe() {
		this.props.service.editor$.subscribe((form) => {
			this.setState({form: [...form]});
		});

		this.props.service.validationVisibilityStatus$.subscribe((show) => {
			this.setState({validationVisibilityStatus: show});
		});
	}
	
	componentDidMount() {
		this.subscribe();
	}
	
	render() {
		return <div className='grade-range-editor-component'>
			{this.state.form.map((gradeRange, index) => {
				return <GradeRangeEntry
					key={gradeRange.gradeScaleEntryID + '_' + gradeRange.subjectID + '_' + gradeRange.testID}
					showValidation={this.state.validationVisibilityStatus}
					first={index === 0}
					last={index + 1 === this.state.form.length}
					gradeRange={gradeRange}
					service={this.props.service}
				/>;
			})}
		</div>;
	}
}