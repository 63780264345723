import React from 'react';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {
	EntityModel,
	NamingWritingPrintSettings,
	SightWordsPrintSettings,
	TemplateType,
	UnitType,
} from '../../shared/types';
import {Modal} from '@esgillc/ui-kit/modal';
import styles from './intro-step.module.less';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {DataService} from './data-service';
import TemplateSelector from './template-selector/template-selector';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SelectedSnapshot,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {ReportGeneratorService as NameWritingReportGeneratorService} from 'modules/reports/writing-practice/shared/templates/name-writing/report-generator-service';
import {ReportGeneratorService as SightWordsReportGeneratorService} from 'modules/reports/writing-practice/shared/templates/sight-words/report-generator-service';
import TemplatePreview from 'modules/reports/writing-practice/shared/components/template-preview/template-preview';
import {NameWritingReport} from 'modules/reports/writing-practice/shared/templates/name-writing/types';
import {NameWritingTemplate} from 'modules/reports/writing-practice/shared/templates/name-writing/name-writing-template';
import {SightWordsReport} from 'modules/reports/writing-practice/shared/templates/sight-words/types';
import {SightWordsTemplate} from 'modules/reports/writing-practice/shared/templates/sight-words/sight-words-template';
import {userStorage} from '@esgi/core/authentication';

interface Props {
	hierarchy: HierarchyInstance;
	templateSelected: (type: TemplateType) => void;
}

class State {
	nwAvailable: boolean = false;
	swAvailable: boolean = false;
	dataLoaded: boolean = false;
	nwReport: NameWritingReport;
	swReport: SightWordsReport;
}

export default class IntroStep extends React.PureComponent<Props, State> {
	private readonly introService = new DataService();
	private readonly nameWritingReportGen = new NameWritingReportGeneratorService();
	private readonly sightWordsReportGen = new SightWordsReportGeneratorService();
	public override readonly state = new State();
	private hierarchy: HierarchyInstance;
	private selected: SelectedSnapshot;

	componentDidMount() {
		this.introService.init(this.props.hierarchy.snapshot).subscribe(response => {
			this.setState({
				nwAvailable: response.isNameWritingAvailable,
				swAvailable: response.isSightWordsAvailable,
				dataLoaded: true,
			});
		});
		this.hierarchy = this.props.hierarchy;
		this.selected = new SelectedSnapshot(this.hierarchy);
		this.generateNwReport();
		this.generateSwReport();
	}

	componentWillUnmount() {
		this.introService.destroy();
	}

	render() {
		return <>
			<ServiceLoader trackingService={this.introService}/>
			<Modal.Body className={styles.body}>
				<FlexBox align='center' justify='center' direction='column'>
					<div>
						<p className={styles.bodyTitle}>Create Writing Templates for Your Students to Practice With</p>
						<p className={styles.bodySubTitle}>Select a template to get started</p>
					</div>
					<FlexBox direction='row' align='center' justify='center' wrap='wrap'>
						<TemplateSelector available={this.state.nwAvailable} buttonTitle='Name Writing' selected={() => this.props.templateSelected(TemplateType.NameWriting)}>
							{
								 this.state.dataLoaded ? this.state.nwAvailable
									 ? this.renderNwPreview()
									 : <span>No students<br/>available</span>
									 : <span/>
							}
						</TemplateSelector>
						<TemplateSelector available={this.state.swAvailable} buttonTitle='Sight Words' selected={() => this.props.templateSelected(TemplateType.SightWords)}>
							{
								this.state.dataLoaded ? this.state.swAvailable
									? this.renderSwPreview()
									: <span>No tests<br/>available</span>
									: <span/>
							}
						</TemplateSelector>
					</FlexBox>
				</FlexBox>
			</Modal.Body>
		</>;
	}

	private generateNwReport(){
		const unitData = this.getUnitData();
		this.nameWritingReportGen.generateReport(unitData, new NamingWritingPrintSettings())
			.subscribe(result => {
				this.setState({nwReport: result});
			});
	}

	private renderNwPreview() {
		return <TemplatePreview className={styles.preview}>
			{
				this.state.nwReport &&
				<NameWritingTemplate
									 details={this.state.nwReport.details}
									 student={this.state.nwReport.students[0]}/>
			}
		</TemplatePreview>;
	}
	
	private generateSwReport(){
		const unitData = this.getUnitData();
		const questions = [
			{questionID: 1, name: 'ask', orderNum: 1, pregenerated: true},
			{questionID: 2, name: 'write', orderNum: 2, pregenerated: true},
			{questionID: 3, name: 'go', orderNum: 3, pregenerated: true},
			{questionID: 4, name: 'two', orderNum: 4, pregenerated: true},
			{questionID: 5, name: 'people', orderNum: 5, pregenerated: true},
			{questionID: 6, name: 'no', orderNum: 6, pregenerated: true},
			{questionID: 7, name: 'could', orderNum: 7, pregenerated: true},
			{questionID: 8, name: 'way', orderNum: 8, pregenerated: true},
			{questionID: 9, name: 'more', orderNum: 9, pregenerated: true},
		];
		const settings = new SightWordsPrintSettings();
		settings.allQuestions = questions;
		settings.selectedQuestions = questions;
		this.sightWordsReportGen.generateReport(unitData, settings)
			.subscribe(result => {
				this.setState({swReport: result});
			});
	}

	private renderSwPreview() {
		return <TemplatePreview className={styles.preview}>
			{
				this.state.swReport &&
				<SightWordsTemplate
					details={this.state.swReport.details}
					student={this.state.swReport.students[0]}
					words={this.state.swReport.words}/>
			}
		</TemplatePreview>;
	}

	private getUnitData(){
		const teacher = this.getTeacher();
		const lecturer = {firstName: teacher.firstName, lastName: teacher.lastName, title: teacher.title};
		const unitType = this.unitType();
		const students = this.students();
		return {classId: this.selected.classID, groupId: null, lecturer, unitType, students};
	}

	private students(){
		const studentUnit = {} as EntityModel;
		let student;
		if (this.hierarchy.mode === HierarchyMode.Classic){
			if (this.hierarchy.classic.selected.level === ClassicHierarchyLevel.Class){
				studentUnit.name = this.hierarchy.classes.filter(c => c.classID === this.selected.classID)[0].name;
				student = this.hierarchy.students.find(s => s.classes.find(c => c === this.selected.classID));
			}
			if (this.hierarchy.classic.selected.level === ClassicHierarchyLevel.Group){
				studentUnit.name = this.hierarchy.groups.filter(c => c.groupID === this.selected.groupID)[0].name;
				student = this.hierarchy.students.find(s => s.groups.find(c => c === this.selected.groupID));
			}
			if (this.hierarchy.classic.selected.level === ClassicHierarchyLevel.Student){
				student = this.hierarchy.students.find(s => s.studentID === this.selected.studentID);
			}
		}
		if (this.hierarchy.mode === HierarchyMode.Specialist){
			if (this.hierarchy.specialist.selected.level === SpecialistHierarchyLevel.Group){
				studentUnit.name = this.hierarchy.specialistGroups.filter(c => c.groupID === this.selected.specialistGroupID)[0].name;
				student = this.hierarchy.students.find(s => s.specialistGroups.find(c => c === this.selected.specialistGroupID));
			}
			if (this.hierarchy.specialist.selected.level === SpecialistHierarchyLevel.Student){
				student = this.hierarchy.students.find(s => s.specialistGroups.find(c => c === this.selected.specialistGroupID));
			}
		}

		if (student == null) {
			student = {studentID: 0, firstName: 'Jeannine', lastName: 'Yancy'};
		}

		return [{studentId: student.studentID, firstName: student.firstName, lastName: student.lastName, units: [studentUnit]}];
	}

	private getTeacher(){
		if (this.hierarchy.mode === HierarchyMode.Specialist){
			const user = userStorage.get();
			return {firstName: user.firstName, lastName: user.lastName, title: this.hierarchy.title};
		} else {
			return this.hierarchy.teachers.find(t => t.userID === this.selected.teacherID);
		}
	}

	private unitType(){
		if (this.hierarchy.mode === HierarchyMode.Classic){
			if (this.hierarchy.classic.selected.level === ClassicHierarchyLevel.Class){
				return UnitType.Class;
			}
			if (this.hierarchy.classic.selected.level === ClassicHierarchyLevel.Group){
				return UnitType.Group;
			}
		}
		if (this.hierarchy.mode === HierarchyMode.Specialist){
			return UnitType.Group;
		}
	}
}
