import {TestTypeLabel} from '@esgi/main/kits/reports';
import {TestItem} from '@esgi/main/features/untested-students-report';
import {
	HeaderLabel,
	SelectableListTriggerContent as SelectableListTriggerContentBox,
	TestColorIndicator,
} from '../../../../../index.styled';
import {TestType} from '@esgi/main/libs/core';

type Props = {
	testID: TestItem['id'];
	testName: string;
	testType: TestType;
};

export function SelectableListTriggerContent({testID, testName, testType}: Props) {
	return (
		<SelectableListTriggerContentBox>
			<TestColorIndicator dataCy='test-color-indicator' testID={testID}/>
			<HeaderLabel data-cy='test-header-label' size='large'>{testName}</HeaderLabel>
			<TestTypeLabel data-cy='test-type-label' testType={testType}/>
		</SelectableListTriggerContentBox>
	);
}
