import {Student} from '@esgi/main/libs/store';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {RefObject, useCallback, useState} from 'react';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {Eye} from '@esgi/ui/icons';
import {AlertHeader, HeaderDivider, AllToggleVisiblePasswordsButton} from './index.styled';
import {StudentCredentials} from './components/student-credentials';
import {AlertBody, alertRootCss} from '../components/components.styled';
import {AlertFooter} from '../components/alert-footer';
import {useComponentState} from './use-component-state';
import {StudentCredentialsSkeleton} from './components/student-credentials-skeleton';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = {
	studentIds: Student['id'][];
	onAlertClose: VoidFunction;
	snackbarRef: RefObject<SnackbarManager>;
};

export function MultipleStudentsCredentialsAlert({studentIds, onAlertClose, snackbarRef}: Props) {
	const studentCredentialsAlertRef = Alert.useRef();
	const closeStudentCredentialsAlert = Alert.useClose(studentCredentialsAlertRef, onAlertClose);

	const [isUnsavedChangesConfirmation, setIsUnsavedChangesConfirmation] = useState(false);

	const {
		isAllCredentialsValid,
		isAllPasswordsOpened,
		isStudentCredentialsTouched,
		skeleton,
		studentsCredentialsState,
		studentCredentialsService,
		toggleAllPasswordHidden,
		resetAllCredentials,
		onSaveAllUserCredentialsClick,
		isCredentialsSaving,
		updateStudentInState,
		onValidateUsername,
	} = useComponentState({
		closeStudentCredentialsAlert,
		snackbarRef,
		studentIds,
	});

	const handleStudentCredentialsAlertClose = () => {
		if (isStudentCredentialsTouched) {
			setIsUnsavedChangesConfirmation(true);
			return;
		}

		closeStudentCredentialsAlert();
	};

	const onCloseUnsavedChangesConfirmation = useCallback(() => {
		setIsUnsavedChangesConfirmation(false);
	}, []);

	return (
		<>
			<Alert modalManagerRef={studentCredentialsAlertRef} css={alertRootCss} dataCy='student-credentials-alert'>
				<AlertHeader withBacklight={false} onCloseIconClick={handleStudentCredentialsAlertClose}>
					<GridBox flow='column' align='center' justify='between'>
						<Text size='small' color='base'>
							Edit Students Credentials
						</Text>
						<GridBox flow='column' gap='3' align='center'>
							<AllToggleVisiblePasswordsButton
								withBackgroundHover
								skeleton={skeleton}
								disabled={skeleton}
								isAllPasswordsOpened={isAllPasswordsOpened}
								onClick={toggleAllPasswordHidden}
								dataCy='show-all-passwords'
							>
								<Eye />
							</AllToggleVisiblePasswordsButton>
							<HeaderDivider />
						</GridBox>
					</GridBox>
				</AlertHeader>
				<AlertBody>
					{skeleton ? (
						<StudentCredentialsSkeleton />
					) : (
						<OverlayScrollbarsComponent
							defer
							options={{
								scrollbars: {autoHide: 'leave'},
								paddingAbsolute: true,
							}}
						>
							<GridBox gap='3'>
								{Object.entries(studentsCredentialsState).map(([studentId, studentState]) => (
									<StudentCredentials
										studentId={Number(studentId)}
										studentState={studentState}
										onValidatePassword={studentCredentialsService.validatePassword.bind(studentCredentialsService)}
										onValidateUsername={onValidateUsername}
										updateStudentInState={updateStudentInState}
										isAllCredentialsValid={isAllCredentialsValid}
										isStudentCredentialsTouched={isStudentCredentialsTouched}
										key={studentId}
									/>
								))}
							</GridBox>
						</OverlayScrollbarsComponent>
					)}
				</AlertBody>
				<AlertFooter
					skeleton={skeleton}
					isCredentialsTouched={isStudentCredentialsTouched}
					isCredentialsValid={isAllCredentialsValid}
					onSaveUserCredentials={onSaveAllUserCredentialsClick}
					resetCredentials={resetAllCredentials}
					isCredentialsSaving={isCredentialsSaving}
				/>
			</Alert>

			{isUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation
					onClose={onCloseUnsavedChangesConfirmation}
					onCloseAnyway={closeStudentCredentialsAlert}
				/>
			)}
		</>
	);
}
