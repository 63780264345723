import {Modal, ModalBody, ModalFooter} from '@esgi/deprecated/react';
import {Transparent} from '@esgi/deprecated/elements/buttons/transparent';
import './component.less';

export interface Props {
	onOKClicked: () => void;
	onBackClicked: () => void;
}

export const MoreLevelsConfirmationModal = (props: Props) => (
	<Modal
		animate={true}
		className='more-levels-confirmation-modal white-header responsive alert-modal-white'
	>
		<ModalBody>
			<p>You are creating a grade scale that contains more levels than some tests have questions.</p>
			<p>This will prevent you from setting up a custom range for the affected tests.</p>
		</ModalBody>
		<ModalFooter>
			<Transparent
				onClick={props.onOKClicked}
				title='OK'
			/>
			<Transparent
				onClick={props.onBackClicked}
				title='Go Back'
			/>
		</ModalFooter>
	</Modal>
);
