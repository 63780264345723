import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const LayoutContainer = styled(FlexBox, {
	direction: 'row',
	alignItems: 'flex-start',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
});
