import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';

import styles from '../rubric-card.module.less';

export default class RubricThumbnail extends React.PureComponent<{ onClick: () => void }> {
	public render() {
		return <OnHoverTooltip message='Show Preview'>
			<div className={styles.thumbnail} onClick={() => this.props.onClick()}>
				<img src='https://esgiwebfiles.s3.amazonaws.com/images/test-explorer/rubric-thumbnail.png' alt='image'
				     width={192} height={120}/>
			</div>
		</OnHoverTooltip>;
	}
}
