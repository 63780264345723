import {SkeletonContainer, SkeletonRow} from './index.styled';

interface Props {
	numberOfRows?: number;
}

export function Skeleton({numberOfRows = 10}: Props) {
	const array = new Array(numberOfRows).fill('');

	return (
		<SkeletonContainer>
			{array.map((_, index) => (
				<SkeletonRow
					key={index}
					css={{width: index < 5 ? `${(index + 1) * 10}%` : '100%'}}
				/>
			))}
		</SkeletonContainer>
	);
}
