import React from 'react';
import styles from './assign-tests-subject-name.module.less';


interface Props {
	subjectName: string;
}
 
export default class AssignTestsSubjectName extends React.Component<Props> {
	render() { 
		return (
			<div className={styles.container}>
				<span>Assign Self-Assess Tests</span>
				<span className={styles.subjectTabName}>Subject Tab: {this.props.subjectName}</span>
			</div>
		);
	}
}