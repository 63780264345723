import {Panel} from '@esgi/main/kits/common';
import {useMemo, useState} from 'react';

import {Text} from '@esgi/ui/typography';

import {ClassesList} from './components/classes-list';
import {GroupsList} from './components/groups-list';
import {StudentsList} from './components/students-list';
import {StudentsPanel, StudentsPanelTabID, MultiSelectState, Entities} from '@esgi/main/features/teacher/students-panel';
import {useClasses, useGroups, useStudents} from '@esgi/main/libs/store';

export function MultipleStudentsLevelStudentsPanel() {
	const [selected, setSelected] = useState<MultiSelectState>({
		studentIDs: [],
		classIDs: [],
		groupIDs: [],
	});

	const [{data: classList}] = useClasses();
	const [{data: groupList}] = useGroups();
	const [{data: studentList}] = useStudents();

	const filteredEntities = useMemo<Entities>(
		() => ({
			classes: classList.filter((c) => c.studentIDs.length),
			groups: groupList.filter((g) => g.studentIDs.length),
			students: studentList,
		}),
		[classList, groupList, studentList],
	);

	return (
		<Panel loaded={true}>
			<StudentsPanel.Root entities={filteredEntities} initialTab={StudentsPanelTabID.Classes}>
				<StudentsPanel.Selection.MultiSelect selected={selected} onSelectedChange={setSelected}>
					<StudentsPanel.Header.Title>
						<Text size='small' color='neutral56'>
							Select Students
						</Text>
					</StudentsPanel.Header.Title>
					<StudentsPanel.Header.Tabs />

					<StudentsPanel.Tabs.Root>
						<StudentsPanel.Tabs.Classes sectionTitle='Classes'>
							<ClassesList />
						</StudentsPanel.Tabs.Classes>
						<StudentsPanel.Tabs.Groups sectionTitle='Groups'>
							<GroupsList />
						</StudentsPanel.Tabs.Groups>
						<StudentsPanel.Tabs.Students sectionTitle='Students'>
							<StudentsList />
						</StudentsPanel.Tabs.Students>
					</StudentsPanel.Tabs.Root>
				</StudentsPanel.Selection.MultiSelect>
			</StudentsPanel.Root>
		</Panel>
	);
}
