import React from 'react';
import styles from './selfassess-modal.module.less';
import {Modal, ModalManagerRefObject, Title, CloseIcon} from '@esgillc/ui-kit/modal';
import {PrimaryButton, TransparentButton} from '@esgi/deprecated/ui-kit/buttons';

interface Props {
    close: () => any;
    showTestRunner: () => void;
    selfAssessClicked: () => void;
}

export default class SelfAssessModal extends React.PureComponent<Props> {
	private modalManagerRef: ModalManagerRefObject = React.createRef();

	private onClose() {
		this.modalManagerRef.current.close(this.props.close);
	}

	private selfAssessClicked() {
		this.modalManagerRef.current.close(this.props.selfAssessClicked);
	}

	private showTestRunnerClicked() {
		this.modalManagerRef.current.close(this.props.showTestRunner);
	}

	render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
            <Modal.Header>
                <Title>How would you like to Administer this test?</Title>
                <CloseIcon onClick={() => this.onClose()}/>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.container}>
                    <div className={styles.item}>
                        <img onClick={() => this.selfAssessClicked()} src={'https://esgiwebfiles.s3.amazonaws.com/images/selfassess/selfassess-test.png'}/>
                        <TransparentButton onClick={() => this.selfAssessClicked()}>
                            STUDENT SELF-ASSESS
                        </TransparentButton>
                    </div>
                    <div className={styles.item}>
                        <img onClick={() => this.showTestRunnerClicked()} src={'https://esgiwebfiles.s3.amazonaws.com/images/selfassess/teacher-one-to-one-test.png'}/>
                        <PrimaryButton onClick={() => this.showTestRunnerClicked()}>
                            TEACHER ONE-ON-ONE
                        </PrimaryButton>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer/>
        </Modal>;
	}
} 
