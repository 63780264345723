import React from 'react';
import {Checkbox, Radio} from '@esgi/deprecated/elements';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import {SettingsModel} from 'shared/modules/reports/flash-cards/settings-form/services/settings-service';

class State {
    settings: SettingsModel;
}

class Props {
    dataService: DataService;
}

export default class Settings extends React.Component<Props, State> {
	constructor(props?: Props) {
    	super(props);
		const m = new SettingsModel();
		m.includeSessionOption = false;
		m.sessionDate = null;
		m.cardsNumber = '';
		m.printOutOption = '';

    	this.state = {
    		settings: m,
    	};
	}
	componentDidMount(): void {
    	this.props.dataService.settingsService.settings$.subscribe((settings) => this.setState({settings: settings}));
	}

	printOutOptionChanged(optionValue: string) {
    	const m = new SettingsModel();
    	m.printOutOption = optionValue;
    	this.props.dataService.settingsService.updateSettingsModel(m);
    	this.props.dataService.testsService.reselectTests();
	}
    
	cardsNumberChanged(optionValue: string) {
		const m = new SettingsModel();
		m.cardsNumber = optionValue;
		this.props.dataService.settingsService.updateSettingsModel(m);
	}
    
	includeSessionChanged(value: boolean) {
		const date = value ? Models.Date.currentDate : null;
		if (!value){
			let m = new Models.ReloadTestsModel();
			m.date = date;
			m.includeSessionOption = value;
			this.props.dataService.testsService.reloadTests(m);
		}

		const m = new SettingsModel();
		m.includeSessionOption = value;
		m.sessionDate = date;
		this.props.dataService.settingsService.updateSettingsModel(m);
		this.props.dataService.testsService.updateTestsDate(date);
	}
        
	render() {
    	return 	<div className='inline-blocks'>
            <div><strong>Settings:</strong></div>
            <div className='print-out-settings'>
                <p>
                    <small>Print out:</small>
                    <a className='inline-tooltip'
                       href='https://support.esgisoftware.com/hc/en-us/articles/209159886#Incorrect'
                       target='_blank' rel='noreferrer'>
                        <sup className='fa fa-question-circle' aria-hidden='true'/>
                    </a>
                </p>
                <div className='options'>
                    <Radio
                        checked={this.state.settings.printOutOption === 'IncorrectOnly'}
                        onSelect={() => this.printOutOptionChanged('IncorrectOnly')}
                        name='printOutOptions'
                        label={this.props.dataService.settingsService.testResultsIncorrectVerbiage + ' Only'}
                        id='IncorrectOnly'
                    />
                    <Radio
                        checked={this.state.settings.printOutOption === 'IncorrectUntested'}
                        onSelect={() => this.printOutOptionChanged('IncorrectUntested')}
                        name='printOutOptions'
                        label={this.props.dataService.settingsService.testResultsIncorrectVerbiage + '  & Skipped'}
                        id='IncorrectUntested'
                    />
                    <Radio
                        checked={this.state.settings.printOutOption === 'FullSet'}
                        onSelect={() => this.printOutOptionChanged('FullSet')}
                        name='printOutOptions'
                        label='Full Set'
                        id='fullSet'
                    />
                </div>
            </div>
            <div className='options-wrapper'>
                <p>
                    <small>Number of cards per page:</small>
                </p>
                <div className='options'>
                    <Radio
                        checked={this.state.settings.cardsNumber === 'Six'}
                        onSelect={(e) => this.cardsNumberChanged('Six')}
                        name='numberOfCards'
                        label='Six'
                        id='six'
                    />
                    <Radio
                        checked={this.state.settings.cardsNumber === 'Nine'}
                        onSelect={(e) => this.cardsNumberChanged('Nine')}
                        name='numberOfCards'
                        label='Nine'
                        id='nine'
                    />
                    <Radio
                        checked={this.state.settings.cardsNumber === 'Twelve'}
                        onSelect={(e) => this.cardsNumberChanged('Twelve')}
                        name='numberOfCards'
                        label='Twelve'
                        id='twelve'
                    />
                </div>
            </div>
            <Checkbox
                id='include-session-chbox'
                checked={this.state.settings.includeSessionOption}
                onClick={(value) => this.includeSessionChanged(value)}
                disabled={this.state.settings.printOutOption === 'FullSet' || this.props.dataService.testsService.showNoStudentsAlert}
                className='sessions-checkbox-wrapper'
                label='Choose Session&nbsp;'
            />
        </div>;
	}
}