export enum Race
{
	Asian = 'Asian',
	BlackOrAfricanAmerican = 'BlackOrAfricanAmerican',
	Multiethnic = 'Multiethnic',
	NativeAmericanOrAlaskaNative = 'NativeAmericanOrAlaskaNative',
	NativeHawaiianOrPacificIslander = 'NativeHawaiianOrPacificIslander',
	WhiteOrCaucasian = 'WhiteOrCaucasian',
	HispanicOrLatino = 'HispanicOrLatino',
	MiddleEasternOrNorthAfrican = 'MiddleEasternOrNorthAfrican',
	Other = 'Other',
}

export const getName = (race: Race) => {
	switch(race){
		case Race.Other: return 'Other';
		case Race.Asian: return 'Asian';
		case Race.BlackOrAfricanAmerican: return 'Black Or African American';
		case Race.Multiethnic: return 'Multi-ethnic';
		case Race.NativeAmericanOrAlaskaNative: return 'Native American Or Alaska Native';
		case Race.NativeHawaiianOrPacificIslander: return 'Native Hawaiian Or Pacific Islander';
		case Race.WhiteOrCaucasian: return 'White Or Caucasian';
		case Race.HispanicOrLatino: return 'Hispanic or Latino';
		case Race.MiddleEasternOrNorthAfrican: return 'Middle Eastern or North African';
	}
};

export const raceNames = () => {
	return [
		{id: Race.Asian.toString(), name: getName(Race.Asian)},
		{id: Race.BlackOrAfricanAmerican.toString(), name: getName(Race.BlackOrAfricanAmerican)},
		{id: Race.Multiethnic.toString(), name: getName(Race.Multiethnic)},
		{id: Race.NativeAmericanOrAlaskaNative.toString(), name: getName(Race.NativeAmericanOrAlaskaNative)},
		{id: Race.NativeHawaiianOrPacificIslander.toString(), name: getName(Race.NativeHawaiianOrPacificIslander)},
		{id: Race.WhiteOrCaucasian.toString(), name: getName(Race.WhiteOrCaucasian)},
		{id: Race.HispanicOrLatino.toString(), name: getName(Race.HispanicOrLatino)},
		{id: Race.MiddleEasternOrNorthAfrican.toString(), name: getName(Race.MiddleEasternOrNorthAfrican)},
		{id: Race.Other.toString(), name: getName(Race.Other)},
	];
};
