import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardRoot = styled(Card, {
	cursor: 'pointer',
	display: 'grid',
	gap: '$3',
	paddingTop: 12,
	paddingBottom: 12,
	marginBottom: 10,

	'&:not(:last-child)': {
		marginBottom: 12,
	},
});
