import {Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';

import styles from './invalid-track-alert.module.less';

type Props = {
	close: () => void;
	schoolYearName: string;
	className?: string;
}

export function InvalidTrackAlert(props: Props) {
	const modalManagerRef = useModal();
	const close = useCloseModal(modalManagerRef, props.close);

	return <Alert className={props.className} modalManagerRef={modalManagerRef}>
		<Alert.Header>
			<Title>
				<div className={styles.header}>
					<div>
						<svg width='16' height='16' className={styles.warningIcon}
						     viewBox='0 0 16 16' fill='none'
						     xmlns='http://www.w3.org/2000/svg'>
							<path d='M0 14.25H16.5L8.25 0L0 14.25ZM9 12H7.5V10.5H9V12ZM9 9H7.5V6H9V9Z' fill='#FEC702'/>
						</svg>
					</div>
					<span>
						The First Marking Period Has Not Yet Started.
						<a href='https://support.esgisoftware.com/hc/en-us/articles/209159566-Changing-Dates'
						   className='help' target='_blank' rel='noreferrer'>
							<i className='fa fa-question-circle'/>
						</a>
					</span>
				</div>
			</Title>
		</Alert.Header>
		<Alert.Body className={styles.alertBody}>
			<div className={styles.body}>
				<span> To view this report, please edit your {props.schoolYearName} marking period dates by selecting Add/Edit Track from your Account page, or select a previous
					&nbsp;
					<a href='https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Year'
					   className='external-link' target='_blank' rel='noreferrer'>
					school year
					</a>
					&nbsp;in the left panel.
				</span>
			</div>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Contained onClick={close}>
				GOT IT
			</Buttons.Contained>
		</Alert.Footer>
	</Alert>;
}
