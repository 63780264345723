import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {SessionSelectorSelectors} from './session-selector/session-selector.selectors';

interface Props {
	onClose: () => void;
}

export function NoIncorrectItemsAlert(props: Props) {
	const modal = useModal();
	const close = useCloseModal(modal, props.onClose);
	return <Alert data-cy={SessionSelectorSelectors.noIncorrectItemsAlert} modalManagerRef={modal}>
		<Alert.Body>
      There are no incorrect or untested questions for this test.
    </Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={close} data-cy={SessionSelectorSelectors.noIncorrectItemsClose}>OK</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
