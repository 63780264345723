import {NavigateFunction} from 'react-router-dom';
import {Observable, Subject} from 'rxjs';

export class HistoryTool {
	private locationChangedEmitter: Subject<Location> = new Subject<Location>();
	public navigateFn: NavigateFunction;

	constructor() {

	}

	public get onLocationChanged$(): Observable<Location> {
		return this.locationChangedEmitter;
	}

	public get currentPath(): string {
		return location.pathname;
	}

	public navigateOutside(path: string, options: { newWindow?: boolean } = {}) {
		if (options.newWindow) {
			window.open(path, '_blank');
		} else {
			location.href = path;
		}
	}

	public navigate(path: string, params?: object) {
		let endPath = path;
		if (params) {
			const p = this.serializeSearchParams(params);
			endPath += '?' + p;
		}
		this.commitNavigate(endPath);
	}

	private serializeSearchParams(obj): string {
		let str = [];
		for (let p in obj) {
			if (obj.hasOwnProperty(p)) {
				str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
			}
		}
		return str.join('&');
	}

	private commitNavigate(path: string) {
		this.navigateFn && this.navigateFn(path);
	}

	public static reload(): void {
		window.location.reload();
	}
}

const AppHistory = new HistoryTool();
export default AppHistory;
