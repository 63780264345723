import * as React from 'react';
import type {SVGProps} from 'react';

export function MoveDown(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			data-cy='move-down-icon'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.48528 9.8097C7.76955 10.0634 8.23045 10.0634 8.51472 9.8097L10.7868 7.11624C11.0711 6.86251 11.0711 6.45113 10.7868 6.1974C10.5025 5.94367 9.875 6.00703 9.5 6.00703L6.125 6.00713C5.75 6.00715 5.49747 5.94367 5.2132 6.1974C4.92893 6.45113 4.92893 6.86251 5.2132 7.11624L7.48528 9.8097Z'
				fill='#333333'
			/>
		</svg>
	);
}
