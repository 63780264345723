import {Users} from '@esgi/ui/icons';
import {Drawer, StudentsSearchableList} from '@esgi/main/features/teacher/home';
import {Class, useClasses} from '@esgi/main/libs/store';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {useService} from '@esgi/core/service';
import {DrawerBody} from '../drawer-body.styled';
import {useCallback, useEffect, useState} from 'react';
import {Student} from '../../types';
import {dispatchAppEvent} from '@esgillc/events';
import {EditClass as EditClassEvent, RemoveClass} from '../../events';
import {EditDataService} from './service';
import {ClassNameForm} from '../class-name-form';
import {ClassContextMenu} from './components/class-context-menu';
import {isUndefined} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';

type Props = {
	schoolYear: number,
	onLoaded: (value: boolean) => void;
	onFormTouched: (value: boolean) => void;
	classID: number;
	onAddStudentByRef?: VoidFunction;
};

export function EditClass({onLoaded, classID, onFormTouched, schoolYear, onAddStudentByRef}: Props) {
	const forceDrawerClose = Drawer.useForceDrawerClose();

	const user = useUser();

	useEffect(() => {
		if(user?.globalSchoolYearID !== schoolYear) {
			forceDrawerClose();
		}
	}, [user?.globalSchoolYearID, schoolYear]);

	const [{data: classList, loaded: isClassListLoaded}] = useClasses();

	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	const [className, setClassName] = useState<string | null>(null);
	const [isClassNameFormValid, setIsClassNameFormValid] = useState(false);
	const [isClassNameFormTouched, setIsClassNameFormTouched] = useState(false);

	const [selectedStudentIds, setSelectedStudentIds] = useState<Student['id'][]>([]);
	const [selectedStudentIdsChanged, setSelectedStudentIdsChanged] = useState(false);

	const dataService = useService(EditDataService);

	const students = useBehaviorSubject(dataService.students$);
	const initialClassName = useBehaviorSubject(dataService.initialClassName$);
	const initialSelectedStudentsIds = useBehaviorSubject(dataService.initialSelectedStudentsIds$);

	useStreamEffect(dataService.initialSelectedStudentsIds$, (value) => setSelectedStudentIds(value ?? []));

	useEffect(() => {
		onLoaded(false);

		if (!isClassListLoaded) {
			return;
		}

		dataService.init({classID}).subscribe(() => onLoaded(true));
	}, [isClassListLoaded]);

	useEffect(() => {
		onFormTouched(isClassNameFormTouched || selectedStudentIdsChanged);
	}, [isClassNameFormTouched, selectedStudentIdsChanged]);

	const handleSaveClass = () => {
		if (className) {
			setIsFormSubmitting(true);

			dataService.updateClass({classID, name: className, studentIDs: selectedStudentIds}).subscribe({
				next: () => {
					const classInfo: Class = {
						id: classID,
						name: className,
						studentIDs: selectedStudentIds,
					};
					dispatchAppEvent(EditClassEvent, new EditClassEvent(classInfo));
				},
				complete: () => {
					setIsFormSubmitting(false);
					forceDrawerClose();
				},
			});
		}
	};

	const onDeleteClass = useCallback(() => {
		dataService.deleteClass({classID}).subscribe({
			next: () => {
				const contractedClass = classList.find(({id}) => id === classID);

				if (!isUndefined(contractedClass)) {
					dispatchAppEvent(RemoveClass, new RemoveClass(contractedClass));
				}
			},
			complete: () => {
				forceDrawerClose();
			},
		});
	}, [classID, classList, dataService, forceDrawerClose]);

	const isActionButtonDisabled =
		!((isClassNameFormValid && isClassNameFormTouched) || selectedStudentIdsChanged) || isFormSubmitting;

	return (
		<>
			<Drawer.Header
				Icon={Users}
				sectionName='Edit Class'
				withActionButton
				actionButtonDisabled={isActionButtonDisabled}
				actionButtonText='Save Changes'
				onActionButtonClick={handleSaveClass}
			>
				<ClassContextMenu
					classBucketLength={classList.length}
					className={initialClassName ?? ''}
					onDeleteClass={onDeleteClass}
				/>
			</Drawer.Header>
			<DrawerBody>
				<Drawer.ContentBlock title='Details'>
					<ClassNameForm
						initialClassName={initialClassName ?? ''}
						setIsFormValid={setIsClassNameFormValid}
						setClassName={setClassName}
						onFormTouched={setIsClassNameFormTouched}
					/>
				</Drawer.ContentBlock>
				<Drawer.ContentBlock title='Select Students' titleBold>
					<StudentsSearchableList
						students={students ?? []}
						selectedStudentIds={selectedStudentIds}
						setSelectedStudentIds={setSelectedStudentIds}
						initialSelectedStudentIds={initialSelectedStudentsIds}
						setSelectedValueChanged={setSelectedStudentIdsChanged}
						onAddStudentByRef={onAddStudentByRef}
					/>
				</Drawer.ContentBlock>
			</DrawerBody>
		</>
	);
}
