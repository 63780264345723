import {CorrectAnswer, InCorrectAnswer, NotTestedAnswer} from '@esgi/ui/icons';
import {AnswerState} from '../types';
import {Item} from './types';

export const items: Item[] = [
	{
		Icon: CorrectAnswer,
		state: AnswerState.Correct,
	},
	{
		Icon: InCorrectAnswer,
		state: AnswerState.Incorrect,
	},
	{
		Icon: NotTestedAnswer,
		state: AnswerState.NotTested,
	},
];
