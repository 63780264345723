import React from 'react';
import {TextInput} from '@esgi/deprecated/elements';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';

export class State {
	keyword: string = '';
}

export class Props extends SharedProps<State> {
	search: () => any;
}

export class Search extends SharedComponent<State, Props> {
	constructor(props: Props) {
		super(props);

	}

	render(): JSX.Element | false | null {
		return <div className='search input-group'>
			<TextInput type='text' inputClassName='search-input' placeholder='Search Images'
			                                       value={this.state.keyword}
			                                       onKeyUp={(e) => this.searchTyped(e)}
			                                       onEdit={(value) => this.setState({keyword: value})}
			/>
			<a href='#' className='input-search-icon' onClick={() => this.searchClicked()}>
				<svg className='esgi-icon i-search' viewBox='0 0 18 18' fill='none'>
					<path
						d='M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z'
						fill='#bdbdbd'/>
				</svg>
			</a>
			{this.state.keyword &&
			<a href='#' className='input-group-addon clear-icon' onClick={() => this.clearSearch()}>
				<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'
				     fill='none'>
					<path
						d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
						fill='#828282'/>
				</svg>
			</a>
			}
		</div>;
	}

	searchTyped(e: React.KeyboardEvent<HTMLInputElement>): void {
		if (e.key === 'Enter' && this.state.keyword) {
			this.searchClicked();
		}
	}

	searchClicked(): void {
		if (this.state.keyword) {
			this.props.search();
		}
	}

	private clearSearch() {
		this.setState({keyword: ''});
	}
}
