import * as React from 'react';
import type {SVGProps} from 'react';

export function TilesView(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6 7.26316C6 6.56554 6.56554 6 7.26316 6H9.78947C10.4871 6 11.0526 6.56554 11.0526 7.26316V9.78947C11.0526 10.4871 10.4871 11.0526 9.78947 11.0526H7.26316C6.56554 11.0526 6 10.4871 6 9.78947V7.26316Z'
				fill='#333333'
			/>
			<path
				d='M6 14.2105C6 13.5129 6.56554 12.9474 7.26316 12.9474H9.78947C10.4871 12.9474 11.0526 13.5129 11.0526 14.2105V16.7368C11.0526 17.4345 10.4871 18 9.78947 18H7.26316C6.56554 18 6 17.4345 6 16.7368V14.2105Z'
				fill='#333333'
			/>
			<path
				d='M12.9474 14.2105C12.9474 13.5129 13.5129 12.9474 14.2105 12.9474H16.7368C17.4345 12.9474 18 13.5129 18 14.2105V16.7368C18 17.4345 17.4345 18 16.7368 18H14.2105C13.5129 18 12.9474 17.4345 12.9474 16.7368V14.2105Z'
				fill='#333333'
			/>
			<path
				d='M12.9474 7.26316C12.9474 6.56554 13.5129 6 14.2105 6H16.7368C17.4345 6 18 6.56554 18 7.26316V9.78947C18 10.4871 17.4345 11.0526 16.7368 11.0526H14.2105C13.5129 11.0526 12.9474 10.4871 12.9474 9.78947V7.26316Z'
				fill='#333333'
			/>
		</svg>
	);
}
