export enum AssignmentStatus {
	None = 'None',
	Published = 'Published',
	NotStarted = 'NotStarted',
	Completed = 'Completed',
	Draft = 'Draft',
}

export const assignmentStatusLabels = {
	[AssignmentStatus.Published]: 'In Progress',
	[AssignmentStatus.Completed]: 'Completed',
	[AssignmentStatus.NotStarted]: 'Not Started',
	[AssignmentStatus.Draft]: 'Draft',
};

export const assignmentDatesLabels = {
	[AssignmentStatus.Published]: 'Posted',
	[AssignmentStatus.Completed]: 'Completed',
	[AssignmentStatus.NotStarted]: 'Created',
};

export interface Assignment {
	title: string;
	postedAt: string;
	code: string;
	id: number;
	status: AssignmentStatus;
	description?: string;
}
