import React, {createRef} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {ModalManager} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import TestingService from '../../testing-service';

interface Props {
	service: TestingService;
	onClosed: () => void;
}

export default class ContinueDialog extends React.PureComponent<Props> {
	private modalManagerRef: React.RefObject<ModalManager> = createRef();

	public render() {
		return <Alert modalManagerRef={this.modalManagerRef}>
			<Alert.Body>
				{this.props.service.studentName} is currently being tested
				on {this.props.service.testModel.rubricName} in our
				system, would you like to take over this session?
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={this.noClicked}>
					NO
				</Buttons.Text>
				<Buttons.Text onClick={this.yesClicked}>
					YES
				</Buttons.Text>
			</Alert.Footer>
		</Alert>;
	}

	private noClicked = () => {
		this.props.service.resetAnswers();
		this.modalManagerRef.current.close(() => this.props.onClosed());
	};

	private yesClicked = () => {
		this.props.service.startTesting();
		this.modalManagerRef.current.close(() => this.props.onClosed());
	};
}
