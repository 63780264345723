import {SVGProps} from 'react';

export const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='12'
		height='11'
		viewBox='0 0 12 11'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M11.25 1.30738L10.1925 0.249878L6 4.44238L1.8075 0.249878L0.75 1.30738L4.9425 5.49988L0.75 9.69238L1.8075 10.7499L6 6.55738L10.1925 10.7499L11.25 9.69238L7.0575 5.49988L11.25 1.30738Z'
			fill='#0088CC'
		/>
	</svg>
);
