import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Content = styled(GridBox, {
	padding: '0px 20px',
	gap: '$3',

	variants: {
		gridView: {
			true: {
				alignItems: 'start',
				gridTemplateColumns: 'repeat(2, 1fr)',
			},
		},
	},
});