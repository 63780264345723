import {Avatar} from '@esgi/ui/avatar';
import {Student} from '@esgi/main/libs/store';
import {getInitials} from '@esgi/main/features/teacher/home';
import {AvatarWrapper, StudentName} from './index.styled';

interface Props extends Pick<Student, 'firstName' | 'lastName' | 'photoUrl'> {
	skeleton?: boolean;
}

export function StudentAvatar(props: Props) {
	const {firstName, lastName, photoUrl, skeleton} = props;

	return (
		<AvatarWrapper flow='row' align='center'>
			<Avatar.Root data-cy='single-student-avatar' size='sm' skeleton={skeleton}>
				<Avatar.Image src={photoUrl}/>
				<Avatar.Fallback>
					{getInitials({firstName, lastName})}
				</Avatar.Fallback>
			</Avatar.Root>
			<StudentName data-cy='single-student-name' size='small' color='neutral56' bold skeleton={skeleton}>{firstName} {lastName}</StudentName>
		</AvatarWrapper>
	);
}
