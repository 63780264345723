import {HomeState} from '@esgi/contracts/esgi/types/sso.accounts/models/user-page-states/teacher/home-state';
import {StudentSection} from '@esgi/contracts/esgi/types/sso.accounts/models/user-page-states/teacher/student-section';
import {SubjectSection} from '@esgi/contracts/esgi/types/sso.accounts/models/user-page-states/teacher/subject-section';

export type StudentSectionState = StudentSection;

export type SubjectSectionState = SubjectSection;

export type PageSectionsState = HomeState;

export enum SectionTabId {
	Students = 'students',
	Subjects = 'subjects',
}