import * as React from 'react';
import type {SVGProps} from 'react';

export function MoveUp(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			data-cy='move-up-icon'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.48528 6.1903C7.76955 5.93657 8.23045 5.93657 8.51472 6.1903L10.7868 8.88376C11.0711 9.13749 11.0711 9.54887 10.7868 9.8026C10.5025 10.0563 9.875 9.99297 9.5 9.99297L6.125 9.99287C5.75 9.99285 5.49747 10.0563 5.2132 9.8026C4.92893 9.54887 4.92893 9.13749 5.2132 8.88376L7.48528 6.1903Z'
				fill='#333333'
			/>
		</svg>
	);
}
