import {AppEvent} from '@esgillc/events';
import {SessionType, SubjectPublishType, SubjectType, TestType} from '@esgi/core/enums';

export type TestData = {
	name: string,
	type: TestType,
	creatorID: number,
	draft: boolean,
};

@AppEvent({broadcast: true})
export class TestAddedToSubjectEvent {
	constructor(
		public subjectID: number,
		public subjectType: SubjectType,
		public testID: number,
		public testData: TestData,
	) {
	}
}

@AppEvent({broadcast: true})
export class TestMovedBetweenSubjectsEvent {
	constructor(
		public testID: number,
		public sourceSubjectID: number,
		public destinationSubjectID: number,
	) {
	}
}

@AppEvent({broadcast: true})
export class TestRemovedFromSubjectEvent {
	constructor(
		public subjectID: number,
		public subjectType: SubjectType,
		public testID: number,
	) {
	}
}

@AppEvent({broadcast: true})
export class SubjectCreatedEvent {
	constructor(
		public id: number,
		public published: boolean,
		public properties: CreateSubject.InModel,
		public selected: boolean = true,
	) {
	}
}

@AppEvent({broadcast: true})
export class SubjectUpdatedEvent {
	constructor(
		public id: number,
		public published: boolean,
		public properties: UpdateSubject.InModel,
	) {
	}
}

@AppEvent({broadcast: true})
export class SubjectReorderedEvent {
	constructor(
		public subjectType: SubjectType,
		public subjectIds: number[],
	) {
	}
}

@AppEvent({broadcast: true})
export class SubjectHiddenEvent {
	constructor(
		public id: number,
		public subjectType: SubjectType,
	) {
	}
}

@AppEvent({broadcast: true})
export class SubjectUnhiddenEvent {
	constructor(
		public id: number,
		public subjectType: SubjectType,
	) {
	}
}

export namespace CreateSubject {
	export interface InModel {
		name: string;
		subjectType: SubjectType;
		publishType?: SubjectPublishType;
		publishFromDate: string;
		publishToDate: string;
		publishTrackID?: number;
		gradeLevels?: number[];
		shuffleQuestions: boolean;
		forceTestType?: SessionType;
	}

	export interface OutModel {
		SubjectId: number;
		Published: boolean;
	}
}

export namespace UpdateSubject {
	export interface InModel {
		id: number;
		name: string;
		subjectType: SubjectType;
		publishType?: SubjectPublishType;
		publishFromDate: string;
		publishToDate: string;
		publishTrackID?: number;
		gradeLevels?: number[];
		shuffleQuestions: boolean;
		forceTestType?: SessionType;
	}

	export interface OutModel {
		SubjectId: number;
		Published: boolean;
	}
}
