import React, {useEffect} from 'react';
import BingoLogo from 'shared/modules/bingo/assets/bingo-logo';
import Players from 'shared/modules/bingo/steps/players/players';
import Subjects from 'shared/modules/bingo/steps/subjects/subjects';
import Footer from 'shared/modules/bingo/components/footer/footer';
import Cards from 'shared/modules/bingo/steps/cards';
import Print from 'shared/modules/bingo/steps/print';
import PreviewQuestions from 'shared/modules/bingo/steps/preview-questions/preview-questions';
import {BingoService} from 'shared/modules/bingo/service/service';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import {SsoTracker} from '@esgi/core/tracker';

import styles from './steps.module.less';

interface IStepsProps {
	service: BingoService;
	hierarchy: HierarchySnapshot;
	step: number;
	onClose: () => void;
	nextStep: () => void;
	prevStep: () => void;
	setStep: (step: number) => void;
	selectedSubjectID: number;
}

export default function Steps ({
	service,
	step,
	onClose,
	hierarchy,
	selectedSubjectID,
	nextStep,
	prevStep,
	setStep,
}: IStepsProps) {
	const players = useBehaviorSubject(service.players$);
	const subjects = useBehaviorSubject(service.subjects$);
	const tests = useBehaviorSubject(service.tests$);
	const cards = useBehaviorSubject(service.cards$);
	const print = useBehaviorSubject(service.print$);
	const questions = useBehaviorSubject(service.questions$);

	const entityName = service.getEntityName();

	const downloadGame = () => {
		let valid = true;
		if (cards.x3cardSize && questions.questions.length < 8) {
			valid = false;
		}

		if (!cards.x3cardSize && questions.questions.length < 24) {
			valid = false;
		}

		if (valid) {
			service.exportPDF(hierarchy).subscribe(() => {
				SsoTracker.trackEvent({
					trackingEvent: print.gamePlayedAtStudentHome ? 'Bingo-Home' : 'Bingo-Class',
				});
				setStep(6);
				service.loading$.next(false);
			});
		}
	};

	useEffect(() => {
		step === 1 && !players?.loaded && service.getPlayers(hierarchy).subscribe();
		step === 2 && !subjects?.loaded && service.getSubjects(hierarchy, selectedSubjectID).subscribe();
	}, [step, players?.loaded, subjects?.loaded]);

	return (
		<>
			<div className={styles.header}>
				<BingoLogo/>
				<CloseIcon className={styles.closeIcon} color={'#fff'} onClick={onClose}/>
			</div>
			<div className={styles.subHeader}><div className={styles.balls}/></div>
			{step === 1 && <Players service={service} players={players} />}
			{step === 2 && <Subjects service={service} subjects={subjects} entityName={entityName}/>}
			{step === 3 && <Cards
				service={service}
				subjectName={subjects.selectedSubject?.name}
				testName={tests.selectedTest?.name}
				questionCount={tests.selectedTest.questionCount}
				entityName={entityName}
				cards={cards}
				selectedTestId={tests.selectedTest.id}
			/>}
			{step === 4 && <Print
				service={service}
				entityName={entityName}
				subjectName={subjects.selectedSubject?.name}
				testName={tests.selectedTest?.name}
				numberOfStudents={service.students.length}
				x3cardSize={cards.x3cardSize}
				twoCardsPerPage={cards.twoCardsPerPage}
				questionsLoaded={!!questions.questions.filter(x => questions.selectedQuestionsIDs.some(c => c === x.questionID)).length}
				print={print}
				previewQuestionsClicked={nextStep}
				downloadGame={downloadGame}
			/>}
			{step === 5 && <PreviewQuestions
				service={service}
				entityName={entityName}
				subjectName={subjects.selectedSubject?.name}
				testName={tests.selectedTest?.name}
				questions={questions.questions}
				warning={questions.warning}
				valid={questions.valid}
				selectedQuestions={questions.selectedQuestionsIDs}
				x3cardSize={cards.x3cardSize}
				selectedSortBy={questions.selectedSortBy}
				questionSelected={(questions) => service.setSelectedQuestions(questions)}
				testID={tests.selectedTest?.id}
			/>}
			<Footer
				service={service}
				step={step}
				prevStep={prevStep}
				nextStep={nextStep}
				setStep={setStep}
				validateStep={service.validateStep(step)}
				downloadGame={downloadGame}
			/>
		</>
	);
}
