import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';

export const StyledToggleGroupRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
});

export const StyledToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: 'auto',
	gap: '2px',
});

export const StyledToggleGroupItem = styled(ToggleGroup.Item, {
	flexDirection: 'column',
	justifyItems: 'center',

	'& svg': {
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});