import {SchoolYearModel, TrackModel, UserModel} from '../../types';
import {EditMode} from './variant/edit-mode';
import {UserAccountService} from '../../user-account-service';
import {useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {ViewMode} from './variant/view-mode';

type Props = {
	dataService: UserAccountService;
	user: UserModel;
	schoolYearTypes: SchoolYearModel[];
	onClose: VoidFunction;
	activeUserTrackId: TrackModel['trackID'] | null;
	tracks: TrackModel[] | null;
};

export function PrimaryAccountContent({dataService, user, schoolYearTypes, onClose, activeUserTrackId, tracks}: Props) {
	const [visibleTrack, setVisibleTrack] = useState<TrackModel | null>(null);

	useEffect(() => {
		const activeTrack = tracks?.find(({trackID}) => trackID === activeUserTrackId) ?? tracks?.[0];

		setVisibleTrack(activeTrack ?? null);
	}, [activeUserTrackId, tracks]);

	if (isNull(visibleTrack)) {
		return null;
	}

	if (user.isLinked) {
		return <ViewMode onClose={onClose} user={user} visibleTrack={visibleTrack} />;
	}

	return (
		<EditMode
			onClose={onClose}
			schoolYearTypes={schoolYearTypes}
			updateTrackRequest={dataService.updateTrackRequest.bind(dataService)}
			user={user}
			visibleTrack={visibleTrack}
		/>
	);
}
