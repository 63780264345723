import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	height: '100%',
	width: '100%',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: 'auto 1fr',
	justifyContent: 'space-between',
	columnGap: 60,
	variants: {
		isTablet: {
			true: {
				gridTemplateColumns: '1fr',
				gap: 12,
			},
		},
	},
});

export const Sidebar = styled(GridBox, {
	overflow: 'hidden',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gap: 14,
});
