import * as React from 'react';
import type {SVGProps} from 'react';

export function QuestionBordered(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path fillRule='evenodd' clipRule='evenodd' d='M9.9375 9.75453C9.9375 9.63496 9.97736 9.52536 10.0272 9.42572C10.361 8.73324 11.0485 8.25 12.01 8.25C13.2754 8.25 14.0625 9.09692 14.0625 10.2428C14.0625 11.0598 13.8034 11.5679 13.1757 12.0462C12.6377 12.4547 12.548 12.6241 12.548 12.8832V12.913C12.548 13.2418 12.279 13.5109 11.9502 13.5109C11.6214 13.5109 11.3524 13.2418 11.3524 12.913V12.8533C11.3524 12.1757 11.5516 11.8668 12.2491 11.3587C12.7373 11 12.8668 10.7011 12.8668 10.2926C12.8668 9.70471 12.538 9.32609 11.99 9.32609C11.5915 9.32609 11.2627 9.56522 11.0933 9.96377C10.9937 10.2029 10.7844 10.3424 10.5254 10.3424C10.1966 10.3424 9.9375 10.0833 9.9375 9.75453ZM11.9502 14.5825C12.279 14.5825 12.548 14.8515 12.548 15.1804C12.548 15.5092 12.279 15.7782 11.9502 15.7782C11.6214 15.7782 11.3524 15.5092 11.3524 15.1804C11.3524 14.8515 11.6214 14.5825 11.9502 14.5825Z' fill='#7A7A7A'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M13.2 6.5625H10.8C9.93826 6.5625 9.3709 6.56352 8.93649 6.59901C8.5174 6.63325 8.33889 6.69292 8.23389 6.74643C7.91637 6.90821 7.65821 7.16637 7.49643 7.48389C7.44292 7.58889 7.38326 7.7674 7.34901 8.18649C7.31352 8.6209 7.3125 9.93826 7.3125 10.8V13.2C7.3125 14.0617 7.31352 15.3791 7.34901 15.8135C7.38326 16.2326 7.44292 16.4111 7.49643 16.5161C7.65821 16.8336 7.91637 17.0918 8.23389 17.2536C8.33889 17.3071 8.5174 17.3667 8.93649 17.401C9.3709 17.4365 9.93826 17.4375 10.8 17.4375H13.2C14.0617 17.4375 14.6291 17.4365 15.0635 17.401C15.4826 17.3667 15.6611 17.3071 15.7661 17.2536C16.0836 17.0918 16.3418 16.8336 16.5036 16.5161C16.5571 16.4111 16.6167 16.2326 16.651 15.8135C16.6865 15.3791 16.6875 14.0617 16.6875 13.2V10.8C16.6875 9.93826 16.6865 8.6209 16.651 8.18649C16.6167 7.7674 16.5571 7.58889 16.5036 7.48389C16.3418 7.16637 16.0836 6.90821 15.7661 6.74643C15.6611 6.69292 15.4826 6.63325 15.0635 6.59901C14.6291 6.56352 14.0617 6.5625 13.2 6.5625ZM6.32698 6.88803C6 7.52976 6 9.11984 6 10.8L6 13.2C6 14.8802 6 16.4702 6.32698 17.112C6.6146 17.6765 7.07354 18.1354 7.63803 18.423C8.27976 18.75 9.11984 18.75 10.8 18.75H13.2C14.8802 18.75 15.7202 18.75 16.362 18.423C16.9265 18.1354 17.3854 17.6765 17.673 17.112C18 16.4702 18 14.8802 18 13.2V10.8C18 9.11984 18 7.52976 17.673 6.88803C17.3854 6.32354 16.9265 5.8646 16.362 5.57698C15.7202 5.25 14.8802 5.25 13.2 5.25L10.8 5.25C9.11984 5.25 8.27976 5.25 7.63803 5.57698C7.07354 5.8646 6.6146 6.32354 6.32698 6.88803Z' fill='#7A7A7A'/>
			<path d='M19.6875 8.90625C19.6875 8.54381 19.9813 8.25 20.3438 8.25C20.7062 8.25 21 8.54381 21 8.90625V15.0937C21 15.4562 20.7062 15.75 20.3438 15.75C19.9813 15.75 19.6875 15.4562 19.6875 15.0937V8.90625Z' fill='#7A7A7A'/>
			<path d='M3 8.90625C3 8.54381 3.29381 8.25 3.65625 8.25C4.01869 8.25 4.3125 8.54381 4.3125 8.90625L4.3125 15.0938C4.3125 15.4562 4.01869 15.75 3.65625 15.75C3.29381 15.75 3 15.4562 3 15.0938L3 8.90625Z' fill='#7A7A7A'/>
		</svg>
	);
}
