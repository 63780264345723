export function TestImageUpdated(testID: number, questionID: number): TestImageUpdated.Args {
	return {
		testID: testID,
		questionID: questionID,
	};
}

export module TestImageUpdated {
	export interface Args {
		testID: number;
		questionID: number;
	}
}