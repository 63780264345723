import {Checkbox} from '@esgillc/ui-kit/control';
import React, {ReactNode} from 'react';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import PartialContent from '@esgi/deprecated/ui-kit/virtual-scroll/partial-content';
import VirtualScrollContainer from '@esgi/deprecated/ui-kit/virtual-scroll/virtual-scroll-container';
import {useState} from '@esgi/deprecated/react';
import DataService from '../../services/data-service';
import StudentsService from '../../services/students-service';
import TableService, {StudentTableInfo} from '../../services/table-service';
import {OperationsPanel} from './components/operations-panel/operations-panel';
import {getUser, userStorage, UserType} from '@esgi/core/authentication';
import TableHeader from './components/table-header';
import styles from './table.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {EventBusManager} from '@esgillc/events';
import {StudentManagerEvents} from 'pages/student-manager/events';
import StudentProfile from 'modules/forms/students-form';

class State {
	studentsToShow: StudentTableInfo[] = [];
	studentEditorOpened: boolean = false;
	studentEditorStudentID: number = 0;
}

class Props {
	dataService: DataService;
	tableService: TableService;
	studentsService: StudentsService;
}

@useState(State)
export class Table extends React.Component<Props, State> {
	private onDestroy$: Subject<void> = new Subject();
	private currentUser = userStorage.get();
	private readonly eventBus: EventBusManager = new EventBusManager();
	private studentDisableMessage: string = 'You cannot modify students that are currently assigned to other teachers.';

	private get showSpecialistColumns(): boolean {
		return this.props.dataService.data.specialists.length > 0 ||
			this.currentUser.userType === UserType.ISD ||
			this.currentUser.userType === UserType.ISS ||
			this.currentUser.userType === UserType.PA;
	}

	private get specialistLabel(): string {
		return this.currentUser.userType === UserType.PA ? 'Pre-Assess' : 'Specialist';
	}

	public componentDidMount() {
		combineLatest(this.props.tableService.studentsToShow$, this.props.tableService.selectedStudentsIDs$)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(projects => this.setState({studentsToShow: projects[0].map(i => this.props.tableService.getStudentInfo(i))}));
		this.eventBus.subscribe(StudentManagerEvents.StudentsAssignedToTeacher, () => {
			this.props.tableService.unselectAll();
		});
		this.eventBus.subscribe(StudentManagerEvents.StudentsAssignedToSpecialist, () => {
			this.props.tableService.unselectAll();
		});
		this.eventBus.subscribe(StudentManagerEvents.StudentsUnassigned, () => {
			this.props.tableService.unselectAll();
		});
	}

	render() {
		return <>
			{!!this.state.studentsToShow.length &&
				<OperationsPanel tableService={this.props.tableService} studentsService={this.props.studentsService}
				                 dataService={this.props.dataService}/>
			}
			<VirtualScrollContainer className={styles.tableContainer}>
				<div className={styles.tableInnerContainer}>
					<table className={join('students-table', styles.studentsTable)}>
						<TableHeader showSpecialistColumns={this.showSpecialistColumns}
												 specialistLabel={this.specialistLabel}
						             tableService={this.props.tableService}/>
						<tbody>
						<PartialContent estimatedHeight={44}
						                topOffsetRender={(style) => <tr style={style}/>}
						                source={this.state.studentsToShow}
						                keySelector={i => i.studentID}>
							{(args) => this.renderRow(args.item, args.index, args.itemRef, args.key)}
						</PartialContent>
						<tr>
							<td colSpan={this.columnsCount}/>
						</tr>
						{this.state.studentsToShow.length === 0 &&
							<tr>
								<td className={styles.emptyResult}
								    colSpan={this.columnsCount}>No
									students
								</td>
							</tr>
						}
						</tbody>
					</table>
				</div>
			</VirtualScrollContainer>
			{this.renderStudentEditor()}
		</>;
	}

	private renderRow(student: StudentTableInfo, index: number, ref, key): ReactNode {
		const {
			studentName,
			schoolName,
			teacherName,
			studentClasses,
			studentGroups,
			specialistGroupUserNames,
			specialistGroupNames,
			studentGrade,
			studentCreateDate,
			studentIDN,
			studentID,
			selected,
			editable,
			primaryTeacherID,
		} = student;
		const showSpecialistColumns = this.showSpecialistColumns;
		const currentUser = getUser();
		const canViewStudents = currentUser.canViewStudents;

		const notLinkedToDistrict = currentUser.agreementLevelCode !== 'T';
		let isCanBeChecked = true;
		if (notLinkedToDistrict) {
			isCanBeChecked = currentUser.userType !== UserType.T
				? canViewStudents
				: canViewStudents && (primaryTeacherID === null || currentUser.userID === primaryTeacherID);
		}

		return <tr ref={r => ref(r)} data-name={studentName} key={key} className={join(index % 2 && 'even')}>
			<td className={join('checkbox_column', styles.checkboxColumn)}>
				<Checkbox checked={selected}
				          id={'student_selected_' + studentID}
				          onClick={() => this.props.tableService.changeStudentSelect(studentID)}
				          title={!editable ? this.studentDisableMessage : null}
				          disabled={!editable && !isCanBeChecked}/>
			</td>
			<td className={styles.studentNameColumn}>
				{this.renderStudentName(studentName, studentID, isCanBeChecked)}
			</td>
			{this.currentUser.userType === UserType.D && <td>{schoolName}</td>}
			<td>{teacherName}</td>
			<td>{studentClasses}</td>
			<td>{studentGroups}</td>
			{showSpecialistColumns && <td>{specialistGroupUserNames}</td>}
			{showSpecialistColumns && <td>{specialistGroupNames}</td>}
			<td className='grade_column'>{studentGrade}</td>
			<td className='createdate_column'>{studentCreateDate}</td>
			<td className='student-idn'>{studentIDN}</td>
		</tr>;
	}

	private renderStudentName(studentName: string, id: number, isOpenAllowed): ReactNode {
		if (isOpenAllowed) {
			return <a href='#' className='edit-student-link'
			          onClick={() => this.setState({
				          studentEditorOpened: true,
				          studentEditorStudentID: id,
			          })}>{studentName}
			</a>;
		} else {
			return studentName;
		}
	}

	private renderStudentEditor(): ReactNode {
		if (this.state.studentEditorOpened && this.state.studentEditorStudentID) {
			const student = this.state.studentsToShow.find(s => s.studentID === this.state.studentEditorStudentID);
			if (!student) {
				return null;
			}
			let viewType = UserType.T;
			if ([UserType.T, UserType.ISD, UserType.ISS, UserType.PA].includes(this.currentUser.userType)) {
				viewType = this.currentUser.userType;
			} else {
				viewType = UserType.T;
				if (!student.primaryTeacherID && student.specialistGroupIDs != null && student.specialistGroupIDs.length > 0) {
					viewType = UserType.ISD; //TODO NEED FIX.
				}
			}
			return <StudentProfile
				studentID={student.studentID}
				preSelected={null}
				onClose={() => this.setState({studentEditorOpened: false})}
			/>;
		}
	}

	private get columnsCount(): number {
		let out = 8;
		if (this.currentUser.userType === UserType.D) {
			out++;
		}
		if (this.showSpecialistColumns) {
			out += 2;
		}
		return out;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
