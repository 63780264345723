import {V2PagesAssignmentsStudentCredentialsController} from '@esgi/contracts/esgi';
import {BaseService} from '@esgi/core/service';
import {Student} from '@esgi/main/libs/store';
import {Observable, map, of} from 'rxjs';
import {StudentForSaving} from './types';
import {notUniqueUserNameMessage} from './constants';

export class StudentCredentialsService extends BaseService {
	protected readonly controller = new V2PagesAssignmentsStudentCredentialsController();

	public init({studentIDs}: {studentIDs: Student['id'][]}) {
		return this.controller.byStudents({studentIDs});
	}

	public validateUsername({
		value,
		studentID,
		initialValue,
	}: {
		value: string;
		studentID: number;
		initialValue: string;
	}): Observable<string | null> {
		if (!value || initialValue === value) {
			return of(null);
		}

		const validateMessage = this.getGeneralValidateMessage(value, {minLenght: 4, maxLenth: 20});

		if (validateMessage) {
			return of(validateMessage);
		}

		return this.controller
			.validateUsername({studentID, userName: value})
			.pipe(map(({isUnique}) => (!isUnique ? notUniqueUserNameMessage : null)));
	}

	public validatePassword(value: string): Observable<string | null> {
		const validateMessage = this.getGeneralValidateMessage(value, {minLenght: 4, maxLenth: 12});

		if (validateMessage) {
			return of(validateMessage);
		}

		return of(null);
	}

	public saveStudentCredentials(students: StudentForSaving[]) {
		return this.controller.save({
			students,
		});
	}

	public override dispose() {
		this.controller.dispose();
	}

	private getGeneralValidateMessage(value: string, {maxLenth, minLenght}: {maxLenth: number; minLenght: number}) {
		if (!value) {
			return;
		}

		const space = ' ';

		if (value.length < minLenght) {
			return `Minimum ${minLenght} characters`;
		}

		if (value.length > maxLenth) {
			return `Maximum ${maxLenth} characters`;
		}

		if (value.includes(space)) {
			return 'Spaces Not Allowed';
		}
	}
}
