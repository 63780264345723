import React, {useEffect, useRef, useState} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {join} from '@esgillc/ui-kit/utils';
import BingoTile from 'shared/modules/bingo/components/tile/tile';
import BingoTests from 'shared/modules/bingo/components/tests/tests';
import {BingoService} from 'shared/modules/bingo/service/service';
import {Subjects} from 'shared/modules/bingo/types/models';

import styles from './subjects.module.less';

interface SubjectProps {
	entityName: string;
	service: BingoService;
	subjects: Subjects;
}

interface ScrollState {
	scrollLeft?: number;
	scrollWidth?: number;
	offsetWidth?: number;
	canScrollRight: boolean;
	canScrollLeft: boolean;
}


export default function Subjects ({service, entityName, subjects: {subjects, selectedSubject, loaded}}: SubjectProps) {
	const tilesContainer = useRef<HTMLDivElement>();
	
	const [state, setState] = useState<ScrollState>({
		scrollLeft: 0,
		scrollWidth: 0,
		offsetWidth: 0,
		canScrollRight: false,
		canScrollLeft: false,
	});

	const recalculateScrollPosition = () => {
		const scrollWidth = tilesContainer.current?.scrollWidth;
		const offsetWidth = tilesContainer.current?.offsetWidth;
		const canScrollLeft = state.scrollLeft > 0;
		const canScrollRight = state.scrollLeft + offsetWidth < scrollWidth;

		if (scrollWidth !== scrollWidth) {
			setState(prevState => ({...prevState, scrollWidth: scrollWidth}));
		}

		if (offsetWidth !== offsetWidth) {
			setState(prevState => ({...prevState, offsetWidth: offsetWidth}));
		}

		if (canScrollLeft !== state.canScrollLeft) {
			setState(prevState => ({...prevState, canScrollLeft: canScrollLeft}));
		}

		if (canScrollRight !== state.canScrollRight) {
			setState(prevState => ({...prevState, canScrollRight: canScrollRight}));
		}
	};

	const getSubjectsOnTheEdge = (offsetLeft: number) => {
		let prev: HTMLDivElement = null;
		let middle: HTMLDivElement = null;
		let next: HTMLDivElement = null;

		const nodes = tilesContainer.current.children;

		for (let i = 0; i < nodes.length; i++) {
			const subjectDiv = nodes[i] as HTMLDivElement;

			if (subjectDiv.offsetLeft >= offsetLeft) {
				middle = subjectDiv;

				if (i > 0) {
					prev = nodes[i - 1] as HTMLDivElement;
				}

				if (i < nodes.length - 1) {
					next = nodes[i + 1] as HTMLDivElement;
				}

				return {prev, middle, next};
			}
		}

		return {prev, middle, next};
	};

	const scrollTo = (scrollLeft: number) => {

		if (scrollLeft < 0) {
			scrollLeft = 0;
		}

		if (state.scrollLeft === scrollLeft) {
			return;
		}
		setState(prevState => ({...prevState, scrollLeft: scrollLeft}));

		tilesContainer.current.scrollTo({left: scrollLeft, behavior: 'smooth'});
	};

	const scrollBack = (offsetLeft: number) => {
		const {prev, middle} = getSubjectsOnTheEdge(offsetLeft);

		if (prev) {
			return scrollTo(prev.offsetLeft);
		}

		if (middle) {
			return scrollTo(middle.offsetLeft);
		}
	};

	const scrollForward = (offsetLeft: number) => {
		const {middle, next} = getSubjectsOnTheEdge(offsetLeft);

		if (next) {
			return scrollTo(next.offsetLeft);
		}

		if (middle) {
			return scrollTo(middle.offsetLeft);
		}
	};

	useEffect(() => {
		recalculateScrollPosition();
	}, [
		subjects.length,
		tilesContainer.current?.scrollLeft,
		tilesContainer.current?.offsetLeft,
		state.scrollLeft,
		state.offsetWidth,
	]);
	
	return (
		<div className={styles.container}>
			<div className={styles.topHeaderSub}>
				{entityName}
			</div>
			<div className={styles.topHeaderMain}>
				Select a Subject Tab and Test to automatically generate bingo questions
			</div>
			{subjects.length > 0 &&
				<>
					<div className={styles.panel}>
						<div className={styles.miniTitle}>Subject Tabs</div>
						<div className={styles.subjectsContainer}>
							{state.canScrollLeft &&
								<>
									<div
										className={join(styles.control, styles.start)}
										onClick={() => scrollBack(Math.max(tilesContainer.current.scrollLeft - tilesContainer.current.offsetWidth, 0))}
									>
										<i className='fa fa-angle-double-left'/>
									</div>
									<div
										className={join(styles.control, styles.back)}
										onClick={() => scrollBack(tilesContainer.current.scrollLeft)}
									>
										<i className='fa fa-angle-left'/>
									</div>
								</>
							}
							<div className={styles.tilesContainer} ref={(r) => tilesContainer.current = r} >
								{selectedSubject && subjects.map((x, key) => {
									return (
										<BingoTile
											key={key}
											className={join(
												styles.subjectTile,
												x.id === selectedSubject.id && styles.selected,
												x.subjectType === SubjectType.Deployed && styles.district,
												!x.published && styles.isNotPublished,
												x.level === 'School' && styles.school,
											)}
											value={x.name}
											onClick={() => service.setSelectedSubject(x)}/>
									);
								})}
							</div>
							{state.canScrollRight &&
								<>
									<div
										className={join(styles.control, styles.next)}
										onClick={() => scrollForward(tilesContainer.current.scrollLeft)}
									>
										<i className='fa fa-angle-right'/>
									</div>
									<div
										className={join(styles.control, styles.end)}
										onClick={() => scrollForward(tilesContainer.current.scrollLeft + tilesContainer.current.scrollWidth)}
									>
										<i className='fa fa-angle-double-right'/>
									</div>
								</>
							}
						</div>
					</div>
					<div className={join(styles.panel, styles.testPanel)}>
						<div className={styles.miniTitle}>Tests</div>
						<div className={styles.selectedSubjectTitle}>
							Selected Subject Tab:
							<span className={styles.bold}>{selectedSubject.name}</span>
						</div>
						{loaded && <BingoTests
							service={service}
							subjectID={selectedSubject.id}
							subjectType={selectedSubject.subjectType}
						/>}
					</div>
				</>}
		</div>
	);
}
