import {HttpClient} from '@esgi/api';
import {LogoutEvent, userStorage, UserType, useUser} from '@esgi/core/authentication';
import {useEventEffect, dispatchAppEvent} from '@esgillc/events';
import {ContactUs} from '@esgi/features/support';
import {ClickOutsideListener, Overlay} from '@esgillc/ui-kit/core';
import {Loader} from '@esgillc/ui-kit/loader';
import {Slide} from '@esgillc/ui-kit/transition';
import {join} from '@esgillc/ui-kit/utils';
import {RefObject, useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import UserSettings from 'shared/modules/user-settings';
import {ShareScreenSession} from '../../modules/testing/share-screen-session/share-screen-session';
import {UserProfile} from '@esgi/main/features/user-profile';
import {HamburgerClickedEvent, HomePageClickedEvent} from '../header/header';
import {AccountIcon, ContactUsIcon, HelpIcon, LogoutIcon, SettingsIcon} from './icons';
import {RoutesMap} from '@esgi/main/libs/core';
import styles from './styles.module.less';
import './styles.less';

const logoutTimeout = 1000;

function logout(userID: number) {
	const deferredLogout = window.setTimeout(() => innerLogout(), logoutTimeout);

	const sSession = new ShareScreenSession();

	sSession.events.sessionEnded(() => {
		window.clearTimeout(deferredLogout);
		innerLogout();
	});

	sSession.events.reconnectAttempt(() => {
		window.clearTimeout(deferredLogout);
		innerLogout();
	});

	sSession.endSession(userID.toString());
}

async function innerLogout() {
	userStorage.clear();
	sessionStorage.clear();
	delete localStorage.shareScreenSessionCreated;

	try {
		await HttpClient.default.ESGIApi.post('Login', 'Logout').toPromise();
	} catch (e) {
		console.error(e);
	} finally {
		dispatchAppEvent(LogoutEvent, new LogoutEvent());
	}
}

export function LeftMenu(props: { triggerRef?: RefObject<HTMLButtonElement> }) {
	const [touched, setTouched] = useState(false);
	const [opened, setOpened] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const [showProfile, setShowProfile] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [showContactUs, setShowContactUs] = useState(false);

	const showContactUsHandler = () => setShowContactUs(true);

	useEventEffect(HamburgerClickedEvent, () => {
		if (opened) {
			setOpened(false);
		} else {
			setOpened(true);
			setTouched(true);
		}
	}, [opened]);

	useEffect(() => {
		global.showContactUs = showContactUsHandler;

		return () => {
			delete global.showContactUs;
		};
	});

	const user = useUser();
	const isAdmin = [UserType.C, UserType.D].includes(user?.userType);

	return (
		<>
			<Loader show={loaded} fullscreen/>
			<Overlay className={styles.overlay} hide={!opened} hideDuration='fast'/>
			<Slide in={opened} duration='fast' from='positive' axis='x'>
				<div className={join('left-menu', touched ? opened ? 'open' : 'closed' : '')}>
					<ClickOutsideListener
						active={opened}
						onClickedOutside={(event) => {
							const target = props.triggerRef.current;
							if (event.target === target || (event.target instanceof Node && target?.contains(event.target))) {
								return;
							}
							setOpened(false);
						}}
					>
						<div className='menu'>
							{isAdmin &&
								<div className='item mobile item-home'>
									<NavLink to={RoutesMap.adminHome} className='title'>
										Home
									</NavLink>
								</div>
							}
							<div className='item mobile item-home'>
								<NavLink
									to={RoutesMap.home}
									end
									className='title'
									onClick={() => dispatchAppEvent(HomePageClickedEvent)}
								>
									{isAdmin ? 'Data' : 'Home'}
								</NavLink>
							</div>
							<div className='item mobile item-home'>
								<NavLink to={RoutesMap.testExplorer} end className='title'>
									Test Explorer
								</NavLink>
							</div>
							<div className='item mobile item-home'>
								<NavLink to={RoutesMap.studentManager} end className='title'>
									Student Manager
								</NavLink>
							</div>
							<div className='item mobile item-home'>
								<NavLink to={RoutesMap.parentConferencer} end className='title'>
									Parent Conferencer
								</NavLink>
							</div>
							<div className='item mobile item-home'>
								<NavLink to={RoutesMap.assignmentCenter} end className='title'>
									Assignments
								</NavLink>
							</div>
							<div className='item item-my-account'>
								<button
									className='title'
									onClick={() => {
										setShowProfile(true);
										setOpened(false);
									}}
								>
									<AccountIcon className='icon'/>
									<span className='text'>My Account</span>
								</button>
							</div>
							<div className='item item-settings'>
								<button
									className='title'
									onClick={() => {
										setShowSettings(true);
										setOpened(false);
									}}
								>
									<SettingsIcon className='icon'/>
									<span className='text'>Settings</span>
								</button>
							</div>
							<div className='item item-contact-us'>
								<button
									className='title'
									onClick={() => {
										setShowContactUs(true);
										setOpened(false);
									}}
								>
									<ContactUsIcon/>
									<span className='text'>Contact Us</span>
								</button>
							</div>
							<div className='item item-help'>
								<a
									href='https://support.esgisoftware.com/hc/en-us/categories/201288126-ESGI'
									target='_blank'
									className='title'
									rel='noreferrer'
								>
									<HelpIcon/>
									<span className='text'>Help</span>
								</a>
							</div>
							<div className='item item-logout'>
								<button
									className='title'
									onClick={() => {
										logout(user?.userID);
										setOpened(false);
										setLoaded(true);
									}}
								>
									<LogoutIcon/>
									<span className='text'>Logout</span>
								</button>
							</div>
						</div>
					</ClickOutsideListener>
				</div>
			</Slide>
			{showProfile && <UserProfile close={() => setShowProfile(false)}/>}
			{showSettings && <UserSettings close={() => setShowSettings(false)}/>}
			{showContactUs &&
				<ContactUs
					onClose={() => setShowContactUs(false)}
					ticketSubmitted={() => setShowContactUs(false)}
				/>
			}
		</>
	);
}
