import {MutableRefObject, Ref} from 'react';

export const assignRefs = <R extends HTMLElement>(current: R, ...targetRefs: Ref<R>[]) => {
	targetRefs.forEach(targetRef => {
		if (targetRef) {
			if (typeof targetRef === 'function') {
				targetRef(current);
			} else if (typeof targetRef === 'object') {
				(targetRef as MutableRefObject<R>).current = current;
			}
		}
	});
};
