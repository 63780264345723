import React, {useEffect} from 'react';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {PieChartService} from 'shared/modules/reports/pie-chart/service/service';
import {join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {userStorage} from '@esgi/core/authentication';
import Filter from 'packages/app/src/shared/modules/reports/pie-chart/components/header/components/filter';
import {FilterType} from 'shared/modules/reports/pie-chart/service/models';
import {Checkbox} from '@esgillc/ui-kit/control';

import styles from './header.module.less';

interface HeaderProps {
	service: PieChartService;
	hierarchy: HierarchySnapshot;
	subjectTabID: number;
	noStudentsData: boolean;
	studentGradeLevels: number[];
	errorOccurred?: () => void;
}

export default function Header ({service, hierarchy, noStudentsData, subjectTabID}: HeaderProps) {
	const {currentGlobalSchoolYearID, userID} = userStorage.get();
	const settings = useBehaviorSubject(service.settings);
	const gradeLevelID = useBehaviorSubject(service.gradeLevelID);
	const headerLoaded = useBehaviorSubject(service.headerLoaded);

	const teachers = service.teachers;
	const classes = service.classes;
	const students = service.students;
	const gradeLevels = service.gradeLevels;

	useEffect(() => {
		service.getSettings({userID, hierarchy, globalSchoolYearID: currentGlobalSchoolYearID}, subjectTabID);
	}, []);

	return (
		settings && headerLoaded &&
				<div className={styles.header}>
					<div className={styles.container}>
						{settings.districtName &&
							<div>
								<span className={styles.bold}>District: </span>
								<span>{settings.districtName}</span>
							</div>
						}
						{(settings.schoolID != null || settings.districtName) &&
							<Filter
								label={'School'}
								type={FilterType.School}
								entity={settings.schools}
								entityID={settings.schoolID}
								onChange={e => service.onSchoolChange(e, hierarchy)}
							/>
						}
						{teachers &&
							<>
								<Filter
									label={settings.specialistGroups ? 'Specialist' : 'Teacher'}
									type={FilterType.Teacher}
									entity={teachers}
									entityID={settings.teacherID}
									onChange={e => service.onTeacherChange(e, hierarchy)}
								/>
								{settings.teacherID > 0
									&& (
										(classes?.length > 0)
										|| (settings?.groups && settings.groups?.length > 0)
										|| (settings?.specialistGroups && settings?.specialistGroups?.length > 0)
									)
									&&
									<>
										{classes &&
											<Filter
												label={'Class'}
												type={FilterType.Unity}
												entity={classes}
												entityID={settings.classID}
												onChange={e => service.onClassChange(e, hierarchy)}
											/>
										}
										{settings.groups &&
											<Filter
												label={'Group'}
												type={FilterType.Unity}
												entity={settings.groups}
												entityID={settings.groupID}
												onChange={e => service.onGroupChange(e)}
											/>
										}
										{settings.specialistGroups &&
											<Filter
												label={'Group'}
												type={FilterType.Unity}
												entity={settings.specialistGroups}
												entityID={settings.specialistGroupID}
												onChange={e => service.onSpecialistGroupChange(e)}
											/>
										}
										<Filter
											label={'Student'}
											type={FilterType.Student}
											entity={students}
											entityID={settings.studentID}
											onChange={e => service.onStudentChange(e)}
										/>
									</>
								}
							</>
						}
						<Filter
							label={'Subject Tab'}
							type={FilterType.Subject}
							entity={settings.subjects}
							entityID={settings.subjectID}
							onChange={e => service.onSubjectChange(e)}
						/>
						{gradeLevels &&
							<Filter
								label={'Grade'}
								type={FilterType.Grade}
								entity={gradeLevels}
								entityID={gradeLevelID}
								onChange={e => service.onGradeLevelChange(e)}
							/>
						}
					</div>
					{!noStudentsData &&
						<div className={styles.container}>
							<div className={styles.checkboxTitle}>Settings:</div>
							{settings.studentID === 0 &&
								<Checkbox
									className={join(styles.checkbox, settings.showEachStudent && styles.bold)}
									checked={settings.showEachStudent}
									onChange={service.onShowEachStudentChange}
								>
									Show each student
								</Checkbox>
							}
								<Checkbox
									className={join(styles.checkbox, settings.printInColor && styles.bold)}
									checked={settings.printInColor}
									onChange={service.onPrintInColorChange}
								>
									Print in color
								</Checkbox>
						</div>
					}
				</div>
	);
}
