import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {
	DataRow,
	InitResponse,
	Report,
	Request,
	SortModel,
	SortRule,
	Test,
	Track,
} from 'modules/reports/growth-report/types';
import Cache from 'modules/reports/growth-report/cache';
import {Comparator} from 'modules/reports/growth-report/comparator';
import {decapitalize} from 'modules/reports/growth-report/utils';
import {HierarchyInstance, HierarchyMode} from 'modules/hierarchy/core/models';

export class GrowthReportService extends BaseService {
	public showNumberOfStudents = new BehaviorSubject<boolean>(false);
	public showMarkingPeriods = new BehaviorSubject<boolean>(false);
	public subjectName = new BehaviorSubject<string>('');
	public tests = new BehaviorSubject<Test[]>([]);
	public tracks = new BehaviorSubject<Track[]>([]);
	public selectedTrack = new BehaviorSubject<Track>(null);
	public numberPeriods = new BehaviorSubject<(number | null)[]>([]);
	public headerColspan: number;
	public trackId = new BehaviorSubject<number>(null);
	public isTruncated = new BehaviorSubject<boolean>(false);
	public validNumberPeriods;
	public isPreAssessMode;

	private districtId: number;
	private globalSchoolYearId: number;
	private subjectId: number;
	private subjectType: string;
	private cacheKey: string;
	private hierarchy: HierarchyInstance;
	private rows = new BehaviorSubject<DataRow[]>([]);

	private sortRules: SortRule[] = [];
	public sortModel = new BehaviorSubject<SortModel>(null);

	private controllerName = 'reports/growth';
	private cache = new Cache();

	get totalColumn() {
		let total = 2;
		if (this.showMarkingPeriods.value) {
			total += this.numberPeriods.value.length;
		}
		if (this.showNumberOfStudents.value) {
			total += 1;
		}
		return total;
	}

	get tableRows() {
		return this.rows.value;
	}

	constructor() {
		super();
		this.cache = new Cache();
		this.showNumberOfStudents.subscribe((value) => {
			this.headerColspan = value ? 4 : 3;
			if (this.isPreAssessMode) {
				this.headerColspan -= 1;
			}
			this.updateShowNumberOfStudents(value);
		})
		this.selectedTrack.subscribe((value) => {
			if (!value) {
				return;
			}
			this.updateTrack(value.trackID, () => {
				let options = this.options;
				options.trackId = value.trackID;
				this.init(options).then((model: InitResponse) => {
					this.cacheKey = model.cacheKey;
					options.cacheKey = model.cacheKey;
					this.trackId.next(value.trackID);
					this.cache.putReport(options, model);
					this.sortModel.next(model.sortModel)
					this.initReport(model.report);
					this.sort(model.sortModel)
				});
			});
		})
	}

	initOptions(options) {
		this.districtId = options.districtId;
		this.globalSchoolYearId = options.globalSchoolYearId
		this.subjectId = options.subjectId;
		this.subjectType = options.subjectType;
		this.hierarchy = options.hierarchy;
		this.isPreAssessMode = this.hierarchy.mode === HierarchyMode.PreAssess;
		return this.getData()
	}

	private async getData() {
		const options = this.options;
		return this.init(options).then((model: InitResponse) => {
			this.cacheKey = model.cacheKey;
			options.cacheKey = model.cacheKey;
			this.isTruncated.next(model.isTruncated);

			options.showNumberOfStudents = String(model.showNumberOfStudents);
			this.showNumberOfStudents.next(model.showNumberOfStudents)
			this.cache.putReport(options, model);

			this.initModel(model);
		})
	}

	initModel(rawModel: InitResponse) {
		rawModel.sortModel.fieldName = decapitalize(rawModel.sortModel.fieldName);
		rawModel.report.sortRules = rawModel.report.sortRules.map((rule) => {
			rule.relatedSorts = rule.relatedSorts.map((item): SortModel => {
				return {
					...item, fieldName: decapitalize(item.fieldName),
				}
			})
			rule.fieldName = decapitalize(rule.fieldName);
			return rule;
		});
		this.tests.next(rawModel.report.tests);
		this.subjectName.next(rawModel.report.subjectName);
		this.tracks.next(rawModel.tracks);
		if (rawModel.report.track.trackID) {
			this.selectedTrack.next(rawModel.report.track);
		}
		this.showMarkingPeriods.next(!!rawModel.report.track.trackID);
		this.initReport(rawModel.report);
		this.sortRules = rawModel.report.sortRules;
		this.sortModel.next(rawModel.sortModel)
		this.sort(rawModel.sortModel)
	}

	initReport(report: Report) {
		this.validNumberPeriods = report.currentNumberPeriods;
		this.numberPeriods.next(report.rows[0].results[0].periodsPercent.slice(0, report.currentNumberPeriods));
		this.rows.next(report.rows);
	}

	removeCache(cacheKey) {
		return this.httpClient.ESGIApi.post(this.controllerName, 'remove-cache', {data: {cacheKey: cacheKey}}).toPromise();
	}

	changeIsTruncated(value: boolean) {
		this.isTruncated.next(value)
	}

	changeShowMarkingPeriods(value: boolean) {
		this.showMarkingPeriods.next(value)
		this.updateTrack(value ? this.tracks.value[0].trackID : null)
		console.log('TRACKS', this.tracks.value.length);
		if (this.tracks.value.length > 0 && value) {
			console.log('CHAGE EXISTING TRACK', this.tracks.value)
			this.selectedTrack.next(this.tracks.value[0]);
		}
	}

	changeShowNumberOfStudents(value: boolean) {
		this.showNumberOfStudents.next(value)
	}

	changeSelectedTrack(track: Track) {
		this.selectedTrack.next(track);
	}

	init(request) {
		request.hierarchy = this.hierarchy;
		return this.httpClient.ESGIApi.get(this.controllerName, 'init', request).toPromise();
	}

	updateTrack(trackId, callback = null) {
		this.httpClient.ESGIApi.post(this.controllerName, 'update-report-track', {TrackID: trackId})
			.subscribe(() => {
				if (callback) {
					callback();
				}
			});
	}

	updateShowNumberOfStudents(value) {
		this.httpClient.ESGIApi.post('reports/growth', 'update-show-number-of-students', {Value: value})
			.subscribe();
	}

	downloadExcel() {
		let options = this.options;
		options.sortModel.fieldName = options.sortModel.fieldName[0].toUpperCase() + options.sortModel.fieldName.slice(1);
		options.sortModel.direction = this.sortModel.value.direction;
		if (!this.showMarkingPeriods.value) {
			delete options.trackId;
		}
		let date = new Date();
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		let filename = 'Growth_report_' + year + '-' + month + '-' + day + '.xlsx';
		this.httpClient.ESGIApi.file('reports/growth', 'download-excel', filename, {...options, showNumberStudents: options.showNumberOfStudents}).subscribe(() => console.log('loaded'));
	}

	sortClicked(model: SortModel) {
		if (model.fieldName === this.sortModel.value.fieldName) {
			model.direction = this.changeSortDirection(model.direction);
		} else {
			model.direction = this.changeSortDirection('None');
		}
		this.sortModel.next(model);
		this.sort(model);
	}

	private sort(sortModel: SortModel) {
		let sortRule = null;
		for (let rule of this.sortRules) {
			if (rule.fieldName === sortModel.fieldName) {
				sortRule = rule;
				break;
			}
		}

		let additionalSortModels = [];
		if (sortRule != null) {
			additionalSortModels = sortRule.relatedSorts;
		}

		let comparator = new Comparator(sortModel, additionalSortModels);
		const updatedRows = this.rows.value.sort((left: DataRow, right: DataRow) => {
			return comparator.comparer(left, right);
		});
		this.rows.next(updatedRows);
	}

	changeSortDirection(oldDirection) {
		switch(oldDirection) {
			case 'Asc':
				return 'Desc';
			case 'Desc':
				return 'Asc';
			case 'None':
				return 'Asc';
			default:
				return 'None';
		}
	}

	private get options() {
		let request = new Request();
		request.trackId = this.trackId.value;
		request.districtId = this.districtId;
		request.globalSchoolYearId = this.globalSchoolYearId;
		request.subjectId = this.subjectId;
		request.subjectType = this.subjectType;
		request.showNumberOfStudents = String(this.showNumberOfStudents.value);
		request.sortModel = this.sortModel.value;
		request.hierarchy = this.hierarchy;
		request.cacheKey = this.cacheKey;
		return request;
	}
}
