import {useState, useMemo} from 'react';
import {TabItem} from '@esgi/main/features/teacher/home';
import {StudentTabId} from '../types';

export function useTabs() {
	const [activeTab, setActiveTab] = useState(StudentTabId.Classes);
	const tabItems = useMemo(() => [
		{
			value: StudentTabId.Classes,
			label: 'Classes',
			valueDescription: 'Classes',
		},
		{
			value: StudentTabId.Groups,
			label: 'Groups',
			valueDescription: 'Students Arranged in Groups',
		},
		{
			value: StudentTabId.Students,
			label: 'Students',
			valueDescription: 'All Students List',
		},
	].map<TabItem<StudentTabId>>((item) => ({
		...item,
		permitted: activeTab !== item.value,
		applyDisabledStyles: false,
		contentType: 'text',
	})), [activeTab]);

	return {activeTab, setActiveTab, tabItems};
}
