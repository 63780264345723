import {Fragment} from 'react';
import {Container, List, Header, Body, Sup} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Test, TestType} from '../../../../../../types';
import {getTestType} from '../../../../utils';

interface Props {
	test: Test;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	includeQuestionNotes: boolean;
}

const getCorrectAnswer = ({answers}: Test) =>
	answers.filter(({correct}) => correct);

const getIncorrectAnswer = ({answers}: Test) =>
	answers.filter(({correct}) => !correct);

const getCorrectAnswerNoteIndex = (
	index: number,
	answers: Test['answers'],
) => answers.slice(0, index + 1).filter(i => !!i.note).length;

const getIncorrectAnswerNoteIndex = (
	index: number,
	answers: Test['answers'],
	correctNotesCount: number,
) => answers.slice(0, index + 1).filter(i => !!i.note).length + correctNotesCount;

export function Answers(props: Props) {
	const {
		test,
		testResultsCorrectVerbiage,
		testResultsIncorrectVerbiage,
		includeQuestionNotes,
	} = props;

	return (
		<Container>
			<List data-cy='correct-answers' type='correct'>
				<Header data-cy='header'>
					<Text>{getTestType(test.type) === TestType.YN ? testResultsCorrectVerbiage : 'Score'}</Text>
				</Header>
				<Body data-cy='body'>
					{getTestType(test.type) === TestType.YN
						? getCorrectAnswer(test).map((answer, index) => (
								<Fragment key={`${index}-${answer.text}`}>
									<Text>{answer.text}</Text>
									{(answer.note && includeQuestionNotes) &&
										<Sup size='small' bold>
											{getCorrectAnswerNoteIndex(
												index,
												getCorrectAnswer(test)
											)}
										</Sup>
									}
									{index !== getCorrectAnswer(test).length - 1 ? ', ' : ''}
								</Fragment>
							))
						: test.totalCorrect
					}
				</Body>
			</List>
			<List data-cy='incorrect-answers' type='incorrect'>
				<Header data-cy='header'>
					<Text>{getTestType(test.type) === TestType.YN ? testResultsIncorrectVerbiage : 'Total Possible'}</Text>
				</Header>
				<Body data-cy='body'>
					{getTestType(test.type) === TestType.YN
						? getIncorrectAnswer(test).map((answer, index) => (
								<Fragment key={answer.text}>
									<Text>{answer.text}</Text>
									{(answer.note && includeQuestionNotes) &&
										<Sup size='small' bold>
											{getIncorrectAnswerNoteIndex(
												index,
												getIncorrectAnswer(test),
												test.answers.filter(({correct, note}) => correct && !!note).length,
											)}
										</Sup>
									}
									{index !== getIncorrectAnswer(test).length - 1 ? ', ' : ''}
								</Fragment>
							))
						: test.questionCount
					}
				</Body>
			</List>
		</Container>
	);
}
