import {ComponentPropsWithoutRef, forwardRef, useEffect} from 'react';
import {useDrawerContext} from '../../context/drawer-context';
import {createPortal} from 'react-dom';
import {ContentWrapper} from './content-wrapper.styled';

type Props = ComponentPropsWithoutRef<'div'>;

export const DrawerPanel = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
	const {setWithPanel, isPanelOpen, panelRef} = useDrawerContext();

	useEffect(() => {
		setWithPanel(true);

		return () => {
			setWithPanel(false);
		};
	}, []);

	if (!isPanelOpen || !panelRef || !panelRef.current) {
		return null;
	}

	return createPortal(<ContentWrapper {...props} ref={forwardedRef} />, panelRef.current);
});
