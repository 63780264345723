import {Modal} from '@esgillc/ui-kit/modal';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useTestSessionDetailsService} from '../../hooks';
import {FooterMain} from './footer-main';
import {FooterIEP} from './footer-iep';
import styles from './footer.module.less';

interface Props {
	onCancel: () => void;
}

export function Footer({onCancel}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const activeTab = useBehaviorSubject(service.activeTab$);

	return (
		<Modal.Footer className={styles.modalFooter}>
			{activeTab === 'details' &&
				<FooterMain onCancel={onCancel}/>
			}
			{activeTab === 'iep' &&
				<FooterIEP onCancel={onCancel}/>
			}
		</Modal.Footer>
	);
}
