import {EventBusManager, dispatchAppEvent} from '@esgillc/events';
import {ReportErrorEvent} from '@esgi/core/react';
import {BehaviorSubject, tap, of, mergeMap, Observable} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {DownloadOptions, FileType} from '../types';
import {V2TeachersPagesReportsItemAnalysisController} from '@esgi/contracts/esgi';
import {UpdateRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/teachers/pages/reports/item-analysis/update-request';
import {ChartPagesReadyEvent} from '../events';
import {getTemplatePdf} from '../utils';
import {ReportService} from './report-service';
import {getUser} from '@esgi/core/authentication';

export class DownloadService extends BaseService {
	public readonly pages$ = new BehaviorSubject<string[]>([]);
	public readonly downloaded$ = new BehaviorSubject(false);
	public readonly busyController = new BehaviorSubject(false);
	private reportService: ReportService;
	private readonly controller = new V2TeachersPagesReportsItemAnalysisController();
	private readonly eventBus = new EventBusManager();
	private readonly downloadParameters = {
		[FileType.PDF]: {
			method: 'download-pdf',
			extension: '.pdf',
		},
		[FileType.Excel]: {
			method: 'download-excel',
			extension: '.xlsx',
		},
	};
	private downloadParams: DownloadOptions;
	private user = getUser();

	constructor(reportService: ReportService) {
		super();

		this.reportService = reportService;
		this.eventBus.subscribe(
			ChartPagesReadyEvent,
			() => this.startDownloadReportPdf(),
		);
	}

	public override dispose() {
		this.controller.dispose();
	}

	public preview(request: UpdateRequest) {
		this.busyController.next(true);
		return this.controller.update(request).pipe(
			tap(() => this.busyController.next(false)),
		);
	}

	public downloadReport(fileType: FileType) {
		this.downloaded$.next(false);
		return this.getPayload(fileType).pipe(
			mergeMap((payload) => this.downloadFile(fileType, payload)),
			tap(() => this.downloaded$.next(true))
		);
	}

	public downloadReportPdf(params: DownloadOptions) {
		this.downloadParams = params;
		this.reportService.downloadingPdf$.next(true);
	}

	public downloadReportExcel(params: DownloadOptions) {
		this.downloadParams = params;
		this.downloadReport(FileType.Excel).subscribe();
	}

	public downloadDetails(html: string) {
		this.downloadFile(FileType.PDF, {html}).subscribe();
	}

	private downloadFile(type: FileType, payload: Record<string, any>) {
		const {method, extension} = this.downloadParameters[type];
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = `Item_Analysis_Report_${year}-${month}-${day}${extension}`;
		console.log('IAR: Start excel download request with filename: ', filename);
		return this.httpClient.ESGIApi.file(
			'v2/teachers/pages/reports/item-analysis',
			method,
			filename,
			payload,
		).pipe(
			tap(() => console.log('IAR: Request finished'))
		);
	}

	private getPayload(fileType: FileType) {
		if (fileType === FileType.PDF) {
			return this.getPayloadPdf();
		}
		return this.getPayloadExcel();
	}

	// console calls added to research by ESGI-23740
	private getPayloadExcel() {
		return new Observable((subscriber) => {
			const {
				classId,
				groupId,
				testId,
				subjectId,
				subjectType,
				sortOptionMode: sortQuestionsBy,
			} = this.downloadParams;

			console.log('IAR: Creating request params');

			const payload = {
				districtId: this.user.districtID,
				schoolId: this.user.schoolID,
				globalSchoolYearId: this.user.globalSchoolYearID,
				classId: classId === -1 ? 0 : classId,
				groupId,
				testId,
				subjectId,
				subjectType,
				sortQuestionsBy,
				gradeLevelId: 0,
			};

			console.log('IAR: Requst params created:', payload);

			console.log('IAR: downloadReportExcel called.');
			const hRoot = document.querySelector('#iar-chart .highcharts-root');
			if (!hRoot) {
				dispatchAppEvent(ReportErrorEvent, {message: `Uh oh. Something seems to be wrong on our end with export. Click Report to contact Customer Support (support@esgisoftware.com) for assistance.`});
				console.error('Unable to get hRoot');
				return;
			}

			const svg = hRoot.outerHTML;
			const width = this.reportService.getFullChartWidth();
			const height = 450;

			console.log('IAR: Generate imgsrc.');
			const imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)));
			let canvas = document.querySelector('#iarCanvas') as any;
			if (!canvas) {
				console.log('IAR: Create canvas.');
				const canvasElem = document.createElement('canvas');
				canvasElem.id = 'iarCanvas';
				document.body.appendChild(canvasElem);
			}
			canvas = document.querySelector('#iarCanvas') as any;
			const context = canvas.getContext('2d');
			canvas.setAttribute('width', width);
			canvas.setAttribute('height', height);

			console.log('IAR: Create img.');
			const image = new Image();
			image.src = imgsrc;
			image.onload = () => {
				console.log('IAR: Img onLoad clb executed.');
				context.drawImage(image, 0, 0);
				const canvasdata = canvas.toDataURL('image/png');
				subscriber.next({
					svgImage: canvasdata.substring(22),
					...payload,
				});
				subscriber.complete();
				canvas.parentNode.removeChild(canvas);
			};
		});
	}

	private getPayloadPdf() {
		const {
			classId,
			className,
			groupId,
			groupName,
			subjectName,
			testName,
		} = this.downloadParams;
		const {firstName, lastName} = this.user;
		const titles = [];
		titles.push({left: 'Teacher:', right: `${firstName} ${lastName}`});
		if (classId) {
			titles.push({left: 'Class:', right: className});
		}
		if (groupId) {
			titles.push({left: 'Group:', right: groupName});
		}
		titles.push({left: 'Subject:', right: subjectName});

		const html = getTemplatePdf(
			titles,
			testName,
			this.pages$.value,
			Array.from(document.querySelectorAll('#iar-chart .highcharts-container .highcharts-legend')).at(-1)?.innerHTML,
		);

		return of({html});
	}

	private startDownloadReportPdf() {
		if (!this.pages$.value.length) {
			return;
		}

		this.downloadReport(FileType.PDF).subscribe(
			() => this.reportService.downloadingPdf$.next(false)
		);
	}
}
