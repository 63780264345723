import {ChangeEvent} from 'react';
import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {EventBusManager} from '@esgillc/events';
import {getUser, UserInfo, UserType} from '@esgi/core/authentication';
import {StudentRosterToolEvents} from './events';
import {
	Notification,
	ServerConflictStudentModel,
	Step,
	UploadRosterResponse,
	ValidationStatus,
	ValidStudentModel,
} from './types';
import {ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {DictionaryItem} from 'modules/forms/students-form/types';

export class UploadRosterService extends BaseService {
	public step = new BehaviorSubject<Step>(Step.AddStudent);
	public notification = new BehaviorSubject<Notification>(Notification.None);
	public conflictedStudents = new BehaviorSubject<ServerConflictStudentModel[]>(null);
	public userID = new BehaviorSubject<number>(0);
	public userType = new BehaviorSubject<UserType>(UserType.V);
	public classID = new BehaviorSubject<number>(0);
	public specialistGroupID = new BehaviorSubject<number>(0);
	public schoolID = new BehaviorSubject<number>(0);
	public uploadedStudentsCount = new BehaviorSubject<number>(null);
	public duplicatesSaved = new BehaviorSubject<boolean>(false);
	public completedWithExistsStudents = new BehaviorSubject<boolean>(false);
	public className = new BehaviorSubject<string>('');
	public specialistGroupName = new BehaviorSubject<string>('');
	public validationData = new BehaviorSubject<string>('');
	public currentUser: UserInfo = getUser();
	public schoolForm = new FormGroup({
		school: new FormControl<DictionaryItem<number>[]>([], {validators: [Validators.required()]}),
	});

	public schoolFormStatus = new BehaviorSubject<ElementStatus>(ElementStatus.untouched);
	private eventBus = new EventBusManager();

	private makeSubscriptions = () => {
		this.schoolForm.onChanged.subscribe((value) => {
			this.schoolFormStatus.next(value.currState.value.school.length ? ElementStatus.valid : ElementStatus.invalid);
		});
	};
	private uploadedFile = new BehaviorSubject<ChangeEvent<HTMLInputElement>>(null);

	public init = (userID, userType, classID, schoolID, specialistGroupID, initialStep, uploadedFile: ChangeEvent<HTMLInputElement>) => {
		this.userID.next(userID);
		this.userType.next(userType);
		this.classID.next(classID);
		this.schoolID.next(schoolID);
		this.specialistGroupID.next(specialistGroupID);

		if (uploadedFile) {
			this.step.next(Step.None);
			this.uploadedFile.next(uploadedFile);
			this.fileUpload(this.uploadedFile.value);
		} else {
			if (initialStep) {
				this.step.next(initialStep);
			} else {
				this.step.next(Step.AddStudent);
			}
			this.makeSubscriptions();
		}
	};

	public fileUpload(e: ChangeEvent<HTMLInputElement>) {
		const {files} = e.target;
		if (files && files.length) {
			const file = files[0];
			const filename = file.name;
			const size = file.size;
			const parts = filename.split('.');
			const fileType = parts[parts.length - 1];

			if (this.validateFile(size, fileType)) {
				this.uploadFile(file);
			} else {
				this.notification.next(Notification.IncorrectFileType);
			}
		}
	}

	public downloadTemplate = () => {
		window.location.href = 'https://esgiwebfiles.s3.amazonaws.com/upload-roster-tool/template-2023.xlsx';
	};

	public uploadRosterFile = (file: File) => {
		const formData = new FormData();
		formData.append('file', file);

		const currentUserType = this.currentUser.userType;
		const isPA = currentUserType === UserType.PA
			|| (currentUserType === UserType.D && this.userType.value === UserType.PA);
		const controller = isPA ? '/student-roster-upload-tool/pre-access' : '/student-roster-upload-tool/teacher';

		if (isPA) {
			formData.append('userID', this.userID.value.toString());
			formData.append('schoolID', this.schoolID.value?.toString());
			formData.append('specialistGroupID', this.specialistGroupID.value?.toString());
		} else {
			formData.append('classID', this.classID.value.toString());
		}

		return this.httpClient.ESGIApi.post<UploadRosterResponse>(controller, 'upload-roster', formData);
	};

	private checkSize(size: number) {
		return size < 10000000;
	}

	private validateFile(size: number, type: string) {
		return this.checkSize(size) && this.checkType(type);
	}

	private checkType(type: string): boolean {
		const types = ['csv', 'xlsx'];
		return types.findIndex(x => x === type) !== -1;
	}

	private studentsCreatedNotify(createdStudents: ValidStudentModel[]) {
		this.eventBus.dispatch(StudentRosterToolEvents.Uploaded, StudentRosterToolEvents.Uploaded(
			createdStudents,
			this.currentUser.schoolID,
			this.currentUser.userID,
			this.classID.value,
			this.currentUser.districtID,
			this.specialistGroupID.value));
	}

	private uploadFile(file) {
		return this.uploadRosterFile(file).subscribe(res => {
			const status = ValidationStatus[res.validation.status];

			switch (status) {
				case ValidationStatus.IncorrectFileType : {
					this.notification.next(Notification.IncorrectFileType);
					break;
				}

				case ValidationStatus.UnsupportedFieldName: {
					this.validationData.next(res.validation.data);
					this.notification.next(Notification.UnsupportedFieldName);
					break;
				}

				case ValidationStatus.MissingRequiredInformation : {
					this.validationData.next(res.validation.data);
					this.notification.next(Notification.MissingRequiredInformation);
					break;
				}

				case ValidationStatus.InvalidField : {
					this.notification.next(Notification.InvalidField);
					this.validationData.next(res.validation.data);
					break;
				}

				case ValidationStatus.DuplicateIDInFile : {
					this.validationData.next(res.validation.data);
					this.notification.next(Notification.DuplicateIDInFile);
					break;
				}

				case ValidationStatus.MaximumStudents : {
					this.notification.next(Notification.MaximumStudents);
					break;
				}

				case ValidationStatus.PurchasingStudents: {
					this.notification.next(Notification.PurchasingStudents);
					this.validationData.next(res.validation.data);
					break;
				}

				case ValidationStatus.ConflictExists: {
					if (this.uploadedFile.value) {
						this.step.next(Step.ConflictResolution);
					} else {
						this.notification.next(Notification.ConflictExists);
					}

					this.conflictedStudents.next(res.conflictStudents);
					this.specialistGroupName.next(res.specialistGroupName);
					this.className.next(res.className);
					this.uploadedStudentsCount.next(res.uploadedStudentsCount);
					break;
				}

				default:
					this.step.next(Step.None);
			}

			if (status === ValidationStatus.Complete || status === ValidationStatus.CompleteWithStudentsExistInClass) {
				this.step.next(Step.None);
				this.notification.next(Notification.FileUploadIsComplete);

				if (status === ValidationStatus.CompleteWithStudentsExistInClass) {
					this.completedWithExistsStudents.next(true);
				}

				this.className.next(res.className);
				this.specialistGroupName.next(res.specialistGroupName);
				this.uploadedStudentsCount.next(res.createdStudents.length);
				if (res.createdStudents.length > 0) {
					this.studentsCreatedNotify(res.createdStudents);
				}
			}
		});
	}
}


