import {BaseService} from '@esgi/core/service';
import {Student} from '../../types';
import {InitEditClassResponse} from './types';
import {BehaviorSubject, tap} from 'rxjs';
import {Class} from '@esgi/main/libs/store';
import {V2TeachersClassesController} from '@esgi/contracts/esgi';

export class EditDataService extends BaseService {
	public students$ = new BehaviorSubject<Student[] | null>(null);
	public initialSelectedStudentsIds$ = new BehaviorSubject<Student['id'][] | null>(null);
	public initialClassName$ = new BehaviorSubject<string | null>(null);

	private controller = 'v2/teachers/modules/classes';
	private teachersClassesController = new V2TeachersClassesController();

	public init({classID}: {classID: Class['id']}) {
		return this.httpClient.ESGIApi.get<InitEditClassResponse>(this.controller, 'update', {classID})
			.pipe(
				tap(({availableStudents, studentsInClass, name}) => {
					this.students$.next([...availableStudents, ...studentsInClass]);
					this.initialSelectedStudentsIds$.next(studentsInClass.map(({id}) => id));
					this.initialClassName$.next(name);
				}),
			)
			.asObservable();
	}

	public updateClass({classID, name, studentIDs}: {classID: Class['id']; name: string; studentIDs: Student['id'][]}) {
		return this.httpClient.ESGIApi.post(this.controller, 'update', {classID, name, studentIDs});
	}

	public deleteClass({classID}: {classID: Class['id']}) {
		return this.teachersClassesController.remove({classID});
	}

	public override dispose() {
		super.dispose();
		this.teachersClassesController.dispose();
	}
}
