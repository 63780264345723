import {Checkbox} from '@esgillc/ui-kit/control';
import React from 'react';
import {Test} from '../models';
import styles from '../styles.module.less';

type Props = {
	test: Test,
	checked: boolean,
	clicked: () => void,
}

export function Row(props: Props): JSX.Element {
	return <tr data-name={props.test.name}>
		<td>
			<div className={styles.checkedColumn}>
				<Checkbox checked={props.checked} onClick={props.clicked}/>
			</div>
		</td>
		<td className={styles.testName}>
			<span>
				{props.test.name}
			</span>
		</td>
	</tr>;
}
