import {useSpring, config} from 'react-spring';
import {LineModel} from '../types';

export function useBarAnimation(bar: LineModel) {
	return useSpring({
		from: {
			y: bar.y1,
		},
		to: {
			y: bar.y2,
		},
		config: {
			...config.molasses,
			clamp: true,
		},
	});
}
