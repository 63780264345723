import {ThemeColorKeys} from '@esgi/ui';

export enum FontSizeType {
	Small = '30pt',
	Medium = '40pt',
	Large = '62pt',
}

export enum FontStyleType {
	Default = 'Trace with guidelines',
}

export type OptionItem = {
	value: FontSizeType | FontStyleType;
	label: string;
};

export enum UnitType {
	Class,
	Group,
	None,
}

export interface UnitData {
	id: number;
	type: UnitType;
	name?: string;
}

export interface StudentWithUnit {
	studentId: number;
	unitData: UnitData,
}

export type ChangeFunction<T> = (value: T) => void;

export interface GradeRange {
	label: string,
	from: number,
	to: number,
	color: ThemeColorKeys,
	labelColor: ThemeColorKeys,
}

export type SelectableListItem = {
	value: string,
	label: string,
	disabled: boolean
}

export enum NotTested {
	NT,
	Zero,
}

export enum MarkingPeriod {
	All,
	Current,
}

export enum DisplayResults {
	Score,
	Percent,
}
