import type {SVGProps} from 'react';

export function StudentProgress(props: SVGProps<SVGSVGElement>): JSX.Element {
	return <svg width='23' height='16' viewBox='0 0 23 16' fill='none' {...props}>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M5.88904 0C3.68015 0 1.88904 1.79111 1.88904 4C1.88904 6.20889 3.68015 8 5.88904 8C8.09793 8 9.88904 6.20889 9.88904 4C9.88904 1.79111 8.09793 0 5.88904 0ZM5.88904 1.77778C7.11659 1.77778 8.11126 2.77244 8.11126 4C8.11126 5.22756 7.11659 6.22222 5.88904 6.22222C4.66148 6.22222 3.66682 5.22756 3.66682 4C3.66682 2.77244 4.66148 1.77778 5.88904 1.77778Z'
		      fill='#333333'/>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M2.72179 9.19467C1.09156 9.86756 0 11.5164 0 13.3333V15.1111C0 15.6018 0.398222 16 0.888889 16H10.6667C11.1573 16 11.5556 15.6018 11.5556 15.1111V13.3333C11.5556 11.5591 10.4578 9.95022 8.83378 9.24978C8.60444 9.15111 8.33867 9.14845 8.11111 9.24978C7.32444 9.60267 6.55822 9.77778 5.77778 9.77778C5 9.77778 4.2311 9.59289 3.41688 9.22222C3.19288 9.12 2.94934 9.10045 2.72179 9.19467ZM3.06399 11.0133C3.90399 11.3316 4.91644 11.5556 5.77778 11.5556C6.632 11.5556 7.60267 11.3467 8.42578 11.0427C9.22845 11.4996 9.77778 12.3547 9.77778 13.3333V14.2222H1.77778V13.3333C1.77778 12.2978 2.36088 11.4249 3.06399 11.0133Z'
		      fill='#333333'/>
		<path fillRule='evenodd' clipRule='evenodd'
		      d='M13.5153 6.30759C14.1818 5.54587 15.3536 5.50671 16.0695 6.22222L17.5604 7.93908C17.6617 8.04032 17.8272 8.03594 17.923 7.9295L21.4417 4.07004C21.7172 3.75548 22.1968 3.72699 22.5076 4.00672C22.8125 4.28114 22.8403 4.74963 22.57 5.05823L19.0379 8.93294C18.3673 9.67807 17.209 9.70867 16.5 9L15.0091 7.28315C14.9068 7.18093 14.7394 7.18652 14.6442 7.29534L13 9.24978C12.7272 9.56151 12.2534 9.5931 11.9417 9.32034C11.63 9.04758 11.5984 8.57376 11.8711 8.26203L13.5153 6.30759Z'
		      fill='#333333'/>
	</svg>;
}