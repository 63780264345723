import {styled} from '@esgi/ui/theme';
import {GridBox, SelectableList} from '@esgi/ui/layout';

export {SelectableList} from '@esgi/ui/layout';

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',

	variants: {
		isListOpen: {
			true: {
				'&:not(:last-child)': {
					marginBottom: '18px',
				},
			},

			false: {
				'&:not(:last-child)': {
					marginBottom: '6px',
				},
			},
		},
	},
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	variants: {
		defaultCursor: {
			true: {
				cursor: 'default',
			},
		},
	},
});

export const SelectableLisItemContent = styled(GridBox, {
	paddingTop: '2px',
	paddingBottom: '2px',
});

export const SelectableListItem = styled(SelectableList.Item, {
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	gap: 10,
	alignItems: 'center',

	'& > svg': {
		width: 24,
		height: 24,
		alignSelf: 'start',
	},

	'&:before': {
		content: '',
		width: '16px',
		height: '16px',
		display: 'inline-block',
		backgroundPosition: 'center',
		backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Crect width='16' height='16' rx='4' fill='%23EBEBEB'/%3E%3C/svg%3E")`,
	},

	variants: {
		checked: {
			true: {
				'&:before': {
					backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='4' width='16' height='16' rx='4' fill='%23DCEFF9'/%3E%3Cpath d='M15.0687 9C14.8963 9 14.7165 9.06271 14.5845 9.20489L11.4062 12.6683C11.2331 12.8564 11.0586 12.8214 10.9226 12.6005L10.2491 11.5068C10.0423 11.1714 9.61126 11.0781 9.30212 11.3012C8.9923 11.525 8.90611 11.9917 9.1122 12.3264L9.78571 13.4201C10.3939 14.4073 11.5786 14.5327 12.3538 13.6942L15.553 10.2534C15.8157 9.9683 15.8157 9.48999 15.553 9.20489C15.421 9.06271 15.2412 9 15.0687 9Z' fill='%230084CC'/%3E%3C/svg%3E%0A")`,
				},
			},
		},
	},
});
