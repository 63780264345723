import {Menu, MenuItem} from '@esgillc/ui-kit/menu';
import React, {RefObject, useMemo} from 'react';
import {userStorage, UserType} from '@esgi/core/authentication';
import styles from './context-menu.module.less';

type Props = {
	renameClicked: () => any;
	hideClicked: () => any;
	show: boolean;
	close: () => any;
	relatedElementRef: RefObject<HTMLDivElement>;
}

export function ContextMenu(props: Props): JSX.Element {
	const currentUser = userStorage.get();

	const left = useMemo(() => {
		if (props.relatedElementRef.current) {
			const parent = props.relatedElementRef.current.offsetParent as HTMLElement;
			return props.relatedElementRef.current.offsetLeft + parent.offsetLeft - parent.scrollLeft + props.relatedElementRef.current.offsetWidth - 24;
		}
	}, [props.show]);

	return <div className={styles.menuHolder}>
		<Menu show={props.show}
		      keepFocusInside
		      style={{left}}
		      onEscPressed={props.close}
		      onClickOutside={props.close}
		      className={styles.menu}>
			<MenuItem onSelect={props.renameClicked}>
				{currentUser.userType === UserType.T ? 'Rename subject tab' : 'Edit subject tab'}
			</MenuItem>
			<MenuItem onSelect={props.hideClicked}>
				Hide subject tab
			</MenuItem>
		</Menu>
	</div>;
}
