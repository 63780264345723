import {Dispatch, useCallback} from 'react';
import {Assignment} from '../../types';
import {AssignmentService} from '../services/assignment-service';
import {useOpenAssignmentList} from '../../hooks/use-open-assignment-list';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {AssignmentInfo, StudentInstructionsAlertState} from '../types';
import {AssignmentState} from '@esgi/main/kits/assignments';
import {dispatchAppEvent} from '@esgillc/events';
import {RemoveAssignmentFromList, UpdateAssignmentInList} from '../../layout/events';
import {LocalStorageService} from '../services/local-storage-service';

export function useAssignmentActions({
	assignmentService,
	snackbarRef,
	setStudentInstructionsAlertState,
	localStorageService,
}: {
	assignmentService: AssignmentService;
	snackbarRef: React.RefObject<SnackbarManager>;
	setStudentInstructionsAlertState: Dispatch<StudentInstructionsAlertState>;
	localStorageService: LocalStorageService;
}) {
	const openAssignmentList = useOpenAssignmentList();

	const onDeleteAssignment = useCallback(
		({assignmentId, assignmentName}: {assignmentId: AssignmentInfo['id']; assignmentName: string}) => {
			assignmentService.deleteAssignmentRequest();

			dispatchAppEvent(RemoveAssignmentFromList, new RemoveAssignmentFromList(assignmentId));
			openAssignmentList();

			snackbarRef.current?.showSnackbar(`${assignmentName} has been successfully deleted.`);
		},
		[assignmentService, openAssignmentList, snackbarRef],
	);

	const onAssignmentComplete = useCallback(
		({assignmentId, assignmentName}: {assignmentId: Assignment['id']; assignmentName: string}) => {
			const completeDate = new Date().toDateString();
			assignmentService.completePublishedAssignment({completedDate: completeDate});

			dispatchAppEvent(
				UpdateAssignmentInList,
				new UpdateAssignmentInList(assignmentId, {
					completed: completeDate,
					state: AssignmentState.Completed,
				}),
			);

			snackbarRef.current?.showSnackbar(`You have successfully completed '${assignmentName}'.`);
		},
		[assignmentService, snackbarRef],
	);

	const onPostNotStartedAssignment = useCallback(
		({assignmentId, assignmentName}: {assignmentId: Assignment['id']; assignmentName: string}) => {
			assignmentService.postNotStartedAssignment();

			if (!localStorageService.isBannedModal) {
				setStudentInstructionsAlertState({
					assignmentName,
				});
			}

			dispatchAppEvent(
				UpdateAssignmentInList,
				new UpdateAssignmentInList(assignmentId, {
					state: AssignmentState.Published,
				}),
			);

			snackbarRef.current?.showSnackbar(`A new assignment, '${assignmentName}', has been created.`);
		},
		[assignmentService, localStorageService, setStudentInstructionsAlertState, snackbarRef],
	);

	const onCreateAssignment = useCallback(
		({assignmentId, assignmentName}: {assignmentId: Assignment['id']; assignmentName: string}) => {
			assignmentService.postNotStartedAssignment();

			dispatchAppEvent(
				UpdateAssignmentInList,
				new UpdateAssignmentInList(assignmentId, {
					state: AssignmentState.Published,
				}),
			);

			snackbarRef.current?.showSnackbar(`'${assignmentName}' has now been posted to students.`);
		},
		[assignmentService, snackbarRef],
	);

	return {onDeleteAssignment, onAssignmentComplete, onPostNotStartedAssignment, onCreateAssignment};
}
