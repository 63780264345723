import {useStream} from '@esgillc/ui-kit/utils';
import {first} from 'underscore';
import {useCallback, useMemo} from 'react';
import {Label} from '@esgillc/ui-kit/form-control';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {userStorage} from '@esgi/core/authentication';
import {YearModel} from '../../models/models';
import {SchoolYearsService} from '../../services/school-years-service';

import styles from './styles.module.less';

type Props = {
	schoolYearsService: SchoolYearsService;
}

export function SchoolYearSelector(props: Props): JSX.Element {
	const schoolYears = useStream(props.schoolYearsService.schoolYears$, []);
	const currentGlobalSchoolYearID = useStream(props.schoolYearsService.currentSchoolYearID$);
	const selectedGlobalSchoolYearID = useStream(props.schoolYearsService.selectedSchoolYearID$);

	const schoolYearSelected = useCallback((year: YearModel[]) => {
		const selectedYearID = first(year.map(y => y.globalSchoolYearID));
		props.schoolYearsService.switch(selectedYearID);
	}, [selectedGlobalSchoolYearID]);

	const selectedYear = useMemo(
		() => schoolYears.filter(y => y.globalSchoolYearID === selectedGlobalSchoolYearID),
		[selectedGlobalSchoolYearID, schoolYears],
	);

	if (!schoolYears) {
		return;
	}

	return <div className={styles.schoolYearSelector}>
		<Label>School Year</Label>
		<div className={styles.selector}>
			<Dropdown optionName='name'
			          className={styles.dropdown}
			          value={selectedYear}
			          setValue={schoolYearSelected}>
				{schoolYears.map((v) => <Option key={v.globalSchoolYearID} value={v}>{v.name}</Option>)}
			</Dropdown>
			<div className={styles.warning}>
				{currentGlobalSchoolYearID !== selectedGlobalSchoolYearID &&
					<a href='https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Year'
					   target='_blank'
					   title="You're not viewing the current school year. Click the icon to learn how to switch school years"
					   rel='noreferrer'><i className='fa fa-warning' style={{color: '#FFAC1E'}}/>
					</a>}
			</div>
		</div>
	</div>;
}
