import {Drawer} from '@esgi/main/kits/common';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const DrawerHeader = styled(GridBox, {
	alignItems: 'center',
	justifyContent: 'space-between',
	gridAutoFlow: 'column',
	backgroundColor: '$surface',
	gap: '$6',
	padding: 20,
	marginRight: -Drawer.drawerPaddingInline,
	marginLeft: -Drawer.drawerPaddingInline,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: '$border',
});

export const ActionButtons = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 20,
});

export const ActionButtonsDivider = styled(Box, {
	width: 1.5,
	height: 20,
	borderRadius: 1,
	backgroundColor: '$border',
});

export const DrawerBody = styled(Drawer.Body, {
	backgroundColor: '$background',
});
