import {resolvedPromise} from '@esgi/deprecated/utils';
import {Module, Renderable, ValidationPromise} from '@esgi/deprecated/knockout';
import {UserInfo} from '@esgi/core/authentication';
import {
	Container, EmailDestination,
	ExportTypeModel,
	Helper,
	ObsTestModel,
	SftpDestination,
	TemplateModel,
	TestItem,
} from 'shared/modules/export/export';
import {ScheduleSection} from 'shared/modules/export/edit-template/schedule-section';
import {Loader} from '@esgi/deprecated/jquery';
import React from 'react';
import './edit-template.less';

export class EditTemplateOptions {
	showScheduleOptions: boolean;
	initTemplate: () => JQueryPromise<TemplateModel>;
	initContainer: () => JQueryPromise<Container>;
}


export class EditTemplateModule extends Module {

	private context: UserInfo;
	private options: EditTemplateOptions;
	private helpers: Helper;
	private schedule: ScheduleSection;
	private rawContainer: Container;

	public loader: Loader;

	constructor(context, options: EditTemplateOptions) {
		super();
		this.context = context;
		this.options = options;
		this.schedule = new ScheduleSection();
		this.helpers = new Helper();
	}

	tests = {
		searchBox: ko.observable(''),
		all: ko.observableArray<ObsTestModel>([]),
		visible: ko.pureComputed(() => {
			let searchValue = this.tests.searchBox().toLowerCase();
			let arr = new Array<ObsTestModel>();
			if (searchValue === '') {
				arr = this.tests.all();
			} else {
				for (let i = 0; i < this.tests.all().length; i++) {
					let test = this.tests.all()[i];
					if (test.name.toLowerCase().indexOf(searchValue) >= 0
						|| test.creatorName.toLowerCase().indexOf(searchValue) >= 0
						|| test.subjectName.toLowerCase().indexOf(searchValue) >= 0
						|| test.contentArea.toLowerCase().indexOf(searchValue) >= 0) {
						arr.push(test);
					}
				}
			}

			this.tests.allVisible(true);
			for (let i = 0; i < arr.length; i++) {
				if (!arr[i].selected()) {
					this.tests.allVisible(false);
					break;

				}
			}
			return arr;
		}),
		selected: ko.pureComputed(() => {
			let arr = new Array<ObsTestModel>();
			for (let i = 0; i < this.tests.all().length; i++) {
				let test = this.tests.all()[i];
				if (test.selected()) {
					arr.push(test);
				}
			}

			this.tests.allSelected(arr.length !== 0);

			return arr;
		}),
		allVisible: ko.observable<boolean>(),
		allSelected: ko.observable<boolean>(),

	};

	types = {
		all: ko.observableArray<ExportTypeModel>(),
	};

	templateModel = {
		raw: new TemplateModel(),
		obs: {
			templateName: ko.observable<string>(''),
			schedule: ko.observable(false),
			exportType: ko.observable(''),
			teacherIdType: ko.observable('importIDN'),
			studentIdType: ko.observable('importIDN'),
			destinations: {
				email: {
					enable: ko.observable(false),
					emails: ko.observable(''),
				},
				sftp: {
					enable: ko.observable(false),
					address: ko.observable(''),
					login: ko.observable(''),
					password: ko.observable(''),
				},
			},
		},
	};


	private initContainer(rawContainer: Container) {
		this.rawContainer = rawContainer;
		for (let i = 0; i < rawContainer.tests().length; i++) {
			this.tests.all.push(new ObsTestModel(rawContainer.tests()[i]));
		}

		this.types.all = rawContainer.exportTypes;

		this.templateModel.obs.destinations.sftp.enable(rawContainer.defaultSftpDestination.enable);
		this.templateModel.obs.destinations.sftp.login(rawContainer.defaultSftpDestination.sftpLogin);
		this.templateModel.obs.destinations.sftp.address(rawContainer.defaultSftpDestination.sftpAddress);


		this.templateModel.obs.destinations.email.enable(rawContainer.defaultEmailDestination.enable);
		this.templateModel.obs.destinations.email.emails(rawContainer.defaultEmailDestination.emailAddresses.join('; '));
	}

	private initTemplate(template: TemplateModel) {
		this.templateModel.raw = template;
		this.templateModel.obs.templateName(template.templateName);
		this.templateModel.obs.schedule(template.schedule);
		this.templateModel.obs.exportType(template.exportType);
		this.templateModel.obs.teacherIdType(template.teacherIDN);
		this.templateModel.obs.studentIdType(template.studentIDN);
		this.schedule.init(template.scheduleCronExpression);

		for (let i = 0; i < template.tests.length; i++) {
			let test = this.helpers.getSelectedTest(this.tests.all(), template.tests[i]);
			if (test) {
				test.selected(true);
			}
		}

		this.tests.allSelected(true);

		for (let i = 0; i < this.tests.all().length; i++) {
			if (!this.tests.all()[i].selected()) {
				this.tests.allSelected(false);
				break;
			}
		}

		if (template.emailDestination != null) {
			this.templateModel.obs.destinations.email.enable(template.emailDestination.enable);
			let emails = template.emailDestination.emailAddresses;
			if (emails != null && emails.length > 0) {
				this.templateModel.obs.destinations.email.emails(emails.join('; '));
			}

		}

		if (template.sftpDestination != null) {
			this.templateModel.obs.destinations.sftp.enable(template.sftpDestination.enable);
			this.templateModel.obs.destinations.sftp.address(template.sftpDestination.sftpAddress);
			this.templateModel.obs.destinations.sftp.login(template.sftpDestination.sftpLogin);
			this.templateModel.obs.destinations.sftp.password(template.sftpDestination.sftpPassword);
		}
	}

	load() {
		return this.options.initContainer().then((response) => {
			this.initContainer(response);
			return this.options.initTemplate().then((template) => {
				this.initTemplate(template);

			});
		});
	}

	view = {
		clearSearchBox: () => {
			this.tests.searchBox('');
		},
		visibleTestClicked: (test: ObsTestModel) => {
			test.selected(!test.selected());
		},
		selectedTestsClicked: () => {
			let val = this.tests.allSelected();
			let selectedTests = this.tests.selected();
			for (let i = 0; i < selectedTests.length; i++) {
				selectedTests[i].selected(val);
			}

			return true;
		},
		allTestsClicked: () => {
			let val = this.tests.allVisible();
			let visibleTests = this.tests.visible();
			for (let i = 0; i < visibleTests.length; i++) {
				visibleTests[i].selected(val);
			}
			return true;
		},
	};
	ui = {
		initValidations: () => {
			this.validations.tests.init($('#selected-tests', this.rootElement), () => {
				let promise = $.Deferred();
				if (this.tests.selected().length > 0) {
					return promise.resolve();
				}
				return promise.reject('Please select at least 1 test to export.');
			});

			this.validations.templateName.init($('#name-validation-icon', this.rootElement), () => {
				let promise = $.Deferred();
				let name = this.templateModel.obs.templateName();
				name = name != null ? name.trim().toLowerCase() : name;
				if (name.length > 0) {
					let otherTempaltes = this.rawContainer.templates();
					for (let i = 0; i < otherTempaltes.length; i++) {
						let otherTemplate = otherTempaltes[i];
						if (name === otherTemplate.templateName.toLowerCase() && this.templateModel.raw.templateID !== otherTemplate.templateID) {
							return promise.reject('Please enter a unique name for this template.');
						}
					}
				} else {
					return promise.reject('Template Name is Required');
				}
				return promise.resolve();
			});

			this.validations.destinationEmails.init($('#email-validation-icon', this.rootElement), () => {
				let promise = $.Deferred();
				if (this.templateModel.obs.schedule() === false) {
					return promise.resolve();
				}
				if (this.templateModel.obs.destinations.email.enable() === false) {
					return promise.resolve();
				}
				let emails = this.templateModel.obs.destinations.email.emails();
				emails = emails != null ? emails.trim() : emails;
				if (emails.length <= 0) {
					return promise.reject('Emails is Required');
				}
				return promise.resolve();
			});

			this.validations.destinationSftp.init($('#sftp-validation-icon', this.rootElement), () => {
				let promise = $.Deferred();
				if (this.templateModel.obs.schedule() === false) {
					return promise.resolve();
				}
				if (this.templateModel.obs.destinations.sftp.enable() === false) {
					return promise.resolve();
				}
				let address = this.templateModel.obs.destinations.sftp.address();
				address = address != null ? address.trim() : address;
				if (address.length <= 0) {
					return promise.reject('SFTP Address is Required');
				}
				return promise.resolve();
			});

			this.validations.repeatOn.init($('#repeat-validation', this.rootElement), () => {
				let promise = $.Deferred();
				if (this.templateModel.obs.schedule() === false) {
					return promise.resolve();
				}

				this.schedule.validate().then(() => {
					promise.resolve();
				},
				() => {
					promise.reject('You should select at least one day.');
				});
				return promise;
			});

		},
	};

	extractTemplate(): JQueryPromise<TemplateModel> {
		let promise = $.Deferred<TemplateModel>();
		this.validations.validateAll().done(() => {
			let template = this.templateModel.raw;
			template.templateName = this.templateModel.obs.templateName();
			template.exportType = this.templateModel.obs.exportType();
			template.studentIDN = this.templateModel.obs.studentIdType();
			template.teacherIDN = this.templateModel.obs.teacherIdType();
			template.tests = this.tests.selected().map((item) => {
				return new TestItem(item.testID, item.subjectId, item.subjectType);
			});
			if (this.options.showScheduleOptions) {
				template.schedule = this.templateModel.obs.schedule();
				template.scheduleCronExpression = this.schedule.getCronExpression();

				if (template.sftpDestination == null) {
					template.sftpDestination = new SftpDestination();
				}
				template.sftpDestination.enable = this.templateModel.obs.destinations.sftp.enable();
				template.sftpDestination.sftpAddress = this.templateModel.obs.destinations.sftp.address();
				template.sftpDestination.sftpLogin = this.templateModel.obs.destinations.sftp.login();
				template.sftpDestination.sftpPassword = this.templateModel.obs.destinations.sftp.password();

				if (template.emailDestination == null) {
					template.emailDestination = new EmailDestination();
				}
				template.emailDestination.enable = this.templateModel.obs.destinations.email.enable();
				template.emailDestination.emailAddresses = this.templateModel.obs.destinations.email.emails().split(';');

			}
			promise.resolve(template);
		}).fail(() => {
			promise.reject();
		});
		return promise.promise();
	}

	private validations = {
		tests: new ValidateField(),
		templateName: new ValidateField(),
		destinationEmails: new ValidateField(),
		destinationSftp: new ValidateField(),
		repeatOn: new ValidateField(),
		validateAll: (): JQueryPromise<any> => {
			return $.when(this.validations.tests.validate(),
				this.validations.templateName.validate(),
				this.validations.destinationEmails.validate(),
				this.validations.destinationSftp.validate(),
				this.validations.repeatOn.validate(),
			);
		},
	};

	afterRender(rootElement) {
		this.rootElement = rootElement;
		setTimeout(() => {
			this.ui.initValidations();
			this.loader = new Loader($('.edit-template-modal .modal-content'));
			let tables = $('.tests-container .table', this.rootElement);
			for (let i = 0; i < tables.length; i++) {
				let qtips = $('.tooltip-with-message', this.rootElement);
				qtips.each((index, qtip) => {
					let message = $(qtip).find('.tooltip-message').text();
					$(qtip).qtip({
						content: {
							text: message,
						},
						position: {
							at: 'top center',
							my: 'bottom center',
						},
					});
				});
			}
		}, 1);

		return Renderable.resolvedDeferred;
	}

	template = () => <div className='edit-template' data-bind='afterRender: true'>
		<div className='columns'>
			<div className='settings-column'>
				<div className='setting-container'>
					<div>
						Template Name
						<sup>
							<i className='fa fa-asterisk' aria-hidden='true'/>
						</sup>
					</div>
					<div>
                        <span className='input-icon input-icon-right template-name-input'
                              data-bind="css: {'validation-fail':!validations.templateName.validationStatus()}">
                            <input type='text' data-bind='textInput: templateModel.obs.templateName'/>
                            <i className='ace-icon fa fa-times' id='name-validation-icon'
                               data-bind='visible: !validations.templateName.validationStatus()'/>
                        </span>
					</div>
				</div>

				<div className='setting-container'>
					<div>
						Export Type
						<sup>
							<i className='fa fa-asterisk' aria-hidden='true'/>
						</sup>
					</div>
					<select className='form-control'
					        data-bind="options: types.all,optionsText: 'displayName', optionsValue:'value', value: templateModel.obs.exportType, chosen: { disable_search_threshold: 10, width:'100%', search_contains: true}"/>
				</div>

				<div className='setting-container row-container'>
					<div className='helper'>
						<i data-bind="bsTooltip:{placement:'bottom'}"
						   title="Do you roster via Clever? If not, no adjustments are needed. If so, select &#34;Export ID&#34; so your district's SIS ID for each teacher and student is included in the export."
						   className='tooltip-with-message fa fa-question-circle'/>
					</div>

					<div className='option'>
						<span className='title'>Teacher ID Type:</span>
						<span className='val'><input type='radio' name='teacherIdTypeEdit' value='importIDN'
						                             data-bind='checked: templateModel.obs.teacherIdType'/> Import ID</span>
						<span className='val'><input type='radio' name='teacherIdTypeEdit' value='exportIDN'
						                             data-bind='checked: templateModel.obs.teacherIdType'/> Export ID</span>
					</div>

				</div>

				<div className='setting-container row-container'>
					<div className='helper'>
					</div>
					<div className='option'>
						<span className='title'>Student ID Type:</span>
						<span className='val'><input type='radio' name='studentIdTypeEdit' value='importIDN'
						                             data-bind='checked: templateModel.obs.studentIdType'/> Import ID</span>
						<span className='val'><input type='radio' name='studentIdTypeEdit' value='exportIDN'
						                             data-bind='checked: templateModel.obs.studentIdType'/> Export ID</span>
					</div>

				</div>


				<div className='setting-container row-container' data-bind='visible: options.showScheduleOptions'>
					<div>Enable Schedule</div>
					<label className='checkbox-theme'>
						<input type='checkbox' className='ace' data-bind='checked: templateModel.obs.schedule'/>
						<span className='lbl'/>
					</label>
				</div>


				<div className='setting-container schedule-section'
				     data-bind='fadeVisible: options.showScheduleOptions && templateModel.obs.schedule()'>
					<ko data-bind='with: schedule'>
						<div className='repater'>
							<span id='repeat-validation'>Repeat on:</span>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: model.days.Sunday'/>
								<span className='lbl'>Su</span>
							</label>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: model.days.Monday'/>
								<span className='lbl'>Mo</span>
							</label>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: model.days.Tuesday'/>
								<span className='lbl'>Tu</span>
							</label>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: model.days.Wednesday'/>
								<span className='lbl'>We</span>
							</label>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: model.days.Thursday'/>
								<span className='lbl'>Th</span>
							</label>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: model.days.Friday'/>
								<span className='lbl'>Fr</span>
							</label>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: model.days.Saturday'/>
								<span className='lbl'>Sa</span>
							</label>
						</div>
						<div className='timer-row'>
							<div>Start Time (EST):</div>
							<div style={{width: '120px'}} className='input-group bootstrap-timepicker'>
								<input type='text' className='form-control'
								       data-bind='timepicker: model.time, timepickerOptions: {minuteStep: 15, showMeridian: true}'/>
								<span className='input-group-addon'>
                    <i className='fa fa-clock-o bigger-110'/>
                </span>
							</div>
						</div>
					</ko>

					<div className='destination-box'>
						<div>
							<div>Send Email</div>
							<label className='checkbox-theme'>
								<input type='checkbox' className='ace'
								       data-bind='checked: templateModel.obs.destinations.email.enable'/>
								<span className='lbl'/>
							</label>
							<i title="This will send you an email that will alert you that the export has been run. You' ll need to log in to your account to view the export."
							   className='fa fa-question-circle tooltip-with-message' style={{
								marginLeft: '5px',
								fontSize: '17px',
							}}/>
						</div>
						<span className='input-icon input-icon-right field'
						      data-bind="fadeVisible: templateModel.obs.destinations.email.enable, css: {'validation-fail':!validations.destinationEmails.validationStatus()}">
            <input type='text' placeholder='Email'
                   data-bind='textInput: templateModel.obs.destinations.email.emails'/>
            <i className='ace-icon fa fa-times' id='email-validation-icon'
               data-bind='visible: !validations.destinationEmails.validationStatus()'/>
        </span>

					</div>

					<div className='destination-box'>
						<div>
							<div>Send Report to SFTP</div>
							<label className='checkbox-theme'>
								<input type='checkbox' className='ace'
								       data-bind='checked: templateModel.obs.destinations.sftp.enable'/>
								<span className='lbl'/>
							</label>
						</div>
						<div data-bind='fadeVisible: templateModel.obs.destinations.sftp.enable'>
            <span style={{width: '100%'}} className='input-icon input-icon-right field'
                  data-bind="css: {'validation-fail':!validations.destinationSftp.validationStatus()}">
                <input type='text' placeholder='SFTP Address'
                       data-bind='textInput: templateModel.obs.destinations.sftp.address'/>
                <i className='ace-icon fa fa-times' id='sftp-validation-icon'
                   data-bind='visible: !validations.destinationSftp.validationStatus()'/>
            </span>
							<input type='text' placeholder='SFTP Login' className='field'
							       data-bind='textInput:templateModel.obs.destinations.sftp.login'/>
							<input type='password' placeholder='SFTP Password' className='field'
							       data-bind='textInput:templateModel.obs.destinations.sftp.password'/>
						</div>

					</div>

				</div>
			</div>


			<div className='tests-column'>
				<div className='available-tests'>
					<div style={{fontWeight: 'bold'}}>Available Tests:</div>
					<div>
        <span className='input-icon input-icon-right'>
            <input type='text' placeholder='Content Area, Test Name, Subject, Creator...' className='form-control'
                   data-bind='textInput: tests.searchBox'/>
            <i className='ace-icon fa fa-search' aria-hidden='true'
               data-bind='visible: tests.searchBox().length==0'/>
            <i className='ace-icon fa fa-times' style={{cursor: 'pointer'}} aria-hidden='true'
               data-bind='visible: tests.searchBox().length!=0,click: view.clearSearchBox'/>
        </span>

					</div>
				</div>
				<div className='tests-container'>
					<table className='table table-striped table-hover table-bordered'>
						<thead>
						<tr>
							<th>Content Area</th>
							<th>Display Subject</th>
							<th>Test Name</th>
							<th># of Questions</th>
							<th>Test Creator</th>
							<th>Creation Date</th>
							<th style={{textAlign: 'center'}}>
								<label>
									<input type='checkbox' className='ace'
									       data-bind='checked: tests.allVisible,click: view.allTestsClicked'/>
									<span className='lbl'/>
								</label>
							</th>
						</tr>
						</thead>
						<tbody data-bind='foreach: tests.visible'>
						<tr data-bind='click:$parent.view.visibleTestClicked'>
							<td data-bind='text:contentArea'/>
							<td data-bind='text:subjectName'/>
							<td data-bind='text:name'/>
							<td data-bind='text:questionsCount'/>
							<td data-bind='text:creatorName'/>
							<td data-bind="text:$parent.helpers.formatDate(createDate,'MM/DD/YY')"/>
							<td style={{textAlign: 'center'}}>
								<label>
									<input type='checkbox' className='ace'data-bind='checked: selected, click: function() { }, clickBubble: false'/>
									<span className='lbl'/>
								</label>
							</td>
						</tr>
						</tbody>
					</table>
					<ko data-bind='if: tests.visible().length == 0'>
						<span>Tests Not Found :(</span>
					</ko>
				</div>
				<div className='selected-tests'>
					<div style={{fontWeight: 'bold'}} id='selected-tests'>
						Selected Tests:
						<sup>
							<i className='fa fa-asterisk' aria-hidden='true'/>
						</sup>
					</div>
				</div>
				<div className='tests-container'>
					<table className='table table-striped table-hover table-bordered'>
						<thead>
						<tr>
							<th>Content Area</th>
							<th>Display Subject</th>
							<th>Test Name</th>
							<th># of Questions</th>
							<th>Test Creator</th>
							<th>Creation Date</th>
							<th style={{textAlign: 'center'}}>
								<label>
									<input type='checkbox' className='ace'
									       data-bind='checked: tests.allSelected'/>
									<span className='lbl'/>
								</label>
							</th>
						</tr>
						</thead>
						<tbody data-bind='foreach: tests.selected'>
						<tr data-bind='click:$parent.view.visibleTestClicked'>
							<td data-bind='text:contentArea'/>
							<td data-bind='text:subjectName'/>
							<td data-bind='text:name'/>
							<td data-bind='text:questionsCount'/>
							<td data-bind='text:creatorName'/>
							<td data-bind="text:$parent.helpers.formatDate(createDate,'MM/DD/YY')"/>
							<td style={{textAlign: 'center'}}>
								<label>
									<input type='checkbox' className='ace' data-bind='checked: selected'/>
									<span className='lbl'/>
								</label>
							</td>
						</tr>
						</tbody>
					</table>
					<ko data-bind='if: tests.selected().length == 0'>
        <span>
            <b>Please select tests</b>
        </span>
					</ko>
				</div>

			</div>
		</div>

	</div>

}

class ValidateField extends ValidationPromise {
	constructor() {
		super({
			defaultFailMessage: '',
			validateCallback: () => resolvedPromise(),
		});
		this.validationStatus(true);
	}

	init(element: JQuery, validation: () => JQuery.Deferred<any>) {
		this.options.validateCallback = () => validation().promise();
		this.options.defaultFailMessage = 'Required';
		this.events.onValidateSuccess(() => {
			element.qtip('destroy', true);
		});
		this.events.onValidateFail((e, message) => {
			element.qtip({
				content: {
					text: message,
				},
				show: {
					ready: true,
				},
				hide: {
					fixed: false,
					delay: 300,
				},
				position: {
					at: 'top center',
					my: 'bottom center',
					container: element.closest('#modal-form'),
				},
				style: {
					classes: 'qtip-red',
				},
			});
		});
	}
}

