import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Root = styled(GridBox, {
	width: '100%',
	gridTemplateRows: 'auto 1fr',
	background: '$background',
	boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',
	overflow: 'hidden',
});
