import {SubjectName} from '@esgi/main/kits/common';
import {SubjectLevel} from '@esgi/main/libs/store';
import {Subject} from '../../../types';

type Props = {
	subjects: Subject[];
};

export function SubjectsList({subjects}: Props) {
	return (
		<>
			{subjects.map(({id, name}) => (
				// TODO: from the backend should returned subjects level instead of sudject type
				<SubjectName subjectLevel={SubjectLevel.Teacher} size='small' bold color='neutral40' key={id}>
					{name}
				</SubjectName>
			))}
		</>
	);
}
