export function chunk<T>(source: T[], maxColumnsCount: number, minItemsCount: number) {
	if (source?.length > 0) {
		const rowsPerColumn = source.length > minItemsCount * maxColumnsCount
			? Math.ceil(source.length / 4)
			: minItemsCount;

		let result = [];

		for(let i = 0; i < maxColumnsCount; i++) {
			result[i] = source.slice(i * rowsPerColumn, rowsPerColumn * (i + 1));
		}

		return result;
	} else {
		return [];
	}
}
