import {Button} from '@esgi/ui';
import {ResultBox, SessionDetailsBox} from '../../index.styled';
import {NoTestedCheap} from '../no-tested-cheap';
import {StudentCell} from '../student-cell';
import {EmptyBarBox} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	firstName: string;
	lastName: string;
	withEmptyBar?: boolean;
	onAddSessionClick: VoidFunction;
	onTestClick: VoidFunction;
	onOpenStudentTestDashboard: VoidFunction;
	index: number;
};

export function StudentNotTestedRow({
	firstName,
	lastName,
	onAddSessionClick,
	onTestClick,
	withEmptyBar = true,
	onOpenStudentTestDashboard,
	index,
}: Props) {
	return (
		<>
			<StudentCell
				firstName={firstName}
				lastName={lastName}
				withExpandedButton={false}
				onOpenStudentTestDashboard={onOpenStudentTestDashboard}
				index={index}
			/>
			<NoTestedCheap outerDataCy={`session-date-${index}`} innerDataCy={'date'}/>
			<ResultBox dataCy={`session-result-${index}`}>
				<NoTestedCheap outerDataCy={`test-dashboard-answers-info`} innerDataCy={'answers-info'}/>
				{withEmptyBar && <EmptyBarBox dataCy='progress-bar'/>}
			</ResultBox>
			<SessionDetailsBox dataCy={`session-buttons-${index}`}>
				<Button dataCy='add-session-button' color='tertiary' onClick={onAddSessionClick}>
					<Text size='medium' bold>
						Add Session
					</Text>
				</Button>
				<Button dataCy='test-button' color='primary' uppercase onClick={onTestClick}>
					<Text size='large'>Test</Text>
				</Button>
			</SessionDetailsBox>
		</>
	);
}
