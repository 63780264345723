import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {DictionaryItem} from 'modules/forms/students-form/types';

export type SchoolSpecialistFormType = FormGroup<{
	specialistGroupIDs: FormControl<number[]>
},
	ControlValue<{
		specialistGroupIDs: FormControl<number[]>
	}>>

export const createSchoolSpecialistForm = ():
	SchoolSpecialistFormType => new FormGroup({
	specialistGroupIDs: new FormControl([], {validators: []}),
});
