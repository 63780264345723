import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Tooltip} from '@esgi/ui/tooltip';

export const InputContainer = styled(Box, {
	position: 'relative',

	'&[data-error=true]': {
		'& span[data-state=none-valued]:not([data-disabled=true])': {
			color: '$negative',
		},

		'& input': {
			'&:not([data-disabled=true])': {
				borderColor: '$negativeHighlight',

				'&[data-state=valued]': {
					color: '$base',
				},
			},
		},
	},
});

export const TooltipContent = styled(Tooltip.Content, {
	maxWidth: 'calc(var(--radix-tooltip-trigger-width) - 10px)',
	justifyContent: 'flex-start',
});
