import {useEffect, useRef, useState} from 'react';
import {merge} from 'rxjs';
import {useService} from '@esgi/core/service';
import {Loader} from '@esgillc/ui-kit/loader';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {join, useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {Dropdown, Input, Label, Option} from '@esgillc/ui-kit/form-control';
import {
	PreSelected,
	ProfileInitData,
	StudentProfileMode,
	StudentProfileTab,
} from 'modules/forms/students-form/types';
import {GeneralService} from 'modules/forms/students-form/components/profile-modal/components/tabs/general/service';
import {TabFooter} from 'modules/forms/students-form/components/profile-modal/components/tab-footer';
import {ErrorText} from 'modules/forms/students-form/components/error-text';
import {useTabsApi} from 'modules/forms/students-form/components/profile-modal/use-tabs-api';
import {Credentials} from './components/credentials';
import {PasswordManagerDisabler} from 'shared/password-manager-disabler';
import {SaveDuplicateConfirmModal} from 'modules/forms/students-form/components/save-duplicate-confirm-modal';
import styles from './styles.module.less';

interface Props {
	mode: StudentProfileMode;
	initData: ProfileInitData;
	studentID: number;
	preSelected: PreSelected;
	tabsController: ReturnType<typeof useTabsApi>;
	onClose: () => void;
}

export function General({
	mode,
	initData,
	studentID,
	preSelected,
	tabsController,
	onClose,
}: Props) {
	const [isInitialFocused, setInitialFocused] = useState(false);
	const service = useService(GeneralService);
	const canViewExportID = useBehaviorSubject(service.canExportID);
	const canViewLoginInfo = useBehaviorSubject(service.canLoginInfo);
	const usingIDStudent = useBehaviorSubject(service.usingIDStudent);
	const shouldSelectSchool = useBehaviorSubject(service.showSelectSchool);
	const schools = useBehaviorSubject(service.schools);
	const isLoading = useBehaviorSubject(service.serviceLoading);
	const tabs = useBehaviorSubject(service.tabsStatus);
	const duplicateError = useBehaviorSubject(service.duplicateError);
	const afterSuccessAction = useBehaviorSubject(service.afterSuccessAction);
	const isRelatedToOtherUsers = useBehaviorSubject(service.isRelatedToOtherUsers);
	const [warningTooltipMessage, setWarningTooltipMessage] = useState<string>('');
	const saveAndAdd = useRef(false);

	const {languages, gradeLevels, genders} = initData.dictionary;

	const onSave = (isSaveAndAdd?: boolean, saveAnyway?: boolean) => {
		saveAndAdd.current = isSaveAndAdd;
		if (tabs[StudentProfileTab.General]?.isTouched) {
			service.save(isSaveAndAdd, saveAnyway).subscribe(() => {
				if (!isSaveAndAdd) {
					if(afterSuccessAction){
						afterSuccessAction();
					}else {
						onClose();
					}
				}
				saveAndAdd.current = null;
			});
		} else {
			if (!isSaveAndAdd) {
				service.form.validate().subscribe((v) => {
					if(v.valid) {
						onClose();
					}
				});
			}
		}
	};

	useEffect(() => {
		service.init({mode, initData, studentID, preSelected, tabsController});
	}, [initData, mode, studentID]);

	const onDuplicateConfirmModalClose = () => {
		service.duplicateError.next(null);
	};

	useStreamEffect(merge(
		service.form.controls.password.onChanged,
		service.form.controls.userName.onChanged,
	), () => {
		if (!isRelatedToOtherUsers.length) {
			setWarningTooltipMessage('');
			return;
		}

		setWarningTooltipMessage(
			`${initData.general.firstName} ${initData.general.lastName} belongs to ${isRelatedToOtherUsers.join(', ')} class/group. Please reach out to let them know of these modifications.`,
		);
	});

	return <div data-cy='general-form' className={styles.content}>
		<Loader show={!!isLoading} fullscreen/>
		<Form noValidate className={styles.form} controller={service.form}>
			<Label className={styles.sectionTitle}>Student Details</Label>
			<div className={styles.row}>
				<FormElement disableValidateOnBlur disableValidateOnChange ref={(ref) => {
					if (ref && mode === StudentProfileMode.add && !isInitialFocused) {
						setInitialFocused(true);
						setTimeout(() => {
							ref.state?.fieldRef?.focus?.();
						}, 300);
					}
				}} className={styles.inputWithErrorText} control={service.form.controls.firstName}>
					<Label className={join(styles.topLabel, styles.requiredLabel)}>First Nаme</Label>
					<div data-cy='first-name'>
						<PasswordManagerDisabler name='frstnm'/>
						<Input id={null} placeholder='Entеr First Nаme'/>
					</div>
					<ErrorText showOnError='required'>
						Please enter student's first name.
					</ErrorText>
				</FormElement>

				<FormElement disableValidateOnBlur disableValidateOnChange className={styles.inputWithErrorText}
							 control={service.form.controls.lastName}>
					<Label className={join(styles.topLabel, styles.requiredLabel)}>Lаst Nаme</Label>
					<div data-cy='last-name'>
						<PasswordManagerDisabler name='lstnm'/>
						<Input id={null} placeholder='Entеr Lаst Nаme'/>
					</div>
					<ErrorText showOnError='required'>
						Please enter student's last name.
					</ErrorText>
				</FormElement>
			</div>

			<div className={join(styles.row, styles.infoRow)}>
				<div data-cy='grade'>
					<FormElement disableValidateOnBlur disableValidateOnChange className={styles.inputWithErrorText}
								 control={service.form.controls.gradeLevel}>
						<Label className={join(styles.topLabel, styles.requiredLabel)}>Grade</Label>
						<Dropdown optionName='name' placeholder='Select Grade'>
							{gradeLevels.map((v, i) => <Option key={i} value={v}>{v.name}</Option>)}
						</Dropdown>
						<ErrorText showOnError='required'>
							Please select a grade.
						</ErrorText>
					</FormElement>
				</div>

				<div data-cy='language'>
					<FormElement disableValidateOnBlur disableValidateOnChange className={styles.inputWithErrorText}
								 control={service.form.controls.language}>
						<Label className={join(styles.topLabel, styles.requiredLabel)}>Language</Label>
						<Dropdown optionName='name' placeholder='Select Language'>
							{languages.map((v, i) => <Option key={i} value={v}>{v.name}</Option>)}
						</Dropdown>
						<ErrorText showOnError='required'>
							Please select a language.
						</ErrorText>
					</FormElement>
				</div>

				<div data-cy='gender'>
					<FormElement disableValidateOnBlur disableValidateOnChange className={styles.inputWithErrorText}
								 control={service.form.controls.gender}>
						<Label className={join(styles.topLabel, styles.requiredLabel)}>Gender</Label>
						<Dropdown optionName='name' placeholder='Select Gender'>
							{genders.map((v, i) => <Option key={i} value={v}>{v.name}</Option>)}
						</Dropdown>
						<ErrorText showOnError='required'>
							Please select a gender.
						</ErrorText>
					</FormElement>
				</div>
			</div>

			<div className={join(styles.row, styles.idsSection)}>
				<div data-cy='student-idn'>
					<FormElement disableValidateOnBlur disableValidateOnChange className={styles.inputWithErrorText}
								 control={service.form.controls.studentIDN}>
						<Label className={styles.topLabel}>Student ID</Label>
						<PasswordManagerDisabler name='stdid'/>
						<Input id={null}/>
						<ErrorText showOnError='id-in-use'>Student ID is already being used
							by {usingIDStudent?.studentName}</ErrorText>
					</FormElement>
				</div>
				{canViewExportID &&
					<div data-cy='export-id'>
						<FormElement disableValidateOnBlur disableValidateOnChange className={styles.inputWithErrorText}
									 control={service.form.controls.exportIDN}>
							<div className={styles.labelContainer}>
								<Label className={styles.topLabel}>Export ID</Label>
							</div>
							<PasswordManagerDisabler name='expid'/>
							<Input id={null} maxLength={4} type='text'/>
						</FormElement></div>}
			</div>
			{shouldSelectSchool && <div data-cy='school' className={styles.row}>
				<FormElement disableValidateOnBlur disableValidateOnChange className={styles.inputWithErrorText}
							 control={service.form.controls.school}>
					<Label className={join(styles.topLabel, styles.requiredLabel)}>School</Label>
					<div data-cy='select-school'>
						<Dropdown optionName='name' tabIndex={3} placeholder='Select School'>
							{schools.map(v => <Option key={v.schoolID} value={v}>{v.name}</Option>)}
						</Dropdown>
					</div>
					<ErrorText showOnError='required'>
						Please select a school.
					</ErrorText>
				</FormElement>
			</div>}

			{canViewLoginInfo && <Credentials
				warningTooltipMessage={warningTooltipMessage}
				passwordControl={service.form.controls.password}
				userNameControl={service.form.controls.userName}
				studentFullName={`${service.form.controls.firstName.value} ${service.form.controls.lastName.value}`}
			/>}

		</Form>
		{duplicateError &&
			<SaveDuplicateConfirmModal name={`${service.form.value.firstName} ${service.form.value.lastName}`}
									   onClose={onDuplicateConfirmModalClose}
									   onSave={() => onSave(saveAndAdd.current, true)}
									   onCancel={onDuplicateConfirmModalClose}/>}
		<TabFooter
			canSaveAndAdd={initData.isOpenedWithAdd}
			tabsApi={tabs} currentTab={StudentProfileTab.General}
			mode={mode}
			onSaveAndAdd={() => onSave(true, false)}
			onSave={() => onSave()}
		/>
	</div>;
}
