import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

type Props = {
	onAlertClose: VoidFunction;
	validationData: string;
};

export function UnsupportedFieldName({onAlertClose, validationData}: Props) {
	const verb = validationData.includes(',') ? 'have' : 'has';

	return (
		<>
			<Alert.Header>
				<Text size='large'>Unsupported Field Name</Text>
			</Alert.Header>
			<Alert.Body>
				{validationData && (
					<Text size='medium'>
						Errors: {validationData} {verb} an unsupported field name. Supported field names include Student First and
						Last Name, ID, Grade, Language, and Gender. Please fix the file and try again.
					</Text>
				)}
			</Alert.Body>
			<Alert.Footer>
				<Button color='secondary' onClick={onAlertClose}>
					<Text size='medium' bold>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</>
	);
}
