import {BaseService} from '@esgi/core/service';
import {LoginController} from '@esgi/contracts/esgi';

export class LogoutService extends BaseService {
	private controller = new LoginController();

	public logout() {
		return this.controller.logout();
	}

	public override dispose() {
		this.controller.dispose();
	}
}
