import {styled} from '@stitches/react';
import {Box} from '@esgi/ui/layout';

export const ReportFilterRow = styled(Box, {
	display: 'flex',
	flexDirection: 'row',
	borderBottom: '2px solid #FFFFFF',
	padding: '0 25px',
  height: '55px',

	'&:last-child': {
		borderRight: 'none',
	},
});