import {useMemo} from 'react';
import {Select, OptionItem} from '@esgi/main/features/teacher/home';
import {AllOptionItem} from '../types';
import {useValue} from '../../../../../hooks';
import {Class} from '@esgi/main/libs/store';

type OptionType = Class['id'] | AllOptionItem.Value;
interface Props {
	classes: Class[];
	onChange: (data: OptionType) => void;
}

export function ClassSelect({
	classes,
	onChange,
}: Props) {
	const [classId, setClassId] = useValue<OptionType>(AllOptionItem.Value, onChange);

	const items = useMemo<OptionItem[]>(
		() => [
			{
				value: AllOptionItem.Value,
				label: AllOptionItem.Label,
			},
			...classes.map<OptionItem>(({id, name}) => ({value: String(id), label: name})),
		],
		[classes],
	);

	return (
		<Select
			placeholder='Class'
			items={items}
			selectedValue={String(classId)}
			onValueChange={(value) => setClassId(value === AllOptionItem.Value ? value : Number(value))}
		/>
	);
}
