import {join} from '@esgillc/ui-kit/utils';
import React, {ReactNode} from 'react';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ColorPicker, State as ColorPickerState} from '@esgi/deprecated/elements';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {contentAreaDefaultColors} from '../content-area-to-colors';
import {Item} from '../models';
import styles from '../styles.module.less';

interface Props {
	readonly: boolean;
	contentAreaControl: FormControl<number[]>;
	stateStandard: FormControl<Item>;
	colorControl: FormControl<string>;
	contentAreas: Item[];
	onStandardAffectingChange: () => void;
}

class State {
	colorPickerState = new ColorPickerState();

	constructor(color?: string) {
		this.colorPickerState = new ColorPickerState(color);
	}
}

export class ContentAreaField extends React.PureComponent<Props, State> {
	public state = new State(this.props.colorControl.value);
	private readonly onDestroy$: Subject<void> = new Subject();

	public componentDidMount() {
		this.props.contentAreaControl
			.onChanged
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(change => {
				const color = contentAreaDefaultColors[change.currState.value[0]];
				this.setState({
					colorPickerState: new ColorPickerState(color?.color),
				}, () => this.props.colorControl.value = color?.color);
			});
	}

	public render() {
		return <div className={styles.field}>
			<div className={join(styles.fieldTitle, styles.hideTablet)}>
				Content Area:
			</div>
			<div className={join(styles.fieldTitle, styles.dTablet)}>
				Content:
			</div>
			<div className={styles.fieldContent} onFocus={this.props.onStandardAffectingChange}>
				<FormElement control={this.props.contentAreaControl}
				             className={styles.contentAreaElement}>
					{this.props.readonly ? this.renderText() : this.renderDropdown()}
				</FormElement>
			</div>
		</div>;
	}

	private renderText(): ReactNode {
		const contentAreaID = this.props.contentAreaControl.value[0];
		const contentArea = this.props.contentAreas.find(c => c.id === contentAreaID);
		return <span>{contentArea.name}</span>;
	}

	private renderDropdown(): ReactNode {
		return <>
			<Dropdown className={styles.contentAreaDropdown} displayLabel={(v: number[]) => {
				return this.props.contentAreas.find(c => v.indexOf(c.id) >= 0)?.name || '';
			}}>
				{this.props.contentAreas.map(s => <Option key={s.id} value={s.id}>{s.name}</Option>)}
			</Dropdown>
			<ColorPicker hideTitle={true}
			             className={styles.colorPicker}
			             state={this.state.colorPickerState}
			             onChange={(ch, cb) => this.setState({colorPickerState: ch}, () => {
				             this.props.colorControl.value = ch.selectedColor;
				             cb && cb();
			             })}/>
		</>;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
