import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {
	ToggleGroupTitle,
	ToggleGroupRoot,
	ToggleGroupContent,
	ToggleGroupItem,
} from './index.styled';
import {LanguageAndTemplate} from '../../types';

interface Props {
	selectedItem: number,
	onChange: (id: number) => void
	items: LanguageAndTemplate[]
}

export function SubSidebarMenu({selectedItem, onChange, items}: Props) {
	return <GridBox>
		<ToggleGroupRoot orientation='vertical' value={selectedItem.toString()}>
			<ToggleGroupTitle>
				<Text size='medium' color='lowContrast'>My Language List</Text>
			</ToggleGroupTitle>

			<ToggleGroupContent>
				{items.map(t => (
					<ToggleGroupItem value={t.id.toString()} key={t.id} onClick={() => onChange(t.id)}>
						<Text size='medium' color='neutral40' bold>{t.name}</Text>
					</ToggleGroupItem>))}
			</ToggleGroupContent>
		</ToggleGroupRoot>
	</GridBox>;
}
