import React from 'react';
import styles from './with-one-column.module.less';
import {TemplateProps} from '../template';
import BaseOption from '../../option/option';
import {getSmallestTextSize, isMultiple} from '../../utils';
import {Question} from '@esgi/selfassess';
import {isIpad, join} from '@esgillc/ui-kit/utils';


class WithOneColumn extends React.PureComponent<TemplateProps> {
	private selected(id: number): boolean {
		return this.props.selectedOptionID.includes(id);
	}

	private getOptionClassName(question: Question): string {
		switch (question.options.length) {
			case 4:
				return styles.fourOptions;
			case 3:
				return styles.threeOptions;
		}
	}

	private containerRef = React.createRef<HTMLDivElement>();

	render() {
		let fontSize, lineHeight;
		if (isIpad()) {
			const textStyles = getSmallestTextSize(this.props.question.options);
			fontSize = textStyles.fontSize;
			lineHeight = textStyles.lineHeight;
		}
		const question = this.props.question;
		const optionClassName = this.getOptionClassName(question);
		return <div className={styles.template}>
			<div className={styles.optionsContainer}>
				{question.options.map(item =>
					<BaseOption
						fontSize={fontSize}
						lineHeight={lineHeight}
						bucket={this.props.awsBucket}
						type={'Horizontal'}
						id={item.id}
						disabled={this.props.maxOptionsSelected && !this.props.selectedOptionID.includes(item.optionID)}
						className={join(styles.optionBox, optionClassName)}
						multiselect={isMultiple(question)}
						key={item.optionID}
						text={item.text}
						picUrl={item.picUrl}
						clicked={() => this.props.onSelectOption(item.optionID)}
						selected={this.selected(item.optionID)}
						audioPath={item.audioUrl}
						disableAudio={this.props.audioPlaying}
					/>)}
			</div>
		</div>;
	}
}

export default WithOneColumn;
