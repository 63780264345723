import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';
import {StudentRace, StudentSpecialProgram, StudentLunchStatus} from '../types';

export type InformationFormType = FormGroup<{
	race: FormControl<StudentRace[]>,
	lunchStatus: FormControl<StudentLunchStatus[]>,
	studentSpecialPrograms: FormControl<StudentSpecialProgram[]>,
	comments: FormControl<string>,
},
	ControlValue<{
		race: FormControl<StudentRace[]>,
		lunchStatus: FormControl<StudentLunchStatus[]>,
		studentSpecialPrograms: FormControl<StudentSpecialProgram[]>,
		comments: FormControl<string>,
	}>>

export const createInformationForm = ():
	InformationFormType => new FormGroup({
	race: new FormControl([]),
	lunchStatus: new FormControl([]),
	studentSpecialPrograms: new FormControl([]),
	comments: new FormControl(''),
});
