import {GridBox, SelectableList} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const expandableListsGap = 6;

export const SelectableListRoot = styled(SelectableList, {
	height: 'max-content',

	variants: {
		isListOpen: {
			true: {
				'&:not(:last-child)': {
					marginBottom: 18,
				},
			},

			false: {
				'&:not(:last-child)': {
					marginBottom: expandableListsGap,
				},
			},
		},
	},
});

export const SelectableListTrigger = styled(SelectableList.Trigger, {
	variants: {
		defaultCursor: {
			true: {
				cursor: 'default',
			},
		},
	},
});

export const SelectableListTriggerContent = styled(GridBox, {
	color: 'inherit',
	alignItems: 'baseline',
	gridTemplateColumns: 'auto 1fr',
});

export const SelectableListTriggerLabel = styled(Text, {
	color: 'inherit',
	marginRight: 6,
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

export const SelectableLisItemContent = styled(GridBox, {
	paddingTop: 2,
	paddingBottom: 2,
});

export const ExpandableRearrangeableItem = styled(SelectableList.ItemDraggable, {
	minHeight: 38,
	padding: 6,
	borderRadius: 6,
	color: '$neutral40',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',

	'& > svg': {
		marginTop: 6,
	},

	'&:not(last-child)': {
		marginBottom: expandableListsGap,
	},

	variants: {
		selected: {
			true: {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
		},
	},
});
