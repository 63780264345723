import {Checkbox} from '@esgillc/ui-kit/control';
import styles from '../../styles.module.less';

interface Props {
	value: boolean;
	onChange: (value: boolean) => void;
}

export const Baseline = (props: Props): JSX.Element => (
	<div className={styles.field}>
		<label className={styles.title}>Results:</label>
		<Checkbox
			checked={props.value}
			onChange={({target}) => props.onChange(target.checked)}
		>
			Show Baseline
		</Checkbox>
	</div>
);
