import {ExpandablePanel, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ExpandablePanelStyled = styled(ExpandablePanel, {
	'&[data-expandable-panel-open=true]': {
		backgroundColor: '$neutral94',
		backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',
	},

	variants: {
		collapsed: {
			true: {
				cursor: 'pointer',
			},
		},
		isTablet: {
			true: {
				'& > div': {
					padding: 12,
				},
			},
		},
	},
});

export const SectionBox = styled(FlexBox, {
	height: '100%',
});

export const ExpandablePanelContent = styled(ExpandablePanel.Content, {
	width: 232,
	height: 'calc(100% - 40px)',
});
