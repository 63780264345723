import {styled} from '@esgi/ui/theme';
import {Alert} from '@esgi/ui/alert';

export const AlertHeader = styled(Alert.Header, {
	'& > div > div': {
		display: 'flex',
		alignItems: 'center',
		color: '$neutral56',

		'& > svg': {
			width: '24px',
			height: '24px',
			marginRight: '4px',

			'& > path': {
				fill: '$neutral56',
			},
		},
	},
});

export const Embed = styled('embed', {
	height: 'calc(100vh - 200px)',
	width: '100%',
	minWidth: 520,
});

