import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, of} from 'rxjs';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {tap, map, takeUntil, filter, concatMap} from 'rxjs/operators';
import {SubjectType} from '@esgi/core/enums';
import {
	IEPGoalModel,
	IEPStatusModel,
	FiltersDataModel,
	StudentModel,
	SubjectModel,
	SubjectTestModel,
	TestModel,
	IEPDatesModel,
} from './models';
import {IEPGoalCreated, IEPGoalChanged} from './events';
import {dispatchAppEvent} from '@esgillc/events';
import {HierarchySnapshot} from 'modules/hierarchy/core/models/snapshot/hierarchy-snapshot';
import {ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {IEPGoalForm} from './types';
import {getSelectedStatus, getSelectedStudent, getSelectedSubject, getSelectedTest} from './utils';
import {DateTools} from 'global/utils/date-utils';
import {userStorage} from '@esgi/core/authentication';
import {NotificationOptions} from '@esgillc/ui-kit/snackbar';

const defaultFormValue = {
	status: new FormControl<IEPStatusModel[]>([]),
	student: new FormControl<StudentModel[]>([]),
	subject: new FormControl<SubjectModel[]>([]),
	test: new FormControl<TestModel[]>([]),
	goal: new FormControl<string>(''),
	benchmark: new FormControl<string>(''),
	notes: new FormControl<string>(''),
	recommendation: new FormControl<string>(''),
	isCompleted: new FormControl<boolean>(false),
};

export class IEPGoalFormService extends BaseService {
	public readonly iepGoal$: BehaviorSubject<IEPGoalModel> = new BehaviorSubject<IEPGoalModel>(null);
	public readonly iepStatuses$: BehaviorSubject<IEPStatusModel[]> = new BehaviorSubject<IEPStatusModel[]>([]);
	public readonly students$: BehaviorSubject<StudentModel[]> = new BehaviorSubject<StudentModel[]>([]);
	public readonly subjects$: BehaviorSubject<SubjectModel[]> = new BehaviorSubject<SubjectModel[]>([]);
	public readonly subjectTests$: BehaviorSubject<SubjectTestModel[]> = new BehaviorSubject<SubjectTestModel[]>([]);
	public readonly tests$: BehaviorSubject<TestModel[]> = new BehaviorSubject<TestModel[]>([]);
	public readonly iepDates$: BehaviorSubject<IEPDatesModel> = new BehaviorSubject<IEPDatesModel>(null);
	public readonly previousGoalId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	public form: IEPGoalForm = new FormGroup(defaultFormValue);
	public statusForm = new FormGroup({
		statusName: new FormControl<string>(''),
	});
	public isDirty = new BehaviorSubject<boolean>(false);
	public iepStatusesSnapshot: IEPStatusModel[];
	private initialFormValue = {
		status: [],
		student: [],
		subject: [],
		test: [],
		goal: '',
		benchmark: '',
		notes: '',
		recommendation: '',
		isCompleted: false,
	};
	private readonly controllerName = 'iep-goals';
	private readonly iepDatesControllerName = 'iep-dates';
	private readonly iepStatusesControllerName = 'iep-statuses';
	private districtID = 0;
	private readonly currentUser = userStorage.get();

	public initStatusFormValidators(statuses: IEPStatusModel[]): void {
		this.statusForm.controls.statusName.validators.push(
			Validators.required(),
			Validators.isDublicateValue(
				statuses.filter(({isDeleted}) => !isDeleted).map(({name}) => name),
			),
		);
	}

	public init(
		districtID: number,
		studentID: number,
		testID: number,
		subjectID: number,
	) {
		return this.httpClient.ESGIApi.get<{ iepGoal: IEPGoalModel, statuses: IEPStatusModel[] }>(
			this.controllerName,
			'init',
			{districtID, studentID, testID},
		).pipe(
			tap((r) => {
				this.districtID = districtID;
				const goal = this.IEPGoalModelMapper(r.iepGoal, studentID, testID);
				this.iepGoal$.next(goal);
				this.iepStatusesSnapshot = r.statuses.slice();
				this.iepStatuses$.next(r.statuses);
				this.initFormValues(goal, subjectID);
				this.initFormSubscriptions();
				this.loadIEPDates(studentID);
			}),
		);
	}

	public initFilters(
		hierarchy: HierarchySnapshot,
		globalSchoolYearID: number,
		subjectID: number,
		subjectType: SubjectType,
	) {
		return this.httpClient.ESGIApi.post<FiltersDataModel>(
			this.controllerName,
			'init/filters',
			{hierarchy, globalSchoolYearID, subjectID, subjectType},
		).pipe(
			tap((s) => {
				this.students$.next(this.studentsMapper(s.students));
				this.subjects$.next(s.subjects);
				this.subjectTests$.next(s.subjectTests);
				this.updateSubjectTests(s.subjectTests, subjectID);
			}),
		);
	}

	public loadIEPGoal(
		districtID: number,
		studentID: number,
		testID: number,
		subjectID: number,
	) {
		this.httpClient.ESGIApi.get<{ iepGoal: IEPGoalModel, statuses: IEPStatusModel[] }>(
			this.controllerName,
			'init',
			{districtID: districtID || this.districtID, studentID, testID},
		).pipe(
			tap((r) => {
				const goal = this.IEPGoalModelMapper(r.iepGoal, studentID, testID);
				this.iepGoal$.next(goal);
				this.iepStatusesSnapshot = r.statuses.slice();
				this.iepStatuses$.next(r.statuses);
				this.initFormValues(goal, subjectID);
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public create(isDraft?: boolean, callback?: () => void, opts?: NotificationOptions) {
		this.initFormValidators();
		this.form.validate().pipe(
			map(res => res.map(r => !r.length)),
			filter(res => res.every(Boolean)),
			concatMap(() => {
				this.httpClient.ESGIApi.post<IEPGoalModel>(
					this.controllerName,
					'create',
					this.buildIEPGoal(isDraft),
				).subscribe((r) => {
					this.iepGoal$.next(r);
					this.resetFormControlStatuses();
					showSnackbarNotification(`IEP Goal created`, {...opts});
					const status = this.iepStatuses$.value.find(
						({id}) => id === r.statusID,
					)?.name;
					dispatchAppEvent(
						IEPGoalCreated,
						new IEPGoalCreated({...r, status}),
					);
					if (callback) {
						callback();
					}
				});
				return of();
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public update(callback?: () => void) {
		this.initFormValidators();
		this.form.validate().pipe(
			map(res => res.map(r => !r.length)),
			filter(res => res.every(Boolean)),
			concatMap(() => {
				this.httpClient.ESGIApi.post<IEPGoalModel>(
					this.controllerName,
					'update',
					this.buildIEPGoal(),
				).subscribe((r) => {
					this.iepGoal$.next(r);
					this.resetFormControlStatuses();
					this.toggleFields();
					this.updateInitialFormValue();
					showSnackbarNotification(`IEP Goal updated`);
					const status = this.iepStatuses$.value.find(
						({id}) => id === r.statusID,
					)?.name;
					dispatchAppEvent(
						IEPGoalChanged,
						new IEPGoalChanged({...r, status}),
					);
					if (callback) {
						callback();
					}
				});
				return of();
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public markGoalAsArchived(goalId?: number, callback?: () => void) {
		this.httpClient.ESGIApi.post<void>(
			this.controllerName,
			'mark-as-archived',
			{goalId: goalId || this.previousGoalId$.value},
		).pipe(
			tap(() => {
				if (callback) {
					callback();
				}
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public loadIEPDates(studentID: number) {
		this.httpClient.ESGIApi.get<IEPDatesModel>(
			this.iepDatesControllerName,
			'init',
			{studentID},
		).pipe(
			tap((r) => this.iepDates$.next(r)),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public createIEPDates(start: Date, end: Date, callback?: () => void) {
		const studentID = this.form.controls.student.value.at(0)?.studentID;
		const startDate = DateTools.toServerString(start);
		const endDate = DateTools.toServerString(end);

		return this.httpClient.ESGIApi.post<void>(
			this.iepDatesControllerName,
			'create',
			{studentID, startDate, endDate},
		).pipe(
			tap(() => {
				this.iepDates$.next({startDate: start, endDate: end} as IEPDatesModel);
				showSnackbarNotification(`IEP Dates saved`);
				if (callback) {
					callback();
				}
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public updateIEPDates(start: Date, end: Date, callback?: () => void) {
		const studentID = this.form.controls.student.value.at(0)?.studentID;
		const startDate = DateTools.toServerString(start);
		const endDate = DateTools.toServerString(end);

		return this.httpClient.ESGIApi.post<void>(
			this.iepDatesControllerName,
			'update',
			{studentID, startDate, endDate},
		).pipe(
			tap(() => {
				this.iepDates$.next({startDate: start, endDate: end} as IEPDatesModel);
				showSnackbarNotification(`IEP Dates saved`);
				if (callback) {
					callback();
				}
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public onAddIEPStatus(
		statuses: IEPStatusModel[],
		setStatuses: React.Dispatch<React.SetStateAction<IEPStatusModel[]>>,
	) {
		this.statusForm.validate().pipe(
			map(res => res.map(r => !r.length)),
			filter(res => res.every(Boolean)),
			map(() => {
				const activeStatuses = statuses.filter(s => !s.isDeleted);
				const newStatus = {
					id: 0,
					name: this.statusForm.controls.statusName.value,
					order: activeStatuses.length
						? activeStatuses[activeStatuses.length - 1].order + 1
						: 1,
					isDeleted: false,
				} as IEPStatusModel;

				this.statusForm.controls.statusName.value = '';
				const newStatuses = [...statuses, newStatus];
				setStatuses(newStatuses);
				this.reinitializeStatusFormValidators(newStatuses);
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	public reinitializeStatusFormValidators(statuses: IEPStatusModel[]) {
		this.statusForm.controls.statusName.clearValidators();
		this.initStatusFormValidators(statuses);
	}

	public saveIEPStatuses(
		statuses: IEPStatusModel[],
		setStatuses: React.Dispatch<React.SetStateAction<IEPStatusModel[]>>,
	) {
		return this.httpClient.ESGIApi.post<{ statuses: IEPStatusModel[] }>(
			this.iepStatusesControllerName,
			'save',
			{districtID: this.districtID, statuses},
		).pipe(
			tap((r) => {
				this.iepStatusesSnapshot = r.statuses.slice();
				this.iepStatuses$.next(r.statuses);
				setStatuses(r.statuses);
				showSnackbarNotification(`IEP Statuses saved`);
				const activeStatusesIDs = r.statuses.filter(s => !s.isDeleted).map(s => s.id);
				if (!activeStatusesIDs.includes(this.form.controls.status.value[0].id)) {
					this.form.controls.status.value = [];
				}
			}),
			takeUntil(this.destroy$),
		).toPromise();
	}

	public resetStatusFormData() {
		this.statusForm.controls.statusName.value = '';

		Object.values(this.statusForm.controls).forEach(
			(control) => control.status = ElementStatus.untouched,
		);

		this.reinitializeStatusFormValidators(this.iepStatuses$.value);
	}

	public resetIEPGoalFormData(createEmptyGoal = false) {
		if (createEmptyGoal) {
			this.iepGoal$.next(this.IEPGoalModelMapper(
				null,
				this.form.controls.student.value.at(0)?.studentID,
				this.form.controls.test.value.at(0)?.testID,
			));
		}
		this.form.controls.goal.value = '';
		this.form.controls.benchmark.value = '';
		this.form.controls.notes.value = '';
		this.form.controls.recommendation.value = '';
		this.form.controls.status.value = [];
		this.form.controls.isCompleted.value = false;

		this.resetFormControlStatuses();
	}

	public setPreviousGoalId(goalId) {
		this.previousGoalId$.next(goalId);
	}

	public restoreStatuses() {
		this.iepStatuses$.next(this.iepStatusesSnapshot.slice());
	}

	public updateInitialFormValue() {
		this.initialFormValue.student = this.form.value.student;
		this.initialFormValue.subject = this.form.value.subject;
		this.initialFormValue.status = this.form.value.status;
		this.initialFormValue.test = this.form.value.test;
		this.initialFormValue.goal = this.form.value.goal;
		this.initialFormValue.benchmark = this.form.value.benchmark;
		this.initialFormValue.notes = this.form.value.notes;
		this.initialFormValue.recommendation = this.form.value.recommendation;
		this.initialFormValue.isCompleted = this.form.value.isCompleted;

		this.updateIsDirty();
	}

	public toggleFields(isCompleted?: boolean) {
		const value = isCompleted || this.form.controls.isCompleted.value;
		Object.keys(this.form.controls).forEach((key) => {
			if (['isCompleted', 'student', 'subject', 'test'].includes(key)) {
				return;
			}
			this.form.controls[key].status = value
				? ElementStatus.disabled
				: ElementStatus.untouched;
		});
	}

	private initFormValidators(): void {
		this.form.controls.goal.validators.push(Validators.required());
		this.form.controls.student.validators.push(Validators.required());
		this.form.controls.test.validators.push(Validators.required());
	}

	private initFormValues(iepGoal: IEPGoalModel, subjectID: number) {
		this.form.controls.student.value = this.initialFormValue.student = getSelectedStudent(this.students$.value, iepGoal.studentID);
		this.form.controls.subject.value = this.initialFormValue.subject = getSelectedSubject(this.subjects$.value, subjectID);
		this.form.controls.test.value = this.initialFormValue.test = getSelectedTest(this.tests$.value, iepGoal.testID);
		this.form.controls.status.value = this.initialFormValue.status = getSelectedStatus(this.iepStatuses$.value, iepGoal.statusID);
		this.form.controls.goal.value = this.initialFormValue.goal = iepGoal.goal || '';
		this.form.controls.benchmark.value = this.initialFormValue.benchmark = iepGoal.benchmark || '';
		this.form.controls.notes.value = this.initialFormValue.notes = iepGoal.notes || '';
		this.form.controls.recommendation.value = this.initialFormValue.recommendation = iepGoal.recommendation || '';
		this.form.controls.isCompleted.value = this.initialFormValue.isCompleted = iepGoal.isCompleted;

		this.resetFormControlStatuses();
	}

	private resetFormControlStatuses() {
		Object.values(this.form.controls).forEach(
			(control) => control.status = ElementStatus.untouched,
		);
	}

	private initFormSubscriptions() {
		this.form.onChanged.subscribe(() => this.updateIsDirty());
		this.form.controls.student.onChanged
			.pipe(
				concatMap(event => {
					if (!event.currState || !event.prevState) {
						return of();
					}

					const prevStudentID = event.prevState.value[0]?.studentID;
					const currStudentID = event.currState.value[0]?.studentID;

					if (prevStudentID !== currStudentID) {
						const subjectID = this.form.controls.subject.value.at(0)?.id;
						const testID = this.form.controls.test.value.at(0)?.testID;
						this.loadIEPGoal(this.districtID, currStudentID, testID, subjectID);
						this.loadIEPDates(currStudentID);
					}

					return of();
				}),
				takeUntil(this.destroy$),
			).subscribe();

		this.form.controls.test.onChanged
			.pipe(
				concatMap(event => {
					if (!event.currState || !event.prevState || !event.currState.value.length) {
						return of();
					}

					const prevTestID = event.prevState.value[0]?.testID;
					const currTestID = event.currState.value[0]?.testID;

					if (prevTestID !== currTestID) {
						const subjectID = this.form.controls.subject.value.at(0)?.id;
						const studentID = this.form.controls.student.value.at(0)?.studentID;
						this.loadIEPGoal(this.districtID, studentID, currTestID, subjectID);
					}

					return of();
				}),
				takeUntil(this.destroy$),
			).subscribe();

		this.form.controls.subject.onChanged
			.pipe(
				concatMap(event => {
					if (!event.currState || !event.prevState) {
						return of();
					}

					const prevSubjectID = event.prevState.value[0]?.id;
					const currSubjectID = event.currState.value[0]?.id;

					if (prevSubjectID !== currSubjectID) {
						this.updateSubjectTests(this.subjectTests$.value, currSubjectID);
						if (this.tests$.value.length) {
							const testID = this.tests$.value.at(0).testID;
							const studentID = this.form.controls.student.value.at(0).studentID;
							this.loadIEPGoal(this.districtID, studentID, testID, currSubjectID);
						} else {
							this.form.controls.test.value = [];
							this.resetIEPGoalFormData();
						}
					}

					return of();
				}),
				takeUntil(this.destroy$),
			).subscribe();

		this.form.onChanged.pipe(
			concatMap(event => {
				if (!event.currState || !event.prevState) {
					return of();
				}

				this.updateIsDirty();

				return of();
			}),
			takeUntil(this.destroy$),
		).subscribe();
	}

	private updateIsDirty(): void {
		this.isDirty.next(
			// The following 3 fields define the destination of the goal, not its properties.
			// this.form.value.student[0].studentID !== this.initialFormValue.student[0]?.studentID ||
			// this.form.value.subject[0].id !== this.initialFormValue.subject[0]?.id ||
			// this.form.value.test[0].testID !== this.initialFormValue.test[0]?.testID ||
			this.form.value.status[0]?.id !== this.initialFormValue.status[0]?.id ||
			this.form.value.goal !== this.initialFormValue.goal ||
			this.form.value.benchmark !== this.initialFormValue.benchmark ||
			this.form.value.notes !== this.initialFormValue.notes ||
			this.form.value.recommendation !== this.initialFormValue.recommendation ||
			this.form.value.isCompleted !== this.initialFormValue.isCompleted,
		);
	}

	private buildIEPGoal(isDraft: boolean = false): IEPGoalModel {
		return {
			id: this.iepGoal$.value.id,
			districtID: this.iepGoal$.value.districtID,
			studentID: this.form.controls.student.value.at(0).studentID,
			testID: this.form.controls.test.value.at(0).testID,
			goal: this.getStringValue(this.form.controls.goal.value),
			statusID: this.form.controls.status.value.at(0)?.id,
			benchmark: this.getStringValue(this.form.controls.benchmark.value),
			notes: this.getStringValue(this.form.controls.notes.value),
			recommendation: this.getStringValue(this.form.controls.recommendation.value),
			isCompleted: this.form.controls.isCompleted.value,
			isDraft,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
		} as IEPGoalModel;
	}

	private updateSubjectTests(subjectTests: SubjectTestModel[], subjectID: number) {
		this.tests$.next(subjectTests.find(st => st.subjectID === subjectID)?.tests);
	}

	private getStringValue(value: string) {
		return value && value.length ? value : null;
	}

	private IEPGoalModelMapper(goal: IEPGoalModel, studentID: number, testID: number): IEPGoalModel {
		if (!goal) {
			return {
				id: 0,
				districtID: this.districtID,
				studentID,
				testID,
				goal: '',
				statusID: null,
				benchmark: '',
				notes: '',
				recommendation: '',
				isCompleted: false,
				isDraft: false,
			} as IEPGoalModel;
		}

		return goal;
	}

	private studentsMapper(students: StudentModel[]) {
		return students.map((s) => {
			s.fullName = s.firstName + ' ' + s.lastName;
			return s;
		});
	}
}
