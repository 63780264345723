export interface SortModel {
  fieldName: string;
  fieldValue?: (value: any) => any;
  direction: string;
  relatedSorts?: SortModel[];
  rowIDField?: string;
}

export interface ExportRequest {
  cacheKey?: string;
  rows?: number[];
}

export const sortDirectionMap = ['None', 'Asc', 'Desc'];
