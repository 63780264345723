import {CSS} from '@stitches/react';
import {BaseComponentProps, Skeletonable, ThemeColorKeys} from '../../../types';
import {ComponentPropsWithoutRef, SVGProps, forwardRef, useCallback, useId, useMemo} from 'react';
import {SkeletonableCircle, Svg} from './index.styled';
import {getThemeColor} from '../../../utils';
import {useProgressBarContext} from '../../context';
import {useSpring, config, animated} from 'react-spring';

type RingFullFilledProps = Omit<BaseComponentProps, 'css'> &
	Skeletonable &
	ComponentPropsWithoutRef<'svg'> & {
		/** Returns a Style interface from a configuration, leveraging the given media and style map. */
		css?: Omit<CSS, 'width' | 'height'>;

		/** Set width and height for svg. */
		size?: number;

		/** Set circle fill color. */
		circleFillColor?: ThemeColorKeys;

		/** Set circle stroke color. */
		circleStrokeColor?: ThemeColorKeys;

		/** Set circle and progress path stroke width. */
		chartStrokeWidth?: number;

		/** Set progress value color. */
		progressColor?: ThemeColorKeys;
	};

export const RingFullFilled = forwardRef<SVGSVGElement, RingFullFilledProps>(
	(
		{
			dataCy = 'ui-kit-progress-bar-ring-full-filled',
			skeleton,
			css = {},
			size = 60,
			circleFillColor = 'background',
			circleStrokeColor = 'border',
			chartStrokeWidth = 1,
			progressColor = 'positiveVivid',
			...svgProps
		},
		forwardedRef,
	) => {
		const progressGradientColorId = useId();

		const {progress} = useProgressBarContext();

		const circleValues = useMemo(() => {
			const circleSize = size - 2 * chartStrokeWidth;

			const radius = circleSize / 2;
			const perimeter = 2 * Math.PI * radius;

			return {
				radius,
				perimeter,
				center: {
					x: size / 2,
					y: size / 2,
				},
			};
		}, [chartStrokeWidth, size]);

		const arcValues = useMemo(() => {
			const arcAngle = progress * 360;

			return {
				x1: circleValues.center.x,
				y1: circleValues.center.y - circleValues.radius,
				arcAngle,
			};
		}, [circleValues, progress]);

		const getArcAnimatePosition = useCallback(
			(arcAngle: number) => {
				const arcAngleRadian = ((arcAngle - 90) * Math.PI) / 180;

				return {
					x2: circleValues.center.x + circleValues.radius * Math.cos(arcAngleRadian),
					y2: circleValues.center.y + circleValues.radius * Math.sin(arcAngleRadian),
					largeArcFlag: arcAngle <= 180 ? 0 : 1,
				};
			},
			[circleValues],
		);

		const getArcAnimatedValueD = useCallback(
			(arcAngle: number) => {
				const isArcAngleMax = arcAngle === 360;
				const udaptedArcAngle = isArcAngleMax ? arcAngle - 1 : arcAngle;

				const {largeArcFlag, x2, y2} = getArcAnimatePosition(udaptedArcAngle);

				const startPosition = `M ${arcValues.x1} ${arcValues.y1}`;
				const arc = `A ${circleValues.radius} ${circleValues.radius}, 0, ${largeArcFlag}, 1, ${x2} ${y2}`;

				if (isArcAngleMax) {
					return `${startPosition} ${arc}`;
				}

				const lineToCenter = `L ${circleValues.center.x} ${circleValues.center.y}`;
				const lineToStart = ` L ${arcValues.x1} ${arcValues.y1}`;

				return `${startPosition} ${arc} ${lineToCenter} ${lineToStart} Z`;
			},
			[arcValues, circleValues, getArcAnimatePosition],
		);

		const arcPathAnimationProps = useSpring({
			from: {
				arcAngle: 0,
			},
			to: {
				arcAngle: arcValues.arcAngle,
			},
			config: {
				...config.molasses,
				clamp: true,
			},
		});

		const getSvgContent = useCallback(() => {
			const commonCircleProps: Pick<
				SVGProps<SVGCircleElement>,
				'cx' | 'cy' | 'r' | 'strokeWidth' | 'strokeDasharray'
			> = {
				cx: circleValues.center.x,
				cy: circleValues.center.y,
				r: circleValues.radius,
				strokeWidth: chartStrokeWidth,
				strokeDasharray: circleValues.perimeter,
			};

			const progressGradientUrl = `url(#${progressGradientColorId})`;

			if (skeleton) {
				return <SkeletonableCircle {...commonCircleProps} />;
			}

			if (arcValues.arcAngle === 0) {
				return (
					<circle
						{...commonCircleProps}
						fill={getThemeColor(circleFillColor)}
						stroke={getThemeColor(circleStrokeColor)}
					/>
				);
			}

			return (
				<>
					<circle
						{...commonCircleProps}
						fill={getThemeColor(circleFillColor)}
						stroke={getThemeColor(circleStrokeColor)}
					/>

					<animated.path
						d={arcPathAnimationProps.arcAngle.to(getArcAnimatedValueD)}
						fill={progressGradientUrl}
						stroke={getThemeColor(progressColor)}
						strokeWidth={chartStrokeWidth}
					/>
				</>
			);
		}, [
			arcValues,
			chartStrokeWidth,
			circleFillColor,
			circleStrokeColor,
			circleValues,
			progressGradientColorId,
			progressColor,
			skeleton,
			arcPathAnimationProps,
			getArcAnimatedValueD,
		]);

		return (
			<Svg
				data-cy={dataCy}
				xmlns='http://www.w3.org/2000/svg'
				viewBox={`0 0 ${size} ${size}`}
				css={{
					width: size,
					height: size,
					...css,
				}}
				ref={forwardedRef}
				{...svgProps}
			>
				{getSvgContent()}

				<defs>
					<radialGradient
						id={progressGradientColorId}
						cx='0'
						cy='0'
						r='1'
						gradientUnits='userSpaceOnUse'
						gradientTransform='translate(30 30) rotate(-90) scale(30 30)'
					>
						<stop offset='0.00121881' stopColor={getThemeColor(progressColor)} />
						<stop offset='0.898891' stopColor={getThemeColor(progressColor)} stopOpacity='0.32' />
					</radialGradient>
				</defs>
			</Svg>
		);
	},
);
