import React from 'react';
import './component.less';

export class Props {
	selected: boolean;
}

export default class GradeScaleCheckbox extends React.PureComponent<Props> {
	renderIcon() {
		if (this.props.selected) {
			return <div className='checkbox-icon'>
				<svg className='check-svg' width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path fillRule='evenodd' clipRule='evenodd'
						  d='M4.74099 7.59404C4.9837 8.19547 5.19293 8.81052 5.39588 9.4301C5.81016 8.86726 6.23071 8.30441 6.6701 7.75972C8.64732 5.29726 12.7127 0.778587 15.9578 0.971498C16.9245 1.02824 16.8973 1.35278 16.0896 1.77038C12.5955 3.57921 9.67882 8.47689 7.66394 11.9629C7.1576 12.8412 6.43366 14.3709 5.60721 14.8475C5.08413 15.1494 4.37275 15.0699 3.88315 14.7227C2.94789 14.0623 1.8306 10.7873 1.1799 9.54585C0.684022 8.47916 1.07738 7.17871 2.06076 6.63856C3.04414 6.10068 4.24303 6.52735 4.74099 7.59404Z'
						  fill='#2FB454'/>
				</svg>
				<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<circle cx='12' cy='12' r='11' fill='#2FB454' fillOpacity='0.3' stroke='#2FB454'
							strokeWidth='2'></circle>
				</svg>
			</div>;
		} else {
			return <div className='checkbox-icon'>
				<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<circle cx='12' cy='12' r='11' stroke='#BDBDBD' strokeWidth='2'></circle>
				</svg>
			</div>;
		}
	}

	render() {
		return <div className='grade-scale-checkbox'>
			{this.renderIcon()}
		</div>;
	}
}