import {SubjectType} from '@esgi/core/enums';
import {HierarchySnapshot} from '../../hierarchy/core/models';

export class RequestSettingsInit {
	constructor(public selectedGroupItemID: number,
	            public selectedStudentID: number,
	            public subjectID: number,
	            public subjectType: SubjectType,
	            public hierarchy: HierarchySnapshot) { }
}

export class ResponseSettingsInit {
	selectedGroupItemID: number;
	selectedGroupItemType: string;
	selectedStudentID: number;
	user: ResponseUser;
	subjects: ResponseSubject[];
	groupItems: ResponseGroupItem[];
	hasGradeScales: boolean;
	readOnly: boolean;
	tests: ResponseTest[];
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	tracks: ResponseTrack[];
}

export class RequestSettingsSubjects {
	hierarchy: HierarchySnapshot;
}

export class RequestResultsInit {
	reportGuid: string;
	teacherID: number;
	subject: ResponseSubject;
	globalSchoolYearID: number;
	includeTestNotes: boolean;
	includeSummaryNotes: boolean;
	includeLetter: boolean;
	includeGradeScale: boolean;
	printInColor: boolean;
	showSessionDate: boolean;
	includeUntestedQuestions: boolean;
	tests: RequestTest[];
	studentIDs: number[];
	allStudentsSelected: boolean;
	groupItemID: number;
	groupItemLevel: string;
	looseMode: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	hierarchy: HierarchySnapshot;
}

export class RequestTest {
	testID: number;
	max?: number;
	testDate?: string;
}

export class RequestSettingsFetchHierarchy {
	subjectID: number;
	subjectType: string;
	students: RequestSettingsStudent[];
	hierarchy: HierarchySnapshot;
}

export interface RequestSettingsFetchSessionHierarchy {
	tests: RequestSettingsTest[];
	studentIDs: number[];
}

export interface RequestSettingsFetchSubjectSessionHierarchy extends RequestSettingsFetchHierarchy {
	testDate: string;
}

export class RequestSettingsStudent {
	studentID: number;
	gradeLevelID: number;
}

export interface RequestSettingsTest {
	testID: number;
	testDate: string;
	questionsCount: number;
}





export class ResponseSubjects {
	subjects: ResponseSubject[];
}

export class ResponseGroupItem {
	id: number;
	name: string;
	itemType: string;
	selectedStudentID: number;
	students: ResponseStudent[];
}

export class ResponseStudent {
	studentID: number;
	firstName: string;
	lastName: string;
	languageID: number;
	gradeLevelID: number;
}

export class ResponseUser {
	userID: number;
	firstName: string;
	lastName: string;
	title: string;
	includeQuestionNotesOption: Flag;
	includeSummaryNotesOption: Flag;
	includeGradeOption: Flag;
	includeUntestedQuestionsOption: Flag;
	includeMessageOption: Flag;
	printInColor: Flag;
	showSessionDate: Flag;
}

export interface ResponseFetchHierarchy {
	tests: ResponseTest[];
}

export interface ResponseTest {
	id: number;
	name: string;
	type: string;
	correct: number;
	incorrect: number;
	untested: number;
	orderNum: number;
	questionsCount: number;
	testDate?: string;
	dataExists: boolean;
}

export class ResponseTrack {
	trackID: number;
	dateTo: string;
}






export class ResponseResultsInit {
	groupItem: ResponseGroupItem;
	students: ResponseResultsStudent[];
	user: ResponseUser;
	letterTemplates: ResponseResultsLetterTemplate[];
	gradeScaleEntries: ResponseResultsGradeScaleEntry[];
	reportDate: string;
	subject: string;
	reportGuid: string;
	showSessionDate: boolean;
	translations: { [languageId: string]: { [name: string]: string } };
	logo: ResponseResultsLogoModel;
}

export class ResponseResultsGroupItem {
	name: string;
	itemType: string;
}

export class ResponseResultsLogoModel {
	base64: string;
	contentType: string;
}

export class ResponseResultsStudent {
	firstName: string;
	lastName: string;
	studentIDN: string;
	studentID: number;
	languageID: number;
	testResults: ResponseResultsTestResult[];
}

export class ResponseResultsLetterTemplate {
	letterText: string;
	languageID: number;
}

export class ResponseResultsTestResult {
	testID: number;
	testType: string;
	testName: string;
	questionsTotal: number;
	answers: ResponseResultsAnswer[];
	gradeScale: ResponseResultsGradeScaleEntry;
	fullwidth: boolean;
	isSkip: boolean;
	summaryNotes: string;
	testDate: Date;
}

export class ResponseResultsAnswer {
	questionName: string;
	questionNote: string;
	score: number;
}

export class ResponseResultsUser {
	firstName: string;
	lastName: string;
	title: string;
	userID: number;
}

export class ResponseResultsGradeScaleEntry {
	name: string;
	description: string;
	color: string;
}

export class ResponseResultsTranslation {
	name: string;
	value: string;
}


export class LanguageRequestCreate {
	name: string;
	userID: number;
}

export class LanguageRequestUpdate {
	languageID: number;
	name: string;
	userID: number;
}

export class LanguageRequestUniqueness {
	name: string;
	userID: number;
}

export class LanguageResponseCreate {
	languageID: number;
}

export class LanguageResponseUniqueness {
	isUnique: boolean;
}

export class Language {
	LanguageID: number;
	LanguageName: string;
	IsDefault: boolean;
}

export class Flag {
	name: string;
	value: boolean;
}

export class ResponseSubject {
	name: string;
	subjectID: number;
	subjectType: string;
	subjectLevel: string;
	hasScales?: boolean;
}

export enum FileType {
	PDF, Word, ZIP
}



