import {useMemo, useState, useEffect} from 'react';
import {useUser} from '@esgi/core/authentication';
import {PieChartService} from '../../../../services';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {SubjectType} from '@esgi/core/enums';

export function useSettings(service: PieChartService) {
	const reportData = useBehaviorSubject(service.reportData$);
	const unitDataSettings = useBehaviorSubject(service.unitDataSettings$);
	const printSettings = useBehaviorSubject(service.printSettings$);
	const subjectList = useBehaviorSubject(service.subjectService.subjects$);
	const classList = useBehaviorSubject(service.studentService.classes$);
	const groupList = useBehaviorSubject(service.studentService.groups$);
	const studentList = useBehaviorSubject(service.studentService.students$);
	const tab = useBehaviorSubject(service.tab$);
	const {userID} = useUser();
	const {selectedClass, selectedGroup, selectedStudent} = unitDataSettings;
	const [state, setState] = useState({
		classId: 0,
		groupId: 0,
		studentId: 0,
	});

	const teachers = useMemo(() => [{
		teacherID: userID,
		name: printSettings.teacherName,
		classes: [],
	}], []);
	const subjects = useMemo(() => subjectList.map(
		({id: subjectID, type, name: subjectName}) => ({
			subjectID, subjectType: SubjectType[type], subjectName,
		})
	), [subjectList]);
	const classes = useMemo(() => classList
		.filter(({studentIDs}) => studentIDs.length > 0)
		.map(({id: unityID, name}) => ({unityID, name, students: []})),
	[classList]);
	const groups = useMemo(() => groupList
		.filter(({studentIDs}) => studentIDs.length > 0)
		.map(({id: unityID, name}) => ({unityID, name, students: []})),
	[groupList]);
	const students = useMemo(() => {
		if (state.classId) {
			const {studentIDs = []} = service.studentService.getClass(state.classId) || {};
			return studentList
				.filter(({id}) => studentIDs.includes(id))
				.map(({id: studentID, firstName, lastName}) => ({studentID, firstName, lastName}));
		} else if (state.groupId) {
			const {studentIDs = []} = service.studentService.getGroup(state.groupId) || {};
			return studentList
				.filter(({id}) => studentIDs.includes(id))
				.map(({id: studentID, firstName, lastName}) => ({studentID, firstName, lastName}));
		}
		return studentList
			.map(({id: studentID, firstName, lastName}) => ({studentID, firstName, lastName}));
	}, [state.classId, state.groupId]);

	useEffect(() => {
		if (selectedStudent) {
			const studentId = selectedStudent.id;
			let classId = 0;
			let groupId = 0;
			if (tab === 'groups') {
				for (const item of groupList) {
					if (item.studentIDs.includes(studentId)) {
						groupId = item.id;
						break;
					}
				}
			} else {
				for (const item of classList) {
					if (item.studentIDs.includes(studentId)) {
						classId = item.id;
						break;
					}
				}
			}
			setState({classId, groupId, studentId});
			return;
		}
		if (tab === 'groups') {
			setState({
				...state,
				groupId: selectedGroup ? selectedGroup.id : 0,
			});
			return;
		}
		setState({
			...state,
			classId: selectedClass ? selectedClass.id: 0,
		});
	}, []);

	return {
		reportData,
		unitDataSettings,
		printSettings,
		subjects,
		teachers,
		classes,
		groups,
		students,
		teacherID: userID,
		tab,
		state,
		setState,
	};
}
