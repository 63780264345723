import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {SubjectType} from '@esgi/core/enums';
import {
	GradeValue,
	MarkingPeriod,
	Settings,
	ReportData,
	RequestReportModel,
	ATRequest,
	ATResponse,
	Options,
	BackgroundGenerationModel,
	InitOptions,
	Report,
	IdNameModel,
	SubjectModel,
	SearchTestResults, TestModel, UpdateRequest,
} from 'modules/reports/student-progress-report/models';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {ObservableBuilder} from '@esgi/api';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {ExceptionReporter} from 'shared/alert/exception-reporter/reporter';
import {SsoTracker} from '@esgi/core/tracker';
import {EventBusManager} from '@esgillc/events';

export default class StudentProgressReportService extends BaseService {
	public globalSchoolYearID: number = 0;
	public groups = new BehaviorSubject<IdNameModel[]>([]);
	public subjects = new BehaviorSubject<SubjectModel[]>([]);
	public classes = new BehaviorSubject<IdNameModel[]>([]);
	public teacherName: string;
	public testResultsCorrectVerbiage: string;
	public markingPeriods = new BehaviorSubject<MarkingPeriod[]>([]);
	public specialistGroups = new BehaviorSubject<IdNameModel[]>([]);
	public students = new BehaviorSubject<IdNameModel[]>([]);
	public gradeValues = new BehaviorSubject<GradeValue[]>([]);
	public selectedSubject = new BehaviorSubject<SubjectModel>(null);
	public selectedTests = new BehaviorSubject<TestModel[]>([]);
	public selectedClass = new BehaviorSubject<IdNameModel>(null);
	public selectedGroup = new BehaviorSubject<IdNameModel>(null);
	public selectedSpecialistGroup = new BehaviorSubject<IdNameModel>(null);
	public selectedStudent = new BehaviorSubject<IdNameModel>(null);
	public reportDisplayResultsMode = new BehaviorSubject<string>('1');
	public displayZeroIfNotTested = new BehaviorSubject<boolean>(false);
	public carryScoresForward = new BehaviorSubject<boolean>(false);
	public showGradeColors = new BehaviorSubject<boolean>(false);
	public showBaseline = new BehaviorSubject<boolean>(true);
	public emptyReport = new BehaviorSubject<boolean>(false);
	public report = new BehaviorSubject<Report>(null);
	private hierarchyUpdated = new BehaviorSubject<boolean>(false);
	private controllerName = 'reports/student-progress';
	private hierarchy: HierarchySnapshot;
	private eventBusManager: EventBusManager = new EventBusManager();

	public get settings(): Settings {
		return {
			classID: this.selectedClass.value ? this.selectedClass.value.id : 0,
			groupID: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			subjectTabID: this.selectedSubject.value ? this.selectedSubject.value.id : 0,
			subjectTabType: SubjectType[this.selectedSubject.value.subjectType],
			specialistGroupID: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			studentID: this.selectedStudent.value ? this.selectedStudent.value.id : 0,
		};
	}

	public get options(): RequestReportModel {
		return {
			carryScoresForward: this.carryScoresForward.value,
			classID: this.selectedClass.value ? this.selectedClass.value.id : 0,
			displayZeroIfNotTested: this.displayZeroIfNotTested.value,
			globalSchoolYearID: this.globalSchoolYearID,
			groupID: this.selectedGroup.value ? this.selectedGroup.value.id : 0,
			hierarchyUpdated: this.hierarchyUpdated.value,
			reportDisplayResultsMode: +this.reportDisplayResultsMode.value,
			selectedTests: this.selectedTests.value,
			showBaseline: this.showBaseline.value,
			showGradeColors: this.showGradeColors.value,
			specialistGroupID: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			studentID: this.selectedStudent.value ? this.selectedStudent.value.id : 0,
			subjectID: this.selectedSubject.value ? this.selectedSubject.value.id : 0,
			subjectLevel: this.selectedSubject.value ? this.selectedSubject.value.subjectLevel : '',
			subjectType: this.selectedSubject.value ? SubjectType[this.selectedSubject.value.subjectType] : '',
			zip: false,
		};
	}

	public get updateOptions(): UpdateRequest {
		return {
			carryScoresForward: this.carryScoresForward.value,
			classID: this.selectedClass.value ? this.selectedClass.value.id : 0,
			displayZeroIfNotTested: this.displayZeroIfNotTested.value,
			globalSchoolYearID: this.globalSchoolYearID,
			groupID: this.selectedGroup.value ? this.selectedGroup.value.id : 0,
			hierarchyUpdated: this.hierarchyUpdated.value,
			reportDisplayResultsMode: +this.reportDisplayResultsMode.value,
			selectedTests: this.selectedTests.value,
			showBaseline: this.showBaseline.value,
			showGradeColors: this.showGradeColors.value,
			specialistGroupID: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			studentID: this.selectedStudent.value ? this.selectedStudent.value.id : 0,
			subjectID: this.selectedSubject.value ? this.selectedSubject.value.id : 0,
			subjectLevel: this.selectedSubject.value ? this.selectedSubject.value.subjectLevel : '',
			subjectType: this.selectedSubject.value ? SubjectType[this.selectedSubject.value.subjectType] : '',
		};
	}

	public changeSelectedClass = (id) => {
		this.selectedClass.next(this.classes.value.find(x => x.id === id));
		this.hierarchyUpdated.next(true);
		this.updateStudentID({id: 0, name: 'All'});
		const options = {...this.updateOptions, classID: id, studentID: 0};
		this.updateReportData(options, true);
	};

	public changeSelectedGroup = (id) => {
		this.selectedGroup.next(this.groups.value.find(x => x.id === id));
		this.hierarchyUpdated.next(true);
		this.updateStudentID({id: 0, name: 'All'});
		const options = {...this.updateOptions, groupID: id, studentID: 0};
		this.updateReportData(options, true);
	};

	public changeSelectedStudent = (id) => {
		const selectedStudent = this.students.value.find(x => x.id === id);
		this.selectedStudent.next(selectedStudent);
		this.hierarchyUpdated.next(true);
		this.updateStudentID(selectedStudent);
		const options = {...this.updateOptions, studentID: id};
		this.updateReportData(options, true);
	};

	public changeSelectedSpecialist = (id) => {
		this.selectedSpecialistGroup.next(this.specialistGroups.value.find(x => x.id === id));
		this.hierarchyUpdated.next(true);
		this.updateStudentID({id: 0, name: 'All'});
		const options = {...this.updateOptions, specialistGroupID: id, studentID: 0};
		this.updateReportData(options, true);
	};

	public changeSelectedSubject = (id) => {
		this.selectedSubject.next(this.subjects.value.find(x => x.id === id));
		const options = {...this.updateOptions, subjectID: id};
		this.updateReportData(options, false);
	};

	public changeDisplayResultsMode = (value: string) => {
		this.reportDisplayResultsMode.next(value);
		this.updateReportDisplayResultsMode(value);
	};

	public changeDisplayZeroIfNotTested = (value: boolean) => {
		this.displayZeroIfNotTested.next(value);
		this.updateSettings('displayZeroIfNotTested', value).subscribe();
	};

	public changeShowGradeColors = (value: boolean) => {
		this.showGradeColors.next(value);
		this.updateSettings('showGradeColors', value).subscribe();
	};

	public changeCarryScoresForward = (value: boolean) => {
		this.carryScoresForward.next(value);
		this.updateSettings('carryScoresForward', value).subscribe(() => {
			const options = {...this.options, carryScoresForward: value, subjectType: this.selectedSubject.value.subjectType};
			this.initOnCarryForwardChange(options, this.hierarchy).then((data) => {
				this.report.next(data.report);
			});
		});
	};

	public changeShowBaseline = (value: boolean) => {
		this.showBaseline.next(value);
		this.updateShowBaseline(value);
	};

	public updateStudentID(student: IdNameModel) {
		this.selectedStudent.next(student);
		this.hierarchy.classic.studentID = this.hierarchy.specialist.studentID = student.id;
	}

	public initReport(options: Options) {
		const opt: InitOptions = {
			ClassID: options.classId,
			GroupID: options.groupId,
			SpecialistGroupID: options.specialistGroupID,
			StudentID: options.studentId,
			SubjectID: options.subjectId,
			SubjectType: options.subjectType,
			SubjectLevel: options.subjectLevel,
			SelectedTests: options.selectedTests,
			GlobalSchoolYearID: options.globalSchoolYearId,
		};
		this.selectedTests.next(options.selectedTests);
		this.globalSchoolYearID = options.globalSchoolYearId;
		return this.init(opt, options.hierarchy).pipe(tap((data: ReportData) => {
			const localSubjects = data.subjects.map(x => ({id: x.subjectID, name: x.name, subjectType: x.subjectType, subjectLevel: x.subjectLevel}));
			localSubjects.unshift({id: 0, name: 'All', subjectType: 'Personal', subjectLevel: 'Teacher'});
			this.subjects.next(localSubjects);
			this.selectedSubject.next(localSubjects.filter(s => s.id === options.subjectId)[0]);
			this.hierarchy = options.hierarchy;
			this.teacherName = data.teacherName;
			this.testResultsCorrectVerbiage = data.testResultsCorrectVerbiage;

			if (data.classes && data.classes.length > 0) {
				const localClasses = data.classes.map(x => ({id: x.classID, name: x.name}));
				this.classes.next(localClasses);
				this.selectedClass.next(localClasses.filter(s => s.id === options.classId)[0]);
			} else {
				this.classes.next([]);
			}

			if (data.groups && data.groups.length > 0) {
				const localGroups = data.groups.map(x => ({id: x.groupID, name: x.name}));
				this.groups.next(localGroups);
				this.selectedGroup.next(localGroups.filter(s => s.id === options.groupId)[0]);
			} else {
				this.groups.next([]);
			}

			if (data.specialistGroups && data.specialistGroups.length > 0) {
				const localSpecialistGroups = data.specialistGroups.map(x => ({id: x.specialistGroupID, name: x.name}));
				this.specialistGroups.next(localSpecialistGroups);

				let specialistGroup = localSpecialistGroups.filter(s => s.id === options.specialistGroupID)[0];
				if (!specialistGroup) {
					specialistGroup = this.specialistGroups[0];
				}

				this.selectedSpecialistGroup.next(specialistGroup);
			} else {
				this.specialistGroups.next([]);
			}

			this.hierarchyUpdated.next(false);

			const students = data.students.map(x => ({id: x.studentID, name: x.name}));
			students.unshift({id: 0, name: 'All'});
			this.students.next(students);
			this.selectedStudent.next(students.filter(s => s.id === options.studentId)[0]);

			this.report.next(data.report);
			if (data.report.gradeScales.length === 1) {
				this.gradeValues.next(data.report.gradeScales[0].gradeValues);
			} else {
				this.gradeValues.next([]);
			}

			this.showBaseline.next(data.showBaseline);
			this.displayZeroIfNotTested.next(data.displayZeroIfNotTested);
			this.carryScoresForward.next(data.carryScoresForward);
			this.reportDisplayResultsMode.next(data.reportDisplayResultsMode.toString());
			this.showGradeColors.next(data.showGradeColors);

			if (this.report.value.subjectGradeScales.length === 0 && this.reportDisplayResultsMode.value === '2') {
				this.reportDisplayResultsMode.next('0');
			}

			const localMP = [];
			localMP.push({index: 0, title: 'B'});
			for (let i = 0; i < data.report.trackDates.length; i++) {
				localMP.push({index: i + 1, title: (i + 1).toString()});
			}
			this.markingPeriods.next(localMP);

			this.fillStudentsInfo(data.report);
		}));
	}

	public downloadPdf = (zip) => {
		const localOptions = {...this.options, zip, subjectType: this.selectedSubject.value.subjectType};
		const apprxPagesNum = this.report.value.students.length;
		const backgroundLoading = zip && apprxPagesNum > 30;

		if (backgroundLoading) {
			this.startBackgroundGeneration(localOptions, this.hierarchy).subscribe((model: BackgroundGenerationModel) => {
				const args: BackgroundDownloadManagerEvents.StartArgs = {
					reportGuid: model.reportGuid,
					fileType: 'ZIP',
					displayRemainingText: true,
				};
				this.eventBusManager.dispatch(BackgroundDownloadManagerEvents.Start, args);
			});
		} else {
			const date = new Date();
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			const extension = zip ? 'zip' : 'pdf';
			const filename = 'Student_Progress_Report_' + year + '-' + month + '-' + day + '.' + extension;
			if (this.selectedClass.value !== null) {
				this.hierarchy.classic.classID = this.selectedClass.value.id;
			}

			if (this.selectedGroup.value !== null) {
				this.hierarchy.classic.groupID = this.selectedGroup.value.id;
			}
			this.httpClient.ESGIApi.file('reports/student-progress', 'download-pdf', filename, {
				requestReportModel: localOptions,
				hierarchy: this.hierarchy,
			}).subscribe(() => {
			}, () => {
				const reporter = new ExceptionReporter();
				reporter.report('Unable to load pdf file.');
			});
		}

		if (this.options.studentID === 0) {
			const eventType = zip ? 'PDF Bulk' : 'PDF Standard';
			SsoTracker.trackEvent({
				trackingEvent: eventType,
				data: {reportType: 'Progress'},
			});
		}
	};

	private fillStudentsInfo(report: Report) {
		for (let i = 0; i < report.students.length; i++) {
			const subjects = report.students[i].subjects;

			for (let j = 0; j < subjects.length; j++) {
				const item = subjects[j];
				item.name = subjects.filter(x => x.subjectID === item.subjectID)[0].name;
				item.gradeValues = null;

				if (report.gradeScales.length === 1 && j !== 0) {
					item.gradeValues = null;
					continue;
				}

				const gradeScaleData = report.subjectGradeScales.filter(t => t.subjectID === item.subjectID)[0];

				if (gradeScaleData == null) {
					continue;
				}

				const gradeScaleID = gradeScaleData.gradeScaleID;
				item.gradeValues = report.gradeScales.filter(t => t.gradeScaleID === gradeScaleID)[0].gradeValues;
			}
		}
		this.report.next(report);
		this.emptyReport.next(report.students.length === 0 && this.students.value.length !== 1);
	}

	private updateReportData(options: UpdateRequest, reloadStudents: boolean = false) {
		let filteredSubjects = this.subjects.value.filter(x => x.id !== 0);
		if (options.subjectID !== 0) {
			filteredSubjects = filteredSubjects.filter(x => x.id === options.subjectID);
		}

		const testsRequest = new ATRequest();
		testsRequest.GlobalSchoolYearID = options.globalSchoolYearID;
		testsRequest.Subjects = filteredSubjects.map(x => ({subjectId: x.id, name: '', subjectType: x.subjectType, subjectLevel: x.subjectLevel}));
		testsRequest.SearchBy = new SearchTestResults();
		testsRequest.SearchBy.ByStudentID =options.studentID;
		testsRequest.Hierarchy = this.hierarchy;
		testsRequest.ClassID = options.classID;
		testsRequest.GroupID = options.groupID;
		testsRequest.SpecialistGroupID = options.specialistGroupID;
		testsRequest.StudentID = options.studentID;
		testsRequest.HierarchyUpdated = options.hierarchyUpdated;

		return this.getTests(testsRequest).then((response: ATResponse) => {
			const tests = [];
			for (let i = 0; i < response.subjectTests.length; i++) {
				const subject = response.subjectTests[i];
				for (let j = 0; j < subject.tests.length; j++) {
					const test = subject.tests[j];
					if (test.isTested) {
						const subjectData = filteredSubjects.filter(x => x.id === subject.subjectID)[0];
						tests.push(<TestModel>{
							testID: test.testID,
							subjectID: subject.subjectID,
							subjectType: subjectData.subjectType,
							subjectLevel: subjectData.subjectLevel,
						});
					}
				}
			}
			options = {...options, selectedTests: tests};
			this.selectedTests.next(tests);
			this.update(options, this.hierarchy).then((data: ReportData) => {
				if (reloadStudents) {
					const students = data.students.map(x => ({id: x.studentID, name: x.name}));
					students.unshift({id: 0, name: 'All'});
					this.students.next(students);
					this.selectedStudent.next(students.filter(s => s.id === options.studentID)[0]);
				}
				if (data.report) {
					if (data.report.subjectGradeScales.length === 0 && options.reportDisplayResultsMode === 2) {
						this.reportDisplayResultsMode.next('0');
					}

					this.report.next(data.report);

					if (data.report.gradeScales.length === 1) {
						this.gradeValues.next(data.report.gradeScales[0].gradeValues);
					} else {
						this.gradeValues.next([]);
					}

					this.fillStudentsInfo(data.report);

					this.emptyReport.next(data.report.students.length === 0 && this.students.value.length > 1);
				} else {
					this.emptyReport.next(this.students.value.length > 1);

					this.report.next(null);
				}
			});
		});
	}

	private init(requestReportModel: InitOptions, hierarchy: HierarchySnapshot) {
		return this.httpClient.ESGIApi.post<ReportData>(this.controllerName, 'init',
			{
				requestReportModel: requestReportModel,
				hierarchy: hierarchy,
			});
	}

	private initOnCarryForwardChange(requestReportModel: RequestReportModel, hierarchy: HierarchySnapshot) {
		const localOptions = {
			CarryScoresForward: requestReportModel.carryScoresForward,
			ClassID: this.selectedClass.value ? this.selectedClass.value.id : 0,
			DisplayZeroIfNotTested: this.displayZeroIfNotTested.value,
			GlobalSchoolYearID: this.globalSchoolYearID,
			GroupID: this.selectedGroup.value ? this.selectedGroup.value.id : 0,
			HierarchyUpdated: this.hierarchyUpdated.value,
			ReportDisplayResultsMode: +this.reportDisplayResultsMode.value,
			SelectedTests: this.selectedTests.value,
			ShowBaseline: this.showBaseline.value,
			ShowGradeColors: this.showGradeColors.value,
			SpecialistGroupID: this.selectedSpecialistGroup.value ? this.selectedSpecialistGroup.value.id : 0,
			StudentID: this.selectedStudent.value ? this.selectedStudent.value.id : 0,
			SubjectID: this.selectedSubject.value ? this.selectedSubject.value.id : 0,
			SubjectLevel: this.selectedSubject.value ? this.selectedSubject.value.subjectLevel : '',
			SubjectType: this.selectedSubject.value.subjectType,
		};
		return this.httpClient.ESGIApi.post<ReportData>(this.controllerName, 'init',
			{
				requestReportModel: localOptions,
				hierarchy: hierarchy,
			}).toPromise();
	}

	private update(requestReportModel: UpdateRequest, hierarchy: HierarchySnapshot) {
		return this.httpClient.ESGIApi.post<ReportData>(this.controllerName, 'update',
			{
				requestReportModel: requestReportModel,
				hierarchy: hierarchy,
			}).toPromise();
	}

	private getTests(request: ATRequest): Promise<ATResponse> {
		return this.httpClient.ESGIApi.post<ATResponse>('reports/student-report-data', 'available-tests', request).toPromise();
	}

	private updateSettings(name: string, value: boolean) {
		return this.httpClient.ESGIApi.post(
			'reports/user-settings',
			'update',
			{name, value});
	}

	private updateReportDisplayResultsMode(value: string) {
		this.httpClient.ESGIApi.post(
			'reports/user-settings',
			'report-display-results-mode',
			{value: +value})
			.subscribe();
	}

	private updateShowBaseline(showBaseline: boolean) {
		this.httpClient.ESGIApi.post(this.controllerName, 'show-baseline', {Value: showBaseline}).subscribe();
	}

	private startBackgroundGeneration(requestReportModel: RequestReportModel, hierarchy: HierarchySnapshot): ObservableBuilder<BackgroundGenerationModel> {
		return this.httpClient.ESGIApi.post(this.controllerName, 'start-background-generation', {
			requestReportModel: requestReportModel,
			hierarchy: hierarchy,
		});
	}
}
