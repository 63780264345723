import {BehaviorSubject, skipUntil} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {BaseService} from '@esgi/core/service';
import {ReportService} from '../utils/report-service';
import {userStorage, getUser, UserType} from '@esgi/core/authentication';
import {
	Class,
	Group,
	ReportModel,
	SpecialistGroup,
	StudentData,
	Subject,
	Test,
	TestData,
	ReportRequest,
} from './models';
import {EventBusManager} from '@esgillc/events';
import {SubjectType, StudentSort} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {untestedStudentsReportUtils} from './utils';
import {selectedStudentID, selectedUserID} from '../../hierarchy/utils';
import {TestSavedEvent} from 'modules/assessments';
import {mapToEnum} from '../../../shared/utils';
import {TestSessionDetailsChanged} from 'shared/modules/test/test-session-details/events';
import {TestSessionDetailsChanged as TestSessionDetailsChangedNew} from 'modules/forms/test-session-details-form/events';
import {ObservableBuilder} from '@esgi/api';

export class UntestedStudentsReportService extends BaseService {
	public hierarchyUpdated = new BehaviorSubject<boolean>(false);
	public teacherName = new BehaviorSubject<string>('');
	public currentUser = userStorage.get();
	public classes = new BehaviorSubject<Array<Class>>([]);
	public groups = new BehaviorSubject<Array<Group>>([]);
	public specialistGroups = new BehaviorSubject<Array<SpecialistGroup>>([]);
	public subjects = new BehaviorSubject<Array<Subject>>([]);
	public tests = new BehaviorSubject<Array<Test>>([]);
	public headerTests = new BehaviorSubject<Array<Test>>([]);
	public lastUntestedWeeks = new BehaviorSubject<number>(1);
	public showOnlyUntested = new BehaviorSubject<boolean>(false);
	public selectedTest = new BehaviorSubject<Test[]>([]);
	public selectedClass = new BehaviorSubject<Class[]>([]);
	public selectedGroup = new BehaviorSubject<Group[]>([]);
	public selectedSpecialistGroup = new BehaviorSubject<SpecialistGroup[]>([]);
	public selectedSubject = new BehaviorSubject<Subject[]>([]);
	public emptyReport = new BehaviorSubject<boolean>(false);
	public lastTestedTestID = new BehaviorSubject<number | null>(null);
	public lastTestedStudentID = new BehaviorSubject<number | null>(null);
	public isEdge = new BehaviorSubject<boolean>(false);
	public isTeacherOrSpecialist = [UserType.T, UserType.ISD, UserType.ISS, UserType.PA].indexOf(this.currentUser.userType) !== -1;
	public firstLoadingSuccess = new BehaviorSubject<boolean>(false);
	public eventBus = new EventBusManager();
	public reportService: ReportService<StudentData> = new ReportService();
	private teacherID: number;
	private subjectID: number;
	private subjectType: SubjectType;
	private hierarchy: HierarchySnapshot;
	private controller = 'reports/untested-students';

	constructor() {
		super();
		this.lastUntestedWeeks.pipe(
			distinctUntilChanged(),
			skipUntil(this.firstLoadingSuccess.pipe(filter(Boolean))),
			debounceTime(400),
		).subscribe(() => {
			this.firstLoadingSuccess.value && this.updateReport(true);

			this.httpClient.ESGIApi.post(
				'reports/user-settings',
				'last-untested-weeks',
				{value: this.lastUntestedWeeks.value},
			).subscribe();
		});
	}

	public destroy() {
		super.destroy();
		this.eventBus.destroy();
	}

	public init(subjectID: number, subjectType: SubjectType, hierarchy: HierarchySnapshot) {
		const studentSort = hierarchy.studentSort[0].toLowerCase() + hierarchy.studentSort.slice(1);
		this.subjectID = subjectID;
		this.subjectType = subjectType;
		this.hierarchy = hierarchy;
		this.reportService.setSortModel({
			fieldName: studentSort,
			direction: 'Asc',
		});
		const {userID} = getUser();
		this.teacherID = selectedUserID(hierarchy) || userID;
		this.isEdge.next(window.navigator.userAgent.indexOf('Edge') > -1);
		const {fieldName, direction} = this.reportService.sortModel.value;

		const request = {
			subjectID,
			subjectType,
			getFilter: true,
			lastUntestedWeeks: 1,
			periodDataOnly: false,
			hierarchy,
			testIDs: [],
			gradeLevelIDs: [],
			teacherID: this.teacherID,
			userID: this.currentUser.userID,
			studentSort: StudentSort[fieldName],
			ascending: direction === 'Asc',
		};

		this.getReport(request);
	}

	public setLastUntestedWeeks(value: number) {
		if (value > 0 && value <= 40) {
			this.lastUntestedWeeks.next(value);
		}
	}

	public toggleShowOnlyUntested() {
		this.showOnlyUntested.next(!this.showOnlyUntested.value);
	}

	public filterStudentsByShowOnlyUntestedOption(doNotSkipStudentID: number = null) {
		if (!this.showOnlyUntested.value) {
			this.reportService.setSortFilter(({studentID, tests}) => true);
			return;
		}
		const selectedTestID = this.selectedTest.value[0]?.testID;
		this.reportService.setSortFilter(({studentID, tests}) => {
			const skip = doNotSkipStudentID !== null ? studentID === doNotSkipStudentID : false;
			const overPeriod = tests
				.filter(({testID}) => testID === selectedTestID)
				.every(({lastTestDateOverPeriod}) => lastTestDateOverPeriod === null);

			return skip || overPeriod;
		});
	}

	public backToAllTests() {
		this.selectedTest.next([this.tests.value[0]]);
	}

	public downloadFile (extension) {
		const actions = {xlsx: 'download-excel', pdf: 'download-pdf'};
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const filename = `Untested_Students_${year}-${month}-${day}.${extension}`;
		const options = this.getExportOptions();

		this.httpClient.ESGIApi.file(
			this.controller,
			actions[extension],
			filename,
			options,
		).subscribe();
	}

	public onGroupChanged = (group: Group[]) => {
		this.selectedGroup.next(group);
	};

	public onSubjectChanged(subject: Subject[]) {
		this.selectedSubject.next(subject);
	}

	public onSelectedTestChanged(test: Test[]) {
		this.selectedTest.next(test);
	}

	public onSelectedSpecialistGroupChanged(group: SpecialistGroup[]) {
		this.selectedSpecialistGroup.next(group);
	}

	public onClassChange(classValue: Class[]) {
		this.selectedClass.next(classValue);
	}

	private updateReport(periodDataOnly: boolean) {
		if (!this.firstLoadingSuccess.value) {
			return;
		}

		const classID = this.selectedClass.value[0]?.classID || 0;
		const groupID = this.selectedGroup.value[0]?.groupID || 0;
		const specialistGroupID = this.selectedSpecialistGroup.value[0]?.specialistGroupID || 0;

		const hierarchy = this.hierarchy;
		hierarchy.classic.classID = classID;
		hierarchy.classic.groupID = groupID;
		hierarchy.specialist.groupID = specialistGroupID;
		hierarchy.preAssess.groupID = specialistGroupID;

		const testIDs = this.selectedSubject.value[0]?.tests.map(({testID}) => testID);
		const {fieldName, direction} = this.reportService.sortModel.value;

		const request = {
			subjectID: this.selectedSubject.value[0]?.subjectID,
			subjectType: this.selectedSubject.value[0]?.subjectType,
			getFilter: false,
			lastUntestedWeeks: this.lastUntestedWeeks.value,
			periodDataOnly,
			hierarchy,
			testIDs: testIDs ?? [],
			gradeLevelIDs: this.selectedSubject.value[0]?.gradeLevelIDs ?? [],
			teacherID: this.teacherID,
			userID: this.currentUser.userID,
			studentSort: StudentSort[fieldName],
			ascending: direction === 'Asc',
		};

		this.getReportData(request).subscribe(({data}: ReportModel) => {
			if (periodDataOnly) {
				untestedStudentsReportUtils.updatePeriodData(this.reportService.reportRows.value, data.students);
			} else {
				this.reportService.setData(data.students);
				const emptyReport = data.students.length === 0;

				const prevEmptyReportValue = this.emptyReport.value;
				this.emptyReport.next(emptyReport);

				if (prevEmptyReportValue && !emptyReport) {
					this.refreshHeaderTests();
				}
			}

			this.filterStudentsByShowOnlyUntestedOption();
		});
	}

	private subscribeOnForm() {
		this.selectedTest.subscribe((newVal) => {
			if (newVal[0]?.testID === 0) {
				this.showOnlyUntested.next(false);
			}
			this.refreshHeaderTests();
			this.filterStudentsByShowOnlyUntestedOption();
		});

		this.selectedClass.subscribe(() => {
			this.hierarchyUpdated.next(true);
			this.updateReport(false);
		});

		this.selectedGroup.subscribe(() => {
			this.hierarchyUpdated.next(true);
			this.updateReport(false);
		});

		this.selectedSpecialistGroup.subscribe(() => {
			this.hierarchyUpdated.next(true);
			this.updateReport(false);
		});

		this.selectedSubject.subscribe(() => {
			this.refreshTestsDropdown();
			this.refreshHeaderTests();
			this.updateReport(false);
		});
	}

	private getExportOptions() {
		const {subjectID, subjectType} = this.selectedSubject.value[0];
		const {rows} = this.reportService.optionsToExport.value;

		return {
			subjectID,
			subjectType,
			lastUntestedWeeks: this.lastUntestedWeeks.value,
			testID: this.selectedTest.value[0]?.testID,
			showOnlyUntested: this.showOnlyUntested.value,
			hierarchy: this.hierarchy,
			teacherID: this.teacherID,
			studentID: selectedStudentID(this.hierarchy),
			userID: this.currentUser.userID,
			rows,
		};
	}

	private refreshTestsDropdown() {
		const allTestsOption = new Test();
		allTestsOption.testID = 0;
		allTestsOption.name = 'All';
		let tests = this.selectedSubject.value[0]?.tests;
		if (tests) {
			tests = tests.filter(test => test.testID !== 0);
			tests.unshift(allTestsOption);
			this.tests.next(tests);
			this.selectedTest.next([allTestsOption]);
		}
	}

	private refreshHeaderTests() {
		let tests = this.selectedSubject.value[0]?.tests;
		if (this.selectedTest.value[0]?.testID > 0) {
			tests = tests.filter(x => x.testID === this.selectedTest.value[0]?.testID);
		}
		this.headerTests.next(tests);
	}

	private updateStudentTestResult(students: StudentData[], studentID: number, testID: number, correct: number, date: Date) {
		const student = students.filter(x => x.studentID === studentID)[0];
		const filteredTest = student.tests.filter(x => x.testID === testID);
		if (filteredTest.length > 0) {
			const test = filteredTest[0];
			test.correctAnswersOverPeriod = correct;
			test.lastTestDateOverPeriod = date;
		} else {
			const test = new TestData();
			test.testID = testID;
			test.correctAnswersOverPeriod = test.correctAnswersAll = correct;
			test.lastTestDateOverPeriod = test.lastTestDate = date;
			student.tests.push(test);
		}
	}

	private updateResult(event: TestSavedEvent) {
		this.lastTestedTestID.next(event.testID);
		this.lastTestedStudentID.next(event.studentID);
		this.updateStudentTestResult(this.reportService.reportRows.value, event.studentID, event.testID, event.correct, new Date());
		this.filterStudentsByShowOnlyUntestedOption(event.studentID);
	}

	private getReport(request: ReportRequest) {
		this.getReportData(request).subscribe(({filter, data}: ReportModel) => {
			this.teacherName.next(filter.teacherName);
			this.lastUntestedWeeks.next(filter.lastUntestedWeeks);

			const {classes, groups, specialistGroups, subjects} = filter;
			if (classes?.length) {
				this.classes.next(classes);
				this.selectedClass.next([this.classes.value.find(
					({classID}) => classID === filter.classID,
				)]);
			} else {
				this.classes.next([]);
			}

			if (groups?.length) {
				this.groups.next(groups);
				this.selectedGroup.next([this.groups.value.find(
					({groupID}) => groupID === filter.groupID,
				)]);
			} else {
				this.groups.next([]);
			}

			if (specialistGroups?.length) {
				this.specialistGroups.next(specialistGroups);
				this.selectedSpecialistGroup.next([this.specialistGroups.value.find(
					({specialistGroupID}) => specialistGroupID === filter.specialistGroupID,
				)]);
			} else {
				this.specialistGroups.next([]);
			}

			if (subjects?.length) {
				this.subjects.next(subjects.map(
					(s) => ({...s, subjectType: mapToEnum(s.subjectType, SubjectType)}),
				));
				const subjEn = this.subjects.value;
				let subject = subjEn.find((s) => s.subjectID === this.subjectID && s.subjectType === this.subjectType);
				if (!subject) {
					subject = subjEn[0];
				}
				this.selectedSubject.next([subject]);

				this.refreshTestsDropdown();
				this.refreshHeaderTests();
			} else {
				this.subjects.next([]);
			}

			this.reportService.setData(data.students);
			this.emptyReport.next(data.students.length === 0);

			this.subscribeOnForm();
			this.eventBus.subscribe(TestSavedEvent, (e) => {
				if (e.correct !== null) {
					this.updateResult(e);
				}
			});
			this.eventBus.subscribe(TestSessionDetailsChanged, () => {
				this.updateReport(false);
			});
			this.eventBus.subscribe(TestSessionDetailsChangedNew, () => {
				this.updateReport(false);
			});

			this.firstLoadingSuccess.next(true);
		});
	}

	private getReportData(request: ReportRequest): ObservableBuilder<ReportModel> {
		return this.httpClient.ESGIApi.post(
			this.controller,
			'get-report',
			request,
		);
	}
}
