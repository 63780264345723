import Selectors from '@esgi/testing/selectors';
import React, {CSSProperties, ReactNode} from 'react';
import ReactDOM from 'react-dom';
import {join} from '@esgillc/ui-kit/utils';
import {deregisterModal, registerModal} from '@esgillc/ui-kit/modal';
import {ReportErrorEvent} from '@esgi/core/react';
import {Loader} from '@esgi/deprecated/jquery';
import './modal.less';
import {LayerRegisterInfo} from '@esgillc/ui-kit/core';
import {dispatchAppEvent} from '@esgillc/events';

export class ModalProps {
	className?: string;
	fullscreen?: boolean;
	top?: number;
	left?: number;
	animate?: boolean;
	loading?: boolean;
	hidden?: boolean;
	onCatchError?: () => any;
	children?: ReactNode;
}

export class ModalState {
	zIndex: number = 1;
	firstLoaded: boolean;
	loaderShown: boolean;
	hasError: boolean = false;
}

/**
 * @deprecated Use UI-Kit/Modal implementation.
 */
export class Modal extends React.Component<ModalProps, ModalState> {
	protected modalRegisterInfo: LayerRegisterInfo = registerModal();
	private ref: HTMLDivElement;
	private loader: Loader = null;

	constructor(props: ModalProps) {
		super(props);

		const zIndex = this.modalRegisterInfo.priority;
		this.state = {
			zIndex: zIndex,
			firstLoaded: false,
			loaderShown: false,
			hasError: false,
		};
	}

	private get modalContentStyle(): CSSProperties {
		if (this.props.top) {
			return {
				marginTop: this.props.top + 'px',
			};
		}

		return null;
	}

	private get modalDialogStyle(): CSSProperties {
		if (this.props.left) {
			return {
				position: 'absolute',
				left: this.props.left + 'px',
			};
		}

		return null;
	}

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		setTimeout(() => {
			dispatchAppEvent(ReportErrorEvent, {
				message: 'Uh oh. Something went wrong with the UI on our end.',
			} as ReportErrorEvent);
		}, 100);
		this.props.onCatchError && this.props.onCatchError();
		this.setState({hasError: true});
	}

	public componentDidMount(): void {
		window.setTimeout(() => {
			$(this.ref).css('opacity', 1);
			$(this.ref).find('.modal-dialog').css('opacity', 1);
		});
		if (this.props.loading != null) {
			this.loader = new Loader($(this.ref));
			if (this.props.loading) {
				this.showLoader();
			}
		}
		if (!this.props.loading) {
			this.setState({firstLoaded: true});
		}
	}

	public componentDidUpdate(prevProps: ModalProps, prevState: ModalState, snapshot?: any): void {
		if (this.loader && prevProps.loading != this.props.loading) {
			if (this.props.loading) {
				this.showLoader();
			} else {
				this.hideLoader();
			}
		}
	}

	public render() {
		if (this.state.hasError) {
			return <></>;
		}

		return ReactDOM.createPortal(<div id='modal-form' ref={(r) => this.ref = r}
		                                  data-cy={Selectors.UIKit.Modal}
		                                  className={join(
			                                  'modal-form',
			                                  this.props.className,
			                                  this.props.fullscreen && ' fullscreen',
			                                  this.state.firstLoaded && ' first-loaded',
			                                  this.state.loaderShown && ' loader-shown',
			                                  this.props.animate && 'animate',
		                                  )}
		                                  aria-hidden='true' style={{zIndex: this.zIndexValue(), opacity: 0}}>
			<div style={this.modalDialogStyle} className={'modal-dialog ' + (this.props.animate ? ' animate' : '')}>
				<div className={'modal-content'} style={this.modalContentStyle}>
					{this.props.children}
				</div>
			</div>
		</div>, document.body);
	}

	public componentWillUnmount(): void {
		deregisterModal(this.modalRegisterInfo);
	}

	public zIndexValue = () => {
		return this.state.zIndex;
	};

	private showLoader = () => {
		this.setState({loaderShown: true});
		this.loader.mask();
	};

	private hideLoader = () => {
		this.loader.unmask();
		this.setState({loaderShown: false});
	};
}
