export class UnlinkedContinueTemplate {
	public static Render() {
		return <div className='registration-modal'>
            <div className='inner-modal'>
                <div className='header'>
                    <div className='title-container'>
                        <div className='title'><b>Finish setting up your account</b></div>
                    </div>
                </div>
                <div className='fields'>
                    <div className='trial-continue-content'>
                        <div data-bind='render:fullName'/>
                        <label>Country</label>
                        <div data-bind='render:country'/>
                        <label>State</label>
                        <div data-bind='render:state'/>
                        <label>School name</label>
                        <div data-bind='render:school'/>
                        <div data-bind='if:school.selectionType == 1'>
                            <label>District</label>
                            <div data-bind='render:district'/>
                        </div>
                        <label>Timezone</label>
                        <div data-bind='render:timezone'/>
                        <label className='control-label'>Grade Level - select all that apply to you:</label>
                        <div data-bind='render:gradeLevel'/>
                    </div>
                </div>
                <div className='footer'>
                    <div className='actions'>
                        <button className='btn cancel-btn' data-bind='click:eventTriggers.cancel'>CANCEL</button>
                        <button className='btn next-btn' data-bind='click:eventTriggers.done, disable: isDoneClicked()'>DONE</button>
                    </div>
                </div>
            </div>
        </div>;
	}
}
