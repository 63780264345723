import React, {ComponentType, FunctionComponent, lazy, Suspense} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {LoadScriptFailedEvent} from './events';

type ExtraComponentType<P> = ComponentType<P> | ((props: P) => JSX.Element);

/**
 * Return asynchronous React Component loader.
 * @param factory import statement wrapped with arrow function.
 * @example
 * const lazy = lazyComponent(() => import('./a.tsx'));
 * ...
 * render() {
 *     return <Suspense fallback={...}>
 *              <lazy/>
 *         </Suspense>
 * };
 * ...
 * @return LazyExoticComponent
 * @tutorial
 */
export function lazyComponent<P>(factory: () => Promise<{ default: ExtraComponentType<P> }>): FunctionComponent<P> {
	const Component = lazy(() => factory().catch(error => {
		const chunkFailMessage = /Loading chunk [\d]+ failed/;
		if (error && chunkFailMessage.test(error?.message)) {
			dispatchAppEvent(LoadScriptFailedEvent, new LoadScriptFailedEvent());
			console.error(error);
		} else {
			throw error;
		}
		return {default: EmptyComponent as ExtraComponentType<P>};
	}));
	// @ts-ignore
	return (props: P) => <Suspense fallback={<></>}><Component {...props}/></Suspense>;
}

/**
 * The function returns an empty React component.
 * @param {any} props - The `props` parameter is an object that contains the properties passed to the
 * `EmptyComponent` component. These properties can be accessed within the component using dot
 * notation, for example `props.propertyName`.
 * @returns An empty JSX element is being returned.
 */
function EmptyComponent(props: any) {
	return <></>;
}
