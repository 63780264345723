import {BaseService} from '@esgi/core/service';
import {Moment} from 'moment';
import {IFormControlValidatorResult} from '@esgi/deprecated/elements';
import {ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {dispatchAppEvent} from '@esgillc/events';
import {BehaviorSubject, tap} from 'rxjs';
import {userStorage} from '@esgi/core/authentication';
import {UserInfoTrackChangedEvent, UserInfoTrackCreatedEvent} from '../../events';
import {DatesPeriodModel, SchoolYearTypeModel, TrackModel} from '../../types';
import {DateTools} from '../../utils';

interface InitArguments {
	districtID: number
	selectedTrack: TrackModel
	schoolYearTypes: SchoolYearTypeModel[]
}


export class TrackAddEditService extends BaseService {
	public periods = new BehaviorSubject<DatesPeriodModel[]>([]);
	public districtID: number;
	public currentUser = userStorage.get();
	public selectedTrack: TrackModel;
	public schoolYearTypes: SchoolYearTypeModel[];
	public selectedType: SchoolYearTypeModel;
	public form = new FormGroup({
		track: new FormControl<TrackModel[]>([]),
		trackInput: new FormControl<string>('', {validators: [Validators.required()]}),
		type: new FormControl<SchoolYearTypeModel[]>([]),
		periods: new FormControl<DatesPeriodModel[]>([]),
	});

	private periodsChanged = new BehaviorSubject<boolean>(false);

	public init({districtID, selectedTrack, schoolYearTypes}: InitArguments) {
		this.districtID = districtID;
		this.selectedTrack = selectedTrack;
		this.schoolYearTypes = schoolYearTypes;
		const selectedType = schoolYearTypes.find(x => x.id === selectedTrack.schoolYearTypeID);
		this.selectedType = selectedType;
		this.form.controls.track.value = [selectedTrack];
		this.form.controls.type.value = [selectedType];
		this.initPeriods(selectedTrack);
		this.form.controls.trackInput.status = ElementStatus.disabled;
		this.form.controls.track.onChanged.subscribe((v) => {
			if(v.currState.value[0].trackID !== v.prevState.value[0].trackID) {
				const value = v.currState.value[0];
				if (v.currState.value[0].trackID) {
					this.selectedTrack = value;
					this.initPeriods(value);
				} else {
					this.initPeriods(null);
					this.form.controls.type.value = [schoolYearTypes[0]];
				}
			}
		});
		this.form.controls.type.onChanged.subscribe((v) => {
			if(v.currState.value[0].id !== v.prevState.value[0].id){
				this.initPeriods(null);
			}

		});
	}

	public initPeriods = (selectedTrack: TrackModel | null) => {
		let periods;
		const addNumber = (item, index) => ({...item, number: index + 1});
		if (selectedTrack) {
			const selectedType = this.schoolYearTypes.find((item) => item.id === selectedTrack.schoolYearTypeID);
			periods = (!selectedTrack.trackDates.length ? selectedType.defaultTrackDates : selectedTrack.trackDates)
				.map(addNumber);
			this.form.controls.type.value = [selectedType];
		} else {
			const selectedType = this.form.controls.type.value[0];

			if (this.form.controls.track.value[0].schoolYearTypeID !== selectedType.id) {
				periods = selectedType.defaultTrackDates.map(addNumber);
			} else {
				periods = this.form.controls.track.value[0].trackDates.map(addNumber);
			}
		}
		this.periods.next(periods);
		this.form.controls.periods.value = periods;
	};

	public changeFromDate = (periodNumber: number, date: Moment) => {
		const updPeriod = this.form.controls.periods.value.find(p => p.number === periodNumber);
		const dateChanged = updPeriod.dateFrom !== date.format('L');

		const updPeriods = this.form.controls.periods.value.map(p => p.number === periodNumber ?
			{...p, dateFrom: date.format('L')} : p,
		);

		const correctedPeriods = this.correctPeriods(periodNumber, updPeriods, true);
		this.periods.next(correctedPeriods);
		this.form.controls.periods.value = correctedPeriods;
		this.periodsChanged.next(dateChanged);
	};

	public changeToDate = (periodNumber: number, date: Moment) => {
		const updPeriod = this.form.controls.periods.value.find(p => p.number === periodNumber);
		const dateChanged = updPeriod.dateTo !== date.format('L');

		const updPeriods = this.form.controls.periods.value.map(p => p.number === periodNumber ?
			{...p, dateTo: date.format('L')} : p,
		);

		const correctedPeriods = this.correctPeriods(periodNumber, [...updPeriods], false);
		this.form.controls.periods.value = correctedPeriods;
		this.periods.next(correctedPeriods);
		this.periodsChanged.next(dateChanged);
	};

	public update = () => {
		const model = new TrackModel(
			this.districtID,
			this.currentUser.globalSchoolYearID,
			this.form.controls.trackInput.value || this.form.controls.track.value[0].name,
			this.form.controls.type.value[0].id,
			this.form.controls.type.value[0].name,
			this.form.controls.track.value[0].trackID,
			this.form.controls.periods.value.map(p => ({
				trackID: this.form.controls.track.value[0].trackID,
				trackDateID: p.trackDateID,
				dateFrom: DateTools.toServerString(p.dateFrom),
				dateTo: DateTools.toServerString(p.dateTo),
			})),
		);
		return this.httpClient.ESGIApi.post('modules/tracks', 'update', model)
			.pipe(tap(()=>{
				dispatchAppEvent(UserInfoTrackChangedEvent, model);
			}));
	};

	public create = () => {
		const model = new TrackModel(
			this.districtID,
			this.currentUser.globalSchoolYearID,
			this.form.controls.trackInput.value,
			this.form.controls.type.value[0].id,
			this.form.controls.type.value[0].name,
			0,
			this.form.controls.periods.value.map(p => ({
				trackID: this.form.controls.track.value[0].trackID,
				trackDateID: p.trackDateID,
				dateFrom: DateTools.toServerString(p.dateFrom),
				dateTo: DateTools.toServerString(p.dateTo),
			})),
		);
		return this.httpClient.ESGIApi.post<TrackModel>('modules/tracks', 'create', model)
			.pipe(tap((response) => {
				dispatchAppEvent(UserInfoTrackCreatedEvent, {...response, schoolYearType: model.schoolYearType});
			}));
	};

	public isPeriodsValid = () => {
		let hasError = false;
		const periods = this.form.controls.periods.value;
		for (let i = 0; i < periods.length; i++) {
			const period = periods[i];
			hasError = !!this.validatePeriod(period, true);
			if (hasError) {
				break;
			}
			hasError = !!this.validatePeriod(period, false);
			if (hasError) {
				break;
			}
		}
		return !hasError;
	};

	public dateToValidator = (value: Moment, index: number, period: DatesPeriodModel): IFormControlValidatorResult => {
		const validationResult = this.dateValidator(value);
		if (!validationResult.valid) {
			return validationResult;
		}

		const message = this.validatePeriod(period, false);

		return {
			valid: message === '',
			message,
		};
	};

	public dateFromValidator = (value: Moment, index: number, period: DatesPeriodModel): IFormControlValidatorResult => {
		const validationResult = this.dateValidator(value);
		if (!validationResult.valid) {
			return validationResult;
		}

		const message = this.validatePeriod(period, true);

		return {
			valid: message === '',
			message,
		};
	};

	private dateValidator = (value: Moment): IFormControlValidatorResult => {
		let valid = true;
		let message = '';
		if (!value.valueOf()) {
			valid = false;
			message = 'Please enter a date';
		}

		return {
			valid,
			message,
		};
	};

	private validatePeriod(period: DatesPeriodModel, isFrom: boolean) {
		let message = '';

		if (!this.periodsChanged.value) {
			return message;
		}

		const schoolYearType = this.schoolYearTypes.find(x => x.id === this.form.controls.type.value[0].id);
		const schoolYearMaxDate = DateTools.toDate(schoolYearType.maxDate);
		const schoolYearMinDate = DateTools.toDate(schoolYearType.minDate);

		const prevPeriod = this.form.controls.periods.value.find(x => x.number === period.number - 1);
		const nextPeriod = this.form.controls.periods.value.find(x => x.number === period.number + 1);

		const currentDate = isFrom ? DateTools.toDate(period.dateFrom) : DateTools.toDate(period.dateTo);
		const prevDate = isFrom ? DateTools.toDate(prevPeriod ? prevPeriod.dateTo : null) : DateTools.toDate(period.dateFrom);
		const nextDate = isFrom ? DateTools.toDate(period.dateTo) : DateTools.toDate(nextPeriod ? nextPeriod.dateFrom : null);

		if (schoolYearMinDate > currentDate) {
			message += `This date must be ${DateTools.toUIString(schoolYearMinDate, 'MM-DD-YYYY')} or greater. \n`;
		}
		if (schoolYearMaxDate < currentDate) {
			message += `This date must be prior to ${DateTools.toUIString(schoolYearMaxDate, 'MM-DD-YYYY')}. \n`;
		}
		if (prevDate >= currentDate) {
			message += 'This date must be greater than previous date.\n';
		}
		if (nextDate != null && currentDate >= nextDate) {
			message += 'This date must be less than next date.\n';
		}
		if (isFrom && prevDate) {
			const prevDatePlusDay = DateTools.clone(prevDate);
			DateTools.addDays(prevDatePlusDay, 1);
			if (prevDatePlusDay < currentDate) {
				message += 'This date contains gap';
			}
		}

		return message;
	}

	private correctPeriods = (periodNumber: number, periods: DatesPeriodModel[], isFrom: boolean) => {
		const period = periods.find(x => x.number === periodNumber);
		if (isFrom) {
			this.correctTo(periods, period, false);
		} else {
			this.correctFrom(periods, period, true);
		}

		return periods;
	};

	private correctTo = (periods: DatesPeriodModel[], period: DatesPeriodModel, changedFieldTo: boolean) => {
		if (!period) {
			return;
		}
		let dateFrom = DateTools.toDate(period.dateFrom);
		const dateTo = DateTools.toDate(period.dateTo);

		if (dateFrom >= dateTo) {
			const copySource = changedFieldTo ? dateTo : dateFrom;
			const dateToCopy = DateTools.clone(copySource);
			DateTools.addDays(dateToCopy, 1);
			period.dateTo = DateTools.toServerString(dateToCopy);

			this.correctFrom(periods, period, changedFieldTo);
		}

		const prevPeriod = periods.find(x => x.number === period.number - 1);

		if (prevPeriod) {
			dateFrom = DateTools.toDate(period.dateFrom);
			const dateTo = DateTools.toDate(prevPeriod.dateTo);

			if (dateTo >= dateFrom) {
				const dateToCopy = DateTools.clone(dateFrom);
				DateTools.addDays(dateToCopy, -1);
				prevPeriod.dateTo = DateTools.toServerString(dateToCopy);

				this.correctFrom(periods, prevPeriod, true);
			}

			const dateFromPlusDay = DateTools.clone(dateFrom);
			DateTools.addDays(dateFromPlusDay, 1);
			if (dateFromPlusDay >= dateTo) {
				const dateToCopy = DateTools.clone(dateFrom);
				DateTools.addDays(dateToCopy, -1);
				prevPeriod.dateTo = DateTools.toServerString(dateToCopy);
			}
		}
	};

	private correctFrom = (periods: DatesPeriodModel[], period: DatesPeriodModel, changedFieldTo: boolean) => {
		if (!period) {
			return;
		}
		const dateTo = DateTools.toDate(period.dateTo);
		const dateFrom = DateTools.toDate(period.dateFrom);

		if (dateFrom >= dateTo) {
			const dateFromSource = changedFieldTo ? dateTo : dateFrom;
			const dateFromCopy = DateTools.clone(dateFromSource);
			DateTools.addDays(dateFromCopy, -1);
			period.dateFrom = DateTools.toServerString(dateFromCopy);
			this.correctTo(periods, period, changedFieldTo);
		}

		const nextPeriod = periods.find(x => x.number === period.number + 1);

		if (nextPeriod) {
			const dateTo = DateTools.toDate(period.dateTo);
			const dateFrom = DateTools.toDate(nextPeriod.dateFrom);

			if (dateTo >= dateFrom) {
				const dateFromCopy = DateTools.clone(dateTo);
				DateTools.addDays(dateFromCopy, 1);
				nextPeriod.dateFrom = DateTools.toServerString(dateFromCopy);
				this.correctTo(periods, nextPeriod, false);
			}
		}
	};
}
