import {Wrapper, ButtonIcon, ButtonText, SortTable} from './index.styled';
import {CheckAll} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {TestListProps} from '../../../../types';

interface Props extends Pick<TestListProps, 'sort' | 'onSort' | 'onSelectAll'> {}

export function Action({sort, onSort, onSelectAll}: Props) {
	return (
		<Wrapper>
			<ButtonIcon dataCy='select-all-tests-checkbox' onClick={onSelectAll}>
				<CheckAll/>
			</ButtonIcon>
			<ButtonText data-cy='label-sort-tests-by-name' onClick={onSort}>
				<Text size='small'>Test Name</Text>
				<SortTable field={sort.field} direction={sort.direction}/>
			</ButtonText>
		</Wrapper>
	);
}
