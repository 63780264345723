import {Option} from '@esgi/main/kits/reports';
import {MarkingPeriod} from './types';

export const items: Option[] = [
	{
		id: MarkingPeriod.Current,
		label: 'Current',
	},
	{
		id: MarkingPeriod.All,
		label: 'All',
	},
];
