import {useMemo, useEffect} from 'react';
import {useFlashcardsService} from '../../hooks';
import {useBehaviorSubject} from '@esgi/ui';
import {EmptyList} from './components/empty-list';
import {TestList} from './components/test-list';
import {ChangeFunction} from '@esgi/main/kits/activities';
import {Session, IncludeType, Test} from '../../types';
import {useTestsControl} from './hooks';
import {SkeletonList} from './index.styled';
import {useFlashcardsHistoryState} from '@esgi/main/features/teacher/utils';

interface Props {
	disabled?: boolean;
	extraTests?: Test[];
	onChange: ChangeFunction<Session['id'][]>;
	onAllTestClick: VoidFunction;
}

export function TestsControl({extraTests = [], onChange, onAllTestClick}: Props) {
	const service = useFlashcardsService();
	const mainTests = useBehaviorSubject(service.tests$);
	const loadingTests = useBehaviorSubject(service.loading$);
	const selectAll = useBehaviorSubject(service.selectAll$);
	const include = useBehaviorSubject(service.include$);
	const {testId} = useFlashcardsHistoryState();

	const tests = useMemo(() => {
		if (include === IncludeType.FullSet) {
			return [...mainTests, ...extraTests];
		}
		return mainTests;
	}, [mainTests, extraTests, include]);
	const {items, selected, setSelected, toggleSessions, sort, onSort, onToggle, onSelectAll, onSelectTestAll} = useTestsControl(tests, onChange);

	useEffect(() => {
		const sessions = tests.flatMap(({sessions}) => sessions).map(({id}) => id);
		setSelected(sessions);
	}, [selectAll]);

	useEffect(()=>{
		if(testId && tests) {
			const sessions = tests.filter(el=>el.id === Number(testId)).flatMap(({sessions}) => sessions).map(({id}) => id);
			setSelected(sessions);
		}
	}, [tests, testId]);

	if (loadingTests) {
		return <SkeletonList/>;
	}

	if (!tests.length) {
		return <EmptyList onAllTestClick={onAllTestClick}/>;
	}

	return (
		<TestList
			disabledItems={include === IncludeType.FullSet}
			state={items}
			selectedSessions={selected}
			toggleSessions={toggleSessions}
			sort={sort}
			onSort={onSort}
			onToggle={onToggle}
			onSelectAll={onSelectAll}
			onSelectTestAll={onSelectTestAll}
		/>
	);
}
