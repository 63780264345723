import {Container, LegendBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {ThemeColorKeys, getThemeColor} from '@esgi/ui';

export type LegendWithTranscriptItem = {
	transcriptText: string;
	transcriptTextColor: NonNullable<VariantProps<typeof Text>['color']>;
	backgroundColor: ThemeColorKeys;
	borderType: NonNullable<VariantProps<typeof LegendBox>['borderType']>;
	borderColor: ThemeColorKeys;
};

export function LegendWithTranscript({
	transcriptText,
	transcriptTextColor,
	backgroundColor,
	borderType,
	borderColor,
}: LegendWithTranscriptItem) {
	return (
		<Container dataCy={`legend-item-${transcriptText.toLowerCase()}`}>
			<LegendBox
				borderType={borderType}
				css={{
					backgroundColor: getThemeColor(backgroundColor),
					borderColor: getThemeColor(borderColor),
				}}
				dataCy='legend-box'
			/>
			<Text data-cy='legend-transcript' size='xSmall' font='mono' color={transcriptTextColor}>
				{transcriptText}
			</Text>
		</Container>
	);
}
