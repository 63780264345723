import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {ContextMenu} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Alert} from '@esgi/ui/alert';

export const AlertBody = styled(Alert.Body, {
	overflow: 'unset',
});

export const ReportModalBodyStyled = styled(GridBox, {
	padding: 0,
	width: '95vw',
	minWidth: '500px',
	overflow: 'hidden',
});

export const TotalReportSettings = styled(GridBox, {
	padding: '20px',
	marginBottom: '20px',
	backgroundColor: '$neutral96',
});

export const TotalReportWrapper = styled(FlexBox, {
	width: '100%',
	flexDirection: 'column',
	boxSizing: 'border-box',
	position: 'relative',
	overflow: 'hidden',
	backgroundColor: '$surface',
});

export const TotalReportContainer = styled(Box, {
	textAlign: 'center',
	maxHeight: '60vh',
	overflow: 'hidden',
	minHeight: '100px',
});

export const SettingsText = styled(Text, {
	marginRight: '10px',
});

export const ContextMenuItem = styled(ContextMenu.Item, {
	cursor: 'pointer',
	'&:hover': {
		background: '$secondaryBackground',
	},
});

export const SelectsContainer = styled(GridBox, {
	gridTemplateColumns: '100px 300px 300px 300px minmax(0, 1fr)',
	justifyItems: 'start',
	gap: '$1',
});

export const OverlayBox = styled(Box, {
	display: 'flex',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: -1,
});
