import styles from '../../../styles.module.less';
import {Checkbox} from '@esgillc/ui-kit/control';
import {Buttons} from '@esgillc/ui-kit/button';
import {TestItem} from '../../../types/types';
import {CreateAssignmentService} from '../../../services/create-assignment-service';
import {useRef} from 'react';
import {HoverTooltip} from '@esgillc/ui-kit/tooltip';

interface Props<Service extends CreateAssignmentService> {
	item: TestItem;
	service: Service;
	selected: boolean;
	disabled: boolean;
}

export function TestRow<Service extends CreateAssignmentService>(props: Props<Service>) {
	const {item, service, selected, disabled} = props;
	const showTooltip = !selected && disabled;
	const ref = useRef<HTMLDivElement>(null);
	return (
		<div key={item.id}>
			<div ref={ref} className={styles.cell}>
				<Checkbox checked={selected} onClick={() => service.onTestToggle(item.id)}
				          disabled={showTooltip}/>
				<Buttons.Link className={styles.name} onClick={() => service.showTestDetails(item.id)}>{item.name}</Buttons.Link>
			</div>
			{showTooltip && <HoverTooltip element={null} elementRef={ref} placement='right' className={styles.tooltip}>
				{'You have selected the maximum number of tests (3) per assignment'}
			</HoverTooltip>}
		</div>
	);
}
