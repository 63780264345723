import React from 'react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {userStorage} from '@esgi/core/authentication';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {EventBusDispatcher} from '@esgillc/events';
import {RemoveClickedEvent, SaveClickedEvent} from './events';
import {TeacherForm} from './teacher-form';

class Props {
	teacherID: number;
	schoolID: number;
	onClose: () => void;
}

class State {
	loading: boolean = false;
	expirationDate: string = '';
}

export default class TeacherFormModal extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();

	constructor(props) {
		super(props);
		this.state = new State();
	}
	private get editMode(): boolean {
		return !!this.props.teacherID;
	}

	private get title(): string {
		return this.props.teacherID ? 'Edit teacher' : 'Add teacher';
	}

	render() {
		return <Modal animate={true} className='teacher-form white-header pull-right responsive'
		              onCatchError={() => this.props.onClose()}>
			<ModalHeader
				title={this.title}
				close={{click: () => this.props.onClose()}}>
				{this.editMode && this.currentUser.canAddAndDeleteTeachers &&
					<div className='remove-link' onClick={() => EventBusDispatcher.dispatch(RemoveClickedEvent)}>Remove teacher</div>
				}
				{this.editMode &&
					<div className='expiration-info'>Expiration Date {this.state.expirationDate}</div>
				}
			</ModalHeader>
			<ModalBody>
				<TeacherForm schoolID={this.props.schoolID}
				             teacherID={this.props.teacherID}
				             onSaved={() => this.props.onClose()}
				             onLoaded={(expirationDate) => {
					             let expDate = this.state.expirationDate;
					             if (expirationDate) {
						             expDate = expirationDate;
					             }
					             this.setState({loading: false, expirationDate: expDate});
				             }
				             }
				             onLoading={() => this.setState({loading: false})}/>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => this.props.onClose()}
					title='Cancel'
				/>
				<Primary
					onClick={() => EventBusDispatcher.dispatch(SaveClickedEvent)}
					disabled={this.state.loading}
					title='Save'
				/>
			</ModalFooter>
		</Modal>;
	}
}
