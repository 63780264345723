import {RefObject, useCallback} from 'react';
import {SnackbarOptions, SnackbarManager} from '../types';

export function useSnackbarOpen(
	snackbarRef: RefObject<SnackbarManager>,
	content: string | React.JSX.Element,
	options?: Partial<SnackbarOptions>,
) {
	return useCallback(
		() => snackbarRef.current?.showSnackbar(content, options),
		[snackbarRef, content, options],
	);
}
