import {createContext, Dispatch, useContext, useMemo, useState} from 'react';

export type PossiblePermissionsType = {
	[key: string]: boolean,
}

export type ModeContextValue<T extends string, PermissionsType extends PossiblePermissionsType> = {
	mode: T,
	setMode: Dispatch<T>,
	permissions: PermissionsType,
	setPermissions: (value: Partial<PermissionsType>) => void,
}

export const ModeContext = createContext<ModeContextValue<any, any> | null>(null);

export function useModeContext<T extends string, PermissionsType extends PossiblePermissionsType>() {
	const context = useContext(ModeContext);

	if (!context) {
		throw new Error('ModeContext is null');
	}

	return context as ModeContextValue<T, PermissionsType>;
}

export function useModeFeature<Mode extends string, PermissionsType extends PossiblePermissionsType>(initial: Mode) {
	const [mode, setMode] = useState<Mode>(initial);
	const [permissions, setPermissions] = useState<PermissionsType>({} as PermissionsType);

	const context = useMemo(() => ({
		mode,
		setMode,
		permissions,
		setPermissions,
	} as ModeContextValue<Mode, PermissionsType>), [mode, permissions]);

	return {
		mode,
		setMode,
		context,
	};
}