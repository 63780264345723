import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Assignment} from '../types';
import {routes} from '@esgi/main/libs/core';

export type State = {
	from: 'assignment-list' | 'assignment';
};

export type OpenEditAssignmentFn = (args: {assignmentId: Assignment['id']; state: State; replace?: boolean}) => void;

export function useOpenEditAssignment() {
	const navigate = useNavigate();

	return useCallback<OpenEditAssignmentFn>(
		({assignmentId, state, replace}) => {
			navigate(routes.teacher.assignments.assignmentEdit.replace(':assignmentId', String(assignmentId)), {
				state,
				replace: Boolean(replace),
			});
		},
		[navigate],
	);
}
