import {CubeCoordinatesModel} from '../types';

export function getCubeCoordinates({
	diagonalX1,
	diagonalY1,
	diagonalX2,
	diagonalY2,
}: {
	diagonalX1: number;
	diagonalX2: number;
	diagonalY1: number;
	diagonalY2: number;
}) {
	const middlePoints = {
		x: (diagonalX1 + diagonalX2) / 2,
		y: (diagonalY1 + diagonalY2) / 2,
	};

	const vectorMiddleToEnd = {
		x: diagonalX2 - middlePoints.x,
		y: diagonalY2 - middlePoints.y,
	};

	const paralelVectorTop = {
		x: vectorMiddleToEnd.y,
		y: -1 * vectorMiddleToEnd.x,
	};

	const paralelVectorBottom = {
		x: -1 * vectorMiddleToEnd.y,
		y: vectorMiddleToEnd.x,
	};

	const cubeCoordinates: CubeCoordinatesModel = {
		x1: diagonalX1,
		y1: diagonalY1,

		x2: middlePoints.x + paralelVectorTop.x,
		y2: middlePoints.y + paralelVectorTop.y,

		x3: diagonalX2,
		y3: diagonalY2,

		x4: middlePoints.x + paralelVectorBottom.x,
		y4: middlePoints.y + paralelVectorBottom.y,
	};

	return cubeCoordinates;
}
