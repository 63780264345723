import {HierarchyInstance, HierarchyMode} from 'modules/hierarchy/core/models';
import {SubjectModel} from './models';
import {UserType} from '@esgi/core/authentication';

export function notHiddenFilter(subjects: SubjectModel[]) {
	return subjects.filter(s => !s.hidden);
}

export function districtTabsFilter(subjects: SubjectModel[], userType: UserType, gradeLevelIds: number[], hierarchy: HierarchyInstance) {
	const result = notHiddenFilter(subjects);

	if ([UserType.C, UserType.D].indexOf(userType) !== -1 && hierarchy && (hierarchy.mode === HierarchyMode.Specialist || hierarchy.mode === HierarchyMode.PreAssess)) {
		return result;
	}

	if ([UserType.ISS, UserType.ISD].indexOf(userType) !== -1) {
		return result;
	}

	if ([UserType.D].indexOf(userType) !== -1
		&& hierarchy
		&& hierarchy.mode === HierarchyMode.Classic
		&& hierarchy.classic.schoolID === 0
	) {
		// don't filter subjects only on district level. for school level we always filter deployed subjects
		return result;
	}

	if (gradeLevelIds.length !== 0) {
		return result.filter(s => s.gradeLevels.filter(g => gradeLevelIds.indexOf(g) > -1).length > 0);
	} else if (UserType.T === userType) {
		return []; //If Teacher have no students, we shouldn't show district tabs.
	}

	return result;
}

export function schoolTabsFilter(subjects: SubjectModel[], userType: UserType, gradeLevelIds: number[], hierarchy: HierarchyInstance) {
	const result = notHiddenFilter(subjects);

	if ([UserType.C, UserType.D].indexOf(userType) !== -1 && hierarchy && (hierarchy.mode === HierarchyMode.Specialist || hierarchy.mode === HierarchyMode.PreAssess)) {
		return result;
	}

	if ([UserType.ISS, UserType.ISD].indexOf(userType) !== -1) {
		return result;
	}

	if (userType == UserType.C || userType == UserType.D
		&& hierarchy
		&& hierarchy.mode === HierarchyMode.Classic
		&& hierarchy.classic.teacherID === 0) {
		// don't filter subjects only on school level. for teacher level we always filter deployed subjects
		return result;
	}

	if (gradeLevelIds.length !== 0) {
		return result.filter(s => s.gradeLevels.filter(g => gradeLevelIds.indexOf(g) > -1).length > 0);
	} else if (UserType.T === userType) {
		return []; //If Teacher have no students, we shouldn't show school tabs.
	}

	return result;
}
