import {useMemo} from 'react';
import {
	TestSectionContentType,
	TestSectionDefault,
	TestSectionGroupStudents,
	TestSectionSingleStudent,
} from '../../../types';
import {
	GroupStudentsRerrangeModeContent,
	GroupStudentsViewModeContent,
	SingleStudentRearrangeModeContent,
	SingleStudentViewModeContent,
} from '../../mode-content-variant';
import {TestGroupStudentsModel, TestModel, TestSingleStudentModel} from '../../../../../types';

export function useModesProps<TestModelType extends TestModel>({
	props,
	testItems,
}: {
	props: TestSectionSingleStudent | TestSectionGroupStudents | TestSectionDefault;
	testItems: TestModelType[];
}) {
	const modeViewContentProps = useMemo(() => {
		if (props.contentType === TestSectionContentType.SingleStudent) {
			const {student, showSelfAssessOption, onTestCardClicked, studentClassID, statisticEntity, setTestLauncherState} =
				props;

			const contentProps: SingleStudentViewModeContent = {
				contentType: TestSectionContentType.SingleStudent,
				allTests: testItems as unknown as TestSingleStudentModel[],
				student,
				onTestCardClicked,
				showSelfAssessOption,
				studentClassID,
				statisticEntity,
				setTestLauncherState,
			};

			return contentProps;
		}

		if (props.contentType === TestSectionContentType.GroupStudents) {
			const {onTestCardClicked, classID, statisticEntity} = props;

			const contentProps: GroupStudentsViewModeContent = {
				contentType: TestSectionContentType.GroupStudents,
				allTests: testItems as unknown as TestGroupStudentsModel[],
				onTestCardClicked,
				classID,
				statisticEntity,
			};

			return contentProps;
		}

		return null;
	}, [props, testItems]);

	const modeRearrangeContentProps = useMemo(() => {
		if (props.contentType === TestSectionContentType.SingleStudent) {
			const {updateTestsOrder, showSelfAssessOption} = props;

			const contentProps: SingleStudentRearrangeModeContent = {
				contentType: TestSectionContentType.SingleStudent,
				allTests: testItems as unknown as TestSingleStudentModel[],
				updateTestsOrder,
				showSelfAssessOption,
			};

			return contentProps;
		}

		if (props.contentType === TestSectionContentType.GroupStudents) {
			const {updateTestsOrder} = props;

			const contentProps: GroupStudentsRerrangeModeContent = {
				contentType: TestSectionContentType.GroupStudents,
				allTests: testItems as unknown as TestGroupStudentsModel[],
				updateTestsOrder,
			};

			return contentProps;
		}

		return null;
	}, [props, testItems]);

	return {
		modeViewContentProps,
		modeRearrangeContentProps,
	};
}
