import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import BaseReportButton from './base-button';
import {HierarchyInstance} from 'modules/hierarchy/core/models';

const WritingPractice = lazyComponent(() => import('../../../../../modules/reports/writing-practice/writing-practice'));

interface Props {
	hierarchy: HierarchyInstance;
}

class State {
	opened: boolean = false;
}

export default class WritingPracticeButton extends React.Component<Props, State> {
	public override readonly state = new State();
	private readonly icon = () => {
		return <svg className='icon' xmlns='http://www.w3.org/2000/svg'
					width='16'
					height='17'
					viewBox='30 0 230 230'
					fill='none'>
			<path fillRule='evenodd' clipRule='evenodd'
				  d='M224,120v88a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h88a8,8,0,0,1,0,16H48V208H208V120a8,8,0,0,1,16,0Zm5.7-50.3-96,96A8.1,8.1,0,0,1,128,168H96a8,8,0,0,1-8-8V128a8.1,8.1,0,0,1,2.3-5.7l96-96a8.1,8.1,0,0,1,11.4,0l32,32A8.1,8.1,0,0,1,229.7,69.7Zm-17-5.7L192,43.3,179.3,56,200,76.7Z'
				  fill='#0088CC'/>
		</svg>;
	};

	public render() {
		return <>
			<BaseReportButton title='Writing Practice'
							  icon={this.icon()}
							  hoverTitle='Quickly create writing practice templates for your students to practice with'
							  onClicked={() => this.setState({opened: true})}/>
			{this.renderWritingPractice()}
		</>;
	}


	private renderWritingPractice() {
		if (this.state.opened) {
			return <Suspense fallback={<div/>}>
				<WritingPractice onCloseClicked={() => this.setState({opened: false})} hierarchy={this.props.hierarchy}/>
			</Suspense>;
		}
	}
}
