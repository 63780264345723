import {Fallback} from '@radix-ui/react-avatar';
import {styled} from '@esgi/ui/theme';

export const StyledAvatarFallback = styled(Fallback, {
	display: 'flex',
	width: '100%',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	backgroundColor: '$vivid',
	gap: '4px',
	fontSize: '12px',
});
