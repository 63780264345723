import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';
import {DropdownItem} from './types';
import {ProfilesCommonController, ProfilesTeacherController, V2UserAccountController} from '@esgi/contracts/esgi';
import {ElementStatus, FormControl, FormGroup, Validators} from '@esgi/ui/form';
import levenshtein from 'js-levenshtein';
import {additionalDistrictsListItems, additionalSchoolsListItems} from '../constants';
import {getUser, userStorage} from '@esgi/core/authentication';
import {isNull, isUndefined} from '@esgi/ui';

export class DataService extends BaseService {
	public countries = new BehaviorSubject<DropdownItem[]>([]);
	public states = new BehaviorSubject<DropdownItem[]>([]);
	public districts = new BehaviorSubject<DropdownItem[]>([]);
	public schools = new BehaviorSubject<DropdownItem[]>([]);

	public filteredDistricts = new BehaviorSubject<DropdownItem[]>([]);

	public selectedSchool = new BehaviorSubject<DropdownItem>(null);
	public selectedDistrict = new BehaviorSubject<DropdownItem>(null);

	public form = new FormGroup({
		countryID: new FormControl(['']),
		stateID: new FormControl(['']),
		districtName: new FormControl('', {validators: [Validators.required()]}),
		schoolName: new FormControl('', {validators: [Validators.required()]}),
	});

	private currentUser = getUser();
	private controller = new ProfilesCommonController();
	private userAccountController = new V2UserAccountController();
	private profilesTeacherController = new ProfilesTeacherController();

	public init({
		defaultCountries,
		defaultStates,
		defaultSelectedCountry,
		defaultSelectedState,
		initialDistrictName,
		initialSchoolName,
		isLinkedToDistrict,
	}: {
		defaultCountries: DropdownItem[],
		defaultStates: DropdownItem[],
		defaultSelectedCountry: string,
		defaultSelectedState: string,
		initialDistrictName: string,
		initialSchoolName: string,
		isLinkedToDistrict: boolean,
	}) {
		this.countries.next(defaultCountries);
		this.states.next(defaultStates);

		this.form.controls.countryID.value = [defaultSelectedCountry];
		this.form.controls.stateID.value = [defaultSelectedState];
		this.form.controls.districtName.value = initialDistrictName;
		this.form.controls.schoolName.value = initialSchoolName;

		if (isLinkedToDistrict) {
			this.form.status = ElementStatus.disabled;
			return;
		}

		this.loadDistricts(Number(defaultSelectedState), initialDistrictName);
		this.selectedSchool.next({id: this.currentUser?.schoolID, value: initialSchoolName});

		this.form.status = ElementStatus.untouched;
	}

	public loadStates(countryID: number) {
		if (this.form.controls.countryID[0] === countryID) {
			return;
		}

		this.form.status = ElementStatus.disabled;

		// TODO Issue with contracts generating
		// @ts-ignore
		return this.userAccountController.statesByCountryId({countryID}).subscribe(({states}) => {
			this.states.next(states.map(x => ({id: x.id, value: x.name})));

			const firstState = states[0];

			this.form.controls.stateID.value = firstState ? [String(firstState.id)] : [];
			this.form.status = ElementStatus.untouched;
		});
	}

	public loadDistricts(stateID: number, initialDistrictName?: string) {
		this.form.status = ElementStatus.disabled;

		return this.controller.federalDistricts({stateID: stateID}).subscribe(({federalDistricts}) => {
			const loadedDistricts = federalDistricts.map<DropdownItem>(({id, name}) => ({id, value: name}));
			this.districts.next(loadedDistricts);

			if (initialDistrictName) {
				const selectedDistrict = loadedDistricts.find(({id, value}) => value === initialDistrictName);
				this.selectedDistrict.next(selectedDistrict);
			}

			this.form.status = ElementStatus.untouched;
		});
	}

	public onCountryChange(countryID: number) {
		this.resetDistrictAndSchool();

		this.districts.next([]);

		this.loadStates(countryID);
	}

	public onStateChange (stateId: number) {
		this.resetDistrictAndSchool();

		this.loadDistricts(stateId);
	}

	public onDistrictNameChange () {
		if (this.form.controls.districtName.value === this.selectedDistrict.value?.value) {
			return;
		}

		this.selectedDistrict.next(null);

		const keyword = this.form.controls.districtName.value;

		const filteredDistricts = this.districts.value.filter(({value}) => {
			const nameDiff = levenshtein(value.toUpperCase(), keyword.toUpperCase());

			return (value.length - nameDiff) >= keyword.length;
		});

		this.filteredDistricts.next(filteredDistricts.length ? filteredDistricts : additionalDistrictsListItems);
	}

	public onSchoolNameChange() {
		if (this.form.controls.schoolName.value === this.selectedSchool.value?.value) {
			return;
		}

		return this.userAccountController.federalSchools({
			stateID: Number(this.form.controls.stateID.value),
			query: this.form.controls.schoolName.value.replace(/[^a-zA-Z0-9\s]/g, ''),
			federalDistrictID: this.selectedDistrict.value?.id,
		}).subscribe(
			({value: {federalSchools}}) => {
				this.schools.next(federalSchools.length
					? federalSchools.map(({id, name}) => ({id, value: name}))
					: additionalSchoolsListItems
				);
			}
		);
	}

	public selectDistrict(districtID: DropdownItem['id'], districtName: string) {
		if (isNull(districtID)) {
			this.selectedDistrict.next({id: null, value: this.form.controls.districtName.value});
			return;
		}

		if (isUndefined(districtID)) {
			this.form.controls.districtName.status = ElementStatus.disabled;
			this.form.controls.districtName.value = this.form.controls.schoolName.value;
			this.selectedDistrict.next({id: undefined, value: this.form.controls.schoolName.value});
			return;
		}

		this.selectedDistrict.next({id: districtID, value: districtName});
		this.form.controls.districtName.value = districtName;
		this.form.controls.districtName.status = ElementStatus.valid;

		this.schools.next([]);
		this.selectedSchool.next(null);
		this.form.controls.schoolName.value = undefined;
		this.form.controls.schoolName.status = ElementStatus.invalid;
	}

	public selectSchool(schoolID: number, schoolName: string) {
		if (this.form.controls.districtName.status === ElementStatus.disabled) {
			this.form.controls.districtName.value = isUndefined(schoolID) ? this.form.controls.schoolName.value : schoolName;
		}

		if (isUndefined(schoolID)) {
			this.selectedSchool.next({id: undefined, value: this.form.controls.schoolName.value});
			return;
		}

		this.selectedSchool.next({id: schoolID, value: schoolName});
		this.form.controls.schoolName.value = schoolName;
	}

	public onSave() {
		this.form.status = ElementStatus.disabled;

		return this.profilesTeacherController.updateLocation({
			countryID: Number(this.form.controls.countryID.value),
			stateID: Number(this.form.controls.stateID.value),
			schoolID: this.selectedSchool.value?.id || undefined,
			schoolName: this.form.controls.schoolName.value,
			federalDistrictID: this.selectedDistrict.value?.id,
			districtName: this.form.controls.districtName.value,
			federalSchoolID: this.selectedSchool.value?.id || undefined,
			userID: this.currentUser.userID,
		}).subscribe({
			complete: () => {
				this.form.controls.countryID.status = ElementStatus.untouched;
				this.form.controls.stateID.status = ElementStatus.untouched;
				this.form.controls.districtName.status = ElementStatus.untouched;
				this.form.controls.schoolName.status = ElementStatus.untouched;
				userStorage.update({
					stateID: Number(this.form.controls.stateID.value),
					districtID: this.selectedDistrict.value?.id,
					schoolID: this.selectedSchool.value?.id,
				});
			},
		});
	}

	public resetDistrictAndSchool() {
		this.filteredDistricts.next([]);
		this.schools.next([]);
		this.form.controls.districtName.value = undefined;
		this.form.controls.schoolName.value = undefined;
		this.selectedDistrict.next(null);
		this.selectedSchool.next(null);
	}

	public override dispose() {
		this.controller.dispose();
		this.userAccountController.dispose();
		this.profilesTeacherController.dispose();
	}
}
