import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {ElementStatus} from '@esgi/ui/form';
import {createTestButtonLabelsForm} from './form';
import {
	TestButtonLabels,
} from '../../../../../types';
import {V2TeachersUserSettingsController} from '@esgi/contracts/esgi';
import {isEqual} from 'underscore';

export class TestButtonLabelsService extends BaseService {
	public initData = new BehaviorSubject<TestButtonLabels | null>(null);
	public form = createTestButtonLabelsForm();
	public isTestButtonLabels = new BehaviorSubject<boolean>(false);
	private controller = new V2TeachersUserSettingsController();

	public init = (data: TestButtonLabels) => {
		this.initData.next(data);

		this.form.value = {
			incorrect: data.incorrectTestLabel,
			correct: data.correctTestLabel,
			testButtonLabels: data.testButtonsLabelsEnabled,
		};

		this.isTestButtonLabels.next(this.form.value.testButtonLabels);

		if (!this.isTestButtonLabels.value) {
			this.form.controls.correct.status = ElementStatus.disabled;
			this.form.controls.incorrect.status = ElementStatus.disabled;
		}

		this.form.controls.testButtonLabels.onChanged.subscribe((v) => {
			this.isTestButtonLabels.next(v.currState.value);
		});
	};

	public compareStatesForEquality = () => {
		const {correctTestLabel, incorrectTestLabel, testButtonsLabelsEnabled} = this.initData.value;

		const initialState = {
			correctTestLabel,
			incorrectTestLabel,
			testButtonsLabelsEnabled,
		};

		const currentState = {
			correctTestLabel: this.form.controls.correct.value,
			incorrectTestLabel: this.form.controls.incorrect.value,
			testButtonsLabelsEnabled: this.form.controls.testButtonLabels.value,
		};

		return !isEqual(initialState, currentState);
	};

	public save = () => {
		return this.controller.tabsTestButtonLabelsSave({
			correct: this.isTestButtonLabels.value? this.form.controls.correct.value : undefined,
			incorrect: this.isTestButtonLabels.value? this.form.controls.incorrect.value: undefined,
			state: this.isTestButtonLabels.value,
		});
	};

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}
