import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const StudentNameStyled = styled(Box, {
	textAlign: 'left',
	paddingLeft: '25px',
	color: '#333333',
	fontSize: '22px',
	fontWeight: 700,
});

export const ContainerStyled = styled(Box, {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

export const RubricNameStyled = styled('h3', {
	fontSize: '20px',
	color: '#333333',
});

export const TableStyled = styled('table', {
	border: '1px solid #BDBDBD',
});

export const CellHeadStyled = styled('th', {
	fontWeight: 400,
	textAlign: 'center',
	width: '30px',
	height: '30px',
	borderRight: '1px solid #BDBDBD',
	backgroundColor: '#E0E0E0',
});

export const CellHeadDescriptionStyled = styled(CellHeadStyled, {
	width: '130px',
});

export const CellHeadBaselineStyled = styled(CellHeadStyled, {
	variants: {
		baseline: {
			true: {
				fontWeight: 700,
			},
		},
	},
});

export const CellStyled = styled('th', {
	fontWeight: 400,
	textAlign: 'center',
	width: '30px',
	height: '30px',
	borderRight: '1px solid #BDBDBD',
});

export const CellDescriptionStyled = styled(CellStyled, {
	width: '130px',
});

export const RowStyled = styled('tr', {
	':first-child': {
		borderRight: '2px solid #BDBDBD',
	},

	':last-child': {
		borderLeft: '2px solid #BDBDBD',
	},
});