import React from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {INotedAuthor} from '../../../../services/landing-service';
import {Author} from '../../../noted-author/author';

interface Props {
	author: INotedAuthor;
	typeText: string;
	numberOfQuestions: number;
	stateStandard: string;
	description: string;
}

export default class Body extends React.PureComponent<Props> {
	public render() {
		return <main>
			<div className='author-wrapper'>
				<Author author={this.props.author}/>
			</div>
			<div className='test-info'>
				{this.props.stateStandard &&
					<div>
						<b>Standard: </b>
						<OnHoverTooltip message={this.props.stateStandard}>
							<span className='state-standard-title'>{this.props.stateStandard}</span>
						</OnHoverTooltip>
					</div>
				}
				{!!this.props.numberOfQuestions && <div>
					<b>Questions: </b> <span>{this.props.numberOfQuestions}</span>
				</div>}
				<div>
					<b>Test Type: </b>
					<span>{this.props.typeText}</span>
				</div>
			</div>
			<div className='description' dangerouslySetInnerHTML={{__html: this.props.description}}/>
		</main>;
	}
}
