import {EmptyList} from './components/empty-list';
import {TestList} from './components/test-list';
import {SkeletonList} from './index.styled';
import {Test} from './types';
import {useTestsControl} from './hooks';
import {Skeletonable} from '@esgi/ui';

interface Props extends Skeletonable {
	tests: Test[] | null;
	disabled?: boolean;
	onChange: (value: Test['id'][]) => void;
	allSubjectsMode?: boolean;
	emptyTestsMessage: string;
}

export function TestsControl({skeleton, tests, onChange, allSubjectsMode, emptyTestsMessage = `There's no test data for the student`}: Props) {
	const {items, selected, toggleSelected, sort, onSort, onSelectAll} = useTestsControl(tests, onChange);

	if (skeleton) {
		return <SkeletonList/>;
	}

	if (!tests?.length) {
		return <EmptyList content={emptyTestsMessage}/>;
	}

	return (
		<TestList
			tests={items}
			selectedTests={selected}
			toggleTests={toggleSelected}
			sort={sort}
			onSort={onSort}
			onSelectAll={onSelectAll}
			allSubjectsMode={allSubjectsMode}
		/>
	);
}
