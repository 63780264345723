import {Dropdown, Option, Input} from '@esgillc/ui-kit/control';
import {FilterModel, IdNameModel} from '../../../../types';
import {FindMoreService} from '../../../../find-more-service';
import {SearchIcon} from './search-icon';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	service: FindMoreService;
}

export const Filters = ({service}: Props): JSX.Element => {
	const filter = useBehaviorSubject<FilterModel>(service.filter);
	const contentAreas = useBehaviorSubject<IdNameModel[]>(service.contentAreas);
	const contentArea = contentAreas.filter(({id}) => id === filter.contentAreaId);

	return (
		<form
			className={styles.filter}
			onSubmit={(event) => event.preventDefault()}
		>
			<div className={styles.filterInputWrapper}>
				<SearchIcon className={styles.filterInputIcon} />
				<Input
					className={styles.filterInput}
					placeholder='Enter test name or author'
					onChange={({target}) => service.keywordChanged(target.value)}
				/>
			</div>
			<div className={styles.filterDropdownWrapper}>
				<label className={styles.filterDropdownLabel}>Filter By:</label>
				<Dropdown
					value={contentArea}
					placeholder='Content Area'
					autoWidth
					optionName='name'
					setValue={(id) => service.contentAreaChanged(id[0].id)}
				>
					{contentAreas.map((area) => <Option key={area.id} value={area}>{area.name}</Option>)}
				</Dropdown>
			</div>
		</form>
	);
};
