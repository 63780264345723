import {useCallback, useMemo, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Drawer, useDrawerClose, useDrawerRef, useRootPageContext} from '@esgi/main/kits/common';

import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {AddWidget} from '@esgi/ui/icons';
import {Portal, Tooltip} from '@esgi/ui';

import {Body, InfoIcon, AverageIconBox} from './styled';
import {WidgetType, WidgetsInfo} from '../types';
import {AveragePerformance} from '../widgets/average-performance';
import {QuestionsAnswered} from '../widgets/questions-answered';
import {TestsTaken} from '../widgets/tests-taken';
import {TimePerQuestion} from '../widgets/time-per-question';
import {UntestedStudents} from '../widgets/untested-students';
import {dispatchAppEvent} from '@esgillc/events';
import {UpdateSelectedWidgets} from '../events';
import {ToggleGroup} from '@esgi/ui/controls';
import {Card} from './card';
import {tooltipTexts} from './constants';

type Props = {
	initialWidgets: WidgetsInfo,
	onClose: VoidFunction,
	studentIDsInClass: number[],
	singleStudentID: number,
	testIDs: number[],
};

enum TabID {
	Class = 'class',
	Student = 'student',
}

export function SettingsDrawer(props: Props) {
	const {testIDs, singleStudentID, studentIDsInClass} = props;
	const {rootPageContentContainerRef} = useRootPageContext();
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, props.onClose);

	const [activeTab, setActiveTab] = useState<TabID>(singleStudentID ? TabID.Student : TabID.Class);

	const [classWidgets, setClassWidgets] = useState(props.initialWidgets?.classGroupLevel || []);
	const [studentWidgets, setStudentWidgets] = useState(props.initialWidgets?.studentLevel || []);

	const onApplyClicked = useCallback(() => {
		dispatchAppEvent(UpdateSelectedWidgets, new UpdateSelectedWidgets(studentWidgets, classWidgets));
		close();
	}, [close, studentWidgets, classWidgets]);

	const toggleWidget = useCallback((widget: WidgetType) => {
		const target = activeTab === TabID.Class ? setClassWidgets : setStudentWidgets;
		target((currentWidgets => {
			if (currentWidgets.includes(widget)) {
				return currentWidgets.filter(w => w !== widget);
			} else {
				return [...currentWidgets, widget];
			}
		}));
	}, [activeTab]);

	const widgets = useMemo(() => activeTab === TabID.Class ? classWidgets : studentWidgets, [activeTab, classWidgets, studentWidgets]);

	const tabContent = () => {
		if (activeTab === TabID.Class) {
			return <>
				<Card enabled={widgets.includes(WidgetType.AveragePerformance)}
				      onChange={() => toggleWidget(WidgetType.AveragePerformance)}>
					<AveragePerformance key={WidgetType.AveragePerformance} transparent studentIDs={studentIDsInClass}
					testIDs={testIDs} studentID={0}/>
					<Tooltip>
						<Tooltip.Trigger>
							<AverageIconBox>
								<InfoIcon />
							</AverageIconBox>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small' color='neutral24'>{tooltipTexts.averageClassText}</Text>
						</Tooltip.Content>
					</Tooltip>
				</Card>
				<Card enabled={widgets.includes(WidgetType.QuestionsAnswered)}
				      onChange={() => toggleWidget(WidgetType.QuestionsAnswered)}>
					<QuestionsAnswered key={WidgetType.QuestionsAnswered} transparent studentIDs={studentIDsInClass}
					    testIDs={testIDs} studentID={0}/>
						<Tooltip>
						<Tooltip.Trigger>
							<AverageIconBox>
								<InfoIcon />
							</AverageIconBox>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary' side='top'>
							<Text size='small' color='neutral24'>{tooltipTexts.questionsClassText}</Text>
						</Tooltip.Content>
					</Tooltip>
				</Card>
				<Card enabled={widgets.includes(WidgetType.TimePerQuestion)}
				      onChange={() => toggleWidget(WidgetType.TimePerQuestion)}>
					<TimePerQuestion key={WidgetType.TimePerQuestion} transparent testIDs={testIDs}
					    studentIDs={studentIDsInClass} studentID={0}/>
					<Tooltip>
						<Tooltip.Trigger>
							<AverageIconBox>
								<InfoIcon />
							</AverageIconBox>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary' side='top'>
							<Text size='small' color='neutral24'>{tooltipTexts.timeClassText}</Text>
						</Tooltip.Content>
					</Tooltip>
				</Card>
				<Card enabled={widgets.includes(WidgetType.UntestedStudents)}
				      onChange={() => toggleWidget(WidgetType.UntestedStudents)}>
					<UntestedStudents key={WidgetType.UntestedStudents} testIDs={testIDs}
					    studentIDs={studentIDsInClass}/>
					<Tooltip>
						<Tooltip.Trigger>
							<AverageIconBox>
								<InfoIcon />
							</AverageIconBox>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small' color='neutral24'>{tooltipTexts.untestedClassText}</Text>
						</Tooltip.Content>
					</Tooltip>
				</Card>
			</>;
		} else {
			const studentID = singleStudentID || studentIDsInClass[0];
			return <>
				<Card enabled={widgets.includes(WidgetType.AveragePerformance)}
				      onChange={() => toggleWidget(WidgetType.AveragePerformance)}>
					<AveragePerformance key={WidgetType.AveragePerformance} transparent studentID={studentID}
					    testIDs={testIDs} studentIDs={[]}/>
						<Tooltip>
							<Tooltip.Trigger>
								<AverageIconBox>
									<InfoIcon />
								</AverageIconBox>
							</Tooltip.Trigger>
							<Tooltip.Content variant='secondary'>
								<Text size='small' color='neutral24'>{tooltipTexts.averageStudentText}</Text>
							</Tooltip.Content>
						</Tooltip>
				</Card>
				<Card enabled={widgets.includes(WidgetType.QuestionsAnswered)}
				      onChange={() => toggleWidget(WidgetType.QuestionsAnswered)}>
					<QuestionsAnswered key={WidgetType.QuestionsAnswered} transparent studentID={studentID}
					    testIDs={testIDs} studentIDs={[]}/>
					<Tooltip>
							<Tooltip.Trigger>
								<AverageIconBox>
									<InfoIcon />
								</AverageIconBox>
							</Tooltip.Trigger>
							<Tooltip.Content variant='secondary'>
								<Text size='small' color='neutral24'>{tooltipTexts.questionsStudentText}</Text>
							</Tooltip.Content>
					</Tooltip>
				</Card>
				<Card enabled={widgets.includes(WidgetType.TimePerQuestion)}
				      onChange={() => toggleWidget(WidgetType.TimePerQuestion)}>
					<TimePerQuestion key={WidgetType.TimePerQuestion} transparent testIDs={testIDs}
					    studentID={studentID} studentIDs={[]}/>
					<Tooltip>
						<Tooltip.Trigger>
							<AverageIconBox>
								<InfoIcon />
							</AverageIconBox>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary' side='top'>
							<Text size='small' color='neutral24'>{tooltipTexts.timeStudentText}</Text>
						</Tooltip.Content>
					</Tooltip>
				</Card>
				<Card enabled={widgets.includes(WidgetType.TestsTaken)}
				      onChange={() => toggleWidget(WidgetType.TestsTaken)}>
					<TestsTaken key={WidgetType.TestsTaken} transparent studentIDs={studentIDsInClass} testIDs={testIDs}
					    studentID={studentID}/>
					<Tooltip>
						<Tooltip.Trigger>
							<AverageIconBox>
								<InfoIcon />
							</AverageIconBox>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small' color='neutral24'>{tooltipTexts.testsTakenText}</Text>
						</Tooltip.Content>
					</Tooltip>
				</Card>
			</>;
		}
	};

	return (
		<Portal container={rootPageContentContainerRef.current}>
			<Drawer drawerRef={drawerRef} onClickOutside={close}>
				<Drawer.Header
					Icon={AddWidget}
					sectionName='Home Screen Widgets'
					withActionButton
					actionButtonText='Apply'
					closeDrawer={close}
					onActionButtonClick={onApplyClicked}
				/>
				<Drawer.Body>
					<Body>
						<FlexBox direction='column' css={{height: '100%', overflow: 'hidden'}}>
							<Text size='medium' color='neutral40' css={{marginBottom: 20}} data-cy='widgets-info'>
								Enable or disable widgets to customize your home screen for both Classes/Groups and Single Students by
								using the tabs below.
							</Text>
							<ToggleGroup.Root value={activeTab} onValueChange={setActiveTab as any} dataCy='class-student-toggle'>
								<ToggleGroup.Content>
									<ToggleGroup.Item value='class' disabled={activeTab === TabID.Class} applyDisabledStyles={false}>
										<Text size='medium' bold>
											For Classes/Groups
										</Text>
									</ToggleGroup.Item>
									<ToggleGroup.Item value='student' disabled={activeTab === TabID.Student} applyDisabledStyles={false}>
										<Text size='medium' bold>
											For Single Student
										</Text>
									</ToggleGroup.Item>
								</ToggleGroup.Content>
							</ToggleGroup.Root>
							<OverlayScrollbarsComponent
								defer
								style={{height: 'calc(100% + 0px)', marginTop: 20}}
								options={{scrollbars: {autoHide: 'leave'}}}
							>
								<GridBox flow='row' gapY='3'>
									{tabContent()}
								</GridBox>
							</OverlayScrollbarsComponent>
						</FlexBox>
					</Body>
				</Drawer.Body>
			</Drawer>
		</Portal>
	);
}