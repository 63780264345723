import {AppEvent} from '@esgillc/events';

@AppEvent()
export class AssignmentDeletedEvent {
	constructor(public assignmentID: number) {
	}
}

@AppEvent()
export class AssignmentReorderEvent {
	constructor(public assignmentID: number) {
	}
}
