import {FlexBox} from '@esgi/ui/layout';
import {Checkbox} from '@esgi/ui/controls';
import {OneLinedText} from '@esgi/main/kits/common';
import {Info} from '@esgi/ui';
import {Container, Link} from './index.styled';
import {Dispatch} from 'react';

interface Props {
	checked: boolean;
	onChange: Dispatch<boolean>
}

export function CarryForward({checked, onChange}: Props) {

	return (
		<Container flow='row'>
			<OneLinedText size='medium'>Results:</OneLinedText>
			<FlexBox>
				<Checkbox
					data-cy='carry-forward-checkbox'
					label='Carry forward'
					checked={checked}
					onCheckedChange={() => onChange(!checked)}
				/>
				<FlexBox align='center'>
					<Link
						href='https://support.esgisoftware.com/hc/en-us/articles/209159526-Class-Totals-Report'
						target='_blank'
						rel='noreferrer'
					>
						<Info width={24} height={24}/>
					</Link>
				</FlexBox>
			</FlexBox>
		</Container>
	);
}