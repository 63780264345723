import {ComponentProps} from 'react';
import {Tooltip} from 'recharts';
import {ChartItem} from '../../../types';
import {TooltipContent} from '../../tooltip-content';

type Props = Pick<ComponentProps<typeof Tooltip>, 'active'> & {
	sessionsCount: number;
	payload?: {
		payload: ChartItem;
	}[];
};

export function BarItemTooltip({active, payload, sessionsCount}: Props) {
	if (payload?.length && active) {
		const {
			payload: {questionTitle, correct, incorrect},
		} = payload[0]!;

		return (
			<TooltipContent
				sessionsCount={sessionsCount}
				correct={correct}
				incorrect={incorrect}
				questionTitle={questionTitle}
			/>
		);
	}

	return null;
}
