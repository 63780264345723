class ObsTimePickerModel {
	value = ko.observable<string>();
	hours = ko.observable<number>();
	minutes = ko.observable<number>();
	seconds = ko.observable<number>();
	meridian = ko.observable<string>();
	constructor(hours: number, minutes: number, meridian: string) {
		this.hours(hours);
		this.minutes(minutes);
		this.meridian(meridian);
		this.seconds(0);
		this.value(hours + ':' + minutes + ' ' + meridian);
	}
}

export class ScheduleSection {
	init(cronExpression: string) {
		this.extractModel(cronExpression);
	}

	validate(): JQueryPromise<any> {
		let promise = $.Deferred();
		if (this.model == null) {
			return promise.reject().promise();
		}
		let status = this.model.days.Sunday()
			|| this.model.days.Monday()
			|| this.model.days.Tuesday()
			|| this.model.days.Wednesday()
			|| this.model.days.Thursday()
			|| this.model.days.Friday()
			|| this.model.days.Saturday();

		if (status) {
			return promise.resolve(true).promise();
		}
		return promise.reject().promise();
	}

	private extractModel(cronExpression: string) {
		if (cronExpression == null || cronExpression === '') {
			return;
		}

		let parts = cronExpression.split(' ');
		if (parts.length < 5) {
			return;
		}


		if (parts[1] && parts[2]) {
			let minutes = parseInt(parts[1], 10);

			let hours = parseInt(parts[2], 10);

			let meridian = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12 || 12;
			this.model.time(new ObsTimePickerModel(hours, minutes, meridian));
		}

		let daysOfWeek = parts[5];
		if (daysOfWeek === '*') {
			this.model.days.Sunday(true);
			this.model.days.Monday(true);
			this.model.days.Tuesday(true);
			this.model.days.Wednesday(true);
			this.model.days.Thursday(true);
			this.model.days.Friday(true);
			this.model.days.Saturday(true);
		} else {
			if (daysOfWeek.indexOf('1') !== -1) {
				this.model.days.Sunday(true);
			}

			if (daysOfWeek.indexOf('2') !== -1) {
				this.model.days.Monday(true);
			}

			if (daysOfWeek.indexOf('3') !== -1) {
				this.model.days.Tuesday(true);
			}

			if (daysOfWeek.indexOf('4') !== -1) {
				this.model.days.Wednesday(true);
			}

			if (daysOfWeek.indexOf('5') !== -1) {
				this.model.days.Thursday(true);
			}

			if (daysOfWeek.indexOf('6') !== -1) {
				this.model.days.Friday(true);
			}

			if (daysOfWeek.indexOf('7') !== -1) {
				this.model.days.Saturday(true);
			}
		}
	}

	getCronExpression() {
		let minute = this.model.time().minutes();
		let hour = this.model.time().hours();
		let meridian = this.model.time().meridian();
		if (meridian === 'AM' && hour === 12) {
			hour -= 12;
		}
		if (meridian === 'PM' && hour !== 12) {
			hour += 12;
		}

		let daysOfWeek = '';
		if (this.model.days.Sunday()) {
			daysOfWeek += '1,';
		}

		if (this.model.days.Monday()) {
			daysOfWeek += '2,';
		}

		if (this.model.days.Tuesday()) {
			daysOfWeek += '3,';
		}

		if (this.model.days.Wednesday()) {
			daysOfWeek += '4,';
		}

		if (this.model.days.Thursday()) {
			daysOfWeek += '5,';
		}

		if (this.model.days.Friday()) {
			daysOfWeek += '6,';
		}

		if (this.model.days.Saturday()) {
			daysOfWeek += '7,';
		}

		if (daysOfWeek.length > 1) {
			daysOfWeek = daysOfWeek.slice(0, -1);
		} else {
			return null;
		}

		return `0 ${minute} ${hour} ? * ${daysOfWeek} *`;
	}

	model = {
		days: {
			Sunday: ko.observable<boolean>(false),
			Monday: ko.observable<boolean>(false),
			Tuesday: ko.observable<boolean>(false),
			Wednesday: ko.observable<boolean>(false),
			Thursday: ko.observable<boolean>(false),
			Friday: ko.observable<boolean>(false),
			Saturday: ko.observable<boolean>(false),
		},
		time: ko.observable<ObsTimePickerModel>(new ObsTimePickerModel(0, 0, 'AM')),
	}
}
