export enum FilterType {
	ContentArea = 1,
	GradeLevel = 2,
	NotedAuthor = 3,
	SubjectTab = 4,
	NotedSeries = 5,
	Scope = 6,
	FeaturedSeries = 7,
	TestType = 8,
}

export enum TestsSortBy {
	None,
	CreateDate,
	TestName,
	ContentArea,
	StateStandard,
	TestOwner,
	NumberOfQuestions,
}

export enum MainScreen {
	Landing,
	Results
}
