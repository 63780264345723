import React, {ReactNode} from 'react';
import {HttpClient} from '@esgi/api';
import {Loader} from '@esgi/deprecated/jquery';
import {SsoTracker} from '@esgi/core/tracker';
import {StudentManagerEvents} from '../../../../../../events';
import StudentsService from '../../../../../../services/students-service';
import TableService from '../../../../../../services/table-service';
import {getEventCoord} from '../utils';
import DeleteButton from './delete-button';
import DeletePopup from './delete-popup';
import {dispatchAppEvent} from '@esgillc/events';

class State {
	modalOpened: boolean = false;
}

class Props {
	studentService: StudentsService;
	tableService: TableService;
}

export default class DeleteOperation extends React.PureComponent<Props, State> {
	public state = new State();
	private readonly loader: Loader = new Loader('body');

	public render(): ReactNode {
		return <>
			<DeleteButton onClicked={(e) => this.deleteClicked(e)}/>
			{this.renderModal()}
		</>;
	}

	private renderModal(): ReactNode {
		if (this.state.modalOpened) {
			return <DeletePopup
				students={this.props.tableService.studentsToShow$.value.filter(x => this.props.tableService.selectedStudentsIDs$.value.indexOf(x.studentID) !== -1)}
				close={() => this.setState({modalOpened: false})}
				process={() => this.deleteSelectedStudents()}
			/>;
		}
	}

	private deleteClicked(e): void {
		let {top, left} = getEventCoord(e);
		this.setState({modalOpened: true}, () => {
			$('.delete_student_dialog .modal-dialog')
				.css('top', top + 'px')
				.css('left', left + 'px');
		});
	}

	private deleteSelectedStudents(): void {
		let selectedStudents = this.props.tableService.selectedStudentsIDs$.value;
		this.loader.mask();

		HttpClient.default.ESGIApi.post('student-explorer', 'delete', {studentIDs: selectedStudents})
			.subscribe(() => this.setState({modalOpened: false}, () => {
				this.loader.unmask();

				let deletingStudent = this.props.studentService.getStudents().find(x => x.studentID === selectedStudents[0]);
				let deletingStudentName = deletingStudent.firstName + ' ' + deletingStudent.lastName;

				selectedStudents.forEach(studentId => {
					SsoTracker.trackEvent({
						trackingEvent: 'StudentDeleted',
						data: {studendId: studentId},
					});
				});
				const args = {
					studentIDs: selectedStudents,
					studentName: deletingStudentName,
				} as StudentManagerEvents.StudentsDeleted;

				dispatchAppEvent(StudentManagerEvents.StudentsDeleted, args);
			}));
	}
}
