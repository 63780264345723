import {Select} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const SelectField = styled(Select.Field, {
	maxHeight: 40,
});

export const SelectOption = styled(Select.Option, {
	variants: {
		displayDefaultLabel: {
			true: {
				display: 'flex',
				justifyContent: 'space-between',

				'&::after': {
					content: 'Default',
					color: '$primary80',
				},
			},
		},
	},
});
