import {FormDataBuilder} from '@esgi/api';
import {BaseService} from '@esgi/core/service';
import {WindowTool} from '@esgi/deprecated/utils';
import {FormControl, FormGroup, ValidatorArgs, Validators} from '@esgillc/ui-kit/form';
import {catchError, map, Observable, of, takeUntil} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ResetPasswordResponse} from './models';

export class ResetPasswordService extends BaseService {
	private key: string;

	public form = new FormGroup({
		password: new FormControl('', {validators: [Validators.required(), Validators.length.min(8)]}),
		confirm: new FormControl('', {validators: [Validators.required(), Validators.length.min(8)]}),
	});

	constructor() {
		super();
		const validator = (validationChain: Observable<ValidatorArgs<string>>) => {
			return validationChain.pipe(tap(args => {
				const value = args.field.currentValue;
				const origin = this.form.controls.password.value;
				if (value !== origin) {
					args.errors.push('consistent');
				}
			}));
		};
		this.form.controls.confirm.validators.push(validator);
	}

	init() {
		this.key = WindowTool.getUrlQueryParameter('key');
		if (!this.key) {
			location.href = '/';
		}

		return this.httpClient.ESGIApi.post<{valid: boolean}>('landing/passwords', 'check-reset-key', FormDataBuilder.BuildParameters({key: this.key}))
			.pipe(
				takeUntil(this.destroy$),
				map(r => {
					if (r.valid) {
						return {
							allowResetPassword: true,
							allowRemindPassword: false,
						};
					} else {
						return {
							allowResetPassword: false,
							allowRemindPassword: true,
						};
					}
				}),
				catchError(() => {
					return of({
						allowResetPassword: false,
						allowRemindPassword: true,
					});
				}),
			);
	}

	save() {
		const value = this.form.value;
		const data = FormDataBuilder.BuildParameters({
			key: this.key,
			password: value.password,
			confirmPassword: value.confirm,
		});
		return this.httpClient.ESGIApi.post<ResetPasswordResponse>('landing/passwords', 'reset', data)
			.pipe(
				map((res) => ({
					showSuccessMessage: true,
					allowRemindPassword: false,
					allowResetPassword: false,
					formIsBeingProcessed: false,
					userType: res.userType,
				})),
			);
	}
}
