export class StripeFormTemplate {
	static render() {
		return <div className='purchase-form' data-bind='afterRender: true, var: {root: $data}'>
            <div data-private data-bind='with: cardNumber'>
                <div className='form-group col-xs-12'
                     data-bind="css: {'has-success' : showSuccessValidation,  'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
                    <div className='row'>
                        <label className='control-label'>Card Number</label>
                        <div data-private id='stripe-card-number' className='form-control input-md validation-error-element'></div>
                        <div className='error-message visible-xs visible-sm hidden-md'>
                            <span data-bind='text:validation.validationResults' />
                        </div>
                        <div data-bind='if: showValidation '>
                            <div data-bind='if: showSuccessValidation'>
                                <i className='fa fa-check form-control-feedback' />
                            </div>
                            <div data-bind='if: showErrorValidation '>
                                <i className='fa fa-close form-control-feedback' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div data-private data-bind='with: cardExpiry'>
                    <div className='form-group col-xs-6'
                         data-bind="css: {'has-success' : showSuccessValidation,  'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
                        <label className='control-label'>Expiration Date</label>
                        <div data-private id='stripe-card-expiry' className='form-control input-md validation-error-element'></div>
                        <div className='error-message visible-xs visible-sm hidden-md'>
                            <span data-bind='text:validation.validationResults' />
                        </div>
                        <div data-bind='if: showValidation '>
                            <div data-bind='if: showSuccessValidation'>
                                <i className='fa fa-check form-control-feedback form-control-feedback-row-element' />
                            </div>
                            <div data-bind='if: showErrorValidation '>
                                <i className='fa fa-close form-control-feedback form-control-feedback-row-element' />
                            </div>
                        </div>
                    </div>
                </div>
                <div data-private data-bind='with: cardCvc'>
                    <div className='form-group col-xs-6'
                         data-bind="css: {'has-success' : showSuccessValidation,  'has-feedback' : showSuccessValidation() || showErrorValidation(), 'has-error' : showErrorValidation}, afterRender: true">
                        <label className='control-label'>Security Number</label>
                        <div data-private id='stripe-card-cvc' className='form-control input-md validation-error-element'></div>
                        <div className='error-message visible-xs visible-sm hidden-md'>
                            <span data-bind='text:validation.validationResults' />
                        </div>
                        <div data-bind='if: showValidation '>
                            <div data-bind='if: showSuccessValidation'>
                                <i className='fa fa-check form-control-feedback form-control-feedback-row-element' />
                            </div>
                            <div data-bind='if: showErrorValidation '>
                                <i className='fa fa-close form-control-feedback form-control-feedback-row-element' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
						<div data-private data-bind='with: address'>
							<div className='form-group col-xs-12' data-bind='afterRender: true'>
								<div className='row'>
									<div data-private id='stripe-address'></div>
								</div>
							</div>
						</div>
        </div>;
	}
}
