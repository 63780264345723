import {forwardRef} from 'react';
import {styled} from '@esgi/ui/theme';
import {Text} from '../../../typography';
import {BaseButton, BaseButtonProps} from '../base-button';

export const ButtonText = forwardRef<HTMLButtonElement, BaseButtonProps>(
	({dataCy, css = {}, ...props}, forwaredRef) => (
		<Button data-cy={dataCy ?? 'ui-kit-button-text'} ref={forwaredRef} css={css} {...props} />
	),
);

const Button = styled(BaseButton, {
	color: '$primary',

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'& svg': {
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	'&:hover:not(:disabled)': {
		color: '$secondary',
	},

	'&:disabled': {
		color: '$muted',
	},
});
