import {Input, OnErrorTooltip} from '@esgillc/ui-kit/form-control';
import {ControlValue, Form, FormControl, FormElement, FormGroup} from '@esgillc/ui-kit/form';
import styles from './styles.module.less';


interface Props {
	groupID: number;
	className: string;
	formController: FormGroup<{ groupName: FormControl<string> }, ControlValue<{ groupName: FormControl<string> }>>
}

export function GroupFormHeader({groupID, className, formController}: Props) {

	return <div data-cy='group-form-header'>
		<div className={styles.row}>
			<label className={styles.label}>Class Name</label>
			<label className={styles.value} data-cy='class-name'>{className}</label>
		</div>
		<div data-cy='header-form'>
			<Form controller={formController}>
				<FormElement control={formController.controls.groupName}>
					<label className={styles.formLabel}
					       data-cy='input-label'>{groupID ? 'Edit Group Name' : 'Group Name'}</label>
					<Input
						placeholder={'i.e. ELL Learners'}
					/>
					<OnErrorTooltip showOnError='required' placement='right'>
						Please enter group name
					</OnErrorTooltip>
				</FormElement>
			</Form>
		</div>
	</div>;

}

