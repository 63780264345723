import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';

export function StudentsContent() {
	const {students} = StudentsPanel.Tabs.Students.useStudentsContext();

	const {
		selected: {studentId: selectedStudentId},
		selectStudent,
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			{students.length ? (
				<StudentsPanel.Lists.SelectableList.Single
					items={students}
					onItemClick={(studentId) => selectStudent(studentId)}
					value={selectedStudentId}
					dataCy='students-list'
				>
					{({item: {firstName, lastName}}) => (
						<StudentsPanel.StudentFullName dataCy='student-fullname' firstName={firstName} lastName={lastName} />
					)}
				</StudentsPanel.Lists.SelectableList.Single>
			) : (
				<StudentsPanel.Tabs.EmptyTabContent size='full' templateText='No students to display' />
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
