import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import React, {Fragment, ReactNode, RefObject} from 'react';
import {OverlayScrollbars} from 'overlayscrollbars';
import {AutoSize} from '@esgillc/ui-kit/core';
import {fromEvent, interval, Subject} from 'rxjs';
import {takeUntil, throttle} from 'rxjs/operators';
import {join, tryCall} from '@esgillc/ui-kit/utils';
import {isEqual} from 'underscore';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {
	BodyData,
	BodyRenderer,
	HeaderData,
	HeaderRenderer,
	RowData,
	RowBodyRenderer, CellOptions, ClassNamesOption, Cell, ScrollActionsState, CalculateFunction,
} from './types';
import {MatrixContext} from './matrix-context';
import 'overlayscrollbars/overlayscrollbars.css';
import styles from './matrix.module.less';

class State {
	scrollActions: ScrollActionsState = {
		canPrevColumn: true,
		canNextColumn: true,
		canPrevRow: true,
		canNextRow: true,
	} as ScrollActionsState;
}

interface Props<ColHeaderType, RowHeaderType, CellT> {
	maxWidth: number | CalculateFunction;
	maxHeight: number | CalculateFunction;

	columnHeaderOptions: CellOptions<ColHeaderType>;
	rowHeaderOptions: CellOptions<RowHeaderType>;
	cellsOptions: CellOptions<CellT> & {cellGetter: (source: CellT[], col: ColHeaderType, row: RowHeaderType) => CellT};

	className?: ClassNamesOption;

	renderHeader?: (data: HeaderData<ColHeaderType>, headerRenderer: HeaderRenderer) => ReactNode;
	renderBody?: (data: BodyData<RowHeaderType, CellT>, bodyRenderer: BodyRenderer) => ReactNode;
	renderRow?: (data: RowData<RowHeaderType, CellT>, rowRenderer: RowBodyRenderer) => ReactNode;

	renderActions?: () => ReactNode;

	overlayScrollRef?: (ref: OverlayScrollbars) => void;
	tableRef?: (ref: HTMLTableElement) => void;
	children?: any;
}

export default class Matrix<ColHeaderType extends Cell, RowHeaderType extends Cell, CellType extends Cell> extends React.PureComponent<Props<ColHeaderType, RowHeaderType, CellType>, State> {
	public readonly state = new State();
	public readonly tableRef: RefObject<HTMLTableElement> = React.createRef();
	private timeout;

	private readonly onDestroy$: Subject<void> = new Subject();
	private overlayScrollRef: OverlayScrollbarsComponentRef;

	public get osInstance(): OverlayScrollbars {
		return this.overlayScrollRef?.osInstance();
	}

	public get overlayScrollWidth(): number {
		return this.overlayScrollRef?.getElement().getBoundingClientRect().width;
	}

	public componentDidMount() {
		fromEvent(window, 'resize')
			.pipe(
				takeUntil(this.onDestroy$),
				throttle(() => interval(50)))
			.subscribe(() => {
				const viewPort = this.osInstance?.elements().viewport;
				this.calculateActionsVisibility(viewPort);
			});
	}

	public componentDidUpdate(prevProps: Readonly<Props<ColHeaderType, RowHeaderType, CellType>>, prevState: Readonly<State>, snapshot?: any) {
		const viewPort = this.osInstance?.elements().viewport;
		if (viewPort) {
			this.timeout = setTimeout(() => this.calculateActionsVisibility(viewPort), 300);
		}
	}

	public componentWillUnmount() {
		clearTimeout(this.timeout);
		this.onDestroy$.next();
	}

	public render() {
		return <MatrixContext.Provider
			value={{osInstance: this.osInstance, scrollActionState: this.state.scrollActions}}>
			{this.renderMatrix()}
		</MatrixContext.Provider>;
	}

	private onScroll = (e) => {
		this.calculateActionsVisibility(e.target);
	};

	private calculateActionsVisibility(element: HTMLElement) {
		if (!element) {
			return;
		}

		const top = element.scrollTop;
		const left = element.scrollLeft;
		const bottom = Math.round(element.scrollTop + element.clientHeight);
		const right = Math.round(element.scrollLeft + element.clientWidth);

		const actionsState = {
			canPrevRow: top !== 0,
			canPrevColumn: left !== 0,
			canNextRow: bottom !== element.scrollHeight,
			canNextColumn: right !== element.scrollWidth,
		} as ScrollActionsState;

		if (!isEqual(actionsState, this.state.scrollActions)) {
			this.setState({scrollActions: actionsState});
		}
	}

	private overlayScrollbarRefSetter = (ref: OverlayScrollbarsComponentRef) => {
		if (this.overlayScrollRef) {
			const viewport = this.osInstance?.elements().viewport;
			viewport?.removeEventListener('scroll', this.onScroll);
		}

		this.overlayScrollRef = ref;

		requestAnimationFrame(() => {
			const instance = ref?.osInstance();
			if (instance) {
				const viewport = instance.elements().viewport;
				viewport?.addEventListener('scroll', this.onScroll);
				this.calculateActionsVisibility(viewport);
				tryCall(this.props.overlayScrollRef, instance);
			}
		});
	};

	private renderMatrix() {
		return <div className={join(styles.body, this.props.className?.body)}>
			<div className={join(styles.tableContainer, this.props.className?.tableContainer)}>
				<AutoSize maxWidth={this.props.maxWidth}
				          maxHeight={this.props.maxHeight}
				          contentHeight={this.props.maxHeight}>
					{(bounds) => this.renderTable(bounds)}
				</AutoSize>
			</div>
			{this.props.children}
		</div>;
	}

	private renderTable(bounds) {
		return <OverlayScrollbarsComponent ref={this.overlayScrollbarRefSetter}
		                                   className={styles.overlayScrollBar}
		                                   options={{scrollbars: {autoHide: 'never'}}}
		                                   style={{
			                                   width: bounds.width,
			                                   height: bounds.height,
		                                   }}>
			<table ref={this.tableRef} className={join(styles.mainPanel, this.props.className?.table)}>
				{this.renderHeader()}
				{this.renderBody()}
			</table>
		</OverlayScrollbarsComponent>;
	}

	private renderHeader() {
		const columnHeaderCells = this.props.columnHeaderOptions.cells || [];
		const cellRenderer = this.props.columnHeaderOptions.cellRenderer;

		const headerCellsRenderer = () => columnHeaderCells.map((c, index) => cellRenderer(c, index));

		const customHeader = this.props.renderHeader;
		if (customHeader) {
			return customHeader(
				{cells: columnHeaderCells},
				() => headerCellsRenderer(),
			);
		}

		return <thead>
		<tr key={0}>
			<th key={-1}/>
			{headerCellsRenderer()}
		</tr>
		</thead>;
	}

	private renderBody() {
		const rowHeaderCells = this.props.rowHeaderOptions.cells;

		const rowsRenderer = () => {
			const rows = [];
			for (const cell of rowHeaderCells) {
				rows.push(<Fragment
					key={rowHeaderCells.indexOf(cell)}>{this.renderRow(cell, rowHeaderCells.indexOf(cell))}</Fragment>);
			}

			return rows;
		};

		const customBody = this.props.renderBody;

		if (customBody) {
			return this.props.renderBody(
				{yHeaderCells: rowHeaderCells, cells: this.props.cellsOptions.cells},
				() => rowsRenderer(),
			);
		}

		return <tbody className={join(this.props.className?.table)}>{rowsRenderer()}</tbody>;
	}

	private renderRow(rowHeader: RowHeaderType, index: number) {
		const cells: CellType[] = [];

		for (const col of this.props.columnHeaderOptions.cells) {
			const cell = this.props.cellsOptions.cellGetter(this.props.cellsOptions.cells, col, rowHeader);
			cells.push(cell);
		}

		const rowBodyRenderer = () => {
			return <>
				{this.props.rowHeaderOptions.cellRenderer(rowHeader, index)}
				{cells.map((c, index) => <Fragment
					key={c.id}>{this.props.cellsOptions.cellRenderer(c, index)}</Fragment>)}
			</>;
		};

		if (this.props.renderRow) {
			return this.props.renderRow({row: rowHeader, cells, rowIndex: index}, () => rowBodyRenderer());
		}

		return <tr key={rowHeader.id}>
			{rowBodyRenderer()}
		</tr>;
	}
}
