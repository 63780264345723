import {deepCopy} from 'shared/utils';
import {CriteriaApiModel, DescriptionApiModel, LevelApiModel} from '../types';

function byPropertySorterFactory<T>(propertySelector: (item: T) => number): (a: T, b: T) => number {
	return (a: T, b: T) => propertySelector(a) - propertySelector(b);
}

export const criteriaSorter = byPropertySorterFactory<CriteriaApiModel>((c: CriteriaApiModel) => c.order);
export const levelSorter = byPropertySorterFactory<LevelApiModel>((c: LevelApiModel) => c.score);

export function descriptionSorter(criteria: CriteriaApiModel[], levels: LevelApiModel[]): (a: DescriptionApiModel, b: DescriptionApiModel) => number {
	return (a: DescriptionApiModel, b: DescriptionApiModel) => {
		if(a.levelID !== b.levelID) {
			const levelA = levels.find(l => l.id === a.levelID);
			const levelB = levels.find(l => l.id === b.levelID);
			return levelB.score - levelA.score; //Levels displaying in reverse order by score;
		} else {
			const criteriaA = criteria.find(c => c.id === a.criteriaID);
			const criteriaB = criteria.find(c => c.id === b.criteriaID);
			return criteriaA.order - criteriaB.order;
		}
	};
}

export function sortCriteria(criteria: CriteriaApiModel[]): CriteriaApiModel[] {
	return deepCopy(criteria).sort(criteriaSorter);
}

export function sortLevels(levels: LevelApiModel[]): LevelApiModel[] {
	return deepCopy(levels).sort(levelSorter).reverse(); // Levels placed in revers order.
}
