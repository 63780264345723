import React from 'react';
import {getUser, userStorage, UserType} from '@esgi/core/authentication';
import {EventBusManager} from '@esgillc/events';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {IBoxInfo, IStudentBox, ITeacherBox} from '../../core/api';
import {HierarchyStudentAdd, HierarchyStudentEdit} from '../../core/events';
import {BoxType} from '../../core/models';
import {GetStarted} from 'shared/modules/get-started/get-started';
import {StudentSorter} from 'global/utils/StudentSorter';
import {StudentEditIcon} from 'modules/hierarchy/components/icons/student-edit-icon';
import {
	StudentChangedEvent,
	StudentCreatedEvent,
} from 'modules/forms/students-form/events';

export class State {
	allBoxRef: React.RefObject<HTMLElement>;
}

export class Props {
	boxes: IBoxInfo[];
	students: IStudentBox;
	teachers: ITeacherBox;
	scheduledIDs: number[];
	classID: number;
	groupID: number;
	teacherID: number;
	schoolID: number;
	teacherGroupID: number;
	selectedID: number;
	onDragStart?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	onDragEnd?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	onSelected: (id: number, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
	onOpenStudentProfile: () => void;
}

export class Student extends React.Component<Props, State> {
	public publish = {
		add: () => {
			const event = new HierarchyStudentAdd({
				userID: this.props.teacherID,
				userType: UserType.T,
				groupID: this.props.groupID,
				classID: this.props.classID,
				schoolID: this.props.schoolID,
				specialistGroupID: null,
			});

			this.eventBus.dispatch(HierarchyStudentAdd, event);
		},
		edit: (id: number) => {
			const student = this.props.students.items.find(s => s.studentID === id);
			if (!student) {
				return;
			}

			const event = new HierarchyStudentEdit({
				studentID: student.studentID,
				userID: student.teacherID,
				userType: UserType.T,
			});

			this.eventBus.dispatch(HierarchyStudentEdit, event);
		},
	};
	private eventBus = new EventBusManager();

	constructor(props) {
		super(props);
		this.state = {
			allBoxRef: null,
		};
	}

	public get items(): ItemProps[] {
		let source = this.props.students.items.filter(x => x.teacherID !== null);
		if (this.props.groupID) {
			source = source.filter(s => s.groups.some(g => g === this.props.groupID));
		} else if (this.props.classID) {
			source = source.filter(s => s.classes.some(c => c === this.props.classID));
		} else if (this.props.teacherID) {
			source = source.filter(s => s.teacherID === this.props.teacherID);
		} else if (this.props.schoolID && !this.props.teacherGroupID) {
			source = source.filter(s => s.schoolID === this.props.schoolID);
		} else if (this.props.teacherGroupID) {
			source = source.filter(s => this.props.teachers.items.filter(x => x.groups.some(y => y === this.props.teacherGroupID)).some(x => x.userID === s.teacherID));
		} else {
			source = source.filter(_ => false);
		}

		const students = StudentSorter.sortStudents(source, this.props.students.sortBy);

		return [...students].map(r => {
			return {
				id: r.studentID,
				title: `${r.firstName} ${r.lastName}`,
			};
		});
	}

	public componentDidMount() {
		this.eventBus.subscribe(StudentChangedEvent, ({studentID}) => {
			this.props.onSelected.call(null, studentID);
		});
		this.eventBus.subscribe(StudentCreatedEvent, ({studentID}) => {
			this.props.onSelected.call(null, studentID);
		});
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	public render() {
		if (!this.props.schoolID && !this.props.teacherGroupID) {
			return null;
		}
		const canView = getUser().canViewStudents;
		const options: BoxOptions = {
			canDrag: false,
			boxType: BoxType.ClassicStudent,
			title: 'All Students',
			canCreateTooltip: null,
			canAdd: canView && this.props.students.canCreate,
			canEdit: canView && this.props.students.canEdit,
			canView,
			tooltipPostfix: 'student',
			editIcon: <StudentEditIcon/>,
			editIconTooltip: 'View profile',
		};

		return (
			<>
				<Box
					options={options}
					selectedID={this.props.selectedID}
					items={this.items}
					empty={{message: 'The list is empty'}}
					scheduledIDs={this.props.scheduledIDs}
					itemSelected={this.props.onSelected}
					open={this.props.boxes.filter(t => t.boxType === BoxType.ClassicStudent && t.open).length > 0}
					afterRender={(ref) => this.afterRender(ref)}
					onDragEnd={this.props.onDragEnd}
					onDragStart={this.props.onDragStart}
					onEditClicked={this.publish.edit}
					onAddClicked={() => this.addClicked()}
					onOpenCloseChanged={this.props.onOpenCloseChanged}
				/>
				{getUser()?.userType === UserType.T &&
					<GetStarted
						pointToRef={this.state.allBoxRef}
						left={195}
						arrowLeft={-7}
						arrowTop={32}
						top={-20}
					/>
				}
			</>
		);
	}

	public afterRender(allBoxRef: React.RefObject<HTMLElement>) {
		this.setState({allBoxRef});
	}

	public addClicked() {
		const context = userStorage.get();

		if (context.userType !== UserType.T || !this.props.classID) {
			this.publish.add();
		} else {
			if (this.items.length === 0) {
				this.props.onOpenStudentProfile();
			} else {
				this.publish.add();
			}
		}
	}
}
