import * as React from 'react';
import type {SVGProps} from 'react';

export function InCorrectAnswer(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect width='16' height='16' rx='4' transform='matrix(0 -1 -1 0 16 16)' fill='none'/>
			<path d='M5.17549 5.23022C5.40948 4.99843 5.78885 4.99843 6.02284 5.23022L10.8245 9.98677C11.0585 10.2186 11.0585 10.5944 10.8245 10.8262C10.5905 11.0579 10.2111 11.0579 9.97712 10.8262L5.17549 6.06961C4.9415 5.83782 4.9415 5.46201 5.17549 5.23022Z' fill='#333'/>
			<path d='M5.17553 10.7698C4.94155 10.538 4.94155 10.1622 5.17553 9.93039L9.97716 5.17384C10.2112 4.94205 10.5905 4.94205 10.8245 5.17384C11.0585 5.40563 11.0585 5.78144 10.8245 6.01323L6.02288 10.7698C5.78889 11.0016 5.40952 11.0016 5.17553 10.7698Z' fill='#333'/>
		</svg>
	);
}
