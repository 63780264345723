import React, {Component} from 'react';
import styles from './card.module.less';
import CardTemplate from './components/card-template';
import {join} from '@esgillc/ui-kit/utils';

class Props {
	selected?: boolean = false;
	titleNumber: number;
	className?: string;
	children?: any;
}

export class Card extends Component<Props> {
	get cardStyle(): string {
		const disabled = this.props.selected || styles.disabled;
		return join(styles.card, this.props.className, disabled);
	}

	render() {
		return (
			<div className={this.cardStyle}>
				<div className={styles.content}>
					{this.props.children}
				</div>
				<CardTemplate number={this.props.titleNumber} />
			</div>
		);
	}
}
