import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from '../route-wrapper';
import DefaultRouteFallback from './default-route-fallback';

const CleverRegistrationPage = lazyComponent(() => import('pages/landing/clever-registration'));

export default function CleverRegistration() {
	return <RouteWrapper title='Clever Registration'>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<CleverRegistrationPage/>
		</Suspense>
	</RouteWrapper>;
}