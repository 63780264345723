import {ControlValue, ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {SessionType, SubjectPublishType} from '@esgi/core/enums';
import {CustomDateRange, DistrictTab, GradeLevel} from '../types';
import PublishToTeachers = DistrictTab.PublishToTeachers;
import PublishToSpecialists = DistrictTab.PublishToSpecialists;

export type DistrictFormType = FormGroup<{
	subjectName: FormControl<string>,
	gradeLevels: FormControl<GradeLevel[]>,
	publishToTeacher: FormControl<PublishToTeachers>,
	schools: FormControl<number[]>,
	publishToSpecialists: FormControl<PublishToSpecialists>,
	isd: FormControl<number[]>,
	iss: FormControl<number[]>
	publishType: FormControl<SubjectPublishType>,
	customDateRange: FormControl<CustomDateRange>,
	track: FormControl<number[]>,
	trackDates: FormControl<number[]>,
	forceTest: FormControl<boolean>
	sessionType: FormControl<SessionType>
	shuffleQuestions: FormControl<boolean>
},
	ControlValue<{
		subjectName: FormControl<string>,
		gradeLevels: FormControl<GradeLevel[]>,
		publishToTeacher: FormControl<PublishToTeachers>,
		schools: FormControl<number[]>,
		publishToSpecialists: FormControl<PublishToSpecialists>,
		isd: FormControl<number[]>,
		iss: FormControl<number[]>
		publishType: FormControl<SubjectPublishType>,
		customDateRange: FormControl<CustomDateRange>,
		track: FormControl<number[]>,
		trackDates: FormControl<number[]>,
		forceTest: FormControl<boolean>
		sessionType: FormControl<SessionType>
		shuffleQuestions: FormControl<boolean>
	}>>

export const createDistrictForm = ():
	DistrictFormType => new FormGroup({
	subjectName: new FormControl('', {
		validators: [Validators.required()],
		initialValidateStatus: ElementStatus.untouched,
	}),
	gradeLevels: new FormControl([], {validators: [Validators.required()]}),
	publishToTeacher: new FormControl(PublishToTeachers.All),
	schools: new FormControl([]),
	publishToSpecialists: new FormControl(PublishToSpecialists.All),
	isd: new FormControl([]),
	iss: new FormControl([]),
	publishType: new FormControl(SubjectPublishType.Indefinitely),
	customDateRange: new FormControl<CustomDateRange>(null),
	track: new FormControl<number[]>([]),
	trackDates: new FormControl<number[]>([]),
	forceTest: new FormControl(false),
	sessionType: new FormControl(SessionType.TestIncorrect),
	shuffleQuestions: new FormControl(false),
});



