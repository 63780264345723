import {Drawer} from '@esgi/main/kits/common';
import {UserCard} from '@esgi/ui/icons';
import {useEffect, useState} from 'react';
import {isTrackDatesValid} from '../../../utils/is-track-dates-valid';
import {isNull} from 'underscore';
import {
	SchoolYearModel,
	TrackDateModelState,
	TrackModel,
	TrackType,
	UpdateTrackRequestParams,
	UserModel,
} from '../../../types';
import {useDateValuesChanged} from '../../../hooks/use-date-values-changed';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {DrawerBody} from '../../drawer-body.styled';
import {ExpirationDate} from '../../expiration-date';
import {CurrentMarkingPeriod} from '../components/current-marking-period';
import {TrackDatesWithSlider} from '../../track-dates-with-slider';
import {Logout} from '../../logout';
import {Observable} from 'rxjs';
import {PotentialDataImpactAlert} from '../../panels/components/potential-data-impact';

type Props = {
	onClose: VoidFunction;
	schoolYearTypes: SchoolYearModel[];
	user: UserModel;
	updateTrackRequest: (args: UpdateTrackRequestParams) => Observable<void>;
	visibleTrack: TrackModel;
};

export function EditMode({onClose, schoolYearTypes, user, updateTrackRequest, visibleTrack}: Props) {
	const [isPotentialDataImpactOpen, setIsPotentialDataImpactOpen] = useState(false);
	const [isDataUpdating, setIsDataUpdating] = useState(false);

	const [dataIsValid, setDataIsValid] = useState(true);
	const [trackName, setTrackName] = useState<string | null>(null);
	const [trackType, setTrackType] = useState<TrackType | null>(null);
	const [datesInRowsState, setDatesInRowsState] = useState<TrackDateModelState[]>([]);

	useEffect(() => {
		setTrackName(visibleTrack.name);
		setTrackType(visibleTrack.schoolYearType);
	}, [visibleTrack]);

	useEffect(() => {
		const isDatesValid = isTrackDatesValid(datesInRowsState);
		const isTrackNameValid = !isNull(trackName) && Boolean(trackName);

		setDataIsValid(isDatesValid && isTrackNameValid);
	}, [datesInRowsState, trackName]);

	const handleActionButtonClick = () => {
		if (trackType !== visibleTrack.schoolYearType || isDatesChanged) {
			setIsPotentialDataImpactOpen(true);
			return;
		}

		handleUpdateTrack();
	};

	const handleUpdateTrack = () => {
		if (visibleTrack && trackName && trackType) {
			setIsDataUpdating(true);

			updateTrackRequest({
				updatedTrack: visibleTrack,
				trackDates: datesInRowsState,
				trackType,
				trackName,
			}).subscribe(() => setIsDataUpdating(false));
		}
	};

	const isDatesChanged = useDateValuesChanged({
		datesInRowsState,
		currentTrackDates: visibleTrack?.trackDates ?? [],
	});

	const isActionButtonActive =
		trackName !== visibleTrack?.name || trackType !== visibleTrack.schoolYearType || isDatesChanged;

	return (
		<>
			<Drawer.Header
				Icon={UserCard}
				tooltipText='My Account'
				sectionName='My Account'
				withActionButton
				closeDrawer={onClose}
				actionButtonText='Save Changes'
				onActionButtonClick={handleActionButtonClick}
				actionButtonDisabled={!isActionButtonActive || !dataIsValid || isDataUpdating}
			/>
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<DrawerBody>
					<Drawer.ContentBlock title='Expiration Date'>
						<ExpirationDate
							date={user?.expirationDate ? new Date(user.expirationDate) : null}
							withRenewButton={user ? !user.notRenewable : false}
							canAllowRenewByCC={user?.canAllowRenewByCC}
						/>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Current Marking Period'>
						<CurrentMarkingPeriod
							trackName={trackName}
							setTrackName={setTrackName}
							trackType={trackType}
							setTrackType={setTrackType}
						/>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Marking Period Dates'>
						{schoolYearTypes && visibleTrack && trackType && (
							<TrackDatesWithSlider
								schoolYearTypes={schoolYearTypes}
								defaultTrackDates={visibleTrack.trackDates}
								defaultTrackType={visibleTrack.schoolYearType}
								selectedTrackType={trackType}
								datesInRowsState={datesInRowsState}
								setDatesInRowsState={setDatesInRowsState}
								withDateSlider={false}
								counterItemSize='small'
								itemRowGap='16'
							/>
						)}
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Terminate Current Session'>
						<Logout />
					</Drawer.ContentBlock>
				</DrawerBody>
			</OverlayScrollbarsComponent>

			{isPotentialDataImpactOpen && (
				<PotentialDataImpactAlert onClose={() => setIsPotentialDataImpactOpen(false)} onProceed={handleUpdateTrack} />
			)}
		</>
	);
}
