import {useCallback} from 'react';
import {useDiagramData} from './use-diagram-data';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {
	barLineGap,
	barLineWidth,
	graphSizes,
	xAxisDiagramGap,
	yAxisDiagramGap,
	yAxisTicksTitleGap,
} from './constants';
import {Notes} from './components/notes';
import {Bar, ComposedChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {StackBarName, XAxisId, YAxisId} from './types';
import {BarItemTooltip} from './components/bar-item-tooltip';
import {BarRect} from './components/bar-rect';
import {getThemeColor} from '@esgi/ui';
import {TestInfo, TestSession} from '../../../../../../types';
import {EmptyDataContainer} from './index.styled';
import {EmptyContentBox} from '../../../../../../kit/empty-content/index.styled';

type Props = {
	session: TestSession;
	testInfo: TestInfo;
};

export function RubricDiagram({session, testInfo}: Props) {
	const {data, maxScore} = useDiagramData({session, testInfo});

	const getTicks = useCallback((length: number) => Array.from({length}, (_, i) => i + 1), []);

	if (!data.length) {
		return (
			<EmptyDataContainer>
				<EmptyContentBox>
					<Text size='small' font='mono' color='neutral40'>
						No data to display
					</Text>
				</EmptyContentBox>
			</EmptyDataContainer>
		);
	}
	return (
		<GridBox gap='4'>
			<ResponsiveContainer height={graphSizes[maxScore]}>
				<ComposedChart
					data={data}
					margin={{top: 24, right: 0, bottom: 0, left: 24}}
				>
					<YAxis
						yAxisId={YAxisId.YBarChart}
						ticks={getTicks(maxScore)}
						tickSize={0}
						tickMargin={0}
						tick={{fontSize: 10, fill: getThemeColor('blue')}}
						axisLine={false}
					>
						<Label
							position='insideTopRight'
							value='Score'
							offset={0}
							dy={-yAxisTicksTitleGap}
							fontSize={10}
							fill={getThemeColor('blue')}
						/>
					</YAxis>

					<XAxis
						xAxisId={XAxisId.XBarChart}
						tickLine={false}
						tickSize={0}
						tickMargin={xAxisDiagramGap}
						axisLine={false}
						tickFormatter={(_, index) => String(index + 1)}
						padding={{right: barLineWidth / 2, left: barLineWidth + yAxisDiagramGap}}
						scale='point'
						tick={{fontSize: 10, fill: getThemeColor('lowContrast')}}
					>
						<Label
							position='left'
							value='Criteria'
							offset={0} dy={-5}
							fontSize={10}
							fill={getThemeColor('lowContrast')}
						/>
					</XAxis>

					<Bar
						yAxisId={YAxisId.YBarChart}
						xAxisId={XAxisId.XBarChart}
						stackId={StackBarName.AnswerInfo}
						dataKey='score'
						barSize={barLineWidth}
						fill={getThemeColor('primaryMuted')}
						shape={<BarRect maxScore={maxScore}/>}
						activeBar={false}
					/>

					<Tooltip cursor={false} content={<BarItemTooltip />} />
				</ComposedChart>
			</ResponsiveContainer>
			{session.notes && <Notes sessionNote={session.notes} contentPaddingLeft={44} />}
		</GridBox>
	);
}
