import * as SelectRadix from '@radix-ui/react-select';
import {styled} from '@esgi/ui/theme';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Skeletonable} from '../../../../types';
import {Expand} from '@esgi/ui/icons';
import {CSS} from '@stitches/react';
import {Text} from '@esgi/ui/typography';
import {useSelectContext} from '../../context';
import {GridBox, Box} from '@esgi/ui/layout';
import {SkeletonStylesWithBorders} from '../../../../skeleton';

export type SelectFieldProps = ComponentPropsWithoutRef<'button'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;
	placeholder?: string;
	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
	error?: boolean
} & Skeletonable &
	SelectRadix.SelectTriggerProps;

export const SelectField = forwardRef<HTMLButtonElement, SelectFieldProps>(
	({dataCy, placeholder, skeleton, error, ...props}, forwaredRef) => {
		const {disabled, errorMessage, onClick} = useSelectContext();

		return (
			<Field
				{...props}
				data-cy={dataCy ?? 'ui-kit-select-field'}
				skeleton={skeleton}
				data-error={Boolean(errorMessage || error)}
				ref={forwaredRef}
				disabled={skeleton || props.disabled}
				withChildren={Boolean(props.children)}
			>
				{props.children}
				<Wrapper onClick={onClick} align='center' justify='between' flow='column'>
					<GridBox>
						<Placeholder size='medium' font='mono' data-placeholder-content=''>
							{placeholder}
						</Placeholder>
						<SelectRadix.Value placeholder={null} data-value-content='' />
					</GridBox>
					{!disabled ? (
						<SelectIcon>
							<ArrowIcon data-cy={dataCy ? `${dataCy}-arrow-icon` : 'ui-kit-select-field-arrow-icon'} />
						</SelectIcon>
					) : (
						<IconPlug data-cy={dataCy ? `${dataCy}-icon-plug` : 'ui-kit-select-field-icon-plug'} />
					)}
				</Wrapper>
			</Field>
		);
	},
);

const Wrapper = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
});

const Placeholder = styled(Text);

const ArrowIcon = styled(Expand, {
	cursor: 'pointer',
	transform: 'rotateX(0deg)',
	transition: 'transform .3s ease, fill .3s',
});

const iconSizes: CSS = {
	width: 24,
	height: 24,
};

const IconPlug = styled(Box, iconSizes);

const SelectIcon = styled(SelectRadix.SelectIcon, {
	...iconSizes,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',

	[`& ${ArrowIcon}`]: {
		width: '100%',
		height: '100%',
		fill: '$base',

		'& path': {
			fill: '$base',
		},
	},
});

const Field = styled(SelectRadix.SelectTrigger, {
	all: 'unset',
	display: 'grid',
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',
	justifyContent: 'space-between',
	borderRadius: 6,
	paddingLeft: 12,
	paddingRight: 5,
	paddingTop: 7,
	paddingBottom: 7,
	backgroundColor: '$vivid',
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$primaryMuted',
	boxSizing: 'border-box',
	userSelect: 'none',
	outline: 'none',
	appearance: 'none',

	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},

		withChildren: {
			true: {
				gridTemplateColumns: 'auto 1fr',
			},
		},
	},

	'&:not([data-disabled]):hover': {
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondary92',

		'&[data-placeholder]': {
			'& [data-placeholder-content]': {
				color: '$secondary',
			},
		},

		'&:not([data-placeholder])': {
			'& [data-placeholder-content]': {
				color: '$secondary80',
			},

			[`& [data-value-content] > ${Text}`]: {
				color: '$secondary',
			},
		},

		[`& ${ArrowIcon}`]: {
			fill: '$secondary',

			'& path': {
				fill: '$secondary',
			},
		},
	},

	'&[data-placeholder]': {
		'& [data-placeholder-content]': {
			color: '$primary',
		},
	},

	'&:not([data-placeholder])': {
		paddingTop: 3.5,
		paddingBottom: 4,

		'& [data-placeholder-content]': {
			color: '$lowContrast',
			fontSize: 11,
			fontWeight: '500',
			lineHeight: '12px',
		},

		[`& [data-value-content] > ${Text}`]: {
			color: '$primary',
		},
	},

	'&[data-state=open]': {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		borderColor: 'transparent',
		borderBottomColor: '$primaryMuted',
		backgroundColor: '$primaryBackground',
		boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)',

		'&[data-placeholder]': {
			'& [data-placeholder-content]': {
				color: '$primary72',
			},
		},

		[`& ${ArrowIcon}`]: {
			transform: 'rotateX(180deg)',
			fill: '$primary',

			'& path': {
				fill: '$primary',
			},
		},
	},

	'&:not([data-state=open])[data-error=true]': {
		borderColor: '$negativeHighlight',

		'&[data-placeholder]': {
			'& [data-placeholder-content]': {
				color: '$negative',
			},
		},

		[`& [data-value-content] > ${Text}`]: {
			color: '$negative',
		},
	},

	'&[data-disabled]': {
		borderColor: '$border',
		cursor: 'default',

		'& [data-placeholder-content]': {
			color: '$lowContrast',
		},

		[`& [data-value-content] > ${Text}`]: {
			color: '$lowContrast',
		},
	},
});
