import {FilterType} from '../../../enums';

export namespace BoxEvents {
	export function Open(type: FilterType) {
		return {
			type: FilterType,
		};
	}

	export module Open {
		export interface Args {
			type: FilterType;
		}
	}
}
