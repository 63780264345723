import {HierarchyMode} from './core/models/enums';
import {HierarchySnapshot} from './core/models/snapshot/hierarchy-snapshot';

export function selectedStudentID(hierarchy: HierarchySnapshot): number {
	if (!hierarchy) {
		return 0;
	}

	const {mode, classic, specialist, preAssess} = hierarchy;
	if (mode === HierarchyMode.Classic && classic) {
		return classic.studentID;
	}
	if (hierarchy.mode === HierarchyMode.Specialist && specialist) {
		return specialist.studentID;
	}
	if (hierarchy.mode === HierarchyMode.PreAssess && preAssess) {
		return preAssess.studentID;
	}
	return 0;
}

export function selectedSchoolID(hierarchy: HierarchySnapshot): number {
	if (!hierarchy) {
		return 0;
	}

	const {mode, classic, specialist} = hierarchy;
	if (mode === HierarchyMode.Classic && classic) {
		return classic.schoolID;
	}
	if (hierarchy.mode === HierarchyMode.Specialist && specialist) {
		return specialist.filter.schoolID;
	}
	return 0;
}

export function selectedUserID(hierarchy: HierarchySnapshot): number {
	if (!hierarchy) {
		return 0;
	}

	const {mode, classic, specialist, preAssess} = hierarchy;
	if (mode === HierarchyMode.Classic && classic) {
		return classic.teacherID;
	}
	if (hierarchy.mode === HierarchyMode.Specialist && specialist) {
		return specialist.userID;
	}
	if (hierarchy.mode === HierarchyMode.PreAssess && preAssess) {
		return preAssess.userID;
	}
	return 0;
}


export function selectedGroupID(hierarchy: HierarchySnapshot): number {
	if (!hierarchy) {
		return 0;
	}

	const {mode, classic} = hierarchy;
	if (mode === HierarchyMode.Classic && classic) {
		return classic.groupID;
	}
	return 0;
}


export function selectedSpecialistGroupID(hierarchy: HierarchySnapshot): number {
	if (!hierarchy) {
		return 0;
	}

	const {mode, specialist, preAssess} = hierarchy;
	if (mode === HierarchyMode.Specialist && specialist) {
		return specialist.groupID;
	}
	if (mode === HierarchyMode.PreAssess && preAssess) {
		return preAssess.groupID;
	}
	return 0;
}


export function selectedClassID(hierarchy: HierarchySnapshot): number {
	if (!hierarchy) {
		return 0;
	}

	const {mode, classic} = hierarchy;
	if (mode === HierarchyMode.Classic && classic) {
		return classic.classID;
	}

	return 0;
}
