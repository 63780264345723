import React from 'react';
import StudentProgressReportService
	from 'modules/reports/student-progress-report/services/student-progress-report-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {GradeValue, IdNameModel, Report, SubjectModel} from 'modules/reports/student-progress-report/models';
import styles from './report-settings.module.less';
import {ReportFiltersBody, ReportFiltersRow} from '@esgi/deprecated/ui-kit/report/filters';
import {ShowResultsFilter} from '../common/show-results-filter';
import {DisplayNotTestedFilter} from '../common/display-not-tested-filter';
import {ShowFilter} from 'modules/reports/student-progress-report/components/show-filter';
import ReportDropdown from 'modules/reports/student-progress-report/components/common/report-dropdown';
import {FiltersTextBlock} from 'modules/reports/student-progress-report/components/common/filters-text-block';
import {GradeScaleLegend} from 'modules/reports/student-progress-report/components/common/grade-scales-legend';

interface Props {
	service: StudentProgressReportService;
}

export default function ReportSettings(props: Props) {
	const {service} = props;
	const specialistGroups = useBehaviorSubject<IdNameModel[]>(service.specialistGroups);
	const groups = useBehaviorSubject<IdNameModel[]>(service.groups);
	const classes = useBehaviorSubject<IdNameModel[]>(service.classes);
	const students = useBehaviorSubject<IdNameModel[]>(service.students);
	const subjects = useBehaviorSubject<SubjectModel[]>(service.subjects);
	const teacherName = service.teacherName;
	const selectedClass = useBehaviorSubject<IdNameModel>(service.selectedClass);
	const selectedGroup = useBehaviorSubject<IdNameModel>(service.selectedGroup);
	const selectedSpecialistGroup = useBehaviorSubject<IdNameModel>(service.selectedSpecialistGroup);
	const selectedSubject = useBehaviorSubject<SubjectModel>(service.selectedSubject);
	const selectedStudent = useBehaviorSubject<IdNameModel>(service.selectedStudent);
	const report = useBehaviorSubject<Report>(service.report);
	const emptyReport = useBehaviorSubject<boolean>(service.emptyReport);
	const gradeValues = useBehaviorSubject<GradeValue[]>(service.gradeValues);
	const reportDisplayResultsMode = useBehaviorSubject<string>(service.reportDisplayResultsMode);
	const displayZeroIfNotTested = useBehaviorSubject<boolean>(service.displayZeroIfNotTested);
	const showGradeColors = useBehaviorSubject<boolean>(service.showGradeColors);
	const carryScoresForward = useBehaviorSubject<boolean>(service.carryScoresForward);
	const showBaseline = useBehaviorSubject<boolean>(service.showBaseline);

	const changeSelectedClass = (selectedClass: IdNameModel) => {
		service.changeSelectedClass(selectedClass.id);
	};

	const changeSelectedGroup = (group: IdNameModel) => {
		service.changeSelectedGroup(group.id);
	};
	const changeSelectedSpecialistGroup = (specialistGroup: IdNameModel) => {
		service.changeSelectedSpecialist(specialistGroup.id);
	};
	const changeSelectedSubject = (subject: SubjectModel) => {
		service.changeSelectedSubject(subject.id);
	};

	const changeSelectedStudent = (student: IdNameModel) => {
		service.changeSelectedStudent(student.id);
	};

	const changeDisplayResultsMode = (value: string) => {
		service.changeDisplayResultsMode(value);
	};

	const changeShowGradeColors = (value: boolean) => {
		service.changeShowGradeColors(value);
	};

	const changeCarryScoresForward = (value: boolean) => {
		service.changeCarryScoresForward(value);
	};

	const changeShowBaseline = (value: boolean) => {
		service.changeShowBaseline(value);
	};

	const changeDisplayZeroIfNotTested = (value: boolean) => {
		service.changeDisplayZeroIfNotTested(value);
	};

	return <ReportFiltersBody>
		<ReportFiltersRow className={styles.row}>
			<FiltersTextBlock title={specialistGroups?.length ? 'Specialist: ' : 'Teacher: '}
			                  text={teacherName}/>
			<ReportDropdown title='Class'
			                entities={classes}
			                onSelected={changeSelectedClass}
			                selectedValue={selectedClass} />
			<ReportDropdown title='Group'
			                entities={groups}
			                onSelected={changeSelectedGroup}
			                selectedValue={selectedGroup} />
			<ReportDropdown title='Specialist'
			                entities={specialistGroups}
			                onSelected={changeSelectedSpecialistGroup}
			                selectedValue={selectedSpecialistGroup} />
			<ReportDropdown title='Student'
			                entities={students}
			                onSelected={changeSelectedStudent}
			                selectedValue={selectedStudent} />
			<ReportDropdown title='Subject'
			                entities={subjects}
			                onSelected={changeSelectedSubject}
			                selectedValue={selectedSubject} />
		</ReportFiltersRow>
		{
			!emptyReport && <ReportFiltersRow className={styles.filterRow}>
				{
					report?.gradeScales.length === 1 && <GradeScaleLegend gradeValues={gradeValues} />
				}
				<ShowFilter reportDisplayResultsMode={reportDisplayResultsMode}
				            changeDisplayResultsMode={changeDisplayResultsMode}
				            hasGradeScales={report?.subjectGradeScales?.length > 0}
				            showGradeColors={showGradeColors}
				            changeShowGradeColors={changeShowGradeColors}/>
				<ShowResultsFilter
					onCarryForwardClicked={changeCarryScoresForward}
					onShowBaseLineClicked={changeShowBaseline}
					carryScoresForward={carryScoresForward}
					showBaseline={showBaseline}
				/>
				<DisplayNotTestedFilter
					onClicked={changeDisplayZeroIfNotTested}
					displayZeroIfNotTested={displayZeroIfNotTested}
				/>
			</ReportFiltersRow>
		}
	</ReportFiltersBody>;
}

