import {styled} from '@esgi/ui/theme';
import {BaseButton} from '@esgi/ui';

export const Button = styled(BaseButton, {
	display: 'grid',
	justifyContent: 'start',
	alignItems: 'center',
	gridAutoFlow: 'column',
	gap: 6,
	borderRadius: 6,
	borderStyle: 'dashed',
	borderWidth: 1,
	borderColor: '$mediumContrast',
	boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(230, 232, 239, 0.24)',
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 8,
	paddingRight: 8,
	color: '$neutral20',
	width: '100%',
	marginBottom: 6,

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	'&:hover': {
		color: '$secondary',
		borderColor: '$secondary',
		backgroundColor: '$secondaryBackground',

		'& > span': {
			color: 'currentColor',
		},
	},

	variants: {
		borderType: {
			default: {
				borderStyle: 'dashed',
				borderWidth: 1,
				borderColor: '$mediumContrast',
			},
			secondary: {
				borderStyle: 'solid',
				borderWidth: 1,
				borderColor: '$border',
			},
		},
		disabled: {
			true: {
				borderStyle: 'dashed',
				borderWidth: 1,
				borderColor: '$border',
				cursor: 'default',

				'& > span': {
					color: '$lowContrast',
				},

				'& svg': {
					width: 24,
					height: 24,
					fill: '$lowContrast',

					'& path': {
						fill: '$lowContrast',
					},
				},

				'&:hover': {
					color: '$lowContrast',
					borderColor: '$border',
					backgroundColor: '$background',

					'& > span': {
						color: '$lowContrast',
					},
				},
			},
		},
	},
});