import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {Text} from '@esgi/ui/typography';

export const DeltaContainer = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'start',
	variants: {
		skeleton: {
			true: SkeletonStyles,
		},
	},
});

export const DeltaIndicator = styled(Box, {
	borderRadius: 2,
	padding: '1px 4px',
	height: 14,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',

	variants: {
		color: {
			positive: {
				background: '$positive',
			},
			negative: {
				background: '$negativeVivid',
			},
			neutral: {
				background: '$lowContrast',
			},
		},
	},
});

export const DeltaText = styled(Text, {
	paddingLeft: 6,
});