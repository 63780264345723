import {CentralPanelRoot, Root} from './index.styled';
import {AssignmentState} from '@esgi/main/kits/assignments';
import {useAssignmentId} from '../hooks/use-assignment-id';
import {AssignmentSkeleton} from './components/assignment-variant/assignment-skeleton';
import {AssignmentPublished} from './components/assignment-variant/assignment-published';
import {AssignmentNotStarted} from './components/assignment-variant/assignment-not-started';
import {AssignmentCompleted} from './components/assignment-variant/assignment-completed';
import {AssignmentDraft} from './components/assignment-variant/assignment-draft';
import {LeftPanel} from './components/left-panel';
import {CommonAssignmentProps} from './components/assignment-variant/types';
import {useOpenEditAssignment} from '../hooks/use-open-edit-assignment';
import {useAssignmentActions} from './hooks/use-assignment-actions';
import {AssignmentNotExisted} from './components/assignment-variant/assignment-not-existed';
import {useTeacherAssignmentsContext} from '../layout/outlet-context';
import {SingleStudentCredentialsAlert} from '../student-credentials/single-student-credentials-alert';
import {MultipleStudentsCredentialsAlert} from '../student-credentials/multiple-students-credentials-alert';
import {useAssignmentState} from './hooks/use-assignment-state';
import {ExportStudentsCredentialsAlert} from '../export-students-credentials-alert';
import {isNull} from 'underscore';
import {StudentInstructionsAlert} from './components/student-instructions-alert';
import {useUser} from '@esgi/core/authentication';
import {useCallback} from 'react';
import {Assignment} from '../types';

export default function () {
	const {isAssignmentListLoaded, snackbarRef} = useTeacherAssignmentsContext();

	const currentUser = useUser();

	const selectedAssignmentId = useAssignmentId();
	const openEditAssignment = useOpenEditAssignment();

	const {
		assignment,
		assignmentService,
		studentsWithoutAccess,
		isAssignmentDataLoaded,
		onOpenCredentialsForStudentsInAssignment,
		onOpenStudentCredentials,
		studentsIdsForCredentialsAlert,
		studentsInAssignment,
		onCloseStudentCredentialsAlert,
		closeExportStudentsCredentialsAlert,
		disabledOpenExportStudentsCredentials,
		isOpenExportStudentsCredentialsAlert,
		onOpenExportStudentsCredentialsAlert,
		studentInstructionsAlertState,
		onCloseStudentInstructionsAlertOpened,
		setStudentInstructionsAlertState,
		localStorageService,
	} = useAssignmentState({selectedAssignmentId});

	const {onDeleteAssignment, onAssignmentComplete, onCreateAssignment, onPostNotStartedAssignment} =
		useAssignmentActions({
			assignmentService,
			snackbarRef,
			setStudentInstructionsAlertState,
			localStorageService,
		});

	const onOpenEditAssignment = useCallback(
		(assignmentID: Assignment['id']) => {
			openEditAssignment({
				assignmentId: assignmentID,
				state: {
					from: 'assignment',
				},
				replace: true,
			});
		},
		[openEditAssignment],
	);

	const getAssignmentContent = () => {
		if (!isAssignmentDataLoaded) {
			return <AssignmentSkeleton />;
		}

		if (!assignment && isAssignmentDataLoaded) {
			return <AssignmentNotExisted />;
		}

		if (!assignment) {
			return null;
		}

		const commonAssignmentProps: CommonAssignmentProps = {
			sortStudentsByKey: assignmentService.sortStudentsByKey.bind(assignmentService),
			students: studentsInAssignment,
			assignment: assignment,
			onDeleteAssignment,
			studentsWithoutAccess,
			onOpenExportCredentialsAlert: onOpenExportStudentsCredentialsAlert,
			onOpenStudentCredentials,
			onOpenCredentialsForStudentsInAssignment,
			disabledOpenExportStudentsCredentials,
		};

		switch (assignment.state) {
			case AssignmentState.Draft:
				return (
					<AssignmentDraft
						onAssignmentCreate={onCreateAssignment}
						swapTests={assignmentService.swapTests.bind(assignmentService)}
						onOpenEditAssignment={onOpenEditAssignment}
						{...commonAssignmentProps}
					/>
				);
			case AssignmentState.NotStarted:
				return (
					<AssignmentNotStarted
						onAssignmentPost={onPostNotStartedAssignment}
						onOpenEditAssignment={onOpenEditAssignment}
						swapTests={assignmentService.swapTests.bind(assignmentService)}
						{...commonAssignmentProps}
					/>
				);
			case AssignmentState.Published:
				return <AssignmentPublished onAssignmentComplete={onAssignmentComplete} {...commonAssignmentProps} />;
			case AssignmentState.Completed:
				return <AssignmentCompleted {...commonAssignmentProps} />;

			default:
				return null;
		}
	};

	return (
		<>
			<Root dataCy='assignment-page'>
				<LeftPanel skeleton={!isAssignmentListLoaded} />
				<CentralPanelRoot>{getAssignmentContent()}</CentralPanelRoot>
			</Root>

			{studentsIdsForCredentialsAlert?.length === 1 && (
				<SingleStudentCredentialsAlert
					snackbarRef={snackbarRef}
					studentId={studentsIdsForCredentialsAlert[0]!}
					onAlertClose={onCloseStudentCredentialsAlert}
				/>
			)}

			{studentsIdsForCredentialsAlert && studentsIdsForCredentialsAlert.length >= 2 && (
				<MultipleStudentsCredentialsAlert
					snackbarRef={snackbarRef}
					studentIds={studentsIdsForCredentialsAlert}
					onAlertClose={onCloseStudentCredentialsAlert}
				/>
			)}

			{isOpenExportStudentsCredentialsAlert && (
				<ExportStudentsCredentialsAlert
					studentsIDs={assignment?.students.map(({id}) => id) ?? []}
					onCloseAlert={closeExportStudentsCredentialsAlert}
					type='singleAssignment'
					assignmentName={assignment?.name ?? ''}
				/>
			)}

			{!isNull(studentInstructionsAlertState) && !isNull(currentUser) && (
				<StudentInstructionsAlert
					assignmentName={studentInstructionsAlertState.assignmentName}
					userDistrict={currentUser.districtID}
					onAlertClose={onCloseStudentInstructionsAlertOpened}
					studentsInAssignment={studentsInAssignment}
					onDownloadStudentsCredentials={assignmentService.downloadStudentsCards.bind(assignmentService)}
					onUpdateSHowAgainValue={localStorageService.setBanModal.bind(localStorageService)}
				/>
			)}
		</>
	);
}
