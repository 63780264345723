import {configureStore} from '@reduxjs/toolkit';
import {classesSlice, groupsSlice, subjectsSlice, studentsSlice, State} from './slices';
import {createReactHook} from './react';
import {fetchMiddleware} from './slices/fetch-middleware';
import {serviceAdapter} from './rx-js';
import {EventBusManager} from '@esgillc/events';
import {ClearStorageEvent} from '../storage';
import {Class, Group, Student, SubjectTab, SubjectType} from '../types';
import {TestData} from 'api/entities/events/subject';

export {type State, LoadingState} from './slices';

export const reduxStore = configureStore({
	reducer: {
		classes: classesSlice.slice.reducer,
		groups: groupsSlice.slice.reducer,
		students: studentsSlice.slice.reducer,
		subjects: subjectsSlice.slice.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: {
			ignoreActions: true,
		},
	}),
});

const classesMiddleware = fetchMiddleware(() => reduxStore.dispatch(classesSlice.fetch()));
const groupsMiddleware = fetchMiddleware(() => reduxStore.dispatch(groupsSlice.fetch()));
const studentsMiddleware = fetchMiddleware(() => reduxStore.dispatch(studentsSlice.fetch()));
const subjectsMiddleware = fetchMiddleware(() => reduxStore.dispatch(subjectsSlice.fetch()));

export const useClasses = createReactHook<State<Class>, typeof classesSlice.slice>(classesSlice.slice, classesMiddleware);
export const useGroups = createReactHook<State<Group>, typeof groupsSlice.slice>(groupsSlice.slice, groupsMiddleware);
export const useStudents = createReactHook<State<Student>, typeof studentsSlice.slice>(studentsSlice.slice, studentsMiddleware);
export const useSubjects = createReactHook<State<SubjectTab>, typeof subjectsSlice.slice>(subjectsSlice.slice, subjectsMiddleware);

export const classesStore = serviceAdapter<Class>(classesSlice.slice, reduxStore, classesMiddleware);
export const groupsStore = serviceAdapter<Group>(groupsSlice.slice, reduxStore, groupsMiddleware);
export const studentsStore = serviceAdapter<Student>(studentsSlice.slice, reduxStore, studentsMiddleware);
export const subjectsStore = serviceAdapter<SubjectTab>(subjectsSlice.slice, reduxStore, subjectsMiddleware);

export class ClearSpecificStoragesEvent {
	constructor(public storeNames: ('classes' | 'students' | 'subjects' | 'groups')[]) {
	}
}

export class TestAssignedToSubjectEvent {
	constructor(public subjectID: number,
		public subjectType: SubjectType,
		public testID: number,
		public testData: TestData) {}
}

new EventBusManager().subscribe(ClearStorageEvent, () => {
	reduxStore.dispatch(classesSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(groupsSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(studentsSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(subjectsSlice.slice.actions.invalidateCache());
});

new EventBusManager().subscribe(ClearSpecificStoragesEvent, (args) => {
	if (args.storeNames.includes('classes')) {
		reduxStore.dispatch(classesSlice.slice.actions.invalidateCache());
	}
	if (args.storeNames.includes('students')) {
		reduxStore.dispatch(studentsSlice.slice.actions.invalidateCache());
	}
	if (args.storeNames.includes('subjects')) {
		reduxStore.dispatch(subjectsSlice.slice.actions.invalidateCache());
	}
	if (args.storeNames.includes('groups')) {
		reduxStore.dispatch(groupsSlice.slice.actions.invalidateCache());
	}
});

new EventBusManager().subscribe(TestAssignedToSubjectEvent, (args: TestAssignedToSubjectEvent)=> {
	subjectsStore().update((iteratedSubject) => {
		if (iteratedSubject.id === args.subjectID) {
			return {
				...iteratedSubject,
				tests: iteratedSubject?.tests.map((iteratedTest) =>
					iteratedTest.id === args.testID
						? {
							...iteratedTest,
							...args.testData,
						}
						: iteratedTest,
				),
			};
		}

		return iteratedSubject;
	});
});


export type RootState = ReturnType<typeof reduxStore.getState>;

export type StoreDispatch = typeof reduxStore.dispatch;
