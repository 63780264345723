import {useMemo, useCallback} from 'react';
import {SelectableList} from '@esgi/ui/layout';
import {Session, Test} from '../../../../../../types';
import {SelectableLisItemContent, SelectableLisItemEmptyContent} from '../list-item';
import {SelectableListRoot, SelectableListGroup, SelectableListTrigger, SelectableListTriggerLabel, SelectableListItem, SelectableListTriggerContent, Collapse, TestColorIndicator, IconBefore, IconBox, Counter, DisabledNotice} from './index.styled';
import {noop, isNull} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {Tooltip} from '@esgi/ui';

const getListValue = (value: string | number | null) => isNull(value)
	? ''
	: String(value);

interface Props {
	id: Test['id'];
	name: Test['name'];
	disabledItems: boolean;
	isEmpty: boolean;
	isListOpen: boolean;
	isListSelected: boolean;
	sessions: Session[];
	selectedSessionId: Session['id'][] | null;
	setSelectedSessionId: (value: Session['id']) => void;
	onToggle: VoidFunction;
	onSelectAll: VoidFunction;
}

export function ExpandableSelectableListMultiple({
	id,
	name,
	disabledItems,
	isEmpty,
	isListOpen,
	isListSelected,
	sessions,
	selectedSessionId,
	setSelectedSessionId,
	onToggle,
	onSelectAll,
}: Props) {
	const value = useMemo(
		() =>(selectedSessionId || []).map(getListValue),
		[selectedSessionId],
	);

	const checked = useCallback(
		(id: string) => value.includes(id),
		[value],
	);

	return (
		<SelectableListRoot data-cy='test-item-root' isListOpen={isListOpen}>
			<SelectableList.GroupRoot
				type='multiple'
				value={value}
			>
				<SelectableListTrigger
					openList={isListOpen}
					onClick={onToggle}
					selected={isListSelected}
					withoutStyledButton
				>
					<IconBefore
						data-cy='test-item-checkbox'
						checked={isEmpty ? checked(`${id}-0`) : isListSelected}
						onClick={(event) => {
							event.stopPropagation();
							if (isEmpty) {
								setSelectedSessionId(`${id}-0`);
								return;
							}
							onSelectAll();
						}}
					/>
					<SelectableListTriggerContent>
						<TestColorIndicator testID={id}/>
						<SelectableListTriggerLabel data-cy='test-item-label' size='large'>{name}</SelectableListTriggerLabel>
					</SelectableListTriggerContent>
					<IconBox>
						<Counter data-cy='test-sessions-number'>
							<Tooltip>
								<Tooltip.Trigger>
									<Text font='mono' size='small'>{sessions.length}</Text>
								</Tooltip.Trigger>
								<Tooltip.Content>
									<Text size='small'>{`${sessions.length} Test sessions`}</Text>
								</Tooltip.Content>
							</Tooltip>
						</Counter>
						<Collapse rotate={isListOpen}/>
					</IconBox>
				</SelectableListTrigger>
				<SelectableListGroup>
					{isEmpty
					?	<SelectableListItem
							value={`${id}-0`}
							checked={checked(`${id}-0`)}
							disabled={disabledItems}
							onClick={noop}
						>
							<SelectableLisItemEmptyContent/>
						</SelectableListItem>
					: sessions.map(({id, ...session}) => (
						<SelectableListItem
							key={id}
							value={String(id)}
							setTextCurrentColor={false}
							checked={checked(String(id))}
							disabled={disabledItems}
							onClick={() => {
								if (disabledItems) {
									return;
								}
								setSelectedSessionId(id);
							}}
						>
							<SelectableLisItemContent item={session}/>
						</SelectableListItem>
					))}
					{disabledItems &&
						<DisabledNotice>
							<Text size='medium' font='mono' color='neutral40'>The full set option prints out a single, complete set of all the test questions.</Text>
						</DisabledNotice>
					}
				</SelectableListGroup>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
