import React, {useEffect, useState} from 'react';
import {FormControl} from '@esgi/ui/form';
import {ErrorText, PasswordManagerDisabler} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Eye, Hide} from '@esgi/ui/icons';
import {SingleCheckboxSection} from '../single-checkbox-section';
import {descriptions} from '../../constants';
import {ButtonIcon, PinContainer, FormElementWrapper, PasswordInput} from './index.styled';

interface Props {
	pinControl: FormControl;
	confirmPinControl: FormControl;
	unlockControl: FormControl;
	isUnlocked?: boolean;
}

export function LockHomepage({pinControl, confirmPinControl, unlockControl, isUnlocked}: Props) {

	const [isPinsVisible, setPinsVisible] = useState(false);

	useEffect(() => {
		setPinsVisible(prev => prev && isUnlocked);
	}, [isUnlocked]);

	return <GridBox data-cy='lock-homepage-section' gapY={3}>
		<SingleCheckboxSection
			checkboxControl={unlockControl}
			isDisabled={!isUnlocked}
			description={descriptions.lockHomepage}
			dataCy='lock-homepage-checkbox'
		/>

		<PinContainer>
			<FormElementWrapper disableValidateOnBlur control={pinControl}>
				<PasswordInput data-cy='lock-homepage-pin-input' placeholder='Pin' type='text' hide={!isPinsVisible}/>
				<ErrorText showOnError='length-min'>
					Pin must be 4 numbers
				</ErrorText>
				<ErrorText showOnError='length-max'>
					Pin must be 4 numbers
				</ErrorText>
				<ErrorText showOnError='pinsNotEqual'>
					Pins do not match
				</ErrorText>
			</FormElementWrapper>

			<FormElementWrapper disableValidateOnBlur control={confirmPinControl}>
				<PasswordInput data-cy='lock-homepage-pin-confirmation-input' placeholder='Confirm Pin' type='text' hide={!isPinsVisible}/>
				<ErrorText showOnError='length-min'>
					Pin must be 4 numbers
				</ErrorText>
				<ErrorText showOnError='length-max'>
					Pin must be 4 numbers
				</ErrorText>
			</FormElementWrapper>
			<ButtonIcon dataCy='set-pins-visible-button' onClick={() => setPinsVisible(prev => !prev)} disabled={!isUnlocked}>
				{isPinsVisible ? <Eye width={24} height={24}/> : <Hide width={24} height={24}/>}
			</ButtonIcon>
		</PinContainer>
	</GridBox>;
}
