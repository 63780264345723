import './bubble-select.less';
import React from 'react';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {IFormControlValidatorResult, Validators} from './form-controls';
import {Checkbox} from './checkbox';

class Props {
    values: InModel[] = [];
    alternativeValues?: InModel[];
    validator?: (value: any) => IFormControlValidatorResult;
    selectedValues: number[] = [];
    onSelect: (values: number[]) => void;
    onRemove: (value: number) => void;
    className?: string;
    label: string;
    disabled?: boolean;
}

export class InModel {
    id: number;
    value: string;

    constructor(id: number, value: string) {
    	this.id = id;
    	this.value = value;
    }
}

class State {
    opened: boolean;
    checkedValues: number[] = [];
    touched: boolean = false;
    validationResult: IFormControlValidatorResult;
}

export class BubbleSelect extends React.PureComponent<Props, State> {
    private html: HTMLElement;

    constructor(props) {
    	super(props);
    	this.state = new State();
    }

    onSelectValue(id: number) {
    	let newState = [...this.state.checkedValues];
    	let selected = newState.filter(x => x === id)[0];
    	if (selected) {
    		newState = newState.filter(x => x !== id);
    	} else {
    		newState.push(id);
    	}

    	this.setState({
    		checkedValues: newState,
    	});
    }

    toogleList() {
    	let values = [...this.state.checkedValues];
    	this.setState((prevState) => {
    		return {
    			opened: !prevState.opened,
    			checkedValues: [],
    		};
    	}, () => this.props.onSelect(values));
    }

    remove(e: any, id: number) {
    	e.stopPropagation();
    	this.setState({
    		opened: false,
    		checkedValues: [],
    	}, () => this.props.onRemove(id));
    }

    validate() {
    	return this.props.validator ? this.props.validator(this.props.selectedValues) : Validators.successfulValidation;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, prevContext: any): void {
    	let validationResult = this.validate();
    	if (validationResult.valid != prevState.validationResult.valid) {
    		this.setState({
    			validationResult: validationResult,
    		});
    	}
    }

    componentDidMount() {
    	let validationResult = this.validate();
    	this.setState({
    		validationResult: validationResult,
    	});
    	document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    componentWillUnmount() {
    	document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    handleClickOutside(event) {
    	if (this.html && !this.html.contains(event.target)) {
    		if (this.state.opened) {
    			this.setState({
    				touched: true,
    			});
    			this.toogleList();
    		}
    	}
    }

    renderSelectedValues() {
    	let values = this.props.alternativeValues || this.props.values;
    	let selectedValues = this.props.selectedValues ?? [];

    	if (values.length > 0) {
    		return values.filter(x => selectedValues.some(c => c === x.id)).map((v) => {
    			return <div className='bubble-item' key={v.id}>{v.value}
                    {!this.props.disabled &&
                    <span onClick={(e) => this.remove(e, v.id)} className='bubble-close'></span>
                    }</div >;
    		});
    	}
    }

    getClass() {
    	let cl = 'bubble-select ';
    	if (this.props.className) {
    		cl += this.props.className + ' ';
    	}
    	if (!this.state.validationResult.valid && this.state.touched) {
    		cl += 'has-error';
    	}
    	return cl;
    }
    render() {

    	const selectedValues = this.props.selectedValues ?? [];

    	return <div className='bubble' ref={r => this.html = r}>
            <div className={this.getClass()} onClick={() => (!this.props.disabled && (this.state.opened || this.props.selectedValues.length > 0)) && this.toogleList()}>
                {this.renderSelectedValues()}
                {(selectedValues.length === 0 && !this.props.disabled) && <div onClick={() => this.toogleList()} className='add-buble-item'>
                    <div className='icon-container'><svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'><path d='M9.83332 4.83332H8.16666V8.16666H4.83332V9.83332H8.16666V13.1667H9.83332V9.83332H13.1667V8.16666H9.83332V4.83332ZM8.99999 0.666656C4.39999 0.666656 0.666656 4.39999 0.666656 8.99999C0.666656 13.6 4.39999 17.3333 8.99999 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99999C17.3333 4.39999 13.6 0.666656 8.99999 0.666656ZM8.99999 15.6667C5.32499 15.6667 2.33332 12.675 2.33332 8.99999C2.33332 5.32499 5.32499 2.33332 8.99999 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 12.675 12.675 15.6667 8.99999 15.6667Z' fill='#0088CC'></path></svg></div>
                    <div className='text-container'>{this.props.label}</div></div>}
            </div>
            {(this.state.opened && this.props.values.length !== selectedValues.length) && <div className='bubble-list'>
                {this.props.values.filter(x => selectedValues.every(c => c !== x.id)).map(x => {
                    return <div className='bubble-list-item' key={x.id}>
                        <Checkbox
                            label={x.value}
                            checked={this.state.checkedValues.some(c => c === x.id)}
                            id={'bubble-item-' + x.id}
                            onClick={(e) => this.onSelectValue(x.id)}
                            className='bubble-checkbox'
                        />
                    </div>;
                })}
            </div>}
            {(this.html && this.state.touched && !this.state.validationResult.valid) &&
            <ValidationTooltip element={this.html} placement='right' container='body' notHide={true}>
                {this.state.validationResult.message}
            </ValidationTooltip>}
        </div>;
    }
}