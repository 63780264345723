import {SVGProps} from 'react';

export const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='15'
		height='11'
		viewBox='0 0 15 11'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M7.93994 0.875C4.75812 0.875 2.04085 2.85409 0.939941 5.64773C2.04085 8.44136 4.75812 10.4205 7.93994 10.4205C11.1218 10.4205 13.839 8.44136 14.9399 5.64773C13.839 2.85409 11.1218 0.875 7.93994 0.875ZM7.93994 8.82955C6.18358 8.82955 4.75812 7.40409 4.75812 5.64773C4.75812 3.89136 6.18358 2.46591 7.93994 2.46591C9.69631 2.46591 11.1218 3.89136 11.1218 5.64773C11.1218 7.40409 9.69631 8.82955 7.93994 8.82955ZM7.93994 3.73864C6.88358 3.73864 6.03085 4.59136 6.03085 5.64773C6.03085 6.70409 6.88358 7.55682 7.93994 7.55682C8.9963 7.55682 9.84903 6.70409 9.84903 5.64773C9.84903 4.59136 8.9963 3.73864 7.93994 3.73864Z'
			fill='#424242'
		/>
	</svg>
);
