import {EntityType, GradeRange, TestInfo, TestSession, TrackDate} from '../../../types';
import {BodyStats} from '../body-stats';
import {TestHistorySection} from '../../../sections/test-history';
import {ItemAnalysisSection} from '../../../sections/item-analysis';
import {ProgressSection} from '../../../sections/progress-section';
import {SessionsSection} from '../../../sections/sessions';
import {EmptyContent} from '../../../kit';
import {Container, EmptyDataContainer} from './index.styled';
import {Class, Student} from '@esgi/main/libs/store';

type Props = {
	isDataLoaded: boolean;
	testSessions: TestSession[];
	testInfo: TestInfo | null;
	gradeRanges: GradeRange[];
	trackDates: TrackDate[];
	studentClassID: Class['id'];
	studentID: Student['id'];
	studentFirstName: string;
	studentLastName: string;
	classCorrectAnswersAvg: number;
	isTablet: boolean;
};

export function StudentStats({
	isDataLoaded,
	testSessions,
	testInfo,
	gradeRanges,
	trackDates,
	studentClassID,
	studentFirstName,
	studentID,
	studentLastName,
	classCorrectAnswersAvg,
	isTablet,
}: Props) {
	if (!isDataLoaded) {
		return (
			<EmptyDataContainer>
				<EmptyContent templateText='Loading...' />
			</EmptyDataContainer>
		);
	}

	if (!testSessions.length) {
		return (
			<EmptyDataContainer>
				<EmptyContent templateText='No previous test data available for selected student' />
			</EmptyDataContainer>
		);
	}

	return (
		<Container>
			<BodyStats
				testType={testInfo.testType}
				testSessions={testSessions}
				gradeRanges={gradeRanges}
				rubricCriteriaCount={testInfo.rubricCriteria.length}
				totalPossible={testInfo.totalPossible}
				questionsCount={testInfo.questions.length}
				classCorrectAnswersAvg={classCorrectAnswersAvg}
				isTablet={isTablet}
			/>
			<TestHistorySection
				entityType={EntityType.Student}
				trackDates={trackDates}
				testSessions={testSessions}
				testInfo={testInfo}
				isTablet={isTablet}
			/>
			<ItemAnalysisSection entityType='student' testInfo={testInfo} testSessions={testSessions} isTablet={isTablet} />
			<ProgressSection
				trackDates={trackDates}
				testInfo={testInfo}
				testSessions={testSessions}
				gradeRanges={gradeRanges}
				isTablet={isTablet}
			/>
			<SessionsSection
				entityType='student'
				testSessions={testSessions}
				testInfo={testInfo}
				studentClassID={studentClassID}
				studentFirstName={studentFirstName}
				studentLastName={studentLastName}
				studentID={studentID}
				isTablet={isTablet}
			/>
		</Container>
	);
}
