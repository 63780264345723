export function ExclamationMarkWarningIcon() {
	return <svg data-cy={'warning-icon'}
							width='17'
							height='15'
							viewBox='0 0 17 15'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M0 14.25H16.5L8.25 0L0 14.25ZM9 12H7.5V10.5H9V12ZM9 9H7.5V6H9V9Z'
					fill='#FEC602'
		/>
	</svg>;
}