import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import React from 'react';
import {TextInput, Field, IFormControlValidatorResult, Validators} from '@esgi/deprecated/elements';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	SharedProps,
} from '@esgi/deprecated/react';
import {Api} from '../../../api';
import {Events} from '../../../models';
import {State as FoldersHierarchySelectState, FoldersHierarchy} from '../../folders-hierarchy/folders-hierarchy';
import {IGModal} from '../modal';

export class State {
	constructor(public currentFolderID: number, public folderID: number, folderName: string) {
		this.folderName = new Field(folderName);
	}

	movable: boolean = true;
	loading: boolean = false;
	folderNameTouched: boolean = false;
	folderName: Field;
	locationSelect: FoldersHierarchySelectState = new FoldersHierarchySelectState();
}

export class Props extends SharedProps<State> {
	close: () => any;
}

export class EditFolderModal extends IGModal<State, Props> {
	private readonly maxLen = 25;
	inputRef: TextInput;

	componentDidMount(): void {
		super.componentDidMount();
		Api.InitEditFolder(this.state.folderID).subscribe(r => this.setState({movable: r.movable}));
	}

	validator(value: string): IFormControlValidatorResult {
		const rqResult = Validators.requriedValidator(value);
		if (!rqResult.valid) {
			return rqResult;
		}

		const lvResult = Validators.maxLengthValidator(value, this.maxLen);
		if (!lvResult.valid) {
			return lvResult;
		}

		return {valid: true, message: ''};
	}

	onEnter = () => this.save();

	render(): JSX.Element | false | null {
		return <Modal className='white-header edit-folder-modal ig-inner-modal base-ig-modal' animate={true}
		              onCatchError={() => this.props.close()}>
			<ModalHeader title='Edit Folder'/>
			<ModalBody>
				<div className='content form-group'>
					<TextInput label='Folder Name' ref={r => this.inputRef = r}
					           value={this.state.folderName.value}
					           maxLength={this.maxLen}
					           touched={this.state.folderNameTouched}
					           onEdit={(value, validation) => {
						           this.setState({
							           folderName: {
								           ...this.state.folderName,
								           value: value,
								           validation: validation,
							           },
							           folderNameTouched: true,
						           });
					           }}
					           validator={(value: string) => this.validator(value)}/>
					<div className='limit-counter'>{this.maxLen - this.state.folderName.value.length} characters left
						({this.maxLen} max)
					</div>
					<FoldersHierarchy state={this.state.locationSelect}
					                  label={'Location'}
					                  disabled={!this.state.movable}
					                  disableLastLevel={true}
					                  textWhenDisabled={'Sorry. You can only move folders that do not have sub-folders'}
					                  excludeFolderID={this.state.folderID}
					                  initialFolderID={this.state.currentFolderID}
					                  onChange={(ch) => this.setState({locationSelect: ch})}/>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => this.props.close()}
					title='CANCEL'
					className='btn-bold'
				/>
				<Primary
					onClick={() => this.save()}
					title='SAVE'
					className='btn-bold'
				/>
			</ModalFooter>
		</Modal>;
	}

	save(): void {
		if (this.state.folderName.validation.valid) {
			if (this.state.folderNameTouched || this.state.currentFolderID !== this.state.locationSelect.selected.value) {
				this.setState({loading: true});
				Api.EditFolder(this.state.folderID, this.state.locationSelect.selected.value, this.state.folderName.value).subscribe(r => {
					this.props.close();
					if (this.state.currentFolderID !== this.state.locationSelect.selected.value) {
						this.dispatch(Events.ItemsMoved, {ids: [this.state.folderID]});
					} else {
						this.dispatch(Events.ItemPropertyChanged, {id: this.state.folderID});
					}
				});
			} else {
				this.props.close();
			}
		} else {
			this.inputRef.ref.focus();
			this.setState({
				folderName: {
					...this.state.folderName,
					validation: this.validator(this.state.folderName.value),
				},
				folderNameTouched: true,
			});
		}
	}
}