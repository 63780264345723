import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const IndicatorBox = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 2,
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
});
