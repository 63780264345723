import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';
import {Input} from '@esgi/ui/controls';

export const SearchInput = styled(Input.Iconable, {
	width: '270px',
});

export const SearchWrapper = styled(FlexBox, {
	padding: '20px 0 0 20px',
});

export const Layout = styled(FlexBox, {
	width: '100%',
	padding: 20,
	justifyContent: 'space-between',
});

export const FiltersContainer = styled(GridBox, {
	width: '100%',
	gap: 12,
	gridAutoFlow: 'column',
	overflow: 'hidden',
});

export const ActionsContainer = styled(FlexBox, {
	height: '100%',
	alignItems: 'center',
	marginLeft: 12,
});

export const UnassignButton = styled(Button, {
	marginLeft: 12,
	marginRight: 12,
});

export const DeleteButton = styled(Button, {
	marginRight: 12,
});

export const Divider = styled(Box, {
	height: '20px',
	borderRadius: '1px',
	borderRight: '1.5px solid $muted',
});