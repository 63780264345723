import {Close} from '@esgi/ui/icons';
import {Cancel} from '@radix-ui/react-alert-dialog';
import {Button} from 'libs/ui/src/buttons';
import {ComponentProps} from 'react';

type AlertCloseIconProps = Omit<ComponentProps<typeof Button.Icon>, 'children' | 'skeleton'>;

export function AlertCloseIcon({dataCy = 'ui-kit-alert-close-icon', ...props}: AlertCloseIconProps) {
	return (
		<Cancel asChild>
			<Button.Icon dataCy={dataCy} {...props}>
				<Close />
			</Button.Icon>
		</Cancel>
	);
}
