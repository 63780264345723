import {TableStudentsRow} from '../components.styled';
import {HeaderCellId} from '../../types';
import {useCallback, useEffect, useState} from 'react';
import {AccessAll} from '@esgi/ui/icons';
import {useSortableCells} from './use-sortable-cells';
import {Box} from '@esgi/ui/layout';
import {Button, Tooltip} from '@esgi/ui';
import {SortDirection, TableHeaderCellSortable} from '@esgi/main/kits/assignments';
import {Text} from '@esgi/ui/typography';

type Props = {
	studentsCount: number;
	disableSortingCells: HeaderCellId[] | 'all';
	sortStudentsData: (args: {sortableCellId: HeaderCellId; sortDirection: SortDirection}) => void;
	testsCount: number;
	onOpenCredentialsForStudentsInAssignment: VoidFunction;
};

export function TableStudentsHeader({
	studentsCount,
	disableSortingCells,
	sortStudentsData,
	testsCount,
	onOpenCredentialsForStudentsInAssignment,
}: Props) {
	const withTestsTakenBar = testsCount >= 1;

	const sortableCellItems = useSortableCells({withTestsTakenBar});

	const [sortDirection, setSortDirection] = useState(SortDirection.Asc);

	const [activeCellId, setActiveCellId] = useState<HeaderCellId | null>(null);

	useEffect(() => {
		if (disableSortingCells === 'all') {
			setActiveCellId(null);
			return;
		}

		const initialSortableKey = sortableCellItems.find(({cellKey}) => !disableSortingCells.includes(cellKey))?.cellKey;

		if (!initialSortableKey) {
			setActiveCellId(null);
			return;
		}

		sortStudentsData({sortableCellId: initialSortableKey, sortDirection});

		setActiveCellId(initialSortableKey);
	}, []);

	const handleCellClick = useCallback(
		({cellKey, nextSortDirection}: {cellKey: HeaderCellId; nextSortDirection: SortDirection}) => {
			setActiveCellId(cellKey);
			setSortDirection(nextSortDirection);

			sortStudentsData({sortDirection: nextSortDirection, sortableCellId: cellKey});
		},
		[sortStudentsData],
	);

	return (
		<TableStudentsRow dataCy='table-students-header'>
			{sortableCellItems.map(({cellKey, cellName, justifyContent, permitted}, index) =>
				permitted ? (
					<TableHeaderCellSortable
						cellKey={cellKey}
						cellName={cellName}
						isActive={activeCellId === cellKey}
						onCellClick={handleCellClick}
						sortDirection={sortDirection}
						justifyContent={justifyContent}
						disableSorting={
							studentsCount <= 1 || disableSortingCells === 'all' || disableSortingCells.includes(cellKey)
						}
						key={index}
					/>
				) : (
					<Box />
				),
			)}

			{studentsCount > 1 ? (
				<Tooltip>
					<Tooltip.Trigger>
						<Button.Icon withBackgroundHover onClick={onOpenCredentialsForStudentsInAssignment}>
							<AccessAll width={24} height={24} />
						</Button.Icon>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>Add, edit, or view student credentials</Text>
					</Tooltip.Content>
				</Tooltip>
			) : (
				<Box />
			)}
		</TableStudentsRow>
	);
}
