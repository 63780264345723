import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox, SelectableList} from '@esgi/ui/layout';
import {Users} from '@esgi/ui';
import {Avatar} from '@esgi/ui/avatar';

export const Container = styled(Box, {
	height: '100%',
	overflow: 'hidden',
});

export const AvatarContainer = styled(FlexBox, {
	height: 'max-content',
});

export const ListLabelContainer = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	alignItems: 'center',

	'& svg': {
		fill: '$primary',

		'& path': {
			fill: '$primary',
		},
	},
});

export const Divider = styled(Box, {
	borderBottom: '1px solid $mild',
	margin: '12px 0',
});

export const ListItem = styled(SelectableList.Item, {
	marginTop: 8,
	marginBottom: 8,

	'&:first-child': {
		marginTop: 0,
	},

	'&:last-child': {
		marginBottom: 0,
	},
});

export const UserIcon = styled(Users, {
	marginLeft: 6,

	'& path': {
		fill: '$lowContrast',
	},
});

export const ContentBlock = styled(Box, {
	marginTop: 32,
});

export const GroupBlock = styled(Box, {
	'& + &': {
		marginTop: 12,
	},
});

export const AvatarRoot = styled(Avatar.Root, {
	flexShrink: 0,
});
