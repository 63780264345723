import {firstValueFrom, map} from 'rxjs';
import {V2TeachersStorageController} from '@esgi/contracts/esgi';
import {createStoreSlice} from './create-slice';

export * from './types';

export const classesSlice = createStoreSlice(
	'classes',
	() => firstValueFrom(new V2TeachersStorageController().classes().pipe(map(r => r.classes))),
	{
		byID: (state, arg: number) => state.data.filter(c => c.id === arg),
		byStudentID: (state, arg: number) => state.data.filter(c => c.studentIDs.includes(arg)),
	}
);

export const groupsSlice = createStoreSlice(
	'groups',
	() => firstValueFrom(new V2TeachersStorageController().groups().pipe(map(r => r.groups))),
);

export const studentsSlice = createStoreSlice(
	'students',
	() => firstValueFrom(new V2TeachersStorageController().students().pipe(map(r => r.students))),
);

export const subjectsSlice = createStoreSlice(
	'subjects',
	() => firstValueFrom(new V2TeachersStorageController().subjects().pipe(map(r => r.subjects))),
);