import React from 'react';
import {EventBusManager} from '@esgillc/events';
import {AppContext, AppContextProvider} from '../application-context';
import {debounce} from '@esgi/deprecated/knockout';

type ObserveProxy<T> = {
	[P in keyof T]?: (prev: any, next: any) => any;
};

type ChangeProxy<T> = {
	[P in keyof T]?: { before: any, next: any };
}

export abstract class SharedProps<S> {
	state: S;
	onChange: (ch: any, callback: () => any) => any;
}

export abstract class SharedComponent<S = any, P extends SharedProps<S> = SharedProps<S>> extends React.Component<P, S> {

	static contextType = AppContextProvider;
	context: AppContext;
	protected eventBusManager: EventBusManager;

	private copyState: S;
	private changes: { props: ChangeProxy<P>, state: ChangeProxy<S> };

	// version variable is used to count how many times setState was called
	// this variable then is stored in React snapshot
	// when componentDidUpdate is called, the variable is compared to what is stored in snapshot
	// if variables are not equal, that means that setState was called and that state has not yet applied
	// this is where we potentially may lose the state
	// because in componentDidMount copyState is set to this.state, effectively copyState loses changes made during the last setState
	// and if another setState comes before the last one has been applied, the state will be lost
	private version: number = 0;

	public constructor(props?: P, context?: AppContext) {
    	super(props, context);
    	if(!context) {
    		this.context = new AppContext();
    	}
    	this.eventBusManager = new EventBusManager();
    	if (this.props) {
    		this.state = this.props.state;
    		this.copyState = this.props.state;
    	}
	}
    
	public abstract render(): JSX.Element | false | null;

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
    	if (nextProps.state) {
    		return nextProps.state;
    	} else {
    		return null;
    	}
	}

	observe: { props?: ObserveProxy<P>, state?: ObserveProxy<S> } = undefined;
    
	//#region Events
	dispatch<T>(event: T, args: any) {
    	this.eventBusManager.dispatch(event, args);
	}

	subscribe(event: any, callback: Function) {
    	this.eventBusManager.subscribe(event, callback);
	}
	//#endregion Events
    
	//#region Lifecycle Hooks
	getSnapshotBeforeUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): any | null {
    	// save version before update, so that we could compare it in ComponentDidMount
    	return this.version;
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
    	// if versions do not match, then setState has come that has not yet been applied, then we don't want to override copyState in case new setState comes in
    	if (this.version == snapshot) {
    		if (this.props.onChange) {
    			this.copyState = this.props.state;
    		} else {
    			this.copyState = undefined;
    		}
    	}

    	if (this.observe && this.changes) {
    		if (this.observe.props && this.changes.props) {
    			for (let prop in this.observe.props) {
    				let change = this.changes.props[prop];
    				if (change) {
    					this.observe.props[prop](change.before, change.next);
    				}
    			}
    		}

    		if (this.observe.state && this.changes.state) {
    			for (let prop in this.observe.state) {
    				let change = this.changes.state[prop];
    				if (change) {
    					this.observe.state[prop](change.before, change.next);
    				}
    			}
    		}
    	}

    	this.changes = undefined;
	}

	shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
    	const propChanges = this.collectChanges(this.props, nextProps);
    	const stateChanges = this.collectChanges(this.state, nextState);

    	if (propChanges || stateChanges) {
    		this.changes = {props: propChanges, state: stateChanges};
    		return true;
    	}

    	// this is dirty hack for developers who cannot obey rules and update state using more than one dot.
    	return this.state != nextState;
	}

	componentWillUnmount(): void {
    	try {
    		this.eventBusManager.destroy();
    	} catch (e) {
    		throw e;
    	}
	}
	//#endregion Lifecycle Hooks
    
	//#region State handling
	private collectChanges<T>(before: T, next: T): ChangeProxy<T> {
    	let change: ChangeProxy<T> = undefined;

    	for (let prop in next) {
    		if (prop === 'state') {
    			continue;
    		}

    		if (next.hasOwnProperty(prop)) {
    			if (!this.equal(before[prop], next[prop])) {
    				if (typeof before[prop] !== 'function') {
    					if (!change) {
    						change = {};
    					}

    					change[prop] = ({before: before[prop], next: next[prop]});
    				}
    			}
    		}
    	}

    	return change;
	}

	private callbackChunks: any[] = [];

	private unwrapState(prevState: S, prevProps: P, chunk: any) {
    	if (typeof chunk === 'function') {
    		return chunk(prevState, prevProps);
    	}

    	return chunk;
	}

	setState<K extends keyof S>(state: ((prevState: Readonly<S>, props: P) => (Pick<S, K> | S)) | Pick<S, K> | S | Partial<S>, callback?: () => any): void {
    	this.version++;

    	let stateChunk = this.unwrapState(this.copyState, this.props, state);
    	this.copyState = {...this.copyState, ...stateChunk};

    	this.updateState(this.copyState, callback);
	}

	private updateState<K extends keyof S>(state: Pick<S, K> | S, callback?: () => any): void {
    	if (this.props.onChange) {
    		this.props.onChange(state, callback);
    	} else {
    		if (callback) {
    			this.callbackChunks.push(callback);
    		}

    		this.updateReactState(state, callback, this.version);
    	}
	}

	@debounce()
	private updateReactState<K extends keyof S>(state: Pick<S, K> | S, callback: () => any, version: number) {
    	let callbacks = this.callbackChunks;
    	this.callbackChunks = [];

    	super.setState(state, () => {
    		for (let i = 0; i < callbacks.length; i++) {
    			let callback = callbacks[i];
    			callback();
    		}
    	});
	}
	//#endregion State handling
    
	private equal<T>(a: T, b: T) {
    	if (Array.isArray(a) && Array.isArray(b)) {
    		if (a === b) {
    			return true;
    		}
    		if (a == null || b == null) {
    			return false;
    		}
    		if (a.length != b.length) {
    			return false;
    		}

    		// If you don't care about the order of the elements inside
    		// the array, you should sort both arrays here.
    		// Please note that calling sort on an array will modify that array.
    		// you might want to clone your array first.

    		for (let i = 0; i < a.length; ++i) {
    			if (a[i] !== b[i]) {
    				return false;
    			}
    		}
    		return true;
    	}

    	return a === b;
	}

	protected extend<T>(obj: any, target: T, source: Partial<T>): T {
    	if (target === source) {
    		return target;
    	}

    	if (typeof source === 'string') {
    		return source;
    	}

    	if (typeof source === 'boolean') {
    		return source;
    	}

    	if (typeof source === 'number') {
    		return source;
    	}

    	if (typeof source === 'function') {
    		return source;
    	}

    	if (source === null) {
    		return null;
    	}

    	if (typeof source === 'undefined') {
    		return undefined;
    	}

    	if (Array.isArray(source) || Array.isArray(target)) {
    		return source as T;
    	}

    	for (let prop in target) {
    		if (target.hasOwnProperty(prop)) {
    			obj[prop] = target[prop];
    		}
    	}

    	for (let prop in source) {
    		if (source.hasOwnProperty(prop)) {
    			if (!obj[prop]) {
    				obj[prop] = source[prop];

    			} else if (obj[prop] !== source[prop]) {
    				obj[prop] = this.extend({}, obj[prop] as any, source[prop] as any);
    			}
    		}
    	}

    	return obj;
	}
}

export function extendObject<T>(obj: any, target: T, source: Partial<T>): T {
	if (target === source) {
		return target;
	}

	if (typeof source === 'string') {
		return source;
	}

	if (typeof source === 'boolean') {
		return source;
	}

	if (typeof source === 'number') {
		return source;
	}

	if (typeof source === 'function') {
		return source;
	}

	if (source === null) {
		return null;
	}

	if (typeof source === 'undefined') {
		return undefined;
	}

	if (Array.isArray(source) || Array.isArray(target)) {
		return source as T;
	}

	for (let prop in target) {
		if (target.hasOwnProperty(prop)) {
			obj[prop] = target[prop];
		}
	}

	for (let prop in source) {
		if (source.hasOwnProperty(prop)) {
			if (!obj[prop]) {
				obj[prop] = source[prop];

			} else if (obj[prop] !== source[prop]) {
				obj[prop] = extendObject({}, obj[prop] as any, source[prop] as any);
			}
		}
	}

	return obj;
}