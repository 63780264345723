import {Dispatch, useCallback, useLayoutEffect, useRef, useState} from 'react';
import {isUndefined} from '../is-undefined';

export function useAutoControlledState<T>({
	initialState,
	controlledState,
	onChange,
}: {
	initialState: T;
	controlledState?: T | undefined;
	onChange?: ((value: T) => void) | undefined;
}): [T, Dispatch<T>] {
	const [state, setState] = useState(initialState);
	const controlledStateRef = useRef(controlledState);

	controlledStateRef.current = controlledState;

	const setValue = useCallback(
		(value: T) => {
			setState(prev => {
				if(prev !== value){
					onChange?.(value);
					return value;
				}
				return prev;
			});
		},
		[onChange],
	);

	const trySetState = useCallback((value: T) => {
		isUndefined(controlledStateRef.current) ? setValue(value) : onChange?.(value);
	}, [onChange, setValue]);

	useLayoutEffect(() => {
		!isUndefined(controlledState) && setValue(controlledState);
	}, [controlledState, setValue]);

	return [controlledState ?? state, trySetState];
}
