import {HttpClient} from '@esgi/api';
import {useEffect, useMemo} from 'react';
import {environment} from '@esgi/core/environments';
import {Service} from './service';
import {Disposable} from './types';
import {Observable} from 'rxjs';

export class BaseService extends Service<HttpClient> {
	public static httpClientBuilder: () => HttpClient = () => new HttpClient([], environment);

	protected override createHttpClient(): HttpClient {
		return BaseService.httpClientBuilder();
	}

	override dispose() {
		super.dispose();
	}
}

export function useServiceFactory<T extends Service>(factory: () => T): T {
	const service = useMemo(factory, []);
	useEffect(() => () => service?.destroy(), []);
	return service;
}

export function useService<T extends Service>(serviceClass: {new(): T}): T {
	const service = useMemo(() => new serviceClass(), []);
	useEffect(() => () => service?.destroy(), []);
	return service;
}

export function useDisposable<T extends Disposable>(disposableClass: {new(): T}): T {
	const service = useMemo(() => new disposableClass(), []);
	useEffect(() => () => service?.dispose(), []);
	return service;
}

export function useObservable<T>(factory: () => Observable<T>, next: (value: T) => any, deps: any[]) {
	useEffect(() => {
		const sub = factory().subscribe((res) => next(res));
		return () => sub.unsubscribe();
	}, deps);
}