import {OverflowSpan} from '@esgillc/ui-kit/layout';
import React from 'react';
import {debounce} from '@esgi/deprecated/knockout';
import {TeAds} from '../te-ads/te-ads';
import {userStorage} from '@esgi/core/authentication';

export class Props {
	limitToSelectedSubjectId: boolean = false;
	scope: string;
	selectedSubjectID: number;
	selectedContentAreaID: number;
	subjects: {id: number, name: string}[] = [];
	contentAreas: {id: number, name: string}[] = [];
	onScopeChanged: (value: string) => void;
	onKeywordChanged: (value: string) => void;
	onSubjectChanged: (id: number) => void;
	onContentAreaChanged: (id: number) => void;
}

export class Filter extends React.Component<Props> {

	scopes = [];

	constructor(props: any) {
		super(props);

		this.scopes.push(
			{name: 'All Tests', value: 'alltests'},
			{name: 'My Tests', value: 'mytests'},
			{name: 'District Tests', value: 'districttests'},
		);
    if (userStorage.get().showSelfAssessOption){
			this.scopes.push({name: 'Self-Assess', value: 'selfassesstests'});
		}
		this.scopes.push(
			{name: 'Rubric', value: 'rubric'},
			{name: 'Single Score', value: 'score'},
		);
	}

	get selectedSubject() {
		return this.props.subjects.find(s => s.id === this.props.selectedSubjectID);
	}

	render(): JSX.Element | false | null {
		return <form className='form-horizontal filter bonnie' onSubmit={(e) => e.preventDefault()}>
			<div className='te-ads-wrapper'>
				<TeAds/>
			</div>
			<div className='form-group'>
				<label className='control-label'>Add to Subject Tab:</label>
				<div className='subject-list'>
					{!this.props.limitToSelectedSubjectId
						? <select className='form-control subject-dropdown'
						          value={this.props.selectedSubjectID}
						          onChange={(e) => this.props.onSubjectChanged(parseInt(e.target.value))}>
							{this.props.subjects.map(s => {
								return <option key={s.id} value={s.id}>{s.name}</option>;
							})}
						</select>
						: <OverflowSpan className={'control-label pt-0'}>
							{this.selectedSubject ? this.selectedSubject.name : ''}
						</OverflowSpan>}
				</div>
			</div>

			<div className='row last-row'>
				<div className='col-sm-5 col-xs-12 search-input-wrapper'>
					<svg className='icon' xmlns='http://www.w3.org/2000/svg' width='18' height='18'
					     viewBox='0 0 18 18'
					     fill='none'>
						<path
							d='M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z'
							fill='#828282'/>
					</svg>
					<input className='form-control filter-input' placeholder='Enter test name or author'
					       onChange={(ch) => {
						       ch.preventDefault();
						       this.handleKeywordChange(ch.target.value);
					       }}/>
				</div>
				<div className='col-sm-7 col-xs-12 double-dropdown '>

					<label className='control-label filter-label xs-invisible'>Filter By:</label>
					<label className='control-label xs-visible'>Scope: </label>
					<select className='form-control scope scope-dropdown' value={this.props.scope}
					        onChange={(e) => this.props.onScopeChanged(e.target.value)}>
						<option disabled value=''>Test Type</option>
						{this.scopes.map(s => {
							return <option key={s.value} value={s.value}>{s.name}</option>;
						})}
					</select>
					<label className='control-label xs-visible'>Content Area: </label>
					<select className='form-control content-area content-area-dropdown'
					        value={this.props.selectedContentAreaID}
					        onChange={(e) => this.props.onContentAreaChanged(parseInt(e.target.value))}>
						<option disabled value={-1}>Content Area</option>
						<option value={-2}>All Content Areas</option>
						{this.props.contentAreas.map(s => {
							return <option key={s.id} value={s.id}>{s.name}</option>;
						})}
					</select>
				</div>
			</div>
		</form>;
	}

	@debounce(300)
	private handleKeywordChange(value: string) {
		this.props.onKeywordChanged(value);
	}
}
