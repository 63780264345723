import React from 'react';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';
import './note-editor.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {OldAlerts} from '@esgi/deprecated/knockout';
import moment from 'moment';

export class State {
    text: string = '';
    on: boolean = false;
    dirty: boolean = false;
}

export class Props extends SharedProps<State> {
    studentName: string;
    testName: string;
    questionText: string;
    date: moment.Moment;
    note: string;
    readOnly: boolean;
    saveHandler: (noteText: string) => void;
    deleteHandler: () => void;
}

export class NoteEditor extends SharedComponent<State, Props> {
    private _maxChars: number = 140;
    private note: HTMLTextAreaElement;

    constructor(props?: Props) {
    	super(props);
    	if (props.note) {
    		this.setState({text: props.note});
    	}
    }

    componentDidMount(): void {
    }

    private textChanged(newText: string) {
    	this.setState({
    		on: true,
    		text: newText,
    		dirty: (this.props.note != newText),
    	});
    }

    private switchOn = (val: boolean) => {
    	this.setState({on: val});

    	if (val) {
    		setTimeout(() => {
    			const {note} = this.props;
    			const caret = note && note.length || 0;
    			this.note.setSelectionRange(caret, caret);
    		}, 10);
    	}
    }

    private saveHandler() {
    	this.props.saveHandler(this.state.text);
    	this.switchOn(false);
    }

    private cancel() {
    	this.setState({text: this.props.note});
    	this.switchOn(false);
    }

    private deleteHandler() {
    	this.props.deleteHandler();
    	this.switchOn(false);
    }

    private renderNoteControls = () => {
    	if (this.props.readOnly) {
    		return null;
    	}

    	return <div className='note-controls'>
            <div className='note-characters-left'>{this._maxChars - this.state.text.length} characters left (max 140)</div>
            <div className='note-controls-btns'>
                <Button className='btn-close' onClick={() => this.cancel()} title='cancel'/>
                <Primary onClick={() => this.saveHandler()} disabled={!this.state.dirty} title='Save'/>
            </div>
        </div>;
    }

    render() {
    	return <>
            <OnHoverTooltip message={(this.props.note && this.props.note.length) ? this.props.note : 'Add note'}>
                <div className={(this.props.note && !!this.props.note.length) ? 'tsa-note' : 'tsa-add-note'}
                     onClick={() => this.switchOn(true)}>
                    {(this.props.note && this.props.note.length)
                        ? <svg width='12' height='16' viewBox='0 0 12 16' fill='none'
                               xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M7.5 0.833496H1.5C0.675 0.833496 0.00750017 1.5085 0.00750017 2.3335L0 14.3335C0 15.1585 0.6675 15.8335 1.4925 15.8335H10.5C11.325 15.8335 12 15.1585 12 14.3335V5.3335L7.5 0.833496ZM6.75 6.0835V1.9585L10.875 6.0835H6.75Z'
                                fill='#0088CC'/>
                        </svg>
                        : <svg width='12' height='15' viewBox='0 0 12 15' fill='none'
                               xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M7.5 0H1.5C0.675 0 0.00750017 0.675 0.00750017 1.5L0 13.5C0 14.325 0.6675 15 1.4925 15H10.5C11.325 15 12 14.325 12 13.5V4.5L7.5 0ZM9 10.5H6.75V12.75H5.25V10.5H3V9H5.25V6.75H6.75V9H9V10.5ZM6.75 5.25V1.125L10.875 5.25H6.75Z'
                                fill='#828282'/>
                        </svg>}
                </div>
            </OnHoverTooltip>

            {this.state.on &&
                <div className='sticky-note'>
                        <div className='note-container'>
                            <div>
                                <span className='note-name'>Question: {this.props.questionText} 
                                    <OnHoverTooltip message={'Delete note'}>
                                            <a href='#' className='action-link delete-note-link'
                                               onClick={()=>{
                                                OldAlerts.bsconfirm({
                                                       message: 'Delete Note?',
                                                       modal: {
                                                           buttons: [
                                                               {
                                                                   title: 'CANCEL',
                                                                   className: 'btn btn-transparent btn-primary',
                                                                   closeModal: true,
                                                               },
                                                               {
                                                                   title: 'DELETE',
                                                                   className: 'btn btn-transparent btn-primary',
                                                                   closeModal: true,
                                                                   onClick: () => this.deleteHandler(),
                                                               },
                                                           ],
                                                       },
                                                   });
                                               }}>
                                                <svg
                                                xmlns='http://www.w3.org/2000/svg' 
                                                width='13' height='18'
                                                viewBox='0 0 13 13' 
                                                fill='none'>
                                                <path d='M1.16671 13.3333C1.16671 14.25 1.91671 15 2.83337 15H9.50004C10.4167 15 11.1667 14.25 11.1667 13.3333V3.33333H1.16671V13.3333ZM12 0.833333H9.08337L8.25004 0H4.08337L3.25004 0.833333H0.333374V2.5H12V0.833333Z' fill='#0088CC'></path></svg>
                                            </a>
                                        
                                    </OnHoverTooltip>
                                </span>
                            </div>
                            <div className='note-description'>
                                <div className='note-subheader'>Notes:</div>
                            </div>
                            <textarea ref={(r) => this.note = r}
                                className='note'
                                maxLength={this._maxChars}
                                defaultValue={this.props.note}
                                autoFocus={true}
                                disabled={this.props.readOnly}
                                onInput={(e: any) => this.textChanged(e.target.value)} />

                            {this.renderNoteControls()}
                        </div>
                </div>
            }
    	</>;
    }
}
