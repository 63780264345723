import {OverlayScrollbars} from 'overlayscrollbars';
import React, {createRef, ReactNode} from 'react';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Button, PrimaryButton, TransparentButton} from '@esgi/deprecated/ui-kit/buttons';
import {BaseDropdown, Option} from '@esgi/deprecated/ui-kit/dropdown';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import {
	CriteriaActiveView,
	DescriptionActiveView,
	LevelActiveView,
	RubricEditForm,
	validateCriteria,
	validateDescriptions,
	validateLevels,
} from '../../../../../common/rubric-edit-form';
import {TemplateModel} from '../../../../../common/types';
import TemplateDataService from '../../template-data-service';
import {ConfirmSwitch} from '../confirm-switch/confirm-switch';
import styles from './picker.module.less';

class State {
	initialized: boolean = false;
	loading: boolean = false;
	selectedTemplateID: number;
	showConfirmSwitch: number = 0;
}

interface Props {
	initialTemplateID: number;
	templateDataService: TemplateDataService;
	templates: TemplateModel[];
	onBackClicked: () => void;
	onSaveClicked: () => void;
	onPreviewClicked: () => void;
}

export default class PickDialog extends React.PureComponent<Props, State> {
	public readonly state = new State();

	private readonly onDestroy$: Subject<void> = new Subject();
	private readonly modalManagerRef: ModalManagerRef = createRef();

	private readonly criteriaToRef: CriteriaActiveView[] = [];
	private readonly levelsToRef: LevelActiveView[] = [];
	private readonly descriptionsToRef: DescriptionActiveView[] = [];
	private osInstance: OverlayScrollbars;

	public render(): ReactNode {
		return <>
			{this.renderPickerModal()}
			{this.renderConfirmDialog()}
		</>;
	}

	public renderPickerModal() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader className={styles.header}>
				<Title className={styles.title}>Edit Template</Title>
			</ModalHeader>
			<ModalBody className={styles.body}>
				<div className={styles.innerBody}>
					{this.renderTemplatePicker()}
					<RubricEditForm overlayScrollRef={(ref) => this.osInstance = ref}
					                options={{
						                criteriaOptions: {editMode: true},
						                levelsOptions: {editMode: true},
						                descriptionsOptions: {editMode: true},
					                }}
					                rubricService={this.props.templateDataService}
					                onCriteriaRendered={this.onCriteriaRendered}
					                onLevelsRendered={this.onLevelRendered}
					                onDescriptionsRendered={this.onDescriptionsRendered}
					/>
				</div>
			</ModalBody>
			<ModalFooter className={styles.footer}>
				<div>
					<Button onClick={() => this.modalManagerRef.current.close(this.props.onBackClicked)}>
						CANCEL
					</Button>
				</div>
				<div className={styles.footerLeftActions}>
					<TransparentButton onClick={() => this.onPreviewClicked()}>
						PREVIEW RUBRIC
					</TransparentButton>
					<PrimaryButton onClick={() => this.onSaveClicked()}>
						SAVE
					</PrimaryButton>
				</div>
			</ModalFooter>
		</Modal>;
	}

	public renderTemplatePicker(): ReactNode {
		const value = this.props.templates
			.map(t => ({
				id: t.rubricID,
				value: t.name,
			}))
			.filter(t => t.id === this.props.templateDataService.testID);

		return <div className={styles.templatePicker}>
			<div className={styles.testDropdownField}>
				<div className={styles.label}>
					Rubric Template:
				</div>
				<div className={styles.templateDropdown}>
					<BaseDropdown setValue={(v) => this.selectTemplate(v[0]?.id)}
					              optionName='value'
					              value={value}>
						{this.props.templates.map(t => <Option key={t.order} value={{
							id: t.rubricID,
							value: t.name,
						}}>{t.name}</Option>)}
					</BaseDropdown>
				</div>
			</div>
		</div>;
	}

	public renderConfirmDialog(): ReactNode {
		const nextTemplateID = this.state.showConfirmSwitch;
		if(nextTemplateID) {
			return <ConfirmSwitch onCancel={() => this.setState({showConfirmSwitch: 0})}
			                      onContinue={() => this.setState({showConfirmSwitch: 0}, () => this.selectTemplate(nextTemplateID, true))}/>;
		}
	}

	public componentWillUnmount(): void {
		this.onDestroy$.next();
	}

	private selectTemplate(id: number, force?: boolean): void {
		if (this.props.templateDataService.isTouched() && !force) {
			this.setState({showConfirmSwitch: id});
		} else {
			this.setState({loading: true, selectedTemplateID: id}, () => {
				this.props.templateDataService
					.initByTest(id)
					.pipe(takeUntil(this.onDestroy$))
					.subscribe(r => this.setState({loading: false}));
			});
		}
	}

	private onCriteriaRendered = (id: number, ref: CriteriaActiveView) => {
		this.criteriaToRef[id] = ref;
	};

	private onLevelRendered = (id: number, ref: LevelActiveView) => {
		this.levelsToRef[id] = ref;
	};

	private onDescriptionsRendered = (id: number, ref: DescriptionActiveView) => {
		this.descriptionsToRef[id] = ref;
	};

	private validate(): boolean {
		const {criteria$, levels$, levelDisplayMode$, descriptions$} = this.props.templateDataService;
		const criteriaValid = validateCriteria({
			criteria: criteria$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.criteriaToRef,
			},
		});

		if (!criteriaValid) {
			return false;
		}

		const levelsValid = validateLevels({
			levels: levels$.value,
			displayMode: levelDisplayMode$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.levelsToRef,
			},
		});

		if (!levelsValid) {
			return false;
		}

		return validateDescriptions({
			criteria: criteria$.value,
			levels: levels$.value,
			descriptions: descriptions$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.descriptionsToRef,
			},
		});
	}

	private onPreviewClicked(): void {
		const isValid = this.validate();

		if (isValid) {
			this.props.onPreviewClicked();
		}
	}

	private onSaveClicked(): void {
		const isValid = this.validate();

		if (isValid) {
			this.props.onSaveClicked();
		}
	}
}
