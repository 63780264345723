export enum TestContentArea {
	LanguageArts = 'Language Arts',
	Science = 'Science',
	SocialStudies = 'Social Studies',
	Math = 'Mathematics',
	Spanish = 'Spanish',
	SEL = 'SEL',
	Other = 'Other',
}

export enum TestType {
	None = 'None',
	YN = 'YN',
	Rubric = 'Rubric',
	Score = 'Score',
}
