import {Card, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const cardHeaderGap = '$3';

export const CardHeaderBox = styled(Card.Header, {
	display: 'grid',
	gridTemplateColumns: 'repeat(3, auto) 1fr',
	gap: cardHeaderGap,
	alignItems: 'center',
});

export const TestLabelsBox = styled(FlexBox, {
	flexDirection: 'row',
	gap: cardHeaderGap,
});