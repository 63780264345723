import React, {createRef, RefObject} from 'react';
import {EventBusManager} from '@esgillc/events';
import Selectors from '@esgi/testing/selectors';
import {EsgiLetters} from 'shared/esgi-letters/component';
import './preloader.less';
import {ErrorOccurredEvent} from '@esgi/core/react';

interface Props {
	loaded: boolean[];
	lifted?: () => any;
}

class State {
	lettersToShow: number = 0;
	finished: boolean = false;
}

export default class Preloader extends React.Component<Props, State> {
	public readonly state = new State();
	private eventBus: EventBusManager = new EventBusManager();
	private root: RefObject<HTMLDivElement> = createRef();
	private intervalID: number;

	public componentDidMount(): void {
		this.intervalID = window.setInterval(() => this.handler(), 100);
		this.eventBus.subscribe(ErrorOccurredEvent, () => this.errorOccurred());
	}

	public render(): JSX.Element | false | null {
		return <div className='page-preloader' data-cy={Selectors.Preloader} ref={this.root} style={{opacity: 1}}>
			<div className='wrapper'>
				<div className='letters-container'>
					{this.renderLetters()}
				</div>
			</div>
		</div>;
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	private renderLetters(): JSX.Element {
		const letters = EsgiLetters.Letters.slice(0, this.state.lettersToShow);
		return <svg width='200' height='89' viewBox='0 0 76 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
			{letters.map(l => l.path)}
		</svg>;
	}

	private errorOccurred() {
		if (!this.state.finished) {
			this.expiredHandler();
		}
	}

	private expiredHandler() {
		clearInterval(this.intervalID);
		this.setState({lettersToShow: 5});
		this.liftPreloader();
	}

	// TODO-szus315 Rewrite to componentDidUpdate/useEffect.
	private handler() {
		const loaded = this.props.loaded;
		const numberOfLoadedComponents = loaded.filter(s => s).length;
		const totalNumberOfComponents = loaded.length;
		const percentsLoaded = numberOfLoadedComponents / totalNumberOfComponents * 100;
		const lettersToShow = Math.ceil(percentsLoaded / 20);
		if (lettersToShow !== this.state.lettersToShow) {
			const dif = lettersToShow - this.state.lettersToShow;
			if (dif >= 1) {
				this.setState({lettersToShow: this.state.lettersToShow + 1});
			}
		}
		if (percentsLoaded === 100 && this.state.lettersToShow === 5) {
			clearInterval(this.intervalID);
			this.liftPreloader();
		}
	}

	// TODO-szus315 Rewrite to UI-Kit transitions.
	private liftPreloader() {
		setTimeout(() => {
			this.root.current.style.opacity = '0';
			setTimeout(() => {
				sessionStorage.setItem('preloader', 'preloader');
				this.setState({finished: true}, this.props.lifted);
			}, 600);
		}, 400);
	}
}
