import {SelectableList} from '@esgi/ui/layout';
import {SelectableListRoot, SelectableListTrigger} from './components/components.styled';
import {ReactNode} from 'react';
import {GroupItemContent} from '../components/group-item-content';
import {IdableItem} from '../../../types';
import {Edit} from '@esgi/ui';
import {SelectableEditableItem} from '../../selectable-list/components/selectable-editable-item';
import {getListValue} from '../../../utils/get-list-value';

type Props<Item extends IdableItem> = {
	dataCy?: string;
	items: Item[];
	onStyledButtonClick: VoidFunction;
	onItemClick: (itemId: Item['id']) => void;
	isListOpen: boolean;
	withCounter?: boolean;
	groupName: string;
	onTriggerButtonClick: VoidFunction;
	children?: (args: {item: Item}) => ReactNode;
};

export function ExpandableEditable<Item extends IdableItem>({
	dataCy,
	items,
	onItemClick,
	isListOpen,
	withCounter,
	groupName,
	onStyledButtonClick,
	children,
	onTriggerButtonClick,
}: Props<Item>) {
	return (
		<SelectableListRoot isListOpen={isListOpen} dataCy={dataCy}>
			<SelectableList.GroupRoot>
				<SelectableListTrigger
					openList={isListOpen}
					onClick={onTriggerButtonClick}
					css={{
						gridTemplateColumns: 'auto 1fr',
					}}
				>
					<GroupItemContent itemsCount={withCounter ? items.length : undefined} label={groupName} />
					<SelectableList.StyledButton onClick={onStyledButtonClick}>
						<Edit />
					</SelectableList.StyledButton>
				</SelectableListTrigger>
				<SelectableList.Group>
					{items.map((item) => (
						<SelectableEditableItem
							key={item.id}
							onItemClick={() => onItemClick(item.id)}
							value={getListValue(item.id)}
						>
							{children?.({
								item,
							})}
						</SelectableEditableItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
