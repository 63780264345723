import {
	ArrowStyle,
	CircleStyle, EdgePosition,
	EdgeStyle, FontStyle,
	IGraphStyle, ImageStyle, LineStyle, Position, RectangleStyle,
	ShapeDefinitions,
	ShapeSize,
	ShapeType, StyleDefinition, TextAlign, TextStyle, TriangleStyle, VertexPosition,
	VertexSize,
	VertexStyle,
} from 'shared/modules/question-editor/models';
import Enumerable from 'linq';

export class ShapeDefinitionService {
	canvasWidth: number = 708;
	canvasHeight: number = 318;
	gridSize: number = 10;

	defaultSizes = {
		text: new ShapeSize(540, 100),
		shape: new ShapeSize(150, 150),
	}

	shapeDefinition: ShapeDefinitions;

	constructor(shapeDefinition?: ShapeDefinitions) {
		this.shapeDefinition = shapeDefinition;
	}

	public getStyle(shapeType: ShapeType) {
		let definition = Enumerable.from(this.shapeDefinition.styles).where(x => x.type === shapeType).firstOrDefault();
		return {...definition.style};
	}

	public getVertexSize(shapeType: ShapeType) {
		let definition = Enumerable.from(this.shapeDefinition.vertexSizes).where(x => x.type === shapeType).firstOrDefault();
		if (definition.size === null) {
			return null;
		}

		return {...definition.size};
	}

	public getVertexPosition(shapeType: ShapeType) {
		let definition = Enumerable.from(this.shapeDefinition.vertexPositions).where(x => x.type === shapeType).firstOrDefault();
		if (definition.position === null) {
			return null;
		}

		return {...definition.position};
	}

	public getEdgePosition(shapeType: ShapeType) {
		let definition = Enumerable.from(this.shapeDefinition.edgePositions).where(x => x.type === shapeType).firstOrDefault();
		return [definition.source, definition.target];
	}

	public updateStyle(shapeType: ShapeType, style: IGraphStyle) {
		if (shapeType === ShapeType.Text) {
			this.updateTextStyle(style);
		} else {
			this.updateShapeStyle(shapeType, style);
		}
	}

	private updateTextStyle(style: IGraphStyle) {
		let definition = Enumerable.from(this.shapeDefinition.styles).where(x => x.type === ShapeType.Text).firstOrDefault();
		definition.style = style;
	}

	private updateShapeStyle(shapeType: ShapeType, style: IGraphStyle) {
		let color = '';
		let strokeColor = '';

		if (shapeType >= ShapeType.Rectangle && shapeType <= ShapeType.Line) {
			color = (style as VertexStyle).fillColor;
			strokeColor = (style as VertexStyle).strokeColor;
		}

		if (shapeType >= ShapeType.Line) {
			color = (style as EdgeStyle).strokeColor;
		}

		for (let i = 0; i < this.shapeDefinition.styles.length; i++) {
			let dStyle = this.shapeDefinition.styles[i].style;
			let dType = this.shapeDefinition.styles[i].type;

			if (dType != shapeType) {
				if (dType >= ShapeType.Rectangle && shapeType <= ShapeType.Line) {
					(dStyle as VertexStyle).fillColor = color;
					(dStyle as VertexStyle).strokeColor = strokeColor;
				}

				if (dType >= ShapeType.Line) {
					(dStyle as EdgeStyle).strokeColor = color;
				}
			}
		}

		let definition = Enumerable.from(this.shapeDefinition.styles).where(x => x.type === shapeType).firstOrDefault();
		definition.style = style;
	}

	public updateVertexSize(shapeType: ShapeType, width: number, height: number) {
		let definition = Enumerable.from(this.shapeDefinition.vertexSizes).where(x => x.type === shapeType).firstOrDefault();
		definition.size = new ShapeSize(width, height);
	}

	public updateVertexPosition(shapeType: ShapeType, x: number, y: number) {
		if (shapeType === ShapeType.Text) {
			return;
		}

		let definition = Enumerable.from(this.shapeDefinition.tempVertexPositions).where(x => x.type === shapeType).firstOrDefault();
		if (!definition) {
			definition = Enumerable.from(this.shapeDefinition.vertexPositions).where(x => x.type === shapeType).firstOrDefault();
			let newDef = {...definition};
			newDef.position = new Position(x, y);
			this.shapeDefinition.tempVertexPositions.push(newDef);
		} else {
			definition.position = new Position(x, y);
		}
	}

	public updateEdgePosition(shapeType: ShapeType, xs: number, ys: number, xt: number, yt: number) {
		let definition = Enumerable.from(this.shapeDefinition.tempEdgePositions).where(x => x.type === shapeType).firstOrDefault();
		if (!definition) {
			definition = Enumerable.from(this.shapeDefinition.edgePositions).where(x => x.type === shapeType).firstOrDefault();
			let newDef = {...definition};
			newDef.source = new Position(xs, ys);
			newDef.target = new Position(xt, yt);
			this.shapeDefinition.tempEdgePositions.push(newDef);
		} else {
			definition.source = new Position(xs, ys);
			definition.target = new Position(xt, yt);
		}
	}

	public saveTempDefinitions() {
		for (let i = 0; i < this.shapeDefinition.vertexPositions.length; i++) {
			const vertexDef = this.shapeDefinition.vertexPositions[i];
			let tempVertexDef = Enumerable.from(this.shapeDefinition.tempVertexPositions).where(x => x.type === vertexDef.type).firstOrDefault();
			if (tempVertexDef) {
				this.shapeDefinition.vertexPositions[i] = tempVertexDef;
			}
		}

		for (let i = 0; i < this.shapeDefinition.edgePositions.length; i++) {
			const edgeDef = this.shapeDefinition.edgePositions[i];
			let tempEdgeDef = Enumerable.from(this.shapeDefinition.tempEdgePositions).where(x => x.type === edgeDef.type).firstOrDefault();
			if (tempEdgeDef) {
				this.shapeDefinition.edgePositions[i] = tempEdgeDef;
			}
		}

		return this.shapeDefinition;
	}

	public getVertexCenterCoordinates(width: number, height: number) {
		let x = Math.ceil(((this.canvasWidth - width) / 2) / this.gridSize) * this.gridSize;
		let y = Math.ceil(((this.canvasHeight - height) / 2) / this.gridSize) * this.gridSize;
		return new Position(x, y);
	}

	public getEdgeCenterCoordinates(width: number, height: number) {
		let sourceX1 = Math.ceil(((this.canvasWidth - width) / 2) / this.gridSize) * this.gridSize;
		let sourceY1 = Math.ceil(((this.canvasHeight + height) / 2) / this.gridSize) * this.gridSize;
		let targetX2 = Math.ceil(((this.canvasWidth + width) / 2) / this.gridSize) * this.gridSize;
		let targetY2 = Math.ceil(((this.canvasHeight - height) / 2) / this.gridSize) * this.gridSize;

		return [new Position(sourceX1, sourceY1), new Position(targetX2, targetY2)];
	}

	public convertStrStyleToGraphStyle(shape: IGraphStyle, style: string): IGraphStyle {
		Object.keys(shape).forEach((field: string) => {
			let startFieldIndex = style.indexOf(field);
			let startValueIndex = style.indexOf('=', startFieldIndex) + 1;
			let endValueIndex = style.indexOf(';', startValueIndex);
			let value = style.substring(startValueIndex, endValueIndex);

			if (endValueIndex === -1) {
				value = style.substring(startValueIndex, style.length);
			}

			shape[field] = this.isNumber(value) ? parseInt(value) : value;
		});

		return shape;
	}

	public convertGraphStyleToStrStyle(shapeStyle: IGraphStyle, type: ShapeType) {
		let str = '';
		let obj = this.shapeStyleFactory(type);
		Object.keys(obj).forEach((field: string) => {
			str += `${field}=${shapeStyle[field]};`;
		});

		return str;
	}

	shapeStyleFactory(type: ShapeType) {
		switch (type) {
			case ShapeType.Text: {
				return new TextStyle();
			}
			case ShapeType.Rectangle: {
				return new RectangleStyle();
			}
			case ShapeType.Circle: {
				return new CircleStyle();
			}
			case ShapeType.Triangle: {
				return new TriangleStyle();
			}
			case ShapeType.Line: {
				return new LineStyle();
			}
			case ShapeType.Arrow: {
				return new ArrowStyle();
			}
			case ShapeType.Image: {
				return new ImageStyle();
			}
		}
	}

	isNumber(n) {
		return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
	}
}

export abstract class ShapeDefinitionBuilder {
	shapeDefinitionService: ShapeDefinitionService = new ShapeDefinitionService();

	public changeToBlack(shapeDefinitions: ShapeDefinitions) {
		let textStyle = shapeDefinitions.styles.filter(x => x.type === ShapeType.Text)[0].style as TextStyle;
		if (textStyle.fontColor === '#000000') {
			textStyle.fontColor = '#FFFFFF';
		}
	}

	public changeToWhite(shapeDefinitions: ShapeDefinitions) {
		let textStyle = shapeDefinitions.styles.filter(x => x.type === ShapeType.Text)[0].style as TextStyle;
		if (textStyle.fontColor === '#FFFFFF') {
			textStyle.fontColor = '#000000';
		}
	}

	public abstract createRectangleStyle(shapeDefinitions: ShapeDefinitions);

	public abstract createCircleStyle(shapeDefinitions: ShapeDefinitions);

	public abstract createTriangleStyle(shapeDefinitions: ShapeDefinitions);

	public abstract createLineStyle(shapeDefinitions: ShapeDefinitions);

	public abstract createArrowStyle(shapeDefinitions: ShapeDefinitions);

	public abstract createEditorStyle(shapeDefinitions: ShapeDefinitions);

	public abstract createImageStyle(shapeDefinitions: ShapeDefinitions);

	public Build(shapeDefinitions: ShapeDefinitions) {
		this.createRectangleStyle(shapeDefinitions);
		this.createCircleStyle(shapeDefinitions);
		this.createTriangleStyle(shapeDefinitions);
		this.createLineStyle(shapeDefinitions);
		this.createArrowStyle(shapeDefinitions);
		this.createEditorStyle(shapeDefinitions);
		this.createImageStyle(shapeDefinitions);
	}
}

export class Black extends ShapeDefinitionBuilder {
	createRectangleStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new RectangleStyle();
		style.fillColor = '#0088CC';
		style.editable = 0;
		style.strokeColor = 'transparent';
		style.strokeWidth = 5;

		let size = this.shapeDefinitionService.defaultSizes.shape;

		let center = this.shapeDefinitionService.getVertexCenterCoordinates(size.width, size.height);
		shapeDefinitions.vertexPositions.push(new VertexPosition(ShapeType.Rectangle, center));
		shapeDefinitions.vertexSizes.push(new VertexSize(ShapeType.Rectangle, size));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Rectangle, style));
	}

	createCircleStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new CircleStyle();
		style.fillColor = '#0088CC';
		style.strokeColor = 'transparent';
		style.editable = 0;
		style.strokeWidth = 5;

		let size = this.shapeDefinitionService.defaultSizes.shape;

		let center = this.shapeDefinitionService.getVertexCenterCoordinates(size.width, size.height);
		shapeDefinitions.vertexPositions.push(new VertexPosition(ShapeType.Circle, center));
		shapeDefinitions.vertexSizes.push(new VertexSize(ShapeType.Circle, size));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Circle, style));
	}

	createTriangleStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new TriangleStyle();
		style.fillColor = '#0088CC';
		style.strokeColor = 'transparent';
		style.editable = 0;
		style.strokeWidth = 5;

		let size = this.shapeDefinitionService.defaultSizes.shape;

		let center = this.shapeDefinitionService.getVertexCenterCoordinates(size.width, size.height);
		shapeDefinitions.vertexPositions.push(new VertexPosition(ShapeType.Triangle, center));
		shapeDefinitions.vertexSizes.push(new VertexSize(ShapeType.Triangle, size));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Triangle, style));
	}

	createLineStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new LineStyle();
		style.strokeColor = '#0088CC';
		style.strokeWidth = 5;
		style.endArrow = 'none';

		let size = this.shapeDefinitionService.defaultSizes.shape;

		let center = this.shapeDefinitionService.getEdgeCenterCoordinates(size.width, size.height);
		shapeDefinitions.edgePositions.push(new EdgePosition(ShapeType.Line, center[0], center[1]));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Line, style));
	}

	createArrowStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new ArrowStyle();
		style.strokeWidth = 5;
		style.strokeColor = '#0088CC';
		style.endArrow = 'block';

		let size = this.shapeDefinitionService.defaultSizes.shape;

		let center = this.shapeDefinitionService.getEdgeCenterCoordinates(size.width, size.height);
		shapeDefinitions.edgePositions.push(new EdgePosition(ShapeType.Arrow, center[0], center[1]));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Arrow, style));
	}

	createEditorStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new TextStyle();
		style.spacing = 0;
		style.editable = 1;
		style.fontFamily = 'AbcPrint';
		style.fontColor = '#FFFFFF';
		style.fillColor = 'transparent';
		style.strokeColor = 'transparent';
		style.fontStyle = FontStyle.Normal;
		style.align = TextAlign.Center;
		style.fontSize = 80;
		style.verticalAlign = TextAlign.Middle;

		let size = this.shapeDefinitionService.defaultSizes.text;

		let center = this.shapeDefinitionService.getVertexCenterCoordinates(size.width, size.height);
		shapeDefinitions.vertexPositions.push(new VertexPosition(ShapeType.Text, center));
		shapeDefinitions.vertexSizes.push(new VertexSize(ShapeType.Text, size));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Text, style));
	}

	createImageStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new ImageStyle();
		style.editable = 0;

		shapeDefinitions.vertexPositions.push(new VertexPosition(ShapeType.Image, null));
		shapeDefinitions.vertexSizes.push(new VertexSize(ShapeType.Image, null));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Image, style));
	}
}

export class White extends Black {
	createEditorStyle(shapeDefinitions: ShapeDefinitions) {
		let style = new TextStyle();
		style.spacing = 0;
		style.editable = 1;
		style.fontFamily = 'AbcPrint';
		style.fontColor = '#000000';
		style.fillColor = 'transparent';
		style.strokeColor = 'transparent';
		style.fontStyle = FontStyle.Normal;
		style.align = TextAlign.Center;
		style.fontSize = 80;
		style.verticalAlign = TextAlign.Middle;

		let size = this.shapeDefinitionService.defaultSizes.text;

		let center = this.shapeDefinitionService.getVertexCenterCoordinates(size.width, size.height);
		shapeDefinitions.vertexPositions.push(new VertexPosition(ShapeType.Text, center));
		shapeDefinitions.vertexSizes.push(new VertexSize(ShapeType.Text, size));
		shapeDefinitions.styles.push(new StyleDefinition(ShapeType.Text, style));
	}
}

