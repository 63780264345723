import {Setup} from '@esgi/ui';
import {IconWrapper} from './index.styled';

export const SetupIcon = () => {
	return (
		<IconWrapper>
			<Setup />
		</IconWrapper>
	);
};
