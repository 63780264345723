import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const CardBody = styled(Box, {
	textAlign: 'center',
	padding: '5px',
});

export const LoadText = styled(Text, {
	variants: {
		loaded: {
			true: {
				display: 'none',
			},
		},
	},
});

export const Image = styled('img', {
	maxWidth: '100%',

	variants: {
		loaded: {
			false: {
				display: 'none',
			},
		},
	},
});
