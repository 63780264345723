import * as React from 'react';
import type {SVGProps} from 'react';

export function AccessError(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			data-cy='access-error-icon'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.75 12.95C10.3415 12.95 9.19995 14.0915 9.19995 15.5V21.45C9.19995 22.8584 10.3415 24 11.75 24H20.25C21.6584 24 22.8 22.8584 22.8 21.45V15.5C22.8 14.0915 21.6584 12.95 20.25 12.95C19.4 12.95 12.6 12.95 11.75 12.95ZM11.75 14.65H20.25C20.7192 14.65 21.1 15.0308 21.1 15.5V21.45C21.1 21.9192 20.7192 22.3 20.25 22.3H11.75C11.2808 22.3 10.9 21.9192 10.9 21.45V15.5C10.9 15.0308 11.2808 14.65 11.75 14.65Z'
				fill='#F50023'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.45 11.25C13.45 9.84168 14.5916 8.7 16 8.7C17.4083 8.7 18.55 9.84168 18.55 11.25V12.95H20.25V11.25C20.25 8.90279 18.3471 7 16 7C13.6527 7 11.75 8.90279 11.75 11.25V12.95H13.45V11.25Z'
				fill='#F50023'
			/>
			<path
				d='M18.2848 20.7848C17.998 21.0717 17.5323 21.0717 17.2455 20.7848L16 19.5394L14.7545 20.7848C14.4677 21.0717 14.002 21.0717 13.7152 20.7848C13.4283 20.498 13.4283 20.0323 13.7152 19.7455L14.9606 18.5L13.7152 17.2545C13.4283 16.9677 13.4283 16.502 13.7152 16.2152C14.002 15.9283 14.4677 15.9283 14.7545 16.2152L16 17.4606L17.2455 16.2152C17.5323 15.9283 17.998 15.9283 18.2848 16.2152C18.5717 16.502 18.5717 16.9677 18.2848 17.2545L17.0394 18.5L18.2848 19.7455C18.5717 20.0323 18.5717 20.498 18.2848 20.7848Z'
				fill='#F50023'
			/>
		</svg>
	);
}
