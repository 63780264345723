import {HttpClient} from '@esgi/api';

export class Import {
	private controllerName = 'import';

	downloadImportFiles(data) {
    	return HttpClient.default.ESGIApi.get(this.controllerName, 'download-import-files', data).toPromise();
	}

	checkImportFiles(importFileId) {
    	return HttpClient.default.ESGIApi.get(this.controllerName, 'check-import-files', {importFileId: importFileId});
	}

	getZip(importFileId) {
		return HttpClient.default.ESGIApi.file(this.controllerName, 'get-zip', 'importing_files_' + importFileId, importFileId);
	}

	static getZip(importFileId) {
		return HttpClient.default.ESGIApi.file('import', 'get-zip', 'importing_files_' + importFileId, importFileId).toPromise();
	}
}
