import {join} from '@esgillc/ui-kit/utils';
import {SortArrowsProps, SortBy} from './types';
import styles from './styes.module.less';

export const SortArrows = ({sortBy, isSortByActive}: SortArrowsProps) => (
	<div className={styles.container}>
		<div
			className={join(
				styles.arrow,
				styles.arrowUp,
				sortBy === SortBy.Asc && isSortByActive && styles.active,
			)}
		/>
		<div
			className={join(
				styles.arrow,
				styles.arrowDown,
				sortBy === SortBy.Desc && isSortByActive && styles.active,
			)}
		/>
	</div>
);
