import {useLocation} from 'react-router-dom';
import {Text} from '@esgi/ui/typography';
import {AppliedTo, Filenametemplate, TextIcon, SightWords} from '@esgi/ui/icons';
import {routes} from '@esgi/main/libs/core';
import {IconComponent} from '@esgi/ui';

export function Title() {
	const {pathname} = useLocation();
	const data: Record<string, [IconComponent, string]> = {
		[routes.teacher.activities.bingo]: [AppliedTo, 'Bingo'],
		[routes.teacher.activities.flashcards]: [Filenametemplate, 'Flashcards'],
		[routes.teacher.activities.writingPractice]: [TextIcon, 'Writing Practice'],
		[routes.teacher.activities.sightWords]: [SightWords, 'Sight Words'],
	};
	const [Icon, text] = data[pathname];

	return (
		<>
			<Icon/>
			<Text size='small' data-cy='activity-title'>{text}</Text>
		</>
	);
}
