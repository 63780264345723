import {Step} from './steps';
import {FreeExtraStudentsTemplate} from '../html/free-extra-students-template';
import {CartModel} from '../models';

export class FreeExtraStudentsStep extends Step {

	constructor(public cart: CartModel) {
		super();
		this.title = 'Extra Students';
	}

	template = FreeExtraStudentsTemplate;

	view = {
		cancel: () => {
			$(this).trigger('cancel');
		},
		continue: () => {
			$(this).trigger('continue');
		},
	};

	events = {
		cancel: (callback) => {
			$(this).on('cancel', callback);
		},
		continue: (callback) => {
			$(this).on('continue', callback);
		},
	};
}