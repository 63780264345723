import {useMemo} from 'react';
import {Column, FixedColumnPosition} from '@esgi/deprecated/ui-kit/report/table/types';
import {StudentResult, SortModel} from '../../../../../types/table-level-report-service-types';
import {SortableColumn} from '../components/sortable-column';
import {TableLevelReportService} from '../../../../../services/table-level-report-service';
import cellStyles from '../styles/cell.module.less';
import styles from '../styles/student-name-column.module.less';

export function useStudentnameColumn({service, sortBy}: {service: TableLevelReportService; sortBy: SortModel}) {
	return useMemo<Column<StudentResult>>(
		() => ({
			fixedColumnPosition: FixedColumnPosition.Left,
			className: styles.studentsColumn,
			headers: [
				[
					{
						renderer: () => <div className={styles.studentsTopHeaderColumn}>Students</div>,
					},
				],
				[
					{
						className: styles.firstNameHeader,
						renderer: () => (
							<div className={cellStyles.cell}>
								<SortableColumn
									title='First Name'
									sortByType={{sortKey: 'firstName'}}
									service={service}
									sortBy={sortBy}
								/>
							</div>
						),
					},
					{
						renderer: () => (
							<div className={cellStyles.cell}>
								<SortableColumn
									title='Last Name'
									sortByType={{sortKey: 'lastName'}}
									service={service}
									sortBy={sortBy}
								/>
							</div>
						),
					},
				],
			],
			cells: [
				{
					className: styles.nameCell,
					renderer: ({firstName}: StudentResult) => firstName,
				},
				{
					className: styles.nameCell,
					renderer: ({lastName}: StudentResult) => lastName,
				},
			],
		}),
		[service, sortBy],
	);
}
