import {SelectableList} from '@esgi/ui/layout';
import {Student} from '@esgi/main/libs/store';
import {MultipleSelectionCenter} from '@esgi/ui/icons';
import {IconComponent} from '@esgi/ui';
import {StudentFullName, getListValue} from '../../../../kit';
import {SelectableListTriggerContent} from './selectable-list-trigger-content';
import {SelectableLisItemContent, SelectableListRoot, SelectableListTrigger} from './lists.styled';
import {noop} from 'underscore';

type Props = {
	IconBefore: IconComponent;
	triggerLabel: string;
	isListOpen: boolean;
	onTriggerButtonClick: VoidFunction;
	onStyledButtonClick: VoidFunction;
	isListSelected: boolean;
	students: Student[];
	selectedStudentId: Student['id'] | null;
	setSelectedStudentId: (value: Student['id']) => void;
};

export function ExpandableSelectableList({
	selectedStudentId,
	isListOpen,
	isListSelected,
	onTriggerButtonClick,
	onStyledButtonClick,
	IconBefore,
	triggerLabel,
	students,
	setSelectedStudentId,
}: Props) {
	return (
		<SelectableListRoot isListOpen={isListOpen}>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedStudentId)}>
				<SelectableListTrigger
					openList={isListOpen}
					onClick={students.length ? onTriggerButtonClick : noop}
					IconBefore={IconBefore}
					selected={isListSelected}
					hovered={students.length ? undefined : false}
					defaultCursor={Boolean(!students.length)}
				>
					<SelectableListTriggerContent itemsCount={students.length} triggerLabel={triggerLabel} />
					<SelectableList.StyledButton onClick={!isListSelected ? onStyledButtonClick : noop} dataCy='selectable-list-icon'>
						<MultipleSelectionCenter />
					</SelectableList.StyledButton>
				</SelectableListTrigger>
				<SelectableList.Group>
					{students.map(({firstName, lastName, id}) => (
						<SelectableList.Item
							value={String(id)}
							key={id}
							forceSelect={selectedStudentId !== id}
							onClick={() => setSelectedStudentId(id)}
						>
							<SelectableLisItemContent>
								<StudentFullName firstName={firstName} lastName={lastName} />
							</SelectableLisItemContent>
						</SelectableList.Item>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableListRoot>
	);
}
