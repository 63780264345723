import {Loader} from '@esgillc/ui-kit/loader';
import React, {createRef, ReactNode} from 'react';
import {CloseIcon, Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import ParentLetterEditor from './editor/parent-letter-editor';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {tryCall} from '@esgillc/ui-kit/utils';
import styles from './parent-letter-editor-modal.module.less';

interface Props {
	readonly?: boolean;
	readonlyMessage?: string;
	title: string;
	message: string;
	defaultMessage: string;
	editMode?: boolean;
	showSnackbar?: boolean;
	children?: any;
	afterTextAreaRenderer?: () => ReactNode;
	onCloseClicked: () => void;
	onSaveClicked: (message: string) => void;
	onCancelClicked?: () => void;
	onToggleEditMode?: (editMode: boolean) => void;
}

class State {
	message: string;
	editMode: boolean;
}

export default class ParentLetterEditorModal extends React.Component<Props, State> {
	state = new State();
	private modalManagerRef: ModalManagerRefObject = createRef();

	public componentDidMount() {
		this.setState({editMode: !!this.props.editMode});
		this.setMessage(this.props.message);
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.message !== this.props.message) {
			this.setMessage(this.props.message);
		}

		if (prevProps.editMode !== this.props.editMode) {
			this.setState({editMode: this.props.editMode});
		}
	}

	public render() {
		return (
			<Modal
				className={styles.modal}
				modalManagerRef={this.modalManagerRef}
			>
				<Loader
					show={false}
					fullscreen={true}
				/>
				<Modal.Header>
					<Title>{this.props.title}</Title>
					<CloseIcon onClick={() => this.modalManagerRef.current.close(() => this.props.onCloseClicked())}/>
				</Modal.Header>
				<Modal.Body className={styles.body}>
					<FlexBox direction='column'>
						{this.props.children}
						<ParentLetterEditor
							revertable={!!this.props.defaultMessage}
							editable={this.state.editMode}
							message={this.state.message}
							readonly={this.props.readonly}
							readonlyMessage={this.props.readonlyMessage}
							afterTextAreaRenderer={this.props.afterTextAreaRenderer}
							onChanged={(message: string) => this.setMessage(message)}
							onReverted={() => this.revert()}
						/>
					</FlexBox>
				</Modal.Body>
				<Modal.Footer className={styles.footer}>
					{this.renderFooter()}
					{this.props.showSnackbar && <SnackbarLayout/>}
				</Modal.Footer>
			</Modal>
		);
	}

	private revert() {
		this.setMessage(this.props.defaultMessage);
	}

	private cancel() {
		this.setMessage(
			this.props.message,
			() => this.toggleEditable(() => tryCall(this.props.onCancelClicked)),
		);
	}

	private save() {
		if (!this.state.message) {
			return;
		}

		this.toggleEditable(() => this.props.onSaveClicked(this.state.message));
	}

	private setMessage(message: string, callback?: () => void) {
		this.setState({message}, callback);
	}

	private toggleEditable(callback?: () => void) {
		this.setState({editMode: !this.state.editMode}, callback);
		tryCall(this.props.onToggleEditMode, !this.state.editMode);
	}

	private renderFooter() {
		const closeButton = (
			<Buttons.Gray onClick={() => this.modalManagerRef.current.close(this.props.onCloseClicked)}>
				CLOSE
			</Buttons.Gray>
		);

		if (this.props.readonly) {
			return <div>{closeButton}</div>;
		}

		if (this.state.editMode) {
			return (
				<>
					<div>{closeButton}</div>
					<div>
						<Buttons.Gray onClick={() => this.cancel()}>
							CANCEL
						</Buttons.Gray>
						<Buttons.Contained onClick={() => this.save()}>
							SAVE
						</Buttons.Contained>
					</div>
				</>
			);
		}

		return (
			<>
				<div>{closeButton}</div>
				<div>
					<Buttons.Outlined onClick={() => this.toggleEditable()}>
						EDIT MESSAGE
					</Buttons.Outlined>
				</div>
			</>
		);
	}
}
