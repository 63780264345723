/* eslint-disable @typescript-eslint/member-ordering */
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from './local-storage-service';
import {Student} from '../types/types';
import {SortBy} from '../kit';
import {deepCopy} from 'shared/utils';

export class TableService extends LocalStorageService {
	private tableOffsetLeft$ = new BehaviorSubject(0);

	public sortBy$ = new BehaviorSubject<{[key in keyof Student]: SortBy} | null>(null);

	public tableData$ = new BehaviorSubject<Student[]>([]);
	public allTableData$ = new BehaviorSubject<Student[]>([]);

	protected get tableData() {
		return this.tableData$.value;
	}

	public get allTableData() {
		return deepCopy(this.allTableData$.value);
	}

	private get sortBy() {
		return this.sortBy$.value;
	}

	public get tableOffsetLeft() {
		return this.tableOffsetLeft$.value;
	}

	public setTableOffsetLeftValue(value: number) {
		this.tableOffsetLeft$.next(value);
	}

	public setSortByValue(key: keyof Student, value: SortBy) {
		this.sortBy$.next({
			[key]: value,
		} as {[key in keyof Student]: SortBy});

		this.sortTableData();
	}

	protected sortTableData() {
		this.tableData.sort((dataA, dataB) => {
			if (!this.sortBy) {
				return 0;
			}

			const sortByData = Object.entries(this.sortBy)[0];

			if (!sortByData) {
				return 0;
			}

			const [key, direction] = sortByData;

			const valueA = dataA[key];
			const valueB = dataB[key];

			if (typeof valueA === 'string' && typeof valueB === 'string') {
				const nameOfStudent1 = valueA.toLocaleLowerCase();
				const nameOfStudent2 = valueB.toLocaleLowerCase();

				if (direction === SortBy.Asc) {
					return nameOfStudent1.localeCompare(nameOfStudent2);
				}

				return nameOfStudent2.localeCompare(nameOfStudent1);
			}

			return 0;
		});
	}
}
