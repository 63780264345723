import {IntroductionBlock} from '@esgi/main/kits/assignments';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AlertBody} from './index.styled';

type Props = {
	onCloseAlert: VoidFunction;
};

export function IntroductionAlert({onCloseAlert}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onCloseAlert);

	return (
		<Alert
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					minWidth: 600,
					width: '100%',
					maxWidth: 950,
					maxHeight: 'calc(100% - 25px)',
					gridTemplateRows: 'auto 1fr auto',

				},
				'& [data-alert-content] > div': {
					paddingRight: 0,
					paddingLeft: 0,
				},
			}}
			dataCy='introduction-dialog'
		>
			<Alert.Header onCloseIconClick={closeAlert} withBacklight={false}>
				<Text size='medium' color='base' data-cy='introduction-title'>
					How to create an assignment
				</Text>
			</Alert.Header>
			<AlertBody>
				<IntroductionBlock alertMode={true}/>
			</AlertBody>

		</Alert>
	);
}
