import {AssignmentModel} from '../../types/types';
import {AssignmentStatus as AssignmentStatusComponent} from '../../../asisgnments-list/components/assignment-tile/components/assignment-status';
import {AssignmentActions} from './components/assignment-actions';
import {ArrowIcon} from '@esgillc/ui-kit/icons';
import {Buttons} from '@esgillc/ui-kit/button';
import {ActionsProps} from './types';
import {AssignmentStatus} from '../../../../typings/assignment';
import {District} from '../../../kit/district';
import styles from './styles.module.less';

type Props = {
	assignment: AssignmentModel;
	onGoBack: VoidFunction;
	status: AssignmentStatus;
} & ActionsProps;

export function Header({assignment, onGoBack, status, ...actions}: Props) {
	return (
		<div className={styles.header}>
			<Buttons.Text className={styles.backBtn} onClick={onGoBack}>
				<ArrowIcon direction='left' />
				Back
			</Buttons.Text>
			<div className={styles.title}>
				{assignment.name}
				<District />
				<AssignmentStatusComponent status={status} />
				<AssignmentActions assignment={assignment} {...actions} />
			</div>
		</div>
	);
}
