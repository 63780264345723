/* eslint-disable @typescript-eslint/member-ordering */
import {BehaviorSubject} from 'rxjs';
import {Student} from '../types/types';
import {TeacherGroup, TeacherClassModel} from '../types/teacher-types';
import {isNull} from 'underscore';
import {FilterService} from './filter-service';

export class FilterTeacherService extends FilterService {
	public readonly classes$ = new BehaviorSubject<TeacherClassModel[]>([]);
	public readonly groups$ = new BehaviorSubject<TeacherGroup[]>([]);

	public selectedClassId$ = new BehaviorSubject<TeacherClassModel['id'] | null>(null);
	public selectedGroupId$ = new BehaviorSubject<TeacherGroup['id'] | null>(null);

	public groupsOfSelectedClass$ = new BehaviorSubject<TeacherGroup[]>([]);

	private get selectedClassId() {
		return this.selectedClassId$.value;
	}

	private get selectedGroupId() {
		return this.selectedGroupId$.value;
	}

	private get classes() {
		return this.classes$.value;
	}

	private get groups() {
		return this.groups$.value;
	}

	public setSelectedClassId(value: TeacherClassModel['id'] | null) {
		this.selectedClassId$.next(value);

		const groupsOfSelectedClass = this.getGroupsOfSelectedClass();
		this.groupsOfSelectedClass$.next(groupsOfSelectedClass);
	}

	private getGroupsOfSelectedClass() {
		const isClassSelected = !isNull(this.selectedClassId);

		if (isClassSelected) {
			const selectedClass = this.classes.find(({id}) => id === this.selectedClassId);

			if (selectedClass) {
				return selectedClass.groups;
			}
		}

		return this.groups;
	}

	public setSelectedGroupId(value: TeacherGroup['id'] | null) {
		this.selectedGroupId$.next(value);
	}

	private getStudentsIds(): Student['id'][] {
		const isClassSelected = !isNull(this.selectedClassId);
		const isGroupSelected = !isNull(this.selectedGroupId);

		if (isClassSelected && !isGroupSelected) {
			const selectedClass = this.classes.find(({id}) => id === this.selectedClassId);

			if (selectedClass) {
				return selectedClass.students.map(({id}) => id).flat();
			}
		}

		if (isGroupSelected) {
			const group = this.groups.find(({id}) => id === this.selectedGroupId);

			if (group) {
				return [...group.studentIDs];
			}
		}

		return [...this.allStudentsIds];
	}

	public override updateTableData() {
		const studentsIds = this.getStudentsIds();

		this.setTableData(studentsIds);
	}
}
