import {getThemeColor} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import {ProgressProps} from '../types';

export function PercentageProgress({
	value,
	maxValue,
	activeLineColor,
	lastActiveLineBorderColor,
	notActiveLineColor,
	progressLineHeight,
}: Required<ProgressProps>) {
	const resultPercentage = (value / maxValue) * 100;

	return (
		<Box
			css={{
				display: 'inline-flex',
				width: '100%',
				backgroundColor: getThemeColor(notActiveLineColor),
				borderRadius: 1,
				overflow: 'hidden',
				boxSizing: 'border-box',
				height: progressLineHeight,
			}}
			data-percentage-progress=''
		>
			<Box
				css={{
					width: `${resultPercentage}%`,
					backgroundColor: getThemeColor(activeLineColor),
					borderRightStyle: 'solid',
					borderRightWidth: 2,
					borderRightColor: getThemeColor(lastActiveLineBorderColor),
					borderTopRightRadius: 1,
					borderBottomRightRadius: 1,
					height: '100%',
				}}
			/>
		</Box>
	);
}
