import {Renderable, ModalCloser, observable} from '@esgi/deprecated/knockout';

export class ModalHeader extends Renderable {

	@observable()
	reportName: string = null;

	@observable()
	showDownload: boolean = false;

	modalCloser: ModalCloser = new ModalCloser({
		click: () => this.close(),
	});

	events = {
		closed: (callback) => {
			$(this).on('closed', callback);
		},
		pdf: (callback) => {
			$(this).on('pdf', callback);
		},
		excel: (callback) => {
			$(this).on('excel', callback);
		},
	};

	public downloadPdf() {
		$(this).trigger('pdf');
	}

	public downloadExcel() {
		$(this).trigger('excel');
	}

	public close() {
		$(this).trigger('closed');
	}

	template = () => (
		<div className='total-report-header'>
			<div className='total-report-name' data-bind='text: reportName'/>
			<div className='print-download-buttons'>
				<div id='downloadDropdown' className='btn-group' data-bind='visible: showDownload'>
					<span className='download-toggle-dropdown' data-toggle='dropdown'>
						<i className='fa fa-download'/> Download
					</span>
					<ul className='dropdown-menu dropdown-default'>
						<li><a href='#' className='download-pdf-link' data-bind='click: downloadPdf'>PDF</a></li>
						<li><a href='#' className='download-excel-link' data-bind='click: downloadExcel'>Excel</a></li>
					</ul>
				</div>
				<div data-bind='render: modalCloser'/>
			</div>
		</div>
	);
}
