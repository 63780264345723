import {Filters} from './components/filters';
import {Results} from './components/results';
import {FindMoreService} from '../../find-more-service';

import styles from '../../styles.module.less';

interface Props {
	assignmentName: string,
	service: FindMoreService;
}

export const FindMoreTests = (props: Props): JSX.Element => (
	<div className='find-more'>
		<h4 className='results-title'>Add Self Assess tests from our extensive library.</h4>
		<Filters service={props.service}/>
		<Results service={props.service}/>
	</div>
);
