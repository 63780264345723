import {Buttons} from '@esgillc/ui-kit/button';

/**
 * @deprecated Use Buttons.Gray from @esgillc/ui-kit/button.
 * */
export const Button = Buttons.Gray;

/**
 * @deprecated Use Buttons.Contained from @esgillc/ui-kit/button.
 * */
export const PrimaryButton = Buttons.Contained;

/**
 * @deprecated Use Buttons.Text from @esgillc/ui-kit/button.
 * */
export const LinkButton = Buttons.Text;

/**
 * @deprecated Use Buttons.Outlined from @esgillc/ui-kit/button.
 * */
export const TransparentButton = Buttons.Outlined;

/**
 * @deprecated Use Buttons.Link from @esgillc/ui-kit/button.
 * */
export const AnchorLikeButton = Buttons.Link;

