import {Text} from '@esgi/ui/typography';
import {Container} from './index.styled';
import {ComponentProps} from 'react';

type Props = Omit<ComponentProps<typeof Text>, 'color'>;

export function WarningMessageText(textProps: Props) {
	return (
		<Container>
			<Text color='negativeVivid' {...textProps} />
		</Container>
	);
}
