import {useState, useMemo, useCallback, useEffect} from 'react';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {AnswerState, TestType} from '@esgi/core/enums';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {delay} from 'rxjs/operators';
import {TestSessionAddEvent, TestSessionUpdateEvent, TestSessionNeedUpdateEvent, AnswersUpdateEvent,} from '../../events';
import {TestSessionDetailsFormService, YesNoService} from '../../services';
import {Answer, TestSessionAnswer, ResultRequest} from '../../types';
import {ChangeActivePanelEvent} from './events';
import {YesNoState} from './types';

export const useYesNo = (service: TestSessionDetailsFormService) => {
	const serviceData = service.serviceData as YesNoService;
	const currentTestSession = useBehaviorSubject(service.currentTestSession$);
	const testDateTouched = useBehaviorSubject(service.testDateTouched$);
	const validation = useBehaviorSubject(service.validation$);
	const loaded = useBehaviorSubject(service.loaded$);
	const testSessionDetails = useBehaviorSubject(service.testSessionDetails$);
	const [state, setState] = useState(new YesNoState());

	const listTsa = useMemo<TestSessionAnswer[]>(() => state.answers.map(
		({answerState, questionId: questionID}) => ({answerState, questionID}),
	), [state]);

	const getAnswersByState = useCallback((answerState: AnswerState): Answer[] => {
		return (state.answers)
			? state.answers.filter((item) => item.answerState === answerState)
			: [];
	}, [state.answers]);

	const correctAnswersHeader = useMemo(() => {
		if (!testSessionDetails) {
			return '';
		}
		const {testResultsCorrectVerbiage, questions} = testSessionDetails;
		return `${testResultsCorrectVerbiage} Answers: ${getAnswersByState(AnswerState.Correct).length}/${questions.length}`;
	}, [testSessionDetails, state]);

	const getAnswers = (request: ResultRequest) => {
		serviceData.load(request)
			/*
				Fixes race condition between Y/N results and Test session question list.
			 */
			.pipe(delay(150))
			.subscribe(({summaryNote, answers}) => {
				setSummaryNote(summaryNote || '');
				setAnswers(Answer.FromResponseArray(
					answers,
					testSessionDetails.questions,
					testSessionDetails.testSessions,
				));
				service.setAnswersLoaded(true);
			});
	};

	const setAnswers = (answers?: Answer[]) => {
		setState((prevState) => ({
			...prevState,
			answers: answers ?? testSessionDetails.questions.map((q) => Answer.Empty(q)),
		}));
	};

	const setSummaryNote = (summaryNote: string) => {
		setState((prevState) => ({
			...prevState,
			summaryNote: summaryNote.replace(/[\r\n\v]+/g, ''),
		}));
	};

	const setActiveAnswerPanel = (activeAnswerPanel?: AnswerState) => {
		if (activeAnswerPanel === state.activeAnswerPanel) {
			return;
		}

		dispatchAppEvent(
			ChangeActivePanelEvent,
			new ChangeActivePanelEvent(activeAnswerPanel),
		);

		setState((prevState) => ({
			...prevState,
			activeAnswerPanel,
		}));
	};

	useEffect(() => {
		setState({
			...state,
			answers: testSessionDetails.questions.map((q) => Answer.Empty(q)),
		});
	}, [loaded]);

	useEventEffect(TestSessionAddEvent, () => setSummaryNote(''));

	useEventEffect(AnswersUpdateEvent, ({prevTestDate, sessionId, testDate}) => {
		const prevDateFormatted = prevTestDate.format('MM-DD-YYYY');
		setAnswers(state.answers.map((item) => {
			if (
				item.sessionId === sessionId
				&& item.testDate.format('MM-DD-YYYY') === prevDateFormatted
			) {
				item.testDate = testDate;
			}
			return item;
		}));
	});

	useEventEffect(TestSessionNeedUpdateEvent, () => {
		if (!validation.valid) {
			return;
		}

		const {testID, studentID} = service.initData;
		const testSessionID = currentTestSession.isEmpty ? 0 : currentTestSession.id;
		const notes = state.summaryNote || null;
		const testDate = currentTestSession.testDateIso;

		dispatchAppEvent(
			TestSessionUpdateEvent,
			new TestSessionUpdateEvent({
				testSessionID,
				testDate,
				testDateTouched,
				listTsa,
				testType: TestType.YN,
				notes,
				testID,
				studentID,
			}),
		);
	});

	return {
		state,
		setState,
		getAnswers,
		setAnswers,
		getAnswersByState,
		correctAnswersHeader,
		setSummaryNote,
		setActiveAnswerPanel,
	};
};
