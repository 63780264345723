import {FontStyle, ShapeType, TextAlign, TextStyle} from 'shared/modules/question-editor/models';
import {ColorPicker} from 'shared/modules/question-editor/mx/editor/color-picker/color-picker';
import React from 'react';
import {DropdownInModel} from '@esgi/deprecated/elements/dropdown';
import {CustomDropdown} from '@esgi/deprecated/elements';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {ToolButton} from 'shared/modules/question-editor/mx/editor/toolbars/tool-button/tool-button';
import {Fonts} from 'shared/modules/assets/fonts';
import './text-toolbar.less';

export class Props {
	shapeStyle: TextStyle;
	mostRecentColors: string[];
	styleChanged: (style: TextStyle, change: string, value: any) => void;
	mostRecentColorsChanged: (colors: string[]) => void;
}

export class TextToolbar extends React.PureComponent<Props> {
	fontSizes: DropdownInModel[] = [];

	constructor(props, context) {
		super(props, context);

		this.fontSizes = [
			{id: 8, value: '6pt'},
			{id: 11, value: '8pt'},
			{id: 16, value: '12pt'},
			{id: 19, value: '14pt'},
			{id: 21, value: '16pt'},
			{id: 24, value: '18pt'},
			{id: 27, value: '20pt'},
			{id: 29, value: '22pt'},
			{id: 32, value: '24pt'},
			{id: 35, value: '26pt'},
			{id: 37, value: '28pt'},
			{id: 40, value: '30pt'},
			{id: 53, value: '40pt'},
			{id: 67, value: '50pt'},
			{id: 80, value: '60pt'},
			{id: 93, value: '70pt'},
			{id: 107, value: '80pt'},
			{id: 120, value: '90pt'},
			{id: 133, value: '100pt'},
			{id: 147, value: '110pt'},
			{id: 160, value: '120pt'},
			{id: 173, value: '130pt'},
			{id: 187, value: '140pt'},
			{id: 200, value: '150pt'},
			{id: 213, value: '160pt'},
			{id: 227, value: '170pt'},
			{id: 240, value: '180pt'},
			{id: 253, value: '190pt'},
			{id: 267, value: '200pt'},
		];
	}

	fontStyleIsEnabled(style: FontStyle) {
		return (this.props.shapeStyle.fontStyle & style) == style;
	}

	changeFontStyle(style: FontStyle) {
		let newStyle = {...this.props.shapeStyle};

		if (this.fontStyleIsEnabled(style)) {
			newStyle.fontStyle = newStyle.fontStyle - style;
		} else {
			newStyle.fontStyle = newStyle.fontStyle + style;
		}

		this.props.styleChanged(newStyle, 'fontStyle', style);
	}

	changeStyle(style: string, value: any) {
		let newStyle = {...this.props.shapeStyle};
		newStyle[style] = Number.isInteger(value) ? +value : value;
		this.props.styleChanged(newStyle, style, value);
	}

	render() {
		return <div className='mx-question-editor-text-toolbar'>
			<div className='text-toolbar-header'>STYLES</div>
			<div className='text-toolbar-line'></div>
			<CustomDropdown
				items={Fonts.map(item => (
					{
						key: item.systemName,
						value: item.systemName,
						title: item.displayName,
						style: item.systemName !== 'AbcMath' ? {fontFamily: item.systemName} : {fontFamily: 'Arial'},
					}),
				)}
				preventDefault={true}
				className='font-family-toolbar-dropdown'
				value={this.props.shapeStyle.fontFamily}
				onSelect={(t) => this.changeStyle('fontFamily', t.key)}
			/>

			<div className='font-size-label'>
				<div>Font Size</div>
				{this.props.shapeStyle.fontSize < 80 &&
				<OnHoverTooltip message='Note: Recommended font size for viewing as a Flash card is 60pts or above.'>
					<svg className='alert-font-size-icon' width='17' height='15' viewBox='0 0 17 15' fill='none'
						 xmlns='http://www.w3.org/2000/svg'>
						<path d='M0 14.25H16.5L8.25 0L0 14.25ZM9 12H7.5V10.5H9V12ZM9 9H7.5V6H9V9Z' fill='#FEC702'/>
					</svg>
				</OnHoverTooltip>}
			</div>
			<CustomDropdown
				items={this.fontSizes.map(item => (
					{
						key: item.id,
						value: item.id,
						title: item.value,
					}),
				)}
				preventDefault={true}
				className='font-size-toolbar-dropdown'
				value={this.props.shapeStyle.fontSize}
				onSelect={(t) => this.changeStyle('fontSize', t.key)}
			/>

			<div className='toolbar-format-menu'>
				<ToolButton
					selected={this.fontStyleIsEnabled(FontStyle.Bold)}
					onClick={() => this.changeFontStyle(FontStyle.Bold)}
				>
					<svg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M0.394531 13V0.203125H4.87695C6.42969 0.203125 7.60742 0.501953 8.41016 1.09961C9.21289 1.69141 9.61426 2.56152 9.61426 3.70996C9.61426 4.33691 9.45312 4.89062 9.13086 5.37109C8.80859 5.8457 8.36035 6.19434 7.78613 6.41699C8.44238 6.58105 8.95801 6.91211 9.33301 7.41016C9.71387 7.9082 9.9043 8.51758 9.9043 9.23828C9.9043 10.4688 9.51172 11.4004 8.72656 12.0332C7.94141 12.666 6.82227 12.9883 5.36914 13H0.394531ZM3.03125 7.42773V10.8818H5.29004C5.91113 10.8818 6.39453 10.7354 6.74023 10.4424C7.0918 10.1436 7.26758 9.7334 7.26758 9.21191C7.26758 8.04004 6.66113 7.44531 5.44824 7.42773H3.03125ZM3.03125 5.56445H4.98242C6.3125 5.54102 6.97754 5.01074 6.97754 3.97363C6.97754 3.39355 6.80762 2.97754 6.46777 2.72559C6.13379 2.46777 5.60352 2.33887 4.87695 2.33887H3.03125V5.56445Z'
							fill='white'/>
					</svg>
				</ToolButton>

				<ToolButton
					selected={this.fontStyleIsEnabled(FontStyle.Italic)}
					onClick={() => this.changeFontStyle(FontStyle.Italic)}
				>
					<svg width='6' height='14' viewBox='0 0 6 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M3.0791 14H0.996094L2.64844 4.49023H4.73145L3.0791 14ZM2.94727 2.05566C2.94727 1.7334 3.05273 1.45801 3.26367 1.22949C3.47461 1.00098 3.75879 0.880859 4.11621 0.869141C4.45605 0.857422 4.7373 0.962891 4.95996 1.18555C5.18848 1.40234 5.30273 1.66895 5.30273 1.98535C5.30273 2.31934 5.19141 2.59473 4.96875 2.81152C4.75195 3.02832 4.47363 3.14258 4.13379 3.1543C3.7998 3.16602 3.51855 3.06641 3.29004 2.85547C3.06152 2.63867 2.94727 2.37207 2.94727 2.05566Z'
							fill='white'/>
					</svg>
				</ToolButton>

				<ToolButton
					selected={this.fontStyleIsEnabled(FontStyle.Underline)}
					onClick={() => this.changeFontStyle(FontStyle.Underline)}
				>
					<svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M11.29 0.203125V8.75488C11.29 10.1143 10.8535 11.1924 9.98047 11.9893C9.11328 12.7803 7.95605 13.1758 6.50879 13.1758C5.04395 13.1758 3.88086 12.7861 3.01953 12.0068C2.1582 11.2217 1.72754 10.1348 1.72754 8.74609V0.203125H3.94238V8.76367C3.94238 9.61914 4.15918 10.2725 4.59277 10.7236C5.02637 11.1748 5.66504 11.4004 6.50879 11.4004C8.21973 11.4004 9.0752 10.498 9.0752 8.69336V0.203125H11.29Z'
							fill='white'/>
						<path d='M0.628906 14.7578H12.3623V15.6367H0.628906V14.7578Z' fill='white'/>
					</svg>
				</ToolButton>
			</div>
			<div className='toolbar-format-menu'>
				<ToolButton
					selected={this.props.shapeStyle.align === TextAlign.Left}
					onClick={() => this.changeStyle('align', TextAlign.Left)}
				>
					<svg width='22' height='15' viewBox='0 0 22 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M10.7812 2.23828H0.0351562V0H10.7812V2.23828ZM21.6094 2.23828H10.8633V0H21.6094V2.23828ZM10.7812 8.23828H0.0351562V6H10.7812V8.23828ZM10.7812 14.2383H0.0351562V12H10.7812V14.2383ZM21.6094 14.2383H10.8633V12H21.6094V14.2383Z'
							fill='white'/>
					</svg>
				</ToolButton>

				<ToolButton
					selected={this.props.shapeStyle.align === TextAlign.Center}
					onClick={() => this.changeStyle('align', TextAlign.Center)}
				>
					<svg width='22' height='15' viewBox='0 0 22 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M10.9531 2.23828H0.207031V0H10.9531V2.23828ZM21.7812 2.23828H11.0352V0H21.7812V2.23828ZM10.9531 8.23828H0.207031V6H10.9531V8.23828ZM21.7812 8.23828H11.0352V6H21.7812V8.23828ZM16.3672 14.2383H5.62109V12H16.3672V14.2383Z'
							fill='white'/>
					</svg>
				</ToolButton>

				<ToolButton
					selected={this.props.shapeStyle.align === TextAlign.Right}
					onClick={() => this.changeStyle('align', TextAlign.Right)}
				>
					<svg width='22' height='15' viewBox='0 0 22 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M11.2188 2.23828H21.9648V0H11.2188V2.23828ZM0.390625 2.23828H11.1367V0H0.390625V2.23828ZM11.2188 8.23828H21.9648V6H11.2188V8.23828ZM11.2188 14.2383H21.9648V12H11.2188V14.2383ZM0.390625 14.2383H11.1367V12H0.390625V14.2383Z'
							fill='white'/>
					</svg>
				</ToolButton>
			</div>
			<ColorPicker
				title='Color'
				bottomPlace={true}
				mostRecentColorsChanged={(colors) => this.props.mostRecentColorsChanged(colors)}
				mostRecentColors={this.props.mostRecentColors}
				color={this.props.shapeStyle.fontColor}
				onSelect={(value: string) => this.changeStyle('fontColor', value)}/>
		</div>;
	}
}

export namespace Events {
	export function StyleChanged(args: StyleChanged.Args): StyleChanged.Args {
		return args;
	}

	export module StyleChanged {
		export interface Args {
			type: ShapeType,
			style: TextStyle;
			change: string;
			value: any;
		}
	}
}

