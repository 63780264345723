import {ReportFiltersLabelContainer} from '../report-filter';
import {
	RadioStyled,
	ReportFilterContainerStyled,
	ReportFilterLabelStyled,
	ReportFiltersContentStyled,
} from './index.styled';

type MarkingPeriodFilterProps = {
	onChanged: (showCurrentMarkingPeriod: boolean) => void;
	showCurrentMarkingPeriod: boolean;
};

export function MarkingPeriodFilter({showCurrentMarkingPeriod, onChanged}: MarkingPeriodFilterProps) {
	return (
		<ReportFilterContainerStyled>
			<ReportFiltersLabelContainer>
				<ReportFilterLabelStyled dataCy='marking-period-label'>Marking Period:</ReportFilterLabelStyled>
			</ReportFiltersLabelContainer>
			<ReportFiltersContentStyled>
				<RadioStyled
					value='Current'
					checked={!!showCurrentMarkingPeriod}
					onChange={() => onChanged(true)}
					data-cy='current-radio'
				>
					Current
				</RadioStyled>
				<RadioStyled
					value='All'
					checked={!showCurrentMarkingPeriod}
					onChange={() => onChanged(false)}
					data-cy='all-radio'
				>
					All
				</RadioStyled>
			</ReportFiltersContentStyled>
		</ReportFilterContainerStyled>
	);
}
