import {BehaviorSubject, from, Observable, switchMap, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {
	ChangedStudents,
	ConflictAction,
	ConflictStudentField,
	ConflictStudentModel,
	ConflictStudentsRecheckModel,
	ConflictType,
	SaveConflictStudentsResponse,
	ServerConflictStudentModel,
	Validation,
	ValidationType,
} from './types';

export class ConflictResolutionService extends BaseService {

	public conflictStudents = new BehaviorSubject<ConflictStudentModel[] | null>(null);
	public changedStudents = new BehaviorSubject<ChangedStudents[] | null>(null);
	public recheckedStudents = new BehaviorSubject<ServerConflictStudentModel[]>(null);
	public initialState = new BehaviorSubject<ConflictStudentModel[] | null>(null);

	public excludedStudentsToCheck = new BehaviorSubject<number[]>([]);
	public excludedStudentsToNamesCheck = new BehaviorSubject<number[]>([]);
	public excludedStudentsToLanguageCheck = new BehaviorSubject<number[]>([]);

	private classID: number;
	private currentUser = getUser();
	private controller = 'v2/teachers/modules/students/roster-tool';

	public init = (conflictStudents: ServerConflictStudentModel[] | null, classID: string) => {
		const initialStudents = this.parseToViewStudents(conflictStudents);

		this.conflictStudents.next(initialStudents);
		this.initialState.next(initialStudents);
		this.classID = Number(classID);

		return new Observable((subscriber) => {
			subscriber.next();
			subscriber.complete();
		});
	};

	public validate = () => {
		const filteredStudentsList = this.getStudents(this.conflictStudents.value);

		const conflictStudentIDNList = filteredStudentsList.filter(st => st.studentIDN.validation.type !== ValidationType.None);
		const conflictNameList = filteredStudentsList.filter(st => st.name.conflictAction !== ConflictAction.AddAnyway).filter(st => st.name.validation.type !== ValidationType.None);

		return conflictNameList.length === 0 && conflictStudentIDNList.length === 0;
	};

	public edit = (students: ConflictStudentModel[]) => this.conflictStudents.next(students);

	public upload = () => {
		const students = this.conflictStudents.value.map(s => {
			const newStudent = {...s};
			if (s.studentIDN.validation.type === ValidationType.Conflict) {
				newStudent.studentIDN.validation.type = ValidationType.Full;
			}
			return newStudent;
		});

		this.conflictStudents.next(students);

		if (this.validate()) {
			return this.save(this.conflictStudents.value);
		} else {
			return from([]);
		}
	};

	private recheckConflicts = (students: ConflictStudentModel[]) => {
		const {excludedStudentsToCheck, excludedStudentsToNamesCheck, excludedStudentsToLanguageCheck} = this.filterExcludedStudentsIDs(students);

		const model = new ConflictStudentsRecheckModel(
			this.classID,
			this.currentUser.schoolID,
			this.parseToSaveStudents(students),
			excludedStudentsToCheck,
			excludedStudentsToNamesCheck,
			excludedStudentsToLanguageCheck,
		);

		return this.httpClient.ESGIApi.post<{ conflictStudents: ServerConflictStudentModel[] }>(this.controller, 'conflicts/recheck', model)
			.pipe(tap((r) => this.recheckedStudents.next(r.conflictStudents || [])));
	};

	private resolveConflicts = () => {
		return this.httpClient.ESGIApi.post<SaveConflictStudentsResponse>(this.controller, 'conflicts/resolve', {classID: this.classID});
	};

	private save = (students: ConflictStudentModel[]) => {
		return this.recheckConflicts(students).pipe(switchMap((recheckStudents) => {
			if (!recheckStudents?.conflictStudents.length) {
				return this.resolveConflicts();
			}

			this.conflictStudents.next(this.parseToViewStudents(recheckStudents.conflictStudents));

			return from([]);
		}));
	};

	private parseToSaveStudents = (students: ConflictStudentModel[]): ChangedStudents[] => {
		return students?.filter(st => st.studentIDN.conflictAction !== ConflictAction.Delete)?.map(student => {
			const {name, id, studentIDN, language, createDate, gradeLevel, gender} = student;
			const [firstName, lastName] = name.value.split(' ');

			return new ChangedStudents(
				id,
				firstName,
				lastName,
				studentIDN.value,
				language.value,
				createDate,
				gradeLevel,
				gender,
			);
		});
	};

	private parseToViewStudents = (students: ServerConflictStudentModel[]): ConflictStudentModel[] => {
		return students?.map(s => {
			return new ConflictStudentModel(
				s.id,
				s.gradeLevel,
				s.createDate,
				s.gender,
				s.existingStudents,
				new ConflictStudentField(
					s.studentIDN.value,
					new Validation(
						ValidationType[s.studentIDN.validationType],
						ConflictType[s.studentIDN.conflictType],
						s.studentIDN.validationMessage || 'This ID is taken',
					),
					ConflictAction.Fix,
				),

				new ConflictStudentField(
					s.name.value,
					new Validation(
						ValidationType[s.name.validationType],
						ConflictType[s.name.conflictType],
						s.name.validationMessage || 'This name matches another student',
					),
					ConflictAction.Fix,
				),

				new ConflictStudentField(
					s.language.value,
					new Validation(
						ValidationType[s.language.validationType],
						ConflictType[s.language.conflictType],
						s.language.validationMessage || 'This Language does not exist',
					),
					ConflictAction.Fix,
				),
			);
		});
	};

	private filterExcludedStudentsIDs = (students: ConflictStudentModel[]): {
		excludedStudentsToCheck: number[],
		excludedStudentsToNamesCheck: number[],
		excludedStudentsToLanguageCheck: number[],
	} => {
		const filterFixedStudents = this.getStudents(students);

		const excludedStudentsToCheck = students.filter(student => student.studentIDN.conflictAction === ConflictAction.Delete).map(st => st.id);
		const excludedStudentsToNamesCheck = filterFixedStudents.filter(student => student.name.conflictAction === ConflictAction.AddAnyway).map(st => st.id);
		const excludedStudentsToLanguageCheck = filterFixedStudents.filter(s => s.language.value === 'Other' && s.language.conflictAction !== ConflictAction.Delete).map(s => s.id);

		return {
			excludedStudentsToCheck,
			excludedStudentsToNamesCheck,
			excludedStudentsToLanguageCheck,
		};
	};

	private getStudents = (students: ConflictStudentModel[]) => students?.filter(s => s.studentIDN.conflictAction !== ConflictAction.Delete) || [];
}
