import {useCollapse} from '@esgillc/ui-kit/layout';
import {SidePanel} from 'shared/page/layout/side-panel/side-panel';
import {Route} from '../../../hooks/use-navigation';
import {AssignmentInfo} from '../../../services/types';
import {AssignmentStatus} from '../../../typings/assignment';
import {Divider} from '../divider';
import {SideBarItem} from './item';
import {countByStatus} from './utils';
import styles from './styles.module.less';

interface Props {
	assignments: AssignmentInfo[];
	route: Route | '';
	onItemClick: (route: Route) => void;
	onHelpClick: VoidFunction;
}

export function AssignmentCenterMenu(props: Props) {
	const {assignments, onItemClick, onHelpClick} = props;
	const [collapseProps, collapsed, toggle] = useCollapse();

	function navigateProps(route: Route) {
		return {
			onClick: () => onItemClick(route),
			selected: props.route === route,
		};
	}

	const counts = countByStatus(assignments);

	return (
		<SidePanel className={styles.sidebar}>
			{!!assignments.length &&
				<>
					<SideBarItem
						name={`All Assignments (${assignments.length})`}
						withIcon
						collapsed={collapsed}
						{...navigateProps(Route.List)}
						onToggle={(e) => {
							// prevent redirecting to 'All assignments' when clicking on the arrow
							e.stopPropagation();
							toggle(!collapsed);
						}}
					/>
					<div
						{...collapseProps}
						className={styles.subMenu}
					>
						<SideBarItem
							name={`In Progress (${counts[AssignmentStatus.Published]})`}
							{...navigateProps(Route.ListInProgress)}
						/>
						<SideBarItem
							name={`Not Started (${counts[AssignmentStatus.NotStarted]})`}
							{...navigateProps(Route.ListNotStarted)}
						/>
						<SideBarItem
							name={`Completed (${counts[AssignmentStatus.Completed]})`}
							{...navigateProps(Route.ListCompleted)}
						/>
						<SideBarItem
							name={`Draft (${counts[AssignmentStatus.Draft]})`}
							{...navigateProps(Route.ListDraft)}
						/>
					</div>
					<Divider/>
					<SideBarItem
						name='Student Credentials'
						className={styles.help}
						{...navigateProps(Route.StudentCredentials)}
					/>
					<Divider/>
				</>
			}
			<SideBarItem
				name='Help'
				selected={false}
				className={styles.help}
				onClick={onHelpClick}
			/>
		</SidePanel>
	);
}
