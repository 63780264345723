import {FontFamily, FontSizeCalculationResult, LecturerModel, NameFormat} from '../types';
import {Typo} from './typo';

export function getLecturerName(lecturer: LecturerModel) {
	if (lecturer == null) {
		return '';
	}
	return `${lecturer.title && `${lecturer.title} ` || ''}${lecturer.firstName} ${lecturer.lastName}`;
}

export function getNameByFormat(firstName: string, lastName: string, nameFormat: NameFormat) {
	return nameFormat === NameFormat.FirstAndLastName
		? `${firstName} ${lastName}`
		: firstName;
}

export function convertEnumToFontFamily(fontFamily: FontFamily) {
	switch (fontFamily) {
		case FontFamily.DottedLined: return 'AbcPrintDottedLined';
		case FontFamily.Normal: return 'AbcPrint';
		case FontFamily.Lined: return 'AbcPrintLined';
		default: return 'AbcPrint';
	}
}

export function calculateMaxPossibleFontSize(text: string, containerWidth: number, initialFontSize: number, fontFamily: string): FontSizeCalculationResult {
	return Typo.getMaxPossibleFontSize(
		text,
		containerWidth,
		initialFontSize,
		10,
		fontFamily)
}