import {
	Button,
	observable,
	observableArray,
	OldAlerts,
	Validators,
} from '@esgi/deprecated/knockout';
import {MakePaymentResponse, OrderSummaryItem} from '../models';
import {Step} from '../steps';
import {InitPaymentResponse} from './models';
import {PurchaseTemplate} from './purchase-template';
import {ServerClient} from 'pages/landing/kit/server-client';
import {PurchaseForm} from 'pages/landing/kit/purchase-form';

export class OrderSummary {
	@observableArray()
	public items: OrderSummaryItem[];
	@observable()
	public total: number;

	static fromResponse(response: InitPaymentResponse): OrderSummary {
		const orderSummary = new OrderSummary();

		for (let i = 0; i < response.items.length; i++) {
			const orderSummaryItem = new OrderSummaryItem();
			orderSummaryItem.quantity = response.items[i].quantity;
			orderSummaryItem.name = response.items[i].name;
			orderSummaryItem.amount = response.items[i].amount;
			orderSummaryItem.months = response.items[i].months;

			orderSummary.items.push(orderSummaryItem);
		}
		orderSummary.total = response.total;
		return orderSummary;
	}
}

export class PurchaseStep extends Step {
	constructor(public quoteId: number) {
		super();
		this.title = 'Purchase';

		this.purchaseButton = this.createPurchaseButton();
		this.purchaseForm = this.createPurchaseForm();

		this.purchaseForm.promoCode = this.createPromoCode();
	}

	private createPromoCode() {
		const promoCodeValidator = Validators.Ajax(
			(f) =>
				ServerClient.SSOAPI.Get('Renewal', 'checkPromoCode', {
					data: {promoCode: f.value()},
				})
					.done(() => {
						f.validation.successValidation(true);
						f.validation.errorValidation(true);
					})
					.always(() => {
						f.validation.validationInProgress(false);
					}),
			(v) => '',
		);

		const f = this.createField('', promoCodeValidator);

		f.validation.showValidation(true);
		f.validation.successValidation(true);

		return f;
	}

	purchaseForm: PurchaseForm;

	@observable()
	purchaseButton: Button;

	@observable()
	orderSummary: OrderSummary;

	load(): any {
		const async = $.Deferred();

		const task1 = this.purchaseForm
			.load()
			.done(() => {})
			.fail(() => {
				async.reject();
			});
		const task2 = this.server
			.init()
			.done((result: InitPaymentResponse) => {
				this.orderSummary = OrderSummary.fromResponse(result);
			})
			.fail(() => async.reject());

		const tasks = [task1, task2];

		$.whenAll<any>(tasks).always(() => async.resolve());

		return async;
	}

	serialize() {
		return this.purchaseForm.serialize();
	}

	private createPurchaseForm() {
		const form = new PurchaseForm();
		form.allowPayPal = false;
		form.showOrderOverview = false;

		this.addElement(form);
		return form;
	}

	private createPurchaseButton() {
		const purchaseButton = new Button();
		purchaseButton.title('Purchase');
		purchaseButton.className('btn btn-warning pull-right');
		purchaseButton.iconplacement('right');

		return purchaseButton;
	}

	server = {
		init: () => {
			return ServerClient.SSOAPI.Get<InitPaymentResponse>(
				'Quotes',
				'InitPayment',
				{data: {quoteId: this.quoteId}},
			).fail((error) => {
				OldAlerts.bsalert(error.Message);
			});
		},
	};

	view = {
		purchaseSuccess: (r: MakePaymentResponse) => {
			$(this).trigger('purchaseSuccess', r);
		},
	};

	events = {
		purchaseClicked: (callback) => {
			this.purchaseButton.events.click(callback);
		},
		purchaseSuccess: (
			callback: (e: Event, data: MakePaymentResponse) => void,
		) => {
			$(this).on('purchaseSuccess', callback);
		},
	};

	template = () => {
		return PurchaseTemplate.render();
	};
}
