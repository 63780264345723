export function PurchaseTemplate() {
	return <div data-bind='afterRender: true, var: {step: $data}'>
		<ko data-bind='render: purchaseForm'>
		</ko>
		<div className='clearfix' style={{marginTop: '25px'}}>
			<button className='btn btn-cancel pull-left' type='button' data-bind='click: view.back'>Back</button>
			<ko data-bind='render: purchaseButton'>
			</ko>
		</div>
	</div>;
}
