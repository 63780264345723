import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {TopicItem} from '../../types';
import styles from './styles.module.less';

interface Props {
	control: FormControl;
	topics: TopicItem[];
	onChange: (id: number) => void;
}

export function Topic({control, topics, onChange}: Props) {

	useStreamEffect(control.onChanged, topic => {
		if (topic.currState.value.length) {
			onChange(topic.currState.value[0].id);
		}
	});

	return <div data-cy='topic' className={styles.row}>
		<div className={styles.titleContainer}>
			<label data-cy='topic-title' className={styles.title}>Test Topic:</label>
		</div>

		<FormElement className={styles.topicContainer} control={control}>
			<Dropdown placeholder='Select a topic' optionName='name'>
				{topics.map(v => <Option value={v} key={v.id}> {v.name} </Option>)}
			</Dropdown>
		</FormElement>
	</div>;
}
