import React, {Dispatch} from 'react';
import {SubjectTab} from '@esgi/main/libs/store';
import {CardViewType, TestDetailsState, TestSectionContentType} from '../../../../types';
import {UserInfo} from '@esgi/core/authentication';
import {SingleStudentCard} from './components/single-student-card';
import {GroupStudentsCard} from './components/group-students-card';
import {CardsContainer} from './index.styled';
import {GroupStudentsViewModeContent, SingleStudentViewModeContent} from './types';

type Props = {
	subject: SubjectTab;
	openTestDetails: Dispatch<TestDetailsState>;
	currentUserId: UserInfo['userID'] | undefined;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	isSmallScreen: boolean;
	cardViewType: CardViewType;
	canRemove: boolean;
} & (SingleStudentViewModeContent | GroupStudentsViewModeContent);

export function ViewMode({
	subject,
	openTestDetails,
	currentUserId,
	isSmallScreen,
	onTestMoveTo,
	onRemoveTest,
	cardViewType,
	...props
}: Props) {
	if (props.contentType === TestSectionContentType.SingleStudent) {
		const {
			allTests,
			onTestCardClicked,
			showSelfAssessOption,
			student,
			studentClassID,
			statisticEntity,
			setTestLauncherState,
		} = props;

		return (
			<CardsContainer cardsVariant={cardViewType}>
				{allTests.map(({analyticsData, lastTestSessions, studentResult, studentsResult, testInfo}) => (
					<SingleStudentCard
						testInfo={testInfo}
						analyticsData={analyticsData}
						studentResult={studentResult}
						lastTestSessions={lastTestSessions}
						studentsResult={studentsResult}
						showSelfAssessOption={showSelfAssessOption}
						setTestLauncherState={setTestLauncherState}
						studentID={student.id}
						subject={subject}
						classID={studentClassID}
						onTestMoveTo={onTestMoveTo}
						onRemoveTest={onRemoveTest}
						openTestDetails={openTestDetails}
						userCanEditTest={currentUserId === testInfo.creatorID}
						userCanRemoveTest={props.canRemove}
						onTestCardClicked={onTestCardClicked}
						cardViewType={cardViewType}
						isSmallScreen={isSmallScreen}
						statisticEntity={statisticEntity}
						key={`single-${cardViewType}-${testInfo.id}`}
					/>
				))}
			</CardsContainer>
		);
	}

	if (props.contentType === TestSectionContentType.GroupStudents) {
		const {allTests, onTestCardClicked, classID, statisticEntity} = props;

		return (
			<CardsContainer cardsVariant={cardViewType}>
				{allTests.map(({analyticsData, classResult, lastTestSessions, studentsResult, testInfo}) => (
					<GroupStudentsCard
						analyticsData={analyticsData}
						classResult={classResult}
						lastTestSessions={lastTestSessions}
						studentsResult={studentsResult}
						testInfo={testInfo}
						onTestCardClicked={onTestCardClicked}
						userCanEditTest={currentUserId === testInfo.creatorID}
						userCanRemoveTest={props.canRemove}
						openTestDetails={openTestDetails}
						isSmallScreen={isSmallScreen}
						onTestMoveTo={onTestMoveTo}
						onRemoveTest={onRemoveTest}
						classID={classID}
						subjectID={subject.id}
						statisticEntity={statisticEntity}
						cardViewType={cardViewType}
						key={`group-${cardViewType}-${testInfo.id}`}
					/>
				))}
			</CardsContainer>
		);
	}

	return null;
}
