import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ControlValue, FormControl, FormGroup, ValidatorArgs, Validators} from '@esgi/ui/form';

export type StudentTestingOptionsFormType = FormGroup<{
	lockScreen: FormControl<boolean>,
	screenLockPIN: FormControl<string>,
	confirmPin: FormControl<string>,
	pinsEqual: FormControl<string>,
	selfAssessment: FormControl<boolean>,
},
	ControlValue<{
		lockScreen: FormControl<boolean>,
		screenLockPIN: FormControl<string>,
		confirmPin: FormControl<string>,
		pinsEqual: FormControl<string>,
		selfAssessment: FormControl<boolean>,
	}>>

export const createStudentTestingOptionsForm = ():
	StudentTestingOptionsFormType => new FormGroup({
	lockScreen: new FormControl(false),
	screenLockPIN: new FormControl('', {validators: [Validators.length.min(4), Validators.length.max(4)]}),
	confirmPin: new FormControl('', {validators: [Validators.length.min(4), Validators.length.max(4)]}),
	pinsEqual: new FormControl(''),
	selfAssessment: new FormControl(false),
});

export const equalPinsValidator = (pin: FormControl<string>) => {
	return function (validationChain: Observable<ValidatorArgs<string>>) {
		return validationChain.pipe(tap(args => {
			const valid = pin.value === args.field.currentValue;
			if (!valid) {
				args.errors.push('pinsNotEqual');
			}
		}));
	};
};
