import {Alert, alertColorsConfig} from '../alert';
import {Button} from '../buttons';
import {GridBox} from '../layout/grid-box';
import {Text} from '../typography';
import {AlertBody, alertRootCss, StyledButton} from './index.styled';

type Props = {
	onClose: VoidFunction,
	onContinueEditing: VoidFunction,
};

export function RemoveStandardDialog(props: Props) {
	const alert = Alert.useRef();
	const close = Alert.useClose(alert, props.onClose);

	const onContinueEditingClick = Alert.useClose(alert, props.onContinueEditing);

	return (
		<Alert modalManagerRef={alert} colorConfig={alertColorsConfig.negative} css={alertRootCss} zIndex={1055}>
			<Alert.Header withBacklight={false} onCloseIconClick={close}>
				<Text size='large' bold>Removing Standard from Test</Text>
			</Alert.Header>
			<AlertBody>
				<GridBox dataCy='test-description' gap='1'>
					<Text size='medium' color='neutral40'>
						Changing Content Area and Grade level, adding a new test question, editing, and removing existing test questions will result in removing the standard from the test.
					</Text>
					<Text size='medium' color='neutral40'>
						Are you sure you want to proceed with changes?
					</Text>
				</GridBox>
			</AlertBody>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={close}>
						<Text size='medium' bold>
							Cancel
						</Text>
					</Button>
					<StyledButton color='secondary' onClick={onContinueEditingClick}>
						<Text size='medium' color='negative' bold>
							Continue editing
						</Text>
					</StyledButton>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
