import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {DetailReportSettingsTeacher} from './components/teacher';
import {DetailReportSettingsClasses} from './components/classes';
import {DetailReportSettingsGroups} from './components/groups';
import {DetailReportSettingsSpecialistGroups} from './components/specialist-groups';
import {DetailReportSettingsStudents} from './components/students';
import {DetailReportSettingsSubjects} from './components/subjects';
import {DetailReportSettingsGradeScale} from './components/grade-scale';
import {DetailReportSettingsShowBlock} from './components/show-block';
import {DetailReportSettingsResultsBlock} from './components/results-block';
import {DetailReportSettingsDisplayZero} from './components/display-zero';
import {ReportService} from '../../../../services/report-service';
import {DetailReportSettingsMarkingPeriods} from './components/marking-periods';
import styles from './styles.module.less';

interface Props {
	service: ReportService;
}

export function DetailReportSettings({service}: Props): JSX.Element {
	const report = useBehaviorSubject(service.dataService.report);

	return (
		<div className={styles.reportSettings}>
			<div className={styles.firstRow}>
				<DetailReportSettingsTeacher service={service.settingsService}/>
				<DetailReportSettingsClasses service={service.settingsService}/>
				<DetailReportSettingsGroups service={service.settingsService}/>
				<DetailReportSettingsSpecialistGroups service={service.settingsService}/>
				<DetailReportSettingsStudents service={service.settingsService}/>
				<DetailReportSettingsSubjects service={service.settingsService}/>
			</div>
			{report && report.students.length !== 0 &&
			<div className={styles.secondRow}>
				<DetailReportSettingsGradeScale service={service}/>
				<DetailReportSettingsShowBlock reportService={service} />
				<DetailReportSettingsResultsBlock service={service} />
				<DetailReportSettingsMarkingPeriods service={service.settingsService} />
				<DetailReportSettingsDisplayZero reportService={service} />
			</div>
			}
		</div>
	);
}
