import {ContentWrapper} from './index.styled';
import {Select} from '@esgi/main/features/teacher/home';
import {Input} from '@esgi/ui/controls';
import {TrackType} from '../../../../types';
import {trackTypeOptions} from './options';
import {ChangeEvent, useCallback, useState} from 'react';
import {Box} from '@esgi/ui/layout';

type Props = {
	trackName: string | null;
	setTrackName: React.Dispatch<React.SetStateAction<string | null>>;
	trackType: TrackType | null;
	setTrackType: React.Dispatch<React.SetStateAction<TrackType | null>>;
};

export function CurrentMarkingPeriod({setTrackName, trackName, setTrackType, trackType}: Props) {
	const [errorMessage, setErrorMessage] = useState<string>();

	const onTrackNameChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;

			setTrackName(value);
			setErrorMessage(!value ? 'Marking period name is empty' : undefined);
		},
		[setTrackName],
	);

	return (
		<ContentWrapper>
			<Box>
				<Input
					dataCy='current-marking-period-input'
					placeholder='Marking Period Name'
					value={trackName ?? ''}
					onChange={onTrackNameChange}
					error={errorMessage}
				/>
			</Box>
			<Select<TrackType>
				dataCy='current-marking-period-select'
				placeholder='Type'
				items={trackTypeOptions}
				selectedValue={trackType ?? trackTypeOptions[0]?.value}
				onValueChange={setTrackType}
			/>
		</ContentWrapper>
	);
}
