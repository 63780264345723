import React from 'react';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Hint, Label, OnErrorTooltip, Input} from '@esgillc/ui-kit/form-control';
import styles from '../confirm.module.less';

interface Props {
	control: FormControl<string>;
	initialValue: string;
}

const MAX_NAME_LENGTH = 65;

export default class NameField extends React.PureComponent<Props> {
	public componentDidMount() {
		this.props.control.value = this.props.initialValue;
	}

	public render() {
		return <div className={styles.field}>
			<FormElement control={this.props.control} className={styles.element}>
				<Label className={styles.topLabel}>Rubric Name</Label>
				<Input autoFocus className={styles.name}
				       placeholder='Enter Rubric Name'
				       maxLength={MAX_NAME_LENGTH}/>
				<Hint className={styles.limitCounter}>{(props) => {
					return `${MAX_NAME_LENGTH - (props.value?.length | 0)} characters left (${MAX_NAME_LENGTH} max)`;
				}}</Hint>
				<OnErrorTooltip showOnError='required' placement='right'>Name
					required</OnErrorTooltip>
			</FormElement>
		</div>;
	}
}
