import React from 'react';
import ParentLetterEditorModal from '../parent-letter-editor-modal';
import {Dropdown, Option} from '@esgillc/ui-kit/control';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {FlexBox} from '@esgillc/ui-kit/layout';
import styles from './parent-letter-editor.module.less';
import {FormModel, LanguageModel, LetterTemplateModel} from './models';
import {SubjectType} from '@esgi/core/enums';
import {Service} from './service';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {LanguageTemplateForm} from '../language-template-form';
import {Language} from '../models';

interface Props {
	subjectType: SubjectType;
	userID: number;
	onClosed: () => void;
	hierarchy: HierarchySnapshot;
}

class State {
	defaultTemplate: LetterTemplateModel;
	template: LetterTemplateModel;
	languages: LanguageModel[];
	language: LanguageModel;
	languageModalMode: 'edit' | 'create' | null;
	loading: boolean;
	form: FormModel;
}

export default class ParentLetterEditor extends React.Component<Props, State> {
	state = new State();
	service: Service;

	public componentDidMount() {
		this.service = new Service(this.props.userID, this.props.subjectType);
		this.service.template$.subscribe(t => this.setState({template: t}));
		this.service.defaultTemplate$.subscribe(t => this.setState({defaultTemplate: t}));
		this.service.form$.subscribe(f => this.setState({form: f}));
		this.service.languages$.subscribe(l => this.setState({languages: l}));
		this.service.selectedLanguage$.subscribe(l => this.setState({language: l}));

		this.service.init();
	}

	public componentWillUnmount() {
		this.service.destroy();
	}

	renderLanguageTemplateForm() {
		const {language} = this.state;
		const isEdit = this.state.languageModalMode === 'edit';
		const onChange = (l) => isEdit ? this.service.onLanguageUpdated(l) : this.service.onLanguageCreated(l);
		const modalLanguage = isEdit ? {
			languageName: language.languageName,
			languageId: language.languageID,
			isDefault: language.isDefault,
		} as Language : null;
		return this.state.languageModalMode &&
			<LanguageTemplateForm language={modalLanguage} onClose={() => this.setState({languageModalMode: null})}
			                      hierarchy={this.props.hierarchy} readOnly={false}
			                      onLanguageUpdated={onChange}/>;
	}

	renderPencil(language: LanguageModel) {
		return <span onClick={() => this.setState({languageModalMode: 'edit'})} className={styles.editControl}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='14'
					height='14'
					viewBox='0 0 14 14'
					fill='none'
				>
					<path
						d='M0 10.6894V13.5019H2.8125L11.1075 5.20688L8.295 2.39438L0 10.6894ZM13.2825 3.03188C13.575 2.73938 13.575 2.26688 13.2825 1.97438L11.5275 0.219375C11.235 -0.073125 10.7625 -0.073125 10.47 0.219375L9.0975 1.59188L11.91 4.40438L13.2825 3.03188Z'
						fill='#0088CC'
					/>
				</svg>
			</span>
		;
	}

	public renderBucket(language: LanguageModel) {
		return (
			<span
				className={styles.editControl}
				onClick={() => this.service.deleteLanguage(language.languageID)}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='11'
					height='13'
					viewBox='0 0 12 15'
					fill='none'
				>
					<path
						d='M1.16671 13.3333C1.16671 14.25 1.91671 15 2.83337 15H9.50004C10.4167 15 11.1667 14.25 11.1667 13.3333V3.33333H1.16671V13.3333ZM12 0.833333H9.08337L8.25004 0H4.08337L3.25004 0.833333H0.333374V2.5H12V0.833333Z'
						fill='#0088CC'
					/>
				</svg>
			</span>
		);
	}

	public render() {
		if (!this.state.template || !this.state.defaultTemplate || !this.state.form) {
			return;
		}

		return (
			<ParentLetterEditorModal
				defaultMessage={this.state.defaultTemplate.templateBody}
				title={'Parent Message'}
				message={this.state.template.templateBody}
				readonly={this.state.form.readonly || this.state.form.templateIsDistrict}
				readonlyMessage={this.state.form.districtTemplateMessage}
				onCloseClicked={this.props.onClosed}
				onSaveClicked={(message: string) => this.service.save(message)}
			>
			{this.renderLanguageTemplateForm()}
				<ServiceLoader trackingService={this.service} fullscreen/>
				<FlexBox
					align='center'
					className={styles.dropdownRow}
				>
					{(this.state.form.templateIsDistrict && this.state.form.logo) &&
						<div
							className={styles.logo}
							style={{
								backgroundImage: `url('data:${this.state.form.logo.contentType};base64,${this.state.form.logo.base64}')`,
							}}
						/>
					}
					{!this.state.form.templateIsDistrict &&
						<>
							<label className={styles.dropdownsLabel}>Message Language:</label>
					<Dropdown setValue={(v) => {
						if (v[0].languageID === 0) {
							this.setState({languageModalMode: 'create'});
						} else {
							this.service.setLanguage(v[0]);
						}
					}}
								className={styles.languageDropdown}
								optionName='languageName'
								value={[this.state.language]}
							>
								{(this.state.languages || []).map(v => (
									<Option
										key={v.languageID}
										value={v}
										className={!v.isDefault && styles.option}
									>
										{v.languageName}
										{(!v.isDefault && !this.state.form.readonly) &&
											<span className={styles.editControlsContainer}>
												{this.renderPencil(v)}
												{this.renderBucket(v)}
											</span>
										}
									</Option>
								))}
							</Dropdown>
						</>
					}
				</FlexBox>
			</ParentLetterEditorModal>
		);
	}
}
