import {HierarchySnapshot} from 'modules/hierarchy/models';
import {TestSelector} from 'modules/reports/test-selector';
import {Test} from 'modules/reports/test-selector/models';
import {SubjectType} from '@esgi/core/enums';
import {userStorage, useUser} from '@esgi/core/authentication';
import {TestsSelectorSourceService} from './services/tests-selector-source-service';
import StudentProgressReportModalReact from './modal';
import {Suspense, useMemo, useState} from 'react';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {useReportValidator} from 'modules/reports/student-detail-report/hooks/use-report-validator';

export interface Props {
	subjectId: number;
	subjectType: SubjectType;
	classId: number;
	groupId: number;
	specialistGroupID: number;
	studentId: number;
	teacherId: number;
	districtID: number;
	schoolID: number;
	changesCollector: ChangesCollector;
	hierarchy: HierarchySnapshot;
	onClose: () => void;
}

export default function Launcher(props: Props) {
	const [selectedSubjectType, setSelectedSubjectType] = useState<SubjectType>(props.subjectType);
	const [selectedSubjectId, setSelectedSubjectId] = useState<number>(props.subjectId);
	const [selectedSubjectLevel, setSelectedSubjectLevel] = useState<string>('');
	const [selectedTests, setSelectedTests] = useState<Test[]>([]);
	const service = useMemo(() => new TestsSelectorSourceService(props.hierarchy), []);
	const user = useUser();
	const [showReport, setShowReport] = useState<boolean>(false);

	const reportIsValid = useReportValidator({
		classId: props.classId,
		groupId: props.groupId,
		studentId: props.studentId,
		globalSchoolYearId: userStorage.get().globalSchoolYearID,
		subjectId: props.subjectId,
		subjectType: props.subjectType,
		userId: props.teacherId,
	}, props.hierarchy, props.onClose);

	const applyHomepageChangesAndClose = () => {
		props.changesCollector.applyChanges({
			subjectTabID: selectedSubjectId,
			subjectTabType: selectedSubjectType,
		});
	};

	const closeHandler = () => {
		setShowReport(false);
		service.destroy();
		props.onClose();
	};

	const closeLauncher = () => {
		applyHomepageChangesAndClose();
		setShowReport(false);
		service.destroy();
		props.onClose();
	};

	const onBack = () => {
		setShowReport(false);
	};

	return (
		<>
			{reportIsValid &&
				<Suspense fallback={<></>}>
					<TestSelector hierarchy={props.hierarchy}
					includeAll
					subjectID={props.subjectId}
					subjectType={props.subjectType}
					title='Student Progress Report'
					testsSourceService={service}
					onSubjectChanged={(s) => {
						setSelectedSubjectId(s.id);
						setSelectedSubjectType(s.subjectType);
					}}
					onRunReportClicked={(info) => {
						setShowReport(true);
						setSelectedSubjectLevel(info.subject.level);
						setSelectedTests(info.tests);
					}}
					onClose={() => closeLauncher()}/>
				</Suspense>
			}
			{showReport &&
			<Suspense fallback={<></>}>
				<StudentProgressReportModalReact
					globalSchoolYearId={user.globalSchoolYearID}
					classId={props.classId}
					groupId={props.groupId}
					specialistGroupID={props.specialistGroupID}
					studentId={props.studentId}
					teacherId={props.teacherId}
					subjectId={selectedSubjectId}
					subjectType={SubjectType[selectedSubjectType]}
					subjectLevel={selectedSubjectLevel}
					selectedTests={selectedTests.map(t => ({...t, subjectType: SubjectType[t.subjectType]}))}
					onClose={() => closeHandler()}
					onBack={() => onBack()}
					changesCollector={props.changesCollector}
					hierarchy={props.hierarchy}/>
			</Suspense>
			}
		</>
	);
}
