import React from 'react';

export class FormFieldTemplate {
	static render() {
		return <div className='form-group'
                    data-bind="css: {'has-error' : showErrorValidation, 'float': float}, afterRender: true">
			<div>
				<ko data-bind='if: title'>
					<label data-bind='text: title' className='control-label'/>
				</ko>
				<input className='form-control'
                       data-bind="value: value, attr: {'tabindex': tabindex, 'disabled': disabled, 'type': type}"/>
			</div>
		</div>;
	}
}

export class NumberFieldTemplate {
	static render() {
		return <div className='form-group'
                    data-bind="css: {'has-error' : showErrorValidation, 'float': float}, afterRender: true">
			<div>
				<ko data-bind='if: title'>
					<label data-bind='text: title' className='control-label'/>
				</ko>
				<input className='form-control' type='number'
                       data-bind="value: value, attr: {'tabindex': tabindex, 'disabled': disabled, min: min, max: max}"/>
			</div>
		</div>;
	}
}

export class SelectFormFieldTemplate {
	static render() {
		return <div className='form-group float'
                    data-bind="css: {'has-error' : showErrorValidation, 'float': float}, afterRender: true">
			<div>
				<ko data-bind='if: title'>
					<label data-bind='text: title' className='control-label'/>
				</ko>
				<select className='form-control'
                        data-bind="options: source, optionsText: optionsText, optionsCaption: caption, value: value, valueAllowUnset: valueAllowUnset, attr: {'tabindex': tabindex, 'disable': disabled}, chosen: chosen"/>
			</div>
		</div>;
	}
}

export class CheckboxFieldTemplate {
	static render() {
		return <div className='form-group' data-bind="css: {'has-error' : showErrorValidation}, afterRender: true">
			<label>
				<input type='checkbox' className='ace' data-bind='checked: value'/>
				<span className='lbl' data-bind='text: title'></span>
			</label>
		</div>;
	}
}

export class TextAreaFieldTemplate {
	static render() {
		return <div className='form-group float'
                    data-bind="css: {'has-error' : showErrorValidation}, afterRender: true">
			<textarea data-bind='value: value'></textarea><label data-bind='text: title'></label>
		</div>;
	}
}

export class ColorPickerFieldTemplate {
	static render() {
		return <div data-bind='var: {picker: $data}, afterRender: true'>
			<ko data-bind='if: title'>
				<label className='control-label' data-bind='text: title' htmlFor='testColor'/>
			</ko>
			<div className='color-picker'>
				<input type='text' className='form-control'
                       data-bind='value: value' placeholder='Color'/>
				<div className='color-picker-container'>
					<div className='dropdown dropdown-colorpicker'>
						<a data-toggle='dropdown' className='dropdown-toggle' data-position='auto' href='#'
                           data-bind="style: {'backgroundColor': value()}"/>
						<ul className='dropdown-menu dropdown-caret' data-bind='foreach: options'>
							<li>
								<a className='colorpick-btn selected' href='#'
                                   data-bind="style: {'background-color': $data}, css: {'selected': $data == picker.value()}, click: $parent.selectColor($data)"/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>;
	}
}