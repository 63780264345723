import {MutableRefObject, useRef} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ReportLeftPanel} from './report-left-panel';
import {ReportRightPanel} from './report-right-panel';
import {Buttons} from '@esgillc/ui-kit/button';
import {HierarchySnapshot} from '../../../hierarchy/models';
import {useUntestedReportEffects} from '../hooks/use-untested-report-effects';
import {UntestedStudentsReportService} from '../service';
import styles from './modal/modal.module.less';

interface Props {
	hierarchy: HierarchySnapshot;
	service: UntestedStudentsReportService
	rootElement: MutableRefObject<HTMLDivElement>;
	untestedStudentsReportRef: MutableRefObject<HTMLDivElement>;
	onClose: () => void;
}

export function ReportMainContainer({service, rootElement, hierarchy, onClose}: Props) {
	const emptyReport = useBehaviorSubject(service.emptyReport);
	const selectedTest = useBehaviorSubject(service.selectedTest);
	const rightBottomPanelRef = useRef<HTMLDivElement>(null);
	const rightTopPanelRef = useRef<HTMLDivElement>(null);
	const leftBottomPanelRef = useRef<HTMLDivElement>(null);
	const leftTopPanelRef = useRef<HTMLDivElement>(null);
	const testNamesRef = useRef<HTMLTableRowElement>(null);
	const leftPanelRef = useRef<HTMLDivElement>(null);
	const grTableShRef = useRef<HTMLTableRowElement>(null);
	const firstHeaderRef = useRef<HTMLTableRowElement>(null);

	useUntestedReportEffects({
		rightTopPanelRef,
		rightBottomPanelRef,
		leftBottomPanelRef,
		testNamesRef,
		rootElement,
		service,
		leftPanelRef,
		grTableShRef,
		leftTopPanelRef,
		firstHeaderRef,
	});

	return !emptyReport &&
		<div className={styles.mainContainer}>
			<div className={styles.reportBody}>
				<ReportLeftPanel
					service={service}
					leftBottomPanelRef={leftBottomPanelRef}
					firstHeaderRef={firstHeaderRef}
					leftTopPanelRef={leftTopPanelRef}
					grTableShRef={grTableShRef}
					leftPanelRef={leftPanelRef}
				/>
				<ReportRightPanel
					service={service}
					hierarchySnapshot={hierarchy}
					onClose={onClose}
					rightBottomPanelRef={rightBottomPanelRef}
					rightTopPanelRef={rightTopPanelRef}
					testNamesRef={testNamesRef}
				/>
				{selectedTest && selectedTest[0]?.testID > 0 &&
					<div className={styles.buttonLink}>
						<Buttons.Link onClick={() => service.backToAllTests()} className={styles.buttonLink}>
							Back to all tests in subject &gt;&gt;
						</Buttons.Link>
					</div>
				}
			</div>
		</div>;
}
