import {BaseService} from '@esgi/core/service';
import {getUser} from '@esgi/core/authentication';
import {BehaviorSubject, Observable, combineLatest, debounceTime, switchMap, of, tap} from 'rxjs';
import {FormatType, FontType, FontFamily, FileTypeType, UnitDataSettings, PrintSettings, StudentWithUnit, DownloadPDFRequest} from '../types';
import {GenerateService} from './generate-service';
import {DownloadService} from './download-service';
import {StudentService} from './student-service';

const wordsPerPage = {
	'40pt': 11,
	'62pt': 7,
};
const wordsCount = 7;

export class WritingPracticeService extends BaseService {
	public readonly students$ = new BehaviorSubject<StudentWithUnit[]>([]);
	public readonly format$ = new BehaviorSubject<FormatType>(null);
	public readonly font$ = new BehaviorSubject<FontType>(null);
	public readonly copies$ = new BehaviorSubject<string>(null);
	public readonly fileType$ = new BehaviorSubject<FileTypeType>(null);
	public readonly report$ = new BehaviorSubject<Omit<DownloadPDFRequest, 'documentDate'>>(null);
	public readonly unitDataSettings$ = new BehaviorSubject<UnitDataSettings>(null);
	public readonly printSettings$ = new BehaviorSubject<PrintSettings>(null);
	public readonly totalPages$: Observable<number> = this.completeOnDestroy(
		combineLatest([this.copies$, this.students$, this.font$])
	).pipe(
		debounceTime(100),
		switchMap(([copies, students, font]) => {
			if (isNaN(+copies) || !font) {
				return of(0);
			}

			const words = wordsPerPage[font.fontSize] || 14;
			const pagesPerStudent = Math.ceil(wordsCount / words);
			return of(pagesPerStudent * students.length * +copies);
		}),
	);
	public readonly valid$: Observable<boolean> = this.completeOnDestroy(
		combineLatest([this.students$])
	).pipe(
		debounceTime(100),
		switchMap(([students]) => {
			return of(Boolean(students.length));
		}),
	);
	public readonly busy$ = new BehaviorSubject<boolean>(false);
	public studentService = new StudentService();
	protected generateService = new GenerateService();
	protected downloadService = new DownloadService();
	protected currentUser = getUser();

	constructor() {
		super();

		this.completeOnDestroy(
			combineLatest([this.format$, this.copies$, this.font$])
		).pipe(
			debounceTime(100),
			tap(([nameFormat, copies, font]) => {
				if (nameFormat == null || copies == null || font == null) {
					return;
				}

				this.printSettings$.next({
					nameFormat,
					selectedFontSize: font.fontSize,
					copiesCount: +copies,
					fontFamily: FontFamily.DottedLined,
					wordsPerPage: wordsPerPage[font.fontSize] || 14,
				});
			}),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.students$])
		).pipe(
			debounceTime(100),
			tap(([students]) => this.unitDataSettings$.next({
				students: students.map(({studentId, unitData}) => {
					const student = this.studentService.students$.value.find(
						({id}) => id === studentId,
					);
					return {...student, unitData};
				}),
				lecturer: {
					firstName: this.currentUser.firstName,
					lastName: this.currentUser.lastName,
					title: '',
				},
			})),
		).subscribe();

		this.completeOnDestroy(
			combineLatest([this.downloadService.busy])
		).pipe(
			debounceTime(100),
			tap(([busy]) => this.busy$.next(busy)),
		).subscribe();
	}

	public destroy() {
		super.destroy();
		this.generateService.destroy();
		this.downloadService.destroy();
		this.studentService.destroy();
	}

	public setStudents(value: StudentWithUnit[]) {
		this.students$.next(value);
	}

	public setFormat(value: FormatType) {
		this.format$.next(value);
	}

	public setFont(value: FontType) {
		this.font$.next(value);
	}

	public setCopies(value: string) {
		this.copies$.next(value);
	}

	public setFileType(value: FileTypeType) {
		this.fileType$.next(value);
	}

	public onPreview() {
		this.generateReport();
	}

	public onDownload() {
		this.generateReport().add(() => {
			this.downloadService.downloadPdf(this.report$.value);
		});
	}

	private generateReport() {
		return this.completeOnDestroy(
			this.generateService.generateReport(
				this.unitDataSettings$.value,
				this.printSettings$.value
			),
		).subscribe((report) => {
			this.report$.next(report);
		});
	}
}
