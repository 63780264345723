import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';

export const TestInfoBox = styled(GridBox, {
	width: '100%',
	padding: 20,
	gap: 20,
	background: '$vivid',
	borderBottom: '1px solid $mild',
});

export const TestName = styled(FlexBox, {
	alignItems: 'center',
	'& >:first-child': {
		marginRight: 12,
	},
});

export const TestDetails = styled(GridBox, {
	alignItems: 'center',
	gridAutoFlow: 'column',
	justifyContent: 'start',
	gap: 20,
	'& path': {
		fill: '$neutral40',
	},
});

export const GradeIcon = styled(FlexBox, {
	justifyContent: 'center',
	alignItems: 'center',
	width: 19,
	height: 19,
	marginLeft: 5,
	border: '1px solid $neutral40',
	borderRadius: '50%',
});

export const Divider = styled(Box, {
	background: '$mild',
	width: '100%',
	height: 1,
});

export const IconWrapper = styled(FlexBox, {
	border: '1px solid $highContrast',
	borderRadius: '50%',
	overflow: 'hidden',

	'& > svg': {
		width: '15px',
		height: '15px',
		fill: '$highContrast',
		transform: 'scale(1.6) translateY(2px) translateX(1px)',

		'& > path': {
			fill: '$highContrast',
		},
	},
});
