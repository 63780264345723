import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	onCloseAlert: VoidFunction;
	onDeleteImage: (value: null) => void;
};

export function DeleteImageAlert({onCloseAlert, onDeleteImage}: Props) {
	const alertRef = Alert.useRef();

	const closeAlert = Alert.useClose(alertRef, onCloseAlert);
	const deleteImage = Alert.useClose(alertRef, () => {
		onDeleteImage(null);
	});

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='neutral24'>
					Delete Profile Photo
				</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='medium' color='neutral40'>
					Are you sure you want to delete the photo?
				</Text>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Discard
						</Text>
					</Button>
					<Button color='secondary' onClick={deleteImage}>
						<Text size='medium' bold color='base'>
							Delete
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
