import React, {useCallback} from 'react';
import {TextArea} from '@esgillc/ui-kit/control';
import {isIpad, join} from '@esgillc/ui-kit/utils';
import {NextRowButton} from '@esgi/deprecated/ui-kit/matrix';
import {RowHeight} from '../../utils';

import styles from './panel.module.less';

interface Props {
	notes: string;
	score: number;
	totalScore: number;
	editMode: boolean;
	onChanged: (notes: string) => void;
}

export function BottomPanel ({notes, score, totalScore, editMode, onChanged}: Props) {
	const summaryNotesOnChange = useCallback((e) => {
		const value = e.target.value.replace(/[\r\n\v]+/g, '');
		onChanged(value);
	}, [onChanged]);

	return (
		<div className={styles.bottomPanel}>
			<div className={styles.actionColumn}>
				<div>
					{!isIpad() && <NextRowButton className={styles.nextRowButton} rowHeight={RowHeight}/>}
				</div>
			</div>
			<div className={styles.notes}>
				<TextArea placeholder='Summary Notes'
				          maxLength={255}
				          value={notes}
				          disabled={!editMode}
				          className={styles.textArea}
				          onChange={editMode && summaryNotesOnChange}
				/>
				{editMode && <div className={styles.counter}>{notes?.length}/255</div>}
			</div>
			<div className={styles.totalColumn}>
				<div className={styles.scoreCellDivider}/>
				<div className={join(styles.totalScore, editMode && styles.active)}>
					<div className={styles.scoreCellHeader}>Total Score</div>
					<div className={styles.scoreCellBody} data-cy={'total-score'}><span>
						{score}/{totalScore}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
