import './styles.less';
import React from 'react';
import {LandingService} from '../../services/landing-service';
import {SubjectsService} from '../../services/subjects-service';
import FeaturedSeries from './featured-series/featured-series';
import GradeLevel from './grade-level/grade-level';
import NotedAuthors from './noted-authors/noted-authors';

class Props {
	landingService: LandingService;
	subjectsService: SubjectsService;
	onCardsLoaded: () => void;
}

export default class Landing extends React.PureComponent<Props> {
	render() {
		return <div className='landing'>
			<FeaturedSeries landingService={this.props.landingService} onLoaded={() => this.props.onCardsLoaded()}/>
			<NotedAuthors landingService={this.props.landingService} onLoaded={() => this.props.onCardsLoaded()}/>
			<GradeLevel landingService={this.props.landingService} subjectsService={this.props.subjectsService} onLoaded={() => this.props.onCardsLoaded()}/>
		</div>;
	}
}
