import {ComponentProps, useCallback} from 'react';
import {useFormElementContext} from '../../../../form';
import {SelectableList as BaseSelectableList} from '../../../../layout/selectable-list';

type SelectableListGroupRootProps = Omit<ComponentProps<typeof BaseSelectableList.GroupRoot>, 'onValueChange' | 'value' | 'defaultValue'> & {
	type: 'single' | 'multiple'
}

export function SelectableListGroupRoot({children, type = 'single', ...props}: SelectableListGroupRootProps) {

	const {value, setValue} = useFormElementContext(type === 'single' ? {
		string: true,
	} : {
		array: true,
	}, BaseSelectableList.GroupRoot);

	const onValueChange = useCallback((value: string | string[]) => setValue(value), [value]);

	const commonProps = {
		value,
		onValueChange,
		type,
		...props,
	};

	return <BaseSelectableList.GroupRoot {...commonProps}>
		{children}
	</BaseSelectableList.GroupRoot>;
}
