import {Student} from '@esgi/main/libs/store';
import {Test} from '../../../types';
import {AlertHeader} from '../../alert-header';
import {TestName} from '../../test-name';
import {TestType} from '@esgi/main/libs/core';
import {AlertFooter} from '../../alert-footer';
import {AlertBody} from '../../components.styled';
import {
	QuestionRowEditable,
	QuestionsContainer,
	AnswerState,
	YNAnswerToggleState,
	EditableSessionDateTimeInfo,
	maxYNCommentLength,
	getDateTimeWithGMT,
} from '../../../../kit';
import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {AnswerStateData, QuestionModel} from './types';
import {Textarea} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {DefaultSkeleton} from '../../default-skeleton';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {isUndefined, useBehaviorSubject, useStreamEffect} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {TestSessionYNCreatedEvent} from '../../../events';
import {useUser} from '@esgi/core/authentication';
import {useInitialUserDate} from '../../../hooks/use-initial-user-date';

type Props = {
	student: Student;
	test: Test;
	onCloseAlert: VoidFunction;
};

export function AddYesNoSession({student, test, onCloseAlert}: Props) {
	const currentUser = useUser();

	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isDataSaving, setIsDataSaving] = useState(false);

	const initialUserDate = useInitialUserDate();

	useEffect(() => {
		service.init({studentID: student.id, testID: test.id}).subscribe(() => {
			setIsDataLoaded(true);
		});
	}, []);

	const service = useService(Service);

	const [answersState, setAnswersState] = useState<AnswerStateData[]>([]);

	const questions = useBehaviorSubject(service.questions$);

	useStreamEffect(service.questions$, (questions) => {
		setAnswersState(
			questions.map(({questionId}) => ({questionID: questionId, comment: null, answerState: AnswerState.NotTested})),
		);
	});

	const [isSessionDateTimeValid, setIsSessionDateTimeValid] = useState(false);
	const [sessionNote, setSessionNote] = useState('');

	const editableSessionTimeRef = EditableSessionDateTimeInfo.useRef();

	const questionTitlesById = useMemo(() => {
		const questionTitlesById: Record<QuestionModel['questionId'], QuestionModel['name']> = {};

		questions.forEach(({questionId, name}) => (questionTitlesById[questionId] = name));

		return questionTitlesById;
	}, [questions]);

	const updateAnswersState = useCallback(
		(args: {questionID: QuestionModel['questionId']; newState: Partial<Omit<AnswerStateData, 'questionID'>>}) => {
			const {questionID, newState} = args;
			setAnswersState((currentAnswersState) =>
				currentAnswersState.map((iteratedState) =>
					iteratedState.questionID !== questionID
						? iteratedState
						: {
								...iteratedState,
								...newState,
						  },
				),
			);
		},
		[],
	);

	const onAddComment = useCallback(
		({questionID, comment}: {questionID: QuestionModel['questionId']; comment: string}) => {
			updateAnswersState({questionID, newState: {comment}});
		},
		[updateAnswersState],
	);

	const onDeleteComment = useCallback(
		({questionID}: {questionID: QuestionModel['questionId']}) => {
			updateAnswersState({questionID, newState: {comment: null}});
		},
		[updateAnswersState],
	);

	const onToggleAnswerState = useCallback(
		({newState, questionID}: {newState: AnswerState; questionID: QuestionModel['questionId']}) => {
			updateAnswersState({questionID, newState: {answerState: newState}});
		},
		[updateAnswersState],
	);

	const handleUpdateSessionNote = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value;

		setSessionNote(value);
	}, []);

	const onSave = () => {
		const dateTime = editableSessionTimeRef.current?.getFullDate();

		if (isUndefined(dateTime)) {
			return;
		}

		setIsDataSaving(true);

		const adaptedDateTime = getDateTimeWithGMT({
			dateTime,
			timeZone: currentUser?.timeZone,
		});

		service
			.save({
				testID: test.id,
				studentID: student.id,
				testDate: adaptedDateTime,
				notes: sessionNote,
				answersState,
			})
			.subscribe(({value: {testSessionID}}) => {
				setIsDataSaving(true);
				onCloseAlert();

				dispatchAppEvent(
					TestSessionYNCreatedEvent,
					new TestSessionYNCreatedEvent({
						testSessionID,
						testID: test.id,
						studentID: student.id,
						testDate: dateTime,
						notes: sessionNote,
						answers: answersState,
					}),
				);
			});
	};

	if (!isDataLoaded) {
		return <DefaultSkeleton onCloseAlert={onCloseAlert} />;
	}

	return (
		<>
			<AlertHeader student={student} onCloseAlert={onCloseAlert} />

			<AlertBody>
				<TestName testID={test.id} name={test.name} type={TestType.YN} />

				<GridBox flow='column' gap='3' columns='2' align='center'>
					<EditableSessionDateTimeInfo
						initialTestDate={initialUserDate}
						setisValid={setIsSessionDateTimeValid}
						editableSessionTimeRef={editableSessionTimeRef}
						timeZone={currentUser?.timeZone}
					/>
				</GridBox>

				<QuestionsContainer>
					{answersState.map(({comment, questionID, answerState}, index) => (
						<QuestionRowEditable
							questionNumber={index + 1}
							questionTitle={questionTitlesById[questionID] ?? ''}
							comment={comment}
							withQuestionUnderline={index !== answersState.length - 1}
							key={questionID}
							onAddComment={(comment) => onAddComment({comment, questionID})}
							onDeleteComment={() => onDeleteComment({questionID})}
							maxCommentLength={maxYNCommentLength}
						>
							<YNAnswerToggleState
								activeState={answerState}
								onToggle={({newState}) => onToggleAnswerState({newState, questionID})}
							/>
						</QuestionRowEditable>
					))}
				</QuestionsContainer>

				<Textarea
					placeholder='Summary Note'
					value={sessionNote}
					onChange={handleUpdateSessionNote}
					dataCy='summary-note'
				/>
			</AlertBody>

			<AlertFooter onSave={onSave} saveDisabled={!isSessionDateTimeValid || isDataSaving} />
		</>
	);
}
