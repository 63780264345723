import {useMemo} from 'react';
import {Column, FixedColumnPosition} from '@esgi/deprecated/ui-kit/report/table/types';
import {StudentResult} from '../../../../../types/table-level-report-service-types';
import styles from '../styles.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {RubricResultReportService} from '../../../../../service';
import {CellStyled, ViewChartDisabledBlockStyled} from '../index.styled';

export function useResultColumn({
	service,
	rows,
}: {
	service: RubricResultReportService;
	rows: StudentResult[] | undefined;
}) {
	return useMemo<Column<StudentResult>>(() => {
		const topHeaderCell = {
			renderer: () => <CellStyled data-cy='results-header-column'>Results</CellStyled>,
		};

		const bottomHeaderCell = {
			renderer: () => {
				const resultsExists = rows?.some(({hasResult}) => hasResult);

				return (
					<CellStyled>
						{resultsExists ? (
							<a href='#' onClick={() => service.openStudentLevelReport(0)}>
								View all
							</a>
						) : (
							<OnHoverTooltip message='This rubric does not have any results.'>
								<ViewChartDisabledBlockStyled data-cy='disabled-view-all'>View all</ViewChartDisabledBlockStyled>
							</OnHoverTooltip>
						)}
					</CellStyled>
				);
			},
		};

		const cells = [
			{
				renderer: ({hasResult, studentID}: StudentResult) => {
					return (
						<CellStyled>
							{hasResult ? (
								<a href='#' onClick={() => service.openStudentLevelReport(studentID)}>
									View chart
								</a>
							) : (
								<OnHoverTooltip message='This student does not have any results for this rubric.'>
									<ViewChartDisabledBlockStyled data-cy='disabled-view-chart' className={styles.viewChartDisabled}>View chart</ViewChartDisabledBlockStyled>
								</OnHoverTooltip>
							)}
						</CellStyled>
					);
				},
			},
		];

		const studentResultColumn: Column<StudentResult> = {
			fixedColumnPosition: FixedColumnPosition.Right,
			className: styles.resultsColumn,
			headers: [[topHeaderCell], [bottomHeaderCell]],
			cells,
		};

		return studentResultColumn;
	}, [rows]);
}
