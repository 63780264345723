import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {SubjectTab} from '@esgi/main/libs/store';
import {lazyComponent} from '@esgi/core/react';
import {withClassReportPage} from './wrapper';

const RubricResultsReport = lazyComponent(() => import('modules/reports/rubric-report'));

type Props = {
	open: boolean,
	close: VoidFunction,
	hierarchy: HierarchySnapshot,
	subject: SubjectTab
};

function RubricReport(props: Props) {
	const {
		open,
		close,
		hierarchy,
		subject,
	} = props;

	if(open) {
		return <RubricResultsReport
			hierarchy={hierarchy}
			onClosed={close}
			subjectID={subject.id}
			subjectType={subject.type as unknown as any}/>;
	}
}

export default withClassReportPage(RubricReport);