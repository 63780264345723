import {Wrapper, ButtonIcon, ButtonText, SortTable} from './index.styled';
import {CheckAll} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {TestListProps} from '../../../../types';

interface Props extends Pick<TestListProps, 'sort' | 'onSort' | 'onSelectAll'> {}

export function Action({sort, onSort, onSelectAll}: Props) {
	return (
		<Wrapper>
			<ButtonIcon onClick={onSelectAll} dataCy='check-all'>
				<CheckAll/>
			</ButtonIcon>
			<ButtonText onClick={onSort} dataCy='sort'>
				<Text size='small' data-cy='test-name-title'>Test Name</Text>
				<SortTable field={sort.field} direction={sort.direction}/>
			</ButtonText>
		</Wrapper>
	);
}
