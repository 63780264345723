import React from 'react';
import {UserType, userStorage} from '@esgi/core/authentication';
import {
	IBoxInfo,
	IClassBox,
	IGroupBox, IGroupModel, GroupModelForAdmin,
	ISchAdminBox,
	ISchoolBox, SchoolsGroupBox,
	IStudentBox,
	ITeacherBox, TeachersGroupBox,
} from '../core/api';
import {
	withClassID,
	withGroupID,
	withSchoolsGroupID,
	withSchoolID,
	withStudent,
	withTeacherID, withTeachersGroupID,
} from '../core/builders/classic';
import {BoxType} from '../core/models';
import {ClassicHierarchySnapshot} from '../models';
import {Class} from './boxes/class';
import {Group} from './boxes/group';
import {School} from './boxes/school';
import {Student} from './boxes/student';
import {Teacher} from './boxes/teacher';
import {StudentRosterToolEvents} from 'shared/modules/student-roster-upload-tool/events';
import {EventBusDispatcher} from '@esgillc/events';
import {SchoolsGroup} from './boxes/schools-group';
import {TeachersGroup} from './boxes/teachers-group';
import {ViewType} from 'modules/forms/admin-group-forms/models/types';

export class ClassicHierarchy extends React.Component<Props, State> {
	private currentUser = userStorage.get();

	render() {
		return <div className='hierarchy'>
			{this.renders.schoolsGroups()}
			{this.renders.schools()}
			{this.renders.teachersGroups()}
			{this.renders.teachers()}
			{this.renders.classes()}
			{this.renders.groups()}
			{this.renders.students()}
		</div>;
	}

	renders = {
		schools: () => {
			return <School
				boxes={this.props.boxes}
				schAdmins={this.props.schAdmins}
				selectedID={this.props.snapshot.schoolID}
				onSelected={(id, cb) => this.on.selected.school(id, cb)}
				schoolsGroupID={this.props.snapshot.schoolsGroupID}
				schools={this.props.schools}
				onOpenCloseChanged={this.props.onOpenCloseChanged}/>;
		},
		classes: () => {
			return <Class
				boxes={this.props.boxes}
				selectedID={this.props.snapshot.classID}
				teacherID={this.props.snapshot.teacherID}
				classes={this.props.classes}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				onSelected={(id, callback) => this.on.selected.class(id, callback)}/>;
		},
		groups: () => {
			return <Group
				boxes={this.props.boxes}
				onOrderChanged={this.props.onGroupsChanged}
				classID={this.props.snapshot.classID}
				groups={this.props.groups}
				classes={this.props.classes}
				selectedID={this.props.snapshot.groupID}
				teacherID={this.props.snapshot.teacherID}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				onSelected={(id, callback) => this.on.selected.group(id, callback)}/>;
		},
		schoolsGroups: () => {
			return <SchoolsGroup
				boxes={this.props.boxes}
				groups={this.props.schoolsGroups}
				snapshot={this.props.snapshot}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				onOrderChanged={null}
				onSelected={(id, callback) => this.on.selected.schoolsGroup(id, callback)}/>;
		},
		teachersGroups: () => {
			return <TeachersGroup
				boxes={this.props.boxes}
				groups={this.props.teachersGroups}
				snapshot={this.props.snapshot}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				onOrderChanged={this.props.onAdminGroupsChanged}
				onSelected={(id, callback) => this.on.selected.teachersGroup(id, callback)}/>;
		},
		students: () => {
			return <Student
				boxes={this.props.boxes}
				teacherID={this.props.snapshot.teacherID}
				selectedID={this.props.snapshot.studentID}
				classID={this.props.snapshot.classID}
				onOpenStudentProfile={() => this.on.onOpenStudentProfile()}
				groupID={this.props.snapshot.groupID}
				schoolID={this.props.snapshot.schoolID}
				teacherGroupID={this.props.snapshot.teachersGroupID}
				teachers={this.props.teachers}
				students={this.props.students}
				onDragStart={this.props.onDragStart}
				onDragEnd={this.props.onDragEnd}
				scheduledIDs={this.props.scheduledIDs}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				onSelected={(id, cb) => this.on.selected.student(id, cb)}
			/>;
		},
		teachers: () => {
			return <Teacher
				boxes={this.props.boxes}
				selectedID={this.props.snapshot.teacherID}
				teachersGroupID={this.props.snapshot.teachersGroupID}
				teachers={this.props.teachers}
				onSelected={this.on.selected.teacher}
				onOpenCloseChanged={this.props.onOpenCloseChanged}
				schoolID={this.props.snapshot.schoolID}/>;
		},
	};

	on = {
		selected: {
			class: (id: number, cb: () => void) => {
				this.props.onChanged(withClassID(this.props.snapshot, id, this.props.students), cb);
			},
			group: (id: number, cb: () => void) => {
				this.props.onChanged(withGroupID(this.props.snapshot, id, this.props.students), cb);
			},
			schoolsGroup: (id: number, cb: () => void) => {
				this.props.onChanged(withSchoolsGroupID(this.props.snapshot, id, this.props.schools), cb);
			},
			teachersGroup: (id: number, cb: () => void) => {
				this.props.onChanged(withTeachersGroupID(this.props.snapshot, id), cb);
			},
			student: (id: number, cb: () => void) => {
				const s = this.props.students.items.find(s => s.studentID === id);
				const h = withStudent(this.props.snapshot, s);
				this.props.onChanged(h, cb);
			},
			teacher: (id: number, cb: () => void) => {
				let h = withTeacherID(this.props.snapshot, id);

				if ([UserType.D, UserType.C].indexOf(this.currentUser.userType) >= 0) {
					const classes = this.props.classes.items.filter(t => t.userID === id);
					classes.sort(Class.sort);
					if (classes.length) {
						h = withClassID(h, classes[0].classID, this.props.students);
					}
				}

				this.props.onChanged(h, cb);
			},
			school: (id: number, cb: () => void) => {
				const h = withSchoolID(this.props.snapshot, id);
				this.props.onChanged(h, cb);
			},
		},
		onOpenStudentProfile: () => {
			EventBusDispatcher.dispatch(StudentRosterToolEvents.AddStudentClicked);
		},
	};
}

export class State {

}

export class Props {
	boxes: IBoxInfo[];
	snapshot: ClassicHierarchySnapshot;
	schools: ISchoolBox;
	teachers: ITeacherBox;
	classes: IClassBox;
	groups: IGroupBox;
	schoolsGroups: SchoolsGroupBox;
	teachersGroups: TeachersGroupBox;
	students: IStudentBox;
	schAdmins: ISchAdminBox;


	onChanged: (item: ClassicHierarchySnapshot, cb: () => void) => void;
	onGroupsChanged: (items: IGroupModel[]) => void;
	onAdminGroupsChanged: (items: GroupModelForAdmin[], viewType: ViewType) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
	onDragStart?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	onDragEnd?: (event: React.DragEvent<HTMLAnchorElement>, id?: number) => void;
	scheduledIDs?: Array<number>;
}
