import {AssignmentState} from '@esgi/main/kits/assignments';
import {Assignment} from '../../../types';
import {noTestsMessage} from './constants';
import {TableTests, TableTestsRowData, TableTestsHeader} from '../table-tests';
import {EmptyContent} from '../empty-content';
import {CommonAssignmentProps} from './types';
import {AssignmentHeader} from './components/assignment-header';
import {AssignmentBody} from './components/assignment-body';
import {Dispatch, useCallback, useState} from 'react';
import {MissingStudentCredentialsAlert, StudentWitoutCredentials} from './components/missing-student-credentials-alert';

type Props = CommonAssignmentProps & {
	onAssignmentPost: (args: {assignmentId: Assignment['id']; assignmentName: string}) => void;
	onOpenEditAssignment: Dispatch<Assignment['id']>;
	swapTests: (index1: number, index2: number) => void;
};

export function AssignmentNotStarted({
	assignment,
	onAssignmentPost,
	onOpenEditAssignment,
	studentsWithoutAccess,
	swapTests,
	students,
	sortStudentsByKey,
	onDeleteAssignment,
	onOpenExportCredentialsAlert,
	onOpenStudentCredentials,
	onOpenCredentialsForStudentsInAssignment,
	disabledOpenExportStudentsCredentials,
}: Props) {
	const testsCount = assignment.tests.length;

	const [studentsWithoutCredentials, setStudentsWithoutCredentials] = useState<StudentWitoutCredentials[]>([]);

	const closeMissingCredentialsAlert = useCallback(() => {
		setStudentsWithoutCredentials([]);
	}, []);

	const onActionButtonClick = useCallback(() => {
		if (studentsWithoutAccess.length) {
			setStudentsWithoutCredentials(
				students
					.filter(({hasCredentials}) => !hasCredentials)
					.map<StudentWitoutCredentials>(({studentID, firstName, lastName}) => ({
						id: studentID,
						firstName,
						lastName,
					})),
			);
			return;
		}

		onAssignmentPost({
			assignmentId: assignment.id,
			assignmentName: assignment.name,
		});
	}, [assignment.id, assignment.name, studentsWithoutAccess, onAssignmentPost, students]);

	const getTableTests = useCallback(() => {
		if (!testsCount) {
			return <EmptyContent messageText={noTestsMessage} />;
		}

		return (
			<TableTests>
				<TableTestsHeader studentsCount={students.length} />

				{assignment.tests.map((test, index) => (
					<TableTestsRowData
						{...test}
						studentsCount={students.length}
						ordered={testsCount > 1}
						moveUpDisabled={!index}
						onMoveUpClick={() => swapTests(index, index - 1)}
						moveDownDisabled={index === testsCount - 1}
						onMoveDownClick={() => swapTests(index, index + 1)}
						key={test.id}
					/>
				))}
			</TableTests>
		);
	}, [testsCount, students, assignment, swapTests]);

	return (
		<>
			<AssignmentHeader
				studentsWithoutAccessCount={studentsWithoutAccess.length}
				hasTestsInAssignment={Boolean(testsCount)}
				onDeleteAssignment={() => onDeleteAssignment({assignmentId: assignment.id, assignmentName: assignment.name})}
				onOpenEditAssignment={() => onOpenEditAssignment(assignment.id)}
				withActionButton
				actionButtonLabel='Post to Students'
				onActionButtonClick={onActionButtonClick}
				actionButtonDisabled={!testsCount || !students.length}
				onOpenExportCredentialsAlert={onOpenExportCredentialsAlert}
				disabledOpenExportStudentsCredentials={disabledOpenExportStudentsCredentials}
			/>
			<AssignmentBody
				name={assignment.name}
				state={AssignmentState.NotStarted}
				description={assignment.description}
				testsCount={testsCount}
				displayedTime={assignment.created}
				displayedTimeType='Created'
				sortStudentsByKey={sortStudentsByKey}
				students={students}
				onOpenStudentCredentials={onOpenStudentCredentials}
				onOpenCredentialsForStudentsInAssignment={onOpenCredentialsForStudentsInAssignment}
			>
				{getTableTests()}
			</AssignmentBody>

			{Boolean(studentsWithoutCredentials.length) && (
				<MissingStudentCredentialsAlert
					onAlertClose={closeMissingCredentialsAlert}
					onPostToStudents={() => {
						onAssignmentPost({
							assignmentId: assignment.id,
							assignmentName: assignment.name,
						});
					}}
					students={studentsWithoutCredentials}
					onOpenCredentialsForStudentsInAssignment={onOpenCredentialsForStudentsInAssignment}
				/>
			)}
		</>
	);
}
