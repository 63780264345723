import {createContext, useContext} from 'react';
import {useService} from '@esgi/core/service';
import {StudentDetailService} from './services';

export const useStudentDetail = () => {
	const service = useService(StudentDetailService);

	return {service};
};

export const StudentDetailContext = createContext(null);
export const useStudentDetailService = () =>
	useContext<StudentDetailService>(StudentDetailContext);
