import {BaseService} from '@esgi/core/service';
import {IntoPageInitResponse} from './types';
import {userStorage} from '@esgi/core/authentication';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class DataService extends BaseService {
	private readonly controller: string = 'writing-practice';

	public init(hierarchy: HierarchySnapshot) {
		const request = {
			globalSchoolYearID: userStorage.get().globalSchoolYearID,
			model: hierarchy,
		};

		return this.httpClient.ESGIApi.post<IntoPageInitResponse>(this.controller, 'intro/init', request);
	}

}
