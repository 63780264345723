import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {SelectAllClassesButton} from './components/select-all-classes-button';
import {useClassBucketFilteredBySubject} from '../../hooks/use-class-bucket-filtered-by-subject';

export function ClassesContent() {
	const {classBuckets} = StudentsPanel.Tabs.Classes.useClassesContext();

	const filteredClassBuckets = useClassBucketFilteredBySubject(classBuckets);
	const {
		selectClass,
		selectStudentFromClass,
		selected: {studentFromClassId, studentId: selectedStudentId, classId: selectedClassId},
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			<SelectAllClassesButton />

			{filteredClassBuckets.length ? (
				filteredClassBuckets.map(({id, name, students}) => {
					const isOpen = selectedClassId === id || studentFromClassId === id;

					return (
						<StudentsPanel.Lists.SelectableListGroup.Expandable.Single
							withCounter
							isListOpen={isOpen && Boolean(students.length)}
							key={id}
							hideArrowIcon={isOpen}
							onTriggerButtonClick={() => selectClass(id)}
							groupName={name}
							onItemClick={(studentId) => selectStudentFromClass({studentId, classId: id})}
							value={selectedStudentId}
							isListSelected={selectedClassId === id}
							items={students}
							allowSelectEmpty
						>
							{({item: {firstName, lastName}}) => (
								<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
							)}
						</StudentsPanel.Lists.SelectableListGroup.Expandable.Single>
					);
				})
			) : (
				<StudentsPanel.Tabs.EmptyTabContent size='full' templateText='No classes to display' />
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
