import {styled} from '@esgi/ui/theme';
import {Alert} from '@esgi/ui/alert';
import {Link as ReactLink} from 'react-router-dom';
import {FlexBox} from '@esgi/ui/layout';

export const Link = styled(ReactLink, {
	display: 'inline-flex',
	alignItems: 'center',
});

export const AlertHeader = styled(FlexBox, {
	display: 'flex',
	alignItems: 'center',
});

export const AlertBody = styled(Alert.Body, {
	width: '600px',
});