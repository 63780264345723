import {Text} from '@esgi/ui/typography';
import {Select as SelectUI} from '@esgi/ui/controls';
import {OptionItem} from './types';
import {SelectField, SelectOption} from './index.styled';

type Props<T extends string = string> = {
	selectedValue: T;
	items: OptionItem<T>[];
	onValueChange: (value: T) => void;
	placeholder: string;
	disabled: boolean;
	defaultValue?: T;
	displayDefaultLabel?: boolean;
	dataCy?: string;
};

export function Select<T extends string = string>({
	selectedValue,
	onValueChange,
	items,
	placeholder,
	disabled,
	defaultValue,
	displayDefaultLabel,
	dataCy = 'test-panel-select-ui-root',
}: Props<T>) {
	const permittedItems = items.filter(({permitted}) => Boolean(permitted));

	return (
		<SelectUI.Root value={selectedValue} onValueChange={onValueChange} disabled={disabled} dataCy={dataCy}>
			<SelectField placeholder={placeholder} dataCy={`${dataCy}-field`} />
			<SelectUI.Content dataCy={`${dataCy}-content`}>
				{permittedItems.map(({label, value, labelDataCy}) => (
					<SelectOption
						value={value}
						key={value}
						displayDefaultLabel={Boolean(defaultValue === value && displayDefaultLabel)}
						dataCy={labelDataCy}
					>
						<Text size='medium' bold data-cy={`${labelDataCy}-label`}>
							{label}
						</Text>
					</SelectOption>
				))}
			</SelectUI.Content>
		</SelectUI.Root>
	);
}
