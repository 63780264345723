import {BoxPlotChartRoot} from './components/boxplot-chart-root';
import {BoxplotChartRound} from './variant/boxplot-chart-round';
import {BoxplotChartCandle} from './variant/candle';

export const BoxplotChart = Object.assign(BoxPlotChartRoot, {
	Round: BoxplotChartRound,
	Candle: BoxplotChartCandle,
});

export {type BoxplotChartCandleProps} from './variant/candle';
export {type BoxplotChartRoundProps} from './variant/boxplot-chart-round';
