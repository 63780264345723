import {styled} from '@esgi/ui/theme';
import {Input} from '@esgi/ui/controls';

export const HiddenInput = styled(Input, {
	width: 0,
	height: 0,
	overflow: 'hidden',
	opacity: 0,
	pointerEvents: 'none',
	display: 'none',
});
