import {SubjectType} from '@esgi/core/enums';

export type SubjectTab = {
	id: number;
	name: string;
	subjectType: SubjectType;
	hidden: boolean;
	level: string;
	gradeLevels: number[];
}

export type SubjectsResponse = {
	subjects: Array<SubjectTab>;
	deployedSubjects: Array<SubjectTab>;
	stockSubjects: Array<SubjectTab>;
	selectedSubject: SubjectTab;
}

export type TestsResponse = {
	subjectTests: SubjectTest[];
}

export type SubjectTest = {
	subjectID: number;
	tests: Array<Test>;
}

export type Test = {
	testID: number;
	subjectID: number;
	subjectType: SubjectType;
	subjectLevel: string;
	name: string;
	isTested: boolean;
}


export class SubjectInfo {
	subjectID: number;
	subjectType: SubjectType;
	subjectLevel: string;

	constructor(item: SubjectTab) {
		this.subjectID = item.id;
		this.subjectType = item.subjectType;
		this.subjectLevel = item.level;
	}
}

export type SelectedInfo = {
	subject: SubjectTab;
	tests: Array<Test>;
}
