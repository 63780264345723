import {SubjectTab} from '@esgi/main/libs/store';
import {SelectContent, SelectField, SelectOption} from './index.styled';
import {Skeletonable} from '@esgi/ui';
import {ChangeFunction} from '../../../types';
import {SubjectName} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';

interface Props extends Skeletonable {
	subjects: SubjectTab[];
	value: string;
	disabled?: boolean;
	onChange: ChangeFunction<string>;
}

export function SubjectControl(props: Props) {
	const {skeleton, subjects, value, disabled, onChange} = props;

	return (
		<Select.Root value={value} disabled={disabled} onValueChange={(value) => onChange(value)} dataCy='subject-select'>
			<SelectField skeleton={skeleton} placeholder='Subject Tab' />
			<SelectContent>
				{subjects.map(({id, name, level}) => (
					<SelectOption value={String(id)} key={id}>
						<SubjectName subjectLevel={level}>{name}</SubjectName>
						<Text size='medium' bold data-placeholder-value=''>
							{name}
						</Text>
					</SelectOption>
				))}
			</SelectContent>
		</Select.Root>
	);
}
