import {DatePicker as UIDatePicker} from '@esgi/ui/controls';
import {DatePickerWrapper} from './index.styled';

type Props = {
	value: Date[],
	onValueChange: (value: Array<string | Date>) => void,
}

export function DatePicker ({value, onValueChange}: Props) {
	return (
		<DatePickerWrapper dataCy='date'>
			<UIDatePicker.Root format={'mM/dD/YY'} value={value || []} onChange={onValueChange} rangeMode>
				<UIDatePicker.DropdownTrigger>
					<UIDatePicker.Input placeholder={'Date Range'} onClear={() => onValueChange(undefined)} dataCy='date-input'/>
				</UIDatePicker.DropdownTrigger>
				<UIDatePicker.DropdownBody>
					<UIDatePicker.CalendarPanel />
				</UIDatePicker.DropdownBody>
			</UIDatePicker.Root>
		</DatePickerWrapper>
	);
}