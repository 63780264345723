import React from 'react';
import {CheckboxList, InModel} from '@esgi/deprecated/elements/checkbox-list';
import {CheckboxOptions, CheckedMode} from '@esgi/deprecated/elements';
import {Transparent} from '@esgi/deprecated/elements/buttons';
import {BaseGroupService} from 'modules/forms/admin-group-forms/services/base-group-service';
import {PreModel} from 'modules/forms/admin-group-forms/models/types';
import styles from 'modules/forms/admin-group-forms/styles.module.less';
import {Subject, takeUntil} from 'rxjs';

export class State {
	groupName: string = '';
	availableItems: InModel[] = [];
	itemsInGroup: InModel[] = [];
	touched: boolean = false;
}

export class Props {
	service: BaseGroupService;
	preModel: PreModel;
	title: string;
	newGroupLabel: string;
	loaded: () => void;
}

export class GroupForm extends React.Component<Props, State> {
	private readonly onDestroy$: Subject<void> = new Subject<void>();

	constructor(props: Props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		if (this.props.preModel.groupID) {
			this.server.initEdit();
		} else {
			this.server.initCreate();
			this.props.service.availableItems$.pipe(takeUntil(this.onDestroy$)).subscribe(x => this.setState({availableItems: x}));
			this.props.service.itemsInGroup$.pipe(takeUntil(this.onDestroy$)).subscribe(x => this.setState({itemsInGroup: x}));
		}

	}

	server = {
		initEdit: () => {
			this.props.service.initEdit(this.props.preModel).subscribe(() => {
				this.setState({
					itemsInGroup: this.props.service.itemsInGroup$.value,
					availableItems: this.props.service.availableItems$.value,
					groupName: this.props.preModel.groupName,
				});
				this.props.loaded();
			});
		},
		initCreate: () => {
			this.props.service.initCreate(this.props.preModel).subscribe(() => {
				this.setState({
					availableItems: this.props.service.availableItems$.value,
				});
				this.props.loaded();
			});
		},
	};

	selectByFilterAvailable(checked: boolean) {
		let newState = {...this.state};
		if (checked) {
			newState.availableItems = this.state.availableItems.map((item) => {
				return {...item, checked: true};
			});

			if (this.state.availableItems.length > 0) {
				newState.itemsInGroup = this.state.itemsInGroup.map((item) => {
					return {...item, checked: false};
				});
			}
		} else {
			newState.availableItems = this.state.availableItems.map((item) => {
				return {...item, checked: false};
			});
		}

		this.setState(newState);
	}

	moveToAvailable() {
		const checkedItems = [...this.state.itemsInGroup.filter(x => x.checked)];
		const itemsInGroup = [...this.state.itemsInGroup.filter(x => !x.checked)];
		const availableItems = [...this.state.availableItems.concat(checkedItems)].map(x => x.checked ? {
			...x,
			checked: false,
		} : x);

		this.setState({
			itemsInGroup: itemsInGroup,
			availableItems: availableItems,
		});

		this.props.service.itemsInGroup$.next(itemsInGroup);
		this.props.service.availableItems$.next(availableItems);
	}

	checkboxOptionsMode(items: string) {
		if (this.state[items].filter(x => x.checked).length === 0) {
			return CheckedMode.None;
		}

		if (this.state[items].filter(x => !x.checked).length === 0) {
			return CheckedMode.All;
		}

		if (this.state[items].filter(x => x.checked).length > 0) {
			return CheckedMode.Partially;
		}
	}

	selectAvailable(id: number, checked: boolean) {
		let newState = {...this.state};

		newState.itemsInGroup = this.state.itemsInGroup.map((item) => {
			return {...item, checked: false};
		});

		newState.availableItems = this.state.availableItems.map(item => item.id === id ?
			{...item, checked: checked} :
			item,
		);

		this.setState(newState);
	}

	selectItemsInGroup(id: number, checked: boolean) {
		let newState = {...this.state};

		newState.availableItems = this.state.availableItems.map((item) => {
			return {...item, checked: false};
		});

		newState.itemsInGroup = this.state.itemsInGroup.map(item => item.id === id ?
			{...item, checked: checked} :
			item,
		);

		this.setState(newState);
	}

	moveToGroup() {
		const checkedItems = [...this.state.availableItems.filter(x => x.checked)];
		const availableItems = [...this.state.availableItems.filter(x => !x.checked)];
		const itemsInGroup = [...this.state.itemsInGroup.concat(checkedItems)]
			.map(x => x.checked ? {
				...x,
				checked: false,
			} : x);

		this.setState({
			availableItems: availableItems,
			itemsInGroup: itemsInGroup,
		});
		this.props.service.itemsInGroup$.next(itemsInGroup);
		this.props.service.availableItems$.next(availableItems);
	}

	selectByFilterInGroup(checked: boolean) {
		let newState = {...this.state};

		if (checked) {
			newState.itemsInGroup = this.state.itemsInGroup.map((item) => {
				return {...item, checked: true};
			});

			if (newState.itemsInGroup.length > 0) {
				newState.availableItems = this.state.availableItems.map((item) => {
					return {...item, checked: false};
				});

			}
		} else {
			newState.itemsInGroup = this.state.itemsInGroup.map((item) => {
				return {...item, checked: false};
			});
		}

		this.setState(newState);
	}

	private setLabelRef(c: Node) {
	}

	moveDirectionRight() {
		let checkedAvailableItems = this.state.availableItems.filter(x => x.checked).length > 0;
		let checkedItemsInGroup = this.state.itemsInGroup.filter(x => x.checked).length > 0;

		if (!checkedAvailableItems && !checkedItemsInGroup) {
			return <span>Move</span>;
		}

		if (checkedAvailableItems) {
			return <span className={styles.spanRight}>Move<svg className={styles.svgIconRight}>
                    <path d='M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z'
													fill='#0088CC'/>
                </svg></span>;
		} else {
			return <span className={styles.spanLeft}>Move<svg className={styles.svgIconLeft}>
                    <path d='M7.40997 10.59L2.82997 6L7.40997 1.41L5.99997 0L-2.67029e-05 6L5.99997 12L7.40997 10.59Z'
													fill='#0088CC'/>
                </svg></span>;
		}
	}

	moveDisabled(): boolean {
		const items = this.state.availableItems.concat(this.state.itemsInGroup);
		const checked = items.filter(x => x.checked);
		return checked && checked.length === 0;
	}

	render() {
		return <div className={styles.groupFormBody}>
			<div className={styles.checkboxListBox}>
				<div className={styles.checkboxOptions}>
					<CheckboxOptions
						onClick={(checked) => this.selectByFilterAvailable(checked)}
						disabled={this.state.availableItems.length === 0}
						checked={this.checkboxOptionsMode('availableItems')}
					/>
					<label className={styles.checkboxOptionsLabel}>{this.props.title}</label>
				</div>

				<CheckboxList
					items={this.state.availableItems}
					onClick={(id, e) => {
						this.selectAvailable(id, e);
					}}
					hasError={false}/>
			</div>
			<div className={styles.fullHeight}>
				<div className={styles.moveButtonWrapper}>
					<Transparent
						onClick={
							() => {
								if (this.state.availableItems.filter(x => x.checked).length > 0) {
									this.moveToGroup();
								} else {
									this.moveToAvailable();
								}
							}
						}
						className={styles.moveButton}
						disabled={this.moveDisabled()}
					>
						{this.moveDirectionRight()}
					</Transparent>
				</div>
			</div>
			<div className={styles.checkboxListBox}>
				<div className={styles.checkboxOptions}>
					<CheckboxOptions
						onClick={(checked) => this.selectByFilterInGroup(checked)}
						disabled={this.state.itemsInGroup.length === 0}
						checked={this.checkboxOptionsMode('itemsInGroup')}
					/>
					<label ref={(n) => this.setLabelRef(n)}
								 className={styles.checkboxOptionsLabel}>{this.state.groupName || this.props.newGroupLabel}</label>
				</div>
				<CheckboxList
					items={this.state.itemsInGroup}
					onClick={(id, e) => {
						this.selectItemsInGroup(id, e);
					}}
					hasError={false}/>
			</div>
		</div>;
	}
}
