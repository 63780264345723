import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: 20,
});

export const ImageCropperContent = styled(GridBox, {
	justifyContent: 'center',
	gap: 20,
});
