import {CenterCoordinatesModel} from '../types';

export function getCenterBetweenTwoPoints({
	x1,
	y1,
	x2,
	y2,
}: {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
}): CenterCoordinatesModel {
	return {
		x: (x1 + x2) / 2,
		y: (y1 + y2) / 2,
	};
}
