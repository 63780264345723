import {Request} from 'modules/reports/growth-report/types';

export class CacheItem {
	options: Request;
	rawModel: any;

	constructor(options: Request, rawModel: any) {
		this.options = options;
		this.rawModel = rawModel;
	}
}

export default class Cache {
	reports: Array<CacheItem>;

	constructor() {
		this.reports = [];
	}

	isEqual(left: Request, right: Request) {
		if (left.districtId !== right.districtId) {
			return false;
		}
		if (left.globalSchoolYearId !== right.globalSchoolYearId) {
			return false;
		}
		return left.trackId === right.trackId;

	}

	putReport(options: Request, rawModel: any) {

		for (let i = 0; i < this.reports.length; i++) {
			if (this.isEqual(options, this.reports[i].options)) {
				this.reports[i].rawModel = {};
				this.reports.splice(i, 1);
				break;
			}
		}
		let cacheOptions = options;
		let report = rawModel;
		this.reports.push(new CacheItem(cacheOptions, report));
	}

	getAllOptions(): Array<Request> {
		return this.reports.map((val) => {
			return val.options;
		});
	}

	getReport(options: Request) {
		for (let i = 0; i < this.reports.length; i++) {
			if (this.isEqual(options, this.reports[i].options)) {
				return this.reports[i].rawModel;
			}
		}
		return null;
	}
}
