import React from 'react';
import {join} from '@esgillc/ui-kit/utils';
import styles from './score.module.less';

interface Props {
	isFirst: boolean;
	isLast: boolean;
	score: number;
	editMode: boolean;
	invalid: boolean;
}

export function Score ({isFirst, isLast, score, editMode}: Props) {
	return (
		<div className={join(
			styles.scoreContainer,
			isFirst && styles.first,
			isLast && styles.last,
			editMode && styles.editMode)}>
			<div className={styles.scoreBox}>
				{isFirst && <div className={styles.scoreColumnTitle}>Scores</div>}
				<div className={styles.score}>{score}</div>
			</div>
		</div>
	);
}
