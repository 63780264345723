import {useEffect, useRef, useState} from 'react';
import {ProfileInitData, StudentProfileTab, TabsApi} from 'modules/forms/students-form/types';
import {getUser, UserType} from '@esgi/core/authentication';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {deepCopy} from 'shared/utils';

export const tabsApiInitial = {
	[StudentProfileTab.General]: {
		isTouched: false,
		save: null,
		form: null,
	},
	[StudentProfileTab.Location]: {
		isTouched: false,
		save: null,
		form: null,
	},
	[StudentProfileTab.Additional]: {
		isTouched: false,
		save: null,
		form: null,
	},
};

export const useTabsApi = (outsideInitData: ProfileInitData) => {
	const [selectedTab, setSelectedTab] = useState(StudentProfileTab.General);
	const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
	const [isConfirmSaveWithoutClassModalOpen, setConfirmSaveWithoutClassModalOpen] = useState(false);
	const [afterConfirmAction, setAfterConfirmAction] = useState<(() => void) | null>(null);
	const [isTabsDisabled, setTabsDisabled] = useState(true);
	const initDataUntouched = useRef(outsideInitData);
	if(!initDataUntouched?.current){
		initDataUntouched.current = outsideInitData;
	}
	const disableTabs = (formValue) => {
		if(formValue?.firstName && formValue?.lastName &&
			(tabsApi.current[StudentProfileTab.General].form.controls.school?.status !== ElementStatus.disabled ?
				!!formValue?.school?.[0] : true
			)){
			setTabsDisabled(false);
		}else {
			setTabsDisabled(true);
		}
	};
	const tabsApi = useRef<TabsApi>(deepCopy(tabsApiInitial));

	useEffect(() => {
		tabsApi.current?.[StudentProfileTab.General].form?.onChanged?.subscribe((formValues) => {
			disableTabs(formValues.currState.value);
		});
		disableTabs(tabsApi.current?.[StudentProfileTab.General]?.form?.value);
	}, []);

	const handleChangeTabAction = (action: () => void) => {
		const tab = tabsApi.current?.[selectedTab];
		if (tab?.isTouched) {
			setTabsDisabled(true);
			tab?.form?.validate().subscribe({
				next: ({valid}) => {
					if (valid) {
						tab?.save(false, false, action).subscribe(
							() => action(),
						);
					}
				},
				complete: () => setTabsDisabled(false),
			});
			return;
		}
		action();
	};

	const handleCloseAction = (action: () => void) => {
		const initData = initDataUntouched.current;
		const isLocationChanged = tabsApi.current?.[StudentProfileTab.Location]?.isTouched || tabsApi.current?.[StudentProfileTab.Location]?.isSaved;
		const showToCurrentUser = ![UserType.ISS, UserType.ISD, UserType.PA].includes(getUser().userType);
		const hasClass = tabsApi.current[StudentProfileTab.Location].haveClass;
		const isAddMode = !initData.studentID;
		const isStudentCreated = tabsApi.current[StudentProfileTab.General].isTouched || tabsApi.current[StudentProfileTab.General].isSaved || !!outsideInitData.studentID;
		const isCurrentTabTouched = tabsApi.current?.[selectedTab]?.isTouched;

		const shouldShowClassWarning = !isCurrentTabTouched && isStudentCreated && ((isLocationChanged && hasClass) || (isAddMode && !hasClass))
			&& showToCurrentUser && tabsApi.current[StudentProfileTab.Location].form
			&& !tabsApi.current[StudentProfileTab.Location].form.value?.classIDs?.length;

		if (shouldShowClassWarning) {
			setConfirmSaveWithoutClassModalOpen(true);
			setAfterConfirmAction(() => action);
			return;
		}

		if (tabsApi.current?.[selectedTab]?.isTouched) {
			tabsApi.current?.[selectedTab]?.form?.validate().subscribe((v) => {
				if(v.valid) {
					setConfirmModalOpen(true);
					setAfterConfirmAction(() => action);
				} else {
					action();
				}
			});
			return;
		}else {
			action();
		}
	};

	const onConfirmModalSave = () => {
		tabsApi.current?.[selectedTab]?.save?.().subscribe(() => {
			setConfirmModalOpen(false);
			const action = afterConfirmAction ?? (() => null);
			handleCloseAction(action);
		});
	};

	const onConfirmModalClose = () => {
		setConfirmModalOpen(false);
	};
	const onSaveWithoutClassClose = () => {
		setConfirmSaveWithoutClassModalOpen(false);
	};

	const onSaveWithoutClassConfirm = () => {
		setConfirmSaveWithoutClassModalOpen(false);
		afterConfirmAction();
	};

	const onSaveWithoutClassCancel = () => {
		setSelectedTab(StudentProfileTab.Location);
		setAfterConfirmAction(null);
	};

	const onConfirmModalCancel = () => {
		if(tabsApi.current?.[selectedTab]){
			tabsApi.current[selectedTab].isTouched = false;
		}
		afterConfirmAction();
		setAfterConfirmAction(null);
	};
	return {
		selectedTab,
		setSelectedTab,
		tabsApi,
		isConfirmModalOpen,
		onConfirmModalClose,
		onConfirmModalCancel,
		onConfirmModalSave,
		handleCloseAction,
		isConfirmSaveWithoutClassModalOpen,
		onSaveWithoutClassConfirm,
		onSaveWithoutClassCancel,
		onSaveWithoutClassClose,
		isTabsDisabled,
		handleChangeTabAction,
	};
};
