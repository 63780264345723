import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';

export const EasyCropContainer = styled(Box, {
	cursor: 'pointer',
	position: 'relative',
	marginBottom: '0',

	'& > div': {
		width: '160px',
		height: '160px',
		position: 'relative',
		borderRadius: '48px',
		border: '1px solid $blue',
		boxSizing: 'border-box',
	},
});
