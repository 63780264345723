import {InputCleanable} from '../input-cleanable';
import {
	ComponentPropsWithoutRef,
	FocusEvent,
	MouseEvent,
	forwardRef,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import {InputContainer} from './index.styled';
import {IndicatorType} from './types';
import {IndicatorPending} from './indicator-variant/indicator-pending';
import {IndicatorFailed} from './indicator-variant/indicator-failed';
import {IndicatorSucceeded} from './indicator-variant/indicator-succeeded';
import {Tooltip} from '../../../../tooltip';
import {Text} from '../../../../typography';
import {useComposedRefs} from '../../../../utils';

type InputCleanableIndicatorProps = Omit<ComponentPropsWithoutRef<typeof InputCleanable>, 'error'> & {
	status: IndicatorType;
	error?: string | undefined;
};

export const InputCleanableIndicator = forwardRef<HTMLInputElement, InputCleanableIndicatorProps>(
	({status, error, onBlur, onFocus, onMouseEnter, onMouseLeave, ...props}, forwardedRef) => {
		const [hovered, setHovered] = useState(false);
		const [focused, setFocused] = useState(false);

		const [tooltipContentHovered, setTooltipContentHovered] = useState(false);

		const innerInputRef = useRef<HTMLInputElement>(null);
		const inputRef = useComposedRefs(forwardedRef, innerInputRef);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(true);
				onMouseEnter?.(event);
			},
			[onMouseEnter],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLInputElement>) => {
				setHovered(false);
				onMouseLeave?.(event);
			},
			[onMouseLeave],
		);

		const handleFocus = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(true);
				onFocus?.(event);
			},
			[onFocus, setFocused],
		);

		const handleBlur = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocused(false);
				onBlur?.(event);
			},
			[onBlur],
		);

		const toggleTooltipContentHover = useCallback(() => {
			setTooltipContentHovered((isHover) => !isHover);
		}, []);

		const statusComponent = useMemo(() => {
			if (status === IndicatorType.Pending) {
				return <IndicatorPending />;
			}

			if (status === IndicatorType.Succeeded) {
				return <IndicatorSucceeded />;
			}

			if (status === IndicatorType.Failed || error) {
				return <IndicatorFailed />;
			}

			return null;
		}, [status, error]);

		const isTooltipOpen = Boolean(error) && (hovered || focused || tooltipContentHovered);

		return (
			<Tooltip open={isTooltipOpen}>
				<Tooltip.Trigger asChild>
					<InputContainer>
						<InputCleanable
							onFocus={handleFocus}
							onBlur={handleBlur}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							onMouseDown={() => {
								innerInputRef.current?.focus();
							}}
							data-error={Boolean(status === IndicatorType.Failed || error)}
							error={Boolean(status === IndicatorType.Failed || error)}
							{...props}
							ref={inputRef}
						/>
						{statusComponent}
					</InputContainer>
				</Tooltip.Trigger>
				<Tooltip.Content
					variant='error'
					sideOffset={-5}
					align='start'
					alignOffset={5}
					size='small'
					onMouseEnter={toggleTooltipContentHover}
					onMouseLeave={toggleTooltipContentHover}
				>
					<Text size='xxSmall' font='mono' color='negativeVivid'>
						{error}
					</Text>
				</Tooltip.Content>
			</Tooltip>
		);
	},
);
