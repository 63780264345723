import {ChangeEvent, PropsWithChildren} from 'react';
import {Switch} from '@esgillc/ui-kit/control';
import {HelpLink, Title} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';

interface Props extends PropsWithChildren {
	title: string
	helpUrl: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	toggled?: boolean
	isShowSwitch?: boolean
}

export default function RowTitle({
	                                 onChange,
	                                 toggled,
	                                 helpUrl,
	                                 title,
	                                 children,
	                                 isShowSwitch,
}: Props) {

	return <div className={styles.rowTitle}>
		<div data-cy='row-header' className={styles.header}>
			<Title className={styles.title}> {title}
				<HelpLink url={helpUrl}/>
			</Title>
			{isShowSwitch && <Switch toggled={toggled} onChange={onChange}/>}
		</div>
		<div data-cy='row-body'>
			{children}
		</div>
	</div>;
}
