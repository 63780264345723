import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {Checkbox} from '@esgillc/ui-kit/control';

export const ReportFilterContainerStyled = styled(Box, {
	display: 'flex',
	borderRight: '2px solid #FFFFFF',
	padding: '10px',
	alignItems: 'flex-start',
	flexDirection: 'column',
	width: '165px',
});

export const ReportFilterLabelStyled = styled(Box, {
	fontWeight: 700,
	textAlign: 'left',
	fontSize: '13px',
});

export const ReportFiltersContentStyled = styled(Box, {
	display: 'flex',
	marginBottom: '5px',
	marginRight: 0,
	width: '120px',
	flexDirection: 'column',
});

export const CheckboxStyled = styled(Checkbox, {
	marginBottom: '8px',

	'label': {
		marginRight: '13px',
		fontSize: '12px',
	},
});