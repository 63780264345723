import React, {ReactNode} from 'react';
import {ArrowButton, Direction} from './arrow-button/arrow-button';

export class Props {
    title: string;
    className?: string;
	children?: ReactNode;
}

export class Tray extends React.PureComponent<Props> {
    private cards: HTMLDivElement;

    render() {
    	return <div className={'tray ' + (this.props.className || '')} >
            <h4 className='title'>{this.props.title}</h4>
            <ArrowButton direction={Direction.left} clicked={() => this.moveLeft()} />
            <div className='cards' ref={r => this.cards = r}>
                {this.props.children}
            </div>
            <ArrowButton direction={Direction.right} clicked={() => this.moveRight()}/>
        </div>;
    }

    private moveLeft() {
    	let offsetLeft = this.cards.scrollLeft - this.cards.offsetWidth;
    	this.scrollBack(offsetLeft);
    }

    private moveRight() {
    	let offsetLeft = this.cards.scrollLeft + this.cards.offsetWidth;
    	this.scrollForward(offsetLeft);
    }

    scrollForward(offsetLeft: number) {
    	let nodes = this.cards.children;

    	let prev: HTMLDivElement = null;
    	let middle: HTMLDivElement = null;
    	let next: HTMLDivElement = null;

    	for (let i = 0; i < nodes.length; i++) {
    		let subjectDiv = nodes[i] as HTMLDivElement;

    		if (subjectDiv.offsetLeft > offsetLeft) {
    			middle = subjectDiv;
    			prev = nodes[i - 1] as HTMLDivElement;
    			next = nodes[i + 1] as HTMLDivElement;
    			break;
    		}
    	}

    	if (!next) {
    		//scroll to last
    		next = nodes[nodes.length - 1] as HTMLDivElement;
    		if (next) {
    			return this.scrollTo(next.offsetLeft - 5);
    		}
    	}

    	if(prev) {
    		let startsBefore = prev.offsetLeft < offsetLeft;
    		let endsAfter = prev.offsetLeft + prev.offsetWidth > offsetLeft;

    		if(startsBefore && endsAfter) {
    			return this.scrollTo(prev.offsetLeft - 5);
    		}
    	}

    	if (next) {
    		let middleWidth = middle.offsetWidth;
    		if (middleWidth + middle.offsetLeft < offsetLeft + 5) {
    			return this.scrollTo(next.offsetLeft - 5);
    		}
    	}

    	if (middle) {
    		this.scrollTo(middle.offsetLeft - 5);
    	}
    }

    private scrollBack(offsetLeft: number) {

    	if(offsetLeft <= 0) {
    		return this.scrollTo(0);
    	}

    	let nodes = this.cards.children;

    	let left: HTMLDivElement = null;
    	let middle: HTMLDivElement = null;

    	for (let i = 0; i < nodes.length; i++) {
    		let subjectDiv = nodes[i] as HTMLDivElement;

    		if (subjectDiv.offsetLeft >= offsetLeft) {
    			middle = subjectDiv;
    			left = nodes[i - 1] as HTMLDivElement;
    			break;
    		}
    	}

    	if (!left) {
    		left = nodes[0] as HTMLDivElement;
    	}

    	if (left) {
    		return this.scrollTo(left.offsetLeft);
    	}
    }

    private scrollTo(scrollLeft: number) {
    	if (scrollLeft < 0) {
    		scrollLeft = 0;
    	}

    	this.cards.classList.add('scrolling');
    	$(this.cards).animate({scrollLeft: scrollLeft}, 400, () => {
    		this.cards.classList.remove('scrolling');
    	});
    }
}
