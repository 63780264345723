import {styled} from '@esgi/ui/theme';
import {FormElement} from '@esgi/ui/form';
import {GridBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';
import {Input} from '@esgi/ui/form-controls';

export const Container = styled(GridBox, {
	backgroundColor: '$vivid',
	alignItems: 'start',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
	padding: '12px',
	gap: '4px',
});

export const PinContainer = styled(GridBox, {
	gap: '12px',
	alignItems: 'start',
	gridTemplateColumns: '1fr 1fr auto',
});

export const ButtonIcon = styled(Button.Icon, {
	backgroundColor: '$vivid',
	padding: '7px',
	border: '1px solid $mild',
	borderRadius: '8px',
	minWidth: '40px',
	minHeight: '40px',
});

export const FormElementWrapper = styled(FormElement, {
	marginBottom: 0,

	'& > div': {
		width: '100%',
	},
});
export const PasswordInput = styled(Input.Base, {
	variants: {
	  hide: {
			true: {
		 		WebkitTextSecurity: 'disc',
			},
	  },
	},
});