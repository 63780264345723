import * as React from 'react';
import type {SVGProps} from 'react';

export function ClearAll(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			data-cy='clear-all-icon'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.95455 7.21631C5.42735 7.21631 5 7.61561 5 8.1082C5 8.60079 5.42735 9.00009 5.95455 9.00009H8.04545C8.57265 9.00009 9 8.60079 9 8.1082C9 7.61561 8.57265 7.21631 8.04545 7.21631H5.95455ZM5.95455 11.1082C5.42735 11.1082 5 11.5075 5 12.0001C5 12.4927 5.42735 12.892 5.95455 12.892H8.04545C8.57265 12.892 9 12.4927 9 12.0001C9 11.5075 8.57265 11.1082 8.04545 11.1082H5.95455Z'
				fill='#333333'/>
			<path
				d='M5.00009 15.892C5.00009 15.3994 5.42744 15.0001 5.95463 15.0001H8.04554C8.57274 15.0001 9.00009 15.3994 9.00009 15.892C9.00009 16.3846 8.57274 16.7839 8.04554 16.7839H5.95463C5.42744 16.7839 5.00009 16.3846 5.00009 15.892Z'
				fill='#333333'/>
			<path
				d='M12.1756 14.9183C11.9415 14.6841 11.9415 14.304 12.1756 14.0698L14.1984 12.047L12.1756 10.0242C11.9415 9.78998 11.9415 9.40984 12.1756 9.17565C12.4098 8.94145 12.79 8.94145 13.0242 9.17565L15.047 11.1984L17.0698 9.17565C17.304 8.94145 17.6841 8.94145 17.9183 9.17565C18.1525 9.40984 18.1525 9.78998 17.9183 10.0242L15.8955 12.047L17.9183 14.0698C18.1525 14.304 18.1525 14.6841 17.9183 14.9183C17.6841 15.1525 17.304 15.1525 17.0698 14.9183L15.047 12.8955L13.0242 14.9183C12.79 15.1525 12.4098 15.1525 12.1756 14.9183Z'
				fill='#333333'/>
		</svg>
	);
}
