import React, {ReactNode} from 'react';
import {combineLatest} from 'rxjs';
import {ReactiveComponent} from '@esgi/core/service';
import {getUser, userStorage, UserType} from '@esgi/core/authentication';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {StudentModel} from '../../../../models/models';
import DataService from '../../../../services/data-service';
import StudentsService from '../../../../services/students-service';
import TableService from '../../../../services/table-service';
import AllCheckbox from './all-checkbox';
import AssignOperation from './operations/assign/assign-operation';
import DeleteOperation from './operations/delete/delete-operation';
import UnassignOperation from './operations/unassign/unassign-operation';
import {join} from '@esgillc/ui-kit/utils';

class Props {
	studentsService: StudentsService;
	tableService: TableService;
	dataService: DataService;
}

export class OperationsPanel extends React.PureComponent<Props> {
	private readonly currentUser = userStorage.get();

	public render(): ReactNode {
		return <div className='operationspanel_container'>
			<ReactiveComponent
				stream={combineLatest(this.props.tableService.selectedStudentsIDs$, this.props.tableService.studentsToShow$)}>
				{project => project && <>
						{this.renderAllSelector(project[0] || [], project[1] || [])}
						{this.renderOperations(project[0] || [], project[1] || [])}
					</>
				}
			</ReactiveComponent>
		</div>;
	}

	private renderAllSelector(selectedStudentsIDs: number[], studentsToShow: StudentModel[]): ReactNode {
		const currentUser = getUser();
		const canViewStudents = currentUser.canViewStudents;
		const notLinkedToDistrict = currentUser.agreementLevelCode !== 'T';

		const editableStudents = studentsToShow.filter(x => x.editable);
		const canBeCheckedStudents = studentsToShow.filter(({primaryTeacherID}) => {
			if(!notLinkedToDistrict) {
				return true;
			}

			return currentUser.userType !== UserType.T
				? canViewStudents
				: canViewStudents && (primaryTeacherID === null || currentUser.userID === primaryTeacherID);
		});

		const workedStudents = editableStudents.length ? editableStudents : canBeCheckedStudents;

		let value;
		if (selectedStudentsIDs.length === 0) {
			value = false;
		} else {
			value = workedStudents.length === selectedStudentsIDs.length ? true : undefined;
		}

		const disabled = workedStudents.length === 0;
		const disabledMessage = 'You cannot modify students that are currently assigned to other teachers.';
		const clickHandler = (toggled: boolean | undefined) => {
			const studentsIDs = workedStudents.map(({studentID}) => studentID);

			if(toggled === undefined) {
				if(value === undefined) {
					this.props.tableService.selectAll(studentsIDs);
					return;
				}

				this.props.tableService.selectAll(!value ? studentsIDs : []);

				return;
			}

			this.props.tableService.selectAll(toggled ? studentsIDs : []);
		};

		return <OnHoverTooltip
			message={disabled ? disabledMessage : null}>
			<div className='select_students'>
				<AllCheckbox onClicked={(toggled) => clickHandler(toggled)}
										 value={value}
										 disabled={disabled}/>
			</div>
		</OnHoverTooltip>;
	}

	private renderOperations(selectedStudents: number[], students: StudentModel[]): ReactNode {
		return <div className={join('student_operations', selectedStudents.length === 0 && 'hidden')}>
			{this.canAssign() && (
				<AssignOperation selectedStudentsSchoolsIDs={this.getSelectedStudentsSchoolsIDs(selectedStudents)}
												 selectedStudentIDs={selectedStudents}
												 dataService={this.props.dataService}
												 studentsService={this.props.studentsService}/>
			)}
			{!this.isSelectedStudentsMoveOnly(selectedStudents, students) && <>
				{this.canUnassign(selectedStudents, students) &&
					<UnassignOperation tableService={this.props.tableService}
														 studentsService={this.props.studentsService}/>}
			</>}
			{this.canDelete() && <DeleteOperation studentService={this.props.studentsService}
																						tableService={this.props.tableService}/>}
		</div>;
	}

	private canAssign() {
		if (!this.currentUser?.canManageClasses) {
			return false;
		}

		return true;
	}

	private canUnassign(selectedStudents: number[], students: StudentModel[]): boolean {
		if (!this.currentUser?.canManageClasses) {
			return false;
		}

		if (this.currentUser.userType === UserType.ISD || this.currentUser.userType === UserType.ISS
			|| this.currentUser.userType === UserType.PA
		) {
			return selectedStudents.every(x => students.find(s => s.studentID === x)?.specialistGroupUserIDs.filter(x => x === this.currentUser.userID).length !== 0);
		}
		return true;
	}

	private isSelectedStudentsMoveOnly(selectedStudents: number[], students: StudentModel[]): boolean {
		return this.currentUser.userType === UserType.T && selectedStudents.some(x => students.find(s => s.studentID === x)?.classIDs?.length === 0);
	}

	private canDelete(): boolean {
		const currentUser = userStorage.get();
		return currentUser.userType === UserType.C ||
			currentUser.userType === UserType.D || currentUser.agreementLevelCode === 'T';
	}

	private getSelectedStudentsSchoolsIDs(selectedIDs: number[]): number[] {
		return this.props.studentsService.getStudents()
			.filter(x => selectedIDs.indexOf(x.studentID) !== -1)
			.map(x => x.schoolID)
			.filter((schoolID, index, arr) => arr.indexOf(schoolID) === index); // distinct
	}
}
