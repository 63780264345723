import React from 'react';
import {Dropdown, DropdownInModel} from '@esgi/deprecated/elements/dropdown';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import {SettingsModel} from 'shared/modules/reports/flash-cards/settings-form/services/settings-service';
import {HierarchyModel} from 'shared/modules/reports/flash-cards/settings-form/services/flash-cards-hieararchy-service';

class State {
	hierarchy: HierarchyModel;
	settings: SettingsModel;
}

class Props {
	dataService: DataService;
}

export default class FlashCardsHierarchy extends React.Component<Props, State> {

	constructor(props?: Props) {
		super(props);
		const student = new Models.Student();
		student.studentId = 0;
		this.state = {
			hierarchy: new HierarchyModel(),
			settings: new SettingsModel(),
		};
	}
	componentDidMount(): void {
		this.props.dataService.hierarchyService.hierarchy$.subscribe((hierarchy) => this.setState({hierarchy: hierarchy}));
		this.props.dataService.settingsService.settings$.subscribe((settings) => this.setState({settings: settings}));
	}

	private studentChanged(t: DropdownInModel) {
		this.props.dataService.testsService.loadSubjects(t.id);
		const m = new HierarchyModel();
		m.selectedStudent = this.state.hierarchy.students.find(x => x.studentId === t.id);
		this.props.dataService.hierarchyService.updateHierarchyModel(m);
	}

	private groupChanged(t: DropdownInModel) {
		const groupItem = this.props.dataService.hierarchyService.groupItems.find(x => x.id === t.id);
		const student = this.state.hierarchy.students.find(x => x.studentId === 0);
		const reloadTestsModel = new Models.ReloadTestsModel();
		reloadTestsModel.groupChanged = true;
		reloadTestsModel.groupItem = groupItem;
		reloadTestsModel.student = student;
		this.props.dataService.testsService.reloadTests(reloadTestsModel);
		const m = new HierarchyModel();
		m.selectedGroupItem = groupItem;
		m.students = groupItem.students;
		m.selectedStudent = student;
		this.props.dataService.hierarchyService.updateHierarchyModel(m);
	}

	private subjectChanged(t: DropdownInModel) {
		const subject = this.state.hierarchy.subjects.find(x => x.id === t.id);
		const reloadTestsModel = new Models.ReloadTestsModel();
		reloadTestsModel.subjectChanged = true;
		reloadTestsModel.subject = subject;
		this.props.dataService.testsService.reloadTests(reloadTestsModel);

		const m = new HierarchyModel();
		m.selectedSubject = subject;
		this.props.dataService.hierarchyService.updateHierarchyModel(m);
	}

	render() {
		return 	<div className='first-row'>
            {this.state.hierarchy.teacher &&
            <div className='form-group'>
                <label className='header-title' >{this.state.hierarchy.groupType === ' SpecialistGroup' ? 'Specialist: ' : 'Teacher: '}</label>
                <span>{this.state.hierarchy.teacher.fullName}</span>
            </div>
            }
            {this.state.hierarchy.groupItems && this.state.hierarchy.groupItems.length > 1 &&
			<Dropdown
				onClick={(t) => this.groupChanged(t)}
				values={this.props.dataService.hierarchyService.groupItems.map(x => {
					return {id: x.id, value: x.name};
				})}
				value={this.props.dataService.hierarchyService.groupItems.map(x => {
					return {id: x.id, value: x.fullName};
				}).filter(x => x.id === this.state.hierarchy.selectedGroupItem?.id)[0]}
				selectClassName='student-dropdown'
				label={this.props.dataService.hierarchyService.groupType === 'SpecialistGroup' ? 'Group: ' : this.props.dataService.hierarchyService.groupType}
			/>}
			{this.state.hierarchy.groupItems && this.state.hierarchy.groupItems.length === 1 &&
			<div className='form-group'>
				<label className='header-title' >{this.state.hierarchy.groupType === 'SpecialistGroup' ? 'Group: ' : this.state.hierarchy.groupType}</label>
				<span>{this.state.hierarchy.groupItems[0].name}</span>
			</div>
            }
            {this.state.hierarchy.students && this.state.hierarchy.students.length > 0 &&
            <Dropdown
                onClick={(t) => this.studentChanged(t)}
                values={this.state.hierarchy.students.map(x => {
                    return {id: x.studentId, value: x.fullName};
                })}
                value={this.state.hierarchy.students.map(x => {
                    return {id: x.studentId, value: x.fullName};
                }).filter(x => x.id === this.state.hierarchy.selectedStudent.studentId)[0]}
                selectClassName='student-dropdown'
                disabled={this.state.settings.printOutOption === 'FullSet'}
                label='Student: '
            />
            }
            {this.state.hierarchy.subjects && this.state.hierarchy.subjects.length > 0 &&
            <Dropdown
                onClick={(t) => this.subjectChanged(t)}
                values={this.state.hierarchy.subjects.map(x => {
                    return {id: x.id, value: x.name};
                })}
                value={this.state.hierarchy.subjects.map(x => {
                    return {id: x.id, value: x.name};
                }).filter(x => x.id === this.state.hierarchy.selectedSubject.id)[0]}
                selectClassName='subject-dropdown'
                label='Subject Tab: '
            />
            }
        </div>;
	}
}
