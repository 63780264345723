import {BaseComponentProps, Button, Close, IconComponent} from '@esgi/ui';
import React, {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {Content, ContentWrapper, Header, ChildrenWrapperBox, ButtonsContainer} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Divider} from '../divider.styled';

type RightPanelHeaderProps = ComponentPropsWithoutRef<'div'> &
	BaseComponentProps & {
		Icon: IconComponent;
		sectionName: string;
		withCloseButton?: boolean;
		onClose?: VoidFunction;
	} & (
		| {
				withActionButton?: false;
		  }
		| {
				withActionButton: true;
				actionButtonText: string;
				actionButtonDisabled?: boolean;
				onActionButtonClick: VoidFunction;
		  }
	);

export const RightPanelHeader = forwardRef<HTMLDivElement, RightPanelHeaderProps>(
	(
		{dataCy = 'features-right-panel-header', css = {}, withCloseButton, onClose, Icon, sectionName, children, ...props},
		forwardedRef,
	) => {
		const {restProps, actionButtonProps} = useMemo(() => {
			if (props.withActionButton) {
				const {withActionButton, actionButtonText, actionButtonDisabled, onActionButtonClick, ...restProps} = props;

				return {
					restProps,
					actionButtonProps: {
						withActionButton,
						actionButtonText,
						actionButtonDisabled,
						onActionButtonClick,
					},
				};
			}

			const {withActionButton, ...restProps} = props;

			return {
				restProps,
				actionButtonProps: {
					withActionButton,
				},
			};
		}, [props]);

		return (
			<Header dataCy={dataCy} css={css} ref={forwardedRef} {...restProps}>
				<ContentWrapper withActionButton={Boolean(actionButtonProps.withActionButton)} withChildren={Boolean(children)}>
					<Content>
						<Icon />
						<Text data-cy='right-panel-title' size='small' color='neutral56'>
							{sectionName}
						</Text>
					</Content>

					{children && <ChildrenWrapperBox data-children-box=''>{children}</ChildrenWrapperBox>}

					<ButtonsContainer>
						{actionButtonProps.withActionButton && (
							<Button
								color='primary'
								disabled={Boolean(actionButtonProps.actionButtonDisabled)}
								onClick={actionButtonProps.onActionButtonClick}
							>
								<Text size='large'>{actionButtonProps.actionButtonText}</Text>
							</Button>
						)}
						{withCloseButton && (
							<Button.Icon onClick={onClose} dataCy='close-button'>
								<Close height={24} width={24} />
							</Button.Icon>
						)}
					</ButtonsContainer>
				</ContentWrapper>

				<Divider />
			</Header>
		);
	},
);
