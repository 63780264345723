import React from 'react';
import {Play, Resume} from '@esgi/ui/icons';
import {IconContainer, Label, ToggleGroupItem} from './styled';
import {ToggleGroupRoot, ToggleGroupContent} from '../../styled';
import {Session} from '../../../../../types';

interface TabsProps {
	onChange: (type: Session) => void;
	selected: Session;
	resumeDisabled: boolean;
}

export function SessionType({selected, onChange, ...props}: TabsProps) {

	return <ToggleGroupRoot value={selected}>
		<ToggleGroupContent>
			<ToggleGroupItem value={Session.NewSession} onClick={() => onChange(Session.NewSession)}>
				<IconContainer>
					<Play width={24} height={24}/>
				</IconContainer>
				<Label data-cy='new-session-label' size='medium' bold>
					New Session
				</Label>
			</ToggleGroupItem>
			<ToggleGroupItem value={Session.Resume} disabled={props.resumeDisabled}
							 onClick={() => onChange(Session.Resume)}>
				<IconContainer>
					<Resume/>
				</IconContainer>
				<Label size='medium' bold>
					Resume
				</Label>
			</ToggleGroupItem>
		</ToggleGroupContent>
	</ToggleGroupRoot>;
}
