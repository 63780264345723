import {useTransition, config} from 'react-spring';
import {useItemStatistics} from '../../../../hooks/use-item-statistics';
import {AnswerBar, Container} from './index.styled';
import {useAnswerBarItems} from './use-answer-bar-items';

type Props = {
	sessionsCount: number;
	correct: number;
	incorrect: number;
};

export function VerticalBox({sessionsCount, correct, incorrect}: Props) {
	const itemStatistics = useItemStatistics({sessionsCount, correct, incorrect});
	const answerBarItems = useAnswerBarItems(itemStatistics);

	const barItemsTransition = useTransition(
		answerBarItems.filter(({value}) => value > 0),
		{
			from: {
				height: '0%',
			},
			enter: (item) => ({
				height: `${item.value}%`,
			}),
			trail: 300 / answerBarItems.length,
			config: {
				...config.gentle,
				duration: 300,
				clamp: true,
			},
		},
	);

	return (
		<Container dataCy='bar-container'>
			{barItemsTransition((styles, {type, borderBottomWidth}) => (
				<AnswerBar itemType={type} style={styles} css={{borderBottomWidth}} />
			))}
		</Container>
	);
}
