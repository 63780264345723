import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';

export function ContinueSessionAlert(props: {onConfirm: () => void, onCancel: () => void}) {
	const modal = useModal();

	const cancel = useCloseModal(modal, props.onCancel);
	const confirm = useCloseModal(modal, props.onConfirm);

	return <Alert modalManagerRef={modal}>
		<Alert.Body>
			A Student Screen session is currently active. Would you like to continue?
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={cancel}>
				NO, START NEW SESSION
			</Buttons.Text>
			<Buttons.Text onClick={confirm}>
				YES, CONTINUE
			</Buttons.Text>
		</Alert.Footer>
	</Alert>;
}
