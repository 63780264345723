import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {PanelTrigger} from '../drawer-panel/components/panel-trigger';
import {animated} from 'react-spring';

export const drawerPaddingInline = 20;
export const drawerWrapperWidth = 400;

export const DrawerWrapper = styled(GridBox, {
	overflow: 'hidden',
	height: '100%',
	gridTemplateRows: 'auto 1fr',
	gap: 20,
	paddingLeft: drawerPaddingInline,
	paddingRight: drawerPaddingInline,
	paddingBottom: 20,
});

export const DrawerContentWrapper = styled(GridBox, {
	width: '100%',
	height: '100%',

	[`& > ${DrawerWrapper}`]: {
		width: drawerWrapperWidth,
	},

	variants: {
		withPanel: {
			true: {
				gridAutoFlow: 'column',
				gridTemplateColumns: 'auto 1fr',
			},
		},
	},
});

export const PanelBox = styled(animated.div, {
	position: 'relative',
	height: '100%',
	paddingTop: 24,
	paddingLeft: 12,
	paddingRight: 12,
	background: 'repeating-linear-gradient(-45deg, $vivid, $vivid 2px, $primary98 0, $primary98 11px)',
	borderRightStyle: 'solid',
	borderRightWidth: 1,
	borderRightColor: '$primaryMuted',
	borderLeftStyle: 'solid',
	borderLeftWidth: 1,
	borderLeftColor: '$primaryMuted',
	cursor: 'pointer',
	transition: 'background-color .3s, background .3s, border-right-color .3s, border-left-color .3s',

	'&:hover': {
		borderRightColor: '$secondaryMuted',
		borderLeftColor: '$secondaryMuted',
		background: 'repeating-linear-gradient(-45deg, $vivid, $vivid 2px, $secondaryBackground 0, $secondaryBackground 11px)',

		[`& > ${PanelTrigger}`]: {
			color: '$secondary',
		},
	},
});
