import {BaseService} from '@esgi/core/service';
import {V2PagesReportsValidationController} from '@esgi/contracts/esgi';

export class ReportValidator extends BaseService {

	private controller = new V2PagesReportsValidationController();

	public validate() {
		return this.controller.track();
	}

	public override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}