import {SelectableList as SelectableListUi} from '@esgi/ui/layout';
import {Student} from '@esgi/main/libs/store';
import {StudentFullName, getListValue} from '../../../../kit';
import {SelectableLisItemContent} from './lists.styled';

type Props = {
	selectedStudentId: Student['id'] | null;
	setSelectedStudentId: (studentId: Student['id']) => void;
	students: Student[];
};

export function SelectableList({selectedStudentId, setSelectedStudentId, students}: Props) {
	return (
		<SelectableListUi dataCy='students-panel-selectable-list'>
			<SelectableListUi.GroupRoot type='single' value={getListValue(selectedStudentId)}>
				<SelectableListUi.Group>
					{students.map(({firstName, lastName, id}) => (
						<SelectableListUi.Item
							value={String(id)}
							key={id}
							forceSelect={selectedStudentId !== id}
							onClick={() => setSelectedStudentId(id)}
						>
							<SelectableLisItemContent>
								<StudentFullName firstName={firstName} lastName={lastName} dataCy='students-list-student-full-name' />
							</SelectableLisItemContent>
						</SelectableListUi.Item>
					))}
				</SelectableListUi.Group>
			</SelectableListUi.GroupRoot>
		</SelectableListUi>
	);
}
