import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const ListItem = styled(FlexBox, {
	alignItems: 'baseline',
	width: '100%',
});

export const DateText = styled(Text, {
	color: '$base',
	marginRight: '8px',
});

export const TimeText = styled(Text, {
	color: '$neutral40',
	border: '1px solid $muted',
	borderRadius: '2px',
	marginRight: '12px',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: '4px',

	'&:after': {
		padding: '1px 4px',
		marginLeft: '4px',
		color: '$vivid',
		backgroundColor: '$neutral72',
		textTransform: 'uppercase',
		fontWeight: '600',
	},

	variants: {
		type: {
			am: {
				'&:after': {
					content: 'am',
				},
			},
			pm: {
				'&:after': {
					content: 'pm',
				},
			},
		},
	},
});

export const CaptionText = styled(Text, {
	color: '$neutral40',
});

export const Counter = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: '$3',
	marginLeft: 'auto',
});

export const CounterText = styled(Text, {
	minWidth: '16px',
	textAlign: 'center',

	variants: {
		type: {
			correct: {
				color: '$positive',
			},
			incorrect: {
				color: '$negative',
			},
			untested: {
				color: '$neutral56',
			},
		},
	},
});
