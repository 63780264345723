import React from 'react';
import {Hint, Label, OnErrorTooltip, TextArea} from '@esgillc/ui-kit/form-control';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';

import styles from '../confirm.module.less';

const MAX_DESCRIPTION_LENGTH = 140;

interface Props {
	control: FormControl<string>;
	initialValue: string;
}

export default class DescriptionField extends React.PureComponent<Props> {
	public componentDidMount() {
		this.props.control.value = this.props.initialValue;
	}

	public render() {
		return <div className={styles.field}>
			<FormElement control={this.props.control} className={styles.element}>
				<Label className={styles.topLabel}>Description</Label>
				<TextArea className={styles.description}
				          autoResizeToFit
				          placeholder='Enter Description'
				          maxLength={MAX_DESCRIPTION_LENGTH}/>
				<Hint className={styles.limitCounter}>{(props) => {
					return `${MAX_DESCRIPTION_LENGTH - (props.value?.length | 0)} characters left (${MAX_DESCRIPTION_LENGTH} max)`;
				}}</Hint>
				<OnErrorTooltip showOnError='required' placement='right'>
					Description required
				</OnErrorTooltip>
			</FormElement>
		</div>;
	}
}
