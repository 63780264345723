import {CentralPanel} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Root = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
});

export const CentralPanelRoot = styled(CentralPanel.Root, {
	paddingLeft: 0,
	paddingRight: 0,
});
