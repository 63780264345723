import React from 'react';
import styles from './elements.module.less';

class Props {
	className?: string;
	children?: any;
}

export function FiltersLabelBlock(props: Props) {
	return <div className={styles.filtersLabelBlock}>
		{props.children}
	</div>;
}
