import {ExpandablePanelContent, ExpandablePanelRoot, NavigationItem, SectionBox, StudentProgressBox, Title} from './styled';
import {Text} from '@esgi/ui/typography';
import {
	AppliedTo,
	Users,
	User,
	File,
	StudentProgress,
	StudentCard,
	Reports,
	FieldConnector,
	RubricReport,
} from '@esgi/ui/icons';
import {ExpandablePanel, GridBox} from '@esgi/ui/layout';
import {useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Tooltip} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {routes} from '@esgi/main/libs/core';

export function OldReportsPanel() {
	const currentUser = useUser();
	const navigate = useNavigate();
	const {pathname} = useLocation();

	const isActive = useCallback((path: string) => pathname === path, [pathname]);

	const navigateToActivity = useCallback(
		(path: string) => {
			navigate(path);
		},
		[navigate],
	);

	return (
		<ExpandablePanelRoot open collapsed={false} css={{zIndex: 1}}>
			{({panelIsHovered, open}) => {
				return (
					<>
						{!open && <ExpandablePanel.Trigger />}
						<ExpandablePanel.Placeholder>
							<SectionBox fillColor={panelIsHovered ? 'secondary' : 'primary'}>
								<AppliedTo data-cy='activities-icon' width={24} height={24} />
							</SectionBox>
						</ExpandablePanel.Placeholder>
						<ExpandablePanelContent>
							<OverlayScrollbarsComponent defer options={{scrollbars: {autoHide: 'leave'}}} style={{height: '100%'}}>
								<Title>
									<User width={24} height={24} />
									<Text size='small' color='neutral56'>
										Student Reports
									</Text>
								</Title>
								<GridBox flow='row' gapY='2'>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.parentLetter)}
												onClick={() => navigateToActivity(routes.teacher.reports.parentLetter)}
												dataCy='navigation-item-parent-letter'
											>
												<File />
												<Text size='large'>Parent Letter</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Print student results with personalized messaging for parent review
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.studentDetail)}
												onClick={() => navigateToActivity(routes.teacher.reports.studentDetail)}
												dataCy='navigation-item-student-detail'
											>
												<StudentCard />
												<Text size='large'>Student Detail</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Comprehensive breakdown of {currentUser?.testResultsCorrectVerbiage ?? 'correct'}/
											{currentUser?.testResultsIncorrectVerbiage ?? 'incorrect'} items, scoring, and student progress
											over time
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.studentProgress)}
												onClick={() => navigateToActivity(routes.teacher.reports.studentProgress)}
												dataCy='navigation-item-student-progress'
											>
												<StudentProgressBox>
													<StudentProgress />
												</StudentProgressBox>
												<Text size='large'>Student Progress</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											View student progress as raw scores, percentages, or grades
										</Tooltip.Content>
									</Tooltip>
								</GridBox>

								<Title css={{marginTop: 40}}>
									<Users width={24} height={24} />
									<Text size='small' color='neutral56'>
										Class Reports
									</Text>
								</Title>

								<GridBox flow='row' gapY='2'>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.classGrades)}
												onClick={() => navigateToActivity(routes.teacher.reports.classGrades)}
												dataCy='navigation-item-class-grades'
											>
												<Users />
												<Text size='large'>Class Grades</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Apply customizable grade scales to student scores
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.classTotals)}
												onClick={() => navigateToActivity(routes.teacher.reports.classTotals)}
												dataCy='navigation-item-class-totals'
											>
												<Users />
												<Text size='large'>Class Totals</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Review and compare student scores for a subject tab
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.itemAnalysis)}
												onClick={() => navigateToActivity(routes.teacher.reports.itemAnalysis)}
												dataCy='navigation-item-item-analysis'
											>
												<Reports />
												<Text size='large'>Item Analysis</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>Bar chart based on least and most known items</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.pieCharts)}
												onClick={() => navigateToActivity(routes.teacher.reports.pieCharts)}
												dataCy='navigation-item-pie-charts'
											>
												<FieldConnector />
												<Text size='large'>Pie Charts</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>Printable report of homepage pie charts</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.rubric)}
												onClick={() => navigateToActivity(routes.teacher.reports.rubric)}
												dataCy='navigation-item-rubric-results'
											>
												<RubricReport />
												<Text size='large'>Rubric Results</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>Rubric results</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												active={isActive(routes.teacher.reports.untestedStudents)}
												onClick={() => navigateToActivity(routes.teacher.reports.untestedStudents)}
												dataCy='navigation-item-untested-students'
											>
												<Users />
												<Text size='large'>Untested Students</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Identify untested students to administer testing
										</Tooltip.Content>
									</Tooltip>
								</GridBox>
							</OverlayScrollbarsComponent>
						</ExpandablePanelContent>
					</>
				);
			}}
		</ExpandablePanelRoot>
	);
}
