import {Moment} from 'moment';

export class TestSessionDetailsChanged {
	constructor(
		public testId: number,
		public sessionId?: number,
		public testDate?: Moment,
	) {}
}

export class TestedByChangedEvent {
	constructor(public testedBy: string = '') {}
}

export class TestSessionAddEvent {}

export class TestSessionDeleteEvent {}

export class TestSessionUpdateEvent {
	constructor(public request: Record<string, any>) {}
}

export class TestSessionNeedUpdateEvent {}

export class TestSessionRestoreEvent {}

export class ResetPanelsEvent {}

export class NewTestSessionCanceledEvent {}

export class AnswersUpdateEvent {
	constructor(
		public prevTestDate: Moment,
		public sessionId: number,
		public testDate: Moment,
	) {}
}
