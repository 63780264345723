import './date-time-picker.less';
import React from 'react';
import {Moment} from 'moment';
import moment from 'moment/moment';
import {IFormControlValidatorResult} from './form-controls';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';

class Props {
	date?: Moment;
	onSelect: (d: Moment) => void;
	id: string;
	validator?: (value: Moment) => IFormControlValidatorResult;
	icon?: any;
	endDate?: Moment;
	validationPlacement?: 'right' | 'top' | 'bottom' | 'left';
}

export class DateTimePicker extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
		if (props.date) {
			this.defaultDate = props.date;
		}
	}

	ref: HTMLDivElement = null;
	containerRef: HTMLDivElement = null;
	defaultDate: Moment = moment();
	defaultFormat: string = 'M/D/YYYY h:mm A';

	componentWillUnmount() {
		$(this.ref).datepicker('destroy');
	}

	componentDidMount() {
		$(this.ref).datetimepicker({
			defaultDate: this.defaultDate,
			maxDate: this.props.endDate ?? moment(),
			useCurrent: false,
			format: this.defaultFormat,
		}).on('dp.change', (e: any) => {
			this.props.onSelect(moment(e.date));
		});
	}

	renderLabel(valid: boolean) {
		return <label className={'input-group-addon' + (valid ? '' : ' has-error')} htmlFor={this.props.id}>
			{(this.props.icon)
				? this.props.icon
				: <i className='fa fa-calendar'/>}
		</label>;
	}

	renderPickerWithValidation() {
		const validationResult = this.props.validator(this.props.date);
		return <div ref={(r) => this.containerRef = r}
		            className={'input-group datetime react-datetimepicker ' + ((validationResult.valid) ? '' : 'has-error')}>
			<input id={this.props.id} ref={(r) => this.ref = r} type='text' className='form-control'
			       autoComplete='off'/>
			{this.renderLabel(validationResult.valid)}
			{(!validationResult.valid && validationResult.message.length > 0) &&
            <ValidationTooltip
                element={this.containerRef}
                placement={this.props.validationPlacement || 'right'}
                container={this.containerRef}
                notHide={true}
            >
				{validationResult.message}
            </ValidationTooltip>
			}
		</div>;
	}

	valueChanged(event: any) {
	}

	renderPicker() {
		return <div className={'input-group datetime react-datetimepicker'}>
			<input id={this.props.id} ref={(r) => this.ref = r} type='text' onChange={(e) => this.valueChanged(e)}
			       className='form-control'
			       autoComplete='off'/>
			{this.renderLabel(true)}
		</div>;
	}

	render() {
		return <>
			{this.props.validator ? this.renderPickerWithValidation() : this.renderPicker()}
		</>;
	}
}