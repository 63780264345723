import {useTeacherPageContext} from '../../../../../context/teacher-page-context';
import {useCallback, useState} from 'react';
import {Group, Student} from '@esgi/main/libs/store';
import {GroupBucket, StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {useNavigate} from 'react-router-dom';
import {TeacherDrawerName} from '@esgi/main/features/teacher/drawers';
import {DrawerMode} from '@esgi/main/features/teacher/home';
import {Box, CounterBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useUser} from '@esgi/core/authentication';

type Props = {
	setViewMode: VoidFunction;
	groupsWithStudents: GroupBucket[];
	studentsWithoutGroup: Student[];
};

export function EditableContent({setViewMode, groupsWithStudents, studentsWithoutGroup}: Props) {
	const {openEditGroupForm, openEditStudentViaProfile} = useTeacherPageContext();

	const navigate = useNavigate();

	const user = useUser();

	const [openedGroupId, setOpenedGroupId] = useState<Group['id']>();

	const handleStudentClicked = useCallback(
		(studentID: number) => {
			const studentProfileDrawerUrl = new URLSearchParams({
				drawerMode: DrawerMode.Edit,
				drawerName: TeacherDrawerName.TeacherStudentViaProfile,
				studentId: studentID.toString(),
			});
			navigate(`/home?${studentProfileDrawerUrl.toString()}`);
		},
		[navigate],
	);

	const openStudentEditableDrawer = useCallback(
		(studentId: Student['id']) => {
			openEditStudentViaProfile({studentId: String(studentId)});
		},
		[openEditStudentViaProfile],
	);

	return (
		<StudentsPanel.Tabs.TabContent withBottomSectionButton buttonText='Done' onBottomButtonClick={setViewMode}>
			{groupsWithStudents.map(({id, name, students, classID}) => {
				const isOpen = openedGroupId === id;

				return (
					<StudentsPanel.Lists.SelectableListGroup.Expandable.Editable
						groupName={name}
						isListOpen={isOpen}
						items={students}
						withCounter
						onStyledButtonClick={() => openEditGroupForm({classId: String(classID), groupId: String(id), schoolYear: String(user?.globalSchoolYearID)})}
						onItemClick={handleStudentClicked}
						onTriggerButtonClick={() =>
							students.length
								? setOpenedGroupId(id)
								: openEditGroupForm({classId: String(classID), groupId: String(id), schoolYear: String(user?.globalSchoolYearID)})
						}
						key={id}
					>
						{({item: {firstName, lastName}}) => (
							<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
						)}
					</StudentsPanel.Lists.SelectableListGroup.Expandable.Editable>
				);
			})}

			<GridBox
				gap='3'
				dataCy='editable-list-students-without-groups'
				css={{
					marginTop: 18,
				}}
			>
				<GridBox flow='column' gap='2' justify='center' align='center'>
					<Text size='small' font='mono' color='lowContrast' data-cy='editable-list-students-without-groups-title'>
						Students without groups
					</Text>
					<Box>
						<CounterBox>
							<Text size='small' font='mono' data-cy='editable-list-students-without-groups-counter-box-value'>
								{studentsWithoutGroup.length}
							</Text>
						</CounterBox>
					</Box>
				</GridBox>
				<StudentsPanel.Lists.SelectableList.Editable
					items={studentsWithoutGroup}
					onItemClick={openStudentEditableDrawer}
				>
					{({item: {firstName, lastName}}) => (
						<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
					)}
				</StudentsPanel.Lists.SelectableList.Editable>
			</GridBox>
		</StudentsPanel.Tabs.TabContent>
	);
}
