import {CloseIcon, Modal, Title} from '@esgillc/ui-kit/modal';
import React from 'react';
import IntroStep from './steps/intro/intro-step';
import styles from './writing-practice.module.less';
import {NameWriting} from './steps/name-writing/name-writing';
import {TemplateType} from './shared/types';
import {SightWords} from './steps/sight-words/sight-words';
import {HierarchyInstance} from 'modules/hierarchy/core/models';

class Props {
	hierarchy: HierarchyInstance;
	onCloseClicked: () => void;
}

export class State {
	templateType: TemplateType = null;
}

export default class WritingPractice extends React.Component<Props, State> {
	public override readonly state = new State();

	public render() {
		return <Modal className={styles.writingPractice} onCatchError={() => this.props.onCloseClicked()}>
			<Modal.Header className={styles.modalHeader}>
				<Title className={styles.modalTitle}>Writing Practice {this.selectedTemplateTypeName()}</Title>
				<CloseIcon color='#fff' onClick={() => this.props.onCloseClicked()}/>
			</Modal.Header>
			<div className={styles.body}>
				{
					this.state.templateType === null &&
					<IntroStep hierarchy={this.props.hierarchy}
							   templateSelected={(template) => this.onTemplateSelected(template)}/>
				}
				{
					this.state.templateType === TemplateType.NameWriting &&
					<NameWriting hierarchy={this.props.hierarchy.snapshot}
								 goToBack={() => this.onResetTemplateType()}
								 close={() => this.props.onCloseClicked()}/>
				}
				{
					this.state.templateType === TemplateType.SightWords &&
					<SightWords hierarchy={this.props.hierarchy.snapshot}
								goToBack={() => this.onResetTemplateType()}
								close={() => this.props.onCloseClicked()}/>
				}
			</div>
		</Modal>;
	}

	private onTemplateSelected(type: TemplateType) {
		this.setState({templateType: type});
	}

	private onResetTemplateType() {
		this.setState({templateType: null});
	}
	
	private selectedTemplateTypeName(){
		if (this.state.templateType != null){
			const templateName = this.state.templateType === TemplateType.NameWriting ? 'Name Writing': 'Sight Words';
			return '/ ' + templateName;
		}
	}
}
