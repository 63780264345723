import {useCallback, useEffect} from 'react';
import {InlineManual} from './inline-manual';
import {useServiceFactory} from '../service';
import {useLocation} from 'react-router-dom';

export function useInlineManual() {
	const instance = useServiceFactory(() => {
		return InlineManual.getInstance();
	});

	const showInlineManual = useCallback(() => {
		instance.showWidget();
	}, [instance]);

	const location = useLocation();

	useEffect(() => {
		if (instance) {
			try {
				instance.update();
			} catch (e) {
				console.error(e);
			}
		}
	}, [location, instance]);

	return {
		showInlineManual,
	};
}