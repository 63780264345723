export class TabTemplate {
	static render() {
		return <div className='edit-school-info-tab-module' data-bind='afterRender: true'>
			<div className='row'>
				<div className='col-sm-3 title'><span className='valign-select-title'>Country:*</span></div>
				<div className='col-sm-9 no-padding-left'>
					<select className='country-select form-control'
					        data-bind="options: countries,optionsText: 'Name', optionsValue: 'CountryID', value: model.countryId, chosen: { disable_search_threshold: 10, width:'100%', search_contains: true }"/>
				</div>
			</div>
			<div className='row'>
				<div className='col-sm-3 title'><span className='valign-select-title'>State\Region:*</span>
				</div>
				<div className='col-sm-9 no-padding-left'>
					<select className='state-select form-control'
					        data-bind="options: states,optionsText: 'name', optionsValue:'id' ,value: model.stateId,chosen: { disable_search_threshold: 10, width:'100%', search_contains: true }"
					/>
				</div>
			</div>
			<div className='row'>
				<div className='col-sm-3 title'><span className='valign-input-title'>District Name:*</span>
				</div>
				<div className='col-sm-9 no-padding-left federal-districts-container'
				     data-bind="css: {'validation-fail':!validate.districtName.validationStatus()}">
					<select className='federal-select form-control'
					        data-bind="options: federalDistricts,optionsText: 'name', optionsValue:'id', value: model.federalDistrictId, chosen: districtChosenOption"/>
				</div>
			</div>
			<div className='row'>
				<div className='col-sm-3 title'><span className='valign-input-title'>School Name:*</span></div>
				<div className='col-sm-9 no-padding-left federal-schools-container'
				     data-bind="css: {'validation-fail':!validate.schoolName.validationStatus()}">
					<select className='federal-select form-control'
					        data-bind="options: federalSchools,optionsText: 'schoolName', optionsValue:'federalSchoolId', value: model.federalSchoolId, chosen: schoolChosenOption"/>
				</div>
			</div>
		</div>;
	}
}
