import {ListItem, CaptionText, IndexText} from './index.styled';
import {forwardRef, ComponentPropsWithoutRef} from 'react';

type Props = {
	name: string;
	index: number;
} & ComponentPropsWithoutRef<'div'>;

export const SelectableLisItemContent = forwardRef<HTMLDivElement, Props>(({index, name, ...rest}, ref)=>{
	return (
		<ListItem ref={ref} {...rest}>
			<CaptionText size='small' data-cy='name'>{name}</CaptionText>
			<IndexText size='xSmall' data-cy='index'>{index < 10 ? `0${index}` : index}</IndexText>
		</ListItem>
	);
});
