import React from 'react';
import './component.less';
import {SelectorGradeRangeType} from 'shared/modules/grade-scale/grade-range/grade-range-type-selector/models';

export class Props {
	sharedRanges: number;
	onClick: () => void;
	selectedType: SelectorGradeRangeType;
	disabled: boolean;
}

export default class GradeRangeTypeSelector extends React.PureComponent<Props> {
	buildTabClass(type: SelectorGradeRangeType) {
		let cls = 'grade-range-type-tab';
		
		if (this.props.selectedType == type) {
			return cls + ' selected';
		}
		
		if (this.props.disabled) {
			return cls + ' disabled';
		}
		
		return cls;
	}
	
	onClick(type: SelectorGradeRangeType) {
		if (type !== this.props.selectedType && !this.props.disabled) {
			this.props.onClick();
		}
	}
	render() {
		return <div className='grade-range-type-selector'>
			<div onClick={() => this.onClick(SelectorGradeRangeType.Custom)} className={this.buildTabClass(SelectorGradeRangeType.Custom)}>RANGE BY TEST</div>
			<div onClick={() => this.onClick(SelectorGradeRangeType.Shared)} className={this.buildTabClass(SelectorGradeRangeType.Shared)}>SHARED RANGE [{this.props.sharedRanges}]</div>
		</div>;
	}
}