import {styled} from '@esgi/ui/theme';
import {Tooltip} from '@esgi/ui/tooltip';
import {Text} from '@esgi/ui/typography';

export const TriggerText = styled(Text, {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export const TooltipContent = styled(Tooltip.Content, {
	width: 'var(--radix-tooltip-trigger-width)',
	wordBreak: 'break-word',
});
