import {Layout} from './index.styled';
import {Header} from './components/header';
import {Body} from './components/body';
import {useAiAnalysisContext} from '../layout/outlet-context';
import {useNavigate} from 'react-router-dom';
import {routes} from '@esgi/main/libs/core';

export default function () {
	const {setSelectedReportType, setTargetReportType} = useAiAnalysisContext();
	const navigate = useNavigate();
	return (
		<Layout>
			<Header />
			<Body
				setReportType={(reportType) => {
					setSelectedReportType(reportType);
					setTargetReportType(reportType);
					navigate(routes.teacher.aiAnalysis.root);
				}}
			/>
		</Layout>
	);
}
