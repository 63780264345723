import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {DeleteButton} from './index.styled';

interface Props {
	onConfirm: VoidFunction;
	onCancel: VoidFunction;
}

export function CloseSelectedBarAlert({onConfirm, onCancel}: Props) {
	const alertRef = Alert.useRef();
	const handleClose = Alert.useClose(alertRef, onCancel);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
			<Alert.Header withBacklight onCloseIconClick={onCancel}>
				<Text size='large' color='negative'>
					Remove Selection
				</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='medium' color='neutral40'>
					This will remove selection for all selected tests. Do you wish to proceed?
				</Text>
			</Alert.Body>
			<Alert.Footer align='center' gapX={3}>
				<Button color='tertiary' onClick={handleClose}>
					<Text size='medium' color='base' bold>
						Cancel
					</Text>
				</Button>
				<DeleteButton onClick={onConfirm} color='secondary'>
					<Text size='medium' color='negative' bold>
						Remove
					</Text>
				</DeleteButton>
			</Alert.Footer>
		</Alert>
	);
}
