import {
	Root,
	AlertDialogProps,
	Portal,
	AlertDialogPortalProps,
	AlertDialogContentProps,
} from '@radix-ui/react-alert-dialog';
import {AlertContext, AlertContextValue} from '../../context';
import {useMemo, useState} from 'react';
import {BaseComponentProps, Skeletonable} from '../../../types';
import {getThemeColor, useAutoControlledState} from '@esgi/ui/utils';
import {ThemeProvider} from '@esgi/ui/theme';
import {config, useTransition} from 'react-spring';
import {AlertContentStyled, AlertOverlay, AnimatedDiv} from './index.styled';
import {AlertColorConfig} from '../../types';
import {alertColorsConfig} from '../../constants';
import {ModalManagerProps, withModalManager} from '@esgillc/ui-kit/modal'; //TODO Fix
import {Property} from '@stitches/react/types/css';

type AlertRootProps = BaseComponentProps &
	AlertDialogProps &
	ModalManagerProps &
	Skeletonable & {
		/**
		 * Alert appearance variant.
		 */
		colorConfig?: AlertColorConfig;

		/** Properties which pass into Portal component. */
		portalProps?: Omit<AlertDialogPortalProps, 'forceMount' | 'children'>;

		/** Properties which pass into Content component. */
		contentProps?: AlertDialogContentProps;

		/**
		 * CSS property sets the z-order of a positioned element.
		 * @default 600
		 */
		zIndex?: Property.ZIndex;
		minWidth?: number,
	};

function AlertRootImpl({
	dataCy = 'ui-kit-alert',
	css = {},
	colorConfig = alertColorsConfig.neutral,
	skeleton,
	defaultOpen,
	open,
	onOpenChange,
	onTransitionStateChanged,
	children,
	portalProps,
	contentProps,
	minWidth = 508,
	zIndex = 600,
}: AlertRootProps) {
	const [labelId, setLabelId] = useState('');
	const [describedId, setDescribedId] = useState('');

	const [isShownContent, setIsShownContent] = useAutoControlledState({
		initialState: defaultOpen ?? false,
		controlledState: open,
		onChange: onOpenChange,
	});

	const [contentWithHeader, setContentWithHeader] = useState(false);

	const fadePortal = useTransition(isShownContent, {
		from: {opacity: 0},
		enter: {opacity: 1},
		leave: {opacity: 0},
		config: config.gentle,
		onRest: () => onTransitionStateChanged('exit'),
	});

	const contextValue = useMemo<AlertContextValue>(
		() => ({
			colorConfig,
			setContentWithHeader,
			setDescribedId,
			setLabelId,
		}),
		[colorConfig],
	);

	return (
		<AlertContext.Provider value={contextValue}>
			<Root open={isShownContent} onOpenChange={setIsShownContent}>
				{fadePortal((styles, item) => {
					if (!item) {
						return null;
					}

					return (
						<Portal forceMount {...portalProps}>
							<AnimatedDiv style={styles} css={{...css, position: 'relative', zIndex}}>
								<ThemeProvider>
									<AlertOverlay data-cy={`${dataCy}-overlay`} data-alert-overlay='' />
									<AlertContentStyled
										data-cy={`${dataCy}-content`}
										css={{
											borderColor: colorConfig.borderColor ? getThemeColor(colorConfig.borderColor) : 'transparent',
											minWidth,
										}}
										contentWithHeader={contentWithHeader}
										aria-describedby={describedId}
										aria-labelledby={labelId}
										data-alert-content=''
										{...contentProps}
									>
										{children}
									</AlertContentStyled>
								</ThemeProvider>
							</AnimatedDiv>
						</Portal>
					);
				})}
			</Root>
		</AlertContext.Provider>
	);
}

export const AlertRoot = withModalManager(AlertRootImpl);
