import {EmptyDataContainer, EmptyContent as EmptyContentBox} from './index.styled';

type Props = {
	messageText: string;
	withoutDefaultHeight?: boolean;
};

export function EmptyContent({messageText, withoutDefaultHeight = false}: Props) {
	return (
		<EmptyDataContainer withoutDefaultHeight={withoutDefaultHeight}>
			<EmptyContentBox templateText={messageText} />
		</EmptyDataContainer>
	);
}
