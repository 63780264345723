import React from 'react';
import {Root, Panel} from './index.styled';
import {useService} from '@esgi/core/service';
import {RubricResultReportService} from './service';
import useReportState from './hooks/use-report-state';
import {TestSelectionPanel} from './components/test-selection-panel';
import {SettingsPanel} from './components/settings-panel';
import {useReportsContext} from '../../../layout/outlet-context';
import {PreviewDialog} from './components/preview-dialog';
import {SubjectTab} from '@esgi/main/libs/store';
import {Reports} from '@esgi/main/kits/reports';
import {AddTestDrawer} from '@esgi/main/features/teacher/drawers';

export default function ClassGrades() {
	const {snackbarRef} = useReportsContext();
	const service = useService(RubricResultReportService);

	const {
		selectedEntity,
		subjects,
		isSubjectsLoaded,
		selectedSubject,
		selectedTest,
		tests,
		setPreview,
		showPreview,
		setShowAddTestsDrawer,
		showAddTestsDrawer,
		onEntitySelect,
		disabled,
	} = useReportState(service);

	return (
		<Root>
			<Panel>
				<Reports.ClassLevel.StudentPanel onSelect={onEntitySelect} hideEmptyClassAndGroup/>
			</Panel>
			<TestSelectionPanel
				selectedEntity={selectedEntity}
				subjects={subjects}
				isSubjectsLoaded={isSubjectsLoaded}
				selectedSubject={selectedSubject}
				onSubjectSelect={value => service.setSubject(value)}
				tests={tests}
				selectedTest={selectedTest}
				onTestSelect={value => service.setTest(value)}
				setShowAddTestsDrawer={() => setShowAddTestsDrawer(true)}
			/>
			<SettingsPanel
				service={service}
				disabled={disabled}
				setShowPreview={() => setPreview(true)}
			/>
			{showPreview && <PreviewDialog onClose={() => setPreview(false)} service={service}/>}
			{showAddTestsDrawer &&
				<AddTestDrawer<SubjectTab>
					onClose={() => setShowAddTestsDrawer(false)}
					subject={subjects.find(item => item.id === selectedSubject.id)}
					snackbarRef={snackbarRef}
				/>}
		</Root>
	);
}