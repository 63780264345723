import {ChangeEvent} from 'react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import styles from './styles.module.less';
import {SelectableList, SelectableListItem} from '@esgillc/ui-kit/control';
import {SelectDropdownVariants} from 'modules/forms/group-form/components/checkbox-list-box/types';
import {CheckboxOptions} from 'modules/forms/group-form/components/checkbox-list-box/components/checkbox-options';
import {InModel} from '../../types';


interface Props {
	items: InModel[],
	onCheckboxClick: (checked: boolean) => void,
	onItemCheckboxClick: (selectedItems: number[]) => void,
	isChecked: boolean | undefined,
	label: string,
	withLabelTooltip?: boolean
}


export function CheckboxListBox({
	                                items,
	                                onCheckboxClick,
	                                isChecked,
	                                label,
	                                onItemCheckboxClick,
	                                withLabelTooltip = false,
}: Props) {

	const renderLabel = () => {
		const showTooltip = withLabelTooltip && label.length > 40;
		return <OnHoverTooltip message={showTooltip ? label : ''}>
			<label data-cy={showTooltip ? 'list-label-with-tooltip' : 'list-label'}
			       className={styles.selectorHeaderLabel}>
				{label}
			</label>
		</OnHoverTooltip>;
	};

	const checkedItems = items.filter(i => i.checked).map(i => i.id);

	const onOptionSelect = (value: SelectDropdownVariants) => {
		onCheckboxClick(value === SelectDropdownVariants.All);
	};

	const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		onCheckboxClick(e.target.checked);
	};

	return <div data-cy='checkbox-list-box'>
		<div className={styles.checkboxOptions}>
			<CheckboxOptions
				onSelect={onOptionSelect}
				isCheckboxChecked={isChecked}
				isCheckboxDisabled={!items.length}
				onCheck={onCheckboxChange}/>
			{renderLabel()}
		</div>
		<div className={styles.checkboxList} data-cy='checkbox-list'>
			<SelectableList
				checkboxPlacement={'left'}
				onChange={onItemCheckboxClick} value={checkedItems}>
				{items.map((item) => <SelectableListItem
					disabled={false}
					className={styles.selectableListItem}
					key={item.id}
					value={item.id}>{item.label}
				</SelectableListItem>)}
			</SelectableList>
		</div>
	</div>;

}
