import React, {createRef, PureComponent, ReactNode, RefObject} from 'react';
import {range} from 'underscore';
import {Input} from '@esgillc/ui-kit/control';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {SpecialButtons} from '@esgillc/ui-kit/button';
import {isIpad} from '@esgillc/ui-kit/utils';

import styles from './pin.module.less';

class State {
	pin: string[] = [];
	showPin: boolean = false;
	showPinWarn: boolean = false;
	currentFocusIndex: number = -1;
}

interface Props {
	autofocus?: boolean;
	canShowPin?: boolean;
	initialValue?: string[];
	readonly?: boolean;
	onChange?: (value: string[]) => void;
	onUserCompleteEnter?: (value: string) => void;
	onBlur?: () => void;
	tooltipPlacement?: 'left' | 'right' | 'top' | 'bottom';
}

export class Pin extends PureComponent<Props, State> {
	public readonly state = new State();

	private blurTimer: number = 0;
	private readonly isIPad = isIpad();
	private readonly indexes = range(0, 4);
	private readonly inputsRefs: RefObject<HTMLInputElement>[] = [createRef(), createRef(), createRef(), createRef()];
	private readonly dotCharacter = '\u25CF';
	private readonly pinRowRef: RefObject<HTMLDivElement> = createRef();

	public componentDidMount() {
		if (this.props.initialValue) {
			this.setState({pin: this.props.initialValue});
		}
	}

	public focus() {
		this.inputsRefs[0]?.current?.focus();
	}

	public validate(): boolean {
		let valid = this.state.pin.filter(c => !!c).length === 4;
		if (valid) {
			for (let char of this.state.pin) {
				if (!/^\d+$/.test(char)) {
					valid = false;
					break;
				}
			}
			this.setState({showPinWarn: !valid});
		}

		return valid;
	}

	public render() {
		return <>
			<div className={styles.pinRow} ref={this.pinRowRef}>
				{this.indexes.map(i => this.renderInput(i))}
				{this.props.canShowPin && <SpecialButtons.Hide scale={1.5}
				                                               onClick={() => this.setState({showPin: !this.state.showPin})}
				                                               active={!this.state.showPin}/>}
			</div>
			{this.state.showPinWarn &&
				<ValidationTooltip element={null} elementRef={this.pinRowRef}
				                   placement={this.props.tooltipPlacement || 'right'}>
					PIN must be numerical. Please try again.
				</ValidationTooltip>}
		</>;
	}

	private renderInput(index: number): ReactNode {
		const value = this.getPinValueByIndex(index);
		return <Input key={index}
		              autoFocus={!this.isIPad && this.props.autofocus && index === 0}
		              disabled={this.props.readonly}
		              type={'number'}
		              inputMode={'numeric'}
		              pattern={'[0-9]*'}
		              placeholder={value}
		              value={this.isIPad && !this.state.showPin ? '' : value}
		              className={styles.input}
		              ref={(ref) => (this.inputsRefs[index] as any).current = ref}
		              onFocus={(e) => {
			              this.cancelBlurEvent();
			              this.setState({currentFocusIndex: index});
			              if (e.target.value) {
				              e.target?.select();
			              }
		              }}
		              onBlur={() => {
			              this.delayBlurEvent();
			              this.setState({currentFocusIndex: -1});
		              }}
		              onChange={() => ({})}
		              onKeyUp={(e) => {
			              if (/^\d+$/.test(e.key)) {
				              this.setState({showPinWarn: false}, () => {
					              this.setPinValueByIndex(index, e.key);
					              this.moveNext(index);
				              });
			              } else {
				              switch (e.key) {
					              case 'Backspace':
						              return this.handleBackspace(index);
					              case 'Delete':
						              return this.handleDelete(index);
					              case 'Tab':
						              return;
					              case 'Shift':
						              return;
					              default:
						              this.setState({showPinWarn: true});
				              }
			              }
		              }}
		              maxLength={1}/>;
	}

	private delayBlurEvent(): void {
		this.blurTimer = window.setTimeout(() => {
			this.props.onBlur && this.props.onBlur();
			this.validate();
		}, 100);
	}

	private cancelBlurEvent(): void {
		clearTimeout(this.blurTimer);
	}

	private handleBackspace(index: number): void {
		const currentValue = this.getPinValueByIndex(index);
		if (currentValue) {
			this.setPinValueByIndex(index, '');
		} else {
			this.setPinValueByIndex(index - 1, '');
			this.moveBack(index);
		}
	}

	private handleDelete(index: number): void {
		this.setPinValueByIndex(index, '');
	}

	private moveBack(index: number): void {
		const currentElement = this.inputsRefs[index];
		const nextElement = this.inputsRefs[index - 1];
		if (nextElement) {
			nextElement.current?.focus();
		} else {
			currentElement.current?.blur();
		}
	}

	private moveNext(index: number): void {
		const currentElement = this.inputsRefs[index];
		const nextElement = this.inputsRefs[index + 1];
		if (nextElement) {
			nextElement.current?.focus();
		} else {
			currentElement.current?.blur();
			if (this.state.pin.every(v => !!v)) {
				this.props.onUserCompleteEnter && this.props.onUserCompleteEnter(this.state.pin.join(''));
			}
		}
	}

	private getPinValueByIndex(index: number): string {
		const char = this.state.pin[index] || '';
		if (char) {
			return this.state.showPin ? char : this.dotCharacter;
		}
	}

	private setPinValueByIndex(index: number, value: string): void {
		if (index < 0) {
			return;
		}

		const pin = this.state.pin;
		pin[index] = value;
		this.setState({pin: [...pin]}, () => this.props.onChange && this.props.onChange(this.state.pin));
	}
}
