import {StudentSort, TimezoneValue, StudentSortModel} from '../../user-settings/types';

export enum ReportShowStudentID {
	None = 'None',
	StudentID = 'StudentID',
	ExportID = 'ExportID',
}

export interface InitResponse {
	isCleverAccountLinked: boolean;
	isOneClickAccountLinked: boolean;
	sortBy: StudentSort;
	sortByVariants: StudentSortModel[];
	districtID: number;
	userID: number;
	globalSchoolYears: GlobalSchoolYearModel[];
	timeZones: TimezoneValue[];
	selectedTimeZone: TimezoneValue;
	showExpiredUsers: boolean;
	reportShowStudentID: ReportShowStudentID;
}

export interface UpdateRequest {
	sortBy: StudentSort;
	timeZone: string;
	showExpiredUsers: boolean;
	reportShowStudentID: ReportShowStudentID;
}

export interface GlobalSchoolYearModel {
	globalSchoolYearID: number;
	name: string;
}

export interface DownloadImportFilesResponse {
	id: number;
}
