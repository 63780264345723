import {useEffect} from 'react';
import {Page} from './complete-registration';
import styles from './styles.module.less';
import {Fade} from '@esgillc/ui-kit/transition';

export default function CompleteRegistrationRoot() {
	useEffect(() => {
		try {
			const page = new Page();
			return () => page.destroy();
		} catch (e) {
			console.error(e);
		}
	}, []);

	return <Fade in animateOnMount duration='common'>
		<div data-bind='render: registration' className={styles.registration}/>
	</Fade>;
}
