import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const RootBox = styled(GridBox, {
	gap: '$5',
	paddingTop: '$5',
	paddingBottom: '$5',
	paddingLeft: '$3',
	paddingRight: '$3',
	height: '100vh',
	background: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',
	gridTemplateRows: 'auto 1fr',
	maxWidth: 256,
});
