import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Radio} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import styles from './class-selector.module.less';
import React from 'react';
import {ClassResponse} from '../types';
import SelectorBox from '../../../shared/components/selector-box/selector-box';
import SelectorBoxHeader from '../../../shared/components/selector-box/header';
import SelectorBoxBody from '../../../shared/components/selector-box/body';

interface Props {
	className?: string;
	classes: ClassResponse[];
	control: IFormControl;
}

export default class ClassSelector extends React.PureComponent<Props> {
	render() {
		const isDataLoaded = this.props.classes != null;
		const hasData = this.props.control && this.props.classes?.length > 0;
		return <div className={this.props.className}>
			<SelectorBox>
				<SelectorBoxHeader>
					Classes
				</SelectorBoxHeader>
				<SelectorBoxBody className={join(styles.body, !hasData && styles.emptyBody)}>
					{
						isDataLoaded ? !hasData && <div className={styles.emptyBodyContent}>No classes have been created</div> : <span/>
					}
					{hasData &&
						<FormElement control={this.props.control} className={styles.formGroup}>
							{this.props.classes.map(x => <Radio key={x.id} value={x.id}>{x.name}</Radio>)}
						</FormElement>
					}
				</SelectorBoxBody>
			</SelectorBox>
		</div>;
	}
}
