import React from 'react';
import styles from './elements.module.less';
import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
	children?: any;
}

export function FiltersContentBlock(props: Props) {
    	return <div className={join(styles.filtersContentBlock, props.className)}>
			{props.children}
		</div>;
}
