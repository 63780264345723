import React from 'react';
import {EventBusDispatcher} from '@esgillc/events';

class Props {
	scope: string;
}

export class EmptyResults extends React.PureComponent<Props> {
	render() {
		let notAll = this.props.scope !== 'AllTests';

		return <div className='empty'>
			<div className='wrapper'>
				<p>Your search returned 0 results.</p>
				{notAll ? <p>Try your search against <a href='#' onClick={() => this.clicked()}>All Tests</a></p> : ''}
			</div>
		</div>;
	}

	clicked() {
		EventBusDispatcher.dispatch(EmptyResultsEvents.Clicked, {});
	}
}


export module EmptyResultsEvents {
	export function Clicked() {

	}
}