import {PropsWithChildren} from 'react';
import {FileTypeType, ChangeFunction} from '../types';
import {useValue} from '../hooks';
import {Section, Title, Body, ToggleGroupRoot, ToggleGroupContent, ToggleGroupItem, Label} from './index.styled';

interface Props extends PropsWithChildren {
	disabled?: boolean;
	visibleToggler?: boolean;
	onChange: ChangeFunction<FileTypeType>;
}

export function FileTypeControl(props: Props) {
	const {disabled, visibleToggler = true, children, onChange} = props;
	const [value, setValue] = useValue<FileTypeType>(FileTypeType.Pdf, onChange);

	return (
		<Section dataCy='output-section'>
			<Title size='small' font='mono' color='lowContrast' data-cy='output-title'>Output</Title>
			<Body columns={visibleToggler && children ? 2 : 1}>
				{visibleToggler &&
					<ToggleGroupRoot
						value={value}
						disabled={disabled}
					>
						<ToggleGroupContent>
							<ToggleGroupItem
								value={FileTypeType.Pdf}
								onClick={() => setValue(FileTypeType.Pdf)}
							>
								<Label size='medium' bold>PDF</Label>
							</ToggleGroupItem>
							<ToggleGroupItem
								value={FileTypeType.Zip}
								onClick={() => setValue(FileTypeType.Zip)}
							>
								<Label size='medium' bold>ZIP</Label>
							</ToggleGroupItem>
						</ToggleGroupContent>
					</ToggleGroupRoot>
				}
				{children}
			</Body>
		</Section>
	);
}
