import {Buttons} from '@esgillc/ui-kit/button';
import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './set-minimum-level.module.less';

type Props = {
    onClose: () => void;
    setMinimemLevel: () => void;
}

export function SetMinimumLevel(props: Props): JSX.Element {
	const modalManager = useModal();

	const closeModal = useCloseModal(modalManager, props.onClose);
	const setMinimemLevelAsZero = useCloseModal(modalManager, props.setMinimemLevel);

	return <Modal modalManagerRef={modalManager} className='col-md-3'>
        <Modal.Body>
            <div className='row'>
                <div className='col-md-12 col-xs-12'>
                    <p className={styles.modalTitle}>
                        Set Minimum Level as Zero?
                    </p>
                    <p className={styles.modalText}>
                        Change the minimum scoring level
                        from 1 to 0 if you would wish to
                        identify students for whom the given
                        criteria was not observed during the
                        assessment. A zero score will appear
                        on reports and results.
                    </p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className='row'>
                <div className='col-md-5 col-xs-5'>
                    <Buttons.Text onClick={() => closeModal()}>CANCEL</Buttons.Text>
                </div>
                <div className='col-md-5 col-xs-5'>
                    <Buttons.Text onClick={() => setMinimemLevelAsZero()}>CONFIRM</Buttons.Text>
                </div>
            </div>
        </Modal.Footer>
    </Modal>;
}