import React from 'react';
import TestRow
	from 'shared/modules/reports/flash-cards/settings-form/components/tests-table/components/test-row/component';
import moment from 'moment';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import SessionDropDown from 'shared/modules/reports/session-dropdown/session-dropdown';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import {SettingsModel} from 'shared/modules/reports/flash-cards/settings-form/services/settings-service';

class State {
    tests: Models.Test[];
    settings: SettingsModel;
    alertPositionX: number;
	incorrectVerbiageColumnHeader: string;
}

class Props {
    dataService: DataService;
}

export default class FlashCardSettingsBody extends React.Component<Props, State> {
    private sessionDropDown: SessionDropDown;
    private chooseSessionRef: React.RefObject<HTMLDivElement> = React.createRef();

    private tableHeaderRef: React.RefObject<HTMLTableHeaderCellElement> = React.createRef();

    constructor(props?: Props) {
    	super(props);
    	this.state = {
    	    tests: [],
    		alertPositionX: 0,
    		settings: new SettingsModel(),
    		incorrectVerbiageColumnHeader: '',
    	};
    }

    componentDidMount(): void {
    	this.positionAlert();
    	this.props.dataService.settingsService.settings$.subscribe((settings) => {
    		if (this.state.settings.includeSessionOption !== settings.includeSessionOption){
    			this.positionAlert();	
    		}
    		this.setState({settings: settings});
    	});    
    	
    	this.props.dataService.settingsService.incorrectVerbiageColumnHeader$.subscribe((incorrectVerbiageColumnHeader) => {
    		this.setState({incorrectVerbiageColumnHeader: incorrectVerbiageColumnHeader});
    	});
    	this.props.dataService.testsService.tests$.subscribe((tests) => {
    		this.positionAlert();
    		this.setState({tests: tests});
    	});
    }

    private setSessionClicked() {
    	let left: number;
    	let top: number;
    	if (this.sessionDropDown) {
    		return;
    	}

    	if (this.chooseSessionRef.current) {
    		const rect = this.chooseSessionRef.current.getBoundingClientRect();
    		top = rect.top;
    		left = rect.left;
    	}

    	const {tracks, sessionDate} = this.props.dataService.settingsService;
    	this.sessionDropDown = new SessionDropDown(
    		left,
    		top + 40,
    		tracks.map(track => ({id: track.trackID, date: track.dateTo.format(Models.Date.defaultFormat)})),
    		sessionDate);
    	this.sessionDropDown.events.hidden(() => {
    		this.sessionDropDown = null;
    	});
    	this.sessionDropDown.events.dateClicked((e, newDate: string) => {
    		const date = moment(newDate.replace(/[-]/g, '/')).format(Models.Date.defaultFormat);
    		const m = new SettingsModel();
    		m.sessionDate = date;

    		this.props.dataService.settingsService.updateSettingsModel(m);
    		this.props.dataService.testsService.updateTestsDate(date);
    		this.props.dataService.testsService.reloadTests(new Models.ReloadTestsModel());
    	});
    	this.sessionDropDown.show();
    }

    private positionAlert() {
    	setTimeout(() => {
    		this.setAlertPosition();
    	}, 1);
    }

    private setAlertPosition() {
    	const ref = this.tableHeaderRef.current;
    	if (ref){
    		const rect = ref.getBoundingClientRect();
    		this.setState({alertPositionX: rect.width});
    	}
    }

    render() {
    	return 	<div className='fc-settings-body'>
            <div className={this.props.dataService.testsService.showAlert ? 'overlay-wrapper show-alert' : 'overlay-wrapper'}>
                {this.props.dataService.testsService.showNoTestsSelectedAlert &&
                <div className='data-alert' style={{left: this.state.alertPositionX}}>
                    <span>{this.props.dataService.testsService.dataAlertText}</span>
                </div>
                }
				{this.props.dataService.testsService.showNoTestsAlert &&
				<div className='no-tests-alert' style={{left: this.state.alertPositionX}}>
					<span>
						This subject tab does not have any Yes/No tests. Single Score and Rubric tests are not supported in Flashcards. Please choose another subject tab.
					</span>
				</div>
				}
				{this.props.dataService.testsService.showNoStudentsAlert &&
				<div className='no-tests-alert' style={{left: this.state.alertPositionX}}>
					<span>
						No students/data to display for that class/subject/group.
					</span>
				</div>
				}
                <div className='table-wrapper'>
                    <table className='table table-striped table-bonnie'>
                        <thead>
                        <tr>
                            <th ref={this.tableHeaderRef} className='test-name-cell'>
                                <strong>Test Name</strong><br/>
                            </th>
                            <th className='results-cell table-block text-center'>{this.props.dataService.settingsService.testResultsCorrectVerbiage}</th>
                            <th className='results-cell text-center'>{this.props.dataService.settingsService.testResultsIncorrectVerbiage}</th>
                            <th className='results-cell text-center'>Skipped</th>
                            <th className='max-items-cell table-block'>
                                <span>{this.state.incorrectVerbiageColumnHeader}</span>
                                <a className='inline-tooltip'
                                   href='https://support.esgisoftware.com/hc/en-us/articles/209159886-Flash-Cards#Number'
                                   target='_blank' rel='noreferrer'>
                                    <sup className='fa fa-question-circle' aria-hidden='true'/>
                                </a>
                            </th>
                            {this.props.dataService.settingsService.includeSessionOption &&
                            <th className='text-left'>
                                <div className='choose-session-header' ref={this.chooseSessionRef}>
                                    <span className='choose-session-title'>View session as of</span>
                                    {this.props.dataService.settingsService.printOutOption !== 'FullSet' &&
                                    <i className='fa fa-caret-down' aria-hidden='true'
                                       onClick={() => this.setSessionClicked()}
                                    />
                                    }
                                </div>
                            </th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.tests.map((t) => {
                                return <TestRow
                                    key={t.id}
                                    test={t}
                                    dataService={this.props.dataService}
                                />;
                            },
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;
    }
}