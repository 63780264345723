import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';

type Props = {
	disabled?: boolean;
};

export function SelectAllClassesButton({disabled}: Props) {
	const {
		entities: {students},
	} = StudentsPanel.useStudentsPanelContext();

	const {
		selectClass,
		selected: {classId: selectedClassId},
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	return (
		<StudentsPanel.Tabs.SelectAllEntitiesButton
			label='All Classes'
			selected={selectedClassId === -1}
			onClick={() => selectClass(-1)}
			disabled={disabled}
			studentsCount={students.length}
			css={{
				marginBottom: 20,
			}}
		/>
	);
}
