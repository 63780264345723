import {tap} from 'rxjs/operators';
import {AssignmentCardService} from './assignment-card-service';
import {AssignmentTeacherModel} from '../types/teacher-types';

export class AssignmentCardTeacherService extends AssignmentCardService {
	private readonly controllerViewTeacher = 'assignments/view/teacher';

	public init(assignmentID: number) {
		this.assignmentID = assignmentID;
		return this.httpClient.ESGIApi.get<{
			assignment: AssignmentTeacherModel | null;
		}>(this.controllerViewTeacher, 'info', {assignmentID})
			.pipe(
				tap((r) => {
					if (r.assignment?.state) {
						this.status.next(r.assignment.state);
					}
				}),
			)
			.asObservable();
	}
}
