import {useCallback, useEffect, useState} from 'react';
import {getGroupsData} from './utils';
import {AllOptionItem, GroupState, OptionType} from './types';
import {Group, Student} from '@esgi/main/libs/store';
import {isUndefined} from '@esgi/ui';

interface Props {
	groups: Group[];
	students: Student[];
}

export function useGroupList({groups, students}: Props) {
	const [selectedClassId, setSelectedClassId] = useState<OptionType>(AllOptionItem.Value);
	const [groupsWithStudents, setGroupsWithStudents] = useState<GroupState[]>([]);
	const [studentsWithoutGroup, setStudentsWithoutGroup] = useState<Student[]>([]);

	useEffect(() => {
		const {studentsWithoutGroup, groupsWithStudents} = getGroupsData({selectedClassId, groups, students});

		setGroupsWithStudents((currentState) =>
			groupsWithStudents.map<GroupState>((groupData) => {
				const current = currentState.find(({id}) => groupData.id === id);

				return {...groupData, isListOpen: current?.isListOpen ?? false};
			}),
		);

		setStudentsWithoutGroup(studentsWithoutGroup);
	}, [selectedClassId, groups, students]);

	const onToggle = useCallback((isListOpen: boolean, groupId: Group['id']) => {
		setGroupsWithStudents((currentGroupsList) => {
			const copied = [...currentGroupsList];
			const copiedGroup = copied.find(({id}) => id === groupId);

			if (copiedGroup) {
				copiedGroup.isListOpen = isListOpen;
			}

			return copied;
		});
	}, []);

	const onSelectAll = useCallback(
		({
			groupId,
			selectedStudentIds,
			isListSelected,
		}: {
			groupId: Group['id'];
			selectedStudentIds: Student['id'][];
			isListSelected: boolean;
		}): Student['id'][] => {
			const group = groupsWithStudents.find(({id}) => groupId === id);

			if (isUndefined(group)) {
				return selectedStudentIds;
			}

			const studentsIDsInGroup = group.students.map(({id}) => id);

			if (isListSelected) {
				return selectedStudentIds.filter((studentID) => !studentsIDsInGroup.includes(studentID));
			}

			return [...selectedStudentIds, ...studentsIDsInGroup.filter((id) => !selectedStudentIds.includes(id))];
		},
		[groupsWithStudents],
	);

	return {
		setSelectedClassId,
		groupsWithStudents,
		studentsWithoutGroup,
		onToggle,
		onSelectAll,
	};
}
