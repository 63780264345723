import {useItemAnalysisService} from '../../../../hooks';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useMemo, useRef} from 'react';
import {toDetailsPDF} from '../../../../utils';
import {Text} from '@esgi/ui/typography';
import {Box} from '@esgi/ui/layout';
import {Download} from '@esgi/ui/icons';
import {Detail, DetailHeader, DetailBody, ButtonIcon, TestData, QuestionData, Toggler} from './index.styled';

export function QuestionDetails() {
	const service = useItemAnalysisService();
	const selectedQuestionLabel = useBehaviorSubject(service.reportService.selectedQuestionLabel$);
	const reportDate = useBehaviorSubject(service.reportService.reportDate$);
	const questionDetails = useBehaviorSubject(service.reportService.questionDetails$);
	const testResultsCorrectVerbiage = useBehaviorSubject(service.reportService.testResultsCorrectVerbiage$);
	const testResultsIncorrectVerbiage = useBehaviorSubject(service.reportService.testResultsIncorrectVerbiage$);
	const testId = useBehaviorSubject(service.test$);
	const qdBodyRef = useRef(null);
	const selectedTest = useMemo(() => service.testService.get(testId), [testId]);

	const hideQuestionDetails = () => {
		service.reportService.setNeedRerender(true);
		service.reportService.setQuestionDetailsShow(false);
	};

	const getDetailsPdf = () => {
		if (!qdBodyRef.current) {
			return;
		}
		service.onDownloadDetails(toDetailsPDF(qdBodyRef.current.outerHTML, DetailBody.className, DetailHeader.className, QuestionData.className));
	};

	return (
		<Detail id='iar-qd'>
			<DetailHeader>
				<Text data-cy='chart-details-title' size='large' color='vivid'>Chart Details</Text>
				<ButtonIcon dataCy='button-details-pdf' onClick={getDetailsPdf}>
					<Download/>
				</ButtonIcon>
			</DetailHeader>
			<DetailBody
				id='iar-qd-body'
				ref={qdBodyRef}
			>
				<Text data-cy='test-question-title' size='large' bold>Test Question: {selectedQuestionLabel}</Text>
				<TestData>
					<Text data-cy='selected-test-title' size='large'>Test: {selectedTest.name}</Text>
					<Text data-cy='report-date' size='large' color='neutral72'>{reportDate}</Text>
				</TestData>
				{questionDetails &&
					<QuestionData>
						{questionDetails.studentsCorrect.length > 0 &&
							<Box>
								<Text data-cy='correct-students-title' size='large'>{testResultsCorrectVerbiage}</Text>
								{questionDetails.studentsCorrect?.map((data) =>
									<Box key={`iar-qd-cr-${data}`}>
										<Text data-cy='correct-student-name'>{data}</Text>
									</Box>
								)}
							</Box>
						}
						{questionDetails.studentsIncorrect.length > 0 &&
							<Box>
								<Text data-cy='incorrect-students-title' size='large'>{testResultsIncorrectVerbiage}</Text>
								{questionDetails.studentsIncorrect?.map((data) =>
									<Box key={`iar-qd-in-${data}`}>
										<Text data-cy='incorrect-student-name'>{data}</Text>
									</Box>
								)}
							</Box>
						}
						{questionDetails.studentsNotTested.length > 0 &&
							<Box>
								<Text data-cy='not-tested-students-title' size='large'>Not Tested</Text>
								{questionDetails.studentsNotTested?.map((data) =>
									<Box key={`iar-qd-nt-${data}`}>
										<Text data-cy='not-tested-student-name'>{data}</Text>
									</Box>
								)}
							</Box>
						}
					</QuestionData>
				}
			</DetailBody>
			<Toggler dataCy='hide-questions' onClick={hideQuestionDetails}/>
		</Detail>
	);
}
