import {Dispatch, useCallback, useState} from 'react';
import {FieldType, SortDirection, Sorting} from '../types';
import {useSearchParams} from 'react-router-dom';

const sortingKey = 'sorting';
const directionKey = 'direction';

export function useSorting(): [Sorting, Dispatch<Sorting>] {
	const [searchParams, updateSearchParams] = useSearchParams();

	const [sorting, setSorting] = useState<Sorting>(() => {
		let field = FieldType.Name;
		let direction = SortDirection.Asc;

		const sortingParam = searchParams.get(sortingKey);
		if(sortingParam && Object.values(FieldType).map(k => k.toString()).includes(sortingParam)) {
			field = sortingParam as FieldType;
		}

		const directionParam = searchParams.get(directionKey);
		if(directionParam && Object.values(SortDirection).map(k => k.toString()).includes(directionParam)) {
			direction = directionParam as SortDirection;
		}

		return {field, direction};
	});
	const updateSorting = useCallback((value: Sorting) => {
		setSorting(value);
		updateSearchParams((search) => {
			search.set(sortingKey, value.field);
			search.set(directionKey, value.direction);
			return search;
		});
	}, [sorting, searchParams, updateSearchParams, setSorting]);
	
	return [sorting, updateSorting];
}