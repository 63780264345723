import React, {ComponentProps, forwardRef} from 'react';
import {Slider} from '@esgi/ui/controls';
import {useImageCropperContext} from '../../context';
import {BaseComponentProps} from '@esgi/ui';

type ImageCropperSliderProps = ComponentProps<typeof Slider> & BaseComponentProps

export const ImageCropperSlider = forwardRef<HTMLDivElement, ImageCropperSliderProps>(({
	dataCy = 'ui-kit-image-cropper-slider',
	...props
}) => {

	const {minZoom, maxZoom, onZoomChange, zoom, step} = useImageCropperContext();

	return <Slider
		dataCy={dataCy}
		step={step}
		min={minZoom}
		max={maxZoom}
		value={zoom}
		onValueChange={onZoomChange}
		{...props}
	/>;
});
