import {
	TestSessionRubricCreatedEventData,
	TestSessionScoreCreatedEventData,
	TestSessionYNCreatedEventData,
} from './types';

export class TestSessionYNCreatedEvent {
	constructor(public sessionData: TestSessionYNCreatedEventData) {}
}

export class TestSessionScoreCreatedEvent {
	constructor(public sessionData: TestSessionScoreCreatedEventData) {}
}

export class TestSessionRubricCreatedEvent {
	constructor(public sessionData: TestSessionRubricCreatedEventData) {}
}
