import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Tooltip} from '@esgi/ui/tooltip';
import {Text} from '@esgi/ui/typography';

export const inlineGapElements = 12;

export const Container = styled(GridBox, {
	gap: inlineGapElements,
	alignItems: 'center',
	paddingTop: 8,
	paddingBottom: 8,

	variants: {
		withComment: {
			true: {
				gridTemplateColumns: 'auto auto auto 1fr',
			},

			false: {
				gridTemplateColumns: 'auto auto 1fr',
			},
		},

		moreThanOneLineTitle: {
			true: {
				alignItems: 'start',
			},

			false: {},
		},
	},

	compoundVariants: [
		{
			withComment: true,
			moreThanOneLineTitle: false,
			css: {
				paddingTop: 4,
				paddingBottom: 4,
			},
		},

		{
			withComment: true,
			moreThanOneLineTitle: true,
			css: {
				'& [data-comment-icon-box]': {
					position: 'relative',
					top: -4,
				},
			},
		},
	],
});

export const CommentIconBox = styled(GridBox, {
	'& > svg': {
		fill: '$tertiary',

		'& path': {
			fill: '$tertiary',
		},
	},
});

export const CommentTooltipContent = styled(Tooltip.Content, {
	[`& ${Text}`]: {
		'&[data-comment-tooltip-title]': {
			color: '$neutral40',
		},

		'&[data-comment-tooltip-content-text]': {
			color: '$base',
		},
	},

	variants: {
		overrideStyles: {
			true: {
				display: 'grid',
				gap: '$2',
				maxWidth: 212,
				padding: 12,
				borderColor: '$orange92',
				borderRadius: 8,

				'&:hover': {
					boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
				},
			},
		},
	},

	defaultVariants: {
		overrideStyles: true,
	},
});

export const ChildrenBox = styled(GridBox, {
	justifySelf: 'end',
});

export const Divider = styled(Box, {
	height: 1,
	borderRight: 1,
	backgroundColor: '$border',
});
