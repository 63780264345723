import {useCallback, useEffect, useMemo, useState} from 'react';
import {
	ModeButtonsPermissions,
	SortByOption,
	TestDetailsState,
	TestedOption,
	TestSectionMode,
	TestSectionTabId,
} from '../types';
import {TestModel} from '../../../types';
import {useFilteredTests} from './use-filtered-tests';
import {SubjectLevel, SubjectTab} from '@esgi/main/libs/store';

export function useSectionContentState<TestModelType extends TestModel>({
	allTests,
	subject,
}: {
	allTests: TestModelType[];
	subject: SubjectTab | null;
}) {
	const [sectionModePermissions, setSectionModePermissions] = useState<Record<TestSectionMode, boolean>>({
		[TestSectionMode.View]: true,
		[TestSectionMode.Rearrange]: false,
		[TestSectionMode.Add]: true,
	});

	const [activeTab, setActiveTab] = useState(TestSectionTabId.All);

	const [selectedTestedOption, setSelectedTestedOption] = useState(TestedOption.Any);
	const [selectedSortByOption, setSelectedSortByOption] = useState(SortByOption.Default);

	const [testDetailsState, setTestDetailsState] = useState<TestDetailsState | null>(null);

	const {testItems} = useFilteredTests({
		activeTab,
		selectedSortByOption,
		selectedTestedOption,
		tests: allTests,
	});

	const updateSectionModePermissions = useCallback(
		({sectionMode, newPermissions}: {sectionMode: TestSectionMode; newPermissions: boolean}) => {
			setSectionModePermissions((currentPermissions) => ({
				...currentPermissions,
				[sectionMode]: newPermissions,
			}));
		},
		[],
	);

	const getSectionModePermissionValue = useCallback(
		({sectionMode}: {sectionMode: TestSectionMode}) => sectionModePermissions[sectionMode],
		[sectionModePermissions],
	);

	useEffect(() => {
		const districtOrSchoolSubject = subject?.level === SubjectLevel.District || subject?.level === SubjectLevel.School;
		const canRearrange =
			Boolean(testItems.length) &&
			activeTab === TestSectionTabId.All &&
			selectedSortByOption === SortByOption.Default &&
			selectedTestedOption === TestedOption.Any &&
			!districtOrSchoolSubject;

		const canAdd = subject && !districtOrSchoolSubject;

		if (getSectionModePermissionValue({sectionMode: TestSectionMode.Rearrange}) !== canRearrange) {
			updateSectionModePermissions({sectionMode: TestSectionMode.Rearrange, newPermissions: canRearrange});
		}

		if (getSectionModePermissionValue({sectionMode: TestSectionMode.Add}) !== canAdd) {
			updateSectionModePermissions({sectionMode: TestSectionMode.Add, newPermissions: Boolean(canAdd)});
		}
	}, [
		activeTab,
		selectedSortByOption,
		selectedTestedOption,
		testItems,
		subject,
		getSectionModePermissionValue,
		updateSectionModePermissions,
	]);

	const modeButtonsPermissions = useMemo<ModeButtonsPermissions>(
		() => ({
			[TestSectionMode.Rearrange]: sectionModePermissions[TestSectionMode.Rearrange],
			[TestSectionMode.Add]: sectionModePermissions[TestSectionMode.Add],
		}),
		[sectionModePermissions],
	);

	const closeTestDetails = useCallback(() => {
		setTestDetailsState(null);
	}, []);

	return {
		selectedTestedOption,
		setSelectedTestedOption,
		selectedSortByOption,
		setSelectedSortByOption,
		activeTab,
		setActiveTab,
		testItems,
		modeButtonsPermissions,
		closeTestDetails,
		testDetailsState,
		setTestDetailsState,
	};
}
