import {setRef, tryCall} from '@esgillc/ui-kit/utils';
import {createRef} from 'react';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {SubjectType} from '@esgi/core/enums';
import {InvalidTrackAlert} from 'shared/alert/invalid-track-alert/invalid-track-alert';
import {openModal} from '@esgi/deprecated/react';
import {BaseService} from '@esgi/core/service';

export class StudentsReportValidatorRequest {
	subjectId: number;
	subjectType: SubjectType;
	globalSchoolYearId: number;
	userId: number;
	schoolId: number;
	districtId: number;
	byValidate: Array<string>;
}

export interface Response {
	validationResults: ValidationResult[];
	schoolYearName: string;
}

export interface ValidationResult {
	type: string;
	result: boolean;
}

export class StudentsReportValidator extends BaseService {
	private showAlert(type: string, schoolYearName: string) {
		let message = '';
		switch (type) {
			case 'HasTrack':
				const closeRef = createRef<Function>();
				const modal = openModal(<InvalidTrackAlert close={() => tryCall(closeRef.current)} schoolYearName={schoolYearName}/>);
				setRef(modal.close, closeRef);
				break;
			default:
				message =
					'Uh oh. Something went wrong on our end. Click Report to contact Customer Support (support@esgisoftware.com) for assistance.';
				OldAlerts.bsalert(message);
				break;
		}


	}

	run(request: StudentsReportValidatorRequest): JQueryPromise<any> {
		let promise = $.Deferred<any>();

		this.httpClient.ESGIApi.get<Response>('reports/common', 'validate', request)
			.subscribe(resp => {
				const results = resp.validationResults;
				for (let i = 0; i < results.length; i++) {
					if (!results[i].result) {
						this.showAlert(results[i].type, resp.schoolYearName);
						promise.reject();
						return;
					}
				}
				promise.resolve();
			}, () => {
				promise.reject();
			});

		return promise.promise();
	}
}
