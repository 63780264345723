import {ReactNode} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
  title: string;
  className?: string;
  children?: ReactNode;
}

export function SectionWrapper({title, className, children}: Props) {
	return (
    <div data-cy={'section'} className={join(className)}>
      <h5 data-cy={'section-header'} className={styles.sectionHeader}>{title}</h5>
      {children}
    </div>
	);
}
