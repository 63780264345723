import React from 'react';
import {CheckboxesState} from '../../types';
import {Checkbox, CheckboxesBox} from '../../../../kit';

type SidebarProps = {
	checkboxState: CheckboxesState;
	toggleCheckbox: (field: keyof CheckboxesState) => void;
	isTablet: boolean;
};

export function Sidebar({checkboxState, toggleCheckbox, isTablet}: SidebarProps) {
	return (
		<CheckboxesBox isTablet={isTablet}>
			<Checkbox
				data-cy='score-checkbox'
				checked={checkboxState.score}
				onCheckedChange={() => toggleCheckbox('score')}
				label='Score'
				selected={checkboxState.score}
			/>
			<Checkbox
				data-cy='percentage-checkbox'
				checked={checkboxState.percentage}
				onCheckedChange={() => toggleCheckbox('percentage')}
				label='Percentage'
				selected={checkboxState.percentage}
			/>
			<Checkbox
				data-cy='grade-checkbox'
				checked={checkboxState.grade}
				onCheckedChange={() => toggleCheckbox('grade')}
				label='Grade'
				selected={checkboxState.grade}
			/>
		</CheckboxesBox>
	);
}
