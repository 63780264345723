import React from 'react';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Checkbox} from '@esgillc/ui-kit/form-control';

import styles from '../confirm.module.less';

interface Props {
	control: FormControl<boolean>;
	initialValue: boolean;
}

export default class ShareField extends React.PureComponent<Props> {
	public componentDidMount() {
		this.props.control.value = this.props.initialValue;
	}

	public render() {
		return <div className={styles.inlineField}>
			<div className={styles.label}>Share Test:</div>
			<div className={styles.element}>
				<FormElement control={this.props.control}>
					<Checkbox>Share with District</Checkbox>
				</FormElement>
			</div>
		</div>;
	}
}
