import {BehaviorSubject, forkJoin, Observable, tap} from 'rxjs';
import {getUser} from '@esgi/core/authentication';
import {RefObject} from 'react';
import {BaseTabService} from '../../../base-tab-service';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {switchMap} from 'rxjs/operators';
import {
	PreSelected, ProfileInitData,
	Schools, SpecialistGroup, SpecialistGroupItem,
	StudentProfileMode,
	StudentProfileTab,
	TabsApi,
} from 'modules/forms/students-form/types';
import {
	createDistrictSpecialistForm,
} from 'modules/forms/students-form/components/forms/location/district-specialist';
import {StudentChangedEvent, StudentSaveAndAddEvent} from 'modules/forms/students-form/events';

interface InitArgs {
	initData: ProfileInitData,
	mode: StudentProfileMode,
	studentID: number,
	preSelected: PreSelected | null,
	tabsApi: RefObject<TabsApi>
}

export class DistrictSpecialistService extends BaseTabService {

	public serviceLoading = new BehaviorSubject<boolean>(false);
	public preSelected = new BehaviorSubject<PreSelected>(null);
	public schools = new BehaviorSubject<Schools[]>(null);
	public specialistGroups = new BehaviorSubject<SpecialistGroupItem[]>(null);
	public selectedSpecialistGroups = new BehaviorSubject<SpecialistGroupItem[]>([]);


	public form = createDistrictSpecialistForm();

	protected tab = StudentProfileTab.Location;
	private currentUser = getUser();

	public init({initData, mode, studentID, preSelected, tabsApi}: InitArgs) {
		this.studentID = studentID;
		this.preSelected.next(preSelected);
		this.initData.next(initData);

		if (mode !== StudentProfileMode.view) {
			this.initTabsApi(tabsApi);
		} else {
			this.form.status = ElementStatus.disabled;
		}

		return forkJoin([this.getSchools(), this.getSpecialistGroups()]).pipe(tap(([schoolsResponse]) => {
			const {location: {specialistGroupIDs}} = initData;
			const schoolID = this.initData.value?.location?.schoolID;
			const school = schoolsResponse.schools.find(s => s.schoolID === schoolID);
			this.form.value = {
				school: school ? [school] : [],
				specialistGroupIDs: specialistGroupIDs || [],
			};

			this.selectedSpecialistGroups.next(this.form.controls.specialistGroupIDs.value.map(id => this.specialistGroups.value?.find(cl => id === cl.id)));

			this.form.controls.specialistGroupIDs.onChanged.subscribe(v => {
				if (v.currState.value) {
					this.selectedSpecialistGroups.next(v.currState.value.map(id => this.specialistGroups.value?.find(cl => id === cl.id)));
				} else {
					this.selectedSpecialistGroups.next([]);
				}
			});

			this.tabsApi.current[StudentProfileTab.Location].isTouched = false;
		})).pipe(tap({
			complete: () => this.serviceLoading.next(false),
		}));
	}

	public getSchools() {
		return this.httpClient.ESGIApi.get<{ schools: Schools[] }>(this.controller,
			`location/schools?globalSchoolYearID=${this.currentUser.globalSchoolYearID}`).pipe(tap((res) => {
			this.schools.next((res.schools));
		}));
	}

	public getSpecialistGroups() {
		return this.httpClient.ESGIApi.get<{ specialistGroups: SpecialistGroup[] }>(this.controller,
			`location/specialist-groups?userID=${this.currentUser.userID}&globalSchoolYearID=${this.currentUser.globalSchoolYearID}`).pipe(tap((res) => {
			this.specialistGroups.next((res.specialistGroups.map(({groupID, name, userID}) => ({
				name,
				id: groupID,
				userID,
			}))));
		}));
	}

	public save = (isSaveAndAdd?: boolean) => {
		const model = {
			studentID: this.studentID,
			specialistGroupIDs: this.form.controls.specialistGroupIDs.value,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
		};

		return this.form.validate().pipe(switchMap(v => {
			if (v.valid) {
				return this.httpClient.ESGIApi.post(this.controller,
					'profile/location/save', model)
					.pipe(tap(() => {
						const {
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
						} = this.initData.value?.general;
						const event = new StudentChangedEvent(
							this.studentID,
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
							this.form.value.school[0]?.schoolID,
							this.initData.value?.location?.teacherID,
							null,
							null,
							this.form.controls.specialistGroupIDs.value.map(item => ({
								id: item,
								userID: this.specialistGroups.value.find(el => el.id === item)?.userID,
							})) ?? [],
						);
						this.eventBus.dispatch(StudentChangedEvent, event);
						this.eventBus.dispatch(StudentSaveAndAddEvent, new StudentSaveAndAddEvent(isSaveAndAdd));
					}));
			}
			return new Observable((subscriber) => {
				subscriber.next();
				subscriber.complete();
			});
		}));
	};

}


