export function ClearIcon() {
	return <svg
		data-cy={'clear-icon'}
		width='12'
		height='11'
		viewBox='0 0 12 11'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path d='M11.25 1.0575L10.1925 0L6 4.1925L1.8075 0L0.75
		1.0575L4.9425 5.25L0.75
		9.4425L1.8075 10.5L6
		6.3075L10.1925 10.5L11.25
		9.4425L7.0575 5.25L11.25 1.0575Z'
					fill='#828282'
		>
		</path>
	</svg>;
}