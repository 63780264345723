import {useState, useEffect} from 'react';
import {Dropdown, Option, Checkbox} from '@esgillc/ui-kit/control';
import {DatePicker} from '@esgi/deprecated/elements';
import {SettingsResponse, FilterSession} from '../../../../types';
import moment from 'moment';
import styles from '../../styles.module.less';

type Dates = {
	from: Date | string;
	to: Date | string;
};

type DatesWIthId = Dates & {id: number};

interface Props {
	value?: Dates;
	settings: SettingsResponse;
	onChange: (value: Dates & {session: number, period: number}) => void;
}

const sessions = [
	{label: 'Marking Period', value: FilterSession.Period},
	{label: 'Date Range', value: FilterSession.Range},
	{label: 'Date', value: FilterSession.Date},
];

const getDefaultPeriod = (periods: DatesWIthId[]) => {
	const now = Date.now();
	const fromTime = new Date(periods[0].from).getTime();

	if (now < fromTime) {
		return periods[0];
	}

	const periodIndex = periods.findIndex(({from}) => {
		const fromTime = new Date(from).getTime();
		return now < fromTime;
	});

	return periodIndex >= 1 ? periods.at(periodIndex - 1) : periods.at(periodIndex);
};

export const Session = (props: Props): JSX.Element => {
	const today = moment().set({hour: 23, minute: 59, second: 59});
	const periods = props.settings.markingPeriods.map((period, index) => {
		period.id = index + 1;
		return period;
	});
	const defaultPeriod = getDefaultPeriod(periods);
	const [session, setSession] = useState(FilterSession.Period);
	const [period, setPeriod] = useState(defaultPeriod);
	const [from, setFrom] = useState(new Date(defaultPeriod.from));
	const [to, setTo] = useState(new Date(defaultPeriod.to));

	const formatPeriod = ({from, to}) => {
		return moment(from).format('L') + ' - ' + moment(to).format('L');
	};

	useEffect(() => {
		props.onChange({from, to, session, period: period.id});
	}, [from, to]);

	useEffect(() => {
		if (session === FilterSession.Date) {
			props.onChange({
				from: null,
				to: today.toDate(),
				session,
				period: period.id,
			});
			return;
		}
		if (session !== FilterSession.Period) {
			return;
		}
		const {from, to} = period;
		props.onChange({
			from: new Date(from),
			to: new Date(to),
			session,
			period: period.id,
		});
	}, [session, period]);

	return (
		<div>
			<div className={styles.field}>
				<label className={styles.title}>Choose Session</label>
				{sessions.map(({label, value}) => (
						<Checkbox
							key={value}
							checked={session === value}
							onChange={() => setSession(value)}
						>
							{label}
						</Checkbox>
				))}
			</div>
			<div className={styles.date}>
				{session === FilterSession.Period &&
					<Dropdown
						displayLabel={(value: DatesWIthId[]) => formatPeriod(value[0])}
						autoWidth={true}
						value={[period]}
						setValue={(period) => setPeriod(period[0])}
					>
						{periods.map((period) => (
							<Option
								key={period.id}
								value={period}
							>
								<strong className={styles.index}>{period.id}</strong>
								{formatPeriod(period)}
							</Option>
						))}
					</Dropdown>
				}
				{session === FilterSession.Range &&
					<>
						<DatePicker
							id='dateFrom'
							placeholder='Start Date'
							onSelect={(v) => setFrom(v.toDate())}
							onInput={(v) => {
								if (v.isValid()) {
									setFrom(v.toDate());
								}
							}}
						/>
						<DatePicker
							id='dateTo'
							placeholder='End Date'
							onSelect={(v) => setTo(v.set({hour: 23, minute: 59, second: 59}).toDate())}
							onInput={(v) => {
								if (v.isValid()) {
									setTo(v.set({hour: 23, minute: 59, second: 59}).toDate());
								}
							}}
						/>
					</>
				}
				{session === FilterSession.Date &&
					<DatePicker
						id='dateFrom'
						placeholder='Today'
						onSelect={(v) => {
							setFrom(null);
							setTo(v.set({hour: 23, minute: 59, second: 59}).toDate());
						}}
					/>
				}
			</div>
		</div>
	);
};
