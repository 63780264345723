import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from './styles.module.less';

interface Props {
	onClicked: () => void;
	classID: number;
	maxStudents: number;
}

const supportUrl = 'https://support.esgisoftware.com/hc/en-us/requests/new';

export function PurchasingStudents({onClicked, classID, maxStudents}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	const sourceScreen = window.location.href.indexOf('student-manager') !== -1 ? 'student-manager' : '';
	const renewUrl = `/renewal?source_screen=${sourceScreen}&back_url=uploadtool?classid=${classID}`;

	const openRenewPage = () => window.open(renewUrl);
	const openSupportPage = () => window.open(supportUrl);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Header>
			<Title className={styles.title}>Your File Exceeds the Maximum Number of Students</Title>
		</Modal.Header>
		<Modal.Body className={styles.body}>
				<span data-cy={'purchasing-students'}
							className={styles.text}>You have reached a maximum of {maxStudents} students in your classes.
					Please remove students or increase the maximum number by <Buttons.Link onClick={openRenewPage}>purchasing additional student slots</Buttons.Link>.
					<p data-cy={'support'} className={styles.paragraph}>
						Please <Buttons.Link onClick={openSupportPage}>contact us</Buttons.Link> with any questions.
					</p>
				</span>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}

