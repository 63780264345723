import {InputDisabled, TranscriptText} from './index.styled';

type Props = {
	value: string;
	placeholder: string;
	inputTranscript?: string;
	dataCy?: string;
};

export function DisabledInput({value, placeholder, inputTranscript, dataCy}: Props) {
	return (
		<>
			<InputDisabled dataCy={dataCy ?? 'disabled-input'} disabled placeholder={placeholder} value={value} />
			{inputTranscript && (
				<TranscriptText data-cy='transcript-text' size='small' color='neutral24'>
					{inputTranscript}
				</TranscriptText>
			)}
		</>
	);
}
