import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {useLocation} from 'react-router-dom';
import {Text} from '@esgi/ui/typography';
import {Search} from '@esgi/ui';

const Container = styled(FlexBox, {
	width: '100%',
	height: '100%',
});

const SearchIcon = styled(Search, {
	width: '4em',
	height: '4em',
});

export default function () {
	const {pathname} = useLocation();
	return <Container align='center' justify='center' direction='column'>
		<Box>
			<SearchIcon/>
		</Box>
		<Text size='xxLarge'>Not found</Text>
		<Text size='small'>Page {pathname} not exist.</Text>
	</Container>;
}