import React from 'react';
import styles from './action-button.module.less';
import {join, isTouchDevice} from '@esgillc/ui-kit/utils';


interface BackButtonProps {
	disabled?: boolean;
	clicked: () => any;
	className?: string;
}
 
export class BackButton extends React.Component<BackButtonProps> {
	render() { 
		return (
			<button onTouchStart={this.props.clicked} onClick={isTouchDevice() ? undefined : this.props.clicked} className={join(styles.actionBtn, styles.backBtn, this.props.className)} disabled={this.props.disabled} type='button'>
                <svg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<circle r='36' transform='matrix(-1 0 0 1 37.5 37.5)' stroke='#828282' strokeWidth='3'/>
					<path fillRule='evenodd' clipRule='evenodd' d='M15.8225 35.7399C15.0147 36.5253 15.0147 37.8225 15.8225 38.6079L34.4316 56.7001C35.6995 57.9327 37.8258 57.0344 37.8258 55.2661V45.6522H59.3043C60.4088 45.6522 61.3043 44.7567 61.3043 43.6522V30.6956C61.3043 29.5911 60.4088 28.6956 59.3043 28.6956H37.8258V19.0817C37.8258 17.3134 35.6995 16.4151 34.4316 17.6477L15.8225 35.7399Z' fill='#848484'/>
				</svg>
			</button>            
		);
	}
}
