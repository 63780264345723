import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {OneLinedText} from '@esgi/main/kits/common';

export const CaptionText = styled(OneLinedText, {
	minWidth: '50px',
});

export const Counter = styled(GridBox, {
	marginLeft: 'auto',
	gridAutoFlow: 'column',
	gap: '$3',
});

export const CounterText = styled(Text, {
	minWidth: '16px',
	textAlign: 'center',
	color: '$muted',

	variants: {
		type: {
			correct: {
				color: '$positive',
			},
			incorrect: {
				color: '$negative',
			},
			untested: {
				color: '$neutral56',
			},
		},
		zero: {
			true: {},
		},
	},
	compoundVariants: ['correct', 'incorrect', 'untested'].map((type) => ({
		type,
		zero: true,
		css: {
			color: '$muted',
		},
	})),
});
