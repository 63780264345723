import {AssignmentDetailsCardBody} from './components/assignment-details-card-body';
import {AssignmentDetailsCardHeader} from './components/assignment-details-card-header';
import {AssignmentDetailsCardRoot} from './components/assignment-details-card-root';
import {AssignmentDetailsCardDivider} from './components/assignment-details-card-divider';

export const AssignmentDetailsCard = {
	Root: AssignmentDetailsCardRoot,
	Header: AssignmentDetailsCardHeader,
	Body: AssignmentDetailsCardBody,
	Divider: AssignmentDetailsCardDivider,
};

export {AssignmentDetailsCardSkeleton} from './components/assignment-details-card-skeleton';
