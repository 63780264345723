import {DragDropContext, DropResult, Droppable, DroppableId} from 'react-beautiful-dnd';
import {useCallback, useId} from 'react';
import {SelectableListGroup, SelectableListGroupProps} from '../selectable-list-group';

export type SelectableListGroupDroppableProps<T> = Omit<SelectableListGroupProps, 'onDragEnd'> & {
	/** A required Id for Droppable Group. */
	droppableId?: DroppableId;

	/** Iterated items. Used for rearrange items. */
	droppableItems: T[];

	/** Function for update "droppableItems" after rearrange. */
	onDragEnd: (groupState: T[]) => void;
};

export function SelectableListGroupDroppable<T>({
	dataCy = 'ui-kit-selectable-list-group-droppable',
	children,
	droppableId,
	droppableItems,
	onDragEnd,
	...props
}: SelectableListGroupDroppableProps<T>) {
	const droppableOwnId = useId();

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return;
			}

			const items = [...droppableItems];

			const [removed] = items.splice(result.source.index, 1);

			if (removed) {
				items.splice(result.destination.index, 0, removed);
			}

			onDragEnd(items);
		},
		[droppableItems, onDragEnd],
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={droppableId ?? droppableOwnId}>
				{(provided) => (
					<SelectableListGroup dataCy={dataCy} ref={provided.innerRef} {...provided.droppableProps} {...props}>
						{children}
						{provided.placeholder}
					</SelectableListGroup>
				)}
			</Droppable>
		</DragDropContext>
	);
}
