import {ValidationStatus} from '@esgi/core/enums';

export class ValidationResult {

	constructor() {
		this.valid = ValidationStatus.Unknown;
		this.message = '';
	}

	valid: ValidationStatus;
	message: string;
	attempt: number;
}

export class Validator {
	constructor(public validator: () => Promise<string> | string) {
	}

	private attempt: number = 0;

	async validate(): Promise<ValidationResult> {
    	const errorMessage = await this.validator();
    	return {
    		attempt: ++this.attempt,
    		message: errorMessage,
    		valid: errorMessage ? ValidationStatus.Invalid : ValidationStatus.Valid,
    	};
	}

	static validate(validator: () => Promise<string> | string): Validator {
    	return new Validator(validator);
	}

}