import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const NavigationPanelLauncher = styled(Box, {
	zIndex: 30,
	position: 'relative',

	'& > div': {
		height: '100%',
	},
});
