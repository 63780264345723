import {useSubjectRootContext} from '../subject-root/context';
import {SelectContent, SelectField, SelectOption} from './index.styled';
import {Select} from '@esgi/ui/controls';
import {SubjectName} from '../../../../subject-name';
import {Text} from '@esgi/ui/typography';

export function SubjectControl() {
	const {isLoaded, subjects, selectedSubjectID, setSelectedSubject} = useSubjectRootContext();

	return (
		<Select.Root dataCy='subject-control-root' value={selectedSubjectID} onValueChange={setSelectedSubject}>
			<SelectField data-cy='subject-control-select-field' placeholder='Subject Tab' skeleton={!isLoaded} />
			<SelectContent dataCy='subject-control-item'>
				{subjects.map(({id, name, level}) => (
					<SelectOption value={String(id)} key={id}>
						<SubjectName subjectLevel={level}>{name}</SubjectName>
						<Text size='medium' bold data-placeholder-value=''>
							{name}
						</Text>
					</SelectOption>
				))}
			</SelectContent>
		</Select.Root>
	);
}
