import React from 'react';
import {GridBox} from '@esgi/ui/layout';
import {ProgressBar} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {AnswersInfo} from '../../../../../kit';

interface Props {
	studentsCount: number;
	studentsTestedCount: number;
	isProgressSuccess: boolean;
}

export function ClassDiagrams({studentsTestedCount, studentsCount, isProgressSuccess}: Props) {
	return (
		<GridBox gap={4} display='inlineGrid' dataCy='students-tested'>
			<GridBox gap='3' flow='column' align='center'>
				<ProgressBar value={studentsTestedCount} maxValue={studentsCount}>
					<ProgressBar.RingDashed
						color={isProgressSuccess ? 'green' : 'lowContrast'}
						size={60}
						css={{
							'& circle': {
								fill: '$background',
							},
						}}
					>
						<Text size='medium' bold font='mono' color={isProgressSuccess ? 'green' : 'base'}>
							{`${studentsTestedCount} / ${studentsCount}`}
						</Text>
					</ProgressBar.RingDashed>
				</ProgressBar>
				<AnswersInfo value={studentsTestedCount} maxValue={studentsCount} transcriptText='Students Tested' />
			</GridBox>
			<Text size='small' font='mono' color='neutral56' data-cy='students-tested-label'>
				Students Tested
			</Text>
		</GridBox>
	);
}
