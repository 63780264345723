import {
	Container, ExportApi,
	ExportDateModel,
	ExportTypeModel,
	Helper, RequestExportModel,
	TemplateModel, TestItem,
	TestModel,
} from 'shared/modules/export/export';
import {ExportHistory} from 'shared/modules/export/history-module/export-history';
import {ManageTemplatesModule} from 'shared/modules/export/manage-templates/manage-templates';
import {ModalWindow, Module, Renderable, OldAlerts} from '@esgi/deprecated/knockout';
import React from 'react';
import './manual-export.less';

class ObsTestModel extends TestModel {
	selected: KnockoutObservable<boolean>;

	constructor(test: TestModel) {
		super(test);
		this.selected = ko.observable(false);
	}
}

export class ManualModule extends Module {
	private context;
	private initApi: () => JQueryPromise<Container>;
	private helpers: Helper;
	private history: ExportHistory;
	private server: ExportApi;
	loader: any;

	private rawContainer: Container;

	constructor(context, history: ExportHistory, initApi: () => JQueryPromise<Container>) {
		super();
		this.initApi = initApi;
		this.context = context;
		this.helpers = new Helper();
		this.history = history;
		this.server = new ExportApi();
	}

	teacherIdType = ko.observable<string>('importIDN');
	studentIdType = ko.observable<string>('importIDN');

	dates = {
		all: ko.observableArray<ExportDateModel>(),
		selectedExportDate: ko.observable(null),
		customDate: ko.observable(null),
	};

	types = {
		all: ko.observableArray<ExportTypeModel>(),
		selectedExportType: ko.observable<string>(),
	};


	tests = {
		searchBox: ko.observable(''),
		all: ko.observableArray<ObsTestModel>([]),
		visible: ko.pureComputed(() => {
			let i;
			let searchValue = this.tests.searchBox().toLowerCase();
			let arr = new Array<ObsTestModel>();
			if (searchValue === '') {
				arr = this.tests.all();
			} else {
				for (i = 0; i < this.tests.all().length; i++) {
					let test = this.tests.all()[i];
					if (test.name.toLowerCase().indexOf(searchValue) >= 0
						|| test.creatorName.toLowerCase().indexOf(searchValue) >= 0
						|| test.subjectName.toLowerCase().indexOf(searchValue) >= 0
						|| test.contentArea.toLowerCase().indexOf(searchValue) >= 0) {
						arr.push(test);
					}
				}
			}

			this.tests.allVisible(true);
			for (i = 0; i < arr.length; i++) {
				if (!arr[i].selected()) {
					this.tests.allVisible(false);
					break;

				}
			}

			this.ui.reflowTable();
			return arr;
		}),
		selected: ko.pureComputed(() => {
			let arr = new Array<ObsTestModel>();
			for (let i = 0; i < this.tests.all().length; i++) {
				let test = this.tests.all()[i];
				if (test.selected()) {
					arr.push(test);
				}
			}

			this.tests.allSelected(arr.length !== 0);

			return arr;
		}),
		allVisible: ko.observable<boolean>(),
		allSelected: ko.observable<boolean>(),

	};


	initModel(rawModel: Container) {
		this.tests.all.removeAll();
		for (let i = 0; i < rawModel.tests().length; i++) {
			this.tests.all.push(new ObsTestModel(rawModel.tests()[i]));
		}

		this.tests.allVisible(false);
		this.tests.allSelected(false);


		this.dates.all = rawModel.exportDates;

		this.dates.selectedExportDate(rawModel.exportDates()[0]);
		this.dates.customDate(this.helpers.convertStringToDate(this.dates.selectedExportDate()));


		this.types.all.removeAll();
		this.types.all = rawModel.exportTypes;
		this.types.selectedExportType(rawModel.exportTypes()[0].value);


	}

	load(): JQueryPromise<any> {
		return this.initApi().done((model) => {
			this.rawContainer = model;
			this.initModel(model);
		});

	}

	ui = {
		//Deprecated
		reflowTable() {
		},
	};

	afterRender(rootElement) {
		this.rootElement = rootElement;
		setTimeout(() => {
			let tooltips = $('.icon-with-helper', rootElement);
			tooltips.each((index, element) => {
				let text = $(element).find('.tooltip-message').html();
				$(element).qtip({
					content: {
						text: text,
					},
					hide: {
						fixed: true,
						delay: 300,
					},
					position: {
						at: 'top center',
						my: 'bottom center',
					},
				});
			});

		}, 1);

		this.dates.selectedExportDate.subscribe((newVal) => {
			if (newVal != null) {
				this.dates.customDate(this.helpers.convertStringToDate(newVal));
			}
		});

		return Renderable.resolvedDeferred;
	}

	logic = {
		applyTemplate: (template: TemplateModel) => {
			if (template != null) {
				let i;
				let type = this.helpers.getById<ExportTypeModel>(this.types.all(), 'value', template.exportType);
				if (type != null) {
					this.types.selectedExportType(type.value);
				}

				this.studentIdType(template.studentIDN);
				this.teacherIdType(template.teacherIDN);

				let selectedTests = this.tests.selected();
				for (i = 0; i < selectedTests.length; i++) {
					selectedTests[i].selected(false);
				}

				for (i = 0; i < template.tests.length; i++) {
					let test = this.helpers.getSelectedTest(this.tests.all(), template.tests[i]);
					if (test) {
						test.selected(true);
					}
				}
			}
		},
	};

	view = {
		clearSearchBox: () => {
			this.tests.searchBox('');
		},
		visibleTestClicked: (test: ObsTestModel) => {
			test.selected(!test.selected());
		},
		selectedTestsClicked: () => {
			let val = this.tests.allSelected();
			let selectedTests = this.tests.selected();
			for (let i = 0; i < selectedTests.length; i++) {
				selectedTests[i].selected(val);
			}

			return true;
		},
		allTestsClicked: () => {
			let val = this.tests.allVisible();
			let visibleTests = this.tests.visible();
			for (let i = 0; i < visibleTests.length; i++) {
				visibleTests[i].selected(val);
			}
			return true;
		},

		manageTemplatesClicked: () => {
			let manageModule = new ManageTemplatesModule(this.context, () => {
				return $.Deferred<Container>().resolve(this.rawContainer).promise();
			}, {allowSelectTemplate: true, showScheduleOptions: true});
			const modal = new ModalWindow(manageModule,
				{
					allowClose: true,
					showHeader: true,
					showFooter: true,
					title: 'Manage Templates',
					className: 'manage-templates-modal',
					buttons: [
						{
							title: 'Apply',
							className: 'btn btn-primary btn-sm',
							submit: true,
							align: 'right',
							closeModal: false,
							disabled: manageModule.templates.isNotSelected,
							onClick: () => {
								let template = manageModule.templates.selected();
								this.logic.applyTemplate(template);
								modal.close(true);
							},
						},
						{
							title: 'Cancel',
							className: 'btn btn-sm',
							align: 'right',
							closeModal: true,
							onClick: () => {
								modal.close(true);
							},
							cancel: true,
						},
					],
				});
			modal.load();
		},
		runExport: () => {
			let tests = this.tests.selected();
			if (tests.length <= 0) {
				OldAlerts.bsalert('Please select tests.');
				return false;
			}
			let request = new RequestExportModel();
			request.exportType = this.types.selectedExportType();
			request.globalSchoolYearID = this.context.globalSchoolYearID;
			request.testItems = tests.map((item: TestModel) => new TestItem(item.testID, item.subjectId, item.subjectType));
			request.dateToExport = this.helpers.convertDateToString(this.dates.customDate());
			request.teacherIDN = this.teacherIdType();
			request.studentIDN = this.studentIdType();

			this.server.run(request).then((model) => {
				this.history.addRow(model);
				OldAlerts.bsalert('The export request was received. You can check the status of the request in the table "Report History"');
			});

		},
	};

	template = () => <div className='manual-export' data-bind='afterRender:true'>
		<div className='available-tests'>
			<div style={{fontWeight: 'bold'}}>Available Tests:</div>
			<div>
        <span className='input-icon input-icon-right'>
            <input type='text' placeholder='Content Area, Test Name, Subject, Creator...' className='form-control'
                   data-bind='textInput: tests.searchBox'/>
            <i className='ace-icon fa fa-search' aria-hidden='true'
               data-bind='visible: tests.searchBox().length==0'/>
            <i className='ace-icon fa fa-times' style={{cursor: 'pointer'}} aria-hidden='true'
               data-bind='visible: tests.searchBox().length!=0,click: view.clearSearchBox'/>
        </span>

			</div>
		</div>
		<div className='tests-container'>
			<table className='table table-striped table-hover table-bordered'>
				<thead>
				<tr>
					<th>Content Area</th>
					<th>Display Subject</th>
					<th>Test Name</th>
					<th># of Questions</th>
					<th>Test Creator</th>
					<th>Creation Date</th>
					<th style={{textAlign: 'center'}}>
						<label>
							<input type='checkbox' className='ace'
							       data-bind='checked: tests.allVisible,click: view.allTestsClicked'/>
							<span className='lbl'/>
						</label>
					</th>
				</tr>
				</thead>
				<tbody data-bind='foreach: tests.visible'>
				<tr data-bind='click:$parent.view.visibleTestClicked'>
					<td data-bind='text:contentArea'/>
					<td data-bind='text:subjectName'/>
					<td data-bind='text:name'/>
					<td data-bind='text:questionsCount'/>
					<td data-bind='text:creatorName'/>
					<td data-bind="text:$parent.helpers.formatDate(createDate,'M/DD/YY')"/>
					<td style={{textAlign: 'center'}}>
						<label>
							<input type='checkbox' className='ace'data-bind='checked: selected, click: function() { }, clickBubble: false'/>
							<span className='lbl'/>
						</label>
					</td>
				</tr>
				</tbody>
			</table>
			<ko data-bind='if: tests.visible().length == 0'>
				<span>Tests Not Found</span>
			</ko>
		</div>

		<div className='choose-template'>
			<button type='button' className='btn btn-primary btn-sm'
			        data-bind='click:view.manageTemplatesClicked'>Manage Templates
			</button>
		</div>

		<div className='separator'/>

		<div className='settings-export'>
			<div style={{fontWeight: 'bold', marginBottom: '20px'}}>Settings for Export:</div>
			<div className='selected-tests-container'>
				<table className='table table-striped table-hover table-bordered'>
					<thead>
					<tr>
						<th>Content Area</th>
						<th>Display Subject</th>
						<th>Test Name</th>
						<th># of Questions</th>
						<th>Test Creator</th>
						<th>Creation Date</th>
						<th style={{textAlign: 'center'}}>
							<label>
								<input type='checkbox' className='ace'
								       data-bind='checked: tests.allSelected,click: view.selectedTestsClicked'/>
								<span className='lbl'/>
							</label>
						</th>
					</tr>
					</thead>
					<tbody data-bind='foreach: tests.selected'>
					<tr data-bind='click:$parent.view.visibleTestClicked'>
						<td data-bind='text:contentArea'/>
						<td data-bind='text:subjectName'/>
						<td data-bind='text:name'/>
						<td data-bind='text:questionsCount'/>
						<td data-bind='text:creatorName'/>
						<td data-bind="text:$parent.helpers.formatDate(createDate,'MM/DD/YY')"/>
						<td style={{textAlign: 'center'}}>
							<label>
								<input type='checkbox' className='ace' data-bind='checked: selected'/>
								<span className='lbl'/>
							</label>
						</td>
					</tr>
					</tbody>
				</table>
				<ko data-bind='if: tests.selected().length == 0'>
					<div style={{textAlign: 'center'}}><b>Please select tests or apply saved template</b></div>
				</ko>
			</div>


			<div className='row-setting'>
				<div>
					<span>Export Type:</span>
					<select className='form-control'
					        data-bind="options: types.all,optionsText: 'displayName', optionsValue:'value', value: types.selectedExportType, chosen: { disable_search_threshold: 10, width:'150px', search_contains: true}"/>
				</div>

				<div>
					<span style={{whiteSpace: 'nowrap'}}>Export As Of Date:</span>
					<select className='form-control'
					        data-bind="options: dates.all,optionsText: 'displayName', optionsValue:'value', value: dates.selectedExportDate,chosen: { disable_search_threshold: 10, width:'150px', search_contains: true}"/>
					<input type='text' style={{width: '100px'}} className='form-control'
					       data-bind='datepicker: dates.customDate,disable:dates.selectedExportDate() != null'/>
				</div>
			</div>

			<div className='idn-settings'>
				<div className='helper'>
					<i data-bind="bsTooltip:{placement:'bottom'}" data-html='true'
					   title="<b>Use Import ID</b> if your district does not import via Clever.<br /><b>Use Export ID</b> if your district imports via Clever. The Export ID will display the SIS student ID if entered into the student's profile."
					   className='icon-with-helper fa fa-question-circle'/>
				</div>
				<div className='options'>
					<div className='option'>
						<span className='title'>Teacher ID Type:</span>
						<span className='val'><input type='radio' name='teacherIdType' value='importIDN'
						                             data-bind='checked: teacherIdType'/> Import ID</span>
						<span className='val'><input type='radio' name='teacherIdType' value='exportIDN'
						                             data-bind='checked: teacherIdType'/> Export ID</span>
					</div>

					<div className='option'>
						<span className='title'>Student ID Type:</span>
						<span className='val'><input type='radio' name='studentIdType' value='importIDN'
						                             data-bind='checked: studentIdType'/> Import ID</span>
						<span className='val'><input type='radio' name='studentIdType' value='exportIDN'
						                             data-bind='checked: studentIdType'/> Export ID</span>
					</div>
				</div>

			</div>

			<div className='row-setting'>
				<input type='button' value='Export' className='btn btn-sm btn-primary'
				       data-bind='click:view.runExport'/>
			</div>
		</div>

		<div className='separator'/>


		<div style={{fontWeight: 'bold', margin: '10px 0 10px 0;'}}>
			Report History
		</div>
		<div id='export-history'>
			<ko data-bind='render: history'>
			</ko>
		</div>
	</div>
}
