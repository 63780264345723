import {BehaviorSubject, Observable} from 'rxjs';
import {SubjectType} from '@esgi/core/enums';
import {SubjectModel} from './models';

export class SubjectsStore {
	private readonly _deployedSubjects$: BehaviorSubject<SubjectModel[]> = new BehaviorSubject<SubjectModel[]>([]);
	private readonly _personalSubjects$: BehaviorSubject<SubjectModel[]> = new BehaviorSubject<SubjectModel[]>([]);
	private readonly _stockSubjects$: BehaviorSubject<SubjectModel[]> = new BehaviorSubject<SubjectModel[]>([]);

	public setSource(source: SubjectModel[]): void {
		this.setSubjectsByType(source.filter(s => s.type === SubjectType.Deployed), SubjectType.Deployed);
		this.setSubjectsByType(source.filter(s => s.type === SubjectType.Personal), SubjectType.Personal);
		this.setSubjectsByType(source.filter(s => s.type === SubjectType.Stock), SubjectType.Stock);
	}

	public setSubjectsByType(value: SubjectModel[], type: SubjectType): void {
		const source = this.getSource$(type);
		source.next(value);
	}

	public getSubjects(type?: SubjectType): SubjectModel[] {
		let source = this.getSource$(type);
		if (!source) {
			return this._deployedSubjects$.value.concat(
				this._personalSubjects$.value,
				this._stockSubjects$.value,
			);
		} else {
			return source.value;
		}
	}

	public getSubject(id: number, type: SubjectType): SubjectModel | null {
		let source = this.getSubjects(type);
		const subject = source.find(s => s.id === id && s.type === type);

		if (subject) {
			return {...subject};
		}

		return null;
	}

	public insertSubject(value: SubjectModel): void {
		const source = this.getSource$(value.type);
		const newValue = source.value;
		newValue.push(value);
		source.next(newValue);
	}

	public updateSubject(value: SubjectModel): void {
		const source = this.getSource$(value.type);
		const newValue = source.value.map(s => {
			if (s.id === value.id) {
				return {...value} as SubjectModel;
			} else {
				return s;
			}
		});
		source.next(newValue);
	}

	public removeSubject(id: number, type: SubjectType): void {
		const source = this.getSource$(type);
		const newValue = source.value.filter(s => s.id !== id);
		source.next(newValue);
	}

	public getSource(subjectType: SubjectType): Observable<SubjectModel[]> {
		return this.getSource$(subjectType);
	}

	private getSource$(subjectType: SubjectType): BehaviorSubject<SubjectModel[]> {
		switch (subjectType) {
			case SubjectType.Personal:
				return this._personalSubjects$;
			case SubjectType.Stock:
				return this._stockSubjects$;
			case SubjectType.Deployed:
				return this._deployedSubjects$;
			default:
				return null;
		}
	}
}
