import React from 'react';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {
	AlertFooter,
	ToggleGroupItem,
	DownloadButton,
	AlertMainContent,
	DescriptionText,
	AlertBody,
	ToggleGroupContent,
} from './index.styled';
import {ParentLetterPreviewProps} from './types';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

export const ParentLetterPreview = ({
	cancel,
	studentFirstName,
	teacherLastName,
}: ParentLetterPreviewProps) => {
	const modal = useModal();
	const onCancel = useCloseModal(modal, cancel);

	return (
		<Alert modalManagerRef={modal}>
			<Alert.Header onCloseIconClick={onCancel} withBacklight={false}>
				<Text size='small' color='base'>Parent Letter Preview</Text>
			</Alert.Header>
			<OverlayScrollbarsComponent>
				<AlertBody>
					<AlertMainContent>
						<Text size='medium' color='neutral24' bold>
							Dear Parent(s) / Guardian(s) of Student {studentFirstName},
						</Text>
						<DescriptionText size='medium' color='neutral24' bold>
							This letter contains some information I would like to share with
							you. Below are the results from the last time I tested Student{' '}
							{studentFirstName}. The left side shows all of the correct answers!
							Please use the right side as a check-list of things to work on with
							Student {studentFirstName}. This one-on-one time you spend with your
							child is essential and greatly appreciated
						</DescriptionText>
						<DescriptionText size='medium' color='neutral24' bold>
							Thanks for your support,
						</DescriptionText>
						<DescriptionText size='medium' color='neutral24' bold>
							Teacher {teacherLastName}.
						</DescriptionText>
					</AlertMainContent>
				</AlertBody>
			</OverlayScrollbarsComponent>
			<AlertFooter>
				<ToggleGroup.Root defaultValue='pdf' defaultChecked>
					<ToggleGroupContent>
						<ToggleGroupItem value='pdf'>
							<Text size='medium' color='base'>PDF</Text>
						</ToggleGroupItem>
						<ToggleGroupItem value='docx'>
							<Text size='medium' color='base'>DOCX</Text>
						</ToggleGroupItem>
					</ToggleGroupContent>
				</ToggleGroup.Root>
				<DownloadButton color='secondary'>
					<Text size='medium' color='base'>Download</Text>
				</DownloadButton>
			</AlertFooter>
		</Alert>
	);
};
