import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Cheap as CheapUI} from '@esgi/ui';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';
import {animated} from 'react-spring';

export const stickyHeight = 68;

export const Container = styled(animated.div, {
	display: 'grid',
	minHeight: stickyHeight,
	position: 'fixed',
	background: 'linear-gradient(180deg, #EEF7FC 0%, #FCFCFC 100%)',
	boxShadow: '0px 0px 40px 0px #3D2F2914, 0px 0px 8px 0px #3D2F291F',
	left: 0,
	right: 0,
	bottom: 0,

	'&::after': {
		position: 'absolute',
		content: '',
		width: '100%',
		height: '100%',
		background: 'repeating-linear-gradient(45deg, $vivid, $vivid 3px, transparent 0, transparent 10px)',
		gridColumnStart: 1,
		gridColumnEnd: -1,
		gridRowStart: 1,
		gridRowEnd: 1,
	},
});

export const Content = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '16px 20px 16px 30px',
	zIndex: 1,
});

export const Cheap = styled(CheapUI, {
	variants: {
		counter: {
			true: {
				color: '$primaryVivid',
				backgroundColor: '$primarySurface',
				borderColor: '$primaryMuted',
				marginLeft: 6,
			},
		},
	},
});

export const Checkbox = styled(CheckboxUI, {
	'& > div': {
		backgroundColor: '$background',
		borderColor: '$primaryHighlight',
	},
	'& > span': {
		color: '$highContrast',
	},
});
