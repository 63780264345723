import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {SubjectLevel, subjectsStore, SubjectTab, SubjectType} from '@esgi/main/libs/store';

export class SubjectService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public subjects$ = new BehaviorSubject<SubjectTab[]>([]);
	private subjectStorage = subjectsStore();

	constructor() {
		super();

		this.subjectStorage.get().subscribe(({data: subjects, loaded}) => {
			const allOption: SubjectTab = {
				gradeLevels: [],
				hidden: false,
				id: -1,
				level: SubjectLevel.Teacher,
				name: 'All',
				tests: [],
				type: SubjectType.Personal,
			};
			this.subjects$.next([allOption, ...subjects.filter(({hidden, tests}) => !hidden && tests.length > 0)]);

			if (!this.loaded$.value) {
				this.loaded$.next(loaded);
			}
		});
	}

	public override dispose() {
		this.subjectStorage.dispose();
	}

	public get(subjectId: SubjectTab['id']) {
		return this.subjects$.value.find(({id}) => id === subjectId);
	}

	public getAll() {
		return this.subjects$.value;
	}

	public getByIndex(index: number) {
		return this.subjects$.value[index];
	}
}
