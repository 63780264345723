import React from 'react';
import styles from '../../styles.module.less';
import {TestHistoryService} from '../../../../../../services/test-history-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Dropdown, Option} from '@esgillc/ui-kit/control';

interface Props {
	service: TestHistoryService
}

export function Classes({service}: Props) {
	const classes = useBehaviorSubject(service.classes);
	const selectedClass = useBehaviorSubject(service.selectedClass);
	return classes.length > 0 && <>
		<div>
			<span className={styles.settingsTitle}>Class: </span>
			{classes.length > 1 && <>
				<Dropdown value={selectedClass}
				          setValue={(v) => service.selectedClass.next(v)} optionName='name'
				          className={styles.fluid} autoWidth={true}>
					{classes.map(v => <Option key={v.classID} value={v}>{v.name}</Option>)}
				</Dropdown>
			</>}
			{classes.length == 1 && <>
				<span className={styles.name}>{selectedClass[0]?.name}</span>
			</>}
		</div>
	</>;
}
