import {Input} from '@esgi/ui/controls';
import {ConflictType, Validation, ValidationType} from '../../../../types';

interface Props {
	onEdit: (value: string, validation: Validation) => void;
	errorMessage?: string;
	existingStudentIDs: string[];
	uploadedStudentID: string;
}

export function Id({onEdit, errorMessage, existingStudentIDs, uploadedStudentID}: Props) {

	const onHandleEdit = (value: string) => {
		if (existingStudentIDs.some(x => x.trim() === value.trim())) {
			return onEdit(value, new Validation(ValidationType.Conflict, ConflictType.DuplicateStudentIDN, 'This ID is taken'));
		}

		if (!value.trim()) {
			return onEdit(value, new Validation(ValidationType.Standard, ConflictType.DuplicateStudentIDN, 'Please enter a student ID.'));
		}

		return onEdit(value, new Validation(ValidationType.None, ConflictType.DuplicateStudentIDN, ''));
	};

	return <Input
		value={uploadedStudentID}
		placeholder='ID'
		error={errorMessage}
		onChange={(e) => onHandleEdit(e.target.value)}
	/>;
}

