import {UserFeaturesController} from '@esgi/contracts/esgi';
import {BaseService} from '@esgi/core/service';
import {BehaviorSubject} from 'rxjs';

export class UserFeatureService extends BaseService {
	public userFeatures$ = new BehaviorSubject<Record<string, boolean>>({});
	private controller = new UserFeaturesController();

	public init() {
		this.controller.current().subscribe((response) => {
			const result = response.value.features.reduce<Record<string, boolean>>(
				(prev, current) => {
					return {...prev, [current.name]: current.enabled};
				}, {});

			this.userFeatures$.next(result);
		});
	}

	override dispose() {
		super.dispose();
		this.controller.dispose();
	}
}
