import {CloseIcon, Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import styles from './styles.module.less';
import {BookIcon} from '../book-icon';
import AudioButton from '../../../../audio-button/audio-button';
import React from 'react';
import {PlayIcon} from '../play-icon';
import {dispatchAppEvent} from '@esgillc/events';
import {AudioEndPlayEvent} from '../../../../../events';

interface Props {
	onClose?: () => void;
	url: string;
	id: number;
	canClose?: boolean;
	onAudioEnd?: () => void;
}

export const AudioModal = ({onClose, url, id, canClose, onAudioEnd}: Props) => {
	 const modalRef = useModal();
	 const handleClose = useCloseModal(modalRef, onClose);
	 const closeAction = () => {
		 dispatchAppEvent(AudioEndPlayEvent, {});
		 handleClose();
	 };

	return (
		<Modal className={styles.modal} containerClassName={styles.modalContainer} modalManagerRef={modalRef}>
			{canClose && <CloseIcon className={styles.closeIcon} onClick={handleClose}/>}
			<Modal.Body className={styles.modalBody}>
				<BookIcon className={styles.bookIcon}/>
				<AudioButton
					className={styles.audioButton}
					onAudioEnd={() => {
						onAudioEnd?.();
						closeAction();
					}}
					id={id}
					audioUrl={url}
					icon={<PlayIcon/>}
				/>
			</Modal.Body>
		</Modal>
	);
};
