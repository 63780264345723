import {Dispatch, useCallback} from 'react';
import {EmptyContentTemplate, ExpandablePanelModeVariant} from '@esgi/main/features/teacher/home';
import {Folder} from '@esgi/ui/icons';
import {Box} from '@esgi/ui/layout';
import {useGroupList} from './hooks';
import {WrapperBox, ListsContentWrapper} from './index.styled';
import {List} from '../list';
import {ClassSelect} from './components/class-select';
import {StudentsWithoutGroups} from './components/students-without-groups';
import {Class, Group, Student} from '@esgi/main/libs/store';
import {isUndefined} from '@esgi/ui';

type Props = {
	classes: Class[];
	groups: Group[];
	students: Student[];
	selectedStudentsIDs: Student['id'][];
	toggleStudent: Dispatch<Student['id']>;
	setSelectedStudentIDs: Dispatch<Student['id'][]>;
};

export function GroupList({
	classes,
	groups,
	students,
	selectedStudentsIDs,
	setSelectedStudentIDs,
	toggleStudent,
}: Props) {
	const {setSelectedClassId, studentsWithoutGroup, groupsWithStudents, onToggle, onSelectAll} = useGroupList({
		students,
		groups,
	});
	const isDisplayClassesSelect = Boolean(classes.length || groups.length);
	const isListSelected = useCallback(
		(groupId: Group['id']) => {
			const group = groups.find(({id}) => id === groupId);

			if (isUndefined(group)) {
				return false;
			}

			const {studentIDs} = group;

			return Boolean(studentIDs.length) && studentIDs.every((id) => selectedStudentsIDs.includes(id));
		},
		[groups, selectedStudentsIDs],
	);

	return (
		<WrapperBox>
			{isDisplayClassesSelect && <ClassSelect classes={classes} onChange={setSelectedClassId} />}

			<ExpandablePanelModeVariant withBottomSectionButton={false}>
				<ListsContentWrapper>
					<Box>
						{groupsWithStudents.length ? (
							groupsWithStudents.map(({id, name, isListOpen, students}) => {
								const isAllStudentsSelected = isListSelected(id);

								return (
									<List.ExpandableSelectableListMultiple
										key={id}
										IconBefore={Folder}
										triggerLabel={name}
										isListOpen={isListOpen}
										onTriggerButtonClick={() => onToggle(!isListOpen, id)}
										students={students}
										selectedStudentId={selectedStudentsIDs}
										setSelectedStudentId={toggleStudent}
										onStyledButtonClick={() =>
											setSelectedStudentIDs(
												onSelectAll({
													groupId: id,
													selectedStudentIds: selectedStudentsIDs,
													isListSelected: isAllStudentsSelected,
												}),
											)
										}
										isListSelected={isAllStudentsSelected}
									/>
								);
							})
						) : (
							<EmptyContentTemplate size='full' templateText='No groups to display' />
						)}
					</Box>

					<StudentsWithoutGroups
						students={studentsWithoutGroup}
						selectedStudentsIDs={selectedStudentsIDs}
						toggleStudent={toggleStudent}
					/>
				</ListsContentWrapper>
			</ExpandablePanelModeVariant>
		</WrapperBox>
	);
}
