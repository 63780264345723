import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {SnackbarManager} from '@esgi/ui/snackbar';

import {StudentModel} from '../../types';
import {StudentManagerService} from '../../service';
import {RefObject, useCallback, useState} from 'react';
import {FixedBox} from './styled';

type Props = {
	students: StudentModel[],
	service: StudentManagerService,
	snackbarRef: RefObject<SnackbarManager>,
	onClose: VoidFunction,
};

export function DeleteDialog({students, service, snackbarRef, onClose}: Props) {
	const modal = Alert.useRef();
	const close = Alert.useClose(modal, onClose);

	const [loading, setLoading] = useState(false);

	const confirm = useCallback(() => {
		setLoading(true);
		const deletedStudentsIds = students.map(({studentID}) => studentID);

		service.delete(deletedStudentsIds).subscribe({
			next: () => {
				close();
				snackbarRef.current.showSnackbar(students.length === 1
					? <Text>{students[0].firstName} {students[0].lastName} has been deleted</Text>
					: <Text>The selected students have been deleted.</Text>
				);
			},
			complete: () => setLoading(false),
		});
	}, [students, service, snackbarRef, close]);

	return <Alert modalManagerRef={modal}>
		<Alert.Header withBacklight={false} onCloseIconClick={close} disableClose={loading}>
			<Text size='small'>Delete</Text>
		</Alert.Header>
		<Alert.Body>
			<FixedBox>
				<Text size='medium'>
					Are you sure you wish to delete the following students from the system? <strong>Doing so will also remove all associated data which is not recoverable.</strong>
				</Text>
				<br/>
				{students.map(({firstName, lastName}, idx) => (
					<Text size='medium' color='primary'>
						{firstName} {lastName}{students.length > 1 && idx !== students.length - 1 && ', '}
					</Text>
				))}
			</FixedBox>
		</Alert.Body>
		<Alert.Footer>
			<Button css={{marginRight: 12}} color='tertiary' onClick={close} disabled={loading}>
				<Text size='medium' color='base'>
					CANCEL
				</Text>
			</Button>
			<Button color='secondary' onClick={confirm} disabled={loading}>
				<Text size='medium' color='base'>
					CONFIRM
				</Text>
			</Button>
		</Alert.Footer>
	</Alert>;
}