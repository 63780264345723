import {useEffect} from 'react';
import {Page} from './page';

export default function RenewalPage() {
	useEffect(() => {
		try {
			const page = new Page();
			page.init();
			return () => page.destroy();
		} catch (e) {
		}
	}, []);

	return <>
		<div style={{backgroundColor: '#FFF', height: '100%', overflowY: 'auto'}}>
			<div style={{minHeight: 450, paddingTop: 15}} data-bind='render: renewal'></div>
		</div>
	</>;
}