import React from 'react';
import {UserType, userStorage} from '@esgi/core/authentication';
import {
	IBoxInfo,
	IDistrictSpecialist, GroupModelForAdmin,
	ISchoolSpecialist,
	GroupOfSpecialistsBox,
} from '../../core/api';
import {BoxType, SpecialistHierarchySnapshot} from '../../core/models';
import {Box, BoxOptions, ItemProps} from '../../components/box';
import {HierarchyEvents} from 'modules/hierarchy/core/events';
import {EventBusDispatcher} from '@esgillc/events';

export class State {
	userTypes: UserType[];
}

export class Props {
	boxes: IBoxInfo[];
	selectedID: number;
	selectedSpecialistID: number;
	groups: GroupOfSpecialistsBox;
	districtSpecialists: IDistrictSpecialist[];
	schoolSpecialists: ISchoolSpecialist[];
	type: number;
	onSelected: (id: UserType, callback: () => void) => void;
	onOpenCloseChanged: (type: BoxType, state: boolean) => void;
}


export class GroupOfSpecialists extends React.Component<Props, State> {
	private readonly currentUser = userStorage.get();

	componentDidMount() {
		if (this.currentUser.userType === UserType.D) {
			this.setState({userTypes: [UserType.ISD, UserType.ISS]});
		} else if (this.currentUser.userType === UserType.C) {
			this.setState({userTypes: [UserType.ISS]});
		}
	}

	get items(): ItemProps[] {
		return this.props.groups.items.map(r => {
			return {id: r.groupID, title: r.name};
		});
	}

	render(): any {
		if (this.currentUser.userType !== UserType.C && this.currentUser.userType !== UserType.D) {
			return null;
		}

		if (this.props.selectedSpecialistID !== 0 && !this.props.selectedID) {
			return null;
		}

		const options: BoxOptions = {
			canDrag: false,
			boxType: BoxType.SpecialistGroupOfSpecialists,
			title: 'Specialist Groups',
			canCreateTooltip: null,
			canAdd: this.props.groups.canCreate,
			canEdit: this.props.groups.canEdit,
			tooltipPostfix: 'specialist group',
		};

		return <Box
			open={this.props.boxes.filter(t => t.boxType == BoxType.SpecialistGroupOfSpecialists && t.open).length > 0}
			selectedID={this.props.selectedID}
			scheduledIDs={[]}
			onDragStart={null}
			onEditClicked={this.publish.edit}
			onAddClicked={this.publish.add}
			options={options}
			empty={{message: 'The list is empty'}}
			onDragEnd={null}
			items={this.items}
			onOpenCloseChanged={this.props.onOpenCloseChanged}
			itemSelected={this.props.onSelected}
		/>;
	}

	publish = {
		add: () => {
			const args: HierarchyEvents.GroupOfSpecialists.Add = {
				districtID: this.currentUser.districtID,
				schoolID: this.currentUser.schoolID,
				userTypes: this.state.userTypes,
				userID: this.currentUser.userID,
			};

			EventBusDispatcher.dispatch(HierarchyEvents.GroupOfSpecialists.Add, args);
		},
		edit: (id: number) => {
			const item = this.props.groups.items.find(t => t.groupID === id);
			const args: HierarchyEvents.GroupOfSpecialists.Edit = {
				districtID: this.currentUser.districtID,
				schoolID: this.currentUser.schoolID,
				userTypes: this.state.userTypes,
				id: item.groupID,
				name: item.name,
			};

			EventBusDispatcher.dispatch(HierarchyEvents.GroupOfSpecialists.Edit, args);
		},
	};
}
