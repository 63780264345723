import React from 'react';
import {filter} from 'rxjs/operators';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Option} from '@esgillc/ui-kit/form-control';
import {ColorPicker, State as ColorPickerState} from '@esgi/deprecated/elements';
import styles from '../confirm.module.less';
import {Field} from '../type';
import {contentAreaDefaultColors} from '../../../../../../../kits/test-details';

class State {
	colorPickerState = new ColorPickerState();
}

interface Props {
	control: FormControl<Field>;
	initialValue: number;
	initialColor: string;
	contentAreas: Field;
	onColorChanged: (color: string) => void;
}

export default class ContentAreaField extends React.PureComponent<Props, State> {
	public state = new State();

	public componentDidMount() {
		this.props.control
			.onChanged
			.pipe(filter(r => r.reason === 'value'))
			.subscribe(r => {
				const id = r.currState.value[0]?.id;
				this.setState({colorPickerState: new ColorPickerState(contentAreaDefaultColors[id]?.color)});
			});

		if (this.props.initialValue) {
			this.props.control.value = [this.props.contentAreas.find(ca => ca.id === this.props.initialValue)];
			this.setState({colorPickerState: new ColorPickerState(this.props.initialColor)});
		} else {
			this.props.control.value = [this.props.contentAreas.find(ca => ca.id === 93)];
		}
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if(this.state.colorPickerState?.selectedColor !== prevState.colorPickerState?.selectedColor) {
			this.props.onColorChanged(this.state.colorPickerState?.selectedColor);
		}
	}

	public render() {
		return <div className={styles.inlineField}>
			<div className={styles.label}>Content Area:</div>
			<FormElement control={this.props.control}
			             className={styles.contentAreaElement}>
				<Dropdown optionName='name' className={styles.contentAreaDropdown}>
					{this.props.contentAreas.map(s => <Option key={s.id} value={s}>{s.name}</Option>)}
				</Dropdown>
				<ColorPicker hideTitle={true}
				             className={styles.colorPicker}
				             state={this.state.colorPickerState}
				             onChange={(ch, cb) => this.setState({colorPickerState: ch}, cb)}/>
			</FormElement>
		</div>;
	}
}
