import * as React from 'react';
import type {SVGProps} from 'react';

export function UserCard(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M11.556 7.818h8.888c.982 0 1.778.814 1.778 1.818v12.728c0 1.004-.796 1.818-1.778 1.818h-8.888c-.982 0-1.778-.814-1.778-1.818V9.636c0-1.004.796-1.818 1.778-1.818ZM8 9.636C8 7.628 9.592 6 11.556 6h8.888C22.408 6 24 7.628 24 9.636v12.728C24 24.372 22.408 26 20.444 26h-8.888C9.592 26 8 24.372 8 22.364V9.636Zm5.333 0a.9.9 0 0 0-.889.91.9.9 0 0 0 .89.909h5.333a.9.9 0 0 0 .889-.91.9.9 0 0 0-.89-.909h-5.333Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M18.667 17.364c0 1.506-1.194 2.727-2.667 2.727-1.473 0-2.667-1.221-2.667-2.727 0-1.507 1.194-2.728 2.667-2.728 1.473 0 2.667 1.221 2.667 2.728Zm-1.778 0a.9.9 0 0 0-.889-.91.9.9 0 0 0-.889.91.9.9 0 0 0 .889.909.9.9 0 0 0 .889-.91Zm.104 4.09c1.674 0 3.415.891 3.882 2.672a1.64 1.64 0 0 1-.43.056l-1.44-.002a2.755 2.755 0 0 0-2.012-.907h-1.986c-.764 0-1.5.347-2.011.907l-1.44.002c-.149 0-.292-.019-.43-.054l-.015.054c.445-1.818 2.205-2.727 3.896-2.727h1.986Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				d='M12.444 10.546a.9.9 0 0 1 .89-.91h5.333a.9.9 0 0 1 .889.91.9.9 0 0 1-.89.909h-5.333a.9.9 0 0 1-.889-.91Z'
			/>
		</svg>
	);
}
