import {ReactNode, RefObject, useCallback} from 'react';
import {isIpad, join, useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {NextColumnButton} from '@esgi/deprecated/ui-kit/matrix';
import Matrix from '@esgi/deprecated/ui-kit/matrix/matrix';
import {PrevColumnButton} from '@esgi/deprecated/ui-kit/matrix/prev-column-button';
import {PrevRowButton} from '@esgi/deprecated/ui-kit/matrix/prev-row-button';
import {RowBodyRenderer, RowData} from '@esgi/deprecated/ui-kit/matrix/types';
import {Criteria, Description, Level, RubricAnswer} from '../../../common/types';
import {calcScore, calcTotalScore, getInfo, isDescriptionSelected, validateCriteria} from '../../../common/utils';
import DataService from '../../data-service';
import {SessionModel} from '../../types';
import {BottomPanel} from './components/bottom-panel/bottom-panel';
import {CriteriaView} from './components/criteria/criteria';
import {DescriptionView} from './components/description/description';
import {LevelView} from './components/level/level-view';
import {Score} from './components/score/score';
import commonMatrixStyles from '../../../common/matrix.common.module.less';
import formStyles from './form.module.less';
import {ColumnWidth, RowHeight} from './utils';

interface Props {
	matrixRef: RefObject<Matrix<Level, Criteria, Description>>,
	criteriaToRef: Map<number, RefObject<HTMLDivElement>>,
	answers: RubricAnswer[],
	session: SessionModel,
	service: DataService;
	editMode: boolean;
	showValidation: boolean;
}

export function SessionForm ({matrixRef, criteriaToRef, service, editMode, session, answers, showValidation}: Props) {
	const testModel = service.testInfo;
	const currentSession = useBehaviorSubject(service.currentSession);

	const renderHeader = useCallback((_, headerRenderer) => {
		return (
			<thead>
			<tr key={0} className={formStyles.header}>
				<th className={commonMatrixStyles.prevActions}>
					<div>
						<div className={join(commonMatrixStyles.prevColumn, isIpad() && commonMatrixStyles.hidden)}>
							{!isIpad() && <PrevColumnButton columnWidth={ColumnWidth}/>}
						</div>
						<div className={commonMatrixStyles.prevRow}>
							{!isIpad() && <PrevRowButton rowHeight={RowHeight}/>}
						</div>
					</div>
				</th>
				{headerRenderer()}
				<th className={commonMatrixStyles.scoreHeader}>
					{!isIpad() && <NextColumnButton className={commonMatrixStyles.nextColumnButton} columnWidth={ColumnWidth}/>}
				</th>
			</tr>
			</thead>
		);
	}, []);

	const renderBody = useCallback((_, bodyRenderer) => {
		return (
			<tbody>
			{bodyRenderer()}
			{isIpad() &&
				<tr>
					<td colSpan={service.testInfo.levelModels.length + 2}>
						{renderBottomPanel()}
					</td>
				</tr>}
			</tbody>);
	}, [service.testInfo.levelModels.length]);

	const renderRow = useCallback((data: RowData<Criteria, Description>, rowRenderer: RowBodyRenderer) => {
		const answer = answers.find(a => a.criteriaID === data.row.id);
		const isInvalid = showValidation && !validateCriteria(data.row.id, testModel, answers);

		return <tr key={data.row?.id}>
			{rowRenderer()}
			<th className={formStyles.scoreCell}>
				<Score
					score={answer.score || 0}
					editMode={editMode}
					isFirst={data.rowIndex === 0}
					isLast={data.rowIndex === (service.testInfo.criteriaModels.length - 1)}
					invalid={isInvalid}
				/>
			</th>
		</tr>;
	}, [editMode, answers, service, testModel]);

	const renderCriteria = useCallback((criteria: Criteria) => {
		return (
			<th key={criteria.id}>
				<CriteriaView
					invalid={showValidation && !validateCriteria(criteria.id, testModel, answers)}
					criteria={criteria}
					editMode={editMode}
					levelName={getInfo(testModel, answers).byCriteria(criteria.id).level?.name}
					note={answers.find(a => a.criteriaID === criteria.id)?.notes}
					onNoteChanged={value => service.updateNote(criteria.id, value)}
					criteriaToRef={criteriaToRef}
				/>
			</th>
		);
	}, [testModel, answers, criteriaToRef, showValidation]);

	const renderLevel = useCallback((level: Level, index: number): ReactNode => {
		return (
			<th key={level.id}>
				<LevelView level={level} highest={index === 0}/>
			</th>
		);
	}, []);

	const renderDescription = useCallback((description: Description) => {
		const {level, criteria} = getInfo(testModel, answers).byDescription(description.id);
		return (
			<td>
				<DescriptionView
					description={description}
					editMode={editMode}
					levelName={level.name}
					criteriaName={criteria.name}
					selected={isDescriptionSelected(answers, testModel, description.id)}
					onClicked={() => editMode && service.selectCard(description.id)}/>
			</td>
		);
	}, [testModel, answers, editMode]);

	const renderBottomPanel = useCallback(() => {
		return (
			<BottomPanel
				notes={currentSession?.summaryNotes}
				score={calcScore(answers)}
				onChanged={(v) => service.updateSummaryNotes(v)}
				totalScore={calcTotalScore(testModel)}
				editMode={editMode}
			/>
		);
	}, [currentSession, service, editMode, testModel]);

	return (
		<>
			{testModel && <div className={commonMatrixStyles.body}>
				<div className={commonMatrixStyles.matrixContainer}>
					<Matrix ref={matrixRef}
					        maxWidth={1059}
					        maxHeight={514}
					        className={{
						        body: join(commonMatrixStyles.matrix, formStyles.matrix),
						        tableContainer: commonMatrixStyles.tableContainer,
					        }}
					        renderBody={renderBody}
					        renderHeader={renderHeader}
					        renderRow={renderRow}
					        columnHeaderOptions={{
						        cells: testModel.levelModels,
						        cellRenderer: renderLevel,
					        }}
					        rowHeaderOptions={{
						        cells: testModel.criteriaModels,
						        cellRenderer: renderCriteria,
					        }}
					        cellsOptions={{
						        cells: testModel.descriptionModels,
						        cellRenderer: renderDescription,
						        cellGetter: (source, col, row) => source.find(d => d.levelID === col.id && d.criteriaID === row.id),
					        }}>
						{!isIpad() && renderBottomPanel()}
					</Matrix>
				</div>
			</div>}
		</>
	);
}
