import * as Highcharts from './highstock';
import {HighchartsChart, HighchartsOptions} from './types';

class HighchartsQueue {
	private handlingTime: number;
	private handlingTimesBuffer: number[] = [];

	private renderCount = 4;
	private isWorking: boolean = false;
	private charts: HighchartsQueueItem[] = [];
	private chartsToRender: HighchartsQueueItem[] = [];

	private generateID() {
    	const id = Math.round(Math.random() * 1000000);
    	if (this.charts.filter(i => i.id === id).length || this.chartsToRender.filter(i => i.id === id).length) {
    		return this.generateID();
    	}
    	return id;
	}

	removeItem(chartID: number) {
    	const waitingChart = this.chartsToRender.find(i => i.id === chartID);
    	if (waitingChart) {
    		this.chartsToRender = this.chartsToRender.filter(i => i.id !== chartID);
    		return;
    	}

    	const renderedChart = this.charts.find(i => i.id === chartID);
    	if (renderedChart) {
    		const libChart = Highcharts.charts.find(i => i === renderedChart.chart);
    		try {
    			if (libChart) {
    				libChart.destroy();
    			}
    			this.charts = this.charts.filter(i => i.id !== chartID);
    		} catch (e) {
    			console.error(e);
    		}
    		return;
    	}
	}

	addItem(container: any, options: HighchartsOptions, onRendered?: (chart: HighchartsChart) => void): number {
    	const item = {
    		id: this.generateID(),
    		container: container,
    		options: options,
    		rendered: false,
    		callback: onRendered,
    	};
    	this.chartsToRender.push(item);
    	this.renderAsync();
    	return item.id;
	}

	private renderAsync() {
    	if (!this.isWorking) {
    		this.startQueue();
    	}
	}

	private startQueue() {
    	this.isWorking = true;
    	this.renderCount = 4;
    	this.handlingTimesBuffer = [];
    	setTimeout(() => this.renderChart());
	}

	private onChartRendered = (item: HighchartsQueueItem) => {
    	item.callback && item.callback(item.chart);
	}

	private renderChart() {
    	if (this.handlingTime) {
    		const log = Date.now() - this.handlingTime;
    		this.handlingTimesBuffer.push(log);
    	}
    	this.handlingTime = Date.now();

    	for (let i = 0; i < this.renderCount; i++) {
    		let item;

    		//To avoid sequenced rendering(harmful for virtual scroll) will render head/tail alternately
    		if (i % 2 == 0) {
    			item = this.chartsToRender.shift();
    		} else {
    			item = this.chartsToRender.pop();
    		}

    		if (item) {
    			item.chart = Highcharts.chart(item.container, item.options);
    			this.onChartRendered(item);
    			this.charts.push(item);
    		} else {
    			this.stopQueue();
    			return;
    		}
    	}

    	if (this.handlingTimesBuffer.length > 10) {
    		this.correctRenderCount();
    	}

    	setTimeout(() => this.renderChart());
	}

	calculateAverageHandlingTimeBuffer = () => this.handlingTimesBuffer.filter(i => i < 500).reduce((a, b) => a + b, 0) / this.handlingTimesBuffer.length;

	// Reduce or increase count of the charts to rendering by one iteration. Calculations is based on average time of rendering with current renderCount value
	private correctRenderCount() {
    	const avgTime = this.calculateAverageHandlingTimeBuffer();
    	//If average rendering time is more than 33ms than reduce to get best performance
    	if (avgTime > 33 && this.renderCount > 1) {
    		this.renderCount--;
    	} else {
    		//If average time less than 16ms than increase to render more charts without performance damage.
    		if (avgTime < 16 && this.renderCount < 4) {
    			this.renderCount++;
    		}
    	}
    	this.handlingTimesBuffer = [];
	}


	private stopQueue = () => {
    	this.isWorking = false;
	}
}

interface HighchartsQueueItem {
	id: number;
	container: any;
	options: HighchartsOptions;
	chart?: HighchartsChart;
	callback?: (chart: HighchartsChart) => void;
	rendered: boolean;
}

export const highchartsQueue = new HighchartsQueue();
