import {ServerClient} from 'pages/landing/kit/server-client';
import {CountryModel} from './components';
import {DistrictAndSchoolSearchResponse, DistrictsResponse, InitResponseModel} from './models';

export class Dictionaries {
	private static readonly controllerName = 'Registration/Dictionaries';
	static loadStates = (countryID: number): JQueryPromise<StatesResponse> => {
		return ServerClient.SSOAPI.Get<StatesResponse>(Dictionaries.controllerName, 'States', {
			data: {countryID: countryID},
			cache: true,
		});
	};
	static loadCountries = (): JQueryPromise<CountriesResponse> => {
		return ServerClient.SSOAPI.Get<CountriesResponse>(Dictionaries.controllerName, 'Countries', {
			cache: true,
		});
	};
	static searchSchools = (query: string, stateId: number): JQueryPromise<DistrictAndSchoolSearchResponse> => {
		return ServerClient.SSOAPI.Get<DistrictAndSchoolSearchResponse>(Dictionaries.controllerName, 'FederalSchools', {
			data: {
				query: query,
				stateID: stateId,
			},
		});
	};
	static loadDistricts = (stateId: number): JQueryPromise<DistrictsResponse> => {
		return ServerClient.SSOAPI.Get<DistrictsResponse>(Dictionaries.controllerName, 'FederalDistricts', {
			data: {
				stateID: stateId,
			},
		});
	};
	static loadTimeZones = (): JQueryPromise<TimeZonesResponse> => {
		return ServerClient.SSOAPI.Get<TimeZonesResponse>(Dictionaries.controllerName, 'TimeZones', {
			cache: true,
		});
	};
}

export class Server {
	private static normalizeDate(num: number): string {
		if (num < 10) {
			return '0' + num.toString();
		}
		return num.toString();
	}

	private static toServerString(val: Date | string | null, includeTime: boolean = false): string {
		if (val instanceof Date) {
			let date = `${val.getFullYear()}-${Server.normalizeDate(val.getMonth() + 1)}-${Server.normalizeDate(val.getDate())}`;
			if (includeTime) {
				const hours = Server.normalizeDate(val.getHours());
				const minutes = Server.normalizeDate(val.getMinutes());
				const seconds = Server.normalizeDate(val.getSeconds());
				date += `T${hours}:${minutes}:${seconds}`;
			}

			return date;
		} else {
			if (val != null && val.indexOf('/') != -1) {
				const parts = val.split('/');
				return parts[2] + '-' + parts[0] + '-' + parts[1] + 'T00:00:00';
			}
		}

		return val;
	}

	static completeUnlinked = (model: UnlinkedCompleteRequestModel): JQueryPromise<CompleteResponse> => {
		return ServerClient.SSOAPI.Post<CompleteResponse>('Registration/Complete', 'Unlinked', {
			data: model,
			headers: {'ajax': 'true'},
		});
	};
	static completeLinked = (model: LinkedCompleteRequestModel): JQueryPromise<CompleteResponse> => {
		return ServerClient.SSOAPI.Post<CompleteResponse>('Registration/Complete', 'Linked', {
			data: model,
			headers: {'ajax': 'true'},
		});
	};
	static init = () => {
		try {
			const browserDateTime = Server.toServerString(new Date(), true);
			let browserTimeZone = null;
			try {
				browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			} catch {

			}
			return ServerClient.SSOAPI.Get<InitResponseModel>('Registration/Complete', 'Init', {
				data: {
					browserDateTime: browserDateTime,
					browserTimeZone: browserTimeZone,
				},
			});
		} catch (e) {
			console.warn(e);
		}
	};
}

export interface BaseCompleteRequestModel {
	timeZoneID: string,
	gradeLevels: Array<number>,
	firstName: string,
	lastName: string,
	title: string
}

export interface UnlinkedCompleteRequestModel extends BaseCompleteRequestModel {
	countryID: number,
	stateID: number,
	federalSchoolID?: number,
	federalDistrictID?: number,
	schoolName: string,
	districtName: string
}

export interface LinkedCompleteRequestModel extends BaseCompleteRequestModel {
	trackID: number
	globalSchoolYearID: number;
}

export interface CompleteResponse {
	token: string
}

export interface CountriesResponse {
	countries: Array<CountryModel>
}

export interface StatesResponse {
	states: Array<StateModel>
}

export interface TimeZonesResponse {
	timeZones: Array<TimeZoneModel>
}

export interface TimeZoneModel {
	id: string,
	displayName: string
}

export interface StateModel {
	id: number;
	name: string;
}
