import React from 'react';
import {highchartsQueue} from './highcharts-queue';
import {HighchartsChart, HighchartsOptions} from './types';

class AsyncHighchartsProps {
	chartRendered?: () => any;
	options: HighchartsOptions;
	updateArgs?: HighchartsOptions;
	allowChartUpdate?: boolean = false;
	className?: string;
	showLoader?: boolean = false;
}

class AsyncHighchartsState {
	ready: boolean = false;
}

export class AsyncHighchartsReact extends React.Component<AsyncHighchartsProps, AsyncHighchartsState> {
	private readonly container: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
	private chart: HighchartsChart;
	private chartID: number;

	constructor(props) {
    	super(props);
    	this.state = new AsyncHighchartsState();
	}

	componentDidMount() {
    	this.createChart();
	}

	createChart() {
    	this.chartID = highchartsQueue.addItem(this.container.current, this.props.options, (chart) => {
    		this.props.chartRendered && this.props.chartRendered();
    		this.chart = chart;
    		this.setState({ready: true});
    	});
	}

	componentDidUpdate() {
    	const props = this.props;
    	if (this.chart) {
    		this.chart.update(
    			props.options,
    		);
    	} else {
    		if (!this.chartID) {
    			this.createChart();
    		}
    	}
	}

	componentWillUnmount() {
    	// Destroy chart
    	if (this.chartID) {
    		highchartsQueue.removeItem(this.chartID);
    	} else {
    		console.error('try to remove item', this.chartID);
    	}
	}

	render() {
    	return <>
	<div ref={this.container} className={(this.props.className || '')}/>
	{this.props.showLoader && !this.state.ready && this.renderLoader()}
    	</>;
	}

	renderLoader() {
    	return <div
            style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
	<div>
		<i className='fa fa-spinner fa-spin'/>
	</div>
        </div>;
	}
}
