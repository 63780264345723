import React, {PropsWithChildren, useCallback, useMemo} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useService} from '@esgi/core/service';
import {TestsRootContext} from './context';
import {TestsRootService} from './service';

export function TestsRoot({children}: PropsWithChildren) {
	const service = useService(TestsRootService);

	const sort = useBehaviorSubject(service.sort$);

	const handleSortDirectionChange = useCallback(() => {
		service.handleSortDirectionChange();
	}, [service]);

	const handleSelectAll = useCallback(() => {
		service.handleSelectAll();
	}, [service]);

	const contextValue = useMemo(() => ({
		sort,
		handleSortDirectionChange,
		handleSelectAll,
	}), [sort, handleSortDirectionChange, handleSelectAll]);

	return (
		<TestsRootContext.Provider value={contextValue}>
			{children}
		</TestsRootContext.Provider>
	);
}

export * from './models';