import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Buttons} from '@esgillc/ui-kit/button';
import {QuestionMoveIcon, QuestionMoveDirection} from '@esgillc/ui-kit/icons';
import {useTestSessionDetailsService} from '../../../../hooks';
import styles from './styles.module.less';

interface Props {
	direction: QuestionMoveDirection;
	title: string;
	onClick: (e: any) => void;
}

export function QuestionMover({
	direction,
	title,
	onClick,
}: Props): JSX.Element {
	const service = useTestSessionDetailsService();
	const editMode = useBehaviorSubject(service.editMode$);

	return (
		<div className={(editMode) ? styles.questionMover : 'hide'}>
			<Buttons.Icon
				className={styles.arrow}
				title={title}
				onClick={onClick}
			>
				<QuestionMoveIcon direction={direction}/>
			</Buttons.Icon>
		</div>
	);
}
