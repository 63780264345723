import {AppEvent} from '@esgillc/events';
import {MoveStudentModel, SpecialistAssignModel} from './components/table/components/operations-panel/operations/assign/models';
import {RemoveModel, UnassignType} from './models/models';

export namespace StudentManagerEvents {
	@AppEvent({broadcast: true})
	export class StudentsDeleted {
		studentIDs: number[];
		studentName: string;
	}

	@AppEvent({broadcast: true})
	export class StudentsUnassigned {
		studentsIDs: number[];
		studentName: string;
		hasGroups: boolean;
		type: UnassignType;
		removeModel: RemoveModel;
	}

	@AppEvent({broadcast: true})
	export class StudentsAssignedToTeacher {
		studentsIDs: number[];
		studentName: string;
		model: MoveStudentModel;
	}

	@AppEvent({broadcast: true})
	export class StudentsAssignedToSpecialist {
		studentsIDs: number[];
		studentName: string;
		model: SpecialistAssignModel;
	}
}
