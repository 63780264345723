import React, {useMemo} from 'react';
import {TestType} from '@esgi/main/libs/core';
import {DateFormatText, Statistics, TimeText} from '../../../kit';
import {GradeRange, TestSession} from '../../../types';
import {DefaultDiagrams} from './components/default-diagrams';
import {RubricDiagrams} from './components/rubric-diagrams';
import {Text} from '@esgi/ui/typography';
import {calculateLastRubricTestScores, calculateLastYNTestScores, determineGradeTemplate, sortedSessions} from './utils';
import {GradeIndicator} from './components/grade-indicator';

interface Props {
	testType: TestType;
	testSessions: TestSession[];
	gradeRanges: GradeRange[];
	rubricCriteriaCount: number;
	questionsCount: number;
	totalPossible: number;
	isTablet: boolean;
	classCorrectAnswersAvg: number;
}

export function BodyStats({
	testType,
	gradeRanges,
	testSessions,
	rubricCriteriaCount,
	totalPossible,
	questionsCount,
	classCorrectAnswersAvg,
	isTablet,
}: Props) {

	const notDeletedSessions = useMemo(()=>testSessions.filter(el=>!el.isDeleted), [testSessions]);

	const averageSessionDuration = useMemo(() => {
		const totalDuration = notDeletedSessions.reduce((accumulatedScores, session) => accumulatedScores + session.duration, 0);
		return Math.round(totalDuration / notDeletedSessions.length);
	}, [notDeletedSessions]);

	const [lastSession] = useMemo(() => {
		if (testType === TestType.YN || testType === TestType.Score) {
			return [calculateLastYNTestScores(notDeletedSessions, totalPossible)];
		}
		return [calculateLastRubricTestScores(notDeletedSessions, rubricCriteriaCount, totalPossible)];
	}, [rubricCriteriaCount, notDeletedSessions, testType, totalPossible]);

	const firstSession = useMemo(() => {
		const {firstSession} = sortedSessions(notDeletedSessions);
		return firstSession;
	}, [notDeletedSessions]);

	const progressPercent = useMemo(() => {
		return determineGradeTemplate(lastSession?.progress, gradeRanges);
	}, [testType]);

	return (
		<Statistics css={{width: '100%', padding: isTablet && '28px 14px'}}>
			<Statistics.ChartsBox>
				{(testType === TestType.YN || testType === TestType.Score) && (
					<DefaultDiagrams
						lastSession={lastSession}
						totalPossible={totalPossible}
						classCorrectAnswersAvg={classCorrectAnswersAvg}
					/>
				)}
				{testType === TestType.Rubric && (
					<RubricDiagrams
						totalPossible={totalPossible}
						lastSession={lastSession}
						rubricCriteriaCount={rubricCriteriaCount}
						rubricAnswers={notDeletedSessions[notDeletedSessions.length - 1]?.rubricAnswers}
						classCorrectAnswersAvg={classCorrectAnswersAvg}
					/>
				)}
			</Statistics.ChartsBox>
			<Statistics.InfoBox>
				<Statistics.InfoWithTranscript transcript='Grade'>
					<GradeIndicator
						label={progressPercent?.gradeScaleEntry?.name}
						color={progressPercent?.gradeScaleEntry?.color || '$lowContrast'}
					/>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript='Sessions'>
					<Text size='medium' font='mono' bold color='base'>
						{notDeletedSessions.length}
					</Text>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript='First Test Date'>
					<DateFormatText
						date={firstSession?.testDate}
						dateFormat='MM/DD/YYYY'
						size='medium'
						font='mono'
						bold
						color='base'
					/>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript={testType === TestType.Rubric ? 'Criteria' : 'Questions'}>
					<Text size='medium' font='mono' bold color='base'>
						{testType === TestType.Rubric ? rubricCriteriaCount : questionsCount}
					</Text>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript='Avg. Session Time'>
					<TimeText
						time={averageSessionDuration / 1000 || 0}
						timeFormat='mm:ss'
						size='medium'
						font='mono'
						bold
						color='base'
					/>
				</Statistics.InfoWithTranscript>
				<Statistics.InfoWithTranscript transcript='Last Test Date'>
					<DateFormatText
						date={lastSession?.testDate}
						dateFormat='MM/DD/YYYY'
						size='medium'
						font='mono'
						bold
						color='base'
					/>
				</Statistics.InfoWithTranscript>
			</Statistics.InfoBox>
		</Statistics>
	);
}
