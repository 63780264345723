import {Root} from './components/root';
import {TabsBar} from './components/tabs-bar';
import {useModeContext, useTabsContext, useTabActivator, PossiblePermissionsType} from './components/features';
import {TabContent} from './components/tab-content';

/**
 * Represents a multi-tab panel.
 *
 * @typedef {Object} MultiTabPanel
 * @property {Root} Root - The root element of the multi-tab panel.
 * @property {Object} Header - The header of the multi-tab panel.
 * @property {TabsBar} TabsBar - The tabs bar of the multi-tab panel.
 * @property {TabContent} TabContent - The content of each tab in the multi-tab panel.
 * @property {Object} context - The context object used for mode and tabs management.
 * @property {useModeContext} context.useModeContext - The hook for managing the current mode.
 * @property {useTabsContext} context.useTabsContext - The hook for managing the tabs.
 * @property {Object} hooks - The hooks used for tab activation.
 * @property {useTabActivator} hooks.useTabActivator - The hook for activating a tab.
 */
export const MultiTabPanel = {
	Root: Root,
	Header: {

	},
	TabsBar: TabsBar,
	TabContent: TabContent,
	context: {
		useModeContext,
		useTabsContext,
	},
	hooks: {
		useTabActivator,
	},
};

/**
 * Build a typed multi-tab panel component and related hooks.
 *
 * @return {Object} The built multi-tab panel component and related hooks.
 * @template Tab - The type of the tabs.
 * @template Mode - The type of the mode.
 * @template PermissionsType - The type of the permissions.
 */
export function buildTypedMultiTabPanel<Tab extends string, Mode extends string, PermissionsType extends PossiblePermissionsType>() {
	return {
		MultiTabPanel: {
			Root: Root<Tab, Mode, PermissionsType>,
			Header: {

			},
			TabsBar: TabsBar<Tab>,
			TabContent: TabContent<Tab>,
		},

		useModeContext: useModeContext<Mode, PermissionsType>,
		useTabsContext: useTabsContext<Tab>,

		useTabActivator: useTabActivator<Tab>,
	};
}