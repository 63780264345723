import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Label, Checkbox} from '@esgillc/ui-kit/form-control';
import {join} from '@esgillc/ui-kit/utils';
import styles from '../styles.module.less';

type Props = {
	control: IFormControl;
}

export const MarkAsCompletedField = ({control}: Props): JSX.Element => (
	<FormElement
		className={styles.markAsCompletedField}
		control={control}
	>
		<Label className={styles.label}>&nbsp;</Label>
		<div className={join(styles.field, styles.checkboxContainer)}>
			<Checkbox id='mark_as_completed'/>
			<label
				className={styles.checkboxLabel}
				htmlFor='mark_as_completed'
			>
				Mark Goal as Complete
			</label>
		</div>
	</FormElement>
);
