import {SelectableList} from '@esgi/ui/layout';
import {SelectableEditableItem} from './components/selectable-editable-item';
import {getListValue} from '../../utils/get-list-value';
import {CommonProps} from './types';
import {IdableItem} from '../../types';

export function Editable<Item extends IdableItem>({dataCy, items, onItemClick, children, css = {}}: CommonProps<Item>) {
	return (
		<SelectableList dataCy={dataCy} css={css}>
			<SelectableList.GroupRoot>
				<SelectableList.Group>
					{items.map((item) => {
						return (
							<SelectableEditableItem
								key={item.id}
								onItemClick={() => onItemClick(item.id)}
								value={getListValue(item.id)}
							>
								{children?.({
									item,
								})}
							</SelectableEditableItem>
						);
					})}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
