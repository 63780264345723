import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Container = styled(FlexBox, {
	width: '100%',
	flexGrow: 1,
});

export const List = styled(Box, {
	width: '50%',
	padding: '10px',
	alignSelf: 'stretch',
	overflowWrap: 'break-word',
	borderTop: '2px solid $muted',

	variants: {
		type: {
			correct: {
				borderRight: '1px solid $muted',
			},
			incorrect: {
				borderLeft: '1px solid $muted',
			},
		},
	},
});

export const Header = styled(Box, {
	textAlign: 'center',

	[`& > ${Text}`]: {
		fontSize: '16px',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
});

export const Body = styled(Box, {
	textAlign: 'center',
	paddingTop: '10px',
	wordBreak: 'break-all',
});

export const Sup = styled(Text, {
	verticalAlign: 'top',
});
