import {StudentTabId} from '../../types';

export const tabItems = [
	{
		tabId: StudentTabId.Classes,
		label: 'Classes',
	},
	{
		tabId: StudentTabId.Groups,
		label: 'Groups',
	},
	{
		tabId: StudentTabId.Students,
		label: 'Students',
	},
];
