import {BaseService} from '@esgi/core/service';
import {SightWordsTest, TestSelectorInitResponse} from './types';
import {userStorage} from '@esgi/core/authentication';
import {map} from 'rxjs/operators';
import {ObservableBuilder} from '@esgi/api';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export default class DataService extends BaseService {
	private readonly controllerName: string = 'writing-practice';

	public init(hierarchy: HierarchySnapshot): ObservableBuilder<SightWordsTest[]> {
		const request = {
			model: hierarchy,
			globalSchoolYearID: userStorage.get().globalSchoolYearID,
		}

		return this.httpClient
			.ESGIApi
			.post<TestSelectorInitResponse>(this.controllerName, 'sight-words/test-selector/init', request)
			.pipe(map(result => this.sortItems(result.tests, true)));
	}

	public sortItems(items: SightWordsTest[], isAscSortDirection: boolean): SightWordsTest[] {
		return items.sort((first, second) => {
			if (first.name == second.name) {
				return 0;
			}
			if (isAscSortDirection) {
				return first.name > second.name ? 1 : -1;
			} else {
				return first.name < second.name ? 1 : -1;
			}
		});
	}
}
