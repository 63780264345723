import {Entity} from '../../../../../../types/table-level-report-service-types';
import {ReportDropdown} from '../../../../../report-dropdown';
import {ReportHeaderField} from '../report-header-field';

type ReportEntityDropdownProps = {
	entityGroup?: Entity[];
	title: string;
	groupID: number;
	onSelected: (entity: Entity) => void;
	showAlternativeHeader?: boolean;
	validateOn: 'length' | 'existGroup';
};

export function ReportEntityDropdown({
	entityGroup,
	title,
	groupID,
	onSelected,
	showAlternativeHeader = false,
	validateOn,
}: ReportEntityDropdownProps) {
	const selectedGroup = entityGroup?.find(({id}) => id === groupID);

	if ((validateOn === 'length' && entityGroup?.length > 1) || (validateOn === 'existGroup' && selectedGroup)) {
		return (
			<ReportHeaderField>
				<ReportDropdown onSelected={onSelected} title={title} entities={entityGroup} selectedValue={selectedGroup} />
			</ReportHeaderField>
		);
	}

	if (showAlternativeHeader) {
		return <ReportHeaderField title={title} value={selectedGroup?.value} />;
	}

	return null;
}
