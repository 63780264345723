import {FormDataBuilder} from '@esgi/api';
import {BaseService} from '@esgi/core/service';
import {mix} from '@esgillc/ui-kit/utils';
import {last} from 'underscore';
import {ShareSyncService} from '../../../../../../kit/share-screen';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {AnswerState, SessionType, TestType} from '@esgi/core/enums';
import {TestSessionStatus} from '../../../../../../kit/enums';
import {QuestionModel} from '../../../../../../kit/models';
import {Summary} from '../../../../types';
import {TestingModel} from '../../../../models';
import {mapToEnum} from '../../../../../../kit/utils';
import {convertQuestions} from '../utils';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {submitAnswer} from './utils';
import {getUser} from '@esgi/core/authentication';

export type InitOptions = {
	questionsOrder: number[],
	subset?: number[],
	sessionType: SessionType,
	resume: boolean;
	continue: boolean;
}

export class TestScreenService extends BaseService {
	public testSessionStatus: Subject<TestSessionStatus> = new Subject<TestSessionStatus>();
	public currentQuestionIdx: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public questions: BehaviorSubject<QuestionModel[]> = new BehaviorSubject<QuestionModel[]>([]);
	public hasLongRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public onLostConnection: Subject<void> = new Subject<void>();
	public questionsLoadRange: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
	private readonly controller: string = 'assessment/test-screen/yn';
	private cacheAvailable: boolean;
	private questionStartTime: number;
	private testingStartTime: number;
	private lastAnsweredQuestionIdx = -1;
	private questionLoadStep = 5;

	constructor(
		private testingModel: TestingModel,
		private guid: string,
		private shareSyncService: ShareSyncService,
		private options: InitOptions,
	) {
		super();
		this.cacheAvailable = testingModel.general.cacheAvailable;
		const questions = convertQuestions(testingModel, options.sessionType, options.questionsOrder, options.resume, options.continue);

		if (options.subset && options.sessionType === SessionType.TestSubset) {
			this.questions.next(questions.filter(q => options.subset.includes(q.questionID)));
		} else {
			this.questions.next(questions);
		}

		this.currentQuestionIdx.pipe(takeUntil(this.destroy$)).subscribe((index) => {
			this.questionStartTime = Date.now();
		});
	}

	private get finishModel() {
		return FormDataBuilder.BuildParameters({
			testSessionGuid: this.guid,
			testId: this.testingModel.general.testID,
			duration: (Date.now() - this.testingStartTime) || 0,
			answers: this.questions.value?.map(a => {
				return {
					AnswerState: a.answerState,
					Duration: a.duration,
					QuestionID: a.questionID,
					Comment: a.comment || '',
				};
			}),
			studentId: this.testingModel.general.studentID,
			sessionType: this.options.sessionType,
			testType: TestType.YN,
			cacheAvailable: this.cacheAvailable,
			isResumed: this.options.resume,
			lastAnsweredQuestionID: this.getLastAnsweredQuestionID(this.questions.value),
		});
	}

	public startTesting(startIndex: number, options?: { isSessionResumed?: boolean }) {
		this.shareSyncService.start(startIndex, this.questions.value, TestType.YN);
		this.currentQuestionIdx.next(startIndex);
		this.testingStartTime = Date.now();
		this.questionStartTime = Date.now();
		this.currentQuestionIdx.pipe(takeUntil(this.destroy$)).subscribe((index) => {
			this.shareSyncService.syncQuestionIndex(index);
			this.refreshQuestionRange(index);
		});

		if (options?.isSessionResumed) {
			this.lastAnsweredQuestionIdx = startIndex - 1;
		}
	}

	public setCurrentQuestionIndex(value: number): void {
		this.currentQuestionIdx.next(value);
	}

	private refreshQuestionRange(index: number){
		const [minIndex, maxIndex] = this.questionsLoadRange.value;
		if(!minIndex && !maxIndex){
			this.questionsLoadRange.next([index-this.questionLoadStep, index+this.questionLoadStep]);
			return;
		}
		const newMinIndex = minIndex < index - this.questionLoadStep ? minIndex : index - this.questionLoadStep;
		const newMaxIndex = maxIndex > index + this.questionLoadStep ? maxIndex : index + this.questionLoadStep;
		this.questionsLoadRange.next([newMinIndex, newMaxIndex]);
	}

	public updateComment(value: string): void {
		this.updateCurrentQuestion({comment: value});
	}

	public submitAnswer(answerState: AnswerState): Observable<void> {
		const index = this.currentQuestionIdx.value;
		const duration = Date.now() - this.questionStartTime;

		const requestTimeout = this.createSnackbarTimeout();
		const target = this.updateCurrentQuestion({answerState, duration});

		const data = FormDataBuilder.BuildParameters({
			questionId: target.questionID,
			answerState: target.answerState,
			duration: target.duration,
			comment: target.comment,
			oldAnswerState: target.oldAnswerState,
			currentQuestionIndex: index,
			testId: this.testingModel.general.testID,
			sessionKey: this.guid,
			studentId: this.testingModel.general.studentID,
			sessionType: this.options.sessionType,
			cacheAvailable: this.cacheAvailable,
		});

		const isESGI25518 = getUser()?.districtID === 29498; //See https://its.velvetech.com/browse/ESGI-25518

		return submitAnswer(this.httpClient, data, isESGI25518)
			.pipe(
				tap((resp: any) => {
					this.lastAnsweredQuestionIdx = index;
					if (resp.testSessionStatus) {
						this.testSessionStatus.next(mapToEnum(resp.testSessionStatus, TestSessionStatus));
					} else {
						this.cacheAvailable = resp.cacheAvailable;
					}
				}),
				tap({
					complete: () => this.completeLongRequest(requestTimeout),
				})
			);
	}

	public finish(): Observable<Summary> {
		const requestTimeout = this.createSnackbarTimeout();
		return this.httpClient.ESGIApi.post<{isSkipped: boolean, testSessionStatus: string, testSessionID: number}>(
			this.controller,
			'end-test',
			this.finishModel,
		).withCustomErrorHandler((error, strategy) => {
			if ([0, 400].includes(error.status)) {
				strategy.stopPropagation();
				this.onLostConnection.next();
			}
		}).pipe(
			tap((resp) => this.testSessionStatus.next(
				mapToEnum(resp.testSessionStatus, TestSessionStatus)),
			),
			map((resp) => {
				const questions = this.questions.value;
				const summary = {
					testSessionID: resp.testSessionID,
					correct: questions.filter(q => q.answerState === AnswerState.Correct).length,
					incorrect: questions.filter(q => q.answerState === AnswerState.Incorrect).length,
					total: questions.length,
				} as Summary;

				this.shareSyncService.syncSummary(
					summary.total,
					summary.correct,
					this.testingModel.showTestSessionResults,
					this.testingModel.testResultsCorrectVerbiage,
					this.testingModel.testResultsIncorrectVerbiage,
				);

				return summary;
			}),
		).asObservable().pipe(tap({
			complete: () => this.completeLongRequest(requestTimeout),
		}));
	}

	private createSnackbarTimeout = () => setTimeout(() => {
		this.hasLongRequest.next(true);
		showSnackbarNotification(
			`
			We apologize for the delay.
			Your request is taking longer than expected.
			Please wait a moment or refresh the page.
			`,
			{timeToLive: 10000, vPos: 'top'},
		);
	}, 3000);

	private completeLongRequest = (requestTimeout: ReturnType<typeof setTimeout>) => {
		this.hasLongRequest.next(false);
		clearTimeout(requestTimeout);
	};

	private updateCurrentQuestion(change: Partial<QuestionModel>): QuestionModel {
		let target = this.questions.value[this.currentQuestionIdx.value];
		this.questions.next([...this.questions.value.map(q => {
			if (q === target) {
				target = mix(target, change);
				return target;
			} else {
				return q;
			}
		})]);
		return target;
	}

	private getLastAnsweredQuestionID(questions: QuestionModel[]): number | null {
		if (this.currentQuestionIdx.value !== this.lastAnsweredQuestionIdx) {
			return questions[this.lastAnsweredQuestionIdx]?.questionID;
		}

		if (!this.options.resume) {
			return null;
		}

		return last(questions).questionID;
	}
}
