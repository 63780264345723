import {ControlValue, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {DropdownInModel} from '..//types';

export type UserInfoProfileFormType = FormGroup<{
	firstName: FormControl<string>,
	lastName: FormControl<string>,
	country: FormControl<DropdownInModel[]>,
	state: FormControl<DropdownInModel[]>,
	title: FormControl<DropdownInModel[]>,
	email: FormControl<string>,
	gradeLevels: FormControl<DropdownInModel[]>
},
	ControlValue<{
		firstName: FormControl<string>,
		lastName: FormControl<string>,
		country: FormControl<DropdownInModel[]>,
		state: FormControl<DropdownInModel[]>,
		title: FormControl<DropdownInModel[]>,
		email: FormControl<string>,
		gradeLevels: FormControl<DropdownInModel[]>
	}>>

export const createUserInfoForm = ():
	UserInfoProfileFormType => new FormGroup({
	title: new FormControl([]),
	firstName: new FormControl('', {validators: [Validators.required()]}),
	lastName: new FormControl('', {validators: [Validators.required()]}),
	state: new FormControl([]),
	country: new FormControl([]),
	email: new FormControl('', {validators: [Validators.required(), Validators.email()]}),
	gradeLevels: new FormControl([]),
});
