import React from 'react';
import styles from './styles.module.less';
import {Init, Student} from '../../../../../services/results-service/models';
import {join} from '@esgillc/ui-kit/utils';
import {testResultsCorrectVerbiage, testResultsIncorrectVerbiage} from '../../../../../utils';

interface Props {
	model: Init
	student: Student
}

export function ResultsTable({model, student}: Props) {
	const translation = model.translations[student.languageID];
	return <table className={styles.testResults}>
		<thead>
		<tr>
			<th className={styles.textLeft}>{translation.testName}</th>
			<th className={styles.textRight}>
				{model.showSessionDate &&
					<span>{translation.testSessionDate}</span>
				}
			</th>
			{model.hasScales && <>
				<th className={styles.leftBorder}>{translation.grade}</th>
			</>}
		</tr>
		</thead>
		<tbody>
		{student.testResults.map(testResult => <>
			<tr className={styles.topBorder}>
				<td className={styles.textLeft}>
					<strong>{testResult.testName} </strong>
					{testResult.testType === 'YN' && <>
						<span> ({testResult.correctAnswers.length} {translation.outOf} {testResult.questionsTotal})</span>
					</>}
				</td>
				<td className={styles.textRight}>
					<span>{model.showSessionDate && testResult.testDateString}</span>
				</td>
				{model.hasScales &&
					<td className={join(styles.leftBorder, styles.textCenter)}
					    style={{backgroundColor: testResult.hasGradeRange ? testResult.gradeScale.color : ''}}>
						{testResult.hasGradeRange &&
							<strong>{testResult.gradeScale.name}</strong>}
					</td>}
			</tr>
			<tr>
				<td className={join(styles.textLeft, model.hasScales ? styles.hasScales : styles.noScales)}>
					<p className={styles.cellTextHeader}>{testResultsCorrectVerbiage(testResult, model.testResultsCorrectVerbiage, translation)}</p>
					<p className={join(styles.cellText, testResult.testType === 'YN' && styles.textLeft, testResult.testType !== 'YN' && styles.textCenter)}
					   dangerouslySetInnerHTML={{__html: testResult.correctAnswersString}}/>
				</td>
				<td className={join(styles.textRight, model.hasScales ? styles.hasScales : styles.noScales)}>
					<p className={styles.cellTextHeader}>{testResultsIncorrectVerbiage(testResult, model.testResultsIncorrectVerbiage, translation)}</p>
					<p className={join(styles.cellText, testResult.testType === 'YN' && styles.textLeft, testResult.testType !== 'YN' && styles.textCenter)}
					   dangerouslySetInnerHTML={{__html: testResult.incorrectAnswersString}}/>
				</td>
				{model.hasScales && <>
					<td className={styles.leftBorder} style={{borderBottom: 'none'}}/>
				</>}
			</tr>
			{testResult.notesText && <>
				<tr>
					<td colSpan={2}>
						<p className={styles.cellTextHeader}>{translation.notes}</p>
						<p className={join(styles.cellText, styles.textLeft)}
						   dangerouslySetInnerHTML={{__html: testResult.notesText}}/>
					</td>
					{model.hasScales && <>
						<td className={join(styles.leftBorder, styles.noVerticalBorder)}/>
					</>}
				</tr>
			</>}
			{testResult.summaryNotes && <>
				<tr>
					<td colSpan={2}>
						<p className={styles.cellTextHeader}>{translation.summary}</p>
						<p className={join(styles.cellText, styles.textLeft)}>{testResult.summaryNotes}</p>
					</td>
					{model.hasScales && <>
						<td className={join(styles.leftBorder, styles.noVerticalBorder)}/>
					</>}
				</tr>
			</>}
		</>)}
		</tbody>
	</table>;
}
