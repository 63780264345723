import React from 'react';
import {userStorage} from '@esgi/core/authentication';
import {ImageUrlBuilder, createQuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {QuestionModel} from '../../services/search-service';
import {imageQueueService} from 'pages/test-explorer/services/image-queue-service';

class Props {
	testId: number;
	distance: number;
	model: QuestionModel;
}

export default class QuestionSliderItem extends React.PureComponent<Props> {
	private currentUser = userStorage.get();
	private urlBuilder: ImageUrlBuilder;
	private img: HTMLImageElement;
	private imageLoadingErrorTimes = 0;
	private readonly notAvailableSrc = 'https://esgiwebfiles.s3.amazonaws.com/images/img-not-available.jpg';

	constructor(props, context) {
		super(props, context);

		this.urlBuilder = createQuestionImageUrlBuilder();
	}

	public get url() {
		if (Math.abs(this.props.distance) <= 1) {
			return this.urlBuilder.medium(this.props.model.questionID, this.props.testId, this.props.model.pregenerated, this.props.model.ticks);
		}

		return this.notAvailableSrc;
	}

	public render() {
		return <img ref={r => this.img = r} width={221} height={100} src={this.url}
		            onError={() => this.imageLoadingError()} loading='lazy'/>;
	}

	private updateImage = (url: string) => {
		imageQueueService.addToQueue(url, this.img);
	};

	private imageLoadingError() {
		if (this.imageLoadingErrorTimes == 0) {
			this.updateImage(this.urlBuilder.medium(this.props.model.questionID, this.props.testId, false, this.props.model.ticks));
		} else {
			this.img.src = this.notAvailableSrc;
		}

		this.imageLoadingErrorTimes++;
	}
}
