import {ConferencerDate} from 'pages/parent-conferencer/models/models';
import React from 'react';
import moment from 'moment';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {DatePicker} from '@esgi/deprecated/elements';
import {Button, Primary} from '@esgi/deprecated/elements/buttons';

export type InitialState = { data?: ConferencerDate, defaultDate?: string };

class State {
	dateID: number;
	date: string;
	dateIsValid: boolean = true;
	error: string;

	constructor(initialState: InitialState = {}) {
		const {data, defaultDate} = initialState;
		this.dateID = data ? data.conferencerDateID : 0;
		this.date = data ? data.date : defaultDate ? defaultDate : moment().format('YYYY-MM-DDTHH:mm:ss');
	}
}

class Props {
	initialState: InitialState;
	delete: (id: number) => void;
	close: () => void;
	process: (data: ConferencerDate) => string;
}

export class EditDate extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = new State(props.initialState);
	}

	confirm = () => {
		let data: ConferencerDate = {
			conferencerDateID: this.state.dateID,
			date: moment(this.state.date).format('YYYY-MM-DDTHH:mm:ss'),
		};

		let result = this.props.process(data);
		if (result) {
			this.setState({error: result});
		} else {
			this.props.close();
		}
	};

	render() {
		return <Modal className='edit_datetime_modal'>
			<ModalHeader>
			</ModalHeader>
			<ModalBody>
				<div className='edit_date'>
					<DatePicker
						id='datepicker'
						date={moment(this.state.date)}
						validator={(value) => {
							return {
								valid: value.isValid(),
								message: 'Please enter a date.',
							};
						}}
						validationPlacement='left'
						onSelect={(d) => {
							this.setState({
								error: null,
								dateIsValid: d.isValid(),
								date: moment(d).format('YYYY-MM-DDTHH:mm:ss'),
							});
						}}
					/>
					{this.state.error &&
					<div className='error'>{this.state.error}</div>
					}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => this.props.close()}
						title='Cancel'
						className='cancel-button'
					/>
					{this.state.dateID > 0 &&
					<Button
						onClick={() => {
							this.props.delete(this.state.dateID);
							this.props.close();
						}}
						title='Delete'
						className='btn-danger delete-button'
					/>
					}
					<Primary
						onClick={() => this.confirm()}
						title='Confirm'
						disabled={!this.state.dateIsValid}
						className='confirm-button'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
