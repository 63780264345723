import React, {Suspense} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {RouteWrapper} from 'root/route-wrapper';
import DefaultRouteFallback from '../default-route-fallback';

const LoginPage = lazyComponent(() => import('pages/landing/login'));

export default function Login() {
	return <RouteWrapper title='Login'>
		<Suspense fallback={<DefaultRouteFallback/>}>
			<LoginPage/>
		</Suspense>
	</RouteWrapper>;
}
