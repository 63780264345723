import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button, Tooltip} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {environment} from '@esgi/core/environments';
import {StudentModel} from '../../types';
import {Dispatch, useCallback, useMemo, useState} from 'react';
import {AlertBody, Checkbox, DistrictCheap, Link} from './index.styled';

type Props = {
	onAlertClose: VoidFunction;
	assignmentName: string;
	userDistrict: number;
	studentsInAssignment: StudentModel[];
	onDownloadStudentsCredentials: (studentIDs: StudentModel['studentID'][]) => void;
	onUpdateSHowAgainValue: Dispatch<boolean>;
};

export function StudentInstructionsAlert({
	onAlertClose,
	assignmentName,
	userDistrict,
	studentsInAssignment,
	onDownloadStudentsCredentials,
	onUpdateSHowAgainValue,
}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onAlertClose);

	const [isShowAgain, setIsShowAgain] = useState(false);

	const studentsIDsWithCredentials = useMemo(
		() => studentsInAssignment.filter(({hasCredentials}) => Boolean(hasCredentials)).map(({studentID}) => studentID),
		[studentsInAssignment],
	);

	const toggleIsShowAgain = useCallback(() => {
		setIsShowAgain((isChecked) => {
			const newValue = !isChecked;
			onUpdateSHowAgainValue(newValue);

			return newValue;
		});
	}, [onUpdateSHowAgainValue]);

	const downloadStudentsCards = useCallback(() => {
		onDownloadStudentsCredentials(studentsIDsWithCredentials);
	}, [onDownloadStudentsCredentials, studentsIDsWithCredentials]);

	const isDownloadDisabled = !studentsIDsWithCredentials.length;

	return (
		<Alert modalManagerRef={alertRef}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative' data-cy='instructions-title'>
					Instructions for Student Assess
				</Text>
			</Alert.Header>
			<AlertBody>
				<GridBox gap='1' flow='column' justify='start' dataCy='assigment-name'>
					<Text size='medium' color='neutral40'>
						Assignment:
					</Text>
					<Text size='medium' color='base'>
						{assignmentName}
					</Text>
				</GridBox>

				<GridBox gap='3'>
					<Text size='large' color='neutral40' data-cy='steps-to-begin-title'>
						Steps to begin assignment on student devices:
					</Text>

					<GridBox gap='1' flow='column' justify='start' dataCy='first-point'>
						<Text size='medium' color='neutral40'>
							1.
						</Text>
						<Tooltip open={!isDownloadDisabled ? false : undefined}>
							<Tooltip.Trigger>
								<Button.Text disabled={isDownloadDisabled} onClick={downloadStudentsCards}>
									<Text size='medium'>Download</Text>
								</Button.Text>
							</Tooltip.Trigger>
							<Tooltip.Content>
								<Text size='small'>Please add student credentials to print cards.</Text>
							</Tooltip.Content>
						</Tooltip>
						<Text size='medium' color='neutral40'>
							student sign in cards with students user name and password.
						</Text>
					</GridBox>

					<Text size='medium' color='neutral40' data-cy='second-point'>
						2. Go to{' '}
						<Link to={`${environment.url}/student/`} target='_blank' rel='noopener noreferrer'>
							www.esgiselfassess.com
						</Link>
					</Text>

					<GridBox gap='1' flow='column' justify='start' align='center' dataCy='third-point'>
						<Text size='medium' color='neutral40'>
							3. Enter District ID:
						</Text>
						<DistrictCheap>
							<Text size='medium' color='primary'>
								{userDistrict}
							</Text>
						</DistrictCheap>
					</GridBox>

					<Text size='medium' color='neutral40' data-cy='fourth-point'>
						4. Enter student username and password.
					</Text>
				</GridBox>

				<Checkbox value='id' label='Don’t show this again.' checked={isShowAgain} onCheckedChange={toggleIsShowAgain} dataCy='dont-show-checkbox'/>
			</AlertBody>
			<Alert.Footer>
				<Button color='secondary' onClick={closeAlert} dataCy='got-it-button'>
					<Text size='medium' bold color='base'>
						GOT IT
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
