import './style.less';
import React from 'react';
import {Chip} from './chip';

export class Props {
	items: ChipItem[];
	editable?: boolean = false;
	onChipClicked: (e: ChipItem) => any;
	onChipDeleteClicked: (e: ChipItem) => any;
}

export class ChipsInput extends React.PureComponent<Props> {
	render() {
		return <div className='chips-container'>
			{this.props.items.map(t =>
				<Chip id={t.id}
				                text={t.text}
				                editable={this.props.editable}
				                onClick={() => this.props.onChipClicked(t)}
				                deleteClicked={() => this.props.onChipDeleteClicked(t)}
				                key={t.text}/>)}
		</div>;
	}
}

export interface ChipItem {
	id: number,
	text: string
}