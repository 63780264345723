import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Link, AlertBody, AlertHeader} from './index.styled';
import {Button, Info} from '@esgi/ui';

type Props = {
	close: () => void;
	schoolYearName: string;
}

export function InvalidTrackAlert(props: Props){
	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, props.close);

	return (
		<Alert
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.negative}>
			<Alert.Header onCloseIconClick={onClose}>
				<AlertHeader>
					<Text size='xLarge' color='negative'>
						The First Marking Period Has Not Yet Started.
					</Text>
					<Link to='https://support.esgisoftware.com/hc/en-us/articles/209159566-Changing-Dates' target='_blank' rel='noreferrer'>
						<Info width={24} height={24}/>
					</Link>
				</AlertHeader>
			</Alert.Header>
			<AlertBody>
				<Text size='medium'>
					To view this report, please edit your {props.schoolYearName} marking period dates by selecting Add/Edit Track from your Account page, or select a previous <Link to='https://support.esgisoftware.com/hc/en-us/articles/209158766-Change-School-Year'
								target='_blank' rel='noreferrer'>
						school year
						<Info width={24} height={24}/>
					</Link> in the left panel.
				</Text>
			</AlertBody>
			<Alert.Footer>
				<Button color='primary' onClick={() => onClose()}>
					<Text size='large' bold>GOT IT</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}