import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';

export function StudentsList() {
	const {students} = StudentsPanel.Tabs.Students.useStudentsContext();
	const {
		toggleStudentId: selectStudent,
		selected: {studentIDs: selectedStudentsIDs},
	} = StudentsPanel.Selection.MultiSelect.useMultiSelectContext();

	return (
		<StudentsPanel.Tabs.TabContent>
			{students.length ? (
				<StudentsPanel.Lists.SelectableList.Multiple
					items={students}
					onItemClick={(groupId) => selectStudent(groupId)}
					value={selectedStudentsIDs}
				>
					{({item: {firstName, lastName}}) => (
						<StudentsPanel.StudentFullName size='medium' color='neutral6' firstName={firstName} lastName={lastName} />
					)}
				</StudentsPanel.Lists.SelectableList.Multiple>
			) : (
				<StudentsPanel.Tabs.EmptyTabContent size='full' templateText='No students to display' />
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
