import ItemAnalysisReportService from '../../services/item-analysis-report-service';
import {HighchartsReact} from '@esgi/deprecated/highcharts';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {useEventEffect, dispatchAppEvent} from '@esgillc/events';
import {Loader} from '@esgillc/ui-kit/loader';
import {useEffect, useState, useMemo} from 'react';
import {getChartOptions} from '../../utils';
import {ChartOptions, ReportData} from '../../models';
import {ChartRenderedEvent, ChartPagesReadyEvent} from '../../events';
import {debounce} from 'underscore';
import styles from '../../item-analysis-report.module.less';

interface Props {
	service: ItemAnalysisReportService;
}
export function ItemAnalysisHighchartPdf({service}: Props) {
	const pageQuestions = 30;
	const [page, setPage] = useState(-1);
	const [chartOptions, setChartOptions] = useState<any>(null);
	const sortOptionMode = useBehaviorSubject<string>(service.sortOptionMode);
	const stackIncorrect = useBehaviorSubject<boolean>(service.displayNotTestedAsIncorrect);
	const testResultsCorrectVerbiage = useBehaviorSubject<string>(service.testResultsCorrectVerbiage);
	const testResultsIncorrectVerbiage = useBehaviorSubject<string>(service.testResultsIncorrectVerbiage);
	const userLevel = service.level;
	const reportData = useBehaviorSubject<ReportData>(service.reportData);
	const downloadingPdf = useBehaviorSubject<boolean>(service.downloadingPdf);
	const maxPage = useMemo(
		() => Math.floor(reportData.questions.length / pageQuestions),
		[reportData],
	);
	const min = useMemo(
		() => {
			const count = page * pageQuestions;
			if (count < 0) {
				return 0;
			}
			return count;
		},
		[page],
	);
	const max = useMemo(
		() => {
			const count = (page + 1) * pageQuestions;
			if (count > reportData.questions.length) {
				return reportData.questions.length;
			}
			return count;
		},
		[page],
	);

	useEffect(
		() =>{
			setPage(downloadingPdf ? 0 : -1);
			if (!downloadingPdf) {
				service.setPages([]);
			}
		},
		[downloadingPdf],
	);

	useEffect(() => {
		if (page < 0) {
			return;
		}
		const chartSize = calcChartSize();
		const chartOptions: ChartOptions = {
			chartSize,
			testName: '',
			reportData,
			sortOptionMode,
			userLevel,
			stackIncorrect,
			testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage,
			selectPoint: () => {},
			updateScrollbarDisplay: () => {},
			normalizeChart: () => {},
			updateTitleSize: () => {},
			clone: true,
		};
		setChartOptions(getChartOptions(chartOptions));
	}, [page]);

	useEventEffect(ChartRenderedEvent, debounce(() => {
		if (!downloadingPdf) {
			return;
		}
		const pages = service.getPages();
		const scrollbar: SVGGElement = document.querySelector('#iar-chart .highcharts-scrollbar');
		if (scrollbar && scrollbar.style.display === 'none') {
			const html = document.querySelector('#iar-chart .highcharts-container')?.innerHTML;
			pages[page] = html;
			service.setPages(pages);
			dispatchAppEvent(ChartPagesReadyEvent, new ChartPagesReadyEvent());
			return;
		}
		const html = document.querySelector('#pdf .highcharts-container')?.innerHTML;
		pages[page] = html;
		service.setPages(pages);
		if (page < maxPage) {
			setTimeout(() => setPage(page + 1), 200);
		} else {
			dispatchAppEvent(ChartPagesReadyEvent, new ChartPagesReadyEvent());
		}
	}, 600));

	const calcChartSize = () => {
		let width = 1200;
		const height = 480;
		const questions = max - min;
		if (questions < pageQuestions) {
			width = (questions * 30) + (questions * 10 + 20) + 120;
			if (width > 1200) {
				width = 1200;
			}
		}
		return {width, height, min, max};
	};

	return (
		<div
			id='pdf'
			className={styles.clone}
		>
			{chartOptions &&
				<HighchartsReact options={chartOptions}/>
			}
			<Loader show={downloadingPdf} fullscreen/>
		</div>
	);
}
