import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, combineLatest, forkJoin, merge, Observable} from 'rxjs';
import {FormState} from 'shared/modules/grade-scale/models';
import {Api as SharedApi} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/api';
import {Api as CustomApi} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/api';
import {CustomGradeRangeService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/custom/service';
import {SharedGradeRangeService} from 'shared/modules/grade-scale/grade-range/forms/custom-shared/shared/service';
import {map} from 'rxjs/operators';
import {Item} from '@esgi/deprecated/elements/custom-dropdown/dropdown';
import {WizardStep} from 'shared/modules/grade-scale/wizard/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export class CustomSharedService extends BaseService {
	public customGradeRangeService: CustomGradeRangeService;
	public sharedGradeRangeService: SharedGradeRangeService;
	private readonly showNotCustomizedAllTestsAlert: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private formState: FormState;
	private step: WizardStep;
	
	constructor(private setUpGradeScaleMode: boolean, hierarchy: HierarchySnapshot) {
		super();
		this.customGradeRangeService = new CustomGradeRangeService(setUpGradeScaleMode, hierarchy);
		this.sharedGradeRangeService = new SharedGradeRangeService(setUpGradeScaleMode, hierarchy);

		this.completeOnDestroy(combineLatest([
			this.formState$,
			this.customGradeRangeService.toSaveGradeRanges$,
			this.sharedGradeRangeService.toSaveTests$,
			this.countTestsInSubject])).subscribe(data => {
			const formState = data[0];
			const toSaveCustomGradeRanges = data[1];
			const toSaveSharedTests = data[2];
			const countTestsInSubject = data[3];

			this.formState = formState;
			
			if (formState === FormState.Valid) {
				const calls = [];
				let savedEntitiesCount = 0;
				
				if (toSaveCustomGradeRanges.length > 0) {
					const toSaveEntitiesWithoutShared = toSaveCustomGradeRanges
						.filter(grs => !toSaveSharedTests.map(t => t.testID).includes(grs.testID));
					
					savedEntitiesCount += toSaveEntitiesWithoutShared.length;
					
					const toSaveEntitiesWithCorrectDefaults = toSaveEntitiesWithoutShared
						.filter(e => !e.applyDefaultRange || (e.applyDefaultRange && !e.defaultRangeApplied));
					
					if (toSaveEntitiesWithCorrectDefaults.length > 0) {
						calls.push(CustomApi.save(toSaveEntitiesWithCorrectDefaults).withCustomErrorHandler(
							() => this.setFormState(FormState.None)));
					}
				}
				
				if(toSaveSharedTests.length > 0) {
					savedEntitiesCount += toSaveSharedTests.length;
					
					const toSaveEntitiesWithCorrectDefaults = toSaveSharedTests
						.filter(e => !e.applyDefaultRange || (e.applyDefaultRange && !e.defaultRangeApplied));
					
					if (toSaveEntitiesWithCorrectDefaults.length > 0){
						calls.push(SharedApi.save(toSaveEntitiesWithCorrectDefaults).withCustomErrorHandler(
							() => this.setFormState(FormState.None)));	
					}
				}

				if (this.setUpGradeScaleMode
					&& savedEntitiesCount < countTestsInSubject
					&& this.showNotCustomizedAllTestsAlert.value === false) {
					this.showNotCustomizedAllTestsAlert.next(true);
					this.setFormState(FormState.None);
					return;
				}

				if (calls.length === 0){
					this.setFormState(FormState.Saved);
				} else {
					forkJoin(calls).subscribe(() => {
						this.setFormState(FormState.Saved);
					});	
				}
			}
		});
	}

	public get selectedSubject$(): Observable<Item> {
		return this.completeOnDestroy(merge(
			this.customGradeRangeService.selectedSubject$,
			this.sharedGradeRangeService.selectedSubject$,
		));
	}
	
	private get countTestsInSubject(): Observable<number> {
		return this.completeOnDestroy(merge(
			this.customGradeRangeService.dropdownTests$,
			this.sharedGradeRangeService.dropdownTests$,
		).pipe(map(tests => {
			return tests.length;
		})));
	}
	
	public get formState$(): Observable<FormState> {
		return this.completeOnDestroy(merge(
			this.customGradeRangeService.formState$,
			this.sharedGradeRangeService.formState$,
		));
	}
	
	public showNotCustomizedAllTestsAlert$: Observable<boolean> = this.completeOnDestroy(this.showNotCustomizedAllTestsAlert);

	public saveNotCustomizedAllTests(needSave: boolean) {
		this.showNotCustomizedAllTestsAlert.next(needSave);
		
		if (needSave) {
			if (this.formState !== FormState.Valid){
				this.setFormState(FormState.Valid);
			}
		} else {
			this.setFormState(FormState.None);
		}
	}

	public setStep(step: WizardStep) {
		this.step = step;
	}
	
	public setFormState(formState: FormState) {
		if (this.step === WizardStep.CustomGradeRange) {
			this.customGradeRangeService.setFormState(formState);
		}

		if (this.step === WizardStep.SharedGradeRange) {
			this.sharedGradeRangeService.setFormState(formState);
		}
	}
	
	destroy() {
		this.customGradeRangeService.destroy();
		this.sharedGradeRangeService.destroy();
		super.destroy();
	}
}
