import {HierarchyMode} from 'modules/hierarchy/core/models';
import {ChangesSnapshot, HierarchySnapshot} from 'modules/hierarchy/models';


export function applyChangesToHierarchy(changesSnapshot: ChangesSnapshot, hierarchy: HierarchySnapshot): HierarchySnapshot {
	const h = {...hierarchy};
	h.classic = {...h.classic};
	h.specialist = {...h.specialist};
	h.specialist.filter = {...h.specialist.filter};

	if (changesSnapshot.classID !== undefined) {
		h.classic.classID = changesSnapshot.classID;
		h.classic.groupID = 0;
		h.classic.studentID = 0;
	}

	if (changesSnapshot.groupID !== undefined) {
		if (h.mode === HierarchyMode.Classic) {
			h.classic.groupID = changesSnapshot.groupID;
			h.classic.studentID = 0;

		} else {
			h.specialist.groupID = changesSnapshot.groupID;
			h.specialist.studentID = 0;
		}
	}

	if (changesSnapshot.specialistGroupID !== undefined) {
		h.specialist.groupID = changesSnapshot.specialistGroupID;
	}

	if (changesSnapshot.studentID !== undefined) {
		if (h.mode === HierarchyMode.Classic) {
			h.classic.studentID = changesSnapshot.studentID;
		} else {
			h.specialist.studentID = changesSnapshot.studentID;
		}
	}

	if (changesSnapshot.schoolID !== undefined) {
		h.classic.schoolID = changesSnapshot.schoolID;
	}

	if (changesSnapshot.teacherID !== undefined) {
		h.classic.teacherID = changesSnapshot.teacherID;
	}

	return h;
}
