import {Renderable, RenderableFunction} from '@esgillc/ui-kit/core';
import {FlexBox} from '@esgillc/ui-kit/layout';
import {join} from '@esgillc/ui-kit/utils';
import styles from './settings.module.less';
import {ReactNode} from 'react';

class Props {
	className?: string;
	children?: ReactNode;
}

export default function Settings(props: Props): JSX.Element {
	const className = join(styles.box, props.className);

	return <FlexBox direction='column' className={className}>
		{props.children}
	</FlexBox>;
}
