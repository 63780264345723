import {Alert} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';

interface Props {
	onClose: () => void,
	isOpen: boolean,
	onApprove: () => void
}

export function RemoveDialog({onClose, isOpen, onApprove}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);

	if (!isOpen) {
		return null;
	}

	return <div data-cy='remove-dialog'>
		<Alert modalManagerRef={modalRef}>
			<Alert.Body>
				Are you sure you want to delete this class?
				This will NOT delete students and their data. The students still will be available, and can be added to
				another class.
			</Alert.Body>
			<Alert.Footer>
				<Buttons.Text onClick={handleClose}>
					CANCEL
				</Buttons.Text>
				<Buttons.Text onClick={() => {
					onApprove();
					handleClose();
				}
				}>
					OK
				</Buttons.Text>
			</Alert.Footer>
		</Alert>
	</div>;
}
