import {PreviewDialog} from './components/preview-dialog';
import {SettingsControl} from './components/settings-control';
import {GradesControl} from './components/grades-control';
import {NotTestedControl} from './components/not-tested-control';
import {OutputControl} from './components/output-control';
import {MarkingPeriodControl} from './components/marking-period-control';

export * from './services';
export * from './hooks';
export * from './types';

export const StudentDetail = {
	PreviewDialog,
	SettingsControl,
	GradesControl,
	NotTestedControl,
	OutputControl,
	MarkingPeriodControl,
};
