import {useCallback} from 'react';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {Class, Group, Student, SubjectType} from '@esgi/main/libs/store';
import {StudentDashboardSearchKey} from '../layout/types';
import {NavigationState, TestDashboardSectionID} from '../types';

type CommonPathnameParams = {
	testID: number;
	subjectID: number;
	subjectType: SubjectType;
	goToSection?: TestDashboardSectionID;
};

type NavigateArguments = CommonPathnameParams &
	(
		| {
				dashboardType: 'student';
				studentID: Student['id'];
				studentFromClassID?: Class['id'] | null;
				studentFromGroupID?: Group['id'] | null;
		  }
		| {
				dashboardType: 'class';
				classID: Class['id'];
		  }
		| {
				dashboardType: 'group';
				groupID: Group['id'];
		  }
	);

export function useNavigateToTestDashboard() {
	const navigate = useNavigate();

	const getTestDashboardPathname = useCallback(
		({
			subjectID,
			testID,
			subjectType,
			entityId,
			entityType,
		}: CommonPathnameParams & {entityType: string; entityId: string}) =>
			`/home/test-dashboard/${testID}/subject/${subjectType}/${subjectID}/${entityType}/${entityId}`,
		[],
	);

	return useCallback(
		({subjectID, testID, subjectType, goToSection, ...restArgs}: NavigateArguments) => {
			const state: NavigationState = {
				initVisibleSection: goToSection ?? null,
			};

			if (restArgs.dashboardType === 'student') {
				const {studentID, studentFromClassID, studentFromGroupID, dashboardType} = restArgs;

				const searchParams = [
					[StudentDashboardSearchKey.StudentFromClassID, studentFromClassID && String(studentFromClassID)],
					[StudentDashboardSearchKey.StudentFromGroupID, studentFromGroupID && String(studentFromGroupID)],
				].filter((param): param is [StudentDashboardSearchKey, string] => typeof param[1] === 'string');

				navigate(
					{
						pathname: getTestDashboardPathname({
							subjectID,
							subjectType,
							testID,
							entityType: dashboardType,
							entityId: String(studentID),
						}),
						search: `?${createSearchParams(searchParams)}`,
					},
					{
						state,
					},
				);

				return;
			}

			if (restArgs.dashboardType === 'class') {
				const {classID, dashboardType} = restArgs;

				navigate(
					getTestDashboardPathname({
						subjectID,
						subjectType,
						testID,
						entityType: dashboardType,
						entityId: String(classID),
					}),
					{
						state,
					},
				);

				return;
			}

			if (restArgs.dashboardType === 'group') {
				const {groupID, dashboardType} = restArgs;

				navigate(
					getTestDashboardPathname({
						subjectID,
						subjectType,
						testID,
						entityType: dashboardType,
						entityId: String(groupID),
					}),
					{
						state,
					},
				);

				return;
			}

			throw new Error('prop "dashboardType" is not matched');
		},
		[getTestDashboardPathname, navigate],
	);
}
