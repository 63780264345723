import {TestContentArea} from '@esgi/main/kits/common';

export const testContentAreaTranscript: Record<TestContentArea, string> = {
	[TestContentArea.LanguageArts]: 'Language Arts',
	[TestContentArea.Science]: 'Science',
	[TestContentArea.SocialStudies]: 'Social Studies',
	[TestContentArea.Math]: 'Math',
	[TestContentArea.Spanish]: 'Spanish',
	[TestContentArea.SEL]: 'SEL',
	[TestContentArea.Other]: 'Other',
};
