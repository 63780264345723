import React, {ReactNode, useState} from 'react';
import {join} from '@esgillc/ui-kit/utils';
import {MenuItem} from '@esgillc/ui-kit/menu';

import styles from './multi-menu-item.module.less';

interface Props {
	title: string;
	children?: ReactNode;
	className?: string
	onHover?: (target: EventTarget) => void;
}

export function MultiLevelItem({children, className, title, onHover}: Props) {
	const [showContent, setShowContent] = useState(false);
	return (
		<MenuItem
			className={join(className, styles.menuItem)}
			onMouseEnter={(e) => {
				setShowContent(true);
				onHover?.(e.target);
			}}
			onMouseLeave={() => setShowContent(false)}
		>
			<i className={join('fa fa-caret-left', styles.icon)} />{title}
			<div className={join(styles.subLevel, !showContent && styles.hidden)}>{children}</div>
		</MenuItem>
	);
}
