import {Observable, tap} from 'rxjs';
import {uniq} from 'underscore';
import {deepCopy} from 'shared/utils';
import {SortBy} from '../kit';
import {StudentCredentialsService} from './student-credentials-service';
import {FilterSpecialistService} from './filter-specialist-service';
import {StudentCredentialsSpecialistInitResponse} from '../types/specialist-types';

export class StudentCredentialsSpecialistService extends StudentCredentialsService<FilterSpecialistService> {
	private readonly studentCredentialsTeacherController = 'assignments/student-credentials/specialist';

	public init(): Observable<StudentCredentialsSpecialistInitResponse> {
		return this.httpClient.ESGIApi.get<StudentCredentialsSpecialistInitResponse>(
			this.studentCredentialsTeacherController,
			'init',
		)
			.pipe(
				tap((response) => {
					const groups = response.groups;
					const students = uniq(groups.map(({students}) => students).flat(), ({id}) => id);
					const studentsIds = students.map(({id}) => id);

					this.filterService.tableData$.next(deepCopy(students));
					this.filterService.allTableData$.next(deepCopy(students));

					this.filterService.allStudentsIds$.next(studentsIds);
					this.filterService.groups$.next(groups);

					this.filterService.setSortByValue('name', SortBy.Asc);
				}),
			)
			.asObservable();
	}
}
