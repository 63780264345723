import React from 'react';
import moment, {Moment} from 'moment';
import {ValidationTooltip} from '@esgillc/ui-kit/tooltip';
import {ConferencerTime} from 'pages/parent-conferencer/models/models';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@esgi/deprecated/react';
import {Dropdown} from '@esgi/deprecated/elements/dropdown';
import {Primary, Button} from '@esgi/deprecated/elements/buttons';

export type InitialState = { time?: ConferencerTime, defaultTime?: string };

class State {
	timeID: number;
	hours: number;
	minutes: number;
	am: boolean;
	error: string;

	constructor(initialState: InitialState = {}) {
		const data = initialState.time;
		const defaultTime = initialState.defaultTime;
		
		this.timeID = data ? data.conferencerTimeID : 0;
		let time: Moment;
		if (data) {
			time = moment(data.time);
		} else {
			if (defaultTime) {
				time = moment(defaultTime);
			} else {
				time = moment();
				time.hours(0);
				time.minutes(0);
			}
		}

		const hours = time.hours();

		this.hours = hours % 12;
		this.minutes = time.minutes();
		this.am = hours < 12;
	}
}

class Props {
	initialState: InitialState;
	delete: (id: number) => void;
	close: () => void;
	process: (date: ConferencerTime) => string;
}

export class EditTime extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = new State(props.initialState);
	}

	private containerRef: HTMLDivElement;

	get hours() {
		let hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		return hours.map(x => {
			return {id: x % 12, value: x.toString()};
		});
	}

	get minutes() {
		let minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
		return minutes.map(x => {
			return {id: x, value: (x < 10 ? '0' : '') + x.toString()};
		});
	}

	get periods() {
		return [{id: 0, value: 'AM'}, {id: 1, value: 'PM'}];
	}

	confirm = () => {
		let date = moment();
		date.hours((this.state.am ? 0 : 12) + this.state.hours);
		date.minutes(this.state.minutes);

		let data: ConferencerTime = {
			conferencerTimeID: this.state.timeID,
			time: date.format('YYYY-MM-DDTHH:mm:ss'),
		};

		let result = this.props.process(data);
		if (result) {
			this.setState({error: result});
		} else {
			this.props.close();
		}
	}

	render() {
		return <Modal className='edit_datetime_modal'>
			<ModalHeader>
			</ModalHeader>
			<ModalBody>
				<div ref={(n) => {
					this.containerRef = n;
				}}>
					<div className='edit_time'>
						<Dropdown
							onClick={(t) => {
								this.setState({hours: t.id, error: null});
							}}
							values={this.hours}
							value={this.hours.filter(x => x.id % 12 === this.state.hours % 12)[0]}
							selectClassName='hour-select'
						/>
						<div className='time_delimiter'>:</div>
						<Dropdown
							onClick={(t) => {
								this.setState({minutes: t.id, error: null});
							}}
							values={this.minutes}
							value={this.minutes.filter(x => x.id === this.state.minutes)[0]}
							selectClassName='minute-select'
						/>
						<Dropdown
							onClick={(t) => {
								this.setState({am: !t.id, error: null});
							}}
							values={this.periods}
							value={this.periods.filter(x => x.id === (+!this.state.am))[0]}
							selectClassName='period-select'
						/>
					</div>
					{this.state.error &&
					<ValidationTooltip element={this.containerRef} placement='right' container={this.containerRef}
					                   notHide={true}>
						{this.state.error}
					</ValidationTooltip>}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='footer_buttons'>
					<Button
						onClick={() => this.props.close()}
						title='Cancel'
						className='cancel-button'
					/>
					{this.state.timeID > 0 &&
					<Button
						onClick={() => {
							this.props.delete(this.state.timeID);
							this.props.close();
						}}
						title='Delete'
						className='btn-danger delete-button'
					/>
					}
					<Primary
						onClick={() => this.confirm()}
						title='Confirm'
						className='confirm-button'
					/>
				</div>
			</ModalFooter>
		</Modal>;
	}
}
