import {useEventEffect} from '@esgillc/events';
import {useCallback, useEffect, useState} from 'react';

import {WidgetService} from './service';
import {UpdateWidgetsEvent} from '../events';
import {Observable} from 'rxjs';

type OutModel<T> = {
	loaded: boolean,
	data: T,
}

export function useStudentsData<StudentResponse, ClassResponse>(service: WidgetService<StudentResponse, ClassResponse>, studentID: number, testIDs: number[], classStudentIDs: number[]): OutModel<StudentResponse> {
	const fetch = useCallback((force?: boolean) => {
		if (force) {
			service.clearCache();
		}

		return service.getStudentData({testIDs, studentID, classStudentIDs});
	}, [service, testIDs, studentID, classStudentIDs]);

	return useData(fetch);
}

export function useClassData<StudentResponse, ClassResponse>(service: WidgetService<StudentResponse, ClassResponse>, testIDs: number[], studentIDs: number[]): OutModel<ClassResponse> {
	const fetch = useCallback((force?: boolean) => {
		if (force) {
			service.clearCache();
		}

		return service.getClassData({testIDs, studentIDs});
	}, [service, testIDs, studentIDs]);

	return useData(fetch);
}

function useData<T>(request: (force?: boolean) => Observable<T>): OutModel<T> {
	const [loaded, setLoaded] = useState(false);
	const [data, setData] = useState<T>({} as T);

	const fetchData = useCallback((force?: boolean) => {
		setLoaded(false);
		const sub = request(force).subscribe((r) => {
			setLoaded(true);
			setData(r);
		});
		return () => sub.unsubscribe();
	}, [request]);

	useEffect(() => fetchData(), [fetchData]);

	useEventEffect(UpdateWidgetsEvent, () => fetchData(true), [fetchData]);

	return {
		loaded,
		data,
	};
}