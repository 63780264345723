import {join} from '@esgillc/ui-kit/utils';
import styles from './styles.module.less';

interface Props {
	className?: string;
	children?: any;
}

export function QuestionHeader(props: Props) {
	return <div {...props} className={join(styles.header, props.className)} data-cy='question-header'/>;
}
