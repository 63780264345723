import {BehaviorSubject, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {ReportTest} from '@esgi/main/kits/reports';
import {V2PagesReportsParentLetterController, V2TeachersPagesHomeSectionsTestsController} from '@esgi/contracts/esgi';
import {TestContentArea} from '@esgi/main/kits/common';
import {SubjectTab} from '@esgi/main/libs/store';
import {makeSessionsData} from '../utils';
import {ReportTestSession, TestCard} from '../types';

export class TestService extends BaseService {
	public loading$ = new BehaviorSubject(false);
	public tests$ = new BehaviorSubject<ReportTest[]>([]);
	public defaultSelectedTestsIDs = new BehaviorSubject<number[]>([]);
	private controller = new V2TeachersPagesHomeSectionsTestsController();
	private letterController = new V2PagesReportsParentLetterController();

	public updateTestCardsData = (testID: number, session: ReportTestSession) => {
		const tests = this.tests$.value.map(test => test.id === testID ? ({...test, ...session}) : test);
		this.tests$.next(tests);
	};

	public getTestCardsByStudent = (subject: SubjectTab, studentIDs: number[]) => {
		this.loading$.next(false);

		return this.controller.testCardsByStudents({
			subjectID: subject.id,
			subjectType: subject.type,
			studentIDs,
		}).pipe(tap(({testCards}) => this.getTestCardsWithSessions(testCards, studentIDs)))
			.subscribe(() => this.loading$.next(true));
	};

	public override dispose() {
		this.controller.dispose();
		this.letterController.dispose();
	}

	private getTestCardsWithSessions = (testCards: TestCard[], studentIDs: number[]) => {
		const testIDs = testCards.map(t => t.testInfo.id);

		return this.letterController.init({testIDs, studentIDs}).subscribe(({tests}) => {
			const testCardsValue: ReportTest[] = testCards.map(({testInfo}) => {
				const {id, type, name, contentArea, totalPossible} = testInfo;

				const currentTest = tests.find(t => t.id === id);
				const {sessions, lastTestSession} = makeSessionsData(currentTest?.testSessions ?? [], totalPossible);

				return {
					id,
					name,
					type,
					sessions,
					contentArea: TestContentArea[contentArea],
					maxScore: testInfo.totalPossible,
					isTested: false,
					...lastTestSession,
				};

			});
			const defaultSelectedTestsIDs = testCardsValue.filter(t => t.correct + t.incorrect > 0).map(test => test.id);
			this.defaultSelectedTestsIDs.next(defaultSelectedTestsIDs);
			this.tests$.next(testCardsValue);
		});
	};
}
