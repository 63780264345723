import {PropsWithChildren} from 'react';
import {ElementStatus, useFormElementContext} from '../../index';
import {styled} from '../../../theme';

interface Props extends PropsWithChildren {
	showOnError: 'required' | 'range' | 'email' | 'length-min' | 'length-max' | 'hex' | string;
}

export function ErrorText(props: Props) {
	const context = useFormElementContext({}, ErrorText);
	const error = context.firstError();
	const showError = context.status === ElementStatus.invalid;
	if (!showError) {
		return null;
	}
	return <StyledErrorText showOnError={error === props.showOnError}> {props.children} </StyledErrorText>;
}

const StyledErrorText = styled('div', {
	padding: '2px 12px',
	color: '$negativeVivid',
	fontWeight: '500',
	lineHeight: '12px',
	fontFamily: 'Roboto Mono, Helvetica Neue, sans-serif',
	letterSpacing: 'normal',
	fontSize: '10px',
	justifyContent: 'center',
	textAlign: 'start',
	opacity: '0',
	position: 'absolute',
	bottom: '-16px',
	whiteSpace: 'nowrap',

	variants: {
		showOnError: {
			true: {
				opacity: '1',
			},
		},
	},
});
