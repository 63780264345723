import {Filters} from '../filters';
import {Chart} from '../chart';
import {EntityType, TestInfo, TestSession, TestType, TrackDate} from '../../../../types';
import {useCallback, useMemo, useState, useEffect} from 'react';
import {FiltersState} from '../../types';
import {filterSessions} from '../../utils/filter-sessions';
import {EmptyContent} from '../../../../kit';
import {Wrapper} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {ChartExpandedView} from '../chart-expanded-view';
import {calculateCorrectAnswers} from '../../../../student/components/body-stats/utils';

type Props = {
	entityType: EntityType;
	trackDates: TrackDate[];
	testSessions: TestSession[];
	isTablet: boolean;
	testInfo: TestInfo | null;
};

export function StudentEntity({
	testSessions,
	trackDates,
	entityType,
	isTablet,
	testInfo: {testType, totalPossible},
}: Props) {
	const [filtersState, setFiltersState] = useState<FiltersState>({
		period: trackDates.find((item) => new Date(item.dateTo) > new Date())?.dateFrom,
	});
	const [isExpandedViewOpen, setExpandedViewOpen] = useState(false);

	const sortedSessions = useMemo(() => {
		const sorted = [...testSessions].sort((a, b) => new Date(a.testDate).getTime() - new Date(b.testDate).getTime());

		return sorted.map((session) => {
			return {
				...session,
				correctAnswers:
					testType === TestType.Rubric || testType === TestType.Score
						? session.correctAnswers
						: calculateCorrectAnswers(session),
			};
		});
	}, [testSessions, testType]);

	const [sessions, setSessions] = useState(sortedSessions);

	const onFilterValueChange = useCallback(
		(field: string) => (value: string | (string | Date)[]) => {
			const filteredSessionsValue = !value.length
				? trackDates.find((item) => new Date(item.dateTo) > new Date())?.dateFrom
				: value;

			const filteredSessionsField = !value.length ? 'period' : field;
			const filteredSessions = filterSessions(filteredSessionsValue, sortedSessions, trackDates);

			setFiltersState({[filteredSessionsField]: filteredSessionsValue});
			setSessions(filteredSessions);
		},
		[sortedSessions, trackDates],
	);


	useEffect(() => {
		const filteredSessionsDefaultValue = trackDates.find((item) => new Date(item.dateTo) > new Date())?.dateFrom ||
			trackDates.find(item => new Date(item.dateTo) > new Date())?.dateFrom;
		setFiltersState({period: filteredSessionsDefaultValue});
		setSessions(filterSessions(filteredSessionsDefaultValue, sortedSessions, trackDates));
	}, [sortedSessions, trackDates]);

	return (
		<Wrapper isTablet={isTablet} emptyMode={!sessions.length}>
			{filtersState && <Filters
				filtersState={filtersState}
				onFilterValueChange={onFilterValueChange}
				trackDates={trackDates}
				isTablet={isTablet}
			/>}
			{!sessions.length ? (
				<GridBox justify='center' align='center'>
					<EmptyContent templateText='No data to display' />
				</GridBox>
			) : (
				<Chart
					isTablet={isTablet}
					entityType={entityType}
					testSessions={sessions?.filter((el) => !el.isDeleted)}
					testType={testType}
					totalPossible={totalPossible}
					showExpandedView={() => setExpandedViewOpen(true)}
				/>
			)}
			{isExpandedViewOpen && (
				<ChartExpandedView testType={testType} entityType={entityType} onCancel={() => setExpandedViewOpen(false)} />
			)}
		</Wrapper>
	);
}
