import ReportSettings from './components/report-settings';
import ItemAnalysisReportService from './services/item-analysis-report-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ItemAnalysisHighchart} from './components/highchart';
import ItemAnalysisQuestionDetails from './components/question-details';
import EmptyReport from './components/empty-report';
import {ReportData, Test} from './models';
import {mapToEnum} from 'shared/utils';
import {TestType} from '@esgi/core/enums';
import styles from './item-analysis-report.module.less';

interface ItemAnalysisReportProps {
	itemAnalysisReportService: ItemAnalysisReportService;
}

export default function ItemAnalysisReport(props: ItemAnalysisReportProps) {
	const {itemAnalysisReportService: service} = props;
	const questionDetailsShow = useBehaviorSubject<boolean>(service.questionDetailsShow);
	const selectedTest = useBehaviorSubject<Test>(service.selectedTest);
	const reportData = useBehaviorSubject<ReportData>(service.reportData);
	const emptyReport = reportData ? reportData?.students.length === 0: false;
	const scoreTest = mapToEnum(selectedTest.type, TestType) === TestType.Score;
	const rubricTest = mapToEnum(selectedTest.type, TestType) === TestType.Rubric;
	const showChart = !emptyReport && !scoreTest && !rubricTest;
	const showDetail = !['district', 'school'].includes(service.level) && questionDetailsShow;

	return (
		<>
			<ReportSettings service={service}/>
			{showChart &&
				<div className={styles.mainContainer}>
					{showDetail &&
						<ItemAnalysisQuestionDetails service={service}/>
					}
					<ItemAnalysisHighchart service={service}/>
				</div>
			}
			<EmptyReport service={service}/>
		</>
	);
}
