import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupContent, ToggleGroupItem} from './index.styled';
import {TabItem} from '../../service/types';
import {useCallback} from 'react';
import {Tooltip, isUndefined} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';

type Props<TabId extends number = number> = {
	activeTab: TabId;
	setActiveTab: (tabId: string) => void;
	tabItems: (TabItem<TabId>)[];
	isSmallScreen?: boolean;
	dataCy?: string;
};

export function Tabs<TabId extends number = number>({
	activeTab,
	setActiveTab,
	tabItems,
	isSmallScreen = false,
	dataCy = 'tabs',
}: Props<TabId>) {
	const getItemContent = useCallback(({label, labelDataCy}: Pick<TabItem<TabId>, 'label' | 'labelDataCy'>) => {
		if (typeof label === 'string') {
			return (
				<Text size='medium' bold data-cy={`${labelDataCy}-label`}>
					{label}
				</Text>
			);
		}

		return label;
	}, []);

	if (!tabItems.length) {
		return null;
	}

	return (
		<ToggleGroup.Root onValueChange={setActiveTab} value={String(activeTab)} dataCy={dataCy}>
			<ToggleGroupContent dataCy={`${dataCy}-content`}>
				{tabItems.map(({value, label, labelDataCy, css = {}, tooltipText}) => {

					return (
						<Tooltip key={value} open={isUndefined(tooltipText) ? false : undefined}>
							<Tooltip.Trigger>
								<Box>
									<ToggleGroupItem
										value={String(value)}
										dataCy={labelDataCy}
										isSmallScreen={isSmallScreen}
										css={css}
									>
										{getItemContent({label, labelDataCy})}
									</ToggleGroupItem>
								</Box>
							</Tooltip.Trigger>
							<Tooltip.Content variant='secondary'>
								<Text size='small'>{tooltipText}</Text>
							</Tooltip.Content>
						</Tooltip>
					);
				})}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
