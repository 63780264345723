import {Dropdown as NewBaseDropdown, Option as NewOption} from '@esgillc/ui-kit/control';
import {Dropdown as NewDropdown} from '@esgillc/ui-kit/form-control';

/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/form-control.
 * */
export const Dropdown = NewDropdown;
/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/control.
 * */
export const BaseDropdown = NewBaseDropdown;
/**
 * @deprecated Import path deprecated. Use @esgillc/ui-kit/control or @esgillc/ui-kit/form-control.
 * */
export const Option = NewOption;

