import {useUser} from '@esgi/core/authentication';
import {join, OsChecker} from '@esgillc/ui-kit/utils';
import {useMemo, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {SubjectType, TestType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {TestModel} from '../../../../../../models';
import {IconButton} from '../../../../../icons/icon-button';
import {IconContainer} from '../../../../../icons/icon-container';
import {TestDraggableInfo} from '../../../../types';
import {testTypes} from './types';
import {OverflowSpan} from '@esgillc/ui-kit/layout';

import styles from './styles.module.less';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

export type Props = {
	onRemoveClicked: () => any;
	subjectType: SubjectType;
	subjectID: number;
	index: number;
	test: TestModel;
	canDelete: boolean;
	hidden: boolean;
}

export function Test(props: Props): JSX.Element {
	const user = useUser();
	const [testDetailsOpened, setTestDetailsOpened] = useState(false);
	const isMac = useMemo(() => OsChecker.isMac(), []);
	const canEdit = user.userID === props.test.creatorID;
	const editTooltipText = canEdit ? 'Edit test' : 'Preview test';

	const testType = useMemo(() => testTypes.find(t => t.id === props.test.type), [props.test.type]);

	const deleteTitle = useMemo(() => {
		if (!props.canDelete) {
			if (props.subjectType === SubjectType.Stock) {
				return 'This is a stock test, Admins cannot remove it';
			} else {
				return 'You are not allowed to remove this test';
			}
		}

		return 'Remove Test from Subject';
	}, [props.canDelete, props.subjectType]);

	const dragTitle = useMemo(() => {
		if (props.canDelete) {
			return '';
		}
		if (props.subjectType === SubjectType.Stock) {
			return 'Teacher tests cannot be reordered';
		} else {
			return 'You are not allowed to change the position of this test';
		}
	}, [props.canDelete, props.subjectType]);

	function renderTestDetails() {
		if (testDetailsOpened) {
			if (props.test.type !== TestType.Rubric) {
				return <TestDetails
					testID={props.test.id}
					firstName={user.firstName}
					lastName={user.lastName}
					userID={user.userID}
					close={() => setTestDetailsOpened(false)}
				/>;
			} else {
				return <RubricDetails
					testID={props.test.id}
					close={() => setTestDetailsOpened(false)}
					disableCopy={false}
				/>;
			}
		}
	}

	const remove = () => {
		if (props.canDelete) {
			props.onRemoveClicked();
		}
	};

	const draggableID = useMemo(() => JSON.stringify({
		testID: props.test.id,
		subjectID: props.subjectID,
		index: props.index,
	} as TestDraggableInfo), [props.index, props.subjectID, props.test.id]);

	const content = (isDragging = false, dragHandleProps = {}) => {
		return <>
			<OnHoverTooltip message={dragTitle}>
				<IconContainer
					data-cy={join(!props.hidden && 'test-drag-trigger')}
					className={join(styles.testDragTrigger, styles.actionBtn, isDragging && styles.dragging, !props.canDelete && styles.disabled, isMac && 'mac')} {...dragHandleProps}>
					<svg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16' fill='none'>
						<path d='M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z' fill='#0088CC'/>
					</svg>
				</IconContainer>
			</OnHoverTooltip>
			<div className={styles.name}>
				<OverflowSpan>
					{props.test.name}
				</OverflowSpan>
			</div>
			<div className={styles.type}>
				<OnHoverTooltip message={testType?.name}>
					<span data-cy='test-code'>{testType?.code}</span>
				</OnHoverTooltip>
			</div>
			<OnHoverTooltip message={editTooltipText}>
				<div className={styles.action}>
					<IconButton className={styles.actionBtn} onClick={() => setTestDetailsOpened(true)}>
						<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'
						     fill='none'>
							<path
								d='M0 10.6894V13.5019H2.8125L11.1075 5.20688L8.295 2.39438L0 10.6894ZM13.2825 3.03188C13.575 2.73938 13.575 2.26688 13.2825 1.97438L11.5275 0.219375C11.235 -0.073125 10.7625 -0.073125 10.47 0.219375L9.0975 1.59188L11.91 4.40438L13.2825 3.03188Z'
								fill='#0088CC'/>
						</svg>
					</IconButton>
				</div>
			</OnHoverTooltip>
			<OnHoverTooltip message={deleteTitle}>
				<div className={styles.action}>
					<IconButton
						disabled={!props.canDelete}
						className={join(styles.actionBtn, !props.canDelete && styles.disabled)}
						data-cy='test-remove-button'
						onClick={remove}>
						<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'
						     fill='none'>
							<path
								d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
								fill='#0088CC'/>
						</svg>
					</IconButton>
				</div>
			</OnHoverTooltip>
		</>;
	};

	if (props.hidden) {
		return <div className={styles.testRow}>
			{content()}
		</div>;
	}

	return <Draggable index={props.index} draggableId={draggableID} isDragDisabled={!props.canDelete}>
		{(provided, snapshot) =>
			<div className={styles.testRow} ref={provided.innerRef} {...provided.draggableProps}>
				{content(snapshot.isDragging, provided.dragHandleProps)}
				{renderTestDetails()}
			</div>}
	</Draggable>;
}

