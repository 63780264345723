import {IGraphStyle, ShapeType, VertexStyle} from 'shared/modules/question-editor/models';
import {ColorPicker} from 'shared/modules/question-editor/mx/editor/color-picker/color-picker';
import React from 'react';
import {StrokeSelector} from 'shared/modules/question-editor/mx/editor/toolbars/stroke-selector/stroke-selector';
import './vertex-toolbar.less';

export class Props {
	shapeStyle: VertexStyle;
	shapeType: ShapeType;
	mostRecentColors: string[];
	mostRecentColorsChanged: (colors: string[]) => void;
	styleChanged: (type: ShapeType, style: IGraphStyle) => void;
}

export class VertexToolbar extends React.PureComponent<Props> {
	changeStyle(field: string, value: any) {
		let newStyle = {...this.props.shapeStyle};
		newStyle[field] = value;
		this.props.styleChanged(this.props.shapeType, newStyle);
	}

	render() {
		return <div>
			<div className='mx-question-editor-vertex-toolbar'>
				<div className='text-toolbar-header'>STYLES</div>
				<div className='text-toolbar-line'></div>
				<div className='color-picker-rows'>
					<ColorPicker
						title='Fill'
						mostRecentColorsChanged={(colors) => this.props.mostRecentColorsChanged(colors)}
						mostRecentColors={this.props.mostRecentColors}
						color={this.props.shapeStyle.fillColor}
						onSelect={(value: string) => this.changeStyle('fillColor', value)}/>
					<ColorPicker
						title='Border'
						mostRecentColorsChanged={(colors) => this.props.mostRecentColorsChanged(colors)}
						mostRecentColors={this.props.mostRecentColors}
						color={this.props.shapeStyle.strokeColor}
						onSelect={(value: string) => this.changeStyle('strokeColor', value)}/>
				</div>
				<div className='stroke-selector-right'>
					<StrokeSelector
						strokeWidth={this.props.shapeStyle.strokeWidth}
						onSelect={(strokeWidth: number) => this.changeStyle('strokeWidth', strokeWidth)}/>
				</div>
			</div>
		</div>;
	}
}

export namespace Events {
	export function StyleChanged(args: StyleChanged.Args): StyleChanged.Args {
		return args;
	}

	export module StyleChanged {
		export interface Args {
			type: ShapeType,
			style: VertexStyle;
		}
	}
}

