import './image-gallery.less';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {Api, FolderResponse, TagModel} from './api';
import {BottomBar} from './components/bottom-bar/bottom-bar';
import {BaseCardState} from './components/cards/base-card';
import {FolderCard} from './components/cards/folder-card';
import {ImageCard, State as ImageCardState} from './components/cards/image-card';
import {Header, State as HeaderState} from './components/header';
import {IGLoader, State as IGLoaderState} from './components/loader/loader';
import {AddFolderModal, State as AddFolderModalState} from './components/modal/folder/add';
import {EditFolderModal, State as EditFolderModalState} from './components/modal/folder/edit';
import {ImageDetailModal, State as ImageDetailModalState} from './components/modal/image/image-detail/image-detail';
import {MoveModal, State as MoveModalState} from './components/modal/image/move';
import {UploadImagesModal, State as UploadImagesModalState} from './components/modal/image/upload';
import {RemoveModal, State as RemoveModalState} from './components/modal/remove/modal';
import {Sorter} from './components/sort/sort';
import {BreadcrumbModel, Events, FolderModel, ItemType, Library, SearchType} from './models';

export class State {
	images: ImageCardState[] = [];
	folders: BaseCardState[] = [];
	header: HeaderState = new HeaderState();
	bottomBar: boolean = false;

	currentFolder: FolderModel;
	manageMode: boolean = false;
	breadcrumb: BreadcrumbModel[] = [];

	removeItem: RemoveModalState = null;
	moveItems: MoveModalState = null;
	uploadImages: UploadImagesModalState = null;
	editFolder: EditFolderModalState = null;
	addFolder: AddFolderModalState = null;
	imageDetail: ImageDetailModalState = null;

	loading: boolean;
	canLoadMore: boolean;
	loadingMoreInProgress: boolean;

	currentSearchKeyword: string;
}

export class Props extends SharedProps<State> {
	selectMode: boolean;
	imageClicked: (id: number) => any;
	returnToHome: () => any;
	library: Library;
}

export class Main extends SharedComponent<State, Props> {
	get keyword() {
		return this.state.header.search.keyword;
	}

	get library(): Library {
		if (this.state.breadcrumb.length) {
			return this.state.breadcrumb[this.state.breadcrumb.length - 1].library;
		} else {
			return this.props.library;
		}
	}

	get loading(): boolean {
		const imageDetail = this.state.imageDetail && !this.state.imageDetail.loaded;
		return this.state.loading || imageDetail;
	}

	render(): JSX.Element | false | null {
		return <div className='main-page'>
			<Header back={() => this.view.backClicked()}
			        searchClicked={() => this.search(this.keyword)}
			        library={this.library}
			        manageModeClicked={() => this.setState({manageMode: !this.state.manageMode})}
			        state={this.state.header}
			        sortChanged={(m) => this.view.sortChanged(m)}
			        navigateToClicked={(id) => this.view.navigateTo(id)}
			        onChange={(ch, cb) => this.setState({header: ch}, cb)}
			        loading={this.loading}
			        uploadImagesClicked={() => this.setState({uploadImages: new UploadImagesModalState()})}
			/>
			<div className='content-container'>
				<div className='cards' id='ig-content' onScroll={() => this.view.onScrollHandler()}>
					{this.renderAddFolder()}
					{this.state.breadcrumb.length < 3 && this.state.folders.map(f => {
						return <FolderCard state={f}
						                   key={f.id}
						                   onChange={(ch, cb) => this.setState({folders: this.updateArrayItem(this.state.folders, f, ch)}, cb)}
						                   editClicked={() => this.setState({editFolder: new EditFolderModalState(this.state.currentFolder.id, f.id, f.name)})}
						                   deleteClicked={(e) => this.view.folderDeleteClicked(e)}
						                   click={(e) => this.view.folderClicked(e)}/>;
					})
					}
					{this.state.images.map(i => {
						return <ImageCard state={i}
						                  selectable={this.state.manageMode}
						                  onChange={(ch, cb) => this.setState({images: this.updateArrayItem(this.state.images, i, ch)}, cb)}
						                  key={i.id}
						                  click={(id) => this.view.imageClicked(id)}/>;
					})}
					{this.renderEmptyContent()}
				</div>
				{this.state.manageMode &&
				<BottomBar close={() => this.setState({manageMode: false})}
				           manageMode={this.state.manageMode && !!this.state.images.filter(i => i.checked).length}
				           multiplyMoveClicked={() => this.view.multiplyMoveClicked()}
				           multiplyDeleteClicked={() => this.view.multiplyDeleteClicked()}/>}

				{this.state.removeItem &&
				<RemoveModal state={this.state.removeItem}
				             onChange={(ch, cb) => this.setState({removeItem: ch}, cb)}
				             close={() => this.setState({removeItem: null})}/>}
				{this.state.moveItems &&
				<MoveModal state={this.state.moveItems}
				           sourceFolderID={this.state.currentFolder.id}
				           onChange={(ch) => this.setState({moveItems: ch})}
				           close={() => this.setState({moveItems: null})}/>}
				{this.state.addFolder &&
				<AddFolderModal state={this.state.addFolder}
				                currentFolderID={this.state.currentFolder.id}
				                onChange={(ch) => this.setState({addFolder: ch})}
				                close={() => this.setState({addFolder: null})}/>}
				{this.state.editFolder &&
				<EditFolderModal state={this.state.editFolder}
				                 onChange={(ch) => this.setState({editFolder: ch})}
				                 close={() => this.setState({editFolder: null})}/>}
				{this.state.uploadImages &&
				<UploadImagesModal state={this.state.uploadImages}
				                   onChange={(ch) => this.setState({uploadImages: ch})}
				                   folderID={this.state.currentFolder ? this.state.currentFolder.id : null}
				                   close={() => {
					                   this.setState({uploadImages: null});
				                   }}/>
				}
				{this.state.imageDetail &&
				<ImageDetailModal state={this.state.imageDetail}
				                  onChange={(ch, cb) => this.setState({imageDetail: ch}, cb)}
				                  tagClicked={(tag) => this.view.tagClicked(tag)}
				                  close={() => this.setState({imageDetail: null})}/>
				}
				<IGLoader loading={this.loading}
				          state={new IGLoaderState()} onChange={null}/>
			</div>
		</div>;
	}

	renderEmptyContent() {
		if (this.library === Library.Search && !this.state.images.length && !this.state.loading) {
			return <div className='empty'>
				<div className='wrapper'>
					<p>Your search returned 0 results.</p>
				</div>
			</div>;
		}
	}

	renderAddFolder() {
		if (this.state.breadcrumb.length < 3
			&& this.library === Library.Private
			&& this.state.breadcrumb.length
			&& (this.state.images.length || this.state.folders.length || !this.state.loading)) {
			return <div className='upload folder-card clickable'
			            onClick={() => this.setState({addFolder: new AddFolderModalState()})}>
				<svg className='esgi-icon folder-no-shadow' viewBox='0 0 200 121' xmlns='http://www.w3.org/2000/svg' fill='none'>
					<path d='M12.2375 120.5H187.525C193.876 120.5 199.025 115.351 199.025 109V12C199.025 5.64873 193.876 0.5 187.525 0.5H143.22C139.888 0.5 136.719 1.94556 134.535 4.46236L128.26 11.6931C125.886 14.4287 122.442 16 118.819 16H12.2375C5.88622 16 0.737503 21.1487 0.737503 27.5V109C0.737503 115.351 5.88622 120.5 12.2375 120.5Z'
					      fill='#FAFAFA' stroke='#BDBDBD'/>
				</svg>
				<div className='content'>
					<svg className='esgi-icon add-rect-icon' viewBox='0 0 25 24' fill='none'>
						<g clipPath='url(#clip0)'>
							<path d='M19.5221 13.0001H13.4934V19.0001H11.4838V13.0001H5.45508V11.0001H11.4838V5.00012H13.4934V11.0001H19.5221V13.0001Z'
							      fill='#0088CC'/>
							<path d='M1.43115 1.00012H23.5461V23.0001H1.43115V1.00012Z' stroke='#0088CC' strokeWidth='2'/>
						</g>
						<defs>
							<clipPath id='clip0'>
								<rect x='0.431152' y='0.00012207' width='24.115' height='24' fill='white'/>
							</clipPath>
						</defs>
					</svg>
					<span>Add Folder</span>
				</div>
			</div>;
		}

	}


	componentDidMount(): void {

		this.subscribe(Events.ItemsDeleted, (args) => this.removeContent(args.ids));
		this.subscribe(Events.ItemsMoved, (args) => {
			if (this.library === Library.Private) {
				this.removeContent(args.ids);
			}
		});
		this.subscribe(Events.ItemPropertyChanged, () => this.reloadContent());
		this.subscribe(Events.ItemCreated, () => {
			this.reloadContent();
		});
		if (this.keyword) {
			this.search(this.keyword);
		} else {
			Api.InitMain(this.library, this.state.currentFolder && this.state.currentFolder.id).subscribe(r => {
				if (r) {
					this.updateContent(r.folder, r.breadcrumbs.map(i => BreadcrumbModel.from(i, r.folder.isShared ? Library.Stock : Library.Private)));
				}
			});
			this.setState({loading: true});
		}
	}

	view = {
		folderClicked: (id: number) => {
			Api.GetFolder(id, this.library).subscribe((r) => {
				const breadcrumb = this.state.breadcrumb;
				breadcrumb.push({
					id: r.id,
					name: r.name,
					library: r.isShared ? Library.Stock : Library.Private,
				} as BreadcrumbModel);
				this.updateContent(r, breadcrumb);
			});
			this.setState({loading: true, folders: [], images: []});
		},
		imageClicked: (id: number) => {
			if (this.props.selectMode) {
				this.props.imageClicked(id);
				this.setState({loading: true});
			} else {
				this.setState({
					imageDetail: new ImageDetailModalState(id),
				});
			}
		},
		tagClicked: (tag: TagModel) => {
			if (!tag.id) {
				return;
			}
			Api.SearchByTag(tag.id).subscribe(r => {
				const name = `Results by tag: ${tag.name}`;
				const breadcrumb = this.state.breadcrumb;
				const currentFolder = breadcrumb[breadcrumb.length - 1];
				if (!currentFolder || currentFolder.library !== Library.Search) {
					breadcrumb.push({
						id: 0,
						name: name,
						library: Library.Search,
						searchKey: tag.id,
						searchType: SearchType.ByTag,
					});
				} else {
					breadcrumb[breadcrumb.length - 1].name = name;
				}
				this.updateContent({
					images: r.images,
					name: name,
					folders: [],
					isShared: false,
					id: 0,
					parentID: 0,
				} as FolderResponse, breadcrumb, true);
			});
			const headerState = this.state.header;
			headerState.search.keyword = tag.name;
			this.setState({manageMode: false, loading: true, imageDetail: null, header: headerState});
		},
		onScrollHandler: () => {
			if (!this.state.loadingMoreInProgress && this.state.canLoadMore) {
				const elem = $('#ig-content');
				const curPos = elem.scrollTop() + elem.innerHeight();
				const maxPos = elem[0].scrollHeight;
				if (maxPos - curPos < 500) {
					this.loadMore();
				}
			}
		},
		folderDeleteClicked: (id: number) => {
			const folder = this.state.folders.find(f => f.id === id);
			this.setState({removeItem: new RemoveModalState([folder.id], ItemType.Folder, folder.name)});
		},
		multiplyMoveClicked: () => {
			const cards = this.state.images.filter(c => c.checked);
			if (cards.length) {
				this.setState({moveItems: new MoveModalState(cards.map(i => i.id))});
			}
		},
		multiplyDeleteClicked: () => {
			const cards = this.state.images.filter(c => c.checked);
			if (cards.length) {
				this.setState({removeItem: new RemoveModalState(cards.map(c => c.id), ItemType.Image, cards[0].name, this.state.currentFolder.name)});
			}
		},
		navigateTo: (id: number) => {
			const breadcrumb = this.state.breadcrumb;
			breadcrumb.length = breadcrumb.indexOf(breadcrumb.find(i => i.id === id)) + 1;
			Api.GetFolder(id, this.library).subscribe((r) => {
				this.updateContent(r, breadcrumb);
			});
			this.setState({loading: true, folders: [], images: []});
		},
		backClicked: () => {
			const breadcrumb = this.state.breadcrumb;
			if (breadcrumb.length >= 2) {
				breadcrumb.pop();
				const folder = breadcrumb[breadcrumb.length - 1];
				Api.GetFolder(folder.id, folder.library).subscribe((r) => {
					this.updateContent(r, breadcrumb);
				});
				this.setState({loading: true, folders: [], images: []});
			} else {
				this.props.returnToHome();
				this.setState({
					folders: [],
					images: [],
					breadcrumb: [],
					header: new HeaderState(),
					manageMode: false,
					currentFolder: null,
				});
			}
		},
		sortChanged: (m) => {
			const images = [...this.state.images];
			this.setState({images: Sorter.By(images, m.type, m.direction)});
		},
	};

	private updateArrayItem<T extends BaseCardState>(arr: Array<T>, item: T, change: any): Array<T> {
		const newItem = this.extend({}, item, change);
		return arr.map(c => c.id === newItem.id ? newItem : c);
	}

	search(keyword: string) {
		Api.Search(keyword.trim()).subscribe(r => {
			const name = `Results for: ${keyword}`;
			const breadcrumb = this.state.breadcrumb;
			const currentFolder = breadcrumb[breadcrumb.length - 1];
			if (!currentFolder || currentFolder.library !== Library.Search) {
				breadcrumb.push({
					id: 0,
					name: name,
					library: Library.Search,
					searchKey: keyword,
					searchType: SearchType.ByKeyword,
				});
			} else {
				breadcrumb[breadcrumb.length - 1].name = name;
			}
			this.updateContent({
				images: r.images || [],
				name: name,
				folders: [],
				isShared: false,
				id: 0,
				parentID: 0,
			} as FolderResponse, breadcrumb, true);
		});
		this.setState({manageMode: false, loading: true, currentSearchKeyword: keyword});
	}

	loadMore() {
		if (this.state.images.length >= 100 && this.library === Library.Search) {
			Api.Search(this.state.currentSearchKeyword, this.state.images.length).subscribe(r => {
				if (r.images.length) {
					const cards = r.images.map((i) => {
						return {
							contextMenu: null,
							selectable: false,
							stock: this.library === Library.Stock,
							id: i.id,
							name: i.name,
							url: i.normalUrl,
							createDate: i.createDate,
							deleted: false,
							checked: false,
						} as ImageCardState;
					});
					this.setState({images: this.state.images.concat(cards), loadingMoreInProgress: false});
				} else {
					this.setState({loadingMoreInProgress: false, canLoadMore: false});
				}
			});
			this.setState({loadingMoreInProgress: true});
		}
	}

	removeContent(ids: number[]) {
		const setDeleteFunc = (i) => {
			if (ids.indexOf(i.id) !== -1) {
				i.deleted = true;
			}
		};
		this.state.images.forEach((i) => setDeleteFunc(i));
		this.state.folders.forEach((i) => setDeleteFunc(i));
		this.setState({images: this.state.images, folders: this.state.folders, manageMode: false});
		if (this.library !== Library.Search) {
			setTimeout(() => this.reloadContent(), 200);
		}
	}

	reloadContent() {
		if (this.library === Library.Search) {
			const last = this.state.breadcrumb[this.state.breadcrumb.length - 1];
			if (last.searchType === SearchType.ByTag) {
				const searchKey = last.searchKey as number;
				Api.SearchByTag(searchKey).subscribe(r => {
					this.updateContent({
						images: r.images,
						name: last.name,
						folders: [],
						isShared: false,
						id: 0,
						parentID: 0,
					} as FolderResponse, this.state.breadcrumb, true);
				});
			} else {
				const searchKey = last.searchKey as string;
				Api.Search(searchKey).subscribe(r => {
					this.updateContent({
						images: r.images || [],
						name: last.name,
						folders: [],
						isShared: false,
						id: 0,
						parentID: 0,
					} as FolderResponse, this.state.breadcrumb, true);
				});
			}
		} else {
			Api.GetFolder(this.state.currentFolder.id, this.library).subscribe((r) => this.updateContent(r, this.state.breadcrumb));
			this.setState({loading: true});
		}
	}

	private updateContent(folder: FolderResponse, breadcrumb: BreadcrumbModel[], searchResults = false): void {
		const sortModel = {direction: this.state.header.sort.direction, type: this.state.header.sort.type};
		const folders = folder.folders.map((f) => {
			return {
				id: f.id,
				name: f.name,
				stock: this.library === Library.Stock,
				contextMenu: null,
			} as BaseCardState;
		});
		let cards = folder.images.map((i) => {
			return {
				contextMenu: null,
				selectable: false,
				stock: this.library === Library.Stock,
				id: i.id,
				name: i.name,
				url: i.normalUrl,
				createDate: i.createDate,
				deleted: false,
				checked: false,
			} as ImageCardState;
		});

		const header = this.state.header;
		header.breadcrumb = searchResults ? [breadcrumb[breadcrumb.length - 1]] : breadcrumb;

		cards = Sorter.By(cards, sortModel.type, sortModel.direction);
		this.setState({
			folders: folders,
			images: cards,
			currentFolder: FolderModel.from(folder),
			breadcrumb: [...breadcrumb],
			header: header,
			loading: false,
			canLoadMore: true,
			loadingMoreInProgress: false,
		});
	}
}