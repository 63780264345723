import React from 'react';
import {Primary} from '@esgi/deprecated/elements/buttons';
import BingoLogo from 'shared/modules/bingo/assets/bingo-logo';
import {CloseIcon} from '@esgillc/ui-kit/modal';
import styles from './preview.module.less';

interface PreviewProps {
	nextClicked: () => void;
	closeClicked: () => void;
}

export default function Preview ({closeClicked, nextClicked}: PreviewProps) {
	return (
		<div className={styles.preview}>
			<div className={styles.header}>
				<BingoLogo/>
				<CloseIcon
					className={styles.closeIcon}
					color={'#fff'}
					onClick={closeClicked}
				/>
			</div>
			<div className={styles.subHeader}>
				<div className={styles.balls}/>
				<div className={styles.title}>
					Play a personalized game catered to your <br/> student's test results!
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.infoLabel}>Create Bingo cards in a few simple steps!</div>
				<div className={styles.info}>
					<ul>
						<li>Easily create Bingo printables tailored to your <br/> student's learning needs.</li>
						<li>Select students, difficulty, board size, and more to <br/> customize your game experience.
						</li>
						<li>Download and print Bingo call sheets and cards <br/> for for In-Class or At-Home Bingo
							sessions.
						</li>
					</ul>
				</div>
				<div className={styles.letterSounds}/>
				<div className={styles.numbers}/>
			</div>
			<div className={styles.footer}>
				<Primary
					className={styles.btn}
					onClick={nextClicked}
					title="LET' S BEGIN!"
				/>
			</div>
		</div>
	);
}
