import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SsoTracker} from '@esgi/core/tracker';
import {TeacherScreenClient} from 'modules/assessments';
import {ShareScreenSettingsResponse} from './models';

export class ShareScreenLauncherService extends BaseService {

	public onJoined: Subject<void> = new Subject<void>();
	public code: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public expireTime: BehaviorSubject<string> = new BehaviorSubject<string>('');

	private timerID = 0;
	private teacherScreenClient = new TeacherScreenClient();

	public init(userID: number) {
		this.teacherScreenClient.shared.pipe(takeUntil(this.destroy$)).subscribe((data) => {
			const {code} = data;

			if (code) {
				this.code.next(code);

				this.teacherScreenClient.studentJoined.pipe(takeUntil(this.destroy$)).subscribe(() => {
					clearInterval(shareScreenTimer);
					this.onJoined.next();
				});

				const shareScreenTimer = window.setInterval(() => {
					const now = new Date().valueOf();
					const expiredTime = 5 * 60 * 1000;
					const time = new Date(expiredTime - (now - data.createdTime));
					const seconds = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
					const minutes = '0' + time.getMinutes();
					this.expireTime.next(minutes + ':' + seconds);

					if (minutes === '00' && seconds === '00') {
						this.code.next('');
						this.expireTime.next('');
						clearInterval(shareScreenTimer);
					}
				}, 1000);

				this.timerID = shareScreenTimer;
			}
		});

		this.teacherScreenClient.isExpired(userID);

	}

	public createShareSession(userID: number) {
		SsoTracker.trackEvent({
			trackingEvent: 'Get2SCode',
		});
		this.teacherScreenClient.share(userID);
		window.localStorage.shareScreenSessionCreated = true;
	}

	public updateShowTestSessionResults(value: boolean) {
		return this.httpClient.ESGIApi.post('second-screen', 'update', {
			showTestSessionResults: value,
		});
	}

	public getSettings() {
		return this.httpClient.ESGIApi.get<ShareScreenSettingsResponse>('second-screen', 'settings');
	}

	public destroy() {
		super.destroy();
		this.teacherScreenClient?.dispose();
		clearInterval(this.timerID);
	}
}
