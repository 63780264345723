import {FormElement, IFormControl} from '@esgillc/ui-kit/form';
import {Dropdown, OnErrorTooltip, Option, Label} from '@esgillc/ui-kit/form-control';
import {SubjectModel} from '../../models';
import styles from '../styles.module.less';

type Props = {
	control: IFormControl;
	subjects: SubjectModel[];
}

export const SubjectDropdown = ({control, subjects}: Props) => (
	<FormElement
		className={styles.formElement}
		control={control}
	>
		<Label className={styles.label}>Subject</Label>
		<div className={styles.field}>
			<Dropdown
				placeholder='Select a subject tab...'
				className={styles.dropDownField}
				optionName='name'
				tabIndex={0}
			>
				{subjects.map((v, i) => <Option key={i} value={v}>{v.name}</Option>)}
			</Dropdown>
			<OnErrorTooltip
				showOnError='required'
				placement='left'
			>
				Please select a subject tab...
			</OnErrorTooltip>
		</div>
	</FormElement>
);
