import * as React from 'react';
import type {SVGProps} from 'react';

export function RubricReport(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M7 9C7 8.44772 7.44772 8 8 8H14C14.5523 8 15 8.44772 15 9V11C15 11.5523 14.5523 12 14 12H8C7.44772 12 7 11.5523 7 11V9Z'
				fill='#333333'/>
			<path
				d='M17 9C17 8.44772 17.4477 8 18 8H24C24.5523 8 25 8.44772 25 9V11C25 11.5523 24.5523 12 24 12H18C17.4477 12 17 11.5523 17 11V9Z'
				fill='#333333'/>
			<path
				d='M7 15C7 14.4477 7.44772 14 8 14H14C14.5523 14 15 14.4477 15 15V17C15 17.5523 14.5523 18 14 18H8C7.44772 18 7 17.5523 7 17V15Z'
				fill='#333333'/>
			<path
				d='M17 15C17 14.4477 17.4477 14 18 14H24C24.5523 14 25 14.4477 25 15V17C25 17.5523 24.5523 18 24 18H18C17.4477 18 17 17.5523 17 17V15Z'
				fill='#333333'/>
			<path
				d='M7 21C7 20.4477 7.44772 20 8 20H14C14.5523 20 15 20.4477 15 21V23C15 23.5523 14.5523 24 14 24H8C7.44772 24 7 23.5523 7 23V21Z'
				fill='#333333'/>
			<path
				d='M17 21C17 20.4477 17.4477 20 18 20H24C24.5523 20 25 20.4477 25 21V23C25 23.5523 24.5523 24 24 24H18C17.4477 24 17 23.5523 17 23V21Z'
				fill='#333333'/>
		</svg>
	);
}
