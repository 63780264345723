import * as React from 'react';
import type {SVGProps} from 'react';

export function GradeScale(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M3.47727 17.7188C3.00656 17.7188 2.625 17.341 2.625 16.875C2.625 16.409 3.00656 16.0312 3.47727 16.0312C3.94798 16.0312 4.32955 16.409 4.32955 16.875C4.32955 17.341 3.94798 17.7188 3.47727 17.7188Z' fill='#333333'/>
			<path d='M6.88636 17.7188C6.41565 17.7188 6.03409 17.341 6.03409 16.875C6.03409 16.409 6.41565 16.0312 6.88636 16.0312C7.35707 16.0312 7.73864 16.409 7.73864 16.875C7.73864 17.341 7.35707 17.7188 6.88636 17.7188Z' fill='#333333'/>
			<path d='M13.7045 17.7188C13.2338 17.7188 12.8523 17.341 12.8523 16.875C12.8523 16.409 13.2338 16.0312 13.7045 16.0312C14.1753 16.0312 14.5568 16.409 14.5568 16.875C14.5568 17.341 14.1753 17.7188 13.7045 17.7188Z' fill='#333333'/>
			<path d='M20.5227 17.7188C20.052 17.7188 19.6705 17.341 19.6705 16.875C19.6705 16.409 20.052 16.0312 20.5227 16.0312C20.9934 16.0312 21.375 16.409 21.375 16.875C21.375 17.341 20.9934 17.7188 20.5227 17.7188Z' fill='#333333'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M9.44318 19.5117C9.44318 19.9195 9.77024 20.25 10.1737 20.25C10.5772 20.25 10.9042 19.9195 10.9042 19.5117V14.2383C10.9042 13.8305 10.5772 13.5 10.1737 13.5C9.77024 13.5 9.44318 13.8305 9.44318 14.2383L9.44318 19.5117Z' fill='#333333'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M16.2614 18.3284C16.2614 18.7361 16.5884 19.0666 16.9919 19.0666C17.3953 19.0666 17.7224 18.7361 17.7224 18.3284L17.7224 15.3633C17.7224 14.9555 17.3953 14.625 16.9919 14.625C16.5884 14.625 16.2614 14.9555 16.2614 15.3633L16.2614 18.3284Z' fill='#333333'/>
			<path d='M10.125 3.75C10.5402 3.75 10.862 4.00088 10.997 4.36215L13.0627 9.94173C13.1042 10.0521 13.125 10.1324 13.125 10.2227C13.125 10.584 12.824 10.875 12.4503 10.875C12.1285 10.875 11.8845 10.6643 11.7963 10.4033L11.4433 9.37975H8.80666L8.45372 10.4033C8.36029 10.6643 8.11635 10.875 7.79974 10.875C7.42604 10.875 7.125 10.584 7.125 10.2227C7.125 10.1324 7.14576 10.0521 7.18728 9.94173L9.25303 4.36215C9.38798 4.00088 9.70977 3.75 10.125 3.75ZM10.125 5.5463L9.19074 8.25581H11.0593L10.125 5.5463Z' fill='#333333'/>
		</svg>
	);
}
