import {BaseService} from '@esgi/core/service';
import {SubjectType, TestType} from '@esgi/core/enums';
import moment from 'moment/moment';
import {BehaviorSubject, map, merge, of, skip, switchMap, tap, zip} from 'rxjs';
import {
	Class,
	Group,
	Report,
	ReportData,
	ReportFilter,
	ReportFormat,
	ReportType,
	SpecialistGroup,
	Student,
	Subject,
	Test,
} from '../models';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {
	getChartOptions,
	getDateString,
	getZoomByDates,
	getZoomDates,
	parseReportFilter,
	setLocalTime,
	sortStudent,
} from '../utils';
import {GetStudentReportChartBase64, RenderStudentGraph} from '../utils/all-students-utils';
import {userStorage} from '@esgi/core/authentication';
import {size} from 'underscore';

export interface ExportOptions {
	students: ExportStudentOptions[],
}

export interface ExportStudentOptions {
	subjectName: string,
	isSpecialist: boolean,
	teacherName: string,
	unityTitle: string,
	unityName: string,
	testName: string,
	totalPossible: number,
	pdfRange: string,
	range: string,
	studentName: string,
	tableDateHeader: string,
	tableTimeHeader: string,
	tableCorrectHeader: string,
	testSessions: { Date: string, Score: number }[]
}

export class TestHistoryService extends BaseService {
	public report = new BehaviorSubject<Report>(null);
	public testId: number;
	public teacherName = new BehaviorSubject<string>('');
	public classes = new BehaviorSubject<Array<Class>>([]);
	public groups = new BehaviorSubject<Array<Group>>([]);
	public specialistGroups = new BehaviorSubject<Array<SpecialistGroup>>([]);
	public students = new BehaviorSubject<Array<Student>>([]);
	public subjects = new BehaviorSubject<Array<Subject>>([]);
	public selectedClass = new BehaviorSubject<Class[]>([]);
	public selectedGroup = new BehaviorSubject<Group[]>([]);
	public selectedSpecialistGroup = new BehaviorSubject<SpecialistGroup[]>([]);
	public selectedStudent = new BehaviorSubject<Student[]>([]);
	public selectedSubject = new BehaviorSubject<Subject[]>([]);
	public selectedTest = new BehaviorSubject<Test[]>([]);
	public zoom = new BehaviorSubject<number>(3);
	public dateFrom = new BehaviorSubject<Date>(new Date());
	public dateTo = new BehaviorSubject<Date>(new Date());
	public applyingZoom = new BehaviorSubject<boolean>(false);
	public settingZoom = new BehaviorSubject<boolean>(false);
	public includeOnlyTestedStudents = new BehaviorSubject<boolean>(false);
	public reportData = new BehaviorSubject<ReportData | null>(null);
	public testResultsCorrectVerbiage: string;
	public testResultsIncorrectVerbiage: string;
	public reportFilter: ReportFilter;
	private readonly currentUser = userStorage.get();

	constructor(private hierarchy: HierarchySnapshot, public subjectID: number, private subjectType: SubjectType, testId: number, public testType: TestType, private studentId?: number) {
		super();
		this.testId = testId;
	}

	public getStudentDisplayName() {
		return this.selectedStudent.value[0] ? this.selectedStudent.value[0].studentID > 0 ? this.selectedStudent.value[0].name : this.selectedGroup.value[0] ? this.selectedGroup.value[0].name : this.selectedClass.value[0] ? this.selectedClass.value[0].name : this.selectedSpecialistGroup.value[0] ? this.selectedSpecialistGroup.value[0].name : '' : '';
	}

	public init() {
		return this.httpClient.ESGIApi.get<Report>('reports/test-history', 'init', {
			testId: this.testId,
			includeOnlyTestedStudents: this.includeOnlyTestedStudents.value,
			studentId: this.studentId,
			fullHierarchy: this.hierarchy,
		}).pipe(tap((data: Report) => {
			const filter = data.reportFilter;
			this.testResultsCorrectVerbiage = data.testResultsCorrectVerbiage;
			this.testResultsIncorrectVerbiage = data.testResultsIncorrectVerbiage;
			this.reportFilter = filter;
			this.teacherName.next(filter.teacherName);
			const parsedData = parseReportFilter(filter, this.subjectID, this.subjectType, this.testId);
			this.classes.next(parsedData.classes);
			this.selectedClass.next(parsedData.selectedClass);
			this.students.next(parsedData.students);
			this.selectedStudent.next(parsedData.selectedStudent);
			this.groups.next(parsedData.groups);
			this.selectedGroup.next(parsedData.selectedGroup);
			this.subjects.next(parsedData.subjects);
			this.selectedSubject.next(parsedData.selectedSubject);
			this.specialistGroups.next(parsedData.specialistGroups);
			this.selectedSpecialistGroup.next(parsedData.selectedSpecialistGroup);
			this.selectedTest.next(parsedData.selectedTest);
			this.reportData.next(data.reportData);
			this.applySelectedZoomToDates();
			this.afterInit();
		}));
	}

	public updateReport() {
		const classId = this.selectedClass.value[0] ? this.selectedClass.value[0].classID : 0;
		const groupId = this.selectedGroup.value[0] ? this.selectedGroup.value[0].groupID : 0;
		const specialistGroupId = this.selectedSpecialistGroup.value[0] ? this.selectedSpecialistGroup.value[0].specialistGroupID : 0;
		const studentId = this.selectedStudent.value[0]?.studentID;
		const testId = this.selectedTest.value[0]?.testID;
		const includeOnlyTestedStudents = this.includeOnlyTestedStudents.value;

		this.httpClient.ESGIApi.get<Report>('reports/test-history', 'update', {
			classId,
			groupId,
			specialistGroupId,
			studentId,
			testId,
			includeOnlyTestedStudents,
			fullHierarchy: this.hierarchy,
		}).subscribe((data: Report) => {
			this.reportData.next(data.reportData);
		});
	}

	public updateZoom(zoom: number) {
		if (this.zoom.value !== zoom) {
			this.settingZoom.next(true);
			this.zoom.next(zoom);
			this.settingZoom.next(false);
		}
	}

	public getExportOptions(fileFormat: ReportFormat, type = ReportType.SingleFile) {
		const isSpecialist = !!this.selectedSpecialistGroup.value[0];
		const testName = this.selectedTest.value[0].name;
		const totalPossible = this.reportData.value.questionsCount;
		const subjectName = this.selectedSubject.value[0].name;

		const tableDateHeader = 'Test session date';
		const tableTimeHeader = 'Test session time';
		const tableCorrectHeader = 'Avg. Score';

		const dateFrom = setLocalTime(this.dateFrom.value.toDateString());
		const dateTo = setLocalTime(this.dateTo.value.toDateString());
		const range = 'Test Sessions from ' + getDateString(dateFrom) + ' to ' + getDateString(dateTo);

		const unityName = this.selectedSpecialistGroup.value[0]
			? this.selectedSpecialistGroup.value[0]?.name
			: this.selectedGroup.value[0] ? this.selectedGroup.value[0]?.name : this.selectedClass.value[0]?.name;
		const unityTitle = this.selectedSpecialistGroup.value[0] || this.selectedGroup.value[0] ? 'Group' : 'Class';
		const {userID, globalSchoolYearID} = this.currentUser;

		return this.getStudentExportOptions(this.selectedStudent.value[0])
			.pipe(
				map((student) => ({
					fileFormat,
					type,
					classID: this.selectedClass.value[0]?.classID ?? 0,
					groupID: this.selectedGroup.value[0]?.groupID ?? 0,
					range,
					isSpecialist,
					teacherName: this.teacherName.value,
					testName,
					totalPossible,
					subjectName,
					tableCorrectHeader,
					tableDateHeader,
					tableTimeHeader,
					unityName,
					unityTitle,
					userID,
					globalSchoolYearID,
					students: [student],
				})),
			);
	}

	private applySelectedZoomToDates() {
		const dates = getZoomDates(this.reportFilter)[this.zoom.value];
		this.applyingZoom.next(true);
		if (dates?.to) {
			this.dateTo.next(dates.to.toDate());
		}
		if (dates?.from) {
			this.dateFrom.next(dates.from.toDate());
		}
		this.applyingZoom.next(false);
	}

	private afterInit() {
		merge(this.zoom, this.reportData).subscribe(() => {
			this.zoom.value !== -1 && this.applySelectedZoomToDates();
		});

		if (this.classes.value.length > 0) {
			this.selectedClass.subscribe((newVal) => {
				const classAverageOption = new Student(0, 'Class Average', '');
				const allClassOption = new Student(-1, 'All Class', '');
				const allOptions = [classAverageOption];
				if (this.testType !== TestType.Rubric) {
					allOptions.push(allClassOption);
				}
				const students = allOptions.concat(newVal[0].students.sort((a, b) => sortStudent(a, b, this.reportFilter.studentSort)));
				this.students.next(students);
			});
		}
		if (this.groups.value.length > 0) {
			this.selectedGroup.subscribe((newVal) => {
				const students = [
					new Student(0, 'Group Average', ''),
					new Student(-1, 'All Group', ''),
				].concat(newVal[0].students.sort((a, b) => sortStudent(a, b, this.reportFilter.studentSort)));
				this.students.next(students);
			});
		}
		if (this.specialistGroups.value.length > 0) {
			this.selectedSpecialistGroup.subscribe((newVal) => {
				const students = [
					new Student(0, 'Specialist Group Average', ''),
					new Student(-1, 'All Group', ''),
				].concat(newVal[0].students.sort((a, b) => sortStudent(a, b, this.reportFilter.studentSort)));
				this.students.next(students);
			});
		}

		merge(this.selectedStudent.pipe(skip(1)), this.selectedTest.pipe(skip(1)), this.includeOnlyTestedStudents.pipe(skip(1))).subscribe((newVal) => {
			this.updateReport();
		});

		this.zoom.subscribe(() => {
			if (this.settingZoom) {
				return;
			}

			this.applySelectedZoomToDates();
		});

		this.dateFrom.subscribe((newValue) => {
			if (!this.applyingZoom.value) {
				if (newValue > this.dateTo.value) {
					this.dateTo.next(moment(newValue).add(1, 'days').toDate());
					return;
				}
				const zoom = getZoomByDates(newValue, this.dateTo.value, this.reportFilter);
				this.updateZoom(zoom);
			}
		});

		this.dateTo.subscribe((newValue) => {
			if (!this.applyingZoom.value) {
				if (newValue < this.dateFrom.value) {
					this.dateFrom.next(moment(newValue).subtract(1, 'days').toDate());
					return;
				}
				const zoom = getZoomByDates(this.dateFrom.value, newValue, this.reportFilter);
				this.updateZoom(zoom);
			}
		});
	}

	private getStudentExportOptions(student: Student) {
		return of({
			reportFilter: this.reportFilter,
			reportData: this.reportData.value,
			testResultsCorrectVerbiage: this.testResultsCorrectVerbiage,
			testResultsIncorrectVerbiage: this.testResultsIncorrectVerbiage,
		}).pipe(
			switchMap((data) => zip(of(data), this.renderStudentChart(data, student))),
			switchMap(([data, chartRef]) => zip(of(data), GetStudentReportChartBase64(chartRef))),
			map(([{reportData}, chartBase64]) => {
				const {studentID, name: studentName} = student;

				return {
					studentID,
					studentName,
					chartBase64,
					sessions: reportData.testSessions.map(
						({date, score}) => ({
							testDate: moment(date).format('L LT'),
							score,
						}),
					),
				};
			}),
		);
	}

	private renderStudentChart(report: Report, student: Student) {
		const chartOptions = getChartOptions(
			report.reportData,
			this.dateTo.value,
			this.dateFrom.value,
			[student],
			TestType[this.selectedTest.value[0].type],
			report.testResultsCorrectVerbiage,
			report.testResultsIncorrectVerbiage,
			null,
		) || {};

		if (size(chartOptions)) {
			chartOptions.chart.animation = false;
			chartOptions.plotOptions.series.animation = false;
			chartOptions.title.text = null;
			chartOptions.tooltip.enabled = false;
		}

		return RenderStudentGraph(chartOptions);
	}
}
