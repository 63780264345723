import {useEffect, useState} from 'react';
import {ClassBucket} from './types';
import {Student, Class} from '@esgi/main/libs/store';

export function useClassesContentState({classes, students}: {classes: Class[]; students: Student[]}) {
	const [classBuckets, setClassBuckets] = useState<ClassBucket[]>([]);

	useEffect(() => {
		setClassBuckets((currentClassesList) => {
			return (classes || []).map<ClassBucket>(({studentIDs, ...classData}) => {
				const filteredStudents = students.filter(({id}) => studentIDs.includes(id));
				const current = currentClassesList.find((statedClassData) => classData.id === statedClassData.id);

				return {...classData, students: filteredStudents, isListOpen: current?.isListOpen ?? false};
			});
		});
	}, [classes, students]);

	return classBuckets;
}
