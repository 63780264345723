import {PropsWithChildren} from 'react';
import {Section, Title, Header, Body} from './styled';

interface Props extends PropsWithChildren {
	title?: string;
}

export function TestSection({title, children}: Props) {

	return <Section>
		<Header>
			<Title size='small' font='mono' color='lowContrast'>{title}</Title>
		</Header>
		<Body>{children}</Body>
	</Section>;
}
