import {useItemAnalysisService} from '../../hooks';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {AlertBody} from './components/body';
import {DownloadButton} from './components/download-button';
import {DownloadFunction, FileType} from '../../types';
import {Observable} from 'rxjs';
import {isEmptyReport} from './utils';
import {Settings} from './components/settings';

type Props = {
	onClose: VoidFunction;
	onDownload: (fileType: FileType) => Observable<boolean>;
};

export function PreviewDialog(props: Props) {
	const service = useItemAnalysisService();
	const report = useBehaviorSubject(service.reportService.reportData$);
	const emptyReport = isEmptyReport(report);
	const alertRef = Alert.useRef();
	const onClose = Alert.useClose(alertRef, props.onClose);
	const onClick: DownloadFunction = (fileType: FileType) => {
		props.onDownload(fileType);
	};

	return (
		<Alert
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.neutral}
		>
			<Alert.Header
				withBacklight={false}
				onCloseIconClick={onClose}
			>
				<Text data-cy='item-analysis-title' size='large'>Item Analysis</Text>
			</Alert.Header>
			<Settings/>
			<AlertBody/>
			{!emptyReport &&
				<Alert.Footer>
					<DownloadButton onClick={onClick}/>
				</Alert.Footer>
			}
		</Alert>
	);
}
