import {Button} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {AlertBody, Divider, Dot, ListItemBox} from './index.styled';
import {listTextItems} from './constants';
import {TextWithLinkBlock} from './components/text-with-link-block';
import {LogoYear20242025} from './components/icons/logo-year-2024-2025';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {useCallback} from 'react';

type Props = {
	onClose: VoidFunction;
};

export function RolloverNotofication({onClose}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef);

	const handleAlertClose = useCallback(() => {
		closeAlert();
		onClose();
	}, [closeAlert, onClose]);

	return (
		<Alert
			colorConfig={alertColorsConfig.neutral}
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					minWidth: 508,
					maxWidth: 508,
					maxHeight: 'calc(100% - 25px)',
					gridTemplateRows: 'auto 1fr auto',
					overflow: 'hidden',
				},
			}}
		>
			<Alert.Header onCloseIconClick={handleAlertClose} withBacklight={false}>
				<Text size='large' color='base'>
					The new 2024-2025 school year is now available!
				</Text>
			</Alert.Header>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
			>
				<AlertBody>
					<GridBox justify='center'>
						<LogoYear20242025 />
					</GridBox>

					<Divider />

					<GridBox gap='2'>
						{listTextItems.map(({label, fontStyle}, index) => (
							<ListItemBox key={index}>
								<Dot />
								<Text size='medium' color='base' css={{fontStyle}}>
									{label}
								</Text>
							</ListItemBox>
						))}
					</GridBox>

					<Divider />

					<TextWithLinkBlock
						text='Interested in Pre-Assessing?'
						to='https://support.esgisoftware.com/hc/en-us/articles/209158786-Pre-Assess-Account-FAQ'
					/>
				</AlertBody>
			</OverlayScrollbarsComponent>

			<Alert.Footer>
				<Button color='secondary' onClick={handleAlertClose}>
					<Text size='medium' bold color='base'>
						Got It
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
