import * as React from 'react';
import type {SVGProps} from 'react';

export function AppliedTo(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M19 7a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-4Zm0 2h4v4h-4V9ZM9 17a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H9Zm10 0a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-4ZM9 19h4v4H9v-4Zm10 0h4v4h-4v-4ZM9.469 7A2.46 2.46 0 0 0 7 9.469v3.062A2.46 2.46 0 0 0 9.469 15h3.062A2.46 2.46 0 0 0 15 12.531V9.469A2.46 2.46 0 0 0 12.531 7H9.469Zm2.687 2.656a.476.476 0 0 1 .688 0 .476.476 0 0 1 0 .688l-2 2a.476.476 0 0 1-.688 0l-1-1a.476.476 0 0 1 0-.688.476.476 0 0 1 .688 0l.656.656 1.656-1.656Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
