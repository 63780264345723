import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button, ImageCropper, CroppedImage} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useState} from 'react';
import {AlertBody, ImageCropperContent} from './index.styled';

type Props = {
	image: CroppedImage;
	onCloseAlert: VoidFunction;
	onSaveImage: (value: CroppedImage) => void;
};

export function CropImageAlert({image, onCloseAlert, onSaveImage}: Props) {
	const [cropperImage, setCropperImage] = useState<CroppedImage>(image);

	const alertRef = Alert.useRef();

	const closeAlert = Alert.useClose(alertRef, onCloseAlert);
	const saveImage = Alert.useClose(alertRef, () => {
		onSaveImage(cropperImage);
	});

	return (
		<Alert modalManagerRef={alertRef}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='neutral24'>
					Crop Photo
				</Text>
			</Alert.Header>
			<AlertBody>
				<Text size='medium' color='neutral40'>
					Drag and zoom in or out to adjust the photo.
				</Text>
				{cropperImage && (
					<ImageCropper imageData={cropperImage} onCropChange={setCropperImage}>
						<ImageCropperContent>
							<ImageCropper.Cropper />
							<ImageCropper.Slider />
						</ImageCropperContent>
					</ImageCropper>
				)}
			</AlertBody>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
					<Button color='secondary' onClick={saveImage}>
						<Text size='medium' bold color='base'>
							Save
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
