import React, {ReactNode} from 'react';
import SortableUIParams = JQueryUI.SortableUIParams;

export class DragAndDrop extends React.PureComponent<Props> {
	private ref: HTMLElement;
	private dragging: boolean = false;

	render() {
		let child = this.getChildAsElem() as any;
		if (!child) {
			return this.props.children;
		}

		return React.cloneElement(
			child,
			{
				ref: el => {
					this.ref = el;
					if (child.ref) {
						child.ref(el);
					}
				},
			},
		);
	}

	componentDidMount(): void {
		this.drag();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
		if (this.props.enabled) {
			this.drag();
		} else {
			this.undrag();
		}
	}

	getChildAsElem() {
		if (!!this.props.children && React.Children.count(this.props.children) === 1) {
			return React.Children.only(this.props.children);
		}
	}

	undrag() {
		if (this.dragging) {
			$(this.ref).sortable('destroy');
			this.dragging = false;
		}
	}

	drag() {
		if (this.ref && !this.dragging) {
			let sortableOptions: JQueryUI.SortableOptions = {
				items: this.props.itemSelector,
				opacity: .8,
				revert: true,
				handle: this.props.handle,
				forceHelperSize: true,
				placeholder: this.props.placeholder,
				forcePlaceholderSize: true,
        connectWith: this.props.connectWith,
        tolerance: 'pointer',
				containment: this.props.containment || this.ref,
				scroll: true,
				update: (event, ui) => {
					let elements = $(this.ref).find(this.props.itemSelector)
						.toArray();

					this.props.orderChanged(elements);
				},
				start: (event, ui) => {
					if (this.props.onStart){
						this.props.onStart(ui);
					} else {
						ui.item.addClass('dragging');
					}
				},
				stop: (event, ui) => {
					if (this.props.onStop){
						this.props.onStop(ui);
					} else {
						ui.item.removeClass('dragging');
					}
				},
			};

			$(this.ref).sortable(sortableOptions);
			this.dragging = true;
		}
	}
}

export class Props {
	itemSelector: string;
	placeholder?: string;
	handle?: string;
  connectWith?: string;
  containment?: string;
	onStart?: (ui: SortableUIParams) => void;
	onStop?: (ui: SortableUIParams) => void;
	orderChanged: (elements: HTMLElement[]) => any;
	enabled: boolean;
	children?: ReactNode;
}
