import React from 'react';
import styles from '../../styles.module.less';
import {TestHistoryService} from '../../../../../../services/test-history-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

interface Props {
	service: TestHistoryService
}

export function Specialist({service}: Props) {
	const specialistGroups = useBehaviorSubject(service.specialistGroups);
	const teacherName = useBehaviorSubject(service.teacherName);
	return <div>
		<span
						className={styles.settingsTitle}>{(specialistGroups.length > 0 ? 'Specialist' : 'Teacher') + ': '}</span>
		<span className={styles.name}>{teacherName}</span>
	</div>;
}
