import moment from 'moment';
import {HttpClient} from '@esgi/api';

export class Helper {
	formatDate(value: string, format: string) {
		if (value == null) {
			return '';
		}
		return moment.utc(value).format(format);
	}

	formatCronExpression(expr: string) {
		if (expr == null || expr === '') {
			return '';
		}

		const parts = expr.split(' ');
		if (parts.length < 5) {
			return '';
		}

		const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

		let result = '';
		let daysOfWeek = parts[5];
		if (daysOfWeek === '*') {
			result = days.join(', ');
		} else {
			let indexes = daysOfWeek.split(',').map((val) => {
				return parseInt(val.trim(), 10);
			});
			for (let i = 0; i < indexes.length; i++) {
				result += days[indexes[i] - 1] + ', ';
			}
			result = result.trim();
			result = result.slice(0, result.length - 1);
		}
		if (parts[1] && parts[2]) {
			let minutes = parseInt(parts[1], 10);
			let hours = parseInt(parts[2], 10);
			let meridian = hours >= 12 ? 'PM' : 'AM';
			result += ' in ' + (hours % 12 || 12) + ':' + (minutes < 10 ? '0' + minutes : minutes) + meridian + ' (EST)';
		}

		return result;
	}

	convertStringToDate(strDate: string) {
		return moment.utc(strDate).toDate();
	}

	convertDateToString(date: Date) {
		if (date == null) {
			return null;
		}
		return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
	}

	getById<T>(array: Array<T>, idField: string, searchId: any) {
		for (let i = 0; i < array.length; i++) {
			if (array[i][idField] === searchId) {
				return array[i];
			}
		}
		return null;
	}

	getSelectedTest(tests: ObsTestModel[], targetTest: TestItem): ObsTestModel | undefined {
		return tests.find(t => t.testID === targetTest.testId && t.subjectId === targetTest.subjectId);
	}
}

export class Container {
	tests: KnockoutObservableArray<TestModel>;
	templates: KnockoutObservableArray<TemplateModel>;
	exportTypes: KnockoutObservableArray<ExportTypeModel>;
	exportDates: KnockoutObservableArray<ExportDateModel>;
	defaultEmailDestination: EmailDestination;
	defaultSftpDestination: SftpDestination;

	static get(response: ContainerResponse) {
		let container = new Container();
		container.defaultEmailDestination = response.defaultEmailDestination;
		container.defaultSftpDestination = response.defaultSftpDestination;
		container.tests = ko.observableArray(response.tests);
		container.templates = ko.observableArray(response.templates);
		container.exportTypes = ko.observableArray(response.exportTypes);
		container.exportDates = ko.observableArray(response.exportDates);
		return container;
	}
}

export class ContainerResponse {
	tests: Array<TestModel>;
	templates: Array<TemplateModel>;
	exportTypes: Array<ExportTypeModel>;
	exportDates: Array<ExportDateModel>;
	defaultEmailDestination: EmailDestination;
	defaultSftpDestination: SftpDestination;
}

export class ExportDateModel {
	displayName: string;
	value: string;

	constructor(date: ExportDateModel) {
		this.displayName = date.displayName;
		this.value = date.value;
	}
}

export class ExportTypeModel {
	displayName: string;
	value: string;
}

export class TemplateModel {
	userID: number;
	templateID: number;
	templateName: string;
	exportType: string;
	tests: Array<TestItem>;
	schedule: boolean;
	scheduleCronExpression: string;
	emailDestination: EmailDestination;
	sftpDestination: SftpDestination;
	teacherIDN: string;
	studentIDN: string;

}

export class BaseDestination {
	enable: boolean;
	type: string;
}

export class EmailDestination extends BaseDestination {
	emailAddresses: Array<string>;
}

export class SftpDestination extends BaseDestination {
	sftpAddress: string;
	sftpLogin: string;
	sftpPassword: string;
}

export class TestModel {
	contentArea: string;
	subjectName: string;
	subjectId: number;
	subjectType: string;
	name: string;
	testID: number;
	questionsCount: number;
	creatorName: string;
	createDate: string;

	constructor(model: TestModel) {
		this.contentArea = model.contentArea;
		this.subjectName = model.subjectName;
		this.subjectId = model.subjectId;
		this.subjectType = model.subjectType;
		this.name = model.name;
		this.testID = model.testID;
		this.questionsCount = model.questionsCount;
		this.creatorName = model.creatorName;
		this.createDate = model.createDate;
	}
}

export class ObsTestModel extends TestModel {
	selected: KnockoutObservable<boolean>;

	constructor(test: TestModel) {
		super(test);
		this.selected = ko.observable(false);
	}
}

export class ExportDeliveryStatus {
	destinationType: string;
	isSuccess: boolean;
	messages: Array<string>;

}


export class HistoryModel {
	id: number;
	createDate: string;
	fileName: string;
	errorMessage: string;
	status: string;
	initializationType: string;
	deliveryStatuses: Array<ExportDeliveryStatus>;

	constructor(model: HistoryModel) {
		this.id = model.id;
		this.createDate = model.createDate;
		this.fileName = model.fileName;
		this.errorMessage = model.errorMessage;
		this.status = model.status;
		this.initializationType = model.initializationType;
		this.deliveryStatuses = model.deliveryStatuses;
	}
}

export class TestItem {
	testId: number;
	subjectId: number;
	subjectType: string;

	constructor(testId: number, subjectId: number, subjectType: string) {
		this.testId = testId;
		this.subjectId = subjectId;
		this.subjectType = subjectType;
	}
}

export class RequestExportModel {
	exportType: string;
	testItems: Array<TestItem>;
	globalSchoolYearID: number;
	dateToExport: string;
	teacherIDN: string;
	studentIDN: string;
}

export class ExportApi {
	private controllerName = 'export';

	initContainer(globalSchoolYearId: number) {
		return HttpClient.default.ESGIApi
			.get<ContainerResponse>(this.controllerName, 'init-container', {globalSchoolYearId: globalSchoolYearId}).toPromise();
	}

	run(model: RequestExportModel) {
		return HttpClient.default.ESGIApi.post<HistoryModel>(this.controllerName, 'run', model).toPromise();
	}

	history(userId: number, offset: number, count: number) {
		return HttpClient.default.ESGIApi.get<HistoryModel[]>(this.controllerName, 'history', {userId: userId, offset: offset, count: count})
			.toPromise();
	}

	progress(ids) {
		return HttpClient.default.ESGIApi.get<HistoryModel[]>(this.controllerName, 'progress', {ids: ids}).toPromise();
	}

	downloadLink(historyId: number) {
		return HttpClient.default.ESGIApi.get<string>(this.controllerName, 'download-link', {historyId: historyId}).toPromise();
	}

	exportTemplate = {
		update: (model: TemplateModel) => {
			return HttpClient.default.ESGIApi.post<TemplateModel>(this.controllerName, 'update-template', model).toPromise();
		},
		delete: (templateId: number) => {
			return HttpClient.default.ESGIApi.post(this.controllerName, 'delete-template', {templateID: templateId}).toPromise();
		},
		create: (model: TemplateModel) => {
			return HttpClient.default.ESGIApi.post<TemplateModel>(this.controllerName, 'create-template', model).toPromise();
		},
	}
}
