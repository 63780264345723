import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Text as TextUI} from '@esgi/ui/typography';
import {CentralPanel} from '@esgi/main/kits/common';
import {SkeletonStylesWithBorders} from '@esgi/ui/skeleton';

export const Root = styled(CentralPanel.Root, {
	maxWidth: 'unset',
	paddingLeft: 20,
	paddingRight: 20,
	paddingTop: 14,
	paddingBottom: 20,
	gap: '$5',
});

export const Header = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'start',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto 1fr',
	gap: '$4',
	minHeight: '40px',
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
});

export const IconWrapper = styled(FlexBox, {
	width: 40,
	height: 40,
	borderRadius: 12,
	borderWidth: 1.5,
	borderStyle: 'solid',
	borderColor: '$border',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	color: '$primary',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const ClassLabel = styled(GridBox, {
	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});