import React, {createRef} from 'react';
import {
	ModalTheme,
	CloseIcon,
	Modal, ModalManagerRefObject,
	Title,
} from '@esgillc/ui-kit/modal';
import RubricPreviewForm from '../../../common/preview-form/rubric-preview-form';
import RubricService from '../../../common/rubric-service';
import styles from './preview.module.less';

interface Props {
	rubricService: RubricService;
	onCloseClicked: () => void;
}

export default class PreviewModal extends React.PureComponent<Props> {
	private readonly modalManagerRef: ModalManagerRefObject = createRef();

	public render() {
		const {name, description} = this.props.rubricService.testInfo$.value;
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Header className={ModalTheme.header.blueHeader}>
				<Title>
					Preview
				</Title>
				<CloseIcon color='#FFFFFF' onClick={() => this.modalManagerRef.current.close(this.props.onCloseClicked)}/>
			</Modal.Header>
			<Modal.Body>
				<div className={styles.body}>
					<div className={styles.subHeader}>
						<span className={styles.name}>
							{name}
						</span>
						<span className={styles.divider}>:</span>
						<span className={styles.description}>
							{description}
						</span>
					</div>
					<div className={styles.previewFormContainer}>
						<RubricPreviewForm rubricService={this.props.rubricService} showCellHeader maxWidth={950}/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer/>
		</Modal>;
	}
}
