import {HttpClient, ObservableBuilder} from '@esgi/api';
import {Requests, Responses} from 'shared/modules/test-details/api/models';

export class Api {
	private static testDetailsControllerName: string = 'assets/test-details';
	private static testsControllerName: string = 'assets/tests';
	private static questionsControllerName = 'assets/questions';

	public static init(request: Requests.IInit): ObservableBuilder<Responses.IInit> {
		return HttpClient.default.ESGIApi.get<Responses.IInit>(this.testDetailsControllerName, 'init', request);
	}

	public static copyTest(request: Requests.ICopyTest): ObservableBuilder<Responses.ISaveTest> {
		return HttpClient.default.ESGIApi.post<Responses.ISaveTest>(this.testsControllerName, 'copy', request);
	}

	public static checkTestIdnExist(request: Requests.ICheckTestIdn): ObservableBuilder<Responses.ICheckTestIdn> {
		return HttpClient.default.ESGIApi.get<Responses.ICheckTestIdn>(this.testDetailsControllerName, 'check-test-idn-exists', request);
	}

	public static deleteQuestion(questionID: number): ObservableBuilder<any> {
		return HttpClient.default.ESGIApi.post(this.questionsControllerName, 'delete', {questionID});
	}

	public static setTestExplorerImage(request: Requests.ITestQuestion): ObservableBuilder<any> {
		return HttpClient.default.ESGIApi.post(this.questionsControllerName, 'set-test-explorer-image', request);
	}

	public static saveTest(request: Requests.ISaveTest): ObservableBuilder<Responses.ISaveTest> {
		return HttpClient.default.ESGIApi.post<Responses.ISaveTest>(this.testDetailsControllerName, 'save', request);
	}

	public static updateTestType(request: Requests.IUpdateTestType): ObservableBuilder<Responses.IUpdateTestType> {
		return HttpClient.default.ESGIApi.put<Responses.IUpdateTestType>(this.testDetailsControllerName, 'update-type', request);
	}

	public static publishTest(request: Requests.ITestPublish): ObservableBuilder<Responses.ISaveTest> {
		return HttpClient.default.ESGIApi.post<Responses.ISaveTest>(this.testDetailsControllerName, 'publish', request);
	}

	public static deleteTest(request: Requests.IDeleteTest): ObservableBuilder<Responses.ISaveTest> {
		return HttpClient.default.ESGIApi.post<Responses.ISaveTest>(this.testDetailsControllerName, 'delete', request);
	}

	public static updateBackgroundColor(request: Requests.IUpdateBackgroundColor) {
		return HttpClient.default.ESGIApi.post(this.testDetailsControllerName, 'update-background-color', request);
	}

	public static updateMostRecentColors(request: Requests.IUpdateMostRecentColors) {
		return HttpClient.default.ESGIApi.post(this.testDetailsControllerName, 'update-most-recent-colors', request);
	}

	public static updateQuestionStateStandard(request: Requests.IUpdateQuestionStateStandard) {
		return HttpClient.default.ESGIApi.post(this.testDetailsControllerName, 'update-question-state-standard', request);
	}
}
