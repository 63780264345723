import './checkbox.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import React from 'react';

class Props {
	checked: boolean;
	label?: string | JSX.Element;
	onClick: (value: boolean) => any;
	id: string;
	className?: string;
	disabled?: boolean;
	title?: string;
	intermediate?: boolean = false;
}

/**
 * @deprecated Use UI-Kit implementation instead.
 */
export class Checkbox extends React.PureComponent<Props> {
	render() {
		return <OnHoverTooltip message={this.props.title}><div className={this.props.className ? 'checkbox-component ' + this.props.className : 'checkbox-component'} data-name={this.props.label}>
            <input className='ace disable-position checkbox-input' id={this.props.id} type='checkbox' checked={this.props.checked} disabled={!!this.props.disabled} onChange={(e) => this.props.onClick && this.props.onClick(e.target.checked)} />
            <label className={ `lbl ${this.props.intermediate ? 'intermediate' : ''}`} htmlFor={this.props.id}>{this.props.label && this.props.label}</label>
        </div></OnHoverTooltip>;
	}
}
