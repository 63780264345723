import {ContentEditableDiv, ErrorWrapper, Placeholder, Wrapper} from './styled';
import {forwardRef, HTMLProps, useCallback, useContext} from 'react';
import {DroppableTextEditorContext} from '../../context';
import {BaseComponentProps} from '../../../../types';
import {convertEditorHTMLToText} from '../../utils';

type DroppableInputProps = BaseComponentProps & Omit<HTMLProps<HTMLDivElement>, 'children'>;
export const DroppableTextBox = forwardRef<HTMLDivElement, DroppableInputProps>(({dataCy='droppable-text-editor', ...props}, ref) => {

	const {onDrop, onDrag, onKeyDown, disabled, onChange, editorRef, value, error, errorContent, placeholder} = useContext(DroppableTextEditorContext);

	const handleInput = useCallback(() => {
		if (editorRef?.current) {
			const selection = window.getSelection();
			if (selection && selection.rangeCount > 0) {
				const range = selection.getRangeAt(0);
				const startContainer = range.startContainer;
				const startOffset = range.startOffset;
				const processTextNode = (node: Node) => {
					if(node.textContent?.includes('\u200B')) {
						node.textContent = node.textContent.replace(/\u200B/g, '');
					}
				};
				const processNode = (node: Node) => {
					if (node.nodeType === Node.TEXT_NODE) {
						processTextNode(node);
					} else {
						node.childNodes.forEach(child => processNode(child));
					}
				};

				processNode(editorRef.current);
				selection.removeAllRanges();
				const newRange = document.createRange();
				if (startContainer.parentNode) {
					newRange.setStart(startContainer, Math.min(startOffset, startContainer?.textContent?.length || 0));
				} else {
					newRange.setStart(editorRef.current, 0);
				}

				newRange.collapse(true);
				selection.addRange(newRange);
				onChange(convertEditorHTMLToText(editorRef.current.innerHTML));
			}
		}
	}, [onChange]);



	return <Wrapper {...props} ref={ref} data-cy={`${dataCy}-wrapper`} valued={Boolean(value)} withError={Boolean(error)}>
			{placeholder && (
				<Placeholder data-cy={`${dataCy}-placeholder`} font='mono'>
					{placeholder}
				</Placeholder>
			)}

			<ContentEditableDiv
				onInput={handleInput}
				data-component='content-editable-box'
				data-cy={`${dataCy}-text-box`}
				contentEditable={!disabled}
				onDrop={onDrop}
				ref={editorRef}
				suppressContentEditableWarning={true}
				onKeyDown={onKeyDown}
				data-disabled={disabled}
				data-error={error}
				onDragStart={onDrag}
			/>
			{errorContent && (
				<ErrorWrapper data-cy={`${dataCy}-error`}>
					{errorContent}
				</ErrorWrapper>
			)}
		</Wrapper>;
});
