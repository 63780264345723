import {styled} from '@esgi/ui/theme';
import {FlexBox, Box} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const Detail = styled(FlexBox, {
	flexDirection: 'column',
	zIndex: 1,
	flexShrink: 0,
	width: 300,
	maxHeight: 480,
	position: 'relative',
	borderRight: '2px solid $neutral94',
	background: '$vivid',
});

export const DetailHeader = styled(FlexBox, {
	justifyContent: 'space-between',
	alignItems: 'center',
	background: '$primary',
	whiteSpace: 'nowrap',
	padding: '0 10px',
});

export const DetailBody = styled(Box, {
	padding: '15px 10px',
	overflow: 'hidden auto',

	'> * + *': {
		marginTop: 15,
	},
});

export const ButtonIcon = styled(Button.Icon, {
	width: 32,
	height: 32,
	color: '$vivid',
});

export const TestData = styled(FlexBox, {
	justifyContent: 'space-between',
});

export const QuestionData = styled(Box, {
	overflow: 'auto',

	'> div': {
		whiteSpace: 'nowrap',

		'&:not(:first-child)': {
			marginTop: 15,
		},
	},
});

export const Toggler = styled(Box, {
	position: 'absolute',
	top: 0,
	right: -20,
	width: 19,
	height: 32,
	cursor: 'pointer',
	boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.4)',

	'&:after': {
		content: '',
		display: 'block',
		width: 0,
		height: 0,
		borderTop: '6px solid transparent',
		borderBottom: '6px solid transparent',
		borderRight: '6px solid #828282',
		margin: '9px 6px',
	},
});
