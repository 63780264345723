import './style.less';
import React from 'react';
import {Loader} from '@esgi/deprecated/jquery';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';

export class State {
}

export class Props extends SharedProps<State> {
    loading: boolean;
    delay?: number;
}

export class IGLoader extends SharedComponent<State, Props> {
    timer: number;
    defaultDelay = 300;
    private loader = new Loader('.image-gallery-modal .main-container');

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    	const delay = this.props.delay || this.defaultDelay;
    	if (this.props.loading && !prevProps.loading) {
    		this.timer = window.setTimeout(() => this.mask(), delay);
    	}
    	if (!this.props.loading && prevProps.loading) {
    		this.unmask();
    	}

    	super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    private mask() {
    	this.loader.mask();
    }

    private unmask() {
    	setTimeout(() => this.loader.unmask(), 300);
    }

    render(): JSX.Element | false | null {
    	return <></>;
    }
}