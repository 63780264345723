import {TestType, SubjectType, StudentSort} from '@esgi/core/enums';
import {HierarchySnapshot} from 'modules/hierarchy/models';

export interface SettingsModel {
	sortBy: StudentSort;
	printInColor: boolean;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	districtName: string;
	showEachStudent: boolean;
	schoolID?: number;
	teacherID: number;
	classID: number;
	schools: SchoolModel[];
	groupID: number;
	groups: UnityModel[];
	specialistGroupID: number;
	specialistGroups: UnityModel[];
	studentID: number;
	subjectID: number;
	subjects: SubjectModel[];
	gradeLevels: GradeLevelModel[];
}

export interface HierarchyModel {
	schoolID?: number;
	teacherID: number;
	classID: number;
	groupID: number;
	studentID: number;
	subjectID: number;
}

export interface SchoolModel {
	schoolID: number;
	name: string;
	teachers: TeacherModel[];
}

export interface TeacherModel {
	teacherID: number;
	name: string;
	classes: UnityModel[];
}

export interface SimpleSubjectModel {
	subjectID: number;
	subjectType: SubjectType;
}

export interface SubjectModel extends SimpleSubjectModel {
	subjectName: string;
}

export interface UnityModel {
	unityID: number;
	name: string;
	students: StudentModel[];
}

export interface StudentModel {
	studentID: number;
	firstName: string;
	lastName: string;
}

export interface ReportData {
	tests: TestModel[];
	students: StudentResultModel[];
}

export interface ChartBlockModel {
	correctPercent: number;
	combinedMaxScore?: number;
	combinedTestResult?: TestResultCombinedModel;

	testResult?: TestResultModel;

	testID: number;
	studentID: number;

	pieChartOptions: any;
}

export interface TestModel {
	testID: number;
	type: TestType;
	name: string;
	maxScore: number;
}

export interface StudentResultModel {
	firstName: string;
	lastName: string;
	studentID: number;
	gradeLevelID: number;
	testResults: TestResultModel[];
}

export interface TestResultModel {
	testID: number;
	correctAnswers: number;
	lastTestDate: string;
}

export interface TestResultCombinedModel {
	testID: number;
	correctAnswers: number;
	studentsTested: number;
}

export interface GradeLevelModel {
	gradeLevelID: number;
	name: string;
}

export interface BackgroundGenerationModel {
	reportGuid: string;
}

export interface SettingsRequest{
	userID: number;
	hierarchy: HierarchySnapshot;
	globalSchoolYearID: number;
}

export interface ReportRequest{
	teacherIDs: number[];
	subjects: SimpleSubjectModel[];
	hierarchy: HierarchySnapshot;
	globalSchoolYearID: number;
}

export interface SubjectsResponse {
	subjects: SubjectResponse[];
}

export interface SubjectResponse {
	id: number;
	type: SubjectType;
	name: string;
}

export interface OnRenderedParams{
	width: number;
	height: number;
}

export enum FilterType {
	Class = 'class',
	Grade = 'gradeLevel',
	Student = 'student',
	School = 'school',
	Teacher = 'teacher',
	Unity = 'unity',
	Subject = 'subject'
}
