
import {useState} from 'react';
import {PieChartService} from '@esgi/main/features/teacher/pie-chart';
import {useBehaviorSubject, useStream} from '@esgi/ui/utils';
import {SelectableListItem} from '@esgi/main/kits/reports';
import {SingleSelectState} from '@esgi/main/features/teacher/students-panel';
import {Class, Group, Student} from '@esgi/main/libs/store';

export function useReportState(service: PieChartService) {
	const [selectedEntity, setSelectedEntity] = useState<SelectableListItem | null>(null);
	const valid = useStream(service.valid$, false);
	const busy = useStream(service.busy$, false);
	const preview = useBehaviorSubject(service.preview$);
	const classes = useBehaviorSubject(service.studentService.classes$);
	const groups = useBehaviorSubject(service.studentService.groups$);
	const students = useBehaviorSubject(service.studentService.students$);
	const loadedStudents = useBehaviorSubject(service.studentService.loaded$);
	const subjects = useBehaviorSubject(service.subjectService.subjects$);
	const loadedSubjects = useBehaviorSubject(service.subjectService.loaded$);
	const subject = useBehaviorSubject(service.subject$);
	const entity = useBehaviorSubject(service.entity$);
	const tests = useBehaviorSubject(service.testService.tests$);
	const loadingTests = useBehaviorSubject(service.testService.loading$);
	const showEachStudent = useBehaviorSubject(service.showEachStudent$);

	const onEntitySelect = ({classId, groupId, studentId}: SingleSelectState) => {
		if (
			classId === null
			&& groupId === null
			&& studentId === null
		) {
			return;
		}

		let selected: Class | Group | (Student & {name?: string}) | undefined;

		if (classId) {
			if (classId === -1) {
				setSelectedEntity({
					value: '0',
					label: 'All',
					disabled: false,
				});
				return;
			}
			selected = service.studentService.getClass(classId);
		} else if (groupId) {
			selected = service.studentService.getGroup(groupId);
		} else {
			const student = service.studentService.get(studentId);
			if(student) {
				selected = {...student, name: `${student.firstName} ${student.lastName}`};
			}
		}

		setSelectedEntity({
			value: selected?.id.toString(),
			label: selected?.name,
			disabled: false,
		});
	};

	return {
		selectedEntity,
		preview,
		setPreview: service.setPreview.bind(service),
		valid,
		busy,
		classes,
		groups,
		students,
		loadedStudents,
		subjects,
		loadedSubjects,
		subject,
		entity,
		tests,
		loadingTests,
		showEachStudent,
		onEntitySelect,
	};
}
