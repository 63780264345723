import {ToggleGroup} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	gap: 2,
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	minWidth: 137,
	minHeight: 36,
});
