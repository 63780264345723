import {DistrictCommonData, Field} from 'modules/subject-details/types';
import BaseSelectorModal from '../../components/selector-modal';

interface Props {
	initiallySelected: number[];
	subjectTabName: string;
	districtSubjectData: DistrictCommonData;
	onConfirm: (values: Field[]) => void;
	onCancel: () => void;
	selectedGradeLevels?: string[];
	isShowISDSelector?: boolean;
	isShowISSSelector?: boolean;
	isShowSchoolSelector?: boolean;
}

export default function Selector({
	initiallySelected,
	selectedGradeLevels,
	districtSubjectData,
	subjectTabName,
	onCancel,
	onConfirm,
	isShowISDSelector,
	isShowISSSelector,
	isShowSchoolSelector,
}: Props) {

	if (isShowSchoolSelector) {
		return <BaseSelectorModal title='Teachers by School'
															description={`Subject tab ${subjectTabName} will be added to all ${selectedGradeLevels} teachers in the chosen school(s).`}
															source={districtSubjectData.schools}
															initiallySelected={districtSubjectData.schools.filter(s => initiallySelected.indexOf(s.id) > -1)}
															dropdownLabel='School'
															searchPlaceholder='Search Schools'
															onConfirm={onConfirm}
															onCancel={onCancel}/>;
	}


	if (isShowISSSelector) {
		return <BaseSelectorModal title='Schools'
															description={`Subject tab ${subjectTabName} will be assigned to specialists in the following schools.`}
															source={districtSubjectData.schoolSpecialistsSchools}
															initiallySelected={districtSubjectData.schools.filter(s => initiallySelected.indexOf(s.id) > -1)}
															dropdownLabel='Schools'
															searchPlaceholder='Search Schools'
															onConfirm={onConfirm}
															onCancel={onCancel}/>;
	}
	if (isShowISDSelector) {
		return <BaseSelectorModal title='District Specialists'
															description={`Subject tab ${subjectTabName} will be assigned to the following district specialists.`}
															source={districtSubjectData.districtSpecialists}
															initiallySelected={districtSubjectData.districtSpecialists.filter(s => initiallySelected.indexOf(s.id) > -1)}
															dropdownLabel='District Specialists'
															searchPlaceholder='Search by name'
															onConfirm={onConfirm}
															onCancel={onCancel}/>;
	}
}

