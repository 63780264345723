import React from 'react';
import './component.less';
import GradeScaleItem from 'shared/modules/grade-scale/grade-scale/selector/item/component';
import {GradeScaleService} from 'shared/modules/grade-scale/grade-scale/service';
import {GradeScaleType, GradeScaleWizardType, IGradeScale} from 'shared/modules/grade-scale/models';
import ShowMoreButton from 'shared/modules/grade-scale/show-more-button/component';
import {IWizardGradeScale} from 'shared/modules/grade-scale/grade-scale/models';

export class Props {
	service: GradeScaleService;
}

export class State {
	selectedScale?: IWizardGradeScale;
	defaultScales: IWizardGradeScale[] = [];
	stockScales: IWizardGradeScale[] = [];
	personalScales: IWizardGradeScale[] = [];
	districtScales: IWizardGradeScale[] = [];
}

export default class Selector extends React.PureComponent<Props, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

	componentDidMount() {
		this.props.service.selectedScale$.subscribe((selectedScale) => {
			this.setState({selectedScale: selectedScale});
		});
		
		this.props.service.scales$.subscribe((scales) => {
			const defaultScales = scales.filter(s => s.wizardType === GradeScaleWizardType.Default);
			const stockScales = scales.filter(s => s.wizardType === GradeScaleWizardType.Stock);
			const personalScales = scales.filter(s => s.wizardType === GradeScaleWizardType.Personal);
			const districtScales = scales.filter(s => s.wizardType === GradeScaleWizardType.District);
			
			this.setState({
				defaultScales: defaultScales,
				districtScales: districtScales,
				personalScales: personalScales,
				stockScales: stockScales,
			});
		});
	}

	selectGradeScale(id: number) {
		this.props.service.selectScale(id);
	}

	renderGradeScaleItem(gs: IWizardGradeScale) {
		return <GradeScaleItem
			key={gs.id}
			gradeScale={gs}
			selected={gs.id === this.state.selectedScale.id}
			onSelect={() => this.selectGradeScale(gs.id)}>
		</GradeScaleItem>;
	}
	
	render() {
		return <div className='grade-scale-selector'>
			<div className='grade-scale-header'>
				Select Your Grade Scale
				<a target='_blank' href='https://support.esgisoftware.com/hc/en-us/articles/115005886826-Class-Grade-Report-Grading-Scales' rel='noreferrer'>
					<i className='glyphicon glyphicon-question-sign tip'></i>
				</a>
			</div>
			{this.state.selectedScale !== null && <div className='grade-scale-list'>
				<div className='grades-line first'>My Scales</div>
				{this.state.defaultScales.map(gs => {
					return this.renderGradeScaleItem(gs);
				})}
				{this.state.personalScales.map(gs => {
					return this.renderGradeScaleItem(gs);
				})}
				
				<div className='grades-line'>System Scales</div>
				{this.state.stockScales.map(gs => {
					return this.renderGradeScaleItem(gs);
				})}

				{this.state.districtScales.length > 0 && <div className='grades-line'>District Scales</div>}

				{this.state.districtScales.length > 0 && this.state.districtScales.map(gs => {
					return this.renderGradeScaleItem(gs);
				})}
			</div>}
		</div>;
	}
}