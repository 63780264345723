import {Modal, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import styles from '../styles.module.less';

interface Props {
	onClicked: () => void;
}

export function MaximumStudents({onClicked}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClicked);

	return <Modal modalManagerRef={modalRef}>
		<Modal.Body className={styles.body}>
			<span data-cy={'maximum-students'} className={styles.text}>
				Your File Exceeds the Maximum Number of Students.
			</span>
		</Modal.Body>
		<Modal.Footer>
			<Buttons.Text onClick={handleClose}>GOT IT</Buttons.Text>
		</Modal.Footer>
	</Modal>;
}