import {Card} from '@esgi/ui/layout';
import {TestGroupStudentsModel} from '../../../../../../../../types';
import {CardRoot, ActionButtonsDivider} from '../../components/components.styled';
import {CardHeader} from '../../components/card-header';
import {Button} from '@esgi/ui';
import {CardFooter} from './components/card-footer';
import {CardBody} from './components/card-body';
import {History, More} from '@esgi/ui/icons';

type Props = Pick<TestGroupStudentsModel, 'testInfo' | 'classResult' | 'studentsResult'>;

export function DraggableCard({testInfo, classResult, studentsResult}: Props) {
	return (
		<CardRoot>
			<CardHeader testID={testInfo.id} testName={testInfo.name} testType={testInfo.type} standards={testInfo.stateStandards}/>

			<Card.Separator />

			<CardBody classResult={classResult} studentsResult={studentsResult} testInfo={testInfo} />

			<Card.Separator />

			<CardFooter testInfo={testInfo}>
				<Button.Icon disabled>
					<More />
				</Button.Icon>

				<ActionButtonsDivider />

				<Button.Icon disabled>
					<History />
				</Button.Icon>
			</CardFooter>
		</CardRoot>
	);
}
