import {BehaviorSubject, Subscription, of, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {TestGroupStudentsModel, TestModel, TestSingleStudentModel} from '@esgi/main/features/teacher/home';
import {Student, SubjectTab, SubjectType, subjectsStore} from '@esgi/main/libs/store';
import {
	V2AssetsSubjectsCommonController,
	V2PersonalSubjectsController,
	V2TeachersPagesHomeSectionsTestsController,
} from '@esgi/contracts/esgi';
import {isUndefined} from '@esgi/ui';
import {TestContentArea} from '@esgi/main/kits/common';
import {SubjectType as SubjectTypeContract} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {TestType} from '@esgi/main/libs/core';
import {TestStorage} from '../components/test-section-content/types';

export class DataTestsService extends BaseService {
	public isLoadedData$ = new BehaviorSubject(true);

	public allSingleStudentTests$ = new BehaviorSubject<TestSingleStudentModel[]>([]);
	public allGroupStudentsTests$ = new BehaviorSubject<TestGroupStudentsModel[]>([]);
	public subjects$ = new BehaviorSubject<SubjectTab[]>([]);

	private readonly controller = new V2TeachersPagesHomeSectionsTestsController();
	private readonly subjectsCommonController = new V2AssetsSubjectsCommonController();

	private readonly personalSubjectsController = new V2PersonalSubjectsController();

	private getTestsRequestSubscription: Subscription | null = null;

	private subjectsStorage = subjectsStore();

	constructor() {
		super();
		this.subjectsStorage.get().subscribe(({data: v}) => {
			this.subjects$.next(v);
		});
	}

	public fetchTestCardsByStudent({
		studentID,
		studentsIDsForStatistic,
		subjectID,
		subjectType,
	}: {
		subjectID: SubjectTab['id'];
		subjectType: SubjectType;
		studentID: Student['id'];
		studentsIDsForStatistic: Student['id'][];
	}) {
		this.getTestsRequestSubscription?.unsubscribe();

		this.getTestsRequestSubscription = this.controller
			.testCardsByStudent({
				subjectID,
				subjectType: subjectType as unknown as SubjectTypeContract,
				studentID,
				studentsIDs: studentsIDsForStatistic,
			})
			.subscribe({
				next: ({testCards}) => {
					this.allSingleStudentTests$.next(
						testCards.map((card) => ({
							...card,
							testInfo: {
								...card.testInfo,
								contentArea: card.testInfo.contentArea as TestContentArea,
								type: card.testInfo.type as unknown as TestType,
							},
						})),
					);
				},
				complete: this.setDataIsLoaded.bind(this),
			});
	}

	public fetchTestCardsByStudents({
		studentIDs,
		subjectID,
		subjectType,
	}: {
		subjectID: SubjectTab['id'];
		subjectType: SubjectType;
		studentIDs: Student['id'][];
	}) {
		this.getTestsRequestSubscription?.unsubscribe();

		this.getTestsRequestSubscription = this.controller
			.testCardsByStudents({
				subjectID,
				subjectType: subjectType as unknown as SubjectTypeContract,
				studentIDs,
			})
			.subscribe({
				next: ({testCards}) => {
					this.allGroupStudentsTests$.next(
						testCards.map((card) => ({
							...card,
							testInfo: {
								...card.testInfo,
								contentArea: card.testInfo.contentArea as TestContentArea,
								type: card.testInfo.type as unknown as TestType,
							},
						})),
					);
				},
				complete: this.setDataIsLoaded.bind(this),
			});
	}

	public reorderAllSingleStudentTests({
		tests,
		subjectInfo,
	}: {
		tests: TestSingleStudentModel[];
		subjectInfo: SubjectTab;
	}) {
		this.reorderTests({tests, subjectInfo}).subscribe();

		this.allSingleStudentTests$.next(tests);
	}

	public reorderAllGroupStudentsTests({
		tests,
		subjectInfo,
	}: {
		tests: TestGroupStudentsModel[];
		subjectInfo: SubjectTab;
	}) {
		this.reorderTests({tests, subjectInfo}).subscribe();

		this.allGroupStudentsTests$.next(tests);
	}

	public onMoveToTestRequest({
		previousSubject,
		newSubject,
		testID,
	}: {
		previousSubject: SubjectTab;
		newSubject: SubjectTab;
		testID: number;
	}) {
		return this.personalSubjectsController
			.testsMove({
				sourceSubjectID: previousSubject.id,
				destinationSubjectID: newSubject.id,
				testID,
				subjectType: newSubject.type as unknown as SubjectTypeContract,
				orderNumber: -1,
			})
			.pipe(
				tap(() => {
					const test = previousSubject.tests.find(({id}) => testID === id);

					this.subjectsStorage
						.update((iteratedSubject) => {
							if (iteratedSubject.id === previousSubject.id) {
								return {
									...iteratedSubject,
									tests: iteratedSubject.tests.filter(({id}) => id !== testID),
								};
							}

							if (!isUndefined(test) && iteratedSubject.id === newSubject.id) {
								return {
									...iteratedSubject,
									tests: [...iteratedSubject.tests, test],
								};
							}

							return iteratedSubject;
						});
				}),
			);
	}

	public onRemoveTestRequest({testID, subjectID}: {testID: number; subjectID: SubjectTab['id']}) {
		const subjects = this.subjects$.value;
		const subject = subjects.find(({id}) => id === subjectID);

		if (!subject) {
			return of();
		}

		return this.personalSubjectsController
			.testsRemove({
				subjectID,
				subjectType: subject.type as unknown as SubjectTypeContract,
				testID,
			})
			.pipe(
				tap(() => {
					this.subjectsStorage
						.update((item) => {
							if(item.id === subject.id) {
								return {
									...subject,
									tests: subject.tests.filter(({id}) => id !== testID),
								};
							}
							return item;
						});
				}),
			);
	}

	public updateTestInStorage({
		subjectID,
		testID,
		newTestValue,
	}: {
		subjectID: SubjectTab['id'];
		testID: TestStorage['id'];
		newTestValue: Partial<Omit<TestStorage, 'id'>>;
	}) {
		this.subjectsStorage
			.update((iteratedSubject) => {
				if (iteratedSubject.id === subjectID) {
					return {
						...iteratedSubject,
						tests: iteratedSubject.tests.map((iteratedTest) =>
							iteratedTest.id === testID
								? {
										...iteratedTest,
										...newTestValue,
								  }
								: iteratedTest,
						),
					};
				}

				return iteratedSubject;
			});
	}

	public override dispose() {
		super.dispose();
		this.subjectsCommonController.dispose();
		this.personalSubjectsController.dispose();
		this.controller.dispose();
	}

	private setDataIsLoaded() {
		this.isLoadedData$.next(true);
	}

	private reorderTests<T extends TestModel>({tests, subjectInfo}: {tests: T[]; subjectInfo: SubjectTab}) {
		return this.subjectsCommonController.testsReorder({
			subjectID: subjectInfo.id,
			subjectType: subjectInfo.type as unknown as SubjectTypeContract,
			testIds: tests.map(({testInfo}) => testInfo.id),
		});
	}
}
