import {Dictionary, GeneralResponse} from 'modules/forms/students-form/types';

export const getGeneralFormValues = (general: GeneralResponse, dictionary: Dictionary) => {
	const {gradeLevels, genders, languages} = dictionary;

	const {
		firstName,
		lastName,
		gradeLevelID,
		gender,
		languageID,
		studentIDN,
		exportIDN,
		credentialModel,
	} = general;

	return {
		firstName: firstName ?? '',
		lastName: lastName ?? '',
		exportIDN: exportIDN ?? '',
		gradeLevel: gradeLevels.filter(v => v.id.toString() === gradeLevelID.toString()),
		gender: [genders.find(v => v.id === gender)],
		language: languages.filter(v => v.id.toString() === languageID.toString()),
		studentIDN: studentIDN ?? '',
		school: [],
		userName: credentialModel?.userName ?? '',
		password: credentialModel?.password ?? '',
	};
};
