import {useSpring, config} from 'react-spring';

export function useArcAnimation({progressFrom, progressTo}: {progressFrom: number; progressTo: number}) {
	return useSpring({
		from: {
			progress: progressFrom,
		},
		to: {
			progress: progressTo,
		},
		config: {
			...config.molasses,
			clamp: true,
		},
	});
}
