import {UserType} from '@esgi/core/authentication';
import {
	SpecialistGroupChangedEvent,
	SpecialistGroupCreatedEvent,
	SpecialistGroupRemoveEvent,
} from 'shared/modules/forms/specialist-group-form/events';
import {ISpecialistGroupModel} from '../core/api';
import {withGroupID} from '../core/builders/specialist';
import {HierarchyState} from './hierarchy-data-service';

export function applySpecialistGroupsCreated(args: SpecialistGroupCreatedEvent, hierarchy: HierarchyState): HierarchyState {
	const {districtSpecialists, schoolSpecialists, specialistGroups} = hierarchy;
	let userType = UserType.V;
	if (districtSpecialists.find(t => t.userID === args.userID)) {
		userType = UserType.ISD;
	} else if (schoolSpecialists.find(t => t.userID === args.userID)) {
		userType = UserType.ISS;
	}

	const group: ISpecialistGroupModel = {
		groupID: args.groupId,
		userID: args.userID,
		name: args.name,
		type: userType,
	};

	specialistGroups.items = specialistGroups.items.concat(group);
	return hierarchy;
}

export function applySpecialistGroupChanged(args: SpecialistGroupChangedEvent, hierarchy: HierarchyState): HierarchyState {
	const {specialistGroups} = hierarchy;
	specialistGroups.items = specialistGroups.items.map(s => s.groupID === args.groupId ? {...s, name: args.name} : s);
	return hierarchy;
}

export function applySpecialistGroupRemoved(args: SpecialistGroupRemoveEvent, hierarchy: HierarchyState): HierarchyState {
	const {specialistGroups, students, specialist} = hierarchy;

	specialistGroups.items = specialistGroups.items.filter(t => t.groupID !== args.id);
	students.items = students.items.map(t => {
		return t.specialistGroups.includes(args.id) ? {
			...t,
			specialistGroups: t.specialistGroups.filter(f => f !== args.id),
		} : t;
	});

	if (specialist.groupID === args.id) {
		hierarchy.specialist = withGroupID(specialist, 0);
	}
	return hierarchy;
}
