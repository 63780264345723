import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {drawerPaddingInline} from './drawer-root/index.styled';

export const Divider = styled(Box, {
	width: `calc(100% + 2 * ${drawerPaddingInline}px)`,
	height: 1,
	backgroundColor: '$mild',
	marginLeft: -drawerPaddingInline,
	marginRight: -drawerPaddingInline,
});
