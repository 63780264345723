import {BaseService} from '@esgi/core/service';
import {Language} from '../models';
import {BehaviorSubject, Observable} from 'rxjs';
import {
	LanguageRequestCreate,
	LanguageRequestUniqueness,
	LanguageRequestUpdate,
	LanguageResponseCreate,
	LanguageResponseUniqueness,
} from '../api-models';
import {FormControl, FormGroup, ValidatorArgs, Validators} from '@esgillc/ui-kit/form';
import {map} from 'rxjs/operators';

export const LanguageTemplateApiHost = 'reports/parent-letter/language-template';

export class LanguageTemplateService extends BaseService {
	public oldName = new BehaviorSubject<string>(null);
	public language = new BehaviorSubject<Language>(null);
	public languageName = new BehaviorSubject<string>('');
	public errors = new BehaviorSubject<string[]>([]);
	public isDirty = new BehaviorSubject<boolean>(false);
	public form = new FormGroup({
		language: new FormControl<string>('', {validators: [Validators.required()]}),
	});
	readonly maxLength: number = 15;

	constructor(private teacherID: number, language?: Language) {
		super();
		this.language.next(language);
		const languageName = language?.languageName || '';
		this.form.controls.language.value = languageName;
		this.oldName.next(languageName);
		this.form.controls.language.onChanged.subscribe((newName) => {
			this.languageName.next(newName.currState.value);
			this.isDirty.next(!!newName && newName.currState.value.toLowerCase() !== this.oldName.value.toLowerCase());
		});

	}

	public validateUniqueness(args: ValidatorArgs<string>): Observable<ValidatorArgs<string>> {
		const v = args.field.currentValue;
		if (!v.length) {
			args.errors.push('unique');
		}

		const requestData = new LanguageRequestUniqueness();
		requestData.name = v;
		requestData.userID = this.teacherID;

		return this.httpClient.ESGIApi.get<LanguageResponseUniqueness>(LanguageTemplateApiHost, 'check-uniqueness', requestData).pipe(map(resp => {
			if (!resp.isUnique) {
				args.errors.push('unique');
			}
			return args;
		})).asObservable();
	}

	public create() {
		const name = this.form.value.language.trim();
		const requestData = new LanguageRequestCreate();
		requestData.name = name;
		requestData.userID = this.teacherID;

		return this.httpClient.ESGIApi.post<LanguageResponseCreate>(LanguageTemplateApiHost, 'create', requestData);
	}

	public update() {
		const name = this.form.value.language.trim();
		const languageID = this.language.value.languageId;

		const requestData = new LanguageRequestUpdate();
		requestData.name = name;
		requestData.languageID = languageID;
		requestData.userID = this.teacherID;

		return this.httpClient.ESGIApi.post<void>(LanguageTemplateApiHost, 'update', requestData);
	}
}
