import {StudentSortModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/models/student-sort-model';
import {StudentSort} from '@esgi/contracts/esgi/types/esgi.enums/student-sort';
import {OutModel as OutModelContract} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/teacher/init/out-model';

export type OutModel = Omit<OutModelContract, 'testResultsCorrectVerbiage' | 'testResultsIncorrectVerbiage'> & {
	testResultsCorrectVerbiage: string | null;
	testResultsIncorrectVerbiage: string | null;
};

export enum TestSessionResult {
	Success = 'success',
	InProgress = 'inProgress',
}

export enum SettingsTab {
	SystemSettings = 'systemSettings',
	LanguagesAndTemplates = 'languagesAndTemplates',
	GradeScales = 'gradeScales',
	KeyboardShortcuts = 'keyboardShortcuts',
	TestButtonLabels = 'testButtonLabels',
	StudentTestingOptions = 'studentTestingOptions',
	TestResultVerbiage = 'testResultVerbiage',
	TestSessionResults = 'testSessionResults',
}

export interface TestSessionProgress {
	questions: number;
	correctAnswers: number;
}

export interface StudentTestingOptions {
	lockScreen: boolean;
	screenLockPIN: string;
	showSelfAssessOption: boolean;
	onUpdateInitData?: (props: Partial<OutModel>) => void;
	onCloseDrawer?: VoidFunction;
	onUpdateTabTouchedData?: (tab: SettingsTab, isTouched: boolean) => void;
}

export interface TestButtonLabels {
	testButtonsLabelsEnabled: boolean;
	correctTestLabel: string;
	incorrectTestLabel: string;
	onUpdateInitData?: (props: Partial<OutModel>) => void;
	onCloseDrawer?: VoidFunction;
	onUpdateTabTouchedData?: (tab: SettingsTab, isTouched: boolean) => void;
}

export interface TimeZone {
	id: string;
	name: string;
}

export interface SystemSettingsInitData {
	sortByVariants: StudentSortModel[];
	sortBy: StudentSort;
	timeZones: TimeZone[];
	selectedTimeZone: TimeZone;
}

export type TestResultsVerbiage = Pick<
	OutModel,
	'testResultsVerbiagesEnabled' | 'testResultsCorrectVerbiage' | 'testResultsIncorrectVerbiage'
> & {
	onUpdateInitData: (props: Partial<OutModel>) => void;
	onCloseDrawer?: VoidFunction;
	onUpdateTabTouchedData?: (tab: SettingsTab, isTouched: boolean) => void;
};

export interface SaveStudentTestingOptionsData {
	isUnlockHomepage: boolean;
	isSelfAssessment?: boolean;
	isKeyboardShortcuts?: boolean;
	pin?: string;
}

export interface TabItem {
	value: SettingsTab;
	name: string;
}

export interface TabItemsGroup {
	general: TabItem[];
	testing: TabItem[];
	testSessionResults: TabItem[];
}

export type SettingsTabTouchedData = {
	[tab in SettingsTab]: boolean;
}
