import React from 'react';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ArrowIcon} from '../arrow';
import {MainTable, TableHeader, HeaderRow, Row, Cell, TableBody} from '../index.styled';
import {Report, Row as ReportRow} from '../../../../../service/types';
import {SortOptions} from '../../../types';

type Props = {
	report: Report;
	onSortableCellClick: (cellIndex: number) => void;
	sortableRows: ReportRow[];
	sortOptions: SortOptions;
	previousSortedColumnIndex: number;
}

export function LeftPanel({report, onSortableCellClick, sortableRows, sortOptions, previousSortedColumnIndex}: Props) {
	return (
		<Box dataCy='left-panel'>
			<Box>
				<MainTable>
					<TableHeader>
						<HeaderRow>
							<Cell data-cy='students-header' simple bgGray colSpan={report.valueToSkip}>
								<Text size='xLarge'>Students</Text>
							</Cell>
						</HeaderRow>
						<Row>
							{report.table.sortRow.cells.map((cell, index) => (
								index < report.valueToSkip && <Cell data-cy='student-name-header' simple studentNameHeader bgGray key={index}>
									<ArrowIcon
										previousSortedColumnIndex={previousSortedColumnIndex}
										sortOptions={sortOptions}
										sorted={index === sortOptions.columnIndex}
										cellValue={cell.value}
										onClick={() => onSortableCellClick(index)}
									/>
								</Cell>
							))}
						</Row>
					</TableHeader>
					<TableBody>
						{sortableRows.map((row, rowIndex) => (
							<Row data-cy='student-name-row' bgLightgray={rowIndex % 2 !== 0} key={rowIndex}>
								{row.cells.map((cell, cellIndex) => (
									cellIndex < report.valueToSkip && (
										<Cell data-cy='student-name-cell' simple studentName key={cellIndex}>{cell.value}</Cell>
									)
								))}
							</Row>
						))}
					</TableBody>
				</MainTable>
			</Box>
		</Box>
	);
}