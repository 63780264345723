import {fileTypes} from './utils';
import {CameraIcon} from '@esgillc/ui-kit/icons';
import styles from './styles.module.less';

interface Props {
	onChanged: (image: File) => void;
}

export function ImageUploader({onChanged}: Props) {

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const onImageChange = (e, isDrop: boolean) => {
		e.preventDefault();
		const {files} = isDrop ? e.dataTransfer : e.target;
		onChanged(files[0]);
	};

	return <label data-cy='image-uploader' onDrop={(e) => onImageChange(e, true)}
								onDragOver={handleDragOver}
								className={styles.uploadPhotoContainer}>
		<div className={styles.imageCircle}>
			<input data-cy='upload-file-input' type='file'
						 accept={fileTypes.join()}
						 onChange={(e) => onImageChange(e, false)}
			/>
			<CameraIcon className={styles.cameraIcon}/>
			<span data-cy='add-label' className={styles.label}>Add Photo</span>
		</div>
	</label>;
}
