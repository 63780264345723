import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Buttons} from '@esgillc/ui-kit/button';
import {ConflictResolutionService} from './service';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {SaveDuplicateName} from '../../notifications/save-duplicate-name';
import {ConflictMessageWizard} from './components/conflict-message-wizard';
import {ConflictTable} from './components/conflict-table';
import {
	ConflictType,
	Notification, SaveConflictStudentsResponse,
	ServerConflictStudentModel, ValidationStatus,
} from 'shared/modules/student-roster-upload-tool/types';
import styles from './styles.module.less';

interface Props {
	onDeleteFileClicked: () => void;
	onFileUploadIsComplete: (isDuplicateSaved: boolean, isCompletedWithExistsStudents: boolean, studentsCreatedCount: number) => void;
	onGetStudentProfile?: (id: number) => void;
	students: ServerConflictStudentModel[];
	specialistGroupID?: number;
	onExit: () => void;
	classID: number;
	schoolID: number;
}

export function ConflictResolution({
	onExit,
	onFileUploadIsComplete,
	onGetStudentProfile,
	onDeleteFileClicked,
	students,
	classID,
	specialistGroupID,
	schoolID,
}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onExit);
	const service = useService(ConflictResolutionService);
	const conflictStudents = useBehaviorSubject(service.conflictStudents);
	const notification = useBehaviorSubject(service.notification);
	const selectedConflict = useBehaviorSubject(service.selectedConflict);
	const isDuplicateIDConflict = useBehaviorSubject(service.isDuplicateIDConflict);
	const completedWithExistsStudents = useBehaviorSubject(service.completedWithExistsStudents);

	const [isSaveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);

	useEffect(() => {
		service.init(classID, schoolID, students, specialistGroupID);
	}, []);

	useEffect(() => {
		setSaveButtonDisabled(isDuplicateIDConflict);
	}, [isDuplicateIDConflict]);

	const getExistingStudentID = (id: number) => {
		onGetStudentProfile(id);
	};

	const handleFileUploadCompletion = (res: SaveConflictStudentsResponse) => {
		setSaveButtonDisabled(false);
		if (res.validation.status === ValidationStatus[ValidationStatus.Complete] ||
			res.validation.status === ValidationStatus[ValidationStatus.CompleteWithStudentsExistInClass]) {
			onFileUploadIsComplete(true, completedWithExistsStudents, res.createdStudents.length);
		}
	};

	const onSaveClicked = () => {
		service.handleSave().subscribe((res) => handleFileUploadCompletion(res));
	};

	const onSaveDuplicateNameClicked = () => {
		service.onSaveDuplicateNameClicked().subscribe((res) => handleFileUploadCompletion(res));
	};

	const onDeleteDuplicateNamesClicked = () => {
		service.onDeleteDuplicateNamesClicked().subscribe((res) => handleFileUploadCompletion(res));
	};

	return <div data-cy='conflict-resolution'>
		{notification === Notification.SaveDuplicateName && <SaveDuplicateName
			onSaveClicked={onSaveDuplicateNameClicked}
			onDeleteClicked={onDeleteDuplicateNamesClicked}
			studentNames={service.getDuplicateNames().map(s => s.name.value)}
		/>}
		<Modal modalManagerRef={modalRef}>
			<Modal.Header>
				<Title className={styles.title}>
					{`${students?.length} Students Need Attention`}
				</Title>
				<CloseIcon onClick={handleClose}/>
			</Modal.Header>
			<Modal.Body>
				<div data-cy='body-container' className={styles.conflictTableContent}
					 onClick={() => service.selectedConflict.next(ConflictType.None)}>
					Some students in your file have duplicate names with students that already exist in ESGI or have some missing fields (e.g. language).<br/>
					If a student is a duplicate, please remove them from the uploaded list and assign the student to
					your class manually within Student Manager.<br/>
					Review highlighted fields and update as indicated below.<br/>

					<ConflictMessageWizard conflictType={selectedConflict}/>

					<ConflictTable
						onView={getExistingStudentID}
						students={conflictStudents}
						onEdit={(students, validation) => service.onEdit(students, validation)}
						onClick={(validation) => service.onConflictTableClick(validation)}
						onReset={service.reset}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer className={styles.footer}>
				<Buttons.Gray onClick={onDeleteFileClicked}>DELETE FILE UPLOAD</Buttons.Gray>
				<Buttons.Contained onClick={onSaveClicked} disabled={isSaveButtonDisabled}>
					SAVE AND FINISH UPLOAD
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>
	</div>;
}
