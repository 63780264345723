import {Buttons, Container} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Access, AccessError, Button, Close, getThemeColor} from '@esgi/ui';
import {Student} from '@esgi/main/libs/store';
import {Dispatch} from 'react';

type Props = {
	id: number;
	firstName: string;
	lastName: string;
	hasCredentials: boolean;
	onClearButtonClick: Dispatch<Student['id']>;
	onOpenStudentCredentials: Dispatch<Student['id']>;
};

export function SelectedStudentRow({
	id,
	firstName,
	lastName,
	hasCredentials,
	onClearButtonClick,
	onOpenStudentCredentials,
}: Props) {
	return (
		<Container dataCy='selected-student-row'>
			<Text size='medium' data-cy='fullname'>{`${firstName} ${lastName}`}</Text>
			<Buttons>
				<Button.Icon dataCy='credentials-button' onClick={() => onOpenStudentCredentials(id)}>
					{hasCredentials ? <Access /> : <AccessError color={getThemeColor('negative')} />}
				</Button.Icon>
				<Button.Icon dataCy='clear-button'>
					<Close onClick={() => onClearButtonClick(id)} />
				</Button.Icon>
			</Buttons>
		</Container>
	);
}
