import styles from './styles.module.less';
import {join} from '@esgillc/ui-kit/utils';
import {TestModel} from 'modules/reports/iep-progress/types';
import moment from 'moment';

interface Props {
	test: TestModel;
	markingPeriod?: number;
}

export const DetailReportScores = ({test, markingPeriod}: Props) => {
	// group test results by 8 sessions
	const groupedResults = test.testResults?.reduce((acc, result, index) => {
		const groupIndex = Math.floor(index / 8);
		acc[groupIndex] = [...(acc[groupIndex] ?? []), result];
		return acc;
	}, [] as TestModel['testResults'][]);
	return (
		<div className={styles.scoreContainer}>
			<table className={styles.table}>
				<tbody>
				{markingPeriod &&
					<tr>
						<td colSpan={9} className={styles.bgGray}>
							<strong>Sessions for Marking Period {markingPeriod}</strong>
						</td>
					</tr>
				}
				{groupedResults?.map((group, index) => (
					<>
						<tr>
							<td className={join(styles.bgGray, styles.cell, styles.smText)}>Sessions</td>
							{group?.map((result, index) => (
								<td
									key={`table-iep-progress-${result.date}-${index}`}
									className={join(styles.bgGray, styles.cell, index === 0 && styles.basis, styles.smText)}
								>
									{result.isBaseline ? <strong>B</strong> : moment(result.date).format('MM/DD')}
								</td>
							))}
						</tr>
						<tr>
							<td className={join(styles.cell, styles.smText)}>Score</td>
							{group?.map((result, index) => (
								<td key={`score-${result.date}-${index}`}>
									{result.score ?? 0}
								</td>
							))}
						</tr>
					</>
				))}
				</tbody>
			</table>
		</div>
	);
};
