import {useCallback} from 'react';
import {LangTypeOption, langTypeList} from '../../../../model';
import {Drawer} from '@esgi/main/kits/common';
import {DrawerContentContainer} from '../../../../index.styled';
import {
	SelectableList,
	SelectableListItem,
	SelectableLisItemContent,
} from './index.styled';

interface Props {
	completedLangTypes: LangTypeOption[];
	selectedLangTypes: LangTypeOption[];
	toggleLangType: (value: LangTypeOption) => void;
}

export function TranslatePanel({
	completedLangTypes,
	selectedLangTypes,
	toggleLangType,
}: Props) {
	const value = (selectedLangTypes || []).map((lang) => lang.value);
	const completedValue = (completedLangTypes || []).map((lang) => lang.value);
	const checked = useCallback((val: string) => value.includes(val), [value]);
	const completed = useCallback(
		(val: string) => completedValue.includes(val),
		[completedValue]
	);

	return (
		<DrawerContentContainer>
			<Drawer.ContentBlock title='Select languages' withDivider>
				<SelectableList>
					<SelectableList.GroupRoot type='multiple' value={value}>
						<SelectableList.Group>
							{langTypeList.map((langType) => (
								<SelectableListItem
									key={langType.value}
									value={langType.value}
									checked={checked(langType.value)}
									disabled={completed(langType.value)}
									onClick={() => toggleLangType(langType)}
								>
									<SelectableLisItemContent>
										{langType.label}
									</SelectableLisItemContent>
								</SelectableListItem>
							))}
						</SelectableList.Group>
					</SelectableList.GroupRoot>
				</SelectableList>
			</Drawer.ContentBlock>
		</DrawerContentContainer>
	);
}
