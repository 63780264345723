import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EmptyContent = styled(GridBox, {
	borderRadius: 6,
	border: '1px solid $vivid',
	backgroundColor: '$background',
	padding: '11px 20px',
	height: 'max-content',
	justifyContent: 'center',
	gap: 6,
	textAlign: 'center',

	variants: {
		size: {
			full: {
				width: '100%',
			},
		},

		position: {
			center: {
				alignSelf: 'center',
				justifySelf: 'center',
			},
		},

		type: {
			students: {
				margin: '20px auto',
				borderColor: '$border',
				backgroundColor: '$vivid',
			},
		},
	},
});
