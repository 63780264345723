import * as React from 'react';
import type {SVGProps} from 'react';

export function Sort(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M8.998 9a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2h-14Zm0 6a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Zm0 6a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4ZM23.764 17.242l-2.829-2.828a1 1 0 0 0-.963-.26 1.007 1.007 0 0 0-.537.28l-2.828 2.828a1 1 0 0 0 1.414 1.414l1.236-1.236.01 4.56a1 1 0 1 0 2 0l-.01-4.436 1.092 1.092a1 1 0 0 0 1.415-1.414Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
