import {TextStyle} from 'shared/modules/question-editor/models';

export class UndoRedoModel {
	value: string;
	style: TextStyle;
}

export class EditingUndoRedoManager {
	curStep: number = -1;
	styles: UndoRedoModel[] = [];

	addStep(style: UndoRedoModel) {
		if (this.curStep < this.styles.length - 1) {
			this.styles = this.styles.slice(0, this.curStep + 1);
		}

		this.curStep++;
		this.styles.push(style);
	}

	undo() {
		if (this.curStep > 0) {
			this.curStep--;
		}

		return this.styles[this.curStep];
	}

	redo() {
		if (this.curStep < this.styles.length - 1) {
			this.curStep++;
		}

		return this.styles[this.curStep];
	}

	clear() {
		this.curStep = -1;
		this.styles = [];
	}
}