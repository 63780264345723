import React from 'react';
import {ConferencerItem, Language, LetterType, Student} from 'pages/parent-conferencer/models/models';
import ParentLetterEditor from 'pages/parent-conferencer/components/parent-letter-editor/parent-letter-editor';

class State {
	editLetterTemplate: boolean = false;
}

class Props {
	clearClicked: boolean;
	conferencerItems: ConferencerItem[];
	students: Student[];
	languages: Language[];
	letterTypes: LetterType[];
	teacherName: string;
	schoolName: string;
	printSchedule: () => void;
	printLetters: (type: number, studentID: number) => void;
	clearSchedule: () => void;
}

export class Header extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = new State();
	}

	get printable() {
		return this.props.conferencerItems.length > 0;
	}

	render() {
		return <>
			{this.props.conferencerItems &&
			<>
				<div className={'header' + (this.printable ? '' : ' centered')}>
					{this.printable &&
					<div className='btn-group downloads-dropdown non-visible'>
						<span data-toggle='dropdown'><i className='fa fa-download'/> Download</span>
					</div>
					}
					<h1>Parent Conferencer</h1>
					{this.printable &&
					<div className='btn-group downloads-dropdown'>
						<span data-toggle='dropdown'><i className='fa fa-download'/> Download</span>
						<ul className='dropdown-menu dropdown-default'>
							<li><a className='print-schedule-link' href='#' onClick={() => {
								this.props.printSchedule();
							}}>Conference Schedule</a></li>
							<li><a className='print-all-letters-link' href='#' onClick={() => {
								this.props.printLetters(2, 0);
							}}>Conference Invitation</a></li>
							<li><a className='print-all-reminders-link' href='#' onClick={() => {
								this.props.printLetters(3, 0);
							}}>Conference Reminder</a></li>
						</ul>
					</div>
					}
				</div>
				<div className='teacher-info'>
					<div className='title'>Name:</div>
					<div className='name'>{this.props.teacherName}</div>
				</div>
				<div className='teacher-info'>
					<div className='title'>School:</div>
					<div className='name'>{this.props.schoolName}</div>
				</div>
			</>
			}
			<div className='table-header'>
				<div>
					<div className='title'>Conference Schedule
						<span>&nbsp;&nbsp;</span>
						<a className='edit-template-link' onClick={() => this.props.clearSchedule()}>
							{this.props.clearClicked ? 'Reset schedule' : 'Clear schedule'}
						</a>
					</div>
				</div>
				<div>
					<a className='edit-template-link' onClick={this.editTemplates}>
						<svg xmlns='http://www.w3.org/2000/svg' width='15' height='14' viewBox='0 0 15 14' fill='none'>
							<path
								d='M8.9034 2.33642L11.816 5.19826L4.44334 12.4424L1.53235 9.58059L8.9034 2.33642ZM14.0262 1.64621L12.7272 0.369929C12.2253 -0.12331 11.4101 -0.12331 10.9064 0.369929L9.66219 1.59248L12.5748 4.45434L14.0262 3.0283C14.4155 2.64572 14.4155 2.02877 14.0262 1.64621ZM0.0081052 13.6031C-0.0449012 13.8375 0.170481 14.0476 0.409064 13.9906L3.65472 13.2173L0.743724 10.3555L0.0081052 13.6031Z'
								fill='#0088CC'/>
						</svg>
						Edit Invitation and Reminder Messages
					</a>
				</div>
			</div>
			{this.state.editLetterTemplate &&
			<ParentLetterEditor
				languages={this.props.languages}
				letterTypes={this.props.letterTypes}
				onClosed={() => this.setState({editLetterTemplate: false})}
			/>
			}
		</>;
	}

	editTemplates = () => {
		this.setState({editLetterTemplate: true});
	}
}
