import React, {PureComponent} from 'react';
import {tryCall} from '@esgillc/ui-kit/utils';

interface Props {
	onClick?: () => void;
}

export class CriteriaActiveIcon extends PureComponent<Props> {
	public render() {
		return <svg width='92' height='97' viewBox='0 0 92 97' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={() => tryCall(this.props.onClick)}>
			<rect x='1.82837' y='1.5' width='88.3434' height='93.4437' rx='4.5' fill='white' stroke='#81D4F7'
			      strokeWidth='3'/>
			<rect x='10.1745' y='10.3098' width='13.6922' height='67.4781' rx='4.5' fill='#51C3F2' stroke='#81D4F7'
			      strokeWidth='3'/>
			<rect x='31.0397' y='10.3098' width='50.7859' height='12.3012' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
			<rect x='31.0397' y='30.7113' width='50.7859' height='46.1492' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
		</svg>;
	}
}

export class LevelActiveIcon extends PureComponent<Props> {
	public render() {
		return <svg width='92' height='97' viewBox='0 0 92 97' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={() => tryCall(this.props.onClick)}>
			<rect x='1.5' y='1.5' width='88.3434' height='93.4437' rx='4.5' fill='white' stroke='#81D4F7'
			      strokeWidth='3'/>
			<rect x='9.84619' y='10.3098' width='13.6922' height='67.4781' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
			<g>
				<rect x='30.7114' y='10.3098' width='50.7859' height='12.3012' rx='4.5' fill='#51C3F2' stroke='#81D4F7'
				      strokeWidth='3'/>
			</g>
			<rect x='30.7114' y='30.7113' width='50.7859' height='46.1492' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
		</svg>;
	}
}

export class DescriptionActiveIcon extends PureComponent<Props> {
	public render() {
		return <svg width='92' height='97' viewBox='0 0 92 97' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={() => tryCall(this.props.onClick)}>
			<rect x='2.15662' y='1.5' width='88.3434' height='93.4437' rx='4.5' fill='white' stroke='#81D4F7'
			      strokeWidth='3'/>
			<rect x='10.5027' y='10.3098' width='13.6922' height='67.4781' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
			<rect x='31.3679' y='10.3098' width='50.7859' height='12.3012' rx='4.5' stroke='#81D4F7' strokeWidth='3'/>
			<g>
				<rect x='31.3679' y='30.7113' width='50.7859' height='46.1492' rx='4.5' fill='#51C3F2' stroke='#81D4F7'
				      strokeWidth='3'/>
			</g>
		</svg>;
	}
}


export class FirstStepIcon extends PureComponent<Props> {
	public render() {
		return <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={() => tryCall(this.props.onClick)}>
			<circle cx='22' cy='22' r='21' fill='white' stroke='#51C3F2' strokeWidth='2'/>
			<path d='M24.4902 29H22.127V17.584L18.6406 18.7754V16.7734L24.1875 14.7324H24.4902V29Z' fill='#0088CC'/>
		</svg>;
	}
}


export class SecondStepIcon extends PureComponent<Props> {
	public render() {
		return <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={() => tryCall(this.props.onClick)}>
			<circle cx='22' cy='22' r='21' fill='white' stroke='#51C3F2' strokeWidth='2'/>
			<path
				d='M27.625 29H18.0645V27.3691L22.8008 22.2031C23.4844 21.4414 23.9694 20.8066 24.2559 20.2988C24.5488 19.7845 24.6953 19.2702 24.6953 18.7559C24.6953 18.0788 24.5033 17.5319 24.1191 17.1152C23.7415 16.6986 23.2305 16.4902 22.5859 16.4902C21.8177 16.4902 21.222 16.7246 20.7988 17.1934C20.3757 17.6621 20.1641 18.3034 20.1641 19.1172H17.791C17.791 18.2513 17.9863 17.4733 18.377 16.7832C18.7741 16.0866 19.3372 15.5462 20.0664 15.1621C20.8021 14.778 21.6484 14.5859 22.6055 14.5859C23.9857 14.5859 25.0729 14.9342 25.8672 15.6309C26.668 16.321 27.0684 17.278 27.0684 18.502C27.0684 19.2116 26.8665 19.957 26.4629 20.7383C26.0658 21.513 25.4115 22.3952 24.5 23.3848L21.0234 27.1055H27.625V29Z'
				fill='#0088CC'/>
		</svg>;
	}
}


export class ThirdStepIcon extends PureComponent<Props> {
	public render() {
		return <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'
		            onClick={() => tryCall(this.props.onClick)}>
			<circle cx='22' cy='22' r='21' fill='white' stroke='#51C3F2' strokeWidth='2'/>
			<path
				d='M20.8086 20.8066H22.2539C22.9831 20.8001 23.5592 20.6113 23.9824 20.2402C24.4121 19.8691 24.627 19.332 24.627 18.6289C24.627 17.9518 24.4479 17.4277 24.0898 17.0566C23.7383 16.679 23.1979 16.4902 22.4688 16.4902C21.8307 16.4902 21.3066 16.6758 20.8965 17.0469C20.4863 17.4115 20.2812 17.89 20.2812 18.4824H17.9082C17.9082 17.7533 18.1003 17.0892 18.4844 16.4902C18.875 15.8913 19.4154 15.4258 20.1055 15.0938C20.8021 14.7552 21.5801 14.5859 22.4395 14.5859C23.8587 14.5859 24.972 14.944 25.7793 15.6602C26.5931 16.3698 27 17.3594 27 18.6289C27 19.2669 26.7949 19.8691 26.3848 20.4355C25.9811 20.9954 25.457 21.4186 24.8125 21.7051C25.5938 21.972 26.1862 22.3919 26.5898 22.9648C27 23.5378 27.2051 24.2214 27.2051 25.0156C27.2051 26.2917 26.7656 27.3073 25.8867 28.0625C25.0143 28.8177 23.8652 29.1953 22.4395 29.1953C21.0723 29.1953 19.9525 28.8307 19.0801 28.1016C18.2077 27.3724 17.7715 26.4023 17.7715 25.1914H20.1445C20.1445 25.8164 20.3529 26.3242 20.7695 26.7148C21.1927 27.1055 21.7591 27.3008 22.4688 27.3008C23.2044 27.3008 23.7839 27.1055 24.207 26.7148C24.6302 26.3242 24.8418 25.7578 24.8418 25.0156C24.8418 24.2669 24.6204 23.6908 24.1777 23.2871C23.735 22.8835 23.0775 22.6816 22.2051 22.6816H20.8086V20.8066Z'
				fill='#0088CC'/>
		</svg>;
	}
}
