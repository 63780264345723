import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';

export const Section = styled(FlexBox, {
	flexDirection: 'column',
	padding: '0 29px',

	'& > div': {
		marginTop: '12px',
	},
});

export const Title = styled(Text, {
	padding: '20px 0 12px',
	margin: '0 3px',
	borderBottom: '1px solid $mild',
	textAlign: 'center',
	display: 'block',
});

export const SelectRoot = Select.Root;

export const SelectContent = Select.Content;

export const SelectField = styled(Select.Field, {
	maxHeight: 40,
});

export const SelectOption = styled(Select.Option, {
	variants: {
		displayDefaultLabel: {
			true: {
				display: 'flex',
				justifyContent: 'space-between',

				'&:after': {
					content: 'Default',
					color: '$primary80',
				},
			},
		},
	},
});


export const Label = styled(Text, {
	textAlign: 'center',
});
