import React, {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Text} from '@esgi/ui/typography';
import {AppliedTo, BoxArrowUpRight, Button, CollapseSide} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {TestColorIndicator} from '@esgi/main/kits/common';
import {StudentModel, SubjectModel, TestLauncherData} from '../../types';
import {
	AlertFooter,
	ClassContainer,
	Container,
	Divider,
	Footer, ProgressBarContainer,
	SessionContainer,
	StudentContainer, StyledButton,
	SummaryNoteArea,
	TestContainer,
	TestName,
	TestType,
	TextName,
	Timestamp,
} from '../../../summary';
import {useNavigateToTestDashboard} from '@esgi/main/features/teacher/test-dashboard/utils';
import {ResultModel} from '../types';
import {GridBox} from '@esgi/ui/layout';
import {AnswersInfo, GridProgressBar, TestSessionDelta} from '../../../../../teacher/test-dashboard/src/kit';
import {CriteriaList} from './criteria-list';
import {Class} from '@esgi/main/libs/store';
import {useUser} from '@esgi/core/authentication';
import {getTimeWithTimeZone} from '../../utils';

type Props = {
    launcherData: TestLauncherData,
    result: ResultModel,
    subject: Pick<SubjectModel, 'id' | 'type' | 'name'>,
    student: StudentModel,
    studentClass: Class,
	onDoneClicked: (testSessionId: number, summaryText: string) => void;
    onExportClicked: VoidFunction,
};

export function SessionResults(props: Props) {
	const {timeZone} = useUser();
	const alert = Alert.useRef();
	const [summaryNote, setSummaryNote] = useState<string>(props.result.summaryNotes);
	const onTestDashboardClicked = useNavigateToTestDashboard();
	const location = useLocation();
	const isShowRedirectButton = !location.pathname.includes('test-dashboard');
	const close = Alert.useClose(alert, () => {
		props.onDoneClicked(props.result.testSessionID, summaryNote);
	});
	const [showCriteria, setShowCriteria] = useState(false);
	const date = useMemo(() => timeZone ? getTimeWithTimeZone(new Date(), timeZone) : new Date(), []);

	const maxScore = Math.max(...props.result.testModel.levelModels.map(({score}) => score));
	const total = props.launcherData.analyticsData?.total || maxScore * props.result.answers.length;

	const rubricProgressItems = useMemo(() =>
		props.result.answers?.map((answer, idx) => ({
			id: answer.criteriaID,
			value: answer.score,
			maxValue: maxScore,
			name: props.result.testModel.criteriaModels[idx].name,
		})),
	[props.result.answers, maxScore, props.result.testModel.criteriaModels]);

	const correctAnswers = useMemo(() => props.result.answers.reduce((acc, {score}) => score + acc, 0), [props.result.answers]) ;
	const delta = correctAnswers - (props.launcherData.studentResult?.correctAnswers ?? 0);

	return <Alert modalManagerRef={alert} colorConfig={alertColorsConfig.neutral} dataCy='session-results'>
        <Alert.Header onCloseIconClick={close}>
            <Text size='large'>Session Results</Text>
        </Alert.Header>
        <Alert.Body>
            <Container>
                <TestContainer>
                    <TestName>
                        <TestColorIndicator testID={props.launcherData.testID}/>
                        <TextName size='xxLarge' data-cy='test-name'>
                            {props.result.testModel.rubricName}
                        </TextName>
                        <TestType size='small' data-cy='test-type'>
                            Rubric
                        </TestType>
                    </TestName>
					{isShowRedirectButton && <Button.Icon
						data-cy='test-dashboard-link'
						css={{width: 36, height: 36}}
						onClick={() =>
							onTestDashboardClicked({
								dashboardType: 'student',
								subjectID: props.subject.id,
								testID: props.launcherData.testID,
								subjectType: props.subject.type,
								studentID: props.student.id,
							})
						}
					>
						<BoxArrowUpRight/>
					</Button.Icon>}
                </TestContainer>
                <Divider/>
		            <ProgressBarContainer>
				            <GridProgressBar items={rubricProgressItems}>
						            <GridBox>
							            <AnswersInfo value={correctAnswers} maxValue={total} transcriptText='Last Score' />
							            <TestSessionDelta value={Number(delta)} deltaTranscript='Since Last Test' lightZeroValue />
						            </GridBox>
				            </GridProgressBar>
		            </ProgressBarContainer>
	              {showCriteria && <CriteriaList maxValue={maxScore} criteria={rubricProgressItems}/>}
		            <GridBox>
			            <StyledButton
				            showCriteria={showCriteria}
				            onClick={() => setShowCriteria(prev => !prev)}
				            color='secondary'
			            >
				            <CollapseSide/>
				            <Text size='medium' bold>{`${showCriteria ? 'Hide' : 'Show'} Criteria`}</Text>
			            </StyledButton>
		            </GridBox>
                <ClassContainer dataCy='class-container'>
                    <Text size='small'>Class: </Text>
                    <Text size='small' bold>{props.studentClass?.name}</Text>
                </ClassContainer>
                <StudentContainer dataCy='student-container'>
                    <Text size='small'>Student: </Text>
                    <Text size='small' bold>{props.student.firstName} {props.student.lastName}</Text>
                </StudentContainer>
                <SessionContainer dataCy='session-container'>
                    <Text size='small'>Session: </Text>
	                <Timestamp date={date}/>
                </SessionContainer>

                <SummaryNoteArea placeholder='Summary Note' value={summaryNote}
                                 onChange={(e) => setSummaryNote(e.target.value)}/>
            </Container>
        </Alert.Body>
        <AlertFooter>
            <Footer>
                <Button color='tertiary' onClick={props.onExportClicked}>
                    <AppliedTo/>
                    <Text size='medium' bold>Export Rubric</Text>
                </Button>
                <Button color='secondary' onClick={close}>
                    <Text size='medium' bold>Done</Text>
                </Button>
            </Footer>
        </AlertFooter>
    </Alert>;
}
