import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ButtonsBox = styled(GridBox, {
	gap: 20,
	alignItems: 'center',
	gridAutoFlow: 'column',
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
});
