import {PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {SubjectSectionMode} from '../types';
import {SubjectSectionContext} from '../context/subject-section-context';
import {SubjectSectionContextValue} from '../context/types';
import {ExpandablePanel} from '../../../kit';
import {Task} from '@esgi/ui/icons';

type Props = PropsWithChildren<{
	isPanelOpened: boolean;
	togglePanelOpened: VoidFunction;
	disablePanelToggling: boolean;
	isTablet: boolean;
}>;

export function SubjectSectionRoot({children, isPanelOpened, togglePanelOpened, disablePanelToggling, isTablet}: Props) {
	const [loaded, setLoaded] = useState(false);
	const [sectionMode, setSectionMode] = useState(SubjectSectionMode.View);

	const setSectionViewMode = useCallback(() => {
		setSectionMode(SubjectSectionMode.View);
	}, []);

	const value = useMemo<SubjectSectionContextValue>(
		() => ({
			setSectionViewMode,
			sectionMode,
			setSectionMode,
			loaded,
			setLoaded,
		}),
		[setSectionViewMode, sectionMode, setSectionMode, setLoaded, loaded],
	);

	return (
		<SubjectSectionContext.Provider value={value}>
			<ExpandablePanel
				PanelIcon={Task}
				loaded={loaded}
				disablePanelToggling={sectionMode !== SubjectSectionMode.View || disablePanelToggling}
				togglePanelOpened={togglePanelOpened}
				onClick={!isPanelOpened ? togglePanelOpened : undefined}
				isPanelOpened={isPanelOpened}
				dataCy='subjects-expandable-panel'
				isTablet={isTablet}
			>
				{children}
			</ExpandablePanel>
		</SubjectSectionContext.Provider>
	);
}
