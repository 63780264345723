import {GroupBucket, StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {useTeacherPageContext} from '../../../../../context/teacher-page-context';
import {noGroupsText} from '../constants';

type Props = {
	canAddGroup: boolean;
	groupsWithStudents: GroupBucket[];
};

export function DefaultContent({canAddGroup, groupsWithStudents}: Props) {
	const {openAddGroupForm} = useTeacherPageContext();

	const {
		selected: {studentId: selectedStudentId, groupId: selectedGroupId, studentFromGroupId, classId: selectedClassID},
		selectStudentFromGroup,
		selectGroup,
	} = StudentsPanel.Selection.Single.useSingleSelectContext();

	return (
		<StudentsPanel.Tabs.TabContent
			withBottomSectionButton={!groupsWithStudents.length}
			buttonText='Add a Group'
			isBottomSectionButtonDisabled={!canAddGroup}
			onBottomButtonClick={() => openAddGroupForm({classId: selectedClassID})}
		>
			{groupsWithStudents.length ? (
				groupsWithStudents.map(({id, name, students}) => {
					const isOpen = selectedGroupId === id || studentFromGroupId === id;

					return (
						<StudentsPanel.Lists.SelectableListGroup.Expandable.Single
							withCounter
							isListOpen={isOpen && Boolean(students.length)}
							key={id}
							hideArrowIcon={isOpen}
							onTriggerButtonClick={() => selectGroup(id)}
							groupName={name}
							onItemClick={(studentId) => selectStudentFromGroup({studentId, groupId: id})}
							value={selectedStudentId}
							isListSelected={selectedGroupId === id}
							items={students}
							allowSelectEmpty
						>
							{({item: {firstName, lastName}}) => (
								<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
							)}
						</StudentsPanel.Lists.SelectableListGroup.Expandable.Single>
					);
				})
			) : (
				<StudentsPanel.Tabs.EmptyTabContent
					size='full'
					templateText={noGroupsText}
					dataCy='students-panel-empty-expandable-content'
				/>
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
