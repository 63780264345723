import React, {createRef, RefObject} from 'react';
import {Modal, ModalManagerRefObject, Title} from '@esgillc/ui-kit/modal';
import ClearIcon from './components/clear-icon';
import styles from './note.module.less';
import {TextArea} from '@esgillc/ui-kit/control';
import {Buttons} from '@esgillc/ui-kit/button';

class State {
	details: string = '';
}

interface Props {
	initValue: string;
	criteriaName: string;
	levelName: string;
	onSave: (value: string) => void;
	onClose: () => void;
}

export default class NoteDialog extends React.PureComponent<Props, State> {
	private readonly modalManagerRef: ModalManagerRefObject = createRef();
	private readonly inputRef: RefObject<TextArea> = createRef();

	constructor(props: Props) {
		super(props);
		const state = new State();
		state.details = props.initValue || '';
		this.state = state;
	}

	private get headerTitle(): string {
		let out = 'Notes: ';
		out += this.props.criteriaName;

		if (this.props.levelName) {
			out += ` | ${this.props.levelName}`;
		}

		return out;
	}

	public render() {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<Modal.Header>
				<Title>{this.headerTitle}</Title>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<div className={styles.inputContainer}>
					<TextArea value={this.state.details}
					          ref={this.inputRef}
					          className={styles.inputField}
					          autoResizeToFit
					          autoFocus
					          maxLength={140}
					          rows={3}
					          placeholder='Enter Note'
					          onChange={(e) => this.setState({details: e.target.value})}/>
					<div className={styles.clear}>
						<ClearIcon onClick={() => this.setState({details: ''}, () => this.inputRef.current.focus())}/>
					</div>
					<div className={styles.counter}>{this.state.details.length}/140</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Buttons.Gray onClick={() => this.modalManagerRef.current.close(this.props.onClose)}>
					CANCEL
				</Buttons.Gray>
				<Buttons.Contained onClick={() => this.modalManagerRef.current.close(() => this.props.onSave(this.state.details))}>
					SAVE
				</Buttons.Contained>
			</Modal.Footer>
		</Modal>;
	}
}
