import './style.less';
import React from 'react';
import {SortDirection} from '@esgi/core/enums';
import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {State as ImageCardState} from '../cards/image-card';
import {ContextMenuState} from '../context-menu/context-menu';
import {ContextMenu} from './context-menu';
import {SortBy, SortModel} from './models';

export class State {
    type: SortBy = SortBy.CreateDate;
    direction: SortDirection = SortDirection.Desc;
    contextMenu: ContextMenuState = null;
}

export class Props extends SharedProps<State> {
    sortChanged: (model: SortModel) => any;
}

export class Sort extends SharedComponent<State, Props> {
    private ref: HTMLAnchorElement;

    render(): JSX.Element | false | null {
    	return <div className='sort'>
            <span>Sort by:</span>
            <span className='type' onClick={() => this.showDropdownMenu()}>{this.typeName()}</span>
            <a href='#' onClick={() => this.changeDirection()} ref={r => this.ref =r}>
                <div className={'arrow up ' + (this.state.direction == SortDirection.Asc ? 'active' : '')}/>
                <div className={'arrow down ' + (this.state.direction == SortDirection.Desc ? 'active' : '')}/>
            </a>
            {this.renderContextMenu()}
        </div>;
    }

    typeName() {
    	return this.state.type === SortBy.CreateDate ? 'Create Date' : 'Image Name';
    }

    renderContextMenu() {
    	if (!this.state.contextMenu) {
    		return null;
    	}
    	const coordinates = this.getOffsetRelativeToBody(this.ref);

    	return <ContextMenu
            close={() => this.closeContextMenu()}
            sortChanged={(e) => this.sortChanged(e)}
            x={coordinates.left}
            element={this.ref}
            y={coordinates.top}
            state={this.state.contextMenu}
            onChange={(ch, cb) => this.setState({contextMenu: ch}, cb)}/>;
    }
    private changeDirection() {
    	const direction = this.state.direction === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc;
    	this.props.sortChanged({type: this.state.type, direction: direction} as SortModel);
    	this.setState({direction: direction});
    }
    private sortChanged(e: SortBy) {
    	this.closeContextMenu();
    	let direction = this.state.direction;
    	if (e === this.state.type) {
    		direction = this.state.direction === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc;
    	}
    	this.props.sortChanged({type: e, direction: direction} as SortModel);
    	this.setState({type: e, direction: direction});
    }

    private closeContextMenu() {
    	this.setState({contextMenu: null});
    }

    private showDropdownMenu() {
    	if (!this.state.contextMenu) {
    		this.setState({contextMenu: new ContextMenuState()});
    	}
    }

    getOffsetRelativeToBody(el: HTMLElement) {
    	let rect = el.getBoundingClientRect(),
    		scrollLeft = (window.pageXOffset || document.documentElement.scrollLeft) - 158,
    		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    	return {top: rect.top + scrollTop + 15, left: rect.left + scrollLeft + 20};
    }
}

export class Sorter {
	public static By(a: Array<ImageCardState>, t: SortBy = SortBy.CreateDate, direction: SortDirection = SortDirection.Desc) {
		if (t === SortBy.CreateDate) {
			return Sorter.ByCreateDate(a, direction);
		}
		if (t === SortBy.Name) {
			return Sorter.ByImageName(a, direction);
		}
	}

	private static ByCreateDate(a: Array<ImageCardState>, direction: SortDirection) {
		const r = a.sort((a, b) => {
			if (a.createDate < b.createDate) {
				return -1;
			}
			if (a.createDate > b.createDate) {
				return 1;
			}
			if(a.id < b.id) {
				return -1;
			}
			if(a.id > b.id) {
				return 1;
			}
			return 0;
		});
		if (direction === SortDirection.Desc) {
			return r.reverse();
		} else {
			return r;
		}
	}

	private static ByImageName(a: Array<ImageCardState>, direction: SortDirection) {
		const r = a.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		if (direction === SortDirection.Desc) {
			return r.reverse();
		} else {
			return r;
		}
	}
}
