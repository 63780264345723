import {useCallback, useEffect, useState, useMemo} from 'react';
import {config, useTransition} from 'react-spring';
import {Container, Content, Cheap, Checkbox} from './index.styled';
import {Button, Close} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Box, GridBox} from '@esgi/ui/layout';

interface Props {
	selectedCount: number;
	onDeselect: VoidFunction;
	onShowSelectedOnly: (value: boolean) => void;
	onSave: VoidFunction;
}

export function Sticky(props: Props) {
	const {selectedCount, onDeselect, onShowSelectedOnly, onSave} = props;
	const [showSelectedOnly, setShowSelectedOnly] = useState(false);

	const open = useMemo(() => selectedCount !== 0, [selectedCount]);

	const contentProps = useTransition(open, {
		from: {transform: 'translateY(100%)'},
		enter: {transform: 'translateY(0%)'},
		leave: {transform: 'translateY(100%)'},
		config: config.gentle,
	});

	const onCheckedChange = useCallback((value: boolean) => {
		setShowSelectedOnly(value);
		onShowSelectedOnly(value);
	}, [onShowSelectedOnly]);

	useEffect(() => {
		if (selectedCount === 0) {
			onCheckedChange(false);
		}
	}, [selectedCount]);

	return contentProps((styles, open) => open &&
		<Container style={styles}>
			<Content>
				<GridBox gapX='4' flow='column' align='center'>
					<Button.Icon onClick={onDeselect} withBackgroundHover>
						<Close />
					</Button.Icon>
					<Checkbox label='Show Only Selected' checked={showSelectedOnly} onCheckedChange={onCheckedChange}/>
				</GridBox>
				<GridBox gapX='4' flow='column' align='center'>
					<Box>
						<Text size='xSmall' font='mono' color='primary'>Tests Selected:</Text>
						<Cheap counter>
							<Text font='mono' size='small'>
								{selectedCount}
							</Text>
						</Cheap>
					</Box>
					<Button color='primary' uppercase onClick={onSave}>
						<Text size='large'>Add Selected</Text>
					</Button>
				</GridBox>
			</Content>
		</Container>
	);
}
