import React from 'react';
import {OverflowSpan} from '@esgillc/ui-kit/layout';
import styles from './starred-name.module.less';


interface StarredNameProps {
	name: string;
}


export default class StarredName extends React.Component<StarredNameProps> {

	private renderStar() {
		return <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M15 0L18.3677 10.3647H29.2658L20.4491 16.7705L23.8168 27.1353L15 20.7295L6.18322 27.1353L9.55093 16.7705L0.734152 10.3647H11.6323L15 0Z'
				fill='#0077B8'/>
		</svg>;
	}

	render() {
		return (
			<div className={styles.nameContainer}>
				<svg className={styles.circles} width='94' height='94' viewBox='0 0 94 94' fill='none'
				     xmlns='http://www.w3.org/2000/svg'>
					<circle cx='47' cy='47' r='45.5' fill='#E1F5FD' stroke='#51C3F2' strokeWidth='3'/>
					<circle cx='47.5341' cy='46.4659' r='37.4205' fill='white' stroke='#81D4F7'/>
				</svg>
				<div className={styles.nameBar}>
					{this.renderStar()}
					<OverflowSpan>
						{this.props.name}
					</OverflowSpan>
					{this.renderStar()}
				</div>
			</div>
		);
	}
}
