import {SubjectType} from '@esgi/core/enums';
import {Question, TemplateType} from '@esgi/selfassess';

export * from '@esgi/selfassess';

export interface AvailableTest {
	id: number;
	creatorName?: string;
	createDate?: string;
	name: string;
	contentAreaID: number;
	contentAreaName: string;
	isPractice?: boolean;
	questionsCount: number;
}

export interface Test extends AvailableTest {
	questions: Question[];
}

// export interface PicAudioOption extends PicOption {
// 	textAudioUrl: string;
// }

export interface StudentsTestAnswer {
	testID: number;
	answers: AnswerModel[]
}

export interface AnswerModel {
	questionID: number;
	optionIDs: number[];
	duration: number;
}

interface TestSessionRequest {
	testIDs: number[];
}

interface TestSessionResponse {
	tests: FullTestModel[];
}

export interface StartTestSessionRequest extends TestSessionRequest {
	studentID: number;
}

export interface StartTestSessionResponse extends TestSessionResponse {
	sessionGuid: string;
}

export interface TestRequest {
	sessionGuid: string;
	testID: number;
	questionIDs: number[];
}

export interface AnswerQuestionRequest {
	sessionGuid: string;
	testID: number;
	questionID: number;
	optionIDs: number[];
	duration: number;
}

export interface AnswerQuestionResponse {
	testFromCacheAvailable: boolean;
}

export interface InitTestSessionRequest {
	studentID: number;
	subjectID: number;
	subjectType: SubjectType;
}

export interface InitTestSessionResponse {
	bucketName: string;
	studentName: string;
	tests: AvailableTest[];
	previousTestSessionAvailable: boolean;
	practiceTestPassed: boolean;
}

export interface EndTestRequest {
	sessionGuid: string;
	testID: number;
	studentID: number;
	answers: AnswerModel[];
}

export interface GetTestsResponse {
	tests: AvailableTest[];
}

export interface FullTestModel extends AvailableTest {
	questions: {id: number, selfAssessmentQuestionTemplate: TemplateType, template: string}[]
}

export interface SessionFromCache {
	sessionGuid: string;
	testsFromCache: TestFromCache[];
}

export interface TestFromCache {
	id: number;
	status: Status;
	questionsFromCache: AnswerModel[];
}

export enum Status {
	None = 'None',
	NotStarted = 'NotStarted',
	InProgress = 'InProgress',
	Completed = 'Completed',
}

export interface RelaunchTestSessionResponse {
	sessionFromCache: SessionFromCache;
	tests: FullTestModel[];
}

export interface Student {
	id: number;
	name: string;
}

export interface SubjectModel {
	name: string;
	type: SubjectType;
	level: string;
	id: number;
	selected: boolean;
	hidden: boolean;
	published: boolean;
	canEdit?: boolean;
	testsCount: number;
	gradeLevels: number[];
}

export interface ContentAreaModel {
	name: string;
	id: number;
}
