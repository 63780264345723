import {HierarchySnapshot} from 'modules/hierarchy/models';
import {SubjectType} from '@esgi/core/enums';

export interface Settings {
	classID: number,
	groupID: number,
	subjectTabID: number,
	subjectTabType: SubjectType,
	specialistGroupID: number,
	studentID: number,
}

export class RequestReportModel {
	classID: number;
	groupID: number;
	specialistGroupID: number;
	hierarchyUpdated: boolean;
	studentID: number;
	subjectID: number;
	subjectType: string;
	subjectLevel: string;
	globalSchoolYearID: number;
	reportDisplayResultsMode: number;
	displayZeroIfNotTested: boolean;
	carryScoresForward: boolean;
	showGradeColors: boolean;
	showBaseline: boolean;
	selectedTests: TestModel[];
	zip: boolean;
}

export interface UpdateRequest {
	carryScoresForward: boolean;
	classID: number;
	displayZeroIfNotTested: boolean;
	globalSchoolYearID: number;
	groupID: number;
	hierarchyUpdated: boolean;
	reportDisplayResultsMode: number;
	selectedTests: SelectedTestRequest[]
	showBaseline: boolean;
	showGradeColors: boolean;
	specialistGroupID: number;
	studentID: number;
	subjectID: number;
	subjectLevel: string;
	subjectType: string;
}

export interface SelectedTestRequest {
	testID: number;
	subjectID: number;
	subjectType: string;
	subjectLevel: string;
}

export class ReportData {
	report: Report;
	teacherName: string;
	classes: Array<ClassInfo>;
	groups: Array<GroupInfo>;
	specialistGroups: Array<SpecialistGroupInfo>;
	students: Array<StudentInfo>;
	subjects: Array<SubjectInfo>;
	reportDisplayResultsMode: number;
	displayZeroIfNotTested: boolean;
	carryScoresForward: boolean;
	showGradeColors: boolean;
	showBaseline: boolean;
	testResultsCorrectVerbiage: string;
}

export class SubjectInfo {
	subjectID: number;
	name: string;
	subjectType: string;
	subjectLevel: string;

	constructor(subjectID: number, name: string, subjectType: string, subjectLevel: string) {
		this.subjectID = subjectID;
		this.name = name;
		this.subjectType = subjectType;
		this.subjectLevel = subjectLevel;
	}
}

export class ClassInfo {
	classID: number;
	name: string;

	constructor(classID: number, name: string) {
		this.classID = classID;
		this.name = name;
	}
}

export interface MarkingPeriod {
	title: string;
	index: number;
}

export class GroupInfo {
	groupID: number;
	name: string;

	constructor(groupID: number, name: string) {
		this.groupID = groupID;
		this.name = name;
	}
}

export class SpecialistGroupInfo {
	specialistGroupID: number;
	name: string;

	constructor(specialistGroupID: number, name: string) {
		this.specialistGroupID = specialistGroupID;
		this.name = name;
	}
}

export class StudentInfo {
	studentID: number;
	name: string;

	constructor(studentID: number, name: string) {
		this.studentID = studentID;
		this.name = name;
	}
}

export interface IdNameModel {
	id: number;
	name: string;
}

export interface SubjectModel extends IdNameModel {
	subjectType: string;
	subjectLevel: string;
}

export class Report {
	globalSchoolYearID: number;
	subjectID: number;
	subjectType: string;
	trackDates: Array<TrackDatesModel>;
	gradeScaleEnabled: boolean;
	subjectGradeScales: Array<SubjectGradeScale>;
	gradeScales: Array<GradeScale>;
	students: Array<Student>;
}

export class SubjectGradeScale {
	subjectID: number;
	gradeScaleID: number;
}

export class GradeScale {
	gradeScaleID: number;
	gradeValues: Array<GradeValue>;
}

export class GradeValue {
	name: string;
	description: string;
	color: string;
}

export class TrackDatesModel {
	from: string;
	to: string;
}

export class TestSessionModel {
	date: string;
	correctAnswers: number;
}

export class Student {
	firstName: string;
	lastName: string;
	subjects: Array<Subject>;
}

export class Subject {
	subjectID: number;
	tests: Array<Test>;
	name: string;
	gradeValues: Array<GradeValue>;
}
export class TestModel {
	testID: number;
	subjectID: number;
	subjectType: string;
	subjectLevel: string;
}
export class Test {
	testID: number;
	testName: string;
	questionCount: number;
	scores: Array<ScoreModel>;
	testSessions: Array<TestSessionModel>;
}

export class ScoreModel {
	score: number;
	percent: number;
	testDate?: string;
	grade: string;
	color: string;
}

export class SearchTestResults {
	ByClassID?: number;
	ByGroupID?: number;
	ByStudentID?: number;
}

export class ATRequest {
	Subjects: SubjectRequestModel[];
	GlobalSchoolYearID: number;
	SearchBy: SearchTestResults;
	Hierarchy: HierarchySnapshot;
	HierarchyUpdated: boolean;
	ClassID?: number;
	GroupID: number;
	SpecialistGroupID: number;
	StudentID: number;
}

export interface SubjectRequestModel {
	subjectId: number;
	name: string;
	subjectType: string;
	subjectLevel: string;
}

export class ATResponse {
	subjectTests: ATSubjectTest[];
}

export class ATSubjectTest {
	subjectID: number;
	tests: Array<ATTest>;
}

export class ATTest {
	testID: number;
	subjectID: number;
	subjectType: string;
	name: string;
	isTested: boolean;
}

export class BackgroundGenerationModel {
	reportGuid: string;
}

export interface Options {
	globalSchoolYearId: number;
	classId: number;
	groupId: number;
	specialistGroupID: number;
	studentId: number;
	subjectId: number;
	subjectType: string;
	subjectLevel: string;
	selectedTests: TestModel[];
	teacherId: number;
	hierarchy: HierarchySnapshot;
}

export interface InitOptions {
	GlobalSchoolYearID: number;
	ClassID: number;
	GroupID: number;
	SpecialistGroupID: number;
	StudentID: number;
	SubjectID: number;
	SubjectType: string;
	SubjectLevel: string;
	SelectedTests: TestModel[];
}
