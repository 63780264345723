import * as React from 'react';
import type {SVGProps} from 'react';

export function Reports(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='black' {...props}>
			<path d='M6.75 4.5C5.50732 4.5 4.5 5.50725 4.5 6.75L4.5 17.25C4.5 18.4928 5.50732 19.5 6.75 19.5H17.25C18.4928 19.5 19.5 18.4928 19.5 17.25V6.75C19.5 5.50725 18.4928 4.5 17.25 4.5L6.75 4.5ZM6.75 6L17.25 6C17.664 6 18 6.336 18 6.75V17.25C18 17.664 17.664 18 17.25 18H6.75C6.33578 18 6 17.664 6 17.25L6 6.75C6 6.336 6.33578 6 6.75 6Z'/>
			<path d='M8.625 8.25165C8.21077 8.25165 7.875 8.58765 7.875 9.00165C7.875 9.41565 8.21077 9.75165 8.625 9.75165H11.625C12.0392 9.75165 12.375 9.41565 12.375 9.00165C12.375 8.58765 12.0392 8.25165 11.625 8.25165L8.625 8.25165ZM14.625 8.25165C14.2108 8.25165 13.875 8.58765 13.875 9.00165C13.875 9.41565 14.2108 9.75165 14.625 9.75165H15.375C15.7892 9.75165 16.125 9.41565 16.125 9.00165C16.125 8.58765 15.7892 8.25165 15.375 8.25165H14.625ZM8.625 14.2516C8.21077 14.2516 7.875 14.5877 7.875 15.0016C7.875 15.4157 8.21077 15.7516 8.625 15.7516H9.375C9.78923 15.7516 10.125 15.4157 10.125 15.0016C10.125 14.5877 9.78923 14.2516 9.375 14.2516H8.625ZM8.625 11.2516C8.21077 11.2516 7.875 11.5877 7.875 12.0016C7.875 12.4157 8.21077 12.7516 8.625 12.7516L12.375 12.7516C12.789 12.7516 13.125 12.4157 13.125 12.0016C13.125 11.5877 12.789 11.2516 12.375 11.2516H8.625ZM12.375 14.2516C11.961 14.2516 11.625 14.5877 11.625 15.0016C11.625 15.4157 11.961 15.7516 12.375 15.7516H15.375C15.789 15.7516 16.125 15.4157 16.125 15.0016C16.125 14.5877 15.789 14.2516 15.375 14.2516H12.375ZM14.625 12.0016C14.625 12.4157 14.961 12.7516 15.375 12.7516C15.789 12.7516 16.125 12.4157 16.125 12.0016C16.125 11.5877 15.789 11.2516 15.375 11.2516C14.961 11.2516 14.625 11.5877 14.625 12.0016Z'/>
		</svg>

	);
}
