import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const Wrapper = styled(FlexBox, {
	flexDirection: 'column',
	border: '1px solid $neutral72',
	marginLeft: '-1px',
	marginTop: '-1px',
});

export const Header = styled(Box, {
	padding: '5px',
	borderBottom: '1px solid $neutral72',
});

// There is no such set of parameters among the default ones.
export const CardText = styled(Text, {
	color: '$base',

	variants: {
		type: {
			studentName: {
				fontWeight: '400',
			},
			reportDate: {
				fontWeight: '400',
			},
			testName: {
				marginTop: '13px',
				display: 'block',
			},
		},
	},
});
