import {RequestModels, ResponseModels} from 'shared/modules/reports/flash-cards/settings-form/api/models';
import {HttpClient} from '@esgi/api';


export class Api {
	private controllerName = 'reports/flash-cards';

	public settingsInit(requestData?: RequestModels.Settings.Init) {
		return HttpClient.default.ESGIApi.get<ResponseModels.Settings.Init>(this.controllerName, 'settings', requestData);
	}

	public getSubjects(requestData: RequestModels.Settings.FetchSubject) {
		return HttpClient.default.ESGIApi.get<ResponseModels.Settings.Subjects>(this.controllerName, 'Subjects', requestData);
	}

	public fetchHierarchy(requestData: RequestModels.Settings.FetchHierarchy) {
		return HttpClient.default.ESGIApi.post<ResponseModels.Settings.FetchHierarchy>(this.controllerName, 'SettingsFetchHierarchy', requestData);
	}

	public fetchSessionHierarchy(requestData: RequestModels.Settings.FetchSessionHierarchy) {
		return HttpClient.default.ESGIApi.post<ResponseModels.Settings.FetchHierarchy>(this.controllerName, 'SettingsFetchSessionHierarchy', requestData);
	}

	public fetchSubjectSessionHierarchy(requestData: RequestModels.Settings.FetchSubjectSessionHierarchy) {
		return HttpClient.default.ESGIApi.post<ResponseModels.Settings.FetchHierarchy>(this.controllerName, 'SettingsFetchSubjectSessionHierarchy', requestData);
	}

	public resultsInit(requestData: RequestModels.Results.Init) {
		return HttpClient.default.ESGIApi.post(this.controllerName, 'Results', requestData);
	}

	public savePrintOutOption(option: string) {
		return HttpClient.default.ESGIApi.post(this.controllerName, 'SavePrintOut', {printOut: option});
	}

	public saveCardsPerPageOption(request: RequestModels.Settings.SaveCardsPerPage) {
		return HttpClient.default.ESGIApi.post(this.controllerName, 'SaveCardsPerPage', request);
	}
}
