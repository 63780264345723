import {SkeletonRow} from './index.styled';
import {VariantProps} from '@stitches/react';

const skeletonRowArray = new Array(30).fill('');

type Props = {
	variant?: VariantProps<typeof SkeletonRow>['variant'];
};

export function Skeleton({variant = 'default'}: Props) {
	return (
		<>{skeletonRowArray.map((_, index) => <SkeletonRow key={index} variant={variant}/>)}</>
	);
}