import moment from 'moment/moment';
import {Moment} from 'moment';
import {Class, Group, ReportData, ReportFilter, SpecialistGroup, Student, Subject, Test} from '../models';
import Enumerable from 'linq';
import {SubjectType, TestScreenType, TestType} from '@esgi/core/enums';
import {MutableRefObject} from 'react';
import {uniq} from 'underscore';

export function getMarkerByType(type: number) {
	const symbol = type === 1 ? 'circle' : type >= 2 ? 'square' : 'diamond';
	const color = type === 1 ? '#03A9F4' : type >= 2 ? '#000000' : '#00BFA5';
	return {
		symbol: symbol,
		fillColor: color,
	};
}

function calculateMonthTickPositions(currDate, tickPositions, months, start, dateTo) {
	if (months > 6) {
		return;
	}

	const step = months < 0.5 ? 1 : months < 2 ? 5 : months < 4 ? 10 : 15;
	const end = months < 0.5 ? 32 : 26;
	for (let i = start + 1; i < end; i++) {
		if (i % step === 0) {
			currDate = currDate.set('date', i);
			if (currDate >= dateTo) {
				break;
			}
			tickPositions.push(moment(currDate));
		}
	}
}

export function getTooltipMarkerHTML(marker: any) {
	return '<div class=\'marker\' style=\'color: ' + marker.fillColor + '\'>&#' + (marker.symbol === 'circle' ? '9679' : marker.symbol === 'square' ? '9632' : '9670') + ';</div>';
}

export function setLocalTime(date: string): Moment {
	const currTime = moment.utc();
	const hours = currTime.get('hours');
	const minutes = currTime.get('minutes');
	const seconds = currTime.get('seconds');
	return moment.utc(date).set({'hours': hours, 'minutes': minutes, 'seconds': seconds});
}

export function getDateWithZero(n: number) {
	return (n < 10 ? '0' : '') + n;
}


export function getDateString(date: Moment, shortYear: boolean = false) {
	return moment(date).format(`MM/DD/YY${!shortYear ? 'YY' : ''}`);
}

export function sortStudent(s1: Student, s2: Student, studentSort) {
	if (s1.studentID === 0) {
		return -1;
	}
	if (s2.studentID === 0) {
		return 1;
	}
	return studentSort === 'LastName' ? (s1.lastName.toLowerCase() > s2.lastName.toLowerCase() ? 1 : s1.lastName.toLowerCase() < s2.lastName.toLowerCase() ? -1 : 0) : (s1.firstName.toLowerCase() > s2.firstName.toLowerCase() ? 1 : s1.firstName.toLowerCase() < s2.firstName.toLowerCase() ? -1 : 0);
}


export function calculateTickPositions(dateTo: Date, dateFrom: Date) {
	const tickPositions = [];

	const months = (Number(dateTo) - Number(dateFrom)) / (30 * 24 * 60 * 60 * 1000);
	let currDate = moment(dateFrom);

	calculateMonthTickPositions(currDate, tickPositions, months, currDate.get('date'), dateTo);

	currDate = currDate.set('date', 1);

	do {
		const month = currDate.get('month');
		currDate = currDate.set('month', month + 1);
		if (currDate < (dateTo as any)) {
			tickPositions.push(moment(currDate));
		}

		calculateMonthTickPositions(currDate, tickPositions, months, 0, dateTo);

		currDate = currDate.set('date', 1);
	}
	while (currDate < (dateTo as any));

	return tickPositions;
}

export function getZoomDates(reportFilter: ReportFilter) {
	const dates = {};

	let to = moment.utc();
	let from = moment.utc().subtract(6, 'days');

	dates[0] = {to: to, from: from};

	from = moment.utc().subtract(29, 'days');

	dates[1] = {to: to, from: from};
	from = setLocalTime(reportFilter?.schoolYearStart);

	dates[3] = {to: to, from: from};

	to = setLocalTime(reportFilter?.currentPeriodEnd);
	from = setLocalTime(reportFilter?.currentPeriodStart);

	dates[2] = {to: to, from: from};

	return dates;
}

export function getZoomByDates(from: Date, to: Date, reportFilter: ReportFilter) {
	const dates: Moment[] | {} = getZoomDates(reportFilter);

	for (let i = 0; i < 4; i++) {
		const zDates = dates[i];
		if (zDates.to.format('MM/DD/YYYY') === moment(to).format('MM/DD/YYYY') && zDates.from.format('MM/DD/YYYY') === moment(from).format('MM/DD/YYYY')) {
			return i;
		}
	}

	return -1;
}

export function getChartOptions(reportData: ReportData, dateTo: Date, dateFrom: Date, selectedStudent: Student[], testType: TestType,
	testResultsCorrectVerbiage: string, testResultsIncorrectVerbiage: string, chartRef: MutableRefObject<HTMLDivElement>) {
	if (!reportData?.testSessions?.length) {
		return;
	}
	const data = reportData && reportData?.testSessions && Enumerable.from(reportData.testSessions).select(x => {
		return {x: moment.utc(x.date), y: x.score, marker: getMarkerByType(x.type)};
	}).toArray() || [];

	if (!data) {
		return;
	}

	const tickPositions = calculateTickPositions(dateTo, dateFrom);

	const chartOptions: any = {
		chart: {
			renderTo: 'chart',
			type: 'line',
			width: 900,
		},
		title: {
			text: '',
		},
		tooltip: {
			crosshairs: true,
			useHTML: true,
			formatter: (event) => {
				const point = event.chart.hoverPoint;

				const pointDate = point.x.format('M-D-YYYY');
				const pointTime = point.x.format('h:mm A').toLowerCase();
				const score = point.y;
				const percents = Math.round(score * 100 / reportData.questionsCount);
				const time = point.x.format('YYYY-MM-DDTHH:mm:ss.SSSS');

				const icon = reportData.testSessions.find(t => time === moment(t.date).format('YYYY-MM-DDTHH:mm:ss.SSSS'));

				const str = '<div class=\'th-tooltip\'>' + (icon?.testScreenType === TestScreenType.SelfAssessment
					? `<div><svg width="20" height="20" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="14.0193" cy="14.9808" r="11.9808" fill="white"/>
									<path d="M21.4937 22.4679H6.54467C6.77403 17.9642 10.0824 14.4823 14.0192 14.4823C17.956 14.4823 21.2643 17.9642 21.4937 22.4679Z" fill="#BA5ACB" stroke="white"/>
									<circle cx="14.0192" cy="10.9871" r="4.992" fill="#FFA53D" stroke="white" stroke-width="2"/>
									<circle cx="13.9808" cy="14.9424" r="12.9808" stroke="#059BE0" stroke-width="2"/>
								</svg></div>` : '') + '<span>Date: ' + pointDate + '</span><br><span>Time: ' + pointTime + '</span><div class=\'markerDataContainer\'>' + getTooltipMarkerHTML(point.marker) + '<div><div>' + (testType === TestType.Score && 'Score' || testResultsCorrectVerbiage) + ': <span class=\'score\'>' + score + '</span></div><div>(' + percents + '%)</div></div></div></div>';

				return str;
			},
		},
		credits: {
			enabled: false,
		},
		plotOptions: {
			series: {
				states: {
					hover: {
						enabled: true,
						halo: {
							size: 0,
						},
					},
					inactive: {
						opacity: 1,
					},
				},
				events: {
					legendItemClick: (e) => {
						e.preventDefault();
					},
				},
			},
		},
		xAxis: {
			title: {
				text: 'Test Session Date',
				style: {'font-size': '13px'},
			},
			type: 'datetime',
			min: dateFrom.valueOf(),
			max: dateTo.valueOf(),
			tickPositions: tickPositions,
			labels: {
				formatter: function () {
					return moment.utc(this.value).format('MMM DD');
				},
			},
		},
		yAxis: {
			min: 0,
			tickPositioner: () => {
				let step = Math.ceil(reportData?.questionsCount / 10);
				if (step > 25) {
					step = 50;
				} else if (step > 15) {
					step = 25;
				} else if (step > 5) {
					step = 10;
				} else if (step > 3) {
					step = 5;
				}
				const positions = [0];
				let i = 0;
				while (i + step < reportData.questionsCount) {
					i += step;
					positions.push(i);
				}
				positions.push(reportData.questionsCount);
				const dif = reportData.questionsCount - i;
				if (dif > 0 && dif < step) {
					$('.highcharts-yaxis-labels text:last').hide();
					$('.highcharts-yaxis-grid path:last').hide();
				}
				return positions;
			},
			title: {
				text: (selectedStudent[0]?.studentID === 0 ? 'Average ' : '') + 'Score',
				style: {'font-size': '13px'},
			},
		},
	};

	if (selectedStudent[0]?.studentID > 0) {
		chartOptions.series = [
			{
				name: 'Baseline',
				color: '#00BFA5',
				lineWidth: 1,
				marker: {
					enabled: true,
					symbol: 'diamond',
					radius: 6,
				},
			}, {
				name: 'Retest All',
				color: '#000000',
				lineWidth: 1,
				stickyTracking: false,
				marker: {
					enabled: true,
					symbol: 'square',
					radius: 6,
				},
				states: {
					hover: {
						lineWidthPlus: 0,
					},
				},
				data: data,
			}, {
				name: 'Test ' + testResultsIncorrectVerbiage,
				color: '#03A9F4',
				lineWidth: 1,
				marker: {
					enabled: true,
					symbol: 'circle',
					radius: 6,
				},
			},
		];
	} else {
		chartOptions.series = [
			{
				name: 'Average ' + testResultsCorrectVerbiage,
				color: '#000000',
				lineWidth: 1,
				stickyTracking: false,
				marker: {
					enabled: true,
					symbol: 'diamond',
					fillColor: '#00BFA5',
					radius: 6,
				},
				states: {
					hover: {
						lineWidthPlus: 0,
					},
				},
				data: data,
			},
		];
	}
	return chartOptions;
}

export function parseReportFilter(filter: ReportFilter, subjectID: number, subjectType: SubjectType, testId: number) {
	let classes: Class[] = [];
	let selectedClass: Class[] = [];
	let students: Student[] = [];
	let selectedStudent: Student[] = [];
	let groups: Group[] = [];
	let selectedGroup: Group[] = [];
	let subjects: Subject[] = [];
	let selectedSubject: Subject[] = [];
	let specialistGroups: SpecialistGroup[] = [];
	let selectedSpecialistGroup: SpecialistGroup[] = [];
	let selectedTest: Test[] = [];
	if (filter.classes && filter.classes.length > 0) {
		classes = filter.classes.map(x => new Class(x.classID, x.name, x.students.map(y => new Student(y.studentID, y.firstName, y.lastName))));
		if (classes.length > 1) {
			let allStudents = [];
			for (let i = 0; i < classes.length; i++) {
				const c = classes[i];
				allStudents = allStudents.concat(c.students);
			}
			allStudents = allStudents.filter((value, index, self) =>
				index === self.findIndex((t) => (
					t.studentID === value.studentID
				)),
			).sort((a, b) => sortStudent(a, b, filter.studentSort));
			const allClasses = new Class(0, 'All', allStudents);
			classes = ([allClasses].concat(classes));
		}
		if (classes.length === 1 && filter.classID === 0) {
			selectedClass = [classes[0]];
		} else {
			selectedClass = [classes.filter(s => s.classID === filter.classID)[0]];
		}

		students = [
			new Student(0, 'Class Average', ''),
			new Student(-1, 'All Class', ''),
		].concat(selectedClass[0]?.students.sort((a, b) => sortStudent(a, b, filter)));
	} else {
		classes = [];
	}

	if (filter.groups && filter.groups.length > 0) {
		groups = filter.groups.map(x => new Group(x.groupID, x.name, x.students.map(y => new Student(y.studentID, y.firstName, y.lastName))));
		selectedGroup = [groups.filter(s => s.groupID === filter.groupID)[0]];
		students = [new Student(0, 'Group Average', ''), new Student(-1, 'All Group', '')];
		students = students.concat(selectedGroup[0].students).sort((a, b) => sortStudent(a, b, filter));
	} else {
		groups = [];
	}
	if (filter.specialistGroups && filter.specialistGroups.length > 0) {
		specialistGroups = filter.specialistGroups.map(x => new SpecialistGroup(x.specialistGroupID, x.name, x.students.map(y => new Student(y.studentID, y.firstName, y.lastName))));
		if (!filter.specialistGroupID) {
			const allStudents = uniq(specialistGroups.map(s => s.students).flat(), s => s.studentID).sort((a, b) => sortStudent(a, b, filter));
			const allSpecialistGroup = new SpecialistGroup(0, 'All', allStudents);
			specialistGroups = [allSpecialistGroup].concat(specialistGroups);
			selectedSpecialistGroup = [specialistGroups[0]];
		} else {
			selectedSpecialistGroup = specialistGroups.filter(s => s.specialistGroupID === filter.specialistGroupID);
		}

		students = [new Student(0, 'Group Average', ''), new Student(-1, 'All Group', '')];
		students = students.concat(selectedSpecialistGroup[0].students).sort((a, b) => sortStudent(a, b, filter));
	} else {
		specialistGroups = [];
	}

	if (students.length !== 0) {
		selectedStudent = [students.filter(s => s.studentID === filter.studentID)[0]];
	}

	if (filter.subjects) {
		subjects = filter.subjects.map(x => new Subject(x.subjectID, x.name, x.subjectType, x.tests.map(y => new Test(y.testID, y.name, y.type))));
		selectedSubject = ([subjects.filter(s => s.subjectID === subjectID && parseInt(SubjectType[s.subjectType]) === subjectType)[0]]);
		selectedTest = [selectedSubject[0].tests.filter(t => t.testID === testId)[0]];
	} else {
		subjects = [];
	}
	return {
		classes,
		selectedClass,
		students,
		selectedStudent,
		groups,
		selectedGroup,
		subjects,
		selectedSubject,
		specialistGroups,
		selectedSpecialistGroup,
		selectedTest,
	};
}



