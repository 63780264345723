import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {OpenQuestionBankEvent, CloseQuestionBankEvent} from 'shared/modules/test-details/events';
import {EventBusManager} from '@esgillc/events';
import {getSuggestedGradeLevel} from './utils';
import {QuestionBankService} from './services';
import {Test} from './types';

interface Props {
	onOpen: () => void;
	onClose: () => void;
}

export const QuestionBankContext = createContext(null);
export const useQuestionBankService = () => useContext<QuestionBankService>(QuestionBankContext);

export const useQuestionBankListener = ({onOpen, onClose}: Props) => {
	const eventBus = useMemo(() => new EventBusManager(), []);

	const [initialTest, setInitialTest] = useState<Test>(null);

	const handleCloseQuestionBank = useCallback(async () => {
		setInitialTest(null);
		onClose();
	}, [onClose]);

	const handleOpenQuestionBank = useCallback((event: OpenQuestionBankEvent) => {
		setInitialTest({
			testID: event.testId,
			contentAreaID: event.contentAreaId,
			contentAreaName: event.contentArea,
			testName: event.testName,
			gradeLevels: event.gradeLevels?.map(getSuggestedGradeLevel),
			stateStandardName: event.stateStandard,
			questions: event.selfAssessQuestions.map((q) => ({
				bankQuestionID: q.bankQuestionID ?? q.id,
				ID: q.id,
				name: q.name,
				template: JSON.stringify(q),
				templateType: q.templateType,
				thumbnailUrl: q.thumbnailUrl,
				orderNum: q.orderNum,
			})),
		});
		onOpen();
	}, [onOpen]);

	useEffect(() => {
		eventBus.subscribe<CloseQuestionBankEvent>(CloseQuestionBankEvent, handleCloseQuestionBank);
		eventBus.subscribe<OpenQuestionBankEvent>(OpenQuestionBankEvent, handleOpenQuestionBank);

		return () => {
			eventBus.destroy();
		};
	}, [eventBus]);

	return {initialTest};
};