export function OrderConfirmationTemplate() {
	return <div className='order-confirmation' data-bind='var: {step: $data}'>
		<div className='title text-center' data-cy='purchase-complete-label'>
			Purchase Complete
		</div>
		<section>
			<ko data-bind='if: Payments && Payments.length'>
				<ko data-bind='foreach: Payments'>
					<div className='cart-details' data-cy='subscription-details'>
						<span><b><span data-bind='text: title'></span> Account Expiration Date: </b></span>
						<span data-bind="text: NewExpDate.format('MM/DD/YY')"></span>
					</div>
				</ko>
			</ko>

			<ko data-bind='if: newStudentLimit'>

				<div className='cart-details' data-cy='student-limit-details'>
					<span><b>Student Limit: </b></span>
					<span data-bind='text: newStudentLimit'></span>
				</div>

			</ko>
		</section>

		<section>
			<div className='cart-details' data-cy='transaction-id-details'>
				<b><span>Your transaction ID: </span></b>
				<span data-bind='text: transactionId'/>
			</div>

			<div className='cart-details' data-cy='order-id-details'>
				<b><span>Your order ID: </span></b>
				<span data-bind='text: orderId'/>
			</div>
		</section>

		<div className='text-center' data-cy='email-notice'>
			<b>You have been emailed a copy of your receipt.</b>
		</div>

		<button className='btn btn-block btn-link have-ac' data-bind='click: view.downloadReceipt' data-cy='receipt-download-button'>Click here to
			download your receipt.
		</button>
		<div className='clearfix text-center' style={{marginBottom: '25px', marginTop: '10px'}} data-cy='continue-button'>
			<button className='btn btn-warning' data-bind='click: view.continue, text: continueText'/>
		</div>
	</div>
}
