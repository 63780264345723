import {useCallback} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Descendant} from 'slate';
import {Slate, Editable, ReactEditor} from 'slate-react';
import {useAiAnalysisContext} from '../../../../layout/outlet-context';
import {
	LoadingText,
	EditorContainer,
	BlockContainer,
	ToolbarContainer,
	VoidContainer,
} from './index.styled';
import {Skeleton} from './components/skeleton';
import {Toolbar} from './components/toolbar';
import {
	MarkButton,
	BlockButton,
	ButtonText,
} from './components/toolbar-button';
import {VerticalDivider} from '../../index.styled';

interface Props {
	editor: ReactEditor;
	initialValue: Descendant[];
	isTextLoading: boolean;
	isTranslationsLoading: boolean;
	showTranslateButton: boolean;
}

export function TextEditor({
	editor,
	initialValue,
	isTextLoading,
	isTranslationsLoading,
	showTranslateButton,
}: Props) {
	const {
		canEdit,
		showTranslatePanel,
		setShowTranslatePanel,
		setShowTranslateDrawer,
		windowWidth,
	} = useAiAnalysisContext();

	const renderElement = useCallback((props) => <Element {...props} />, []);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

	const toggleShowTranslatePanel = useCallback(() => {
		if (windowWidth >= 1024) {
			setShowTranslatePanel(!showTranslatePanel);
		} else {
			setShowTranslateDrawer(true);
		}
	}, [showTranslatePanel, windowWidth]);

	return isTextLoading ? (
		<BlockContainer>
			<LoadingText>Report generation could take up to a minute.</LoadingText>
			<Skeleton />
		</BlockContainer>
	) : (
		<Slate editor={editor} initialValue={initialValue}>
			{canEdit && (
				<ToolbarContainer>
					<Toolbar>
						<MarkButton format='strong' />
						<MarkButton format='italic' />
						<VerticalDivider />
						<BlockButton format='numbered-list' />
						<BlockButton format='bulleted-list' />
					</Toolbar>
					{showTranslateButton && (
						<ButtonText
							onClick={toggleShowTranslatePanel}
							active={showTranslatePanel}
						>
							Translate
						</ButtonText>
					)}
				</ToolbarContainer>
			)}
			<OverlayScrollbarsComponent
				defer
				options={{scrollbars: {autoHide: 'leave'}}}
			>
				<EditorContainer>
					<Editable
						renderElement={renderElement}
						renderLeaf={renderLeaf}
						placeholder='Write some markdown...'
						readOnly={!canEdit}
					/>
				</EditorContainer>
				{isTranslationsLoading && (
					<BlockContainer>
						<Skeleton />
					</BlockContainer>
				)}
			</OverlayScrollbarsComponent>
		</Slate>
	);
}

const Element = ({attributes, children, element}) => {
	const style = {textAlign: element.align};
	switch (element.type) {
		case 'block-quote':
			return (
				<blockquote style={style} {...attributes}>
					{children}
				</blockquote>
			);
		case 'heading':
			return (
				<strong style={style} {...attributes}>
					{children}
				</strong>
			);
		case 'list-item':
			return (
				<li style={style} {...attributes}>
					{children}
				</li>
			);
		case 'numbered-list':
			return (
				<ol style={style} {...attributes}>
					{children}
				</ol>
			);
		case 'bulleted-list':
			return (
				<ul style={style} {...attributes}>
					{children}
				</ul>
			);
		case 'paragraph':
			return (
				<p style={style} {...attributes}>
					{children}
				</p>
			);
		case 'block':
			return (
				<BlockContainer className='editor-block' style={style} {...attributes}>
					{children}
				</BlockContainer>
			);
		case 'editable-void':
			return (
				<VoidContainer>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
						contentEditable={false}
						{...attributes}
					>
						{element?.children?.length &&
							element.children.map((el) => (
								<p key={el.id}>
									{el.children?.length &&
										el.children.map((leaf) =>
											leaf.strong ? (
												<strong>{leaf.text}</strong>
											) : (
												<span key={leaf.text}>{leaf.text}</span>
											)
										)}
								</p>
							))}
					</div>
				</VoidContainer>
			);
		default:
			return (
				<span style={style} {...attributes}>
					{children}
				</span>
			);
	}
};

const Leaf = ({attributes, children, leaf}) => {
	if (leaf.strong) {
		children = <strong>{children}</strong>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	return <span {...attributes}>{children}</span>;
};
