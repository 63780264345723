import {TextWithIcon} from '@esgi/main/kits/assignments';
import {AiAnalysis} from '@esgi/ui';
import {Layout} from './index.styled';

export function Header() {
	return (
		<Layout>
			<TextWithIcon size='small' color='neutral56' Icon={AiAnalysis}>
				AI Analysis
			</TextWithIcon>
		</Layout>
	);
}
