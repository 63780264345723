import {useEffect, useState} from 'react';
import {useSpring, config} from 'react-spring';

export function useAnimationData({
	startValueProgress,
	endValueProgress,
}: {
	startValueProgress: number;
	endValueProgress: number;
}) {
	const [forceUpdatedOnInit, setForceUpdatedOnInit] = useState(false);

	// This useEffect triggers animations
	useEffect(() => {
		if (!forceUpdatedOnInit) {
			setForceUpdatedOnInit(true);
		}
	}, [forceUpdatedOnInit]);

	return useSpring({
		from: {
			progress: startValueProgress,
		},
		to: {
			progress: endValueProgress,
		},
		config: {
			...config.molasses,
			clamp: true,
		},
	});
}
