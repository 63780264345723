import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const Panel = styled(Box, {
	overflow: 'hidden',
	height: '100%',
	backgroundColor: '$neutral94',
	backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',

	'> div': {
		width: '100%',
	},
});

export const Header = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'start',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto 1fr',
	gap: '$4',
	minHeight: '40px',
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$border',
});
