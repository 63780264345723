import {FormDataBuilder} from '@esgi/api';
import {BaseService} from '@esgi/core/service';
import {ElementStatus, FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {Observable, takeUntil} from 'rxjs';

export class PasswordReminderService extends BaseService {
	public form = new FormGroup({
		email: new FormControl('', {validators: [Validators.email()]}),
		userName: new FormControl(''),
	});

	constructor() {
		super();

		this.form.onChanged
			.pipe(takeUntil(this.destroy$))
			.subscribe((ch) => {
				const formValue = ch.currState.value;
				const {email, userName} = this.form.controls;
				if(formValue.email) {
					userName.status = ElementStatus.disabled;
				} else if(userName.status === ElementStatus.disabled) {
					userName.status = ElementStatus.untouched;
				}

				if (formValue.userName) {
					email.status = ElementStatus.disabled;
				} else if(email.status === ElementStatus.disabled) {
					email.status = ElementStatus.untouched;
				}
			});
	}

	public submit(): Observable<boolean> {
		return this.httpClient.ESGIApi.post<boolean>('Login', 'remind-password', FormDataBuilder.BuildParameters(this.form.value)).asObservable();
	}
}
