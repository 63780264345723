import {useCallback} from 'react';
import {useSlate} from 'slate-react';
import {isMarkActive} from '../../utils/is-mark-active';
import {toggleMark} from '../../utils/toggle-mark';
import {Bold, Italic} from '@esgi/ui';
import {ButtonIcon} from './index.styled';

interface Props {
  format: string;
}

export function MarkButton({format}: Props) {
	const editor = useSlate();

	const getIcon = useCallback(() => {
		switch (format) {
			case 'strong':
				return <Bold />;
			case 'italic':
				return <Italic />;
		}
	}, [format]);

	return (
    <ButtonIcon
      color='secondary'
      active={isMarkActive(editor, format)}
      onClick={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {getIcon()}
    </ButtonIcon>
	);
}
