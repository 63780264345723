import {PropsWithChildren} from 'react';
import {Button, Close, IconComponent, Skeletonable} from '@esgi/ui';
import {ActionButtons, Content, ContentWrapper, Header} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {useDrawerContext} from '../../context/drawer-context';
import {Divider} from '../divider.styled';

type Props = {
	Icon: IconComponent;
	sectionName: string;
} & (
	| {
			withActionButton?: false;
	  }
	| {
			withActionButton: true;
			actionButtonText: string;
			actionButtonDisabled?: boolean;
			onActionButtonClick: VoidFunction;
	  }
) &
	PropsWithChildren &
	Skeletonable;

export function DrawerHeader({Icon, sectionName, children, skeleton, ...props}: Props) {
	const {closeDrawerWithCbBefore} = useDrawerContext();

	return (
		<Header>
			<ContentWrapper>
				<Content gap='1' align='center' flow='column'>
					<Icon />
					<Text size='small' data-cy='drawer-title'>{sectionName}</Text>
				</Content>
				<ActionButtons>
					{children}

					{props.withActionButton && (
						<Button
							skeleton={skeleton}
							color='primary'
							disabled={Boolean(props.actionButtonDisabled)}
							onClick={props.onActionButtonClick}
						>
							<Text size='large'>{props.actionButtonText}</Text>
						</Button>
					)}

					<Button.Icon skeleton={skeleton} onClick={closeDrawerWithCbBefore} withBackgroundHover dataCy='close-button'>
						<Close />
					</Button.Icon>
				</ActionButtons>
			</ContentWrapper>

			<Divider />
		</Header>
	);
}
