import React from 'react';
import {TestType as TestTypeEnum} from '@esgi/core/enums';
import {FilterType} from '../../../enums';
import {TestExplorerBackEvent} from '../../../events';
import {RemoveFilterEvent} from '../../header/search-tags/search-tags';
import {BoxItem} from '../box-item/box-item';
import {Box} from '../box/box';
import {EventBusManager} from '@esgillc/events';
import {ClearAllEvent} from '../events';
import {AuthorClicked} from '../../noted-author/events';

interface Props {
	hasChanged: (testType: TestTypeEnum) => void;
}

class State {
	selectedType?: TestTypeEnum;
}

export class TestType extends React.PureComponent<Props, State> {
	public state = new State();
	private readonly eventBus = new EventBusManager();

	public componentDidMount(): void {
		this.eventBus.subscribe(AuthorClicked, () => {
			this.hasChanged(null);
		});

		this.eventBus.subscribe(ClearAllEvent, () => {
			this.hasChanged(null);
		});

		this.eventBus.subscribe(RemoveFilterEvent, (args: RemoveFilterEvent) => {
			if (args.type === FilterType.TestType) {
				this.hasChanged(null);
			}
		});

		this.eventBus.subscribe(TestExplorerBackEvent, () => {
			this.hasChanged(null, false);
		});
	}

	public render() {
		return <Box title='Test Type'
		            onOpened={() => {
		            }}
		            cleared={() => this.hasChanged(null)}
		            canBeCleared={this.state.selectedType != null}
		            filterType={FilterType.TestType}
		            loading={false}
		            collapsible={true}>
			<BoxItem title='Yes/No'
			         selected={this.state.selectedType === TestTypeEnum.YN}
			         onSelect={() => this.hasChanged(TestTypeEnum.YN)}/>
			<BoxItem title='Single Score'
			         selected={this.state.selectedType === TestTypeEnum.Score}
			         onSelect={() => this.hasChanged(TestTypeEnum.Score)}/>
			<BoxItem title='Rubric'
			         selected={this.state.selectedType === TestTypeEnum.Rubric}
			         onSelect={() => this.hasChanged(TestTypeEnum.Rubric)}/>
		</Box>;
	}

	private hasChanged(type?: TestTypeEnum, refreshFilter: boolean = true) {
		this.setState({selectedType: type}, () => refreshFilter && this.props.hasChanged(type));
	}
}
