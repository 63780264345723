import {resolvedPromise} from '@esgi/deprecated/utils';
import {computed, FormField, Validators} from '@esgi/deprecated/knockout';
import moment, {Moment} from 'moment';
import {AddTimeTemplate} from '../html/add-time-template';
import {ExpirationCartModel} from '../models';
import {isESGI} from '../server';
import {Step} from './steps';

export class AddTimeStep extends Step {

	template = AddTimeTemplate;

	constructor(public cart: ExpirationCartModel) {
		super();
		this.title = 'Add Time';
		this.icon = 'fa-clock-o';

		this.years = this.createYear(this.cart.years);
		this.months = this.createMonth(this.cart.months);

		cart.products.forEach(p => p.selected.subscribe(_ => {
			this.initTooltips();
			this.syncronizeExpirationDates();
			$(this).trigger('addTimeCartDataChanged');
		}));

		cart.months.subscribe(_ => {
			this.syncronizeExpirationDates();
			$(this).trigger('addTimeCartDataChanged');
		});

		cart.years.subscribe(_ => {
			this.syncronizeExpirationDates();
			$(this).trigger('addTimeCartDataChanged');
		});
	}

	afterRender(rootElement: JQuery): JQueryPromise<any> {
		this.rootElement = rootElement;
		this.cart.products[0].selected(true);
		this.initTooltips();
		return resolvedPromise({});
	}

	private initTooltips() {
		setTimeout(() => {

			let tooltips = $('.tooltip-text', this.rootElement);
			tooltips.each((ind, elem) => {
				// @ts-ignore
				$(elem).qtip({
					content: {
						text: $(elem).find('.tooltip-message').html(),
					},
					position: {
						at: 'top center',
						my: 'bottom center',
						container: $(elem).closest('#modal-form'),
					},
					hide: {
						fixed: true,
						delay: 500,
					},
					style: {classes: 'tooltip-body'},
				});
			});
		}, 1);
	}

	private syncronizeExpirationDates() {

		let dropdownmonths = 0;
		let ddVisible = this.isTimeDropdownsVisible;

		if (ddVisible) {
			dropdownmonths = this.cart.months() + this.cart.years() * 12;
		}

		let s = this.getCartProductsState();

		let esgiStartDate = moment();
		if (!s.esgi.expired()) {
			esgiStartDate = s.esgi.expirationDate;
		}

		let getNewExpDate = (startDate: Moment) => {
			let newExpDate = moment(startDate);
			if (ddVisible) {
				newExpDate = newExpDate.add(dropdownmonths, 'months');
			}

			return newExpDate;
		};

		if (s.isEsgiSelected) {
			let soloEsgiNewExpDate = getNewExpDate(esgiStartDate);
			s.esgi.newExpirationDate(soloEsgiNewExpDate);
		}
	}

	private getCartProductsState() {
		let query = this.cart.products;
		let esgi = query.find(x => isESGI(x.name));

		let isEsgiSelected = esgi.selected();

		let esgiExp = moment(esgi.expirationDate).startOf('day');
		let now = moment().startOf('day');

		let esgiExpiresInDays = esgiExp.diff(now, 'days');

		let maxDiffOfExpDates = this.cart.esgiExpDaysAffectTimeDropdown;
		let esgiHideDropdown = esgiExpiresInDays >= this.cart.esgiExpDaysAffectTimeDropdown;

		return {
			esgi: esgi,

			isEsgiSelected: isEsgiSelected,

			esgiExpiresInDays: esgiExpiresInDays,
			maxDiffOfExpDates: maxDiffOfExpDates,
			esgiHideDropdown: esgiHideDropdown,
		};
	}

	years: FormField<number>;
	months: FormField<number>;

	@computed()
	get isAnyProductSelected() {
		return this.cart.products.some(x => x.selected() && x.isPurchasable());
	}

	createYear(value: KnockoutObservable<number>) {
		return this.createField(value, Validators.Required());
	}

	createMonth(value: KnockoutObservable<number>) {
		return this.createField(value, Validators.Required());
	}

	@computed()
	get isTimeDropdownsVisible(): boolean {
		let s = this.getCartProductsState();

		if (s.isEsgiSelected) {
			return true;
		}

		return false;
	}

	view = {
		cancel: () => {
			$(this).trigger('cancel');
		},
		next: () => {
			$(this).trigger('next');
		},
	};


	events = {
		cancel: (callback) => {
			$(this).on('cancel', callback);
		},
		next: (callback) => {
			$(this).on('next', callback);
		},
		addTimeCartDataChanged: (callback) => {
			$(this).on('addTimeCartDataChanged', callback);
		},
	};
}
