import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {ToggleGroup} from '@esgi/ui/controls';

export const AlertBody = styled(Alert.Body, {
	display: 'flex',
	flexDirection: 'column',
	width: '508px',
});
export const AlertMainContent = styled(FlexBox, {
	border: '1px solid $neutral92',
	padding: '24px 20px',
	width: '100%',
	borderRadius: '12px',
	flexDirection: 'column',
	height: '224px',
});
export const DescriptionText = styled(Text, {
	marginTop: '20px',
});

export const AlertFooter = styled(Alert.Footer, {
	justifyContent: 'space-between !important',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr',
	backgroundColor: '$neutral96',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	background: '$neutral96',
	borderRadius: '5px',
});

export const DownloadButton = styled(Button, {
	borderColor: '$neutral99',
	display: 'flex',
	alignItems: 'center',
});
