import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import './component.less';
import {Loader} from '@esgi/deprecated/jquery';
import FlashCardSettingsBody from 'shared/modules/reports/flash-cards/settings-form/components/tests-table/component';
import FlashCardsSettingsFooter from 'shared/modules/reports/flash-cards/settings-form/components/footer/footer';
import Settings from 'shared/modules/reports/flash-cards/settings-form/components/settings/component';
import FlashCardsHierarchy from 'shared/modules/reports/flash-cards/settings-form/components/hierarchy/component';
import {SubjectType} from '@esgi/core/enums';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import ResultsFormModal from 'shared/modules/reports/flash-cards/results-form/modal';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {ChangesCollector} from '../../utils/changes-collector';
import SortBy = Models.SortBy;
import {HierarchySnapshot} from 'modules/hierarchy/models';

class State {
	isSettingsOpened: boolean;
	selectedEntity: string;
	isResultsOpened: boolean;
	sortBy: SortBy;
  sortingOptionsOpened: boolean;
}

class Props {
hierarchy: HierarchySnapshot;
	dataService: DataService;
  onCloseClicked: () => void;
	changesCollector: ChangesCollector;
	parentLetterOnClosed: (emit: boolean, changesCollector: ChangesCollector) => any;
}

export default class FlashCardsSettings extends React.Component<Props, State> {
	private loader: Loader;

	constructor(props?: Props) {
		super(props);
		this.state = {
			selectedEntity: '',
			isResultsOpened: false,
			isSettingsOpened: false,
      sortingOptionsOpened: false,
      sortBy: SortBy.TestOrder,
		};
	}

	componentDidMount(): void {
		this.loader = new Loader($('#app'));
		this.loading(true);
		const selectedItems = new Models.InitModel();
		selectedItems.subjectType = this.props.dataService.settingsService.selectedItems.subjectType;
		selectedItems.subjectID = this.props.dataService.settingsService.selectedItems.subjectID;

		this.props.dataService.testsService.init(selectedItems)
			.subscribe(() => {
				if (!this.props.dataService.settingsService.selectedItems.selectedTestID) {
					this.setState({isSettingsOpened: true});
					this.loading(false);
				} else {
					this.props.dataService.resultsService.init().subscribe(() => {
						this.setState({isResultsOpened: true});
					}, null, () => this.loading(false));
				}
			});

		this.props.dataService.hierarchyService.selectedEntity$.subscribe((selectedEntity) => {
			this.setState({selectedEntity: selectedEntity});
		});
		this.props.dataService.testsService.loading$.subscribe((loading) => {
			this.loading(loading);
		});
		this.props.dataService.testsService.sortBy$.subscribe((sortBy) => {
			this.setState({sortBy: sortBy});
		});
	}

	componentWillUnmount(): void {
		this.props.dataService.destroy();
	}

	private launchHierarchyEvents() {
		this.props.changesCollector.applyChanges({
			subjectTabID: this.props.dataService.hierarchyService.selectedSubject?.id,
			subjectTabType: SubjectType[this.props.dataService.hierarchyService.selectedSubject?.subjectType],
			studentID: this.props.dataService.hierarchyService.selectedStudent?.studentId,
		});
		const selectedGroupItem = this.props.dataService.hierarchyService.selectedGroupItem;
		if(selectedGroupItem && selectedGroupItem.itemType === 'Class') {
			this.props.changesCollector.classID.value = selectedGroupItem.id;
		}
		if (selectedGroupItem && ['Group', 'SpecialistGroup'].indexOf(selectedGroupItem.itemType) > -1) {
			this.props.changesCollector.groupID.value = selectedGroupItem.id;
		}
	}



	private closeFc() {
		this.launchHierarchyEvents();
		this.props.onCloseClicked();
	}

	private loading(loading: boolean) {
		if (loading){
			this.loader.mask();
		} else {
			this.loader.unmask();
		}
	}

  toggleSortingOptions(){
    this.setState({sortingOptionsOpened: !this.state.sortingOptionsOpened});
  }

	renderSortBy() {
    return <div className='select-all-links'>
      <span>Sort by:</span>
      {this.state.sortBy == SortBy.TestOrder && <a href='#' onClick={() => this.toggleSortingOptions()}>Test Order
        <svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M0 0L5 5L10 0H0Z' fill='#0088CC'/>
        </svg>
      </a>}
      {this.state.sortBy == SortBy.Alphabetical && <a href='#' onClick={() => this.toggleSortingOptions()}>Alphabetical
        <svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M0 0L5 5L10 0H0Z' fill='#0088CC'/>
        </svg>
      </a>}
      {this.state.sortingOptionsOpened && <ul className='sortingOptionsMenu' id='sortingOptionsMenu'>
        <li onClick={() => {
          this.props.dataService.testsService.setSortBy(SortBy.TestOrder);
          this.toggleSortingOptions();
        }}>Test Order</li>
        <li onClick={() => {
          this.props.dataService.testsService.setSortBy(SortBy.Alphabetical);
          this.toggleSortingOptions();
        }}>Alphabetical</li>
      </ul>}
    </div>
  }

	render() {
		if (!this.state.isSettingsOpened && !this.state.isResultsOpened) {
			return null;
		}

		return <>
			{this.state.isSettingsOpened && <Modal className='flash-cards-settings-modal blue-header responsive'>
				<ModalHeader title='Flashcards Settings' close={{
					click: () => {
						this.closeFc();
					},
					white: true,
				}}/>
				<ModalBody>
					<div className='fc-settings-modal-content'>
						<div className='fc-settings-header'>
							<FlashCardsHierarchy
								dataService={this.props.dataService}
							/>
							<Settings
								dataService={this.props.dataService}
							/>
							<div className='selected-entity-name'>
								<span>{this.state.selectedEntity}</span>
							</div>
              <div className='tableControls'>
                <div className='select-all-links'>
                  <a className='select-all-link' href='#'
                     onClick={() => this.props.dataService.testsService.selectAll()}>Select
                    All</a>
                  &nbsp;|&nbsp;
                  <a href='#'
                     onClick={() => this.props.dataService.testsService.deselectAll()}>Deselect
                    All</a>
                </div>
                {this.renderSortBy()}
              </div>
						</div>
						<FlashCardSettingsBody
							dataService={this.props.dataService}
						/>
					</div>
				</ModalBody>
				<ModalFooter>
					<FlashCardsSettingsFooter
					hierarchy={this.props.hierarchy}
						dataService={this.props.dataService}
						onCloseClicked={this.props.onCloseClicked}
						launchHieararchyEvents={() => this.launchHierarchyEvents()}
						changesCollector={this.props.changesCollector}
						parentLetterOnClosed={this.props.parentLetterOnClosed}
						viewResultsClicked={() => this.viewResults()}
					/>
				</ModalFooter>
			</Modal>}
			{this.state.isResultsOpened &&
			<ResultsFormModal
				dataService={this.props.dataService}
				settingsClose={this.props.onCloseClicked}
				onClosed={() => {
					this.setState({isResultsOpened: false});
					this.launchHierarchyEvents();
				}}
				onLoading={(m) => this.loading(m)}
				onBack={() => this.setState({isSettingsOpened: true})}
			/>}
		</>;
	}

	private viewResults() {
		this.loading(true);
		this.props.dataService.resultsService.init().subscribe(() => {
			this.setState({isResultsOpened: true});
		}, null, () => this.loading(false));
	}
}
