import {join} from '@esgillc/ui-kit/utils';

interface Props {
	className?: string;
}

export function TriangleIcon({className}: Props) {
	return <svg
		className={join(className)}
		width='10'
		height='6'
		viewBox='0 0 10 6'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M0 0L5 5.11133L10 0L0 0Z'
			fill='#828282'>
		</path>
	</svg>;
}