import {StudentSectionMode} from '../../types';
import {useTeacherPageContext} from '../../../../context/teacher-page-context';
import {EditableContent} from './components/editable-content';
import {RearrangeContent} from './components/rearrange-content';
import {StudentsPanel} from '@esgi/main/features/teacher/students-panel';
import {Class} from '@esgi/main/libs/store';
import {SelectAllClassesButton} from './components/select-all-classes-button';
import {Observable} from 'rxjs';
import {useEffect} from 'react';

type Props = {
	sectionMode: StudentSectionMode;
	canAddClass: boolean;
	setViewMode: VoidFunction;
	updateClassesOrder: (classes: Class[]) => Observable<void>;
};

export function ClassesContent({sectionMode, canAddClass, setViewMode, updateClassesOrder}: Props) {
	const {openAddClassForm} = useTeacherPageContext();

	const {classBuckets} = StudentsPanel.Tabs.Classes.useClassesContext();

	const {
		selectClass,
		selectStudentFromClass,
		selected: {studentFromClassId, studentId: selectedStudentId, classId: selectedClassId, groupId: selectedGroupId},
	} = StudentsPanel.Selection.Single.useSingleSelectContext();


	useEffect(()=>{
		if(!selectedStudentId && !selectedGroupId && selectedClassId && classBuckets.length){
			selectClass(classBuckets[0].id);
		}
	}, [classBuckets]);

	if (sectionMode === StudentSectionMode.Edit) {
		return <EditableContent setViewMode={setViewMode} classBuckets={classBuckets} />;
	}

	if (sectionMode === StudentSectionMode.Rearrange) {
		return (
			<RearrangeContent setViewMode={setViewMode} updateClassesOrder={updateClassesOrder} classBuckets={classBuckets} />
		);
	}

	return (
		<StudentsPanel.Tabs.TabContent
			withBottomSectionButton={!classBuckets.length}
			buttonText='Add a Class'
			isBottomSectionButtonDisabled={!canAddClass}
			onBottomButtonClick={openAddClassForm}
		>
			<SelectAllClassesButton />

			{classBuckets.length ? (
				classBuckets.map(({id, name, students}) => {
					const isOpen = selectedClassId === id || studentFromClassId === id;

					return (
						<StudentsPanel.Lists.SelectableListGroup.Expandable.Single
							withCounter
							isListOpen={isOpen && Boolean(students.length)}
							key={id}
							hideArrowIcon={isOpen}
							onTriggerButtonClick={() => selectClass(id)}
							groupName={name}
							onItemClick={(studentId) => selectStudentFromClass({studentId, classId: id})}
							value={selectedStudentId}
							isListSelected={selectedClassId === id}
							items={students}
							allowSelectEmpty
						>
							{({item: {firstName, lastName}}) => (
								<StudentsPanel.StudentFullName firstName={firstName} lastName={lastName} />
							)}
						</StudentsPanel.Lists.SelectableListGroup.Expandable.Single>
					);
				})
			) : (
				<StudentsPanel.Tabs.EmptyTabContent
					size='full'
					templateText='No classes to display'
					dataCy='students-panel-empty-default-content'
				/>
			)}
		</StudentsPanel.Tabs.TabContent>
	);
}
