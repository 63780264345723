import {Observable} from 'rxjs';
import {ClassResponse} from './types';
import {WidgetService} from '../service';
import {StudentBundle, ClassBundle} from '../types';

export class UntestedStudentsService extends WidgetService<any, ClassResponse> {
	protected requestStudentData(request: StudentBundle): Observable<any> {
		throw new Error('Method not implemented.');
	}

	protected requestClassData(request: ClassBundle): Observable<ClassResponse> {
		return this.classController.untestedStudents({studentIDs: request.studentIDs, testIDs: request.testIDs});
	}
}