import {Input} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const InputDisabled = styled(Input, {
	'&[data-state=valued][data-disabled=true]': {
		color: '$lowContrast',
	},
});

export const TranscriptText = styled(Text, {
	display: 'inline-block',
	paddingLeft: '$3',
	paddingRight: '$4',
});
