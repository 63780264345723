import {Renderable, Form, OldAlerts} from '@esgi/deprecated/knockout';
import {FieldContainer} from '../../../kit/component';
import {
	CountrySelectComponent, DistrictSelectComponent,
	FederalSchoolSelectComponent,
	FullNameFormComponent, GradeScaleForm,
	SchoolInfo,
	StateSelectComponent, TimeZoneSelectComponent,
} from '../../components';
import {SelectionType, UnlinkedModel} from '../../models';
import {UnlinkedCompleteRequestModel} from '../../server';
import {UnlinkedContinueTemplate} from './templates';

export class UnlinkedComplete extends Renderable {

	constructor(opts: UnlinkedModel) {
		super();
		this.createFields(opts);
		this.resolveValidationPosition();
	}

	template = () => UnlinkedContinueTemplate.Render();

	resolveValidationPosition() {
		const width = window.screen.width;
		if (width < 660) {
			this.fullName.lastName.validation.errorPosition = 'bottom';
			this.fullName.firstName.validation.errorPosition = 'bottom';
			this.state.setErrorPosition('bottom');
			this.district.setErrorPosition('bottom');
			this.school.setErrorPosition('bottom');
			this.gradeLevel.setErrorPosition('bottom');
		}
	}

	private fullName: FullNameFormComponent;
	private country: CountrySelectComponent;
	private state: StateSelectComponent;
	private school: FederalSchoolSelectComponent;
	private district: DistrictSelectComponent;
	private timezone: TimeZoneSelectComponent;
	private gradeLevel: GradeScaleForm;
	private fields: Array<FieldContainer | Form>;

	createFields(opts: UnlinkedModel) {
		this.fullName = new FullNameFormComponent({firstName: opts.firstName, lastName: opts.lastName});
		this.state = new StateSelectComponent();
		this.country = new CountrySelectComponent(opts.countryID);
		this.school = new FederalSchoolSelectComponent();
		this.district = new DistrictSelectComponent();
		this.timezone = new TimeZoneSelectComponent(opts.timeZoneID);
		this.gradeLevel = new GradeScaleForm();
		this.country.selectedCountryID.subscribe((v) => !!v && this.state.setCountry(v));
		this.state.setCountry(opts.countryID);
		this.state.state.subscribe((v) => {
			this.school.setState(v && v.id);
			this.district.setState(v && v.id);
		});
		this.school.schoolUpdated.subscribe((v: SchoolInfo) => {
			if (v.selectionType !== SelectionType.New) { // remove tooltips
				this.district.destroy();
			} else {
				this.district.setSchoolInfo(v);
			}
		});
		this.fields = new Array<FieldContainer | Form>();
		this.fields.push(...[this.fullName, this.state, this.country, this.school, this.timezone, this.gradeLevel]);
	}

	isValid() {
		const districtValidation = this.school.selectionType === SelectionType.New ? this.district.validate() : $.Deferred().resolve(true).promise();
		return $.whenAll([...this.fields.map(s => s.validate()), districtValidation]);
	}

	serialize() {
		const model = <UnlinkedCompleteRequestModel>{
			firstName: this.fullName.firstName.value(),
			lastName: this.fullName.lastName.value(),
			title: this.fullName.title.value(),
			stateID: this.state.state().id,
			countryID: this.country.selectedCountryID(),
			timeZoneID: this.timezone.serialize().id,
			gradeLevels: this.gradeLevel.serialize().map(g => parseInt(g)),
		};
		if (this.school.selectionType === SelectionType.New) {
			model.schoolName = this.school.inputText();
			model.districtName = this.district.selectionType === SelectionType.Skip ? this.school.inputText() : this.district.serialize().name;
			model.federalDistrictID = this.district.selectedDistrict && this.district.selectedDistrict.id;
		} else {
			const school = this.school.selectedSchool;
			model.federalDistrictID = school.districtID;
			model.districtName = school.districtName;
			model.schoolName = school.schoolName;
			model.federalSchoolID = school.schoolID;
		}
		return model;
	}


	private isDoneClicked = ko.observable(false);

	private onFail = () => {
		this.isDoneClicked(false);
	};

	private eventTriggers = {
		cancel: () => {
			OldAlerts.bsconfirm('Are you sure you want to exit? You cannot use your ESGI account until you complete this form.', (r) => {
				if (r) {
					$(this).trigger('cancelClicked');
				}
			});
		},
		done: () => {
			this.isValid().done((r) => {
				const isValid = r.reduce((a, b) => a && b);
				if (isValid) {
					this.isDoneClicked(true);
					$(this).trigger('doneClicked', {data: this.serialize(), onFail: this.onFail});
				}
			});
		},
	};

	events = {
		cancel: (callback) => {
			$(this).on('cancelClicked', callback);
		},
		done: (callback) => {
			$(this).on('doneClicked', callback);
		},
	};
}
