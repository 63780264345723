import styles from './styles.module.less';
import {Buttons} from '@esgillc/ui-kit/button';
import {ArrowDirection} from 'modules/forms/class-form/types';


interface Props {
	moveDisabled: boolean,
	onClick: () => void,
	arrowDirection: ArrowDirection
}

export function MoveButton({moveDisabled, onClick, arrowDirection}: Props) {
	const buttonContent = () => {
		if (arrowDirection === ArrowDirection.None) {
			return <span>Move</span>;
		}

		if (arrowDirection === ArrowDirection.Right) {
			return <span className={styles.spanRight} data-cy={'arrow-right'}>Move<svg className={styles.svgIconRight}>
                    <path d='M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z'
                          fill='#0088CC'/>
                </svg></span>;
		}

		return <span className={styles.spanLeft}>Move<svg className={styles.svgIconLeft} data-cy={'arrow-left'}>
                    <path d='M7.40997 10.59L2.82997 6L7.40997 1.41L5.99997 0L-2.67029e-05 6L5.99997 12L7.40997 10.59Z'
                          fill='#0088CC'/>
                </svg></span>;
	};

	return <div className={styles.moveButtonWrapper} data-cy={'move-button'}>
		<Buttons.Outlined className={styles.moveButton} onClick={onClick} disabled={moveDisabled}>
			{buttonContent()}
		</Buttons.Outlined>
	</div>;
}
