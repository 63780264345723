export const IepProgressReportIcon = (): JSX.Element => (
  <svg
		className='icon'
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
	>
		<circle
			cx='8.61444'
			cy='8.96201'
			r='7.5'
			stroke='#0088CC'
			strokeWidth='2'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.87299 6.06357V12.462H3.59418V6.06357H4.87299ZM9.64105 11.3985V12.462H6.85931V11.3985H9.64105ZM7.25922 6.06357V12.462H5.97162V6.06357H7.25922ZM9.26752 8.65634V9.68906H6.85931V8.65634H9.26752ZM9.63226 6.06357V7.13144H6.85931V6.06357H9.63226ZM12.5722 10.2076H11.2626V9.13974H12.5678C12.7553 9.13974 12.9062 9.09872 13.0204 9.01669C13.1347 8.93466 13.2182 8.82187 13.2709 8.67831C13.3237 8.53183 13.35 8.36777 13.35 8.18613C13.35 8.00156 13.3237 7.8287 13.2709 7.66757C13.2182 7.50644 13.1347 7.37753 13.0204 7.28085C12.9062 7.18124 12.7568 7.13144 12.5722 7.13144H11.6757V12.462H10.3925V6.06357H12.5722C13.0029 6.06357 13.3735 6.15439 13.684 6.33603C13.9946 6.51767 14.2319 6.76816 14.3959 7.08749C14.5629 7.4039 14.6464 7.76718 14.6464 8.17734C14.6464 8.58749 14.5629 8.94492 14.3959 9.2496C14.2289 9.55429 13.9902 9.79013 13.6796 9.95712C13.372 10.1241 13.0029 10.2076 12.5722 10.2076Z'
			fill='#0088CC'
		/>
	</svg>
);
